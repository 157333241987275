import { Component, OnInit,Input } from '@angular/core';
import { Http, Headers } from '@angular/http';
import {Helper_Class} from '../../helper_class';
import { Data, Route, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { formattedDate, Day_Return, Session_Return } from '../../../assets/js/common';
import { ipaddress } from '../../ipaddress' ;import { Message_data } from 'src/assets/js/Message_data';

@Component({
  selector: 'app-doctor-manage-schedule-main',
  templateUrl: './doctor-manage-schedule-main.component.html',
  styleUrls: ['./doctor-manage-schedule-main.component.css']
})
export class DoctorManageScheduleMainComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  persons: any = [];
  masterSelected: boolean = false;
  public deletebutton:boolean=false
  checklist:any;
  public hospitalinfoarray=[];
  public hptl_clinic_id;
  checkedList:any
  public msgflag:boolean=false;
  public profile_image:string;
  public Get_manage_doctor;
  public active_tx;
  public actoive_color;
  public Session_txt;
  public day_txt;
  public Get_manage_nurse;
  public Get_Manage_arry :any = [];
  public Get_Managenurse_arry :any= [];
  public Get_manage_physio;
  public physio_active_tx;
  public Get_Managephysio_arry:any = [];
  public userinfo:any;
  public user_id:any;
  public getipaddress = Helper_Class.getIPAddress();
  // hptl_clinic_id: any;
  adminuser_id: any;
  user_type: any;
  is_admin: boolean;
  doctorid: any;
  doctorList: any[];

  constructor(public  http:Http,public  router:Router,public  toastr:ToastrService) {
  }

  ngOnInit(): void {   
    this.dtOptions = {
      pageLength: 25,
      "lengthMenu": [ [25, 50, -1], [25, 50, "All"] ],
      "columnDefs": [
        { "orderable": false, "targets": [0,2,3,4] },
      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search by name"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    if(Helper_Class.getmodulelist() != undefined){
      for (var i = 0; i < Helper_Class.getmodulelist().length; i++) {
        if (Helper_Class.getmodulelist()[i].module_id == "11"){
            if(Helper_Class.getmodulelist()[i].edit == "1"){
             // this.editbutton=true;
            }
            if(Helper_Class.getmodulelist()[i].create == "1"){
              //this.newbutton=true;
            }
            if(Helper_Class.getmodulelist()[i].delete == "1"){
              this.deletebutton=true;
            }
            if(Helper_Class.getmodulelist()[i].print == "1"){
             // this.printbutton=true;
            }
            if(Helper_Class.getmodulelist()[i].view == "1"){
             // this.viewbutton=true;
            }
            
            
        }
       
      }
    }
    this.userinfo = Helper_Class.getInfo();
    this.user_id = this.userinfo.user_id;
    this.hospitalinfoarray = Helper_Class.getHospital();
    if(this.hospitalinfoarray != undefined){
      this.hptl_clinic_id = this.hospitalinfoarray[0].hptl_clinic_id;
    }
   
    this.changeProviderType();
    this.Get_doctor_availability();
    this.Get_nusre_availability();
    this.Get_physio_availability();
   
  }  
  changeProviderType() {

    if (Helper_Class.getInfo().user_type == 'Admin') {
      this.hptl_clinic_id = Helper_Class.getInfo().hptl_clinic_id;
      this. adminuser_id = Helper_Class.getInfo().user_id;
      this.user_type = Helper_Class.getInfo().user_type;
      this.is_admin = true;
      this.getDoctors();
    }
    
  }
  changedoc(){
    this.user_id = this.doctorid;
    this.Get_doctor_availability();
    this.Get_nusre_availability();
    this.Get_physio_availability();
  }
  
  getDoctors() {
    this.doctorList = [];
    var send_data = {
      country: 'IN',
      doc_reg_id: this.adminuser_id,
      type: this.user_type,
      hptl_clinic_id: this.hptl_clinic_id,
    };
    console.log(
      'check---' +
      JSON.stringify({
        country: 'IN', 
        doc_reg_id: this.adminuser_id,
        type: this.user_type,
        hptl_clinic_id: this.hptl_clinic_id,
      })
    );
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(ipaddress.getIp.toString() + 'adm/docbyhosp', JSON.stringify(send_data), { headers: headers })
      .subscribe(
        (response) => {
          var obj = JSON.parse(response["_body"]);
          console.log('check-- Doctor list-' +  JSON.stringify(obj));
          if (obj != null) {
            for (var i = 0; i < obj.doctors.length; i++) {
              var doctorName = '';
              var fname, mname, lname;
              if (
                obj.doctors[i].middle_name != '' &&
                obj.doctors[i].middle_name != undefined
              ) {
                doctorName =
                  obj.doctors[i].first_name +
                  ' ' +
                  obj.doctors[i].middle_name +
                  ' ' +
                  obj.doctors[i].last_name;
                fname = obj.doctors[i].first_name;
                mname = obj.doctors[i].middle_name;
                lname = obj.doctors[i].last_name;
              } else {
                doctorName =
                  obj.doctors[i].first_name + ' ' + obj.doctors[i].last_name;
                fname = obj.doctors[i].first_name;
                lname = obj.doctors[i].last_name;
              }

              this.doctorList.push({
                Doc_Name: doctorName,
                Doc_ID: obj.doctors[i].prov_id,
                type: this.userinfo.user_type,
              });
            }

            this.doctorid = this.userinfo.user_id;
            this.user_id = this.userinfo.user_id;
            for (var i = 0; i < this.doctorList.length; i++) {
              if (this.doctorList[i].Doc_ID == this.userinfo.user_id) {

              }
            }
          }
        },
        (error) => {

        }
      );
  }

  //Get doctors
  Get_doctor_availability() {
    var send_data = JSON.stringify({
      doc_reg_id: this.user_id,
      type: "doctor",
      flag: "doctor",
      imei: this.getipaddress,
      hptl_clinic_id:this.hptl_clinic_id 
    });
    console.log("rhj --"+JSON.stringify(send_data))
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/docbyhosp',send_data,{ headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          console.log("rhj --"+JSON.stringify(obj))
          if (obj.doctors != null) {
            this.Get_manage_doctor = obj.doctors;
            for (var i = 0; i < this.Get_manage_doctor.length; i++) {
              if (this.Get_manage_doctor[i].is_active != null) {
                if (this.Get_manage_doctor[i].is_active == "0") {
                  this.active_tx = "InActive";
                  this.actoive_color = "inactive_style";
                } else if (this.Get_manage_doctor[i].is_active == "1") {
                  this.active_tx = "Active";
                  this.actoive_color = "active_style";
                } else {
                  this.active_tx = "";
                }
              }
              var session_split = this.Get_manage_doctor[i].day_session.split(",");
              this.Session_txt = "";
              if (session_split[0] != null) {
                this.Session_txt = "";
                for (var j = 0; j < session_split.length; j++) {
                  this.Session_txt = this.Session_txt + Session_Return(session_split[j]);
                  if (j != session_split.length - 1) {
                    this.Session_txt = this.Session_txt + ", ";
                  }
                }
              } else {
                this.Session_txt = Session_Return(this.Get_manage_doctor[i].day_session);
              }
              var day_split = this.Get_manage_doctor[i].work_days.split(",");
              this.day_txt = "";
              if (day_split[0] != null) {
                this.day_txt = "";
                for (var j = 0; j < day_split.length; j++) {
                  this.day_txt = this.day_txt + Day_Return(day_split[j]);
                  if (j != day_split.length - 1) {
                    this.day_txt = this.day_txt + ", ";
                  }
                }
              } else {
                this.day_txt = Day_Return(this.Get_manage_doctor[i].work_days);
              }

              var docname;
              if(this.Get_manage_doctor[i].middle_name != undefined){
                docname=this.Get_manage_doctor[i].first_name + " " + this.Get_manage_doctor[i].middle_name + " " + this.Get_manage_doctor[i].last_name;
              } else {
                docname=this.Get_manage_doctor[i].first_name + " " + this.Get_manage_doctor[i].last_name;
              }

              this.Get_Manage_arry.push({
                full_name: docname,
                days: this.day_txt,
                day_session: this.Session_txt,
                specialization: this.Get_manage_doctor[i].specialization,
                active: this.active_tx,
                prov_id: this.Get_manage_doctor[i].prov_id,
                hptl_clinic_id: this.Get_manage_doctor[i].hptl_clinic_id,
                active_style: this.actoive_color
              });
            }
          }
        },
        error => {});
  } 
  
  delete_dotor(data) {//Delete doctors
    if (this.Get_Manage_arry.length != 0) {
      for (var i = 0; i < this.Get_Manage_arry.length; i++) {
        if (this.Get_Manage_arry[i].prov_id == data) {
          var headers = new Headers();
          headers.append('Content-Type', 'application/json');
          this.http.post(ipaddress.getIp.toString() + 'adm/docdel',
            JSON.stringify({
              doc_reg_id: this.Get_Manage_arry[i].prov_id,
              hptl_clinic_id: this.Get_Manage_arry[i].hptl_clinic_id,
              type: "doctor"
            }),
            { headers: headers })
            .subscribe(
              response => {
                var obj = JSON.parse(response["_body"]);
                if (obj["key"] != null) {
                  if (obj["key"] == "1") {
                    this.toastr.success(Message_data.removeSuccess);
                    this.Get_Manage_arry.splice(i, 1);
                  }
                }
              },
              error => {});
        }
      }
    }
  }

  Get_nusre_availability() {//Get Nusres
    var send_data3= JSON.stringify({
      doc_reg_id: this.user_id,
      type: "nurse",
      flag: "doctor",
      imei:this.getipaddress,
      hptl_clinic_id:this.hptl_clinic_id 
    })
    console.log("rhj --nurse"+JSON.stringify(send_data3))
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/docbyhosp',
      JSON.stringify({
        doc_reg_id: this.user_id,
        type: "nurse",
        flag: "doctor",
        imei:this.getipaddress,  hptl_clinic_id: this.hptl_clinic_id,
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          console.log("rhj nurse--"+JSON.stringify(obj))
          if (obj.doctors != null) {
            this.Get_manage_nurse = obj.doctors;
            if (this.Get_manage_nurse != undefined) {
              for (var i = 0; i < this.Get_manage_nurse.length; i++) {
                if (this.Get_manage_nurse[i].is_active != null) {
                  if (this.Get_manage_nurse[i].is_active == "0") {
                    this.active_tx = "InActive";
                    this.actoive_color = "inactive_style";
                  } else if (this.Get_manage_nurse[i].is_active == "1") {
                    this.active_tx = "Active";
                    this.actoive_color = "active_style";

                  } else {
                    this.active_tx = "";
                  }
                }
                var day_split = this.Get_manage_nurse[i].work_days.split(",");
                this.day_txt = "";
              
                if (day_split[0] != null) {
                  this.day_txt = "";
                  for (var j = 0; j < day_split.length; j++) {
                    this.day_txt = this.day_txt + Day_Return(day_split[j]);
                    if (j != day_split.length - 1) {
                      this.day_txt = this.day_txt + ", ";
                    }
                  }

                } else {
                  this.day_txt = Day_Return(this.Get_manage_nurse[i].work_days);
                }

                var nursename;
                if(this.Get_manage_nurse[i].middle_name != undefined){
                  nursename = this.Get_manage_nurse[i].first_name + " " + this.Get_manage_nurse[i].middle_name + " " + this.Get_manage_nurse[i].last_name;
                } else {
                  nursename = this.Get_manage_nurse[i].first_name + " " + this.Get_manage_nurse[i].last_name;
                }

                this.Get_Managenurse_arry.push({
                  nurse_full_name: nursename,
                  nurse_days: this.day_txt,
                  nurse_active: this.active_tx,
                  nurse_prov_id: this.Get_manage_nurse[i].prov_id,
                  nurse_hptl_clinic_id: this.Get_manage_nurse[i].hptl_clinic_id,
                  active_style: this.actoive_color
                });
              }
            }
          }
        },
        error => {});
  }

  //Delete nurse
  Nurse_delete(data) {
    if (this.Get_Managenurse_arry.length != 0) {
      for (var i = 0; i < this.Get_Managenurse_arry.length; i++) {
        if (this.Get_Managenurse_arry[i].nurse_prov_id == data) {
          var headers = new Headers();
          headers.append('Content-Type', 'application/json');
          this.http.post(ipaddress.getIp.toString() + 'adm/docdel',
            JSON.stringify({
              doc_reg_id: this.Get_Managenurse_arry[i].nurse_prov_id,
              hptl_clinic_id: this.Get_Managenurse_arry[i].nurse_hptl_clinic_id,
              type: "nurse",
            }),
            { headers: headers })
            .subscribe(
              response => {
                var obj = JSON.parse(response["_body"]);
                if (obj["key"] != null) {
                  if (obj["key"] == "1") {
                    this.toastr.success(Message_data.removeSuccess);
                    this.Get_Managenurse_arry.splice(i, 1);
                  }
                } else {
                  this.toastr.success(Message_data.removeNotSuccess);
                }
              },
              error => {});
        }
      }
    }
  }

  Get_physio_availability() {//Get Physiotherapist
    var send_data1 =  JSON.stringify({
      doc_reg_id: this.user_id,
      type: "physio",
      flag: "doctor",
      imei: this.getipaddress,
      hptl_clinic_id:this.hptl_clinic_id 
    })
    console.log("rhj physsio--"+JSON.stringify(send_data1))
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/docbyhosp',send_data1,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          console.log("rhj physsio--"+JSON.stringify(obj))

          if (obj != null) {
            this.Get_Managephysio_arry=[];
            this.Get_manage_physio = obj.doctors;
            if(this.Get_manage_physio != undefined){
              for (var i = 0; i < this.Get_manage_physio.length; i++) {
                if (this.Get_manage_physio[i].is_active != null) {
                  if (this.Get_manage_physio[i].is_active == "0") {
                    this.physio_active_tx = "InActive";
                    this.actoive_color = "inactive_style";

                  } else if (this.Get_manage_physio[i].is_active == "1") {
                    this.physio_active_tx = "Active";
                    this.actoive_color = "active_style";

                  } else {
                    this.physio_active_tx = "";
                  }
                }

                var day_split = this.Get_manage_physio[i].work_days.split(",");
                this.day_txt = "";

                if (day_split[0] != null) {
                  this.day_txt = "";

                  for (var j = 0; j < day_split.length; j++) {
                    this.day_txt = this.day_txt + Day_Return(day_split[j]);

                    if (j != day_split.length - 1) {
                      this.day_txt = this.day_txt + ", ";
                    }
                  }

                }    else {
                  this.day_txt = Day_Return(this.Get_manage_physio[i].work_days);
                }

                var physioname;
                if(this.Get_manage_physio[i].middle_name != undefined){
                  physioname = this.Get_manage_physio[i].first_name + " " + this.Get_manage_physio[i].middle_name + " " + this.Get_manage_physio[i].last_name;
                } else {
                  physioname = this.Get_manage_physio[i].first_name + " " + this.Get_manage_physio[i].last_name;
                }
                this.Get_Managephysio_arry.push({
                  physio_full_name: physioname,
                  physio_days: this.day_txt,
                  physio_active: this.physio_active_tx,
                  physio_prov_id: this.Get_manage_physio[i].prov_id,
                  physio_hptl_clinic_id: this.Get_manage_physio[i].hptl_clinic_id,
                  active_style: this.actoive_color
                });
              }
            }

          } else {
              this.toastr.error(Message_data.noPhysio);
          }
        },
        error => {});
  }

  Physio_Delete(data) {//Physio doctors
    if (this.Get_Managephysio_arry.length != 0) {
      for (var i = 0; i < this.Get_Managephysio_arry.length; i++) {
        if (this.Get_Managephysio_arry[i].physio_prov_id == data) {
          var headers = new Headers();
          headers.append('Content-Type', 'application/json');
          this.http.post(ipaddress.getIp.toString() + 'adm/docdel',
            JSON.stringify({
              doc_reg_id: this.Get_Managephysio_arry[i].physio_prov_id,
              hptl_clinic_id: this.Get_Managephysio_arry[i].physio_hptl_clinic_id,
              type: "physio"
            }),
            { headers: headers })
            .subscribe(
              response => {
                var obj = JSON.parse(response["_body"]);
                if (obj["key"] != null) {
                  if (obj["key"] == "1") {
                    this.toastr.success(Message_data.removeSuccess);
                    this.Get_Managephysio_arry.splice(i, 1);
                  }
                }
              },
              error => {});
        }
      }
    }
  }
}
