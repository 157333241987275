<app-header></app-header>
<div class="privacy_header">
    <div class="row" style="margin-top: 10px;">
        <div class="col-12" style="padding-left: 100px;padding-right: 100px; font-size: 20px;"> 
            <p class="tervys_heading header_font"><strong>Privacy Policy</strong></p>
            <p class="matlabel para_font">Please take a moment to read the following Privacy Policy. This should be read in conjunction with our Terms &amp; Conditions. Please note that words which are defined in our Terms &amp; Conditions also apply to this Privacy Policy</p>
            
            <p class="matlabel para_font para_font">Your privacy is critically important to us. At Gritsys, we must comply with Indian privacy act (IPA) under “The privacy (Protection) bill, 2013” and other privacy laws that govern how private sector health service providers like Gritsys handle your personal information (including your health information).</p>
            
            <p class="matlabel para_font para_font">IMPORTANT: By accessing and using Tervys (healthcare product of Gritsys), or by submitting any personal information to us via or in relation to Gritsys, or by your registration as a Subscriber you will automatically be taken to have read, understood and accepted this Privacy Policy.</p>
            
            <p class="matlabel para_font">If you do not want us to collect, use and/or transfer your personal information in this way then you should (depending on what it is you object to) cease to access and use Gritsys and/or Contact us and ask for your registration as a Subscriber to be deleted. </p>
            
            <p class="matlabel para_font">Tervys mobile or web applications are intended to connect to the internet servers and systems maintained by Gritsys in order to provide you secure access to your health information.</p>
            
            <p class="tervys_heading header_font"><b>Your Personal Information</b></p>
            <p class="matlabel para_font">Gritsys does not disclose, license or lease any information that it may collect from you from using our Applications except to comply with the law or protect our rights.</p>
            
            <p class="matlabel para_font">And, except for those things stated below, our Applications or systems do not store any of your personal information in the server or in Cloud.</p>
            
            <p class="matlabel para_font">Gritsys attempt to collect as much as information possible so that analytics of the patient’s health will be accurate and measurable. And also provide as much as information about the service provider to the patient when they search for health service providers for their needs.</p>
            
            <p class="matlabel para_font">Store a copy of a picture if you choose to add a picture to your profile.</p>
            
            <p class="matlabel para_font"> Share some limited amount of information for purposes of troubleshooting and error correction directly or indirectly with Gritsys with your consent.</p>
            
            <p class="matlabel para_font"> From time to time, we may also use your information to contact you for healthcare research purposes. We may contact you by email, phone, fax or mail. We may use the information to customise the website according to your interests.</p>
            
            <p class="matlabel para_font">To use our Applications, you must have an account with Gritsys to use Tervys. You understand that while connected, or attempting to connect, to Gritsys, system may collect, store, process, maintain, upload, sync, transmit, share, disclose and use certain data and related information, including but not limited to information or data regarding the characteristics or usage of your device, system and application software, and peripherals as well as your personal information, location data and other content.</p>
            
            <p class="tervys_heading header_font"><b>Data security</b></p>
            
            <p class="matlabel para_font">The security of your information and data while using our Applications is very critical to us. Our Applications employ a variety of technical safeguards / algorithms to protect the confidentiality, integrity, and availability of your personal information including supporting Transport Layer Security (TLS)/Secure Sockets Layer (SSL) certificate technology and data encryption.</p>
            
            
            <p class="tervys_heading header_font"><b>Cookies</b></p>
            
            <p class="matlabel para_font">A cookie is a device that allows our server to identify and interact more effectively with your computer. Cookies do not identify individual users, but they do identify your ISP and your browser type.</p>
            
            <p class="matlabel para_font">Our web based application uses temporary cookies. This means that upon closing your browser, the temporary cookie assigned to you will be destroyed and no personal information is maintained which will identify you at a later date.</p>
            
            <p class="tervys_heading header_font"><b>Liability</b></p>
            
            <p class="matlabel para_font">Please read carefully the paragraphs entitled DISCLAIMERS, OUR LIABILITY</p>
        </div>
    </div>
</div>


<footer>
    <p>© 2022 All rights reserved - www.tervys.com</p>
</footer>