<div class="row" style="margin:0 auto; width:100%">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white" style="padding:7px 20px;">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Request</h5>
          </div>
          <div class="headerButtons">
            <img (click)="check_next()" class="saveimgbtn_inpatinfo"
            src="../../../assets/ui_icons/buttons/Next_button.svg" />
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="cover_div" style="padding-bottom: 13px;">
          <div class="header_lable">
             Address details
          </div>
          <div class="content_cover">
            <div class="row">
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Hospital<br>
                  <input type="text" class="ipcss widthappt" required maxlength="50"
                    (keyup)="hosfollowfilter($event)" [(ngModel)]="hospitalName" matInput
                    [matAutocomplete]="auto1" />
                  <mat-autocomplete #auto1="matAutocomplete">
                    <mat-option (click)="selectHospital(hospital)" *ngFor="let hospital of hospitalList"
                      [value]="hospital">
                      {{hospital.hos_name}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel"> location<br>
                  <input type="text" class="ipcss widthappt" required maxlength="50" (keyup)="locationFilter($event)"
                    [(ngModel)]="locationName" matInput [matAutocomplete]="auto" />
                  <mat-autocomplete #auto="matAutocomplete">
                    <mat-option (click)="selectLocation(location)" *ngFor="let location of locationList"
                      [value]="locationName">
                      {{location}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottomy">
                <mat-label class="matlabel"> City<br>
                  <mat-select disableOptionCentering class="ipcss widthappt" required [(ngModel)]="filtCity"
                    (ngModelChange)="cityChange($event)">
                    <mat-option disabled>Select</mat-option>
                    <mat-option *ngFor="let city of cityList" [value]="city.city_id">{{city.city_desc}}</mat-option>
                  </mat-select>
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel"> State<br>
                  <mat-select disableOptionCentering class="ipcss widthappt" required [(ngModel)]="filtState"
                    (ngModelChange)="stateChange($event)">
                    <mat-option disabled>Select</mat-option>
                    <mat-option *ngFor="let state of stateList" [value]="state.state_id">{{state.state_desc}}
                    </mat-option>
                  </mat-select>
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel"> Country<br>
                  <mat-select disableOptionCentering class="ipcss widthappt" [(ngModel)]="filtCountry"
                    (ngModelChange)="countryChange($event)">
                    <mat-option disabled>Select</mat-option>
                    <mat-option *ngFor="let country of countryList" [value]="country.country_desc">
                      {{country.country_desc}}</mat-option>
                  </mat-select>
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom">
                <mat-label class="matlabel">Zipcode<br>
                  <input disabled="true" maxlength="6" class="ipcss widthappt" matInput value={{zipcode}}
                    [(ngModel)]="zipcode">
                </mat-label>
              </div>
            </div>
          </div>
        </div>
        <div class="cover_div" style="padding-bottom: 13px;">
          <div class="header_lable">
             Blood request details
          </div>
          <div class="content_cover">
            <div class="row">
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom">
                <mat-label class="matlabel">Date<br>
                  <input type="date" class="ipcss widthbillcreate" id="appt_date"
                    (change)="OnDateChangedto(appointmentDate)" [(ngModel)]="appointmentDate" min="{{currentMaxDate}}"
                    #matInput style="background: #fff;">
                </mat-label>
              </div>
              <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom">
                <mat-label class="matlabel">No of unit(s)
                  <input type="text" class="ipcss widthappt" value={{unit}} [(ngModel)]="unit" type="number"
                    pattern="[0-9]*" required #matInput />
                </mat-label>
              </div>
              <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Blood group<br>
                  <mat-select disableOptionCentering class="ipcss widthappt" [(ngModel)]="bloodId">
                    <mat-option *ngFor="let bgrps of bgrpList" value="{{bgrps.bgrp_id}}"
                      [selected]="bgrps.bgrp_id == bloodId">{{bgrps.bgrp_name}}</mat-option>
                  </mat-select>
                </mat-label>
              </div>
            </div>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>