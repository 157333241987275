<div class="row">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Diagnosis Appointments</h5>
          </div>
          <div class="headerButtons">
            <a (click)= create()><img src="../../../assets/ui_icons/buttons/new_button.svg" class="saveimgbtn_inpatinfo" /></a>
          </div>
        </div>
       </mdb-card-header>
      <mdb-card-body>
        <p class="nodata" *ngIf="app_datas.length == 0 && app_datas == undefined ">No Appointment(s) Found</p>
        <table *ngIf="app_datas.length" mdbTable datatable [dtOptions]="dtOptions" class="table table-nowrap table-sm dataTable">
          <thead>
            <tr>
              <th>Name</th>
              <th>Diagnosis center</th>     
              <th>Date</th>
              <th>Session</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let person of app_datas; let i = index" (click)="viewAppt(person.app_id,person.status)">
              <td style="font-size: 12px;text-align: left;">{{ person.name }}</td>
              <td style="font-size: 12px;text-align: left;">{{ person.center_name }}</td>
              <td style="font-size: 12px;">{{ person.date }}</td>
              <td style="font-size: 12px;text-align: center;">{{ person.session }}</td>
              <td style="font-size: 12px;"> 
                <div style="position: relative;top: 9px;">
                  <img *ngIf="person.status == 'Completed'" width="30px" height="auto" src="../../../assets/ui_icons/Completed_icon.svg" />
                  <img *ngIf="person.status == 'Cancelled'"  width="30px" height="auto" src="../../../assets/ui_icons/cancel_icon.svg" />
                  <img *ngIf="person.status == 'Not visited'"  width="30px" height="auto" src="../../../assets/ui_icons/not_visited_icon.svg" />
                  <img *ngIf="person.status == 'Confirmed'"  width="30px" height="auto" src="../../../assets/ui_icons/confirmed_icon.svg" />
                  <img *ngIf="person.status == 'Open'"  width="30px" height="32px" src="../../../assets/ui_icons/opened_icon.svg" />
                  <img *ngIf="person.status == 'Rejected'"  width="30px" height="auto" src="../../../assets/ui_icons/confirmed_icon.svg" />
                  <img *ngIf="person.status == 'Awaiting for rating'"  width="30px" height="32px" src="../../../assets/ui_icons/awaiting_for_rating.svg" />
                  <img *ngIf="person.status == 'Time not alloted'"  width="30px" height="32px" src="../../../assets/ui_icons/time_not_alloted.svg" />
                  <img *ngIf="person.status == 'Time alloted'"  width="30px" height="32px" src="../../../assets/ui_icons/time_not_alloted.svg" />
                  <img *ngIf="person.status == 'Sample collected'"  width="30px" height="32px" src="../../../assets/ui_icons/sample_collected.svg" />
                  <img *ngIf="person.status == 'Report yet to be finalized'"  width="30px" height="32px" src="../../../assets/ui_icons/report_yet.svg" />
                  <p>{{person.status}}</p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <br><br><br>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>