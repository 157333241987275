import { Component, OnInit,ViewEncapsulation,ElementRef,Input,OnDestroy,HostListener, Inject } from '@angular/core';
import { ConvertTimeformat, session_based_time_fun, Date_Formate, Time_Formate } from '../../../assets/js/common';
import { ToastrService } from 'ngx-toastr';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Http, Headers } from '@angular/http';
import { Router } from '@angular/router';
import { Helper_Class } from 'src/app/helper_class';
import { ipaddress } from '../../ipaddress' ;import { Master_Helper } from '../Master_Helper';
import { Message_data } from 'src/assets/js/Message_data';

@Component({
  selector: 'app-popup-doctor-schedule-list',
  templateUrl: './popup-doctor-schedule-list.component.html',
  styleUrls: ['./popup-doctor-schedule-list.component.css']
})
export class PopupDoctorScheduleListComponent implements OnInit {
  @Input() id: string;parm: string;  
  public userinfo;
  public user_id
  public  element: any;
  minDate =new Date();
  public  Start_time;
  public  end_time;
  public  time_session;
  public  Start_time1;
  public  end_time1;
  public  time_session_end;
  public  starts_from_array :any= [];
  public  ends_at_array :any= [];
  public  chk_data;
  public  session_txt;
  public  CurrentDatetime;
  public  Appoint_Date: string;
  public  Current_time;
  public  doc_sche_list :any = [];
  public  doc_sche_list1 = [];
  public  reason_txt;
  public  From_date;
  public  To_date;
  public currentDate;
  public showFullday:boolean = false;
  public  session_hide;
  public Full_daytxt;

  constructor(public toastr: ToastrService,public http: Http,public router:Router, 
    public  el: ElementRef, public dialogRef: MatDialogRef<PopupDoctorScheduleListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      dialogRef.disableClose = true;
      this.element = el.nativeElement;
      this.Start_time = "01";
      this.end_time = "00";
      this.time_session = "AM";
      this.Start_time1 = "11";
      this.end_time1 = "00";
      this.time_session_end = "PM";
      this.chk_data = "0";
      this.reason_txt ="select";
      this.session_txt = "select";
  }

  ngOnInit(): void {
    this.userinfo=Helper_Class.getInfo()
    this.user_id=this.userinfo.user_id
    
    for (var i = 1; i <= 12; i++) {
      if (i < 10) {
        this.starts_from_array.push("0" + i);
      } else {
        this.starts_from_array.push(i);
      }
    }

    for (var i = 0; i <= 55; i += 5) {
      if (i < 10) {
        this.ends_at_array.push("0" + i);
      } else {
        this.ends_at_array.push(i);
      }
    }
    this.Get_current_date();
    this.Get_retrivel_schedule();
    // this.showFullday = false;
  }
   //Get current date
  Get_current_date() {
    var obj = Master_Helper.getMasterCurrentDate();

    if (obj.current_date != null) {
      this.CurrentDatetime = obj.current_date;
      this.currentDate = obj.current_date;
      this.From_date = this.CurrentDatetime
      this.To_date = this.CurrentDatetime
    }
  }

  //Get retrivel doctor schedule
  Get_retrivel_schedule() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'usercontroller/gdocavail/',
      JSON.stringify({
        doc_reg_id:this.userinfo.user_id,
        type: "doctor",
      }),
      { headers: headers })
      .subscribe(
        response => {
          this.doc_sche_list = [];
          var obj = response.json()

          if (obj.doc_avail != null) {
            for (var i = 0; i < obj.doc_avail.length; i++) {
              if (obj.doc_avail[i].from_time != null) {
                this.doc_sche_list.push({
                  from_date: obj.doc_avail[i].from_date,
                  to_date: obj.doc_avail[i].to_date,
                  from_date_d: Date_Formate(obj.doc_avail[i].from_date),
                  to_date_d: Date_Formate(obj.doc_avail[i].to_date),
                  session: obj.doc_avail[i].session,
                  from_time: obj.doc_avail[i].from_time,
                  from_time_data: Time_Formate(obj.doc_avail[i].from_time) + " to " + Time_Formate(obj.doc_avail[i].to_time),
                  to_time: obj.doc_avail[i].to_time,
                  reason: obj.doc_avail[i].reason,
                  full_day: obj.doc_avail[i].full_day,
                  doc_reg_id: this.userinfo.user_id
                });

              } else {
                this.doc_sche_list.push({
                  Index: obj.doc_avail[i].availability_id,
                  from_date: obj.doc_avail[i].from_date,
                  to_date: obj.doc_avail[i].to_date,
                  from_date_d: Date_Formate(obj.doc_avail[i].from_date),
                  to_date_d: Date_Formate(obj.doc_avail[i].to_date),
                  session: obj.doc_avail[i].session,
                  reason: obj.doc_avail[i].reason,
                  full_day: obj.doc_avail[i].full_day,
                  doc_reg_id:this.userinfo.user_id
                });
              }
            }
          }
        },
        error => {
            this.toastr.error(Message_data.defaultErr);
        }
      )
  }

  stateChanged () {
    if (this.Full_daytxt == true) {
      this.chk_data = "1";
      this.session_hide = true;
    } else {
      this.chk_data = "0";
      this.session_hide = false;
    }
  }

  //Add doctor scheduleassas
  Doctor_schedule_add() {
    var flag = true;
    if (this.From_date == undefined) {
    this.toastr.error(Message_data.enterFromDate);
      flag = false;
    }

    if (flag == true && this.To_date == undefined) {
      this.toastr.error(Message_data.enterToDate);
      flag = false;
    }

    if (flag == true && this.reason_txt == undefined) {
      this.toastr.error(Message_data.mandatory);
      flag = false;
    }

    if(this.showFullday != true){
      if (flag == true && this.chk_data == "0") {
        if (this.session_txt == undefined) {
          this.toastr.error(Message_data.mandatory);
          flag = false;
        }
      }
    }

    if (flag == true) {
      if (this.From_date > this.To_date) {
        this.toastr.error(Message_data.validDate);

      } else {
        var get_f_date =this.From_date;
        var get_t_date = this.To_date

        if (this.chk_data == "0") {// ! full day
          var f_time = ConvertTimeformat("", this.Start_time + ":" + this.end_time + " " + this.time_session);
          var t_time = ConvertTimeformat("", this.Start_time1 + ":" + this.end_time1 + " " + this.time_session_end);
          var ret_value = session_based_time_fun(f_time, t_time, this.session_txt);
          var get_f_time = this.Start_time + ":" + this.end_time + " " + this.time_session;
          var get_t_time = this.Start_time1 + ":" + this.end_time1 + " " + this.time_session_end

          // Past Time
          var Time_Now = new Date(this.CurrentDatetime + " " + this.Current_time);

          if (this.time_session == "PM") {
            if (this.Start_time != "12") {
              var Frmtime_Now = new Date(get_f_date + " " + (parseInt(this.Start_time) + 12) + ":" + this.end_time);
            } else {
              var Frmtime_Now = new Date(get_f_date + " " + this.Start_time + ":" + this.end_time);
            }

          } else {
            if (this.Start_time != "12") {
              var Frmtime_Now = new Date(get_f_date + " " + this.Start_time + ":" + this.end_time);
            } else {
              var Frmtime_Now = new Date(get_f_date + " " + (12 - parseInt(this.Start_time)) + ":" + this.end_time);
            }
          }

          if (this.time_session_end == "PM") {
            if (this.Start_time1 != "12") {
              var Totime_Now = new Date(get_t_date + " " + (parseInt(this.Start_time1) + 12) + ":" + this.end_time1);
            } else {
              var Totime_Now = new Date(get_t_date + " " + this.Start_time1 + ":" + this.end_time1);
            }
            
          } else {
            if (this.Start_time1 != "12") {
              var Totime_Now = new Date(get_t_date + " " + this.Start_time1 + ":" + this.end_time1);
            } else {
              var Totime_Now = new Date(get_t_date + " " + (12 - parseInt(this.Start_time1)) + ":" + this.end_time1);
            }
          }

          if ((get_f_date == this.CurrentDatetime) || (get_t_date == this.CurrentDatetime)) {
            if ((Time_Now > Frmtime_Now) || (Time_Now > Totime_Now)) {
              ret_value = false;
            }
          }

          if (get_f_date == get_t_date){ // Check weather fromtime < totime
            if (Frmtime_Now > Totime_Now) {
              ret_value = false;
            }
          }

          if (get_f_time == get_t_time) {
              ret_value = false;
          }
          if(this.showFullday == true){
            ret_value = true;
          }
          
          if (ret_value == false) {
            this.toastr.error(Message_data.enterValidTime);
          } else {
            var from_time_data;
            if(this.showFullday == true){
              from_time_data = "12:00 AM to 11:59 PM";

            }else{
              from_time_data = this.Start_time + ":" + this.end_time + " " + this.time_session + " to " + this.Start_time1 + ":" + this.end_time1 + " " + this.time_session_end;
            }
            
            this.doc_sche_list.push({
              from_date_d:Date_Formate(this.From_date),
              to_date_d: Date_Formate(this.To_date),
              from_date:(this.From_date),
              to_date: (this.To_date),
              session: this.session_txt,
              from_time: ConvertTimeformat("24", this.Start_time + ":" + this.end_time + " " + this.time_session),
              to_time: ConvertTimeformat("24", this.Start_time1 + ":" + this.end_time1 + " " + this.time_session_end),
              from_time_data: from_time_data,
              reason: this.reason_txt,
              doc_reg_id: this.user_id
            });

            this.From_date = "";
            this.To_date = "";
            this.session_txt = "";
            this.reason_txt = "";
          }
        } else {  // Fullxfv daysdasdas
          this.doc_sche_list.push({
            from_date_d:Date_Formate(this.From_date),
            to_date_d: Date_Formate(this.To_date),
            from_date:(this.From_date),
            to_date: (this.To_date),
            reason: this.reason_txt,
            full_day: this.chk_data,
            doc_reg_id: this.user_id
          });
          this.From_date = "";
          this.To_date = "";
          this.session_txt = "";
          this.reason_txt = "";
        }
      }
    }
  }

  ClosePopup(){
    this.dialogRef.close();
  }

  OnDateChangedfrom(e){
    this.From_date = e
  }

  OnDateChangedTo(e){
    this.To_date = e;
  }

  fulldayChange(event){
    this.showFullday = event.checked;
    if(event.checked ==  true){
      this.showFullday = true;
      this.Start_time="06:00";
      this.end_time="23:00";

    }else{
      this.showFullday = false;
    }
  }

  selectSession(event){
    if(event == "Morning"){
      this.time_session = "AM";
      this.time_session_end = "AM";
      this.Start_time = "06";
      this.end_time = "00";
      this.Start_time1 = "08";
      this.end_time1 = "00";
      this.chk_data = "0";
    }else if(event == "Evening"){
      this.time_session_end = "PM";
      this.time_session = "PM";
      this.Start_time = "06";
      this.end_time = "00";
      this.Start_time1 = "08";
      this.end_time1 = "00";
      this.chk_data = "0";
    }else{
      this.time_session_end = "PM";
      this.time_session = "PM";
      this.Start_time = "12";
      this.end_time = "00";
      this.Start_time1 = "02";
      this.end_time1 = "00";
      this.chk_data = "0";
    }
  } 

  Schedule_save() {//Save schedule listsassa
    if (this.doc_sche_list.length != 0) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'usercontroller/docavail/',
        JSON.stringify({
          doc_reg_id:this.userinfo.user_id,
          doc_avail: this.doc_sche_list,
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json()
            if (obj["key"] != null) {
              if (obj["key"] == "1") {
                this.toastr.success(Message_data.dataSavedSuccess);
                this.dialogRef.close();
              }else{
                this.dialogRef.close();
              }
            }else{
              this.dialogRef.close();
            }
          },
          error => {
              this.toastr.error(Message_data.defaultErr);
          });
    } else {
      this.toastr.error(Message_data.addSchedule);
    }
  }

  Doctor_schedule_delete(reasontxt, fromdate, todate) {
    for (var i = 0; i < this.doc_sche_list.length; i++) {
      if (this.doc_sche_list[i].reason == reasontxt && this.doc_sche_list[i].from_date == fromdate && this.doc_sche_list[i].to_date == todate){
        this.doc_sche_list.splice(i, 1);
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp.toString() + 'usercontroller/updocavail/',
        JSON.stringify({
          doc_reg_id:this.userinfo.user_id,
          doc_avail: this.doc_sche_list
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json()
            if (obj["key"] != null) {
              if (obj["key"] == "1") {
                this.toastr.success(Message_data.dataSavedSuccess);
              }
            }
          },
          error => {
              this.toastr.error(Message_data.defaultErr);
          });
      }
    }
  }
}
