import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { DatePipe } from '@angular/common';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatExpansionModule } from '@angular/material/expansion';
import { RouterModule, Routes } from '@angular/router';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';

import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { MatListModule } from '@angular/material/list';
import { ToastrModule, ToastNoAnimation, ToastNoAnimationModule } from 'ngx-toastr';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxPrintModule } from 'ngx-print';
import { AngularSplitModule } from 'angular-split';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatSliderModule } from '@angular/material/slider';
import { MatDialogModule } from '@angular/material/dialog';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { DataTablesModule } from 'angular-datatables';
import { CommonModule } from '@angular/common';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { BrowserModule } from '@angular/platform-browser';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { ChangePasswordModule } from '../common-module/change-password/change-password.module';
import { TranslateModule } from '@ngx-translate/core';
import { QuillModule } from 'ngx-quill';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { AdminViewComponent } from './admin-view/admin-view.component';
import { SalesReportModule } from './sales-report-gst/sales-report-gst.module';
import { AccessRightsComponent } from './access-rights/access-rights.component';
import { TimelineReportComponent } from './timeline-report/timeline-report.component';
import { InventoryConfigComponent } from './inventory-config/inventory-config.component';
import { InsuranceproviderComponent } from './insuranceprovider/insuranceprovider.component';
import { InsuranceproviderEditComponent } from './insuranceprovider-edit/insuranceprovider-edit.component';
import { InsuranceproviderListComponent } from './insuranceprovider-list/insuranceprovider-list.component';
import { InsuranceproviderDetailsComponent } from './insuranceprovider-details/insuranceprovider-details.component';
// import { PreauthorizationFormComponent } from './preauthorization-form/preauthorization-form.component';
import { AdminHomeComponent } from './admin-home/admin-home.component';

@NgModule({
  declarations: [
    AdminViewComponent,
    AccessRightsComponent,
    TimelineReportComponent,
    InventoryConfigComponent,
    InsuranceproviderComponent,
    InsuranceproviderEditComponent,
    InsuranceproviderListComponent,
    InsuranceproviderDetailsComponent,
    AdminHomeComponent,
   //// PreauthorizationFormComponent,
    //  SalesReportGstComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    MatSliderModule,
    RouterModule,
    MatSelectModule,
    MatOptionModule,
    ScrollingModule,
    MatButtonModule,
    MatIconModule,
    MatGridListModule,
    MatDividerModule,
    MatInputModule,
    MatFormFieldModule,
    HttpClientModule,
    HttpModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatListModule,
    MatExpansionModule,
    MatRadioModule,
    MatTabsModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTableModule,
    NgxPrintModule,
    AngularSplitModule,
    MatCardModule,
    MatDialogModule,
    DataTablesModule,
    CarouselModule,
    NgbModule,
    MatButtonToggleModule,
    ChangePasswordModule,
    TranslateModule,
    TabsModule,
    // PharmaModule,
    // FrontDeskModule,
    // DoctorModule,
    // CustomeCommonModule,
    // DiagnosisModule,
    SalesReportModule,
    QuillModule.forRoot(),
    DataTablesModule.forRoot(),
    MDBBootstrapModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 1000,
      positionClass: 'toast-top-center'
    }),
    ToastNoAnimationModule.forRoot(),
  ],
  exports: [
    BrowserModule,
    CommonModule,
    MatDialogModule,
    MatNativeDateModule,
    TranslateModule,
    SalesReportModule,
    AccessRightsComponent,
    TimelineReportComponent,
    InventoryConfigComponent,
    InsuranceproviderComponent,
    InsuranceproviderDetailsComponent,
    InsuranceproviderEditComponent,
    InsuranceproviderListComponent,
    AdminHomeComponent,
  //PreauthorizationFormComponent
],
  providers: [DatePipe],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
})
export class AdminModule { }
