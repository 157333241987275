<div mat-dialog-title class="popup_border_icd">
    <h5 class="header_txt">Prescription details</h5>
    <div mat-dialog-actions>
        <img src="../../../assets/img/close_icon.png" (click)="close()" width="20px" height="20px"
            id="close_icd" />
    </div>
</div>
<div mat-dialog-content class="med_content">
    <div class="container">
        <div class="row">
            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 ">
                <mat-label>Medicare type
                    <select class="ipcss" required [(ngModel)]="medicareName">
                        <option [ngValue]="Select">Select</option>
                        <option *ngFor="let meditype of medicareList"> {{meditype.medicare_name}}</option>
                    </select>
                </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 ">
                <mat-label>Medicine type
                    <select class="ipcss" required (change)="changeMededicineType()" [(ngModel)]="medicineType">
                        <option [ngValue]="Select">Select</option>
                        <option *ngFor="let medtype of medicineTypeList"> {{medtype.med_name}}</option>
                    </select>
                </mat-label>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 ">
                <mat-label class="matlabel">Medicine name
                    <input type="text" class="ipcss widthappt" required (keyup)="changeMedicineName()"
                        (focus)="clearMedicineName()" maxlength="50" [(ngModel)]="medicineName" matInput
                        [matAutocomplete]="auto1" />
                    <mat-autocomplete #auto1="matAutocomplete">
                        <mat-option (click)="selectMedicineName(medicine)" *ngFor="let medicine of medicineNameList"
                            value="{{medicine}}">
                            {{medicine}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 ">
                <mat-label class="matlabel">Duration</mat-label>
                <div class="row">
                    <div class="col-6">
                        <input class="ipcss" required maxlength="5"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57" [(ngModel)]="day"
                            matInput>
                    </div>
                    <div class="col-6">
                        <select class="ipcss" [(ngModel)]="dayduration">
                            <option [ngValue]="Select">Select</option>
                            <option value="day(s)">day(s)</option>
                            <option value="week(s)">week(s)</option>
                            <option value="month(s)">month(s)</option>
                        </select>
                    </div>
                </div>
            </div>
            <div [hidden]="intakeFlag" class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 ">
                <div class="row">
                    <div class="col-6">
                        <mat-label class="matlabel">Intake<br>
                            <select class="ipcss" (change)="changeIntakeFood()" [(ngModel)]="intakeFood">
                                <option [ngValue]="Select">Select</option>
                                <option>Empty stomach</option>
                                <option>After food</option>
                                <option>Before food</option>
                                <option>With food</option>
                            </select>
                        </mat-label>
                    </div>
                    <div [hidden]="durationFlag" class="col-6">
                        <mat-label class="matlabel">Minutes
                            <input class="ipcss" onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                maxlength="3" [(ngModel)]="duration" matInput />
                        </mat-label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 ">
                <mat-label class="matlabel">Intake session
                    <select class="ipcss" (change)="changeIntakeSession()" required [(ngModel)]="intakeSession">
                        <option [ngValue]="Select">Select</option>
                        <option *ngFor="let intake of intakeSessionList"> {{intake.description}}</option>
                    </select>
                </mat-label>
            </div>
            <div [hidden]="mornEvenFlag" class="col-3 col-sm-2 col-md-1 col-lg-1 col-xl-1" style="padding: 0px;">
                <mat-label [hidden]="morningFlag" class="matlabel">Mor
                    <input class="ipcss" [required]="morningRequiredFlag" maxlength="1"
                        onkeypress="return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)"
                        maxlength="3" [disabled]="morningDisabledFlag" [(ngModel)]="mor" matInput />
                </mat-label>
                <mat-label [hidden]="qtyFlag" class="matlabel">Qty
                    <input class="ipcss" [required]="morningRequiredFlag" maxlength="1"
                        onkeypress="return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)"
                        maxlength="3" [disabled]="morningDisabledFlag" [(ngModel)]="mor" matInput />
                </mat-label>
            </div>
            <div [hidden]="morHypenFlag" class="col-1 col-sm-2 col-md-1 col-lg-1 col-xl-1">
                <span style="position: relative;top: 27px;">-</span>
            </div>
            <div [hidden]="afternoonFlag" class="col-3 col-sm-2 col-md-1 col-lg-1 col-xl-1" style="padding: 0px;">
                <mat-label class="matlabel">Aft
                    <input class="ipcss" [disabled]="afternoonDisabledFlag" maxlength="1"
                        onkeypress="return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)"
                        maxlength="3" [required]="afternoonRequiredFlag" [(ngModel)]="aft" matInput />
                </mat-label>
            </div>
            <div [hidden]="aftHypenFlag" class="col-1 col-sm-2 col-md-1 col-lg-1 col-xl-1">
                <span style="position: relative;top: 27px;">-</span>
            </div>
            <div [hidden]="eveEvenFlag" class="col-3 col-sm-2 col-md-1 col-lg-1 col-xl-1" style="padding: 0px;">
                <mat-label class="matlabel">Eve
                    <input class="ipcss" [disabled]="evenDisabledFlag" [required]="eveningRequiredFlag" maxlength="1"
                        onkeypress="return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)"
                        maxlength="3" [(ngModel)]="eve" matInput />
                </mat-label>
            </div>
            <div [hidden]="eveHypenFlag" class="col-1 col-sm-2 col-md-1 col-lg-1 col-xl-1">
                <span style="position: relative;top: 27px;">-</span>
            </div>
            <div [hidden]="nightEvenFlag" class="col-3 col-sm-2 col-md-1 col-lg-1 col-xl-1" style="padding: 0px;">
                <mat-label class="matlabel">Ngt
                    <input class="ipcss" [disabled]="nightDisabledFlag" [required]="nightRequiredFlag" maxlength="1"
                        onkeypress="return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)"
                        maxlength="3" [(ngModel)]="ngt" matInput />
                </mat-label>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <img src="../../../assets/ui_icons/buttons/add_button.svg" width="80" height="27"
                    class="" (click)="addMedicine()" style="float: right;" />
            </div>
            <div class="col-12" [hidden]="listProductsFlag">
                <div *ngIf="listProducts.length !=0 " style="margin-bottom: 10px;"></div>
                <table id="tbl" *ngIf="listProducts.length !=0 ">
                    <tr>
                        <th>Medicine type</th>
                        <th>Medicine name</th>
                        <th>Days</th>
                        <th>Intake</th>
                        <th>Duration</th>
                        <th>Action</th>
                    </tr>
                    <tr *ngFor="let product of listProducts">
                        <td>{{product.med_typetxt}}</td>
                        <td>{{product.drug_name}}</td>
                        <td>{{product.days}}</td>
                        <td>{{product.every_six}}</td>
                        <td>{{product.dure_txt_table + " " + product.drug_intake}}</td>
                        <td>
                            <a (click)="deleteMedicine(product.drug_id)"><img
                                    src="../../../assets/ui_icons/buttons/trash_icon.svg" class="editDelicon1" /></a>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions align="end" style="width: auto;right: 19px;">
    <button [hidden]="doneFlag" id="add_btn" [mat-dialog-close]="listProducts" cdkFocusInitial mat-raised-button>
        <img src="../../../assets/ui_icons/buttons/Done_button.svg" class="addimgbtn_icd" />
    </button>
</div>