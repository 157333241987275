<div class="container-fluid g-0" style="height: 100%;">
    <div class="warpper_div">
        <div class="asied_section" #asied_section_width>
            <section id="aside_section">
                <div class="logopanel" id="logo" #logo>
                    <h1>
                        <a (click)="menuNav('appointment');"></a>
                    </h1>
                </div>
                <div class="menu_list">
                    <div [hidden]="homecareFlag" class="menu menu_active" id="appointment"
                        (click)="menuNav('appointment');appointments('app')">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/appointment.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                            <span>Appointments</span><span style="float: right;padding-right: 7px;"> <i class="fa fa-angle-right"></i></span>
                        </div>
                    </div>
                
                    <div class="menu" [hidden]="eventIconFlag" id="homecare"
                        (click)="menuNav('homecare');appointments('home')">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/home.svg" /></i>
                        </div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                            <span>Homecare </span><span style="float: right;padding-right: 7px;" ><i class="fa fa-angle-right"></i></span>
                        </div>
                    </div>
                    <div class="menu" id="assignmentList" [hidden]="eventIconFlag" (click)="menuNav('assignmentList');">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/Schedule.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                            <span>Assignments</span><span style="float: right;padding-right: 7px;" ><i class="fa fa-angle-right"></i></span>
                        </div>
                    </div>
                    <div class="menu" id="therapies" [hidden]="isAdmin" (click)="menuNav('therapies');">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/doctor.svg" /></i>
                        </div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                            <span>Therapies </span><span style="float: right;padding-right: 7px;"><i class="fa fa-angle-right"></i></span>
                        </div>
                    </div>
                    <div [hidden]="walkinFlag" class="menu" id="walkin" (click)="menuNav('walkin');">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/doctor.svg" /></i>
                        </div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                            <span>Walkin </span><span style="float: right;padding-right: 7px;"><i class="fa fa-angle-right"></i></span>
                        </div>
                    </div>
                    <div class="menu" id="billing" [hidden]="isAdmin" (click)="menuNav('billing');">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/billing.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                            <span>Billing</span><span style="float: right;padding-right: 7px;"> <i class="fa fa-angle-right"></i></span>
                        </div>
                    </div>
                    <div [hidden]="reference_video" class="menu" id="reference_video"
                        (click)="menuNav('reference_video');">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/reference_video.png" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined"> <span>
                                Reference
                                video</span><span style="float: right;padding-right: 7px;" ><i class="fa fa-angle-right"></i></span>
                            <div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div class="right_content_section" #right_section_width>
            <section id="headar_section">
                <div class="header-left" style="position: relative;">
                    <div class="topnav">
                        <div class="openbtn_desktop" (click)="minimizing()"><i class="fas fa-bars"></i></div>
                        <div class="openbtn" (click)="openMenumodel()"><i class="fas fa-bars"></i></div>
                        <div class="hospital_logo">
                            <img class="hospital_logo" src="{{hospitalLogo}}" alt="user image">
                        </div>
                        <!-- <i class="far fa-bell"></i> 
                        <i class="far fa-paper-plane"></i>-->
                        <div class="hospital_name">{{hospitalName}}</div>
                        <div class="bell"></div>
                        <div class="share"></div>
                        <div class="doctor_profile_pc" data-bs-toggle="modal" data-bs-target="#exampleModal"><img
                                _ngcontent-ore-c119="" src="{{profileImage}}"
                                onerror="this.src='./././assets/img/default.jpg'"> </div>
                        <div class="doctor_name" data-bs-toggle="modal" data-bs-target="#exampleModal">
                            {{firstName}}&nbsp;{{lastName}}</div>
                        <!-- Modal -->
                        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                            aria-hidden="true">
                            <div class="modal-dialog profile_dialog">
                                <div class="modal-content">
                                    <div class="modal-header" style="display: inline-block;text-align: center">
                                        
                                        <div class="modal-title" id="exampleModalLabel"><img class="model_profile"
                                                src="{{profileImage}}"
                                                onerror="this.src='./././assets/img/default.jpg'"></div>
                                    </div>
                                    <div class="modal-body">
                                        <div class="menu_div" (click)="menuModelAction('profile');"> <i
                                                class="far fa-user"></i> <span class="header_model_menu">My
                                                Profile</span> </div>
                                        <div class="menu_div" (click)="menuModelAction('changePassword');"><i
                                                class="fas fa-cog"></i><span class="header_model_menu">Change
                                                Password</span></div>
                                        <div class="menu_div" (click)="menuModelAction('logout');"
                                            style="border-bottom: none;"><i class="fas fa-sign-out-alt"></i><span
                                                class="header_model_menu">Log Out</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="body_section">
                <div class="body_warpper">
                    <div class="card shadow-lg">
                        <div [ngSwitch]="displayPage">
                            <div *ngSwitchDefault="'appointment'">
                                <app-physio-appointments></app-physio-appointments> 
                            </div>
                            <div *ngSwitchCase="'appointment'">
                                <app-physio-appointments></app-physio-appointments>
                            </div>
                            <div *ngSwitchCase="'casesheet'">
                                <app-physio-casesheet></app-physio-casesheet>
                            </div>
                            <div *ngSwitchDefault="'Home'">
                                <app-physio-prof-dash></app-physio-prof-dash>
                            </div>
                            <div *ngSwitchCase="'assignmentList'">
                                <app-physio-assign-list></app-physio-assign-list>
                            </div>
                            <div *ngSwitchCase="'assignmentDetailPage'">
                                <app-physio-assign-view></app-physio-assign-view>
                            </div>
                            <div *ngSwitchCase="'therapies'">
                                <app-physio-therapies></app-physio-therapies>
                            </div>
                            <div *ngSwitchCase="'userList'">
                                <app-physio-user-list></app-physio-user-list>
                            </div>
                            <div *ngSwitchCase="'userViewPage'">
                                <app-physio-user-view></app-physio-user-view>
                            </div>
                            <div *ngSwitchCase="'Home'">
                                <app-physio-prof-dash></app-physio-prof-dash>
                            </div>
                            <div *ngSwitchCase="'billing'">
                                <app-physio-billlist></app-physio-billlist>
                            </div>
                            <div *ngSwitchCase="'walkin'">
                                <app-walking></app-walking>
                            </div>
                            <div *ngSwitchCase="'billdetails'">
                                <app-physio-bill-details></app-physio-bill-details>
                            </div>
                            <div *ngSwitchCase="'billCreate'">
                                <app-physio-bill-create></app-physio-bill-create>
                            </div>
                            <div *ngSwitchCase="'reference_video'">
                                <app-reference-video></app-reference-video>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <footer id="footer_section">
                <div class="footer_left">Copyright © 2021 All rights reserved </div>
                <div class="footer_right">
                    <p class="pull-right sm-pull-reset">
                        <span>
                            <a href="#" class="m-r-10">Support</a> | <a href="#" class="m-l-10 m-r-10">Terms of use</a>
                            | <a href="#" class="m-l-10">Privacy Policy</a>
                        </span>
                    </p>
                </div>
            </footer>
        </div>
    </div>
</div>