<mat-accordion class="mataccordion">
    <mat-expansion-panel  class="expandpanel"(opened)="panelOpenState16 = true"
    (closed)="panelOpenState16 = false">
       <mat-expansion-panel-header class="exppanel width">
          <mat-panel-title class="title ">
            <span class=""> 
             Birth history </span>
            
          </mat-panel-title>
       </mat-expansion-panel-header>
     
       <div  [hidden]="birthTab ">
           <div class="row"  [hidden]="Birth_First_Div">
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" >
                 <mat-label class="matlabel">Duration Of gestation<br>
                <input matInput class="ipcss" maxlength="3" [required]="birth_du_gest_req" (change)="Birth_change()" [disabled]="client" [(ngModel)]="birth_dur_ges" onkeypress="return event.charCode >= 48 && event.charCode <= 57"/>
            </mat-label>
           </div>
           <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                 
            <mat-label class="matlabel">Mother’s age<br>
                <input matInput class="ipcss "  maxlength="2" [required]="birth_mot_age_req" (change)="Birth_change()" [disabled]="client"
                [(ngModel)]="birth_mother_age" onkeypress="return event.charCode >= 48 && event.charCode <= 57"/>
            </mat-label>  
             </div>
             <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                   
                <mat-label class="matlabel">Parity times<br>
                    <input matInput class="ipcss " maxlength="2" [required]="birth_parity_times_req" (change)="Birth_change()" [disabled]="client"
                    [(ngModel)]="birth_parity_times" onkeypress="return event.charCode >= 48 && event.charCode <= 57"/>
                </mat-label> 
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Maternal insults<br>
                    <textarea class="ipcss " #myInput id="myInput" (change)="Birth_change()" [(ngModel)]="birth_mate_ill" [disabled]="client"maxlength="50"></textarea>
                </mat-label>     
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Where born<br>
                    <select class="ipcss "  [(ngModel)]="birth_wh_born" [required]="birth_wh_born_req" (selectionChange)="Birth_change()" disableOptionCentering [disabled]="client">
                        <option disabled>Select</option>
                        <option value="Clinic">Clinic</option>
                        <option value="Hospital">Hospital</option>
                        <option value="Home">Home</option>
                    </select>
                </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">

                <mat-label class="matlabel">Resuscitation<br>
                    <textarea class="ipcss " #myInput id="myInput" (change)="Birth_change()" [(ngModel)]="birth_res" maxlength="50" [disabled]="client"></textarea>
                </mat-label>    
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Difficulties in delivery<br>
                    <textarea class="ipcss " #myInput id="myInput" (change)="Birth_change()" [(ngModel)]="birth_dif_deli" maxlength="50" [disabled]="client"></textarea>
                </mat-label>  
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                 
                <mat-label class="matlabel">Diseases<br>
                    <textarea class="ipcss " #myInput id="myInput" (change)="Birth_change()" [(ngModel)]="birth_dis" maxlength="50" [disabled]="client"></textarea>
                </mat-label>     
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Apgar score at birth<br>
                    <input matInput class="ipcss " maxlength="2" (change)="Birth_change()"
                    [(ngModel)]="birth_apg_score"  onkeypress="return event.charCode >= 48 && event.charCode <= 57" [disabled]="client"/>
                </mat-label>    
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Intensive care requirement<br>
                    <textarea class="ipcss " #myInput id="myInput" (change)="Birth_change()" [(ngModel)]="birth_int_score" maxlength="50" [disabled]="client"></textarea>
                </mat-label> 
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                   
                <mat-label class="matlabel">How the baby was fed in first few days<br>
                    <textarea class="ipcss " #myInput id="myInput"  [required]="birth_baby_fed_req" (change)="Birth_change()" [(ngModel)]="birth_baby_fed" maxlength="250" [disabled]="client"></textarea>
                </mat-label>
            </div>
         
                <div class="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                    <div calss="row">
                        <div class="col-10">
                            <mat-label class="matlabel">Child went home</mat-label>
                        </div> 
                        <div class="col-1">
                            <mat-button-toggle id="toggid12" class="togglebtn" [disabled]="client"
                            [(ngModel)]="child_wtxt1" ngDefaultControl [checked]="child_wtxt1"
                            (change)="changechildwenthome($event.source.checked)" [ngStyle]="child_wtxt1 && { 'background' : 'green' } || !child_wtxt1 && { 'background' : 'red' }">
                           <span class="familytab" *ngIf="!child_wtxt1">No</span>
                           <span class="familytab" *ngIf="child_wtxt1">Yes</span>
                        </mat-button-toggle>
                        </div>
                    </div>
                
                     
                        <!-- <button  id="matbtn"  *ngIf="ynopt12"    [(ngModel)]="child_wtxt" [style.color]="getColor('Yes')" [ngStyle]="{'border':'1px solid green'}" mat-raised-button (click)="option(12)">Yes</button>
                        <button id="matbtn"  *ngIf="!ynopt12"   [style.color]="getColor('No')" [ngStyle]="{'border':'1px solid red'}" [(ngModel)]="child_wtxt" mat-raised-button (click)="option(12)">No</button>
                        -->
            
            </div>
                <div class="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                    <mat-label class="matlabel">Reason<br>
                        <textarea class="ipcss " #myInput id="myInput"  [disabled]="client" [required]="birth_reason_req" (change)="Birth_change()" [(ngModel)]="birth_reason" maxlength="250"></textarea>
                    </mat-label>  
                </div>
           </div>
            <div class="row" [hidden]="Post_Natal_div">
                <div class="col-12">
                    <p><strong>Post natal</strong></p><br>
                </div>
            </div>
            <div class="row" [hidden]="Post_Natal_div">
                <div class="col-6 col-sm-4 col-md-4 col-lg-3 col-xl-3">
                    <p>Breast feeding</p>
                </div>
                <div class="col-3 col-sm-4 col-md-4 col-lg-3 col-xl-3">
                    <mat-label class="matlabel">Duration<br>
                        <textarea #matInput class="ipcss "   maxlength="10"    [required]="birth_bre_du_req" 
                        [(ngModel)]="breast_fed_dur_txt" [disabled]="client"></textarea>
                    </mat-label>  
                </div>
                <div class="col-3 col-sm-4 col-md-4 col-lg-3 col-xl-3">
                    <mat-label class="matlabel">Frequency<br>
                        <textarea matInput class="ipcss " maxlength="10"  [disabled]="client" [required]="birth_bre_frq_req"
                        [(ngModel)]="breast_fed_freq_txt"></textarea>
                    </mat-label>
                </div>
              
            </div>
            <div class="row">
                <div class="col-3 col-sm-4 col-md-4 col-lg-3 col-xl-3">
                    <p>Bottle feeding</p>
                </div>
                <div class="col-3 col-sm-4 col-md-4 col-lg-3 col-xl-3">
                    <mat-label class="matlabel">Duration<br>
                        <textarea matInput class="ipcss "  maxlength="10" [disabled]="client" [required]="birth_bot_du_req" (change)="Birth_change()"
                        [(ngModel)]="bottle_fed_dur_txt"></textarea>
                    </mat-label>  
                </div>
                <div class="col-3 col-sm-4 col-md-4 col-lg-3 col-xl-3">
                   <!-- //                        <input matInput class="ipcss "  type="number" max="10" [required]="birth_bot_frq_req" (change)="Birth_change()" -->
     
                    <mat-label class="matlabel">Frequency<br>
                        <textarea matInput class="ipcss " [disabled]="client"  maxlength="10"  [required]="birth_bot_frq_req" (change)="Birth_change()"
                        [(ngModel)]="bottle_fed_freq_txt"></textarea>
                    </mat-label>   
                </div>
              
            </div>
            <div class="row">
                <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                    <mat-label class="matlabel">Weaned out<br>
                        <input matInput class="ipcss "  type="text" [disabled]="client"   maxlength="10" (change)="Birth_change()" [(ngModel)]="weaned_out_txt"/>
                    </mat-label>     
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                    <mat-label class="matlabel">Childhood diseases<br>
                        <select class="ipcss "  [(ngModel)]="child_hood_dis" [disabled]="client" (change)="Birth_change()" disableOptionCentering >
                            <option disabled>Select</option>
                            <option *ngFor="let child of child_hood_dis_array" value={{child.childhood_id}}>{{child.description}}</option>
                        </select>
                    </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                    <mat-label class="matlabel">Type of nipple used<br>
                        <select class="ipcss "  [(ngModel)]="type_nipple_txt" [disabled]="client" [required]="birth_type_nipple_req" (change)="Birth_change()" disableOptionCentering >
                            <option disabled>Select</option>
                            <option value="Traditional">Traditional</option>
                            <option value="Orthodontic">Orthodontic</option>
                            <option value="Flat-Topped">Flat-Topped</option>
                        </select>
                    </mat-label>
                </div>              
            </div>        

                  
       </div>
    </mat-expansion-panel>
 </mat-accordion>