import { Component, OnInit ,ElementRef,Input,ViewEncapsulation,OnDestroy,Inject} from '@angular/core';
// import { LoggingService } from '../logging.service';
// import {ipaddress.getIp,Ip_with_img_address,country_code} from '../../../assets/js/ipaddress.js';
import { Http, Headers } from '@angular/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import {Helper_Class} from '../../helper_class';
import { ToastrService } from 'ngx-toastr'
import { CommonDataService } from '../../providers/common-data.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Data, Route, Router } from '@angular/router';
import { ipaddress } from '../../ipaddress';

@Component({
  selector: 'app-filterpage',
  templateUrl: './filterpage.component.html',
  styleUrls: ['./filterpage.component.css']
})
export class FilterpageComponent implements OnInit {
  locationArray = [];
  location: any;
  lcoationID:any;
  public userInfo;
  @Input() id: string;parm: string;  
  public element: any;
public cancel; 
public imei;
constructor(public comm_Data:CommonDataService,private el: ElementRef, toastr:ToastrService,public router:Router,public  http:Http,public dialog: MatDialog ,public dialogRef: MatDialogRef<FilterpageComponent>,
  @Inject(MAT_DIALOG_DATA) public data: any) {
    dialogRef.disableClose = true;
    
  }
  onNoClick(){
    var filter_close=({
      selectedtext: this.location,
      selectid:this.lcoationID
    })
   var data=this.lcoationID
   this.dialogRef.close(data)
   // this.dialogRef.close(this.locationtxt)
  }
  ngOnInit(): void {
    this.userInfo=Helper_Class.getInfo();
    this.imei=Helper_Class.getIPAddress();
    this.pharmacyBranchList();
  }
  pharmacyBranchList() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');

    this.http.post(ipaddress.getIp + "pharmacontrol/locbyregid/", JSON.stringify({
      pharma_reg_id: this.userInfo.user_id,
      imei: this.imei,
    }),
      { headers: headers }).subscribe(
        response => {
          
          //order_details
          var obj = JSON.parse(response["_body"]);

          if (obj.pharma != null) {

            for (var l = 0; l < obj.pharma.length; l++) {
              this.locationArray.push({
                value: obj.pharma[l].pharma_id,
                taxt: obj.pharma[l].location,
              });
            }
          }
        },
    )
  }
  LocationChange(e) {

    this.lcoationID=e.target.value.toString();
  }
  filter() {
    var filter_close=({
      selectedtext: this.location,
      selectid:this.lcoationID
    })
   this.dialogRef.close(this.lcoationID)
    // this.view.dismiss({
    //   selectedtext: this.locationtxt,
    //   selectid:this.lcoationid
    // });

  }
  closePopup() {
    this.dialogRef.close(this.lcoationID)

  }
}
