
var canvas, flag = false,
    prevX = 50,
    currX = 50,
    prevY = 50,
    currY = 50,
    dot_flag = false;
var imageOpacity = 1.33;

function load_img(data_url) {
    var img = new Image();
    //img.setAttribute('crossOrigin', 'anonymous');

    img.src = data_url;
    img.width = 200;
    img.height = 235;

    canvas = document.getElementById('can');
    ctx = canvas.getContext("2d");

    w = canvas.width;
    h = canvas.height;
    canvas.width = canvas.width = img.width;
    canvas.height = img.height;

    img.onload = function () {
        drawTheImage(img, imageOpacity);
    }

    canvas.addEventListener('mousedown', function (event) {
        ctx.beginPath();
        canvas.addEventListener('mousemove', draw_new);
    });

    canvas.addEventListener('mouseup', function () {
        ctx.beginPath();
        canvas.removeEventListener('mousemove', draw_new);
    });

    function drawTheImage(img, opacity) {
        ctx.globalAlpha = opacity;
        ctx.drawImage(img, 0, 0); // set width,height
        ctx.globalAlpha = 1.00;
    }

    function draw() {
        ctx.beginPath();
        ctx.moveTo(prevX, prevY);
        ctx.lineTo(currX, currY);
        ctx.strokeStyle = "black";
        ctx.lineWidth = 2;
        ctx.stroke();
        ctx.closePath();
    }

    function draw_new() {
        ctx.lineTo(event.offsetX, event.offsetY);
        ctx.strokeStyle = "black";
        ctx.lineWidth = 2;
        ctx.stroke();
    }

    function findxy(res, e) {
        if (res == 'down') {
            prevX = currX;
            prevY = currY;
            currX = e.clientX - canvas.offsetLeft;
            currY = e.clientY - canvas.offsetTop;

            flag = true;
            dot_flag = true;
            if (dot_flag) {
                ctx.beginPath();
                ctx.fillStyle = "black";
                ctx.fillRect(currX, currY, 2, 2);
                ctx.closePath();
                dot_flag = false;
            }
        }

        if (res == 'up' || res == "out") {
            flag = false;
        }

        if (res == 'move') {
            if (flag) {
                prevX = currX;
                prevY = currY;
                currX = e.clientX - canvas.offsetLeft;
                currY = e.clientY - canvas.offsetTop;
                draw();
            }
        }
    }
}
exports.load_img = load_img;

function clear_click() {
    load_img();
}

function clear_click_neph(imageurl) {
    // alert("image "+imageurl);
    load_img(imageurl);
}
exports.clear_click = clear_click;

function green_click() {
    canvas.addEventListener('mousedown', function (event) {
        ctx.moveTo(event.offsetX, event.offsetY);

        canvas.addEventListener('mousemove', function (event) {
            ctx.strokeStyle = "rgb(192,0,0)";
            ctx.lineWidth = 2;
            ctx.stroke();
        });
    });

    canvas.addEventListener('mouseup', function () {
        canvas.removeEventListener('mousemove', function () {
            ctx.strokeStyle = "rgb(192,0,0)";
            ctx.lineWidth = 2;
            ctx.stroke();
        });
    });
}
exports.green_click = green_click;


function blue_click() {
    canvas.addEventListener('mousedown', function (event) {
        ctx.moveTo(event.offsetX, event.offsetY);

        canvas.addEventListener('mousemove', function (event) {
            ctx.strokeStyle = "rgb(153,0,51)";
            ctx.lineWidth = 2;
            ctx.stroke();
        });
    });

    canvas.addEventListener('mouseup', function () {
        canvas.addEventListener('mousemove', function (event) {
            ctx.strokeStyle = "rgb(153,0,51)";
            ctx.lineWidth = 2;
            ctx.stroke();
        });
    });
}
exports.blue_click = blue_click;

function red_click() {
    canvas.addEventListener('mousedown', function (event) {
        ctx.moveTo(event.offsetX, event.offsetY);

        canvas.addEventListener('mousemove', function (event) {
            ctx.strokeStyle = "rgb(153, 51, 0)";
            ctx.lineWidth = 2;
            ctx.stroke();
        });
    });

    canvas.addEventListener('mouseup', function () {
        canvas.addEventListener('mousemove', function (event) {
            ctx.strokeStyle = "rgb(153, 51, 0)";
            ctx.lineWidth = 2;
            ctx.stroke();
        });
    });
}
exports.red_click = red_click;

function yellow_click() {
    canvas.addEventListener('mousedown', function (event) {
        ctx.moveTo(event.offsetX, event.offsetY);

        canvas.addEventListener('mousemove', function (event) {
            ctx.strokeStyle = "rgb(255, 102, 0)";
            ctx.lineWidth = 2;
            ctx.stroke();
        });
    });

    canvas.addEventListener('mouseup', function () {
        canvas.addEventListener('mousemove', function (event) {
            ctx.strokeStyle = "rgb(255, 102, 0)";
            ctx.lineWidth = 2;
            ctx.stroke();
        });
    });
}
exports.yellow_click = yellow_click;

function orange_click() {
    canvas.addEventListener('mousedown', function (event) {
        ctx.moveTo(event.offsetX, event.offsetY);

        canvas.addEventListener('mousemove', function (event) {
            ctx.strokeStyle = "rgb(38,38,38)";
            ctx.lineWidth = 2;
            ctx.stroke();
        });
    });

    canvas.addEventListener('mouseup', function () {
        canvas.addEventListener('mousemove', function (event) {
            ctx.strokeStyle = "rgb(38, 38, 38)";
            ctx.lineWidth = 2;
            ctx.stroke();
        });
    });
}
exports.orange_click = orange_click;

function black_click() {
    canvas.addEventListener('mousedown', function (event) {
        ctx.moveTo(event.offsetX, event.offsetY);

        canvas.addEventListener('mousemove', function (event) {
            ctx.strokeStyle = "rgb(127, 96, 0)";
            ctx.lineWidth = 2;
            ctx.stroke();
        });
    });

    canvas.addEventListener('mouseup', function () {
        canvas.addEventListener('mousemove', function (event) {
            ctx.strokeStyle = "rgb(127, 96, 0)";
            ctx.lineWidth = 2;
            ctx.stroke();
        });
    });
}
exports.black_click = orange_click;

function brown_click() {
    canvas.addEventListener('mousedown', function (event) {
        ctx.moveTo(event.offsetX, event.offsetY);

        canvas.addEventListener('mousemove', function (event) {
            ctx.strokeStyle = "rgb(204, 102, 0)";
            ctx.lineWidth = 2;
            ctx.stroke();
        });
    });

    canvas.addEventListener('mouseup', function () {
        canvas.addEventListener('mousemove', function (event) {
            ctx.strokeStyle = "rgb(204, 102, 0)";
            ctx.lineWidth = 2;
            ctx.stroke();
        });
    });
}
exports.brown_click = brown_click;

function gray_click() {
    canvas.addEventListener('mousedown', function (event) {
        ctx.moveTo(event.offsetX, event.offsetY);

        canvas.addEventListener('mousemove', function (event) {
            ctx.strokeStyle = "rgb(255, 80, 80)";
            ctx.lineWidth = 2;
            ctx.stroke();
        });
    });

    canvas.addEventListener('mouseup', function () {
        canvas.addEventListener('mousemove', function (event) {
            ctx.strokeStyle = "rgb(255, 80, 80)";
            ctx.lineWidth = 2;
            ctx.stroke();
        });
    });
}
exports.gray_click = gray_click;