import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Helper_Class } from '../../helper_class';
import { Time_Formate_Session,Date_Formate } from '../../../assets/js/common.js';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { CommonDataService } from '../../providers/common-data.service';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ClientCancelAppointmentComponent } from '../client-cancel-appointment/client-cancel-appointment.component';
import { ClientViewService } from '../clientview/client-view.service';
import { Client_Helper } from '../Client_helper';
import { ipaddress } from '../../ipaddress';
import { ClientVideoLinkComponent } from '../client-video-link/client-video-link.component';
import { Message_data } from 'src/assets/js/Message_data';

declare var Razorpay: any;

@Component({
  selector: 'app-doctor-appointment-detail',
  templateUrl: './doctor-appointment-detail.component.html',
  styleUrls: ['./doctor-appointment-detail.component.css'],
  providers:[DatePipe]
})
export class DoctorAppointmentDetailComponent implements OnInit {

  public personalDetails;
  public sendData;
  public hospitalFlag: boolean;
  public hospitalDetails: string;
  public apptDetail: string;
  public clntIncome: string;
  public clntFName: string;
  public clntMName: string;
  public clntLName: string;
  public clntAge: string;
  public clntMobile: string;
  public clntGender: string;
  public clntBloodGrp: string;
  public clntRelation: string;
  public clntGuardian: string;
  public clntOccupation: string;
  public clntSymptom: string;
  public clntTreatType: string;
  public doctorName: string;
  public speciality: string;
  public apptDate: string;
  public session: string;
  public tokenNo: string;
  public clntReason: string;
  public retrieveUrl: string;
  public sendRetrvData;
  public docAppId: string;
  public height: string;
  public weight: string;
  public temp: string;
  public bmi: string;
  public complaints: string;
  public illnes: string;
  public medical: string;
  public family: string;
  public phyExam: string;
  public locationDesc: string;
  public rescheduleReason: string;
  public payType: string;
  public referredBy:string;
  public rpayorderUrl;

  public heightFlag;
  public weightFlag;
  public temperatureFlag;
  public bmiFlag;
  public complaintsFlag;
  public illnessFlag;
  public medicalFlag;
  public familyFlag;
  public physicalFlag;
  public hospitalName;
  public appointmentDate;
  public currentDate;
  public currentTime;
  public availableFlag;
  public Unavailable;
  public nonAvailableFlag;
  public timeorTokenFlag;
  public appTypeTokenFlag;
  public appointmentTime;
  public visitSession;
  public availableTimeFlag;
  public appType;
  public homecareTimeList;
  public visitHomeSession;

  public visitHomeTime;
  public sendDocAvailData;
  public sendDataForDocSchedule;
  public doctorId;
  public clientId;
  public hospitalId;
  public dayOfWeek;
  public apptCancelUrl;
  public paySuccessUrl;
  public homecare;
  public status;
  public doctorFee;

  public doctorDetails = [];
  public homecareDoctorDetails = [];
  public doctorUnavailabilityDetails = [];
  public doctorAvailabilityDetails = [];
  public visitingSessionData = [];
  public homecareSessionData = [];
  public timeList = [];

  public normalRescheduleFlag: Boolean;
  public homecareRescheduleFlag: Boolean;
  public rescheduleFlag: Boolean; // reschedule btn div
  public cancelFlag: Boolean; // cancel btn div
  public rateFlag: Boolean; // rate btn div
  public payFlag: boolean; // pay btn div

  public currentMaxDate;
  public userInfo;
  public payid;
  public orderId;
  public signature;
  public clntemail;

  constructor(public messageservice:ClientViewService,public dialog: MatDialog,private gservice:CommonDataService,private datepipe:DatePipe,private http:Http,private routes:ActivatedRoute,private router:Router,public toastr:ToastrService) { 
    this.apptCancelUrl = ipaddress.getIp + "appointment/cancelapp";
    this.retrieveUrl = ipaddress.getIp + "appointment/viewapp";
    this.rpayorderUrl = ipaddress.getIp + "rpay/oid";
    this.paySuccessUrl = ipaddress.getIp + "rpay/conforder";
    this.payFlag = false;
  }

  ngOnInit(): void {
    this.userInfo = Helper_Class.getInfo();
    this.homecare = Client_Helper.getHomecare();
    this.docAppId = Client_Helper.getDoctorApptdetail().doc_app_id;
    this.status = Client_Helper.getDoctorApptdetail().status;
    
    this.sendRetrvData = {
      doc_app_id: this.docAppId,
      type: "client",
      country: ipaddress.country_code,
      home_care: this.homecare
    }

    this.getCurrentDate();
    this.getRetrvData();
  }

  getCurrentDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "adm/curdate", JSON.stringify({
      country: ipaddress.country_code
    }), { headers: headers }).subscribe(
      data => {
        this.currentDate = data.json().current_date;
        this.currentTime = data.json().current_time;
        var Date_splitter = this.currentDate.split('-');
        this.appointmentDate = (this.currentDate);
        this.currentMaxDate = (data.json().current_date);          
      },
      error => {});
  }

  getRetrvData() {
    var headers = new Headers;
    headers.append('Content-Type', 'application/json');
    this.http.post(this.retrieveUrl, JSON.stringify(this.sendRetrvData), { headers: headers }).subscribe(
      data => {
        function StringBuilder(this:any,value) {// String Builder
          this.strings = new Array("");
          this.append(value);
        }
        
        StringBuilder.prototype.append = function (value) {// Appends the given value to the end of this instance.
          if (value) {
            this.strings.push(value);
          }
        }
        
        StringBuilder.prototype.clear = function () {// Clears the string buffer
          this.strings.length = 1;
        }
        
        StringBuilder.prototype.toString = function () {// Converts this instance to a String.
          return this.strings.join("");
        }

        
        if (data.json().length != 0 && data.json() != null) {// Data set
          this.payType = data.json().payment_type;
          this.doctorFee = data.json().fee;
          this.payFlag = (this.payType =="Online" && data.json().payment_id == undefined) ? true : false;
          this.clntFName = encrypt_decript.Decript(data.json().first_name);
          this.clntLName = encrypt_decript.Decript(data.json().last_name);

          if (data.json().middle_name != null) {
            this.clntMName = encrypt_decript.Decript(data.json().middle_name);
          } else {
            this.clntMName="";
          }

          this.clntAge = (data.json().age != null) ? data.json().age : "";

          if (data.json().symptoms != null)
            this.clntSymptom = (data.json().symptoms);

          if (data.json().mobile != null) 
            this.clntMobile = encrypt_decript.Decript(data.json().mobile);

          if(data.json().email !=null){
            this.clntemail=encrypt_decript.Decript(data.json().email);
          }
          if (data.json().dob != null) {
            var dateSplit = (data.json().dob).split("-");
            this.apptDate = dateSplit[2] + "-" + dateSplit[1] + "-" + dateSplit[0];
          }

          if (data.json().blood_desc != null) 
            this.clntBloodGrp = (data.json().blood_desc);

          if (data.json().relation != null) 
            this.clntRelation = (data.json().relation);

          if (data.json().occupation != null) 
            this.clntOccupation = encrypt_decript.Decript(data.json().occupation);
          
          if (data.json().income != null) 
            this.clntIncome = encrypt_decript.Decript(data.json().income);
          
          if (data.json().guardian != null) 
            this.clntGuardian = encrypt_decript.Decript(data.json().guardian);
          
          if (data.json().gender != null) 
            this.clntGender = encrypt_decript.Decript(data.json().gender);
          
          if (data.json().specialization != null) 
            this.speciality = data.json().specialization;
          
          if (data.json().medicare_name != null) 
            this.clntTreatType = data.json().medicare_name;

          if (data.json().token_count != false) 
            this.tokenNo = data.json().token

          if (data.json().dr_middle_name != undefined) {
            this.doctorName = "Dr." + data.json().dr_first_name + ' ' + data.json().dr_middle_name + ' ' + data.json().dr_last_name;
          } else {
            this.doctorName = "Dr." + data.json().dr_first_name + ' ' + data.json().dr_last_name;
          }

          var sb = new StringBuilder("");
          if ((data.json().hptl_name) != null) {
            this.hospitalFlag = true;
            this.hospitalName = data.json().hptl_name;

            sb.append((data.json().hptl_name));
            sb.append("\n");
            sb.append((data.json().address1));
            sb.append("\n");

            if ((data.json().address1) != null && ((data.json().address2)) != "") {
              sb.append((data.json().address2));
              sb.append("\n");
            }
            sb.append(data.json().location);
            sb.append("\n");
            sb.append(data.json().city + "-" + (data.json().zipcode));
            sb.append("\n");
            sb.append(data.json().state);
            sb.append("\n");
            sb.append(data.json().country);
            sb.append("\n");
            sb.append((data.json().telephone));
            this.hospitalDetails = sb.toString();
          }

          var personal = new StringBuilder("");
          if ((data.json().rel_country) != null) {
            personal.append(encrypt_decript.Decript(data.json().rel_address1));
            personal.append("\n");

            if ((data.json().rel_address1) != null && ((data.json().rel_address2)) != "") {
              personal.append(encrypt_decript.Decript(data.json().rel_address2));
              personal.append("\n");
            }
            personal.append(data.json().location_desc);
            personal.append("\n");
            personal.append(data.json().city_desc + "-" + encrypt_decript.Decript(data.json().rel_zipcode));
            personal.append("\n");
            personal.append(data.json().state_desc);
            personal.append("\n");
            personal.append(data.json().country_desc);
            personal.append("\n");
            this.personalDetails = personal.toString();
          }

          this.session = encrypt_decript.Decript(data.json().session);

          var hosp_string = new StringBuilder("");
          if (data.json().app_type != null) {
            if (data.json().app_type == "1") {
              hosp_string.append("Appointment type : New");
              hosp_string.append("\n");
            } else {
              hosp_string.append("Appointment type : Follow-up");
              hosp_string.append("\n");
            }
            
            if (data.json().date != null) {
              hosp_string.append("Date :" + Date_Formate(data.json().date));
              hosp_string.append("\n");
            }
            
            if (data.json().session != null) {
              hosp_string.append("Session :" + encrypt_decript.Decript(data.json().session));
              hosp_string.append("\n");
            }
            
            if (data.json().token_count == "true") {
              hosp_string.append("Token no :" + data.json().token);
            } else {
              hosp_string.append("Time :" + Time_Formate_Session(data.json().f_time, encrypt_decript.Decript(data.json().session)));
            }
            this.apptDetail = hosp_string.toString();
          }

          // Button hide and show
          if (data.json().status == "0" || data.json().status == "2" && data.json().token_count != "true") {
            this.cancelFlag = true;
          }
       
          if (this.status == "Open" ) {
            this.cancelFlag = true;
            if (this.homecare != "1") {
              this.rescheduleFlag = true;
            }
          }

          if (data.json().token_count == "true" && this.status == "Confirmed") {
            this.cancelFlag = true;
            this.rescheduleFlag = true;
          }

          if (data.json().status == "0" || data.json().status == "2" && data.json().token_count != "true") {
            this.cancelFlag = false;
          }

          if (data.json().status == "0" && data.json().is_canclled == "0") {
            this.cancelFlag = false;
            this.rescheduleFlag = false;
            this.rateFlag = (data.json().rating != null) ? false : true;
          }

          if (this.status != undefined) {
            if (this.status == "Not visited" || this.status == "Rejected" || this.status == "Cancelled" ||
              this.status == "Awaiting for vating" || this.status == "Completed") {
              this.cancelFlag = false;
              this.rescheduleFlag = false;
            }
          }

          if (data.json().status == "3") {
            this.cancelFlag = true;
            this.rescheduleFlag = true;
            this.rateFlag = false;
          }

          // Observation Data 
          if (data.json().temparature != null) {
            this.temperatureFlag = true;
            this.temp = encrypt_decript.Decript(data.json().temparature)
          }

          if (data.json().bmi != null && encrypt_decript.Decript(data.json().bmi) != "") {
            this.bmiFlag = true;
            this.bmi = encrypt_decript.Decript(data.json().bmi)
          }

          if (data.json().complaints != null && encrypt_decript.Decript(data.json().complaints) != "") {
            this.complaintsFlag = true;
            this.complaints = encrypt_decript.Decript(data.json().complaints)
          }

          if (data.json().present_illness != null && encrypt_decript.Decript(data.json().present_illness) != "") {
            this.illnessFlag = true;
            this.illnes = encrypt_decript.Decript(data.json().present_illness)
          }

          if (data.json().medical_history != null && encrypt_decript.Decript(data.json().medical_history) != "") {
            this.medicalFlag = true;
            this.medical = encrypt_decript.Decript(data.json().medical_history)
          }

          if (data.json().family_history != null && encrypt_decript.Decript(data.json().family_history) != "") {
            this.familyFlag = true;
            this.family = encrypt_decript.Decript(data.json().family_history)
          }

          if (data.json().physical_exam != null && encrypt_decript.Decript(data.json().physical_exam) != "") {
            this.physicalFlag = true;
            this.phyExam = encrypt_decript.Decript(data.json().physical_exam)
          }

          if (data.json().height != null && encrypt_decript.Decript(data.json().height) != "") {
            this.heightFlag = true;
            this.height = encrypt_decript.Decript(data.json().height) + " " + (data.json().height_measure)
          }

          if (data.json().weight != null) {
            this.weightFlag = true;
            this.weight = encrypt_decript.Decript(data.json().weight) + " " + (data.json().weight_measure)
          }

          if (data.json().reason != null && (data.json().reason) != "") 
            this.clntReason = (data.json().reason)

          this.doctorId = data.json().doc_id;
          this.clientId = data.json().client_reg_id;
          this.hospitalId = data.json().hptl_id;
          var date_txt = new Date(data.json().date);
          this.dayOfWeek = date_txt.getDay() + 1;
          this.locationDesc = data.json().location_desc;

          if(data.json().ref_by != undefined)
            this.referredBy ="Dr."+ data.json().ref_by;
        
        }
      }, error => {});
  }

  reschedule_App() {
    this.rescheduleFlag = false;
    if (this.homecare != "0") {// Homecare
      this.sendDataForDocSchedule = {
        doc_reg_id: this.doctorId,
        Location: this.locationDesc,
        home_care: this.homecare
      };
      this.getHMDoctorAvailSchedule();
      this.normalRescheduleFlag = false;
      this.homecareRescheduleFlag = true;
    } else { //  Normal
      this.sendDataForDocSchedule = {
        day: this.dayOfWeek,
        hptl_id: this.hospitalId,
        doc_id: this.doctorId
      };
      this.getNormalDoctorAvailSchedule();
      this.normalRescheduleFlag = true;
      this.homecareRescheduleFlag = false;
    }
  }

  Get_Doctor_Avail(current_date) {
    this.Unavailable = false;
    var today = new Date(current_date);
    this.sendDocAvailData = {
      day: today.getDay() + 1,
      hptl_id: this.hospitalId,
      doc_id: this.doctorId,
      country: ipaddress.country_code
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "appointment/getdoctime/", JSON.stringify(this.sendDocAvailData), { headers: headers }).subscribe(
      data => {
        if (data.json().non_available != null) 
          this.doctorUnavailabilityDetails = data.json().non_available;

        if (data.json().available != null) {
          if (data.json().available != null && data.json().available[0] != null) {
            this.doctorAvailabilityDetails = data.json().available;
            this.homecareSessionData = [];
            if (this.doctorAvailabilityDetails[0].token_count != "0") {
              this.appType = "system";
              this.appTypeTokenFlag = true;
            } else {
              this.appType = "time";
              this.appTypeTokenFlag = false;
            }
            this.availableFlag = true;
            this.nonAvailableFlag = false;
            this.Available_function(current_date, this.currentTime);
          } else {
            this.availableFlag = false;
            this.nonAvailableFlag = true;
          }
        }
      },error => {});
  }

  Session_Add() {
    this.visitingSessionData = [];
    var Current_Time_Split = this.currentTime.split(':');
    var appdatenew = this.appointmentDate;

    if (this.currentDate == appdatenew) {
      if (Current_Time_Split[0] < 12) {
        if (this.gservice.get_Array_Contains_string(this.homecareSessionData, "Morning") == true) {
          this.visitingSessionData.push({
            description: "Morning",
            id: "1"
          });
        }
        if (this.gservice.get_Array_Contains_string(this.homecareSessionData, "Afternoon") == true) {
          this.visitingSessionData.push({
            description: "Afternoon",
            id: "2"
          });
        }
        if (this.gservice.get_Array_Contains_string(this.homecareSessionData, "Evening") == true) {
          this.visitingSessionData.push({
            description: "Evening",
            id: "3"
          });
        }
      } else if (Current_Time_Split[0] >= 12 && Current_Time_Split[0] < 16) {
        if (this.gservice.get_Array_Contains_string(this.homecareSessionData, "Afternoon") == true) {
          this.visitingSessionData.push({
            description: "Afternoon",
            id: "2"
          });
        }
        if (this.gservice.get_Array_Contains_string(this.homecareSessionData, "Evening") == true) {
          this.visitingSessionData.push({
            description: "Evening",
            id: "3"
          });
        }
      } else {
        if (this.gservice.get_Array_Contains_string(this.homecareSessionData, "Evening") == true) {
          this.visitingSessionData.push({
            description: "Evening",
            id: "3"
          });
        }
      }
    } else {
      if (this.gservice.get_Array_Contains_string(this.homecareSessionData, "Morning") == true) {
        this.visitingSessionData.push({
          description: "Morning",
          id: "1"
        });
      }
      if (this.gservice.get_Array_Contains_string(this.homecareSessionData, "Afternoon") == true) {
        this.visitingSessionData.push({
          description: "Afternoon",
          id: "2"
        });
      }
      if (this.gservice.get_Array_Contains_string(this.homecareSessionData, "Evening") == true) {
        this.visitingSessionData.push({
          description: "Evening",
          id: "3"
        });
      }
    }
    if (this.visitingSessionData.length != 0) {
      this.visitSession = this.visitingSessionData[0].description;
      this.visitHomeSession = this.visitingSessionData[0].description;
    }
  }

  Available_function(curtdate, curttime) {
    this.availableTimeFlag = false;

    for (var i = 0; i < this.doctorAvailabilityDetails.length; i++) {
      var Cur_timesplit = curttime.split(':');
      var Current_time = new Date();
      Current_time.setHours(Cur_timesplit[0]);
      Current_time.setMinutes(Cur_timesplit[1]);
      var Avtotimesplit = this.doctorAvailabilityDetails[i].available_to.split(':');
      var Retrieved_time = new Date();
      Retrieved_time.setHours(Avtotimesplit[0]);
      Retrieved_time.setMinutes(Avtotimesplit[1]);

      if (curtdate == this.currentDate) {
        if (Current_time < Retrieved_time) {
          this.availableTimeFlag = true;
        }
      } else {
        this.availableTimeFlag = true;
      }
    }
    if (this.availableTimeFlag == false) {
      this.availableFlag = false;
      this.nonAvailableFlag = true;
      this.Unavailable = true;
    } else {
      this.availableFlag = true;
      this.nonAvailableFlag = false;
      this.visit_Session_Change(this.visitSession, curtdate);
    }
  }

  ConvertTimeformat(format, str) {
    var time = str;
    var hours = Number(time.match(/^(\d+)/)[1]);
    var minutes = Number(time.match(/:(\d+)/)[1]);
    var AMPM = time.match(/\s(.*)$/)[1];
    if (AMPM == "PM" && hours < 12) hours = hours + 12;
    if (AMPM == "AM" && hours == 12) hours = hours - 12;
    var sHours = hours.toString();
    var sMinutes = minutes.toString();
    if (hours < 10) sHours = "0" + sHours;
    if (minutes < 10) sMinutes = "0" + sMinutes;
    return (sHours + ":" + sMinutes);
  }

  check_not_available(get_sel_date, sess, f_time, t_time) {
    var Available_flag = true;
    var get_date = get_sel_date.split('-');
    var check_date = new Date(get_date[2] + "-" + get_date[1] + "-" + get_date[0]);

    if (this.doctorUnavailabilityDetails.length != 0) {
      for (var i = 0; i < this.doctorUnavailabilityDetails.length; i++) {
        if (this.doctorUnavailabilityDetails[i].full_day != null) {
          var Start_Date = new Date(this.doctorUnavailabilityDetails[i].from_date);
          var End_Date = new Date(this.doctorUnavailabilityDetails[i].to_date);
          if (check_date >= Start_Date && check_date <= End_Date) {
            Available_flag = false;
            break;
          }
        } else {
          if (sess != null) {
            // get unavialable date
            var From_Split = this.doctorUnavailabilityDetails[i].from_time.split(':');
            var To_Split = this.doctorUnavailabilityDetails[i].to_time.split(':');

            var t1 = new Date(check_date);
            t1.setHours(From_Split[0]);
            t1.setMinutes(From_Split[1]);

            var t2 = new Date(check_date); // t_time
            t2.setHours(To_Split[0]);
            t2.setMinutes(To_Split[1]);

            var get_f_time = this.gservice.get_time_form_session(f_time, this.gservice.get_seesion_ampm(sess));
            var get_t_time = this.gservice.get_time_form_session(t_time, this.gservice.get_seesion_ampm(sess))

            var get_time1 = this.ConvertTimeformat("24", get_f_time);
            var get_time2 = this.ConvertTimeformat("24", get_t_time);

            // get available date
            var From_Split1 = get_time1.split(':');
            var To_Split1 = get_time2.split(':');

            var get_f_time1:any = get_time1.split(':');
            var get_f_time2:any = get_time2.split(':');

            var f_time1 = new Date(check_date);
            f_time1.setHours(get_f_time1[0]);
            f_time1.setMinutes(get_f_time1[1]);

            var get_chk_date = this.datepipe.transform(check_date, 'dd-MM-yyyy');
            var get_f_date = this.datepipe.transform(this.doctorUnavailabilityDetails[i].from_date, 'dd-MM-yyyy');
            var get_t_date = this.datepipe.transform(this.doctorUnavailabilityDetails[i].from_date, 'dd-MM-yyyy');

            if (get_chk_date.toString() == get_f_date.toString() && sess == this.doctorUnavailabilityDetails[i].day_session || get_chk_date.toString() == get_t_date.toString() && sess == this.doctorUnavailabilityDetails[i].day_session) {
              if (f_time1 > t1 && f_time1 < t2) 
                Available_flag = false;
              
              if (f_time1 == t1 && f_time1 <= t2)
                Available_flag = false;
              
              if (f_time1 >= t1 && f_time1 == t2) 
                Available_flag = false;
              
              if (f_time1 == t1 && f_time1 == t2) 
                Available_flag = false;
            }

            if (get_chk_date.toString() == get_f_date.toString() && get_chk_date.toString() == get_t_date.toString() && sess == this.doctorUnavailabilityDetails[i].day_session) {
              if (f_time1 > t1 && f_time1 < t2) 
                Available_flag = false;

              if (f_time1 == t1 && f_time1 < t2) 
                Available_flag = false;

              if (f_time1 > t1 && f_time1 == t2) 
                Available_flag = false;

              if (f_time1 == t1 && f_time1 == t2) 
                Available_flag = false;
            }
          }
        }
      }
    }
    return Available_flag;
  }

  visit_Session_Change(session, appdate) {
    this.Unavailable = false;
    if (session != null) {
      this.timeorTokenFlag = true;
      if (this.appType == "time") {
        for (var i = 0; i < this.doctorAvailabilityDetails.length; i++) {
          if (session == this.gservice.get_Session(this.doctorAvailabilityDetails[i].day_session)) {
            var From_Split = this.doctorAvailabilityDetails[i].available_from.split(':');
            var To_Split = this.doctorAvailabilityDetails[i].available_to.split(':');
            var fromday = new Date();
            var parts = appdate.split('-');
            var curr_datetime = new Date(parts[2] + "-" + parts[1] + "-" + parts[0] + " " + this.currentTime);
            var curr_get_ftime = new Date(parts[2] + "-" + parts[1] + "-" + parts[0] + " " + this.doctorAvailabilityDetails[i].available_from);

            fromday.setHours(From_Split[0]);
            fromday.setMinutes(From_Split[1]);

            var today = new Date();
            today.setHours(To_Split[0]);
            today.setMinutes(To_Split[1]);
            this.timeList = [];

            var New_time1 = this.datepipe.transform(fromday, 'hh:mm a');
            var chk_time = this.datepipe.transform(fromday, 'hh:mm');
            var end_time = this.datepipe.transform(today, 'hh:mm');

            this.availableTimeFlag = this.check_not_available(appdate, session.description, chk_time, end_time);

            if (this.availableTimeFlag == true && this.currentDate == this.datepipe.transform(appdate, 'dd-MM-yyyy') && curr_get_ftime > curr_datetime) {
              this.timeList.push(New_time1);
            } else {
              if (this.availableTimeFlag == true && this.currentDate != this.datepipe.transform(appdate, 'dd-MM-yyyy')) {
                this.timeList.push(New_time1);
              }
            }

            while (fromday < today) {
              var Day_Now = new Date();
              if (this.currentDate == this.datepipe.transform(appdate, 'dd-MM-yyyy')) {
                var Now_Split = this.currentTime.split(':');
                Day_Now.setHours(Now_Split[0]);
                Day_Now.setMinutes(Now_Split[1]);
              } else {
                Day_Now.setHours(From_Split[0]);
                Day_Now.setMinutes(From_Split[1]);
              }
              fromday.setMinutes(fromday.getMinutes() + parseInt(this.doctorAvailabilityDetails[i].time_duration));
              var chk_time1 = this.datepipe.transform(fromday, 'hh:mm');
              var Avail_flag = this.check_not_available(appdate, session.description, chk_time1, end_time);

              if (Avail_flag == true && Day_Now <= fromday && fromday < today) {
                var New_time = this.datepipe.transform(fromday, 'hh:mm a');
                if (New_time != this.gservice.get_Timeformate(this.doctorAvailabilityDetails[i].available_to)) {
                  this.timeList.push(New_time);
                }
              }
            }
            break;
          }
        }
      }
    } else {
      this.timeorTokenFlag = false;
    }
  }

  HomeSession_Change(session, appdate) {
    this.homecareTimeList = [];
    for (var i = 0; i < this.homecareDoctorDetails.length; i++) {
      if (session == this.homecareDoctorDetails[i].day_session) {
        var From_Split = this.homecareDoctorDetails[i].available_from.split(':');
        var To_Split = this.homecareDoctorDetails[i].available_to.split(':');
        var parts = appdate.split('-');
        var curr_datetime = new Date(parts[2] + "-" + parts[1] + "-" + parts[0] + " " + this.currentTime);
        var curr_get_ftime = new Date(parts[2] + "-" + parts[1] + "-" + parts[0] + " " + this.homecareDoctorDetails[i].available_from);
        var curr_get_ttime = new Date(parts[2] + "-" + parts[1] + "-" + parts[0] + " " + this.homecareDoctorDetails[i].available_to);
        var New_time1 = this.datepipe.transform(curr_get_ftime, 'hh:mm a');

        if (this.currentDate == this.datepipe.transform(appdate, 'dd-MM-yyyy')) { //&& $scope.curr_get_ftime > $scope.curr_datetime
          this.homecareTimeList.push(New_time1);
        } else {
          if (this.currentDate != this.datepipe.transform(appdate, 'dd-MM-yyyy')) {
            this.homecareTimeList.push(New_time1);
          }
        }

        var substr = this.homecareDoctorDetails[i].Time_Inter;
        while (curr_get_ftime < curr_get_ttime) {
          var Day_Now = new Date();
          if (this.currentDate == this.datepipe.transform(appdate, 'dd-MM-yyyy')) {
            var Now_Split = this.currentTime.split(':');
            Day_Now.setHours(Now_Split[0]);
            Day_Now.setMinutes(Now_Split[1]);
          } else {
            Day_Now.setHours(From_Split[0]);
            Day_Now.setMinutes(From_Split[1]);
          }

          curr_get_ftime.setMinutes(curr_get_ftime.getMinutes() + parseInt(this.homecareDoctorDetails[i].time_interval));

          if (curr_get_ttime > Day_Now && curr_get_ftime < curr_get_ttime) {
            var New_time = this.datepipe.transform(curr_get_ftime, 'hh:mm a');
            if (New_time != this.gservice.get_Timeformate(this.homecareDoctorDetails[i].avail_to)) {
              this.homecareTimeList.push(New_time);
            }
          }
        }
      }
    }
  }

  Homeapp_Date_Change() {
    this.visitHomeSession = null;
  }

  HomeResched_Book_Click() {
    if (this.visitHomeSession == undefined || this.visitHomeSession == null) {
      this.toastr.error(Message_data.sltSession);
    } else if (this.visitHomeTime == undefined || this.visitHomeTime == null) {
      this.toastr.error(Message_data.sltTime);
    } else {
      var get_time = this.visitHomeTime.split(' ');
      var get_time1 = get_time[0] + ":00";

      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "appointment/getappexist/", JSON.stringify({ country: ipaddress.country_code }), { headers: headers }).subscribe(
        data => {
          if (data.json().result != null) {
            if (data.json().result == false)
              this.HomeResched_Booking();
            else 
              this.toastr.error(Message_data.sryAlreadyBooked);
          }
        }, error => {});
    }
  }

  HomeResched_Booking() {
    var get_time = this.visitHomeTime.split(' ');
    var get_time1 = get_time[0] + ":00";
    var send_data = {
      doc_app_id: this.docAppId,
      doc_reg_id: this.doctorId,
      date:this.appointmentDate,
      f_time: get_time1,
      session: encrypt_decript.Encript(this.visitHomeSession).toString(),
      token: "false"
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "appointment/reschedule/",
      JSON.stringify({
        send_data
      }), { headers: headers }).subscribe(
        data => {
          if (data.json().result != null && data.json().result == "Appointment rescheduled successfully") {
            this.toastr.success(data.json().result);
            Client_Helper.setHomecare(this.homecare);
            this.messageservice.sendMessage("doctor");
          }else {
            this.toastr.error(data.json().result);
          }
        },error => {});
  }

  Reschedule_Book() {
    if (this.rescheduleReason != undefined && this.rescheduleReason.length != 0) {
      var gettime = this.currentTime.split(':');
      var appdatenew = this.appointmentDate;
      var mrg_flag = "", aft_flag = "", eve_flag = "";
      if (gettime[0] < 12) {
        mrg_flag = "Morning";
        aft_flag = "Afternoon";
        eve_flag = "Evening";
      } else if (gettime[0] >= 12 && gettime[0] <= 16) {
        mrg_flag = "";
        aft_flag = "Afternoon";
        eve_flag = "Evening";
      } else if (gettime[0] >= 16 && gettime[0] <= 24) {
        mrg_flag = "";
        aft_flag = "";
        eve_flag = "Evening";
      }

      if (this.currentDate == appdatenew) {
        if (this.session == "Morning" && this.visitSession == mrg_flag) {
          this.toastr.error(Message_data.sltValidSession);
        } else if (this.session == "Afternoon" && this.visitSession == aft_flag) {
          this.toastr.error(Message_data.sltValidSession);
        } else if (this.session == "Evening" && this.visitSession == eve_flag) {
          this.toastr.error(Message_data.sltValidSession);
        } else {
          this.book_app();
        }
      } else {
        this.book_app();
      }
    } else {
      this.toastr.error(Message_data.reasonForReschedule);
    }
  }

  book_app() {
    var valid_flag = false;
    var Date_splitter = (this.appointmentDate).split('-');
    var send_data;
    var url;

    if (this.appType == "system") {// Token Appointment
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      url = "appointment/doctoken/";
      send_data = {
        hospital_id: this.hospitalId,
        doc_reg_id: this.doctorId,
        date: this.appointmentDate,
        session: encrypt_decript.Encript(this.visitSession).toString()
      }
    } else {// Time Appointment
      url = "appointment/getappexist/";
      if (this.appointmentTime == undefined) {
        this.toastr.error(Message_data.sltTime);
        valid_flag = true;
      } else {
        var Temptime = this.appointmentTime.split(' ');
        send_data = {
          type: "doctor",
          doc_reg_id: this.doctorId,
          appointment_date: this.appointmentDate,
          session: encrypt_decript.Encript(this.visitSession).toString(),
          from_time: Temptime[0] + ":00"
        }
      }
    }

    if (valid_flag == false) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + url,
        JSON.stringify(
          send_data
        ), { headers: headers }).subscribe(
          data => {
            if (data.json().result != null) {
              if (this.appType == "system") {// Token
                if (data.json().result != "true") {
                  this.toastr.error(Message_data.sryApptClosedForSession);
                  valid_flag = false;
                }else {
                  this.rescheduleBooking();
                }
              }
              else{ // Time
                if (data.json().result != true) {
                  this.rescheduleBooking();
                }else {
                  this.toastr.error(Message_data.sryAlreadyBooked);
                  valid_flag = false;
                }
              }
            }
          },error => {});
    }
  }
 
  rescheduleBooking() {  //reschedule booking
    if (this.appType == "system") {
      var Res_Send_Data = JSON.stringify({
        hospital_id: this.hospitalId,
        doc_reg_id: this.doctorId,
        date: this.appointmentDate,
        session: encrypt_decript.Encript(this.visitSession).toString(),
        doc_app_id: this.docAppId,
        token: true,
        reschedule_reason: this.rescheduleReason
      });
    } else {
      var Temptime = this.appointmentTime.split(' ');
      var Res_Send_Data = JSON.stringify({
        hospital_id: this.hospitalId,
        doc_reg_id: this.doctorId,
        date: this.appointmentDate,
        session: encrypt_decript.Encript(this.visitSession).toString(),
        doc_app_id: this.docAppId,
        token: false,
        f_time: Temptime[0] + ":00",
        reschedule_reason: this.rescheduleReason
      });
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "appointment/reschedule/", Res_Send_Data
      , { headers: headers }).subscribe(
        data => {
          if (data.json().result != "Can't reschedule more then three times") {
            this.toastr.success(data.json().result);
            if (this.homecare != "0") {
              Client_Helper.setHomecare(this.homecare);
              this.messageservice.sendMessage("doctor");
            } else {
              this.messageservice.sendMessage("doctor");
            }
          } else {
            this.toastr.error(data.json().result);
            if (this.homecare != "0") {
              Client_Helper.setHomecare(this.homecare);
              this.messageservice.sendMessage("doctor");
            } else {
              this.messageservice.sendMessage("doctor");
            }
          }
        },error => {});
  }
  
  getNormalDoctorAvailSchedule() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "appointment/getweekschedule/", JSON.stringify(this.sendDataForDocSchedule), { headers: headers }).subscribe(
      data => {
        if (data.json().weekschedule != null && data.json().weekschedule.length != 0) {
          for (var k = 0; k < data.json().weekschedule.length; k++) {
            this.homecareSessionData.push(this.gservice.get_Session(data.json().weekschedule[k].day_session));
            this.doctorDetails.push({
              hospital: this.hospitalName,
              workdays: this.gservice.get_Day(data.json().weekschedule[k].work_days),
              available_from: this.gservice.get_Timeformate(data.json().weekschedule[k].available_from),
              available_to: this.gservice.get_Timeformate(data.json().weekschedule[k].available_to),
              day_session: this.gservice.get_Session(data.json().weekschedule[k].day_session),
              token_count: data.json().weekschedule[k].token_count,
            });
          }
          this.Session_Add();
          this.Get_Doctor_Avail(this.currentDate);
        }
      },error => {});
  }
  
  getHMDoctorAvailSchedule() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "search/doctorbyid/", JSON.stringify(this.sendDataForDocSchedule), { headers: headers }).subscribe(
      data => {
        if (data.json().home_consult != null && data.json().home_consult.length != 0) {
          for (var k = 0; k < data.json().home_consult.length; k++) {
            this.homecareSessionData.push(this.gservice.get_Session(data.json().home_consult[k].session));
            this.homecareDoctorDetails.push({
              day_session: this.gservice.get_Session(data.json().home_consult[k].session),
              available_from: this.gservice.get_Timeformate(data.json().home_consult[k].available_from),
              available_to: this.gservice.get_Timeformate(data.json().home_consult[k].available_to),
              time_interval: data.json().home_consult[k].time_interval
            });
          }
        }
        this.Session_Add();
      },error => {});
  }
  
  cancel_App() {
    const dialogRef = this.dialog.open(ClientCancelAppointmentComponent, {
      width: '50%',
      height: '300px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        if (result != undefined && result != undefined) {
          this.sendData = {
            reason: result,
            doc_app_id: this.docAppId,
          }
          var headers = new Headers();
          headers.append('Content-Type', 'application/json');
          this.http.post(this.apptCancelUrl, JSON.stringify(this.sendData), { headers: headers }).subscribe(
            data => {
              if (data.json().cancel == "success") {
                this.toastr.success(Message_data.appcancel);
                if (this.homecare != "0") {
                  Client_Helper.setHomecare(this.homecare);
                  this.messageservice.sendMessage("doctor");
                } else {
                  Client_Helper.setHomecare(this.homecare);
                  this.messageservice.sendMessage("doctor");
                }
              }else
                this.toastr.error(Message_data.apptNotCancelled);
            }, error => {});
        }
      }
    });
  }
  
  back() {
    Client_Helper.setHomecare(this.homecare);
    this.messageservice.sendMessage("doctor");
  }
  
  rating() {
    // var modalPage = this.modalCtrl.create('RatingPage', {
    //   hospital_id: this.app_Hosp_ID,
    //   doc_reg_id: this.doctorId,
    //   doc_app_id: this.doc_app_id,
    //   "AppFlow": "doctor",
    //   "homecare": this.homecare
    // });

    // modalPage.present();
    // modalPage.onDidDismiss(data => {
    // });
  }
  
  videocall(){
    var calleeid;
    calleeid = (Helper_Class.getUserType() =="doctor") ? this.clientId : this.doctorId;
  }
  
  payWithRazor() {
    var billamount= this.doctorFee+"00";
   
    var orderoptions = {
      amount: billamount,  // amount in the smallest currency unit
      currency: "INR",
      receipt: "consult_rcptid"
    };
    
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.rpayorderUrl, JSON.stringify(orderoptions), { headers: headers }).subscribe(
      data => {
        var obj = data.json();

        if(obj.id != undefined){
          var options = {
            description: 'Consultation fee',
            image: 'https://i.imgur.com/3g7nmJC.png',
            currency: 'INR',
            key: 'rzp_live_2MzOMLIEZm8bbG',
            order_id: obj.id,
            amount: billamount,
            name: 'gritsys',
            theme: {
              color: '#51b0e7'
            },
            "prefill":{
              "contact": this.clntMobile,
              "email":this.clntemail,
            }
          };
          options['handler'] = this.razorPaySuccessHandler.bind(this);
          let razorpay = new Razorpay(options);
          razorpay.open();
          razorpay.on('payment.failed', function (response){
            console.log("ERROR in payments "+response.error.description);
          });
        }
      });
  }
  
  public razorPaySuccessHandler(success) {
    this.payid = success.razorpay_payment_id;
    this.orderId = success.razorpay_order_id;
    this.signature = success.razorpay_signature;
    this.updatePaymentDetails(this.orderId,this.payid,this.signature);
  }

  updatePaymentDetails(order,payment,signature){
    var send_data={
      doc_app_id:this.docAppId,
      app_type:"Doctor appointment",
      order_id:order,
      payment_id:payment,
      signature:signature,
    }
    
    var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.paySuccessUrl, JSON.stringify(send_data), { headers: headers }).subscribe(
        data => {
          if (data.json().key == "1") {
            this.toastr.success(Message_data.paymentSuccess);
          }
        }, error => {});
  }
    
  async selectApptDate(e) {
    this.appointmentDate = e;
    await this.Get_Doctor_Avail(e);
  }

  video(){
    const dialogRef1 = this.dialog.open(ClientVideoLinkComponent, {
      width: '400',
      height: '400',
      data: {
        login_id: this.userInfo.client,
        callee_id: this.doctorId,
      }
    });
    dialogRef1.afterClosed().subscribe(result => {});
  }
}
