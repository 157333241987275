import { Component, OnInit, HostListener } from '@angular/core';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import { Http, Headers, JSONPBackend } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from '../../helper_class';
import { ipaddress } from '../../ipaddress' ;
@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css']
})
export class HelpComponent implements OnInit {
  public user_info;
  public user_id;

   //mdbTable
   dtOptions: DataTables.Settings = {};
  public  packid: string;
  public  usertype: string;
  public  moduledata:any=[];
  public  helptopic:any = [];
  public  module : any;
  public nodataapp:boolean;
  public header_footer_flag: boolean;
  public msgflag:boolean=false;
  public profile_image:string;
  public first_name;
  public last_name;
  public hospital_logo;
  public messages;
  public notifyflag:boolean;
  public hospital_name;

  constructor(private http:Http,private routes:ActivatedRoute,private router:Router,public toastr:ToastrService) { 
  }

  ngOnInit(): void {  
    this.user_info = Helper_Class.getInfo()
    this.user_id = this.user_info.package_id;
    this.dtOptions = {
      pageLength: 5,
      "lengthMenu": [ [5, 10, 25, 50, -1], [5, 10, 25, 50, "All"] ],
      "columnDefs": [
        { "orderable": false, "targets": [] },
      ],
      "language": {
       "search": "Filter"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12">><"table-responsive"t><"row mt-1 d-flex"<"col-xs-12 col-sm-6 col-md-4"l><"col-xs-12 col-sm-12 col-md-4 text-center sm-order-last"i><"col-xs-12 col-sm-6 col-md-4"p>>'
    };
    this.usertype = "2";

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "usercontroller/gmodules",
      JSON.stringify({
        user_type: this.usertype,
        package_id:this.user_id,//package_id
      }),
      { headers: headers })
      .subscribe(
        response => {
          this.moduledata = [];
          var obj = response.json();
          if(obj.length !=0 && obj.modules != undefined){
            for(var i = 0; i < obj.modules.length; i++){
              this.moduledata.push({
                module_id: obj.modules[i].module_id,
                description: obj.modules[i].description
              });
            }
            this.module = this.moduledata[0].module_id;
          }
        });
   }
  
  topic_change(data){   
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "usercontroller/ghelp",
      JSON.stringify({
        module_id: data,        
      }),
      { headers: headers })
      .subscribe(
        response => {
          this.helptopic = [];         
          var obj = response.json();
          
          if(obj.length !=0 && obj.help_topics != undefined){
            for(var i = 0; i < obj.help_topics.length; i++){
              this.helptopic.push({
                helpdesc: obj.help_topics[i].help_desc,                
              });
            }
          }
        });
  } 
}
