<div class="row" style="width:100%;margin:0 auto;">
  <div class="">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header" >
            <h5 class="m-0" class="mainHeadingStyle">Bill list</h5>
          </div>
          <div class="headerButtons">
            <a *ngIf="createFlag && newbutton" (click)="bill_create_click()">
              <img src="../../../assets/ui_icons/buttons/new_button.svg" 
              class="saveimgbtn_inpatinfo">
            </a>
          </div>
        </div>      
      </mdb-card-header>
      <mdb-card-body>
        <div class="row">
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom"  [hidden]="hospital_flag">
            <mat-label class="matlabel">
              <img src='../../../assets/ui_icons/Bill_list/Hospital_icon.svg' width="20px" height="auto" />&nbsp;
              Hospital
              <select required class="ipcss widthbilllist" [(ngModel)]="hptl_clinic_id"  style="height: 28px;">
                <option *ngFor="let hosp of hospital_array" value={{hosp.hptl_clinic_id}}>{{hosp.hptl_name}}
                </option>
              </select>
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-3 col-lg-2 col-xl-2 select_bottom">
            <mat-label class="matlabel">
              <img src="../../../assets/ui_icons/Bill_list/Filter_Icon.svg" width="15px" height="auto" />
              Filter by
            </mat-label>
            <select required class="ipcss" [(ngModel)]="Filter_txt" required (change)="Filter_change()"
              disableOptionCentering style="height: 28px;">
              <option value="Patient ID">Patient ID</option>
              <option value="Location">Location</option>
              <option value="Name">Name</option>
              <option value="Date">Date</option>
              <option value="Mobile">Mobile</option>
            </select>
          </div>
          <div [hidden]="To_show" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
            <mat-label class="matlabel">From</mat-label>
            <input type="date" class="ipcss" (change)="OnDateChangedfrom(f_date)" [hidden]="From_show"
              [(ngModel)]="f_date" #matInput max="{{currentDate}}">
          </div>
          <div [hidden]="City_show" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel">City</mat-label>
            <select required [(ngModel)]="city_desc" (change)="City_change(city_desc)" disableOptionCentering
              class="ipcss">
              <option *ngFor="let city of city_array" value={{city}}>{{city}}
              </option>
            </select>
          </div>
          <div [hidden]="firstname_show" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel">First name</mat-label>
            <input matInput type="text" [hidden]="firstname_show" required [(ngModel)]="first_name" class="ipcss" />
          </div>
          <div [hidden]="patient_show" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel">Patient id</mat-label>
            <input matInput type="text" [hidden]="patient_show" required [(ngModel)]="patient_id" class="ipcss" />
          </div>
          <div [hidden]="mobile_show" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel">Mobile number</mat-label>
            <input matInput type="text" [hidden]="mobile_show" required [(ngModel)]="mobile_num" class="ipcss" />
          </div>
          <div [hidden]="Location_show" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel">Location</mat-label>
            <select required [hidden]="Location_show" [(ngModel)]="location_desc" disableOptionCentering class="ipcss">
              <option *ngFor="let location of hosloc_array" value={{location}}>{{location}}</option>
            </select>
          </div>
          <div [hidden]="To_show" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
            <mat-label class="matlabel">To</mat-label>
            <input type="date" id="appt_date" (change)="OnDateChangedto(t_date)" [hidden]="To_show" [(ngModel)]="t_date"
              max="{{currentDate}}" class="ipcss" matInput>
          </div>
          <div [hidden]="firstname_show" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel">Last name</mat-label>
            <input matInput type="text" [hidden]="firstname_show" required [(ngModel)]="last_name" class="ipcss" />
          </div>
          <div class="col-12 col-sm-6 col-md-3 col-lg-2 col-xl-2 select_bottom">
            <mat-label class="matlabel">
              Appointment type
            </mat-label>
            <select required class="ipcss" [(ngModel)]="appType" required
              disableOptionCentering style="height: 28px;">
              <option value="all">All</option>
              <option value="New">New</option>
              <option value="follow">Follow-up</option>
            </select>
          </div>
          <div class="col-12 col-sm-6 col-md-3 col-lg-2 col-xl-2 select_bottom" *ngIf="is_admin">
            <mat-label class="matlabel">
              Doctor
            </mat-label>
            <select class="ipcss widthappt" (ngModelChange)="changeDoctor($event)" [(ngModel)]="doctorid">
              <option *ngFor="let doctors of doctorList" [ngValue]="doctors" [selected]="doctors === doctorid">
                {{doctors.Doc_Name}}
              </option>
            </select>
          </div>

          <div class="col-1 d-flex justify-content-center my-auto" style="padding-bottom: 5px;">
            <mat-label class="matlabel"><br></mat-label>
              <a (click)="filter_click()">
                <img src='../../../assets/img/search.png' width='20px' height="auto" class="topvalue" />
              </a>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-sm-12 col-md-12 col-xl-6 col-lg-6">
            <mat-card class="tot_revenue">
              <mat-card-content>
                <div class="container" style="padding-left: 5px;padding-right: 5px;">
                  <div class="row">
                    <div class="col-2"></div>
                    <div class="col-7">
                      <mat-label class="matlabel" style="font-size: 12px !important;"><strong>Total revenue for the
                          selected period: र &nbsp;</strong>
                      </mat-label>
                    </div>
                    <div class="col-3">
                      <mat-label class="matlabel"><b>{{totalamount}}</b></mat-label>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-xl-6 col-lg-6">
            <mat-card class="tot_bal">
              <mat-card-content>
                <div class="container" style="padding-left: 5px;padding-right: 5px;">
                  <div class="row">
                    <div class="col-2"></div>
                    <div class="col-7">
                      <mat-label class="matlabel" style="font-size: 12px !important;"><strong>Total balance for the
                          selected period: र &nbsp;</strong>
                      </mat-label>
                    </div>
                    <div class="col-3">
                      <mat-label class="matlabel"><b>{{totalbalance}}</b></mat-label>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>

        <div class="row" style="margin-top: 15px;">
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <p class="nodata" *ngIf="billing_lsit_array.length == 0">No bill(s) found</p>
            <table *ngIf="billing_lsit_array.length" mdbTable datatable [dtOptions]="dtOptions"
              class="table table-nowrap table-sm dataTable billlisttable">
              <thead>
                <tr>
                  <th class="delete">Bill no</th>
                  <th>Date</th>
                  <th>Client name</th>
                  <th>Amount</th>
                  <th class="delete" *ngIf="editFlag">Edit</th>
                  <th class="delete" *ngIf="deleteFlag">Delete</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let person of billing_lsit_array; let i = index">
                  <td style="font-size: 12px;text-align: center;" (click)="view_bill(person.bill_id,person.doc_reg_id,person.fin_year,person)">{{person.bill_id}}
                  </td>
                  <td style="font-size: 12px;text-align: center;" (click)="view_bill(person.bill_id,person.doc_reg_id,person.fin_year,person)">{{person.date}}
                  </td>
                  <td style="font-size: 12px;text-align: left !important;" (click)="view_bill(person.bill_id,person.doc_reg_id,person.fin_year,person)">
                    {{person.salutation}} {{person.client_name}}
                  </td>
                  <td style="font-size: 12px;text-align: right;" (click)="view_bill(person.bill_id,person.doc_reg_id,person.fin_year,person)">{{person.amount}}
                  </td>
                  <td *ngIf="editFlag && editbutton " style="font-size: 12px;text-align: center;cursor: pointer;" (click)="editBill(person.bill_id, person.doc_reg_id, person.doc_app_id, person.fin_year)"><img
                      src="../../../assets/img/edit.png" class="billing_edit" alt="">
                  </td>
                  <td *ngIf="deleteFlag && deletebutton" style="font-size: 12px;text-align: center;cursor: pointer;" (click)="deleteBill(person.bill_id, person.fin_year)"><img
                      src="../../../assets/ui_icons/trash_icon.svg" class="billing_edit" alt="">
                  </td>
                </tr>
              </tbody>
            </table>         
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>