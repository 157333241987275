import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Helper_Class } from '../../helper_class';
import { ipaddress } from '../../ipaddress';
import { MenuViewService } from '../../../app/common-module/menu-view/menu-view.service';
import { Diagnosis_Helper } from '../Diagnosis_Helper';

@Component({
  selector: 'app-outsource-centre-list',
  templateUrl: './outsource-centre-list.component.html',
  styleUrls: ['./outsource-centre-list.component.scss']
})
export class OutsourceCentreListComponent implements OnInit {
  public outsourceList:any=[];
  dtOptions: DataTables.Settings = {};
  diagid: any;

   // modules flags
   public newbutton: boolean = false
   public editbutton: boolean = false;
   public deletebutton: boolean = false;
   public printbutton: boolean = false;
   public viewbutton: boolean = false;
   public moduleList: any = [];
  reporttype: any;

  constructor(public messageservice:MenuViewService ,public toastr: ToastrService,public http: HttpClient) { 

  }

  ngOnInit(): void {
    this.moduleList = Helper_Class.getmodulelist();
    this.reporttype = Diagnosis_Helper.getReportType();
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 25,
      "lengthMenu": [ [25, 50, -1], [25, 50, "All"] ],
      "order": [[0, 'asc']],
      "columnDefs": [
        { "orderable": false, "targets": [] },
      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search by name,location,contact no"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };

    if (this.moduleList != undefined) {
      for (var i = 0; i < this.moduleList.length; i++) {
        if (this.moduleList[i].module_id == "115") {
          console.log(JSON.stringify(this.moduleList[i]));
          if (this.moduleList[i].edit == "1") {
            this.editbutton = true;//update button
          }
          if (this.moduleList[i].create == "1") {
            this.newbutton = true;
          }
          if (this.moduleList[i].delete == "1") {
            this.deletebutton = true;
          }
          if (this.moduleList[i].print == "1") {
            this.printbutton = true;
          }
          if (this.moduleList[i].view == "1") {
            this.viewbutton = true;
          }
        }
      }
    }

    if (Helper_Class.getInfo().user_type == 'Admin') {
      this.diagid = Helper_Class.getInfo().diag_centre_id;
      this.getOutsourceList();

      this.editbutton = true;
      this.newbutton = true;
      this.deletebutton = true;
      this.printbutton = true;
      this.viewbutton = true;
    }
    else {
      this.diagid = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.getOutsourceList();
    }
  
  }

  getOutsourceList(){
   
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "diagnosiscontroller/gosl", { diag_center_id: this.diagid }, 
    { headers: headers }).subscribe(
      data => {
        var dataval = JSON.parse(JSON.stringify(data))
        if(dataval != undefined && dataval.outsource_list != undefined) {
          this.outsourceList = dataval.outsource_list;
          
        }
      })
  }

  createOutCentre(){
    Helper_Class.setOutsource(null);
    this.messageservice.sendMessage("out-centre");
  }
  
  editOutSource(list){
    Helper_Class.setOutsource(list)
    this.messageservice.sendMessage("out-centre");
  }

  getTests(list){
    Helper_Class.setOutsource(list)
    this.messageservice.sendMessage("out-test-map");
  }
}
