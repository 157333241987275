<div mat-dialog-title class="popup_border_icd">
    <h5 class="header_txt">Bill History</h5>
    <div mat-dialog-actions>
        <img src="../../../assets/img/close_icon.png" (click)="close()" width="20px" height="20px"
            id="close_icd" />
    </div>
</div>
<div mat-dialog-content class="diab_content">
    <div class="container" style="padding-top: 5px;padding-bottom: 5px;">
        <div class="row" style="max-height: 250px;">
            <div class="col-12">
                <div [hidden]="billList.length != 0" class="nodata">No data found</div>
                <div *ngFor="let bill of  billList">
                    <div class="row" style="padding: 0 3px 0 4px;">
                        <div class="col-12 col-md-6 col-lg-6 col-xl-7">Modified by: {{bill.name}}</div>
                        <div class="col-12 col-md-6 col-lg-6 col-xl-5" style="text-align: right;">Bill Date: {{bill.created_date}}</div>
                    </div>
                    <div class="row" style="margin-top: 3px;margin-bottom: 3px;">
                        <div class="col-12 " *ngIf = "bill.details.length !=0">
                            <div class="table-responsive">
                                <table id="tbl">
                                    <tr>
                                        <th style="width: 150px;">Type</th>
                                        <th style="width: 50px; ">Fee</th>
                                        <th style="width: 100px;">Concession %</th>
                                        <th style="width: 150px;">Amount</th>
                                    </tr>
                                    <tr *ngFor="let billdet of  bill.details">
                                        <td style="width: 150px;">{{billdet.charge_type}}</td>
                                        <td style="width: 50px; text-align: right;; ">{{billdet.fee_bef_con}}</td>
                                        <td style="width: 100px; text-align: right;">{{billdet.concession}}</td>
                                        <td style="width: 150px; text-align: right;"><span
                                                class="fee_txt">{{billdet.fee}}</span></td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions align="end" style="width: auto;right: 19px;">
</div>