import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule} from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { DietpopupwindowComponent } from './dietpopupwindow.component';
import {MatDialogModule} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    imports:[MatDialogModule,CommonModule,MatInputModule ,MatSelectModule,MatFormFieldModule,MatIconModule,MatButtonModule,FormsModule],
    declarations:[DietpopupwindowComponent],
    exports:[DietpopupwindowComponent]
})
export class DietpopupwindowModule{   
   
}