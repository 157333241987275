import { Component, OnInit, Input } from '@angular/core';
import {Helper_Class} from '../../helper_class';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { Observable, observable, Subscription } from 'rxjs';
import { SecondOpinionService } from '../second-opinion-casesheet/SecondOpinion.Service';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
@Component({
  selector: 'app-second-opinion-casesheet-personal',
  templateUrl: './second-opinion-casesheet-personal.component.html',
  styleUrls: ['./second-opinion-casesheet-personal.component.css']
})
export class SecondOpinionCasesheetPersonalComponent implements OnInit {
  
    public personal = "min"
    public subscription:Subscription;
    public marital_stat;
    public Height_txt;
    public weight_txt;
    public weightkg_txt;
    public heightkg_txt;
    public BMI_txt;
    public blood_pres;
    public stress_factortxt;
    public Dietary_txt;
    public HabitualDIV: boolean;
    public smoke_dure;
    public smoke_freq;

    public alcohol_dure;
    public alcohol_freq;
    public quan_txt;
    public alcohol_mix;
    public Alcohol_div: boolean;
    public gutka_dure;
    public gutka_freqency;
    public gutka_div: boolean;
    public Exercises_Div: boolean;
    public exer_dure;
    public exer_routine;
    public excer_txt;
    public Any_issue: boolean;
    public main_concerns;
    public religious_txt;
    public past_medical_hist;
    public smoke_hidden: boolean;
    public header_footer_flag: boolean;
    //Redaonly 
    public name;
    public age;
    public gender;
    isReadonly() { return true; }

    constructor(private http:Http,private routes:ActivatedRoute,private router:Router,public toastr:ToastrService, public messageservice1: SecondOpinionService) { }

    ngOnInit(): void {
        this.smoke_hidden = true;
        this.gutka_div = true;
        this.Exercises_Div = true;
        this.Alcohol_div = true;
        this.subscription = this.messageservice1.getSecondOpinionMessage().subscribe(message => {
            if (message) {
                this.personal = message;
            }
        });
        this.Get_general_retrivel();
    }

    Get_general_retrivel() {
        function StringBuilder(this:any,value) {
            this.strings = new Array();
            this.append(value);
        }

        StringBuilder.prototype.append = function (this:any,value) {
            if (value) {
                this.strings.push(value);
            }
        }

        StringBuilder.prototype.clear = function () {
            this.strings.length = 0;
        }

        StringBuilder.prototype.toString = function () {
            return this.strings.join("");
        }

        var getOpnRet = Helper_Class.getOpnRet();
        if (getOpnRet.marital_status != null) {
            this.marital_stat = getOpnRet.marital_status
        }

        this.Height_txt = getOpnRet.height;
        this.weight_txt = getOpnRet.weight;
        this.weightkg_txt = getOpnRet.weight_measure;
        this.heightkg_txt = getOpnRet.height_measure;
        this.BMI_txt = getOpnRet.bmi;
        this.blood_pres = getOpnRet.blood_pressure;
        this.stress_factortxt = getOpnRet.stress_factor;
        this.Dietary_txt = getOpnRet.diet_habits;
        this.name = encrypt_decript.Decript(getOpnRet.mem_first_name)+ " " + encrypt_decript.Decript(getOpnRet.mem_last_name);
        this.age = getOpnRet.age;
        this.gender = encrypt_decript.Decript(getOpnRet.gender);
        if ((getOpnRet.smoke_duration != null && getOpnRet.smoke_duration.length != 0) || (getOpnRet.alc_duration != null && getOpnRet.alc_duration.length != 0) || 
            (getOpnRet.gutka_duration != null && getOpnRet.gutka_duration.length != 0) || (getOpnRet.exer_day_dur != null && getOpnRet.exer_day_dur.length != 0)) {
            this.HabitualDIV = false;
        }else {
            this.HabitualDIV = true;
        }
        if (getOpnRet.smoke_duration != null) {
            this.smoke_dure = getOpnRet.smoke_duration;
            this.smoke_freq = getOpnRet.smoke_frequency;
            this.smoke_hidden = false;
        }else {
            this.smoke_hidden = true;
        }
        if (getOpnRet.alc_duration != null) {
            this.alcohol_dure = getOpnRet.alc_duration;
            this.alcohol_freq = getOpnRet.alc_frequency;
            this.quan_txt = getOpnRet.alc_qty_desc;
            this.alcohol_mix = getOpnRet.alc_mixing_desc;
            this.Alcohol_div = false;
        }else {
            this.Alcohol_div = true;
        }
        if (getOpnRet.gutka_duration != null) {
            this.gutka_dure = getOpnRet.gutka_duration;
            this.gutka_freqency = getOpnRet.gutka_frequency;
            this.gutka_div = false;
        }else {
            this.gutka_div = true;
        }
        if (getOpnRet.exer_day_dur != null || getOpnRet.exer_routine != null || (getOpnRet.exercises != null && getOpnRet.exercises.length != 0)) {
            if (getOpnRet.exer_day_dur != null) {
                this.exer_dure = getOpnRet.exer_day_dur;
                this.Exercises_Div = false;
            }
            if (getOpnRet.exer_routine != null) {
                this.exer_routine = getOpnRet.exer_routine_desc;
                this.Exercises_Div = false;
            }
            var exer_build = new StringBuilder("");
            if (getOpnRet.exercises != null) {

                for (var i = 0; i < getOpnRet.exercises.length; i++) {
                    if (getOpnRet.exercises[i].exec_description != null) {
                        exer_build.append(getOpnRet.exercises[i].exec_description);
                        exer_build.append("\n");
                        this.excer_txt = exer_build.toString();
                    }
                }
            }
        }else {
            this.Exercises_Div = true;
        }
        this.Any_issue = getOpnRet.other_issues;

        var main_build = new StringBuilder("");
        if (getOpnRet.main_concerns != null) {
            for (var i = 0; i < getOpnRet.main_concerns.length; i++) {
                if (getOpnRet.main_concerns[i].concern_description != null) {
                    main_build.append(getOpnRet.main_concerns[i].concern_description);
                    main_build.append("\n");
                    this.main_concerns = main_build.toString();
                }
            }
        }

        var religious_build = new StringBuilder("");
        if (getOpnRet.religious_beliefs != null) {
            for (var i = 0; i < getOpnRet.religious_beliefs.length; i++) {
                if (getOpnRet.religious_beliefs[i].rel_description != null) {
                    religious_build.append(getOpnRet.religious_beliefs[i].rel_description);
                    religious_build.append("\n");
                    this.religious_txt = religious_build.toString();
                }
            }
        }

        var medical_build = new StringBuilder("");
        if (getOpnRet.medical_history != null) {
            for (var i = 0; i < getOpnRet.medical_history.length; i++) {
                if (getOpnRet.medical_history[i].description != null) {
                    medical_build.append(getOpnRet.medical_history[i].description);
                    medical_build.append("\n");
                    this.past_medical_hist = medical_build.toString();
                }
            }
        }
    }
}
