import { Component, OnInit ,ElementRef,Input,Inject } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr'
import { CommonDataService } from '../../providers/common-data.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Message_data } from 'src/assets/js/Message_data';
@Component({
  selector: 'app-react-page',
  templateUrl: './react-page.component.html',
  styleUrls: ['./react-page.component.css']
})
export class ReactPageComponent implements OnInit {
  public medSupplyName;
  public reactAllergy;
  public prescriptionList = [];
  public reactAllgHeading:string;
  public reactAllgSubHeading:string;
  public reactAllg:string;
  public medSuppHead:string;
  public retriveData = [];
  public mobOrWebFlag:boolean;
  public retriveDataFlag:boolean;
  @Input() id: string;parm: string;  
  public element: any;

  constructor(public comm_Data:CommonDataService,public el: ElementRef,public toastr:ToastrService,public router:Router,public  http:Http,public dialog: MatDialog ,public dialogRef: MatDialogRef<ReactPageComponent>,
  @Inject(MAT_DIALOG_DATA) public data: any) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    if(this.data.type == "reaction"){
      this.reactAllgHeading = "Reaction to medication or supplements";
      this.reactAllgSubHeading = "Name of supplement";
      this.reactAllg = "Reaction";
      this.medSuppHead="Supplement name";
    }else{
      this.reactAllgHeading = "Allergic to any substance";
      this.reactAllgSubHeading = "Name of medicine";
      this.reactAllg = "Allergy";
      this.medSuppHead="Medicine name";
    }
    this.retriveDataFlag = (this.data.retrival == true) ? true : false;
    this.mobOrWebFlag = this.data.flag;
    if(this.data.table_data != null){
      this.retriveData =this.data.table_data;
      this.prescriptionList=[];
      for(var k=0; k<this.retriveData.length; k++){
        var medsup_name="";
        var reactallg_name="";
        if(this.data.type == "reaction"){
          medsup_name = this.retriveData[k].supplement_name;
          reactallg_name = this.retriveData[k].reaction;
        }else{
          medsup_name = this.retriveData[k].medicine_name;
          reactallg_name = this.retriveData[k].allergies;
        }
        this.prescriptionList.push({
          type: this.data.type,
          med_supply_name: medsup_name,
          react_allrgy: reactallg_name
        });
      }
    }
  }

  addAllergy() {
    if (this.medSupplyName == undefined || this.medSupplyName == "") {
     this.toastr.error(Message_data.enterSuppltName);
    } else if (this.reactAllergy == undefined || this.reactAllergy == "") {
     this.toastr.error(Message_data.enterReact);
    } else{
      this.prescriptionList.push({
        type:this.data.type,
        med_supply_name: this.medSupplyName,
        react_allrgy: this.reactAllergy
      });
      this.medSupplyName = "";
      this.reactAllergy = "";
    }
  }

  deleteMedication(index) {
    this.prescriptionList.splice(index, 1);
  }

  close(){
    this.dialogRef.close(this.prescriptionList)
  }
}
