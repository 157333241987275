export class Message_data{
    static produdetails="Product name or Batch number or Rack and bin is not filled";
    static nomedicine="No medicine found";
    static getpharmaprice="Batches and Racks are empty";
    static returnreason="Stock reason is not filled";
    static returnindex="Stock has no index ";
    static returnproduct_id="Stock has no product";
    static returnbatchno="Stock has no batch number";
    static returnexpiry_date="Stock has no expiry date";
    static  returnmfg_name="Stock has no supplier";
    static  returnqty_return="Stock has no return quantity";
    static unableToFetchsupp="Unable to fetch supplier";
    static quantityerror="Insufficient stock";
    static pogeneratorsuccess="Purchase order generated successfully";
    static pogeneratorerror="There is error in generating purchase order";
    static cancelsuccess="Estimate cancelled successfully";
    static cancelerror="Unable to cancel";
    static productmap="Product all ready exist";
    static unableToFetchSech="Unable to fetch Schedule name";
    static unabletofetsupl="Unable to fetch supplier name";
    static prodstorename="The product has not saved for the store";
    static prodstorenamesucc="Product has not saved for the store Successfully" 
    static removerow = "Remove the empty row";
    static successstockadjustedsavedraft = "Product is in save draft";
    static errorstockadjustedsavedraft = "Error in save draft";
    static interdepttrans="Enter the mandatory fields to place inter department transfer";
    private static alert = "Testing the app";
    private static homeassign = "Homecare service assigned successfully";
    private static homeunassign = "Unable to homecare service assigned, please try again later";
    private static pastdate = "Please select current or past date";
    private static futuredate = "Please select current or future date";
    static diagFrequency="Cannot book the test as it doesn't meet the frequency criteria";
    static network = "Internet is too slow or no connection, try again";
    static mandatory ="Enter mandatory fields";
    static addbill ="Add bill details";
    static billgen = "Estimate saved successfully";
    static ordersave="Order saved successfully";

    private static billungen="Unable to generate bill, please try later";
    static passunchange = "Password not changed, please try again later";
    static passchange="Password changed successfully";
    static appcancelreason = "Please enter reason for cancellation";
    static maxlen150 ="You have reached a limit of 150 character";
    static appcancel="Appointment cancelled successfully";
    static selectapp="Please select appointment(s)";
    private static pastapp = "Past appointments can't be edited";
    static futureapp =" Future appointments can't be edited";
    private static compapp ="Completed appointments can't be edited";
    static appconf="Appointment confirmed successfully";
    private static genral="Some what went Wrong, please try again later!";
    private static Noappoinment="No Appointments Found!";

    static purchaserequestsuccess="Request got approved";
    static purchaserequesterror="Unable to approve the request"
    static purchaseordersucess="Order got approved";
    static purchaseordererror="Unable to approve the order"
    static checkDrugListEmpty = "Please fill required details";
    static allMandatory ="Please enter the all mandatory fields";
    static enterMedicine = "Please add Medicine";
    static orderNotPlaced = "Order not placed, try again later";
    static selectMedicine ="Select medicine";
    static selectbatch_no="Select batch no";
    static enterMedType ="Enter medicine type";
    static enterMedName ="Enter medicine name";
    static enterNoOfDays = "Enter no of days";
    static enterIntkSess = "Enter intake session";
    static enterDailyIntk = "Enter daily intake";
    static enterIntake = "Enter intake";
    static enterDisease = "Enter diagnosis";
    static addMedDetails = "Add medicine details";
    static fillIntk = "Please fill intake details";
    static discAlreadyExist = "Discount details already added";
    static maxImgSize = "Select maximum 50kb image";
    static locationExist ="Pharmacy in this location already been registered please select other location";
    static homeORstorepickup = "Select home delivery or store pickup";
    static enterStorepickupdays = "Enter store pickup days";
    static deleteWorkLoca = "Successfully deleted work location";
    static unDeleteWorkLoca = "Unable to delete work location, please try later";
    static validEmail = "Enter valid email address";
    static validMobileNo = "Enter valid mobile number";
    static verifyWrkLocation = "Please verify work location";
    static existMobileNo = "Mobile number already exists";
    static existEmailID = "Email id already exists";
    static profileUpdSuccess = "Profile update successfully";
    static profileUpdFailed = "Profile not update, please try again later";
    static saveSuccess = "Saved successfully";
    static invoiceExist = "Invoice details already exists, please try with different invoice";
    static unableUpldInventory = "Unable to upload inventory details, please try again later";
    static addInventory = "Please add inventory details";
    static addMandatory = "Please add mandatory data";
    static defaultErr = "Something Went Wrong, Please Try Again Later";
    static sameBatchNo = "Same batch no";
    static selectOneFile = "Select one file at a time";
    static unableToSave = "Unable to save details, please try again later";
    static unableToSavedept = "Unable to save details, please fill the department";
    static unableToSavestore = "Unable to save details, please fill the store name";
    static noPrd = "No product found";
    static prdUpldSuccess = "Product uploaded successfully";
    static updSuccess = "Uploaded successfully";
    static unableToFetchDocDet = "Unable to fetch doctor details, please try later";
    static unableToFetchUsrtype = "Unable to fetch user types, please try later";
    static contactAlrdyAdded = "Contact details already added";
    static enterSentMsg = "Enter message to be sent to recepients";
    static upldReferral = "Uploaded referral data";
    static unableUpldReferral = "Unable to uploaded referral data, try later";
    static exportExcelFailed = "Can't download excel now, !please try again later";
    static stockUpdSuccess = "Stock updated Successfully";
    static plsWaitUpdStock = "Please wait while updating stock details";
    static updPrevRecord = "Update previous record";
    static suppDetAddedSuccess  = "Supplier details added successfully";
    static manDetAddedSuccess  = "Manufacturer details added successfully";
    static cancelOrderSuccess = "Successfully cancelled the order";
    static orderNotRejected = "Order not rejected, please try again later";
    static viewPresBfrSave = "View prescription before confirming order";
    static orderConfirmSuccess = "Order confirmed successfully";
    static orderConfirmFailure = "Order confirm failure, please try again later";
    static fillBatchNoPrd = "Please fill batch number for the product";
    static fillAllFields = "Please Fill All The Fields";
    static dateSaveSuccess = "Date saved successfully";
    static purchaseReqCreatedSuccess = "Purchase requisition created successfully";
    static selectOnePrd = "Please select atleast one product";
    static suppORdistributor = "Please select Supplier / Distributor name";
    static prdUpdSuccess = "Product updated successfully";
    static errorinupdate="Error in Updating product";
    static fillPrdAndQty = "Please fill Product Name and Quantity";
    static poCancelSuccess = "PO cancelled successfuly";
    static poSaveSuccess = "Successfully saved purchase order details";

    static logOut = "Logged out successfully ";
    static noReportFound = "No Reports Found";
    static noClientFound = "No client found";
    static unableToFetchData ="Unable to fetch data, please try again later";
    static enterNameOrOtherDet = "Enter either name or mobile number to get patient details";
    static plsEnterAdvAmt = "Please enter advance amount";
    static advPaySavedSuccess = "Advance payments data saved successfully";
    static unableToAdvPaySave = "Unable to save advance payments, please try again later";
    static selectFromAndToDate = "Please select both the dates";
    static refundSavedSuccess = "Refund saved successfully";
    static enterRemarks = "Enter the remarks";
    static chargesSavedSuccess = "Charges saved successfully";
    static consultantAddedSuccess = "Consultant added successfully";
    static consultantUpdSuccess = "Consultant updated successfully";
    static enterMobileno = "Enter mobile number";
    static enterEmail = "Enter email";
    static enterHosp = "Enter hospital";
    static clientNotFound = "Client not found";
    static fillText = "Fill in the text";
    static sltSalutation = "Select salutation";
    static sltIdproof = "Select ID proof";
    static splOnlyForChild = "This speciality only for children's";
    static splOnlyForwomen = "This speciality only for women";
    static sltSession = "Please select session";
    static enterPatAddr = "Please enter patient address";
    static sltAppId = "Select appointment id";
    static sltBillType = "Select bill type";
    static enterAmt = "Enter amount";
    static selectrateCard = "Please select Ratecard";
    static enterQty = "Enter quantity";
    static sltServiceType = "Select service type";
    static unableToUpdBill = "Unable to update Bill! please try again later";
    static billUpdSuccess = "Bill updated successfully";
    static noBillsFound = "No Bills Found!";
    static completedApptCantedit = "Completed appointment can't be edited";
    static sltValidSession = "Please select valid session";
    static reasonForReschedule = "Please enter reason for reschedule";
    static sltTime = "Select time";
    static sryApptClosedForSession = "Sorry appointments were closed for the session";
    static sryAlreadyBooked = "Sorry already booked";
    static apptNotCancelled = "Appointment not cancelled successfully,try again later";
    static bookAnotherSession = "Book another session";
    static enterFName = "Please enter first name";
    static enterLName = "Please enter last name";
    static enterZipcode = "Please enter zipcode";
    static plsAddTestBfrSave = "Please add test before save";
    static unableToSavePrescription = "Unable to save prescription, please try again later";
    static dataAlreadyExist = "Data already exist";
    static addSubTest = "Add subtest";
    static testDetNotSaved = "Test details not saved";
    static testCancelledSuccess = "Successfully cancelled the test";
    static testNotDeleted = "Test details not deleted successfully";
    static error = "ERROR";
    static sltDiagCenter = "Select diagnosis centre";
    static noAptForBillCreation = "No Appointments for Bill Creation";
    static sltApptToCancel = "Select appointment to cancel";
    static diagCenterAlrdyRegInLoc = "Diagnosis centre in this location already been registered please select other location";
    static rptApprovedSuccess = "Report approved successfully";
    static rptApprovedNotSuccess = "Report approved not successfully";
    static rptRejectedSuccess = "Successfully rejected report";
    static unableToRejectRpt = "Unable to reject report, please try later";
    static rptUploaddSuccess ="Report upload successfully";
    static unableToUpdRpt = "Unable update report, please try again later";
    static unableToUploadRpt = "Unable report upload, please try again later";
    static enterTestReading = "Please enter the test readings";
    static userDetDeletedSuccess = "User details deleted successfully";
    static testDetNotDeleted = "Test details not deleted!please try again later";
    static maxOnembImg = "Select maximum 1mb image";
    static appSupportsJPGorPng = "Application supports only jpg / png /mp4";
    static userSavedSuccess = "User details saved successfully";
    static userNotSavedSuccess = "User details not saved successfully";
    static enterCorrectMobileno = "Enter mobile number correctly";
    static pwdNotMatched = "Password not matched";
    static sampleCollectedSuccess = "Sample collected successfully";
    static sltDate = "Select date";
    static enterValidTime = "Enter valid time";
    static timeAllottedSuccess = "Time allotted successfully";
    static apptProcessCompleted = "Appointment process has been completed";
    static pastApptCantbeEdited = "Past appointment can't be edited";
    static sltLocAndDate = "Please select the location and date";
    static readingsSavedSuccess = "Readings saved successfully";
    static readingsNotSavedSuccess = "Readings not saved successfully, please try again later";
    
    static confirmPWD = "Please Conform Your Password";
    static regtrSuccess = "Registration successful, please wait for registration approval";
    static unableToRegtr = "Unable to register,try again later";
    static sltIAgree = "Please select i agree";
    static sltProfileImg = "Please select your profile image";
    static personalFieldMandtory = "Enter Personal data mandatory fields";
    static enterSpouseName = "Enter spouse name";
    static enterPresentAddrMandatory = "Enter Present address mandatory fields";
    static enterPermenantAddrMandatory = "Enter Permenant address mandatory fields";
    static validRegtrYear = "Enter valid registration year";
    static invalidRegtrOrDOB = "Either registration year or DOB is invalid";
    static existRegstrCode = "Registration code already exists";
    static addWrkLocation = "Add work location";
    static termsNconditions = "Accept terms & conditions";
    static sltTreatmentType = "Select treatment type";
    static sltSplType = "Select specialization type";
    static sltSubSplType = "Select sub specialization type";
    static invalidTime = "Invalid time";
    static validHosptl = "Enter valid hospital";
    static noHosptlFound = "No hospital found";
    static validYrOfCompletion = "Enter valid year of completion";
    static addQualf = "Add qualification";
    static chkUrMailBox = "Check your mail box";
    static sentSMStoRegtrMobile = "SMS sent to your registered mobile";
    static chkUserId = "Check your user id";
    static chkUrDOB = "Check your DOB";
    static hosptlNotFound = "Hospital not found";
    static enterEmailOrMobNo = "Enter email or mobile number";
    static enterPwd = "Enter password";
    static urAccYetToBeActivated = "Your account yet to be activated";
    static verifyPwd = "Verify Password";
    static chkLoginCredt = "Check login credentials";
    static subscriptionExpired = "Subscription expired, please renew";
    static invalidUser = "Invalid user";
    static renewUrSubscription = "Renew your subscription";
    static docNotAvailableTime = "Doctor not available for this time";
    static sltConsultationTime = "Select consultation time";
    static sltHosptl = "Select hospital";
    static timeSlotAllotedToSomeone = "This time slot has been allotted to someone else, try with another time slot";
    static addHomeCaredata = "Add homecare data";
    static timeSlotsAlrdyBooked = "Time slots already booked";
    static validQty = "Enter valid quantity";
    static notSavedSuccess = "Not saved successfully";
    static sltApptToConfirm = "Select appointment to confirm";
    static apptUpdSuccess = "Appointment updated successfully";
    static enterHomeCareMandatory = "Please enter homecare mandatory fields";
    static addHomeCareConsult = "Please add homecare consultation";
    static verfiyQualf = "Please verify qualification";
    static unableToGetBillDet  = "Unable to get bill details, try later";
    static apptClosedSuccess = "Appointment closed successfully";
    static unableToCloseAppt = "Unable to close appointment, try again";
    static personalCardMandatory = "Please enter Personal card mandatory fields";
    static clinicalCardMandatory = "Please enter Clinical card mandatory fields";
    static treatmentCardMandatory = "Please enter Treatment Plan card mandatory fields";
    static caseHistSavedSuccess = "Case history saved successfully";
    static caseHistNotSaved = "Case history not saved, please try again later";
    static caseHistNotUpd = "Case history not updated, please try again later";

    
    static alrdyExist = "Already exists";
    static prescriptionSavedSuccess = "Prescription saved successfully";
    static noPharmaFound = "No pharmacy found";
    static existTherapy = "Therapy already exists";
    static enterTherapyNPrice = "Please enter therapy and price";
    static therapyDelSuccess = "Therapy deleted successfully";
    static unabltToDelTherapy = "Unable to deleted therapy, try later";
    static therapyReactivatedSuccess = "Therapy reactivated successfully";
    static unabltToReactivateTherapy = "Unable to reactivate therapy, try later";
    static addProg = "Add prognosis";
    static apptSavedSuccess = "Appointment saved successfully";
    static enterPhysioDet = "Please enter physio details";
    static unableToGetApptDet = "Unable to get appointment details, try later";

    static unableToFetchCasesheet = "Unable to fetch casesheet";
    static caseHistUpdSuccess = "Case history updated successfully";
    static concernMandatory = "Please enter concerns mandatory fields";
    static motherAgeMandatory = "Please enter mother_age mandatory fields";
    static gestAgeMandatory = "Please enter gestational_age mandatory fields";
    static delivTypeMandatory = "Please enter delivery_type mandatory fields";
    static noOfPregMandatory = "Please enter no_of_pregnancy mandatory fields";
    static feedHabitMandatory = "Please enter feeding_habit mandatory fields";
    static diseaseMandatory = "Please enter disease mandatory fields";
    static occupMandatory = "Enter occupation mandatory fields";
    static ageMandatory = "Enter age mandatory fields";
    static bmiMandatory = "Enter BMI mandatory fields";
    static diseaseStageMandatory = "Enter Disease stage mandatory fields";
    static treatDialyMandatory = "Enter Treatment Dialysis Type mandatory fields";
    static treatDialy7Mandatory = "Enter Treatment Dialysis Type 7 mandatory fields";
    static treatDialy8Mandatory = "Enter Treatment Dialysis Type 8 mandatory fields";
    static addDietPlan = "Please add diet plan";
    static vitalMandatory = "Please enter vitals mandatory fields";
    static verifyTreaPlan = "Please verify treatment plan";
    static treatPlanMandatory = "Please enter mandatory fields in treatment plan";
    static footAssmMandatory = "Please Enter foot assessment manditory fields";
    static sltChiefComp = "Select chief complients";
    static sltSpeciality = "Select speciality";
    static addClinicalExamTable = "Add clinical examination table data";
    static intraOralMandatory = "Enter intra oral mandatory fields";
    static extraOralMandatory = "Enter extra oral mandatory fields";
    static gumNTeethMandatory = "Enter gums & teeth mandatory fields";
    static physicalStatusMandatory = "Enter physical status mandatory fields";
    static functMandatory = "Enter functional mandatory fields";
    static birthHistMandatory = "Enter birth history mandatory fields";
    static natalHistMandatory = "Enter natal history mandatory fields";
    static generalHistMandatory = "Enter general history mandatory fields";
    static endodonticHistMandatory = "Enter endodontic history  mandatory fields";
    static pediaSpecificMandatory = "Enter pediatric specific  mandatory fields";
    static orthoEvaluationMandatory = "Enter orthodontic evaluation mandatory fields";
    static dentalHistMandatory = "Enter dental history mandatory fields";
    static enterInvestigation = "Enter investigation";
    static surgeryCardMandatory = "Please enter Surgery card mandatory fields";
    static unableToSaveCaseSheet = "Unable to save case sheet, try again later";
    static stressFactorMandatory = "Please enter stress factor mandatory fields";
    static mainConcernMandatory = "Please enter main concern mandatory fields";
    static presentIllMandatory = "Please enter present illness mandatory fields";
    static lastMensPeriodMandatory = "Please enter last menstrual period mandatory fields";
    static stageDateMandatory = "Please enter stage date mandatory fields";
    static painTimingMandatory = "Please enter pain timing mandatory fields";
    static painCharMandatory = "Please enter pain character mandatory fields";
    static noofdaysMandatory = "Please enter no of days mandatory fields";
    static painFrequencyMandatory = "Please enter pain frequency mandatory fields";
    static contraceptiveTypeMandatory = "Please enter contraceptive type mandatory fields";
    static discontReasonMandatory = "Please enter discont reason mandatory fields";
    static papSmearMandatory  = "Please enter pap smear result mandatory fields";
    static drugsMandatory  = "Please enter drugs mandatory fields";
    static skinAbnormMandatory  = "Please enter skin abnormalities mandatory fields";
    static pulseMandatory  = "Please enter pulse mandatory fields";
    static clinicbpMandatory  = "Please enter clinical bp mandatory fields";
    static fetuspresMandatory  = "Please enter fetus presentation mandatory fields";
    static emergtypeMandatory  = "Please enter emergency type mandatory fields";
    static treattypeMandatory  = "Please enter treatment type mandatory fields";
    static emergothersMandatory  = "Please enter emergency others mandatory fields";
    static babygenderMandatory  = "Please enter baby gender mandatory fields";
    static babyweightMandatory  = "Please enter baby weight mandatory fields";
    static contraceptiveMandatory  = "Please enter contraceptive mandatory fields";
    static anaemicMandatory  = "Please enter anaemic mandatory fields";
    static chestcondMandatory  = "Please enter chest condition mandatory fields";
    static breastcondMandatory  = "Please enter Breast condition mandatory fields";
    static puerperiumplanMandatory  = "Please enter Puerperium plan condition mandatory fields";
    static uterineevolveMandatory  = "Please enter uterine evolve condition mandatory fields";
    static palpablebladderMandatory  = "Please enter palpable bladder condition mandatory fields";
    static recomexecMandatory  = "Please enter recommended exercise mandatory fields";
    static postSurgeryMandatory  = "Please enter post surgery mandatory fields";
    static breastfeedingMandatory  = "Please enter breast feeding condition mandatory fields";

    static sltBills = "Please select bills";
    static enterPaidAmount = "Enter paid amout";
    static sltJawType = "Select jaw type";
    static sltTeethType = "Select teeth type";
    static sltTeethName = "Select teeth name";
    static sltFoodType = "Select food type";
    static sltFoodItem = "Select food item";
    static noDocFound = "No doctor found";
    static addNotes = "Add notes";
    static disSummarySavedSuccess = "Discharge summary saved successfully";
    static unabltToSaveDisSummary = "Unable to save discharge summary, please try again";
    static removeSuccess = "Removed successfully";
    static removeNotSuccess = "Removed not successfully, please try again later";
    static noPhysio = "No physiotherapist(s) Found";
    static prflUpdSuccess = "Profile updated successfully. Please login again to view the changes";
    static dataUpdSuccess = "Data updated successfully";
    static dataNotUpd = "Data not updated";
    static billCancelledSuccess = "Bill cancellation is successfull";
    static heightMSValue = "If height measure is inches then height value should be less than 100";
    static imgSavedSuccess = "Image saved successfully";
    static errOccur = "Error Occurred";
    static addTreatmentDet = "Add treatment details";
    static sltPatientForCancel = "Please select patient for cancel";
    static sltPatientForConfirm = "Please select patient for confirm";
    static noDataFound = "No data found";
    static addmissionSavedSuccess = "Addmission created successfully";

    static allRowSlt = "Thank you...', 'All rows are selected!";
    static noRowSlt = "Thank you...', 'No row seleted!";
    static sltMaterialord = "Select material order";

    static enterDiagpres = "Enter Diagnosis prescription";
    static sltTestType = "select test type";
    static sltAtleastOneTest  = "Please select atleast one test to proceed";
    static vitalSavedSuccess = "Vital details saved successfully";
    static vitalNotSavedSuccess = "Vital details not saved, please try again later";
    static enterRmk = "Enter Remarks";
    static cancelClicked = "Cancel clicked";

    static vaccineSavedSuccess = "Vaccine saved successfully";
    static sltDueDate = "Select due date";
    static dataSavedSuccess = "Data saved successfully";
    static deleterack = "Rack deleted successfully";
    static deletebin = "Bin deleted successfully";
    static enterFromDate = "Please enter from date";
    static enterToDate = "Please enter to date";
    static validDate = "Select valid date";
    static addSchedule = "Add schedule";
    static medAlreadySlt = "medicine already selected, are you sure want to add retain both else unselect unwanted";
    static pastTimeAllot = "Past time can not be alloted";
    static sltTimeNotAvail = "Selected time is not available";
    static opnAllotSuccess = "Opinion alloted successfully";
    static opnAllotNotSuccess = "Opinion alloted not successfully";
    static sltValidTime = "Select valid time";
    static enterObserv = "Enter observations";
    static opnUpdSuccess = "Opinion updated successfully";
    static opnUpdNotSuccess = "Opinion updated not successfully";
    static congrtsAcceptTerms = "Congratulations on accepting terms and data protection clauses, please adhere to the policy";
    static addAtlstOneImg = "Add atleast one image to continue";
    static sltReportType = "Select report type";
    static enterReportName = "enter report name";
    static sltSpecializtion = "Select specialization";
    static readingUpdSuccess = "Readings updated successfully";
    static readingUpdNotSuccess = "Not updated successfully, please try again later";
    static unableToFetchBillDet = "Unable to fetch bill details, please try later";
    static enterAddrMandatory = "Enter address mandatory fields";
    static enterReason = "Enter reason";
    static sltApptToDel = "Select appointment to delete";
    static noTest = "No test(s) found";
    static paymentSuccess = "Payment is successfull";
    static sltDoctor = "Select doctor";
    static sltRelation = "Select relation";
    static noDocument = "No documents found";
    static sltDonor = "Select donor";
    static sltYoga = "Select required yoga";
    static splOnlyForFemale = "This speciality only for Female";
    static cannotOrderMoreThanPrescQty = "cannot order more than prescribed quantity concern Doctor";
    static noMedToOrder = "No medicine to order";
    static orderPlaced = "Order placed successfully";
    static enterDosage = "Enter dosage";
    static enterSideEffect = "Enter side effect";
    static orderCancelSuccess = "Order cancel successfully";
    static enterDeliverBy = "Enter delivered by";
    static sltAppt = "Select appointments";
    static enterSleepDur = "Enter sleep duration";
    static sltSleepPattern = "Select sleep pattern";
    static noMedDet = "No medicine details found";
    static sltOrderType = "Select order type";
    static enterSuppltName = "Enter supplement name";
    static enterReact = "Enter reaction";
    static existUrFatherDet = "Your father details already exits";
    static existUrMomDet = "Your Mother details already exits";
    static existUrSpouseDet = "Your spouse  details already exits";
    static enterAddr1 = "Enter address1 fields";
    static enterLoc = "Enter location fields";
    static relSavedSuccess = "Successfully saved relations details";
    static unableToDelDocument = "Unable to delete document";
    static existTest = "Selected test already exists";
    static maxFiveReportsCanAttach = "Maximum five reports can be attached";
    static confirmAboveDet = "Please confirm above details";
    static sltMainConcern = "Select main concerns";
    static sltExercise = "Select excercise";
    static chkFileFormat = "Check the file format try only uploading '.jpeg','.jpg', '.png', '.gif', '.bmp','pdf'"
    static fileAddSuccess = "File added successfully";
    static sltFile = "Please select file";
    static addPrescMedFile = "Add prescribed medications file";
    static addOTC = "Add otc data";
    static addHM = "Add health and medication data";
    static addVitOrSupplt = "Add vitamins or supplement data";
    static addAllergy = "Add allergy data";
    static sltFamilyMember = "Please select family member(s)";
    static addDiscount = "Please add discount details";
    static noDrugDetFoundToSave = "No drug details were found to take inward";
    static alteastOneOffer = "Please add atleast one offer Or Add the package image";
    static dietappupdate = "Patient registration yet to be completed";
    static leadgen="Successfully created lead generation";
    static greateramount="Amount is greater than Paid amount";
    
    static getGreaterAmount(){
        return this.greateramount;
    }
    
    static saveLeadGenerate(){
        return this.leadgen;
    }
    static getDietAppUpdate(){
        return this.dietappupdate;
    }
    static getGenralError(){
        return this.genral;
    }
    static NoAppointments(){
        return this.Noappoinment;
    }
    static getAppconf(){
        return this.appconf;
    }

    static getCompapps(){
        return this.compapp;
    }

    static getFutureapp(){
        return this.futureapp;
    }

    static getPastapp(){
        return this.pastapp;
    }

    static getSelectapp(){
        return this.selectapp;
    }

    static getAppcancel(){
        return this.appcancel;
    }

    static getMaxlen150(){
        return this.maxlen150;
    }
    static getAppcancelreason(){
        return this.appcancelreason;
    }

    static getPasschange(){
        return this.passchange;
    }
    static getPassUnchange(){
        return this.passunchange;
    }

    static getBillUngen(){
        return this.billungen;
    }

    static getBillGen(){
        return this.billgen;
    }

    static getAddBill(){
        return this.addbill;
    }
    static getAlertData(){
        return this.alert;
    }
    
    static getHomeAssign(){
        return this.homeassign;
    }

    static getHomeUnAssign(){
        return this.homeunassign;
    }
    
    static getPastDateMessage(){
        return this.pastdate;
    }
    
    static getFutureDateMessage(){
        return this.futuredate;
    }

    static getNetworkMessage(){
        return this.network;
    }

    static getMandatory(){
        return this.mandatory;
    }
    
    static apptconfirm="Confirm one at a time to generate token number";

}