<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Stock ledger</h5>
          </div>
          <div class="headerButtons">
          </div>
        </div>

      </mdb-card-header>
      <mdb-card-body>
        <div class="row">
        


          <div class="col-12" style="margin-bottom:15px;">
            <div class="row">
              <div class="col-3">
                  <input type="text" class="ipcss order_width getMedcien" maxlength="50"
                   maxlength="50" [(ngModel)]="patientName" matInput (keyup)="getdatausingname(patientName)"
                  />

              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-6 col-xl-6">
                <mat-label class="matlabel"><br>
                  <img src="../../../assets/ui_icons/buttons/XL_export.svg" class="saveimgbtn_inpatinfo"
                    (click)="exportToExcel()" style="    position: relative;
                    bottom: 17px !important;"/>
                </mat-label>
              </div>
              <div class="col-2" style="margin-left:10px !important">
                <mat-label class="matlabel"style="position: relative;
                bottom: 17px !important;" >Per page<br>
                  <select [(ngModel)]="pagelist" class="ipcss" (change)="changePageList()">
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="full">Full</option>
                  </select>
                </mat-label>
              </div>
            </div>
          </div>
          <div *ngIf="viewbutton">

          <div class="col-12 stock_ledger_table" *ngIf="stockData.length != 0">
            <table  class="table-fixed outline_setup rounded-3" >
              <thead>
                <tr class="background-color_b" style="height: 35px;">
                  <th style="font-weight: 400;width:10%;text-align: center;">S.no</th>
                  <th style="width:20%;font-weight: 400;text-align: center;">Medicine</th>
                  <th style="width:20%;font-weight: 400;text-align: center;">
                    Supplier</th>
                  <th style="font-weight: 400;width: 16%;text-align: center;">
                    Batch</th>
                  <th style="font-weight: 400;width: 10%;text-align: center;">
                    Rack</th>
                    <th style="font-weight: 400;width:10%;text-align: center;">Bin</th>
                  <th style="font-weight: 400;width: 16%;text-align: center;">Expiry date</th>
                  <th style="font-weight: 400;width: 10%;text-align: center;">Min</th>
                    <th style="font-weight: 400;width: 10%;text-align: center;">Max</th>
                    <th style="font-weight: 400;width: 13%;text-align: center;">Danger</th>
                    <th style="font-weight: 400;width:14%;text-align: center;">Avbl qty</th>
                </tr>
              </thead>
    
              <tbody>
                <tr *ngFor="let stock of stockData; let i = index" style="height: 30px;">
                  <td>{{stock.sn}}</td>
                  <td
                    style="font-size: 12px; text-align: left;padding: 5px;">
                    {{stock.short_name}} - {{stock.name}}
                  </td>
                  <td style="font-size: 12px;text-align: left;padding: 5px;">
                    {{stock.supplier}}
                  </td>
                  <td style="font-size: 12px;text-align: center;">
                    {{stock.batch_no}}
                  </td>
                    <td style="text-align: center;">
                      {{stock.rack}}
                      </td>
                  <td style="text-align: center;">
                    {{stock.bin}}
                    
                    </td>
                  <td style="text-align: center;">
                    {{stock.exp_date}}
                    
                    </td>

                    <td style="text-align: center;">
                    {{stock.min_level}}
                  </td>
                    <td style="text-align: center;">
                    {{stock.max_level}}
                  </td>
                    <td style="text-align: center;">
                    {{stock.danger_level}}
                  </td>
                    <td style="text-align: center;">
                    {{stock.qty_onhand}}
                  </td>

                </tr>
              </tbody>
            </table>
          </div>

          <div *ngIf="stockData != undefined && stockData.length !=0">
            <div class="row" style="text-align: center;margin-top: 5px;">
                <div class="col-3 col-sm-3 col-md-4 col-lg-4 col-xl-4 select_bottom"> </div>
                <div class="col-3 col-sm-3 col-md-1 col-lg-1 col-xl-1 select_bottom" >
                <input id="Text1" type="image" [disabled]="prevPageCount" class="pag_images" (click)="pageLimitPrevious()"
                    src="../../../assets/ui_icons/arrow_blue.svg" />
                </div>
                <div class="col-3 col-sm-3 col-md-1 col-lg-1 col-xl-1 select_bottom">
                <span class="current_font">{{currentPage}}/{{numberOfPages()||1}}</span>
                </div>
    
                <div class="col-3 col-sm-3 col-md-1 col-lg-1 col-xl-1 select_bottom">
                <input id="Image1" type="image" [disabled]="nextPageCount" class="pag_images" (click)="pageLimitnext()"
                    src="../../../assets/ui_icons/arrow_blue r.svg" />
                </div>                     
            </div>
        </div> 

          </div>
          <div class="col-12" *ngIf="stockData.length == 0">
            <p class="nodata">No data found</p>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>