<script src="https://code.jquery.com/jquery-3.3.1.min.js"></script>

<div *ngIf="readingTab == 'min'">
  <div class="row" style="margin:0 20px">
    <div class="col-12" style="height: 40px;border-bottom: 1px solid rgb(216, 216, 216);">
      <div class="matlabel" style="font-weight: 500;"> Current</div>
    </div>
    <div class="col-12" style="height: 40px;border-bottom: 1px solid rgb(216, 216, 216);">
      <div class="matlabel" style="font-weight: 500;"> Past 1 month</div>
    </div>
    <div class="col-12" style="height: 40px;border-bottom: 1px solid rgb(216, 216, 216);">
      <div class="matlabel" style="font-weight: 500;"> Past 3 month</div>
    </div>
    <div class="col-12" style="height: 40px;border-bottom: 1px solid rgb(216, 216, 216);">
      <div class="matlabel" style="font-weight: 500;">Past 6 month</div>
    </div>
    <div class="col-12" style="height: 40px;border-bottom: 1px solid rgb(216, 216, 216);">
      <div class="matlabel" style="font-weight: 500;"> Past 1 year</div>
    </div>
    <div class="col-12" style="height: 40px;">
      <div class="matlabel" style="font-weight: 500;">Past 2 years</div>
    </div>
  </div>
</div>

<div *ngIf="readingTab == 'max'">
  <!-- <div [hidden]="nodapp" class="nodata">No reading(s) found</div> -->
  <div class="padd_top_btm">
    <mat-accordion class="mataccordion">
      <mat-expansion-panel class="expandpanel" [expanded]="true" (click)="tab_click('1')">
        <mat-expansion-panel-header class="exppanel width ">
          <mat-panel-title class="title ">
            Current
          </mat-panel-title>
        </mat-expansion-panel-header>
        <hr class="hr">
        <div style="margin-top: 15px;">
          <div class="row">
            <div class="col-12 col-md-6 col-lg-6 col-xl-6">
              <mat-card class="">
                <div class="appint_graph">
                  <div id="Third_lvl2" class="chart_div"></div>
                  <p class="text-center cahrat_para">Blood glucose</p>
                </div>
              </mat-card>
            </div>
            <div class="col-12 col-md-6 col-lg-6 col-xl-6">
              <mat-card class="">
                <div class="appint_graph">
                  <div [hidden]="HbA1c_no_data" class="graph_dataunavail">
                    <p class="Paraclass">
                      <img src="../../../assets/img/Warning.png" class="Imageclass" />No data found
                    </p>
                  </div>
                  <div id="Third_lvl1" class="chart_div" [hidden]="!HbA1c_no_data"></div>
                  <p class="text-center cahrat_para">HbA1c</p>
                </div>
              </mat-card>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6 col-lg-6 col-xl-6">
              <mat-card class="">
                <div class="appint_graph">
                  <!-- <h1>Cur_Urine_avail {{Cur_Urine_avail}} </h1> -->
                  <!-- <h1>Cur_Urine_avail {{Cur_Urine_avail}} </h1> -->
                  <div [hidden]="!Cur_Urine_avail" class="graph_dataunavail">
                    <p class="Paraclass">
                      <img src="../../../assets/img/Warning.png" class="Imageclass" />No data found
                    </p>
                  </div>
                  <div [hidden]="Cur_Urine_avail" id="Urine_Glucose" class="chart_div"></div>
                  <p class="text-center text-center cahrat_para">Urine glucose</p>
                </div>

              </mat-card>
            </div>
            <div class="col-12 col-md-6 col-lg-6 col-xl-6">
              <mat-card class="">
                <div class="appint_graph">
                  <div [hidden]="Cur_Ketone_unavail" class="graph_dataunavail">
                    <p class="Paraclass">
                      <img src="../../../assets/img/Warning.png" class="Imageclass" />No data found
                    </p>
                  </div>
                  <div [hidden]="!Cur_Ketone_unavail" id="cur_ketone" class="chart_div"></div>
                  <p class="text-center cahrat_para">Ketone</p>
                </div>
              </mat-card>
            </div>
          </div>
          <div class="row ">
            <div class="col-12 col-md-6 col-lg-6 col-xl-6">
              <mat-card class="">
                <div class="appint_graph">
                  <div [hidden]="Cur_Protein_unavail" class="graph_dataunavail">
                    <p class="Paraclass">
                      <img src="../../../assets/img/Warning.png" class="Imageclass" />No data found
                    </p>
                  </div>
                  <div [hidden]="!Cur_Protein_unavail" id="cur_protein" class="chart_div"></div>
                  <p class="text-center cahrat_para">Protein</p>
                </div>

              </mat-card>
            </div>
            <div class="col-12 col-md-6 col-lg-6 col-xl-6">
              <mat-card class="">
                <div class="appint_graph">
                  <div [hidden]="Cur_Cholesterol_unavail" class="graph_dataunavail">
                    <p class="Paraclass">
                      <img src="../../../assets/img/Warning.png" class="Imageclass" />No data found
                    </p>
                  </div>
                  <div [hidden]="!Cur_Cholesterol_unavail" id="cur_colestrol" class="chart_div"></div>
                  <p class="text-center cahrat_para">Cholesterol</p>
                </div>
              </mat-card>
            </div>

          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <!-- expansion2ng s -->
  <div class="padd_top_btm">
    <mat-accordion class="mataccordion">
      <mat-expansion-panel  class="expandpanel" (click)="tab_click('2')">
        <mat-expansion-panel-header class="exppanel width">
          <mat-panel-title class="title ">
            Past 1 month 
            <!-- <mat-icon *ngIf="!panelOpenState2">add</mat-icon>
                     <mat-icon *ngIf="panelOpenState2">remove</mat-icon>    -->
          </mat-panel-title>
        </mat-expansion-panel-header>
        <hr class="hr">
        <div style="margin-top: 15px;">
          <div class="row">
            <div class="col-12 col-md-6 col-lg-6 col-xl-6">

              <mat-card class="">
                <div class="appint_graph">
                  <div id="Blood_Glucose_Chart" class="chart_div"></div>
                  <div>
                    <!-- <mat-icon height="40px" width="40px" style="position: absolute;"
                              (click)="reading_poup('1','Blood_Glucose_Chartzoom')">zoom_in</mat-icon> -->
                    <p class="text-center cahrat_para">Blood glucose</p>
                  </div>
                </div>

              </mat-card>
            </div>
            <div class="col-12 col-md-6 col-lg-6 col-xl-6">
              <mat-card class="">
                <div class="appint_graph">
                  <div [hidden]="HbA1c_one_no_data">
                    <div class="graph_dataunavail">
                      <p class="Paraclass">
                        <img src="../../../assets/img/Warning.png" class="Imageclass" />No data found
                      </p>
                    </div>
                  </div>
                  <div [hidden]="!HbA1c_one_no_data">
                    <div id="chartContainer5" class="chart_div"></div>
                  </div>
                  <div>
                    <!-- <mat-icon height="40px" width="40px" style="position: absolute;"
                              (click)="reading_poup('1','chartContainer5zoom')" [hidden]="HbA1c_one_graph">zoom_in</mat-icon> -->
                    <p class="text-center cahrat_para">HbA1c</p>
                  </div>
                </div>
              </mat-card>
            </div>
          </div>
          <div class=" row">
            <div class="col-12 col-md-6 col-lg-6 col-xl-6">
              <mat-card class="">
                <div class="appint_graph">
                  <div [hidden]="blood_glu_one_notavail" class="graph_dataunavail">
                    <p class="Paraclass">
                      <img src="../../../assets/img/Warning.png" class="Imageclass" />No data found
                    </p>
                  </div>
                  <div id="Urine_Charts" class="chart_div" [hidden]="!blood_glu_one_notavail"></div>
                  <div>
                    <!-- <mat-icon height="40px" width="40px" style="position: absolute;"
                                  (click)="reading_poup('1','Urine_Chartszoom')" [hidden]="blood_glu_one_avail">zoom_in</mat-icon> -->
                    <p class="text-center cahrat_para">Urine glucose</p>
                  </div>
                </div>
              </mat-card>
            </div>
            <div class="col-12 col-md-6 col-lg-6 col-xl-6">
              <mat-card class="">
                <div class="appint_graph">
                  <div [hidden]="coles_one_notavail" class="graph_dataunavail">
                    <p class="Paraclass">
                      <img src="../../../assets/img/Warning.png" class="Imageclass" />No data found
                    </p>
                  </div>
                  <div id="chartContainer6" class="chart_div" [hidden]="!coles_one_notavail"></div>
                  <div>
                    <!-- <mat-icon
                                  (click)="reading_poup('1','chartContainer6zoom')" [hidden]="coles_one_avail">zoom_in</mat-icon> -->
                    <p class="text-center cahrat_para">Cholesterol</p>
                  </div>
                </div>
              </mat-card>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <!-- expansionpast3  s -->
  <div class="padd_top_btm">
    <mat-accordion class="mataccordion">
      <mat-expansion-panel  class="expandpanel" (click)="tab_click('3')">
        <mat-expansion-panel-header class="exppanel width">
          <mat-panel-title class="title">
            Past 3 months 
            <!-- <mat-icon *ngIf="!panelOpenState1">add</mat-icon>
                     <mat-icon *ngIf="panelOpenState1">remove</mat-icon>    -->
          </mat-panel-title>
        </mat-expansion-panel-header>
        <hr class="hr">
        <div style="margin-top: 15px;">
          <div class="row">
            <div class="col-12 col-md-6 col-lg-6 col-xl-6">
              <mat-card class="">
                <div class="appint_graph">
                  <div id="Blood_three_Glucose_Chart" class="chart_div"></div>
                  <div>
                    <!-- <mat-icon height="40px" width="40px" style="position: absolute;"
                              (click)="reading_poup('3','Blood_three_Glucose_Chartzoom')">zoom_in</mat-icon> -->
                    <p class="text-center cahrat_para">Blood glucose</p>
                  </div>
                </div>

              </mat-card>
            </div>
            <div class="col-12 col-md-6 col-lg-6 col-xl-6">
              <mat-card class="">
                <div class="appint_graph">
                  <div [hidden]="HbA1c_three_no_data" class="graph_dataunavail">
                    <p class="Paraclass">
                      <img src="../../../assets/img/Warning.png" class="Imageclass" />No data found
                    </p>
                  </div>
                  <div id="hbA1c_three" class="chart_div" [hidden]="!HbA1c_three_no_data"></div>
                  <div>
                    <!-- <mat-icon height="40px" width="40px" style="position: absolute;"
                              (click)="reading_poup('3','hbA1c_threezoom')" [hidden]="HbA1c_three_graph">zoom_in</mat-icon> -->
                    <p class="text-center cahrat_para">HbA1c</p>
                  </div>
                </div>
              </mat-card>
            </div>

          </div>
          <div class="row">
            <div class="col-12 col-md-6 col-lg-6 col-xl-6">
              <mat-card class="">
                <div class="appint_graph">
                  <div [hidden]="urine_glu_notavail" class="graph_dataunavail">
                    <p class="Paraclass">
                      <img src="../../../assets/img/Warning.png" class="Imageclass" />No data found
                    </p>
                  </div>
                  <div id="Urine_three_Charts" class="chart_div" [hidden]="!urine_glu_notavail"></div>
                  <div>
                    <!-- <mat-icon  height="40px" width="40px" style="position: absolute;"
                                  (click)="reading_poup('3','Urine_three_Chartszoom')" [hidden]="urine_glu_avail">zoom_in</mat-icon> -->
                    <p class="text-center cahrat_para">Urine glucose</p>
                  </div>
                </div>
              </mat-card>
            </div>
            <div class="col-12 col-md-6 col-lg-6 col-xl-6">
              <mat-card class="">
                <div class="appint_graph">
                  <div [hidden]="coles_three_notavail" class="graph_dataunavail">
                    <p class="Paraclass">
                      <img src="../../../assets/img/Warning.png" class="Imageclass" />No data found
                    </p>
                  </div>
                  <div id="coles_three_graph" class="chart_div" [hidden]="!coles_three_notavail"></div>
                  <div>
                    <!-- <mat-icon height="40px" width="40px" style="position: absolute;"
                                  (click)="reading_poup('3','coles_three_graphzoom')" [hidden]="coles_three_avail"></mat-icon> -->
                    <p class="text-center cahrat_para">Cholesterol</p>
                  </div>
                </div>
              </mat-card>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>

  </div>
  <!-- expansionpast6  s -->
  <div class="padd_top_btm">
    <mat-accordion class="mataccordion">
      <mat-expansion-panel  class="expandpanel" (click)="tab_click('6')">
        <mat-expansion-panel-header class="exppanel width">
          <mat-panel-title class="title">
            Past 6 months 
            <!-- <mat-icon *ngIf="!panelOpenState4">add</mat-icon>
                 <mat-icon *ngIf="panelOpenState4">remove</mat-icon>    -->
          </mat-panel-title>
        </mat-expansion-panel-header>
        <hr class="hr">
        <div style="margin-top: 15px;">
          <div class="row">
            <div class="col-12 col-md-6 col-lg-6 col-xl-6">
              <mat-card class="">
                <div class="appint_graph">
                  <div id="Blood_six_Glucose_Chart" class="chart_div"></div>
                  <div>
                    <!-- <mat-icon height="40px" width="40px" style="position: absolute;"
                            (click)="reading_poup('6','Blood_six_Glucose_Chartzoom')">zoom_in</mat-icon> -->
                    <p class="text-center cahrat_para">Blood glucose</p>
                  </div>
                </div>

              </mat-card>
            </div>
            <div class="col-12 col-md-6 col-lg-6 col-xl-6">
              <mat-card class="">
                <div class="appint_graph">
                  <div [hidden]="HbA1c_six_no_data" class="graph_dataunavail">
                    <p class="Paraclass">
                      <img src="../../../assets/img/Warning.png" class="Imageclass" />No data found
                    </p>
                  </div>
                  <div id="HbA1c_six" class="chart_div" [hidden]="!HbA1c_six_no_data"></div>
                  <div>
                    <!-- <mat-icon height="40px" width="40px" style="position: absolute;"
                            (click)="reading_poup('6','HbA1c_sixzoom')" [hidden]="HbA1c_six_graph">zoom_in</mat-icon> -->
                    <p class="text-center cahrat_para">HbA1c</p>
                  </div>
                </div>
              </mat-card>
            </div>

          </div>
          <div class=" row">
            <div class="col-12 col-md-6 col-lg-6 col-xl-6">
              <mat-card class="">
                <div class="appint_graph">
                  <div [hidden]="blood_glu_six_notavail" class="graph_dataunavail">
                    <p class="Paraclass">
                      <img src="../../../assets/img/Warning.png" class="Imageclass" />No data found
                    </p>
                  </div>
                  <div id="Urine_six_Charts" class="chart_div" [hidden]="!blood_glu_six_notavail"></div>
                  <div>
                    <!-- <mat-icon height="40px" width="40px" style="position: absolute;"
                              (click)="reading_poup('6','Urine_six_Chartszoom')" [hidden]="blood_glu_six_avail">zoom_in</mat-icon> -->
                    <p class="text-center cahrat_para">Urine glucose</p>
                  </div>
                </div>
              </mat-card>
            </div>
            <div class="col-12 col-md-6 col-lg-6 col-xl-6">
              <mat-card class="">
                <div class="appint_graph">
                  <div [hidden]="coles_six_notavail" class="graph_dataunavail">
                    <p class="Paraclass">
                      <img src="../../../assets/img/Warning.png" class="Imageclass" />No data found
                    </p>
                  </div>
                  <div id="coles_six_month" class="chart_div" [hidden]="!coles_six_notavail"></div>
                  <div>
                    <!-- <mat-icon height="40px" width="40px" style="position: absolute;"
                              (click)="reading_poup('6','coles_six_monthzoom')" [hidden]="coles_six_avail">zoom_in</mat-icon> -->
                    <p class="text-center cahrat_para">Cholesterol</p>
                  </div>
                </div>
              </mat-card>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>

  </div>
  <!-- expansionpast1 yr  s -->
  <div class="padd_top_btm">

    <mat-accordion class="mataccordion">
      <mat-expansion-panel  class="expandpanel" (click)="tab_click('1year')">
        <mat-expansion-panel-header class="exppanel width">
          <mat-panel-title class="title">
            Past 1 year 
            <!-- <mat-icon *ngIf="!panelOpenState5">add</mat-icon>
                 <mat-icon *ngIf="panelOpenState5">remove</mat-icon>    -->
          </mat-panel-title>
        </mat-expansion-panel-header>
        <hr class="hr">

        <div style="margin-top: 15px;">
          <div class=" row">
            <div class="col-12 col-md-6 col-lg-6 col-xl-6">
              <mat-card class="">
                <div class="appint_graph">
                  <div id="Blood_oneyear_Glucose_Chart" class="chart_div"></div>
                  <mat-card-content>
                    <div>
                      <!-- <mat-icon height="40px" width="40px" style="position: absolute;"
                              (click)="reading_poup('1year','Blood_oneyear_Glucose_Chartzoom')">zoom_in</mat-icon> -->
                      <p class="text-center cahrat_para">Blood glucose</p>
                    </div>
                  </mat-card-content>
                </div>

              </mat-card>
            </div>
            <div class="col-12 col-md-6 col-lg-6 col-xl-6">
              <mat-card class="">
                <div class="appint_graph">
                  <div [hidden]="HbA1c_1year_no_data" class="graph_dataunavail">
                    <p class="Paraclass">
                      <img src="../../../assets/img/Warning.png" class="Imageclass" />No data found
                    </p>
                  </div>
                  <div id="HbA1c_1year" class="chart_div" [hidden]="!HbA1c_1year_no_data"></div>
                  <mat-card-content>
                    <div>
                      <!-- <mat-icon height="40px" width="40px" style="position: absolute;"
                              (click)="reading_poup('1year','HbA1c_1yearzoom')" [hidden]="HbA1c_1year_graph">zoom_in</mat-icon> -->
                      <p class="text-center cahrat_para">HbA1c</p>
                    </div>
                  </mat-card-content>
                </div>
              </mat-card>
            </div>

          </div>
          <div class="row">
            <div class="col-12 col-md-6 col-lg-6 col-xl-6">
              <mat-card class="">
                <div class="appint_graph">
                  <div [hidden]="blood_glu_1year_notavail" class="graph_dataunavail">
                    <p class="Paraclass">
                      <img src="../../../assets/img/Warning.png" class="Imageclass" />No data found
                    </p>
                  </div>
                  <div id="Urine_1year_Charts" class="chart_div" [hidden]="!blood_glu_1year_notavail"></div>
                  <mat-card-content>
                    <div>
                      <!-- <mat-icon height="40px" width="40px" style="position: absolute;"
                              (click)="reading_poup('1year','Urine_1year_Chartszoom')" [hidden]="blood_glu_1year_avail">zoom_in</mat-icon> -->
                      <p class="text-center cahrat_para">Urine glucose</p>
                    </div>
                  </mat-card-content>
                </div>
              </mat-card>
            </div>
            <div class="col-12 col-md-6 col-lg-6 col-xl-6">
              <mat-card class="">
                <div class="appint_graph">
                  <div [hidden]="coles_1year_notavail" class="graph_dataunavail">
                    <p class="Paraclass">
                      <img src="../../../assets/img/Warning.png" class="Imageclass" />No data found
                    </p>
                  </div>
                  <div id="coles_1year_month" class="chart_div" [hidden]="!coles_1year_notavail"></div>
                  <mat-card-content></mat-card-content>
                  <div>
                    <!-- <mat-icon height="40px" width="40px" style="position: absolute;"
                              (click)="reading_poup('1year','coles_1year_monthzoom')" [hidden]="coles_1year_avail">zoom_in</mat-icon> -->
                    <p class="text-center cahrat_para">Cholesterol</p>
                  </div>
                  <!-- <app-graphpopup id="modal-5"></app-graphpopup> -->
                </div>
              </mat-card>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>

  </div>
  <!-- expansionpast2 yr  s -->
  <div class="padd_top_btm">

    <mat-accordion class="mataccordion">
      <mat-expansion-panel  class="expandpanel" (click)="tab_click('2year')">
        <mat-expansion-panel-header class="exppanel width">
          <mat-panel-title class="title">
            Past 2 years 
            <!-- <mat-icon *ngIf="!panelOpenState6">add</mat-icon>
                 <mat-icon *ngIf="panelOpenState6">remove</mat-icon>    -->
          </mat-panel-title>
        </mat-expansion-panel-header>
        <hr class="hr">

        <div style="margin-top: 15px;">
          <div class="row">
            <div class="col-12 col-md-6 col-lg-6 col-xl-6">
              <mat-card class="">
                <div class="appint_graph">
                  <div id="Blood_twoyear_Glucose_Chart" class="chart_div"></div>
                  <div>
                    <!-- <mat-icon height="40px" width="40px" style="position: absolute;"
                              (click)="reading_poup('2year','Blood_twoyear_Glucose_Chartzoom')">zoom_in</mat-icon> -->
                    <p class="text-center cahrat_para">Blood glucose</p>
                  </div>
                </div>

              </mat-card>
            </div>
            <div class="col-12 col-md-6 col-lg-6 col-xl-6">
              <mat-card class="">
                <div class="appint_graph">
                  <div [hidden]="HbA1c_2year_no_data" class="graph_dataunavail">
                    <p class="Paraclass">
                      <img src="../../../assets/img/Warning.png" class="Imageclass" />No data found
                    </p>
                  </div>
                  <div id="HbA1c_2year" class="chart_div" [hidden]="!HbA1c_2year_no_data"></div>
                  <div>
                    <!-- <mat-icon height="40px" width="40px" style="position: absolute;"
                              (click)="reading_poup('2year','HbA1c_2yearzoom')" [hidden]="HbA1c_2year_graph">zoom_in</mat-icon> -->
                    <p class="text-center cahrat_para">HbA1c</p>
                  </div>
                </div>
              </mat-card>
            </div>

          </div>
          <div class="row">
            <div class="col-12 col-md-6 col-lg-6 col-xl-6">
              <mat-card class="">
                <div class="appint_graph">
                  <div [hidden]="blood_glu_2year_notavail" class="graph_dataunavail">
                    <p class="Paraclass">
                      <img src="../../../assets/img/Warning.png" class="Imageclass" />No data found
                    </p>
                  </div>
                  <div id="Urine_2year_Charts" class="chart_div" [hidden]="!blood_glu_2year_notavail"></div>
                  <div>
                    <!-- <mat-icon height="40px" width="40px" style="position: absolute;"
                              (click)="reading_poup('2year','Urine_2year_Chartszoom')" [hidden]="blood_glu_2year_avail">zoom_in</mat-icon> -->
                    <p class="text-center cahrat_para">Urine glucose</p>
                  </div>
                </div>
              </mat-card>
            </div>
            <div class="col-12 col-md-6 col-lg-6 col-xl-6">
              <mat-card class="">
                <div class="appint_graph">
                  <div [hidden]="coles_2year_notavail" class="graph_dataunavail">
                    <p class="Paraclass">
                      <img src="../../../assets/img/Warning.png" class="Imageclass" />No data found
                    </p>
                  </div>
                  <div id="coles_2year_month" class="chart_div" [hidden]="!coles_2year_notavail"></div>
                  <div>
                    <!-- <mat-icon height="60px" width="40px" style="position: absolute;"
                              (click)="reading_poup('2year','coles_2year_monthzoom')" [hidden]="coles_2year_avail">zoom_in</mat-icon> -->
                    <p class="text-center cahrat_para">Cholesterol</p>
                  </div>
                  <!-- <app-graphpopup id='modal'></app-graphpopup> -->
                </div>
              </mat-card>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>

  </div>
  <div class="">
    <!-- <img src="assets/imgs/button_icon/back.png" class="button_width" (click)="backClicked()" /> -->
  </div>
</div>