<div mat-dialog-title class="popup_border_icd">
  <h5 class="header_txt">History</h5>
  <div mat-dialog-actions>
    <img src="../../../assets/img/close_icon.png" (click)="dismiss()" width="20px" height="20px" id="close_icd" />
  </div>
</div>
<div mat-dialog-content class="cardio_content">
  <div class="container">
    <div [hidden]="nodataapp" class="nodata col-12">No history found</div>
    <div class="row" style="max-height: 250px;">

      <div class="col-12 ">
        <!-- <div [hidden]="nodataapp" class="nodata">No history found</div> -->
        <!-- clinical history-->
        <table id="tbl" [hidden]="clinical_table">
          <tr>
            <th>Stress factors</th>
            <th>Heart sound</th>
            <th>Murmurs</th>
            <th>BP (right)</th>
            <th>BP (left)</th>
            <th>Date</th>
          </tr>

          <tr *ngFor="let analysis of clinical_data">
            <td>{{analysis.stress_factor}}</td>
            <td>{{analysis.heart_sound}}</td>
            <td>{{analysis.murmurs}}</td>
            <td>{{analysis.bp_right}}</td>
            <td>{{analysis.bp_left}}</td>

            <td class="diet_histtable_date_td">{{analysis.created_date | date:'dd/MM/yyyy'}}</td>
          </tr>
        </table>

        <!--clinical pain history-->
        <table id="tbl" [hidden]="pain_table">
          <tr>
            <th>Pain</th>
            <th>Location</th>
            <th>Character</th>
            <th>Radiation</th>
            <th>Cause</th>
            <th>Date</th>
          </tr>

          <tr *ngFor="let painloc of pain_data">
            <td>{{painloc.pain}}</td>
            <td>{{painloc.location}}</td>
            <td>{{painloc.character}}</td>
            <td>{{painloc.radiation}}</td>
            <td>{{painloc.cause}}</td>
            <td class="diet_histtable_date_td">{{painloc.created_date | date:'dd/MM/yyyy'}}</td>
          </tr>
        </table>

        <!--clinical breathing history-->
        <table id="tbl" [hidden]="breath_table">
          <tr>
            <th>Triggers</th>
            <th>Onset</th>
            <th>Dizziness</th>
            <th>Palpitation</th>
            <th>Caugh</th>
            <th>Date</th>
          </tr>

          <tr *ngFor="let breath of breath_data">
            <td>{{breath.trigger}}</td>
            <td>{{breath.onset}}</td>
            <td>{{breath.dizziness}}</td>
            <td>{{breath.palpitation}}</td>
            <td>{{breath.caugh}}</td>
            <td class="diet_histtable_date_td">{{breath.created_date | date:'dd/MM/yyyy'}}</td>
          </tr>
        </table>

        <!--clinical physical history-->
        <table id="tbl" [hidden]="physical_table">
          <tr>
            <th>Face</th>
            <th>Eyes</th>
            <th>Nails</th>
            <th>Lip & tongue</th>
            <th>Hand & arm</th>
            <th>Cap refill</th>
            <th>Date</th>
          </tr>

          <tr *ngFor="let physical of physical_data">
            <td>{{physical.face}}</td>
            <td>{{physical.eyes}}</td>
            <td>{{physical.nails}}</td>
            <td>{{physical.lip_tongue}}</td>
            <td>{{physical.hand_arm}}</td>
            <td>{{physical.cap_refill}}</td>
            <td class="diet_histtable_date_td">{{physical.created_date | date:'dd/MM/yyyy'}}</td>
          </tr>
        </table>

        <!--diagnosis history-->
        <table id="tbl" [hidden]="diagnosis_table">
          <tr>
            <th>Disorder</th>
            <th>Med procedure</th>
            <th>Post opt complexities</th>
            <th>exercise</th>
            <th>Duration</th>
            <th>Frequency</th>
            <th>Date</th>
          </tr>

          <tr *ngFor="let diagnosis of diagnosis_data">
            <td>{{diagnosis.disease_type}}</td>
            <td>{{diagnosis.med_procedure}}</td>
            <td>{{diagnosis.opt_complex}}</td>
            <td>{{diagnosis.exec_desc}}</td>
            <td>{{diagnosis.exec_dur}}</td>
            <td>{{diagnosis.exec_freq}}</td>
            <td class="diet_histtable_date_td">{{diagnosis.created_date | date:'dd/MM/yyyy'}}</td>
          </tr>
        </table>

        <!-- Habitual  history -->
        <table id="tbl" [hidden]="habitual_history_data.length==0">
          <tr>
            <th>Alchol frequency (per day)</th>
            <th>Alchol quantity</th>
            <th>Smoke frequency (per day)</th>
            <th>Gutka frequency (per day)</th>
            <th>Exercise duration</th>
            <th>Date</th>
          </tr>

          <tr *ngFor="let habitual of habitual_history_data">
            <td>{{habitual.alc_frequency}}</td>
            <td>{{habitual.alc_qty}}</td>
            <td>{{habitual.smoke_frequency}}</td>
            <td>{{habitual.gutka_frequency}}</td>
            <td>{{habitual.curr_exer_dur}}</td>
            <td class="diet_histtable_date_td">{{habitual.created_date | date:'dd/MM/yyyy'}}</td>
          </tr>
        </table>

        <!-- //Family hsitory_pop -->
        <table id="tbl" [hidden]="family_history_data.length==0">
          <tr>
            <th>Diabetes</th>
            <th>Cardio</th>
            <th>Endocrine</th>
            <th>Hypertension</th>
            <th>Hepatitis</th>
            <th>HIV</th>
            <th>Other disease</th>
            <th>Date</th>
          </tr>
          <tr *ngFor="let family of family_history_data">
            <td>{{family.Diabetics}}</td>
            <td>{{family.heart_attack}}</td>
            <td>{{family.endocrine}}</td>
            <td>{{family.hypertension}}</td>
            <td>{{family.hepatitis}}</td>
            <td>{{family.hiv}}</td>
            <td>{{family.others}}</td>
            <td class="diet_histtable_date_td">{{family.created_date | date:'dd/MM/yyyy'}}</td>
          </tr>
        </table>

        <!-- //Diet plan -->
        <table id="tbl"  ng-table="DietHist_Table" [hidden]="DietHist_Table_Data.length==0">
          <thead>
            <tr>
              <th>Break fast</th>
              <th>Morning</th>
              <th>Mid-day</th>
              <th>Lunch</th>
              <th>Evening</th>
              <th>Dinner1</th>
              <th>Night</th>
              <th>Date</th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let Diet of DietHist_Table_Data">
              <td>{{Diet.break_fast}}</td>
              <td>{{Diet.morning}}</td>
              <td>{{Diet.midday}}</td>
              <td>{{Diet.lunch}}</td>
              <td>{{Diet.evening}}</td>
              <td>{{Diet.dinner}}</td>
              <td>{{Diet.night}}</td>
              <td class="diet_histtable_date_td">{{Diet.created_date | date:'dd-MM-yyyy'}}</td>
            </tr>
          </tbody>
        </table>

        <!-- //Tracker history -->
        <table id="tbl"  [hidden]="Trackhist_Table_Data.length==0">
          <thead>
            <tr>
              <th>Break fast</th>
              <th>Morning</th>
              <th>Mid-day</th>
              <th>Lunch</th>
              <th>Evening</th>
              <th>Dinner</th>
              <th>Night</th>
              <th>Date</th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let Tracker of Trackhist_Table_Data">
              <td>{{Tracker.break_fast}}</td>
              <td>{{Tracker.morning}}</td>
              <td>{{Tracker.midday}}</td>
              <td>{{Tracker.lunch}}</td>
              <td>{{Tracker.evening}}</td>
              <td>{{Tracker.dinner}}</td>
              <td>{{Tracker.night}}</td>
              <td class="diet_histtable_date_td">{{Tracker.created_date | date:'dd-MM-yyyy'}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions align="end" style="width: auto;right: 19px;">
</div>