<div class="row">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Diagnosis</h5>
                    </div>
                    <div class="headerButtons">
                        <img src="../../../assets/ui_icons/buttons/Back_button.svg" (click)="backClicked()"
                            class="saveimgbtn_inpatinfo" />
                        <img src="../../../assets/ui_icons/buttons/save_button.svg" [hidden]="retriveLocation"
                            (click)="saveUser('save')" class="saveimgbtn_inpatinfo" />
                        <img src="../../../assets/ui_icons/buttons/update_button.svg" [hidden]="updateFlag"
                            (click)="saveUser('update')" class="saveimgbtn_inpatinfo" />
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                                <mat-label class="matlabel">First Name
                                    <input type="text" class="ipcss " maxlength="50" required
                                        [readonly]="retrivelNameFlag" [(ngModel)]="firstName" matInput />
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                                <mat-label class="matlabel">Middle Name
                                    <input type="text" class="ipcss " maxlength="50" [readonly]="retrivelNameFlag"
                                        [(ngModel)]="middleName" matInput />
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                                <mat-label class="matlabel">Last Name
                                    <input type="text" class="ipcss " maxlength="50" required
                                        [readonly]="retrivelNameFlag" [(ngModel)]="lastName" matInput />
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                                <mat-label class="matlabel">Gender
                                    <select class="ipcss" [(ngModel)]="Gender" [disabled]="retrivelNameFlag" required>
                                        <option disabled value="">Select</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                        <option value="Transgender">Transgender</option>
                                    </select>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                                <mat-label class="matlabel">User type
                                    <select class="ipcss " (ngModelChange)="usertypeChange1($event)" required
                                        [(ngModel)]="userType">
                                        <option disabled value="">Select</option>
                                        <option *ngFor="let type of userTypeArray" value="{{type}}">{{type}}
                                        </option>
                                    </select>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                                <mat-label class="matlabel">Location
                                    <select [hidden]="showLoactionFlag" class="ipcss " [disabled]="showUserLocation"
                                        [(ngModel)]="Location">
                                        <option disabled value="">Select</option>
                                        <option *ngFor="let loc of locationArray" value="{{loc.view_data}}">
                                            {{loc.view_data}}</option>
                                    </select>
                                    <input class="ipcss" readonly=true maxlength="50" [hidden]="reteiveLocationFlag"
                                        required [(ngModel)]="retriveLocation" matInput>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                                <mat-label class="matlabel">Mobile No
                                    <input type="text" class="ipcss " maxlength="10" required [(ngModel)]="mobileNo"
                                        matInput />
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                                <mat-label class="matlabel">Email
                                    <input type="text" class="ipcss " maxlength="50" [(ngModel)]="Email" matInput />
                                </mat-label>
                            </div>
                            <div [hidden]="saveFlag" class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                                <mat-label class="matlabel">Password
                                    <input type="text" class="ipcss " type="password" maxlength="15" required
                                        [(ngModel)]="password" matInput />
                                </mat-label>
                            </div>
                            <div [hidden]="saveFlag" class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                                <mat-label class="matlabel">Confirm Password
                                    <input type="text" class="ipcss " type="password" maxlength="15" required
                                        [(ngModel)]="confirmPassword" matInput />
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                                <mat-label class="matlabel">&nbsp;</mat-label>
                                <mat-label class="matlabel">Report approval
                                    <mat-checkbox color=primary labelPosition="before" [(ngModel)]="reportApproval">
                                    </mat-checkbox>
                                </mat-label>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <mat-card>
                            <mat-card-title>Signature</mat-card-title>
                            <mat-card-content>
                                <div class="">
                                    <img id="profile_img" src="../../../assets/img/signature.png"
                                        onerror="this.src='./././assets/img/signature.png'" class="pro_img_cls" />
                                    <!-- <input type="file" (change)="fileChange($event)" name="filUpload"
                                        [hidden]="upd_sign" id="file" class="upload_cls" /> -->
                                    <label class="matlabel" for="file">Upload Signature</label>
                                    <input type="file" class="ipcss" (change)="fileChange($event)" name="filUpload"
                                        [hidden]="uploadedSignature" id="file" />
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>