<div class="row">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Appointment Details</h5>
                    </div>
                    <div class="headerButtons">
                        <a><img src="../../../assets/ui_icons/buttons/Back_button.svg" (click)="back()"
                                class="saveimgbtn_inpatinfo" /></a>
                        <a *ngIf="saveFlag" (click)="save()"><img src="../../../assets/ui_icons/buttons/save_button.svg"
                            class="saveimgbtn_inpatinfo" /></a>
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>
                <div class="row">
                    <div class="" style="margin-top: -10px;">
                      <div class="row">
                        <div class="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-5" style="margin: 0px;">
                            <div class="row">
                              <div class="col-5">
                                <mat-label class="matlabel" style="font-weight: 600;">Appointment Type : </mat-label>
                              </div>
                              <div class="col-7">
                                <div class="switch-field">
                                  <input type="radio" id="radio-one" (click)="changeType('app',1,'yes')" [disabled]="appdisable" />
                                  <label for="radio-one" [ngClass]="apptTypeFlag ? 'radio_active':'radio_inactive'">New</label>
                                  <input type="radio" id="radio-two" (click)="changeType('app',1,'no')" [disabled]="appdisable" />
                                  <label for="radio-two"
                                    [ngClass]="!apptTypeFlag ? 'radio_active':'radio_inactive'">Follow-up</label>
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                      <div class="row">
                      <div class="container-fluid" style="margin-left: -20px;">
                        <table style="width: 100%;">
                          <tr>
                            <td colspan="4" style="vertical-align: top !important;width: 24%">
                              <div class="cover_div" style="padding-bottom: 13px;">
                                <div class="header_lable">Patient</div>
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                  <div class="row">
                                    <div class="col-12">
                                      <div class="switch-field">
                                        <input type="radio" id="radio-five" (click)="searchType('name')" />
                                        <label for="radio-five"
                                          [ngClass]="typeflag == 'name' ? 'radio_active':'radio_inactive'">Name</label>
                                        <input type="radio" id="radio-six" (click)="searchType('mobile')" />
                                        <label for="radio-six"
                                          [ngClass]="typeflag == 'mobile' ? 'radio_active':'radio_inactive'">Mobile</label>
                                        <input type="radio" id="radio-seven" (click)="searchType('patientid');" />
                                        <label for="radio-seven"
                                          [ngClass]="typeflag == 'patientid' ? 'radio_active':'radio_inactive'">Patient id</label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
            
                                <div class="content_cover">
                                  <div>
                                    <input class="inputStyle2" type="text" id="filterInput" [(ngModel)]="patientName"
                                      placeholder={{searchtext}} (keyup)="getPatientListByName(patientName)">
                                    <!-- (keyup)="tableFilter(patientName)" -->
                                    <div class="scrollbar" #scrollContainer (scroll)="onScroll($event)">
                                      <div class="scrollbar-inner">
                                        <table *ngIf="patientList.length">
                                          <tbody>
                                            <tr *ngFor="let person of filteredArr; let i = index" (click)='retrieveClient(person)'>
                                              <td style="font-size: 12px;text-align: left;">{{ person.full_name }} <br /> {{
                                                person.mobile }} </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td colspan="8" style="vertical-align: top !important;">                          
                              <div class="cover_div" style="padding-bottom: 13px;">
                                <div class="header_lable">Personal Details</div>
                                <div class="content_cover">
                                 
                                    <div class="row" >
                                      <div class="col-2">
                                        <img src="{{this.profileImg}}"
                                            onerror="this.src='../../../assets/img/default.jpg';"
                                            class="img-fluid z-depth-1 rounded-circle mr-1 profileimage">
                                      </div>
                                      <div class="col-10">
                                        <div class="row" >
                                          <div class="col-3">
                                            <p class="textformat"><span class="fontweight_normal">MR no:</span> {{this.mrno}}</p>
                                          </div>
                                        <div class="col-3">
                                          <p class="textformat"><span class="fontweight_normal">Name:</span> {{this.clientname}}</p>
                                        </div>
                                        <div class="col-3">
                                          <p class="textformat"><span class="fontweight_normal">DOB:</span> {{this.clntDOB}}</p>
                                        </div>
                                        <div class="col-3">
                                          <p class="textformat"><span class="fontweight_normal">  Age:</span> {{clntAge}} </p>
                                        </div>
                                        <div class="col-3">
                                          <p class="textformat"><span class="fontweight_normal">  Gender:</span> {{clntGender}} </p>
                                        </div>
                                        <div class="col-3">
                                          <p class="textformat"><span class="fontweight_normal">Mobile:</span> {{clntContNumber}} </p>                                           
                                        </div>
                                        <div class="col-3">
                                          <p class="textformat"><span class="fontweight_normal">Emergency contact1:</span> {{this.clntEmergNumber}}</p>
                                        </div>
                                        <div class="col-3">
                                          <p class="textformat"><span class="fontweight_normal">Emergency contact2:</span> {{this.clntEmergNumber1}}</p>
                                        </div>
                                        <div class="col-3">
                                          <p class="textformat"> <span class="fontweight_normal">  Aadhar Number:</span> {{this.aadharNumber}}</p>
                                        </div>
                                        <div class="col-3">
                                          <p class="textformat"><span class="fontweight_normal">    Whatsapp Number:</span> {{this.whatsappNumber}}</p>
                                        </div>
                                        <!-- <div class="col-3">
                                          <p class="textformat">   Symptoms: {{this.clntSymptom}}</p>
                                        </div> -->
                        
                        
                                        <div class="col-3">
                                          <p class="textformat"><span class="fontweight_normal"></span>    Address: {{this.clntAddress1}}</p>
                                        </div>
                                        <div class="col-3">
                                          <p class="textformat"> <span class="fontweight_normal"></span>   Address2: {{this.clntAddress2}}</p>
                                      </div>
                                        <div class="col-3">
                                          <p class="textformat"><span class="fontweight_normal"></span>   Zipcode: {{this.clntZipcode}} </p>
                                        </div>
                                        <div class="col-3">
                                          <p class="textformat"> <span class="fontweight_normal"></span>    Location: {{this.clntLocation}} </p>
                                        </div>
                                        <div class="col-3">
                                          <p class="textformat"><span class="fontweight_normal"></span>   District / City: {{this.clntCity}} </p>
                                        </div>
                                        <div class="col-3">
                                          <p class="textformat"> <span class="fontweight_normal"></span>  State: {{this.clntState}}</p>
                                        </div>
                                        <div class="col-3">
                                          <p class="textformat"> <span class="fontweight_normal"></span>  Country: {{this.clntCountry}}</p>
                                        </div>
                                        <div class="col-3">
                                          <p class="textformat"> <span class="fontweight_normal"></span>  Channel: {{this.ref_source}}</p>
                                        </div>
                                        <div class="col-3">
                                          <p class="textformat"><span class="fontweight_normal"></span>   Referred by: {{this.referredDoctor}} </p>
                                        </div>
                                        <!-- <div class="col-3">
                                          <p class="textformat">   Visit purpose: {{this.visit_purpose}}</p>
                                        </div> -->
                                    </div>
                                  </div>
                                    </div>
                                </div>
                            </div>
                         
                                <div class="cover_div" style="padding-bottom: 13px;margin-bottom: 25px;">
                                    <div class="header_lable">
                                        Appointment Details
                                    </div>
                                    <div class="content_cover">
                                        <div class="row">
                                          <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-2 col-xxl-2"
                                          *ngIf="doctorFlag">
                                          <mat-label class="matlabel">
                                              <span *ngIf="this.frontdesktophysio != '1'">Doctor</span>
                                              <span *ngIf="this.frontdesktophysio == '1'">Physio</span>
                                              <br>

                                              <select class="ipcss widthappt" (ngModelChange)="changeDoctor($event)"
                                                  [(ngModel)]="doctor">
                                                  <option *ngFor="let doctors of doctorList"
                                                      value={{doctors.Doc_Name}}>{{doctors.Doc_Name}}
                                                  </option>
                                              </select>
                                          </mat-label>
                                      </div>
                                            <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-2 col-xxl-2">
                                                <mat-label class="matlabel">Date<br>
                                                    <input type="date" class="ipcss_date widthappt" id="appt_date"
                                                        (change)="getApptByDate(appointmentDate)"
                                                        [(ngModel)]="appointmentDate" min={{currentDate}} #matInput>
                                                </mat-label>
                                            </div>
                                            <!-- <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-2 col-xxl-2" *ngIf="appTypeTokenFlag && visitingSessionData.length != 0">
                                                <mat-label class="matlabel"> Visiting session<br>
                                                    <mat-select required class="ipcss " [(ngModel)]="visitSession">
                                                        <mat-option *ngFor="let session of visitingSessionData" value="{{session.description}}"
                                                            (click)="visitSessionChange(visitSession, appointmentDate)">
                                                            {{session.description}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-label>
                                            </div>
                                            <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-2 col-xxl-2" *ngIf="timeListData.length != 0 && !appTypeTokenFlag">
                                                <mat-label class="matlabel">Visiting session<br>
                                                    <mat-select required class="ipcss " [(ngModel)]="visitSession">
                                                        <mat-option *ngFor="let session of timeListData" value="{{session.session}}" (click)="visitSessionChange(visitSession, appointmentDate)"> {{session.session}}</mat-option>
                                                    </mat-select>
                                                </mat-label>
                                            </div> -->
                                            <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-2 col-xxl-2"
                                            *ngIf="appTypeTokenFlag && visitingSessionData.length != 0">
                                            <mat-label class="matlabel"> Visiting session<br>
                                                <mat-select required class="ipcss " [(ngModel)]="visitSession">
                                                    <mat-option *ngFor="let session of visitingSessionData"
                                                        value="{{session.description}}"
                                                        (click)="visitSessionChange(visitSession, appointmentDate)">
                                                        {{session.description}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-label>
                                        </div>

                                        <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-2 col-xxl-2"
                                            *ngIf="timeListData.length != 0 && !appTypeTokenFlag">
                                            <mat-label class="matlabel">Visiting session<br>
                                                <mat-select required class="ipcss " [(ngModel)]="visitSession">
                                                    <mat-option *ngFor="let session of timeListData"
                                                        value="{{session.session}}"
                                                        (click)="visitSessionChange(visitSession, appointmentDate)">
                                                        {{session.session}}</mat-option>
                                                </mat-select>
                                            </mat-label>
                                        </div>

                                        <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-2 col-xxl-2"
                                            *ngIf="!appTypeTokenFlag && timeList.length != 0">
                                            <mat-label class="matlabel">Appointment Time
                                                <select required class="ipcss widthappt" [(ngModel)]="visitTime"
                                                    required *ngIf="!appTypeTokenFlag">
                                                    <option *ngFor="let time of timeList" value="{{time}}">{{time}}
                                                    </option>
                                                </select>
                                            </mat-label>
                                        </div>
                                            <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-2 col-xxl-2"
                                                *ngIf="appTypeTokenFlag">
                                                <mat-label class="matlabel">
                                                    <p *ngIf="appTypeTokenFlag">Appointment type : Token</p>
                                                </mat-label>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                              <mat-label class="matlabel">Visit purpose<br>
                                                <select class="ipcss widthappt" [(ngModel)]="visit_purpose"
                                                  (change)="changevisit(visit_purpose)">
                                                  <option *ngFor="let visit of pursopearray" value="{{visit.visit_purpose_id}}">{{visit.visit_purpose_desc}}
                                                  </option>
                                                </select>
                                              </mat-label>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                                              <mat-label class="matlabel">Complaints
                                                <input type="text" class="ipcss noappt_width" [(ngModel)]="clntSymptom"
                                                  (keyup)="getSymptoms()" matInput />
                                                <div class="auto_complete_text" *ngIf="symptomList.length != 0">
                                                  <ul *ngFor="let symptom of symptomList">
                                                    <li>
                                                      <a (click)="selectSymptom(symptom)">{{symptom}}</a>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </mat-label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        
                             
                            
                            </td>
                          </tr>
                       
                      </table>
                      </div>
                    </div>
                    </div>
                  </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>