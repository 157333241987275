import { Component, OnInit ,ElementRef,Input,Inject } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr'
import { CommonDataService } from '../../providers/common-data.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Data, Route, Router } from '@angular/router';

@Component({
  selector: 'app-diagnosiscancel',
  templateUrl: './diagnosiscancel.component.html',
  styleUrls: ['./diagnosiscancel.component.css']
})
export class DiagnosiscancelComponent implements OnInit {

  @Input() id: string;parm: string;  
  public element: any;
  public cancel;

  constructor(public comm_Data:CommonDataService,private el: ElementRef, toastr:ToastrService,public router:Router,public  http:Http,public dialog: MatDialog ,public dialogRef: MatDialogRef<DiagnosiscancelComponent>,
  @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
  }

  onNoClick(){
    this.dialogRef.close(this.cancel);
  }

  submit(){
    this.dialogRef.close(this.cancel);
  }
}
