<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Stock audit</h5>
          </div>

          <div class="headerButtons">
            <img [hidden]="backbuttonflag" src="../../../assets/ui_icons/buttons/Back_button.svg"
              class="saveimgbtn_inpatinfo" (click)="back()" />
            <img *ngIf="newbutton" [hidden]="newbuttonflag" class="saveimgbtn_inpatinfo"
              src="../../../assets/ui_icons/buttons/new_button.svg" (click)="showeditpage()" />
            <img *ngIf="confirmbuttonflag" src="../../../assets/ui_icons/buttons/confirm_button.svg" (click)="save_data('0')"
              class="saveimgbtn_inpatinfo">
              <img *ngIf="savebuttonflag" src="../../../assets/ui_icons/buttons/save_button.svg" (click)="save_data('1')"
              class="saveimgbtn_inpatinfo" />
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>



        <div class="tablelist-responsive" [hidden]="all_stock_adj_dta">

          <table style="width:95%">
            <thead>
              <tr style="background-color:#c1e6fb;color: rgba(0,0,0,.54);min-height: 56px;">
                <!-- <div style="width:100%;display: flex;
                    justify-content: space-evenly;"> -->
                <th style="width:10%">Product Id</th>
                <th style="width:20%">Department</th>
                <th style="width:20%">Store name</th>
                <th style="width:20%">Status</th>

                <!-- </div> -->
                <!-- <td><td> -->


              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let adjustdata of stockadjustmentdata"
                (click)="view_detailsad_justdata(adjustdata.prod_adj,adjustdata.reference_no)">
                <!-- <div style="width:100%;display: flex;
                    justify-content: space-evenly;text-align:left"  > -->
                <td style="width:20%">{{adjustdata.prod_adj}}</td>
                <td style="width:20%">{{adjustdata.depat_name}}</td>
                <td style="width:20%">{{adjustdata.store_name}}</td>
                <td style="width:20%">{{adjustdata.is_draft}}</td>



              </tr>

            </tbody>
          </table>

        </div>
        
        <div [hidden]="saveandedit_stock">
          <div class="cover_div" style="padding-bottom: 13px;">
            <div class="header_lable">

            </div>
            <div class="content_cover">
              <div class="row">

                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                  <mat-label class="matlabel" style="margin-top: 7px;">Reference No<br>
                    <input type="text" class="ipcss " [(ngModel)]="reference_no" required maxlength="25" matInput />
                  </mat-label>
                </div>


                <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                  <mat-label class="matlabel">Account<br>
                    <mat-select class="ipcss">


                      <mat-option></mat-option>

                    </mat-select>
                  </mat-label>
                </div> -->


                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                  <mat-label class="matlabel">Reason<br>
                    
                    <select required id="paytype" class="ipcss  widthbillcreate" [(ngModel)]="reasonss" disableOptionCentering>
                      <option *ngFor="let reason of reasondata"
                        value={{reason.stock_adj_reason_id}}>{{reason.description}}</option>
                    </select>
                  
                  </mat-label>
                </div>




                <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                  <mat-label class="matlabel">Department<br>
                    <select  class="ipcss " [(ngModel)]="departname"
                      (change)="prbaseon_departement($event)">

                      <option *ngFor="let department of departmentarray"
                        value={{department.department_id}}>{{department.description}}</option>

                    </select>
                  </mat-label>
                </div>




                <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                  <mat-label class="matlabel">Store name<br>
                    <select class="ipcss " [(ngModel)]="storename">

                      <option *ngFor="let stro of storearray" placeholder={{stro.store_desc}}
                        value={{stro.store_names_id}}>{{stro.store_desc}}</option>

                    </select>
                  </mat-label>
                </div>
                </div>
            </div>
          </div>

          <div class="row">


            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" style="margin-top:5px;">
              
              <!-- <button type="button" style="border-radius:5px;" data-bs-toggle="modal" data-bs-target="#profilePicModal"
                (click)="get_stock('value')">
                Bulk add
              </button> -->
              <img style="border-radius:5px;" data-bs-toggle="modal" data-bs-target="#profilePicModal" src="\assets\img\mobile_buttons\bulk_add_button.svg" (click)="get_stock()"
              class="saveimgbtn_inpatinfo">

            </div>


            <div class="modal fade" id="profilePicModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
              aria-hidden="true">

              <div class="modal-dialog modal-md">
                <div class="modal-content" style="width:155%">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Multi select</h5>
                    
                    <button type="button" class="btn-close " data-bs-dismiss="modal" aria-label="Close">
                     
                    </button>
                  </div>
                  <div class="modal-body">

                    <div class="col-12">
                     <div class="text-left">
                      <div>
                        <input type="text" class="ipcss order_width getMedcien" maxlength="50"
                         maxlength="50" [(ngModel)]="productname" matInput (keyup)="getdatausingname(productname)"
                        />
                    </div></div>
                      
                      <div class="text-right">
                       
                          <mat-label class="matlabel"style="position: relative;
                          bottom: 17px !important;" >Per page<br>
                            <select [(ngModel)]="pagelist" class="ipcss" (change)="get_stock()">
                              <option value="25">25</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                              <option value="full">Full</option>
                            </select>
                          </mat-label>
                       
  
                        <button type="button" class="btn btn-secondary text-right" data-bs-dismiss="modal"
                          (click)="updateStock()">Add</button>
                      </div>

                      <div class="snapshot">
                        <div class="scrollbar">

                          <table *ngIf="bulkstock.length" style="border:1px solid gainsboro;font-size:12px" 
                             class="table table-nowrap table-sm dataTable"
                            style="width:95%">
                            <thead class="sticky-header">
                              <tr>
                                <th style="width: 25%;">S.no</th>
                                <th style="width: 25%;">Item</th>
                                <th style="width: 15%;">Supplier</th>
                                <th style="width: 10%;">Batch no</th>
                                <th style="width: 10%;">Expiry Date</th>
                                <!-- <th style="width: 10%;">On hand</th> -->
                                <th></th>



                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let stock of bulkstock">
                                <td class="align_left" style="font-size:12px !important">{{stock.sn}}</td>
                                <td class="align_left" style="font-size:12px !important">{{stock.name}}</td>
                                <td class="align_left" style="font-size:12px !important">{{stock.supplier}} </td>
                                <td class="align_left" style="font-size:12px !important">{{stock.batch_no}} </td>
                                <td class="align_left" style="font-size:12px !important">{{stock.exp_date}} </td>
                                <!-- <td class="align_left" style="font-size:12px !important">{{stock.qty_onhand}}  </td> -->

                                <td>
                                  <input type="checkbox" [(ngModel)]="stock.selected" (click)="list(stock)">
                                </td>



                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div *ngIf="bulkstock != undefined && bulkstock.length !=0">
                        <div class="row" style="text-align: center;margin-top: 5px;">
                            <div class="col-3 col-sm-3 col-md-4 col-lg-4 col-xl-4 select_bottom"> </div>
                            <div class="col-3 col-sm-3 col-md-1 col-lg-1 col-xl-1 select_bottom" >
                            <input id="Text1" type="image" [disabled]="prevPageCount" class="pag_images" (click)="pageLimitPrevious()"
                                src="../../../assets/ui_icons/arrow_blue.svg" />
                            </div>
                            <div class="col-3 col-sm-3 col-md-1 col-lg-1 col-xl-1 select_bottom">
                            <span class="current_font">{{currentPage}}/{{numberOfPages()||1}}</span>
                            </div>
                
                            <div class="col-3 col-sm-3 col-md-1 col-lg-1 col-xl-1 select_bottom">
                            <input id="Image1" type="image" [disabled]="nextPageCount" class="pag_images" (click)="pageLimitnext()"
                                src="../../../assets/ui_icons/arrow_blue r.svg" />
                            </div>                     
                        </div>
                    </div> 
                      <div class="col-12" *ngIf="bulkstock.length == 0">
                        <p class="nodata">No data found</p>
                      </div>



                    </div>
                  </div>



                </div>
              </div>
            </div>  

            <div class="row" style="margin-top:5px;padding: 0 10px 0 10px;">
              <div id="pharma_tbl_overlay" class="dig_table_overflow table-responsive"
                style="height:400px;overflow-x: scroll;">
                <table id="tbl_med" class="table table-hover table-dynamic" style="font-size: 11px; width:100%">
                  <thead>
                    <tr>

                      <th style="width: 45px;">Si.No</th>
                      <th style="width: 100px;">Item</th>
                      <th style="width: 100px;">Supplier</th>

                      <th style="width: 150px;">Batch no</th>

                      <th style="width: 70px;">Qty avaliable</th>
                      <th style="width:10px"></th>
                      <th style="width: 70px;">Adjust</th>
                      <th style="width: 70px;">Qty onhand</th>
                      <th style="width: 70px;"> </th>

                    </tr>
                  </thead>

                  <tbody>
                    <tr *ngFor="let stockesarray of stock;let i = index" class="tdvalues">

                      <td>{{i + 1}} </td>

                      <td>


                        <input type="text" class="ipcss order_width" #matInput [(ngModel)]="stockesarray.name" />
                      </td>



                      <td>



                        <input class="ipcss" matInput text-right maxlength="10" style="margin:0"
                          [(ngModel)]="stockesarray.supplier" style="text-align:right;" />



                      </td>


                      <td>


                        <input type="text" class="ipcss order_width" required [(ngModel)]="stockesarray.batch_no"
                          matInput maxlength="50" readonly autocomplete="on" />



                      </td>

                      <td>
                        <input type="text" class="ipcss order_width" required [(ngModel)]="stockesarray.qty_onhand"
                          (keyup.enter)="createRow()" #matInput />
                      </td>

                      <!-- [disabled]="!isAdjustValid(stockesarray)"
                      [disabled]="!isAdjustValid(stockesarray)" -->
                      <td>
                        <img style="width:20px" src="../../../assets/img/minussybl.svg"
                          (click)="subtractFromStock('plus');">
                        <img style="width:20px" src="../../../assets/img/minus1.png"
                          (click)="subtractFromStock('minus');">

                      </td>

                      <td>
                        <mat-label>
                          <input type="text" [(ngModel)]="stockesarray.adjust"
                            (keyup)="adjustChange(stockesarray)" 
                            style="text-align: right;" class="ipcss order_width" />
                        </mat-label>
                      </td>
                      <!--  -->
                      <td>
                        <input type="text" [(ngModel)]="stockesarray.avilable" style="text-align: right;"
                          class="ipcss order_width" (keyup)="updateAvailable(stockesarray)"/>
                      </td>


                      <td>
                        <a (click)="deleteMedicine(i)">
                          <img src="../../../assets/ui_icons/buttons/trash_icon.svg" width="20" height="20" /></a>
                      </td>

                    </tr>
                  </tbody>
                </table>
              </div>

            </div>
          </div>
        </div>




      </mdb-card-body>
    </mdb-card>
  </div>
</div>