
<div *ngIf="dentalSpecFlag == 'min'" class="block1_grid">
    <div [hidden]="Intra_Oral_Pane">
        <div class="block1">
            <mat-accordion class="mataccordion">
                <mat-expansion-panel class="expandpanel" (opened)="panelOpenState6 = true"
                    (closed)="panelOpenState6 = false">
                    <mat-expansion-panel-header class="exppanel width ">
                        <mat-panel-title class="title ">
                            <span class=""> Intra oral</span>
                            <!-- <mat-icon class="" *ngIf="!panelOpenState6">add</mat-icon>
                            <mat-icon class="" *ngIf="panelOpenState6">remove</mat-icon> -->
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div [hidden]="intraTab">
                        <div class="row">
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                                <mat-label class="matlabel">Mouth<br>
                                    <textarea  #matInput maxlength="50" class="ipcss"
                                        [(ngModel)]="intra_mouth" required></textarea>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                                <mat-label class="matlabel">Labial mucosa<br>
                                    <textarea  #matInput maxlength="50" class="ipcss"
                                        [(ngModel)]="intra_lab_mous" required></textarea>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                                <mat-label class="matlabel">Palatal<br>
                                    <textarea  #matInput maxlength="50" class="ipcss"
                                        [(ngModel)]="intra_paltal" required></textarea>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                                <mat-label class="matlabel">Buccal mucosa<br>
                                    <textarea  #matInput maxlength="50" class="ipcss"
                                        [(ngModel)]="intra_buc_mou" required></textarea>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                                <mat-label class="matlabel">Tongue<br>
                                    <textarea  #matInput maxlength="50" class="ipcss"
                                        [(ngModel)]="intra_tong" required></textarea>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                                <mat-label class="matlabel">Alveolar mucosa<br>
                                    <textarea  #matInput maxlength="50" class="ipcss"
                                        [(ngModel)]="intra_alv_mus" required></textarea>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                                <mat-label class="matlabel">Periodontium<br>
                                    <textarea  #matInput maxlength="50" class="ipcss"
                                        [(ngModel)]="intra_peri" required></textarea>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                                <mat-label class="matlabel">Floor of mouth<br>
                                    <textarea  #matInput maxlength="50" class="ipcss"
                                        [(ngModel)]="intra_flo_mou" required></textarea>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Vestible_Div">
                                <mat-label class="matlabel">Vestibule<br>
                                    <textarea  #matInput maxlength="50" class="ipcss"
                                        [(ngModel)]="intra_vest"></textarea>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Tonsils_Third_Div_new">
                                <mat-label class="matlabel">Tonsils<br>
                                    <textarea  #matInput maxlength="50" class="ipcss"
                                        [(ngModel)]="intra_tons" required></textarea>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Tonsils_Third_Div">
    
                                <mat-label class="matlabel">Lips and labial mucosa<br>
                                    <textarea  #matInput maxlength="50" class="ipcss"
                                        [(ngModel)]="intra_lip_lab" required></textarea>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Tonsils_Third_Div">
    
                                <mat-label class="matlabel">Hard and soft palate<br>
                                    <textarea  #matInput maxlength="50" class="ipcss"
                                        [(ngModel)]="intra_hard_soft" required></textarea>
                                </mat-label>
                            </div>
                            <div class=" col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Soft_tissue_Div">
                                <mat-label class="matlabel">Pocket<br>
                                    <textarea  #matInput maxlength="50" class="ipcss"
                                        [(ngModel)]="intra_pocket"></textarea>
                                </mat-label>
    
    
                            </div>
                            <div class=" col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Soft_tissue_Div">
                                <mat-label class="matlabel">Bleeding<br>
                                    <textarea  #matInput maxlength="50" class="ipcss"
                                        [(ngModel)]="intra_bleed"></textarea>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Fernum_txt_Div">
                                <mat-label class="matlabel">Fernum<br>
                                    <textarea  #matInput maxlength="50" class="ipcss"
                                        [(ngModel)]="intra_fern"></textarea>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Extrersinus_Txt_Div">
                                <mat-label class="matlabel">External sinus tracts<br>
                                    <textarea  #matInput maxlength="50" class="ipcss"
                                        [(ngModel)]="intra_ext_tract" [required]="intra_ext_sinus_req"></textarea>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="ease_access_Txt_Div">
                                <mat-label class="matlabel">Ease of access<br>
                                    <textarea  #matInput maxlength="50" class="ipcss"
                                        [(ngModel)]="intra_ease" [required]="intra_ease_access_req"></textarea>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="sewl_txt">
    
                                <mat-label class="matlabel">Facial swelling<br>
                                    <textarea  #matInput maxlength="50" class="ipcss"
                                        [(ngModel)]="intra_fac_swell" [required]="intra_face_swell_req"></textarea>
                                </mat-label>
                            </div>
                        </div>
                        <div class="row" [hidden]="Hardtissue_Div_Head">
                            <div class="col-12">
                                <p class="tervys_heading">Hard tissue</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Hardtissue_First_Div">
                                <mat-label class="matlabel">No. of permanent teeth<br>
                                    <textarea  #matInput maxlength="2" class="ipcss"
                                        [(ngModel)]="perm_teeth_present_txt" (change)="intra_oral_change()"
                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                        [required]="intra_per_teeth_req"></textarea>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Hardtissue_First_Div">
                                <mat-label class="matlabel">No deciduous teeth<br>
                                    <textarea  #matInput maxlength="2" class="ipcss"
                                        [(ngModel)]="dec_teeth_present_txt" [required]="intra_dec_teeth_req"
                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"></textarea>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Hardtissue_First_Div">
                                <mat-label class="matlabel">Texture<br>
                                    <select class="ipcss  " [(ngModel)]="phy_build" disableOptionCentering>
                                        <option value="Normal">Normal</option>
                                        <option value="Abnormal">Abnormal</option>
                                    </select>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Hardtissue_First_Div">
    
                                <mat-label class="matlabel">Unerupted teeth<br>
                                    <select required class="ipcss  " [required]="intra_un_erupt_teeth_req"
                                        [(ngModel)]="unerupted_teeth" (change)="intra_oral_change()"
                                        disableOptionCentering>
                                        <option *ngFor="let unerupted of unerupted_teeth_array"
                                            value={{unerupted.den_teeth_name_id}}>{{unerupted.description}}</option>
                                    </select>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Hardtissue_First_Div">
                                <mat-label class="matlabel">Ineruption teeth<br>
                                    <select required class="ipcss  " [required]="intra_un_erupt_teeth_req"
                                        [(ngModel)]="inerupted_teeth" (change)="intra_oral_change()"
                                        disableOptionCentering>
                                        <option *ngFor="let inerupted of inerupted_teeth_array"
                                            value={{inerupted.den_teeth_name_id}}>{{inerupted.description}}</option>
                                    </select>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Hardtissue_First_Div">
                                <mat-label class="matlabel">Super numery teeth<br>
                                    <textarea  #matInput class="ipcss" (change)="intra_oral_change()"
                                        maxlength="50" [(ngModel)]="intra_super"></textarea>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Intra_Keyridge_Div">
                                <mat-label class="matlabel">Key ridge position<br>
                                    <textarea  #matInput class="ipcss"
                                        [required]="intra_key_post_req" (change)="intra_oral_change()" maxlength="50"
                                        [(ngModel)]="intra_key" required></textarea>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Hard_Gingival_Cal_div">
                                <mat-label class="matlabel">Supragingival calculus<br>
                                    <textarea  #matInput  class="ipcss"
                                        maxlength="50" [(ngModel)]="intra_sup_ging"></textarea>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Hard_Gingival_Cal_div">
                                <mat-label class="matlabel">Subgingival calculus<br>
                                    <textarea  #matInput  class="ipcss"
                                        maxlength="50" [(ngModel)]="intra_sub_ging"></textarea>
                                </mat-label>
                            </div>
    
    
    
                            <div class=" col-8 col-md-4 col-lg-4 col-xl-2" [hidden]="Intra_Endotreat_Div">
                                <mat-label class="matlabel ">Endodontically treated</mat-label>
                            </div>
                            <div class=" col-4 col-md-1 col-lg-1 col-xl-1">
    
                                <button id="matbtn" *ngIf="ynopt5" [hidden]="Intra_Endotreat_Div"
                                    [(ngModel)]="togg_endo_treat" [style.color]="getColor('Yes')"
                                    [ngStyle]="{'border':'1px solid green'}" mat-raised-button
                                    (click)="option(5)">Yes</button>
                                <button id="matbtn" *ngIf="!ynopt5" [hidden]="Intra_Endotreat_Div"
                                    [style.color]="getColor('No')" [ngStyle]="{'border':'1px solid red'}"
                                    [(ngModel)]="togg_endo_treat" mat-raised-button (click)="option(5)">No</button>
    
                            </div>
                        </div>
    
                        <div class="row" [hidden]="Caries_Div">
                            <div class="col-12">
                                <p class="tervys_heading">Caries</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Caries_Div">
                                <mat-label class="matlabel" >Restorable<br>
                                    <textarea  #matInput class="ipcss" maxlength="500"
                                        [(ngModel)]="intra_rest"></textarea>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Caries_Div">
                                <mat-label class="matlabel" >Unrestorable<br>
                                    <textarea  #matInput class="ipcss" maxlength="500"
                                        [(ngModel)]="intra_unrest"></textarea>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Caries_Div"> 
                                <mat-label class="matlabel">Occlusal facet’s wear<br>
                                    <textarea  #matInput class="ipcss" maxlength="500"
                                        [(ngModel)]="intra_occ"></textarea>
                                </mat-label>
                            </div>
    
    
                        </div>
                        <div class="row"  [hidden]="Tot_Causes_Div">
                            <div class="col-12">
                                <p class="tervys_heading">Causes</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3"  [hidden]="Tot_Causes_Div">
                                <mat-label class="matlabel" [hidden]="Tot_Causes_Div">Attrition<br>
                                    <select class="ipcss"  [(ngModel)]="intra_att">
                                        <option value="Tooth wear">Tooth wear</option>
                                        <option value="Advanced and excessive wear">Advanced and excessive wear
                                        </option>
                                        <option value="Tooth surface loss">Tooth surface loss</option>
    
                                    </select>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Tot_Causes_Div">
                                <mat-label class="matlabel" >Abration<br>
                                    <select class="ipcss  "  [(ngModel)]="intra_abart">
                                        <option value="Tooth wear">Pathologic wear</option>
                                        <option value="Advanced and excessive wear">Extrinsic</option>
                                        <option value="Tooth surface loss">Intrinsic</option>
    
                                    </select>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Tot_Causes_Div">
                                <mat-label class="matlabel" [hidden]="Tot_Causes_Div">Description<br>
                                    <textarea  #matInput class="ipcss" maxlength="50"
                                        [(ngModel)]="intra_discri"></textarea>
                                </mat-label>
    
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Tot_Causes_Div">
                                <mat-label class="matlabel" [hidden]="Tot_Causes_Div">Erosion<br>
                                    <select class="ipcss  "  [(ngModel)]="ero_txt">
                                        <option value="Pathologic wear">Idiopathic</option>
                                        <option value="Extrinsic">Extrinsic</option>
                                        <option value="Intrinsics">Intrinsic</option>
    
                                    </select>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Tot_Causes_Div">
                                <mat-label class="matlabel" [hidden]="Tot_Causes_Div">Description<br>
                                    <textarea  #matInput  class="ipcss"
                                        maxlength="50" [(ngModel)]="intra_des1"></textarea>
                                </mat-label>
                            </div>
    
                        </div>
                        <div class="row" >
                            <div class="col-12">
                                <p class="tervys_heading" [hidden]="Tot_Occlusal_Div">Occlusal analysis</p>
                            </div>
    
                        </div>
                        <div class="row">
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Tot_Causes_Div">
                                <mat-label class="matlabel" [hidden]="Tot_Occlusal_Div">Over bite<br>
                                    <textarea  #matInput class="ipcss" maxlength="50"
                                        [(ngModel)]="intra_over"></textarea>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Tot_Causes_Div">
                                <mat-label class="matlabel" [hidden]="Tot_Occlusal_Div">Cross bite<br>
                                    <textarea  #matInput class="ipcss" maxlength="50"
                                        [(ngModel)]="intra_cross"></textarea>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Tot_Causes_Div">
                                <mat-label class="matlabel" [hidden]="Tot_Occlusal_Div">Open bite<br>
                                    <textarea  #matInput class="ipcss" maxlength="50"
                                        [(ngModel)]="intra_open"></textarea>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Tot_Causes_Div">
                                <mat-label class="matlabel" [hidden]="Tot_Occlusal_Div">Plunger cusps<br>
                                    <textarea  #matInput class="ipcss" maxlength="50"
                                        [(ngModel)]="intra_plung"></textarea>
                                </mat-label>
                            </div>
    
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <p class="tervys_heading" [hidden]="Trauma_Div">Trauma from occlusion</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3"  [hidden]="Trauma_Div">
                                <mat-label class="matlabel">Primary trauma from occlusion<br>
                                    <textarea  #matInput class="ipcss" maxlength="250"
                                        [(ngModel)]="intra_pri"></textarea>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3"  [hidden]="Trauma_Div">
                                <mat-label class="matlabel">Secondary trauma from occlusion<br>
                                    <textarea  #matInput class="ipcss" maxlength="250"
                                        [(ngModel)]="intra_sec"></textarea>
                                </mat-label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <p class="tervys_heading" [hidden]="Tot_Soft_Div">Soft tissue</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3"  [hidden]="Tot_Soft_Div">
                                <mat-label class="matlabel">Oral hygienic status (Gingiva)<br>
                                    <select class="ipcss  " [(ngModel)]="oral_hyg_sta" [required]="intra_oral_hyg_req"
                                        (change)="intra_oral_change()">
                                        <option value="Good">Good</option>
                                        <option value="Satisfactory">Satisfactory</option>
                                        <option value="Poor">Poor</option>
                                    </select>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3"  [hidden]="Tot_Soft_Div">
                                <mat-label class="matlabel">Max mouth opening incisal edge<br>
                                    <input  class="ipcss  " matInput maxlength="5" 
                                        [(ngModel)]="intra_max_mouth" [required]="intra_max_mouth_req"
                                        (change)="intra_oral_change()" />
                                </mat-label>
    
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Tot_Soft_Div">
                                <mat-label class="matlabel" >Gingival colour<br>
                                    <select required class="ipcss  "  [(ngModel)]="ging_col"
                                        [required]="intra_ging_col_req" (change)="intra_oral_change()"
                                        disableOptionCentering>
                                        <option *ngFor="let ging of ging_col_array" value={{ging.ging_color_id}}>
                                            {{ging.description}}</option>
                                    </select>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Tot_Soft_Div">
                                <mat-label class="matlabel" >Gingival texture<br>
                                    <select class="ipcss  "  [(ngModel)]="intra_ging_txt"
                                        [required]="intra_ging_txt_req" (change)="intra_oral_change()">
                                        <option value="Normal">Normal</option>
                                        <option value="Oedematous">Oedematous</option>
                                        <option value="Fibrous">Fibrous</option>
    
                                    </select>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Tot_Soft_Div">
                                <mat-label class="matlabel" >Palatal contour<br>
                                    <select class="ipcss  " [(ngModel)]="intra_pal_cont" 
                                        [required]="intra_pal_cont_req" (change)="intra_oral_change()">
                                        <option value="Normal">Normal</option>
                                        <option value="Shallow">Shallow</option>
                                        <option value="Deep">Deep</option>
    
                                    </select>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Tot_Soft_Div">
                                <mat-label class="matlabel" >Cirum oral muscle tone<br>
                                    <select required class="ipcss  " [required]="intra_cir_oral_req"
                                         [(ngModel)]="circum_oral_mus_tone"
                                        (change)="intra_oral_change()" disableOptionCentering>
                                        <option *ngFor="let circum of circum_oral_mus_tone_array"
                                            value={{circum.circum_tone_id}}>{{circum.description}}</option>
                                    </select>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Trauma_Div">
                                <mat-label class="matlabel" >Tonsils and Adenoids<br>
                                    <textarea  #matInput class="ipcss" maxlength="50"
                                        [(ngModel)]="intra_ton_anod" [required]="intra_tons_ando_req"
                                        (change)="intra_oral_change()" ></textarea>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Trauma_Div">
                                <mat-label class="matlabel" >Oral mucosa<br>
                                    <textarea  #matInput class="ipcss" required maxlength="25"
                                        [(ngModel)]="intra_oral_muos" [required]="intra_oral_mous_req"
                                        (change)="intra_oral_change()" ></textarea>
                                </mat-label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <p class="tervys_heading" [hidden]="Frenal_Attach_Div">Frenal attachment</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Frenal_Attach_Div"> 
                                <mat-label class="matlabel">Upper<br>
                                    <select required class="ipcss  " [(ngModel)]="fren_upper" disableOptionCentering>
                                        <option *ngFor="let fren_up of fren_upper_array"
                                            value={{fren_up.den_fre_att_typ_id}}>{{fren_up.description}}</option>
                                    </select>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Frenal_Attach_Div">
                                <mat-label class="matlabel" >Lower<br>
                                    <select required class="ipcss  " [(ngModel)]="fren_lower" disableOptionCentering>
                                        <option *ngFor="let fren_up of fren_upper_array"
                                            value={{fren_up.den_fre_att_typ_id}}>{{fren_up.description}}</option>
                                    </select>
                                </mat-label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <p class="tervys_heading" [hidden]="Tot_Tongue_Div">Tongue</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Tot_Tongue_Div">
                                <mat-label class="matlabel" >Size<br>
                                    <textarea  #matInput class="ipcss"
                                        [required]="intra_tong_size_req" (change)="intra_oral_change()" maxlength="25"
                                        [(ngModel)]="intra_size" ></textarea>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Tot_Tongue_Div">
    
                                <mat-label class="matlabel" [hidden]="Tot_Tongue_Div">Posture<br>
                                    <textarea  #matInput class="ipcss"
                                        [required]="intra_tong_post_req" (change)="intra_oral_change()" maxlength="25"
                                        [(ngModel)]="intra_post" ></textarea>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Tot_Tongue_Div">
                                <mat-label class="matlabel" [hidden]="Tot_Tongue_Div">Shape<br>
                                    <textarea  #matInput class="ipcss"
                                        [required]="intra_tong_shape_req" (change)="intra_oral_change()" maxlength="25"
                                        [(ngModel)]="intra_shape" ></textarea>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Tot_Tongue_Div">
                                <mat-label class="matlabel" [hidden]="Tot_Tongue_Div">Movement<br>
                                    <textarea  #matInput class="ipcss"
                                        [required]="intra_tong_move_req" (change)="intra_oral_change()" maxlength="25"
                                        [(ngModel)]="intra_move" ></textarea>
                                </mat-label>
                            </div>
    
                        </div>
    
    
                    </div>
                            </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
</div>
<div *ngIf="dentalSpecFlag == 'max'">
<div  [hidden]="perio_specific" class="expvalue">

    <mat-accordion class="mataccordion">
        <mat-expansion-panel  class="expandpanel" (opened)="panelOpenState2 = true"
            (closed)="panelOpenState2 = false">
            <mat-expansion-panel-header class="exppanel width ">
                <mat-panel-title class="title ">
                    Periodontical specfic disease
                    <!-- <mat-icon class="" *ngIf="!panelOpenState2">add</mat-icon>
                    <mat-icon class="" *ngIf="panelOpenState2">remove</mat-icon> -->
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div [hidden]="spdiseaseTab">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                        <mat-label class="matlabel">Area specific disease<br>
                            <select class="ipcss"  [required]="loc_required" disableOptionCentering [disabled]="client"  [(ngModel)]="area_spec_dis"  (change)="send_data()">
                                <option Selected *ngFor="let area of area_spec_dis_array"
                                    value={{area.den_area_specific_id}}>{{area.description}}</option>
                            </select>
                        </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                        <mat-label class="matlabel">Age specific disease<br>
                            <select class="ipcss" [(ngModel)]="age_spec_dis" [disabled]="client" (change)="send_data()">
                                <option *ngFor="let age of age_spec_dis_array" value={{age.den_age_spec_id}}>
                                    {{age.description}}</option>
                            </select>
                        </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                        <mat-label class="matlabel">Gender specific disease<br>
                            <select class="ipcss" [(ngModel)]="gen_spec_dis" [disabled]="client" (change)="send_data()">
                                <option *ngFor="let gen of gen_spec_dis_array" value={{gen.den_gen_spec_id}}>
                                    {{gen.description}}</option>
                            </select>
                        </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                        <mat-label class="matlabel">Occupational hazards<br>
                            <select class="ipcss" [(ngModel)]="occ_haz" [disabled]="client" (change)="send_data()">
                                <option *ngFor="let occ of occ_haz_array" value={{occ.den_occ_haz_id}}>
                                    {{occ.description}}</option>

                            </select>
                        </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                        <mat-label class="matlabel">Mastigatory muscle triedness<br>
                            <select class="ipcss" [(ngModel)]="mast_mus_tri" [disabled]="client" (change)="send_data()">
                                <option value="Day">Day</option>
                                <option value="Night">Night</option>
                            </select>
                        </mat-label>
                    </div>

                </div>

            </div>
        </mat-expansion-panel>
    </mat-accordion>

</div>
<div [hidden]="ortho_physical" class="expvalue">
    <div class="">
        <mat-accordion class="mataccordion">
            <mat-expansion-panel  class="expandpanel" (opened)="panelOpenState1 = true"
                (closed)="panelOpenState1 = false">
                <mat-expansion-panel-header class="exppanel width ">
                    <mat-panel-title class="title ">
                        <span class="">
                            Physical status </span>
                        <!-- <mat-icon class="" *ngIf="!panelOpenState1">add</mat-icon>
                        <mat-icon class="" *ngIf="panelOpenState1">remove</mat-icon> -->
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div [hidden]="phyststusTab" class="margin_top_10">
                    <div class="row">
                        <div class="row" style="margin: 20px 0;">
                            <div class="col-9 col-sm-9 col-md-6 col-lg-4 col-xl-4 " style="border-bottom: 3px;">
                            <mat-label  class="matlabel ">Is the patient regular attender?</mat-label>
                            </div>
                                <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                    <mat-button-toggle id="toggid11" class="togglebtn" [disabled]="client"
                                    [(ngModel)]="tog_pat_att1" ngDefaultControl [checked]="tog_pat_att1" (change)="send_data()"
                                    (change)="changepatientatt($event.source.checked)" [ngStyle]="tog_pat_att1 && { 'background' : 'green' } || !tog_pat_att1 && { 'background' : 'red' }">
                                   <span class="familytab" *ngIf="!tog_pat_att1">No</span>
                                   <span class="familytab" *ngIf="tog_pat_att1">Yes</span>
                                </mat-button-toggle>

                            <!-- <button  id="matbtn" *ngIf="ynopt11"
                                [(ngModel)]="tog_pat_att" [style.color]="getColor('Yes')"
                                [ngStyle]="{'border':'1px solid green'}" mat-raised-button
                                (click)="option(11)">Yes</button>
                            <button  id="matbtn" *ngIf="!ynopt11"
                                [style.color]="getColor('No')" [ngStyle]="{'border':'1px solid red'}"
                                [(ngModel)]="tog_pat_att" mat-raised-button (click)="option(11)">No</button> -->
                        </div>
                    </div>
                    </div>

                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                            <mat-label class="matlabel">Build<br>
                                <select class="ipcss  " [(ngModel)]="phy_build" disableOptionCentering [disabled]="client" (change)="send_data()">
                                    <option value="Ectomorphic">Ectomorphic</option>
                                    <option value="Mesomorphic">Mesomorphic</option>
                                    <option value="Endomorphic">Endomorphic</option>
                                </select>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                            <mat-label class="matlabel">Height<br>
                                <input  class="ipcss " matInput [(ngModel)]="phy_ht" maxlength="3" (blur)="send_data()"
                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57" [disabled]="client"/>
                            </mat-label>

                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                            <mat-label class="matlabel">Weight<br>
                                <input  class="ipcss " matInput [(ngModel)]="phy_wt" maxlength="3" (blur)="send_data()"
                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57" [disabled]="client"/>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">

                            <mat-label class="matlabel">Gait<br>
                                <input  class="ipcss " matInput maxlength="25" [disabled]="client" [(ngModel)]="phy_gait" (blur)="send_data()"
                                    [required]="pedbody_type!=undefined || phy_build != undefined || phy_ht != undefined && phy_ht != 0 || phy_wt != undefined && phy_wt != 0 ||
                phy_post != undefined && phy_post != 0 || phy_pre_hel != undefined">
                            </mat-label>
                        </div>
                  

                    <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                        <mat-label class="matlabel">Posture<br>
                            <input  class="ipcss " matInput maxlength="25" [(ngModel)]="phy_post" [disabled]="client" (blur)="send_data()"/>
                        </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                        <mat-label class="matlabel">Materials<br>
                            <select class="ipcss  " [disabled]="client" disableOptionCentering [(ngModel)]="pedbody_type" [required]="phy_gait!=undefined && phy_gait !=0 || phy_build != undefined || phy_ht != undefined && phy_ht != 0 || phy_wt != undefined && phy_wt != 0 ||
                                phy_post != undefined && phy_post != 0 || phy_pre_hel != undefined" (change)="send_data()">
                                <option *ngFor="let body of body_type_array" value={{body.body_type_id}}>
                                    {{body.description}}</option>
                            </select>
                        </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                        <mat-label class="matlabel">Present health<br>
                            <select class="ipcss " [(ngModel)]="phy_pre_hel" disableOptionCentering [disabled]="client" (change)="send_data()">
                                <option >Select</option>
                                <option value="Good">Good</option>
                                <option value="Fair">Fair</option>
                                <option value="Poor">Poor</option>
                            </select>
                        </mat-label>
                    </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>
<div [hidden]="vital_signs_public" class="expvalue">
    <div class="block1">
        <mat-accordion class="mataccordion">
            <mat-expansion-panel  class="expandpanel" (opened)="panelOpenState3 = true"
                (closed)="panelOpenState3 = false">
                <mat-expansion-panel-header class="exppanel ">
                    <mat-panel-title class="title ">
                        <span class="">
                            Vital signs </span>
                        <!-- <mat-icon class="" *ngIf="!panelOpenState3">add</mat-icon>
                        <mat-icon class="" *ngIf="panelOpenState3">remove</mat-icon> -->
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div [hidden]="vitalTab" class="margin_top_10">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                            <mat-label class="matlabel">Respiratory rate<br>
                                <input  class="ipcss " matInput maxlength="3" [(ngModel)]="respi_txt" [disabled]="client"  onkeypress="return event.charCode >= 48 && event.charCode <= 57" (blur)="send_data()"/>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                            <mat-label class="matlabel">Pulse rate<br>
                                <input  class="ipcss " matInput maxlength="3" [(ngModel)]="pulse_txt" [disabled]="client" onkeypress="return event.charCode >= 48 && event.charCode <= 57"  (blur)="send_data()"/>
                            </mat-label>
                        </div>
                    </div>

                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>
<div [hidden]="Pediatric_Spec_Pane" class="expvalue">
    <div class="block1">
        <mat-accordion class="mataccordion">
            <mat-expansion-panel  class="expandpanel" (opened)="panelOpenState4 = true"
                (closed)="panelOpenState4 = false">
                <mat-expansion-panel-header class="exppanel width ">
                    <mat-panel-title class="title ">
                        <span class="">
                            Pediatric specific</span>
                        <!-- <mat-icon class="iconsleft" *ngIf="!panelOpenState4">add</mat-icon>
                        <mat-icon class="iconsleft" *ngIf="panelOpenState4">remove</mat-icon> -->
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div [hidden]="pedsplTab" class="margin_top_10">
                    <p>Child behaviour</p>

                    <div class="row " [hidden]="rating_hidden">
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                            <div class="row ">
                                <mat-label>Rating</mat-label>
                            </div>

                         
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                            <mat-label class="matlabel" [hidden]="rating_type_hidden">Rating type<br>
                                <select required class="ipcss wid " [(ngModel)]="rating_type" [disabled]="client"
                                    (change)="pediatric_change();send_data();" disableOptionCentering>
                                    <option *ngFor="let rate of rating_type_array"
                                        value={{rate.den_ped_rate_id}}>{{rate.description}}</option>
                                </select>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                            <mat-label class="matlabel" [hidden]="description_hidden">Description<br>
                                <textarea  #matInput maxlength="50" class="ipcss" [disabled]="client"
                                    [(ngModel)]="ped_spc_des" (change)="pediatric_change()" (blur)="send_data()"></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                            <mat-label class="matlabel" [hidden]="reason_hidden">Reason<br>
                                <textarea  #matInput class="ipcss" [(ngModel)]="ped_spc_rea" [disabled]="client"
                                    [required]="ped_reason_req" maxlength="50" (change)="pediatric_change()" (blur)="send_data()"></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">

                            <mat-label class="matlabel" [hidden]="pro_hidden">Prolonged use of bottle at bed<br>
                                <textarea  #matInput class="ipcss" [(ngModel)]="ped_spc_pro_bot" [disabled]="client" (blur)="send_data()"
                                    maxlength="50" (change)="pediatric_change()"></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                            <mat-label class="matlabel" [hidden]="tooth_hidden">Tooth decay appearance<br>
                                <textarea  #matInput class="ipcss" [disabled]="client" (blur)="send_data()"
                                    [required]="ped_tooth_decay_req" [required]="ped_pro_use_req"
                                    [(ngModel)]="ped_spc_tooth_app" maxlength="50"
                                    (change)="pediatric_change()"></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                            <mat-label class="matlabel" [hidden]="rel_med_hidden">Relevant medical history<br>
                                <textarea  #matInput class="ipcss" maxlength="250" [disabled]="client" (blur)="send_data()"
                                    [(ngModel)]="ped_spc_rel_hist" (change)="pediatric_change()"></textarea>
                            </mat-label>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>
<div [hidden]="Endodontic_Hist_Pane" class="expvalue">
    <div class="block1">
        <mat-accordion class="mataccordion">
            <mat-expansion-panel  class="expandpanel" (opened)="panelOpenState5 = true"
                (closed)="panelOpenState5 = false">
                <mat-expansion-panel-header class="exppanel width ">
                    <mat-panel-title class="title ">
                        <span class="">
                            Endodontic history</span>
                        <!-- <mat-icon class="" *ngIf="!panelOpenState5">add</mat-icon>
                        <mat-icon class="" *ngIf="panelOpenState5">remove</mat-icon> -->
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div [hidden]="endohistTab" class="margin_top_10">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="pain_hidden">
                            <mat-label class="matlabel" >Did anything make the pain worse<br>
                                <textarea  #matInput class="ipcss" [disabled]="client" (blur)="send_data()"
                                    [required]="endo_pain_wrose_req" (change)="endo_change()" [(ngModel)]="pain_txt"
                                    maxlength="250"></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="freq_episode_hidden">
                            <mat-label class="matlabel" >Frequency of painful episode<br>
                                <textarea  #matInput class="ipcss" [disabled]="client" (blur)="send_data()"
                                    [required]="endo_pain_episode_req" (change)="endo_change()"
                                    [(ngModel)]="pain_episode" maxlength="250"></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="loc_hidden">
                            <mat-label class="matlabel" >Location<br>
                                <textarea  #matInput class="ipcss" [required]="endo_loc_req" [disabled]="client" (blur)="send_data()"
                                    (change)="endo_change()" [(ngModel)]="location_txt" maxlength="250"></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                            <mat-label class="matlabel">Relieving factor<br>
                                <textarea  #matInput *ngIf="!flag1" class="ipcss" [disabled]="client"
                                   [(ngModel)]="reli_factor_type"  (click)="relieve()" rows="1"></textarea>  <!--     [(ngModel)]="reli_factor_type"  (click)="relieve()" rows="1" disabled -->
                                <mat-select *ngIf="flag1" class="ipcss  " disableOptionCentering="true" multiple (blur)="send_data()">
                                    <button mat-raised-button id="closemedhist"
                                        (click)="closerel_popup()">close</button>
                                    <mat-option color="primary" *ngFor="let array of reli_factor" value={{array.label}}>
                                        <mat-checkbox multiple [checked]="array.checked"
                                            (change)="relievef(array.label,array.value,$event.checked)">{{array.label}}
                                        </mat-checkbox>&nbsp;
                                    </mat-option>
                                </mat-select>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3"  [hidden]="soc_hist_hidden">
                            <mat-label class="matlabel">Social history<br>
                                <textarea  #matInput class="ipcss" (change)="endo_change()" [disabled]="client" (blur)="send_data()"
                                    [(ngModel)]="social_txt" maxlength="250"></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3"  [hidden]="int_pain_hidden">
                            <mat-label class="matlabel">Intensity of pain<br>
                                <textarea  #matInput class="ipcss" [required]="endo_int_pain_req" [disabled]="client" (blur)="send_data()"
                                    (change)="endo_change()" [(ngModel)]="intnsity_txt" maxlength="250"></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="type_pain_hidden">
                            <mat-label class="matlabel" >Type of pain <br>
                                <textarea  #matInput class="ipcss" [required]="endo_int_pain_req" [disabled]="client" (blur)="send_data()"
                                    (change)="endo_change()" [(ngModel)]="quality_pain" maxlength="250"></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                            <mat-label class="matlabel">Speciality specific disease<br>
                                <textarea  #matInput *ngIf="!specific_flag1"   class="ipcss" [disabled]="client" (blur)="send_data()"
                                    [(ngModel)]="spl_speci_dis_type" (click)="specificdiasease()" rows="1"></textarea>
                                <mat-select *ngIf="specific_flag1" class="ipcss  " disableOptionCentering="true"
                                    multiple>
                                    <button mat-raised-button id="closemedhist"
                                        (click)="closespecificdisease()">close</button>
                                    <mat-option color="primary" *ngFor="let spl_speci_dis1 of spl_speci_dis"
                                        value={{spl_speci_dis1.label}}>
                                        <mat-checkbox multiple [checked]="spl_speci_dis1.checked"
                                            (change)="specificdiaseasepop(spl_speci_dis1.label,spl_speci_dis1.value,$event.checked)">
                                            {{spl_speci_dis1.label}}</mat-checkbox>&nbsp;
                                    </mat-option>
                                </mat-select>
                            </mat-label>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="row">
                            <div class="col-8 col-md-8 col-lg-5 col-xl-5 ">
                                <mat-label class="matlabel ">Bleeding/injury that needed special treatment
                                </mat-label>
                            </div>
                            <div class="col-4 col-md-2 col-lg-1 col-xl-1">
                                <mat-button-toggle id="toggid1" class="togglebtn" [disabled]="client"
                                [(ngModel)]="bleed_togg1" ngDefaultControl [checked]="bleed_togg1"
                                (change)="chnagebleedtogg($event.source.checked); send_data();" [ngStyle]="bleed_togg1 && { 'background' : 'green' } || !bleed_togg1 && { 'background' : 'red' }">
                               <span class="familytab" *ngIf="!bleed_togg1">No</span>
                               <span class="familytab" *ngIf="bleed_togg1">Yes</span>
                            </mat-button-toggle>


                                <!-- <button id="matbtn" *ngIf="ynopt1" [(ngModel)]="bleed_togg"
                                    [style.color]="getColor('Yes')" [ngStyle]="{'border':'1px solid green'}"
                                    mat-raised-button (click)="option(1)">Yes</button>
                                <button id="matbtn" *ngIf="!ynopt1" [style.color]="getColor('No')"
                                    [ngStyle]="{'border':'1px solid red'}" [(ngModel)]="bleed_togg" mat-raised-button
                                    (click)="option(1)">No</button> -->

                            </div>
                            <div class="col-8 col-md-3 col-lg-3 col-xl-2" [hidden]="bleed_txt">
                                <textarea  #matInput  [disabled]="client"  class="ipcss" (change)="endo_change();send_data()" 
                                    [required]="endo_bleed_inj_req" [(ngModel)]="bledd_injure" rows="1"></textarea>

                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="row">
                            <div class="col-8 col-md-8 col-lg-5 col-xl-5 ">
                                <mat-label class="matlabel ">Serious illnesses or operations?</mat-label>
                            </div>
                            <div class="col-4 col-md-2 col-lg-1 col-xl-1">

                                <mat-button-toggle id="toggid1" class="togglebtn" [disabled]="client"
                                [(ngModel)]="ill_opert1" ngDefaultControl [checked]="ill_opert1"
                                (change)="changeilloper($event.source.checked);send_data()" [ngStyle]="ill_opert1 && { 'background' : 'green' } || !ill_opert1 && { 'background' : 'red' }">
                               <span class="familytab" *ngIf="!ill_opert1">No</span>
                               <span class="familytab" *ngIf="ill_opert1">Yes</span>
                            </mat-button-toggle>
                            </div>
                            <div class="col-8 col-md-3 col-lg-3 col-xl-2" [hidden]="ill_operation_txt">
                                <textarea  #matInput  [disabled]="client" class="ipcss " (change)="endo_change();send_data()" [(ngModel)]="ill_desc_data" rows="1"></textarea>
                            </div>
                            </div>
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-12">
                            <mat-label class="matlabel ">
                                <b> Dental history</b>
                            </mat-label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="row"  style="padding-bottom: 3px;">
                            <div class="col-8 col-md-8 col-lg-5 col-xl-5 ">
                                <mat-label class="matlabel ">Is the patient regular attender?</mat-label>
                            </div>
                            <div class="col-4 col-md-2 col-lg-2 col-xl-2">

                                <mat-button-toggle id="toggid1" class="togglebtn"
                                    [(ngModel)]="reg_atend1" ngDefaultControl [checked]="reg_atend1" [disabled]="client" (change)="changepatientreg($event.source.checked); send_data()" [ngStyle]="reg_atend1 && { 'background' : 'green' } || !reg_atend1 && { 'background' : 'red' }">
                                <span class="familytab" *ngIf="!reg_atend1">No</span>
                                <span class="familytab" *ngIf="reg_atend1">Yes</span>
                                </mat-button-toggle>
                                </div>
                                <!-- <button id="matbtn" *ngIf="ynopt3" [(ngModel)]="reg_atend"
                                    [style.color]="getColor('Yes')" [ngStyle]="{'border':'1px solid green'}"
                                    mat-raised-button (click)="option(3)">Yes</button>
                                <button id="matbtn" *ngIf="!ynopt3" [style.color]="getColor('No')"
                                    [ngStyle]="{'border':'1px solid red'}" [(ngModel)]="reg_atend" mat-raised-button
                                    (click)="option(3)">No</button> -->

                            </div>
                            <!-- <div class="col-8 col-md-3 col-lg-3 col-xl-2">
                    <textarea   #matInput  *ngIf="!illflag" class="ipcss " (change)="endo_change()" [(ngModel)]="ill_desc_data" rows="1"></textarea>

                </div> -->
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="row">
                            <div class="col-8 col-md-8 col-lg-5 col-xl-5 ">
                                <mat-label class="matlabel ">Patient motivation towards endodontically tooth restored
                                </mat-label>
                            </div>
                            <div class="col-4 col-md-2 col-lg-2 col-xl-2">
                                <mat-button-toggle id="toggid1" class="togglebtn" [disabled]="client" [(ngModel)]="pat_mottxt1" ngDefaultControl [checked]="pat_mottxt1" (change)="changepatientmotivation($event.source.checked); send_data()" [ngStyle]="pat_mottxt1 && { 'background' : 'green' } || !pat_mottxt1 && { 'background' : 'red' }">
                               <span class="familytab" *ngIf="!pat_mottxt1">No</span>
                               <span class="familytab" *ngIf="pat_mottxt1">Yes</span>
                            </mat-button-toggle>
                                <!-- <button id="matbtn" *ngIf="ynopt4" [(ngModel)]="pat_mottxt"
                                    [style.color]="getColor('Yes')" [ngStyle]="{'border':'1px solid green'}"
                                    mat-raised-button (click)="option(4)">Yes</button>
                                <button id="matbtn" *ngIf="!ynopt4" [style.color]="getColor('No')"
                                    [ngStyle]="{'border':'1px solid red'}" [(ngModel)]="pat_mottxt" mat-raised-button
                                    (click)="option(4)">No</button> -->

                            </div>
                            <!-- <div class="col-8 col-md-3 col-lg-3 col-xl-2">
                    <textarea   #matInput  *ngIf="!illflag" class="ipcss " (change)="endo_change()" [(ngModel)]="ill_desc_data" rows="1"></textarea>

                </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class=" col-8 col-md-8 col-lg-8 col-xl-5">
                            <mat-label class="matlabel ">Grade scale</mat-label>
                        </div>
                        <div class="col-12 col-md-3 col-lg-2 col-xl-2">
                            <mat-slider min="1" max="10" (change)="updateSetting($event);send_data()" [(ngModel)]="grade_txt" color="primary"
                                value={{gridsize}} #gridsize [disabled]="client"></mat-slider>
                        </div>
                        <div class=" col-12 col-md-1 col-lg-1 col-xl-1" style="margin-left: 7px;margin-top: 9px;">
                            <mat-label class="ipcss  ">
                                <span class="gridSizeValue">{{gridsize.value}}</span>
                            </mat-label>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>
<div [hidden]="Intra_Oral_Pane" class="expvalue">
    <div class="block1">
        <mat-accordion class="mataccordion">
            <mat-expansion-panel class="expandpanel" (opened)="panelOpenState6 = true"
                (closed)="panelOpenState6 = false">
                <mat-expansion-panel-header class="exppanel width ">
                    <mat-panel-title class="title ">
                        <span class=""> Intra oral</span>
                      
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div [hidden]="intraTab" class="margin_top_10">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                            <mat-label class="matlabel">Mouth<br>
                                <textarea  #matInput maxlength="50" class="ipcss" [disabled]="client"
                                    [(ngModel)]="intra_mouth" required (change)="send_data()"></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                            <mat-label class="matlabel">Labial mucosa<br>
                                <textarea  #matInput maxlength="50" class="ipcss" [disabled]="client"
                                    [(ngModel)]="intra_lab_mous" required (change)="send_data()"></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                            <mat-label class="matlabel">Palatal<br>
                                <textarea  #matInput maxlength="50" class="ipcss" [disabled]="client"
                                    [(ngModel)]="intra_paltal" required (change)="send_data()"></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                            <mat-label class="matlabel">Buccal mucosa<br>
                                <textarea  #matInput maxlength="50" class="ipcss" [disabled]="client"
                                    [(ngModel)]="intra_buc_mou" required (change)="send_data()"> </textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                            <mat-label class="matlabel">Tongue<br>
                                <textarea  #matInput maxlength="50" class="ipcss" [disabled]="client"
                                    [(ngModel)]="intra_tong" required (change)="send_data()"></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                            <mat-label class="matlabel">Alveolar mucosa<br>
                                <textarea  #matInput maxlength="50" class="ipcss" [disabled]="client"
                                    [(ngModel)]="intra_alv_mus" required (change)="send_data()"></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                            <mat-label class="matlabel">Periodontium<br>
                                <textarea  #matInput maxlength="50" class="ipcss" [disabled]="client"
                                    [(ngModel)]="intra_peri" required (change)="send_data()"></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                            <mat-label class="matlabel">Floor of mouth<br>
                                <textarea  #matInput maxlength="50" class="ipcss" [disabled]="client"
                                    [(ngModel)]="intra_flo_mou" required (change)="send_data()"></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Vestible_Div">
                            <mat-label class="matlabel">Vestibule<br>
                                <textarea  #matInput maxlength="50" class="ipcss" [disabled]="client" (change)="send_data()"
                                    [(ngModel)]="intra_vest"></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Tonsils_Third_Div_new">
                            <mat-label class="matlabel">Tonsils<br>
                                <textarea  #matInput maxlength="50" class="ipcss" [disabled]="client" [(ngModel)]="intra_tons" required (change)="send_data()"></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Tonsils_Third_Div">

                            <mat-label class="matlabel">Lips and labial mucosa<br>
                                <textarea  #matInput maxlength="50" class="ipcss" [disabled]="client" [(ngModel)]="intra_lip_lab" required (change)="send_data()"></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Tonsils_Third_Div">

                            <mat-label class="matlabel">Hard and soft palate<br>
                                <textarea  #matInput maxlength="50" class="ipcss" [disabled]="client"  [(ngModel)]="intra_hard_soft" required (change)="send_data()"></textarea>
                            </mat-label>
                        </div>
                        <div class=" col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Soft_tissue_Div">
                            <mat-label class="matlabel">Pocket<br>
                                <textarea  #matInput maxlength="50" class="ipcss" [disabled]="client" [(ngModel)]="intra_pocket" (change)="send_data()"></textarea>
                            </mat-label>


                        </div>
                        <div class=" col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Soft_tissue_Div">
                            <mat-label class="matlabel">Bleeding<br>
                                <textarea  #matInput maxlength="50" class="ipcss" [disabled]="client" [(ngModel)]="intra_bleed" (change)="send_data()"></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Fernum_txt_Div">
                            <mat-label class="matlabel">Fernum<br>
                                <textarea  #matInput maxlength="50" class="ipcss" [disabled]="client" [(ngModel)]="intra_fern" (change)="send_data()"></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Extrersinus_Txt_Div">
                            <mat-label class="matlabel">External sinus tracts<br>
                                <textarea  #matInput maxlength="50" class="ipcss" [disabled]="client"  [(ngModel)]="intra_ext_tract" [required]="intra_ext_sinus_req" (change)="send_data()"></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="ease_access_Txt_Div">
                            <mat-label class="matlabel">Ease of access<br>
                                <textarea  #matInput maxlength="50" class="ipcss" [disabled]="client" [(ngModel)]="intra_ease" [required]="intra_ease_access_req" (change)="send_data()"></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="sewl_txt">

                            <mat-label class="matlabel">Facial swelling<br> 
                                <textarea  #matInput maxlength="50" class="ipcss" [disabled]="client"  [(ngModel)]="intra_fac_swell" [required]="intra_face_swell_req" (change)="send_data()"></textarea>
                            </mat-label>
                        </div>
                    </div>
                    <div class="row" [hidden]="Hardtissue_Div_Head">
                        <div class="col-12">
                            <p class="tervys_heading">Hard tissue</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Hardtissue_First_Div">
                            <mat-label class="matlabel">No. of permanent teeth<br>
                                <textarea  #matInput maxlength="2" class="ipcss" [disabled]="client" (change)="send_data()"
                                    [(ngModel)]="perm_teeth_present_txt" (change)="intra_oral_change()"
                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                    [required]="intra_per_teeth_req"></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Hardtissue_First_Div">
                            <mat-label class="matlabel">No deciduous teeth<br>
                                <textarea  #matInput maxlength="2" class="ipcss" [disabled]="client" (change)="send_data()"
                                    [(ngModel)]="dec_teeth_present_txt" [required]="intra_dec_teeth_req"
                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Hardtissue_First_Div">
                            <mat-label class="matlabel">Texture<br>
                                <select class="ipcss  " [(ngModel)]="intra_texture" disableOptionCentering [disabled]="client" (change)="send_data()">
                                    <option value="Normal">Normal</option>
                                    <option value="Abnormal">Abnormal</option>
                                </select>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Hardtissue_First_Div">

                            <mat-label class="matlabel">Unerupted teeth<br>
                                <select required class="ipcss  " [required]="intra_un_erupt_teeth_req" (change)="send_data()"
                                    [(ngModel)]="unerupted_teeth" (change)="intra_oral_change()"
                                    disableOptionCentering [disabled]="client">
                                    <option *ngFor="let unerupted of unerupted_teeth_array"
                                        value={{unerupted.den_teeth_name_id}}>{{unerupted.description}}</option>
                                </select>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Hardtissue_First_Div">
                            <mat-label class="matlabel">Ineruption teeth<br>
                                <select required class="ipcss  " [required]="intra_un_erupt_teeth_req" [disabled]="client" (change)="send_data()"
                                    [(ngModel)]="inerupted_teeth" (change)="intra_oral_change()"
                                    disableOptionCentering>
                                    <option *ngFor="let inerupted of inerupted_teeth_array"
                                        value={{inerupted.den_teeth_name_id}}>{{inerupted.description}}</option>
                                </select>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Hardtissue_First_Div">
                            <mat-label class="matlabel">Super numery teeth<br>
                                <textarea  #matInput class="ipcss" (change)="intra_oral_change()" [disabled]="client" (change)="send_data()"
                                    maxlength="50" [(ngModel)]="intra_super"></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Intra_Keyridge_Div">
                            <mat-label class="matlabel">Key ridge position<br>
                                <textarea  #matInput class="ipcss" [disabled]="client" (change)="send_data()"
                                    [required]="intra_key_post_req" (change)="intra_oral_change()" maxlength="50"
                                    [(ngModel)]="intra_key" required></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Hard_Gingival_Cal_div">
                            <mat-label class="matlabel">Supragingival calculus<br>
                                <textarea  #matInput  class="ipcss" [disabled]="client" (change)="send_data()"
                                    maxlength="50" [(ngModel)]="intra_sup_ging"></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Hard_Gingival_Cal_div">
                            <mat-label class="matlabel">Subgingival calculus<br>
                                <textarea  #matInput  class="ipcss" [disabled]="client" (change)="send_data()"
                                    maxlength="50" [(ngModel)]="intra_sub_ging"></textarea>
                            </mat-label>
                        </div>



                        <div class=" col-8 col-md-4 col-lg-4 col-xl-2" [hidden]="Intra_Endotreat_Div">
                            <mat-label class="matlabel ">Endodontically treated</mat-label>
                        </div>
                        <div class=" col-4 col-md-1 col-lg-1 col-xl-1"  [hidden]="Intra_Endotreat_Div">

                            <mat-button-toggle id="toggid1" class="togglebtn"  [hidden]="Intra_Endotreat_Div"
                            [(ngModel)]="togg_endo_treat1" ngDefaultControl [checked]="togg_endo_treat1" [disabled]="client"
                            (change)="changeendotreat($event.source.checked);send_data()" [ngStyle]="togg_endo_treat1 && { 'background' : 'green' } || !togg_endo_treat1 && { 'background' : 'red' }">
                           <span class="familytab" *ngIf="!togg_endo_treat1">No</span>
                           <span class="familytab" *ngIf="togg_endo_treat1">Yes</span>
                        </mat-button-toggle>

                            <!-- <button id="matbtn" *ngIf="ynopt5" [hidden]="Intra_Endotreat_Div"
                                [(ngModel)]="togg_endo_treat" [style.color]="getColor('Yes')"
                                [ngStyle]="{'border':'1px solid green'}" mat-raised-button
                                (click)="option(5)">Yes</button>
                            <button id="matbtn" *ngIf="!ynopt5" [hidden]="Intra_Endotreat_Div"
                                [style.color]="getColor('No')" [ngStyle]="{'border':'1px solid red'}"
                                [(ngModel)]="togg_endo_treat" mat-raised-button (click)="option(5)">No</button> -->

                        </div>
                    </div>

                    <div class="row" [hidden]="Caries_Div">
                        <div class="col-12">
                            <p class="tervys_heading">Caries</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Caries_Div">
                            <mat-label class="matlabel" >Restorable<br>
                                <textarea  #matInput class="ipcss" maxlength="500" [disabled]="client"
                                    [(ngModel)]="intra_rest" (change)="send_data()"></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Caries_Div">
                            <mat-label class="matlabel" >Unrestorable<br>
                                <textarea  #matInput class="ipcss" maxlength="500" [disabled]="client"
                                    [(ngModel)]="intra_unrest" (change)="send_data()"></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Caries_Div"> 
                            <mat-label class="matlabel">Occlusal facet’s wear<br>
                                <textarea  #matInput class="ipcss" maxlength="500" [disabled]="client"
                                    [(ngModel)]="intra_occ" (change)="send_data()"></textarea>
                            </mat-label>
                        </div>


                    </div>
                    <div class="row"  [hidden]="Tot_Causes_Div">
                        <div class="col-12">
                            <p class="tervys_heading">Causes</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3"  [hidden]="Tot_Causes_Div">
                            <mat-label class="matlabel" [hidden]="Tot_Causes_Div">Attrition<br>
                                <select class="ipcss"  [(ngModel)]="intra_att" [disabled]="client" (change)="send_data()">
                                    <option value="Tooth wear">Tooth wear</option>
                                    <option value="Advanced and excessive wear">Advanced and excessive wear
                                    </option>
                                    <option value="Tooth surface loss">Tooth surface loss</option>

                                </select>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Tot_Causes_Div">
                            <mat-label class="matlabel" >Abration<br>
                                <select class="ipcss  "  [(ngModel)]="intra_abart" [disabled]="client" (change)="send_data()">
                                    <option value="Tooth wear">Pathologic wear</option>
                                    <option value="Advanced and excessive wear">Extrinsic</option>
                                    <option value="Tooth surface loss">Intrinsic</option>

                                </select>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Tot_Causes_Div">
                            <mat-label class="matlabel" [hidden]="Tot_Causes_Div">Description<br>
                                <textarea  #matInput class="ipcss" maxlength="50" [disabled]="client" (change)="send_data()"
                                    [(ngModel)]="intra_discri"></textarea>
                            </mat-label>

                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Tot_Causes_Div">
                            <mat-label class="matlabel" [hidden]="Tot_Causes_Div">Erosion<br>
                                <select class="ipcss  "  [(ngModel)]="ero_txt" [disabled]="client" (change)="send_data()">
                                    <option value="Pathologic wear">Idiopathic</option>
                                    <option value="Extrinsic">Extrinsic</option>
                                    <option value="Intrinsics">Intrinsic</option>

                                </select>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Tot_Causes_Div">
                            <mat-label class="matlabel" [hidden]="Tot_Causes_Div">Description<br>
                                <textarea  #matInput  class="ipcss" [disabled]="client" (change)="send_data()"
                                    maxlength="50" [(ngModel)]="intra_des1"></textarea>
                            </mat-label>
                        </div>

                    </div>
                    <div class="row" >
                        <div class="col-12">
                            <p class="tervys_heading" [hidden]="Tot_Occlusal_Div">Occlusal analysis</p>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Tot_Causes_Div">
                            <mat-label class="matlabel" [hidden]="Tot_Occlusal_Div">Over bite<br>
                                <textarea  #matInput class="ipcss" maxlength="50" [disabled]="client" (change)="send_data()"
                                    [(ngModel)]="intra_over"></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Tot_Causes_Div">
                            <mat-label class="matlabel" [hidden]="Tot_Occlusal_Div">Cross bite<br>
                                <textarea  #matInput class="ipcss" maxlength="50" [disabled]="client" (change)="send_data()"
                                    [(ngModel)]="intra_cross"></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Tot_Causes_Div">
                            <mat-label class="matlabel" [hidden]="Tot_Occlusal_Div">Open bite<br>
                                <textarea  #matInput class="ipcss" maxlength="50" [disabled]="client" (change)="send_data()"
                                    [(ngModel)]="intra_open"></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Tot_Causes_Div">
                            <mat-label class="matlabel" [hidden]="Tot_Occlusal_Div">Plunger cusps<br>
                                <textarea  #matInput class="ipcss" maxlength="50" [disabled]="client" (change)="send_data()"
                                    [(ngModel)]="intra_plung"></textarea>
                            </mat-label>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-12">
                            <p class="tervys_heading" [hidden]="Trauma_Div">Trauma from occlusion</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3"  [hidden]="Trauma_Div">
                            <mat-label class="matlabel">Primary trauma from occlusion<br>
                                <textarea  #matInput class="ipcss" maxlength="250" [disabled]="client" (change)="send_data()"
                                    [(ngModel)]="intra_pri"></textarea> 
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3"  [hidden]="Trauma_Div">
                            <mat-label class="matlabel">Secondary trauma from occlusion<br>
                                <textarea  #matInput class="ipcss" maxlength="250" [disabled]="client" (change)="send_data()"
                                    [(ngModel)]="intra_sec"></textarea>
                            </mat-label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <p class="tervys_heading" [hidden]="Tot_Soft_Div">Soft tissue</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3"  [hidden]="Tot_Soft_Div">
                            <mat-label class="matlabel">Oral hygienic status (Gingiva)<br>
                                <select class="ipcss  " [(ngModel)]="oral_hyg_sta" [required]="intra_oral_hyg_req" [disabled]="client"
                                    (change)="intra_oral_change();send_data();">
                                    <option value="Good">Good</option>
                                    <option value="Satisfactory">Satisfactory</option>
                                    <option value="Poor">Poor</option>
                                </select>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3"  [hidden]="Tot_Soft_Div">
                            <mat-label class="matlabel">Max mouth opening incisal edge<br>
                                <input  class="ipcss  " matInput maxlength="5"  [disabled]="client"
                                    [(ngModel)]="intra_max_mouth" [required]="intra_max_mouth_req"
                                    (change)="intra_oral_change();send_data()" />
                            </mat-label>

                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Tot_Soft_Div">
                            <mat-label class="matlabel" >Gingival colour<br>
                                <select required class="ipcss  "  [(ngModel)]="ging_col"
                                    [required]="intra_ging_col_req" (change)="intra_oral_change(); send_data();"
                                    disableOptionCentering [disabled]="client">
                                    <option *ngFor="let ging of ging_col_array" value={{ging.ging_color_id}}>
                                        {{ging.description}}</option>
                                </select>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Tot_Soft_Div">
                            <mat-label class="matlabel" >Gingival texture<br>
                                <select class="ipcss  "  [(ngModel)]="intra_ging_txt" [disabled]="client"
                                    [required]="intra_ging_txt_req" (change)="intra_oral_change();send_data()">
                                    <option value="Normal">Normal</option>
                                    <option value="Oedematous">Oedematous</option>
                                    <option value="Fibrous">Fibrous</option>

                                </select>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Tot_Soft_Div">
                            <mat-label class="matlabel" >Palatal contour<br>
                                <select class="ipcss  " [(ngModel)]="intra_pal_cont"  [disabled]="client"
                                    [required]="intra_pal_cont_req" (change)="intra_oral_change();send_data();">
                                    <option value="Normal">Normal</option>
                                    <option value="Shallow">Shallow</option>
                                    <option value="Deep">Deep</option>

                                </select>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Tot_Soft_Div">
                            <mat-label class="matlabel" >Cirum oral muscle tone<br>
                                <select required class="ipcss  " [required]="intra_cir_oral_req"
                                     [(ngModel)]="circum_oral_mus_tone" [disabled]="client"
                                    (change)="intra_oral_change();send_data()" disableOptionCentering>
                                    <option *ngFor="let circum of circum_oral_mus_tone_array"
                                        value={{circum.circum_tone_id}}>{{circum.description}}</option>
                                </select>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Trauma_Div">
                            <mat-label class="matlabel" >Tonsils and Adenoids<br>
                                <textarea  #matInput class="ipcss" maxlength="50" [disabled]="client"
                                    [(ngModel)]="intra_ton_anod" [required]="intra_tons_ando_req"
                                    (change)="intra_oral_change();send_data();" ></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Trauma_Div">
                            <mat-label class="matlabel" >Oral mucosa<br>
                                <textarea  #matInput class="ipcss" required maxlength="25" [disabled]="client"
                                    [(ngModel)]="intra_oral_muos" [required]="intra_oral_mous_req"
                                    (change)="intra_oral_change();send_data();" ></textarea>
                            </mat-label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <p class="tervys_heading" [hidden]="Frenal_Attach_Div">Frenal attachment</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Frenal_Attach_Div"> 
                            <mat-label class="matlabel">Upper<br>
                                <select required class="ipcss  " [(ngModel)]="fren_upper" disableOptionCentering [disabled]="client" (change)="send_data()"> 
                                    <option *ngFor="let fren_up of fren_upper_array"
                                        value={{fren_up.den_fre_att_typ_id}}>{{fren_up.description}}</option>
                                </select>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Frenal_Attach_Div">
                            <mat-label class="matlabel" >Lower<br>
                                <select required class="ipcss  " [(ngModel)]="fren_lower" disableOptionCentering [disabled]="client" (change)="send_data()">
                                    <option *ngFor="let fren_up of fren_upper_array"
                                        value={{fren_up.den_fre_att_typ_id}}>{{fren_up.description}}</option>
                                </select>
                            </mat-label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <p class="tervys_heading" [hidden]="Tot_Tongue_Div">Tongue</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Tot_Tongue_Div">
                            <mat-label class="matlabel" >Size<br>
                                <textarea  #matInput class="ipcss" [disabled]="client"
                                    [required]="intra_tong_size_req" (change)="intra_oral_change();send_data();" maxlength="25"
                                    [(ngModel)]="intra_size" ></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Tot_Tongue_Div">

                            <mat-label class="matlabel" [hidden]="Tot_Tongue_Div">Posture<br>
                                <textarea  #matInput class="ipcss" [disabled]="client"
                                    [required]="intra_tong_post_req" (change)="intra_oral_change();send_data();" maxlength="25"
                                    [(ngModel)]="intra_post" ></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Tot_Tongue_Div">
                            <mat-label class="matlabel" [hidden]="Tot_Tongue_Div">Shape<br>
                                <textarea  #matInput class="ipcss" [disabled]="client"
                                    [required]="intra_tong_shape_req" (change)="intra_oral_change();send_data();" maxlength="25"
                                    [(ngModel)]="intra_shape" ></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Tot_Tongue_Div">
                            <mat-label class="matlabel" [hidden]="Tot_Tongue_Div">Movement<br>
                                <textarea  #matInput class="ipcss" [disabled]="client"
                                    [required]="intra_tong_move_req" (change)="intra_oral_change();send_data();" maxlength="25"
                                    [(ngModel)]="intra_move" ></textarea>
                            </mat-label>
                        </div>

                    </div>


                </div>
                        </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>
<div [hidden]="Extraoral_Pane" class="expvalue">
    <div class="block1">
        <mat-accordion class="mataccordion">
            <mat-expansion-panel  class="expandpanel" (opened)="panelOpenState7 = true"
                (closed)="panelOpenState7 = false">
                <mat-expansion-panel-header class="exppanel width">
                    <mat-panel-title class="title ">
                        <span class=""> Extra oral </span>
                        <!-- <mat-icon  class=""  *ngIf="!panelOpenState7">add</mat-icon>
                <mat-icon  class="" *ngIf="panelOpenState7">remove</mat-icon>   -->
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div [hidden]="extraTab" class="margin_top_10">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                            <mat-label class="matlabel">Conditions<br>
                                <textarea  #matInput *ngIf="!extraflag" class="ipcss" [disabled]="client"
                                    [(ngModel)]="Extra_Condtion_Data_type"  (click)="extra()" rows="1"></textarea>
                                <mat-select *ngIf="extraflag" class="ipcss  " disableOptionCentering="true" multiple>
                                    <button mat-raised-button id="closemedhist" (click)="closeextra()">close</button>
                                    <mat-option color="primary" *ngFor="let condition of Extra_Condtion_Data"
                                        value={{condition.label}}>
                                        <mat-checkbox multiple [checked]="condition.checked"
                                            (change)="extra_cond_popup(condition.label,condition.value)">
                                            {{condition.label}}</mat-checkbox>&nbsp;
                                    </mat-option>
                                </mat-select>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">

                            <mat-label class="matlabel">Jaws & TMJ<br>
                                <textarea  #matInput *ngIf="!jawflag" class="ipcss" [disabled]="client"
                                    [(ngModel)]="Extra_JawsTMJ_Data_type"  (click)="extrajaw()" rows="1"></textarea>
                                <mat-select *ngIf="jawflag" class="ipcss  " disableOptionCentering="true" multiple>
                                    <button mat-raised-button id="closemedhist" (click)="closejaw()">close</button>
                                    <mat-option color="primary" *ngFor="let jaw of Extra_JawsTMJ_Data"
                                        value={{jaw.label}}>
                                        <mat-checkbox multiple [checked]="jaw.checked"
                                            (change)="extra_jaws_popup(jaw.label,jaw.value)">{{jaw.label}}
                                        </mat-checkbox>&nbsp;
                                    </mat-option>
                                </mat-select>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                            <mat-label class="matlabel">Conditions note<br>
                                <textarea  #matInput class="ipcss" maxlength="50" [disabled]="client" (change)="send_data()"
                                    [(ngModel)]="con_note" required></textarea> 
                            </mat-label>
                        </div>

                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">

                            <mat-label class="matlabel">Jaws & TMJ Note<br>
                                <textarea  #matInput class="ipcss" maxlength="50" [disabled]="client" (change)="send_data()"
                                    [(ngModel)]="jaw_note" required></textarea>
                            </mat-label>
                        </div>

                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3"  [hidden]="Tmjexam_Div">
                            <mat-label class="matlabel">TMJ examination<br>
                                <textarea  #matInput class="ipcss" [disabled]="client" (change)="send_data()"
                                    [required]="extra_tmj_exam_req" maxlength="50" [(ngModel)]="tmj_exam"></textarea>
                            </mat-label>
                        </div>

                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="extra_div">
                            <mat-label class="matlabel" >Cephalic index<br>
                                <input  class="ipcss  " maxlength="5" [(ngModel)]="ceph_ind_txt" [disabled]="client" (change)="send_data()"
                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                    [required]="extra_ceph_req" (change)="extra_oral_change()">
                            </mat-label>
                        </div>

                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3"  [hidden]="facial_index_hidden">
                            <mat-label class="matlabel">Facial index<br>
                                <input  class="ipcss  " maxlength="5" [(ngModel)]="facial_index_txt" [disabled]="client" 
                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                    [required]="extra_facial_index_req" (change)="extra_oral_change();send_data();" />
                            </mat-label>
                        </div>

                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="facial_div_hidden">

                            <mat-label class="matlabel" >Facial divergence<br>
                                <select required class="ipcss  " [(ngModel)]="facial_divergence" [disabled]="client"
                                    (change)="extra_oral_change();send_data();" [required]="extra_facial_div_req"
                                    disableOptionCentering>
                                    <option *ngFor="let facial of facial_divergence_array"
                                        value={{facial.facial_div_id}}>{{facial.description}}</option>
                                </select>
                            </mat-label>
                        </div>

                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3"  [hidden]="lip_hidden">

                            <mat-label class="matlabel">Lip posture<br>
                                <select required class="ipcss  " [(ngModel)]="lip_posture" [disabled]="client"
                                    (change)="extra_oral_change();send_data();" [required]="extra_lip_post_req"
                                    disableOptionCentering>
                                    <option *ngFor="let lip of lip_posture_array" value={{lip.lip_post_id}}>
                                        {{lip.description}}</option>
                                </select>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="incomp_hidden">
                            <mat-label class="matlabel" >Incompetent<br>
                                <select class="ipcss  " [(ngModel)]="in_comp" [required]="extra_in_comp_req" [disabled]="client"
                                    (change)="extra_oral_change();send_data();" disableOptionCentering>
                                    <option >Select</option>
                                    <option value="Short Upper Lip">Short Upper Lip</option>
                                    <option value="Short Lower Lip">Short Lower Lip</option>
                                </select>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="clinical_hidden"> 

                            <mat-label class="matlabel" >Clinical FMA<br>
                                <select class="ipcss  " [(ngModel)]="clinical_fma_txt" [disabled]="client"
                                    [required]="extra_clinical_fma_req" (change)="extra_oral_change();send_data()"
                                    disableOptionCentering>
                                    <option >Select</option>
                                    <option value="Average">Average</option>
                                    <option value="High">High</option>
                                    <option value="Low">Low</option>
                                </select>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="upper_hidden">

                            <mat-label class="matlabel" >Upper lip<br>
                                <select class="ipcss  " [(ngModel)]="upp_lip_txt" disableOptionCentering [disabled]="client" (change)="send_data()">
                                    <option >Select</option>
                                    <option value="Short">Short</option>
                                    <option value="Long">Long</option>
                                    <option value="Normal">Normal</option>
                                </select>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="upper_hidden">

                            <mat-label class="matlabel" >Lower lip<br>
                                <select class="ipcss  " [(ngModel)]="low_lip_txt" disableOptionCentering [disabled]="client" (change)="send_data()">
                                    <option >Select</option>
                                    <option value="Short">Short</option>
                                    <option value="Long">Long</option>
                                    <option value="Normal">Normal</option>
                                </select>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="chin_hidden">
                            <mat-label class="matlabel" >Chin position<br>
                                <select class="ipcss  " [(ngModel)]="chin_position" [disabled]="client"
                                    (change)="extra_oral_change();send_data();" [required]="extra_chin_posi_req"
                                    disableOptionCentering>
                                    <option *ngFor="let chin of chin_position_array" value={{chin.chin_posi_id}}>
                                        {{chin.description}}</option>
                                </select>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="naso_hidden">
                            <mat-label class="matlabel" >Naso labial angle<br>
                                <select class="ipcss  " [(ngModel)]="naso_lab_angle" [disabled]="client"
                                    (change)="extra_oral_change();send_data();" [required]="extra_naso_lab_req"
                                    disableOptionCentering>
                                    <option *ngFor="let naso of naso_lab_angle_array" value={{naso.naso_lobial_id}}>
                                        {{naso.description}}</option>
                                </select>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="mento_hidden">

                            <mat-label class="matlabel" >Mentolabial sclcus<br>
                                <select required class="ipcss  " [(ngModel)]="mento_lab_scl" [disabled]="client"
                                    (change)="extra_oral_change();send_data();" [required]="extra_mento_scl_req"
                                    disableOptionCentering>
                                    <option *ngFor="let facial of facial_divergence_array"
                                        value={{facial.facial_div_id}}>{{facial.description}}</option>
                                </select>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="inter_hidden">
                            <mat-label class="matlabel" >Inter labial gap<br>
                                <textarea  #matInput class="ipcss  " maxlength="50" [disabled]="client"
                                    [(ngModel)]="inter_labial_gap_txt" [required]="extra_intra_lab_req"
                                    (change)="extra_oral_change();send_data();"></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3"  [hidden]="fac_data_div">
                            <mat-label class="matlabel">Facial profile<br>
                                <select class="ipcss  " [(ngModel)]="facial_profile" [disabled]="client"
                                    (change)="extra_oral_change();send_data();" [required]="extra_face_prof_req"
                                    disableOptionCentering>
                                    <option *ngFor="let facial of facial_profile_array"
                                        value={{facial.facial_profile_id}}>{{facial.description}}</option>
                                </select>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="fac_data_div">

                            <mat-label class="matlabel" >Shape of head<br>
                                <select class="ipcss  " (change)="extra_oral_change();send_data();"
                                    [(ngModel)]="shape_of_head" [required]="extra_shape_head_req"
                                    disableOptionCentering [disabled]="client">
                                    <option *ngFor="let shape of shape_of_head_array" value={{shape.head_shape_id}}>
                                        {{shape.description}}</option>
                                </select>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3"  [hidden]="fac_data_div">
                            <mat-label class="matlabel">Facial form<br>
                                <select class="ipcss  " (change)="extra_oral_change();send_data();" [disabled]="client"
                                    [(ngModel)]="facial_form" [required]="extra_facial_form_req" disableOptionCentering>
                                    <option *ngFor="let facial of facial_form_array"
                                        value={{facial.facial_form_id}}>{{facial.description}}</option>
                                </select>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="skeletal">

                            <mat-label class="matlabel" >Skeletal relationship<br>
                                <textarea  #matInput class="ipcss" maxlength="50" [disabled]="client"
                                    [(ngModel)]="skeletal_relation_txt" [required]="extra_skeletal_req"
                                    (change)="extra_oral_change();send_data();"></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Pharynx_tonsils_txt_div">
                            <mat-label class="matlabel" >Pharynx and tonsils<br>
                                <textarea  #matInput class="ipcss" maxlength="50" [disabled]="client"
                                    [(ngModel)]="phy_tons_txt" [required]="extra_phy_tonsils_req"
                                    (change)="extra_oral_change();send_data();"></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Facial_sym_txt_div">

                            <mat-label class="matlabel" >Facial symmetry<br>
                                <select class="ipcss  " (change)="extra_oral_change();send_data();" [disabled]="client"
                                    [(ngModel)]="facial_sym" [required]="extra_facial_sym_req" disableOptionCentering>
                                    <option *ngFor="let fac_sym of facial_sym_array"
                                        value={{fac_sym.facial_sym_id}}>{{fac_sym.description}}</option>
                                </select>
                            </mat-label>
                        </div>

                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3"  [hidden]="EXtra_lip_txt_div">
                            <mat-label class="matlabel">Lip<br>
                                <select class="ipcss  " (change)="extra_oral_change();send_data();" [disabled]="client"
                                    [(ngModel)]="lip_type" [required]="extra_lip_req" disableOptionCentering>
                                    <option *ngFor="let lip of lip_type_array" value={{lip.lip_type_id}}>
                                        {{lip.description}}</option>
                                </select>
                            </mat-label>
                        </div>

                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="Lymphnode_txt_div">

                            <mat-label class="matlabel" >Lymph node<br>
                                <textarea  #matInput class="ipcss" [(ngModel)]="lym_node_txt"
                                    maxlength="50" [disabled]="client"
                                    onkeypress="return ((event.keyCode > 64 && event.keyCode < 91) || (event.keyCode > 96 && event.keyCode < 123) || event.keyCode == 8)"
                                    [required]="extra_lymph_node_req" (change)="extra_oral_change();send_data();"></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3"  [hidden]="pedia_asym">
                            <mat-label class="matlabel">Asymmetries<br>
                                <textarea  #matInput class="ipcss" [(ngModel)]="ext_asy" (change)="send_data()"
                                    maxlength="50" [disabled]="client"
                                    onkeypress="return ((event.keyCode > 64 && event.keyCode < 91) || (event.keyCode > 96 && event.keyCode < 123) || event.keyCode == 8)"></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="pedia_asym"> 
                            <mat-label class="matlabel" >Speech<br>
                                <textarea  #matInput class="ipcss" [(ngModel)]="ext_speech" (change)="send_data()"
                                    maxlength="50" [disabled]="client"
                                    onkeypress="return ((event.keyCode > 64 && event.keyCode < 91) || (event.keyCode > 96 && event.keyCode < 123) || event.keyCode == 8)"></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="pedia_asym">
                            <mat-label class="matlabel" >Body type<br>
                                <select class="ipcss  " [(ngModel)]="body_type" disableOptionCentering [disabled]="client" (change)="send_data()">
                                    <option *ngFor="let body of body_type_array" value={{body.body_type_id}}>
                                        {{body.description}}</option>
                                </select>
                            </mat-label>

                        </div>

                    </div>

                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>
<div [hidden]="Gnathic_Pane" class="expvalue">

    <div class="block1">

        <mat-accordion class="mataccordion">
            <mat-expansion-panel  class="expandpanel">
                <mat-expansion-panel-header class="exppanel width">
                    <mat-panel-title class="title ">
                        Gnathic
                        <!-- <mat-icon class="iconsleft" *ngIf="!panelOpenState15">add</mat-icon>
                        <mat-icon class="iconsleft" *ngIf="panelOpenState15">remove</mat-icon> -->
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div [hidden]="gnathicTab"  class="row margin_top_10">
                    <div class="col-12">
                        <table id="tbl">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>
                                        <label class="label1">Maxillary Arch</label>
                                    </th>
                                    <th>
                                        <label class="label1">Mandibular Arch</label>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <label>Shape</label>
                                    </td>
                                    <td class="padding_10">
                                        <select [(ngModel)]="gnathic_shape" class="ipcss dropClass" [disabled]="client" (change)="send_data()">
                                            <option >Select</option>
                                            <option value="Average">Average</option>
                                            <option value="V Shape">V Shape</option>
                                            <option value="U Shape">U Shape</option>
                                            <option value="Square">Square</option>
                                        </select>
                                    </td>
                                    <td class="padding_10">
                                        <select [(ngModel)]="gnathic_shape_left" class="ipcss dropClass" [disabled]="client" (change)="send_data()">
                                            <option >Select</option>
                                            <option value="Average">Average</option>
                                            <option value="V Shape">V Shape</option>
                                            <option value="U Shape">U Shape</option>
                                            <option value="Square">Square</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>Arch symmetry</label>
                                    </td>
                                    <td class="padding_10">
                                        <select [(ngModel)]="gnathic_arc" class="ipcss dropClass" [disabled]="client" (change)="send_data()">
                                            <option >Select</option>
                                            <option value="Symmetrical">Symmetrical</option>
                                            <option value="Asymmetrical">Asymmetrical</option>
                                        </select>
                                    </td>
                                    <td class="padding_10">
                                        <select [(ngModel)]="gnathic_arc_left" class="ipcss dropClass" [disabled]="client" (change)="send_data()">
                                            <option >Select</option>
                                            <option value="Symmetrical">Symmetrical</option>
                                            <option value="Asymmetrical">Asymmetrical</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>Arch alignment</label>
                                    </td>
                                    <td class="padding_10">
                                        <select [(ngModel)]="gnathic_arc_ali" class="ipcss dropClass" [disabled]="client" (change)="send_data()">
                                            <option value="select">Select</option>
                                            <option value="Crowding">Crowding</option>
                                            <option value="Spacing">Spacing</option>
                                            <option value="Rotation">Rotation</option>
                                        </select>
                                    </td>
                                    <td class="padding_10">
                                        <select [(ngModel)]="gnathic_arc_ali_left" class="ipcss dropClass" [disabled]="client" (change)="send_data()">
                                            <option value="select">Select</option>
                                            <option value="Crowding">Crowding</option>
                                            <option value="Spacing">Spacing</option>
                                            <option value="Rotation">Rotation</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr [hidden]="axial_hidden">
                                    <td>
                                        <label>Axial inclination</label>
                                    </td>
                                    <td class="padding_10">
                                        <input  #myInput id="myInput" class="ipcss axial_inclination" maxlength="50"
                                            [(ngModel)]="gnathic_axial_incli" [disabled]="client" (change)="send_data()">
                                    </td>
                                    <td class="padding_10">
                                        <input  #myInput id="myInput" class="ipcss axial_inclination" maxlength="50"
                                            [(ngModel)]="gnathic_axial_incli_left" [disabled]="client" (change)="send_data()">
                                    </td>
                                </tr>
                                <tr class="shape_data" [hidden]="other_ind_hidden">
                                    <td>
                                        <label>Other individual regularities</label>
                                    </td>
                                    <td class="padding_10">
                                        <input  #myInput id="myInput" class="ipcss axial_inclination" maxlength="50"
                                            [(ngModel)]="gnathic_other_indi" [disabled]="client" (change)="send_data()">
                                    </td>
                                    <td class="padding_10" >
                                        <input  #myInput id="myInput" class="ipcss axial_inclination" maxlength="50"
                                            [(ngModel)]="gnathic_other_indi_left" [disabled]="client" (change)="send_data()">
                                    </td>
                                </tr>
                                <tr class="shape_data" [hidden]="dental_hidden">
                                    <td>
                                        <label>Dental midline</label>
                                    </td>
                                    <td class="padding_10">
                                        <input  #myInput id="myInput" class="ipcss axial_inclination" maxlength="50"
                                            [(ngModel)]="gnathic_den_mid" [disabled]="client" (change)="send_data()">
                                    </td>
                                    <td class="padding_10">
                                        <input  #myInput id="myInput" class="ipcss axial_inclination" maxlength="50"
                                            [(ngModel)]="gnathic_den_mid_left" [disabled]="client" (change)="send_data()">
                                    </td>

                                </tr>
                                <tr class="shape_data" [hidden]="ske_hidden">
                                    <td>
                                        <label>Skeletal midline</label>
                                    </td>
                                    <td class="padding_10">
                                        <input  #myInput id="myInput" class="ipcss axial_inclination" maxlength="50"
                                            [(ngModel)]="gnathic_ske" (change)="send_data()">
                                    </td>
                                    <td class="padding_10">
                                        <input  #myInput id="myInput" class="ipcss axial_inclination" maxlength="50"
                                            [(ngModel)]="gnathic_ske_left" [disabled]="client" (change)="send_data()">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3"  [hidden]="cure_hidden">
                        <mat-label class="matlabel">Curve of spee (to second molar if
                            present)<br>
                            <select class="ipcss  " [(ngModel)]="gnathic_curve" required disableOptionCentering [disabled]="client" (change)="send_data()">
                                <option >Select</option>
                                <option value="Flat">Flat</option>
                                <option value="Average">Average</option>
                                <option value="Increased">Increased</option>
                            </select>
                        </mat-label>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <p class="tervys_heading">Antero - Posterior</p>
                        </div>
                    </div>
                    <div class="row" [hidden]="antero_hidden">
                        <div class="col-12">
                            <mat-label class="matlabel "><b>Molar Relation</b></mat-label>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                            <mat-label class="matlabel">Right<br>
                                <select class="ipcss  " disableOptionCentering [(ngModel)]="molar_can_right" [disabled]="client" (change)="send_data()">
                                    <option *ngFor="let mol_right of molar_right_array"
                                        value={{mol_right.molar_relation_id}}>{{mol_right.description}}</option>
                                </select>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                            <mat-label class="matlabel">Left<br>
                                <select class="ipcss  " disableOptionCentering [(ngModel)]="molar_can_left" [disabled]="client" (change)="send_data()">
                                    <option *ngFor="let mol_right of molar_right_array"
                                        value={{mol_right.molar_relation_id}}>{{mol_right.description}}</option>
                                </select>
                            </mat-label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12" [hidden]="antero_hidden">
                            <mat-label class="matlabel "><b>Canine Relation</b></mat-label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                            <mat-label class="matlabel">Right<br>
                                <select class="ipcss  " disableOptionCentering [(ngModel)]="molar_right" [disabled]="client" (change)="send_data()">
                                    <option *ngFor="let mol_right of molar_right_array"
                                        value={{mol_right.molar_relation_id}}>{{mol_right.description}}</option>
                                </select>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                            <mat-label class="matlabel">Left<br>
                                <select class="ipcss  " disableOptionCentering [(ngModel)]="molar_left" [disabled]="client" (change)="send_data()">
                                    <option *ngFor="let mol_right of molar_right_array"
                                        value={{mol_right.molar_relation_id}}>{{mol_right.description}}</option>
                                </select>
                            </mat-label>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-12">
                            <p class="tervys_heading" [hidden]="incisor_hidden">Incisor relation</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="incisor_hidden">
                            <mat-label class="matlabel" >Over jet (mm)<br>
                                <select class="ipcss  " [(ngModel)]="gnathic_over" disableOptionCentering [disabled]="client" (change)="send_data()">
                                    <option >Select</option>
                                    <option value="Normal">Normal</option>
                                    <option value="Increased">Increased</option>
                                    <option value="Reversed">Reversed</option>
                                </select>
                            </mat-label>

                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                            <mat-label class="matlabel">Value<br>
                                <input  class="ipcss  " matInput maxlength="5" [(ngModel)]="gnathic_over_val" [disabled]="client" (change)="send_data()"
                                    required />
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3"[hidden]="incisor_hidden">
                            <mat-label class="matlabel" >Over bite (mm)<br>
                                <select class="ipcss  " [(ngModel)]="gnathic_over_bite" disableOptionCentering [disabled]="client" (change)="send_data()">
                                    <option >Select</option>
                                    <option value="Normal">Normal</option>
                                    <option value="Increased">Increased</option>
                                    <option value="Reversed">Reversed</option>
                                </select>
                            </mat-label>

                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                            <mat-label class="matlabel">Value<br>
                                <input  class="ipcss  " matInput maxlength="5" [(ngModel)]="gnathic_bite_val" [disabled]="client" (change)="send_data()"
                                    required />
                            </mat-label>
                        </div>
                    </div>
                  
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>
<div [hidden]="Functional_Pane" class="expvalue">

    <div class="block1">

        <mat-accordion class="mataccordion">
            <mat-expansion-panel  class="expandpanel" (opened)="panelOpenState8 = true"
                (closed)="panelOpenState8 = false">
                <mat-expansion-panel-header class="exppanel ">
                    <mat-panel-title class="title ">
                        <span class="">
                            Functional</span>
                        <!-- <mat-icon class="" *ngIf="!panelOpenState8">add</mat-icon>
                        <mat-icon class="" *ngIf="panelOpenState8">remove</mat-icon> -->
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div [hidden]="funTab" class="margin_top_10">
                    <div class="row">

                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="resp_hidden">
                            <mat-label class="matlabel" >Respiration<br>
                                <select  class="ipcss  " [(ngModel)]="fun_res" [disabled]="client"
                                    (change)="functional_change();send_data();" disableOptionCentering>
                                    <option >Select</option>
                                    <option value="Nasal">Nasal</option>
                                    <option value="Oral">Oral</option>
                                    <option value="Oronasal">Oronasal</option>
                                </select>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="path_hidden">
                            <mat-label class="matlabel" >Path of closure <br>
                                <select  class="ipcss  " [(ngModel)]="fun_path_clo" [disabled]="client"
                                    [required]="fun_path_clos_req" (change)="functional_change();send_data();"
                                    disableOptionCentering>
                                    <option >Select</option>
                                    <option value="Upward">Upward</option>
                                    <option value="Backward">Backward</option>
                                </select>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3"  [hidden]="deg_hidden"> 

                            <mat-label class="matlabel">Deglutition<br>
                                <select  class="ipcss  " [(ngModel)]="fun_deg" [required]="fun_degul_req" [disabled]="client"
                                    (change)="functional_change();send_data();" disableOptionCentering>
                                    <option >Select</option>
                                    <option value="Normal">Normal</option>
                                    <option value="Infantile">Infantile</option>

                                </select>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="upper_hidden">
                            <mat-label class="matlabel" >Perioral muscle activity<br>
                                <select  class="ipcss  " [(ngModel)]="fun_perio_mus_act" [disabled]="client"
                                    [required]="fun_per_mus_act_req" (change)="functional_change();send_data();"
                                    disableOptionCentering>
                                    <option >Select</option>
                                    <option value="Normal">Normal</option>
                                    <option value="Hyper active">Hyper active</option>
                                    <option value="Hypotonic">Hypotonic</option>
                                </select>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="speech_hidden">
                            <mat-label class="matlabel" >Speech<br>
                                <textarea  #matInput class="ipcss" [required]="fun_speech_req" [disabled]="client"
                                    (change)="functional_change();send_data();" maxlength="50" [(ngModel)]="fun_speech"></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3"  [hidden]="mast_hidden">
                            <mat-label class="matlabel">Mastication<br>
                                <textarea  #matInput class="ipcss" [required]="fun_mast_req" [disabled]="client"
                                    (change)="functional_change();send_data();" maxlength="25" [(ngModel)]="fun_masti"></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="inter_fun_hidden">
                            <mat-label class="matlabel" >Interocclusal clearance<br>
                                <textarea  #matInput class="ipcss" [required]="fun_mast_req" [disabled]="client"
                                    (change)="functional_change();send_data();" maxlength="25" [(ngModel)]="fun_int_cle"></textarea>
                            </mat-label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">

                            <p class="tervys_heading" [hidden]="amt_expo_hidden">Amount of incisor exposure</p>
                        </div>
                        <div class="row">
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                                <mat-label class="matlabel">During speech<br>
                                    <input  class="ipcss  " #matInput maxlength="5" [(ngModel)]="fun_dur_speech"
                                        [required]="fun_dur_speech_req" (change)="functional_change();send_data();"
                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57" [disabled]="client" />
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                                <mat-label class="matlabel">During smile<br>
                                    <input  class="ipcss  " #matInput maxlength="5" [(ngModel)]="fun_du_smi"
                                        [required]="fun_fun_du_smi_req" (change)="functional_change();send_data();"
                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57" [disabled]="client" />
                                </mat-label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <p class="matlabel" [hidden]="Temporomandibular">Temporomandibular joint</p>
                            </div>
                        </div>
                        <div class="row" [hidden]="jaw_hidden" style="padding-bottom: 3px;">
                            <div class="col-8 col-sm-6 col-md-6 col-lg-6 col-xl-6 padding">
                                <mat-label class="matlabel ">Jaw function / TMJ complaint now</mat-label>
                            </div>
                            <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1">
                                <mat-button-toggle id="toggid6" class="togglebtn" [disabled]="client"
                                [(ngModel)]="fun_togg_jaw1" ngDefaultControl [checked]="fun_togg_jaw1"
                                (change)="changejawvalue($event.source.checked);send_data();" [ngStyle]="fun_togg_jaw1 && { 'background' : 'green' } || !fun_togg_jaw1 && { 'background' : 'red' }">
                               <span class="familytab" *ngIf="!fun_togg_jaw1">No</span>
                               <span class="familytab" *ngIf="fun_togg_jaw1">Yes</span>
                            </mat-button-toggle>
                                
                                <!-- <button id="matbtn" *ngIf="ynopt6" [(ngModel)]="fun_togg_jaw"
                                    [style.color]="getColor('Yes')" [ngStyle]="{'border':'1px solid green'}"
                                    mat-raised-button (click)="option(6)">Yes</button>
                                <button id="matbtn" *ngIf="!ynopt6" [style.color]="getColor('No')"
                                    [ngStyle]="{'border':'1px solid red'}" [(ngModel)]="fun_togg_jaw" mat-raised-button
                                    (click)="option(6)">No</button> -->

                            </div>
                            <div class="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
                                <textarea  #matInput [hidden]="jaw_function_txt" class="ipcss" maxlength="50" [(ngModel)]="fun_jaw_txt"
                                    [required]="fun_jaw_req" (change)="functional_change();send_data();" [disabled]="client"></textarea>
                            </div>


                        </div>
                        <div class="row" [hidden]="pain_hidden" style="padding-bottom: 3px;">
                            <div class="col-8 col-sm-6 col-md-6 col-lg-6 col-xl-6 padding">
                                <mat-label class="matlabel ">H/O pain</mat-label>
                            </div>
                            <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1">
                                <mat-button-toggle id="toggid7" class="togglebtn" [disabled]="client"
                                [(ngModel)]="fun_ho_pain1" ngDefaultControl [checked]="fun_ho_pain1"
                                (change)="ho_pain_function($event.source.checked);send_data();" [ngStyle]="fun_ho_pain1 && { 'background' : 'green' } || !fun_ho_pain1 && { 'background' : 'red' }">
                               <span class="familytab" *ngIf="!fun_ho_pain1">No</span>
                               <span class="familytab" *ngIf="fun_ho_pain1">Yes</span>
                            </mat-button-toggle>


                                <!-- <button id="matbtn" *ngIf="ynopt7" [(ngModel)]="fun_ho_pain"
                                    [style.color]="getColor('Yes')" [ngStyle]="{'border':'1px solid green'}"
                                    mat-raised-button (click)="option(7)">Yes</button>
                                <button id="matbtn" *ngIf="!ynopt7" [style.color]="getColor('No')"
                                    [ngStyle]="{'border':'1px solid red'}" [(ngModel)]="fun_ho_pain" mat-raised-button
                                    (click)="option(7)">No</button> -->

                            </div>
                            <div class="col-2 col-sm-2 col-md-3 col-lg-3 col-xl-3" [hidden]="hopainflag">
                                <mat-label class="matlabel" >Duration<br>
                                    <input  class="ipcss  iconsleft1 " #matInput maxlength="5" [disabled]="client"
                                        [(ngModel)]="fun_pain_txt" [required]="fun_pain_du_req"
                                        (change)="functional_change();send_data();"
                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
                                </mat-label>
                            </div>


                        </div>
                        <div class="row" [hidden]="sound_hidden" style="padding-bottom: 3px;">
                            <div class="col-8 col-sm-6 col-md-6 col-lg-6 col-xl-6 padding">
                                <mat-label class="matlabel ">H/O sounds</mat-label>
                            </div>
                            <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1">
                                <mat-button-toggle id="toggid8" class="togglebtn" [disabled]="client"
                                [(ngModel)]="fun_ho_son1" ngDefaultControl [checked]="fun_ho_son1"
                                (change)="ho_sound_function($event.source.checked);send_data();" [ngStyle]="fun_ho_son1 && { 'background' : 'green' } || !fun_ho_son1 && { 'background' : 'red' }">
                               <span class="familytab" *ngIf="!fun_ho_son1">No</span>
                               <span class="familytab" *ngIf="fun_ho_son1">Yes</span>
                            </mat-button-toggle>

<!-- 
                                <button id="matbtn" *ngIf="ynopt8" [(ngModel)]="fun_ho_son"
                                    [style.color]="getColor('Yes')" [ngStyle]="{'border':'1px solid green'}"
                                    mat-raised-button (click)="option(8)">Yes</button>
                                <button id="matbtn" *ngIf="!ynopt8" [style.color]="getColor('No')"
                                    [ngStyle]="{'border':'1px solid red'}" [(ngModel)]="fun_ho_son" mat-raised-button
                                    (click)="option(8)">No</button> -->

                            </div>
                            <div class="col-2 col-sm-2 col-md-3 col-lg-3 col-xl-3" [hidden]="dur_flag">
                                <mat-label class="matlabel" >Duration<br>
                                    <input  class="ipcss  iconsleft1 " #matInput maxlength="5" [disabled]="client"
                                        [required]="fun_sound_du_req" [(ngModel)]="fun_ho_sound_txt" (change)="send_data()"
                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
                                </mat-label>
                            </div>


                        </div>
                        <div class="row" style="padding-bottom: 3px;">
                            <div class="col-8 col-sm-6 col-md-6 col-lg-6 col-xl-6 padding">
                                <mat-label class="matlabel ">TMJ tenderness to palpation</mat-label>
                            </div>
                            <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1">

                                <mat-button-toggle id="toggid9" class="togglebtn" [disabled]="client"
                                [(ngModel)]="fun_tj_trend1" ngDefaultControl [checked]="fun_tj_trend1"
                                (change)="tmj_trend_function($event.source.checked);send_data();" [ngStyle]="fun_tj_trend1 && { 'background' : 'green' } || !fun_tj_trend1 && { 'background' : 'red' }">
                               <span class="familytab" *ngIf="!fun_tj_trend1">No</span>
                               <span class="familytab" *ngIf="fun_tj_trend1">Yes</span>
                            </mat-button-toggle>
                                <!-- <button id="matbtn" *ngIf="ynopt9" [(ngModel)]="fun_tj_trend"
                                    [style.color]="getColor('Yes')" [ngStyle]="{'border':'1px solid green'}"
                                    mat-raised-button (click)="option(9)">Yes</button>
                                <button id="matbtn" *ngIf="!ynopt9" [style.color]="getColor('No')"
                                    [ngStyle]="{'border':'1px solid red'}" [(ngModel)]="fun_tj_trend" mat-raised-button
                                    (click)="option(9)">No</button> -->

                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3"  [hidden]="tmj_trend_txt">
                                <mat-label class="matlabel">Left<br>
                                    <textarea  #matInput [disabled]="client"
                                        class="ipcss " [(ngModel)]="tmj_lt"
                                        [required]="fun_trend_lt_req" maxlength="25" (change)="functional_change();send_data()"></textarea>
                                </mat-label>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-2 col-xl-2" [hidden]="tmj_trend_txt">
                                <mat-label class="matlabel" >Right<br>
                                    <textarea  #matInput [disabled]="client"
                                        class="ipcss" [(ngModel)]="tmj_rt"
                                        [required]="fun_trend_rt_req" maxlength="25"   (change)="functional_change();send_data();"></textarea>
                                </mat-label>
                            </div>
                            


                        </div>
                        <div class="row " style="padding-bottom: 3px;">
                            <div class="col-8 col-sm-6 col-md-6 col-lg-6 col-xl-6 padding">
                                <mat-label class="matlabel ">Muscle tenderness to palpation</mat-label>
                            </div>
                            <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1">
                                <mat-button-toggle id="toggid10" class="togglebtn" [disabled]="client"
                                [(ngModel)]="fun_tog_mus1" ngDefaultControl [checked]="fun_tog_mus1"
                                (change)="changemuscle($event.source.checked);send_data();" [ngStyle]="fun_tog_mus1 && { 'background' : 'green' } || !fun_tog_mus1 && { 'background' : 'red' }">
                               <span class="familytab" *ngIf="!fun_tog_mus1">No</span>
                               <span class="familytab" *ngIf="fun_tog_mus1">Yes</span>
                            </mat-button-toggle>


                                <!-- <button id="matbtn" *ngIf="ynopt10" [(ngModel)]="fun_tog_mus"
                                    [style.color]="getColor('Yes')" [ngStyle]="{'border':'1px solid green'}"
                                    mat-raised-button (click)="option(10)">Yes</button>
                                <button id="matbtn" *ngIf="!ynopt10" [style.color]="getColor('No')"
                                    [ngStyle]="{'border':'1px solid red'}" [(ngModel)]="fun_tog_mus" mat-raised-button
                                    (click)="option(10)">No</button> -->

                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3"  [hidden]="muscle_trend_txt">
                                <mat-label class="matlabel ">Where<br>
                                    <textarea  #matInput [hidden]="muscleflag" [disabled]="client" (change)="send_data()"
                                        class="ipcss   " [required]="fun_mus_wh_req"
                                        maxlength="50" [(ngModel)]="fun_mus_wh"></textarea>
                                </mat-label>
                            </div>


                        </div>
                        <div class="row" >
                            <div class="col-12">
                                <mat-label class="matlabel ">Range of mandibular motion</mat-label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                                <mat-label class="matlabel marginl">Opening<br>
                                    <input  matInput class="ipcss  " maxlength="5" [required]="fun_open_req"
                                        (change)="functional_change();send_data()" [(ngModel)]="fun_open_txt" [disabled]="client"/>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                                <mat-label class="matlabel">Protrusion<br>
                                    <input  matInput class="ipcss  " maxlength="5" [required]="fun_port_req"
                                        (change)="functional_change();send_data();" [(ngModel)]="fun_prot" [disabled]="client"/>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                                <mat-label class="matlabel">Right excursion<br>
                                    <input  matInput class="ipcss  " maxlength="5" [required]="fun_rt_ex_req"
                                        (change)="functional_change();send_data();" [(ngModel)]="fun_right_ex" [disabled]="client" />
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                                <mat-label class="matlabel">Left excursion<br>
                                    <input  matInput class="ipcss  " maxlength="5" [required]="fun_lt_ex_req"
                                        (change)="functional_change();send_data();" [(ngModel)]="fun_left_ex" [disabled]="client" />
                                </mat-label>
                            </div>
                        </div>



                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>
<div [hidden]="Orthodontic_Pane" class="expvalue">
    <div class="block1">
        <mat-accordion class="mataccordion">
            <mat-expansion-panel class="expandpanel" (opened)="panelOpenState9 = true"
                (closed)="panelOpenState9 = false">
                <mat-expansion-panel-header class="exppanel ">
                    <mat-panel-title class="title ">
                        <span class=""> Orthodontic evaluation </span>
                        <!-- <mat-icon class="" *ngIf="!panelOpenState9">add</mat-icon>
                        <mat-icon class="" *ngIf="panelOpenState9">remove</mat-icon> -->
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div [hidden]="orthoTab" class="margin_top_10">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="overjet_hidden">
                            <mat-label class="matlabel" >Over jet<br>
                                <textarea  #matInput class="ipcss" [disabled]="client"
                                    [required]="ortho_over_jet_req" (change)="Ortho_change();send_data()" 
                                    [(ngModel)]="ortho_eva_overjet" maxlength="50"></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3"  [hidden]="overbite_hidden">
                            <mat-label class="matlabel">Over bite<br>
                                <textarea  #matInput class="ipcss" [disabled]="client"
                                    [required]="ortho_over_bite_req" (change)="Ortho_change();send_data()" 
                                    [(ngModel)]="ortho_eva_overbite" maxlength="50"></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="cross_hidden">
                            <mat-label class="matlabel" >Cross bite<br>
                                <textarea  #matInput class="ipcss" [disabled]="client"
                                    [required]="ortho_cross_bite_req" (change)="Ortho_change();send_data()" 
                                    [(ngModel)]="ortho_eva_crossbite" maxlength="50"></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="open_hidden">
                            <mat-label class="matlabel" >Open bite<br>
                                <textarea  #matInput class="ipcss" [disabled]="client"
                                    [required]="ortho_open_bite_req" (change)="Ortho_change();send_data();" 
                                    [(ngModel)]="ortho_eva_openbite" maxlength="50"></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3"  [hidden]="sup_hidden">
                            <mat-label class="matlabel" [hidden]="sup_hidden">Supernumerary teeth<br>
                                <textarea  #matInput class="ipcss" (change)="Ortho_change();send_data();" [disabled]="client"
                                    [(ngModel)]="ortho_eva_sup_teetht" maxlength="50"></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="ectopic_hidden">
                            <mat-label class="matlabel" [hidden]="ectopic_hidden">Ectopic eruption<br>
                                <textarea  #matInput class="ipcss" (change)="Ortho_change();send_data();" [disabled]="client"
                                    [(ngModel)]="ortho_eva_ect_erop" [required]="ortho_etho_erop_req"
                                    maxlength="50"></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3"  [hidden]="rotations_hidden">
                            <mat-label class="matlabel">Rotations<br>
                                <textarea  #matInput class="ipcss" (change)="Ortho_change();send_data()" [disabled]="client"
                                    [(ngModel)]="ortho_eva_rot" maxlength="50"></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="crowding_hidden">
                            <mat-label class="matlabel" >Crowding / Spacing<br>
                                <textarea  #matInput class="ipcss" (change)="Ortho_change();send_data();" [disabled]="client"
                                    [(ngModel)]="ortho_eva_crow" maxlength="50"></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="midline_hidden">
                            <mat-label class="matlabel" >Midline<br>
                                <select class="ipcss  " [(ngModel)]="ortho_eva_mid" disableOptionCentering [disabled]="client" (change)="send_data()">
                                    <option >Select</option>
                                    <option value="Normal">Normal</option>
                                    <option value="Deviated">Deviated</option>

                                </select>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3"  [hidden]="arc_length_max_hidden">
                            <mat-label class="matlabel">Arch length maxilla<br>
                                <select class="ipcss  " [(ngModel)]="ortho_eva_arc" (change)="Ortho_change()" [disabled]="client" (change)="send_data()"
                                    disableOptionCentering>
                                    <option >Select</option>
                                    <option value="Adequate">Adequate</option>
                                    <option value="Inadequate">Inadequate</option>

                                </select>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3"[hidden]="arc_length_mand_hidden">
                            <mat-label class="matlabel" >Arch length mandible<br>
                                <select class="ipcss  " (change)="Ortho_change()" [disabled]="client" (change)="send_data()"
                                    [(ngModel)]="ortho_eva_arc_mand" disableOptionCentering>
                                    <option >Select</option>
                                    <option value="Adequate">Adequate</option>
                                    <option value="Inadequate">Inadequate</option>

                                </select>
                            </mat-label>

                        </div>
                        <div class="row">
                            <div class="col-12 col-sm-6 col-md-5 col-lg-3 col-xl-3" [hidden]="any_hidden">
                                <mat-label class="matlabel ">Analysis required</mat-label>
    
                            </div>
                            <div class="col-12 col-sm-6 col-md-1 col-lg-3 col-xl-3">
                                <mat-button-toggle id="toggid17" class="togglebtn" [disabled]="client"
                                [(ngModel)]="ortho_eva_any1" ngDefaultControl [checked]="ortho_eva_any1"
                                (change)="changeanalysis($event.source.checked);send_data();" [ngStyle]="ortho_eva_any1 && { 'background' : 'green' } || !ortho_eva_any1 && { 'background' : 'red' }">
                               <span class="familytab" *ngIf="!ortho_eva_any1">No</span>
                               <span class="familytab" *ngIf="ortho_eva_any1">Yes</span>
                            </mat-button-toggle>


                                <!-- <button id="matbtn" *ngIf="ynopt17" [(ngModel)]="ortho_eva_any"
                                    [style.color]="getColor('Yes')" [ngStyle]="{'border':'1px solid green'}"
                                    mat-raised-button (click)="option(17)">Yes</button>
                                <button id="matbtn" *ngIf="!ynopt17" [style.color]="getColor('No')"
                                    [ngStyle]="{'border':'1px solid red'}" [(ngModel)]="ortho_eva_any" mat-raised-button
                                    (click)="option(17)">No</button> -->
    
                            </div>
    
                        </div>

                    </div>

                
                    <div class="row">
                        <div class="col-12">
                            <p class="tervys_heading" [hidden]="molar_prime_hidden">Molar relation primary</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="molar_prime_hidden">
                            <mat-label class="matlabel" >Right<br>
                                <textarea  #matInput class="ipcss" [disabled]="client"
                                    [required]="ortho_prime_rt_req" (change)="Ortho_change();send_data();"
                                    [(ngModel)]="ortho_eva_rig" maxlength="50"></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3"  [hidden]="molar_prime_hidden">
                            <mat-label class="matlabel">Left<br>
                                <textarea  #matInput class="ipcss" [disabled]="client"
                                    [required]="ortho_prime_lt_req" (change)="Ortho_change();send_data();"
                                    [(ngModel)]="ortho_eva_lef" maxlength="50"></textarea>
                            </mat-label>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-12">
                            <p class="tervys_heading" [hidden]="molar_prime_hidden">Molar relation perm</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="molar_prime_hidden">
                            <mat-label class="matlabel" >Right<br>
                                <textarea  #matInput class="ipcss"  [disabled]="client"
                                    [required]="ortho_prime_rt_req" (change)="Ortho_change();send_data();"
                                    [(ngModel)]="ortho_eva_mol_rit" maxlength="50"></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3"  [hidden]="molar_prime_hidden">
                            <mat-label class="matlabel">Left<br>
                                <textarea  #matInput class="ipcss" [disabled]="client"
                                    [required]="ortho_prime_lt_req" (change)="Ortho_change();send_data();"
                                    [(ngModel)]="ortho_eva_mol_left" maxlength="50"></textarea>
                            </mat-label>
                        </div>

                    </div>


                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>
<div [hidden]="Gums_Teeth_Pane" class="expvalue">

    <div class="block1">

        <app-dental-gums-teeth></app-dental-gums-teeth>
    </div>
</div>
<div [hidden]="Dental_Hist_Pane" class="expvalue">

    <div class="block1">

        <app-dental-spl-dental-hist></app-dental-spl-dental-hist>
    </div>
</div>

<div [hidden]="Birth_Hist_Pane" class="expvalue">
   
        <app-dental-spl-birth-hist-pane></app-dental-spl-birth-hist-pane>

</div>
<div [hidden]="General_Hist_Pane" class="expvalue">

    <div class="block1">
        <app-dental-spl-general-hist-pane></app-dental-spl-general-hist-pane>
    </div>
</div>


<div [hidden]="Natal_Hist_Pane" class="expvalue">

    <div class="block1">
        <app-dental-spl-nata-hist-pane></app-dental-spl-nata-hist-pane>
    </div>
</div>
</div>