<div mat-dialog-title class="popup_border_icd">
  <h5 class="header_txt">Diagnosis</h5>
  <div mat-dialog-actions>
    <img src="../../../assets/img/close_icon.png" (click)="ClosePopup()" width="20px" height="20px" id="close_icd" />
  </div>
</div>
<div mat-dialog-content class="icd_content">
  <div class="container">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <p class="nodata" *ngIf="listProducts.length == 0">No datas(s) found</p>
      <table *ngIf="listProducts.length"
      class="table table-sm discount_table">
        <thead>
          <tr>
            <th class="delete">Patient name</th>
            <th>Mobile</th>
            <th>Doctor</th>
            <th>Admitted date</th>

          </tr>
        </thead>
        <tbody>

          <tr *ngFor="let person of listProducts; let i = index">
            <td style="font-size: 12px;text-align: left;" (click)="view_bill(person)">{{person.patientname}}
            </td>

            <td style="font-size: 12px;text-align: left;" (click)="view_bill(person)">{{person.mobile}}
            </td>
            <td style="font-size: 12px;text-align: left;" (click)="view_bill(person)">{{person.doctor_name}}
            </td>
            <td style="font-size: 12px;text-align: center;" (click)="view_bill(person)">{{person.Date}}
            </td>

          </tr>
        </tbody>
      </table>
      <br><br><br>
    </div>
  </div>
</div>
<div mat-dialog-actions align="end" style="width: auto;right: 19px;">
  <!-- <button (click)="submit()" id="add_btn" [mat-dialog-close]="listProducts" cdkFocusInitial mat-button>
        <img src="../../../assets/ui_icons/buttons/Done_button.svg" class="addimgbtn_icd" />
    </button> -->
</div>