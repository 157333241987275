import { Component, OnInit, ElementRef, ViewChild, } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { Helper_Class } from '../../helper_class';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { customjs } from '../../../assets/js/custom';
import { Subscription } from 'rxjs';
import { ClientViewService } from './client-view.service';
import { Client_Helper } from '../Client_helper';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { ipaddress } from '../../ipaddress';
import { Message_data } from '../../../assets/js/Message_data';
import { ChangePasswordComponent } from '../../../app/common-module/change-password/change-password.component';
import { MatDialog } from '@angular/material/dialog';
declare var $: any;

@Component({
  selector: 'app-clientview',
  templateUrl: './clientview.component.html',
  styleUrls: ['./clientview.component.scss']
})
export class ClientviewComponent implements OnInit {
  @ViewChild('asied_section_width', { static: false }) asied_width: ElementRef;
  @ViewChild('right_section_width', { static: false }) right_width: ElementRef;
  @ViewChild('logo', { static: false }) logo: ElementRef;

  subscription: Subscription;

  public userInfo;
  public profileImg;
  public firstName: string;
  public lastName: string;

  public settings: boolean = false;
  public pharmaSubmenuFlag: boolean = false;
  public activitySubmenuFlag: boolean = false;
  public settingSubmenuFlag: boolean = false;
  public schduleSubmenuFlag: boolean = false;
  public hmSubmenuFlag: boolean = false;
  public apptSubmenuFlag: boolean = false;
  public lifestyleSubmenuFlag: boolean = false;
  public wellnessSubmenuFlag: boolean = false;
  public ehrSubmenuFlag: boolean = false;
  public minimizedAsied: boolean = false;
  public selectedMenu;
  public displayPage;
  public modules = [];

  public docApptFlag: boolean;
  public diagApptFlag: boolean;
  public physioApptFlag: boolean;
  public dietApptFlag:boolean;
  public recordWalletFlag: boolean;
  public pharmaFlag: boolean;
  public secopnFlag: boolean;
  public lifestyleFlag: boolean;
  public relationFlag: boolean;
  public homecareFlag: boolean;
  public billingFlag: boolean;
  public mydocFlag: boolean;
  public blooddonFlag: boolean;
  public referFrndFlag:  boolean;
  public activitiesFlag:  boolean;
  public dietFlag:  boolean;
  public medFlag:  boolean;
  public mindfulFlag:  boolean;
  public healthFlag:  boolean;
  public postCovidFlag:  boolean;
  public vitalsFlag:  boolean;
 // this.vitalsFlag = true;
  public reference_video: boolean = true;
  constructor(public gservice: CommonDataService, public http: Http, public routes: ActivatedRoute, public router: Router, public toastr: ToastrService,public dialog:MatDialog, public messageservice: ClientViewService) { 
      Client_Helper.setrelationlistDiet(undefined);
    }

  ngOnInit(): void {
    this.subscription = this.messageservice.getMessage().subscribe(message => {
      if (message) {
        this.menuNav(message);
      }
    });

    this.userInfo = Helper_Class.getInfo();
    this.firstName = encrypt_decript.Decript(this.userInfo.first_name.toString());
    this.lastName = encrypt_decript.Decript(this.userInfo.last_name.toString());
    this.profileImg = ipaddress.Ip_with_img_address + this.userInfo.profile_image;
    this.getRelation();
    this.pharmaSubmenuFlag = false;
    this.minimizedAsied = false;
    this.schduleSubmenuFlag = false;
    this.activitySubmenuFlag = false;
    if (Helper_Class.getTreatmentList() != null){
      if (Helper_Class.getTreatmentList().app_flow == "without_Normal") {
        this.setHomecareNursePhysio(Helper_Class.getTreatmentList().homecare, "");
        this.menuNav("appnewsecondopinion");
      }else{
        this.menuNav("default");
      }
    }else{
      this.menuNav("default");
    }

    this.modules = Helper_Class.getModules();
    this.docApptFlag = true;
    this.diagApptFlag = true;
    this.physioApptFlag = true;
    this.recordWalletFlag = true;
    this.pharmaFlag = true;
    this.secopnFlag = true;
    this.lifestyleFlag = true;
    this.relationFlag = true;
    this.homecareFlag = true;
    this.billingFlag = true;
    this.mydocFlag = true;
    this.blooddonFlag = true;
    this.referFrndFlag = true;
    this.activitiesFlag = true;
    this.dietFlag = true;
    this.medFlag = true;
    this.mindfulFlag = true;
    this.healthFlag = true;
    this.postCovidFlag = true;
    this.vitalsFlag = true;
    for (var i = 0; i < this.modules.length; i++) {
      if (this.modules[i] == "1") {
        this.docApptFlag = false;
      } else if (this.modules[i] == "2") {
        this.recordWalletFlag = false;
      } else if (this.modules[i] == "3") {
        this.diagApptFlag = false;
      } else if (this.modules[i] == "4") {
        this.pharmaFlag = false;
      } else if (this.modules[i] == "6") {
        this.homecareFlag = false;
      } else if (this.modules[i] == "9") {
        this.secopnFlag = false;
      } else if (this.modules[i] == "10") {
        this.blooddonFlag = false;
      } else if (this.modules[i] == "14") {
        this.lifestyleFlag = false;
      } else if (this.modules[i] == "26") {
        this.relationFlag = false;
      } else if (this.modules[i] == "27") {
        this.mydocFlag = false;
      } else if (this.modules[i] == "28") {
        this.billingFlag = false;
      } else if (this.modules[i] == "34") {
        this.referFrndFlag = false;
      } else if (this.modules[i] == "35") {
        this.physioApptFlag = false;
      } else if (this.modules[i] == "35") {
        this.dietApptFlag = false;
      }else if (this.modules[i] == "78") {
        this.activitiesFlag = false;
      } else if (this.modules[i] == "79") {
        this.dietFlag = false;
      } else if (this.modules[i] == "80") {
        this.medFlag = false;
      } else if (this.modules[i] == "81") {
        this.mindfulFlag = false;
      } else if (this.modules[i] == "82") {
        this.healthFlag = false;
      } else if (this.modules[i] == "83") {
        this.postCovidFlag = false;
      } else if (this.modules[i] == "46") {
        this.reference_video = false;
      }else if (this.modules[i] == "84") {
        this.vitalsFlag = false;
      }
    }
  }
  
  openSetting() {
    this.settings = !this.settings;
  }

  logout(name) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'usercontroller/logout/',
      JSON.stringify({
        type: "client",
        user: this.userInfo.user_id
      }),
      { headers: headers })
      .subscribe(
        response => {
          this.toastr.success(Message_data.logOut + name)
          localStorage.clear();
          this.router.navigateByUrl('/loginpage');
        },error => {
          this.toastr.error(Message_data.network);
        });
  }

  menuNav(page) {
    let page_nmae: string = page;
    this.selectedMenu = page;
    this.pharmaSubmenuFlag = false;
    this.schduleSubmenuFlag = false;
    this.apptSubmenuFlag = false;
    this.wellnessSubmenuFlag = false;
    this.ehrSubmenuFlag = false;
    this.settingSubmenuFlag = false;
    var flow;
    Client_Helper.setdietFlow(null);
    switch (page_nmae) {
      case 'dashboard' : this.displayPage = page; break;
      case 'home':
        this.displayPage = "home";
        break;
      case 'relations': this.displayPage = page; break;
      case 'relationdetails': this.displayPage = page; break;
      case 'pharmacy':
        this.pharmaSubmenuFlag = !this.pharmaSubmenuFlag;
        if (this.minimizedAsied == true) {
          this.minimizing();
        }
        break;
      case 'clientpharmacycurrent': this.displayPage = page; break;
      case 'clientpharmacycompleted': this.displayPage = page; break;
      case 'pharmacyorderdetailview': this.displayPage = page; break;
      case 'pharmacyprescriptionrelation': this.displayPage = page; break;
      case 'PharmacyorderComponent': this.displayPage = page; break;
      case 'Pharmacyordercancel': this.displayPage = page; break;
      case 'pharmacyorderlist': this.displayPage = page; break;
      case 'quantitychange': this.displayPage = page; break;
      case 'documentlist': this.displayPage = page; break;
      case 'uploaddocs': this.displayPage = page; break;
      case 'billlist': this.displayPage = page; break;
      case 'clientbilldetailview': this.displayPage = page; break;
      case 'recordwallet': this.displayPage = page; break;
      case 'recordwallet_medpres': this.displayPage = page; break;
      case 'report_details': this.displayPage = page; break;
      case 'secondopinionlist': this.displayPage = page; break;
      case 'appnewsecondopinion': this.displayPage = page; break;
      case 'doctorlist': this.displayPage = page; break;
      case 'secondopinioncreate': this.displayPage = page; break;
      case 'secondopinionretrival': this.displayPage = page; break;
      case 'blood':
        this.schduleSubmenuFlag = !this.schduleSubmenuFlag;
        if (this.minimizedAsied == true) {
          this.minimizing();
        }
        break;
      case 'bloodrequest': this.schduleSubmenuFlag = false; this.displayPage = page; break;
      case 'finddonors': this.schduleSubmenuFlag = false; this.displayPage = page; break;
      case 'donors': this.schduleSubmenuFlag = false; this.displayPage = page; break;
      case 'bloodeligibility': this.schduleSubmenuFlag = false; this.displayPage = page; break;
      case 'DonorUpdateDetailPage': this.displayPage = page; break;
      case 'donordetail': this.displayPage = page; break;
      case 'donationhistory': this.displayPage = page; break;
      case 'notification': this.displayPage = page; break;
      case 'notificationview': this.displayPage = page; break;
      case 'clienthelp': this.displayPage = page; break;
      case 'homecare':this.setHomecare(1);
                      this.apptSubmenuFlag = false;
                      this.hmSubmenuFlag = !this.hmSubmenuFlag;
                      if (this.minimizedAsied == true) {
                        this.minimizing();
                      }
                      break;
      case 'appt':
                    this.apptSubmenuFlag = !this.apptSubmenuFlag;
                    if (this.minimizedAsied == true) {
                      this.minimizing();
                    }
                    break;
      case 'postcovid': this.displayPage = page; break;
      case 'healthdata': this.displayPage = page; break;
      case 'lifestyle':
        this.activitySubmenuFlag = false;
        this.lifestyleSubmenuFlag = !this.lifestyleSubmenuFlag;
        if (this.minimizedAsied == true) {
          this.minimizing();
        };
        break
      case 'medicine': this.lifestyleSubmenuFlag = false; Client_Helper.setTrackerFlag("medicine"); this.displayPage = page; break;
      case 'diet': this.lifestyleSubmenuFlag = false; Client_Helper.setTrackerFlag("diet"); this.displayPage = page; break;
      case 'medicine_tracker': this.lifestyleSubmenuFlag = false; this.displayPage = page; break;
      case 'diet_tracker': 
                          flow = {
                              dietFlow : "dietTracker"
                          }
                          Client_Helper.setdietFlow(flow);
                          this.displayPage = page; break;
      case 'doctor': this.displayPage = page; break;
      case 'doctor_apptdetails': this.displayPage = page; break;
      case 'doctor_appcreate': this.displayPage = page; break;
      case 'diagnosis': this.displayPage = page; break;
      case 'diagnosis_detalied_view': this.displayPage = page; break;
      case 'diagnosis_appcreate': this.displayPage = page; break;
      case 'diagnosis_pres_list': this.displayPage = page; break;
      case 'diagnosis_booking': this.displayPage = page; break;
      case 'physio':
        Client_Helper.setAppFlow("physio");
        this.displayPage = page;break;
      case 'physio_appointment_detailed_view': this.displayPage = page; break;
      case 'physio_appcreate_new': this.displayPage = page; break;
      case 'physio_doctor_list': this.displayPage = page; break;
      case 'physio_doctor_detail': this.displayPage = page; break;
      case 'physio_appointment_conform': this.displayPage = page; break;
      case 'nurse': this.displayPage = page; break;
      case 'client_nurse_appcreate_new': this.displayPage = page; break;
      case 'casesheet': this.displayPage = page; break;
      case 'default': this.setHomecare(0); this.displayPage = page; break;
      case 'doctor_appointment_conform':this.displayPage = page; break;
      case 'exercise':
                        this.wellnessSubmenuFlag = false;this.lifestyleSubmenuFlag =  false;
                        this.activitySubmenuFlag = !this.activitySubmenuFlag;
                        if (this.minimizedAsied == true) {
                          this.minimizing();
                        }
                        break;
      case 'exercise_plan':this.displayPage = page; break;
      case 'exercise_tracker':this.displayPage = page; break;
      case 'mindfulness': this.activitySubmenuFlag = false;this.lifestyleSubmenuFlag = false;this.displayPage = page; break; 
      case 'newDietTrackingHistory':this.displayPage = page; break; 
      case 'activity':this.displayPage = page; break; 
      case 'diet_paln':
                        flow = {
                            dietFlow : "dietPlan"
                        }
                        Client_Helper.setdietFlow(flow);
                        this.displayPage = page; break;     
      case 'wellness':
                      this.wellnessSubmenuFlag = !this.wellnessSubmenuFlag;
                      if (this.minimizedAsied == true) {
                        this.minimizing();
                      }
                      break;
      case 'ehr':
                  this.ehrSubmenuFlag = !this.ehrSubmenuFlag;
                  if (this.minimizedAsied == true) {
                    this.minimizing();
                  }
                  break;
      case 'aboutus':this.displayPage = page; break; 
      case 'vital':this.displayPage = page; break; 
      case 'settings': 
          this.settingSubmenuFlag = !this.settingSubmenuFlag;
          if (this.minimizedAsied == true) {
            this.minimizing();
          }
          break;
      case 'features':this.displayPage = page; break; 
      case 'doctDietTracking':this.displayPage = page; break; 
      case 'packages':this.displayPage = page; break; 
      case 'readings':this.displayPage = page; break;
      case 'dietician':
        this.displayPage = page; 
        break;  
      case 'reference_video':this.displayPage = page; break;  
      case 'dietPlanMenu':this.displayPage = page; break; 
      case 'getDateDiet':this.displayPage = page; break; 
      case 'listRecepies':this.displayPage = page; break; 
      case 'createRecepies':this.displayPage = page; break; 
      
    }
    customjs.SelectMenuFun(this.selectedMenu,"client");
  }

  setHomecare(e) {
    Client_Helper.setHomecare(e);
  }

  setHomecareNursePhysio(homecare, physio_nurse) {
    Client_Helper.setHomecare(homecare);
    Client_Helper.setHomecare_nurse_physio(physio_nurse);
  }

  setSecondOpinion(Secondopinion){
    Client_Helper.setsecondopinion(Secondopinion);
  }

  minimizing() {
    this.minimizedAsied = !this.minimizedAsied;
    if (this.minimizedAsied == true) {
      this.asied_width.nativeElement.setAttribute('style', 'width:3%; transition: all 0.3s ease-in-out;animation-delay: 15s;');
      this.right_width.nativeElement.setAttribute('style', 'width:97%;margin-left: 3%; transition: all 0.3s ease-in-out;animation-delay: 15s;');
      this.logo.nativeElement.setAttribute('style', 'padding: 12px 0 0 3px;}');
      this.pharmaSubmenuFlag = false;
      this.activitySubmenuFlag = false;
       this.hmSubmenuFlag = false;
    } else {
      this.asied_width.nativeElement.setAttribute('style', 'width:17%; transition: all 0.3s ease-in-out;animation-delay: 15s;');
      this.right_width.nativeElement.setAttribute('style', 'width:83%;margin-left: 17%; transition: all 0.3s ease-in-out;animation-delay: 15s;');
      this.logo.nativeElement.setAttribute('style', 'padding: 17px 0 0 14px;}');
    }
    this.apptSubmenuFlag = false;
    this.wellnessSubmenuFlag = false;
  }

  onResize(event) {
    this.asied_width.nativeElement.removeAttribute('style');
    this.right_width.nativeElement.removeAttribute('style');
    this.logo.nativeElement.removeAttribute('style');
    this.minimizedAsied = false;
  }

  openMenumodel() {
    this.minimizedAsied = false;
    customjs.openMobileMenuFun();
  }

  async getRelation(){
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    await this.http.post(ipaddress.getIp + "usercontroller/grel/", JSON.stringify({
      client_reg_id: Helper_Class.getInfo().client,
    }), { headers: headers }).toPromise().then(
      data => { Client_Helper.setrelationlistDiet(data.json());
      });
  }
  
  MenuModelAction(action) {
    if (action == 'profile') {
      this.menuNav('home');
    } else if (action == 'changePassword') {
      const dialogRef = this.dialog.open(ChangePasswordComponent, {
        width: '33%',
        height: '275px',
        data: {type:"client"}
      }); 
    } else {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'usercontroller/logout/',
        JSON.stringify({
          type: "client",
          user: this.userInfo.user_id
        }),
        { headers: headers })
        .subscribe(
          response => {
            this.toastr.success(Message_data.logOut)
            localStorage.clear();
            this.router.navigateByUrl('/loginpage');
          },error => {
            this.toastr.error(Message_data.network);
          });
    }
    $('#exampleModal').modal('hide');
  }
}
