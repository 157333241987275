import { Component, OnInit, HostListener, Input } from '@angular/core';
import {Helper_Class} from '../../helper_class';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
// import {ipaddress.getIp,Ip_with_img_address,ipaddress.country_code} from '../../../assets/js/ipaddress.js';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import * as $ from 'jquery/dist/jquery.min.js';
import { FormControl } from '@angular/forms';
import { Observable, observable, Subscription } from 'rxjs';
import { Date_Formate,Time_Formate } from '../../../assets/js/common.js';
import { CasesheetService } from '../casesheet/casesheet.service';
import { Doc_Helper } from '../Doc_Helper';
import { DropdownModelpageComponent } from '../dropdown-modelpage/dropdown-modelpage.component';
import { MatDialog } from '@angular/material/dialog';
import { ipaddress } from '../../ipaddress' ;import { masterCSData_Helper } from '../casesheet/masterCSData_Helper';
import { MasterCSHelperService } from '../casesheet/masterCSData.service';
import { Master_Helper } from '../Master_Helper';
import { Message_data } from 'src/assets/js/Message_data';

@Component({
  selector: 'app-obstetric-history',
  templateUrl: './obstetric-history.component.html',
  styleUrls: ['./obstetric-history.component.css']
})
export class ObstetricHistoryComponent implements OnInit {
  public obsHistoryFlag ="min";
  subscription:Subscription;
  public minflag:boolean=true;
  public currentDate;
  public gest_age_array = [];
  public Gest_age;
  public Mode_del_array = [];
  public Modeof_delivery;
  public complication_array = [];
  public complication_delivery;
  public complication_post_array = [];
  public complication_postpartum;
  public reason_array = [];
  public obstestric_historylist = [];
  public send_del_comp_array = [];
  public send_comp_partum_array = [];
  public del_com_array=[];
  public com_post_array=[];
  public gravida;
  public date_ofdelivery;
  public onset_lab;
  public baby_viability;
  public obs_gender;
  public baby_weight;
  public baby_weightmeausre;
  public breast_feeding;
  public baby_abnormalities;
  public antenatal_prblm;
  public reason_csection;
  public delivery_location;
  public baby_height;
  public baby_height_measure;
  public reason_data;
  public delivery_location_txt;
  public baby_height_txt;
  public gest_id;
  public mode_del_id;
  public comp_id;
  public comppostpart_id;
  public baby_weight1;
  public year_data;
  public obs_send_arry = [];
  public obs_hisflg;
  public del_comp_hist;
  public comp_partum_hist;

  public spl_addbtn: boolean;
  public norma_addbtn: boolean;
  public gestage_disabled: boolean;
  public onesetla_disabled: boolean;
  public modeof_disabled: boolean;
  public complidel_disabled: boolean;
  public complpostpar_disabled: boolean;
  public babyvia_disabled: boolean;
  public babygend_disabled: boolean;
  public babywei_disabled: boolean;

  public brefed_disabled: boolean;
  public bababnor_disabled: boolean;
  public ante_disabled: boolean;
  public reasonc_disabled: boolean;
  public birtloc_disabled: boolean;
  public birtheig_disabled: boolean;
  public datedel_hidden: boolean;
  public gestation_hidden: boolean;
  public onset_labhidden: boolean;
  public modedel_hidden: boolean;
  public complicate_hidden: boolean;
  public complicateduring_hidden: boolean;
  public baby_viahidden: boolean;
  public baby_gendhidden: boolean;
  public baby_weighthidden: boolean;
  public breastfeed_hidden: boolean;
  public babyabnormal_hidden: boolean;
  public antenatal_hidden: boolean;
  public reason_hidden: boolean;
  public birthlocation_hidden: boolean;
  public birthheight_hidden: boolean;
  public static Provider_or_Client: string;
  public obestric_client: boolean;
  public header_footer_flag: boolean;

  public duringdelivery_flag:boolean=false;
  public postpartum_flag:boolean=false; 
  public del_value_array=[];
  public postpartum_array=[];

  public create_ddate:boolean;

  public comp_postpartum_flag:boolean = true;

  public client:boolean = false;
  public padd_birth;
  public padd_others;

  constructor(public dialog:MatDialog,private http:Http,private routes:ActivatedRoute,private router:Router,public toastr:ToastrService,
    public messageservice:CasesheetService,public masterCSdata:MasterCSHelperService) { 
    this.minflag=true;
    this.spl_addbtn = true;
    this.datedel_hidden = true;
    this.gestation_hidden = true;
    this.onset_labhidden = true;
    this.modedel_hidden = true;
    this.complicate_hidden = true;
    this.complicateduring_hidden = true;
    this.baby_viahidden = true;
    this.baby_gendhidden = true;
    this.baby_weighthidden = true;
    this.breastfeed_hidden = true;
    this.babyabnormal_hidden = true;
    this.antenatal_hidden = true;
    this.reason_hidden = true;
    this.birthlocation_hidden = true;
    this.birthheight_hidden = true;

    this.baby_weightmeausre="kgs";
    this.baby_height_measure="cms";
    this.baby_viability="Live birth";
    this.baby_abnormalities = "None";
    this.antenatal_prblm = "None";
    this.padd_birth = "33px";
    this.padd_others = "0";
    this.getCurrentDate();
  }

  async ngOnInit() {
    this.subscription = this.messageservice.getCasesheetMessage().subscribe(message => {
      if (message) {
          this.obsHistoryFlag = message;
      }
    });
    if(Doc_Helper.getAppFlow() == "client" ){
      this.client = true;
    }else{
      this.client = false;
    }

    var getHopitalfieldsRet=Helper_Class.getHopitalfieldsRet();
    if (getHopitalfieldsRet != undefined) {
      for (var i = 0; i < getHopitalfieldsRet.length; i++) {
        if (getHopitalfieldsRet[i].page_name == "Obstetric history") {
          if (getHopitalfieldsRet[i].fields != undefined) {
            for (var j = 0; j < getHopitalfieldsRet[i].fields.length; j++) {
              if (getHopitalfieldsRet[i].fields[j].field_name == "onset of labour") {
                this.onset_labhidden = false;
              }

              if (getHopitalfieldsRet[i].fields[j].field_name == "Baby's viability") {
                this.baby_viahidden = false;
              }

              if (getHopitalfieldsRet[i].fields[j].field_name == "Mode of delivery") {
                this.modedel_hidden = false;
              }

              if (getHopitalfieldsRet[i].fields[j].field_name == "Baby's gender") {
                this.baby_gendhidden = false;
              }

              if (getHopitalfieldsRet[i].fields[j].field_name == "Baby's weight") {
                this.baby_weighthidden = false;
              }

              if (getHopitalfieldsRet[i].fields[j].field_name == "Birth height") {
                this.birthheight_hidden = false;
              }

              if (getHopitalfieldsRet[i].fields[j].field_name == "Date of delivery") {
                this.datedel_hidden = false;
              }

              if (getHopitalfieldsRet[i].fields[j].field_name == "Gestational age") {
                this.gestation_hidden = false;
              }

              if (getHopitalfieldsRet[i].fields[j].field_name == "Complication during delivery") {
                this.complicate_hidden = false;
              }

              if (getHopitalfieldsRet[i].fields[j].field_name == "Complication during postpartum") {
                this.complicateduring_hidden = false;
              }

              if (getHopitalfieldsRet[i].fields[j].field_name == "Breasts feeding") {
                this.breastfeed_hidden = false;
              }

              if (getHopitalfieldsRet[i].fields[j].field_name == "Baby's abnormalities") {
                this.babyabnormal_hidden = false;
              }

              if (getHopitalfieldsRet[i].fields[j].field_name == "Antental problems") {
                this.antenatal_hidden = false;
              }

              if (getHopitalfieldsRet[i].fields[j].field_name == "Birth location") {
                this.birthlocation_hidden = false;
              }
            }
          }
        }
      }
    }

    if(masterCSData_Helper.getMasterGestationstage() == undefined){
      await this.masterCSdata.getGestationage();
      this.Get_gestationage();
    }else{
      this.Get_gestationage();
    }
    if(masterCSData_Helper.getMasterModeofdelivery() == undefined){
      await this.masterCSdata.getModeofdelivery();
      this.Get_modeofdelivery();
    }else{
      this.Get_modeofdelivery();
    }
    if(masterCSData_Helper.getMasterReasonCsection() == undefined){
      await this.masterCSdata.getReasonCsection();
      this.Get_reason_csection();
    }else{
      this.Get_reason_csection();
    }
    if(masterCSData_Helper.getMasterComplicationDelivery() == undefined){
      await this.masterCSdata.getComplicationDelivery();
      this.Get_complication_delivery();
    }else{
      this.Get_complication_delivery();
    }
    if(masterCSData_Helper.getMasterComplicationPostpartum() == undefined){
      await this.masterCSdata.getComplicationPostpartum();
      this.Get_complication_postpartum();
    }else{
      this.Get_complication_postpartum();
    }

    this.complication_postpartum = "None";

    this.Gest_age = "Full term";
    this.onset_lab = "None";
    this.Modeof_delivery = "Normal";

    this.baby_weightmeausre = "kgs";
    this.breast_feeding = "Yes";
    this.breast_feeding = "Yes";
    this.obestric_retrieval();
  }

  getCurrentDate(){
    var obj = Master_Helper.getMasterCurrentDate();
    
    if (obj.current_date != null) {
      this.currentDate = obj.current_date;
    }
   }

   @HostListener('window:click', ['$event'])
  onclick(event: any): void {
    this.send_data();
  }

  @HostListener('window:blur', ['$event'])
  onBlur(event: any): void {
    this.send_data();
  }
  
  obestric_retrieval() {
    var obstric_hist;
    this.obstestric_historylist = [];
    var getgynaRet =  Helper_Class.getgynaRet();
    if (getgynaRet != undefined) {
      
      if (getgynaRet.obstestric_history != undefined && getgynaRet.obstestric_history.length != 0) {
        for (var i = 0; i < getgynaRet.obstestric_history.length; i++) {
          var height_measure;
          if (getgynaRet.obstestric_history[i].baby_height_measure != undefined) {
            height_measure = getgynaRet.obstestric_history[i].baby_height_measure

          } else {
            height_measure = "cms";
          }

          if(getgynaRet.obstestric_history[i].delivery_year !=undefined){
            var dod =Date_Formate(getgynaRet.obstestric_history[i].delivery_year)
          }

          this.obstestric_historylist.push({
            delivery_year: dod,
            gestational_age: getgynaRet.obstestric_history[i].gestational_age,
            gest_age_desc: getgynaRet.obstestric_history[i].gest_age_desc,
            obs_del_mode_desc: getgynaRet.obstestric_history[i].obs_del_mode_desc,
            gender: getgynaRet.obstestric_history[i].baby_gender,
            weight: getgynaRet.obstestric_history[i].baby_weight,
            baby_viab: getgynaRet.obstestric_history[i].baby_viability,
            baby_abnorm: getgynaRet.obstestric_history[i].baby_abnormalities,
            reason_c_section: getgynaRet.obstestric_history[i].reason_c_section,
            delivery_location: getgynaRet.obstestric_history[i].delivery_location,
            baby_height: getgynaRet.obstestric_history[i].baby_height,
            baby_height_measure: height_measure,
            antenatal_problem: getgynaRet.obstestric_history[i].antenatal_problem,
            delivery_mode: getgynaRet.obstestric_history[i].delivery_mode,
            labour_onset: getgynaRet.obstestric_history[i].labour_onset,
            delivery_comp: getgynaRet.obstestric_history[i].delivery_comp,
            postpartum_comp: getgynaRet.obstestric_history[i].postpartum_comp,
            breast_feeding: getgynaRet.obstestric_history[i].breast_feeding,
            new_data: "0",
          });
          
        if (this.obstestric_historylist.length != 0) {
          for (var i = 0; i < this.obstestric_historylist.length; i++) {
              if (this.obstestric_historylist[i].new_data != "0") {
                  this.obs_send_arry = this.obstestric_historylist;
              }
          }
          this.obs_hisflg = "yes";
          obstric_hist = {
            obstestric_history: this.obs_send_arry,
            obs_hist_flag: this.obs_hisflg
              }
          } else {
            obstric_hist = {
              obstestric_history: this.obs_send_arry,
                }
  
  
          }
            Helper_Class.setobestric(obstric_hist);
        }
      }
    }
  }

  obstric_select(delivery, agedesc, gender, weight) {
    for (var i = 0; i < this.obstestric_historylist.length; i++) {
      if (this.obstestric_historylist[i].delivery_year == delivery && this.obstestric_historylist[i].gest_age_desc == agedesc && this.obstestric_historylist[i].gender == gender && this.obstestric_historylist[i].weight == weight) {
        if(this.obstestric_historylist[i].delivery_year != undefined){
          this.date_ofdelivery = Date_Formate(this.obstestric_historylist[i].delivery_year);
        }

        for (var j = 0; j < this.gest_age_array.length; j++) {
          if (this.gest_age_array[j].gestation_age_id == this.obstestric_historylist[i].gestational_age) {
            this.Gest_age = this.gest_age_array[j].description;
          }
        }
        this.onset_lab = this.obstestric_historylist[i].labour_onset;

        for (var j = 0; j < this.Mode_del_array.length; j++) {
          if (this.Mode_del_array[j].labour_id == this.obstestric_historylist[i].delivery_mode) {
            this.Modeof_delivery = this.Mode_del_array[j].description;
          }
        }

        for (var j = 0; j < this.complication_array.length; j++) {
          if (this.complication_array[j].delivery_comp_id == this.obstestric_historylist[i].delivery_comp) {
            this.complication_delivery = this.complication_array[j].description;
          }
        }

        for (var j = 0; j < this.complication_post_array.length; j++) {
          if (this.complication_post_array[j].postpart_comp_id == this.obstestric_historylist[i].postpartum_comp) {
            this.complication_postpartum = this.complication_post_array[j].description;
          }
        }

        this.baby_viability = this.obstestric_historylist[i].baby_viab;
        this.obs_gender = this.obstestric_historylist[i].gender;
        var babaywe = this.obstestric_historylist[i].weight.split('.');
        
        this.baby_weight = babaywe[0];
        var babaywe_dataagain = babaywe[1].split(' ');;
        
        this.baby_weight1 = babaywe_dataagain[0];
        this.baby_weightmeausre = babaywe_dataagain[1];

        this.breast_feeding = this.obstestric_historylist[i].breast_feeding;
        this.baby_abnormalities = this.obstestric_historylist[i].baby_abnorm;
        this.antenatal_prblm = this.obstestric_historylist[i].antenatal_problem;

        for (var j = 0; j < this.reason_array.length; j++) {
          if (this.reason_array[j].csec_reason_id == this.obstestric_historylist[i].reason_c_section) {
            this.reason_csection = this.reason_array[j].description;
          }
        }
        this.delivery_location = this.obstestric_historylist[i].delivery_location;
        this.baby_height = this.obstestric_historylist[i].baby_height;
        this.baby_height_measure = this.obstestric_historylist[i].height_measure;
        this.gravida = this.obstestric_historylist[i].gravida;
        this.obstestric_historylist.splice(i, 1);
      }
    }

  }

  obstric_view(delivery, agedesc, gender, weight) {
    this.norma_addbtn = true;
    this.spl_addbtn = false;
    for (var i = 0; i < this.obstestric_historylist.length; i++) {
      if (this.obstestric_historylist[i].delivery_year == delivery && this.obstestric_historylist[i].gest_age_desc == agedesc && this.obstestric_historylist[i].gender == gender && this.obstestric_historylist[i].weight == weight) {
        this.date_ofdelivery = this.obstestric_historylist[i].delivery_year;

        for (var j = 0; j < this.gest_age_array.length; j++) {
          if (this.gest_age_array[j].gestation_age_id == this.obstestric_historylist[i].gestational_age) {
            this.Gest_age = this.gest_age_array[j].description;
          }
        }
        this.onset_lab = this.obstestric_historylist[i].labour_onset;

        for (var j = 0; j < this.Mode_del_array.length; j++) {
          if (this.Mode_del_array[j].labour_id == this.obstestric_historylist[i].delivery_mode) {
            this.Modeof_delivery = this.Mode_del_array[j].description;
          }
        }

        for (var k = 0; k < this.complication_array.length; k++) {
          this.del_com_array.push({
              type: 'checkbox',
              // multiple:true,
              value: this.complication_array[k].delivery_comp_id,
              label: this.complication_array[k].description,
              checked: false
          });
        }

        var delcomparray = this.obstestric_historylist[i].delivery_comp;
        this.del_comp_change(delcomparray);

        // for (var j = 0; j < this.complication_array.length; j++) {
        //   if (this.complication_array[j].delivery_comp_id == this.obstestric_historylist[i].delivery_comp) {
        //     this.complication_delivery = this.complication_array[j].description;
        //   }
        // }
        for (var l = 0; l < this.complication_post_array.length; l++) {
          this.com_post_array.push({
              type: 'checkbox',
              // multiple:true,
              value: this.complication_post_array[l].postpart_comp_id,
              label: this.complication_post_array[l].description,
              checked: false
          });
        }
        var partumcomparray = this.obstestric_historylist[i].postpartum_comp;
        this.comp_postpartum_change(partumcomparray);
        // for (var j = 0; j < this.complication_post_array.length; j++) {
        //   if (this.complication_post_array[j].postpart_comp_id == this.obstestric_historylist[i].postpartum_comp) {
        //     this.complication_postpartum = this.complication_post_array[j].description;
        //   }
        // }

        this.baby_viability = this.obstestric_historylist[i].baby_viab;
        this.obs_gender = this.obstestric_historylist[i].gender;
        var babaywe = this.obstestric_historylist[i].weight.split('.');
        this.baby_weight = babaywe[0];
        var babaywe_dataagain = babaywe[1].split(' ');;
        this.baby_weight1 = babaywe_dataagain[0];
        this.baby_weightmeausre = babaywe_dataagain[1];
        this.breast_feeding = this.obstestric_historylist[i].breast_feeding;
        this.baby_abnormalities = this.obstestric_historylist[i].baby_abnorm;
        this.antenatal_prblm = this.obstestric_historylist[i].antenatal_problem;
        this.gravida = this.obstestric_historylist[i].gravida;

        for (var j = 0; j < this.reason_array.length; j++) {
          if (this.reason_array[j].csec_reason_id == this.obstestric_historylist[i].reason_c_section) {
            this.reason_csection = this.reason_array[j].description;
          }
        }

        this.delivery_location = this.obstestric_historylist[i].delivery_location;
        this.baby_height = this.obstestric_historylist[i].baby_height;
        this.baby_height_measure = this.obstestric_historylist[i].baby_height_measure;
        this.gestage_disabled = true;
        this.onesetla_disabled = true;
        this.modeof_disabled = true;
        this.complidel_disabled = true;
        this.complpostpar_disabled = true;
        this.babyvia_disabled = true;
        this.babygend_disabled = true;
        this.babywei_disabled = true;
        this.brefed_disabled = true;
        this.bababnor_disabled = true;
        this.ante_disabled = true;
        this.reasonc_disabled = true;
        this.birtloc_disabled = true;
        this.birtheig_disabled = true;
      }
    }
  }

  comp_postpartum() {
    this.comp_postpartum_flag = !this.comp_postpartum_flag;
    this.com_post_array=[]
    //this.complicateduring_hidden=true;
    this.postpartum_flag=true;
    for (var i = 0; i < this.complication_post_array.length; i++) {
      this.com_post_array.push({
          type: 'checkbox',
          // multiple:true,
          value: this.complication_post_array[i].postpart_comp_id,
          label: this.complication_post_array[i].description,
          selected: false
      });
    }
   
    for (var j = 0; j < this.com_post_array.length; j++) {
      this.com_post_array[j].checked = false;
    }

    if (this.send_comp_partum_array != undefined && this.send_comp_partum_array.length != 0) {
      for (var i = 0; i < this.send_comp_partum_array.length; i++) {
        for (var j = 0; j < this.com_post_array.length; j++) {
          if (this.send_comp_partum_array[i] == this.com_post_array[j].value) {
            this.com_post_array[j].checked = true;
          }

        }
      }
      
    }
    
    const dialogRef = this.dialog.open(DropdownModelpageComponent, {
      width: '500px',
      height: '400px',
      data:{
      title: "Postportum Complications",
      main_array: this.com_post_array,
      selected_list:this.send_comp_partum_array,
  }
  });

  dialogRef.afterClosed().subscribe(result => {
      if(result != undefined){
      this.comp_postpartum_change(result);
      }
  });
  }

  clospostpart_popup(){
    this.comp_postpartum_flag = !this.comp_postpartum_flag;
  }

  postpartum_click(data,id,e){
      if(e == true){
        this.postpartum_array.push(id)
      }
      else{
        if(this.postpartum_array.length != 0){
          for( var k=0; k<this.postpartum_array.length; k++){
            if(id == this.postpartum_array[k])
            {
              this.postpartum_array.splice(k);
            }
          }
        }
      }
      this.comp_postpartum_change(this.postpartum_array) 
  }

  comp_postpartum_change(selected) {
    this.comp_partum_hist = "";

    function StringBuilder(this:any,value) {
      this.strings = new Array("");
        this.append(value);
    }

    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
        if (value) {
            this.strings.push(value);
        }
    }

    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
        this.strings.length = 1;
    }

    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
        return this.strings.join("");
    }

    var selected_data = new StringBuilder("");
    this.send_comp_partum_array = selected;
    
    if (this.send_comp_partum_array.length != 0) {
        var n = 0;
        for (var j = 0; j < this.send_comp_partum_array.length; j++) {
            for (var i = 0; i < this.com_post_array.length; i++) {
                if (this.send_comp_partum_array[j] == this.com_post_array[i].value) {
                    n = n + 1;
                    if (n > 1) {
                        selected_data.append(",");
                        selected_data.append("\n");
                    }
                    selected_data.append(this.com_post_array[i].label);
                }
            }
        }      
        
        this.comp_partum_hist = selected_data.toString();
    }
  }
  
  del_comp_popup() {
    this.duringdelivery_flag=true;
    for (var j = 0; j < this.del_com_array.length; j++) {
        this.del_com_array[j].checked = false;
    }
    
    if (this.send_del_comp_array != undefined && this.send_del_comp_array.length != 0) {
        for (var i = 0; i < this.send_del_comp_array.length; i++) {
            for (var j = 0; j < this.del_com_array.length; j++) {
                if (this.send_del_comp_array[i] == this.del_com_array[j].value) {
                    this.del_com_array[j].checked = true;
                }
            }
        }
    }
    const dialogRef = this.dialog.open(DropdownModelpageComponent, {
      width: '500px',
      height: '400px',
      data:{
      title: "Complications During Delivery",
      main_array: this.del_com_array,
      selected_list:this.send_del_comp_array,
  }
  });

  dialogRef.afterClosed().subscribe(result => {
      if(result != undefined){
      this.del_comp_change(result);
      }
  });
  }

  duringdel_array_click(data,id,e){
    if(e == true){
      this.del_value_array.push(id)
    }
    else{
      if(this.del_value_array.length != 0){
        for( var k=0; k<this.del_value_array.length; k++){
          if(id == this.del_value_array[k])
          {
            this.del_value_array.splice(k);
          }
        }
      }
    }
    this.del_comp_change(this.del_value_array)
  }

  closedel_popup(){
    this.duringdelivery_flag=false;
  }

  del_comp_change(selected) {
    this.del_comp_hist = "";
    function StringBuilder(this:any,value) {
        this.strings = new Array("");
        this.append(value);
    }

    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
        if (value) {
            this.strings.push(value);
        }
    }

    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
        this.strings.length = 1;
    }

    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
        return this.strings.join("");
    }

    var selected_data = new StringBuilder("");
    this.send_del_comp_array = selected;

    if (this.send_del_comp_array.length != 0) {
        var n = 0;

        for (var j = 0; j < this.send_del_comp_array.length; j++) {
            for (var i = 0; i < this.del_com_array.length; i++) {
                if (this.send_del_comp_array[j] == this.del_com_array[i].value) {
                    n = n + 1;
                    if (n > 1) {
                        selected_data.append(",");
                        selected_data.append("\n");
                    }
                    selected_data.append(this.del_com_array[i].label);
                }
            }
        }
        this.del_comp_hist = selected_data.toString();
    }
  }

  //send data to server
  send_data() {
    var obstric_hist = null;
    if (this.obstestric_historylist.length != 0) {
      for (var i = 0; i < this.obstestric_historylist.length; i++) {
        this.obs_send_arry = this.obstestric_historylist;
      }
      this.obs_hisflg = "yes"
      obstric_hist = {
        obstestric_history: this.obs_send_arry,
        obs_hist_flag: this.obs_hisflg
      }
  
    } else {
      obstric_hist = {
        obstestric_history: this.obs_send_arry,
      }
      }

    Helper_Class.setobestric(obstric_hist);
  }

  //view for diabled
  obstertcspl_addclick() {
    this.norma_addbtn = false;
    this.spl_addbtn = true;
    this.gestage_disabled = false;
    this.onesetla_disabled = false;
    this.modeof_disabled = false;
    this.complidel_disabled = false;
    this.complpostpar_disabled = false;
    this.babyvia_disabled = false;
    this.babygend_disabled = false;
    this.babywei_disabled = false;

    this.brefed_disabled = false;
    this.bababnor_disabled = false;
    this.ante_disabled = false;
    this.reasonc_disabled = false;
    this.birtloc_disabled = false;
    this.birtheig_disabled = false;

    this.date_ofdelivery = "";
    this.Gest_age = this.gest_age_array[0].description;
    this.onset_lab = "None";
    this.Modeof_delivery = this.Mode_del_array[0].description;
    this.complication_delivery = this.send_del_comp_array;
    this.complication_postpartum = this.send_comp_partum_array;
    this.baby_viability = "";
    this.obs_gender = "";
    this.baby_weight = "";
    this.baby_weight1 = "";
    this.breast_feeding = "Yes";
    this.baby_abnormalities = "None";
    this.antenatal_prblm = "None";
    this.reason_csection = this.reason_array[0].description;
    // this.delivery_location = undefined;
    this.delivery_location = "";
    this.baby_height = "";
  }

  //obestric add
  Obestric_addclick() {
    var flag = true;
    var dod
    if (this.date_ofdelivery != undefined) {
      dod = Date_Formate(this.date_ofdelivery)
    }
    else{
      flag = false;
    }
    if (this.gravida == undefined) {
      flag = false;
    }

    if (this.obs_gender == undefined) {
      flag = false;
    }

    if (this.baby_weight == undefined) {
      flag = false;
    }

    if (this.baby_weight1 == undefined) {
      flag = false;
    }

    if (this.delivery_location == undefined) {
      flag = false;
    }

    if (this.baby_height == undefined) {
      flag = false;
    }

    if (this.Modeof_delivery == undefined) {
      if (this.Modeof_delivery == "C-section") {
        if (this.reason_csection == undefined) {
          flag = false;
        }
      }
    }

    if (flag == false) {
      this.toastr.error(Message_data.mandatory);
    }

    if (flag == true) {
      for (var i = 0; i < this.obstestric_historylist.length; i++) {
        if (this.obstestric_historylist[i].delivery_year == this.date_ofdelivery 
          && this.obstestric_historylist[i].gest_age_desc == this.Gest_age 
          && this.obstestric_historylist[i].labour_onset == this.onset_lab 
          && this.obstestric_historylist[i].delivery_mode == this.mode_del_id 
          && this.obstestric_historylist[i].delivery_comp == this.send_del_comp_array 
          && this.obstestric_historylist[i].postpartum_comp == this.comppostpart_id) {
          flag = false;

          this.date_ofdelivery = undefined;
          this.Gest_age = this.gest_age_array[0].description;
          this.onset_lab = "None";
          this.Modeof_delivery = this.Mode_del_array[0].description;
          this.complication_delivery = this.send_del_comp_array;
          this.complication_postpartum = this.send_comp_partum_array;
          this.baby_viability = undefined;
          this.obs_gender = undefined;
          this.baby_weight = undefined;
          this.baby_weightmeausre = undefined;
          this.breast_feeding = "Yes";
          this.baby_abnormalities = "None";
          this.antenatal_prblm = "None";
          this.reason_csection = this.reason_array[0].description;
          this.delivery_location = undefined;
          this.baby_height = undefined;

        }
      }
    }

    if (flag == true) {
      if (this.reason_csection != undefined) {
        for (var i = 0; i < this.reason_array.length; i++) {
          if (this.reason_csection == this.reason_array[i].description) {
            this.reason_data = this.reason_array[i].csec_reason_id;
          }
        }

      } else {
        this.delivery_location_txt = "0";
      }

      if (this.delivery_location != undefined) {
        this.delivery_location_txt = this.delivery_location;

      } else {
        this.delivery_location_txt = "";
      }

      if (this.baby_height != undefined) {
        this.baby_height_txt = this.baby_height;

      } else {
        this.baby_height_txt = "";
      }

      if (this.Gest_age != undefined) {
        for (var i = 0; i < this.gest_age_array.length; i++) {
          if (this.Gest_age == this.gest_age_array[i].description) {
            this.gest_id = this.gest_age_array[i].gestation_age_id;
          }
        }
      }

      if (this.Modeof_delivery != undefined) {
        for (var i = 0; i < this.Mode_del_array.length; i++) {
          if (this.Modeof_delivery == this.Mode_del_array[i].description) {
            this.mode_del_id = this.Mode_del_array[i].labour_id;
          }
        }
      }
      
      // if (this.complication_delivery != undefined) {
      //   for (var i = 0; i < this.complication_array.length; i++) {
      //     if (this.complication_delivery == this.complication_array[i].description) {
      //       this.comp_id = this.complication_array[i].delivery_comp_id;
      //     }
      //   }
      // }

      // if (this.complication_postpartum != undefined) {
      //   for (var i = 0; i < this.complication_post_array.length; i++) {
      //     if (this.complication_postpartum == this.complication_post_array[i].description) {
      //       this.comppostpart_id = this.complication_post_array[i].postpart_comp_id;
      //     }
      //   }
      // }

      this.obstestric_historylist.push({
        gravida:this.gravida,
        delivery_year: dod,
        gest_age_desc: this.Gest_age,
        gestational_age: this.gest_id,
        antenatal_problem: this.antenatal_prblm,
        labour_onset: this.onset_lab,
        obs_del_mode_desc: this.Modeof_delivery,
        delivery_mode: this.mode_del_id,
        delivery_comp: this.send_del_comp_array,
        postpartum_comp: this.send_comp_partum_array,
        gender: this.obs_gender,
        weight: this.baby_weight + "." + this.baby_weight1 + " " + this.baby_weightmeausre,
        baby_viab: this.baby_viability,
        baby_abnorm: this.baby_abnormalities,
        breast_feeding: this.breast_feeding,
        reason_c_section: this.reason_data,
        delivery_location: this.delivery_location_txt,
        baby_height: this.baby_height_txt,
        height_measure: this.baby_height_measure,
        new_data: "1",
      });

      this.gravida="";
      this.date_ofdelivery = undefined;
      this.Gest_age = this.gest_age_array[0].description;
      this.onset_lab = "None";
      this.Modeof_delivery = this.Mode_del_array[0].description;
      this.complication_delivery = this.send_del_comp_array;
      this.complication_postpartum = this.send_comp_partum_array;
      this.baby_viability = "";
      this.obs_gender = "";
      this.baby_weight = "";
      this.baby_weight1 = "";
      this.breast_feeding = "Yes";
      this.baby_abnormalities = "None";
      this.antenatal_prblm = "None";
      this.reason_csection = this.reason_array[0].description;
      this.delivery_location = "";
      this.baby_height = "";
    }
    this.send_data();
  }

  //gestation age
  Get_gestationage() {
    var obj = masterCSData_Helper.getMasterGestationstage();
    if (obj != undefined) {
      for (var i = 0; i < obj.gestation_ages.length; i++) {
        this.gest_age_array = obj.gestation_ages;
        this.Gest_age = this.gest_age_array[0].description;
      }
    }
  }

  //Mode of delivery
  Get_modeofdelivery() {
    var obj = masterCSData_Helper.getMasterModeofdelivery();
    if (obj != undefined) {
      for (var i = 0; i < obj.labour_types.length; i++) {
        this.Mode_del_array = obj.labour_types;
        this.Modeof_delivery = this.Mode_del_array[0].description;
      }
    }
  }

  //Complication during delivery
  Get_complication_delivery() {
    var obj = masterCSData_Helper.getMasterComplicationDelivery();
    if (obj != undefined) {
      for (var i = 0; i < obj.delivery_comps.length; i++) {
        this.complication_array = obj.delivery_comps;
        this.complication_delivery = this.complication_array[0].description;
      }
      for (var i = 0; i < this.complication_array.length; i++) {
        this.del_com_array.push({
            type: 'checkbox',
            // multiple:true,
            value: this.complication_array[i].delivery_comp_id,
            label: this.complication_array[i].description,
            checked: false
        });
      }
    }
  }

  //Back page
  backClicked() {
    // var modalPage = this.modalCtrl.create("LoadingPage", {
    //   "applist": this.navParams.get("applist"),
    //   "diabhist": "back"
    // });
    // modalPage.present();
  }

  //Complication during delivery
  Get_complication_postpartum() {
    var obj = masterCSData_Helper.getMasterComplicationPostpartum();
    if (obj != undefined) {
      for (var i = 0; i < obj.postpart_comps.length; i++) {
        this.complication_post_array = obj.postpart_comps;
        this.complication_postpartum = this.complication_post_array[0].description;
      }
    }
  }

  //Reason for c section
  Get_reason_csection() {
    var obj = masterCSData_Helper.getMasterReasonCsection();
    if (obj != undefined) {
      for (var i = 0; i < obj.csec_reasons.length; i++) {
        this.reason_array = obj.csec_reasons;
        this.reason_csection = this.reason_array[0].description;
      }
    }
  }

  date_delpop(e){
    this.date_ofdelivery = e;
  }

  Gender(e){
    if(e != "select"){
      document.getElementById("ohgender").style.borderColor = "#2ca8fe";
    }else{
      document.getElementById("ohgender").style.borderColor = "rgb(250, 40, 2)";
    }
  }

  modeOfDelivery(e){
    if(e == "C-section"){
      this.reason_hidden = false;
      this.padd_birth = "0";
      this.padd_others = "22px";
    }else{
      this.reason_hidden = true;
      this.padd_birth = "33px";
      this.padd_others = "0";
    }

  }
}
