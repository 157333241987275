import { Component, OnInit ,ElementRef,Input,Inject,ViewEncapsulation,HostListener, ViewChild} from '@angular/core';
import { Http, Headers } from '@angular/http';
// import { getIp, Ip_with_img_address, country_code } from '../../../assets/js/ipaddress';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
// import { PreviousprescriptionComponent } from '../previousprescription/previousprescription.component';
import {MatSort} from '@angular/material/sort';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from '../../helper_class';
@Component({
  selector: 'app-diagnosis-imagepopup',
  templateUrl: './diagnosis-imagepopup.component.html',
  styleUrls: ['./diagnosis-imagepopup.component.scss']
})
export class DiagnosisImagepopupComponent implements OnInit {
  public check;
  constructor(public http:Http,public dialogRef: MatDialogRef<DiagnosisImagepopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public el: ElementRef, public toastr:ToastrService) { 
      this.check=data.image;
    }

  ngOnInit() {
  }
  ClosePopup(){
  //  this.mod
  this.dialogRef.close();
  }

}
