<div *ngIf="obsHistoryFlag == 'min'">
    <div class="row">
        <p><strong>Obstetrics History</strong></p>
        <div class="col-12 ">
            <mat-label class="matlabel">Gravida<br>
                <input matInput class="ipcss " required (readonly)="true" [(ngModel)]="gravida" maxlength="2">
            </mat-label>
        </div>
        <div [hidden]="gestation_hidden" class="col-12">
            <mat-label class="matlabel" [hidden]="gestation_hidden">Gestational Age<br>
                <select class="ipcss " [disabled]="gestage_disabled" [(ngModel)]="Gest_age" disableOptionCentering>
                    <option *ngFor="let gest of gest_age_array" value="{{gest.description}}">{{gest.description}}
                    </option>
                </select>
            </mat-label>
        </div>
        <div [hidden]="onset_labhidden" class="col-12 ">
            <mat-label class="matlabel" [hidden]="onset_labhidden">Onset Of Labour<br>
                <select class="ipcss " [(ngModel)]="onset_lab" [disabled]="onesetla_disabled" disableOptionCentering>
                    <option value="None">None</option>
                    <option value="Spontaneous">Spontaneous</option>
                    <option value="Induced">Induced</option>
                </select>
            </mat-label>
        </div>
    </div>
</div>

<div class="Details" *ngIf="obsHistoryFlag == 'max'">
    <div class="row">
        <div class="col-6">
            <div class="cover_div" [ngStyle]="{'padding-bottom':padd_birth}">
                <div class="header_lable">
                    During birth
                </div>
                <div class="content_cover">
                    <div class="row">
                        <div [hidden]="datedel_hidden" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <mat-label class="matlabel" [hidden]="datedel_hidden">Date Of Delivery<br>
                                <input type="date" class="ipcss_date " id="appt_date" required (blur)="send_data()"
                                    (change)="date_delpop(date_ofdelivery)" [(ngModel)]="date_ofdelivery"
                                    max="{{currentDate}}" [disabled]="client" matInput>
                            </mat-label>
                        </div>
                        <div [hidden]="modedel_hidden" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <mat-label class="matlabel" [hidden]="modedel_hidden">Mode Of Delivery<br>
                                <select class="ipcss " [disabled]="modeof_disabled || client" (change)="send_data()"
                                    [(ngModel)]="Modeof_delivery" (ngModelChange)="modeOfDelivery($event)" disableOptionCentering>
                                    <option *ngFor="let mode of Mode_del_array" value="{{mode.description}}">
                                        {{mode.description}}</option>
                                </select>
                            </mat-label>
                        </div>
                        <div [hidden]="reason_hidden" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <mat-label class="matlabel">Reasons for C-section<br>
                                <select class="ipcss " [disabled]="reasonc_disabled || client" (change)="send_data()"
                                    [(ngModel)]="reason_csection" disableOptionCentering>
                                    <option disabled>Select</option>
                                    <option *ngFor="let reason of reason_array">{{reason.description}}</option>
                                </select>
                            </mat-label>
                        </div>

                        <div [hidden]="baby_gendhidden" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <mat-label class="matlabel" [hidden]="baby_gendhidden">Gender<br> 
                                <select style="width: 100% !important;border-color: rgb(250, 40, 2);" id="ohgender" (change)="send_data()"
                                    class="ipcss " (change)="Gender($event)" [disabled]="babygend_disabled || client"
                                    required [(ngModel)]="obs_gender" disableOptionCentering>
                                    <option disabled>Select</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                </select>
                            </mat-label>
                        </div>
                        <div [hidden]="baby_weighthidden" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <mat-label class="matlabel" [hidden]="baby_weighthidden">Weight<br>
                                <div class="row ">
                                    <div class="col-3 col-sm-3 col-xl-3 col-lg-3 col-md-3" style="padding-right: 0;">
                                        <input matInput [disabled]="babywei_disabled || client" class="ipcss " required (blur)="send_data()"
                                            [(ngModel)]="baby_weight" maxlength="2">
                                    </div>
                                    <div class="col-1">
                                        <span style="padding: 0;font-size: 23px;">.</span>
                                    </div>
                                    <div class="col-xl-3 col-sm-3 col-xl-3 col-lg-3 col-md-3" style="padding-left: 5px;">
                                        <input matInput class="ipcss " [hidden]="baby_weighthidden" (blur)="send_data()"
                                            [disabled]="babywei_disabled || client" required [(ngModel)]="baby_weight1"
                                            maxlength="2">
                                    </div>
                                    <div class="col-4 col-sm-4 col-xl-4 col-lg-4 col-md-4" style="padding-left: 4px;padding-right: 0px;">
                                        <select class="ipcss " disableOptionCentering [hidden]="baby_weighthidden" (change)="send_data()"
                                            [disabled]="babywei_disabled || client" [(ngModel)]="baby_weightmeausre">
                                            <option value="kgs">kgs</option>
                                            <option value="lbs">lbs</option>
                                        </select>
                                    </div>
                                </div>
                            </mat-label>
                        </div>
                        <div [hidden]="birthheight_hidden" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <div class="row">
                                <div class="col-7">
                                    <mat-label class="matlabel" [hidden]="birthheight_hidden">Birth Height<br>
                                        <input matInput class=" ipcss heightmsr_obshist" required  (blur)="send_data()"
                                            [disabled]="birtheig_disabled || client"
                                            onkeypress="return event.charCode >= 48 && event.charCode <= 57" required
                                            [(ngModel)]="baby_height" maxlength="3">
                                    </mat-label>
                                </div>
                                <div class="col-5" style="padding-left: 0;">
                                    <mat-label class="matlabel" [hidden]="birthheight_hidden">Measure<br>
                                        <select class="ipcss" disableOptionCentering (change)="send_data()"
                                            [disabled]="birtheig_disabled || client" [(ngModel)]="baby_height_measure">
                                            <option value="cms">cms</option>
                                            <option value="inch">inch</option>
                                        </select>
                                    </mat-label>
                                </div>
                            </div>

                        </div>
                        <div [hidden]="onset_labhidden" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <mat-label class="matlabel" [hidden]="onset_labhidden">Onset Of Labour<br>
                                <select class="ipcss " [(ngModel)]="onset_lab" [disabled]="onesetla_disabled || client" (change)="send_data()"
                                    disableOptionCentering>
                                    <option value="None">None</option>
                                    <option value="Spontaneous">Spontaneous</option>
                                    <option value="Induced">Induced</option>
                                </select>
                            </mat-label>
                        </div>
                        <div [hidden]="birthlocation_hidden" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <mat-label class="matlabel" [hidden]="birthlocation_hidden">Place Of Birth<br>
                                <input matInput class="ipcss " [disabled]="birtloc_disabled || client" required (blur)="send_data()"
                                    [(ngModel)]="delivery_location">
                            </mat-label>
                        </div>
                        <div [hidden]="baby_viahidden" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <mat-label class="matlabel" [hidden]="baby_viahidden">Baby's Viability<br>
                                <select class="ipcss " [(ngModel)]="baby_viability" (change)="send_data()"
                                    [disabled]="babyvia_disabled || client" disableOptionCentering>
                                    <option disabled>Select</option>
                                    <option value="Live birth">Live birth</option>
                                    <option value="Still birth">Still birth</option>
                                </select>
                            </mat-label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="cover_div">
                <div class="header_lable">
                    Parity & gravida
                </div>
                <div class="content_cover">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <mat-label class="matlabel">Gravida<br>
                                <input matInput class="ipcss " required (readonly)="true" [(ngModel)]="gravida" (blur)="send_data()"
                                    [disabled]="client" maxlength="2">
                            </mat-label>
                        </div>
                        <div [hidden]="gestation_hidden" class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <mat-label class="matlabel" [hidden]="gestation_hidden">Gestational Age<br>
                                <select class="ipcss " [disabled]="gestage_disabled || client" [(ngModel)]="Gest_age" (change)="send_data()"
                                    disableOptionCentering>
                                    <option *ngFor="let gest of gest_age_array" value="{{gest.description}}">
                                        {{gest.description}}
                                    </option>
                                </select>
                            </mat-label>
                        </div>
                        <div [hidden]="breastfeed_hidden" class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <mat-label class="matlabel" [hidden]="breastfeed_hidden">Breast Feeding<br>
                                <select class="ipcss hw_measure" [disabled]="brefed_disabled || client" (change)="send_data()"
                                    [(ngModel)]="breast_feeding" disableOptionCentering>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </mat-label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="cover_div"  [ngStyle]="{'padding-bottom':padd_others}">
                <div class="content_cover">
                    <div  *ngIf="!client"  class="row">
                        <div [hidden]="complicate_hidden" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <mat-label class="matlabel" [hidden]="complicate_hidden">Complications During Delivery<br>
                                <textarea matInput class="ipcss obsttxtareaheight " [disabled]="client" (blur)="send_data()"
                                    [(ngModel)]="del_comp_hist" (click)="del_comp_popup()" rows="1"></textarea>
                            </mat-label>
                        </div>
                        <div [hidden]="complicateduring_hidden" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <mat-label class="matlabel" [hidden]="complicateduring_hidden">Postportum Complications <br>
                                <textarea matInput class="ipcss obsttxtareaheight" [(ngModel)]="comp_partum_hist" (blur)="send_data()"
                                    (click)="comp_postpartum()" rows="1" [disabled]="client"></textarea>

                            </mat-label>
                        </div>
                        <div [hidden]="babyabnormal_hidden" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <mat-label class="matlabel" [hidden]="babyabnormal_hidden">Baby's Abnormalities<br>
                                <textarea matInput class="ipcss obsttxtareaheight " #myInput id="myInput" (blur)="send_data()"
                                    [disabled]="bababnor_disabled || client" rows="1" value="None" maxlength="150"
                                    [(ngModel)]="baby_abnormalities"></textarea>
                            </mat-label>
                        </div>
                        <div [hidden]="babyabnormal_hidden" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <mat-label class="matlabel" [hidden]="antenatal_hidden">Antenatal Problems<br>
                                <textarea matInput class="ipcss obsttxtareaheight " #myInput id="myInput" (blur)="send_data()"
                                    [disabled]="ante_disabled || client" rows="1" value="None" maxlength="150"
                                    [(ngModel)]="antenatal_prblm"></textarea>
                            </mat-label>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <a style="float: right;" (click)="Obestric_addclick()"><img
                    src="../../../assets/ui_icons/buttons/add_button.svg" class="saveimgbtn_inpatinfo" /></a>
        </div>
    </div>

    <div [hidden]="obstestric_historylist.length==0" class="row" style="margin-top: 5px;">
        <div class="col-12">
            <div class="dig_table_overflow" [hidden]="obstestric_historylist.length==0">
                <div class="table-responsive">
                    <table class="table table-hover table-dynamic">
                        <thead>
                            <tr>
                                <th>Gravida</th>
                                <th>Date of delivery</th>
                                <th>Gestational age</th>
                                <th>Mode of delivery</th>
                                <th>Gender</th>
                                <th>Weight</th>
                                <th>Viability</th>
                                <th>Abnormalities</th>
                                <th>Edit</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let Obstetrichist of obstestric_historylist">
                                <td style="text-align: left;">{{Obstetrichist.gravida}}</td>
                                <td style="text-align: left;">{{Obstetrichist.delivery_year}}</td>
                                <td style="text-align: left;">{{Obstetrichist.gest_age_desc}}</td>
                                <td style="text-align: left;">{{Obstetrichist.obs_del_mode_desc}}</td>
                                <td style="text-align: left;">{{Obstetrichist.gender}}</td>
                                <td style="text-align: left;">{{Obstetrichist.weight}}</td>
                                <td style="text-align: left;">{{Obstetrichist.baby_viab}}</td>
                                <td style="text-align: left;">{{Obstetrichist.baby_abnorm}}</td>

                                <td [hidden]="Obstetrichist.new_data == 0 "><a
                                        (click)="obstric_select(Obstetrichist.delivery_year,Obstetrichist.gest_age_desc,Obstetrichist.gender,Obstetrichist.weight)"
                                        style="cursor: pointer"><img src="../../../assets/ui_icons/buttons/pencil_icon.svg" width="17px"
                                            height="17px" /></a></td>
                                <td [hidden]=" Obstetrichist.new_data != 0 "><a
                                        (click)="obstric_view(Obstetrichist.delivery_year,Obstetrichist.gest_age_desc,Obstetrichist.gender,Obstetrichist.weight)"
                                        style="cursor: pointer">View</a></td>
                            </tr>
                        </tbody>

                    </table>
                </div>
            </div>
        </div>
    </div>
</div>