import { Component, OnInit , Input , HostListener} from '@angular/core';
import {Helper_Class} from '../../helper_class';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
// import {ipaddress.getIp,Ip_with_img_address,ipaddress.country_code} from '../../../assets/js/ipaddress.js';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { Date_Formate } from '../../../assets/js/common';
import { Observable, Observer,Subscription } from 'rxjs';
import * as Highcharts from 'highcharts';
import { Doc_Helper } from '../Doc_Helper';
import { CasesheetService } from '../casesheet/casesheet.service';
import { DiabeticpopuphistoryComponent } from '../diabeticpopuphistory/diabeticpopuphistory.component';
import { MatDialog } from '@angular/material/dialog';
import { BmiandbmrService } from '../service/bmiandbmr.service';
import { DecimalPipe } from '@angular/common';
import { DropdownModelpageComponent } from '../dropdown-modelpage/dropdown-modelpage.component';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { ipaddress } from '../../ipaddress' ;import { masterCSData_Helper } from '../casesheet/masterCSData_Helper';
import { Master_Helper } from '../Master_Helper';
import { MasterCSHelperService } from '../casesheet/masterCSData.service';
import { Message_data } from 'src/assets/js/Message_data';

@Component({
  selector: 'app-gyn-personal',
  templateUrl: './gyn-personal.component.html',
  styleUrls: ['./gyn-personal.component.css']
})

export class GynPersonalComponent implements OnInit {
    public gynPersonalFlag ="min";
    public complaints;
    public present_illvalue;
    public past_medical;
    public minflag:boolean = true;  
    public chart_hidden:boolean;
    public compli_hidden;
    public occupation_txt;
    public Main_concerntxt;
    public disease_txt;
    public stress_txt;
    public diab_type = [];
    public preg_related_condition_array = [];
    public send_pregnancy_array = [];
    public send_issues_array = [];
    public preg_related_issues_array = [];
    public contracept_array = [];
    public drug_allergy;
    public Menstrual_Period;
    public cycle_length;
    public Frequency_Length;
    public Medical_hist_array = [];
    public send_medical_array = [];
    public present_illness_arry = [];
    public send_present_array = [];
    public Refered_doctor = [];
    public Refered_name = [];
    public refer_txt;
    public speclization_txt;
    public Gyna_Stage = [];
    public Characer_pain = [];
    public pain_freqlist = [];

    public infection;
    public infect_list = [];
    public compli_sex: string;

    public gyna_fields;
    public edd_redd: boolean;
    public noof_weekhidden: boolean;
    public trimester_hidden: boolean;
    public edd_hidden: boolean;
    public popsmear_hidden: boolean;
    public gyna_fields_hidden: boolean;
    public sexual_satushidden: boolean;
    public typeofpain_hidden: boolean;
    public difficult_durehidden: boolean;
    public sexual_satisfacthidden: boolean;
    public age_whensexualhidden: boolean;
    public typeofsex_hidden: boolean;
    public contracept_hidden: boolean;
    public typeof_contracepthidden: boolean;
    public infection_hidden: boolean;
    public reason_dishidden: boolean;
    public any_otherhidden: boolean;
    public menstrual_painhidden: boolean;
    public timeofpain_hidden: boolean;
    public character_fieldhidden: boolean;
    public menorrhagia_hidden: boolean;
    public noof_dayshidden: boolean;
    public nofrequency_hidden: boolean;
    public pop_datehidden: boolean;
    public pop_resulthidden: boolean;
    public stage_hidden: boolean;
    public stage_datehidden: boolean;
    public compli_disabled: boolean;
    public height_hidden: boolean;
    public weight_hidden: boolean;
    public bmi_hidden: boolean;
    public bmr_hidden: boolean;

    public gyna_case_hist_id;
    public life_style_id;
    public occupation;
    public refered_by;
    public hospital_name;
    public stress_factor;
    public purpose_ofvisit;
    public Main_concern;
    public maritial_status;
    public maritial;
    public drugs;
    public stage;
    public stage_date;
    public stage_date_chk;
    public gyna_menstrual;
    public obestric_menstrual;
    public sexual_status;
    public obst_flag;
    public obstric_sexual;
    public Sexual_Difficulties;
    public obstric_sexdifficult;
    public typeof_pain;
    public Sexual_Pain_txt;
    public gynasex_flag;
    public gynaperiods_flag;
    public stage_id;
    public gyna_stdate;
    public last_mensperiod;
    public last_mendate;
    public last_gynadate;
    public cyclefreq_obstric;
    public cyclefreq_gyna;
    public Edd_rddtxt;
    public Edd_redd;
    public Trmester_txt;
    public trime_data;
    public pop_date;
    public pop_datetxt;
    public pop_date_chk;
    public pop_result;
    public pop_resultdata;
    public sexual_satisfaction;
    public satis_data;
    public sexual_active;
    public age_sexdata;
    public sextype_data;
    public type_ofsex;
    public contracept;
    public contre_data;
    public type_contracept;
    public contracept_type;
    public sex_infect_id;
    public reason_discontinue;
    public discount_readata;
    public other_issuesex;
    public sex_othdata;
    public mens_paintxt;
    public menpain_data;
    public pain_timedata;
    public pain_timing;
    public character_pain;
    public character_id;
    public menregia_data;
    public menorragiatxt;
    public no_of_days;
    public noof_data;
    public pain_frequency;
    public painfreq_data;
    public trime_flg;
    public Medical_hist;
    public Present_illness;
    public noof_weeks;
    public drug_required: boolean;
    public drug_disabled: boolean;
    public statde_darequire: boolean;
    public time_painrequire: boolean;
    public character_painrequire: boolean;
    public time_paindisabled: boolean;
    public character_paindisabled: boolean;
    public noof_daysrequire: boolean;
    public frequency_required: boolean;
    public noof_daysdisabled: boolean;
    public freuency_disabled: boolean;
    public typcontra_require: boolean;
    public reason_require: boolean;
    public popre_require: boolean;

    public difficult_disabled: boolean;
    public sexaul_satdisabled: boolean;
    public age_whendisabled: boolean;
    public typeof_sexdisabled: boolean;
    public contracept_disabled: boolean;
    public infect_disabled: boolean;
    public any_otherdisabled: boolean;
    public typeofpain_disabled: boolean;
    public typecontracept_disabled: boolean;
    public reason_disabled: boolean;
    public menstrualgyna;
    public menstrualobstric;
    public obestric_menstrualhidden: boolean;
    public gyna_menstrualhidden: boolean;
    public sexualobstric;
    public sexualgyna;
    public alcholhist;
    public age_hidden: boolean;
    public maritial_hidden: boolean;
    public current_occupationhidden: boolean;
    public stress_hidden: boolean;
    public main_hidden: boolean;
    public past_hiddeen: boolean;
    public present_hiddeen: boolean;
    public drug_hiddeen: boolean;
    public drug1_hiddeen: boolean;
    public menstrual_hidden: boolean;
    public lastmenstrual_hidden: boolean;
    public cycle_hidden: boolean;
    public cycle_frequencyhidden: boolean;
    public static Provider_or_Client: string;
    public maritial_disabled: boolean;
    public occupation_disabled: boolean;
    public stress_disabled: boolean;
    public maincon_disabled: boolean;
    public past_medicaldisabled: boolean;
    public present_disabled: boolean;
    public drugallergy_disabled: boolean;
    public drugs_disabled: boolean;
    public stage_disabled: boolean;
    public menstrual_disabled: boolean;
    public cycle_disabled: boolean;
    public cycle_freqdisabled: boolean;
    public trimester_disabled: boolean;
    public noof_weekdis: boolean;
    public menstrual_paindisbaled: boolean;
    public menorhagia_disabled: boolean;
    public popresult_disabled: boolean;
    public sexual_disabled: boolean;
    
    public Hospital_hidden: boolean;
    public spl_hidden: boolean;
    public refer_hidden: boolean;
    public referedby_hidden: boolean;
    public habitual_history_doctor: boolean;
    public sexual_menshidden: boolean;
    public loader: boolean;

    public others;
    public height_txt;
    public height_measure;
    public weight_txt;
    public weight_measure;
    public txt_bmi;
    public txt_bmr;

    public last_date;
    public age_txt;
    public marital_life;
    public agefield: boolean;

    public medhist_value_array =[];
    public pres_ill_value_array =[];
    subscription: Subscription;

    public currentDate;
    public patient_list;
    public userinfo:any;
    public user_id:any;
    public chart_flag:boolean = false;

    public client:boolean;
    public client_name;
    public client_gender;
    editorStyle = {
        height: '210px;',
      };
      content;
      htmlString: string = '';
      modules = {
        toolbar: [
          ['bold', 'italic', 'underline'], // toggled buttons
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ align: [] }],
        ],
      };

    constructor(public bmiservice:BmiandbmrService,public dialog:MatDialog,public messageservice:CasesheetService,private http:Http,
        private routes:ActivatedRoute,private router:Router,public toastr:ToastrService,public masterCSdata:MasterCSHelperService) { 
        this.agefield=true;
        this.height_measure = "cms";
        this.weight_measure = "kgs";
        this.weight_txt = "000.00";
        var ClntAge = Helper_Class.getClntAge();

        this.drug_allergy = "No";
        this.Menstrual_Period = "Regular";
        this.cycle_length = "3";
        this.Frequency_Length = "5";
        this.mens_paintxt = "No";
        this.menorragiatxt = "No";
        this.sexual_status = "None";
        this.Sexual_Difficulties= "None";
        this.typeof_pain = "None";
        this.contracept = "None";
        this.typeof_pain = "Superficial";
        this.infection = "None";

        this.age_hidden = true;
        this.maritial_hidden = true;
        this.current_occupationhidden = true;
        this.stress_hidden = true;
        this.main_hidden = true;
        this.past_hiddeen = true;
        this.present_hiddeen = true;
        this.drug_hiddeen = true;
        this.drug1_hiddeen = true;
        this.stage_hidden = true;
        this.menstrual_painhidden = true;
        this.timeofpain_hidden = true;
        this.character_fieldhidden = true;
        this.menorrhagia_hidden = true;
        this.noof_dayshidden = true;
        this.nofrequency_hidden = true;
        this.pop_datehidden = true;
        this.pop_resulthidden = true;
        this.sexual_satushidden = true;
        this.difficult_durehidden = true;
        this.typeofpain_hidden = true;
        this.sexual_satisfacthidden = true;
        this.age_whensexualhidden = true;
        this.typeofsex_hidden = true;
        this.contracept_hidden = true;
        this.typeof_contracepthidden = true;
        this.infection_hidden = true;
        this.reason_dishidden = true;
        this.any_otherhidden = true;
        this.edd_hidden = true;
        this.trimester_hidden = true;
        this.noof_weekhidden = true;
        this.height_hidden = true;
        this.weight_hidden = true;
        this.bmi_hidden = true;
        this.bmr_hidden = true;
    }

    async ngOnInit() {
        this.subscription = this.messageservice.getCasesheetMessage().subscribe(message => {
            if (message) {
                this.gynPersonalFlag = message;
                if(message == "max"){
                    if(this.last_mensperiod == undefined){
                        this.check_Menstrual_period("1");
                    }
                }
            }
        });

        this.client = Doc_Helper.getAppFlow() == "client" ? true : false;
        this.userinfo = Helper_Class.getInfo();
        this.patient_list = Doc_Helper.getClient_Info();
        this.user_id = Helper_Class.getInpatientFlow() == "nurse" ? this.patient_list.user_id : this.userinfo.user_id;

        this.client_name = this.patient_list.client_name;
        this.client_gender = this.patient_list.Gender_data;
        
        this.age_txt = this.patient_list.Age_data;
        if(this.age_txt == undefined){
            this.age_txt="";
        }
        var ClntAge = this.age_txt;
        if (ClntAge != null) {
            if(ClntAge =="0"){
                this.age_txt="<1";
                this.agefield=true;
            } else {
                this.age_txt = ClntAge;
            }
            
            if (parseInt(ClntAge) <= 11) {
                this.sexual_menshidden = true;
                if (this.gyna_fields == "Gynaecology") {
                    this.gyna_menstrualhidden = true;
                    this.obestric_menstrualhidden = false;
                } else {
                    this.obestric_menstrualhidden = true;
                    this.gyna_menstrualhidden = false;
                }
            }
        } else {
            this.age_txt = "";
            this.sexual_menshidden = false;
            this.agefield=false;
        }
       
        this.ChangeGynofield(Doc_Helper.getGyna_fields());
        this.getCurrentDate();
        if(masterCSData_Helper.getMasterGynoStage() == undefined){
            await this.masterCSdata.getGynoStage();
            this.Get_stage();
        }else{
            this.Get_stage();
        }
        if(masterCSData_Helper.getMasterCharacterofpain() == undefined){
            await this.masterCSdata.getCharacterofpain();
            this.Get_character_ofpain();
        }else{
            this.Get_character_ofpain();
        }
        if(masterCSData_Helper.getMasterFrequency() == undefined){
            await this.masterCSdata.getFrequency();
            this.Get_frequency();
        }else{
            this.Get_frequency();
        }
        if(masterCSData_Helper.getMasterInfection() == undefined){
            await this.masterCSdata.getInfection();
            this.Get_infection();
        }else{
            this.Get_infection();
        }
        if(masterCSData_Helper.getMasterReligiousOtherBeliefs() == undefined){
            await this.masterCSdata.getMedicalHistory();
            this.Get_Medical_history();
        }else{
            this.Get_Medical_history();
        }
        if(masterCSData_Helper.getMasterTypeContraceptive() == undefined){
            await this.masterCSdata.getTypeContraceptive();
            this.Type_contraceptive();
        }else{
            this.Type_contraceptive();
        }
        
        var getHopitalfieldsRet=Helper_Class.getHopitalfieldsRet();
        if (getHopitalfieldsRet != undefined) {
            for (var i = 0; i < getHopitalfieldsRet.length; i++) {
                if (getHopitalfieldsRet[i].page_name == "Personal") {
                    if (getHopitalfieldsRet[i].fields != undefined) {
                        for (var j = 0; j < getHopitalfieldsRet[i].fields.length; j++) {
                            if (getHopitalfieldsRet[i].fields[j].field_name == "Height") {
                                this.height_hidden = false;
                            }
                            if (getHopitalfieldsRet[i].fields[j].field_name == "Weight") {
                                this.weight_hidden = false;
                            }

                            if (getHopitalfieldsRet[i].fields[j].field_name == "BMI") {
                                this.bmi_hidden = false;
                            }

                            if (getHopitalfieldsRet[i].fields[j].field_name == "BMR") {
                                this.bmr_hidden = false;
                            }

                            if (getHopitalfieldsRet[i].fields[j].field_name == "Age") {
                                this.age_hidden = false;
                            }

                            if (getHopitalfieldsRet[i].fields[j].field_name == "Marital status") {
                                this.maritial_hidden = false;
                            }

                            if (getHopitalfieldsRet[i].fields[j].field_name == "Current occupation") {
                                this.current_occupationhidden = false;
                            }

                            if (getHopitalfieldsRet[i].fields[j].field_name == "Stress factors") {
                                this.stress_hidden = false;
                            }

                            if (getHopitalfieldsRet[i].fields[j].field_name == "Main concenrns") {
                                this.main_hidden = false;
                            }

                            if (getHopitalfieldsRet[i].fields[j].field_name == "Past medical history") {
                                this.past_hiddeen = false;
                            }

                            if (getHopitalfieldsRet[i].fields[j].field_name == "Present illness") {
                                this.present_hiddeen = false;
                            }

                            if (getHopitalfieldsRet[i].fields[j].field_name == "Drug allergy") {
                                this.drug_hiddeen = false;
                                this.drug1_hiddeen = false;
                            }

                            if (this.gyna_fields == "Gynaecology") {
                                if (getHopitalfieldsRet[i].fields[j].field_name == "Stage") {
                                    this.stage_hidden = false;
                                    this.stage_datehidden = false;
                                }

                                if (getHopitalfieldsRet[i].fields[j].field_name == "Menstrual pain") {
                                    this.menstrual_painhidden = false;
                                    this.timeofpain_hidden = false;
                                    this.character_fieldhidden = false;
                                }

                                if (getHopitalfieldsRet[i].fields[j].field_name == "Menorrhagia") {
                                    this.menorrhagia_hidden = false;
                                    this.noof_dayshidden = false;
                                    this.nofrequency_hidden = false;
                                }

                                if (getHopitalfieldsRet[i].fields[j].field_name == "Sexual satisfaction") {
                                    this.sexual_satisfacthidden = false;
                                }

                                if (getHopitalfieldsRet[i].fields[j].field_name == "Age when sexual start") {
                                    this.age_whensexualhidden = false;
                                }

                                if (getHopitalfieldsRet[i].fields[j].field_name == "Type of sex") {
                                    this.typeofsex_hidden = false;
                                }

                                if (getHopitalfieldsRet[i].fields[j].field_name == "Contraceptives") {
                                    this.contracept_hidden = false;
                                    this.typeof_contracepthidden = false;
                                    this.reason_dishidden = false;
                                }

                                if (getHopitalfieldsRet[i].fields[j].field_name == "Infection") {
                                    this.infection_hidden = false;
                                }

                                if (getHopitalfieldsRet[i].fields[j].field_name == "Any other issues") {
                                    this.any_otherhidden = false;
                                }

                            } else {
                                if (getHopitalfieldsRet[i].fields[j].field_name == "Papsmear date") {
                                    this.pop_datehidden = false;
                                    this.pop_resulthidden = false;
                                }

                                if (getHopitalfieldsRet[i].fields[j].field_name == "Edd") {
                                    this.edd_hidden = false;
                                }

                                if (getHopitalfieldsRet[i].fields[j].field_name == "Trimester") {
                                    this.trimester_hidden = false;
                                }

                                if (getHopitalfieldsRet[i].fields[j].field_name == "No of weeks") {
                                    this.noof_weekhidden = false;
                                }
                            }

                            if (getHopitalfieldsRet[i].fields[j].field_name == "Mesnstrual periods") {
                                this.menstrual_hidden = false;
                            }

                            if (getHopitalfieldsRet[i].fields[j].field_name == "Last mesnstrual period") {
                                this.lastmenstrual_hidden = false;
                            }

                            if (getHopitalfieldsRet[i].fields[j].field_name == "Cycle length") {
                                this.cycle_hidden = false;
                            }

                            if (getHopitalfieldsRet[i].fields[j].field_name == "Cycle frequency") {
                                this.cycle_frequencyhidden = false;
                            }

                            if (getHopitalfieldsRet[i].fields[j].field_name == "Sexual status") {
                                this.sexual_satushidden = false;
                            }

                            if (getHopitalfieldsRet[i].fields[j].field_name == "Difficulties during") {
                                this.difficult_durehidden = false;
                            }

                            if (getHopitalfieldsRet[i].fields[j].field_name == "Type of pain") {
                                this.typeofpain_hidden = false;
                            }

                            if (getHopitalfieldsRet[i].fields[j].field_name == "Hospital name") {
                                this.Hospital_hidden = false;
                            }

                            if (getHopitalfieldsRet[i].fields[j].field_name == "Speciality") {
                                this.spl_hidden = false;
                            }

                            if (getHopitalfieldsRet[i].fields[j].field_name == "Reffered to") {
                                this.refer_hidden = false;
                            }

                            if (getHopitalfieldsRet[i].fields[j].field_name == "Referred by") {
                                this.referedby_hidden = false;
                            }
                        }
                    }
                }
            }
        }
    }
    changelinemgmt = (event,value) => {
        if(value == "main"){
         // this.Maincon_txt = event.html;   
          this.complaints = event.html;   
        }else if(value == "past"){
          this.past_medical= event.html;   
        }else{
          this.present_illvalue=event.html
        }
        
      };

    ChangeGynofield(e){
        this.gyna_fields = "";
        this.gyna_fields = e;
        
        if (this.gyna_fields == "Gynaecology") {
            this.edd_redd = true;
            this.noof_weekhidden = true;
            this.trimester_hidden = true;
            this.edd_hidden = true;
            this.popsmear_hidden = true;
            this.pop_datehidden = true;
            this.pop_resulthidden = true;

            this.gyna_fields_hidden = false;
            this.stage_hidden = false;
            this.menstrual_painhidden = false;
            this.timeofpain_hidden = false;
            this.character_fieldhidden = false;
            this.menorrhagia_hidden = false;
            this.noof_dayshidden = false;
            this.nofrequency_hidden = false;
            this.stage_datehidden = false;
            this.sexual_satisfacthidden = false;
            this.age_whensexualhidden = false;
            this.typeofsex_hidden = false;
            this.contracept_hidden = false;
            this.typeof_contracepthidden = false;
            this.infection_hidden = false;
            this.reason_dishidden = false;
            this.any_otherhidden = false;
            this.purpose_ofvisit = "Gynaecology";
            this.obestric_menstrualhidden = true;
            this.gyna_menstrualhidden = false;
            this.compli_hidden = false;

        } else if (this.gyna_fields == "Obstetrics") {
            this.compli_hidden = true;
            this.edd_redd = false;
            this.noof_weekhidden = false;
            this.trimester_hidden = false;
            this.edd_hidden = false;
            this.popsmear_hidden = false;
            this.pop_datehidden = false;
            this.pop_resulthidden = false;
            this.gyna_fields_hidden = true;
            this.stage_hidden = true;
            this.stage_datehidden = true;
            this.menstrual_painhidden = true;
            this.timeofpain_hidden = true;
            this.character_fieldhidden = true;
            this.menorrhagia_hidden = true;
            this.noof_dayshidden = true;
            this.nofrequency_hidden = true;
            this.purpose_ofvisit = "Obstetrics";
            this.obestric_menstrualhidden = false;
            this.gyna_menstrualhidden = true;
            this.sexual_satisfacthidden = true;
            this.age_whensexualhidden = true;
            this.typeofsex_hidden = true;
            this.contracept_hidden = true;
            this.typeof_contracepthidden = true;
            this.infection_hidden = true;
            this.reason_dishidden = true;
            this.any_otherhidden = true;
            var weeks_data = this.noof_weeks;

            if (weeks_data != "" && weeks_data != undefined && weeks_data != null) {
                this.check_Menstrual_period(weeks_data);
            } else {
                this.check_Menstrual_period("1");
            }
        }
        this.send_data();
    }

    Get_pregnancy_related_condition() {
        var obj = masterCSData_Helper.getMasterPregnancyRelatedCondition();
        if(obj != undefined){
            for (var i = 0; i < obj.preg_medical_conds.length; i++) {
                this.preg_related_condition_array = obj.preg_medical_conds;
            }
        }
    }

    pregnancy_change(selectedValue) {
        this.send_pregnancy_array = selectedValue;
    }

    issue_change(selectedValue) {
        this.send_issues_array = selectedValue;
    }

    //Read only(disabeld)
    isReadonly() { return true; }

    drug_change(data) {
        if (data == "Yes") {
            this.drug_required = true;
            this.drug_disabled = false;
        } else {
            this.drug_required = false;
            this.drug_disabled = true;
            this.drugs = undefined;
        }
    }

    stage_change(data) {
        this.statde_darequire = data == "Perimenopause" ? true : false;
    }

    menstrualpain_change(data) {
        if (data == "Yes") {
            this.time_painrequire = true;
            this.character_painrequire = true;
            this.time_paindisabled = false;
            this.character_paindisabled = false;
            
        } else {
            this.time_painrequire = false;
            this.character_painrequire = false;
            this.time_paindisabled = true;
            this.character_paindisabled = true;
            this.pain_timing = undefined;
            this.character_pain = undefined;
        }
    }

    menorrhagia_change(data) {
        if (data == "No") {
            this.noof_daysrequire = false;
            this.frequency_required = false;
            this.noof_daysdisabled = true;
            this.freuency_disabled = true;
            this.no_of_days = undefined;
            this.pain_frequency = undefined;
        } else {
            this.noof_daysrequire = true;
            this.frequency_required = true;
            this.noof_daysdisabled = false;
            this.freuency_disabled = false;
        }
    }

    sexual_statuschange(sexual) {
        if (sexual == "None") {
            this.difficult_disabled = true;
            this.sexaul_satdisabled = true;
            this.age_whendisabled = true;
            this.typeof_sexdisabled = true;
            this.contracept_disabled = true;
            this.infect_disabled = true;
            this.any_otherdisabled = true;
            this.typeofpain_disabled = true;
            this.typecontracept_disabled = true;
            this.reason_disabled = true;
            this.compli_disabled = true;
            this.reason_discontinue = "";
            this.other_issuesex = "";
            
            this.Sexual_Difficulties = "None";
            this.typeof_pain = "Superficial";
            this.sexual_satisfaction = undefined;
            this.sexual_active = undefined;
            this.type_ofsex = undefined;
            this.contracept = "None";
            this.type_contracept = undefined;
            this.reason_discontinue = undefined;
            this.infection = "None";
            this.other_issuesex = undefined;
            this.typcontra_require = false;
            this.reason_require = false;
        } else {
            this.difficult_disabled = false;
            this.sexaul_satdisabled = false;
            this.age_whendisabled = false;
            this.typeof_sexdisabled = false;
            this.contracept_disabled = false;
            this.infect_disabled = false;
            this.any_otherdisabled = false;
        }
    }

    diificult_duringchange(diificult) {
        if (diificult == "None") {
            this.typeof_pain = "Superficial";
            this.typeofpain_disabled = true;
        } else {
            this.typeofpain_disabled = false;
        }
    }

    contrapt_change(contra) {
        if (contra == "None") {
            this.reason_discontinue = undefined;
            this.infection = this.infect_list[0].description;
            this.typcontra_require = false;
            this.reason_require = false;
            this.typecontracept_disabled = true;
            this.reason_disabled = true;
            this.compli_disabled =true;
        } else {
            this.typcontra_require = true;
            this.reason_require = false;
            this.typecontracept_disabled = false;
            this.reason_disabled = false;
            this.compli_disabled = false;
        }
    }

    //menstrual graph
    check_Menstrual_period(chk_count) {
        this.chart_flag = true;
        if (chk_count == "0") {
            chk_count = "1"
        }
        if (chk_count == 1) {
            $(document).ready(function(){
                try {
                    var aChart1 = new Highcharts.Chart({
                        tooltip: { enabled: false },
                        chart: {
                            type: 'line',
                            renderTo: "chart_container1",
                            plotBackgroundImage: 'assets/img/conception.png',
                            height: 210,
                            width: 604,
                        },
                        plotOptions: {
                            line: {
                                marker: {
                                    enabled: false
                                }
                            }
                        },
                        legend: {
                            enabled: false
                        },
                        xAxis: {
                            visible: false,
                            gridLineWidth: 0,
                            minorGridLineWidth: 0,
                            labels:
                            {
                                lineColor: 'transparent',
                                enabled: false
                            }
                        },
                        series: [
                            {
                                marker: {
                                    symbol: ''
                                },
                                data: [
                                    0, {
                                        y: 0,
                                        color: '#BF0B23',
                                        marker: {
                                            enabled: true,
                                            fillColor: 'red'
                                        }
                                    }, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
                                ]
                            }
                        ]
                    });
                }
                catch (error) {
                    
                }
            });
        }

        if (chk_count == 2) {
            $(document).ready(function(){
                try {
                    var aChart1 = new Highcharts.Chart({
                        tooltip: { enabled: false },
                        chart: {
                            type: 'line',
                            renderTo: "chart_container1",
                            plotBackgroundImage: 'assets/img/conception.png',
                            height: 210,
                            width: 604,
                            //axisLabelPadding: 5
                        },
                        plotOptions: {
                            line: {
                                marker: {
                                    enabled: false
                                }
                            }
                        },
                        legend: {
                            enabled: false
                        },
                        xAxis: {
                            visible: false,
                            gridLineWidth: 0,
                            minorGridLineWidth: 0,
                            labels:
                            {
                                lineColor: 'transparent',
                                enabled: false
                            }
                        },

                        series: [
                            {
                                marker: {
                                    symbol: ''
                                },
                                data: [
                                    0, {
                                        y: 0,
                                        color: '#BF0B23',
                                        marker: {
                                            enabled: true,
                                            fillColor: 'red'
                                        }
                                    }, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
                                ]
                            }
                        ]
                    });
                }
                catch (error) {

                }
            });
        }

        if (chk_count == 3) {
            $(document).ready(function(){
                try {
                    var aChart1 = new Highcharts.Chart({
                        tooltip: { enabled: false },
                        chart: {
                            type: 'line',
                            renderTo: "chart_container1",
                            plotBackgroundImage: 'assets/img/conception.png',
                            height: 230,
                            width: 604,
                            //axisLabelPadding: 5
                        },
                        plotOptions: {
                            line: {
                                marker: {
                                    enabled: false
                                }
                            }
                        },
                        legend: {
                            enabled: false
                        },
                        xAxis: {
                            visible: false,
                            gridLineWidth: 0,
                            minorGridLineWidth: 0,
                            labels:
                            {
                                lineColor: 'transparent',
                                enabled: false
                            }
                        },
                        series: [
                            {
                                marker: {
                                    symbol: ''
                                },
                                data: [
                                    0, 0, {
                                        y: 0,
                                        color: '#BF0B23',
                                        marker: {
                                            enabled: true,
                                            fillColor: 'red'
                                        }
                                    }, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
                                ]
                            }
                        ]
                    });
                }
                catch (error) {
                    
                }
            });
        }

        if (chk_count == 4) {
            $(document).ready(function(){
                try {
                    var aChart1 = new Highcharts.Chart({
                        tooltip: { enabled: false },
                        chart: {
                            type: 'line',
                            renderTo: "chart_container1",
                            plotBackgroundImage: 'assets/img/conception.png',
                            height: 230,
                            width: 604,
                            //axisLabelPadding: 5

                        },
                        plotOptions: {
                            line: {
                                marker: {
                                    enabled: false
                                }
                            }
                        },
                        legend: {
                            enabled: false
                        },
                        xAxis: {
                            visible: false,
                            gridLineWidth: 0,
                            minorGridLineWidth: 0,
                            labels:
                            {
                                lineColor: 'transparent',
                                enabled: false
                            }
                        },
                        series: [
                            {
                                marker: {
                                    symbol: ''
                                },
                                data: [
                                    0, 0, 0, {
                                        y: 0,
                                        color: '#BF0B23',
                                        marker: {
                                            enabled: true,
                                            fillColor: 'red'
                                        }
                                    }, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
                                ]
                            }
                        ]

                    });

                }
                catch (error) {
                    

                }
            });
        }

        if (chk_count == 5) {
            $(document).ready(function(){
                try {
                    var aChart1 = new Highcharts.Chart({
                        tooltip: { enabled: false },
                        chart: {
                            type: 'line',
                            renderTo: "chart_container1",
                            plotBackgroundImage: 'assets/img/conception.png',
                            height: 230,
                            width: 604,
                            //axisLabelPadding: 5

                        },
                        plotOptions: {
                            line: {
                                marker: {
                                    enabled: false
                                }
                            }
                        },
                        xAxis: {
                            visible: false,
                            gridLineWidth: 0,
                            minorGridLineWidth: 0,
                            labels:
                            {
                                lineColor: 'transparent',
                                enabled: false
                            }
                        },
                        legend: {
                            enabled: false
                        },
                        series: [
                            {
                                marker: {
                                    symbol: ''
                                },
                                //marker: {
                                //    symbol: 'url(https://www.highcharts.com/samples/graphics/sun.png)',
                                //    width: 16,
                                //    height: 16
                                //}, 

                                data: [
                                    0, 0, 0, 0, {
                                        y: 0,
                                        color: '#BF0B23',
                                        marker: {
                                            enabled: true,
                                            fillColor: 'red'
                                        }
                                    }, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
                                ]
                            }
                        ]

                    });

                }
                catch (error) {

                }
            });
        }

        if (chk_count == 6) {
            $(document).ready(function(){
                try {
                    var aChart1 = new Highcharts.Chart({
                        tooltip: { enabled: false },
                        chart: {
                            type: 'line',
                            renderTo: "chart_container1",
                            plotBackgroundImage: 'assets/img/conception.png',
                            height: 230,
                            width: 604,
                            //axisLabelPadding: 5

                        },
                        plotOptions: {
                            line: {
                                marker: {
                                    enabled: false
                                }
                            }
                        },
                        xAxis: {
                            visible: false,
                            gridLineWidth: 0,
                            minorGridLineWidth: 0,
                            labels:
                            {
                                lineColor: 'transparent',
                                enabled: false
                            }
                        },
                        legend: {
                            enabled: false
                        },
                        series: [
                            {
                                marker: {
                                    symbol: ''
                                },
                                //marker: {
                                //    symbol: 'url(https://www.highcharts.com/samples/graphics/sun.png)',
                                //    width: 16,
                                //    height: 16
                                //}, 

                                data: [
                                    0, 0, 0, 0, 0, {
                                        y: 0,
                                        color: '#BF0B23',
                                        marker: {
                                            enabled: true,
                                            fillColor: 'red'
                                        }
                                    }, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
                                ]
                            }
                        ]

                    });

                }
                catch (error) {

                }
            });
        }

        if (chk_count == 7) {
            $(document).ready(function(){
                try {
                    var aChart1 = new Highcharts.Chart({
                        tooltip: { enabled: false },
                        chart: {
                            type: 'line',
                            renderTo: "chart_container1",
                            plotBackgroundImage: 'assets/img/conception.png',
                            height: 230,
                            width: 604,
                            //axisLabelPadding: 5

                        },
                        plotOptions: {
                            line: {
                                marker: {
                                    enabled: false
                                }
                            }
                        },
                        xAxis: {
                            visible: false,
                            gridLineWidth: 0,
                            minorGridLineWidth: 0,
                            labels:
                            {
                                lineColor: 'transparent',
                                enabled: false
                            }
                        },
                        legend: {
                            enabled: false
                        },
                        series: [
                            {
                                marker: {
                                    symbol: ''
                                },
                                //marker: {
                                //    symbol: 'url(https://www.highcharts.com/samples/graphics/sun.png)',
                                //    width: 16,
                                //    height: 16
                                //}, 

                                data: [
                                    0, 0, 0, 0, 0, 0, {
                                        y: 0,
                                        color: '#BF0B23',
                                        marker: {
                                            enabled: true,
                                            fillColor: 'red'
                                        }
                                    }, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
                                ]
                            }
                        ]

                    });

                }
                catch (error) {

                }
            });
        }

        if (chk_count == 8) {
            $(document).ready(function(){
                try {
                    var aChart1 = new Highcharts.Chart({
                        tooltip: { enabled: false },
                        chart: {
                            type: 'line',
                            renderTo: "chart_container1",
                            plotBackgroundImage: 'assets/img/conception.png',
                            height: 230,
                            width: 604,
                            //axisLabelPadding: 5

                        },
                        plotOptions: {
                            line: {
                                marker: {
                                    enabled: false
                                }
                            }
                        },
                        xAxis: {
                            visible: false,
                            gridLineWidth: 0,
                            minorGridLineWidth: 0,
                            labels:
                            {
                                lineColor: 'transparent',
                                enabled: false
                            }
                        },
                        legend: {
                            enabled: false
                        },
                        series: [
                            {
                                marker: {
                                    symbol: ''
                                },
                                //marker: {
                                //    symbol: 'url(https://www.highcharts.com/samples/graphics/sun.png)',
                                //    width: 16,
                                //    height: 16
                                //}, 

                                data: [
                                    0, 0, 0, 0, 0, 0, 0, {
                                        y: 0,
                                        color: '#BF0B23',
                                        marker: {
                                            enabled: true,
                                            fillColor: 'red'
                                        }
                                    }, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                ]
                            }
                        ]

                    });

                }
                catch (error) {

                }
            });
        }

        if (chk_count == 9) {
            $(document).ready(function(){
                try {
                    var aChart1 = new Highcharts.Chart({
                        tooltip: { enabled: false },
                        chart: {
                            type: 'line',
                            renderTo: "chart_container1",
                            plotBackgroundImage: 'assets/img/conception.png',
                            height: 230,
                            width: 604,
                            //axisLabelPadding: 5

                        },
                        plotOptions: {
                            line: {
                                marker: {
                                    enabled: false
                                }
                            }
                        },
                        xAxis: {
                            visible: false,
                            gridLineWidth: 0,
                            minorGridLineWidth: 0,
                            labels:
                            {
                                lineColor: 'transparent',
                                enabled: false
                            }
                        },
                        legend: {
                            enabled: false
                        },
                        series: [
                            {
                                marker: {
                                    symbol: ''
                                },
                                //marker: {
                                //    symbol: 'url(https://www.highcharts.com/samples/graphics/sun.png)',
                                //    width: 16,
                                //    height: 16
                                //}, 

                                data: [
                                    0, 0, 0, 0, 0, 0, 0, {
                                        y: 0,
                                        color: '#BF0B23',
                                        marker: {
                                            enabled: true,
                                            fillColor: 'red'
                                        }
                                    }, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                ]
                            }
                        ]

                    });

                }
                catch (error) {

                }
            });
        }

        if (chk_count == 10) {
            $(document).ready(function(){
                try {
                    var aChart1 = new Highcharts.Chart({
                        tooltip: { enabled: false },
                        chart: {
                            type: 'line',
                            renderTo: "chart_container1",
                            plotBackgroundImage: 'assets/img/conception.png',
                            height: 230,
                            width: 604,
                            //axisLabelPadding: 5

                        },
                        plotOptions: {
                            line: {
                                marker: {
                                    enabled: false
                                }
                            }
                        },
                        xAxis: {
                            visible: false,
                            gridLineWidth: 0,
                            minorGridLineWidth: 0,
                            labels:
                            {
                                lineColor: 'transparent',
                                enabled: false
                            }
                        },
                        legend: {
                            enabled: false
                        },
                        series: [
                            {
                                marker: {
                                    symbol: ''
                                },
                                //marker: {
                                //    symbol: 'url(https://www.highcharts.com/samples/graphics/sun.png)',
                                //    width: 16,
                                //    height: 16
                                //}, 

                                data: [
                                    0, 0, 0, 0, 0, 0, 0, 0, {
                                        y: 0,
                                        color: '#BF0B23',
                                        marker: {
                                            enabled: true,
                                            fillColor: 'red'
                                        }
                                    }, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                ]
                            }
                        ]

                    });

                }
                catch (error) {

                }
            });
        }

        if (chk_count == 11) {
            $(document).ready(function(){
                try {
                    var aChart1 = new Highcharts.Chart({
                        tooltip: { enabled: false },
                        chart: {
                            type: 'line',
                            renderTo: "chart_container1",
                            plotBackgroundImage: 'assets/img/conception.png',
                            height: 230,
                            width: 604,
                            //axisLabelPadding: 5

                        },
                        plotOptions: {
                            line: {
                                marker: {
                                    enabled: false
                                }
                            }
                        },
                        xAxis: {
                            visible: false,
                            gridLineWidth: 0,
                            minorGridLineWidth: 0,
                            labels:
                            {
                                lineColor: 'transparent',
                                enabled: false
                            }
                        },
                        legend: {
                            enabled: false
                        },
                        series: [
                            {
                                marker: {
                                    symbol: ''
                                },
                                //marker: {
                                //    symbol: 'url(https://www.highcharts.com/samples/graphics/sun.png)',
                                //    width: 16,
                                //    height: 16
                                //}, 

                                data: [
                                    0, 0, 0, 0, 0, 0, 0, 0, 0, {
                                        y: 0,
                                        color: '#BF0B23',
                                        marker: {
                                            enabled: true,
                                            fillColor: 'red'
                                        }
                                    }, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                ]
                            }
                        ]
                    });
                }
                catch (error) {

                }
            });
        }

        if (chk_count == 12) {
            $(document).ready(function(){
                try {
                    var aChart1 = new Highcharts.Chart({
                        tooltip: { enabled: false },
                        chart: {
                            type: 'line',
                            renderTo: "chart_container1",
                            plotBackgroundImage: 'assets/img/conception.png',
                            height: 230,
                            width: 604,
                            //axisLabelPadding: 5

                        },
                        plotOptions: {
                            line: {
                                marker: {
                                    enabled: false
                                }
                            }
                        },
                        xAxis: {
                            visible: false,
                            gridLineWidth: 0,
                            minorGridLineWidth: 0,
                            labels:
                            {
                                lineColor: 'transparent',
                                enabled: false
                            }
                        },
                        legend: {
                            enabled: false
                        },
                        series: [
                            {
                                marker: {
                                    symbol: ''
                                },
                                //marker: {
                                //    symbol: 'url(https://www.highcharts.com/samples/graphics/sun.png)',
                                //    width: 16,
                                //    height: 16
                                //}, 

                                data: [
                                    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, {
                                        y: 0,
                                        color: '#BF0B23',
                                        marker: {
                                            enabled: true,
                                            fillColor: 'red'
                                        }
                                    }, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
                                ]
                            }
                        ]
                    });
                }
                catch (error) {

                }
            });
        }

        if (chk_count == 13) {
            $(document).ready(function(){
                try {
                    var aChart1 = new Highcharts.Chart({
                        tooltip: { enabled: false },
                        chart: {
                            type: 'line',
                            renderTo: "chart_container1",
                            plotBackgroundImage: 'assets/img/conception.png',
                            height: 230,
                            width: 604,
                            //axisLabelPadding: 5

                        },
                        plotOptions: {
                            line: {
                                marker: {
                                    enabled: false
                                }
                            }
                        },
                        xAxis: {
                            visible: false,
                            gridLineWidth: 0,
                            minorGridLineWidth: 0,
                            labels:
                            {
                                lineColor: 'transparent',
                                enabled: false
                            }
                        },
                        legend: {
                            enabled: false
                        },
                        series: [
                            {
                                marker: {
                                    symbol: ''
                                },
                                //marker: {
                                //    symbol: 'url(https://www.highcharts.com/samples/graphics/sun.png)',
                                //    width: 16,
                                //    height: 16
                                //}, 

                                data: [
                                    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, {
                                        y: 0,
                                        color: '#BF0B23',
                                        marker: {
                                            enabled: true,
                                            fillColor: 'red'
                                        }
                                    }, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
                                ]
                            }
                        ]
                    });
                }
                catch (error) {

                }
            });
        }

        if (chk_count == 14) {
            $(document).ready(function(){
                try {
                    var aChart1 = new Highcharts.Chart({
                        tooltip: { enabled: false },
                        chart: {
                            type: 'line',
                            renderTo: "chart_container1",
                            plotBackgroundImage: 'assets/img/conception.png',
                            height: 230,
                            width: 604,
                            //axisLabelPadding: 5

                        },
                        plotOptions: {
                            line: {
                                marker: {
                                    enabled: false
                                }
                            }
                        },
                        xAxis: {
                            visible: false,
                            gridLineWidth: 0,
                            minorGridLineWidth: 0,
                            labels:
                            {
                                lineColor: 'transparent',
                                enabled: false
                            }
                        },
                        legend: {
                            enabled: false
                        },
                        series: [
                            {
                                marker: {
                                    symbol: ''
                                },
                                //marker: {
                                //    symbol: 'url(https://www.highcharts.com/samples/graphics/sun.png)',
                                //    width: 16,
                                //    height: 16
                                //}, 

                                data: [
                                    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, {
                                        y: 0,
                                        color: '#BF0B23',
                                        marker: {
                                            enabled: true,
                                            fillColor: 'red'
                                        }
                                    }, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
                                ]
                            }
                        ]
                    });
                }
                catch (error) {

                }
            });
        }

        if (chk_count == 15) {
            $(document).ready(function(){
                try {
                    var aChart1 = new Highcharts.Chart({
                        tooltip: { enabled: false },
                        chart: {
                            type: 'line',
                            renderTo: "chart_container1",
                            plotBackgroundImage: 'assets/img/conception.png',
                            height: 230,
                            width: 604,
                            //axisLabelPadding: 5

                        },
                        plotOptions: {
                            line: {
                                marker: {
                                    enabled: false
                                }
                            }
                        },
                        xAxis: {
                            visible: false,
                            gridLineWidth: 0,
                            minorGridLineWidth: 0,
                            labels:
                            {
                                lineColor: 'transparent',
                                enabled: false
                            }
                        },
                        legend: {
                            enabled: false
                        },
                        series: [
                            {
                                marker: {
                                    symbol: ''
                                },
                                //marker: {
                                //    symbol: 'url(https://www.highcharts.com/samples/graphics/sun.png)',
                                //    width: 16,
                                //    height: 16
                                //}, 

                                data: [
                                    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, {
                                        y: 0,
                                        color: '#BF0B23',
                                        marker: {
                                            enabled: true,
                                            fillColor: 'red'
                                        }
                                    }, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
                                ]
                            }
                        ]
                    });
                }
                catch (error) {

                }
            });
        }

        if (chk_count == 16) {
            $(document).ready(function(){
                try {
                    var aChart1 = new Highcharts.Chart({
                        tooltip: { enabled: false },
                        chart: {
                            type: 'line',
                            renderTo: "chart_container1",
                            plotBackgroundImage: 'assets/img/conception.png',
                            height: 230,
                            width: 604,
                            //axisLabelPadding: 5

                        },
                        plotOptions: {
                            line: {
                                marker: {
                                    enabled: false
                                }
                            }
                        },
                        xAxis: {
                            visible: false,
                            gridLineWidth: 0,
                            minorGridLineWidth: 0,
                            labels:
                            {
                                lineColor: 'transparent',
                                enabled: false
                            }
                        },
                        legend: {
                            enabled: false
                        },
                        series: [
                            {
                                marker: {
                                    symbol: ''
                                },
                                //marker: {
                                //    symbol: 'url(https://www.highcharts.com/samples/graphics/sun.png)',
                                //    width: 16,
                                //    height: 16
                                //}, 

                                data: [
                                    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, {
                                        y: 0,
                                        color: '#BF0B23',
                                        marker: {
                                            enabled: true,
                                            fillColor: 'red'
                                        }
                                    }, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
                                ]
                            }
                        ]
                    });
                }
                catch (error) {

                }
            });
        }

        if (chk_count == 17) {
            $(document).ready(function(){
                try {
                    var aChart1 = new Highcharts.Chart({
                        tooltip: { enabled: false },
                        chart: {
                            type: 'line',
                            renderTo: "chart_container1",
                            plotBackgroundImage: 'assets/img/conception.png',
                            height: 230,
                            width: 604,
                            //axisLabelPadding: 5

                        },
                        plotOptions: {
                            line: {
                                marker: {
                                    enabled: false
                                }
                            }
                        },
                        xAxis: {
                            visible: false,
                            gridLineWidth: 0,
                            minorGridLineWidth: 0,
                            labels:
                            {
                                lineColor: 'transparent',
                                enabled: false
                            }
                        },
                        legend: {
                            enabled: false
                        },
                        series: [
                            {
                                marker: {
                                    symbol: ''
                                },
                                //marker: {
                                //    symbol: 'url(https://www.highcharts.com/samples/graphics/sun.png)',
                                //    width: 16,
                                //    height: 16
                                //}, 

                                data: [
                                    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, {
                                        y: 0,
                                        color: '#BF0B23',
                                        marker: {
                                            enabled: true,
                                            fillColor: 'red'
                                        }
                                    }, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
                                ]
                            }
                        ]

                    });

                }
                catch (error) {

                }
            });
        }

        if (chk_count == 18) {
            $(document).ready(function(){
                try {
                    var aChart1 = new Highcharts.Chart({
                        tooltip: { enabled: false },
                        chart: {
                            type: 'line',
                            renderTo: "chart_container1",
                            plotBackgroundImage: 'assets/img/conception.png',
                            height: 230,
                            width: 604,
                            //axisLabelPadding: 5

                        },
                        plotOptions: {
                            line: {
                                marker: {
                                    enabled: false
                                }
                            }
                        },
                        xAxis: {
                            visible: false,
                            gridLineWidth: 0,
                            minorGridLineWidth: 0,
                            labels:
                            {
                                lineColor: 'transparent',
                                enabled: false
                            }
                        },
                        legend: {
                            enabled: false
                        },
                        series: [
                            {
                                marker: {
                                    symbol: ''
                                },
                                //marker: {
                                //    symbol: 'url(https://www.highcharts.com/samples/graphics/sun.png)',
                                //    width: 16,
                                //    height: 16
                                //}, 

                                data: [
                                    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, {
                                        y: 0,
                                        color: '#BF0B23',
                                        marker: {
                                            enabled: true,
                                            fillColor: 'red'
                                        }
                                    }, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
                                ]
                            }
                        ]

                    });

                }
                catch (error) {

                }
            });
        }

        if (chk_count == 19) {
            $(document).ready(function(){
                try {
                    var aChart1 = new Highcharts.Chart({
                        tooltip: { enabled: false },
                        chart: {
                            type: 'line',
                            renderTo: "chart_container1",
                            plotBackgroundImage: 'assets/img/conception.png',
                            height: 230,
                            width: 604,
                            //axisLabelPadding: 5

                        },
                        plotOptions: {
                            line: {
                                marker: {
                                    enabled: false
                                }
                            }
                        },
                        xAxis: {
                            visible: false,
                            gridLineWidth: 0,
                            minorGridLineWidth: 0,
                            labels:
                            {
                                lineColor: 'transparent',
                                enabled: false
                            }
                        },
                        legend: {
                            enabled: false
                        },
                        series: [
                            {
                                marker: {
                                    symbol: ''
                                },
                                //marker: {
                                //    symbol: 'url(https://www.highcharts.com/samples/graphics/sun.png)',
                                //    width: 16,
                                //    height: 16
                                //}, 

                                data: [
                                    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, {
                                        y: 0,
                                        color: '#BF0B23',
                                        marker: {
                                            enabled: true,
                                            fillColor: 'red'
                                        }
                                    }, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
                                ]
                            }
                        ]

                    });

                }
                catch (error) {

                }
            });
        }
        
        if (chk_count == 20) {
            $(document).ready(function(){
                try {
                    var aChart1 = new Highcharts.Chart({
                        tooltip: { enabled: false },
                        chart: {
                            type: 'line',
                            renderTo: "chart_container1",
                            plotBackgroundImage: 'assets/img/conception.png',
                            height: 230,
                            width: 604,
                            //axisLabelPadding: 5

                        },
                        plotOptions: {
                            line: {
                                marker: {
                                    enabled: false
                                }
                            }
                        },
                        xAxis: {
                            visible: false,
                            gridLineWidth: 0,
                            minorGridLineWidth: 0,
                            labels:
                            {
                                lineColor: 'transparent',
                                enabled: false
                            }
                        },
                        legend: {
                            enabled: false
                        },
                        series: [
                            {
                                marker: {
                                    symbol: ''
                                },
                                //marker: {
                                //    symbol: 'url(https://www.highcharts.com/samples/graphics/sun.png)',
                                //    width: 16,
                                //    height: 16
                                //}, 

                                data: [
                                    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, {
                                        y: 0,
                                        color: '#BF0B23',
                                        marker: {
                                            enabled: true,
                                            fillColor: 'red'
                                        }
                                    }, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                ]
                            }
                        ]

                    });

                }
                catch (error) {

                }
            });
        }

        if (chk_count == 21) {
            $(document).ready(function(){
                try {
                    var aChart1 = new Highcharts.Chart({
                        tooltip: { enabled: false },
                        chart: {
                            type: 'line',
                            renderTo: "chart_container1",
                            plotBackgroundImage: 'assets/img/conception.png',
                            height: 230,
                            width: 604,
                            //axisLabelPadding: 5

                        },
                        plotOptions: {
                            line: {
                                marker: {
                                    enabled: false
                                }
                            }
                        },
                        xAxis: {
                            visible: false,
                            gridLineWidth: 0,
                            minorGridLineWidth: 0,
                            labels:
                            {
                                lineColor: 'transparent',
                                enabled: false
                            }
                        },
                        legend: {
                            enabled: false
                        },
                        series: [
                            {
                                marker: {
                                    symbol: ''
                                },
                                //marker: {
                                //    symbol: 'url(https://www.highcharts.com/samples/graphics/sun.png)',
                                //    width: 16,
                                //    height: 16
                                //}, 

                                data: [
                                    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, {
                                        y: 0,
                                        color: '#BF0B23',
                                        marker: {
                                            enabled: true,
                                            fillColor: 'red'
                                        }
                                    }, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                ]
                            }
                        ]

                    });

                }
                catch (error) {

                }
            });
        }

        if (chk_count == 22) {
            $(document).ready(function(){
                try {
                    var aChart1 = new Highcharts.Chart({
                        tooltip: { enabled: false },
                        chart: {
                            type: 'line',
                            renderTo: "chart_container1",
                            plotBackgroundImage: 'assets/img/conception.png',
                            height: 230,
                            width: 604,
                            //axisLabelPadding: 5

                        },
                        plotOptions: {
                            line: {
                                marker: {
                                    enabled: false
                                }
                            }
                        },
                        xAxis: {
                            visible: false,
                            gridLineWidth: 0,
                            minorGridLineWidth: 0,
                            labels:
                            {
                                lineColor: 'transparent',
                                enabled: false
                            }
                        },
                        legend: {
                            enabled: false
                        },
                        series: [
                            {
                                marker: {
                                    symbol: ''
                                },
                                //marker: {
                                //    symbol: 'url(https://www.highcharts.com/samples/graphics/sun.png)',
                                //    width: 16,
                                //    height: 16
                                //}, 

                                data: [
                                    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, {
                                        y: 0,
                                        color: '#BF0B23',
                                        marker: {
                                            enabled: true,
                                            fillColor: 'red'
                                        }
                                    }, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                ]
                            }
                        ]

                    });

                }
                catch (error) {

                }
            });
        }

        if (chk_count == 23) {
            $(document).ready(function(){
                try {
                    var aChart1 = new Highcharts.Chart({
                        tooltip: { enabled: false },
                        chart: {
                            type: 'line',
                            renderTo: "chart_container1",
                            plotBackgroundImage: 'assets/img/conception.png',
                            height: 230,
                            width: 604,
                            //axisLabelPadding: 5

                        },
                        plotOptions: {
                            line: {
                                marker: {
                                    enabled: false
                                }
                            }
                        },
                        xAxis: {
                            visible: false,
                            gridLineWidth: 0,
                            minorGridLineWidth: 0,
                            labels:
                            {
                                lineColor: 'transparent',
                                enabled: false
                            }
                        },
                        legend: {
                            enabled: false
                        },
                        series: [
                            {
                                marker: {
                                    symbol: ''
                                },
                                //marker: {
                                //    symbol: 'url(https://www.highcharts.com/samples/graphics/sun.png)',
                                //    width: 16,
                                //    height: 16
                                //}, 

                                data: [
                                    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, {
                                        y: 0,
                                        color: '#BF0B23',
                                        marker: {
                                            enabled: true,
                                            fillColor: 'red'
                                        }
                                    }, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                ]
                            }
                        ]

                    });

                }
                catch (error) {

                }
            });
        }

        if (chk_count == 24) {
            $(document).ready(function(){
                try {
                    var aChart1 = new Highcharts.Chart({
                        tooltip: { enabled: false },
                        chart: {
                            type: 'line',
                            renderTo: "chart_container1",
                            plotBackgroundImage: 'assets/img/conception.png',
                            height: 230,
                            width: 604,
                            //axisLabelPadding: 5

                        },
                        plotOptions: {
                            line: {
                                marker: {
                                    enabled: false
                                }
                            }
                        },
                        xAxis: {
                            visible: false,
                            gridLineWidth: 0,
                            minorGridLineWidth: 0,
                            labels:
                            {
                                lineColor: 'transparent',
                                enabled: false
                            }
                        },
                        legend: {
                            enabled: false
                        },
                        series: [
                            {
                                marker: {
                                    symbol: ''
                                },
                                //marker: {
                                //    symbol: 'url(https://www.highcharts.com/samples/graphics/sun.png)',
                                //    width: 16,
                                //    height: 16
                                //}, 

                                data: [
                                    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, {
                                        y: 0,
                                        color: '#BF0B23',
                                        marker: {
                                            enabled: true,
                                            fillColor: 'red'
                                        }
                                    }, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                ]
                            }
                        ]

                    });

                }
                catch (error) {

                }
            });
        }

        if (chk_count == 25) {
            $(document).ready(function(){
                try {
                    var aChart1 = new Highcharts.Chart({
                        tooltip: { enabled: false },
                        chart: {
                            type: 'line',
                            renderTo: "chart_container1",
                            plotBackgroundImage: 'assets/img/conception.png',
                            height: 230,
                            width: 604,
                            //axisLabelPadding: 5

                        },
                        plotOptions: {
                            line: {
                                marker: {
                                    enabled: false
                                }
                            }
                        },
                        xAxis: {
                            visible: false,
                            gridLineWidth: 0,
                            minorGridLineWidth: 0,
                            labels:
                            {
                                lineColor: 'transparent',
                                enabled: false
                            }
                        },
                        legend: {
                            enabled: false
                        },
                        series: [
                            {
                                marker: {
                                    symbol: ''
                                },
                                //marker: {
                                //    symbol: 'url(https://www.highcharts.com/samples/graphics/sun.png)',
                                //    width: 16,
                                //    height: 16
                                //}, 

                                data: [
                                    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, {
                                        y: 0,
                                        color: '#BF0B23',
                                        marker: {
                                            enabled: true,
                                            fillColor: 'red'
                                        }
                                    }, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                ]
                            }
                        ]

                    });

                }
                catch (error) {

                }
            });
        }

        if (chk_count == 26) {
            $(document).ready(function(){
                try {
                    var aChart1 = new Highcharts.Chart({
                        tooltip: { enabled: false },
                        chart: {
                            type: 'line',
                            renderTo: "chart_container1",
                            plotBackgroundImage: 'assets/img/conception.png',
                            height: 230,
                            width: 604,
                            //axisLabelPadding: 5

                        },
                        plotOptions: {
                            line: {
                                marker: {
                                    enabled: false
                                }
                            }
                        },
                        xAxis: {
                            visible: false,
                            gridLineWidth: 0,
                            minorGridLineWidth: 0,
                            labels:
                            {
                                lineColor: 'transparent',
                                enabled: false
                            }
                        },
                        legend: {
                            enabled: false
                        },
                        series: [
                            {
                                marker: {
                                    symbol: ''
                                },
                                //marker: {
                                //    symbol: 'url(https://www.highcharts.com/samples/graphics/sun.png)',
                                //    width: 16,
                                //    height: 16
                                //}, 

                                data: [
                                    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, {
                                        y: 0,
                                        color: '#BF0B23',
                                        marker: {
                                            enabled: true,
                                            fillColor: 'red'
                                        }
                                    }, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                ]
                            }
                        ]

                    });

                }
                catch (error) {

                }
            });
        }

        if (chk_count == 27) {
            $(document).ready(function(){
                try {
                    var aChart1 = new Highcharts.Chart({
                        tooltip: { enabled: false },
                        chart: {
                            type: 'line',
                            renderTo: "chart_container1",
                            plotBackgroundImage: 'assets/img/conception.png',
                            height: 230,
                            width: 604,
                            //axisLabelPadding: 5

                        },
                        plotOptions: {
                            line: {
                                marker: {
                                    enabled: false
                                }
                            }
                        },
                        xAxis: {
                            visible: false,
                            gridLineWidth: 0,
                            minorGridLineWidth: 0,
                            labels:
                            {
                                lineColor: 'transparent',
                                enabled: false
                            }
                        },
                        legend: {
                            enabled: false
                        },
                        series: [
                            {
                                marker: {
                                    symbol: ''
                                },
                                //marker: {
                                //    symbol: 'url(https://www.highcharts.com/samples/graphics/sun.png)',
                                //    width: 16,
                                //    height: 16
                                //}, 

                                data: [
                                    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, {
                                        y: 0,
                                        color: '#BF0B23',
                                        marker: {
                                            enabled: true,
                                            fillColor: 'red'
                                        }
                                    }, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                ]
                            }
                        ]

                    });

                }
                catch (error) {

                }
            });
        }

        if (chk_count == 28) {
            $(document).ready(function(){
                try {
                    var aChart1 = new Highcharts.Chart({
                        tooltip: { enabled: false },
                        chart: {
                            type: 'line',
                            renderTo: "chart_container1",
                            plotBackgroundImage: 'assets/img/conception.png',
                            height: 230,
                            width: 604,
                            //axisLabelPadding: 5

                        },
                        plotOptions: {
                            line: {
                                marker: {
                                    enabled: false
                                }
                            }
                        },
                        xAxis: {
                            visible: false,
                            gridLineWidth: 0,
                            minorGridLineWidth: 0,
                            labels:
                            {
                                lineColor: 'transparent',
                                enabled: false
                            }
                        },
                        legend: {
                            enabled: false
                        },
                        series: [
                            {
                                marker: {
                                    symbol: ''
                                },
                                //marker: {
                                //    symbol: 'url(https://www.highcharts.com/samples/graphics/sun.png)',
                                //    width: 16,
                                //    height: 16
                                //}, 

                                data: [
                                    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, {
                                        y: 0,
                                        color: '#BF0B23',
                                        marker: {
                                            enabled: true,
                                            fillColor: 'red'
                                        }
                                    }, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                ]
                            }
                        ]

                    });

                }
                catch (error) {

                }
            });
        }

        if (chk_count == 29) {
            $(document).ready(function(){
                try {
                    var aChart1 = new Highcharts.Chart({
                        tooltip: { enabled: false },
                        chart: {
                            type: 'line',
                            renderTo: "chart_container1",
                            plotBackgroundImage: 'assets/img/conception.png',
                            height: 230,
                            width: 604,
                            //axisLabelPadding: 5

                        },
                        plotOptions: {
                            line: {
                                marker: {
                                    enabled: false
                                }
                            }
                        },
                        xAxis: {
                            visible: false,
                            gridLineWidth: 0,
                            minorGridLineWidth: 0,
                            labels:
                            {
                                lineColor: 'transparent',
                                enabled: false
                            }
                        },
                        legend: {
                            enabled: false
                        },
                        series: [
                            {
                                marker: {
                                    symbol: ''
                                },
                                //marker: {
                                //    symbol: 'url(https://www.highcharts.com/samples/graphics/sun.png)',
                                //    width: 16,
                                //    height: 16
                                //}, 

                                data: [
                                    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, {
                                        y: 0,
                                        color: '#BF0B23',
                                        marker: {
                                            enabled: true,
                                            fillColor: 'red'
                                        }
                                    }, 0, 0, 0, 0, 0, 0, 0
                                ]
                            }
                        ]

                    });

                }
                catch (error) {

                }
            });
        }

        if (chk_count == 30) {
            $(document).ready(function(){
                try {
                    var aChart1 = new Highcharts.Chart({
                        tooltip: { enabled: false },
                        chart: {
                            type: 'line',
                            renderTo: "chart_container1",
                            plotBackgroundImage: 'assets/img/conception.png',
                            height: 230,
                            width: 604,
                            //axisLabelPadding: 5

                        },
                        plotOptions: {
                            line: {
                                marker: {
                                    enabled: false
                                }
                            }
                        },
                        xAxis: {
                            visible: false,
                            gridLineWidth: 0,
                            minorGridLineWidth: 0,
                            labels:
                            {
                                lineColor: 'transparent',
                                enabled: false
                            }
                        },
                        legend: {
                            enabled: false
                        },
                        series: [
                            {
                                marker: {
                                    symbol: ''
                                },
                                //marker: {
                                //    symbol: 'url(https://www.highcharts.com/samples/graphics/sun.png)',
                                //    width: 16,
                                //    height: 16
                                //}, 

                                data: [
                                    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, {
                                        y: 0,
                                        color: '#BF0B23',
                                        marker: {
                                            enabled: true,
                                            fillColor: 'red'
                                        }
                                    }, 0, 0, 0, 0, 0, 0, 0
                                ]
                            }
                        ]

                    });

                }
                catch (error) {

                }
            });
        }

        if (chk_count == 31) {
            $(document).ready(function(){
                try {
                    var aChart1 = new Highcharts.Chart({
                        tooltip: { enabled: false },
                        chart: {
                            type: 'line',
                            renderTo: "chart_container1",
                            plotBackgroundImage: 'assets/img/conception.png',
                            height: 230,
                            width: 604,
                            //axisLabelPadding: 5

                        },
                        plotOptions: {
                            line: {
                                marker: {
                                    enabled: false
                                }
                            }
                        },
                        xAxis: {
                            visible: false,
                            gridLineWidth: 0,
                            minorGridLineWidth: 0,
                            labels:
                            {
                                lineColor: 'transparent',
                                enabled: false
                            }
                        },
                        legend: {
                            enabled: false
                        },
                        series: [
                            {
                                marker: {
                                    symbol: ''
                                },
                                //marker: {
                                //    symbol: 'url(https://www.highcharts.com/samples/graphics/sun.png)',
                                //    width: 16,
                                //    height: 16
                                //}, 

                                data: [
                                    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, {
                                        y: 0,
                                        color: '#BF0B23',
                                        marker: {
                                            enabled: true,
                                            fillColor: 'red'
                                        }
                                    }, 0, 0, 0, 0, 0, 0, 0
                                ]
                            }
                        ]

                    });

                }
                catch (error) {

                }
            });
        }

        if (chk_count == 32) {
            $(document).ready(function(){
                try {
                    var aChart1 = new Highcharts.Chart({
                        tooltip: { enabled: false },
                        chart: {
                            type: 'line',
                            renderTo: "chart_container1",
                            plotBackgroundImage: 'assets/img/conception.png',
                            height: 230,
                            width: 604,
                            //axisLabelPadding: 5

                        },
                        plotOptions: {
                            line: {
                                marker: {
                                    enabled: false
                                }
                            }
                        },
                        xAxis: {
                            visible: false,
                            gridLineWidth: 0,
                            minorGridLineWidth: 0,
                            labels:
                            {
                                lineColor: 'transparent',
                                enabled: false
                            }
                        },
                        legend: {
                            enabled: false
                        },
                        series: [
                            {
                                marker: {
                                    symbol: ''
                                },
                                //marker: {
                                //    symbol: 'url(https://www.highcharts.com/samples/graphics/sun.png)',
                                //    width: 16,
                                //    height: 16
                                //}, 

                                data: [
                                    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, {
                                        y: 0,
                                        color: '#BF0B23',
                                        marker: {
                                            enabled: true,
                                            fillColor: 'red'
                                        }
                                    }, 0, 0, 0, 0, 0, 0, 0
                                ]
                            }
                        ]

                    });

                }
                catch (error) {

                }
            });
        }

        if (chk_count == 33) {
            $(document).ready(function(){
                try {
                    var aChart1 = new Highcharts.Chart({
                        tooltip: { enabled: false },
                        chart: {
                            type: 'line',
                            renderTo: "chart_container1",
                            plotBackgroundImage: 'assets/img/conception.png',
                            height: 230,
                            width: 604,
                            //axisLabelPadding: 5

                        },
                        plotOptions: {
                            line: {
                                marker: {
                                    enabled: false
                                }
                            }
                        },
                        xAxis: {
                            visible: false,
                            gridLineWidth: 0,
                            minorGridLineWidth: 0,
                            labels:
                            {
                                lineColor: 'transparent',
                                enabled: false
                            }
                        },
                        legend: {
                            enabled: false
                        },
                        series: [
                            {
                                marker: {
                                    symbol: ''
                                },
                                //marker: {
                                //    symbol: 'url(https://www.highcharts.com/samples/graphics/sun.png)',
                                //    width: 16,
                                //    height: 16
                                //}, 

                                data: [
                                    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, {
                                        y: 0,
                                        color: '#BF0B23',
                                        marker: {
                                            enabled: true,
                                            fillColor: 'red'
                                        }
                                    }, 0, 0, 0, 0, 0, 0, 0
                                ]
                            }
                        ]

                    });

                }
                catch (error) {

                }
            });
        }

        if (chk_count == 34) {
            $(document).ready(function(){
                try {
                    var aChart1 = new Highcharts.Chart({
                        tooltip: { enabled: false },
                        chart: {
                            type: 'line',
                            renderTo: "chart_container1",
                            plotBackgroundImage: 'assets/img/conception.png',
                            height: 230,
                            width: 604,
                            //axisLabelPadding: 5

                        },
                        plotOptions: {
                            line: {
                                marker: {
                                    enabled: false
                                }
                            }
                        },
                        xAxis: {
                            visible: false,
                            gridLineWidth: 0,
                            minorGridLineWidth: 0,
                            labels:
                            {
                                lineColor: 'transparent',
                                enabled: false
                            }
                        },
                        legend: {
                            enabled: false
                        },
                        series: [
                            {
                                marker: {
                                    symbol: ''
                                },
                                //marker: {
                                //    symbol: 'url(https://www.highcharts.com/samples/graphics/sun.png)',
                                //    width: 16,
                                //    height: 16
                                //}, 

                                data: [
                                    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, {
                                        y: 0,
                                        color: '#BF0B23',
                                        marker: {
                                            enabled: true,
                                            fillColor: 'red'
                                        }
                                    }, 0, 0, 0, 0, 0, 0, 0
                                ]
                            }
                        ]

                    });

                }
                catch (error) {

                }
            });
        }

        if (chk_count == 35) {
            $(document).ready(function(){
                try {
                    var aChart1 = new Highcharts.Chart({
                        tooltip: { enabled: false },
                        chart: {
                            type: 'line',
                            renderTo: "chart_container1",
                            plotBackgroundImage: 'assets/img/conception.png',
                            height: 230,
                            width: 604,
                            //axisLabelPadding: 5

                        },
                        plotOptions: {
                            line: {
                                marker: {
                                    enabled: false
                                }
                            }
                        },
                        xAxis: {
                            visible: false,
                            gridLineWidth: 0,
                            minorGridLineWidth: 0,
                            labels:
                            {
                                lineColor: 'transparent',
                                enabled: false
                            }
                        },
                        legend: {
                            enabled: false
                        },
                        series: [
                            {
                                marker: {
                                    symbol: ''
                                },
                                //marker: {
                                //    symbol: 'url(https://www.highcharts.com/samples/graphics/sun.png)',
                                //    width: 16,
                                //    height: 16
                                //}, 

                                data: [
                                    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, {
                                        y: 0,
                                        color: '#BF0B23',
                                        marker: {
                                            enabled: true,
                                            fillColor: 'red'
                                        }
                                    }, 0, 0, 0, 0, 0, 0, 0
                                ]
                            }
                        ]

                    });

                }
                catch (error) {

                }
            });
        }

        if (chk_count == 36) {
            $(document).ready(function(){
                try {
                    var aChart1 = new Highcharts.Chart({
                        tooltip: { enabled: false },
                        chart: {
                            type: 'line',
                            renderTo: "chart_container1",
                            plotBackgroundImage: 'assets/img/conception.png',
                            height: 230,
                            width: 604,
                            //axisLabelPadding: 5

                        },
                        plotOptions: {
                            line: {
                                marker: {
                                    enabled: false
                                }
                            }
                        },
                        xAxis: {
                            visible: false,
                            gridLineWidth: 0,
                            minorGridLineWidth: 0,
                            labels:
                            {
                                lineColor: 'transparent',
                                enabled: false
                            }
                        },
                        legend: {
                            enabled: false
                        },
                        series: [
                            {
                                marker: {
                                    symbol: ''
                                },
                                //marker: {
                                //    symbol: 'url(https://www.highcharts.com/samples/graphics/sun.png)',
                                //    width: 16,
                                //    height: 16
                                //}, 

                                data: [
                                    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, {
                                        y: 0,
                                        color: '#BF0B23',
                                        marker: {
                                            enabled: true,
                                            fillColor: 'red'
                                        }
                                    }, 0, 0, 0, 0, 0, 0, 0
                                ]
                            }
                        ]

                    });

                }
                catch (error) {

                }
            });
        }

        if (chk_count == 37) {
            $(document).ready(function(){
                try {
                    var aChart1 = new Highcharts.Chart({
                        tooltip: { enabled: false },
                        chart: {
                            type: 'line',
                            renderTo: "chart_container1",
                            plotBackgroundImage: 'assets/img/conception.png',
                            height: 230,
                            width: 604,
                            //axisLabelPadding: 5

                        },
                        plotOptions: {
                            line: {
                                marker: {
                                    enabled: false
                                }
                            }
                        },
                        xAxis: {
                            visible: false,
                            gridLineWidth: 0,
                            minorGridLineWidth: 0,
                            labels:
                            {
                                lineColor: 'transparent',
                                enabled: false
                            }
                        },
                        legend: {
                            enabled: false
                        },
                        series: [
                            {
                                marker: {
                                    symbol: ''
                                },
                                //marker: {
                                //    symbol: 'url(https://www.highcharts.com/samples/graphics/sun.png)',
                                //    width: 16,
                                //    height: 16
                                //}, 

                                data: [
                                    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, {
                                        y: 0,
                                        color: '#BF0B23',
                                        marker: {
                                            enabled: true,
                                            fillColor: 'red'
                                        }
                                    }, 0, 0, 0, 0, 0, 0, 0
                                ]
                            }
                        ]

                    });

                }
                catch (error) {

                }
            });
        }
        
        if (chk_count == 38) {
            $(document).ready(function(){
                try {
                    var aChart1 = new Highcharts.Chart({
                        tooltip: { enabled: false },
                        chart: {
                            type: 'line',
                            renderTo: "chart_container1",
                            plotBackgroundImage: 'assets/img/conception.png',
                            height: 230,
                            width: 604,
                            //axisLabelPadding: 5

                        },
                        plotOptions: {
                            line: {
                                marker: {
                                    enabled: false
                                }
                            }
                        },
                        xAxis: {
                            visible: false,
                            gridLineWidth: 0,
                            minorGridLineWidth: 0,
                            labels:
                            {
                                lineColor: 'transparent',
                                enabled: false
                            }
                        },
                        legend: {
                            enabled: false
                        },
                        series: [
                            {
                                marker: {
                                    symbol: ''
                                },
                                //marker: {
                                //    symbol: 'url(https://www.highcharts.com/samples/graphics/sun.png)',
                                //    width: 16,
                                //    height: 16
                                //}, 

                                data: [
                                    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, {
                                        y: 0,
                                        color: '#BF0B23',
                                        marker: {
                                            enabled: true,
                                            fillColor: 'red'
                                        }
                                    }, 0, 0, 0, 0, 0, 0, 0
                                ]
                            }
                        ]

                    });

                }
                catch (error) {

                }
            });
        }

        if (chk_count == 39) {
            $(document).ready(function(){
                try {
                    var aChart1 = new Highcharts.Chart({
                        tooltip: { enabled: false },
                        chart: {
                            type: 'line',
                            renderTo: "chart_container1",
                            plotBackgroundImage: 'assets/img/conception.png',

                            height: 230,
                            width: 604,
                            //axisLabelPadding: 5

                        },
                        plotOptions: {
                            line: {
                                marker: {
                                    enabled: false
                                }
                            }
                        },
                        xAxis: {
                            visible: false,
                            gridLineWidth: 0,
                            minorGridLineWidth: 0,
                            labels:
                            {
                                lineColor: 'transparent',
                                enabled: false
                            }
                        },
                        legend: {
                            enabled: false
                        },
                        series: [
                            {
                                marker: {
                                    symbol: ''
                                },
                                //marker: {
                                //    symbol: 'url(https://www.highcharts.com/samples/graphics/sun.png)',
                                //    width: 16,
                                //    height: 16
                                //}, 

                                data: [
                                    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, {
                                        y: 0,
                                        color: '#BF0B23',
                                        marker: {
                                            enabled: true,
                                            fillColor: 'red'
                                        }
                                    }, 0, 0, 0, 0, 0, 0, 0
                                ]
                            }
                        ]

                    });

                }
                catch (error) {
                }
            });
        }

        if (chk_count == 40) {
            $(document).ready(function(){
                try {
                    var aChart1 = new Highcharts.Chart({
                        tooltip: { enabled: false },
                        chart: {
                            type: 'line',
                            renderTo: "chart_container1",
                            plotBackgroundImage: 'assets/img/conception.png',
                            height: 230,
                            width: 604,
                            //axisLabelPadding: 5

                        },
                        plotOptions: {
                            line: {
                                marker: {
                                    enabled: false
                                }
                            }
                        },
                        xAxis: {
                            visible: false,
                            gridLineWidth: 0,
                            minorGridLineWidth: 0,
                            labels:
                            {
                                lineColor: 'transparent',
                                enabled: false
                            }
                        },
                        legend: {
                            enabled: false
                        },
                        series: [
                            {
                                marker: {
                                    symbol: ''
                                },
                                //marker: {
                                //    symbol: 'url(https://www.highcharts.com/samples/graphics/sun.png)',
                                //    width: 16,
                                //    height: 16
                                //}, 

                                data: [
                                    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, {
                                        y: 0,
                                        color: '#BF0B23',
                                        marker: {
                                            enabled: true,
                                            fillColor: 'red'
                                        }
                                    }, 0, 0, 0, 0, 0,
                                ]
                            }
                        ]

                    });

                }
                catch (error) {

                }
            });
        }

        if (chk_count == 41) {
            $(document).ready(function(){
                try {
                    var aChart1 = new Highcharts.Chart({
                        tooltip: { enabled: false },
                        chart: {
                            type: 'line',
                            renderTo: "chart_container1",
                            plotBackgroundImage: 'assets/img/conception.png',
                            height: 230,
                            width: 604,
                            //axisLabelPadding: 5

                        },
                        plotOptions: {
                            line: {
                                marker: {
                                    enabled: false
                                }
                            }
                        },
                        xAxis: {
                            visible: false,
                            gridLineWidth: 0,
                            minorGridLineWidth: 0,
                            labels:
                            {
                                lineColor: 'transparent',
                                enabled: false
                            }
                        },
                        legend: {
                            enabled: false
                        },
                        series: [
                            {
                                marker: {
                                    symbol: ''
                                },
                                //marker: {
                                //    symbol: 'url(https://www.highcharts.com/samples/graphics/sun.png)',
                                //    width: 16,
                                //    height: 16
                                //}, 

                                data: [
                                    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, {
                                        y: 0,
                                        color: '#BF0B23',
                                        marker: {
                                            enabled: true,
                                            fillColor: 'red'
                                        }
                                    },
                                ]
                            }
                        ]

                    });

                }
                catch (error) {

                }
            });
        }
    }

    //send data to server
    send_data() {
        var Pedia_personal = null, case_hist_id = null;
        case_hist_id = this.gyna_case_hist_id != null ? this.gyna_case_hist_id : undefined;
        var sub_rel = this.patient_list.sub_id != "" && this.patient_list.sub_id.length != 0 && this.patient_list.sub_id !=null && this.patient_list.sub_id !=undefined ? this.patient_list.sub_id : undefined;
        var lif_stl_id = this.life_style_id != null ? this.life_style_id :undefined;

        if (this.gyna_fields == "Gynaecology") {
            this.gyna_menstrual = this.Menstrual_Period;
            if (this.sexual_status != undefined && this.sexual_status != "None") {
                this.gynasex_flag = "yes";
                this.obstric_sexual = this.sexual_status;
                this.obstric_sexdifficult = this.Sexual_Difficulties;
                this.Sexual_Pain_txt = this.typeof_pain;
                this.satis_data = this.sexual_satisfaction != undefined && this.sexual_satisfaction != "" ? this.sexual_satisfaction : "";
                this.age_sexdata = this.sexual_active != undefined ? this.sexual_active : "";
                this.sextype_data = this.type_ofsex != undefined && this.type_ofsex != "" ? this.type_ofsex : "";
                this.contre_data = this.contracept != undefined && this.contracept != "" ? this.contracept : "";
                if (this.type_contracept != undefined && this.type_contracept != "") {
                    for (var i = 0; i < this.contracept_array.length; i++) {
                        if (this.type_contracept == this.contracept_array[i].description) {
                            this.contracept_type = this.contracept_array[i].contraceptive_id;
                        }
                    }
                } else {
                    this.contracept_type = "";
                }

                if (this.infection != undefined && this.infection != "") {
                    for (var i = 0; i < this.infect_list.length; i++) {
                        if (this.infection == this.infect_list[i].description) {
                            this.sex_infect_id = this.infect_list[i].sex_infect_id;
                        }
                    }
                } else {
                    this.sex_infect_id = "";
                }
                this.discount_readata = this.reason_discontinue != undefined ? this.reason_discontinue : "";
                this.sex_othdata = this.other_issuesex != undefined ? this.other_issuesex : "";
            } else {
                this.gynasex_flag = "no";
                this.obstric_sexual = this.sexual_status;
                this.obstric_sexdifficult="None"
                this.Sexual_Pain_txt="None"
            }

            this.gynaperiods_flag = "yes";
            for (var i = 0; i < this.Gyna_Stage.length; i++) {
                if (this.stage == this.Gyna_Stage[i].description) {
                    this.stage_id = this.Gyna_Stage[i].mens_stage_id;
                }
            }

            if (this.stage_date != undefined) {
                this.stage_date_chk = this.stage_date;
                this.gyna_stdate =  this.stage_date;
            }
            this.last_gynadate = this.last_mensperiod != undefined ? this.last_mensperiod : null;
            this.cyclefreq_gyna = this.Frequency_Length + " " + this.cycle_length;
            this.menpain_data = this.mens_paintxt;

            if (this.mens_paintxt == "Yes") {
                this.pain_timedata = this.pain_timing;
            }

            if (this.mens_paintxt == "Yes") {
                for (var i = 0; i < this.Characer_pain.length; i++) {
                    if (this.character_pain == this.Characer_pain[i].description) {
                        this.character_id = this.Characer_pain[i].mens_pain_id;
                    }
                }
            }

            this.menregia_data = this.menorragiatxt;
            if (this.no_of_days != undefined && this.no_of_days != "") {
                this.noof_data = this.no_of_days;
            }

            for (var i = 0; i < this.pain_freqlist.length; i++) {
                if (this.pain_frequency == this.pain_freqlist[i].description) {
                    this.painfreq_data = this.pain_freqlist[i].pain_freq_id;
                }
            }
            this.trime_flg = "no";
            this.obst_flag = "no"

        } else if (this.gyna_fields == "Obstetrics") {
            this.obestric_menstrual = this.Menstrual_Period;
            this.last_gynadate = this.last_mensperiod != undefined ? this.last_mensperiod : null;

            this.cyclefreq_obstric = this.Frequency_Length + " " + this.cycle_length;
            if (this.Edd_redd != undefined) {
                var edd = this.Edd_redd.split("-");
                this.Edd_rddtxt = edd[2] + "-" + edd[1] + "-" + edd[0];
            }

            if (this.Trmester_txt != undefined) {
                this.trime_data = this.Trmester_txt;
            }

            if (this.pop_date != undefined) {
                this.pop_datetxt = this.pop_date;
            }

            if (this.pop_result != undefined) {
                this.pop_resultdata = this.pop_result;
            }

            if (this.sexual_status != undefined && this.sexual_status != "None") {
                this.obst_flag = "yes";
                this.obstric_sexual = this.sexual_status;
                this.obstric_sexdifficult = this.Sexual_Difficulties;
                this.Sexual_Pain_txt = this.typeof_pain;
            }
            this.gynasex_flag = "no";
            this.gynaperiods_flag = "no";
            this.trime_flg = "yes";
        }
        this.maritial = this.maritial_status != undefined ? this.maritial_status : null;
        this.occupation_txt = this.occupation != undefined ? encrypt_decript.Encript(this.occupation.trim()).toString() : null;
        this.stress_txt = this.stress_factor != undefined ? this.stress_factor : null;
        this.Main_concerntxt = this.Main_concern != undefined ? this.Main_concern : null;

        if(this.patient_list.sub_id != "" && this.patient_list.sub_id.length != 0 && this.patient_list.sub_id !=null && this.patient_list.sub_id !=undefined){
            var sub_rel = this.patient_list.sub_id;
            Pedia_personal = {
                case_hist_id: case_hist_id,
                life_style_id: lif_stl_id,
                app_id: this.patient_list.app_id,
                doc_reg_id: this.user_id,
                client_reg_id: this.patient_list.Client_id,
                relation_id: this.patient_list.rel_id,
                sub_rel_id: sub_rel,
                country: ipaddress.country_code,
                current_occupation: this.occupation_txt,
                age: this.age_txt,
                refered_by: this.refered_by,
                ref_hospital: this.hospital_name,
                ref_speciality: this.speclization_txt,
                stress_factor: this.stress_txt,
                visit_purpose: this.purpose_ofvisit,
                main_concern: this.Main_concerntxt,
                marital_status: this.maritial,
                marital_life: this.marital_life,
                drug_allergy: this.drug_allergy,
                drugs: this.drugs,
                medical_history: this.send_medical_array,
                //present_illness: this.send_present_array,
                mens_stage: this.stage_id,
                stage_date: this.gyna_stdate,
                periods: this.obestric_menstrual,
                obsexhist_flag: this.obst_flag,
                obs_status: this.obstric_sexual,
                difficulties: this.obstric_sexdifficult,
                pain_type: this.Sexual_Pain_txt,
                status: this.obstric_sexual,
        
                gyn_sex_flag: this.gynasex_flag,
                gync_periods_flag: this.gynaperiods_flag,
                gync_periods: this.gyna_menstrual,
                last_mens_period: this.last_gynadate,
                gync_last_mens_period: this.last_gynadate,
                cycle_length: this.cyclefreq_obstric,
                gync_cycle_length: this.cyclefreq_gyna,
                edd: this.Edd_rddtxt,
                trimester: this.trime_data,
                trimester_flag: this.trime_flg,
                pap_smear_date: this.pop_datetxt,
                pap_smear_result: this.pop_resultdata,
                satisfaction: this.satis_data,
                age_sex_active: this.age_sexdata,
                sex_type: this.sextype_data,
                complications: this.compli_sex,
                contraceptive: this.contre_data,
                contraceptives: this.contre_data,
                contraceptive_type: this.contracept_type,
                infections: this.sex_infect_id,
                discont_reason: this.discount_readata,
                sex_others: this.sex_othdata,
                mens_pain: this.menpain_data,
                pain_timing: this.pain_timedata,
                pain_character: this.character_id,
                menorragia: this.menregia_data,
                no_of_days: this.noof_data,
                pain_frequency: this.painfreq_data,
                        
                complaints:this.complaints,
                present_illness:this.present_illvalue,
                past_illness:this.past_medical,       
               } 
        } else {
            Pedia_personal = {
                case_hist_id: case_hist_id,
                life_style_id: lif_stl_id,
                app_id: this.patient_list.app_id,
                doc_reg_id: this.user_id,
                client_reg_id: this.patient_list.Client_id,
                relation_id: this.patient_list.rel_id,
                country: ipaddress.country_code,
                current_occupation: this.occupation_txt,
                age: this.age_txt,
                refered_by: this.refered_by,
                ref_hospital: this.hospital_name,
                ref_speciality: this.speclization_txt,
                stress_factor: this.stress_txt,
                visit_purpose: this.purpose_ofvisit,
                main_concern: this.Main_concerntxt,
                marital_status: this.maritial,
                marital_life: this.marital_life,
                drug_allergy: this.drug_allergy,
                drugs: this.drugs,
                medical_history: this.send_medical_array,
               // present_illness: this.send_present_array,
                mens_stage: this.stage_id,
                stage_date: this.gyna_stdate,
                periods: this.obestric_menstrual,
                obsexhist_flag: this.obst_flag,
                obs_status: this.obstric_sexual,
                difficulties: this.obstric_sexdifficult,
                pain_type: this.Sexual_Pain_txt,
                status: this.obstric_sexual,
        
                gyn_sex_flag: this.gynasex_flag,
                gync_periods_flag: this.gynaperiods_flag,
                gync_periods: this.gyna_menstrual,
                last_mens_period: this.last_gynadate,
                gync_last_mens_period: this.last_gynadate,
                cycle_length: this.cyclefreq_obstric,
                gync_cycle_length: this.cyclefreq_gyna,
                edd: this.Edd_rddtxt,
                trimester: this.trime_data,
                trimester_flag: this.trime_flg,
                pap_smear_date: this.pop_datetxt,
                pap_smear_result: this.pop_resultdata,
                satisfaction: this.satis_data,
                age_sex_active: this.age_sexdata,
                sex_type: this.sextype_data,
                complications: this.compli_sex,
                contraceptive: this.contre_data,
                contraceptives: this.contre_data,
                contraceptive_type: this.contracept_type,
                infections: this.sex_infect_id,
                discont_reason: this.discount_readata,
                sex_others: this.sex_othdata,
                mens_pain: this.menpain_data,
                pain_timing: this.pain_timedata,
                pain_character: this.character_id,
                menorragia: this.menregia_data,
                no_of_days: this.noof_data,
                pain_frequency: this.painfreq_data,
                
                complaints:this.complaints,
                present_illness:this.present_illvalue,
                past_illness:this.past_medical,
            }   
          }
        Helper_Class.setClntAge(this.age_txt);
        Helper_Class.setClntGender("Female");
        Helper_Class.setPersonalHist(Pedia_personal);
    }

    Menstrual_OnChange(data) {
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth() + 1; //January is 0!
        var yyyy = today.getFullYear();
        
        if (dd < 10) {
            dd = 0 + dd
        }

        if (mm < 10) {
            mm = 0 + mm
        }

        var today1 = yyyy + '-' + mm + '-' + dd;
        var Selecteddate = Date_Formate(data).toString().split('-');
        var Selected_Date = Selecteddate[2].toString() + "-" + Selecteddate[1].toString() + "-" + Selecteddate[0].toString();

        var curr_date = new Date(today);
        curr_date.setDate(curr_date.getDate() + 7);

        var startDay = new Date(today1);
        var Endday = new Date(Selected_Date);

        var millisecondsPerDay = 1000 * 60 * 60 * 24;

        var millisBetween1 = curr_date.getTime() - Endday.getTime();
        var days2 = millisBetween1 / millisecondsPerDay;
        var Days1 = Math.floor(days2);
        var Weeks1 = (Days1 / 7)
        var Weeks_New1 = Math.floor(Weeks1);
        this.check_Menstrual_period(Weeks_New1);
        this.noof_weeks = Weeks_New1;

        if (Days1 <= 91) {
            this.Trmester_txt = "I";
        } else if (Days1 > 91 && Days1 <= 183) {
            this.Trmester_txt = "II";
        } else if (Days1 > 183) {
            this.Trmester_txt = "III";
        }

        if (this.Frequency_Length != "") {
            var Cycleday = 280 - (28 - parseInt(this.Frequency_Length));
            var EDD_Day = new Date(Endday.getTime() + ((Cycleday) * 24 * 60 * 60 * 1000));
            this.Edd_redd = toYYYYMMDD(EDD_Day);
        }

        function toYYYYMMDD(d) {
            var yyyy = d.getFullYear().toString();
            var mm = (d.getMonth() + 101).toString().slice(-2);
            var dd = (d.getDate() + 100).toString().slice(-2);
            return dd + "-" + mm + "-" + yyyy;
        }
    }

    //Common data for all casesheets
    get_client_data() {
        var obj = Helper_Class.getAsg();
        // Past Medical History
        if (obj.medical_history != null) {
            for (var j = 0; j < obj.medical_history.length; j++) {
                this.send_medical_array.push(obj.medical_history[j]);
                this.medhist_value_array=this.send_medical_array;
            }
            this.medical_change(this.send_medical_array)
        }
        this.Get_gynaretrieval_data();
    }

    //Retrieve case hist data
    Get_gynaretrieval_data() {
        this.gyna_case_hist_id = null;
        var obj = Helper_Class.getgynaRet();
        console.log("dierb obj"+JSON.stringify(obj))
        if(obj != null && obj != undefined){
            if (obj.case_hist_id != null) {                
                if (obj.case_hist_id != null) {
                    this.gyna_case_hist_id = obj.case_hist_id;
                }
                if (obj.life_style_id != null) {
                    this.life_style_id = obj.life_style_id;
                    Doc_Helper.setLife_style_id(this.life_style_id);
                }
                if(obj.age != undefined ){
                    this.age_txt = obj.age;
                } else if(Helper_Class.getClntAge() =="0"){
                    this.age_txt="<1";
                } else {
                    this.age_txt = Helper_Class.getClntAge();
                }
                if(this.age_txt == undefined){
                    this.age_txt="";
                    this.agefield=false;
                }
                if(obj.complaints != undefined){
                    this.complaints = obj.complaints;
                  }
                  if(obj.present_illness){
                    this.present_illvalue =  obj.present_illness;
                  }
                  if(obj.past_illness){
                    this.past_medical= obj.past_illness;
                  }
                this.maritial_status = obj.marital_status;
                this.marital_life = obj.marital_life;
                if(obj.current_occupation != "" && obj.current_occupation != undefined && obj.current_occupation != null){
                    this.occupation = encrypt_decript.Decript(obj.current_occupation);
                }
    
                if (obj.stress_factor != null) {
                    this.stress_factor = obj.stress_factor;
                }
                if (obj.main_concern != null) {
                    this.Main_concern = obj.main_concern;
                }
                if (obj.height != null) {
                    this.height_txt = obj.height;
                    this.height_measure = obj.height_measure;
                }
                if (obj.weight != null) {
                    this.weight_txt = obj.weight;
                    this.weight_measure = obj.weight_measure;
                }
                if (obj.bmi != null) {
                    this.txt_bmi = obj.bmi;
                }
                if (obj.bmr != null) {
                    this.txt_bmr = obj.bmr;
                }
                    
                if (obj.visit_purpose == "Gynaecology") {
                    if (obj.gync_periods != null) {
                        this.Menstrual_Period = obj.gync_periods;
                    }
                    if (obj.gync_last_mens_period != null) {
                        if (this.gyna_fields == "Gynaecology") {
                            var last_date_ret = obj.gync_last_mens_period.split("-");
                            this.last_mensperiod = obj.gync_last_mens_period;
                            this.Menstrual_OnChange(this.last_mensperiod);
                        }
                    }
                    if (obj.gync_cycle_length != null) {
                                var cyclesplit = obj.gync_cycle_length.split(" ");
                                this.Frequency_Length = cyclesplit[0];
                                this.cycle_length = cyclesplit[1];
                    }
                    if (obj.sex_status != null) {
                        this.sexual_status = obj.sex_status;
    
                        if (this.sexual_status == "None") {
                            this.difficult_disabled = true;
                            this.sexaul_satdisabled = true;
                            this.age_whendisabled = true;
                            this.typeof_sexdisabled = true;
                            this.contracept_disabled = true;
                            this.infect_disabled = true;
                            this.any_otherdisabled = true;

                            this.Sexual_Difficulties = "None";
                            this.typeof_pain = "Superficial";
                            this.sexual_satisfaction = undefined;
                            this.sexual_active = undefined;
                            this.type_ofsex = undefined;
                            this.contracept = "None";
                            this.type_contracept = undefined;
                            this.reason_discontinue = undefined;
                            this.infection = "None";
                            this.other_issuesex = undefined;
                            this.typcontra_require = false;
                            this.reason_require = false;
    
                        } else {
                            this.difficult_disabled = false;
                            this.sexaul_satdisabled = false;
                            this.age_whendisabled = false;
                            this.typeof_sexdisabled = false;
                            this.contracept_disabled = false;
                            this.infect_disabled = false;
                            this.any_otherdisabled = false;
                        }
                    }
                    if (obj.difficulties != null) {
                            this.Sexual_Difficulties = obj.difficulties;
                        if (this.Sexual_Difficulties == "None") {
                            this.typeof_pain = "Superficial";
                            this.typeofpain_disabled = true;
                        } else {
                            this.typeofpain_disabled = false;
                        }
                    } else {
                        this.Sexual_Difficulties = "None";
                    }
                    if (obj.pain_type != null) {
                        this.typeof_pain = obj.pain_type;
                    }
                }
                if (obj.visit_purpose == "Obstetrics") {
                    if (obj.cycle_length != null) {
                        var cyclesplit = obj.cycle_length.split(" ");
                        this.Frequency_Length = cyclesplit[0];
                        this.cycle_length = cyclesplit[1];
                    }
                    if (obj.periods != null) {
                        this.Menstrual_Period = obj.periods;
                    }
                    if (obj.last_mens_period != null) {
                        var last_date_ret = obj.last_mens_period.split("-");
                        this.last_mensperiod = obj.last_mens_period;
                        this.Menstrual_OnChange(this.last_mensperiod);
                    }
                    if (obj.pap_smear_date != null) {
                        this.pop_date= obj.pap_smear_date;
                    }
                    if (obj.pap_smear_result != null) {
                        this.pop_result = obj.pap_smear_result;
                    }
                    if (obj.trimester != null) {
                        this.Trmester_txt = obj.trimester;
                    }
                    if (obj.obs_status != null) {
                        this.sexual_status = obj.obs_status;
                        if (this.sexual_status == "None") {
                            this.difficult_disabled = true;
                            this.sexaul_satdisabled = true;
                            this.age_whendisabled = true;
                            this.typeof_sexdisabled = true;
                            this.contracept_disabled = true;
                            this.infect_disabled = true;
                            this.any_otherdisabled = true;
                            this.Sexual_Difficulties = "None";
                            this.typeof_pain = "Superficial";
                            this.sexual_satisfaction = undefined;
                            this.sexual_active = undefined;
                            this.type_ofsex = undefined;
                            this.contracept = "None";
                            this.type_contracept = undefined;
                            this.reason_discontinue = undefined;
                            this.infection = "None";
                            this.other_issuesex = undefined;
                            this.typcontra_require = false;
                            this.reason_require = false;
                        } else {
                            this.difficult_disabled = false;
                            this.sexaul_satdisabled = false;
                            this.age_whendisabled = false;
                            this.typeof_sexdisabled = false;
                            this.contracept_disabled = false;
                            this.infect_disabled = false;
                            this.any_otherdisabled = false;
                        }
                    }
                    if (obj.obs_difficulties != null) {
                            this.Sexual_Difficulties = obj.obs_difficulties;
                        if (this.Sexual_Difficulties == "None") {
                            this.typeof_pain = "Superficial";
                            this.typeofpain_disabled = true;
                        } else {
                            this.typeofpain_disabled = false;
                        }
                    } else {
                        this.Sexual_Difficulties = "None";
                    }
                    if (obj.pain_type != null) {
                        this.typeof_pain = obj.pain_type;
                    }
                }
                if (obj.present_illness != null) {
                    for (var j = 0; j < obj.present_illness.length; j++) {
                        this.send_present_array.push(obj.present_illness[j].present_illness);
                        this.pres_ill_value_array=this.send_present_array;
                    }
                    this.present_change(this.send_present_array);
                }
                if (obj.drug_allergy != null) {
                    this.drug_allergy = obj.drug_allergy;
                    this.drug_disabled = this.drug_allergy == "No" ? true : false;
                }
                if (obj.drugs != null) {
                    this.drugs = obj.drugs;
                }
    
                if (obj.mens_stage != null) {
                    for (var i = 0; i < this.Gyna_Stage.length; i++) {
                        for (var j = 0; j < obj.mens_stage.length; j++) {
                            if (obj.mens_stage[j] == this.Gyna_Stage[i].mens_stage_id) {
                                this.stage = this.Gyna_Stage[i].description;
                            }
                        }
                    }
                }
                if (obj.stage_date != null) {
                    this.stage_date = obj.stage_date;
                }
                if (obj.edd != null) {
                    var edddate_ret = obj.edd.split('-');
                }
                if (obj.mens_pain != null) {
                    this.mens_paintxt = obj.mens_pain;
                    if (this.mens_paintxt == "Yes") {
                        this.time_painrequire = true;
                        this.character_painrequire = true;
                        this.time_paindisabled = false;
                        this.character_paindisabled = false;
                    } else {
                        this.time_painrequire = false;
                        this.character_painrequire = false;
                        this.time_paindisabled = true;
                        this.character_paindisabled = true;
                        this.pain_timing = undefined;
                        this.character_pain = undefined;
                    }
                }
                if (obj.pain_timing != null) {
                    this.pain_timing = obj.pain_timing;
                }
                if (obj.pain_timing != null) {
                    this.pain_timing = obj.pain_timing;
                }
                            //character of pain
                if (obj.pain_character != null) {
                    for (var i = 0; i < this.Characer_pain.length; i++) {
                        for (var j = 0; j < obj.pain_character.length; j++) {
                            if (obj.pain_character[j] == this.Characer_pain[i].mens_pain_id) {
                                    this.character_pain = this.Characer_pain[i].description;
                            }
                        }
                    }
                }
                this.menorragiatxt = obj.menorragia;
                if (this.menorragiatxt == "Yes") {
                    this.noof_daysrequire = true;
                    this.frequency_required = true;
                    this.noof_daysdisabled = false;
                    this.freuency_disabled = false;
                } else {
                    this.noof_daysrequire = false;
                    this.frequency_required = false;
                    this.noof_daysdisabled = true;
                    this.freuency_disabled = true;
                    this.no_of_days = undefined;
                    this.pain_frequency = undefined;
                }
                this.no_of_days = obj.no_of_days;
                            //Menstrual frequency
                if (obj.pain_frequency != null) {
                    for (var i = 0; i < this.pain_freqlist.length; i++) {
                        for (var j = 0; j < obj.pain_frequency.length; j++) {
                            if (obj.pain_frequency[j] == this.pain_freqlist[i].pain_freq_id) {
                                this.pain_frequency = this.pain_freqlist[i].description;
                            }
                        }
                    }
                }
                if (obj.satisfaction != null) {
                    this.sexual_satisfaction = obj.satisfaction;
                }
                if (obj.age_sex_active != null) {
                    this.sexual_active = obj.age_sex_active;
                }
                if (obj.sex_type != null) {
                    this.type_ofsex = obj.sex_type;
                }
                if (obj.contraceptives != null) {
                    this.contracept = obj.contraceptives;
                    if (this.contracept == "None") {
                        this.reason_discontinue = undefined;
                        this.infection = this.infect_list[0].description;
                        this.typcontra_require = false;
                        this.reason_require = false;
                        this.typecontracept_disabled = true;
                        this.reason_disabled = true;
                        this.compli_disabled = true;
                    } else {
                        this.typcontra_require = true;
                        this.reason_require = false;
                        this.typecontracept_disabled = false;
                        this.reason_disabled = false;
                        this.compli_disabled = false;
                    }
                }
                        //Type of contraceptive
                if (obj.contraceptive_type != null) {
                    for (var i = 0; i < this.contracept_array.length; i++) {
                        for (var j = 0; j < obj.contraceptive_type.length; j++) {
                            if (obj.contraceptive_type[j] == this.contracept_array[i].contraceptive_id) {
                                this.type_contracept = this.contracept_array[i].description;
                            }
                        }
                    }
                }
                        //Infection
                if (obj.infections != null) {
                    for (var i = 0; i < this.infect_list.length; i++) {
                        for (var j = 0; j < obj.infections.length; j++) {
                            if (obj.infections[j] == this.infect_list[i].sex_infect_id) {
                                this.infection = this.infect_list[i].description;
                            }
                        }
                    }
                }
                if (obj.discont_reason != null) {
                    this.reason_discontinue = obj.discont_reason;
                }
                if (obj.complications != null) {
                    this.compli_sex = obj.complications;
                }
                if (obj.sex_others != null) {
                    this.other_issuesex = obj.sex_others;
                }
            }else {
                this.loader = true;                      
                this.agefield = false;
            }
        }else{
        this.complaints = this.patient_list.ascomplaints;
        this.present_illvalue =  this.patient_list.present_illness;
        this.past_medical= this.patient_list.past_illness;

        }
        this.disabled_fields();
        this.send_data();
    }

    disabled_fields() {
        if (this.sexual_status == "None") {
            this.difficult_disabled = true;
            this.sexaul_satdisabled = true;
            this.Sexual_Difficulties = true;
            this.Sexual_Difficulties = "None";
            this.age_whendisabled = true;
            this.typeof_sexdisabled = true;
            this.contracept_disabled = true;
            this.infect_disabled = true;
            this.any_otherdisabled = true;
            this.typeofpain_disabled = true;
            this.typecontracept_disabled = true;
            this.reason_disabled = true;
            this.compli_disabled = true;
        } else {
            this.difficult_disabled = false;
            this.sexaul_satdisabled = false;
            this.age_whendisabled = false;
            this.typeof_sexdisabled = false;
            this.contracept_disabled = false;
            this.infect_disabled = false;
            this.any_otherdisabled = false;
        }
        this.typeofpain_disabled = this.Sexual_Difficulties == "None" ? true : false;

        if (this.contracept == "None") {
            this.typecontracept_disabled = true;
            this.reason_disabled = true;
            this.compli_disabled=true;
        } else {
            this.typecontracept_disabled = false;
            this.reason_disabled = false;
            this.compli_disabled = false;
        }
        this.drug_disabled = this.drug_allergy == "No" ? true : false;

        if (this.mens_paintxt == "No") {
            this.time_paindisabled = true;
            this.character_paindisabled = true;
        } else {
            this.time_paindisabled = false;
            this.character_paindisabled = false;
        }

        if (this.menorragiatxt == "No") {
            this.noof_daysdisabled = true;
            this.freuency_disabled = true;
        } else {
            this.noof_daysdisabled = false;
            this.freuency_disabled = false;
        }
    }

    //Get medical history data
    async Get_Medical_history() {
        this.Medical_hist_array = [];
        var obj = masterCSData_Helper.getMasterMedicalHistory();
        if(obj != undefined){
            if (obj.med_hist != null) {
                for (var i = 0; i < obj.med_hist.length; i++) {
                    this.Medical_hist_array.push({
                        type: 'checkbox',
                        // multiple:true,
                        value: obj.med_hist[i].med_hist_id,
                        label: obj.med_hist[i].description,
                        checked: false
                    });
                    if(obj.med_hist[i].med_hist_id == 32){
                        this.Medical_hist = obj.med_hist[i].description;
                        }
                }
            }
        }
        if(masterCSData_Helper.getMasterGynoPresentIllness() == undefined){
            await this.masterCSdata.getGynoPresentIllness();
            this.Get_present_illness();
        }else{
            this.Get_present_illness();
        }
    }

    medical_popup() {
        if(this.client == false){
            for (var j = 0; j < this.Medical_hist_array.length; j++) {
            this.Medical_hist_array[j].checked = false;
            }

            if (this.send_medical_array != undefined && this.send_medical_array.length != 0) {
                for (var i = 0; i < this.send_medical_array.length; i++) {
                    for (var j = 0; j < this.Medical_hist_array.length; j++) {
                        if (this.send_medical_array[i] == this.Medical_hist_array[j].value) {
                            this.Medical_hist_array[j].checked = true;
                        }
                    }
                }
            }

            const dialogRef = this.dialog.open(DropdownModelpageComponent, {
                width: '500px',
                height: '400px',
                data:{
                title: "Past Medical History",
                main_array: this.Medical_hist_array,
                selected_list:this.send_medical_array,
            }
            });

            dialogRef.afterClosed().subscribe(result => {
                if(result != undefined){
                this.medical_change(result);
                }
            });
        }
    }


    medical_change(selected) {
        this.Medical_hist = "";
        function StringBuilder(this:any,value) {
            this.strings = new Array("");
            this.append(value);
        }

        // Appends the given value to the end of this instance.
        StringBuilder.prototype.append = function (value) {
            if (value) {
                this.strings.push(value);
            }
        }

        // Clears the string buffer
        StringBuilder.prototype.clear = function () {
            this.strings.length = 1;
        }

        // Converts this instance to a String.
        StringBuilder.prototype.toString = function () {
            return this.strings.join("");
        }

        var selected_data = new StringBuilder("");
        this.send_medical_array = selected;

        if (this.send_medical_array.length != 0) {
            var n = 0;
            for (var j = 0; j < this.send_medical_array.length; j++) {
                for (var i = 0; i < this.Medical_hist_array.length; i++) {
                    if (this.send_medical_array[j] == this.Medical_hist_array[i].value) {
                        n = n + 1;
                        if (n > 1) {
                            selected_data.append(",");
                            selected_data.append("\n");
                        }
                        selected_data.append(this.Medical_hist_array[i].label);
                    }
                }
            }
            this.Medical_hist = selected_data.toString();
            Doc_Helper.setMedical_hist(this.Medical_hist);
        }
    }

    //Get present illness
    Get_present_illness() {
        this.present_illness_arry = [];
        var obj = masterCSData_Helper.getMasterGynoPresentIllness();
        if(obj != undefined){
            if (obj.present_illnesses != null) {
                for (var i = 0; i < obj.present_illnesses.length; i++) {
                    this.present_illness_arry.push({
                        type: 'checkbox',
                        value: obj.present_illnesses[i].present_illness_id,
                        label: obj.present_illnesses[i].description,
                        checked: false
                    });
                }
            }
        }
        this.get_client_data();
    }

    present_change(selected) {
        this.Present_illness = "";

        function StringBuilder(this:any,value) {
            this.strings = new Array("");
            this.append(value);
        }

        // Appends the given value to the end of this instance.
        StringBuilder.prototype.append = function (value) {
            if (value) {
                this.strings.push(value);
            }
        }

        // Clears the string buffer
        StringBuilder.prototype.clear = function () {
            this.strings.length = 1;
        }

        // Converts this instance to a String.
        StringBuilder.prototype.toString = function () {
            return this.strings.join("");
        }

        var selected_data = new StringBuilder("");
        this.send_present_array = selected;

        if (this.send_present_array.length != 0) {
            var n = 0;
            for (var j = 0; j < this.send_present_array.length; j++) {
                for (var i = 0; i < this.present_illness_arry.length; i++) {
                    if (this.send_present_array[j] == this.present_illness_arry[i].value) {
                        n = n + 1;
                        if (n > 1) {
                            selected_data.append(",");
                            selected_data.append("\n");
                        }
                        selected_data.append(this.present_illness_arry[i].label);
                    }
                }
            }
            this.Present_illness = selected_data.toString();
        }
    }

    present_pop() {
        if(this.client == false){
            for (var j = 0; j < this.present_illness_arry.length; j++) {
                this.present_illness_arry[j].checked = false;
            }

            if (this.send_present_array != undefined && this.send_present_array.length != 0) {
                for (var i = 0; i < this.send_present_array.length; i++) {
                    for (var j = 0; j < this.present_illness_arry.length; j++) {
                        if (this.send_present_array[i] == this.present_illness_arry[j].value) {
                            this.present_illness_arry[j].checked = true;
                        }
                    }
                }
            }
            const dialogRef = this.dialog.open(DropdownModelpageComponent, {
                width: '500px',
                height: '400px',
                data:{
                title: "Present Illness",
                main_array: this.present_illness_arry,
                selected_list:this.send_present_array,
            }
            });

            dialogRef.afterClosed().subscribe(result => {
                if(result != undefined){
                this.present_change(result);
                }
            });
        }
    }

    //get referal
    Get_referal() {
        this.Refered_doctor = [];
        this.Refered_name = [];

        if (this.refer_txt != "" && this.refer_txt.length > 2) {
            var headers = new Headers();
            headers.append('Content-Type', 'application/json');
            this.http.post(ipaddress.getIp.toString() + 'search/doctor/',
                JSON.stringify({
                    spl_name: this.speclization_txt,
                    doc_name: this.refer_txt
                }),
                { headers: headers })
                .subscribe(
                    response => {
                        var obj = response.json();
                        this.Refered_name = obj.info;
                        if (obj.info != null) {
                            for (var i = 0; i < this.Refered_name.length; i++) {
                                if(this.Refered_name[i].middle_name != undefined){
                                    this.Refered_doctor.push(this.Refered_name[i].first_name + " " + this.Refered_name[i].middle_name + " " + this.Refered_name[i].last_name);
                                } else {
                                    this.Refered_doctor.push(this.Refered_name[i].first_name + " " + this.Refered_name[i].last_name);
                                }
                            }
                        }
                        
                        this.Refered_doctor = this.Refered_doctor.filter(function (this:any,el) {
                            return el.toLowerCase().indexOf(this.refer_txt.toLowerCase()) > -1;
                        }.bind(this));

                        if (this.Refered_doctor.length == 0) {
                            this.toastr.error(Message_data.noDocFound);
                        }
                    },
                    error => {
                            this.toastr.error(Message_data.defaultErr);
                    });
         } else {
            this.Refered_doctor = [];
        }
    }

    Select_refer_doctor(item) {
        this.refer_txt = item;
        this.Refered_doctor = [];
    }

    //Get stage
    Get_stage() {
        var obj = masterCSData_Helper.getMasterGynoStage();
        if(obj != undefined){
            for (var i = 0; i < obj.mens_stages.length; i++) {
                this.Gyna_Stage = obj.mens_stages;
            }
        }
    }

    //Get character of pain
    Get_character_ofpain() {
        var obj = masterCSData_Helper.getMasterCharacterofpain();
        if(obj != undefined){
            for (var i = 0; i < obj.mens_pains.length; i++) {
                this.Characer_pain = obj.mens_pains;
            }
        }
    }

    //Get Frequency
    Get_frequency() {
        var obj = masterCSData_Helper.getMasterFrequency();
        if(obj != undefined){
            for (var i = 0; i < obj.pain_frequencies.length; i++) {
                this.pain_freqlist = obj.pain_frequencies;
            }
        }
    }

    //Type of contraceptive
    Type_contraceptive() {
        var obj = masterCSData_Helper.getMasterTypeContraceptive();
        if(obj != undefined){
            for (var i = 0; i < obj.contraceptives.length; i++) {
                this.contracept_array = obj.contraceptives;
                this.type_contracept = this.contracept_array[0].description;
            }
        }
    }

    //Infections
    Get_infection() {
        var obj = masterCSData_Helper.getMasterInfection();
        if(obj != undefined){
            for (var i = 0; i < obj.sexual_infections.length; i++) {
                this.infect_list = obj.sexual_infections;
                this.infection = this.infect_list[0].description;
            }
        }
    }


    menstrual_gynahistoryclick() {
        this.menstrualgyna = "gynaMenstrual";
        const dialogRef = this.dialog.open(DiabeticpopuphistoryComponent, {
            width: '700px',
            height: '300px',
            data:{
                Diab_case_hist_id: this.gyna_case_hist_id,
            Life_style_id: this.life_style_id,
            History: this.menstrualgyna,
            },
        });
        
        dialogRef.afterClosed().subscribe(result => {});
    }

    obestric_menstrualclick() {
        this.menstrualobstric = "obestricmenstrual";
        const dialogRef = this.dialog.open(DiabeticpopuphistoryComponent, {
            width: '700px',
            height: '300px',
            data:{
                Diab_case_hist_id: this.gyna_case_hist_id,
            Life_style_id: this.life_style_id,
            History: this.menstrualobstric,
            },
        });
        
        dialogRef.afterClosed().subscribe(result => {});
    }

    obestric_sexualclick() {
        this.sexualobstric = "obestricsexual";
        const dialogRef = this.dialog.open(DiabeticpopuphistoryComponent, {
            width: '700px',
            height: '300px',
            data:{
                Diab_case_hist_id: this.gyna_case_hist_id,
            Life_style_id: this.life_style_id,
            History: this.sexualobstric,
            },
        });
        
        dialogRef.afterClosed().subscribe(result => {});
    }

    menstrual_sexualclick() {
        this.sexualgyna = "gynasexual";
        const dialogRef = this.dialog.open(DiabeticpopuphistoryComponent, {
            width: '700px',
            height: '300px',
            data:{
                Diab_case_hist_id: this.gyna_case_hist_id,
            Life_style_id: this.life_style_id,
            History: this.sexualgyna,
            },
        });
        
        dialogRef.afterClosed().subscribe(result => {});
    }

    formatNumber(e: any, separador: string = '.', decimais: number = 2) {
        let a:any = e.split('');
        let ns:string = '';
        a.forEach((c:any) => { if (!isNaN(c)) ns = ns + c; });
        ns = parseInt(ns).toString();
        if (ns.length < (decimais+1)) { ns = ('0'.repeat(decimais+1) + ns); ns = ns.slice((decimais+1)*-1); }
        let ans = ns.split('');
        let r = '';
        for (let i=0; i < ans.length; i++) if (i == ans.length - decimais) r = r + separador + ans[i]; else r = r + ans[i];
        if(this.weight_txt != NaN){
            this.weight_txt = r;
        }
    }

    formatNumber_height(e: any, separador: string = '.', decimais: number = 2) {
        let a:any = e.split('');
        let ns:string = '';
        a.forEach((c:any) => { if (!isNaN(c)) ns = ns + c; });
        ns = parseInt(ns).toString();
        if (ns.length < (decimais+1)) { ns = ('0'.repeat(decimais+1) + ns); ns = ns.slice((decimais+1)*-1); }
        let ans = ns.split('');
        let r = '';
        for (let i=0; i < ans.length; i++) if (i == ans.length - decimais) r = r + separador + ans[i]; else r = r + ans[i];
        if(this.height_txt != NaN){
            this.height_txt = r;
        }
    }

    OnDateChangestagedate(e){
        this.stage_date = e;
    }
  
    OnDateChangesDate(e){
        this.pop_date = e;
    }

    Menstrual_dateclick(e){
        this.last_mensperiod=e;
        this.Menstrual_OnChange(e);
    }

    getCurrentDate(){
        var obj = Master_Helper.getMasterCurrentDate();
        if (obj.current_date != null) {
            this.currentDate = obj.current_date;
        }
    }
}
