import { Component, OnInit ,ElementRef,Input,Inject,ViewEncapsulation,HostListener} from '@angular/core';
import { Http, Headers } from '@angular/http';
// import { getIp, Ip_with_img_address, country_code } from '../../../assets/js/ipaddress';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Data, Route, Router } from '@angular/router';
import { Doc_Helper } from '../Doc_Helper';
import { DoctorViewService } from '../doctor-view/doctor-view.service';
import { ClientViewService } from '../../client/clientview/client-view.service';
import { NurseService } from 'src/app/Nurse_module/nurse-view/nurse.service';
import { Helper_Class } from 'src/app/helper_class';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
@Component({
  selector: 'app-gyna-obestric-pop-selection',
  templateUrl: './gyna-obestric-pop-selection.component.html',
  styleUrls: ['./gyna-obestric-pop-selection.component.css']
})
export class GynaObestricPopSelectionComponent implements OnInit {

  constructor(public clientservice: ClientViewService,public router:Router,public http:Http, public el: ElementRef,public dialogRef: MatDialogRef<GynaObestricPopSelectionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,public messageservice:MenuViewService, public nurseservice:MenuViewService) { 
      dialogRef.disableClose = true;
    }

  ngOnInit(): void {
  }

  setgynofields(e){
    Doc_Helper.setGyna_fields(null);
    Doc_Helper.setGyna_fields(e);
    if(Doc_Helper.getAppFlow() == "client" ){
      this.clientservice.sendMessage("casesheet");
    }else if(Helper_Class.getInpatientFlow() == "nurse"){
      this.nurseservice.sendMessage("casesheet");
    }else{
      this.messageservice.sendMessage("casesheet");
    }
  }

  ClosePopup(){
    this.dialogRef.close();
  }
}
