<div class="container-fluid g-0" style="height: 100%;">
    <div class="warpper_div">
        <div class="asied_section" #asied_section_width>
            <section id="aside_section">
                <div class="logopanel" id="logo" #logo>
                    <h1>
                        <a (click)="menuNav('appointment');"></a>
                    </h1>
                </div>
                <div class="menu_list">
                    <div class="menu menu_active" id="appointment" [hidden]="appointmentData"
                        (click)="menuNav('appointment');">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/appointment.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                            <span>Appointments</span>
                        </div>
                    </div>
                    <div class="menu" id="appointment" [hidden]="appointmentData" (click)="menuNav('assignList');">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/Schedule.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                            <span>Assignments</span>
                        </div>
                    </div>
                    <div class="menu" id="appointment" [hidden]="appointmentData" (click)="menuNav('doctorAppList');">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/doctor.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                            <span>Doctor appointment</span>
                        </div>
                    </div>
                    <div class="menu" id="appointment" [hidden]="appointmentData" (click)="menuNav('doctorList');">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/doctor.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                            <span>Doctor list</span>
                        </div>
                    </div>
                    <div class="menu" id="inpatient" (click)="menuNav('inpatient');">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/doctor.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                            <span>Inpatient</span>
                        </div>
                    </div>
                    <div [hidden]="reference_video" class="menu" id="reference_video" (click)="menuNav('reference_video');">
                        <div class="menu_icon"> <i class="icon-menu"><img src="../../assets/global/images/reference_video.png" /></i></div>
                        <div class="menu_title" > <span> Reference video</span><span style="float: right;padding-right: 7px;"> <i class="fa fa-angle-right"></i></span></div>
                    </div>
                </div>
            </section>
        </div>
        <div class="right_content_section" #right_section_width>
            <section id="headar_section">
                <div class="header-left" style="position: relative;">
                    <div class="topnav">
                        <div class="openbtn_desktop" (click)="minimizing()"><i class="fas fa-bars"></i></div>
                        <div class="openbtn" (click)="openMenumodel()"><i class="fas fa-bars"></i></div>
                        <div class="hospital_logo">
                            <img class="hospital_logo" src="{{hospitalLogo}}" alt="user image">
                        </div>
                        <!-- <i class="far fa-bell"></i> 
                        <i class="far fa-paper-plane"></i>-->
                        <div class="hospital_name">{{hospitalName}}</div>
                        <div class="bell"></div>
                        <div class="share"></div>
                        <div class="doctor_profile_pc" data-bs-toggle="modal" data-bs-target="#exampleModal"><img
                                _ngcontent-ore-c119="" src="{{profileImage}}"
                                onerror="this.src='./././assets/img/default.jpg'"> </div>
                        <div class="doctor_name" data-bs-toggle="modal" data-bs-target="#exampleModal">
                            {{firstName}}&nbsp;{{lastName}}</div>
                        <!-- Modal -->
                        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                            aria-hidden="true">
                            <div class="modal-dialog profile_dialog">
                                <div class="modal-content">
                                    <div class="modal-header" style="display: inline-block;text-align: center">
                                        <!-- src="{{profile_image}}" -->
                                        <div class="modal-title" id="exampleModalLabel"><img class="model_profile"
                                                src="{{profileImage}}"
                                                onerror="this.src='./././assets/img/default.jpg'"></div>
                                    </div>
                                    <div class="modal-body">
                                        <div class="menu_div" (click)="MenuModelAction('profile');"> <i
                                                class="far fa-user"></i> <span class="header_model_menu">My
                                                Profile</span> </div>
                                        <div class="menu_div" (click)="MenuModelAction('changePassword');"><i
                                                class="fas fa-cog"></i><span class="header_model_menu">Change
                                                Password</span></div>
                                        <div class="menu_div" (click)="MenuModelAction('logout');"
                                            style="border-bottom: none;"><i class="fas fa-sign-out-alt"></i><span
                                                class="header_model_menu">Log Out</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="body_section">
                <div class="body_warpper">
                    <div class="card shadow-lg">
                        <div [ngSwitch]="displayPage">
                            <div *ngSwitchDefault="'appointment'">
                                <app-nurse-appointments></app-nurse-appointments>
                            </div>
                            <div *ngSwitchCase="'appointment'">
                                <app-nurse-appointments></app-nurse-appointments>
                            </div>
                            <div *ngSwitchCase="'appoinmentDetailView'">
                                <app-nurse-appointment-detailspage></app-nurse-appointment-detailspage>
                            </div>
                            <div *ngSwitchCase="'assignList'">
                                <app-nurse-assign-list></app-nurse-assign-list>
                            </div>
                            <div *ngSwitchCase="'assignDetailView'">
                                <app-nurse-assign-view></app-nurse-assign-view>
                            </div>
                            <div *ngSwitchCase="'doctorAppList'">
                                <app-nurse-doc-app-list></app-nurse-doc-app-list>
                            </div>
                            <div *ngSwitchCase="'doctorList'">
                                <app-nurse-doc-list></app-nurse-doc-list>
                            </div>
                            <div *ngSwitchCase="'profile'">
                                <app-nurse-profile></app-nurse-profile>
                            </div>
                            <div *ngSwitchCase="'inpatient'">
                                <app-medicine-tracker></app-medicine-tracker>
                            </div>
                            <!-- <div *ngSwitchCase="'inPatientViewInfo'">
                                <app-nurse-inpatient-info></app-nurse-inpatient-info>
                            </div> -->
                            <div *ngSwitchCase="'medicine_tracker'">
                                <app-medicine-tracker></app-medicine-tracker>
                            </div>
                            <div *ngSwitchCase="'viewIpatient'">
                                <app-inpat-patient-info></app-inpat-patient-info>
                            </div>
                            <div *ngSwitchCase="'DoctorAppDetail'">
                                <app-nurse-doc-app-view></app-nurse-doc-app-view>
                            </div>
                            <div *ngSwitchCase="'casesheet'">
                                <app-casesheet></app-casesheet>
                            </div>
                            <div *ngSwitchCase="'casesheet_list'">
                                <app-casesheet-list></app-casesheet-list>
                            </div>
                            <div *ngSwitchCase="'cardio_casesheet_view'">
                                <app-cardio-casesheet-view></app-cardio-casesheet-view>
                            </div>
                            <div *ngSwitchCase="'reference_video'">
                                <app-reference-video></app-reference-video>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <footer id="footer_section">
                <div class="footer_left">Copyright © 2021 All rights reserved </div>
                <div class="footer_right">
                    <p class="pull-right sm-pull-reset">
                        <span>
                            <a href="#" class="m-r-10">Support</a> | <a href="#" class="m-l-10 m-r-10">Terms of use</a>
                            | <a href="#" class="m-l-10">Privacy Policy</a>
                        </span>
                    </p>
                </div>
            </footer>
        </div>
    </div>
</div>