<div class="container-fluid g-0" style="height: 100%;">
    <div class="warpper_div">
        <div class="asied_section" #asied_section_width>
            <section id="aside_section">
                <div class="logopanel" id="logo" #logo>
                    <h1>
                        <a (click)="menuNav('pharmacycurentinformation');"></a>
                    </h1>
                </div>
                <div class="menu_list">
                    <div [hidden]="manageOrders" class="menu menu_active" id="patient"
                        (click)="menuNav('patient');setValue()">
                        <div class="menu_icon"> <i style="transform: rotate(-55deg);" class="fa fa-puzzle-piece"></i>
                        </div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                            <span>Orders</span><span style="float: right;padding-right: 7px;"> <i
                                    class="fa fa-angle-right"></i></span>
                        </div>
                        <ul *ngIf="submenuFlag" class="nav-children">
                            <li (click)="menuNav('pharmacycurentinformation');setValue()"><a id="nav-a"><i
                                        class="fa fa-angle-right"></i> Current order</a></li>
                            <li (click)="menuNav('pharmacypastinformation');setValue()"><a><i
                                        class="fa fa-angle-right"></i> Past order</a></li>
                            <li (click)="menuNav('interdeptlist');setValue()"><a><i class="fa fa-angle-right"></i> Inter
                                    department</a></li>
                            <li (click)="menuNav('billestimate');setValue()"><a><i class="fa fa-angle-right"></i> Bill
                                    Estimate</a></li>
                        </ul>
                    </div>
                    <div class="menu" id="stockmanagment" (click)="menuNav('stockmanagment');">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/dashboard.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined"> <span>Stock
                                Management</span><span style="float: right;padding-right: 7px;"> <i
                                    class="fa fa-angle-right"></i></span> </div>
                        <ul *ngIf="submenuFlag1" class="nav-children">
                            
                            <li [hidden]="manufacturer" (click)="menuNav('manufacturer');setValue()"><a id="nav-a"><i
                                        class="fa fa-angle-right"></i>Manufacturer</a></li>
                            <li [hidden]="stockorlevel" (click)="menuNav('srl');setValue()"><a id="nav-a"><i
                                            class="fa fa-angle-right"></i>Stock reorder level</a></li>            
                            <li [hidden]="suppliers" (click)="menuNav('pharmacysupplier');setValue()"><a id="nav-a"><i
                                        class="fa fa-angle-right"></i>Suppliers</a></li>
                            <li [hidden]="suppliersProduct" (click)="menuNav('supplierProduct');setValue()"><a
                                    id="nav-a"><i class="fa fa-angle-right"></i>
                                    Suppliers product</a></li>
                            <li [hidden]="discount" (click)="menuNav('discounts');setValue()"><a><i
                                        class="fa fa-angle-right"></i> Discounts</a></li>
                            <li [hidden]="productMaster" (click)="menuNav('product');setValue()"><a><i
                                        class="fa fa-angle-right"></i> Product</a></li>
                                        <li [hidden]="purchaseRequestlist" (click)="menuNav('purchasereqlist');setValue()"><a
                                            id="nav-a"><i class="fa fa-angle-right"></i>
                                            Purchase request </a></li>

                                        <li [hidden]="purchaseRequest" (click)="menuNav('pharmacyrequest');setValue()"><a
                                    id="nav-a"><i class="fa fa-angle-right"></i>
                                    Indent </a></li>

                            <li [hidden]="purchaseOrder" (click)="menuNav('pharmacyresponse');setValue()"><a><i
                                        class="fa fa-angle-right"></i> Purchase order</a></li>
                            <li [hidden]="inventoryReceivables" (click)="menuNav('inventory_receivables');setValue()">
                                <a><i class="fa fa-angle-right"></i>
                                    Inventory receivables</a>
                            </li>
                            <li [hidden]="stockEdit" (click)="menuNav('stockStatusAdmin');setValue()"><a id="nav-a"><i
                                        class="fa fa-angle-right"></i> Stock edit</a></li>
                            <li [hidden]="purchaseRequest" (click)="menuNav('productReturns');setValue()">
                                <a><i class="fa fa-angle-right"></i> Stock returns </a>
                            </li>
                            <li [hidden]="purchaseRequest" (click)="menuNav('supppay');setValue()">
                                <a><i class="fa fa-angle-right"></i> Supplier payment </a>
                            </li>
                            <li [hidden]="purchaseRequest" (click)="menuNav('rackbin');setValue()">
                                <a><i class="fa fa-angle-right"></i> Racks and bins </a>
                            </li>
                            <!-- <li [hidden]="pharmasetting" (click)="menuNav('pharmasettings');setValue()">
                                <a><i class="fa fa-angle-right"></i> Pharma Settings </a>
                            </li> -->

                        </ul>
                    </div>

                    
                    <div class="menu" id="reports" (click)="menuNav('reports');">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/dashboard.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                            <span>Reports</span><span style="float: right;padding-right: 7px;"> <i
                                    class="fa fa-angle-right"></i></span> </div>
                        <ul *ngIf="reportSubmenuFlag" class="nav-children">
                            <li (click)="menuNav('stock_status');setValue()"><a id="nav-a"><i
                                        class="fa fa-angle-right"></i> Stock status</a></li>

                            <li (click)="menuNav('sales_report');setValue()"><a><i class="fa fa-angle-right"></i>
                                    Sales report</a></li>
                            <li (click)="menuNav('receivables_report');setValue()"><a><i class="fa fa-angle-right"></i>
                                    Receivables report</a></li>
                            <li (click)="menuNav('productReturnsReport');setValue()"><a><i
                                        class="fa fa-angle-right"></i> Returns report</a></li>
                            <li (click)="menuNav('pharmaracksbins');setValue()"><a><i class="fa fa-angle-right"></i>Pharma control Racks and Bins</a></li>

                        </ul>
                    </div>

                    <div class="menu" (click)="menuNav('phamratimeline');;setReport('pharmatat')">
                        <div class="menu_icon"> <i class="icon-menu"><img
                            src="../../assets/global/images/dashboard.svg" /></i></div>
                            <div class="menu_title">
                                <span>Pharma TAT</span><span style="float: right;padding-right: 7px;"> <i
                                        class="fa fa-angle-right"></i></span>
                            </div>
                            <br>
                        </div>




                    <div class="menu" id="referral" (click)="menuNav('referral');">
                        <div class="menu_icon"> <i style="transform: rotate(-55deg);" class="fa fa-puzzle-piece"></i>
                        </div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                            <span>Referral</span><span style="float: right;padding-right: 7px;"> <i
                                    class="fa fa-angle-right"></i></span>
                        </div>
                    </div>
                    <div [hidden]="inwardFlag" class="menu" id="inward" (click)="menuNav('inward');setValue()">
                        <div class="menu_icon"><i class="icon-menu"><img
                                    src="../../assets/global/images/appointment.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                            <span>Inward</span><span style="float: right;padding-right: 7px;"> <i
                                    class="fa fa-angle-right"></i></span>
                        </div>
                    </div>
                    <div [hidden]="reference_video" class="menu" id="reference_video"
                        (click)="menuNav('reference_video');">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/reference_video.png" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined"> <span>
                                Reference video</span><span style="float: right;padding-right: 7px;"> <i
                                    class="fa fa-angle-right"></i></span></div>
                    </div>
                    <div class="menu" id="help" (click)="menuNav('help');setValue()">
                        <div class="menu_icon"><i class="icon-menu"><img
                                    src="../../assets/global/images/appointment.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                            <span>Help</span><span style="float: right;padding-right: 7px;"> <i
                                    class="fa fa-angle-right"></i></span>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div class="right_content_section" #right_section_width>
            <section id="headar_section">
                <div class="header-left" style="position: relative;">
                    <div class="topnav">
                        <div class="openbtn_desktop" (click)="minimizing()"><i class="fas fa-bars"></i></div>
                        <div class="openbtn" (click)="openMenumodel()"><i class="fas fa-bars"></i></div>
                        <div class="hospital_logo">
                            <img class="hospital_logo" src="{{hospitalLogo}}" alt="Logo"
                                onerror="this.src='../../../assets/img/default.jpg';">
                        </div>
                        <div class="hospital_name"><span
                                class="d-none d-sm-inline-block d-md-inline-block d-lg-inline-block d-xl-inline-block ">{{hospitalName}}</span>
                        </div>
                        <div class="doctor_profile_pc" data-bs-toggle="modal" data-bs-target="#exampleModal"><img
                                src="{{profileImage}}" onerror="this.src='./././assets/img/default.jpg'"> </div>
                        <div class="doctor_name" data-bs-toggle="modal" data-bs-target="#exampleModal">
                            {{firstName}}&nbsp;{{lastName}}</div>
                        
                        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                            aria-hidden="true">
                            <div class="modal-dialog profile_dialog">
                                <div class="modal-content">
                                    <div class="modal-header" style="display: inline-block;text-align: center">
                                        
                                        <div class="modal-title" id="exampleModalLabel"><img class="model_profile"
                                                src="{{profileImage}}"
                                                onerror="this.src='./././assets/img/default.jpg'"></div>
                                    </div>
                                    <div class="modal-body">
                                        <div class="menu_div" (click)="MenuModelAction('pharmacyhome');"> <i
                                                class="far fa-user"></i> <span class="header_model_menu">My
                                                Profile</span> </div>
                                        <div class="menu_div" (click)="MenuModelAction('changePassword');"><i
                                                class="fas fa-cog"></i><span class="header_model_menu">Change
                                                Password</span></div>
                                        <div class="menu_div" (click)="MenuModelAction('logout');"
                                            style="border-bottom: none;"><i class="fas fa-sign-out-alt"></i><span
                                                class="header_model_menu">Log Out</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="body_section">
                <div class="body_warpper">
                    <div id="pharma_overlay" class="card shadow-lg">
                        <div [ngSwitch]="displayPage">
                            <div *ngSwitchDefault="'pharmacycurentinformation'">
                                <app-pharmacycurrentinformation></app-pharmacycurrentinformation>
                            </div>
                            <div *ngSwitchCase="'pharmacycurentinformation'">
                                <app-pharmacycurrentinformation></app-pharmacycurrentinformation>
                            </div>
                            <div *ngSwitchCase="'ordercreate'">
                                <app-ordercreate></app-ordercreate>
                            </div>
                            <div *ngSwitchCase="'pharmacypastinformation'">
                                <app-pharmacypastinformation></app-pharmacypastinformation>
                            </div>
                            <div *ngSwitchCase="'pharmacymoduleorderdetailview'">
                                <app-pharmacymodule-orderdetiledview></app-pharmacymodule-orderdetiledview>
                            </div>
                            <div *ngSwitchCase="'pharmacysupplier'">
                                <app-pharma-supplier-mst></app-pharma-supplier-mst>
                            </div>
                            <div *ngSwitchCase="'pharmacyrequest'">
                                <app-pharmapurchaserequest></app-pharmapurchaserequest>
                            </div>
                            <div *ngSwitchCase="'pharmacyresponse'">
                                <app-pharmapurchaseordermst></app-pharmapurchaseordermst>
                            </div>

                            <div *ngSwitchCase="'orderview'">
                                <app-purchaseorderview></app-purchaseorderview>
                            </div>



                            <div *ngSwitchCase="'phamratimeline'">
                                <app-timeline-report></app-timeline-report>
                            </div>

                            <div *ngSwitchCase="'inventory_receivables'">
                                <app-pharma-inventory-receivables></app-pharma-inventory-receivables>
                            </div>
                            <div *ngSwitchCase="'inventory_upload'">
                                <app-pharma-inventory-upload></app-pharma-inventory-upload>
                            </div>
                            <div *ngSwitchCase="'stock_status'">
                                <app-pharma-stock-status></app-pharma-stock-status>
                            </div>
                            <div *ngSwitchCase="'sales_report'">
                                <app-pharma-sales-report></app-pharma-sales-report>
                            </div>
                            <div *ngSwitchCase="'receivables_report'">
                                <app-pharma-receivable-report></app-pharma-receivable-report>
                            </div>
                            <div *ngSwitchCase="'product'">
                                <app-pharma-product-mst></app-pharma-product-mst>
                            </div>
                            <div *ngSwitchCase="'discounts'">
                                <app-pharma-discount-mst></app-pharma-discount-mst>
                            </div>
                            <div *ngSwitchCase="'help'">
                                <app-pharma-help></app-pharma-help>
                            </div>
                            <div *ngSwitchCase="'referral'">
                                <app-pharma-referral></app-pharma-referral>
                            </div>
                            <div *ngSwitchCase="'pharmacyhome'">
                                <app-pharma-home></app-pharma-home>
                            </div>
                            <div *ngSwitchCase="'inward'">
                                <app-inward></app-inward>
                            </div>
                            <div *ngSwitchCase="'stockStatusAdmin'">
                                <app-pharma-stock-status-admin></app-pharma-stock-status-admin>
                            </div>
                            <div *ngSwitchCase="'ipWriteRx'">
                                <app-inpatient-write-rx></app-inpatient-write-rx>
                            </div>
                            <div *ngSwitchCase="'manufacturer'">
                                <app-phrama-manufacturer-mst></app-phrama-manufacturer-mst>
                            </div>
                            <div *ngSwitchCase="'supplierProduct'">
                                <app-phrama-supplier-product-mst></app-phrama-supplier-product-mst>
                            </div>
                            <div *ngSwitchCase="'productReturns'">
                                <app-pharma-returns></app-pharma-returns>
                            </div>
                            <div *ngSwitchCase="'productReturnsReport'">
                                <app-sales-report-gst></app-sales-report-gst>
                            </div>
                            
                            <div *ngSwitchCase="'pharmaracksbins'">
                                <app-pharma-racks-bins></app-pharma-racks-bins>
                            </div>

                            <div *ngSwitchCase="'reference_video'">
                                <app-reference-video></app-reference-video>
                            </div>
                            <div *ngSwitchCase="'interdept'">
                                <app-interdept-trans></app-interdept-trans>
                            </div>
                            <div *ngSwitchCase="'interdeptlist'">
                                <app-interdept-trans-list></app-interdept-trans-list>
                            </div>
                            <div *ngSwitchCase="'supppay'">
                                <app-pharma-supplier-payment></app-pharma-supplier-payment>
                            </div>
                            <div *ngSwitchCase="'rackbin'">
                                <app-pharma-rackbin></app-pharma-rackbin>
                            </div>
                            <!-- <div *ngSwitchCase="'pharmasettings'">
                                <app-pharma-settings></app-pharma-settings>
                            </div> -->
                            <div *ngSwitchCase="'billestimate'">
                                <app-bill-estimate></app-bill-estimate>
                            </div>
                            <div *ngSwitchCase="'srl'">
                                <app-stockreorderlevel></app-stockreorderlevel>
                            </div>
                            <div *ngSwitchCase="'purchasereqlist'">
                                <app-purchaserequestlist></app-purchaserequestlist>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <footer id="footer_section">
                <div class="footer_left">Copyright © 2021 All rights reserved </div>
                <div class="footer_right">
                    <p class="pull-right sm-pull-reset">
                        <span>
                            <a href="#" class="m-r-10">Support</a> | <a href="#" class="m-l-10 m-r-10">Terms of use</a>
                            | <a href="#" class="m-l-10">Privacy Policy</a>
                        </span>
                    </p>
                </div>
            </footer>
        </div>
    </div>
</div> 

<!-- <div class="container-fluid g-0" style="height: 100%;">
    <div class="warpper_div">
        <div class="asied_section" #asied_section_width>
            <section id="aside_section">
                <div class="logopanel" id="logo" #logo>
                    <h1>
                        <a (click)="menuNav('pharmacycurentinformation');"></a>
                    </h1>
                </div>
                <div class="menu_list">
                    <div [hidden]="manageOrders" class="menu menu_active" id="patient"
                        (click)="menuNav('patient');setValue()">
                        <div class="menu_icon"> <i style="transform: rotate(-55deg);" class="fa fa-puzzle-piece"></i>
                        </div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                            <span>Orders</span><span style="float: right;padding-right: 7px;"> <i
                                    class="fa fa-angle-right"></i></span>
                        </div>
                        <ul *ngIf="submenuFlag" class="nav-children">
                            <li (click)="menuNav('pharmacycurentinformation');setValue()"><a id="nav-a"><i
                                        class="fa fa-angle-right"></i> Current order</a></li>
                            <li (click)="menuNav('pharmacypastinformation');setValue()"><a><i
                                class="fa fa-angle-right"></i> Past order</a></li>
                            <li (click)="menuNav('billestimate');setValue()"><a><i class="fa fa-angle-right"></i> Bill
                                Estimate</a></li>
                            <li (click)="menuNav('interdeptlist');setValue()"><a><i
                                class="fa fa-angle-right"></i> Inter department</a></li>
                        </ul>
                    </div>
                    <div class="menu" id="stockmanagment" (click)="menuNav('stockmanagment');">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/dashboard.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined"> <span>Stock Management</span><span style="float: right;padding-right: 7px;"> <i class="fa fa-angle-right"></i></span> </div>
                        <ul *ngIf="submenuFlag1" class="nav-children">
                            <li [hidden]="manufacturer" (click)="menuNav('manufacturer');setValue()"><a id="nav-a"><i
                                        class="fa fa-angle-right"></i> Manufacturer</a></li>
                            <li [hidden]="suppliers" (click)="menuNav('pharmacysupplier');setValue()"><a id="nav-a"><i
                                        class="fa fa-angle-right"></i> Suppliers</a></li>
                            <li [hidden]="suppliersProduct" (click)="menuNav('supplierProduct');setValue()"><a
                                    id="nav-a"><i class="fa fa-angle-right"></i>
                                    Suppliers product</a></li>
                            <li [hidden]="discount" (click)="menuNav('discounts');setValue()"><a><i
                                        class="fa fa-angle-right"></i> Discounts</a></li>
                            <li [hidden]="productMaster" (click)="menuNav('product');setValue()"><a><i
                                        class="fa fa-angle-right"></i> Product</a></li>
                            <li [hidden]="purchaseRequest" (click)="menuNav('pharmacyrequest');setValue()"><a
                                    id="nav-a"><i class="fa fa-angle-right"></i>
                                    Purchase request</a></li>
                            <li [hidden]="purchaseOrder" (click)="menuNav('pharmacyresponse');setValue()"><a><i
                                        class="fa fa-angle-right"></i> Purchase order</a></li>
                            <li [hidden]="inventoryReceivables" (click)="menuNav('inventory_receivables');setValue()">
                                <a><i class="fa fa-angle-right"></i>
                                    Inventory receivables</a></li>
                            <li [hidden]="stockEdit" (click)="menuNav('stockStatusAdmin');setValue()"><a id="nav-a"><i
                                class="fa fa-angle-right"></i> Stock edit</a></li>
                            <li [hidden]="purchaseRequest" (click)="menuNav('productReturns');setValue()">
                                        <a><i class="fa fa-angle-right"></i> Stock returns </a></li>
                            <li [hidden]="purchaseRequest" (click)="menuNav('supppay');setValue()">
                                <a><i class="fa fa-angle-right"></i> Supplier payment </a></li>
                            <li [hidden]="purchaseRequest" (click)="menuNav('rackbin');setValue()">
                                <a><i class="fa fa-angle-right"></i> Racks and bins </a></li>
                            <li [hidden]="purchaseRequest" (click)="menuNav('srl');setValue()">
                                <a><i class="fa fa-angle-right"></i> Reorder level </a></li>
                           
                        </ul>
                    </div>

                    <div class="menu" id="reports" (click)="menuNav('reports');">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/dashboard.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined"> <span>Reports</span><span style="float: right;padding-right: 7px;"> <i class="fa fa-angle-right"></i></span> </div>
                        <ul *ngIf="reportSubmenuFlag" class="nav-children">
                            <li (click)="menuNav('stock_status');setValue()"><a id="nav-a"><i
                                        class="fa fa-angle-right"></i> Stock status</a></li>
                            
                            <li (click)="menuNav('sales_report');setValue()"><a><i class="fa fa-angle-right"></i>
                                    Sales report</a></li>
                            <li (click)="menuNav('receivables_report');setValue()"><a><i
                                        class="fa fa-angle-right"></i> Receivables report</a></li>
                            <li (click)="menuNav('productReturnsReport');setValue()"><a><i class="fa fa-angle-right"></i> Returns report</a></li>
                        </ul>
                    </div>

                    <div class="menu" id="referral" (click)="menuNav('referral');">
                        <div class="menu_icon"> <i style="transform: rotate(-55deg);" class="fa fa-puzzle-piece"></i>
                        </div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                            <span>Referral</span><span style="float: right;padding-right: 7px;"> <i
                                    class="fa fa-angle-right"></i></span></div>
                    </div>
                    <div [hidden]="inwardFlag" class="menu" id="inward" (click)="menuNav('inward');setValue()">
                        <div class="menu_icon"><i class="icon-menu"><img
                                    src="../../assets/global/images/appointment.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                            <span>Inward</span><span style="float: right;padding-right: 7px;"> <i
                                    class="fa fa-angle-right"></i></span></div>
                    </div>
                    <div [hidden]="reference_video" class="menu" id="reference_video" (click)="menuNav('reference_video');">
                        <div class="menu_icon"> <i class="icon-menu"><img src="../../assets/global/images/reference_video.png" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined"> <span> Reference video</span><span style="float: right;padding-right: 7px;"> <i class="fa fa-angle-right"></i></span></div>
                    </div>
                    <div class="menu" id="help" (click)="menuNav('help');setValue()">
                        <div class="menu_icon"><i class="icon-menu"><img
                                    src="../../assets/global/images/appointment.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                            <span>Help</span><span style="float: right;padding-right: 7px;"> <i
                                    class="fa fa-angle-right"></i></span></div>
                    </div>
                </div>
            </section>
        </div>
        <div class="right_content_section" #right_section_width>
            <section id="headar_section">
                <div class="header-left" style="position: relative;">
                    <div class="topnav">
                        <div class="openbtn_desktop" (click)="minimizing()"><i class="fas fa-bars"></i></div>
                        <div class="openbtn" (click)="openMenumodel()"><i class="fas fa-bars"></i></div>
                        <div class="hospital_logo">
                            <img class="hospital_logo" src="{{hospitalLogo}}" alt="Logo"
                                onerror="this.src='../../../assets/img/default.jpg';">
                        </div>
                        <div class="hospital_name"><span
                                class="d-none d-sm-inline-block d-md-inline-block d-lg-inline-block d-xl-inline-block ">{{hospitalName}}</span>
                        </div>
                        <div class="doctor_profile_pc" data-bs-toggle="modal" data-bs-target="#exampleModal"><img
                                src="{{profileImage}}" onerror="this.src='./././assets/img/default.jpg'"> </div>
                        <div class="doctor_name" data-bs-toggle="modal" data-bs-target="#exampleModal">
                            {{firstName}}&nbsp;{{lastName}}</div>
                        
                        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                            aria-hidden="true">
                            <div class="modal-dialog profile_dialog">
                                <div class="modal-content">
                                    <div class="modal-header" style="display: inline-block;text-align: center">
                                        
                                        <div class="modal-title" id="exampleModalLabel"><img class="model_profile" src="{{profileImage}}"  onerror="this.src='./././assets/img/default.jpg'"></div>
                                    </div>
                                    <div class="modal-body">
                                        <div class="menu_div" (click)="MenuModelAction('pharmacyhome');"> <i class="far fa-user"></i> <span class="header_model_menu">My Profile</span> </div>
                                        <div class="menu_div" (click)="MenuModelAction('changePassword');"><i class="fas fa-cog"></i><span class="header_model_menu">Change Password</span></div>
                                        <div class="menu_div" (click)="MenuModelAction('logout');" style="border-bottom: none;"><i class="fas fa-sign-out-alt"></i><span class="header_model_menu">Log Out</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="body_section">
                <div class="body_warpper">
                    <div id="pharma_overlay" class="card shadow-lg">
                        <div [ngSwitch]="displayPage">
                            <div *ngSwitchDefault="'pharmacycurentinformation'">
                                <app-pharmacycurrentinformation></app-pharmacycurrentinformation>
                            </div>
                            <div *ngSwitchCase="'pharmacycurentinformation'">
                                <app-pharmacycurrentinformation></app-pharmacycurrentinformation>
                            </div>
                            <div *ngSwitchCase="'ordercreate'">
                                <app-ordercreate></app-ordercreate>
                            </div>
                            <div *ngSwitchCase="'pharmacypastinformation'">
                                <app-pharmacypastinformation></app-pharmacypastinformation>
                            </div>
                            <div *ngSwitchCase="'pharmacymoduleorderdetailview'">
                                <app-pharmacymodule-orderdetiledview></app-pharmacymodule-orderdetiledview>
                            </div>
                            <div *ngSwitchCase="'pharmacysupplier'">
                                <app-pharma-supplier-mst></app-pharma-supplier-mst>
                            </div>
                            <div *ngSwitchCase="'pharmacyrequest'">
                                <app-pharmapurchaserequest></app-pharmapurchaserequest>
                            </div>
                            <div *ngSwitchCase="'pharmacyresponse'">
                                <app-pharmapurchaseordermst></app-pharmapurchaseordermst>
                            </div>
                           
                            <div *ngSwitchCase="'orderview'">
                                <app-purchaseorderview></app-purchaseorderview>
                            </div>
                           
                            <div *ngSwitchCase="'inventory_receivables'">
                                <app-pharma-inventory-receivables></app-pharma-inventory-receivables>
                            </div>
                            <div *ngSwitchCase="'inventory_upload'">
                                <app-pharma-inventory-upload></app-pharma-inventory-upload>
                            </div>
                            <div *ngSwitchCase="'stock_status'">
                                <app-pharma-stock-status></app-pharma-stock-status>
                            </div>
                            <div *ngSwitchCase="'sales_report'">
                                <app-pharma-sales-report></app-pharma-sales-report>
                            </div>
                            <div *ngSwitchCase="'receivables_report'">
                                <app-pharma-receivable-report></app-pharma-receivable-report>
                            </div>
                            <div *ngSwitchCase="'product'">
                                <app-pharma-product-mst></app-pharma-product-mst>
                            </div>
                            <div *ngSwitchCase="'discounts'">
                                <app-pharma-discount-mst></app-pharma-discount-mst>
                            </div>
                            <div *ngSwitchCase="'help'">
                                <app-pharma-help></app-pharma-help>
                            </div>
                            <div *ngSwitchCase="'referral'">
                                <app-pharma-referral></app-pharma-referral>
                            </div>
                            <div *ngSwitchCase="'pharmacyhome'">
                                <app-pharma-home></app-pharma-home>
                            </div>
                            <div *ngSwitchCase="'inward'">
                                <app-inward></app-inward>
                            </div>
                            <div *ngSwitchCase="'stockStatusAdmin'">
                                <app-pharma-stock-status-admin></app-pharma-stock-status-admin>
                            </div>
                            <div *ngSwitchCase="'ipWriteRx'">
                                <app-inpatient-write-rx></app-inpatient-write-rx>
                            </div>
                            <div *ngSwitchCase="'manufacturer'">
                                <app-phrama-manufacturer-mst></app-phrama-manufacturer-mst>
                            </div>
                            <div *ngSwitchCase="'supplierProduct'">
                                <app-phrama-supplier-product-mst></app-phrama-supplier-product-mst>
                            </div>
                            <div *ngSwitchCase="'productReturns'">
                                <app-pharma-returns></app-pharma-returns>
                            </div>
                            <div *ngSwitchCase="'productReturnsReport'">
                                <app-sales-report-gst></app-sales-report-gst>
                            </div>
                            <div *ngSwitchCase="'reference_video'">
                                <app-reference-video></app-reference-video>
                            </div>
                            <div *ngSwitchCase="'interdept'">
                                <app-interdept-trans></app-interdept-trans>
                            </div>
                            <div *ngSwitchCase="'interdeptlist'">
                                <app-interdept-trans-list></app-interdept-trans-list>
                            </div>
                            <div *ngSwitchCase="'supppay'">
                                <app-pharma-supplier-payment></app-pharma-supplier-payment>
                            </div>
                            <div *ngSwitchCase="'rackbin'">
                                <app-pharma-rackbin></app-pharma-rackbin>
                            </div>
                            <div *ngSwitchCase="'srl'">
                                <app-stockreorderlevel></app-stockreorderlevel>
                            </div>
                            <div *ngSwitchCase="'billestimate'">
                                <app-bill-estimate></app-bill-estimate>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <footer id="footer_section">
                <div class="footer_left">Copyright © 2021 All rights reserved </div>
                <div class="footer_right">
                    <p class="pull-right sm-pull-reset">
                        <span>
                            <a href="#" class="m-r-10">Support</a> | <a href="#" class="m-l-10 m-r-10">Terms of use</a>
                            | <a href="#" class="m-l-10">Privacy Policy</a>
                        </span>
                    </p>
                </div>
            </footer>
        </div>
    </div>
</div> -->