<div class="wipes">
    <div class="forgot">
        <!-- <div>
            <a routerLink="/loginpage"><img src="../../assets/img/close_icon.png" width="20px" height="20px"
                    style="position: absolute;right:5px;top:5px;" />
            </a>
        </div> -->
        <div style="text-align: center;margin: 25px 0px 7px 2px;">
            <label class="tervys_heading">Help us to send your password</label>
        </div>
        <div class="row" style="    padding: 16px 50px;">
            <div class="col-12">
                <mat-label class="matlabel">Select user type</mat-label>
                <select class="ipcss" required [(ngModel)]="optiontype">
                    <option class="optgr" value="select">Select</option>
                    <option class="optgr" value="doctor">Doctor</option>
                    <option class="optgr" value="client">Client</option>
                    <option class="optgr" value="nurse">Nurse</option>
                    <option class="optgr" value="diagonsis">Diagonsis</option>
                    <option class="optgr" value="pharmacy">Pharmacy</option>
                    <option class="optgr" value="diagonsis">Diagonsis</option>
                    <option class="optgr" value="physiotherapist">Physiotherapist</option>
                    <option class="optgr" value="front-desk">Front-desk</option>
                </select>
            </div>
            <div class="col-12">
                <mat-label class="matlabel">Select user ID type</mat-label>
                <select class="ipcss" required [(ngModel)]="usertype">
                    <option class="optgr" value="email">Email</option>
                    <option class="optgr" value="mobileno">MobileNo</option>
                </select>
            </div>
            <div class="col-12">
                <mat-label class="matlabel"> Enter userID</mat-label>
                <input class="ipcss" matInput type="text" [(ngModel)]="username" />
            </div>

        </div>
        <div style="padding: 0px 30%">
            <a routerLink="/loginpage"><img src="../../../assets/ui_icons/buttons/cancel_button.svg" width="85px" height="auto" /></a>
           <img (click)="forgotpwd()" src="../../../assets/ui_icons/buttons/submit_button.svg" width="85px" height="auto" style="float: right;"/>
        </div>
    </div>

</div>
<!-- calendar 


<h3> 
   
    dd-mm-yyyy format 
</h3> 
  
<label for="Date of Birth"> 
    Enter the Date: 
    <input type="date" name="date" placeholder="DD-MM-YYYY" val=""> 
</label> 
<input type="date" (click)="formatdate()">


-->