<div mat-dialog-title class="popup_border_icd">
  <h5 class="header_txt">{{popup_heading}}</h5>
  <div mat-dialog-actions>
    <img src="../../../assets/img/close_icon.png" (click)="otc_herbel_close()" width="20px" height="20px"
      id="close_icd" />
  </div>
</div>
<div mat-dialog-content class="otc_content">
  <div class="container" style="padding: 8px;">
    <div class="row">
      <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
        <mat-label class="matlabel">{{medicine_supply_name}}<br>
          <input #matInput class="ipcss  " [(ngModel)]="med_name">
        </mat-label>
      </div>
      <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
        <mat-label class="matlabel">Dosage<br>
          <input #matInput class="ipcss  " value="" [(ngModel)]="dosage">
        </mat-label>
      </div>
      <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
        <mat-label class="matlabel">Session <br>
          <mat-select required class="ipcss " name="" [(ngModel)]="sess_id" (selectionChange)="sessionselection()">
            <mat-option *ngFor="let sess of sessions" value="{{sess.drug_id}}">{{sess.drug_name}}</mat-option>
          </mat-select>
        </mat-label>
      </div>
      <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
        <div class="row">
          <div class="col-4">
            <mat-label class="matlabel" *ngIf="quantity">Mor<br>
              <input value="" class="ipcss " matInput [(ngModel)]="mor_qty" [disabled]=mor_flag>
            </mat-label>
          </div>
          <div class="col-4" *ngIf="!quantity">
            <mat-label class="matlabel" *ngIf="!quantity">qty<br>
              <input value="" class="ipcss " matInput [(ngModel)]="mor_qty">
            </mat-label>
          </div>
          <div class="col-4" *ngIf="quantity">
            <mat-label class="matlabel">Aft<br>
              <input value="" class="ipcss " [(ngModel)]="aft_qty" [disabled]=aft_flag matInput>
            </mat-label>
          </div>
          <div class="col-4" *ngIf="!eveningflag && quantity">
            <mat-label class="matlabel">Eve<br>
              <input value="" class="ipcss " [(ngModel)]="eve_qty" [disabled]=eve_flag>
            </mat-label>
          </div>
          <div class="col-4" *ngIf="quantity">
            <mat-label class="matlabel">Ngt<br>
              <input value="" class="ipcss " [(ngModel)]="ngt_qty" [disabled]=ngt_flag>
            </mat-label>
          </div>
        </div>
      </div>
      <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
        <mat-label class="matlabel">Any side effects<br>
          <input #matInput class="ipcss  " [(ngModel)]="side_effect">
        </mat-label>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <img style="float: right;" src="../../../assets/ui_icons/buttons/add_button.svg" class="saveimgbtn_inpatinfo"
          (click)="AddDatas()" />
      </div>
    </div>
    <div class="table-responsive" *ngIf="pres_datas.length" style="padding: 0;">
      <table id="tbl" class="table table-nowrap table-sm" *ngIf="pres_datas.length != 0">
        <thead class="thvalues">
          <tr>
            <th>{{table_medsup_name}}</th>
            <th>Dosage</th>
            <th>Session</th>
            <th>Intake</th>
            <th>Side effect</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let sess of pres_datas; let i=index">
            <td data-th="Medicine name">{{sess.med_name}}</td>
            <td data-th="Dosage">{{sess.dosage}}</td>
            <td data-th="Session">{{sess.session}}</td>
            <td data-th="Intake">{{sess.intake_qty}}</td>
            <td data-th="Side effect">{{sess.side_effect}}</td>
            <td data-th="Action">
              <a (click)="DeleteMedication(i)">delete</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<div mat-dialog-actions align="end" style="width: auto;right: 19px;">
</div>