<div *ngIf="pediaDevelopFlag == 'min'">
    <div class="row">
        <div class="col-12" style="margin-bottom: 5px;">
            <mat-accordion class="mataccordion">
                <mat-expansion-panel class="expandpanel">
                    <mat-expansion-panel-header class="exppanel width">
                        <mat-panel-title class="title">
                            &#60;1 Year (Infant)
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <hr class="hr">
    
                    <div class="row" style="padding-left: 13px;padding-top: 10px">
                        <div class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 ">
                            <mat-label class="matlabel pediatrics_lbl_width">Deciduous Teeth </mat-label>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 ">
                            <mat-radio-group class="matlabel" color="primary" [(ngModel)]="oneyearDecide_teeth" (change)="send_data()">
                                <mat-radio-button class="pediatrics_width" value="yes">Normal</mat-radio-button>
                                <mat-radio-button class="pediatrics_width" value="no">Abnormal</mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                            <textarea [hidden]="oneyearDecide_teeth=='yes'" class="ipcss txtarea" myInput (change)="send_data()"
                                [disabled]="decide_disabled" [(ngModel)]="oneyearDecide_teethremarks" rows="1"></textarea>
                        </div>
                    </div>
                    <div class="row" style="padding-left: 13px;padding-top: 10px">
                        <div class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 ">
                            <mat-label class="matlabel">Dental Caries</mat-label>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                            <mat-radio-group class="matlabel" color="primary" [(ngModel)]="oneyearDental_caries" (change)="send_data()">
                                <mat-radio-button class="pediatrics_width" value="yes">Yes</mat-radio-button>
                                <mat-radio-button class="pediatrics_width" value="no">No</mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                            <textarea [hidden]="oneyearDental_caries=='no'" class="ipcss txtarea" myInput
                                [disabled]="den_disabled" [(ngModel)]="oneyeardental_cariesremarks" rows="1" (blur)="send_data()"></textarea>
                        </div>
                    </div>
                    <div class="row" style="padding-left: 13px;padding-top: 10px">
                        <div class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 ">
                            <mat-label class="matlabel">Object Permanence</mat-label>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                            <mat-radio-group class="matlabel" color="primary"
                                [(ngModel)]="oneyearobject_permonce" (blur)="send_data()"> 
                                <mat-radio-button class="pediatrics_width" value="yes">Normal</mat-radio-button>
                                <mat-radio-button class="pediatrics_width" value="no">Abnormal</mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                            <textarea [hidden]="oneyearobject_permonce=='yes'" class="ipcss txtarea" myInput (blur)="send_data()"
                                [disabled]="object_disabled" [(ngModel)]="oneyearobject_remarks" rows="1"></textarea>
                        </div>
                    </div>
                    <div class="row" style="padding-left: 13px;padding-top: 10px">
                        <div class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 ">
                            <mat-label class="matlabel">Sleep Pattern</mat-label>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                            <mat-radio-group class="matlabel" color="primary" [(ngModel)]="oneyearsleep_pattern" (change)="send_data()">
                                <mat-radio-button class="pediatrics_width" value="yes">Normal</mat-radio-button>
                                <mat-radio-button class="pediatrics_width" value="no">Abnormal</mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                            <textarea [hidden]="oneyearsleep_pattern=='yes'" class="ipcss txtarea" myInput
                                [disabled]="sleep_disabled" [(ngModel)]="oneyear_sleepremarks" rows="1" (blur)="send_data()"></textarea>
                        </div>
                    </div>
    
                    <p class="tervys_heading"><strong>Cognitive Development</strong></p>
                    <div class="row">
                        <mat-accordion class="mataccordion">
                            <mat-expansion-panel class="expandpanel">
                                <mat-expansion-panel-header class="exppanel width">
                                    <mat-panel-title class="title">
                                        1-2 months
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <hr class="hr">
                                <div [hidden]="cognitiveonemonth" style="padding-top:10px">
                                    <div class="row"
                                        *ngFor="let Developement of Devel_Cog_1_2_month_Data;" style="padding-top:10px">
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">{{Developement.Description}}</mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                            <mat-radio-group class="matlabel" color="primary"
                                                [(ngModel)]="Developement.No" (change)="send_data()">
                                                <mat-radio-button class="pediatrics_width" [disabled]="cogone_disabled"
                                                    [value]="false" [checked]="Developement.Yes">Normal</mat-radio-button>
                                                <mat-radio-button class="pediatrics_width" [disabled]="cogone_disabled"
                                                    [value]="true" [checked]="Developement.No">Abnormal</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <textarea *ngIf="Developement.No" class="ipcss txtarea" myInput (blur)="send_data()"
                                                [disabled]="cogone_disabled" [(ngModel)]="Developement.remarks"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                    <div class="row">
                        <mat-accordion class="mataccordion">
                            <mat-expansion-panel class="expandpanel">
                                <mat-expansion-panel-header class="exppanel width">
                                    <mat-panel-title class="title ">
                                        3 months
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <hr class="hr">
                                <div [hidden]="cognitivethreemonth" style="padding-top:10px">
                                    <div class="row"
                                        *ngFor="let Developement of Devel_Cog_3_month_Data" style="padding-top:10px">
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">{{Developement.Description}}</mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                            <mat-radio-group class="matlabel" color="primary"
                                                [(ngModel)]="Developement.No" (change)="send_data()">
                                                <mat-radio-button class="pediatrics_width" [disabled]="cogthree_disabled && client"
                                                    [value]="false" [checked]="Developement.Yes">Yes</mat-radio-button>
                                                <mat-radio-button class="pediatrics_width" [disabled]="cogthree_disabled && client"
                                                    [value]="true" [checked]="Developement.No">No</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <textarea *ngIf="Developement.No" class="ipcss txtarea" myInput (blur)="send_data()"
                                                [disabled]="cogthree_disabled"
                                                [(ngModel)]="Developement.remarks"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                    <div class="row">
                        <mat-accordion class="mataccordion">
                            <mat-expansion-panel class="expandpanel">
                                <mat-expansion-panel-header class="exppanel width">
                                    <mat-panel-title class="title ">
                                        4-5 months
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <hr class="hr">
                                <div [hidden]="cognitivefourmonth" style="padding-top:10px">
                                    <div class="row"
                                        *ngFor="let Developement of Devel_Cog_4_5_month_Data" style="padding-top:10px">
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">{{Developement.Description}}</mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                            <mat-radio-group class="matlabel" color="primary"
                                                [(ngModel)]="Developement.No" (change)="send_data()">
                                                <mat-radio-button class="pediatrics_width" [disabled]="cogfive_disabled"
                                                    [value]="false" [checked]="Developement.Yes">Yes</mat-radio-button>
                                                <mat-radio-button class="pediatrics_width" [disabled]="cogfive_disabled"
                                                    [value]="true" [checked]="Developement.No">No</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <textarea *ngIf="Developement.No" class="ipcss txtarea" myInput  (blur)="send_data()"
                                                [disabled]="cogfive_disabled" [(ngModel)]="Developement.remarks"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                    <div class="row">
                        <mat-accordion class="mataccordion">
                            <mat-expansion-panel class="expandpanel">
                                <mat-expansion-panel-header class="exppanel width">
                                    <mat-panel-title class="title ">
                                        6-7 months
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <hr class="hr">
                                <div [hidden]="cognitivesixmonth" style="padding-top:10px">
                                    <div class="row" style="padding-top:10px"
                                        *ngFor="let Developement of Devel_Cog_6_7_month_Data">
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">{{Developement.Description}}</mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                            <mat-radio-group class="matlabel" color="primary"
                                                [(ngModel)]="Developement.No" (change)="send_data()">
                                                <mat-radio-button class="pediatrics_width" [disabled]="cogsix_disabled"
                                                    [value]="false" [checked]="Developement.Yes">Yes</mat-radio-button>
                                                <mat-radio-button class="pediatrics_width" [disabled]="cogsix_disabled"
                                                    [value]="true" [checked]="Developement.No">No</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <textarea *ngIf="Developement.No" class="ipcss txtarea" myInput (blur)="send_data()"
                                                [disabled]="cogsix_disabled" [(ngModel)]="Developement.remarks"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                    <div class="row">
                        <mat-accordion class="mataccordion">
                            <mat-expansion-panel class="expandpanel">
                                <mat-expansion-panel-header class="exppanel width">
                                    <mat-panel-title class="title ">
                                        8-9 months
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <hr class="hr">
                                <div [hidden]="cognitiveeightmonth" style="padding-top:10px">
                                    <div class="row" style="padding-top:10px"
                                        *ngFor="let Developement of Devel_Cog_8_9_month_Data">
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">{{Developement.Description}} </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                            <mat-radio-group class="matlabel" color="primary" (change)="send_data()"
                                                [(ngModel)]="Developement.No">
                                                <mat-radio-button class="pediatrics_width" [disabled]="cogeight_disabled"
                                                    [value]="false" [checked]="Developement.Yes">Yes</mat-radio-button>
                                                <mat-radio-button class="pediatrics_width" [disabled]="cogeight_disabled"
                                                    [value]="true" [checked]="Developement.No">No</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <textarea *ngIf="Developement.No" class="ipcss txtarea" myInput 
                                                [disabled]="cogeight_disabled" (blur)="send_data()"
                                                [(ngModel)]="Developement.remarks"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                    <div class="row">
                        <mat-accordion class="mataccordion">
                            <mat-expansion-panel class="expandpanel">
                                <mat-expansion-panel-header class="exppanel width">
                                    <mat-panel-title class="title ">
                                        10-12 months
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <hr class="hr">
    
                                <div [hidden]="cognitivetenmonth" style="padding-top:10px">
                                    <div class="row" style="padding-top:10px"
                                        *ngFor="let Developement of Devel_Cog_10_12_month_Data">
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">{{Developement.Description}}</mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                            <mat-radio-group class="matlabel" color="primary" (change)="send_data()"
                                                [(ngModel)]="Developement.No">
                                                <mat-radio-button class="pediatrics_width" [disabled]="cogten_disabled"
                                                    [value]="false" [checked]="Developement.Yes">Yes</mat-radio-button>
                                                <mat-radio-button class="pediatrics_width" [disabled]="cogten_disabled"
                                                    [value]="true" [checked]="Developement.No">No</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <textarea *ngIf="Developement.No" class="ipcss txtarea" myInput (blur)="send_data()"
                                                [disabled]="cogten_disabled" [(ngModel)]="Developement.remarks"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
    
                    <p class="tervys_heading"><strong>Language Development</strong></p>
                    <div class="row">
                        <mat-accordion class="mataccordion">
                            <mat-expansion-panel class="expandpanel">
                                <mat-expansion-panel-header class="exppanel width">
                                    <mat-panel-title class="title ">
                                        1-2 months
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <hr class="hr">
    
                                <div [hidden]="lanone" style="padding-top:10px">
                                    <div class="row" style="padding-top:10px"
                                        *ngFor="let Developement of Devel_Lang_1_2_month_Data">
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">{{Developement.Description}}</mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                            <mat-radio-group class="matlabel" color="primary" (change)="send_data()"
                                                [(ngModel)]="Developement.No">
                                                <mat-radio-button class="pediatrics_width" [disabled]="lagone_disabled"
                                                    [value]="false" [checked]="Developement.Yes">Yes</mat-radio-button>
                                                <mat-radio-button class="pediatrics_width" [disabled]="lagone_disabled"
                                                    [value]="true" [checked]="Developement.No">No</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <textarea *ngIf="Developement.No" class="ipcss txtarea" myInput (blur)="send_data()"
                                                [disabled]="lagone_disabled" [(ngModel)]="Developement.remarks"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                    <div class="row">
                        <mat-accordion class="mataccordion">
                            <mat-expansion-panel class="expandpanel">
                                <mat-expansion-panel-header class="exppanel width">
                                    <mat-panel-title class="title ">
                                        3 months
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <hr class="hr">
                                <div [hidden]="lanthree" style="padding-top:10px">
                                    <div class="row" style="padding-top:10px"
                                        *ngFor="let Developement of Devel_Lang_3_month_Data">
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">{{Developement.Description}}</mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                            <mat-radio-group class="matlabel" color="primary" (change)="send_data()"
                                                [(ngModel)]="Developement.No">
                                                <mat-radio-button class="pediatrics_width" [disabled]="lagthree_disabled"
                                                    [value]="false" [checked]="Developement.Yes">Yes</mat-radio-button>
                                                <mat-radio-button class="pediatrics_width" [disabled]="lagthree_disabled"
                                                    [value]="true" [checked]="Developement.No">No</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <textarea *ngIf="Developement.No" class="ipcss txtarea" myInput (blur)="send_data()"
                                                [disabled]="lagthree_disabled"
                                                [(ngModel)]="Developement.remarks"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                    <div class="row">
                        <mat-accordion class="mataccordion">
                            <mat-expansion-panel class="expandpanel">
                                <mat-expansion-panel-header class="exppanel width">
                                    <mat-panel-title class="title ">
                                        4-5 months
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <hr class="hr">
                                <div [hidden]="lanfour" style="padding-top:10px">
                                    <div class="row" style="padding-top:10px"
                                        *ngFor="let Developement of Devel_Lang_4_5_month_Data">
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">{{Developement.Description}}</mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                            <mat-radio-group class="matlabel" color="primary" (change)="send_data()"
                                                [(ngModel)]="Developement.No"> 
                                                <mat-radio-button class="pediatrics_width" [disabled]="lagfour_disabled"
                                                    [value]="false" [checked]="Developement.Yes">Yes</mat-radio-button>
                                                <mat-radio-button class="pediatrics_width" [disabled]="lagfour_disabled"
                                                    [value]="true" [checked]="Developement.No">No</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <textarea *ngIf="Developement.No" class="ipcss txtarea" myInput (blur)="send_data()"
                                                [disabled]="lagfour_disabled" [(ngModel)]="Developement.remarks"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                    <div class="row">
                        <mat-accordion class="mataccordion">
                            <mat-expansion-panel class="expandpanel">
                                <mat-expansion-panel-header class="exppanel width ">
                                    <mat-panel-title class="title ">
                                        6-7 months
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <hr class="hr">
                                <div [hidden]="lansix" style="padding-top:10px">
                                    <div class="row" style="padding-top:10px"
                                        *ngFor="let Developement of Devel_Lang_6_7_month_Data">
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">{{Developement.Description}}</mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                            <mat-radio-group class="matlabel" color="primary" (change)="send_data()"
                                                [(ngModel)]="Developement.No">
                                                <mat-radio-button class="pediatrics_width" [disabled]="lagsix_disabled"
                                                    [value]="false" [checked]="Developement.Yes">Yes</mat-radio-button>
                                                <mat-radio-button class="pediatrics_width" [disabled]="lagsix_disabled"
                                                    [value]="true" [checked]="Developement.No">No</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <textarea *ngIf="Developement.No" class="ipcss txtarea" myInput (blur)="send_data()"
                                                [disabled]="lagsix_disabled" [(ngModel)]="Developement.remarks"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                    <div class="row">
                        <mat-accordion class="mataccordion">
                            <mat-expansion-panel class="expandpanel">
                                <mat-expansion-panel-header class="exppanel width">
                                    <mat-panel-title class="title ">
                                        8-9 months
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <hr class="hr">
                                <div [hidden]="laneight" style="padding-top:10px">
                                    <div class="row" style="padding-top:10px"
                                        *ngFor="let Developement of Devel_Lang_8_9_month_Data">
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">{{Developement.Description}}</mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                            <mat-radio-group class="matlabel" color="primary"
                                                [(ngModel)]="Developement.No" (change)="send_data()">
                                                <mat-radio-button class="pediatrics_width" [disabled]="lageight_disabled"
                                                    [value]="false" [checked]="Developement.Yes">Yes</mat-radio-button>
                                                <mat-radio-button class="pediatrics_width" [disabled]="lageight_disabled"
                                                    [value]="true" [checked]="Developement.No">No</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <textarea *ngIf="Developement.No" class="ipcss txtarea" myInput
                                                [disabled]="lageight_disabled" (blur)="send_data()"
                                                [(ngModel)]="Developement.remarks"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                    <div class="row">
                        <mat-accordion class="mataccordion">
                            <mat-expansion-panel class="expandpanel">
                                <mat-expansion-panel-header class="exppanel width">
                                    <mat-panel-title class="title ">
                                        10-12 months
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <hr class="hr">
                                <div [hidden]="lanten" style="padding-top:10px">
                                    <div class="row" style="padding-top:10px"
                                        *ngFor="let Developement of Devel_Lang_10_12_month_Data">
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">{{Developement.Description}}</mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                            <mat-radio-group class="matlabel" color="primary" (change)="send_data()"
                                                [(ngModel)]="Developement.No">
                                                <mat-radio-button class="pediatrics_width" [disabled]="lagten_disabled"
                                                    [value]="false" [checked]="Developement.Yes">Yes</mat-radio-button>
                                                <mat-radio-button class="pediatrics_width" [disabled]="lagten_disabled"
                                                    [value]="true" [checked]="Developement.No">No</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <textarea *ngIf="Developement.No" class="ipcss txtarea" myInput (blur)="send_data()"
                                                [disabled]="lagten_disabled" [(ngModel)]="Developement.remarks"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
    <div class="row">
        <div class="col-12" style="margin-bottom: 5px;">
            <mat-accordion class="mataccordion">
                <mat-expansion-panel class="expandpanel">
                    <mat-expansion-panel-header class="exppanel width">
                        <mat-panel-title class="title ">
                            1-2 Years (Toddler)
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <hr class="hr">
                    <div [hidden]="twoyear">
                        <div class="row"
                            style="padding-left: 13px;padding-top: 10px">
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                <mat-label class="matlabel">Dentition</mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                <mat-radio-group class="matlabel" color="primary" (change)="send_data()"
                                    [(ngModel)]="twoyearDention_teeth">
                                    <mat-radio-button [disabled]="tedden_disabled" class="pediatrics_width" value="yes">
                                        Normal</mat-radio-button>
                                    <mat-radio-button [disabled]="tedden_disabled" class="pediatrics_width" value="no">
                                        Abnormal</mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                <textarea [hidden]="twoyearDention_teeth=='yes'" class="ipcss txtarea" myInput (blur)="send_data()"
                                    [disabled]="tedden_disabled" [(ngModel)]="twoyearDention_remarks" rows="1"></textarea>
                            </div>
                        </div>
    
                        <div class="row"
                            style="padding-left: 13px;padding-top: 10px">
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                <mat-label class="matlabel">Moral development</mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                <mat-radio-group class="matlabel" color="primary" [(ngModel)]="twoyearmoral_dev" (change)="send_data()">
                                    <mat-radio-button [disabled]="tedmore_disabled" class="pediatrics_width" value="yes">
                                        Yes</mat-radio-button>
                                    <mat-radio-button [disabled]="tedmore_disabled" class="pediatrics_width" value="no">
                                        No</mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                <textarea [hidden]="twoyearmoral_dev=='no'" class="ipcss txtarea" myInput
                                    [disabled]="tedmore_disabled" [(ngModel)]="twoyearmoral_remarks" rows="1" (blur)="send_data()"></textarea>
                            </div>
                        </div>
                        <div class="row"
                            style="padding-left: 13px;padding-top: 10px">
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                <mat-label class="matlabel">Psycho-social development</mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                <mat-radio-group class="matlabel" color="primary" (change)="send_data()"
                                    [(ngModel)]="twoyearphyscho_social">
                                    <mat-radio-button [disabled]="tedsycho_disabled" class="pediatrics_width" value="yes">
                                        Normal</mat-radio-button>
                                    <mat-radio-button [disabled]="tedsycho_disabled" class="pediatrics_width" value="no">
                                        Abnormal</mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                <textarea [hidden]="twoyearphyscho_social=='yes'" class="ipcss txtarea" myInput (blur)="send_data()" 
                                    [disabled]="tedsycho_disabled" [(ngModel)]="twoyearphysco_remarks" rows="1"></textarea>
                            </div>
                        </div>
                        <div class="row"
                            style="padding-left: 13px;padding-top: 10px">
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                <mat-label class="matlabel">Toilet training</mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                <mat-radio-group class="matlabel" color="primary" (change)="send_data()"
                                    [(ngModel)]="twoyeartoilet_training">
                                    <mat-radio-button [disabled]="tedtoilet_disabled" class="pediatrics_width" value="yes">
                                        Normal</mat-radio-button>
                                    <mat-radio-button [disabled]="tedtoilet_disabled" class="pediatrics_width" value="no">
                                        Abnormal</mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                <textarea [hidden]="twoyeartoilet_training=='yes'" class="ipcss txtarea" myInput (blur)="send_data()"
                                    [disabled]="tedtoilet_disabled" [(ngModel)]="twoyeartoilet_remarks" rows="1"></textarea>
                            </div>
                        </div>
                        <div class="row"
                            style="padding-left: 13px;padding-top: 10px">
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                <mat-label class="matlabel">Sleep Pattern</mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                <mat-radio-group class="matlabel" color="primary" [(ngModel)]="two_yearsleep" (change)="send_data()">
                                    <mat-radio-button [disabled]="tedsleep_disabled" class="pediatrics_width" value="yes">
                                        Normal</mat-radio-button>
                                    <mat-radio-button [disabled]="tedsleep_disabled" class="pediatrics_width" value="no">
                                        Abnormal</mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                <textarea [hidden]="two_yearsleep=='yes'" class="ipcss txtarea" myInput (blur)="send_data()"
                                    [disabled]="tedsleep_disabled" [(ngModel)]="two_yearsleepremarks" rows="1"></textarea>
                            </div>
                        </div>
                        <div class="row"
                            style="padding-left: 13px;padding-top: 10px">
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                <mat-label class="matlabel">Playing Pattern</mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                <mat-radio-group class="matlabel" color="primary"
                                    [(ngModel)]="twoyearplaying_pattren" (change)="send_data()">
                                    <mat-radio-button [disabled]="tedplay_disabled" class="pediatrics_width" value="yes">
                                        Normal</mat-radio-button>
                                    <mat-radio-button [disabled]="tedplay_disabled" class="pediatrics_width" value="no">
                                        Abnormal</mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                <textarea [hidden]="twoyearplaying_pattren=='yes'" class="ipcss txtarea" myInput (blur)="send_data()"
                                    [disabled]="tedplay_disabled" [(ngModel)]="two_yearplayremarks" rows="1"></textarea>
                            </div>
                        </div>
    
                        <p class="tervys_heading"><strong>Cognitive Development</strong></p>
                        <div class="row" style="padding-left: 13px;">
                            <mat-accordion class="mataccordion">
                                <mat-expansion-panel class="expandpanel">
                                    <mat-expansion-panel-header class="exppanel width">
                                        <mat-panel-title class="title ">
                                            15 months
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>
                                    <div [hidden]="cogfif" style="padding-top:10px">
                                        <div class="row" style="padding-top:10px"
                                            *ngFor="let Developement of Devel_Cog_15_month_Data">
                                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                                <mat-label class="matlabel">{{Developement.Description}}
                                                </mat-label>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                                <mat-radio-group class="matlabel" color="primary" (change)="send_data()"
                                                    [(ngModel)]="Developement.No">
                                                    <mat-radio-button [disabled]="devfif_disabled" class="pediatrics_width"
                                                        [value]="false" [checked]="Developement.Yes">Yes
                                                    </mat-radio-button>
                                                    <mat-radio-button [disabled]="devfif_disabled" class="pediatrics_width"
                                                        [value]="true" [checked]="Developement.No">No
                                                    </mat-radio-button>
                                                </mat-radio-group>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                                <textarea *ngIf="Developement.No" class="ipcss txtarea" myInput
                                                    [disabled]="devfif_disabled" (blur)="send_data()"
                                                    [(ngModel)]="Developement.remarks"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                        <div class="row" style="padding-left: 13px;">
                            <mat-accordion class="mataccordion">
                                <mat-expansion-panel class="expandpanel">
                                    <mat-expansion-panel-header class="exppanel width">
                                        <mat-panel-title class="title ">
                                            18 months
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>
                                    <hr class="hr">
                                    <div [hidden]="cogeight" style="padding-top:10px">
                                        <div class="row" style="padding-top:10px"
                                            *ngFor="let Developement of Devel_Cog_18_month_Data">
                                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                                <mat-label class="matlabel">{{Developement.Description}}
                                                </mat-label>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                                <mat-radio-group class="matlabel" color="primary" (change)="send_data()"
                                                    [(ngModel)]="Developement.No">
                                                    <mat-radio-button [disabled]="deveighteen_disabled"
                                                        class="pediatrics_width" [value]="false"
                                                        [checked]="Developement.Yes">Yes</mat-radio-button>
                                                    <mat-radio-button [disabled]="deveighteen_disabled"
                                                        class="pediatrics_width" [value]="true" [checked]="Developement.No">
                                                        No</mat-radio-button>
                                                </mat-radio-group>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                                <textarea *ngIf="Developement.No" class="ipcss txtarea" myInput
                                                    [disabled]="deveighteen_disabled" (blur)="send_data()"
                                                    [(ngModel)]="Developement.remarks"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                        <div class="row" style="padding-left: 13px;">
                            <mat-accordion class="mataccordion">
                                <mat-expansion-panel class="expandpanel">
                                    <mat-expansion-panel-header class="exppanel width">
                                        <mat-panel-title class="title ">
                                            24 months
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>
                                    <hr class="hr">
                                    <div [hidden]="cogtwnty" style="padding-top:10px">
                                        <div class="row" style="padding-top:10px"
                                            *ngFor="let Developement of Devel_Cog_24_month_Data">
                                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                                <mat-label class="matlabel">{{Developement.Description}}
                                                </mat-label>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                                <mat-radio-group class="matlabel" color="primary" (change)="send_data()"
                                                    [(ngModel)]="Developement.No">
                                                    <mat-radio-button [disabled]="devtwen_disabled" class="pediatrics_width"
                                                        [value]="false" [checked]="Developement.Yes">Yes
                                                    </mat-radio-button>
                                                    <mat-radio-button [disabled]="devtwen_disabled" class="pediatrics_width"
                                                        [value]="true" [checked]="Developement.No">No
                                                    </mat-radio-button>
                                                </mat-radio-group>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                                <textarea *ngIf="Developement.No" class="ipcss txtarea" myInput
                                                    [disabled]="devtwen_disabled" (blur)="send_data()"
                                                    [(ngModel)]="Developement.remarks"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
    
                        <p class="tervys_heading"><strong>Language Development</strong></p>
                        <div class="row" style="padding-left: 13px;">
                            <mat-accordion class="mataccordion">
                                <mat-expansion-panel class="expandpanel">
                                    <mat-expansion-panel-header class="exppanel width">
                                        <mat-panel-title class="title ">
                                            15 months
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>
                                    <hr class="hr">
                                    <div [hidden]="langfifmonth" style="padding-top:10px">
                                        <div class="row" style="padding-top:10px"
                                            *ngFor="let Developement of Devel_Lang_15_month_Data">
                                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                                <mat-label class="matlabel">{{Developement.Description}}
                                                </mat-label>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 "> 
                                                <mat-radio-group class="matlabel" color="primary" (change)="send_data()"
                                                    [(ngModel)]="Developement.No">
                                                    <mat-radio-button [disabled]="lagfifteen_disabled" [value]="false"
                                                        class="pediatrics_width" [checked]="Developement.Yes">Yes
                                                    </mat-radio-button>
                                                    <mat-radio-button [disabled]="lagfifteen_disabled" [value]="true"
                                                        class="pediatrics_width" [checked]="Developement.No">No
                                                    </mat-radio-button>
                                                </mat-radio-group>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                                <textarea *ngIf="Developement.No" class="ipcss txtarea" myInput
                                                    [disabled]="lagfifteen_disabled" (blur)="send_data()"
                                                    [(ngModel)]="Developement.remarks"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                        <div class="row" style="padding-left: 13px;">
                            <mat-accordion class="mataccordion">
                                <mat-expansion-panel class="expandpanel">
                                    <mat-expansion-panel-header class="exppanel width">
                                        <mat-panel-title class="title ">
                                            18 months
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>
                                    <hr class="hr">
                                    <div [hidden]="langeightmonth" style="padding-top:10px">
                                        <div class="row" style="padding-top:10px"
                                            *ngFor="let Developement of Devel_Lang_18_month_Data">
                                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                                <mat-label class="matlabel">{{Developement.Description}}
                                                </mat-label>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                                <mat-radio-group class="matlabel" color="primary" (change)="send_data()"
                                                    [(ngModel)]="Developement.No">
                                                    <mat-radio-button [disabled]="lageighteen_disabled" [value]="false"
                                                        class="pediatrics_width" [checked]="Developement.Yes">Yes
                                                    </mat-radio-button>
                                                    <mat-radio-button [disabled]="lageighteen_disabled" [value]="true"
                                                        class="pediatrics_width" [checked]="Developement.No">No
                                                    </mat-radio-button>
                                                </mat-radio-group>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                                <textarea *ngIf="Developement.No" class="ipcss txtarea" myInput
                                                    [disabled]="lageighteen_disabled" (blur)="send_data()"
                                                    [(ngModel)]="Developement.remarks"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                        <div class="row"
                            style="padding-left: 13px;padding-top: 10px">
                            <mat-accordion class="mataccordion">
                                <mat-expansion-panel class="expandpanel">
                                    <mat-expansion-panel-header class="exppanel width">
                                        <mat-panel-title class="title ">
                                            24 months
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>
                                    <hr class="hr">
                                    <div [hidden]="langtwntymonth" style="padding-top:10px">
                                        <div class="row" style="padding-top:10px"
                                            *ngFor="let Developement of Devel_Lang_24_month_Data">
                                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                                <mat-label class="matlabel">{{Developement.Description}}
                                                </mat-label>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                                <mat-radio-group class="matlabel" color="primary"
                                                    [(ngModel)]="Developement.No" (change)="send_data()">
                                                    <mat-radio-button [disabled]="lagtwnfour_disabled" [value]="false"
                                                        class="pediatrics_width" [checked]="Developement.Yes">Yes
                                                    </mat-radio-button>
                                                    <mat-radio-button [disabled]="lagtwnfour_disabled" [value]="true"
                                                        class="pediatrics_width" [checked]="Developement.No">No
                                                    </mat-radio-button>
                                                </mat-radio-group>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                                <textarea *ngIf="Developement.No" class="ipcss txtarea" myInput
                                                    [disabled]="lagtwnfour_disabled" (blur)="send_data()"
                                                    [(ngModel)]="Developement.remarks"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
    <div class="row">
        <div class="col-12" style="margin-bottom: 5px;">
            <mat-accordion class="mataccordion">
                <mat-expansion-panel class="expandpanel">
                    <mat-expansion-panel-header class="exppanel width">
                        <mat-panel-title class="title ">
                            3 – 5 Years (Pre-Schooler)
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <hr class="hr">
                    <div class="row" style="padding-left: 13px;padding-top: 10px">
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                            <mat-label class="matlabel">Dentition</mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                            <mat-radio-group class="matlabel" color="primary" (change)="send_data()"
                                [(ngModel)]="threeyearDention_teeth">
                                <mat-radio-button class="pediatrics_width" [disabled]="preden_disabled" value="yes">Normal
                                </mat-radio-button>
                                <mat-radio-button class="pediatrics_width" [disabled]="preden_disabled" value="no">Abnormal
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                            <textarea [hidden]="threeyearDention_teeth=='yes'" class="ipcss txtarea" myInput (blur)="send_data()"
                                [disabled]="preden_disabled" [(ngModel)]="threeyearDention_remarks" rows="1"></textarea>
                        </div>
                    </div>
                    <div class="row" style="padding-left: 13px;padding-top: 10px">
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                            <mat-label class="matlabel">Moral Development</mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                            <mat-radio-group class="matlabel" color="primary" [(ngModel)]="threeyearmoral_dev" (change)="send_data()">
                                <mat-radio-button class="pediatrics_width" [disabled]="premore_disabled" value="yes">Yes
                                </mat-radio-button>
                                <mat-radio-button class="pediatrics_width" [disabled]="premore_disabled" value="no">No
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                            <textarea [hidden]="threeyearmoral_dev=='no'" class="ipcss txtarea" myInput (blur)="send_data()"
                                [disabled]="premore_disabled" [(ngModel)]="threeyearmoral_remarks" rows="1"></textarea>
                        </div>
                    </div>
                    <div class="row" style="padding-left: 13px;padding-top: 10px">
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                            <mat-label class="matlabel">Psycho-Social Development</mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                            <mat-radio-group class="matlabel" color="primary"
                                [(ngModel)]="threeyearphyscho_social" (change)="send_data()">
                                <mat-radio-button class="pediatrics_width" [disabled]="prepyscho_disable" value="yes">Normal
                                </mat-radio-button>
                                <mat-radio-button class="pediatrics_width" [disabled]="prepyscho_disable" value="no">
                                    Abnormal</mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                            <textarea [hidden]="threeyearphyscho_social=='yes'" class="ipcss txtarea" myInput (blur)="send_data()"
                                [disabled]="prepyscho_disable" [(ngModel)]="threeyearphyscho_remarks" rows="1"></textarea>
                        </div>
                    </div>
                    <div class="row" style="padding-left: 13px;padding-top: 10px">
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                            <mat-label class="matlabel">Toilet Training</mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                            <mat-radio-group class="matlabel" color="primary"
                                [(ngModel)]="threeyeartoilet_training" (change)="send_data()">
                                <mat-radio-button class="pediatrics_width" [disabled]="pretoilet_disable" value="yes">Normal
                                </mat-radio-button>
                                <mat-radio-button class="pediatrics_width" [disabled]="pretoilet_disable" value="no">
                                    Abnormal</mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                            <textarea [hidden]="threeyeartoilet_training=='yes'" class="ipcss txtarea" myInput (blur)="send_data()"
                                [disabled]="pretoilet_disable" [(ngModel)]="threeyeartoilet_remarks" rows="1"></textarea>
                        </div>
                    </div>
                    <div class="row" style="padding-left: 13px;padding-top: 10px">
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                            <mat-label class="matlabel">Sleep Pattern</mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                            <mat-radio-group class="matlabel" color="primary" [(ngModel)]="threeyear_sleep" (blur)="send_data()">
                                <mat-radio-button class="pediatrics_width" [disabled]="presleep_disable" value="yes">Normal
                                </mat-radio-button>
                                <mat-radio-button class="pediatrics_width" [disabled]="presleep_disable" value="no">Abnormal
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                            <textarea [hidden]="threeyear_sleep=='yes'" class="ipcss txtarea" myInput (change)="send_data()"
                                [disabled]="presleep_disable" [(ngModel)]="threeyear_sleep_remarks" rows="1"></textarea>
                        </div>
                    </div>
                    <div class="row" style="padding-left: 13px;padding-top: 10px">
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                            <mat-label class="matlabel">Playing Pattern</mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                            <mat-radio-group class="matlabel" color="primary" (change)="send_data()"
                                [(ngModel)]="threeyearplaying_pattren">
                                <mat-radio-button class="pediatrics_width" [disabled]="preplay_disable" value="yes">Normal
                                </mat-radio-button>
                                <mat-radio-button class="pediatrics_width" [disabled]="preplay_disable" value="no">Abnormal
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                            <textarea [hidden]="threeyearplaying_pattren=='yes'" class="ipcss txtarea" myInput (blur)="send_data()"
                                [disabled]="preplay_disable" [(ngModel)]="threeyearplaying_remarks" rows="1"></textarea>
                        </div>
                    </div>
    
                    <p class="tervys_heading"><strong>Cognitive Development</strong></p>
                    <div class="row" style="padding-left: 13px;">
                        <mat-accordion class="mataccordion">
                            <mat-expansion-panel class="expandpanel">
                                <mat-expansion-panel-header class="exppanel width">
                                    <mat-panel-title class="title ">
                                        3 years
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <hr class="hr">
                                <div [hidden]="cogthree" style="padding-top:10px">
                                    <div class="row" style="padding-top:10px"
                                        *ngFor="let Developement of Devel_Cog_3_year_Data">
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">{{Developement.Description}}</mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                            <mat-radio-group class="matlabel" color="primary" (change)="send_data()"
                                                [(ngModel)]="Developement.No">
                                                <mat-radio-button class="pediatrics_width" [disabled]="devthyear_disabled"
                                                    [value]="false" [checked]="Developement.Yes">Yes</mat-radio-button>
                                                <mat-radio-button class="pediatrics_width" [disabled]="devthyear_disabled"
                                                    [value]="true" [checked]="Developement.No">No</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <textarea *ngIf="Developement.No" class="ipcss txtarea" myInput
                                                [disabled]="devthyear_disabled" (blur)="send_data()"
                                                [(ngModel)]="Developement.remarks"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                    <div class="row" style="padding-left: 13px;">
                        <mat-accordion class="mataccordion">
                            <mat-expansion-panel class="expandpanel">
                                <mat-expansion-panel-header class="exppanel width">
                                    <mat-panel-title class="title ">
                                        4 Years
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <hr class="hr">
                                <div [hidden]="cogfour" style="padding-top:10px">
                                    <div class="row" style="padding-top:10px"
                                        *ngFor="let Developement of Devel_Cog_4_year_Data">
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">{{Developement.Description}}</mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                            <mat-radio-group class="matlabel" color="primary"
                                                [(ngModel)]="Developement.No">
                                                <mat-radio-button class="pediatrics_width" [disabled]="devfouryear_disabled" (change)="send_data()"
                                                    [value]="false" [checked]="Developement.Yes">Yes</mat-radio-button>
                                                <mat-radio-button class="pediatrics_width" [disabled]="devfouryear_disabled"
                                                    [value]="true" [checked]="Developement.No">No</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <textarea *ngIf="Developement.No" class="ipcss txtarea" myInput
                                                [disabled]="devfouryear_disabled" (blur)="send_data()"
                                                [(ngModel)]="Developement.remarks"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                    <div class="row" style="padding-left: 13px;">
                        <mat-accordion class="mataccordion">
                            <mat-expansion-panel class="expandpanel">
                                <mat-expansion-panel-header class="exppanel width">
                                    <mat-panel-title class="title ">
                                        5 Years
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <hr class="hr">
                                <div [hidden]="cogfive" style="padding-top:10px">
                                    <div class="row" style="padding-top:10px"
                                        *ngFor="let Developement of Devel_Cog_5_year_Data">
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">{{Developement.Description}}</mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                            <mat-radio-group class="matlabel" color="primary" (change)="send_data()"
                                                [(ngModel)]="Developement.No">
                                                <mat-radio-button class="pediatrics_width" [disabled]="devfiveyear_disabled" 
                                                    [value]="false" [checked]="Developement.Yes">Yes</mat-radio-button>
                                                <mat-radio-button class="pediatrics_width" [disabled]="devfiveyear_disabled"
                                                    [value]="true" [checked]="Developement.No">No</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <textarea *ngIf="Developement.No" class="ipcss txtarea" myInput (blur)="send_data()"
                                                [disabled]="devfiveyear_disabled"
                                                [(ngModel)]="Developement.remarks"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
    
                    <p class="tervys_heading"><strong>Language Development</strong></p>
                    <div class="row" style="padding-left: 13px;">
                        <mat-accordion class="mataccordion">
                            <mat-expansion-panel class="expandpanel">
                                <mat-expansion-panel-header class="exppanel width">
                                    <mat-panel-title class="title ">
                                        3 years
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <hr class="hr">
                                <div [hidden]="lanthreeyear" style="padding-top:10px">
                                    <div class="row" style="padding-top:10px"
                                        *ngFor="let Developement of Devel_Lang_3_year_Data">
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">{{Developement.Description}}</mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                            <mat-radio-group class="matlabel" color="primary" (change)="send_data()"
                                                [(ngModel)]="Developement.No">
                                                <mat-radio-button class="pediatrics_width" [disabled]="lanthyear_disabled"
                                                    [value]="false" [checked]="Developement.Yes">Yes</mat-radio-button>
                                                <mat-radio-button class="pediatrics_width" [disabled]="lanthyear_disabled"
                                                    [value]="true" [checked]="Developement.No">No</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <textarea *ngIf="Developement.No" class="ipcss txtarea" myInput (blur)="send_data()"
                                                [disabled]="lanthyear_disabled"
                                                [(ngModel)]="Developement.remarks"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                    <div class="row" style="padding-left: 13px;">
                        <mat-accordion class="mataccordion">
                            <mat-expansion-panel class="expandpanel">
                                <mat-expansion-panel-header class="exppanel width">
                                    <mat-panel-title class="title ">
                                        4 Years
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <hr class="hr">
                                <div [hidden]="lanfyear" style="padding-top:10px">
                                    <div class="row" style="padding-top:10px"
                                        *ngFor="let Developement of Devel_Lang_4_year_Data">
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">{{Developement.Description}}</mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                            <mat-radio-group class="matlabel" color="primary" (change)="send_data()"
                                                [(ngModel)]="Developement.No">
                                                <mat-radio-button class="pediatrics_width" [disabled]="lanfouryear_disabled"
                                                    [value]="false" [checked]="Developement.Yes">Yes</mat-radio-button>
                                                <mat-radio-button class="pediatrics_width" [disabled]="lanfouryear_disabled"
                                                    [value]="true" [checked]="Developement.No">No</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <textarea *ngIf="Developement.No" class="ipcss txtarea" myInput (blur)="send_data()"
                                                [disabled]="lanfouryear_disabled"
                                                [(ngModel)]="Developement.remarks"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                    <div class="row" style="padding-left: 13px;">
                        <mat-accordion class="mataccordion">
                            <mat-expansion-panel class="expandpanel">
                                <mat-expansion-panel-header class="exppanel width">
                                    <mat-panel-title class="title ">
                                        5 Years
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <hr class="hr">
                                <div [hidden]="lanfiveyear" style="padding-top:10px">
                                    <div class="row" style="padding-top:10px"
                                        *ngFor="let Developement of Devel_Lang_5_year_Data">
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                            <mat-label class="matlabel">{{Developement.Description}}</mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                            <mat-radio-group class="matlabel" color="primary" (change)="send_data()"
                                                [(ngModel)]="Developement.No">
                                                <mat-radio-button class="pediatrics_width" [disabled]="lanfiveyear_disabled"
                                                    [value]="false" [checked]="Developement.Yes">Yes</mat-radio-button>
                                                <mat-radio-button class="pediatrics_width" [disabled]="lanfiveyear_disabled"
                                                    [value]="true" [checked]="Developement.No">No</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <textarea *ngIf="Developement.No" class="ipcss txtarea" myInput (blur)="send_data()"
                                                [disabled]="lanfiveyear_disabled"
                                                [(ngModel)]="Developement.remarks"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
</div>

<div *ngIf="pediaDevelopFlag == 'max'">
    <div class="row">
        <div class="col-12" style="margin-bottom: 5px;">
            <mat-accordion class="mataccordion">
                <mat-expansion-panel class="expandpanel">
                    <mat-expansion-panel-header class="exppanel width">
                        <mat-panel-title class="title">
                            &#60;1 Year (Infant)
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <hr class="hr">
    
                    <div class="row" style="padding-left: 13px;padding-top: 10px">
                        <div class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 ">
                            <mat-label class="matlabel pediatrics_lbl_width">Deciduous Teeth </mat-label>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 ">
                            <mat-radio-group class="matlabel" color="primary" [(ngModel)]="oneyearDecide_teeth" blurblur>
                                <mat-radio-button  [disabled]="client" class="pediatrics_width" value="yes">Normal</mat-radio-button>
                                <mat-radio-button  [disabled]="client" class="pediatrics_width" value="no">Abnormal</mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                            <textarea [hidden]="oneyearDecide_teeth=='yes'" class="ipcss txtarea" myInput (blur)="send_data()"
                                [disabled]="decide_disabled" [(ngModel)]="oneyearDecide_teethremarks" rows="1"></textarea>
                        </div>
                    </div>
                    <div class="row" style="padding-left: 13px;padding-top: 10px">
                        <div class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 ">
                            <mat-label class="matlabel">Dental Caries</mat-label>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                            <mat-radio-group class="matlabel" color="primary" [(ngModel)]="oneyearDental_caries" (change)="send_data()">
                                <mat-radio-button [disabled]="client" class="pediatrics_width" value="yes">Yes</mat-radio-button>
                                <mat-radio-button [disabled]="client" class="pediatrics_width" value="no">No</mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                            <textarea [hidden]="oneyearDental_caries=='no'" class="ipcss txtarea" myInput (blur)="send_data()"
                                [disabled]="den_disabled" [(ngModel)]="oneyeardental_cariesremarks" rows="1"></textarea>
                        </div>
                    </div>
                    <div class="row" style="padding-left: 13px;padding-top: 10px">
                        <div class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 ">
                            <mat-label class="matlabel">Object Permanence</mat-label>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                            <mat-radio-group class="matlabel" color="primary" (change)="send_data()"
                                [(ngModel)]="oneyearobject_permonce">
                                <mat-radio-button [disabled]="client" class="pediatrics_width" value="yes">Normal</mat-radio-button>
                                <mat-radio-button [disabled]="client" class="pediatrics_width" value="no">Abnormal</mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                            <textarea [hidden]="oneyearobject_permonce=='yes'" class="ipcss txtarea" myInput (blur)="send_data()"
                                [disabled]="object_disabled" [(ngModel)]="oneyearobject_remarks" rows="1"></textarea>
                        </div>
                    </div>
                    <div class="row" style="padding-left: 13px;padding-top: 10px">
                        <div class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 ">
                            <mat-label class="matlabel">Sleep Pattern</mat-label>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                            <mat-radio-group class="matlabel" color="primary"  (change)="send_data()" [(ngModel)]="oneyearsleep_pattern">
                                <mat-radio-button [disabled]="client" class="pediatrics_width" value="yes">Normal</mat-radio-button>
                                <mat-radio-button [disabled]="client" class="pediatrics_width" value="no">Abnormal</mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                            <textarea [hidden]="oneyearsleep_pattern=='yes'" class="ipcss txtarea" myInput (blur)="send_data()"
                                [disabled]="sleep_disabled" [(ngModel)]="oneyear_sleepremarks" rows="1"></textarea>
                        </div>
                    </div>
    
                    <p class="tervys_heading"><strong>Cognitive Development</strong></p>
                    <div class="row">
                        <mat-accordion class="mataccordion">
                            <mat-expansion-panel class="expandpanel">
                                <mat-expansion-panel-header class="exppanel width">
                                    <mat-panel-title class="title">
                                        1-2 months
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <hr class="hr">
                                <div [hidden]="cognitiveonemonth" style="padding-top:10px">
                                    <div class="row"
                                        *ngFor="let Developement of Devel_Cog_1_2_month_Data;" style="padding-top:10px">
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">{{Developement.Description}}</mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                            <mat-radio-group class="matlabel" color="primary" (change)="send_data()"
                                                [(ngModel)]="Developement.No">
                                                <mat-radio-button class="pediatrics_width" [disabled]="cogone_disabled"
                                                    [value]="false" [checked]="Developement.Yes">Normal</mat-radio-button>
                                                <mat-radio-button class="pediatrics_width" [disabled]="cogone_disabled"
                                                    [value]="true" [checked]="Developement.No">Abnormal</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                            <textarea *ngIf="Developement.No" class="ipcss txtarea" myInput (blur)="send_data()"
                                                [disabled]="cogone_disabled" [(ngModel)]="Developement.remarks"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                    <div class="row">
                        <mat-accordion class="mataccordion">
                            <mat-expansion-panel class="expandpanel">
                                <mat-expansion-panel-header class="exppanel width">
                                    <mat-panel-title class="title ">
                                        3 months
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <hr class="hr">
                                <div [hidden]="cognitivethreemonth" style="padding-top:10px">
                                    <div class="row"
                                        *ngFor="let Developement of Devel_Cog_3_month_Data" style="padding-top:10px">
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">{{Developement.Description}}</mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                            <mat-radio-group class="matlabel" color="primary" (change)="send_data()"
                                                [(ngModel)]="Developement.No"> 
                                                <mat-radio-button class="pediatrics_width" [disabled]="cogthree_disabled"
                                                    [value]="false" [checked]="Developement.Yes">Yes</mat-radio-button>
                                                <mat-radio-button class="pediatrics_width" [disabled]="cogthree_disabled"
                                                    [value]="true" [checked]="Developement.No">No</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                            <textarea *ngIf="Developement.No" class="ipcss txtarea" myInput (blur)="send_data()"
                                                [disabled]="cogthree_disabled"
                                                [(ngModel)]="Developement.remarks"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                    <div class="row">
                        <mat-accordion class="mataccordion">
                            <mat-expansion-panel class="expandpanel">
                                <mat-expansion-panel-header class="exppanel width">
                                    <mat-panel-title class="title ">
                                        4-5 months
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <hr class="hr">
                                <div [hidden]="cognitivefourmonth" style="padding-top:10px">
                                    <div class="row"
                                        *ngFor="let Developement of Devel_Cog_4_5_month_Data" style="padding-top:10px">
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">{{Developement.Description}}</mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                            <mat-radio-group class="matlabel" color="primary" (change)="send_data()"
                                                [(ngModel)]="Developement.No">
                                                <mat-radio-button class="pediatrics_width" [disabled]="cogfive_disabled"
                                                    [value]="false" [checked]="Developement.Yes">Yes</mat-radio-button>
                                                <mat-radio-button class="pediatrics_width" [disabled]="cogfive_disabled"
                                                    [value]="true" [checked]="Developement.No">No</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                            <textarea *ngIf="Developement.No" class="ipcss txtarea" myInput (blur)="send_data()"
                                                [disabled]="cogfive_disabled" [(ngModel)]="Developement.remarks"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                    <div class="row">
                        <mat-accordion class="mataccordion">
                            <mat-expansion-panel class="expandpanel">
                                <mat-expansion-panel-header class="exppanel width">
                                    <mat-panel-title class="title ">
                                        6-7 months
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <hr class="hr">
                                <div [hidden]="cognitivesixmonth" style="padding-top:10px">
                                    <div class="row" style="padding-top:10px"
                                        *ngFor="let Developement of Devel_Cog_6_7_month_Data">
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">{{Developement.Description}}</mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                            <mat-radio-group class="matlabel" color="primary" (change)="send_data()"
                                                [(ngModel)]="Developement.No">
                                                <mat-radio-button class="pediatrics_width" [disabled]="cogsix_disabled"
                                                    [value]="false" [checked]="Developement.Yes">Yes</mat-radio-button>
                                                <mat-radio-button class="pediatrics_width" [disabled]="cogsix_disabled"
                                                    [value]="true" [checked]="Developement.No">No</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                            <textarea *ngIf="Developement.No" class="ipcss txtarea" myInput (blur)="send_data()"
                                                [disabled]="cogsix_disabled" [(ngModel)]="Developement.remarks"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                    <div class="row">
                        <mat-accordion class="mataccordion">
                            <mat-expansion-panel class="expandpanel">
                                <mat-expansion-panel-header class="exppanel width">
                                    <mat-panel-title class="title ">
                                        8-9 months
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <hr class="hr">
                                <div [hidden]="cognitiveeightmonth" style="padding-top:10px">
                                    <div class="row" style="padding-top:10px"
                                        *ngFor="let Developement of Devel_Cog_8_9_month_Data">
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">{{Developement.Description}} </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                            <mat-radio-group class="matlabel" color="primary" (change)="send_data()"
                                                [(ngModel)]="Developement.No">
                                                <mat-radio-button class="pediatrics_width" [disabled]="cogeight_disabled"
                                                    [value]="false" [checked]="Developement.Yes">Yes</mat-radio-button>
                                                <mat-radio-button class="pediatrics_width" [disabled]="cogeight_disabled"
                                                    [value]="true" [checked]="Developement.No">No</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                            <textarea *ngIf="Developement.No" class="ipcss txtarea" myInput (blur)="send_data()"
                                                [disabled]="cogeight_disabled"
                                                [(ngModel)]="Developement.remarks"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                    <div class="row">
                        <mat-accordion class="mataccordion">
                            <mat-expansion-panel class="expandpanel">
                                <mat-expansion-panel-header class="exppanel width">
                                    <mat-panel-title class="title ">
                                        10-12 months
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <hr class="hr">
    
                                <div [hidden]="cognitivetenmonth" style="padding-top:10px">
                                    <div class="row" style="padding-top:10px"
                                        *ngFor="let Developement of Devel_Cog_10_12_month_Data">
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">{{Developement.Description}}</mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                            <mat-radio-group class="matlabel" color="primary" (change)="send_data()"
                                                [(ngModel)]="Developement.No">
                                                <mat-radio-button class="pediatrics_width" [disabled]="cogten_disabled"
                                                    [value]="false" [checked]="Developement.Yes">Yes</mat-radio-button>
                                                <mat-radio-button class="pediatrics_width" [disabled]="cogten_disabled"
                                                    [value]="true" [checked]="Developement.No">No</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                            <textarea *ngIf="Developement.No" class="ipcss txtarea" myInput (blur)="send_data()"
                                                [disabled]="cogten_disabled" [(ngModel)]="Developement.remarks"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
    
                    <p class="tervys_heading"><strong>Language Development</strong></p>
                    <div class="row">
                        <mat-accordion class="mataccordion">
                            <mat-expansion-panel class="expandpanel">
                                <mat-expansion-panel-header class="exppanel width">
                                    <mat-panel-title class="title ">
                                        1-2 months
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <hr class="hr">
    
                                <div [hidden]="lanone" style="padding-top:10px">
                                    <div class="row" style="padding-top:10px"
                                        *ngFor="let Developement of Devel_Lang_1_2_month_Data">
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">{{Developement.Description}}</mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                            <mat-radio-group class="matlabel" color="primary" (change)="send_data()"
                                                [(ngModel)]="Developement.No">
                                                <mat-radio-button class="pediatrics_width" [disabled]="lagone_disabled"
                                                    [value]="false" [checked]="Developement.Yes">Yes</mat-radio-button>
                                                <mat-radio-button class="pediatrics_width" [disabled]="lagone_disabled"
                                                    [value]="true" [checked]="Developement.No">No</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                            <textarea *ngIf="Developement.No" class="ipcss txtarea" myInput  (blur)="send_data()"
                                                [disabled]="lagone_disabled" [(ngModel)]="Developement.remarks"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                    <div class="row">
                        <mat-accordion class="mataccordion">
                            <mat-expansion-panel class="expandpanel">
                                <mat-expansion-panel-header class="exppanel width">
                                    <mat-panel-title class="title ">
                                        3 months
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <hr class="hr">
                                <div [hidden]="lanthree" style="padding-top:10px">
                                    <div class="row" style="padding-top:10px"
                                        *ngFor="let Developement of Devel_Lang_3_month_Data">
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">{{Developement.Description}}</mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                            <mat-radio-group class="matlabel" color="primary" (change)="send_data()"
                                                [(ngModel)]="Developement.No">
                                                <mat-radio-button class="pediatrics_width" [disabled]="lagthree_disabled"
                                                    [value]="false" [checked]="Developement.Yes">Yes</mat-radio-button>
                                                <mat-radio-button class="pediatrics_width" [disabled]="lagthree_disabled"
                                                    [value]="true" [checked]="Developement.No">No</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                            <textarea *ngIf="Developement.No" class="ipcss txtarea" myInput (blur)="send_data()"
                                                [disabled]="lagthree_disabled"
                                                [(ngModel)]="Developement.remarks"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                    <div class="row">
                        <mat-accordion class="mataccordion">
                            <mat-expansion-panel class="expandpanel">
                                <mat-expansion-panel-header class="exppanel width">
                                    <mat-panel-title class="title ">
                                        4-5 months
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <hr class="hr">
                                <div [hidden]="lanfour" style="padding-top:10px">
                                    <div class="row" style="padding-top:10px"
                                        *ngFor="let Developement of Devel_Lang_4_5_month_Data">
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">{{Developement.Description}}</mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                            <mat-radio-group class="matlabel" color="primary" (change)="send_data()"
                                                [(ngModel)]="Developement.No">
                                                <mat-radio-button class="pediatrics_width" [disabled]="lagfour_disabled"
                                                    [value]="false" [checked]="Developement.Yes">Yes</mat-radio-button>
                                                <mat-radio-button class="pediatrics_width" [disabled]="lagfour_disabled"
                                                    [value]="true" [checked]="Developement.No">No</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                            <textarea *ngIf="Developement.No" class="ipcss txtarea" myInput
                                                [disabled]="lagfour_disabled" [(ngModel)]="Developement.remarks" (blur)="send_data()"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                    <div class="row">
                        <mat-accordion class="mataccordion">
                            <mat-expansion-panel class="expandpanel">
                                <mat-expansion-panel-header class="exppanel width ">
                                    <mat-panel-title class="title ">
                                        6-7 months
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <hr class="hr">
                                <div [hidden]="lansix" style="padding-top:10px">
                                    <div class="row" style="padding-top:10px"
                                        *ngFor="let Developement of Devel_Lang_6_7_month_Data">
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">{{Developement.Description}}</mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                            <mat-radio-group class="matlabel" color="primary" (change)="send_data()"
                                                [(ngModel)]="Developement.No">
                                                <mat-radio-button class="pediatrics_width" [disabled]="lagsix_disabled"
                                                    [value]="false" [checked]="Developement.Yes">Yes</mat-radio-button>
                                                <mat-radio-button class="pediatrics_width" [disabled]="lagsix_disabled"
                                                    [value]="true" [checked]="Developement.No">No</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                            <textarea *ngIf="Developement.No" class="ipcss txtarea" myInput (blur)="send_data()"
                                                [disabled]="lagsix_disabled" [(ngModel)]="Developement.remarks"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                    <div class="row">
                        <mat-accordion class="mataccordion">
                            <mat-expansion-panel class="expandpanel">
                                <mat-expansion-panel-header class="exppanel width">
                                    <mat-panel-title class="title ">
                                        8-9 months
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <hr class="hr">
                                <div [hidden]="laneight" style="padding-top:10px">
                                    <div class="row" style="padding-top:10px"
                                        *ngFor="let Developement of Devel_Lang_8_9_month_Data">
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">{{Developement.Description}}</mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                            <mat-radio-group class="matlabel" color="primary" (change)="send_data()"
                                                [(ngModel)]="Developement.No">
                                                <mat-radio-button class="pediatrics_width" [disabled]="lageight_disabled"
                                                    [value]="false" [checked]="Developement.Yes">Yes</mat-radio-button>
                                                <mat-radio-button class="pediatrics_width" [disabled]="lageight_disabled"
                                                    [value]="true" [checked]="Developement.No">No</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                            <textarea *ngIf="Developement.No" class="ipcss txtarea" myInput (blur)="send_data()"
                                                [disabled]="lageight_disabled"
                                                [(ngModel)]="Developement.remarks"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                    <div class="row">
                        <mat-accordion class="mataccordion">
                            <mat-expansion-panel class="expandpanel">
                                <mat-expansion-panel-header class="exppanel width">
                                    <mat-panel-title class="title ">
                                        10-12 months
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <hr class="hr">
                                <div [hidden]="lanten" style="padding-top:10px">
                                    <div class="row" style="padding-top:10px"
                                        *ngFor="let Developement of Devel_Lang_10_12_month_Data">
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">{{Developement.Description}}</mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                            <mat-radio-group class="matlabel" color="primary" (change)="send_data()"
                                                [(ngModel)]="Developement.No">
                                                <mat-radio-button class="pediatrics_width" [disabled]="lagten_disabled"
                                                    [value]="false" [checked]="Developement.Yes">Yes</mat-radio-button>
                                                <mat-radio-button class="pediatrics_width" [disabled]="lagten_disabled"
                                                    [value]="true" [checked]="Developement.No">No</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                            <textarea *ngIf="Developement.No" class="ipcss txtarea" myInput (blur)="send_data()"
                                                [disabled]="lagten_disabled" [(ngModel)]="Developement.remarks"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
    <div class="row">
        <div class="col-12" style="margin-bottom: 5px;">
            <mat-accordion class="mataccordion">
                <mat-expansion-panel class="expandpanel">
                    <mat-expansion-panel-header class="exppanel width">
                        <mat-panel-title class="title ">
                            1-2 Years (Toddler)
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <hr class="hr">
                    <div [hidden]="twoyear">
                        <div class="row"
                            style="padding-left: 13px;padding-top: 10px">
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                <mat-label class="matlabel">Dentition</mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                <mat-radio-group class="matlabel" color="primary" (change)="send_data()"
                                    [(ngModel)]="twoyearDention_teeth">
                                    <mat-radio-button [disabled]="tedden_disabled" class="pediatrics_width" value="yes">
                                        Normal</mat-radio-button>
                                    <mat-radio-button [disabled]="tedden_disabled" class="pediatrics_width" value="no">
                                        Abnormal</mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                <textarea [hidden]="twoyearDention_teeth=='yes'" class="ipcss txtarea" myInput (blur)="send_data()"
                                    [disabled]="tedden_disabled" [(ngModel)]="twoyearDention_remarks" rows="1"></textarea>
                            </div>
                        </div>
    
                        <div class="row"
                            style="padding-left: 13px;padding-top: 10px">
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                <mat-label class="matlabel">Moral development</mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                <mat-radio-group class="matlabel" color="primary" [(ngModel)]="twoyearmoral_dev" (change)="send_data()">
                                    <mat-radio-button [disabled]="tedmore_disabled" class="pediatrics_width" value="yes">
                                        Yes</mat-radio-button>
                                    <mat-radio-button [disabled]="tedmore_disabled" class="pediatrics_width" value="no">
                                        No</mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                <textarea [hidden]="twoyearmoral_dev=='no'" class="ipcss txtarea" myInput (blur)="send_data()"
                                    [disabled]="tedmore_disabled" [(ngModel)]="twoyearmoral_remarks" rows="1"></textarea>
                            </div>
                        </div>
                        <div class="row"
                            style="padding-left: 13px;padding-top: 10px">
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                <mat-label class="matlabel">Psycho-social development</mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                <mat-radio-group class="matlabel" color="primary" (change)="send_data()"
                                    [(ngModel)]="twoyearphyscho_social">
                                    <mat-radio-button [disabled]="tedmore_disabled" class="pediatrics_width" value="yes">
                                        Normal</mat-radio-button>
                                    <mat-radio-button [disabled]="tedmore_disabled" class="pediatrics_width" value="no">
                                        Abnormal</mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                <textarea [hidden]="twoyearphyscho_social=='yes'" class="ipcss txtarea" myInput (blur)="send_data()"
                                    [disabled]="tedsycho_disabled" [(ngModel)]="twoyearphysco_remarks" rows="1"></textarea>
                            </div>
                        </div>
                        <div class="row"
                            style="padding-left: 13px;padding-top: 10px">
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                <mat-label class="matlabel">Toilet training</mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                <mat-radio-group class="matlabel" color="primary" (change)="send_data()"
                                    [(ngModel)]="twoyeartoilet_training">
                                    <mat-radio-button [disabled]="tedtoilet_disabled" class="pediatrics_width" value="yes">
                                        Normal</mat-radio-button>
                                    <mat-radio-button [disabled]="tedtoilet_disabled" class="pediatrics_width" value="no">
                                        Abnormal</mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                <textarea [hidden]="twoyeartoilet_training=='yes'" class="ipcss txtarea" myInput (blur)="send_data()"
                                    [disabled]="tedtoilet_disabled" [(ngModel)]="twoyeartoilet_remarks" rows="1"></textarea>
                            </div>
                        </div>
                        <div class="row"
                            style="padding-left: 13px;padding-top: 10px">
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                <mat-label class="matlabel">Sleep Pattern</mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                <mat-radio-group class="matlabel" color="primary" [(ngModel)]="two_yearsleep" (change)="send_data()">
                                    <mat-radio-button [disabled]="tedsleep_disabled" class="pediatrics_width" value="yes">
                                        Normal</mat-radio-button>
                                    <mat-radio-button [disabled]="tedsleep_disabled" class="pediatrics_width" value="no">
                                        Abnormal</mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4"> 
                                <textarea [hidden]="two_yearsleep=='yes'" class="ipcss txtarea" myInput (blur)="send_data()"
                                    [disabled]="tedsleep_disabled" [(ngModel)]="two_yearsleepremarks" rows="1"></textarea>
                            </div>
                        </div>
                        <div class="row"
                            style="padding-left: 13px;padding-top: 10px">
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                <mat-label class="matlabel">Playing Pattern</mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                <mat-radio-group class="matlabel" color="primary" (change)="send_data()"
                                    [(ngModel)]="twoyearplaying_pattren">
                                    <mat-radio-button [disabled]="tedplay_disabled" class="pediatrics_width" value="yes">
                                        Normal</mat-radio-button>
                                    <mat-radio-button [disabled]="tedplay_disabled" class="pediatrics_width" value="no">
                                        Abnormal</mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                <textarea [hidden]="twoyearplaying_pattren=='yes'" class="ipcss txtarea" myInput (blur)="send_data()"
                                    [disabled]="tedplay_disabled" [(ngModel)]="two_yearplayremarks" rows="1"></textarea>
                            </div>
                        </div>
    
                        <p class="tervys_heading"><strong>Cognitive Development</strong></p>
                        <div class="row" style="padding-left: 13px;">
                            <mat-accordion class="mataccordion">
                                <mat-expansion-panel class="expandpanel">
                                    <mat-expansion-panel-header class="exppanel width">
                                        <mat-panel-title class="title ">
                                            15 months
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>
                                    <div [hidden]="cogfif" style="padding-top:10px">
                                        <div class="row" style="padding-top:10px"
                                            *ngFor="let Developement of Devel_Cog_15_month_Data">
                                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                                <mat-label class="matlabel">{{Developement.Description}}
                                                </mat-label>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                                <mat-radio-group class="matlabel" color="primary" (change)="send_data()"
                                                    [(ngModel)]="Developement.No">
                                                    <mat-radio-button [disabled]="devfif_disabled" class="pediatrics_width"
                                                        [value]="false" [checked]="Developement.Yes">Yes
                                                    </mat-radio-button>
                                                    <mat-radio-button [disabled]="devfif_disabled" class="pediatrics_width"
                                                        [value]="true" [checked]="Developement.No">No
                                                    </mat-radio-button>
                                                </mat-radio-group>
                                            </div>
                                            <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                                <textarea *ngIf="Developement.No" class="ipcss txtarea" myInput (blur)="send_data()"
                                                    [disabled]="devfif_disabled"
                                                    [(ngModel)]="Developement.remarks"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                        <div class="row" style="padding-left: 13px;">
                            <mat-accordion class="mataccordion">
                                <mat-expansion-panel class="expandpanel">
                                    <mat-expansion-panel-header class="exppanel width">
                                        <mat-panel-title class="title ">
                                            18 months
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>
                                    <hr class="hr">
                                    <div [hidden]="cogeight" style="padding-top:10px">
                                        <div class="row" style="padding-top:10px"
                                            *ngFor="let Developement of Devel_Cog_18_month_Data">
                                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                                <mat-label class="matlabel">{{Developement.Description}}
                                                </mat-label>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                                <mat-radio-group class="matlabel" color="primary" (change)="send_data()"
                                                    [(ngModel)]="Developement.No">
                                                    <mat-radio-button [disabled]="deveighteen_disabled"
                                                        class="pediatrics_width" [value]="false"
                                                        [checked]="Developement.Yes">Yes</mat-radio-button>
                                                    <mat-radio-button [disabled]="deveighteen_disabled"
                                                        class="pediatrics_width" [value]="true" [checked]="Developement.No">
                                                        No</mat-radio-button>
                                                </mat-radio-group>
                                            </div>
                                            <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                                <textarea *ngIf="Developement.No" class="ipcss txtarea" myInput (blur)="send_data()"
                                                    [disabled]="deveighteen_disabled"
                                                    [(ngModel)]="Developement.remarks"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                        <div class="row" style="padding-left: 13px;">
                            <mat-accordion class="mataccordion">
                                <mat-expansion-panel class="expandpanel">
                                    <mat-expansion-panel-header class="exppanel width">
                                        <mat-panel-title class="title ">
                                            24 months
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>
                                    <hr class="hr">
                                    <div [hidden]="cogtwnty" style="padding-top:10px">
                                        <div class="row" style="padding-top:10px"
                                            *ngFor="let Developement of Devel_Cog_24_month_Data">
                                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                                <mat-label class="matlabel">{{Developement.Description}}
                                                </mat-label>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                                <mat-radio-group class="matlabel" color="primary" (change)="send_data()"
                                                    [(ngModel)]="Developement.No"> 
                                                    <mat-radio-button [disabled]="devtwen_disabled" class="pediatrics_width"
                                                        [value]="false" [checked]="Developement.Yes">Yes
                                                    </mat-radio-button>
                                                    <mat-radio-button [disabled]="devtwen_disabled" class="pediatrics_width"
                                                        [value]="true" [checked]="Developement.No">No
                                                    </mat-radio-button>
                                                </mat-radio-group>
                                            </div>
                                            <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                                <textarea *ngIf="Developement.No" class="ipcss txtarea" myInput (blur)="send_data()"
                                                    [disabled]="devtwen_disabled"
                                                    [(ngModel)]="Developement.remarks"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
    
                        <p class="tervys_heading"><strong>Language Development</strong></p>
                        <div class="row" style="padding-left: 13px;">
                            <mat-accordion class="mataccordion">
                                <mat-expansion-panel class="expandpanel">
                                    <mat-expansion-panel-header class="exppanel width">
                                        <mat-panel-title class="title ">
                                            15 months
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>
                                    <hr class="hr">
                                    <div [hidden]="langfifmonth" style="padding-top:10px">
                                        <div class="row" style="padding-top:10px"
                                            *ngFor="let Developement of Devel_Lang_15_month_Data">
                                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                                <mat-label class="matlabel">{{Developement.Description}}
                                                </mat-label>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                                <mat-radio-group class="matlabel" color="primary" (change)="send_data()"
                                                    [(ngModel)]="Developement.No">
                                                    <mat-radio-button [disabled]="lagfifteen_disabled" [value]="false"
                                                        class="pediatrics_width" [checked]="Developement.Yes">Yes
                                                    </mat-radio-button>
                                                    <mat-radio-button [disabled]="lagfifteen_disabled" [value]="true"
                                                        class="pediatrics_width" [checked]="Developement.No">No
                                                    </mat-radio-button>
                                                </mat-radio-group>
                                            </div>
                                            <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                                <textarea *ngIf="Developement.No" class="ipcss txtarea" myInput (blur)="send_data()"
                                                    [disabled]="lagfifteen_disabled"
                                                    [(ngModel)]="Developement.remarks"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                        <div class="row" style="padding-left: 13px;">
                            <mat-accordion class="mataccordion">
                                <mat-expansion-panel class="expandpanel">
                                    <mat-expansion-panel-header class="exppanel width">
                                        <mat-panel-title class="title ">
                                            18 months
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>
                                    <hr class="hr">
                                    <div [hidden]="langeightmonth" style="padding-top:10px">
                                        <div class="row" style="padding-top:10px"
                                            *ngFor="let Developement of Devel_Lang_18_month_Data">
                                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                                <mat-label class="matlabel">{{Developement.Description}}
                                                </mat-label>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                                <mat-radio-group class="matlabel" color="primary" (change)="send_data()"
                                                    [(ngModel)]="Developement.No">
                                                    <mat-radio-button [disabled]="lageighteen_disabled" [value]="false"
                                                        class="pediatrics_width" [checked]="Developement.Yes">Yes
                                                    </mat-radio-button>
                                                    <mat-radio-button [disabled]="lageighteen_disabled" [value]="true"
                                                        class="pediatrics_width" [checked]="Developement.No">No
                                                    </mat-radio-button>
                                                </mat-radio-group>
                                            </div>
                                            <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                                <textarea *ngIf="Developement.No" class="ipcss txtarea" myInput (blur)="send_data()"
                                                    [disabled]="lageighteen_disabled"
                                                    [(ngModel)]="Developement.remarks"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                        <div class="row"
                            style="padding-left: 13px;padding-top: 10px">
                            <mat-accordion class="mataccordion">
                                <mat-expansion-panel class="expandpanel">
                                    <mat-expansion-panel-header class="exppanel width">
                                        <mat-panel-title class="title ">
                                            24 months
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>
                                    <hr class="hr">
                                    <div [hidden]="langtwntymonth" style="padding-top:10px">
                                        <div class="row" style="padding-top:10px"
                                            *ngFor="let Developement of Devel_Lang_24_month_Data">
                                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                                <mat-label class="matlabel">{{Developement.Description}}
                                                </mat-label>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                                <mat-radio-group class="matlabel" color="primary" (change)="send_data()"
                                                    [(ngModel)]="Developement.No">
                                                    <mat-radio-button [disabled]="lagtwnfour_disabled" [value]="false"
                                                        class="pediatrics_width" [checked]="Developement.Yes">Yes
                                                    </mat-radio-button>
                                                    <mat-radio-button [disabled]="lagtwnfour_disabled" [value]="true"
                                                        class="pediatrics_width" [checked]="Developement.No">No
                                                    </mat-radio-button>
                                                </mat-radio-group>
                                            </div>
                                            <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                                <textarea *ngIf="Developement.No" class="ipcss txtarea" myInput (blur)="send_data()"
                                                    [disabled]="lagtwnfour_disabled"
                                                    [(ngModel)]="Developement.remarks"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
    <div class="row">
        <div class="col-12" style="margin-bottom: 5px;">
            <mat-accordion class="mataccordion">
                <mat-expansion-panel class="expandpanel">
                    <mat-expansion-panel-header class="exppanel width">
                        <mat-panel-title class="title ">
                            3 – 5 Years (Pre-Schooler)
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <hr class="hr">
                    <div class="row" style="padding-left: 13px;padding-top: 10px">
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                            <mat-label class="matlabel">Dentition</mat-label>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                            <mat-radio-group class="matlabel" color="primary" (change)="send_data()"
                                [(ngModel)]="threeyearDention_teeth">
                                <mat-radio-button class="pediatrics_width" [disabled]="preden_disabled" value="yes">Normal
                                </mat-radio-button>
                                <mat-radio-button class="pediatrics_width" [disabled]="preden_disabled" value="no">Abnormal
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                            <textarea [hidden]="threeyearDention_teeth=='yes'" class="ipcss txtarea" myInput (blur)="send_data()"
                                [disabled]="preden_disabled" [(ngModel)]="threeyearDention_remarks" rows="1"></textarea>
                        </div>
                    </div>
                    <div class="row" style="padding-left: 13px;padding-top: 10px">
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                            <mat-label class="matlabel">Moral Development</mat-label>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                            <mat-radio-group class="matlabel" color="primary" [(ngModel)]="threeyearmoral_dev" (change)="send_data()">
                                <mat-radio-button class="pediatrics_width" [disabled]="premore_disabled" value="yes">Yes
                                </mat-radio-button>
                                <mat-radio-button class="pediatrics_width" [disabled]="premore_disabled" value="no">No
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                            <textarea [hidden]="threeyearmoral_dev=='no'" class="ipcss txtarea" myInput (blur)="send_data()"
                                [disabled]="premore_disabled" [(ngModel)]="threeyearmoral_remarks" rows="1"></textarea>
                        </div>
                    </div>
                    <div class="row" style="padding-left: 13px;padding-top: 10px">
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                            <mat-label class="matlabel">Psycho-Social Development</mat-label>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                            <mat-radio-group class="matlabel" color="primary" (change)="send_data()"
                                [(ngModel)]="threeyearphyscho_social">
                                <mat-radio-button class="pediatrics_width" [disabled]="prepyscho_disable" value="yes">Normal
                                </mat-radio-button>
                                <mat-radio-button class="pediatrics_width" [disabled]="prepyscho_disable" value="no">
                                    Abnormal</mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                            <textarea [hidden]="threeyearphyscho_social=='yes'" class="ipcss txtarea" myInput (blur)="send_data()"
                                [disabled]="prepyscho_disable" [(ngModel)]="threeyearphyscho_remarks" rows="1"></textarea>
                        </div>
                    </div>
                    <div class="row" style="padding-left: 13px;padding-top: 10px">
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                            <mat-label class="matlabel">Toilet Training</mat-label>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                            <mat-radio-group class="matlabel" color="primary" (change)="send_data()"
                                [(ngModel)]="threeyeartoilet_training">
                                <mat-radio-button class="pediatrics_width" [disabled]="pretoilet_disable" value="yes">Normal
                                </mat-radio-button>
                                <mat-radio-button class="pediatrics_width" [disabled]="pretoilet_disable" value="no">
                                    Abnormal</mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                            <textarea [hidden]="threeyeartoilet_training=='yes'" class="ipcss txtarea" myInput (blur)="send_data()"
                                [disabled]="pretoilet_disable" [(ngModel)]="threeyeartoilet_remarks" rows="1"></textarea>
                        </div>
                    </div>
                    <div class="row" style="padding-left: 13px;padding-top: 10px">
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                            <mat-label class="matlabel">Sleep Pattern</mat-label>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                            <mat-radio-group class="matlabel" color="primary" [(ngModel)]="threeyear_sleep" (change)="send_data()">
                                <mat-radio-button class="pediatrics_width" [disabled]="presleep_disable" value="yes">Normal
                                </mat-radio-button>
                                <mat-radio-button class="pediatrics_width" [disabled]="presleep_disable" value="no">Abnormal
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                            <textarea [hidden]="threeyear_sleep=='yes'" class="ipcss txtarea" myInput (blur)="send_data()"
                                [disabled]="presleep_disable" [(ngModel)]="threeyear_sleep_remarks" rows="1"></textarea>
                        </div>
                    </div>
                    <div class="row" style="padding-left: 13px;padding-top: 10px">
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                            <mat-label class="matlabel">Playing Pattern</mat-label>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                            <mat-radio-group class="matlabel" color="primary" (change)="send_data()"
                                [(ngModel)]="threeyearplaying_pattren">
                                <mat-radio-button class="pediatrics_width" [disabled]="preplay_disable" value="yes">Normal
                                </mat-radio-button>
                                <mat-radio-button class="pediatrics_width" [disabled]="preplay_disable" value="no">Abnormal
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                            <textarea [hidden]="threeyearplaying_pattren=='yes'" class="ipcss txtarea" myInput (blur)="send_data()"
                                [disabled]="preplay_disable" [(ngModel)]="threeyearplaying_remarks" rows="1"></textarea>
                        </div>
                    </div>
    
                    <p class="tervys_heading"><strong>Cognitive Development</strong></p>
                    <div class="row" style="padding-left: 13px;">
                        <mat-accordion class="mataccordion">
                            <mat-expansion-panel class="expandpanel">
                                <mat-expansion-panel-header class="exppanel width">
                                    <mat-panel-title class="title ">
                                        3 years
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <hr class="hr">
                                <div [hidden]="cogthree" style="padding-top:10px">
                                    <div class="row" style="padding-top:10px"
                                        *ngFor="let Developement of Devel_Cog_3_year_Data">
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">{{Developement.Description}}</mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                            <mat-radio-group class="matlabel" color="primary" (change)="send_data()"
                                                [(ngModel)]="Developement.No">
                                                <mat-radio-button class="pediatrics_width" [disabled]="devthyear_disabled"
                                                    [value]="false" [checked]="Developement.Yes">Yes</mat-radio-button>
                                                <mat-radio-button class="pediatrics_width" [disabled]="devthyear_disabled"
                                                    [value]="true" [checked]="Developement.No">No</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                            <textarea *ngIf="Developement.No" class="ipcss txtarea" myInput (blur)="send_data()"
                                                [disabled]="devthyear_disabled"
                                                [(ngModel)]="Developement.remarks"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                    <div class="row" style="padding-left: 13px;">
                        <mat-accordion class="mataccordion">
                            <mat-expansion-panel class="expandpanel">
                                <mat-expansion-panel-header class="exppanel width">
                                    <mat-panel-title class="title ">
                                        4 Years
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <hr class="hr">
                                <div [hidden]="cogfour" style="padding-top:10px">
                                    <div class="row" style="padding-top:10px"
                                        *ngFor="let Developement of Devel_Cog_4_year_Data">
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">{{Developement.Description}}</mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                            <mat-radio-group class="matlabel" color="primary" (change)="send_data()"
                                                [(ngModel)]="Developement.No">
                                                <mat-radio-button class="pediatrics_width" [disabled]="devfouryear_disabled"
                                                    [value]="false" [checked]="Developement.Yes">Yes</mat-radio-button>
                                                <mat-radio-button class="pediatrics_width" [disabled]="devfouryear_disabled"
                                                    [value]="true" [checked]="Developement.No">No</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                            <textarea *ngIf="Developement.No" class="ipcss txtarea" myInput (blur)="send_data()"
                                                [disabled]="devfouryear_disabled"
                                                [(ngModel)]="Developement.remarks"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                    <div class="row" style="padding-left: 13px;">
                        <mat-accordion class="mataccordion">
                            <mat-expansion-panel class="expandpanel">
                                <mat-expansion-panel-header class="exppanel width">
                                    <mat-panel-title class="title ">
                                        5 Years
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <hr class="hr">
                                <div [hidden]="cogfive" style="padding-top:10px">
                                    <div class="row" style="padding-top:10px"
                                        *ngFor="let Developement of Devel_Cog_5_year_Data">
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">{{Developement.Description}}</mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                            <mat-radio-group class="matlabel" color="primary" (change)="send_data()"
                                                [(ngModel)]="Developement.No">
                                                <mat-radio-button class="pediatrics_width" [disabled]="devfiveyear_disabled"
                                                    [value]="false" [checked]="Developement.Yes">Yes</mat-radio-button>
                                                <mat-radio-button class="pediatrics_width" [disabled]="devfiveyear_disabled"
                                                    [value]="true" [checked]="Developement.No">No</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                            <textarea *ngIf="Developement.No" class="ipcss txtarea" myInput (blur)="send_data()"
                                                [disabled]="devfiveyear_disabled"
                                                [(ngModel)]="Developement.remarks"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
    
                    <p class="tervys_heading"><strong>Language Development</strong></p>
                    <div class="row" style="padding-left: 13px;">
                        <mat-accordion class="mataccordion">
                            <mat-expansion-panel class="expandpanel">
                                <mat-expansion-panel-header class="exppanel width">
                                    <mat-panel-title class="title ">
                                        3 years
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <hr class="hr">
                                <div [hidden]="lanthreeyear" style="padding-top:10px">
                                    <div class="row" style="padding-top:10px"
                                        *ngFor="let Developement of Devel_Lang_3_year_Data">
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">{{Developement.Description}}</mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                            <mat-radio-group class="matlabel" color="primary" (change)="send_data()"
                                                [(ngModel)]="Developement.No">
                                                <mat-radio-button class="pediatrics_width" [disabled]="lanthyear_disabled"
                                                    [value]="false" [checked]="Developement.Yes">Yes</mat-radio-button>
                                                <mat-radio-button class="pediatrics_width" [disabled]="lanthyear_disabled"
                                                    [value]="true" [checked]="Developement.No">No</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                            <textarea *ngIf="Developement.No" class="ipcss txtarea" myInput (blur)="send_data()"
                                                [disabled]="lanthyear_disabled"
                                                [(ngModel)]="Developement.remarks"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                    <div class="row" style="padding-left: 13px;">
                        <mat-accordion class="mataccordion">
                            <mat-expansion-panel class="expandpanel">
                                <mat-expansion-panel-header class="exppanel width">
                                    <mat-panel-title class="title ">
                                        4 Years
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <hr class="hr">
                                <div [hidden]="lanfyear" style="padding-top:10px">
                                    <div class="row" style="padding-top:10px"
                                        *ngFor="let Developement of Devel_Lang_4_year_Data">
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">{{Developement.Description}}</mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                            <mat-radio-group class="matlabel" color="primary" (change)="send_data()"
                                                [(ngModel)]="Developement.No">
                                                <mat-radio-button class="pediatrics_width" [disabled]="lanfouryear_disabled"
                                                    [value]="false" [checked]="Developement.Yes">Yes</mat-radio-button>
                                                <mat-radio-button class="pediatrics_width" [disabled]="lanfouryear_disabled"
                                                    [value]="true" [checked]="Developement.No">No</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                            <textarea *ngIf="Developement.No" class="ipcss txtarea" myInput (blur)="send_data()"
                                                [disabled]="lanfouryear_disabled"
                                                [(ngModel)]="Developement.remarks"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                    <div class="row" style="padding-left: 13px;">
                        <mat-accordion class="mataccordion">
                            <mat-expansion-panel class="expandpanel">
                                <mat-expansion-panel-header class="exppanel width">
                                    <mat-panel-title class="title ">
                                        5 Years
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <hr class="hr">
                                <div [hidden]="lanfiveyear" style="padding-top:10px">
                                    <div class="row" style="padding-top:10px"
                                        *ngFor="let Developement of Devel_Lang_5_year_Data">
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                            <mat-label class="matlabel">{{Developement.Description}}</mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 ">
                                            <mat-radio-group class="matlabel" color="primary" (change)="send_data()"
                                                [(ngModel)]="Developement.No">
                                                <mat-radio-button class="pediatrics_width" [disabled]="lanfiveyear_disabled"
                                                    [value]="false" [checked]="Developement.Yes">Yes</mat-radio-button>
                                                <mat-radio-button class="pediatrics_width" [disabled]="lanfiveyear_disabled"
                                                    [value]="true" [checked]="Developement.No">No</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                            <textarea *ngIf="Developement.No" class="ipcss txtarea" myInput (blur)="send_data()"
                                                [disabled]="lanfiveyear_disabled"
                                                [(ngModel)]="Developement.remarks"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
</div>
