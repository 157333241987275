<div class="row">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Vitals</h5>
                    </div>
                    <div class="headerButtons">
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>
                <div class="col-12" id="CLvital">
                    <owl-carousel-o [options]="customOptions">
                        <ng-template carouselSlide *ngFor="let relation of relationlistData;">
                            <div class="slide">
                                <div class="cu_slider_cover">
                                    <div class="card_border1"
                                        (click)="selectedSpl(relation.rel_name,relation.rel_id,relation.sub_rel_id,relation.life_style_id)">
                                        <div class="d-flex justify-content-center">
                                            <img class="img-fluid" class="image" src="{{relation.prof_img}}"
                                                alt="Card image cap">
                                        </div>
                                        <p class="carousel_label">{{relation.rel_name}}</p>
                                    </div>
                                    <a tabindex="0">
                                        <div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </ng-template>
                    </owl-carousel-o>
                </div>
                <div class="cover_div">
                    <div class="content_cover">
                        <div class="row">
                            <div  class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <div class="row">
                                    <div  class="col-6">
                                        <mat-label class="matlabel">Height<br>
                                            <input  matInput class="ipcss " (change)="caloriesData()" required [(ngModel)]="height">
                                        </mat-label>
                                    </div>
                                    <div  class="col-6">
                                        <mat-label class="matlabel">Measure<br>
                                            <select  class="ipcss " (change)="caloriesData()"
                                                [(ngModel)]="heightMeasure" disableOptionCentering>
                                                <option value="cms">cms</option>
                                                <option value="inch">inch</option>
                                            </select>
                                        </mat-label>
                                    </div>
                                </div>
                            </div>
                            <div  class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <div class="row">
                                    <div class="col-6">
                                        <mat-label class="matlabel">Weight<br>
                                            <input  matInput class="ipcss "
                                                (change)="caloriesData()" required [(ngModel)]="weight">
                                        </mat-label>
                                    </div>
                                    <div class="col-6">
                                        <mat-label class="matlabel">Measure<br>
                                            <select  class="ipcss " [(ngModel)]="weightMeasure"
                                                disableOptionCentering>
                                                <option value="kgs">kgs</option>
                                                <option value="lbs">lbs</option>
                                            </select>
                                        </mat-label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel">BMI
                                    <input class="ipcss bmi_lenght" matInput type="text" 
                                        [(ngModel)]="bmi"  />
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel">BMR
                                    <input class="ipcss bmi_lenght" matInput type="text" 
                                        [(ngModel)]="bmr"  />
                                </mat-label>
                            </div>
                            <div  class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel" >Temperature<br>
                                    <input  matInput class="ipcss " [(ngModel)]="temperature">
                                </mat-label>
                            </div>
                            <div class="col-12">
                                <a style="float: right;" (click)="save()"><img src="../../../assets/ui_icons/buttons/save_button.svg"  class="saveimgbtn_inpatinfo" /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
    
</div>