<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Purchase order</h5>
          </div>
          <div class="headerButtons" >
            <img *ngIf="newbutton" class="saveimgbtn_inpatinfo" src="../../../assets/ui_icons/buttons/new_button.svg" (click)="purchase_view()" />
            <img class="saveimgbtn_inpatinfo" *ngIf="orderArray.length != 0 && approvalboxandbutton" src="../../../assets/ui_icons/buttons/approve_button.svg" (click)="approved()">
            <!-- <button class="saveimgbtn_inpatinfo" *ngIf="approvalboxandbutton" (click)="approved()">Approved</button> -->
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row justify-content-center">
          <div class="col-12 col-lg-12 col-md-12 col-xl-12 d-flex flex-wrap" style="width: 100%;">
            <p class="nodata" *ngIf="orderArray.length == 0">No order(s) found</p>
            <div class="table-responsive" *ngIf="orderArray.length != 0" >
             <table>
                  <thead   style="  position: sticky;
                  top: 0;
                  z-index: 10;">
                    <tr style="background-color:#c1e6fb;color: rgba(0,0,0,.54);min-height: 56px;">
                      <div style="width:100%;display: flex;
                      justify-content: space-evenly">
                      <th style="width:10%">Order no</th>
                      <th style="width:20%">Supplier name</th>
                      <th style="width:20%">Department</th>
                      <th style="width:20%">Store name</th>
                      <th style="width:20%">Date</th>
                      <th style="width:20%">Status</th>
                      </div>
                      <td><td>
                    </tr>
                  </thead>
                  <tbody>
                   <tr *ngFor="let list of orderArray" style="height: 62px !important;">
                      <div style="width:100%;display: flex;
                      justify-content: space-evenly;text-align:left;height: 60px" (click)="view_details(list.po_id,list)" >
                      <td style="width:20%;position: sticky;
                      top: 0;">{{list.po_no}}</td>
                      <td style="width:20%;position: sticky;
                      top: 0;">{{list.supplier_name}}</td>
                      <td style="width:20% ;position: sticky;
                      top: 0;">{{list.dept_name ||"-"}}</td>
                      <td style="width:20% ;position: sticky;
                      top: 0;">{{list.store_name || "-"}}</td>
                      
                      <td style="width:20% ;position: sticky;
                      top: 0;">{{list.po_date}}</td>
                      <td style="width:20%;position: sticky;
                      top: 0;">
                        <img *ngIf="list.status == 'Open'" width="30px;" height="22px"
                        src="../../../assets/ui_icons/opened_icon.svg" />
 
                        <img *ngIf="list.status == 'Approved'" width="30px;" height="22px"
                        src="../../../assets/ui_icons/awaiting_for_rating.svg" />
 
                        <p style="margin: 0px 0 0px">{{list.status}}</p>
                      </td>
                    </div>
                      <td>
                      <input *ngIf="approvalboxandbutton && list.status == 'Open'" type="checkbox" [checked]="accepted" (click)="checkeddata(list)">
                      <input *ngIf="approvalboxandbutton && list.status == 'Approved'"
                        style=" cursor:no-drop;color:blue" type="checkbox" [checked]="list.status === 'Approved'"
                        [disabled]="list.status === 'Approved'">
                      </td>
                    </tr>
                  </tbody>
                </table>
  </div>
      </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>