import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { ipaddress } from '../ipaddress';
// import { ipaddress.getIp,Ip_with_img_address,get_time_format, system_ip, country_code } from '../../assets/js/ipaddress.js';
// import { ipaddress.getIp,Ip_with_img_address, country_code } from '../../assets/js/ipaddress.js';

@Injectable({
  providedIn: 'root'
})
export class CommonDataService {

  public Client_ID;

  public Client_login_detail_data;

  public city_url: string;
  public state_url: string;
  public country_url: string;

  public temp_string: string;
  public temp_boolean: Boolean;

  public temp_list;

  public appointment_book_list;

  public appointment_Client_Data;
  public appointment_Doctor_Data;
  public second_doctor_id_list;

  public general_tab_flag: Boolean;
  public health_tab_flag: Boolean;
  public family_tab_flag: Boolean;
  public document_tab_flag: Boolean;

  public second_opinion_docotor_list = [];

  public second_opinion_general_data = [];
  public second_opinion_health_data = [];
  public second_opinion_family_data = [];
  public second_opinion_document_data = [];
  public second_opinion_appointment_data = [];
  public second_opinion_tab_flag: Boolean;

  public second_opinion_gen_mainconcern=[];
  public second_opinion_gen_medhist=[];
  public second_opinion_gen_religious_beliefs=[];
  public second_opinion_gen_excer=[];

  public static OpnSplId: string;

  public event_tab_list_type: string;

  //// profile static list
  public client_profile_Terms = [];
  public client_profile_personal_data = [];
  public client_profile_address_data = [];
  public client_profile_package_data = [];


  public reg_client_personal_flag: boolean = false;
  public reg_client_address_flag: boolean = false;
  public reg_client_package_flag: boolean = false;

  //// Not found page text

  public data_not_found_text: string;
  loc_data: any = [];
  loc_arr: any = [];
    public user_id = "diagnosis";

  constructor(public http: Http) {
      

      this.city_url = ipaddress.getIp + "gen/city";
      this.state_url = ipaddress.getIp + "gen/state";
      this.country_url = ipaddress.getIp + "gen/count";

  }

  get_String_from_Array(array_data, key_val) {
      this.temp_string = "";

      for (var a = 0; a < array_data.length; a++) {
          if (a == 0) {
              this.temp_string = array_data[a][key_val];
          }
          else {
              this.temp_string = this.temp_string + ", " + array_data[a][key_val];
          }
      }

      return this.temp_string;
  }

  get_Array_Contains_string(array_data, value) {
      this.temp_boolean = false;

      for (var c = 0; c < array_data.length; c++) {
          if (array_data[c] == value) {
              this.temp_boolean = true;
              break;
          }
      }

      return this.temp_boolean;
  }

  get_Array_Contains(array_data, key_val, current_val) {
      this.temp_boolean = false;

      if (array_data.length != 0) {
          for (var b = 0; b < array_data.length; b++) {
              if (array_data[b][key_val] == current_val) {
                  this.temp_boolean = true;
                  break;
              }
          }
      }

      return this.temp_boolean;
  }

  get_Date_Formate(text) {
      var new_txt = text.split('-');

      return new_txt[2] + "-" + new_txt[1] + "-" + new_txt[0];
  }

  get_Day(text) {
      if (text == "1") {
          return "Sunday";
      } else if (text == "2") {
          return "Monday";
      } else if (text == "3") {
          return "Tuesday";
      } else if (text == "4") {
          return "Wednesday";
      } else if (text == "5") {
          return "Thursday";
      } else if (text == "6") {
          return "Friday";
      } else if (text == "7") {
          return "Saturday";
      } else if (text == "8") {
          return "Mon - Sat";
      } else if (text == "9") {
          return "All Days";
      }
  }

  get_Day_num(text) {
      if (text == "Sunday") {
          return 1;
      } else if (text == "Monday") {
          return 2;
      } else if (text == "Tuesday") {
          return 3;
      } else if (text == "Wednesday") {
          return 4;
      } else if (text == "Thursday") {
          return 5;
      } else if (text == "Friday") {
          return 6;
      } else if (text == "Saturday") {
          return 7;
      } else if (text == "Mon - Sat") {
          return 8;
      } else if (text == "All Days") {
          return 9;
      }
  }

  get_diabday(text) {
      if (text == "1") {
          return "Mon - Sat";
      } else if (text == "2") {
          return "All days";
      }
  }

  get_Session(text) {
      if (text == "1") {
          return "Morning";
      } else if (text == "2") {
          return "Afternoon";
      } else if (text == "3") {
          return "Evening";
      }
  }

  get_Timeformate(text) {
      var time = text.split(':');

      if (time[0] >= 12) {

          if (time[0] == 12) {
              return time[0] + ":" + time[1] + " PM";
          } else {
              var hours = time[0] - 12;

              if (hours < 10) {

                  if (time[1] < 10 && time[1].length == 1) {
                      return "0" + hours + ":" + "0" + time[1] + " PM";
                  } else {
                      return "0" + hours + ":" + time[1] + " PM";
                  }
              } else {
                  return hours + ":" + time[1] + " PM";
              }
          }

      } else {
          if (time[0].toString() == "00") {
              return "12" + ":" + time[1] + " AM";
          } else {
              return time[0] + ":" + time[1] + " AM";
          }
      }
  }

  get_time_form_session(timetxt, session) {
      var time = timetxt.split(':');

      if (session == "Morning") {
          if (time[0] == "12") {
              return time[0] + ":" + time[1] + " PM";
          } else {
              return time[0] + ":" + time[1] + " AM";
          }
      }
      else {
          return time[0] + ":" + time[1] + " PM";
      }
  }

  get_seesion_ampm(text) {
      if (text == "Morning") {
          return "AM";
      } else if (text == "Afternoon") {
          return "PM";
      } else if (text == "Evening") {
          return "PM";
      }
  }


  // for appointments

  set_appoint_book_data(array_list) {
      this.appointment_book_list = [];

      this.appointment_book_list = array_list;
  }

  get_appoint_book_data() {
      return this.appointment_book_list;
  }

  set_appoint_client_data(client_obj) {
      this.appointment_Client_Data = client_obj;
  }

  get_appoint_client_data() {
      return this.appointment_Client_Data;
  }

  set_appoint_doctor_data(doct_obj) {
      this.appointment_Doctor_Data = doct_obj;
  }

  get_appoint_doctor_data() {
      return this.appointment_Doctor_Data;
  }

  set_second_doc_id_list(data) {
      this.second_doctor_id_list = data;
  }

  get_second_doc_id_list() {
      return this.second_doctor_id_list;
  }

  // Second Opinion data

  set_second_doctor_data(data) {
      this.second_opinion_docotor_list = data;
  }

  get_second_doctor_data() {
      return this.second_opinion_docotor_list;
  }

  set_second_general_data(data) {
      this.second_opinion_general_data = data;
  }

  get_second_general_data() {
      return this.second_opinion_general_data
  }

  set_second_health_data(data) {
      this.second_opinion_health_data = data;
  }

  get_second_health_data() {
      return this.second_opinion_health_data;
  }

  set_second_family_data(data) {
      
      this.second_opinion_family_data = data;
  }

  get_second_family_data() {
      return this.second_opinion_family_data;
  }

  set_second_document_data(data) {
      this.second_opinion_document_data = data;
  }

  get_second_documet_data() {
      return this.second_opinion_document_data;
  }
  

  get_second_appointment_data() {
      return this.second_opinion_appointment_data
  }

  set_second_appointment_data(data) {
      this.second_opinion_appointment_data = data;
  }

  //// Client Profile Page
  

  set_client_profile_terms_data(data) {
      this.client_profile_Terms = data;
  }

  get_client_profile_terms_data() {
      return this.client_profile_Terms;
  }
  
  set_client_profile_personal_data(data) {
      this.client_profile_personal_data = data;
  }

  get_client_profile_personal_data() {
      return this.client_profile_personal_data;
  }

  set_client_profile_address_data(data) {
      this.client_profile_address_data = data;
  }

  get_client_profile_address_data() {
      return this.client_profile_address_data;
  }

  set_client_profile_package_data(data) {
      this.client_profile_package_data = data;
  }

  get_client_profile_package_data() {
      return this.client_profile_package_data;
  }

  // app running platform
  get_platform_screen() {
      if (window.outerWidth > 1024) {// Desktop
          return true;

      } else {// tablet, mobiles
          return false;
      }
  }

  get_diag_location() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "diagnosiscontroller/diagloc/", JSON.stringify({ diag_name: 11, flag: "id", prov_id: 11, type: this.user_id, }), { headers: headers }).subscribe(
        data => {
            this.loc_arr = []
            if (data.json().diag_loc != null) {
                this.loc_data = data.json().diag_loc;
                for (var i = 0; i < this.loc_data.length; i++) {
                    this.loc_arr.push({
                        id: this.loc_data[i].diag_centre_id,
                        centre_name: this.loc_data[i].diag_centre_name,
                        address1: this.loc_data[i].address1,
                        diag_loc: this.loc_data[i].diag_loc,
                        city: this.loc_data[i].city,
                        state: this.loc_data[i].state,
                        country: this.loc_data[i].country,
                        zipcode: this.loc_data[i].zipcode,
                        telephone: this.loc_data[i].telephone
                    });
                }
                return (this.loc_arr);
            }
        })
}
}
