import { Component,ViewChild, OnInit,HostListener,ViewEncapsulation,ElementRef,OnDestroy, Injector } from '@angular/core';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { Doc_Helper } from '../Doc_Helper';
import { DoctorViewService } from '../doctor-view/doctor-view.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { SecondOpinionService } from './SecondOpinion.Service';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
@Component({
  selector: 'app-second-opinion-casesheet',
  templateUrl: './second-opinion-casesheet.component.html',
  styleUrls: ['./second-opinion-casesheet.component.css']
})
export class SecondOpinionCasesheetComponent implements OnInit {
  public expansion:boolean=false;
  public userinfo:any;
  public user_id:any;
  public first_name:string;
  public last_name:string;
  public submenu_flag:boolean =false;
  public submenu_flag1:boolean =false;
  public hospital_logo;
  public hospital_name;
  public hospital_details;
  public C_age;
  public C_gender;
  public C_image;
  public profile_image:string;
  public messages;
  public msgflag:boolean=false;
  public notifications;
  public notifyflag:boolean=false;
  public patient_name;
  public patient_image:any;
  public doctor_profile_img;
  public patient_age: any;
  public patient_gender: string;
  public cards = [];
  public cardview_flag:boolean = false;
  public users: any = [];
  public userlist:any;
  public selectedUser: any;
  //detailed
  @ViewChild("slickModal")
  activeIndex = -1;
  fadeInOut = false;
  isShow = false;
  public min = "min";
  public max = "max";
  public apptdetails;
  public personal;
  public hm;
  public family;
  public reports;
  public observation;
  public min_max_flag:boolean = false;
  customOptions: OwlOptions = {
  //autoplay : true,
   autoplay: false,
   autoplaySpeed: 1500,
   loop: true,
   pullDrag: false,
   dots: false,
   navSpeed: 750,
   navText: ['<i class="fa fa-chevron-circle-left left" aria-hidden="true" style="font-size: 50px;color: #bfbbbb;position: absolute;left: 16px;top: 46%;z-index: 1;"></i>', '<i class="fa fa-chevron-circle-right right" aria-hidden="true"></i>'],
   responsive: {
     0: {
       items: 2
     },
     400: {
       items: 4
     },
     740: {
       items: 5
     },
     940: {
       items: 6
     }
   },
   nav: true,
  
  }

  constructor(private http:Http,private routes:ActivatedRoute,private router:Router,public toastr:ToastrService,public messageservice:MenuViewService,public secondmessageservice:SecondOpinionService) { 
    this.secondmessageservice.sendSecondOpinionMessage("min");
    this.cardview_flag = false;
  }

  ngOnInit(): void {
    this.patient_name = Doc_Helper.getClient_name();
    this.patient_age = Doc_Helper.getClient_age();
    this.patient_gender = Doc_Helper.getClient_gender();
    this.userlist =  Doc_Helper.getOpnid();
    this.patient_image = 
    this.cards = ["Appointment_Details","Personal","Health & Medication","Family","Reports","Observations"];
  }

  backClicked(){
    this.messageservice.sendMessage("secondopinion");
  }

  getRecord(id: any) {
    this.selectedUser = [];
    const res = this.userlist.filter((d: { id: string; }) => d.id === id);
    this.selectedUser = res[0];
  }

  opencard(id,card,index,card_id,flag){
    this.min_max_flag = flag;
    if(this.min_max_flag == true){
      this.secondmessageservice.sendSecondOpinionMessage("max");
      this.cardview_flag = true;

      for(var i=0; i< this.cards.length; i++){
        document.getElementById("cardindex_"+i).className = "col-md-12 m-auto user-view";
        if(card == this.cards[i]){
          document.getElementById("card_"+i).style.width = "100%";
          document.getElementById("card_"+i).style.height = "auto";
        }else{
          document.getElementById("card_"+i).style.display = "none";
        }
      }
    }

    if(this.min_max_flag == false){
      this.secondmessageservice.sendSecondOpinionMessage("min");
      this.cardview_flag = false;

      for(var i=0; i< this.cards.length; i++){
        document.getElementById("cardindex_"+i).className = "col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4";
        if(card == this.cards[i]){
          document.getElementById("card_"+i).style.width = "100%";
          document.getElementById("card_"+i).style.height = "290px";
        }else{
          document.getElementById("card_"+i).style.display = "flex";
          document.getElementById("card_"+i).style.width = "100%";
          document.getElementById("card_"+i).style.height = "290px";
        }
      }
    }
  }

  viewRecord(card, index: any,card_id) {
    this.secondmessageservice.sendSecondOpinionMessage("max");
    for(var i=0; i< this.cards.length; i++){
      document.getElementById("cardindex_"+i).className = "col-md-12 m-auto user-view";
      if(card == this.cards[i]){
        document.getElementById("card_"+i).style.width = "100%";
        document.getElementById("card_"+i).style.height = "auto";
        document.getElementById("card_"+i).style.display = "flex";
      }else{
        document.getElementById("card_"+i).style.display = "none";
      }
    }
  }

  viewRecord_user(id: any, card, index: any) {
    this.fadeInOut = true;
    this.isShow = true;
    this.activeIndex = index;
    this.selectedUser = [];
    this.selectedUser = card;
    setTimeout(() => {
      this.isShow = false;
      this.fadeInOut = false;
    },500)
  }
}
