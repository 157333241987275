import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DiagnosisImagepopupComponent } from './diagnosis-imagepopup/diagnosis-imagepopup.component';

@Injectable({
  providedIn: 'root'
})
export class DiagnosisImagepopupserviceService {

  private isOpenSubject = new BehaviorSubject<boolean>(false);
  isOpen$ = this.isOpenSubject.asObservable();
  constructor( public dialog: MatDialog){}
  openDialog(data) {
    this.isOpenSubject.next(true);
    const dialogRef2 = this.dialog.open(DiagnosisImagepopupComponent, {
      width: '200px',
      height: '200px',
      data:{
        image: data,
        // hosp_diag: "diagnois",
        // patient_type: pat_type,
    }
    });
    dialogRef2.afterClosed().subscribe(result => {
      console.log("resul  yyugjhb"+JSON.stringify(result))
      if (result != null) {
        console.log("resul  yyugjhb"+JSON.stringify(result))
        // this.clientId=result.patient_id,
        // this.relationId="1",
        // this.firstNamePatient1=result.patientname
        // this.retrieveClient()
        
      }
    });
  }

  closeDialog() {
    this.isOpenSubject.next(false);
  }
}
