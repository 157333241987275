<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white" >
        <div class="headerCover">
          <div class="headerTilte app_list_header" >
            <h5 class="m-0" class="mainHeadingStyle">Doctor's availability</h5>
          </div>
          <div class="headerButtons">
          </div>
        </div>
        
      </mdb-card-header>
      <mdb-card-body>
        <div class="row">
          <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3" *ngIf="is_admin">
            <mat-label class="matlabel" style="font-size: 15px;
            font-weight: 600;margin-left: 5px;">Doctor<br></mat-label>
            <select required [(ngModel)]="doctorid" (change)="changedoc()"  class="ipcss widthappt">
              <option value="All">All</option>
              <option *ngFor="let doctors of doctorList" value={{doctors.Doc_ID}}>{{doctors.Doc_Name}}
              </option>
            </select>
          </div>
          <!-- <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3" *ngIf="is_admin">
            <select required [(ngModel)]="doctorid" (change)="changedoc()"  class="ipcss widthappt">
              <option value="All">All</option>
              <option *ngFor="let doctors of doctorList" value={{doctors.Doc_ID}}>{{doctors.Doc_Name}}
              </option>
            </select>
          </div> -->
          <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 select_bottom">
            <mat-label class="matlabel">Date<br> </mat-label>
            <input type="date" class="ipcss_date" id="appt_date" (change)="OnDateChangedfrom(filter_date)"
              [(ngModel)]="filter_date" max="{{currentDate}}" #matInput>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 addvalue">
            <mat-label class="matlabel">
              <a (click)="Doctor_availability_search()"><img src="../../../assets/ui_icons/buttons/search_button.svg"
                  class="saveimgbtn_inpatinfo" /></a>
            </mat-label>
          </div>
          <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 select_bottom">
            <mat-label class="matlabel">Date<br> </mat-label>
            <input type="date" class="ipcss_date" id="appt_date" (change)="OnDateChangedfrom(filter_date)"
              [(ngModel)]="filter_date" max="{{currentDate}}" #matInput>

          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 addvalue">
            <mat-label class="matlabel">
              <a (click)="Doctor_availability_search()"><img src="../../../assets/ui_icons/buttons/search_button.svg"
                  class="saveimgbtn_inpatinfo" /></a>
            </mat-label>
          </div> -->
          <div class="col-0 col-sm-0 col-md-0 col-lg-3 col-xl-3"></div>
        </div>
        <div class="row" [hidden]="avial_show">
          <div class="col-12 col-lg-12 col-md-12 col-xl-12">
            <table *ngIf="consul_arry.length" mdbTable datatable [dtOptions]="dtOptions" class="table table-nowrap table-sm dataTable">
              <thead>
                <tr>
                  <th>Doctor Name</th>
                  <th>Morning</th>
                  <th>Afternoon</th>
                  <th>Evening</th>
                  <th>Not available</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let person of consul_arry">
                  <td style="text-align: left;">{{person.docname}}</td>
                  <td>{{person.morning}}</td>
                  <td>{{person.afternoon}}</td>
                  <td>{{person.evening}}</td>
                  <td style="color: red;word-wrap: break-word;font-size: 15px !important;width: 17% !important;font-weight: bold !important;">{{person.non_avail}}</td>
                  <!-- <td style="font-size: 12px;text-align: center;" class="{{person.boxavial}} avail_class">
                    {{person.consul_avail}}</td> -->
                  
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- <div class="row">
          <div class="col-12 col-md-12 col-lg-12 col-xl-6">
            <div class="boxnonavial" *ngFor="let noavail of non_avail_arry">
              <div class="{{noavail.boxavial}} avail_class">
                  <p>{{noavail.non_vail}}</p>
              </div>
            </div>
          </div>
        </div> -->
      </mdb-card-body>
    </mdb-card>
  </div>
</div>