import { Component, ElementRef, OnInit ,Inject} from '@angular/core';
import { Http, Headers } from '@angular/http';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-dietplan-edit',
  templateUrl: './dietplan-edit.component.html',
  styleUrls: ['./dietplan-edit.component.css']
})
export class DietplanEditComponent implements OnInit {

  public dietarray=[];
  public cal_data: any;
  public day;

  constructor(public http:Http,public dialogRef: MatDialogRef<DietplanEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public el: ElementRef, public toastr:ToastrService) {
      dialogRef.disableClose = true;    
    if (this.data.dietlist != undefined) {
      this.dietarray=[];
      this.dietarray =this.data.dietlist;
      this.cal_data = this.data.cal_data;
      this.day = this.data.day;
    }
  }

  ngOnInit() {
  }

  dismiss() {
    //this.view.dismiss();
  }

  submit_click() {
    this.data=[];
    
    this.data.push({
      dietlist: this.dietarray,
      cal_data: this.cal_data
    })
  }

  ClosePopup(){
    this.dialogRef.close();
  }

  delete_item(value){
    for (var i = 0; i < this.dietarray.length; i++) {
      if(this.dietarray[i].day == this.day){
        if (this.dietarray[i].description == value) {
          var afterdel = parseFloat(this.cal_data) - parseFloat(this.dietarray[i].energy);
          this.cal_data = afterdel
          this.dietarray.splice(i, 1);
          break;
        }
      }
    }
  }

}
