import { Component, OnInit } from '@angular/core';
import { MasterHelperService } from 'src/app/Doctor_module/MasterHelper.service';
import { Master_Helper } from 'src/app/Doctor_module/Master_Helper';
import { ServerApi } from 'src/app/server-api';
import { Client_Helper } from '../Client_helper';
import { Date_Formate } from '../../../assets/js/common';
import moment from 'moment';
import { ipaddress } from '../../ipaddress';
import { ClientViewService } from '../clientview/client-view.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-doc-diet-tracking',
  templateUrl: './doc-diet-tracking.component.html',
  styleUrls: ['./doc-diet-tracking.component.scss']
})
export class DocDietTrackingComponent implements OnInit {
  public getClientDetails;
  public obj;
  public currentMaxDate;
  public toDate;
  public dayArray;
  public displayArray = [];
  public diet_mrng_arry = [];
  public break_fast_arrydata = [];
  public mid_day_arrydata = [];
  public lunch_arrydata = [];
  public evening_arrydata = [];
  public dinner_arrydata = [];
  public night_arrydata = [];
  public getSelectedArray = [];
  public trackerArray = [];
  public morningFoodItem = [];
  public breadfastFoodItem = [];
  public midDayFoodItem = [];
  public lunchFoodItem = [];
  public eveningFoodItem = [];
  public dinnerFoodItem = [];
  public nightFoodItem = [];
  public morningFoods;
  public BreakfastFoods;
  public MidDayFoods;
  public LunchFoods;
  public EveningFoods;
  public DinnerFoods;
  public NightFoods;
  public sendFromDate;
  public sendToDate;
  public obj1:any = [];
  public foodLogArray:any = [];
  
  constructor(public serviceAPI: ServerApi, public masterData: MasterHelperService,public messageservice: ClientViewService,public toastr: ToastrService) { }

  ngOnInit(): void {
    this.getClientDetails = Client_Helper.getDoctDietTracking();

    this.getCurrentDate();
  }
  async getCurrentDate() {
    if (Master_Helper.getMasterCurrentDate() == undefined) {
      await this.masterData.getCurrentDate();
    }
    var obj = Master_Helper.getMasterCurrentDate();
    if (obj != undefined) {
      this.currentMaxDate = obj.current_date;
      this.toDate = Date_Formate(this.currentMaxDate);
      let days = [];
      let daysRequired = 6;
      for (let i = 0; i <= daysRequired; i++) {
        var day = {
          days: moment().subtract(i, 'days').format('dddd'),
          date: moment().subtract(i, 'days').format('DD-MM-YYYY'),
        }
        days.push(day)
        if( i == 0 ){
          this.sendToDate =  Date_Formate(moment().subtract(i, 'days').format('DD-MM-YYYY'));
        }else if( i == daysRequired ){
          this.sendFromDate =  Date_Formate(moment().subtract(i, 'days').format('DD-MM-YYYY'));
        }
      }
      this.dayArray = days;
      console.log("this.dayArray" + JSON.stringify(this.dayArray));
      for (var i = 0; i < this.dayArray.length; i++) {
        var selected = i == 0 ? true : false;
        var tempPush = {
          date: this.dayArray[i].date,
          day: this.dayArray[i].days,
          selected: selected,
          session: [{
            type: "Morning",
            lableName: "Morning",
            foodItems: [],
            plateContent: "",
            mainDish: false,
            energy: "0.00",
            Protien: "0.00",
            Ca: "0.00",
            Fe: "0.00",
            fiber: "0.00",
            vaitamina: "0.00",
            Fat: "0.00",
            h2o: "0.00",
            Folic: "0.00",
            Carbohydrates: "0.00",
            Vitaminc: "0.00",
            vitaminb12: "0.00",
            checked: false,
            disabled : false,
          },
          {
            type: "Breakfast",
            lableName: "Breakfast",
            foodItems: [],
            mainDish: false,
            plateContent: "",
            energy: "0.00",
            Protien: "0.00",
            Ca: "0.00",
            Fe: "0.00",
            fiber: "0.00",
            vaitamina: "0.00",
            Fat: "0.00",
            h2o: "0.00",
            Folic: "0.00",
            Carbohydrates: "0.00",
            Vitaminc: "0.00",
            vitaminb12: "0.00",
            checked: false,
            disabled : false,
          },
          {
            type: "Mid-day",
            lableName: "Mid-day Snack",
            foodItems: [],
            mainDish: false,
            plateContent: "",
            energy: "0.00",
            Protien: "0.00",
            Ca: "0.00",
            Fe: "0.00",
            fiber: "0.00",
            vaitamina: "0.00",
            Fat: "0.00",
            h2o: "0.00",
            Folic: "0.00",
            Carbohydrates: "0.00",
            Vitaminc: "0.00",
            vitaminb12: "0.00",
            checked: false,
            disabled : false,
          },
          {
            type: "Lunch",
            lableName: "Lunch",
            foodItems: [],
            mainDish: false,
            plateContent: "",
            energy: "0.00",
            Protien: "0.00",
            Ca: "0.00",
            Fe: "0.00",
            fiber: "0.00",
            vaitamina: "0.00",
            Fat: "0.00",
            h2o: "0.00",
            Folic: "0.00",
            Carbohydrates: "0.00",
            Vitaminc: "0.00",
            vitaminb12: "0.00",
            checked: false,
            disabled : false,

          },
          {
            type: "Evening",
            lableName: "Evening Snack",
            foodItems: [],
            mainDish: false,
            plateContent: "",
            energy: "0.00",
            Protien: "0.00",
            Ca: "0.00",
            Fe: "0.00",
            fiber: "0.00",
            vaitamina: "0.00",
            Fat: "0.00",
            h2o: "0.00",
            Folic: "0.00",
            Carbohydrates: "0.00",
            Vitaminc: "0.00",
            vitaminb12: "0.00",
            checked: false,
            disabled : false,
          },
          {
            type: "Dinner",
            lableName: "Dinner",
            foodItems: [],
            mainDish: false,
            plateContent: "",
            energy: "0.00",
            Protien: "0.00",
            Ca: "0.00",
            Fe: "0.00",
            fiber: "0.00",
            vaitamina: "0.00",
            Fat: "0.00",
            h2o: "0.00",
            Folic: "0.00",
            Carbohydrates: "0.00",
            Vitaminc: "0.00",
            vitaminb12: "0.00",
            checked: false,
            disabled : false,
          },
          {
            type: "Night",
            lableName: "Night",
            foodItems: [],
            mainDish: false,
            plateContent: "",
            energy: "0.00",
            Protien: "0.00",
            Ca: "0.00",
            Fe: "0.00",
            fiber: "0.00",
            vaitamina: "0.00",
            Fat: "0.00",
            h2o: "0.00",
            Folic: "0.00",
            Carbohydrates: "0.00",
            Vitaminc: "0.00",
            vitaminb12: "0.00",
            checked: false,
            disabled : false,
          },
          ]
        }
        var sendArray = {
          created_date: Date_Formate(this.dayArray[i].date),
          dplan_morning: [],
          dplan_breakfast: [],
          dplan_midday: [],
          dplan_lunch: [],
          dplan_evening: [],
          dplan_dinner: [],
          dplan_night: [],
          cal_required: "0.00",
          cal_intake: "0.00"
        }
        this.displayArray.push(tempPush);
        this.trackerArray.push(sendArray)
      }
      this.get_casesheet_data();
    }
  }
  async get_casesheet_data() {
    var sen_pass;
    this.obj = [];

    if (this.getClientDetails.sub_id != null && this.getClientDetails.sub_id != undefined &&
      this.getClientDetails.sub_id != "" && this.getClientDetails.sub_id.length != 0) {
      sen_pass = {
        client_reg_id: this.getClientDetails.client_reg_id,
        relation_id: this.getClientDetails.relation_id,
        sub_rel_id: this.getClientDetails.sunRelID,
        country: "IN",
      }
    } else {
      sen_pass = {
        client_reg_id: this.getClientDetails.client_reg_id,
        relation_id: this.getClientDetails.relation_id,
        country: "IN",
      }
    }
    var response = await this.serviceAPI.PostData('lifestyle/dplann', sen_pass).toPromise();
    if (response) {
      var obj = JSON.parse(JSON.stringify(response));
      obj = obj.diet_plan;
      console.log("this.obj" + JSON.stringify(this.obj));

      if (obj.dplan_morning != null && obj.dplan_morning.length != 0) {
        if (obj.dplan_morning != null) { 
          var tempFoodItems = {};
          for (var i = 0; i < obj.dplan_morning.length; i++) {
            var dayMorning = obj.dplan_morning[i].day != undefined ? obj.dplan_morning[i].day : " ";
            var tempWeight = obj.dplan_morning[i].weight.split(" ");
            var calsWight = tempWeight[0];
            var wightMesure = tempWeight[1];
            tempFoodItems = {
              food_item_id: obj.dplan_morning[i].food_item_id,
              description: obj.dplan_morning[i].description,
              food_type_id: obj.dplan_morning[i].food_type_id,
              food_sub_type: obj.dplan_morning[i].food_sub_type,
              quantity: obj.dplan_morning[i].quantity != undefined ? obj.dplan_morning[i].quantity : "0.00",
              measure: obj.dplan_morning[i].measure != undefined ? obj.dplan_morning[i].measure : "0.00",
              weight: obj.dplan_morning[i].weight != undefined ? obj.dplan_morning[i].weight : "0.00",
              cals_weight: calsWight,
              wightMesure: wightMesure,
              energy: obj.dplan_morning[i].energy != undefined ? obj.dplan_morning[i].energy : "0.00",
              proteins: obj.dplan_morning[i].proteins != undefined ? obj.dplan_morning[i].proteins : "0.00",
              carbohydrates: obj.dplan_morning[i].carbohydrates != undefined ? obj.dplan_morning[i].carbohydrates : "0.00",
              fat: obj.dplan_morning[i].fat != undefined ? obj.dplan_morning[i].fat : "0.00",
              calcium: obj.dplan_morning[i].calcium != undefined ? obj.dplan_morning[i].calcium : "0.00",
              iron: obj.dplan_morning[i].iron != undefined ? obj.dplan_morning[i].iron : "0.00",
              vitaminC: obj.dplan_morning[i].vitaminC != undefined ? obj.dplan_morning[i].vitaminC : "0.00",
              vitaminA: obj.dplan_morning[i].vitaminA != undefined ? obj.dplan_morning[i].vitaminA : "0.00",
              vitaminB12: obj.dplan_morning[i].vitaminB12 != undefined ? obj.dplan_morning[i].vitaminB12 : "0.00",
              fiber: obj.dplan_morning[i].fiber != undefined ? obj.dplan_morning[i].fiber : "0.00",
              folic: obj.dplan_morning[i].folic != undefined ? obj.dplan_morning[i].folic : "0.00",
              h2o: obj.dplan_morning[i].h2o != undefined ? obj.dplan_morning[i].h2o : "0.00",
              session: "Morning",
              image: obj.dplan_morning[i].image,
              mainDish: obj.dplan_morning[i].main_course
            };
            this.selctedFood("", obj.dplan_morning[i].day, tempFoodItems, "0");
          }
        }
      }
      if (obj.dplan_breakfast != null && obj.dplan_breakfast.length != 0) {
        for (var i = 0; i < obj.dplan_breakfast.length; i++) {
          var dayMorning = obj.dplan_breakfast[i].day != undefined ? obj.dplan_breakfast[i].day : " ";
          var tempWeight = obj.dplan_breakfast[i].weight.split(" ");
          var calsWight = tempWeight[0];
          var wightMesure = tempWeight[1];
          tempFoodItems = {
            food_item_id: obj.dplan_breakfast[i].food_item_id,
            description: obj.dplan_breakfast[i].description,
            food_type_id: obj.dplan_breakfast[i].food_type_id,
            food_sub_type: obj.dplan_breakfast[i].food_sub_type,
            quantity: obj.dplan_breakfast[i].quantity != undefined ? obj.dplan_breakfast[i].quantity : "0.00",
            measure: obj.dplan_breakfast[i].measure != undefined ? obj.dplan_breakfast[i].measure : "0.00",
            weight: obj.dplan_breakfast[i].weight != undefined ? obj.dplan_breakfast[i].weight : "0.00",
            cals_weight: calsWight,
            wightMesure: wightMesure,
            energy: obj.dplan_breakfast[i].energy != undefined ? obj.dplan_breakfast[i].energy : "0.00",
            proteins: obj.dplan_breakfast[i].proteins != undefined ? obj.dplan_breakfast[i].proteins : "0.00",
            carbohydrates: obj.dplan_breakfast[i].carbohydrates != undefined ? obj.dplan_breakfast[i].carbohydrates : "0.00",
            fat: obj.dplan_breakfast[i].fat != undefined ? obj.dplan_breakfast[i].fat : "0.00",
            calcium: obj.dplan_breakfast[i].calcium != undefined ? obj.dplan_breakfast[i].calcium : "0.00",
            iron: obj.dplan_breakfast[i].iron != undefined ? obj.dplan_breakfast[i].iron : "0.00",
            vitaminC: obj.dplan_breakfast[i].vitaminC != undefined ? obj.dplan_breakfast[i].vitaminC : "0.00",
            vitaminA: obj.dplan_breakfast[i].vitaminA != undefined ? obj.dplan_breakfast[i].vitaminA : "0.00",
            vitaminB12: obj.dplan_breakfast[i].vitaminB12 != undefined ? obj.dplan_breakfast[i].vitaminB12 : "0.00",
            fiber: obj.dplan_breakfast[i].fiber != undefined ? obj.dplan_breakfast[i].fiber : "0.00",
            folic: obj.dplan_breakfast[i].folic != undefined ? obj.dplan_breakfast[i].folic : "0.00",
            h2o: obj.dplan_breakfast[i].h2o != undefined ? obj.dplan_breakfast[i].h2o : "0.00",
            session: "Breakfast",
            image: obj.dplan_breakfast[i].image,
            mainDish: obj.dplan_breakfast[i].main_course
          };
          this.selctedFood("", obj.dplan_breakfast[i].day, tempFoodItems, "0");
        }
      }
      //Mid day
      if (obj.dplan_midday != null && obj.dplan_midday.length != 0) {
        for (var i = 0; i < obj.dplan_midday.length; i++) {
          var dayMorning = obj.dplan_midday[i].day != undefined ? obj.dplan_midday[i].day : " ";
          var tempWeight = obj.dplan_midday[i].weight.split(" ");
          var calsWight = tempWeight[0];
          var wightMesure = tempWeight[1];
          tempFoodItems = {
            food_item_id: obj.dplan_midday[i].food_item_id,
            description: obj.dplan_midday[i].description,
            food_type_id: obj.dplan_midday[i].food_type_id,
            food_sub_type: obj.dplan_midday[i].food_sub_type,
            quantity: obj.dplan_midday[i].quantity != undefined ? obj.dplan_midday[i].quantity : "0.00",
            measure: obj.dplan_midday[i].measure != undefined ? obj.dplan_midday[i].measure : "0.00",
            weight: obj.dplan_midday[i].weight != undefined ? obj.dplan_midday[i].weight : "0.00",
            cals_weight: calsWight,
            wightMesure: wightMesure,
            energy: obj.dplan_midday[i].energy != undefined ? obj.dplan_midday[i].energy : "0.00",
            proteins: obj.dplan_midday[i].proteins != undefined ? obj.dplan_midday[i].proteins : "0.00",
            carbohydrates: obj.dplan_midday[i].carbohydrates != undefined ? obj.dplan_midday[i].carbohydrates : "0.00",
            fat: obj.dplan_midday[i].fat != undefined ? obj.dplan_midday[i].fat : "0.00",
            calcium: obj.dplan_midday[i].calcium != undefined ? obj.dplan_midday[i].calcium : "0.00",
            iron: obj.dplan_midday[i].iron != undefined ? obj.dplan_midday[i].iron : "0.00",
            vitaminC: obj.dplan_midday[i].vitaminC != undefined ? obj.dplan_midday[i].vitaminC : "0.00",
            vitaminA: obj.dplan_midday[i].vitaminA != undefined ? obj.dplan_midday[i].vitaminA : "0.00",
            vitaminB12: obj.dplan_midday[i].vitaminB12 != undefined ? obj.dplan_midday[i].vitaminB12 : "0.00",
            fiber: obj.dplan_midday[i].fiber != undefined ? obj.dplan_midday[i].fiber : "0.00",
            folic: obj.dplan_midday[i].folic != undefined ? obj.dplan_midday[i].folic : "0.00",
            h2o: obj.dplan_midday[i].h2o != undefined ? obj.dplan_midday[i].h2o : "0.00",
            session: "Mid-day",
            image: obj.dplan_midday[i].image,
            mainDish: obj.dplan_midday[i].main_course
          };
          this.selctedFood("", obj.dplan_midday[i].day, tempFoodItems, "0");
        }
      }
      //Lunch
      if (obj.dplan_lunch != null && obj.dplan_lunch.length != 0) {
        for (var i = 0; i < obj.dplan_lunch.length; i++) {
          var dayMorning = obj.dplan_lunch[i].day != undefined ? obj.dplan_lunch[i].day : " ";
          var tempWeight = obj.dplan_lunch[i].weight.split(" ");
          var calsWight = tempWeight[0];
          var wightMesure = tempWeight[1];
          tempFoodItems = {
            food_item_id: obj.dplan_lunch[i].food_item_id,
            description: obj.dplan_lunch[i].description,
            food_type_id: obj.dplan_lunch[i].food_type_id,
            food_sub_type: obj.dplan_lunch[i].food_sub_type,
            quantity: obj.dplan_lunch[i].quantity != undefined ? obj.dplan_lunch[i].quantity : "0.00",
            measure: obj.dplan_lunch[i].measure != undefined ? obj.dplan_lunch[i].measure : "0.00",
            weight: obj.dplan_lunch[i].weight != undefined ? obj.dplan_lunch[i].weight : "0.00",
            cals_weight: calsWight,
            wightMesure: wightMesure,
            energy: obj.dplan_lunch[i].energy != undefined ? obj.dplan_lunch[i].energy : "0.00",
            proteins: obj.dplan_lunch[i].proteins != undefined ? obj.dplan_lunch[i].proteins : "0.00",
            carbohydrates: obj.dplan_lunch[i].carbohydrates != undefined ? obj.dplan_lunch[i].carbohydrates : "0.00",
            fat: obj.dplan_lunch[i].fat != undefined ? obj.dplan_lunch[i].fat : "0.00",
            calcium: obj.dplan_lunch[i].calcium != undefined ? obj.dplan_lunch[i].calcium : "0.00",
            iron: obj.dplan_lunch[i].iron != undefined ? obj.dplan_lunch[i].iron : "0.00",
            vitaminC: obj.dplan_lunch[i].vitaminC != undefined ? obj.dplan_lunch[i].vitaminC : "0.00",
            vitaminA: obj.dplan_lunch[i].vitaminA != undefined ? obj.dplan_lunch[i].vitaminA : "0.00",
            vitaminB12: obj.dplan_lunch[i].vitaminB12 != undefined ? obj.dplan_lunch[i].vitaminB12 : "0.00",
            fiber: obj.dplan_lunch[i].fiber != undefined ? obj.dplan_lunch[i].fiber : "0.00",
            folic: obj.dplan_lunch[i].folic != undefined ? obj.dplan_lunch[i].folic : "0.00",
            h2o: obj.dplan_lunch[i].h2o != undefined ? obj.dplan_lunch[i].h2o : "0.00",
            session: "Lunch",
            image: obj.dplan_lunch[i].image,
            mainDish: obj.dplan_lunch[i].main_course
          };
          this.selctedFood("", obj.dplan_lunch[i].day, tempFoodItems, "0");
        }
      }
      //Evening nacks
      if (obj.dplan_evening != null && obj.dplan_evening.length != 0) {
        for (var i = 0; i < obj.dplan_evening.length; i++) {
          var dayMorning = obj.dplan_evening[i].day != undefined ? obj.dplan_evening[i].day : " ";
          var tempWeight = obj.dplan_evening[i].weight.split(" ");
          var calsWight = tempWeight[0];
          var wightMesure = tempWeight[1];
          tempFoodItems = {
            food_item_id: obj.dplan_evening[i].food_item_id,
            description: obj.dplan_evening[i].description,
            food_type_id: obj.dplan_evening[i].food_type_id,
            food_sub_type: obj.dplan_evening[i].food_sub_type,
            quantity: obj.dplan_evening[i].quantity != undefined ? obj.dplan_evening[i].quantity : "0.00",
            measure: obj.dplan_evening[i].measure != undefined ? obj.dplan_evening[i].measure : "0.00",
            weight: obj.dplan_evening[i].weight != undefined ? obj.dplan_evening[i].weight : "0.00",
            cals_weight: calsWight,
            wightMesure: wightMesure,
            energy: obj.dplan_evening[i].energy != undefined ? obj.dplan_evening[i].energy : "0.00",
            proteins: obj.dplan_evening[i].proteins != undefined ? obj.dplan_evening[i].proteins : "0.00",
            carbohydrates: obj.dplan_evening[i].carbohydrates != undefined ? obj.dplan_evening[i].carbohydrates : "0.00",
            fat: obj.dplan_evening[i].fat != undefined ? obj.dplan_evening[i].fat : "0.00",
            calcium: obj.dplan_evening[i].calcium != undefined ? obj.dplan_evening[i].calcium : "0.00",
            iron: obj.dplan_evening[i].iron != undefined ? obj.dplan_evening[i].iron : "0.00",
            vitaminC: obj.dplan_evening[i].vitaminC != undefined ? obj.dplan_evening[i].vitaminC : "0.00",
            vitaminA: obj.dplan_evening[i].vitaminA != undefined ? obj.dplan_evening[i].vitaminA : "0.00",
            vitaminB12: obj.dplan_evening[i].vitaminB12 != undefined ? obj.dplan_evening[i].vitaminB12 : "0.00",
            fiber: obj.dplan_evening[i].fiber != undefined ? obj.dplan_evening[i].fiber : "0.00",
            folic: obj.dplan_evening[i].folic != undefined ? obj.dplan_evening[i].folic : "0.00",
            h2o: obj.dplan_evening[i].h2o != undefined ? obj.dplan_evening[i].h2o : "0.00",
            session: "Evening",
            image: obj.dplan_evening[i].image,
            mainDish: obj.dplan_evening[i].main_course
          };
          this.selctedFood("", obj.dplan_evening[i].day, tempFoodItems, "0");
        }
      }
      //Dinner nacks
      if (obj.dplan_dinner != null && obj.dplan_dinner.length != 0) {
        for (var i = 0; i < obj.dplan_dinner.length; i++) {
          var dayMorning = obj.dplan_dinner[i].day != undefined ? obj.dplan_dinner[i].day : " ";
          var tempWeight = obj.dplan_dinner[i].weight.split(" ");
          var calsWight = tempWeight[0];
          var wightMesure = tempWeight[1];
          tempFoodItems = {
            food_item_id: obj.dplan_dinner[i].food_item_id,
            description: obj.dplan_dinner[i].description,
            food_type_id: obj.dplan_dinner[i].food_type_id,
            food_sub_type: obj.dplan_dinner[i].food_sub_type,
            quantity: obj.dplan_dinner[i].quantity != undefined ? obj.dplan_dinner[i].quantity : "0.00",
            measure: obj.dplan_dinner[i].measure != undefined ? obj.dplan_dinner[i].measure : "0.00",
            weight: obj.dplan_dinner[i].weight != undefined ? obj.dplan_dinner[i].weight : "0.00",
            cals_weight: calsWight,
            wightMesure: wightMesure,
            energy: obj.dplan_dinner[i].energy != undefined ? obj.dplan_dinner[i].energy : "0.00",
            proteins: obj.dplan_dinner[i].proteins != undefined ? obj.dplan_dinner[i].proteins : "0.00",
            carbohydrates: obj.dplan_dinner[i].carbohydrates != undefined ? obj.dplan_dinner[i].carbohydrates : "0.00",
            fat: obj.dplan_dinner[i].fat != undefined ? obj.dplan_dinner[i].fat : "0.00",
            calcium: obj.dplan_dinner[i].calcium != undefined ? obj.dplan_dinner[i].calcium : "0.00",
            iron: obj.dplan_dinner[i].iron != undefined ? obj.dplan_dinner[i].iron : "0.00",
            vitaminC: obj.dplan_dinner[i].vitaminC != undefined ? obj.dplan_dinner[i].vitaminC : "0.00",
            vitaminA: obj.dplan_dinner[i].vitaminA != undefined ? obj.dplan_dinner[i].vitaminA : "0.00",
            vitaminB12: obj.dplan_dinner[i].vitaminB12 != undefined ? obj.dplan_dinner[i].vitaminB12 : "0.00",
            fiber: obj.dplan_dinner[i].fiber != undefined ? obj.dplan_dinner[i].fiber : "0.00",
            folic: obj.dplan_dinner[i].folic != undefined ? obj.dplan_dinner[i].folic : "0.00",
            h2o: obj.dplan_dinner[i].h2o != undefined ? obj.dplan_dinner[i].h2o : "0.00",
            session: "Dinner",
            mainDish: obj.dplan_dinner[i].main_course
          };
          this.selctedFood("", obj.dplan_dinner[i].day, tempFoodItems, "0");
        }
      }
      //Night
      if (obj.dplan_night != null && obj.dplan_night.length != 0) {
        for (var i = 0; i < obj.dplan_night.length; i++) {
          var dayMorning = obj.dplan_night[i].day != undefined ? obj.dplan_night[i].day : " ";
          var tempWeight = obj.dplan_night[i].weight.split(" ");
          var calsWight = tempWeight[0];
          var wightMesure = tempWeight[1];
          tempFoodItems = {
            food_item_id: obj.dplan_night[i].food_item_id,
            description: obj.dplan_night[i].description,
            food_type_id: obj.dplan_night[i].food_type_id,
            food_sub_type: obj.dplan_night[i].food_sub_type,
            quantity: obj.dplan_night[i].quantity != undefined ? obj.dplan_night[i].quantity : "0.00",
            measure: obj.dplan_night[i].measure != undefined ? obj.dplan_night[i].measure : "0.00",
            weight: obj.dplan_night[i].weight != undefined ? obj.dplan_night[i].weight : "0.00",
            cals_weight: calsWight,
            wightMesure: wightMesure,
            energy: obj.dplan_night[i].energy != undefined ? obj.dplan_night[i].energy : "0.00",
            proteins: obj.dplan_night[i].proteins != undefined ? obj.dplan_night[i].proteins : "0.00",
            carbohydrates: obj.dplan_night[i].carbohydrates != undefined ? obj.dplan_night[i].carbohydrates : "0.00",
            fat: obj.dplan_night[i].fat != undefined ? obj.dplan_night[i].fat : "0.00",
            calcium: obj.dplan_night[i].calcium != undefined ? obj.dplan_night[i].calcium : "0.00",
            iron: obj.dplan_night[i].iron != undefined ? obj.dplan_night[i].iron : "0.00",
            vitaminC: obj.dplan_night[i].vitaminC != undefined ? obj.dplan_night[i].vitaminC : "0.00",
            vitaminA: obj.dplan_night[i].vitaminA != undefined ? obj.dplan_night[i].vitaminA : "0.00",
            vitaminB12: obj.dplan_night[i].vitaminB12 != undefined ? obj.dplan_night[i].vitaminB12 : "0.00",
            fiber: obj.dplan_night[i].fiber != undefined ? obj.dplan_night[i].fiber : "0.00",
            folic: obj.dplan_night[i].folic != undefined ? obj.dplan_night[i].folic : "0.00",
            h2o: obj.dplan_night[i].h2o != undefined ? obj.dplan_night[i].h2o : "0.00",
            session: "Night",
            image: obj.dplan_night[i].image,
            mainDish: obj.dplan_night[i].main_course
          };
          this.selctedFood("", obj.dplan_night[i].day, tempFoodItems, "0");
        }
      }
      console.log("this.displayArray" + JSON.stringify(this.displayArray));
      this.getFoodhistory();
    }
  }
  async getFoodhistory() {
    var sen_pass;
    if (this.getClientDetails.sunRelID != null && this.getClientDetails.sunRelID != undefined && this.getClientDetails.sunRelID != "" && this.getClientDetails.sunRelID.length != 0) {
      sen_pass = {
        client_reg_id: this.getClientDetails.client_reg_id,
        relation_id: this.getClientDetails.relation_id,
        sub_rel_id: this.getClientDetails.sunRelID,
        from_date: this.sendFromDate,
        to_date: this.sendToDate,
      }
    } else {
      sen_pass = {
        client_reg_id: this.getClientDetails.client_reg_id,
        relation_id: this.getClientDetails.relation_id,
        from_date: this.sendFromDate,
        to_date: this.sendToDate,
      }
    }
    var response = await this.serviceAPI.PostData('lifestyle/dphn', sen_pass).toPromise();
    if (response) {
      console.log("get the food history" + JSON.stringify(response));
      var obj = JSON.parse(JSON.stringify(response));
      console.log("get Display Array" + JSON.stringify(this.foodLogArray));
      var dplan_track_morning = obj.dplan_track_morning != undefined ? obj.dplan_track_morning : [];
      var dplan_track_breakfast = obj.dplan_track_breakfast != undefined ? obj.dplan_track_breakfast : [];
      var dplan_track_midday = obj.dplan_track_midday != undefined ? obj.dplan_track_midday : [];
      var dplan_track_lunch = obj.dplan_track_lunch != undefined ? obj.dplan_track_lunch : [];
      var dplan_track_evening = obj.dplan_track_evening != undefined ? obj.dplan_track_evening : [];
      var dplan_track_dinner = obj.dplan_track_dinner != undefined ? obj.dplan_track_dinner : [];
      var dplan_track_night = obj.dplan_track_night != undefined ? obj.dplan_track_night : [];
      for( var i = 0; i < this.displayArray.length; i++ ){

          for( var j = 0; j < this.displayArray[i].session.length; j++){
            if( this.displayArray[i].session[j].type == 'Morning' ){
              var loopingCheck = true;
              
                for( var k = 0; k < dplan_track_morning.length; k++ ){
                    if( Date_Formate(this.displayArray[i].date) == dplan_track_morning[k].created_date){
                      this.displayArray[i].session[j].checked = true;
                      this.displayArray[i].session[j].disabled = true;
                      if( loopingCheck == true ){
                        this.displayArray[i].session[j].foodItems = [];
                        loopingCheck = false;
                      }
                      var tempWeight = dplan_track_morning[k].weight.split(" ");
                          var calsWight = tempWeight[0];
                          var wightMesure = tempWeight[1];
                      var pushingArray = {
                        displayContent: dplan_track_morning[k].description + " (" + dplan_track_morning[k].quantity + " " + dplan_track_morning[k].measure + " - " + dplan_track_morning[k].weight + " )",
                        food_item_id: dplan_track_morning[k].food_item_id,
                        description: dplan_track_morning[k].description,
                        food_type_id: dplan_track_morning[k].food_type_id,
                        food_sub_type: dplan_track_morning[k].food_sub_type,
                        quantity: dplan_track_morning[k].quantity,
                        temp_cals_weight: calsWight,
                        wightMesure: wightMesure,
                        measure: dplan_track_morning[k].measure,
                        weight: dplan_track_morning[k].weight,
                        cals_weight: calsWight,
                        energy: dplan_track_morning[k].energy,
                        proteins: dplan_track_morning[k].proteins,
                        carbohydrates: dplan_track_morning[k].carbohydrates,
                        fat: dplan_track_morning[k].fat,
                        calcium: dplan_track_morning[k].calcium,
                        iron: dplan_track_morning[k].iron,
                        vitaminC: dplan_track_morning[k].vitaminC,
                        vitaminA: dplan_track_morning[k].vitaminA,
                        vitaminB12: dplan_track_morning[k].vitaminB12,
                        fiber: dplan_track_morning[k].fiber,
                        folic: dplan_track_morning[k].folic,
                        h2o: dplan_track_morning[k].h2o,
                        checked: true,
                        session: dplan_track_morning[k].session,
                        day: dplan_track_morning[k].day,
                        mainDish: dplan_track_morning[k].mainDish,
                      };
                      this.displayArray[i].session[j].foodItems.push(pushingArray);
                    }
                }
            }else if(this.displayArray[i].session[j].type == 'Breakfast'){
              var loopingCheck2 = true;
             
                for( var k = 0; k < dplan_track_breakfast.length; k++ ){
                    if( Date_Formate(this.displayArray[i].date) == dplan_track_breakfast[k].created_date){
                      this.displayArray[i].session[j].checked = true;
                      this.displayArray[i].session[j].disabled = true;
                      if( loopingCheck2 == true ){
                        this.displayArray[i].session[j].foodItems = [];
                        loopingCheck2 = false;
                      }
                      var tempWeight = dplan_track_breakfast[k].weight.split(" ");
                          var calsWight = tempWeight[0];
                          var wightMesure = tempWeight[1];
                      var pushingArray = {
                        displayContent: dplan_track_breakfast[k].description + " (" + dplan_track_breakfast[k].quantity + " " + dplan_track_breakfast[k].measure + " - " + dplan_track_breakfast[k].weight + " )",
                        food_item_id: dplan_track_breakfast[k].food_item_id,
                        description: dplan_track_breakfast[k].description,
                        food_type_id: dplan_track_breakfast[k].food_type_id,
                        food_sub_type: dplan_track_breakfast[k].food_sub_type,
                        quantity: dplan_track_breakfast[k].quantity,
                        temp_cals_weight: calsWight,
                        wightMesure: wightMesure,
                        measure: dplan_track_breakfast[k].measure,
                        weight: dplan_track_breakfast[k].weight,
                        cals_weight: calsWight,
                        energy: dplan_track_breakfast[k].energy,
                        proteins: dplan_track_breakfast[k].proteins,
                        carbohydrates: dplan_track_breakfast[k].carbohydrates,
                        fat: dplan_track_breakfast[k].fat,
                        calcium: dplan_track_breakfast[k].calcium,
                        iron: dplan_track_breakfast[k].iron,
                        vitaminC: dplan_track_breakfast[k].vitaminC,
                        vitaminA: dplan_track_breakfast[k].vitaminA,
                        vitaminB12: dplan_track_breakfast[k].vitaminB12,
                        fiber: dplan_track_breakfast[k].fiber,
                        folic: dplan_track_breakfast[k].folic,
                        h2o: dplan_track_breakfast[k].h2o,
                        checked: true,
                        session: dplan_track_breakfast[k].session,
                        day: dplan_track_breakfast[k].day,
                        mainDish: dplan_track_breakfast[k].mainDish,
                      };
                      this.displayArray[i].session[j].foodItems.push(pushingArray);
                    }
                }
            }else if(this.displayArray[i].session[j].type == 'Mid-day'){
              var loopingCheck3 = true;
               
                  for( var k = 0; k < dplan_track_midday.length; k++ ){
                      if( Date_Formate(this.displayArray[i].date) == dplan_track_midday[k].created_date){
                        this.displayArray[i].session[j].checked = true;
                        this.displayArray[i].session[j].disabled = true;
                        if( loopingCheck3 == true ){
                          this.displayArray[i].session[j].foodItems = [];
                          loopingCheck3 = false;
                        }
                        var tempWeight = dplan_track_midday[k].weight.split(" ");
                            var calsWight = tempWeight[0];
                            var wightMesure = tempWeight[1];
                        var pushingArray = {
                          displayContent: dplan_track_midday[k].description + " (" + dplan_track_midday[k].quantity + " " + dplan_track_midday[k].measure + " - " + dplan_track_midday[k].weight + " )",
                          food_item_id: dplan_track_midday[k].food_item_id,
                          description: dplan_track_midday[k].description,
                          food_type_id: dplan_track_midday[k].food_type_id,
                          food_sub_type: dplan_track_midday[k].food_sub_type,
                          quantity: dplan_track_midday[k].quantity,
                          temp_cals_weight: calsWight,
                          wightMesure: wightMesure,
                          measure: dplan_track_midday[k].measure,
                          weight: dplan_track_midday[k].weight,
                          cals_weight: calsWight,
                          energy: dplan_track_midday[k].energy,
                          proteins: dplan_track_midday[k].proteins,
                          carbohydrates: dplan_track_midday[k].carbohydrates,
                          fat: dplan_track_midday[k].fat,
                          calcium: dplan_track_midday[k].calcium,
                          iron: dplan_track_midday[k].iron,
                          vitaminC: dplan_track_midday[k].vitaminC,
                          vitaminA: dplan_track_midday[k].vitaminA,
                          vitaminB12: dplan_track_midday[k].vitaminB12,
                          fiber: dplan_track_midday[k].fiber,
                          folic: dplan_track_midday[k].folic,
                          h2o: dplan_track_midday[k].h2o,
                          checked: true,
                          session: dplan_track_midday[k].session,
                          day: dplan_track_midday[k].day,
                          mainDish: dplan_track_midday[k].mainDish,
                        };
                        this.displayArray[i].session[j].foodItems.push(pushingArray);
                      }
                  }
            }else if(this.displayArray[i].session[j].type == 'Lunch'){
              var loopingCheck4 = true;
                
                  for( var k = 0; k < dplan_track_lunch.length; k++ ){
                      if( Date_Formate(this.displayArray[i].date) == dplan_track_lunch[k].created_date){
                        this.displayArray[i].session[j].checked = true;
                        this.displayArray[i].session[j].disabled = true;
                        if( loopingCheck4 == true ){
                          this.displayArray[i].session[j].foodItems = [];
                          loopingCheck4 = false;
                        }
                        var tempWeight = dplan_track_lunch[k].weight.split(" ");
                            var calsWight = tempWeight[0];
                            var wightMesure = tempWeight[1];
                        var pushingArray = {
                          displayContent: dplan_track_lunch[k].description + " (" + dplan_track_lunch[k].quantity + " " + dplan_track_lunch[k].measure + " - " + dplan_track_lunch[k].weight + " )",
                          food_item_id: dplan_track_lunch[k].food_item_id,
                          description: dplan_track_lunch[k].description,
                          food_type_id: dplan_track_lunch[k].food_type_id,
                          food_sub_type: dplan_track_lunch[k].food_sub_type,
                          quantity: dplan_track_lunch[k].quantity,
                          temp_cals_weight: calsWight,
                          wightMesure: wightMesure,
                          measure: dplan_track_lunch[k].measure,
                          weight: dplan_track_lunch[k].weight,
                          cals_weight: calsWight,
                          energy: dplan_track_lunch[k].energy,
                          proteins: dplan_track_lunch[k].proteins,
                          carbohydrates: dplan_track_lunch[k].carbohydrates,
                          fat: dplan_track_lunch[k].fat,
                          calcium: dplan_track_lunch[k].calcium,
                          iron: dplan_track_lunch[k].iron,
                          vitaminC: dplan_track_lunch[k].vitaminC,
                          vitaminA: dplan_track_lunch[k].vitaminA,
                          vitaminB12: dplan_track_lunch[k].vitaminB12,
                          fiber: dplan_track_lunch[k].fiber,
                          folic: dplan_track_lunch[k].folic,
                          h2o: dplan_track_lunch[k].h2o,
                          checked: true,
                          session: dplan_track_lunch[k].session,
                          day: dplan_track_lunch[k].day,
                          mainDish: dplan_track_lunch[k].mainDish,
                        };
                        this.displayArray[i].session[j].foodItems.push(pushingArray);
                      }
                  }
            }else if(this.displayArray[i].session[j].type == 'Evening'){
              var loopingCheck5 = true;
                
                  for( var k = 0; k < dplan_track_evening.length; k++ ){
                      if( Date_Formate(this.displayArray[i].date) == dplan_track_evening[k].created_date){
                        this.displayArray[i].session[j].checked = true;
                        this.displayArray[i].session[j].disabled = true;
                        if( loopingCheck5 == true ){
                          this.displayArray[i].session[j].foodItems = [];
                          loopingCheck5 = false;
                        }
                        var tempWeight = dplan_track_evening[k].weight.split(" ");
                            var calsWight = tempWeight[0];
                            var wightMesure = tempWeight[1];
                        var pushingArray = {
                          displayContent: dplan_track_evening[k].description + " (" + dplan_track_evening[k].quantity + " " + dplan_track_evening[k].measure + " - " + dplan_track_evening[k].weight + " )",
                          food_item_id: dplan_track_evening[k].food_item_id,
                          description: dplan_track_evening[k].description,
                          food_type_id: dplan_track_evening[k].food_type_id,
                          food_sub_type: dplan_track_evening[k].food_sub_type,
                          quantity: dplan_track_evening[k].quantity,
                          temp_cals_weight: calsWight,
                          wightMesure: wightMesure,
                          measure: dplan_track_evening[k].measure,
                          weight: dplan_track_evening[k].weight,
                          cals_weight: calsWight,
                          energy: dplan_track_evening[k].energy,
                          proteins: dplan_track_evening[k].proteins,
                          carbohydrates: dplan_track_evening[k].carbohydrates,
                          fat: dplan_track_evening[k].fat,
                          calcium: dplan_track_evening[k].calcium,
                          iron: dplan_track_evening[k].iron,
                          vitaminC: dplan_track_evening[k].vitaminC,
                          vitaminA: dplan_track_evening[k].vitaminA,
                          vitaminB12: dplan_track_evening[k].vitaminB12,
                          fiber: dplan_track_evening[k].fiber,
                          folic: dplan_track_evening[k].folic,
                          h2o: dplan_track_evening[k].h2o,
                          checked: true,
                          session: dplan_track_evening[k].session,
                          day: dplan_track_evening[k].day,
                          mainDish: dplan_track_evening[k].mainDish,
                        };
                        this.displayArray[i].session[j].foodItems.push(pushingArray);
                      }
                  }
            }else if(this.displayArray[i].session[j].type == 'Dinner'){
              var loopingCheck6 = true;
             
                for( var k = 0; k < dplan_track_dinner.length; k++ ){
                    if( Date_Formate(this.displayArray[i].date) == dplan_track_dinner[k].created_date){
                      this.displayArray[i].session[j].checked = true;
                      this.displayArray[i].session[j].disabled = true;
                      if( loopingCheck6 == true ){
                        this.displayArray[i].session[j].foodItems = [];
                        loopingCheck6 = false;
                      }
                      var tempWeight = dplan_track_dinner[k].weight.split(" ");
                          var calsWight = tempWeight[0];
                          var wightMesure = tempWeight[1];
                      var pushingArray = {
                        displayContent: dplan_track_dinner[k].description + " (" + dplan_track_dinner[k].quantity + " " + dplan_track_dinner[k].measure + " - " + dplan_track_dinner[k].weight + " )",
                        food_item_id: dplan_track_dinner[k].food_item_id,
                        description: dplan_track_dinner[k].description,
                        food_type_id: dplan_track_dinner[k].food_type_id,
                        food_sub_type: dplan_track_dinner[k].food_sub_type,
                        quantity: dplan_track_dinner[k].quantity,
                        temp_cals_weight: calsWight,
                        wightMesure: wightMesure,
                        measure: dplan_track_dinner[k].measure,
                        weight: dplan_track_dinner[k].weight,
                        cals_weight: calsWight,
                        energy: dplan_track_dinner[k].energy,
                        proteins: dplan_track_dinner[k].proteins,
                        carbohydrates: dplan_track_dinner[k].carbohydrates,
                        fat: dplan_track_dinner[k].fat,
                        calcium: dplan_track_dinner[k].calcium,
                        iron: dplan_track_dinner[k].iron,
                        vitaminC: dplan_track_dinner[k].vitaminC,
                        vitaminA: dplan_track_dinner[k].vitaminA,
                        vitaminB12: dplan_track_dinner[k].vitaminB12,
                        fiber: dplan_track_dinner[k].fiber,
                        folic: dplan_track_dinner[k].folic,
                        h2o: dplan_track_dinner[k].h2o,
                        checked: true,
                        session: dplan_track_dinner[k].session,
                        day: dplan_track_dinner[k].day,
                        mainDish: dplan_track_dinner[k].mainDish,
                      };
                      this.displayArray[i].session[j].foodItems.push(pushingArray);
                    }
                }
            }else if(this.displayArray[i].session[j].type == 'Night'){
              var loopingCheck7 = true;
                
                  for( var k = 0; k < dplan_track_night.length; k++ ){
                      if( Date_Formate(this.displayArray[i].date) == dplan_track_night[k].created_date){
                        this.displayArray[i].session[j].checked = true;
                        this.displayArray[i].session[j].disabled = true;
                        if( loopingCheck7 == true ){
                          this.displayArray[i].session[j].foodItems = [];
                          loopingCheck7 = false;
                        }
                        var tempWeight = dplan_track_night[k].weight.split(" ");
                            var calsWight = tempWeight[0];
                            var wightMesure = tempWeight[1];
                        var pushingArray = {
                          displayContent: dplan_track_night[k].description + " (" + dplan_track_night[k].quantity + " " + dplan_track_night[k].measure + " - " + dplan_track_night[k].weight + " )",
                          food_item_id: dplan_track_night[k].food_item_id,
                          description: dplan_track_night[k].description,
                          food_type_id: dplan_track_night[k].food_type_id,
                          food_sub_type: dplan_track_night[k].food_sub_type,
                          quantity: dplan_track_night[k].quantity,
                          temp_cals_weight: calsWight,
                          wightMesure: wightMesure,
                          measure: dplan_track_night[k].measure,
                          weight: dplan_track_night[k].weight,
                          cals_weight: calsWight,
                          energy: dplan_track_night[k].energy,
                          proteins: dplan_track_night[k].proteins,
                          carbohydrates: dplan_track_night[k].carbohydrates,
                          fat: dplan_track_night[k].fat,
                          calcium: dplan_track_night[k].calcium,
                          iron: dplan_track_night[k].iron,
                          vitaminC: dplan_track_night[k].vitaminC,
                          vitaminA: dplan_track_night[k].vitaminA,
                          vitaminB12: dplan_track_night[k].vitaminB12,
                          fiber: dplan_track_night[k].fiber,
                          folic: dplan_track_night[k].folic,
                          h2o: dplan_track_night[k].h2o,
                          checked: true,
                          session: dplan_track_night[k].session,
                          day: dplan_track_night[k].day,
                          mainDish: dplan_track_night[k].mainDish,
                        };
                        this.displayArray[i].session[j].foodItems.push(pushingArray);
                      }
                  }
            }
          }
      }  
      console.log("displayArray ((((()))))" + JSON.stringify(this.displayArray));
    }
  }
  selctedFood(date, day, foodArray, flag) {
    for (var i = 0; i < this.displayArray.length; i++) {
      if (this.displayArray[i].day == day) {
        var session = this.displayArray[i].session;
        if (session.length != 0) {
          for (var j = 0; j < session.length; j++) {
            if (session[j].type == foodArray.session) {
              var is_doctor;
              if( flag == "1" ){
                is_doctor = "0";
              }
              var disArray = {
                displayContent: foodArray.description + " (" + foodArray.quantity + " " + foodArray.measure + " - " + foodArray.weight + " )",
                food_item_id: foodArray.food_item_id,
                description: foodArray.description,
                food_type_id: foodArray.food_type_id,
                food_sub_type: foodArray.food_sub_type,
                quantity: foodArray.quantity,
                temp_cals_weight: foodArray.cals_weight,
                wightMesure: foodArray.wightMesure,
                measure: foodArray.measure,
                weight: foodArray.weight,
                cals_weight: foodArray.cals_weight,
                energy: foodArray.energy,
                proteins: foodArray.proteins,
                carbohydrates: foodArray.carbohydrates,
                fat: foodArray.fat,
                calcium: foodArray.calcium,
                iron: foodArray.iron,
                vitaminC: foodArray.vitaminC,
                vitaminA: foodArray.vitaminA,
                vitaminB12: foodArray.vitaminB12,
                fiber: foodArray.fiber,
                folic: foodArray.folic,
                h2o: foodArray.h2o,
                checked: true,
                session: foodArray.session,
                day: day,
                mainDish: foodArray.mainDish,
                is_doctor : is_doctor,
              };
              this.displayArray[i].session[j].foodItems.push(disArray);
              this.calculatingFoodData(day, foodArray.session);
              if (flag == "1") {
                this.selectSend(date, foodArray.session, disArray);
              }
              break;
            }
          }
        }
        break;
      }
    }
  }
  selectSend(date, session, getArray) {
    for (var i = 0; i < this.trackerArray.length; i++) {
      if (this.trackerArray[i].created_date == Date_Formate(date)) {
        if (session == "Morning") {
          this.trackerArray[i].dplan_morning.push(getArray);
        } else if (session == "Breakfast") {
          this.trackerArray[i].dplan_breakfast.push(getArray);
        } else if (session == "Mid-day") {
          this.trackerArray[i].dplan_midday.push(getArray);
        } else if (session == "Lunch") {
          this.trackerArray[i].dplan_lunch.push(getArray);
        } else if (session == "Evening") {
          this.trackerArray[i].dplan_evening.push(getArray);
        } else if (session == "Dinner") {
          this.trackerArray[i].dplan_dinner.push(getArray);
        } else if (session == "Night") {
          this.trackerArray[i].dplan_night.push(getArray);
        }
      }
    }
  }
  calculatingFoodData(day, session) {
    for (var i = 0; i < this.displayArray.length; i++) {
      if (this.displayArray[i].day == day) {
        for (var j = 0; j < this.displayArray[i].session.length; j++) {
          if (this.displayArray[i].session[j].type == session) {
            var fooditems = this.displayArray[i].session[j].foodItems;
            var energy = "0.00";
            var Protien = "0.00";
            var Ca = "0.00";
            var Fe = "0.00";
            var fiber = "0.00";
            var vaitamina = "0.00";
            var Fat = "0.00";
            var h2o = "0.00";
            var Folic = "0.00";
            var Carbohydrates = "0.00";
            var Vitaminc = "0.00";
            var vitaminb12 = "0.00";
            var htmlContent = "";
            var count = 1;
            for (var k = 0; k < fooditems.length; k++) {
              if (fooditems[k].checked == true) {
                energy = (parseFloat(energy) + parseFloat(fooditems[k].energy)).toFixed(2);
                Protien = (parseFloat(Protien) + parseFloat(fooditems[k].proteins)).toFixed(2);
                Ca = (parseFloat(Ca) + parseFloat(fooditems[k].calcium)).toFixed(2);
                Fe = (parseFloat(Fe) + parseFloat(fooditems[k].iron)).toFixed(2);
                fiber = (parseFloat(fiber) + parseFloat(fooditems[k].fiber)).toFixed(2);
                vaitamina = (parseFloat(vaitamina) + parseFloat(fooditems[k].vitaminA)).toFixed(2);
                Fat = (parseFloat(Fat) + parseFloat(fooditems[k].fat)).toFixed(2);
                h2o = (parseFloat(h2o) + parseFloat(fooditems[k].h2o)).toFixed(2);
                Folic = (parseFloat(Folic) + parseFloat(fooditems[k].folic)).toFixed(2);
                Carbohydrates = (parseFloat(Carbohydrates) + parseFloat(fooditems[k].carbohydrates)).toFixed(2);
                Vitaminc = (parseFloat(Vitaminc) + parseFloat(fooditems[k].vitaminC)).toFixed(2);
                vitaminb12 = (parseFloat(vitaminb12) + parseFloat(fooditems[k].vitaminB12)).toFixed(2);
              }
            }
            this.displayArray[i].session[j].energy = energy;
            this.displayArray[i].session[j].Protien = Protien;
            this.displayArray[i].session[j].Ca = Ca;
            this.displayArray[i].session[j].Fe = Fe;
            this.displayArray[i].session[j].fiber = fiber;
            this.displayArray[i].session[j].vaitamina = vaitamina;
            this.displayArray[i].session[j].Fat = Fat;
            this.displayArray[i].session[j].h2o = h2o;
            this.displayArray[i].session[j].Folic = Folic;
            this.displayArray[i].session[j].Carbohydrates = Carbohydrates;
            this.displayArray[i].session[j].Vitaminc = Vitaminc;
            this.displayArray[i].session[j].vitaminb12 = vitaminb12;
            this.displayArray[i].session[j].plateContent = htmlContent;
            break;
          }
        }
        break;
      }
    }
  }

  slectedDay(day) {
    for (var i = 0; i < this.displayArray.length; i++) {
      if (this.displayArray[i].day == day) {
        this.displayArray[i].selected = true;
      } else {
        this.displayArray[i].selected = false;
      }
    }
  }
  isSelected(array, action, date) {
    console.log(JSON.stringify(array));

    for (var j = 0; j < array.foodItems.length; j++) {
      var tempArray = array.foodItems[j];
      var sendArray = {
        food_item_id: tempArray.food_item_id,
        quantity: tempArray.quantity,
        measure: tempArray.measure,
        weight: tempArray.weight,
        cals_weight: tempArray.cals_weight,
        food_sub_type: tempArray.food_sub_type,
        description: tempArray.description,
        energy: tempArray.energy,
        proteins: tempArray.proteins,
        carbohydrates: tempArray.carbohydrates,
        fat: tempArray.fat,
        calcium: tempArray.calcium,
        vitaminC: tempArray.vitaminC,
        vitaminA: tempArray.vitaminA,
        vitaminB12: tempArray.vitaminB12,
        h2o: tempArray.h2o,
        main_course: tempArray.mainDish,
        is_doctor: "1",
        day: tempArray.day,
        date: Date_Formate(date)
      }

      for (var l = 0; l < this.trackerArray.length; l++) {
        if (Date_Formate(date) == this.trackerArray[l].created_date) {
          if (action == "add") {
            array.checked = true;
            this.trackerArray[l].cal_intake = (parseFloat(this.trackerArray[l].cal_required) + parseFloat(array.foodItems[j].energy)).toFixed(2);
          } else {
            array.checked = false;
            this.trackerArray[l].cal_intake = (parseFloat(this.trackerArray[l].cal_required) - parseFloat(array.foodItems[j].energy)).toFixed(2);
          }
          if (array.type == "Morning") {
            if (action == "add") {
              this.trackerArray[l].dplan_morning.push(sendArray);
            } else {
              for (var i = 0; i < this.trackerArray[l].dplan_morning.length; i++) {
                if (this.trackerArray[l].dplan_morning[i].food_item_id == tempArray.food_item_id && this.trackerArray[l].dplan_morning[i].date == Date_Formate(date)) {
                  this.trackerArray[l].dplan_morning.splice(i, 1);
                }
              }
            }
          } else if (array.type == "Breakfast") {
            if (action == "add") {
              var mainDish = array.mainDish != true ? "0" : "1";
              this.trackerArray[l].dplan_breakfast.push(sendArray);
            } else {
              for (var i = 0; i < this.trackerArray[l].dplan_breakfast.length; i++) {
                if (this.trackerArray[l].dplan_breakfast[i].food_item_id == tempArray.food_item_id && this.trackerArray[l].dplan_breakfast[i].date == Date_Formate(date)) {
                  this.trackerArray[l].dplan_breakfast.splice(i, 1);
                }
              }

            }
          } else if (array.type == "Mid-day") {
            if (action == "add") {
              var mainDish = array.mainDish != true ? "0" : "1";
              this.trackerArray[l].dplan_midday.push(sendArray);
            } else {
              for (var i = 0; i < this.trackerArray[l].dplan_midday.length; i++) {
                if (this.trackerArray[l].dplan_midday[i].food_item_id == tempArray.food_item_id && this.trackerArray[l].dplan_midday[i].date == Date_Formate(date)) {
                  this.trackerArray[l].dplan_midday.splice(i, 1);
                }
              }

            }
          } else if (array.type == "Lunch") {
            if (action == "add") {
              var mainDish = array.mainDish != true ? "0" : "1";
              this.trackerArray[l].dplan_lunch.push(sendArray);
            } else {
              for (var i = 0; i < this.trackerArray[l].dplan_lunch.length; i++) {
                if (this.trackerArray[l].dplan_lunch[i].food_item_id == tempArray.food_item_id && this.trackerArray[l].dplan_lunch[i].date == Date_Formate(date)) {
                  this.trackerArray[l].dplan_lunch.splice(i, 1);
                }
              }

            }
          } else if (array.type == "Evening") {
            if (action == "add") {
              var mainDish = array.mainDish != true ? "0" : "1";
              this.trackerArray[l].dplan_evening.push(sendArray);
            } else {
              for (var i = 0; i < this.trackerArray[l].dplan_evening.length; i++) {
                if (this.trackerArray[l].dplan_evening[i].food_item_id == tempArray.food_item_id && this.trackerArray[l].dplan_evening[i].date == Date_Formate(date)) {
                  this.trackerArray[l].dplan_evening.splice(i, 1);
                }
              }

            }
          } else if (array.type == "Dinner") {
            if (action == "add") {
              var mainDish = array.mainDish != true ? "0" : "1";
              this.trackerArray[l].dplan_dinner.push(sendArray);
            } else {
              for (var i = 0; i < this.trackerArray[l].dplan_dinner.length; i++) {
                if (this.trackerArray[l].dplan_dinner[i].food_item_id == tempArray.food_item_id && this.trackerArray[l].dplan_dinner[i].date == Date_Formate(date)) {
                  this.trackerArray[l].dplan_dinner.splice(i, 1);
                }
              }

            }
          } else if (array.type == "Night") {
            if (action == "add") {
              var mainDish = array.mainDish != true ? "0" : "1";
              this.trackerArray[l].dplan_night.push(sendArray);
            } else {
              for (var i = 0; i < this.trackerArray[l].dplan_night.length; i++) {
                if (this.trackerArray[l].dplan_night[i].food_item_id == tempArray.food_item_id && this.trackerArray[l].dplan_night[i].date == Date_Formate(date)) {
                  this.trackerArray[l].dplan_night.splice(i, 1);
                }
              }
            }
          }
        }
      }
    }
    console.log("this.trackerArray" + JSON.stringify(this.trackerArray));
  }
  async foodType(itemname, session, sessionArray) {
    if (itemname != undefined && itemname != "" && itemname.length > 2) {

      var response = await this.serviceAPI.PostData('lifestyle/gdbn', {
        item_name: itemname
      }).toPromise();
      if (response) {
        var obj = JSON.parse(JSON.stringify(response));
        console.log("GDBNNNNN value-----" + JSON.stringify(obj));
        var food_items = obj.food_items;
        if (food_items.length != 0) {
          var tempFoodItems = [];
          this.displayArray;
          this.morningFoodItem = [];
          this.breadfastFoodItem = [];
          this.midDayFoodItem = [];
          this.lunchFoodItem = [];
          this.eveningFoodItem = [];
          this.dinnerFoodItem = [];
          this.nightFoodItem = [];
          for (var i = 0; i < food_items.length; i++) {
            if(food_items[i].weight != undefined){
              var tempWeight = food_items[i].weight.split(" ");
              var calsWight = tempWeight[0];
              var wightMesure = tempWeight[1] !=  undefined ?  tempWeight[1] : "--" ;
            }
            // var tempWeight = food_items[i].weight.split(" ");
            // var calsWight = tempWeight[0];
            var foodType;
            foodType = "patient";
            // var tempWeight = food_items[i].weight.split(" ");
            // var calsWight = tempWeight[0];
            // var wightMesure = tempWeight[1];
            tempFoodItems.push({
              food_item_id: food_items[i].food_item_id,
              description: food_items[i].description,
              food_type_id: food_items[i].food_type_id,
              food_sub_type: food_items[i].food_sub_type,
              quantity: food_items[i].quantity,
              measure: food_items[i].measure,
              weight: food_items[i].weight,
              cals_weight: calsWight,
              wightMesure: wightMesure,
              energy: food_items[i].energy,
              proteins: food_items[i].proteins,
              carbohydrates: food_items[i].carbohydrates,
              fat: food_items[i].fat,
              calcium: food_items[i].calcium,
              iron: food_items[i].iron,
              vitaminC: food_items[i].vitaminC,
              vitaminA: food_items[i].vitaminA,
              vitaminB12: food_items[i].vitaminB12,
              fiber: food_items[i].fiber,
              folic: food_items[i].folic,
              h2o: food_items[i].h2o,
              image: food_items[i].image,
              session: session,
              displayContent: food_items[i].description + " (" + food_items[i].quantity + " " + food_items[i].measure + " - " + food_items[i].weight + " )",
              type: foodType,
              
            });

            switch (session) {
              case 'Morning':
                this.morningFoodItem = tempFoodItems;
                break;
              case 'Breakfast':
                this.breadfastFoodItem = tempFoodItems;
                break;
              case 'Mid-day':
                this.midDayFoodItem = tempFoodItems;
                break;
              case 'Lunch':
                this.lunchFoodItem = tempFoodItems;
                break;
              case 'Evening':
                this.eveningFoodItem = tempFoodItems;
                break;
              case 'Dinner':
                this.dinnerFoodItem = tempFoodItems;
                break;
              case 'Night':
                this.nightFoodItem = tempFoodItems;
                break;
            }
          }
        }
      }
    }
  }
  selectFood(foodList, action, date, session) {
    if (action == "add") {
      foodList.checked = true;
    } else {
      foodList.checked = false;
      for (var i = 0; i < this.displayArray.length; i++) {
        if (this.displayArray[i].date == date) {
          for (var j = 0; j < this.displayArray[i].session.length; j++) {
            if (this.displayArray[i].session[j].type == session) {
              for (var k = 0; k < this.displayArray[i].session[j].foodItems.length; k++) {
                if (this.displayArray[i].session[j].foodItems[k].food_item_id == foodList.food_item_id) {
                  this.displayArray[i].session[j].foodItems.splice(k, 1);
                  break;
                }
              }
              break;
            }
          }
          break;
        }
      }
    }
    for (var i = 0; i < this.trackerArray.length; i++) {
      if (this.trackerArray[i].created_date == Date_Formate(date)) {
        switch (session) {
          case 'Morning':
            if (action == "add") {
              this.trackerArray[i].dplan_morning.push(foodList);
            } else {
              for (var j = 0; j < this.trackerArray[i].dplan_morning[j].length; j++) {
                if (this.trackerArray[i].dplan_morning[j].food_item_id == foodList.food_item_id) {
                  this.trackerArray[i].dplan_morning.splice(j, 1);
                  break;
                }
              }
            }
            break;
          case 'Breakfast':
            if (action == "add") {
              this.trackerArray[i].dplan_breakfast.push(foodList);
            } else {
              for (var j = 0; j < this.trackerArray[i].dplan_breakfast[j].length; j++) {
                if (this.trackerArray[i].dplan_breakfast[j].food_item_id == foodList.food_item_id) {
                  this.trackerArray[i].dplan_breakfast.splice(j, 1);
                  break;
                }
              }
            }
            break;
          case 'Mid-day':
            if (action == "add") {
              this.trackerArray[i].dplan_midday.push(foodList);
            } else {
              for (var j = 0; j < this.trackerArray[i].dplan_midday[j].length; j++) {
                if (this.trackerArray[i].dplan_midday[j].food_item_id == foodList.food_item_id) {
                  this.trackerArray[i].dplan_midday.splice(j, 1);
                  break;
                }
              }
            }
            break;
          case 'Lunch':
            if (action == "add") {
              this.trackerArray[i].dplan_lunch.push(foodList);
            } else {
              for (var j = 0; j < this.trackerArray[i].dplan_lunch[j].length; j++) {
                if (this.trackerArray[i].dplan_lunch[j].food_item_id == foodList.food_item_id) {
                  this.trackerArray[i].dplan_lunch.splice(j, 1);
                  break;
                }
              }
            }
            break;
          case 'Evening':
            if (action == "add") {
              this.trackerArray[i].dplan_evening.push(foodList);
            } else {
              for (var j = 0; j < this.trackerArray[i].dplan_evening[j].length; j++) {
                if (this.trackerArray[i].dplan_evening[j].food_item_id == foodList.food_item_id) {
                  this.trackerArray[i].dplan_evening.splice(j, 1);
                  break;
                }
              }
            }
            break;
          case 'Dinner':
            if (action == "add") {
              this.trackerArray[i].dplan_dinner.push(foodList);
            } else {
              for (var j = 0; j < this.trackerArray[i].dplan_dinner[j].length; j++) {
                if (this.trackerArray[i].dplan_dinner[j].food_item_id == foodList.food_item_id) {
                  this.trackerArray[i].dplan_dinner.splice(j, 1);
                  break;
                }
              }
            }
            break;
          case 'Night':
            if (action == "add") {
              this.trackerArray[i].dplan_night.push(foodList);
            } else {
              for (var j = 0; j < this.trackerArray[i].dplan_night[j].length; j++) {
                if (this.trackerArray[i].dplan_night[j].food_item_id == foodList.food_item_id) {
                  this.trackerArray[i].dplan_night.splice(j, 1);
                  break;
                }
              }
            }
            break;
        }
        break;
      }
    }
  }
  isInArray(value, array) {
    for (var i = 0; i < array.length; i++) {
      if (value == array[i]) {
        return true;
      }
    }
    return false;
  }
  async save() {
    var send_data;
    send_data = {
      tracker: this.trackerArray,
      client_reg_id: this.getClientDetails.client_reg_id,
      relation_id: this.getClientDetails.client_reg_id,
      life_style_id: this.getClientDetails.life_style_id,
      // cal_required: this.calories,
      // cal_intake: this.totalCalories, 
      country: ipaddress.country_code,
    }
    if (this.getClientDetails.sunRelID != null && this.getClientDetails.sunRelID != undefined && this.getClientDetails.sunRelID != "") {
      send_data.sub_rel_id = this.getClientDetails.sunRelID;
    }
    var response = await this.serviceAPI.PostData('lifestyle/dplantrack', send_data).toPromise();
    if (response) {
    console.log("response" + JSON.stringify(response));
    var obj = JSON.parse(JSON.stringify(response));
      if( obj.key == "1" ){
        this.toastr.success("Diet save successfully");
        this.messageservice.sendMessage("diet_tracker");
      }
    }
  }

}
