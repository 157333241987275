<div class="row" style="margin: 0 auto; width: 100%;">
  <div class="col-12" *ngIf="Diag_Location_Data.length > 1">
    <div class="row">
      <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4" style="margin-top: 14px;margin-left: 9px;">
        <mat-label class="matlabel">Diagnosis center<br>
          <select disableOptionCentering class="ipcss " [(ngModel)]="Location_txt"
            (change)="changeDigCenter(Location_txt)">
            <option *ngFor="let loc of Diag_Location_Data" value={{loc.view_data}}>{{loc.view_data}}
            </option>
          </select>
        </mat-label>
      </div>
    </div>
  </div>
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Tests</h5>
          </div>
          <div class="headerButtons">
            <img *ngIf="newbutton" class="saveimgbtn_inpatinfo" src="../../../assets/ui_icons/buttons/new_button.svg"
              (click)="testCreate()" />
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row">
          <div class="col-3" style="margin-top: 20px;">
            <div class="switch-field" *ngIf="ynopt1 != undefined">
              <input type="radio" id="radio-three" (click)="change_type(1,'test')" />
              <label for="radio-three" [ngClass]="ynopt1 ? 'radio_active':'radio_inactive'">
                <div class="tab_cover">
                  <div class="tab_icon_cover"><img src="../../../assets/ui_icons/Outpatient.svg" class="iocinStyle"
                      alt=""></div>
                  <div class="lableStyle">Tests</div>
                </div>
              </label>
              <input type="radio" id="radio-four" (click)="change_type(1,'pack')" />
              <label for="radio-four" [ngClass]="!ynopt1 ? 'radio_active':'radio_inactive'">
                <div class="tab_cover">
                  <div class="tab_icon_cover"><img src="../../../assets/ui_icons/Inpatient.svg" class="iocinStyle"
                      alt=""></div>
                  <div class="lableStyle">Profiles</div>
                </div>
              </label>
            </div>
          </div>
          <div class="col-2" style="margin-left: -20px;" [hidden]="test_flag">
            <div>
              <mat-label class="matlabel">Department<br>
                <select disableOptionCentering class="ipcss " [(ngModel)]="diag_dept"
                  (change)="getTestCategory(diag_dept, '0')">
                  <!-- <option value='All'> All</option> -->
                  <option *ngFor="let dept of department_data" required value={{dept.diag_department_id}}>
                    {{dept.diag_dept_desc}}</option>
                </select>
              </mat-label>
            </div>
          </div>
          <div class="col-2" [hidden]="test_flag">
            <div>
              <mat-label class="matlabel">Sub department<br>
                <select disableOptionCentering class="ipcss " [(ngModel)]="diag_test_categ" (change)="getTestList()">
                  <option value='All'> All</option>
                  <option *ngFor="let testcat of test_category_data" required value={{testcat.diag_test_cat_id}}>
                    {{testcat.description}}</option>
                </select>
              </mat-label>
            </div>
          </div>
        </div>
        <br />
        <div class="row" [hidden]="test_flag">
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <p class="nodata" *ngIf="Diag_Test_list_Data.length == 0">No Test(s) Found</p>

            <table *ngIf="Diag_Test_list_Data.length" mdbTable datatable [dtOptions]="dtOptions"
              class="table table-nowrap table-sm dataTable" id="diaTestTable">
              <thead>
                <tr>
                  <th style="width: 85px;">Test order</th>
                  <th style="width: 320px;">Test name</th>
                  <!-- <th>Department</th> -->
                  <th>Test category</th>
                  <th>Rate</th>
                  <th style="width: 200px;">Conducting format</th>
                  <th style="width: 90px;">TAT</th>
                  <th>Action</th>
                  <!-- <th *ngIf="deletebutton">Action</th> -->
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let person of Diag_Test_list_Data; let i = index" draggable="true"
                  (dragstart)="onDragStart($event, person)" (dragover)="onDragOver($event)" (drop)="onDrop($event, i)">
                  <td style="font-size: 12px;" (click)="testView(person.diag_tests_id, person.status, person.test_order)">{{person.test_order}}</td>
                  <td style="font-size: 12px;" (click)="testView(person.diag_tests_id, person.status, person.test_order)">{{person.test_name}}</td>
                  <!-- <td style="font-size: 12px;" (click)="testView(person.diag_tests_id, person.status, person.test_order)">{{person.diag_dept_desc}}</td> -->
                  <td style="font-size: 12px;" (click)="testView(person.diag_tests_id, person.status, person.test_order)">{{person.test_category}}</td>
                  <td style="font-size: 12px;" (click)="testView(person.diag_tests_id, person.status, person.test_order)">{{person.price}}</td>
                  <td style="font-size: 12px;" (click)="testView(person.diag_tests_id, person.status, person.test_order)">{{person.conduction_desc}}</td>
                  <td style="font-size: 12px;" (click)="testView(person.diag_tests_id, person.status, person.test_order)">{{person.totalTAT}}</td>
                  <td style="text-align: center;">
                    <div class="toggler">
                      <input id={{i}} name="toggler-1" type="checkbox" value="1"
                        [(ngModel)]="person.status" (change)="testStatus(person.status, person.diag_tests_id)">
                      <label for={{i}}>
                        <svg class="toggler-on" version="1.1" xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 130.2 130.2">
                          <polyline class="path check" points="100.2,40.2 51.5,88.8 29.8,67.5"></polyline>
                        </svg>
                        <svg class="toggler-off" version="1.1" xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 130.2 130.2">
                          <line class="path line" x1="34.4" y1="34.4" x2="95.8" y2="95.8"></line>
                          <line class="path line" x1="95.8" y1="34.4" x2="34.4" y2="95.8"></line>
                        </svg>
                      </label>
                    </div>
                  </td>
                  <!-- <td style="font-size: 12px;" (click)="testView(person.diag_tests_id)">{{person.test_type_name}}</td> -->
                  <!-- <td style="font-size: 12px;text-align: center;" *ngIf="deletebutton"><img class="deleteimg"
                      (click)="testDelete(person.diag_tests_id)"
                      src="../../../assets/ui_icons/buttons/trash_icon.svg" /></td> -->
                </tr>
              </tbody>
            </table>
          </div>
        </div>


        <div class="row my-3" [hidden]="!test_flag">
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <p class="nodata" *ngIf="packageData.length == 0">No Package(s) Found</p>
            <mat-accordion *ngIf="packageData.length" displayMode="flat" multi="false" class="mat-table">
              <section matSort class="mat-elevation-z2 mat-header-row" style="background-color: #c1e6fb;">
                <span class="mat-header-cell">Profile name</span>
                <span class="mat-header-cell">Description</span>
                <span class="mat-header-cell">Price</span>
                <span class="mat-header-cell">Action</span>
              </section>

              <mat-expansion-panel *ngFor="let package of packageData; let i = index">
                <mat-expansion-panel-header class="mat-row align-items-center">
                  <!-- (click)="getSampleDetails(list)" -->
                  <span class="mat-cell">{{package.profile_name}}</span>
                  <span class="mat-cell">{{package.package_desc}}</span>
                  <span class="mat-cell">{{package.price}}</span>
                  <span class="mat-cell">
                    <img class="deleteimg" (click)="packageEdit(package)"
                      src="../../../assets/ui_icons/buttons/pencil_icon.svg" /> &nbsp;
                    <img class="deleteimg" (click)="packageDelete(package.diag_profile_id)"
                      src="../../../assets/ui_icons/buttons/trash_icon.svg" />
                  </span>
                </mat-expansion-panel-header>
                <div>
                  <div class="container-fluid my-3">
                    <h2 class="tervys_heading2">Selected Tests</h2>
                    <div class="row">
                      <div *ngFor="let test of package.tests"
                        class="col-3 card-container d-flex flex-row align-items-center justify-content-between">
                        <p style="margin: 0px !important;">{{test.test_name}}</p>
                        <!-- <img (click)="removeTest(price.test_id,price.type_of_test)"
                             src="../../../assets/ui_icons/buttons/trash_icon.svg" width="15px" height="15px" /> -->
                      </div>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>