<div class="row" style="width:100%;margin:0 auto;">
   <div class="col-12">
      <mdb-card>
         <mdb-card-header class="bg-white">
            <div class="headerCover">
               <div class="headerTilte app_list_header">
                  <h5 class="m-0" class="mainHeadingStyle">Bill Estimate</h5>
               </div>
                   
               <div class="headerButtons">
                  <img [hidden]="newEstimateFlag" src="../../../assets/ui_icons/buttons/Back_button.svg"
                     class="saveimgbtn_inpatinfo" (click)="backButton()" />

                  <!-- <img  [hidden]="newEstimateFlag" printSectionId="print" (click)="printArea()"
                     src="../../../assets/img/printer.svg" class="saveimgbtn_inpatinfo"
                     style="margin-left:5px;height: 25px;width: 25px;"> -->

                     <img *ngIf="cancel_flag" src="../../../assets/ui_icons/buttons/cancel_button.svg" data-bs-toggle="modal" data-bs-target="#profilePicModal"
                     class="saveimgbtn_inpatinfo" >

                     <img [hidden]="updateBtnFlag" (click)="printcover()" src="../../../assets/img/Print_cover.svg" class="saveimgbtn_inpatinfo">

                  <img *ngIf="editbutton && status_buttonflag" [hidden]="updateBtnFlag" src="../../../assets/ui_icons/buttons/update_button.svg"
                     class="saveimgbtn_inpatinfo" (click)="updateEstimate('noprint')" />

                  <img *ngIf="newbutton" [hidden]="!newEstimateFlag" class="saveimgbtn_inpatinfo"
                     src="../../../assets/ui_icons/buttons/new_button.svg" (click)="createOrder()" />

                     <img *ngIf="tokenflag" src="../../../assets/ui_icons/buttons/generate_bill.svg"
                     (click)="saveOrder('print')" class="saveimgbtn_inpatinfo">

                      <img [hidden]="saveBtnFlag" *ngIf="status_buttonflag"  src="../../../assets/ui_icons/buttons/save_button.svg"
                      class="saveimgbtn_inpatinfo" (click)="saveEstimate('noprint')" />

                      <span *ngIf="thermalspan && thermalspantoken">
                        <!-- <button (click)="printdevice()">Request device</button> -->
                        <img class="saveimgbtn_inpatinfo" src="../../../assets/ui_icons/buttons/thermal_request.svg" (click)="printdevice()">
                        <!-- <img class="saveimgbtn_inpatinfo" id="device" src="../../../assets/ui_icons/buttons/thermal_print.svg"> -->
                        <!-- <button id="device"></button> -->
                       </span>
               
               
               
                  </div>
            </div>
         </mdb-card-header>
         <mdb-card-body>
            <div [hidden]="newEstimateFlag">
               
               <div class="row">
                  <div class="modal fade" id="profilePicModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                  aria-hidden="true">
    
                  <div class="modal-dialog modal-md">
                    <div class="modal-content" style="width:100%">
                      <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Cancel reason</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                        </button>
                      </div>
                      <div class="modal-body">
                        <div class="col-12">
                          <div class="snapshot">
                            <div class="scrollbar">
                              <textarea type="text"  cols="5" rows="5" class="textarea_reason" [(ngModel)]="cancelreasonest"
                               #matInput 
                               ></textarea>
                               <img src="../../../assets/img/mobile_buttons/confirm_.svg" data-bs-toggle="modal" data-bs-target="#profilePicModal"
                               class="saveimgbtn_inpatinfo"  (click)="cancel()">
                            </div>
                          </div>
    
                        </div>
                      </div>
                    </div>
                  </div>
                </div> 


                <div class="col-12 col-sm-6 col-md-12 col-lg-12 col-xl-12" [hidden]="patient_detailsflag" >
                  <div class="cover_div">
                    <div class="header_lable">
                       Patient Details
                    </div>
                    <div class="content_cover">
                      <div class="row">
                      <div class="col-3">
                        <p class="textformat"> <span style="font-weight: 500;">MR No: </span>{{uhid}} </p>
                       
                      </div>                 
                      <!-- {{this.clntDOB}}   {{this.clntEmergNumber}} {{this.clntEmergNumber1}} {{this.aadharNumber}} {{this.whatsappNumber}} -->
                      <div class="col-3">
                        <p class="textformat"> <span style="font-weight: 500;">Patient name: </span>{{clntSal}}. {{clientname}} </p>
                      </div>
                      <div class="col-3">
                        <p class="textformat"> <span style="font-weight: 500;">Age/Gender: </span> {{clntAge}} / {{headergen}}</p>
                      </div>
                      <div class="col-3">
                        <p class="textformat"> <span style="font-weight: 500;">DOB: </span>{{clntdob | date : "dd-MM-yyyy"}}</p>
                      </div>
                      <div class="col-3">
                        <p class="textformat"> <span style="font-weight: 500;">IP/OP: </span> {{ipop}} </p>
                      </div>
                      <div class="col-3">
                        <p class="textformat"> <span style="font-weight: 500;">Mobile: </span> {{this.clntContNumber}}</p>
                      </div>
                      <div class="col-3">
                        <p class="textformat"> <span style="font-weight: 500;">Doctor: </span><span [hidden]="docsalflag">{{docsal}}</span> {{this.doctorName}}</p>
                      </div>
                      <div class="col-3" *ngIf="fromprescflag">
                        <p class="textformat"> <span style="font-weight: 500;">Ward name: </span> {{Ward_name}}</p>
                      </div>
                      <div class="col-3" *ngIf="fromprescflag">
                        <p class="textformat"> <span style="font-weight: 500;">Bed no: </span> {{Bed_no}}</p>
                      </div>
                      <div class="col-3" *ngIf="fromprescflag">
                        <p class="textformat"> <span style="font-weight: 500;">Admission date & time: </span> <span style="font-size:12px;">{{admissiondate | date : "dd-MM-yyyy"}} & {{admission_time}}</span></p>
                      </div>
                      <div class="col-3" *ngIf='this.auto_order == "0"' >
                    <p class="textformat"> <span style="font-weight: 500;">Token no: </span> {{tokenno}}
                    </p>
                  </div>
                  <div class="col-3 inpatinfopadding"><span style="font-weight: 500;">Address: </span> {{clntAddress1}} {{clntAddress2}} {{clntLocation}} {{clntCity}} {{clntZipcode}} {{clntState}} {{country}}</div>
                  <div class="col-12 col-sm-6 col-md-2 col-xl-2 col-lg-2" [hidden]="languageflag">
                  
                        <p class="textformat">  <span style="font-weight: 500;">Language: </span>
                        <mat-select disableOptionCentering class="ipcss" [(ngModel)]="language"
                           (selectionChange)="languageChange()">
                           <mat-option value="English">English</mat-option>
                           <mat-option value="Tamil">Tamil</mat-option>
                           <mat-option value="Telugu">Telugu</mat-option>
                           <mat-option value="Kannada">Kannada</mat-option>
                           <mat-option value="Malayalam">Malayalam</mat-option>
                           <mat-option value="Oriya">Oriya</mat-option>
                           <mat-option value="Bengali">Bengali</mat-option>
                           <mat-option value="Hindi">Hindi</mat-option>
                        </mat-select>
                     </p>
                  </div>
                  <!-- <div class="col-12 col-sm-6 col-md-4 col-xl-6 col-lg-6" [hidden]="updateBtnFlag">
                     <div class="row">
                        <div class="col-2">
                           <span style="font-weight: 500;">
                              Rate plan:
                           </span>
                       </div>
                       <div class="col-6">
                       <select style="width:125px" disableOptionCentering class="ipcss " required [(ngModel)]="discountType"
                           (change)="discountChange(discountType)">
                           <option disabled>Select</option>
                           <option *ngFor="let disc of discountDatasArray" value="{{disc.disc_type_id}}">
                              {{disc.description}}</option>
                        </select>
                        </div>
                    </div>
                  </div> -->

                    </div>
                    </div>
                </div>
                </div>
                
                

                <div class="col-12 col-sm-6 col-md-4 col-xl-2 col-lg-2" [hidden]="!updateBtnFlag">
                  <mat-label class="matlabel">Search type<br>
                    <select disableOptionCentering class="ipcss" [(ngModel)]="search_type" (change)="searchtype(search_type)">
                      <option disabled>Select</option>
                      <option value="name">Name</option>
                      <option value="mrnumber">MR No</option>
                    </select>
                  </mat-label>
                </div>
                  <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" *ngIf="retrivemr">
                     <mat-label class="matlabel"> MR No <br>
                        <input type="text" class="ipcss order_width getMedcien" required maxlength="50"
                           (keyup)="patient_name.length >= 3 && handleKeyUp($event)" maxlength="50" [(ngModel)]="patient_name" matInput
                           [matAutocomplete]="mrnumauto3" />
                     </mat-label>
                     <mat-autocomplete #mrnumauto3="matAutocomplete" [panelWidth]="'auto'">
                        <mat-option (click)="retrieveClient(patient)" *ngFor="let patient of patientArray"
                           value="{{patient.patient_name}}">
                           <div>{{patient.patient_name}}   <span>(</span>{{patient.gender}} - {{patient.age}}<span>)</span></div>
                        </mat-option>
                     </mat-autocomplete>
                  </div>

                  <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" *ngIf="retrivenameflag">
                     <mat-label class="matlabel"> Name <br>
                        <input type="text" class="ipcss order_width getMedcien" required maxlength="50"
                           (keyup)="patient_name.length >= 3 && handleKeyUp($event)" maxlength="50" [(ngModel)]="patient_name" matInput
                           [matAutocomplete]="auto3" />
                     </mat-label>
                     <mat-autocomplete #auto3="matAutocomplete" [panelWidth]="'auto'">
                        <mat-option (click)="retrieveClient(patient)" *ngFor="let patient of patientArray"
                           value="{{patient.patient_name}}">
                           <div>{{patient.patient_name}}   <span>(</span>{{patient.gender}} - {{patient.age}}<span>)</span></div>
                        </mat-option>
                     </mat-autocomplete>
                  </div>
                  <!-- getReferralData($event) -->
                  <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2" [hidden]="!updateBtnFlag">
                     <mat-label class="matlabel">Doctor<br>
                        <input type="text" class="ipcss" maxlength="50" (keyup)="doctorName.length >=3 && handleKeydoctorUp($event)" maxlength="50"
                           [(ngModel)]="doctorName" matInput [matAutocomplete]="auto1" />
                        <mat-autocomplete #auto1="matAutocomplete" [panelWidth]="'auto'">
                           <mat-option (click)="Select_refer_doctor(item)" *ngFor="let item of ReferedDoctorArray"
                              value="{{item}}">
                              {{item}}
                           </mat-option>
                        </mat-autocomplete>
                     </mat-label>
                  </div>
                  
                  <div class="col-12 col-sm-6 col-md-4 col-lg-1 col-xl-1" style="margin-top: auto;margin-bottom:auto;width: 50px;" [hidden]="!updateBtnFlag">
                     <img src="../../../assets/img/Page_icon/Info_icon.png" style="width:25px;position: relative;
                     top: 10px;" data-bs-toggle="modal" data-bs-target="#patientdetails" (click)="patientdetail()">
                     </div>
                     <div class="modal fade" id="patientdetails" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                     aria-hidden="true">
                     <div class="modal-dialog modal-md">
                       <div class="modal-content" style="width:165%;position: relative;
                       right: 100px">
                         <div class="modal-header">
                           <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                           </button>
                         </div>
                         <div class="modal-body">
                           <div class="col-12">
                             <div class="patientdetails-responsive">
                              <table class="patientdetailstable">
                                 <thead>
                                   <tr style="background-color:#c1e6fb;color: rgba(0,0,0,.54);min-height: 56px;">
                                     <th style="width:15%">Mr number</th>
                                     <th style="width:25%">Patient name</th>
                                     <th style="width:10%">IP/OP</th>
                                     <th style="width:15%">Mobile no</th>
                                     <th style="width:17%">Age</th>
                                     <th style="width:25%">Doctor</th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                   <tr *ngFor="let details of patientdetailsarray" (click)="patientdetailslist(details)"  data-bs-dismiss="modal" aria-label="Close">
                                     <td>{{details.mrno}}</td>
                                     <td>{{details.name}}</td>
                                     <td>{{details.op_ip}}</td>
                                     <td>{{details.mobile}}</td>
                                     <td>{{details.age}}</td>
                                     <td>{{details.dr_name}}</td>
                                   </tr>                          
                                 </tbody>
                               </table>
                             </div>
                           </div>
                         </div>
                       </div>
                     </div>
                   </div>

                  <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2" [hidden]="!updateBtnFlag">
                     <mat-label class="matlabel">Date<br>
                        <input type="date" class="ipcss" max="{{todayDate}}" (change)="changeOrderDate(orderDate)"
                           [(ngModel)]="orderDate" #matInput>
                     </mat-label>
                  </div>


                  <!-- <div class="col-2">
                     <span> <span style="font-weight: 500;"> </span> </span>
                     </div>
                     <div class="col-6">
                     
                      </div> -->




                      <div class="col-12 col-sm-6 col-md-4 col-xl-2 col-lg-2">
                        <mat-label>Rate plan:<br>
                        <select style="width:125px" disableOptionCentering class="ipcss " required [(ngModel)]="discountType"
                        (change)="discountChange(discountType)">
                            <option disabled>Select</option>
                            <option *ngFor="let disc of discountDatasArray" value="{{disc.disc_type_id}}">
                               {{disc.description}} ({{disc.discount_type}})</option>
                         </select>
                        </mat-label>
                      </div>




                  <!-- <div class="col-12 col-sm-6 col-md-4 col-xl-3 col-lg-2" [hidden]="!updateBtnFlag">
                     <mat-label class="matlabel">Rate plan<br>
                        <select disableOptionCentering class="ipcss " required [(ngModel)]="discountType"
                           (change)="discountChange(discountType)">
                           <option disabled>Select</option>
                           <option *ngFor="let disc of discountDatasArray" value="{{disc.disc_type_id}}">
                              {{disc.description}}</option>
                        </select>
                     </mat-label>
                  </div> -->
                  <div class="col-12 col-sm-6 col-md-4 col-xl-3 col-lg-3" [hidden]="!updateBtnFlag">
                     <label class="matlabel" for="customFile">Prescription file choosen</label>
                     <input type="file" class="ipcss form-control" id="customFile" />
                  </div>

              


               </div>
               <div class="row" style="margin-top:5px;padding: 0 10px 0 10px;">
                  <div id="pharma_tbl_overlay" class="dig_table_overflow table-responsive" style="height: 210px;">
                     <table id="tbl_med" class="table table-hover table-dynamic" style="font-size: 11px;">
                        <thead>
                           <tr>
                              <th style="width: 45px;z-index: 100;">Si.No</th>
                              <th style="width: 250px;" *ngIf="genericDisplayFlag">Generic name</th>
                              <th style="width: 250px;">Medicine name</th>
                              <th style="width: 150px;">Batch no</th>
                              
                              <th style="width: 70px;">Rack</th>
                              <th style="width: 70px;">Bin</th>
                              <th style="width: 90px;">Price</th>
                              <!-- <th style="width: 70px;">Discount</th> -->
                              <th style="width: 130px;">Duration</th>
                              <th style="width: 133px;">{{'Intake'}}</th>
                              <th style="width: 50px;">{{'Min'}}</th>
                              <th style="width: 20px;" >{{"In-take Session"}}</th>
                              <th style="width: 70px;">Quantity</th>
                              <th style="width: 150px;">Amount</th>
                              <th style="width: 20px;"></th>
                           </tr>
                        </thead>

                        <tbody>
                           <tr *ngFor="let drug of drugDetailsArray;let i = index" class="tdvalues">
                              <th>{{i + 1}} </th>
                              <!--  -->
                              <td *ngIf="genericDisplayFlag">
                                 <input type="text" class="ipcss order_width getMedcien" maxlength="50"
                                    (keyup)="changeMedicineName($event,'generic')" maxlength="50"
                                    [(ngModel)]="drug.gen_name" matInput [matAutocomplete]="auto3" placeholder="1" />
                                 <mat-autocomplete #auto3="matAutocomplete" [panelWidth]="'auto'">
                                    <mat-option (click)="selectMedicineItem(medicine,drug)"
                                       *ngFor="let medicine of MedicneData" value="{{medicine.name}}">
                                       <div [ngStyle]="{'color' : medicine.quantity == '0' ? '#FFA07A' : '#000' }">
                                          {{medicine.name}}</div>
                                    </mat-option>
                                 </mat-autocomplete>
                              </td>
                              <td>
                                 <input type="text" class="ipcss order_width getMedcien" required maxlength="50"
                                    (keyup)="drug.drug_name.length >=3 && handleKeymedicineUp($event,drug.drug_name,drug)" maxlength="50"
                                    [(ngModel)]="drug.drug_name" matInput [matAutocomplete]="auto2" />
                                 <mat-autocomplete #auto2="matAutocomplete" [panelWidth]="'auto'">
                                    <mat-option (click)="selectMedicineItem(medicine,drug)"
                                       *ngFor="let medicine of MedicneData" value="{{medicine.name}}">
                                       <div [ngStyle]="{'color' : medicine.quantity == '0' ? '#FFA07A' : '#000' }">
                                          {{medicine.name}}</div>
                                    </mat-option>
                                 </mat-autocomplete>
                              </td>

                              <td>
                                 <!-- [ngStyle]="{'color':batch_required}"
                                 [ngStyle]="drug.batch_required"
                                 -->
                                 <!--  -->
                                 <input type="text" class="ipcss order_width" [ngStyle]="{'color':batch_required}"  required
                                    [(ngModel)]="drug.batch_no" matInput maxlength="50" readonly autocomplete="on"
                                    (click)="getCORD($event,'drug_'+drug.index);drug.show_batcharray = !drug.show_batcharray"
                                    />

                                 <div [id]="'drug_'+drug.index" class="auto_complete_text1"
                                    *ngIf="drug.batcharray.length != 0"  [ngStyle]="{'display':displayStyle}">
                                    <table style="width: 100%;">
                                        <thead>
                                          <tr>
                                             <th> Batch no</th>
                                             <th> Qty </th>
                                             <th> MRP </th>
                                             <th> Exp Date </th>
                                             
                                          </tr>
                                       </thead>
                                       <tbody>
                                          <tr *ngFor="let batch of drug.batcharray"
                                             (click)="selectedBatch(batch,drug,'drug_'+drug.index)">
                                             <td [ngStyle]="{'color':batch.color}">{{batch.batch_no}}
                                             </td>
                                             <td [ngStyle]="{'color':batch.color}">{{batch.quantity}}
                                             </td>
                                             <td [ngStyle]="{'color':batch.color}">{{batch.mrp}}</td>
                                             <td [ngStyle]="{'color':batch.color}">{{batch.expiry_date}}
                                             </td>
                                          </tr>
                                       </tbody>
                                    </table>
                                 </div>

                                 <!-- <select [(ngModel)]="drug.batch_no" class="ipcss">
                                    
                                    <option *ngFor="let batch of drug.batcharray">
                                       
                                       
                                       
                                       {{batch.batch_no}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{batch.quantity}}&nbsp;&nbsp;&nbsp;&nbsp;{{batch.mrp}}&nbsp;&nbsp;&nbsp;&nbsp;{{batch.expiry_date}}
                                    </option>
                                  </select>      -->
                              </td>
                              
                              <td>
                                 <input type="text" class="ipcss order_width"
                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="4"
                                    required [(ngModel)]="drug.rack_id" (keyup.enter)="createRow(drug)" #matInput />
                              </td>
                              <td>
                                 <input type="text" class="ipcss order_width"
                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="4"
                                    required [(ngModel)]="drug.bin_id" (keyup.enter)="createRow(drug)" #matInput />
                              </td>
                              <td>
                                 <input type="text" style="text-align: right;" class="ipcss order_width"
                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57" [disabled]="true"
                                    [(ngModel)]="drug.price" #matInput (keyup)="calculateamount(drug)"
                                     />
                              </td>
                              <!-- <td>
                                 <input type="text" class="ipcss order_width" maxlength="3" [(ngModel)]="drug.discount"
                                    (keyup)="calculateamount(drug)" #matInput [readonly]="!discoutFlag"
                                    (keyup.enter)="createRow()" />
                              </td> -->
                              <td>
                                 <!-- (keyup.enter)="createRow(drug)" -->
                                 <div style="display: flex;">
                                   <input type="text" style="width: 40px;" class="ipcss order_width" matInput maxlength="5"
                                     onkeypress="return event.charCode >= 48 && event.charCode <= 57" [readonly]="drug.show_intakeFlag" [(ngModel)]="drug.day_txt"
                                      (keyup.enter)="sessionqty(drug)" />
                                   &nbsp;
                                   <mat-select disableOptionCentering class="ipcss  order_width" (selectionChange)="Duration()"
                                     [(ngModel)]="drug.daydur_txt" style="width: 90px;">
                                     <mat-option disabled>Select</mat-option>
                                     <mat-option value="day(s)">day(s)</mat-option>
                                     <mat-option value="week(s)">week(s)</mat-option>
                                     <mat-option value="month(s)">month(s)</mat-option>
                                   </mat-select>
                                 </div>
                               </td>

                               <td>
                                 <mat-select disableOptionCentering class="ipcss order_width" required
                                 (selectionChange)="changeIntake(drug)" [(ngModel)]="drug.afterfood_txt"
                                 [disabled]="drug.intake_show">
                                 <mat-option value="Select">Select</mat-option>
                                 <mat-option value="Empty stomach">Empty stomach</mat-option>
                                 <mat-option value="After food">After food</mat-option>
                                 <mat-option value="Before food">Before food</mat-option>
                                 <mat-option value="With food">With Food</mat-option>
                               </mat-select>
                             </td>

                             <td>
                              <input type="text" class="ipcss  order_width" matInput [required]="drug.dure_require"
                                onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="3"
                                [(ngModel)]="drug.dure_txt" [disabled]="drug.dure_show" />
                            </td>

                           <td>
                              <!-- -->
                              <div style="display: flex;">
                                 <mat-select disableOptionCentering class="ipcss order_width"  [disabled]="intakesessionflag" required 
                                 [(ngModel)]="drug.intake_txt" style="width: 164px;">
                                 <mat-option disabled>Select</mat-option>
                                 <mat-option *ngFor="let intake of getIntakeArray" 
                                   (click)="sesssionBaseChange(drug)" value={{intake.description}}>
                                   {{intake.description}}</mat-option>
                               </mat-select>&nbsp;
                               <div class="divClass">
                                 <input type="text" [hidden]="drug.mor_lable" type="text" class="inputClass order_width" matInput
                                   [required]="morningRequset" [(ngModel)]="drug.morning" [disabled]="drug.morning_dis" maxlength="5"
                                   onkeypress="return event.charCode == 32 || event.charCode == 46 || event.charCode == 47 || (event.charCode >= 48 && event.charCode <= 57)"
                                   (blur)="focusFunction($event,drug);sessionqty(drug)">
         
                                 <input type="text" [hidden]="drug.quan_lable" type="text" class="inputClass order_width"
                                   matInput [required]="drug.morning_req" maxlength="5"
                                   onkeypress="return event.charCode == 32 || event.charCode == 46 || event.charCode == 47 || (event.charCode >= 48 && event.charCode <= 57)"
                                   [disabled]="drug.morning_dis" [(ngModel)]="drug.morning" (blur)="focusFunction($event,drug);sessionqty(drug)" >
         
                                 <span [hidden]="drug.after_eventxt"> - </span>
         
                                 <input type="text" class="inputClass order_width" matInput [disabled]="drug.afternoon_dis"
                                   maxlength="5" [hidden]="drug.after_eventxt"
                                   onkeypress="return event.charCode == 32 || event.charCode == 46 || event.charCode == 47 || (event.charCode >= 48 && event.charCode <= 57)"
                                   [required]="drug.afternoon_req" [(ngModel)]="drug.afternoon" (blur)="focusFunction($event,drug);sessionqty(drug)" >
         
                                 <span [hidden]="drug.eve_eventxt"> - </span>
         
                                 <input type="text" [hidden]="drug.eve_eventxt" class="inputClass order_width" matInput
                                   [disabled]="drug.even_dis" [required]="drug.evening_req" maxlength="5"
                                   
                                   onkeypress="return event.charCode == 32 || event.charCode == 46 || event.charCode == 47 || (event.charCode >= 48 && event.charCode <= 57)"
                                   [(ngModel)]="drug.evening" (blur)="focusFunction($event,drug);sessionqty(drug)" >
         
                                 <span [hidden]="drug.night_eventxt"> - </span>
         
                                 <input type="text" [hidden]="drug.night_eventxt" class="inputClass order_width" matInput
                                   [disabled]="drug.ngt_dis" [required]="drug.night_req" maxlength="5"
                                   
                                   onkeypress="return event.charCode == 32 || event.charCode == 46 || event.charCode == 47 || (event.charCode >= 48 && event.charCode <= 57)"
                                   [(ngModel)]="drug.night" (blur)="focusFunction($event,drug);sessionqty(drug)">
         
                               </div>
                              </div>  
                           </td>   

                           <td>
                             <input type="text" class="ipcss order_width"
                                 onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="4"
                                  required [(ngModel)]="drug.quantity" 
                                 (keyup.enter)="createRow(drug)" #matInput />
                           </td>

                              <td>
                                 <input type="text" style="text-align: right;" class="ipcss order_width"
                                    [(ngModel)]="drug.amount" #matInput disabled />
                              </td>
                              
                              <td style="text-align: center;" *ngIf="deletebutton">
                                 <a (click)="deleteMedicine(drug.drug_id,drug.index)">
                                    <img src="../../../assets/ui_icons/buttons/trash_icon.svg" width="20"
                                       height="20" /></a>
                              </td>
                           
                           </tr>
                        </tbody>
                     </table>
                  </div>
               </div>
               <div class="row">
                  <div class="col-0 col-sm-3 col-md-7 col-lg-7 col-xl-7">
                     <div class="cover_div" *ngIf="paytyperequestflag" [hidden]="paytyperequestflag">
                        <div class="row">


                           <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                              <mat-label class="matlabel">Payment type<br>
                                 <mat-select id="paytype" class="ipcss  widthbillcreate" [(ngModel)]="payType"
                                    (selectionChange)="changePay()" disableOptionCentering>
                                    <mat-option *ngFor="let pay of payTypeListArrray"
                                       value={{pay.pay_id}}>{{pay.pay_desc}}</mat-option>
                                 </mat-select>
                              </mat-label>
                           </div>
                           <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4" [hidden]="insurFlag">
                              <mat-label class="matlabel">Insurer<br>
                                 <mat-select required class="ipcss  widthbillcreate" [(ngModel)]="insurerID"
                                    disableOptionCentering>
                                    <mat-option *ngFor="let ins of insurerArray"
                                       value={{ins.insurer_id}}>{{ins.insurer_desc}}
                                    </mat-option>
                                 </mat-select>
                              </mat-label>
                           </div>
                           <div class="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-2" [hidden]="cardFlag">
                              <mat-label class="matlabel">Card No <br>
                                 <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardNumber" />
                              </mat-label>
                           </div>
                           <div class="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-3" [hidden]="cardFlag">
                              <mat-label class="matlabel">Holder name<br>
                                 <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardHolderName" />
                              </mat-label>
                           </div>
                           <div class="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-3" [hidden]="tranFlag">
                              <mat-label class="matlabel">Transaction ID<br>
                                 <input class="ipcss  widthbillcreate" matInput [(ngModel)]="transactionID" />
                              </mat-label>
                           </div>
                           <div class="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-2" [hidden]="chequeFlag">
                              <mat-label class="matlabel">Cheque No <br>
                                 <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardNumber" />
                              </mat-label>
                           </div>
                           <div class="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-4" [hidden]="chequeFlag">
                              <mat-label class="matlabel">Bank Name <br>
                                 <input class="ipcss  widthbillcreate" matInput [(ngModel)]="bankName" />
                              </mat-label>
                           </div>
                        </div>

                        <div class="row">
                           <table>
                              <tr *ngFor="let pay of paymentsarray">
                                 <td style="width: 150px !important;">{{pay.pay_desc}}</td>
                                 <td style="width: 150px !important;">{{pay.amount}}</td>
                                 <td>
                                    <img src="../../../assets/img/edit.png" class="billing_edit"
                                       (click)="editPayment(pay)"> &nbsp;&nbsp;
                                    <img src="../../../assets/img/Delete.png" class="billing_edit"
                                       (click)="deletePayment(pay)">
                                 </td>
                              </tr>
                           </table>
                        </div>
                     </div>

                  </div>
                  <div class="col-0 col-sm-9 col-md-5 col-lg-5 col-xl-5">
                     <div class="row" style="margin: 10px 0px;">


                        <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                           <mat-label class="matlabel">Total Bill</mat-label>
                           <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                              style="width: 10px; height: 10px;" />
                        </div>
                        <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                           <input class="ipcss billcreatech heightmsr bill_txt" matInput text-right readonly
                              [(ngModel)]="totalAmount" style="text-align: right;" />
                        </div>

                        <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6" [hidden]="discoutFlag">
                           <mat-label class="matlabel  heightmsr">Discount ({{disperce}}%)</mat-label>
                           <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                              style="width: 10px; height: 10px;" />
                        </div>
                        <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6" [hidden]="discoutFlag">
                           <input type="text" class="ipcss " style="text-align: right;" [(ngModel)]="prodDiscount"
                              (keyup)="changeTotal()" (blur)="dissBlur()">
                        </div>

                        <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6" [hidden]="GSTamountflag">
                           <mat-label class="matlabel  heightmsr">GST</mat-label>
                           <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png" style="width: 10px; height: 10px;" />
                         </div>
                         <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6" [hidden]="GSTamountflag">
                           <input type="text" class="ipcss " style="text-align: right;" [(ngModel)]="GSTamount"
                             (keyup)="changeTotal()" (blur)="dissBlur()">
                         </div>

                        <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6" *ngIf="round_off_descflag">
                           <mat-label class="matlabel heightmsr">Round off</mat-label>
                         </div>
                         <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6" *ngIf="round_off_descflag" style="display: flex;
                           align-items: center;">
                           <span class="roundoffButton"><img src="../../../assets/ui_icons/minus_icon.svg" alt="" (click)="addRound('minus')"
                               ></span>
                            
                               <span class="roundoffButton"><img
                               src="../../../assets/ui_icons/plus_icon.svg" style="margin: 0 0 0 5px;" alt="" (click)="addRound('plus')"></span>
                      
           
                           <input class="ipcss  heightmsr bill_txt billcreatech" matInput text-right [(ngModel)]="round_off"
                             maxlength="10" style="    display: inline-block !important; 
                               width: 100%;
                               margin: 0px 0px 4px 9px;" (keyup)="updatedvalue(round_off)" [disabled]="isInputDisabled" />
                         </div>

                        <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6" *ngIf="totalFlag">
                           <mat-label class="matlabel">Bill Amount </mat-label>
                           <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                              style="width: 10px; height: 10px;" />
                        </div>
                        <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6" *ngIf="totalFlag">
                           <input class="ipcss billcreatech heightmsr bill_txt" matInput text-right readonly
                              [(ngModel)]="amountDicount" style="text-align: right;"
                              (ngModelChange)="calculateRemainBalance()"/>
                        </div>


                        <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6" *ngIf="balanceFlag">
                           <mat-label class="matlabel">Paid </mat-label>
                           <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                              style="width: 10px; height: 10px;" />
                        </div>
                        <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6" *ngIf="balanceFlag">
                           <input class="ipcss billcreatech heightmsr bill_txt" matInput text-right
                              (keyup)="calculateRemainBalance()" (focus)="paidFocus()" [(ngModel)]="paidAmount" style="text-align: right;" />
                        </div>



                        <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6" *ngIf="paidFlag">
                           <mat-label class="matlabel">Balance</mat-label>
                           <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                              style="width: 10px; height: 10px;" />
                        </div>
                        <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6" *ngIf="paidFlag">
                           <input class="ipcss billcreatech heightmsr bill_txt" matInput text-right readonly
                              [(ngModel)]="remainBalance" style="text-align: right;" />
                        </div>
                        <!-- {{}} -->
                     </div>
                  </div>
               </div>

            </div>
            <!-- Bill Estimate List -->
            <div [hidden]="!newEstimateFlag">
               <div class="col-12">
                  <div class="switch-field" *ngIf="ynopt1 != undefined">
                    <input type="radio" id="radio-three" (click)="changeType(1,'yes')" />
                    <label for="radio-three" [ngClass]="ynopt1 ? 'radio_active':'radio_inactive'">
                      <div class="tab_cover">
                        <div class="tab_icon_cover"><img src="../../../assets/ui_icons/Outpatient.svg" class="iocinStyle"
                            alt=""></div>
                        <div class="lableStyle">Out-patient</div>
                      </div>
                    </label>
                    <input type="radio" id="radio-four" (click)="changeType(1,'no')" />
                    <label for="radio-four" [ngClass]="!ynopt1 ? 'radio_active':'radio_inactive'">
                      <div class="tab_cover">
                        <div class="tab_icon_cover"><img src="../../../assets/ui_icons/Inpatient.svg" class="iocinStyle"
                            alt=""></div>
                        <div class="lableStyle">In-patient</div>
                      </div>
                    </label>
                  </div>
                </div>
               <div class="row">
                  <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                     <mat-label class="matlabel" style="margin-top: 7px;">From
                        <input type="date" class="ipcss_date " [(ngModel)]="fromDate" max="{{currentDate}}" #matInput
                           style="width: 140px;">
                     </mat-label>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                     <mat-label class="matlabel" style="margin-top: 7px;">To
                        <input type="date" class="ipcss_date " [(ngModel)]="toDate" max="{{currentDate}}" #matInput
                           style="width: 140px;">
                     </mat-label>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                     <mat-label class="matlabel"><br>
                        <img src="../../../assets/ui_icons/buttons/search_button.svg" class="saveimgbtn_inpatinfo"
                           (click)="currentEstimates(pharmacyID)" />
                     </mat-label>
                  </div>


                

               </div>
               <div class="row">
                  <div class="col-12 col-lg-12 col-md-12 col-xl-12">
                     <p class="nodata" *ngIf="orderListArray.length == 0">No Estimation(s) found</p>

                     

                     
                     
                     
                     <table style="margin-top:10px" *ngIf="orderListArray.length != 0" mdbTable datatable [dtOptions]="dtOptions"
                        class="table table-nowrap table-sm dataTable pharmacycurrentorder">
                        <thead class="tableheading">
                           <tr>
                              <th>Estimate Id</th>
                              <!-- <th></th> -->
                              <th>Token</th>
                              <th>Name</th>
                              <th>Doctor name</th>
                              <th>Delivery type </th>
                              <th>Estimation date</th>
                              <th>Prescription date</th>
                              
                              <th> Status </th>
                              
                             
                             
                           </tr>
                        </thead>
                        <tbody>

                           <!-- (click)='orderView(list.drug_purchase_id, list.home_delivery, list.flag,list.fin_year)' -->
                           <tr *ngFor="let list of orderListArray" class="tdvalues">
                              <td style="font-size: 12px;" (click)='orderView(list.pharma_Estimate_Id,list.financial_Year,list.delivery_Type,list.status,list,list.uhid,list.token,list.pres_date,list.pres_time)'>{{list.pharma_Estimate_Id}}</td>
                              <!-- <td> -->
                                 <!-- <img src="{{person.profile_image}}" class="img-fluid z-depth-1 rounded-circle mr-1"> -->
                              <!-- </td> -->
                              <td style="font-size: 12px;">{{list.token}}
                                 &nbsp;&nbsp;
                                 <img style="width:45px;" src="../../../assets/img/Button_Icons/mic_on.svg" (click)="tokennospeech(list.pharma_Estimate_Id,list.financial_Year,list.delivery_Type,list.status,list,list.uhid,list.token)">
                              </td>
                              <td style="font-size: 12px;text-align: left !important;" (click)='orderView(list.pharma_Estimate_Id,list.financial_Year,list.delivery_Type,list.status,list,list.uhid,list.token,list.pres_date,list.pres_time)'>{{list.person_Name}}</td>
                              <td style="font-size:12px;text-align: left !important;" (click)='orderView(list.pharma_Estimate_Id,list.financial_Year,list.delivery_Type,list.status,list,list.uhid,list.token,list.pres_date,list.pres_time)'>{{list.doctor_name}}</td>
                              <td style="font-size: 12px;text-align: left !important;" (click)='orderView(list.pharma_Estimate_Id,list.financial_Year,list.delivery_Type,list.status,list,list.uhid,list.token,list.pres_date,list.pres_time)'> {{list.delivery_Type}}</td>
                              <td style="font-size: 12px;" (click)='orderView(list.pharma_Estimate_Id,list.financial_Year,list.delivery_Type,list.status,list,list.uhid,list.token,list.pres_date,list.pres_time)'>{{list.estimation_Date}}</td>
                              <td style="font-size: 12px;" (click)='orderView(list.pharma_Estimate_Id,list.financial_Year,list.delivery_Type,list.status,list,list.uhid,list.token,list.pres_date,list.pres_time)'>{{list.pres_date || '-'}}/{{list.pres_time||'-'}}</td>
                              <!-- <td style="font-size: 12px;" *ngIf="list.pres_date == undefined"></td> -->
                              
                              <td style="font-size: 12px;" (click)='orderView(list.pharma_Estimate_Id,list.financial_Year,list.delivery_Type,list.status,list,list.uhid,list.token,list.pres_date,list.pres_time)'>
                                 <div style="position: relative;top: 9px;">
                                    <img *ngIf="list.status == 'Completed'" width="30px" height="auto"
                                       src="../../../assets/ui_icons/Completed_icon.svg" />
                                    <img *ngIf="list.status == 'Cancelled'" width="30px" height="auto"
                                       src="../../../assets/ui_icons/cancel_icon.svg" />
                                    <img *ngIf="list.status == 'Confirmed'" width="30px" height="auto"
                                       src="../../../assets/ui_icons/confirmed_icon.svg" />
                                    <img *ngIf="list.status == 'Open'" width="30px" height="32px"
                                       src="../../../assets/ui_icons/opened_icon.svg" />
                                    <img *ngIf="list.status == 'Rejected'" width="30px" height="auto"
                                       src="../../../assets/ui_icons/confirmed_icon.svg" />
                                    <img *ngIf="list.status == 'Awaiting for rating'" width="30px" height="32px"
                                       src="../../../assets/ui_icons/awaiting_for_rating.svg" />

                                    <img *ngIf="list.status == 'Review completed'" width="30px" height="auto"
                                       src="../../../assets/ui_icons/second_opinion/review_completed.svg" />
                                    <p>{{list.status}}</p>
                                 </div>
                              </td>

                            
                              </tr>
                        </tbody>
                     </table>

                  </div>
               </div>
            </div>
            
            <p [hidden]="true">
            <select id="printer" [(ngModel)]="selectedPrinter">
               <option *ngFor="let printer of printers" [ngValue]="printer">{{ printer }}</option>
             </select>
             <br>
             <br>
             
             <br>
             <label for="message">Write a message:</label>
             <input [(ngModel)]="message" type="text" id="message" placeholder="Message">
             <br>
             <br>
             
             <button (click)="testPrint()">Print</button>
             </p>
            <!-- Printing bill && cover print-->
            <div [hidden]="true">
               <div>
                  <div #billprint id="billprint">
                     <table class="report-container" style="width: 100%;">
                        <thead class="report-header" style="margin-left:10px;margin-top:10px;margin-right: 10px;">
                           <tr>
                              <td style="display: flex;justify-content: center;">
                                 <div>
                                 </div>
                                 <div style="position: relative;top: 20px;left: 10px;text-align: left;">
                                    <p><b>{{pharmacyName}}</b>,</p>
                                    <span *ngIf="pharmaAddress1 != undefined">{{pharmaAddress1}},</span><span
                                       *ngIf="pharmaAddress2 != undefined">{{pharmaAddress2}},</span><br />
                                    <span>{{location}},</span> <span>{{city}},</span>
                                    <span>{{state}},</span><span>{{country}}.</span><br>
                                    <p><span *ngIf="dlNO != undefined">DL No : {{dlNO}}
                                          &nbsp;&nbsp;</span><span *ngIf="gstNO != undefined">GST :
                                          {{gstNO}}</span></p>
                                 </div>
                              </td>
                           </tr>
                        </thead>
                        <tfoot class="report-footer">
                           <tr>
                              <td style="height:100px;"></td>
                           </tr>
                        </tfoot>
                        <tbody class="report-content">
                           <tr>
                              <td class="report-content-cell">
                                 <div class="main">
                                    <table style="margin-left: 75px;margin-top: 20px;">
                                       <td>
                                          <p>Name :{{name}}</p>
                                          <p #billid id="billid"></p>
                                          <p>Bill type :{{PurchaseType}}</p>
                                       </td>
                                       <td style="width: 250px;"></td>
                                       <td>
                                          <p>Doctor : {{doctorName}}</p>
                                          <p>Bill date :{{orderDate | date: 'dd-MM-yyyy'}}</p>
                                          <p>Bill time :{{orderTime}}</p>
                                       </td>
                                    </table>
                                    <br />
                                    <table cellspacing="0" width="85%"
                                       style="border-collapse: collapse;border:1px solid black; margin-left: 75px; margin-right: 50px;">
                                       <tr style="border:1px solid black;">
                                          <th style="border:1px solid black;">Medicine name</th>
                                          <th style="border:1px solid black;">Quantity</th>
                                          <th style="border:1px solid black;">Batch No</th>
                                          <th style="border:1px solid black;">Rack No</th>
                                          <th style="border:1px solid black;">Bin No</th>
                                          <th style="border:1px solid black;">Expiry date</th>
                                          <th style="border:1px solid black;">Price</th>
                                          <th style="border:1px solid black;"> Disc(%)</th>
                                          <th style="border:1px solid black;">CGST(%)</th>
                                          <th style="border:1px solid black;">SGST(%)</th>
                                          <th style="border:1px solid black;"> Amount</th>
                                       </tr>
                                       <tbody>
                                          <tr *ngFor="let medpre of drugDetailsArray">
                                             <td style="border:1px solid black;">
                                                {{medpre.drug_disp_name}}</td>

                                             <!-- <td style="border:1px solid black;">
                                                   {{medpre.drug_name}}</td> -->


                                             <td style="border:1px solid black;">
                                                <p style="float: right; margin-right: 10px;">
                                                   {{medpre.quantity}} </p>
                                             </td>
                                             <td style="border:1px solid black;">
                                                <p style="float: right; margin-right: 10px;">
                                                   {{medpre.batch_no}} </p>
                                             </td>
                                             <td style="border:1px solid black;">
                                                <p style="float: right; margin-right: 10px;">
                                                   {{medpre.rack_id}} </p>
                                             </td>
                                             <td style="border:1px solid black;">
                                                <p style="float: right; margin-right: 10px;">
                                                   {{medpre.bin_id}} </p>
                                             </td>
                                             <td style="border:1px solid black;">
                                                <p style="float: right; margin-right: 10px;">
                                                   {{medpre.expiry_date}} </p>
                                             </td>
                                             <td style="border:1px solid black;">
                                                <p style="float: right; margin-right: 10px;">
                                                   {{medpre.price}} </p>
                                             </td>
                                             <td style="border:1px solid black;">
                                                <p style="float: right; margin-right: 10px;">
                                                   {{medpre.discount}} </p>
                                             </td>
                                             <td style="border:1px solid black;">
                                                <p style="float: right; margin-right: 10px;">
                                                   {{medpre.cgst}} </p>
                                             </td>
                                             <td style="border:1px solid black;">
                                                <p style="float: right; margin-right: 10px;">
                                                   {{medpre.sgst}} </p>
                                             </td>
                                             <td style="border:1px solid black;">
                                                <p style="float: right; margin-right: 10px;">
                                                   {{medpre.amount}}</p>
                                             </td>
                                          </tr>
                                       </tbody>
                                    </table>
                                    <table>
                                       <tr>
                                          <td style="vertical-align: top; width: 68%;">
                                             <table class="col-3 col-md-5 col-lg-3 col-xl-3"
                                                style="float: left;margin-left: 40px;" *ngIf="paymentsarray !=0">
                                                <tr *ngFor="let pay of paymentsarray">
                                                   <td style="width: 150px !important;vertical-align: top;">
                                                      {{pay.pay_desc}}</td>
                                                   <td style="width: 150px !important;vertical-align: top;">
                                                      {{pay.amount}}</td>
                                                </tr>
                                             </table>
                                          </td>
                                          <td>
                                             <table width="85%" style="margin-left: 76px; margin-right: 50px;">
                                                <tr *ngIf="displayDisplay">
                                                   <td style="text-align: right; width: 80%;">
                                                      <b>Discount</b>
                                                      <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                                         style="width: 15px; height: 15px;" />
                                                   </td>
                                                   <td>
                                                      <p style="float: right;margin-right: 10px;">
                                                         {{prodDiscount}}</p>
                                                   </td>
                                                </tr>
                                                <tr>
                                                   <td style="text-align: right; width: 80%;">
                                                      <b>Total</b>
                                                      <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                                         style="width: 15px; height: 15px;" />
                                                   </td>
                                                   <td>
                                                      <p style="float: right;margin-right: 10px;">
                                                         {{totalAmount}}</p>
                                                   </td>
                                                </tr>

                                                <tr>
                                                   <td style="text-align: right; width: 80%;">
                                                      <b>Paid</b>
                                                      <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                                         style="width: 15px; height: 15px;" />
                                                   </td>
                                                   <td class="billamttext">
                                                      <p style="float: right;margin-right: 10px;">
                                                         {{paidAmount}}</p>
                                                   </td>
                                                </tr>

                                                <tr>
                                                   <td style="text-align: right; width: 80%;">
                                                      <b>Balance</b>
                                                      <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                                         style="width: 15px; height: 15px;" />
                                                   </td>

                                                   <td class="billamttext">
                                                      <p style="float: right;margin-right: 10px;">
                                                         {{remainBalance}}</p>
                                                   </td>

                                                </tr>

                                             </table>
                                          </td>
                                       </tr>
                                    </table>
                                 </div>
                              </td>
                           </tr>
                        </tbody>
                     </table>
                  </div>

                  <div #noheader id="noheader">
                     <table class="report-container" style="width: 100%;">
                        <thead class="report-header" style="margin-left:10px;margin-top:10px;margin-right: 10px;">
                           <tr>
                              <td style="height:130px;"></td>
                           </tr>
                        </thead>
                        <tfoot class="report-footer">
                           <tr>
                              <td style="height:100px;"></td>
                           </tr>
                        </tfoot>
                        <tbody class="report-content">
                           <tr>
                              <td class="report-content-cell">
                                 <div class="main">
                                    <table style="margin-left: 75px;margin-top: 20px;">
                                       <td>
                                          <p>Name :{{name}}</p>
                                          <p #billid id="billid"></p>
                                          <p>Bill type :{{PurchaseType}}</p>
                                       </td>
                                       <td style="width: 250px;"></td>
                                       <td>
                                          <p>Doctor : {{doctorName}}</p>
                                          <p>Bill date :{{orderDate | date: 'dd-MM-yyyy'}}</p>
                                          <p>Bill time :{{orderTime}}</p>
                                       </td>
                                    </table>
                                    <br />
                                    <table cellspacing="0" width="85%"
                                       style="border-collapse: collapse;border:1px solid black; margin-left: 75px; margin-right: 50px;">
                                       <tr style="border:1px solid black;">
                                          <th style="border:1px solid black;">Medicine name</th>
                                          <th style="border:1px solid black;">Quantity</th>
                                          <th style="border:1px solid black;">Batch No</th>
                                          <th style="border:1px solid black;">Expiry date</th>
                                          <th style="border:1px solid black;">Price</th>
                                          <th style="border:1px solid black;"> Disc(%)</th>
                                          <th style="border:1px solid black;">CGST(%)</th>
                                          <th style="border:1px solid black;">SGST(%)</th>
                                          <th style="border:1px solid black;"> Amount</th>
                                       </tr>
                                       <tbody>
                                          <tr *ngFor="let medpre of drugDetailsArray">

                                             <td style="border:1px solid black;">
                                                {{medpre.drug_disp_name}}</td>

                                             <!-- <td style="border:1px solid black;">
                                                   {{medpre.drug_name}}</td> -->

                                             <td style="border:1px solid black;">
                                                <p style="float: right; margin-right: 10px;">
                                                   {{medpre.quantity}} </p>
                                             </td>
                                             <td style="border:1px solid black;">
                                                <p style="float: right; margin-right: 10px;">
                                                   {{medpre.batch_no}} </p>
                                             </td>
                                             <td style="border:1px solid black;">
                                                <p style="float: right; margin-right: 10px;">
                                                   {{medpre.expiry_date}} </p>
                                             </td>
                                             <td style="border:1px solid black;">
                                                <p style="float: right; margin-right: 10px;">
                                                   {{medpre.price}} </p>
                                             </td>
                                             <td style="border:1px solid black;">
                                                <p style="float: right; margin-right: 10px;">
                                                   {{medpre.discount}} </p>
                                             </td>
                                             <td style="border:1px solid black;">
                                                <p style="float: right; margin-right: 10px;">
                                                   {{medpre.cgst}} </p>
                                             </td>
                                             <td style="border:1px solid black;">
                                                <p style="float: right; margin-right: 10px;">
                                                   {{medpre.sgst}} </p>
                                             </td>
                                             <td style="border:1px solid black;">
                                                <p style="float: right; margin-right: 10px;">
                                                   {{medpre.amount}}</p>
                                             </td>
                                          </tr>
                                       </tbody>
                                    </table>
                                    <table>
                                       <tr>
                                          <td style="vertical-align: top; width: 68%;">
                                             <table class="col-3 col-md-5 col-lg-3 col-xl-3"
                                                style="float: left;margin-left: 40px;" *ngIf="paymentsarray !=0">
                                                <tr *ngFor="let pay of paymentsarray">
                                                   <td style="width: 150px !important;vertical-align: top;">
                                                      {{pay.pay_desc}}</td>
                                                   <td style="width: 150px !important;vertical-align: top;">
                                                      {{pay.amount}}</td>
                                                </tr>
                                             </table>
                                          </td>
                                          <td>
                                             <table width="85%" style="margin-left: 76px; margin-right: 50px;">
                                                <tr *ngIf="displayDisplay">
                                                   <td style="text-align: right; width: 80%;">
                                                      <b>Discount</b>
                                                      <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                                         style="width: 15px; height: 15px;" />
                                                   </td>
                                                   <td>
                                                      <p style="float: right;margin-right: 10px;">
                                                         {{prodDiscount}}</p>
                                                   </td>
                                                </tr>
                                                <tr>
                                                   <td style="text-align: right; width: 80%;">
                                                      <b>Total</b>
                                                      <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                                         style="width: 15px; height: 15px;" />
                                                   </td>
                                                   <td>
                                                      <p style="float: right;margin-right: 10px;">
                                                         {{totalAmount}}</p>
                                                   </td>
                                                </tr>
                                                <tr>
                                                   <td style="text-align: right; width: 80%;">
                                                      <b>Paid</b>
                                                      <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                                         style="width: 15px; height: 15px;" />
                                                   </td>
                                                   <td class="billamttext">
                                                      <p style="float: right;margin-right: 10px;">
                                                         {{paidAmount}}</p>
                                                   </td>
                                                </tr>
                                                <tr>
                                                   <td style="text-align: right; width: 80%;">
                                                      <b>Balance</b>
                                                      <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                                         style="width: 15px; height: 15px;" />
                                                   </td>
                                                   <td class="billamttext">
                                                      <p style="float: right;margin-right: 10px;">
                                                         {{remainBalance}}</p>
                                                   </td>
                                                </tr>
                                             </table>
                                          </td>
                                       </tr>
                                    </table>
                                 </div>
                              </td>
                           </tr>
                        </tbody>
                     </table>
                  </div>
                  <div #banner id="banner">
                     <table class="report-container" style="width: 100%;">
                        <thead class="report-header" style="margin-left:10px;margin-top:10px;margin-right: 10px;">
                           <tr>
                              <td style="width: 50px;"></td>
                              <td style="text-align: center">
                                 <img alt="image" src={{pharmaLogo}} width="50px">
                              </td>
                           </tr>
                        </thead>
                        <tfoot class="report-footer">
                           <tr>
                              <td style="height:100px;"></td>
                           </tr>
                        </tfoot>
                        <tbody class="report-content">
                           <tr>
                              <td class="report-content-cell">
                                 <div class="main">
                                    <table style="margin-left: 75px;margin-top: 20px;">
                                       <td>
                                          <p>Name :{{name}}</p>
                                          <p #billid id="billid"></p>
                                          <p>Bill type :{{PurchaseType}}</p>
                                       </td>
                                       <td style="width: 250px;"></td>
                                       <td>
                                          <p>Doctor : {{doctorName}}</p>
                                          <p>Bill date :{{orderDate | date: 'dd-MM-yyyy'}}</p>
                                          <p>Bill time :{{orderTime}}</p>
                                       </td>
                                    </table>
                                    <br />
                                    <table cellspacing="0" width="85%"
                                       style="border-collapse: collapse;border:1px solid black; margin-left: 75px; margin-right: 50px;">
                                       <tr style="border:1px solid black;">
                                          <th style="border:1px solid black;">Medicine name</th>
                                          <th style="border:1px solid black;">Quantity</th>
                                          <th style="border:1px solid black;">Batch No</th>
                                          <th style="border:1px solid black;">Expiry date</th>
                                          <th style="border:1px solid black;">Price</th>
                                          <th style="border:1px solid black;"> Disc(%)</th>
                                          <th style="border:1px solid black;">CGST(%)</th>
                                          <th style="border:1px solid black;">SGST(%)</th>
                                          <th style="border:1px solid black;"> Amount</th>
                                       </tr>
                                       <tbody>
                                          <tr *ngFor="let medpre of drugDetailsArray">

                                             <td style="border:1px solid black;">
                                                {{medpre.drug_disp_name}}</td>

                                             <!-- <td style="border:1px solid black;">
                                                   {{medpre.drug_name}}</td> -->

                                             <td style="border:1px solid black;">
                                                <p style="float: right; margin-right: 10px;">
                                                   {{medpre.quantity}} </p>
                                             </td>
                                             <td style="border:1px solid black;">
                                                <p style="float: right; margin-right: 10px;">
                                                   {{medpre.batch_no}} </p>
                                             </td>
                                             <td style="border:1px solid black;">
                                                <p style="float: right; margin-right: 10px;">
                                                   {{medpre.expiry_date}} </p>
                                             </td>
                                             <td style="border:1px solid black;">
                                                <p style="float: right; margin-right: 10px;">
                                                   {{medpre.price}} </p>
                                             </td>
                                             <td style="border:1px solid black;">
                                                <p style="float: right; margin-right: 10px;">
                                                   {{medpre.discount}} </p>
                                             </td>
                                             <td style="border:1px solid black;">
                                                <p style="float: right; margin-right: 10px;">
                                                   {{medpre.cgst}} </p>
                                             </td>
                                             <td style="border:1px solid black;">
                                                <p style="float: right; margin-right: 10px;">
                                                   {{medpre.sgst}} </p>
                                             </td>
                                             <td style="border:1px solid black;">
                                                <p style="float: right; margin-right: 10px;">
                                                   {{medpre.amount}}</p>
                                             </td>
                                          </tr>
                                       </tbody>
                                    </table>
                                    <table>
                                       <tr>
                                          <td style="vertical-align: top; width: 68%;">
                                             <table class="col-3 col-md-5 col-lg-3 col-xl-3"
                                                style="float: left;margin-left: 40px;" *ngIf="paymentsarray !=0">
                                                <tr *ngFor="let pay of paymentsarray">
                                                   <td style="width: 150px !important;vertical-align: top;">
                                                      {{pay.pay_desc}}</td>
                                                   <td style="width: 150px !important;vertical-align: top;">
                                                      {{pay.amount}}</td>
                                                </tr>
                                             </table>
                                          </td>
                                          <td>
                                             <table width="85%" style="margin-left: 76px; margin-right: 50px;">
                                                <tr *ngIf="displayDisplay">
                                                   <td style="text-align: right; width: 80%;">
                                                      <b>Discount</b>
                                                      <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                                         style="width: 15px; height: 15px;" />
                                                   </td>
                                                   <td>
                                                      <p style="float: right;margin-right: 10px;">
                                                         {{prodDiscount}}</p>
                                                   </td>
                                                </tr>
                                                <tr>
                                                   <td style="text-align: right; width: 80%;">
                                                      <b>Total</b>
                                                      <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                                         style="width: 15px; height: 15px;" />
                                                   </td>
                                                   <td>
                                                      <p style="float: right;margin-right: 10px;">
                                                         {{totalAmount}}</p>
                                                   </td>
                                                </tr>
                                                <tr>
                                                   <td style="text-align: right; width: 80%;">
                                                      <b>Paid</b>
                                                      <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                                         style="width: 15px; height: 15px;" />
                                                   </td>
                                                   <td class="billamttext">
                                                      <p style="float: right;margin-right: 10px;">
                                                         {{paidAmount}}</p>
                                                   </td>
                                                </tr>
                                                <tr>
                                                   <td style="text-align: right; width: 80%;">
                                                      <b>Balance</b>
                                                      <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                                         style="width: 15px; height: 15px;" />
                                                   </td>
                                                   <td class="billamttext">
                                                      <p style="float: right;margin-right: 10px;">
                                                         {{remainBalance}}</p>
                                                   </td>
                                                </tr>
                                             </table>
                                          </td>
                                       </tr>
                                    </table>
                                    <!-- <table width="85%" style="margin-left: 76px; margin-right: 50px;">
                            <tr *ngIf="displayDisplay">
                              <td style="text-align: right; width: 80%;">
                                <b>Discount</b>
                                <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                  style="width: 15px; height: 15px;" />
                              </td>
                              <td>
                                <p style="float: right;margin-right: 10px;">{{prodDiscount}}</p>
                              </td>
                            </tr>
                            <tr>
                              <td style="text-align: right; width: 80%;">
                                <b>Total</b>
                                <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                  style="width: 15px; height: 15px;" />
                              </td>
                              <td>
                                <p style="float: right;margin-right: 10px;">{{totalAmount}}</p>
                              </td>
                            </tr>
                            <tr>
                              <td style="text-align: right; width: 80%;">
                                <b>Paid</b>
                                <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                  style="width: 15px; height: 15px;" />
                              </td>
                              <td class="billamttext">
                                <p style="float: right;margin-right: 10px;">{{paidAmount}}</p>
                              </td>
                            </tr>
                            <tr>
                              <td style="text-align: right; width: 80%;">
                                <b>Balance</b>
                                <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                  style="width: 15px; height: 15px;" />
                              </td>
                              <td class="billamttext">
                                <p style="float: right;margin-right: 10px;">{{remainBalance}}</p>
                              </td>
                            </tr>
                          </table> -->
                                 </div>
                              </td>
                           </tr>
                        </tbody>
                     </table>
                  </div>
                  <div #logowithname id="logowithname"
                     style="background-image: url({{bgImage}}); background-position: center;">
                     <table class="report-container" style="width: 100%;">
                        <thead class="report-header">
                           <tr>
                              <td style="display: flex;justify-content: center;">
                                 <div>
                                    <img alt="image" src={{pharmaLogo}} width="100px" height="100px"
                                       style="vertical-align: middle;">
                                 </div>
                                 <div style="position: relative;top: 20px;left: 10px;text-align: left;">
                                    <p><b>{{pharmacyName}}</b>,</p>
                                    <span *ngIf="pharmaAddress1 != undefined">{{pharmaAddress1}},</span><span
                                       *ngIf="pharmaAddress2 != undefined">{{pharmaAddress2}},</span><br />
                                    <span>{{location}},</span> <span>{{city}},</span>
                                    <span>{{state}},</span><span>{{country}}.</span><br>
                                    <p><span *ngIf="dlNO != undefined">DL No : {{dlNO}}
                                          &nbsp;&nbsp;</span><span *ngIf="gstNO != undefined">GST :
                                          {{gstNO}}</span></p>
                                 </div>
                              </td>
                           </tr>
                        </thead>
                        <tfoot class="report-footer">
                           <tr>
                              <td style="height:100px;"></td>
                           </tr>
                        </tfoot>
                        <tbody class="report-content">
                           <tr>
                              <td class="report-content-cell">
                                 <div class="main">
                                    <table style="margin-left: 75px;margin-top: 20px;">
                                       <td>
                                          <p>Name :{{name}}</p>
                                          <p #billid id="billid"></p>
                                          <p>Bill type :{{PurchaseType}}</p>
                                       </td>
                                       <td style="width: 250px;"></td>
                                       <td>
                                          <p>Doctor : {{doctorName}}</p>
                                          <p>Bill date :{{orderDate | date: 'dd-MM-yyyy'}}</p>
                                          <p>Bill time :{{orderTime}}</p>
                                       </td>
                                    </table>
                                    <br />
                                    <table cellspacing="0" width="85%"
                                       style="border-collapse: collapse;border:1px solid black; margin-left: 75px; margin-right: 50px;">
                                       <tr style="border:1px solid black;">
                                          <th style="border:1px solid black;">Medicine name</th>
                                          <th style="border:1px solid black;">Quantity</th>
                                          <th style="border:1px solid black;">Batch No</th>
                                          <th style="border:1px solid black;">Expiry date</th>
                                          <th style="border:1px solid black;">Price</th>
                                          <th style="border:1px solid black;"> Disc(%)</th>
                                          <th style="border:1px solid black;">CGST(%)</th>
                                          <th style="border:1px solid black;">SGST(%)</th>
                                          <th style="border:1px solid black;"> Amount</th>
                                       </tr>
                                       <tbody>
                                          <tr *ngFor="let medpre of drugDetailsArray">

                                             <td style="border:1px solid black;">
                                                {{medpre.drug_disp_name}}</td>

                                             <!-- <td style="border:1px solid black;">
                                                   {{medpre.drug_name}}</td>    -->

                                             <td style="border:1px solid black;">
                                                <p style="float: right; margin-right: 10px;">
                                                   {{medpre.quantity}} </p>
                                             </td>
                                             <td style="border:1px solid black;">
                                                <p style="float: right; margin-right: 10px;">
                                                   {{medpre.batch_no}} </p>
                                             </td>
                                             <td style="border:1px solid black;">
                                                <p style="float: right; margin-right: 10px;">
                                                   {{medpre.expiry_date}} </p>
                                             </td>
                                             <td style="border:1px solid black;">
                                                <p style="float: right; margin-right: 10px;">
                                                   {{medpre.price}} </p>
                                             </td>
                                             <td style="border:1px solid black;">
                                                <p style="float: right; margin-right: 10px;">
                                                   {{medpre.discount}} </p>
                                             </td>
                                             <td style="border:1px solid black;">
                                                <p style="float: right; margin-right: 10px;">
                                                   {{medpre.cgst}} </p>
                                             </td>
                                             <td style="border:1px solid black;">
                                                <p style="float: right; margin-right: 10px;">
                                                   {{medpre.sgst}} </p>
                                             </td>
                                             <td style="border:1px solid black;">
                                                <p style="float: right; margin-right: 10px;">
                                                   {{medpre.amount}}</p>
                                             </td>
                                          </tr>
                                       </tbody>
                                    </table>
                                    <table>
                                       <tr>
                                          <td style="vertical-align: top; width: 68%;">
                                             <table class="col-3 col-md-5 col-lg-3 col-xl-3"
                                                style="float: left;margin-left: 40px;" *ngIf="paymentsarray !=0">
                                                <tr *ngFor="let pay of paymentsarray">
                                                   <td style="width: 150px !important;vertical-align: top;">
                                                      {{pay.pay_desc}}</td>
                                                   <td style="width: 150px !important;vertical-align: top;">
                                                      {{pay.amount}}</td>
                                                </tr>
                                             </table>
                                          </td>
                                          <td>
                                             <table width="85%" style="margin-left: 76px; margin-right: 50px;">
                                                <tr *ngIf="displayDisplay">
                                                   <td style="text-align: right; width: 80%;">
                                                      <b>Discount</b>
                                                      <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                                         style="width: 15px; height: 15px;" />
                                                   </td>
                                                   <td>
                                                      <p style="float: right;margin-right: 10px;">
                                                         {{prodDiscount}}</p>
                                                   </td>
                                                </tr>
                                                <tr>
                                                   <td style="text-align: right; width: 80%;">
                                                      <b>Total</b>
                                                      <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                                         style="width: 15px; height: 15px;" />
                                                   </td>
                                                   <td>
                                                      <p style="float: right;margin-right: 10px;">
                                                         {{totalAmount}}</p>
                                                   </td>
                                                </tr>
                                                <tr>
                                                   <td style="text-align: right; width: 80%;">
                                                      <b>Paid</b>
                                                      <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                                         style="width: 15px; height: 15px;" />
                                                   </td>
                                                   <td class="billamttext">
                                                      <p style="float: right;margin-right: 10px;">
                                                         {{paidAmount}}</p>
                                                   </td>
                                                </tr>
                                                <tr>
                                                   <td style="text-align: right; width: 80%;">
                                                      <b>Balance</b>
                                                      <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                                         style="width: 15px; height: 15px;" />
                                                   </td>
                                                   <td class="billamttext">
                                                      <p style="float: right;margin-right: 10px;">
                                                         {{remainBalance}}</p>
                                                   </td>
                                                </tr>
                                             </table>
                                          </td>
                                       </tr>
                                    </table>
                                    <!-- <table width="85%" style="margin-left: 76px; margin-right: 50px;">
                            <tr *ngIf="displayDisplay">
                              <td style="text-align: right; width: 80%;">
                                <b>Discount</b>
                                <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                  style="width: 15px; height: 15px;" />
                              </td>
                              <td>
                                <p style="float: right;margin-right: 10px;">{{prodDiscount}}</p>
                              </td>
                            </tr>
                            <tr>
                              <td style="text-align: right; width: 80%;">
                                <b>Total</b>
                                <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                  style="width: 15px; height: 15px;" />
                              </td>
                              <td>
                                <p style="float: right;margin-right: 10px;">{{totalAmount}}</p>
                              </td>
                            </tr>
                            <tr>
                              <td style="text-align: right; width: 80%;">
                                <b>Paid</b>
                                <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                  style="width: 15px; height: 15px;" />
                              </td>
                              <td class="billamttext">
                                <p style="float: right;margin-right: 10px;">{{paidAmount}}</p>
                              </td>
                            </tr>
                            <tr>
                              <td style="text-align: right; width: 80%;">
                                <b>Balance</b>
                                <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                  style="width: 15px; height: 15px;" />
                              </td>
                              <td class="billamttext">
                                <p style="float: right;margin-right: 10px;">{{remainBalance}}</p>
                              </td>
                            </tr>
                          </table> -->
                                 </div>
                              </td>
                           </tr>
                        </tbody>
                     </table>
                     <div>
                     </div>
                  </div>
               </div>


          
              <!-- cover print -->
              <div [hidden]="true" id="printsmallcover" #printsmallcover>
               <div class="container-fluid m-1">
                 <div class="row">
                   <div class="col-6">
                     <!-- margin-top:50px -->
                     <div style="width:120px;height:230px;" *ngFor="let drug of drugDetailsArray">
                       <table class="covertablebig" border="1" style="width:100%;height:230px;">
                         <thead class="report-header">
                           <tr>
                             <td style="text-align: left;font-size:11px" colspan="4">
                              <b> Name: <span>{{clntSal}}. </span>  {{clientname}}</b>
                             </td>
                           </tr>
                           <tr>
                             <td style="text-align: left;font-size:11px" colspan="4">
                               <b> MR no: {{this.uhid}} </b>
                             </td>
                           </tr>
                           <tr>
                             <td style="text-align: left;font-size:11px" colspan="4">
                               <b> Medicine: {{drug.drug_disp_name}} </b>
                             </td>
                           </tr>
                           <tr>
                             <td style="text-align: left;font-size:11px" colspan="4">
                               <b> Quantity:{{drug.quantity}} </b>
                             </td>
                           </tr>
                           <tr>
                             <td style="text-align: left;font-size:11px" colspan="4">
                               <b> Batch no:{{drug.batch_no}} </b>
                             </td>
                           </tr>
                           <tr>
                             <td style="text-align: left;font-size:11px" colspan="4">
                               <b> Expire date:{{drug.expiry_date}} </b>
                             </td>
                           </tr>
                           <tr [hidden]="alternatsession">
                             <td colspan="4" style="text-align: left;font-size:10px"><b>{{intakesession}}</b></td>
                           </tr>
                           <tr [hidden]="sessionhide" *ngIf="intakesession === 'Morning only' || 'Afternoon only' || 'Night only' 
                     || 'Morning & Afternoon' || 'Morning & Night' || 'Afternoon & Night'||
                     'Afternoon & Evening'||'Evening & Night' || 'Four times a day'||'Once a week'
                     ||'Alternative day'||'Once in two weeks'||'Once a month'
                     ">
                             <th style="width:25%;font-size:8px">
                               <b>{{'Morning'}}
                                 <span>(</span>{{'Morning'|translate}}<span>)</span></b>
                             </th>
                             <th style="width:25%;font-size:8px"><b> {{'Afternoon'}}
                                 <span> (</span>{{'Afternoon'|translate}}<span>)</span></b>
                             </th>
                             <th style="width:25%;font-size:8px"><b> {{'Evening'}}
                                 <span>(</span>{{'Evening'| translate}}<span>)</span></b>
                             </th>
                             <th style="width:25%;font-size:8px"><b> {{'Night'}}
                                 <span>(</span>{{'Night'| translate}}<span>)</span></b>
                             </th>
   
                           </tr>
   
                           <tr [hidden]="sessionhrs"
                             *ngIf="intakesession === 'Every 6 hours'||'SOS'||'Stat'||'Once a day' || 'Every 8 hours' || 'Every 12 hours' ||'SOS' || 'Once a day'">
                             <th colspan="4" style="text-align: left;;font-size:10px">{{intakesession}}</th>
                           </tr>
   
   
                         </thead>
   
                         <tbody style="height:20px">
                           <tr [hidden]="sessionhide" *ngIf="intakesession === 'Morning only' || 'Afternoon only' 
                     || 'Night only' || 'Morning & Afternoon' || 'Morning & Night' || 'Afternoon & Night'||
                     'Afternoon & Evening'||'Evening & Night' || 'Four times a day'||'Once a week'||
                     'Alternative day'||'Once in two weeks'||'Once a month'
                     ">
                             <td style="text-align: center;;font-size:10px"><b>{{drug.morning}}{{drug.shortname}}</b></td>
                             <td style="text-align: center;;font-size:10px"><b>{{drug.afternoon}}{{drug.shortname}}</b>
                             </td>
                             <td style="text-align: center;font-size:10px"><b>{{drug.evening}}{{drug.shortname}}</b></td>
                             <td style="text-align: center;font-size:10px"><b>{{drug.night}}{{drug.shortname}}</b></td>
                           </tr>
   
                           <tr [hidden]="sessionhrs"
                             *ngIf="intakesession === 'Every 6 hours'||'SOS'||'Stat'||'Once a day' || 'Every 8 hours' || 'Every 12 hours' ||'SOS' || 'Once a day'">
                             <td colspan="4" style="font-size:10px;font-size:10px">{{intakesession}} - {{drug.mor}}</td>
                           </tr>
                         </tbody>
   
                         <tfoot>
                           <tr style="border:1px solid black">
                             <th style="text-align: left;font-size:10px" colspan="4">
                               {{drug.days}} {{drug.daydur_txt |translate}}
                             </th>
   
                           </tr>
                           <tr style="border:1px solid black">
                             <th style="text-align: left;font-size:10px" colspan="4">
                               {{drug.time_duration}} {{'Minutes'|translate}} - {{drug.drug_intake |translate}}
                             </th>
                           </tr>
                         </tfoot>
                       </table>
                     </div>
   
                   </div>
                 </div>
                 <!-- margin-left:20px !important;
                -->
               </div>
             </div>
             <div [hidden]="true" id="printsmallcovereng" #printsmallcovereng>
               <div class="container-fluid m-1">
               <div class="row">
                  <div class="col-6">
                  <div style="width:120px;height:230px;" *ngFor="let drug of drugDetailsArray">
   
   
                 <table class="covertablebig" border="1" style="width:100%;height:230px;">
                   <thead class="report-header">
   
                     <tr>
                       <td style="text-align: left;font-size:10px" colspan="4">
                         <b> Name: <span>{{clntSal}}. </span>  {{clientname}}</b>
                       </td>
                     </tr>
                     <tr>
                       <td style="text-align: left;font-size:10px" colspan="4">
                         <b> MR number: {{this.uhid}}</b>
                       </td>
                     </tr>
                     <tr>
   
                       <td style="text-align: left;font-size:10px" colspan="4">
                         <b>Medicine: {{drug.drug_disp_name}}</b>
                       </td>
   
                     </tr>
                     <tr>
   
                       <td style="text-align: left;font-size:10px" colspan="4">
                         <b> Quantity:{{drug.quantity}}</b>
                       </td>
   
                     </tr>
                     <tr>
   
                       <td style="text-align: left;font-size:10px" colspan="4">
                         <b> Batch no:{{drug.batch_no}}</b>
                       </td>
   
                     </tr>
                     <tr>
                       <td style="text-align: left;font-size:10px" colspan="4">
                         <b> Expire date:{{drug.expiry_date}}</b>
                       </td>
                     </tr>
   
                     <tr [hidden]="alternatsession">
                       <td colspan="4" style="text-align: left;font-size:10px"><b>{{intakesession}}</b></td>
                     </tr>
   
                     <tr [hidden]="sessionhide" *ngIf="intakesession === 'Morning only' || 'Afternoon only' || 'Night only' 
                     || 'Morning & Afternoon' || 'Morning & Night' || 'Afternoon & Night'||
                     'Afternoon & Evening'||'Evening & Night' || 'Four times a day'||'Once a week'
                     ||'Alternative day'||'Once in two weeks'||'Once a month'
                     ">
                       <th style="width:25%;font-size:10px">{{'Morning'}}
                       </th>
                       <th style="width:25%;font-size:10px">{{'Afternoon'}}
   
                       </th>
                       <th style="width:25%;font-size:10px">{{'Evening'}}
   
                       </th>
                       <th style="width:25%;font-size:10px">{{'Night'}}
                       </th>
   
                     </tr>
   
                     <tr [hidden]="sessionhrs"
                       *ngIf="intakesession === 'Every 6 hours'||'SOS'||'Stat'||'Once a day' || 'Every 8 hours' || 'Every 12 hours' ||'SOS' || 'Once a day'">
                       <th colspan="4" style="text-align: left;;font-size:10px">{{intakesession}}</th>
                     </tr>
                   </thead>
   
                   <tbody style="height:20px">
                     <tr [hidden]="sessionhide" *ngIf="intakesession === 'Morning only' || 'Afternoon only' 
                     || 'Night only' || 'Morning & Afternoon' || 'Morning & Night' || 'Afternoon & Night'||
                     'Afternoon & Evening'||'Evening & Night' || 'Four times a day'||'Once a week'||
                     'Alternative day'||'Once in two weeks'||'Once a month'
                     ">
                       <td style="text-align: center;;font-size:10px">{{drug.morning }}{{drug.shortname}}</td>
                       <td style="text-align: center;;font-size:10px">{{drug.afternoon}}{{drug.shortname}}</td>
                       <td style="text-align: center;font-size:10px">{{drug.evening}}{{drug.shortname}}</td>
                       <td style="text-align: center;font-size:10px">{{drug.night}}{{drug.shortname}}</td>
                     </tr>
                     <tr [hidden]="sessionhrs"
                       *ngIf="intakesession === 'Every 6 hours'||'SOS'||'Stat'||'Once a day' || 'Every 8 hours' || 'Every 12 hours' ||'SOS' || 'Once a day'">
                       <td colspan="4" style="font-size:10px;font-size:10px">{{intakesession}} - {{drug.mor}}</td>
                     </tr>
                   </tbody>
   
                   <tfoot>
                     <tr style="border:1px solid black">
                       <th style="text-align: left;font-size:10px" colspan="4">
                         {{drug.days}} {{drug.daydur_txt}} {{drug.time_duration}} {{'Minutes'}} - {{drug.drug_intake}}
                       </th>
   
                     </tr>
                   </tfoot>
                 </table>
               </div>

               </div>
               </div>
             </div>
             </div>
            <!-- cover print -->






            </div>
         </mdb-card-body>
      </mdb-card>
   </div>
</div>