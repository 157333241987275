import { Component, OnInit ,ElementRef,Input,Inject,ViewEncapsulation,HostListener, ViewChild} from '@angular/core';
import { Http, Headers } from '@angular/http';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { SecondOpinionReportdetailviewPopupModule } from './second_opinion_reportdetailview_popup.module';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Time_Formate_Session } from '../../../assets/js/common';
import { DomSanitizer } from '@angular/platform-browser';
import { ipaddress } from '../../ipaddress' ;import { Message_data } from 'src/assets/js/Message_data';

@Component({
  selector: 'app-second-opinion-reportdetailview-popup',
  templateUrl: './second-opinion-reportdetailview-popup.component.html',
  styleUrls: ['./second-opinion-reportdetailview-popup.component.css']
})
export class SecondOpinionReportdetailviewPopupComponent implements OnInit {
  public report_pres_id;
  public report_type;
  public report_diag_id;
  public report_test_id;
  public report_img_flag:boolean;

  public date_txt: string;
  public diag_name: string;
  public diag_add: string;
  public diag_loc: string;
  public diag_cityzip: string;
  public diag_statecon: string;
  public diag_teleph: string;
  public client_age: string;
  public get_age: string;
  public Age_div: boolean;
  public review_sign: string;
  public review_div: boolean;
  public clent_id: string;
  public review_by: string;
  public client_another_name: string;
  public client_name: string;
  public diag_refertxt: string;
  public client_gender: string;
  public time_collect: string;
  public review_txt: string;
  public second_opinion_mintlist = [];
  public second_opinion_subtlist = [];
  public second_opinion_mintlist1 = [];
  public temp_value = null;
  public dicom_doc: boolean = true;
  public report_img: string;
  public report_detail: string;
  public header_footer_flag: boolean;
  public blood_urine_faeces_div: boolean;
  public xray_scan_ultra_div: boolean;

  public opn_id;
  public opn_type;
  public opn_pres_test_id;
  public opn_app_id;
  public loader: boolean;
  public clientflag: boolean;
  public docflag:boolean;
  public diagflag:boolean;
  public relation: any;

  constructor(public sanitizer:DomSanitizer,public http:Http,public dialogRef: MatDialogRef<SecondOpinionReportdetailviewPopupModule>,
    @Inject(MAT_DIALOG_DATA) public data: any, public el: ElementRef, public toastr:ToastrService) { 
        dialogRef.disableClose = true;
        this.report_diag_id = data.diag_appointment_id;
        this.report_test_id = data.pres_test_id;
        this.relation = localStorage.getItem("rel_id");

        if (data.opinion_id != null) {
            this.report_type = data.type;
            this.opn_id = data.opinion_id;
            this.opn_type = data.type;
            this.opn_pres_test_id = data.pres_test_id;
            this.opn_app_id = data.app_id;
            this.Get_Report_Detailview("second"); // pass second opinion
            this.clientflag = false;
            this.docflag=true;
            this.diagflag=false;
        }else {
            this.report_type = data.type;
            this.Get_Report_Detailview("normal"); // view report view
            this.clientflag = true;
            this.docflag=false;
            this.diagflag=false;
        }
    }

    ngOnInit(): void {
    }

    Get_Report_Detailview(type) {
    var send_json = null, url = null;
    if (type == "normal") {
        url = 'record/diagreportview/';
        send_json = JSON.stringify({
            type: this.report_type,
            flag: "doctor",
            diag_app_id: this.report_diag_id,
            country: ipaddress.country_code,
            pres_diag_id: this.report_pres_id,
            pres_test_id: this.report_test_id,
        });
    } else if(type == "diag"){
        url = 'record/diagreportview/';
        send_json = JSON.stringify({
            type: this.report_type,
            flag: "diagnosis",
            diag_app_id: this.report_diag_id,
            country: ipaddress.country_code,
            pres_diag_id: this.report_pres_id,
            pres_test_id: this.report_test_id,
        });
    } else {
        url = 'secopn/greport/';
        send_json = JSON.stringify({
            type: this.opn_type,
            pres_test_id: this.opn_pres_test_id,
            opinion_id: this.opn_id,
            country: ipaddress.country_code,
            app_id: this.opn_app_id
        });
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + url, send_json,
        { headers: headers })
        .subscribe(
            response => {
                var obj = response.json();
                if (obj.diag_centre_name != null) {
                    this.loader = true;
                    if (obj.date_entered != null) {
                        var datereport = obj.date_entered.split('-');
                        this.date_txt = datereport[2] + "-" + datereport[1] + "-" + datereport[0]
                    }
                    this.diag_name = obj.diag_centre_name;
                    this.diag_add = obj.address2 != null ? obj.address1 + " " + obj.address2 : obj.address1;
                    this.diag_loc = obj.location;
                    this.diag_cityzip = obj.city + " - " + obj.zipcode;
                    this.diag_statecon = obj.state + " - " + obj.country;
                    this.diag_teleph = obj.telephone;

                    if (obj != null) {
                        this.client_age = obj.age;
                        this.get_age = obj.age;
                        this.Age_div = false;
                    } else {
                        this.Age_div = true;
                    }

                    if (obj.sign != null) {
                        this.review_sign = "data:image/png;base64," + obj.sign;
                    } 

                    if (obj.app_first_name != null) {
                        this.review_div = true;
                    } 
                    
                    if (obj.app_middle_name != null) {
                        if (obj.app_first_name != undefined && obj.app_middle_name != undefined && obj.app_last_name != undefined) {
                            this.review_by = obj.app_first_name + " " + obj.app_middle_name + " " + obj.app_last_name;
                        }
                    } else {
                        if (obj.app_first_name != undefined && obj.app_last_name != undefined) {
                            this.review_by = obj.app_first_name + " " + obj.app_last_name;
                        }
                    }

                    this.clent_id = obj.client_reg_id;
                    localStorage.setItem("client_decrypt_first", obj.first_name);
                    localStorage.setItem("client_decrypt_middle", obj.middle_name);
                    localStorage.setItem("client_decrypt_last", obj.last_name);

                    if (obj.middle_name != null) {
                        this.client_another_name = encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.middle_name) + " " + encrypt_decript.Decript(obj.last_name);
                    } else {
                        this.client_another_name = encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.last_name);
                    }

                    this.client_name = encrypt_decript.Encript(localStorage.getItem("client_decrypt_first")).toString(), + " " + encrypt_decript.Encript(localStorage.getItem("client_decrypt_middle")).toString() + " " + encrypt_decript.Encript(localStorage.getItem("client_decrypt_last")).toString();
                    if( obj.doc_middle_name != undefined && obj.doc_middle_name != null){
                        this.diag_refertxt = "Dr." + obj.doc_first_name + " " + obj.doc_middle_name + " " + obj.doc_last_name;
                    } else{
                        this.diag_refertxt = "Dr." + obj.doc_first_name + " " + obj.doc_last_name;
                    }
                    
                    this.client_age = obj.age;
                    this.client_gender = encrypt_decript.Decript(obj.gender);
                    this.time_collect = Time_Formate_Session(obj.time_collected);
                    this.review_txt = "------";

                    this.second_opinion_mintlist = [];
                    this.second_opinion_subtlist = [];
                    this.second_opinion_mintlist1 = [];

                    if (this.report_type == "Urine" || this.report_type == "Blood" || this.report_type == "Faeces") {
                        this.blood_urine_faeces_div = false;
                        this.xray_scan_ultra_div = true;
                        for (var i = 0; i < obj.main_tests.length; i++) {
                            this.second_opinion_mintlist.push({
                                pres_test_id: obj.main_tests[i].pres_test_id,
                                test_id: obj.main_tests[i].test_id,
                                test_name: obj.main_tests[i].test_name,
                            });

                            for (var i = 0; i < obj.main_tests.length; i++) {
                                for (var j = 0; j < obj.main_tests[i].subtests.length; j++) {
                                    this.temp_value = "";
                                    this.temp_value = this.sanitizer.bypassSecurityTrustHtml(obj.main_tests[i].subtests[j].range);
                                    this.second_opinion_subtlist.push({
                                        sub_test_name: obj.main_tests[i].subtests[j].sub_test_name,
                                        reading: obj.main_tests[i].subtests[j].reading,
                                        test_range: this.temp_value,
                                        unit: obj.main_tests[i].subtests[j].unit,
                                        subtest_name_txt: obj.main_tests[i].subtests[j].test_name
                                    });
                                }
                            }
                        }
                    } else {
                        this.blood_urine_faeces_div = true;
                        this.xray_scan_ultra_div = false;
                        for (var i = 0; i < obj.main_tests.length; i++) {
                            if (obj.main_tests[i].image != null) {
                                this.dicom_doc = false;
                                this.report_detail = ipaddress.Ip_with_img_address.toString() + obj.main_tests[i].image;
                                this.report_img = ipaddress.Ip_with_img_address.toString() + obj.main_tests[i].image;
                            }

                            var sequences:any = "";
                            if(obj.main_tests[i].sequences != undefined){
                              sequences = this.sanitizer.bypassSecurityTrustHtml(obj.main_tests[i].sequences);
                            }
                            var impression:any = "";
                            if(obj.main_tests[i].impression != undefined){
                              impression = this.sanitizer.bypassSecurityTrustHtml(obj.main_tests[i].impression);
                            }

                            this.second_opinion_mintlist1.push({
                                pres_test_id: obj.main_tests[i].pres_test_id,
                                test_name: obj.main_tests[i].test_name,
                                sequences: sequences,
                                impression: impression,
                            });
                        }
                    }
                } else {
                    this.loader = true;
                    this.toastr.error(Message_data.defaultErr);
                }
            },
            error => {
                this.loader = true;
                this.toastr.error(Message_data.defaultErr);
            });
    }

    ClosePopup(){
        this.dialogRef.close();
    }
}
