import { Component, OnInit } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Helper_Class } from '../../helper_class';
import { Location } from '@angular/common';
import { Message_data } from '../../../assets/js/Message_data';
import { ToastrService } from 'ngx-toastr';
import { PhysioService } from '../physio/physio.service';
import { ipaddress } from '../../ipaddress';

@Component({
  selector: 'app-physio-therapies',
  templateUrl: './physio-therapies.component.html',
  styleUrls: ['./physio-therapies.component.scss']
})
export class PhysioTherapiesComponent implements OnInit {
  public therapyList = [];
  public therapy;
  public price;
  public therapyId;
  public currentDate;
  public therapyFlag: boolean;
  public therapyType: string;
  public userInfo;
  public hptlClinicId;

  constructor(public http: Http, public toastr: ToastrService, public location: Location, public messageService: PhysioService) {
    this.therapyFlag = false;
    this.therapyType = "active";
  }

  ngOnInit(): void {
    this.userInfo = Helper_Class.getInfo();
    this.hptlClinicId = this.userInfo.hospitals[0].hptl_clinic_id;
    this.getCurrentDate();
  }

  getCurrentDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'adm/curdate',
      JSON.stringify({
        country: ipaddress.country_code
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.current_date != null) {
            this.currentDate = obj.current_date;
            this.getTherapies();
          }
        },error => {});
  }

  getTherapies() {
    this.therapyList = [];
    var statusval;
    statusval = (this.therapyType == "active") ? "1" : "0";
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'physio/gtherapy/',
      JSON.stringify({
        hptl_clinic_id: this.hptlClinicId,
        status: statusval,
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          for (var i = 0; i < obj.therapies.length; i++) {
            this.therapyList.push({
              therapy: obj.therapies[i].therapy,
              price: obj.therapies[i].price,
              therapy_id: obj.therapies[i].therapy_id,
              status: obj.therapies[i].status
            });
          }
        }, error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }

  save() {
    if(this.therapyList.length != 0){
      for (var i = 0; i < this.therapyList.length; i++) {
        if (this.therapyList[i].therapy == this.therapy && this.therapyList[i].price == this.price) {
          this.toastr.error(Message_data.existTherapy); 
          this.therapyFlag = true;
        } else {
          this.therapyFlag = false;
        }
      }
    }

    if (this.therapy == "" || this.therapy == null) {
      this.therapyFlag = true;
      this.toastr.error(Message_data.enterTherapyNPrice);     
    }
    if (this.therapyFlag == false) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'physio/stherapy/',
        JSON.stringify({
          therapy_id: this.therapyId,
          therapy: this.therapy,
          price: this.price,
          hptl_clinic_id: this.hptlClinicId,
          date: this.currentDate
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            console.log(JSON.parse(response["_body"]))
            if (obj["key"] != null) {
              if (obj["key"] == "1") {
                var flag = true;
                for (var i = 0; i < this.therapyList.length; i++) {
                  if (this.therapyList[i].therapy == this.therapy) {
                    flag = false;
                    break;
                  }
                }
                if (flag == true) {
                }
                this.therapy = undefined;
                this.price = undefined;
                this.getTherapies();
                this.toastr.success(obj["result"]);
                this.therapy = null;
                this.price = null;
              }
            }else {
              this.toastr.error(obj["result"]);
            }
          },error => {
            this.toastr.error(Message_data.defaultErr);
          });
    }
  }

  editTherapy(value, price) {
    for (var i = 0; i < this.therapyList.length; i++) {
      if (this.therapyList[i].therapy == value && this.therapyList[i].price == price) {
        this.therapy = this.therapyList[i].therapy;
        this.therapyId = this.therapyList[i].therapy_id;
        this.price = this.therapyList[i].price;
        this.therapyList.splice(i, 1);
      }
    }
  }

  deleteTherapy(therapyid) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'physio/dtherapy/',
      JSON.stringify({
        therapyid: therapyid
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.key == "1") {
            this.toastr.success(Message_data.therapyDelSuccess);
            this.getTherapies();
          } else {
            this.toastr.error(Message_data.unabltToDelTherapy);
          }
        });
  }

  activateTherapy(therapyid){
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'physio/atherapy/',
      JSON.stringify({
        therapyid: therapyid
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if(obj.key =="1"){
            this.toastr.success(Message_data.therapyReactivatedSuccess);
            this.getTherapies();
          } else {
            this.toastr.error(Message_data.unabltToReactivateTherapy);
          }
        })
  }
}
