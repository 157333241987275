import { Component, OnInit ,ElementRef,Input,Inject,ViewEncapsulation,HostListener} from '@angular/core';
import { Http, Headers } from '@angular/http';
// import { getIp, Ip_with_img_address, country_code } from '../../../assets/js/ipaddress';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-pediatrics-immunization-remarks',
  templateUrl: './pediatrics-immunization-remarks.component.html',
  styleUrls: ['./pediatrics-immunization-remarks.component.scss']
})
export class PediatricsImmunizationRemarksComponent implements OnInit {

  public remarks;

  constructor(public http:Http, public el: ElementRef,public dialogRef: MatDialogRef<PediatricsImmunizationRemarksComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {   
      dialogRef.disableClose = true;
      if(data !=null) {
        this.remarks = data;
      }
  }

  ngOnInit(): void {
  }

  ClosePopup(){
    this.dialogRef.close();
  }
}
