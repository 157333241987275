import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { CommonDataService } from '../../providers/common-data.service';
import { ToastrService } from 'ngx-toastr';
import { Diagnosis_Helper } from '../Diagnosis_Helper';
import { DiagnosisService } from '../diagnosis/diagnosis.service';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
import { Helper_Class } from '../../../app/helper_class';

@Component({
  selector: 'app-diag-package-create',
  templateUrl: './diag-package-create.component.html',
  styleUrls: ['./diag-package-create.component.scss']
})
export class DiagPackageCreateComponent implements OnInit {
  public save_btn: boolean = true;
  public update_btn: boolean = true;
  show = true
  view = false
  public select_country: any;
  // public subtestID:any
  public diag_testtype_name: any;
  public test_name: any = [];
  public testTypeName: any = [];
  public urine_test: any = []
  public faeces_test: any = []
  public scan_test: any = []
  public xray_test: any = []
  public ultra_test: any = []
  public culture: any = []
  public biopsy: any = []
  public tests: any
  public diag_packages_id: number = 0
  type_name: any;
  test_id: any
  typename: any
  public blood_test: any[] = []
  public checked: boolean = false;
  data: any;
  service: any;
  package_id: any;
  subtest: any;
  typenames: any;
  typeName: any;
  bloodtest: any;
  id: any;
  testname: any;

  public diag_test = [];
  public testtypename: any = [];

  public test_flag: boolean = false;

  public diag_dept: string;
  public diag_test_categ: string;
  public pkg_name: string;
  public pkg_desc: string;
  public pkg_price: string;
  private diag_centre_id;
  public showTest_type: any;
  public userinfo: any;
  public testName: any;
  public filter_diag_test: any[];

  public blood_testObj: any = [];
  public scan_testObj: any = [];
  public ultra_testObj: any = [];
  public xray_testObj: any = [];
  public biopsy_testObj: any = [];
  public urine_testObj: any = [];
  public faeces_testObj: any = [];
  public culture_testObj: any = [];
  public department_data: any = [];
  public test_category_data: any = [];
  public fullTest_list: any = [];
  public diag_test_categ_desc;
  package_details: any = [];
  selected_Tests: any[];


  constructor(public diagnosisservice: MenuViewService, public toastr: ToastrService,
    public gservice: CommonDataService, public http: HttpClient, public dialog: MatDialog) {

  }

  ngOnInit(): void {
    this.userinfo = Helper_Class.getInfo();
    this.showTest_type = this.userinfo.hospitals[0].show_by_test_type;
    this.getDiagDepartment();

    this.package_details = Diagnosis_Helper.getdiagnosistest();
    console.log(this.package_details)

    if (this.package_details != undefined) {
      this.diag_dept = this.package_details.pack_data.department_id;
      this.diag_test_categ = this.package_details.pack_data.sub_department_id;
      this.getTestCategory(this.diag_dept);
      this.diag_packages_id = this.package_details.diag_package_id;
      this.pkg_name = this.package_details.pack_data.profile_name;
      this.pkg_price = this.package_details.pack_data.price;
      this.getPackageDetails(this.package_details.pack_data.tests);
    }
  }

  getDiagDepartment() {
    this.department_data = [];
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'mas/gdd', { diag_centre_id: Helper_Class.getHospitalInfo().hptl_clinic_id },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          var dept_data = obj.diag_dept_list;

          if (obj.diag_dept_list.length != 0) {
            for (var i = 0; i < obj.diag_dept_list.length; i++) {
              this.department_data.push({
                diag_department_id: dept_data[i].diag_department_id,
                diag_dept_desc: dept_data[i].diag_dept_desc,
                status: dept_data[i].status,
              })
            }
            console.log("getDiagDepartment", this.department_data)
          }
        }, error => { });
  }

  getTestCategory(deptId) {//get disease cateegory
    this.test_category_data = [];

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/dtcatn', { diag_centre_id: this.userinfo.hospitals[0].hptl_clinic_id, diag_dept_id: deptId },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.test_category_data = obj.test_categories;
          if (this.test_category_data != undefined) {
            this.diag_test_categ = this.test_category_data[0].diag_test_cat_id;
            this.diag_test_categ_desc = this.test_category_data[0].description;
            console.log('this.test_category_data', this.test_category_data)
          }

          if (this.package_details.is_new == '0') {
            this.diag_test_categ = this.package_details.pack_data.sub_department_id;
          }
          this.getTestByName("", this.diag_test_categ);
        }, error => { });
  }

  getPackageDetails1(packageid) {
    this.selected_Tests = [];
    console.log({
      package_id: packageid,
    })

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/gpbd/',
      {
        package_id: packageid,
      },
      { headers: headers }).subscribe(response => {
        var obj = JSON.parse(JSON.stringify(response));
        this.selected_Tests = obj.tests;
        console.log("PACKAGE RETRIEVE " + JSON.stringify(obj))
        if (obj.blood_test != undefined) {
          this.blood_test = obj.blood_test;
          for (var i = 0; i < this.diag_test.length; i++) {
            if (this.diag_test[i].test_type_name == "Blood Test") {
              for (var j = 0; j < this.diag_test[i].sub_tests.length; j++) {
                for (var k = 0; k < obj.blood_test.length; k++) {
                  if (this.diag_test[i].sub_tests[j].test_id == obj.blood_test[k]) {
                    this.diag_test[i].sub_tests[j].checked = true;
                  }
                }
              }
            }
          }
        }

        if (obj.urine_test != undefined) {
          this.urine_test = obj.urine_test;
          for (var i = 0; i < this.diag_test.length; i++) {
            if (this.diag_test[i].test_type_name == "Urine Test") {
              for (var j = 0; j < this.diag_test[i].sub_tests.length; j++) {
                for (var k = 0; k < obj.urine_test.length; k++) {
                  if (this.diag_test[i].sub_tests[j].test_id == obj.urine_test[k]) {
                    this.diag_test[i].sub_tests[j].checked = true;
                  }
                }
              }
            }
          }
        }

        if (obj.faeces_test != undefined) {
          this.faeces_test = obj.faeces_test;
          for (var i = 0; i < this.diag_test.length; i++) {
            if (this.diag_test[i].test_type_name == "Faeces Test") {
              for (var j = 0; j < this.diag_test[i].sub_tests.length; j++) {
                for (var k = 0; k < obj.faeces_test.length; k++) {
                  if (this.diag_test[i].sub_tests[j].test_id == obj.faeces_test[k]) {
                    this.diag_test[i].sub_tests[j].checked = true;
                  }
                }
              }
            }
          }
        }

        if (obj.scan_test != undefined) {
          this.scan_test = obj.scan_test;
          for (var i = 0; i < this.diag_test.length; i++) {
            if (this.diag_test[i].test_type_name == "Scan") {
              for (var j = 0; j < this.diag_test[i].sub_tests.length; j++) {
                for (var k = 0; k < obj.scan_test.length; k++) {
                  if (this.diag_test[i].sub_tests[j].test_id == obj.scan_test[k]) {
                    this.diag_test[i].sub_tests[j].checked = true;
                  }
                }
              }
            }
          }
        }

        if (obj.ultrasound_test != undefined) {
          this.ultra_test = obj.ultrasound_test;
          for (var i = 0; i < this.diag_test.length; i++) {
            if (this.diag_test[i].test_type_name == "Ultra Sound") {
              for (var j = 0; j < this.diag_test[i].sub_tests.length; j++) {
                for (var k = 0; k < obj.ultrasound_test.length; k++) {
                  if (this.diag_test[i].sub_tests[j].test_id == obj.ultrasound_test[k]) {
                    this.diag_test[i].sub_tests[j].checked = true;
                  }
                }
              }
            }
          }
        }

        if (obj.xray_test != undefined) {
          this.xray_test = obj.xray_test;
          for (var i = 0; i < this.diag_test.length; i++) {
            if (this.diag_test[i].test_type_name == "X-ray") {
              for (var j = 0; j < this.diag_test[i].sub_tests.length; j++) {
                for (var k = 0; k < obj.xray_test.length; k++) {
                  if (this.diag_test[i].sub_tests[j].test_id == obj.xray_test[k]) {
                    this.diag_test[i].sub_tests[j].checked = true;
                  }
                }
              }
            }
          }
        }

        if (obj.culture_test != undefined) {
          this.culture = obj.culture_test;
          for (var i = 0; i < this.diag_test.length; i++) {
            if (this.diag_test[i].test_type_name == "Culture") {
              for (var j = 0; j < this.diag_test[i].sub_tests.length; j++) {
                for (var k = 0; k < obj.culture_test.length; k++) {
                  if (this.diag_test[i].sub_tests[j].test_id == obj.culture_test[k]) {
                    this.diag_test[i].sub_tests[j].checked = true;
                  }
                }
              }
            }
          }
        }

        if (obj.biopsy_test != undefined) {
          this.biopsy = obj.biopsy_test;
          for (var i = 0; i < this.diag_test.length; i++) {
            if (this.diag_test[i].test_type_name == "Biopsy") {
              for (var j = 0; j < this.diag_test[i].sub_tests.length; j++) {
                for (var k = 0; k < obj.biopsy_test.length; k++) {
                  if (this.diag_test[i].sub_tests[j].test_id == obj.biopsy_test[k]) {
                    this.diag_test[i].sub_tests[j].checked = true;
                  }
                }
              }
            }
          }
        }
      },
        error => { }
      )
  }

  getPackageDetails(selectedTests) {
    this.selected_Tests = [];
    this.selected_Tests = selectedTests;
    this.update_btn = false;
    this.save_btn = true;
    selectedTests.forEach(element => {
      this.blood_testObj.push({
        test_id: element.diag_tests_id,
        test_name: element.test_name,
        TAT: "",
      })
    });
    console.log("PACKAGE RETRIEVE " + JSON.stringify(this.selected_Tests))
  }

  savePackage(data) {
    var dept_name
    if (this.diag_dept == '1') {
      dept_name = 'Laboratory';
    } else {
      dept_name = 'Radiology';
    }

    var flag = true;
    if (this.pkg_name != undefined && this.pkg_price != undefined) {
      flag = true;
    } else {
      flag = false;
    }

    var sub_dept_name;
    for (var i = 0; i < this.test_category_data.length; i++) {
      if (this.test_category_data[i].diag_test_cat_id == this.diag_test_categ) {
        sub_dept_name = this.test_category_data[i].description;
      }
    }

    var params = {
      diag_centre_id: Helper_Class.getHospitalInfo().hptl_clinic_id,
      diag_profile_id: this.diag_packages_id,
      profile_name: this.pkg_name,
      price: this.pkg_price,
      package_desc: this.pkg_desc,
      department: dept_name,
      sub_department: sub_dept_name,
      "selected_test": this.blood_testObj,
    }
    console.log("JSON.stringify+JS" + JSON.stringify(params));

    if (flag == true) {
      var headers = new HttpHeaders()
      this.http.post(ipaddress.getIp + "diagnosiscontroller/sdpd",
        params, { headers: headers }).subscribe(response => {
          this.data = JSON.parse(JSON.stringify(response))
          if (this.data.key == "1") {
            this.toastr.success(this.data.result);
            this.diagnosisservice.sendMessage('testsandpackadv')
          } else {
            this.toastr.error("Something went wrong, try again later")
          }
        })
    } else {
      this.toastr.error("Enter the required fields")
    }
  }

  backClicked() {  //back
    this.diagnosisservice.sendMessage('testsandpackadv')
  }

  ChangeTestType(event, id, data, type, typeName, TAT) {
    if (event == true) {
      for (var k = 0; k < this.fullTest_list.length; k++) {
        if (this.fullTest_list[k].test_id == id) {
          this.fullTest_list[k].checked = true;
        }
      }
     
      if (this.package_details.is_new == 0) {
        this.update_btn = false;

      } else {
        this.save_btn = false;
      }

      var Tat_val

      if (TAT != undefined) {
        Tat_val = TAT + ' (mins)';
      } else {
        Tat_val = '';
      }

      this.blood_testObj.push({
        test_id: id,
        test_name: data,
        TAT: Tat_val
      })

      if (type == "Blood Test") {
        this.blood_test.push(id);


      } else if (type == "Scan") {
        this.scan_test.push(id);


      } else if (type == "Ultra Sound") {
        this.ultra_test.push(id);


      } else if (type == "X-ray") {
        this.xray_test.push(id);


      } else if (type == "Biopsy") {
        this.biopsy.push(id);


      } else if (type == "Urine Test") {
        this.urine_test.push(id);


      } else if (type == "Faeces Test") {
        this.faeces_test.push(id);


      } else if (type == "Culture") {
        this.culture.push(id);

      }

    } else if (event == false) {
      for (var k = 0; k < this.fullTest_list.length; k++) {
        if (this.fullTest_list[k].test_id == id) {
          this.fullTest_list[k].checked = false;
        }
      }
      // this.save_btn = true;
      // this.update_btn=true;
      for (var k = 0; k < this.fullTest_list.length; k++) {
        if (this.fullTest_list[k].checked == true) {
          if (this.package_details.is_new == 0) {
            this.update_btn = false;
          } else {
            this.save_btn = false;
          }
        }
      }

      for (var h = 0; h < this.blood_testObj.length; h++) {
        if (this.blood_testObj[h].test_id == id) {
          this.blood_testObj.splice(h, 1);
        }
      }

      if (type == "Blood Test") {
        this.blood_test.forEach(test => {
          if (test == id) {
            let index = this.blood_test.indexOf(id)
            this.blood_test.splice(index, 1);
          }
        });

        for (var h = 0; h < this.blood_testObj.length; h++) {
          if (this.blood_testObj[h].test_id == id) {
            this.blood_testObj.splice(h, 1);
          }
        }

      } else if (type == "Scan") {
        this.scan_test.forEach(test => {
          if (test == id) {
            let index = this.scan_test.indexOf(id)
            this.scan_test.splice(index, 1);
          }
        });

        for (var h = 0; h < this.scan_testObj.length; h++) {
          if (this.scan_testObj[h].test_id == id) {
            this.scan_testObj.splice(h, 1);
          }
        }

      } else if (type == "Ultra Sound") {
        this.ultra_test.forEach(test => {
          if (test == id) {
            let index = this.ultra_test.indexOf(id)
            this.ultra_test.splice(index, 1);
          }
        });

        for (var h = 0; h < this.ultra_testObj.length; h++) {
          if (this.ultra_testObj[h].test_id == id) {
            this.ultra_testObj.splice(h, 1);
          }
        }

      } else if (type == "X-ray") {
        this.xray_test.forEach(test => {
          if (test == id) {
            let index = this.xray_test.indexOf(id)
            this.xray_test.splice(index, 1);
          }
        });

        for (var h = 0; h < this.xray_testObj.length; h++) {
          if (this.xray_testObj[h].test_id == id) {
            this.xray_testObj.splice(h, 1);
          }
        }

      } else if (type == "Biopsy") {
        this.biopsy.forEach(test => {
          if (test == id) {
            let index = this.biopsy.indexOf(id)
            this.biopsy.splice(index, 1);
          }
        });

        for (var h = 0; h < this.biopsy_testObj.length; h++) {
          if (this.biopsy_testObj[h].test_id == id) {
            this.biopsy_testObj.splice(h, 1);
          }
        }

      } else if (type == "Urine Test") {
        this.urine_test.forEach(test => {
          if (test == id) {
            let index = this.urine_test.indexOf(id)
            this.urine_test.splice(index, 1);
          }
        });

        for (var h = 0; h < this.urine_testObj.length; h++) {
          if (this.urine_testObj[h].test_id == id) {
            this.urine_testObj.splice(h, 1);
          }
        }

      } else if (type == "Faeces Test") {
        this.faeces_test.forEach(test => {
          if (test == id) {
            let index = this.faeces_test.indexOf(id)
            this.faeces_test.splice(index, 1);
          }
        });

        for (var h = 0; h < this.faeces_testObj.length; h++) {
          if (this.faeces_testObj[h].test_id == id) {
            this.faeces_testObj.splice(h, 1);
          }
        }

      } else if (type == "Culture") {
        this.culture.forEach(test => {
          if (test == id) {
            let index = this.culture.indexOf(id)
            this.culture.splice(index, 1);
          }
        });

        for (var h = 0; h < this.culture_testObj.length; h++) {
          if (this.culture_testObj[h].test_id == id) {
            this.culture_testObj.splice(h, 1);
          }
        }
      }
    }
  }

  getDiagnosisTests() {
    console.log("check")
    this.diag_test = [];
    var order_by;
    if (this.showTest_type == "0") {
      order_by = "sub_dept";
    } else {
      order_by = "test_type";
    }

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/dtsubn/',
      {
        diag_centre_id: Helper_Class.getHospitalInfo().hptl_clinic_id,
        orderby: order_by,
      },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          if (obj.diag_tests.length == 0) {
            this.test_flag = true;
          } else {
            this.test_flag = false;
          }

          this.diag_test = obj.diag_tests;
          for (var k = 0; k < this.diag_test.length; k++) {
            this.testtypename[k] = this.diag_test[k].test_type_name;
            for (var j = 0; j < this.diag_test[k].sub_tests.length; j++) {
              this.diag_test[k].sub_tests[j] = {
                test_id: this.diag_test[k].sub_tests[j].test_id,
                test_name: this.diag_test[k].sub_tests[j].test_name,
                test_type: this.diag_test[k].sub_tests[j].test_type,
                checked: false
              }
            }
          }
        },
        error => { }
      )
  }

  getTestByName(testName, diag_test_categ) {
    if (this.diag_test_categ != undefined) {
      for (var i = 0; i < this.test_category_data.length; i++) {
        if (this.diag_test_categ == this.test_category_data[i].diag_test_cat_id) {
          this.diag_test_categ = this.test_category_data[i].diag_test_cat_id;
          this.diag_test_categ_desc = this.test_category_data[i].description;
        }
      }
    }

    var order_by;
    if (this.showTest_type == "0") {
      order_by = "sub_dept";
    } else {
      order_by = "test_type";
    }
    var send_data;
    if (testName.length > 2) {
      send_data = {
        orderby: order_by,
        test_name: testName,
        test_type_id: diag_test_categ,
        diag_centre_id: Helper_Class.getHospitalInfo().hptl_clinic_id,
      }

    } else {
      send_data = {
        orderby: order_by,
        test_type_id: diag_test_categ,
        diag_centre_id: Helper_Class.getHospitalInfo().hptl_clinic_id,
      }
    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/gtbc/', send_data,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log("VALUES ARE " + JSON.stringify(obj))
          this.fullTest_list = obj.diag_tests;
          console.log("SELECTED TESTS " + JSON.stringify(this.blood_testObj))
          console.log("ALL TESTS  " + JSON.stringify(this.fullTest_list))
          if (this.package_details != undefined) {
            for (var i = 0; i < this.blood_testObj.length; i++) {
              for (var j = 0; j < this.fullTest_list.length; j++) {

                if (this.blood_testObj[i].test_id == this.fullTest_list[j].test_id) {
                  this.fullTest_list[j].checked = true;
                  if (this.fullTest_list[j].totalTAT != undefined) {
                    this.blood_testObj[i].TAT = this.fullTest_list[j].totalTAT + ' (mins)';
                  }
                  // this.blood_testObj.push({
                  //   test_id: this.fullTest_list[j].test_id,
                  //   test_name: this.fullTest_list[j].test_name,
                  //   TAT: Tat_val 
                  // })
                }
              }
            }
          }

        });
  }

}
