import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CasesheetPersonalComponent } from './casesheet-personal/casesheet-personal.component';
import { DoctorAppListComponent } from './doctor-app-list/doctor-app-list.component';

import { DoctorViewComponent } from './doctor-view/doctor-view.component';
import { GeneralPersonalComponent } from './general-personal/general-personal.component';
import { InpatPatientInfoComponent } from './inpat-patient-info/inpat-patient-info.component';
import { DoctorhomeComponent } from './doctorhome/doctorhome.component';
import { NoappointmentComponent } from './noappointment/noappointment.component';
import { DoctorbilllistpageComponent } from './doctorbilllistpage/doctorbilllistpage.component';
import { BillCreateComponent } from './bill-create/bill-create.component';
import { BillDetailViewComponent } from './bill-detail-view/bill-detail-view.component';
import { InpatDischargeSummaryComponent } from './inpat-discharge-summary/inpat-discharge-summary.component';
import { InpatDischargeViewComponent } from './inpat-discharge-view/inpat-discharge-view.component';
import { InpatDashboardComponent } from './inpat-dashboard/inpat-dashboard.component';
import { InpatPatientListComponent } from './inpat-patient-list/inpat-patient-list.component';
import { HelpComponent } from './help/help.component';
import { DoctorNotificationComponent } from './doctor-notification/doctor-notification.component';
import { CasesheetComponent } from './casesheet/casesheet.component';
import { DoctorScheduleListPageComponent } from './doctor-schedule-list-page/doctor-schedule-list-page.component';
import { DoctorAvailableNonavailableScedulePageComponent } from './doctor-available-nonavailable-scedule-page/doctor-available-nonavailable-scedule-page.component';
import { DoctorManageScheduleMainComponent } from './doctor-manage-schedule-main/doctor-manage-schedule-main.component';
import { DoctorProfileUpdateComponent } from './doctor-profile-update/doctor-profile-update.component';
import { DropdownModelpageComponent } from './dropdown-modelpage/dropdown-modelpage.component';
import {DocAppCreateComponent} from './doc-app-create/doc-app-create.component';

const routes: Routes = [
    //  //doctor module
    {path: 'Doctor', component: DoctorViewComponent},
    {path: 'GeneralPersonal', component: GeneralPersonalComponent},
    {path: 'inpatient_list', component: InpatPatientListComponent},
    {path: 'patient-list', component: DoctorAppListComponent},
    {path: 'patient-list', component: CasesheetPersonalComponent},
    {path: 'doctor-home', component: DoctorhomeComponent},
    {path:'Walk_in',component:NoappointmentComponent},
    {path:'billlist',component:DoctorbilllistpageComponent},
    {path:'billcreate',component:BillCreateComponent},
    {path:'billcreate',component:BillCreateComponent},
    {path:'billdetail',component:BillDetailViewComponent},
    {path:'help',component:HelpComponent},
    {path:'doctor_notification',component:DoctorNotificationComponent},
    {path:'inpatient_information',component:InpatPatientInfoComponent},
    {path:'inpatient_Discharge_summary',component:InpatDischargeSummaryComponent},
    {path:'inpatient_Dashboard',component:InpatDashboardComponent},
    {path:'inpatient_Discharge_view',component:InpatDischargeViewComponent},
    {path: 'casesheet', component: CasesheetComponent},
    {path:'doctorschedule',component:DoctorScheduleListPageComponent},
    {path:'doctoravailability',component:DoctorAvailableNonavailableScedulePageComponent},
    {path:'manageschedule',component:DoctorManageScheduleMainComponent},
    {path:'DoctorUpdateProfile',component:DoctorProfileUpdateComponent},
    {path: 'docappcreate', component: DocAppCreateComponent},
    {path: 'openModelPage', component: DropdownModelpageComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DoctorRoutingModule { }
