<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            
            <h5 class="m-0" class="mainHeadingStyle">{{pagetype}}</h5>
          </div>
          <div class="headerButtons">
            <img src="../../../assets/ui_icons/buttons/Back_button.svg" [hidden]="backbtn" class="saveimgbtn_inpatinfo"
              (click)="backButton()" />
              
            <img src="../../../assets/ui_icons/buttons/Back_button.svg" [hidden]="backbuttonest"
              class="saveimgbtn_inpatinfo" (click)="backButtonest()" />

            <img *ngIf="saveFlag" printSectionId="print" src="../../../assets/img/printer.svg"
              class="saveimgbtn_inpatinfo" style="width:25px" (click)="printArea()">

			      <img *ngIf="saveFlag" src="../../../assets/ui_icons/buttons/save_button.svg" class="saveimgbtn_inpatinfo"
              (click)="saveOrder('noprint')" />
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row">
          <div class="col-12 col-sm-6 col-md-12 col-lg-12 col-xl-12" [hidden]="patient_detailsflag">
            <div class="cover_div">
                  
              <div class="header_lable">Patient Details</div>
              <div class="content_cover">
                <div class="row">
                  <div class="col-3">
                    <p class="textformat"> <span style="font-weight: 500;">MR No: </span> {{mrnumber}} </p>
                  </div>
                  <div class="col-3">
                   <p class="textformat"> <span style="font-weight: 500;"> Patient name: </span> <span
                        [hidden]="clientsalflag">{{clntSal}}. </span> {{clientname}} </p>
                  </div>
                  <div class="col-3">
                    <p class="textformat"> <span style="font-weight: 500;">Age / Gender: </span> {{clntAge}} /
                      {{headergen}}</p>
                  </div>
                  <div class="col-3">
                    <p class="textformat"> <span style="font-weight: 500;">DOB: </span>{{clntdob | date : "dd-MM-yyyy"}}
                    </p>
                  </div>
                  <div class="col-3">
                    <p class="textformat"> <span style="font-weight: 500;">IP/OP: </span> {{ipop}} </p>
                  </div>
                  <div class="col-3">
                    <p class="textformat"> <span style="font-weight: 500;">Mobile: </span> {{clntContNumber}}</p>
                  </div>
                  <div class="col-3">
                    <p class="textformat"> <span style="font-weight: 500;">Doctor: </span> <span
                        [hidden]="docsalflag">{{docsal}}</span> {{doctorName}}</p>
                  </div>
                  <div class="col-3" *ngIf="fromprescflag">
                    <p class="textformat"> <span style="font-weight: 500;">Ward name: </span> {{Ward_name}}</p>
                  </div>
                  <div class="col-3" *ngIf="fromprescflag">
                    <p class="textformat"> <span style="font-weight: 500;">Bed no: </span> {{Bed_no}}</p>
                  </div>
                  <div class="col-3" *ngIf="fromprescflag">
                    <p class="textformat"> <span style="font-weight: 500;">Admission date & time: </span> <span
                        style="font-size:12px">{{admissiondate | date : "dd-MM-yyyy"}}/{{admission_time}}</span></p>
                  </div>
                  <div class="col-3" *ngIf="this.pagetype == 'Rx Bill'">
                    <p class="textformat"> <span style="font-weight: 500;">Token no: </span> {{tokenno}}</p>
                  </div>
                      
                  <div class="col-6 inpatinfopadding"><span style="font-weight: 500;">Address: </span> {{clntAddress1}}
                    {{clntAddress2}} {{clntLocation}} {{clntCity}} {{clntZipcode}} {{clntState}}</div>
                  <div class="col-12 col-sm-6 col-md-4 col-xl-6 col-lg-6" *ngIf='this.pagetype == "Rx Bill"'>
                    <!-- <mat-label class="matlabel">Rate plan<br>
                          <select disableOptionCentering class="ipcss " required [(ngModel)]="discountType"
                            (change)="discountChange(discountType)">
                            <option disabled>Select</option>
                            <option *ngFor="let disc of discountDatasArray" value="{{disc.disc_type_id}}">{{disc.description}}
                            </option>
                          </select>
                        </mat-label> -->

                    <div class="row">
                      <div class="col-2">
                        <span> <span style="font-weight: 500;">Rate plan: </span> </span>
                      </div>
                      <div class="col-6">
                         
                        <select style="width:125px" disableOptionCentering class="ipcss " required
                          [(ngModel)]="discountType" (change)="discountChange(discountType)">
                          <option disabled>Select</option>
                          <option *ngFor="let disc of discountDatasArray" value="{{disc.disc_type_id}}">
                            {{disc.description}} ({{disc.discount_type}})</option>
                        </select>


                        <!-- <mat-label class="matlabel">Rate plan: <br>
                            <select disableOptionCentering class="ipcss " required [(ngModel)]="discountType"
                              (change)="discountChange(discountType)">
                              <option disabled>Select</option>
                              <option *ngFor="let disc of discountDatasArray" value="{{disc.disc_type_id}}">{{disc.description}}
                              </option>
                            </select>
                          </mat-label> -->



                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
         

          <div class="col-12 col-sm-6 col-md-4 col-xl-2 col-lg-2" *ngIf='this.pagetype != "Rx Bill"'>
            <mat-label class="matlabel">Search type<br>
              <select disableOptionCentering class="ipcss" [(ngModel)]="search_type" (change)="searchtype(search_type)">
                <option disabled>Select</option>
                <option value="name">Name</option>
                <option value="mrnumber">MR number</option>
              </select>
            </mat-label>
          </div>

          <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2" *ngIf='retrivemr'>
            <mat-label class="matlabel"> MR number <br>
              <input type="text" class="ipcss order_width getMedcien" required maxlength="50"
                (keyup)="name.length >= 3 && handlekeyUp($event)" maxlength="50" [(ngModel)]="name" matInput
                [matAutocomplete]="mrnumauto3" />
            </mat-label>
            <mat-autocomplete #mrnumauto3="matAutocomplete" [panelWidth]="'auto'">
              <mat-option (click)="retrieveClient(patient)" *ngFor="let patient of patientArray"
                value="{{patient.patient_name}}">
                <div>{{patient.patient_name}} <span>(</span>{{patient.gender}} - {{patient.age}}<span>)</span></div>
              </mat-option>
            </mat-autocomplete>
          </div>
          <!-- getPatientListByName($event) -->
          <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2" *ngIf='retrivenameflag'>
            <mat-label class="matlabel"> Name <br>
              <input type="text" class="ipcss order_width getMedcien" required maxlength="50"
                (keyup)="name.length >=3 && handlekeyUp($event)" maxlength="50" [(ngModel)]="name" matInput
                [matAutocomplete]="autoordercrete" (focusout)="onFocusOutname()" />
            </mat-label>
            <mat-autocomplete #autoordercrete="matAutocomplete" [panelWidth]="'auto'">
              
              <mat-option (click)="retrieveClient(patient)" *ngFor="let patient of patientArray"
                value="{{patient.patient_name}}">
                <div>{{patient.patient_name}} <span>(</span> {{patient.gender}} - {{patient.age}}<span>)</span> </div>
              </mat-option>
            </mat-autocomplete>
          </div>

          <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2" *ngIf='this.pagetype != "Rx Bill"'>
            <mat-label class="matlabel">Doctor<br>
             
              <input type="text" class="ipcss" maxlength="50"
                (keyup)="doctorName.length >=3 &&handleKeydoctorUp($event)" maxlength="50" [(ngModel)]="doctorName"
                matInput [matAutocomplete]="auto1" />
              <mat-autocomplete #auto1="matAutocomplete" [panelWidth]="'auto'">
                <mat-option (click)="Select_refer_doctor(item)" *ngFor="let item of ReferedDoctorArray"
                  value="{{item}}">
                  {{item}}
                </mat-option>
              </mat-autocomplete>
            </mat-label>
          </div>

          <div class="col-12 col-sm-6 col-md-4 col-lg-1 col-xl-1"
            style="margin-top: auto;margin-bottom:auto;width: 50px;" *ngIf='this.pagetype != "Rx Bill"'>
            <img src="../../../assets/img/Page_icon/Info_icon.png" style="width:25px;position: relative;
            top: 10px;" data-bs-toggle="modal" data-bs-target="#profilePicModal" (click)="patientdetail()">
          </div>
          <div class="modal fade" id="profilePicModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-md">
            
              <div class="modal-content" style="width:165%;position: relative;right: 100px">
                <div class="modal-header">
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                  </button>
                </div>
                <div class="modal-body">
                  <div class="col-12">
                    <div class="patientdetails-responsive">
                      <table class="patientdetailstable">
                        <thead>
                          <tr style="background-color:#c1e6fb;color: rgba(0,0,0,.54);min-height: 56px;">
                            <th style="width:15%">MR number</th>
                            <th style="width:25%">Patient name</th>
                            <th style="width:10%">IP/OP</th>
                            <th style="width:15%">Mobile no</th>
                            <th style="width:17%">Age</th>
                            <th style="width:22%">Doctor</th>
                          </tr>
                        </thead>
                        <tbody>
                         
                          <tr *ngFor="let details of patientdetailsarray" (click)="patientdetailslist(details)"
                            data-bs-dismiss="modal" aria-label="Close">
                            <td>{{details.mrno}}</td>
                            <td>{{details.name}}</td>
                            <td>{{details.op_ip}}</td>
                            <td>{{details.mobile}}</td>
                            <td>{{details.age}}</td>
                            <td>{{details.dr_name}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2" *ngIf='this.pagetype != "Rx Bill"'>
            <mat-label class="matlabel">Date<br>
              <input type="date" class="ipcss" max="{{todayDate}}" (change)="changeOrderDate(orderDate)"
                [(ngModel)]="orderDate" #matInput>
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 select_bottom" [hidden]="!paytypreq">
            <mat-label class="matlabel">Payment type<br>
              <mat-select id="paytype" class="ipcss  widthbillcreate" [(ngModel)]="payType1"
                (selectionChange)="changePay()" disableOptionCentering>
                <mat-option *ngFor="let pay of payTypeListArrray" value={{pay.pay_id}}>{{pay.pay_desc}}</mat-option>
              </mat-select>
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2" [hidden]="insurFlag">
            <mat-label class="matlabel">Insurer<br>
              <mat-select required class="ipcss  widthbillcreate" [(ngModel)]="insurerID" disableOptionCentering>
                <mat-option *ngFor="let ins of insurerArray" value={{ins.insurer_id}}>{{ins.insurer_desc}}</mat-option>
              </mat-select>
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2" [hidden]="cardFlag">
            <mat-label class="matlabel">Card No <br>
              <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardNumber" />
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2" [hidden]="cardFlag">
            <mat-label class="matlabel">Holder name<br>
              <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardHolderName" />
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2" [hidden]="tranFlag">
            <mat-label class="matlabel">Transaction ID<br>
              <input class="ipcss  widthbillcreate" matInput [(ngModel)]="TransactionID" />
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2" [hidden]="chequeFlag">
            <mat-label class="matlabel">Cheque No <br>
              <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardNumber" />
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2" [hidden]="chequeFlag">
            <mat-label class="matlabel">Bank Name <br>
              <input class="ipcss  widthbillcreate" matInput [(ngModel)]="bankName" />
            </mat-label>
          </div>

          <div class="col-12 col-sm-6 col-md-4 col-xl-2 col-lg-2" *ngIf='this.pagetype != "Rx Bill"'>
            
            <mat-label class="matlabel">Rate plan <br>
              <select disableOptionCentering class="ipcss " required [(ngModel)]="discountType"
                (change)="discountChange(discountType)">
                <option disabled>Select</option>
                <option *ngFor="let disc of discountDatasArray" value="{{disc.disc_type_id}}">{{disc.description}}
                  ({{disc.discount_type}})
                </option>
              </select>
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-xl-3 col-lg-3" *ngIf='this.pagetype != "Rx Bill"'>
            <mat-label class="matlabel" for="customFile">Prescription file choosen<br>
              <input type="file" class="ipcss form-control" id="customFile" />
            </mat-label>
          </div>
        </div>

        <div class="row" style="margin-top:5px;padding: 0 10px 0 10px;">
          <div id="pharma_tbl_overlay" class="dig_table_overflow table-responsive"
            style="height: 210px;overflow-x: scroll;">
            <table id="tbl_med" class="table table-hover table-dynamic" style="font-size: 11px">
              <thead>
                <tr>
                  <th style="width: 45px;z-index:100">Si.No</th>
                  <th style="width: 250px;" *ngIf="genericDisplayFlag">Generic name</th>
                  <!-- <th style="width: 250px;">HSN NO</th> -->
                  <th style="width: 250px;">Medicine name</th>
                  <th style="width: 70px;">Batch no</th>
                  <th style="width: 70px;" [hidden]="rack_binrequire">Rack</th>
                  <th style="width: 70px;" [hidden]="rack_binrequire">Bin</th>
                  <th style="width: 90px;">Price</th>
                  <th style="width: 70px;" [hidden]="lineitmdisflag">Discount</th>
                  <th style="width: 130px;">Duration</th>
                  <th style="width: 133px;">{{'Intake'}}</th>
                  <th style="width: 50px;">{{'Min'}}</th>
                  <th style="width: 20px;">{{"In-take Session"}}</th>
                  <th style="width: 70px;">Quantity</th>
                  <th style="width: 100px;">Amount</th>
                  <th style="width: 45px;z-index:100"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let drug of drugDetailsArray;let i = index" class="tdvalues">
                  <th>{{i + 1}} </th>
                  <td *ngIf="genericDisplayFlag">
                    <input type="text" class="ipcss order_width getMedcien" maxlength="50"
                      (keyup)="changeMedicineName($event,'generic')" maxlength="50" [(ngModel)]="drug.gen_name" matInput
                      [matAutocomplete]="auto3" />

                    <mat-autocomplete #auto3="matAutocomplete" [panelWidth]="'auto'">
                      <mat-option (click)="selectMedicineItem(medicine,drug)" *ngFor="let medicine of MedicneData"
                        value="{{medicine.name}}">
                        <div [ngStyle]="{'color' : medicine.quantity == '0' ? '#FFA07A' : '#000' }">{{medicine.name}}
                        </div>
                      </mat-option>
                    </mat-autocomplete>
                  </td>
					        <td>
                    <input type="text" class="ipcss order_width getMedcien" required maxlength="50"
                      
                      (keyup)="drug.drug_name.length >=3 && handleKeymedicineUp($event,drug.drug_name,drug)"
                      maxlength="50" [(ngModel)]="drug.drug_name" matInput [matAutocomplete]="auto2" />
                    <mat-autocomplete #auto2="matAutocomplete" [panelWidth]="'auto'">
                      <mat-option (click)="selectMedicineItem(medicine,drug)" *ngFor="let medicine of MedicneData"
                        value="{{medicine.name}}">
                        <div [ngStyle]="{'color' : medicine.quantity == '0' ? '#FFA07A' : '#000' }">{{medicine.name}}
                       
                        </div>
                      </mat-option>
                    </mat-autocomplete>
                  </td>
                  <td>
                    <input type="text" class="ipcss order_width" [ngStyle]="drug.batch_required"
                      [(ngModel)]="drug.batch_no" matInput maxlength="50" readonly autocomplete="on"
                      (click)="getCORD($event,'drug_'+drug.index);drug.show_batcharray = !drug.show_batcharray" />
                    <div [id]="'drug_'+drug.index" class="auto_complete_text1" *ngIf="drug.batcharray.length != 0"
                      style="display: none;">
                      <table style="width: 100%;">
                        <thead>
                          <tr>
                            <th> Batch no</th>
                            <th> Qty </th>
                            <th> MRP </th>
                            <th> Exp Date </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let batch of drug.batcharray"
                            (click)="selectedBatch(batch,drug,'drug_'+drug.index)">
                            <td [ngStyle]="{'color':batch.color}">{{batch.batch_no}}</td>
                            <td [ngStyle]="{'color':batch.color}">{{batch.quantity}}</td>
                            <td [ngStyle]="{'color':batch.color}">{{batch.mrp}}</td>
                            <td [ngStyle]="{'color':batch.color}">{{batch.expiry_date}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    
                  </td>

                  <td [hidden]="rack_binrequire">
                    <input type="text" class="ipcss order_width"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="4" required
                      [(ngModel)]="drug.rack_id" (keyup.enter)="createRow(drug)" #matInput />
                  </td>
                  <td [hidden]="rack_binrequire">
                    <input type="text" class="ipcss order_width"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="4" required
                      [(ngModel)]="drug.bin_id" (keyup.enter)="createRow(drug)" #matInput />
                  </td>

                  <td>
                    <!-- (keyup)="calculateamount(drug)" -->
                    <input type="text" style="text-align: right;" class="ipcss order_width" [disabled]="true"
                     [(ngModel)]="drug.price"
                      #matInput  [disabled]="priceedit == 0" (keyup.enter)="createRow(drug)" />
                  </td>

                  <td [hidden]="lineitmdisflag">
                    <!-- [readonly]="!discoutFlag" -->
                    <input type="text" class="ipcss order_width" maxlength="3" [(ngModel)]="drug.discount" 
                      (keyup)="discountcalculatestaff()" #matInput  (keyup.enter)="createRow(drug)"/>
                  </td>

                  <td>
                    <div style="display: flex;">

                      <input type="text" style="width: 40px;" class="ipcss order_width" matInput
                        [required]="drug.day_txtflag" [readonly]="drug.show_intakeFlag" maxlength="5"
                        onkeypress="return event.charCode >= 48 && event.charCode <= 57" [(ngModel)]="drug.day_txt"
                       
                        (keyup.enter)="createRow(drug)" (keyup)="sessionqty(drug)" />&nbsp;

                      <mat-select disableOptionCentering class="ipcss  order_width" [disabled]="drug.show_intakeFlag"
                        (selectionChange)="Duration()" [(ngModel)]="drug.daydur_txt" style="width: 90px;">
                        <mat-option disabled>Select</mat-option>
                        <mat-option value="day(s)">day(s)</mat-option>
                        <mat-option value="week(s)">week(s)</mat-option>
                        <mat-option value="month(s)">month(s)</mat-option>
                      </mat-select>
                    </div>
                  </td>

                  <td>
                    <mat-select disableOptionCentering class="ipcss order_width" required
                      (selectionChange)="changeIntake(drug)" [(ngModel)]="drug.afterfood_txt"
                      [disabled]="drug.show_intakeFlag">
                      <mat-option value="Select">Select</mat-option>
                      <mat-option value="Empty stomach">Empty stomach</mat-option>
                      <mat-option value="After food">After food</mat-option>
                      <mat-option value="Before food">Before food</mat-option>
                      <mat-option value="With food">With Food</mat-option>
                    </mat-select>
                  </td>

                  <td>
                    <input type="text" class="ipcss  order_width" matInput [required]="drug.dure_require"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="3"
                      [(ngModel)]="drug.dure_txt" [readonly]="drug.show_intakeFlag" />
                  </td>

                  <td>
                    <div style="display: flex;" [disabled]="drug.show_intakeFlag">
                      <mat-select disableOptionCentering class="ipcss order_width" required
                        [(ngModel)]="drug.intake_txt" style="width: 164px;" [disabled]="drug.show_intakeFlag">
                        <mat-option disabled>Select</mat-option>
                        <mat-option *ngFor="let intake of getIntakeArray" (click)="sesssionBaseChange(drug)"
                          value={{intake.description}}>
                          {{intake.description}}</mat-option>
                      </mat-select>&nbsp;

                      <div class="divClass">
                        <input type="text" [hidden]="drug.mor_lable" type="text" class="inputClass order_width" matInput
                          [required]="morningRequset" [(ngModel)]="drug.morning" [disabled]="drug.morning_dis"
                          [readonly]="drug.show_intakeFlag" maxlength="5"
                          onkeypress="return event.charCode == 32 || event.charCode == 46 || event.charCode == 47 || (event.charCode >= 48 && event.charCode <= 57)"
                          (blur)="focusFunction($event,drug);sessionqty(drug)">

                        <input type="text" [hidden]="drug.quan_lable" type="text" class="inputClass order_width"
                          matInput [required]="drug.morning_req" maxlength="5"
                          onkeypress="return event.charCode == 32 || event.charCode == 46 || event.charCode == 47 || (event.charCode >= 48 && event.charCode <= 57)"
                          [disabled]="drug.morning_dis" [(ngModel)]="drug.morning"
                          (blur)="focusFunction($event,drug);sessionqty(drug)" [readonly]="drug.show_intakeFlag">

                        <span [hidden]="drug.after_eventxt"> - </span>

                        <input type="text" class="inputClass order_width" matInput [disabled]="drug.afternoon_dis"
                          maxlength="5" [hidden]="drug.after_eventxt"
                          onkeypress="return event.charCode == 32 || event.charCode == 46 || event.charCode == 47 || (event.charCode >= 48 && event.charCode <= 57)"
                          [required]="drug.afternoon_req" [(ngModel)]="drug.afternoon"
                          (blur)="focusFunction($event,drug);sessionqty(drug)" [readonly]="drug.show_intakeFlag">

                        <span [hidden]="drug.eve_eventxt"> - </span>

                        <input type="text" [hidden]="drug.eve_eventxt" class="inputClass order_width" matInput
                          [disabled]="drug.even_dis" [required]="drug.evening_req" maxlength="5"
                          onkeypress="return event.charCode == 32 || event.charCode == 46 || event.charCode == 47 || (event.charCode >= 48 && event.charCode <= 57)"
                          [(ngModel)]="drug.evening" (blur)="focusFunction($event,drug);sessionqty(drug)"
                          [readonly]="drug.show_intakeFlag">

                        <span [hidden]="drug.night_eventxt"> - </span>

                        <input type="text" [hidden]="drug.night_eventxt" class="inputClass order_width" matInput
                          [disabled]="drug.ngt_dis" [required]="drug.night_req" maxlength="5"
                          onkeypress="return event.charCode == 32 || event.charCode == 46 || event.charCode == 47 || (event.charCode >= 48 && event.charCode <= 57)"
                          [(ngModel)]="drug.night" (blur)="focusFunction($event,drug);sessionqty(drug)"
                          [readonly]="drug.show_intakeFlag">
                      </div>
                    </div>
                  </td>

                  <td>
                    <input type="text" class="ipcss order_width"
                     
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="4"
                      
                      (keyup.enter)="createRow(drug)" required [(ngModel)]="drug.quantity"
                      #matInput />
                  </td>

                  <td>
                    <input type="text" style="text-align: right;" class="ipcss order_width" [(ngModel)]="drug.amount"
                      #matInput disabled />
                  </td>

                  <td style="text-align: center;width: 20px;">
                    <a (click)="deleteMedicine(drug.drug_id,drug.index)">
                      <img src="../../../assets/ui_icons/buttons/trash_icon.svg" width="20" height="20" /></a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- Inwards -->
        <div style="margin-top:15px">
          <div>
            <img class="pluseIcon" src="../../../assets/ui_icons/Add_icon.svg" *ngIf="showaddbutton"
              (click)="createReturns()" />
              

            <img class="pluseIcon" src="../../../assets/ui_icons/Minus.svg" *ngIf="hiddeaddbutton"
              
              (click)="hideReturns()" />Inwards
          </div>

          <div *ngIf="showreturns">
            <div class="row" style="width:100%;margin-top:10px">
              <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2 select_bottom">
                <mat-label class="matlabel">Filter by<br>
                    
                  <select required class="ipcss" [(ngModel)]="filter" required (change)="changeFilter()" disableOptionCentering>
                    <option value="bill_no">Bill no</option>
                    <option value="date">Date</option>
                    <option value="Name">Name</option>
                    <option value="Patient_ID">MR.No</option>
                    <option value="Mobile_No">Mobile No</option>
                  </select>
                </mat-label>
              </div>

              <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2 select_bottom">
                <mat-label class="matlabel" [hidden]="BillnoFlag">Bill no<br>
                 
                  <input type="text" [(ngModel)]="retivebillnodata" class="ipcss" />
                </mat-label>

                <mat-label class="matlabel" [hidden]="toFlag">Date<br>
                  <input type="date" class="ipcss widthbilllist datecss" (change)="selectFromDate(fromDate)"
                    [hidden]="toFlag" [(ngModel)]="fromDate" #matInput max="{{todayDate}}">
                </mat-label>

                <mat-label class="matlabel" [hidden]="firstNameFlag">First name<br>
                  <input matInput class="ipcss widthbilllist" type="text" [hidden]="firstNameFlag" required
                    [(ngModel)]="firstName" (blur)="fnameToUpper()" style="margin-bottom: 10px;" />
                </mat-label>

                <mat-label class="matlabel" [hidden]="uhidFlag">MR.No<br>
                  <input matInput class="ipcss widthbilllist" type="text" [hidden]="uhidFlag" required
                    [(ngModel)]="patientId" />
                </mat-label>

                <mat-label class="matlabel" [hidden]="mobileFlag">Mobile No<br>
                  <input matInput class="ipcss widthbilllist" type="text" [hidden]="mobileFlag" required
                    [(ngModel)]="mobileNo" />
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2 select_bottom" [hidden]="firstNameFlag">
                <mat-label class="matlabel" [hidden]="firstNameFlag">Last name<br>
                  <input matInput class="ipcss widthbilllist" type="text" [hidden]="firstNameFlag" required
                    [(ngModel)]="lastName" (blur)="lnameToUpper()" />
                </mat-label>
              </div>
              <div class="col-1  p-0">
                <mat-label class="matlabel">
                  <a>
                     
                    <img src="../../assets/img/search.png" *ngIf="this.filter == 'bill_no'"
                      (click)="retrivebillno(this.retivebillnodata)" class="seacrhicon" width="25" height="auto"
                      class="searchIcon" style="position:relative;top:20px" />
                    <img src="../../assets/img/search.png" *ngIf="this.filter != 'bill_no'" (click)="searchBill()"
                      class="seacrhicon" width="25" height="auto" class="searchIcon" data-bs-toggle="modal"
                      data-bs-target="#billdetails" style="position:relative;top:20px" />
                  </a>
                </mat-label>
              </div>

              <div class="modal fade" id="billdetails" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div class="modal-dialog modal-md">
                  <div class="modal-content" style="width:155%">
                    <div class="modal-header">
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                      </button>
                    </div>
                    <div class="modal-body">
                      <div class="col-12">
                        <div class="patientdetails-responsive">
                          <table class="patientdetailstable" *ngIf="billList.length != 0">
                            <thead>
                              <tr style="background-color:#c1e6fb;color: rgba(0,0,0,.54);min-height: 56px;">
                                <th class="delete">Bill no</th>
                                <th>Date</th>
                                <th>Client name</th>
                                <!-- <th>Pay type</th> -->
                                <th>Amount</th>
                                <th>Paid</th>
                                <th>Balance</th>
                                <th>Status</th>
                              </tr>
                            </thead>
                            <tbody>
                                
                              <tr *ngFor="let bill of billList" (click)="retrivebillno(bill.bill_id)"
                                data-bs-dismiss="modal" aria-label="Close">
                                <td>{{bill.bill_id}}</td>
                                <td>{{bill.date}}</td>
                                <td>{{bill.salutation}} {{bill.client_name}}</td>
                                <!-- <td >{{bill.pay_type}}</td> -->
                                <td>{{bill.bill_amount}}</td>
                                <td>{{bill.paid_amount}}</td>
                                <td>{{bill.balance}}</td>
                                    
                                <td>{{bill.paid_flag}}</td>
                              </tr>
                            </tbody>
                          </table>
                          <p class="nodata" *ngIf="billList.length == 0">No Inwards found</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            
            </div>

            <div class="row" style="margin-top:5px;padding: 0 10px 0 10px;">
              <div id="pharma_tbl_overlay" class="dig_table_overflow table-responsive" style="height: 210px;">
                <table id="tbl_med" class="table table-hover table-dynamic" style="font-size: 11px;">
                  <thead>
                    <tr>
                      <th style="width: 45px;">Si.No</th>
                      <th style="width: 250px;" *ngIf="genericDisplayFlag">Generic name</th>
                      <th style="width: 250px;">Medicine name</th>
                      <th style="width: 70px;">Batch</th>
                      <th style="width: 70px;">Qty</th>
                      <th style="width:60px;">Racks</th>
                      <th style="width:60px;">Bins</th>
                      <th style="width:50px;">Expiry</th>
                      <th style="width: 90px;">Price</th>
                      <th style="width: 70px;" [hidden]="lineitmdisflag">Discount</th>
                      <th style="width: 100px;">Amount</th>
                      <th style="width: 20px;"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let drug of returnDetailsArray;let i = index" class="tdvalues">
                      <th style="width: 45px;">{{i + 1}} </th>
                      <td *ngIf="genericDisplayFlag">
                        <input type="text" class="ipcss order_width getMedcien" maxlength="50"
                         
                          (keyup)="changeMedicineNamereturns($event,'generic')" maxlength="50"
                          [(ngModel)]="drug.gen_name" matInput [matAutocomplete]="auto3" />

                        <mat-autocomplete #auto3="matAutocomplete" [panelWidth]="'auto'">
                          
                          <mat-option (click)="selectMedicineItemreturns(medicine,drug)"
                            *ngFor="let medicine of MedicneDatareturns" value="{{medicine.name}}">
                            <div [ngStyle]="{'color' : medicine.quantity == '0' ? '#FFA07A' : '#000' }">
                              {{medicine.name}}</div>
                          </mat-option>
                        </mat-autocomplete>
                      </td>
                      <td>
                      
                        <input type="text" class="ipcss order_width getMedcien" required maxlength="50"
                          
                          (keyup)="drug.drug_name.length >=3 && handleKeyreturnmedicineUp($event,drug.drug_name,drug)"
                          maxlength="50" [(ngModel)]="drug.drug_name" matInput [matAutocomplete]="auto2" />
                        <mat-autocomplete #auto2="matAutocomplete" [panelWidth]="'auto'">
                         
                          <mat-option (click)="selectMedicineItemreturns(medicine,drug)"
                            *ngFor="let medicine of MedicneDatareturns" value="{{medicine.name}}">
                            <div [ngStyle]="{'color' : medicine.quantity == '0' ? '#FFA07A' : '#000' }">
                              {{medicine.name}}</div>
                          </mat-option>
                        </mat-autocomplete>
                      </td>

                      <td>
                        <input type="text" class="ipcss order_width" [ngStyle]="drug.batch_required" required
                          [(ngModel)]="drug.batch_no" matInput maxlength="50" readonly autocomplete="on"
                          (click)="getCORDretuns($event,'drugreturns_'+drug.index);drug.show_batcharray = !drug.show_batcharray" />

                        <div [id]="'drugreturns_'+drug.index" class="auto_complete_text1"
                          *ngIf="drug.batcharray.length != 0" style="display: none;">
                          <table style="width: 100%;">
                            <thead>
                              <tr>
                                <th> Batch no</th>
                                <th> Qty </th>
                                <th> MRP </th>
                                <th> Exp Date </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let batch of drug.batcharray"
                                (click)="selectedBatchreturns(batch,drug,'drugreturns_'+drug.index)">
                                <td [ngStyle]="{'color':batch.color}">{{batch.batch_no}}</td>
                                <td [ngStyle]="{'color':batch.color}">{{batch.quantity}}</td>
                                <td [ngStyle]="{'color':batch.color}">{{batch.mrp}}</td>
                                <td [ngStyle]="{'color':batch.color}">{{batch.expiry_date}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </td>
                      <td>
                        <input type="text" class="ipcss order_width"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="4" required
                        
                          [(ngModel)]="drug.quantity" (keyup)="discountcalculatestaffreturn()"  (keyup.enter)="createrowreturn()"
                          #matInput />
                          <!-- (keyup)="quantityreturns(drug)" -->
                      </td>
                      <td>
                        <input type="text" class="ipcss order_width"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="4" required
                          [(ngModel)]="drug.rack_id" (keyup.enter)="createrowreturn()" #matInput />
                      </td>
                      <td>
                        <input type="text" class="ipcss order_width"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="4" required
                          [(ngModel)]="drug.bin_id" (keyup.enter)="createrowreturn()" #matInput />
                      </td>
                      <td>
                        <input type="text" class="ipcss order_width"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="4" required
                          [(ngModel)]="drug.expiry_date" #matInput />
                      </td>
                      <td>
                    
                        <input type="text" style="text-align: right;" class="ipcss order_width"
                          
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57" [disabled]="true"
                          [(ngModel)]="drug.price" #matInput (keyup)="calculateamountreturns(drug)"
                          (keyup.enter)="createrowreturn()" />
                      </td>
                      
                      <td [hidden]="lineitmdisflag">
                        <!-- [readonly]="!discoutFlag" -->
                        <input type="text" class="ipcss order_width" maxlength="3" [(ngModel)]="drug.discount" 
                          (keyup)="discountcalculatestaffreturn()" #matInput  (keyup.enter)="createrowreturn()"/>
                      </td>
                     
                      <td>
                        <input type="text" style="text-align: right;" class="ipcss order_width"
                          [(ngModel)]="drug.amount" #matInput disabled />
                      </td>
                      <td style="text-align: center;">
                        <a (click)="deleteMedicinereturn(drug.drug_id,drug.index)">
                          <img src="../../../assets/ui_icons/buttons/trash_icon.svg" width="20" height="20" /></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          
          <div class="col-0 col-sm-3 col-md-7 col-lg-8 col-xl-8">
            <div class="cover_div" [hidden]="paytypreq">
              <div class="row">
                <div class="col-2">
                  <mat-label class="matlabel">Credit bill
                    <mat-checkbox (change)="showCreditOption()" color="primary" [(ngModel)]="credit_bill">
                    </mat-checkbox>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-5 col-md-5 col-lg-4 col-xl-4" [hidden]="credit_flag">
                  <div class="row">
                    <div class="col-4">
                      <mat-label class="matlabel">Credit type </mat-label>
                    </div>
                    <div class="col-8">
                      <select class="ipcss" [(ngModel)]="credit_type" disableOptionCentering>
                        
                        <option *ngFor="let credit of hosp_credits" value={{credit.credit_id}}> {{credit.credit_desc}}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-5" [hidden]="credit_flag">
                  <input class="ipcss" [(ngModel)]="credit_person" placeholder="name of employee" matInput
                    maxlength="50" />
                </div>
              </div>
              <div class="row" [hidden]="!credit_flag">
                <div class="col-12 col-sm-5 col-md-5 col-lg-4 col-xl-4 select_bottom">
                  <mat-label class="matlabel">Payment type<br>
                   
                    <select class="ipcss " [(ngModel)]="payType1" disableOptionCentering>
                      <option *ngFor="let pay of payTypeListArrray" value={{pay.pay_id}}>{{pay.pay_desc}}</option>
                    </select>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-5 col-md-5 col-lg-4 col-xl-4">
                  <mat-label class="matlabel">Amount<br>
                    
                    <input type=number class="ipcss billing_text_align" [(ngModel)]="amountval" matInput
                      maxlength="7" />
                  </mat-label>
                </div>
                <div class="col-12 col-sm-2 col-md-3 col-lg-2 col-xl-2">
                  <mat-label class="matlabel ">
                    
                    <img src="../../../assets/ui_icons/buttons/add_button.svg" class="saveimgbtn_inpatinfo"
                      (click)="addPaymentDetails()" style="margin-top:19px"/>
                  </mat-label>
                </div>
              </div>
              <div class="row">
                <table>
                  <tr *ngFor="let pay of paymentsarray">
                    <td style="width: 150px !important;">{{pay.pay_desc}}</td>
                    <td style="width: 150px !important;">{{pay.amount}}</td>
                    <td>
                     
                      <img src="../../../assets/img/edit.png" class="billing_edit" (click)="editPayment(pay)">
                      &nbsp;&nbsp;
                      <img src="../../../assets/img/Delete.png" class="billing_edit" (click)="deletePayment(pay)">
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>

          <div class="col-0 col-sm-9 col-md-4 col-lg-4 col-xl-4" [hidden]="!credit_flag">
            <div class="row" style="margin: 10px 0px;">
              <div class="col-6 col-sm-4 col-md-4 col-lg-5 col-xl-5">
                <mat-label class="matlabel">Total Bill</mat-label>
                <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png" style="width: 10px; height: 10px;" />
              </div>
              <div class="col-6 col-sm-8 col-md-6 col-lg-7 col-xl-7">
                <input class="ipcss billcreatech heightmsr bill_txt" matInput text-right readonly
                  [(ngModel)]="totalAmount" style="text-align: right;" />
              </div>

              <div class="col-6 col-sm-4 col-md-6 col-lg-5 col-xl-5" [hidden]="discoutFlag">
                <mat-label class="matlabel  heightmsr" [hidden]="lineitmdisflag">Discount</mat-label>
                <mat-label class="matlabel  heightmsr" [hidden]="!lineitmdisflag">Discount ({{disperce}}%)</mat-label>

                <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png" style="width: 10px; height: 10px;" />
              </div>
              <div class="col-6 col-sm-8 col-md-6 col-lg-7 col-xl-7" [hidden]="discoutFlag">
                <input type="text" class="ipcss " style="text-align: right;" [(ngModel)]="prodDiscount"
                  (keyup)="changeTotal()" (blur)="dissBlur()">
              </div>

              <div class="col-6 col-sm-4 col-md-6 col-lg-5 col-xl-5" [hidden]="GSTamountflag">
                <mat-label class="matlabel  heightmsr">GST</mat-label>
                <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png" style="width: 10px; height: 10px;" />
              </div>
              <div class="col-6 col-sm-8 col-md-6 col-lg-7 col-xl-7" [hidden]="GSTamountflag">
                <input type="text" class="ipcss " style="text-align: right;" [(ngModel)]="GSTamount"
                  (keyup)="changeTotal()" (blur)="dissBlur()">
              </div>

              <div class="col-6 col-sm-4 col-md-6 col-lg-5 col-xl-5" *ngIf="showreturns">
                <mat-label class="matlabel">Returns</mat-label>
                <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png" style="width: 10px; height: 10px;" />
              </div>
              <div class="col-6 col-sm-8 col-md-6 col-lg-7 col-xl-7" *ngIf="showreturns">
                <input class="ipcss billcreatech heightmsr bill_txt" matInput text-right readonly
                  [(ngModel)]="returnbilltotalAmount" style="text-align: right;" />
              </div>
              <div class="col-6 col-sm-4 col-md-6 col-lg-5 col-xl-5" *ngIf="round_off_descflag">
                <mat-label class="matlabel heightmsr">Round off</mat-label>
              </div>
             
              <div class="col-6 col-sm-8 col-md-6 col-lg-7 col-xl-7" *ngIf="round_off_descflag" 
                style="display: flex;align-items: center;">
                <span class="roundoffButton"><img src="../../../assets/ui_icons/minus_icon.svg" alt=""
                    (click)="addRound('minus')"></span>

                <span class="roundoffButton"><img src="../../../assets/ui_icons/plus_icon.svg"
                    style="margin: 0 0 0 5px;" alt="" (click)="addRound('plus')"></span>

                <input class="ipcss  heightmsr bill_txt billcreatech" matInput text-right [(ngModel)]="round_off"
                 
                  maxlength="10" style="    display: inline-block !important; width: 100%;
                    margin: 0px 0px 4px 9px;" (keyup)="updatedvalue(round_off)" [disabled]="isInputDisabled" />
              </div>

              <div class="col-6 col-sm-4 col-md-6 col-lg-5 col-xl-5">
                <mat-label class="matlabel">Bill Amount</mat-label>
                <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png" style="width: 10px; height: 10px;" />
              </div>
              <div class="col-6 col-sm-8 col-md-6 col-lg-7 col-xl-7">
                <input class="ipcss billcreatech heightmsr bill_txt" matInput text-right readonly
                  [(ngModel)]="amountDicount" style="text-align: right;" />
              </div>
              <div class="col-6 col-sm-4 col-md-6 col-lg-5 col-xl-5">
                <mat-label class="matlabel heightmsr">Paid </mat-label>
              </div>
              <div class="col-6 col-sm-8 col-md-6 col-lg-7 col-xl-7">
                <input class="ipcss billcreatech heightmsr bill_txt" matInput text-right style="text-align: right;"
                 
                  [(ngModel)]="paidAmount" (keyup)="changeAdvance();changePaid()" (focus)="paidFocus()"
                  (blur)="paidBlur()" />
              </div>
              <div class="col-6 col-sm-4 col-md-6 col-lg-5 col-xl-5">
                <mat-label class="matlabel heightmsr">Balance</mat-label>
                <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png" style="width: 10px; height: 10px;" />
              </div>
              <div class="col-6 col-sm-8 col-md-6 col-lg-7 col-xl-7">
                <input type="text" class="ipcss " style="background-color: #e7e7e7;
                  border-radius: 5px;text-align: right;" [(ngModel)]="remainBalance" readonly>
              </div>
            </div>
          </div>
        </div>
     
        <!-- Printing bill-->
        <div [hidden]="true">
          <div>
            <div #billprint id="billprint">
              <table class="report-container" style="width: 100%;">
                <thead class="report-header" style="margin-left:10px;margin-top:10px;margin-right: 10px;">
                  <tr>
                    <td style="display: flex;justify-content: center;">
                      <div>
                      </div>
                      <div style="position: relative;top: 20px;left: 10px;text-align: left;">
                        <p><b>{{pharmacyName}}</b>,</p>
                        <span *ngIf="pharmaAddress1 != undefined">{{pharmaAddress1}},</span><span
                          *ngIf="pharmaAddress2 != undefined">{{pharmaAddress2}},</span><br />
                        <span>{{location}},</span> <span>{{city}},</span>
                        <span>{{state}},</span><span>{{country}}.</span><br>
                        <p><span *ngIf="dlNO != undefined">DL No : {{dlNO}} &nbsp;&nbsp;</span><span
                            *ngIf="gstNO != undefined">GST : {{gstNO}}</span></p>
                      </div>
                    </td>
                  </tr>
                </thead>
                <tfoot class="report-footer">
                  <tr>
                    <td style="height:100px;"></td>
                  </tr>
                </tfoot>
                <tbody class="report-content">
                  <tr>
                    <td class="report-content-cell">
                      <div class="main">
                        <table style="margin-left: 75px;margin-top: 20px;">
                          <td>
                            <p>Name :{{name}}</p>
                            <p #billid id="billid"></p>
                            <p>Bill type :{{PurchaseType}}</p>
                          </td>
                          <td style="width: 250px;"></td>
                          <td>
                            <p>Doctor : {{doctorName}}</p>
                            <p>Bill date :{{orderDate | date: 'dd-MM-yyyy'}}</p>
                            <p>Bill time :{{orderTime}}</p>
                          </td>
                        </table>
                        <br />
                        <table cellspacing="0" width="85%"
                          style="border-collapse: collapse;border:1px solid black; margin-left: 75px; margin-right: 50px;">
                          <tr style="border:1px solid black;">
                            <th style="border:1px solid black;">Medicine name</th>
                            <th style="border:1px solid black;">Quantity</th>
                            <th style="border:1px solid black;">Batch No</th>
                            <th style="border:1px solid black;">Expiry date</th>
                            <th style="border:1px solid black;">Price</th>
                            <th style="border:1px solid black;"> Disc(%)</th>
                            <th style="border:1px solid black;">CGST(%)</th>
                            <th style="border:1px solid black;">SGST(%)</th>
                            <th style="border:1px solid black;"> Amount</th>
                          </tr>
                          <tbody>
                            <tr *ngFor="let medpre of drugDetailsArray">
                              <td style="border:1px solid black;">{{medpre.drug_disp_name}}</td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 10px;">{{medpre.quantity}} </p>
                              </td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 10px;">{{medpre.batch_no}} </p>
                              </td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 10px;">{{medpre.expiry_date}} </p>
                              </td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 10px;">{{medpre.price}} </p>
                              </td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 10px;">{{medpre.discount}} </p>
                              </td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 10px;">{{medpre.cgst}} </p>
                              </td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 10px;">{{medpre.sgst}} </p>
                              </td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 10px;">{{medpre.amount}}</p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table width="85%" style="margin-left: 76px; margin-right: 50px;">
                          <tr *ngIf="displayDisplay">
                            <td style="text-align: right; width: 80%;">
                              <b>Discount</b>
                              <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                style="width: 15px; height: 15px;" />
                            </td>
                            <td>
                              <p style="float: right;margin-right: 10px;">{{prodDiscount}}</p>
                            </td>
                          </tr>
                          <tr>
                            <td style="text-align: right; width: 80%;">
                              <b>Total</b>
                              <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                style="width: 15px; height: 15px;" />
                            </td>
                            <td>
                              <p style="float: right;margin-right: 10px;">{{totalAmount}}</p>
                            </td>
                          </tr>
                          <tr>
                            <td style="text-align: right; width: 80%;">

                              <b>Paid</b>
                              <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                style="width: 15px; height: 15px;" />

                            </td>
                            <td class="billamttext">
                              <p style="float: right;margin-right: 10px;">{{paidAmount}}</p>
                            </td>
                          </tr>

                          <tr>
                            <td style="text-align: right; width: 80%;">
                              <b>Balance</b>
                              <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                style="width: 15px; height: 15px;" />
                            </td>
                            <td class="billamttext">
                              <p style="float: right;margin-right: 10px;">{{remainBalance}}</p>
                            </td>
                          </tr>

                        </table>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div #noheader id="noheader">
              <table class="report-container" style="width: 100%;">
                <thead class="report-header" style="margin-left:10px;margin-top:10px;margin-right: 10px;">
                  <tr>
                    <td style="height:130px;"></td>
                  </tr>
                </thead>
                <tfoot class="report-footer">
                  <tr>
                    <td style="height:100px;"></td>
                  </tr>
                </tfoot>
                <tbody class="report-content">
                  <tr>
                    <td class="report-content-cell">
                      <div class="main">
                        <table style="margin-left: 75px;margin-top: 20px;">
                          <td>
                            <p>Name :{{name}}</p>
                            <p #billid id="billid"></p>
                            <p>Bill type :{{PurchaseType}}</p>
                          </td>
                          <td style="width: 250px;"></td>
                          <td>
                            <p>Doctor : {{doctorName}}</p>
                            <p>Bill date :{{orderDate | date: 'dd-MM-yyyy'}}</p>
                            <p>Bill time :{{orderTime}}</p>
                          </td>
                        </table>
                        <br />
                        <table cellspacing="0" width="85%"
                          style="border-collapse: collapse;border:1px solid black; margin-left: 75px; margin-right: 50px;">
                          <tr style="border:1px solid black;">
                            <th style="border:1px solid black;">Medicine name</th>
                            <th style="border:1px solid black;">Quantity</th>
                            <th style="border:1px solid black;">Batch No</th>
                            <th style="border:1px solid black;">Expiry date</th>
                            <th style="border:1px solid black;">Price</th>
                            <th style="border:1px solid black;"> Discount(%)</th>
                            <th style="border:1px solid black;">CGST(%)</th>
                            <th style="border:1px solid black;">SGST(%)</th>
                            <th style="border:1px solid black;"> Amount</th>
                          </tr>
                          <tbody>
                            <tr *ngFor="let medpre of drugDetailsArray">
                              <td style="border:1px solid black;">{{medpre.drug_disp_name}}</td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 10px;">{{medpre.quantity}} </p>
                              </td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 10px;">{{medpre.batch_no}} </p>
                              </td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 10px;">{{medpre.expiry_date}} </p>
                              </td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 10px;">{{medpre.price}} </p>
                              </td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 10px;">{{medpre.discount}} </p>
                              </td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 10px;">{{medpre.cgst}} </p>
                              </td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 10px;">{{medpre.sgst}} </p>
                              </td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 10px;">{{medpre.amount}}</p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table width="85%" style="margin-left: 76px; margin-right: 50px;">
                          <tr *ngIf="displayDisplay">
                            <td style="text-align: right; width: 80%;">
                              <b>Discount</b>
                              <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                style="width: 15px; height: 15px;" />
                            </td>
                            <td>
                              <p style="float: right;margin-right: 10px;">{{prodDiscount}}</p>
                            </td>
                          </tr>
                          <tr>
                            <td style="text-align: right; width: 80%;">
                              <b>Total</b>
                              <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                style="width: 15px; height: 15px;" />
                            </td>
                            <td>
                              <p style="float: right;margin-right: 10px;">{{totalAmount}}</p>
                            </td>
                          </tr>
                          <tr>
                            <td style="text-align: right; width: 80%;">
                              <b>Paid</b>
                              <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                style="width: 15px; height: 15px;" />
                            </td>
                            <td class="billamttext">
                              <p style="float: right;margin-right: 10px;">{{paidAmount}}</p>
                            </td>
                          </tr>
                          <tr>
                            <td style="text-align: right; width: 80%;">
                              <b>Balance</b>
                              <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                style="width: 15px; height: 15px;" />
                            </td>
                            <td class="billamttext">
                              <p style="float: right;margin-right: 10px;">{{remainBalance}}</p>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div #banner id="banner">
              <table class="report-container" style="width: 100%;">
                <thead class="report-header" style="margin-left:10px;margin-top:10px;margin-right: 10px;">
                  <tr>
                    <td style="width: 150px;"></td>
                    <td>
                      <img alt="image" src={{pharmaLogo}} width="750px">
                    </td>
                  </tr>
                </thead>
                <tfoot class="report-footer">
                  <tr>
                    <td style="height:100px;"></td>
                  </tr>
                </tfoot>
                <tbody class="report-content">
                  <tr>
                    <td class="report-content-cell">
                      <div class="main">
                        <table style="margin-left: 75px;margin-top: 20px;">
                          <td>
                            <p>Name :{{name}}</p>
                            <p #billid id="billid"></p>
                            <p>Bill type :{{PurchaseType}}</p>
                          </td>
                          <td style="width: 250px;"></td>
                          <td>
                            <p>Doctor : {{doctorName}}</p>
                            <p>Bill date :{{orderDate | date: 'dd-MM-yyyy'}}</p>
                            <p>Bill time :{{orderTime}}</p>
                          </td>
                        </table>
                        <br />
                        <table cellspacing="0" width="85%"
                          style="border-collapse: collapse;border:1px solid black; margin-left: 75px; margin-right: 50px;">
                          <tr style="border:1px solid black;">
                            <th style="border:1px solid black;">Medicine name</th>
                            <th style="border:1px solid black;">Quantity</th>
                            <th style="border:1px solid black;">Batch No</th>
                            <th style="border:1px solid black;">Expiry date</th>
                            <th style="border:1px solid black;">Price</th>
                            <th style="border:1px solid black;"> Disc(%)</th>
                            <th style="border:1px solid black;">CGST(%)</th>
                            <th style="border:1px solid black;">SGST(%)</th>
                            <th style="border:1px solid black;"> Amount</th>
                          </tr>
                          <tbody>
                            <tr *ngFor="let medpre of drugDetailsArray">
                              <td style="border:1px solid black;">{{medpre.drug_disp_name}}</td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 10px;">{{medpre.quantity}} </p>
                              </td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 10px;">{{medpre.batch_no}} </p>
                              </td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 10px;">{{medpre.expiry_date}} </p>
                              </td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 10px;">{{medpre.price}} </p>
                              </td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 10px;">{{medpre.discount}} </p>
                              </td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 10px;">{{medpre.cgst}} </p>
                              </td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 10px;">{{medpre.sgst}} </p>
                              </td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 10px;">{{medpre.amount}}</p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table width="85%" style="margin-left: 76px; margin-right: 50px;">
                          <tr *ngIf="displayDisplay">
                            <td style="text-align: right; width: 80%;">
                              <b>Discount</b>
                              <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                style="width: 15px; height: 15px;" />
                            </td>
                            <td>
                              <p style="float: right;margin-right: 10px;">{{prodDiscount}}</p>
                            </td>
                          </tr>
                          <tr>
                            <td style="text-align: right; width: 80%;">
                              <b>Total</b>
                              <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                style="width: 15px; height: 15px;" />
                            </td>
                            <td>
                              <p style="float: right;margin-right: 10px;">{{totalAmount}}</p>
                            </td>
                          </tr>
                          <tr>
                            <td style="text-align: right; width: 80%;">
                              <b>Paid</b>
                              <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                style="width: 15px; height: 15px;" />
                            </td>
                            <td class="billamttext">
                              <p style="float: right;margin-right: 10px;">{{paidAmount}}</p>
                            </td>
                          </tr>
                          <tr>
                            <td style="text-align: right; width: 80%;">
                              <b>Balance</b>
                              <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                style="width: 15px; height: 15px;" />
                            </td>
                            <td class="billamttext">
                              <p style="float: right;margin-right: 10px;">{{remainBalance}}</p>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div #logowithname id="logowithname"
              style="background-image: url({{bgImage}}); background-position: center;">
              <table class="report-container" style="width: 100%;">
                <thead class="report-header">
                  <tr>
                    <td style="display: flex;justify-content: center;">
                      <div>
                        <img alt="image" src={{pharmaLogo}} width="100px" height="100px"
                          style="vertical-align: middle;">
                      </div>
                      <div style="position: relative;top: 20px;left: 10px;text-align: left;">
                        <p><b>{{pharmacyName}}</b>,</p>
                        <span *ngIf="pharmaAddress1 != undefined">{{pharmaAddress1}},</span><span
                          *ngIf="pharmaAddress2 != undefined">{{pharmaAddress2}},</span><br />
                        <span>{{location}},</span> <span>{{city}},</span>
                        <span>{{state}},</span><span>{{country}}.</span><br>
                        <p><span *ngIf="dlNO != undefined">DL No : {{dlNO}} &nbsp;&nbsp;</span><span
                            *ngIf="gstNO != undefined">GST : {{gstNO}}</span></p>
                      </div>
                    </td>
                  </tr>
                </thead>
                <tfoot class="report-footer">
                  <tr>
                    <td style="height:100px;"></td>
                  </tr>
                </tfoot>
                <tbody class="report-content">
                  <tr>
                    <td class="report-content-cell">
                      <div class="main">
                        <table style="margin-left: 75px;margin-top: 20px;">
                          <td>
                            <p>Name :{{name}}</p>
                            <p #billid id="billid"></p>
                            <p>Bill type :{{PurchaseType}}</p>
                          </td>
                          <td style="width: 250px;"></td>
                          <td>
                            <p>Doctor : {{doctorName}}</p>
                            <p>Bill date :{{orderDate | date: 'dd-MM-yyyy'}}</p>
                            <p>Bill time :{{orderTime}}</p>
                          </td>
                        </table>
                        <br />
                        <table cellspacing="0" width="85%"
                          style="border-collapse: collapse;border:1px solid black; margin-left: 75px; margin-right: 50px;">
                          <tr style="border:1px solid black;">
                            <th style="border:1px solid black;">Medicine name</th>
                            <th style="border:1px solid black;">Quantity</th>
                            <th style="border:1px solid black;">Batch No</th>
                            <th style="border:1px solid black;">Expiry date</th>
                            <th style="border:1px solid black;">Price</th>
                            <th style="border:1px solid black;"> Disc(%)</th>
                            <th style="border:1px solid black;">CGST(%)</th>
                            <th style="border:1px solid black;">SGST(%)</th>
                            <th style="border:1px solid black;"> Amount</th>
                          </tr>
                          <tbody>
                            <tr *ngFor="let medpre of drugDetailsArray">
                              <td style="border:1px solid black;">{{medpre.drug_disp_name}}</td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 10px;">{{medpre.quantity}} </p>
                              </td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 10px;">{{medpre.batch_no}} </p>
                              </td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 10px;">{{medpre.expiry_date}} </p>
                              </td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 10px;">{{medpre.price}} </p>
                              </td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 10px;">{{medpre.discount}} </p>
                              </td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 10px;">{{medpre.cgst}} </p>
                              </td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 10px;">{{medpre.sgst}} </p>
                              </td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 10px;">{{medpre.amount}}</p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table width="85%" style="margin-left: 76px; margin-right: 50px;">
                          <tr *ngIf="displayDisplay">
                            <td style="text-align: right; width: 80%;">
                              <b>Discount</b>
                              <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                style="width: 15px; height: 15px;" />{{disperce}}%
                            </td>
                            <td>
                              <p style="float: right;margin-right: 10px;">{{prodDiscount}}</p>
                            </td>
                          </tr>
                          <tr>
                            <td style="text-align: right; width: 80%;">
                              <b>Total</b>
                              <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                style="width: 15px; height: 15px;" />
                            </td>
                            <td>
                              <p style="float: right;margin-right: 10px;">{{totalAmount}}</p>
                            </td>
                          </tr>
                          <tr>
                            <td style="text-align: right; width: 80%;">
                              <b>Paid</b>
                              <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                style="width: 15px; height: 15px;" />
                            </td>
                            <td class="billamttext">
                              <p style="float: right;margin-right: 10px;">{{paidAmount}}</p>
                            </td>
                          </tr>
                          <tr>
                            <td style="text-align: right; width: 80%;">
                              <b>Balance</b>
                              <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                style="width: 15px; height: 15px;" />
                            </td>
                            <td class="billamttext">
                              <p style="float: right;margin-right: 10px;">{{remainBalance}}</p>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div>
              </div>
            </div>

          </div>
        </div>
        <!----Printing bill----->

        <!-----print cover-------->

        <div [hidden]="true" id="printsmallcover" #printsmallcover>
          <!-- height:350px; -->
          
          <div style="width:30%;height:205px;margin-bottom: 100%" *ngFor="let drug of drugDetailsArray">

            <!-- <img alt="image" src={{pharmaLogo}} style="width:100%;"> -->
            <table class="covertablebig" border="1" style="width:100%">
              <thead class="report-header">

                <tr>
                  <td style="text-align: left;" colspan="4">
                    Name: {{name}}
                  </td>
                </tr>
                <tr>

                  <td style="text-align: left;" colspan="4">
                    Medicine: {{drug.drug_disp_name}}
                  </td>

                </tr>
                <tr>

                  <td style="text-align: left" colspan="4">
                    Quantity:{{drug.quantity}}
                  </td>

                </tr>
                <tr>

                  <td style="text-align: left" colspan="4">
                    Batch no:{{drug.batch_no}}
                  </td>

                </tr>
                <tr>

                  <td style="text-align: left" colspan="4">
                    Expire date:{{drug.expiry_date}}
                  </td>

                </tr>


                <tr [hidden]="alternatsession">

                  <th colspan="4" style="text-align: left;">{{intakesession}}</th>

                </tr>


                <tr [hidden]="sessionhide" *ngIf="intakesession === 'Morning only' || 'Afternoon only' || 'Night only' 
                || 'Morning & Afternoon' || 'Morning & Night' || 'Afternoon & Night'||
                'Afternoon & Evening'||'Evening & Night' || 'Four times a day'||'Once a week'
                ||'Alternative day'||'Once in two weeks'||'Once a month'
                ">
                  <th style="width:25%;font-size:10px">{{'Morning'|translate}}</th>
                  <th style="width:25%;font-size:10px">{{'Afternoon'|translate }}</th>
                  <th style="width:25%;font-size:10px">{{'Evening'| translate}}</th>
                  <th style="width:25%;font-size:10px">{{'Night'| translate}}</th>
                </tr>

                
                <tr [hidden]="sessionhrs"
                  *ngIf="intakesession === 'Every 6 hours'||'SOS'||'Stat'||'Once a day' || 'Every 8 hours' || 'Every 12 hours' ||'SOS' || 'Once a day'">
                  <th colspan="4" style="text-align: left;;font-size:10px">{{intakesession}}</th>
                </tr>


              </thead>

              <tbody style="height:70px">
                <tr [hidden]="sessionhide" *ngIf="intakesession === 'Morning only' || 'Afternoon only' 
                || 'Night only' || 'Morning & Afternoon' || 'Morning & Night' || 'Afternoon & Night'||
                'Afternoon & Evening'||'Evening & Night' || 'Four times a day'||'Once a week'||
                'Alternative day'||'Once in two weeks'||'Once a month'
                ">
                  <td style="text-align: center;;font-size:10px">{{drug.morning}}{{drug.shortname}}</td>
                  <td style="text-align: center;;font-size:10px">{{drug.afternoon}}{{drug.shortname}}</td>
                  <td style="text-align: center;font-size:10px">{{drug.evening}}{{drug.shortname}}</td>
                  <td style="text-align: center;font-size:10px">{{drug.night}}{{drug.shortname}}</td>
                </tr>

                <tr [hidden]="sessionhrs"
                  *ngIf="intakesession === 'Every 6 hours'||'SOS'||'Stat'||'Once a day' || 'Every 8 hours' || 'Every 12 hours' ||'SOS' || 'Once a day'">
                  <td colspan="4" style="font-size:10px;font-size:10px">{{intakesession}} - {{drug.morning}}</td>
                </tr>
              </tbody>

              <tfoot>
                <tr style="border:1px solid black">
                  <th style="text-align: left;font-size:8px" colspan="4">
                  
                    {{drug.day_txt}} {{drug.daydur_txt |translate}} {{drug.dure_txt}} {{'Minutes'|translate}} -
                    {{drug.afterfood_txt |translate}}
                  </th>

                </tr>
              </tfoot>
            </table>
          </div>
        </div>

        <!-------print cover------------>

      </mdb-card-body>
    </mdb-card>
  </div>
</div>