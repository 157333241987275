<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <div class="row" *ngIf="locationInfoArray.length>1">
      <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4" style="margin-left: 9px;margin-top: 13px;">
        <mat-label class="matlabel">Diagnosis Centre
          <mat-select class="ipcss" [(ngModel)]="retivelArray1">
            <mat-option value="{{loc.diag_centre_name+'-'+loc.diag_loc}}"
              *ngFor="let loc of locationInfoArray; let i = index" (click)="loc_change(loc)">
              {{loc.diag_centre_name+"-"+loc.diag_loc}}
            </mat-option>
          </mat-select>
        </mat-label>
      </div>
    </div>
  </div>
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Rejected</h5>
          </div>
          <div class="headerButtons">
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row">
          <div class="col-12">
            <p class="nodata" [hidden]='updateTestArray.length!=0'>No Report(s) Found</p>
            <table *ngIf="updateTestArray.length" mdbTable datatable [dtOptions]="dtOptions"
              class="table table-nowrap table-sm dataTable">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Test Type</th>
                  <th> Test Name </th>
                  <th> Status </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let app of updateTestArray"
                  (click)="viewReportDetails(app.test_type,app.diag_app_id,app.pres_test_id,app.pres_diag_id,app.test_name)">
                  <td style="text-align: left;">{{ app.first_name }} {{app.last_name}}</td>
                  <td> {{app.test_type}}</td>
                  <td> {{app.test_name}}</td>
                  <td style="font-size: 12px;">
                    <div style="position: relative;top: 9px;">
                      <img width="30px" height="auto" src="../../../assets/ui_icons/Requiers_modification.svg" />
                      <p>Requires Modification</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>