<div mat-dialog-title class="popup_border_icd">
    <h5 class="header_txt">History</h5>
    <div mat-dialog-actions>
        <img src="../../../assets/img/close_icon.png" (click)="ClosePopup()" width="20px" height="20px"
            id="close_icd" />
    </div>
</div>
<div mat-dialog-content class="neph_content">
    <div class="container">
        <div class="row" style="max-height: 250px;">
            <div class="col-12" [hidden]="sitting_hide">
                <p class="tervys_heading">Sitting</p>
                <p *ngIf="vital_sitting_data.length ==0" class="nodata">No Data Found</p>
                <div class="table-responsive" *ngIf="vital_sitting_data.length !=0">
                    <table id="tbl">
                        <thead>
                            <tr>
                                <th>Blood Pressure</th>
                                <th>Heart Beat</th>
                                <th>Respiratory Rate</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let vital of vital_sitting_data">
                                <td>vital.blood_pressure_sit</td>
                                <td>vital.heart_rate_sit</td>
                                <td>vital.respiratory_rate_sit</td>
                                <td>vital.created_date</td>

                            </tr>
                        </tbody>
                    </table>
                </div>
                <p class="tervys_heading">Standing</p>
                <p *ngIf="vital_sitting_data.length ==0" class="nodata">No Data Found</p>
                <div class="table-responsive" *ngIf="vital_sitting_data.length !=0">
                    <table id="tbl">
                        <thead>
                            <tr>
                                <th>Blood Pressure</th>
                                <th>Heart Beat</th>
                                <th>Respiratory Rate</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let vital of vital_sitting_data">
                                <td>vital.blood_pressure_stand</td>
                                <td>vital.heart_rate_stand</td>
                                <td>vital.respiratory_rate_stand</td>
                                <td>vital.created_date</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-12" [hidden]="per_hist">
                <p *ngIf="medical_history_data.length ==0" class="nodata">No Data Found</p>
                <div class="table-responsive" *ngIf="medical_history_data.length !=0">
                    <table id="tbl">
                        <thead>
                            <tr>
                                <th>Skin</th>
                                <th>Eye</th>
                                <th>Cardiovascular</th>
                                <th>Respiratory</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let analysis of medical_history_data">
                                <td>{{analysis.skin_dis}}</td>
                                <td>{{analysis.retino_dis}}</td>
                                <td>{{analysis.cardio_dis}}</td>
                                <td>{{analysis.resp_dis}}</td>
                                <td class="diet_histtable_date_td">{{analysis.created_date | date:'dd/MM/yyyy'}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-12" [hidden]="habit_hist">
                <p *ngIf="habitual_history_data.length ==0" class="nodata">No Data Found</p>
                <div class="table-responsive" *ngIf="habitual_history_data.length !=0">
                    <table id="tbl">
                        <thead>
                            <tr>
                                <th>Alchol frequency (For day)</th>
                                <th>Alchol quantity</th>
                                <th>Smoke frequency (For day)</th>
                                <th>Gutka frequency (For day)</th>
                                <th>Exercise duration</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let habitual of habitual_history_data">
                                <td>{{habitual.alc_frequency}}</td>
                                <td>{{habitual.alc_qty}}</td>
                                <td>{{habitual.smoke_frequency}}</td>
                                <td>{{habitual.gutka_frequency}}</td>
                                <td>{{habitual.curr_exer_dur}}</td>
                                <td class="diet_histtable_date_td">{{habitual.created_date | date:'dd/MM/yyyy'}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-12" [hidden]="family_hist">
                <p *ngIf="family_history_data.length ==0" class="nodata">No Data Found</p>
                <!-- //Family hsitory_pop -->
                <div class="table-responsive" *ngIf="family_history_data.length != 0">
                    <table id="tbl">
                        <thead>
                            <tr>
                                <th>Diabetes</th>
                                <th>Cardio</th>
                                <th>Endocrine</th>
                                <th>Hypertension</th>
                                <th>Hepatitis</th>
                                <th>HIV</th>
                                <th>Other disease</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let family of family_history_data">
                                <td>{{family.Diabetics}}</td>
                                <td>{{family.heart_attack}}</td>
                                <td>{{family.endocrine}}</td>
                                <td>{{family.hypertension}}</td>
                                <td>{{family.hepatitis}}</td>
                                <td>{{family.hiv}}</td>
                                <td>{{family.others}}</td>
                                <td class="diet_histtable_date_td">{{family.created_date | date:'dd/MM/yyyy'}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-12" [hidden]="diet_hide">
                <p *ngIf="DietHist_Table_Data.length ==0" class="nodata">No Data Found</p>
                <!-- //Diet plan -->
                <div class="table-responsive" *ngIf="DietHist_Table_Data.length !=0">
                    <table id="tbl">
                        <thead>
                            <tr>
                                <th>Break fast</th>
                                <th>Morning</th>
                                <th>Mid-day</th>
                                <th>Lunch</th>
                                <th>Evening</th>
                                <th>Dinner1</th>
                                <th>Night</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let Diet of DietHist_Table_Data">
                                <td>{{Diet.break_fast}}</td>
                                <td>{{Diet.morning}}</td>
                                <td>{{Diet.midday}}</td>
                                <td>{{Diet.lunch}}</td>
                                <td>{{Diet.evening}}</td>
                                <td>{{Diet.dinner}}</td>
                                <td>{{Diet.night}}</td>
                                <td class="diet_histtable_date_td">{{Diet.created_date | date:'dd-MM-yyyy'}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-12" [hidden]="tracker_hist">
                <p *ngIf="Trackhist_Table_Data.length ==0" class="nodata">No Data Found</p>
                <!-- //Tracker history -->
                <div class="table-responsive" *ngIf="Trackhist_Table_Data.length !=0">
                    <table id="tbl">
                        <thead>
                            <tr>
                                <th>Break fast</th>
                                <th>Morning</th>
                                <th>Mid-day</th>
                                <th>Lunch</th>
                                <th>Evening</th>
                                <th>Dinner</th>
                                <th>Night</th>
                                <th>Date</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr *ngFor="let Tracker of Trackhist_Table_Data">
                                <td>{{Tracker.break_fast}}</td>
                                <td>{{Tracker.morning}}</td>
                                <td>{{Tracker.midday}}</td>
                                <td>{{Tracker.lunch}}</td>
                                <td>{{Tracker.evening}}</td>
                                <td>{{Tracker.dinner}}</td>
                                <td>{{Tracker.night}}</td>
                                <td class="diet_histtable_date_td">{{Tracker.created_date | date:'dd-MM-yyyy'}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
            <div class="col-12" [hidden]="bmi_hide">
                <p *ngIf="bmi_history_data.length ==0" class="nodata">No Data Found</p>
                <!-- bmi history -->
                <div class="table-responsive" *ngIf="bmi_history_data.length !=0">
                    <table id="tbl">
                        <thead>
                            <tr>
                                <th>Height</th>
                                <th>Height measure</th>
                                <th>Weight</th>
                                <th>Weight measure</th>
                                <th>BMI</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let bmidata of bmi_history_data">
                                <td>{{bmidata.height}}</td>
                                <td>{{bmidata.height_measure}}</td>
                                <td>{{bmidata.weight}}</td>
                                <td>{{bmidata.weight_measure}}</td>
                                <td>{{bmidata.bmi_data}}</td>
                                <td class="diet_histtable_date_td">{{bmidata.created_date | date:'dd/MM/yyyy'}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions align="end" style="width: auto;right: 19px;">
</div>