<div *ngIf="dentalExaminationFlag == 'min'">
    <div class="block1_grid">
        <mat-label class="matlabel">Jaw type<br>
            <select class="ipcss" [(ngModel)]="jaw_txt" required disableOptionCentering [disabled]="client">
                <option value="Select" selected>Select</option>
                <option value="Upper">Upper</option>
                <option value="Lower">Lower</option>
            </select>
        </mat-label>

        <mat-label class="matlabel">Colour<br>
            <select class="ipcss" disableOptionCentering [(ngModel)]="color_of_teeth" [disabled]="client">
                <option value="Select" selected>Select</option>
                <option *ngFor="let color of color_of_teeth_array" value={{color.teeth_color_id}}>
                    {{color.description}}</option>
            </select>
        </mat-label>

        <mat-label class="matlabel">Teeth type<br>
            <select class="ipcss " [(ngModel)]="teeth_type_txt" disableOptionCentering [disabled]="client">
                <option value="Select" selected>Select</option>
                <option value="Primary">Primary</option>
                <option value="Permenent">Permenent</option>
                <option value="Suplementry Teeth">Suplementry Teeth</option>
            </select>
        </mat-label>
    </div>
</div>

<div *ngIf="dentalExaminationFlag == 'max'">
    <div class="personaldetails">
        <div class="row">
            <div class="col-md-3 col-lg-3 col-sm-12 col-xl-3 ">

                <div class="row">
                    <!-- tab_view -->
                    <div class="col-md-12 col-lg-12 col-sm-12 col-xl-12">
                        <!-- <mat-label class="ipcss ">{{perio_div}}</mat-label> -->
                        <img src="../../assets/img/dental_icons/above_e_teen.png" class="map" usemap="#features"
                            draggable="false">
                        <br />
                        <map name="features" [disabled]="client">
                            <area shape="poly" id="teeth15" alt="star1"
                                coords="21,155,25,156,31,156,37,155,42,157,44,163,44,167,43,171,44,176,42,182,39,184,34,187,28,188,21,187,16,182,12,176,12,170,12,163,16,157"
                                state="teeth15" (click)="selected_teethimage('15')" >

                            <area alt="" id="teeth13" title="" shape="poly"
                                coords="37,124,44,124,49,130,48,137,46,143,45,151,40,154,34,154,28,154,22,153,17,148,16,141,16,132,19,126,25,120"
                                state="teeth13" (click)="selected_teethimage('13')" />

                            <area alt="" id="teeth11" title="" shape="poly"
                                coords="39,86,46,89,51,92,56,93,56,101,54,109,53,118,49,121,47,123,38,123,31,120,25,117,22,111,24,104,26,97,29,90,33,86"
                                state="teeth11" (click)="selected_teethimage('11')" />

                            <area alt="" id="teeth9" title="" shape="poly"
                                coords="47,60,53,64,56,66,63,70,64,77,62,85,60,88,56,90,51,89,46,87,41,85,36,81,34,73,38,65"
                                state="teeth9" (click)="selected_teethimage('9')" />

                            <area alt="" id="teeth7" title="" shape="poly"
                                coords="65,40,70,46,75,50,76,55,76,60,74,66,70,66,64,66,59,64,53,62,50,58,49,51,54,45"
                                state="teeth7" (click)="selected_teethimage('7')" />

                            <area alt="" id="teeth5" title="" shape="poly"
                                coords="67,39,71,32,78,29,84,34,87,41,85,49,80,51,73,49,67,41" state="teeth5"
                                state="teeth5" (click)="selected_teethimage('5')" />

                            <area alt="" id="teeth3" title="" shape="poly"
                                coords="98,42,101,41,103,38,103,35,104,31,103,26,102,22,99,20,95,19,91,18,87,19,85,21,84,24,83,27,84,31,87,35,89,38,93,41"
                                state="teeth3" (click)="selected_teethimage('3')" />

                            <area alt="" id="teeth1" title="" shape="poly"
                                coords="113,13,121,15,127,20,126,27,124,35,120,40,115,40,110,38,107,32,104,25,104,17"
                                state="teeth1" (click)="selected_teethimage('1')" />

                            <area alt="" id="teeth2" title="" shape="poly"
                                coords="128,19,135,14,145,16,150,22,149,25,148,29,146,33,143,38,139,41,136,42,132,40,129,33,127,27"
                                state="teeth2" (click)="selected_teethimage('2')" />

                            <area alt="" id="teeth4" title="" shape="poly"
                                coords="148,37,149,31,150,26,154,21,161,22,165,23,168,27,169,32,166,35,164,40,158,43,153,44,149,43"
                                state="teeth4" (click)="selected_teethimage('4')" />

                            <area alt="" id="teeth6" title="" shape="poly"
                                coords="167,51,171,52,175,50,178,48,180,44,181,40,180,36,177,33,174,31,170,31,168,34,165,37,163,41,161,46,163,51"
                                state="teeth6" (click)="selected_teethimage('6')" />

                            <area alt="" id="teeth8" title="" shape="poly"
                                coords="171,55,175,50,179,47,183,42,190,42,195,48,197,53,197,58,194,61,190,64,187,65,184,66,179,68,174,65,173,62"
                                state="teeth8" (click)="selected_teethimage('8')" />

                            <area alt="" id="teeth10" title="" shape="poly"
                                coords="183,70,187,67,193,63,201,61,208,64,211,69,211,77,209,84,204,86,198,89,192,90,188,89,184,83,183,75"
                                state="teeth10" (click)="selected_teethimage('10')" />

                            <area alt="" id="teeth12" title="" shape="poly"
                                coords="196,90,202,88,209,85,214,86,218,90,221,96,222,101,224,107,224,113,220,118,214,121,209,122,202,122,196,120,194,107,191,97"
                                state="teeth12" (click)="selected_teethimage('12')" />
                            <area alt="" id="teeth14" title="" shape="poly"
                                coords="211,123,217,120,222,121,228,125,230,132,231,139,230,148,225,153,217,154,209,154,202,152,201,142,198,134,199,125"
                                state="teeth14" (click)="selected_teethimage('14')" />
                            <area alt="" title="" id="teeth16" shape="poly"
                                coords="208,155,215,156,223,154,230,156,235,165,234,174,229,183,221,187,211,187,203,180,202,163"
                                state="teeth16" (click)="selected_teethimage('16')" />
                            <area alt="" title="" id="teeth17" shape="poly"
                                coords="232,243,235,236,234,228,230,221,224,217,218,215,212,216,207,219,205,225,203,232,203,239,205,247,214,246,221,248,226,248"
                                state="teeth17" (click)="selected_teethimage('16')" />
                            <area alt="" title="" id="teeth18" shape="poly"
                                coords="206,248,216,249,226,251,230,256,231,263,232,268,230,272,228,277,225,281,219,283,214,281,210,279,203,278,199,276,197,268,202,252"
                                state="teeth18" (click)="selected_teethimage('14')" />
                            <area alt="" title="" id="teeth19" shape="poly"
                                coords="195,293,196,284,200,280,205,281,212,283,219,284,223,289,224,294,222,299,222,305,219,311,215,317,210,317,205,316,198,312,192,308"
                                state="teeth19" (click)="selected_teethimage('12')" />
                            <area alt="" title="" id="teeth20" shape="poly"
                                coords="182,327,183,321,186,316,191,313,198,314,206,318,211,322,212,330,209,336,206,339,202,341,199,342,193,340,185,336"
                                state="teeth20" (click)="selected_teethimage('10')" />
                            <area alt="" title="" id="teeth21" shape="poly"
                                coords="170,350,175,355,179,360,185,363,192,359,195,355,197,349,195,342,188,338,180,336,173,338"
                                state="teeth21" (click)="selected_teethimage('8')" />
                            <area alt="" title="" id="teeth22" shape="poly"
                                coords="155,349,161,347,167,351,174,356,176,362,177,370,174,374,167,376,161,371,156,363"
                                state="teeth22" (click)="selected_teethimage('6')" />
                            <area alt="" title="" id="teeth23" shape="poly"
                                coords="145,354,151,358,154,364,157,370,160,375,155,379,148,382,143,380,141,371,141,359"
                                state="teeth23" (click)="selected_teethimage('4')" />
                            <area alt="" title="" id="teeth24" shape="poly"
                                coords="131,356,137,364,139,372,140,380,136,385,129,386,123,382,123,366,125,360"
                                state="teeth24" (click)="selected_teethimage('2')" />
                            <area alt="" title="" id="teeth25" shape="poly"
                                coords="112,357,117,361,120,367,120,374,120,383,117,386,108,385,104,382,106,364"
                                state="teeth25" (click)="selected_teethimage('1')" />
                            <area alt="" title="" id="teeth26" shape="poly"
                                coords="85,372,87,367,90,361,93,356,99,354,103,359,103,367,103,373,100,380,94,383,86,381"
                                state="teeth26" (click)="selected_teethimage('3')" />
                            <area alt="" title="" id="teeth27" shape="poly"
                                coords="79,375,86,368,89,361,90,354,89,348,83,347,76,351,70,357,67,366,69,372"
                                state="teeth27" (click)="selected_teethimage('5')" />
                            <area alt="" title="" id="teeth28" shape="poly"
                                coords="48,349,50,343,56,340,63,337,69,337,75,340,76,347,73,352,68,358,65,363,58,363,51,358"
                                state="teeth28" (click)="selected_teethimage('7')" />
                            <area alt="" title="" id="teeth29" shape="poly"
                                coords="35,332,34,330,34,328,34,325,35,323,36,321,37,319,39,318,42,317,45,316,48,314,51,313,54,313,57,313,59,314,61,316,62,318,63,320,64,323,64,325,65,328,64,330,63,332,62,335,60,337,57,338,55,339,53,340,50,342,48,342,45,342,43,341,40,340,39,338,36,336,35,334"
                                state="teeth29" (click)="selected_teethimage('9')" />
                            <area alt="" title="" id="teeth30" shape="poly"
                                coords="38,318,40,317,43,316,45,315,49,314,52,312,55,311,56,308,57,305,57,302,56,300,55,297,55,295,54,292,54,288,53,285,52,282,49,280,47,279,45,280,43,280,40,280,37,280,35,281,33,282,29,283,28,285,25,287,24,290,23,292,23,295,24,298,25,301,26,304,26,307,28,311,30,314,32,317,35,318"
                                state="teeth30" (click)="selected_teethimage('11')" />
                            <area alt="" title="" id="teeth31" shape="poly"
                                coords="22,250,25,249,28,249,32,249,36,248,40,248,42,249,44,251,46,254,46,257,46,259,46,261,48,263,49,267,50,270,49,273,48,276,46,279,43,279,38,279,36,279,34,280,32,282,29,283,25,283,22,280,20,278,18,274,16,270,16,266,15,262,16,258,18,254"
                                state="teeth31" (click)="selected_teethimage('13')" />
                            <area alt="" title="" id="teeth32" shape="poly"
                                coords="12,237,14,223,20,217,29,214,40,218,42,224,42,233,43,241,40,245,36,246,27,248,17,247"
                                state="teeth32" (click)="selected_teethimage('15')" />
                        </map>
                    </div>
                </div>
            </div>
            <div class="col-md-9 col-lg-9 col-sm-12 col-xl-9">
                <div class="row">
                    <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6 ">
                        <div class="col-md-6 col-lg-6 col-sm-12 col-xl-12 ">
                            <mat-label class="matlabel">Jaw type<br>
                                <select class="ipcss " [(ngModel)]="jaw_txt" required disableOptionCentering
                                    [disabled]="client" (change)="send_data()">
                                    <option value="Select" selected>Select</option>
                                    <option value="Upper">Upper</option>
                                    <option value="Lower">Lower</option>
                                </select>
                            </mat-label>
                        </div>
    
                        <div class="col-md-6 col-lg-6 col-sm-12 col-xl-12">
                            <mat-label class="matlabel">Colour<br>
                                <select class="ipcss " disableOptionCentering [(ngModel)]="color_of_teeth"
                                    [disabled]="client" (change)="send_data()">
                                    <option value="Select" selected>Select</option>
    
                                    <option *ngFor="let color of color_of_teeth_array" value={{color.teeth_color_id}}>
                                        {{color.description}}</option>
                                </select>
                            </mat-label>
                        </div>
                        
                        <div class="col-md-6 col-lg-6 col-sm-12 col-xl-12 ">
                            <mat-label class="matlabel">Teeth type<br>
                                <select class="ipcss " [(ngModel)]="teeth_type_txt" disableOptionCentering
                                    [disabled]="client" (change)="send_data()">
                                    <option value="Select">Select</option>
                                    <option value="Primary">Primary</option>
                                    <option value="Permenent">Permenent</option>
                                    <option value="Suplementry Teeth">Suplementry Teeth</option>
                                </select>
                            </mat-label>
                        </div>
                        <div class="col-md-6 col-lg-6 col-sm-12 col-xl-12">
                            <mat-label class="matlabel">Consistency<br>
                                <select class="ipcss " disableOptionCentering [(ngModel)]="consistency_of_teeth"
                                    [disabled]="client" (change)="send_data()">
                                    <option *ngFor="let consistency of consistency_of_teeth_array"
                                        value={{consistency.teeth_cons_id}}>{{consistency.description}}</option>
                                </select>
                            </mat-label>
                        </div>
                        <div class="col-md-6 col-lg-6 col-sm-12 col-xl-12 ">
                            <mat-label class="matlabel">Condition<br>
                                <select class="ipcss " disableOptionCentering [(ngModel)]="condition_of_teeth"
                                    [disabled]="client" (change)="send_data()">
                                    <option *ngFor="let condition of condition_of_teeth_array"
                                        value={{condition.den_issue_id}}>{{condition.description}}</option>
                                </select>
                            </mat-label>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6 ">
                        
                        <mat-label class="matlabel" *ngIf="!teeth_div">Teeth name<br>
                            <mat-list class="ipcss mat-list_style" disableOptionCentering multiple [disabled]="client" (change)="send_data()">
                                <mat-list-item class="mat-list_item_style" #matoption color="primary" *ngFor="let teeth of Get_teethname"
                                    value={{teeth.description}}>
                                    <mat-checkbox multiple
                                        [checked]="send_teeth_name_array.indexOf(teeth.den_teeth_name_id) > -1"
                                        (change)="teeth_click_new(teeth.den_teeth_name_id,matoption)">
                                        {{teeth.description}}
                                    </mat-checkbox>&nbsp;
                                </mat-list-item>
                            </mat-list>
                            <!-- <mat-select class="ipcss " disableOptionCentering multiple [disabled]="client"
                                (change)="send_data()">
                                <mat-option #matoption color="primary" *ngFor="let teeth of Get_teethname"
                                    value={{teeth.description}}>
                                    <mat-checkbox multiple
                                        [checked]="send_teeth_name_array.indexOf(teeth.den_teeth_name_id) > -1"
                                        (change)="teeth_click_new(teeth.den_teeth_name_id,matoption)">
                                        {{teeth.description}}
                                    </mat-checkbox>&nbsp;
                                </mat-option>
                            </mat-select> -->
                        </mat-label>
                    </div>

                    <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6 ">
                        <mat-label class="matlabel">Shape of teeth<br>
                            <select class="ipcss " disableOptionCentering [(ngModel)]="shape_of_teeth"
                                [disabled]="client" (change)="send_data()">
                                <option disabled selected>Select</option>
                                <option *ngFor="let shape of shape_of_teeth_array" value={{shape.shape_id}}>
                                    {{shape.description}}</option>
                            </select>
                        </mat-label>
                    </div>

                    <div class="col-md-5 col-lg-5 col-sm-12 col-xl-5">
                        <mat-label class="matlabel">ICD 10<br>
                            <input matInput class="ipcss" [(ngModel)]="icd10_txt" required [disabled]="client" />
                        </mat-label>
                    </div>

                    <div class="col-md-1 col-lg-1 col-sm-12 col-xl-1">
                        <img src="../../assets/img/icd.png" class="icd_img" (click)="open_icdcodeservice()"
                            [disabled]="client" />
                    </div>

                    <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6 ">
                        <mat-label class="matlabel">Note<br>
                            <textarea #matInput class="ipcss obsttxtareaheight " [(ngModel)]="note_data"
                                (blur)="send_data()" maxlength="250" [disabled]="client"></textarea>
                        </mat-label>
                    </div>

                    <div class="col-md-2 col-lg-2 col-sm-12 col-xl-2 " *ngIf="!client">
                        <a><img src="../../../assets/ui_icons/buttons/add_button.svg" (click)="clinical_click()"
                                class="saveimgbtn_inpatinfo add" /></a>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 col-lg-12 col-sm-12 col-xl-12 ">
                    <div class="dig_table_overflow table-responsive">
                        <table id="tbl" [hidden]="clinic_arry_list.length==0">
                            <tbody>
                                <tr>
                                    <th>Jaw type</th>
                                    <th>Teeth type</th>
                                    <th>Teeth name</th>
                                    <th>Teeth shape</th>
                                    <th>Condition</th>
                                    <th>Action</th>
                                </tr>
                                <tr *ngFor="let clinic of clinic_arry_list">
                                    <td style="text-align: center;">{{clinic.jaw_type}}</td>
                                    <td style="text-align: left;">{{clinic.teeth_type}}</td>
                                    <td style="text-align: left;">{{clinic.teethname}}</td>
                                    <td style="text-align: left;">{{clinic.teeth_shape_show}}</td>
                                    <td style="text-align: left;">{{clinic.condition}}</td>
                                    <td>
                                        <a (click)="clinic_delete(clinic.jaw_type,clinic.teeth_type,clinic.teethname,clinic.teeth_shape_show,clinic.condition) "
                                            [disabled]="client"><img src="../../../assets/ui_icons/trash_icon.svg"
                                                width="20px" /></a>
                                    </td>
                                </tr>
                        </table>
                    </div>
                </div>
            </div>
            <br>
        </div>
        <br>
        <div class="row ">
            <div class="col-md-6 col-lg-6 col-sm-12 col-xl-3 col-12">
                <mat-label class="matlabel" [hidden]="miss_teeth_hidden">Missing teeth<br>
                    <textarea #matInput class="ipcss obsttxtareaheight " maxlength="50" [disabled]="client"
                        [(ngModel)]="missing_teeth" (blur)="send_data()"></textarea>
                </mat-label>
            </div>

            <div class="col-md-6 col-lg-6 col-sm-12 col-xl-3 col-12">
                <mat-label class="matlabel" [hidden]="Calculus_hidden">Calculus<br>
                    <textarea #matInput class="ipcss obsttxtareaheight " maxlength="50" [disabled]="client"
                        [(ngModel)]="calculus_txt" (blur)="send_data()"></textarea>
                </mat-label>
            </div>

            <div class="col-md-6 col-lg-6 col-sm-12 col-xl-3 col-12">
                <mat-label class="matlabel" [hidden]="oral_hyg_hidden">Oral hygiene<br>
                    <textarea #matInput class="ipcss obsttxtareaheight " maxlength="50" [disabled]="client"
                        [(ngModel)]="oral_hygiene_txt" (blur)="send_data()"></textarea>
                </mat-label>
            </div>

            <div class="col-md-6 col-lg-6 col-sm-12 col-xl-3 col-12">
                <mat-label class="matlabel" [hidden]="swell_hidden">Swelling<br>
                    <textarea #matInput class="ipcss obsttxtareaheight " maxlength="50" [disabled]="client"
                        [(ngModel)]="swell_txt" (blur)="send_data()"></textarea>
                </mat-label>
            </div>

            <div class="col-md-6 col-lg-6 col-sm-12 col-xl-3 col-12">
                <mat-label class="matlabel" [hidden]="mal_hidden">Malocclusion<br>
                    <textarea #matInput class="ipcss obsttxtareaheight " maxlength="50" [disabled]="client"
                        [(ngModel)]="mal_occ_txt" (blur)="send_data()"></textarea>
                </mat-label>
            </div>

            <div class="col-md-6 col-lg-6 col-sm-12 col-xl-3 col-12">
                <mat-label class="matlabel" [hidden]="sup_hidden">Supraeruption<br>
                    <textarea #matInput class="ipcss obsttxtareaheight " maxlength="50" [disabled]="client"
                        [(ngModel)]="sup_erup_txt" (blur)="send_data()"></textarea>
                </mat-label>
            </div>

            <div class="col-md-6 col-lg-6 col-sm-12 col-xl-3 col-12">
                <mat-label class="matlabel" [hidden]="mob_hidden">Mobility<br>
                    <textarea #matInput class="ipcss obsttxtareaheight " maxlength="50" [disabled]="client"
                        [(ngModel)]="mobility_txt" (blur)="send_data()"></textarea>
                </mat-label>
            </div>

            <div class="col-md-6 col-lg-6 col-sm-12 col-xl-3 col-12">
                <mat-label class="matlabel" [hidden]="bleed_hidden">Bleeding<br>
                    <textarea #matInput class="ipcss obsttxtareaheight " maxlength="50" [disabled]="client"
                        [(ngModel)]="bleeding_txt" (blur)="send_data()"></textarea>
                </mat-label>
            </div>

            <div class="col-md-6 col-lg-6 col-sm-12 col-xl-3 col-12">
                <mat-label class="matlabel" [hidden]="stains_hidden">Stains<br>
                    <textarea #matInput class="ipcss obsttxtareaheight " maxlength="50" [disabled]="client"
                        [(ngModel)]="stains_txt" (blur)="send_data()"> </textarea>
                </mat-label>
            </div>

            <div class="col-md-6 col-lg-6 col-sm-12 col-xl-3 col-12">
                <mat-label class="matlabel" [hidden]="dental_hidden">Dental caries<br>
                    <textarea #matInput class="ipcss obsttxtareaheight " maxlength="50" [disabled]="client"
                        [(ngModel)]="dental_carries_txt" (blur)="send_data()"></textarea>
                </mat-label>
            </div>

            <div class="col-md-6 col-lg-6 col-sm-12 col-xl-3 col-12">
                <mat-label class="matlabel" [hidden]="root_hidden">Root system<br>
                    <textarea #matInput class="ipcss obsttxtareaheight " maxlength="50" [disabled]="client"
                        [(ngModel)]="root_stem_txt" (blur)="send_data()"></textarea>
                </mat-label>
            </div>

            <div class="col-md-6 col-lg-6 col-sm-12 col-xl-3 col-12">
                <mat-label class="matlabel" [hidden]="other_hidden">Other<br>
                    <textarea #matInput class="ipcss obsttxtareaheight " maxlength="50" [disabled]="client"
                        [(ngModel)]="other_txt" (blur)="send_data()"></textarea>
                </mat-label>
            </div>
        </div>

        <div [hidden]="perio_div" class="perioClass">
            <mat-accordion class="mataccordion">
                <mat-expansion-panel hideToggle class="matexpandpanel" (opened)="panelOpenState3 = true"
                    (closed)="panelOpenState3 = false">
                    <mat-expansion-panel-header class="exppanel ">
                        <mat-panel-title class="title width">
                            Perio dental chart&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <mat-icon *ngIf="!panelOpenState3">add</mat-icon>
                            <mat-icon *ngIf="panelOpenState3">remove</mat-icon>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="row">
                        <div class="col-12 col-md-12 col-lg-12 col-xl-2">
                            <mat-label class=matlabel>Mobility</mat-label>
                        </div>
                        <div class="col-12 col-md-12 col-lg-12 col-xl-10">
                            <table ng-table="Upper_Value_Table" class="Chart_table mobility_data">
                                <thead>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td *ngFor="let Mobility of Upper_Mobility_List;">
                                            <select [(ngModel)]="Mobility.Value" class="Drop_Class borderng"
                                                [disabled]="client" (change)="send_data()">
                                                <option value="0">0</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                            </select>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-12 col-lg-12 col-xl-2">
                            <mat-label class=matlabel>Implant</mat-label>
                        </div>
                        <div class="col-12 col-md-12 col-lg-12 col-xl-10">
                            <table ng-table="Upper_Value_Table" class="Chart_table mobility_data">
                                <thead>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td *ngFor="let Implant of Upper_Implant_List">
                                            <mat-checkbox color="primary" class="implant_check"
                                                [checked]="Implant.Check" [disabled]="client"
                                                (change)="send_data()"></mat-checkbox>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-12 col-lg-12 col-xl-2">
                            <mat-label class=matlabel>Furcation</mat-label>
                        </div>
                        <div class="col-12 col-md-12 col-lg-12 col-xl-10">
                            <table ng-table="Upper_Value_Table" class="Chart_table mobility_data">
                                <thead>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td *ngFor="let Furcation of Upper_Furcation_List">
                                            <mat-select class="Drop_Class borderng" [(ngModel)]="Furcation.Value"
                                                [disabled]="client" (change)="send_data()">
                                                <mat-option value="0">0</mat-option>
                                                <mat-option value="1">1</mat-option>
                                                <mat-option value="2">2</mat-option>
                                                <mat-option value="3">3</mat-option>
                                            </mat-select>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-12 col-lg-12 col-xl-2">
                            <mat-label class=matlabel>Bleed & probe</mat-label>
                        </div>
                        <div class="col-12 col-md-12 col-lg-12 col-xl-10">
                            <table ng-table="Upper_Value_Table" class="Chart_table mobility_data">
                                <thead>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td *ngFor="let BleedProbe of Upper_BleedProbe_List" class="bledding_data">
                                            <div class="probe_teeth"
                                                (click)="Upper_BleedProbe_Btnclick(BleedProbe.Value, Upper_BleedProbe_List.indexOf(BleedProbe))"
                                                [disabled]="client">
                                                <input type="button" [ngStyle]="BleedProbe.Style"
                                                    (click)="Upper_BleedProbe_Btnclick(BleedProbe.Value, Upper_BleedProbe_List.indexOf(BleedProbe))"
                                                    [disabled]="client" />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-12 col-lg-12 col-xl-2">
                            <mat-label class=matlabel>Plaque</mat-label>
                        </div>
                        <div class="col-12 col-md-12 col-lg-12 col-xl-10">
                            <table ng-table="Upper_Value_Table" class="Chart_table mobility_data">
                                <thead>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td *ngFor="let Plaque of Upper_Plaque_List" class="bledding_data">
                                            <div class="probe_teeth"
                                                (click)="Upper_Plaque_Btnclick(Plaque.Value, Upper_Plaque_List.indexOf(Plaque))"
                                                [disabled]="client">
                                                <input type="button" [ngStyle]="Plaque.Style"
                                                    (click)="Upper_Plaque_Btnclick(Plaque.Value, Upper_Plaque_List.indexOf(Plaque))"
                                                    [disabled]="client" />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-12 col-lg-12 col-xl-2">
                            <mat-label class=matlabel>Gingival margin</mat-label>
                        </div>
                        <div class="col-12 col-md-12 col-lg-12 col-xl-10">
                            <table ng-table="Upper_Value_Table" class="Chart_table mobility_data">
                                <thead>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td *ngFor="let Gingival of Upper_Gingival_List" class="bledding_data">
                                            <mat-checkbox color="primary" class="implant_check"
                                                [checked]="Gingival.Check" [disabled]="client"></mat-checkbox>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-12 col-lg-12 col-xl-2">
                            <mat-label class=matlabel>Probing depth</mat-label>
                        </div>
                        <div class="col-12 col-md-12 col-lg-12 col-xl-10">
                            <table ng-table="Upper_Value_Table" class="Chart_table mobility_data">
                                <thead>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="probingtee">
                                            <input type="text" maxlength="1" placeholder="0-0-0" class="probtxttest"
                                                value="0-0-0" (click)="upper_probing_depth('18',Value_18_01)"
                                                [(ngModel)]="Value_18_01" [disabled]="client" />
                                        </td>
                                        <td class="probingtee">
                                            <input type="text" maxlength="1" placeholder="0-0-0" class="probtxttest"
                                                value="0-0-0" (click)="upper_probing_depth('17',Value_17_01)"
                                                [(ngModel)]="Value_17_01" [disabled]="client" />
                                        </td>
                                        <td class="probingtee">
                                            <input type="text" maxlength="1" placeholder="0-0-0" class="probtxttest"
                                                value="0-0-0" (click)="upper_probing_depth('16',Value_16_01)"
                                                [(ngModel)]="Value_16_01" [disabled]="client" />
                                        </td>
                                        <td class="probingtee">
                                            <input type="text" maxlength="1" placeholder="0-0-0" class="probtxttest"
                                                value="0-0-0" (click)="upper_probing_depth('15',Value_15_01)"
                                                [(ngModel)]="Value_15_01" [disabled]="client" />
                                        </td>
                                        <td class="probingtee">
                                            <input type="text" maxlength="1" placeholder="0-0-0" class="probtxttest"
                                                value="0-0-0" (click)="upper_probing_depth('14',Value_14_01)"
                                                [(ngModel)]="Value_14_01" [disabled]="client" />
                                        </td>
                                        <td class="probingtee">
                                            <input type="text" maxlength="1" placeholder="0-0-0" class="probtxttest"
                                                value="0-0-0" (click)="upper_probing_depth('13',Value_13_01)"
                                                [(ngModel)]="Value_13_01" [disabled]="client" />
                                        </td>
                                        <td class="probingtee">
                                            <input type="text" maxlength="1" placeholder="0-0-0" class="probtxttest"
                                                value="0-0-0" (click)="upper_probing_depth('12',Value_12_01)"
                                                [(ngModel)]="Value_12_01" [disabled]="client" />
                                        </td>
                                        <td class="probingtee">
                                            <input type="text" maxlength="1" placeholder="0-0-0" class="probtxttest"
                                                value="0-0-0" (click)="upper_probing_depth('11',Value_11_01)"
                                                [(ngModel)]="Value_11_01" [disabled]="client" />
                                        </td>
                                        <td class="probingtee">
                                            <input type="text" maxlength="1" placeholder="0-0-0" class="probtxttest"
                                                value="0-0-0" (click)="upper_probing_depth('21',Value_21_01)"
                                                [(ngModel)]="Value_21_01" [disabled]="client" />
                                        </td>
                                        <td class="probingtee">
                                            <input type="text" maxlength="1" placeholder="0-0-0" class="probtxttest"
                                                value="0-0-0" (click)="upper_probing_depth('22',Value_23_01)"
                                                [(ngModel)]="Value_22_01" [disabled]="client" />
                                        </td>
                                        <td class="probingtee">
                                            <input type="text" maxlength="1" placeholder="0-0-0" class="probtxttest"
                                                value="0-0-0" (click)="upper_probing_depth('23',Value_23_01)"
                                                [(ngModel)]="Value_23_01" [disabled]="client" />
                                        </td>
                                        <td class="probingtee">
                                            <input type="text" maxlength="1" placeholder="0-0-0" class="probtxttest"
                                                value="0-0-0" (click)="upper_probing_depth('24',Value_24_01)"
                                                [(ngModel)]="Value_24_01" [disabled]="client" />
                                        </td>
                                        <td class="probingtee">
                                            <input type="text" maxlength="1" placeholder="0-0-0" class="probtxttest"
                                                value="0-0-0" (click)="upper_probing_depth('25',Value_25_01)"
                                                [(ngModel)]="Value_25_01" [disabled]="client" />
                                        </td>
                                        <td class="probingtee">
                                            <input type="text" maxlength="1" placeholder="0-0-0" class="probtxttest"
                                                value="0-0-0" (click)="upper_probing_depth('26',Value_26_01)"
                                                [(ngModel)]="Value_26_01" [disabled]="client" />
                                        </td>
                                        <td class="probingtee">
                                            <input type="text" maxlength="1" placeholder="0-0-0" class="probtxttest"
                                                value="0-0-0" (click)="upper_probing_depth('27',Value_27_01)"
                                                [(ngModel)]="Value_27_01" [disabled]="client" />
                                        </td>
                                        <td class="probingtee">
                                            <input type="text" maxlength="1" placeholder="0-0-0" class="probtxttest"
                                                value="0-0-0" (click)="upper_probing_depth('28',Value_28_01)"
                                                [(ngModel)]="Value_28_01" [disabled]="client" />
                                        </td>

                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-12 col-lg-12 col-xl-2">
                            <mat-label class=matlabel>Palpation</mat-label>
                        </div>
                        <div class="col-12 col-md-12 col-lg-12 col-xl-10">
                            <table ng-table="Upper_Value_Table" class="Chart_table mobility_data">
                                <thead>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td *ngFor="let Palpation of Upper_Palpation_List">
                                            <select class="Drop_Class" [(ngModel)]="Palpation.Value" [disabled]="client"
                                                (change)="send_data()">
                                                <option>0</option>
                                                <option>1</option>
                                                <option>2</option>
                                                <option>3</option>
                                            </select>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-12 col-lg-12 col-xl-2">
                            <mat-label class=matlabel>Attachment lose</mat-label>
                        </div>
                        <div class="col-12 col-md-12 col-lg-12 col-xl-10">
                            <table ng-table="Upper_Value_Table" class="Chart_table mobility_data">
                                <thead>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td *ngFor="let Attachment of Upper_Attachment_List">
                                            <input type="checkbox" class="implant_check" [(ngModel)]="Attachment.Check"
                                                (change)="send_data()" checked="Attachment.Check" [disabled]="client" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-12 col-lg-12 col-xl-2">
                            <mat-label class=matlabel>Recession</mat-label>
                        </div>
                        <div class="col-12 col-md-12 col-lg-12 col-xl-10">
                            <table ng-table="Upper_Value_Table" class="Chart_table mobility_data">
                                <thead>
                                </thead>
                                <tbody>
                                    <tr>

                                        <td *ngFor="let Recession of Upper_Recession_List">
                                            <select class="Drop_Class" [(ngModel)]="Recession.Value" [disabled]="client"
                                                (change)="send_data()">
                                                <option>0</option>
                                                <option>1</option>
                                                <option>2</option>
                                                <option>3</option>
                                            </select>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 col-md-12 col-lg-12 col-xl-2">
                            <mat-label class=matlabel></mat-label>
                        </div>
                        <div class="col-12 col-md-12 col-lg-12 col-xl-10">
                            <table ng-table="Upper_Value_Table" class="Chart_table mobility_data">
                                <thead>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div class="Empty_Class">18</div>
                                        </td>
                                        <td>
                                            <div class="Empty_Class">17</div>
                                        </td>
                                        <td>
                                            <div class="Empty_Class">16</div>
                                        </td>
                                        <td>
                                            <div class="Empty_Class">15</div>
                                        </td>
                                        <td>
                                            <div class="Empty_Class">14</div>
                                        </td>
                                        <td>
                                            <div class="Empty_Class">13</div>
                                        </td>
                                        <td>
                                            <div class="Empty_Class">12</div>
                                        </td>
                                        <td>
                                            <div class="Empty_Class">11</div>
                                        </td>
                                        <td>
                                            <div class="Empty_Class">21</div>
                                        </td>
                                        <td>
                                            <div class="Empty_Class">22</div>
                                        </td>
                                        <td>
                                            <div class="Empty_Class">23</div>
                                        </td>
                                        <td>
                                            <div class="Empty_Class">24</div>
                                        </td>
                                        <td>
                                            <div class="Empty_Class">25</div>
                                        </td>
                                        <td>
                                            <div class="Empty_Class">26</div>
                                        </td>
                                        <td>
                                            <div class="Empty_Class">27</div>
                                        </td>
                                        <td>
                                            <div class="Empty_Class">28</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    
                    <br />
                    <div class="row">
                        <div class="col-12 col-xl-2"></div>
                        <div class="col-12 col-md-12 col-lg-12 col-xl-10">
                            <div class="Doc_Teeth_Upper_group">
                                <span class="upper_line"></span>
                                <p class="Upper_dental_1" id="dental_upper1"></p>
                                <p class="Upper_dental_1" id="dental_upper2"></p>
                                <p class="Upper_dental_1" id="dental_upper3"></p>
                                <p class="Upper_dental_1" id="dental_upper4"></p>
                                <p class="Upper_dental_1" id="dental_upper5"></p>
                                <p class="Upper_dental_1" id="dental_upper6"></p>
                                <p class="Upper_dental_1" id="dental_upper7"></p>
                                <p class="Upper_dental_1" id="dental_upper8"></p>
                                <p class="Upper_dental_1" id="dental_upper9"></p>
                                <p class="Upper_dental_1" id="dental_upper10"></p>
                                <p class="Upper_dental_1" id="dental_upper11"></p>
                                <p class="Upper_dental_1" id="dental_upper12"></p>
                                <p class="Upper_dental_1" id="dental_upper13"></p>
                                <p class="Upper_dental_1" id="dental_upper14"></p>
                                <p class="Upper_dental_1" id="dental_upper15"></p>
                                <p class="Upper_dental_1" id="dental_upper16"></p>
                            </div>
                            <div class="row">
                                <div class="col-md-12" style="margin-top: -47px;margin-left: 8px;">
                                    <div class="form-horizontal">
                                        <div class="col-md-12">
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label class="col-md-3 control-label"></label>
                                                    <div class="col-md-12">
                                                        <table ng-table="Upper_Value_Table"
                                                            class="Chart_table upper_chart uppergraphClass"
                                                            style="width: 100%;height: 100px;">
                                                            <thead>
                                                            </thead>
                                                            <tbody>
                                                                <tr>

                                                                    <td style="width: 44px;">
                                                                        <div id="Upper_18_Chart"
                                                                            [ngStyle]="Upper_18_Style"></div>
                                                                    </td>
                                                                    <td style="width: 48px;">
                                                                        <div id="Upper_17_Chart"
                                                                            [ngStyle]="Upper_17_Style"></div>
                                                                    </td>
                                                                    <td style="width: 53px;">
                                                                        <div id="Upper_16_Chart"
                                                                            [ngStyle]="Upper_16_Style"></div>
                                                                    </td>
                                                                    <td style="width: 44px;">
                                                                        <div id="Upper_15_Chart"
                                                                            [ngStyle]="Upper_15_Style"></div>
                                                                    </td>
                                                                    <td style="width: 44px;">
                                                                        <div id="Upper_14_Chart"
                                                                            [ngStyle]="Upper_14_Style"></div>
                                                                    </td>
                                                                    <td style="width: 44px;">
                                                                        <div id="Upper_13_Chart"
                                                                            [ngStyle]="Upper_13_Style"></div>
                                                                    </td>
                                                                    <td style="width: 44px;">
                                                                        <div id="Upper_12_Chart"
                                                                            [ngStyle]="Upper_12_Style"></div>
                                                                    </td>
                                                                    <td style="width: 46px;">
                                                                        <div id="Upper_11_Chart"
                                                                            [ngStyle]="Upper_11_Style"></div>
                                                                    </td>
                                                                    <td style="width: 56px;">
                                                                        <div id="Upper_21_Chart"
                                                                            [ngStyle]="Upper_21_Style"></div>
                                                                    </td>
                                                                    <td style="width: 44px;">
                                                                        <div id="Upper_22_Chart"
                                                                            [ngStyle]="Upper_22_Style"></div>
                                                                    </td>
                                                                    <td style="width: 52px;">
                                                                        <div id="Upper_23_Chart"
                                                                            [ngStyle]="Upper_23_Style"></div>
                                                                    </td>
                                                                    <td style="width: 46px;">
                                                                        <div id="Upper_24_Chart"
                                                                            [ngStyle]="Upper_24_Style"></div>
                                                                    </td>
                                                                    <td style="width: 49px;">
                                                                        <div id="Upper_25_Chart"
                                                                            [ngStyle]="Upper_25_Style"></div>
                                                                    </td>
                                                                    <td style="width: 55px;">
                                                                        <div id="Upper_26_Chart"
                                                                            [ngStyle]="Upper_26_Style"></div>
                                                                    </td>
                                                                    <td style="width: 46px;">
                                                                        <div id="Upper_27_Chart"
                                                                            [ngStyle]="Upper_27_Style"></div>
                                                                    </td>
                                                                    <td style="">
                                                                        <div id="Upper_28_Chart"
                                                                            [ngStyle]="Upper_28_Style"></div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />

                    <!-- <div class="row">
                        <div class="col-12 col-xl-2"></div>
                        <div class="col-12 col-md-12 col-lg-12 col-xl-10">
                            <table ng-table="Upper_Value_Table" class="Chart_table chart_txt">
                                <thead>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div class="table_width"></div>
                                        </td>
                                        <td *ngFor="let Upper_Image of Upper_Image_List ; let i=index">
                                            <input type="image" src="{{Upper_Image.Imgpath}}" class="upper_imagetxt"
                                                (click)="Upper_Image_View_Click(Upper_Image.Imgpath, Upper_Image.Teeth, Upper_Image.Value,i)" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table ng-table="Upper_Value_Table" class="Chart_table mobility_data chrat_value">
                                <thead>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div style="width: 0px;"></div>
                                        </td>
                                        <td *ngFor="let Lower_Image of Lower_Image_List;let i=index">
                                            <input type="image" src="{{Lower_Image.Imgpath}}" class="upper_imagetxt"
                                                (click)="Lower_Image_View_Click(Lower_Image.Imgpath, Lower_Image.Teeth, Lower_Image.Value,i)" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div> -->
                    <div class="row">
                        <div class="col-12 col-xl-2"></div>
                        <div class="col-12 col-md-12 col-lg-12 col-xl-10" style="height: 83px;">
                            <div class="Doc_Teeth_Lower_group">
                                <span class="lower_line"></span>
                                <p class="Upper_dental_1" id="dental_lower1"></p>
                                <p class="Upper_dental_1" id="dental_lower2"></p>
                                <p class="Upper_dental_1" id="dental_lower3"></p>
                                <p class="Upper_dental_1" id="dental_lower4"></p>
                                <p class="Upper_dental_1" id="dental_lower5"></p>
                                <p class="Upper_dental_1" id="dental_lower6"></p>
                                <p class="Upper_dental_1" id="dental_lower7"></p>
                                <p class="Upper_dental_1" id="dental_lower8"></p>
                                <p class="Upper_dental_1" id="dental_lower9"></p>
                                <p class="Upper_dental_1" id="dental_lower10"></p>
                                <p class="Upper_dental_1" id="dental_lower11"></p>
                                <p class="Upper_dental_1" id="dental_lower12"></p>
                                <p class="Upper_dental_1" id="dental_lower13"></p>
                                <p class="Upper_dental_1" id="dental_lower14"></p>
                                <p class="Upper_dental_1" id="dental_lower15"></p>
                                <p class="Upper_dental_1" id="dental_lower16"></p>
                            </div>
                            <div class="row">
                                <div class="col-md-12" style="margin-top: -22px;">
                                    <div class="form-horizontal">
                                        <div class="col-md-12">
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <span class="col-md-3 control-label"></span>
                                                    <div class="col-md-12">
                                                        <table ng-table="Upper_Value_Table"
                                                            class="Chart_table upper_chart lower_teeth_Class lowergraphClass">
                                                            <thead>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td style="width: 46px;">
                                                                        <div id="Lower_48_Chart"
                                                                            [ngStyle]="Lower_48_Chart_style"></div>
                                                                    </td>
                                                                    <td style="width: 51px;">
                                                                        <div id="Lower_47_Chart"
                                                                            [ngStyle]="Lower_47_Chart_style"></div>
                                                                    </td>
                                                                    <td style="width: 51px;">
                                                                        <div id="Lower_46_Chart"
                                                                            [ngStyle]="Lower_46_Chart_style"></div>
                                                                    </td>
                                                                    <td style="width: 55px;">
                                                                        <div id="Lower_45_Chart"
                                                                            [ngStyle]="Lower_45_Chart_style"></div>
                                                                    </td>
                                                                    <td style="width: 46px;">
                                                                        <div id="Lower_44_Chart"
                                                                            [ngStyle]="Lower_44_Chart_style"></div>
                                                                    </td>
                                                                    <td style="width: 55px;">
                                                                        <div id="Lower_43_Chart"
                                                                            [ngStyle]="Lower_43_Chart_style"></div>
                                                                    </td>
                                                                    <td style="width: 46px;">
                                                                        <div id="Lower_42_Chart"
                                                                            [ngStyle]="Lower_42_Chart_style"></div>
                                                                    </td>
                                                                    <td style="width: 47px;">
                                                                        <div id="Lower_41_Chart"
                                                                            [ngStyle]="Lower_41_Chart_style"></div>
                                                                    </td>
                                                                    <td style="width: 46px;">
                                                                        <div id="Lower_31_Chart"
                                                                            [ngStyle]="Lower_31_Chart_style"></div>
                                                                    </td>
                                                                    <td style="width: 40px;">
                                                                        <div id="Lower_32_Chart"
                                                                            [ngStyle]="Lower_32_Chart_style"></div>
                                                                    </td>
                                                                    <td style="width: 46px;">
                                                                        <div id="Lower_33_Chart"
                                                                            [ngStyle]="Lower_33_Chart_style"></div>
                                                                    </td>
                                                                    <td style="width: 46px;">
                                                                        <div id="Lower_34_Chart"
                                                                            [ngStyle]="Lower_34_Chart_style"></div>
                                                                    </td>
                                                                    <td style="width: 51px;">
                                                                        <div id="Lower_35_Chart"
                                                                            [ngStyle]="Lower_35_Chart_style"></div>
                                                                    </td>
                                                                    <td style="width: 47px;">
                                                                        <div id="Lower_36_Chart"
                                                                            [ngStyle]="Lower_36_Chart_style"></div>
                                                                    </td>
                                                                    <td style="width: 46px;">
                                                                        <div id="Lower_37_Chart"
                                                                            [ngStyle]="Lower_37_Chart_style"></div>
                                                                    </td>
                                                                    <td>
                                                                        <div id="Lower_38_Chart"
                                                                            [ngStyle]="Lower_38_Chart_style"></div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 col-xl-2"></div>
                        <div class="col-12 col-md-12 col-lg-12 col-xl-10">
                            <table ng-table="Upper_Value_Table" class="Chart_table mobility_data">
                                <thead>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div class="Drop_Class">48</div>
                                        </td>
                                        <td>
                                            <div class="Drop_Class">47</div>
                                        </td>
                                        <td>
                                            <div class="Drop_Class">46</div>
                                        </td>
                                        <td>
                                            <div class="Drop_Class">45</div>
                                        </td>
                                        <td>
                                            <div class="Drop_Class">44</div>
                                        </td>
                                        <td>
                                            <div class="Drop_Class">43</div>
                                        </td>
                                        <td>
                                            <div class="Drop_Class">42</div>
                                        </td>
                                        <td>
                                            <div class="Drop_Class">41</div>
                                        </td>
                                        <td>
                                            <div class="Drop_Class">31</div>
                                        </td>
                                        <td>
                                            <div class="Drop_Class">32</div>
                                        </td>
                                        <td>
                                            <div class="Drop_Class">33</div>
                                        </td>
                                        <td>
                                            <div class="Drop_Class">34</div>
                                        </td>
                                        <td>
                                            <div class="Drop_Class">35</div>
                                        </td>
                                        <td>
                                            <div class="Drop_Class">36</div>
                                        </td>
                                        <td>
                                            <div class="Drop_Class">37</div>
                                        </td>
                                        <td>
                                            <div class="Drop_Class">38</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 col-md-12 col-lg-12 col-xl-2">
                            <mat-label class=matlabel>Mobility</mat-label>
                        </div>
                        <div class="col-12 col-md-12 col-lg-12 col-xl-10">
                            <table ng-table="Upper_Value_Table" class="Chart_table mobility_data">
                                <thead>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td *ngFor="let Mobility of Lower_Mobility_List">
                                            <select [(ngModel)]="Mobility.Value" class="Drop_Class" [disabled]="client"
                                                (change)="send_data()">
                                                <option>0</option>
                                                <option>1</option>
                                                <option>2</option>
                                                <option>3</option>
                                            </select>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-12 col-lg-12 col-xl-2">
                            <mat-label class=matlabel>Implant</mat-label>
                        </div>
                        <div class="col-12 col-md-12 col-lg-12 col-xl-10">
                            <table ng-table="Upper_Value_Table" class="Chart_table mobility_data">
                                <thead>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td *ngFor="let Implant of Lower_Implant_List">
                                            <input type="checkbox" class="implant_check" [(ngModel)]="Implant.Check"
                                                [checked]="Implant.Check" [disabled]="client" (change)="send_data()" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-12 col-lg-12 col-xl-2">
                            <mat-label class=matlabel>Furcation</mat-label>
                        </div>
                        <div class="col-12 col-md-12 col-lg-12 col-xl-10">
                            <table ng-table="Upper_Value_Table" class="Chart_table mobility_data">
                                <thead>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td *ngFor="let Furcation of Lower_Furcation_List">
                                            <select class="Drop_Class" [(ngModel)]="Furcation.Value" [disabled]="client"
                                                (change)="send_data()">
                                                <option>0</option>
                                                <option>1</option>
                                                <option>2</option>
                                                <option>3</option>
                                            </select>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-12 col-lg-12 col-xl-2">
                            <mat-label class=matlabel>Bleed & Probe</mat-label>
                        </div>
                        <div class="col-12 col-md-12 col-lg-12 col-xl-10">
                            <table ng-table="Upper_Value_Table" class="Chart_table mobility_data">
                                <thead>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td *ngFor="let BleedProbe of Lower_BleedProbe_List" class="bledding_data">
                                            <div class="probe_teeth"
                                                (click)="Lower_BleedProbe_Btnclick(BleedProbe.Value, Lower_BleedProbe_List.indexOf(BleedProbe))"
                                                [disabled]="client">
                                                <input type="button" [ngStyle]="BleedProbe.Style"
                                                    (click)="Lower_BleedProbe_Btnclick(BleedProbe.Value, Lower_BleedProbe_List.indexOf(BleedProbe))"
                                                    [disabled]="client" />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-12 col-lg-12 col-xl-2">
                            <mat-label class=matlabel>Plaque</mat-label>
                        </div>
                        <div class="col-12 col-md-12 col-lg-12 col-xl-10">
                            <table ng-table="Upper_Value_Table" class="Chart_table mobility_data">
                                <thead>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td *ngFor="let Plaque of Lower_Plaque_List" class="bledding_data">
                                            <div class="probe_teeth"
                                                (click)="Lower_Plaque_Btnclick(Plaque.Value, Lower_Plaque_List.indexOf(Plaque))"
                                                [disabled]="client">
                                                <input type="button" [ngStyle]="Plaque.Style"
                                                    (click)="Lower_Plaque_Btnclick(Plaque.Value, Lower_Plaque_List.indexOf(Plaque))"
                                                    [disabled]="client" />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-12 col-lg-12 col-xl-2">
                            <mat-label class=matlabel>Gingival margin</mat-label>
                        </div>
                        <div class="col-12 col-md-12 col-lg-12 col-xl-10">
                            <table ng-table="Upper_Value_Table" class="Chart_table mobility_data">
                                <thead>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td *ngFor="let Gingival of Lower_Gingival_List">
                                            <input type="checkbox" class="implant_check" [(ngModel)]="Gingival.Check"
                                                [checked]="Gingival.Check" [disabled]="client" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-12 col-lg-12 col-xl-2">
                            <mat-label class=matlabel>Probing depth</mat-label>
                        </div>
                        <div class="col-12 col-md-12 col-lg-12 col-xl-10">
                            <table ng-table="Upper_Value_Table" class="Chart_table mobility_data">
                                <thead>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="probingtee">
                                            <input type="text" maxlength="1" placeholder="0-0-0" class="probtxttest"
                                                value="0" (click)="lower_probing_depth('48',Value_48_01)"
                                                [(ngModel)]="Value_48_01" [disabled]="client" />
                                        </td>
                                        <td class="probingtee">
                                            <input type="text" maxlength="1" placeholder="0-0-0" class="probtxttest"
                                                value="0" (click)="lower_probing_depth('47',Value_47_01)"
                                                [(ngModel)]="Value_47_01" [disabled]="client" />
                                        </td>
                                        <td class="probingtee">
                                            <input type="text" maxlength="1" placeholder="0-0-0" class="probtxttest"
                                                value="0" (click)="lower_probing_depth('46',Value_46_01)"
                                                [(ngModel)]="Value_46_01" [disabled]="client" />
                                        </td>
                                        <td class="probingtee">
                                            <input type="text" maxlength="1" placeholder="0-0-0" class="probtxttest"
                                                value="0" (click)="lower_probing_depth('45',Value_45_01)"
                                                [(ngModel)]="Value_45_01" [disabled]="client" />
                                        </td>
                                        <td class="probingtee">
                                            <input type="text" maxlength="1" placeholder="0-0-0" class="probtxttest"
                                                value="0" (click)="lower_probing_depth('44',Value_44_01)"
                                                [(ngModel)]="Value_44_01" [disabled]="client" />
                                        </td>
                                        <td class="probingtee">
                                            <input type="text" maxlength="1" placeholder="0-0-0" class="probtxttest"
                                                value="0" (click)="lower_probing_depth('43',Value_43_01)"
                                                [(ngModel)]="Value_43_01" [disabled]="client" />
                                        </td>
                                        <td class="probingtee">
                                            <input type="text" maxlength="1" placeholder="0-0-0" class="probtxttest"
                                                value="0" (click)="lower_probing_depth('42',Value_42_01)"
                                                [(ngModel)]="Value_42_01" [disabled]="client" />
                                        </td>
                                        <td class="probingtee">
                                            <input type="text" maxlength="1" placeholder="0-0-0" class="probtxttest"
                                                value="0" (click)="lower_probing_depth('41',Value_41_01)"
                                                [(ngModel)]="Value_41_01" [disabled]="client" />
                                        </td>
                                        <td class="probingtee">
                                            <input type="text" maxlength="1" placeholder="0-0-0" class="probtxttest"
                                                value="0" (click)="lower_probing_depth('31',Value_31_01)"
                                                [(ngModel)]="Value_31_01" [disabled]="client" />
                                        </td>
                                        <td class="probingtee">
                                            <input type="text" maxlength="1" placeholder="0-0-0" class="probtxttest"
                                                value="0" (click)="lower_probing_depth('32',Value_32_01)"
                                                [(ngModel)]="Value_32_01" [disabled]="client" />
                                        </td>
                                        <td class="probingtee">
                                            <input type="text" maxlength="1" placeholder="0-0-0" class="probtxttest"
                                                value="0" (click)="lower_probing_depth('33',Value_33_01)"
                                                [(ngModel)]="Value_33_01" [disabled]="client" />
                                        </td>
                                        <td class="probingtee">
                                            <input type="text" maxlength="1" placeholder="0-0-0" class="probtxttest"
                                                value="0" (click)="lower_probing_depth('34',Value_34_01)"
                                                [(ngModel)]="Value_34_01" [disabled]="client" />
                                        </td>
                                        <td class="probingtee">
                                            <input type="text" maxlength="1" placeholder="0-0-0" class="probtxttest"
                                                value="0" (click)="lower_probing_depth('35',Value_35_01)"
                                                [(ngModel)]="Value_35_01" [disabled]="client" />
                                        </td>
                                        <td class="probingtee">
                                            <input type="text" maxlength="1" placeholder="0-0-0" class="probtxttest"
                                                value="0" (click)="lower_probing_depth('36',Value_36_01)"
                                                [(ngModel)]="Value_36_01" [disabled]="client" />
                                        </td>
                                        <td class="probingtee">
                                            <input type="text" maxlength="1" placeholder="0-0-0" class="probtxttest"
                                                value="0" (click)="lower_probing_depth('37',Value_37_01)"
                                                [(ngModel)]="Value_37_01" [disabled]="client" />
                                        </td>
                                        <td class="probingtee">
                                            <input type="text" maxlength="1" placeholder="0-0-0" class="probtxttest"
                                                value="0" (click)="lower_probing_depth('38',Value_38_01)"
                                                [(ngModel)]="Value_38_01" [disabled]="client" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-12 col-lg-12 col-xl-2">
                            <mat-label class=matlabel>Palpation</mat-label>
                        </div>
                        <div class="col-12 col-md-12 col-lg-12 col-xl-10">
                            <table ng-table="Upper_Value_Table" class="Chart_table mobility_data">
                                <thead>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td *ngFor="let Palpation of Lower_Palpation_List">
                                            <select class="Drop_Class" [(ngModel)]="Palpation.Value" [disabled]="client"
                                                (change)="send_data()">
                                                <option>0</option>
                                                <option>1</option>
                                                <option>2</option>
                                                <option>3</option>
                                            </select>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-12 col-lg-12 col-xl-2">
                            <mat-label class=matlabel>Attachment lose</mat-label>
                        </div>
                        <div class="col-12 col-md-12 col-lg-12 col-xl-10">
                            <table ng-table="Upper_Value_Table" class="Chart_table mobility_data">
                                <thead>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td *ngFor="let Attachment of Lower_Attachment_List">
                                            <input type="checkbox" class="implant_check" [(ngModel)]="Attachment.Check"
                                                (change)="send_data()" checked="Attachment.Check" [disabled]="client" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-12 col-lg-12 col-xl-2">
                            <mat-label class=matlabel>Recession</mat-label>
                        </div>
                        <div class="col-12 col-md-12 col-lg-12 col-xl-10">
                            <table ng-table="Upper_Value_Table" class="Chart_table mobility_data">
                                <thead>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td *ngFor="let Recession of Lower_Recession_List">
                                            <select class="Drop_Class" [(ngModel)]="Recession.Value" [disabled]="client"
                                                (change)="send_data()">
                                                <option>0</option>
                                                <option>1</option>
                                                <option>2</option>
                                                <option>3</option>
                                            </select>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
</div>