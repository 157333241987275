<div>
  <div class="theader" mat-dialog-title>
    <h5 class="header_txt">Please provide reason for cancellation</h5>
    <img src="../../../assets/img/close_icon.png" (click)="ClosePopup()" width="20px" height="20px" id="close_icd" />
  </div>
  <div mat-dialog-content>
    <div class="row">
      <div class="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2"></div>
      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-8">
        <mat-label class="matlabel"><br>
          <textarea #matInput class="ipcss obsttxtareaheight widthappt" maxlength="50"
            [(ngModel)]="cancelTxt"></textarea>
        </mat-label>
      </div>
      <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-2"></div>
    </div>
    <div class="row">
      <div class="col-2 col-xl-2 col-md-2 col-lg-2 col-xl-2 padding">
        <img src="../../../assets/ui_icons/buttons/submit_button.svg" class="marginvalue saveimgbtn_inpatinfo"
          (click)="submit_click()" [mat-dialog-close]="cancelTxt" cdkFocusInitial mat-raised-button>
      </div>
    </div>
    <br>
  </div>