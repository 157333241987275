<div class="row" style="width:100%;margin:0 auto;">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white ">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Outsource centre</h5>
                    </div>
                    <div class="headerButtons">
                       
                        <img src="../../../assets/ui_icons/buttons/Back_button.svg" (click)="backClicked()"
                        class="saveimgbtn_inpatinfo " />
                       
                        <img class="saveimgbtn_inpatinfo" *ngIf="outsource_save" (click)="saveCentreDetails()" src="../../../assets/ui_icons/buttons/save_button.svg" />
                        <img class="saveimgbtn_inpatinfo" *ngIf="!outsource_save" (click)="saveCentreDetails()" src="../../../assets/ui_icons/buttons/update_button.svg" />
                    
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                        <mat-label class="matlabel">Center Name<br>
                            <input type="text" class="ipcss" required maxlength="50" [(ngModel)]="hospNameTxt"
                                (keyup)="filterHospital($event)" matInput [matAutocomplete]="auto10" />
                            <mat-autocomplete #auto10="matAutocomplete">
                                <mat-option (click)="selectHospital(item)" *ngFor="let item of filteredHospArray" value={{item}}>
                                    {{item}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-label>
                    </div>
                    
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                        <mat-label class="matlabel">Location<br>
                            <input type="text" class=" ipcss" required maxlength="50" [(ngModel)]="hospLoctionTxt"
                                (keyup)="locFilter($event)" matInput [matAutocomplete]="auto11" (keyup.enter)="selectLocation(hospLoctionTxt)"/>
                            <mat-autocomplete #auto11="matAutocomplete">
                                <mat-option (click)="selectLocation(item)"  *ngFor="let item of filteredLocList"
                                    value={{item}}>{{item}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-label>
                    </div>

                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                        <mat-label class="matlabel">District / City<br>
                            <mat-select disableOptionCentering class="  ipcss" required [(ngModel)]="hospDist"
                                (ngModelChange)="changeCity($event)">
                                <mat-option disabled>Select</mat-option>
                                <mat-option *ngFor="let city of cityListArray" value={{city.city_id}}>{{city.city_desc}}
                                </mat-option>
                            </mat-select>
                        </mat-label>
                    </div>

                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                        <mat-label class="matlabel">State<br>
                            <mat-select disableOptionCentering class="ipcss" required [(ngModel)]="hospState"
                                (ngModelChange)="changeState($event)">
                                <mat-option disabled>Select</mat-option>
                                <mat-option *ngFor="let state of stateListArray" value="{{state.state_id}}">
                                    {{state.state_desc}}</mat-option>
                            </mat-select>
                        </mat-label>
                    </div>

                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                        <mat-label class="matlabel">Country<br>
                            <mat-select disableOptionCentering class="ipcss" required [(ngModel)]="hospCountry"
                                (ngModelChange)="changeCountry()">
                                <mat-option disabled>Select</mat-option>
                                <mat-option *ngFor="let country of countryListArray" value={{country.country_desc}}>
                                    {{country.country_desc}}</mat-option>
                            </mat-select>
                        </mat-label>
                    </div>

                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                        <mat-label class="matlabel">Zip code<br>
                            <input class="ipcss" [(ngModel)]="hospZip" matInput [disabled]="true"/>
                        </mat-label>
                    </div>

                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                        <mat-label class="matlabel">Address 1<br>
                            <input type="text" class="ipcss" matInput [(ngModel)]="hospAddress1" required
                                maxlength="50">
                        </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                        <mat-label class="matlabel">Address 2<br>
                            <input type="text" class="ipcss" matInput [(ngModel)]="hospAddress2" 
                                maxlength="50" />
                        </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                        <mat-label class="matlabel">Phone number<br>
                            <input type="text" class="ipcss" matInput [(ngModel)]="hospContact" required
                                maxlength="10" />
                        </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                        <mat-label class="matlabel">Website<br>
                            <input type="text" class="ipcss" matInput [(ngModel)]="hopWebsite" maxlength="100" />
                        </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                        <mat-label class="matlabel">Payment type<br>
                            <mat-select disableOptionCentering class="ipcss" required [(ngModel)]="outpay"
                            (ngModelChange)="paytypechange()">
                                <mat-option value="percentage" >Percentage</mat-option>
                                <mat-option value="fixed">Fixed amount</mat-option>
                            </mat-select>
                        </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                        <mat-label class="matlabel">{{percval}}<br>
                            <input type="text" class="ipcss" matInput [(ngModel)]="percamt" maxlength="7" required />
                        </mat-label>
                    </div>
                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>