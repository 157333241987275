import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PhysioComponent } from './physio/physio.component';

const routes: Routes = [
    
      {path: 'physio', component: PhysioComponent},

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PhysioRoutingModule { }
