import { Component, OnInit } from '@angular/core';
import { CommonDataService } from '../../providers/common-data.service';
import { Http, Headers } from '@angular/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { Message_data } from '../../../assets/js/Message_data';
import { ClientViewService } from '../clientview/client-view.service';
import { Client_Helper } from '../Client_helper';
import { ipaddress } from '../../ipaddress';

@Component({
  selector: 'app-client-notification-view',
  templateUrl: './client-notification-view.component.html',
  styleUrls: ['./client-notification-view.component.css']
})
export class ClientNotificationViewComponent implements OnInit {
  dtOptions: DataTables.Settings = {};

  public tabletTracker = [];
  public clientName: string;
  public doctorName: string;
  public prescriptionDate: string;
  public diseaseName: string;
  public diseaseDesc: string;
  public consultTxt;
  public daysTxt: string;
  public remark: string;
  public daysFlag: boolean;
  public presId: string;
  public notificationId: string;
  public saveFlag: boolean = true;

  constructor(public clientservice:ClientViewService,public gservice:CommonDataService,public http:Http,public routes:ActivatedRoute,public router:Router,public toastr:ToastrService) { 
    this.daysFlag = true;
  }

  ngOnInit() {
    this.saveFlag = true;
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 5,
      "lengthMenu": [[5, 10, 25, 50, -1], [5, 10, 25, 50, "All"]],
      "columnDefs": [
        { "orderable": false, "targets": [0, 1, 2, 3] },
      ],
      "language": {
        "search": "Filter"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12">><"table-responsive"t><"row mt-1 d-flex"<"col-xs-12 col-sm-6 col-md-4"l><"col-xs-12 col-sm-12 col-md-4 text-center sm-order-last"i><"col-xs-12 col-sm-6 col-md-4"p>>'
    };

    if (Client_Helper.getviewnotification() != null) {
      var notification_list = Client_Helper.getviewnotification();
      this.presId = notification_list[0].pres_id;
      this.notificationId = notification_list[0].not_id;
      this.getNotifyView(this.presId, this.notificationId)
    }
  }

  back() {
    this.clientservice.sendMessage("notification");
  }

  getNotifyView(pres_id, not_id) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'gen/gp',
      JSON.stringify({
        pres_drug_id: pres_id,
        notification_id: not_id,
        type: "client"
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          if (obj.first_name != null) {
            if (obj.middle_name != null && obj.middle_name != "") {
              this.doctorName = obj.first_name + ' ' + obj.middle_name + ' ' + obj.last_name;
            }else {
              this.doctorName = obj.first_name + ' ' + obj.last_name;
            }

            if (obj.pres_date != null) 
              this.prescriptionDate = obj.pres_date;

            if (obj.disease != null) 
              this.diseaseName = obj.disease;

            if (obj.dis_desc != null) 
              this.diseaseDesc = obj.dis_desc;

            if (obj.cl_middle_name != null && obj.cl_middle_name != "") {
              this.clientName = encrypt_decript.Decript(obj.cl_first_name).toString()+ ' ' + encrypt_decript.Decript(obj.cl_middle_name).toString() + ' ' + encrypt_decript.Decript(obj.cl_last_name).toString();
            }else {
              this.clientName = encrypt_decript.Decript(obj.cl_first_name).toString()+ ' ' + encrypt_decript.Decript(obj.cl_last_name).toString();
            }

            for (var i = 0; i < obj.drug_list.length; i++) {
              var master_tablet_data = obj.drug_list[i];
              var medicene_dosage_data = "";

              if (master_tablet_data.intake_desc == "Every 6 hours") {
                medicene_dosage_data = master_tablet_data.morning + '-' + master_tablet_data.morning + '-' + master_tablet_data.morning + '-' + master_tablet_data.morning;
              }else {
                medicene_dosage_data = master_tablet_data.morning + '-' + master_tablet_data.afternoon + '-' + master_tablet_data.evening;;
              }

              var master_timeDuration = "";
              if (master_tablet_data.drug_intake == "With food") {
                master_timeDuration = master_tablet_data.drug_intake;
              }else {
                if (master_tablet_data.time_duration != null && master_tablet_data.time_duration != "0") {
                  master_timeDuration = master_tablet_data.time_duration + ' mins ' + master_tablet_data.drug_intake;
                }else {
                  master_timeDuration = master_tablet_data.drug_intake;
                }
              }

              this.tabletTracker.push({
                medicene_name: master_tablet_data.drug_type_name + ' - ' + master_tablet_data.drug_name,
                medicene_dosage: medicene_dosage_data,
                medicene_intakedays: master_tablet_data.days + ' days ',
                medicene_food: master_timeDuration
              })
            }
          }
          else {
            this.toastr.error(Message_data.getNetworkMessage());
          }
        },error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }
  
  savePrescriptionNotification() {
    if (this.consultTxt == "Extend") {
      if (this.daysTxt != undefined && this.daysTxt != '') {
        var pres_id_send = this.presId;
        var days_extend = this.daysTxt;
      }
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'gen/uc',
      JSON.stringify({
        remarks: this.remark,
        notification_id: this.notificationId,
        pres_drug_id: pres_id_send,
        days: days_extend
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          if (obj.key != null && obj.key == "1") {
            this.saveFlag = false;
            this.toastr.success(Message_data.saveSuccess);
            this.router.navigateByUrl("/clientnotification")
          }else {
            this.saveFlag = true;
            this.toastr.error(Message_data.notSavedSuccess);
          }
        },error => {
          this.saveFlag = true;
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  consultChange() {
    if (this.consultTxt == "Extend") {
      this.daysFlag = false;
      this.remark = "";
      this.daysTxt = "";
    }else if (this.consultTxt == "Consult me") {
      this.daysFlag = true;
      this.remark = "";
    }else {
      this.daysFlag = false;
      this.remark = "";
      this.daysTxt = "";
    }
  }

  keyPress(event){
    return event.charCode >= 48 && event.charCode <= 57;
  }
}
