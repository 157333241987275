import { Component, OnInit ,ElementRef,Input,Inject,ViewEncapsulation,HostListener} from '@angular/core';
import { Http, Headers } from '@angular/http';
// import { ipaddress.getIp, Ip_with_img_address, ipaddress.country_code } from '../../../assets/js/ipaddress';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Doc_Helper } from '../Doc_Helper';
import { ToastrService } from 'ngx-toastr';
import pdfMake from 'pdfmake/build/pdfmake';
import { ipaddress } from '../../ipaddress' ;import { masterCSData_Helper } from '../casesheet/masterCSData_Helper';
import { MasterCSHelperService } from '../casesheet/masterCSData.service';
import { Master_Helper } from '../Master_Helper';
import { Message_data } from 'src/assets/js/Message_data';

@Component({
  selector: 'app-upload-report',
  templateUrl: './upload-report.component.html',
  styleUrls: ['./upload-report.component.scss']
})
export class UploadReportComponent implements OnInit {

  public report_datas = [];
  public report_url: string;
  public doc_date;
  public image_file_str: any;
  public pdfObj;
  public photos=[];
  public pdf;
  public doc_type: string = "";
  public report_name;
  public rel_id;
  public sub_id;
  public sub_rel_id;
  public client_id;
  public spl_id;
  public disease: string;
  public current_date;
  public Select = "Select";

  constructor(public http:Http, public toastr:ToastrService,public dialogRef: MatDialogRef<UploadReportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,public masterData:MasterCSHelperService) {    
      dialogRef.disableClose = true;
    this.report_url = ipaddress.getIp + "gen/dtypes";
    this.client_id = data.client_reg_id;
    this.rel_id = data.relation_id;
    this.sub_id = data.sub_rel_id;
    this.spl_id = data.spl_id;
    
  }

  async ngOnInit() {
    if(masterCSData_Helper.getMastergetReportTypes() == undefined){
      await this.masterData.getReportTypes();
      this.getReportTypes();
    }else{
      this.getReportTypes();
    }
    this.appGetdate();
  }

  getReportTypes() {
    this.report_datas = [];
    var obj = masterCSData_Helper.getMastergetReportTypes()
    if (obj != undefined) 
      this.report_datas = obj.doc_types;
  }

  appGetdate() {
    var obj = Master_Helper.getMasterCurrentDate();
    if (obj.current_date != null) {
      this.current_date = (obj.current_date);
    }
  }


  Date_Click(date) {
    this.doc_date = date;
  }

  fileChange(data) {
    this.readThis(data.target);
  }

  readThis(inputValue: any): void {
    var ValidFileExtension = ['jpg', 'JPG', 'jpeg', 'JPEG', 'png', 'PNG', 'gif', 'GIF', 'bmp', 'BMP','pdf'];

    var file: File = inputValue.files[0];
    var fileExtension = file.type.split("/").pop();
    var myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      var fileSize = Math.round(inputValue.files[0].size / 1024);
      this.image_file_str = myReader.result.toString();
      // this.image_file_name = inputValue.files[0].name.replace(" ", "");
      // this.image_file_valid_name = this.image_file_name.split('.')[1];
      // this.profile_image = this.image_file_str.split(',')[1];

      if(fileExtension == 'pdf'){
        this.pdf = file;
      }else{
        this.photos.push(this.image_file_str)
      }
    }
    myReader.readAsDataURL(inputValue.files[0]);
    
  }

  createPdf(){
    if(this.photos.length !=0){
      let imgFinding = [];
      var fileExtension;
      for(var i=0;i<this.photos.length;i++){
          imgFinding.push({image: this.photos[i],fit: [540, 700]})
      }
          let docDefinition = {
           pageSize: 'A4',
           content: imgFinding
          };
    
          this.pdfObj = pdfMake.createPdf(docDefinition);
          this.pdfObj.getBuffer((buffer) => {
          var blob = new Blob([buffer], { 
            type: 'application/pdf' 
          });
        
          var reader = new FileReader();
          reader.readAsDataURL(blob); 
          reader.onloadend = function(this:any) {
            var base64data = reader.result;    
                        
            this.save_data(base64data) 
          }.bind(this);        
        });
    }else if(this.pdf != undefined){
      var reader = new FileReader();
      reader.readAsDataURL(this.pdf); 
      reader.onloadend = function(this:any) {
        var base64data = reader.result;    
                    
        this.save_data(base64data) 
      }.bind(this);   
    }
    else {
      this.toastr.error(Message_data.addAtlstOneImg);
    }
  }
  
  save_data(pdffile) {
    if (this.doc_type == undefined || this.doc_type == "") {
      this.toastr.error(Message_data.sltReportType);
    } else if (this.doc_date == undefined) {
      this.toastr.error(Message_data.sltDate);
    } else if (this.report_name == undefined || this.report_name == "") {
      this.toastr.error(Message_data.enterReportName);
    } else {
      if (this.rel_id == "1") {
        this.sub_id = undefined;
      } else {
        this.sub_id = this.sub_rel_id;
      }

      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'gen/cudoc',
        JSON.stringify({
          client_reg_id: this.client_id,
          document_date: (this.doc_date),
          //this.doc_date.split('-')[2] + "-" + this.doc_date.split('-')[1] + "-" + this.doc_date.split('-')[0],
          relation_id: this.rel_id,
          sub_rel_id: this.sub_id,
          country: ipaddress.country_code,
          document_type: this.doc_type,
          spl_id: this.spl_id,
          disease: this.disease,
          report_name:this.report_name,
          document: pdffile,
          // medicines: this.medicine_data
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            
            if (obj.key == "1") {
              this.toastr.success(Message_data.rptUploaddSuccess);
            }
          },
          error => {
            this.toastr.error(Message_data.network);
          }
        )
    }
  }

  ClosePopup(){
    this.dialogRef.close();
  }

}
