<div *ngIf="surgHistoryFlag == 'min'">
    <div class="row">
        <div [hidden]="dc_hidden" class="col-12">
            <mat-label [hidden]="dc_hidden" class="matlabel">D&C<br>
                <input type="date"  class="ipcss_date" id="appt_date"  (change)="OnDateChanged_c(d_c)" [(ngModel)] ="d_c" max="{{currentDate}}" matInput>
            </mat-label>
        </div>
        <div [hidden]="ovarian_hidden" class="col-12">
            <mat-label [hidden]="ovarian_hidden" class="matlabel">Ovarian surgery<br>
                <input type="date"  class="ipcss_date" id="appt_date"  (change)="OnDateChangeos(ovarian_surgery)" [(ngModel)] ="ovarian_surgery" max="{{currentDate}}" matInput>
            </mat-label>
        </div>
        <div [hidden]="hysteroscopy_hidden" class="col-12 ">
            <mat-label [hidden]="hysteroscopy_hidden" class="matlabel">Hysteroscopy<br>
                <input type="date"  class="ipcss_date" id="appt_date"  (change)="OnDateChangehyst(hysteroscopy)" [(ngModel)] ="hysteroscopy" max="{{currentDate}}" matInput>
            </mat-label>
        </div>
       
    </div>
</div>

<div *ngIf="surgHistoryFlag == 'max'">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="cover_div" style="padding-bottom: 36px;">
                    <div class="content_cover">
                        <div class="row">
                            <div [hidden]="dc_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label [hidden]="dc_hidden" class="matlabel">D&C<br>
                                    <input type="date"  class="ipcss_date" id="appt_date"  (change)="OnDateChanged_c(d_c)" [(ngModel)] ="d_c" max="{{currentDate}}"  [readonly]="client" matInput (blur)="send_data()">
                                </mat-label>
                            </div>
                            <div [hidden]="ovarian_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label [hidden]="ovarian_hidden" class="matlabel">Ovarian surgery<br>
                                    <input type="date"  class="ipcss_date" id="appt_date"  (change)="OnDateChangeos(ovarian_surgery)" [(ngModel)] ="ovarian_surgery" max="{{currentDate}}" [readonly]="client" (blur)="send_data()"matInput >
                                </mat-label>
                            </div>
                            <div [hidden]="hysteroscopy_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label [hidden]="hysteroscopy_hidden" class="matlabel">Hysteroscopy<br>
                                    <input type="date"  class="ipcss_date" id="appt_date"  (change)="OnDateChangehyst(hysteroscopy)" [(ngModel)] ="hysteroscopy" max="{{currentDate}}" (blur)="send_data()" [readonly]="client" matInput>
                                </mat-label>
                            </div>
                            <div [hidden]="infertility_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label [hidden]="infertility_hidden" class="matlabel">Infertility surgery<br>
                                    <input type="date"  class="ipcss_date" id="appt_date"  (change)="OnDateChangeis(infertility_surgery)" (blur)="send_data()" [(ngModel)] ="infertility_surgery" [readonly]="client" max="{{currentDate}}" matInput>
                                </mat-label>
                            </div>
                            <div [hidden]="lcyst_removed" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label [hidden]="lcyst_removed" class="matlabel">L cyst(s) removed<br>
                                    <input type="date"  class="ipcss_date" id="appt_date"  (change)="OnDateChangelc(left_cyst_removed)"  (blur)="send_data()" [(ngModel)] ="left_cyst_removed" max="{{currentDate}}" [readonly]="client" matInput>
                                </mat-label>
                            </div>
                            <div [hidden]="rcyst_removed" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label [hidden]="rcyst_removed" class="matlabel">R cyst(s) removed<br>
                                    <input type="date"  class="ipcss_date" id="appt_date"  (change)="OnDateChangerc(right_cyst_removed)" (blur)="send_data()" [(ngModel)] ="right_cyst_removed" max="{{currentDate}}" [readonly]="client" matInput>
                                </mat-label>
                            </div>
                            <div [hidden]="tuboplasty_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label [hidden]="tuboplasty_hidden" class="matlabel">Tuboplasty<br>
                                    <input type="date"  class="ipcss_date" id="appt_date"  (change)="OnDateChangetubo(tuboplasty)" [(ngModel)] ="tuboplasty" (blur)="send_data()" max="{{currentDate}}" [readonly]="client" matInput>
                                </mat-label>
                            </div>
                            <div [hidden]="tubal_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label [hidden]="tubal_hidden" class="matlabel">Tubal ligation<br>
                                    <input type="date"  class="ipcss_date" id="appt_date"  (change)="OnDateChangetubo_lig(tubal_ligation)" [(ngModel)] ="tubal_ligation" (blur)="send_data()" max="{{currentDate}}" [readonly]="client" matInput>
                                </mat-label>
                            </div>
                            <div [hidden]="lovary_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label [hidden]="lovary_hidden" class="matlabel">L ovary removed<br>
                                    <input type="date"  class="ipcss_date" id="appt_date"  (change)="OnDateChangelor(left_ovary_removed)" [(ngModel)] ="left_ovary_removed" (blur)="send_data()"  max="{{currentDate}}" [readonly]="client" matInput>
                                </mat-label>
                            </div>
                            <div [hidden]="rovary_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label [hidden]="rovary_hidden" class="matlabel">R ovary removed<br>
                                    <input type="date"  class="ipcss_date" id="appt_date"  (change)="OnDateChangeror(right_ovary_removed)" [(ngModel)] ="right_ovary_removed" (blur)="send_data()" max="{{currentDate}}" [readonly]="client" matInput>
                                </mat-label>
                            </div>
                            <div [hidden]="laparoscopy_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label [hidden]="laparoscopy_hidden" class="matlabel">Laparoscopy<br>
                                    <input type="date"  class="ipcss_date" id="appt_date"  (change)="OnDateChangelaparo(laparoscopy)" [(ngModel)] ="laparoscopy" max="{{currentDate}}" (blur)="send_data()" [readonly]="client" matInput>
                                </mat-label>
                            </div>
                            <div [hidden]="hysterec_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label [hidden]="hysterec_hidden" class="matlabel">Hysterectomy (vaginal)<br>
                                    <input type="date"  class="ipcss_date" id="appt_date"  (change)="OnDateChangehysto_vag(hysterectomy_vaginal)" [(ngModel)] ="hysterectomy_vaginal" (blur)="send_data()" max="{{currentDate}}" [readonly]="client" matInput>
                                </mat-label>
                            </div>
                            <div [hidden]="hysterecabdominal_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label [hidden]="hysterecabdominal_hidden" class="matlabel">Hysterectomy (abdominal)<br>
                                    <input type="date"  class="ipcss_date" id="appt_date"  (change)="OnDateChangehysto_abd(hysterectomy_abdominal)" [(ngModel)] ="hysterectomy_abdominal" (blur)="send_data()" max="{{currentDate}}" [readonly]="client" matInput>
                                </mat-label>
                            </div>
                            <div [hidden]="myomectomy_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label [hidden]="myomectomy_hidden" class="matlabel">Myomectomy<br>
                                    <input type="date"  class="ipcss_date" id="appt_date"  (change)="OnDateChangemymoect(myomectomy)" [(ngModel)] ="myomectomy" max="{{currentDate}}" (blur)="send_data()" [readonly]="client" matInput>
                                </mat-label>
                            </div>
                            <div [hidden]="vaginal_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label [hidden]="vaginal_hidden" class="matlabel">Vaginal or bladder repair for prolapsed or incontinence<br>
                                    <input type="date"  class="ipcss_date" id="appt_date"  (change)="OnDateChangebladerorvaginalrepair(bladder_repair)" [(ngModel)] ="bladder_repair"  (blur)="send_data()" max="{{currentDate}}" [readonly]="client" matInput>
                                </mat-label>
                            </div>
                            <div class="col-md-12 col-lg-12 col-sm-12 col-xl-12">
                                <mat-label class="matlabel" [hidden]="other_hidden" >Others<br>
                                    <textarea  matInput class="ipcss obsttxtareaheight" rows="1" maxlength="150" [(ngModel)]="others" [readonly]="client" (blur)="send_data()"></textarea>
                                </mat-label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
