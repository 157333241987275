<div class="row">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Exercise pattern</h5>
                    </div>
                    <div class="headerButtons">
                        <img *ngIf="showStartExerciseFlag" class="saveimgbtn_inpatinfo" (click)="back()"
                            src="../../../assets/ui_icons/buttons/Back_button.svg" />
                        <img *ngIf="showStartExerciseFlag" class="saveimgbtn_inpatinfo" (click)="save()"
                            src="../../../assets/ui_icons/buttons/save_button.svg" />
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>
                <div class="row"  *ngIf="!showStartExerciseFlag">
                    <div class="col-12" id="exerciseTracker">
                        <owl-carousel-o [options]="customOptions">
                            <ng-template carouselSlide *ngFor="let relation of relationlistData;">
                                <div class="slide">
                                    <div class="cu_slider_cover">
                                        <div class="card_border1"
                                            (click)="changeRelation(relation.rel_name,relation.rel_id,relation.sub_rel_id,relation.life_style_id)">
                                            <div class="d-flex justify-content-center">
                                                <img class="img-fluid" class="image" src="{{relation.prof_img}}"
                                                    alt="Card image cap">
                                            </div>
                                            <p class="carousel_label">{{relation.rel_name}}</p>
                                        </div>
                                        <a tabindex="0">
                                            <div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </ng-template>
                        </owl-carousel-o>
                    </div>
                </div>
                <div class="row" *ngIf="!showStartExerciseFlag">
                    <div class="col-4" style="padding-bottom: 25px;" *ngFor="let exercise of exerciseTracker">
                        <mat-card id="cardcontent" (click)="selectExercisePattern(true,exercise)">
                            <mat-card-title style="font-size: 13px;margin-left: 10px;padding-top: 5px;">
                                {{exercise.description}}
                            </mat-card-title>
                            <mat-card-content>
                                <div style="text-align: center;">
                                    <img src="{{exercise.exerc_image}}"
                                        onerror="this.src='../../../assets/ui_icons/cycle_icon.svg'" width="100px"
                                        height="auto" />
                                </div>
                                <div class="row" style="justify-content: center;">
                                    <div class="col-10">
                                        <mat-label class="matlabel">Variation<br>
                                          <input class="ipcss" [(ngModel)]="exercise.activity_name" disabled #matInput>                       
                                        </mat-label>
                                      </div>
                                      <div class="col-10">
                                        <mat-label class="matlabel">Session<br>
                                          <input class="ipcss" [(ngModel)]="exercise.session" disabled #matInput>
                                        </mat-label>
                                      </div>
                                      <div class="col-10">
                                        <mat-label class="matlabel" style="text-transform: capitalize;">Duration<br>
                                          <input class="ipcss" [(ngModel)]="exercise.activity_dur" disabled #matInput>
                                        </mat-label>
                                      </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
                <div class="row" *ngIf="showStartExerciseFlag">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-4">
                                <button class="start_btn" [hidden]="reset" (click)="resetTimer()"><img
                                    class="saveimgbtn_inpatinfo"
                                    src="../../../assets/ui_icons/buttons/Stop_button.svg" /></button>
                                <button class="start_btn" [hidden]="!reset" (click)="resetTimer()"><img
                                        class="saveimgbtn_inpatinfo"
                                        src="../../../assets/ui_icons/buttons/Reset_button.svg" /></button>
                            </div>
                            <div class="col-4 d-flex justify-content-center">
                                <div class="stopwatch">
                                    <div class="timer"><b>{{format(hh)}}:{{format(mm)}}:{{format(ss)}}.{{format(ms)}}</b></div>
                                </div>
                            </div>
                            <div class="col-4">
                                <button class="stop_btn" [hidden]="!isRunning" (click)="clickHandler()"><img
                                        class="saveimgbtn_inpatinfo"
                                        src="../../../assets/ui_icons/buttons/Pause_button.svg" /></button>
                                <button class="stop_btn" [hidden]="isRunning" (click)="clickHandler()"><img
                                    class="saveimgbtn_inpatinfo"
                                    src="../../../assets/ui_icons/buttons/Start_button.svg" /></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" style="margin-top: 15px;" [ngStyle]="{'display':chartDisplay}">
                    <div class="col-12">
                        <div class="dite_content_cover">
                            <ul class="nav nav-tabs" id="myTab" role="tablist">
                                <li class="nav-item" role="presentation" *ngFor="let heading of chartHeadingArray; let i = index">
                                    <button class="nav-link" id="{{heading}}" data-bs-toggle="tab"
                                        attr.data-bs-target="#{{heading}}" type="button" role="tab"
                                        attr.aria-controls="{{heading}}" aria-selected="true"
                                        (click)="changeChartHeadingData(heading)">{{heading}}</button>
                                </li>
                            </ul>
                            <div class="tab-content" id="myTabContent">
                                <div class="row" style="margin-top: 15px;">
                                    <div class="col-6" style="max-height: 266px;overflow-y:auto;overflow-x:hidden">
                                        <mat-card  [ngStyle]="{'display':chartDisplay}" class="card_chart" *ngFor="let chart of chartData" style="margin-top: 5px;">
                                            <mat-card-content>
                                                <div class="row">
                                                    <div class="col-4">
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <div style="display: grid;margin-top: 5px;">
                                                                    <p style="margin: 0;" class="d-flex justify-content-center">{{chart.date}}&nbsp;{{chart.created_time}}</p>
                                                                    <div class="d-flex justify-content-center">
                                                                        <img src="{{chart.exerc_image}}"
                                                                        onerror="this.src='../../../assets/ui_icons/cycle_icon.svg'" width="50px"
                                                                        height="auto" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-4 d-flex align-items-center">
                                                        {{chart.exercise_dur}}
                                                    </div>
                                                    <div class="col-4 d-flex align-items-center">
                                                        {{chart.kcal}} - Calories burned
                                                    </div>
                                                </div>
                                            </mat-card-content>
                                        </mat-card>
                                    </div>
                                    <div class="col-6">
                                        <div class="row" [ngStyle]="{'display':chartDisplay}">
                                            <div class="col-12" [ngStyle]="{'display':morningChart}">
                                                <mat-card id="" >
                                                    <mat-card-content style="background-color: #fff;">
                                                        <canvas id="workoutCanvas" width="361" height="233"
                                                            style="display: block;"></canvas>
                                                    </mat-card-content>
                                                </mat-card>
                                            </div>
                                            <div class="col-12" [ngStyle]="{'display':noonChart}">
                                                <mat-card id="">
                                                    <mat-card-content style="background-color: #fff;">
                                                        <canvas id="workoutNoonCanvas" width="361" height="233"
                                                            style="display: block;"></canvas>
                                                    </mat-card-content>
                                                </mat-card>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>
