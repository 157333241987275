import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Date_Formate } from '../../../assets/js/common.js';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { CommonDataService } from '../../providers/common-data.service';
import { ClientViewService } from '../clientview/client-view.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';

@Component({
  selector: 'app-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.css']
})
export class DocumentListComponent implements OnInit {
    tomorrow = new Date();
    //docu
    public relationUrl: string;
    public reportUrl: string;
    public splUrl: string;
    public doctorDataUrl: string;
    public sendClientRegId;
    public relationList = [];
    public reportList = [];
    public splList = [];
    public splId: string;
    public docType: string;
    public relId: string;
    public sendDoctorData;
    public documentDetails = [];
    public docDate :string;
    public docSrc;
    public pdfFlag: boolean;
    public select = "Select";
  
  constructor(private sanitizer: DomSanitizer,public clientservice:ClientViewService,public gservice:CommonDataService,public datepipe:DatePipe,public http:Http,public routes:ActivatedRoute,public router:Router,public toastr:ToastrService, public dialog: MatDialog) { 
      this.relationUrl = ipaddress.getIp + "usercontroller/grels/";
      this.reportUrl = ipaddress.getIp + "gen/dtypes";
      this.splUrl = ipaddress.getIp + "usercontroller/getspecialization/";
      this.doctorDataUrl = ipaddress.getIp + "gen/cgdoc";
      this.pdfFlag =true;
    }

  ngOnInit(){
    this.sendClientRegId = {
      client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
    }

    this.getRelations();
    this.getReportTypes();
    this.getSplDatas();
  }

  getRelations() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.relationUrl, JSON.stringify(this.sendClientRegId), { headers: headers }).subscribe(
      data => {
        this.relationList = [];
        this.relationList = data.json().relationships;
      }, error => {});
  }

  getReportTypes() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(this.reportUrl, { headers: headers }).subscribe(
      data => {
        this.reportList = [];
        this.reportList = data.json().doc_types;
      }, error => {});
  }

  getSplDatas() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(this.splUrl, { headers: headers }).subscribe(
      data => {
        this.splList = [];
        for (var t = 0; t < data.json().specialization.length; t++) {
          var spls = data.json().specialization[t];
          if (spls.spl_name != "-") {
            this.splList.push({
              spl_id: spls.spl_id,
              spl_name: spls.spl_name
            });
          }
        }
      }, error => {});
  }

  search() {
    this.documentDetails = [];
    this.docSrc = "";
    this.pdfFlag = true;
    if (this.relId == undefined) {
      this.toastr.error(Message_data.sltRelation);
    } else {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.sendDoctorData = {
        client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
        relation_id: this.relId,
        doc_type: this.docType,
        spl_id: this.splId,
        docdate :this.docDate
      };
      this.http.post(this.doctorDataUrl, JSON.stringify(this.sendDoctorData), { headers: headers }).subscribe(
        data => {
          this.documentDetails = [];
          if (data.json().doc_details == null || (data.json().doc_details != null && data.json().doc_details.length == 0)) {
            this.toastr.error(Message_data.noDocument);
          } else {
            for (var y = 0; y < data.json().doc_details.length; y++) {
              var data_rep = data.json().doc_details[y];
              var cln_name = "";
              var doc_date = "";
              if (data_rep.middle_name != null) {
                cln_name = encrypt_decript.Decript(data_rep.first_name) + " " + encrypt_decript.Decript(data_rep.middle_name) + " " + encrypt_decript.Decript(data_rep.last_name);
              } else {
                cln_name = encrypt_decript.Decript(data_rep.first_name) + " " + encrypt_decript.Decript(data_rep.last_name);
              }
              if (data_rep.document_date != null) {
                doc_date = data_rep.document_date.split('-')[2] + "-" + data_rep.document_date.split('-')[1] + "-" + data_rep.document_date.split('-')[0];
              }
              this.documentDetails.push({
                data: "Report for " + cln_name + "(" + data_rep.relationship_name + ") taken on " + doc_date,
                doc_name: data_rep.document_name,
                doc_id:data_rep.client_doc_id,
                doc_url:data_rep.document,
              });
            }
          }
        }, error => {});
    }
  }

  uploadNew() {
    this.clientservice.sendMessage('uploaddocs')
  }

  detailView(doc_name) {
    this.docSrc = this.sanitizer.bypassSecurityTrustResourceUrl(ipaddress.Ip_with_img_address + doc_name);
    this.pdfFlag = false;
  }
}
