import { Component, OnInit, HostListener } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Helper_Class } from '../../../helper_class';
import { pharmacy_helper } from 'src/app/pharma/Pharmcy_Helper';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../../providers/common-data.service';
import { Date_Formate, Time_Formate } from '../../../../assets/js/common';
// import { PharmacyService } from '../pharmacy/pharmacy.service';
import { PharmacyService } from 'src/app/pharma/pharmacy/pharmacy.service';
import { ipaddress } from '../../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
import moment from 'moment';
import { convertNumberToWords } from '../../../../assets/js/common';
import { split } from 'ts-node';
import { ChangeDetectorRef } from '@angular/core';
import { error } from 'console';

@Component({
  selector: 'app-pharma-inventory-receivables',
  templateUrl: './pharma-inventory-receivables.component.html',
  styleUrls: ['./pharma-inventory-receivables.component.css']
})
export class PharmaInventoryReceivablesComponent implements OnInit {
  public countryID: any;
  public mgfID: any;
  public getPurchaseArray: any = [];
  public inventory: any = [];
  public inventoryitems: any = [];
  public pharmacyLocationArray: any;
  public locationIP = "";
  public manufacturer: any;
  public gst_no: string;
  public product_data: any;
  public pharmacistID;
  public invoiceNO: any;
  public poID: any;
  public purchasePoID;
  public batchNO: any;
  public locationArray: any = [];
  public locationName;
  public hsnNO;
  public packageCodeArray: any = [];
  public productArray: any = [];
  public receivedQuantity;
  public freeQuantity;
  public discount;
  public recvDate;
  public uom;
  public productName;
  public productID;
  public orderProduct;
  public unitPrice;
  public userInfo;
  public userID;
  public pharmacyID;
  public currentDate;
  public suppID;
  public inventoryLength;
  public currentMonthYear;
  public package_data;
  public tax_data;
  public uom_list_data;
  public invoiceAmount: number = 0.00;
  public actual_invoiceAmount: number=0.00;
  public invDate: string;
  public poapproved;
  public inventrydepartname: string = "All";
  public inventdepartmentarray = [];
  public pobase;
  public inventrystorename: string = "All";
  public inventrystorenamearray = [];
  public hsp_id;
  public user_type;
  public editbutton: boolean = false;
  public newbutton: boolean = false;
  public deletebutton: boolean = false;
  public printbutton: boolean = false;
  public viewbutton: boolean = false;
  public pagetitle;
  public Totalcostpirce: number = 0.00;
  public tcprice: string;
  public constpriceinwords;
  public supplierList: any = [];
  public poarray: any = [];
  public productdata_detal: any = [];
  public clickCount: number = 0;
  public inventorydetailsexp = [];
  public poorder: any = [];
  public checkboxarry = [];
  public filtercheckbox;
  public multicheck: boolean;
  public MedicineNameArray: any = [];
  public medicineArray: any = [];
  public MedicneData: any = [];
  public discamount = 0.00;
  public expiry_flag: boolean;
  public discountamount;
  public Discount_value;
  public discount_option;
  public discountpercentageflag: boolean;
  public totalCostForCheckedItems: number = 0.00;
  public totalCostForCheckedItems_actual:number=0.00;

  public product_wise_discountflag: boolean = true;
  public discountpriceflag: boolean = false;
  public discounttype = [];
  public podataid;
  public check_product_wise: number = 0.00;
  public disamt: number = 0.00;
  public aftdiscamt: number = 0.00;
  public GSTamount: number = 0.00;

  public Cgst:number =0.00;
  public Sgst:number =0.00;
  public Cgstper:number = 0.00;
  public Sgstper:number = 0.00;
  public invoice_editflag:boolean;
  public po_required;
  public returnAmount:number = 0.00;
  public receivedAmount:number = 0.00;
  public balance:number=0.00;
  public cgst:number=0.00;
  public sgst:number=0.00;
  public product_returns_id;
  public invoiceAmountbe:number=0.00;
  public receivedamoutsupp:number=0.00;
  public supplierflag:boolean;
  public inputsupplierflag:boolean;
  public batchNo_flag:boolean;
  public data_type;
  public hospitalid;
  public accepted: boolean;
  public save_dataflag:boolean;
  public perunitflag:boolean;
  public rec_qtyflag:boolean;
  public inventory_data=[];
  public errorflag:boolean;
  public batchno_array:any=[];
  dtOptions: DataTables.Settings = {};
  public receivableslistarray:any=[];
  public updateflag:boolean;
  public pagetype:string;
  public backflag:boolean;
  public exlfalg:boolean;
  public sendurl;
  public inventroyflag:boolean;
  public stor_id;
  public depart_id;
  public dept_desc;
  public store_desc;
  public gr_no;
  public product_receive_id;
  public po_number_flag:boolean;
  public po_app;
  public po_requiredflag:boolean;


  constructor(public gservice: CommonDataService, public http: Http, public routes: ActivatedRoute, public router: Router, public toastr: ToastrService,
    public messageservice: MenuViewService, public dialog: MatDialog,private cdr: ChangeDetectorRef) {
    this.getDate();
  }

  ngOnInit(): void {
    this.inventroyflag=false
    this.save_dataflag=false;
    this.supplierflag = false;
    this.inputsupplierflag=true;
    this.updateflag=false;
    this.backflag=false;
    this.discounttype = ["", "", ""]
    this.userInfo = Helper_Class.getInfo();
    this.user_type = Helper_Class.getInfo().user_type;
    this.expiry_flag = true
    this.Discount_value
    this.pagetype="old&pending";
    this.exlfalg=true;

    if (Helper_Class.getInfo().user_type == 'Admin') {
      this.pharmacyID = Helper_Class.getInfo().pharma_id;
      this.poapproved = Helper_Class.getInfo().po_approval_req;
      this.userID = Helper_Class.getInfo().po_approver;
      this.pharmacistID = Helper_Class.getInfo().po_approver;
      this.hsp_id = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.po_required=Helper_Class.getInfo().hospitals[0].po_required;
      this.data_type="hospital";
      this.hospitalid=Helper_Class.getInfo().hospitals[0].hosp_id;
       this.depart_id=Helper_Class.getInfo().hospitals[0].department_id;
       this.stor_id=Helper_Class.getInfo().hospitals[0].store_id;
       this.dept_desc=Helper_Class.getInfo().hospitals[0].dept_desc;
       this.store_desc=Helper_Class.getInfo().hospitals[0].store_desc;
    }else if(Helper_Class.getInfo().user_type == 'pharmacy'){
      this.data_type="hospital"
      this.hsp_id = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.pharmacyID = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.poapproved = Helper_Class.getInfo().hospitals[0].po_approval_req;
      this.userID = Helper_Class.getInfo().user_id;
      this.pharmacistID = Helper_Class.getInfo().user_id;
      this.po_required=Helper_Class.getInfo().hospitals[0].po_required;
      this.hospitalid=Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
       this.depart_id=Helper_Class.getInfo().hospitals[0].department_id;
       this.stor_id=Helper_Class.getInfo().hospitals[0].store_id;
       this.dept_desc=Helper_Class.getInfo().hospitals[0].dept_desc;
       this.store_desc=Helper_Class.getInfo().hospitals[0].store_desc;
    } 
    else {
      this.hsp_id = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.pharmacyID = Helper_Class.getInfo().hospitals[0].pharma_id;
      this.poapproved = Helper_Class.getInfo().hospitals[0].po_approval_req;
      this.userID = Helper_Class.getInfo().user_id;
      this.pharmacistID = Helper_Class.getInfo().user_id;
      this.po_required=Helper_Class.getInfo().hospitals[0].po_required;
      this.data_type="hospital"
      this.hospitalid=Helper_Class.getInfo().hospitals[0].hosp_id;
       this.depart_id=Helper_Class.getInfo().hospitals[0].department_id
       this.stor_id=Helper_Class.getInfo().hospitals[0].store_id
       this.dept_desc=Helper_Class.getInfo().hospitals[0].dept_desc;
       this.store_desc=Helper_Class.getInfo().hospitals[0].store_desc;
    }
    if(this.userInfo.hospitals[0].receiv_invoice_edit == "0"){
      this.invoice_editflag=true
    }else{
      this.invoice_editflag=false
    }

    //this.productOrder();
    this.getPackages();
    this.getPharmacy();
    this.getSuppliers();
    this.freeQuantity = "0";
    if (Helper_Class.getmodulelist() != undefined) {
      for (var i = 0; i < Helper_Class.getmodulelist().length; i++) {
        if (Helper_Class.getmodulelist()[i].module_id == "57") {
          if (Helper_Class.getmodulelist()[i].edit == "1") {
            this.editbutton = true;
          }
          if (Helper_Class.getmodulelist()[i].create == "1") {
            this.newbutton = true;
          }
          if (Helper_Class.getmodulelist()[i].delete == "1") {
            this.deletebutton = true;
          }
          if (Helper_Class.getmodulelist()[i].print == "1") {
            this.printbutton = true;
          }
          if (Helper_Class.getmodulelist()[i].view == "1") {
            this.viewbutton = true;
          }
        }else{
          this.newbutton=true;
        }
      }
    }else{
      this.newbutton=true;
      
    }
    this.po_requiredflag= this.userInfo.hospitals[0].po_required == '0'? true:false
    this.discount_option = "select"
    this.isDiscountOptionSelected(this.discount_option)
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 10,
      "lengthMenu": [[5, 10, 25, 50, -1], [5, 10, 25, 50, "All"]],
      "columnDefs": [
        { "orderable": false, "targets": [0, 1, 2] },
      ],
      "language": {
        "search": "Filter"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12">><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"l><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    this.getreceivableslist()
  }

  getSuppliers() {
    // if(this.supp_name != undefined && this.supp_name.length >2){
   
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "inv/gsup/",
      JSON.stringify({
        pharmacy_id: this.pharmacyID,
        supp_name: this.manufacturer

      }), { headers: headers }).subscribe(
        data => {
          var obj = data.json();

          for (var i = 0; i < obj.supplier_details.length; i++) {
            this.supplierList.push({
              name: obj.supplier_details[i].name,
              supp_id: obj.supplier_details[i].supp_id,
              supp_code: obj.supplier_details[i].supp_code,
              gst_no: obj.supplier_details[i].gst_no,
              refund_amount:obj.supplier_details[i].refund_amount,
              product_returns_id:obj.supplier_details[i].product_returns_id,
              received_amount:obj.supplier_details[i].received_amount
            })
          }
        },
        error => {
          this.toastr.error(Message_data.unabletofetsupl);
        }
      )
  }

  supplier_change(supplier) {
    this.suppID = supplier.supp_id;
    this.manufacturer = supplier.name;
    this.gst_no = supplier.gst_no;
    this.returnAmount = parseFloat(supplier.refund_amount);
    // this.inventory=[];
    this.supplier_changee()
  }

  supplier_changee() {
    const selectedSupplier = this.supplierList.find(supplier => supplier.name === this.manufacturer);
    if (selectedSupplier) {
      this.suppID = selectedSupplier.supp_id;
      this.gst_no = selectedSupplier.gst_no;
      if(selectedSupplier.refund_amount != undefined){
        this.returnAmount = parseFloat(selectedSupplier.refund_amount);
      }else{
        this.returnAmount = 0;
      }
      this.product_returns_id=selectedSupplier.product_returns_id;
      if(selectedSupplier.received_amount != undefined && selectedSupplier.received_amount  != ""){
        this.receivedamoutsupp=parseFloat(selectedSupplier.refund_amount);
      }else{
        this.receivedamoutsupp=0;
      }
    }
   
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "inv/gpbs",
      JSON.stringify({
        supp_id: this.suppID
      }), { headers: headers }).subscribe(
        data => {
          this.product_data = [];
          this.productdata_detal = [];
          if(this.pagetype != "old&pending"){
            this.inventory = [];  
          }
            this.tcprice = "0";
          this.constpriceinwords = "zero";
          this.updateTotalCost()

          var obj = data.json();


          for (var i = 0; i < obj.po_details.length; i++) {
            this.product_data.push({
              purchase_order_id: obj.po_details[i].po_id,
              po_no:obj.po_details[i].po_no,
              date: obj.po_details[i].po_date,
              checked: false
            })
            this.productdata_detal.push({
              purchase_order_id: obj.po_details[i].po_id,
              date: obj.po_details[i].po_date,
              po_no:obj.po_details[i].po_no,
              checked: false
            })
          }

        }, error => {

        }
      )
  }

  changeMedicineName(e, type) {

    var senddetails = JSON.stringify({
      drug_name: e.target.value.toString(),
      pharmacy_id: this.pharmacyID,
      medicare_name: "1",
      type: type
    })
    if (e.target.value.toString() != null && e.target.value.toString().length > 2) {

      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/nmeddetails/', senddetails,
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();


            if (obj.med_details != null) {
              this.MedicineNameArray = [];
              this.medicineArray = [];
              this.MedicneData = [];
              for (var i = 0; i < obj.med_details.length; i++) {
                var medname = obj.med_details[i].short_name + " " + obj.med_details[i].med_name
                // this.MedicneData.push(medname);
                this.MedicneData.push({ name: medname, quantity: obj.med_details[i].quantity, generic: obj.med_details[i].generic_name });
                this.medicineArray.push({
                  med_id: obj.med_details[i].med_id,
                  med_name: obj.med_details[i].med_name,
                  med_type_id: obj.med_details[i].med_type_id,
                  type_name: obj.med_details[i].type_name,
                  short_name: obj.med_details[i].short_name,
                  short_form: obj.med_details[i].short_form,
                  show_short_form: obj.med_details[i].show_short_form,
                  show_intake: obj.med_details[i].show_intake,
                  medname: medname,
                  cgst: obj.med_details[i].cgst,
                  sgst: obj.med_details[i].sgst,
                  rack_id: obj.med_details[i].rack_id,
                  bin_id: obj.med_details[i].bin_id
                })
              }
            }
          },
          error => { });
    } else {
      this.MedicineNameArray = [];
    }
  }

  selectMedicineItem(item, inventorySendArray) {

    // this.medicinenameflag = true;
    // this.selectedbatch_flag = false;
    for (var i = 0; i < this.medicineArray.length; i++) {
      if (item.name == this.medicineArray[i].medname) {

        inventorySendArray.product_id = this.medicineArray[i].med_id
        // drug_detailsarray.drug_name = item.name;
        // drug_detailsarray.gen_name = item.generic;
        // drug_detailsarray.drug_disp_name = this.medicineArray[i].short_name + " " + this.medicineArray[i].med_name;
        // drug_detailsarray.drug_id = this.medicineArray[i].med_id;
        // this.medicineName = this.medicineArray[i].med_name;
        // drug_detailsarray.cgst = this.medicineArray[i].cgst;
        // drug_detailsarray.sgst = this.medicineArray[i].sgst;
        // drug_detailsarray.rack_id = this.medicineArray[i].rack_id;
        // drug_detailsarray.bin_id = this.medicineArray[i].bin_id;
        // sno: i + 1,
        // purchase_request_id: obj.product_details[i].purchase_request_id,
        // product_id: obj.product_details[i].product_id,
        // hsn_no: obj.product_details[i].hsn_no,
        // product_name: obj.product_details[i].product_name,
        // uom_code: obj.product_details[i].uom_code,
        // uom_desc: obj.product_details[i].uom_desc,
        // quantity: obj.product_details[i].quantity,
        // unit_price: obj.product_details[i].sales_price,
        // sales_price: obj.product_details[i].sales_price,
        // cost_price: obj.product_details[i].cost_price,
        // pharma_id: this.pharmacyID,
        // cgst: obj.product_details[i].cgst,
        // sgst: obj.product_details[i].sgst,
        // location: this.locationName,
        // expiry_date: "",//this.currentMonthYear,
        // index: index,
        // checked: false,
        // previousValue:0,
        // type:"noinputbox"
      }
    }

    // this.getItemPrices(drug_detailsarray);
    // drug_detailsarray.batcharray = [];
    // drug_detailsarray.batch_no = "";
    // drug_detailsarray.quantityDisable = true;
    this.MedicineNameArray = [];
  }

  isAllSelectedPO(po, checked) {
    po.checked = !checked;
    this.Totalcostpirce = 0;
    this.Discount_value=0;
    this.inventory = [];
   
    var poorder: any = [];
    for (var i = 0; i < this.productdata_detal.length; i++) {
      if (po.purchase_order_id == this.productdata_detal[i].purchase_order_id) {
        if (checked == true) {
          this.poorder = this.poorder.filter(orderId => orderId !== po.purchase_order_id);
          if(this.poorder.length == 0){
            this.inputsupplierflag=true;
            this.supplierflag=false;
          }
          
          var send_data = {
            pharma_id: this.pharmacyID,
            department_id:this.depart_id,
            po_no: this.poorder,
            type:this.user_type
          }
          
          var headers = new Headers();
          headers.append('Content-Type', 'application/json');
          this.http.post(ipaddress.getIp.toString() + 'inv/gibp', send_data,
            { headers: headers })
            .subscribe(
              response => {
                var obj = response.json();

                var index = 0;
                for (var i = 0; i < obj.product_details.length; i++) {
                  var discount;
                  var perunit;
                  if (obj.product_details[i].discount != undefined) {
                    discount = obj.product_details[i].discount
                  } else {
                    discount = 0
                  }
                  if(obj.product_details[i].perunit != undefined){
                    perunit=obj.product_details[i].perunit
                  }else{
                    perunit=0
                  }
                  this.inventory.push({
                    sno: i + 1,
                    purchase_request_id: obj.product_details[i].purchase_request_id,
                    product_id: obj.product_details[i].product_id,
                    hsn_no: obj.product_details[i].hsn_no,
                    product_name: obj.product_details[i].product_name,
                    uom_code: obj.product_details[i].uom_code,
                    uom_desc: obj.product_details[i].uom_desc,
                    quantity: obj.product_details[i].quantity,
                    unit_price: obj.product_details[i].sales_price,
                    sales_price: obj.product_details[i].sales_price,
                    cost_price: obj.product_details[i].cost_price,
                    pharma_id: this.pharmacyID,
                    cgst: obj.product_details[i].cgst,
                    sgst: obj.product_details[i].sgst,
                    prod_rec_item_id:"0",
                    location: this.locationName,
                    discount: discount,
                    expiry_date: "",//this.currentMonthYear,
                    index: index,
                    checked: true,
                    previousValue: 0,
                    type: "noinputbox",
                    perunit:perunit
                  });
                  this.cost_price(obj.product_details[i])
                  index++;
                }
                this.inventoryLength = this.inventory.length;
                this.updateflag=true
              },
              error => {

              });

        } else {
          this.poorder.push(this.productdata_detal[i].purchase_order_id);
          this.supplierflag=true;
          this.inputsupplierflag=false;
          this.podataid = this.productdata_detal[i].purchase_order_id;
          
          var send_data = {
            pharma_id: this.pharmacyID,
            department_id:this.depart_id,
            po_no: this.poorder,
            type:this.user_type
          }

          var headers = new Headers();
          headers.append('Content-Type', 'application/json');
          this.http.post(ipaddress.getIp.toString() + 'inv/gibp', send_data,
            { headers: headers })
            .subscribe(
              response => {
                var obj = response.json();

                var index = 0;
                var perunit;
                for (var i = 0; i < obj.product_details.length; i++) {
                  if(obj.product_details[i].perunit != undefined){
                    perunit=obj.product_details[i].perunit
                  }else{
                    perunit=0
                  }
                  this.inventory.push({
                    sno: i + 1,
                    purchase_request_id: obj.product_details[i].purchase_request_id,
                    product_id: obj.product_details[i].product_id,
                    hsn_no: obj.product_details[i].hsn_no,
                    product_name: obj.product_details[i].product_name,
                    uom_code: obj.product_details[i].uom_code,
                    uom_desc: obj.product_details[i].uom_desc,
                    quantity: obj.product_details[i].quantity,
                    unit_price: obj.product_details[i].sales_price,
                    sales_price: obj.product_details[i].sales_price,
                    cost_price: obj.product_details[i].cost_price,
                    prod_rec_item_id:"0",
                    pharma_id: this.pharmacyID,
                    cgst: obj.product_details[i].cgst,
                    sgst: obj.product_details[i].sgst,
                    location: this.locationName,
                    expiry_date: "",//this.currentMonthYear,
                    index: index,
                    checked: true,
                    previousValue: 0,
                    type: "noinputbox",
                    perunit:perunit
                  });
                  this.cost_price(obj.product_details[i])
                  index++;
                }
                this.inventoryLength = this.inventory.length;
                this.updateflag=true
              },
              error => {

              });
              // setTimeout(()=>{
              //   if(this.poorder.length != 0){
              //     if(this.inventory.length != 0 ){
              //       this.save_dataflag=true
              //   }else{
              //     this.save_dataflag=false
              //   }
              //   this.save_dataflag=true
              //   }else{
              //     this.save_dataflag=false
              //   }
              // },100)
        }
      }
    }

    if(this.po_required == "1" && this.inventory.length == 0){
      this.po_requiredflag= false
    }else if(this.po_required == "0" && this.inventory.length == 0){
      this.po_requiredflag= true
    }else if(this.po_required == "0" && this.inventory.length > 0){
      this.po_requiredflag= true
    }else{
      this.po_requiredflag= true
    }
   
  }

  checkboxfunction() {


    if (this.filtercheckbox == true) {


      this.checkboxarry = this.product_data;

      this.product_data = [];
      this.productdata_detal = [];
      for (var i = 0; i < this.checkboxarry.length; i++) {
        // this.product_data.checked=true;
        // this.productdata_detal.checked=true;

        this.product_data.push({
          purchase_order_id: this.checkboxarry[i].purchase_order_id,
          date: this.checkboxarry[i].date,
          checked: true
        })
        this.productdata_detal.push({
          purchase_order_id: this.checkboxarry[i].purchase_order_id,
          date: this.checkboxarry[i].date,
          checked: true
        })
      }
      this.selectallpoorder(true);
      //   for (var j=0 ;j < this.product_data.length;j++){
      //     this.isAllSelectedPO(this.product_data[j],false)
      //  }

    } else if (this.filtercheckbox == false) {
      this.checkboxarry = this.product_data
      this.product_data = [];
      this.productdata_detal = [];
      for (var i = 0; i < this.checkboxarry.length; i++) {
        this.product_data.checked = false;
        this.productdata_detal.checked = false;

        this.product_data.push({
          purchase_order_id: this.checkboxarry[i].purchase_order_id,
          date: this.checkboxarry[i].date,
          checked: false
        })
        this.productdata_detal.push({
          purchase_order_id: this.checkboxarry[i].purchase_order_id,
          date: this.checkboxarry[i].date,
          checked: false
        })
      }
      this.selectallpoorder(false);
    }

  }

  selectallpoorder(value) {
    this.poorder = [];
    this.inventory = [];
    this.Totalcostpirce = 0;
    // let selectallpurchaseid=[];
    if (value == true) {
      for (var k = 0; k < this.productdata_detal.length; k++) {
        this.poorder.push(this.productdata_detal[k].purchase_order_id);
      }
      var send_data = {
        pharma_id: this.pharmacyID,
        po_no: this.poorder
      }

      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'inv/gibp', send_data,
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();

            var index = 0;
            var perunit
            for (var i = 0; i < obj.product_details.length; i++) {
              if(obj.product_details[i].perunit != undefined){
                perunit=obj.product_details[i].perunit
              }else{
                perunit=0
              }
              this.inventory.push({
                sno: i + 1,
                purchase_request_id: obj.product_details[i].purchase_request_id,
                product_id: obj.product_details[i].product_id,
                hsn_no: obj.product_details[i].hsn_no,
                product_name: obj.product_details[i].product_name,
                uom_code: obj.product_details[i].uom_code,
                uom_desc: obj.product_details[i].uom_desc,
                quantity: obj.product_details[i].quantity,
                unit_price: obj.product_details[i].sales_price,
                sales_price: obj.product_details[i].sales_price,
                cost_price: obj.product_details[i].cost_price,
                pharma_id: this.pharmacyID,
                cgst: obj.product_details[i].cgst,
                sgst: obj.product_details[i].sgst,
                location: this.locationName,
                expiry_date: "",//this.currentMonthYear,
                index: index,
                checked: false,
                previousValue: 0,
                type: "noinputbox",
                perunit:perunit
              });
              this.cost_price(obj.product_details[i])
              index++;
            }
            this.inventoryLength = this.inventory.length;
            if(this.po_required == "1" && this.inventory.length == 0){
              this.po_requiredflag= false
            }else if(this.po_required == "0" && this.inventory.length == 0){
              this.po_requiredflag= true
            }else if(this.po_required == "0" && this.inventory.length > 0){
              this.po_requiredflag= true
            }else{
              this.po_requiredflag= true
            }
          },
          error => {

          });
    } else {
      this.inventory = [];
    }

    // this.poorder.push(this.productdata_detal[i].purchase_order_id);


  }

  checkeddata(item) {

    var id = item.purchase_order_id;
    this.poarray.push(
      item.purchase_order_id
    )

  }

  productOrder() {
    var senddata;
    senddata = {
      pharmacy_id: this.pharmacyID,
      is_approve: this.poapproved === "1" ? "1" : undefined
    };

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "inv/gpos/", JSON.stringify(senddata),
      { headers: headers }).subscribe(
        data => {
          var obj = data.json();

          if (data.json().purchase_orders != null) {
            this.product_data = data.json().purchase_orders;
          }
        },
        error => { });
  }

  inventrybase_department() {
    var senddata = {
      po_base: this.pobase,
      po_id: this.poID

    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "gen/gdpo ", JSON.stringify({ po_base: this.pobase, po_id: this.poID, type: this.user_type }), { headers: headers }).subscribe(
      response => {
        var obj = response.json()

        this.inventdepartmentarray = obj.departments;
      }
    )
  }

  pobase_department(value) {
    this.getstorename(this.inventrydepartname)
    this.getPurchaseDetails()
  }

  getstorename(value) {
    var senddata = {
      hptl_clinic_id: this.pharmacyID,
      department_id: value
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "gen/gsdbh", JSON.stringify({ hptl_clinic_id: this.hsp_id, department_id: value }), { headers: headers }).subscribe(
      response => {
        var obj = response.json()

        this.inventrystorenamearray = obj.stores;
      },
      error => {

      }
    )
  }

  pobase_store(value) {

    this.getPurchaseDetails()
  }

  getPackages() {
    this.http.get(ipaddress.getIp + "pharmacontrol/pkgcode/").subscribe(
      data => {
        if (data.json().product_packages != null) {
          this.package_data = data.json().product_packages;
        }
      },
      error => { });
  }

  getsupplierid() {
    this.inventory = [];
    this.purchasePoID = "";
    this.purchasePoID = this.poID;

    if (this.poID != undefined) {
      for (var j = 0; j < this.product_data.length; j++) {
        if (this.poID == this.product_data[j].purchase_order_id) {
          this.pobase = this.product_data[j].po_base
        }
      }
    }
    var inventorydatasenddata = {
      purchase_order_id: this.poID, department_id: this.inventrydepartname,
      store_id: this.inventrystorename,
      po_base: this.pobase
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/gpo/", JSON.stringify({
      purchase_order_id: this.poID,
      // department_id:this.inventrydepartname,
      // store_id:this.inventrystorename,
      // po_base:this.pobase
    }), { headers: headers }).subscribe(
      data => {
        if (data.json() != null) {
          var obj = data.json();

          this.mgfID = obj.mfg_id;
          this.suppID = obj.supp_id;
          this.manufacturer = obj.name;
          this.gst_no = obj.gst_no;
          this.countryID = obj.country;
          var index = 0;
        }
      },
    )
  
  }

  getPurchaseDetails() {
    this.inventory = [];
    this.purchasePoID = "";
    this.purchasePoID = this.poID;

    if (this.poID != undefined) {
      for (var j = 0; j < this.product_data.length; j++) {
        if (this.poID == this.product_data[j].purchase_order_id) {
          this.pobase = this.product_data[j].po_base
        }
      }
    }
    // if (this.poID != undefined) {
    var inventorydatasenddata = {
      purchase_order_id: this.poID, department_id: this.inventrydepartname,
      store_id: this.inventrystorename,
      po_base: this.pobase
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "inv/gibpo/", JSON.stringify({
      po_id: this.poID, department_id: this.inventrydepartname,
      store_id: this.inventrystorename, po_base: this.pobase
    }), { headers: headers }).subscribe(
      data => {
        if (data.json() != null) {
          var obj = data.json();

          // this.mgfID = obj.mfg_id;
          // this.suppID = obj.supp_id;
          // this.manufacturer = obj.name;
          // this.gst_no=obj.gst_no;
          // this.countryID = obj.country;
          var index = 0;
          var perunit
          for (var i = 0; i < obj.purchase_items.length; i++) {
            if(obj.product_details[i].perunit != undefined){
              perunit=obj.product_details[i].perunit
            }else{
              perunit=0
            }
            this.inventory.push({
              purchase_request_id: obj.purchase_items[i].purchase_request_id,
              product_id: obj.purchase_items[i].product_id,
              hsn_no: obj.purchase_items[i].hsn_no,
              product_name: obj.purchase_items[i].product_name,
              uom_code: obj.purchase_items[i].uom_code,
              uom_desc: obj.purchase_items[i].uom_desc,
              quantity: obj.purchase_items[i].quantity,
              unit_price: obj.purchase_items[i].unit_price,
              sales_price: "0",
              cost_price: "0",
              pharma_id: this.pharmacyID,
              cgst: obj.purchase_items[i].cgst,
              sgst: obj.purchase_items[i].sgst,
              location: this.locationName,
              expiry_date: "",//this.currentMonthYear,
              index: index,
              checked: false,
              previousValue: 0,
              type: "noinputbox",
              perunit:perunit
            });
            index++;
          }
          this.inventoryLength = this.inventory.length;
          if(this.po_required == "1" && this.inventory.length == 0){
            this.po_requiredflag= false
          }else if(this.po_required == "0" && this.inventory.length == 0){
            this.po_requiredflag= true
          }else if(this.po_required == "0" && this.inventory.length > 0){
            this.po_requiredflag= true
          }else{
            this.po_requiredflag= true
          }
        }
      },
    )

    this.inventrybase_department()
  
  }

  fillOtherDetails() {
    if (this.productName != undefined) {
      for (var i = 0; i < this.getPurchaseArray.length; i++) {
        if (this.productName == this.getPurchaseArray[i].product_name) {
          this.receivedQuantity = this.getPurchaseArray[i].quantity;
          if (this.getPurchaseArray[i].uom_desc != undefined) {
            if (this.uom != undefined) {
              this.uom.uom_desc = this.getPurchaseArray[i].uom_desc;
            }
          }
        }
      }
    }
  }

  getPharmacy() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/pharlocbyid/",
      JSON.stringify({
        pharmacy_id: this.pharmacyID,
        pharmacist_id: this.pharmacistID,
      }), { headers: headers }).subscribe(
        data => {
          if (data.json().pharma_locations != null) {
            this.pharmacyLocationArray = data.json().pharma_locations;
            this.locationArray = this.pharmacyLocationArray;
            this.locationName = this.locationArray[0].location;
          }
        },
        error => { });
  }

  isDiscountOptionSelected(value) {
    if (value == "percentage") {
      this.discountpercentageflag = true
      this.discountpriceflag = false
      this.product_wise_discountflag = true
      for(var i=0;i<this.inventory.length;i++){
        this.inventory[i].discount=0;
      }
      // this.GSTamount = 0;
      // this.invoiceAmount = 0;

    } else if (value == "price") {
      this.discountpriceflag = true
      this.discountpercentageflag = false
      this.product_wise_discountflag = true
      // this.GSTamount = 0;
      // this.invoiceAmount = 0;

    } else if (value == "product_wise") {
      this.product_wise_discountflag = false
      this.discountpercentageflag = false
      this.discountpriceflag = false
      // this.GSTamount = 0;
      // this.invoiceAmount = 0;
    }
  }

  cost_price(data) {

    if(this.pagetype == "new"){
      this.updateTotalCost();
    }else{
     if(data.prod_rec_item_id != 0){
      if(data.quantity < data.actual_quantity){
        data.quantity = data.actual_quantity
        this.toastr.error("The quantity should not less than "+data.actual_quantity)
        this.updateTotalCost();
      }else{
        this.updateTotalCost();
      }
     }else{
      this.updateTotalCost();
     }
    }
  }

  free_quantity(data) {

    if(this.pagetype == "new"){
    }else{
     if(data.prod_rec_item_id != 0){
      if(data.free_qty < data.actual_free_qty){
        const free_qty_data:number =  data.actual_free_qty+0;
        data.free_qty = free_qty_data
        this.toastr.error("The free_qty should not less than "+data.actual_free_qty)

      }else{
      }
     }else{
     }
    }

  }

  updateTotalCost() {
    if(this.discount_option == "select"){
      this.discountpercentageflag=false
    }
    this.totalCostForCheckedItems = 0.00;
    this.GSTamount = 0;
    this.invoiceAmount = 0;
    this.disamt = 0.00;
    this.Cgst=0.00;
    this.Cgstper=0.00;
    this.Sgst=0.00;
    this.Sgstper=0.00;
    this.tcprice="0.00"
    for (let data of this.inventory) {

      if (data.checked) {
        // Assuming data.quantity and data.cost_price are numeric values
        var discgst = 0;
        var dissgst = 0;
        var cgstcal = 0;
        var sgstcal = 0;
        
        if (data.cost_price != undefined) {
          this.totalCostForCheckedItems += data.quantity * data.cost_price;
        } else {
          this.totalCostForCheckedItems += data.quantity * 0;
        }

        if (data.cost_price != undefined) {
          var itemvalue:any = data.quantity * data.cost_price;
        } else {
          var itemvalue:any = data.quantity * 0;
        }

        if (data.cgst != undefined && data.cgst != null && data.cgst != "") {
          discgst = parseFloat(data.cgst)
        } else {
          discgst = 0
        }
        if (data.sgst != undefined && data.sgst != null && data.sgst != "") {
          dissgst = parseFloat(data.sgst)
        } else {
          dissgst = 0
        }
       
        var afterdisamt = parseFloat(itemvalue);
        this.Cgstper+=discgst;
        this.Sgstper+=dissgst;
        this.Cgst+=afterdisamt * (discgst / 100)
        this.Sgst+=afterdisamt * (dissgst / 100)
        this.Cgst = this.spliceDecimal(this.Cgst)
        this.Sgst = this.spliceDecimal(this.Sgst)
        var itemgst:any = (afterdisamt * (discgst / 100)) + (afterdisamt * (dissgst / 100));
        
        data.gst=itemgst.toFixed(2)
      
        var total_item_value = afterdisamt + itemgst
     
        this.GSTamount += parseFloat(itemgst);
        this.GSTamount = parseFloat(this.GSTamount.toFixed(2))
        this.invoiceAmount += total_item_value;
        this.invoiceAmount = parseFloat(Math.round(this.invoiceAmount).toFixed(2))
        this.refundamountcal()
       
        this.tcprice = (this.totalCostForCheckedItems).toFixed(2)
        this.constpriceinwords = convertNumberToWords(this.invoiceAmount, ipaddress.country_code);
      }
    }
    // this.discountcal();
  }

  discountcal() {
    this.invoiceAmount = 0.00;
    this.discamount = 0.00;
    if (this.product_wise_discountflag == false) {
      this.totalCostForCheckedItems = 0.00;
      this.aftdiscamt = 0.00;
      this.GSTamount = 0.00;
      this.disamt = 0.00;
      this.invoiceAmount = 0.00;
      this.cgst=0.00;
      this.sgst=0.00;
      for (let data of this.inventory) {
        if (data.checked) {
          // if (data.discount != undefined && data.discount != "") {
            this.aftdiscamt = 0.00;
            var discgst = 0.00;
            var dissgst = 0.00;
            var cgstcal = 0.00;
            var sgstcal = 0.00;

            if (data.cgst != undefined && data.cgst != null && data.cgst != "") {
              discgst = parseFloat(data.cgst)

            } else {
              discgst = 0.00
            }
            if (data.sgst != undefined && data.sgst != null && data.sgst != "") {
              dissgst = parseFloat(data.sgst)

            } else {
              dissgst = 0.00
            }
            var itemvalue = data.quantity * data.cost_price;
           

            if (data.discount != undefined) {
              var discamt = itemvalue * (parseFloat(data.discount) / 100);

            } else {
              var discamt = 0;
            }
            
            var afterdisamt = itemvalue - discamt
          
            var itemgst = ((afterdisamt *discgst) / 100) + ((afterdisamt * dissgst) / 100)
            var total_item_value = afterdisamt + itemgst
           
            data.gst=itemgst.toFixed(2)
            this.disamt += discamt;
            this.disamt = parseFloat(this.disamt.toFixed(2))
            this.cgst += (afterdisamt * (discgst / 100))
            this.sgst += (afterdisamt * (dissgst / 100))
            this.cgst = parseFloat(this.cgst .toFixed(2))
            this.sgst = parseFloat(this.sgst.toFixed(2))
            this.GSTamount += itemgst;
            this.GSTamount = parseFloat(this.GSTamount.toFixed(2))
            this.invoiceAmount += total_item_value;
            this.invoiceAmount = parseFloat(Math.round(this.invoiceAmount).toFixed(2))
            this.refundamountcal()
            this.disamt.toFixed(2);
            
          // }
        }
      }

    } else if (this.discountpercentageflag == true) {
      this.totalCostForCheckedItems = 0;
      this.aftdiscamt = 0;
      this.GSTamount = 0;
      this.disamt = 0;
      this.invoiceAmount = 0;
      this.cgst=0;
      this.sgst=0;
      var disamount = 0;
      for (let data of this.inventory) {
        if (data.checked) {
          if (this.Discount_value != undefined && this.Discount_value.length >= 1) {
            this.aftdiscamt = 0;
            var discgst = 0;
            var dissgst = 0;
            var cgstcal = 0;
            var sgstcal = 0;

            if (data.cgst != undefined && data.cgst != null && data.cgst != "") {
              discgst = parseFloat(data.cgst)

            } else {
              discgst = 0
            }
            if (data.sgst != undefined && data.sgst != null && data.sgst != "") {
              dissgst = parseFloat(data.sgst)

            } else {
              dissgst = 0
            }

            var itemvalue = data.quantity * data.cost_price;
            var discamt = itemvalue * (this.Discount_value / 100)
            var afterdisamt = itemvalue - discamt;
            var itemgst = ((afterdisamt *discgst) / 100) + ((afterdisamt * dissgst) / 100);
            var total_item_value = afterdisamt + itemgst;
            data.gst=itemgst.toFixed(2)
            this.disamt += discamt;
            this.disamt = parseFloat(this.disamt.toFixed(2))
            this.cgst += (afterdisamt * (discgst / 100))
            this.sgst += (afterdisamt * (dissgst / 100))
            this.cgst = parseFloat(this.cgst .toFixed(2))
            this.sgst = parseFloat(this.sgst.toFixed(2))
            this.GSTamount += itemgst;
            this.GSTamount = parseFloat(this.GSTamount.toFixed(2))
            this.invoiceAmount += total_item_value;
            this.invoiceAmount = parseFloat(Math.round(this.invoiceAmount).toFixed(2))
            this.refundamountcal()

            this.disamt.toFixed(2);

           

            // this.totalCostForCheckedItems += (data.quantity * data.cost_price);
            // disamount = disamount + this.totalCostForCheckedItems * (parseFloat(this.Discount_value) / 100);
            // const discountAmount: number = this.totalCostForCheckedItems * (parseFloat(this.Discount_value) / 100);

            // // Format the discount amount for display
            // this.disamt = parseFloat(discountAmount.toFixed(2));
            // var aft = (this.totalCostForCheckedItems - disamount)
            // this.aftdiscamt += (this.totalCostForCheckedItems - disamount)
            // var cgstcal = (this.aftdiscamt * discgst / 100)
            // var sgstcal = (this.aftdiscamt * discgst / 100)
            // this.GSTamount = parseFloat((cgstcal + sgstcal).toFixed(2));
            // this.invoiceAmount += this.aftdiscamt + cgstcal + sgstcal
            // this.invoiceAmount = parseFloat(this.invoiceAmount.toFixed(2));
           
          } else {
            this.updateTotalCost()
            this.refundamountcal()
          }
        }
      }

    } else if (this.discountpriceflag == true) {
  
      this.GSTamount = 0;
      this.totalCostForCheckedItems = 0;
      this.aftdiscamt = 0;
      this.cgst=0;
      this.sgst=0;
      this.invoiceAmount = 0;
      for (let data of this.inventory) {
        if (data.checked) {

          if (this.discountamount != undefined && this.discountamount.length >= 1) {
            var discgst = 0;
            var dissgst = 0;
            var cgstcal = 0;
            var sgstcal = 0;
            if (data.cgst != undefined && data.cgst != null && data.cgst != "") {
              discgst = parseFloat(data.cgst)
            } else {
              discgst = 0
            }
            if (data.sgst != undefined && data.sgst != null && data.sgst != "") {
              dissgst = parseFloat(data.sgst)
            } else {
              dissgst = 0
            }
            var itemvalue = data.quantity * data.cost_price;
            var itemgst = (itemvalue * (discgst / 100)) + (itemvalue * (dissgst / 100));
            data.itemvalue = itemvalue;
            data.gstvalue = itemgst;
            data.gst=itemgst.toFixed(2)
          } else {
            this.invoiceAmount = 0
            this.refundamountcal()
          }

        }
      }
      var totalval = 0;
      var totalgst = 0;
      for (let data of this.inventory) {
        if (data.checked) {
          if (this.discountamount != undefined && this.discountamount.length >= 1) {
            totalval += data.itemvalue;
            totalgst += data.itemgst;
          }
        }
      }
      this.disamt = this.discountamount.toFixed(2);
      this.GSTamount = totalgst;
      this.cgst += (itemvalue * (discgst / 100))
      this.sgst += (itemvalue * (dissgst / 100))
      this.cgst = parseFloat(this.cgst .toFixed(2))
      this.sgst = parseFloat(this.sgst.toFixed(2))
      this.invoiceAmount = (totalval - this.disamt) + this.GSTamount
      this.refundamountcal()
    }else{
      this.updateTotalCost()
    }
    this.invoiceamount(this.invoiceAmount)
  }

  deleteItemReceive(data) {
    if (this.inventory.length != 0) {
      for (var i = 0; i < this.inventory.length; i++) {
        if (this.inventory[i].product_id == data.product_id && this.inventory[i].quantity == data.quantity) {
          if (this.inventory[i].index == data.index)
            this.inventory.splice(i, 1);
        }
      }
      this.updateTotalCost();
    }
    this.updateflag = this.inventory.length != 0 ? true:false
  }

  deleteMedicine(drug_id, index) {

  }

  getProductDetails() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/gpcbypharn/",
      JSON.stringify({
        pharmacist_id: this.userID,
        prod_name: this.productName,
        mfg_id: this.mgfID,
        pharmacy_id: this.pharmacyID
      }), { headers: headers }).subscribe(
        data => {
          var dataval = JSON.parse(data["_body"]);
          if (dataval != undefined && dataval.products != null) {
            this.productArray = dataval.products;
          }
        });
  }

  getProductName(data) {
    this.productName = data.name;
    this.uom = data.uom_desc;
    this.productID = data.product_id;
    this.orderProduct = data.product_code;
    this.unitPrice = data.cost_price;
    this.hsnNO = data.hsn_no;
    this.productArray = [];
  }

 

  edit(data) {
    this.inventory.splice(data, 1);
  }

  selectLocation() {
    this.locationName = this.locationName;
  }

  clickReceviedDate(date, dataval) {
    dataval.qty_recieved_date1 = (date);
    dataval.qty_recieved_date = Date_Formate(date);
  }

  clearProdName() {
    this.productName = "";
  }

  importFromExcel() {
    var send = { pharma_fac_id: this.locationArray.pharma_facility_id }
    Helper_Class.set_inventory_upload(send);
    this.messageservice.sendMessage("inventory_upload");
  }

  getDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate', { country: ipaddress.country_code },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.currentDate = obj.current_date;
          this.recvDate = this.currentDate;
          this.invDate = this.currentDate;
          var mY = this.currentDate.split("-");
          this.currentMonthYear = mY[0] + "-" + mY[1];
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }

  addInventory() {
    var index = this.inventory.length + 1;
   

    this.inventory.push({
      sno: index,
      purchase_request_id: "",
      perunit:"",
      product_id: "",
      hsn_no: "",
      product_name: "",
      uom_code: "",
      uom_desc: "",
      quantity: "",
      unit_price: "",
      pharma_id: this.pharmacyID,
      cgst: "",
      sgst: "",
      location: this.locationName,
      expiry_date: "",//this.currentMonthYear,
      index: index - 1,
      checked: true,
      type: "input",
      delete: false
    });

    if(this.po_required == "1" && this.inventory.length == 0){
      this.po_requiredflag= false
    }else if(this.po_required == "0" && this.inventory.length == 0){
      this.po_requiredflag= true
    }else if(this.po_required == "0" && this.inventory.length > 0){
      this.po_requiredflag= true
    }else{
      this.po_requiredflag= true
    }
    this.updateflag=true
  }



  batchNo(inventory) {
    var bool: boolean = false;
    for (var i = 0; i < this.inventory.length; i++) {
      if (this.inventory[i].index != inventory.index) {
        if (this.inventory[i].product_id == inventory.product_id) {
          if (this.inventory[i].batch_no == inventory.batch_no) {
            this.toastr.error(Message_data.sameBatchNo);
            bool = true;
          }
        }
      }
      if (bool == true) {
        if (this.inventory[i].index == inventory.index) {
          this.inventory[i].batch_no = "";
        }
      }
    }
  }

  changeExpiryDate(e, data) {

    

    var len = e.length;
    var flag: boolean = false;
    if (len <= 2 && len != 0) {
      e = this.checkValue(e, 12);
      if (e.length > 1)
        flag = true;
    }
    if (len === 2 && flag == true) {
      e += '/';
    }
    data.expiry_date = e;

    if (length > (length = data.expiry_date.length)) return;
    var mapped = data.expiry_date.split("").map(function (this, char, i) {
      switch (i) {
        case 0:
          return char == "1" || char == "0" ? char : "";
          break;
        case 1:
          return !isNaN(parseInt(char, 10)) ? (this[0] == "0" ? char : (+char < 3 ? char : "")) : "";
          break;
        case 2:
          return "-";
          break;
        case 3:
        case 4:
        case 5:
        case 6:
          return !isNaN(parseInt(char, 10)) ? char : "";
          break;
        default:
          return "";
          break;
      }
    }, data.expiry_date);
    if (mapped.length === 2) mapped.push("-");
    data.expiry_date = mapped.join("");
  }

  validateExpiryDate(date, data) {
    const currentYear = new Date().getFullYear(); // Full year (e.g., 2025)
    const currentMonth = new Date().getMonth() + 1; // Months are 0-indexed
    var m = [];
    m = date.split("-");
    let [monthStr, yearStr] = m;
    if (m[0] == "00" || m[0] == "0" || m[0].length < 2) {
      if (m[1] == "0000" || m[1] == "0" || m[1].length < 4) {
        this.toastr.error("Check Expiry year and date");
        data.expiry_date_flag = false;

      } else {
        this.toastr.error("Check Expiry date");
        data.expiry_date_flag = false;
      }

    } else if (m[1] == "0000" || m[1] == "0" || m[1].length < 4) {
      this.toastr.error("Check Expiry year and date");
      data.expiry_date_flag = false;

    } 
    // else {
    //   data.expiry_date_flag = true;
    // }
  
    const month = parseInt(monthStr, 10);
    const year = parseInt(yearStr, 10);
    // Validate month value
    if (isNaN(month) || month < 1 || month > 12) {
      this.toastr.error("Invalid month. Use a value between 01 and 12.");
      data.expiry_date = ""; // Clear the field
      data.expiry_date_flag = false;
      return;
    }
  
    // Validate year value
    if (isNaN(year) || year < 1000 || year > 9999) {
      this.toastr.error("Invalid year. Enter a valid 4-digit year.");
      data.expiry_date = ""; // Clear the field
      data.expiry_date_flag = false;
      return;
    }
  
    // Check if the year is less than the current year
    if (year < currentYear) {
      this.toastr.error("Enter valid expiry date.");
      data.expiry_date = ""; // Clear the field
      data.expiry_date_flag = false;
      return;
    }
  
    // If the year is the current year, check if the month is less than the current month
    if (year === currentYear && month < currentMonth) {
      this.toastr.error("Month cannot be less than the current month for the current year.");
      data.expiry_date = ""; // Clear the field
      data.expiry_date_flag = false;
      return;
    }
    data.expiry_date_flag = true;
  }

  checkValue(str, max) {
    if (str.charAt(0) !== '0' || str == '00') {
      var num = parseInt(str);
      if (isNaN(num) || num <= 0 || num > max) num = 1;
      str = num > parseInt(max.toString().charAt(0)) && num.toString().length == 1 ? '0' + num : num.toString();
    };
    return str;
  }

  isAllSelected(list, action) {
    list.checked = action == "select" ? true : false;


    this.updateTotalCost()
    
    //   var inventorySendArray = this.inventory.filter((x) => { return x.checked == true });
    //   for(var i=0;i<inventorySendArray.length;i++) {
    //     var expdate = inventorySendArray[i].expiry_date.split("-");
    //     inventorySendArray[i].expiry_date = expdate[1]+"-"+expdate[0]+"-"+"28";

    // }
    // this.inventorydetailsexp=inventorySendArray

  }

  setSalesPrice(data) {
    for (var i = 0; i < this.inventory.length; i++) {
      if (this.inventory[i].product_id == data.product_id) {
        this.inventory[i].unit_price = data.sales_price;
      }
    }
  }

  setgstdata(data) {
    for (var i = 0; i < this.inventory.length; i++) {
      if (this.inventory[i].product_id == data.product_id) {
        this.inventory[i].sgst = data.cgst;
      }
    }
  }

  invoiceamount(value){
    this.constpriceinwords = convertNumberToWords(value, ipaddress.country_code);
  }

  refundamountcal(){

   
    if(this.returnAmount > this.invoiceAmount){
      this.balance = this.invoiceAmount  - this.returnAmount;
      this.balance = this.spliceDecimal(this.balance)
      if(this.invoiceAmount != 0){
        this.receivedAmount = this.invoiceAmount;
      }
      // this.invoiceAmount = this.receivedamoutsupp + 0;
      this.invoiceAmountbe =  0.00;
      // this.invoiceAmount= this.invoiceAmount - this.returnAmount;
    }else{
      // this.receivedAmount = this.returnAmount;

      // this.invoiceAmount= this.invoiceAmount - this.returnAmount
      this.invoiceAmountbe = (this.invoiceAmount  - this.returnAmount)
      // this.invoiceAmount= this.spliceDecimal(this.invoiceAmount)
      this.invoiceAmountbe= this.spliceDecimal(this.invoiceAmountbe)
      this.balance=0.00;
    }

  }



  spliceDecimal(number) {
    // Convert the number to a string
    let numberStr = number.toString();
    // Split the number into integer and decimal parts
    let parts = numberStr.split('.');
    // Check if there's a decimal part
    
    if (parts.length > 1) {
      // Take the first two digits of the decimal part
      let newDecimal = parts[1].slice(0, 2);
      // Combine the integer part with the truncated decimal part
      var newNumberStr = parts[0] + '.' + newDecimal;
      // Convert back to a number
      return parseFloat(newNumberStr);
      
    } else {
      // If there's no decimal part, return the original number
      return number;
    }
    // 
  }

  clearSupplier() {
    this.manufacturer=undefined;
    this.productdata_detal=[];
    this.product_data=[];
    this.poorder=[];
  }

  getbatchnos(data){
    var send;
    if(data.batch_no.length >= 3){
      send={
        product_id:data.product_id,
        pharma_id:this.pharmacyID,
        batch_no:data.batch_no
      }
      
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'inv/gbbpp', send,
        { headers: headers })
        .subscribe(
          response => {
            this.batchno_array=[];
            var obj = JSON.parse(response["_body"]);
            for(var i=0;i<obj.product_batches.length;i++){
              this.batchno_array.push(obj.product_batches[i])
            }
          },
          error => {
            this.toastr.error(Message_data.defaultErr);
          });
    }

  }

  getPriceDetails(data){

    var send={
      product_id:data.product_id,
      pharma_id:this.pharmacyID,
      batch_no:data.batch_no
    }
    
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'inv/gpdbb', send,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if(obj != undefined) {
            for(var i=0;i<this.inventory.length;i++) {
              if(this.inventory[i].sno == data.sno) {
                this.inventory[i].cost_price=obj.cost_price;
                this.inventory[i].sales_price=obj.sales_price;
                this.inventory[i].unit_price=obj.mrp;
                this.inventory[i].expiry_date=obj.expiry_date;
              }
            }

          }
          
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }



  getreceivableslist(){
   var send_data={
    pharma_id:this.pharmacyID 
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'inv/ggrl', send_data,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);

          if(obj.gr_list != undefined){
            if(obj.gr_list.length != 0){
              obj.gr_list.forEach(receivables=>{
                var gr_date= receivables.gr_date != undefined && receivables.gr_date != '' ? Date_Formate(receivables.gr_date):'';
                var invoice_date= receivables.invoice_date != undefined && receivables.invoice_date != '' ? Date_Formate(receivables.invoice_date):'';
                var po_date= receivables.po_date != undefined && receivables.po_date != '' ? Date_Formate(receivables.po_date):'';
                var gr_time= receivables.gr_time != undefined && receivables.gr_time != '' ? Time_Formate(receivables.gr_time):'';
                // var invoice_time= receivables.invoice_time != undefined && receivables.invoice_time != '' ? Time_Formate(receivables.invoice_time):'';
                var po_time= receivables.po_time != undefined && receivables.po_time != '' ? Time_Formate(receivables.po_time):'';
                // var icon=  ?"../../../assets/images/admin_nav/pending.svg": 
                var src,type;
                if(receivables.status == "0"){
                  src="../../../assets/ui_icons/Closed_icon.svg"
                  type="Closed"
                }else{
                  src="../../../assets/images/admin_nav/pending.svg"
                  type="Pending"
                }
                this.receivableslistarray.push({
                  gr_no:receivables.gr_no,
                  gr_date:gr_date,
                  gr_time:gr_time,
                  invoice_no:receivables.invoice_no,
                  invoice_date:invoice_date,
                  supplier:receivables.supplier,
                  po_no	:receivables.po_no,
                  po_date	:po_date,
                  po_time	:	po_time,
                  status	:	receivables.status,
                  receivable_status:type,
                  src:src
                })
              })
            }
          }
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }

  getreceivables(data){

    if(data.status == 0){
      this.updateflag=false
    }else{
      this.updateflag = true
    }
    var send_data={
      pharma_id:this.pharmacyID,
      gr_no:data.gr_no
      }

      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'inv/ggrd', send_data,
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);

            // obj.gr_list.forEach(receivables=>{
            // })

            this.pagetype ='old&pending'
            var index = 0;
            this.gr_no=obj.gr_no;
            
            this.product_receive_id=obj.product_receive_id;
            this.invoiceNO = obj.invoice_no != undefined && obj.invoice_no != '' ? obj.invoice_no:'';
            this.actual_invoiceAmount	=  obj.invoice_amount != undefined && obj.invoice_amount != '' ? obj.invoice_amount:'';
            this.invDate = obj.invoice_date != undefined && obj.invoice_date != '' ? new Date(obj.invoice_date).toISOString().split('T')[0] : '';
            this.recvDate = obj.received_date != undefined && obj.received_date != '' ?  new Date(obj.received_date).toISOString().split('T')[0]:'';
            this.manufacturer = obj.name != undefined && obj.name != '' ? obj.name:'';
            this.inventroyflag=true;
            this.disamt=  obj.discount != undefined && obj.discount != '' ? obj.discount:0.00;
            this.po_number_flag=false;
            this.getsupplier()
            this.supplier_changee()
            this.poID=obj.po_no;
            this.poorder.push(obj.po_no);
            if(obj.product_list != undefined)
              if(obj.product_list.length != 0)
                this.newbutton=false;
                this.updateflag=true;
                this.receivableslistarray=[];
                this.batchno_array=[];
                this.sendurl="inv/urdp";
                for (var i = 0; i < obj.product_list.length; i++) {
                  var discount;
                  var perunit;
                  if (obj.product_list[i].discount != undefined) {
                    discount = obj.product_list[i].discount
                  } else {
                    discount = 0
                  }
                  if(obj.product_list[i].perunit != undefined){
                    perunit=obj.product_list[i].perunit
                  }else{
                    perunit=0
                  }
                  if(obj.product_list[i].checked == "true"){
                    var flag=true;
                  }else{
                    var flag=false;
                  }
                  var expiry,expirydate,quantity,free_qty;
                  if(obj.product_list[i].expiry_date != undefined && obj.product_list[i].expiry_date != ''){
                    expiry=obj.product_list[i].expiry_date.split("-")
                    expirydate=expiry[1]+"-"+expiry[0]
                  }else{
                    expirydate=''
                  }

                  if(obj.product_list[i].quantity != undefined && obj.product_list[i].quantity != '' ){
                    if(obj.product_list[i].checked == 'true'){
                      if(obj.product_list[i].perunit != undefined && obj.product_list[i].perunit != ''){
                        quantity= parseFloat(obj.product_list[i].quantity)/ parseFloat(obj.product_list[i].perunit)
                      }else{
                        quantity= parseFloat(obj.product_list[i].quantity)/1
                      }
                    }else{
                      quantity=obj.product_list[i].quantity
                    }
                  }else{
                    if(obj.product_list[i].checked == 'true'){
                      if(obj.product_list[i].perunit != undefined && obj.product_list[i].perunit != ''){
                        quantity= 0/parseFloat(obj.product_list[i].perunit)
                      }else{
                        quantity= 0
                      }
                    }else{
                      quantity=obj.product_list[i].quantity
                    }
                  }

                  if(obj.product_list[i].free_qty != undefined && obj.product_list[i].free_qty != '' ){
                    if(obj.product_list[i].checked == 'true'){
                      if(obj.product_list[i].perunit != undefined && obj.product_list[i].perunit != ''){
                        free_qty= parseFloat(obj.product_list[i].free_qty)/ parseFloat(obj.product_list[i].perunit)
                      }else{
                        free_qty= parseFloat(obj.product_list[i].free_qty)/1
                      }
                    }else{
                      free_qty=obj.product_list[i].free_qty
                    }
                  }else{
                    if(obj.product_list[i].checked == 'true'){
                      if(obj.product_list[i].perunit != undefined && obj.product_list[i].perunit != ''){
                        free_qty= 0/parseFloat(obj.product_list[i].perunit)
                      }else{
                        free_qty= 0
                      }
                    }else{
                      free_qty=obj.product_list[i].free_qty
                    }
                  }

                  this.inventory.push({
                    sno: i + 1,
                    purchase_request_id: obj.product_list[i].purchase_request_id,
                    product_id: obj.product_list[i].product_id,
                    hsn_no: obj.product_list[i].hsn_no,
                    product_name: obj.product_list[i].product_name,
                    uom_code: obj.product_list[i].uom_code,
                    uom_desc: obj.product_list[i].uom_desc,
                    quantity:quantity,
                    actual_quantity:quantity,
                    free_qty:free_qty,
                    actual_free_qty:free_qty,
                    unit_price: obj.product_list[i].sales_price,
                    sales_price: obj.product_list[i].sales_price,
                    cost_price: obj.product_list[i].cost_price,
                    pharma_id: this.pharmacyID,
                    cgst: obj.product_list[i].cgst,
                    sgst: obj.product_list[i].sgst,
                    batch_no:obj.product_list[i].batch_no,
                    prod_rec_item_id:obj.product_list[i].prod_rec_item_id,
                    received_date:this.recvDate,
                    location: this.locationName,
                    discount: discount,
                    expiry_date: expirydate,//this.currentMonthYear,
                    index: index,
                    checked: flag,
                    previousValue: 0,
                    type: "noinputbox",
                    perunit:perunit,
                    department_id:this.depart_id,
                    store_id:this.stor_id
                  });
                  obj.product_list[i].actual_quantity=quantity
                  obj.product_list[i].quantity=quantity
                  this.cost_price(obj.product_list[i])
                  index++;
                }
                this.inventoryLength = this.inventory.length;
                this.backflag=true;
                this.exlfalg=false;
                if(this.po_required == "1" && this.inventory.length == 0){
                  this.po_requiredflag= false
                }else if(this.po_required == "0" && this.inventory.length == 0){
                  this.po_requiredflag= true
                }else if(this.po_required == "0" && this.inventory.length > 0){
                  this.po_requiredflag= true
                }else{
                  this.po_requiredflag= true
                }
          },
          error => {
            this.toastr.error(Message_data.defaultErr);
          });
  }


  getsupplier(){
    const selectedSupplier = this.supplierList.find((supplier) => supplier.name === this.manufacturer);
    if (selectedSupplier) {
      this.suppID = selectedSupplier.supp_id;
      this.gst_no = selectedSupplier.gst_no;
      if(selectedSupplier.refund_amount != undefined){
        this.returnAmount = parseFloat(selectedSupplier.refund_amount);
      }else{
        this.returnAmount = 0;
      }
      this.product_returns_id=selectedSupplier.product_returns_id;
      if(selectedSupplier.received_amount != undefined && selectedSupplier.received_amount  != ""){
        this.receivedamoutsupp=parseFloat(selectedSupplier.refund_amount);
      }else{
        this.receivedamoutsupp=0;
      }
    }
  }

  newreceivables(){
    this.pagetype="new";
    this.manufacturer='';
    this.invoiceNO=0;
    this.invDate=this.currentDate;
    this.actual_invoiceAmount=0;
    this.gst_no='';
    this.recvDate=this.currentDate;
    this.poID='';
    this.suppID='';
    this.receivableslistarray=[];
    this.backflag=true;
    this.exlfalg=false;
    this.newbutton=false;
    this.inventroyflag=true;
    this.sendurl="inv/upprod/";
    this.po_number_flag=true;
  }

  backButton(){
    this.pagetype="old&pending";
    this.manufacturer='';
    this.invoiceNO=0;
    this.invDate=this.currentDate;
    this.actual_invoiceAmount=0;
    this.gst_no='';
    this.recvDate=this.currentDate;
    this.poID='';
    this.inventory=[];
    this.batchno_array=[];
    this.getreceivableslist();
    this.exlfalg=true;
    this.backflag=false;
    this.newbutton=true;
    this.updateflag=false;
    this.inventroyflag=false;
    this.po_number_flag=false
  }

  save_data() {
    if(this.actual_invoiceAmount == this.invoiceAmount){
    var save_flag = true;

    this.inventory_data=this.inventory
    pharmacy_helper.setreceviables_data(this.inventory_data)
      if (this.invoiceNO == undefined) {
        save_flag = false;
      }else if(this.invoiceNO == 0){
        save_flag = false;
      }
      if (this.inventory.length != 0) {
        var res = this.inventory.filter(o => o.expiry_date_flag == false);
        if (res.length != 0) {
          save_flag = false;
          this.toastr.error("Check given expiry date in format mm-yyyy");
        }
      }
      if (save_flag != false) {
        if (this.inventory.length != 0) {
          this.inventorydetailsexp = this.inventory
          var inventorySendArray = this.inventorydetailsexp.filter((x) => { return x.checked == true});
          for (var i = 0; i < inventorySendArray.length; i++) {
            this.expiry_flag = true
            this.batchNo_flag = true
            this.perunitflag = true;
            this.rec_qtyflag=true;
  
            if (inventorySendArray[i].expiry_date != "" && inventorySendArray[i].expiry_date != undefined) {
              this.expiry_flag = false
              //var expdate = inventorySendArray[i].expiry_date.split("-");
              //inventorySendArray[i].expiry_date = expdate[1] + "-" + expdate[0] + "-" + "28";
            }
            if(inventorySendArray[i].batch_no !="" && inventorySendArray[i].batch_no != undefined){
              this.batchNo_flag = false
            }
            if(inventorySendArray[i].quantity != 0 && inventorySendArray[i].quantity != '' && inventorySendArray[i].quantity != undefined){
              if(inventorySendArray[i].perunit != 0 && inventorySendArray[i].perunit != '' && inventorySendArray[i].perunit != undefined){
                if(this.expiry_flag != true && this.batchNo_flag !=true){
                  inventorySendArray[i].quantity = parseFloat(inventorySendArray[i].quantity) * parseFloat(inventorySendArray[i].perunit)
                  if(inventorySendArray[i].free_qty != undefined && inventorySendArray[i].free_qty != ''){
                    inventorySendArray[i].free_qty = parseFloat(inventorySendArray[i].free_qty) * parseFloat(inventorySendArray[i].perunit)
                  }else{
                    inventorySendArray[i].free_qty = 0 * parseFloat(inventorySendArray[i].perunit)
                  }
                }
                this.perunitflag = false;
                 this.rec_qtyflag=false;
              }else{
                  this.perunitflag = true;
              }
            }else{
              this.rec_qtyflag=true;
            }
            // if(this.expiry_flag == false && this.batchNo_flag == false && this.perunitflag == false && this.rec_qtyflag == false){
            //   if(inventorySendArray[i].cost_price != undefined){
            //     inventorySendArray[i].cost_price= (parseFloat(inventorySendArray[i].cost_price) * parseFloat(inventorySendArray[i].perunit)).toFixed(2)
            //   }
            // }
            // if(inventorySendArray[i].perunit != 0){
            //   inventorySendArray[i].quantity = inventorySendArray[i].quantity * 
            // }
          }

  
          if (this.expiry_flag == false && this.batchNo_flag == false && this.perunitflag == false && this.rec_qtyflag == false) {
            var podataid = "";
            var send_data;
            if(this.po_required == "1"){
              for (var i = 0; i < this.poorder.length; i++) {
                // Add comma if not the last element
                podataid += this.poorder[i];
                if (i < this.poorder.length - 1) {
                  podataid += ",";
                }
              }
              var receivedamount:any;
              receivedamount = this.receivedAmount + this.receivedamoutsupp
              receivedamount = parseFloat(receivedamount).toFixed(2)
              if(this.returnAmount != 0){
                if(this.pagetype == "new")
                  send_data = JSON.stringify({
                    invoice_no: this.invoiceNO,
                    amount: this.invoiceAmountbe,
                    country: ipaddress.country_code,
                    balance: Math.abs(this.balance),
                    disc_amount:this.disamt,
                    gst_amount:this.GSTamount,
                    refund_amount:receivedamount,
                    mfg_id: this.mgfID,
                    supp_id: this.suppID,
                    recieved_date: this.recvDate,
                    invoice_date: this.invDate,
                    product_details: inventorySendArray,
                    purchase_order_id: podataid,
                  
                    product_returns_id:this.product_returns_id,
                    po_required:this.po_required,
                    pharma_id:this.pharmacyID,
                    po_count:this.inventoryLength,
                    department_id:this.depart_id,
                    store_id:this.stor_id
                  });
                else
                send_data = JSON.stringify({
                   invoice_amount:this.invoiceAmount,
                cash_discount:this.disamt,
                  cgst:this.Cgst,
                  sgst:this.Sgst,
                  gst:this.GSTamount,
                  refund_amount:receivedamount,
                  discount:this.disamt,
                  gr_no:this.gr_no,
                  product_receivables_id:this.product_receive_id,
                  pharma_id:this.pharmacyID,
                  product_details:inventorySendArray,
                  department_id:this.depart_id,
                  store_id:this.stor_id
                });
              }
              else{
                if(this.pagetype == "new")
                  send_data = JSON.stringify({
                    invoice_no: this.invoiceNO,
                    amount: this.invoiceAmountbe,
                    country: ipaddress.country_code,
                   
                    disc_amount:this.disamt,
                    gst_amount:this.GSTamount,
                   
                    mfg_id: this.mgfID,
                    supp_id: this.suppID,
                    recieved_date: this.recvDate,
                    invoice_date: this.invDate,
                    product_details: inventorySendArray,
                    purchase_order_id: podataid,
                 
                    po_required:this.po_required,
                    pharma_id:this.pharmacyID,
                    po_count:this.inventoryLength,
                    department_id:this.depart_id,
                    store_id:this.stor_id
                  });
                else
                  send_data = JSON.stringify({
                    invoice_amount:this.invoiceAmount,
                    cash_discount:this.disamt,
                      cgst:this.Cgst,
                      sgst:this.Sgst,
                      gst:this.GSTamount,
                      discount:this.disamt,
                      gr_no:this.gr_no,
                      product_receivables_id:this.product_receive_id,
                      pharma_id:this.pharmacistID,
                      product_details:inventorySendArray,
                      department_id:this.depart_id,
                      store_id:this.stor_id
                  });
              }
  
            }else{
              // var receivedamount = this.receivedAmount + this.receivedamoutsupp
              var receivedamount:any;
              receivedamount = this.receivedAmount + this.receivedamoutsupp
              receivedamount = parseFloat(receivedamount).toFixed(2)
              for (var i = 0; i < this.poorder.length; i++) {
                // Add comma if not the last element
                podataid += this.poorder[i];
                if (i < this.poorder.length - 1) {
                  podataid += ",";
                }
              }

              if(this.po_required == '0' && podataid == ''){
                podataid=undefined
              }
              if(this.returnAmount  != 0){
                if(this.pagetype == "new")
                  send_data = JSON.stringify({
                    invoice_no: this.invoiceNO,
                    amount: this.invoiceAmountbe,
                    balance:Math.abs(this.balance),
                    refund_amount:receivedamount,
                    disc_amount:this.disamt,
                    gst_amount:this.GSTamount,
                    country: ipaddress.country_code,
                    mfg_id: this.mgfID,
                    supp_id: this.suppID,
                    recieved_date: this.recvDate,
                    invoice_date: this.invDate,
                    product_details: inventorySendArray,
                    product_returns_id:this.product_returns_id,
                    po_required:this.po_required,
                    pharma_id:this.pharmacyID,
                    po_count:this.inventoryLength,
                    department_id:this.depart_id,
                    store_id:this.stor_id

                  });
                else
                  send_data = JSON.stringify({
                      invoice_amount:this.invoiceAmount,
                      cash_discount:this.disamt,
                      cgst:this.Cgst,
                      sgst:this.Sgst,
                      gst:this.GSTamount,
                      refund_amount:receivedamount,
                      discount:this.disamt,
                      gr_no:this.gr_no,
                      product_receivables_id:this.product_receive_id,
                      pharma_id:this.pharmacistID,
                      product_details:inventorySendArray,
                      department_id:this.depart_id,
                      store_id:this.stor_id
                  });
              }else{
                if(this.pagetype == "new")
                  send_data = JSON.stringify({
                    invoice_no: this.invoiceNO,
                    amount: this.invoiceAmountbe,
                    
                    disc_amount:this.disamt,
                    gst_amount:this.GSTamount,
                    country: ipaddress.country_code,
                    mfg_id: this.mgfID,
                    supp_id: this.suppID,
                    recieved_date: this.recvDate,
                    invoice_date: this.invDate,
                    product_details: inventorySendArray,
                    purchase_order_id: podataid,
                   
                    po_required:this.po_required,
                    pharma_id:this.pharmacyID,
                    po_count:this.inventoryLength,
                    department_id:this.depart_id,
                    store_id:this.stor_id
                  });
                else
                  send_data = JSON.stringify({
                    // invoice_no: this.invoiceNO,
                    // amount: this.invoiceAmountbe,
                    // disc_amount:this.disamt,
                    // gst_amount:this.GSTamount,
                    // country: ipaddress.country_code,
                    // mfg_id: this.mgfID,
                    // supp_id: this.suppID,
                    // recieved_date: this.recvDate,
                    // invoice_date: this.invDate,
                    // product_details: inventorySendArray,
                    // purchase_order_id: podataid,
                    // po_required:this.po_required,
                    // pharma_id:this.pharmacyID
                    invoice_amount:this.invoiceAmount,
                    cash_discount:this.disamt,
                      cgst:this.cgst,
                      sgst:this.Sgst,
                      gst:this.GSTamount,
                      discount:this.disamt,
                      gr_no:this.gr_no,
                      product_receivables_id:this.product_receive_id,
                      pharma_id:this.pharmacistID,
                      product_details:inventorySendArray,
                      department_id:this.depart_id,
                      store_id:this.stor_id
                  });
              }
            }

            if(podataid == "" && this.po_required == "1"){
              this.toastr.error("Select purchase order number")
            }else{

              this.updateflag=false
              var headers = new Headers();
              headers.append('Content-Type', 'application/json');
              this.http.post(ipaddress.getIp + this.sendurl, send_data, { headers: headers }).subscribe(
                data => {
                  var obj = JSON.parse(data["_body"]);
                  if (obj != undefined && obj.key == "1") {
                    this.toastr.success(Message_data.saveSuccess);
                    // this.manufacturer;
                    // this.poID;
                    // this.invoiceNO
                    // this.invDate
                    // this.gst_no
                    // this.recvDate
                    // this.discount_option
                    // this.messageservice.sendMessage("pharmacycurentinformation");
                    Helper_Class.setReportType("stock_ledger");
                    this.messageservice.sendMessage("stock_status")
                  }
                  else if (obj != undefined && obj.key == "2") {
                    this.inventorydetailsexp = this.inventory
                    var inventorySendArray = this.inventorydetailsexp.filter((x) => { return x.checked == true });
                    // for (var i = 0; i < inventorySendArray.length; i++) {
                    //   if (inventorySendArray[i].expiry_date != undefined) {
                    //     var expdate = inventorySendArray[i].expiry_date.split("-");
                    //     inventorySendArray[i].expiry_date = expdate[1] + "-" + expdate[0] + "-" + "28";
                    //   }
                    // }
                    this.toastr.success(Message_data.invoiceExist);
    
                  } else if(this.pagetype == "new") {
                    // this.inventorydetailsexp = this.inventory
                    // var inventorySendArray = this.inventorydetailsexp.filter((x) => { return x.checked == true });
                    this.updateflag=true
                    this.inventory_data=[];
                    // this.inventory=[]
                    this.inventory_data=pharmacy_helper.getreceviables_data()


                    this.inventory.forEach(product => {
                      const perunit = parseFloat(product.perunit); 
                          const quantityDivided = product.quantity / perunit;
                          const free_qtydivided = product.free_qty/perunit;
                          // Update quantity and actual_quantity
                          product.free_qty = free_qtydivided;
                          product.quantity = quantityDivided;
                          product.actual_quantity = quantityDivided;
                          // Convert to integers if necessary
                          product.quantity = Math.floor(product.quantity);
                          product.actual_quantity = Math.floor(product.actual_quantity);
                  });
                    // for(var v= 0;v < this.inventory_data.length; v++){
                    //   this.inventory.push({
                    //   purchase_request_id: this.inventory_data[v].purchase_request_id,
                    //   product_id: this.inventory_data[v].product_id,
                    //   hsn_no: this.inventory_data[v].hsn_no,
                    //   product_name: this.inventory_data[v].product_name,
                    //   uom_code: this.inventory_data[v].uom_code,
                    //   uom_desc: this.inventory_data[v].uom_desc,
                    //   quantity: this.inventory_data[v].quantity,
                      
                    //   unit_price: this.inventory_data[v].unit_price,
                    //   sales_price: this.inventory_data[v].sales_price,
                    //   cost_price: this.inventory_data[v].cost_price,
                    //   pharma_id: this.inventory_data[v].pharma_id,
                    //   cgst: this.inventory_data[v].cgst,
                    //   sgst: this.inventory_data[v].sgst,
                    //   location: this.inventory_data[v].location,
                    //   expiry_date: this.inventory_data[v].expiry_date,
                    //   index: this.inventory_data[v].index,
                    //   checked: this.inventory_data[v].checked,
                    //   previousValue: this.inventory_data[v].previousValue,
                    //   type: this.inventory_data[v].type,
                    //   perunit:this.inventory_data[v].perunit,
                    // });
                    // }
                    // for (var i = 0; i < inventorySendArray.length; i++) {
                    //   var expdate = inventorySendArray[i].expiry_date.split("-");
                    //   inventorySendArray[i].expiry_date = expdate[1] + "-" + expdate[0] + "-" + "28";
                    // }
                    this.toastr.error(Message_data.unableUpldInventory);
                  }else{
                    this.updateflag=true
                    this.inventory.forEach(product => {
                      const perunit = parseFloat(product.perunit); // Ensure perunit is a number
                      // if (!isNaN(perunit) && perunit > 0) { // Check for valid perunit
                          const quantityDivided = product.quantity / perunit;
                          const free_qtydivided = product.free_qty/perunit;
                          // Update quantity and actual_quantity
                          product.free_qty = free_qtydivided;
                          product.quantity = quantityDivided;
                          product.actual_quantity = quantityDivided;
                          // Convert to integers if necessary
                          product.quantity = Math.floor(product.quantity);
                          product.actual_quantity = Math.floor(product.actual_quantity);
                      // } else {
                      //     console.warn(`Invalid perunit for product_id ${product.product_id}`);
                      // }
                  });
                  
                  // Log updated inventory

                  
                    this.toastr.error("Error on save receivables")
                  }
                },
                error => {

                  if(this.pagetype == "new"){
                    this.updateflag=true
                    this.inventory.forEach(product => {
                      const perunit = parseFloat(product.perunit); 
                          const quantityDivided = product.quantity / perunit;
                          const free_qtydivided = product.free_qty/perunit;
                          // Update quantity and actual_quantity
                          product.free_qty = free_qtydivided;
                          product.quantity = quantityDivided;
                          product.actual_quantity = quantityDivided;
                          // Convert to integers if necessary
                          product.quantity = Math.floor(product.quantity);
                          product.actual_quantity = Math.floor(product.actual_quantity);
                  });
                  this.toastr.error("Error on save receivables")
                  }else{
                    this.updateflag=true
                    this.inventory.forEach(product => {
                      const perunit = parseFloat(product.perunit); 
                          const quantityDivided = product.quantity / perunit;
                          const free_qtydivided = product.free_qty/perunit;
                          product.free_qty = free_qtydivided;
                          product.quantity = quantityDivided;
                          product.actual_quantity = quantityDivided;
                          product.quantity = Math.floor(product.quantity);
                          product.actual_quantity = Math.floor(product.actual_quantity);
                  });
                  this.toastr.error("Error on save receivables")
                  }
                  this.errorflag=true
                }
              )
            }

          //
          } else {
            if(this.expiry_flag == true){
              this.toastr.error("Expiry date field is empty");
            }
            if(this.batchNo_flag == true){
              this.toastr.error("Batch no should not be empty");
            }
  
            if(this.perunitflag == true){
              this.toastr.error("Per unit  should not be empty or Per unit  should not be zero");
            }
  
            if(this.rec_qtyflag == true){
              this.toastr.error("Received quanity should not be empty or Received quanity should not be zero");
            }
  
          }
        } else {
          this.toastr.error(Message_data.addInventory);
        }
  
      } else {
        this.toastr.error(Message_data.addMandatory);
      }
    }else{
      this.toastr.error("The invoice amount differ from the Total invoice amount")
  }
  }

  invoicevalidation(){
   
    if(this.suppID == ''){
      this.toastr.error("Supplier should not be empty")
    }else{
      this.invoiceNO = this.invoiceNO.replace(/\s/g, '');
    var send_data={
      invoice_no:this.invoiceNO,
      pharma_id:this.pharmacyID,
      supp_id:this.suppID
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp+"inv/vinp",send_data,{headers:headers}).subscribe(
      data=>{
        var obj= JSON.parse(data['_body']);

        if(obj.is_exists == false){
          // this.toastr.error("")
        }else{
          this.invoiceNO=0
          this.toastr.error("Invoice number has allready exists")
        }
      },error=>{
        this.toastr.error("something went wrong")
      }) 
    }
  }
  
}
