<div mat-dialog-title class="popup_border_icd">
  <h5 class="header_txt">Probing depth value</h5>
  <div mat-dialog-actions>
    <img src="../../../assets/img/close_icon.png" (click)="ClosePopup()" width="20px" height="20px" id="close_icd" />
  </div>
</div>
<div mat-dialog-content class="dental_content">
  <div class="container">
    <div class="row">
      <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
        <mat-label class="matlabel">
          <input matInput placeholder="1st Value" maxlength="1" name="username" id="loginField" type="text"
            class="ipcss " required [(ngModel)]="frt_value" #email>
        </mat-label>

      </div>
      <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
        <mat-label class="matlabel"><input matInput placeholder="2nd Value" maxlength="1" name="password"
            id="passwordField" class="ipcss " type="text" required [(ngModel)]="sec_value"></mat-label>

      </div>
      <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
        <mat-label class="matlabel"><input matInput placeholder="3rd Value" maxlength="1" name="password"
            id="passwordField" class="ipcss " type="text" required [(ngModel)]="thd_value">
        </mat-label>
      </div>
      <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4"></div>

      <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4"></div>

    </div>
  </div>
</div>
<div mat-dialog-actions align="end" style="width: auto;right: 19px;">
  <button (click)="click_value()" id="add_btn" [mat-dialog-close]="value" cdkFocusInitial mat-button>
    <img src="../../../assets/ui_icons/buttons/Done_button.svg" class="addimgbtn_icd" />
  </button>
</div>