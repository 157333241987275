<div class="row" style="width:100%;margin:0 auto;">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Create Recepies</h5>
                    </div>
                    <div class="headerButtons">
                        <a (click)="back()"><img src="../../../assets/ui_icons/buttons/Back_button.svg"
                                style="width: 85px;" /></a>
                        <a *ngIf="!updateFlag" (click)="save()"><img
                                src="../../../assets/ui_icons/buttons/save_button.svg" style="width: 85px;" /></a>
                        <a *ngIf="updateFlag" (click)="update()"><img
                                src="../../../assets/ui_icons/buttons/update_button.svg" style="width: 85px;" /></a>
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>

                <div class="cover_div" style="padding-bottom: 13px;height: 270px;">
                    <div class="header_lable">
                        Package details
                    </div>
                    <div class="content_cover">
                        <div class="row" style="margin: 15px 0 0 0;">
                            <div class="col-4">
                                <div class="row ">
                                    <div class="col-11">
                                        <div class="row therapyCover">
                                            <div class="col-12 select_bottom">
                                                <mat-label class="matlabel">Recepies name<br>
                                                    <input class="ipcss  widthbillcreate" required matInput
                                                        [(ngModel)]="recepiesName" />
                                                </mat-label>
                                            </div>
                                            <div class="col-6"
                                                style="position: relative;">
                                                <input type="file" class="ipcss" (change)="fileChange1($event)"
                                                    name="filUpload" id="file1" />
                                                <label class="closeoption" (change)="fileChange1($event)" for="file1"
                                                    style="position: relative;width: 100%;height: 100%;margin: 13px 0;"><span class="excr_confirm matlabel"><i class="fa fa-upload" aria-hidden="true"></i> Upload Icon</span> </label>
                                            </div>
                                            <div class="col-6">
                                                <img *ngIf="recepiesImage != undefined && recepiesImage != null"
                                                    [src]="recepiesImage" id="recepiesImage"
                                                    style="width: 50px;height: 50px;top: 2px;position: relative;border: 2px solid #8bd0f7;">
                                            </div>
                                            <div class="col-12" style="position: relative;">
                                                <mat-label class="matlabel">Ingredients name<br>
                                                    <input type="text" class="ipcss mainDishInput"
                                                        [(ngModel)]="ingredientsName" name="ingredientsName"
                                                        (keyup)="foodType($event)" type="text" matInput
                                                        [matAutocomplete]="auto" />
                                                    <mat-autocomplete #auto="matAutocomplete">
                                                        <mat-option id="optionfont" (click)="selctedFood(food)"
                                                            *ngFor="let food of getFoodList" [value]="food.description">
                                                            {{food.displayContent}}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                </mat-label>
                                            </div>
                                            <div class="col-6 " style="position: relative;">
                                                <mat-label class="matlabel">Weight <span
                                                        *ngIf="wightMesure != '' && wightMesure != undefined">({{wightMesure}})</span>
                                                    <br>
                                                    <input class="ipcss  widthbillcreate" required matInput (keyup)="changeQuantity(weight,'weight')" [(ngModel)]="weight" />
                                                </mat-label>
                                            </div>
                                            <div class="col-6">
                                                <mat-label class="matlabel">Quantity <span
                                                        *ngIf="quantityMeasure != '' && quantityMeasure != undefined">
                                                        ({{quantityMeasure}})</span><br>
                                                    <input class="ipcss  widthbillcreate" required matInput
                                                        [(ngModel)]="quantity" (keyup)="changeQuantity(quantity,'quantity')"/>
                                                </mat-label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-1 my-auto" style="text-align: center;">
                                        <a (click)="add()"><img src="../../../assets/img/pagination_next.png"
                                                class="addIcon" /></a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-8 ">
                                <div class="table_cover ">
                                    <div class="col-12" style="margin-top: 5px; height: 160px;">
                                        <div *ngIf="sendRecepi.length == 0" class="nodata">No recepies found</div>
                                        <div *ngIf="sendRecepi.length != 0">
                                            <div class="dig_table_overflow">
                                                <table id="card_tbl" class="table table-hover table-dynamic">
                                                    <thead>
                                                        <tr>
                                                            <th>Si.no</th>
                                                            <th>Recepie Name</th>
                                                            <th>Weight</th>
                                                            <th>Quantity</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let recepi of sendRecepi; let i = index">
                                                            <td> {{i + 1}}</td>
                                                            <td> {{recepi.description}} </td>
                                                            <td> {{recepi.weight}} ({{recepi.wightMesure}})</td>
                                                            <td>{{recepi.quantity}} ({{recepi.measure}}) </td>
                                                            <td>
                                                                <a (click)="deleteOffer(recepi.food_item_id)">
                                                                    <img src="../../../assets/ui_icons/buttons/trash_icon.svg"
                                                                        class="editDelicon" /></a>
                                                                <a (click)="editOffer(recepi)"> <img
                                                                        src="../../../assets/ui_icons/buttons/pencil_icon.svg"
                                                                        class="editDelicon" /></a>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="4" style="text-align: right;">Total Kcal : <span>{{totalKcal}}</span></td> <td ></td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="4" style="text-align: right;">Total Weight : <span>{{totalWeight}}</span>  </td> <td ></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </mdb-card-body>
        </mdb-card>
    </div>
</div>