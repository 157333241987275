<div class="row" style="margin:0 auto; width:100%">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Appointments</h5>
          </div>
          <div class="headerButtons">
            <img class="saveimgbtn_inpatinfo" *ngIf="!saveUpdateFlag" (click)="save()" float-right
              src="../../../assets/ui_icons/buttons/save_button.svg" />
            <img class="saveimgbtn_inpatinfo" *ngIf="saveUpdateFlag" (click)="update()" float-right
              src="../../../assets/ui_icons/buttons/update_button.svg" />
            <img class="saveimgbtn_inpatinfo" (click)="back()" src="../../../assets/ui_icons/buttons/Back_button.svg" />
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="">
          <mat-accordion class="mataccordion">
            <mat-expansion-panel class="expandpanel " [expanded]="true">
              <mat-expansion-panel-header class="exppanel width">
                <mat-panel-title class="title">
                  Appointment
                </mat-panel-title>
              </mat-expansion-panel-header>
              <hr class="hr">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div class="cover_div">
                    <div class="header_lable">
                      Personal
                    </div>
                    <div class="content_cover">
                      <div class="row">
                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <mat-label class="matlabel">Relationship
                            <input type="text" class="ipcss " [(ngModel)]="retrvSecOpnAppointmntdata.relationship_name"
                              [readonly]="true" #matInput />
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <mat-label class="matlabel">First name
                            <input type="text" class="ipcss " [(ngModel)]="retrvSecOpnAppointmntdata.mem_first_name"
                              [readonly]="true" matInput />
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <mat-label class="matlabel">Middle name
                            <input type="text" class="ipcss " [(ngModel)]="retrvSecOpnAppointmntdata.mem_middle_name"
                              [readonly]="true" matInput />
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <mat-label class="matlabel">Last name
                            <input type="text" class="ipcss " [(ngModel)]="retrvSecOpnAppointmntdata.mem_last_name"
                              [readonly]="true" matInput />
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <mat-label class="matlabel">Age
                            <input type="text" class="ipcss " [(ngModel)]="retrvSecOpnAppointmntdata.age"
                              [readonly]="true" />
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <mat-label class="matlabel">Blood group
                            <input type="text" class="ipcss " [(ngModel)]="retrvSecOpnAppointmntdata.blood_desc"
                              [readonly]="true" matInput />
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <mat-label class="matlabel">Gender
                            <input type="text" class="ipcss " [(ngModel)]="retrvSecOpnAppointmntdata.gender"
                              [readonly]="true" matInput />
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <mat-label class="matlabel">Contact number
                            <input type="text" class="ipcss " [(ngModel)]="retrvSecOpnAppointmntdata.mem_mobile"
                              [readonly]="true" matInput />
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <mat-label class="matlabel">Appointment type
                            <input type="text" class="ipcss " [(ngModel)]="retrvSecOpnAppointmntdata.opinion_desc"
                              [readonly]="true" matInput />
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <mat-label class="matlabel">Treatment type
                            <input type="text" class="ipcss " [(ngModel)]="retrvSecOpnAppointmntdata.medicare_name"
                              [readonly]="true" matInput />
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <mat-label class="matlabel">Specialization
                            <input type="text" class="ipcss "
                              [(ngModel)]="retrvSecOpnAppointmntdata.specialization_name" [readonly]="true" matInput />
                          </mat-label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div class="cover_div">
                    <div class="header_lable">
                      Observations
                    </div>
                    <div class="content_cover">
                      <div class="row" [hidden]="observeFlag">
                        <div class="col-12">
                          <mat-label class="matlabel">General observations<br>
                            <textarea #matInput class="ipcss obse_height " disabled [(ngModel)]="genObser"></textarea>
                          </mat-label>
                        </div>
                        <div class="col-12">
                          <mat-label class="matlabel">Medical observations<br>
                            <textarea #matInput class="ipcss obse_height " disabled [(ngModel)]="medObser"></textarea>
                          </mat-label>
                        </div>
                        <div class="col-12">
                          <mat-label class="matlabel">Diagnosis observations<br>
                            <textarea #matInput class="ipcss obse_height " disabled [(ngModel)]="diagObser"></textarea>
                          </mat-label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12" *ngIf="retrvSecOpnAppointmntdata.hptl_name != null">
                <div class="cover_div">
                  <div class="header_lable">
                    Hospital
                  </div>
                  <div class="content_cover">
                    <div class="row">
                      <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                        <mat-label class="matlabel">Doctor name
                          <input type="text" class="ipcss " [(ngModel)]="retrvSecOpnAppointmntdata.doctor_name"
                            [readonly]="true" matInput />
                        </mat-label>
                      </div>
                      <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                        <mat-label class="matlabel">Hospital name
                          <input type="text" class="ipcss " [(ngModel)]="retrvSecOpnAppointmntdata.hptl_name"
                            [readonly]="true" matInput />
                        </mat-label>
                      </div>
                      <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                        <mat-label class="matlabel">Location
                          <input type="text" class="ipcss " [(ngModel)]="retrvSecOpnAppointmntdata.hptl_location"
                            [readonly]="true" matInput />
                        </mat-label>
                      </div>
                      <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                        <mat-label class="matlabel">District/ City
                          <input type="text" class="ipcss " [(ngModel)]="retrvSecOpnAppointmntdata.hptl_city"
                            [readonly]="true" matInput />
                        </mat-label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                        <mat-label class="matlabel">Date
                          <input type="text" class="ipcss " [(ngModel)]="retrvSecOpnAppointmntdata.appointment_date"
                            [readonly]="true" matInput />
                        </mat-label>
                      </div>
                      <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                        <mat-label class="matlabel">Session
                          <input type="text" class="ipcss " [(ngModel)]="retrvSecOpnAppointmntdata.app_session"
                            [readonly]="true" matInput />
                        </mat-label>
                      </div>
                      <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                        <mat-label class="matlabel">Starts from
                          <input type="text" class="ipcss " [(ngModel)]="retrvSecOpnAppointmntdata.app_from_time"
                            [readonly]="true" matInput />
                        </mat-label>
                      </div>
                      <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                        <mat-label class="matlabel">Ends at
                          <input type="text" class="ipcss " [(ngModel)]="retrvSecOpnAppointmntdata.app_to_time"
                            [readonly]="true" matInput />
                        </mat-label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
          <mat-accordion class="mataccordion">
            <mat-expansion-panel class="expandpanel">
              <mat-expansion-panel-header class="exppanel width">
                <mat-panel-title class="title width">
                  General
                </mat-panel-title>
              </mat-expansion-panel-header>
              <hr class="hr">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div class="cover_div">
                    <div class="header_lable">
                      Personal
                    </div>
                    <div class="content_cover">
                      <div class="row">
                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <mat-label class="matlabel">Opinion type<br>
                            <mat-select disableOptionCentering class="ipcss " [(ngModel)]="opinionType"
                              disabled="{{fieldDisable}}">
                              <mat-option *ngFor="let opinion of opinionList" value={{opinion.description}}>
                                {{opinion.description}}</mat-option>
                            </mat-select>
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <mat-label class="matlabel">Marital status<br>
                            <mat-select id="genderid" disableOptionCentering class="ipcss " [(ngModel)]="maritalStatus"
                              disabled="{{fieldDisable}}">
                              <mat-option value="Single" [selected]="'Single'== maritalStatus">Single</mat-option>
                              <mat-option value="Bachelor" [selected]="'Bachelor'== maritalStatus">Bachelor</mat-option>
                              <mat-option value="Spinster" [selected]="'Spinster'== maritalStatus">Spinster</mat-option>
                              <mat-option value="Married" [selected]="'Married'== maritalStatus">Married</mat-option>
                              <mat-option value="Divorced" [selected]="'Divorced'== maritalStatus">Divorced</mat-option>
                              <mat-option value="Seperated" [selected]="'Seperated'== maritalStatus">Seperated
                              </mat-option>
                              <mat-option value="Widowed" [selected]="'Widowed'== maritalStatus">Widowed</mat-option>
                            </mat-select>
                          </mat-label>
                        </div>
                        <div class="col-12">
                          <mat-label class="matlabel">Stress factors<br>
                            <textarea #matInput class="ipcss  " disabled="{{fieldDisable}}"
                              [(ngModel)]="retrvSecOpnGeneralData.stress_factor"></textarea>
                          </mat-label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div class="cover_div" style="padding-bottom: 32px;">
                    <div class="header_lable">
                      BMI
                    </div>
                    <div class="content_cover">
                      <div class="row">
                        <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                          <mat-label class="matlabel">Height<br>
                            <input class="ipcss" matInput type="text" (change)="calculateCalories()"
                              [(ngModel)]="retrvSecOpnGeneralData.height" disabled="{{fieldDisable}}"
                              (keyup)="calculateCalories()" type="number" pattern="[0-9]*" required />
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                          <mat-label class="matlabel" disabled="{{fieldDisable}}">Measure<br>
                            <mat-select class="ipcss " required (selectionChange)="calculateCalories()"
                              [(ngModel)]="retrvSecOpnGeneralData.height_measure" disabled="{{fieldDisable}}">
                              <mat-option value="cms">cms</mat-option>
                              <mat-option value="inch">inch</mat-option>
                            </mat-select>
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                          <mat-label class="matlabel" disabled="{{fieldDisable}}">Weight<br>
                            <input class="ipcss" matInput (change)="calculateCalories()" requiredtype="number"
                              pattern="[0-9]*" required [(ngModel)]="retrvSecOpnGeneralData.weight"
                              disabled="{{fieldDisable}}" />
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                          <mat-label class="matlabel" disabled="{{fieldDisable}}">Measure<br>
                            <mat-select class="ipcss" (selectionChange)="calculateCalories()" required disabled="{{fieldDisable}}"
                              [(ngModel)]="retrvSecOpnGeneralData.weight_measure ">
                              <mat-option value="kgs">kgs</mat-option>
                              <mat-option value="lbs">lbs</mat-option>
                            </mat-select>
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <mat-label class="matlabel">BMI<br>
                            <input class="ipcss" matInput [(ngModel)]="bmi" disabled />
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <mat-label class="matlabel">Blood pressure<br>
                            <mat-select class="ipcss" (selectionChange)="calculateCalories()" required
                              [(ngModel)]="retrvSecOpnGeneralData.blood_pressure">
                              <mat-option value="Normal" [selected]="'Normal'== bloodPressure">Normal
                              </mat-option>
                              <mat-option value="Low" [selected]="'Low'== bloodPressure">Low</mat-option>
                              <mat-option value="High" [selected]="'High'== bloodPressure">High</mat-option>
                            </mat-select>
                          </mat-label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div class="cover_div">
                    <div class="header_lable">
                      Concerns
                    </div>
                    <div class="content_cover">
                      <div class="row">
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <mat-label class="matlabel">Concerns<br>
                            <textarea #matInput class="ipcss obsttxtareaheight " [(ngModel)]="mainconcern" required
                              disabled (click)=" mainconcernpopup()" readonly></textarea>
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <mat-label class="matlabel">Past medical history<br>
                            <textarea #matInput class="ipcss obsttxtareaheight " [(ngModel)]="medhist" disabled
                              (click)="selectMedicalHistory()" readonly></textarea>
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <mat-label class="matlabel">Religious<br>
                            <textarea #matInput class="ipcss obsttxtareaheight " [(ngModel)]="religiousBelief" required
                              disabled (click)="!fieldDisable && religious()" readonly></textarea>
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <mat-label class="matlabel">Any other isuue<br>
                            <textarea #matInput class="ipcss obsttxtareaheight " disabled="{{fieldDisable}}"
                              disabled="{{fieldDisable}}" [(ngModel)]="anyOtherIssue"></textarea>
                          </mat-label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div class="cover_div">
                    <div class="header_lable">
                      Diet
                    </div>
                    <div class="content_cover">
                      <div class="row">
                        <div class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12">
                          <mat-label class="matlabel">Dietary habits<br>
                            <textarea #matInput class="ipcss" disabled="{{fieldDisable}}"
                              [(ngModel)]="retrvSecOpnGeneralData.diet_habits"
                              style="height: 132px !important;"></textarea>
                          </mat-label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <!-- habitual -->
                <div class="row" style="margin-top: 20px;">
                  <div class="col-3">
                    <div class="hebitual_cover">
                      <div class="header_lable">
                        Alcohol
                      </div>
                      <div class="content_cover">
                        <mat-accordion class="mataccordion">
                          <div class="vl"></div>
                          <mat-expansion-panel [expanded]="panelOpenState1" hideToggle (opened)="panelOpenState1 = true"
                            (closed)="panelOpenState1 = false" class="exp_panel_cover">
                            <mat-expansion-panel-header class="exppanel">
                              <div class="hab_exp_image_cover">
                                <img
                                  [src]="!panelOpenState1 ? '../../../assets/ui_icons/alcohol_red.svg' : '../../../assets/ui_icons/alcohol.svg'">
                              </div>
                            </mat-expansion-panel-header> <!-- heading  -->
                            <div class="hab_content_cover">
                              <div class="row">
                                <mat-label class="col-12" class="hab_conter_text">Quantity
                                  <select class="sdropdown" [(ngModel)]="alcoholQuant">
                                    <option disabled value="Select">Select</option>
                                    <option value={{quan.description}} *ngFor="let quan of alcoholQuantityList">
                                      {{quan.description}}</option>
                                  </select>
                                </mat-label>
                                <mat-label class="col-12" class="hab_conter_text">Mixing type
                                  <select class="sdropdown" [(ngModel)]="alcoholMix">
                                    <option disabled value="Select">Select</option>
                                    <option value={{Mix.description}} *ngFor="let Mix of alcoholMixingList">
                                      {{Mix.description}}
                                    </option>
                                  </select>
                                </mat-label>
                                <mat-label class="col-12" class="hab_conter_text">Duration
                                  <select class="sdropdown" [(ngModel)]="alcholDuration">
                                    <option value="&lt;1 Year">
                                      &lt;1 Year</option>
                                    <option value="&lt;5 Years">
                                      &lt;5 Years</option>
                                    <option value=">5 Years"> >5 Years</option>
                                  </select>
                                </mat-label>
                                <mat-label class="col-12" class="hab_conter_text">Freqency/day
                                  <select class="sdropdown" [(ngModel)]="alcoholFrequency">
                                    <option value=&lt;5>
                                      &lt;5</option>
                                    <option value=">5 to 10">>5 to 10</option>
                                    <option value="10+">10+</option>
                                  </select>
                                </mat-label>
                              </div>
                            </div>
                          </mat-expansion-panel>
                        </mat-accordion>
                      </div>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="hebitual_cover">
                      <div class="header_lable">
                        Smoke
                      </div>
                      <div class="content_cover">
                        <mat-accordion class="mataccordion">
                          <div class="vl"></div>
                          <mat-expansion-panel [expanded]="panelOpenState2" hideToggle (opened)="panelOpenState2 = true"
                            (closed)="panelOpenState2 = false" class="exp_panel_cover">
                            <mat-expansion-panel-header class="exppanel">
                              <div class="hab_exp_image_cover">
                                <img
                                  [src]="!panelOpenState2 ? '../../../assets/ui_icons/smoke_red.svg' : '../../../assets/ui_icons/smoke.svg'">
                              </div>
                            </mat-expansion-panel-header> <!-- heading  -->
                            <div class="hab_content_cover">
                              <div class="row">
                                <mat-label class="col-12" class="hab_conter_text">Duration
                                  <select class="sdropdown" [(ngModel)]="smokeDuration">
                                    <option value="&lt;1 Year"> &lt;1 Year</option>
                                    <option value="&lt;5 Years "> &lt;5 Years </option>
                                    <option value="&gt;5 Years">&gt;5 Years </option>
                                  </select>
                                </mat-label>
                                <mat-label class="col-12" class="hab_conter_text">Freqency/day(times)
                                  <select class="sdropdown" [(ngModel)]="smokeFrequency">
                                    <option value="&lt;5">&lt;5</option>
                                    <option value="&gt;5 to &lt;10">&gt;5 to &lt;10 </option>
                                    <option value="10+">10+</option>
                                  </select>
                                </mat-label>
                              </div>
                            </div>
                          </mat-expansion-panel>
                        </mat-accordion>
                      </div>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="hebitual_cover">
                      <div class="header_lable">
                        Gutka
                      </div>
                      <div class="content_cover">
                        <mat-accordion class="mataccordion">
                          <div class="vl"></div>
                          <mat-expansion-panel hideToggle [expanded]="panelOpenState3" (opened)="panelOpenState3 = true"
                            (closed)="panelOpenState3 = false" class="exp_panel_cover">
                            <mat-expansion-panel-header class="exppanel">
                              <div class="hab_exp_image_cover">
                                <img
                                  [src]="!panelOpenState3 ? '../../../assets/ui_icons/gutkha_red.svg' : '../../../assets/ui_icons/gutkha.svg'">
                              </div>
                            </mat-expansion-panel-header> <!-- heading  -->
                            <div class="hab_content_cover">
                              <div class="row">
                                <mat-label class="col-12" class="hab_conter_text">Duration
                                  <select class="sdropdown" [(ngModel)]="gutkaDuration">
                                    <option value="&lt;1 Year">&lt;1 Year</option>
                                    <option value="&lt;5 Years ">&lt;5 Years </option>
                                    <option value="&gt;5 Years">&gt;5 Years</option>
                                  </select>
                                </mat-label>
                                <mat-label class="col-12" class="hab_conter_text">Freqency/day (times)
                                  <select class="sdropdown" [(ngModel)]="gutkaFrequency">
                                    <option value="&lt;5">&lt;5</option>
                                    <option value="&gt;5 to &lt;10">&gt;5 to &lt;10</option>
                                    <option value="10+">10+</option>
                                  </select>
                                </mat-label>
                              </div>
                            </div>
                          </mat-expansion-panel>
                        </mat-accordion>
                      </div>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="hebitual_cover">
                      <div class="header_lable">
                        Exercise
                      </div>
                      <div class="content_cover">
                        <mat-accordion class="mataccordion">
                          <mat-expansion-panel hideToggle [expanded]="panelOpenState4" (opened)="panelOpenState4 = true"
                            (closed)="panelOpenState4 = false" class="exp_panel_cover">
                            <mat-expansion-panel-header class="exppanel">
                              <div class="hab_exp_image_cover">
                                <img
                                  [src]="!panelOpenState4 ? '../../../assets/ui_icons/exercise_red.svg' : '../../../assets/ui_icons/exercise.svg'">
                              </div>
                            </mat-expansion-panel-header> <!-- heading  -->
                            <div class="hab_content_cover">
                              <div class="row">
                                <mat-label class="col-12" class="hab_conter_text" *ngIf="!exerciseFlag">Exercise
                                  <textarea matInput class="ipcss " [(ngModel)]="exercise"
                                    (click)="selectExercise()"></textarea>
                                </mat-label>
                                <mat-label class="col-12" class="hab_conter_text" *ngIf="!yogaFlag"><br>
                                  <select class="sdropdown" disableOptionCentering multiple>
                                    <option disabled value="Select">Select</option>
                                    <option #matoption color="primary"
                                      (click)="changeYoga(yoga.yoga_asana_id,matoption)"
                                      *ngFor="let yoga of getYogaList" value={{yoga.description}}>
                                      {{yoga.description}}
                                    </option>
                                  </select>
                                </mat-label>
                                <mat-label class="col-12" class="hab_conter_text">{{exerDurationLabel}}<br>
                                  <select class="sdropdown" [(ngModel)]="exerDuration"
                                    [disabled]="diagnosisDisabledFlag" disableOptionCentering>
                                    <option disabled value="Select">Select</option>
                                    <option value="10mins">10mins</option>
                                    <option value="15mins">15mins</option>
                                    <option value="30mins">30mins</option>
                                    <option value="45mins">45mins</option>
                                  </select>
                                </mat-label>
                                <mat-label class="col-12" class="hab_conter_text">{{exerFreqLabel}}<br>
                                  <select class="sdropdown" [(ngModel)]="exerFreq" [disabled]="diagnosisDisabledFlag"
                                    disableOptionCentering>
                                    <option disabled value="Select">Select</option>
                                    <option value="Daily">Daily</option>
                                    <option value="Thrice a week">Thrice a week</option>
                                    <option value="4 days a week">4 days a week</option>
                                    <option value="5 days a week">5 days a week</option>
                                  </select>
                                </mat-label>
                                <mat-label class="col-12" class="hab_conter_text">Exercise routine<br>
                                  <select class="sdropdown" [(ngModel)]="exerRoutine" disableOptionCentering>
                                    <option disabled value="Select">Select</option>
                                    <option *ngFor="let routine of exerciseRoutineList" value="{{routine.description}}">
                                      {{routine.description}}</option>
                                  </select>
                                </mat-label>
                              </div>
                            </div>
                          </mat-expansion-panel>
                        </mat-accordion>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion><br>
          <!-- hm -->
          <mat-accordion class="mataccordion">
            <mat-expansion-panel class="expandpanel ">
              <mat-expansion-panel-header class="exppanel width">
                <mat-panel-title class="title width">
                  Health and medication
                </mat-panel-title>
              </mat-expansion-panel-header>
              <hr class="hr">
              <div style="margin-top: 10px;">
                <div class="row" style="padding-left: 13px;padding-bottom: 7px;">
                  <div class="col-8 col-sm-7 col-md-8 col-xl-6 col-lg-8 my-auto">
                    <mat-label class="matlabel">Have you had recent unexplained weight gain?</mat-label>
                  </div>
                  <div class="col-3 col-sm-4 col-md-2 col-xl-1 col-lg-2 my-auto">
                    <mat-label class="matlabel">
                      <img *ngIf="!wgtGainFlag" src="../../../assets/ui_icons/cross_mark.svg" width="32px"
                        height="auto" />
                      <img *ngIf="wgtGainFlag" src="../../../assets/ui_icons/tick_mark.svg" width="32px"
                        height="auto" />
                    </mat-label>
                  </div>
                </div>
                <div class="row" style="padding-left: 13px;padding-bottom: 7px;">
                  <div class="col-8 col-sm-7 col-md-8 col-xl-6 col-lg-8 my-auto">
                    <mat-label class="matlabel">Have you had recent unexplained weight loss?</mat-label>
                  </div>
                  <div class="col-3 col-sm-4 col-md-2 col-xl-1 col-lg-2 my-auto">
                    <mat-label class="matlabel">
                      <img *ngIf="!wgtGainFlag" src="../../../assets/ui_icons/cross_mark.svg" width="32px"
                        height="auto" />
                      <img *ngIf="wgtGainFlag" src="../../../assets/ui_icons/tick_mark.svg" width="32px"
                        height="auto" />
                    </mat-label>
                  </div>
                </div>
                <div class="row" style="padding-left: 13px;padding-bottom: 7px;">
                  <div class="col-8 col-sm-7 col-md-8 col-xl-6 col-lg-8 my-auto">
                    <mat-label class="matlabel">Are you frequently tired?</mat-label>
                  </div>
                  <div class="col-3 col-sm-4 col-md-2 col-xl-1 col-lg-2 my-auto">
                    <mat-label class="matlabel">
                      <img *ngIf="!freqFlag" src="../../../assets/ui_icons/cross_mark.svg" width="32px" height="auto" />
                      <img *ngIf="freqFlag" src="../../../assets/ui_icons/tick_mark.svg" width="32px" height="auto" />
                    </mat-label>
                  </div>
                </div>
                <div class="row" style="padding-left: 13px;padding-bottom: 7px;">
                  <div class="col-8 col-sm-7 col-md-8 col-xl-6 col-lg-8 my-auto">
                    <mat-label class="matlabel">Have you had recent fever, chills?</mat-label>
                  </div>
                  <div class="col-3 col-sm-4 col-md-2 col-xl-1 col-lg-2 my-auto">
                    <mat-label class="matlabel">
                      <img *ngIf="!recentFlag" src="../../../assets/ui_icons/cross_mark.svg" width="32px"
                        height="auto" />
                      <img *ngIf="recentFlag" src="../../../assets/ui_icons/tick_mark.svg" width="32px" height="auto" />
                    </mat-label>
                  </div>
                </div>
                <div class="row" style="padding-left: 13px;padding-bottom: 7px;">
                  <div class="col-8 col-sm-7 col-md-8 col-xl-6 col-lg-8 my-auto">
                    <mat-label class="matlabel">Are you taking any prescribed medications?</mat-label>
                  </div>
                  <div class="col-3 col-sm-4 col-md-2 col-xl-1 col-lg-2 my-auto">
                    <mat-label class="matlabel">
                      <img *ngIf="!anyPresMedFlag" src="../../../assets/ui_icons/cross_mark.svg" width="32px"
                        height="auto" />
                      <img *ngIf="anyPresMedFlag" src="../../../assets/ui_icons/tick_mark.svg" width="32px"
                        height="auto" />
                    </mat-label>
                  </div>
                </div>
                <div class="row" style="padding-left: 13px;padding-bottom: 7px;">
                  <div class="col-8 col-sm-7 col-md-8 col-xl-6 col-lg-8 my-auto">
                    <mat-label class="matlabel">Are you taking any OTC / non-prescribed medications?</mat-label>
                  </div>
                  <div class="col-3 col-sm-4 col-md-2 col-xl-1 col-lg-2 my-auto">
                    <mat-label class="matlabel">
                      <img *ngIf="!otcMedFlag" src="../../../assets/ui_icons/cross_mark.svg" width="32px"
                        height="auto" />
                      <img *ngIf="otcMedFlag" src="../../../assets/ui_icons/tick_mark.svg" width="32px" height="auto" />
                    </mat-label>
                  </div>
                  <div *ngIf="otcMedFlag" class="col-3 col-sm-4 col-md-2 col-xl-3 col-lg-3">
                    <img src="../../../assets/img/OTC.png" (click)="navigateOtcHerbal('OTC')" width="25px"
                      height="auto" />
                  </div>
                </div>
                <div class="row" style="padding-left: 13px;padding-bottom: 7px;">
                  <div class="col-8 col-sm-7 col-md-8 col-xl-6 col-lg-8">
                    <mat-label class="matlabel">Are you taking any vitamins or herbal supplements?</mat-label>
                  </div>
                  <div class="col-3 col-sm-4 col-md-2 col-xl-1 col-lg-2">
                    <mat-label class="matlabel">
                      <img *ngIf="!vitaminsFlag" src="../../../assets/ui_icons/cross_mark.svg" width="32px"
                        height="auto" />
                      <img *ngIf="vitaminsFlag" src="../../../assets/ui_icons/tick_mark.svg" width="32px"
                        height="auto" />
                    </mat-label>
                  </div>
                  <div *ngIf="vitaminsFlag" class="col-3 col-sm-4 col-md-2 col-xl-3 col-lg-3">
                    <img src="../../../assets/img/VitaminorHerbal_icon.png" (click)="navigateOtcHerbal('Herbal')"
                      width="25px" height="auto" />
                  </div>
                </div>
                <div class="row" style="padding-left: 13px;padding-bottom: 7px;">
                  <div class="col-8 col-sm-7 col-md-8 col-xl-6 col-lg-8">
                    <mat-label class="matlabel">Have you ever had reaction to medication or supplements?</mat-label>
                  </div>
                  <div class="col-3 col-sm-4 col-md-2 col-xl-1 col-lg-2">
                    <img *ngIf="!reactFlag" src="../../../assets/ui_icons/cross_mark.svg" width="32px" height="auto" />
                    <img *ngIf="reactFlag" src="../../../assets/ui_icons/tick_mark.svg" width="32px" height="auto" />
                  </div>
                  <div *ngIf="reactFlag" class="col-3 col-sm-4 col-md-2 col-xl-3 col-lg-3">
                    <img src="../../../assets/img/Reaction_Icon.png" (click)="navigateReactAllergy('reaction')"
                      width="20px" height="auto" />
                  </div>
                </div>
                <div class="row" style="padding-left: 13px;padding-bottom: 7px;">
                  <div class="col-8 col-sm-7 col-md-8 col-xl-6 col-lg-8">
                    <mat-label class="matlabel">Are you allergic to any substance?</mat-label>
                  </div>
                  <div class="col-3 col-sm-4 col-md-2 col-xl-1 col-lg-2">
                    <mat-label class="matlabel">
                      <img *ngIf="!allergyFlag" src="../../../assets/ui_icons/cross_mark.svg" width="32px"
                        height="auto" />
                      <img *ngIf="allergyFlag" src="../../../assets/ui_icons/tick_mark.svg" width="32px"
                        height="auto" />
                    </mat-label>
                  </div>
                  <div *ngIf="allergyFlag" class="col-3 col-sm-4 col-md-2 col-xl-3 col-lg-3">
                    <img src="../../../assets/img/Allergy_Icon.png" (click)="navigateReactAllergy('allergy')"
                      width="20px" height="auto" />
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
          <mat-accordion class="mataccordion">
            <mat-expansion-panel class="expandpanel">
              <mat-expansion-panel-header class="exppanel width">
                <mat-panel-title class="title width">
                  Family
                </mat-panel-title>
              </mat-expansion-panel-header>
              <hr class="hr">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <div class="cover_div">
                    <div class="content_cover">
                      <div class="row">
                        <div class="col-7 margin-bottomvalue">
                          <mat-label class="matlabel ">Diabetics</mat-label>
                        </div>
                        <div class="col-2 margin-bottomvalue">
                          <img *ngIf="!familyHistData.diabetics" src="../../../assets/ui_icons/cross_mark.svg"
                            width="32px" height="auto" />
                          <img *ngIf="familyHistData.diabetics" src="../../../assets/ui_icons/tick_mark.svg"
                            width="32px" height="auto" />
                        </div>
                        <div class="col-7">
                          <mat-label class="matlabel ">Heart attack</mat-label>
                        </div>
                        <div class="col-2 margin-bottomvalue">
                          <img *ngIf="!familyHistData.heart_attack" src="../../../assets/ui_icons/cross_mark.svg"
                            width="32px" height="auto" />
                          <img *ngIf="familyHistData.heart_attack" src="../../../assets/ui_icons/tick_mark.svg"
                            width="32px" height="auto" />
                        </div>
                        <div class="col-7 margin-bottomvalue">
                          <mat-label class="matlabel ">Stroke</mat-label>
                        </div>
                        <div class="col-2 margin-bottomvalue">
                          <img *ngIf="!familyHistData.stroke" src="../../../assets/ui_icons/cross_mark.svg" width="32px"
                            height="auto" />
                          <img *ngIf="familyHistData.stroke" src="../../../assets/ui_icons/tick_mark.svg" width="32px"
                            height="auto" />
                        </div>
                        <div class="col-7 margin-bottomvalue">
                          <mat-label class="matlabel ">Arterial</mat-label>
                        </div>
                        <div class="col-2">
                          <img *ngIf="!familyHistData.arterial" src="../../../assets/ui_icons/cross_mark.svg"
                            width="32px" height="auto" />
                          <img *ngIf="familyHistData.arterial" src="../../../assets/ui_icons/tick_mark.svg" width="32px"
                            height="auto" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" style="position: relative;left: -18px;">
                  <div class="cover_div">
                    <div class="content_cover">
                      <div class="row">
                        <div class="col-7 margin-bottomvalue">
                          <mat-label class="matlabel ">Endocrine</mat-label>
                        </div>
                        <div class="col-2  margin-bottomvalue">
                          <img *ngIf="!familyHistData.endocrine" src="../../../assets/ui_icons/cross_mark.svg"
                            width="32px" height="auto" />
                          <img *ngIf="familyHistData.endocrine" src="../../../assets/ui_icons/tick_mark.svg"
                            width="32px" height="auto" />
                        </div>
                        <div class="col-7">
                          <mat-label class="matlabel ">Autoimmune</mat-label>
                        </div>
                        <div class="col-2 margin-bottomvalue">
                          <img *ngIf="!familyHistData.autoimmune" src="../../../assets/ui_icons/cross_mark.svg"
                            width="32px" height="auto" />
                          <img *ngIf="familyHistData.autoimmune" src="../../../assets/ui_icons/tick_mark.svg"
                            width="32px" height="auto" />
                        </div>
                        <div class="col-7 margin-bottomvalue">
                          <mat-label class="matlabel ">Hypertension</mat-label>
                        </div>
                        <div class="col-2 margin-bottomvalue">
                          <img *ngIf="!familyHistData.hypertension" src="../../../assets/ui_icons/cross_mark.svg"
                            width="32px" height="auto" />
                          <img *ngIf="familyHistData.hypertension" src="../../../assets/ui_icons/tick_mark.svg"
                            width="32px" height="auto" />
                        </div>
                        <div class="col-7 margin-bottomvalue">
                          <mat-label class="matlabel ">Hemophilia</mat-label>
                        </div>
                        <div class="col-2 margin-bottomvalue">
                          <img *ngIf="!familyHistData.hemophilia" src="../../../assets/ui_icons/cross_mark.svg"
                            width="32px" height="auto" />
                          <img *ngIf="familyHistData.hemophilia" src="../../../assets/ui_icons/tick_mark.svg"
                            width="32px" height="auto" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" style="position: relative;left: -36px;">
                  <div class="cover_div">
                    <div class="content_cover">
                      <div class="row">
                        <div class="col-7 margin-bottomvalue">
                          <mat-label class="matlabel ">Tuberculosis</mat-label>
                        </div>
                        <div class="col-2 margin-bottomvalue">
                          <img *ngIf="!familyHistData.tuberculosis" src="../../../assets/ui_icons/cross_mark.svg"
                            width="32px" height="auto" />
                          <img *ngIf="familyHistData.tuberculosis" src="../../../assets/ui_icons/tick_mark.svg"
                            width="32px" height="auto" />
                        </div>
                        <div class="col-7 margin-bottomvalue">
                          <mat-label class="matlabel ">HIV</mat-label>
                        </div>
                        <div class="col-2 margin-bottomvalue">
                          <img *ngIf="!familyHistData.hiv" src="../../../assets/ui_icons/cross_mark.svg" width="32px"
                            height="auto" />
                          <img *ngIf="familyHistData.hiv" src="../../../assets/ui_icons/tick_mark.svg" width="32px"
                            height="auto" />
                        </div>
                        <div class="col-7 margin-bottomvalue">
                          <mat-label class="matlabel">Hepatitis</mat-label>
                        </div>
                        <div class="col-2  margin-bottomvalue">
                          <img *ngIf="!familyHistData.hepatitis" src="../../../assets/ui_icons/cross_mark.svg"
                            width="32px" height="auto" />
                          <img *ngIf="familyHistData.hepatitis" src="../../../assets/ui_icons/tick_mark.svg"
                            width="32px" height="auto" />
                        </div>
                        <div class="col-7 margin-bottomvalue">
                          <mat-label class="matlabel">Glaucoma</mat-label>
                        </div>
                        <div class="col-2 margin-bottomvalue">
                          <img *ngIf="!familyHistData.glaucoma" src="../../../assets/ui_icons/cross_mark.svg"
                            width="32px" height="auto" />
                          <img *ngIf="familyHistData.glaucoma" src="../../../assets/ui_icons/tick_mark.svg" width="32px"
                            height="auto" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-lg-12 col-md-12 col-xl-12" style="margin-top: 5px;">
                  <mat-label class="matlabel">Others<br>
                    <textarea class="ipcss" disabled="{{fieldDisable}}" [(ngModel)]="familyHistData.other"
                      style="width: 96.5%;"></textarea>
                  </mat-label>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
          <!-- document -->
          <mat-accordion class="mataccordion">
            <mat-expansion-panel class="expandpanel">
              <mat-expansion-panel-header class="exppanel width">
                <mat-panel-title class="title width">
                  Document
                </mat-panel-title>
              </mat-expansion-panel-header>
              <hr class="hr">
              <div>
                <p class="tervys_heading"><b>Upload documents and reports</b></p>
                <p class="tervys_heading" [hidden]="retrvImageList.length==0"><b>Documents</b></p>
                <div *ngIf="retrvImageList.length != 0">
                  <table id="patienttable" class="table table-hover table-dynamic">
                    <thead>
                      <tr>
                        <th>Preview document</th>
                        <th>View document</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let viewdoc of  retrvImageList">
                        <td>
                          <img id="doc_image" src="{{viewdoc.show_image}}" class="document_img" width="45px"
                            height="45px" />
                        </td>
                        <td>
                          <a (click)="viewReportImagepdfDetail(viewdoc.document_image)">View</a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <p class="tervys_heading" *ngIf="retrvBloodReportList.length != 0 || retrvUrineReportList.length != 0 ||
                retrvFeacesReportList.length != 0 || retrvXrayReportList.length != 0 ||
                retrvScanReportList.length != 0 || retrvUltrasoundReportList.length != 0">
                  <b><u>Report Upload</u></b>
                </p>
                <div *ngIf="retrvBloodReportList.length != 0 || retrvUrineReportList.length != 0 ||
                retrvFeacesReportList.length != 0 || retrvXrayReportList.length != 0 ||
                retrvScanReportList.length != 0 || retrvUltrasoundReportList.length != 0" style="margin-bottom: 5px;">
                  <table id="patienttable" class="table table-hover table-dynamic">
                    <thead>
                      <tr>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let blood_report of retrvBloodReportList"
                        (click)="viewReport(blood_report.test_id,'Blood',blood_report.app_id)">
                        <td>
                          {{blood_report.test_name+" "+"test"}}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div *ngIf="retrvUrineReportList.length != 0" style="margin-bottom: 5px;">
                  <mat-accordion class="mataccordion">
                    <mat-expansion-panel class="expandpanel" [expanded]="true">
                      <mat-expansion-panel-header class="exppanel ">
                        <mat-panel-title class="title width">
                          Urine
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="block1_grid">
                        <mat-card *ngFor="let urine_report of retrvUrineReportList"
                          (click)="viewReport(urine_report.test_id,'urine',urine_report.app_id)" class="List_border">
                          <mat-card-header></mat-card-header>
                          <mat-card-content>
                            <mat-label>{{urine_report.test_name+" "+"test"}}</mat-label><br>
                          </mat-card-content>
                        </mat-card><br>
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>
                </div>
                <div [hidden]="retrvFeacesReportList.length == 0" style="margin-bottom: 5px;">
                  <mat-accordion class="mataccordion">
                    <mat-expansion-panel class="expandpanel" [expanded]="true">
                      <mat-expansion-panel-header class="exppanel ">
                        <mat-panel-title class="title width">
                          Faeces
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="block1_grid">
                        <mat-card *ngFor="let feaces_report of feacesReportList;"
                          (click)="viewReport(feaces_report.test_id,'faeces',feaces_report.app_id)" class="List_border">
                          <mat-card-header></mat-card-header>
                          <mat-card-content>
                            {{feaces_report.test_name+" "+"test"}}
                          </mat-card-content>
                        </mat-card><br>
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>
                </div>
                <div [hidden]="retrvXrayReportList.length == 0" style="margin-bottom: 5px;">
                  <mat-accordion class="mataccordion">
                    <mat-expansion-panel class="expandpanel" [expanded]="true">
                      <mat-expansion-panel-header class="exppanel ">
                        <mat-panel-title class="title width">
                          X-ray
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="block1_grid">
                        <mat-card *ngFor="let xray_report of retrvXrayReportList"
                          (click)="viewReport(xray_report.test_id,'X-ray',xray_report.app_id)" class="List_border">
                          <mat-card-header></mat-card-header>
                          <mat-card-content>
                            {{xray_report.test_name+" "+"test"}}
                          </mat-card-content>
                        </mat-card><br>
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>
                </div>
                <div [hidden]="retrvScanReportList.length == 0" style="margin-bottom: 5px;">
                  <mat-accordion class="mataccordion">
                    <mat-expansion-panel class="expandpanel" [expanded]="true">
                      <mat-expansion-panel-header class="exppanel ">
                        <mat-panel-title class="title width">
                          Scans
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="block1_grid">
                        <mat-card *ngFor="let scan_report of retrvScanReportList"
                          (click)="viewReport(scan_report.test_id,'scans',scan_report.app_id)" class="List_border">
                          <mat-card-header></mat-card-header>
                          <mat-card-content>
                            <mat-label class="matlabel"> {{scan_report.test_name+" "+"test"}}</mat-label>
                          </mat-card-content>
                        </mat-card><br>
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>
                </div>
                <div [hidden]="retrvUltrasoundReportList.length == 0" style="margin-bottom: 5px;">
                  <mat-accordion class="mataccordion">
                    <mat-expansion-panel class="expandpanel" [expanded]="true">
                      <mat-expansion-panel-header class="exppanel ">
                        <mat-panel-title class="title width">
                          Ultra-sound
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="block1_grid">
                        <mat-card *ngFor="let Ultra_report of retrvUltrasoundReportList"
                          (click)="viewReport(Ultra_report.test_id,'Ultra-Sound',Ultra_report.app_id)"
                          class="List_border">
                          <mat-card-header></mat-card-header>
                          <mat-card-content>
                            <p text-wrap>{{Ultra_report.test_name+" "+"test"}}</p>
                          </mat-card-content>
                        </mat-card><br>
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>
                </div>
                <p class="tervys_heading"><b>Medical prescription</b></p>
                <div class="nodata" [hidden]="retrvPrescriptionList.length != 0">No prescription(s) found</div>
                <table id="patienttable" [hidden]="retrvPrescriptionList.length == 0"
                  class="table table-hover table-dynamic">
                  <thead>
                    <tr>
                      <th></th>
                      <th> </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let list of retrvPrescriptionList" (click)='viewMedPres(list.test_id)'>
                      <td>{{list.test_name}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <ng-container *ngIf="extraadd_document_div">
                <div class="col-8" *ngIf="extraadd_document_div">
                  <p class="tervys_heading">Continue upload documents,reports</p>
                  <button id="matbtn" *ngIf="ynopt13" ngDefaultControl [(ngModel)]="newUpload"
                    [style.color]="getColor('Yes')" [ngStyle]="{'border':'1px solid green'}" mat-raised-button
                    (click)="uploadNewReports(13);addDocumentFlag != addDocumentFlag">Yes</button>
                  <button id="matbtn" *ngIf="!ynopt13" ngDefaultControl [style.color]="getColor('No')"
                    [ngStyle]="{'border':'1px solid red'}" [(ngModel)]="newUpload" mat-raised-button
                    (click)="uploadNewReports(13);addDocumentFlag != addDocumentFlag">No</button>
                </div>
              </ng-container>
              <!-- Add Documents -->
              <ng-container *ngIf="addDocumentFlag">
                <p class="casesheet_para">Document upload</p>
                <div class="row">
                  <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-2">
                    <input type="file" (change)="fileChange($event)" name="filUpload" id="file" class="uploadDoc" />
                  </div>
                  <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-2">
                    <img class="saveimgbtn_inpatinfo" src="../../../assets/ui_icons/buttons/add_button.svg"
                      (click)="addDocumentImage()">
                  </div>
                </div>
                <br />
                <div *ngIf="Image_table_list.length != 0">
                  <table id="patienttable" class="table table-hover table-dynamic">
                    <thead>
                      <tr>
                        <th>File name</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let image of Image_table_list; let i=index">
                        <td data-th="File name">{{image.image_name}}</td>
                        <td data-th="Action"><a (click)="deleteDocumentImage(i)"><img
                              src="../../../assets/ui_icons/buttons/trash_icon.svg" width="15px" /></a></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div *ngIf="documentList.length != 0">
                  <p class="tervys_heading">My documents</p>
                </div>
                <div *ngIf="documentList.length != 0" class="block1">
                  <table id="patienttable" class="table table-hover table-dynamic">
                    <tbody>
                      <tr *ngFor="let doc of documentList">
                        <td data-th="File name" style="text-align: left;">{{doc.data}}</td>
                        <td data-th="Action" style="text-align: left;">
                          <p class="textcolor">
                            <mat-checkbox item-right (change)="changeDocument(doc.id,doc.name)"></mat-checkbox>
                            <span (click)="viewDocument(doc.id,doc.name)">
                              <u style="padding-left: 5px;">View document</u>
                            </span>
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <p *ngIf="bloodReportList.length != 0 || urineReportList.length != 0 ||
                feacesReportList.length != 0 || xrayReportList.length != 0 ||
                scanReportList.length != 0 || ultrasoundReportList.length != 0" class="tervys_heading">
                  <b>Report upload</b>
                </p>
                <div>
                  <div *ngIf="bloodReportList.length != 0" style="margin-bottom: 5px;">
                    <mat-accordion class="mataccordion">
                      <mat-expansion-panel class="expandpanel" [expanded]="true">
                        <mat-expansion-panel-header class="exppanel ">
                          <mat-panel-title class="title width">
                            Blood &nbsp;&nbsp;
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="block1_grid">
                          <mat-card *ngFor="let blood_report of bloodReportList; let i=index;" class="List_border">
                            <mat-card-header></mat-card-header>
                            <mat-card-content>
                              <!-- <mat-label class="matlabel">{{list.test_name+" "+"test"}}</mat-label><br> -->
                              <p text-wrap><span>{{blood_report.Date+" : Report taken for "+blood_report.Client_Name +"
                                  in "+
                                  blood_report.Centre_Name +" referred by Dr."+ blood_report.Doctor_Name}}</span>
                                <span class="check_box_class">
                                  <mat-checkbox style="padding-left: 5px;" item-right
                                    (change)="changeBlood($event, blood_report.Diag_Test_Id)">
                                  </mat-checkbox>
                                </span>
                              </p>
                            </mat-card-content>
                          </mat-card><br>
                        </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                  </div>
                  <div [hidden]="urineReportList.length == 0" style="margin-bottom: 5px;">
                    <mat-accordion class="mataccordion">
                      <mat-expansion-panel class="expandpanel" [expanded]="true">
                        <mat-expansion-panel-header class="exppanel ">
                          <mat-panel-title class="title width">
                            Urine
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="block1_grid">
                          <mat-card *ngFor="let urine_report of urineReportList; let i=index;" class="List_border">
                            <mat-card-header></mat-card-header>
                            <mat-card-content>
                              <p text-wrap><span>{{urine_report.Date+" : Report taken for "+urine_report.Client_Name +"
                                  in "+
                                  urine_report.Centre_Name +" referred by Dr."+ urine_report.Doctor_Name}}</span>
                                <span class="check_box_class">
                                  <mat-checkbox item-right (change)="changeUrine($event, urine_report.Diag_Test_Id)">
                                  </mat-checkbox>
                                </span>
                              </p>
                            </mat-card-content>
                          </mat-card><br>
                        </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                  </div>
                  <div [hidden]="feacesReportList.length == 0" style="margin-bottom: 5px;">
                    <mat-accordion class="mataccordion">
                      <mat-expansion-panel class="expandpanel" [expanded]="true">
                        <mat-expansion-panel-header class="exppanel ">
                          <mat-panel-title class="title width">
                            Faeces
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="block1_grid">
                          <mat-card *ngFor="let feaces_report of feacesReportList; let i=index;" class="List_border">
                            <mat-card-header></mat-card-header>
                            <mat-card-content>
                              <p text-wrap><span>{{feaces_report.Date+" : Report taken for "+feaces_report.Client_Name
                                  +" in "+
                                  feaces_report.Centre_Name +" referred by Dr."+ feaces_report.Doctor_Name}}</span>
                                <span class="check_box_class">
                                  <mat-checkbox item-right (change)="changeFeaces($event, feaces_report.Diag_Test_Id)">
                                  </mat-checkbox>
                                </span>
                              </p>
                            </mat-card-content>
                          </mat-card><br>
                        </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                  </div>
                  <div [hidden]="xrayReportList.length == 0" style="margin-bottom: 5px;">
                    <mat-accordion class="mataccordion">
                      <mat-expansion-panel class="expandpanel" [expanded]="true">
                        <mat-expansion-panel-header class="exppanel ">
                          <mat-panel-title class="title width">
                            X-ray
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="block1_grid">
                          <mat-card *ngFor="let xray_report of xrayReportList; let i=index;" class="List_border">
                            <mat-card-header></mat-card-header>
                            <mat-card-content>
                              <!-- <a (click)="Report_list_Select(xray_report.check, i)"> -->
                              <p text-wrap><span>{{xray_report.Date+" : Report taken for "+xray_report.Client_Name +"
                                  in "+
                                  xray_report.Centre_Name +" referred by Dr."+ xray_report.Doctor_Name}}</span>
                                <span class="check_box_class">
                                  <mat-checkbox item-right (change)="changeXray($event, xray_report.Diag_Test_Id)">
                                  </mat-checkbox>
                                </span>
                              </p>
                            </mat-card-content>
                          </mat-card><br>
                        </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                  </div>
                  <div [hidden]="scanReportList.length == 0" style="margin-bottom: 5px;">
                    <mat-accordion class="mataccordion">
                      <mat-expansion-panel class="expandpanel" [expanded]="true">
                        <mat-expansion-panel-header class="exppanel ">
                          <mat-panel-title class="title width">
                            Scans
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="block1_grid">
                          <mat-card *ngFor="let scan_report of scanReportList; let i=index;" class="List_border">
                            <mat-card-header></mat-card-header>
                            <mat-card-content>
                              <p text-wrap><span>{{scan_report.Date+" : Report taken for "+scan_report.Client_Name +"
                                  in "+
                                  scan_report.Centre_Name +" referred by Dr."+ scan_report.Doctor_Name}}</span>
                                <span class="check_box_class">
                                  <mat-checkbox item-right (change)="changeScan($event, scan_report.Diag_Test_Id)">
                                  </mat-checkbox>
                                </span>
                              </p>
                            </mat-card-content>
                          </mat-card><br>
                        </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                  </div>
                  <div [hidden]="ultrasoundReportList.length == 0" style="margin-bottom: 5px;">
                    <mat-accordion class="mataccordion">
                      <mat-expansion-panel class="expandpanel" [expanded]="true">
                        <mat-expansion-panel-header class="exppanel ">
                          <mat-panel-title class="title width">
                            Ultra-sound
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="block1_grid">
                          <mat-card *ngFor="let Ultra_report of ultrasoundReportList; let i=index;" class="List_border">
                            <mat-card-header></mat-card-header>
                            <mat-card-content>
                              <p text-wrap><span>{{Ultra_report.Date+" : Report taken for "+Ultra_report.Client_Name +"
                                  in "+
                                  Ultra_report.Centre_Name +" referred by Dr."+ Ultra_report.Doctor_Name}}</span>
                                <span class="check_box_class">
                                  <mat-checkbox item-right
                                    (change)="changeUltraSound($event, Ultra_report.Diag_Test_Id)"></mat-checkbox>
                                </span>
                              </p>
                            </mat-card-content>
                          </mat-card><br>
                        </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <p class="tervys_heading" *ngIf="retrvPrescriptionList.length != 0"><b>Medical Prescription</b></p>
                    <table id="patienttable" *ngIf="retrvPrescriptionList.length != 0"
                      class="table table-hover table-dynamic">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Delivery type </th>
                          <th>Order date </th>
                          <th>Order status </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let list of retrvPrescriptionList" (click)="viewMedPres(list.test_id)">
                          <td><b>{{list.clientName}}</b></td>
                          <td> {{list.deliverType}}</td>
                          <td>{{list.orderDate}} - {{list.orderTime}}</td>
                          <td> {{list.orderStatus}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <p *ngIf="prescriptionList.length!=0" class="tervys_heading">Medical prescription</p>
                <div *ngIf="prescriptionList.length!=0">
                  <table id="patienttable" class="table table-hover table-dynamic">
                    <thead>
                      <tr>
                        <th></th>
                        <th> </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let pres of prescriptionList">
                        <td style="text-align: left;">
                          <mat-label>{{pres.data}}</mat-label>
                        </td>
                        <td>
                          <mat-checkbox class="chk widthcheck" (change)="changePrescriptions(pres.id,$event.checked)">
                          </mat-checkbox>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </ng-container>
              <ng-container >
                <div class="block1">
                  <mat-checkbox class="center" [(ngModel)]="agreeBox"></mat-checkbox>
                  <mat-label class="matlabel" text-wrap> I confirm that the information given in the form is true,
                    complete and accurate.</mat-label>
                </div>
              </ng-container>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>