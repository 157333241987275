<div *ngIf="dietPlanFlag == 'min'">
   <div class="details row">
      <div class="col-12" style="margin: 5px 0;">
         <div class="diet_lable_cover">
            <mat-label class="dite_input" [(ngModel)]="cal_txt">
               Caloried required
               <span style="float: right;padding: 0 10px 0 0;">{{cal_txt}}</span>
            </mat-label>
            <div class="progress progress-xxs">
               <div class="progress-bar progress-bar-success" role="progressbar" aria-valuenow="77" aria-valuemin="0"
                  aria-valuemax="100" [ngStyle]="{'border-bottom':'1px solid green','width':'0'}"></div>
            </div>
         </div>
      </div>
      <div class="col-12" style="margin: 5px 0;">
         <div class="diet_lable_cover">
            <mat-label class="dite_input" [(ngModel)]="tot_cal">
               Total calories(Kcal)
               <span style="float: right;padding: 0 10px 0 0;">{{tot_cal}}</span>
            </mat-label>
            <div class="progress progress-xxs">
               <div class="progress-bar progress-bar-warning" role="progressbar" aria-valuenow="50" aria-valuemin="0"
                  aria-valuemax="100" [ngStyle]="{'width':'0'}"></div>
            </div>
         </div>
      </div>
      <div class="col-12" style="margin: 5px 0;">
         <div class="diet_lable_cover">
            <mat-label class="dite_input" id="sga" [(ngModel)]="Sga_overalltxt">
               SGA score
               <span style="float: right;padding: 0 10px 0 0;">{{(Sga_overalltxt == "") ? 0 : Sga_overalltxt}}</span>
            </mat-label>

            <div class="progress progress-xxs">
               <div class="progress-bar progress-bar-info" role="progressbar" aria-valuenow="23" aria-valuemin="0"
                  aria-valuemax="100" [ngStyle]="{'width':''}"></div>
            </div>
         </div>
      </div>

   </div>
</div>

<div *ngIf="dietPlanFlag == 'max'">
   <div class="row">
      <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" style="margin: 5px 0;">
         <div class="diet_lable_cover">
            <mat-label class="dite_input" [(ngModel)]="cal_txt">
               Caloried required
               <span style="float: right;padding: 0 10px 0 0;">{{cal_txt}}</span>
            </mat-label>
            <div class="progress progress-xxs">
               <div class="progress-bar progress-bar-success" role="progressbar" aria-valuenow="77" aria-valuemin="0"
                  aria-valuemax="100" [ngStyle]="{'border-bottom':'1px solid green','width':''}"></div>
            </div>
         </div>
      </div>
      <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" style="margin: 5px 0;">
         <div class="diet_lable_cover">
            <mat-label class="dite_input" [(ngModel)]="tot_cal">
               Total calories(Kcal)
               <span style="float: right;padding: 0 10px 0 0;">{{tot_cal}}</span>
            </mat-label>
            <div class="progress progress-xxs">
               <div class="progress-bar progress-bar-warning" role="progressbar" aria-valuenow="50" aria-valuemin="0"
                  aria-valuemax="100" [ngStyle]="{'width':''}"></div>
            </div>
         </div>
      </div>
      <div class="col-9 col-sm-6 col-md-6 col-lg-3 col-xl-3" style="margin: 5px 0;">
         <div class="diet_lable_cover">
            <mat-label class="dite_input" id="sga" [(ngModel)]="Sga_overalltxt">
               SGA score
               <span style="float: right;padding: 0 10px 0 0;">{{(Sga_overalltxt == "") ? 0 : Sga_overalltxt}}</span>
            </mat-label>

            <div class="progress progress-xxs">
               <div class="progress-bar progress-bar-info" role="progressbar" aria-valuenow="23" aria-valuemin="0"
                  aria-valuemax="100" [ngStyle]="{'width':''}"></div>
            </div>
         </div>
      </div>
      <div class="col-3 col-sm-6 col-md-6 col-lg-3 col-xl-3">
         <img src="../assets/global/images/settings-icon.png" (click)="openDietPlan()" class=""
            style="position: relative;top: 8px;" width="22px" height="22px" />
      </div>

      <div class="dite_content_cover">
         <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
               <button class="nav-link active" id="morning-tab" data-bs-toggle="tab" data-bs-target="#morning"
                  type="button" role="tab" aria-controls="morning" aria-selected="true" (click)="selectDay('Sunday','Morning')">Morning snacks</button>
            </li>
            <li class="nav-item" role="presentation">
               <button class="nav-link" id="breakfast-tab" data-bs-toggle="tab" data-bs-target="#breakfast"
                  type="button" role="tab" aria-controls="breakfast" aria-selected="false" (click)="selectDay('Sunday','Breakfast')">Breakfast</button>
            </li>
            <li class="nav-item" role="presentation">
               <button class="nav-link" id="mid_day-tab" data-bs-toggle="tab" data-bs-target="#mid_day" type="button"
                  role="tab" aria-controls="mid_day" aria-selected="false" (click)="selectDay('Sunday','Mid-day')">Mid-day-snacks</button>
            </li>
            <li class="nav-item" role="presentation">
               <button class="nav-link" id="lunch-tab" data-bs-toggle="tab" data-bs-target="#lunch" type="button"
                  role="tab" aria-controls="lunch" aria-selected="false" (click)="selectDay('Sunday','Lunch')">Lunch</button>
            </li>
            <li class="nav-item" role="presentation">
               <button class="nav-link" id="Evening-tab" data-bs-toggle="tab" data-bs-target="#Evening" type="button"
                  role="tab" aria-controls="Evening" aria-selected="false" (click)="selectDay('Sunday','Evening')">Evening snacks</button>
            </li>
            <li class="nav-item" role="presentation">
               <button class="nav-link" id="dinner-tab" data-bs-toggle="tab" data-bs-target="#dinner" type="button"
                  role="tab" aria-controls="dinner" aria-selected="false" (click)="selectDay('Sunday','Dinner')">Dinner</button>
            </li>
            <li class="nav-item" role="presentation">
               <button class="nav-link" id="night_snacks-tab" data-bs-toggle="tab" data-bs-target="#night_snacks"
                  type="button" role="tab" aria-controls="night_snacks" aria-selected="false" (click)="selectDay('Sunday','Night')">Night snacks</button>
            </li>
         </ul>
         <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="morning" role="tabpanel" aria-labelledby="morning-tab">
               <ul class="nav nav-tabs_week" id="myTab" role="tablist">
                  <li class="nav-item" role="presentation">
                     <button class="nav-link_week active" id="Sunday-tab" data-bs-toggle="tab" data-bs-target="#Sunday"
                        type="button" role="tab" aria-controls="Sunday" aria-selected="true" (click)="selectDay('Sunday','Morning')">Sunday</button>
                  </li>
                  <li class="nav-item" role="presentation">
                     <button class="nav-link_week" id="Monday-tab" data-bs-toggle="tab" data-bs-target="#Monday"
                        type="button" role="tab" aria-controls="Monday" aria-selected="false" (click)="selectDay('Monday','Morning')">Monday</button>
                  </li>
                  <li class="nav-item" role="presentation">
                     <button class="nav-link_week" id="Tuesday-tab" data-bs-toggle="tab" data-bs-target="#Tuesday" type="button"
                        role="tab" aria-controls="Tuesday" aria-selected="false" (click)="selectDay('Tuesday','Morning')">Tuesday</button>
                  </li>
                  <li class="nav-item" role="presentation">
                     <button class="nav-link_week" id="Wednesday-tab" data-bs-toggle="tab" data-bs-target="#Wednesday" type="button"
                        role="tab" aria-controls="Wednesday" aria-selected="false" (click)="selectDay('Wednesday','Morning')">Wednesday</button>
                  </li>
                  <li class="nav-item" role="presentation">
                     <button class="nav-link_week" id="Thursday-tab" data-bs-toggle="tab" data-bs-target="#Thursday" type="button"
                        role="tab" aria-controls="Thursday" aria-selected="false" (click)="selectDay('Thursday','Morning')">Thursday</button>
                  </li>
                  <li class="nav-item" role="presentation">
                     <button class="nav-link_week" id="Friday-tab" data-bs-toggle="tab" data-bs-target="#Friday" type="button"
                        role="tab" aria-controls="Friday" aria-selected="false" (click)="selectDay('Friday','Morning')">Friday</button>
                  </li>
                  <li class="nav-item" role="presentation">
                     <button class="nav-link_week" id="Saturday-tab" data-bs-toggle="tab" data-bs-target="#Saturday"
                        type="button" role="tab" aria-controls="Saturday" aria-selected="false" (click)="selectDay('Saturday','Morning')">Saturday</button>
                  </li>
               </ul>
               <div class="tab-content" id="myweekTabContent">
                  <div class="tab-pane fade show active" role="tabpanel">
                     <div class="row" style="padding: 5px 0 5px 0;">
                        <div class="col-2">
                           <img *ngIf="mor_show_menu" (click)="selectMenu('Morning','menu',false)"
                              src="../../../assets/ui_icons/popup_tick_mark.svg" style="cursor: pointer;" width="12px" />
                           <img *ngIf="!mor_show_menu" (click)="selectMenu('Morning','menu',true)"
                              src="../../../assets/ui_icons/yellow_box.svg" style="cursor: pointer;" width="12px" />
                           &nbsp;Menu
                        </div>
                        <div class="col-2">
                           <img *ngIf="mor_show_non_menu" (click)="selectMenu('Morning','non-menu',false)"
                              src="../../../assets/ui_icons/popup_tick_mark.svg" style="cursor: pointer;" width="12px" />
                           <img *ngIf="!mor_show_non_menu" (click)="selectMenu('Morning','non-menu',true)"
                              src="../../../assets/ui_icons/yellow_box.svg" style="cursor: pointer;" width="12px" />
                           &nbsp;Non-menu
                        </div>
                     </div>
                     <div class="row" style="margin-top: 10px;">
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 " *ngIf="morning_option == 'non-menu'">
                           <div class="cover_div">
                              <div class="content_cover">
                                 <div class="row">
                                    <div class="col-6">
                                       <mat-label class="matlabel">Category<br>
                                          <select [(ngModel)]="mor_catxt" id="mornCategory"
                                             (change)="food_catchange('Morning')" disableOptionCentering
                                             class="ipcss widthbillcreate">
                                             <option disabled>Select</option>
                                             <option *ngFor="let food of foot_cate" [value]="food.description">
                                                {{food.description}}
                                             </option>
                                          </select>
                                       </mat-label>
                                    </div>
                                    <div class="col-6 select_bottomy">
                                       <mat-label class="matlabel">Food type<br>
                                          <select [(ngModel)]="mor_tpetxt" id="mornfoodtype" class="ipcss widthbillcreate"
                                             (change)="food_typechange(mor_tpetxt,'Morning')" disableOptionCentering>
                                             <option disabled>Select</option>
                                             <option *ngFor="let food of mor_foot_type" [value]="food">{{food}}</option>
      
                                          </select>
                                       </mat-label>
                                    </div>
                                    <div class="col-6 select_bottomy">
                                       <mat-label class="matlabel">Food sub type<br>
                                          <select [(ngModel)]="mor_food_subtxt" id="mornsubfoodtype"
                                             class="ipcss widthbillcreate" (change)="food_subtypes(mor_food_subtxt,'Morning')"
                                             disableOptionCentering>
                                             <option disabled>Select</option>
                                             <option *ngFor="let food of mor_subfoot_type" [value]="food.description">
                                                {{food.description}}</option>
                                          </select>
                                       </mat-label>
                                    </div>
                                    <div class="col-6 select_bottomy">
                                       <mat-label class="matlabel">Quantity<br>
                                          <select [(ngModel)]="mor_food_qty" id="mornQuantity" class="ipcss"
                                             (change)="Quantity_OnChange(mor_food_qty,'Morning')" required>
                                             <option disabled>Select </option>
                                             <option>1</option>
                                             <option>2</option>
                                             <option>3</option>
                                             <option>4</option>
                                             <option>5</option>
                                             <option>6</option>
                                             <option>7</option>
                                             <option>8</option>
                                             <option>9</option>
                                             <option>10</option>
                                             <option>11</option>
                                             <option>12</option>
                                             <option>13</option>
                                             <option>14</option>
                                             <option>15</option>
                                             <option>16</option>
                                             <option>17</option>
                                             <option>20</option>
                                             <option>21</option>
                                             <option>22</option>
                                             <option>24</option>
                                             <option>26</option>
                                             <option>28</option>
                                             <option>30</option>
                                             <option>32</option>
                                             <option>40</option>
                                             <option>42</option>
                                             <option>48</option>
                                             <option>60</option>
                                             <option>1/8</option>
                                             <option>1/4</option>
                                             <option>1/3</option>
                                             <option>1/2</option>
                                             <option>3/4</option>
                                             <option>11/2</option>
                                             <option>21/2</option>
                                             <option>21/8</option>
                                             <option>91/2</option>
                                             <option>2/3</option>
                                             <option>41/4</option>
                                          </select>
                                       </mat-label>
                                    </div>
                                    <div class="col-6">
                                       <mat-label class="matlabel">Measure<br>
                                          <input type="text" name="name" id="formqnty" class="ipcss" [(ngModel)]="mor_measure"
                                             minlength="3" required="" aria-required="true">
                                       </mat-label>
                                    </div>
                                    <div class="col-6">
                                       <mat-label class="matlabel">Weight<br>
                                          <input type="text" name="name" id="formqnty" class="ipcss" [(ngModel)]="mor_weight"
                                             minlength="3" required="" aria-required="true">
                                       </mat-label>
                                    </div>
                                    <div class="col-6">
                                       <mat-label class="matlabel">Energy<br>
                                          <input type="text" name="name" id="formqnty" class="ipcss" [(ngModel)]="mor_enegry"
                                             minlength="3" required="" aria-required="true">
                                       </mat-label>
                                    </div>
                                    <div class="col-6 select_bottomy">
                                       <mat-label class="matlabel">Condition<br>
                                          <select [(ngModel)]="mor_food_cond" class="ipcss widthbillcreate"
                                             disableOptionCentering>
                                             <option value="or">or</option>
                                             <option value="and">and</option>
                                             <option value="with">with</option>
                                          </select>
                                       </mat-label>
                                    </div>
                                    <div class="col-12">
                                       <div [hidden]="!client" style="margin-bottom: 10px;"></div>
                                       <div [hidden]="client">
                                          <a><img (click)="diet_morclick() " [hidden]="client"
                                                src="../../../assets/ui_icons/buttons/add_button.svg"
                                                class="saveimgbtn_inpatinfo" style="float: right;" /></a>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" *ngIf="morning_option == 'menu'">
                           <div class="row">
                              <div class="col-6">
                                 <mat-label class="matlabel">Food type</mat-label>
                                 <select class="ipcss" required [(ngModel)]="menu_mor_tpetxt"
                                    (change)="menu_change_food_type('Morning',menu_mor_tpetxt)">
                                    <option *ngFor="let food of menu_mor_foodType" value={{food.food_menu_id}}>{{food.name}}
                                    </option>
                                 </select>
                              </div>
                              <div class="col-6">
                                 <mat-label class="matlabel">Condition<br>
                                    <select [(ngModel)]="menu_mor_cond" class="ipcss widthbillcreate" disableOptionCentering>
                                       <!-- <option>Select</option> -->
                                       <option value="or">or</option>
                                       <option value="and">and</option>
                                       <option value="with">with</option>
                                    </select>
                                 </mat-label>
                              </div>
                              <div class="col-12">
                                 <div class="cover_div">
                                    <div class="content_cover">
                                       <div class="row">
                                          <div class="dig_table_overflow" style="border: none;">
                                             <table>
                                                <tr *ngFor="let subfood of menu_mor_subfoodType">
                                                   <td class="align_left">
                                                      <img *ngIf="subfood.checked == true" (click)="subfood.checked = false;menu_change_sub_food_type('Morning',subfood,false)"
                                                         src="../../../assets/ui_icons/popup_tick_mark.svg"
                                                         style="cursor: pointer;position: relative; top: -5px;"
                                                         width="12px" />
                                                      <img *ngIf="subfood.checked == false" (click)="subfood.checked = true;menu_change_sub_food_type('Morning',subfood,true)"
                                                       src="../../../assets/ui_icons/yellow_box.svg"
                                                         style="cursor: pointer;position: relative; top: -5px;"
                                                         width="12px" />
                                                   </td>
                                                   <td class="align_left">
                                                      <mat-label class="ipcss">{{subfood.quantity}}&nbsp;{{subfood.measure}}
                                                      </mat-label>
                                                   </td>
                                                   <td class="align_left">
                                                      <mat-label class="ipcss">{{subfood.food_item}}</mat-label>
                                                   </td>
                                                </tr>
                                             </table>
                                          </div>
                                          <div class="col-12">
                                             <a><img (click)="menu_diet_Add('Morning')" [hidden]="client"
                                                   src="../../../assets/ui_icons/buttons/add_button.svg"
                                                   class="saveimgbtn_inpatinfo" style="float: right;" /></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex align-items-end" *ngIf="morning_option == 'menu'">
                           <div class="cover_div" style="padding-bottom: 111px;">
                              <div class="content_cover">
                                 <div class="row d-flex align-items-center" >
                                    <table class="col-12 tbl">
                                       <tbody>
                                          <tr>
                                             <td rowspan="8" style="width: 143px;"><img src={{mor_food_image}} style="width: 100px; height: auto;"></td>
                                          </tr>
                                          <tr>
                                             <th>Energy(kcal)</th>
                                             <th>Protien(gm)</th>
                                             <th>Fat(gm)</th>
                                          </tr>
                                          <tr>
                                             <td>{{mor_energy}}</td>
                                             <td>{{mor_protein}}</td>
                                             <td>{{mor_fat}}</td>
                                          </tr>
                                          <tr>
                                             <th>Carbohydrates(gm)</th>
                                             <th>Fiber(gm)</th>
                                             <th>Vitamin A(mg)</th>
                                          </tr>
                                          <tr>
                                             <td>{{mor_carbohydrates}}</td>
                                             <td>{{mor_fiber}}</td>
                                             <td>{{mor_vitA}}</td>
                                          </tr>
                                       </tbody>
                                    </table>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div [ngClass]="morn_class">
                           <div class="cover_div">
                              <div class="content_cover">
                                 <div style="float: right;">
                                    <img class="pencil_refresh_icon" [hidden]="client" (click)="edit_content('mor')"
                                       src="../../../assets/ui_icons/diet_plan/pencil_icon.svg" />
                                    <img class="pencil_refresh_icon" [hidden]="client" (click)="clear_content('mor')"
                                       src="../../../assets/ui_icons/diet_plan/refresh_icon.svg" />
                                 </div>
                                 <textarea class="ipcss widthappt result_area"
                                    style="overflow-y: scroll;margin: 15px 0 0 0; height: 239px !important; background: #eff8fd; border: none;"
                                    disabled [innerHTML]="dietmorning" (change)="send_data()"></textarea>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <!-- //Breakfast -->
            <div class="tab-pane fade show" id="breakfast" role="tabpanel" aria-labelledby="breakfast-tab">
               <ul class="nav nav-tabs_week" id="myTab" role="tablist">
                  <li class="nav-item" role="presentation">
                     <button class="nav-link_week active" id="Sunday-tab" data-bs-toggle="tab" data-bs-target="#Sunday"
                        type="button" role="tab" aria-controls="Sunday" aria-selected="true" (click)="selectDay('Sunday','Breakfast')">Sunday</button>
                  </li>
                  <li class="nav-item" role="presentation">
                     <button class="nav-link_week" id="Monday-tab" data-bs-toggle="tab" data-bs-target="#Monday"
                        type="button" role="tab" aria-controls="Monday" aria-selected="false" (click)="selectDay('Monday','Breakfast')">Monday</button>
                  </li>
                  <li class="nav-item" role="presentation">
                     <button class="nav-link_week" id="Tuesday-tab" data-bs-toggle="tab" data-bs-target="#Tuesday" type="button"
                        role="tab" aria-controls="Tuesday" aria-selected="false" (click)="selectDay('Tuesday','Breakfast')">Tuesday</button>
                  </li>
                  <li class="nav-item" role="presentation">
                     <button class="nav-link_week" id="Wednesday-tab" data-bs-toggle="tab" data-bs-target="#Wednesday" type="button"
                        role="tab" aria-controls="Wednesday" aria-selected="false" (click)="selectDay('Wednesday','Breakfast')">Wednesday</button>
                  </li>
                  <li class="nav-item" role="presentation">
                     <button class="nav-link_week" id="Thursday-tab" data-bs-toggle="tab" data-bs-target="#Thursday" type="button"
                        role="tab" aria-controls="Thursday" aria-selected="false" (click)="selectDay('Thursday','Breakfast')">Thursday</button>
                  </li>
                  <li class="nav-item" role="presentation">
                     <button class="nav-link_week" id="Friday-tab" data-bs-toggle="tab" data-bs-target="#Friday" type="button"
                        role="tab" aria-controls="Friday" aria-selected="false" (click)="selectDay('Friday','Breakfast')">Friday</button>
                  </li>
                  <li class="nav-item" role="presentation">
                     <button class="nav-link_week" id="Saturday-tab" data-bs-toggle="tab" data-bs-target="#Saturday"
                        type="button" role="tab" aria-controls="Saturday" aria-selected="false" (click)="selectDay('Saturday','Breakfast')">Saturday</button>
                  </li>
               </ul>
               <div class="tab-content" id="myweekTabContent">
                  <div class="row" style="padding: 5px 0 5px 0;">
                     <div class="col-2">
                        <img *ngIf="bf_show_menu" (click)="selectMenu('Breakfast','menu',false)"
                           src="../../../assets/ui_icons/popup_tick_mark.svg" style="cursor: pointer;" width="12px" />
                        <img *ngIf="!bf_show_menu" (click)="selectMenu('Breakfast','menu',true)"
                           src="../../../assets/ui_icons/yellow_box.svg" style="cursor: pointer;" width="12px" />
                        &nbsp;Menu
                     </div>
                     <div class="col-2">
                        <img *ngIf="bf_show_non_menu" (click)="selectMenu('Breakfast','non-menu',false)"
                           src="../../../assets/ui_icons/popup_tick_mark.svg" style="cursor: pointer;" width="12px" />
                        <img *ngIf="!bf_show_non_menu" (click)="selectMenu('Breakfast','non-menu',true)"
                           src="../../../assets/ui_icons/yellow_box.svg" style="cursor: pointer;" width="12px" />
                        &nbsp;Non-menu
                     </div>
                  </div>
                  <div class="row" style="margin-top: 10px;">
                     <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" *ngIf="breakfast_option == 'non-menu'">
                        <div class="cover_div">
                           <div class="content_cover">
                              <div class="row">
                                 <div class="col-6">
                                    <mat-label class="matlabel">Category<br>
                                       <select [(ngModel)]="break_catxt" id="bfCategory"
                                          (change)="food_catchange('breakfast')" required disableOptionCentering
                                          class="ipcss widthbillcreate">
                                          <option disabled>Select</option>
                                          <option *ngFor="let food of foot_cate" [value]="food.description">
                                             {{food.description}}
                                          </option>
                                       </select>
                                    </mat-label>
                                 </div>
                                 <div class="col-6">
                                    <mat-label class="matlabel">Food type<br>
                                       <select [(ngModel)]="break_tpetxt" id="bffoodtype" class="ipcss widthbillcreate"
                                          required (change)="food_typechange(break_tpetxt,'breakfast')"
                                          disableOptionCentering>
                                          <option disabled>Select</option>
                                          <option *ngFor="let food of break_foot_type" [value]="food">{{food}}</option>
                                       </select>
                                    </mat-label>
                                 </div>
                                 <div class="col-6">
                                    <mat-label class="matlabel">Food sub type<br>
                                       <select [(ngModel)]="break_subtxt" id="bfsubfoodtype" class="ipcss widthbillcreate"
                                          (change)="food_subtypes(break_subtxt,'breakfast')" disableOptionCentering
                                          required>
                                          <option disabled>Select</option>
                                          <option *ngFor="let food of break_subfoot_type" [value]="food.description">
                                             {{food.description}}</option>
                                       </select>
                                    </mat-label>
                                 </div>
                                 <div class="col-6">
                                    <mat-label class="matlabel">Quantity<br>
                                       <select [(ngModel)]="Break_Quantity" id="bfQuantity" class="ipcss"
                                          (change)="Quantity_OnChange(Break_Quantity,'breakfast')" required>
                                          <option disabled>Select </option>
                                          <option>1</option>
                                          <option>2</option>
                                          <option>3</option>
                                          <option>4</option>
                                          <option>5</option>
                                          <option>6</option>
                                          <option>7</option>
                                          <option>8</option>
                                          <option>9</option>
                                          <option>10</option>
                                          <option>11</option>
                                          <option>12</option>
                                          <option>13</option>
                                          <option>14</option>
                                          <option>15</option>
                                          <option>16</option>
                                          <option>17</option>
                                          <option>20</option>
                                          <option>21</option>
                                          <option>22</option>
                                          <option>24</option>
                                          <option>26</option>
                                          <option>28</option>
                                          <option>30</option>
                                          <option>32</option>
                                          <option>40</option>
                                          <option>42</option>
                                          <option>48</option>
                                          <option>60</option>
                                          <option>1/8</option>
                                          <option>1/4</option>
                                          <option>1/3</option>
                                          <option>1/2</option>
                                          <option>3/4</option>
                                          <option>11/2</option>
                                          <option>21/2</option>
                                          <option>21/8</option>
                                          <option>91/2</option>
                                          <option>2/3</option>
                                          <option>41/4</option>
                                       </select>
                                    </mat-label>
                                 </div>
                                 <div class="col-6">

                                    <mat-label class="matlabel">Measure<br>
                                       <input type="text" name="name" id="formqnty" class="ipcss"
                                          [(ngModel)]="Break_Measure" minlength="3" required="" aria-required="true">
                                    </mat-label>
                                 </div>
                                 <div class="col-6">

                                    <mat-label class="matlabel">Weight<br>
                                       <input type="text" name="name" id="formqnty" class="ipcss"
                                          [(ngModel)]="Break_Weight" minlength="3" required="" aria-required="true">
                                    </mat-label>
                                 </div>
                                 <div class="col-6">

                                    <mat-label class="matlabel">Energy<br>
                                       <input type="text" name="name" id="formqnty" class="ipcss"
                                          [(ngModel)]="Break_Energy" minlength="3" required="" aria-required="true">
                                    </mat-label>
                                 </div>
                                 <div class="col-6">
                                    <mat-label class="matlabel">Condition<br>
                                       <select [(ngModel)]="breakfast_cond" class="ipcss widthbillcreate"
                                          disableOptionCentering>
                                          <option>Select</option>
                                          <option value="or">or</option>
                                          <option value="and">and</option>
                                          <option value="with">with</option>
                                       </select>
                                    </mat-label>
                                 </div>
                                 <div class="col-12">
                                    <div [hidden]="!client" style="margin-bottom: 10px;"></div>
                                    <div [hidden]="client">
                                       <a><img (click)="diet_breakfastclick()"
                                             src="../../../assets/ui_icons/buttons/add_button.svg"
                                             class="saveimgbtn_inpatinfo" style="float: right;" /></a>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" *ngIf="breakfast_option == 'menu'">
                        <div class="row">
                           <div class="col-6">
                              <mat-label class="matlabel">Food type</mat-label>
                              <select class="ipcss" required [(ngModel)]="menu_bf_tpetxt"
                                 (change)="menu_change_food_type('Breakfast',menu_bf_tpetxt)">
                                 <option *ngFor="let food of menu_bf_foodType" value={{food.food_menu_id}}>{{food.name}}
                                 </option>
                              </select>
                           </div>
                           <div class="col-6">
                              <mat-label class="matlabel">Condition<br>
                                 <select [(ngModel)]="menu_bf_cond" class="ipcss widthbillcreate" disableOptionCentering>
                                    <!-- <option>Select</option> -->
                                    <option value="or">or</option>
                                    <option value="and">and</option>
                                    <option value="with">with</option>
                                 </select>
                              </mat-label>
                           </div>
                           <div class="col-12">
                              <div class="cover_div">
                                 <div class="content_cover">
                                    <div class="row">
                                       <div class="dig_table_overflow" style="border: none;">
                                          <table>
                                             <tr *ngFor="let subfood of menu_bf_subfoodType">
                                                <td class="align_left">
                                                   <img *ngIf="subfood.checked == true" (click)="subfood.checked = false;menu_change_sub_food_type('Breakfast',subfood,false)"
                                                      src="../../../assets/ui_icons/popup_tick_mark.svg"
                                                      style="cursor: pointer;position: relative; top: -5px;"
                                                      width="12px" />
                                                   <img *ngIf="subfood.checked == false" (click)="subfood.checked = true;menu_change_sub_food_type('Breakfast',subfood,true)"
                                                   src="../../../assets/ui_icons/yellow_box.svg"
                                                      style="cursor: pointer;position: relative; top: -5px;"
                                                      width="12px" />
                                                </td>
                                                <td class="align_left">
                                                   <mat-label class="ipcss">{{subfood.quantity}}&nbsp;{{subfood.measure}}
                                                   </mat-label>
                                                </td>
                                                <td class="align_left">
                                                   <mat-label class="ipcss">{{subfood.food_item}}</mat-label>
                                                </td>
                                             </tr>
                                          </table>
                                       </div>
                                       <div class="col-12">
                                          <a><img (click)="menu_diet_Add('Breakfast')" [hidden]="client"
                                                src="../../../assets/ui_icons/buttons/add_button.svg"
                                                class="saveimgbtn_inpatinfo" style="float: right;" /></a>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex align-items-end" *ngIf="breakfast_option == 'menu'">
                        <div class="cover_div" style="padding-bottom: 111px;">
                           <div class="content_cover">
                              <div class="row d-flex align-items-center" >
                                 <table class="col-12 tbl">
                                    <tbody>
                                       <tr>
                                          <td rowspan="8" style="width: 143px;"><img src={{bf_food_image}} style="width: 100px; height: auto;"></td>
                                       </tr>
                                       <tr>
                                          <th>Energy(kcal)</th>
                                          <th>Protien(gm)</th>
                                          <th>Fat(gm)</th>
                                       </tr>
                                       <tr>
                                          <td>{{bf_energy}}</td>
                                          <td>{{bf_protein}}</td>
                                          <td>{{bf_fat}}</td>
                                       </tr>
                                       <tr>
                                          <th>Carbohydrates(gm)</th>
                                          <th>Fiber(gm)</th>
                                          <th>Vitamin A(mg)</th>
                                       </tr>
                                       <tr>
                                          <td>{{bf_carbohydrates}}</td>
                                          <td>{{bf_fiber}}</td>
                                          <td>{{bf_vitA}}</td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div [ngClass]="bf_class">
                        <div class="cover_div">
                           <div class="content_cover">
                              <div style="float: right;">
                                 <img class="pencil_refresh_icon" (click)="edit_content('break')"
                                    src="../../../assets/ui_icons/diet_plan/pencil_icon.svg" [hidden]="client" />
                                 <img class="pencil_refresh_icon" (click)="clear_content('break')"
                                    src="../../../assets/ui_icons/diet_plan/refresh_icon.svg" [hidden]="client" />
                              </div>
                              <textarea class="ipcss widthappt result_area"
                                 style="overflow-y: scroll;margin: 15px 0 0 0; height: 239px !important; background: #eff8fd; border: none;"
                                 disabled [innerHTML]="dietbreakfast" (change)="send_data()"></textarea>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <!-- //Mid-Day-Snacks -->
            <div class="tab-pane fade show" id="mid_day" role="tabpanel" aria-labelledby="mid_day-tab">
               <ul class="nav nav-tabs_week" id="myTab" role="tablist">
                  <li class="nav-item" role="presentation">
                     <button class="nav-link_week active" id="Sunday-tab" data-bs-toggle="tab" data-bs-target="#Sunday"
                        type="button" role="tab" aria-controls="Sunday" aria-selected="true" (click)="selectDay('Sunday','Mid-day')">Sunday</button>
                  </li>
                  <li class="nav-item" role="presentation">
                     <button class="nav-link_week" id="Monday-tab" data-bs-toggle="tab" data-bs-target="#Monday"
                        type="button" role="tab" aria-controls="Monday" aria-selected="false" (click)="selectDay('Monday','Mid-day')">Monday</button>
                  </li>
                  <li class="nav-item" role="presentation">
                     <button class="nav-link_week" id="Tuesday-tab" data-bs-toggle="tab" data-bs-target="#Tuesday" type="button"
                        role="tab" aria-controls="Tuesday" aria-selected="false" (click)="selectDay('Tuesday','Mid-day')">Tuesday</button>
                  </li>
                  <li class="nav-item" role="presentation">
                     <button class="nav-link_week" id="Wednesday-tab" data-bs-toggle="tab" data-bs-target="#Wednesday" type="button"
                        role="tab" aria-controls="Wednesday" aria-selected="false" (click)="selectDay('Wednesday','Mid-day')">Wednesday</button>
                  </li>
                  <li class="nav-item" role="presentation">
                     <button class="nav-link_week" id="Thursday-tab" data-bs-toggle="tab" data-bs-target="#Thursday" type="button"
                        role="tab" aria-controls="Thursday" aria-selected="false" (click)="selectDay('Thursday','Mid-day')">Thursday</button>
                  </li>
                  <li class="nav-item" role="presentation">
                     <button class="nav-link_week" id="Friday-tab" data-bs-toggle="tab" data-bs-target="#Friday" type="button"
                        role="tab" aria-controls="Friday" aria-selected="false" (click)="selectDay('Friday','Mid-day')">Friday</button>
                  </li>
                  <li class="nav-item" role="presentation">
                     <button class="nav-link_week" id="Saturday-tab" data-bs-toggle="tab" data-bs-target="#Saturday"
                        type="button" role="tab" aria-controls="Saturday" aria-selected="false" (click)="selectDay('Saturday','Mid-day')">Saturday</button>
                  </li>
               </ul>
               <div class="tab-content" id="myweekTabContent">
                  <div class="row" style="padding: 5px 0 5px 0;">
                     <div class="col-2">
                        <img *ngIf="mid_day_show_menu" (click)="selectMenu('Mid-day','menu',false)"
                           src="../../../assets/ui_icons/popup_tick_mark.svg" style="cursor: pointer;" width="12px" />
                        <img *ngIf="!mid_day_show_menu" (click)="selectMenu('Mid-day','menu',true)"
                           src="../../../assets/ui_icons/yellow_box.svg" style="cursor: pointer;" width="12px" />
                        &nbsp;Menu
                     </div>
                     <div class="col-2">
                        <img *ngIf="mid_day_show_non_menu" (click)="selectMenu('Mid-day','non-menu',false)"
                           src="../../../assets/ui_icons/popup_tick_mark.svg" style="cursor: pointer;" width="12px" />
                        <img *ngIf="!mid_day_show_non_menu" (click)="selectMenu('Mid-day','non-menu',true)"
                           src="../../../assets/ui_icons/yellow_box.svg" style="cursor: pointer;" width="12px" />
                        &nbsp;Non-menu
                     </div>
                  </div>
                  <div class="row" style="margin-top: 10px;">
                     <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" *ngIf="mid_day_option == 'non-menu'">
                        <div class="cover_div">
                           <div class="content_cover">
                              <div class="row">
                                 <div class="col-6">
                                    <mat-label class="matlabel">Category<br>
                                       <select [(ngModel)]="midday_catxt" id="middayCategory"
                                          (change)="food_catchange('midday')" required disableOptionCentering
                                          class="ipcss widthbillcreate">
                                          <option disabled>Select</option>
                                          <option *ngFor="let food of foot_cate" [value]="food.description">
                                             {{food.description}}
                                          </option>
                                       </select>
                                    </mat-label>
                                 </div>
                                 <div class="col-6">
                                    <mat-label class="matlabel">Food type<br>
                                       <select [(ngModel)]="midday_tpetxt" id="middayfoodtype"
                                          class="ipcss widthbillcreate" required
                                          (change)="food_typechange(midday_tpetxt,'midday')" disableOptionCentering>
                                          <option disabled>Select</option>
                                          <option *ngFor="let food of midday_foot_type" [value]="food">{{food}}</option>
                                       </select>
                                    </mat-label>
                                 </div>
                                 <div class="col-6">
                                    <mat-label class="matlabel">Food sub type<br>
                                       <select [(ngModel)]="midday_subtxt" id="middaysubfoodtype"
                                          class="ipcss widthbillcreate" (change)="food_subtypes(midday_subtxt,'midday')"
                                          disableOptionCentering required>
                                          <option disabled>Select</option>
                                          <option *ngFor="let food of midday_subfoot_type" [value]="food.description">
                                             {{food.description}}</option>
                                       </select>
                                    </mat-label>
                                 </div>
                                 <div class="col-6">
                                    <mat-label class="matlabel">Quantity<br>
                                       <select [(ngModel)]="midday_Quantity" id="middayQuantity" class="ipcss"
                                          (change)="Quantity_OnChange(midday_Quantity,'midday')" required>
                                          <option disabled>Select </option>
                                          <option>1</option>
                                          <option>2</option>
                                          <option>3</option>
                                          <option>4</option>
                                          <option>5</option>
                                          <option>6</option>
                                          <option>7</option>
                                          <option>8</option>
                                          <option>9</option>
                                          <option>10</option>
                                          <option>11</option>
                                          <option>12</option>
                                          <option>13</option>
                                          <option>14</option>
                                          <option>15</option>
                                          <option>16</option>
                                          <option>17</option>
                                          <option>20</option>
                                          <option>21</option>
                                          <option>22</option>
                                          <option>24</option>
                                          <option>26</option>
                                          <option>28</option>
                                          <option>30</option>
                                          <option>32</option>
                                          <option>40</option>
                                          <option>42</option>
                                          <option>48</option>
                                          <option>60</option>
                                          <option>1/8</option>
                                          <option>1/4</option>
                                          <option>1/3</option>
                                          <option>1/2</option>
                                          <option>3/4</option>
                                          <option>11/2</option>
                                          <option>21/2</option>
                                          <option>21/8</option>
                                          <option>91/2</option>
                                          <option>2/3</option>
                                          <option>41/4</option>
                                       </select>
                                    </mat-label>
                                 </div>
                                 <div class="col-6">
                                    <mat-label class="matlabel">Measure<br>
                                       <input type="text" name="name" id="formqnty" class="ipcss"
                                          [(ngModel)]="midday_Measure" minlength="3" required="" aria-required="true">
                                    </mat-label>
                                 </div>
                                 <div class="col-6">
                                    <mat-label class="matlabel">Weight<br>
                                       <input type="text" name="name" id="formqnty" class="ipcss"
                                          [(ngModel)]="midday_Weight" minlength="3" required="" aria-required="true">
                                    </mat-label>
                                 </div>
                                 <div class="col-6">
                                    <mat-label class="matlabel">Energy<br>
                                       <input type="text" name="name" id="formqnty" class="ipcss"
                                          [(ngModel)]="midday_Energy" minlength="3" required="" aria-required="true">
                                    </mat-label>
                                 </div>
                                 <div class="col-6">
                                    <mat-label class="matlabel">Condition<br>
                                       <select [(ngModel)]="midday_cond" class="ipcss widthbillcreate"
                                          disableOptionCentering>
                                          <option>Select</option>
                                          <option value="or">or</option>
                                          <option value="and">and</option>
                                          <option value="with">with</option>
                                       </select>
                                    </mat-label>
                                 </div>
                                 <div class="col-12">
                                    <div [hidden]="!client" style="margin-bottom: 10px;"></div>
                                    <div [hidden]="client">
                                       <a><img (click)="diet_middayclick()"
                                             src="../../../assets/ui_icons/buttons/add_button.svg"
                                             class="saveimgbtn_inpatinfo" [hidden]="client" style="float: right;" /></a>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" *ngIf="mid_day_option == 'menu'">
                        <div class="row">
                           <div class="col-6">
                              <mat-label class="matlabel">Food type</mat-label>
                              <select class="ipcss" required [(ngModel)]="menu_mid_day_tpetxt"
                                 (change)="menu_change_food_type('Mid-day',menu_mid_day_tpetxt)">
                                 <option *ngFor="let food of menu_mid_day_foodType" value={{food.food_menu_id}}>{{food.name}}
                                 </option>
                              </select>
                           </div>
                           <div class="col-6">
                              <mat-label class="matlabel">Condition<br>
                                 <select [(ngModel)]="menu_mid_day_cond" class="ipcss widthbillcreate" disableOptionCentering>
                                    <!-- <option>Select</option> -->
                                    <option value="or">or</option>
                                    <option value="and">and</option>
                                    <option value="with">with</option>
                                 </select>
                              </mat-label>
                           </div>
                           <div class="col-12">
                              <div class="cover_div">
                                 <div class="content_cover">
                                    <div class="row">
                                       <div class="dig_table_overflow" style="border: none;">
                                          <table>
                                             <tr *ngFor="let subfood of menu_mid_day_subfoodType">
                                                <td class="align_left">
                                                   <img *ngIf="subfood.checked == true" (click)="subfood.checked = false;menu_change_sub_food_type('Mid-day',subfood,false)"
                                                      src="../../../assets/ui_icons/popup_tick_mark.svg"
                                                      style="cursor: pointer;position: relative; top: -5px;"
                                                      width="12px" />
                                                   <img *ngIf="subfood.checked == false" (click)="subfood.checked = true;menu_change_sub_food_type('Mid-day',subfood,true)"
                                                   src="../../../assets/ui_icons/yellow_box.svg"
                                                      style="cursor: pointer;position: relative; top: -5px;"
                                                      width="12px" />
                                                </td>
                                                <td class="align_left">
                                                   <mat-label class="ipcss">{{subfood.quantity}}&nbsp;{{subfood.measure}}
                                                   </mat-label>
                                                </td>
                                                <td class="align_left">
                                                   <mat-label class="ipcss">{{subfood.food_item}}</mat-label>
                                                </td>
                                             </tr>
                                          </table>
                                       </div>
                                       <div class="col-12">
                                          <a><img (click)="menu_diet_Add('Mid-day')" [hidden]="client"
                                                src="../../../assets/ui_icons/buttons/add_button.svg"
                                                class="saveimgbtn_inpatinfo" style="float: right;" /></a>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex align-items-end" *ngIf="mid_day_option == 'menu'">
                        <div class="cover_div" style="padding-bottom: 111px;">
                           <div class="content_cover">
                              <div class="row d-flex align-items-center" >
                                 <table class="col-12 tbl">
                                    <tbody>
                                       <tr>
                                          <td rowspan="8" style="width: 143px;"><img src={{mid_day_food_image}} style="width: 100px; height: auto;"></td>
                                       </tr>
                                       <tr>
                                          <th>Energy(kcal)</th>
                                          <th>Protien(gm)</th>
                                          <th>Fat(gm)</th>
                                       </tr>
                                       <tr>
                                          <td>{{mid_day_energy}}</td>
                                          <td>{{mid_day_protein}}</td>
                                          <td>{{mid_day_fat}}</td>
                                       </tr>
                                       <tr>
                                          <th>Carbohydrates(gm)</th>
                                          <th>Fiber(gm)</th>
                                          <th>Vitamin A(mg)</th>
                                       </tr>
                                       <tr>
                                          <td>{{mid_day_carbohydrates}}</td>
                                          <td>{{mid_day_fiber}}</td>
                                          <td>{{mid_day_vitA}}</td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div [ngClass]="mid_day_class">
                        <div class="cover_div">
                           <div class="content_cover">
                              <div style="float: right;">
                                 <img class="pencil_refresh_icon" (click)="edit_content('midday')"
                                    src="../../../assets/ui_icons/diet_plan/pencil_icon.svg" [hidden]="client" />
                                 <img class="pencil_refresh_icon" (click)="clear_content('midday')"
                                    src="../../../assets/ui_icons/diet_plan/refresh_icon.svg" [hidden]="client" />
                              </div>
                              <textarea class="ipcss widthappt result_area"
                                 style="overflow-y: scroll;margin: 15px 0 0 0; height: 239px !important; background: #eff8fd; border: none;"
                                 disabled [innerHTML]="dietmidday" (change)="send_data()"></textarea>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <!-- //lunch -->
            <div class="tab-pane fade show" id="lunch" role="tabpanel" aria-labelledby="lunch-tab">
               <ul class="nav nav-tabs_week" id="myTab" role="tablist">
                  <li class="nav-item" role="presentation">
                     <button class="nav-link_week active" id="Sunday-tab" data-bs-toggle="tab" data-bs-target="#Sunday"
                        type="button" role="tab" aria-controls="Sunday" aria-selected="true" (click)="selectDay('Sunday','Lunch')">Sunday</button>
                  </li>
                  <li class="nav-item" role="presentation">
                     <button class="nav-link_week" id="Monday-tab" data-bs-toggle="tab" data-bs-target="#Monday"
                        type="button" role="tab" aria-controls="Monday" aria-selected="false" (click)="selectDay('Monday','Lunch')">Monday</button>
                  </li>
                  <li class="nav-item" role="presentation">
                     <button class="nav-link_week" id="Tuesday-tab" data-bs-toggle="tab" data-bs-target="#Tuesday" type="button"
                        role="tab" aria-controls="Tuesday" aria-selected="false" (click)="selectDay('Tuesday','Lunch')">Tuesday</button>
                  </li>
                  <li class="nav-item" role="presentation">
                     <button class="nav-link_week" id="Wednesday-tab" data-bs-toggle="tab" data-bs-target="#Wednesday" type="button"
                        role="tab" aria-controls="Wednesday" aria-selected="false" (click)="selectDay('Wednesday','Lunch')">Wednesday</button>
                  </li>
                  <li class="nav-item" role="presentation">
                     <button class="nav-link_week" id="Thursday-tab" data-bs-toggle="tab" data-bs-target="#Thursday" type="button"
                        role="tab" aria-controls="Thursday" aria-selected="false" (click)="selectDay('Thursday','Lunch')">Thursday</button>
                  </li>
                  <li class="nav-item" role="presentation">
                     <button class="nav-link_week" id="Friday-tab" data-bs-toggle="tab" data-bs-target="#Friday" type="button"
                        role="tab" aria-controls="Friday" aria-selected="false" (click)="selectDay('Friday','Lunch')">Friday</button>
                  </li>
                  <li class="nav-item" role="presentation">
                     <button class="nav-link_week" id="Saturday-tab" data-bs-toggle="tab" data-bs-target="#Saturday"
                        type="button" role="tab" aria-controls="Saturday" aria-selected="false" (click)="selectDay('Saturday','Lunch')">Saturday</button>
                  </li>
               </ul>
               <div class="tab-content" id="myweekTabContent">
                  <div class="row" style="padding: 5px 0 5px 0;">
                     <div class="col-2">
                        <img *ngIf="lunch_show_menu" (click)="selectMenu('Lunch','menu',false)"
                           src="../../../assets/ui_icons/popup_tick_mark.svg" style="cursor: pointer;" width="12px" />
                        <img *ngIf="!lunch_show_menu" (click)="selectMenu('Lunch','menu',true)"
                           src="../../../assets/ui_icons/yellow_box.svg" style="cursor: pointer;" width="12px" />
                        &nbsp;Menu
                     </div>
                     <div class="col-2">
                        <img *ngIf="lunch_show_non_menu" (click)="selectMenu('Lunch','non-menu',false)"
                           src="../../../assets/ui_icons/popup_tick_mark.svg" style="cursor: pointer;" width="12px" />
                        <img *ngIf="!lunch_show_non_menu" (click)="selectMenu('Lunch','non-menu',true)"
                           src="../../../assets/ui_icons/yellow_box.svg" style="cursor: pointer;" width="12px" />
                        &nbsp;Non-menu
                     </div>
                  </div>
                  <div class="row" style="margin-top: 10px;">
                     <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" *ngIf="lunch_option == 'non-menu'">
                        <div class="cover_div">
                           <div class="content_cover">
                              <div class="row">
                                 <div class="col-6">
                                    <mat-label class="matlabel">Category<br>
                                       <select [(ngModel)]="lunch_catxt" id="lunchCategory"
                                          (change)="food_catchange('lunch')" required disableOptionCentering
                                          class="ipcss widthbillcreate">
                                          <option disabled>Select</option>
                                          <option *ngFor="let food of foot_cate" [value]="food.description">
                                             {{food.description}}
                                          </option>
                                       </select>
                                    </mat-label>
                                 </div>
                                 <div class="col-6">
                                    <mat-label class="matlabel">Food type<br>
                                       <select [(ngModel)]="lunch_tpetxt" id="lunchfoodtype" class="ipcss widthbillcreate"
                                          required (change)="food_typechange(lunch_tpetxt,'lunch')" disableOptionCentering>
                                          <option disabled>Select</option>
                                          <option *ngFor="let food of lunch_foot_type" [value]="food">{{food}}</option>
                                       </select>
                                    </mat-label>
                                 </div>
                                 <div class="col-6">
                                    <mat-label class="matlabel">Food sub type<br>
                                       <select [(ngModel)]="lunch_subtxt" id="lunchsubfoodtype"
                                          class="ipcss widthbillcreate" (change)="food_subtypes(lunch_subtxt,'lunch')"
                                          disableOptionCentering required>
                                          <option disabled>Select</option>
                                          <option *ngFor="let food of lunch_subfoot_type" [value]="food.description">
                                             {{food.description}}</option>
                                       </select>
                                    </mat-label>
                                 </div>
                                 <div class="col-6">
                                    <mat-label class="matlabel">Quantity<br>
                                       <select [(ngModel)]="lunch_Quantity" id="lunchQuantity" class="ipcss"
                                          (change)="Quantity_OnChange(lunch_Quantity,'lunch')" required>
                                          <option disabled>Select </option>
                                          <option>1</option>
                                          <option>2</option>
                                          <option>3</option>
                                          <option>4</option>
                                          <option>5</option>
                                          <option>6</option>
                                          <option>7</option>
                                          <option>8</option>
                                          <option>9</option>
                                          <option>10</option>
                                          <option>11</option>
                                          <option>12</option>
                                          <option>13</option>
                                          <option>14</option>
                                          <option>15</option>
                                          <option>16</option>
                                          <option>17</option>
                                          <option>20</option>
                                          <option>21</option>
                                          <option>22</option>
                                          <option>24</option>
                                          <option>26</option>
                                          <option>28</option>
                                          <option>30</option>
                                          <option>32</option>
                                          <option>40</option>
                                          <option>42</option>
                                          <option>48</option>
                                          <option>60</option>
                                          <option>1/8</option>
                                          <option>1/4</option>
                                          <option>1/3</option>
                                          <option>1/2</option>
                                          <option>3/4</option>
                                          <option>11/2</option>
                                          <option>21/2</option>
                                          <option>21/8</option>
                                          <option>91/2</option>
                                          <option>2/3</option>
                                          <option>41/4</option>
                                       </select>
                                    </mat-label>
                                 </div>
                                 <div class="col-6">
                                    <mat-label class="matlabel">Measure<br>
                                       <input type="text" name="name" id="formqnty" class="ipcss"
                                          [(ngModel)]="lunch_Measure" minlength="3" required="" aria-required="true">
                                    </mat-label>
                                 </div>
                                 <div class="col-6">
                                    <mat-label class="matlabel">Weight<br>
                                       <input type="text" name="name" id="formqnty" class="ipcss"
                                          [(ngModel)]="lunch_Weight" minlength="3" required="" aria-required="true">
                                    </mat-label>
                                 </div>
                                 <div class="col-6">
                                    <mat-label class="matlabel">Energy<br>
                                       <input type="text" name="name" id="formqnty" class="ipcss"
                                          [(ngModel)]="lunch_Energy" minlength="3" required="" aria-required="true">
                                    </mat-label>
                                 </div>
                                 <div class="col-6">
                                    <mat-label class="matlabel">Condition<br>
                                       <select [(ngModel)]="lunch_cond" class="ipcss widthbillcreate"
                                          disableOptionCentering>
                                          <option>Select</option>
                                          <option value="or">or</option>
                                          <option value="and">and</option>
                                          <option value="with">with</option>
                                       </select>
                                    </mat-label>
                                 </div>
                                 <div class="col-12">
                                    <div [hidden]="!client" style="margin-bottom: 10px;"></div>
                                    <div [hidden]="client">
                                       <a><img (click)="diet_lunchclick()"
                                             src="../../../assets/ui_icons/buttons/add_button.svg"
                                             class="saveimgbtn_inpatinfo" [hidden]="client" style="float: right;" /></a>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" *ngIf="lunch_option == 'menu'">
                        <div class="row">
                           <div class="col-6">
                              <mat-label class="matlabel">Food type</mat-label>
                              <select class="ipcss" required [(ngModel)]="menu_lunch_tpetxt"
                                 (change)="menu_change_food_type('Lunch',menu_lunch_tpetxt)">
                                 <option *ngFor="let food of menu_lunch_foodType" value={{food.food_menu_id}}>{{food.name}}
                                 </option>
                              </select>
                           </div>
                           <div class="col-6">
                              <mat-label class="matlabel">Condition<br>
                                 <select [(ngModel)]="menu_lunch_cond" class="ipcss widthbillcreate" disableOptionCentering>
                                    <!-- <option>Select</option> -->
                                    <option value="or">or</option>
                                    <option value="and">and</option>
                                    <option value="with">with</option>
                                 </select>
                              </mat-label>
                           </div>
                           <div class="col-12">
                              <div class="cover_div">
                                 <div class="content_cover">
                                    <div class="row">
                                       <div class="dig_table_overflow" style="border: none;">
                                          <table>
                                             <tr *ngFor="let subfood of menu_lunch_subfoodType">
                                                <td class="align_left">
                                                   <img *ngIf="subfood.checked == true" (click)="subfood.checked = false;menu_change_sub_food_type('Lunch',subfood,false)"
                                                      src="../../../assets/ui_icons/popup_tick_mark.svg"
                                                      style="cursor: pointer;position: relative; top: -5px;"
                                                      width="12px" />
                                                   <img *ngIf="subfood.checked == false" (click)="subfood.checked = true;menu_change_sub_food_type('Lunch',subfood,true)"
                                                   src="../../../assets/ui_icons/yellow_box.svg"
                                                      style="cursor: pointer;position: relative; top: -5px;"
                                                      width="12px" />
                                                </td>
                                                <td class="align_left">
                                                   <mat-label class="ipcss">{{subfood.quantity}}&nbsp;{{subfood.measure}}
                                                   </mat-label>
                                                </td>
                                                <td class="align_left">
                                                   <mat-label class="ipcss">{{subfood.food_item}}</mat-label>
                                                </td>
                                             </tr>
                                          </table>
                                       </div>
                                       <div class="col-12">
                                          <a><img (click)="menu_diet_Add('Lunch')" [hidden]="client"
                                                src="../../../assets/ui_icons/buttons/add_button.svg"
                                                class="saveimgbtn_inpatinfo" style="float: right;" /></a>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex align-items-end" *ngIf="lunch_option == 'menu'">
                        <div class="cover_div" style="padding-bottom: 111px;">
                           <div class="content_cover">
                              <div class="row d-flex align-items-center" >
                                 <table class="col-12 tbl">
                                    <tbody>
                                       <tr>
                                          <td rowspan="8" style="width: 143px;"><img src={{lunch_food_image}} style="width: 100px; height: auto;"></td>
                                       </tr>
                                       <tr>
                                          <th>Energy(kcal)</th>
                                          <th>Protien(gm)</th>
                                          <th>Fat(gm)</th>
                                       </tr>
                                       <tr>
                                          <td>{{lunch_energy}}</td>
                                          <td>{{lunch_protein}}</td>
                                          <td>{{lunch_fat}}</td>
                                       </tr>
                                       <tr>
                                          <th>Carbohydrates(gm)</th>
                                          <th>Fiber(gm)</th>
                                          <th>Vitamin A(mg)</th>
                                       </tr>
                                       <tr>
                                          <td>{{lunch_carbohydrates}}</td>
                                          <td>{{lunch_fiber}}</td>
                                          <td>{{lunch_vitA}}</td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div [ngClass]="lunch_class">
                        <div class="cover_div">
                           <div class="content_cover">
                              <div style="float: right;">
                                 <img class="pencil_refresh_icon" (click)="edit_content('lunch')"
                                    src="../../../assets/ui_icons/diet_plan/pencil_icon.svg" [hidden]="client" />
                                 <img class="pencil_refresh_icon" (click)="clear_content('lunch')"
                                    src="../../../assets/ui_icons/diet_plan/refresh_icon.svg" [hidden]="client" />
                              </div>
                              <textarea class="ipcss widthappt result_area"
                                 style="overflow-y: scroll;margin: 15px 0 0 0; height: 239px !important; background: #eff8fd; border: none;"
                                 disabled [innerHTML]="dietlunch" (change)="send_data()"></textarea>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <!-- //evening Snacks -->
            <div class="tab-pane fade show" id="Evening" role="tabpanel" aria-labelledby="Evening-tab">
               <ul class="nav nav-tabs_week" id="myTab" role="tablist">
                  <li class="nav-item" role="presentation">
                     <button class="nav-link_week active" id="Sunday-tab" data-bs-toggle="tab" data-bs-target="#Sunday"
                        type="button" role="tab" aria-controls="Sunday" aria-selected="true" (click)="selectDay('Sunday','Evening')">Sunday</button>
                  </li>
                  <li class="nav-item" role="presentation">
                     <button class="nav-link_week" id="Monday-tab" data-bs-toggle="tab" data-bs-target="#Monday"
                        type="button" role="tab" aria-controls="Monday" aria-selected="false" (click)="selectDay('Monday','Evening')">Monday</button>
                  </li>
                  <li class="nav-item" role="presentation">
                     <button class="nav-link_week" id="Tuesday-tab" data-bs-toggle="tab" data-bs-target="#Tuesday" type="button"
                        role="tab" aria-controls="Tuesday" aria-selected="false" (click)="selectDay('Tuesday','Evening')">Tuesday</button>
                  </li>
                  <li class="nav-item" role="presentation">
                     <button class="nav-link_week" id="Wednesday-tab" data-bs-toggle="tab" data-bs-target="#Wednesday" type="button"
                        role="tab" aria-controls="Wednesday" aria-selected="false" (click)="selectDay('Wednesday','Evening')">Wednesday</button>
                  </li>
                  <li class="nav-item" role="presentation">
                     <button class="nav-link_week" id="Thursday-tab" data-bs-toggle="tab" data-bs-target="#Thursday" type="button"
                        role="tab" aria-controls="Thursday" aria-selected="false" (click)="selectDay('Thursday','Evening')">Thursday</button>
                  </li>
                  <li class="nav-item" role="presentation">
                     <button class="nav-link_week" id="Friday-tab" data-bs-toggle="tab" data-bs-target="#Friday" type="button"
                        role="tab" aria-controls="Friday" aria-selected="false" (click)="selectDay('Friday','Evening')">Friday</button>
                  </li>
                  <li class="nav-item" role="presentation">
                     <button class="nav-link_week" id="Saturday-tab" data-bs-toggle="tab" data-bs-target="#Saturday"
                        type="button" role="tab" aria-controls="Saturday" aria-selected="false" (click)="selectDay('Saturday','Evening')">Saturday</button>
                  </li>
               </ul>
               <div class="tab-content" id="myweekTabContent">
                  <div class="row" style="padding: 5px 0 5px 0;">
                     <div class="col-2">
                        <img *ngIf="eve_show_menu" (click)="selectMenu('Evening','menu',false)"
                           src="../../../assets/ui_icons/popup_tick_mark.svg" style="cursor: pointer;" width="12px" />
                        <img *ngIf="!eve_show_menu" (click)="selectMenu('Evening','menu',true)"
                           src="../../../assets/ui_icons/yellow_box.svg" style="cursor: pointer;" width="12px" />
                        &nbsp;Menu
                     </div>
                     <div class="col-2">
                        <img *ngIf="eve_show_non_menu" (click)="selectMenu('Evening','non-menu',false)"
                           src="../../../assets/ui_icons/popup_tick_mark.svg" style="cursor: pointer;" width="12px" />
                        <img *ngIf="!eve_show_non_menu" (click)="selectMenu('Evening','non-menu',true)"
                           src="../../../assets/ui_icons/yellow_box.svg" style="cursor: pointer;" width="12px" />
                        &nbsp;Non-menu
                     </div>
                  </div>
                  <div class="row" style="margin-top: 10px;">
                     <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" *ngIf="eve_option == 'non-menu'">
                        <div class="cover_div">
                           <div class="content_cover">
                              <div class="row">
                                 <div class="col-6">
                                    <mat-label class="matlabel">Category<br>
                                       <select [(ngModel)]="eve_catxt" id="eveCategory" (change)="food_catchange('eve')"
                                          required disableOptionCentering class="ipcss widthbillcreate">
                                          <option disabled>Select</option>
                                          <option *ngFor="let food of foot_cate" [value]="food.description">
                                             {{food.description}}
                                          </option>
                                       </select>
                                    </mat-label>
                                 </div>
                                 <div class="col-6">
                                    <mat-label class="matlabel">Food type<br>
                                       <select [(ngModel)]="eve_tpetxt" id="evefoodtype" class="ipcss widthbillcreate"
                                          required (change)="food_typechange(eve_tpetxt,'eve')" disableOptionCentering>
                                          <option disabled>Select</option>
                                          <option *ngFor="let food of eve_foot_type" [value]="food">{{food}}</option>
                                       </select>
                                    </mat-label>
                                 </div>
                                 <div class="col-6">
                                    <mat-label class="matlabel">Food sub type<br>
                                       <select [(ngModel)]="eve_subtxt" id="evesubfoodtype" class="ipcss widthbillcreate"
                                          (change)="food_subtypes(eve_subtxt,'eve')" disableOptionCentering required>
                                          <option disabled>Select</option>
                                          <option *ngFor="let food of eve_subfoot_type" [value]="food.description">
                                             {{food.description}}</option>
                                       </select>
                                    </mat-label>
                                 </div>
                                 <div class="col-6">
                                    <mat-label class="matlabel">Quantity<br>
                                       <select [(ngModel)]="eve_Quantity" id="eveQuantity" class="ipcss"
                                          (change)="Quantity_OnChange(eve_Quantity,'eve')" required>
                                          <option disabled>Select </option>
                                          <option>1</option>
                                          <option>2</option>
                                          <option>3</option>
                                          <option>4</option>
                                          <option>5</option>
                                          <option>6</option>
                                          <option>7</option>
                                          <option>8</option>
                                          <option>9</option>
                                          <option>10</option>
                                          <option>11</option>
                                          <option>12</option>
                                          <option>13</option>
                                          <option>14</option>
                                          <option>15</option>
                                          <option>16</option>
                                          <option>17</option>
                                          <option>20</option>
                                          <option>21</option>
                                          <option>22</option>
                                          <option>24</option>
                                          <option>26</option>
                                          <option>28</option>
                                          <option>30</option>
                                          <option>32</option>
                                          <option>40</option>
                                          <option>42</option>
                                          <option>48</option>
                                          <option>60</option>
                                          <option>1/8</option>
                                          <option>1/4</option>
                                          <option>1/3</option>
                                          <option>1/2</option>
                                          <option>3/4</option>
                                          <option>11/2</option>
                                          <option>21/2</option>
                                          <option>21/8</option>
                                          <option>91/2</option>
                                          <option>2/3</option>
                                          <option>41/4</option>
                                       </select>
                                    </mat-label>
                                 </div>
                                 <div class="col-6">
                                    <mat-label class="matlabel">Measure<br>
                                       <input type="text" name="name" id="formqnty" class="ipcss" [(ngModel)]="eve_Measure"
                                          minlength="3" required="" aria-required="true">
                                    </mat-label>
                                 </div>
                                 <div class="col-6">
                                    <mat-label class="matlabel">Weight<br>
                                       <input type="text" name="name" id="formqnty" class="ipcss" [(ngModel)]="eve_Weight"
                                          minlength="3" required="" aria-required="true">
                                    </mat-label>
                                 </div>
                                 <div class="col-6">
                                    <mat-label class="matlabel">Energy<br>
                                       <input type="text" name="name" id="formqnty" class="ipcss" [(ngModel)]="eve_Energy"
                                          minlength="3" required="" aria-required="true">
                                    </mat-label>
                                 </div>
                                 <div class="col-6">
                                    <mat-label class="matlabel">Condition<br>
                                       <select [(ngModel)]="eve_cond" class="ipcss widthbillcreate" disableOptionCentering>
                                          <option>Select</option>
                                          <option value="or">or</option>
                                          <option value="and">and</option>
                                          <option value="with">with</option>
                                       </select>
                                    </mat-label>
                                 </div>
                                 <div class="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12">
                                    <div [hidden]="!client" style="margin-bottom: 10px;"></div>
                                    <div [hidden]="client">
                                       <a><img (click)="diet_eveclick()"
                                             src="../../../assets/ui_icons/buttons/add_button.svg"
                                             class="saveimgbtn_inpatinfo" [hidden]="client" style="float: right;" /></a>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" *ngIf="eve_option == 'menu'">
                        <div class="row">
                           <div class="col-6">
                              <mat-label class="matlabel">Food type</mat-label>
                              <select class="ipcss" required [(ngModel)]="menu_eve_tpetxt"
                                 (change)="menu_change_food_type('Evening',menu_eve_tpetxt)">
                                 <option *ngFor="let food of menu_eve_foodType" value={{food.food_menu_id}}>{{food.name}}
                                 </option>
                              </select>
                           </div>
                           <div class="col-6">
                              <mat-label class="matlabel">Condition<br>
                                 <select [(ngModel)]="menu_eve_cond" class="ipcss widthbillcreate" disableOptionCentering>
                                    <!-- <option>Select</option> -->
                                    <option value="or">or</option>
                                    <option value="and">and</option>
                                    <option value="with">with</option>
                                 </select>
                              </mat-label>
                           </div>
                           <div class="col-12">
                              <div class="cover_div">
                                 <div class="content_cover">
                                    <div class="row">
                                       <div class="dig_table_overflow" style="border: none;">
                                          <table>
                                             <tr *ngFor="let subfood of menu_eve_subfoodType">
                                                <td class="align_left">
                                                   <img *ngIf="subfood.checked == true" (click)="subfood.checked = false;menu_change_sub_food_type('Evening',subfood,false)"
                                                      src="../../../assets/ui_icons/popup_tick_mark.svg"
                                                      style="cursor: pointer;position: relative; top: -5px;"
                                                      width="12px" />
                                                   <img *ngIf="subfood.checked == false" (click)="subfood.checked = true;menu_change_sub_food_type('Evening',subfood,true)"
                                                   src="../../../assets/ui_icons/yellow_box.svg"
                                                      style="cursor: pointer;position: relative; top: -5px;"
                                                      width="12px" />
                                                </td>
                                                <td class="align_left">
                                                   <mat-label class="ipcss">{{subfood.quantity}}&nbsp;{{subfood.measure}}
                                                   </mat-label>
                                                </td>
                                                <td class="align_left">
                                                   <mat-label class="ipcss">{{subfood.food_item}}</mat-label>
                                                </td>
                                             </tr>
                                          </table>
                                       </div>
                                       <div class="col-12">
                                          <a><img (click)="menu_diet_Add('Evening')" [hidden]="client"
                                                src="../../../assets/ui_icons/buttons/add_button.svg"
                                                class="saveimgbtn_inpatinfo" style="float: right;" /></a>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex align-items-end" *ngIf="eve_option == 'menu'">
                        <div class="cover_div" style="padding-bottom: 111px;">
                           <div class="content_cover">
                              <div class="row d-flex align-items-center" >
                                 <table class="col-12 tbl">
                                    <tbody>
                                       <tr>
                                          <td rowspan="8" style="width: 143px;"><img src={{eve_food_image}} style="width: 100px; height: auto;"></td>
                                       </tr>
                                       <tr>
                                          <th>Energy(kcal)</th>
                                          <th>Protien(gm)</th>
                                          <th>Fat(gm)</th>
                                       </tr>
                                       <tr>
                                          <td>{{eve_energy}}</td>
                                          <td>{{eve_protein}}</td>
                                          <td>{{eve_fat}}</td>
                                       </tr>
                                       <tr>
                                          <th>Carbohydrates(gm)</th>
                                          <th>Fiber(gm)</th>
                                          <th>Vitamin A(mg)</th>
                                       </tr>
                                       <tr>
                                          <td>{{eve_carbohydrates}}</td>
                                          <td>{{eve_fiber}}</td>
                                          <td>{{eve_vitA}}</td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div [ngClass]="eve_class">
                        <div class="cover_div">
                           <div class="content_cover">
                              <div style="float: right;">
                                 <img class="pencil_refresh_icon" (click)="edit_content('eve')"
                                    src="../../../assets/ui_icons/diet_plan/pencil_icon.svg" [hidden]="client" />
                                 <img class="pencil_refresh_icon" (click)="clear_content('eve')"
                                    src="../../../assets/ui_icons/diet_plan/refresh_icon.svg" [hidden]="client" />
                              </div>
                              <textarea class="ipcss widthappt result_area"
                                 style="overflow-y: scroll;margin: 15px 0 0 0; height: 239px !important; background: #eff8fd; border: none;"
                                 disabled [innerHTML]="dietevening" (change)="send_data()"></textarea>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <!-- //dinner Snacks -->
            <div class="tab-pane fade show" id="dinner" role="tabpanel" aria-labelledby="dinner-tab">
               <ul class="nav nav-tabs_week" id="myTab" role="tablist">
                  <li class="nav-item" role="presentation">
                     <button class="nav-link_week active" id="Sunday-tab" data-bs-toggle="tab" data-bs-target="#Sunday"
                        type="button" role="tab" aria-controls="Sunday" aria-selected="true" (click)="selectDay('Sunday','Dinner')">Sunday</button>
                  </li>
                  <li class="nav-item" role="presentation">
                     <button class="nav-link_week" id="Monday-tab" data-bs-toggle="tab" data-bs-target="#Monday"
                        type="button" role="tab" aria-controls="Monday" aria-selected="false" (click)="selectDay('Monday','Dinner')">Monday</button>
                  </li>
                  <li class="nav-item" role="presentation">
                     <button class="nav-link_week" id="Tuesday-tab" data-bs-toggle="tab" data-bs-target="#Tuesday" type="button"
                        role="tab" aria-controls="Tuesday" aria-selected="false" (click)="selectDay('Tuesday','Dinner')">Tuesday</button>
                  </li>
                  <li class="nav-item" role="presentation">
                     <button class="nav-link_week" id="Wednesday-tab" data-bs-toggle="tab" data-bs-target="#Wednesday" type="button"
                        role="tab" aria-controls="Wednesday" aria-selected="false" (click)="selectDay('Wednesday','Dinner')">Wednesday</button>
                  </li>
                  <li class="nav-item" role="presentation">
                     <button class="nav-link_week" id="Thursday-tab" data-bs-toggle="tab" data-bs-target="#Thursday" type="button"
                        role="tab" aria-controls="Thursday" aria-selected="false" (click)="selectDay('Thursday','Dinner')">Thursday</button>
                  </li>
                  <li class="nav-item" role="presentation">
                     <button class="nav-link_week" id="Friday-tab" data-bs-toggle="tab" data-bs-target="#Friday" type="button"
                        role="tab" aria-controls="Friday" aria-selected="false" (click)="selectDay('Friday','Dinner')">Friday</button>
                  </li>
                  <li class="nav-item" role="presentation">
                     <button class="nav-link_week" id="Saturday-tab" data-bs-toggle="tab" data-bs-target="#Saturday"
                        type="button" role="tab" aria-controls="Saturday" aria-selected="false" (click)="selectDay('Saturday','Dinner')">Saturday</button>
                  </li>
               </ul>
               <div class="tab-content" id="myweekTabContent">
                  <div class="row" style="padding: 5px 0 5px 0;">
                     <div class="col-2">
                        <img *ngIf="dinner_show_menu" (click)="selectMenu('Dinner','menu',false)"
                           src="../../../assets/ui_icons/popup_tick_mark.svg" style="cursor: pointer;" width="12px" />
                        <img *ngIf="!dinner_show_menu" (click)="selectMenu('Dinner','menu',true)"
                           src="../../../assets/ui_icons/yellow_box.svg" style="cursor: pointer;" width="12px" />
                        &nbsp;Menu
                     </div>
                     <div class="col-2">
                        <img *ngIf="dinner_show_non_menu" (click)="selectMenu('Dinner','non-menu',false)"
                           src="../../../assets/ui_icons/popup_tick_mark.svg" style="cursor: pointer;" width="12px" />
                        <img *ngIf="!dinner_show_non_menu" (click)="selectMenu('Dinner','non-menu',true)"
                           src="../../../assets/ui_icons/yellow_box.svg" style="cursor: pointer;" width="12px" />
                        &nbsp;Non-menu
                     </div>
                  </div>
                  <div class="row" style="margin-top: 10px;">
                     <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" *ngIf="dinner_option == 'non-menu'">
                        <div class="cover_div">
                           <div class="content_cover">
                              <div class="row">
                                 <div class="col-6">
                                    <mat-label class="matlabel">Category<br>
                                       <select [(ngModel)]="dinner_catxt" id="dinnerCategory"
                                          (change)="food_catchange('dinner')" required disableOptionCentering
                                          class="ipcss widthbillcreate">
                                          <option disabled>Select</option>
                                          <option *ngFor="let food of foot_cate" [value]="food.description">
                                             {{food.description}}
                                          </option>
                                       </select>
                                    </mat-label>
                                 </div>
                                 <div class="col-6">
                                    <mat-label class="matlabel">Food type<br>
                                       <select [(ngModel)]="dinner_tpetxt" id="dinnerfoodtype"
                                          class="ipcss widthbillcreate" required
                                          (change)="food_typechange(dinner_tpetxt,'dinner')" disableOptionCentering>
                                          <option disabled>Select</option>
                                          <option *ngFor="let food of dinner_foot_type" [value]="food">{{food}}</option>
                                       </select>
                                    </mat-label>
                                 </div>
                                 <div class="col-6">
                                    <mat-label class="matlabel">Food sub type<br>
                                       <select [(ngModel)]="dinner_subtxt" id="dinnersubfoodtype"
                                          class="ipcss widthbillcreate" (change)="food_subtypes(dinner_subtxt,'dinner')"
                                          disableOptionCentering required>
                                          <option disabled>Select</option>
                                          <option *ngFor="let food of dinner_subfoot_type" [value]="food.description">
                                             {{food.description}}</option>
                                       </select>
                                    </mat-label>
                                 </div>
                                 <div class="col-6">
                                    <mat-label class="matlabel">Quantity<br>
                                       <select [(ngModel)]="dinner_Quantity" id="dinnerQuantity" class="ipcss"
                                          (change)="Quantity_OnChange(dinner_Quantity,'dinner')" required>
                                          <option disabled>Select </option>
                                          <option>1</option>
                                          <option>2</option>
                                          <option>3</option>
                                          <option>4</option>
                                          <option>5</option>
                                          <option>6</option>
                                          <option>7</option>
                                          <option>8</option>
                                          <option>9</option>
                                          <option>10</option>
                                          <option>11</option>
                                          <option>12</option>
                                          <option>13</option>
                                          <option>14</option>
                                          <option>15</option>
                                          <option>16</option>
                                          <option>17</option>
                                          <option>20</option>
                                          <option>21</option>
                                          <option>22</option>
                                          <option>24</option>
                                          <option>26</option>
                                          <option>28</option>
                                          <option>30</option>
                                          <option>32</option>
                                          <option>40</option>
                                          <option>42</option>
                                          <option>48</option>
                                          <option>60</option>
                                          <option>1/8</option>
                                          <option>1/4</option>
                                          <option>1/3</option>
                                          <option>1/2</option>
                                          <option>3/4</option>
                                          <option>11/2</option>
                                          <option>21/2</option>
                                          <option>21/8</option>
                                          <option>91/2</option>
                                          <option>2/3</option>
                                          <option>41/4</option>
                                       </select>
                                    </mat-label>
                                 </div>
                                 <div class="col-6">
                                    <mat-label class="matlabel">Measure<br>
                                       <input type="text" name="name" required id="formqnty" class="ipcss"
                                          [(ngModel)]="dinner_Measure" minlength="3" aria-required="true">
                                    </mat-label>
                                 </div>
                                 <div class="col-6">
                                    <mat-label class="matlabel">Weight<br>
                                       <input type="text" name="name" required id="formqnty" class="ipcss"
                                          [(ngModel)]="dinner_Weight" minlength="3" aria-required="true">
                                    </mat-label>
                                 </div>
                                 <div class="col-6">
                                    <mat-label class="matlabel">Energy<br>
                                       <input type="text" name="name" required id="formqnty" class="ipcss"
                                          [(ngModel)]="dinner_Energy" minlength="3" aria-required="true">
                                    </mat-label>
                                 </div>
                                 <div class="col-6">
                                    <mat-label class="matlabel">Condition<br>
                                       <select [(ngModel)]="dinner_cond" class="ipcss widthbillcreate"
                                          disableOptionCentering>
                                          <option>Select</option>
                                          <option value="or">or</option>
                                          <option value="and">and</option>
                                          <option value="with">with</option>
                                       </select>
                                    </mat-label>
                                 </div>
                                 <div class="col-12">
                                    <div [hidden]="!client" style="margin-bottom: 10px;"></div>
                                    <div [hidden]="client">
                                       <a><img (click)="diet_dinnerclick()"
                                             src="../../../assets/ui_icons/buttons/add_button.svg"
                                             class="saveimgbtn_inpatinfo" [hidden]="client" style="float: right;" /></a>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" *ngIf="dinner_option == 'menu'">
                        <div class="row">
                           <div class="col-6">
                              <mat-label class="matlabel">Food type</mat-label>
                              <select class="ipcss" required [(ngModel)]="menu_dinner_tpetxt"
                                 (change)="menu_change_food_type('Dinner',menu_dinner_tpetxt)">
                                 <option *ngFor="let food of menu_dinner_foodType" value={{food.food_menu_id}}>{{food.name}}
                                 </option>
                              </select>
                           </div>
                           <div class="col-6">
                              <mat-label class="matlabel">Condition<br>
                                 <select [(ngModel)]="menu_dinner_cond" class="ipcss widthbillcreate" disableOptionCentering>
                                    <!-- <option>Select</option> -->
                                    <option value="or">or</option>
                                    <option value="and">and</option>
                                    <option value="with">with</option>
                                 </select>
                              </mat-label>
                           </div>
                           <div class="col-12">
                              <div class="cover_div">
                                 <div class="content_cover">
                                    <div class="row">
                                       <div class="dig_table_overflow" style="border: none;">
                                          <table>
                                             <tr *ngFor="let subfood of menu_dinner_subfoodType">
                                                <td class="align_left">
                                                   <img *ngIf="subfood.checked == true" (click)="subfood.checked = false;menu_change_sub_food_type('Dinner',subfood,false)"
                                                      src="../../../assets/ui_icons/popup_tick_mark.svg"
                                                      style="cursor: pointer;position: relative; top: -5px;"
                                                      width="12px" />
                                                   <img *ngIf="subfood.checked == false" (click)="subfood.checked = true;menu_change_sub_food_type('Dinner',subfood,true)"
                                                   src="../../../assets/ui_icons/yellow_box.svg"
                                                      style="cursor: pointer;position: relative; top: -5px;"
                                                      width="12px" />
                                                </td>
                                                <td class="align_left">
                                                   <mat-label class="ipcss">{{subfood.quantity}}&nbsp;{{subfood.measure}}
                                                   </mat-label>
                                                </td>
                                                <td class="align_left">
                                                   <mat-label class="ipcss">{{subfood.food_item}}</mat-label>
                                                </td>
                                             </tr>
                                          </table>
                                       </div>
                                       <div class="col-12">
                                          <a><img (click)="menu_diet_Add('Dinner')" [hidden]="client"
                                                src="../../../assets/ui_icons/buttons/add_button.svg"
                                                class="saveimgbtn_inpatinfo" style="float: right;" /></a>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex align-items-end" *ngIf="dinner_option == 'menu'">
                        <div class="cover_div" style="padding-bottom: 111px;">
                           <div class="content_cover">
                              <div class="row d-flex align-items-center" >
                                 <table class="col-12 tbl">
                                    <tbody>
                                       <tr>
                                          <td rowspan="8" style="width: 143px;"><img src={{dinner_food_image}} style="width: 100px; height: auto;"></td>
                                       </tr>
                                       <tr>
                                          <th>Energy(kcal)</th>
                                          <th>Protien(gm)</th>
                                          <th>Fat(gm)</th>
                                       </tr>
                                       <tr>
                                          <td>{{dinner_energy}}</td>
                                          <td>{{dinner_protein}}</td>
                                          <td>{{dinner_fat}}</td>
                                       </tr>
                                       <tr>
                                          <th>Carbohydrates(gm)</th>
                                          <th>Fiber(gm)</th>
                                          <th>Vitamin A(mg)</th>
                                       </tr>
                                       <tr>
                                          <td>{{dinner_carbohydrates}}</td>
                                          <td>{{dinner_fiber}}</td>
                                          <td>{{dinner_vitA}}</td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div [ngClass]="dinner_class">
                        <div class="cover_div">
                           <div class="content_cover">
                              <div style="float: right;">
                                 <img class="pencil_refresh_icon" (click)="edit_content('dinner')"
                                    src="../../../assets/ui_icons/diet_plan/pencil_icon.svg" [hidden]="client" />
                                 <img class="pencil_refresh_icon" (click)="clear_content('dinner')"
                                    src="../../../assets/ui_icons/diet_plan/refresh_icon.svg" [hidden]="client" />
                              </div>
                              <textarea class="ipcss widthappt result_area"
                                 style="overflow-y: scroll;margin: 15px 0 0 0; height: 239px !important; background: #eff8fd; border: none;"
                                 disabled [innerHTML]="dietdinner" (change)="send_data()"></textarea>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <!-- //night Snacks -->
            <div class="tab-pane fade show" id="night_snacks" role="tabpanel" aria-labelledby="night_snacks-tab">
               <ul class="nav nav-tabs_week" id="myTab" role="tablist">
                  <li class="nav-item" role="presentation">
                     <button class="nav-link_week active" id="Sunday-tab" data-bs-toggle="tab" data-bs-target="#Sunday"
                        type="button" role="tab" aria-controls="Sunday" aria-selected="true" (click)="selectDay('Sunday','Night')">Sunday</button>
                  </li>
                  <li class="nav-item" role="presentation">
                     <button class="nav-link_week" id="Monday-tab" data-bs-toggle="tab" data-bs-target="#Monday"
                        type="button" role="tab" aria-controls="Monday" aria-selected="false" (click)="selectDay('Monday','Night')">Monday</button>
                  </li>
                  <li class="nav-item" role="presentation">
                     <button class="nav-link_week" id="Tuesday-tab" data-bs-toggle="tab" data-bs-target="#Tuesday" type="button"
                        role="tab" aria-controls="Tuesday" aria-selected="false" (click)="selectDay('Tuesday','Night')">Tuesday</button>
                  </li>
                  <li class="nav-item" role="presentation">
                     <button class="nav-link_week" id="Wednesday-tab" data-bs-toggle="tab" data-bs-target="#Wednesday" type="button"
                        role="tab" aria-controls="Wednesday" aria-selected="false" (click)="selectDay('Wednesday','Night')">Wednesday</button>
                  </li>
                  <li class="nav-item" role="presentation">
                     <button class="nav-link_week" id="Thursday-tab" data-bs-toggle="tab" data-bs-target="#Thursday" type="button"
                        role="tab" aria-controls="Thursday" aria-selected="false" (click)="selectDay('Thursday','Night')">Thursday</button>
                  </li>
                  <li class="nav-item" role="presentation">
                     <button class="nav-link_week" id="Friday-tab" data-bs-toggle="tab" data-bs-target="#Friday" type="button"
                        role="tab" aria-controls="Friday" aria-selected="false" (click)="selectDay('Friday','Night')">Friday</button>
                  </li>
                  <li class="nav-item" role="presentation">
                     <button class="nav-link_week" id="Saturday-tab" data-bs-toggle="tab" data-bs-target="#Saturday"
                        type="button" role="tab" aria-controls="Saturday" aria-selected="false" (click)="selectDay('Saturday','Night')">Saturday</button>
                  </li>
               </ul>
               <div class="tab-content" id="myweekTabContent">
                  <div class="row" style="padding: 5px 0 5px 0;">
                     <div class="col-2">
                        <img *ngIf="night_show_menu" (click)="selectMenu('Night','menu',false)"
                           src="../../../assets/ui_icons/popup_tick_mark.svg" style="cursor: pointer;" width="12px" />
                        <img *ngIf="!night_show_menu" (click)="selectMenu('Night','menu',true)"
                           src="../../../assets/ui_icons/yellow_box.svg" style="cursor: pointer;" width="12px" />
                        &nbsp;Menu
                     </div>
                     <div class="col-2">
                        <img *ngIf="night_show_non_menu" (click)="selectMenu('Night','non-menu',false)"
                           src="../../../assets/ui_icons/popup_tick_mark.svg" style="cursor: pointer;" width="12px" />
                        <img *ngIf="!night_show_non_menu" (click)="selectMenu('Night','non-menu',true)"
                           src="../../../assets/ui_icons/yellow_box.svg" style="cursor: pointer;" width="12px" />
                        &nbsp;Non-menu
                     </div>
                  </div>
                  <div class="row" style="margin-top: 10px;">
                     <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" *ngIf="night_option == 'non-menu'">
                        <div class="cover_div">
                           <div class="content_cover">
                              <div class="row">
                                 <div class="col-6">
                                    <mat-label class="matlabel">Category<br>
                                       <select [(ngModel)]="night_catxt" id="nightCategory"
                                          (change)="food_catchange('night')" required disableOptionCentering
                                          class="ipcss widthbillcreate">
                                          <option disabled>Select</option>
                                          <option *ngFor="let food of foot_cate" [value]="food.description">
                                             {{food.description}}
                                          </option>
                                       </select>
                                    </mat-label>
                                 </div>
                                 <div class="col-6">
                                    <mat-label class="matlabel">Food type<br>
                                       <select [(ngModel)]="night_tpetxt" id="nightfoodtype" class="ipcss widthbillcreate"
                                          required (change)="food_typechange(night_tpetxt,'night')" disableOptionCentering>
                                          <option disabled>Select</option>
                                          <option *ngFor="let food of night_foot_type" [value]="food">{{food}}</option>
                                       </select>
                                    </mat-label>
                                 </div>
                                 <div class="col-6">
                                    <mat-label class="matlabel">Food sub type<br>
                                       <select [(ngModel)]="night_subtxt" id="nightsubfoodtype"
                                          class="ipcss widthbillcreate" (change)="food_subtypes(night_subtxt,'night')"
                                          disableOptionCentering required>
                                          <option disabled>Select</option>
                                          <option *ngFor="let food of night_subfoot_type" [value]="food.description">
                                             {{food.description}}</option>
                                       </select>
                                    </mat-label>
                                 </div>
                                 <div class="col-6">
                                    <mat-label class="matlabel">Quantity<br>
                                       <select [(ngModel)]="night_Quantity" id="nightQuantity" class="ipcss"
                                          (change)="Quantity_OnChange(night_Quantity,'night')" required>
                                          <option disabled>Select </option>
                                          <option>1</option>
                                          <option>2</option>
                                          <option>3</option>
                                          <option>4</option>
                                          <option>5</option>
                                          <option>6</option>
                                          <option>7</option>
                                          <option>8</option>
                                          <option>9</option>
                                          <option>10</option>
                                          <option>11</option>
                                          <option>12</option>
                                          <option>13</option>
                                          <option>14</option>
                                          <option>15</option>
                                          <option>16</option>
                                          <option>17</option>
                                          <option>20</option>
                                          <option>21</option>
                                          <option>22</option>
                                          <option>24</option>
                                          <option>26</option>
                                          <option>28</option>
                                          <option>30</option>
                                          <option>32</option>
                                          <option>40</option>
                                          <option>42</option>
                                          <option>48</option>
                                          <option>60</option>
                                          <option>1/8</option>
                                          <option>1/4</option>
                                          <option>1/3</option>
                                          <option>1/2</option>
                                          <option>3/4</option>
                                          <option>11/2</option>
                                          <option>21/2</option>
                                          <option>21/8</option>
                                          <option>91/2</option>
                                          <option>2/3</option>
                                          <option>41/4</option>
                                       </select>
                                    </mat-label>
                                 </div>
                                 <div class="col-6">
                                    <mat-label class="matlabel">Measure<br>
                                       <input type="text" name="name" id="formqnty" class="ipcss"
                                          [(ngModel)]="night_Measure" minlength="3" required="" aria-required="true">
                                    </mat-label>
                                 </div>
                                 <div class="col-6">
                                    <mat-label class="matlabel">Weight<br>
                                       <input type="text" name="name" id="formqnty" class="ipcss"
                                          [(ngModel)]="night_Weight" minlength="3" required="" aria-required="true">
                                    </mat-label>
                                 </div>
                                 <div class="col-6">
                                    <mat-label class="matlabel">Energy<br>
                                       <input type="text" name="name" id="formqnty" class="ipcss"
                                          [(ngModel)]="night_Energy" minlength="3" required="" aria-required="true">
                                    </mat-label>
                                 </div>
                                 <div class="col-6">
                                    <mat-label class="matlabel">Condition<br>
                                       <select [(ngModel)]="night_cond" class="ipcss widthbillcreate"
                                          disableOptionCentering>
                                          <option>Select</option>
                                          <option value="or">or</option>
                                          <option value="and">and</option>
                                          <option value="with">with</option>
                                       </select>
                                    </mat-label>
                                 </div>
                                 <div class="col-12">
                                    <div [hidden]="!client" style="margin-bottom: 10px;"></div>
                                    <div [hidden]="client">
                                       <a><img (click)="diet_nightclick()"
                                             src="../../../assets/ui_icons/buttons/add_button.svg"
                                             class="saveimgbtn_inpatinfo" [hidden]="client" style="float: right;" /></a>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" *ngIf="night_option == 'menu'">
                        <div class="row">
                           <div class="col-6">
                              <mat-label class="matlabel">Food type</mat-label>
                              <select class="ipcss" required [(ngModel)]="menu_night_tpetxt"
                                 (change)="menu_change_food_type('Night',menu_night_tpetxt)">
                                 <option *ngFor="let food of menu_night_foodType" value={{food.food_menu_id}}>{{food.name}}
                                 </option>
                              </select>
                           </div>
                           <div class="col-6">
                              <mat-label class="matlabel">Condition<br>
                                 <select [(ngModel)]="menu_night_cond" class="ipcss widthbillcreate" disableOptionCentering>
                                    <!-- <option>Select</option> -->
                                    <option value="or">or</option>
                                    <option value="and">and</option>
                                    <option value="with">with</option>
                                 </select>
                              </mat-label>
                           </div>
                           <div class="col-12">
                              <div class="cover_div">
                                 <div class="content_cover">
                                    <div class="row">
                                       <div class="dig_table_overflow" style="border: none;">
                                          <table>
                                             <tr *ngFor="let subfood of menu_night_subfoodType">
                                                <td class="align_left">
                                                   <img *ngIf="subfood.checked == true" (click)="subfood.checked = false;menu_change_sub_food_type('Night',subfood,false)"
                                                      src="../../../assets/ui_icons/popup_tick_mark.svg"
                                                      style="cursor: pointer;position: relative; top: -5px;"
                                                      width="12px" />
                                                   <img *ngIf="subfood.checked == false" (click)="subfood.checked = true;menu_change_sub_food_type('Night',subfood,true)"
                                                   src="../../../assets/ui_icons/yellow_box.svg"
                                                      style="cursor: pointer;position: relative; top: -5px;"
                                                      width="12px" />
                                                </td>
                                                <td class="align_left">
                                                   <mat-label class="ipcss">{{subfood.quantity}}&nbsp;{{subfood.measure}}
                                                   </mat-label>
                                                </td>
                                                <td class="align_left">
                                                   <mat-label class="ipcss">{{subfood.food_item}}</mat-label>
                                                </td>
                                             </tr>
                                          </table>
                                       </div>
                                       <div class="col-12">
                                          <a><img (click)="menu_diet_Add('Night')" [hidden]="client"
                                                src="../../../assets/ui_icons/buttons/add_button.svg"
                                                class="saveimgbtn_inpatinfo" style="float: right;" /></a>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex align-items-end" *ngIf="night_option == 'menu'">
                        <div class="cover_div" style="padding-bottom: 111px;">
                           <div class="content_cover">
                              <div class="row d-flex align-items-center" >
                                 <table class="col-12 tbl">
                                    <tbody>
                                       <tr>
                                          <td rowspan="8" style="width: 143px;"><img src={{night_food_image}} style="width: 100px; height: auto;"></td>
                                       </tr>
                                       <tr>
                                          <th>Energy(kcal)</th>
                                          <th>Protien(gm)</th>
                                          <th>Fat(gm)</th>
                                       </tr>
                                       <tr>
                                          <td>{{night_energy}}</td>
                                          <td>{{night_protein}}</td>
                                          <td>{{night_fat}}</td>
                                       </tr>
                                       <tr>
                                          <th>Carbohydrates(gm)</th>
                                          <th>Fiber(gm)</th>
                                          <th>Vitamin A(mg)</th>
                                       </tr>
                                       <tr>
                                          <td>{{night_carbohydrates}}</td>
                                          <td>{{night_fiber}}</td>
                                          <td>{{night_vitA}}</td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div [ngClass]="night_class">
                        <div class="cover_div">
                           <div class="content_cover">
                              <div style="float: right;">
                                 <img class="pencil_refresh_icon" (click)="edit_content('night')"
                                    src="../../../assets/ui_icons/diet_plan/pencil_icon.svg" [hidden]="client" />
                                 <img class="pencil_refresh_icon" (click)="clear_content('night')"
                                    src="../../../assets/ui_icons/diet_plan/refresh_icon.svg" [hidden]="client" />
                              </div>
                              <textarea class="ipcss widthappt result_area"
                                 style="overflow-y: scroll;margin: 15px 0 0 0; height: 239px !important; background: #eff8fd; border: none;"
                                 disabled [innerHTML]="dietnight" (change)="send_data()"></textarea>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <a (click)="dietplanhistory('diettracker')"><img src="../../../assets/ui_icons/buttons/history.svg"
               class="saveimgbtn_inpatinfo" /></a>
         <a (click)="dietplanhistory('patient')"><img src="../../../assets/ui_icons/buttons/wrong.svg"
               class="saveimgbtn_inpatinfo" /></a>
      </div>
   </div>
</div>