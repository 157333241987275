<div class="row" style="width:100%;margin:0 auto;">
    <div class="col-12">
      <mdb-card>
        <mdb-card-header class="bg-white" style="border-bottom: 0 !important;" >
          <div class="headerCover">
            <div class="headerTilte app_list_header">
              <h5 style="color:black" class="m-0" class="mainHeadingStyle">Bill details</h5>
            </div>
            <div class="headerButtons">
              <a class="print_area" *ngIf="printFlag"><button printSectionId="print" (click)="print()" [useExistingCss]="true" ngxPrint
                  class="borderdiv billdetailviewleft" mat-icon-button>
                  <img src="../../../assets/img/printer.svg" style="width: 25px !important;height: 25px !important;"
                    class="printimagecss" /></button></a>
              <a (click)="back()"><img src="../../../assets/ui_icons/buttons/Back_button.svg"
                  class="saveimgbtn_inpatinfo" /></a>
            </div>
          </div>
        </mdb-card-header>
        <mdb-card-body>
          <div *ngFor="let billprint of billDetailedViewData">
            <div class="row">
              <div class="col-12 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                <div [hidden]="hospAddressFlag">
                  <table>
                    <tr>
                      <td style="text-align: left !important;font-size: 12px;"><b>{{billprint.hptl_name}}</b></td>
                    </tr>
                    <tr>
                      <td class="changefont">{{billprint.address}}</td>
                    </tr>
                    <tr>
                      <td class="changefont">{{billprint.location}}{{billprint.city}}</td>
                    </tr>
                    <tr>
                      <td class="changefont">{{billprint.state}}</td>
                    </tr>
                    <tr>
                      <td class="changefont"> <img class="phone_img" height="15px" width="15px"
                          src="../../../assets/img/phone.png" /> {{billprint.telephone}}</td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                <table>
                  <tr>
                    <th style="text-align: left !important;font-size: 12px;">Bill date : </th>
                    <td>
                      {{billprint.created_date}}
                    </td>
                  </tr>
                  <tr [hidden]="mainBalanceFlag">
                    <th style="text-align: left !important;font-size: 12px;">Bill amount (र) :</th>
                    <td style="text-align: left !important;font-size: 12px;">
                      {{billAmount}}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="row chwidth">
              <div class="col-12 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                <p class="col-12 col-md-6 col-lg-3 col-xl-3 col-sm-12" class="tervys_heading ">Patient details</p>
                <table>
                  <tr>
                    <th style="text-align: left !important;font-size: 12px;" class="thvalue">Patient name : </th>
                    <td style="text-align: left;font-size: 12px;">
                      {{billprint.client_name}}
                    </td>
                  </tr>
                  <tr>
                    <th style="text-align: left !important;font-size: 12px;
                    " class="thvalue">Patient id : </th>
                    <td style="text-align: left;font-size: 12px;">
                      {{billprint.client_reg_id}}
                    </td>
                  </tr>
                </table>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                <p class="col-12 col-md-6 col-lg-3 col-xl-3 col-sm-12" class="tervys_heading">Address</p>
                <table>
                  <tr>
                    <td class="thvalue" style="font-size: 12px;">{{billprint.caddress}}</td>
                  </tr>
                  <tr>
                    <td class="thvalue" style="font-size: 12px;">{{billprint.clocation}}{{ billprint.clientcity}}</td>
                  </tr>
                  <tr>
                    <td class="thvalue" style="font-size: 12px;">{{billprint.clestare}}</td>
                  </tr>
                  <tr>
                    <td class="thvalue" style="font-size: 12px;">{{billprint.ccountry}}</td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="row">
              <div class="col-12" [hidden]="billingFlag">
                <p class=" col-md-3 col-lg-3 col-xl-3 col-sm-3" *ngIf="billingPrintView.length !=0"
                  class="tervys_heading" style="font-weight: 500;">Bill particulars</p>
              </div>
              <div class="table-responsive" *ngIf="billList.length">
                <table id="card_tbl" class="table table-nowrap table-sm">
                  <thead>
                    <tr style="height: 30px;">
                      <th>Service type</th>
                      <th>Payment type</th>
                      <th>Fee</th>
                      <th>Concession (%)</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr style="height: 30px;" *ngFor="let person of billList">
                      <td style="font-size: 12px;" class="txtalign">{{person.charge_desc}}</td>
                      <td style="font-size: 12px;" class="txtalign">{{person.pay_type}}</td>
                      <td style="font-size: 12px;" class="txtalignright">{{person.fee}}</td>
                      <td style="font-size: 12px;" class="txtalignright">{{person.concession}}</td>
                      <td class="billlistleft" style="font-size: 12px;" class="txtalignright">{{person.fee}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div [hidden]="treatmentPlanFlag">
              <p class="casesheet_para">Treatement plan</p>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" [hidden]="treatmentPlanFlag">
              <table mdbTable datatable [dtOptions]="dtOptions" class="table table-nowrap table-sm dataTable">
                <thead>
                  <tr>
                    <th>Plan</th>
                    <th class="tbl_txt_center">Estimate</th>
                    <th class="tbl_txt_center">Select</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let person of estimatePrintData let i = index">
                    <td>{{person.treatment_desc}}</td>
                    <td class="tbl_txt_center">{{person.estimate}}</td>
                  </tr>
                </tbody>
              </table>
              <br><br><br>
            </div>
            <div class="row">
              <div class="col-4 col-sm-4 col-md-8 col-lg-8 col-xl-8"></div>
              <div class="col-8 col-sm-8 col-md-4 col-lg-4 col-xl-4" style="padding-top: 20px;padding-right: 19px;">
                <div class="row">
                  <div class="col-8 col-sm-8 col-md-6 col-lg-8 col-xl-8 alignRight" [hidden]="totalFlag">
                    <mat-label class="matlabel ">Total र </mat-label>
                  </div>
                  <div class="col-4 col-sm-4 col-md-5 col-lg-4 col-xl-4" [hidden]="totalFlag">
                    <p class="txtalignright"><span class="">{{totalAmount}}</span></p>
                  </div>
                  <div class="col-8 col-sm-8 col-md-6 col-lg-8 col-xl-8  alignRight" [hidden]="cgstFlag">
                    <mat-label class="matlabel">Central GST {{billprint.cgst}}% </mat-label>
                  </div>
                  <div class="col-4 col-sm-4 col-md-5 col-lg-4 col-xl-4" [hidden]="cgstFlag">
                    <p class="txtalignright"><span class="">{{cgst}} </span></p>
                  </div>
                  <div class="col-8 col-sm-8 col-md-6 col-lg-8 col-xl-8  alignRight" [hidden]="sgstFlag">
                    <mat-label class="matlabel">State GST {{billprint.cgst}} %</mat-label>
                  </div>
                  <div class="col-4 col-sm-4 col-md-5 col-lg-4 col-xl-4" [hidden]="sgstFlag">
                    <p class="txtalignright"><span class="">{{sgst}} </span></p>
                  </div>
                  <div class="col-8 col-sm-8 col-md-6 col-lg-8 col-xl-8  alignRight" [hidden]="prevBalanceFlag">
                    <mat-label class="matlabel"> Previous balance र</mat-label>
                  </div>
                  <div class="col-4 col-sm-4 col-md-5 col-lg-4 col-xl-4" [hidden]="prevBalanceFlag">
                    <p class="txtalignright"><span class="">{{prevBalance}}</span></p>
                  </div>
                  <div class="col-8 col-sm-8 col-md-6 col-lg-8 col-xl-8  alignRight" [hidden]="totalFlag">
                    <mat-label class="matlabel "> Bill amount र</mat-label>
                  </div>
                  <div class="col-4 col-sm-4 col-md-5 col-lg-4 col-xl-4" [hidden]="totalFlag">
                    <p class="txtalignright"><span class="">{{billAmount}} </span></p>
                  </div>
                  <div class="col-8 col-sm-8 col-md-6 col-lg-8 col-xl-8  alignRight" [hidden]="totalFlag">
                    <mat-label class="matlabel "> Round off र</mat-label>
                  </div>
                  <div class="col-4 col-sm-4 col-md-5 col-lg-4 col-xl-4" [hidden]="totalFlag">
                    <p class="txtalignright"><span class="">{{roundoff}} </span></p>
                  </div>
                  <div class="col-8 col-sm-8 col-md-6 col-lg-8 col-xl-8  alignRight" [hidden]="advanceFlag">
                    <mat-label class="matlabel">Advance  र</mat-label>
                  </div>
                  <div class="col-4 col-sm-4 col-md-5 col-lg-4 col-xl-4" [hidden]="advanceFlag">
                    <p class="txtalignright"><span class="">{{billprint.advance}} </span></p>
                  </div>
                  <div class="col-8 col-sm-8 col-md-6 col-lg-8 col-xl-8  alignRight" [hidden]="advanceFlag">
                    <mat-label class="matlabel">Paid र</mat-label>
                  </div>
                  <div class="col-4 col-sm-4 col-md-5 col-lg-4 col-xl-4" [hidden]="advanceFlag">
                    <p class="txtalignright"><span class="">{{paid}} </span></p>
                  </div>
                  <div class="col-8 col-sm-8 col-md-6 col-lg-8 col-xl-8  alignRight">
                    <mat-label class="matlabel">Balance र </mat-label>
                  </div>
                  <div class="col-4 col-sm-4 col-md-5 col-lg-4 col-xl-4">
                    <p class="txtalignright"><span class="">{{billprint.balance}}</span></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div [hidden]="true">
            <div id="bill-print-section">
              <div class="bill_border">
                <div *ngFor="let billprint of billDetailedViewData">
                  <table style="width: 82%;margin-left: auto;margin-right: auto; margin-top: 100px;">
                    <td>
                      <div [hidden]="hospAddressFlag">
                        <span><strong>{{billprint.hptl_name}}</strong>
                        </span>
                        <br />
                        <span *ngIf="billprint.address != ''">{{billprint.address}}</span>
                        <br />
                        <span>{{billprint.location}}{{billprint.city}}</span>
                        <br />
                        <span>{{billprint.state}}</span>
                        <br />
                        <span>
                          <img src="../../../assets/img/phone.png" width="15px" height="15px" />
                          {{billprint.telephone}}
                        </span>
                      </div>
                    </td>
                    <td style="width: 60px;"></td>
                    <td valign="top" style="width: 180px; float: right;margin-right: -55px;">
                      <span><strong>Bill date : </strong>{{billprint.created_date}} </span>
                      <br />
                      <span [hidden]="mainBalanceFlag"><strong>Bill amount र :</strong>
                        {{billprint.tot_amt}}</span>
                      <br />
                    </td>
                  </table>
                  <br />
                  <table style="margin-left: 75px;">
                    <tr>
                      <td>
                        <h3 class="heading">Patient details</h3>
                        <div class="row">
                          <div class=" col-12 col-md-7 col-lg-8 col-xl-8">
                            <span><strong>Patient name : </strong>{{billprint.client_name}} </span>
                            <br />
                            <span><strong> Patient id : </strong>{{billprint.client_reg_id}}</span>
                          </div>
                          <div class="amount_duecol-12 col-md-5 col-lg-4 col-xl-4">
                            <span><strong><br />Address</strong></span>
                            <br />
                            <span> {{billprint.caddress}}</span>
                            <br />
                            <span>{{billprint.clocation}}{{ billprint.clientcity}}</span>
                            <br />
                            <span>{{billprint.clestare}}</span>
                            <br />
                            <span>{{billprint.ccountry}}</span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                  <div [hidden]="billingFlag" style="margin-left: 75px; width: 800px;">
                    <h3 class="heading">Bill particulars</h3>
                    <div class="row">
                      <table style=" border: 1px solid black;border-collapse: collapse;margin-right: 20px;">
                        <tr>
                          <th style="width: 200px;border: 1px solid black;border-collapse: collapse;"><b>Service</b></th>
                          <th style="width: 200px;border: 1px solid black;border-collapse: collapse;">Payment type</th>
                          <th style="width: 150px;border: 1px solid black;border-collapse: collapse;"><b>Fee</b></th>
                          <th style="width: 150px;border: 1px solid black;border-collapse: collapse;"><b>Concession
                              (%)</b></th>
                          <th style="width: 150px;border: 1px solid black;border-collapse: collapse;"><b>Amount</b></th>
                        </tr>
                        <tr *ngFor="let billprinttable of billingPrintView">
                          <td style="width: 200px; text-align: left;border: 1px solid black;border-collapse: collapse;"
                            data-th="Type">{{billprinttable.bill_type}}</td>
                          <td style="width: 200px; text-align: left;border: 1px solid black;border-collapse: collapse;"
                            data-th="Type">{{billprinttable.pay_type}}</td>
                          <td style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                            data-th="Fee">{{billprinttable.amount_txt}}</td>
                          <td style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                            data-th="Concession">{{billprinttable.concession}}</td>
                          <td style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                            data-th="Amount" class="fee_txt">{{billprinttable.fee}}</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <br />
                  <div [hidden]="treatmentPlanFlag" style="margin-left: 75px; width: 800px;">
                    <h3 class="heading">Bill particulars</h3>
                    <div class="main_class row">
                      <table style="margin-left: 50px; border: 1px solid black;border-collapse: collapse;">
                        <tr>
                          <th style="width: 350px;border: 1px solid black;border-collapse: collapse;"><b>Plan</b></th>
                          <th style="width: 300px;border: 1px solid black;border-collapse: collapse;"><b>Estimate</b></th>
                        </tr>
                        <tr *ngFor="let estimate of estimatePrintData">
                          <td style="width: 350px; text-align: left;border: 1px solid black;border-collapse: collapse;"
                            data-th="Plan">{{estimate.treatment_desc}}</td>
                          <td style="width: 300px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                            data-th="Estimate">{{estimate.estimate}}</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <br />
                  <div style="width: 100%;">
                    <table class="col-3 col-md-5 col-lg-3 col-xl-3" style="float: right;margin-right: 20px;">
                      <tr [hidden]="totalFlag">
                        <td style="width: 510px;background-color: #ffffff;"></td>
                        <th style="text-align: left;">Total (र) </th>
                        <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="Total र" class="fee_txt">
                          {{totalAmount}}</td>
                      </tr>
                      <tr [hidden]="cgstFlag">
                        <td style="width: 510px;background-color: #ffffff;"></td>
                        <th style="text-align: left;"><strong>Central GST {{billingPrintView.cgst}}</strong> % </th>
                        <td style="text-align: right; width: 100px;margin-right: 10px;" data-th="Central GST"
                          class="fee_txt">
                          {{cgst}}</td>
                      </tr>
                      <tr [hidden]="sgstFlag">
                        <td style="width: 300px;background-color: #ffffff;"></td>
                        <th style="text-align: left;"><strong>State GST {{billingPrintView.cgst}}</strong> % </th>
                        <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="State GST"
                          class="fee_txt">
                          {{sgst}}</td>
                      </tr>
                      <tr [hidden]="prevBalanceFlag">
                        <td style="width: 300px;background-color: #ffffff;"></td>
                        <th style="text-align: left;">Previous balance (र)</th>
                        <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="Previous balance र"
                          class="fee_txt">{{prevBalance}}</td>
                      </tr>
                      <tr [hidden]="totalFlag">
                        <td style="width: 300px;background-color: #ffffff;"></td>
                        <th style="text-align: left;">Bill amount (र)</th>
                        <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="Bill amount"
                          class="fee_txt">
                          {{billAmount}}</td>
                      </tr>
                      <tr [hidden]="advanceFlag">
                        <td style="width: 300px;background-color: #ffffff"></td>
                        <th style="text-align: left;">Advance / Paid (र)</th>
                        <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="Advance / Paid र"
                          class="fee_txt">
                          {{billprint.advance}}</td>
                      </tr>
                      <tr>
                        <td style="width: 300px;background-color: #ffffff;"></td>
                        <th style="text-align: left;">Balance (र)</th>
                        <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="Balance" class="fee_txt">
                          {{billprint.balance}}</td>
                      </tr>
                    </table><br /><br /><br />
                  </div>
                </div>
                <br />
                <br /><br />
              </div>
              <br />
              <table>
                <tr>
                  <td>
                    <p style="margin-left: 350px;font-size: 13px"><b><i>This is digitally generated bill</i></b></p>
                    <br />
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </mdb-card-body>
      </mdb-card>
    </div>
  </div>