<div mat-dialog-title class="popup_border_medtrack">
    <h5 class="header_txt" *ngIf="Title=='Diet'">Diet</h5>
    <h5 class="header_txt" *ngIf="Title=='Tracker'">Tracker</h5>
    <div mat-dialog-actions>
        <img src="../../../assets/img/close_icon.png" (click)="close()" width="20px" height="20px"
            id="close_medtrack" />
    </div>
</div>
<div mat-dialog-content class="dtrack_content">
    <div class="popup_box">
        <div class="row row_padding">
            <div class="col-12" *ngIf="Title=='Diet'">
                <div class="nodata"  *ngIf="dietHistTableData.length == 0">
                    No Data Found
                </div>
            </div>
            <div class="col-12" *ngIf="Title=='Diet'">
                <div class="table-responsive" *ngIf="dietHistTableData.length !=0">
                    <table id="tbl" cellpadding="5" cellspacing="0" border="1">
                      <thead>
                        <tr>
                          <th >Break fast</th>
                          <th>Morning</th>
                          <th>Mid-day</th>
                          <th>Lunch</th>
                          <th>Evening</th>
                          <th>Dinner</th>
                          <th>Night</th>
                          <th style="width: 6% ;">Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let diet of dietHistTableData">
                          <td>{{diet.break_fast}}</td>
                          <td>{{diet.morning}}</td>
                          <td>{{diet.midday}}</td>
                          <td>{{diet.lunch}}</td>
                          <td>{{diet.evening}}</td>
                          <td>{{diet.dinner}}</td>
                          <td>{{diet.night}}</td>
                          <td>{{diet.created_date}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
            </div>
            <div class="col-12" *ngIf="Title=='Tracker'">
                <div class="nodata"  *ngIf="trackHistTableData.length ==0">
                    No Data Found
                </div>
            </div>
            <div class="col-12" *ngIf="Title=='Tracker'">
                <div class="table-responsive" *ngIf="trackHistTableData.length !=0">
                    <table id="tbl" cellpadding="5" cellspacing="0" border="1">
                      <thead>
                        <tr>
                          <th>Break fast</th>
                          <th>Morning</th>
                          <th>Mid-day</th>
                          <th>Lunch</th>
                          <th>Evening</th>
                          <th>Dinner</th>
                          <th>Night</th>
                          <th>Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let diet of trackHistTableData">
                          <td>{{diet.break_fast}}</td>
                          <td>{{diet.morning}}</td>
                          <td>{{diet.midday}}</td>
                          <td>{{diet.lunch}}</td>
                          <td>{{diet.evening}}</td>
                          <td>{{diet.dinner}}</td>
                          <td>{{diet.night}}</td>
                          <td>{{diet.created_date}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions align="end" style="width: auto;right: 19px;">
</div>