import { Component, OnInit, ElementRef,Inject } from '@angular/core';
// import { ipaddress.video_ip, ipaddress.video_port } from '../../../assets/js/ipaddress';
import Peer from 'peerjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { encrypt_decript } from 'src/assets/js/Encription_Decription';
import { Doc_Helper } from '../Doc_Helper';
import { ipaddress } from '../../ipaddress' ;
@Component({
  selector: 'app-videocall',
  templateUrl: './videocall.component.html',
  styleUrls: ['./videocall.component.scss']
})
export class VideocallComponent implements OnInit {
  topVideoFrame = "partner-video";
  loginId: string;
  calleeId: string;
  myEl: HTMLMediaElement;
  partnerEl: HTMLMediaElement;
  public call_flag: boolean;
  public androidCordova = false;
  public web_flag: boolean;
  public video_height: string;
  public video_width: string;
  public is_videopage:boolean;
  public videolink;
  public videolink_paste;
  public videolink1;
  public textboxvalue;

  peer: Peer;
  myStream: MediaStream;

  stun = 'stun.l.google.com:19302';
  mediaConnection: Peer.MediaConnection;
  options: Peer.PeerJSOption;
  stunServer: RTCIceServer = {
    urls: 'stun:' + this.stun,
  };
  
  constructor(private elRef: ElementRef,@Inject(MAT_DIALOG_DATA) public data: any ,public dialogRef: MatDialogRef<VideocallComponent>) {
   // this.loginId = data.login_id;
   // this.calleeId = data.callee_id;
    dialogRef.disableClose = true;
    this.call_flag = false;
    this.is_videopage = false;
  }

  ngOnInit(): void {
    var initial_url = Doc_Helper.getVideoLink();
    var url = initial_url.split( '/' );
    var updated_url= document.location.hostname + '/' + url[ url.length - 2 ] + '/' + url[ url.length - 1 ];
    var callerid = url[ url.length - 2 ];
    var data1 = encrypt_decript.Decript(callerid);
    var calleeidvalue = encrypt_decript.Decript(url[ url.length - 1 ]);
    if(this.data.login_id == data1){
      this.loginId = data1.trim();
    }
    if(this.data.callee_id == calleeidvalue){
      this.calleeId = calleeidvalue.trim();

    } 

    this.dialogRef.disableClose = true;
    this.myEl = this.elRef.nativeElement.querySelector('#my-video');
    this.myEl.muted = true;
    this.partnerEl = this.elRef.nativeElement.querySelector('#partner-video');

    this.init(this.loginId, this.myEl, this.partnerEl);
    this.inital(true,'my-video');
  }


  screensize_function() {
    if (window.outerWidth > 1024) {// Desktop
      this.web_flag = true;

    } else { // tablet, mobiles
      this.web_flag = false;
    }
    this.video_height = window.outerHeight.toString();
    this.video_width = window.outerWidth.toString();
  }

  inital(flag,video) {
    this.call_flag = flag;
    //this.call(this.calleeId);
   // this.swapVideo(video);
  }

  swapVideo(topVideo: string) {
    this.topVideoFrame = topVideo;
  }

  getMedia() { 
    var constraints = {
      audio: true,
      video: true,
    }
   
    navigator.mediaDevices.getUserMedia(constraints).then(stream => {
      this.myEl.volume=1;
      this.handleSuccess(stream);
    });
  }

  async init(userId: string, myEl: HTMLMediaElement, partnerEl: HTMLMediaElement) {
    this.myEl = myEl;
    this.partnerEl = partnerEl;
    try {
      this.getMedia();
    } catch (e) {
      this.handleError(e);
    }
    await this.createPeer(userId);
  }

  async createPeer(userId: string) {

    this.peer = new Peer(userId, {
      key: "peerjs",
      host: ipaddress.video_ip,
      port: parseInt(ipaddress.video_port),
      path: '/gritapp',
      secure: true,
      config: {
        'iceServers': [this.stunServer]
      }
    });

    this.peer.on('open', () => {
        this.wait();
    });
  }

  call(partnerId: string) {
    
    const call = this.peer.call(partnerId, this.myStream);
   // call.answer(this.myStream);
    call.on('stream', (stream) => {
       
      this.partnerEl.srcObject = stream;
    });
  }
  
 
  // wait() {
  //   this.peer.on('call', (call) => {
  //     this.mediaConnection = call;
      
  //     // var acceptsCall = confirm("Patient calling, do you want to accept it ?");
  //     // if (acceptsCall) {
  //       
  //       call.answer(this.myStream);
  //       call.on('stream', (stream) => {
  //         this.partnerEl.srcObject = stream;
  //       // this.status = 'Connected';
  //       
  //       });
  //       this.swapVideo("my-video");
  //     // }
  //   });
  // }

  wait() {
    this.peer.on('call', (call) => {
      this.mediaConnection = call;
      
     // var acceptsCall = confirm("Doctor calling, do you want to accept it ?");
     // if (acceptsCall) {
        
      call.answer(this.myStream);
      call.on('stream', (stream) => {
        this.partnerEl.srcObject = stream;
        this.swapVideo("my-video");
       // this.status = 'Connected';
       
      });
    //}
    });
  }


  handleSuccess(stream: MediaStream) {
    this.myStream = stream;
    this.myEl.srcObject = stream;
    this.call(this.calleeId);
  }

  handleError(error: any) {
    if (error.name === 'ConstraintNotSatisfiedError') {
       this.errorMsg(`The resolution px is not supported by your device.`);
    } else if (error.name === 'PermissionDeniedError') {
      this.errorMsg('Permissions have not been granted to use your camera and ' +
        'microphone, you need to allow the page access to your devices in ' +
        'order for the demo to work.');
    }
    this.errorMsg(`getUserMedia error: ${error.name}`, error);
  }

  errorMsg(msg: string, error?: any) {
    const errorElement = document.querySelector('#errorMsg');
    errorElement.innerHTML += `<p>${msg}</p>`;
    if (typeof error !== 'undefined') {
      console.error(error);
    }
  }

  disconnect_call() {
     this.peer.destroy();
    
     navigator.mediaDevices.getUserMedia({audio: false, video: true})
     .then(mediaStream => {
     this.myEl.srcObject = mediaStream;
       setTimeout(() => {
         const tracks = mediaStream.getTracks()
         tracks[0].stop()
       }, 5000)
    })
 
    if(this.myStream != undefined){
      this.myStream.getTracks().forEach(function (track) {
        track.stop();
      });
    }
    this.dialogRef.close();
  }

  ClosePopup(){
    this.dialogRef.close();
  }
}
