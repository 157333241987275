<div class="row">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle" *ngIf="appNursePhysio == 'physio' || this.appNursePhysio == 'dietician'">{{typeLable}} list</h5>
          <h5 class="m-0" class="mainHeadingStyle" *ngIf="appNursePhysio == 'nurse'">Nurse list</h5>
          </div>
          <div class="headerButtons">
            <img class="saveimgbtn_inpatinfo" (click)="back()" style="width: 85px;" src="../../../assets/ui_icons/buttons/Back_button.svg" />
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row" style="padding: 14px;">
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <mat-card id="cardcontent">
              <mat-card-title id="cardheading">Search by</mat-card-title>
              <mat-card-content>
                <div class="row" style="padding: 5px;">
                  <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                    <mat-label class="matlabel">Country<br>
                      <mat-select disableOptionCentering class="ipcss widthappt" [(ngModel)]="filtCountry"
                        (selectionChange)="changeCountry(filtCountry, '0')">
                        <mat-option disabled>Select</mat-option>
                        <mat-option *ngFor="let country of clntCountryList" value={{country.description}}>
                          {{country.description}}</mat-option>
                      </mat-select>
                    </mat-label>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                    <mat-label class="matlabel">State<br>
                      <mat-select disableOptionCentering class="ipcss widthappt" required [(ngModel)]="filtState"
                        (selectionChange)="changeState(filtState, '0')">
                        <mat-option disabled>Select</mat-option>
                        <mat-option *ngFor="let state of clntStateList" value={{state.description}}>
                          {{state.description}}</mat-option>
                      </mat-select>
                    </mat-label>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottomy">
                    <mat-label class="matlabel">City<br>
                      <mat-select disableOptionCentering class="ipcss widthappt" required [(ngModel)]="filtCity"
                        (selectionChange)="changeCity(filtCity, '0')">
                        <mat-option disabled>Select</mat-option>
                        <mat-option *ngFor="let city of clntCityList" value="{{city.description}}">
                          {{city.description}}</mat-option>
                      </mat-select>
                    </mat-label>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                    <mat-label class="matlabel">location<br>
                      <input type="text" class="ipcss widthappt" required maxlength="50"
                        (keyup)="changeLocation(locationName)" [(ngModel)]="locationName" matInput [matAutocomplete]="auto" />
                      <mat-autocomplete #auto="matAutocomplete">
                        <mat-option (click)="selectLocation(location)" *ngFor="let location of locationList"
                          [value]="location">
                          {{location}}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-label>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div><br>
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="margin:10px">
            <div *ngIf="physioDoctorList.length == 0">
              <p class="nodata">No data found</p>
            </div>
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngIf="physioDoctorList.length != 0">
            <table *ngIf="physioDoctorList.length" mdbTable datatable [dtOptions]="dtOptions" class="table table-nowrap table-sm dataTable">
              <thead>
                <tr>
                  <th> </th>
                  <th>{{typeLable}} name</th>
                  <th>Hospital name</th>
                  <th>Location</th>
                  <th>Gender </th>
                  <th>Experience </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let physio of physioDoctorList;let i = index" (click)="selectDoctor(physio)">
                  <td>
                    <img src="{{physio.profile_image}}"  height="40px" width="40px" class="img-fluid z-depth-1 rounded-circle mr-1"> 
                  </td>
                  <td class="align_left">{{physio.name}}</td>
                  <td class="align_left">{{physio.hospital_name}}</td>
                  <td class="align_left">{{physio.location}}</td>
                  <td class="align_left">{{physio.gender}}</td>
                  <td>{{physio.experience}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>