<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Appointment Details</h5>
          </div>
          <div class="headerButtons">
            <img src="../../../assets/ui_icons/buttons/Back_button.svg" (click)="backClicked()"
              class="saveimgbtn_inpatinfo" />
            <img src="../../../assets/ui_icons/buttons/Allot_button.svg" class="saveimgbtn_inpatinfo "
              [hidden]="allotFlag" (click)="allot()" />
            <!-- <img src="../../../assets/ui_icons/buttons/Sample_collected.svg" class=" saveimgbtn_inpatinfo"
              [hidden]="sampleCollectFlag" (click)="sample()" /> -->
            <img src="../../../assets/ui_icons/buttons/cancel_button.svg" class="saveimgbtn_inpatinfo"
              [hidden]="cancelAppFlag" (click)="cancel()" />
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row">
          <div class="Details">
            <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div class="cover_div" style="padding-bottom: 13px;">
                <div class="header_lable">Personal Details</div>
                <div class="content_cover">
         
                  <div class="row">
                    <div class="col-3" [hidden]="relationFalg">
                      <p class="textformat"><span style="font-weight: 550;">Relationship:</span> {{relationType}}</p>
                    </div>
                    <div class="col-3">
                      <p class="textformat"><span style="font-weight: 550;">MR no:</span> {{patientid}}</p>
                    </div>
                    <div class="col-3">
                      <p class="textformat"><span style="font-weight: 550;">Name:</span> {{clientName}}</p>
                    </div>
                    <div class="col-3">
                      <p class="textformat"><span style="font-weight: 550;">Age:</span> {{age}}/ {{gendervalue}}</p>
                    </div>
                    <div class="col-3">
                      <p class="textformat"><span style="font-weight: 550;">Mobile:</span> {{clientMobile}}</p>
                    </div>
                    <div class="col-3">
                      <p class="textformat"><span style="font-weight: 550;">Blood group:</span> {{clientBlood}}</p>
                    </div>
                    <div class="col-3">
                      <p class="textformat"><span style="font-weight: 550;">Doctor:</span> {{docname}}</p>
                    </div>
                    <div class="col-3">
                      <p class="textformat"><span style="font-weight: 550;">Date & Time:</span> {{appointDate}} {{fromFirstTimeArray}}:{{fromtimeArray}}:{{timeAmpmHome}}</p>
                    </div>
                    <div class="col-3">
                      <p class="textformat"><span style="font-weight: 550;">Session:</span> {{session}}</p>
                    </div>
                    <div class="col-3" *ngIf="!referedBy">
                      <p class="textformat"><span style="font-weight: 550;" *ngIf="!referedBy">Referred by:</span>
                        {{diagDoctorName}}</p>
                    </div>
                    <!-- <div class="col-3">
                      <p class="textformat"><span style="font-weight: 550;">Centre details:</span> {{centerName}}</p>
                    </div>
                    <div class="col-3">
                      <p class="textformat"><span style="font-weight: 500;">Centre Address:</span> {{fulladress}}</p>
                    </div>
                    <div class="col-3">
                      <p class="textformat"><span style="font-weight: 500;">Mobile:</span> {{phone}}</p>
                    </div>
                    <div class="col-3" *ngIf="websiteflag">
                      <p class="textformat"><span style="font-weight: 500;">Website:</span> {{website}}</p>
                    </div> -->
                  </div>
                </div>
              </div>
             
             
            </div>
              <div class="col-12" [hidden]="diagTestArray.length==0" style="margin-top: 10px;margin-bottom: 10px;">
                <div class="dig_table_overflow">
                  <div class="table-responsive" *ngIf="diagTestArray.length != 0">
                    <table [hidden]="diagTestArray.length==0" id="card_tbl" class="table table-hover table-dynamic">
                      <thead>
                        <tr>
                          <th>Department</th>
                          <th>Test name</th>
                          <!-- <th>Last Visit </th> -->
                          <!-- <th style="text-align: center !important;">Status </th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let Diagnosis of diagTestArray">
                          <td style="text-align: left;">{{Diagnosis.Groupname}}</td>
                          <td style="text-align: left;">{{Diagnosis.Testname}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            
            </div>
            </div>
          </div>
        
      </mdb-card-body>
    </mdb-card>
  </div>
</div>