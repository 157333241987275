import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Master_Helper } from '../../Doctor_module/Master_Helper';
import { MasterHelperService } from '../../Doctor_module/MasterHelper.service';
import { Helper_Class } from '../../helper_class';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Http, Headers, JSONPConnection } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { ConvertTimeformat, Date_Formate, tConv24, Time_Formate } from '../../../assets/js/common';
import { MatDialog } from '@angular/material/dialog';
import { ipaddress } from '../../ipaddress'; import { Doc_Helper } from '../../Doctor_module/Doc_Helper';
import { MenuViewService } from '../../common-module/menu-view/menu-view.service';
import { Message_data } from '../../../assets/js/Message_data';
import { Nurse_Helper } from '../../Nurse_module/Nurse_Helper';
import { WarstrackerInfoComponent } from '../warstracker-info/warstracker-info.component';

@Component({
  selector: 'app-medicinetracker-nurse',
  templateUrl: './medicinetracker-nurse.component.html',
  styleUrls: ['./medicinetracker-nurse.component.scss']
})
export class MedicinetrackerNurseComponent implements OnInit {
  public flagcheck: boolean = false;
  public ward_list: any = [];
  public ward_id: any = 'All';
  public life_style_id;
  public foodarray: any = []
  bedList: any[];
  hospital_id: any;
  public wardBeds: any = [];
  public ward_Name: any;
  splUrl: string;
  public userInfo: any;
  public splList = [];
  nurseWardList: any = [];
  ward_ids: any[];
  public med_url_txt;
  public Medicine_Hist_List: any = [];
  public current_date;
  public current_time;
  public session;
  public Appointment_Date;
  public date;
  public sub_rel_id;
  public send_data_med;
  public relation_id;
  public client_reg_id;
  public backflag;
  constructor(public dialog: MatDialog,private cdr: ChangeDetectorRef, public masterData: MasterHelperService, public http: Http, public routes: ActivatedRoute, public router: Router, public toastr: ToastrService, public messageservice: MenuViewService,public nurseservice: MenuViewService) { }

  async ngOnInit() {

    this.userInfo = Helper_Class.getInfo();
    console.log('this.Helper_Class.getInfo()'+JSON.stringify(Helper_Class.getInfo()))

    console.log('this.userInfo'+JSON.stringify(Master_Helper.getMasterWardData().wards))
    this.nurseWardList = Helper_Class.getNurseWardList();

    console.log('this.nurseWardList'+JSON.stringify(this.nurseWardList))

    if (this.userInfo.user_type == "nurse") {
      if (Master_Helper.getMasterWardData() != undefined) {
        this.ward_list = Master_Helper.getMasterWardData().wards.filter(item => this.nurseWardList.includes(item.ward_id));
      } else {
        console.log('this.userInfo *****' + JSON.stringify(Master_Helper.getMasterWardData()))
        await this.masterData.getWardData();
        this.ward_list = Master_Helper.getMasterWardData().wards.filter(item => this.nurseWardList.includes(item.ward_id));
        console.log('this. *****' + JSON.stringify(this.ward_list))

      }
    } else {
      if (Master_Helper.getMasterWardData() != undefined) {
        this.ward_list = Master_Helper.getMasterWardData().wards;
      } else {
        await this.masterData.getWardData();
        this.ward_list = Master_Helper.getMasterWardData().wards;
      }
    }
    if(Helper_Class.getapp_flowinpa() != undefined){
      this.backflag =true
    }else{
      this.backflag =false
    }

    console.log("this.ward_list", this.ward_list)

    this.splUrl = ipaddress.getIp + "adm/gsplbyhptl";

    var hosp: any = Helper_Class.getHospital();
    this.hospital_id = hosp[0].hptl_clinic_id;
    this.appGetDate('medicine');
    this.getBedList('All')
    this.getSpecializations();
  }
  appGetDate(e) {
    var headers = new Headers();
    var date
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate', { country: ipaddress.country_code },
      { headers: headers })
      .subscribe(
        response => {
          if (e == "Diet") {
            var obj = JSON.parse(response["_body"]);
            console.log("current time", JSON.stringify(obj))
            this.current_date = obj.current_date;
            this.current_time = obj.current_time;
            var currenttime = obj.current_time.split(":");
            if (currenttime[0] >= 6 && currenttime[0] < 8) {
              this.session = "Morning";
            } else if (currenttime[0] >= 8 && currenttime[0] < 10) {
              this.session = "Breakfast";
            } else if (currenttime[0] >= 10 && currenttime[0] < 12) {
              this.session = "Midday";
            } else if (currenttime[0] >= 12 && currenttime[0] < 14) {
              this.session = "Lunch";
            } else if (currenttime[0] >= 15 && currenttime[0] < 17) {
              this.session = "Evening";
            } else if (currenttime[0] >= 19 && currenttime[0] < 21) {
              this.session = "Dinner";
            }
            else if (currenttime[0] >= 21 && currenttime[0] < 22) {
              this.session = "Night";
            }
            this.Appointment_Date = obj.current_date;
            this.date = obj.current_date.split('-')[2] + "-" + obj.current_date.split('-')[1] + "-" + obj.current_date.split('-')[0];

            // this.updateCurrentSession(this.presentdate,this.currenttime)
            //this.getSalesData();
            console.log('presentdate1', this.session + currenttime)
          } else {
            var obj = JSON.parse(response["_body"]);
            console.log("current time", JSON.stringify(obj))
            this.current_date = obj.current_date;
            this.current_time = obj.current_time;
            var currenttime = obj.current_time.split(":");
            if (currenttime[0] >= 3 && currenttime[0] < 12) {
              this.session = "Morning";
            } else if (currenttime[0] >= 12 && currenttime[0] < 16) {
              this.session = "Afternoon";
            } else if (currenttime[0] >= 16 && currenttime[0] < 19) {
              this.session = "Evening";
            } else if (currenttime[0] >= 19 && currenttime[0] < 24) {
              this.session = "Evening";
            }
            this.Appointment_Date = obj.current_date;
            this.date = obj.current_date.split('-')[2] + "-" + obj.current_date.split('-')[1] + "-" + obj.current_date.split('-')[0];

            // this.updateCurrentSession(this.presentdate,this.currenttime)
            //this.getSalesData();
            console.log('presentdate1', this.session + currenttime)
            this.Appointment_Date = obj.current_date;
            this.date = obj.current_date.split('-')[2] + "-" + obj.current_date.split('-')[1] + "-" + obj.current_date.split('-')[0];

            // this.updateCurrentSession(this.presentdate,this.currenttime)
            //this.getSalesData();
            console.log('presentdate1', this.session + currenttime)
          }

        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });

  }

  getBedList(ward_id) {
    this.bedList = [];
    this.wardBeds = [];
    this.ward_ids = [];

    if (ward_id != 'All') {
      for (var k = 0; k < this.ward_list.length; k++) {
        if (this.ward_list[k].ward_id == ward_id) {
          this.ward_Name = this.ward_list[k].ward_name
        }
      }
    }

    console.log("???????????" + JSON.stringify({
      hptl_clinic_id: this.hospital_id,
      ward_id: ward_id,
      country: ipaddress.country_code.toString(),
      user_id: this.userInfo.user_id,
      type: this.userInfo.user_type,
    }))

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "inpat/gwbd",
      JSON.stringify({
        hptl_clinic_id: this.hospital_id,
        ward_id: ward_id,
        country: ipaddress.country_code.toString(),
        user_id: this.userInfo.user_id,
        type: this.userInfo.user_type,
      }),
      { headers: headers }).subscribe(
        data => {
          var obj = data.json();
           console.log("BedList ==> " + JSON.stringify(obj));
          this.wardBeds = obj.ward_beds;
          for (var i = 0; i < this.wardBeds.length; i++) {
            this.wardBeds[i].isselected = false;
          }
          console.log("wardBeds ==> " + JSON.stringify(this.wardBeds));
          if (obj != undefined && obj != null && obj != "") {
            console.log("BedList ==> " + JSON.stringify(obj));
          }
        });
  }

  getSpecializations() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.splUrl, JSON.stringify({ hptl_clinic_id: this.userInfo.hptl_clinic_id }),
      { headers: headers }).subscribe(
        data => {
          this.splList = [];
          var obj = JSON.parse(JSON.stringify(data));
          if (obj.specializations != null)
            this.splList = obj.specializations;
        },
        error => {
          this.toastr.error(Message_data.unableToFetchData);
        });
  }
  data() {
    this.flagcheck = !this.flagcheck
  }

  viewPatient(inpatient_id, mobile, regid, array, fname, mname, lname, speciality) {
    this.flagcheck = false;
    Doc_Helper.setFirstname(encrypt_decript.Decript(fname));
    Doc_Helper.setMiddlename(encrypt_decript.Decript(mname));
    Doc_Helper.setLastname(encrypt_decript.Decript(lname));
    Doc_Helper.setClient_id(regid);
    Doc_Helper.setSearchFlag("true");
    Doc_Helper.setClient_type("Existing");
    Doc_Helper.setMobile_no(mobile);
    Doc_Helper.setInpatientId(inpatient_id);
    Doc_Helper.setInpatientFlow("list");
    Doc_Helper.setFlowName("ward");
    Nurse_Helper.setINPatient(array);
    var spa_id
    for (var i = 0; i < this.splList.length; i++) {
      if (this.splList[i].spl_name == speciality) {
        spa_id = this.splList[i].spl_id;
      }
    }
    var specializations = [{
      spl_id: spa_id,
      spl_name: speciality
    }]

    if (this.userInfo.user_type == "nurse") {
      Helper_Class.setInpatientFlow("nurse");
    } else if (this.userInfo.user_type == "front-desk") {
      Helper_Class.setInpatientFlow("front-desk");
    }

    Helper_Class.setSpecializations(specializations);
    this.messageservice.sendMessage("viewIpatient");

    // console.log(encrypt_decript.Decript(mobile))
  }
  //
  // if (obj.ward_beds.length != 0) {
  //   for (var j = 0; j < obj.ward_beds.length; j++) {
  //     if (obj.ward_beds[j].bed_details != undefined){
  //       // var bedDetails = obj.ward_beds[j].bed_details;
  //       console.log("obj.ward_beds[j].bed_details---" , obj.ward_beds[j].bed_details)
  //       for (var i = 0; i < obj.ward_beds[j].bed_details.length; i++) {
  //         var pat_name, fontWeight = "", flag: boolean;
  //         pat_name = (obj.ward_beds[j].bed_details[i].name == "Vacant") ? '#000' : 'darkgreen';
  //         fontWeight = (obj.ward_beds[j].bed_details[i].name == "Vacant") ? '400' : '600';
  //         flag = (obj.ward_beds[j].bed_details[i].name == "Vacant") ? true : false;
  //         this.bedList.push({
  //           bed_no: obj.ward_beds[j].bed_details[i].bed_no != undefined ? obj.ward_beds[j].bed_details[i].bed_no : "--",
  //           pat_name: obj.ward_beds[j].bed_details[i].name,
  //           bed: obj.ward_beds[j].bed_details[i].bed_no + " - " + obj.ward_beds[j].bed_details[i].name,
  //           bed_img: "../../../assets/ui_icons/bed_icon.svg",
  //           color: pat_name,
  //           fontWeight: fontWeight,
  //           flag: flag,
  //         });
  //       }
  //     }                
  //   }              
  // }
  // this.adminService.sendMessage("createPackage");
  toggleFlagCheck1(bedId, wardid, warddata) {  
    this.Medicine_Hist_List = [];
    // Example usage  
    Helper_Class.setapp_flowinpa(null)
    Helper_Class.setapp_flowinpa("tracker")
    if (warddata.name != "Vacant") {
      var result = this.isBedOccupied(wardid, bedId);
      console.log("warddata --" + JSON.stringify(warddata))
      console.log("warddata -bedId-" + JSON.stringify(bedId))
      console.log("wardid --" + JSON.stringify(wardid))
      console.log(result);
      this.foodarray = [];this.Medicine_Hist_List=[]
      const dialogRef = this.dialog.open(WarstrackerInfoComponent, {
        width: '70%',
        height: '700px',
        data: {
         warddata:warddata,Title:'Diet'
        }
      });
      dialogRef.afterClosed().subscribe(result => {
  
  
        if (result != undefined) {
          
        }
  
  
      });
     
    } else {
      this.Medicine_Hist_List = [];
      const result = this.isBedOccupied1(wardid, bedId);
    }


  

  }

  retrieveData(warddata) {
    if (warddata.relation_id != undefined) {
      this.relation_id = warddata.relation_id;
    }
    if (warddata.sub_rel_id != undefined) {
      this.sub_rel_id = warddata.sub_rel_id;
    }
    if (warddata.client_reg_id != undefined) {
      this.client_reg_id = warddata.client_reg_id;
    }
    if (warddata.life_style_id != undefined) {
      this.life_style_id = warddata.life_style_id;
    }


    this.sub_rel_id = this.sub_rel_id
    if (this.sub_rel_id != undefined && this.sub_rel_id != null && this.sub_rel_id != "") {
      this.send_data_med = {
        client_reg_id: this.client_reg_id,
        relation_id: this.relation_id,
        life_style_id: this.life_style_id,
        sub_rel_id: this.sub_rel_id,
        country: ipaddress.country_code,
        is_doctor: "1",
        plan_date: this.current_date,
        end_date: this.current_date,
        type: "plan"
      }
    }
    else {
      this.send_data_med = {
        client_reg_id: this.client_reg_id,
        relation_id: this.relation_id,
        country: ipaddress.country_code,
        life_style_id: this.life_style_id,
        is_doctor: "1",
        plan_date: this.current_date,
        end_date: this.current_date,
        type: "plan"
      }
    }
    console.log("check now--" + JSON.stringify(this.send_data_med))
    console.log("session now--" + JSON.stringify(this.session))
    var image;
    var headers = new Headers();

    headers.append('Content-Type', 'application/json');
    this.http.post( ipaddress.getIp + 'lifestyle/gdpnt', (this.send_data_med), { headers: headers }).subscribe(
      data => {
        var shortform;
        var obj = JSON.parse(data["_body"]);
        console.log("check data" + JSON.stringify(obj))
        if (this.session == "Morning" && obj.doctor_morning != null && obj.doctor_morning.length != 0) {
          if (obj.doctor_morning != null) {
            var tempFoodItems = {};
            this.foodarray = []
            for (var i = 0; i < obj.doctor_morning.length; i++) {
              var dayMorning = obj.doctor_morning[i].day != undefined ? obj.doctor_morning[i].day : " ";
              var tempWeight = obj.doctor_morning[i].weight.split(" ");
              var calsWight = tempWeight[0];
              var wightMesure = tempWeight[1];
              tempFoodItems = {
                food_item_id: obj.doctor_morning[i].food_item_id,
                description: obj.doctor_morning[i].description,
                food_type_id: obj.doctor_morning[i].food_type_id,
                food_sub_type: obj.doctor_morning[i].food_sub_type,
                quantity: obj.doctor_morning[i].quantity != undefined ? obj.doctor_morning[i].quantity : "0.00",
                measure: obj.doctor_morning[i].measure != undefined ? obj.doctor_morning[i].measure : "0.00",
                weight: obj.doctor_morning[i].weight != undefined ? obj.doctor_morning[i].weight : "0.00",
                cals_weight: calsWight,
                wightMesure: wightMesure,
                energy: obj.doctor_morning[i].energy != undefined ? obj.doctor_morning[i].energy : "0.00",
                proteins: obj.doctor_morning[i].proteins != undefined ? obj.doctor_morning[i].proteins : "0.00",
                carbohydrates: obj.doctor_morning[i].carbohydrates != undefined ? obj.doctor_morning[i].carbohydrates : "0.00",
                fat: obj.doctor_morning[i].fat != undefined ? obj.doctor_morning[i].fat : "0.00",
                calcium: obj.doctor_morning[i].calcium != undefined ? obj.doctor_morning[i].calcium : "0.00",
                iron: obj.doctor_morning[i].iron != undefined ? obj.doctor_morning[i].iron : "0.00",
                vitaminC: obj.doctor_morning[i].vitaminC != undefined ? obj.doctor_morning[i].vitaminC : "0.00",
                vitaminA: obj.doctor_morning[i].vitaminA != undefined ? obj.doctor_morning[i].vitaminA : "0.00",
                vitaminB12: obj.doctor_morning[i].vitaminB12 != undefined ? obj.doctor_morning[i].vitaminB12 : "0.00",
                fiber: obj.doctor_morning[i].fiber != undefined ? obj.doctor_morning[i].fiber : "0.00",
                folic: obj.doctor_morning[i].folic != undefined ? obj.doctor_morning[i].folic : "0.00",
                h2o: obj.doctor_morning[i].h2o != undefined ? obj.doctor_morning[i].h2o : "0.00",
                session: "Morning",
                type: "Doctor",
                image: obj.doctor_morning[i].image,
                day: dayMorning,
                date: obj.doctor_morning[i].date, checked: false
              };
              this.foodarray.push({
                food_item_id: obj.doctor_morning[i].food_item_id,
                description: obj.doctor_morning[i].description,
                food_type_id: obj.doctor_morning[i].food_type_id,
                food_sub_type: obj.doctor_morning[i].food_sub_type,
                quantity: obj.doctor_morning[i].quantity != undefined ? obj.doctor_morning[i].quantity : "0.00",
                measure: obj.doctor_morning[i].measure != undefined ? obj.doctor_morning[i].measure : "0.00",
                weight: obj.doctor_morning[i].weight != undefined ? obj.doctor_morning[i].weight : "0.00",
                cals_weight: calsWight,
                wightMesure: wightMesure,
                energy: obj.doctor_morning[i].energy != undefined ? obj.doctor_morning[i].energy : "0.00",
                proteins: obj.doctor_morning[i].proteins != undefined ? obj.doctor_morning[i].proteins : "0.00",
                carbohydrates: obj.doctor_morning[i].carbohydrates != undefined ? obj.doctor_morning[i].carbohydrates : "0.00",
                fat: obj.doctor_morning[i].fat != undefined ? obj.doctor_morning[i].fat : "0.00",
                calcium: obj.doctor_morning[i].calcium != undefined ? obj.doctor_morning[i].calcium : "0.00",
                iron: obj.doctor_morning[i].iron != undefined ? obj.doctor_morning[i].iron : "0.00",
                vitaminC: obj.doctor_morning[i].vitaminC != undefined ? obj.doctor_morning[i].vitaminC : "0.00",
                vitaminA: obj.doctor_morning[i].vitaminA != undefined ? obj.doctor_morning[i].vitaminA : "0.00",
                vitaminB12: obj.doctor_morning[i].vitaminB12 != undefined ? obj.doctor_morning[i].vitaminB12 : "0.00",
                fiber: obj.doctor_morning[i].fiber != undefined ? obj.doctor_morning[i].fiber : "0.00",
                folic: obj.doctor_morning[i].folic != undefined ? obj.doctor_morning[i].folic : "0.00",
                h2o: obj.doctor_morning[i].h2o != undefined ? obj.doctor_morning[i].h2o : "0.00",
                session: "Morning",
                type: "Doctor",
                image: ipaddress.Ip_with_img_address + "/" + obj.doctor_morning[i].image,
                day: dayMorning,
                date: obj.doctor_morning[i].date
              })
              // this.mainDishFlag = obj.doctor_morning[i].main_course != "1" ? false : true;
              //  this.selctedFood(obj.doctor_morning[i].day, obj.doctor_morning[i].date, tempFoodItems, 0);
            }
          }
        }   

        if (this.session == "Breakfast" && obj.doctor_breakfast != null && obj.doctor_breakfast.length != 0) {
          var tempFoodItems = {};
          for (var i = 0; i < obj.doctor_breakfast.length; i++) {
            var dayBreak = obj.doctor_breakfast[i].day != undefined ? obj.doctor_breakfast[i].day : " ";
            var tempWeight = obj.doctor_breakfast[i].weight.split(" ");
            var calsWight = tempWeight[0];
            var wightMesure = tempWeight[1];
            this.foodarray.push({
              food_item_id: obj.doctor_breakfast[i].food_item_id,
              description: obj.doctor_breakfast[i].description,
              food_type_id: obj.doctor_breakfast[i].food_type_id,
              food_sub_type: obj.doctor_breakfast[i].food_sub_type,
              quantity: obj.doctor_breakfast[i].quantity != undefined ? obj.doctor_breakfast[i].quantity : "0.00",
              measure: obj.doctor_breakfast[i].measure != undefined ? obj.doctor_breakfast[i].measure : "0.00",
              weight: obj.doctor_breakfast[i].weight != undefined ? obj.doctor_breakfast[i].weight : "0.00",
              cals_weight: calsWight,
              wightMesure: wightMesure,
              energy: obj.doctor_breakfast[i].energy != undefined ? obj.doctor_breakfast[i].energy : "0.00",
              proteins: obj.doctor_breakfast[i].proteins != undefined ? obj.doctor_breakfast[i].proteins : "0.00",
              carbohydrates: obj.doctor_breakfast[i].carbohydrates != undefined ? obj.doctor_breakfast[i].carbohydrates : "0.00",
              fat: obj.doctor_breakfast[i].fat != undefined ? obj.doctor_breakfast[i].fat : "0.00",
              calcium: obj.doctor_breakfast[i].calcium != undefined ? obj.doctor_breakfast[i].calcium : "0.00",
              iron: obj.doctor_breakfast[i].iron != undefined ? obj.doctor_breakfast[i].iron : "0.00",
              vitaminC: obj.doctor_breakfast[i].vitaminC != undefined ? obj.doctor_breakfast[i].vitaminC : "0.00",
              vitaminA: obj.doctor_breakfast[i].vitaminA != undefined ? obj.doctor_breakfast[i].vitaminA : "0.00",
              vitaminB12: obj.doctor_breakfast[i].vitaminB12 != undefined ? obj.doctor_breakfast[i].vitaminB12 : "0.00",
              fiber: obj.doctor_breakfast[i].fiber != undefined ? obj.doctor_breakfast[i].fiber : "0.00",
              folic: obj.doctor_breakfast[i].folic != undefined ? obj.doctor_breakfast[i].folic : "0.00",
              h2o: obj.doctor_breakfast[i].h2o != undefined ? obj.doctor_breakfast[i].h2o : "0.00",
              session: "Morning",
              type: "Doctor",
              image: ipaddress.Ip_with_img_address + "/" + obj.doctor_breakfast[i].image,
              day: dayMorning,
              date: obj.doctor_breakfast[i].date,
              checked: false
            })
          }
        }
        if (this.session == "Midday" && obj.doctor_midday != null && obj.doctor_midday.length != 0) {
          if (obj.doctor_midday != null) {
            var tempFoodItems = {};
            this.foodarray = []
            for (var i = 0; i < obj.doctor_midday.length; i++) {
              var dayMorning = obj.doctor_midday[i].day != undefined ? obj.doctor_midday[i].day : " ";
              var tempWeight = obj.doctor_midday[i].weight.split(" ");
              var calsWight = tempWeight[0];
              var wightMesure = tempWeight[1];
              tempFoodItems = {
                food_item_id: obj.doctor_midday[i].food_item_id,
                description: obj.doctor_midday[i].description,
                food_type_id: obj.doctor_midday[i].food_type_id,
                food_sub_type: obj.doctor_midday[i].food_sub_type,
                quantity: obj.doctor_midday[i].quantity != undefined ? obj.doctor_midday[i].quantity : "0.00",
                measure: obj.doctor_midday[i].measure != undefined ? obj.doctor_midday[i].measure : "0.00",
                weight: obj.doctor_midday[i].weight != undefined ? obj.doctor_midday[i].weight : "0.00",
                cals_weight: calsWight,
                wightMesure: wightMesure,
                energy: obj.doctor_midday[i].energy != undefined ? obj.doctor_midday[i].energy : "0.00",
                proteins: obj.doctor_midday[i].proteins != undefined ? obj.doctor_midday[i].proteins : "0.00",
                carbohydrates: obj.doctor_midday[i].carbohydrates != undefined ? obj.doctor_midday[i].carbohydrates : "0.00",
                fat: obj.doctor_midday[i].fat != undefined ? obj.doctor_midday[i].fat : "0.00",
                calcium: obj.doctor_midday[i].calcium != undefined ? obj.doctor_midday[i].calcium : "0.00",
                iron: obj.doctor_midday[i].iron != undefined ? obj.doctor_midday[i].iron : "0.00",
                vitaminC: obj.doctor_midday[i].vitaminC != undefined ? obj.doctor_midday[i].vitaminC : "0.00",
                vitaminA: obj.doctor_midday[i].vitaminA != undefined ? obj.doctor_midday[i].vitaminA : "0.00",
                vitaminB12: obj.doctor_midday[i].vitaminB12 != undefined ? obj.doctor_midday[i].vitaminB12 : "0.00",
                fiber: obj.doctor_midday[i].fiber != undefined ? obj.doctor_midday[i].fiber : "0.00",
                folic: obj.doctor_midday[i].folic != undefined ? obj.doctor_midday[i].folic : "0.00",
                h2o: obj.doctor_midday[i].h2o != undefined ? obj.doctor_midday[i].h2o : "0.00",
                session: "Morning",
                type: "Doctor",
                image: obj.doctor_midday[i].image,
                day: dayMorning,
                date: obj.doctor_midday[i].date, checked: false
              };
              this.foodarray.push({
                food_item_id: obj.doctor_midday[i].food_item_id,
                description: obj.doctor_midday[i].description,
                food_type_id: obj.doctor_midday[i].food_type_id,
                food_sub_type: obj.doctor_midday[i].food_sub_type,
                quantity: obj.doctor_midday[i].quantity != undefined ? obj.doctor_midday[i].quantity : "0.00",
                measure: obj.doctor_midday[i].measure != undefined ? obj.doctor_midday[i].measure : "0.00",
                weight: obj.doctor_midday[i].weight != undefined ? obj.doctor_midday[i].weight : "0.00",
                cals_weight: calsWight,
                wightMesure: wightMesure,
                energy: obj.doctor_midday[i].energy != undefined ? obj.doctor_midday[i].energy : "0.00",
                proteins: obj.doctor_midday[i].proteins != undefined ? obj.doctor_midday[i].proteins : "0.00",
                carbohydrates: obj.doctor_midday[i].carbohydrates != undefined ? obj.doctor_midday[i].carbohydrates : "0.00",
                fat: obj.doctor_midday[i].fat != undefined ? obj.doctor_midday[i].fat : "0.00",
                calcium: obj.doctor_midday[i].calcium != undefined ? obj.doctor_midday[i].calcium : "0.00",
                iron: obj.doctor_midday[i].iron != undefined ? obj.doctor_midday[i].iron : "0.00",
                vitaminC: obj.doctor_midday[i].vitaminC != undefined ? obj.doctor_midday[i].vitaminC : "0.00",
                vitaminA: obj.doctor_midday[i].vitaminA != undefined ? obj.doctor_midday[i].vitaminA : "0.00",
                vitaminB12: obj.doctor_midday[i].vitaminB12 != undefined ? obj.doctor_midday[i].vitaminB12 : "0.00",
                fiber: obj.doctor_midday[i].fiber != undefined ? obj.doctor_midday[i].fiber : "0.00",
                folic: obj.doctor_midday[i].folic != undefined ? obj.doctor_midday[i].folic : "0.00",
                h2o: obj.doctor_midday[i].h2o != undefined ? obj.doctor_midday[i].h2o : "0.00",
                session: "Morning",
                type: "Doctor",
                image: ipaddress.Ip_with_img_address + "/" + obj.doctor_midday[i].image,
                day: dayMorning,
                date: obj.doctor_midday[i].date
              })
              // this.mainDishFlag = obj.doctor_morning[i].main_course != "1" ? false : true;
              //  this.selctedFood(obj.doctor_morning[i].day, obj.doctor_morning[i].date, tempFoodItems, 0);
            }
          }
        }
        if (this.session == "Lunch" && obj.doctor_lunch != null && obj.doctor_lunch.length != 0) {
          if (obj.doctor_lunch != null) {
            var tempFoodItems = {};
            this.foodarray = []
            for (var i = 0; i < obj.doctor_lunch.length; i++) {
              var dayMorning = obj.doctor_lunch[i].day != undefined ? obj.doctor_lunch[i].day : " ";
              var tempWeight = obj.doctor_lunch[i].weight.split(" ");
              var calsWight = tempWeight[0];
              var wightMesure = tempWeight[1];

              this.foodarray.push({
                food_item_id: obj.doctor_lunch[i].food_item_id,
                description: obj.doctor_lunch[i].description,
                food_type_id: obj.doctor_lunch[i].food_type_id,
                food_sub_type: obj.doctor_lunch[i].food_sub_type,
                quantity: obj.doctor_lunch[i].quantity != undefined ? obj.doctor_lunch[i].quantity : "0.00",
                measure: obj.doctor_lunch[i].measure != undefined ? obj.doctor_lunch[i].measure : "0.00",
                weight: obj.doctor_lunch[i].weight != undefined ? obj.doctor_lunch[i].weight : "0.00",
                cals_weight: calsWight,
                wightMesure: wightMesure,
                energy: obj.doctor_lunch[i].energy != undefined ? obj.doctor_lunch[i].energy : "0.00",
                proteins: obj.doctor_lunch[i].proteins != undefined ? obj.doctor_lunch[i].proteins : "0.00",
                carbohydrates: obj.doctor_lunch[i].carbohydrates != undefined ? obj.doctor_lunch[i].carbohydrates : "0.00",
                fat: obj.doctor_lunch[i].fat != undefined ? obj.doctor_lunch[i].fat : "0.00",
                calcium: obj.doctor_lunch[i].calcium != undefined ? obj.doctor_lunch[i].calcium : "0.00",
                iron: obj.doctor_lunch[i].iron != undefined ? obj.doctor_lunch[i].iron : "0.00",
                vitaminC: obj.doctor_lunch[i].vitaminC != undefined ? obj.doctor_lunch[i].vitaminC : "0.00",
                vitaminA: obj.doctor_lunch[i].vitaminA != undefined ? obj.doctor_lunch[i].vitaminA : "0.00",
                vitaminB12: obj.doctor_lunch[i].vitaminB12 != undefined ? obj.doctor_lunch[i].vitaminB12 : "0.00",
                fiber: obj.doctor_lunch[i].fiber != undefined ? obj.doctor_lunch[i].fiber : "0.00",
                folic: obj.doctor_lunch[i].folic != undefined ? obj.doctor_lunch[i].folic : "0.00",
                h2o: obj.doctor_lunch[i].h2o != undefined ? obj.doctor_lunch[i].h2o : "0.00",
                session: "Morning",
                type: "Doctor",
                image: ipaddress.Ip_with_img_address + "/" + obj.doctor_lunch[i].image,
                day: dayMorning,
                date: obj.doctor_lunch[i].date,
                checked: false
              })
              // this.mainDishFlag = obj.doctor_morning[i].main_course != "1" ? false : true;
              //  this.selctedFood(obj.doctor_morning[i].day, obj.doctor_morning[i].date, tempFoodItems, 0);
            }
          }
        }
        if (this.session == "Evening" && obj.doctor_evening != null && obj.doctor_evening.length != 0) {
          if (obj.doctor_evening != null) {
            var tempFoodItems = {};
            this.foodarray = []
            for (var i = 0; i < obj.doctor_evening.length; i++) {
              var dayMorning = obj.doctor_evening[i].day != undefined ? obj.doctor_evening[i].day : " ";
              var tempWeight = obj.doctor_evening[i].weight.split(" ");
              var calsWight = tempWeight[0];
              var wightMesure = tempWeight[1];

              this.foodarray.push({
                food_item_id: obj.doctor_evening[i].food_item_id,
                description: obj.doctor_evening[i].description,
                food_type_id: obj.doctor_evening[i].food_type_id,
                food_sub_type: obj.doctor_evening[i].food_sub_type,
                quantity: obj.doctor_evening[i].quantity != undefined ? obj.doctor_evening[i].quantity : "0.00",
                measure: obj.doctor_evening[i].measure != undefined ? obj.doctor_evening[i].measure : "0.00",
                weight: obj.doctor_evening[i].weight != undefined ? obj.doctor_evening[i].weight : "0.00",
                cals_weight: calsWight,
                wightMesure: wightMesure,
                energy: obj.doctor_evening[i].energy != undefined ? obj.doctor_evening[i].energy : "0.00",
                proteins: obj.doctor_evening[i].proteins != undefined ? obj.doctor_evening[i].proteins : "0.00",
                carbohydrates: obj.doctor_evening[i].carbohydrates != undefined ? obj.doctor_evening[i].carbohydrates : "0.00",
                fat: obj.doctor_evening[i].fat != undefined ? obj.doctor_evening[i].fat : "0.00",
                calcium: obj.doctor_evening[i].calcium != undefined ? obj.doctor_evening[i].calcium : "0.00",
                iron: obj.doctor_evening[i].iron != undefined ? obj.doctor_evening[i].iron : "0.00",
                vitaminC: obj.doctor_evening[i].vitaminC != undefined ? obj.doctor_evening[i].vitaminC : "0.00",
                vitaminA: obj.doctor_evening[i].vitaminA != undefined ? obj.doctor_evening[i].vitaminA : "0.00",
                vitaminB12: obj.doctor_evening[i].vitaminB12 != undefined ? obj.doctor_evening[i].vitaminB12 : "0.00",
                fiber: obj.doctor_evening[i].fiber != undefined ? obj.doctor_evening[i].fiber : "0.00",
                folic: obj.doctor_evening[i].folic != undefined ? obj.doctor_evening[i].folic : "0.00",
                h2o: obj.doctor_evening[i].h2o != undefined ? obj.doctor_evening[i].h2o : "0.00",
                session: "Morning",
                type: "Doctor",
                image: ipaddress.Ip_with_img_address + "/" + obj.doctor_evening[i].image,
                day: dayMorning,
                date: obj.doctor_evening[i].date,
                checked: false
              })
              // this.mainDishFlag = obj.doctor_morning[i].main_course != "1" ? false : true;
              //  this.selctedFood(obj.doctor_morning[i].day, obj.doctor_morning[i].date, tempFoodItems, 0);
            }
          }
        }

        if (this.session == "Dinner" && obj.doctor_dinner != null && obj.doctor_dinner.length != 0) {
          if (obj.doctor_dinner != null) {
            var tempFoodItems = {};
            this.foodarray = []
            for (var i = 0; i < obj.doctor_dinner.length; i++) {
              var dayMorning = obj.doctor_dinner[i].day != undefined ? obj.doctor_dinner[i].day : " ";
              var tempWeight = obj.doctor_dinner[i].weight.split(" ");
              var calsWight = tempWeight[0];
              var wightMesure = tempWeight[1];
              tempFoodItems = {
                food_item_id: obj.doctor_dinner[i].food_item_id,
                description: obj.doctor_dinner[i].description,
                food_type_id: obj.doctor_dinner[i].food_type_id,
                food_sub_type: obj.doctor_dinner[i].food_sub_type,
                quantity: obj.doctor_dinner[i].quantity != undefined ? obj.doctor_dinner[i].quantity : "0.00",
                measure: obj.doctor_dinner[i].measure != undefined ? obj.doctor_dinner[i].measure : "0.00",
                weight: obj.doctor_dinner[i].weight != undefined ? obj.doctor_dinner[i].weight : "0.00",
                cals_weight: calsWight,
                wightMesure: wightMesure,
                energy: obj.doctor_dinner[i].energy != undefined ? obj.doctor_dinner[i].energy : "0.00",
                proteins: obj.doctor_dinner[i].proteins != undefined ? obj.doctor_dinner[i].proteins : "0.00",
                carbohydrates: obj.doctor_dinner[i].carbohydrates != undefined ? obj.doctor_dinner[i].carbohydrates : "0.00",
                fat: obj.doctor_dinner[i].fat != undefined ? obj.doctor_dinner[i].fat : "0.00",
                calcium: obj.doctor_dinner[i].calcium != undefined ? obj.doctor_dinner[i].calcium : "0.00",
                iron: obj.doctor_dinner[i].iron != undefined ? obj.doctor_dinner[i].iron : "0.00",
                vitaminC: obj.doctor_dinner[i].vitaminC != undefined ? obj.doctor_dinner[i].vitaminC : "0.00",
                vitaminA: obj.doctor_dinner[i].vitaminA != undefined ? obj.doctor_dinner[i].vitaminA : "0.00",
                vitaminB12: obj.doctor_dinner[i].vitaminB12 != undefined ? obj.doctor_dinner[i].vitaminB12 : "0.00",
                fiber: obj.doctor_dinner[i].fiber != undefined ? obj.doctor_dinner[i].fiber : "0.00",
                folic: obj.doctor_dinner[i].folic != undefined ? obj.doctor_dinner[i].folic : "0.00",
                h2o: obj.doctor_dinner[i].h2o != undefined ? obj.doctor_dinner[i].h2o : "0.00",
                session: "Morning",
                type: "Doctor",
                image: obj.doctor_dinner[i].image,
                day: dayMorning,
                date: obj.doctor_dinner[i].date, checked: false
              };
              this.foodarray.push({
                food_item_id: obj.doctor_dinner[i].food_item_id,
                description: obj.doctor_dinner[i].description,
                food_type_id: obj.doctor_dinner[i].food_type_id,
                food_sub_type: obj.doctor_dinner[i].food_sub_type,
                quantity: obj.doctor_dinner[i].quantity != undefined ? obj.doctor_dinner[i].quantity : "0.00",
                measure: obj.doctor_dinner[i].measure != undefined ? obj.doctor_dinner[i].measure : "0.00",
                weight: obj.doctor_dinner[i].weight != undefined ? obj.doctor_dinner[i].weight : "0.00",
                cals_weight: calsWight,
                wightMesure: wightMesure,
                energy: obj.doctor_dinner[i].energy != undefined ? obj.doctor_dinner[i].energy : "0.00",
                proteins: obj.doctor_dinner[i].proteins != undefined ? obj.doctor_dinner[i].proteins : "0.00",
                carbohydrates: obj.doctor_dinner[i].carbohydrates != undefined ? obj.doctor_dinner[i].carbohydrates : "0.00",
                fat: obj.doctor_dinner[i].fat != undefined ? obj.doctor_dinner[i].fat : "0.00",
                calcium: obj.doctor_dinner[i].calcium != undefined ? obj.doctor_dinner[i].calcium : "0.00",
                iron: obj.doctor_dinner[i].iron != undefined ? obj.doctor_dinner[i].iron : "0.00",
                vitaminC: obj.doctor_dinner[i].vitaminC != undefined ? obj.doctor_dinner[i].vitaminC : "0.00",
                vitaminA: obj.doctor_dinner[i].vitaminA != undefined ? obj.doctor_dinner[i].vitaminA : "0.00",
                vitaminB12: obj.doctor_dinner[i].vitaminB12 != undefined ? obj.doctor_dinner[i].vitaminB12 : "0.00",
                fiber: obj.doctor_dinner[i].fiber != undefined ? obj.doctor_dinner[i].fiber : "0.00",
                folic: obj.doctor_dinner[i].folic != undefined ? obj.doctor_dinner[i].folic : "0.00",
                h2o: obj.doctor_dinner[i].h2o != undefined ? obj.doctor_dinner[i].h2o : "0.00",
                session: "Morning",
                type: "Doctor",
                image: ipaddress.Ip_with_img_address + "/" + obj.doctor_dinner[i].image,
                day: dayMorning,
                date: obj.doctor_dinner[i].date
              })
              // this.mainDishFlag = obj.doctor_morning[i].main_course != "1" ? false : true;
              //  this.selctedFood(obj.doctor_morning[i].day, obj.doctor_morning[i].date, tempFoodItems, 0);
            }
          }
        }
        if (this.session == "Night" && obj.doctor_night != null && obj.doctor_night.length != 0) {
          if (obj.doctor_night != null) {
            var tempFoodItems = {};
            this.foodarray = []
            for (var i = 0; i < obj.doctor_night.length; i++) {
              var dayMorning = obj.doctor_night[i].day != undefined ? obj.doctor_night[i].day : " ";
              var tempWeight = obj.doctor_night[i].weight.split(" ");
              var calsWight = tempWeight[0];
              var wightMesure = tempWeight[1];

              this.foodarray.push({
                food_item_id: obj.doctor_night[i].food_item_id,
                description: obj.doctor_night[i].description,
                food_type_id: obj.doctor_night[i].food_type_id,
                food_sub_type: obj.doctor_night[i].food_sub_type,
                quantity: obj.doctor_night[i].quantity != undefined ? obj.doctor_night[i].quantity : "0.00",
                measure: obj.doctor_night[i].measure != undefined ? obj.doctor_night[i].measure : "0.00",
                weight: obj.doctor_night[i].weight != undefined ? obj.doctor_night[i].weight : "0.00",
                cals_weight: calsWight,
                wightMesure: wightMesure,
                energy: obj.doctor_night[i].energy != undefined ? obj.doctor_night[i].energy : "0.00",
                proteins: obj.doctor_night[i].proteins != undefined ? obj.doctor_night[i].proteins : "0.00",
                carbohydrates: obj.doctor_night[i].carbohydrates != undefined ? obj.doctor_night[i].carbohydrates : "0.00",
                fat: obj.doctor_night[i].fat != undefined ? obj.doctor_night[i].fat : "0.00",
                calcium: obj.doctor_night[i].calcium != undefined ? obj.doctor_night[i].calcium : "0.00",
                iron: obj.doctor_night[i].iron != undefined ? obj.doctor_night[i].iron : "0.00",
                vitaminC: obj.doctor_night[i].vitaminC != undefined ? obj.doctor_night[i].vitaminC : "0.00",
                vitaminA: obj.doctor_night[i].vitaminA != undefined ? obj.doctor_night[i].vitaminA : "0.00",
                vitaminB12: obj.doctor_night[i].vitaminB12 != undefined ? obj.doctor_night[i].vitaminB12 : "0.00",
                fiber: obj.doctor_night[i].fiber != undefined ? obj.doctor_night[i].fiber : "0.00",
                folic: obj.doctor_night[i].folic != undefined ? obj.doctor_night[i].folic : "0.00",
                h2o: obj.doctor_night[i].h2o != undefined ? obj.doctor_night[i].h2o : "0.00",
                session: "Morning",
                type: "Doctor",
                image: ipaddress.Ip_with_img_address + "/" + obj.doctor_night[i].image,
                day: dayMorning,
                date: obj.doctor_night[i].date,
                checked: false
              })
              // this.mainDishFlag = obj.doctor_morning[i].main_course != "1" ? false : true;
              //  this.selctedFood(obj.doctor_morning[i].day, obj.doctor_morning[i].date, tempFoodItems, 0);
            }
          }
        }
        





        console.log("check foodarray" + JSON.stringify(this.foodarray))

      },
      error => {
      })


  }
  toggleFlagCheck(bedId, wardid, warddata) {
    this.appGetDate('Medicine'); 

    // Example usage
    if (warddata.name != "Vacant") {
      var result = this.isBedOccupied(wardid, bedId);
      console.log("warddata --" + JSON.stringify(warddata))
      console.log("warddata -bedId-" + JSON.stringify(bedId))
      console.log("wardid --" + JSON.stringify(wardid))
      console.log(result);
      this.foodarray = [];this.Medicine_Hist_List=[]
      const dialogRef = this.dialog.open(WarstrackerInfoComponent, {
        width: '33%',
        height: '500px',
        data: {
         warddata:warddata,Title:'Medicine'
        }
      });
      dialogRef.afterClosed().subscribe(result => {
  
  
        if (result != undefined) {
          
        }
  
  
      });
    
    } else {
      this.Medicine_Hist_List = [];
      var result = this.isBedOccupied1(wardid, bedId);
    }


  }
  isBedOccupied(wardId, bedNumber) {
    var jsonData = this.wardBeds;

    for (var ward of jsonData) {
      if (ward.ward_id == wardId) {
        for (var bed of ward.bed_details) {
          if (bed.bed_no === bedNumber) {
            bed.isselected = true;
            ward.selectedBedNo = bed.bed_no; // Update the selected bed number for the ward
            return true; // Bed is occupied
          }
        }
      }
    }
    return false; // Bed is vacant or not found
  }
  isBedOccupied1(wardId, bedNumber) {
    var jsonData = this.wardBeds;

    for (var ward of jsonData) {
      for (var bed of ward.bed_details) {
        if (bed.bed_no === bedNumber) {
          bed.isselected = false;
          ward.selectedBedNo = bed.bed_no; // Update the selected bed number for the ward
          return true; // Bed is occupied

        }
      }
    }
    return false; // Bed is vacant or not found
  }


  medicinetracker(warddata) {
    this.med_url_txt = ipaddress.getIp + "lifestyle/pmeds";
    console.log("ward data ****"+JSON.stringify(warddata))
    if (warddata.relation_id != undefined) {
      this.relation_id = warddata.relation_id;
    }
    if (warddata.sub_rel_id != undefined) {
      this.sub_rel_id = warddata.sub_rel_id;
    }
    if (warddata.client_reg_id != undefined) {
      this.client_reg_id = warddata.client_reg_id;
    }

    this.sub_rel_id = this.sub_rel_id
    if (this.sub_rel_id != undefined && this.sub_rel_id != null && this.sub_rel_id != "") {
      this.send_data_med = {
        client_reg_id: this.client_reg_id,
        relation_id: this.relation_id,
        sub_rel_id: this.sub_rel_id,
        time_string: this.session
      }
    }
    else {
      this.send_data_med = {
        client_reg_id: this.client_reg_id,
        relation_id: this.relation_id,
        time_string: this.session
      }
    }
    console.log("ward data ****"+JSON.stringify(this.send_data_med))
    var image;
    var headers = new Headers();

    headers.append('Content-Type', 'application/json');
    this.http.post(this.med_url_txt, (this.send_data_med), { headers: headers }).subscribe(
      data => {
        var shortform;
        this.Medicine_Hist_List = [];
        var obj = JSON.parse(data["_body"]);
        console.log("check data" + JSON.stringify(obj))
        var obj1 = obj.medicines;
        if (obj.medicines != null && obj.medicines.length != 0) {
          for (var i = 0; i < obj.medicines.length; i++) {
            if (obj.medicines[i].short_form != null && obj.medicines[i].short_form != undefined && obj.medicines[i].short_form != "") {
              shortform = obj.medicines[i].short_form;
            }
            else {
              shortform = "no(s)";
            }
            if (obj.medicines[i].short_name != null) {
              if (obj.medicines[i].short_name == "TAB") {
                image = "../../../assets/imgs/homepagemobileicons/Aspirin_tab_icon.png"
              }
              else if (obj.medicines[i].short_name == "CAP") {
                image = "../../../assets/imgs/homepagemobileicons/Synthroid_icon.png"
              }
              else if (obj.medicines[i].short_name == "Drops") {
                image = "../../../assets/imgs/homepagemobileicons/eyedrops_icon.png"
              }
            }
            var retintake = obj.medicines[i].drug_intake;
            // if (retintake != null) {
            //   for (var j = 0; j < this.mainarray.length; j++) {
            //     if (retintake == this.mainarray[j].intake_txt) {
            //       var inatkeform = this.mainarray[j].intakedesc;
            //     }
            //   }
            // }
            this.Medicine_Hist_List.push({
              drug_id: obj.medicines[i].drug_id,
              drug_name: obj.medicines[i].drug_name,
              quantity: obj.medicines[i].quantity,
              short_form: shortform,
              time_duration: obj.medicines[i].time_duration,
              drug_inake: obj.medicines[i].drug_intake,
              tabimg: image,
            
            });
            console.log("check *****" + JSON.stringify(this.Medicine_Hist_List))
            Nurse_Helper.setwardinfomedicinetracker(null);
            
            // if (this.Medicine_Hist_List.length != 0) {
            //   this.nodata = false;
            // }
            // else {
            //   this.nodata = true;
            // }
          }
        } else {
          //  this.nodata = true;
          this.Medicine_Hist_List = [];
        }
      },
      error => {
      })


  }
  gotoback(){
    if(Helper_Class.getapp_flowinpa() != undefined){
      this.nurseservice.sendMessage("viewIpatient")
    }
   
  }


  
}
