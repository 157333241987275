<div mat-dialog-title class="popup_border_icd">
  <h5 class="header_txt">{{header_text}}</h5>
  <div mat-dialog-actions>
    <img src="../../../assets/img/close_icon.png" (click)="ClosePopup()" width="20px" height="20px" id="close_icd" />
  </div>
</div>
<div mat-dialog-content class="neph_content">
  <div class="container">
    <div class="row">
      <div id="nephro_popup_grph" class="chart_div graph_size"></div>
    </div>
  </div>
</div>
<div mat-dialog-actions align="end" style="width: auto;right: 19px;">
</div>