import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from '../../helper_class';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Message_data } from '../../../assets/js/Message_data';
import { encrypt_decript } from 'src/assets/js/Encription_Decription';
import { FormControl } from '@angular/forms';
import { Time_Formate, Time_Formate_Session } from '../../../assets/js/common';
import { FrontDesk_Helper } from '../FrontDesk_Helper';
import { FrontDeskService } from '../front-desk/front-desk.service';
import { ipaddress } from '../../ipaddress' ;import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
declare var $: any;

@Component({
  selector: 'app-doc-ref-create',
  templateUrl: './doc-ref-create.component.html',
  styleUrls: ['./doc-ref-create.component.scss']
})
export class DocRefCreateComponent implements OnInit {
  @ViewChild('printref') printref: ElementRef
  public referralist: any;
  public userInfo;
  public ipaddress;
  dtOptions: DataTables.Settings = {};
  private currentDate;
  public filter;
  public maxLength: number;
  public somePlaceholder;
  public filterType;
  public patientFName;
  public clientList;
  public clntName;
  public clientId;
  public clntAge: string;
  public clntGender: string;
  public clientRegId: string;
  public relationId: string;
  public subrelid: string;
  public clntSalList: any = [];
  public clntSal: string;
  public clntContNumber: string;
  public Refered_doctor = [];
  public Refered_name = [];
  public refer_txt;
  public create_flag: boolean = true;
  public special_txt;
  public spl_id: string;
  public specializationList: any = [];
  public locationUrl; string;
  public locationNameByUrl: string;
  public cityUrl: string;
  public stateUrl: string;
  public hosp_txt: string;
  public hosplitallist: any = [];
  public hospStateList; any = [];
  public hosplist: any = [];
  public hospStateId: string;
  public hospState: string;
  public hospCityList: any = [];
  public hospCity: string;
  public hospCityId: string;
  public hospLocationList: any = [];
  public hospLocationId: string;
  public hospLocation: string;
  public hospZipcode: string;
  public hospid: string;
  public hospcountryid: string;
  public hosptelno: string;
  public ref_by: string;
  public doctorList: any[];
  public doctor_list=[];
  public doctorName: string;
  public ref_to: string;
  public speciality: string;
  public Hptl_name: string;
  public notes: string;
  public checked: boolean;
  public save_data: boolean=false;
  
  constructor(public toastr: ToastrService, public frontdeskservice: MenuViewService,
    public messageservice: MenuViewService, public http: HttpClient) {
    this.locationUrl = ipaddress.getIp + "usercontroller/loc";
    this.locationNameByUrl = ipaddress.getIp + "usercontroller/locbyname";
    this.cityUrl = ipaddress.getIp + "usercontroller/city";
    this.stateUrl = ipaddress.getIp + "usercontroller/state";
  }

  ngOnInit(): void {
    this.userInfo = Helper_Class.getInfo();
    console.log("USER INTO " + JSON.stringify(this.userInfo))
    this.hospStateId = this.userInfo.state;
    this.hospCityId = this.userInfo.city;
    this.ipaddress = Helper_Class.getIPAddress();
    //this.hospcountryid = 
    this.getCurrentDate();
    this. getDoctors();
    this. getDocHospDetails();
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 25,
      "lengthMenu": [[25, 50, -1], [25, 50, "All"]],
      order: [[2, 'desc']],
      "columnDefs": [
        { "orderable": false, "targets": [0] },
      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search by doctor name, hospital"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
  }

  getCurrentDate() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'adm/curdate',
      {
        country: ipaddress.country_code
      },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response))
          if (obj.current_date != null) {
            this.currentDate = obj.current_date;
          }
        }, error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  getPatientList() {
    if (this.filter == "First name") {
      this.maxLength = 50;
      this.somePlaceholder = "Search by first name";
      this.filterType = "0";
      this.patientFName = "";
      this.clientList = [];
      this.clntName = "";
    } else if (this.filter == "Mobile") {
      this.maxLength = 10;
      this.somePlaceholder = "Search by mobile number";
      this.filterType = "1";
      this.patientFName = "";
      this.clientList = [];
      this.clntName = "";
    } else if (this.filter == "Patient Id") {
      this.maxLength = 20;
      this.somePlaceholder = "Search by patient id";
      this.filterType = "2";
      this.patientFName = "";
      this.clientList = [];
      this.clntName = "";
    }
  }

  goToPatientlist() {
    this.messageservice.sendMessage("docref");
  }

  changeName() {
    this.clientList = [];
    this.patientFName = this.patientFName.toLocaleUpperCase();
  }

  changePatientName(name) {//based client search filters patient  names
    this.clntName = null;
    this.clntAge = "";
    this.clntGender = "";

    var type_name;
    if (name != undefined && name != "") {
      if (this.filter == "name")
        type_name = "name";
      else if (this.filter == "mobile")
        type_name = "mobile";
      else
        type_name = "patient_id";

      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'adm/cid', {
        first_name: name,
        type: type_name,
        hptl_clinic_id: Helper_Class.getHospitalId(),
      },
        { headers: headers })
        .subscribe(
          response => {
            this.clientList = [];
            var obj = JSON.parse(JSON.stringify(response));
            console.log("RETURNED CLIENTS " + JSON.stringify(obj.clients))
            if (obj.clients != null && obj.clients.length != 0)
              this.clientList = obj.clients;
            else
              this.toastr.error(Message_data.clientNotFound);

            if (obj.clients.length != 0) {
              this.clientId = this.clientList[0].patient;
              this.changeClient(this.clientList[0].patient);
            }
          },
          error => {
            this.toastr.error(Message_data.defaultErr);
          });
    } else {
      this.toastr.error(Message_data.fillText);
    }
  }

  patientNameToUpper() {
    if (this.patientFName != undefined) {
      if (this.filter == "name")
        this.patientFName = this.patientFName.toLocaleUpperCase();
    }
  }

  changeClient(client) {// get the the client detials based on patient id
    this.clntName = "";
    for (var i = 0; i < this.clientList.length; i++) {
      if (this.clientList[i].patient == client) {
        this.clntName = (this.clientList[i].clntName);
        this.clientRegId = this.clientList[i].client_reg_id;
        this.relationId = this.clientList[i].relation_id;
        this.subrelid = this.clientList[i].sub_rel_id;
      }
    }
    this.retrieveClient();
  }

  getDoctors() {
    // this.appointmentList = [];
    this.doctorList = [];
    var send_data = {
      country: "IN",
      reg_id: "6",
      type: 'front-desk',
    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'adm/docbyrep', send_data,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          if (obj != null) {
            for (var i = 0; i < obj.doctors.length; i++) {
              this.doctorName = "";
              var fname, mname, lname;
              if (obj.doctors[i].middle_name != "" && obj.doctors[i].middle_name != undefined) {
                this.doctorName = obj.doctors[i].first_name + " " + obj.doctors[i].middle_name + " " + obj.doctors[i].last_name;
                fname = obj.doctors[i].first_name; mname = obj.doctors[i].middle_name; lname = obj.doctors[i].last_name;
              } else {
                this.doctorName = obj.doctors[i].first_name + " " + obj.doctors[i].last_name;
                fname = obj.doctors[i].first_name; lname = obj.doctors[i].last_name;
              }

              this.doctorList.push({
                Doc_Name: this.doctorName,
                Doc_ID: obj.doctors[i].prov_id,
              });
              this.ref_by = this.doctorList[0].Doc_Name;
            }
            console.log(this.doctorList);
          }
        })
  }

  retrieveClient() {
    var get_data;
    if (this.subrelid != null && this.subrelid != undefined) {
      get_data = {
        user_id: this.clientRegId,
        relation_id: this.relationId,
        sub_rel_id: this.subrelid,
        country: ipaddress.country_code
      };
    } else {
      get_data = {
        user_id: this.clientRegId,
        relation_id: this.relationId,
        country: ipaddress.country_code
      };
    }

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'usercontroller/grclnt/',
      get_data,
      { headers: headers })
      .subscribe(
        response => {
          FrontDesk_Helper.setClientId(null);
          var obj = JSON.parse(JSON.stringify(response));

          if (obj.client_id != null) {
            FrontDesk_Helper.setClientId(obj.client_id);
            // Editable Fields
            if (obj.salutation != null) {
              for (var i = 0; i < this.clntSalList.length; i++) {
                if (this.clntSalList[i].sal_desc == obj.salutation) {
                  this.clntSal = this.clntSalList[i].sal_id;
                }
              }
            }
            var clnname;
            if (obj.middle_name != undefined) {
              this.clntName = encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.middle_name) + " " + encrypt_decript.Decript(obj.last_name);
            } else {
              this.clntName = encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.last_name);
            }
            this.clntGender = obj.gender != null && (encrypt_decript.Decript(obj.gender) != "") ? encrypt_decript.Decript(obj.gender) : "";
            this.clntContNumber = obj.mobile != null && obj.mobile != "" && obj.mobile != undefined && (encrypt_decript.Decript(obj.mobile) != "") ? encrypt_decript.Decript(obj.mobile) : "";

            if (obj.age != undefined && obj.age != null && obj.age != "") {
              this.clntAge = obj.age;
            }
          }

        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }

  getReferralData(e) {
    this.Refered_doctor = [];
    this.Refered_name = [];
    this.refer_txt = e.target.value.toString();
    if (this.refer_txt != "" && this.refer_txt.length > 2) {
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'search/docbyhns/', {
        doc_name: this.refer_txt
      },
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));
            this.Refered_name = obj.doctors;

            if (this.Refered_name != undefined) {
              for (var i = 0; i < this.Refered_name.length; i++) {
                var docname;
                if (this.Refered_name[i].middle_name != undefined) {
                  docname = this.Refered_name[i].first_name + " " + this.Refered_name[i].middle_name + " " + this.Refered_name[i].last_name;

                } else {
                  docname = this.Refered_name[i].first_name + " " + this.Refered_name[i].last_name;
                }
                this.Refered_doctor.push(docname);
              }
            }
            var name = this.refer_txt;
            this.Refered_doctor = this.Refered_doctor.filter(function (el) {
              return el.toLowerCase().indexOf(name.toLowerCase()) > -1;
            }.bind(this));
          },
          error => {
            this.toastr.error(Message_data.defaultErr);
          }
        )
    } else {
      this.Refered_doctor = [];
    }
  }

  Select_refer_doctor(item) {
    this.refer_txt = item;
    this.Refered_doctor = [];
  }

  getSpecialities() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp + "usercontroller/getspecialization").subscribe(
      data => {
        var dataval = JSON.parse(JSON.stringify(data));
        for (var i = 0; i < dataval.specialization.length; i++) {
          if (dataval.specialization[i].spl_name != "-") {
            this.specializationList.push({
              spl_name: dataval.specialization[i].spl_name,
              spl_id: dataval.specialization[i].spl_id
            });

            this.special_txt = this.specializationList[0].spl_name;
          }
        }
      }, error => { });
  }

  changeSpeciality(id, name) {
    this.special_txt = name;
    this.spl_id = id;
  }

  getHospitals(e) {
    this.hosplitallist = [];
    this.Refered_name = [];
    this.hosp_txt = e.target.value.toString();
    if (this.hosp_txt != "" && this.hosp_txt.length > 2) {
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'adm/hpdet/', {
        hosp_name: this.hosp_txt
      },
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));
            if (obj != null && obj.hospitals != undefined) {
              this.hosplitallist = obj.hospitals;
              if (this.hosplitallist.length != 0) {
                for (var i = 0; i < this.hosplitallist.length; i++) {
                  this.hosplist.push(this.hosplitallist[i].hptl_name)
                }
              }
              var hname = this.hosp_txt;
              this.hosplist = this.hosplist.filter(function (el) {
                return el.toLowerCase().indexOf(hname.toLowerCase()) > -1;
              }.bind(this));

            } else {
              this.getStates(this.userInfo.country, "0");
            }

          },
          error => {
            this.toastr.error(Message_data.defaultErr);
          }
        )
    } else {
      this.Refered_doctor = [];
    }
  }

  selecteHospital(data) {
    if (this.hosplitallist.length != 0) {
      for (var i = 0; this.hosplitallist.length; i++) {
        if (this.hosplitallist[i].hptl_name == data) {
          this.hosp_txt = this.hosplitallist[i].hptl_name;
          this.hospid = this.hosplitallist[i].hptl_clinic_id;
          this.hospcountryid = this.hosplitallist[i].country_id;
          this.hospStateId = this.hosplitallist[i].state_id;
          this.hospCityId = this.hosplitallist[i].city_id;
          this.hospLocationId = this.hosplitallist[i].location_id;
          this.hospLocation = this.hosplitallist[i].location_desc;
          this.hospZipcode = this.hosplitallist[i].zipcode;
          this.hosptelno = this.hosplitallist[i].telephone;
          this.getStates(this.hospcountryid, "0");
        }
      }
    }
  }

  getStates(country, flag) {
    this.hospStateList = [];
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.stateUrl, { country_id: country },
      { headers: headers }).subscribe(
        data => {
          var dataval = JSON.parse(JSON.stringify(data))
          if (dataval.states != null) {
            this.hospStateList = dataval.states;
            if (this.hospStateId != undefined) {
              for (var i = 0; i < this.hospStateList.length; i++) {
                if (this.hospStateList[i].state_id == this.hospStateId) {
                  this.hospStateId = this.hospStateList[i].state_id;
                  this.hospState = this.hospStateList[i].description;
                  this.getCities(this.hospStateId, flag);
                  break;
                }
              }
            }

          } else {
            this.getCities(this.hospStateList[0].state_id, flag);
          }
        });
  }

  changeState(clnt_state, flag) {
    for (var i = 0; i < this.hospStateList.length; i++) {
      if (this.hospStateList[i].description == clnt_state) {
        this.hospStateId = this.hospStateList[i].state_id;
        this.hospState = this.hospStateList[i].description;
        this.hospCityList = [];
        this.hospCityId = undefined;
        this.hospLocationId = undefined;
        this.hospLocation = "";
        this.hospZipcode = "";
        this.getCities(this.hospStateId, flag);
      }
    }
  }

  getCities(state, flag) {
    this.hospCityList = [];
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.cityUrl, { state_id: state },
      { headers: headers }).subscribe(
        data => {
          var dataval = JSON.parse(JSON.stringify(data))
          if (dataval.cities != null) {
            this.hospCityList = dataval.cities;
            for (var i = 0; i < this.hospCityList.length; i++) {
              if (this.hospCityList[i].district_id == this.hospCityId) {
                this.hospCityId = this.hospCityList[i].district_id;
                this.hospCity = this.hospCityList[i].description;
                this.getLocations(this.hospCityList);
                break;
              } else {
                this.hospCity = this.hospCityList[0].description;
              }
            }
          }
        });
  }

  changeCity(clnt_city, flag) {
    this.hospLocationList = [];
    for (var i = 0; i < this.hospCityList.length; i++) {
      if (this.hospCityList[i].description == clnt_city) {
        this.hospCityId = this.hospCityList[i].district_id;
        this.hospCity = this.hospCityList[i].description;
        this.hospLocationId = undefined;
        this.hospLocation = "";
        this.hospZipcode = "";
      }
    }
  }

  getLocations(city) {
    this.hospLocationList = [];
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.locationUrl, { city_id: city },
      { headers: headers }).subscribe(
        data => {
          var dataval = JSON.parse(JSON.stringify(data))
          if (dataval.locations != null && dataval.locations.length != 0) {
            this.hospLocationList = dataval.locations;
            for (var i = 0; i < this.hospLocationList.length; i++) {
              if (this.hospLocationList[i].location_id == this.hospLocationId) {
                this.hospLocationId = this.hospLocationList[i].location_id;
                this.hospLocation = this.hospLocationList[i].description;
                this.hospZipcode = this.hospLocationList[i].pincode;
                this.hospLocationList = [];
                break;
              }
            }
          }
        });
  }

  changeLocation(locat_desc) {
    this.hospLocation = locat_desc.target.value.toString();
    this.hospLocationList = [];

    if (this.hospLocation != null && this.hospLocation.length > 2) {
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.locationNameByUrl, {
        name: this.hospLocation,
        city_id: this.hospCityId,
      }, { headers: headers }).subscribe(
        data => {
          var dataval = JSON.parse(JSON.stringify(data))
          if (dataval.locations != null && dataval.locations.length != 0)
            this.hospLocationList = dataval.locations;
        },
        error => {
          this.toastr.error(Message_data.unableToFetchData);
        });
    } else {
      this.hospLocationList = [];
    }
  }

  selectLocation(location) {
    this.hospLocation = location;
    for (var i = 0; i < this.hospLocationList.length; i++) {
      if (this.hospLocationList[i].location_id == location.location_id) {
        this.hospLocationId = this.hospLocationList[i].location_id;
        this.hospLocation = this.hospLocationList[i].description;
        this.hospZipcode = this.hospLocationList[i].pincode;
      }
    }
    this.hospLocationList = [];
  }

  create() {
    this.create_flag = false;
    this.getSpecialities();
  }

  getDocHospDetails(){
    this.doctor_list=[]
    var headers=new HttpHeaders();
    this.http.post(ipaddress.getIp+"gen/gdrh",{hptl_clinic_id: Helper_Class.getHospitalId()},{headers:headers}).subscribe(result=>{
     console.log(result);
     var data=JSON.parse(JSON.stringify(result))
     var doc_list=data.doctor_details
     console.log(doc_list);
     if (doc_list!==null) {
       for (let i = 0; i < doc_list.length; i++) {
         this.doctor_list.push({
           doc_name:doc_list[i].doc_name,
           spl_name:doc_list[i].speciality,
           Hptl_name:doc_list[i].ref_hosp_name,
           hptl_adrs:doc_list[i].location+", "+doc_list[i].city+", "+doc_list[i].state
         })
       }
       console.log(this.doctor_list);
       
     }
    })
 }

  print_area() {
    if (this.ref_by != undefined && this.ref_to != undefined && this.speciality != undefined 
      && this.Hptl_name != undefined && this.clientRegId != undefined && this.notes != undefined) {
      var send_data = {
        country: "in",
        hptl_clinic_id: "55",
        referred_by: this.ref_by,
        referred_to: this.ref_to,
        speciality: this.speciality,
        ref_hospital: this.Hptl_name,
        client_reg_id: this.clientRegId,
        notes: this.notes
      }

    } else {
        this.toastr.error("Enter mandatory fields")
    }

    if (send_data) {
      var headers = new HttpHeaders()
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "gen/srh", send_data, { headers: headers }).subscribe(response => {
        var data =JSON.parse(JSON.stringify(response));
        if(data.key =="1"){
          this.save_data = true;
          this.toastr.success("Successfully update doctor referral details")
          let printContents, popupwin
      printContents = this.printref.nativeElement.innerHTML
      console.log(printContents);
  
      popupwin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
      popupwin.document.open();
      popupwin.document.write(`
        <html>
          <head>
            <title>Bill</title>
            <link rel="stylesheet" media="screen" href="">
            <style>
            @page { size: auto;  margin: 10mm 0mm 10mm 0mm; }
            @media print {
              @page {
                margin-top: 0;
                margin-bottom: 0;
              }
              body {
                padding-top: 72px;
                padding-bottom: 72px ;
              }
            }
            </style>
          </head>
          <body onload="window.print();window.close();">${printContents}</body>
        </html>`
      );
      popupwin.document.close();
        }
      })
    }
  }

  saveRefDocDetails() {
    var hospital = {
      hptl_clinic_id: Helper_Class.getHospitalId(),
      ref_hosp_name: this.hosp_txt,
      doc_name: this.refer_txt,
      location: this.hospLocation,
      city: this.hospCity,
      state: this.hospState,
      speciality: this.special_txt
    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + "gen/sdrh/", hospital,
      { headers: headers })
      .subscribe(
        response => {
          var result = JSON.parse(JSON.stringify(response))
          if(result.key =="1"){
            this. getDocHospDetails();
            this.hosp_txt="";
            this.hospLocation="";
            this.hospCity="";
            this.hospState="";
            this.special_txt="";
            this.refer_txt="";
            this.create_flag=true;
            this.toastr.success("Successfully added doctor details")
          }
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        })
  }
  getDoctor(doclist){
    this.ref_to=doclist.doc_name
    this.speciality=doclist.spl_name
    this.Hptl_name=doclist.Hptl_name
    this.checked=true
  }

  saveDocRef(){
    console.log(">>>>>>>"+this.ref_by+this.speciality+this.clientRegId)
    console.log(">>>>>>>"+this.ref_to+this.Hptl_name+this.notes)
    if (this.ref_by != undefined && this.ref_to != undefined && this.speciality != undefined 
      && this.Hptl_name != undefined && this.clientRegId != undefined && this.notes != undefined) {
      var send_data = {
        country: "in",
        hptl_clinic_id: "55",
        referred_by: this.ref_by,
        referred_to: this.ref_to,
        speciality: this.speciality,
        ref_hospital: this.Hptl_name,
        client_reg_id: this.clientRegId,
        notes: this.notes
      }

    } else {
        this.toastr.error("Enter mandatory fields")
    }

    if (send_data) {
      var headers = new HttpHeaders()
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "gen/srh", send_data, { headers: headers }).subscribe(response => {
        var data =JSON.parse(JSON.stringify(response));
        if(data.key =="1"){
          this.save_data = true;
          this.toastr.success("Successfully update doctor referral details")
        }
      })
    }
  }
}
