<div class="row" style="margin:0 auto; width:100%">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Donor</h5>
          </div>
          <div class="headerButtons">
            <img (click)="update()" src="../../../assets/ui_icons/buttons/update_button.svg"
              class="saveimgbtn_inpatinfo">
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row">
          <div class="col-5">
            <div *ngFor="let person of donorsList">
              <mat-card class="cardcontent" (click)="checkDonar(person.client_reg_id,person.check)">
                <mat-card-content>
                  <div class="row">
                    <div class="col-12">
                      <div style="position: relative;">
                        <img [src]="person.check == true ? '../../../assets/ui_icons/popup_tick_mark.svg' : ''"
                          class="check_event">
                      </div>
                    </div>
                    <div class="appointment_name col-8">
                      <p class="matlabel">{{"Name : "+ person.name}}</p>
                      <p class="matlabel">{{"Mob : "+ person.mobile}}</p>
                      <div class="row">
                        <div class="col-4">
                          <mat-icon (click)="minus()">remove</mat-icon>
                        </div>
                        <div class="col-4">
                          <mat-label class="matlabel">
                            <input class="ipcss widthappt" [(ngModel)]="unit" maxlength="1">
                          </mat-label>
                        </div>
                        <div class="col-4">
                          <mat-icon (click)="plus()">add</mat-icon>
                        </div>
                      </div>
                      <!-- </span>
                    </div> -->
                      <!-- </div> -->
                    </div>
                    <div class="col-4">
                      <img src="{{person.profile_image}}" class="img-fluid z-depth-1 rounded-circle mr-1">
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>