<div mat-dialog-title class="popup_border_icd">
  <h5 class="header_txt">Schedule</h5>
  <div mat-dialog-actions>
    <img src="../../../assets/img/close_icon.png" (click)="ClosePopup()" width="20px" height="20px" id="close_icd" />
  </div>
</div>
<div mat-dialog-content class="icd_content">
  <div class="Details" style="padding:10px 32px">
    <div class="row">
      <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
        <mat-label class="matlabel">From<br>
          <input type="date" id="appt_date" class="ipcss_date" (change)="OnDateChangedfrom(From_date)"
            [(ngModel)]="From_date" matInput min="{{currentDate}}">
        </mat-label>
      </div>
      <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
        <mat-label class="matlabel">To<br>
          <input type="date" id="appt_date" class="ipcss_date" (change)="OnDateChangedTo(To_date)"
            [(ngModel)]="To_date" matInput min="{{currentDate}}">
        </mat-label>
      </div>
      <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
        <div class="row">
          <div class="col-9" style="padding: 15px 0;">
            <mat-label class="matlabel iplen"> Full day
            </mat-label>
          </div>
          <div class="col-3" style="padding: 15px 0;">
            <mat-checkbox color="primary" (change)="fulldayChange($event)" class="matlabel"></mat-checkbox>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
        <mat-label class="matlabel">Reason<br>
          <select class="ipcss " required [(ngModel)]="reason_txt">
            <option value="select" disabled>select</option>
            <option value="Leave">Leave</option>
            <option value="Operation Theatre">Operation Theatre</option>
            <option value="Conference">Conference</option>
          </select>
        </mat-label>
      </div>
      <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3" *ngIf="!showFullday">
        <mat-label class="matlabel">Session<br>
          <select class="ipcss" (ngModelChange)="selectSession($event)" required [(ngModel)]="session_txt">
            <option value="select" disabled>select</option>
            <option value="Morning">Morning</option>
            <option value="Afternoon">Afternoon</option>
            <option value="Evening">Evening</option>
          </select>
        </mat-label>
      </div>
      <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4" *ngIf="!showFullday">
        <mat-label class="matlabel">From</mat-label>
        <div class="row">
          <div class="col-4" style="padding-right: 0px;">
              <select class="ipcss  " [(ngModel)]="Start_time">
                <option *ngFor="let name of starts_from_array" value="{{name}}">{{name}}</option>
              </select>
          </div>
          <div class="col-4" [hidden]="session_hide" style="padding-right: 0px;">
              <select class="ipcss " [(ngModel)]="end_time">
                <option *ngFor="let name of ends_at_array" value="{{name}}">{{name}}</option>
              </select>
          </div>
          <div class="col-4" [hidden]="session_hide" style="padding-right: 0px;">
              <select class="ipcss" [(ngModel)]="time_session">
                <option value="AM" Selected>AM</option>
                <option value="PM">PM</option>
              </select>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4" *ngIf="!showFullday">
        <mat-label class="matlabel" [hidden]="session_hide">To</mat-label>
        <div class="row">
          <div class="col-4" [hidden]="session_hide" style="padding-right: 0px;">
              <select class="ipcss" [(ngModel)]="Start_time1">
                <option *ngFor="let name of starts_from_array" value="{{name}}">{{name}}</option>
              </select>
          </div>
          <div class="col-4" [hidden]="session_hide" style="padding-right: 0px;">
              <select class="ipcss" [(ngModel)]="end_time1">
                <option *ngFor="let name of ends_at_array" value="{{name}}">{{name}}</option>
              </select>
          </div>
          <div class="col-4" [hidden]="session_hide" style="padding-right: 0px;">
              <select class="ipcss" [(ngModel)]="time_session_end">
                <option value="AM" Selected>AM</option>
                <option value="PM">PM</option>
              </select>
          </div>

        </div>
      </div>
      <div class="col-12">
        <img (click)="Doctor_schedule_add()" src="../../../assets/ui_icons/buttons/add_button.svg" 
        class="saveimgbtn_inpatinfo" style="float: right;"/>
      </div>
      <div class="col-12" *ngIf="doc_sche_list.length != 0">
        <div class="dig_table_overflow" *ngIf="doc_sche_list.length != 0" style="height: 200px;">
          <table id="card_tbl" class="table table-hover table-dynamic">
              <thead>
                  <tr>
                      <th>Reason</th>
                      <th class="edit">Date </th>
                      <th class="edit">Time</th>
                      <th class="edit">Action</th>
                  </tr>
              </thead>
              <tbody>
                <tr *ngFor="let person of doc_sche_list" class="tdvalues">
                  <td style="text-align: left;">{{person.reason}}</td>
                  <td >{{person.from_date_d}} to {{person.to_date_d}}</td>
                  <td>{{person.from_time_data}} </td>
                 <td > <img class="schedule_img" (click)="Doctor_schedule_delete(person.reason,person.from_date,person.to_date)"
                  src="../../../assets/ui_icons/buttons/trash_icon.svg"  class="editDelicon" /></td>
                  </tr>
              </tbody>
          </table>
      </div>
      </div>
    </div>
  </div>
</div>

<div mat-dialog-actions align="end" style="width: auto;right: 19px;">
  <button (click)="Schedule_save()" id="add_btn" mat-button>
      <img src="../../../assets/ui_icons/buttons/save_button.svg" class="addimgbtn_icd" />
  </button>
</div>