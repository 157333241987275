import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Helper_Class } from '../../helper_class';
import * as $ from 'jquery/dist/jquery.min.js';
import { ToastrService } from 'ngx-toastr';

import { Session_Return, seesion_nameid, day_nameid, Day_Return, Time_Formate, ConvertTimeformat, Nurse_Day_Return } from '../../../assets/js/common';
import { Message_data } from '../../../assets/js/Message_data';
import { session_based_time_fun } from '../../../assets/js/session_based_time';
import { CommonDataService } from '../../providers/common-data.service';
import { DropdownModelpageComponent } from '../dropdown-modelpage/dropdown-modelpage.component';
import { MatDialog } from '@angular/material/dialog';
import { ipaddress } from '../../ipaddress' ;import { Master_Helper } from '../Master_Helper';
import { MasterHelperService } from '../MasterHelper.service';

@Component({
  selector: 'app-doctor-profile-update',
  templateUrl: './doctor-profile-update.component.html',
  styleUrls: ['./doctor-profile-update.component.css']
})
export class DoctorProfileUpdateComponent implements OnInit {
  //update func
  public profile_tab: any;
  public profile_work_tab: any;
  public profile_work_add: any;
  public profile_qual: any;
  public send_url = "usercontroller/updprov/";
  public mobile_num = null;
  public email_id = null;
  public home_care = null;
  public homecare_val: boolean;

  //personal tab
  public treat_flag: boolean = true;
  public spl_flag: boolean = true;
  public sub_spl_flag: boolean = true;

  public footheader: boolean;

  public header_footer_flag: boolean;

  // variable declaration
  public img_str: string;
  public treatement_array = [];
  public treatement_array1 = [];
  public specilization_array = [];
  public spl_array = [];
  public subspl_array = [];

  public subspecilities_array = [];
  public starts_from_array = [];
  public ends_at_array = [];
  public homecare_consult_array = [];

  public Homecare_show;
  tab2Params: any;
  public togg_home: boolean;
  public homecare_session: string;
  public start_time1: string;
  public start_time2: string;
  public start_time3: string;
  public end_time1: string;
  public end_time2: string;
  public end_time3: string;
  public homecare_fee: string;
  public first_name: string;
  public middle_name: string;
  public profile_img: string;
  public last_name: string;
  public dob: string;
  public mobile: string;
  public email: string;
  public gender: string;
  public registeration_code: string;
  public registeration_year: string;
  public registeration_state: string;
  public client_call: boolean;
  public second_opinion: boolean;
  public pay_cash: boolean;
  public pay_online: boolean;
  public pay_ins: boolean;
  public medicare_name: string;
  public pres_address1: string;
  public pres_address2: string;
  public pres_location_txt: string;
  public pres_city: string;
  public pres_state: string;
  public pres_country: string;
  public pres_zipcode: string;
  public pres_telephone: string;
  public perm_address1: string;
  public perm_address2: string;
  public perm_location_txt: string;
  public perm_city;
  public perm_state: string;
  public perm_country: string;
  public perm_zipcode: string;
  public perm_telephone: string;
  public pres_city_list_data;
  public pres_state_list_data;
  public pres_country_list_data;
  public city_url;
  public state_url;
  public country_url;
  public pres_location_filterlist = [];
  public send_spl_array = [];
  public send_treat_array = [];
  public send_subspl_array = [];
  public pres_loc_txt = [];
  public pres_location_filter_List = [];
  public Get_present_city_arry = [];
  public filt_city_desc;
  public pres_loc_id: string;
  public perm_loc_id: string;

  public mobile_str: string;
  public email_str: string;

  //perm address
  public perm_loc_txt = [];
  public perm_location_filterlist = [];
  public prem_location_txt: string;
  public perm_location_filter_List = [];
  public perm_state_list_data;
  public perm_country_list_data;
  public perm_city_list_data;
  public filt_state_desc;

  public country_id: string;

  public hosp_name_txt: string;
  public hosp_loc_txt: string;
  public hosp_id;
  public hosp_address1: string;
  public hosp_address2: string;
  public hop_website: string;
  public hosp_contact_num: string;
  public pres_city_select;

  public spl_col;
  public subspl_col;
  public perm_div: boolean;
  public sameasabove_check: boolean;
  public PreClient_address = [];
  public pro_img;

  lastImage: string = null;
  base64Image: any;
  public loader: boolean;
  public treat_type;
  public spl_type;
  public sub_spl_type;
  public grit_package_id: string;

  public userinfo;
  public user_id;

  isReadonly() { return true; }

  public presentActionSheet() {
   
  }

  public clientCall: boolean = false;
  public secopinion: boolean = false;
  public homecare: boolean = false;
  public secop;
  public homecare_lab;
  public clientCall_lab;
  //work_location
  public work_arry = [];
  public hosp_dist;
  public hosp_state;
  public hosp_country;
  public hosp_zip: string;
  public hosp_fee: string;
  public hosp_days: string;
  public hosp_session: string;
  public start_from1: string;
  public start_from2: string;
  public start_from3: string;
  public end_at1: string;
  public end_at2: string;
  public end_at3: string;
  public hops_consult: boolean;
  public app_token: boolean;
  public app_time: boolean;
  public count_txt: string;
  public hospital_txt = [];
  public location_txt = []
  public loc_id: string;
  public country_desc: string;

  public location_filterlist = [];
  public filteredlocList = [];
  public filteredhospList = [];
  public hospital_filterlist = [];
  public location_list_data = [];

  public locationList;
  public location_search_items;
  public city_list_data;
  public state_list_data;
  public country_list_data;
  public clnt_location;

  public Location_array = [];
  public Search_City: string
  public Get_city_arry = [];
  public app_type_lbl: string;
  public cancelwork: any;
  public homecare_morningtool: boolean;
  public homecare_afternoontool: boolean;
  public homecare_eveningtool: boolean;

  //quailification
  public qualification_array = [];
  public qualification_degree: string;
  public qualification_clg: string;
  public qualification_uni: string;
  public qualification_year: string;

  constructor(public dialog: MatDialog, public gservice: CommonDataService, public http: Http, public routes: ActivatedRoute, public router: Router,
    public toastr: ToastrService, public masterData: MasterHelperService) {
    this.img_str = null;
    this.homecare_session = "";
    this.homecare_fee = "";
    this.togg_home = false;

    this.spl_col = true;
    this.subspl_col = true;
    this.loader = false;
    this.sameasabove_check = false;
    this.perm_div = false;
    //default value

    this.start_time1 = "06";
    this.start_time2 = "00";
    this.start_time3 = "AM";
    this.end_time1 = "08";
    this.end_time2 = "00";
    this.end_time3 = "AM";

    this.city_url = ipaddress.getIp + "gen/city";
    this.state_url = ipaddress.getIp + "gen/state";
    this.country_url = ipaddress.getIp + "gen/count";

    this.Homecare_show = true;
    for (var i = 1; i <= 12; i++) {
      if (i < 10) {
        this.starts_from_array.push("0" + i);
      } else {
        this.starts_from_array.push(i);
      }
    }

    for (var j = 0; j <= 55; j += 5) {
      if (j < 10) {
        this.ends_at_array.push("0" + j);
      } else {
        this.ends_at_array.push(j);
      }
    }

    //work_location
    this.hosp_name_txt = "";
    this.hosp_loc_txt = "";
    this.hosp_dist = "";
    this.hosp_state = "";
    this.hosp_country = "";
    this.hosp_zip = "";
    this.hosp_address1 = "",
      this.hosp_address2 = "";
    this.hosp_contact_num = "";
    this.hop_website = '';
    this.hosp_fee = "";
    this.hosp_days = "";
    this.hosp_session = "";
    this.hops_consult = false;
    this.count_txt = "";

    this.app_type_lbl = "Count";
    this.app_token = true;
    this.start_from1 = "06";
    this.start_from2 = "00";
    this.start_from3 = "AM";
    this.end_at1 = "08";
    this.end_at2 = "00";
    this.end_at3 = "AM";
    this.hosp_id = "0";

    this.city_url = ipaddress.getIp + "gen/city";
    this.state_url = ipaddress.getIp + "gen/state";
    this.country_url = ipaddress.getIp + "gen/count";
    this.cancelwork = ipaddress.getIp + "usercontroller/cancelwork";

    //qualification
    this.qualification_degree = "";
    this.qualification_uni = "";
    this.qualification_year = "";
  }

  ngOnInit() {
    this.userinfo = Helper_Class.getInfo();
    this.user_id = this.userinfo.user_id;
    this.Get_treatement();

    //work_location
    this.homecare_afternoontool = false;
    this.homecare_morningtool = true;
    this.homecare_eveningtool = true;
  }

  //personal tab
  checkemail() {
    if (this.email != undefined) {
      this.email = this.email.trim()
      var x = this.email;
      var re = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/igm;

      if (!re.test(x)) {
        this.toastr.error(Message_data.validEmail);

      } else {
        this.email = this.email.trim();
      }
    }
  }

  public pathForImage(img) {
    if (img === null) {
      return '';
    } else {
      // return cordova.file.dataDirectory + img;
    }
  }

  send_Personaldata() {
    var cli_call = null;
    if (this.client_call == true) {
      cli_call = "1";

    } else {
      cli_call = "0";
    }

    var sec_opn = null;
    if (this.second_opinion == true) {
      sec_opn = "1"

    } else {
      sec_opn = "0"
    }

    var home_value = null;

    if (this.togg_home == true) {
      home_value = "1"

    } else {
      home_value = "0"
    }

    var pcash;
    if (this.pay_cash == true) {
      pcash = "1";

    } else {
      pcash = "0";
    }

    var ponline;
    if (this.pay_online == true) {
      ponline = "1";

    } else {
      ponline = "0";
    }

    var pins;
    if (this.pay_ins == true) {
      pins = "1";

    } else {
      pins = "0";
    }

    var present_address2 = "", homecare_val = true;
    if (this.pres_address2 != undefined) {
      present_address2 = this.pres_address2;
    }

    var permanent_address2 = "";
    if (this.perm_address2 != undefined) {
      permanent_address2 = this.perm_address2;
    }

    this.PreClient_address = [];

    if (this.sameasabove_check == true) {
      if (this.pres_address1 == undefined || this.pres_address1 == "") {
        homecare_val = false;
      }

      if (this.pres_location_txt == undefined || this.pres_location_txt == "") {
        homecare_val = false;
      }

      if (this.pres_city_select == undefined || this.pres_city_select == "") {
        homecare_val = false;
      }

      if (this.pres_state == undefined || this.pres_state == "") {
        homecare_val = false;
      }

      if (this.pres_country == undefined || this.pres_country == "") {
        homecare_val = false;
      }

      if (this.pres_telephone == undefined || this.pres_telephone == "") {
        homecare_val = false;
      }

      this.PreClient_address.push({
        address1: this.pres_address1,
        address2: present_address2,
        location: this.pres_loc_id,
        city: this.pres_city_select,
        state: this.pres_state,
        country: this.pres_country,
        zipcode: this.pres_zipcode,
        telephone: this.pres_telephone,
        sameascurrent: "1",
        type: "pres",
      });

    } else {
      // present addd validation
      if (this.pres_address1 == undefined || this.pres_address1 == "") {
        homecare_val = false;
      }

      if (this.pres_location_txt == undefined || this.pres_location_txt == "") {
        homecare_val = false;
      }

      if (this.pres_city_select == undefined || this.pres_city_select == "") {
        homecare_val = false;
      }

      if (this.pres_state == undefined || this.pres_state == "") {
        homecare_val = false;
      }

      if (this.pres_country == undefined || this.pres_country == "") {
        homecare_val = false;
      }

      if (this.pres_telephone == undefined || this.pres_telephone == "") {
        homecare_val = false;
      }

      //perment add valdi
      if (this.perm_address1 == undefined || this.perm_address1 == "") {
        homecare_val = false;
      }

      if (this.perm_loc_id == undefined || this.perm_loc_id == "") {
        homecare_val = false;
      }

      if (this.perm_city == undefined || this.perm_city == "") {
        homecare_val = false;
      }

      if (this.perm_state == undefined || this.perm_state == "") {
        homecare_val = false;
      }

      if (this.perm_country == undefined || this.perm_country == "") {
        homecare_val = false;
      }

      if (this.perm_telephone == undefined || this.perm_telephone == "") {
        homecare_val = false;
      }

      this.PreClient_address.push({
        address1: this.pres_address1,
        address2: present_address2,
        location: this.pres_loc_id,
        city: this.pres_city_select,
        state: this.pres_state,
        country: this.pres_country,
        zipcode: this.pres_zipcode,
        telephone: this.pres_telephone,
        sameascurrent: "0",
        type: "pres",
      },
        {
          address1: this.perm_address1,
          address2: permanent_address2,
          location: this.perm_loc_id,
          city: this.perm_city,
          state: this.perm_state,
          country: this.perm_country,
          zipcode: this.perm_zipcode,
          telephone: this.perm_telephone,
          sameascurrent: "0",
          type: "perm",
        });
    }

    this.mobile_str = encrypt_decript.Encript(this.mobile).toString();
    if (this.email.trim() != undefined) {
      this.email_str = encrypt_decript.Encript(this.email).toString();
    }

    var sub_spl = undefined;
    if (this.send_subspl_array.length != 0) {
      sub_spl = this.send_subspl_array;
    }

    var img_path = this.pro_img, upd_url = "0";
    if (this.img_str != null) {
      img_path = this.img_str.split(',')[1];
      upd_url = "1";
    }
    else {
      upd_url = undefined;
    }

    var send_array = null;
    if (home_value == "1") {
      send_array = {
        home_care_val: homecare_val,
        upd_url: upd_url,
        profile_image: img_path,
        serv_provider_reg_id: this.user_id,
        mobile: this.mobile_str,
        language: "",
        provider: "doctor",
        client_call: cli_call,
        second_opinion: sec_opn,
        medicare_name: this.send_treat_array,
        specialization_id: this.send_spl_array,
        den_spl_id: sub_spl,
        home_address: this.PreClient_address,
        homecare_consult: this.homecare_consult_array,
        home_care: home_value,
        email: this.email_str,
        pay_cash: pcash,
        pay_online: ponline,
        pay_ins: pins,
        grit_package_subscribe_id: this.grit_package_id,
      }
    } else {
      send_array = {
        home_care_val: homecare_val,
        upd_url: upd_url,
        profile_image: img_path,
        serv_provider_reg_id: this.user_id,
        mobile: this.mobile_str,
        language: "",
        provider: "doctor",
        client_call: cli_call,
        second_opinion: sec_opn,
        medicare_name: this.send_treat_array,
        specialization_id: this.send_spl_array,
        den_spl_id: sub_spl,
        home_care: home_value,
        email: this.email_str,
        pay_cash: pcash,
        pay_online: ponline,
        pay_ins: pins,
        grit_package_subscribe_id: this.grit_package_id,
      }
    }
    Helper_Class.setprofile_array(send_array);
  }

  fileChange($event): void {
    this.readThis($event.target);
  }

  readThis(inputValue: any): void {
    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      var fileSize = Math.round(inputValue.files[0].size / 1024);
      if (fileSize >= 50) {
        this.toastr.error(Message_data.maxImgSize);
        $("#file").val('');
        this.img_str = null;

      } else {
        this.img_str = myReader.result.toString();
        $('#profile_img1').attr('src', myReader.result);
      }
    }
    myReader.readAsDataURL(inputValue.files[0]);
  }

  // present address - location
  pres_location_fun(e) {
    this.pres_country_list_data = [];
    this.pres_state_list_data = [];
    this.pres_city_list_data = [];
    this.pres_location_filter_List = [];

    this.pres_city_select = '';
    this.pres_state = '';
    this.pres_country = '';
    this.pres_zipcode = '';

    this.pres_loc_txt = [];
    this.pres_location_filterlist = [];
    var tmp_arr = [];
    if (this.pres_location_txt !== "" && (this.pres_location_txt != undefined && this.pres_location_txt.length > 2)) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'gen/loc',
        JSON.stringify({
          loc_name: this.pres_location_txt
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);

            if (obj.locations != null) {
              this.pres_location_filterlist = obj.locations;
              this.pres_location_filter_List = this.pres_location_filterlist.filter(function (this: any, el) {
                return el.toLowerCase().indexOf(this.pres_location_txt.toLowerCase()) > -1;
              }.bind(this));
            }
          },
          error => {
            this.toastr.error(Message_data.getNetworkMessage());
          }
        )

    } else {
      this.pres_location_filter_List = [];
    }
  }

  select_pres_location(data) {
    this.pres_location_txt = data;
    this.pres_location_filter_List = [];

    this.Get_Present_City(data);
    this.pres_state_list_data = [];
    this.pres_city_list_data = [];
    this.pres_country_list_data = [];
  }

  // present address - city
  Get_Present_City(data) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');

    this.http.post(this.city_url, JSON.stringify({ loc_name: data }), { headers: headers }).subscribe(
      data => {

        if (data.json().cities != null && data.json().cities.length != 0) {
          this.pres_city_list_data = data.json().cities;
          this.pres_city_select = this.pres_city_list_data[0].city_id;
          this.filt_city_desc = this.pres_city_list_data[0].city_desc;
          this.pres_loc_id = this.pres_city_list_data[0].location_id;
          this.pres_zipcode = this.pres_city_list_data[0].zipcode;
          this.Pres_City_change(this.pres_city_list_data[0].city_id);
        }
      },
      error => {
        this.toastr.error(Message_data.getNetworkMessage());
      })
  }

  Pres_City_change(data) {
    if (data != null) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');

      this.http.post(this.state_url, JSON.stringify({ city_id: data }), { headers: headers }).subscribe(
        data => {

          if (data.json().states != null && data.json().states.length != 0) {
            this.pres_state_list_data = data.json().states;
            this.pres_state = this.pres_state_list_data[0].state_id;
            this.filt_state_desc = this.pres_state_list_data[0].state_desc;
            this.Present_state_change(this.pres_state_list_data[0].state_id);
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        })

    } else {
      this.pres_state_list_data = [];
      this.pres_city_list_data = [];
      this.pres_country_list_data = [];
    }
  }

  // present address - state
  Present_state_change(data) {
    if (data != null) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');

      this.http.post(this.country_url, JSON.stringify({ state_id: data }), { headers: headers }).subscribe(
        data => {

          if (data.json().countries != null && data.json().countries.length != 0) {
            this.pres_country_list_data = data.json().countries;
            this.country_id = this.pres_country_list_data[0].country_id;
            this.pres_country = this.pres_country_list_data[0].country_id;
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        })

    } else {
      this.pres_state_list_data = [];
      this.pres_country_list_data = [];
    }
  }

  //permanent address- location
  perm_location_fun(e) {
    this.perm_country_list_data = [];
    this.perm_state_list_data = [];
    this.perm_city_list_data = [];
    this.perm_location_filter_List = [];

    this.perm_city = '';
    this.perm_country = '';
    this.perm_state = '';
    this.perm_zipcode = '';

    this.perm_loc_txt = [];
    this.perm_location_filterlist = [];
    var tmp_arr = [];
    if (this.perm_location_txt !== "" && (this.perm_location_txt != undefined && this.perm_location_txt.length > 2)) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'gen/loc',
        JSON.stringify({
          loc_name: this.perm_location_txt
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);

            if (obj.locations != null) {
              this.perm_location_filterlist = obj.locations;
              this.perm_location_filter_List = this.perm_location_filterlist.filter(function (this: any, el) {
                return el.toLowerCase().indexOf(this.perm_location_txt.toLowerCase()) > -1;
              }.bind(this));
            }
          },
          error => {
            this.toastr.error(Message_data.getNetworkMessage());
          }
        )

    } else {
      this.perm_location_filter_List = [];
    }
  }

  select_perm_location(data) {
    this.perm_location_txt = data;
    this.perm_location_filter_List = [];

    this.Get_Permanent_City(data);
    this.perm_state_list_data = [];
    this.perm_city_list_data = [];
    this.perm_country_list_data = [];
  }

  //permanent address- city
  Get_Permanent_City(data) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');

    this.http.post(this.city_url, JSON.stringify({ loc_name: data }), { headers: headers }).subscribe(
      data => {

        if (data.json().cities != null && data.json().cities.length != 0) {
          this.perm_city_list_data = data.json().cities;
          this.perm_city = this.perm_city_list_data[0].city_id;
          this.filt_city_desc = this.perm_city_list_data[0].city_desc;
          this.perm_loc_id = this.perm_city_list_data[0].location_id;
          this.perm_zipcode = this.perm_city_list_data[0].zipcode;
          this.Perm_City_change(this.perm_city_list_data[0].city_id);
        }
      },
      error => {
        this.toastr.error(Message_data.getNetworkMessage());
      })
  }

  Perm_City_change(data) {
    if (data != null) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');

      this.http.post(this.state_url, JSON.stringify({ city_id: data }), { headers: headers }).subscribe(
        data => {
          if (data.json().states != null && data.json().states.length != 0) {
            this.perm_state_list_data = data.json().states;
            this.perm_state = this.perm_state_list_data[0].state_id;
            this.filt_state_desc = this.perm_state_list_data[0].state_desc;
            this.Permanent_state_change(this.perm_state_list_data[0].state_id);
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        })

    } else {
      this.perm_state_list_data = [];
      this.perm_city_list_data = [];
      this.perm_country_list_data = [];
    }
  }

  //permanent address- state
  Permanent_state_change(data) {
    if (data != null) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');

      this.http.post(this.country_url, JSON.stringify({ state_id: data }), { headers: headers }).subscribe(
        data => {
          if (data.json().countries != null && data.json().countries.length != 0) {
            this.perm_country_list_data = data.json().countries;
            this.country_id = this.perm_country_list_data[0].country_id;
            this.perm_country = this.perm_country_list_data[0].country_id;
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        })

    } else {
      this.perm_state_list_data = [];
      this.perm_country_list_data = [];
    }
  }

  // profile retrivel data
  profile_info() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'usercontroller/providerdetails',
      JSON.stringify({
        doctor: this.user_id,
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);


          Helper_Class.setProfile_info(obj);
          if (obj.profile_image != null) {
            this.loader = true;
            this.pro_img = obj.profile_image;
            var img1 = document.getElementById("profile_img1") as HTMLImageElement;
            img1.src = ipaddress.Ip_with_img_address + obj.profile_image;
          } else {
            this.loader = true;
            this.toastr.error(Message_data.getNetworkMessage());
          }

          if (obj.first_name != null) {
            this.first_name = obj.first_name;
          }

          if (obj.middle_name != null) {
            this.middle_name = obj.middle_name;
          }

          if (obj.last_name != null) {
            this.last_name = obj.last_name;
          }

          if (obj.dob != null) {
            var dobval = obj.dob.split('-');
            this.dob = dobval[2] + "-" + dobval[1] + "-" + dobval[0];

          } else {
            this.dob = "";
          }

          if (obj.mobile != null) {
            this.mobile = encrypt_decript.Decript(obj.mobile);
          }

          if (obj.email != null) {
            this.email = encrypt_decript.Decript(obj.email);
          }

          if (obj.gender != null) {
            this.gender = obj.gender;
          }

          if (obj.registeration_code != null) {
            this.registeration_code = obj.registeration_code;
          }

          if (obj.registeration_year != null) {
            this.registeration_year = obj.registeration_year;
          }

          if (obj.client_call != null) {
            if (obj.client_call == "1") {
              this.client_call = true;
              this.change_clientcall(this.client_call);
            }
            else {
              this.client_call = false;
              this.change_clientcall(this.client_call);
            }

          }

          if (obj.second_opinion != null) {

            if (obj.second_opinion == "1") {
              this.second_opinion = true;
              this.change_secopinion(this.second_opinion);
            }
            else {
              this.second_opinion = false;
              this.change_secopinion(this.second_opinion);
            }
          }

          if (obj.pay_cash != null) {
            this.pay_cash = obj.pay_cash;
          }

          if (obj.pay_online != null) {
            this.pay_online = obj.pay_online;
          }

          if (obj.pay_ins != null) {
            this.pay_ins = obj.pay_ins;
          }
          if (obj.home_care != null) {
            if (obj.home_care == "1") {
              this.togg_home = true;
              this.Homecare_show = false;
              this.change_homecare(this.togg_home) //toggle

            } else {
              this.togg_home = false;
              this.Homecare_show = true;
              this.change_homecare(this.togg_home) //toggle
            }
          }

          if (obj.registeration_state != null) {
            this.registeration_state = obj.registeration_state;
          }

          if (obj.den_spl_ids != null && obj.den_spl_ids.length != 0) {
            this.send_subspl_array = [];
            for (var k = 0; k < obj.den_spl_ids.length; k++) {
              if (!this.send_subspl_array.some(data1 => data1 === obj.den_spl_ids[k])) {
                this.send_subspl_array.push(obj.den_spl_ids[k]);
              }
            }
            this.subspl_array = this.send_subspl_array;
            this.sub_spl_change(this.send_subspl_array)
          }

          if (obj.specialization_id != null && obj.specialization_id.length != 0) {
            this.spl_col = false;
            this.send_spl_array = [];
            for (var i = 0; i < obj.specialization_id.length; i++) {
              if (!this.send_spl_array.some(x => x === obj.specialization_id[i])) {
                this.send_spl_array.push(obj.specialization_id[i]);

                if (obj.specialization_id[i] == "2") {
                  this.subspl_col = false;
                }
              }
            }
            this.spl_array = this.send_spl_array;
            this.spl_change(this.send_spl_array)
          }

          if (obj.medicare_name != null) {
            this.send_treat_array = [];
            for (var j = 0; j < obj.medicare_name.length; j++) {
              // this.inMedArray(obj.medicare_name[j], this.send_treat_array);
              this.send_treat_array.push(obj.medicare_name[j]);
            }
            this.treatement_array1 = this.send_treat_array;
            this.medicare_change(this.send_treat_array);
          }

          if (obj.homecare_consult != null && obj.homecare_consult.length != 0) {
            for (var k = 0; k < obj.homecare_consult.length; k++) {
              this.homecare_consult_array.push({
                day_session: obj.homecare_consult[k].day_session,
                session: Session_Return(obj.homecare_consult[k].day_session),
                available_from1: Time_Formate(Helper_Class.getProfile_info().homecare_consult[k].available_from),
                available_to1: Time_Formate(Helper_Class.getProfile_info().homecare_consult[k].available_to),
                available_from: Helper_Class.getProfile_info().homecare_consult[k].available_from,
                available_to: Helper_Class.getProfile_info().homecare_consult[k].available_to,
                home_care_fee: obj.homecare_consult[k].home_care_fee,
                homecare_consult_id: obj.homecare_consult[k].homecare_consult_id,
              })
            }
          }

          if (obj.home_address != null) {
            if (obj.home_address.length != 0) {
              for (var l = 0; l < obj.home_address.length; l++) {
                if (obj.home_address[l].type == "pres") {
                  this.pres_address1 = obj.home_address[l].address1;
                  this.pres_address2 = obj.home_address[l].address2;
                  this.pres_city = obj.home_address[l].city_desc;
                  this.pres_loc_id = obj.home_address[l].location;
                  this.pres_country = obj.home_address[l].country_desc;
                  this.pres_location_txt = obj.home_address[l].location_desc;
                  this.pres_state = obj.home_address[l].state_desc;
                  this.pres_telephone = obj.home_address[l].telephone;
                  this.pres_zipcode = obj.home_address[l].zipcode;
                  this.Get_Present_City(obj.home_address[l].location_desc);

                } else {
                  this.perm_address1 = obj.home_address[l].address1;
                  this.perm_address2 = obj.home_address[l].address2;
                  this.perm_city = obj.home_address[l].city_desc;
                  this.perm_loc_id = obj.home_address[l].location;
                  this.perm_country = obj.home_address[l].country_desc;
                  this.perm_location_txt = obj.home_address[l].location_desc;
                  this.perm_state = obj.home_address[l].state_desc;
                  this.perm_telephone = obj.home_address[l].telephone;
                  this.perm_zipcode = obj.home_address[l].zipcode;
                  this.Get_Permanent_City(obj.home_address[l].location_desc);
                }
              }

              if (obj.home_address.length == 1) {
                this.sameasabove_check = true;
                this.perm_div = true;
              }
            }
          }

          // set work location array
          if (obj.consultation != null) {
            for (var i = 0; i < obj.consultation.length; i++) {
              var token_name = undefined, time_dure = undefined;
              if (obj.consultation[i].token != "0") {
                token_name = obj.consultation[i].token;
                time_dure = "0";

              } else {
                token_name = "0";
                time_dure = obj.consultation[i].app_duration;
              }

              this.work_arry.push({
                hospital_id: obj.consultation[i].hospital_clinic_id,
                name: obj.consultation[i].name,

                location: obj.consultation[i].location,
                city: obj.consultation[i].city,
                state: obj.consultation[i].state,
                country: obj.consultation[i].country,
                zipcode: obj.consultation[i].zipcode,
                address1: obj.consultation[i].address1,
                address2: obj.consultation[i].address2,
                mobilenum: obj.consultation[i].telephone,
                website: obj.consultation[i].hospital_website,
                fee: obj.consultation[i].fee,
                day_desc: Day_Return(obj.consultation[i].day),
                day: obj.consultation[i].day,
                session_desc: Session_Return(obj.consultation[i].day_session),
                day_session: obj.consultation[i].day_session,
                available_from: Time_Formate(obj.consultation[i].available_from),
                available_to: Time_Formate(obj.consultation[i].available_to),
                consultant: obj.consultation[i].consultant,
                token: token_name,
                app_duration: time_dure,
                consult_time_id: obj.consultation[i].doc_cons_time_id,
              })
            }

            // set work location
            if (this.work_arry.length != 0) {
              var work_arry1 = [];
              for (var i = 0; i < this.work_arry.length; i++) {
                if (this.work_arry[i].consult_time_id != "0") {
                  var consult_time_id = this.work_arry[i].consult_time_id;

                } else {
                  consult_time_id = "0";
                }

                work_arry1.push({
                  hospital_id: this.work_arry[i].hospital_id,
                  available_from: ConvertTimeformat("", this.work_arry[i].available_from),
                  available_to: ConvertTimeformat("", this.work_arry[i].available_to),
                  fee: this.work_arry[i].fee,
                  day: this.work_arry[i].day,
                  day_session: this.work_arry[i].day_session,
                  token: this.work_arry[i].token,
                  app_duration: this.work_arry[i].app_duration,
                  consultant: this.work_arry[i].consultant,
                  consultation_time_id: consult_time_id,
                });
              }
              Helper_Class.set_work_array(work_arry1);

            }
          }

          if (obj.qualification != null) {
            this.qualification_array = [];
            // set default qualfication send array
            for (var i = 0; i < obj.qualification.length; i++) {
              var clg_name = null;
              if (obj.qualification[i].institute != null) {
                clg_name = obj.qualification[i].institute;
              }
              else {
                clg_name = "-";
              }
              this.qualification_array.push({
                degree: obj.qualification[i].degree_name,
                institute: clg_name,
                university: obj.qualification[i].university,
                year: obj.qualification[i].graduation_year,
              })
            }
            var qual_arr = null;
            qual_arr = {
              qualification: this.qualification_array
            }
            Helper_Class.setprofile_qual_array(qual_arr);
            this.grit_package_id = obj.package_id;

          }
          //this.send_Personaldata();
        },
        error => {
          this.loader = true;
          this.toastr.error(Message_data.getNetworkMessage());
        }
      )
  }

  inSPlArray(elem, array) {
    var len = array.length;
    if (array.length != 0) {
      for (var i = 0; i < len; i++) {
        if (array[i] != elem) {
          array.push(elem);
        }
      }

    } else {
      array.push(elem);
    }
  }

  inMedArray(elem, array) {
    var len = array.length;
    if (array.length != 0) {
      for (var i = 0; i < len; i++) {
        if (array[i] != elem) {
          array.push(elem);
        }
      }

    } else {
      array.push(elem);
    }
  }

  //specialization
  spl_change(selectedValue) {
    this.spl_type = "";
    function StringBuilder(this: any, value) {
      this.strings = new Array("");
      this.append(value);
    }

    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }

    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }

    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }

    var selected_data = new StringBuilder("");
    this.send_spl_array = selectedValue;

    if (this.send_spl_array.length != 0) {
      var n = 0;

      for (var i = 0; i < this.send_spl_array.length; i++) {

        if (this.send_spl_array[i] == "2") {
          this.subspl_col = false;

        } else {
          this.subspl_col = true;
        }

        for (var j = 0; j < this.specilization_array.length; j++) {
          if (this.send_spl_array[i] == this.specilization_array[j].value) {
            n = n + 1;
            if (n > 1) {
              selected_data.append(",");
              selected_data.append("\n");
            }

            selected_data.append(this.specilization_array[j].label);
          }
        }
      }
      this.spl_type = selected_data.toString();
    }
  }

  //subspl
  sub_spl_change(selectedValue) {
    this.sub_spl_type = "";
    function StringBuilder(this: any, value) {
      this.strings = new Array("");
      this.append(value);
    }

    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }

    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }

    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }

    var selected_data = new StringBuilder("");

    this.send_subspl_array = selectedValue;

    if (this.send_subspl_array.length != 0) {
      var n = 0;

      for (var j = 0; j < this.send_subspl_array.length; j++) {
        for (var i = 0; i < this.subspecilities_array.length; i++) {
          if (this.send_subspl_array[j] == this.subspecilities_array[i].value) {
            n = n + 1;
            if (n > 1) {
              selected_data.append(",");
              selected_data.append("\n");
            }

            selected_data.append(this.subspecilities_array[i].label);
          }
        }
      }
      this.sub_spl_type = selected_data.toString();
    }
  }

  // data added in table
  homecare_btn_click() {
    if (this.homecare_session == "" || this.homecare_fee == "") {
      this.toastr.error(Message_data.mandatory);

    } else {
      var f_time = ConvertTimeformat("", this.start_time1 + ":" + this.start_time2 + " " + this.start_time3);
      var t_time = ConvertTimeformat("", this.end_time1 + ":" + this.end_time2 + " " + this.end_time3);
      var ret_value = session_based_time_fun(f_time, t_time, this.homecare_session);

      if (ret_value == true) {
        this.homecare_consult_array.push({
          day_session: seesion_nameid(this.homecare_session),
          session: this.homecare_session,
          available_from: ConvertTimeformat("", this.start_time1 + ":" + this.start_time2 + " " + this.start_time3),
          available_to: ConvertTimeformat("", this.end_time1 + ":" + this.end_time2 + " " + this.end_time3),
          available_from1: this.start_time1 + ":" + this.start_time2 + " " + this.start_time3,
          available_to1: this.end_time1 + ":" + this.end_time2 + " " + this.end_time3,
          home_care_fee: this.homecare_fee,
          homecare_consult_id: "0"
        });

        this.homecare_session = "";
        this.start_time1 = "06";
        this.start_time2 = "00";
        this.start_time3 = "AM";
        this.end_time1 = "08";
        this.end_time2 = "00";
        this.end_time3 = "AM";
        this.homecare_fee = "";
      }
      else {
        this.toastr.error(Message_data.invalidTime);
      }
    }
  }

  //table delete action
  home_consult_delete(session_data, avail_from, avail_to) {
    for (var i = 0; i < this.homecare_consult_array.length; i++)
      if (session_data == this.homecare_consult_array[i].session && avail_from == this.homecare_consult_array[i].available_from1 && avail_to == this.homecare_consult_array[i].available_to1) {
        this.homecare_consult_array.splice(i, 1);
      }
  }

  //Get treatmentplan data
  async Get_treatement() {
    this.treatement_array = [];
    if (Master_Helper.getMasterTreatement() == undefined) {
      await this.masterData.getTreatement()
    }
    var obj = Master_Helper.getMasterTreatement();
    if (obj.medicare != undefined) {
      for (var i = 0; i < obj.medicare.length; i++) {
        this.treatement_array.push({
          type: 'checkbox',
          value: obj.medicare[i].medicare_id,
          label: obj.medicare[i].medicare_name,
          checked: false
        });
      }
      this.treat_type = obj.medicare[0].medicare_id;
      this.spl_col = false;
    }
    this.Get_specialization();
  }

  medicare_popup() {
    this.treat_flag = !this.treat_flag;
    for (var j = 0; j < this.treatement_array.length; j++) {
      this.treatement_array[j].checked = false;
    }

    if (this.send_treat_array != undefined && this.send_treat_array.length != 0) {
      for (var i = 0; i < this.send_treat_array.length; i++) {
        for (var j = 0; j < this.treatement_array.length; j++) {
          if (this.send_treat_array[i] == this.treatement_array[j].value) {
            this.treatement_array[j].checked = true;
          }
        }
      }
    }
  }

  closetreatment() {
    this.treat_flag = !this.treat_flag;
  }

  medicare_click(data, id) {
    if (id == 1) {
      this.spl_col = false;
    }
    else {
      this.spl_col = true;

    }
  }

  medicare_change(selectedValue) {
    this.treat_type = "";
    function StringBuilder(this: any, value) {
      this.strings = new Array("");
      this.append(value);
    }

    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }

    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }

    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }

    var selected_data = new StringBuilder("");
    this.send_treat_array = selectedValue;
    if (this.send_treat_array.length != 0) {
      var n = 0;
      for (var i = 0; i < this.send_treat_array.length; i++) {

        if (this.send_treat_array[i] == "1") {
          this.spl_col = false;

        } else {
          this.spl_col = true;
        }

        for (var j = 0; j < this.treatement_array.length; j++) {
          if (this.treatement_array[j].value == this.send_treat_array[i]) {
            n = n + 1;
            if (n > 1) {
              selected_data.append(",");
              selected_data.append("\n");
            }
            this.treat_type = this.treatement_array[j].value
            selected_data.append(this.treatement_array[j].label);
          }
        }
      }
    }
  }

  //sameas Event
  Sameas_Change(e) {
    this.sameasabove_check = e;
    if (this.sameasabove_check == true) {
      this.perm_div = true;

    } else {
      this.perm_div = false;
    }
  }

  home_click() {
    if (this.Homecare_show == true) {
      this.Homecare_show = false; // view true

    } else {
      this.Homecare_show = true;
    }
  }

  spl_popup() {
    for (var j = 0; j < this.specilization_array.length; j++) {
      this.specilization_array[j].checked = false;
    }

    if (this.send_spl_array != undefined && this.send_spl_array.length != 0) {
      for (var i = 0; i < this.send_spl_array.length; i++) {
        for (var j = 0; j < this.specilization_array.length; j++) {
          if (this.send_spl_array[i] == this.specilization_array[j].value) {
            this.specilization_array[j].checked = true;
          }
        }
      }
    }

    const dialogRef = this.dialog.open(DropdownModelpageComponent, {
      width: '500px',
      height: '400px',
      data: {
        title: "Past Medical History",
        main_array: this.specilization_array,
        selected_list: this.send_spl_array,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.spl_change(result);
      }
    });
  }

  //Get specialization data
  async Get_specialization() {
    this.specilization_array = [];
    if (Master_Helper.getMasterSpecialization() == undefined) {
      await this.masterData.getSpeciality();
    }
    var obj = Master_Helper.getMasterSpecialization();
    if (obj != undefined) {
      for (var i = 0; i < obj.specialization.length; i++) {
        this.specilization_array.push({
          type: 'checkbox',
          value: obj.specialization[i].spl_id,
          label: obj.specialization[i].spl_name,
          checked: false
        });
      }
    }
    this.Get_subspecilities();
  }

  sub_spl_popup() {
    this.sub_spl_flag = !this.sub_spl_flag;
    for (var j = 0; j < this.subspecilities_array.length; j++) {
      this.subspecilities_array[j].checked = false;
    }

    if (this.send_subspl_array != undefined && this.send_subspl_array.length != 0) {
      for (var i = 0; i < this.send_subspl_array.length; i++) {
        for (var j = 0; j < this.subspecilities_array.length; j++) {
          if (this.send_subspl_array[i] == this.subspecilities_array[j].value) {
            this.subspecilities_array[j].checked = true;
          }
        }
      }
    }

    const dialogRef = this.dialog.open(DropdownModelpageComponent, {
      width: '500px',
      height: '400px',
      data: {
        title: "Past Medical History",
        main_array: this.subspecilities_array,
        selected_list: this.send_subspl_array,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.sub_spl_change(result);
      }
    });
  }

  //Get subspecilities data
  async Get_subspecilities() {
    this.subspecilities_array = [];
    if (Master_Helper.getMasterSubspecilities() == undefined) {
      await this.masterData.getSubspecilities();
    }
    var obj = Master_Helper.getMasterSubspecilities();
    if (obj != undefined) {
      for (var i = 0; i < obj.den_spl.length; i++) {
        this.subspecilities_array.push({
          type: 'checkbox',
          value: obj.den_spl[i].den_spl_id,
          label: obj.den_spl[i].description,
          checked: false
        });
      }
    }
    this.profile_info();
  }
  //hide and show

  //work_location_tab
  session_change() {
    if (this.hosp_session == "Morning") {
      this.homecare_morningtool = false;
      this.homecare_afternoontool = true;
      this.homecare_eveningtool = true;
      this.start_from3 = "AM";
      this.end_at3 = "AM";

    } else if (this.hosp_session == "Afternoon") {
      this.homecare_afternoontool = false;
      this.homecare_morningtool = true;
      this.homecare_eveningtool = true;
      this.start_from3 = "PM";
      this.end_at3 = "PM";

    } else if (this.hosp_session == "Evening") {
      this.homecare_eveningtool = false;
      this.homecare_afternoontool = true;
      this.homecare_morningtool = true;
      this.start_from3 = "PM";
      this.end_at3 = "PM";

    } else {
      this.homecare_morningtool = true;
      this.homecare_afternoontool = true;
      this.homecare_eveningtool = true;
    }
  }

  // add table
  work_btn_click() {
    var cosult_id = null;
    if (this.hops_consult == true) {
      cosult_id = 1;

    } else {
      cosult_id = 0;
    }

    var token_name = "0", time_dure = "0";
    if (this.app_token == true) {
      token_name = this.count_txt;
      time_dure = "0";

    } else {
      token_name = "0";
      time_dure = this.count_txt;
    }

    var fee = 0;

    if (this.hosp_fee != "") {
      fee = parseInt(this.hosp_fee);
    }

    if (this.hosp_name_txt == "" || this.hosp_loc_txt == "" ||
      this.hosp_dist == undefined || this.hosp_state == "" || this.hosp_country == "" ||
      this.hosp_zip == "" || this.hosp_address1 == "" || this.hosp_contact_num == "" ||
      this.hosp_days == "" || this.hosp_session == "") {
      this.toastr.error(Message_data.mandatory);

    } else {
      var f_time = ConvertTimeformat("", this.start_from1 + ":" + this.start_from2 + " " + this.start_from3);
      var t_time = ConvertTimeformat("", this.end_at1 + ":" + this.end_at2 + " " + this.end_at3);
      var ret_value = session_based_time_fun(f_time, t_time, this.hosp_session);
      if (ret_value == true) {

        this.work_arry.push({
          hospital_id: this.hosp_id,
          name: this.hosp_name_txt,
          location: this.hosp_loc_txt,
          city: this.hosp_dist,
          state: this.hosp_state,
          country: this.hosp_country,
          zipcode: this.hosp_zip,
          address1: this.hosp_address1,
          address2: this.hosp_address2,
          mobilenum: this.hosp_contact_num,
          website: this.hop_website,
          fee: fee,
          day_desc: this.hosp_days,
          day: day_nameid(this.hosp_days),
          session_desc: this.hosp_session,
          day_session: seesion_nameid(this.hosp_session),
          available_from: this.start_from1 + ":" + this.start_from2 + " " + this.start_from3,
          available_to: this.end_at1 + ":" + this.end_at2 + " " + this.end_at3,
          consultant: cosult_id,
          appointment: this.app_token + "" + this.app_time,
          token: token_name,
          app_duration: time_dure,
          consult_time_id: "0"
        })

        // this.hosp_name_txt = "";
        // this.hosp_loc_txt = "";
        // this.hosp_dist = "";
        // this.hosp_state = "";
        // this.hosp_country = "";
        // this.hosp_zip = "";
        // this.hosp_address1 = "",
        //   this.hosp_address2 = "";
        // this.hosp_contact_num = "";
        // this.hop_website = '';
        // this.hosp_fee = "";
        this.hosp_days = "";
        this.hosp_session = "";
        this.hops_consult = false;
        this.count_txt = "";
        this.start_from1 = "06";
        this.start_from2 = "00";
        this.start_from3 = "AM";
        this.end_at1 = "08";
        this.end_at2 = "00";
        this.end_at3 = "AM";

      } else {
        this.toastr.error(Message_data.invalidTime);
      }
    }
  }

  //table delecte action
  work_delete(name_data, name, day, session, start_time) {
    for (var i = 0; i < this.work_arry.length; i++) {
      if (name_data == this.work_arry[i].consult_time_id && name == this.work_arry[i].name && day == this.work_arry[i].day_desc
        && session == this.work_arry[i].session_desc && start_time == this.work_arry[i].available_from) {
        this.work_arry.splice(i, 1);
      }
    }

    // var headers = new Headers();
    // headers.append('Content-Type', 'application/json');
    // this.http.post(this.cancelwork, JSON.stringify({
    //   cons_time_id: name_data,
    //   flag: "doctor"
    // }), { headers: headers }).subscribe(
    //   data => {
    //     
    //     if (data.json().key !== null && data.json().key == "1") {
    //       this.toastr.error("Successfully deleted work location");

    //     } else {
    //       this.toastr.error("Unable to delete work location, please try later");
    //     }
    //   }, error => {

    //   }
    // );
  }

  clearHospital() {
    this.hosp_name_txt = null;
    this.hosp_id = undefined;
    this.hospital_filterlist = [];
  }

  hospital_filter(e) {
    this.city_list_data = [];
    this.state_list_data = [];
    this.country_list_data = [];
    this.filteredlocList = [];
    this.location_list_data = [];

    this.hosp_loc_txt = '';
    this.hosp_dist = '';
    this.hosp_state = '';
    this.hosp_country = '';
    this.hosp_zip = '';
    this.hosp_address1 = '';
    this.hosp_address2 = '';
    this.hosp_contact_num = '';
    this.hop_website = '';

    var tmp_arr = [];
    if (this.hosp_name_txt !== "" && this.hosp_name_txt.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'usercontroller/hospital/',
        JSON.stringify({
          hosp_name: this.hosp_name_txt
        }),
        { headers: headers })
        .subscribe(
          response => {
            this.hospital_txt = [];
            this.hospital_filterlist = [];

            var obj = response.json();

            if (obj.hospitals != null) {
              this.filteredhospList = [];
              this.hospital_filterlist = obj.hospitals;

              for (var i = 0; i < this.hospital_filterlist.length; i++) {
                this.hospital_txt.push(this.hospital_filterlist[i].hosp_name);
              }

              this.filteredhospList = this.hospital_txt.filter(function (this: any, el) {
                return el.toLowerCase().indexOf(this.hosp_name_txt.toLowerCase()) > -1;
              }.bind(this));

              if (this.hospital_txt.length == 0) {
                this.toastr.error(Message_data.noHosptlFound);
              }

            } else {
              this.toastr.error(Message_data.noHosptlFound);
            }
          },
          error => {
          }
        )

    } else {
      this.filteredhospList = [];
    }
  }

  getLocationBasedonhospital() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'gen/hloc/',
      JSON.stringify({
        hosp_name: this.hosp_name_txt
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);

          if (obj.hosp_locations != null) {
            this.location_list_data = obj.hosp_locations;
          }
        },
        error => {

        }
      )
  }

  select_hospital(data) {
    this.hosp_name_txt = data;
    this.filteredhospList = [];
    if (this.hosp_name_txt != undefined) {
      this.getLocationBasedonhospital();
    }
  }

  Loc_change(e) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'gen/haddr/',
      JSON.stringify({
        hosp_name: this.hosp_name_txt,
        location: this.hosp_loc_txt
      }),
      { headers: headers })
      .subscribe(
        response => {

          var obj = JSON.parse(response["_body"]);

          if (obj.hptl_clinic_id != null) {
            this.hosp_id = obj.hptl_clinic_id;
            this.hosp_address1 = obj.address1;
            this.hosp_address2 = obj.address2;
            this.hosp_dist = obj.city;
            this.hosp_state = obj.state;
            this.hosp_country = obj.country;
            this.hosp_contact_num = obj.telephone;
            this.hop_website = obj.website;
            this.hosp_zip = obj.zipcode;

          }

        },
        error => {

        }
      )
  }
  //location autopopulate
  locfilter() {
    this.hosp_dist = '';
    this.hosp_state = '';
    this.hosp_country = '';
    this.hosp_zip = '';
    this.hosp_address1 = '';
    this.hosp_address2 = '';
    this.hosp_contact_num = '';
    this.hop_website = '';
    this.hosp_id = "0";
    this.location_txt = [];
    this.location_filterlist = [];
    if (this.hosp_loc_txt !== "" && this.hosp_loc_txt.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'gen/loc',
        JSON.stringify({
          loc_name: this.hosp_loc_txt

        }),
        { headers: headers })
        .subscribe(
          response => {

            var obj = JSON.parse(response["_body"]);

            if (obj.locations != null) {
              this.location_filterlist = obj.locations;

              this.filteredlocList = this.location_filterlist.filter(function (this: any, el) {
                return el.toLowerCase().indexOf(this.hosp_loc_txt.toLowerCase()) > -1;
              }.bind(this));
            }


          },
          error => {

          }
        )
    } else {
      this.filteredlocList = [];
    }
  }
  select_location(data) {
    this.hosp_loc_txt = data;
    this.filteredlocList = [];

    this.Get_City(data);
    this.state_list_data = [];
    this.city_list_data = [];
    this.country_list_data = [];
  }

  Get_City(data) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');

    this.http.post(this.city_url, JSON.stringify({ loc_name: data }), { headers: headers }).subscribe(
      data => {



        if (data.json().cities != null && data.json().cities.length != 0) {
          this.city_list_data = data.json().cities;

          this.hosp_dist = this.city_list_data[0].city_id;
          this.filt_city_desc = this.city_list_data[0].city_desc;
          this.loc_id = this.city_list_data[0].location_id;
          this.hosp_zip = this.city_list_data[0].zipcode;
          this.City_change(this.city_list_data[0].city_id);
        }
      },
      error => {

      })
  }

  City_change(data) {
    if (data != null) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');

      this.http.post(this.state_url, JSON.stringify({ city_id: data }), { headers: headers }).subscribe(
        data => {
          if (data.json().states != null) {
            this.state_list_data = data.json().states;

            this.hosp_state = this.state_list_data[0].state_id;
            this.filt_state_desc = this.state_list_data[0].state_desc;

            this.State_change(this.state_list_data[0].state_id);
          }
        },
        error => {

        })
    } else {
      this.state_list_data = [];
      this.city_list_data = [];
      this.country_list_data = [];
    }
  }

  State_change(data) {
    if (data != null) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');

      this.http.post(this.country_url, JSON.stringify({ state_id: data }), { headers: headers }).subscribe(
        data => {

          if (data.json().countries != null && data.json().countries.length != null) {
            this.country_list_data = data.json().countries;
            this.country_id = this.country_list_data[0].country_id;
            this.hosp_country = this.country_list_data[0].country_desc;

            this.SerCountry_Change();


          }
        },
        error => {

        })
    } else {
      this.state_list_data = [];
      this.country_list_data = [];
    }
  }
  SerCountry_Change() {
    this.hosp_id = "0";
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'usercontroller/hospdetails/',
      JSON.stringify({
        hosp_name: this.hosp_name_txt,
        location: this.hosp_loc_txt,
      }),
      { headers: headers })
      .subscribe(
        response => {



          var obj = JSON.parse(response["_body"]);
          if (obj.hosp_id != null) {
            this.hosp_id = obj.hosp_id;

            this.hosp_address1 = obj.address1;
            this.hosp_address2 = obj.address2;
            if (obj.telephone != null) {
              this.hosp_contact_num = obj.telephone;
            }
            if (obj.website != null) {
              this.hop_website = obj.website;
            }
          }
        },
        error => {

        }
      )
  }

  send_workdata() {
    //this.work_arry
    var work_arry1: any = [];
    var work_address_array: any = [];

    for (var i = 0; i < this.work_arry.length; i++) {
      if (this.work_arry[i].hospital_id == "0") {
        var address2_txt = undefined
        if (this.work_arry[i].address2 != undefined) {
          address2_txt = this.work_arry[i].address2;
        }
        else {
          address2_txt = "";
        }

        work_address_array.push({
          name: this.work_arry[i].name,
          address1: this.work_arry[i].address1,
          address2: address2_txt,
          city: this.work_arry[i].city,
          state: this.work_arry[i].state,
          zipcode: this.work_arry[i].zipcode,
          country: this.work_arry[i].country,
          telephone: this.work_arry[i].mobilenum,
          website: this.work_arry[i].hospital_website,
          location: this.work_arry[i].location,

        });

        var hosp_nursename = this.work_arry[i].name;
        var hosp_nurseloc = this.work_arry[i].location;

      }

      if (this.work_arry[i].consult_time_id != "0") {
        var consult_time_id = this.work_arry[i].consult_time_id;
      }
      else {
        consult_time_id = "0";
      }

      work_arry1.push({
        hospital_id: this.work_arry[i].hospital_id,
        available_from: ConvertTimeformat("", this.work_arry[i].available_from),
        available_to: ConvertTimeformat("", this.work_arry[i].available_to),
        fee: this.work_arry[i].fee,
        day: this.work_arry[i].day,
        day_session: this.work_arry[i].day_session,
        token: this.work_arry[i].token,
        app_duration: this.work_arry[i].app_duration,
        consultant: this.work_arry[i].consultant,
        consultation_time_id: consult_time_id,
        hosp_name: hosp_nursename,
        location: hosp_nurseloc,

      });
    }
    Helper_Class.set_work_array(work_arry1);
    Helper_Class.set_work_add_array(work_address_array);
  }

  app_token_fun() {
    this.count_txt = '';
    if (this.app_token == true) {
      this.app_time = false;
      this.app_type_lbl = "Count";
      // this.app_token = true;
    }
  }

  app_time_fun() {
    this.count_txt = '';
    if (this.app_time == true) {
      this.app_token = false;
      this.app_type_lbl = "Minutes";
      // this.app_token = true;
    }
  }

  //qualification table
  qualification_btn_click() {
    if (this.qualification_degree == "" || this.qualification_uni == "" || this.qualification_year == "") {
      this.toastr.error(Message_data.mandatory);

    } else {
      this.qualification_array.push({
        degree: this.qualification_degree,
        institute: this.qualification_clg,
        university: this.qualification_uni,
        year: this.qualification_year,
      })
      //Resets the form 
      this.qualification_degree = "";
      this.qualification_uni = "";
      this.qualification_year = "";
      this.qualification_clg = "";
    }
  }

  //delete action
  qua_delete(degree_da, year_da) {
    for (var i = 0; i < this.qualification_array.length; i++) {
      if (degree_da == this.qualification_array[i].degree) {
        this.qualification_array.splice(i, 1);
      }
    }
  }

  send_Qualificationdata() {
    var qual_arr = null;
    qual_arr = {
      qualification: this.qualification_array
    }
    Helper_Class.setprofile_qual_array(qual_arr);
  }

  //update
  Update_click() {
    this.send_Personaldata();
    this.send_workdata();
    this.send_Qualificationdata();
    this.profile_tab = Helper_Class.getprofile_array();
    this.profile_work_tab = Helper_Class.get_work_array();
    this.profile_work_add = Helper_Class.get_work_add_array();
    this.profile_qual = Helper_Class.getprofile_qual_array();
    var homecareArray = [];

    if (this.profile_tab.upd_url == "1") {
      this.send_url = "usercontroller/updprovweb/";
    }

    if (encrypt_decript.Decript(this.profile_tab.mobile).toString().trim() == "" || encrypt_decript.Decript(this.profile_tab.mobile).length != 10) {
      this.toastr.error(Message_data.validMobileNo);

    } else if (this.profile_tab.home_care == "1" && this.profile_tab.homecare_val == false) {
      this.toastr.error(Message_data.enterHomeCareMandatory);

    } else if (this.profile_tab.home_care == "1" && this.profile_tab.homecare_val == true && homecareArray.length == 0) {
      this.toastr.error(Message_data.addHomeCareConsult);

    } else if (this.profile_work_tab == undefined || this.profile_work_tab.length == 0) {
      this.toastr.error(Message_data.verifyWrkLocation);

    } else if (this.profile_qual == undefined || this.profile_qual.length == 0) {
      this.toastr.error(Message_data.verfiyQualf);

    } else if (this.profile_tab.mobile != Helper_Class.getProfile_info().mobile) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'usercontroller/ismobileexist',
        JSON.stringify({
          mobile: this.mobile_num,
          flag: "1"
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            if (obj.result == true) {
              this.toastr.error(Message_data.existMobileNo);

            } else {
              this.emailCheck();
            }
          },
          error => {

          }
        )

    } else {
      this.emailCheck();
    }
  }

  emailCheck() {
    if (this.profile_tab.email != Helper_Class.getProfile_info().email) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'usercontroller/isemailexist',
        JSON.stringify({
          email: this.email_id,
          flag: "1"
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);

            if (obj.result == true) {
              this.toastr.error(Message_data.existEmailID);

            } else {
              this.final_update();
            }
          },
          error => {

          }
        )

    } else {
      this.final_update();
    }
  }

  final_update() {
    var send_work = null;
    send_work = {
      consultation: this.profile_work_tab
    }

    var finalObj = $.extend(true, this.profile_tab, send_work);
    var finalObj1 = $.extend(true, finalObj, this.profile_qual);
    var output = null;
    output = finalObj1;

    // send foot ass
    var send_foot = null, getfoot_ass = null, qual;
    getfoot_ass = Helper_Class.get_work_add_array();

    if (getfoot_ass != undefined && getfoot_ass.length != 0) {
      send_foot = {
        address: getfoot_ass,
      }
      output = $.extend(true, finalObj1, send_foot);
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + this.send_url, output,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.key != null && obj.key == "1") {
            this.toastr.success(Message_data.prflUpdSuccess);
            this.router.navigateByUrl("loginpage");

          } else {
            this.toastr.error(Message_data.profileUpdFailed);
          }
        },
        error => {
        }
      )
  }

  jsonConcat(o1, o2) {
    for (var key in o2) {
      o1[key] = o2[key];
    }
    return o1;
  }

  //toggle
  change_clientcall(e) {
    this.client_call = e;
    if (e == false) {
      this.clientCall_lab = "No";
      document.getElementById("toggid").style.background = "red";
    }
    if (e == true) {
      this.clientCall_lab = "Yes";
      document.getElementById("toggid").style.background = "green";
    }
  }

  //toggle 2nd opinion
  change_secopinion(e) {
    this.second_opinion = e;
    if (e == false) {
      this.secop = "No";
      document.getElementById("toggsecopid").style.background = "red";
    }
    if (e == true) {
      this.secop = "Yes";
      document.getElementById("toggsecopid").style.background = "green";
    }
  }

  //toggle hm
  change_homecare(e) {
    this.homecare = e;
    this.togg_home = e;
    if (e == false) {
      this.homecare_lab = "No";
      document.getElementById("togghmid").style.background = "red";
    }
    if (e == true) {
      this.homecare_lab = "Yes";
      document.getElementById("togghmid").style.background = "green";
    }
    if (this.togg_home == false) {
      this.Homecare_show = true;
    }
    else {
      this.Homecare_show = false;
    }
  }
}
