import { Component, OnInit,ElementRef,Input,ViewEncapsulation,OnDestroy,Inject } from '@angular/core';
import{Helper_Class } from '../../helper_class';
import { Http, Headers, Jsonp } from '@angular/http';
// import { ipaddress.getIp, Ip_with_img_address, country_code } from '../../../assets/js/ipaddress';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Doc_Helper } from '../Doc_Helper';
import { Physio_Helper } from '../../Physio_module/Physio_Helper';
import { ipaddress } from '../../ipaddress' ;
@Component({
  selector: 'app-dietplanhistorypopup',
  templateUrl: './dietplanhistorypopup.component.html',
  styleUrls: ['./dietplanhistorypopup.component.css']
})
export class DietplanhistorypopupComponent implements OnInit {

  public nodataapp: boolean;

  @Input() id: string;parm: string;  
  public element: any;
  patient_entrydetails:boolean;
  public dietpopup:string;
  public pflag:boolean=false;
  public diettrackerflag:boolean=false;
  public client_reg_id;
  public relation_id;
  public sub_rel_id;
  public DietHist_Table_Data = [];
  public Trackhist_Table_Data=[];
  public getdata:any;
  constructor(private el: ElementRef,private http:Http,public dialogRef: MatDialogRef<DietplanhistorypopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
        dialogRef.disableClose = true;
            this.element = el.nativeElement;
          if(Helper_Class.getInfo().provider == "doctor"){
            this.getdata = Doc_Helper.getClient_Info();
          }else{
            this.getdata = Physio_Helper.getClient_Info();
          }
           if(data =="diettracker"){
               this.diettrackerflag=true;
               this.pflag=false;
               this.Diabetic_tracker_plan() 
           } else{
               this.pflag=true;
               this.diettrackerflag=false;
               this.Diabetic_diet_plan()
           }
             this.element.style.display = 'block';
  }

  onNoClick(){
      this.dialogRef.close();
  }
  
  ngOnInit(): void {
  }

  Diabetic_diet_plan() {
    var send_data;
   
    if(this.getdata.sub_id != null && this.getdata.sub_id.length !=0 && this.getdata.sub_id != undefined){
        send_data= {
        client_reg_id: this.getdata.Client_id,
        relation_id: this.getdata.rel_id,
        sub_rel_id: this.getdata.sub_id
        }
    } else {
        send_data= {
            client_reg_id: this.getdata.Client_id,
            relation_id: this.getdata.rel_id,
        }
    }
    
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'lifestyle/dphist', send_data ,
        { headers: headers })
        .subscribe(
            response => {
                var obj =response.json();
                
                for (var i = 0; i < obj.diet_plan_hist.length; i++) {
                    this.DietHist_Table_Data.push({
                        break_fast: obj.diet_plan_hist[i].break_fast,
                        morning: obj.diet_plan_hist[i].morning,
                        midday: obj.diet_plan_hist[i].midday,
                        lunch: obj.diet_plan_hist[i].lunch,
                        evening: obj.diet_plan_hist[i].evening,
                        dinner: obj.diet_plan_hist[i].dinner,
                        night: obj.diet_plan_hist[i].night,
                        created_date: obj.diet_plan_hist[i].created_date,
                    });
                    if (this.DietHist_Table_Data.length == 0) {
                        this.nodataapp = false;
                    }
                    else {
                        this.nodataapp = true;
                    }
                }
            },
            error => { 
            }
        )
    }

//TRacker history
  Diabetic_tracker_plan() {
    var send_data;

    if(this.getdata.sub_id != null && this.getdata.sub_id.length !=0 && this.getdata.sub_id != undefined){
        send_data= {
        client_reg_id: this.getdata.Client_id,
        relation_id: this.getdata.rel_id,
        sub_rel_id: this.getdata.sub_id,
        }
    } else {
        send_data= {
            client_reg_id: this.getdata.Client_id,
            relation_id: this.getdata.rel_id,
        }
    }
    
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'lifestyle/dpthist', send_data,
          { headers: headers })
          .subscribe(
              response => {
                  var obj =response.json();
                  
                  
                  for (var i = 0; i < obj.diet_plan_track_hist.length; i++) {
                      this.Trackhist_Table_Data.push({
                          break_fast: obj.diet_plan_track_hist[i].break_fast,
                          morning: obj.diet_plan_track_hist[i].morning,
                          midday: obj.diet_plan_track_hist[i].midday,
                          lunch: obj.diet_plan_track_hist[i].lunch,
                          evening: obj.diet_plan_track_hist[i].evening,
                          dinner: obj.diet_plan_track_hist[i].dinner,
                          night: obj.diet_plan_track_hist[i].night,
                          created_date: obj.diet_plan_track_hist[i].created_date,
                      });
                      if (this.Trackhist_Table_Data.length == 0) {
                          
                          this.nodataapp = false;
                      }
                      else {
                          this.nodataapp = true;
                      }
                  }
              },
              error => {
              }
          )
  
    }
//     else{
//       var send_data1= {
//         client_reg_id: localStorage.getItem("Client_id"),
//         relation_id: localStorage.getItem("rel_id"),
//       };
    

//     var headers = new Headers();
//     headers.append('Content-Type', 'application/json');
//     this.http.post(ipaddress.getIp.toString() + 'lifestyle/dpthist', send_data1,
//         { headers: headers })
//         .subscribe(
//             response => {
//                 var obj =response.json();
//                 
//                 for (var i = 0; i < obj.diet_plan_track_hist.length; i++) {
//                     this.Trackhist_Table_Data.push({
//                         break_fast: obj.diet_plan_track_hist[i].break_fast,
//                         morning: obj.diet_plan_track_hist[i].morning,
//                         midday: obj.diet_plan_track_hist[i].midday,
//                         lunch: obj.diet_plan_track_hist[i].lunch,
//                         evening: obj.diet_plan_track_hist[i].evening,
//                         dinner: obj.diet_plan_track_hist[i].dinner,
//                         night: obj.diet_plan_track_hist[i].night,
//                         created_date: obj.diet_plan_track_hist[i].created_date,
//                     });
//                     if (this.Trackhist_Table_Data.length == 0) {
//                         
//                         this.nodataapp = false;
//                     }
//                     else {
//                         this.nodataapp = true;
//                     }
//                 }
//             },
//             error => {
//             }
//         )
//      }
//   }


}
