import { Component, OnInit } from '@angular/core';
import { CommonDataService } from '../../providers/common-data.service';
import { Http, Headers } from '@angular/http';
import { MatDialog } from '@angular/material/dialog';
import { ipaddress } from '../../ipaddress';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from '../../helper_class';
import { DocumentDetailsZoomPageComponent } from '../document-details-zoom-page/document-details-zoom-page.component';
import { OtcNonpresPageComponent } from '../otc-nonpres-page/otc-nonpres-page.component';
import { ReactPageComponent } from '../react-page/react-page.component';
import { Client_Helper } from '../Client_helper';
import { ClientViewService } from '../clientview/client-view.service';
import { DropdownModelpageComponent } from '../../Doctor_module/dropdown-modelpage/dropdown-modelpage.component';
import pdfMake from 'pdfmake/build/pdfmake';
import { Message_data } from 'src/assets/js/Message_data';
declare var $ : any; 

@Component({
  selector: 'app-secondopinioncreate',
  templateUrl: './secondopinioncreate.component.html',
  styleUrls: ['./secondopinioncreate.component.css']
})
export class SecondopinioncreateComponent implements OnInit {
  toggHeartAttkFlag;
  public strokeFlag;
  public toggDiabFlag 
  public toggEndoFlag;
  public toggHyperFlag;
  public toggTuberFlag;
  public toggHepatFlag;
  public toggArterFlag;
  public toggAutoFlag;
  public toggHemoFlag;
  public toggHivFlag;
  public toggGlaFlag
  public relationId;
  public exerciseList = [];
  public retrvExerciseList = [];
  public red:boolean = false;
  public red1:boolean = false;
  public red2:boolean = false; public red3:boolean = false;
  public red4:boolean = false;
  public red5:boolean = false;
  public red6:boolean = false;
  public red7:boolean = false;
  public red8:boolean = false;
  public red9:boolean = false;
  public exerciseTxt: any;
  public toggAlcholFlag: boolean;
  public toggSmokingFlag: boolean;
  public toggGutkaFlag: boolean;
  public toggExerciseFlag;
  public opinioncheck;
  public panelOpenState11:boolean;
  public panelOpenState12:boolean;
  public panelOpenState13:boolean;
  public panelOpenState14:boolean;
  public panelOpenState10:boolean;
  public panelOpenState1:boolean;
  public panelOpenState2:boolean;
  public panelOpenState3:boolean;
  public panelOpenState4:boolean;
  public panelOpenState5:boolean;
  public panelOpenState6:boolean;
  public panelOpenState7:boolean;
  public panelOpenState8:boolean;
  public panelOpenState9:boolean;
  public toggDiab:string="no";
  public toggStorke:string="no";
  public toggEndo:string="no";
  public toggHyper:string="no";
  public toggTuber:string="no";
  public toggHepat:string="no";
  public toggHeartAttk:string="no";
  public toggArter:string="no";
  public toggAuto:string="no";
  public toggHemo:string="no";
  public toggHiv:string="no";
  public toggGla:string="no";
  public familyOther;
  public mainConcernMasterList = [];
  public mainconcern: any;
  public medicalHistoryMasterList = [];
  public medhist: any;
  public religiousbeliefMainList = [];
  public retrvReligiousBelief = [];
  public religiousBelief: any;
  public anyOtherIssue: string;
  public bloodPressure: string;
  public stressFactor: string;
  public dietaryHabits: string;
  public bmi;
  public height;
  public heightMeasure: string;
  public weight;
  public weightMeasure: string;
  public agreeBox: boolean;
  public opinionType:any;
  public presUrl: string;
  public docUrl: string;
  public sendData;
  public sendDocData;
  public medPresList;
  public presIdsList = [];
  public documentList;
  public docIdsList
  public sendImgData;
  public imageFileStr;
  public imageFileName;
  public imageList = [];
  public setGeneralDataList;
  public setGeneralData;
  public sendSecondOpnDataForSave;
  public secondOpnDoctorList;
  public appointmentClientData;
  public secondOpnGeneralDataList;
  public secondOpnGeneralData;
  public secondOpnHMDataList;
  public secondOpnFamilyDataList;
  public sendReportData: any;
  public appointmentDataList;
  public bloodReportList = [];
  public urineReportList = [];
  public feacesReportList = [];
  public xrayReportList = [];
  public scanReportList = [];
  public ultrasoundReportList = [];
  public retrvBloodReportList = [];
  public retrvUrineReportList = [];
  public retrvFeacesReportList = [];
  public retrvXrayReportList = [];
  public retrvScanReportList = [];
  public retrvUltrasoundReportList = [];
  public retrvPrescriptionList = [];
  public retrvDocumentList;
  public retrvImageList = [];
  public documentFlag: boolean = false;
  public sendUltraList = [];
  public sendScanList = [];
  public sendXrayList = [];
  public sendFeacesList = [];
  public sendUrineList = [];
  public sendBloodList = [];
  public saveUpdateFlag: boolean;
  public newUpload;
  public saveflag: boolean;
  public ynoption:boolean=false;
  public newUploadFlag:boolean=false;
  public ynopt1:boolean=false;
  public ynopt2:boolean=false;
  public ynopt3:boolean=false;
  public ynopt4:boolean=false;
  public ynopt5:boolean=false;
  public ynopt7:boolean=false;
  public ynopt8:boolean=false;
  public ynopt9:boolean=false;
  public ynopt10:boolean=false;
  public ynopt11:boolean=false;
  public ynopt12:boolean=false;
  public familyHistDataList;
  FieldDisable: boolean;
  exits: boolean;
  public anyMedFlag: boolean;
  public otcMedFlag: boolean;
  public otcFlag: boolean;
  public vitamins: boolean;
  public vitaminsFlag: boolean;
  public react: boolean;
  public reactFlag: boolean;
  public recentFlag: boolean;
  public freqFlag: boolean;
  public wgtLossFlag: boolean;
  public wgtGainFlag: boolean;
  public allergyFlag: boolean;
  public allergy: boolean;
  public otcNonpresData = [];
  public vitaminHerbalData = [];
  public reactionMedsupplyData = [];
  public allergyData = [];
  public setHealthDataList;
  public getHealthData;
  public presMedFlag: boolean;
  public imgFilePath: any;
  public retrvMedicalHistlist = [];
  public retrvMainConcern = [];
  public pastMedical;
  public userInfo:any;
  public opinionTypeList = [];
  public maritalStatus: string;
  public exerRoutine: string;
  public exerDuration: string;
  public exerFreq: string;
  public phyfreqLabel;
  public exerciseFlag:boolean
  public smokeDuration: string;
  public smokeFrequency: string;
  public alcholDuration: string;
  public alcoholFrequency: string;
  public gutkaDuration: string;
  public gutkaFrequency: string;
  public alcQuant: string;
  public alcMix: string;
  public tempExerciseDuration: string;
  public alcQuantityList = [];
  public mixingList = [];
  public exerciseRoutineList = [];
  public phydureLabel;
  public yogaList;
  public sendYogaData = [];
  public diagnosisFlag;
  public anyPresMedFlag:Boolean;
  public relationship;
  public pdfObj;
  public photos=[];
  public pdf;
  public base64data;
  public yogaFlag:boolean = false;
    constructor(public clientservice:ClientViewService,public gservice:CommonDataService,public toastr:ToastrService, public  http:Http,public  router:Router,public dialog: MatDialog) {
      this.Get_Opinion_Data();
      this.familyHistDataList = {
        diabetics: false,
        heart_attack: false,
        stroke: false,
        arterial: false,
        endocrine: false,
        autoimmune: false,
        hypertension: false,
        hemophilia: false,
        tuberculosis: false,
        hiv: false,
        hepatitis: false,
        glaucoma: false,
        other: ""
      }
      if (this.gservice.get_second_family_data() != null && this.gservice.get_second_family_data() != undefined) {
        this.familyHistDataList = this.gservice.get_second_family_data();
        if (this.familyHistDataList.disabled != null) {
          this.FieldDisable = true;
          this.saveUpdateFlag = true;
          if (this.familyHistDataList.Retrival != null) {
            this.exits = true;
          }
        }
        if (this.familyHistDataList.diabetics == null) {
          this.familyHistDataList = {
            diabetics: false,
            heart_attack: false,
            stroke: false,
            arterial: false,
            endocrine: false,
            autoimmune: false,
            hypertension: false,
            hemophilia: false,
            tuberculosis: false,
            hiv: false,
            hepatitis: false,
            glaucoma: false,
            other: ""
          }
        }
         else {
          if (this.familyHistDataList.diabetics == "yes"){
            this.ynopt1=true;
          }
          else{
            this.ynopt1=false
          }
          if (this.familyHistDataList.heart_attack == "yes"){
            this.ynopt2 =true}
          else{
            this.ynopt2 =false}
          if (this.familyHistDataList.stroke == "yes"){
            this.ynopt3=true;
          }
          else{this.ynopt3=false;}
          if (this.familyHistDataList.arterial == "yes"){
            this.ynopt4=true;
          }
          else{
            this.ynopt4=false;
          }
          if (this.familyHistDataList.endocrine == "yes")
            this.familyHistDataList.endocrine = true;
          else
            this.familyHistDataList.endocrine = false;
          if (this.familyHistDataList.autoimmune == "yes")
            this.familyHistDataList.autoimmune = true;
          else
            this.familyHistDataList.autoimmune = false;
          if (this.familyHistDataList.hypertension == "yes")
            this.familyHistDataList.hypertension = true;
          else
            this.familyHistDataList.hypertension = false;
          if (this.familyHistDataList.hemophilia == "yes")
            this.familyHistDataList.hemophilia = true;
          else
            this.familyHistDataList.hemophilia = false;
          if (this.familyHistDataList.tuberculosis == "yes")
            this.familyHistDataList.tuberculosis = true;
          else
            this.familyHistDataList.tuberculosis = false;
          if (this.familyHistDataList.hiv == "yes")
            this.familyHistDataList.hiv = true;
          else
            this.familyHistDataList.hiv = false;
          if (this.familyHistDataList.hepatitis == "yes")
            this.familyHistDataList.hepatitis = true;
          else
            this.familyHistDataList.hepatitis = false;
          if (this.familyHistDataList.glaucoma == "yes"){
            this.familyHistDataList.glaucoma = true;
          }
          else{
            this.familyHistDataList.glaucoma = false;
          }
          this.familyHistDataList.other = this.familyHistDataList.others;
        }
      }
      this.gservice.set_second_health_data(null);
      this.gservice.set_appoint_client_data(null);
      this.gservice.set_second_family_data(null);
      this.gservice.set_second_general_data(null);
      this.gservice.set_second_appointment_data(null);
      this.gservice.set_second_document_data(null);
      this.gservice.second_opinion_gen_mainconcern = [];
      this.gservice.second_opinion_gen_medhist = [];
      this.gservice.second_opinion_gen_religious_beliefs = [];
      this.gservice.second_opinion_gen_excer = [];
      this.saveUpdateFlag = false;
      this.saveflag = false;
      this.sendUltraList = [];
      this.sendScanList = [];
      this.sendXrayList = [];
      this.sendFeacesList = [];
      this.sendUrineList = [];
      this.sendBloodList = [];
      this.docIdsList = [];
      this.imageList = [];
      this.medPresList = [];
      this.documentList = [];
    }
    ngOnInit() {
      this.get_Basic_data();
      this.Get_main_concern();
      this.saveUpdateFlag=false;
      this.presMedFlag = true;
     this.anyPresMedFlag = true;
      this.imgFilePath = null;

      this.set_retrival_document_data();
      this.appointmentDataList = Client_Helper.getSO_client_details();
      var rel = this.appointmentDataList.relation; 
       for(var k = 0 ; k < rel.length; k++){
          this.relationship = rel[k].relationship_name;
          this.relationId = rel[k].relation_id;
        }
        this.sendData = {
          client_id: this.gservice.Client_login_detail_data.Client_Id,
          relation: this.relationship,
          home_care: "0"
        };
        this.sendDocData = {
          client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
          relation_id:  this.relationship,
        };
        this.sendReportData = {
          client_id: this.gservice.Client_login_detail_data.Client_Id,
          relation: this.relationship,
          home_care: "0"
        }
        this.presIdsList = [];
        this.docIdsList = [];
        this.GetPrescriptions();
        this.GetDocuments();
        this.get_Blood_reports(this.sendReportData);
        this.get_Urine_reports(this.sendReportData);
        this.get_Feaces_reports(this.sendReportData);
        this.get_Scan_reports(this.sendReportData);
        this.get_Xray_reports(this.sendReportData);
        this.get_Ultrasound_reports(this.sendReportData);
        this.documentFlag = true;
      this.set_general_data();
      this.Get_medical_history();
      this.Get_Religious_other_beliefs();
      this.Get_quantity();
      this.Get_Mixingtype();
      this.Get_exercise_routine();
      this.maritalStatus = "Single";
      this.stressFactor = "None"
      this.anyOtherIssue = "None";
      this.bloodPressure = "Normal";
      this.heightMeasure = "cms";
      this.weightMeasure = "kgs";
      this.alcholDuration = "<1 Year";
      this.alcoholFrequency = "<5";
      this.smokeDuration = "<1 Year";
      this.smokeFrequency = "<5";
      this.gutkaDuration = "<1 Year";
      this.gutkaFrequency = "<5";
      this.tempExerciseDuration = "15 Mins";
      this.anyMedFlag = false;
      this.otcFlag = false;
      this.vitaminsFlag = false;
      this.vitamins = false;
      this.reactFlag = false;
      this.vitaminsFlag = false;
      this.react = false;
      this.reactFlag = false;
      this.recentFlag = false;
      this.wgtGainFlag = false;
      this.wgtLossFlag = false;
      this.freqFlag = false;
      this.allergyFlag = false;
      this.get_retriev_data();
      this.imageFileStr = "assets/imgs/default.jpg";
      this.presUrl = ipaddress.getIp + "record/medprescriptions/";
      this.docUrl = ipaddress.getIp + "secopn/cdoc";
      this.opinioncheck= Client_Helper.getopinionchecked()
      this.maritalStatus = "Single";
      this.stressFactor = "None"
      this.anyOtherIssue = "None";
      this.bloodPressure = "Normal";
      this.heightMeasure = "cms";
      this.weightMeasure = "kgs";
      this.alcholDuration = "<1 Year";
      this.alcoholFrequency = "<5";
      this.smokeDuration = "<1 Year";
      this.smokeFrequency = "<5";
      this.gutkaDuration = "<1 Year";
      this.gutkaFrequency = "<5";
      this.tempExerciseDuration = "15 Mins"; 
    }
    myCallbackFunction = function (_params) {
      return new Promise((resolve, reject) => {
        resolve(_params);
      });
    }
    back_Nav() {
      this.clientservice.sendMessage("appnewsecondopinion")
    }
    nextPage() {
    }
    main_click(selected) {
      this.mainconcern = "";
      function StringBuilder(this:any,value) {
        this.strings = new Array("");
        this.append(value);
      }
      StringBuilder.prototype.append = function (value) {
        if (value) {
          this.strings.push(value);
        }
      }
      StringBuilder.prototype.clear = function () {
        this.strings.length = 1;
      }
      StringBuilder.prototype.toString = function () {
        return this.strings.join("");
      }
      var selected_data = new StringBuilder("");
      this.retrvMainConcern = selected;
      if (this.retrvMainConcern.length != 0) {
        var n = 0;
        for (var j = 0; j < this.retrvMainConcern.length; j++) {
          for (var i = 0; i < this.mainConcernMasterList.length; i++) {
            if (this.retrvMainConcern[j] == this.mainConcernMasterList[i].value) {
              n = n + 1;
              if (n > 1) {
                selected_data.append(",");
                selected_data.append("\n");
              }
              selected_data.append(this.mainConcernMasterList[i].label);
            }
          }
        }
        this.mainconcern = selected_data.toString();
      }
    }
  medical_popup() {
    for (var j = 0; j < this.medicalHistoryMasterList.length; j++) {
      this.medicalHistoryMasterList[j].checked = false;
    }
    if (this.retrvMedicalHistlist != undefined && this.retrvMedicalHistlist.length != 0) {
      for (var i = 0; i < this.retrvMedicalHistlist.length; i++) {
        for (var j = 0; j < this.medicalHistoryMasterList.length; j++) {
          if (this.retrvMedicalHistlist[i] == this.medicalHistoryMasterList[j].value) {
            this.medicalHistoryMasterList[j].checked = true;
          }
        }
      }
    }
    const dialogRef = this.dialog.open(DropdownModelpageComponent, {
      width: '500px',
      height: '400px',
      data:{
        title: "Past Medical History",
        main_array: this.medicalHistoryMasterList,
        selected_list:this.retrvMedicalHistlist,
      } 
    });
    dialogRef.afterClosed().subscribe(result => {
        if(result != undefined){
        this.meddiab_click(result);
        }
    });
  }

    meddiab_click(selected) {
      this.medhist = "";
      function StringBuilder(this:any,value) {
        this.strings = new Array("");
        this.append(value);
      }
      StringBuilder.prototype.append = function (value) {
        if (value) {
          this.strings.push(value);
        }
      }
      StringBuilder.prototype.clear = function () {
        this.strings.length = 1;
      }
      StringBuilder.prototype.toString = function () {
        return this.strings.join("");
      }
      var selected_data = new StringBuilder("");
      if (selected != "" && selected != null) {
        this.retrvMedicalHistlist = selected;
      }
      if (this.retrvMedicalHistlist.length != 0) {
        var n = 0;
        for (var j = 0; j < this.retrvMedicalHistlist.length; j++) {
          for (var i = 0; i < this.medicalHistoryMasterList.length; i++) {
            if (this.retrvMedicalHistlist[j] == this.medicalHistoryMasterList[i].value) {
              n = n + 1;
              if (n > 1) {
                selected_data.append(",");
                selected_data.append("\n");
              }
              selected_data.append(this.medicalHistoryMasterList[i].label);
            }
          }
        }
        this.medhist = selected_data.toString();
      }
    }
    relclick(selected) {
      this.religiousBelief = "";
      function StringBuilder(this:any,value) {
        this.strings = new Array("");
        this.append(value);
      }
      StringBuilder.prototype.append = function (value) {
        if (value) {
          this.strings.push(value);
        }
      }
      StringBuilder.prototype.clear = function () {
        this.strings.length = 1;
      }
      StringBuilder.prototype.toString = function () {
        return this.strings.join("");
      }
      var selected_data = new StringBuilder("");
      this.retrvReligiousBelief = selected;
      if (this.retrvReligiousBelief.length != 0) {
        var n = 0;
        for (var j = 0; j < this.retrvReligiousBelief.length; j++) {
          for (var i = 0; i < this.religiousbeliefMainList.length; i++) {
            if (this.retrvReligiousBelief[j] == this.religiousbeliefMainList[i].value) {
              n = n + 1;
              if (n > 1) {
                selected_data.append(",");
                selected_data.append("\n");
              }
              selected_data.append(this.religiousbeliefMainList[i].label);
            }
          }
        }
        this.religiousBelief = selected_data.toString();
      }
    }
    Add_Documents_Data() {
    }
    GetPrescriptions() {
     this.opinioncheck= Client_Helper.getopinionchecked()
      this.sendData = {
        client_id: this.gservice.Client_login_detail_data.Client_Id,
        relation: this.relationship,
        home_care: "0"
      };
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "record/medprescriptions/", JSON.stringify(this.sendData), { headers: headers }).subscribe(
        data => {
          if (data.json() != null) {
            if (data.json().prescriptions != null && data.json().prescriptions.length != 0) {
              this.medPresList = [];
              for (var pres_int = 0; pres_int < data.json().prescriptions.length; pres_int++) {
                var pres = data.json().prescriptions[pres_int];
                var doc_name = "";
                if (pres.middle_name != null) {
                  doc_name = pres.first_name + " " + pres.middle_name + " " + pres.last_name;
                } else {
                  doc_name = pres.first_name + " " + pres.last_name;
                }
                var clnt_name = "";
                if (pres.client_middle_name != null) {
                  clnt_name = encrypt_decript.Decript(pres.client_first_name) + " " + encrypt_decript.Decript(pres.client_middle_name) + " " + encrypt_decript.Decript(pres.client_last_name);
                } else {
                  clnt_name = encrypt_decript.Decript(pres.client_first_name) + " " + encrypt_decript.Decript(pres.client_last_name);
                }
                var pres_date = "";
                if (pres.date != null) {
                  pres_date = " on " + pres.date.split('-')[2] + "-" + pres.date.split('-')[1] + "-" + pres.date.split('-')[0];
                }
                this.medPresList.push({
                  data: "Dr." + doc_name + " prescribed for " + clnt_name + pres_date,
                  id: pres.pres_drug_id
                });
              }
            }
          }
        }, error => {
        });
    }
    GetDocuments() {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.docUrl, JSON.stringify(this.sendDocData), { headers: headers }).subscribe(
        data => {
          if (data.json().documents != null && data.json().documents.length != 0) {
            this.documentList = [];
            for (var d = 0; d < data.json().documents.length; d++) {
              var doc = data.json().documents[d];
              var doc_date = "";
              if (doc.document_date != null) {
                doc_date = doc.document_date.split('-')[2] + "-" + doc.document_date.split('-')[1] + "-" + doc.document_date.split('-')[0];
              }
              this.documentList.push({
                data: doc.document_name + " created : " + doc_date,
                id: doc.client_doc_id,
                name: doc.document_name
              });
            }
          }
        }, error => {
        });
    }
    getpres(pres_id,e) {
      var chk=e;
      var check_value = true;
      if (this.presIdsList.length != 0) {
        for (var h = 0; h < this.presIdsList.length; h++) {
          if (this.presIdsList[h] == pres_id) {
            check_value = false;
            break;
          }
        }
      }
      if (this.retrvPrescriptionList.length != 0) {
        for (var h = 0; h < this.retrvPrescriptionList.length; h++) {
          if (this.retrvPrescriptionList[h].test_id == pres_id) {
            check_value = false;
            break;
          }
        }
      }
      if (check_value == true) {
        this.presIdsList.push(pres_id);
      } else {
        for (var i = 0; i < this.presIdsList.length; i++) {
          if (this.presIdsList[i] == pres_id) {
            this.presIdsList.splice(this.presIdsList.indexOf(i), 1);
            break;
          }
        }
      }
    }
    getdoc(doc_id, docname) {
      var check_value = true;
      for (var h = 0; h < this.docIdsList.length; h++) {
        if (this.docIdsList[h] == doc_id) {
          check_value = false;
        } else {
          check_value = true;
        }
      }
      if (check_value == true) {
        this.docIdsList.push(doc_id + "#" + docname);
      } else {
        for (var i = 0; i < this.docIdsList.length; i++) {
          if (this.docIdsList[i] == doc_id + "#" + docname) {
            this.docIdsList.splice(this.docIdsList.indexOf(i), 1);
            break;
          }
        }
      }
    }
    view_med(test_id) {
      this.appointmentClientData = this.gservice.get_appoint_client_data();
      var viewmedicine={
        AppFlow:'presdetails1',
          "pres_test_id": test_id,
      }
    }
    viewdoc(doc_id, name) {
      this.report_imagepdf_detail(doc_id + "#" + name);
    }
    get_Blood_reports(get_data) {
      get_data.type = "blood";
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(
        ipaddress.getIp + "record/reportlist/", JSON.stringify(get_data), { headers: headers }).subscribe(
          data => {
            if (data.json() != null) {
              if (data.json().reportlist != null && data.json().reportlist.length != 0) {
                for (var i = 0; i < data.json().reportlist.length; i++) {
                  var client_name = "";
                  var doctor_name = "";
                  if (data.json().reportlist[i].client_middle_name != null) {
                    client_name = encrypt_decript.Decript(data.json().reportlist[i].client_first_name) + " " +
                      encrypt_decript.Decript(data.json().reportlist[i].client_middle_name) + " " +
                      encrypt_decript.Decript(data.json().reportlist[i].client_last_name);
                  } else {
                    client_name = encrypt_decript.Decript(data.json().reportlist[i].client_first_name) + " " +
                      encrypt_decript.Decript(data.json().reportlist[i].client_last_name);
                  }
                  if (data.json().middle_name != null) {
                    doctor_name = data.json().reportlist[i].first_name + " " + data.json().reportlist[i].middle_name + " " + data.json().reportlist[i].last_name;
                  } else {
                    doctor_name = data.json().reportlist[i].first_name + " " + data.json().reportlist[i].last_name;
                  }
                  this.bloodReportList.push({
                    Date: this.gservice.get_Date_Formate(data.json().reportlist[i].appointment_date),
                    Diag_Test_Id: data.json().reportlist[i].diag_test_id,
                    Centre_Name: data.json().reportlist[i].diag_centre_name,
                    Doctor_Name: doctor_name,
                    Client_Name: client_name
                  });
                }
              }
            }
          },
          error => {
          });
    }
    get_Urine_reports(get_data) {
      get_data.type = "urine";
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(
        ipaddress.getIp + "record/reportlist/", JSON.stringify(get_data), { headers: headers }).subscribe(
          data => {
            if (data.json() != null) {
              if (data.json().reportlist != null && data.json().reportlist.length != 0) {
                for (var i = 0; i < data.json().reportlist.length; i++) {
                  var client_name = "";
                  var doctor_name = "";
                  if (data.json().reportlist[i].client_middle_name != null) {
                    client_name = encrypt_decript.Decript(data.json().reportlist[i].client_first_name) + " " +
                      encrypt_decript.Decript(data.json().reportlist[i].client_middle_name) + " " +
                      encrypt_decript.Decript(data.json().reportlist[i].client_last_name);
                  } else {
                    client_name = encrypt_decript.Decript(data.json().reportlist[i].client_first_name) + " " +
                      encrypt_decript.Decript(data.json().reportlist[i].client_last_name);
                  }
                  if (data.json().middle_name != null) {
                    doctor_name = data.json().reportlist[i].first_name + " " + data.json().reportlist[i].middle_name + " " + data.json().reportlist[i].last_name;
                  } else {
                    doctor_name = data.json().reportlist[i].first_name + " " + data.json().reportlist[i].last_name;
                  }
                  this.urineReportList.push({
                    Date: this.gservice.get_Date_Formate(data.json().reportlist[i].appointment_date),
                    Diag_Test_Id: data.json().reportlist[i].diag_test_id,
                    Centre_Name: data.json().reportlist[i].diag_centre_name,
                    Doctor_Name: doctor_name,
                    Client_Name: client_name
                  });
                }
              }
            }
          },
          error => {
          });
    }
    get_Feaces_reports(get_data) {
      get_data.type = "faeces";
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(
        ipaddress.getIp + "record/reportlist/", JSON.stringify(get_data), { headers: headers }).subscribe(
          data => {
            if (data.json() != null) {
              if (data.json().reportlist != null && data.json().reportlist.length != 0) {
                for (var i = 0; i < data.json().reportlist.length; i++) {
                  var client_name = "";
                  var doctor_name = "";
                  if (data.json().reportlist[i].client_middle_name != null) {
                    client_name = encrypt_decript.Decript(data.json().reportlist[i].client_first_name) + " " +
                      encrypt_decript.Decript(data.json().reportlist[i].client_middle_name) + " " +
                      encrypt_decript.Decript(data.json().reportlist[i].client_last_name);
                  } else {
                    client_name = encrypt_decript.Decript(data.json().reportlist[i].client_first_name) + " " +
                    encrypt_decript.Decript(data.json().reportlist[i].client_last_name);
                  }
                  if (data.json().middle_name != null) {
                    doctor_name = data.json().reportlist[i].first_name + " " + data.json().reportlist[i].middle_name + " " + data.json().reportlist[i].last_name;
                  } else {
                    doctor_name = data.json().reportlist[i].first_name + " " + data.json().reportlist[i].last_name;
                  }
                  this.feacesReportList.push({
                    Date: this.gservice.get_Date_Formate(data.json().reportlist[i].appointment_date),
                    Diag_Test_Id: data.json().reportlist[i].diag_test_id,
                    Centre_Name: data.json().reportlist[i].diag_centre_name,
                    Doctor_Name: doctor_name,
                    Client_Name: client_name
                  });
                }
              }
            }
          },
          error => {
          });
    }
    get_Scan_reports(get_data) {
      get_data.type = "scans";
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(
        ipaddress.getIp + "record/reportlist/", JSON.stringify(get_data), { headers: headers }).subscribe(
          data => {
            if (data.json() != null) {
              if (data.json().reportlist != null && data.json().reportlist.length != 0) {
                for (var i = 0; i < data.json().reportlist.length; i++) {
                  var client_name = "";
                  var doctor_name = "";
                  if (data.json().reportlist[i].client_middle_name != null) {
                    client_name = encrypt_decript.Decript(data.json().reportlist[i].client_first_name) + " " +
                      encrypt_decript.Decript(data.json().reportlist[i].client_middle_name) + " " +
                      encrypt_decript.Decript(data.json().reportlist[i].client_last_name);
                  } else {
                    client_name = encrypt_decript.Decript(data.json().reportlist[i].client_first_name) + " " +
                      encrypt_decript.Decript(data.json().reportlist[i].client_last_name);
                  }
                  if (data.json().middle_name != null) {
                    doctor_name = data.json().reportlist[i].first_name + " " + data.json().reportlist[i].middle_name + " " + data.json().reportlist[i].last_name;
                  } else {
                    doctor_name = data.json().reportlist[i].first_name + " " + data.json().reportlist[i].last_name;
                  }
                  this.scanReportList.push({
                    Date: this.gservice.get_Date_Formate(data.json().reportlist[i].appointment_date),
                    Diag_Test_Id: data.json().reportlist[i].diag_test_id,
                    Centre_Name: data.json().reportlist[i].diag_centre_name,
                    Doctor_Name: doctor_name,
                    Client_Name: client_name
                  });
                }
              }
            }
          },
          error => {
          });
    }
    get_Xray_reports(get_data) {
      get_data.type = "x-ray";
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(
        ipaddress.getIp + "record/reportlist/", JSON.stringify(get_data), { headers: headers }).subscribe(
          data => {
            if (data.json() != null) {
              if (data.json().reportlist != null && data.json().reportlist.length != 0) {
                for (var i = 0; i < data.json().reportlist.length; i++) {
                  var client_name = "";
                  var doctor_name = "";
                  if (data.json().reportlist[i].client_middle_name != null) {
                    client_name = encrypt_decript.Decript(data.json().reportlist[i].client_first_name) + " " +
                      encrypt_decript.Decript(data.json().reportlist[i].client_middle_name) + " " +
                      encrypt_decript.Decript(data.json().reportlist[i].client_last_name);
                  } else {
                    client_name = encrypt_decript.Decript(data.json().reportlist[i].client_first_name) + " " +
                      encrypt_decript.Decript(data.json().reportlist[i].client_last_name);
                  }
                  if (data.json().middle_name != null) {
                    doctor_name = data.json().reportlist[i].first_name + " " + data.json().reportlist[i].middle_name + " " + data.json().reportlist[i].last_name;
                  } else {
                    doctor_name = data.json().reportlist[i].first_name + " " + data.json().reportlist[i].last_name;
                  }
                  this.xrayReportList.push({
                    Date: this.gservice.get_Date_Formate(data.json().reportlist[i].appointment_date),
                    Diag_Test_Id: data.json().reportlist[i].diag_test_id,
                    Centre_Name: data.json().reportlist[i].diag_centre_name,
                    Doctor_Name: doctor_name,
                    Client_Name: client_name
                  });
                }
              }
            }
          },
          error => {
          });
    }
    get_Ultrasound_reports(get_data) {
      get_data.type = "Ultra-Sound";
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(
        ipaddress.getIp + "record/reportlist/", JSON.stringify(get_data), { headers: headers }).subscribe(
          data => {
            if (data.json() != null) {
              if (data.json().reportlist != null && data.json().reportlist.length != 0) {
                for (var i = 0; i < data.json().reportlist.length; i++) {
                  var client_name = "";
                  var doctor_name = "";
                  if (data.json().reportlist[i].client_middle_name != null) {
                    client_name = encrypt_decript.Decript(data.json().reportlist[i].client_first_name) + " " +
                      encrypt_decript.Decript(data.json().reportlist[i].client_middle_name) + " " +
                      encrypt_decript.Decript(data.json().reportlist[i].client_last_name);
                  } else {
                    client_name = encrypt_decript.Decript(data.json().reportlist[i].client_first_name) + " " +
                      encrypt_decript.Decript(data.json().reportlist[i].client_last_name);
                  }
                  if (data.json().middle_name != null) {
                    doctor_name = data.json().reportlist[i].first_name + " " + data.json().reportlist[i].middle_name + " " + data.json().reportlist[i].last_name;
                  } else {
                    doctor_name = data.json().reportlist[i].first_name + " " + data.json().reportlist[i].last_name;
                  }
                  this.ultrasoundReportList.push({
                    Date: this.gservice.get_Date_Formate(data.json().reportlist[i].appointment_date),
                    Diag_Test_Id: data.json().reportlist[i].diag_test_id,
                    Centre_Name: data.json().reportlist[i].diag_centre_name,
                    Doctor_Name: doctor_name,
                    Client_Name: client_name,
                    TestId: "Test_" + i
                  });
                }
              }
            }
          },
          error => {
          });
    }
send_datafam(){ 
  var    family_hist_data = {
        diabetics:this.toggDiab,
        heart_attack: this.toggHeartAttk,
        stroke: this.toggStorke,
        arterial:this.toggArter,
        endocrine:this.toggEndo,
        autoimmune: this.toggAuto,
        hypertension:this.toggHyper,
        tuberculosis:this.toggTuber,
        hemophilia:this.toggHemo,
        hiv: this.toggHiv,
        hepatitis:this.toggHepat,
        glaucoma:this.toggGla,
        others: this.familyOther,
      }
      Helper_Class.setDiabFam(family_hist_data);
    }
    Document_Image_add() {
      this.sendImgData = {
        client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
        docname: this.imageFileName,
        document: this.base64data.split(',')[1],
        country: ipaddress.country_code,
        type:"pdf"
      }
      if( this.imageFileName != undefined && this.imageFileName != "" ){
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp + "secopn/uplfileweb/",
          JSON.stringify(this.sendImgData), { headers: headers }).subscribe(
            data => {
              if (data.json().key == "1" && data.json().file_path != null) {
                this.imageList.push({
                  image_path: data.json().file_path,
                  image_name: this.imageFileName
                });
                this.imageFileStr = "../../../assets/img/default.jpg";
                this.imageFileName = undefined;
              }
            },
            error => {
            })
      }
      
    }
    Document_Image_delete(index_val) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "secopn/delfl/",
        JSON.stringify({ filename: this.imageList[index_val].image_path }), { headers: headers }).subscribe(
          data => {
            if (data.json().result != null && data.json().key == "1") {
              this.imageList.splice(index_val, 1);
            } else {
              this.toastr.error(Message_data.unableToDelDocument);
            }
          },
          error => {
          })
    }
   report_imagepdf_detail(document) {
    if (document.indexOf("#") >= 0) {
      var report_data = document.split('#');
      var get_file_type = report_data[1].split('.');
      var send_data;
      send_data = {
        client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
        document_name: report_data[1],
      }
      if(report_data[0] != undefined && report_data[0] != null && report_data[0] != "")
        send_data.client_doc_id = report_data[0];
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(
        ipaddress.getIp + "gen/durl/", JSON.stringify(send_data), { headers: headers }).subscribe(
          data => {
            if (data.json() != null) {
              var report_detail = ipaddress.Ip_with_img_address + data.json().path;
              if (get_file_type[1] != undefined && get_file_type[1] == "pdf") {

                const dialogRef = this.dialog.open(DocumentDetailsZoomPageComponent, {
                  width: '40%',
                  height: '500px',
                  data:{ 
                    report_pdf: report_detail,
                  }
                });
                dialogRef.afterClosed().subscribe(result => {
                });
              }
            }
          },
          error => {
          });
    } else {
      const dialogRef = this.dialog.open(DocumentDetailsZoomPageComponent, {
        width: '40%',
        height: '300px',
        data:{ 
          report_image: document
        }
      });
      dialogRef.afterClosed().subscribe(result => {
      });
    }
  }
    set_retrival_document_data() {
      if (this.gservice.get_second_documet_data() != null) {
        this.saveUpdateFlag = true;
        this.retrvDocumentList = this.gservice.get_second_documet_data();
        if (this.retrvDocumentList != null) {
          if (this.retrvDocumentList.upload_documentimg_list != null) {
            this.retrvImageList = this.retrvDocumentList.upload_documentimg_list;
            for (var i = 0; i < this.retrvImageList.length; i++) {
              this.imageList.push({
                image_path: this.retrvImageList[i].documentFile,
                image_name: this.retrvImageList[i].document,
              })
            }
          }
          if (this.retrvDocumentList.Blood_Report_Data != null) {
            this.retrvBloodReportList = this.retrvDocumentList.Blood_Report_Data;
          } 
          if (this.retrvDocumentList.Urine_Report_Data != null) {
            this.retrvUrineReportList = this.retrvDocumentList.Urine_Report_Data;
          }
          if (this.retrvDocumentList.Faeces_Report_Data != null) {
            this.retrvFeacesReportList = this.retrvDocumentList.Faeces_Report_Data;
          }
          if (this.retrvDocumentList.Xray_Report_Data != null) {
            this.retrvXrayReportList = this.retrvDocumentList.Xray_Report_Data;
          }
          if (this.retrvDocumentList.Scan_Report_Data != null) {
            this.retrvScanReportList = this.retrvDocumentList.Scan_Report_Data;
          }
          if (this.retrvDocumentList.UltraSound_Report_Data != null) {
            this.retrvUltrasoundReportList = this.retrvDocumentList.UltraSound_Report_Data;
          }
          if (this.retrvDocumentList.Prescription_List_Data != null) {
            this.retrvPrescriptionList = this.retrvDocumentList.Prescription_List_Data;
          }
        }
      }
    }
    report_view(test_id, type, app_id, ) {
    }
    Blood_Checked(check, test_id) {
      if (check.checked == true) {
        this.sendBloodList.push(test_id);
        for (var i = 0; i < this.retrvBloodReportList.length; i++) {
          if (this.retrvBloodReportList[i].test_id == test_id) {
            this.toastr.error(Message_data.existTest);
            this.sendBloodList.splice(this.sendBloodList.indexOf(test_id), 1);
          }
        }
      } else {
        this.sendBloodList.splice(this.sendBloodList.indexOf(test_id), 1);
      }
    }
    Urine_Checked(check, test_id) {
      if (check.checked == true) {
        this.sendUrineList.push(test_id);
        for (var i = 0; i < this.retrvUrineReportList.length; i++) {
          if (this.retrvUrineReportList[i].test_id == test_id) {
            this.toastr.error(Message_data.existTest);
            this.sendUrineList.splice(this.sendUrineList.indexOf(test_id), 1);
          }
        }
      } else {
        this.sendUrineList.splice(this.sendUrineList.indexOf(test_id), 1);
      }
    }
    Feaces_Checked(check, test_id) {
      if (check.checked == true) {
        this.sendFeacesList.push(test_id);
        for (var i = 0; i < this.retrvFeacesReportList.length; i++) {
          if (this.retrvFeacesReportList[i].test_id == test_id) {
            this.toastr.error(Message_data.existTest);
            this.sendFeacesList.splice(this.sendFeacesList.indexOf(test_id), 1);
          }
        }
      } else {
        this.sendFeacesList.splice(this.sendFeacesList.indexOf(test_id), 1);
      }
    }
    Xray_Checked(check, test_id) {
      if (check.checked == true) {
        this.sendXrayList.push(test_id);
        for (var i = 0; i < this.retrvXrayReportList.length; i++) {
          if (this.retrvXrayReportList[i].test_id == test_id) {
            this.toastr.error(Message_data.existTest);
            this.sendXrayList.splice(this.sendXrayList.indexOf(test_id), 1);
          }
        }
      } else {
        this.sendXrayList.splice(this.sendXrayList.indexOf(test_id), 1);
      }
    }
    Scan_Checked(check, test_id) {
      if (check.checked == true) {
        this.sendScanList.push(test_id);
        for (var i = 0; i < this.retrvScanReportList.length; i++) {
          if (this.retrvScanReportList[i].test_id == test_id) {
            this.toastr.error(Message_data.existTest);
            this.sendScanList.splice(this.sendScanList.indexOf(test_id), 1);
          }
        }
      } else {
        this.sendScanList.splice(this.sendScanList.indexOf(test_id), 1);
      }
    }
    UltraSound_Checked(check, test_id) {
      if (check.checked == true) {
        this.sendUltraList.push(test_id);
        for (var i = 0; i < this.retrvUltrasoundReportList.length; i++) {
          if (this.retrvUltrasoundReportList[i].test_id == test_id) {
            this.toastr.error(Message_data.existTest);
            this.sendUltraList.splice(this.sendUltraList.indexOf(test_id), 1);
          }
        }
      } else {
        this.sendUltraList.splice(this.sendUltraList.indexOf(test_id), 1);
      }
    }
    Report_Update_click() {
      Client_Helper.setsecondopinioncreate(null);
      var Valid_flag = true;
      var ImgLength = this.imageList.length + this.docIdsList.length + this.sendBloodList.length +
        this.sendUrineList.length + this.sendFeacesList.length + this.sendXrayList.length +
        this.sendScanList.length + this.sendUltraList.length + this.presIdsList;
      ImgLength = this.documentList.length + this.bloodReportList.length + this.urineReportList.length +
        +this.feacesReportList.length + this.xrayReportList.length + this.scanReportList.length + this.ultrasoundReportList.length +
        this.medPresList;
      if (ImgLength >= 5) {
        this.toastr.error(Message_data.maxFiveReportsCanAttach);
      } else {
        var Image_document_1 = undefined;
        var Image_document_2 = undefined;
        var Image_document_3 = undefined;
        var Image_document_4 = undefined;
        var Image_document_5 = undefined;
        if (this.imageList.length != 0) {
          for (var i = 0; i < this.imageList.length; i++) {
            if (i == 0) {
              Image_document_1 = this.imageList[i].image_path;
            } else if (i == 1) {
              Image_document_2 = this.imageList[i].image_path;
            } else if (i == 2) {
              Image_document_3 = this.imageList[i].image_path;
            } else if (i == 3) {
              Image_document_4 = this.imageList[i].image_path;
            } else if (i == 4) {
              Image_document_5 = this.imageList[i].image_path;
            }
          }
        }
        if (this.docIdsList.length != 0) {
          for (var i = 0; i < this.docIdsList.length; i++) {
            if (Image_document_1 == undefined) {
              Image_document_1 = this.docIdsList[i];
            } else if (Image_document_2 == undefined) {
              Image_document_2 = this.docIdsList[i];
            } else if (Image_document_3 == undefined) {
              Image_document_3 = this.docIdsList[i];
            } else if (Image_document_4 == undefined) {
              Image_document_4 = this.docIdsList[i];
            } else if (Image_document_5 == undefined) {
              Image_document_5 = this.docIdsList[i];
            }
          }
        }
        var bloodList = undefined, UrineList = undefined, FaecesList = undefined, XrayTest = undefined, ScanTest = undefined, ULtraTest = undefined;
        if (this.sendBloodList.length != 0)
          bloodList = this.sendBloodList;
        if (this.sendUrineList.length != 0)
          UrineList = this.sendUrineList;
        if (this.sendFeacesList.length != 0)
          FaecesList = this.sendFeacesList;
        if (this.sendXrayList.length != 0)
          XrayTest = this.sendXrayList;
        if (this.sendScanList.length != 0)
          ScanTest = this.sendScanList;
        if (this.sendUltraList.length != 0)
          ULtraTest = this.sendUltraList;
        this.sendSecondOpnDataForSave = {
          doc1: Image_document_1,
          doc2: Image_document_2,
          doc3: Image_document_3,
          doc4: Image_document_4,
          doc5: Image_document_5,
          blood_test: bloodList,
          urine_test: UrineList,
          faeces_test: FaecesList,
          xray_test: XrayTest,
          scan_test: ScanTest,
          ultrasound_test: ULtraTest,
          pres_drug: this.presIdsList,
          opinion_id:localStorage.getItem("opinion_id"),
          country: ipaddress.country_code,
        };
        this.save_opinion("secopn/usecopn");
        Client_Helper.setsecondopinioncreate(this.sendSecondOpnDataForSave)
      }
    }
    UploadNewReports(e) {
      if (this.newUpload == "yes"){
      this.newUploadFlag=false;
        this.documentFlag = false;}
      else{
      this.newUploadFlag=true;
        this.documentFlag = true;}
    }
    second_opinion_save() {
      Client_Helper.setsecondopinioncreate(null);
      var ImgLength = this.imageList.length + this.docIdsList.length + this.sendBloodList.length +
        this.sendUrineList.length + this.sendFeacesList.length + this.sendXrayList.length +
        this.sendScanList.length + this.sendUltraList.length + this.presIdsList.length;
      if (this.agreeBox == undefined || this.agreeBox == false) {
        this.toastr.error(Message_data.confirmAboveDet);
      } else if (ImgLength > 5) {
        this.toastr.error(Message_data.maxFiveReportsCanAttach);
      } else {
        this.appointmentClientData = Helper_Class.getappoint_client_data();
        this.store_general_data()
        this.HealthMedOpen();
        this.send_datafam();
        this.Second_family_next()
        if(this.appointmentDataList !=null && this.appointmentDataList != undefined){
          var splid =Client_Helper.getsplnextdoclistpage().secOpn  
          for (var k=0;k<splid.length;k++){       
            var specalizationid= splid.specialization_id
          }
          var clnt_send_spl_id ;
          if (specalizationid != null) {
            clnt_send_spl_id = specalizationid;
          }
          if (CommonDataService.OpnSplId != undefined && CommonDataService.OpnSplId != null) {
            clnt_send_spl_id = CommonDataService.OpnSplId;
          }
          if (this.appointmentDataList.clnt_age != null) {
            var client_app_age = this.appointmentDataList.clnt_age;
          }
          if (this.appointmentDataList.clnt_blood_id != null) {
            var clnt_blood_id = this.appointmentDataList.clnt_blood_id;
          }
          if (this.appointmentDataList.clnt_symptom != null) {
            var clnt_symtom_data = encrypt_decript.Encript(this.appointmentDataList.clnt_symptom).toString();
          }
          if (this.appointmentDataList.clnt_fg_name != null) {
            var clnt_fatgad_name = encrypt_decript.Encript(this.appointmentDataList.clnt_fg_name).toString();
          }
          if (this.appointmentDataList.clnt_occupation != null) {
            var clnt_fgoccup_data = encrypt_decript.Encript(this.appointmentDataList.clnt_occupation).toString();
          }
          if (this.appointmentDataList.clnt_income != null) {
            var clnt_fgincome_data = encrypt_decript.Encript(this.appointmentDataList.clnt_income).toString();
          }
          if (this.appointmentDataList.clnt_mname != null) {
            var clnt_middle_name = encrypt_decript.Encript(this.appointmentDataList.clnt_mname).toString();
          }
          if (this.appointmentDataList.clnt_dob != null) {
            var client_send_dob = this.appointmentDataList.clnt_dob;
          }
          if (this.appointmentDataList.clnt_address2 != null) {
            var client_send_address2 = encrypt_decript.Encript(this.appointmentDataList.clnt_address2).toString();
          }
          if (this.appointmentDataList.sub_rel_id != null) {
            var client_sub_rel_id = this.appointmentDataList.sub_rel_id;
          }
          var  clntfirstname =encrypt_decript.Encript(this.appointmentDataList.clnt_fname).toString()
          if(clntfirstname!= null && clntfirstname != undefined)
          {
           clntfirstname= clntfirstname
          }   
          if( encrypt_decript.Encript(this.appointmentDataList.clnt_lname).toString() != null &&  encrypt_decript.Encript(this.appointmentDataList.clnt_lname).toString() != undefined)
          {
          var clntlast_name= encrypt_decript.Encript(this.appointmentDataList.clnt_lname).toString()
          }    
          if( encrypt_decript.Encript(this.appointmentDataList.clnt_gender).toString() != null &&  encrypt_decript.Encript(this.appointmentDataList.clnt_gender).toString() != undefined)
          {
          var Gender= encrypt_decript.Encript(this.appointmentDataList.clnt_gender).toString()
          }    
          if( encrypt_decript.Encript(this.appointmentDataList.clnt_gender).toString() != null &&  encrypt_decript.Encript(this.appointmentDataList.clnt_gender).toString() !=undefined)
          {
          var Gender= encrypt_decript.Encript(this.appointmentDataList.clnt_gender).toString()
          }  if(encrypt_decript.Encript(this.appointmentDataList.clnt_location_desc).toString() != null &&  encrypt_decript.Encript(this.appointmentDataList.clnt_location_desc).toString().toString() !=undefined)
          {
          var locationval= encrypt_decript.Encript(this.appointmentDataList.clnt_location_desc).toString()
          } 
          if( encrypt_decript.Encript(this.appointmentDataList.clnt_city_desc).toString() != null &&  encrypt_decript.Encript(this.appointmentDataList.clnt_city_desc).toString() !=undefined)
          {
          var cityval= encrypt_decript.Encript(this.appointmentDataList.clnt_city_desc).toString()
          } 
          if( encrypt_decript.Encript(this.appointmentDataList.clnt_cont_number).toString() != null &&  encrypt_decript.Encript(this.appointmentDataList.clnt_cont_number).toString() !=undefined)
          {
          var clnt_cont_number= encrypt_decript.Encript(this.appointmentDataList.clnt_cont_number).toString()
          } 
          if( this.appointmentDataList.treatement_type.medicare_id.toString() != null &&  this.appointmentDataList.treatement_type.medicare_id.toString() !=undefined)
          {
          var medicare=  this.appointmentDataList.treatement_type.medicare_id.toString()
          } 
          }
          if(client_sub_rel_id != undefined && client_sub_rel_id !=null){
          client_sub_rel_id=client_sub_rel_id
          }
        this.secondOpnGeneralDataList = this.gservice.get_second_general_data();
        this.secondOpnHMDataList = this.gservice.get_second_health_data();
        var Image_document_1 = undefined;
        var Image_document_2 = undefined;
        var Image_document_3 = undefined;
        var Image_document_4 = undefined;
        var Image_document_5 = undefined;
        if (this.imageList.length != 0) {
          for (var i = 0; i < this.imageList.length; i++) {
            if (i == 0) {
              Image_document_1 = this.imageList[i].image_path;
            } else if (i == 1) {
              Image_document_2 = this.imageList[i].image_path;
            } else if (i == 2) {
              Image_document_3 = this.imageList[i].image_path;
            } else if (i == 3) {
              Image_document_4 = this.imageList[i].image_path;
            } else if (i == 4) {
              Image_document_5 = this.imageList[i].image_path;
            }
          }
        }
        if (this.docIdsList.length != 0) {
          for (var i = 0; i < this.docIdsList.length; i++) {
            if (Image_document_1 == undefined) {
              Image_document_1 = this.docIdsList[i];
            } else if (Image_document_2 == undefined) {
              Image_document_2 = this.docIdsList[i];
            } else if (Image_document_3 == undefined) {
              Image_document_3 = this.docIdsList[i];
            } else if (Image_document_4 == undefined) {
              Image_document_4 = this.docIdsList[i];
            } else if (Image_document_5 == undefined) {
              Image_document_5 = this.docIdsList[i];
            }
          }
        }
        var bloodList = undefined, UrineList = undefined, FaecesList = undefined, XrayTest = undefined, ScanTest = undefined, ULtraTest = undefined;
        if (this.sendBloodList.length != 0)
          bloodList = this.sendBloodList;
        if (this.sendUrineList.length != 0)
          UrineList = this.sendUrineList;
        if (this.sendFeacesList.length != 0)
          FaecesList = this.sendFeacesList;
        if (this.sendXrayList.length != 0)
          XrayTest = this.sendXrayList;
        if (this.sendScanList.length != 0)
          ScanTest = this.sendScanList;
        if (this.sendUltraList.length != 0)
          ULtraTest = this.sendUltraList;
        this.secondOpnDoctorList = this.gservice.get_second_doctor_data();
        for (var i = 0; i < this.alcQuantityList.length; i++) {
          if (this.alcQuant == this.alcQuantityList[i].description) {
            this.secondOpnGeneralDataList.qty_id = this.alcQuantityList[i].alcohol_qty_id;
          } 
        }
        for (var i = 0; i < this.mixingList.length; i++) {
          if (this.alcMix == this.mixingList[i].description) {
            this.secondOpnGeneralDataList.mix_id = this.mixingList[i].mixing_id;
            var mix_id = this.secondOpnGeneralDataList.mix_id 
          }
        }
        this.secondOpnDoctorList = this.gservice.get_second_doctor_data();
        for (var i = 0; i < this.exerciseRoutineList.length; i++) {
          if (this.exerRoutine == this.exerciseRoutineList[i].description) {
           var exe_rout_id = this.exerciseRoutineList[i].exer_rout_id;
          }
        }
      this.sendSecondOpnDataForSave = {
          country: ipaddress.country_code,
          docarray: this.secondOpnDoctorList,
          client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
          relation_id:this.appointmentDataList.relation_id,
          sub_rel_id: client_sub_rel_id,
          mem_first_name: clntfirstname,
          mem_middle_name: clnt_middle_name,
          mem_last_name: clntlast_name,
          age: client_app_age,
          blood_group: clnt_blood_id,
          gender:Gender,
          location: locationval,
          city:cityval,
          mem_mobile:clnt_cont_number,
          guardian: clnt_fatgad_name,
          income: clnt_fgincome_data,
          occupation: clnt_fgoccup_data,
          medicare_id:medicare,
          specialization_id: clnt_send_spl_id,
          opinion_type:this.secondOpnGeneralDataList.opinion_type,
          marital_status: this.secondOpnGeneralDataList.marital_status,
          other_issues: this.secondOpnGeneralDataList.other_issues,
          height: this.secondOpnGeneralDataList.height,
          height_measure: this.secondOpnGeneralDataList.height_measure,
          weight: this.secondOpnGeneralDataList.weight,
          weight_measure: this.secondOpnGeneralDataList.weight_measure,
          bmi: this.secondOpnGeneralDataList.bmi,
          blood_pressure: this.secondOpnGeneralDataList.blood_pressure,
          stress_factor: this.secondOpnGeneralDataList.stress_factor,
          diet_habits: this.secondOpnGeneralDataList.diet_habits,
          opinion_concerns: this.secondOpnGeneralDataList.opinion_concerns,
          medical_history: this.secondOpnGeneralDataList.medical_history,
          religious_belief: this.secondOpnGeneralDataList.religious_belief,
          alcohol: this.secondOpnGeneralDataList.alcohol,
          quantity:this.secondOpnGeneralDataList.qty_id,
          mixing_type:mix_id,
          alc_duration: this.secondOpnGeneralDataList.alc_duration,
          alc_frequency: this.secondOpnGeneralDataList.alc_frequency,
          smoke: this.secondOpnGeneralDataList.smoke,
          smoke_duration: this.secondOpnGeneralDataList.smoke_duration,
          smoke_frequency: this.secondOpnGeneralDataList.smoke_frequency,
          gutka: this.secondOpnGeneralDataList.gutka,
          gutka_duration: this.secondOpnGeneralDataList.gutka_duration,
          gutka_frequency: this.secondOpnGeneralDataList.gutka_frequency,
          exercises: this.secondOpnGeneralDataList.exercises,
          exer_day_dur: this.secondOpnGeneralDataList.exc_dur,
          exer_routine: exe_rout_id,
        recent_weight_gain: this.secondOpnHMDataList.recent_weight_gain,
        recent_weight_loss: this.secondOpnHMDataList.recent_weight_loss,
        tiredness_frequency: this.secondOpnHMDataList.tiredness_frequency,
        recent_fever_chills: this.secondOpnHMDataList.recent_fever_chills,
        prescribed_medicine: this.secondOpnHMDataList.prescribed_medicine,
        pres_med_doc: this.secondOpnHMDataList.pres_med_doc, 
        "non-otc": this.secondOpnHMDataList["non-otc"],
        vitamins: this.secondOpnHMDataList.vitamins,
        med_reaction: this.secondOpnHMDataList.med_reaction,
        allergies: this.secondOpnHMDataList.allergies,
        fam_hist: this.gservice.get_second_family_data(),
          doc1: Image_document_1,
          doc2: Image_document_2,
          doc3: Image_document_3,
          doc4: Image_document_4,
          doc5: Image_document_5,
          blood_test: bloodList,
          urine_test: UrineList,
          faeces_test: FaecesList,
          xray_test: XrayTest,
          scan_test: ScanTest,
          ultrasound_test: ULtraTest,
          pres_drug: this.presIdsList,
        }
        this.save_opinion("secopn/savesecopn/");
        Client_Helper.setsecondopinioncreate(this.sendSecondOpnDataForSave);
      }
    }
    store_general_data() {
        this.setGeneralDataList = {};
        this.setGeneralData = {};
      this.setGeneralDataList.opinion_type = this.opinionType;
      if (this.maritalStatus != undefined) {
        this.setGeneralDataList.marital_status = this.maritalStatus;
      }
      if (this.anyOtherIssue != undefined) {
        this.setGeneralDataList.other_issues = this.anyOtherIssue;
      }
      if (this.height != undefined) {
        this.setGeneralDataList.height = this.height;
      }
      if (this.heightMeasure != undefined) {
        this.setGeneralDataList.height_measure = this.heightMeasure;
      }
      if (this.weight != undefined) {
        this.setGeneralDataList.weight = this.weight;
      }
      if (this.weightMeasure != undefined) {
        this.setGeneralDataList.weight_measure = this.weightMeasure;
      }
      if (this.bmi != undefined) {
        this.setGeneralDataList.bmi = this.bmi;
      }
      if (this.bloodPressure != undefined) {
        this.setGeneralDataList.blood_pressure = this.bloodPressure;
      }
      if (this.stressFactor != undefined) {
        this.setGeneralDataList.stress_factor = this.stressFactor;
      }
      if (this.dietaryHabits != undefined) {
        this.setGeneralDataList.diet_habits = this.dietaryHabits;
      }
      if (this.retrvMainConcern.length != 0) {
        this.setGeneralDataList.opinion_concerns = this.retrvMainConcern;
      }
      if (this.retrvMedicalHistlist.length != 0) {
        this.setGeneralDataList.medical_history = this.retrvMedicalHistlist;
      }
      if (this.retrvReligiousBelief.length != 0) {
        this.setGeneralDataList.religious_belief = this.retrvReligiousBelief;
      }
      if (this.retrvExerciseList.length != 0) {
        this.setGeneralDataList.exerciseTxt = this.retrvExerciseList;
      }
      if (this.panelOpenState11 == true) {
        this.setGeneralDataList.alcohol = "Yes";
        this.setGeneralDataList.quantity = this.alcQuant;
        this.setGeneralDataList.mixing_type = this.alcMix;
        this.setGeneralDataList.alc_duration = this.alcholDuration;
        this.setGeneralDataList.alc_frequency = this.alcoholFrequency;
      }
      if (this.panelOpenState11 == true) 
      {
        this.setGeneralDataList.alcohol = "Yes";
        this.setGeneralDataList.quantity = this.alcQuant;
        this.setGeneralDataList.mixing_type = this.alcMix;
        this.setGeneralDataList.alc_duration = this.alcholDuration;
        this.setGeneralDataList.alc_frequency = this.alcoholFrequency;
      }
      else {
        this.setGeneralDataList.alcohol = "No";
      }
      if (this.panelOpenState12 == true) 
      {
        this.setGeneralDataList.smoke = "Yes";
        this.setGeneralDataList.smoke_duration = this.smokeDuration;
        this.setGeneralDataList.smoke_frequency = this.smokeFrequency;
      }
      else {
        this.setGeneralDataList.smoke = "No";
      }
      if (this.panelOpenState13 == true) 
      {
        this.setGeneralDataList.gutka = "Yes";
        this.setGeneralDataList.gutka_duration = this.gutkaDuration;
        this.setGeneralDataList.gutka_frequency = this.gutkaFrequency;
      }
      else {
        this.setGeneralDataList.gutka = "No";
      }
      if (this.panelOpenState14 == true) 
      {
        if (this.retrvExerciseList.length != 0) {
          this.setGeneralDataList.exercises = this.retrvExerciseList;
        }
        this.setGeneralDataList.exc_dur = this.tempExerciseDuration;
        this.toggExerciseFlag="Yes";
        for (var i = 0; i < this.exerciseRoutineList.length; i++) {
          if (this.exerRoutine == this.exerciseRoutineList[i].description) {
            this.setGeneralDataList.exc_rout = this.exerciseRoutineList[i].exer_rout_id;
          }
        }
        if (this.exerciseRoutineList != undefined) {
          for (var z = 0; z < this.exerciseRoutineList.length; z++) {
            if (this.exerciseRoutineList[z].exer_rout_id == this.exerRoutine) {
              this.setGeneralDataList.exc_rout = this.exerciseRoutineList[z].exer_rout_id;
              break;
            }
          }
        }
      }
      this.gservice.set_second_general_data(this.setGeneralDataList);
      Helper_Class.setgeneral(this.setGeneralData)
    }
    save_opinion(url) {
      this.saveflag = true;
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + url,
        JSON.stringify(this.sendSecondOpnDataForSave), { headers: headers }).subscribe(
          data => {
            if (data.json().result != null && data.json().key == "1") {
              this.toastr.success(data.json().result)
              this.clientservice.sendMessage('secondopinionlist')
            } else {
              this.toastr.error(data.json().result)
              this.saveflag = false;
            }
          },
          error => {
          })
    }
    /* FAmily******/
    Second_general_next() {
      if ((this.maritalStatus == undefined) || (this.maritalStatus == "") ||
        (this.anyOtherIssue == "") || (this.bloodPressure == "") || (this.height == undefined) ||
        (this.weight == undefined) || (this.dietaryHabits == undefined)) {
          this.toastr.error(Message_data.mandatory);
        }

      else if (this.toggExerciseFlag == true && this.retrvExerciseList.length == 0) {
      this.toastr.error(Message_data.sltExercise);
      }
      else {
        this.store_general_data();
      }
    }
    calories_data() {
      if (this.height != undefined) {
        if (this.heightMeasure == "cms") {
          this.height = this.height.replace(/[^0-9]/g, '');
        }
        else {
          this.height = this.height.replace('/[^-0-9\.]/g', '');
          if (this.height.indexOf('.') !== -1) {
            if (this.height.split('.').length > 2) {
              this.height = this.height.height.slice(0, -1);
            }
          }
        }
        if (this.heightMeasure == "cms") {
          if (parseFloat(this.height) > 300) {
            this.height = this.height.slice(0, -1);
          }
        }
        else {
          if (parseFloat(this.height) > 100) {
            this.height = this.height.slice(0, -1);
          }
        }
      }
      if (this.weightMeasure != undefined && this.heightMeasure != undefined && this.height != undefined &&
        this.weight != undefined) {
        if (this.weightMeasure == "kgs" && this.heightMeasure == "inch") {
          var pound = this.weight * 2.20462;
          var Height_txt = parseFloat(this.height)
          var dat = pound / (Height_txt * Height_txt);
          var to = dat * 703;
          this.bmi = Math.round(to);
        }
        else if (this.weightMeasure == "kgs" && this.heightMeasure == "cms") {
          var meter = parseFloat(this.height) / 100.00;
          var to = parseFloat(this.weight) / (meter * meter);
          this.bmi = Math.round(to);
        }
        else if (this.weightMeasure == "lbs" && this.heightMeasure == "inch") {
          var Height_txt = parseFloat(this.height);
          var dat = this.weight / (Height_txt * Height_txt);
          var to = dat * 703;
          this.bmi = Math.round(to);
        }
        else if (this.weightMeasure == "lbs" && this.heightMeasure == "cms") {
          var Height_txt = parseFloat(this.height);
          var inch = Height_txt * 0.393701;
          var dat = this.weight / (inch * inch);
          var to = dat * 703;
          this.bmi = Math.round(to);
        }
      }
    }
  getColor(v){
    if(v=="Yes"){
      return "green";
    }
    if(v="No"){
      return "red";
    }
  }
  get_retriev_data() {
    this.wgtGainFlag = false;
    if (this.gservice.get_second_health_data() != null) {
      this.presMedFlag = false;
      this.anyPresMedFlag = false;
      this.exits = true;
      this.getHealthData = this.gservice.get_second_health_data();
      if (this.getHealthData.disabled != null) {
        this.FieldDisable = true;
      }
      if (this.getHealthData.recent_weight_gain == "false"){
        this.wgtGainFlag = false;
      }
      else{
        this.wgtGainFlag = true;  
        document.getElementById("hm1").style.backgroundColor="green";}
      if (this.getHealthData.recent_weight_loss == "false"){
        this.wgtLossFlag = false;
        document.getElementById("hm2").style.backgroundColor="red";}
      else{
        this.wgtLossFlag = true;
        document.getElementById("hm2").style.backgroundColor="green";}
      if (this.getHealthData.tiredness_frequency == "false"){
        this.freqFlag = false;
        document.getElementById("hm3").style.backgroundColor="red";}
      else{
        this.freqFlag = true;
        document.getElementById("hm3").style.backgroundColor="green";}
      if (this.getHealthData.recent_fever_chills == "false"){
        this.recentFlag = false;
        document.getElementById("hm4").style.backgroundColor="red";}
      else{
        this.recentFlag = true;
        document.getElementById("hm4").style.backgroundColor="green";}
      if (this.getHealthData.prescribed_medicine == "true") {
        this.anyMedFlag = true;
        this.anyPresMedFlag = false;
        document.getElementById("hm5").style.backgroundColor="green";
      } else {
        this.anyPresMedFlag = true;
        this.anyMedFlag = false;
        document.getElementById("hm5").style.backgroundColor="red";
      }
      if (this.getHealthData.allergies != null) {
        this.allergyFlag = true;
        this.allergy = true;
        this.allergyData = this.getHealthData.allergies
        document.getElementById("hm9").style.backgroundColor="green";
      } else {
        this.allergyFlag = false;
        document.getElementById("hm9").style.backgroundColor="red";
      }
      if (this.getHealthData.otc_medicines != null) {
        this.otcFlag = true;
        this.otcMedFlag = true;
        this.otcNonpresData = this.getHealthData.otc_medicines
        document.getElementById("hm6").style.backgroundColor="green";
      } else {
        this.otcFlag = false;
        this.otcMedFlag = false;
        document.getElementById("hm6").style.backgroundColor="red";
      }
      if (this.getHealthData.med_reaction != null) {
        this.reactFlag = true;
        this.react = true;
        this.reactionMedsupplyData = this.getHealthData.med_reaction
        document.getElementById("hm8").style.backgroundColor="green";
      } else {
        this.reactFlag = false;
        this.react = false;
        document.getElementById("hm8").style.backgroundColor="red";
      }
      if (this.getHealthData.vitamins != null) {
        this.vitaminsFlag = true;
        this.vitamins = true;
        this.vitaminHerbalData = this.getHealthData.vitamins
        document.getElementById("hm7").style.backgroundColor="green";
      } else {
        this.vitaminsFlag = false;
        document.getElementById("hm7").style.backgroundColor="red";
      }
    }
  }
  ViewDocument() {
  }
  any_pres_change() {
    if (this.anyMedFlag == undefined || this.anyMedFlag == true) {
      this.anyMedFlag = false;
      if (this.presMedFlag == true)
        this.anyPresMedFlag = true;
      else
        this.anyPresMedFlag = false;
    } else if (this.anyMedFlag == false) {
      this.anyMedFlag = true;
    }
  }
  vit_change(value) {
      this.red7 = !this.red7;
      if(this.red7 == true){
        this.vitaminsFlag = true;
      }
      if(this.red7 == false){
        this.vitaminsFlag = false;
      }
  }
  react_change(e) {
      this.red8 = !this.red8;
      if(this.red8 == true){
        this.reactFlag = true;
      }
      if(this.red8 == false){
        this.reactFlag = false;
      }
  }
  allergy_change(e) {
    this.red9 = !this.red9;
    if(this.red9 == true){
      this.allergyFlag = true;
    }
    if(this.red9 == false){
      this.allergyFlag = false;
    }
  }
  navigate_otc_herbal1(type_text) {
    this.red6 = !this.red6;
    if(this.red6 == true){
      this.otcFlag = true;
      this.otcMedFlag = true;
    }
    if(this.red6 == false){
      this.otcFlag = false;
      this.otcMedFlag = false;
    }
  }
  navigate_otc_herbal(type_text){
    if (type_text == "OTC") {
      const dialogRef = this.dialog.open(OtcNonpresPageComponent, {
        width: '50%',
        height: '500px',
        data:{ 
            type: type_text,
              retrival: false,
              flag: false,
              table_data: this.otcNonpresData
          }
      });
      dialogRef.afterClosed().subscribe(data => {
      if(data !=undefined){
        if (data.length != 0) {
          if (data[0].type == "OTC") 
          {
            this.otcNonpresData = [];
            for (var p = 0; p < data.length; p++) {
              var intake_qty_data = data[p].intake_qty.split('-');
              this.otcNonpresData.push({
                medicine_name: data[p].med_name,
                dosage: data[p].dosage,
                intake: data[p].session,
                morning: intake_qty_data[0],
                afternoon: intake_qty_data[1],
                evening: intake_qty_data[2],
                night: intake_qty_data[3],
                side_effects: data[p].side_effect,
              });
            }
          }
        }
      }
    });
    }
    if(type_text == "Herbal"){
    const dialogRef = this.dialog.open(OtcNonpresPageComponent, {
      width: '50%',
      height: '500px',
      data:{ 
        type: type_text,
        retrival: true,
        flag: false,
        table_data: this.vitaminHerbalData
        }
    });
    dialogRef.afterClosed().subscribe(data => {
      this.vitaminHerbalData = [];
      for (var p = 0; p < data.length; p++) {
        var intake_qty_data = data[p].intake_qty.split('-');
        this.vitaminHerbalData.push({
          supplement: data[p].med_name,
          dosage: data[p].dosage,
          intake: data[p].session.drug_id,
          morning: intake_qty_data[0],
          afternoon: intake_qty_data[1],
          evening: intake_qty_data[2],
          side_effects: data[p].side_effect,
        });
      }
      });
    }
  }
  navigate_react_allergy(type_text) {
    let modelPage;
    if (type_text == "reaction") {
      if(type_text !=null){
    const dialogRef = this.dialog.open(ReactPageComponent, {
      width: '40%',
        height: '200px',
      data:{ 
        type: type_text,
              retrival: true,
              flag: false,
              table_data: this.reactionMedsupplyData
        }
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data != null) {
        if (data.length != 0) {
          if (data[0].type == "reaction") {
            this.reactionMedsupplyData = [];
            for (var p = 0; p < data.length; p++) {
              this.reactionMedsupplyData.push({
                supplement_name: data[p].med_supply_name,
                reaction: data[p].react_allrgy
              });
            }
          } else {
            this.allergyData = [];
            for (var p = 0; p < data.length; p++) {
              this.allergyData.push({
                medicine_name: data[p].med_supply_name,
                allergies: data[p].react_allrgy
              });
            }
          }
        }
      }
    });
  }
  else{
    const dialogRef = this.dialog.open(ReactPageComponent, {
      width: '40%',
        height: '200px',
      data:{ 
        type: type_text,
              retrival: false,
              flag: false,
              table_data: this.reactionMedsupplyData
        }
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data != null) {
        if (data.length != 0) {
          if (data[0].type == "reaction") {
            this.reactionMedsupplyData = [];
            for (var p = 0; p < data.length; p++) {
              this.reactionMedsupplyData.push({
                supplement_name: data[p].med_supply_name,
                reaction: data[p].react_allrgy
              });
            }
          } else {
            this.allergyData = [];
            for (var p = 0; p < data.length; p++) {
              this.allergyData.push({
                medicine_name: data[p].med_supply_name,
                allergies: data[p].react_allrgy
              });
            }
          }
        }
      }
    });
  }
    }
  else{
    if(type_text != null){
      const dialogRef = this.dialog.open(ReactPageComponent, {
        width: '40%',
        height: '200px',
        data:{ 
          type: type_text,
            retrival: true,
            flag: false,
            table_data: this.allergyData
          }
      });
      dialogRef.afterClosed().subscribe(data => {
        if (data != null) {
          if (data.length != 0) {
            if (data[0].type == "reaction") {
              this.reactionMedsupplyData = [];
              for (var p = 0; p < data.length; p++) {
                this.reactionMedsupplyData.push({
                  supplement_name: data[p].med_supply_name,
                  reaction: data[p].react_allrgy
                });
              }
            } else {
              type_text="allergy"
              this.allergyData = [];
              for (var p = 0; p < data.length; p++) {
                this.allergyData.push({
                  medicine_name: data[p].med_supply_name,
                  allergies: data[p].react_allrgy
                });
              }
            }
          }
        }
      });
    }
    else{
      const dialogRef = this.dialog.open(ReactPageComponent, {
        width: '40%',
        height: '200px',
        data:{ 
          type: type_text,
            retrival: false,
            flag: false,
            table_data: this.allergyData
          }
      });
      dialogRef.afterClosed().subscribe(data => {
        if (data != null) {
          if (data.length != 0) {
            if (data[0].type == "reaction") {
              this.reactionMedsupplyData = [];
              for (var p = 0; p < data.length; p++) {
                this.reactionMedsupplyData.push({
                  supplement_name: data[p].med_supply_name,
                  reaction: data[p].react_allrgy
                });
              }
            } else {
              this.allergyData = [];
              for (var p = 0; p < data.length; p++) {
                this.allergyData.push({
                  medicine_name: data[p].med_supply_name,
                  allergies: data[p].react_allrgy
                });
              }
            }
          }
        }
      });
    }
  }
  }
  presentActionSheet(){
  }
    fileChange(data) {
      this.imgFilePath = null;
      this.readThis(data.target);
    }
    readThis(inputValue: any): void {
      var ValidFileExtension = ['jpg', 'JPG', 'jpeg', 'JPEG', 'png', 'PNG', 'gif', 'GIF', 'bmp', 'BMP','pdf'];
      if (inputValue.length != 0) {
        var file: File = inputValue.files[0];
        var fileExtension = file.type.split("/").pop();
        var myReader: FileReader = new FileReader();
        myReader.onloadend = (e) => {
          var fileSize = Math.round(inputValue.files[0].size / 1024);
          this.imageFileStr = myReader.result;
          this.imageFileName = inputValue.files[0].name.replace(" ", "");
          var ImgFlag = false;
          if (this.imageFileName.length != 0) {
            var FileName = this.imageFileName.split('.');
            for (var i = 0; i < ValidFileExtension.length; i++) {
              if (ValidFileExtension[i] == FileName[1]) {
                ImgFlag = true;
                break;
              }
            }
          }
          if(fileExtension == 'pdf'){
            this.pdf = file;
          }else{
            this.photos.push(this.imageFileStr)
          }
          if (ImgFlag == false) {
            this.toastr.error(Message_data.chkFileFormat);
            this.imageFileStr = null;
            $("#file").val('');
          } else {
            this.createPdf()
          }
        }
        if (inputValue.files[0] != undefined && inputValue.files[0].length != 0)
          myReader.readAsDataURL(inputValue.files[0]);
      }
    }
    createPdf(){
      if(this.photos.length !=0){
        let imgFinding = [];
        var fileExtension;
        for(var i=0;i<this.photos.length;i++){
            imgFinding.push({image: this.photos[i],fit: [540, 700]})
        }
            let docDefinition = {
             pageSize: 'A4',
             content: imgFinding
            };
            this.pdfObj = pdfMake.createPdf(docDefinition);
            this.pdfObj.getBuffer((buffer) => {
            var blob = new Blob([buffer], { 
              type: 'application/pdf' 
            });
            var reader = new FileReader();
            reader.readAsDataURL(blob); 
            reader.onloadend = function(this:any) {
              var base64data = reader.result;    
              this.base64data = base64data;
              this.savePres(base64data) 
            }.bind(this);        
          });
      }else if(this.pdf != undefined){
        var reader = new FileReader();
        reader.readAsDataURL(this.pdf); 
        reader.onloadend = function(this:any) {
          var base64data = reader.result;    
          this.base64data = base64data;
          this.savePres(base64data) 
        }.bind(this);   
      } else {
        this.toastr.error(Message_data.addAtlstOneImg);
      }
    }
    Get_main_concern() {
      this.mainConcernMasterList = [];
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.get(ipaddress.getIp + 'gen/mcorn',
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            if (obj.main_concerns.length != 0) {
              for (var i = 0; i < obj.main_concerns.length; i++) {
                this.mainConcernMasterList.push({
                  type: 'checkbox',
                  value: obj.main_concerns[i].main_concerns_id,
                  label: obj.main_concerns[i].description,
                  checked: false
                });
              }
            }
            if (this.gservice.second_opinion_gen_mainconcern != undefined && this.gservice.second_opinion_gen_mainconcern.length != 0) {
              this.main_click(this.gservice.second_opinion_gen_mainconcern)
            }
          },
          error => {
          }
        )
        this.Get_quantity();
        this.Get_Mixingtype();
        this.Get_exercise_routine();
        this.Get_excers_data();
    }
    Get_medical_history() {
      this.medicalHistoryMasterList = [];
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.get(ipaddress.getIp + 'gen/pmedhis', { headers: headers }).subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.med_hist.length != 0) {
            for (var i = 0; i < obj.med_hist.length; i++) {
              this.medicalHistoryMasterList.push({
                type: 'checkbox',
                value: obj.med_hist[i].med_hist_id,
                label: obj.med_hist[i].description,
                checked: false
              });
              if(obj.med_hist[i].med_hist_id == 32){
                this.pastMedical = obj.med_hist[i].description;
              }
            }
          }
          if (this.gservice.second_opinion_gen_medhist != undefined && this.gservice.second_opinion_gen_medhist.length != 0) {
            this.secondOpnGeneralDataList = this.gservice.get_second_general_data();
            if (this.gservice.second_opinion_gen_medhist.length != 0)
            {
              this.meddiab_click(this.gservice.second_opinion_gen_medhist)
            }
          }
        },
        error => {
        }
      )
    }
    Get_Religious_other_beliefs() {
      this.religiousbeliefMainList = [];
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.get(ipaddress.getIp + 'gen/relblf',
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            if (obj.religious_beliefs.length != 0) {
              for (var i = 0; i < obj.religious_beliefs.length; i++) {
                this.religiousbeliefMainList.push({
                  type: 'checkbox',
                  value: obj.religious_beliefs[i].religious_belief_id,
                  label: obj.religious_beliefs[i].description,
                  checked: false
                });
              }
            }
            if (this.gservice.second_opinion_gen_religious_beliefs != undefined && this.gservice.second_opinion_gen_religious_beliefs.length != 0) {
              this.relclick(this.gservice.second_opinion_gen_religious_beliefs)
            }
          },
          error => {
          }
        )
        this.store_general_data()
    }
    Get_quantity() {
      this.alcQuantityList = [];
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.get(ipaddress.getIp.toString() + 'dental/aqty',
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            this.alcQuantityList = obj.mixing_quantity;
            this.alcQuant = obj.mixing_quantity[0].alcohol_qty_id;
          },
          error => {
          }
        )
    }
    Get_Mixingtype() {
      this.mixingList = [];
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.get(ipaddress.getIp.toString() + 'dental/amix',
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            this.mixingList = obj.mixing_types;
            this.alcMix = obj.mixing_types[0].mixing_id;
          },
          error => {
          }
        )
    }
    set_general_data() {
      if (this.gservice.get_second_general_data() != null) {
        this.FieldDisable = true;
        this.exits = true;
        this.secondOpnGeneralDataList = this.gservice.get_second_general_data();
      this.secondOpnGeneralData = Helper_Class.getgeneral()
        if(this.secondOpnGeneralData.opinion_type !=null){
          this.opinionType=this.secondOpnGeneralData.opinion_type
        }
        if (this.secondOpnGeneralDataList.alc_duration != null) 
        {
          this.toggAlcholFlag= this.secondOpnGeneralDataList.alcohol
          this.alcQuant = this.secondOpnGeneralDataList.quantity;
          this.alcMix = this.secondOpnGeneralDataList.mixing_type;
          this.alcholDuration = this.secondOpnGeneralDataList.alc_duration;
          this.alcoholFrequency =  this.secondOpnGeneralDataList.alc_frequency;
         }
        if (this.secondOpnGeneralDataList.smoke_duration != null) 
        {
          this.toggSmokingFlag = this.secondOpnGeneralDataList.smoke;
          this.smokeDuration = this.secondOpnGeneralDataList.smoke_duration;
        }
        else {
          this.toggSmokingFlag =this.secondOpnGeneralDataList.smoke;
        }
        if (this.secondOpnGeneralDataList.gutka_duration != null) 
        {
          this.toggGutkaFlag =  this.secondOpnGeneralDataList.gutka;
          this.gutkaDuration = this.secondOpnGeneralDataList.gutka_duration;
          this.gutkaFrequency = this.secondOpnGeneralDataList.gutka_frequency;
        }
        else {
          this.toggGutkaFlag =  this.secondOpnGeneralDataList.gutka;
        }
        if (this.secondOpnGeneralDataList.exer_routine != null) 
        {
        this.toggExerciseFlag="Yes"
          this.exerciseTxt = this.secondOpnGeneralDataList.exercises;
          this.tempExerciseDuration = this.secondOpnGeneralDataList.exer_day_dur;
          this.exerRoutine = this.secondOpnGeneralDataList.exer_routine;
        }
         else {
           this.exerciseTxt =this.secondOpnGeneralDataList.exercises
      }
      if (this.secondOpnGeneralDataList.exer_day_dur != null) {
        this.tempExerciseDuration =this.secondOpnGeneralDataList.exer_day_dur;
        this.panelOpenState14 = true;
      }
           if (this.secondOpnGeneralDataList.exercise_routine != null) {
        for (var i = 0; i < this.exerciseRoutineList.length; i++) {
          for (var j = 0; j < this.secondOpnGeneralDataList.exercise_routine.length; j++) {
            if (this.secondOpnGeneralDataList.exercise_routine[j] == this.exerciseRoutineList[i].exer_rout_id) {
              this.exerRoutine = this.exerciseRoutineList[i].description;
            }
          }
        }
        this.panelOpenState14 = true;
      }
        if (this.secondOpnGeneralDataList.marital_status != null && this.secondOpnGeneralDataList.marital_status != undefined) {
          this.maritalStatus = this.secondOpnGeneralDataList.marital_status;
          this.bmi = this.secondOpnGeneralDataList.bmi;
          this.bloodPressure = this.secondOpnGeneralDataList.blood_pressure;
          this.dietaryHabits = this.secondOpnGeneralDataList.diet_habits;
          this.height = this.secondOpnGeneralDataList.height;
          this.heightMeasure = this.secondOpnGeneralDataList.height_measure;
          this.weight = this.secondOpnGeneralDataList.weight;
          this.weightMeasure = this.secondOpnGeneralDataList.weight_measure;
          this.anyOtherIssue = this.secondOpnGeneralDataList.other_issues;
        }
      }
    }
    get_Basic_data() {
      var appointment_client_data =this.gservice.get_appoint_client_data();
       if( localStorage.getItem("sec_sub_rel_id") !=null &&  localStorage.getItem("sec_sub_rel_id") != undefined){
        var send_data= JSON.stringify({
          client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
          relation_id: localStorage.getItem("secopinionrelation_id"),
          sub_rel_id: localStorage.getItem("sec_sub_rel_id")
        })
       }
       else{
        var send_data= JSON.stringify({
          client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
          relation_id: localStorage.getItem("secopinionrelation_id"),
       })
      }
      if (appointment_client_data != null) {
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp + "gen/asgdet",send_data
         ,
          { headers: headers }).subscribe(
            data => {
              this.secondOpnGeneralDataList = {};
              this.secondOpnFamilyDataList = {};
              if (data.json().alc_duration != null) 
              {
                this.secondOpnGeneralDataList.alcohol = "Yes";
                this.secondOpnGeneralDataList.quantity = data.json().alcohol_qty_id;
                this.secondOpnGeneralDataList.mixing_type = data.json().alcohol_mixing_id;
                this.secondOpnGeneralDataList.alc_duration = data.json().alc_duration;
                this.secondOpnGeneralDataList.alc_frequency = data.json().alc_frequency;
              }
              else {
                this.secondOpnGeneralDataList.alcohol = "No";
              }
              if (data.json().smoke_duration != null) 
              {
                 this.secondOpnGeneralDataList.smoke="Yes"
                this.secondOpnGeneralDataList.smoke_duration = data.json().smoke_duration;
                this.secondOpnGeneralDataList.smoke_frequency = data.json().smoke_frequency;
              }
              else {
                this.secondOpnGeneralDataList.smoke = "No";
                this.toggSmokingFlag=this.secondOpnGeneralDataList.smoke
              }
              if (data.json().gutka_duration != null) 
              {               
                this.secondOpnGeneralDataList.gutka = "Yes";
                this.secondOpnGeneralDataList.gutka_duration = data.json().gutka_duration;
                this.secondOpnGeneralDataList.gutka_frequency = data.json().gutka_frequency;
              }
              else {
                this.secondOpnGeneralDataList.gutka = "No";
              }
              if (data.json().exer_routine != null) 
              {
                 this.toggExerciseFlag="yes"
                 this.secondOpnGeneralDataList.exercises=data.json().exercises
                this.secondOpnGeneralDataList.exer_routine = data.json().exer_routine;
                this.secondOpnGeneralDataList.exer_day_dur = data.json().smoke_frequency;
              }
              else{
                this.secondOpnGeneralDataList.exercises=data.json().exercises
              }
              if(data.json().opinion_desc != null){
                this.secondOpnGeneralDataList.opinion_type=data.json().opinion_desc;
                this.opinionType=this.secondOpnGeneralDataList.opinion_type
              }
              this.set_general_data();
              if (data.json().diabetics != null) 
              {
                if (data.json().diabetics != "No" && data.json().diabetics != "no") {
                  this.secondOpnFamilyDataList.diabetics = "yes";
                  this.ynopt1 =true;
                }
                else {
                  this.secondOpnFamilyDataList.diabetics = "no";
                  this.ynopt1 =false;
                }
                if (data.json().heart_attack != "No" && data.json().heart_attack != "no") {
                  this.secondOpnFamilyDataList.heart_attack = "yes";
                  this.ynopt2=true;
                }
                else {
                  this.secondOpnFamilyDataList.heart_attack = "no";
                  this.ynopt2 =false;
                }
                if (data.json().stroke != "No" && data.json().stroke != "no") {
                  this.secondOpnFamilyDataList.stroke = "yes";
                  this.ynopt3=true;
                } else {
                  this.secondOpnFamilyDataList.stroke = "no";
                  this.ynopt3=false;
                }
                if (data.json().arterial != "No" && data.json().arterial != "no") {
                  this.secondOpnFamilyDataList.arterial = "yes";
                  this.ynopt4=true;
                } else {
                  this.ynopt4=false;
                  this.secondOpnFamilyDataList.arterial = "no";
                }
                if (data.json().endocrine != "No" && data.json().endocrine != "no") {
                  this.secondOpnFamilyDataList.endocrine = "yes";
                }
                else {
                  this.secondOpnFamilyDataList.endocrine = "no";
                }
                if (data.json().autoimmune != "No" && data.json().autoimmune != "no") {
                  this.ynopt5=true;
                  this.secondOpnFamilyDataList.autoimmune = "yes";
                }
                else {
                  this.ynopt5=false;
                  this.secondOpnFamilyDataList.autoimmune = "no";
                }
                if (data.json().hypertension != "No" && data.json().hypertension != "no") {
                  this.secondOpnFamilyDataList.hypertension = "yes";
                }
                else {
                  this.secondOpnFamilyDataList.hypertension = "no";
                }
                if (data.json().hemophilia != "No" && data.json().hemophilia != "no") {
                  this.secondOpnFamilyDataList.hemophilia = "yes";
                }
                else {
                  this.secondOpnFamilyDataList.hemophilia = "no";
                }
                if (data.json().tuberculosis != "No" && data.json().tuberculosis != "no") {
                  this.secondOpnFamilyDataList.tuberculosis = "yes";
                }
                else {
                  this.secondOpnFamilyDataList.tuberculosis = "no";
                }
                if (data.json().hiv != "No" && data.json().hiv != "no") {
                  this.secondOpnFamilyDataList.hiv = "yes";
                }
                else {
                  this.secondOpnFamilyDataList.hiv = "no";
                }
                if (data.json().hepatitis != "No" && data.json().hepatitis != "no") {
                  this.secondOpnFamilyDataList.hepatitis = "yes";
                }
                else {
                  this.secondOpnFamilyDataList.hepatitis = "no";
                }
                if (data.json().glaucoma != "No" || data.json().glaucoma != "no") {
                  this.secondOpnFamilyDataList.glaucoma = "yes";
                }
                else {
                  this.secondOpnFamilyDataList.glaucoma = "no";
                }
                this.secondOpnFamilyDataList.others = data.json().others;
                this.secondOpnFamilyDataList.disabled = true;
                this.gservice.set_second_family_data(this.secondOpnFamilyDataList);
              }
            },
            error => {
            })
      }
    }
      Alchol_toggle(e) {
        if (e.toString()=="Yes") {
          this.toggAlcholFlag == false
        } else {
          this.toggAlcholFlag == true;
        }
      }
      smoke_toggle(e) {
        if (e.toString()=="Yes") {
          this.toggSmokingFlag = false;
        } else {
          this.toggSmokingFlag = true;
        }
      }
      Gutka_toggle(e) {
        if (e.toString()=="Yes") {
          this.toggGutkaFlag = false;
        } else {
          this.toggGutkaFlag = true;
        }
      }
      Exercise_toggle(e) {
        if (e.toString()== "Yes") {
          this.toggExerciseFlag = false
        } else {
          this.toggExerciseFlag = true
        }
      }
  religious() {
    for (var j = 0; j < this.religiousbeliefMainList.length; j++) {
      this.religiousbeliefMainList[j].checked = false;
    }
    if (this.retrvReligiousBelief != undefined && this.retrvReligiousBelief.length != 0) {
      for (var i = 0; i < this.retrvReligiousBelief.length; i++) {
        for (var j = 0; j < this.religiousbeliefMainList.length; j++) {
          if (this.retrvReligiousBelief[i] == this.religiousbeliefMainList[j].value) {
            this.religiousbeliefMainList[j].checked = true;
          }
        }
      }
    }
    const dialogRef = this.dialog.open(DropdownModelpageComponent, {
      width: '500px',
      height: '400px',
      data:{
        title: "Religious",
        main_array: this.religiousbeliefMainList,
        selected_list:this.retrvReligiousBelief,
      } 
    });
    dialogRef.afterClosed().subscribe(result => {
        if(result != undefined){
        this.relclick(result);
        }
    });
  } 
      Get_Opinion_Data() {
        this.http.get(ipaddress.getIp + "secopn/opntype").subscribe(
          data => {
            if (data.json().appointment_types != null) {
              this.opinionTypeList = data.json().appointment_types;
              this.opinionType = this.opinionTypeList[0].opinion_type_id;
            }
          },
          error => {
          }
        )
      }
  mainconcern_popup() {
    for (var j = 0; j < this.mainConcernMasterList.length; j++) {
      this.mainConcernMasterList[j].checked = false;
    }
    if (this.retrvMainConcern != undefined && this.retrvMainConcern.length != 0) {
      for (var i = 0; i < this.retrvMainConcern.length; i++) {
        for (var j = 0; j < this.mainConcernMasterList.length; j++) {
          if (this.retrvMainConcern[i] == this.mainConcernMasterList[j].value) {
            this.mainConcernMasterList[j].checked = true;
          }
        }
      }
    }  
    const dialogRef = this.dialog.open(DropdownModelpageComponent, {
      width: '500px',
      height: '400px',
      data:{
        title: "Main Concerns",
        main_array: this.mainConcernMasterList,
        selected_list:this.retrvMainConcern,
      } 
    });
    dialogRef.afterClosed().subscribe(result => {
        if(result != undefined){
        this.main_click(result);
        }
    });
  }
  get_app_retrivel() {
  }
  change_wgt_gain_value(value){
    this.red = !this.red;
    if(this.red == true){
      this.wgtGainFlag = true;
    }
    if(this.red == false){
      this.wgtGainFlag = false;
    }
  }
  chnageweightloss(value){
    this.red1 = !this.red1;
    if(this.red1 == true){
      this.wgtLossFlag = true;
    }
    if(this.red1 == false){
      this.wgtLossFlag = false;
    }
  }
  changechills(value){
    this.red2 = !this.red2;
    if(this.red2 == true){
      this.recentFlag = true;
    }
    if(this.red2 == false){
      this.recentFlag = false;
    }
  }
  changetired(value){
    this.red3 = !this.red3;
    if(this.red3 == true){
      this.freqFlag = true;
    }
    if(this.red3 == false){
      this.freqFlag = false;
    }
  }
  changemed(value){
    this.red4 = !this.red4;
    if(this.red4 == true){
      this.anyMedFlag = true;
      this.anyPresMedFlag=true;
    }
    if(this.red4 == false){
      this.anyMedFlag = false;
    }
  }
  HealthMedOpen() {
    if (this.otcFlag != false) {
      var otc_table_data = this.otcNonpresData;
    }
    if (this.vitaminsFlag != false) {
      var health_table_data = this.vitaminHerbalData;
    }
    if (this.reactFlag != false) {
      var medsupply_table_data = this.reactionMedsupplyData;
    }
    if (this.allergyFlag != false) {
      var allergy_table_data = this.allergyData;
    }
    var presImgPath = undefined;
    if (this.imgFilePath != null) {
      presImgPath = this.imgFilePath;
    }
    this.setHealthDataList = {
      recent_weight_gain: this.wgtGainFlag,
      recent_weight_loss: this.wgtLossFlag,
      tiredness_frequency: this.freqFlag,
      recent_fever_chills: this.recentFlag,
      prescribed_medicine: this.anyMedFlag,
      pres_med_doc: presImgPath, 
      "non-otc": otc_table_data,
      vitamins: health_table_data,
      med_reaction: medsupply_table_data,
      allergies: allergy_table_data,
    }
    this.gservice.set_second_health_data(this.setHealthDataList);
  }
  second_health_next() {
    if (this.exits == true){
    } else {
      var validation_flag = "";
      if (this.imgFilePath == null && this.anyMedFlag == true) {
      } else if (this.otcFlag == true && this.otcNonpresData.length == 0) {
      } else if (this.vitaminsFlag == true && this.vitaminHerbalData.length == 0) {
      } else if (this.reactFlag == true && this.reactionMedsupplyData.length == 0) {
      } else if (this.allergyFlag == true && this.allergyData.length == 0) {
      } else {
        this.HealthMedOpen();
        if (this.gservice.second_opinion_tab_flag != false) {
          if (this.exits == false) {
          } else {
          }
        }
      }
    }
  }
  Second_family_next() {
    if (this.saveUpdateFlag == false) {
      var sendFam =[];
      var diab = "no", heart = "no", stroke = "no", arterial = "no", endocrine = "no", autoimmune = "no", hyper = "no",
        hemo = "no", tuber = "no", hiv = "no", hepat = "no", glau = "no";
      if (this.familyHistDataList.diabetics == true)
        diab = "yes";
      if (this.familyHistDataList.heart_attack == true)
        heart = "yes";
      if (this.familyHistDataList.stroke == true)
        stroke = "yes";
      if (this.familyHistDataList.arterial == true)
        arterial = "yes";
      if (this.familyHistDataList.endocrine == true)
        endocrine = "yes";
      if (this.familyHistDataList.autoimmune == true)
        autoimmune = "yes";
      if (this.familyHistDataList.hypertension == true)
        hyper = "yes";
      if (this.familyHistDataList.hemophilia == true)
        hemo = "yes";
      if (this.familyHistDataList.tuberculosis == true)
        tuber = "yes";
      if (this.familyHistDataList.hiv == true)
        hiv = "yes";
      if (this.familyHistDataList.hepatitis == true)
        hepat = "yes";
      if (this.familyHistDataList.glaucoma == true)
        glau = "yes";
      sendFam.push({
          diabetics:this.toggDiab,
          heart_attack: this.toggHeartAttk,
          stroke: this.toggStorke,
          arterial:this.toggArter,
          endocrine:this.toggEndo,
          autoimmune: this.toggAuto,
          hypertension:this.toggHyper,
          tuberculosis:this.toggTuber,
          hemophilia:this.toggHemo,
          hiv: this.toggHiv,
          hepatitis:this.toggHepat,
          glaucoma:this.toggGla,
          others: this.familyOther,
      });
      this.gservice.set_second_family_data(sendFam);
    }
    else {
      this.gservice.set_second_family_data(undefined);
    }
  }

  savePres(pdffile) {
    if (this.imageFileStr != null) {
      var image_send_data = {
        client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
        docname: this.imageFileName,
        document: pdffile.split(',')[1],
        country: ipaddress.country_code,
        type:"pdf"
      }
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "secopn/uplfileweb/",
        JSON.stringify(image_send_data), { headers: headers }).subscribe(
          data => {
            if (data.json().key == "1" && data.json().file_path != null) {
              this.imgFilePath = data.json().file_path;
            }
          },
          error => {
          })
    } else {
    }
  }
   Get_excers_data() {
    this.exerciseList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'gen/exes/',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.exercises.length != 0) {
            for (var i = 0; i < obj.exercises.length; i++) {
              this.exerciseList.push({
                type: 'checkbox',
                value: obj.exercises[i].exercise_id,
                label: obj.exercises[i].description,
                checked: false
              });
            }
            this.get_yoga_type();
          }
          if (this.gservice.second_opinion_gen_excer != undefined && this.gservice.second_opinion_gen_excer.length != 0) {
            this.exercise_click(this.gservice.second_opinion_gen_excer)
          }
        },
        error => {
        }
      )
  }
  exercise_click(selectedValue) {
    this.exerciseTxt = "";
    function StringBuilder(this:any,value) {
      this.strings = new Array("");
      this.append(value);
    }
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }
    var selected_data = new StringBuilder("");
    this.retrvExerciseList = selectedValue;
    var n = 0;
    this.yogaFlag = false;
    for (var j = 0; j < this.retrvExerciseList.length; j++) {
      for (var i = 0; i < this.exerciseList.length; i++) {
        if (this.retrvExerciseList[j] == this.exerciseList[i].value) {
          n = n + 1;
          if (n > 1) {
            selected_data.append(",");
            selected_data.append("\n");
          }
          selected_data.append(this.exerciseList[i].label);
          if( this.exerciseList[i].label == "Yoga" ){
              this.yogaFlag = true;
          }
        }
      }
    }
    this.exerciseTxt = selected_data.toString();
  }
  Get_exercise_routine() {
    this.exerciseRoutineList;
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'gen/exesrot/',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.exerciseRoutineList = obj.exercise_routine;
          this.exerRoutine = this.exerciseRoutineList[0].exer_rout_id;
          this.calories_data();
        },
        error => {
        }
      )
  }

  selectExercise() {
    for (var j = 0; j < this.exerciseList.length; j++) {
      this.exerciseList[j].checked = false;
    }
    if (this.retrvExerciseList != undefined && this.retrvExerciseList.length != 0) {
      for (var i = 0; i < this.retrvExerciseList.length; i++) {
        for (var j = 0; j < this.exerciseList.length; j++) {
          if (this.retrvExerciseList[i] == this.exerciseList[j].value) {
            this.exerciseList[j].checked = true;
          }
        }
      }
    }
    const dialogRef = this.dialog.open(DropdownModelpageComponent, {
      width: '500px',
      height: '400px',
      data:{
        title: "Exercises",
        main_array: this.exerciseList,
        selected_list:this.retrvExerciseList,
      } 
    });
    dialogRef.afterClosed().subscribe(result => {
        if(result != undefined){
        this.exercise_click(result);
        }
    });
  }  
get_yoga_type() {
  var headers = new Headers();
  headers.append('Content-Type', 'application/json');
  this.http.get(ipaddress.getIp.toString() + 'gen/ysna',
    { headers: headers })
    .subscribe(
      response => {
        var obj = response.json();
        this.yogaList = obj.yogasanas;
      },
      error => {
        this.toastr.error(Message_data.defaultErr);
      }
    )
}
  yoga_click(valuecard, event) {
    if (event.selected == true) {
      this.sendYogaData.push(
        valuecard
      )
    } else {
      for (var i = 0; this.sendYogaData.length; i++) {
        if (this.sendYogaData[i] == valuecard) {
          this.sendYogaData.splice(i, 1);
          break;
        }
      }
    }
}
  changediab(e){
    this.toggDiabFlag  = e;
    if(e == false){
      this.toggDiab="no";
    }
    if(e == true){
      this.toggDiab="yes";
    }
  }
  changeheart(e){
    this.toggHeartAttkFlag  = e;
    if(e == false){
      this.toggHeartAttk="no";
    }
    else{
      this.toggHeartAttk="yes"
    }
  }
  changestroke(e){
    this.strokeFlag  = e;
    if(e == false){
      this.toggStorke="no";
    }
    else{
      this.toggStorke="yes"
    }
  }
  changearter(e){
    this.toggArterFlag  = e;
    if(e == false){
      this.toggArter="no";
    }
    else{
      this.toggArter="yes"
    }
  }
  changeendro(e){
    this.toggEndoFlag  = e;
    if(e == false){
      this.toggEndo="no";
    }
    else{
      this.toggEndo="yes"
    }
  }
  changeautoimmune(e){
    this.toggAutoFlag  = e;
    if(e == false){
      this.toggAuto="no";
    }
    else{
      this.toggAuto="yes"
    }
  }
  changehypertension(e){
    this.toggHyperFlag  = e;
    if(e == false){
      this.toggHyper="no";
    }
    else{
      this.toggHyper="yes"
    }
  }
  changehemophilia(e){
    this.toggHemoFlag  = e;
    if(e == false){
      this.toggHemo="no";
    }
    else{
      this.toggHemo="yes"
    }
  }
  changetuber(e){
    this.toggTuberFlag  = e;
    if(e == false){
      this.toggTuber="no";
    }
    else{
      this.toggTuber="yes"
    }
  }
  changehiv(e){
    this.toggHivFlag  = e;
    if(e == false){
      this.toggHiv="no";
    }
    else{
      this.toggHiv="yes"
    }
  }
  changehepatitis(e){
    this.toggHepatFlag  = e;
    if(e == false){
      this.toggHepat="no";
    }
    else{
      this.toggHepat="yes"
    }
  }
  changegla(e){
    this.toggGlaFlag  = e;
    if(e == false){
      this.toggGla="no";
    }
    else{
      this.toggGla="yes"
    }
  }
}
  