<mat-accordion class="mataccordion">
    <mat-expansion-panel  class="expandpanel"(opened)="panelOpenState10 = true"
    (closed)="panelOpenState10 = false">
       <mat-expansion-panel-header class="exppanel width">
          <mat-panel-title class="title ">
            <span class="widthappt"> 
            Gums & teeth</span>
            <!-- <mat-icon  class="iconsleft" *ngIf="!panelOpenState10">add</mat-icon>
            <mat-icon  class="iconsleft" *ngIf="panelOpenState10">remove</mat-icon>   -->
          </mat-panel-title>
       </mat-expansion-panel-header>            
        <div  [hidden]="gumsTab">
            <!-- <div class="casesheet_container neph_head sga" [hidden]="Tot_Causes_Div">
                <div class="casesheet_icon">
                <mat-icon  class="icon_images">account_circle</mat-icon>
                </div>
                <div class="casesheet_heading">
                <p class="casesheet_para">Gums</p>
                </div>
            </div> -->
            <p class="tervys_heading" [hidden]="Tot_Causes_Div">Gums</p>
            <div class="row">
                <div class=" col-12 col-md-6 col-lg-6 col-xl-3" [hidden]="Surface_Size_txt_Div">
                    <mat-label class="matlabel"  >Surface texture<br>
                        <textarea disabled="{{disabled}}" #matInput  id="myInput" class="ipcss" 
                        (change)="gum_teeth_change()"    [(ngModel)]="gum_sur_txt" maxlength="50" [disabled]="client"
                        ></textarea>
                    </mat-label> 
                </div>
                <div class=" col-12 col-md-6 col-lg-6 col-xl-3" [hidden]="Surface_Size_txt_Div">
                                         
                <mat-label class="matlabel" id="myInput"  >Size<br>
                    <textarea disabled="{{disabled}}" #matInput  class="ipcss" 
                    (change)="gum_teeth_change()" [(ngModel)]="gum_size" maxlength="50" [disabled]="client"
                    ></textarea>
                </mat-label> 
                </div>
                <div class=" col-12 col-md-6 col-lg-6 col-xl-3">
                    <mat-label class="matlabel">Position<br>
                        <textarea disabled="{{disabled}}" #matInput  id="myInput" class="ipcss" 
                        (change)="gum_teeth_change()" [(ngModel)]="gum_pos" maxlength="50" [disabled]="client"
                        ></textarea>
                    </mat-label> 
                </div>
                <div class=" col-12 col-md-6 col-lg-6 col-xl-3">
                    <mat-label class="matlabel">Periodontal pocket<br>
                        <textarea disabled="{{disabled}}" #matInput id="myInput" class="ipcss "  [disabled]="client"
                        (change)="gum_teeth_change()" [(ngModel)]="gum_perio_pock" maxlength="50"></textarea>
                    </mat-label> 
                
                </div>
                <div class=" col-12 col-md-6 col-lg-6 col-xl-3">
                    <mat-label class="matlabel">Exudates<br>
                        <textarea disabled="{{disabled}}" #matInput  id="myInput" class="ipcss " [disabled]="client"
                        (change)="gum_teeth_change()" [(ngModel)]="gum_exud" maxlength="50"></textarea>
                    </mat-label> 
                </div>
                <div class=" col-12 col-md-6 col-lg-6 col-xl-3">
                    <mat-label class="matlabel" id="myInput">Factor affecting colour<br>
                        <textarea disabled="{{disabled}}" #matInput  class="ipcss " [disabled]="client"
                        (change)="gum_teeth_change()" [(ngModel)]="fact_aff_col" maxlength="50"></textarea>
                    </mat-label> 
                </div>
                <div class=" col-12 col-md-6 col-lg-6 col-xl-3">
                    <mat-label class="matlabel">Consistency<br>
                        <textarea disabled="{{disabled}}" #matInput  id="myInput" class="ipcss " [disabled]="client"
                        (change)="gum_teeth_change()" [(ngModel)]="gum_cons" maxlength="50"></textarea>
                    </mat-label> 
                </div>
                <div class=" col-12 col-md-6 col-lg-6 col-xl-3">
                    
                <mat-label class="matlabel">Contour<br>
                    <textarea disabled="{{disabled}}" #matInput id="myInput" class="ipcss " [disabled]="client"
                    (change)="gum_teeth_change()" [(ngModel)]="gum_cont" maxlength="50"></textarea>
                </mat-label> 
                </div> 
                <div class=" col-12 col-md-6 col-lg-6 col-xl-3">
                    <mat-label class="matlabel" [hidden]="Gums_Others_txt">Other<br>
                        <textarea disabled="{{disabled}}" #matInput id="myInput" class="ipcss " [disabled]="client"
                        (change)="gum_teeth_change()" [(ngModel)]="gum_other" maxlength="50"></textarea>
                    </mat-label> 
                </div>
               
            </div>
            <div class="row">
                <div class="col-12">
                    <p class="casesheet_para">Teeth</p>
                </div>
                    
            </div>
            <div class="row">
            <div class=" col-12 col-md-6 col-lg-6 col-xl-3" [hidden]="Opencontact_Pathmig_Div">
                <mat-label class="matlabel"  [hidden]="Opencontact_Pathmig_Div">Open contacts<br>
                    <textarea disabled="{{disabled}}" #matInput  id="myInput" class="ipcss " 
                    [required]="gum_teeth_open_cont_req" [disabled]="client"
                    (change)="gum_teeth_change()" [(ngModel)]="teeth_open_cont" maxlength="50"></textarea>
                </mat-label> 
            </div>
            <div class=" col-12 col-md-6 col-lg-6 col-xl-3" [hidden]="Opencontact_Pathmig_Div">
                <mat-label class="matlabel"  [hidden]="Opencontact_Pathmig_Div">Pathologic migration<br>
                    <textarea disabled="{{disabled}}" #matInput  id="myInput" class="ipcss " 
                    [required]="gum_teeth_open_cont_req" [disabled]="client"
                    (change)="gum_teeth_change()" [(ngModel)]="teeth_path_mig" maxlength="50"></textarea>
                </mat-label>
            </div>
            <div class=" col-12 col-md-6 col-lg-6 col-xl-3" [hidden]="Prosthesis_txt_div">
                <mat-label class="matlabel"  >Prosthesis<br>
                    <textarea disabled="{{disabled}}" #matInput  id="myInput" class="ipcss " 
                    [required]="gum_teeth_open_cont_req" [disabled]="client"
                    (change)="gum_teeth_change()" [(ngModel)]="teeth_prost" maxlength="50"></textarea>
                </mat-label> 
            </div>
            <div class=" col-12 col-md-6 col-lg-6 col-xl-3" [hidden]="Fractured_txt_div">
                <mat-label class="matlabel" >Fractured teeth<br>
                    <textarea disabled="{{disabled}}" #matInput  id="myInput" class="ipcss " 
                    [required]="gum_teeth_open_cont_req" [disabled]="client"
                    (change)="gum_teeth_change()" [(ngModel)]="teeth_fract_teeth" maxlength="50"></textarea>
                </mat-label> 
            </div>
            <div class=" col-12 col-md-6 col-lg-6 col-xl-3" [hidden]="occ_div">
                <mat-label class="matlabel"  [hidden]="occ_div">Occlusion<br>
                    <textarea disabled="{{disabled}}" #matInput  id="myInput" class="ipcss " [disabled]="client" [required]="gum_teeth_sup_erupt_req"
                    (change)="gum_teeth_change()" [(ngModel)]="occ_txt" maxlength="50"></textarea>
                </mat-label> 
            </div>
            <div class=" col-12 col-md-6 col-lg-6 col-xl-3" [hidden]="Supraaerupted_txt_div">
                <mat-label class="matlabel"  >Supraerupted<br>
                    <textarea disabled="{{disabled}}" #matInput  id="myInput" class="ipcss " 
                    [required]="gum_teeth_open_cont_req" [disabled]="client"
                    (change)="gum_teeth_change()" [(ngModel)]="teeth_sup" maxlength="50"></textarea>
                </mat-label> 
            </div>
            <div class=" col-12 col-md-6 col-lg-6 col-xl-3" [hidden]="Reason_Loss_Txt_div">
                <mat-label class="matlabel" >Reason for loss of missing teeth<br>
                    <textarea disabled="{{disabled}}" #matInput  id="myInput" class="ipcss " 
                    required="gum_teeth_open_cont_req" [disabled]="client"
                    (change)="gum_teeth_change()" [(ngModel)]="teeth_reason_loss" maxlength="50"></textarea>
                </mat-label> 
            </div>
            <!-- <div class=" col-12 col-md-6 col-lg-6 col-xl-3">
                
            </div>
            <div class=" col-12 col-md-6 col-lg-6 col-xl-3">
                
            </div> -->
           
            <!-- <div class="casesheet_container neph_head sga" [hidden]="Tot_Causes_Div">
                <div class="casesheet_icon">
                <mat-icon  class="icon_images">account_circle</mat-icon>
                </div>
                <div class="casesheet_heading">
                <p class="casesheet_para">Teeth</p>
                </div>
            </div> -->
            
           
        </div>
         </div>
    </mat-expansion-panel>
</mat-accordion>

