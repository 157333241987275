<div class="row" style="width:100%;margin:0 auto;">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white ">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Outsource centre list</h5>
                    </div>
                    <div class="headerButtons">
                        <img *ngIf="this.newbutton" class="saveimgbtn_inpatinfo" (click)="createOutCentre()" src="../../../assets/ui_icons/buttons/new_button.svg" />
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>
                <div class="row">
                    <div class="col-12">
                      <p class="nodata" [hidden]='outsourceList.length!=0'>No Centre(s) found</p>
                      <table *ngIf="outsourceList.length" mdbTable datatable [dtOptions]="dtOptions"
                        class="table table-nowrap table-sm dataTable">
                        <thead>
                          <tr>
                            <th>Centre</th>
                            <th>Address1</th>
                            <th>Location </th>
                            <th>Zipcode </th>
                            <th>Contact no </th>
                            <th>Pay type </th>
                            <th>Pay value</th>
                            <th style="width: 5%;">&nbsp;</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let list of outsourceList">
                            <td style="text-align: left;">{{ list.centre_name}}</td>
                            <td> {{list.address1}}</td>
                            <td>{{list.location_desc}}</td>
                            <td>{{list.zipcode}}</td>
                            <td>{{list.telephone}}</td>
                            <td>{{list.paytype}}</td>
                            <td>{{list.amount}}</td>
                            <td style="font-size: 12px;width: 10%;">
                              <a *ngIf="this.editbutton" (click)="editOutSource(list)"> <img src="../../../assets/ui_icons/buttons/pencil_icon.svg" class="editicon" alt=""></a>
                              &nbsp;&nbsp;<a *ngIf="this.viewbutton" (click)="getTests(list)"> <img src="../../../assets/img/record_reports/Urine_test_icon_00.png" class="editicon" alt=""></a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>