<div class="Details container" id="Begin_content_others" style="padding: 0 15px 0 15px; max-width: 100%;">
    <div class="row">
        <div class="col-12 p-0">
            <mdb-card>
                <mdb-card-header class="bg-white">
                    <div class="headerCover">
                        <div class="headerTilte app_list_header">
                            <h5 class="m-0" class="mainHeadingStyle">User list</h5>
                        </div>
                        <div class="headerButtons">
                        </div>
                    </div>
                </mdb-card-header>
                <mdb-card-body>
                    <div class="row" style="margin-top: 15px;">
                        <div class="col-3" style="padding-left: 0;">
                            <select (change)="changeLocation(location)" class="ipcss" [(ngModel)]="location">
                                <option value="Select">Select</option>
                                <option *ngFor="let loc of physioLocations" [ngValue]="loc.view_data">{{loc.view_data}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div *ngIf="physioUserList.length == 0" class="nodata">No appointments(s) found</div>
                    <div class="row">
                        <div class="table-responsive dig_table_overflow" *ngIf="physioUserList.length != 0">
                            <table id="card_tbl" class="table table-hover table-dynamic">
                                <thead>
                                    <tr>
                                        <th>&nbsp;</th>
                                        <th>Name</th>
                                        <th>Gender</th>
                                        <th>Mobile</th>
                                        <th>Active</th>
                                        <th>&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let physiouser of physioUserList"
                                        (click)="viewPhysiotherapist(physiouser.users_id)">
                                        <td> <img src="{{physiouser.profile}}" class="userProfileImage" /></td>
                                        <td class="align_left">{{physiouser.name}}</td>
                                        <td>{{physiouser.gender}}</td>
                                        <td>{{physiouser.mobile}}</td>
                                        <td>{{physiouser.is_active}}</td>
                                        <td (click)="deleteUser(physiouser.users_id)"><img
                                                src="../../../assets/ui_icons/trash_icon.svg" width="20px"
                                                height="20px" /></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </mdb-card-body>
            </mdb-card>
        </div>
    </div>
</div>