<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Receivable report</h5>
          </div>
          <div class="headerButtons">
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="cover_div" style="padding-bottom: 13px;">
          <div class="header_lable">
            Receivable details
          </div>
          <div class="content_cover">
            <div class="row">
              <div class="col-12 col-sm-6 col-md-4 col-xl-3 col-lg-3">
                <mat-label class="matlabel">Type<br>
                  <select disableOptionCentering class="ipcss " required [(ngModel)]="type"
                    (change)="changeProductType()">
                    <option value="all" selected>All</option>
                    <option value="supplier">Supplier</option>
                    <option value="product">Product</option>
                  </select>
                </mat-label>
              </div>
              <!-- <div class="col-12 col-sm-6 col-md-4 col-xl-3 col-lg-3">
                <mat-label class="matlabel">Supplier<br>
                  <select disableOptionCentering class="ipcss " required [(ngModel)]="discountType"
                    (change)="discountChange(discountType)">
                    <option value="all">All</option>
                    <option value="supplier">Sun Smart</option>
                    <option value="product">Product</option>
                  </select>
                </mat-label>
              </div> -->
              <div class="col-12 col-sm-6 col-md-4 col-xl-3 col-lg-3" *ngIf="productFlag">
                <mat-label class="matlabel">Product name<br>  
                  <input type="text" class="ipcss order_width getMedcien" required maxlength="50"
                  (keyup)="changeMedicineName($event)" maxlength="50" [(ngModel)]="productName" matInput
                  [matAutocomplete]="auto2" />
                <mat-autocomplete #auto2="matAutocomplete" [panelWidth]="'auto'">
                  <mat-option (click)="selectMedicineItem(medicine)" *ngFor="let medicine of MedicneData"
                    value="{{medicine.name}}">
                    <div [ngStyle]="{'color' : medicine.quantity == '0' ? '#FFA07A' : '#000' }">{{medicine.name}}</div> 
                  </mat-option>
                </mat-autocomplete>
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-xl-3 col-lg-3" *ngIf="suplierFlag">
                <mat-label class="matlabel">Supplier name<br>  
                  <input type="text" class="ipcss order_width getMedcien" required maxlength="50"
                  (keyup)="changeSupplier($event)" maxlength="50" [(ngModel)]="supllierName" matInput
                  [matAutocomplete]="auto3" />
                <mat-autocomplete #auto3="matAutocomplete" [panelWidth]="'auto'">
                  <mat-option (click)="selectSupplierName(supplier)" *ngFor="let supplier of supplierArray"
                    value="{{supplier.name}}"> <div>{{supplier.name}}</div> 
                  </mat-option>
                </mat-autocomplete>
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                <mat-label class="matlabel" style="margin-top: 7px;">PO from
                  <input type="date" class="ipcss_date " (change)="dateSelect(fromDate,'1')" [(ngModel)]="fromDate"
                    max="{{currentDate}}" #matInput>
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                <mat-label class="matlabel" style="margin-top: 7px;">PO to
                  <input type="date" class="ipcss_date " (change)="dateSelect(toDate,'2')" [(ngModel)]="toDate"
                    max="{{currentDate}}" #matInput>
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                <mat-label class="matlabel"><br>
                  <img src="../../../assets/ui_icons/buttons/search_button.svg" class="saveimgbtn_inpatinfo"
                    (click)="getReceivablesData()" />
                </mat-label>
              </div>
            </div>
          </div>
        </div>
        <p class="nodata" *ngIf="receiveData.length ==0">No records found</p>
        <div class="row">
          <div class="col-12" style="margin-top: 10px;">
            <div class="table-responsive dig_table_overflow" *ngIf="receiveData.length !=0">
              <table id="tbl" class="table table-hover table-dynamic"
                style="font-size: 12px; width: 95%;margin-left: 5px;">
                <thead>
                  <tr>
                    <th>Supplier</th>
                    <th>Product name</th>
                    <th>PO no</th>
                    <th>PO date</th>
                    <th>PO quantity</th>
                    <th>Received qty</th>
                    <th>Pending Qty</th>
                    <th>Cost price</th>
                    <th>Sales price</th>
                    <th>MRP</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let receive of receiveData">
                    <td class="align_left">{{receive.supName}}</td>
                    <td class="align_left">{{receive.product_name}}</td>
                    <td>{{receive.po_code}}</td>
                    <td>{{receive.poDate}}</td>
                    <td>{{receive.po_qty}}</td>
                    <td>{{receive.qty_received}}</td>
                    <td>{{receive.balance}}</td>
                    <td>{{receive.costPrice}}</td>
                    <td>{{receive.salsePrice}}</td>
                    <td>{{receive.mrp}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>