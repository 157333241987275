import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { ClientViewService } from '../clientview/client-view.service';
import { Client_Helper } from '../Client_helper';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';
import { ServerApi } from 'src/app/server-api';
import {Helper_Class} from '../../helper_class';
@Component({
  selector: 'app-list-recepies',
  templateUrl: './list-recepies.component.html',
  styleUrls: ['./list-recepies.component.scss']
})
export class ListRecepiesComponent implements OnInit {
  public recepiesListArray = [];
  dtOptions: DataTables.Settings = {};

  constructor(public messageservice:ClientViewService,private http:Http,private routes:ActivatedRoute,private router:Router,public toastr:ToastrService,public serviceAPI: ServerApi) { }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 25,
      "lengthMenu": [[25, 50, -1], [25, 50, "All"]],
      "columnDefs": [
        { "orderable": false, "targets": [ 4 ] },
      ],
      "language": {
        "search": "<img src='../../../assets/ui_icons/Bill_list/Filter_Icon.svg' width='18px'/>",
        searchPlaceholder: "Filter by recipe name, total kcal or quantity"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12">><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"l><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    
    this.getList();
  }
  async getList(){
        var response = await this.serviceAPI.getData('lifestyle/gdrl').toPromise();
        if (response) { 
          var obj = JSON.parse(JSON.stringify(response));
          console.log("this.obj" + JSON.stringify(obj));
          this.recepiesListArray = obj.recipies;
        } 
  }
  create(){
    this.messageservice.sendMessage("createRecepies");
  }
  editPackage(recepiesList){
      var send = {
        recipe_id : recepiesList.id,
        flow : "edit",
        recepies : recepiesList
      }
      Helper_Class.setRecepiesEdit(null);
      Helper_Class.setRecepiesEdit(send);
      this.messageservice.sendMessage("createRecepies");
  }
}
