import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import { Http, Headers } from '@angular/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import {Helper_Class} from '../../helper_class';
import { ToastrService } from 'ngx-toastr';
import * as HighCharts from 'highcharts';
import { Date_Formate } from '../../../assets/js/common';
import { Chart } from 'chart.js';
import { Message_data } from '../../../assets/js/Message_data';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ipaddress } from '../../ipaddress';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Diet_Helper } from '../../Diet_module/Diet_Helper';

@Component({
  selector: 'app-diet-home',
  templateUrl: './diet-home.component.html',
  styleUrls: ['./diet-home.component.scss']
})
export class DietHomeComponent implements OnInit {
  public menu:boolean = false;
  public userinfo:any;
  public user_id:any;
  public first_name:string;
  public last_name:string;
  public opd=77;
  public opdwidth;
  public opdbk;
  public operations=50;
  public operationwidth;
  public operationbk;
  public patient_visit=23;
  public patientwidth;
  public patientbk;
  public diagnosisdetails:any = [];
  public docdetails:any = [];
  public hospital_details;
  public submenu_flag:boolean =false;
  public submenu_flag1:boolean =false;
  public profile_image:string;
  public datainfo:any = [];
  public dob:string;
  public obj:any;
  public mobile:any;
  public email:any;
  public gender:string;
  public urlt=ipaddress.getIp+"usercontroller/providerdetails";
  public memberdetails=null;
  public pres_address1;
  public pres_address2;
  public pres_city;
  public pres_loc_id;
  public pres_country;
  public pres_location_txt;
  public pres_state;
  public pres_telephone;
  public pres_zipcode;
  public perm_address1;
  public perm_address2;
  public perm_city;
  public perm_loc_id;
  public perm_country;
  public perm_location_txt;
  public perm_state;
  public perm_telephone;
  public perm_zipcode;
  public pres_city_list_data;
  public pres_city_select;
  public filt_city_desc;
  public degree_name:any = [];
  public d_name:any = [];
  public qualification:any = [];
  public pres_state_list_data;
  public filt_state_desc;
  public pres_country_list_data;
  public country_id;
  public perm_city_list_data;
  public perm_state_list_data;
  public perm_country_list_data;
  public address:boolean;
  public addr:any;
  public addr1:any;
  public specializations:any = [];
  public spl_name:any = [];
  public social_media:boolean=false;
  public twitter;
  public fbook;
  public instagram;
  public hospital_logo;
  public hospital_name;
  //chart
  public curr_date = null;
  public chart_type: string;
  // Date Picker
  public f_date;
  public t_date;
  public totalapp2: string;
  public totalapp3: string;
  public totalapp4: string;
  public totalapp1: string;
  public LocationCanvas: any;
  public specialization_canvas: any;
  public no_data: boolean;
  public no_grpah: boolean;
  public genmyChart: any;
  public AgeCanvas: any;
  public SplCanvas: any;
  public age_graph: boolean;
  public Appointment_typebase: boolean;
  public totalapp;
  public pendingapp;
  public newpatientapp;
  public total_earnings;
  public appointment_type;
  public tdate;
  public fdate;
  public totalapp_00: string;
  public totalapp_02: string;
  public totalapp_01: string;
  public loc_flag:boolean;
  public gender_flag:boolean;
  public age_flag:boolean;
  public spec_flag:boolean;
  dtOptions: DataTables.Settings = {};
  //  inpatient 
  public inpatientGrpah:any = [];
  public inpatientSpacility: any;
  public inSpacilityGraphData = [];
  public inWardGraphData = [];
  public wardSpacility: any;
  public inSplFlag:boolean = true;
  public inWardFlag:boolean = true;
  public inSpalDataArray = [];
  public inWardDataArray = [];
  public totalBeds;
  public availableBeds;
  public showDate:boolean = true;
  public type;
  public spl_display;
  public displayType;
  public followApp;
  constructor(private http:HttpClient,private routes:ActivatedRoute,private router:Router,public toastr:ToastrService) {
    this.appointment_type = "Today";
  }
  
  async ngOnInit(){
    this.userinfo = Helper_Class.getInfo();
    this.user_id = this.userinfo.user_id;
    this.hospital_details = Helper_Class.getHospitalInfo();
    this.hospital_name =this.hospital_details.hosp_name;
    this.hospital_logo = ipaddress.Ip_with_img_address + this.hospital_details.hptl_logo;
    
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 5,
      processing: true,
      order: [[1, 'desc']],
      "columnDefs": [
        { "orderable": false, "targets": [0] },
       ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px' style='display:inline-block;'/>",
        searchPlaceholder: "Search by name"
      },
      dom: '<"row"<"col-sm-12 col-xs-12"f>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center"p>>'
    };
    
    if(Diet_Helper.getDietFlow() != undefined && Diet_Helper.getDietFlow() == "dietician"){
      this.type = Diet_Helper.getDietFlow();
      this.spl_display = "none";
      this.get_curr_date(); 
      this.displayType = "Dashboard";
      
    }else{
      this.spl_display = "block";
      this.type = "doctor";
      this.displayType = "Out patient";
      if(this.userinfo.specializations != undefined){
        for (var j = 0; j < this.userinfo.specializations.length; j++) {
          this.specializations[j] = this.userinfo.specializations[j].spl_name;
        }
      }
      this.get_curr_date();   
      
    }
    
    var seconds = 5;
    window.addEventListener("keyup", disableF5);
    window.addEventListener("keydown", disableF5);
    function disableF5(e) {
        if ((e.which || e.keyCode) == 116) e.preventDefault(); 
    };
  }

  
  selectTab(event: MatTabChangeEvent){
    this.showDate = true;
    const tab = event.tab.textLabel;
    if(tab == "inpatient"){
      this.getInpatient();
      this.showDate = false;
    }
  }

  async get_curr_date() {
    var obj = Diet_Helper.getAppDate();

    if(obj != undefined){
      this.f_date = Date_Formate(obj);
      this.t_date = Date_Formate(obj);
      this.chart_type = "Pie";
      
      this.gender_base();
      this.age_base();
      this.speciality_chart();
      this.location_chart();
      this.apptypegraphView();
      this.get_earnings();
    }

  }

  getInpatient(){
    var clinicalid = Diet_Helper.getHospitalClinicalId();
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'inpat/inprep/',
      ({
        hptl_clinic_id: clinicalid,
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          
          this.inpatientGrpah  = obj;
          this.inSpacilityGraphData = this.inpatientGrpah.speciality_wise;
          this.inWardGraphData = this.inpatientGrpah.ward_wise;
          this.inpatientSpecialityChart();
          this.inpatientWard();
        },
        error => {}
      )
  }

  closeList(value){
    if(value == "Specialization" ){
      this.inSplFlag = true;
    }else if(value == "ward"){
      this.inWardFlag = true;
    }
    
  }

  inpatientSpecialityChart(){
    var name = Array();
    var count = Array();
    var dataArrayFinal = Array();
    if (this.inSpacilityGraphData != null) {
      for (var i = 0; i < this.inSpacilityGraphData.length; i++) {
        name[i] = this.inSpacilityGraphData[i].spl_name;
        count[i] = parseFloat(this.inSpacilityGraphData[i].inpat_count);
        var spec_value = this.inSpacilityGraphData[i].inpat_count;
      }
      for ( var j = 0; j < name.length; j++) {
        var temp = new Array(name[j], count[j]);
        dataArrayFinal[j] = count[j];
      }

      var jsonString = JSON.stringify(dataArrayFinal);
      var bgColor = ["#878BB6", "#FFEA88", "#FF8153", "#4ACAB4", "#c0504d", "#8064a2", "#772c2a", "#f2ab71", "#2ab881", "#4f81bd", "#2c4d75"];
       var ctx = <HTMLCanvasElement>document.getElementById("InpatientSpecializationCanvas");
        this.inpatientSpacility = new Chart(ctx, {
          type: 'doughnut',
          data: {
            labels: name,
            datasets: [
              {
                data: dataArrayFinal,
                backgroundColor: bgColor
              }]
          },
          maintainAspectRatio: true,
        });
    }else {
      this.toastr.error(Message_data.NoAppointments());
    }
  }

  inpatientWard(){
    var name = Array();
    var count = Array();
    var dataArrayFinal = Array();
    if (this.inWardGraphData != null && this.inWardGraphData != undefined) {
      for (var i = 0; i < this.inWardGraphData.length; i++) {
        name[i] = this.inWardGraphData[i].ward_name;
        count[i] = parseFloat(this.inWardGraphData[i].inpat_count);
        var spec_value = this.inWardGraphData[i].inpat_count
      }
      for ( var j = 0; j < name.length; j++) {
        var temp = new Array(name[j], count[j]);
        dataArrayFinal[j] = count[j];
      }

      var jsonString = JSON.stringify(dataArrayFinal);
      if(this.wardSpacility){
        this.wardSpacility.destroy();
      }

      var bgColor = ["#878BB6", "#FFEA88", "#FF8153", "#4ACAB4", "#c0504d", "#8064a2", "#772c2a", "#f2ab71", "#2ab881", "#4f81bd", "#2c4d75"];
        var ctx = <HTMLCanvasElement>document.getElementById("WardCanvas");
        this.wardSpacility = new Chart(ctx, {
          type: 'doughnut',
          data: {
            labels: name,
            datasets: [
              {
                data: dataArrayFinal,
                backgroundColor: bgColor
              }]
          }
        });
    }else {
      this.toastr.error(Message_data.NoAppointments());
    }
  }

  inSpalData(evt:any){
    var data = this.inpatientSpacility.getElementsAtEvent(evt);
   
    var activePoints = this.inpatientSpacility.getElementsAtEventForMode(evt, 'point', this.inpatientSpacility.options);
    var firstPoint = activePoints[0];
    var label = this.inpatientSpacility.data.labels[firstPoint._index];
    var value = this.inpatientSpacility.data.datasets[firstPoint._datasetIndex].data[firstPoint._index];
  
    this.inSpalDataArray = [];
    var inSplSenddata;
    for (var i = 0; i < this.inSpacilityGraphData.length; i++) { 
      if(this.inSpacilityGraphData[i].spl_name == label){
        inSplSenddata = {
          hptl_clinic_id : Diet_Helper.getHospitalClinicalId(),
          spl_id : this.inSpacilityGraphData[i].spl_id,
        }
        break;
      }
    }
    
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'inpat/inpsd/',
      (inSplSenddata),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
            var splList = obj.inpat_details;
            var name;
            for (var i = 0; i < splList.length; i++) {  
              if( splList[i].middle_name != undefined ){
                name = encrypt_decript.Decript(splList[i].first_name) + " " + encrypt_decript.Decript(splList[i].middle_name) + " " + encrypt_decript.Decript(splList[i].last_name);
              }else{
                if( splList[i].last_name != undefined ){
                  name = encrypt_decript.Decript(splList[i].first_name) + " " + encrypt_decript.Decript(splList[i].last_name);
                }else{
                  name = encrypt_decript.Decript(splList[i].first_name);
                }
              }
              this.inSpalDataArray.push({
                name : name,
                profile_image : ipaddress.getIp.toString() + splList[i].profile_image,
                bed_no :  splList[i].bed_no
              });
            }
            this.inSplFlag = false;
        },
        error => {});
  }

  inWardData(evt:any){
    var data = this.wardSpacility.getElementsAtEvent(evt);
   
    var activePoints = this.wardSpacility.getElementsAtEventForMode(evt, 'point', this.wardSpacility.options);
    var firstPoint = activePoints[0];
    var label = this.wardSpacility.data.labels[firstPoint._index];
    var value = this.wardSpacility.data.datasets[firstPoint._datasetIndex].data[firstPoint._index];
  
    this.inWardDataArray = [];
    var inSplSenddata;
    for (var i = 0; i < this.inWardGraphData.length; i++) { 
      if(this.inWardGraphData[i].ward_name == label){
        inSplSenddata = {
          hptl_clinic_id : Diet_Helper.getHospitalClinicalId(),
          ward_id : this.inWardGraphData[i].ward_id,
        }
        break;
      }
    }
    
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'inpat/inpwd/',
      (inSplSenddata),
      { headers: headers })
      .subscribe(
        response => {
            var obj = JSON.parse(JSON.stringify(response));
          
            var splList = obj.inpat_details;
            var name;
            this.totalBeds = obj.no_of_beds;
            this.availableBeds = obj.vacant_beds;
            for (var i = 0; i < splList.length; i++) {  
              if( splList[i].middle_name != undefined ){
                name = encrypt_decript.Decript(splList[i].first_name) + " " + encrypt_decript.Decript(splList[i].middle_name) + " " + encrypt_decript.Decript(splList[i].last_name);
              }else{
                if( splList[i].last_name != undefined ){
                  name = encrypt_decript.Decript(splList[i].first_name) + " " + encrypt_decript.Decript(splList[i].last_name);
                }else{
                  name = encrypt_decript.Decript(splList[i].first_name);
                }
              }
              this.inWardDataArray.push({
                name : name,
                profile_image : ipaddress.getIp.toString() + splList[i].profile_image,
                bed_no :  splList[i].bed_no
              });
            }
            this.inWardFlag = false;
        },
        error => {});
  }
 
  location_chart(){
    var name = Array();
    var count = Array();
    var dataArrayFinal = Array();
    var send_data:any;
    send_data = {
      reg_id: this.user_id,
      fdate: Date_Formate(this.f_date),
      tdate: Date_Formate(this.t_date),
    }

    if(this.type == "dietician")
      send_data.type = this.type;

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'dash/appconloc/',
      (send_data),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          
          if (obj.appointments != null) {
            this.loc_flag = true;
            for (var i = 0; i < obj.appointments.length; i++) {
              name[i] = obj.appointments[i].name;
              count[i] = parseFloat(obj.appointments[i].data);
            }

            var dashsearch_array:any = [];
            if (obj.appointments != null) {
              for (var i = 0; i < obj.appointments.length; i++) {
                dashsearch_array.push(obj.appointments[i].data);
              }
            }

            var Appointment_total = 0;
            for (var i = 0; i < dashsearch_array.length; i++) {
              if (Appointment_total != 0) {
                Appointment_total = Appointment_total + parseInt(dashsearch_array[i]);
              }else {
                Appointment_total = parseInt(dashsearch_array[i]);
              }
            }

            this.totalapp2 = "No of appointments :" + Appointment_total;
            this.totalapp3 = "No of appointments :" + Appointment_total;
            this.totalapp4 = "No of appointments :" + Appointment_total;

            var d = [{ "name": "Cardiology", "data": 1.0 }]
            for (var j = 0; j < name.length; j++) {
              var temp = new Array(name[j], count[j]);
              dataArrayFinal[j] = count[j];
            }

            HighCharts.setOptions({
              colors: ["#878BB6", "#FFEA88", "#FF8153", "#4ACAB4", "#c0504d", "#8064a2", "#772c2a", "#f2ab71", "#2ab881", "#4f81bd", "#2c4d75"]
        
            });
          // current Blood Glucose chart
          if(this.LocationCanvas){
            this.LocationCanvas.destroy();
          }

           var bgColor = ["#878BB6", "#FFEA88", "#FF8153", "#4ACAB4", "#c0504d", "#8064a2", "#772c2a", "#f2ab71", "#2ab881", "#4f81bd", "#2c4d75"];
                          var ctx = <HTMLCanvasElement>document.getElementById("LocationCanvas");
                          this.LocationCanvas = new Chart(ctx, {
                            type: 'doughnut',
                            data: {
                              labels: name,
                              datasets: [
                                {
                                  data: dataArrayFinal,
                                  backgroundColor: bgColor
                                }]
                            }
                          });
          }else{
            this.loc_flag = false;
          }
        },
        error => {});
  }

  age_chart(){
    HighCharts.setOptions({
      colors: ['rgb(8,96,0)', 'rgb(255,50,50)', 'rgb(0,47,135)', 'rgb(135, 0, 135)']

    });
                  // current Blood Glucose chart
    var aChart1 = new HighCharts.Chart({
                    chart: {
                      type: 'pie',
                      renderTo: "age",
                      options3d: {
                        enabled: true,
                        alpha: 0,
                        beta: 0,
                        depth: 20
                      }
                    },
                    exporting: {
                      enabled: false
                    },
                    title: {
                      text: ''
                    },
                    subtitle: {
                      text: ''
                    },
                    xAxis: {
                      type: 'category',
                    },
                    yAxis: {
                      title: {
                        text: ' '
                      },
                    },
                    legend: {
                      enabled: false
                    },
                    depth3D: 20, angle: 30,
                    plotOptions: {
                      pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: true,
                            format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                            style: {
                                color: (HighCharts.theme && HighCharts.theme.contrastTextColor) || 'black'
                            }
                        },
                        showInLegend: true
                      },
                      series: {
                        borderWidth: 0,
                        dataLabels: {
                          enabled: true,
                          format: '{point.y}'
                        },
                        column: {
                          depth: 20
                        }
                      }
                    },
                    tooltip: {
                      headerFormat: '<br>',
                      pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b> mg/dl<br/>'
                    },
                    series: [{
                      name: 'Brands',
                      colorByPoint: true,
                      data: [{
                          name: '<5',
                          y: 61.41
                      }, {
                          name: '5-10',
                          y: 11.84
                      }, {
                          name: '11-20',
                          y: 10.85
                      }, {
                          name: '21-30',
                          y: 4.67
                      }]
                  }]
                });
  }

  gender_chart(){
    HighCharts.setOptions({
      colors: ['rgb(8,96,0)', 'rgb(255,50,50)', 'rgb(0,47,135)', 'rgb(135, 0, 135)']

    });
                  // current Blood Glucose chart
    var aChart1 = new HighCharts.Chart({
                    chart: {
                      type: 'pie',
                      renderTo: "gender",
                      options3d: {
                        enabled: true,
                        alpha: 0,
                        beta: 0,
                        depth: 20
                      }
                    },
                    exporting: {
                      enabled: false
                    },
                    title: {
                      text: ''
                    },
                    subtitle: {
                      text: ''
                    },
                    xAxis: {
                      type: 'category',
                    },
                    yAxis: {
                      title: {
                        text: ' '
                      },
                    },
                    legend: {
                      enabled: false
                    },
                    depth3D: 20, angle: 30,
                    plotOptions: {
                      pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: true,
                            format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                            style: {
                                color: (HighCharts.theme && HighCharts.theme.contrastTextColor) || 'black'
                            }
                        },
                        showInLegend: true
                      },
                      series: {
                        borderWidth: 0,
                        dataLabels: {
                          enabled: true,
                          format: '{point.y}'
                        },
                        column: {
                          depth: 20
                        }
                      }
                    },
    
                    tooltip: {
                      headerFormat: '<br>',
                      pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b> mg/dl<br/>'
                    },
    
                    series: [{
                      name: 'Brands',
                      colorByPoint: true,
                      data: [{
                          name: 'male',
                          y: 61.41
                      }, {
                          name: 'female',
                          y: 11.84
                      }, {
                          name: 'transgender',
                          y: 10.85
                      }]
                  }]
                });
  }

  gender_base() {
    var name = Array();
    var count = Array();
    var dataArrayFinal = Array();
    var dataArrayFinal1 = new Array();
    var send_data:any;
    send_data = {
      reg_id: this.userinfo.user_id,
      fdate: Date_Formate(this.f_date),
      tdate: Date_Formate(this.t_date),
      type:"dietician",
    }

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'dash/appconbygen/',
      (send_data),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          
          if (obj.appointments != null) {
            this.gender_flag = true;
            for (var i = 0; i < obj.appointments.length; i++) {
              name[i] = obj.appointments[i].name;
              count[i] = parseFloat(obj.appointments[i].data);
            }

            if (obj.appointments.length == 0) {
              this.no_data = false;
              this.no_grpah = true;
            }else {
              this.no_data = true;
              this.no_grpah = false;
            }

            var d = [{ "name": "Cardiology", "data": 1.0 }]
            var dashsearch_array:any = [];
            if (obj.appointments != null) {
              for (var i = 0; i < obj.appointments.length; i++) {
                dashsearch_array.push(obj.appointments[i].data);
              }
            }

            var Appointment_total = 0;

            for (var i = 0; i < dashsearch_array.length; i++) {
              if (Appointment_total != 0) {
                Appointment_total = Appointment_total + parseInt(dashsearch_array[i]);
              }else {
                Appointment_total = parseInt(dashsearch_array[i]);
              }
            }

            this.totalapp2 = "No of appointment :" + Appointment_total;
            this.totalapp3 = "No of appointment :" + Appointment_total;
            this.totalapp4 = "No of appointment :" + Appointment_total;

            for (var j = 0; j < name.length; j++) {
              var temp = new Array(name[j], count[j]);
              dataArrayFinal[j] = count[j];
              dataArrayFinal1[j] = temp;
            }

            var jsonString = JSON.stringify(dataArrayFinal);
            if(this.genmyChart){
              this.genmyChart.destroy();
            }

            var bgColor = ["#878BB6", "#FFEA88", "#FF8153", "#4ACAB4", "#c0504d", "#8064a2", "#772c2a", "#f2ab71", "#2ab881", "#4f81bd", "#2c4d75"];
            var ctx = <HTMLCanvasElement>document.getElementById("piechart_gen");
            this.genmyChart = new Chart(ctx, {
              type: 'doughnut',
              data: {
                labels: name,
                datasets: [
                  {
                    data: dataArrayFinal,
                    backgroundColor: bgColor
                  }]
              }
            });
          }
          else {
            this.gender_flag = false;
          }
        },
        error => {});
  }

  age_base() {
    var name = Array();
    var count = Array();
    var dataArrayFinal = Array();
    var get_age_value = null;
    var send_data:any;
    send_data = {
      reg_id: this.userinfo.user_id,
      fdate: Date_Formate(this.f_date),
      tdate: Date_Formate(this.t_date),
      type:"dietician",
    }

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'dash/appconbyage/',
      (send_data),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
         
          if (obj.appointments != null) {
            this.age_flag = true;
            for (var i = 0; i < obj.appointments.length; i++) {
              name[i] = obj.appointments[i].name;
              count[i] = parseFloat(obj.appointments[i].data);
              var get_age_value = obj.appointments[i].data;
            }

            var dashsearch_array:any = [];
            for (var i = 0; i < obj.appointments.length; i++) {
              dashsearch_array.push(obj.appointments[i].data);
            }

            var Appointment_total = 0;
            for (var i = 0; i < dashsearch_array.length; i++) {
              if (Appointment_total != 0) {
                Appointment_total = Appointment_total + parseInt(dashsearch_array[i]);
              }else {
                Appointment_total = parseInt(dashsearch_array[i]);
              }
            }

            if (get_age_value != null) {
              this.totalapp1 = "No of appointments :" + get_age_value;
              this.age_graph = false;
            }else {
              this.totalapp1 = "No of appointments :" + get_age_value;
              this.age_graph = true;
            }

            this.totalapp2 = "No of appointments :" + Appointment_total;
            this.totalapp3 = "No of appointments :" + Appointment_total;
            this.totalapp4 = "No of appointments :" + Appointment_total;

            var d = [{ "name": "Cardiology", "data": 1.0 }]

            for (var j = 0; j < name.length; j++) {
              var temp = new Array(name[j], count[j]);
              dataArrayFinal[j] = count[j];
            }

            var jsonString = JSON.stringify(dataArrayFinal);

            if(this.AgeCanvas){
              this.AgeCanvas.destroy();
            }
            // AgeCanvas
            var bgColor = ["#878BB6", "#FFEA88", "#FF8153", "#4ACAB4", "#c0504d", "#8064a2", "#772c2a", "#f2ab71", "#2ab881", "#4f81bd", "#2c4d75"];
            var ctx = <HTMLCanvasElement>document.getElementById("AgeCanvas");
            this.AgeCanvas = new Chart(ctx, {
              type: 'doughnut',
              data: {
                labels: name,
                datasets: [
                  {
                    data: dataArrayFinal,
                    backgroundColor: bgColor
                  }]
              }
            });
          }else{
            this.age_flag = false;
          }
        },
        error => {});
  }

  get_earnings(){//future(not used yet)
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/gbam',
      ({
        doc_Reg_id: this.userinfo.user_id,
        from_date: Date_Formate(this.f_date),
        to_date: Date_Formate(this.t_date)
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
        });
  }

  apptypegraphView() {
    var name = Array();
    var pending_name = Array();
    var newpatient_name = Array();
    var count = Array();
    var pending_count = Array();
    var newpatient_count = Array();
    var dataArrayFinal = Array();
    this.totalapp = 0;
    this.pendingapp = 0;
    this.newpatientapp = 0;

    var send_data:any;
    send_data = {
      reg_id: this.userinfo.user_id,
      fdate: Date_Formate(this.f_date),
      country: ipaddress.country_code,
      type:"dietician",
    }

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'dash/appconbytypen',
      (send_data),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          if (obj.appointments != null) {
            if (obj.appointments.length == 0) {
              this.Appointment_typebase = true;
            }
            
            var totalpatient;
            for (var i = 0; i < obj.appointments.length; i++) {
              totalpatient = obj.appointments[i];
              if(totalpatient["total_apps"] != undefined){
                if (totalpatient.type == "1") {
                  name[i] = "New";
                } else if (totalpatient.type == "2") {
                  name[i] = "Follow-up";
                }
                count[i] = parseFloat(totalpatient.total_apps);             
               }

               //pending
               if(totalpatient["pending_apps"] != undefined){
                if (totalpatient.type == "1") {
                  pending_name.push("New");
                } 
                if (totalpatient.type == "2") {
                  pending_name.push("Follow-up");
                }
                pending_count.push(parseFloat(totalpatient.pending_apps));  
               }

               //new
               if(totalpatient["new_clients"] != undefined){
                if (totalpatient.type == "1") {
                  newpatient_name.push("New");
                } 
                if (totalpatient.type == "2") {
                  newpatient_name.push("Follow-up");
                }
                newpatient_count.push(parseFloat(totalpatient.new_clients));             
               }
            }

            var total = 0;
            var pending_total = 0;
            var new_total = 0;

            for (var j = 0; j < name.length; j++) {
              var temp = new Array(name[j], count[j]);
              dataArrayFinal[j] = temp;
              total = total + count[j];
            }
            this.totalapp = total;

            //pending
            dataArrayFinal = [];
            for (var j = 0; j < pending_name.length; j++) {
               var temp = new Array(pending_name[j], pending_count[j]);
              dataArrayFinal[j] = temp;
              pending_total = pending_total + pending_count[j];
            }
            this.pendingapp = pending_total;

           //new
           dataArrayFinal = [];
            for (var j = 0; j < newpatient_name.length; j++) {
              var temp = new Array(newpatient_name[j], newpatient_count[j]);
              dataArrayFinal[j] = temp;
              new_total = new_total + newpatient_count[j];
            }
            this.newpatientapp = new_total;   
            this.followApp =  this.totalapp - this.newpatientapp;      
          }
        });
  }

  getDateFormate(d){
    var month = ((d.getMonth() + 1) < 10 ? '0' : '') + (d.getMonth() + 1);
    var day = (d.getDate() < 10 ? '0' : '') + d.getDate();
    var temp = d.getFullYear() + "-" + month + "-" + day;
    return temp
  }
  
  Appointment_type_change(){
    var e;
    this.tdate = Date_Formate(this.curr_date);
    if(this.appointment_type =="Today"){
      e = this.curr_date;
    }else if(this.appointment_type == "Weekly"){
      var weekly,d = new Date();
      d.setDate(d.getDate() - 7);
      e = this.getDateFormate(d);
    }else if(this.appointment_type == "Fort Night"){
      var weekly,d =new Date();
      d.setDate(d.getDate() - 14);
      e = this.getDateFormate(d);
    }else if(this.appointment_type == "Monthly"){
      var weekly,d =new Date();
      d.setDate(d.getDate() - 30)
      e = this.getDateFormate(d);
    }else if(this.appointment_type == "3 Months"){
      var d =new Date();
      d.setDate(d.getDate() - 92)
      e = this.getDateFormate(d);
    }else if(this.appointment_type == "6 Months"){
      var weekly,d =new Date();
      d.setDate(d.getDate() - 183)
      e = this.getDateFormate(d);
    }else if(this.appointment_type == "Yearly"){
      var weekly,d =new Date();
      d.setDate(d.getDate() - 365)
      e = this.getDateFormate(d);
    }else{}
    this.f_date = Date_Formate(e);
    this.apptypegraphView();
    this.speciality_chart();
    this.location_chart();
    this.gender_base();
    this.age_base();
  //  this.age_chart();
  //  this.gender_chart();
  }

  speciality_chart(){
    var name = Array();
    var count = Array();
    var dataArrayFinal = Array();
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'dash/appconspl/',
      ({
        reg_id: this.userinfo.user_id,
        fdate: Date_Formate(this.f_date),
        tdate: Date_Formate(this.t_date),
        spl_name: this.spl_name,
        type:"dietician",
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          if (obj.appointments != undefined) {
            this.spec_flag = true;
            for (var i = 0; i < obj.appointments.length; i++) {
              name[i] = obj.appointments[i].name;
              count[i] = parseFloat(obj.appointments[i].data);
              var spec_value = obj.appointments[i].data
            }
            var d = [{ "name": "Cardiology", "data": 1.0 }]
            var Appoint_total = 0;
            for (var j = 0; j < count.length; j++) {
              Appoint_total = Appoint_total != 0 ? Appoint_total + count[j] : count[j];
            }

            this.totalapp_00 = "No of appointment is : " + Appoint_total;
            this.totalapp_01 = "No of appointment is : " + Appoint_total;
            this.totalapp_02 = "No of appointment is : " + Appoint_total;

            for (j = 0; j < name.length; j++) {
              var temp = new Array(name[j], count[j]);
              dataArrayFinal[j] = count[j];
            }

            var jsonString = JSON.stringify(dataArrayFinal);

            if(this.specialization_canvas){
              this.specialization_canvas.destroy();
            }

            var bgColor = ["#878BB6", "#FFEA88", "#FF8153", "#4ACAB4", "#c0504d", "#8064a2", "#772c2a", "#f2ab71", "#2ab881", "#4f81bd", "#2c4d75"];
              var ctx = <HTMLCanvasElement>document.getElementById("specialization_canvas");
              this.specialization_canvas = new Chart(ctx, {
                type: 'doughnut',
                data: {
                  labels: name,
                  datasets: [
                    {
                      data: dataArrayFinal,
                      backgroundColor: bgColor
                    }]
                }
              });
          }
          else {
            this.spec_flag = false;
          }
        },
        error => {});
  }
}
