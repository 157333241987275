
export class customjs {
  static SelectMenuFun(page,from){
     if(from == "doctor"){
       $(".doc_menu").removeClass( "menu_active" );
     }else{
       $(".menu").removeClass( "menu_active" );
     }
     $("#"+page).addClass( "menu_active" );
     $(".asied_section").removeClass( "show_asied_menu" );
     $(".hide_asied_menu").addClass( "show_asied_menu" );
  //    hide_asied_menu
    // $(".asied_section").hide();
 }
 static openMobileMenuFun(){
  //    alert("test");
     $(".asied_section").addClass( "show_asied_menu" );
     $(".hide_asied_menu").removeClass( "show_asied_menu" );
     $(".asied_section").show();
 }
}