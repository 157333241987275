<div class="row" style="width:100%;margin:0 auto;">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white ">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Package List</h5>
                    </div>
                    <div class="headerButtons">
                        <div *ngIf="createFlag"><a (click)="create()"><img src="../../../assets/ui_icons/buttons/new_button.svg"
                                    class="saveimgbtn_inpatinfo"></a></div>
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>
                <div class="row">
                    <div class="col-2" *ngFor="let package of packageListArray;">
                      <div class="card cardstyle" (click)="viewPackage(package)">
                        <div class="card-body" style="text-align: center;">
                          <div><img [src]="package.image" class="packimg"> </div>
                          <div>
                            <!-- <p class="card-text"></p> -->
                            <p class="card-text">{{package.package_type_desc}} - {{package.package_name}}</p>
                            <div style="display: flex;
                            align-items: center;
                            text-align: center;
                            justify-content: center;
                            margin: 2px !important;">
                            <div style="width: 50%;">
                              <p class="card-text" style="margin: 0px !important;">{{package.price}}</p>
                            </div>
                              <div style="margin: 2px;"> <img src="../../../assets/img/edit.png" class="billing_edit"
                                  style="" alt="" (click)="editPackage(package)">
                              </div>
                            </div>
                          </div>
                          <!-- <a href="#" class="btn btn-primary">Go somewhere</a> -->
                        </div>
                      </div>
                    </div>
                  </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>