import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from '../../helper_class';
import { Router } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ipaddress } from '../../ipaddress';
import { Message_data } from '../../../assets/js/Message_data';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { MatDialog } from '@angular/material/dialog';
import { PhysioService } from '../physio/physio.service';
import { Physio_Helper } from '../Physio_Helper';

@Component({
  selector: 'app-physio-bill-details',
  templateUrl: './physio-bill-details.component.html',
  styleUrls: ['./physio-bill-details.component.scss']
})
export class PhysioBillDetailsComponent implements OnInit {
  public billId !: string | null;
  public billingPrintView: any = [];
  public payFlag: boolean;
  public payType: string;
  public userInfo
  public cgstFlag;
  public sgstFlag;
  public billList:any = [];
  public billingFlag: boolean;
  public totalFlag: boolean;
  public advanceFlag: boolean;
  public prevBalance: string;
  public prevBalanceFlag: boolean;
  public mainBalanceFlag: boolean;
  public estimatePrintData;
  public treatmentPlanFlag: boolean;
  public charge = [];
  public hospAddressFlag: boolean;
  public hospAddress: string;
  public clientAddress: string;
  public billAmount: string;
  public totalAmount: string;
  public cgst: string;
  public sgst: string;
  public billDetailedViewData: any = [];
  public sendCLientName: string;
  public doctorName: string;
  public printFlag:boolean;
  dtOptions: DataTables.Settings = {};
  public paid;
  public roundoff;

  constructor(public dialog:MatDialog,public toastr: ToastrService, public http: Http, public router: Router,public messageservice:PhysioService) { 
    this.hospAddressFlag = true;
    this.billingFlag = true;
    this.treatmentPlanFlag = true;
    this.cgstFlag = true;
    this.sgstFlag = true;
    this.prevBalanceFlag = true;
  }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 5,
      "lengthMenu": [[5, 10, 25, 50, -1], [5, 10, 25, 50, "All"]],
      "columnDefs": [
        { "orderable": false, "targets": [0, 1, 2,] },
      ],
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12">><"table-responsive"t><"row mt-1 d-flex"<"col-xs-12 col-sm-6 col-md-4"l><"col-xs-12 col-sm-12 col-md-4 text-center sm-order-last"i><"col-xs-12 col-sm-6 col-md-4"p>>'
    };
    this.userInfo = Helper_Class.getInfo();
    this.printFlag = Helper_Class.getHospitalInfo().doc_bill_print == "1" ? true : false;
    this.billId = Physio_Helper.getBillId().bill_view_id;
    this.getBillDetailView();
  }

  getBillDetailView() {
    var headers = new Headers();
    var senddata = {
      reg_id: this.userInfo.user_id,
      billing_id: this.billId,
    }
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/getpbill/',
      JSON.stringify(senddata),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj != null) {
            if (obj.cgst != null) {
              this.cgstFlag = false;
              this.sgstFlag = false;
            }
            if (obj.bills != undefined) {
              this.billList = [];
              this.billList = obj.bills;
              if (this.billList != null && this.billList[0].charge_type != null) {
                this.billingFlag = false;
              } else {
                this.billingFlag = true;
              }
              for (var i = 0; i < this.billList.length; i++) {
                this.billingPrintView.push({
                  bill_type: this.billList[i].charge_desc,
                  amount_txt: parseFloat(this.billList[i].amount_txt).toFixed(2),
                  concession: this.billList[i].concession,
                  fee: parseFloat(this.billList[i].fee).toFixed(2),
                  charge_type: this.billList[i].charge_type,
                  pay_type: this.billList[i].pay_type,
                })
              }
            }
            if (obj.bill_amount == undefined && obj.bill_amount == "") {
              this.totalFlag = true;
            }
            if (obj.advance == undefined || obj.advance == "") {
              this.advanceFlag = true;
            }
            if (obj.prev_balance != null) {
              this.prevBalanceFlag = false;
              this.prevBalance = obj.prev_balance;
            } else {
              this.prevBalanceFlag = true;
            }
            if(obj.pay_type != null){
              this.payType =obj.pay_type;
            }
            if (obj.balance == undefined || obj.balance == "") {
              this.mainBalanceFlag = true;
            }
            this.estimatePrintData = obj.estimates;
            if (this.estimatePrintData != undefined) {
              this.treatmentPlanFlag = false;
            } else {
              this.treatmentPlanFlag = true;
            }
            this.charge = [];
            if (obj.hptl_name != null) {
              this.hospAddressFlag = false;
            } else {
              this.hospAddressFlag = true;
            }
            if (obj.created_date != undefined) {
              var datetxt;
              var get_date = obj.created_date.toString().split('-');
              datetxt = get_date[2] + "-" + get_date[1] + "-" + get_date[0];
            }
            if (obj.first_name != undefined) {
              if (obj.address2 != null && obj.address2 != "") {
                this.hospAddress = obj.address1 + ", " + obj.address2 + ",";
              } else {
                this.hospAddress = obj.address1 + ","
              }
              if(obj.caddress1 != undefined && obj.caddress1 != "null") {
                if(obj.caddress2 != "null" && obj.caddress2 != "" ) {
                  this.clientAddress = encrypt_decript.Decript(obj.caddress1).toString() + " " + encrypt_decript.Decript(obj.caddress2).toString() + ",";
                } else {
                  this.clientAddress = encrypt_decript.Decript(obj.caddress1).toString();
                }
              } else {
                this.clientAddress = "";
              }
              var tot_amt = "0";
              if (obj.bills != null && obj.bills.length != 0) {
                for (var i = 0; i < obj.bills.length; i++) {
                  if (obj.bills[i].fee != null) {
                    tot_amt = (parseInt(tot_amt) + parseFloat(obj.bills[i].fee)).toFixed(2);
                  }
                  if (obj.bills[i].pay_type != null) {
                    this.payType = obj.bills[i].pay_type;
                    this.payFlag = false;
                  }
                }
              }
              if (obj.estimates != null) {
                if (obj.estimates.length != 0) {
                  for (var i = 0; i < obj.estimates.length; i++) {
                    if (obj.estimates[i].estimate != null) {
                      tot_amt = (parseFloat(tot_amt) + parseFloat(obj.estimates[i].estimate)).toFixed(2);
                    }
                  }
                }
              }
              this.billAmount = tot_amt;
              this.totalAmount = tot_amt;
              if (obj.cgst != null) {
                this.cgst = (parseFloat(this.billAmount) * (parseFloat(obj.cgst) / 100)).toFixed(2);
                this.sgst = (parseFloat(this.billAmount) * (parseFloat(obj.sgst) / 100)).toFixed(2);
              }
              this.billAmount = encrypt_decript.Decript(obj.bill_amount);
              this.paid =  ((parseFloat(this.billAmount) - parseFloat(obj.round_off))  - parseFloat(obj.balance) - parseFloat(obj.advance)).toFixed(2);
              this.roundoff = parseFloat(obj.round_off).toFixed(2);
              if (obj.middle_name != null && obj.middle_name != undefined) {
                this.sendCLientName = encrypt_decript.Decript(obj.first_name).toString() + " " + encrypt_decript.Decript(obj.middle_name).toString() + " " + encrypt_decript.Decript(obj.last_name).toString();
              } else {
                this.sendCLientName = encrypt_decript.Decript(obj.first_name).toString() + " " + encrypt_decript.Decript(obj.last_name).toString();
              }
              if (obj.dr_middle_name == undefined) {
                this.doctorName = "Dr." + obj.dr_first_name + " " + obj.dr_last_name;
              } else {
                this.doctorName = "Dr." + obj.dr_first_name + " " + obj.dr_middle_name + " " + obj.dr_last_name;
              }
              this.billDetailedViewData.push({
                dr_name: "Dr." + obj.dr_first_name + " " + obj.dr_middle_name + " " + obj.dr_last_name,
                licence_code: " Reg. No: " + obj.licence_code,
                hptl_name: obj.hptl_name,
                address: this.hospAddress,
                location: obj.location + ", ",
                city: obj.city + " - " + obj.zipcode + ",",
                state: obj.state + " - " + obj.country + ",",
                telephone: obj.telephone,
                created_date: datetxt,
                appointment_id: obj.appointment_id,
                client_name: this.sendCLientName,
                advance: parseFloat(obj.advance).toFixed(2),
                balance: parseFloat(obj.balance).toFixed(2),
                caddress: this.clientAddress,
                pay_type:this.payType,
                clocation: obj.clocation + ", ",
                clientcity: obj.ccity + " - " + encrypt_decript.Decript(obj.czipcode) + ",",
                clestare: obj.cstate + " - " + obj.ccountry + ".",
                client_reg_id: obj.client_reg_id,
                tot_amt : this.billAmount,
              });
            }
          } else {
            this.toastr.error(Message_data.unableToGetBillDet);
          }
        },error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }

  print() {
    let printContents, popupWin;
    printContents = document.getElementById('bill-print-section')!.innerHTML;
    popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
    <head>
      <title>Bill</title>
      <link rel="stylesheet" media="screen" href="">
      <style>
      @page { size: auto;  margin: 0mm; }
      .table_class {
        background-color: rgb(108, 180, 248);
      }
      </style>
    </head>
        <body onload="window.print();window.onfocus=function(){ window.close();}">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
  }

  back() {
    this.messageservice.sendMessage("billing");
  }
}
