import { Component, OnInit, Input} from '@angular/core';
import { Helper_Class } from '../../helper_class';
import { ToastrService } from 'ngx-toastr';
import { Date_Formate } from '../../../assets/js/common.js';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FrontDeskService } from '../front-desk/front-desk.service';
import { ipaddress } from '../../ipaddress' ;import { Message_data } from 'src/assets/js/Message_data';
declare var $:any;
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
import { FrontDesk_Helper } from '../FrontDesk_Helper';

@Component({
  selector: 'app-inpatient-diacharge-list',
  templateUrl: './inpatient-diacharge-list.component.html',
  styleUrls: ['./inpatient-diacharge-list.component.scss']
})
export class InpatientDiachargeListComponent implements OnInit {

  @Input() max: any;
  public currentDate;
  public currentYear;
  public currentTime;
  public getDate;
  public hospitalId;
  public userId;
  public fromDate: string;
  public toDate: string;
  public dateFlag: boolean;
  public dischargeList = []; 
  dtOptions: DataTables.Settings = {};
  editbuttondie: boolean = false;
  newbuttondie: boolean = false;
  deletebuttondie:boolean = false;
  printbuttondie: boolean = false;
  viewbuttondie: boolean = false;

  constructor(public http:HttpClient,public toastr:ToastrService,public messageservice:MenuViewService) { }

  ngOnInit(): void {
    $(document).ready(function () {
      $("input").attr("autocomplete", "off");
    });
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 25,
      "lengthMenu": [[25, 50, -1], [25, 50, "All"]],
      order: [[2, 'desc']],
      "columnDefs": [
        { "orderable": false, "targets": [0] },
       ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search by name or with ward type"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    this.hospitalId = Helper_Class.getInfo().hptl_clinic_id;
    this.userId = Helper_Class.getInfo().user_id;
    this.getCurrentDate();

    if (FrontDesk_Helper.getmodules() != undefined) {
      for (var i = 0; i < FrontDesk_Helper.getmodules().length; i++) {
        console.log("dis list ----"+JSON.stringify(FrontDesk_Helper.getmodules()[i].module_id))
        if (FrontDesk_Helper.getmodules()[i].module_id == "3") {
          if (FrontDesk_Helper.getmodules()[i].edit == "1" ) {
            this.editbuttondie = true;
          }
          if (FrontDesk_Helper.getmodules()[i].create == "1"  ) {
            this.newbuttondie = true;
          }
          if (FrontDesk_Helper.getmodules()[i].delete == "1") {
            this.deletebuttondie = true;
          }
          if (FrontDesk_Helper.getmodules()[i].print == "1") {
            this.printbuttondie = true; 
          }
          if (FrontDesk_Helper.getmodules()[i].view == "1" ) {
            this.viewbuttondie = true;
          }
        }
      }
    }else{
      if(Helper_Class.getInfo().user_type == "Admin"){
        this.editbuttondie = true;
        this.deletebuttondie = true;
        this.newbuttondie = true;
        this.printbuttondie = true; 
        this.viewbuttondie = true;
      }
    }
  }

  getCurrentDate() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate', {country: ipaddress.country_code},
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          //City_change
          this.getDate = obj.current_date.split('-');
          this.currentYear = this.getDate[0];
          this.currentTime = obj.current_time;
          this.currentDate = obj.current_date;
          this.fromDate = this.currentDate;
          this.toDate = this.currentDate;
          this.getDischargeList();
        },error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }
  
  getDischargeList(){
    console.log("dis list ----"+JSON.stringify(this.fromDate)+this.toDate)

    var validate:boolean = true;
    if( this.fromDate == undefined && this.toDate == undefined ){
      validate = false;
    }
    this.dischargeList = [];
   
    if( validate == true){
      var sendData ={
        hptl_clinic_id: this.hospitalId,
        from_date : this.fromDate,
        to_date : this.toDate
      }
      console.log("dis sendData ----"+JSON.stringify(sendData))

      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'inpat/inpdd/', sendData,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse( JSON.stringify(response));
          console.log("dis sendData ----"+JSON.stringify(obj))

          var list = obj.discharge_clients;
          var name;
          for (var i = 0; i < list.length; i++) { 
            if( list[i].middle_name != undefined){
              name = encrypt_decript.Decript(list[i].first_name) + " " + encrypt_decript.Decript(list[i].middle_name) + " " + encrypt_decript.Decript(list[i].last_name);
            }else{
              if( list[i].last_name != undefined){
                name = encrypt_decript.Decript(list[i].first_name)  + " " + encrypt_decript.Decript(list[i].last_name);
              }else{
                name = encrypt_decript.Decript(list[i].first_name);
              }
            }
            var date = list[i].discharge_date != undefined ? Date_Formate(list[i].discharge_date) : "";
            this.dischargeList.push({
              name:name,
              profileImage : ipaddress.getIp.toString() + list[i].profile_image,
              ward_name:  list[i].ward_name,
              bed_no:  list[i].bed_no,
              discharge_date: date,
              inpatient_id:list[i].inpatient_id,
            });
          } 
        }, error => {});
    }
  }

  viewDischargeSummary(inpatient_id){
    if(this.viewbuttondie == true){

    FrontDesk_Helper.setInpatientId(inpatient_id)
    this.messageservice.sendMessage("inpdischargeview");
  }}
}