<div class="row" style="margin:0 auto; width:100%">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Diet Tracking </h5>
                    </div>
                    <div class="headerButtons">
                        <div style="width: 385px;">
                            <img class="saveimgbtn_inpatinfo" (click)="save()"  src="../../../assets/ui_icons/buttons/save_button.svg" />
                        </div>
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>
                <div class="row">
                    <!-- <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-2 col-xxl-2">
                        <mat-label class="matlabel">Region<br>
                            <select class="ipcss widthappt" (ngModelChange)="changeRegion($event)" [(ngModel)]="region">
                                <option *ngFor="let region of regionArray" [value]="region">{{region}} </option>
                            </select>
                        </mat-label>
                    </div> -->
                    <!-- <div class="col-12 col-sm-4 col-md-3 col-xl-2 col-lg-2">
                        <mat-label class="matlabel" style="font-size: 15px; font-weight: 600;">Range</mat-label><br>
                        <select disableOptionCentering class="ipcss" (change)="changeAppointmentType()"
                            [(ngModel)]="appointmentType">
                            <option value="Today">Today</option>
                            <option value="Weekly">Weekly</option>
                            <option value="Fort Night">Fort Night</option>
                            <option value="Monthly">Monthly</option>
                            <option value="3 Months">3 Months</option>
                            <option value="6 Months">6 Months</option>
                            <option value="Yearly">Yearly</option>
                            <option value="Date">Date</option>
                        </select>
                    </div> -->
                    <!-- <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2 select_bottom" *ngIf="dateFlag">
                        <mat-label class="matlabel">From<br>
                            <input type="date" class="ipcss widthbilllist datecss" (change)="selectFromDate(fromDate)"
                                [(ngModel)]="fromDate" #matInput max="{{currentMaxDate}}">
                        </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2" *ngIf="dateFlag">
                        <mat-label class="matlabel">To<br>
                            <input type="date" class="ipcss widthbilllist datecss" id="appt_date"
                                (change)="selectToDate(toDate)" [(ngModel)]="toDate" max="{{currentMaxDate}}" matInput>
                        </mat-label>
                    </div> -->
                    <div class="col-12 col-sm-4 col-md-3 col-xl-2 col-lg-2">
                        <mat-label class="matlabel" style="font-size: 15px; font-weight: 600;">View Type</mat-label><br>
                        <select disableOptionCentering class="ipcss"  [(ngModel)]="viewType">
                            <option value="date">Date</option>
                            <option value="session">Session</option>
                        </select>
                    </div>
                </div>
                <div class="billAreaCover">

                    <div class="contanier-flud p-0">
                        <img *ngIf="previousFlag" src="../../../assets/ui_icons/diet_plan/Previous_icon.svg"
                            (click)="copyDietPlan('previous')" style="left: 2px;" class="navIcon" alt="">
                        <img *ngIf="nextFlag" src="../../../assets/ui_icons/diet_plan/Next_icon.svg"
                            style="right: -2px;" class="navIcon" alt="" (click)="copyDietPlan('next')">
                        <div class="row cardCover">
                            <div class="col-2 " style="text-align:center; padding-right: 0; margin: 13px 0;border-right: 1px solid #81caf4;">
                                <div class="dateWarpper">
                                    <div *ngIf="viewType == 'date'">
                                        <div *ngFor="let date of datesArray;let i = index" class="dateCover">
                                            <div *ngIf="date.weekNo == week"
                                                [ngStyle]="date.checked == true ? {'width': '100%' } : {'width' : '90%'}">
                                                <div class="dateLable"
                                                    [ngStyle]="date.checked == true ? {'border': '1px solid #81caf4', 'border-right' : 'none'} : {}"
                                                    (click)="selectedSession(date,'date')">{{date.date}} <br>
                                                    ({{date.days}})</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="viewType == 'session'">
                                        <div *ngFor="let type of session;let i = index" class="dateCover">
                                            <div
                                                [ngStyle]="type.checked == true ? {'width': '100%' } : {'width' : '90%'}">
                                                <div class="dateLable" style=""
                                                    [ngStyle]="type.checked == true ? {'border': '1px solid #81caf4', 'border-right' : 'none'} : {}"
                                                    (click)="selectedSession(type,'session')">{{type.name}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-10" style="margin: 12px 0 14px 0px; border-top: 1px solid #81caf4; border-bottom: 1px solid #81caf4;">
                                <div class="dateWarpper">
                                    <div *ngIf="viewType == 'date'">
                                        <div *ngFor="let date of datesArray;let i = index">

                                            <div class="row" *ngIf="date.date == selectedDate">
                                                <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                                                    <div class="card readingCard">
                                                        <div class="card-header readignCardHeader">
                                                            Morning
                                                        </div>
                                                        <div class="card-body "
                                                            style="padding: 0px;margin: 4px 8px 0px;">
                                                            <div>
                                                                <mat-label class="matlabel">Add food item
                                                                    <br>
                                                                    <div>
                                                                        <input type="text" class="ipcss mainDishInput"
                                                                            [(ngModel)]="morningFoods"
                                                                            name="morningFoods"
                                                                            (keyup)="foodType(morningFoods,'Morning',date.date)"
                                                                            type="text" aria-label="Number" matInput
                                                                            [matAutocomplete]="autoMorning" />
                                                                        <mat-autocomplete
                                                                            #autoMorning="matAutocomplete">
                                                                            <mat-option id="optionfont"
                                                                                (click)="selctedFood('Morning',food,'1')"
                                                                                *ngFor="let food of morningFoodItem"
                                                                                [value]="food.food_item">
                                                                                {{food.food_item}} </mat-option>
                                                                        </mat-autocomplete>
                                                                    </div>
                                                                </mat-label>
                                                            </div>

                                                            <div class="foodItemCover">
                                                                <div *ngFor="let foodList of date.morning"
                                                                    style="margin-top: 7px; display: flex;">
                                                                    <div class="foodListCover" style="">
                                                                        <span style="">
                                                                            <img *ngIf="foodList.checked == true"
                                                                                src="../../../assets/ui_icons/popup_tick_mark.svg"
                                                                                style="cursor: pointer;margin: 0px 17%;"
                                                                                width="15px"
                                                                                (click)="checkedFood(foodList,'remove','Morning')" />
                                                                            <img *ngIf="foodList.checked == false"
                                                                                src="../../../assets/ui_icons/yellow_box.svg"
                                                                                style="cursor: pointer;margin: 0px 17%;"
                                                                                width="15px"
                                                                                (click)="checkedFood(foodList,'add','Morning')" />
                                                                        </span>
                                                                        <div class="foodLable"> {{ foodList.food_item }} 
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                                                    <div class="card readingCard">
                                                        <div class="card-header readignCardHeader">
                                                            Break-fast
                                                        </div>
                                                        <div class="card-body "
                                                            style="padding: 0px;margin: 4px 8px 0px;">
                                                            <div>
                                                                <mat-label class="matlabel">Add food item
                                                                    <br>
                                                                    <div>
                                                                        <input type="text" class="ipcss mainDishInput"
                                                                            [(ngModel)]="breakFastFoods"
                                                                            name="breakFastFoods"
                                                                            (keyup)="foodType(breakFastFoods,'Breakfast',date.date)"
                                                                            type="text" aria-label="Number" matInput
                                                                            [matAutocomplete]="autoBreakfast" />
                                                                        <mat-autocomplete
                                                                            #autoBreakfast="matAutocomplete">
                                                                            <mat-option id="optionfont"
                                                                                (click)="selctedFood('Breakfast',food,'1')"
                                                                                *ngFor="let food of breadfastFoodItem"
                                                                                [value]="food.food_item">
                                                                                {{food.food_item}} </mat-option>
                                                                        </mat-autocomplete>
                                                                    </div>
                                                                </mat-label>
                                                            </div>

                                                            <div class="foodItemCover">
                                                                <div *ngFor="let foodList of date.breakFast"
                                                                    style="margin-top: 7px; display: flex;">
                                                                    <div class="foodListCover" style="">
                                                                        <span style="">
                                                                            <img *ngIf="foodList.checked == true"
                                                                                src="../../../assets/ui_icons/popup_tick_mark.svg"
                                                                                style="cursor: pointer;margin: 0px 17%;"
                                                                                width="15px"
                                                                                (click)="checkedFood(foodList,'remove','Breakfast')" />
                                                                            <img *ngIf="foodList.checked == false"
                                                                                src="../../../assets/ui_icons/yellow_box.svg"
                                                                                style="cursor: pointer;margin: 0px 17%;"
                                                                                width="15px"
                                                                                (click)="checkedFood(foodList,'add','Breakfast')" />
                                                                        </span>
                                                                        <div class="foodLable"> {{ foodList.food_item }}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                                                    <div class="card readingCard">
                                                        <div class="card-header readignCardHeader">
                                                            Mid-day
                                                        </div>
                                                        <div class="card-body "
                                                            style="padding: 0px;margin: 4px 8px 0px;">
                                                            <div>
                                                                <mat-label class="matlabel">Add food item
                                                                    <br>
                                                                    <div>
                                                                        <input type="text" class="ipcss mainDishInput"
                                                                            [(ngModel)]="midDayFoods" name="Mid-day"
                                                                            (keyup)="foodType(midDayFoods,'Mid-day',date.date)"
                                                                            type="text" aria-label="Number" matInput
                                                                            [matAutocomplete]="autoMidDay" />
                                                                        <mat-autocomplete #autoMidDay="matAutocomplete">
                                                                            <mat-option id="optionfont"
                                                                                (click)="selctedFood('Mid-day',food,'1')"
                                                                                *ngFor="let food of midDayFoodItem"
                                                                                [value]="food.food_item">
                                                                                {{food.food_item}} </mat-option>
                                                                        </mat-autocomplete>
                                                                    </div>
                                                                </mat-label>
                                                            </div>

                                                            <div class="foodItemCover">
                                                                <div *ngFor="let foodList of date.midDay"
                                                                    style="margin-top: 7px; display: flex;">
                                                                    <div class="foodListCover" style="">
                                                                        <span style="">
                                                                            <img *ngIf="foodList.checked == true"
                                                                                src="../../../assets/ui_icons/popup_tick_mark.svg"
                                                                                style="cursor: pointer;margin: 0px 17%;"
                                                                                width="15px"
                                                                                (click)="checkedFood(foodList,'remove','Mid-day')" />
                                                                            <img *ngIf="foodList.checked == false"
                                                                                src="../../../assets/ui_icons/yellow_box.svg"
                                                                                style="cursor: pointer;margin: 0px 17%;"
                                                                                width="15px"
                                                                                (click)="checkedFood(foodList,'add','Mid-day')" />
                                                                        </span>
                                                                        <div class="foodLable"> {{ foodList.food_item }}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                                                    <div class="card readingCard">
                                                        <div class="card-header readignCardHeader">
                                                            Lunch
                                                        </div>
                                                        <div class="card-body "
                                                            style="padding: 0px;margin: 4px 8px 0px;">
                                                            <div>
                                                                <mat-label class="matlabel">Add food item
                                                                    <br>
                                                                    <div>
                                                                        <input type="text" class="ipcss mainDishInput"
                                                                            [(ngModel)]="lunchFoods" name="Lunch"
                                                                            (keyup)="foodType(lunchFoods,'Lunch',date.date)"
                                                                            type="text" aria-label="Number" matInput
                                                                            [matAutocomplete]="autoLunch" />
                                                                        <mat-autocomplete #autoLunch="matAutocomplete">
                                                                            <mat-option id="optionfont"
                                                                                (click)="selctedFood('Lunch',food,'1')"
                                                                                *ngFor="let food of lunchFoodItem"
                                                                                [value]="food.food_item">
                                                                                {{food.food_item}} </mat-option>
                                                                        </mat-autocomplete>
                                                                    </div>
                                                                </mat-label>
                                                            </div>

                                                            <div class="foodItemCover">
                                                                <div *ngFor="let foodList of date.lunch"
                                                                    style="margin-top: 7px; display: flex;">
                                                                    <div class="foodListCover" style="">
                                                                        <span style="">
                                                                            <img *ngIf="foodList.checked == true"
                                                                                src="../../../assets/ui_icons/popup_tick_mark.svg"
                                                                                style="cursor: pointer;margin: 0px 17%;"
                                                                                width="15px"
                                                                                (click)="checkedFood(foodList,'remove','Lunch')" />
                                                                            <img *ngIf="foodList.checked == false"
                                                                                src="../../../assets/ui_icons/yellow_box.svg"
                                                                                style="cursor: pointer;margin: 0px 17%;"
                                                                                width="15px"
                                                                                (click)="checkedFood(foodList,'add','Lunch')" />
                                                                        </span>
                                                                        <div class="foodLable"> {{ foodList.food_item }}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                                                    <div class="card readingCard">
                                                        <div class="card-header readignCardHeader">
                                                            Evening
                                                        </div>
                                                        <div class="card-body "
                                                            style="padding: 0px;margin: 4px 8px 0px;">
                                                            <div>
                                                                <mat-label class="matlabel">Add food item
                                                                    <br>
                                                                    <div>
                                                                        <input type="text" class="ipcss mainDishInput"
                                                                            [(ngModel)]="eveingFoods" name="Evening"
                                                                            (keyup)="foodType(eveingFoods,'Evening',date.date)"
                                                                            type="text" aria-label="Number" matInput
                                                                            [matAutocomplete]="autoEvening" />
                                                                        <mat-autocomplete
                                                                            #autoEvening="matAutocomplete">
                                                                            <mat-option id="optionfont"
                                                                                (click)="selctedFood('Evening',food,'1')"
                                                                                *ngFor="let food of eveningFoodItem"
                                                                                [value]="food.food_item">
                                                                                {{food.displayContent}} </mat-option>
                                                                        </mat-autocomplete>
                                                                    </div>
                                                                </mat-label>
                                                            </div>

                                                            <div class="foodItemCover">
                                                                <div *ngFor="let foodList of date.evening"
                                                                    style="margin-top: 7px; display: flex;">
                                                                    <div  class="foodListCover" style="">
                                                                        <span style="">
                                                                            <img *ngIf="foodList.checked == true"
                                                                                src="../../../assets/ui_icons/popup_tick_mark.svg"
                                                                                style="cursor: pointer;margin: 0px 17%;"
                                                                                width="15px"
                                                                                (click)="checkedFood(foodList,'remove','Evening')" />
                                                                            <img *ngIf="foodList.checked == false"
                                                                                src="../../../assets/ui_icons/yellow_box.svg"
                                                                                style="cursor: pointer;margin: 0px 17%;"
                                                                                width="15px"
                                                                                (click)="checkedFood(foodList,'add','Evening')" />
                                                                        </span>
                                                                        <div class="foodLable"> {{ foodList.food_item }}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                                                    <div class="card readingCard">
                                                        <div class="card-header readignCardHeader">
                                                            Dinner
                                                        </div>
                                                        <div class="card-body "
                                                            style="padding: 0px;margin: 4px 8px 0px;">
                                                            <div>
                                                                <mat-label class="matlabel">Add food item
                                                                    <br>
                                                                    <div>
                                                                        <input type="text" class="ipcss mainDishInput"
                                                                            [(ngModel)]="dinnerFoods" name="Dinner"
                                                                            (keyup)="foodType(dinnerFoods,'Dinner',date.date)"
                                                                            type="text" aria-label="Number" matInput
                                                                            [matAutocomplete]="autoDinner" />
                                                                        <mat-autocomplete #autoDinner="matAutocomplete">
                                                                            <mat-option id="optionfont"
                                                                                (click)="selctedFood('Dinner',food,'1')"
                                                                                *ngFor="let food of dinnerFoodItem"
                                                                                [value]="food.food_item">
                                                                                {{food.displayContent}} </mat-option>
                                                                        </mat-autocomplete>
                                                                    </div>
                                                                </mat-label>
                                                            </div>

                                                            <div class="foodItemCover">
                                                                <div *ngFor="let foodList of date.dinner"
                                                                    style="margin-top: 7px; display: flex;">
                                                                    <div  class="foodListCover" style="">
                                                                        <span style="">
                                                                            <img *ngIf="foodList.checked == true"
                                                                                src="../../../assets/ui_icons/popup_tick_mark.svg"
                                                                                style="cursor: pointer;margin: 0px 17%;"
                                                                                width="15px"
                                                                                (click)="checkedFood(foodList,'remove','Dinner')" />
                                                                            <img *ngIf="foodList.checked == false"
                                                                                src="../../../assets/ui_icons/yellow_box.svg"
                                                                                style="cursor: pointer;margin: 0px 17%;"
                                                                                width="15px"
                                                                                (click)="checkedFood(foodList,'add','Dinner')" />
                                                                        </span>
                                                                        <div class="foodLable"> {{ foodList.food_item }}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                                                    <div class="card readingCard">
                                                        <div class="card-header readignCardHeader">
                                                            Night
                                                        </div>
                                                        <div class="card-body "
                                                            style="padding: 0px;margin: 4px 8px 0px;">
                                                            <div>
                                                                <mat-label class="matlabel">Add food item
                                                                    <br>
                                                                    <div>
                                                                        <input type="text" class="ipcss mainDishInput"
                                                                            [(ngModel)]="nightFoods" name="Night"
                                                                            (keyup)="foodType(nightFoods,'Night',date.date)"
                                                                            type="text" aria-label="Number" matInput
                                                                            [matAutocomplete]="autoNight" />
                                                                        <mat-autocomplete #autoNight="matAutocomplete">
                                                                            <mat-option id="optionfont"
                                                                                (click)="selctedFood('Night',food,'1')"
                                                                                *ngFor="let food of nightFoodItem"
                                                                                [value]="food.food_item">
                                                                                {{food.displayContent}} </mat-option>
                                                                        </mat-autocomplete>
                                                                    </div>
                                                                </mat-label>
                                                            </div>

                                                            <div class="foodItemCover">
                                                                <div *ngFor="let foodList of date.night"
                                                                    style="margin-top: 7px; display: flex;">
                                                                    <div *ngIf="foodList.date == selectedDate"
                                                                        class="foodListCover" style="">
                                                                        <span style="">
                                                                            <img *ngIf="foodList.checked == true"
                                                                                src="../../../assets/ui_icons/popup_tick_mark.svg"
                                                                                style="cursor: pointer;margin: 0px 17%;"
                                                                                width="15px"
                                                                                (click)="checkedFood(foodList,'remove','Night')" />
                                                                            <img *ngIf="foodList.checked == false"
                                                                                src="../../../assets/ui_icons/yellow_box.svg"
                                                                                style="cursor: pointer;margin: 0px 17%;"
                                                                                width="15px"
                                                                                (click)="checkedFood(foodList,'add','Night')" />
                                                                        </span>
                                                                        <div class="foodLable"> {{ foodList.food_item }}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div *ngIf="viewType == 'session'">
                                        <div *ngIf="this.sessionType == 'Morning'">
                                            <div class="row">
                                                <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4" *ngFor="let date of datesArray;let i = index">
                                                    <div class="card readingCard">
                                                        <div class="card-header readignCardHeader">
                                                            {{date.days}} {{date.date}}
                                                        </div>
                                                        <div class="card-body" style="padding: 0px;margin: 4px 8px 0px;">
                                                            <div>
                                                                <mat-label class="matlabel">Add food item
                                                                    
                                                                    <div>
                                                                        <input type="text" class="ipcss mainDishInput" name="morningFoods"
                                                                            (keyup)="foodType($event,'Morning',date.date)"
                                                                            type="text" aria-label="Number" matInput
                                                                            [matAutocomplete]="auto" />
                                                                        <mat-autocomplete #auto="matAutocomplete">
                                                                            <mat-option id="optionfont"
                                                                                (click)="selctedFood('Morning',food,'1')"
                                                                                *ngFor="let food of morningFoodItem"
                                                                                [value]="food.food_item">
                                                                                {{food.displayContent}} </mat-option>
                                                                        </mat-autocomplete>
                                                                    </div>
                                                                </mat-label>
                                                            </div>
                                                            <div class="foodItemCover">
                                                                <div *ngFor="let foodList of date.morning" style="margin-top: 7px; display: flex;">
                                                                    <div class="foodListCover" style="">
                                                                        <span style="">
                                                                            <img *ngIf="foodList.checked == true"
                                                                                src="../../../assets/ui_icons/popup_tick_mark.svg"
                                                                                style="cursor: pointer;margin: 0px 17%;"
                                                                                width="15px"
                                                                                (click)="checkedFood(foodList,'remove','Morning')" />
                                                                            <img *ngIf="foodList.checked == false"
                                                                                src="../../../assets/ui_icons/yellow_box.svg"
                                                                                style="cursor: pointer;margin: 0px 17%;"
                                                                                width="15px"
                                                                                (click)="checkedFood(foodList,'add','Morning')" />
                                                                        </span>
                                                                        <div class="foodLable"> {{ foodList.food_item }} 
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="this.sessionType == 'Breakfast'">
                                            <div class="row">
                                                <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4" *ngFor="let date of datesArray;let i = index">
                                                    <div class="card readingCard">
                                                        <div class="card-header readignCardHeader">
                                                            {{date.days}} {{date.date}}
                                                        </div>
                                                        <div class="card-body" style="padding: 0px;margin: 4px 8px 0px;">
                                                            <div>
                                                                <mat-label class="matlabel">Add food item
                                                                    <br>
                                                                    <div>
                                                                        <input type="text" class="ipcss mainDishInput"
                                                                            [(ngModel)]="breakFastFoods"
                                                                            name="breakFastFoods"
                                                                            (keyup)="foodType($event,'Breakfast',date.date)"
                                                                            type="text" aria-label="Number" matInput
                                                                            [matAutocomplete]="autoBreakfast" />
                                                                        <mat-autocomplete
                                                                            #autoBreakfast="matAutocomplete">
                                                                            <mat-option id="optionfont"
                                                                                (click)="selctedFood('Breakfast',food,'1')"
                                                                                *ngFor="let food of breadfastFoodItem"
                                                                                [value]="food.food_item">
                                                                                {{food.displayContent}} </mat-option>
                                                                        </mat-autocomplete>
                                                                    </div>
                                                                </mat-label>
                                                            </div>

                                                            <div class="foodItemCover">
                                                                <div *ngFor="let foodList of date.breakFast"
                                                                    style="margin-top: 7px; display: flex;">
                                                                    <div class="foodListCover" style="">
                                                                        <span style="">
                                                                            <img *ngIf="foodList.checked == true"
                                                                                src="../../../assets/ui_icons/popup_tick_mark.svg"
                                                                                style="cursor: pointer;margin: 0px 17%;"
                                                                                width="15px"
                                                                                (click)="checkedFood(foodList,'remove','Breakfast')" />
                                                                            <img *ngIf="foodList.checked == false"
                                                                                src="../../../assets/ui_icons/yellow_box.svg"
                                                                                style="cursor: pointer;margin: 0px 17%;"
                                                                                width="15px"
                                                                                (click)="checkedFood(foodList,'add','Breakfast')" />
                                                                        </span>
                                                                        <div class="foodLable"> {{ foodList.food_item }} 
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="this.sessionType == 'Mid-day'">
                                            <div class="row">
                                                <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4" *ngFor="let date of datesArray;let i = index">
                                                    <div class="card readingCard">
                                                        <div class="card-header readignCardHeader">
                                                            {{date.days}} {{date.date}}
                                                        </div>
                                                        <div class="card-body" style="padding: 0px;margin: 4px 8px 0px;">
                                                            <div>
                                                                <mat-label class="matlabel">Add food item
                                                                    <br>
                                                                    <div>
                                                                        <input type="text" class="ipcss mainDishInput"
                                                                            [(ngModel)]="midDayFoods" name="Mid-day"
                                                                            (keyup)="foodType($event,'Mid-day',date.date)"
                                                                            type="text" aria-label="Number" matInput
                                                                            [matAutocomplete]="autoMidDay" />
                                                                        <mat-autocomplete #autoMidDay="matAutocomplete">
                                                                            <mat-option id="optionfont"
                                                                                (click)="selctedFood('Mid-day',food,'1')"
                                                                                *ngFor="let food of midDayFoodItem"
                                                                                [value]="food.food_item">
                                                                                {{food.displayContent}} </mat-option>
                                                                        </mat-autocomplete>
                                                                    </div>
                                                                </mat-label>
                                                            </div>

                                                            <div class="foodItemCover">
                                                                <div *ngFor="let foodList of date.midDay"
                                                                    style="margin-top: 7px; display: flex;">
                                                                    <div class="foodListCover" style="">
                                                                        <span style="">
                                                                            <img *ngIf="foodList.checked == true"
                                                                                src="../../../assets/ui_icons/popup_tick_mark.svg"
                                                                                style="cursor: pointer;margin: 0px 17%;"
                                                                                width="15px"
                                                                                (click)="checkedFood(foodList,'remove','Mid-day')" />
                                                                            <img *ngIf="foodList.checked == false"
                                                                                src="../../../assets/ui_icons/yellow_box.svg"
                                                                                style="cursor: pointer;margin: 0px 17%;"
                                                                                width="15px"
                                                                                (click)="checkedFood(foodList,'add','Mid-day')" />
                                                                        </span>
                                                                        <div class="foodLable"> {{ foodList.food_item }} 
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="this.sessionType == 'Lunch'">
                                            <div class="row">
                                                <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4" *ngFor="let date of datesArray;let i = index">
                                                    <div class="card readingCard">
                                                        <div class="card-header readignCardHeader">
                                                            {{date.days}} {{date.date}}
                                                        </div>
                                                        <div class="card-body" style="padding: 0px;margin: 4px 8px 0px;">
                                                            <div>
                                                                <mat-label class="matlabel">Add food item
                                                                    <br>
                                                                    <div>
                                                                        <input type="text" class="ipcss mainDishInput"
                                                                            [(ngModel)]="lunchFoods" name="Lunch"
                                                                            (keyup)="foodType($event,'Lunch',date.date)"
                                                                            type="text" aria-label="Number" matInput
                                                                            [matAutocomplete]="autoLunch" />
                                                                        <mat-autocomplete #autoLunch="matAutocomplete">
                                                                            <mat-option id="optionfont"
                                                                                (click)="selctedFood('Lunch',food,'1')"
                                                                                *ngFor="let food of lunchFoodItem"
                                                                                [value]="food.food_item">
                                                                                {{food.displayContent}} </mat-option>
                                                                        </mat-autocomplete>
                                                                    </div>
                                                                </mat-label>
                                                            </div>

                                                            <div class="foodItemCover">
                                                                <div *ngFor="let foodList of date.lunch"
                                                                    style="margin-top: 7px; display: flex;">
                                                                    <div class="foodListCover" style="">
                                                                        <span style="">
                                                                            <img *ngIf="foodList.checked == true"
                                                                                src="../../../assets/ui_icons/popup_tick_mark.svg"
                                                                                style="cursor: pointer;margin: 0px 17%;"
                                                                                width="15px"
                                                                                (click)="checkedFood(foodList,'remove','Lunch')" />
                                                                            <img *ngIf="foodList.checked == false"
                                                                                src="../../../assets/ui_icons/yellow_box.svg"
                                                                                style="cursor: pointer;margin: 0px 17%;"
                                                                                width="15px"
                                                                                (click)="checkedFood(foodList,'add','Lunch')" />
                                                                        </span>
                                                                        <div class="foodLable"> {{ foodList.food_item }}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="this.sessionType == 'Evening'">
                                            <div class="row">
                                                <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4" *ngFor="let date of datesArray;let i = index">
                                                    <div class="card readingCard">
                                                        <div class="card-header readignCardHeader">
                                                            {{date.days}} {{date.date}}
                                                        </div>
                                                        <div class="card-body" style="padding: 0px;margin: 4px 8px 0px;">
                                                            <div>
                                                                <mat-label class="matlabel">Add food item
                                                                    <br>
                                                                    <div>
                                                                        <input type="text" class="ipcss mainDishInput"
                                                                            [(ngModel)]="eveingFoods" name="Evening"
                                                                            (keyup)="foodType($event,'Evening',date.date)"
                                                                            type="text" aria-label="Number" matInput
                                                                            [matAutocomplete]="autoEvening" />
                                                                        <mat-autocomplete
                                                                            #autoEvening="matAutocomplete">
                                                                            <mat-option id="optionfont"
                                                                                (click)="selctedFood('Evening',food,'1')"
                                                                                *ngFor="let food of eveningFoodItem"
                                                                                [value]="food.food_item">
                                                                                {{food.displayContent}} </mat-option>
                                                                        </mat-autocomplete>
                                                                    </div>
                                                                </mat-label>
                                                            </div>

                                                            <div class="foodItemCover">
                                                                <div *ngFor="let foodList of date.evening"
                                                                    style="margin-top: 7px; display: flex;">
                                                                    <div  class="foodListCover" style="">
                                                                        <span style="">
                                                                            <img *ngIf="foodList.checked == true"
                                                                                src="../../../assets/ui_icons/popup_tick_mark.svg"
                                                                                style="cursor: pointer;margin: 0px 17%;"
                                                                                width="15px"
                                                                                (click)="checkedFood(foodList,'remove','Evening')" />
                                                                            <img *ngIf="foodList.checked == false"
                                                                                src="../../../assets/ui_icons/yellow_box.svg"
                                                                                style="cursor: pointer;margin: 0px 17%;"
                                                                                width="15px"
                                                                                (click)="checkedFood(foodList,'add','Evening')" />
                                                                        </span>
                                                                        <div class="foodLable"> {{ foodList.food_item}}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="this.sessionType == 'Dinner'">
                                            <div class="row">
                                                <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4" *ngFor="let date of datesArray;let i = index">
                                                    <div class="card readingCard">
                                                        <div class="card-header readignCardHeader">
                                                            {{date.days}} {{date.date}}
                                                        </div>
                                                        <div class="card-body" style="padding: 0px;margin: 4px 8px 0px;">
                                                            <div>
                                                                <mat-label class="matlabel">Add food item
                                                                    <br>
                                                                    <div>
                                                                        <input type="text" class="ipcss mainDishInput"
                                                                            [(ngModel)]="dinnerFoods" name="Dinner"
                                                                            (keyup)="foodType($event,'Dinner',date.date)"
                                                                            type="text" aria-label="Number" matInput
                                                                            [matAutocomplete]="autoDinner" />
                                                                        <mat-autocomplete #autoDinner="matAutocomplete">
                                                                            <mat-option id="optionfont"
                                                                                (click)="selctedFood('Dinner',food,'1')"
                                                                                *ngFor="let food of dinnerFoodItem"
                                                                                [value]="food.food_item">
                                                                                {{food.displayContent}} </mat-option>
                                                                        </mat-autocomplete>
                                                                    </div>
                                                                </mat-label>
                                                            </div>

                                                            <div class="foodItemCover">
                                                                <div *ngFor="let foodList of date.dinner"
                                                                    style="margin-top: 7px; display: flex;">
                                                                    <div   class="foodListCover" style="">
                                                                        <span style="">
                                                                            <img *ngIf="foodList.checked == true"
                                                                                src="../../../assets/ui_icons/popup_tick_mark.svg"
                                                                                style="cursor: pointer;margin: 0px 17%;"
                                                                                width="15px"
                                                                                (click)="checkedFood(foodList,'remove','Dinner')" />
                                                                            <img *ngIf="foodList.checked == false"
                                                                                src="../../../assets/ui_icons/yellow_box.svg"
                                                                                style="cursor: pointer;margin: 0px 17%;"
                                                                                width="15px"
                                                                                (click)="checkedFood(foodList,'add','Dinner')" />
                                                                        </span>
                                                                        <div class="foodLable"> {{ foodList.food_item  }} 
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="this.sessionType == 'Night'">
                                            <div class="row">
                                                <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4" *ngFor="let date of datesArray;let i = index">
                                                    <div class="card readingCard">
                                                        <div class="card-header readignCardHeader">
                                                            {{date.days}} {{date.date}}
                                                        </div>
                                                        <div class="card-body" style="padding: 0px;margin: 4px 8px 0px;">
                                                            <div>
                                                                <mat-label class="matlabel">Add food item
                                                                    <br>
                                                                    <div>
                                                                        <input type="text" class="ipcss mainDishInput"
                                                                            [(ngModel)]="nightFoods" name="Night"
                                                                            (keyup)="foodType($event,'Night',date.date)"
                                                                            type="text" aria-label="Number" matInput
                                                                            [matAutocomplete]="autoNight" />
                                                                        <mat-autocomplete #autoNight="matAutocomplete">
                                                                            <mat-option id="optionfont"
                                                                                (click)="selctedFood('Night',food,'1')"
                                                                                *ngFor="let food of nightFoodItem"
                                                                                [value]="food.food_item">
                                                                                {{food.displayContent}} </mat-option>
                                                                        </mat-autocomplete>
                                                                    </div>
                                                                </mat-label>
                                                            </div>

                                                            <div class="foodItemCover">
                                                                <div *ngFor="let foodList of date.night"
                                                                    style="margin-top: 7px; display: flex;">
                                                                    <div class="foodListCover" style="">
                                                                        <span style="">
                                                                            <img *ngIf="foodList.checked == true"
                                                                                src="../../../assets/ui_icons/popup_tick_mark.svg"
                                                                                style="cursor: pointer;margin: 0px 17%;"
                                                                                width="15px"
                                                                                (click)="checkedFood(foodList,'remove','Night')" />
                                                                            <img *ngIf="foodList.checked == false"
                                                                                src="../../../assets/ui_icons/yellow_box.svg"
                                                                                style="cursor: pointer;margin: 0px 17%;"
                                                                                width="15px"
                                                                                (click)="checkedFood(foodList,'add','Night')" />
                                                                        </span>
                                                                        <div class="foodLable"> {{ foodList.food_item }}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>