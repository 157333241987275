import { Component, OnInit } from '@angular/core';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
import { Helper_Class } from '../../../helper_class';
import { Http, Headers } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ipaddress } from '../../../ipaddress';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-assets-list',
  templateUrl: './assets-list.component.html',
  styleUrls: ['./assets-list.component.scss']
})
export class AssetsListComponent implements OnInit {
  public userType;
  public hsp_id;
  public assetsdetaillistarray=[];

  constructor(public frontdeskservice:MenuViewService,public http: HttpClient, public httpp: Http, public toastr: ToastrService) { }

  ngOnInit(): void {
    this.userType = Helper_Class.getInfo().user_type;
    this.hsp_id = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
    var retirvesend_data;
    if (this.userType == "pharmacy") {
      retirvesend_data = {
        center_id: this.hsp_id,
        center_type: this.userType
      }
      this.retrive_assetslistdata(retirvesend_data)
    } else if (this.userType == "diagnosis") {
      retirvesend_data = {
        center_id: this.hsp_id,
        center_type: this.userType
      }
      this.retrive_assetslistdata(retirvesend_data)
    } else {
      retirvesend_data = {
        center_id: this.hsp_id,
        center_type: "doctor"
      }
      this.userType = "doctor";
      this.retrive_assetslistdata(retirvesend_data)
    }
  }
  backtoassetslist(){
    this.frontdeskservice.sendMessage("pharma_assets");
  }

  retrive_assetslistdata(retirvesend_data) {
    console.log("SEND DATA ========"+ JSON.stringify(retirvesend_data))
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'inv/gahsa', retirvesend_data,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          var assetslist = obj.asset_details
           console.log(JSON.stringify(obj))
           for (var i = 0; i < assetslist.length; i++) {
              this.assetsdetaillistarray.push({
              sn:i+1,   
              assetid:assetslist[i].asset_code,
              hspassetid:assetslist[i].hosp_assets_id,
              assetName:assetslist[i].asset_name,
              acq_date:assetslist[i].acquisition_date,
              ast_categry_id:assetslist[i].asset_category,
              asset_category_desc:assetslist[i].asset_category_desc,
              suppid:assetslist[i].supplier_id,
              supname:assetslist[i].supplier_name
            })
            console.log("list array = "+JSON.stringify(this.assetsdetaillistarray))
           }
        }, error => {
          console.log("error in generic data")
        }
      )
  }

  retrive_data(data){
    var astid=data.assetid;
    console.log("ast id ="+astid);
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'inv/gahid',JSON.stringify({asset_id:astid}) ,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
           var assetslist = obj;
           Helper_Class.setassetslistdata(obj);
          var flagvalue={value:"true"}
          Helper_Class.setassetslistdatachangepage(flagvalue);
          this.frontdeskservice.sendMessage("pharma_assets");

        }, error => {
          console.log("retrive data error")
        }
      )
  }
}
