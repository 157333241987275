<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Manage appointments</h5>
          </div>
          <div class="headerButtons">
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row">
          <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 select_bottom">
            <mat-label class="matlabel">Doctor<br>
              <mat-select class="ipcss widthappt" [(ngModel)]="doctor">
                <mat-option *ngFor="let doctors of doctorList" value={{doctors.Doc_ID}}
                  (click)="selectReferredDoctor()">{{doctors.Doc_Name}}
                </mat-option>
              </mat-select>
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 select_bottom">
            <mat-label class="matlabel">Date<br>
              <input type="date" class="ipcss_date" id="appt_date" (change)="selectAppointmentDate(appointmentDate)"
                [(ngModel)]="appointmentDate" max="{{currentMaxDate}}" #matInput style="width: 140px;">
            </mat-label>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <p class="nodata" *ngIf="appointmentList.length == 0">No Appointments(s) Found</p>
            <table *ngIf="appointmentList.length" mdbTable datatable [dtOptions]="dtOptions"
              class="table table-nowrap table-sm dataTable billlisttable">
              <thead>
                <tr>
                  <th>Profile image</th>
                  <th>Patient id</th>
                  <th>Name</th>
                  <th>Token/Time</th>
                  <th>Contact no</th>
                  <th>Session</th>
                  <th> Status </th>
                  <!-- <th style="width: 5%;"><mdb-checkbox [default]="true" [(ngModel)]="masterSelected" name="list_name" value="m1" (change)="checkUncheckAll()"></mdb-checkbox></th> -->
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let person of appointmentList; let i = index"
                  (click)="viewAppt(person,person.Docappid,person.session,appointmentDate)">
                  <td><img src="{{person.Pro_Image}}" onerror="this.src='../../../assets/img/default.jpg';"
                      class="img-fluid z-depth-1 rounded-circle mr-1"></td>
                  <td style="font-size: 12px;">{{ person.pat_id }}</td>
                  <td style="font-size: 12px;text-align: left;">{{ person.Display }}</td>
                  <td style="font-size: 12px;">{{ person.token }}</td>
                  <td style="font-size: 12px;">{{ person.contact }}</td>
                  <td style="font-size: 12px;">{{ person.session }}</td>
                  <td style="font-size: 12px;">
                    <div style="position: relative;top: 9px;">
                      <img *ngIf="person.status_txt == 'Completed'" width="30px" height="auto"
                        src="../../../assets/ui_icons/Completed_icon.svg" />
                      <img *ngIf="person.status_txt == 'Cancel'" width="30px" height="auto"
                        src="../../../assets/ui_icons/cancel_icon.svg" />
                      <img *ngIf="person.status_txt == 'Not visited'" width="30px" height="auto"
                        src="../../../assets/ui_icons/not_visited_icon.svg" />
                      <img *ngIf="person.status_txt == 'Confirmed'" width="30px" height="auto"
                        src="../../../assets/ui_icons/confirmed_icon.svg" />
                      <img *ngIf="person.status_txt == 'Open'" width="30px" height="32px"
                        src="../../../assets/ui_icons/opened_icon.svg" />
                      <p>{{person.status_txt}}</p>
                    </div>
                </tr>
              </tbody>
            </table>
            <br><br><br>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>