<div class="row" style="width:100%;margin:0 auto;">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white ">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Advance bill List</h5>
                    </div>
                    <div  class="headerButtons">
                        <a><img src="../../../assets/ui_icons/buttons/new_button.svg" *ngIf="newbuttondie" class="saveimgbtn_inpatinfo" (click)="advCreate()"></a>
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>
                <div class="cover_div" style="padding-bottom: 13px;">
                  <div class="header_lable">
                      Advance details
                  </div>
                  <div class="content_cover">
                    <div class="row">
                      <div class="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-2">
                        <mat-label class="matlabel">Filter by<br>
                            <select class="ipcss widthch1" [(ngModel)]="advanceCategory" required
                                (change)="selectAdvanceCategory()">
                                <option value="All"selected>Name</option>
                                <option value="Date">Date</option>
                            </select>
                        </mat-label>
                      </div>
                      <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 select_bottom" [hidden]="!advanceCategoryflag">
                          <mat-label class="matlabel">First name<br>
                              <input matInput class="ipcss widthbilllist" type="text" required
                                  [(ngModel)]="clntName" style="margin-bottom: 10px;" (keyup)=clientFilter()
                                  (blur)="fnameToUpper()" />
                              <div class="auto_complete_text" *ngIf="clientList.length != 0">
                                  <ul *ngFor="let item of clientList">
                                      <li>
                                          <a (click)="selectClient(item.prov_id)">{{item.firstName}}&nbsp;{{item.lastName}}</a>
                                      </li>
                                  </ul>
                              </div>
                          </mat-label>
                      </div>
                      <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 select_bottom" [hidden]="!advanceCategoryflag">
                          <mat-label class="matlabel">Middle name<br>
                              <input matInput class="ipcss widthbilllist" type="text" [(ngModel)]="middleName"
                                  style="margin-bottom: 10px;" (blur)="mnameToUpper()" />
                          </mat-label>
                      </div>
                      <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 select_bottom" [hidden]="!advanceCategoryflag">
                          <mat-label class="matlabel">Last name <br>
                              <input matInput class="ipcss widthbilllist" type="text" required
                                  [(ngModel)]="lastName" style="margin-bottom: 10px;" (blur)="lnameToUpper()" />
                          </mat-label>
                      </div>
                      <div class="col-6 col-sm-4 col-md-3 col-lg-3 col-xl-3" [hidden]="advanceCategoryflag">
                          <mat-label class="matlabel">From date<br>
                              <input type="date" class="ipcss_date widthbilllist datecss" required
                                  [(ngModel)]="fromDate" max="{{currentDate}}" matInput>
                          </mat-label>
                      </div>
                      <div class="col-6 col-sm-4 col-md-3 col-lg-3 col-xl-3" [hidden]="advanceCategoryflag">
                          <mat-label class="matlabel">To date<br>
                              <input type="date" class="ipcss_date widthbilllist datecss" required
                                  [(ngModel)]="toDate" max="{{currentDate}}" matInput>
                          </mat-label>
                      </div>
                      <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1">
                          <img class="saveimgbtn_inpatinfo searchButton" (click)="filter()"
                          src="../../../assets/ui_icons/search_icon_new.svg" />
                      </div>
                    </div>
                  </div>

                  <div [hidden]="advanceList.length==0" class="dig_table_overflow">
                    <table class="card_tbl table-dynamic table-hover">
                        <thead>
                            <tr>
                                <th>Appointment ID</th>
                                <th>Client</th>
                                <th>Advance</th>
                                <th>Balance</th>
                                <th>Date</th>
                                <th>Time</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let advance of advanceList">
                                <td style="text-align: center;">{{advance.app_id}}</td>
                                <td class="align_left">{{advance.salutation}}{{advance.client}}</td>
                                <td class="align_right">{{advance.advance}}</td>
                                <td class="align_right">{{advance.balance}}</td>
                                <td style="text-align: center;">{{advance.created_date}}</td>
                                <td style="text-align: center;">{{advance.created_time}}</td>
                                <td>
                                    <img *ngIf="printbuttondie" (click)="print(advance)" src="../../../assets/img/printer.svg"
                                        class="backimgbtn_inpatinfo" style="width: 25px; height: 25px;" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                  </div>
                </div>
                             
                <div [hidden]="true" #printbanner id="printbanner">
                  <table class="report-container" style="width: 100%;">
                    <thead>
                      <tr>
                        <th style="width:100%;">                                 
                          <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;margin-right: 30px; margin-left: 20px;"> 
                            <div style="width: 100%; height: 195px !important;"> 
                              <div style="width: 100%;">
                                <img alt="image" src={{hospitalLogo}}  [style]="printlogostyle">
                              </div>                                                             
                              <table style="width: 100%;font-size: 12px;border: 1px solid black;margin-top: 15px;margin-bottom: 5px;border-radius: 10px;height: 95px;"> 
                                <tbody>
                                  <tr style="height: 14px;">
                                    <td style="padding-left: 5px;"><strong>Patient Name</strong></td>
                                    <td>: {{salutation}}{{client}} </td>
                                    <td style="padding-left: 5px;"><strong>Address</strong></td>
                                    <td>: {{clientAddr1}} {{clientAddr2}}</td>
                                  </tr>
                                  <tr style="height: 14px;">
                                    <td style="padding-left: 5px;"><strong>MR No</strong></td>
                                    <td>: {{patient_id}}</td>
                                    <td style="padding-left: 5px;"><strong></strong></td>
                                    <td><span class="address_visibility">:</span> {{location}} {{city}} {{zipcode}}</td>
                                  </tr>
                                  <tr style="height: 14px;">
                                    <td style="padding-left: 5px;"><strong>Age/Gender</strong></td>
                                    <td>: {{age}} / {{gen_short}}</td>
                                    <td style="padding-left: 5px;"><strong></strong></td>
                                    <td><span class="address_visibility">:</span> {{state}} {{country}}</td>
                                  </tr>                                
                                  <tr>
                                    <td style="padding-left: 5px;"><strong></strong></td>
                                    <td>
                                    </td> 
                                    <td style="padding-left: 5px;"><strong></strong></td>
                                    <td *ngIf="barcode_flag">
                                      <img style="width: 157px; height: 37px !important; margin-left: 0px; margin-top: 0px;"
                                        src={{barcode_url}} /></td> 
                                  </tr>                         
                                </tbody>
                              </table>
                            </div>
                          </div>               
                        </th>
                      </tr>
                    </thead>           
                    <tbody class="report-content">
                      <tr>
                        <td class="report-content-cell">
                          <div class="main">                                       
                            <p *ngIf="getBillsList.length != 0" style="text-align: center;font-size: 16px;margin-top: -5px;margin-bottom: 5px"><b>Advance bill</b></p>
                            <table *ngIf="getBillsList.length != 0" style="margin-left: 20px;border: 1px solid black;width: 94%;border-collapse: collapse;margin-right:10px">                            
                              <thead>
                                <tr style="border: 1px solid black;border-collapse: collapse;">
                                  <th style="border: 1px solid black;border-collapse: collapse; text-align:center;">S.No</th>
                                  <th style="border: 1px solid black;border-collapse: collapse; text-align:center;">Date</th>
                                  <th style="border: 1px solid black;border-collapse: collapse; text-align:center;">Payment type</th>
                                  <th style="border: 1px solid black;border-collapse: collapse; text-align:center;">Time</th>
                                  <th style="border: 1px solid black;border-collapse: collapse; text-align:center;">Advance</th>                 
                              </tr>  
                              </thead>
                              <tbody>
                                <tr style="border: 1px solid black;border-collapse: collapse; text-align:center;" *ngFor="let getBill of getBillsList; let i = index">
                                  <td style="border: 1px solid black;border-collapse: collapse; text-align:center;"> {{i + 1}} </td> 
                                  <td style="border: 1px solid black;border-collapse: collapse; text-align:center;"> {{getBill.created_date}} </td>
                                  <td style="border: 1px solid black;border-collapse: collapse; text-align:center;"> {{getBill.paymentType}} </td>
                                  <td style="border: 1px solid black;border-collapse: collapse; text-align:center;"> {{getBill.created_time}} </td>
                                  <td style="border: 1px solid black;border-collapse: collapse; text-align:right;"> {{getBill.advance}} </td>
                                </tr>
                                <tr> 
                                  <td  colspan="4" style="border: 1px solid black;border-collapse: collapse; text-align:center;"> Total advance </td> 
                                  <td style="border: 1px solid black;border-collapse: collapse; text-align:right;"> {{totalAdvance}} </td> 
                                </tr> 
                                <tr>
                                  <td  colspan="6" style="border: 1px solid black;border-collapse: collapse; text-align:left;"> 
                                    <strong>In Words:&nbsp;</strong>Received Rupees&nbsp;{{numToWords}}&nbsp;Only
                                  </td>
                                </tr> 
                              </tbody>                                                                                             
                            </table>                           
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot style="margin-bottom: 10px;"> 
                      <tr>  
                        <td width="100%">  
                          <table style="width: 100%;">  
                            <tr>  
                              <td style="vertical-align: top;width:50%;">
                                <table style="float: left !important;margin-left: 15px;">
                                  <tr>
                                    <td>&nbsp;</td>
                                  </tr>
                                </table> 
                              </td>  
                              <td style="vertical-align: top;width:50%;">
                                <table style="float: right !important;margin-left: 20px;margin-right: 32px;">
                                  <tr>
                                    <div style="float: right !important;margin-left: 20px;margin-right: 32px;">
                                      <p style="margin-bottom: 3px !important;text-align: center;">{{logUserName}}</p>
                                      <p style="text-align: center;margin-top: 6px;margin-bottom: 20px;"><strong>Created by</strong></p>
                                    </div>                       
                                  </tr>
                                </table>                      
                              </td>
                            </tr>  
                          </table> 
                        </td>
                      </tr> 
                    </tfoot> 
                  </table>
                </div>

                <div [hidden]="true" #printnoheader id="printnoheader">
                  <table class="report-container" style="width: 100%;" >
                    <thead>
                      <tr>
                        <th style="width:100%;">
                          <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;margin-right: 30px; margin-left: 20px;"> 
                            <div style="width: 100%; height: 100px;">
                              <div style="height: 5px !important;"></div>
                              <table style="width: 100%;font-size: 12px;border: 1px solid black;border-radius: 10px;height: 85px;"> 
                                <tbody>
                                  <tr style="height: 14px;">
                                    <td style="padding-left: 5px;"><strong>Patient Name</strong></td>
                                    <td>: {{salutation}}{{client}} </td>
                                    <td style="padding-left: 5px;"><strong>Address</strong></td>
                                    <td>: {{clientAddr1}} {{clientAddr2}}</td>
                                  </tr>
                                  <tr style="height: 14px;">
                                    <td style="padding-left: 5px;"><strong>MR No</strong></td>
                                    <td>: {{patient_id}}</td>
                                    <td style="padding-left: 5px;"><strong></strong></td>
                                    <td><span class="address_visibility">:</span> {{location}} {{city}} {{zipcode}}</td>
                                  </tr>
                                  <tr style="height: 14px;">
                                    <td style="padding-left: 5px;"><strong>Age/Gender</strong></td>
                                    <td>: {{age}} / {{gen_short}}</td>
                                    <td style="padding-left: 5px;"><strong></strong></td>
                                    <td><span class="address_visibility">:</span> {{state}} {{country}}</td>
                                  </tr>                                
                                  <tr>
                                    <td style="padding-left: 5px;"><strong></strong></td>
                                    <td>
                                    </td> 
                                    <td style="padding-left: 5px;"><strong></strong></td>
                                    <td *ngIf="barcode_flag">
                                      <img style="width: 157px; height: 37px !important; margin-left: 0px; margin-top: 0px;"
                                        src={{barcode_url}} /></td> 
                                  </tr>
                                </tbody> 
                              </table>
                            </div>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody class="report-content">
                      <tr>
                        <td class="report-content-cell">
                            <div class="main">                                       
                              <p *ngIf="getBillsList.length != 0" style="text-align: center;font-size: 16px;margin-top: 0px;margin-bottom: 2px"><b>Advance bill</b></p>
                              <table *ngIf="getBillsList.length != 0" style="margin-left: 20px;border: 1px solid black;width: 94%;border-collapse: collapse;margin-right:10px;">                            
                                <thead>
                                  <tr style="border: 1px solid black;border-collapse: collapse;">
                                    <th style="border: 1px solid black;border-collapse: collapse; text-align:center;">S.No</th>
                                    <th style="border: 1px solid black;border-collapse: collapse; text-align:center;">Date</th>
                                    <th style="border: 1px solid black;border-collapse: collapse; text-align:center;">Payment type</th>
                                    <th style="border: 1px solid black;border-collapse: collapse; text-align:center;">Time</th>
                                    <th style="border: 1px solid black;border-collapse: collapse; text-align:center;">Advance</th>
                                  </tr> 
                                </thead>
                                <tbody>
                                  <tr style="border: 1px solid black;border-collapse: collapse; text-align:center;" *ngFor="let getBill of getBillsList; let i = index">
                                    <td style="border: 1px solid black;border-collapse: collapse; text-align:center;"> {{i + 1}} </td> 
                                    <td style="border: 1px solid black;border-collapse: collapse; text-align:center;"> {{getBill.created_date}} </td>
                                    <td style="border: 1px solid black;border-collapse: collapse; text-align:center;"> {{getBill.paymentType}} </td>
                                    <td style="border: 1px solid black;border-collapse: collapse; text-align:center;"> {{getBill.created_time}} </td>
                                    <td style="border: 1px solid black;border-collapse: collapse; text-align:right;"> {{getBill.advance}} </td>
                                  </tr>
                                  <tr> 
                                    <td  colspan="4" style="border: 1px solid black;border-collapse: collapse; text-align:center;"> Total advance </td> 
                                    <td style="border: 1px solid black;border-collapse: collapse; text-align:right;"> {{totalAdvance}} </td> 
                                  </tr>  
                                  <tr>
                                    <td  colspan="6" style="border: 1px solid black;border-collapse: collapse; text-align:left;"> 
                                      <strong>In Words:&nbsp;</strong>Received Rupees&nbsp;{{numToWords}}&nbsp;Only
                                    </td>
                                  </tr> 
                                </tbody>                                                                                          
                              </table>                                                                             
                            </div>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot style="margin-bottom: 10px;"> 
                      <tr>  
                        <td width="100%">  
                          <table style="width: 100%;">  
                            <tr>  
                              <td style="vertical-align: top;width:50%;">
                                <table style="float: left !important;margin-left: 15px;">
                                  <tr>
                                    <td>&nbsp;</td>
                                  </tr>
                                </table> 
                              </td>  
                              <td style="vertical-align: top;width:50%;">
                                <table style="float: right !important;margin-left: 20px;margin-right: 32px;">
                                  <tr>
                                    <div style="float: right !important;margin-left: 20px;margin-right: 32px;">
                                      <p style="margin-bottom: 3px !important;text-align: center;">{{logUserName}}</p>
                                      <p style="text-align: center;margin-top: 6px;margin-bottom: 20px;"><strong>Created by</strong></p>
                                    </div>                       
                                  </tr>
                                </table>                      
                              </td>
                            </tr>  
                          </table> 
                        </td>
                      </tr> 
                    </tfoot> 
                  </table>
                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>