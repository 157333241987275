import { Component, OnInit, HostListener, Input } from '@angular/core';
import {Helper_Class} from '../../helper_class';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { Date_Formate } from '../../../assets/js/common';
 import { DatePipe } from '@angular/common';
 import { Observable, observable, Subscription } from 'rxjs';
import { Doc_Helper } from '../Doc_Helper';
import { CasesheetService } from '../casesheet/casesheet.service';
import { MatDialog } from '@angular/material/dialog';
import { PediatricsImmunizationRemarksComponent } from '../pediatrics-immunization-remarks/pediatrics-immunization-remarks.component';
import { PediatricsAddVaccineComponent } from '../pediatrics-add-vaccine/pediatrics-add-vaccine.component';
import { ipaddress } from '../../ipaddress' ;import { masterCSData_Helper } from '../casesheet/masterCSData_Helper';
import { MasterCSHelperService } from '../casesheet/masterCSData.service';
import { Master_Helper } from '../Master_Helper';
import { Message_data } from 'src/assets/js/Message_data';

@Component({
  selector: 'app-pediatrics-immunization',
  templateUrl: './pediatrics-immunization.component.html',
  styleUrls: ['./pediatrics-immunization.component.css']
})
export class PediatricsImmunizationComponent implements OnInit {
 public  pediaImmFlag ="min";
  public keys;
  public values;
  public pediaminflag:boolean=true;
  subscription:Subscription;
  public mor_lable;

  public Main_Vaccine_Data;
  public header_footer_flag: boolean;
  public ismobile:boolean;
  public Client_dob;
  public Client_age;
  public CurrentDatetime: string;
  public age_person_txt;
  public Age_Based;
  public birth_arry = [];
  public due_date;
  public given_date;
  public remark_txt;
  public Given_Date_Age;
  public VarDate;
  public Vaccine_Array = [];
  public due_datetxt;
  public give_datetxt;
  public due_datetxt1;
  public give_datetxt1;
  public due_datetxt2;
  public give_datetxt2;
  public due_datetxt3;
  public give_datetxt3;
  public due_datetxt4;
  public give_datetxt4;
  public due_datetxt5;
  public give_datetxt5;
  public due_datetxt6;
  public give_datetxt6;
  public due_datetxt7;
  public give_datetxt7;
  public due_datetxt8;
  public give_datetxt8;
  public due_datetxt9;
  public give_datetxt9;
  public due_datetxt10;
  public give_datetxt10;
  public key1:any = [];
  public dis_txt;
  public datepipe:DatePipe = new DatePipe('en-US');

  public Age_grouparray;
  public Vaccine_name;
  public Age_group;
  public Age_id;
public test;
  public add_vaccine_flag:boolean=false;
  public remarks_flag:boolean=false;

  public userlist;
  public client:boolean;

  constructor(public dialog:MatDialog,public messageservice:CasesheetService,private http:Http,private routes:ActivatedRoute,
    private router:Router,public toastr:ToastrService,public masterCSdata:MasterCSHelperService) { 
        //Date of birth
       
    this.getCurrentDate();
  }

  async ngOnInit(){
    this.subscription = this.messageservice.getCasesheetMessage().subscribe(message => {
      if (message) {
          this.pediaImmFlag = message;
      }
    });

    if(Doc_Helper.getAppFlow() == "client" ){
      this.userlist = Helper_Class.getInfo();
      this.client = true;
    }else{
      this.userlist = Doc_Helper.getClient_Info();
      this.client = false;
    }

    this.Client_dob = this.userlist.Case_Clnt_dob;
    this.Client_age = this.userlist.Age_data;
    if(masterCSData_Helper.getMasterImmunizationData() == undefined){
      await this.masterCSdata.getImmunizationData();
      this.Get_immunization_data();
    }else{
      this.Get_immunization_data();
    }
   
    if(masterCSData_Helper.getMasterAgeGroup() == undefined){
      await this.masterCSdata.getAgeGroup();
      this.Get_age_group();
    }else{
      this.Get_age_group();
    }
  }

  getCurrentDate(){
    var get_date;
    var obj = Master_Helper.getMasterCurrentDate();
    
    if (obj.current_date != null) {
      this.CurrentDatetime = obj.current_date;  
    }
   }

  // //send data
  // @HostListener('window:click', ['$event'])
  //   onclick(event: any): void {
  //       if(this.client == false){
  //           this.send_data();
  //       }
  // }
  // @HostListener('window:blur', ['$event'])
  //   onBlur(event: any): void {
  //       if(this.client == false){
  //           this.send_data();
  //       }  }

  send_data() {
    var Immunizaion_send = null;
    this.Vaccine_Array = [];
    
    if (this.Main_Vaccine_Data["Birth"] != undefined) {
      for (var i = 0; i < this.Main_Vaccine_Data["Birth"].length; i++) {
        if (this.Main_Vaccine_Data["Birth"][i].given_date != null) {
          if (this.Main_Vaccine_Data["Birth"][i].due_date != undefined) {
            this.due_datetxt = Date_Formate(this.Main_Vaccine_Data["Birth"][i].due_date)
          }
          if (this.Main_Vaccine_Data["Birth"][i].given_date != undefined) {
            this.give_datetxt = Date_Formate(this.Main_Vaccine_Data["Birth"][i].given_date)
          }
          
          var Temp_Data = {
            ped_vaccine_age_id: this.Main_Vaccine_Data["Birth"][i].ped_vaccine_age_id,
            due_date: this.due_datetxt,
            given_date: this.give_datetxt,
            remarks: this.Main_Vaccine_Data["Birth"][i].remarks,
          }

          this.Vaccine_Array.push(Temp_Data);
        }
      }
    }

    if (this.Main_Vaccine_Data["6 weeks"] != undefined) {
      for (var i = 0; i < this.Main_Vaccine_Data["6 weeks"].length; i++) {
        if (this.Main_Vaccine_Data["6 weeks"][i].given_date != null) {

          if (this.Main_Vaccine_Data["6 weeks"][i].due_date != undefined) {
            
            this.due_datetxt1 = Date_Formate(this.Main_Vaccine_Data["6 weeks"][i].due_date)
          }
          if (this.Main_Vaccine_Data["6 weeks"][i].given_date != undefined) {
            this.give_datetxt1 = Date_Formate(this.Main_Vaccine_Data["6 weeks"][i].given_date)

          }

          Temp_Data = {

            ped_vaccine_age_id: this.Main_Vaccine_Data["6 weeks"][i].ped_vaccine_age_id,
            due_date: this.due_datetxt1,
            given_date: this.give_datetxt1,
            remarks: this.Main_Vaccine_Data["6 weeks"][i].remarks,
          }


          this.Vaccine_Array.push(Temp_Data);
        }
      }
    }

    if (this.Main_Vaccine_Data["10 weeks"] != undefined) {
      for (var i = 0; i < this.Main_Vaccine_Data["10 weeks"].length; i++) {
        if (this.Main_Vaccine_Data["10 weeks"][i].given_date != null) {

          if (this.Main_Vaccine_Data["10 weeks"][i].due_date != undefined) {

            this.due_datetxt2 = Date_Formate(this.Main_Vaccine_Data["10 weeks"][i].due_date)
          }
          if (this.Main_Vaccine_Data["10 weeks"][i].given_date != undefined) {

            this.give_datetxt2 = Date_Formate(this.Main_Vaccine_Data["10 weeks"][i].given_date)
          }
          Temp_Data = {

            ped_vaccine_age_id: this.Main_Vaccine_Data["10 weeks"][i].ped_vaccine_age_id,
            due_date: this.due_datetxt2,
            given_date: this.give_datetxt2,
            remarks: this.Main_Vaccine_Data["10 weeks"][i].remarks,

          }

          this.Vaccine_Array.push(Temp_Data);
        }
      }
    }

    if (this.Main_Vaccine_Data["14 weeks"] != undefined) {
      for (var i = 0; i < this.Main_Vaccine_Data["14 weeks"].length; i++) {
        if (this.Main_Vaccine_Data["14 weeks"][i].given_date != null) {
          if (this.Main_Vaccine_Data["14 weeks"][i].due_date != undefined) {

            this.due_datetxt3 = Date_Formate(this.Main_Vaccine_Data["14 weeks"][i].due_date)
          }
          if (this.Main_Vaccine_Data["14 weeks"][i].given_date != undefined) {

            this.give_datetxt3 = Date_Formate(this.Main_Vaccine_Data["14 weeks"][i].given_date)
          }
          Temp_Data = {

            ped_vaccine_age_id: this.Main_Vaccine_Data["14 weeks"][i].ped_vaccine_age_id,
            due_date: this.due_datetxt3,
            given_date: this.give_datetxt3,
            remarks: this.Main_Vaccine_Data["14 weeks"][i].remarks,

          }

          this.Vaccine_Array.push(Temp_Data);
        }
      }
    }

    if (this.Main_Vaccine_Data["6 months"] != undefined) {
      for (var i = 0; i < this.Main_Vaccine_Data["6 months"].length; i++) {
        if (this.Main_Vaccine_Data["6 months"][i].given_date != null) {
          if (this.Main_Vaccine_Data["6 months"][i].due_date != undefined) {

            this.due_datetxt4 = Date_Formate(this.Main_Vaccine_Data["6 months"][i].due_date)
          }
          if (this.Main_Vaccine_Data["6 months"][i].given_date != undefined) {

            this.give_datetxt4 = Date_Formate(this.Main_Vaccine_Data["6 months"][i].given_date)
          }
          Temp_Data = {

            ped_vaccine_age_id: this.Main_Vaccine_Data["6 months"][i].ped_vaccine_age_id,
            due_date: this.due_datetxt4,
            given_date: this.give_datetxt4,
            remarks: this.Main_Vaccine_Data["6 months"][i].remarks,

          }

          this.Vaccine_Array.push(Temp_Data);
        }
      }
    }

    if (this.Main_Vaccine_Data["9 months"] != undefined) {
      for (var i = 0; i < this.Main_Vaccine_Data["9 months"].length; i++) {
        if (this.Main_Vaccine_Data["9 months"][i].given_date != null) {
          if (this.Main_Vaccine_Data["9 months"][i].due_date != undefined) {

            this.due_datetxt5 = Date_Formate(this.Main_Vaccine_Data["9 months"][i].due_date)
          }
          if (this.Main_Vaccine_Data["9 months"][i].given_date != undefined) {

            this.give_datetxt5 = Date_Formate(this.Main_Vaccine_Data["9 months"][i].given_date)
          }
          Temp_Data = {

            ped_vaccine_age_id: this.Main_Vaccine_Data["9 months"][i].ped_vaccine_age_id,
            due_date: this.due_datetxt5,
            given_date: this.give_datetxt5,
            remarks: this.Main_Vaccine_Data["9 months"][i].remarks,

          }

          this.Vaccine_Array.push(Temp_Data);
        }
      }
    }

    if (this.Main_Vaccine_Data["15 - 18 months"] != undefined) {
      for (var i = 0; i < this.Main_Vaccine_Data["15 - 18 months"].length; i++) {
        if (this.Main_Vaccine_Data["15 - 18 months"][i].given_date != null) {
          if (this.Main_Vaccine_Data["15 - 18 months"][i].due_date != undefined) {

            this.due_datetxt6 = Date_Formate(this.Main_Vaccine_Data["15 - 18 months"][i].due_date)
          }
          if (this.Main_Vaccine_Data["15 - 18 months"][i].given_date != undefined) {

            this.give_datetxt6 =Date_Formate(this.Main_Vaccine_Data["15 - 18 months"][i].given_date)
          }
          Temp_Data = {

            ped_vaccine_age_id: this.Main_Vaccine_Data["15 - 18 months"][i].ped_vaccine_age_id,
            due_date: this.due_datetxt6,
            given_date: this.give_datetxt6,
            remarks: this.Main_Vaccine_Data["15 - 18 months"][i].remarks,

          }

          this.Vaccine_Array.push(Temp_Data);
        }
      }
    }

    if (this.Main_Vaccine_Data["2 years"] != undefined) {
      for (var i = 0; i < this.Main_Vaccine_Data["2 years"].length; i++) {
        if (this.Main_Vaccine_Data["2 years"][i].given_date != null) {
          if (this.Main_Vaccine_Data["2 years"][i].due_date != undefined) {

            this.due_datetxt7 = Date_Formate(this.Main_Vaccine_Data["2 years"][i].due_date)
          }
          if (this.Main_Vaccine_Data["2 years"][i].given_date != undefined) {

            this.give_datetxt7 = Date_Formate(this.Main_Vaccine_Data["2 years"][i].given_date)
          }
          Temp_Data = {

            ped_vaccine_age_id: this.Main_Vaccine_Data["2 years"][i].ped_vaccine_age_id,
            due_date: this.due_datetxt7,
            given_date: this.give_datetxt7,
            remarks: this.Main_Vaccine_Data["2 years"][i].remarks,

          }

          this.Vaccine_Array.push(Temp_Data);
        }
      }
    }

    if (this.Main_Vaccine_Data["5 years"] != undefined) {
      for (var i = 0; i < this.Main_Vaccine_Data["5 years"].length; i++) {
        if (this.Main_Vaccine_Data["5 years"][i].due_date != undefined) {

          this.due_datetxt8 = Date_Formate(this.Main_Vaccine_Data["5 years"][i].due_date)
        }
        if (this.Main_Vaccine_Data["5 years"][i].given_date != undefined) {

          this.give_datetxt8 = Date_Formate(this.Main_Vaccine_Data["5 years"][i].given_date)
        }
        if (this.Main_Vaccine_Data["5 years"][i].given_date != null) {
          Temp_Data = {

            ped_vaccine_age_id: this.Main_Vaccine_Data["5 years"][i].ped_vaccine_age_id,
            due_date: this.due_datetxt8,
            given_date: this.give_datetxt8,
            remarks: this.Main_Vaccine_Data["5 years"][i].remarks,

          }

          this.Vaccine_Array.push(Temp_Data);
        }
      }
    }

    if (this.Main_Vaccine_Data["10 years"] != undefined) {
      for (var i = 0; i < this.Main_Vaccine_Data["10 years"].length; i++) {
        if (this.Main_Vaccine_Data["10 years"][i].due_date != undefined) {

          this.due_datetxt9 = Date_Formate(this.Main_Vaccine_Data["10 years"][i].due_date)
        }
        if (this.Main_Vaccine_Data["10 years"][i].given_date != undefined) {

          this.give_datetxt9 = Date_Formate(this.Main_Vaccine_Data["10 years"][i].given_date)
        }
        if (this.Main_Vaccine_Data["10 years"][i].given_date != null) {
          Temp_Data = {

            ped_vaccine_age_id: this.Main_Vaccine_Data["10 years"][i].ped_vaccine_age_id,
            due_date: this.due_datetxt9,
            given_date: this.give_datetxt9,
            remarks: this.Main_Vaccine_Data["10 years"][i].remarks,

          }

          this.Vaccine_Array.push(Temp_Data);
        }
      }
    }

    if (this.Main_Vaccine_Data["15-16 years"] != undefined) {
      for (var i = 0; i < this.Main_Vaccine_Data["15-16 years"].length; i++) {
        if (this.Main_Vaccine_Data["15-16 years"][i].due_date != undefined) {

          this.due_datetxt10 = Date_Formate(this.Main_Vaccine_Data["15-16 years"][i].due_date)
        }
        if (this.Main_Vaccine_Data["15-16 years"][i].given_date != undefined) {

          this.give_datetxt10 = Date_Formate(this.Main_Vaccine_Data["15-16 years"][i].given_date)
        }
        if (this.Main_Vaccine_Data["15-16 years"][i].given_date != null) {
          Temp_Data = {

            ped_vaccine_age_id: this.Main_Vaccine_Data["15-16 years"][i].ped_vaccine_age_id,
            due_date: this.due_datetxt10,
            given_date: this.give_datetxt10,
            remarks: this.Main_Vaccine_Data["15-16 years"][i].remarks,

          }

          this.Vaccine_Array.push(Temp_Data);
        }
      }
    }


    Immunizaion_send = {

      vaccination: this.Vaccine_Array,

    }
    Helper_Class.Setpediaimmunization(Immunizaion_send);
  }

  //Get age calculation
  age_calculation() {

    var Current_date = this.CurrentDatetime;

    if (this.Client_age != undefined) {

      var age = this.Get_Age_Final(this.Client_age, Current_date)



      var some = age.split('-');

      var Client_Year = some[0].toString();

      var Client_Month = some[1].toString();


      if (Client_Year <= "2") {
        if (Client_Year == "0") {
          this.age_person_txt = Client_Month;
          this.Age_Based = "Months";
        }
        else if (Client_Year == "1") {
          this.age_person_txt = Math.round(parseFloat(Client_Month) + 12);
          this.Age_Based = "Months";
        }
        else if (Client_Year == "2" && Client_Month == "0") {
          this.age_person_txt = 24;
          this.Age_Based = "Months";
        }
        else {
          this.age_person_txt = Client_Year;
          this.Age_Based = "Years";
        }
      }
      else {
        this.Age_Based = "Years";
      }
    }
  }

  Get_Age_Final(Client_DOB, Current_Date) {
    var DOBsplit = Client_DOB.split('-');
    var Datesplit = Current_Date.split('-');

    var yearNow = Datesplit[0];
    var monthNow = Datesplit[1];
    var dateNow = Datesplit[2];

    var yearDob = DOBsplit[0];
    var monthDob = DOBsplit[1];
    var dateDob = DOBsplit[2];

    var yearAge;
    yearAge = yearNow - yearDob;

    if (monthNow >= monthDob) {
      var monthAge = monthNow - monthDob;
    }
    else {
      yearAge--;
      var monthAge = (12 + parseInt(monthNow)) - parseInt(monthDob);
    }

    if (dateNow >= dateDob) {
      var dateAge = dateNow - dateDob;
    }
    else {
      monthAge--;
      var dateAge = 31 + parseInt(dateNow) - parseInt(dateDob);

      if (monthAge < 0) {
        monthAge = 11;
        yearAge--;
      }
    }

    //age = {
    //    years: yearAge,
    //    months: monthAge,
    //    days: dateAge
    //};

    var ageString = yearAge + "-" + monthAge;
    return ageString;
  }


  //Immunization disable

  // Immun_date_disable(element, val) {



  //   if (val == "0") {

  //       $(element).datepicker('disable');
  //   }
  // }

  changedatefunction(date){

  }

  Immunization_givendate_change(Age_txt, selected_date, vaccine_id, due_date) {

    if (due_date != undefined && due_date != null) {
      var New_date = new Date(Date_Formate(selected_date));
      if (Age_txt == "15-16 years") {
        // Nothing
      }
      else if (Age_txt == "10 years") {
        New_date.setFullYear(New_date.getFullYear() + 5);
        // New_date.setFullYear(New_date.getFullYear() + 15);

        this.VarDate = new Date(New_date.getTime() + ((1) * (24 * 60 * 60 * 1000)));

        for (var i = 0; i < this.Main_Vaccine_Data["15-16 years"].length; i++) {
          var d = new Date(this.VarDate);
          this.Main_Vaccine_Data["15-16 years"][i].due_date = this.datepipe.transform(this.VarDate, 'dd-MM-yyyy');
          this.Main_Vaccine_Data["15-16 years"][i].due_date1 = Date_Formate(this.datepipe.transform(this.VarDate, 'dd-MM-yyyy'));//Date display purpose

        }
      }
      else if (Age_txt == "5 years") {
        New_date.setFullYear(New_date.getFullYear() + 5);
        //New_date.setFullYear(New_date.getFullYear() + 10);

        this.VarDate = new Date(New_date.getTime() + ((1) * (24 * 60 * 60 * 1000)));

        for (var i = 0; i < this.Main_Vaccine_Data["10 years"].length; i++) {
          var d = new Date(this.VarDate);
          this.Main_Vaccine_Data["10 years"][i].due_date = this.datepipe.transform(this.VarDate, 'dd-MM-yyyy');
          this.Main_Vaccine_Data["10 years"][i].due_date1 = Date_Formate(this.datepipe.transform(this.VarDate, 'dd-MM-yyyy'));
        }
      }
      else if (Age_txt == "2 years") {
        New_date.setFullYear(New_date.getFullYear() + 3);
        this.VarDate = new Date(New_date.getTime() + ((1) * (24 * 60 * 60 * 1000)));

        for (var i = 0; i < this.Main_Vaccine_Data["5 years"].length; i++) {
         // var d = new Date(this.VarDate);
          this.Main_Vaccine_Data["5 years"][i].due_date = (this.datepipe.transform(this.VarDate, 'dd-MM-yyyy'));
          this.Main_Vaccine_Data["5 years"][i].due_date1 = Date_Formate(this.datepipe.transform(this.VarDate, 'dd-MM-yyyy'));
        }
        
      }
      else if (Age_txt == "15 - 18 months") {
        //New_date.setFullYear(New_date.getFullYear() + 2);

        New_date.setMonth(New_date.getMonth() + 8);

        this.VarDate = new Date(New_date.getTime() + ((1) * (24 * 60 * 60 * 1000)));

        for (var i = 0; i < this.Main_Vaccine_Data["2 years"].length; i++) {
          var d = new Date(this.VarDate);
          this.Main_Vaccine_Data["2 years"][i].due_date = this.datepipe.transform(this.VarDate, 'dd-MM-yyyy');
          this.Main_Vaccine_Data["2 years"][i].due_date1 = Date_Formate(this.datepipe.transform(this.VarDate, 'dd-MM-yyyy'));

        }
      }
      else if (Age_txt == "9 months") {
        New_date.setMonth(New_date.getMonth() + 6);

        //New_date.setMonth(New_date.getMonth() + 15);

        this.VarDate = new Date(New_date.getTime() + ((1) * (24 * 60 * 60 * 1000)));

        for (var i = 0; i < this.Main_Vaccine_Data["15 - 18 months"].length; i++) {
          var d = new Date(this.VarDate);
          this.Main_Vaccine_Data["15 - 18 months"][i].due_date = this.datepipe.transform(this.VarDate, 'dd-MM-yyyy');
          this.Main_Vaccine_Data["15 - 18 months"][i].due_date1 = Date_Formate(this.datepipe.transform(this.VarDate, 'dd-MM-yyyy'));

        }
      }
      else if (Age_txt == "6 months") {
        New_date.setMonth(New_date.getMonth() + 3);
        //New_date.setMonth(New_date.getMonth() + 9);

        this.VarDate = new Date(New_date.getTime() + ((1) * (24 * 60 * 60 * 1000)));

        for (var i = 0; i < this.Main_Vaccine_Data["9 months"].length; i++) {
          var d = new Date(this.VarDate);
          this.Main_Vaccine_Data["9 months"][i].due_date = this.datepipe.transform(this.VarDate, 'dd-MM-yyyy');
          this.Main_Vaccine_Data["9 months"][i].due_date1 = Date_Formate(this.datepipe.transform(this.VarDate, 'dd-MM-yyyy'));

        }
      }
      else if (Age_txt == "14 weeks") {
        // New_date.setMonth(New_date.getMonth() + 6);

        this.VarDate = new Date(New_date.getTime() + ((86) * (24 * 60 * 60 * 1000)));

        for (var i = 0; i < this.Main_Vaccine_Data["6 months"].length; i++) {
          var d = new Date(this.VarDate);
          this.Main_Vaccine_Data["6 months"][i].due_date = (this.datepipe.transform(this.VarDate, 'dd-MM-yyyy'));
          this.Main_Vaccine_Data["6 months"][i].due_date1 = Date_Formate(this.datepipe.transform(this.VarDate, 'dd-MM-yyyy'));

        }
      }
      else if (Age_txt == "10 weeks") {
        this.VarDate = new Date(New_date.getTime() + ((29) * (24 * 60 * 60 * 1000)));
        //$scope.VarDate = new Date(New_date.getTime() + ((99) * (24 * 60 * 60 * 1000)));

        for (var i = 0; i < this.Main_Vaccine_Data["14 weeks"].length; i++) {
          var d = new Date(this.VarDate);
          this.Main_Vaccine_Data["14 weeks"][i].due_date = this.datepipe.transform(this.VarDate, 'dd-MM-yyyy');
          this.Main_Vaccine_Data["14 weeks"][i].due_date1 = Date_Formate(this.datepipe.transform(this.VarDate, 'dd-MM-yyyy'));

        }
      }
      else if (Age_txt == "6 weeks") {
        this.VarDate = new Date(New_date.getTime() + ((29) * (24 * 60 * 60 * 1000)));
        //$scope.VarDate = new Date(New_date.getTime() + ((71) * (24 * 60 * 60 * 1000)));

        for (var i = 0; i < this.Main_Vaccine_Data["10 weeks"].length; i++) {
          var d = new Date(this.VarDate);
          this.Main_Vaccine_Data["10 weeks"][i].due_date = this.datepipe.transform(this.VarDate, 'dd-MM-yyyy');
          this.Main_Vaccine_Data["10 weeks"][i].due_date1 = Date_Formate(this.datepipe.transform(this.VarDate, 'dd-MM-yyyy'));

        }
      }
      else if (Age_txt == "Birth") {
        this.VarDate = new Date(New_date.getTime() + ((43) * (24 * 60 * 60 * 1000)));

        for (var i = 0; i < this.Main_Vaccine_Data["6 weeks"].length; i++) {
          var d = new Date(this.VarDate);
          this.Main_Vaccine_Data["6 weeks"][i].due_date = this.datepipe.transform(this.VarDate, 'dd-MM-yyyy');
          this.Main_Vaccine_Data["6 weeks"][i].due_date1 = Date_Formate(this.datepipe.transform(this.VarDate, 'dd-MM-yyyy'));

        }
      }
    }
    else // Due date not selected
    {
      if (Age_txt == "Birth") {
        for (var i = 0; i < this.Main_Vaccine_Data.Birth.length; i++) {
          if (vaccine_id == this.Main_Vaccine_Data.Birth[i].ped_vaccine_age_id) {
            this.Main_Vaccine_Data.Birth[i].given_date = undefined;
            break;
          }
        }
      }
      else if (Age_txt == "6 weeks") {
        for (var i = 0; i < this.Main_Vaccine_Data["6 weeks"].length; i++) {
          if (vaccine_id == this.Main_Vaccine_Data["6 weeks"][i].ped_vaccine_age_id) {
            this.Main_Vaccine_Data["6 weeks"][i].given_date = undefined;
            break;
          }
        }
      }
      else if (Age_txt == "10 weeks") {
        for (var i = 0; i < this.Main_Vaccine_Data["10 weeks"].length; i++) {
          if (vaccine_id == this.Main_Vaccine_Data["10 weeks"][i].ped_vaccine_age_id) {
            this.Main_Vaccine_Data["10 weeks"][i].given_date = undefined;
            break;
          }
        }
      }
      else if (Age_txt == "14 weeks") {
        for (var i = 0; i < this.Main_Vaccine_Data["14 weeks"].length; i++) {
          if (vaccine_id == this.Main_Vaccine_Data["14 weeks"][i].ped_vaccine_age_id) {
            this.Main_Vaccine_Data["14 weeks"][i].given_date = undefined;
            break;
          }
        }
      }
      else if (Age_txt == "6 months") {
        for (var i = 0; i < this.Main_Vaccine_Data["6 months"].length; i++) {
          if (vaccine_id == this.Main_Vaccine_Data["6 months"][i].ped_vaccine_age_id) {
            this.Main_Vaccine_Data["6 months"][i].given_date = undefined;
            break;
          }
        }
      }
      else if (Age_txt == "9 months") {
        for (var i = 0; i < this.Main_Vaccine_Data["9 months"].length; i++) {
          if (vaccine_id == this.Main_Vaccine_Data["9 months"][i].ped_vaccine_age_id) {
            this.Main_Vaccine_Data["9 months"][i].given_date = undefined;
            break;
          }
        }
      }
      else if (Age_txt == "15 - 18 months") {

        for (var i = 0; i < this.Main_Vaccine_Data["15 - 18 months"].length; i++) {
          if (vaccine_id == this.Main_Vaccine_Data["15 - 18 months"][i].ped_vaccine_age_id) {
            this.Main_Vaccine_Data["15 - 18 months"][i].given_date = undefined;
            break;
          }
        }
      }
      else if (Age_txt == "2 years") {
        for (var i = 0; i < this.Main_Vaccine_Data["2 years"].length; i++) {
          if (vaccine_id == this.Main_Vaccine_Data["2 years"][i].ped_vaccine_age_id) {
            this.Main_Vaccine_Data["2 years"][i].given_date = undefined;
            break;
          }
        }
      }
      else if (Age_txt == "5 years") {
        for (var i = 0; i < this.Main_Vaccine_Data["5 years"].length; i++) {
          if (vaccine_id == this.Main_Vaccine_Data["5 years"][i].ped_vaccine_age_id) {
            this.Main_Vaccine_Data["5 years"][i].given_date = undefined;
            break;
          }
        }
      }
      else if (Age_txt == "10 years") {
        for (var i = 0; i < this.Main_Vaccine_Data["10 years"].length; i++) {
          if (vaccine_id == this.Main_Vaccine_Data["10 years"][i].ped_vaccine_age_id) {
            this.Main_Vaccine_Data["10 years"][i].given_date = undefined;
            break;
          }
        }
      }
      else if (Age_txt == "15-16 years") {
        for (var i = 0; i < this.Main_Vaccine_Data["15-16 years"].length; i++) {
          if (vaccine_id == this.Main_Vaccine_Data["15-16 years"][i].ped_vaccine_age_id) {
            this.Main_Vaccine_Data["15-16 years"][i].given_date = undefined;
            break;
          }
        }
      }

      this.toastr.error(Message_data.sltDueDate);
    }
  }

  //Date disabled
  Get_Pediatric_Date_disable(Age_given) {
    if (Age_given == "15-16 years") {
      this.Disable_Immuni_birth_data(); // birth
      this.Disable_Immuni_sixweeks_data(); // 6 weeks
      this.Disable_Immuni_tenweeks_data(); // 10 weeks
      this.Disable_Immuni_fourweeks_data(); // 14 weeks
      this.Disable_Immuni_sixmons_data(); // 6 months
      this.Disable_Immuni_ninemon_data(); // 9 months
      this.Disable_Immuni_fiftmon_data(); // 15-18 months
      this.Disable_Immuni_twoyear_data(); // 2 years
      this.Disable_Immuni_fiveyear_data(); // 5 years
      this.Disable_Immuni_tenyear_data(); // 10 years
    }
    else if (Age_given == "10 years") {
      this.Disable_Immuni_birth_data(); // birth
      this.Disable_Immuni_sixweeks_data(); // 6 weeks
      this.Disable_Immuni_tenweeks_data(); // 10 weeks
      this.Disable_Immuni_fourweeks_data(); // 14 weeks
      this.Disable_Immuni_sixmons_data(); // 6 months
      this.Disable_Immuni_ninemon_data(); // 9 months
      this.Disable_Immuni_fiftmon_data(); // 15-18 months
      this.Disable_Immuni_twoyear_data(); // 2 years
      this.Disable_Immuni_fiveyear_data(); // 5 years
    }
    else if (Age_given == "5 years") {
      this.Disable_Immuni_birth_data(); // birth
      this.Disable_Immuni_sixweeks_data(); // 6 weeks
      this.Disable_Immuni_tenweeks_data(); // 10 weeks
      this.Disable_Immuni_fourweeks_data(); // 14 weeks
      this.Disable_Immuni_sixmons_data(); // 6 months
      this.Disable_Immuni_ninemon_data(); // 9 months
      this.Disable_Immuni_fiftmon_data(); // 15-18 months
      this.Disable_Immuni_twoyear_data(); // 2 years
    }
    else if (Age_given == "2 years") {
      this.Disable_Immuni_birth_data(); // birth
      this.Disable_Immuni_sixweeks_data(); // 6 weeks
      this.Disable_Immuni_tenweeks_data(); // 10 weeks
      this.Disable_Immuni_fourweeks_data(); // 14 weeks
      this.Disable_Immuni_sixmons_data(); // 6 months
      this.Disable_Immuni_ninemon_data(); // 9 months
      this.Disable_Immuni_fiftmon_data(); // 15-18 months
    }
    else if (Age_given == "15 - 18 months") {
      this.Disable_Immuni_birth_data(); // birth
      this.Disable_Immuni_sixweeks_data(); // 6 weeks
      this.Disable_Immuni_tenweeks_data(); // 10 weeks
      this.Disable_Immuni_fourweeks_data(); // 14 weeks
      this.Disable_Immuni_sixmons_data(); // 6 months
      this.Disable_Immuni_ninemon_data(); // 9 months
    }
    else if (Age_given == "9 months") {
      this.Disable_Immuni_birth_data(); // birth
      this.Disable_Immuni_sixweeks_data(); // 6 weeks
      this.Disable_Immuni_tenweeks_data(); // 10 weeks
      this.Disable_Immuni_fourweeks_data(); // 14 weeks
      this.Disable_Immuni_sixmons_data(); // 6 months
    }
    else if (Age_given == "6 months") {
      this.Disable_Immuni_birth_data(); // birth
      this.Disable_Immuni_sixweeks_data(); // 6 weeks
      this.Disable_Immuni_tenweeks_data(); // 10 weeks
      this.Disable_Immuni_fourweeks_data(); // 14 weeks
    }
    else if (Age_given == "14 weeks") {
      this.Disable_Immuni_birth_data(); // birth
      this.Disable_Immuni_sixweeks_data(); // 6 weeks
      this.Disable_Immuni_tenweeks_data(); // 10 weeks
    }
    else if (Age_given == "10 weeks") {
      this.Disable_Immuni_birth_data(); // birth
      this.Disable_Immuni_sixweeks_data(); // 6 weeks
    }
    else if (Age_given == "6 weeks") {
      this.Disable_Immuni_birth_data(); // birth
    }
    //else if (Age_given == "Birth") {
    //    $scope.Disable_Immuni_birth_data(); // birth
    //}
  }

  Disable_Immuni_birth_data() {
    for (var i = 0; i < this.Main_Vaccine_Data.Birth.length; i++) {
      this.Main_Vaccine_Data.Birth[i].given_flag = "0";
      this.Main_Vaccine_Data.Birth[i].due_flag = "0";
    }
  }

  Disable_Immuni_sixweeks_data() {
    for (var i = 0; i < this.Main_Vaccine_Data["6 weeks"].length; i++) {
      this.Main_Vaccine_Data["6 weeks"][i].given_flag = "0";
      this.Main_Vaccine_Data["6 weeks"][i].due_flag = "0";
    }
  }

  Disable_Immuni_tenweeks_data() {
    for (var i = 0; i < this.Main_Vaccine_Data["10 weeks"].length; i++) {
      this.Main_Vaccine_Data["10 weeks"][i].given_flag = "0";
      this.Main_Vaccine_Data["10 weeks"][i].due_flag = "0";
    }
  }

  Disable_Immuni_fourweeks_data() {
    for (var i = 0; i < this.Main_Vaccine_Data["14 weeks"].length; i++) {
      this.Main_Vaccine_Data["14 weeks"][i].given_flag = "0";
      this.Main_Vaccine_Data["14 weeks"][i].due_flag = "0";
    }
  }

  Disable_Immuni_sixmons_data() {
    for (var i = 0; i < this.Main_Vaccine_Data["6 months"].length; i++) {
      this.Main_Vaccine_Data["6 months"][i].given_flag = "0";
      this.Main_Vaccine_Data["6 months"][i].due_flag = "0";
    }
  }

  Disable_Immuni_ninemon_data() {
    for (var i = 0; i < this.Main_Vaccine_Data["9 months"].length; i++) {
      this.Main_Vaccine_Data["9 months"][i].given_flag = "0";
      this.Main_Vaccine_Data["9 months"][i].due_flag = "0";
    }
  }

  Disable_Immuni_fiftmon_data() {
    for (var i = 0; i < this.Main_Vaccine_Data["15 - 18 months"].length; i++) {
      this.Main_Vaccine_Data["15 - 18 months"][i].given_flag = "0";
      this.Main_Vaccine_Data["15 - 18 months"][i].due_flag = "0";
    }
  }

  Disable_Immuni_twoyear_data() {
    for (var i = 0; i < this.Main_Vaccine_Data["2 years"].length; i++) {
      this.Main_Vaccine_Data["2 years"][i].given_flag = "0";
      this.Main_Vaccine_Data["2 years"][i].due_flag = "0";
    }
  }

  Disable_Immuni_fiveyear_data() {
    for (var i = 0; i < this.Main_Vaccine_Data["5 years"].length; i++) {
      this.Main_Vaccine_Data["5 years"][i].given_flag = "0";
      this.Main_Vaccine_Data["5 years"][i].due_flag = "0";
    }
  }

  Disable_Immuni_tenyear_data() {
    for (var i = 0; i < this.Main_Vaccine_Data["10 years"].length; i++) {
      this.Main_Vaccine_Data["10 years"][i].given_flag = "0";
      this.Main_Vaccine_Data["10 years"][i].due_flag = "0";
    }
  }

  Disable_Immuni_fifteenyear_data() {
    for (var i = 0; i < this.Main_Vaccine_Data["15-16 years"].length; i++) {
      this.Main_Vaccine_Data["15-16 years"][i].given_flag = "0";
      this.Main_Vaccine_Data["15-16 years"][i].due_flag = "0";
    }
  }

  //calendar
  calendar_click(key_txt, index_txt, type, selected_date, vaccine_id, due_date) {
    
    this.Main_Vaccine_Data[key_txt][index_txt].given_date = Date_Formate(selected_date);
    this.Immunization_givendate_change(key_txt, Date_Formate(selected_date), vaccine_id, due_date);

    // let search_model = this.modalCtrl.create('CalendarPage', {
    //   Case_Clnt_dob: this.Client_dob,
    //   flow:"docapp"
    // });
    // search_model.present();

    // search_model.onDidDismiss(
    //   data => {
    //     if (data != null) {
    //       this.Main_Vaccine_Data[key_txt][index_txt].given_date = data;
    //       this.Immunization_givendate_change(key_txt, data, vaccine_id, due_date)
    //     }
    //   });
  }
  calendar_click1(key_txt,date, index_txt, type) {
   
    this.Main_Vaccine_Data[key_txt][index_txt].due_date = Date_Formate(date);
   

    // let search_model = this.modalCtrl.create('CalendarPage',{flow:"docapp"});
    // search_model.present();

    // search_model.onDidDismiss(
    //   data => {
    //     if (data != null) {
    //       this.Main_Vaccine_Data[key_txt][index_txt].due_date = data;
    //     }
    //   });
  }
  //Get immunization table data

  Get_immunization_data() {
    this.Main_Vaccine_Data = [];
    this.birth_arry = [];
    var obj = masterCSData_Helper.getMasterImmunizationData();
    if (obj != undefined) {
      this.Main_Vaccine_Data = obj;
      this.keys = Object.keys(this.Main_Vaccine_Data);
      this.Get_development_retrivel();
    }

  }

  //Get immunization retrivel
  Get_development_retrivel() {
    if (Helper_Class.getpediaRet() != undefined) {
      if (Helper_Class.getpediaRet().vaccination != null) {
        this.Given_Date_Age = Helper_Class.getpediaRet().age;
        if (Helper_Class.getpediaRet().vaccination[0].Birth != null && Helper_Class.getpediaRet().vaccination[0].Birth != undefined) {
          for (var i = 0; i < Helper_Class.getpediaRet().vaccination[0].Birth.length; i++) {
            for (var j = 0; j < this.Main_Vaccine_Data["Birth"].length; j++) {
              if (Helper_Class.getpediaRet().vaccination[0].Birth[i].ped_vaccine_age_id == this.Main_Vaccine_Data["Birth"][j].ped_vaccine_age_id) {
                var index = this.Main_Vaccine_Data["Birth"].indexOf(this.Main_Vaccine_Data["Birth"][j]);
                this.Main_Vaccine_Data["Birth"].splice(index, 1); // Delete Row
                this.Main_Vaccine_Data["Birth"].splice(index, 0, Helper_Class.getpediaRet().vaccination[0].Birth[i]); // Add response that Row
                if (this.Main_Vaccine_Data["Birth"][j].due_date != null || this.Main_Vaccine_Data["Birth"][j].due_date != undefined) {
                  this.Main_Vaccine_Data["Birth"][j].due_date = Date_Formate(this.Main_Vaccine_Data["Birth"][j].due_date);
                  this.Main_Vaccine_Data["Birth"][j].due_flag = "0";
                }
                if (this.Main_Vaccine_Data["Birth"][j].given_date != null && this.Main_Vaccine_Data["Birth"][j].given_date != undefined) {
                  var d = this.Main_Vaccine_Data["Birth"][j].given_date;
                  this.Main_Vaccine_Data["Birth"][j].given_date = Date_Formate(d);
                  this.Main_Vaccine_Data["Birth"][j].given_flag = "0";
                }
                if (this.Main_Vaccine_Data["Birth"][j].remarks != null || this.Main_Vaccine_Data["Birth"][j].remarks != undefined) {
                  this.Main_Vaccine_Data["Birth"][j].remarks = this.Main_Vaccine_Data["Birth"][j].remarks;
                }else{
                  this.Main_Vaccine_Data["Birth"][j].remarks = "";
                }
                if (Helper_Class.getpediaRet().vaccination[0].Birth[i].given_date != null) {
                  this.Given_Date_Age = "Birth";
                }
                if (this.Main_Vaccine_Data["Birth"][j].remarks != null) {
                  this.Main_Vaccine_Data["Birth"][j].remarks = this.Main_Vaccine_Data["Birth"][j].remarks;
                }
              }
            }
          }
        }

        // 6 weeks
        if (Helper_Class.getpediaRet().vaccination[0]["6 weeks"] != null && Helper_Class.getpediaRet().vaccination[0]["6 weeks"] != undefined) {
          for (var i = 0; i < Helper_Class.getpediaRet().vaccination[0]["6 weeks"].length; i++) {
            for (var j = 0; j < this.Main_Vaccine_Data["6 weeks"].length; j++) {
              if (Helper_Class.getpediaRet().vaccination[0]["6 weeks"][i].ped_vaccine_age_id == this.Main_Vaccine_Data["6 weeks"][j].ped_vaccine_age_id) {
                var index = this.Main_Vaccine_Data["6 weeks"].indexOf(this.Main_Vaccine_Data["6 weeks"][j]);
                this.Main_Vaccine_Data["6 weeks"].splice(index, 1); // Delete Row
                this.Main_Vaccine_Data["6 weeks"].splice(index, 0, Helper_Class.getpediaRet().vaccination[0]["6 weeks"][i]); // Add response that Row
                if (this.Main_Vaccine_Data["6 weeks"][j].due_date != null || this.Main_Vaccine_Data["6 weeks"][j].due_date != undefined) {
                  var d = this.Main_Vaccine_Data["6 weeks"][j].due_date;
                  this.Main_Vaccine_Data["6 weeks"][j].due_date = Date_Formate(d);
                  this.Main_Vaccine_Data["6 weeks"][j].due_flag = "0";

                }

                if (this.Main_Vaccine_Data["6 weeks"][j].given_date != null && this.Main_Vaccine_Data["6 weeks"][j].given_date != undefined) {
                  var d = this.Main_Vaccine_Data["6 weeks"][j].given_date;
                  this.Main_Vaccine_Data["6 weeks"][j].given_date = Date_Formate(d);
                  this.Main_Vaccine_Data["6 weeks"][j].given_flag = "0";
                }
                if (this.Main_Vaccine_Data["6 weeks"][j].remarks != null && this.Main_Vaccine_Data["6 weeks"][j].remarks != undefined) {
                  this.Main_Vaccine_Data["6 weeks"][j].remarks = this.Main_Vaccine_Data["6 weeks"][j].remarks;
                }
                if(this.Main_Vaccine_Data["6 weeks"][j].remarks == undefined){
                  this.Main_Vaccine_Data["6 weeks"][j].remarks = ""
                }
                if (Helper_Class.getpediaRet().vaccination[0]["6 weeks"][i].given_date != null) {
                  this.Given_Date_Age = "6 weeks";
                }
              }
            }
          }
        }
        // // 10 weeks
        if (Helper_Class.getpediaRet().vaccination[0]["10 weeks"] != null) {
          for (var i = 0; i < Helper_Class.getpediaRet().vaccination[0]["10 weeks"].length; i++) {
            for (var j = 0; j < this.Main_Vaccine_Data["10 weeks"].length; j++) {
              if (Helper_Class.getpediaRet().vaccination[0]["10 weeks"][i].ped_vaccine_age_id == this.Main_Vaccine_Data["10 weeks"][j].ped_vaccine_age_id) {
                var index = this.Main_Vaccine_Data["10 weeks"].indexOf(this.Main_Vaccine_Data["10 weeks"][j]);
                this.Main_Vaccine_Data["10 weeks"].splice(index, 1); // Delete Row
                this.Main_Vaccine_Data["10 weeks"].splice(index, 0, Helper_Class.getpediaRet().vaccination[0]["10 weeks"][i]); // Add response that Row
                if (this.Main_Vaccine_Data["10 weeks"][j].due_date != null || this.Main_Vaccine_Data["10 weeks"][j].due_date != undefined) {
                  var d = this.Main_Vaccine_Data["10 weeks"][j].due_date;
                  this.Main_Vaccine_Data["10 weeks"][j].due_date = Date_Formate(d);
                  this.Main_Vaccine_Data["10 weeks"][j].due_flag = "0";
                }
                if (this.Main_Vaccine_Data["10 weeks"][j].given_date != null && this.Main_Vaccine_Data["10 weeks"][j].given_date != undefined) {
                  var d = this.Main_Vaccine_Data["10 weeks"][j].given_date;
                  this.Main_Vaccine_Data["10 weeks"][j].given_date = Date_Formate(d);
                  this.Main_Vaccine_Data["10 weeks"][j].given_flag = "0";
                }
                if (this.Main_Vaccine_Data["10 weeks"][j].remarks != null && this.Main_Vaccine_Data["10 weeks"][j].remarks != undefined) {
                  this.Main_Vaccine_Data["10 weeks"][j].remarks = this.Main_Vaccine_Data["10 weeks"][j].remarks;
                }else{
                  this.Main_Vaccine_Data["10 weeks"][j].remarks = "";
                }
                if (Helper_Class.getpediaRet().vaccination[0]["10 weeks"][i].given_date != null) {
                  this.Given_Date_Age = "10 weeks";
                }
              }
            }
          }
        }
        // 14 weeks
        if (Helper_Class.getpediaRet().vaccination[0]["14 weeks"] != null) {
          for (var i = 0; i < Helper_Class.getpediaRet().vaccination[0]["14 weeks"].length; i++) {
            for (var j = 0; j < this.Main_Vaccine_Data["14 weeks"].length; j++) {
              if (Helper_Class.getpediaRet().vaccination[0]["14 weeks"][i].ped_vaccine_age_id == this.Main_Vaccine_Data["14 weeks"][j].ped_vaccine_age_id) {
                var index = this.Main_Vaccine_Data["14 weeks"].indexOf(this.Main_Vaccine_Data["14 weeks"][j]);
                this.Main_Vaccine_Data["14 weeks"].splice(index, 1); // Delete Row
                this.Main_Vaccine_Data["14 weeks"].splice(index, 0, Helper_Class.getpediaRet().vaccination[0]["14 weeks"][i]); // Add response that Row
                if (this.Main_Vaccine_Data["14 weeks"][j].due_date != null || this.Main_Vaccine_Data["14 weeks"][j].due_date != undefined) {
                  var d = this.Main_Vaccine_Data["14 weeks"][j].due_date;
                  this.Main_Vaccine_Data["14 weeks"][j].due_date = Date_Formate(d);
                  this.Main_Vaccine_Data["14 weeks"][j].due_flag = "0";
                }
                if (this.Main_Vaccine_Data["14 weeks"][j].remarks != null && this.Main_Vaccine_Data["14 weeks"][j].remarks != undefined) {
                  this.Main_Vaccine_Data["14 weeks"][j].remarks = this.Main_Vaccine_Data["14 weeks"][j].remarks;
                }else{
                  this.Main_Vaccine_Data["14 weeks"][j].remarks = "";
                }
                if (this.Main_Vaccine_Data["14 weeks"][j].given_date != null && this.Main_Vaccine_Data["14 weeks"][j].given_date != undefined) {
                  var d = this.Main_Vaccine_Data["14 weeks"][j].given_date;
                  this.Main_Vaccine_Data["14 weeks"][j].given_date = Date_Formate(d);
                  this.Main_Vaccine_Data["14 weeks"][j].given_flag = "0";
                }
                if (Helper_Class.getpediaRet().vaccination[0]["14 weeks"][i].given_date != null) {
                  this.Given_Date_Age = "14 weeks";
                }
              }
            }
          }
        }
        // 6 months
        if (Helper_Class.getpediaRet().vaccination[0]["6 months"] != null) {
          for (var i = 0; i < Helper_Class.getpediaRet().vaccination[0]["6 months"].length; i++) {
            for (var j = 0; j < this.Main_Vaccine_Data["6 months"].length; j++) {
              if (Helper_Class.getpediaRet().vaccination[0]["6 months"][i].ped_vaccine_age_id == this.Main_Vaccine_Data["6 months"][j].ped_vaccine_age_id) {
                var index = this.Main_Vaccine_Data["6 months"].indexOf(this.Main_Vaccine_Data["6 months"][j]);
                this.Main_Vaccine_Data["6 months"].splice(index, 1); // Delete Row
                this.Main_Vaccine_Data["6 months"].splice(index, 0, Helper_Class.getpediaRet().vaccination[0]["6 months"][i]); // Add response that Row
                if (this.Main_Vaccine_Data["6 months"][j].due_date != null || this.Main_Vaccine_Data["6 months"][j].due_date != undefined) {
                  var d = this.Main_Vaccine_Data["6 months"][j].due_date;
                  this.Main_Vaccine_Data["6 months"][j].due_date = Date_Formate(d);
                  this.Main_Vaccine_Data["6 months"][j].due_flag = "0";
                }
                if (this.Main_Vaccine_Data["6 months"][j].given_date != null && this.Main_Vaccine_Data["6 months"][j].given_date != undefined) {
                  var d = this.Main_Vaccine_Data["6 months"][j].given_date;
                  this.Main_Vaccine_Data["6 months"][j].given_date = Date_Formate(d);
                  this.Main_Vaccine_Data["6 months"][j].given_flag = "0";
                }
                if (this.Main_Vaccine_Data["6 months"][j].remarks != null || this.Main_Vaccine_Data["6 months"][j].remarks != undefined) {
                  this.Main_Vaccine_Data["6 months"][j].remarks = this.Main_Vaccine_Data["6 months"][j].remarks;
                }else{
                  this.Main_Vaccine_Data["6 months"][j].remarks = "";
                }
                if (Helper_Class.getpediaRet().vaccination[0]["6 months"][i].given_date != null) {
                  this.Given_Date_Age = "6 months";
                }
              }
            }
          }
        }
        // 9 months
        if (Helper_Class.getpediaRet().vaccination[0]["9 months"] != null) {
          for (var i = 0; i < Helper_Class.getpediaRet().vaccination[0]["9 months"].length; i++) {
            for (var j = 0; j < this.Main_Vaccine_Data["9 months"].length; j++) {
              if (Helper_Class.getpediaRet().vaccination[0]["9 months"][i].ped_vaccine_age_id == this.Main_Vaccine_Data["9 months"][j].ped_vaccine_age_id) {
                var index = this.Main_Vaccine_Data["9 months"].indexOf(this.Main_Vaccine_Data["9 months"][j]);
                this.Main_Vaccine_Data["9 months"].splice(index, 1); // Delete Row
                this.Main_Vaccine_Data["9 months"].splice(index, 0, Helper_Class.getpediaRet().vaccination[0]["9 months"][i]); // Add response that Row
                if (this.Main_Vaccine_Data["9 months"][j].due_date != null || this.Main_Vaccine_Data["9 months"][j].due_date != undefined) {
                  var d = this.Main_Vaccine_Data["9 months"][j].due_date;
                  this.Main_Vaccine_Data["9 months"][j].due_date = Date_Formate(d);
                  this.Main_Vaccine_Data["9 months"][j].due_flag = "0";
                }
                if (this.Main_Vaccine_Data["9 months"][j].given_date != null && this.Main_Vaccine_Data["9 months"][j].given_date != undefined) {
                  var d = this.Main_Vaccine_Data["9 months"][j].given_date;
                  this.Main_Vaccine_Data["9 months"][j].given_date = Date_Formate(d);
                  this.Main_Vaccine_Data["9 months"][j].given_flag = "0";
                }
                if (this.Main_Vaccine_Data["9 months"][j].remarks != null || this.Main_Vaccine_Data["9 months"][j].remarks != undefined) {
                  this.Main_Vaccine_Data["9 months"][j].remarks = this.Main_Vaccine_Data["9 months"][j].remarks;
                }else{
                  this.Main_Vaccine_Data["9 months"][j].remarks = "";
                }
                if (Helper_Class.getpediaRet().vaccination[0]["9 months"][i].given_date != null) {
                  this.Given_Date_Age = "9 months";
                }
              }
            }
          }
        }
        // 15 - 18 months
        if (Helper_Class.getpediaRet().vaccination[0]["15 - 18 months"] != null) {
          for (var i = 0; i < Helper_Class.getpediaRet().vaccination[0]["15 - 18 months"].length; i++) {
            for (var j = 0; j < this.Main_Vaccine_Data["15 - 18 months"].length; j++) {
              if (Helper_Class.getpediaRet().vaccination[0]["15 - 18 months"][i].ped_vaccine_age_id == this.Main_Vaccine_Data["15 - 18 months"][j].ped_vaccine_age_id) {
                var index = this.Main_Vaccine_Data["15 - 18 months"].indexOf(this.Main_Vaccine_Data["15 - 18 months"][j]);
                this.Main_Vaccine_Data["15 - 18 months"].splice(index, 1); // Delete Row
                this.Main_Vaccine_Data["15 - 18 months"].splice(index, 0, Helper_Class.getpediaRet().vaccination[0]["15 - 18 months"][i]); // Add response that Row
                if (this.Main_Vaccine_Data["15 - 18 months"][j].due_date != null || this.Main_Vaccine_Data["15 - 18 months"][j].due_date != undefined) {
                  var d = this.Main_Vaccine_Data["15 - 18 months"][j].due_date;
                  this.Main_Vaccine_Data["15 - 18 months"][j].due_date = Date_Formate(d);
                  this.Main_Vaccine_Data["15 - 18 months"][j].due_flag = "0";
                }
                if (this.Main_Vaccine_Data["15 - 18 months"][j].given_date != null && this.Main_Vaccine_Data["15 - 18 months"][j].given_date != undefined) {
                  var d = this.Main_Vaccine_Data["15 - 18 months"][j].given_date;
                  this.Main_Vaccine_Data["15 - 18 months"][j].given_date = Date_Formate(d);
                  this.Main_Vaccine_Data["15 - 18 months"][j].given_flag = "0";
                }
                if (this.Main_Vaccine_Data["15 - 18 months"][j].remarks != null || this.Main_Vaccine_Data["15 - 18 months"][j].remarks != undefined) {
                  this.Main_Vaccine_Data["15 - 18 months"][j].remarks = this.Main_Vaccine_Data["15 - 18 months"][j].remarks;
                }else{
                  this.Main_Vaccine_Data["15 - 18 months"][j].remarks = "";
                }
                if (Helper_Class.getpediaRet().vaccination[0]["15 - 18 months"][i].given_date != null) {
                  this.Given_Date_Age = "15 - 18 months";
                }
              }
            }
          }
        }
        // 2 years
        if (Helper_Class.getpediaRet().vaccination[0]["2 years"] != null) {
          for (var i = 0; i < Helper_Class.getpediaRet().vaccination[0]["2 years"].length; i++) {
            for (var j = 0; j < this.Main_Vaccine_Data["2 years"].length; j++) {
              if (Helper_Class.getpediaRet().vaccination[0]["2 years"][i].ped_vaccine_age_id == this.Main_Vaccine_Data["2 years"][j].ped_vaccine_age_id) {
                var index = this.Main_Vaccine_Data["2 years"].indexOf(this.Main_Vaccine_Data["2 years"][j]);
                this.Main_Vaccine_Data["2 years"].splice(index, 1); // Delete Row
                this.Main_Vaccine_Data["2 years"].splice(index, 0, Helper_Class.getpediaRet().vaccination[0]["2 years"][i]); // Add response that Row
                if (this.Main_Vaccine_Data["2 years"][j].due_date != null || this.Main_Vaccine_Data["2 years"][j].due_date != undefined) {
                  var d = this.Main_Vaccine_Data["2 years"][j].due_date;
                  this.Main_Vaccine_Data["2 years"][j].due_date = Date_Formate(d);
                  this.Main_Vaccine_Data["2 years"][j].due_flag = "0";
                }
                if (this.Main_Vaccine_Data["2 years"][j].given_date != null && this.Main_Vaccine_Data["2 years"][j].given_date != undefined) {
                  var d = this.Main_Vaccine_Data["2 years"][j].given_date;
                  this.Main_Vaccine_Data["2 years"][j].given_date = Date_Formate(d);
                  this.Main_Vaccine_Data["2 years"][j].given_flag = "0";
                }
                if (this.Main_Vaccine_Data["2 years"][j].remarks != null || this.Main_Vaccine_Data["2 years"][j].remarks != undefined) {
                  this.Main_Vaccine_Data["2 years"][j].remarks = this.Main_Vaccine_Data["2 years"][j].remarks;
                }else{
                  this.Main_Vaccine_Data["2 years"][j].remarks = "";
                }
                if (Helper_Class.getpediaRet().vaccination[0]["2 years"][i].given_date != null) {
                  this.Given_Date_Age = "2 years";
                }
              }
            }
          }
        }
        // 5 years
        if (Helper_Class.getpediaRet().vaccination[0]["5 years"] != null) {
          for (var i = 0; i < Helper_Class.getpediaRet().vaccination[0]["5 years"].length; i++) {
            for (var j = 0; j < this.Main_Vaccine_Data["5 years"].length; j++) {
              if (Helper_Class.getpediaRet().vaccination[0]["5 years"][i].ped_vaccine_age_id == this.Main_Vaccine_Data["5 years"][j].ped_vaccine_age_id) {
                var index = this.Main_Vaccine_Data["5 years"].indexOf(this.Main_Vaccine_Data["5 years"][j]);
                this.Main_Vaccine_Data["5 years"].splice(index, 1); // Delete Row
                this.Main_Vaccine_Data["5 years"].splice(index, 0, Helper_Class.getpediaRet().vaccination[0]["5 years"][i]); // Add response that Row

                if (this.Main_Vaccine_Data["5 years"][j].due_date != null || this.Main_Vaccine_Data["5 years"][j].due_date != undefined) {
                  var d = this.Main_Vaccine_Data["5 years"][j].due_date;
                  this.Main_Vaccine_Data["5 years"][j].due_date = Date_Formate(d);
                  this.Main_Vaccine_Data["5 years"][j].due_flag = "0";
                }
                if (this.Main_Vaccine_Data["5 years"][j].given_date != null && this.Main_Vaccine_Data["5 years"][j].given_date != undefined) {
                  var d = this.Main_Vaccine_Data["5 years"][j].given_date;
                  this.Main_Vaccine_Data["5 years"][j].given_date = Date_Formate(d);
                  this.Main_Vaccine_Data["5 years"][j].given_flag = "0";
                }
                if (this.Main_Vaccine_Data["5 years"][j].remarks != null || this.Main_Vaccine_Data["5 years"][j].remarks != undefined) {
                  this.Main_Vaccine_Data["5 years"][j].remarks = this.Main_Vaccine_Data["5 years"][j].remarks;
                }else{
                  this.Main_Vaccine_Data["5 years"][j].remarks = "";
                }
                if (Helper_Class.getpediaRet().vaccination[0]["5 years"][i].given_date != null) {
                  this.Given_Date_Age = "5 years";
                }
              }
            }
          }
        }
        // 10 years
        if (Helper_Class.getpediaRet().vaccination[0]["10 years"] != null) {
          for (var i = 0; i < Helper_Class.getpediaRet().vaccination[0]["10 years"].length; i++) {
            for (var j = 0; j < this.Main_Vaccine_Data["10 years"].length; j++) {
              if (Helper_Class.getpediaRet().vaccination[0]["10 years"][i].ped_vaccine_age_id == this.Main_Vaccine_Data["10 years"][j].ped_vaccine_age_id) {
                var index = this.Main_Vaccine_Data["10 years"].indexOf(this.Main_Vaccine_Data["10 years"][j]);
                this.Main_Vaccine_Data["10 years"].splice(index, 1); // Delete Row
                this.Main_Vaccine_Data["10 years"].splice(index, 0, Helper_Class.getpediaRet().vaccination[0]["10 years"][i]); // Add response that Row
                if (this.Main_Vaccine_Data["10 years"][j].due_date != null || this.Main_Vaccine_Data["10 years"][j].due_date != undefined) {
                  var d = this.Main_Vaccine_Data["10 years"][j].due_date;
                  this.Main_Vaccine_Data["10 years"][j].due_date = Date_Formate(d);
                  this.Main_Vaccine_Data["10 years"][j].due_flag = "0";
                }
                if (this.Main_Vaccine_Data["10 years"][j].remarks != null || this.Main_Vaccine_Data["10 years"][j].remarks != undefined) {
                  this.Main_Vaccine_Data["10 years"][j].remarks = this.Main_Vaccine_Data["10 years"][j].remarks;
                }else{
                  this.Main_Vaccine_Data["10 years"][j].remarks = "";
                }
                if (Helper_Class.getpediaRet().vaccination[0]["10 years"][i].given_date != null) {
                  this.Given_Date_Age = "10 years";
                }
              }
            }
          }
        }
        // 15-16 year
        if (Helper_Class.getpediaRet().vaccination[0]["15-16 years"] != null) {
          for (var i = 0; i < Helper_Class.getpediaRet().vaccination[0]["15-16 years"].length; i++) {
            for (var j = 0; j < this.Main_Vaccine_Data["15-16 years"].length; j++) {
              if (Helper_Class.getpediaRet().vaccination[0]["15-16 years"][i].ped_vaccine_age_id == this.Main_Vaccine_Data["15-16 years"][j].ped_vaccine_age_id) {
                var index = this.Main_Vaccine_Data["15-16 years"].indexOf(this.Main_Vaccine_Data["15-16 years"][j]);
                this.Main_Vaccine_Data["15-16 years"].splice(index, 1); // Delete Row
                this.Main_Vaccine_Data["15-16 years"].splice(index, 0, Helper_Class.getpediaRet().vaccination[0]["15-16 years"][i]); // Add response that Row

                if (this.Main_Vaccine_Data["15-16 years"][j].due_date != null || this.Main_Vaccine_Data["15-16 years"][j].due_date != undefined) {
                  var d = this.Main_Vaccine_Data["15-16 years"][j].due_date;
                  this.Main_Vaccine_Data["15-16 years"][j].due_date = Date_Formate(d);
                  this.Main_Vaccine_Data["15-16 years"][j].due_flag = "0";
                }

                if (this.Main_Vaccine_Data["15-16 years"][j].given_date != null && this.Main_Vaccine_Data["15-16 years"][j].given_date != undefined) {
                  var d = this.Main_Vaccine_Data["15-16 years"][j].given_date;
                  this.Main_Vaccine_Data["15-16 years"][j].given_date = Date_Formate(d);
                  this.Main_Vaccine_Data["15-16 years"][j].given_flag = "0";
                }

                if (Helper_Class.getpediaRet().vaccination[0]["15-16 years"][i].given_date != null) {
                  this.Given_Date_Age = "15-16 years";
                }
                if (this.Main_Vaccine_Data["15-16 years"][j].remarks != null || this.Main_Vaccine_Data["15-16 years"][j].remarks != undefined) {
                  this.Main_Vaccine_Data["15-16 years"][j].remarks = this.Main_Vaccine_Data["15-16 years"][j].remarks;
                }else{
                  this.Main_Vaccine_Data["15-16 years"][j].remarks = "";
                }
              }
            }
          }
        }
        if (this.Given_Date_Age != "" && this.Client_dob != null && this.Client_dob != "") {
          var New_date = new Date(this.Client_dob);
          if (this.Given_Date_Age == "15-16 years") {
            // Nothing
          }
          else if (this.Given_Date_Age == "10 years") {
            New_date.setFullYear(New_date.getFullYear() + 15);
            this.VarDate = new Date(New_date.getTime() + ((1) * (24 * 60 * 60 * 1000)));
            if (this.Main_Vaccine_Data["15-16 years"] != undefined) {
              for (var i = 0; i < this.Main_Vaccine_Data["15-16 years"].length; i++) {
                this.Main_Vaccine_Data["15-16 years"][i].due_date = this.datepipe.transform(this.VarDate, 'dd-MM-yyyy');
              }
            }
          }
          else if (this.Given_Date_Age == "5 years") {
            New_date.setFullYear(New_date.getFullYear() + 10);
            this.VarDate = new Date(New_date.getTime() + ((1) * (24 * 60 * 60 * 1000)));
            if (this.Main_Vaccine_Data["10 years"] != undefined) {
              for (var i = 0; i < this.Main_Vaccine_Data["10 years"].length; i++) {
                this.Main_Vaccine_Data["10 years"][i].due_date = this.datepipe.transform(this.VarDate, 'dd-MM-yyyy');;
              }
            }
          }
          else if (this.Given_Date_Age == "2 years") {
            New_date.setFullYear(New_date.getFullYear() + 5);
            this.VarDate = new Date(New_date.getTime() + ((1) * (24 * 60 * 60 * 1000)));
            if (this.Main_Vaccine_Data["5 years"] != undefined) {
              for (var i = 0; i < this.Main_Vaccine_Data["5 years"].length; i++) {
                this.Main_Vaccine_Data["5 years"][i].due_date = this.datepipe.transform(this.VarDate, 'dd-MM-yyyy');;
              }
            }
          }
          else if (this.Given_Date_Age == "15 - 18 months") {
            New_date.setFullYear(New_date.getFullYear() + 2);
            this.VarDate = new Date(New_date.getTime() + ((1) * (24 * 60 * 60 * 1000)));
            if (this.Main_Vaccine_Data["2 years"] != undefined) {
              for (var i = 0; i < this.Main_Vaccine_Data["2 years"].length; i++) {
                this.Main_Vaccine_Data["2 years"][i].due_date = this.datepipe.transform(this.VarDate, 'dd-MM-yyyy');;
              }
            }
          }
          else if (this.Given_Date_Age == "9 months") {
            New_date.setMonth(New_date.getMonth() + 15);
            this.VarDate = new Date(New_date.getTime() + ((1) * (24 * 60 * 60 * 1000)));
            if (this.Main_Vaccine_Data["15 - 18 months"] != undefined) {
              for (var i = 0; i < this.Main_Vaccine_Data["15 - 18 months"].length; i++) {
                this.Main_Vaccine_Data["15 - 18 months"][i].due_date = this.datepipe.transform(this.VarDate, 'dd-MM-yyyy');;
              }
            }
          }
          else if (this.Given_Date_Age == "6 months") {
            New_date.setMonth(New_date.getMonth() + 9);
            this.VarDate = new Date(New_date.getTime() + ((1) * (24 * 60 * 60 * 1000)));
            if (this.Main_Vaccine_Data["9 months"] != undefined) {
              for (var i = 0; i < this.Main_Vaccine_Data["9 months"].length; i++) {
                this.Main_Vaccine_Data["9 months"][i].due_date = this.datepipe.transform(this.VarDate, 'dd-MM-yyyy');;
              }
            }
          }
          else if (this.Given_Date_Age == "14 weeks") {
            New_date.setMonth(New_date.getMonth() + 6);
            this.VarDate = new Date(New_date.getTime() + ((86) * (24 * 60 * 60 * 1000)));
            if (this.Main_Vaccine_Data["6 months"] != undefined) {
              for (var i = 0; i < this.Main_Vaccine_Data["6 months"].length; i++) {
                this.Main_Vaccine_Data["6 months"][i].due_date = this.datepipe.transform(this.VarDate, 'dd-MM-yyyy');;
              }
            }
          }
          else if (this.Given_Date_Age == "10 weeks") {
            this.VarDate = new Date(New_date.getTime() + ((29) * (24 * 60 * 60 * 1000)));
            if (this.Main_Vaccine_Data["14 weeks"] != undefined) {
              for (var i = 0; i < this.Main_Vaccine_Data["14 weeks"].length; i++) {
                this.Main_Vaccine_Data["14 weeks"][i].due_date = this.datepipe.transform(this.VarDate, 'dd-MM-yyyy');;
              }
            }
          }
          else if (this.Given_Date_Age == "6 weeks") {
            this.VarDate = new Date(New_date.getTime() + ((29) * (24 * 60 * 60 * 1000)));
            if (this.Main_Vaccine_Data["10 weeks"] != undefined) {
              for (var i = 0; i < this.Main_Vaccine_Data["10 weeks"].length; i++) {
                this.Main_Vaccine_Data["10 weeks"][i].due_date = this.datepipe.transform(this.VarDate, 'dd-MM-yyyy');;
              }
            }
          }
          else if (this.Given_Date_Age == "Birth") {
            this.VarDate = new Date(New_date.getTime() + ((43) * (24 * 60 * 60 * 1000)));
            if (this.Main_Vaccine_Data["6 weeks"] != undefined) {
              for (var i = 0; i < this.Main_Vaccine_Data["6 weeks"].length; i++) {
                this.Main_Vaccine_Data["6 weeks"][i].due_date = this.datepipe.transform(this.VarDate, 'dd-MM-yyyy');;
              }
            }
          }
          this.Get_Pediatric_Date_disable(this.Given_Date_Age);
        }
      }
    }
  }

  //Add vaccine
  Vaccine_add() {
      this.add_vaccine_flag=!this.add_vaccine_flag;
      const dialogRef = this.dialog.open(PediatricsAddVaccineComponent, {
        width: '700px',
        height: '250px',
      });
    
      dialogRef.afterClosed().subscribe(result => {
        if(masterCSData_Helper.getMasterImmunizationData() == undefined){
          this.masterCSdata.getImmunizationData();
          this.Get_immunization_data();
        }else{
          this.Get_immunization_data();
        }
      });
  }
  
    //remarks retrivel and click event
    remarkclick(key_txt, index_txt) {
      // if (this.Main_Vaccine_Data[key_txt][index_txt].given_flag != null) {
      //   var bool = true;
      // }
      // else {
      //   var bool = false;
      // }
      this.remarks_flag = true;
      const dialogRef = this.dialog.open(PediatricsImmunizationRemarksComponent, {
        width: '700px',
        height: '250px',
        data:this.Main_Vaccine_Data[key_txt][index_txt].remarks,
      });
    
      dialogRef.afterClosed().subscribe(result => {
        this.Main_Vaccine_Data[key_txt][index_txt].remarks = result;
      });
    }

    Get_age_group() {
      this.Age_grouparray;
      var obj = masterCSData_Helper.getMasterAgeGroup();
      if (obj != undefined) 
        this.Age_grouparray = obj.age_groups;
    }
  
    //save vaccine
    Vaccine_save() {
      for (var i = 0; i < this.Age_grouparray.length; i++) {
        if (this.Age_grouparray[i].description == this.Age_group) {
  
          this.Age_id = this.Age_grouparray[i].age_id;
        }
      }
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'pedia/svac/',
        JSON.stringify({
          vaccine_name: this.Vaccine_name,
          age_group_id: this.Age_id
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            if (obj["key"] == "1") {
              this.toastr.success(Message_data.vaccineSavedSuccess);
              this.add_vaccine_flag=!this.add_vaccine_flag;
            }
          },
          error => {});
    }

    Vaccine_cancel(){
      this.add_vaccine_flag=!this.add_vaccine_flag;
    }
}
