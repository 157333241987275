import { Component, OnInit ,ElementRef } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr'
import { CommonDataService } from '../../providers/common-data.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';
@Component({
  selector: 'app-medupload',
  templateUrl: './medupload.component.html',
  styleUrls: ['./medupload.component.scss']
})
export class MeduploadComponent implements OnInit {
  public medicareName: string;
  public medicineTypeList = [];
  public medicareList = [];
  public medicineType: string;
  public medicareId: any;
  public drugType: string;
  public medicineName: string;
  public intakeSessionList = [];
  public intakeSession: string;
  public mor: string;
  public aft: string;
  public eve: string;
  public ngt: string;
  public everySix: string;
  public duration: string;
  public sendDuration: string;
  public listProducts = [];
  public day: string;
  public intakeFood: string;
  public listProductsFlag: boolean;
  public durationFlag: boolean;
  public morningFlag: boolean;
  public afternoonFlag: boolean;
  public morHypenFlag: boolean;
  public aftHypenFlag: boolean;
  public qtyFlag: boolean;
  public doneFlag: boolean;
  public intakeId;

  public shortForm: string;
  public showShortForm: string;
  public showIntake: string;
  public dayduration: string;
  public drugId: string;
  public mornEvenFlag: boolean;
  public eveEvenFlag: boolean;
  public nightEvenFlag:boolean;
  public intakeFlag: boolean;
  public eveHypenFlag: boolean;
  public morningRequiredFlag: boolean;
  public morningDisabledFlag: boolean;
  public afternoonRequiredFlag: boolean;
  public evenDisabledFlag: boolean;
  public afternoonDisabledFlag: boolean;
  public eveningRequiredFlag: boolean;
  public nightRequiredFlag: boolean;
  public nightDisabledFlag: boolean;
  public medicineNameList =[];
  public medicineNameListData = [];
  public medicineMasterList = [];
  public Select = "Select";
  
  constructor(public comm_Data:CommonDataService,public el: ElementRef,public toastr:ToastrService,public router:Router,public  http:Http,public dialog: MatDialog ,public dialogRef: MatDialogRef<MeduploadComponent>){
    this.getMedicareType();
    this.getMedicineType();
    this.getIntakeSession();
    this.qtyFlag = true;
  }

  ngOnInit(): void {
    this.listProductsFlag = true;
    this.durationFlag = false;
    this.qtyFlag = true;
    this.morningFlag = false;
    this.afternoonFlag = false;
    this.eveEvenFlag= true;
    this.nightEvenFlag=false;
    this.morHypenFlag = false;
    this.aftHypenFlag = false;
    this.eveHypenFlag = true;
    this.doneFlag = true;
    this.dayduration = "day(s)"
    this.intakeFood = "After food";
    this.duration = "30";
    this.drugId="0";
  }

  getMedicareType() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'usercontroller/getmedicare',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.medicareList = obj.medicare;
        },error => {});
  }

  getMedicineType() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/medtypes/',
      JSON.stringify({
        medicare_id: this.medicareName
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.medicineTypeList = obj.med_details;
        },error => {});
  }

  getIntakeSession() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'prescription/dintypes/',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.intakeSessionList = obj.drug_intake;
        },error => {});
  }

  changeMededicineType(){
    for(var i=0;i<this.medicineTypeList.length;i++){
      if(this.medicineTypeList[i].med_name == this.medicineType){
        this.medicineType = this.medicineTypeList[i].med_name;
        this.drugType = this.medicineTypeList[i].med_code;
        this.shortForm = this.medicineTypeList[i].short_form;
        this.showShortForm = this.medicineTypeList[i].show_short_form;
        this.showIntake = this.medicineTypeList[i].show_intake;
        if (this.showIntake == "0") {
          this.mornEvenFlag = true;
          this.afternoonFlag = true;
          this.eveEvenFlag=true;
          this.nightEvenFlag=true;
          this.intakeFlag = true;
          this.durationFlag = true;
          this.morHypenFlag = true;
          this.aftHypenFlag = true;
          this.eveHypenFlag= true;
        } else {
          this.mornEvenFlag = false;
          this.afternoonFlag = false;
          this.eveEvenFlag=true;
          this.nightEvenFlag=false;
          this.intakeFlag = false;
          if(this.intakeFood !=undefined){
            this.durationFlag = false;
          } else {
            this.durationFlag = true;
          }
          this.morHypenFlag = false;
          this.aftHypenFlag = false;
          this.eveHypenFlag=true;
        }
      }
    }
  }

  changeMedicineName() {
    this.medicineNameList = [];
    this.medicineMasterList = [];
    this.medicineNameListData=[];
    for (var i = 0; i < this.medicineTypeList.length; i++) {
      if (this.medicineTypeList[i].med_name == this.medicineType) {
        this.drugType = this.medicineTypeList[i].med_code;
      }
    }
    for (var j = 0; j < this.medicareList.length; j++) {
      if (this.medicareList[j].medicare_name == this.medicareName) {
        this.medicareId = this.medicareList[j].medicare_id;
      }
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/mdetbyclnt/',
      JSON.stringify({
        drug_type: this.drugType,
        drug_category: this.medicareId,
        drug_name: this.medicineName
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.med_details != null) {
            this.medicineMasterList = obj.med_details;
            for (var i = 0; i < obj.med_details.length; i++) {
              this.medicineNameListData.push(obj.med_details[i].med_name);
            }
            this.medicineNameList = this.medicineNameListData.filter(function (this:any,el) {
              return el.toLowerCase().indexOf(this.medicine_nametxt.toLowerCase()) > -1;
            }.bind(this));
            this.doneFlag = false;
          }
        },
        error => {
        }
      )
  }
    
  selectMedicineName(item) {
    this.medicineName = item;
    for(var k=0;k<this.medicineMasterList.length;k++){
      if(this.medicineMasterList[k].med_name == item){
        this.drugId = this.medicineMasterList[k].med_id;
      }
    }
    this.medicineNameList = [];
  }

  close(){
    this.dialogRef.close();
  }

  addMedicine() {
    var flag = false;
    if (this.medicineType == undefined) {
      this.toastr.error(Message_data.enterMedType);
      flag = true;
    } if (this.medicineName == undefined || this.medicineName == "") {
      this.toastr.error(Message_data.enterMedName);
      flag = true;
    } else if (this.day == undefined || this.day == "") {
      this.toastr.error(Message_data.enterNoOfDays);
      flag = true;
    } else if (this.intakeSession == undefined) {
      this.toastr.error(Message_data.enterIntkSess);
      flag = true;
    }
    if (this.showIntake == "1") {
      if (this.intakeSession != undefined && this.intakeSession == "Morning only" && (this.mor == undefined || this.mor == "")) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intakeSession != undefined && this.intakeSession == "Afternoon only" && (this.aft == undefined || this.aft == "")) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intakeSession != undefined && this.intakeSession == "Evening only" && (this.eve == undefined || this.eve == "")) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      }  else if (this.intakeSession != undefined && this.intakeSession == "Night only" && (this.ngt == undefined || this.ngt == "")) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intakeSession != undefined && this.intakeSession == "Morning & Afternoon" &&
        ((this.mor == undefined || this.mor == "") || (this.aft == undefined || this.aft == ""))) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intakeSession != undefined && this.intakeSession == "Morning & Evening" &&
        ((this.mor == undefined || this.mor == "") || (this.eve == undefined || this.eve == ""))) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intakeSession != undefined && this.intakeSession == "Morning & Night" &&
        ((this.mor == undefined || this.mor == "") || (this.ngt == undefined || this.ngt == ""))) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intakeSession != undefined && this.intakeSession == "Afternoon & Evening" &&
        ((this.aft == undefined || this.aft == "") || (this.eve == undefined || this.eve == ""))) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intakeSession != undefined && this.intakeSession == "Afternoon & Night" &&
        ((this.aft == undefined || this.aft == "") || (this.ngt == undefined || this.ngt == ""))) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intakeSession != undefined && this.intakeSession == "Morning,Afternoon & Evening" &&
        ((this.mor == undefined || this.mor == "") || (this.aft == undefined || this.aft == "")
          || (this.eve == undefined || this.eve == ""))) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intakeSession != undefined && this.intakeSession == "Morning,Afternoon & Night" &&
        ((this.mor == undefined || this.mor == "") || (this.aft == undefined || this.aft == "")
          || (this.ngt == undefined || this.ngt == ""))) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intakeSession != undefined && this.intakeSession == "Morning,Afternoon,Evening & Night" &&
        ((this.mor == undefined || this.mor == "") || (this.aft == undefined || this.aft == "")
          || (this.eve == undefined || this.eve == "")|| (this.ngt == undefined || this.ngt == ""))) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intakeSession != undefined && this.intakeSession == "Afternoon,Evening & Night" &&
        ((this.aft == undefined || this.aft == "") || (this.eve == undefined || this.eve == "")
          || (this.ngt == undefined || this.ngt == ""))) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intakeSession != undefined && this.intakeSession == "Evening & Night" &&
        ((this.eve == undefined || this.eve == "") || (this.ngt == undefined || this.ngt == ""))) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intakeSession != undefined && this.intakeSession == "Thrice a day" &&
        ((this.mor == undefined || this.mor == "") || (this.aft == undefined || this.aft == "")
          || (this.ngt == undefined || this.ngt == ""))) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intakeSession != undefined && this.intakeSession == "Four times a day" &&
        ((this.mor == undefined || this.mor == "") || (this.aft == undefined || this.aft == "")
          || (this.eve == undefined || this.eve == "")|| (this.ngt == undefined || this.ngt == ""))) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } 
      else if (this.intakeSession != undefined && this.intakeSession == "Every 6 hours" && (this.mor == undefined || this.mor == "")) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intakeSession != undefined && (this.intakeSession == "Alternative day" || this.intakeSession == "Once a week"
        || this.intakeSession == "Once in two weeks") && (this.mor == "0" && this.aft == "0" && this.eve == "0")) {
          this.toastr.error(Message_data.enterDailyIntk);          
          flag = true;
      }
    }
    if (flag == false) {
      for (var i = 0; i < this.medicineTypeList.length; i++) {
        if (this.medicineTypeList[i].med_name == this.medicineType) {
          this.drugType = this.medicineTypeList[i].med_code;
        }
      }
      for (var i = 0; i < this.intakeSessionList.length; i++) {
        if (this.intakeSession == this.intakeSessionList[i].description) {
          this.intakeId = this.intakeSessionList[i].drug_int_id;
        }
      }
      this.listProductsFlag = false;
      if (this.drugId != null) {
        var aftfood;
        if (this.showShortForm == "1") {
          var morntxt;
          if (parseInt(this.mor) != 0) {
            morntxt = this.mor + this.shortForm;
          } else {
            morntxt = "0"
          }
          var afttxt;
          if (parseInt(this.aft) != 0) {
            afttxt = this.aft + this.shortForm;
          } else {
            afttxt = "0"
          }
          var evetxt;
          if (parseInt(this.eve) != 0) {
            evetxt = this.eve + this.shortForm;
          } else {
            evetxt = "0"
          }
          var ngttxt;
          if (parseInt(this.ngt) != 0) {
            ngttxt = this.ngt + this.shortForm;
          } else {
            ngttxt = "0"
          }
          if (this.showIntake == "0") {
            this.everySix = this.intakeSession;
          } else {
            if (this.intakeSession == "Every 6 hours") {
              this.everySix = morntxt + " - " + morntxt + " - " + morntxt + " - " + morntxt;
            } else if (this.intakeSession == "SOS") {
              this.everySix = "SOS - if required";
            } else if (this.intakeSession == "Thrice a day") {
              this.everySix = morntxt + " - " + afttxt + " - " + ngttxt;
            } else if (this.intakeSession == "Four times a day") {
              this.everySix = morntxt + " - " + afttxt + " - " + evetxt+ " - " + ngttxt;
            } else {
              this.everySix = morntxt + " - " + afttxt + " - " + ngttxt;
            }
          }
          if (this.duration != undefined && this.duration != "") {
            this.sendDuration = this.duration + " " + "mins";
          } else {
            this.sendDuration = "";
          }
          if (this.intakeFood == undefined) {
            this.intakeFood = "";
          }
          var frequency;
          if (this.intakeSession == "Alternative day" || this.intakeSession == "Once a week" || this.intakeSession == "Once in two weeks") {
            frequency = this.intakeSession;
          } else {
            if (this.day == "1" && this.dayduration == "day(s)") {
              frequency ="";
            } else {
              frequency = "Daily";
            }
          }
          var period
          if (this.day == "1" && this.dayduration == "day(s)") {
            period = "day";
          } else if (this.day != "1" && this.dayduration == "day(s)") {
            period = "days";
          } else if (this.day == "1" && this.dayduration == "week(s)") {
            period = "week";
          } else if (this.day != "1" && this.dayduration == "week(s)") {
            period = "weeks";
          } else if (this.day == "1" && this.dayduration == "month(s)") {
            period = "month";
          } else if (this.day != "1" && this.dayduration == "month(s)") {
            period = "months";
          } else {
            period = "";
          }
          if(this.intakeFood != undefined && this.intakeFood !='Select'){
            aftfood=this.intakeFood;
          } else {
            aftfood="";
          }
          this.listProducts.push({
            med_typetxt: this.medicineType,
            drug_name: this.medicineName,
            days: this.day,
            period: period,
            drug_intake: aftfood,
            intake: this.intakeId,
            every_six: this.everySix,
            dure_txt_table: this.sendDuration,
            time_duration: this.duration,
            morning: this.mor,
            afternoon: this.aft,
            evening: this.eve,
            night: this.ngt,
            drug_id: this.drugId,
            drug_type_id: this.drugType,
            frequency: frequency,
            show_intake: this.showIntake,
          });
        } else {
          var morntxt;
          if (parseInt(this.mor) != 0) {
            morntxt = this.mor + this.shortForm;
          } else {
            morntxt = "0"
          }
          var afttxt;
          if (parseInt(this.aft) != 0) {
            afttxt = this.aft + this.shortForm;
          } else {
            afttxt = "0"
          }
          var evetxt;
          if (parseInt(this.eve) != 0) {
            evetxt = this.eve + this.shortForm;
          } else {
            evetxt = "0"
          }
          var ngttxt;
          if (parseInt(this.ngt) != 0) {
            ngttxt = this.ngt + this.shortForm;
          } else {
            ngttxt = "0"
          }
          if (this.showIntake == "0") {
            this.everySix = this.intakeSession;
          } else {
            if (this.intakeSession == "Every 6 hours") {
              this.everySix = morntxt + " - " + morntxt + " - " + morntxt + " - " + morntxt;
            } else if (this.intakeSession == "SOS") {
              this.everySix = "SOS - if required";
            } else if (this.intakeSession == "Thrice a day") {
              this.everySix = morntxt + " - " + afttxt + " - " + ngttxt;
            } else if (this.intakeSession == "Four times a day") {
              this.everySix = morntxt + " - " + afttxt + " - " + evetxt+ " - " + ngttxt;
            } else {
              this.everySix = morntxt + " - " + afttxt + " - " + ngttxt;  
            }
          }
          if (this.duration != undefined && this.duration != "") {
            this.sendDuration = this.duration + " " + "mins";
          } else {
            this.sendDuration = "";
          }
          var frequency;
          if (this.intakeSession == "Alternative day" || this.intakeSession == "Once a week" || this.intakeSession == "Once in two weeks") {
            frequency = this.intakeSession;
          } else {
            if (this.day == "1" && this.dayduration == "day(s)") {
              frequency ="";
            } else {
              frequency = "Daily";
            }
          }
          var period
          if (this.day == "1" && this.dayduration == "day(s)") {
            period = "day";
          } else if (this.day != "1" && this.dayduration == "day(s)") {
            period = "days";
          } else if (this.day == "1" && this.dayduration == "week(s)") {
            period = "week";
          } else if (this.day != "1" && this.dayduration == "week(s)") {
            period = "weeks";
          } else if (this.day == "1" && this.dayduration == "month(s)") {
            period = "month";
          } else if (this.day != "1" && this.dayduration == "month(s)") {
            period = "months";
          } else {
            period = "";
          }
          if(this.intakeFood != undefined && this.intakeFood !='Select'){
            aftfood=this.intakeFood;
          } else {
            aftfood="";
          }
          this.listProducts.push({
            med_typetxt: this.medicineType,
            drug_name: this.medicineName,
            days: this.day,
            period: period,
            drug_intake: aftfood,
            intake: this.intakeId,
            every_six: this.everySix,
            dure_txt_table: this.sendDuration,
            time_duration: this.duration,
            morning: this.mor,
            afternoon: this.aft,
            evening: this.eve,
            night:this.ngt,
            drug_id: this.drugId,
            drug_type_id: this.drugType,
            frequency: frequency,
            show_intake: this.showIntake,
          });
        }
        this.medicineType = "";
        this.medicineName = "";
      }
    }
  }

  deleteMedicine(medid) {
    for (var i = 0; i < this.listProducts.length; i++) {
      if (this.listProducts[i].drug_id == medid) {
        this.listProducts.splice(i, 1);
        break;
      }
    }
    if (this.listProducts.length != 0) {
      this.listProductsFlag = false;
    } else {
      this.listProductsFlag = true;
    }
  }

  changeIntakeSession() {
    if (this.intakeSession == "Morning only" && this.showIntake == "1") {
      this.mor = "";
      this.aft = "0";
      this.eve = "0";
      this.ngt = "0";
      this.morningDisabledFlag = false;
      this.afternoonDisabledFlag = true;
      this.morHypenFlag = false;
      this.aftHypenFlag = false;
      this.eveHypenFlag = true;
      this.eveEvenFlag=true;
      this.nightEvenFlag=false;
      this.morningDisabledFlag=false;
      this.afternoonDisabledFlag = true;
      this.evenDisabledFlag = true;
      this.nightDisabledFlag = true;
      this.morningFlag = false;
      this.afternoonFlag = false;
      this.qtyFlag = true;
      this.morningRequiredFlag = true;
      this.afternoonRequiredFlag = false;
      this.eveningRequiredFlag = false;
      this.nightRequiredFlag = false;
      this.intakeFlag = false;
      if (this.intakeFood != "With food") {
        this.durationFlag = false;
      }
      this.mornEvenFlag = false;
    } else if (this.intakeSession == "Afternoon only" && this.showIntake == "1") {
      this.mor = "0";
      this.aft = "";
      this.eve = "0";
      this.ngt = "0";
      this.afternoonDisabledFlag = false;
      this.morningDisabledFlag = true;
      this.evenDisabledFlag = true;
      this.nightDisabledFlag = true;
      this.morHypenFlag = false;
      this.aftHypenFlag = false;
      this.eveHypenFlag = true;
      this.eveEvenFlag=true;
      this.nightEvenFlag=false;
      this.morningFlag = false;
      this.afternoonFlag = false;
      this.qtyFlag = true;
      this.morningRequiredFlag = false;
      this.afternoonRequiredFlag = true;
      this.eveningRequiredFlag = false;
      this.nightRequiredFlag = true;
      this.intakeFlag = false;
      if (this.intakeFood != "With food") {
        this.durationFlag = false;
      }
      this.mornEvenFlag = false;
    } else if (this.intakeSession == "Evening only" && this.showIntake == "1") {
      this.mor = "0";
      this.aft = "0";
      this.eve = "";
      this.ngt = "0";
      this.morningDisabledFlag = true;
      this.afternoonDisabledFlag = true;
      this.evenDisabledFlag = false;
      this.nightDisabledFlag = true;
      this.morHypenFlag = false;
      this.aftHypenFlag = false;
      this.eveHypenFlag = true;
      this.eveEvenFlag=false;
      this.nightEvenFlag=true;
      this.morningFlag = false;
      this.afternoonFlag = false;
      this.qtyFlag = true;
      this.morningRequiredFlag = false;
      this.afternoonRequiredFlag = false;
      this.eveningRequiredFlag = true;
      this.nightRequiredFlag=false;
      this.intakeFlag = false;
      if (this.intakeFood != "With food") {
        this.durationFlag = false;
      }
      this.mornEvenFlag = false;
    } else if (this.intakeSession == "Night only" && this.showIntake == "1") {
      this.mor = "0";
      this.aft = "0";
      this.eve = "0";
      this.ngt = "";
      this.afternoonDisabledFlag = true;
      this.morningDisabledFlag = true;
      this.evenDisabledFlag = true;
      this.nightDisabledFlag = false;
      this.morHypenFlag = false;
      this.aftHypenFlag = false;
      this.eveHypenFlag = true;
      this.eveEvenFlag=true;
      this.nightEvenFlag=false;
      this.morningRequiredFlag = false;
      this.afternoonRequiredFlag = false;
      this.eveningRequiredFlag = false;
      this.nightRequiredFlag =true;
      this.morningFlag = false;
      this.afternoonFlag = false;
      this.qtyFlag = true;
      this.intakeFlag = false;
      if (this.intakeFood != "With food") {
        this.durationFlag = false;
      }
      this.mornEvenFlag = false;
    } else if (this.intakeSession == "Morning & Afternoon" && this.showIntake == "1") {
      this.mor = "";
      this.aft = "";
      this.eve = "0";
      this.ngt = "0";
      this.morningDisabledFlag = false;
      this.afternoonDisabledFlag = false;
      this.evenDisabledFlag = true;
      this.nightDisabledFlag = true;
      this.morHypenFlag = false;
      this.aftHypenFlag = false;
      this.eveHypenFlag = true;
      this.eveEvenFlag=true;
      this.nightEvenFlag=false;
      this.morningRequiredFlag = true;
      this.afternoonRequiredFlag = true;
      this.eveningRequiredFlag = false;
      this.nightRequiredFlag =false;
      this.morningFlag = false;
      this.afternoonFlag = false;
      this.qtyFlag = true;
      this.intakeFlag = false;
      if (this.intakeFood != "With food") {
        this.durationFlag = false;
      }
      this.mornEvenFlag = false;
    } else if (this.intakeSession == "Morning & Evening" && this.showIntake == "1") {
      this.mor = "";
      this.aft = "0";
      this.eve = "";
      this.ngt = "0";
      this.morningDisabledFlag = false;
      this.afternoonDisabledFlag = true;
      this.evenDisabledFlag = false;
      this.nightDisabledFlag = true;
      this.morHypenFlag = false;
      this.aftHypenFlag = false;
      this.eveHypenFlag = true;
      this.eveEvenFlag=false;
      this.nightEvenFlag=true;
      this.morningRequiredFlag = true;
      this.afternoonRequiredFlag = false;
      this.eveningRequiredFlag = true;
      this.nightRequiredFlag =false;
      this.morningFlag = false;
      this.afternoonFlag = false;
      this.qtyFlag = true;
      this.intakeFlag = false;
      if (this.intakeFood != "With food") {
        this.durationFlag = false;
      }
      this.mornEvenFlag = false;
    } else if (this.intakeSession == "Morning & Night" && this.showIntake == "1") {
      this.mor = "";
      this.aft = "0";
      this.eve = "0";
      this.ngt = "";
      this.morningDisabledFlag = false;
      this.afternoonDisabledFlag = true;
      this.evenDisabledFlag = true;
      this.nightDisabledFlag = false;
      this.morHypenFlag = false;
      this.aftHypenFlag = false;
      this.eveHypenFlag = true;
      this.eveEvenFlag=true;
      this.nightEvenFlag=false;
      this.morningRequiredFlag = true;
      this.afternoonRequiredFlag = false;
      this.eveningRequiredFlag = false;
      this.nightRequiredFlag =true;
      this.morningFlag = false;
      this.afternoonFlag = false;
      this.qtyFlag = true;
      this.intakeFlag = false;
      if (this.intakeFood != "With food") {
        this.durationFlag = false;
      }
      this.mornEvenFlag = false;
    } else if (this.intakeSession == "Afternoon & Night" && this.showIntake == "1") {
      this.mor = "0";
      this.aft = "";
      this.eve = "0";
      this.ngt = "";
      this.morningDisabledFlag = true;
      this.afternoonDisabledFlag = false;
      this.evenDisabledFlag = true;
      this.nightDisabledFlag = false;
      this.morHypenFlag = false;
      this.aftHypenFlag = false;
      this.eveHypenFlag = true;
      this.eveEvenFlag=true;
      this.nightEvenFlag=false;
      this.morningRequiredFlag = false;
      this.afternoonRequiredFlag = true;
      this.eveningRequiredFlag = false;
      this.nightRequiredFlag =true;
      this.morningFlag = false;
      this.afternoonFlag = false;
      this.qtyFlag = true;
      this.intakeFlag = false;
      if (this.intakeFood != "With food") {
        this.durationFlag = false;
      }
      this.mornEvenFlag = false;
    } else if (this.intakeSession == "Afternoon & Evening" && this.showIntake == "1") {
      this.mor = "0";
      this.aft = "";
      this.eve = "";
      this.ngt = "0";
      this.morningDisabledFlag = true;
      this.afternoonDisabledFlag = false;
      this.evenDisabledFlag = false;
      this.nightDisabledFlag = true;
      this.morHypenFlag = false;
      this.aftHypenFlag = false;
      this.eveHypenFlag = true;
      this.eveEvenFlag=false;
      this.nightEvenFlag=true;
      this.morningRequiredFlag = false;
      this.afternoonRequiredFlag = true;
      this.eveningRequiredFlag = true;
      this.nightRequiredFlag =false;
      this.morningFlag = false;
      this.afternoonFlag = false;
      this.qtyFlag = true;
      this.intakeFlag = false;
      if (this.intakeFood != "With food") {
        this.durationFlag = false;
      }
      this.mornEvenFlag = false;
    } else if (this.intakeSession == "Morning,Afternoon & Evening" && this.showIntake == "1") {
      this.mor = "";
      this.aft = "";
      this.eve = "";
      this.ngt = "0";
      this.morningDisabledFlag = false;
      this.afternoonDisabledFlag = false;
      this.evenDisabledFlag = false;
      this.nightDisabledFlag = true;
      this.morHypenFlag = false;
      this.aftHypenFlag = false;
      this.eveHypenFlag = true;
      this.eveEvenFlag=false;
      this.nightEvenFlag=true;
      this.morningRequiredFlag = true;
      this.afternoonRequiredFlag = true;
      this.eveningRequiredFlag = true;
      this.nightRequiredFlag =false;
      this.morningFlag = false;
      this.afternoonFlag = false;
      this.qtyFlag = true;
      this.intakeFlag = false;
      if (this.intakeFood != "With food") {
        this.durationFlag = false;
      }
      this.mornEvenFlag = false;
    } else if (this.intakeSession == "Thrice a day" && this.showIntake == "1") {
      this.mor = "";
      this.aft = "";
      this.eve = "0";
      this.ngt = "";
      this.morningDisabledFlag = false;
      this.afternoonDisabledFlag = false;
      this.evenDisabledFlag = true;
      this.nightDisabledFlag = false;
      this.morHypenFlag = false;
      this.aftHypenFlag = false;
      this.eveHypenFlag = true;
      this.eveEvenFlag=true;
      this.nightEvenFlag=false;
      this.morningRequiredFlag = true;
      this.afternoonRequiredFlag = true;
      this.eveningRequiredFlag = false;
      this.nightRequiredFlag =true;
      this.morningFlag = false;
      this.afternoonFlag = false;
      this.qtyFlag = true;
      this.intakeFlag = false;
      if (this.intakeFood != "With food") {
        this.durationFlag = false;
      }
      this.mornEvenFlag = false;
    } else if (this.intakeSession == "Morning,Afternoon & Night" && this.showIntake == "1") {
      this.mor = "";
      this.aft = "";
      this.eve = "0";
      this.ngt = "";
      this.morningDisabledFlag = false;
      this.afternoonDisabledFlag = false;
      this.evenDisabledFlag = true;
      this.nightDisabledFlag = false;
      this.morHypenFlag = false;
      this.aftHypenFlag = false;
      this.eveHypenFlag = true;
      this.eveEvenFlag=true;
      this.nightEvenFlag=false;
      this.morningRequiredFlag = true;
      this.afternoonRequiredFlag = true;
      this.eveningRequiredFlag = false;
      this.nightRequiredFlag =true;
      this.morningFlag = false;
      this.afternoonFlag = false;
      this.qtyFlag = true;
      this.intakeFlag = false;
      if (this.intakeFood != "With food") {
        this.durationFlag = false;
      }
      this.mornEvenFlag = false;
    } else if (this.intakeSession == "Morning,Afternoon,Evening & Night" && this.showIntake == "1") {
      this.mor = "";
      this.aft = "";
      this.eve = "";
      this.ngt = "";
      this.morningDisabledFlag = false;
      this.afternoonDisabledFlag = false;
      this.evenDisabledFlag = false;
      this.nightDisabledFlag = false;
      this.morHypenFlag = false;
      this.aftHypenFlag = false;
      this.eveHypenFlag = false;
      this.eveEvenFlag=false;
      this.nightEvenFlag=false;
      this.morningRequiredFlag = true;
      this.afternoonRequiredFlag = true;
      this.eveningRequiredFlag = true;
      this.nightRequiredFlag =true;
      this.morningFlag = false;
      this.afternoonFlag = false;
      this.qtyFlag = true;
      this.intakeFlag = false;
      if (this.intakeFood != "With food") {
        this.durationFlag = false;
      }
      this.mornEvenFlag = false;
    } else if (this.intakeSession == "Four times a day" && this.showIntake == "1") {
      this.mor = "";
      this.aft = "";
      this.eve = "";
      this.ngt = "";
      this.morningDisabledFlag = false;
      this.afternoonDisabledFlag = false;
      this.evenDisabledFlag = false;
      this.nightDisabledFlag = false;
      this.morHypenFlag = false;
      this.aftHypenFlag = false;
      this.eveHypenFlag = false;
      this.eveEvenFlag=false;
      this.nightEvenFlag=false;
      this.morningRequiredFlag = true;
      this.afternoonRequiredFlag = true;
      this.eveningRequiredFlag = true;
      this.nightRequiredFlag =true;
      this.morningFlag = false;
      this.afternoonFlag = false;
      this.qtyFlag = true;
      this.intakeFlag = false;
      if (this.intakeFood != "With food") {
        this.durationFlag = false;
      }
      this.mornEvenFlag = false;
    } else if (this.intakeSession == "Afternoon,Evening & Night" && this.showIntake == "1") {
      this.mor = "0";
      this.aft = "";
      this.eve = "";
      this.ngt = "";
      this.morningDisabledFlag = true;
      this.afternoonDisabledFlag = false;
      this.evenDisabledFlag = false;
      this.nightDisabledFlag = false;
      this.morHypenFlag = false;
      this.aftHypenFlag = false;
      this.eveHypenFlag = false;
      this.eveEvenFlag=false;
      this.nightEvenFlag=false;
      this.morningRequiredFlag = false;
      this.afternoonRequiredFlag = true;
      this.eveningRequiredFlag = true;
      this.nightRequiredFlag =true;
      this.morningFlag = false;
      this.afternoonFlag = false;
      this.qtyFlag = true;
      this.intakeFlag = false;
      if (this.intakeFood != "With food") {
        this.durationFlag = false;
      }
      this.mornEvenFlag = false;
    } else if (this.intakeSession == "Evening & Night" && this.showIntake == "1") {
      this.mor = "0";
      this.aft = "0";
      this.eve = "";
      this.ngt = "";
      this.morningDisabledFlag = true;
      this.afternoonDisabledFlag = true;
      this.evenDisabledFlag = false;
      this.nightDisabledFlag = false;
      this.morHypenFlag = false;
      this.aftHypenFlag = false;
      this.eveHypenFlag = false;
      this.eveEvenFlag=false;
      this.nightEvenFlag=false;
      this.morningRequiredFlag = false;
      this.afternoonRequiredFlag = false;
      this.eveningRequiredFlag = true;
      this.nightRequiredFlag =true;
      this.morningFlag = false;
      this.afternoonFlag = false;
      this.qtyFlag = true;
      this.intakeFlag = false;
      if (this.intakeFood != "With food") {
        this.durationFlag = false;
      }
      this.mornEvenFlag = false;
    } else if ((this.intakeSession == "Every 6 hours" || this.intakeSession == "Every 8 hours" || this.intakeSession == "Every 12 hours" || this.intakeSession == "SOS" || this.intakeSession == "Once a day") && this.showIntake == "1" ) {
      this.mor = "";
      this.aft = "0";
      this.eve = "0";
      this.ngt = "0";
      this.qtyFlag = false;
      this.morningDisabledFlag = false;
      this.morHypenFlag = true;
      this.aftHypenFlag = true;
      this.eveHypenFlag = true;
      this.eveEvenFlag=true;
      this.nightEvenFlag=true;
      this.morningFlag = true;
      this.afternoonFlag = true;
      this.morningRequiredFlag = true;
      this.intakeFlag = false;
      if (this.intakeFood != "With food") {
        this.durationFlag = false;
      }
      this.mornEvenFlag = false;
    }else if (this.intakeSession == "Stat" && this.showIntake == "1" ) {
      this.mor = "";
      this.aft = "0";
      this.eve = "0";
      this.ngt = "0";
      this.qtyFlag = false;
      this.morningDisabledFlag = true;
      this.morHypenFlag = true;
      this.aftHypenFlag = true;
      this.eveHypenFlag = true;
      this.eveEvenFlag=true;
      this.nightEvenFlag=true;
      this.morningFlag = true;
      this.afternoonFlag = true;
      this.morningRequiredFlag = true;
      this.intakeFlag = true;
      if (this.intakeFood != "With food") {
        this.durationFlag = true;
      }
      this.mornEvenFlag = false;
    }
  else if (this.intakeSession == "Alternative day" || this.intakeSession == "Once a week" || this.intakeSession == "Once in two weeks") {
      this.mor = "0";
      this.aft = "0";
      this.ngt = "0";
      this.afternoonDisabledFlag = false;
      this.morningDisabledFlag = false;
      this.morHypenFlag = false;
      this.morningFlag = false;
      this.aftHypenFlag = false;
      this.eveHypenFlag = false;
      this.eveEvenFlag=false;
      this.nightEvenFlag=false;
      this.evenDisabledFlag = false;
      this.nightDisabledFlag = false;
      this.afternoonFlag = false;
      this.qtyFlag = true;
      this.eveningRequiredFlag = true;
      this.afternoonRequiredFlag = true;
      this.morningRequiredFlag = true;
      this.intakeFlag = false;
      if (this.intakeFood != "With food") {
        this.durationFlag = false;
      }
      this.mornEvenFlag = false;
    }
  }

  clearMedicineName() {
    this.medicineName = null;
    this.medicineNameList = [];
  }

  changeIntakeFood() {
    if (this.intakeFood == "After food" || this.intakeFood == "Before food") {
      this.durationFlag = false;
    } else {
      this.durationFlag = true;
      this.duration = undefined;
    }
  }
}
