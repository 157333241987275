import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { CommonDataService } from '../../providers/common-data.service';
import { MatDialog } from '@angular/material/dialog';
import { ClientPostcovidTrackComponent } from '../client-postcovid-track/client-postcovid-track.component';
import { Message_data } from '../../../assets/js/Message_data';
import { ipaddress } from '../../ipaddress';
@Component({
  selector: 'app-postcovid-track',
  templateUrl: './postcovid-track.component.html',
  styleUrls: ['./postcovid-track.component.css']
})
export class PostcovidTrackComponent implements OnInit {
  public symptomId: string;
  public severity: string;
  public symptomUrl: string;
  public sleepUrl: string;
  public medPresUrl: string;
  public currentDate: string;
  public sleepSession: string;
  public wakeSession: string;
  public exercise: string;
  public date;
  public clientRegId: string;
  public sleepDuration: string;
  public sleepId: string;
  public trackerName: string;
  public exerDuration: string;
  public exerFrequency: string;
  public temperature: string;
  public BP01: string;
  public BP02: string;
  public spo2: string;
  public pulse: string;
  public respiratoryRate: string;
  public timeStart1;
  public timeStart2;
  public timeStart3;
  public sleepStart1;
  public sleepStart2;
  public wakeStart1;
  public wakeStart2;
  public visitSession;
  public subRelId;
  public relationId;
  public relationName;
  public relationsList=[];
  public dailySympList=[];
  public covidSympList=[];
  public sleepPatternList=[];
  public sleepTimeList=[];
  public phyExerList=[]
  public yogaList=[];
  public yogaCheck = [];
  public sendYogaData = [];
  public timeStartsFrom = [];
  public timeEndsAt = [];
  public visitingSessionData = [];
  public medicineHistList = [];
  public foodCategory = [];
  public morFoodType = [];
  public morSubfoodType = [];
  public symptomFlag: boolean;
  public sleepFlag: boolean;
  public dietFlag: boolean;
  public medicationFlag: boolean;
  public physicalFlag: boolean;
  public yogaFlag: boolean;
  public noData: boolean;
  public morFCategory;
  public morFType;
  public morSubFType;
  public morMeasure;
  public morWeight;
  public morEnegry;
  public morFoodQty;
  public dietMorListData:any = [];
  public caloriesData;
  public calories: string;
  public totalCalories;
  public morningFlag: boolean;
  public lifeStyleId;
  public breakFCategory: string;
  public breakFType: string;
  public breakSubFType: string;
  public breakFoodQty: string;
  public breakMeasure: string;
  public breakWeight;
  public breakEnergy;
  public breakfastFlag: boolean;
  public breakFoodType:any = [];
  public breakSubfoodType:any = [];
  public dietBreakFastListData:any = [];
  public middayFCategory: string;
  public middayFType: string;
  public middaySubFType: string;
  public middayFoodQty: string;
  public middayMeasure: string;
  public middayWeight;
  public middayEnergy;
  public middayFlag: boolean;
  public middayFoodType:any = [];
  public middaySubfoodType:any = [];
  public dietMiddayListData:any = [];
  public lunchFCategory: string;
  public lunchFType: string;
  public lunchSubFType: string;
  public lunchFoodQty: string;
  public lunchMeasure: string;
  public lunchWeight;
  public lunchWeightEnergy;
  public lunchEnergy;
  public lunchFlag: boolean;
  public lunchFoodType:any = [];
  public lunchSubfoodType:any = [];
  public dietLunchListData:any = [];
  public eveFCategory: string;
  public eveFType: string;
  public eveSubFType: string;
  public eveFoodQty: string;
  public eveMeasure: string;
  public eveWeight;
  public eveEnergy;
  public eveFlag: boolean;
  public eveFoodType:any = [];
  public eveSubfoodType:any = [];
  public dietEveListData:any = [];
  public dinnerFCategory: string;
  public dinnerFType: string;
  public dinnerSubFType: string;
  public dinnerFoodQty: string;
  public dinnerMeasure: string;
  public dinnerWeight;
  public dinnerEnergy;
  public dinnerFlag: boolean;
  public dinnerFoodType:any = [];
  public dinnerSubfoodType:any = [];
  public dietDinnerListData:any = [];
  public nightFCategory: string;
  public nightFType: string;
  public nightSubFType: string;
  public nightFoodQty: string;
  public nightMeasure: string;
  public nightWeight;
  public nightEnergy;
  public nightFlag: boolean;
  public nightFoodType:any = [];
  public nightSubfoodType:any = [];
  public dietNightListData:any = [];
  public calcNumber: number;
  public calcQuantity: number;
  public calcWeight: number;
  public calcEnergy: number;
  public calcMeasure: string;
  public createdTime;
  public header_footer_flag: boolean = this.gservice.get_platform_screen();
  public currentMaxDate;

  constructor(public dialog: MatDialog,public gservice:CommonDataService,public datepipe:DatePipe,public http:HttpClient,public routes:ActivatedRoute,public router:Router,public toastr:ToastrService) { 
      this.symptomUrl=ipaddress.getIp + "gen/covsym";
      this.sleepUrl=ipaddress.getIp + "gen/spat";
      this.medPresUrl = ipaddress.getIp + "lifestyle/pmeds";
      this.symptomFlag=false;
      this.yogaFlag = true;
      this.caloriesData = "0";
      this.calories = "0";
      this.totalCalories = "0";
      this.calcQuantity = 0;
      this.calcWeight = 0;
      this.calcEnergy = 0;
      this.calcMeasure = "";
      for (var i = 1; i <= 12; i++) {
        if (i < 10) {
          this.timeStartsFrom.push("0" + i);
        } else {
          this.timeStartsFrom.push(i);
        }
      }
      for (var i = 0; i <= 55; i += 5) {
        if (i < 10) {
          this.timeEndsAt.push("0" + i);
        } else {
          this.timeEndsAt.push(i);
        }
      }
      this.sleepStart1 = "10";
      this.sleepStart2 = "30";
      this.sleepSession = "PM";
      this.wakeStart1 = "06";
      this.wakeStart2 = "30";
      this.wakeSession = "AM";
      this.relationId="1"
      this.clientRegId = this.gservice.Client_login_detail_data.Client_Id;
  }

  ngOnInit(): void {
    this.severity ="Low";
    this.getSession();
    this.calculateTimeDiff();
    this.getRelationList();
    this.getCovidSymptoms();
    this.getCurrentDate();
    this.getSleepPatterns();
    this.getExercise();
    this.getYoga();
    this.getFoodCategory(); 
  }

  isReadonly() { return true; }

  getSession() {
    this.visitingSessionData = [{ "id": 1, "description": "Morning" }, { "id": 2, "description": "Afternoon" }, { "id": 3, "description": "Evening" }];
    this.visitSession = this.visitingSessionData[0].description;
  }

  getCurrentDate(){
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate', {
        country: ipaddress.country_code.toString()
      },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          if (obj != undefined && obj.current_date != null) {
            var get_date = obj.current_date.split('-');
            this.currentDate = get_date[2] + "-" + get_date[1] + "-" + get_date[0];
            this.currentMaxDate = obj.current_date;
            this.date = obj.current_date;
            this.changeIntakeSession("Morning");
          }
        },error => {
            this.toastr.error(Message_data.getNetworkMessage());
        })
  }

  getRelationList() {
    this.relationsList = [];
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'usercontroller/grel',{
        client_reg_id: this.gservice.Client_login_detail_data.Client_Id
      },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          if (obj != undefined) {
            this.relationsList.push({
              client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
              relation_id: "1",
              name: this.gservice.Client_login_detail_data.Full_Name,
              sub_rel_id: undefined,
              gender: this.gservice.Client_login_detail_data.gender,
              age: this.gservice.Client_login_detail_data.age,
            })
            for (var i = 0; i < obj.relations.length; i++) {
              var name;
              if (obj.relations[i].middle_name != undefined) {
                name = encrypt_decript.Decript(obj.relations[i].first_name) + " " + encrypt_decript.Decript(obj.relations[i].middle_name) + " " + encrypt_decript.Decript(obj.relations[i].last_name);
              } else {
                name = encrypt_decript.Decript(obj.relations[i].first_name) + " " + encrypt_decript.Decript(obj.relations[i].last_name);
              }
              this.relationsList.push({
                client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
                relation_id: obj.relations[i].relation_id,
                sub_rel_id: obj.relations[i].sub_rel_id,
                name: name,
                gender: encrypt_decript.Decript(obj.relations[i].gender),
                age: obj.relations[i].age,
              })
            }
          }
          this.relationName = this.relationsList[0].relation_id;
        },error => {});
  }

  changeRelation(e) {
    for (var i = 0; i < this.relationsList.length; i++) {
      if (e == this.relationsList[i].relation_id) {
        this.clientRegId = this.relationsList[i].client_reg_id;
        this.relationId = this.relationsList[i].relation_id;
        this.subRelId = this.relationsList[i].sub_rel_id;
      }
    }
  }

  getCovidSymptoms(){
    this.covidSympList = [];
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.get(this.symptomUrl, { headers: headers }).subscribe(
      data => {
        var obj = JSON.parse(JSON.stringify(data));
        if(obj !=undefined){
          for(var i = 0; i < obj.covid_symptoms.length;i++){
            this.covidSympList.push({
              symp_id:obj.covid_symptoms[i].covid_symp_id,
              symp_desc:obj.covid_symptoms[i].description,
            });
          }
          this.symptomId= this.covidSympList[0].symp_id;
        }
      })
  }

  addSymptoms(){
    var symp_desc;
    var res = this.covidSympList.filter(o => o.symp_id == this.symptomId);
    if(res.length != 0){
      symp_desc = res[0].symp_desc;
    }

    this.dailySympList.push({
      symp_id:this.symptomId,
      symptom:symp_desc,
      severity: this.severity,
      date:this.currentDate,
    });
  }

  selectDate(date) {
    this.date = date;
  }

  changeIntakeSession(data) {
    this.noData = false;
    var sendData = {
      client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
      relation_id: this.relationId,
      sub_rel_id: this.subRelId,
      time_string: data
    }
    if (data != null && data.length != 0 && data != undefined) {
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.medPresUrl, sendData , { headers: headers }).subscribe(
        data => {
          this.medicineHistList = [];
          var med_details = JSON.parse(JSON.stringify(data));
          if (med_details.medicines != null && med_details.medicines.length != 0) {
            for (var i = 0; i < med_details.medicines.length; i++) {
              this.medicineHistList.push({
                drug_id: med_details.medicines[i].drug_id,
                drug_name: med_details.medicines[i].drug_name,
                quantity: med_details.medicines[i].quantity,
                short_form: med_details.medicines[i].short_form,
              });
            }
          } else {
            this.noData = true;
            this.medicineHistList = [];
          }
        },
        error => {
        })
    } 
  }

  deleteDrug(Drug_id, ind) {
    for (var i = 0; i < this.medicineHistList.length; i++) {
      if (this.medicineHistList[i].drug_id == Drug_id) {
        this.medicineHistList.splice(i, 1);
        break;
      }
    }
  }

  getSleepPatterns(){
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.get(this.sleepUrl, { headers: headers }).subscribe(
      data => {
        var obj = JSON.parse(JSON.stringify(data));
        if(obj !=undefined){
          for(var i=0;i<obj.sleep_types.length;i++){
            this.sleepPatternList.push({
              sleep_id:obj.sleep_types[i].sleep_id,
              sleep_desc:obj.sleep_types[i].description,
            });
          }
          this.sleepId= this.sleepPatternList[0].sleep_id;
        }
      });
  }

  calculateTimeDiff(){
    if(this.wakeStart2 !=undefined){
      var startDate = new Date(0, 0, 0, this.sleepStart1, this.sleepStart2, 0);
      var endDate = new Date(0, 0, 0, this.wakeStart1, this.wakeStart2, 0);
      var diff = endDate.getTime() - startDate.getTime();
      var hours = Math.floor(diff / 1000 / 60 / 60);
      diff -= hours * 1000 * 60 * 60;
      var minutes = Math.floor(diff / 1000 / 60);
      if (hours < 0)
        hours = hours + 12;
      this.sleepDuration =(hours <= 9 ? "0" : "") + hours + ":" + (minutes <= 9 ? "0" : "") + minutes;
    }
  }

  addSleepPattern(){
    if(this.sleepStart1 == undefined || this.sleepStart2 == undefined || this.wakeStart1 == undefined  || this.wakeStart2 == undefined){
      this.toastr.error(Message_data.enterSleepDur);
    } else if(this.sleepId == undefined){
      this.toastr.error(Message_data.sltSleepPattern);
    } else {
      var sduration = this.sleepStart1+":"+this.sleepStart2+" "+this.sleepSession;
      var wduration = this.wakeStart1+":"+this.wakeStart2+" "+this.wakeSession;
      var spattern;
      for(var i=0;i<this.sleepPatternList.length;i++){
        if(this.sleepId == this.sleepPatternList[i].sleep_id){
          spattern = this.sleepPatternList[i].sleep_desc;
        }
      }
      this.sleepTimeList.push({
        bedtime: sduration,
        wakeup: wduration,
        duration: this.sleepDuration,
        pattern: spattern,
      })
    }
  }

  getExercise() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'gen/exes',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          if (obj != null) {
            this.phyExerList = obj.exercises;
          }
        },error => {
            this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  changeExercise(exer) {
    if (exer == "Yoga") {
      this.yogaFlag = false;
    } else {
      this.yogaCheck = [];
      this.yogaFlag = true;    }
  }

  getYoga() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'gen/ysna',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.yogaList = obj.yogasanas;
        },error => {
            this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  changeYoga(valuecard, event) {
    if (event == true) {
      this.sendYogaData.push(valuecard);
    } else {
      for (var i = 0; this.sendYogaData.length; i++) {
        if (this.sendYogaData[i] == valuecard) {
          this.sendYogaData.splice(i, 1);
          break;
        }
      }
    }
  }

  getFoodCategory() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'lifestyle/fcat',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.foodCategory = obj.food_categories;
        },error => {});
  }

  changeFoodType(session) {
    if (session == "Morning") {
      this.morMeasure = "";
      this.morWeight = "";
      this.morEnegry = "";
      this.morFoodQty = undefined;
      this.morSubFType = undefined;
    } else if (session == "breakfast") {
      this.breakMeasure = "";
      this.breakWeight = "";
      this.breakEnergy = "";
      this.breakFoodQty = undefined;
      this.breakSubFType = undefined;
    } else if (session == "lunch") {
      this.lunchMeasure = "";
      this.lunchWeight = "";
      this.lunchEnergy = "";
      this.lunchFoodQty = undefined;
      this.lunchSubFType = undefined;
    } else if (session == "eve") {
      this.eveMeasure = "";
      this.eveWeight = "";
      this.eveEnergy = "";
      this.eveFoodQty = undefined;
      this.eveSubFType = undefined;
    } else if (session == "dinner") {
      this.dinnerMeasure = "";
      this.dinnerWeight = "";
      this.dinnerEnergy = "";
      this.dinnerFoodQty = undefined;
      this.dinnerSubFType = undefined;
    } else if (session == "night") {
      this.nightMeasure = "";
      this.nightWeight = "";
      this.nightEnergy = "";
      this.nightFoodQty = undefined;
      this.nightSubFType = undefined;
    }
    var type_id = null, food_ty = null;
    for (var i = 0; i < this.foodCategory.length; i++) {
      if (session == "Morning") {
        if (this.morFCategory == this.foodCategory[i].description) {
          type_id = this.foodCategory[i].type_id;
        }
        food_ty = this.morFType;
      } else if (session == "breakfast") {
        if (this.breakFCategory == this.foodCategory[i].description) {
          type_id = this.foodCategory[i].type_id;
        }
        food_ty = this.breakFType;
      } else if (session == "midday") {
        if (this.middayFCategory == this.foodCategory[i].description) {
          type_id = this.foodCategory[i].type_id;
        }
        food_ty = this.middayFType;
      } else if (session == "lunch") {
        if (this.lunchFCategory == this.foodCategory[i].description) {
          type_id = this.foodCategory[i].type_id;
        }
        food_ty = this.lunchFType;
      } else if (session == "eve") {
        if (this.eveFCategory == this.foodCategory[i].description) {
          type_id = this.foodCategory[i].type_id;
        }
        food_ty = this.eveFType;
      } else if (session == "dinner") {
        if (this.dinnerFCategory == this.foodCategory[i].description) {
          type_id = this.foodCategory[i].type_id;
        }
        food_ty = this.dinnerFType;
      } else if (session == "night") {
        if (this.nightFCategory == this.foodCategory[i].description) {
          type_id = this.foodCategory[i].type_id;
        }
        food_ty = this.nightFType;
      }
    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'lifestyle/fstypes', {
        food_category: type_id,
        food_type: food_ty
      },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          if (session == "Morning") {
            this.morSubfoodType = obj.food_subtypes;
          } else if (session == "breakfast") {
            this.breakSubfoodType = obj.food_subtypes;
          } else if (session == "midday") {
            this.middaySubfoodType = obj.food_subtypes;
          } else if (session == "lunch") {
            this.lunchSubfoodType = obj.food_subtypes;
          } else if (session == "eve") {
            this.eveSubfoodType = obj.food_subtypes;
          } else if (session == "dinner") {
            this.dinnerSubfoodType = obj.food_subtypes;
          } else if (session == "night") {
            this.nightSubfoodType = obj.food_subtypes;
          }
        },
        error => {
        }
      )
  }

  changeFoodSubType(foodsub, session) {
    this.calculateQuantity(foodsub, session);
    if (session == "Morning") {
      for (var i = 0; i < this.morSubfoodType.length; i++) {
        if (this.morSubFType == this.morSubfoodType[i].description) {
          this.morFoodQty = this.morSubfoodType[i].quantity;
          this.morMeasure = this.morSubfoodType[i].measure;
          this.morWeight = this.morSubfoodType[i].weight;
          this.morEnegry = this.morSubfoodType[i].energy;
        }
      }
    } else if (session == "breakfast") {
      for (var i = 0; i < this.breakSubfoodType.length; i++) {
        if (this.breakSubFType == this.breakSubfoodType[i].description) {
          this.breakFoodQty = this.breakSubfoodType[i].quantity;
          this.breakMeasure = this.breakSubfoodType[i].measure;
          this.breakWeight = this.breakSubfoodType[i].weight;
          this.breakEnergy = this.breakSubfoodType[i].energy;
        }
      }
    } else if (session == "midday") {
      for (var i = 0; i < this.middaySubfoodType.length; i++) {
        if (this.middaySubFType == this.middaySubfoodType[i].description) {
          this.middayFoodQty = this.middaySubfoodType[i].quantity;
          this.middayMeasure = this.middaySubfoodType[i].measure;
          this.middayWeight = this.middaySubfoodType[i].weight;
          this.middayEnergy = this.middaySubfoodType[i].energy;
        }
      }
    } else if (session == "lunch") {
      for (var i = 0; i < this.lunchSubfoodType.length; i++) {
        if (this.lunchSubFType == this.lunchSubfoodType[i].description) {
          this.lunchFoodQty = this.lunchSubfoodType[i].quantity;
          this.lunchMeasure = this.lunchSubfoodType[i].measure;
          this.lunchWeight = this.lunchSubfoodType[i].weight;
          this.lunchEnergy = this.lunchSubfoodType[i].energy;
        }
      }
    } else if (session == "eve") {
      for (var i = 0; i < this.eveSubfoodType.length; i++) {
        if (this.eveSubFType == this.eveSubfoodType[i].description) {
          this.eveFoodQty = this.eveSubfoodType[i].quantity;
          this.eveMeasure = this.eveSubfoodType[i].measure;
          this.eveWeight = this.eveSubfoodType[i].weight;
          this.eveEnergy = this.eveSubfoodType[i].energy;
        }
      }
    } else if (session == "dinner") {
      for (var i = 0; i < this.dinnerSubfoodType.length; i++) {
        if (this.dinnerSubFType == this.dinnerSubfoodType[i].description) {
          this.dinnerFoodQty = this.dinnerSubfoodType[i].quantity;
          this.dinnerMeasure = this.dinnerSubfoodType[i].measure;
          this.dinnerWeight = this.dinnerSubfoodType[i].weight;
          this.dinnerEnergy = this.dinnerSubfoodType[i].energy;
        }
      }
    } else if (session == "night") {
      for (var i = 0; i < this.nightSubfoodType.length; i++) {
        if (this.nightSubFType == this.nightSubfoodType[i].description) {
          this.nightFoodQty = this.nightSubfoodType[i].quantity;
          this.nightMeasure = this.nightSubfoodType[i].measure;
          this.nightWeight = this.nightSubfoodType[i].weight;
          this.nightEnergy = this.nightSubfoodType[i].energy;
        }
      }
    }
  }

  changeFoodCategory(session) {
    if (session == "Morning") {
      this.morMeasure = "";
      this.morWeight = "";
      this.morEnegry = "";
      this.morFoodQty = undefined;
      this.morSubfoodType = [];
    } else if (session == "breakfast") {
      this.breakFoodQty = undefined;
      this.breakMeasure = "";
      this.breakWeight = "";
      this.breakEnergy = "";
      this.breakSubfoodType = [];
    } else if (session == "midday") {
      this.middayFoodQty = undefined;
      this.middayMeasure = "";
      this.middayWeight = "";
      this.middayEnergy = "";
      this.middaySubfoodType = [];
    } else if (session == "lunch") {
      this.lunchFoodQty = undefined;
      this.lunchMeasure = "";
      this.lunchWeight = "";
      this.lunchEnergy = "";
      this.lunchSubfoodType = [];
    } else if (session == "eve") {
      this.eveFoodQty = undefined;
      this.eveMeasure = "";
      this.eveWeight = "";
      this.eveEnergy = "";
      this.eveSubfoodType = [];
    } else if (session == "dinner") {
      this.dinnerFoodQty = undefined;
      this.dinnerMeasure = "";
      this.dinnerWeight = "";
      this.dinnerEnergy = "";
      this.dinnerSubfoodType = [];
    } else if (session == "night") {
      this.nightFoodQty = undefined;
      this.nightMeasure = "";
      this.nightWeight = "";
      this.nightEnergy = "";
      this.nightSubfoodType = [];
    }
    var type_id = null;
    for (var i = 0; i < this.foodCategory.length; i++) {
      if (session == "Morning") {
        if (this.morFCategory == this.foodCategory[i].description) {
          type_id = this.foodCategory[i].type_id;
        }
      } else if (session == "breakfast") {
        if (this.breakFCategory == this.foodCategory[i].description) {
          type_id = this.foodCategory[i].type_id;
        }
      } else if (session == "midday") {
        if (this.middayFCategory == this.foodCategory[i].description) {
          type_id = this.foodCategory[i].type_id;
        }
      } else if (session == "lunch") {
        if (this.lunchFCategory == this.foodCategory[i].description) {
          type_id = this.foodCategory[i].type_id;
        }
      } else if (session == "eve") {
        if (this.eveFCategory == this.foodCategory[i].description) {
          type_id = this.foodCategory[i].type_id;
        }
      } else if (session == "dinner") {
        if (this.dinnerFCategory == this.foodCategory[i].description) {
          type_id = this.foodCategory[i].type_id;
        }
      } else if (session == "night") {
        if (this.nightFCategory == this.foodCategory[i].description) {
          type_id = this.foodCategory[i].type_id;
        }
      }
    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'lifestyle/ftypes', {
        food_category: type_id,
      },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          if (session == "Morning") {
            this.morFoodType = obj.food_types;
          } else if (session == "breakfast") {
            this.breakFoodType = obj.food_types;
          } else if (session == "midday") {
            this.middayFoodType = obj.food_types;
          } else if (session == "lunch") {
            this.lunchFoodType = obj.food_types;
          } else if (session == "eve") {
            this.eveFoodType = obj.food_types;
          } else if (session == "dinner") {
            this.dinnerFoodType = obj.food_types;
          } else if (session == "night") {
            this.nightFoodType = obj.food_types;
          }
        },
        error => {
        }
      )
  }

  addDietMorn() {
    if (this.morFCategory != undefined && this.morFType != undefined && this.morSubFType != undefined && this.morFoodQty != undefined) {
      this.morningFlag = false;
      var flag = true;
      if (this.dietMorListData.length != 0) {
        for (var i = 0; i < this.dietMorListData.length; i++) {
          if (this.dietMorListData[i].description == this.morSubFType) {
            flag = false;
            this.toastr.error(Message_data.alrdyExist);
            break;
          }
        }
      }
      if (flag == true) {
        var food_id = undefined;
        for (var i = 0; i < this.morSubfoodType.length; i++) {
          if (this.morSubFType.trim() == this.morSubfoodType[i].description.trim()) {
            food_id = this.morSubfoodType[i].food_item_id;
          }
        }
        this.dietMorListData.push({
          category: this.morFCategory,
          food_sub_type: this.morFType,
          description: this.morSubFType,
          quantity: this.morFoodQty,
          measure: this.morMeasure,
          weight: this.morWeight,
          energy: this.morEnegry,
          food_item_id: food_id,
          Retrieval: "No"
        });
        if (this.dietMorListData.length == 0) {
          if (this.caloriesData == "0") {
            this.caloriesData = this.morEnegry;
            this.calculateCalories();
          } else {
            var tot = parseFloat(this.caloriesData) + parseFloat(this.morEnegry);
            this.caloriesData = tot.toString();
            this.calculateCalories();
          }
        } else {
          if (this.caloriesData == "0") {
            this.caloriesData = this.morEnegry;
            this.calculateCalories();
          } else {
            var tot = parseFloat(this.caloriesData) + parseFloat(this.morEnegry);
            this.caloriesData = tot.toString();
            this.calculateCalories();
          }
        }
        this.morFCategory = undefined;
        this.morFType = undefined;
        this.morSubFType = undefined;
        this.morFoodQty = undefined;
        this.morMeasure = '';
        this.morWeight = '';
        this.morEnegry = '';
      }
    } else {
      this.toastr.error(Message_data.mandatory);
    }
  }

  addDietbreakfast() {
    if (this.breakFCategory != undefined && this.breakFType != undefined && this.breakSubFType != undefined && this.breakFoodQty != undefined) {
      this.breakfastFlag = false;
      var flag = true;
      if (this.dietBreakFastListData.length != 0) {
        for (var i = 0; i < this.dietBreakFastListData.length; i++) {
          if (this.dietBreakFastListData[i].description == this.breakSubFType) {
            flag = false;
            this.toastr.error(Message_data.alrdyExist);
            break;
          }
        }
      }
      if (flag == true) {
        for (var i = 0; i < this.breakSubfoodType.length; i++) {
          if (this.breakSubFType == this.breakSubfoodType[i].description) {
            var food_item_id = this.breakSubfoodType[i].food_item_id;
          }
        }
        this.dietBreakFastListData.push({
          category: this.breakFCategory,
          food_sub_type: this.breakFType,
          description: this.breakSubFType,
          quantity: this.breakFoodQty,
          measure: this.breakMeasure,
          weight: this.breakWeight,
          energy: this.breakEnergy,
          food_item_id: food_item_id,
          Retrieval: "No"
        });
        if (this.dietBreakFastListData.length == 0) {
          if (this.caloriesData == "0") {
            this.caloriesData = this.breakEnergy;
            this.calculateCalories();
          } else {
            var tot = parseFloat(this.caloriesData) + parseFloat(this.breakEnergy);
            this.caloriesData = tot.toString();
            this.calculateCalories();
          }
        } else {
          if (this.caloriesData == "0") {
            this.caloriesData = this.breakEnergy;
            this.calculateCalories();
          } else {
            var tot = parseFloat(this.caloriesData) + parseFloat(this.breakEnergy);
            this.caloriesData = tot.toString();
            this.calculateCalories();
          }
        }
        this.breakFCategory = undefined;
        this.breakFType = undefined;
        this.breakSubFType = undefined;
        this.breakFoodQty = undefined;
        this.breakMeasure = '';
        this.breakWeight = '';
        this.breakEnergy = '';
      }
    } else {
      this.toastr.error(Message_data.mandatory);
    }
  }

  addDietMidday() {
    if (this.middayFCategory != undefined || this.middaySubFType != undefined || this.middayFType != undefined || this.middayFoodQty != undefined) {
      this.middayFlag = false;
      var flag = true;
      if (this.dietMiddayListData.length != 0) {
        for (var i = 0; i < this.dietMiddayListData.length; i++) {
          if (this.dietMiddayListData[i].description == this.middaySubFType) {
            flag = false;
            this.toastr.error(Message_data.alrdyExist);
            break;
          }
        }
      }
      if (flag == true) {
        for (var i = 0; i < this.middaySubfoodType.length; i++) {
          if (this.middaySubFType == this.middaySubfoodType[i].description) {
            var food_item_id = this.middaySubfoodType[i].food_item_id;
          }
        }
        this.dietMiddayListData.push({
          category: this.middayFCategory,
          food_sub_type: this.middayFType,
          description: this.middaySubFType,
          quantity: this.middayFoodQty,
          measure: this.middayMeasure,
          weight: this.middayWeight,
          energy: this.middayEnergy,
          food_item_id: food_item_id,
          Retrieval: "No"
        });
        if (this.dietMiddayListData.length == 0) {
          if (this.caloriesData == "0") {
            this.caloriesData = this.middayEnergy;
            this.calculateCalories();
          } else {
            var tot = parseFloat(this.caloriesData) + parseFloat(this.middayEnergy);
            this.caloriesData = tot.toString();
            this.calculateCalories();
          }
        } else {
          if (this.caloriesData == "0") {
            this.caloriesData = this.middayEnergy;
            this.calculateCalories();
          } else {
            var tot = parseFloat(this.caloriesData) + parseFloat(this.middayEnergy);
            this.caloriesData = tot.toString();
            this.calculateCalories();
          }
        }
        this.middayFCategory = undefined;
        this.middayFType = undefined;
        this.middaySubFType = undefined;
        this.middayFoodQty = undefined;
        this.middayMeasure = '';
        this.middayWeight = '';
        this.middayEnergy = '';
      }
    } else {
      this.toastr.error(Message_data.mandatory);
    }
  }

  addDietLunch() {
    if (this.lunchFCategory != undefined || this.lunchFType != undefined || this.lunchSubFType != undefined || this.lunchFoodQty != undefined) {
      this.lunchFlag = false;
      var flag = true;
      if (this.dietLunchListData.length != 0) {
        for (var i = 0; i < this.dietLunchListData.length; i++) {
          if (this.dietLunchListData[i].description == this.lunchSubFType) {
            flag = false;
            this.toastr.error(Message_data.alrdyExist);
            break;
          }
        }
      }
      if (flag == true) {
        for (var i = 0; i < this.lunchSubfoodType.length; i++) {
          if (this.lunchSubFType == this.lunchSubfoodType[i].description) {
            var food_item_id = this.lunchSubfoodType[i].food_item_id;
          }
        }
        this.dietLunchListData.push({
          category: this.lunchFCategory,
          food_sub_type: this.lunchFType,
          description: this.lunchSubFType,
          quantity: this.lunchFoodQty,
          measure: this.lunchMeasure,
          weight: this.lunchWeight,
          energy: this.lunchEnergy,
          food_item_id: food_item_id,
          Retrieval: "No"
        });
        if (this.dietLunchListData.length == 0) {
          if (this.caloriesData == "0") {
            this.caloriesData = this.lunchEnergy;
            this.calculateCalories();
          } else {
            var tot = parseFloat(this.caloriesData) + parseFloat(this.lunchEnergy);
            this.caloriesData = tot.toString();
            this.calculateCalories();
          }
        } else {
          if (this.caloriesData == "0") {
            this.caloriesData = this.lunchEnergy;
            this.calculateCalories();
          } else {
            var tot = parseFloat(this.caloriesData) + parseFloat(this.lunchEnergy);
            this.caloriesData = tot.toString();
            this.calculateCalories();
          }
        }
        this.lunchFCategory = undefined;
        this.lunchFType = undefined;
        this.lunchSubFType = undefined;
        this.lunchFoodQty = undefined;
        this.lunchMeasure = '';
        this.lunchWeight = '';
        this.lunchEnergy = '';
      }
    } else {
      this.toastr.error(Message_data.mandatory);
    }
  }

  addDietEvening() {
    if (this.eveFCategory != undefined || this.eveFType != undefined || this.eveSubFType != undefined || this.eveFoodQty != undefined) {
      this.eveFlag = false;
      var flag = true;
      if (this.dietEveListData.length != 0) {
        for (var i = 0; i < this.dietEveListData.length; i++) {
          if (this.dietEveListData[i].description == this.eveSubFType) {
            flag = false;
            this.toastr.error(Message_data.alrdyExist);
            break;
          }
        }
      }
      if (flag == true) {
        for (var i = 0; i < this.eveSubfoodType.length; i++) {
          if (this.eveSubFType == this.eveSubfoodType[i].description) {
            var food_item_id = this.eveSubfoodType[i].food_item_id;
          }
        }
        this.dietEveListData.push({
          category: this.eveFCategory,
          food_sub_type: this.eveFType,
          description: this.eveSubFType,
          quantity: this.eveFoodQty,
          measure: this.eveMeasure,
          weight: this.eveWeight,
          energy: this.eveEnergy,
          food_item_id: food_item_id,
          Retrieval: "No"
        });
        if (this.dietEveListData.length == 0) {
          if (this.caloriesData == "0") {
            this.caloriesData = this.eveEnergy;
            this.calculateCalories();
          } else {
            var tot = parseFloat(this.caloriesData) + parseFloat(this.eveEnergy);
            this.caloriesData = tot.toString();
            this.calculateCalories();
          }
        } else {
          if (this.caloriesData == "0") {
            this.caloriesData = this.eveEnergy;
            this.calculateCalories();
          } else {
            var tot = parseFloat(this.caloriesData) + parseFloat(this.eveEnergy);
            this.caloriesData = tot.toString();
            this.calculateCalories();
          }
        }
        this.eveFCategory = undefined;
        this.eveFType = undefined;
        this.eveSubFType = undefined;
        this.eveFoodQty = undefined;
        this.eveMeasure = '';
        this.eveWeight = '';
        this.eveEnergy = '';
      }
    } else {
      this.toastr.error(Message_data.mandatory);
    }
  }

  addDietDinner() {
    if (this.dinnerFCategory != undefined || this.dinnerFType != undefined || this.dinnerSubFType != undefined || this.dinnerFoodQty != undefined) {
      this.dinnerFlag = false;
      var flag = true;
      if (this.dietDinnerListData.length != 0) {
        for (var i = 0; i < this.dietDinnerListData.length; i++) {
          if (this.dietDinnerListData[i].description == this.dinnerSubFType) {
            flag = false;
            this.toastr.error(Message_data.alrdyExist);
            break;
          }
        }
      }
      if (flag == true) {
        for (var i = 0; i < this.dinnerSubfoodType.length; i++) {
          if (this.dinnerSubFType == this.dinnerSubfoodType[i].description) {
            var food_item_id = this.dinnerSubfoodType[i].food_item_id;
          }
        }
        this.dietDinnerListData.push({
          category: this.dinnerFCategory,
          food_sub_type: this.dinnerFType,
          description: this.dinnerSubFType,
          quantity: this.dinnerFoodQty,
          measure: this.dinnerMeasure,
          weight: this.dinnerWeight,
          energy: this.dinnerEnergy,
          food_item_id: food_item_id,
          Retrieval: "No"
        });
        if (this.dietDinnerListData.length == 0) {
          if (this.caloriesData == "0") {
            this.caloriesData = this.dinnerEnergy;
            this.calculateCalories();
          } else {
            var tot = parseFloat(this.caloriesData) + parseFloat(this.dinnerEnergy);
            this.caloriesData = tot.toString();
            this.calculateCalories();
          }
        } else {
          if (this.caloriesData == "0") {
            this.caloriesData = this.dinnerEnergy;
            this.calculateCalories();
          } else {
            var tot = parseFloat(this.caloriesData) + parseFloat(this.dinnerEnergy);
            this.caloriesData = tot.toString();
            this.calculateCalories();
          }
        }
        this.dinnerFCategory = undefined;
        this.dinnerFType = undefined;
        this.dinnerSubFType = undefined;
        this.dinnerFoodQty = undefined;
        this.dinnerMeasure = '';
        this.dinnerWeight = '';
        this.dinnerEnergy = '';
      }
    } else {
      this.toastr.error(Message_data.mandatory);
    }
  }

  addDietNight() {
    if (this.nightFCategory != undefined || this.nightFType != undefined || this.nightSubFType != undefined || this.nightFoodQty != undefined) {
      this.nightFlag = false;
      var flag = true;
      if (this.dietNightListData.length != 0) {
        for (var i = 0; i < this.dietNightListData.length; i++) {
          if (this.dietNightListData[i].description == this.nightSubFType) {
            flag = false;
            this.toastr.error(Message_data.alrdyExist);
            break;
          }
        }
      }
      if (flag == true) {
        for (var i = 0; i < this.nightSubfoodType.length; i++) {
          if (this.nightSubFType == this.nightSubfoodType[i].description) {
            var food_item_id = this.nightSubfoodType[i].food_item_id;
          }
        }
        this.dietNightListData.push({
          category: this.nightFCategory,
          food_sub_type: this.nightFType,
          description: this.nightSubFType,
          quantity: this.nightFoodQty,
          measure: this.nightMeasure,
          weight: this.nightWeight,
          energy: this.nightEnergy,
          food_item_id: food_item_id,
          Retrieval: "No"
        });
        if (this.dietNightListData.length == 0) {
          if (this.caloriesData == "0") {
            this.caloriesData = this.nightEnergy;
            this.calculateCalories();
          } else {
            var tot = parseFloat(this.caloriesData) + parseFloat(this.nightEnergy);
            this.caloriesData = tot.toString();
            this.calculateCalories();
          }
        } else {
          if (this.caloriesData == "0") {
            this.caloriesData = this.nightEnergy;
            this.calculateCalories();
          } else {
            var tot = parseFloat(this.caloriesData) + parseFloat(this.nightEnergy);
            this.caloriesData = tot.toString();
            this.calculateCalories();
          }
        }
        this.nightFCategory = undefined;
        this.nightFType = undefined;
        this.nightSubFType = undefined;
        this.nightFoodQty = undefined;
        this.nightMeasure = '';
        this.nightWeight = '';
        this.nightEnergy = '';
      }
    } else {
      this.toastr.error(Message_data.mandatory);
    }
  }

  deleteDietNight(data) {
    for (var i = 0; i < this.dietNightListData.length; i++) {
      if (this.dietNightListData[i].description == data) {
        var a = parseFloat(this.caloriesData) - parseFloat(this.dietNightListData[i].energy);
        this.caloriesData = a
        this.calculateCalories();
        this.dietNightListData.splice(i, 1);
        break;
      }
    }
    if (this.dietNightListData.length != "0") {
      this.nightFlag = false;
    } else {
      this.nightFlag = true;
    }
  }

  deleteDietDinner(data) {
    for (var i = 0; i < this.dietDinnerListData.length; i++) {
      if (this.dietDinnerListData[i].description == data) {
        var a = parseFloat(this.caloriesData) - parseFloat(this.dietDinnerListData[i].energy);
        this.caloriesData = a
        this.calculateCalories();
        this.dietDinnerListData.splice(i, 1);
        break;
      }
    }
    if (this.dietDinnerListData.length != "0") {
      this.dinnerFlag = false;
    } else {
      this.dinnerFlag = true;
    }
  }

  deleteDietEvening(data) {
    for (var i = 0; i < this.dietEveListData.length; i++) {
      if (this.dietEveListData[i].description == data) {
        var a = parseFloat(this.caloriesData) - parseFloat(this.dietEveListData[i].energy);
        this.caloriesData = a
        this.calculateCalories();
        this.dietEveListData.splice(i, 1);
        break;
      }
    }
    if (this.dietEveListData.length != "0") {
      this.eveFlag = false;
    } else {
      this.eveFlag = true;
    }
  }

  deleteDietLunch(data) {
    for (var i = 0; i < this.dietLunchListData.length; i++) {
      if (this.dietLunchListData[i].description == data) {
        var a = parseFloat(this.caloriesData) - parseFloat(this.dietLunchListData[i].energy);
        this.caloriesData = a
        this.calculateCalories();
        this.dietLunchListData.splice(i, 1);
        break;
      }
    }
    if (this.dietLunchListData.length != 0) {
      this.lunchFlag = false;
    } else {
      this.lunchFlag = true;
    }
  }

  deleteDietMidday(data) {
    for (var i = 0; i < this.dietMiddayListData.length; i++) {
      if (this.dietMiddayListData[i].description == data) {
        var a = parseFloat(this.caloriesData) - parseFloat(this.dietMiddayListData[i].energy);
        this.caloriesData = a
        this.calculateCalories();
        this.dietMiddayListData.splice(i, 1);
        break;
      }
    }
    if (this.dietMiddayListData.length != "0") {
      this.middayFlag = false;
    } else {
      this.middayFlag = true;
    }
  }

  deleteDietBreakfast(data) {
    for (var i = 0; i < this.dietBreakFastListData.length; i++) {
      if (this.dietBreakFastListData[i].description == data) {
        var a = parseFloat(this.caloriesData) - parseFloat(this.dietBreakFastListData[i].energy);
        this.caloriesData = a
        this.calculateCalories();
        this.dietBreakFastListData.splice(i, 1);
        break;
      }
    }
    if (this.dietBreakFastListData.length != "0") {
      this.breakfastFlag = false;
    } else {
      this.breakfastFlag = true;
    }
  }

  deleteDietMorning(data) {
    for (var i = 0; i < this.dietMorListData.length; i++) {
      if (this.dietMorListData[i].description == data) {
        var a = parseFloat(this.caloriesData) - parseFloat(this.dietMorListData[i].energy);
        this.caloriesData = a
        this.calculateCalories();
        this.dietMorListData.splice(i, 1);
        break;
      }
    }
    if (this.dietMorListData.length != "0") {
      this.morningFlag = false;
    } else {
      this.morningFlag = true;
    }
  }

  calculateQuantity(data, session) {
    var quantity = null, measure = null, weight = null, energy = null;
    if (session == "Morning") {
      for (var i = 0; i < this.morSubfoodType.length; i++) {
        if (data == this.morSubfoodType[i].description) {
          quantity = this.morSubfoodType[i].quantity;
          measure = this.morSubfoodType[i].measure;
          weight = this.morSubfoodType[i].weight;
          energy = this.morSubfoodType[i].energy;
        }
      }
    } else if (session == "breakfast") {
      for (var i = 0; i < this.breakSubfoodType.length; i++) {
        if (data == this.breakSubfoodType[i].description) {
          quantity = this.breakSubfoodType[i].quantity;
          measure = this.breakSubfoodType[i].measure;
          weight = this.breakSubfoodType[i].weight;
          energy = this.breakSubfoodType[i].energy;
        }
      }
    } else if (session == "midday") {
      for (var i = 0; i < this.middaySubfoodType.length; i++) {
        if (data == this.middaySubfoodType[i].description) {
          quantity = this.middaySubfoodType[i].quantity;
          measure = this.middaySubfoodType[i].measure;
          weight = this.middaySubfoodType[i].weight;
          energy = this.middaySubfoodType[i].energy;
        }
      }
    } else if (session == "lunch") {
      for (var i = 0; i < this.lunchSubfoodType.length; i++) {
        if (data == this.lunchSubfoodType[i].description) {
          quantity = this.lunchSubfoodType[i].quantity;
          measure = this.lunchSubfoodType[i].measure;
          weight = this.lunchSubfoodType[i].weight;
          energy = this.lunchSubfoodType[i].energy;
        }
      }
    } else if (session == "eve") {
      for (var i = 0; i < this.eveSubfoodType.length; i++) {
        if (data == this.eveSubfoodType[i].description) {
          quantity = this.eveSubfoodType[i].quantity;
          measure = this.eveSubfoodType[i].measure;
          weight = this.eveSubfoodType[i].weight;
          energy = this.eveSubfoodType[i].energy;
        }
      }
    } else if (session == "dinner") {
      for (var i = 0; i < this.dinnerSubfoodType.length; i++) {
        if (data == this.dinnerSubfoodType[i].description) {
          quantity = this.dinnerSubfoodType[i].quantity;
          measure = this.dinnerSubfoodType[i].measure;
          weight = this.dinnerSubfoodType[i].weight;
          energy = this.dinnerSubfoodType[i].energy;
        }
      }
    } else if (session == "night") {
      for (var i = 0; i < this.nightSubfoodType.length; i++) {
        if (data == this.nightSubfoodType[i].description) {
          quantity = this.nightSubfoodType[i].quantity;
          measure = this.nightSubfoodType[i].measure;
          weight = this.nightSubfoodType[i].weight;
          energy = this.nightSubfoodType[i].energy;
        }
      }
    }
    if (quantity == "1" || quantity == "2" || quantity == "3" || quantity == "4" || quantity == "5" ||
      quantity == "6" || quantity == "7" || quantity == "8" || quantity == "9" || quantity == "10" ||
      quantity == "11" || quantity == "12" || quantity == "13" || quantity == "14" || quantity == "15" ||
      quantity == "16" || quantity == "17" || quantity == "20" || quantity == "21" || quantity == "22" ||
      quantity == "24" || quantity == "26" || quantity == "28" || quantity == "30" || quantity == "32" ||
      quantity == "40" || quantity == "42" || quantity == "48" || quantity == "60") {
      this.calcNumber = 1 / parseFloat(quantity);
    } else if (quantity == "1/8") {
      this.calcNumber = 8;
    } else if (quantity == "1/4") {
      this.calcNumber = 4;
    } else if (quantity == "1/3") {
      this.calcNumber = 3;
    } else if (quantity == "1/2") {
      this.calcNumber = 2;
    } else if (quantity == "3/4") {
      this.calcNumber = 1.34;
    } else if (quantity == "11/2") {
      this.calcNumber = 0.67;
    } else if (quantity == "21/2") {
      this.calcNumber = 0.4;
    } else if (quantity == "21/8") {
      this.calcNumber = 0.47;
    } else if (quantity == "91/2") {
      this.calcNumber = 0.105;
    } else if (quantity == "2/3") {
      this.calcNumber = 1.5;
    } else if (quantity == "41/4") {
      this.calcNumber = 0.235;
    }
    this.calcQuantity = 1;
    this.calcMeasure = measure;
    this.calcWeight = parseInt((weight * this.calcNumber).toFixed(2).toString());
    this.calcEnergy = parseInt((energy * this.calcNumber).toFixed(2).toString());
  }

  changeQuantity(quantity, session) {
    var Actual_Quantity = quantity;
    if (quantity == "1/8") {
      Actual_Quantity = 0.125;
    } else if (quantity == "1/4") {
      Actual_Quantity = 0.25;
    } else if (quantity == "1/3") {
      Actual_Quantity = 0.33;
    } else if (quantity == "1/2") {
      Actual_Quantity = 0.5;
    } else if (quantity == "3/4") {
      Actual_Quantity = 0.75;
    } else if (quantity == "11/2") {
      Actual_Quantity = 1.5;
    } else if (quantity == "21/2") {
      Actual_Quantity = 2.5;
    } else if (quantity == "21/8") {
      Actual_Quantity = 2.125;
    } else if (quantity == "91/2") {
      Actual_Quantity = 9.5;
    } else if (quantity == "2/3") {
      Actual_Quantity = 0.67;
    } else if (quantity == "41/4") {
      Actual_Quantity = 4.25;
    }
    if (session == "Morning") {
      this.morMeasure = this.calcMeasure;
      this.morWeight = Math.round(this.calcWeight * parseFloat(Actual_Quantity));
      this.morEnegry = Math.round(this.calcEnergy * parseFloat(Actual_Quantity));
    } else if (session == "breakfast") {
      this.breakMeasure = this.calcMeasure;
      this.breakWeight = Math.round(this.calcWeight * parseFloat(Actual_Quantity));
      this.breakEnergy = Math.round(this.calcEnergy * parseFloat(Actual_Quantity));
    } else if (session == "midday") {
      this.middayMeasure = this.calcMeasure;
      this.middayWeight = Math.round(this.calcWeight * parseFloat(Actual_Quantity));
      this.middayEnergy = Math.round(this.calcEnergy * parseFloat(Actual_Quantity));
    } else if (session == "lunch") {
      this.lunchMeasure = this.calcMeasure;
      this.lunchWeight = Math.round(this.calcWeight * parseFloat(Actual_Quantity));
      this.lunchWeightEnergy = Math.round(this.calcEnergy * parseFloat(Actual_Quantity));
    } else if (session == "eve") {
      this.eveMeasure = this.calcMeasure;
      this.eveWeight = Math.round(this.calcWeight * parseFloat(Actual_Quantity));
      this.eveEnergy = Math.round(this.calcEnergy * parseFloat(Actual_Quantity));
    } else if (session == "dinner") {
      this.dinnerMeasure = this.calcMeasure;
      this.dinnerWeight = Math.round(this.calcWeight * parseFloat(Actual_Quantity));
      this.dinnerEnergy = Math.round(this.calcEnergy * parseFloat(Actual_Quantity));
    } else if (session == "night") {
      this.nightMeasure = this.calcMeasure;
      this.nightWeight = Math.round(this.calcWeight * parseFloat(Actual_Quantity));
      this.nightEnergy = Math.round(this.calcEnergy * parseFloat(Actual_Quantity));
    }
  }

  calculateCalories() {
    var col_dtaa = this.caloriesData;
    this.totalCalories = Math.ceil(parseInt(col_dtaa));
    this.kcalCalories();
  }

  kcalCalories() {
    var Cal_req = this.calories;
    var Cal_req_00 = parseInt(Cal_req);
    var Cal_max = Cal_req_00 + 500;
    var Tot_Cal = Math.ceil(parseInt(this.totalCalories));
    var Tot_Cal_00 = 0;
    if (Tot_Cal != 0) {
      Tot_Cal_00 = Tot_Cal;
    }
  }

  saveData(){
    var saveFlag = true;
    var exercise_id = null, yoga_flag = null, yoga_txt = undefined, nxt_vis = undefined, dit_fol_flg = null, dit_avoid_flg = null, type_id = null;
    var drug_hist_list = [];
    var Send_Night_Data = [];
    var Send_Even_Data = [];
    var Send_Lunch_Data = [];
    var Send_Midday_Data = [];
    var Send_Break_Data = [];
    var Send_Morning_Data = [];
    var Send_Dinner_Data = [];
    if(this.medicineHistList.length !=0){
      if (this.header_footer_flag == true) {
        if (this.timeStart3 == "PM") {
          if (this.timeStart1 != "12") {
            this.createdTime = parseInt(this.timeStart1) + 12 + ":" + this.timeStart2;
          } else {
            this.createdTime = "12" + ":" + this.timeStart2;
          }
        } else {
          this.createdTime = this.timeStart1 + ":" + this.timeStart2;
        }
      } else {
          this.createdTime = this.timeStart1;
      }
      for (var i = 0; i < this.medicineHistList.length; i++) {
        if (this.medicineHistList[i].quantity == "" || this.medicineHistList[i].quantity == "0") {
          this.toastr.error(Message_data.validQty);
          saveFlag = false;
        }
        drug_hist_list.push({
          drug_id: this.medicineHistList[i].drug_id,
          quantity: this.medicineHistList[i].quantity,
          intake: this.visitSession,
          created_date: this.date,
          created_time: (this.createdTime),
        });
      }
    }
    var sleeptime;
    if(this.sleepStart1 != undefined){
      sleeptime = this.sleepStart1+":"+this.sleepStart2+" "+this.sleepSession;
    }
    var waketime;
    if(this.wakeStart1 != undefined){
      waketime=this.wakeStart1+":"+this.wakeStart2+" "+this.wakeSession;
    }
    if (this.dietMorListData.length != 0) {
      for (var i = 0; i < this.dietMorListData.length; i++) {
        if (this.dietMorListData[i].Retrieval == "No") {
          Send_Morning_Data.push({
            food_item_id: this.dietMorListData[i].food_item_id,
            quantity: this.dietMorListData[i].quantity,
            energy: this.dietMorListData[i].energy,
            cond: this.dietMorListData[i].cond
          });
        }
      }
      if (Send_Morning_Data.length == 0) {
        Send_Morning_Data = undefined;
      }
    }
    if (this.dietBreakFastListData.length != 0) {
      for (var i = 0; i < this.dietBreakFastListData.length; i++) {
        if (this.dietBreakFastListData[i].Retrieval == "No") {
          Send_Break_Data.push({
            food_item_id: this.dietBreakFastListData[i].food_item_id,
            quantity: this.dietBreakFastListData[i].quantity,
            energy: this.dietBreakFastListData[i].energy,
            cond: this.dietBreakFastListData[i].cond
          });
        }
      }
      if (Send_Break_Data.length == 0) {
        Send_Break_Data = undefined;
      }
    }
    if (this.dietMiddayListData.length != 0) {
      for (var i = 0; i < this.dietMiddayListData.length; i++) {
        if (this.dietMiddayListData[i].Retrieval == "No") {
          Send_Midday_Data.push({
            food_item_id: this.dietMiddayListData[i].food_item_id,
            quantity: this.dietMiddayListData[i].quantity,
            energy: this.dietMiddayListData[i].energy,
            cond: this.dietMiddayListData[i].cond
          });
        }
      }
      if (Send_Midday_Data.length == 0) {
        Send_Midday_Data = undefined;
      }
    }
    if (this.dietLunchListData.length != 0) {
      for (var i = 0; i < this.dietLunchListData.length; i++) {
        if (this.dietLunchListData[i].Retrieval == "No") {
          Send_Lunch_Data.push({
            food_item_id: this.dietLunchListData[i].food_item_id,
            quantity: this.dietLunchListData[i].quantity,
            energy: this.dietLunchListData[i].energy,
            cond: this.dietLunchListData[i].cond
          });
        }
      }
      if (Send_Lunch_Data.length == 0) {
        Send_Lunch_Data = undefined;
      }
    }
    if (this.dietEveListData.length != 0) {
      for (var i = 0; i < this.dietEveListData.length; i++) {
        if (this.dietEveListData[i].Retrieval == "No") {
          Send_Even_Data.push({
            food_item_id: this.dietEveListData[i].food_item_id,
            quantity: this.dietEveListData[i].quantity,
            energy: this.dietEveListData[i].energy,
            cond: this.dietEveListData[i].cond
          });
        }
      }
      if (Send_Even_Data.length == 0) {
        Send_Even_Data = undefined;
      }
    }
    if (this.dietDinnerListData.length != 0) {
      for (var i = 0; i < this.dietDinnerListData.length; i++) {
        if (this.dietDinnerListData[i].Retrieval == "No") {
          Send_Dinner_Data.push({
            food_item_id: this.dietDinnerListData[i].food_item_id,
            quantity: this.dietDinnerListData[i].quantity,
            energy: this.dietDinnerListData[i].energy,
            cond: this.dietDinnerListData[i].cond
          });
        }
      }
      if (Send_Dinner_Data.length == 0) {
        Send_Dinner_Data = undefined;
      }
    }
    if (this.dietNightListData.length != 0) {
      for (var i = 0; i < this.dietNightListData.length; i++) {
        if (this.dietNightListData[i].Retrieval == "No") {
          Send_Night_Data.push({
            food_item_id: this.dietNightListData[i].food_item_id,
            quantity: this.dietNightListData[i].quantity,
            energy: this.dietNightListData[i].energy,
            cond: this.dietNightListData[i].cond
          });
        }
      }
      if (Send_Night_Data.length == 0) {
        Send_Night_Data = undefined;
      }
    }
    var tcal_to_txt = null;
    if (this.totalCalories != "") {
      tcal_to_txt = this.totalCalories;
    } else {
      tcal_to_txt = "0"
    }
    if (this.exercise != undefined) {
      if (this.exercise == "Yoga") {
        yoga_txt = this.sendYogaData;
        yoga_flag = "yes";
      } else {
        yoga_flag = "no";
      }
    } else {
      yoga_flag = "no";
    }
    for (var i = 0; i < this.phyExerList.length; i++) {
      if (this.exercise == this.phyExerList[i].description) {
        exercise_id = this.phyExerList[i].exercise_id;
      }
    }
    var send_data={
      client_id:this.clientRegId,
      relation_id: this.relationId,
      sub_rel_id: this.subRelId,
      symptoms:this.dailySympList,
      temparature:this.temperature,
      bp: this.BP01+"/"+this.BP02,
      spo2:this.spo2,
      pulse: this.pulse,
      resp_rate: this.respiratoryRate,
      sleep_time:sleeptime,
      wake_time:waketime,
      sleep_dur: this.sleepDuration,
      sleep_type: this.sleepId,
      medicines: drug_hist_list,
      dplan_morning: Send_Morning_Data,
      dplan_breakfast: Send_Break_Data,
      dplan_midday: Send_Midday_Data,
      dplan_lunch: Send_Lunch_Data,
      dplan_evening: Send_Even_Data,
      dplan_dinner: Send_Dinner_Data,
      dplan_night: Send_Night_Data,
      cal_required: this.calories,
      cal_intake: tcal_to_txt,
      country: ipaddress.country_code,
      exec_id: exercise_id,
      exec_desc: this.exercise,
      exec_dur: this.exerDuration,
      exec_freq: this.exerFrequency,
      yoga_asanas: yoga_txt,
      yogaflag: yoga_flag,
    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "lifestyle/sctrack/", send_data, { headers: headers }).subscribe(
      data => {
        if(data["key"] != 0){
          var dataval = JSON.parse(JSON.stringify(data));
          if (dataval != null && dataval != undefined) {
            this.toastr.success(Message_data.dataSavedSuccess);
            this.clearData();
          }
        }
      });
  }

  getCovidHistory(){
    const dialogRef = this.dialog.open(ClientPostcovidTrackComponent, {
      width: '70%',
      height: '500px',
      data:{
        client_reg_id: this.clientRegId,
            relation_id: this.relationId,
            sub_rel_id: this.subRelId,
          }
    });
  }

  clearData(){
    this.dailySympList = [];
    this.temperature = "";
    this.BP01 = "";
    this.BP02 = "";
    this.spo2 = "";
    this.pulse = "";
    this.respiratoryRate = "";
    this.dietMorListData = [];
    this.dietBreakFastListData = [];
    this.dietLunchListData = [];
    this.dietEveListData = [];
    this.dietDinnerListData = [];
    this.dietNightListData = [];
    this.timeStart1 = "";
    this.timeStart2 = "";
    this.timeStart3 = "";
    this.exercise = "";
    this.exerDuration = "";
    this.ngOnInit();
  }
}
