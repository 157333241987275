<div class="row" style="width:100%;margin:0 auto;">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white">
              <div class="headerCover">
                <div class="headerTilte app_list_header">
                  <h5 class="m-0" class="mainHeadingStyle">Tests</h5>
                </div>
                <div class="headerButtons">
                  <img *ngIf="newbutton" class="saveimgbtn_inpatinfo" src="../../../assets/ui_icons/buttons/new_button.svg"
                    (click)="testCreate()" />
                </div>
              </div>
            </mdb-card-header>
            <mdb-card-body>
            
          
      
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <p class="nodata" *ngIf="packageData.length == 0">No Prescription(s) Found</p>
      
                  <table *ngIf="packageData.length" mdbTable datatable [dtOptions]="dtOptions1"
                    class="table table-nowrap table-sm dataTable" id="diaTestTable">
                    <thead>
                      <tr>
                        <th>profile name</th>
                        <th>Description</th>
                        <th>Price </th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let package of packageData; let i = index">
                        <!-- <td style="font-size: 12px;" (click)="packageView(package.diag_tests_id)">{{package.package_name}}
                        </td>
                        <td style="font-size: 12px;" (click)="packageView(package.diag_tests_id)">{{package.package_desc}}
                        </td>
                        <td style="font-size: 12px;" (click)="packageView(package.diag_tests_id)">{{package.price}}
                        </td>
      
                        <td style="font-size: 12px;text-align: center;">
                          <img class="deleteimg" (click)="packageEdit(package)"
                            src="../../../assets/ui_icons/buttons/pencil_icon.svg" /> &nbsp;
                          <img class="deleteimg" (click)="packageDelete(package.diag_packages_id)"
                            src="../../../assets/ui_icons/buttons/trash_icon.svg" /> -->
      
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </mdb-card-body>
          </mdb-card>
    </div>
</div>