import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Client_Helper } from '../Client_helper';
import { ClientViewService } from '../clientview/client-view.service';
import { ipaddress } from '../../ipaddress';

@Component({
  selector: 'app-client-bill-detail-view',
  templateUrl: './client-bill-detail-view.component.html',
  styleUrls: ['./client-bill-detail-view.component.css']
})
export class ClientBillDetailViewComponent implements OnInit {
 
  dtOptions: DataTables.Settings = {};
  public billId: string;
  public cgstFlag;
  public sgstFlag;
  public billDetailView = [];
  public billDetailViewFlag: boolean;
  public totalFlag: boolean;
  public advanceFlag: boolean;
  public prevBalance: string;
  public prevBalanceFlag: boolean;
  public billAmtFlag: boolean;
  public estimatedBill;
  public treatmentPlanFlag: boolean;
  public charge = [];
  public hospitalAddrFlag: boolean;
  public hospitalAddr: string;
  public clientAddr: string;
  public billAmt: string;
  public totalAmt: string;
  public cgst: string;
  public sgst: string;
  public billingDetails = [];
  public clientName: string;
  public doctorName: string;
  public doctorRegId;
  public splName;
  public billdetailview;
  public finyear;

  constructor(public clientservice: ClientViewService, public toastr: ToastrService,public http: Http,public router:Router) { 
    this.hospitalAddrFlag = true;
    this.billDetailViewFlag = true;
    this.treatmentPlanFlag = true;
    this.cgstFlag = true;
    this.sgstFlag = true;
    this.prevBalanceFlag = true;
  }
 
  ngOnInit(): void { 
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 5,
      "lengthMenu": [[5, 10, 25, 50, -1], [5, 10, 25, 50, "All"]],
      "columnDefs": [
        { "orderable": false, "targets": [0, 1, 2,3] },
      ],
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12">><"table-responsive"t><"row mt-1 d-flex"<"col-xs-12 col-sm-6 col-md-4"l><"col-xs-12 col-sm-12 col-md-4 text-center sm-order-last"i><"col-xs-12 col-sm-6 col-md-4"p>>'
    };
    this.billdetailview =Client_Helper.getclientviewbilldetail();
    this.billId=this.billdetailview[0].bill_view_id;
    this.doctorRegId = this.billdetailview[0].doc_reg_id;
    this.splName = this.billdetailview[0].spl_name;
    this.finyear= this.billdetailview[0].fin_year;
    this.getBillDetailView();    
  }

  getBillDetailView() {
    var send_data ={
      doc_reg_id:  this.doctorRegId,
      billing_id: this.billId,
      app_type:"client",
      spl_name: this.splName,
      fin_year:this.finyear,
    }
    
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/getbill/', JSON.stringify(send_data),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();

          if (obj.bills != null) {            
            if (obj.cgst != null) {
              this.cgstFlag = false;
              this.sgstFlag = false;
            }
            this.billDetailView = [];
            this.billDetailView = obj.bills;
            this.billDetailViewFlag = this.billDetailView != null && this.billDetailView[0].charge_type != null ? false : true;
            this.totalFlag = obj.bill_amount == undefined && obj.bill_amount == "" ? true : false;
            this.advanceFlag = obj.advance == undefined || obj.advance == "" ? true : false;

            if (obj.prev_balance != null) {
              this.prevBalanceFlag = false;
              this.prevBalance = parseFloat(obj.prev_balance).toFixed(2);
            } else {
              this.prevBalanceFlag = true;
            }

            this.billAmtFlag = obj.balance == undefined || obj.balance == "" ? true : false;
            this.estimatedBill = obj.estimates;
            this.treatmentPlanFlag = this.estimatedBill != "" && this.estimatedBill != undefined ? false : true;
            this.charge = [];
            this.hospitalAddrFlag = obj.hptl_name != null ? false : true;

            if (obj.created_date != undefined) {
              var get_date = obj.created_date.toString().split('-');
              var datetxt = get_date[2] + "-" + get_date[1] + "-" + get_date[0];
            }

            if (obj.first_name != undefined) {
              this.hospitalAddr = obj.address2 != null && obj.address2 != "" ? obj.address1 + ", " + obj.address2 + "," : obj.address1 + ",";

              if (obj.caddress1 != null) {
                this.clientAddr = obj.caddress2 != null && obj.caddress2 != "" ? encrypt_decript.Decript(obj.caddress1).toString() + " " + encrypt_decript.Decript(obj.caddress2).toString() + "," : encrypt_decript.Decript(obj.caddress1).toString() + ",";
              }

              var tot_amt = "0";
              if (obj.bills != null && obj.bills.length != 0) {
                for (var i = 0; i < obj.bills.length; i++) {
                  if (obj.bills[i].fee != null) {
                    tot_amt = (parseInt(tot_amt) + parseFloat(obj.bills[i].fee)).toFixed(2);
                  }
                }
              }

              if (obj.estimates != null) {
                if (obj.estimates.length != 0) {
                  for (var i = 0; i < obj.estimates.length; i++) {
                    if (obj.estimates[i].estimate != null) {
                      tot_amt = (parseFloat(tot_amt) + parseFloat(obj.estimates[i].estimate)).toFixed(2);
                    }
                  }
                }
              }

              this.billAmt = tot_amt;
              this.totalAmt = tot_amt;
              if (obj.cgst != null) {
                this.cgst = (parseFloat(this.billAmt) * (parseFloat(obj.cgst) / 100)).toFixed(2);
                this.sgst = (parseFloat(this.billAmt) * (parseFloat(obj.sgst) / 100)).toFixed(2);
              }

              this.billAmt = encrypt_decript.Decript(obj.bill_amount);

              if (obj.middle_name != null && obj.middle_name != undefined) {
                this.clientName = encrypt_decript.Decript(obj.first_name).toString() + " " + encrypt_decript.Decript(obj.middle_name).toString() + " " + encrypt_decript.Decript(obj.last_name).toString();

              } else {
                this.clientName = encrypt_decript.Decript(obj.first_name).toString() + " " + encrypt_decript.Decript(obj.last_name).toString();
              }
              if (obj.dr_middle_name != null && obj.dr_middle_name != undefined) {
                this.doctorName = "Dr." + obj.dr_first_name + " " + obj.dr_middle_name + " " + obj.dr_last_name;

              } else {
                this.doctorName = "Dr." + obj.dr_first_name + " " + obj.dr_last_name;
              }
              this.billingDetails.push({
                dr_name: "Dr." + obj.dr_first_name + " " + obj.dr_middle_name + " " + obj.dr_last_name,
                licence_code: " Reg. No: " + obj.licence_code,
                hptl_name: obj.hptl_name,
                address: this.hospitalAddr,
                location: obj.location + ", ",
                city: obj.city + " - " + obj.zipcode + ",",
                state: obj.state + " - " + obj.country + ",",
                telephone: obj.telephone,
                created_date: datetxt,
                appointment_id: obj.appointment_id,
                client_name: this.clientName,
                advance: parseFloat(obj.advance).toFixed(2),
                balance: parseFloat(Math.ceil(obj.balance).toString()).toFixed(2),
                caddress: this.clientAddr,

                clocation: obj.clocation + ", ",
                clientcity: obj.ccity + " - " + encrypt_decript.Decript(obj.czipcode).toString() + ",",
                clestare: obj.cstate + " - " + obj.ccountry + ".",
                client_reg_id: obj.client_reg_id,
                tot_amt:this.billAmt
              });
            }
          } 
        },
        error => {});
  }

  print() {
    let printContents, popupWin;
    printContents = document.getElementById('bill-print-section').innerHTML;
    popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Bill</title>
          <link rel="stylesheet" media="screen" href="">
          <style>
          @page { size: auto;  margin: 0mm; }
          .table_class {
            background-color: rgb(108, 180, 248);
          }
          tr:nth-child(even) {background-color: #f2f2f2;}
          </style>
        </head>
        <body onload="window.print();window.onfocus=function(){ window.close();}">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
  }

  back() {
    this.clientservice.sendMessage("billlist");
  }
}
