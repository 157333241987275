<div class="row">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">My documents</h5>
          </div>
          <div class="headerButtons">
            <img src="../../../assets/img/minussybl.png" (click)="uploadNew()" width="20px" height="20px" alt="" />
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
       <div class="row">
         <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
          <mat-label style="margin-top: 7px;" class="matlabel">Relation<br>
            <select disableOptionCentering class="ipcss" required [(ngModel)]="relId">
            <option [ngValue]="select" disabled>Select</option>
            <option *ngFor="let rel of relationList" value={{rel.relation_id}}>{{rel.relationship_name}}</option>
            </select>
        </mat-label>
         </div>
         <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
          <mat-label style="margin-top: 7px;" class="matlabel">Report type<br>
            <select disableOptionCentering class="ipcss " [(ngModel)]="docType">
            <option [ngValue]="select" disabled>Select</option>
            <option *ngFor="let rep of reportList" value={{rep.document_type_id}}>{{rep.description}}</option>
            </select>
        </mat-label>
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
          <mat-label style="margin-top: 7px;" class="matlabel">Specialization<br>
            <select disableOptionCentering class="ipcss " [(ngModel)]="splId">
            <option [ngValue]="select" disabled>Select</option>
            <option *ngFor="let spl of splList" value="{{spl.spl_id}}">{{spl.spl_name}}</option>
            </select>
        </mat-label>
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 searchbtn">
          <img class="saveimgbtn_inpatinfo"  (click)="search()" src="../../../assets/ui_icons/buttons/search_button.svg" />
          </div>
          <div *ngIf="documentDetails.length != 0" id="presdiv">
            <div class="row">
              <div class="col-6" *ngFor="let val of documentDetails">
                <mat-card id="cardcontent"  (click)="detailView(val.doc_url)">
                  <mat-card-content>
                    <p>{{val.data}}</p>
                  </mat-card-content>
                </mat-card>
              </div>
            </div>
          </div>
          <div [hidden]="pdfFlag" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8" style="margin-top: 10px;">
            <iframe class="controls" [src]="docSrc" width="900px" height="500px" frameborder="0" webkitallowfullscreen
              mozallowfullscreen allowfullscreen></iframe>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>