import { Component, OnInit,Input } from '@angular/core';
import {Helper_Class} from '../../helper_class';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
// import {ipaddress.getIp,ipaddress.Ip_with_img_address,ipaddress.country_code} from '../../../assets/js/ipaddress.js';
import { Http, Headers, JSONPConnection } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { ConvertTimeformat, Date_Formate, tConv24 } from '../../../assets/js/common';
import { Doc_Helper } from '../Doc_Helper';
import { DoctorViewService } from '../doctor-view/doctor-view.service';
import { CasesheetService } from '../casesheet/casesheet.service';
import { MatDialog } from '@angular/material/dialog';
import { InpatMedprescViewComponent } from '../inpat-medpresc-view/inpat-medpresc-view.component';
import { ipaddress } from '../../ipaddress' ;import { Helper_nephro_casesheet } from 'src/assets/js/Helper_nephro_casesheet';
import { GynaObestricPopSelectionComponent } from '../gyna-obestric-pop-selection/gyna-obestric-pop-selection.component';
import { FrontDeskService } from 'src/app/FrontDesk_module/front-desk/front-desk.service';
import { InpatMedicineTrackerComponent } from 'src/app/Nurse_module/inpat-medicine-tracker/inpat-medicine-tracker.component';
import { Nurse_Helper } from 'src/app/Nurse_module/Nurse_Helper';
import { NurseService } from 'src/app/Nurse_module/nurse-view/nurse.service';
import { Message_data } from 'src/assets/js/Message_data';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
@Component({
  selector: 'app-inpatien-card',
  templateUrl: './inpatien-card.component.html',
  styleUrls: ['./inpatien-card.component.scss']
})
export class InpatienCardComponent implements OnInit {
  public minView:boolean = true;
  @Input() pattern: string | RegExp;
  public patient_name;
  public userinfo:any;
  public user_id:any;
  public first_name;
  public last_name;
  public hospital_logo;
  public hospital_name;
  public hospital_details;
  public messages;
  public msgflag:boolean=false;
  public notifications;
  public notifyflag:boolean=false;
  public profile_image;
  public covid_type: string;
  public header_footer_flag: Boolean;
  public isMobile: boolean;
  public fabButtonOpened: boolean;
  // urls
  public treate_type_url;

  public get_location_url;
  public get_city_url;
  public get_state_url;
  public get_country_url;
  public treate_list_data = [];

  public data;

  public clnt_subrel_id;
  public clnt_sal;
  public clnt_fname;
  public clnt_mname;
  public clnt_lname;
  public clnt_age;
  public clnt_gender;
  public clnt_blood;
  public clnt_blood_id;
  public clnt_fg_name;
  public clnt_income;
  public clnt_occupation;
  public clnt_address1;
  public clnt_address2;
  public clnt_location;
  public clnt_city;
  public clnt_state;
  public clnt_country;
  public clnt_location_id;
  public clnt_city_id;
  public clnt_state_id;
  public clnt_country_id;
  public clnt_zipcode;
  public clnt_cont_number;
  public clnt_symptom;
  public clnt_dob;
  public ward_id;
  public bed_no;
  public send_bed_no;
  public emer_contact;
  public bp_txt;
  // public opinion_type;

  public treatement_type;
  public treatement_type_obj;
  public relationship_obj;
  public opiniontype_obj;
  public clnt_location_list:any = [];
  public clnt_city_list:any = [];
  public clnt_state_list:any = [];
  public clnt_country_list:any = [];
  public ward_list:any =[];
  public clnt_sal_list:any=[];

  public App_type;
  public Relationship;
  public client_data_list:any = [];
  public doctor_search_list;
  public appoint_client_data;

  public doctor_search_div: Boolean;
  public opinion_type_Div: Boolean;
  public appoint_Type_Div: Boolean;
  public search_Opinion_div: Boolean;
  public homecarevalue;

  public clnt_url;
  public hptl_url;
  public sal_url;
  public user_type: boolean;
  public mobile_type:boolean;
  public new_user:boolean;
  public exist_user:boolean;
  public Client_type;
  public search_hide: boolean;

  public height_txt;
  public hmeasure: string;
  public weight_txt;
  public wmeasure: string;
  public temp_txt;
  public bmi_txt;
  public encheight;
  public encweight;
  public chief_comp;
  public pres_ill;
  public med_hist;
  public family_hist;
  public physical_exam;
  public client_reg_id;
  public Confirm_btn: boolean;

  public CurrentDatetime;
  public get_date;
  public tokenumber;
  public spl_id;
  public spl_array:any =[];
  public splvalue;
  public spl;
  public app_id;
  public admission_id;
  public hospital_id;
  public life_style_id;
  public get_locname_url;
  public update_btn: boolean;

  public refer_txt;
  public Refered_doctor:any = [];
  public Refered_name:any = [];
  public submenu_flag:boolean =true;
  public submenu_flag1:boolean =false;

  public dob_flag:boolean;
  public currentYear;
  public currentTime;
  public currentDate;
  public covid_flag:boolean
  public readonlyFlag:boolean;

  public observList=[];
  public covidSympList=[];
  public comorbList=[];
  public finalcovidsymp=[];
  public finalcomorbList=[];
  public BP_01: string;
  public BP_02: string;
  public spo_txt: string;
  public pulse_txt: string;
  public resp_txt: string;
  public symp_url: string;
  public comorb_url: string;
  public covid_duration: string;

  public observetable: boolean;
  public scan_01: string;
  public scan_02: string;
  public rtpcr_result: string;
  public bmr_txt: string;
  public blood_disabled:boolean;

  public h_location;
  public h_address1;
  public h_address2;
  public h_city;
  public h_country;
  public h_state;
  public h_zipcode;

  public blood_preshidden:boolean;

  public symptom_data=[];
  public symptomlist=[];
  public symptoms_list=[];
  public selected_symptoms=[];

  public newsymptoms_flag:boolean = false;
  public newsymptoms:any =[];

  public discharge_date;
  public discharge_time1;
  public discharge_time2 ;
  public discharge_time3 = "AM";
  public dischage_min = [];
  public dischage_hrs = [];

  public disable_dob:boolean;
  public disable_age:boolean;
  public require_flag: boolean;
  public h_city_id;
  public h_state_id;
  public h_country_id;

  public clnt_marital;
  public pre_hosp;
  public pre_hospital_details;
  public allergy;
  public allergyFlag;
  public drugDetailsFlag:boolean = false;
  public engDetailsFlag:boolean = false;
  public foodDetailsFlag:boolean = false;
  public drug_value;
  public drug_details;
  public env_details; 
  public env_value;
  public food_details;
  public food_value;
  public bedList = [];
  public selectBed;
  

  public printFlag;
  public hosp_addr;
  public hosp_csz;
  public hosp_count;
  public hosp_tel;
  public inpatientId;
  public curr_date;
public coviedSytemmsText = "";
public comorb_desc = "";
  public doctor_name;
  public wardName;
  public disPrint;
  public timePrint;

  public preHospFlag;
  public drugdetailsFlag;
  public envDetailsFlag;

  public hospitalAddress;
  public Hospital_location;
  public non_inpatient_flow:boolean = false;
  public doc_url;
  public doctor_list = [];
  public docname_list = [];
  public doc_id;
  public nurse_flow:boolean = false;

  public visiting_doctor;
public visiting_doc_name;
  public docName;
public visiting_remarks;
public is_consultent = "0";
public visiting_doc_id;
public visitignArrray = [];
  constructor(public dialog:MatDialog,public http:Http,public routes:ActivatedRoute,public router:Router,public toastr:ToastrService,public messageservice:MenuViewService,public cashsheet: CasesheetService,public frontservice:MenuViewService, public nurseservice:MenuViewService) {
    this.treate_type_url = ipaddress.getIp + "usercontroller/getmedicare/";
   
    this.hptl_url=ipaddress.getIp + "usercontroller/hospdetails";
    this.get_location_url = ipaddress.getIp + "usercontroller/loc";
    this.get_locname_url = ipaddress.getIp + "usercontroller/locbyname";
    this.get_city_url = ipaddress.getIp + "usercontroller/city";
    this.get_state_url = ipaddress.getIp + "usercontroller/state";
    this.get_country_url = ipaddress.getIp + "usercontroller/count";
    this.doc_url = ipaddress.getIp + "search/docbyhs";
    this.sal_url=ipaddress.getIp + "gen/sal";
    this.symp_url=ipaddress.getIp + "gen/covsym";
    this.comorb_url=ipaddress.getIp + "gen/gcomorb";

   // this.homecarevalue = Doc_Helper.getHomeCare();
    this.homecarevalue = "0";
    this.user_type=false;
    this.mobile_type = true;
    this.exist_user = false;
    this.new_user = true;
    this.update_btn=true;
    this.hmeasure="cms";
    this.wmeasure="kgs";
    this.Client_type="Existing";
    this.search_hide=false;
    this.readonlyFlag = false;

    this.covid_type="no";
    this.scan_02="25";
    this.covid_flag=false;
    this.observetable=true;
    this.temp_txt = "";
    this.BP_01 = "";
    this.BP_02 = "";
    this.spo_txt = "";
    this.pulse_txt = "";
    this.resp_txt = "";
    this.scan_01 = "";
    this.rtpcr_result="Positive";
    this.covid_flag=false;
    this.observetable=true;
    this.require_flag=true;

    this.discharge_time1 = "06";
    this.discharge_time2 = "00";
    for (var i = 1; i <= 12; i++) {
      if (i < 10) {
        this.dischage_hrs.push("0" + i);

      } else {
        this.dischage_hrs.push(i);
      }
    }

    for (var i = 0; i <= 55; i += 5) {
      if (i < 10) {
        this.dischage_min.push("0" + i);

      } else {
        this.dischage_min.push(i);
      }
    }
    
    // this.getCovidSymptoms();
    // this.getComorbidities();
    this.treatement_type = Doc_Helper.getMedicare_name();
    var hosp:any = Helper_Class.getHospital();
    this.hospital_id = hosp[0].hptl_clinic_id;
    Helper_Class.setapp_flow("0");
    Doc_Helper.setAppFlow("Inpatient");
    Doc_Helper.setClient_Info(null);
   }

  ngOnInit(): void {
    $(document).ready(function () {
      $("input").attr("autocomplete", "off");
    });

    if(Helper_Class.getInpatientFlow() == "front-desk"){
      this.non_inpatient_flow = true;
      this.nurse_flow = false;
    } else if(Helper_Class.getInpatientFlow() == "nurse"){
      this.non_inpatient_flow = true;
      this.nurse_flow = true;
    }else{
      this.non_inpatient_flow = false;
      this.nurse_flow = false;
    }

    if(Doc_Helper.getInpatientFlow() == "list"){
      this.clnt_url = ipaddress.getIp + "inpat/gpd/";
    }else{
      this.clnt_url = ipaddress.getIp + "usercontroller/cdetnoapph/";
    }
    
    this.changestroke(true);
    this.userTypeSelect("Existing");
    this.userinfo= Helper_Class.getInfo();
    this.user_id=this.userinfo.user_id;
    this.Confirm_btn = false;
    this.clnt_marital = "single";
    this.pre_hosp = "no";
    if( this.userinfo.middle_name != undefined ){
      this.docName =  this.userinfo.first_name + " " + this.userinfo.middle_name + " " + this.userinfo.last_name;
    }else{
      if( this.userinfo.last_name != undefined){
        this.docName =  this.userinfo.first_name + " " + this.userinfo.last_name;
      }else{
        this.docName =  this.userinfo.first_name;
      }
    }
    if(Helper_Class.getInpatientFlow() == "front-desk"){
      this.non_inpatient_flow = true;
    }else{
      this.visiting_doc_name = this.docName;
    }
    this.splvalue = Helper_Class.getSpecializations();
    for(var i=0;i<this.splvalue.length;i++){
      this.spl_array.push({
        spl_id:this.splvalue[i].spl_id,
        spl_name:this.splvalue[i].spl_name,
      })
    }
    this.spl_id=this.spl_array[0].spl_id;
    this.spl = this.spl_array[0].spl_name;

    if(Helper_Class.getInpatientFlow() == "front-desk"){
      this.getDoctorList(this.spl_id);
    }
   
    this.getCurrentDate();
    this.getCurrentToken();
    this.getHospitalDetails();
    this.getWardData();
    this.getSalutations();
    this.getCountries("0");
    // if(this.covidSympList != undefined && this.covidSympList.length ==0){
      this.getCovidSymptoms();
    // }
    // if(this.comorbList != undefined && this.comorbList.length ==0){
      this.getComorbidities();
    // }
    
   
    var hospitaldet = Helper_Class.getHospital();
    this.hospital_id = hospitaldet[0].hptl_clinic_id;
    this.hospital_name = hospitaldet[0].hptl_name;
    this.clnt_location_id=hospitaldet[0].location;
    this.clnt_city_id=hospitaldet[0].city;
    this.clnt_state_id= hospitaldet[0].state;
    this.clnt_country_id = hospitaldet[0].country;
   
    if(Doc_Helper.getClient_type() != undefined &&  Doc_Helper.getClient_type() =="New"){
      this.Client_type = Doc_Helper.getClient_type();
      this.user_type=true;
      this.mobile_type =false;
      this.new_user=false;
      this.exist_user=true;
      this.client_reg_id="0";
      this.clnt_gender="Male";
      this.search_hide=false;
      this.dob_flag = false; 
      this.readonlyFlag = false;
    } else {
      this.new_user=true;
      this.mobile_type =true;
      this.dob_flag = true; 
      this.Client_type = "Existing";
      this.exist_user=false;
      this.search_hide=true;
      this.readonlyFlag = false;
    }

    if(Doc_Helper.getMobile_no() != null){
      this.clnt_cont_number = encrypt_decript.Decript(Doc_Helper.getMobile_no());
    }

    if(Doc_Helper.getFirstname() != null){
      this.clnt_fname = Doc_Helper.getFirstname();
    }

    if(Doc_Helper.getMiddlename() != null){
      this.clnt_mname = Doc_Helper.getMiddlename();
    }else{
      this.clnt_mname = undefined;
    }

    if(Doc_Helper.getLastname() != null){
      this.clnt_lname = Doc_Helper.getLastname();
    }

    if(this.nurse_flow == true){
      if(Nurse_Helper.getINPatient().client_reg_id != null){
        this.client_reg_id = Nurse_Helper.getINPatient().client_reg_id;
      }
    }

    if(this.clnt_cont_number != undefined || this.clnt_cont_number !=null){
        this.getUserDetails();
     }
    this.search_hide = Boolean(Doc_Helper.getSearchFlag());
  }
  openCard(name){

  }
  addObservations(){
    if(this.temp_txt.length != 0 && this.BP_01.length != 0 && this.BP_02.length != 0 && this.spo_txt.length != 0
       &&  this.resp_txt.length != 0 && this.scan_01.length != 0){
         this.BP_01 = this.BP_01 != null ? this.BP_01 : "00";
         this.BP_02 = this.BP_02 != null ? this.BP_02 : "00";
     
        this.observList.push({
          temp: this.temp_txt,
          bp: this.BP_01+"/"+this.BP_02,
          sop2: this.spo_txt,
          pulse: this.pulse_txt,
          resprate: this.resp_txt,
          ct_scan:this.scan_01+"/"+this.scan_02,
          rtpcr: this.rtpcr_result,
          status:"1",
        });
        this.observetable=false
       }
    else{
      this.toastr.error(Message_data.mandatory);
    }

  }

  getDoctorList(e) {
    if(Helper_Class.getInpatientFlow() == "front-desk"){
      for (var i = 0; i < this.spl_array.length; i++) {
        if (this.spl_id == this.spl_array[i].spl_id) {
          var splname = this.spl_array[i].spl_name;
        }
      }
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.doc_url,
        JSON.stringify({
          hptl_clinic_id: this.hospital_id,
          // hptl_clinic_id : Helper_Class.getHospitalId(),
          spl_name: splname,
        }),
        { headers: headers }).subscribe(
          data => {
            this.doctor_list = [];
            this.docname_list = [];
            
            var dataobj = JSON.parse(data["_body"]);
            this.doctor_list = dataobj.doctors;
            if (this.doctor_list != undefined) {
              for (var i = 0; i < this.doctor_list.length; i++) {
                var docname;
                if (this.doctor_list[i].middle_name != undefined) {
                  docname = this.doctor_list[i].first_name + " " + this.doctor_list[i].middle_name + " " + this.doctor_list[i].last_name;

                } else {
                  docname = this.doctor_list[i].first_name + " " + this.doctor_list[i].last_name;
                }
                this.docname_list.push({
                  docname: docname,
                  prov_id: this.doctor_list[i].prov_id
                });
              }
            }
          },
          error => {
            this.toastr.error(Message_data.unableToFetchData);
          });
    }
  }

  getCovidSymptoms(){
    var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.get(this.symp_url, { headers: headers }).subscribe(
        data => {
          var obj = data.json();
          
          if(obj !=undefined){
            for(var i=0;i<obj.covid_symptoms.length;i++){
              this.covidSympList.push({
                symp_id:obj.covid_symptoms[i].covid_symp_id,
                symp_desc:obj.covid_symptoms[i].description,
              });
            }
          }
        })
  }

  getComorbidities(){
    var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.get(this.comorb_url, { headers: headers }).subscribe(
        data => {
          var obj = data.json();
          
          if(obj !=undefined){
            for(var i=0;i<obj.comorbidities.length;i++){
              this.comorbList.push({
                comorb_id:obj.comorbidities[i].comorbidity_id,
                comorb_desc:obj.comorbidities[i].comorbidity,
              });
            }
          }
        })
  }

  covidtypeSelect(e){
    if(this.covid_type=="yes"){
      this.covid_flag=true;
    } else {
      this.covid_flag=false;
    }
  }
  allergyChange(e){
    if(this.allergy == "yes"){
      this.allergyFlag=true;
    } else {
      this.allergyFlag=false;
    }
  }
  allergyType(type,value){
    if( type == "drug" ){
      this.drugDetailsFlag =  value.toLowerCase() == "yes" ? true : false; 
    }else if( type == "evn" ){
      this.engDetailsFlag =  value.toLowerCase() == "yes" ? true : false; 
    }else{
      this.foodDetailsFlag =  value.toLowerCase() == "yes" ? true : false; 
    }
  }
  symptom_click (e: any, data) {
    if (e == true) {
      if (this.covidSympList.length!=0) {
        for(var i=0;i<this.covidSympList.length;i++){
          if(this.covidSympList[i].symp_id == data){
            this.covidSympList[i].checked=true;
            this.finalcovidsymp.push(this.covidSympList[i].symp_id)
          }
        }
      }
      
    } else {
      if (this.covidSympList.length!=0) {
        for(var i=0;i<this.covidSympList.length;i++){
          if(this.covidSympList[i].symp_id == data){
            this.covidSympList[i].checked=false;
          }
        }
      }

      if(this.finalcovidsymp.length !=0){
        for(var j=0;j<this.finalcovidsymp.length;j++){
          if(data == this.finalcovidsymp[j]){
            this.finalcovidsymp.splice(j,1)
          }
        }
      }
    }
  }

  comorb_click (e: any, data) {
    if (e == true) {
      if (this.comorbList.length!=0) {
        for(var i=0;i<this.comorbList.length;i++){
          if(this.comorbList[i].comorb_id == data){
            this.comorbList[i].checked=true;
            this.finalcomorbList.push(this.comorbList[i].comorb_id)
          }
        }
      }

    } else {
      if (this.comorbList.length!=0) {
        for(var i=0;i<this.comorbList.length;i++){
          if(this.comorbList[i].comorb_id == data){
            this.comorbList[i].checked=false;
          }
        }
      }

      if(this.finalcomorbList.length !=0){
        for(var j=0;j<this.finalcomorbList.length;j++){
          if(data == this.finalcomorbList[j]){
            this.finalcomorbList.splice(j,1)
          }
        }
      }
    }
  }

  Change_Specialization(e){
    this.spl_id = e;
    for(var i=0;i< this.spl_array.length;i++){
      if(this.spl_array[i].spl_id == this.spl_id){
          this.spl = this.spl_array[i].spl_name
      }
    }
  }

  getSalutations(){
    var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.get(this.sal_url, { headers: headers }).subscribe(
        data => {
          var obj = data.json();
          
          if(obj !=undefined){
            for(var i=0;i<obj.salutations.length;i++){
              this.clnt_sal_list.push({
                sal_id:obj.salutations[i].salutation_id,
                sal_desc:obj.salutations[i].salutation_desc,
              });
            }
            this.clnt_sal = "select";
           this.changeSalutation(this.clnt_sal);
          }
        })
  }

  openFabButton() {
    if (this.fabButtonOpened == false) {
        this.fabButtonOpened = true;
    } else {
        this.fabButtonOpened = false;
    }
  }

  getHospitalDetails(){
    var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.hptl_url, JSON.stringify({
        hosp_clinic_id: this.hospital_id

      }), { headers: headers }).subscribe(
        data => {
          var obj = data.json();
          if(obj !=undefined){
            this.h_location = obj.location;
            this.h_address1 = obj.address1;
            this.h_address2 = obj.address2;
            this.h_city = obj.city;
            this.h_state = obj.state;
            this.h_country = obj.country;
            this.h_zipcode = obj.zipcode;
            this.h_city_id = obj.city_id;
            this.h_state_id = obj.state_id;
            this.h_country_id = obj.country_id;

           
            if (obj.hptl_logo != undefined) {
              Doc_Helper.setHospital_logo(ipaddress.Ip_with_img_address+obj.hptl_logo);
            } 
            Doc_Helper.setHospital_name(obj.hosp_name);
            Doc_Helper.setHospital_location(obj.location);

            if (obj.address2 != null && obj.address2 != "") {
              Doc_Helper.setHospital_address(obj.address1 + " " + obj.address2);
            } else {
              Doc_Helper.setHospital_address(obj.address1);
            }

            Doc_Helper.setHospital_city_zip(obj.city + " - " + obj.zipcode);
            Doc_Helper.setHospital_state_country(obj.state + " , " + obj.country);
          }
        })
  }

  userTypeSelect(e){
    this.Client_type = e;
    if(this.Client_type == "New"){
      this.user_type=true;
      this.new_user=false;
      this.exist_user=true;
      this.client_reg_id="0";
      this.clnt_gender="Male";
      this.search_hide=true;
      this.mobile_type = false;
      this.dob_flag = false;
      this.client_reg_id="0";
      this.clnt_gender="Male";
      this.dob_flag = false; 
      this.clnt_fname=null;
      this.clnt_mname=null;
      this.clnt_lname=null;
      this.clnt_age="";
      this.clnt_gender="";
      this.clnt_blood="";
      this.clnt_blood_id="";
      this.clnt_fg_name="";
      this.clnt_income="";
      this.clnt_occupation="";
      this.clnt_address1=null;
      this.clnt_address2=null;
      
      this.clnt_cont_number= null;
      this.clnt_symptom="";
      this.clnt_dob=null;

      this.height_txt="";
      this.hmeasure="cms";
      this.weight_txt="";
      this.wmeasure="kgs";
      this.bmi_txt=""
      this.temp_txt="";
      this.bmi_txt;
      this.encheight;
      this.encweight;
      this.chief_comp="";
      this.pres_ill="";
      this.med_hist="";
      this.family_hist="";
      this.physical_exam="";
      this.refer_txt = null;
      this.covid_type="no";
      this.scan_02="25";
      this.rtpcr_result="Positive";
      this.covid_flag=false;
      this.observList = [];
      this.BP_01 = "";
      this.BP_02 = "";
      this.spo_txt = "";
      this.pulse_txt = "";
      this.resp_txt= "";
      this.clnt_symptom = "";
      this.disable_age = false;
      this.disable_dob = false;
      for(var i=0; i<this.comorbList.length;i++){
        this.comorbList[i].checked=false;
      }
      for(var i=0; i<this.covidSympList.length;i++){
        this.covidSympList[i].checked=false;
      }

      if(this.clnt_country_list.length !=0){
        this.countryChange(this.clnt_country_id,"0");
      } else {
        this.getCountries("0");
      }
      this.clnt_sal=this.clnt_sal_list[0].sal_id;
      // this.clnt_fname = "";
      // this.clnt_mname = "";
      // this.clnt_lname = "";
      // this.clnt_cont_number = "";
    } else {
      this.user_type=false;
      this.new_user=true;
      this.exist_user=false;
      this.search_hide=false;
      this.mobile_type = true;
      this.dob_flag = true;
      this.clnt_fname=null;
      this.clnt_mname=null;
      this.clnt_lname=null;
      this.clnt_cont_number= null;
      this.clnt_age="";
      this.clnt_gender="";
      this.clnt_blood="";
      this.clnt_blood_id="";
      this.clnt_fg_name="";
      this.clnt_income="";
      this.clnt_occupation="";
      this.clnt_address1=null;
      this.clnt_address2=null;
      this.height_txt="";
      this.hmeasure="cms";
      this.weight_txt="";
      this.wmeasure="kgs";
      this.bmi_txt="";
      this.temp_txt="";
      this.bmi_txt;
      this.encheight;
      this.encweight;
      this.chief_comp="";
      this.pres_ill="";
      this.med_hist="";
      this.family_hist="";
      this.physical_exam="";
      this.covid_type="no";
      this.scan_02="25";
      this.rtpcr_result="Positive";
      this.covid_flag=false;
      this.observList = [];
      this.user_type=true;
      this.new_user=true;
      this.exist_user=false;
      this.dob_flag = true; 
      this.BP_01 = "";
      this.BP_02 = "";
      this.spo_txt = "";
      this.pulse_txt = "";
      this.resp_txt= "";
      this.refer_txt = "";
      this.clnt_symptom = "";
      this.clnt_dob=null;

      this.disable_age = false;
      this.disable_dob = false;
      for(var i=0; i<this.comorbList.length;i++){
        this.comorbList[i].checked=false;
      }
      for(var i=0; i<this.covidSympList.length;i++){
        this.covidSympList[i].checked=false;
      }
    }
  }

  Gotohome() {
    // this.navCtrl.setRoot('ClientHomepagePage');
    // this.navCtrl.popToRoot();
  }

  fname_toUpper(){
    if(this.clnt_fname != undefined){
      this.clnt_fname = this.clnt_fname.toLocaleUpperCase();
    }
  }

  mname_toUpper(){
    if(this.clnt_mname != undefined){
      this.clnt_mname = this.clnt_mname.toLocaleUpperCase();
    }
  }

  lname_toUpper(){
    if(this.clnt_lname != undefined){
      this.clnt_lname = this.clnt_lname.toLocaleUpperCase();
    }
  }

  getUserDetails(){
    var fname,mname,lname,mnumber;
    this.inpatientId = undefined;
    if(this.clnt_fname!= undefined && this.clnt_fname != undefined && this.clnt_fname!= null){
      fname = encrypt_decript.Encript(this.clnt_fname).toString();
    }
    if(this.clnt_mname!= '' && this.clnt_mname!= undefined && this.clnt_mname != null){
      mname = encrypt_decript.Encript(this.clnt_mname).toString();

    }
    if(this.clnt_lname!= undefined &&this.clnt_lname != undefined && this.clnt_lname!= null){
      lname = encrypt_decript.Encript(this.clnt_lname).toString();

    }
    if(this.clnt_cont_number != null){
      mnumber = encrypt_decript.Encript(this.clnt_cont_number).toString();
    }
    var senddata;
    if(this.nurse_flow == true){
      senddata = JSON.stringify({
        mobile: mnumber,
       client_reg_id: this.client_reg_id,
       fname:fname,
       mname: mname,
       lname: lname,
       country: "IN",
    });
    }else{
      senddata = JSON.stringify({
        mobile: mnumber,
       //client_reg_id: Doc_Helper.getClient_id(),
       fname:fname,
       mname: mname,
       lname: lname,
       country: "IN",
       hptl_id: this.hospital_id,
        type:this.Client_type,
        is_doc: 1
    });
    }

    

    var headers = new Headers();
      headers.append('Content-Type', 'application/json');

      this.http.post(this.clnt_url, senddata, { headers: headers }).subscribe(
        response => {
          var data = response.json();
          
          if(data.client_reg_id !=undefined){
            this.life_style_id = data.life_style_id;
            this.client_reg_id=data.client_reg_id;
            Doc_Helper.setClient_id(this.client_reg_id)
            Doc_Helper.setRel_id("1")
            Doc_Helper.setClient_gender(encrypt_decript.Decript(data.gender))
            this.inpatientId = data.admission_id;
            this.clnt_sal = data.salutation;
            this.clnt_fname = encrypt_decript.Decript(data.first_name);

            if (data.middle_name != null) {
              this.clnt_mname = encrypt_decript.Decript(data.middle_name);
            }
  
            this.clnt_lname = encrypt_decript.Decript(data.last_name);
            if(this.clnt_mname != undefined){
              Doc_Helper.setClient_name(this.clnt_fname+" "+this.clnt_mname+" "+this.clnt_lname);
              this.patient_name = this.clnt_fname+" "+this.clnt_mname+" "+this.clnt_lname;
            } else {
              Doc_Helper.setClient_name(this.clnt_fname+" "+this.clnt_lname);
              this.patient_name = this.clnt_fname+" "+this.clnt_lname;
            }

            if( data.dr_middle_name != undefined){
              this.doctor_name = data.dr_first_name +" "+ data.dr_middle_name +" "+ data.dr_last_name; 
            }else{
              this.doctor_name = data.dr_first_name +" "+ data.dr_last_name; 
            }
            this.visiting_doc_name = this.doctor_name;
            this.is_consultent; 
            if(data.dob != undefined && data.dob != "" && data.dob != null){
              var ddata = data.dob.split("-");
              this.clnt_dob = data.dob;
              if(this.spl_id =="10"){
                this.OnDateChangeDOB(this.clnt_dob);
              }
            }
            else{
              this.disable_dob = true;
            }
        
            if(data.age != undefined && data.age != "" && data.age != null){
              this.clnt_age = data.age;
            } else {
              this.clnt_age = "<1";
            }
            Doc_Helper.setClient_age(this.clnt_age);

            this.clnt_marital = data.marital_status != undefined && data.marital_status != "" ? data.marital_status : "";
            this.pre_hosp = data.prev_hosp;
            this.preHospFlag = data.prev_hosp == "Yes" ? true : false;
           this.allergyFlag
            this.pre_hospital_details = data.prev_hosp_det != undefined ? data.prev_hosp_det : "";
             this.allergyFlag = data.allergy == "yes" ? true : false;
            this.allergy = data.allergy;
            if(data.drug_details != undefined){
                this.allergyType("drug","yes");
              this.drug_value = "yes";
              this.drug_details =  data.drug_details;
              this.drugdetailsFlag = true;
            }else{
              this.drugdetailsFlag = false;
              this.drug_value = "no";
            }
            if(data.env_details != undefined){  
              this.allergyType("evn","yes");
              this.env_value = "yes";
              this.env_details = data.env_details;
              this.envDetailsFlag = true;
            }else{
              this.envDetailsFlag = false;
              this.env_value = "no";
            }
            if(data.food_details != undefined){
              this.allergyType("food","yes");
              this.food_value = "yes";
              this.food_details = data.food_details;
              this.envDetailsFlag = true;
            }else{
              this.envDetailsFlag = false;
              this.food_value = "no";
            }
            // if( data.age !=0){
            //   this.clnt_age = data.age;
            // } else {
            //   this.clnt_age = "<1";
            // }
  

            if (data.gender != null && data.gender != "0: undefined") {
              this.clnt_gender = encrypt_decript.Decript(data.gender);
            }
  
            this.clnt_blood = data.blood_desc;
            this.clnt_blood_id = data.blood_group;
  
            if (data.guardian != 'null') {
              this.clnt_fg_name = data.guardian;
            }
            
            if (data.emerg_contact != 'null') {
              this.emer_contact = data.emerg_contact;

            } else {
              this.emer_contact =""; 
            }

            if (data.ward_id != undefined) {
              this.ward_id = data.ward_id;
            }
            
            if (data.bed_no != 'null' && data.bed_no != null && data.bed_no != "" && data.bed_no != undefined) {
              this.send_bed_no = data.bed_no;
              this.getBedList(data.bed_no,"ret");
            }

            if (data.family_income != null) {
              this.clnt_income = encrypt_decript.Decript(data.family_income);
            }

            if (data.doc_reg_id != null) {
              this.user_id = data.doc_reg_id;
              this.doc_id = data.doc_reg_id;
            }

            this.height_txt = data.height;
            this.hmeasure = data.hmeasure;
            this.weight_txt = data.weight;
            this.wmeasure = data.wmeasure;
            this.bmi_txt = data.bmi;
            this.chief_comp = data.complaints;
            this.pres_ill = data.present_illness;
            this.med_hist = data.medical_history;
            this.family_hist = data.family_history;
            this.physical_exam = data.physical_exam;
            this.app_id=data.app_id;
            this.admission_id = data.admission_id;
            this.bp_txt = data.bp;
            this.temp_txt = data.temparature;
            this.clnt_symptom=data.symptoms;
            this.clnt_blood = data.blood_group;
            this.refer_txt = data.ref_by;
            
            if(data.bp != undefined){
              var bpre = data.bp.split('/');
              this.BP_01 = bpre[0];
              this.BP_02 = bpre[1]
            }

            this.covid_type = data.is_covid;
            this.covid_duration = data.covid_dur;

            if(this.covid_type == "yes"){
              this.covid_flag=true;
            } else {
              this.covid_type="no";
              this.covid_flag=false;
            }

            this.finalcovidsymp=[];
            this.finalcomorbList =[];
            if(data.covid_symp != undefined){
              this.finalcovidsymp = data.covid_symp;
            }
            if(data.covid_comorb != undefined){
              this.finalcomorbList = data.covid_comorb;
            }
            if(data.covid_observ != undefined){
              this.observList= data.covid_observ;
              this.observetable=false;
            }

            if(this.finalcovidsymp!= undefined && this.finalcovidsymp.length !=0){
              if (this.covidSympList.length!=0) {
                for(var i=0;i<this.covidSympList.length;i++){
                  for(var j=0;j<this.finalcovidsymp.length;j++){
                    if(this.covidSympList[i].symp_id == this.finalcovidsymp[j]){
                      this.covidSympList[i].checked=true;
                      if(this.coviedSytemmsText == ""){
                        this.coviedSytemmsText =  this.covidSympList[i].symp_desc;
                      }else{
                        this.coviedSytemmsText = this.coviedSytemmsText +", "+ this.covidSympList[i].symp_desc;
                      }
                    }
                  }
                }
              }
            }

            if(this.finalcomorbList != undefined && this.finalcomorbList.length !=0){
              if (this.comorbList.length!=0) {
                for(var i=0;i<this.comorbList.length;i++){
                  for(var j=0;j<this.finalcomorbList.length;j++){
                    if(this.comorbList[i].comorb_id == this.finalcomorbList[j]){
                      this.comorbList[i].checked=true;
                      if( this.comorb_desc == "" ){
                        this.comorb_desc = this.comorbList[i].comorb_desc;
                      }else{
                        this.comorb_desc = this.comorb_desc +", "+ this.comorbList[i].comorb_desc;
                      }
                     
                    }
                  }
                }
              }
            }
            if (data.ward_id != undefined) {
              for( var i=0; i< this.ward_list.length; i++){
                  if( data.ward_id == this.ward_list[i].ward_id){
                    this.wardName = this.ward_list[i].ward_name;
                  }
              }           
            }

            if(data.discharge_date != undefined){
              this.discharge_date = data.discharge_date;
              this.disPrint = Date_Formate(this.discharge_date);
            }

            if(data.discharge_time != undefined){
              this.timePrint = tConv24(data.discharge_time);
              var discharge_time = this.timePrint.split(" ");
              var time = discharge_time[0];
              var hr_min = time.split(":");

              this.discharge_time3 = discharge_time[1];
              this.discharge_time1 = hr_min[0];
              this.discharge_time2 = hr_min[1];
            }

            if(data.ref_by != undefined){
              Doc_Helper.setRefer_by(data.ref_by);
            } 
            Doc_Helper.setApp_id(this.app_id); 
            if(this.inpatientId != undefined){
              this.update_btn=false;
              this.Confirm_btn=true;
              this.printFlag = true;
            } else {
              this.update_btn=true;
              this.Confirm_btn=false;
              this.printFlag = false;
            }

            if (data.occupation != null) {
              this.clnt_occupation = data.occupation;
            }
            if (data.address1 != null) {
              this.clnt_address1 = encrypt_decript.Decript(data.address1);
            }
  
            if (data.address2 != null) {
              this.clnt_address2 = encrypt_decript.Decript(data.address2);
            }
  
            this.clnt_location = data.location_desc;
            this.clnt_city = data.city_desc;
            this.clnt_state = data.state_desc;
            this.clnt_country = data.country_desc;
            
            this.clnt_location_id = data.location;
            this.clnt_city_id = data.city;
            this.clnt_state_id = data.state;
            this.clnt_country_id = data.country; 
  
            if(data.zipcode != undefined){
              this.clnt_zipcode = encrypt_decript.Decript(data.zipcode);
            }
            if(data.mobile != undefined){
              this.clnt_cont_number = encrypt_decript.Decript(data.mobile);
            }
            var addval;
            if(data.address2 != undefined){
              addval = this.clnt_address1+","+this.clnt_address2;

            } else {
              data = this.clnt_address1;
            }
            this.hosp_addr = addval;
            this.hosp_csz= this.clnt_location +", "+ this.clnt_city +" - "+ this.clnt_state +"  "+ this.clnt_zipcode;
            this.hosp_count = this.clnt_location;

            if(data.telephone != undefined){
              this.hosp_tel = data.telephone;

            } else {
              this.hosp_tel = "-";
            }

            var hospital;
            if(data.hosp_address2 != undefined){
              hospital = data.hosp_address1+","+ data.hosp_address2;

            } else {
              hospital = data.hosp_address1;
            }
            this.hospitalAddress = hospital;
            this.Hospital_location= data.hosp_loc_desc +", "+ data.hosp_city_desc +" - "+ data.hosp_state_desc +"  "+ data.hosp_zipcode;

            // visiting hospital 
            this.visiting_doc_id = data.doc_reg_id; 
            if(data.doctor_visits != undefined){
              var doc_visitng = data.doctor_visits;
              for( var i=0; i < doc_visitng.length; i++){
                var billable = doc_visitng[i].is_billable == "1" ? "Yes" : "No";
                var docName;
                if( doc_visitng[i].middle_name != undefined){
                  docName = doc_visitng[i].first_name + " " + doc_visitng[i].middle_name + " " + doc_visitng[i].last_name;
                }else{
                  if(doc_visitng[i].last_name != undefined){
                    docName = doc_visitng[i].first_name + " " + doc_visitng[i].last_name;
                  }else{
                    docName = doc_visitng[i].first_name
                  }
                }
                this.visitignArrray.push({
                  visiting_doc_name: docName,
                  remarks: doc_visitng[i].remarks,
                  billable : billable,
                  date : Date_Formate(doc_visitng[i].created_date),
                  time : this.tConvert(doc_visitng[i].created_time)
                });
              }
            }
          }  else {
             this.toastr.error(Message_data.noDataFound);
          }
          
        },
        error => {
          this.toastr.error(Message_data.unableToFetchData);
        }
      )
     
  }
  tConvert(time) {
    let hour = (time.split(':'))[0]
    let min = (time.split(':'))[1]
    let part = hour > 12 ? 'pm' : 'am';
    
    min = (min+'').length == 1 ? `0${min}` : min;
    hour = hour > 12 ? hour - 12 : hour;
    hour = (hour+'').length == 1 ? `0${hour}` : hour;
  
    return (`${hour}:${min} ${part}`)
    // Check correct time format and split into components
    // time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
  
    // if (time.length > 1) { // If time format correct
    //   time = time.slice (1);  // Remove full string match value
    //   time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
    //   time[0] = +time[0] % 12 || 12; // Adjust hours
    // }
    // return time.join (''); // return adjusted time or original string
  }
  getCurrentDate(){
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate',
      JSON.stringify({
        country: ipaddress.country_code.toString()
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          if (obj.current_date != null) {
            
            this.CurrentDatetime = obj.current_date;
            this.get_date = obj.current_date.split('-');
            var currentDate = this.get_date[2] + "-" + this.get_date[1] + "-" + this.get_date[0];
            this.currentYear = this.get_date[0];
            this.currentTime = obj.current_time;
            this.currentDate = obj.current_date;
            // this.discharge_date = obj.current_date;
            this.curr_date = obj.current_date
            
            Helper_Class.setKey(obj.provider+obj.clientid+obj.messageid);
            Helper_Class.setIV(obj.random);
          }
        },
        error => {
            this.toastr.error(Message_data.defaultErr);
        }
      )
  }

  getWardData(){
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'inpat/gward',
      JSON.stringify({
        hptl_clinic_id:this.hospital_id
      }),
      { headers: headers })
      .subscribe(
        response => {
          
          var obj = response.json();
          if(obj!=undefined){
            this.ward_list = obj.wards;
          }
        });
  }

  getCurrentToken(){
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'appointment/dtoken',
      JSON.stringify({
        doc_reg_id:this.user_id
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          if(obj!=undefined){
            this.tokenumber = obj.tokem;
          }
        });
  }

  create_admission(){
    var create_flag=true;

    if(this.clnt_symptom != "" && this.clnt_symptom != undefined && this.clnt_symptom != null){
      var new_symptoms = [];
      new_symptoms = this.filterNew(this.clnt_symptom,this.newsymptoms);
      var new_symptoms = [];
    }else{
      this.clnt_symptom = "";
    }

    if(this.Client_type =="New"){
      if(this.clnt_cont_number==undefined || this.clnt_fname ==undefined || this.clnt_lname == undefined 
        || this.clnt_age == undefined || this.clnt_gender==undefined || this.clnt_location_id  == undefined 
        || this.spl_id == undefined || this.clnt_dob == undefined){

          create_flag = false;
        }
        if(this.require_flag == true){
          if(this.clnt_age == ""){
            create_flag = false;
          }
        }

    } else {
      if(this.clnt_cont_number==undefined || this.spl_id == undefined ){
          create_flag = false;
        }
    }
    if( this.clnt_sal == "select"){
      create_flag = false;
    }

    if(create_flag == false ){
      if(this.clnt_sal == "select"){
        this.toastr.error(Message_data.sltSalutation);
      }else{
        this.toastr.error(Message_data.mandatory);
      }
    } else {
      this.patient_name = null;
      if (this.clnt_mname != undefined && this.clnt_mname != null) {
        if (this.clnt_mname != "") {
          this.patient_name = this.clnt_fname + " " + this.clnt_mname + " " + this.clnt_lname;

        } else {
          this.patient_name = this.clnt_fname + " " + this.clnt_lname;
        }

      } else {
        this.patient_name = this.clnt_fname + " " + this.clnt_lname;
      }
      Doc_Helper.setClient_name(this.patient_name);
      Doc_Helper.setClient_gender(this.clnt_gender);
      if(this.clnt_age != ""){
        if(this.clnt_age =="0"){
          this.clnt_age ="<1";
        }
        Doc_Helper.setClient_age(this.clnt_age);

      } else {
        Doc_Helper.setClient_age("");
      }

      var get_date = Date_Formate(this.currentDate);

      var curr_datetime = new Date(Date_Formate(get_date) + " " + this.currentTime);
      var curr_session;
      if (curr_datetime.getHours() >= 0 && curr_datetime.getHours() < 12) {
        curr_session = "Morning";
      }
  
      if (curr_datetime.getHours() >= 12 && curr_datetime.getHours() < 16) {
        curr_session = "Afternoon";
      }
  
      if (curr_datetime.getHours() >= 16 && curr_datetime.getHours() < 22) {
        curr_session = "Evening";
      }

      curr_session = "Evening";
      
      var apptype;
      if(this.App_type =="New"){
        apptype="1";
  
      } else {
        apptype="2";
      }
  
      var midname;
      if(this.clnt_mname !=undefined){
        midname=encrypt_decript.Encript(this.clnt_mname).toString()
      } 

      var addr2;
      if(this.clnt_address2 != undefined){
        addr2 = encrypt_decript.Encript(this.clnt_address2).toString()
  
      } else {
        addr2 = undefined;
      }
      
      var address:any =[];
      address.push({
        address1:encrypt_decript.Encript(this.clnt_address1).toString(),
        address2:addr2,
        location:this.clnt_location_id,
        city:this.clnt_city_id,
        state:this.clnt_state_id,
        country:this.clnt_country_id,
        zipcode:encrypt_decript.Encript(this.clnt_zipcode).toString(),
        type:"pres"
      });

      var bpval;
      if(this.BP_01 != null){
        bpval = this.BP_01+"/"+this.BP_02;
      }

      var discharge_time;
      if(this.discharge_time1 !=undefined && this.discharge_time2 != undefined){
        // var hours = this.discharge_time1  < 10 ? '0' : '';
        // var mins = this.discharge_time2  < 10 ? '0' : '';
        discharge_time = this.discharge_time1 + ":" + this.discharge_time2;
      }else{
        discharge_time = "";
      }

      var user_id;
      if(Helper_Class.getInpatientFlow() == "front-desk"){
        user_id = this.doc_id;
      }else{
        user_id = this.user_id;
      }

      var save_data = JSON.stringify({
        prov_id: user_id,
        client_id:this.client_reg_id,
        relation_id: "1",
        salutation:this.clnt_sal,
        first_name:encrypt_decript.Encript(this.clnt_fname).toString(),
        middle_name:midname,
        last_name: encrypt_decript.Encript(this.clnt_lname).toString(),
        age: this.clnt_age,
        dob: this.clnt_dob,
        gender: encrypt_decript.Encript(this.clnt_gender).toString(),
        mem_mobile: encrypt_decript.Encript(this.clnt_cont_number).toString(),
        medicare_id: "1",
        specialization_id:this.spl_id,
        hptl_id:this.hospital_id,
        date: this.CurrentDatetime,
        time:"1",
        token:this.tokenumber,
        session: encrypt_decript.Encript(curr_session).toString(),
        symptoms: this.clnt_symptom,
        newsymptoms:new_symptoms,
        app_type:"1",
        height:this.height_txt,
        height_measure:this.hmeasure,
        weight: this.weight_txt,
        weight_measure: this.wmeasure,
        bmi: this.bmi_txt,
        temparature:this.temp_txt,
        bp: bpval,
        complaints: this.chief_comp,
        present_illness: this.pres_ill,
        medical_history: this.med_hist,
        family_history: this.family_hist,
        physical_exam: this.physical_exam,
        home_care:this.homecarevalue,
        blood_group:this.clnt_blood,
        occupation: this.clnt_occupation,
        income:this.clnt_income,
        guardian:this.clnt_fg_name,
        citizenship:encrypt_decript.Encript("Indian").toString(),
        nationality:encrypt_decript.Encript("Indian").toString(),
        address:address,
        emergency_contact: this.emer_contact,
        ward_id: this.ward_id,
        bed_no: this.send_bed_no,
        flag:"mobile",
        ref_by:this.refer_txt,
        covid_symp:this.finalcovidsymp,
        covid_comorb:this.finalcomorbList,
        covid_observ:this.observList,
        is_covid:this.covid_type,
        covid_dur:this.covid_duration,
        location:this.h_location,
        address1:this.h_address1,
        address2:this.h_address2,
        city: this.h_city,
        state:this.h_state,
        country:this.h_country,
        zipcode:this.h_zipcode,
        refer_by: this.refer_txt,
        discharge_date:this.discharge_date,
        discharge_time:discharge_time,
        marital_status : this.clnt_marital, // single or married
        prev_hosp : this.pre_hosp, // yes or no
        prev_hosp_det : this.pre_hospital_details,
        allergy : this.allergy, // yes or no
        drug_details : this.drug_details,
        env_details : this.env_details,
        food_details : this.food_details,
      });

      

      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'inpat/nadm', save_data ,
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            
            if(obj.amission_id!=undefined){
              this.toastr.success("Addmission created successfully");
              // this.navCtrl.setRoot('InpatientListPage', this.navParams);
              if(Helper_Class.getInpatientFlow() == "front-desk"){
                this.frontservice.sendMessage("Patients");
              }else{
                this.messageservice.sendMessage("in_patient");
              }
              this.app_id=obj.app_id;
              this.admission_id=obj.admission_id;

              Doc_Helper.setApp_id(this.app_id);              
              Doc_Helper.setAdmission_id(obj.admission_id);
              
              if(this.Client_type =="New"){
                this.client_reg_id = obj.client_id;
                Doc_Helper.setClient_id(obj.client_id);
                Doc_Helper.setRel_id("1");

                this.Client_type="Existing";
              }
              
              this.update_btn=false;
              this.Confirm_btn=true;              
            } else {
              this.toastr.error(obj.result);
            }
          });
    }
  }

  //update appointment 
  update_admission(){
    var create_flag = true;
    if( this.clnt_sal == "select"){
      create_flag = false;
    }
    if(create_flag == false ){
      if(this.clnt_sal == "select"){
        this.toastr.error(Message_data.sltSalutation);
      }
    }else{
      var bpval;
      if(this.BP_01 != null){
        bpval = this.BP_01+"/"+this.BP_02;
      }
  
      var midname;
      if(this.clnt_mname !=undefined){
        midname=encrypt_decript.Encript(this.clnt_mname).toString()
      } 
  
      var addr2;
      if(this.clnt_address2 != undefined){
        addr2 = encrypt_decript.Encript(this.clnt_address2).toString()
  
      } else {
        addr2 = undefined;
      }
  
      var get_date = Date_Formate(this.currentDate);
  
      var curr_datetime = new Date(Date_Formate(get_date) + " " + this.currentTime);
      var curr_session;
      if (curr_datetime.getHours() >= 0 && curr_datetime.getHours() < 12) {
        curr_session = "Morning";
      }
  
      if (curr_datetime.getHours() >= 12 && curr_datetime.getHours() < 16) {
        curr_session = "Afternoon";
      }
  
      if (curr_datetime.getHours() >= 16 && curr_datetime.getHours() < 22) {
        curr_session = "Evening";
      }
  
      curr_session = "Evening";
      if(this.clnt_address1 != null && this.clnt_address1 != undefined){
       var  address1=this.clnt_address1;
      }
      if(addr2!= null &&addr2 != undefined){
        var  address2=addr2;
       }
      var address =[];
      address.push({
        address1:encrypt_decript.Encript(this.clnt_address1).toString(),
        address2:addr2,
        location:this.clnt_location_id,
        city:this.clnt_city_id,
        state:this.clnt_state_id,
        country:this.clnt_country_id,
        zipcode:encrypt_decript.Encript(this.clnt_zipcode).toString(),
        type:"pres"
      });
  
      var discharge_time;
      if(this.discharge_time1 !=undefined && this.discharge_time2 != undefined){
        // var hours = this.discharge_time1  < 10 ? ('0'+ this.discharge_time1) : this.discharge_time1;
        // var mins = this.discharge_time2  < 10 ?  ('0'+ this.discharge_time2) : this.discharge_time2;
        discharge_time = ConvertTimeformat("",this.discharge_time1 + ":" + this.discharge_time2 + " " + this.discharge_time3);
      }else{
        discharge_time = "";
      }
      

      var user_id;
      if(Helper_Class.getInpatientFlow() == "front-desk"){
        user_id = this.doc_id;
      }else{
        user_id = this.user_id;
      }

      var send_data;
      if(this.nurse_flow == true){
        send_data = {
          temparature: this.temp_txt,
          height:this.height_txt,
          height_measure:this.hmeasure,
          weight:this.weight_txt,
          weight_measure:this.wmeasure,
          bmi: this.bmi_txt,
          bp: bpval,
          complaints: this.chief_comp,
          present_illness: this.pres_ill,
          medical_history: this.med_hist,
          family_history: this.family_hist,
          physical_exam: this.physical_exam,
          admission_id: this.admission_id,
          discharge_date:this.discharge_date,
          discharge_time:discharge_time,
          ward_id: this.ward_id,
          bed_no: this.send_bed_no,
          marital_status : this.clnt_marital, // single or married
          prev_hosp : this.pre_hosp, // yes or no
          prev_hosp_det : this.pre_hospital_details,
          allergy : this.allergy, // yes or no
          drug_details : this.drug_details,
          env_details : this.env_details,
          food_details : this.food_details,
          created_by:Nurse_Helper.getINPatient().created_by,
          creator_type:Nurse_Helper.getINPatient().creator_type,
        };
      }else{
        send_data = {
          prov_id: user_id,
          temparature: this.temp_txt,
          height:this.height_txt,
          height_measure:this.hmeasure,
          weight:this.weight_txt,
          weight_measure:this.wmeasure,
          bmi: this.bmi_txt,
          bp: bpval,
          complaints: this.chief_comp,
          present_illness: this.pres_ill,
          medical_history: this.med_hist,
          family_history: this.family_hist,
          physical_exam: this.physical_exam,
          admission_id: this.admission_id,
          discharge_date:this.discharge_date,
          discharge_time:discharge_time,
          ward_id: this.ward_id,
          bed_no: this.send_bed_no,
          marital_status : this.clnt_marital, // single or married
          prev_hosp : this.pre_hosp, // yes or no
          prev_hosp_det : this.pre_hospital_details,
          allergy : this.allergy, // yes or no
          drug_details : this.drug_details,
          env_details : this.env_details,
          food_details : this.food_details,
        };
      }
  


      

      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'inpat/updaadm/',
        JSON.stringify(send_data),
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            
            if(obj.key=="1"){
              this.toastr.success(obj.result);
              var inpat = {
                prov_id: this.user_id,
                client_id:this.client_reg_id,
                relation_id: "1",
                app_id:this.app_id,
                salutation:this.clnt_sal,
                first_name:encrypt_decript.Encript(this.clnt_fname).toString(),
                middle_name:midname,
                last_name: encrypt_decript.Encript(this.clnt_lname).toString(),
                age: this.clnt_age,
                dob: this.clnt_dob,
                gender: encrypt_decript.Encript(this.clnt_gender).toString(),
                mem_mobile: encrypt_decript.Encript(this.clnt_cont_number).toString(),
                medicare_id: "1",
                specialization_id:this.spl_id,
                hptl_id:this.hospital_id,
                date: this.CurrentDatetime,
                Appoint_Date: Date_Formate(this.CurrentDatetime),
                time:"1",
                token:this.tokenumber,
                session: encrypt_decript.Encript(curr_session).toString(),
                symptoms: this.clnt_symptom,
                app_type:"2",
                temparature: this.temp_txt,
                height:this.height_txt,
                height_measure:this.hmeasure,
                weight:this.weight_txt,
                weight_measure:this.wmeasure,
                bmi: this.bmi_txt,
                bp: bpval,
                complaints: this.chief_comp,
                present_illness: this.pres_ill,
                medical_history: this.med_hist,
                family_history: this.family_hist,
                physical_exam: this.physical_exam,
                admission_id: this.admission_id,
                home_care:this.homecarevalue,
                blood_group:this.clnt_blood,
                occupation: this.clnt_occupation,
                income:this.clnt_income,
                guardian:this.clnt_fg_name,
                citizenship:encrypt_decript.Encript("Indian").toString(),
                nationality:encrypt_decript.Encript("Indian").toString(),
                address:address,
                address1:address1,
                address2:address2,
                emergency_contact: this.emer_contact,
                ward_id: this.ward_id,
                bed_no: this.bed_no,
                flag:"mobile",
                refer_by:this.refer_txt,
                covid_symp:this.finalcovidsymp,
                covid_comorb:this.finalcomorbList,
                covid_observ:this.observList,
                is_covid:this.covid_type,
                covid_dur:this.covid_duration,
                location:this.clnt_location,
                city: this.clnt_city,
                state:this.clnt_state,
                country:this.clnt_country,
                zipcode:this.clnt_zipcode,
                
  
                //refer_by: this.refer_txt,
              }
              Doc_Helper.setClient_Info(inpat);
              if(Helper_Class.getInpatientFlow() == "front-desk"){
                this.frontservice.sendMessage("Patients");
              }
              //this.navCtrl.push('DoctorHomepagePage');
              
            } else {
              this.toastr.error(obj.result);
            }
          });
    }
    
  }

  Write_prescription(){
    if (this.clnt_mname != undefined && this.clnt_mname != null) {
      if (this.clnt_mname != "") {
        this.patient_name = this.clnt_fname + " " + this.clnt_mname + " " + this.clnt_lname;

      } else {
        this.patient_name = this.clnt_fname + " " + this.clnt_lname;
      }
    } else {
        this.patient_name = this.clnt_fname + " " + this.clnt_lname;
    }
    var bpval;
    if(this.BP_01 != null){
      bpval = this.BP_01+"/"+this.BP_02;
    }

      var inpat_pres:any = []
    inpat_pres = {
      prov_id: this.user_id,
      client_id:this.client_reg_id,
      relation_id: "1",
      salutation:this.clnt_sal,
      Gender_data: this.clnt_gender,
      client_name: this.patient_name,
      Age_data: this.clnt_age,
      app_id: this.app_id,
      Client_id: this.client_reg_id,
      rel_id: "1",
      sub_id:"",
      Case_Clnt_dob: this.clnt_dob,
      spl_id: this.spl_id,
      hptl_clinic_id:this.hospital_id,
      mobile: this.clnt_cont_number.toString(),
      spl: this.spl,
      temparature: this.temp_txt,
      height:this.height_txt,
      height_measure:this.hmeasure,
      weight:this.weight_txt,
      weight_measure:this.wmeasure,
      bmi: this.bmi_txt,
      bp: bpval,
      symptoms:this.clnt_symptom,
      medicare_name:this.treatement_type,
      Appoint_Date: Date_Formate(this.CurrentDatetime),
      addmed: "write",
      AppFlow: "InpatMedPres",
     // ref_by:this.refer_txt,
      covid_symp:this.finalcovidsymp,
      covid_comorb:this.finalcomorbList,
      covid_observ:this.observList,
      is_covid:this.covid_type,
      covid_dur: this.covid_duration,
      location:this.h_location,
      address1:this.h_address1,
      address2:this.h_address2,
      city: this.h_city,
      state:this.h_state,
      country:this.h_country,
      zipcode:this.h_zipcode,
      refer_by: this.refer_txt,
    };
    Doc_Helper.setClient_Info(inpat_pres);
    Doc_Helper.setDiagAppflow("medical");
    this.messageservice.sendMessage("medprescription");
    // this.router.navigateByUrl("/Walk_in_med_pres");
  }

  Write_prescription_forgyno(){
    if (this.clnt_mname != undefined && this.clnt_mname != null) {
      if (this.clnt_mname != "") {
        this.patient_name = this.clnt_fname + " " + this.clnt_mname + " " + this.clnt_lname;

      } else {
        this.patient_name = this.clnt_fname + " " + this.clnt_lname;
      }
    } else {
        this.patient_name = this.clnt_fname + " " + this.clnt_lname;
    }
    var bpval;
    if(this.BP_01 != null){
      bpval = this.BP_01+"/"+this.BP_02;
    }

      var inpat_pres:any = []
    inpat_pres = {
      prov_id: this.user_id,
      client_id:this.client_reg_id,
      relation_id: "1",
      salutation:this.clnt_sal,
      Gender_data: this.clnt_gender,
      client_name: this.patient_name,
      Age_data: this.clnt_age,
      app_id: this.app_id,
      Client_id: this.client_reg_id,
      rel_id: "1",
      sub_id:"",
      Case_Clnt_dob: this.clnt_dob,
      spl_id: this.spl_id,
      hptl_clinic_id:this.hospital_id,
      mobile: this.clnt_cont_number.toString(),
      spl: this.spl,
      temparature: this.temp_txt,
      height:this.height_txt,
      height_measure:this.hmeasure,
      weight:this.weight_txt,
      weight_measure:this.wmeasure,
      bmi: this.bmi_txt,
      bp: bpval,
      symptoms:this.clnt_symptom,
      medicare_name:this.treatement_type,
      Appoint_Date: Date_Formate(this.CurrentDatetime),
      addmed: "write",
      AppFlow: "InpatMedPres",
     // ref_by:this.refer_txt,
      covid_symp:this.finalcovidsymp,
      covid_comorb:this.finalcomorbList,
      covid_observ:this.observList,
      is_covid:this.covid_type,
      covid_dur: this.covid_duration,
      location:this.h_location,
      address1:this.h_address1,
      address2:this.h_address2,
      city: this.h_city,
      state:this.h_state,
      country:this.h_country,
      zipcode:this.h_zipcode,
      refer_by: this.refer_txt,
    };
    Doc_Helper.setGynoMedPres(inpat_pres);

  }

  getCountries(flag) {
    this.clnt_country_list = [];

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(this.get_country_url, { headers: headers }).subscribe(
      data => {
        this.clnt_country_list = [];

        if (data.json().countries != null) {
          this.clnt_country_list = data.json().countries;
          for (var c = 0; c < this.clnt_country_list.length; c++) {
            if (this.clnt_country_list[c].country_id == this.clnt_country_id) {
              this.clnt_country = this.clnt_country_list[c].description;
              this.clnt_country_id = this.clnt_country_list[c].country_id;
              this.getStates(this.clnt_country_id, flag);
              break;
            }
          }
        }
      },
      error => {
        this.toastr.error(Message_data.unableToFetchData);
      })
  }

  countryChange(clnt_country, flag){
    for (var c = 0; c < this.clnt_country_list.length; c++) {
      if (this.clnt_country_list[c].description == clnt_country) {
        this.clnt_country = this.clnt_country_list[c].description;
        this.clnt_country_id = this.clnt_country_list[c].country_id;

        this.clnt_state_list=[];
        this.clnt_city_list=[];
        this.clnt_location_id=undefined;
        this.clnt_location="";
        this.clnt_zipcode="";
        this.getStates(this.clnt_country_id, flag);
      }
    }
  }

  getStates(country, flag){
    this.clnt_state_list = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.get_state_url,JSON.stringify({country_id : country }), 
    { headers: headers }).subscribe(
      data => {
        if (data.json().states != null) {
          this.clnt_state_list = data.json().states;
          for (var i = 0; i < this.clnt_state_list.length; i++) {
            if (this.clnt_state_list[i].state_id == this.clnt_state_id) {
              this.clnt_state_id = this.clnt_state_list[i].state_id;
              this.clnt_state = this.clnt_state_list[i].description;
              this.getCities(this.clnt_state_id, flag);
              break;
            }
          }
        }
      })
  }

  stateChange(clnt_state, flag){
    for (var i = 0; i < this.clnt_state_list.length; i++) {
      if (this.clnt_state_list[i].description == clnt_state) {
        this.clnt_state_id = this.clnt_state_list[i].state_id;
        this.clnt_state = this.clnt_state_list[i].description;
        this.clnt_city_list=[];
        this.clnt_city_id=undefined;
        this.clnt_location_id=undefined;
        this.clnt_location="";
        this.clnt_zipcode="";
        this.getCities(this.clnt_state_id, flag);
      }
    }
  }

  getCities(state, flag){
    this.clnt_city_list = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.get_city_url,JSON.stringify({state_id : state }), 
    { headers: headers }).subscribe(
      data => {
        if (data.json().cities != null) {
          this.clnt_city_list = data.json().cities;
          for (var i = 0; i < this.clnt_city_list.length; i++) {
            if (this.clnt_city_list[i].district_id == this.clnt_city_id) {
              this.clnt_city_id = this.clnt_city_list[i].district_id;
              this.clnt_city = this.clnt_city_list[i].description;
              //if(this.new_user ==true){
                this.getLocations(this.clnt_city_id);

              //} 
              break;

            } else {
              this.clnt_city = this.clnt_city_list[0].description;
            }
          }
        }
      })
  }

  cityChange(clnt_city, flag){
    this.clnt_location_list=[];
    for (var i = 0; i < this.clnt_city_list.length; i++) {
      if (this.clnt_city_list[i].description == clnt_city) {
        this.clnt_city_id = this.clnt_city_list[i].district_id;
        this.clnt_city = this.clnt_city_list[i].description;
        this.clnt_location_id=undefined;
        this.clnt_location="";
        this.clnt_zipcode="";
      }
    }
  }

  getLocations(city){
    this.clnt_location_list = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.get_location_url,JSON.stringify({city_id : city }), 
    { headers: headers }).subscribe(
      data => {
        if (data.json().locations != null && data.json().locations.length != 0) {
          this.clnt_location_list = data.json().locations;
          for (var i = 0; i < this.clnt_location_list.length; i++) {
            if (this.clnt_location_list[i].location_id == this.clnt_location_id) {
              this.clnt_location_id = this.clnt_location_list[i].location_id;
              this.clnt_location = this.clnt_location_list[i].description;
              this.clnt_zipcode=this.clnt_location_list[i].pincode;
              this.clnt_location_list=[];
              break;
            }
          }
        }
      })
  }

  location_change(locat_desc) {
    this.clnt_location_list =[];
    if (locat_desc != null && locat_desc.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.get_locname_url, JSON.stringify({
        name: locat_desc,
        city_id: this.clnt_city_id,
      }), { headers: headers }).subscribe(
        data => {
          if (data.json().locations != null && data.json().locations.length != 0) {
            this.clnt_location_list = data.json().locations;
          }
        },
        error => {
          this.toastr.error(Message_data.unableToFetchDocDet);
        }
      )

    } else {
      this.clnt_location_list = [];
    }
  }

  location_selected(location){
    this.clnt_location = location;
    for (var i = 0; i < this.clnt_location_list.length; i++) {
      if (this.clnt_location_list[i].location_id == location.location_id) {
        this.clnt_location_id = this.clnt_location_list[i].location_id;
        this.clnt_location = this.clnt_location_list[i].description;
        this.clnt_zipcode=this.clnt_location_list[i].pincode;
      }
    }
    this.clnt_location_list = [];
  }

  // callback...
  myCallbackFunction = function (_params) {
    return new Promise((resolve, reject) => {
      resolve(_params);
    });
  }

  goToPatientlist() {
    // this.router.navigateByUrl("/inpatient_list");
    // this.navCtrl.setRoot('InpatientListPage', this.navParams);
    if(Helper_Class.getInpatientFlow() == "front-desk"){
      this.frontservice.sendMessage("Patients");
    }else if(Helper_Class.getInpatientFlow() == "nurse"){
      this.nurseservice.sendMessage("inpatient");
    }else{
      this.messageservice.sendMessage("in_patient");
    }
   
  }

  calories_data() {
    
    if((this.height_txt != undefined && this.height_txt.length !=0) 
      || (this.weight_txt != undefined && this.weight_txt.length !=0)    
      || (this.chief_comp != undefined && this.chief_comp.length !=0)  
      || (this.family_hist != undefined && this.family_hist.length !=0) 
      || (this.pres_ill != undefined && this.pres_ill.length !=0) 
      || (this.med_hist != undefined && this.med_hist.length !=0) 
      || (this.physical_exam != undefined && this.physical_exam.length !=0 )) {
      
        if(this.app_id == undefined){
          this.Confirm_btn=false;
        }
      
        if(this.height_txt != undefined && this.height_txt.length !=0){
          this.encheight = encrypt_decript.Encript(this.height_txt.toString());
        }
        if(this.weight_txt != undefined && this.weight_txt.length !=0){
          this.encweight = encrypt_decript.Encript(this.weight_txt.toString());
        }
    }else{
      this.Confirm_btn=true;
    }

    if (this.height_txt == undefined || this.height_txt == "" || this.weight_txt == undefined || this.weight_txt == "") {
      this.bmi_txt = "";
      
    } else {
      if (this.height_txt != undefined) {
        if (this.hmeasure == "cms") {
          this.height_txt = this.height_txt.toString().replace(/[^0-9]/g, '');
        } else {
          this.height_txt = this.height_txt.toString().replace('/[^-0-9\.]/g', '');
          if (this.height_txt.indexOf('.') !== -1) {
            if (this.height_txt.split('.').length > 2) {
              this.height_txt = this.height_txt.height.slice(0, -1);
            }
          }
        }

        if (this.hmeasure == "cms") {
          if (parseFloat(this.height_txt) > 300) {
            this.height_txt = this.height_txt.slice(0, -1);
          }
        } else {
          if (parseFloat(this.height_txt) > 100) {
            this.height_txt = this.height_txt.slice(0, -1);
          }
        }
      }

      if (this.wmeasure == "kgs" && this.hmeasure == "inch") {
        var pound = this.weight_txt * 2.20462;
        var Height_txt = parseFloat(this.height_txt)
        var dat = pound / (Height_txt * Height_txt);
        var to = dat * 703;
        this.bmi_txt = Math.round(to);

      } else if (this.wmeasure == "kgs" && this.hmeasure == "cms") {
        var meter = parseFloat(this.height_txt) / 100.00;
        var to = parseFloat(this.weight_txt) / (meter * meter);
        this.bmi_txt = Math.round(to);

      } else if (this.wmeasure == "lbs" && this.hmeasure == "inch") {
        var Height_txt = parseFloat(this.height_txt);
        var dat = this.weight_txt / (Height_txt * Height_txt);
        var to = dat * 703;
        this.bmi_txt = Math.round(to);

      } else if (this.wmeasure == "lbs" && this.hmeasure == "cms") {
        var Height_txt = parseFloat(this.height_txt);
        var inch = Height_txt * 0.393701;
        var dat = this.weight_txt / (inch * inch);
        var to = dat * 703;
        this.bmi_txt = Math.round(to);
      }
    }
  }

  dischargeSummary(){
    if (this.clnt_mname != undefined && this.clnt_mname != null) {
      if (this.clnt_mname != "") {
        this.patient_name = this.clnt_fname + " " + this.clnt_mname + " " + this.clnt_lname;

      } else {
        this.patient_name = this.clnt_fname + " " + this.clnt_lname;
      }
    } else {
        this.patient_name = this.clnt_fname + " " + this.clnt_lname;
    }
    var bpval;
    if(this.BP_01 != null){
      bpval = this.BP_01+"/"+this.BP_02;
    }
    var inpat_pres = {
      prov_id: this.user_id,
      client_id:this.client_reg_id,
      relation_id: "1",
      salutation:this.clnt_sal,
      Gender_data: this.clnt_gender,
      client_name: this.patient_name,
      Age_data: this.clnt_age,
      app_id: this.app_id,
      Client_id: this.client_reg_id,
      rel_id: "1",
      sub_id:"",
      Case_Clnt_dob: this.clnt_dob,
      spl_id: this.spl_id,
      hptl_clinic_id:this.hospital_id,
      mobile: this.clnt_cont_number.toString(),
      spl: this.spl,
      temparature: this.temp_txt,
      height:this.height_txt,
      height_measure:this.hmeasure,
      weight:this.weight_txt,
      weight_measure:this.wmeasure,
      bmi: this.bmi_txt,
      bp: bpval,
      symptoms:this.clnt_symptom,
      medicare_name:this.treatement_type,
      Appoint_Date: Date_Formate(this.CurrentDatetime),
      addmed: "write",
      AppFlow: "InpatMedPres",
      // ref_by:this.refer_txt,
      covid_symp:this.finalcovidsymp,
      covid_comorb:this.finalcomorbList,
      covid_observ:this.observList,
      is_covid:this.covid_type,
      covid_dur: this.covid_duration,
      location:this.h_location,
      address1:this.h_address1,
      address2:this.h_address2,
      city: this.h_city,
      state:this.h_state,
      country:this.h_country,
      zipcode:this.h_zipcode,
      refer_by: this.refer_txt,
    };
    Doc_Helper.setClient_Info(inpat_pres);
    if(this.spl_id =="11"){
      this.Write_prescription_forgyno();
      var gyno = {
        life_style_id:this.life_style_id
      }
      Doc_Helper.setGynoInpatDS(gyno);
      this.messageservice.sendMessage("inpatient_guno_Discharge_view");
      // this.navCtrl.push("GynacDisSummaryPage",{
      //   life_style_id:this.life_style_id,
      // });

    } else {
      Doc_Helper.setMobile_no(encrypt_decript.Encript(this.clnt_cont_number));
      // this.router.navigateByUrl("/inpatient_Discharge_summary");
    
      this.messageservice.sendMessage("inPatientDS");

    }
    
    Helper_Class.set_med_pres_id(null);
    Helper_Class.set_diag_pres_id(null);
  }

  view_prescription() {
    const dialogRef5 = this.dialog.open(InpatMedprescViewComponent, {
      width: '700px',
      height: '300px',
      data:{inpatient_id: this.admission_id}
    });
  
    dialogRef5.afterClosed().subscribe(result => {
      
      //this.dis_txt = result;
    });
  //   var bpval;
  // if(this.BP_01 != null){
  //   bpval = this.BP_01+"/"+this.BP_02;
  // }
  //   var inpat_pres:any = [];
  //   inpat_pres = {
  //     prov_id: this.user_id,
  //     client_id:this.client_reg_id,
  //     relation_id: "1",
  //     salutation:this.clnt_sal,
  //     Age_data: this.clnt_age,
  //     app_id: this.app_id,
  //     Client_id: this.client_reg_id,

  //       rel_id: "1",
  //     sub_id:"",
  //     Case_Clnt_dob: this.clnt_dob,
  //     spl_id: this.spl_id,
  //     hptl_clinic_id:this.hospital_id,
  //     mobile: this.clnt_cont_number.toString(),
  //     spl: this.spl,
  //     temparature: this.temp_txt,
  //     height:this.height_txt,
  //     height_measure:this.hmeasure,
  //     weight:this.weight_txt,
  //     weight_measure:this.wmeasure,
  //     bmi: this.bmi_txt,
  //     bp: bpval,
  //     symptoms:this.clnt_symptom,
  //     medicare_name:this.treatement_type,
  //     Appoint_Date: Date_Formate(this.CurrentDatetime),
  //     addmed: "view",
  //     AppFlow: "InpatMedPres",
  //     covid_symp:this.finalcovidsymp,
  //     covid_comorb:this.finalcomorbList,
  //     covid_observ:this.observList,
  //     is_covid:this.covid_type,
  //     covid_dur: this.covid_duration,
  //     location:this.clnt_location,
  //   city: this.clnt_city,
  //   state:this.clnt_state,
  //   country:this.clnt_country,
  //   zipcode:this.clnt_zipcode,
  //   refer_by: this.refer_txt,
  //   }

  //   Doc_Helper.setClient_Info(inpat_pres);
  //   // this.cashsheet.sendCasesheetMessage("max");
  //   this.messageservice.sendMessage("medprescription");
  //   // this.router.navigateByUrl("/Walk_in_med_pres")
  }

  getReferralData(e) {
    this.Refered_doctor = [];
    this.Refered_name = [];
    this.refer_txt = e.target.value.toString();
    if (this.refer_txt != "" && this.refer_txt.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'search/docbyhns/',
        JSON.stringify({
          doc_name: this.refer_txt
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            this.Refered_name = obj.doctors;
            
            if (this.Refered_name != undefined) {
              for (var i = 0; i < this.Refered_name.length; i++) {
                var docname;
                if(this.Refered_name[i].middle_name !=undefined){
                  docname=this.Refered_name[i].first_name + " " + this.Refered_name[i].middle_name + " " + this.Refered_name[i].last_name;

                } else {
                  docname=this.Refered_name[i].first_name + " " + this.Refered_name[i].last_name;
                }
                this.Refered_doctor.push(docname);
              }
            }
            var name = this.refer_txt;
            this.Refered_doctor = this.Refered_doctor.filter(function (el) {
              return el.toLowerCase().indexOf(name.toLowerCase()) > -1;
            }.bind(this));

            
            // if (this.Refered_doctor.length == 0) {
            //   this.fun_toast("No doctor found");
            // }
          },
          error => {
              this.toastr.error(Message_data.defaultErr);
          }
        )

    } else {
      this.Refered_doctor = [];
    }
  }
  getVisitingDoc(e){
    this.visiting_doctor = [];
    var docName = [];
    this.visiting_doc_name = e.target.value.toString();
    if (this.visiting_doc_name != "" && this.visiting_doc_name.length > 2) {
      var sendData ={
        doc_name: this.visiting_doc_name,
        hospital_id:this.hospital_id
      }
      
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'search/docbyhns/',
        JSON.stringify(sendData),
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            
            this.docName = obj.doctors;
            
            if (this.docName != undefined) {
              for (var i = 0; i < this.docName.length; i++) {
                var docname;
                if(this.docName[i].middle_name !=undefined){
                  docname=this.docName[i].first_name + " " + this.docName[i].middle_name + " " + this.docName[i].last_name;

                } else {
                  docname=this.docName[i].first_name + " " + this.docName[i].last_name;
                }
                this.visiting_doctor.push({
                    name: docname,
                    id: this.docName[i].prov_id,
                    type : this.docName[i].doctor_type,  
                });
              }
            }
            var name = this.visiting_doc_name;
            // this.visiting_doctor = this.visiting_doctor.filter(function (el) {
            //   return el.toLowerCase().indexOf(name.toLowerCase()) > -1;
            // }.bind(this));

            
            // if (this.visiting_doctor.length == 0) {
            //   this.fun_toast("No doctor found");
            // }
          },
          error => {
              this.toastr.error(Message_data.defaultErr);
          }
        )

    } else {
      this.visiting_doctor = [];
    }
  }
  Select_refer_doctor(item) {
    this.refer_txt = item;
    this.Refered_doctor = [];
  }
  Select_visiting_doctor(item){
      
      for(var i=0; i<this.visiting_doctor.length; i++){
        if( this.visiting_doctor[i].id == item){
          this.is_consultent = this.visiting_doctor[i].type != "doctor" ? "1" : "0"; 
          this.visiting_doc_id = this.visiting_doctor[i].id;
        }
      }  

  }
  addVisiting(){
    var providername;
    if(this.userinfo.middle_name != undefined){
      providername = this.userinfo.first_name + " " + this.userinfo.middle_name + " " + this.userinfo.last_name; 
    }else{  
      if( this.userinfo.last_name != undefined){
        providername = this.userinfo.first_name + " " +  this.userinfo.last_name; 
      }else{
        providername = this.userinfo.first_name; 
      }
    }
    var billable = this.doc_billable == "0" ? "No" : "Yes";
    this.visitignArrray.push({
      visiting_doc_name: this.visiting_doc_name,
      remarks: this.visiting_remarks,
      billable : billable,
      date : Date_Formate(this.CurrentDatetime),
      time : this.currentTime
    });
    var visitingsend = {
      inpatient_id:  this.inpatientId,
      doc_reg_id:  this.visiting_doc_id,
      is_consultant: this.is_consultent,
      is_billable: this.doc_billable,
      remarks: this.visiting_remarks,
      created_by: providername,
      hosp_clinic_id: this.hospital_id,
    }
    
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() +"inpat/sdv", JSON.stringify(visitingsend), { headers: headers }).subscribe(
      data => {
        var obj = data.json();
        
        if(obj !=undefined){
         
        }
      })
  }
  OnDateChangeDOB(e){
  this.clnt_dob = e;
  if(e != null){
    this.disable_age = true;
  }
  var headers = new Headers();
  headers.append('Content-Type', 'application/json');
  this.http.post(ipaddress.getIp + "adm/ddif", JSON.stringify({
    dobdate:  Date_Formate(this.clnt_dob),
    curdate:  Date_Formate(this.CurrentDatetime),
    spl:this.spl_id,
  }), { headers: headers }).subscribe(
    data => {
      var obj = data.json();
      
      if(obj !=undefined){
        this.clnt_age=obj.value;
      }
    })
  }

  checkchar(evt){
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)){
            return false;
          }else{
            return true;
          }
  }

  getSymptoms(){
    var searchString;
    if (this.clnt_symptom.indexOf(',') > -1) { 
      var dataval = this.clnt_symptom.split(","); 
      searchString = dataval[dataval.length-1].trim();
    } else {
      searchString = this.clnt_symptom;
    }

    if(searchString.length >2){
      var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp.toString() + 'adm/gsymbn/',
          JSON.stringify({
            symp_name: searchString
          }),
          { headers: headers })
          .subscribe(
            response => {
              var obj = JSON.parse(response["_body"]);
              if (obj.symptoms != null && obj.symptoms != undefined && obj.symptoms != ""  && obj.symptoms.length != 0) {
                this.newsymptoms_flag = false; 
                this.symptoms_list = [];
                this.symptomlist = [];
                this.symptom_data = [];
                this.symptoms_list = obj.symptoms;
                for (var i = 0; i < obj.symptoms.length; i++) {
                  this.symptom_data.push(obj.symptoms[i].description);
                }
  
                this.symptomlist = this.symptom_data.filter(function (el) {
                  return el.toLowerCase().indexOf(searchString.toLowerCase()) > -1;
                }.bind(this));
                
              }else{
                this.symptomlist = [];
                this.newsymptoms_flag = true; 
              }
            },
            error => {
              
            })
    }
  }

  select_symptom(data){
    this.newsymptoms.push(data);
    for(var j=0;j<this.symptoms_list.length;j++){
      if(this.symptoms_list[j].description == data){
        this.selected_symptoms.push(data);
        break;
      }
    }
    
    if (this.clnt_symptom.indexOf(',') > -1) { 
      var dataval = this.clnt_symptom.split(","); 
      dataval.pop();
      this.clnt_symptom = dataval.join(",");
      
      this.clnt_symptom = this.clnt_symptom+","+data;
    } else {
      this.clnt_symptom = data;
    }
    this.symptomlist=[];    
  }

  ChangeAge(){
    if(this.clnt_age != ""){
      this.disable_dob = true;
      this.clnt_dob = "";
    }
    else{
      this.disable_dob = false;
    }
  }

  changeSalutation(e){
    if(e == "1"|| e == "6")
      this.clnt_gender = "Male";
    else if(e == "2" || e == "3" || e == "4" || e == "7")
      this.clnt_gender = "Female";
    else if(e == "5")
      this.clnt_gender = "Transgender";
    else{
      this.clnt_gender = "";
    }
  }

  filterNew(event,newEvent){
    if(event.trim() != "" && event != undefined  && event != null){
      var new_symptoms = [];
      if (event.indexOf(',') > -1) { 
        var dataval1 = event.trim().split(","); 
        var dataval = dataval1.sort();
        if(newEvent.length != 0){
          new_symptoms = dataval.filter((val) => !newEvent.includes(val))
        }else{
          for(var i = 0;i< dataval.length;i++){
            if(dataval[i].trim() != "" && dataval[i].trim() != undefined && dataval[i].trim() != null){
              new_symptoms.push(dataval[i].trim());
            }
          }
        }
      }else{
        var dataval1 = event.trim().split(",");
        var dataval = dataval1.sort(); 
        if(newEvent.length != 0){
          new_symptoms = dataval.filter((val) => !newEvent.includes(val))
        }else{
          for(var i = 0;i< dataval.length;i++){
            if(dataval[i].trim() != "" && dataval[i].trim() != undefined && dataval[i].trim() != null){
              new_symptoms.push(dataval[i].trim());
            }
          }
        }
      }
      return new_symptoms;
    }
  }
  Casesheet(){
    if (this.clnt_mname != undefined && this.clnt_mname != null) {
      if (this.clnt_mname != "") {
        this.patient_name = this.clnt_fname + " " + this.clnt_mname + " " + this.clnt_lname;

      } else {
        this.patient_name = this.clnt_fname + " " + this.clnt_lname;
      }
    } else {
        this.patient_name = this.clnt_fname + " " + this.clnt_lname;
    }

    this.BP_01 = this.BP_01 != null ? this.BP_01 : "00";
    this.BP_02 = this.BP_02 != null ? this.BP_02 : "00";


   var walkinViewpres = {
      Age_data: this.clnt_age,
      Gender_data:this.clnt_gender,
      app_id: this.app_id,
      client_name: this.patient_name,
      //image: list.profile_image,
      sub_id:"",
      Client_id: this.client_reg_id,
      rel_id: "1",
      relation_id: "1",
      Case_Clnt_dob: this.clnt_dob,
     // relationship_name:list.relationship_name,
      hptl_clinic_id:this.hospital_id,
      spl_id: this.spl_id,
      spl: this.spl,
    //  mobile:list.contact,
      symptoms:this.clnt_symptom,
   //   Appoint_Date: list.lastvisit,
      medicare_name : this.treatement_type,
      inpatient_id : this.admission_id,
      address1:this.h_address1,
      address2:this.h_address2,
      location:this.h_location,
      city:this.h_city,
      state:this.h_state,
      country:this.h_country,
      zipcode:this.h_zipcode,
      hptl_name:Doc_Helper.getHospital_name(),
      height:this.height_txt,
      weight:this.weight_txt,
      height_measure:this.hmeasure,
      weight_measure:this.wmeasure,
      temparature:this.temp_txt,
      bp: this.BP_01+"/"+this.BP_02,
      bmi:this.bmi_txt,
      bmr:this.bmr_txt,
      pulse:this.pulse_txt,
      patient_id:this.admission_id,
      AppFlow: "Inpatient",
      country_id:this.h_country_id,
      state_id:this.h_state_id,
      city_id:this.h_city_id,
    };
     
      Doc_Helper.setClient_Info(walkinViewpres);
      
      this.check_specialization();
      // this.messageservice.sendMessage("medprescription");
  }
  public Gynecology_flag;
  public Is_specilization;
  public Pediatrics_flag;
  public Cardio_flag;
  public Nephrology_flag;
  public Dental_flag;
  public Diabetes_flag;
  public Others_flag;
  public spl_url;
  check_specialization(){
    var oflag:boolean = false;
    var oflag:boolean = false;
    this.Gynecology_flag=false;
    Helper_Class.setapp_flow("0");
    this.Is_specilization = this.spl;
    if(this.Is_specilization=="Gynecology"){
      this.Gynecology_flag=true;
      oflag = true;
    }else if(this.Is_specilization=="Pediatrics"){
        this.Pediatrics_flag=true;
        oflag = true;
    }else if(this.Is_specilization=="Cardiology"){
        this.Cardio_flag=true;
        oflag = true;
    }else if(this.Is_specilization=="Nephrology"){
       this.Nephrology_flag=true;
       oflag = true;
    }else if(this.Is_specilization=="Dental"){
       this.Dental_flag = true;
       oflag = true;
    }else if(this.Is_specilization=="Diabetes"){
      this.Diabetes_flag = true;
      oflag = true;
    }else{
      if(oflag == false){
        this.Others_flag=true;
      }
    } 

    // this.spl_id = spl_id;
    // this.hptl_clinic_id = hptl_clinic_id;
    // this.doc_app_id = this.doc_app_id;

    if(this.Gynecology_flag==true){
      this.Get_gynaretrieval_data();
    }
    if(this.Gynecology_flag==false){
      this.getpatientcasesheetDetails(); 
    }
    
  }
  Get_gynaretrieval_data() {
    Helper_Class.setgynaRet(undefined)
    Helper_Class.setRet(undefined)
  
    var sendata=JSON.stringify({
        doc_reg_id: this.userinfo.user_id,
        client_reg_id: Doc_Helper.getClient_id(),
        relation_id: Doc_Helper.getRel_id(),
        country: ipaddress.country_code
      })
  
  var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'goi/chist',sendata,
        { headers: headers })
        .subscribe(
            response => {
                
                
                var obj = response.json();
                if(obj["key"] != 0){
                  if(obj != null && obj != undefined && obj != ""){
                    Helper_Class.setgynaRet(obj);
                    Helper_Class.setRet(obj);
                    this.messageservice.sendMessage("casesheet_list")
                  }
              }else{
                this.getPatientCaseSheetFields(Doc_Helper.getClient_Info().hptl_clinic_id, Doc_Helper.getClient_Info().spl_id, Doc_Helper.getClient_Info().doc_app_id);
              }
            },
            error => {
                //this.toastr.error("Internet is too slow or no connection, try again");
            }
        )
    }
    getpatientcasesheetDetails(){
      Helper_Class.setRet(null);      
      Helper_Class.setpediaRet(null);
      
      var send_data=JSON.stringify({
          doc_reg_id: this.userinfo.user_id,
          client_reg_id: Doc_Helper.getClient_id(),
          relation_id: Doc_Helper.getRel_id(),
          country:"IN",});
  
     
  
      if(this.Pediatrics_flag==true){
        this.spl_url='pedia/chist';
      }
      else if(this.Cardio_flag==true){
        this.spl_url='cardio/chist';
      }
      else if(this.Nephrology_flag==true){
        this.spl_url='neph/chist';
      }
      else if(this.Dental_flag==true){
        this.spl_url='dental/perdet/';
      }
      else if(this.Diabetes_flag == true){
        this.spl_url = 'diab/chist'
      }
      else{
        this.spl_url='other/chist';
      }
      var headers = new Headers();
      
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + this.spl_url, send_data ,
          { headers: headers })
          .subscribe(
            response => {
             var obj=response.json();
             
             if (obj["key"] != 0) {
                if (obj != null || obj != "" || obj != undefined) {                
                Helper_Class.setRet(obj);      
                  if(this.Pediatrics_flag==true){
                    Helper_Class.setpediaRet(obj);
                  }
                  if(this.Nephrology_flag == true){
                    
                    Helper_nephro_casesheet.nephro_get_ret = obj;
                  }
                  if(this.Dental_flag == true){
                    
                    Helper_Class.Set_den_ret1(obj);
                    this.Dental_Retrieval_2();
                  }
                  if (this.Dental_flag == false && this.Nephrology_flag == false) { 
                    this.messageservice.sendMessage("casesheet_list")
                  }else{
                    this.getPatientCaseSheetFields( this.hospital_id , this.spl_id , Doc_Helper.getApp_id());
                  }
                }
              }else{
                if(Doc_Helper.getCasesheet_flag() == "1"){
                  this.getPatientCaseSheetFields( this.hospital_id , this.spl_id , Doc_Helper.getApp_id());
                }else{
                  this.getPatientCaseSheetFields( this.hospital_id , this.spl_id , Doc_Helper.getApp_id());
                }
              }
  
          },//response
          error => {
                //this.toastr.error("Internet is too slow or no connection, try again");
          })
  
    }
    Dental_Retrieval_2() {
      var sen_pass = JSON.stringify({
           client_reg_id: Doc_Helper.getClient_id(),
           relation_id: Doc_Helper.getRel_id(),
           country: ipaddress.country_code,
           den_case_hist_id: Helper_Class.Get_den_ret1().den_case_hist_id,
         });
         
  
       var headers = new Headers();
       headers.append('Content-Type', 'application/json');
       this.http.post(ipaddress.getIp.toString() + 'dental/patdet/', sen_pass,
         { headers: headers })
         .subscribe(
           response => {
             
             var obj = response.json();
             Helper_Class.Set_den_ret2(obj);
           }
         )
     }
     getPatientCaseSheetFields(hptl_id, spl_id, doc_app_id) {
       
      var getFields = JSON.stringify({
        hosp_id: hptl_id,
        spl_id: spl_id
      });
      
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'adm/gpfhsp/', getFields,
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            
            Helper_Class.setHopitalfieldsRet(obj.pages);
              this.get_case_sheet_data(Doc_Helper.getApp_id());
          },
          error => {
            this.toastr.error(Message_data.defaultErr);
          }
        )
    }
    get_case_sheet_data(doc_app_id) {
      // call cbook
      Doc_Helper.setDischarge_Summary(undefined);
  
        var sen_pass = JSON.stringify({
          doc_reg_id: this.userinfo.user_id,
          client_reg_id: Doc_Helper.getClient_id(),
          relation_id: Doc_Helper.getRel_id(),
          country: ipaddress.country_code
        });
    
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'gen/cbook', sen_pass,
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            
            if (obj["key"] != 0) {
              if (obj != null && obj.length != 0 && obj != undefined && obj != "") {
                Doc_Helper.setDischarge_Summary("S");
                            Helper_Class.setcasesumret(obj);
              } else {
                Helper_Class.setcasesumret(undefined);
              }
            }else {
              Helper_Class.setcasesumret(undefined);
            }
            if (this.Gynecology_flag == true) {
              const dialogRef = this.dialog.open(GynaObestricPopSelectionComponent, {
                width: '280px',
                height: '182px'
              });
            }
            else {
              this.messageservice.sendMessage("casesheet")
            }
          },
          error => {
           //this.toastr.error("Internet is too slow or no connection, try again");
          }
        )
    }

    changeGender(e){
      if(this.clnt_age != undefined && this.clnt_age != null && this.clnt_age != ""){
        if(e == "Male"){
          if(this.clnt_age <= 15)
            this.clnt_sal = "6";
          else
            this.clnt_sal = "1";
        }else if(e == "Female"){
          if(this.clnt_age <= 15)
            this.clnt_sal = "7";
          else
            this.clnt_sal = "2";
        }else if(e == "Transgender")
            this.clnt_sal = "5";
        else{
          this.clnt_sal = "";
        }
      }else{
        if(e == "Male"){
            this.clnt_sal = "1";
        }else if(e == "Female"){
            this.clnt_sal = "2";
        }else if(e == "Transgender")
            this.clnt_sal = "5";
        else{
          this.clnt_sal = "";
        }
      }
    }
    print_area() {
      let printContents, popupWin;
      printContents = document.getElementById('inpatientInfoPrint')!.innerHTML;
      popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
      popupWin.document.open();
      popupWin.document.write(`
      <head>
        <title>Bill</title>
        <link rel="stylesheet" media="screen" href="">
        <style>
        @page { size: auto;  margin: 0mm; }
        .table_class {
          background-color: rgb(108, 180, 248);
        }
          
        table.table td {
          padding: 0px 15px !important;
          border: 1px solid #000 !important;
          height: 30px !important;
          font-size: 12px !important;
        }
        .table th {
          height: 25px;
          color: #000;
          padding: 8px 0px;
          background: #fff;
          border: 1px solid #000 !important;
        }
        .align_left{
          width: 50%;
          padding-left: 10px;
        }
        .pRow{
          padding-left: 20px;
        }
        </style>
      </head>
          <body onload="window.print();window.onfocus=function(){ window.close();}">${printContents}</body>
        </html>`
      );
      popupWin.document.close();
    }

    getBedList(e,flag){
      this.bedList = [];
      var send_data = {
        hptl_clinic_id:this.hospital_id,
        ward_id:this.ward_id,
        bed_no:e
      }
      

      if (e != null && e.length != 0) {
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp.toString() + 'inpat/gbn',JSON.stringify(send_data),
          { headers: headers })
          .subscribe(
            response => {
              
              var obj = JSON.parse(response["_body"]);  
              
              if(obj.key != 0){
                if(flag == "ret"){
                  this.bed_no = obj.bed_details[0].bed_no + " - " + obj.bed_details[0].bed_type;
                }else{
                  for(var i = 0;i < obj.bed_details.length; i++){
                    this.bedList.push({
                      bed_no:obj.bed_details[i].bed_no,
                      bed_type:obj.bed_details[i].bed_type,
                      bed:obj.bed_details[i].bed_no + " - " + obj.bed_details[i].bed_type,
                    });
                  }
                }
              }else{
                this.send_bed_no = e;
                this.bed_no = e;
                if(flag == "")
                  this.toastr.error(obj.result);
              }
            });
      }

    }

    changeBed(e){
      this.bed_no = e.bed;
      this.send_bed_no = e.bed_no;
      this.bedList = [];
    }
    public stroke1
    public doc_billable
    changestroke(e) {
      this.stroke1 = e;
      if (e == false) {
        this.doc_billable = "0";
      }
      else {
        this.doc_billable = "1"
      }
    }

    view_Medtracker(){
      const dialogRef5 = this.dialog.open(InpatMedicineTrackerComponent, {
        width: '700px',
        height: 'auto',
        data:{
          relation_id: "1",
          // sub_rel_id: sub_rel_id,
          trackerFlag: "medicine"
        }
      });
    
      dialogRef5.afterClosed().subscribe(result => {
        
        //this.dis_txt = result;
      });
    }
}
