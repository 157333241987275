<div class="row" style="margin:0 auto; width:100%">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Appointments</h5>
          </div>
          <div class="headerButtons">
            <img src="../../../assets/ui_icons/buttons/new_button.svg" (click)="New_appointment()" class="saveimgbtn_inpatinfo" />
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="">
          <div class="row">
            <p class="nodata" *ngIf="opinionList.length == 0" style="width: 33% !important;">No Opinion(s) Found</p>
            <div>
              
              <table *ngIf="opinionList.length" mdbTable datatable [dtOptions]="dtOptions"
                class="table table-nowrap table-sm dataTable ">
                <thead class="tableheading">
                  <tr style="height: 32px;">
                    <th> Client Name</th>
                    <th>Doctor Name </th>
                    <th>Date </th>
                    <th>status </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let opinion of opinionList" class="tdvalues"
                    (click)="opinion_Checked(opinion.opinion_ID, opinion.doc_Reg_id,opinion.relation_id,opinion.relationship_name)">
                    <td style="text-align: left;padding-left: 50px;">{{opinion.client_Name}}</td>
                    <td style="text-align: left;padding-left: 50px;">{{"Dr."+ opinion.doctor_Name}}</td>
                    <td>{{opinion.date}} </td>
                    <td> 
                      <div style="position: relative;top: 9px;">
                        <img *ngIf="opinion.status == 'Completed'" width="30px" height="auto" src="../../../assets/ui_icons/Completed_icon.svg" />
                        <img *ngIf="opinion.status == 'Cancelled'"  width="30px" height="auto" src="../../../assets/ui_icons/cancel_icon.svg" />
                        <img *ngIf="opinion.status == 'Not visited'"  width="30px" height="auto" src="../../../assets/ui_icons/not_visited_icon.svg" />
                        <img *ngIf="opinion.status == 'Confirmed'"  width="30px" height="auto" src="../../../assets/ui_icons/confirmed_icon.svg" />
                        <img *ngIf="opinion.status == 'Open'"  width="30px" height="32px" src="../../../assets/ui_icons/opened_icon.svg" />
                        <img *ngIf="opinion.status == 'Rejected'"  width="30px" height="auto" src="../../../assets/ui_icons/cancel_icon.svg" />
                        <img *ngIf="opinion.status == 'Awaiting for rating'"  width="30px" height="32px" src="../../../assets/ui_icons/awaiting_for_rating.svg" />
                        <img *ngIf="opinion.status == 'Time not alloted'"  width="30px" height="32px" src="../../../assets/ui_icons/time_not_alloted.svg" />
                        <img *ngIf="opinion.status == 'Time alloted'"  width="30px" height="32px" src="../../../assets/ui_icons/opened_icon.svg" />
                        <img *ngIf="opinion.status == 'Sample collected'"  width="30px" height="32px" src="../../../assets/ui_icons/sample_collected.svg" />
                        <img *ngIf="opinion.status == 'Yet to be reviewed'"  width="30px" height="32px" src="../../../assets/ui_icons/report_yet.svg" />
                        <p>{{opinion.status}}</p>
                      </div>
<!--                       
                      {{"Status : "+ opinion.status}}</td> -->
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>