<div class="row">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                      <h5 class="m-0" class="mainHeadingStyle">Report View</h5>
                    </div>
                    <div class="headerButtons">
                        <img src="../../../assets/ui_icons/buttons/Back_button.svg" class="saveimgbtn_inpatinfo"
                        (click)="back_Nav()">
                         <img src="../../../assets/ui_icons/buttons/update_button.svg" [hidden]="Uploadbtn"
                        class="saveimgbtn_inpatinfo" (click)="updateReport()" />
                    </div>
                  </div>
                
            </mdb-card-header>
            <mdb-card-body>
                <div class="row">
                    <div class="col-12 col-md-6 col-lg-6 col-sm-12 col-xl-6 ">
                        <mat-card class="card_addr">
                            <mat-card-title style="margin: 0px !important;" class="card_title tervys_heading">
                                Address Details</mat-card-title>
                            <mat-card-content style="padding: 10px 24px;">
                                <div class="col-md-12 col-lg-12 col-sm-12 col-xl-12 ">
                                    <mat-label class="matlabel"><strong>{{diagName}}</strong>
                                    </mat-label>
                                </div>
                                <div class="col-md-12 col-lg-12 col-sm-12 col-xl-12 ">
                                    <mat-label class="matlabel">{{diagAddress}},{{diagLocation}},</mat-label>
                                </div>
                                <div class="col-md-12 col-lg-12 col-sm-12 col-xl-12 ">
                                    <mat-label class="matlabel">{{diagCityZip}},{{diagStateCon}}</mat-label>
                                </div>
                                <div class="col-md-12 col-lg-12 col-sm-12 col-xl-12 ">
                                    <mat-label class="matlabel">Mobile No:{{diagTelephone}}</mat-label>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                    <div class="col-12 col-md-6 col-lg-6 col-sm-12 col-xl-6 ">
                        <mat-card class="card_addr">
                            <mat-card-title style="margin: 0 !important;" class="card_title tervys_heading">Client
                                Details</mat-card-title>
                            <mat-card-content style="padding: 10px 24px;">
                                <div class="row">
                                    <div class="col-6">
                                        <mat-label class="matlabel"><strong>Date :</strong> {{date}}</mat-label>
                                    </div>
                                    <div class="col-6">
                                        <mat-label class="matlabel"><strong>Time :</strong> {{time}}</mat-label>
                                    </div>
                                    <div class="col-6">
                                        <mat-label class="matlabel"><strong>Client id :</strong> {{clentID}}
                                        </mat-label>
                                    </div>
                                    <div class="col-6">
                                        <mat-label class="matlabel"><strong>Name :</strong> {{clientAnotherName}}
                                        </mat-label>
                                    </div>
                                    <div class="col-6">
                                        <mat-label class="matlabel"><strong>Referred by :</strong>
                                            {{"Dr."+referedBy}}
                                        </mat-label>
                                    </div>
                                    <div class="col-6">
                                        <mat-label class="matlabel"><strong>Age :</strong> {{clientAge}}
                                        </mat-label>
                                    </div>
                                    <div class="col-6">
                                        <mat-label class="matlabel"><strong>Gender :</strong> {{clientGender}}
                                        </mat-label>
                                    </div>
                                    <div class="col-6">
                                        <mat-label class="matlabel"><strong>Collected at :</strong>
                                            {{sampleCollectTime}}</mat-label>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>

                <div [hidden]="tableViewReportArray" style="margin-top: 37px;">
                    <p class="tervys_heading clinical col-12"><b style="text-transform: capitalize;">{{reportType}}
                            Test Results</b></p>
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <mat-label *ngIf="subTestNameFlag" class='matlabel'><b>Test Name: {{testsName}}</b>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="margin-top: 10px;">
                            <div class="dig_table_overflow">
                                <div class="table-responsive">
                                    <table class="table table-hover table-dynamic" id="card_tbl">
                                        <thead>
                                            <tr>
                                                <th *ngIf="!subTestNameFlag">Test Name</th>
                                                <th *ngIf="subTestNameFlag">Subtest Name</th>
                                                <th>Reading</th>
                                                <th>Unit</th>
                                                <th>Referred value</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let subtest of getretrivelArray">
                                                <td style='text-align: left;'>
                                                    <strong><p>{{subtest.sub_test_name}}</p></strong>
                                                    <span *ngIf="subTestFlag" class="test_method">( <div [innerHtml]="subtest.test_method"></div> )</span>
                                                </td>
                                                <td><input style="position: relative;top: 5px;" class="ipcss"
                                                        [(ngModel)]="subtest.reading" matInput></td>
                                                <td>{{subtest.unit}}</td>
                                                <td style='text-align: left;'>
                                                    <div [innerHtml]="subtest.test_range | safeHtml"
                                                        style="position: relative;top: 6px;"></div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div [hidden]="paraViewReportArray">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="margin-top:13px;">
                            <mat-label class="matlabel"><strong>Report type :</strong> {{reportType}}</mat-label>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="margin-top:5px;">
                            <mat-label class="matlabel"><strong>Test name :</strong> {{testName}}</mat-label>
                        </div>
                        <div *ngIf="!cultureFlag" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="margin-top:10px;">
                            <mat-label class="matlabel">Sequences</mat-label>
                        </div>
                        <div *ngIf="!cultureFlag" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <!-- <textarea class="ipcss txtareaheight" [(ngModel)]="Sequence_txt"></textarea> -->
                            <quill-editor id="editor" [(ngModel)]="Sequence" [style]="editorStyle"
                                [modules]="modules" (onEditorCreated)="editorCreated($event)" placeholder="Enter Text"
                                (onSelectionChanged)="onSelectionChanged($event)"
                                (onContentChanged)="onContentChanged($event)"></quill-editor>
                        </div>
                        <div *ngIf="!cultureFlag" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="margin-top: 10px;">
                            <mat-label class="matlabel">Impression</mat-label>
                        </div>
                        <div *ngIf="!cultureFlag" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <quill-editor id="editor1" [(ngModel)]="Impression" [style]="editorStyle"
                                [modules]="modules" (onEditorCreated)="editorCreated($event)" placeholder="Enter Text"
                                (onSelectionChanged)="onSelectionChanged($event)"
                                (onContentChanged)="onContentChanged2($event)"></quill-editor>
                        </div>
                        <div *ngIf="cultureFlag" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="margin-top: 10px;">
                            <mat-label class="matlabel">Report details</mat-label>
                        </div>
                        <div *ngIf="cultureFlag" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <quill-editor id="editor1" [(ngModel)]="culture" [style]="editorStyle"
                                [modules]="modules" (onEditorCreated)="editorCreated($event)" placeholder="Enter Text"
                                (onSelectionChanged)="onSelectionChanged($event)"
                                (onContentChanged)="onContentChanged2($event)"></quill-editor>
                        </div>
                        <div [hidden]="dicomDoctor" class="col-12">
                            <mat-card *ngIf="!cultureFlag">
                                <mat-card-title class="tervys_heading">Attachment</mat-card-title>
                                <mat-card-content>
                                    <img alt="report image" src="{{reportImges}}" (click)="Document_click()"
                                        class="report_img" />
                                </mat-card-content>
                            </mat-card>
                        </div>
                    </div>
                </div>

                <div *ngIf="testMethod != undefined && !subTestFlag" class="row" style="margin-top: 10px;">
                    <div class="col-1" style="min-width: fit-content;">
                        <mat-label class="matlabel"><b>Test method :</b></mat-label>
                    </div>
                    <div class="col-10">
                        <div *ngIf="testMethod != undefined && !subTestFlag" class="col-12" [innerHtml]="testMethod | safeHtml">
                        </div>
                    </div>
                </div>

                <hr [hidden]="reviewFlag || createdBy == undefined" style="border: 1px solid #51b0e7;">
                <div  class="row" style="margin-top:5px;">
                    <div class="col-6" >
                        <div class="row" [hidden]="createdBy == undefined">
                            <div [hidden]="createdBy == undefined"
                                class="col-12 col-md-12 col-lg-12 col-sm-12 col-xl-12">
                                <mat-label class="matlabel"><strong>Lab technician :</strong> {{createdBy}}
                                </mat-label>
                            </div>
                            <div class="col-12 col-md-12 col-lg-12 col-sm-12 col-xl-12 ">
                                <mat-label class="matlabel"><strong>Signature :</strong>
                                    <!-- <img alt="" class="sign_img" /> -->
                                </mat-label>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="row" [hidden]="reviewFlag"  style="margin-left: 20px;">
                            <div [hidden]="reviewFlag" class="col-12 col-md-12 col-lg-12 col-sm-12 col-xl-12">
                                <mat-label class="matlabel"><strong>(Chief) Pathologist :</strong> {{reviewBy}}
                                </mat-label>
                            </div>
                            <div  class="col-12 col-md-12 col-lg-12 col-sm-12 col-xl-12 ">
                                <mat-label class="matlabel"><strong>Signature :</strong>
                                </mat-label>
                                <img alt="" [hidden]="signFlag" src="{{reviewSign}}" class="sign_img" />
                            </div>
                        </div>
                    </div>

                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>