<div class="row" style="width: 100%;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Profile Information</h5>
          </div>
          <div class="headerButtons">
            <img src="../../../assets/ui_icons/buttons/update_button.svg" (click)="update()"
              class="saveimgbtn_inpatinfo" />
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <mat-accordion class="mataccordion">
          <mat-expansion-panel class="expandpanel " [expanded]="true">
            <mat-expansion-panel-header class="exppanel width">
              <mat-panel-title class="title">
                Profile
              </mat-panel-title>
            </mat-expansion-panel-header>
            <hr class="hr">
            <p class="tervys_heading col-12">Personal information</p>
            <div class="row">
              <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-2" style="text-align: center;position: relative;">
                <div style="position: relative;width: 160px;margin: 0 auto;">
                  <img id="profile_img" class="profileimg" src="{{profileImage}}"
                    onerror="this.src='./././assets/img/default.jpg'" />
                  <input type="file" (change)="fileChange($event)" name="filUpload" id="profile_pic">
                  <label for="profile_pic" class="edit_profile_icon"><i class="fas fa-pencil-alt"></i></label>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-8 col-lg-9 col-xl-10">
                <div class="row">
                  <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                    <mat-label class="matlabel">First Name<br>
                      <input type="text" class="ipcss widthappt" [(ngModel)]="firstName" disabled="true" matInput />
                    </mat-label>
                  </div>
                  <div class="col-12  col-sm-6  col-md-6 col-lg-3 col-xl-3">
                    <mat-label class="matlabel">Middle Name(optional)<br>
                      <input type="text" class="ipcss widthappt" [(ngModel)]="middleName" disabled="true" matInput />
                    </mat-label>
                  </div>
                  <div class="col-12  col-sm-6  col-md-6 col-lg-3 col-xl-3">
                    <mat-label class="matlabel">Last Name<br>
                      <input type="text" class="ipcss widthappt" [(ngModel)]="lastName" disabled="true" matInput />
                    </mat-label>
                  </div>
                  <div class="col-12  col-sm-6  col-md-6 col-lg-3 col-xl-3">
                    <mat-label class="matlabel">Gender<br>
                      <input type="text" class="ipcss widthappt" [(ngModel)]="gender" disabled="true" matInput />
                    </mat-label>
                  </div>
                  <div class="col-12  col-sm-6  col-md-6 col-lg-3 col-xl-3">
                    <mat-label class="matlabel">Date<br>
                      <input type="text" class="ipcss widthappt" [(ngModel)]="dob" disabled="true" matInput />
                    </mat-label>
                  </div>
                  <div class="col-12  col-sm-6  col-md-6 col-lg-3 col-xl-3">
                    <mat-label class="matlabel">Phone Number<br>
                      <input type="text" class="ipcss widthappt" [(ngModel)]="mobile" maxlength="15" myNumberOnly
                        required matInput />
                    </mat-label>
                  </div>
                  <div class="col-12  col-sm-6  col-md-6 col-lg-3 col-xl-3">
                    <mat-label class="matlabel">Email<br>
                      <input type="text" class="ipcss widthappt" [(ngModel)]="email" maxlength="50" required matInput />
                    </mat-label>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <p class="tervys_heading col-12">&nbsp; Work location details &nbsp;&nbsp;</p>
              <div class="row">
                <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  <mat-label class="matlabel">Hospital / Clinic Name<br>
                    <input type="text" class="ipcss " required maxlength="50" [(ngModel)]="hospitalName"
                      (keyup)="changeHospital($event)" matInput [matAutocomplete]="auto10" />
                    <mat-autocomplete #auto10="matAutocomplete">
                      <mat-option (click)="selectHospital(item)" *ngFor="let item of hospitalList" value={{item}}>
                        {{item}}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  <mat-label class="matlabel">Location<br>
                    <input type="text" class="ipcss" required maxlength="50" [(ngModel)]="hospitalLocation"
                      (keyup)="changeLocation($event)" matInput [matAutocomplete]="auto11" />
                    <mat-autocomplete #auto11="matAutocomplete">
                      <mat-option (click)="selectLocation(item)" *ngFor="let item of locationList" value={{item}}>
                        {{item}}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  <mat-label class="matlabel">District / City<br>
                    <select disableOptionCentering class="ipcss" [(ngModel)]="hospitalDist" required
                      (ngModelChange)="changeCity($event)">
                      <option disabled>Select</option>
                      <option *ngFor="let city of cityList" value={{city.city_id}}>{{city.city_desc}}
                      </option>
                    </select>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  <mat-label class="matlabel">State<br>
                    <select disableOptionCentering class="ipcss" [(ngModel)]="hospitalState" required
                      (ngModelChange)="changeState($event)">
                      <option disabled>Select</option>
                      <option *ngFor="let state of stateList" value="{{state.state_id}}">{{state.state_desc}}
                      </option>
                    </select>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  <mat-label cl ass="matlabel">Country<br>
                    <select disableOptionCentering class="ipcss" (ngModelChange)="changeCountry()" required
                      [(ngModel)]="hospitalCountry">
                      <option disabled>Select</option>
                      <option *ngFor="let country of countryList" value={{country.country_desc}}>
                        {{country.country_desc}}</option>
                    </select>
                  </mat-label>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion><br>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>