<div class="row" style="width:100%;margin:0 auto;">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white ">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Service charges</h5>
                    </div>
                    <div class="headerButtons">
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>
                <div class="cover_div" style="padding-bottom: 5px;margin-top: -10px !important;">
                    <div class="header_lable">
                        Service charges
                    </div>
                    <div class="content_cover">
                        <div class="row">
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" >
                                <mat-label class="matlabel">Service charge<br>             
                                    <input type="text" class="ipcss order_width" required maxlength="50"
                                    (keyup)="chargeDesc && chargeDesc.length >=2 && handleKeyUp($event,chargeDesc)" maxlength="50" [(ngModel)]="chargeDesc"
                                    matInput [matAutocomplete]="auto2" />
                                  <mat-autocomplete #auto2="matAutocomplete" [panelWidth]="'auto'">
                                    <mat-option (click)="changeChargeType1(chargeDesc)" *ngFor="let charge of serviceType"
                                      value="{{charge.charge_desc}}"> {{charge.charge_desc}}                                          
                                    </mat-option>
                                  </mat-autocomplete>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom">
                                <mat-label class="matlabel">IP/OP<br>
                                    <select  [(ngModel)]="sub_department" class="ipcss" (ngModelChange)="wardShow()"> 
                                        <option value="ip">IP</option>
                                        <option value="op">OP</option>                                      
                                    </select>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom ">
                                <mat-label class="matlabel">Department<br>
                                    <select  [(ngModel)]="department" required class="ipcss" (change)="getAllChargeDetails()">
                                        <option *ngFor="let departments of departmentList" value={{departments.department_id}}>{{departments.description}}</option>
                                    </select>
                                </mat-label>
                            </div>                                                             
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom">
                                <mat-label class="matlabel">Charge bases<br>
                                    <select  [(ngModel)]="charge_base" class="ipcss">
                                        <option *ngFor="let charge_bases of charge_bases_array" value={{charge_bases.charge_base_id}}>{{charge_bases.charge_base}}</option>
                                    </select>
                                </mat-label>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" [hidden]="!wardFlag">
                                <mat-label class="matlabel">Amount<br>
                                    <input type="text" class="ipcss" required [(ngModel)]="chargeAmount" maxlength="9" onkeypress="return event.charCode >= 48 && event.charCode <= 57" matInput>
                                </mat-label>
                            </div>  

                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" [hidden]="wardFlag" *ngFor="let ward of wardDetailList">
                                <mat-label class="matlabel" style="margin-top: 34px !important;">{{ward.ward_name}}<br>                              
                                    <input type="text" class="ipcss" [(ngModel)]="ward.amount"  matInput> 
                                </mat-label>
                            </div>

                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2 select_bottom" style="margin-top: 5px;"><br/>
                                <mat-label class="matlabel">Auto Generate
                                    <mat-checkbox color="primary" [(ngModel)]="autoGen"></mat-checkbox>
                                </mat-label>
                            </div>
                            <div  class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2 select_bottom"> 
                                <mat-label class="matlabel"><br>
                                    <img *ngIf="newbutton" src="../../../assets/ui_icons/buttons/add_button.svg"  class="saveimgbtn_inpatinfo add_align btn-outline-success rounded" style="cursor: pointer;" (click)="save()" /> 
                                </mat-label>                               
                            </div>                    
                            <div class="headerButtons">
                                <mat-label class="matlabel">Per page<br>
                                  <select [(ngModel)]="pagelist" class="ipcss" (change)="changePageList()">
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    <option value="full">All</option>
                                  </select>
                                </mat-label>
                            </div>
                                                               
                            <mat-label class="matlabel" style="margin-top: -5px !important;">                              
                                <span> <img src='../../../assets/ui_icons/Bill_list/Filter_Icon.svg' width='18px'/> <span class="charge_filter"><input type="text"  placeholder="Search Service charge by Name" [(ngModel)]="charge_desc" (keyup)="getChargeTypes()" matInput></span>  </span>
                            </mat-label> 

                            <div *ngIf="chargeList.length !=0" class="table-wrapper" style="max-height: 365px;overflow-y: auto;overflow-x: hidden;border-top: 1px solid #51b0e7;border-radius: 5px;border-bottom: 1px solid #51b0e7;margin: 0px;padding: 0px;border-right: 1px solid #51b0e7;">
                                <table  style="font-size: 12px;width: 100%;"  class="table table-hover table-nowrap table-sm dataTable">
                                    <thead style="position: sticky;top: 0;z-index: 10;color: #fff !important;background-color: #51b0e7;">
                                        <tr>
                                            <th style="width: 25%;">Service charge</th>
                                            <th style="width: 15%;">Department</th>
                                            <th style="width: 10%;">IP/OP</th>
                                            <th style="width: 15%;">Ward</th>
                                            <th style="width: 10%;">Amount</th>
                                            <th style="width: 12%;">Charge base</th>
                                            <th style="width:  5%;">Autogen</th>
                                            <th style="width: 10%;">Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody style="border: 1px solid #51b0e7;">
                                        <tr *ngFor="let charge of chargeList" >
                                            <td style="width: 25%;" class="align_left">{{charge.charge_desc}}</td>                                       
                                            <td style="width: 15%;" class="align_center">{{charge.department}}</td>
                                            <td style="width: 10%;" class="align_center">{{charge.sub_department}}</td>
                                            <td style="width: 15%;" class="align_center">{{charge.ward_name}}</td>
                                            <td style="width: 10%;" class="align_right">{{charge.charge_rate}}</td>
                                            <td style="width: 12%;" class="align_center">{{charge.charge_base}}</td>
                                            <td style="width:  5%;" class="align_center">{{charge.auto_generate}}</td>
                                            <td style="width: 10%;" class="align_center">
                                                <a (click)="deleteCharge(charge.charge_id)"><img *ngIf="deletebutton" style="width: 17px; height: 17px;cursor: pointer;"   src="../../../assets/ui_icons/trash_icon.svg" /> </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            
                            <div *ngIf="chargeList.length != 0">
                                <div class="row" style="text-align: center;margin-top: 5px;">
                                    <div class="col-3 col-sm-3 col-md-4 col-lg-4 col-xl-4 select_bottom"> </div>
                                    <div class="col-3 col-sm-3 col-md-1 col-lg-1 col-xl-1 select_bottom">
                                        <input id="Text1" type="image" [disabled]="prevPageCount" class="pag_images" (click)="pageLimitPrevious()"
                                            src="../../../assets/ui_icons/arrow_blue.svg" />
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-1 col-lg-1 col-xl-1 select_bottom">
                                        <span class="current_font">{{currentPage}}/{{numberOfPages()}}</span>
                                    </div>  
                                    <div class="col-3 col-sm-3 col-md-1 col-lg-1 col-xl-1 select_bottom">
                                        <input id="Image1" type="image" [disabled]="nextPageCount" class="pag_images" (click)="pageLimitnext()"
                                            src="../../../assets/ui_icons/arrow_blue r.svg" />
                                    </div>                     
                                </div>
                            </div>                           
                        </div>
                    </div>             
                </div>              
            </mdb-card-body>
        </mdb-card>
    </div>
</div>