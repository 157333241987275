<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Manufacturer master</h5>
          </div>
          <div class="headerButtons">
            <img *ngIf="saveFlag" src="../../../assets/ui_icons/buttons/save_button.svg" class="saveimgbtn_inpatinfo"
              (click)="suppler_masterclick()" />
            <img *ngIf="!showManufacturerList" src="../../../assets/ui_icons/buttons/Back_button.svg"
              class="saveimgbtn_inpatinfo" (click)="back()" />
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="cover_div" style="padding-bottom: 13px;">
          <div class="header_lable">
            Manufacturer details
          </div>
          <div class="content_cover" style="position: relative;">
            <img style="position: absolute;  top: -10px;  right: -13px;  width: 25px;  cursor: pointer;height: 25px;"
              (click)="reset()" src="../../../assets/img/captcha_refresh.png" />
            <div class="row">
              <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Manufacturer name
                  <input type="text" class="ipcss widthappt" [(ngModel)]="supplierName" required maxlength="75" (keyup)="checkInputFields()"  matInput />
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Manufacturer code
                  <input type="text" class="ipcss widthappt" [(ngModel)]="supplierCode" required maxlength="15" onkeypress="return (event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122) || (event.charCode >= 48 && event.charCode <= 57)"
                  (keyup)="checkInputFields()"  matInput />
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Telephone
                  <input type="text" class="ipcss widthappt" [(ngModel)]="supplierTelephone" maxlength="10" matInput />
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Mobile
                  <input type="text" class="ipcss widthappt" [(ngModel)]="supplierMobile" pattern="^\d{4}-\d{3}-\d{4}$"
                    maxlength="10" maxlength="10" matInput />
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Address 1
                  <input type="text" class="ipcss widthappt" [(ngModel)]="supplierAddress1" required maxlength="300" (keyup)="checkInputFields()" 
                    matInput />
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Address 2
                  <input type="text" class="ipcss widthappt" [(ngModel)]="supplierAddress2" maxlength="300" matInput />
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Country<br>
                  <select disableOptionCentering class="ipcss widthappt" required [(ngModel)]="supplierCountry"
                    (ngModelChange)="get_state(supplierCountry,'0','0')">
                    <option disabled>Select</option>
                    <option *ngFor="let country of countryListArray" [value]="country.country_id">
                      {{country.description}}
                    </option>
                  </select>
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel">State<br>
                  <select disableOptionCentering class="ipcss widthappt" required [(ngModel)]="supplierState"
                    (change)="get_city(supplierState,'0')">
                    <option disabled>Select</option>
                    <option *ngFor="let states of stateListArray" value={{states.state_id}}>{{states.description}}
                    </option>
                  </select>
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel">City<br>
                  <select disableOptionCentering class="ipcss widthappt" required [(ngModel)]="supplierCity">
                    <option disabled>Select</option>
                    <option *ngFor="let city of cityListArray" [value]="city.district_id">{{city.description}}</option>
                  </select>
                </mat-label>
              </div>
            </div>
          </div>
        </div>
        <div class="row manufacture_table" *ngIf="showManufacturerList">
          <div class="col-12" style="margin-top: 20px;">
            <table *ngIf="supplerMasterArray.length" mdbTable datatable [dtOptions]="dtOptions"
              class="table table-nowrap table-sm dataTable">
              <thead>
                <tr>
                  <th>Manufacturer name</th>
                  <th>Manufacturer code</th>
                  <th>Address</th>
                  <th>City</th>
                  <th>State</th>
                  <th>Country</th>
                  <th>Telephone</th>
                  <th>Mobile</th>
                  <th *ngIf="editbutton">Edit</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let supplier of supplerMasterArray; let i = index">
                  <td style="font-size: 12px;text-align: left !important;">{{supplier.name}}</td>
                  <td style="font-size: 12px;text-align: left !important;">{{supplier.mfg_code}}</td>
                  <td style="font-size: 12px;text-align: left !important;">{{supplier.address}}</td>
                  <td style="font-size: 12px;text-align: left !important;">{{supplier.city_desc}}</td>
                  <td style="font-size: 12px;text-align: left !important;">{{supplier.state_desc}}</td>
                  <td style="font-size: 12px;text-align: left !important;">{{supplier.country_desc}}</td>
                  <td style="font-size: 12px;">{{supplier.telephone}}</td>
                  <td style="font-size: 12px;">{{supplier.mobile}}</td>
                  <td style="font-size: 12px;" *ngIf="editbutton"> <a (click)="suppler_edit(supplier.Index)"> <i style="color: #2c7fe1;" class="fas fa-edit"></i></a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>