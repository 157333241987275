import { Component, OnInit, ElementRef, ViewChild, } from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import { Http, Headers } from '@angular/http';
import {Helper_Class} from '../../helper_class';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { customjs } from '../../../assets/js/custom';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ChangePasswordComponent } from '../../common-module/change-password/change-password.component';
import { ipaddress } from '../../ipaddress' ;import { AdminViewService } from './admin-view.service';
import { FrontDesk_Helper } from'../../FrontDesk_module/FrontDesk_Helper';
import { Admin_Helper } from '../Admin_Helper';
import { Message_data } from '../../../assets/js/Message_data';
import { MenuViewService } from '../../common-module/menu-view/menu-view.service';
declare var $ : any; 
@Component({
  selector: 'app-admin-view',
  templateUrl: './admin-view.component.html',
  styleUrls: ['./admin-view.component.scss']
})
export class AdminViewComponent implements OnInit {
  @ViewChild('asied_section_width', { static: false }) asied_width: ElementRef;
  @ViewChild('right_section_width', { static: false }) right_width: ElementRef;
  @ViewChild('logo', { static: false }) logo: ElementRef;
  @ViewChild('scrol_traget') public myScrollContainer: ElementRef;

  subscription: Subscription;
  public hospitalLogo;
  public hospitalName;
  public userInfo;
  public profileImage;
  public firstName:string;
  public lastName:string;
  public selectedMenu;
  public displayPage;
  public currentDate;
  public settings:boolean = false;
  public minimizedAsied: boolean = false;
  public salesReportFlag: boolean = true;
  public stockReportFlag: boolean = true;
  public stockEditFlag: boolean = true;
  public docBillEditFlag: boolean = true;
  public diagBillEditFlag: boolean = true;
  public salesReportGstFlag: boolean = true;
  public stockReceivableFlag: boolean = true;
  public schdForDrugInspFlag: boolean = true;
  public inwardReportFlag: boolean = true;
  public inpatReportFlag: boolean = true;
  public salesForDrugInspFlag:boolean = true;
  public hospBillsReportFlag:boolean = true;
  public diagBillsReportFlag:boolean = true;
  public consolidatedFlag:boolean = true;
  public minimized_asied: boolean = false;
  public submenu_flag: boolean = false;
  public submenuFlag1: boolean = false;
  public packageCreateFlag:boolean =  false;
  constructor(public gservice:CommonDataService,public http:Http,public routes:ActivatedRoute,public router:Router,public toastr:ToastrService, public messageservice:MenuViewService ,public dialog:MatDialog) { 
      FrontDesk_Helper.setBillFlag(undefined);
      Admin_Helper.setDiagBill(true);
    }

  ngOnInit(): void {
    this.getCurrentDate();
    this.subscription = this.messageservice.getMessage().subscribe(message => {
      if (message) {
          this.menuNav(message,"");
      } 
    });
    this.submenu_flag = false;
    this.submenuFlag1 = false;
    this.minimized_asied = false;
    this.userInfo= Helper_Class.getInfo();
    this.firstName=this.userInfo.first_name;
    this.lastName=this.userInfo.last_name;
    this.profileImage= ipaddress.Ip_with_img_address + this.userInfo.profile_image;
    this.hospitalName= this.userInfo.hospitals[0].hptl_name;
    this.hospitalLogo= ipaddress.Ip_with_img_address + this.userInfo.hospitals[0].hptl_logo;
    this.minimizedAsied = false;
    
    // dynamic menu display
    var Moduleidlist;
    if (Helper_Class.getmodulelist() != null) {
      Moduleidlist = Helper_Class.getmodulelist();
      this.salesReportFlag = true;
      this.stockEditFlag = true;
      this.stockReportFlag = true;
      this.docBillEditFlag = true;
      this.diagBillEditFlag = true;
      this.salesReportGstFlag = true;
      this.stockReceivableFlag = true;
      this.schdForDrugInspFlag = true;
      this.inwardReportFlag = true;
      this.inpatReportFlag = true;
      this.salesForDrugInspFlag = true;
      this.hospBillsReportFlag = true;
      this.diagBillsReportFlag = true;
      for (var i = 0; i < Moduleidlist.length; i++) {
        if (Moduleidlist[i] == "47") {  
          this.salesReportFlag = false;
        } else if (Moduleidlist[i] == "49") {
          this.stockEditFlag = false;
        } else if (Moduleidlist[i] == "48") {
          this.stockReportFlag = false;
        } else if (Moduleidlist[i] == "50") {
          this.docBillEditFlag = false;
        } else if (Moduleidlist[i] == "51") {
          this.diagBillEditFlag = false;
          Admin_Helper.setDiagBill(false);
        }else if (Moduleidlist[i] == "61") {
          this.salesReportGstFlag = false;
        }else if (Moduleidlist[i] == "62") {
          this.stockReceivableFlag = false;
        }else if (Moduleidlist[i] == "62") {
          this.stockReceivableFlag = false;
        }else if (Moduleidlist[i] == "63") {
          this.schdForDrugInspFlag = false;
        }else if (Moduleidlist[i] == "64") {
          this.salesForDrugInspFlag = false;
        }else if (Moduleidlist[i] == "65") {
          this.hospBillsReportFlag = false;
        }else if (Moduleidlist[i] == "66") {
          this.diagBillsReportFlag = false;
        }else if (Moduleidlist[i] == "67") {
          this.inwardReportFlag = false;
        }else if (Moduleidlist[i] == "68") {
          this.inpatReportFlag = false;
        }else if (Moduleidlist[i] == "77") {
          this.consolidatedFlag = false;
        }else if (Moduleidlist[i] == "28") { // Bill View
          Helper_Class.setBillView(null);
          Helper_Class.setBillView("1");
        }else if (Moduleidlist[i] == "54") { // Bill Create
          Helper_Class.setBillCreate(null);
          Helper_Class.setBillCreate("1");
        } else if (Moduleidlist[i] == "55") { // Bill Edit
          Helper_Class.setBillEdit(null);
          Helper_Class.setBillEdit("1");
        } else if (Moduleidlist[i] == "85") { // Bill Print
          Helper_Class.setBillPrint(null);
          Helper_Class.setBillPrint("1");
        } else if (Moduleidlist[i] == "100") { // package create
          this.packageCreateFlag = true
        }   
      }
    }
  }

  logout(name) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'usercontroller/logout/',
      JSON.stringify({
        type: "doctor",
        user: this.userInfo.user_id
      }),
      { headers: headers })
      .subscribe(
        response => {
          this.toastr.success(Message_data.logOut + name)
          localStorage.clear();
          this.router.navigateByUrl('/loginpage');
        },
        error => {});
  }

  menuNav(page,type){
    let page_name : string = page;
    this.selectedMenu = page;
    Helper_Class.setReportType(type);
    switch (page_name) {
      case 'sales_report': 
        this.displayPage = "sales_report";
      break;
      case 'stock_report': this.displayPage = page; break;
      case 'stock_edit': this.displayPage = page;break ;
      case 'bill_list': this.displayPage = page;break ;
      case 'bill_edit': this.displayPage = page;break ;
      case 'salesGst': this.displayPage = page;break ;
      case 'stockReceivable': this.displayPage = page;break ;
      case 'gsd': this.displayPage = page;break ;
      case 'recreceivableReport': this.displayPage = page;break ;
      case 'SDI': this.displayPage = page;break ;
      case 'HBR': this.displayPage = page;break ;
      case 'DBR': this.displayPage = page;break ;
      case 'IR': this.displayPage = page;break ;
      case 'IPR': this.displayPage = page;break ;
      case 'consolidatedBills'  : this.displayPage = page;break;
      case 'billingList': 
      this.submenu_flag = !this.submenu_flag;
      if(this.minimized_asied == true){
        this.minimizing();
      }
       break;
      case 'billingEdit' : this.displayPage = page;break;
      case 'billingCreate' : this.displayPage = page;break;
      case 'inPatientCreate' : this.displayPage = page;break;
      case 'billingDetailsView' : this.displayPage = page;break;
      case 'diagBillingList' : this.displayPage = page;break;
      case 'diagBillingView' : this.displayPage = page;break;
      case 'diagBillingEdit' : this.displayPage = page;break;
      case 'pharmaBillingList' : this.displayPage = page;break;
      case 'pharmaBillingView' : this.displayPage = page;break;
      case 'createPackage' : this.displayPage = page;break;
      case 'reports' : 
      this.submenuFlag1 = !this.submenuFlag1;
      if(this.minimized_asied == true){
        this.minimizing();
      }
       break;
       case 'clientPackageList' :this.displayPage = page;break; 
       case 'listPackage' : this.displayPage = page;break; 
       case 'createUserPackage' :this.displayPage = page;break; 
    }  
  }

  getCurrentDate() {
    var get_date;
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'adm/curdate',
      JSON.stringify({
        country: ipaddress.country_code.toString()
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          
          if (obj.current_date != null) {
            this.currentDate = obj.current_date;
          }
        },
        error => {});
  }

  setAppoinmentHelper(e){
    if(e == "billing"){
      FrontDesk_Helper.setBillFromDate(this.currentDate);
      FrontDesk_Helper.setBillToDate(this.currentDate);
      var bill ={
        edit : "55"
      }
      FrontDesk_Helper.setBillFlag(bill);
    }
  }

  minimizing(){
    this.minimizedAsied = !this.minimizedAsied;
    if(this.minimizedAsied ==  true){
      this.asied_width.nativeElement.setAttribute('style','width:3%; transition: all 0.3s ease-in-out;animation-delay: 15s;');
      this.right_width.nativeElement.setAttribute('style','width:97%;margin-left: 3%; transition: all 0.3s ease-in-out;animation-delay: 15s;');
      this.logo.nativeElement.setAttribute('style','padding: 12px 0 0 3px;}');
    }else{
      this.asied_width.nativeElement.setAttribute('style','width:17%; transition: all 0.3s ease-in-out;animation-delay: 15s;');
      this.right_width.nativeElement.setAttribute('style','width:83%;margin-left: 17%; transition: all 0.3s ease-in-out;animation-delay: 15s;');
      this.logo.nativeElement.setAttribute('style','padding: 17px 0 0 14px;}');
    }
  }

  onResize(event){
    this.asied_width.nativeElement.removeAttribute('style');
    this.right_width.nativeElement.removeAttribute('style');
    this.logo.nativeElement.removeAttribute('style');
    this.minimizedAsied = false;
  }

  openMenumodel(){
    this.minimizedAsied = false;
    customjs.openMobileMenuFun();
  }
  
  menuModelAction(action){
    if(action == 'profile_update'){
      this.menuNav(action,"");
    }else if(action == 'changePassword'){
      const dialogRef = this.dialog.open(ChangePasswordComponent, {
        width: '33%',
        height: '275px',
        data: {}
      });
    }else{
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'usercontroller/logout/',
        JSON.stringify({
          type: "doctor",
          user: this.userInfo.user_id
        }),
        { headers: headers })
        .subscribe(
          response => {
            this.toastr.success(Message_data.logOut);
            localStorage.clear();
            this.router.navigateByUrl('/loginpage');
          },
          error => {});
    }
    $('#exampleModal').modal('hide'); 
  }
}
