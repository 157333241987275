import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { Message_data } from '../../../assets/js/Message_data';
import { ClientViewService } from '../clientview/client-view.service';
import { Client_Helper } from '../Client_helper';
import { ipaddress } from '../../ipaddress';

@Component({
  selector: 'app-client-notification',
  templateUrl: './client-notification.component.html',
  styleUrls: ['./client-notification.component.css']
})
export class ClientNotificationComponent implements OnInit {

  dtOptions: DataTables.Settings = {};

  public notificationList = [];
  public tabletTracker = [];
  public doctorName: string;
  public clientName: string;
  public date_prescription: string;
  public disease_name: string;
  public disease_describtion: string;
  public consul_text;
  public days_txt: string;
  public notifacte_remark: string;
  public days_hidd: boolean;
  public pres_id: string;
  public not_id: string;
  public app_flag: boolean;
  public pres_flag:boolean;

  constructor(public clientservice:ClientViewService,public gservice:CommonDataService,public http:Http,public routes:ActivatedRoute,public router:Router,public toastr:ToastrService) { 
    this.days_hidd = true;
    this.pres_flag = true;
    this.app_flag = true;
  }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 5,
      "lengthMenu": [[5, 10, 25, 50, -1], [5, 10, 25, 50, "All"]],
      "columnDefs": [
        { "orderable": false, "targets": [0, 1, 2, 3] },
      ],
      "language": {
        "search": "Filter"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12">><"table-responsive"t><"row mt-1 d-flex"<"col-xs-12 col-sm-6 col-md-4"l><"col-xs-12 col-sm-12 col-md-4 text-center sm-order-last"i><"col-xs-12 col-sm-6 col-md-4"p>>'
    };
    this.getNotificationList();
  }

  getNotificationList() {
    var send_data = {
      type: "client",
      user_id: this.gservice.Client_login_detail_data.Client_Id,
      imei: ipaddress.getIp
    }
    
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'gen/gc', send_data ,
      { headers: headers })
      .subscribe(
        response => {
          
          var obj = response.json();
          var name;
          if (obj.notifications != null) {
            for (var i = 0; i < obj.notifications.length; i++) {
              if(obj.notifications[i].middle_name != null){
                name = obj.notifications[i].first_name + " " + obj.notifications[i].middle_name + " " + obj.notifications[i].last_name
              }else{
                name = obj.notifications[i].first_name + " " + " " + obj.notifications[i].last_name
              }

              this.notificationList.push({
                notification_id: obj.notifications[i].notification_id,
                pres_drug_id: obj.notifications[i].pres_drug_id,
                content:obj.notifications[i].content,
                dr_name : name,
              });
            }
          }else{
            this.toastr.error(Message_data.getNetworkMessage());
          }
        },error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  expandNotification(data){
    for(var i=0;i<this.notificationList.length;i++){
      if(data == this.notificationList[i].notification_id){
        if(this.notificationList[i].pres_drug_id != undefined){
          this.pres_flag = false;
          this.viewNotification(this.notificationList[i].pres_drug_id, data);
        } else {
          this.app_flag =false;
          this.viewNotification(undefined, data);
        }
      }
    }
    
  }

  viewNotification(pres_id, not_id) {
    Client_Helper.setviewnotification(null);
    if(pres_id !=undefined){
      var send_data =[]
      send_data.push({
        pres_id:pres_id,
        not_id:not_id
      });
      Client_Helper.setviewnotification(send_data);
      this.clientservice.sendMessage("clientnotificationview");
    } 
  }
}
