import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from 'src/app/helper_class';
import { ServerApi } from 'src/app/server-api';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';
import { FrontDeskService } from 'src/app/FrontDesk_module/front-desk/front-desk.service';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';

@Component({
  selector: 'app-create-package',
  templateUrl: './create-package.component.html',
  styleUrls: ['./create-package.component.scss']
})
export class CreatePackageComponent implements OnInit {
  public packageArray = [];
  public packageType;
  public packageName;
  public packagePrice;
  public packageShortDesc;
  public packageLongDesc;
  public diet_table;
  public prognosis_table;
  public imgFileStr;
  public offerArray: any = [];
  public offerTitle;
  public test
  public sendImageString;
  public diagCenterID;
  public testListArray: any = [];
  public diagTest;
  public labTestlistArray: any = [];
  public radioTestlistArray: any = [];
  public testNameArray: any = [];
  
  public test_name;
  public testArray: any = [];
  public saveFlag: boolean = true;
  public updateFlag: boolean = false;
  public disableFlag: boolean = false;
  public packageImage;
  public sendPackageImage;
  public userinfo;
  public getData;
  public imagepath;
  public userAppintment: boolean = false;
  public clientName;
  public clientAddress;
  public appDate;
  public currentDate;
  public lab_array = [];
  public radio_arry = [];
  public Faeces_tests = [];
  public Xray_tests = [];
  public scan_arry = [];
  public ultra_tests = [];
  public culture_test = [];
  public biopsy_test = [];
  public sessionTimeStart: any = [];
  public sessionTimeEnd: any = [];
  public getVisitingSessionArray: any = [];
  public visitSession;
  public timeSession;
  public startTime;
  public endTime;
  public userType;
  public bookAppointment: boolean = false;
  public diagApp: boolean = false;
  public centerId;
  public splList = [];
  public ynopt1 = "doctor";;
  public doctorList: any = [];
  public doctorId;
  public splName;
  public appointmentDate;
  public splId;
  public visitTime;
  public tokenorTime;
  public timeOfAppt;
  public dietType;
  public dietTypeDesc;
  public dietTypeList = [];
  public chargeList:any=[];
  public doctorName;
  public doctor;
  public userId;
  public medicareID;
  public diagDeptDesc;
  editorStyle = {
    height: '300px;',
    border: '1px solid #000;'
  };
  modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      // [{ 'header': 1 }, { 'header': 2 }], // custom button values
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      // [{ 'script': 'sub' }, { 'script': 'super' }], // superscript/subscript

      [{ 'direction': 'rtl' }], // text direction
      [{ 'size': ['small', false, 'large', 'huge'] }], // custom dropdown
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

      [{ 'font': [] }],
      [{ 'align': [] }],
      ['clean'], // remove formatting button
      // ['link', 'image'], // link and image, video
      // ['table']
    ]
  };

  constructor(public serviceAPI: ServerApi, public toastr: ToastrService, public adminService: MenuViewService, public frontService: FrontDeskService, public diagService: MenuViewService) { }
  
  async ngOnInit() {
    this.userinfo = Helper_Class.getInfo();
    this.userType = Helper_Class.getLoginType();
    if (Helper_Class.getLoginType() == "diagnosis") {
      this.diagCenterID = Helper_Class.getHospitalInfo().hptl_clinic_id;
      this.centerId = Helper_Class.getHospitalInfo().hptl_clinic_id;

    } else {
      this.diagCenterID = this.userinfo.diag_centre_id;
      this.centerId = this.userinfo.hptl_clinic_id;
    }

    this.getData = Helper_Class.GetPackage();
    this.getDiagDepartments();
    var response = await this.serviceAPI.getData('adm/ghp').toPromise();
    if (response) {
      var obj = JSON.parse(JSON.stringify(response));
      this.packageArray = obj.health_packages;
      this.packageType = obj.health_packages[0].package_type_id;
    }
    this.changetab('diet');
    
    if (this.getData.flow == "edit" || this.getData.flow == "view" || this.getData.flow == "userPackage") {
      this.getRetieval();
      this.saveFlag = false;
      if (this.getData.flow == "edit") {
        this.updateFlag = true;
      }
      
      if (this.getData.flow == "userPackage") {
        this.userAppintment = true;
        if (Helper_Class.getLoginType() == "diagnosis") {
          this.diagApp = true;
        } else {
          this.bookAppointment = true;
        }
      }
    }

    for (var i = 1; i <= 12; i++) {
      if (i < 10) {
        this.sessionTimeStart.push(0 + i);
      } else {
        this.sessionTimeStart.push(i);
      }
    }

    for (var i = 0; i <= 55; i += 5) {
      if (i < 10) {
        this.sessionTimeEnd.push(0 + i);
      } else {
        this.sessionTimeEnd.push(i);
      }
    }

    this.getCurrentDate();
    this.getDoctors();
  }

  async getCurrentDate() {
    var response = await this.serviceAPI.PostData('adm/curdate', { country: ipaddress.country_code.toString() }).toPromise();
    if (response) {
      var obj = JSON.parse(JSON.stringify(response));
      this.currentDate = obj.current_date;
      this.appDate = this.currentDate;
      this.getVisitingSessionArray = [{ "description": "Morning" }, { "description": "Afternoon" }, { "description": "Evening" }];
      this.visitSession = this.getVisitingSessionArray[0].description;
      this.getSpecializations();
    }
  }

  async getSpecializations() {
    var sendDate = {
      hptl_clinic_id: this.centerId,
    }
    var response = await this.serviceAPI.PostData('adm/gsplbyhptl', sendDate).toPromise();
    if (response) {
      var obj = JSON.parse(JSON.stringify(response));
      this.splList = [];
      if (obj.specializations != null) {
        this.splList = obj.specializations;
      }
    }
  }

  async getDoctorList() {
    var sendDate = {
      hptl_clinic_id: this.centerId,
      spl_name: this.splName,
    }
    this.splList.forEach((x: any) => {
      if (x.spl_name == this.splName) {
        this.splId = x.spl_id;
      }
    })
    
    var response = await this.serviceAPI.PostData('search/docbyhs', sendDate).toPromise();
    if (response) {
      var obj = JSON.parse(JSON.stringify(response));
      this.doctorList = [];
      this.doctorList = obj.doctors.map((x) => ({ docname: x.middle_name != undefined ? x.first_name + " " + x.middle_name + " " + x.last_name : x.first_name + " " + x.last_name, prov_id: x.prov_id }));
    }
  }
  
  async getDoctorDetails() {
    var sendDate = {
      doc_reg_id: this.doctorId,
      location: this.userinfo.location,
      country: ipaddress.country_code,
      home_care: "0",
    }
    var response = await this.serviceAPI.PostData('search/doctorbyid/', sendDate).toPromise();
    if (response) {
      var obj = JSON.parse(JSON.stringify(response));
      this.medicareID = obj.medicare[0].medicare_id;
    }
  }

  selectSession() {
    if (this.visitSession == "Morning") {
      this.timeSession = "AM";
      this.startTime = "06";
      this.endTime = "00";

    } else if (this.visitSession == "Evening") {
      this.startTime = "06";
      this.endTime = "00";
      this.timeSession = "PM";

    } else {
      this.timeSession = "PM";
      this.startTime = "12";
      this.endTime = "00";
    }
  }

  async getDiagDepartments() {
    var send = {
      diag_centre_id: this.userinfo.hospitals[0].hptl_clinic_id
    }
    
    var response = await this.serviceAPI.PostData('mas/gdd', send).toPromise();
    if (response) {
      var obj = JSON.parse(JSON.stringify(response));
      if (obj.diag_dept_list != null) {
        this.testListArray = obj.diag_dept_list;
        this.diagTest = obj.diag_dept_list[0].diag_department_id;
        this.diagDeptDesc = obj.diag_dept_list[0].diag_dept_desc;
        this.diagTestChange();
      }
    }
  }

  async diagTestChange() {
    if (this.diagTest != undefined) {
      for(var i=0;i<this.testListArray.length;i++) {
        if(this.diagTest == this.testListArray[i].diag_department_id){
          this.diagDeptDesc = this.testListArray[i].diag_dept_desc;
        }
      }
      var send = {
        diag_id: this.diagCenterID,
        dept_id: this.diagTest
      }
      var response = await this.serviceAPI.PostData('diagnosiscontroller/gtbp', send).toPromise();
      if (response) {
        var obj = JSON.parse(JSON.stringify(response));
        if (obj.diag_tests != null) {
          if (this.diagTest == "1") {
            if (this.labTestlistArray.length == 0) {
              for (var i = 0; i < obj.diag_tests.length; i++) {
                this.labTestlistArray.push({
                  test_id: obj.diag_tests[i].diag_tests_id,
                  test_name: obj.diag_tests[i].test_name,
                  is_profile:obj.diag_tests[i].is_profile,
                  checked: false,
                  show: false,
                });
              }
              this.testNameArray = this.labTestlistArray;
            } else {
              this.testNameArray = this.labTestlistArray;
            }

          } else {
            if (this.radioTestlistArray.length == 0) {
              for (var i = 0; i < obj.diag_tests.length; i++) {
                this.radioTestlistArray.push({
                  test_id: obj.diag_tests[i].diag_tests_id,
                  test_name: obj.diag_tests[i].test_name,
                  is_profile:obj.diag_tests[i].is_profile,
                  checked: false,
                  show: false,
                });
              }
              this.testNameArray = this.radioTestlistArray;
            } else {
              this.testNameArray = this.radioTestlistArray;
            }
          } 
        }
      }
    } else {
      this.testNameArray = [];
    }
  }

  getTest(test) {
    if (test.length >= 3) {
      if (this.testNameArray.length != 0) {
        if (this.testNameArray.filter(o => o.test_name.toLowerCase().startsWith(test.toLowerCase()))) {
          var chg = this.testNameArray.filter(o => o.test_name.toLowerCase().startsWith(test.toLowerCase()));
          for (var j = 0; j < this.testNameArray.length; j++) {
            this.testNameArray[j].show = true;
          }
          for (var i = 0; i < chg.length; i++) {
            for (var j = 0; j < this.testNameArray.length; j++) {
              if (this.testNameArray[j].test_name.includes(chg[i].test_name) == true) {
                this.testNameArray[j].show = false;
              }
            }
          }
        } else {
        }
      }
    } else {
      for (var j = 0; j < this.testNameArray.length; j++) {
        this.testNameArray[j].show = false;
      }
    }
  }

  clickTest(e: any, data,test) {
    if (e == true) {
      for (var i = 0; i < this.testNameArray.length; i++) {
        if (this.diagTest == "1" && this.testNameArray[i].test_id == data) {
          for (var i = 0; i < this.labTestlistArray.length; i++) {
            if (this.labTestlistArray[i].test_id == test.test_id 
                && this.labTestlistArray[i].is_profile == test.is_profile) {
              this.labTestlistArray[i].checked = true;
            }
          }
        }
        if (this.diagTest == "2" && this.testNameArray[i].test_id == data) {
          for (var i = 0; i < this.radioTestlistArray.length; i++) {
            if (this.radioTestlistArray[i].test_id == data 
                && this.radioTestlistArray[i].is_profile == test.is_profile) {
              this.radioTestlistArray[i].checked = true;
            }
          }
        }
      }

    } else {
      for (var i = 0; i < this.testNameArray.length; i++) {
        if (this.diagTest == "1" && this.testNameArray[i].test_id == data) {
          for (var i = 0; i < this.labTestlistArray.length; i++) {
            if (this.labTestlistArray[i].test_id == data) {
              this.labTestlistArray[i].checked = false;
            }
          }
        }
        if (this.diagTest == "2" && this.testNameArray[i].test_id == data) {
          for (var i = 0; i < this.radioTestlistArray.length; i++) {
            if (this.radioTestlistArray[i].test_id == this.testNameArray[i].test_id) {
              this.radioTestlistArray[i].checked = false;
            }
          }
        }
      }
    }
  }

  changetab(e) {
    this.diet_table = false;
    this.prognosis_table = false;
    if (e == "diet") {
      this.diet_table = true;
    } else if (e == "prognosis") {
      this.prognosis_table = true;
    }
  }

  add(action) {
    if (action == "offers") {
      if (this.offerTitle != undefined && this.offerTitle != "") {
        if (this.offerArray.length != 0) {
          this.offerArray.push({
            index: this.offerArray.length + 1,
            description: this.offerTitle,
            image: this.sendImageString,
            displayImage: this.imgFileStr,
            edit: true
          });
        } else {
          this.offerArray.push({
            index: "1",
            description: this.offerTitle,
            image: this.sendImageString,
            displayImage: this.imgFileStr,
            edit: true
          });
        }
        $("#file").val('');
        this.imgFileStr = null;
        this.sendImageString = null;
        this.offerTitle = "";
      } else {
        this.toastr.error("File all mandatory fields");
      }
      
    } else {
      for (var i = 0; i < this.testNameArray.length; i++) {
        if (this.testNameArray[i].checked == true) {
          if (this.testArray.length != 0) {
            var filter_array = this.testArray.filter(x => x.test_id == this.testNameArray[i].test_id);
            if (filter_array.length == 0) {
              this.testArray.push({
                test_type: this.diagTest,
                test_type_desc:this.diagDeptDesc,
                test_id: this.testNameArray[i].test_id,
                test_name: this.testNameArray[i].test_name,
                is_profile:this.testNameArray[i].is_profile,
                edit: true
              });
            }
          } else {
            this.testArray.push({
              test_type: this.diagTest,
              test_type_desc:this.diagDeptDesc,
              test_id: this.testNameArray[i].test_id,
              test_name: this.testNameArray[i].test_name,
              is_profile:this.testNameArray[i].is_profile,
              edit: true
            });
          }
        }
      }
    }
  }

  onContentChanged = (event) => {
    this.packageLongDesc = event.html;
  }

  deleteOffer(id) {
    for (var i = 0; i < this.offerArray.length; i++) {
      if (this.offerArray[i].index == id) {
        this.offerArray.splice(i, 1);
      }
    }
  }

  deleteTest(id) {
    for (var i = 0; i < this.testArray.length; i++) {
      if (this.testArray[i].test_id == id) {
        this.testArray.splice(i, 1);
      }
    }
  }

  fileChange($event): void {
    this.readThis($event.target);
  }

  readThis(inputValue: any): void {
    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      var fileSize = Math.round(inputValue.files[0].size / 1024);
      if (fileSize >= 50) {
        $("#file").val('');
        $("#file3").val('');

        this.imgFileStr = null;
        this.toastr.error("Image size must be below 50Kb");
      } else {
        this.imgFileStr = myReader.result;
        this.sendImageString = this.imgFileStr.split(',')[1];
      }
    }
    myReader.readAsDataURL(inputValue.files[0]);
  }

  fileChange1($event): void {
    this.readThis1($event.target);
  }

  readThis1(inputValue: any): void {
    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      var fileSize = Math.round(inputValue.files[0].size / 1024);
      if (fileSize >= 50) {
        $("#file1").val('');
        this.packageImage = null;
        this.toastr.error("Image size must be below 50Kb");
      } else {
        this.packageImage = myReader.result;
        this.sendPackageImage = this.packageImage.split(',')[1];
        $("#packageImage").attr("src", this.packageImage);
      }
    }
    myReader.readAsDataURL(inputValue.files[0]);
  }

  async save() {
    if (this.packageName != undefined && this.packageName != "" && this.packagePrice != undefined 
      && this.packagePrice != "" && this.packageShortDesc != undefined && this.packageShortDesc != "") {
      if ((this.offerArray.length != 0 || this.testArray.length != 0) 
        && (this.sendPackageImage != null && this.sendPackageImage != undefined)) {
        this.offerArray = this.offerArray != undefined && this.offerArray.length != 0 ? this.offerArray : undefined;
        this.testArray = this.testArray != undefined && this.testArray.length != 0 ? this.testArray : undefined;
        this.sendPackageImage = this.sendPackageImage != undefined ? this.sendPackageImage : undefined;
        var centerId = this.userinfo.hptl_clinic_id;
        var send = {
          package_type: this.packageType,
          centre_id: centerId,
          package_name: this.packageName,
          image: this.sendPackageImage,
          package_short_desc: this.packageShortDesc,
          package_long_desc: this.packageLongDesc,
          price: this.packagePrice,
          test_details: this.testArray,
          package_details: this.offerArray,
          country: ipaddress.country_code
        }
        var response = await this.serviceAPI.PostData('adm/shpd', send).toPromise();
        if (response) {
          var obj = JSON.parse(JSON.stringify(response));
          if (obj.key == "1") {
            this.toastr.success("Package saved successfully");
            this.adminService.sendMessage("listPackage");
          }
        }
      } else {
        this.toastr.error(Message_data.alteastOneOffer)
      }

    } else {
      this.toastr.error(Message_data.allMandatory)
    }
  }

  back() {
    this.adminService.sendMessage("listPackage");
  }

  async getRetieval() {
    var send = {
      health_pkg_id: this.getData.content.health_pkg_id
    }
    var response = await this.serviceAPI.PostData('adm/ghpid', send).toPromise();
    if (response) {
      var obj = JSON.parse(JSON.stringify(response));
      if (obj.health_tests != undefined) {
        for (var i = 0; i < obj.health_tests.length; i++) {
          this.testArray.push({
            test_type: obj.health_tests[i].test_type,
            test_type_desc: obj.health_tests[i].test_type_desc,
            test_id: obj.health_tests[i].test_id,
            test_name: obj.health_tests[i].test_name,
            is_profile:obj.health_tests[i].is_profile,
            //edit: false
          });
        }
        this.lab_array = obj.health_tests.filter((x) => x.test_type == "Laboratory").map((x) => x.test_id);
        this.radio_arry = obj.health_tests.filter((x) => x.test_type == "Radiology").map((x) => x.test_id);
      }
      if (obj.health_details != undefined) {
        for (var i = 0; i < obj.health_details.length; i++) {
          var img = obj.health_details[i].image != undefined ? ipaddress.Ip_with_img_address + obj.health_details[i].image : "";
          this.offerArray.push({
            index: i + 1,
            description: obj.health_details[i].description,
            // image: obj.health_details[i].description this.sendImageString,
            displayImage: img,
            edit: false
          });
        }
      }
      this.packageType = this.getData.content.package_type_id;
      this.packageName = this.getData.content.package_name;
      this.packagePrice = this.getData.content.price;
      this.packageType = this.getData.content.package_type_id;
      this.packageShortDesc = this.getData.content.package_short_desc;
      this.packageLongDesc = this.getData.content.package_long_desc;
      $("#packageImage").attr("src", this.getData.content.image);
      this.packageImage = this.getData.content.image;
      this.imagepath = this.getData.content.image_path;
      if (this.getData.content.middle_name != "") {
        this.clientName = this.getData.content.middle_name + " " + this.getData.content.middle_name + " " + this.getData.content.last_name;
      } else {
        if (this.getData.content.last_name != "") {
          this.clientName = this.getData.content.middle_name + " " + this.getData.content.last_name;
        } else {
          this.clientName = this.getData.content.middle_name;
        }
      }
      if (this.getData.content.address2 != "") {
        this.clientAddress = this.getData.content.address1 + "," + this.getData.content.address2;
      } else {
        this.clientAddress = this.getData.content.address1;
      }
      this.diagTestChange();
    }
  }

  async update() {
    if (this.packageName != undefined && this.packageName != "" && this.packagePrice != undefined && this.packagePrice != "" && this.packageShortDesc != undefined && this.packageShortDesc != "") {
      if ((this.offerArray.length != 0 || this.testArray.length != 0)) {
        // var testArray = this.testArray.filter(x => x.edit == true);
        // var offerArray = this.offerArray.filter(x => x.edit == true);
        this.testArray = this.testArray.length != 0 ? this.testArray : undefined;
        this.offerArray = this.offerArray.length != 0 ? this.offerArray : undefined;
        this.sendPackageImage = this.sendPackageImage != undefined ? this.sendPackageImage : undefined;
        var send = {
          health_pkg_id: this.getData.content.health_pkg_id,
          package_type: this.packageType,
          centre_id: this.userinfo.hptl_clinic_id,
          package_name: this.packageName,
          image: this.sendPackageImage,
          package_short_desc: this.packageShortDesc,
          package_long_desc: this.packageLongDesc,
          price: this.packagePrice,
          test_details: this.testArray,
          package_details: this.offerArray,
          country: ipaddress.country_code,
          image_path: this.imagepath,
        }
        var response = await this.serviceAPI.PostData('adm/uhpd', send).toPromise();
        if (response) {
          var obj = JSON.parse(JSON.stringify(response));
          if (obj.key == "1") {
            this.toastr.success("Package updated successfully");
            this.adminService.sendMessage("listPackage");
          }
        }
      } else {
        this.toastr.error(Message_data.alteastOneOffer)
      }

    } else {
      this.toastr.error(Message_data.allMandatory)
    }
  }

  async saveDiagApp() {
    var senddata;
    var valid_flag: any = false;
    if (this.visitSession != "Select") {
      this.visitSession = this.visitSession;
    } else {
      this.toastr.error(Message_data.sltSession);
      valid_flag = true;
    }
    if (valid_flag == false) {
      senddata = {
        "Laboratory": this.lab_array,
        "Radiology": this.radio_arry,
      }
      var zcode;
      if (this.getData.content.zipcode != undefined) {
        zcode = encrypt_decript.Encript(this.getData.content.zipcode).toString();
      }

      var addr1;
      if (this.getData.content.address1 != null) {
        addr1 = encrypt_decript.Encript(this.getData.content.address1).toString()

      } else {
        addr1 = null;
      }
      var addr2;
      if (this.getData.content.address1 != null) {
        addr2 = encrypt_decript.Encript(this.getData.content.address1).toString()
      } else {
        addr2 = null;
      }
      var mname;
      if (this.getData.content.middle_name != "") {
        mname = encrypt_decript.Encript(this.getData.content.middle_name).toString();
      }
      var lname;
      if (this.getData.content.last_name != "") {
        lname = encrypt_decript.Encript(this.getData.content.last_name).toString();
      }
      var fname;
      if (this.getData.content.last_name != "") {
        fname = encrypt_decript.Encript(this.getData.content.last_name).toString();
      }

      senddata.client_reg_id = this.getData.content.client_reg_id;
      senddata.relation_id = this.getData.content.relation_id;
      senddata.sub_rel_id = this.getData.content.relation_id;

      senddata.first_name = fname;
      senddata.middle_name = mname;
      senddata.last_name = lname;

      senddata.mem_mobile = encrypt_decript.Encript(this.getData.content.mobile).toString();
      senddata.app_type = "New";

      senddata.diag_centre_id = this.diagCenterID;
      senddata.pres_id = undefined;

      senddata.provider = "diagnosis";
      senddata.date = this.appDate;//.split('-')[2] + "-" + this.appDate.split('-')[1] + "-" + this.appDate.split('-')[0];
      senddata.session = this.visitSession;
      senddata.is_package = true;
      senddata.time = this.startTime + ":" + this.endTime;
      var response = await this.serviceAPI.PostData('appointment/dbapp', senddata).toPromise();
      if (response) {
        var obj = JSON.parse(JSON.stringify(response));
      }
    }
  }
  async bookingConfirm() {
    var zcode;
    if (this.getData.content.zipcode != undefined) {
      zcode = encrypt_decript.Encript(this.getData.content.zipcode).toString();
    }

    var addr1;
    if (this.getData.content.address1 != null) {
      addr1 = encrypt_decript.Encript(this.getData.content.address1).toString()

    } else {
      addr1 = null;
    }
    var addr2;
    if (this.getData.content.address1 != null) {
      addr2 = encrypt_decript.Encript(this.getData.content.address1).toString()
    } else {
      addr2 = null;
    }
    var mname;
    if (this.getData.content.middle_name != "") {
      mname = encrypt_decript.Encript(this.getData.content.middle_name).toString();
    }
    var lname;
    if (this.getData.content.last_name != "") {
      lname = encrypt_decript.Encript(this.getData.content.last_name).toString();
    }
    var fname;
    if (this.getData.content.last_name != "") {
      fname = encrypt_decript.Encript(this.getData.content.last_name).toString();
    }
    if (this.visitTime != undefined) {
      var app_time = this.visitTime.toString().split(" ");
      this.tokenorTime = this.visitTime.toString();
      if (this.tokenorTime != "true") {
        if (this.tokenorTime != undefined) {
          this.timeOfAppt = app_time[0] + ":00";
        }
      } else {
        this.timeOfAppt = "1";
      }

    } else {
      this.timeOfAppt = "1";
    }

    var mobile = encrypt_decript.Encript(this.getData.content.mobile).toString();
    var visitSession = encrypt_decript.Encript(this.visitSession).toString();
    var senddata = {
      prov_id: this.userinfo.user_id,
      provider: "dietician",
      client_id: this.getData.content.client_reg_id,
      relation_id: this.getData.content.relation_id,
      sub_relation_id: this.getData.content.relation_id,
      hptl_clinic_id: this.centerId,
      // Client
      first_name: fname,
      middle_name: mname,
      last_name: lname,
      mem_mobile: mobile,
      app_type: "1",
      specialization_id: this.splId,
      session: visitSession,
      app_date: this.appDate,
      app_time:this.startTime + ":" + this.endTime,
      token: false,
      time: this.startTime + ":" + this.endTime,
      hptl_id: this.centerId,
      home_care: "0",
      payment_type: "Cash",
      medicare_id: this.medicareID,
      is_package: true,
      gender: this.getData.content.gender,
      visit_purpose:this.dietType,
    };
    if(this.dietType == undefined)
    //  senddata.visit_desc = this.dietTypeDesc;
    var response = await this.serviceAPI.PostData('appointment/dapp', senddata).toPromise();
    if (response) {
      var obj = JSON.parse(JSON.stringify(response));
    }
  }
  async getTypes() {
    var send_data;
    this.dietType = undefined;
    if (this.dietTypeDesc.length > 2 && this.dietTypeDesc != undefined) {
      send_data = {
        visit_purpose: this.dietTypeDesc
      }
      var response = await this.serviceAPI.PostData('adm/gdat', send_data).toPromise();
      if (response) {
        var obj = JSON.parse(JSON.stringify(response));
        if (obj.visit_types != undefined && obj.visit_types.length != 0) {
          this.dietTypeList = obj.visit_types;
        }
      }
    } else {
      this.dietType = undefined;
      this.dietTypeList = [];
    }
  }

  selectPurposeOfVisit(pov) {
    this.dietType = pov.visit_id;
    this.dietTypeDesc = pov.description;
    this.dietTypeList = [];
  }
  async savediagnosis() {
    var create_flag = true; var dob;
    if (this.visitSession != "Select") {
      this.visitSession = this.visitSession;
    } else {
      this.toastr.error(Message_data.sltSession);
      create_flag = false;
    }
    if (create_flag == false) {
      this.toastr.error(Message_data.mandatory);
    } else {
      this.saveFlag = true;
      var mname;
      if (this.getData.content.middle_name != "") {
        mname = encrypt_decript.Encript(this.getData.content.middle_name).toString();
      }
      var lname;
      if (this.getData.content.last_name != "") {
        lname = encrypt_decript.Encript(this.getData.content.last_name).toString();
      }
      var fname;
      if (this.getData.content.last_name != "") {
        fname = encrypt_decript.Encript(this.getData.content.last_name).toString();
      }

    }
    var address: any = [];
    var visitSession = encrypt_decript.Encript(this.visitSession).toString();
    var save_data;
    save_data = {
      prov_id: this.userId,
      client_id: this.getData.content.client_reg_id,
      relation_id: this.getData.content.relation_id,
      sub_rel_id: this.getData.content.relation_id,
      first_name: fname,
      middle_name: mname,
      last_name: lname,
      mem_mobile: encrypt_decript.Encript(this.getData.content.mobile).toString(),
      hptl_clinic_id: this.centerId,
      date: this.appDate,
      session: visitSession,
      provider: "dietician",
      app_date: this.appointmentDate,
      app_time: this.startTime + ":" + this.endTime,
      app_type: "1",
      visit_purpose: this.dietType,
    };
    if (this.dietType == undefined)
      save_data.visit_desc = this.dietTypeDesc;

    var response = await this.serviceAPI.PostData('appointment/dapp', save_data).toPromise();
    if (response) {
      var obj = JSON.parse(JSON.stringify(response));
      if (obj.status != 0) {
        this.toastr.success(Message_data.apptSavedSuccess);
      }
    }

  }

  async getDoctors() {
    var send = {
      country: "IN",
      reg_id: this.userinfo.user_id,
      imei: Helper_Class.getIPAddress(),
      type: "dietician",
    }
    var response = await this.serviceAPI.PostData('adm/docbyrep', send).toPromise();
    if (response) {
      var obj = JSON.parse(JSON.stringify(response));
      if (obj != null) {
        for (var i = 0; i < obj.doctors.length; i++) {
          this.doctorName = "";
          var fname, mname, lname;
          if (obj.doctors[i].middle_name != "" && obj.doctors[i].middle_name != undefined) {
            this.doctorName = obj.doctors[i].first_name + " " + obj.doctors[i].middle_name + " " + obj.doctors[i].last_name;
            fname = obj.doctors[i].first_name; mname = obj.doctors[i].middle_name; lname = obj.doctors[i].last_name;
          } else {
            this.doctorName = obj.doctors[i].first_name + " " + obj.doctors[i].last_name;
            fname = obj.doctors[i].first_name; lname = obj.doctors[i].last_name;
          }
          this.doctorList.push({
            Doc_Name: this.doctorName,
            Doc_ID: obj.doctors[i].prov_id,
          });
        }
        this.doctor = this.doctorList[0].Doc_Name;
        this.changeDoctor(this.doctor);
      }
    }

  }

  changeDoctor(e) {
    this.doctor = e;
    for (var i = 0; i < this.doctorList.length; i++) {
      if (this.doctorList[i].Doc_Name.trim() == this.doctor) {
        this.userId = this.doctorList[i].Doc_ID;
      }
    }
  }

  changeType(id, value) {
    if (value == "doctor") {
      this.ynopt1 = "doctor";
    } else if (value == "diagnosis") {
      this.ynopt1 = 'diagnosis';
    } else if (value == "dietician") {
      this.ynopt1 = "dietician";
    }
  }

  async getCharges(offerTitle){
    
    if(offerTitle.length >2) {
      var send = {
        country: "IN",
        hptl_clinic_id: this.centerId,
        charge_name: this.offerTitle,
      }
      var response = await this.serviceAPI.PostData('bill/gsbnd', send).toPromise();
      if (response) {
        var obj = JSON.parse(JSON.stringify(response));
        this.chargeList = obj.charge_details;
      };
    }
    
  }

  selectedCharge(charge:any){
    
  }
}
