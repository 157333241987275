<div class="wipes">
  <div *ngIf="!packageExpierflag">
    <div class="grid">
      <div class="row">
        <div class="col-12">
          <div class="logoimg">
            <img src="../../assets/img/popup_bg-1.png" width="64px" height="65px"
              style="left: 139px;top:10px;position: relative;" />
          </div>
        </div>
      </div>

      <div class="row" style="margin-top: 12px;">
        <div class="col-12">
          <mat-label class="matlabel1">User name
            <img class="login_user_icon" src="../../assets/img/new_user.png">
            <input class="loginstyle" name="username" [(ngModel)]="username" required matInput type="text"
              placeholder="Email or Mobile number" (change)="focusUserType(username)"/>
          </mat-label><br>
        </div>
        <div class="col-12">
          <mat-label class="matlabel1">Password
            <img class="password_user_icon" src="../../assets/img/new_pass.png">
            <input class="loginstyle" type="password" (click)="pwd=''" name="pwd" [(ngModel)]="pwd" required matInput
              type="password" placeholder="Password" />
          </mat-label><br>
        </div>
        
        <div class="col-12"  *ngIf="selectUser" style="margin-bottom: 5px;">
          <mat-label class="matlabel1">
            <div class="row selectUser">
              <div class="col-4" *ngFor="let user of usertype;let i = index">
                <div *ngIf="user == 'doctor'">
                  <img class="img-fluid" src="../../../assets/images/login_model/doctor_model.svg" alt="Card image cap"
                    style="width: 107px;" (click)="selectType('doctor');">
                </div>
                <div *ngIf="user == 'Admin'">
                  <img class="img-fluid" src="../../../assets/images/login_model/Admin_icon.svg" alt="Card image cap"
                  style="width: 107px;" (click)="selectType('Admin');">
                </div>
                <div *ngIf="user == 'consultant'">
                  <img class="img-fluid" src="../../../assets/images/login_model/Consultant_icon.svg" alt="Card image cap"
                  style="width: 107px;" (click)="selectType('consultant');">
                </div>
                <div
                  *ngIf="user == 'diagnosis' || user == 'Chief pathologist' || user == 'Pathologist' || user == 'Chief lab technician' || user == 'Lab technician'">
                  <img class="img-fluid" src="../../../assets/images/login_model/diagnosis_model.svg"
                    alt="Card image cap" style="width: 107px;" (click)="selectType(user);">
                </div>
                <div *ngIf="user == 'front-desk'">
                  <img class="img-fluid" src="../../../assets/images/login_model/front_model.svg" alt="Card image cap"
                    style="width: 107px;" (click)="selectType('front-desk');">
                </div>
                <div *ngIf="user == 'Billing'">
                  <img class="img-fluid" src="../../../assets/images/login_model/billing.svg" alt="Card image cap"
                    style="width: 107px;" (click)="selectType('Billing');">
                </div>
                <div *ngIf="user == 'pharmacy'">
                  <img class="img-fluid" src="../../../assets/images/login_model/pharmacy.svg" alt="Card image cap"
                    style="width: 107px;" (click)="selectType('pharmacy');">
                </div>
                <div *ngIf="user == 'physio'">
                  <img class="img-fluid" src="../../../assets/images/login_model/physio_model.svg" alt="Card image cap"
                    style="width: 107px;" (click)="selectType('physio');">
                </div>
                <div *ngIf="user == 'nurse'">
                  <img class="img-fluid" src="../../../assets/images/login_model/nurse_model.svg" alt="Card image cap"
                    style="width: 107px;" (click)="selectType('nurse');">
                </div>
                <div *ngIf="user == 'client'">
                  <img class="img-fluid" src="../../../assets/images/login_model/client_model.svg" alt="Card image cap"
                    style="width: 107px;" (click)="selectType('client');">
                </div>
                <div *ngIf="user == 'dietician'">
                  <img class="img-fluid" src="../../../assets/images/login_model/Dietician_icon.svg" alt="Card image cap"
                    style="width: 107px;" (click)="selectType('dietician');">
                </div>
                <div *ngIf="user == 'OT admin'">
                  <img class="img-fluid" src="../../../assets/images/login_model/OT_admin.svg" alt="Card image cap"
                    style="width: 107px;" (click)="selectType('OT admin');">
                </div>
              </div>
            </div>
          </mat-label>
        </div>
        <div class="col-12" style="margin-bottom: 12px;">
          <a *ngIf="client_app_booking_flag" routerLink="/clientreg" style="float: left;"><label
              class="register">Register</label></a>
          <a routerLink="/forgotpwd"><label class="forgotpwd">Forgot Password</label></a>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="packageExpierflag">
    <div class="grid_Expier">
      <img (click)="close_expier()" src="../../../assets/img/close_icon.png" alt="Card image cap"
        style="width: 20px;right: 5px;top: 4px;position: absolute;cursor: pointer;z-index: 2;">
      <div class="entry_expiery">
        <div class="row">
          <div class="col-12">
            <div style="width: fit-content; margin: 7px auto; ">
              <img width="110px" src="../../../assets/img/Subsribe_msg_icon.png" />
              <p>{{Package_Name +" - "+ Package_Validity}}</p>
            </div>
          </div>
          <div class="col-12" style="padding: 0 55px;">
            <div class="table-responsive" st>
              <table id="packagetable" class="table table-hover table-dynamic">
                <thead>
                  <tr>
                    <th>Featuers</th>
                    <th *ngFor="let Packages of MainPackage" style="text-align: center" class="packge_cell">
                      {{Packages.package_name}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let subpack of PackageModels;let i = index">
                    <td style="text-align: left;">{{subpack.package_name}}</td>
                    <td *ngFor="let Packages of Pack_id_list" style="text-align: center" class="packge_cell">
                      <img src="{{Packages[i]}}" width="22" height="22" />
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td *ngFor="let Packages of MainPackage" style="text-align: center" class="packge_cell">
                      <input type="button" ng-model="Packages.package_id" value="Buy now" class="Buynow_Class"
                        (click)="BuyNow_Click(Packages.package_id)" />
                      <br /><br />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>