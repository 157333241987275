import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AdminViewService } from 'src/app/Admin_module/admin-view/admin-view.service';
import { FrontDeskService } from 'src/app/FrontDesk_module/front-desk/front-desk.service';
import { Helper_Class } from 'src/app/helper_class';
import { ipaddress } from 'src/app/ipaddress';
import { ServerApi } from 'src/app/server-api';
import { DiagnosisService } from '../../Diagnosis_module/diagnosis/diagnosis.service';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
@Component({
  selector: 'app-user-package-list',
  templateUrl: './user-package-list.component.html',
  styleUrls: ['./user-package-list.component.scss']
})
export class UserPackageListComponent implements OnInit {
  public userinfo;
  public diagCenterID;
  public centre_id;
  public createFlag:boolean = true;
  public packageListArray:any = [];
  public editFlag:boolean = true;
  dtOptions: DataTables.Settings = {};
  constructor(public serviceAPI: ServerApi, public toastr: ToastrService, public adminService: MenuViewService, public frontService:MenuViewService,public diagService: MenuViewService,) { }

  ngOnInit(): void {
    var userinfo = Helper_Class.getInfo();
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 25,
      "lengthMenu": [[25, 50, -1], [25, 50, "All"]],
      "columnDefs": [
        { "orderable": false, "targets": [ 0,4 ] },
      ],
      "language": {
        "search": "<img src='../../../assets/ui_icons/Bill_list/Filter_Icon.svg' width='18px'/>",
        searchPlaceholder: "Filter by name, package name or package type"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12">><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"l><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    //this.diagCenterID = userinfo.diag_centre_id;
    if( Helper_Class.getLoginType() == "diagnosis" ) {
      this.centre_id = userinfo.hospitals[0].hptl_clinic_id;
    }else{
      this.centre_id = userinfo.hptl_clinic_id;
    }
    this.getPackageList();
  }
  async getPackageList(){
    var sendData = {
        centre_id : this.centre_id
      }
    var response = await this.serviceAPI.PostData('adm/gbpd',sendData).toPromise();
    if (response) {
        var obj = JSON.parse(JSON.stringify(response));
        console.log("obj" + JSON.stringify(obj));
        for( var i = 0; i < obj.package_details.length; i++ ){
          this.packageListArray.push({
            health_pkg_id : obj.package_details[i].health_pkg_id,
            client_reg_id : obj.package_details[i].client_reg_id,
            relation_id : obj.package_details[i].relation_id,
            sub_rel_id : obj.package_details[i].sub_rel_id,
            first_name : obj.package_details[i].first_name != undefined ? encrypt_decript.Decript(obj.package_details[i].first_name) : "",
            last_name : obj.package_details[i].last_name != undefined ? encrypt_decript.Decript(obj.package_details[i].last_name) : "",
            middle_name : obj.package_details[i].middle_name != undefined ? encrypt_decript.Decript(obj.package_details[i].middle_name) : "",
            address1 : obj.package_details[i].address1 != undefined ? encrypt_decript.Decript(obj.package_details[i].address1) : "",
            address2 : obj.package_details[i].address2 != undefined ? encrypt_decript.Decript(obj.package_details[i].address2) : "",
            pkg_type_desc : obj.package_details[i].pkg_type_desc,
            package_name :  obj.package_details[i].package_name,
            location :  obj.package_details[i].location,
            city :  obj.package_details[i].city,
            state :  obj.package_details[i].state,
            country :  obj.package_details[i].country,
            zipcode :  obj.package_details[i].zipcode,
            mobile : obj.package_details[i].mobile != undefined ? encrypt_decript.Decript(obj.package_details[i].mobile) : "",
            image : ipaddress.Ip_with_img_address + obj.package_details[i].image,
            price : obj.package_details[i].price,
            package_short_desc : obj.package_details[i].package_short_desc,
            gender : obj.package_details[i].gender
          })
        }
        console.log("this.packageListArray" + JSON.stringify(this.packageListArray));        
    }
  }
  viewBill(e){
    var sendData = {
      provied : Helper_Class.getLoginType(),
      flow : "userPackage",
      content : e
    }
    Helper_Class.setPackage(null);
    Helper_Class.setPackage(sendData);
    if( Helper_Class.getLoginType() == "diagnosis" ) { 
      this.diagService.sendMessage("createUserPackage");
    }else if( Helper_Class.getLoginType() == "Admin" ) { 
      this.adminService.sendMessage("createUserPackage");
    }
    
  }
}
