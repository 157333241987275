<div class="Details container" id="Begin_content_others" style="padding: 0 15px 0 15px; max-width: 100%;">
    <div class="row">
        <div class="col-12 p-0">
            <mdb-card>
                <mdb-card-header class="bg-white">
                    <div class="headerCover">
                        <div class="headerTilte app_list_header">
                            <h5 class="m-0" class="mainHeadingStyle">Patient list</h5>
                        </div>
                        <div class="headerButtons">
                        </div>
                    </div>
                </mdb-card-header>
                <mdb-card-body>
                    <div class="row" style="width:100%;margin:0 auto;position: relative;">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-12 col-sm-4 col-md-3 col-xl-2 col-lg-2" *ngIf="!foodLogFlag">
                                    <mat-label class="matlabel" style="font-size: 15px;
                                          font-weight: 600;">Visualization</mat-label><br>
                                    <select disableOptionCentering class="ipcss" (change)="changeVisualization()"
                                        [(ngModel)]="visualizationType">
                                        <option value="graph">Graph </option>
                                        <option value="data">Data</option>
                                    </select>
                                </div>
                                <div class="col-12 col-sm-4 col-md-3 col-xl-2 col-lg-2">
                                    <mat-label class="matlabel" style="font-size: 15px;
                                          font-weight: 600;">Range</mat-label><br>
                                    <select disableOptionCentering class="ipcss" (change)="changeAppointmentType()"
                                        [(ngModel)]="appointmentType">
                                        <option value="Today">Today</option>
                                        <option value="Weekly">Weekly</option>
                                        <option value="Fort Night">Fort Night</option>
                                        <option value="Monthly">Monthly</option>
                                        <option value="3 Months">3 Months</option>
                                        <option value="6 Months">6 Months</option>
                                        <option value="Yearly">Yearly</option>
                                        <option value="Date">Date</option>
                                    </select>
                                </div>
                                <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2 select_bottom" *ngIf="dateFlag">
                                    <mat-label class="matlabel">From<br>
                                        <input type="date" class="ipcss widthbilllist datecss"
                                            (change)="selectFromDate(fromDate)" [(ngModel)]="fromDate" #matInput
                                            max="{{currentMaxDate}}">
                                    </mat-label>
                                </div>
                                <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2" *ngIf="dateFlag">
                                    <mat-label class="matlabel">To<br>
                                        <input type="date" class="ipcss widthbilllist datecss" id="appt_date"
                                            (change)="selectToDate(toDate)" [(ngModel)]="toDate" max="{{currentMaxDate}}"
                                            matInput>
                                    </mat-label>
                                </div>
                                <img *ngIf="dateFlag" (click)="getGraphData()" src='../../../assets/img/search.png'
                                    width='18px' height="auto"
                                    style="width: 46px;height: 19px;position: relative;top: 24px;right: 12px;" />
                                <div class="col-12 col-sm-4 col-md-3 col-xl-3 col-lg-3 my-auto">
                                    <div class="row">
                                        <div class="col-4 p-0">
                                            <mat-label class="matlabel ">Food log</mat-label>
                                        </div>
                                        <div class="col-2 margin-bottomvalue">
                                            <mat-button-toggle id="toggid2" class="togglebtn" [(ngModel)]="foodLogFlag"
                                                ngDefaultControl [checked]="foodLogFlag"
                                                (change)="changeFoodLog($event.source.checked);">
                                                <span class="familytab" *ngIf="!foodLogFlag"><img
                                                        class="foot_toggle_img"
                                                        src="../../../assets/ui_icons/cross_mark.svg"></span>
                                                <span class="familytab" *ngIf="foodLogFlag"><img class="foot_toggle_img"
                                                        src="../../../assets/ui_icons/tick_mark.svg"></span>
                                            </mat-button-toggle>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="foodLogFlag">
                                <div class="foodLogCover">
                                    <div class="accordion accordion-flush" id="accordionFlushExample">
                                        <div class="accordion-item" style="background: none; height: 36px;">
                                            <h2 class="accordion-header" id="flush-headingOne">
                                                <div class="container-fluid coverRow">
                                                    <div class="row" style="text-align: center;">
                                                        <div class=" p-0 my-auto ConHeading" data-bs-toggle="collapse"
                                                            data-bs-target="#flush-collapseOne" aria-expanded="true"
                                                            aria-controls="flush-collapseOne">Date</div>
                                                        <div class=" p-0 my-auto ConHeading" data-bs-toggle="collapse"
                                                            data-bs-target="#flush-collapseOne" aria-expanded="true"
                                                            aria-controls="flush-collapseOne"> Morning </div>
                                                        <div class=" p-0 my-auto ConHeading" data-bs-toggle="collapse"
                                                            data-bs-target="#flush-collapseOne" aria-expanded="true"
                                                            aria-controls="flush-collapseOne"> Break-fast </div>
                                                        <div class=" p-0 my-auto ConHeading" data-bs-toggle="collapse"
                                                            data-bs-target="#flush-collapseOne" aria-expanded="true"
                                                            aria-controls="flush-collapseOne"> Mid-day </div>
                                                        <div class=" p-0 my-auto ConHeading" data-bs-toggle="collapse"
                                                            data-bs-target="#flush-collapseOne" aria-expanded="true"
                                                            aria-controls="flush-collapseOne"> Lunch </div>
                                                        <div class=" p-0 my-auto ConHeading" data-bs-toggle="collapse"
                                                            data-bs-target="#flush-collapseOne" aria-expanded="true"
                                                            aria-controls="flush-collapseOne"> Evening </div>
                                                        <div class=" p-0 my-auto ConHeading" data-bs-toggle="collapse"
                                                            data-bs-target="#flush-collapseOne" aria-expanded="true"
                                                            aria-controls="flush-collapseOne"> Dinner </div>
                                                        <div class=" p-0 my-auto ConHeading" data-bs-toggle="collapse"
                                                            data-bs-target="#flush-collapseOne" aria-expanded="true"
                                                            aria-controls="flush-collapseOne"> Night </div>
                                                    </div>
                                                </div>
                                            </h2>
                                        </div>
                                        <div style="height: calc(100% - 61px);  overflow-y: auto; overflow-x: hidden;">
                                            <div class="accordion-item"
                                                *ngFor="let food of foodLogArray; let i = index">
                                                <h2 class="accordion-header" id="flush-heading{{food.index}}"
                                                    style="margin: 0 !important;">
                                                    <div class="container-fluid coverRow">
                                                        <div class="row" style="text-align: center;">
                                                            <div class="my-auto ConContent p-0 " data-bs-toggle="collapse"
                                                                aria-expanded="false"
                                                                aria-controls="flush-collapse{{food.index}}">
                                                                {{food.date}}</div>
                                                            <div *ngFor="let session of food.session; let j = index"
                                                                class="my-auto ConContent" data-bs-toggle="collapse"
                                                                aria-expanded="false"
                                                                aria-controls="flush-collapse{{food.index}}">
                                                                <div [style]="{ 'color': session.color,'border-color': session.color}"
                                                                    class="foodLogPer"
                                                                    (click)="accordianOpen(food.index,session.name)">
                                                                    {{session.percentage}} % </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </h2>
                                                <div id="flush-collapse{{food.index}}"
                                                    class="accordion-collapse collapse removeShow"
                                                    aria-labelledby="flush-heading{{0}}"
                                                    data-bs-parent="#accordionFlushExample">
                                                    <div class="accordion-body" style="border-top: 1px solid #84ccf5;">
                                                        <div *ngFor="let session of food.session; let j = index">
                                                            <div class="row" *ngIf="session.name == food.sectedSession">
                                                                <div class="col-12 col-sm-12 col-12 col-lg-6 col-xl-6">
                                                                    <div class="card readingCard">
                                                                        <div class="card-header readignCardHeader">
                                                                            {{session.name}}
                                                                        </div>
                                                                        <div class="card-body "
                                                                            style="padding-bottom: 0px;">
                                                                            <div class="row foodLogBody">
                                                                                <div class="col-6"
                                                                                    style="border-right: 1px solid #6abaeb;">
                                                                                    <div class="foodLogCardBodyHeading">
                                                                                        Consumed </div>
                                                                                    <div *ngFor="let consumed of session.tracking;"
                                                                                        class="foodLogItemsCover">
                                                                                        <div class="foodLogItemsLable">
                                                                                            {{consumed.item}}</div>
                                                                                        <div class="foodLogItemsWeight">
                                                                                            {{consumed.weight}}</div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-6">
                                                                                    <div class="foodLogCardBodyHeading">
                                                                                        Suggested </div>
                                                                                    <div *ngFor="let plan of session.plan;"
                                                                                        class="foodLogItemsCover">
                                                                                        <div class="foodLogItemsLable">
                                                                                            {{plan.item}}</div>
                                                                                        <div class="foodLogItemsWeight">
                                                                                            {{plan.weight}}</div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row foodLogFotter">
                                                                                <div class="col-6">
                                                                                    <div
                                                                                        style="display:flex;width: 100%;">
                                                                                        <div style="width: 50%;">
                                                                                            <div class="foodLogSt">
                                                                                                Energy :
                                                                                                {{session.energy}}
                                                                                            </div>
                                                                                            <div class="foodLogSt">
                                                                                                proteins :
                                                                                                {{session.proteins}}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div style="width: 50%;">
                                                                                            <div class="foodLogSt">Carbs
                                                                                                : {{session.carbs}}
                                                                                            </div>
                                                                                            <div class="foodLogSt">Fat :
                                                                                                {{session.fat}} </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-6">
                                                                                    <div
                                                                                        style="display:flex;width: 100%;">
                                                                                        <div style="width: 50%;">
                                                                                            <div class="foodLogSt">
                                                                                                Energy :
                                                                                                {{session.plan_energy}}
                                                                                            </div>
                                                                                            <div class="foodLogSt">
                                                                                                proteins :
                                                                                                {{session.plan_proteins}}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div style="width: 50%;">
                                                                                            <div class="foodLogSt">Carbs
                                                                                                : {{session.plan_carbs}}
                                                                                            </div>
                                                                                            <div class="foodLogSt">Fat :
                                                                                                {{session.plan_fat}}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-12 col-sm-12 col-12 col-lg-6 col-xl-6">
                                                                    <canvas id="{{session.name}}{{food.index}}"
                                                                        width="150" height="94"
                                                                        style="display: block;"></canvas>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="!foodLogFlag">
                                <div class="col-12 col-sm-12 col-md-6 col-sm-6 col-xl-6" style="margin-bottom: 16px;">
                                    <div class="card readingCard height100" >
                                        <div class="card-header readignCardHeader">
                                            Energy
                                        </div>
                                        <div class="card-body height100">
                                            <div *ngIf="visualFlag" class="height100">
                                                <div *ngIf="showGraph" >
                                                    <img src="../../../assets/ui_icons/diet_plan/graph_bar_icon.svg" style="width: 57px; height: 28px; top: 3px; position: absolute; right: -6px;" alt="" (click)="energyToggle('showToggle')">
                                                    <canvas *ngIf="visualFlag" id="EnergyGraph" width="361" height="200"
                                                        style="display: block;"></canvas>
                                                </div>
                                                <div *ngIf="!showGraph" class="height100">
                                                    <img src="../../../assets/ui_icons/diet_plan/graph_icon.svg" style="width: 57px; height: 28px; top: 3px; position: absolute; right: -6px;" alt="" (click)="energyToggle('showToggle')">
                                                    <div class="height100">
                                                        <div style="width: 78%; height: 99%;position:relative;text-align: center;">
                                                            <div style="width: 52%;height: 49%;position:absolute;bottom:0;left:0;background:#548235;z-index:9;color: #fff;    align-items: center;
                                                        display: flex;
                                                        justify-content: center;">
                                                                <div *ngIf="lableFlag == '1'"> Keep going <br>
                                                                    ({{energyPercentage}} %) </div>
                                                            </div>
                                                            <div
                                                                style="width: 66%;height: 69%;position:absolute;bottom:0;left:0;background:#ffc000;z-index:8;color: #fff;padding-top: 3%;">
                                                                <div *ngIf="lableFlag == '2'">Need attention
                                                                    ({{energyPercentage}} %)</div>
                                                            </div>
                                                            <div
                                                                style="width: 83%;height: 85%;position:absolute;bottom:0;left:0;background:#f98137;z-index:7;color: #fff;padding-top: 2%;">
                                                                <div *ngIf="lableFlag == '3'">Need greater attention
                                                                    ({{energyPercentage}} %)</div>
                                                            </div>
                                                            <div
                                                                style="width: 100%;height: 100%;position:absolute;bottom:0;left:0;z-index:6;background:#ff0000;color: #fff;padding-top: 2%;">
                                                                <div *ngIf="lableFlag == '4'">Need immediate steps to
                                                                    control diet habits ({{energyPercentage}} %)
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            <div class=" " *ngIf="!visualFlag" style="height: 370px;">
                                                <div class="row" style="text-align: center; font-weight: 600;">
                                                    <div class="col-4">Date</div>
                                                    <div class="col-4">Suggested</div>
                                                    <div class="col-4">Consumed</div>
                                                </div>
                                                <div class="container dataCover ">
                                                    <div class="row dataRow"
                                                        *ngFor="let energy of dataSetEnergy;let i = index"
                                                        [style]="{ 'border-color': energy.EnergyColor}">
                                                        <div class="col-4 dataContent">{{energy.date}}</div>
                                                        <div class="col-4 dataContent">{{energy.DPEnergy}}</div>
                                                        <div class="col-4 dataContent"
                                                            [style]="{ color: energy.EnergyColor}">
                                                            {{energy.DTEnergy}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-6 col-sm-6 col-xl-6" style="margin-bottom: 16px;">
                                    <div class="card readingCard height100">
                                        <div class="card-header readignCardHeader">
                                            proteins
                                        </div>
                                        <div class="card-body">
                                            <canvas *ngIf="visualFlag" id="proteinsGraph" width="361" height="200"
                                                style="display: block;"></canvas>
                                            <div class="" *ngIf="!visualFlag">
                                                <div class="row" style="text-align: center; font-weight: 600;">
                                                    <div class="col-4">Date</div>
                                                    <div class="col-4">Suggested</div>
                                                    <div class="col-4">Consumed</div>
                                                </div>
                                                <div class="container dataCover">
                                                    <div class="row dataRow"
                                                        *ngFor="let energy of dataSetProtine;let i = index"
                                                        [style]="{ 'border-color': energy.EnergyColor}">
                                                        <div class="col-4 dataContent">{{energy.date}}</div>
                                                        <div class="col-4 dataContent">{{energy.DPEnergy}}</div>
                                                        <div class="col-4 dataContent"
                                                            [style]="{ color: energy.EnergyColor}">
                                                            {{energy.DTEnergy}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-6 col-sm-6 col-xl-6" style="margin-bottom: 16px;">
                                    <div class="card readingCard height100">
                                        <div class="card-header readignCardHeader">
                                            Fats
                                        </div>
                                        <div class="card-body">
                                            <canvas *ngIf="visualFlag" id="fatsGraph" width="361" height="200"
                                                style="display: block;"></canvas>
                                            <div class="" *ngIf="!visualFlag">
                                                <div class="row" style="text-align: center; font-weight: 600;">
                                                    <div class="col-4">Date</div>
                                                    <div class="col-4">Suggested</div>
                                                    <div class="col-4">Consumed</div>
                                                </div>
                                                <div class="container dataCover">
                                                    <div class="row dataRow"
                                                        *ngFor="let energy of dataSetFats;let i = index"
                                                        [style]="{ 'border-color': energy.EnergyColor}">
                                                        <div class="col-4 dataContent">{{energy.date}}</div>
                                                        <div class="col-4 dataContent">{{energy.DPEnergy}}</div>
                                                        <div class="col-4 dataContent"
                                                            [style]="{ color: energy.EnergyColor}">
                                                            {{energy.DTEnergy}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-6 col-sm-6 col-xl-6" style="margin-bottom: 16px;">
                                    <div class="card readingCard height100">
                                        <div class="card-header readignCardHeader">
                                            Carbs
                                        </div>
                                        <div class="card-body">
                                            <canvas *ngIf="visualFlag" id="crabsGraph" width="361" height="200"  style="display: block;"></canvas>
                                            <div class=" " *ngIf="!visualFlag">
                                                <div class="row" style="text-align: center; font-weight: 600;">
                                                    <div class="col-4">Date</div>
                                                    <div class="col-4">Suggested</div>
                                                    <div class="col-4">Consumed</div>
                                                </div>
                                                <div class="container dataCover">
                                                    <div class="row dataRow"
                                                        *ngFor="let energy of dataSetCarbs;let i = index"
                                                        [style]="{ 'border-color': energy.EnergyColor}">
                                                        <div class="col-4 dataContent">{{energy.date}}</div>
                                                        <div class="col-4 dataContent">{{energy.DPEnergy}}</div>
                                                        <div class="col-4 dataContent"
                                                            [style]="{ color: energy.EnergyColor}">
                                                            {{energy.DTEnergy}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Modal -->
                    <div class="modal fade" id="staticBackdrop"  data-bs-keyboard="false"
                        tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div class="modal-dialog" style="position: fixed; bottom: 16%; right: 6%; margin: 0px; width: 33%;">
                            <div class="modal-content">
                                <div class="titleStyle">
                                    <h5 class="modal-title" id="staticBackdropLabel">Send Notification</h5>
                                    <span class="closeicon" data-bs-dismiss="modal" aria-label="Close"
                                        style="float: right;"><img src="../../../assets/img/close_icon.png"
                                            alt="Card image cap" style="width:19px"> </span>
                                </div>
                                <div class="modal-body">
                                    <textarea class="ipcss noappt_width"  style="height:108px !important;" [(ngModel)]="notificationText" maxlength="500"></textarea>
                                </div>
                                <div class="modal-footer">
                                    <img src="../../../assets/ui_icons/diet_plan/send_button.svg" (click)="sendNotification()" alt="Card image cap"
                                        style="width:85px; height: 25px;" >
                                </div>
                            </div>
                        </div>
                    </div>
                </mdb-card-body>
            </mdb-card>
        </div>
    </div>
    <img src="../../../assets/ui_icons/diet_plan/Notification_icon.svg" (click)="openNotification()" alt="Card image cap" style="    width: 77px; height: 50px; position: fixed; bottom: 10%; right: 3%;" >
</div>