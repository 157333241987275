<div mat-dialog-title class="popup_border_icd">
    <h5 class="header_txt">Report details</h5>
    <div mat-dialog-actions>        
        <img src="../../../assets/img/close_icon.png" (click)="ClosePopup()" width="20px" height="20px" id="close_icd"/>
    </div>
</div>
<div mat-dialog-content class="icd_content">
    <div class="row">
        <div class="col-12 col-sm-6 col-md-6 col-lg-6  col-xl-6">
            <div class="col-md-12 col-lg-12 col-sm-12 col-xl-12 padding_right_Zero" style="text-align: left;">
                <mat-label class="matlabel"><strong>{{diag_name}}</strong></mat-label>
            </div>
            <div class="col-md-12 col-lg-12 col-sm-12 col-xl-12 padding_right_Zero" style="text-align:left">
                <mat-label class="matlabel">{{diag_add}},{{diag_loc}},</mat-label>
            </div>
            <div class="col-md-12 col-lg-12 col-sm-12 col-xl-12 padding_right_Zero" style="text-align:left">
                <mat-label class="matlabel">{{diag_cityzip}},{{diag_statecon}}</mat-label>
            </div>
            <div class="col-md-12 col-lg-12 col-sm-12 col-xl-12 padding_right_Zero" style="text-align:left">
                <mat-label class="matlabel">Mobile No:{{diag_teleph}}</mat-label>
            </div>
        </div>
   
        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 block1_reports_view block1_mrwidth">
            <mat-label class="matlabel"><strong>Personal Information</strong></mat-label>
            <table id="report_view">
               
                <tr>
                    <th class="matlabel">Client id :</th>
                    <td class="dataLable" >{{clent_id}}</td>
                </tr>
                <tr>
                    <th class="matlabel">Name :</th>
                    <td class="dataLable">{{client_another_name}}</td>
                </tr>
                <tr>
                    <th class="matlabel">Age :</th>
                    <td class="dataLable">{{client_age}}</td>
                </tr>
                <tr>
                    <th class="matlabel">Gender :</th>
                    <td class="dataLable">{{client_gender}}</td>
                </tr>
            </table>

            <table id="report_view">
                <tr>
                    <th class="matlabel">Date :</th>
                    <td class="dataLable">{{date_txt}}</td>
                </tr>
                <tr>
                <th class="matlabel">Referred by :</th>
                <td class="dataLable">{{diag_refertxt}}</td>
                </tr>
                <tr>
                <th class="matlabel">Collected at :</th>
                <td class="dataLable">{{time_collect}}</td>
                </tr>
            </table>
        </div>
    </div>
    <div  *ngIf="!blood_urine_faeces_div">
        <p class="tervys_heading block1_mrwidth"><b>Clinical Test Results</b></p>
        <div class="block1_grid block1_mrwidth" *ngFor="let test of second_opinion_mintlist">
            <!-- <mat-label class="matlabel"><strong>{{test.test_name+" test"}}</strong></mat-label> -->
                <table id="tbl" class="table table-hover table-dynamic" style="width: 90%;margin-left: 20px;text-align: center;">
                    <thead>
                    <tr>
                        <th>Test Name</th>
                        <th>Reading</th>
                        <th>Unit</th>
                        <th>Referred value</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr><td style="background-color: #F0F4F8;"><b>{{test.test_name+" test"}}</b></td>  </tr>
                    <tr *ngFor="let subtest of second_opinion_subtlist" >
                        <td>{{subtest.sub_test_name}}</td>
                        <td>{{subtest.reading}}</td>
                        <td>{{subtest.unit}}</td>
                        <td>
                            <!-- {{subtest.test_range}} -->
                            <div class="matlabel" id="viewhtml" [innerHtml]="subtest.test_range"></div>
                        </td>
                    </tr>
                    </tbody>
                </table>
        </div>
    </div>
    <div *ngIf="!xray_scan_ultra_div">
        <div *ngFor="let report of second_opinion_mintlist1">
          <mat-label class="matlabel">Report type :{{report_type}}</mat-label>
          <mat-label class="matlabel">Test name :{{report.test_name}}</mat-label>
          <div class="block1_reports block1_radiologywt" >
            <div *ngIf="report_img_flag" class="block1_grid img_size">
                <p class="tervys_heading"><b>Attachment</b><p>
                <img alt="" src="{{report_img}}" class="img_radiology" />
            </div>
            <div class="block1">
              <table id="report_radiology_tbl">
                  <tr>
                      <th>Sequences</th>
                      <td>
                        <div class="matlabel" [innerHtml]="report.sequences"></div>
                        <!-- {{report.sequences}} -->
                    </td>
                  </tr><br>
                  <tr>
                      <th>Impression</th>
                      <td>
                        <div class="matlabel" [innerHtml]="report.impression"></div>

                          <!-- {{report.impression}} -->
                        </td>
                  </tr>
              </table>
            </div>
          </div>  
        </div>
    </div>
    <hr style="border: 1px solid #06438a;">
    <div class="row">
        <div class="col-md-12 col-lg-12 col-sm-12 col-xl-12 padding_right_Zero">
            <mat-label class="matlabel">Reviewed by :{{review_by}}</mat-label>
        </div>
        <div class="col-md-12 col-lg-12 col-sm-12 col-xl-12 padding_right_Zero">
            <mat-label class="matlabel">Signature :<img alt="" src="{{review_sign}}" class="sign_img" /></mat-label>    
        </div>
    </div>
</div>
<div mat-dialog-actions align="end" style="width: auto;right: 19px;">
</div>