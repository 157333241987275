<div mat-dialog-title class="popup_border_icd">
    <h5 class="header_txt">Diagnosis</h5>
    <div mat-dialog-actions>
      <img src="../../../assets/img/close_icon.png" (click)="ClosePopup()" width="20px" height="20px" id="close_icd" />
    </div>
  </div>
  <div mat-dialog-content class="icd_content">
  
    <img src="{{check}}" class="imagecheck" >
   
  </div>
