import { Component, OnInit } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Helper_Class } from '../../helper_class';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { Client_Helper } from '../Client_helper';
import { ClientViewService } from '../clientview/client-view.service';
import { DietTrackHistoryComponent } from '../diet-track-history/diet-track-history.component';
import { SgaScoreTrackerComponent } from '../sga-score-tracker/sga-score-tracker.component';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { encrypt_decript } from 'src/assets/js/Encription_Decription';
import { Date_Formate } from 'src/assets/js/common';
import { ipaddress } from '../../ipaddress';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { ServerApi } from '../../server-api';
declare var $: any;
import moment from 'moment';

@Component({
  selector: 'app-new-diet-tracker',
  templateUrl: './new-diet-tracker.component.html',
  styleUrls: ['./new-diet-tracker.component.scss']
})
export class NewDietTrackerComponent implements OnInit {
  public dietPlanFlag = "min";
  public calories: string;
  public totalCalories = "0.00";
  subscription: Subscription;
  public sgaOverallScore: string;
  public userinfo: any;
  public clientRegId;
  public relationId;
  public subRelId;
  public splId;
  public splName;
  public displayArray;
  public morningFoodItem = [];
  public breadfastFoodItem = [];
  public midDayFoodItem = [];
  public lunchFoodItem = [];
  public eveningFoodItem = [];
  public dinnerFoodItem = [];
  public nightFoodItem = [];
  public morningFoods;
  public BreakfastFoods;
  public MidDayFoods;
  public LunchFoods;
  public EveningFoods;
  public DinnerFoods;
  public NightFoods;
  public mainDishFlag: boolean = false;
  public contanier;
  public foodImages = [];
  public dietMrngArry = [];
  public breakFastArryData = [];
  public midDayArryData = [];
  public lunchArryData = [];
  public eveningArryData = [];
  public dinnerArryData = [];
  public nightArryData = [];
  public relationName;
  public relationlistData = [];
  public relationFName;
  public relationLName;
  public relationMName;
  public currentDate;
  public currentTime;
  public session;
  public currentYear;
  public lifeStyleId;
  public today;
  public tempDisplyArray = [];
  public dietPlanHist: string;
  public trackerData: string;
  public dietFlow;
  public pageHeading;
  public dietPlanAll = "0";
  public ynopt1: boolean = true;
  public dietType = "self";
  public previousDay;
  public tempMorningArray = [];
  public tempBreakfastArray = [];
  public tempMid_dayArray = [];
  public tempLunchArray = [];
  public tempEveningArray = [];
  public tempDinnerArray = [];
  public tempNightArray = [];
  public dayChangeFlag: boolean = true;
  public saveFlag:boolean = true;
  public pdfFlag : boolean = false;
  public pdfURL;
  public checkedFlag: boolean = false;
  public trackerArray: any = []; 
  public week;
  public previousFlag:boolean;
  public nextFlag:boolean = true;
  public selectedDate;
  public updateURLFlag:boolean =  false;
  public appointmentType = "Today";
  public dateFlag:boolean = false;
  public fromDate;
  public toDate;
  public sendToDate;      
  public sendFromDate;

  customOptions: OwlOptions = {
    autoplay: false,
    autoplaySpeed: 1500,
    loop: true,
    pullDrag: false,
    dots: false,
    navSpeed: 750,
    navText: ['<i class="fa fa-chevron-circle-left left" aria-hidden="true" style="font-size: 50px;color: #bfbbbb;position: absolute;left: 16px;top: 46%;z-index: 1;"></i>', '<i class="fa fa-chevron-circle-right right" aria-hidden="true"></i>'],
    responsive: {
      0: {
        items: 4
      },
      400: {
        items: 6
      },
      740: {
        items: 8
      },
      940: {
        items: 9
      }
    },
    nav: true,
  }

  constructor(public dialog: MatDialog, public http: Http, public gservice: CommonDataService, public messageservice: ClientViewService,
    public router: Router, public route: ActivatedRoute, public toastr: ToastrService, public sanitizer: DomSanitizer, public serviceAPI: ServerApi) {
    
      this.calories = "0";

  }

  ngOnInit(): void {
    this.saveFlag = true;
    this.userinfo = Helper_Class.getInfo();
    this.dietFlow = Client_Helper.getdietFlow().dietFlow;
    this.pageHeading = this.dietFlow == "dietTracker" ? "Diet Tracker" : "Diet Plan";
    console.log(this.dietFlow);
    this.getRelationlist();
  }

  async getCurrentDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    await this.http.post(ipaddress.getIp + "adm/curdate",
      JSON.stringify({
        country: ipaddress.country_code.toString()
      }), { headers: headers }).toPromise().then(
        data => {
          var obj = data.json();
          this.currentDate = Date_Formate(obj.current_date);
          this.currentTime = obj.current_time;
          this.currentYear = obj.current_date.split('-')[0];
          var today = (new Date()).getDay();
          
          let days = [];
          let daysRequired = 6;
          for (let i = 0; i <= daysRequired; i++) {
            var day = {
              days: moment().subtract(i, 'days').format('dddd'),
              date: moment().subtract(i, 'days').format('DD-MM-YYYY'),
            }
            days.push(day)
          }
     
          for (var i = 0; i < days.length; i++) {
            var selected = i == 0 ? true : false;
            var sendArray = {
              created_date: Date_Formate(days[i].date),
              day: days[i].days,
              dplan_morning: [],
              dplan_breakfast: [],
              dplan_midday: [],
              dplan_lunch: [],
              dplan_evening: [],
              dplan_dinner: [],
              dplan_night: [],
              cal_required: "0.00",
              cal_intake: "0.00"
            }
            this.trackerArray.push(sendArray)
          }
          console.log("this.trackerArray" + JSON.stringify(this.trackerArray));
          this.getCasesheetData();
        });
  }

  changeAppointmentType() {
    var e;
    this.sendFromDate = Date_Formate(this.currentDate);
    this.dateFlag = false;
    let days = [];
    if (this.appointmentType == "Today") {
      e = this.currentDate;

    } else if (this.appointmentType == "Weekly") {
      var weekly, d = new Date();
      d.setDate(d.getDate() + 6);
      e = this.getDateFormate(d);

    } else if (this.appointmentType == "Fort Night") {
      var weekly, d = new Date();
      d.setDate(d.getDate() + 13);
      e = this.getDateFormate(d);

    } else if (this.appointmentType == "Monthly") {
      var weekly, d = new Date();
      d.setDate(d.getDate() + 29)
      e = this.getDateFormate(d);

    } else if (this.appointmentType == "3 Months") {
      var d = new Date();
      d.setDate(d.getDate() + 92)
      e = this.getDateFormate(d);

    } else if (this.appointmentType == "6 Months") {
      var weekly, d = new Date();
      d.setDate(d.getDate() + 183)
      e = this.getDateFormate(d);

    } else if (this.appointmentType == "Yearly") {
      var weekly, d = new Date();
      d.setDate(d.getDate() + 365)
      e = this.getDateFormate(d);

    } else if (this.appointmentType == "Date"){
      this.dateFlag = true;
      this.sendFromDate = Date_Formate(this.currentDate);
    }
    this.sendToDate = e;
    this.getRange(this.sendFromDate,this.sendToDate, "days");
    console.log("this.sendFromDate" + this.sendFromDate + "this.sendToDate" + this.sendToDate)
  }

  selectToDate(e) {
    this.toDate = e;
    this.sendToDate = Date_Formate(e);
  }

  selectFromDate(e) {
    this.fromDate = e;
    this.sendFromDate = Date_Formate(e);
  }

  getDateFormate(d) {
    var month = ((d.getMonth() + 1) < 10 ? '0' : '') + (d.getMonth() + 1);
    var day = (d.getDate() < 10 ? '0' : '') + d.getDate();
    var temp = d.getFullYear() + "-" + month + "-" + day;
    return temp
  }

  async getRelationlist() {
    var obj1 = Client_Helper.getrelationlistDiet();
    this.relationlistData = [];
    if (obj1.relations != null && obj1.relations.length != 0) {
      this.dayChangeFlag = true;
      if (obj1.relations != null) {
        if (this.userinfo.middle_name != undefined && encrypt_decript.Decript(this.userinfo.middle_name)) {
          this.relationName = encrypt_decript.Decript(this.userinfo.first_name) + " " + encrypt_decript.Decript(this.userinfo.middle_name) + " " + encrypt_decript.Decript(this.userinfo.last_name);
        } else {
          this.relationName = encrypt_decript.Decript(this.userinfo.first_name) + " " + encrypt_decript.Decript(this.userinfo.last_name);
        }
        if (this.userinfo.profile_image != null && this.userinfo.profile_image != undefined && this.userinfo.profile_image != '') {
          imgpath = this.userinfo.profile_image;
          img = ipaddress.Ip_with_img_address + this.userinfo.profile_image;
          this.relationId = "1";
          this.subRelId = "";
          this.lifeStyleId = this.userinfo.life_style_id;
        }
        this.relationlistData.push({
          rel_name: this.relationName,
          life_style_id: this.userinfo.life_style_id,
          rel_id: this.relationId,
          sub_rel_id: this.subRelId,
          prof_img: img,
          imgpath: imgpath,
        });
      }
      for (var f = 0; f < obj1.relations.length; f++) {
        var obj = obj1.relations[f];
        this.subRelId = null;
        if (obj.sub_rel_id != null) {
          this.subRelId = obj.sub_rel_id
        }
        this.relationFName = "";
        this.relationMName = "";
        this.relationLName = "";
        if (obj.middle_name != null) {
          this.relationFName = encrypt_decript.Decript(obj.first_name.toString());
          this.relationMName = encrypt_decript.Decript(obj.middle_name.toString());
          this.relationLName = encrypt_decript.Decript(obj.last_name.toString());
          this.relationName = encrypt_decript.Decript(obj.first_name.toString()) + " " + encrypt_decript.Decript(obj.middle_name.toString()) + " " + encrypt_decript.Decript(obj.last_name.toString());
        } else {
          this.relationFName = encrypt_decript.Decript(obj.first_name.toString());
          this.relationLName = encrypt_decript.Decript(obj.last_name.toString());
          this.relationName = encrypt_decript.Decript(obj.first_name.toString()) + " " + encrypt_decript.Decript(obj.last_name.toString());
        }
        var img = "../../../assets/imgs/default.jpg", imgpath = "../../../assets/imgs/default.jpg";
        if (obj.profile_image !== "default") {
          imgpath = obj.profile_image;
          img = ipaddress.Ip_with_img_address + obj.profile_image;
        }
        this.relationlistData.push({
          rel_name: this.relationName,
          rel_f_name: this.relationFName,
          rel_m_name: this.relationMName,
          rel_l_name: this.relationLName,
          rel_id: obj.relation_id,
          sub_rel_id: this.subRelId,
          gender_val: encrypt_decript.Decript(obj.gender),
          prof_img: img,
          imgpath: imgpath,
          loc_id: obj.location,
          loc_name: obj.location_desc,
          city_id: obj.city,
          city_desc: obj.city_desc,
          state_id: obj.state,
          state_desc: obj.state_desc,
          country_id: obj.country,
          country_desc: obj.country_desc,
          update_flag: true,
          life_style_id: obj.life_style_id,
        });
      }
    }
    if (this.relationlistData.length == 0) {
      this.selectRelation("", "1", undefined, this.userinfo.life_style_id);
    } else {
      this.selectRelation(this.relationlistData[0].rel_name, this.relationlistData[0].rel_id, this.relationlistData[0].sub_rel_id, this.relationlistData[0].life_style_id);
    }
  }

  selectRelation(name, rel_id, sub_rel_id, life_style_id) {
    Client_Helper.setrelationsubrelationslide(null);
    var relId = rel_id != undefined ? rel_id : "1";
    var lifeStyleId = life_style_id != undefined ? life_style_id : this.userinfo.life_style_id;
    var getdata = {
      relation_id: relId,
      sub_rel_id: sub_rel_id,
      life_style_id: lifeStyleId
    }
    Client_Helper.setrelationsubrelationslide(getdata);
  
    var sendDate = {
      Client_id: this.gservice.Client_login_detail_data.Client_Id,
      rel_id: relId,
      sub_id: sub_rel_id,
      flow : this.dietFlow
    }
    Client_Helper.setClientDietHistory(null);
    Client_Helper.setClientDietHistory(sendDate);

    this.lifeStyleId = lifeStyleId;
    this.getDisplayArray();
  }

  getRange(startDate, endDate, type) {
    this.week = 1;
    console.log("this.startDate" + startDate + "endDate" + endDate)
    var getDates = [];
    let fromDate = moment((startDate));
    let toDate = moment((endDate));
    let diff = toDate.diff(fromDate, type)
    let range = [];
    var docChecked = this.dietFlow == "dietTracker" ? true : false;
    var clientCheked = this.dietFlow == "dietPlan" ? true : false;  
    for (let i = 0; i <= diff; i++) {
      var index = this.displayArray.findIndex(x => x.date == moment(startDate).add(i, type).format('YYYY-MM-DD'));
      if( index == -1 || this.displayArray.length == 0){
        this.displayArray.push({
          day: moment(startDate).add(i, type).format('dddd'),
          dayLable : moment(startDate).add(i, type).format('dddd'),
          date : moment(startDate).add(i, type).format('YYYY-MM-DD'),
          isDisable: false,
          session: [{
            type: "Morning",
            docChecked: docChecked,
            patintChecked: clientCheked,
            empty: true,
            lableName: "Morning",
            foodItems: [],
            plateContent: "",
            mainDish: false,
            energy: "0.00",
            Protien: "0.00",
            Ca: "0.00",
            Fe: "0.00",
            fiber: "0.00",
            vaitamina: "0.00",
            Fat: "0.00",
            h2o: "0.00",
            Folic: "0.00",
            Carbohydrates: "0.00",
            Vitaminc: "0.00",
            vitaminb12: "0.00",
          },
          {
            type: "Breakfast",
            lableName: "Breakfast",
            docChecked: docChecked,
            patintChecked: clientCheked,
            empty: true,
            foodItems: [],
            mainDish: false,
            plateContent: "",
            energy: "0.00",
            Protien: "0.00",
            Ca: "0.00",
            Fe: "0.00",
            fiber: "0.00",
            vaitamina: "0.00",
            Fat: "0.00",
            h2o: "0.00",
            Folic: "0.00",
            Carbohydrates: "0.00",
            Vitaminc: "0.00",
            vitaminb12: "0.00",
          },
          {
            type: "Mid-day",
            lableName: "Mid-day Snack",
            docChecked: docChecked,
            patintChecked: clientCheked,
            empty: true,
            foodItems: [],
            mainDish: false,
            plateContent: "",
            energy: "0.00",
            Protien: "0.00",
            Ca: "0.00",
            Fe: "0.00",
            fiber: "0.00",
            vaitamina: "0.00",
            Fat: "0.00",
            h2o: "0.00",
            Folic: "0.00",
            Carbohydrates: "0.00",
            Vitaminc: "0.00",
            vitaminb12: "0.00",
          },
          {
            type: "Lunch",
            lableName: "Lunch",
            docChecked: docChecked,
            patintChecked: clientCheked,
            empty: true,
            foodItems: [],
            mainDish: false,
            plateContent: "",
            energy: "0.00",
            Protien: "0.00",
            Ca: "0.00",
            Fe: "0.00",
            fiber: "0.00",
            vaitamina: "0.00",
            Fat: "0.00",
            h2o: "0.00",
            Folic: "0.00",
            Carbohydrates: "0.00",
            Vitaminc: "0.00",
            vitaminb12: "0.00",
          },
          {
            type: "Evening",
            lableName: "Evening Snack",
            docChecked: docChecked,
            patintChecked: clientCheked,
            empty: true,
            foodItems: [],
            mainDish: false,
            plateContent: "",
            energy: "0.00",
            Protien: "0.00",
            Ca: "0.00",
            Fe: "0.00",
            fiber: "0.00",
            vaitamina: "0.00",
            Fat: "0.00",
            h2o: "0.00",
            Folic: "0.00",
            Carbohydrates: "0.00",
            Vitaminc: "0.00",
            vitaminb12: "0.00",
          },
          {
            type: "Dinner",
            lableName: "Dinner",
            docChecked: docChecked,
            patintChecked: clientCheked,
            empty: true,
            foodItems: [],
            mainDish: false,
            plateContent: "",
            energy: "0.00",
            Protien: "0.00",
            Ca: "0.00",
            Fe: "0.00",
            fiber: "0.00",
            vaitamina: "0.00",
            Fat: "0.00",
            h2o: "0.00",
            Folic: "0.00",
            Carbohydrates: "0.00",
            Vitaminc: "0.00",
            vitaminb12: "0.00",
          },
          {
            type: "Night",
            lableName: "Night",
            docChecked: docChecked,
            patintChecked: clientCheked,
            empty: true,
            foodItems: [],
            mainDish: false,
            plateContent: "",
            energy: "0.00",
            Protien: "0.00",
            Ca: "0.00",
            Fe: "0.00",
            fiber: "0.00",
            vaitamina: "0.00",
            Fat: "0.00",
            h2o: "0.00",
            Folic: "0.00",
            Carbohydrates: "0.00",
            Vitaminc: "0.00",
            vitaminb12: "0.00",
          },
          ]
        },
        )
      }
    }
   
    var temp = []; 
    var week = 1;
    this.displayArray.forEach((element,index) => {
      console.log("index" + JSON.stringify(index));
      if( (index != 0) && ((index % 7) == 0) ){
        week++
      }
      element.weekNo = week;
    });
    this.setNav();
    return range;
  }

  setNav(){
    var lastWeek = this.displayArray.findLast(x => true);
    if( lastWeek == 1  ){
      this.previousFlag = false;
      this.nextFlag = false;

    }else{
      this.previousFlag = (this.week != 1 && lastWeek.weekNo != 1) ? true :false ;
      this.nextFlag = (lastWeek.weekNo != 1 && this.week != lastWeek.weekNo ) ? true : false ;
    }
  }

  copyDietPlan(action){
    this.week = action == "next" ? this.week+1 : this.week-1;
    this.setNav();
  }

  getCasesheetData() {
    var sen_pass;
    var cal_txt = cal_txt;
    this.lifeStyleId = this.lifeStyleId != undefined ? this.lifeStyleId : this.userinfo.life_style_id;
    var senddata = JSON.stringify({
      life_style_id: this.lifeStyleId,
    })
    this.dietMrngArry = [];
    this.breakFastArryData = [];
    this.midDayArryData = [];
    this.lunchArryData = [];
    this.eveningArryData = [];
    this.dinnerArryData = [];
    this.nightArryData = [];
    this.totalCalories = "0.00";
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'lifestyle/gdpn',
      senddata,
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          console.log("check array" + JSON.stringify(obj));
          this.tempDisplyArray = [];
          this.displayArray = [];
          var family = Helper_Class.getRet();
          if( obj != null ){
            this.sgaOverallScore = obj.sga_overall_rating != undefined ? obj.sga_overall_rating : "";
            this.dietPlanAll = "0";
            var tempDate = this.getRange(obj.plan_start_date,obj.plan_end_date, "days");
            console.log("theis.dataag lksdbjk" + JSON.stringify(this.displayArray));
            this.selectedDate = obj.plan_start_date;
            this.calories = obj.cal_required != undefined ? obj.cal_required : "0";
            this.pdfFlag = obj.doc_url != undefined && this.dietFlow != "dietTracker"  ? true : false;
            this.pdfURL = obj.doc_url != undefined ? this.sanitizer.bypassSecurityTrustResourceUrl(ipaddress.Ip_with_img_address+"/"+ obj.doc_url+"#toolbar=0") : "";
            
            var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
            if (obj.doctor_morning != null && obj.doctor_morning.length != 0) {
              if (obj.doctor_morning != null) {
                var tempFoodItems = {};
                for (var i = 0; i < obj.doctor_morning.length; i++) {
                  var dayMorning = obj.doctor_morning[i].day != undefined ? obj.doctor_morning[i].day : " ";
                  var tempWeight = obj.doctor_morning[i].weight.split(" ");
                  var calsWight = tempWeight[0];
                  var wightMesure = tempWeight[1];
                  tempFoodItems = {
                    food_item_id: obj.doctor_morning[i].food_item_id,
                    description: obj.doctor_morning[i].description,
                    food_type_id: obj.doctor_morning[i].food_type_id,
                    food_sub_type: obj.doctor_morning[i].food_sub_type,
                    quantity: obj.doctor_morning[i].quantity != undefined ? obj.doctor_morning[i].quantity : "0.00",
                    measure: obj.doctor_morning[i].measure != undefined ? obj.doctor_morning[i].measure : "0.00",
                    weight: obj.doctor_morning[i].weight != undefined ? obj.doctor_morning[i].weight : "0.00",
                    cals_weight: calsWight,
                    wightMesure: wightMesure,
                    energy: obj.doctor_morning[i].energy != undefined ? obj.doctor_morning[i].energy : "0.00",
                    proteins: obj.doctor_morning[i].proteins != undefined ? obj.doctor_morning[i].proteins : "0.00",
                    carbohydrates: obj.doctor_morning[i].carbohydrates != undefined ? obj.doctor_morning[i].carbohydrates : "0.00",
                    fat: obj.doctor_morning[i].fat != undefined ? obj.doctor_morning[i].fat : "0.00",
                    calcium: obj.doctor_morning[i].calcium != undefined ? obj.doctor_morning[i].calcium : "0.00",
                    iron: obj.doctor_morning[i].iron != undefined ? obj.doctor_morning[i].iron : "0.00",
                    vitaminC: obj.doctor_morning[i].vitaminC != undefined ? obj.doctor_morning[i].vitaminC : "0.00",
                    vitaminA: obj.doctor_morning[i].vitaminA != undefined ? obj.doctor_morning[i].vitaminA : "0.00",
                    vitaminB12: obj.doctor_morning[i].vitaminB12 != undefined ? obj.doctor_morning[i].vitaminB12 : "0.00",
                    fiber: obj.doctor_morning[i].fiber != undefined ? obj.doctor_morning[i].fiber : "0.00",
                    folic: obj.doctor_morning[i].folic != undefined ? obj.doctor_morning[i].folic : "0.00",
                    h2o: obj.doctor_morning[i].h2o != undefined ? obj.doctor_morning[i].h2o : "0.00",
                    session: "Morning",
                    type: "Doctor",
                    image: obj.doctor_morning[i].image,
                    date : obj.doctor_morning[i].date != undefined ? obj.doctor_morning[i].date : undefined
                  };
                  this.mainDishFlag = obj.doctor_morning[i].main_course != "1" ? false : true;
                  this.selctedFood(obj.doctor_morning[i].day, obj.doctor_morning[i].date,tempFoodItems, 0);
                }
              }
            }
            if (obj.doctor_breakfast != null && obj.doctor_breakfast.length != 0) {
              for (var i = 0; i < obj.doctor_breakfast.length; i++) {
                var dayMorning = obj.doctor_breakfast[i].day != undefined ? obj.doctor_breakfast[i].day : " ";
                var tempWeight = obj.doctor_breakfast[i].weight.split(" ");
                var calsWight = tempWeight[0];
                var wightMesure = tempWeight[1];
                tempFoodItems = {
                  food_item_id: obj.doctor_breakfast[i].food_item_id,
                  description: obj.doctor_breakfast[i].description,
                  food_type_id: obj.doctor_breakfast[i].food_type_id,
                  food_sub_type: obj.doctor_breakfast[i].food_sub_type,
                  quantity: obj.doctor_breakfast[i].quantity != undefined ? obj.doctor_breakfast[i].quantity : "0.00",
                  measure: obj.doctor_breakfast[i].measure != undefined ? obj.doctor_breakfast[i].measure : "0.00",
                  weight: obj.doctor_breakfast[i].weight != undefined ? obj.doctor_breakfast[i].weight : "0.00",
                  cals_weight: calsWight,
                  wightMesure: wightMesure,
                  energy: obj.doctor_breakfast[i].energy != undefined ? obj.doctor_breakfast[i].energy : "0.00",
                  proteins: obj.doctor_breakfast[i].proteins != undefined ? obj.doctor_breakfast[i].proteins : "0.00",
                  carbohydrates: obj.doctor_breakfast[i].carbohydrates != undefined ? obj.doctor_breakfast[i].carbohydrates : "0.00",
                  fat: obj.doctor_breakfast[i].fat != undefined ? obj.doctor_breakfast[i].fat : "0.00",
                  calcium: obj.doctor_breakfast[i].calcium != undefined ? obj.doctor_breakfast[i].calcium : "0.00",
                  iron: obj.doctor_breakfast[i].iron != undefined ? obj.doctor_breakfast[i].iron : "0.00",
                  vitaminC: obj.doctor_breakfast[i].vitaminC != undefined ? obj.doctor_breakfast[i].vitaminC : "0.00",
                  vitaminA: obj.doctor_breakfast[i].vitaminA != undefined ? obj.doctor_breakfast[i].vitaminA : "0.00",
                  vitaminB12: obj.doctor_breakfast[i].vitaminB12 != undefined ? obj.doctor_breakfast[i].vitaminB12 : "0.00",
                  fiber: obj.doctor_breakfast[i].fiber != undefined ? obj.doctor_breakfast[i].fiber : "0.00",
                  folic: obj.doctor_breakfast[i].folic != undefined ? obj.doctor_breakfast[i].folic : "0.00",
                  h2o: obj.doctor_breakfast[i].h2o != undefined ? obj.doctor_breakfast[i].h2o : "0.00",
                  session: "Breakfast",
                  type: "Doctor",
                  image: obj.doctor_breakfast[i].image,
                  date : obj.doctor_breakfast[i].date != undefined ? obj.doctor_breakfast[i].date : undefined
                };
                this.mainDishFlag = obj.doctor_breakfast[i].main_course != "1" ? false : true;
                this.selctedFood(obj.doctor_breakfast[i].day,obj.doctor_breakfast[i].date, tempFoodItems, 0);
              }
            }
            if (obj.doctor_midday != null && obj.doctor_midday.length != 0) {
              for (var i = 0; i < obj.doctor_midday.length; i++) {
                var dayMorning = obj.doctor_midday[i].day != undefined ? obj.doctor_midday[i].day : " ";
                var tempWeight = obj.doctor_midday[i].weight.split(" ");
                var calsWight = tempWeight[0];
                var wightMesure = tempWeight[1];
                tempFoodItems = {
                  food_item_id: obj.doctor_midday[i].food_item_id,
                  description: obj.doctor_midday[i].description,
                  food_type_id: obj.doctor_midday[i].food_type_id,
                  food_sub_type: obj.doctor_midday[i].food_sub_type,
                  quantity: obj.doctor_midday[i].quantity != undefined ? obj.doctor_midday[i].quantity : "0.00",
                  measure: obj.doctor_midday[i].measure != undefined ? obj.doctor_midday[i].measure : "0.00",
                  weight: obj.doctor_midday[i].weight != undefined ? obj.doctor_midday[i].weight : "0.00",
                  cals_weight: calsWight,
                  wightMesure: wightMesure,
                  energy: obj.doctor_midday[i].energy != undefined ? obj.doctor_midday[i].energy : "0.00",
                  proteins: obj.doctor_midday[i].proteins != undefined ? obj.doctor_midday[i].proteins : "0.00",
                  carbohydrates: obj.doctor_midday[i].carbohydrates != undefined ? obj.doctor_midday[i].carbohydrates : "0.00",
                  fat: obj.doctor_midday[i].fat != undefined ? obj.doctor_midday[i].fat : "0.00",
                  calcium: obj.doctor_midday[i].calcium != undefined ? obj.doctor_midday[i].calcium : "0.00",
                  iron: obj.doctor_midday[i].iron != undefined ? obj.doctor_midday[i].iron : "0.00",
                  vitaminC: obj.doctor_midday[i].vitaminC != undefined ? obj.doctor_midday[i].vitaminC : "0.00",
                  vitaminA: obj.doctor_midday[i].vitaminA != undefined ? obj.doctor_midday[i].vitaminA : "0.00",
                  vitaminB12: obj.doctor_midday[i].vitaminB12 != undefined ? obj.doctor_midday[i].vitaminB12 : "0.00",
                  fiber: obj.doctor_midday[i].fiber != undefined ? obj.doctor_midday[i].fiber : "0.00",
                  folic: obj.doctor_midday[i].folic != undefined ? obj.doctor_midday[i].folic : "0.00",
                  h2o: obj.doctor_midday[i].h2o != undefined ? obj.doctor_midday[i].h2o : "0.00",
                  session: "Mid-day",
                  type: "Doctor",
                  image: obj.doctor_midday[i].image,
                  date : obj.doctor_midday[i].date != undefined ? obj.doctor_midday[i].date : undefined
                };
                this.mainDishFlag = obj.doctor_midday[i].main_course != "1" ? false : true;
                this.selctedFood(obj.doctor_midday[i].day,obj.doctor_midday[i].date, tempFoodItems, 0);
              }
            }
            if (obj.doctor_lunch != null && obj.doctor_lunch.length != 0) {
              for (var i = 0; i < obj.doctor_lunch.length; i++) {
                var dayMorning = obj.doctor_lunch[i].day != undefined ? obj.doctor_lunch[i].day : " ";
                var tempWeight = obj.doctor_lunch[i].weight.split(" ");
                var calsWight = tempWeight[0];
                var wightMesure = tempWeight[1];
                tempFoodItems = {
                  food_item_id: obj.doctor_lunch[i].food_item_id,
                  description: obj.doctor_lunch[i].description,
                  food_type_id: obj.doctor_lunch[i].food_type_id,
                  food_sub_type: obj.doctor_lunch[i].food_sub_type,
                  quantity: obj.doctor_lunch[i].quantity != undefined ? obj.doctor_lunch[i].quantity : "0.00",
                  measure: obj.doctor_lunch[i].measure != undefined ? obj.doctor_lunch[i].measure : "0.00",
                  weight: obj.doctor_lunch[i].weight != undefined ? obj.doctor_lunch[i].weight : "0.00",
                  cals_weight: calsWight,
                  wightMesure: wightMesure,
                  energy: obj.doctor_lunch[i].energy != undefined ? obj.doctor_lunch[i].energy : "0.00",
                  proteins: obj.doctor_lunch[i].proteins != undefined ? obj.doctor_lunch[i].proteins : "0.00",
                  carbohydrates: obj.doctor_lunch[i].carbohydrates != undefined ? obj.doctor_lunch[i].carbohydrates : "0.00",
                  fat: obj.doctor_lunch[i].fat != undefined ? obj.doctor_lunch[i].fat : "0.00",
                  calcium: obj.doctor_lunch[i].calcium != undefined ? obj.doctor_lunch[i].calcium : "0.00",
                  iron: obj.doctor_lunch[i].iron != undefined ? obj.doctor_lunch[i].iron : "0.00",
                  vitaminC: obj.doctor_lunch[i].vitaminC != undefined ? obj.doctor_lunch[i].vitaminC : "0.00",
                  vitaminA: obj.doctor_lunch[i].vitaminA != undefined ? obj.doctor_lunch[i].vitaminA : "0.00",
                  vitaminB12: obj.doctor_lunch[i].vitaminB12 != undefined ? obj.doctor_lunch[i].vitaminB12 : "0.00",
                  fiber: obj.doctor_lunch[i].fiber != undefined ? obj.doctor_lunch[i].fiber : "0.00",
                  folic: obj.doctor_lunch[i].folic != undefined ? obj.doctor_lunch[i].folic : "0.00",
                  h2o: obj.doctor_lunch[i].h2o != undefined ? obj.doctor_lunch[i].h2o : "0.00",
                  session: "Lunch",
                  type: "Doctor",
                  image: obj.doctor_lunch[i].image,
                  date : obj.doctor_lunch[i].date != undefined ? obj.doctor_lunch[i].date : undefined
                };
                this.mainDishFlag = obj.doctor_lunch[i].main_course != "1" ? false : true;
                this.selctedFood(obj.doctor_lunch[i].day,obj.doctor_lunch[i].date, tempFoodItems, 0);
              }
            }
            if (obj.doctor_evening != null && obj.doctor_evening.length != 0) {
              for (var i = 0; i < obj.doctor_evening.length; i++) {
                var dayMorning = obj.doctor_evening[i].day != undefined ? obj.doctor_evening[i].day : " ";
                var tempWeight = obj.doctor_evening[i].weight.split(" ");
                var calsWight = tempWeight[0];
                var wightMesure = tempWeight[1];
                tempFoodItems = {
                  food_item_id: obj.doctor_evening[i].food_item_id,
                  description: obj.doctor_evening[i].description,
                  food_type_id: obj.doctor_evening[i].food_type_id,
                  food_sub_type: obj.doctor_evening[i].food_sub_type,
                  quantity: obj.doctor_evening[i].quantity != undefined ? obj.doctor_evening[i].quantity : "0.00",
                  measure: obj.doctor_evening[i].measure != undefined ? obj.doctor_evening[i].measure : "0.00",
                  weight: obj.doctor_evening[i].weight != undefined ? obj.doctor_evening[i].weight : "0.00",
                  cals_weight: calsWight,
                  wightMesure: wightMesure,
                  energy: obj.doctor_evening[i].energy != undefined ? obj.doctor_evening[i].energy : "0.00",
                  proteins: obj.doctor_evening[i].proteins != undefined ? obj.doctor_evening[i].proteins : "0.00",
                  carbohydrates: obj.doctor_evening[i].carbohydrates != undefined ? obj.doctor_evening[i].carbohydrates : "0.00",
                  fat: obj.doctor_evening[i].fat != undefined ? obj.doctor_evening[i].fat : "0.00",
                  calcium: obj.doctor_evening[i].calcium != undefined ? obj.doctor_evening[i].calcium : "0.00",
                  iron: obj.doctor_evening[i].iron != undefined ? obj.doctor_evening[i].iron : "0.00",
                  vitaminC: obj.doctor_evening[i].vitaminC != undefined ? obj.doctor_evening[i].vitaminC : "0.00",
                  vitaminA: obj.doctor_evening[i].vitaminA != undefined ? obj.doctor_evening[i].vitaminA : "0.00",
                  vitaminB12: obj.doctor_evening[i].vitaminB12 != undefined ? obj.doctor_evening[i].vitaminB12 : "0.00",
                  fiber: obj.doctor_evening[i].fiber != undefined ? obj.doctor_evening[i].fiber : "0.00",
                  folic: obj.doctor_evening[i].folic != undefined ? obj.doctor_evening[i].folic : "0.00",
                  h2o: obj.doctor_evening[i].h2o != undefined ? obj.doctor_evening[i].h2o : "0.00",
                  session: "Evening",
                  type: "Doctor",
                  image: obj.doctor_evening[i].image,
                  date : obj.doctor_evening[i].date != undefined ? obj.doctor_evening[i].date : undefined
                };
                this.mainDishFlag = obj.doctor_evening[i].main_course != "1" ? false : true;
                this.selctedFood(obj.doctor_evening[i].day,obj.doctor_evening[i].date, tempFoodItems, 0);
              }
            }
            if (obj.doctor_dinner != null && obj.doctor_dinner.length != 0) {
              for (var i = 0; i < obj.doctor_dinner.length; i++) {
                var dayMorning = obj.doctor_dinner[i].day != undefined ? obj.doctor_dinner[i].day : " ";
                var tempWeight = obj.doctor_dinner[i].weight.split(" ");
                var calsWight = tempWeight[0];
                var wightMesure = tempWeight[1];
                tempFoodItems = {
                  food_item_id: obj.doctor_dinner[i].food_item_id,
                  description: obj.doctor_dinner[i].description,
                  food_type_id: obj.doctor_dinner[i].food_type_id,
                  food_sub_type: obj.doctor_dinner[i].food_sub_type,
                  quantity: obj.doctor_dinner[i].quantity != undefined ? obj.doctor_dinner[i].quantity : "0.00",
                  measure: obj.doctor_dinner[i].measure != undefined ? obj.doctor_dinner[i].measure : "0.00",
                  weight: obj.doctor_dinner[i].weight != undefined ? obj.doctor_dinner[i].weight : "0.00",
                  cals_weight: calsWight,
                  wightMesure: wightMesure,
                  energy: obj.doctor_dinner[i].energy != undefined ? obj.doctor_dinner[i].energy : "0.00",
                  proteins: obj.doctor_dinner[i].proteins != undefined ? obj.doctor_dinner[i].proteins : "0.00",
                  carbohydrates: obj.doctor_dinner[i].carbohydrates != undefined ? obj.doctor_dinner[i].carbohydrates : "0.00",
                  fat: obj.doctor_dinner[i].fat != undefined ? obj.doctor_dinner[i].fat : "0.00",
                  calcium: obj.doctor_dinner[i].calcium != undefined ? obj.doctor_dinner[i].calcium : "0.00",
                  iron: obj.doctor_dinner[i].iron != undefined ? obj.doctor_dinner[i].iron : "0.00",
                  vitaminC: obj.doctor_dinner[i].vitaminC != undefined ? obj.doctor_dinner[i].vitaminC : "0.00",
                  vitaminA: obj.doctor_dinner[i].vitaminA != undefined ? obj.doctor_dinner[i].vitaminA : "0.00",
                  vitaminB12: obj.doctor_dinner[i].vitaminB12 != undefined ? obj.doctor_dinner[i].vitaminB12 : "0.00",
                  fiber: obj.doctor_dinner[i].fiber != undefined ? obj.doctor_dinner[i].fiber : "0.00",
                  folic: obj.doctor_dinner[i].folic != undefined ? obj.doctor_dinner[i].folic : "0.00",
                  h2o: obj.doctor_dinner[i].h2o != undefined ? obj.doctor_dinner[i].h2o : "0.00",
                  session: "Dinner",
                  type: "Doctor",
                  image: obj.doctor_dinner[i].image,
                  date : obj.doctor_dinner[i].date != undefined ? obj.doctor_dinner[i].date : undefined
                };
                this.mainDishFlag = obj.doctor_dinner[i].main_course != "1" ? false : true;
                this.selctedFood(obj.doctor_dinner[i].day,obj.doctor_dinner[i].date, tempFoodItems, 0);
              }
            }
            if (obj.doctor_night != null && obj.doctor_night.length != 0) {
              for (var i = 0; i < obj.doctor_night.length; i++) {
                var dayMorning = obj.doctor_night[i].day != undefined ? obj.doctor_night[i].day : " ";
                var tempWeight = obj.doctor_night[i].weight.split(" ");
                var calsWight = tempWeight[0];
                var wightMesure = tempWeight[1];
                tempFoodItems = {
                  food_item_id: obj.doctor_night[i].food_item_id,
                  description: obj.doctor_night[i].description,
                  food_type_id: obj.doctor_night[i].food_type_id,
                  food_sub_type: obj.doctor_night[i].food_sub_type,
                  quantity: obj.doctor_night[i].quantity != undefined ? obj.doctor_night[i].quantity : "0.00",
                  measure: obj.doctor_night[i].measure != undefined ? obj.doctor_night[i].measure : "0.00",
                  weight: obj.doctor_night[i].weight != undefined ? obj.doctor_night[i].weight : "0.00",
                  cals_weight: calsWight,
                  wightMesure: wightMesure,
                  energy: obj.doctor_night[i].energy != undefined ? obj.doctor_night[i].energy : "0.00",
                  proteins: obj.doctor_night[i].proteins != undefined ? obj.doctor_night[i].proteins : "0.00",
                  carbohydrates: obj.doctor_night[i].carbohydrates != undefined ? obj.doctor_night[i].carbohydrates : "0.00",
                  fat: obj.doctor_night[i].fat != undefined ? obj.doctor_night[i].fat : "0.00",
                  calcium: obj.doctor_night[i].calcium != undefined ? obj.doctor_night[i].calcium : "0.00",
                  iron: obj.doctor_night[i].iron != undefined ? obj.doctor_night[i].iron : "0.00",
                  vitaminC: obj.doctor_night[i].vitaminC != undefined ? obj.doctor_night[i].vitaminC : "0.00",
                  vitaminA: obj.doctor_night[i].vitaminA != undefined ? obj.doctor_night[i].vitaminA : "0.00",
                  vitaminB12: obj.doctor_night[i].vitaminB12 != undefined ? obj.doctor_night[i].vitaminB12 : "0.00",
                  fiber: obj.doctor_night[i].fiber != undefined ? obj.doctor_night[i].fiber : "0.00",
                  folic: obj.doctor_night[i].folic != undefined ? obj.doctor_night[i].folic : "0.00",
                  h2o: obj.doctor_night[i].h2o != undefined ? obj.doctor_night[i].h2o : "0.00",
                  session: "Night",
                  type: "Doctor",
                  image: obj.doctor_night[i].image,
                  date : obj.doctor_night[i].date != undefined ? obj.doctor_night[i].date : undefined
                };
                this.mainDishFlag = obj.doctor_night[i].main_course != "1" ? false : true;
                this.selctedFood(obj.doctor_night[i].day,obj.doctor_night[i].date, tempFoodItems, 0);
              }
            }
            if ((obj.self_morning != undefined) && obj.self_morning != null && obj.self_morning.length != 0) {
              this.dietPlanAll = "1";
              if (obj.self_morning != null) {
                var tempFoodItems = {};
                for (var i = 0; i < obj.self_morning.length; i++) {
                  var dayMorning = obj.self_morning[i].day != undefined ? obj.self_morning[i].day : " ";
                  var tempWeight = obj.self_morning[i].weight.split(" ");
                  var calsWight = tempWeight[0];
                  var wightMesure = tempWeight[1];
                  tempFoodItems = {
                    food_item_id: obj.self_morning[i].food_item_id,
                    description: obj.self_morning[i].description,
                    food_type_id: obj.self_morning[i].food_type_id,
                    food_sub_type: obj.self_morning[i].food_sub_type,
                    quantity: obj.self_morning[i].quantity != undefined ? obj.self_morning[i].quantity : "0.00",
                    measure: obj.self_morning[i].measure != undefined ? obj.self_morning[i].measure : "0.00",
                    weight: obj.self_morning[i].weight != undefined ? obj.self_morning[i].weight : "0.00",
                    cals_weight: calsWight,
                    wightMesure: wightMesure,
                    energy: obj.self_morning[i].energy != undefined ? obj.self_morning[i].energy : "0.00",
                    proteins: obj.self_morning[i].proteins != undefined ? obj.self_morning[i].proteins : "0.00",
                    carbohydrates: obj.self_morning[i].carbohydrates != undefined ? obj.self_morning[i].carbohydrates : "0.00",
                    fat: obj.self_morning[i].fat != undefined ? obj.self_morning[i].fat : "0.00",
                    calcium: obj.self_morning[i].calcium != undefined ? obj.self_morning[i].calcium : "0.00",
                    iron: obj.self_morning[i].iron != undefined ? obj.self_morning[i].iron : "0.00",
                    vitaminC: obj.self_morning[i].vitaminC != undefined ? obj.self_morning[i].vitaminC : "0.00",
                    vitaminA: obj.self_morning[i].vitaminA != undefined ? obj.self_morning[i].vitaminA : "0.00",
                    vitaminB12: obj.self_morning[i].vitaminB12 != undefined ? obj.self_morning[i].vitaminB12 : "0.00",
                    fiber: obj.self_morning[i].fiber != undefined ? obj.self_morning[i].fiber : "0.00",
                    folic: obj.self_morning[i].folic != undefined ? obj.self_morning[i].folic : "0.00",
                    h2o: obj.self_morning[i].h2o != undefined ? obj.self_morning[i].h2o : "0.00",
                    session: "Morning",
                    type: "patient",
                    image: obj.self_morning[i].image,
                    date : obj.self_morning[i].date != undefined ? obj.self_morning[i].date : undefined
                  };
                  this.mainDishFlag = obj.self_morning[i].main_course != "1" ? false : true;
                  this.selctedFood(obj.self_morning[i].day,obj.self_morning[i].date, tempFoodItems, 0);
                }
              }
            }
            if ((obj.self_breakfast != undefined) && obj.self_breakfast != null && obj.self_breakfast.length != 0) {
              this.dietPlanAll = "1";
              for (var i = 0; i < obj.self_breakfast.length; i++) {
                var dayMorning = obj.self_breakfast[i].day != undefined ? obj.self_breakfast[i].day : " ";
                var tempWeight = obj.self_breakfast[i].weight.split(" ");
                var calsWight = tempWeight[0];
                var wightMesure = tempWeight[1];
                tempFoodItems = {
                  food_item_id: obj.self_breakfast[i].food_item_id,
                  description: obj.self_breakfast[i].description,
                  food_type_id: obj.self_breakfast[i].food_type_id,
                  food_sub_type: obj.self_breakfast[i].food_sub_type,
                  quantity: obj.self_breakfast[i].quantity != undefined ? obj.self_breakfast[i].quantity : "0.00",
                  measure: obj.self_breakfast[i].measure != undefined ? obj.self_breakfast[i].measure : "0.00",
                  weight: obj.self_breakfast[i].weight != undefined ? obj.self_breakfast[i].weight : "0.00",
                  cals_weight: calsWight,
                  wightMesure: wightMesure,
                  energy: obj.self_breakfast[i].energy != undefined ? obj.self_breakfast[i].energy : "0.00",
                  proteins: obj.self_breakfast[i].proteins != undefined ? obj.self_breakfast[i].proteins : "0.00",
                  carbohydrates: obj.self_breakfast[i].carbohydrates != undefined ? obj.self_breakfast[i].carbohydrates : "0.00",
                  fat: obj.self_breakfast[i].fat != undefined ? obj.self_breakfast[i].fat : "0.00",
                  calcium: obj.self_breakfast[i].calcium != undefined ? obj.self_breakfast[i].calcium : "0.00",
                  iron: obj.self_breakfast[i].iron != undefined ? obj.self_breakfast[i].iron : "0.00",
                  vitaminC: obj.self_breakfast[i].vitaminC != undefined ? obj.self_breakfast[i].vitaminC : "0.00",
                  vitaminA: obj.self_breakfast[i].vitaminA != undefined ? obj.self_breakfast[i].vitaminA : "0.00",
                  vitaminB12: obj.self_breakfast[i].vitaminB12 != undefined ? obj.self_breakfast[i].vitaminB12 : "0.00",
                  fiber: obj.self_breakfast[i].fiber != undefined ? obj.self_breakfast[i].fiber : "0.00",
                  folic: obj.self_breakfast[i].folic != undefined ? obj.self_breakfast[i].folic : "0.00",
                  h2o: obj.self_breakfast[i].h2o != undefined ? obj.self_breakfast[i].h2o : "0.00",
                  session: "Breakfast",
                  type: "patient",
                  image: obj.self_breakfast[i].image,
                  date : obj.self_breakfast[i].date != undefined ? obj.self_breakfast[i].date : undefined
                };
                this.mainDishFlag = obj.self_breakfast[i].main_course != "1" ? false : true;
                this.selctedFood(obj.self_breakfast[i].day,obj.self_breakfast[i].date, tempFoodItems, 0);
              }
            }
            if ((obj.self_midday != undefined) && obj.self_midday != null && obj.self_midday.length != 0) {
              this.dietPlanAll = "1";
              for (var i = 0; i < obj.self_midday.length; i++) {
                var dayMorning = obj.self_midday[i].day != undefined ? obj.self_midday[i].day : " ";
                var tempWeight = obj.self_midday[i].weight.split(" ");
                var calsWight = tempWeight[0];
                var wightMesure = tempWeight[1];
                tempFoodItems = {
                  food_item_id: obj.self_midday[i].food_item_id,
                  description: obj.self_midday[i].description,
                  food_type_id: obj.self_midday[i].food_type_id,
                  food_sub_type: obj.self_midday[i].food_sub_type,
                  quantity: obj.self_midday[i].quantity != undefined ? obj.self_midday[i].quantity : "0.00",
                  measure: obj.self_midday[i].measure != undefined ? obj.self_midday[i].measure : "0.00",
                  weight: obj.self_midday[i].weight != undefined ? obj.self_midday[i].weight : "0.00",
                  cals_weight: calsWight,
                  wightMesure: wightMesure,
                  energy: obj.self_midday[i].energy != undefined ? obj.self_midday[i].energy : "0.00",
                  proteins: obj.self_midday[i].proteins != undefined ? obj.self_midday[i].proteins : "0.00",
                  carbohydrates: obj.self_midday[i].carbohydrates != undefined ? obj.self_midday[i].carbohydrates : "0.00",
                  fat: obj.self_midday[i].fat != undefined ? obj.self_midday[i].fat : "0.00",
                  calcium: obj.self_midday[i].calcium != undefined ? obj.self_midday[i].calcium : "0.00",
                  iron: obj.self_midday[i].iron != undefined ? obj.self_midday[i].iron : "0.00",
                  vitaminC: obj.self_midday[i].vitaminC != undefined ? obj.self_midday[i].vitaminC : "0.00",
                  vitaminA: obj.self_midday[i].vitaminA != undefined ? obj.self_midday[i].vitaminA : "0.00",
                  vitaminB12: obj.self_midday[i].vitaminB12 != undefined ? obj.self_midday[i].vitaminB12 : "0.00",
                  fiber: obj.self_midday[i].fiber != undefined ? obj.self_midday[i].fiber : "0.00",
                  folic: obj.self_midday[i].folic != undefined ? obj.self_midday[i].folic : "0.00",
                  h2o: obj.self_midday[i].h2o != undefined ? obj.self_midday[i].h2o : "0.00",
                  session: "Mid-day",
                  type: "patient",
                  image: obj.self_midday[i].image,
                  date : obj.self_midday[i].date != undefined ? obj.self_midday[i].date : undefined
                };
                this.mainDishFlag = obj.self_midday[i].main_course != "1" ? false : true;
                this.selctedFood(obj.self_midday[i].day,obj.self_midday[i].date, tempFoodItems, 0);
              }
            }
            if ((obj.self_lunch != undefined) && obj.self_lunch != null && obj.self_lunch.length != 0) {
              this.dietPlanAll = "1";
              for (var i = 0; i < obj.self_lunch.length; i++) {
                var dayMorning = obj.self_lunch[i].day != undefined ? obj.self_lunch[i].day : " ";
                var tempWeight = obj.self_lunch[i].weight.split(" ");
                var calsWight = tempWeight[0];
                var wightMesure = tempWeight[1];
                tempFoodItems = {
                  food_item_id: obj.self_lunch[i].food_item_id,
                  description: obj.self_lunch[i].description,
                  food_type_id: obj.self_lunch[i].food_type_id,
                  food_sub_type: obj.self_lunch[i].food_sub_type,
                  quantity: obj.self_lunch[i].quantity != undefined ? obj.self_lunch[i].quantity : "0.00",
                  measure: obj.self_lunch[i].measure != undefined ? obj.self_lunch[i].measure : "0.00",
                  weight: obj.self_lunch[i].weight != undefined ? obj.self_lunch[i].weight : "0.00",
                  cals_weight: calsWight,
                  wightMesure: wightMesure,
                  energy: obj.self_lunch[i].energy != undefined ? obj.self_lunch[i].energy : "0.00",
                  proteins: obj.self_lunch[i].proteins != undefined ? obj.self_lunch[i].proteins : "0.00",
                  carbohydrates: obj.self_lunch[i].carbohydrates != undefined ? obj.self_lunch[i].carbohydrates : "0.00",
                  fat: obj.self_lunch[i].fat != undefined ? obj.self_lunch[i].fat : "0.00",
                  calcium: obj.self_lunch[i].calcium != undefined ? obj.self_lunch[i].calcium : "0.00",
                  iron: obj.self_lunch[i].iron != undefined ? obj.self_lunch[i].iron : "0.00",
                  vitaminC: obj.self_lunch[i].vitaminC != undefined ? obj.self_lunch[i].vitaminC : "0.00",
                  vitaminA: obj.self_lunch[i].vitaminA != undefined ? obj.self_lunch[i].vitaminA : "0.00",
                  vitaminB12: obj.self_lunch[i].vitaminB12 != undefined ? obj.self_lunch[i].vitaminB12 : "0.00",
                  fiber: obj.self_lunch[i].fiber != undefined ? obj.self_lunch[i].fiber : "0.00",
                  folic: obj.self_lunch[i].folic != undefined ? obj.self_lunch[i].folic : "0.00",
                  h2o: obj.self_lunch[i].h2o != undefined ? obj.self_lunch[i].h2o : "0.00",
                  session: "Lunch",
                  type: "patient",
                  image: obj.self_lunch[i].image,
                  date : obj.self_lunch[i].date != undefined ? obj.self_lunch[i].date : undefined
                };
                this.mainDishFlag = obj.self_lunch[i].main_course != "1" ? false : true;
                this.selctedFood(obj.self_lunch[i].day,obj.self_lunch[i].date, tempFoodItems, 0);
              }
            }
            if ((obj.self_evening != undefined) && obj.self_evening != null && obj.self_evening.length != 0) {
              this.dietPlanAll = "1";
              for (var i = 0; i < obj.self_evening.length; i++) {
                var dayMorning = obj.self_evening[i].day != undefined ? obj.self_evening[i].day : " ";
                var tempWeight = obj.self_evening[i].weight.split(" ");
                var calsWight = tempWeight[0];
                var wightMesure = tempWeight[1];
                tempFoodItems = {
                  food_item_id: obj.self_evening[i].food_item_id,
                  description: obj.self_evening[i].description,
                  food_type_id: obj.self_evening[i].food_type_id,
                  food_sub_type: obj.self_evening[i].food_sub_type,
                  quantity: obj.self_evening[i].quantity != undefined ? obj.self_evening[i].quantity : "0.00",
                  measure: obj.self_evening[i].measure != undefined ? obj.self_evening[i].measure : "0.00",
                  weight: obj.self_evening[i].weight != undefined ? obj.self_evening[i].weight : "0.00",
                  cals_weight: calsWight,
                  wightMesure: wightMesure,
                  energy: obj.self_evening[i].energy != undefined ? obj.self_evening[i].energy : "0.00",
                  proteins: obj.self_evening[i].proteins != undefined ? obj.self_evening[i].proteins : "0.00",
                  carbohydrates: obj.self_evening[i].carbohydrates != undefined ? obj.self_evening[i].carbohydrates : "0.00",
                  fat: obj.self_evening[i].fat != undefined ? obj.self_evening[i].fat : "0.00",
                  calcium: obj.self_evening[i].calcium != undefined ? obj.self_evening[i].calcium : "0.00",
                  iron: obj.self_evening[i].iron != undefined ? obj.self_evening[i].iron : "0.00",
                  vitaminC: obj.self_evening[i].vitaminC != undefined ? obj.self_evening[i].vitaminC : "0.00",
                  vitaminA: obj.self_evening[i].vitaminA != undefined ? obj.self_evening[i].vitaminA : "0.00",
                  vitaminB12: obj.self_evening[i].vitaminB12 != undefined ? obj.self_evening[i].vitaminB12 : "0.00",
                  fiber: obj.self_evening[i].fiber != undefined ? obj.self_evening[i].fiber : "0.00",
                  folic: obj.self_evening[i].folic != undefined ? obj.self_evening[i].folic : "0.00",
                  h2o: obj.self_evening[i].h2o != undefined ? obj.self_evening[i].h2o : "0.00",
                  session: "Evening",
                  type: "patient",
                  image: obj.self_evening[i].image,
                  date : obj.self_evening[i].date != undefined ? obj.self_evening[i].date : undefined
                };
                this.mainDishFlag = obj.self_evening[i].main_course != "1" ? false : true;
                this.selctedFood(obj.self_evening[i].day,obj.self_evening[i].date, tempFoodItems, 0);
              }
            }
            if ((obj.self_dinner != undefined) && obj.self_dinner != null && obj.self_dinner.length != 0) {
              this.dietPlanAll = "1";
              for (var i = 0; i < obj.self_dinner.length; i++) {
                var dayMorning = obj.self_dinner[i].day != undefined ? obj.self_dinner[i].day : " ";
                var tempWeight = obj.self_dinner[i].weight.split(" ");
                var calsWight = tempWeight[0];
                var wightMesure = tempWeight[1];
                tempFoodItems = {
                  food_item_id: obj.self_dinner[i].food_item_id,
                  description: obj.self_dinner[i].description,
                  food_type_id: obj.self_dinner[i].food_type_id,
                  food_sub_type: obj.self_dinner[i].food_sub_type,
                  quantity: obj.self_dinner[i].quantity != undefined ? obj.self_dinner[i].quantity : "0.00",
                  measure: obj.self_dinner[i].measure != undefined ? obj.self_dinner[i].measure : "0.00",
                  weight: obj.self_dinner[i].weight != undefined ? obj.self_dinner[i].weight : "0.00",
                  cals_weight: calsWight,
                  wightMesure: wightMesure,
                  energy: obj.self_dinner[i].energy != undefined ? obj.self_dinner[i].energy : "0.00",
                  proteins: obj.self_dinner[i].proteins != undefined ? obj.self_dinner[i].proteins : "0.00",
                  carbohydrates: obj.self_dinner[i].carbohydrates != undefined ? obj.self_dinner[i].carbohydrates : "0.00",
                  fat: obj.self_dinner[i].fat != undefined ? obj.self_dinner[i].fat : "0.00",
                  calcium: obj.self_dinner[i].calcium != undefined ? obj.self_dinner[i].calcium : "0.00",
                  iron: obj.self_dinner[i].iron != undefined ? obj.self_dinner[i].iron : "0.00",
                  vitaminC: obj.self_dinner[i].vitaminC != undefined ? obj.self_dinner[i].vitaminC : "0.00",
                  vitaminA: obj.self_dinner[i].vitaminA != undefined ? obj.self_dinner[i].vitaminA : "0.00",
                  vitaminB12: obj.self_dinner[i].vitaminB12 != undefined ? obj.self_dinner[i].vitaminB12 : "0.00",
                  fiber: obj.self_dinner[i].fiber != undefined ? obj.self_dinner[i].fiber : "0.00",
                  folic: obj.self_dinner[i].folic != undefined ? obj.self_dinner[i].folic : "0.00",
                  h2o: obj.self_dinner[i].h2o != undefined ? obj.self_dinner[i].h2o : "0.00",
                  session: "Dinner",
                  type: "patient",
                  image: obj.self_dinner[i].image,
                  date : obj.self_dinner[i].date != undefined ? obj.self_dinner[i].date : undefined
                };
                this.mainDishFlag = obj.self_dinner[i].main_course != "1" ? false : true;
                this.selctedFood(obj.self_dinner[i].day,obj.self_dinner[i].date, tempFoodItems, 0);
              }
            }
            if ((obj.self_night != undefined) && obj.self_night != null && obj.self_night.length != 0) {
              this.dietPlanAll = "1";
              for (var i = 0; i < obj.self_night.length; i++) {
                var dayMorning = obj.self_night[i].day != undefined ? obj.self_night[i].day : " ";
                var tempWeight = obj.self_night[i].weight.split(" ");
                var calsWight = tempWeight[0];
                tempFoodItems = {
                  food_item_id: obj.self_night[i].food_item_id,
                  description: obj.self_night[i].description,
                  food_type_id: obj.self_night[i].food_type_id,
                  food_sub_type: obj.self_night[i].food_sub_type,
                  quantity: obj.self_night[i].quantity != undefined ? obj.self_night[i].quantity : "0.00",
                  measure: obj.self_night[i].measure != undefined ? obj.self_night[i].measure : "0.00",
                  weight: obj.self_night[i].weight != undefined ? obj.self_night[i].weight : "0.00",
                  cals_weight: calsWight,
                  energy: obj.self_night[i].energy != undefined ? obj.self_night[i].energy : "0.00",
                  proteins: obj.self_night[i].proteins != undefined ? obj.self_night[i].proteins : "0.00",
                  carbohydrates: obj.self_night[i].carbohydrates != undefined ? obj.self_night[i].carbohydrates : "0.00",
                  fat: obj.self_night[i].fat != undefined ? obj.self_night[i].fat : "0.00",
                  calcium: obj.self_night[i].calcium != undefined ? obj.self_night[i].calcium : "0.00",
                  iron: obj.self_night[i].iron != undefined ? obj.self_night[i].iron : "0.00",
                  vitaminC: obj.self_night[i].vitaminC != undefined ? obj.self_night[i].vitaminC : "0.00",
                  vitaminA: obj.self_night[i].vitaminA != undefined ? obj.self_night[i].vitaminA : "0.00",
                  vitaminB12: obj.self_night[i].vitaminB12 != undefined ? obj.self_night[i].vitaminB12 : "0.00",
                  fiber: obj.self_night[i].fiber != undefined ? obj.self_night[i].fiber : "0.00",
                  folic: obj.self_night[i].folic != undefined ? obj.self_night[i].folic : "0.00",
                  h2o: obj.self_night[i].h2o != undefined ? obj.self_night[i].h2o : "0.00",
                  session: "Night",
                  type: "patient",
                  image: obj.self_night[i].image,
                  date : obj.self_night[i].date != undefined ? obj.self_night[i].date : undefined
                };
                this.mainDishFlag = obj.self_night[i].main_course != "1" ? false : true;
                this.selctedFood(obj.self_night[i].day,obj.self_night[i].date, tempFoodItems, 0);
              }
            }
            this.dayClick(this.today,obj.plan_start_date ,false);
            console.log("check display array" + JSON.stringify(this.displayArray));
            console.log("check temp display array" + JSON.stringify(this.tempDisplyArray));
          } else {
              this.displayArray = [];
              this.appointmentType = "Weekly";
              this.changeAppointmentType();
          } 
          
        },
        error => { }
      )
  }

  selctedFood(day,date, foodArray, flag) { 
    for (var i = 0; i < this.displayArray.length; i++) {
      if (this.displayArray[i].day == day && this.displayArray[i].date == date) {
        var session = this.displayArray[i].session;
        if (session.length != 0) {
          for (var j = 0; j < session.length; j++) {
            if (session[j].type == foodArray.session) {
              if( flag == 0 ){
                this.updateURLFlag = true
              }
              var mainDish = this.mainDishFlag != true ? false : true;
              var checked
              if (foodArray.type == "Doctor" && this.today == day) {
                checked = session[j].docChecked == true ? true : false;
              } else if (flag == 1) {
                checked = true;
              } else {
                checked = session[j].patintChecked == true ? true : false;
              }
              this.displayArray[i].session[j].empty = this.displayArray[i].session[j].foodItems.length != 0 ? false : true;
              var wightMesure = foodArray.wightMesure != undefined ? foodArray.wightMesure : "--";
              var disArray = {
                food_item_id: foodArray.food_item_id,
                description: foodArray.description,
                food_type_id: foodArray.food_type_id,
                food_sub_type: foodArray.food_sub_type,
                quantity: foodArray.quantity,
                temp_quantity : foodArray.quantity,
                orginal_quantity :foodArray.quantity,
                measure: foodArray.measure,
                weight: foodArray.weight,
                cals_weight: foodArray.cals_weight,
                wightMesure: wightMesure,
                temp_cals_weight: foodArray.cals_weight,
                orginal_weight: foodArray.cals_weight,
                energy: foodArray.energy,
                proteins: foodArray.proteins,
                carbohydrates: foodArray.carbohydrates,
                fat: foodArray.fat,
                calcium: foodArray.calcium,
                iron: foodArray.iron,
                vitaminC: foodArray.vitaminC,
                vitaminA: foodArray.vitaminA,
                vitaminB12: foodArray.vitaminB12,
                fiber: foodArray.fiber,
                folic: foodArray.folic,
                h2o: foodArray.h2o,
                checked: checked,
                session: foodArray.session,
                day: day,
                type: foodArray.type,
                mainDish: mainDish,
                date : date,
                displayContent: foodArray.description + " (" + foodArray.quantity + " " + foodArray.measure + " - " + foodArray.weight + " )",
                foodImges: ipaddress.Ip_with_img_address + "/" + foodArray.image,
              };
              console.log("foodArray" + JSON.stringify(foodArray));
              this.morningFoods = "";
              this.BreakfastFoods = "";
              this.MidDayFoods = "";
              this.LunchFoods = "";
              this.EveningFoods = "";
              this.DinnerFoods = "";
              this.NightFoods = "";
              this.tempDisplyArray.push(disArray);
              if (this.dietFlow == "dietTracker") {
                if ((flag == 1) || (flag == 0 && this.today == day && this.displayArray[i].date == date)) {
                  this.displayArray[i].session[j].foodItems.push(disArray);
                }
              } else {
                if (flag != 1) {
                  this.displayArray[i].session[j].foodItems.push(disArray);
                } else if (flag == 1 && session[j].patintChecked == true) {
                  this.displayArray[i].session[j].foodItems.push(disArray);
                } else {
                  this.toastr.error("You can't modify provider's diet plan");
                }
              }
              if (checked == true) {
                if (this.dietFlow == "dietTracker") {
                  this.getSendData(foodArray.session, "add", disArray);
                } else {
                  if (foodArray.type == "patient" && this.today == day && foodArray.date == date) {
                    this.getSendData(foodArray.session, "add", disArray);
                  }
                }
                this.totalCalories = (parseFloat(this.totalCalories) + parseFloat(foodArray.energy)).toFixed(2);
                this.calculatingFoodData(day,date, foodArray.session)
              }
              break;
            }
          }
        }
        break;
      }
    }
    this.mainDishFlag = false;
  }

  dayClick(day,date, flag) {
    $(".nav-link").removeClass("active");
    $("#" + date + "-tab").addClass("active");
    $(".tab-pane").removeClass("active");
    $("#" + date).addClass("active");
    this.previousDay = this.today;
    this.today = day
    if (this.dietFlow == "dietTracker") {
      if (flag == true) {
        for (var i = 0; i < this.displayArray.length; i++) {
          if (this.displayArray[i].day == day && this.displayArray[i].date == date ) {
            var session = this.displayArray[i].session;
            if (session.length != 0) {
              for (var j = 0; j < session.length; j++) {
                if (this.displayArray[i].session[j].foodItems.length == 0) {
                  for (var k = 0; k < this.tempDisplyArray.length; k++) {
                    if (session[j].type == this.tempDisplyArray[k].session) {
                      if (this.tempDisplyArray[k].day == day && this.tempDisplyArray[k].date == date) {
                        if (this.tempDisplyArray[k].type == "Doctor") {
                          this.tempDisplyArray[k].checked = session[j].docChecked == true ? true : false;
                        } else {
                          this.tempDisplyArray[k].checked = session[j].patintChecked == true ? true : false;
                        }
                        this.displayArray[i].session[j].foodItems.push(this.tempDisplyArray[k]);
                        this.morningFoods = "";
                        this.BreakfastFoods = "";
                        this.MidDayFoods = "";
                        this.LunchFoods = "";
                        this.EveningFoods = "";
                        this.DinnerFoods = "";
                        this.NightFoods = "";
                        if (this.tempDisplyArray[k].checked == true) {
                          this.calculatingFoodData(day,date, this.tempDisplyArray[k].session)
                          this.getSendData(this.tempDisplyArray[k].session, "add", this.tempDisplyArray[k]);
                        }
                      }
                    }
                  }
                }
              }
            }
            break;
          }
        }
      }
    } else {
      this.totalCalories = "0.00";
      // if (this.dayChangeFlag == true && flag == true && this.diet_plan_all != "1") {
      //   $("#staticBackdrop").modal("show");
      // }else{
      // }
      this.ynopt1 = true;
      this.dietType = "self";
      this.getArrayList("patient", null, day,date, "add");
      //this.changeType(1,'yes')
    }
  }

  foodType(itemname, session, sessionArray) {
    if (itemname != undefined && itemname != "" && itemname.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'lifestyle/gdbn',
        {
          item_name: itemname
        },
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            console.log("GDBNNNNN value-----" + JSON.stringify(obj));
            var food_items = obj.food_items;
            if (food_items.length != 0) {
              var tempFoodItems = [];
              this.displayArray;
              this.morningFoodItem = [];
              this.breadfastFoodItem = [];
              this.midDayFoodItem = [];
              this.lunchFoodItem = [];
              this.eveningFoodItem = [];
              this.dinnerFoodItem = [];
              this.nightFoodItem = [];
              for (var i = 0; i < food_items.length; i++) {
                var tempWeight = food_items[i].weight.split(" ");
                var calsWight = tempWeight[0];
                var foodType;
                if (this.dietFlow == "dietTracker") {
                  if (sessionArray.patintChecked == true && sessionArray.docChecked == true) {
                    foodType = "patient";
                  } else if (sessionArray.patintChecked != true && sessionArray.docChecked == true && sessionArray.empty != true) {
                    foodType = "Doctor";
                  } else {
                    foodType = "patient";
                  }
                } else {
                  foodType = "patient";
                }
                if(food_items[i].weight != undefined){
                  var tempWeight = food_items[i].weight.split(" ");
                  var calsWight = tempWeight[0];
                  var wightMesure = tempWeight[1] !=  undefined ?  tempWeight[1] : "--" ;
                }
                // var tempWeight = food_items[i].weight.split(" ");
                // var calsWight = tempWeight[0];
                // var wightMesure = tempWeight[1];
                tempFoodItems.push({
                  food_item_id: food_items[i].food_item_id,
                  description: food_items[i].description,
                  food_type_id: food_items[i].food_type_id,
                  food_sub_type: food_items[i].food_sub_type,
                  quantity: food_items[i].quantity,
                  measure: food_items[i].measure,
                  weight: food_items[i].weight,
                  cals_weight: calsWight,
                  wightMesure: wightMesure,
                  energy: food_items[i].energy,
                  proteins: food_items[i].proteins,
                  carbohydrates: food_items[i].carbohydrates,
                  fat: food_items[i].fat,
                  calcium: food_items[i].calcium,
                  iron: food_items[i].iron,
                  vitaminC: food_items[i].vitaminC,
                  vitaminA: food_items[i].vitaminA,
                  vitaminB12: food_items[i].vitaminB12,
                  fiber: food_items[i].fiber,
                  folic: food_items[i].folic,
                  h2o: food_items[i].h2o,
                  session: session,
                  displayContent: food_items[i].description + " (" + food_items[i].quantity + " " + food_items[i].measure + " - " + food_items[i].weight + " )",
                  type: foodType,
                  image: food_items[i].image,
                });
              }
              switch (session) {
                case 'Morning':
                  this.morningFoodItem = tempFoodItems;
                  break;
                case 'Breakfast':
                  this.breadfastFoodItem = tempFoodItems;
                  break;
                case 'Mid-day':
                  this.midDayFoodItem = tempFoodItems;
                  break;
                case 'Lunch':
                  this.lunchFoodItem = tempFoodItems;
                  break;
                case 'Evening':
                  this.eveningFoodItem = tempFoodItems;
                  break;
                case 'Dinner':
                  this.dinnerFoodItem = tempFoodItems;
                  break;
                case 'Night':
                  this.nightFoodItem = tempFoodItems;
                  break;
              }
            }
          },
          error => { }
        )
    }
  }

  isSelected(selectedArray, action) {
    if (this.dietFlow == "dietTracker") {
      for (var i = 0; i < this.displayArray.length; i++) {
        if (this.displayArray[i].day == selectedArray.day && this.displayArray[i].date == selectedArray.date) {
          for (var j = 0; j < this.displayArray[i].session.length; j++) {
            if (this.displayArray[i].session[j].type == selectedArray.session) {
              for (var k = 0; k < this.displayArray[i].session[j].foodItems.length; k++) {
                if (this.displayArray[i].session[j].foodItems[k].food_item_id == selectedArray.food_item_id) {
                  if (action == "add") {
                    if (this.mainDishFlag == true) {
                      this.displayArray[i].session[j].foodItems[k].mainDish = true;
                      this.displayArray[i].session[j].mainDish = true;
                    }
                    this.displayArray[i].session[j].foodItems[k].checked = true;
                    this.getSendData(selectedArray.session, "add", this.displayArray[i].session[j].foodItems[k]);
                  } else {
                    if (this.displayArray[i].session[j].foodItems[k].mainDish == true) {
                      this.mainDishFlag = false;
                      this.displayArray[i].session[j].mainDish = false;
                      this.displayArray[i].session[j].foodItems[k].mainDish = false
                    }
                    this.displayArray[i].session[j].foodItems[k].checked = false;
                    this.getSendData(selectedArray.session, "remove", this.displayArray[i].session[j].foodItems[k]);
                  }
                  this.calculatingFoodData(selectedArray.day,selectedArray.date, selectedArray.session);
                  break;
                }
              }
              break;
            }
          }
          break;
        }
      }
    } else {
      for (var i = 0; i < this.displayArray.length; i++) {
        if (this.displayArray[i].day == selectedArray.day && this.displayArray[i].date == selectedArray.date) {
          for (var j = 0; j < this.displayArray[i].session.length; j++) {
            if (this.displayArray[i].session[j].type == selectedArray.session) {
              for (var k = 0; k < this.displayArray[i].session[j].foodItems.length; k++) {
                if (this.displayArray[i].session[j].foodItems[k].food_item_id == selectedArray.food_item_id) {
                  if (this.displayArray[i].session[j].foodItems[k].type == "patient") {
                    if (action == "add") {
                      if (this.mainDishFlag == true) {
                        this.displayArray[i].session[j].foodItems[k].mainDish = true;
                        this.displayArray[i].session[j].mainDish = true;
                      }
                      this.displayArray[i].session[j].foodItems[k].checked = true;
                      this.totalCalories = (parseFloat(this.totalCalories) - parseFloat(this.displayArray[i].session[j].foodItems[k].energy)).toFixed(2);
                      this.getSendData(selectedArray.session, "add", this.displayArray[i].session[j].foodItems[k]);
                    } else {
                      if (this.displayArray[i].session[j].foodItems[k].mainDish == true) {
                        this.mainDishFlag = false;
                        this.displayArray[i].session[j].mainDish = false;
                        this.displayArray[i].session[j].foodItems[k].mainDish = false
                      }
                      this.displayArray[i].session[j].foodItems[k].checked = false;
                      this.totalCalories = (parseFloat(this.totalCalories) - parseFloat(this.displayArray[i].session[j].foodItems[k].energy)).toFixed(2);
                      this.getSendData(selectedArray.session, "remove", this.displayArray[i].session[j].foodItems[k]);
                      setTimeout(() => {
                        this.displayArray[i].session[j].foodItems.splice(k, 1);
                      }, 200);
                    }
                    this.calculatingFoodData(selectedArray.day,selectedArray.date, selectedArray.session);
                  } else {
                    this.toastr.error("You can't modify provider's diet plan");
                  }
                  break;
                }
              }
              break;
            }
          }
          break;
        }
      }
    }
    this.mainDishFlag = false;
  }

  mainDish(selectedArray, day, date ) {
    this.mainDishFlag = true;
    for (var i = 0; i < this.displayArray.length; i++) {
      if (this.displayArray[i].day == day && this.displayArray[i].date == date) {
        for (var j = 0; j < this.displayArray[i].session.length; j++) {
          if (this.displayArray[i].session[j].type == selectedArray.type) {
            this.displayArray[i].session[j].mainDish = true;
            break;
          }
        }
        break;
      }
    }
  }

  calculatingFoodData(day,date, session) {
    this.foodImages = [];
    for (var i = 0; i < this.displayArray.length; i++) {
      if (this.displayArray[i].day == day && this.displayArray[i].date == date) {
        for (var j = 0; j < this.displayArray[i].session.length; j++) {
          if (this.displayArray[i].session[j].type == session) {
            var fooditems = this.displayArray[i].session[j].foodItems;
            var energy = "0.00";
            var Protien = "0.00";
            var Ca = "0.00";
            var Fe = "0.00";
            var fiber = "0.00";
            var vaitamina = "0.00";
            var Fat = "0.00";
            var h2o = "0.00";
            var Folic = "0.00";
            var Carbohydrates = "0.00";
            var Vitaminc = "0.00";
            var vitaminb12 = "0.00";
            var htmlContent = "";
            var count = 1;
            $('#' + date + "_" + session).css('background-image', 'url()');
            for (var k = 0; k < fooditems.length; k++) {
              if (fooditems[k].checked == true) {
                energy = (parseFloat(energy) + parseFloat(fooditems[k].energy)).toFixed(2);
                Protien = (parseFloat(Protien) + parseFloat(fooditems[k].proteins)).toFixed(2);
                Ca = (parseFloat(Ca) + parseFloat(fooditems[k].calcium)).toFixed(2);
                Fe = (parseFloat(Fe) + parseFloat(fooditems[k].iron)).toFixed(2);
                fiber = (parseFloat(fiber) + parseFloat(fooditems[k].fiber)).toFixed(2);
                vaitamina = (parseFloat(vaitamina) + parseFloat(fooditems[k].vitaminA)).toFixed(2);
                Fat = (parseFloat(Fat) + parseFloat(fooditems[k].fat)).toFixed(2);
                h2o = (parseFloat(h2o) + parseFloat(fooditems[k].h2o)).toFixed(2);
                Folic = (parseFloat(Folic) + parseFloat(fooditems[k].folic)).toFixed(2);
                Carbohydrates = (parseFloat(Carbohydrates) + parseFloat(fooditems[k].carbohydrates)).toFixed(2);
                Vitaminc = (parseFloat(Vitaminc) + parseFloat(fooditems[k].vitaminC)).toFixed(2);
                vitaminb12 = (parseFloat(vitaminb12) + parseFloat(fooditems[k].vitaminB12)).toFixed(2);
                var mainDish = fooditems[k].mainDish != true ? false : true;
                if (mainDish == true) {
                  $('#' + date + "_" + session).css('background-image', 'url(' + fooditems[k].foodImges + ')');
                } else {
                  if (count <= 12){
                    htmlContent += '<img src="' + fooditems[k].foodImges + '" class="pos p' + count + '">';
                  }
                  count = count + 1;
                }
              }
            }
            this.displayArray[i].session[j].energy = energy;
            this.displayArray[i].session[j].Protien = Protien;
            this.displayArray[i].session[j].Ca = Ca;
            this.displayArray[i].session[j].Fe = Fe;
            this.displayArray[i].session[j].fiber = fiber;
            this.displayArray[i].session[j].vaitamina = vaitamina;
            this.displayArray[i].session[j].Fat = Fat;
            this.displayArray[i].session[j].h2o = h2o;
            this.displayArray[i].session[j].Folic = Folic;
            this.displayArray[i].session[j].Carbohydrates = Carbohydrates;
            this.displayArray[i].session[j].Vitaminc = Vitaminc;
            this.displayArray[i].session[j].vitaminb12 = vitaminb12;
            this.displayArray[i].session[j].plateContent = htmlContent;
            break;
          }
        }
        break;
      }
    }
  }

  getSendData(session, action, array) {
    Helper_Class.setDiet(null);
    var sga_send = null;
    var sub_rel = null;
    var tcal_to_txt = null;
    var get_life_sty_id = this.lifeStyleId;
    var is_doctor = array.type == "patient" ? "0" : "1";
    var mainDish = array.mainDish != true ? "0" : "1";
    var sendArray = {
      food_item_id: array.food_item_id,
      quantity: array.quantity,
      measure: array.measure,
      weight: array.weight,
      cals_weight: array.cals_weight,
      food_sub_type: array.food_sub_type,
      description: array.description,
      energy: array.energy,
      proteins: array.proteins,
      carbohydrates: array.carbohydrates,
      fat: array.fat,
      calcium: array.calcium,
      vitaminC: array.vitaminC,
      vitaminA: array.vitaminA,
      vitaminB12: array.vitaminB12,
      h2o: array.h2o,
      main_course: mainDish,
      is_doctor: is_doctor,
      day: array.day,
      date: array.date
    }
    switch (session) {
      case 'Morning':
        if (action == "add") {
          this.dietMrngArry.push(sendArray);
        } else {
          for (var i = 0; i < this.dietMrngArry.length; i++) {
            if (this.dietMrngArry[i].food_item_id == array.food_item_id && this.dietMrngArry[i].day == array.day && this.dietMrngArry[i].date == array.date) {
              this.dietMrngArry.splice(i, 1);
              if (action == "update") {
                this.dietMrngArry.push(sendArray);
              }
            }
          }
        }
        break;
      case 'Breakfast':
        if (action == "add") {
          var mainDish = array.mainDish != true ? "0" : "1";
          this.breakFastArryData.push(sendArray);
        } else {
          for (var i = 0; i < this.breakFastArryData.length; i++) {
            if (this.breakFastArryData[i].food_item_id == array.food_item_id  && this.breakFastArryData[i].day == array.day && this.breakFastArryData[i].date == array.date) {
              this.breakFastArryData.splice(i, 1);
              if (action == "update") {
                this.breakFastArryData.push(sendArray);
              }
            }
          }
          
        }
        break;
      case 'Mid-day':
        if (action == "add") {
          var mainDish = array.mainDish != true ? "0" : "1";
          this.midDayArryData.push(sendArray);
        } else {
          for (var i = 0; i < this.midDayArryData.length; i++) {
            if (this.midDayArryData[i].food_item_id == array.food_item_id  && this.midDayArryData[i].day == array.day && this.midDayArryData[i].date == array.date) {
              this.midDayArryData.splice(i, 1);
              if (action == "update") {
                this.midDayArryData.push(sendArray);
              }
            }
          }
          
        }
        break;
      case 'Lunch':
        if (action == "add") {
          var mainDish = array.mainDish != true ? "0" : "1";
          this.lunchArryData.push(sendArray);
        } else {
          for (var i = 0; i < this.lunchArryData.length; i++) {
            if (this.lunchArryData[i].food_item_id == array.food_item_id  && this.lunchArryData[i].day == array.day && this.lunchArryData[i].date == array.date) {
              this.lunchArryData.splice(i, 1);
              if (action == "update") {
                this.lunchArryData.push(sendArray);
              }
            }
          }
          
        }
        break;
      case 'Evening':
        if (action == "add") {
          var mainDish = array.mainDish != true ? "0" : "1";
          this.eveningArryData.push(sendArray);
        } else {
          for (var i = 0; i < this.eveningArryData.length; i++) {
            if (this.eveningArryData[i].food_item_id == array.food_item_id  && this.eveningArryData[i].day == array.day && this.eveningArryData[i].date == array.date) {
              this.eveningArryData.splice(i, 1);
              if (action == "update") {
                this.eveningArryData.push(sendArray);
              }
            }
          }
         
        }
        break;
      case 'Dinner':
        if (action == "add") {
          var mainDish = array.mainDish != true ? "0" : "1";
          this.dinnerArryData.push(sendArray);
        } else {
          for (var i = 0; i < this.dinnerArryData.length; i++) {
            if (this.dinnerArryData[i].food_item_id == array.food_item_id  && this.dinnerArryData[i].day == array.day && this.dinnerArryData[i].date == array.date) {
              this.dinnerArryData.splice(i, 1);
              if (action == "update") {
                this.dinnerArryData.push(sendArray);
              }
            }
          }
          
        }
        break;
      case 'Night':
        if (action == "add") {
          var mainDish = array.mainDish != true ? "0" : "1";
          this.nightArryData.push(sendArray);
        } else {
          for (var i = 0; i < this.nightArryData.length; i++) {
            if (this.nightArryData[i].food_item_id == array.food_item_id  && this.nightArryData[i].day == array.day && this.nightArryData[i].date == array.date) {
              this.nightArryData.splice(i, 1);
              if (action == "update") {
                this.nightArryData.push(sendArray);
              }
            }
          }
          
        }
        break;
    }
    if (this.dietFlow != "dietTracker") { 
      tcal_to_txt = this.totalCalories != "" && this.totalCalories != null && this.totalCalories != undefined ? this.totalCalories : "0.00";
      if (this.subRelId != null && this.subRelId != undefined && this.subRelId != "") {
        sga_send = {
          is_doctor: "0",
          overall_rating: this.sgaOverallScore,
          client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
          relation_id: Client_Helper.getrelationsubrelationslide().relation_id,
          sub_rel_id: this.subRelId,
          country: "IN",
          cal_required: this.calories,
          cal_intake: tcal_to_txt,
          life_style_id: this.lifeStyleId,
          dplan_morning: this.dietMrngArry,
          dplan_breakfast: this.breakFastArryData,
          dplan_midday: this.midDayArryData,
          dplan_lunch: this.lunchArryData,
          dplan_evening: this.eveningArryData,
          dplan_dinner: this.dinnerArryData,
          dplan_night: this.nightArryData,
          diet_plan: this.dietPlanAll,
        }
      } else {
        sga_send = {
          is_doctor: "0",
          overall_rating: this.sgaOverallScore,
          client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
          relation_id: Client_Helper.getrelationsubrelationslide().relation_id,
          country: "IN",
          cal_required: this.calories,
          cal_intake: tcal_to_txt,
          life_style_id: this.lifeStyleId,
          dplan_morning: this.dietMrngArry,
          dplan_breakfast: this.breakFastArryData,
          dplan_midday: this.midDayArryData,
          dplan_lunch: this.lunchArryData,
          dplan_evening: this.eveningArryData,
          dplan_dinner: this.dinnerArryData,
          dplan_night: this.nightArryData,
          diet_plan: this.dietPlanAll,
        }
      }
      Helper_Class.setDietplan(sga_send);
      Helper_Class.setDiet(sga_send);
      console.log("theis kldfak" + JSON.stringify(sga_send));
    }
    console.log( "this.dietMrngArry" + JSON.stringify(this.dietMrngArry)); 
    console.log( "this.breakFastArryData" + JSON.stringify(this.breakFastArryData));
    console.log( "this.midDayArryData" + JSON.stringify(this.midDayArryData)); 
    console.log( "this.lunchArryData" + JSON.stringify(this.lunchArryData));
    console.log( "this.eveningArryData" + JSON.stringify(this.eveningArryData));
    console.log( "this.dinnerArryData" + JSON.stringify(this.dinnerArryData));
    console.log( "this.nightArryData" + JSON.stringify(this.nightArryData));
  }

  getArrayList(type, sessionArray, day,date, action) {
    if (this.dietFlow == "dietTracker") {
      for (var i = 0; i < this.displayArray.length; i++) {
        if (this.displayArray[i].day == day && this.displayArray[i].date == date) {
          for (var j = 0; j < this.displayArray[i].session.length; j++) {
            if (this.displayArray[i].session[j].type == sessionArray.type) {
              for (var k = 0; k < this.displayArray[i].session[j].foodItems.length; k++) {
                if (this.displayArray[i].session[j].foodItems[k].type == type) {
                  if (action == "add") {
                    if (type == "Doctor") {
                      this.displayArray[i].session[j].docChecked = true;
                    } else {
                      this.displayArray[i].session[j].patintChecked = true;
                    }
                    this.displayArray[i].session[j].foodItems[k].checked = true;
                    this.getSendData(sessionArray.type, "add", this.displayArray[i].session[j].foodItems[k]);
                  } else {
                    if (type == "Doctor") {
                      this.displayArray[i].session[j].docChecked = false;
                    } else {
                      this.displayArray[i].session[j].patintChecked = false;
                    }
                    this.displayArray[i].session[j].foodItems[k].checked = false;
                    this.getSendData(sessionArray.type, "remove", this.displayArray[i].session[j].foodItems[k]);
                  }
                  this.calculatingFoodData(day,date, sessionArray.type);
                }
              }
              break;
            }
          }
          break;
        }
      }
    } else {
      for (var i = 0; i < this.displayArray.length; i++) {
        if (this.displayArray[i].day == day && this.displayArray[i].date == date) {
          for (var j = 0; j < this.displayArray[i].session.length; j++) {
            if (type == "Doctor") {
              this.displayArray[i].session[j].docChecked = true;
              this.displayArray[i].session[j].patintChecked = false;
            } else {
              this.displayArray[i].session[j].patintChecked = true;
              this.displayArray[i].session[j].docChecked = false;
            }
            for (var k = 0; k < this.displayArray[i].session[j].foodItems.length; k++) {
              if (this.displayArray[i].session[j].foodItems[k].type == type) {
                if (action == "add") {
                  this.displayArray[i].session[j].foodItems[k].checked = true;
                  if (type == "patient") {
                    this.getSendData(this.displayArray[i].session[j].type, "add", this.displayArray[i].session[j].foodItems[k]);
                  }
                }
                this.totalCalories = (parseFloat(this.totalCalories) + parseFloat(this.displayArray[i].session[j].foodItems[k].energy)).toFixed(2);
                this.calculatingFoodData(day,date, this.displayArray[i].session[j].type);
              } else {
                this.displayArray[i].session[j].foodItems[k].checked = false;
                this.calculatingFoodData(day,date, this.displayArray[i].session[j].type);
              }
            }
          }
          break;
        }
      }
    }
  }

  getDisplayArray() {
    var docChecked = this.dietFlow == "dietTracker" ? true : false;
    var clientCheked = this.dietFlow == "dietPlan" ? true : false;
    this.getCurrentDate();
  }

  dietPlanHistory() {
    this.dietPlanHist = "Diet_planhist";
    const dialogRef = this.dialog.open(DietTrackHistoryComponent, {
      width: '70%',
      height: '500px',
      data: {
        client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
        relation_id: this.relationId,
        sub_rel_id: this.subRelId,
        History: this.dietPlanHist
      }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  trackerHistory() {
    // this.trackerData = "Tracker_hist";
    // const dialogRef = this.dialog.open(DietTrackHistoryComponent, {
    //   width: '70%',
    //   height: '500px',
    //   data: {
    //     client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
    //     relation_id: this.relationId,
    //     sub_rel_id: this.subRelId,
    //     History: this.trackerData
    //   }
    // });
    // dialogRef.afterClosed().subscribe(result => {
    // });
    this.messageservice.sendMessage("newDietTrackingHistory");
  }

  async save() {
    if (this.dietFlow == "dietTracker") {
      this.relationId = Client_Helper.getrelationsubrelationslide().relation_id;
      this.subRelId = Client_Helper.getrelationsubrelationslide().sub_rel_id;
      this.lifeStyleId = Client_Helper.getrelationsubrelationslide().life_style_id;
     
      for( let i = 0; i < this.trackerArray.length; i++ ){
        if(this.dietMrngArry.length != 0){
          var getMorning = this.dietMrngArry.filter((x) => { return x.day == this.trackerArray[i].day })
          this.trackerArray[i].dplan_morning = getMorning;
        }
        if(this.breakFastArryData.length != 0){
          var getBreak = this.breakFastArryData.filter((x) => { return x.day == this.trackerArray[i].day })
          this.trackerArray[i].dplan_breakfast = getBreak;
        }
        if(this.midDayArryData.length != 0){
          var getMidDay = this.midDayArryData.filter((x) => { return x.day == this.trackerArray[i].day })
          this.trackerArray[i].dplan_midday = getMidDay;
        }
        if(this.lunchArryData.length != 0){
          var getLunchDay = this.lunchArryData.filter((x) => { return x.day == this.trackerArray[i].day })
          this.trackerArray[i].dplan_lunch = getLunchDay;
        }
        if(this.eveningArryData.length != 0){
          var getEveing = this.eveningArryData.filter((x) => { return x.day == this.trackerArray[i].day })
          this.trackerArray[i].dplan_evening = getEveing;
        }
        if(this.dinnerArryData.length != 0){
          var getDinner = this.dinnerArryData.filter((x) => { return x.day == this.trackerArray[i].day })
          this.trackerArray[i].dplan_dinner = getDinner;
        }
        if(this.nightArryData.length != 0){
          var getNight = this.nightArryData.filter((x) => { return x.day == this.trackerArray[i].day })
          this.trackerArray[i].dplan_night = getNight;
        }
      }
      var send_data;
      send_data = {
        // dplan_morning: this.dietMrngArry,
        // dplan_breakfast: this.breakFastArryData,
        // dplan_midday: this.midDayArryData,
        // dplan_lunch: this.lunchArryData,
        // dplan_evening: this.eveningArryData,
        // dplan_dinner: this.dinnerArryData,
        // dplan_night: this.nightArryData,
        client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
        relation_id: this.relationId,
        life_style_id: this.lifeStyleId,
        cal_required: this.calories,
        cal_intake: this.totalCalories, 
        country: ipaddress.country_code,
        tracker: this.trackerArray,
       
      }
      if (this.subRelId != null && this.subRelId != undefined && this.subRelId != "") {
        send_data.sub_rel_id = this.subRelId;
      }
      console.log( "this.trackerArray" + JSON.stringify(send_data));
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "lifestyle/dplantrack", JSON.stringify(send_data), { headers: headers }).subscribe(
        data => {
          Client_Helper.setDietProgress(undefined);
          if (data.json().key != undefined && data.json().key == "1") {
            if (Client_Helper.getrelationsubrelationslide().relation_id == "1") {
              send_data.dietFlow = this.pageHeading;
              Client_Helper.setDietProgress(send_data);
            }
            this.saveFlag = false;
            this.toastr.success('Diet details saved successfully');
            Client_Helper.setTrackerFlag("diet");
            this.messageservice.sendMessage("diet_tracker");
          } else {
            this.toastr.error('Diet details not saved, please try again later');
          }
        });
    } else {
      var get_diet_data = Helper_Class.getDietplan();
      var diet_plan_url = "lifestyle/sdplan";
      if(this.dietPlanAll == "1"){
        diet_plan_url = "lifestyle/updplan";
      }
      // for (var key in get_diet_data) {
      //   if (key == "diet_plan" && get_diet_data[key] == "1") {
      //     diet_plan_url = "lifestyle/updplan";
      //   }
      // }
      var response = await this.serviceAPI.PostData(diet_plan_url, get_diet_data).toPromise();
      if (response) {
        var obj = JSON.parse(JSON.stringify(response));
        console.log("diet plan sendtata"+JSON.stringify(get_diet_data))
        this.toastr.success("Diet save successfully");
      } else {
        this.toastr.error("Something went wrong, try later")
      }
    }
  }

  changeType(id, value) {
    this.totalCalories = "0.00";
    if (value == "yes") {
      this.ynopt1 = true;
      this.dietType = "self";
      this.getArrayList("patient", null, this.today,this.selectedDate, "add");
      this.saveFlag = true;
    } else {
      this.ynopt1 = false;
      this.dietType = "doctor";
      this.getArrayList("Doctor", null, this.today,this.selectedDate, "add");
      this.saveFlag = false;
    }
  }
  
  changeQuantity(value, day,date, selectedArray, sessiontype, action) {
    var checked;
    var dicrption = "";
    for (var i = 0; i < this.displayArray.length; i++) {
      if (this.displayArray[i].day == day && this.displayArray[i].date == date) {
        for (var j = 0; j < this.displayArray[i].session.length; j++) {
          if (this.displayArray[i].session[j].type == sessiontype) {
            for (var k = 0; k < this.displayArray[i].session[j].foodItems.length; k++) {
              if (this.displayArray[i].session[j].foodItems[k].food_item_id == selectedArray.food_item_id) {
                var energy, proteins_change, carbohydrates_change, fat_change, calcium_change, vitaminC_change, vitaminA_change, vitaminB12_change, fiber_change, folic_change, h2o_change,weight,quantity;
                this.totalCalories = (parseFloat(this.totalCalories) - parseFloat(this.displayArray[i].session[j].foodItems[k].energy)).toFixed(2);
                this.displayArray[i].session[j].foodItems[k].food_item_id = selectedArray.food_item_id;
                if( action == "quantity" ) {
                  energy = ((this.displayArray[i].session[j].foodItems[k].energy / this.displayArray[i].session[j].foodItems[k].temp_quantity) * (value));
                  proteins_change = ((this.displayArray[i].session[j].foodItems[k].proteins / this.displayArray[i].session[j].foodItems[k].temp_quantity) * (value));
                  fat_change = ((this.displayArray[i].session[j].foodItems[k].fat / this.displayArray[i].session[j].foodItems[k].temp_quantity) * (value));
                  calcium_change = ((this.displayArray[i].session[j].foodItems[k].calcium / this.displayArray[i].session[j].foodItems[k].temp_quantity) * (value));
                  vitaminC_change = ((this.displayArray[i].session[j].foodItems[k].vitaminC / this.displayArray[i].session[j].foodItems[k].temp_quantity) * (value));
                  vitaminA_change = ((this.displayArray[i].session[j].foodItems[k].vitaminA / this.displayArray[i].session[j].foodItems[k].temp_quantity) * (value));
                  vitaminB12_change = ((this.displayArray[i].session[j].foodItems[k].vitaminB12 / this.displayArray[i].session[j].foodItems[k].temp_quantity) * (value));
                  fiber_change = ((this.displayArray[i].session[j].foodItems[k].fiber / this.displayArray[i].session[j].foodItems[k].temp_quantity) * (value));
                  folic_change = ((this.displayArray[i].session[j].foodItems[k].folic / this.displayArray[i].session[j].foodItems[k].temp_quantity) * (value));
                  h2o_change = ((this.displayArray[i].session[j].foodItems[k].h2o / this.displayArray[i].session[j].foodItems[k].temp_quantity) * (value));
                  weight = ((this.displayArray[i].session[j].foodItems[k].cals_weight / this.displayArray[i].session[j].foodItems[k].temp_quantity) * (value));
                  quantity = value;
                } else {
                  energy = ((this.displayArray[i].session[j].foodItems[k].energy / this.displayArray[i].session[j].foodItems[k].temp_cals_weight) * (value));
                  proteins_change = (this.displayArray[i].session[j].foodItems[k].proteins / this.displayArray[i].session[j].foodItems[k].temp_cals_weight) * (value);
                  carbohydrates_change = (this.displayArray[i].session[j].foodItems[k].carbohydrates / this.displayArray[i].session[j].foodItems[k].temp_cals_weight) * (value);
                  fat_change = (this.displayArray[i].session[j].foodItems[k].fat / this.displayArray[i].session[j].foodItems[k].temp_cals_weight) * (value);
                  calcium_change = ((this.displayArray[i].session[j].foodItems[k].calcium / this.displayArray[i].session[j].foodItems[k].temp_cals_weight) * (value));
                  vitaminC_change = ((this.displayArray[i].session[j].foodItems[k].vitaminC / this.displayArray[i].session[j].foodItems[k].temp_cals_weight) * (value));
                  vitaminA_change = ((this.displayArray[i].session[j].foodItems[k].vitaminA / this.displayArray[i].session[j].foodItems[k].temp_cals_weight) * (value));
                  vitaminB12_change = ((this.displayArray[i].session[j].foodItems[k].vitaminB12 / this.displayArray[i].session[j].foodItems[k].temp_cals_weight) * (value));
                  fiber_change = ((this.displayArray[i].session[j].foodItems[k].fiber / this.displayArray[i].session[j].foodItems[k].temp_cals_weight) * (value));
                  folic_change = ((this.displayArray[i].session[j].foodItems[k].folic / this.displayArray[i].session[j].foodItems[k].temp_cals_weight) * (value));
                  h2o_change = ((this.displayArray[i].session[j].foodItems[k].h2o / this.displayArray[i].session[j].foodItems[k].temp_cals_weight) * (value));
                  weight = value;
                  var test =  value / this.displayArray[i].session[j].foodItems[k].orginal_weight;
                  console.log("test" + JSON.stringify(test))
                  if(!this.isDecimal(test)){
                    quantity = test ;
                  } else {
                    quantity = this.displayArray[i].session[j].foodItems[k].temp_quantity;
                  }
                 
                }
                this.displayArray[i].session[j].foodItems[k].weight = parseInt(weight).toString() + " " + this.displayArray[i].session[j].foodItems[k].wightMesure;
                this.displayArray[i].session[j].foodItems[k].energy = parseInt(energy).toString();
                this.displayArray[i].session[j].foodItems[k].temp_quantity = parseInt(quantity).toString();
                this.displayArray[i].session[j].foodItems[k].quantity = parseInt(quantity).toString();
                this.displayArray[i].session[j].foodItems[k].temp_cals_weight =  parseInt(weight).toString(); ;
                this.displayArray[i].session[j].foodItems[k].cals_weight =  parseInt(weight).toString(); ;
                this.displayArray[i].session[j].foodItems[k].proteins =  parseInt(proteins_change).toString(); ;
                this.displayArray[i].session[j].foodItems[k].carbohydrates =  parseInt(carbohydrates_change).toString(); ;
                this.displayArray[i].session[j].foodItems[k].fat =  parseInt(fat_change).toString(); ;
                this.displayArray[i].session[j].foodItems[k].calcium =  parseInt(calcium_change).toString(); ;
                this.displayArray[i].session[j].foodItems[k].vitaminC =  parseInt(vitaminC_change).toString(); ;
                this.displayArray[i].session[j].foodItems[k].vitaminA =  parseInt(vitaminA_change).toString(); ;
                this.displayArray[i].session[j].foodItems[k].vitaminB12 =  parseInt(vitaminB12_change).toString(); ;
                this.displayArray[i].session[j].foodItems[k].fiber =  parseInt(fiber_change).toString(); ;
                this.displayArray[i].session[j].foodItems[k].folic =  parseInt(folic_change).toString(); ;
                this.displayArray[i].session[j].foodItems[k].h2o =  parseInt(h2o_change).toString(); ;
                this.totalCalories = (parseFloat(this.totalCalories) + parseFloat(weight)).toFixed(2);
                console.log("chhhhqqqqqqqqqq--------" + JSON.stringify(this.displayArray[i].session[j].foodItems[k]))
                this.getSendData(this.displayArray[i].session[j].type, "update", this.displayArray[i].session[j].foodItems[k]);
                this.calculatingFoodData(day,date, this.displayArray[i].session[j].type);
              }
            }
            break;
          }
        }
      }
    }
  }

  isDecimal(num) {
    return (num % 1);
  }

  // assingData(action) {
  //   if (action == "yes") {
  //     for (var i = 0; i < this.displayArray.length; i++) {
  //       if (this.displayArray[i].day == this.previousDay) {
  //         for (var j = 0; j < this.displayArray[i].session.length; j++) {
  //           for (var k = 0; k < this.displayArray[i].session[j].foodItems.length; k++) {
  //             if (this.displayArray[i].session[j].foodItems[k].type == "patient") {
  //               switch (this.displayArray[i].session[j].type) {
  //                 case 'Morning':
  //                   this.tempMorningArray.push(this.displayArray[i].session[j].foodItems[k]);
  //                   break;
  //                 case 'Breakfast':
  //                   this.tempBreakfastArray.push(this.displayArray[i].session[j].foodItems[k]);
  //                   break;
  //                 case 'Mid-day':
  //                   this.tempMid_dayArray.push(this.displayArray[i].session[j].foodItems[k]);
  //                   break;
  //                 case 'Lunch':
  //                   this.tempLunchArray.push(this.displayArray[i].session[j].foodItems[k]);
  //                   break;
  //                 case 'Evening':
  //                   this.tempEveningArray.push(this.displayArray[i].session[j].foodItems[k]);
  //                   break;
  //                 case 'Dinner':
  //                   this.tempDinnerArray.push(this.displayArray[i].session[j].foodItems[k]);
  //                   break;
  //                 case 'Night':
  //                   this.tempNightArray.push(this.displayArray[i].session[j].foodItems[k]);
  //                   break;
  //               }
  //             }
  //           }
  //         }
  //         break;
  //       }
  //     }
  //     for (var i = 0; i < this.displayArray.length; i++) {
  //       if (this.displayArray[i].day != this.previousDay) {
  //         for (var j = 0; j < this.displayArray[i].session.length; j++) {
  //           switch (this.displayArray[i].session[j].type) {
  //             case 'Morning':
  //               for (var l = 0; l < this.tempMorningArray.length; l++) {
  //                 this.tempMorningArray[l].day = this.displayArray[i].day;
  //                 this.displayArray[i].session[j].foodItems.push(this.tempMorningArray[l]);
  //                 this.getSendData(this.displayArray[i].session[j].type, "add", this.tempMorningArray[l]);
  //                 this.calculatingFoodData(this.displayArray[i].day, this.displayArray[i].session[j].type);
  //               }
  //               break;
  //             case 'Breakfast':
  //               for (var l = 0; l < this.tempBreakfastArray.length; l++) {
  //                 this.tempBreakfastArray[l].day = this.displayArray[i].day;
  //                 this.displayArray[i].session[j].foodItems.push(this.tempBreakfastArray[l]);
  //                 this.getSendData(this.displayArray[i].session[j].type, "add", this.tempBreakfastArray[l]);
  //                 this.calculatingFoodData(this.displayArray[i].day, this.displayArray[i].session[j].type);
  //               }
  //               break;
  //             case 'Mid-day':
  //               for (var l = 0; l < this.tempMid_dayArray.length; l++) {
  //                 this.tempMid_dayArray[l].day = this.displayArray[i].day;
  //                 this.displayArray[i].session[j].foodItems.push(this.tempMid_dayArray[l]);
  //                 this.getSendData(this.displayArray[i].session[j].type, "add", this.tempMid_dayArray[l]);
  //                 this.calculatingFoodData(this.displayArray[i].day, this.displayArray[i].session[j].type);
  //               }
  //               break;
  //             case 'Lunch':
  //               for (var l = 0; l < this.tempLunchArray.length; l++) {
  //                 this.tempLunchArray[l].day = this.displayArray[i].day;
  //                 this.displayArray[i].session[j].foodItems.push(this.tempLunchArray[l]);
  //                 this.getSendData(this.displayArray[i].session[j].type, "add", this.tempLunchArray[l]);
  //                 this.calculatingFoodData(this.displayArray[i].day, this.displayArray[i].session[j].type);
  //               }
  //               break;
  //             case 'Evening':
  //               for (var l = 0; l < this.tempEveningArray.length; l++) {
  //                 this.tempEveningArray[l].day = this.displayArray[i].day;
  //                 this.displayArray[i].session[j].foodItems.push(this.tempEveningArray[l]);
  //                 this.getSendData(this.displayArray[i].session[j].type, "add", this.tempEveningArray[l]);
  //                 this.calculatingFoodData(this.displayArray[i].day, this.displayArray[i].session[j].type);
  //               }
  //               break;
  //             case 'Dinner':
  //               for (var l = 0; l < this.tempDinnerArray.length; l++) {
  //                 this.tempDinnerArray[l].day = this.displayArray[i].day;
  //                 this.displayArray[i].session[j].foodItems.push(this.tempDinnerArray[l]);
  //                 this.getSendData(this.displayArray[i].session[j].type, "add", this.tempDinnerArray[l]);
  //                 this.calculatingFoodData(this.displayArray[i].day, this.displayArray[i].session[j].type);
  //               }
  //               break;
  //             case 'Night':
  //               for (var l = 0; l < this.tempNightArray.length; l++) {
  //                 this.tempNightArray[l].day = this.displayArray[i].day;
  //                 this.displayArray[i].session[j].foodItems.push(this.tempNightArray[l]);
  //                 this.getSendData(this.displayArray[i].session[j].type, "add", this.tempNightArray[l]);
  //                 this.calculatingFoodData(this.displayArray[i].day, this.displayArray[i].session[j].type);
  //               }
  //               break;
  //           }
  //         }
  //       }
  //     }
  //   }
  //   this.dayChangeFlag = false;
  //   $("#staticBackdrop").modal("hide");
  // }

  getPdf(){
    $("#pdfModel").modal("show");
  }

  docDietTracking(){
    var sunRelID;
    this.relationId = Client_Helper.getrelationsubrelationslide().relation_id;
      this.subRelId = Client_Helper.getrelationsubrelationslide().sub_rel_id;
      this.lifeStyleId = Client_Helper.getrelationsubrelationslide().life_style_id;
    if (this.subRelId != null && this.subRelId != undefined && this.subRelId != "") {
      sunRelID = this.subRelId;
    }
      var getTracking = {
        client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
        relation_id: this.relationId,
        life_style_id: this.lifeStyleId,
        sunRelID : sunRelID,
        flow:"dietTracking"
      }
      Client_Helper.setDoctDietTracking(null);
      Client_Helper.setDoctDietTracking(getTracking);
      this.messageservice.sendMessage("doctDietTracking");
  }

  checkedSuggested(action){
    if( action == 'checked' ){
      this.checkedFlag = true;
      this.docDietTracking();
    }else{
      this.checkedFlag = false;
    }
  }

  dietMenu(){
    var sunRelID;
    this.relationId = Client_Helper.getrelationsubrelationslide().relation_id;
      this.subRelId = Client_Helper.getrelationsubrelationslide().sub_rel_id;
      this.lifeStyleId = Client_Helper.getrelationsubrelationslide().life_style_id;
    if (this.subRelId != null && this.subRelId != undefined && this.subRelId != "") {
      sunRelID = this.subRelId;
    }
    var getTracking = {
      client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
      relation_id: this.relationId,
      life_style_id: this.lifeStyleId,
      sunRelID : sunRelID,
      flow:"dietPlan",
      update : this.dietPlanAll,
    }
    console.log("getTracking" + JSON.stringify(getTracking));
    Client_Helper.setMenuDiet(null);
    Client_Helper.setMenuDiet(getTracking);
    this.messageservice.sendMessage("dietPlanMenu");
  }
}
