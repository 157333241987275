import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DietViewComponent } from './diet-view/diet-view.component';

const routes: Routes = [
  {path: 'DietView', component: DietViewComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DietRoutingModule { }
