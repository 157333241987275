<div class="theader" mat-dialog-title>
  <h5 class="header_txt">Filter page</h5>
  <img src="../../../assets/img/close_icon.png" (click)="closePopup()" width="20px" height="20px" id="close_icd" />

</div>
<div mat-dialog-content>
  <div class="row">
    <div class="col-12 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">

      <mat-label class="matlabel">Filter Location<br>
        <select disableOptionCentering class="ipcss widthappt" [(ngModel)]="location"
          (change)="LocationChange($event)">
          <option *ngFor="let list of locationArray" value={{list.value}}>{{list.taxt}}</option>
        </select>
      </mat-label>




    </div>
    <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"></div>
  </div>
  <div class="row">
    <div class="col-2 col-xl-2 col-md-2 col-lg-2 col-xl-2 padding">
      <img src="../../../assets/ui_icons/buttons/search_button.svg" class="saveimgbtn_inpatinfo" (click)="filter()"
        [mat-dialog-close]="cancel" cdkFocusInitial mat-raised-button>
     

    </div>
  </div>
  <br>
</div>