<div class="row " style="margin:0 auto; width:100%">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Confirm order</h5>
          </div>
          <div class="headerButtons">
            <img src="../../../assets/ui_icons/buttons/purchase_button.svg" class="saveimgbtn_inpatinfo" (click)="next()">
            <img src="../../../assets/ui_icons/buttons/Back_button.svg"  class="saveimgbtn_inpatinfo"
              (click)="back()">
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="">
          <div class="row">
            <div class="col-12 col-xl-6 col-sm-6 col-lg-6 col-md-6">
              <p>
                <b>{{pharmacyName}}</b>
              </p>
              <p class="changefont">{{pharmacyAddr1}}, {{pharmacyAddr2}}</p>
              <p class="changefont">{{pharmacyLocation}}</p>
              <p class="changefont">{{pharmacyCity}} - {{pharmacyZipcode}}</p>
              <p class="changefont">{{pharmacyState}}, {{pharmacyCountry}}</p>
              <p class="changefont">{{pharmacyTelephone}}</p><br>
            </div>
            <div [attr.disabled]="prevflow" style="padding-bottom: 10px;">
              <div class="col-12 col-xl-4 col-sm-4 col-lg-4 col-md-4">
                <mat-label class="matlabel">Order type &nbsp;&nbsp;
                  <mat-select required class="ipcss widthappt" [(ngModel)]="orderType"
                    (selectionChange)="changeOrderType()" [disabled]="orderDisabledFlag">
                    <mat-option value="None">None</mat-option>
                    <mat-option value="Store pickup">Store pickup</mat-option>
                    <mat-option value="Home delivery">Home delivery</mat-option>
                  </mat-select>
                </mat-label>
              </div>
            </div>
          </div>
          <div class="row" *ngIf=isHomeDelFlag>
            <div class="col-12">
              <div class="row">
                <div class="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 " *ngIf="isHomeDelFlag">
                  <mat-radio-group class="inpat_radiogrp" [(ngModel)]="timeGrp" color="primary" labelPosition="before">
                    <b style="vertical-align: text-bottom;" class="tervys_heading">Client Type &nbsp;&nbsp;&nbsp;&nbsp;:
                    </b>&nbsp;&nbsp;
                    <mat-radio-button (click)="changeDecision('Anytime between 8:00 AM - 6:00 PM')"
                      value="Anytime between 8:00 AM - 6:00 PM">Anytime between 8:00 AM - 6:00 PM
                    </mat-radio-button>&nbsp;&nbsp;
                    <mat-radio-button (click)="changeDecision('Enter preferred time')" value="Enter preferred time">
                      Enter
                      preferred time</mat-radio-button>
                  </mat-radio-group>
                </div>
                <div class="col-12 col-xl-4 col-sm-4 col-lg-4 col-md-4" *ngIf="isPrefTime">
                  <mat-label class="matlabel">Starts from<br>
                    <input class="ipcss" onclick="timeNow(fromTime)" id="test1" type="time" [(ngModel)]="fromTime"
                      #matInput placeholder="hh:mm a">
                  </mat-label>
                </div>
                <div class="col-12 col-xl-4 col-sm-4 col-lg-4 col-md-4" *ngIf="isPrefTime">
                  <mat-label class="matlabel">Ends at<br>
                    <input class="ipcss" onclick="timeNow1(toTime)" id="test1" type="time" [(ngModel)]="toTime"
                      #matInput placeholder="hh:mm a">
                  </mat-label>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="row">
                <div class="cover_div" style="padding-bottom: 13px; margin-top: 20px;">
                  <div class="header_lable">
                     Personal details
                  </div>
                  <div class="content_cover">
                    <div class="row">
                      <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                        <mat-label class="matlabel">First name
                          <input type="text" class="ipcss " [(ngModel)]="firstName" matInput />
                        </mat-label>
                      </div>
                      <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                        <mat-label class="matlabel">Middle name(optional)
                          <input type="text" class="ipcss " [(ngModel)]="middleName" matInput />
                        </mat-label>
                      </div>
                      <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                        <mat-label class="matlabel">Last name
                          <input type="text" class="ipcss " [(ngModel)]="lastName" matInput />
                        </mat-label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="cover_div" style="padding-bottom: 13px; margin-top: 20px;">
                  <div class="header_lable">
                      Delivery details
                  </div>
                  <div class="content_cover">
                    <div class="row">
                      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                        <mat-label class="matlabel">Address 1
                          <input class="ipcss " [(ngModel)]="address1" maxlength="50" required matInput>
                        </mat-label>
                      </div>
                      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                        <mat-label class="matlabel">Address 2
                          <input class="ipcss " [(ngModel)]="address2" maxlength="50" matInput>
                        </mat-label>
                      </div>
                      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                        <mat-label class="matlabel"> location<br>
                          <input type="text" class="ipcss widthappt" required maxlength="50" (keyup)="changeLocation($event)"
                            [(ngModel)]="locationName" matInput [matAutocomplete]="auto" />
                          <mat-autocomplete #auto="matAutocomplete">
                            <mat-option (click)="selectLocation(location)" *ngFor="let location of locationList"
                              [value]="location">
                              {{location}}
                            </mat-option>
                          </mat-autocomplete>
                        </mat-label>
                      </div>
                      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                        <mat-label class="matlabel" style="margin-top:7px"> City<br>
                          <mat-select disableOptionCentering class="ipcss widthappt" required [(ngModel)]="filtCity"
                            (ngModelChange)="changeCity($event)">
                            <mat-option disabled>Select</mat-option>
                            <mat-option *ngFor="let city of cityList" [value]="city.city_id">{{city.city_desc}}
                            </mat-option>
                          </mat-select>
                        </mat-label>
                      </div>
                      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                        <mat-label class="matlabel"> State<br>
                          <mat-select disableOptionCentering class="ipcss widthappt" required [(ngModel)]="filtState"
                            (ngModelChange)="changeState($event)">
                            <mat-option disabled>Select</mat-option>
                            <mat-option *ngFor="let state of stateList" [value]="state.state_id">{{state.state_desc}}
                            </mat-option>
                          </mat-select>
                        </mat-label>
                      </div>
                      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                        <mat-label class="matlabel"> Country<br>
                          <mat-select disableOptionCentering class="ipcss widthappt" [(ngModel)]="filtCountry"
                            (selectionChange)="changeCountry($event)">
                            <mat-option disabled>Select</mat-option>
                            <mat-option *ngFor="let country of countryList" [value]="country.country_desc">
                              {{country.country_desc}}</mat-option>
                          </mat-select>
                        </mat-label>
                      </div>
                      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                        <mat-label class="matlabel">Zipcode<br>
                          <input #matInput class="ipcss  widthappt" [(ngModel)]="zipcode" [value]="zipcode" disabled>
                        </mat-label>
                      </div>
                      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                        <mat-label class="matlabel">Mobile no<br>
                          <input #matInput class="ipcss  widthappt" [(ngModel)]="mobile" disabled>
                        </mat-label>
                      </div>
                      <div class="row" *ngIf=!isHomeDelFlag>
                        <p class="tervys_heading col-6">Order is valid for {{validity}}</p><br>
                        <p class="tervys_heading col-6">Store timings : {{workTime}}</p>
                      </div>
                    </div>
                  </div>
              </div>
              </div>
            </div>
          </div>
          <div class="col-12" style="margin-top: 20px;">
            <div class="table-responsive dig_table_overflow">
              <table *ngIf="medicineList.length" id="tbl" mdbTable datatable [dtOptions]="dtOptions"
                class="table table-nowrap table-sm dataTable bill_create">
                <thead class="tableheading">
                  <tr>
                    <th>Medicine type</th>
                    <th>Medicine name</th>
                    <th>Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let datas of medicineList" class="table_items">
                    <td class="align_left">{{datas.drug_type_name}}</td>
                    <td class="align_left">{{datas.drug_name}}</td>
                    <td class="d-flex justify-content-center">
                      <input type="number" class="ipcss" value="{{datas.act_qty}}"
                        style="width: 71px !important;text-align: right;" #matInput
                        [(ngModel)]="datas.drug_qty" maxlength="3">
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>