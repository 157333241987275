 import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { ipaddress } from '../../../ipaddress';
import { Helper_Class } from '../../../helper_class';
import { Message_data } from 'src/assets/js/Message_data';

@Component({
  selector: 'app-pharma-stock-status-admin',
  templateUrl: './pharma-stock-status-admin.component.html',
  styleUrls: ['./pharma-stock-status-admin.component.scss']
})
export class PharmaStockStatusAdminComponent implements OnInit {
  public locationArray:any = [];
  public loadLocationList:any = [];
  public pharmacyFacID: any;
  public location: any;
  public locationIP = null;
  public stock: any = [];
  public pharmacistID;
  public userInfo;
  public pharmacyID;
  public editbutton:boolean=false;
  public newbutton:boolean=false;
  public deletebutton:boolean=false;
  public printbutton:boolean=false;
  public viewbutton:boolean=false;
  dtOptions: DataTables.Settings = {};
  isDisabled: boolean = false;
  public pagelist:any;
  public sno;
  public usertype:string;
  searchby: any;
  public typeflag: string;
  public patientName: string;
  public searchtext: string;
  public patientid;
  public isadmin;
  public prevPageCount: boolean;
  public nextPageCount: boolean;
  public noOfPages: any;
  public currentPage: any;
  public pageSize;
  public pageNavigate:boolean;
  public clientCount;
  
  
  

  constructor(public http: Http, public routes: ActivatedRoute, public router: Router, public toastr: ToastrService) {
    this.pageSize = 50;
    this.currentPage = 1;
    this.pageNavigate = true
  }
  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 25,
      "lengthMenu": [[25, 50, -1], [25, 50, "All"]],
      order: [[0, 'desc']],
      "columnDefs": [
       // { "orderable": false, "targets": [0] },
      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search supplier or batch no"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    this.userInfo = Helper_Class.getInfo();
    if(this.userInfo.user_type=='pharmacy') {
      this.pharmacyID = this.userInfo.hospitals[0].hptl_clinic_id
    } else {
      this.pharmacyID = this.userInfo.pharma_id;
    }
  
    this.pharmacistID = this.userInfo.user_id;
    this.usertype= this.userInfo.user_type;
    this.isadmin=this.userInfo.is_admin;
    
    
    this.pagelist="25"
    this.navpagelist()
    this.pharmaLocation();

    if(Helper_Class.getmodulelist() != undefined){
      for (var i = 0; i < Helper_Class.getmodulelist().length; i++) {
        if (Helper_Class.getmodulelist()[i].module_id == "58" && Helper_Class.getmodulelist()[i].description =="Stock edit"){
            if(Helper_Class.getmodulelist()[i].edit == "1"){
              this.editbutton=true;
            }
            if(Helper_Class.getmodulelist()[i].create == "1"){ 
              this.newbutton=true;
            }
            if(Helper_Class.getmodulelist()[i].delete == "1"){
              this.deletebutton=true;
            }
            if(Helper_Class.getmodulelist()[i].print == "1"){
              this.printbutton=true;
            }
            if(Helper_Class.getmodulelist()[i].view == "1"){
              this.viewbutton=true;
            }
            
            
        }
       
      }
    }
  }
  pharmaLocation() {
    var send_data;
    if (Helper_Class.getIsAdmin() != undefined) {
      send_data = JSON.stringify({
        hptl_clinic_id: this.pharmacyID,
      });
    } else {
      send_data = JSON.stringify({
        pharmacy_id: this.pharmacyID,
        pharmacist_id: this.pharmacistID
      });
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/pharlocbyid/", send_data, { headers: headers }).subscribe(
      data => {
        var obj = data.json();
        if (data.json().pharma_locations != null) {
          this.locationArray = data.json().pharma_locations;
          for (var i = 0; i < this.locationArray.length; i++) {
            this.loadLocationList.push({
              location_id: this.locationArray[i].pharma_facility_id + " - " + this.locationArray[i].location,
              pharma_facility_id: this.locationArray[i].pharma_facility_id,
            });
            if (i == 0) {
              // this.get_stock(this.locationArray[i].pharma_facility_id);
            }
          }
          if (this.loadLocationList.length != 0) {
            this.location = this.loadLocationList[0].location_id;
          }
        }
      },
      error => { });
  }
  // get_stock(pharmacy_fac_id) {
  //   var send_data;
  //   if (Helper_Class.getIsAdmin() != undefined) {
  //     send_data = JSON.stringify({
  //       hptl_clinic_id: this.pharmacyID,
  //       is_admin: "1"
  //     });
  //   } else {
  //     send_data = JSON.stringify({
  //       pharmacy_id: this.pharmacyID,
  //       pharmacist_id: this.pharmacistID,
  //       is_admin: "1"
  //     });
  //   }
  //   var headers = new Headers();
  //   headers.append('Content-Type', 'application/json');
  //   this.http.post(ipaddress.getIp + "pharmacontrol/gpstock/", send_data,
  //     { headers: headers }).subscribe(
  //       data => {
  //         var obj = data.json();
  //         console.log("STOCK DATA = "+JSON.stringify(obj))
  //         if (obj.key != 0) {
  //           if (obj.stock_onhand != null) {
  //             this.stock = obj.stock_onhand;
  //           }
  //         }
  //       },
  //       error => { });
  // }


  changePageList() {
  
    // if(this.pagelist == "25"){
    //   this.sno="1"
    // }else if(this.pagelist == "50"){
    //   this.sno="51"
    // }else if(this.pagelist == "100"){
    //   this.sno="101"
    // }else if(this.pagelist == "full"){
    //   this.sno="etc"
    // }
    
    var send_data;
    if(this.usertype=="admin" ){
      send_data={
        hptl_clinic_id:this.userInfo.hptl_clinic_id,
        limit:this.pagelist,
        sno:this.sno,
        is_admin: "1"
      }
    }else{
      send_data={
        pharmacy_id:this.pharmacyID,
        limit:this.pagelist,
        sno:this.sno,
        is_admin: "0"
      }
    }
    console.log(" SEND DATA = "+JSON.stringify(send_data))
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "inv/gpsbl", send_data,
      { headers: headers }).subscribe(
        data => {
          var obj = data.json();
          console.log("stock data 2 = "+JSON.stringify(obj))
          if (obj.key != 0) {
            if (obj.stock_onhand != null) {
              this.pageNavigate = obj.stock_count > 50 ? false : true;
              this.clientCount = obj.prod_count
              this.stock=[];
            
              this.stock = obj.stock_onhand;
            }
          }
        },
        error => { });
        
      }
    
      getdatausingname(value){
        if(value.length > 3){
          var send_data
          if(this.usertype=="admin" ){
            if(this.isadmin == "1"){
              send_data={
                pharmacy_id:this.pharmacyID,
                prod_name:value,
                is_admin: "1"
              }

            } else{
              send_data={
                pharmacy_id:this.pharmacyID,
                prod_name:value,
                is_admin: "0"
              }
            }

          } else{
            if(this.isadmin == "1"){
              send_data={
                pharmacy_id:this.pharmacyID,
                prod_name:value,
                is_admin: "1"
              }
            } else{
              send_data={
                pharmacy_id:this.pharmacyID,
                prod_name:value,
                is_admin: "0"
              }
          }
        }
        console.log("data ="+JSON.stringify(send_data))
          var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "inv/gpsbl", send_data,
      { headers: headers }).subscribe(
        data => {
          var obj = data.json();
          console.log("stock data 3 = "+JSON.stringify(obj))
          if (obj.key != 0) {
            if (obj.stock_onhand != null) {
              if (obj.stock_onhand != null) {
                this.stock=[];
                this.stock = obj.stock_onhand;
              }
            }
          }
        },
        error => { });
        
        }else if(value.length ==0 ){
          this.changePageList()
        }
    
      }


      pageLimitnext() {

        if (this.currentPage == this.noOfPages){
          this.nextPageCount = true;}
        else{
        this.currentPage = parseInt(this.currentPage);
        // console.log("check --"+this.currentPage)
        this.pageSize = this.pagelist;
        this.prevPageCount = false;
        this.sno = (parseInt(this.currentPage) * parseInt(this.pagelist)) + 1;
        this.changePageList();

        if (this.currentPage == this.noOfPages)
          this.nextPageCount = true;
    
        
        this.currentPage = this.currentPage + 1;
        }
      }

      pageLimitPrevious() {
        if (this.currentPage == "1"){
          this.prevPageCount = true;}
        else{
          this.currentPage = this.currentPage - 1;
        // console.log("check --"+this.currentPage);
        this.pageSize = this.pagelist;
        if (this.currentPage != 1)
          this.sno = ((parseInt(this.currentPage) - 1) * parseInt(this.pagelist)) + 1;
        else if (this.currentPage == 1)
          this.sno = 1;
    
        // console.log(this.currentLimit);
        this.changePageList();

        this.nextPageCount = false;
        }
      }

      navpagelist() {
        this.currentPage = 1;
        this.sno = 1;
        this.pageSize = this.pagelist;
        this.changePageList();
        console.log(this.stock.length);
        if (this.pagelist == "25") {
          if (this.clientCount > 25) {
            this.pageNavigate = false;
            this.nextPageCount = false;
            this.prevPageCount = false;
          } else {
            this.pageNavigate = false;
            this.nextPageCount = false;
            this.prevPageCount = false;
          }
        } else if (this.pagelist == "50") {
          if (this.clientCount > 50) {
            this.pageNavigate = false;
            this.nextPageCount = false;
            this.prevPageCount = false;
          } else {
            this.pageNavigate = true;
            this.nextPageCount = true;
            this.prevPageCount = true;
          }
        } else if (this.pagelist == "100") {
          if (this.clientCount > 100) {
            this.pageNavigate = false;
            this.nextPageCount = false;
            this.prevPageCount = false;
          } else {
            this.pageNavigate = true;
            this.nextPageCount = true;
            this.prevPageCount = true;
          }
        }
      }
      numberOfPages() {
        this.noOfPages = Math.ceil(this.clientCount / this.pagelist);
        return Math.ceil(this.clientCount / this.pagelist);
        // console.log("client count "+this.clientCount+"...."+this.pagelist)
        // this.noOfPages = Math.floor(this.clientCount / this.pagelist);
        console.log("client count " + this.noOfPages)
    
        // return Math.floor(this.clientCount / this.pagelist);
      }  


  save_data() {
    if (this.isDisabled == false) {
      this.isDisabled = true;
      var update = this.stock;
      var send_data = JSON.stringify({
        items: this.stock,
      });
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "pharmacontrol/spstock/", send_data,
        { headers: headers }).subscribe(
          data => {
            var obj = data.json();
            console.log("data = "+JSON.stringify(obj))
            if (obj.key != 0) {
              this.toastr.success(Message_data.stockUpdSuccess);
            }
            this.isDisabled = false;
          },
          error => { });
    } else {
      this.toastr.error(Message_data.plsWaitUpdStock);
    }
  }




}
