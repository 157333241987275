<div class="row">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white ">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Create Appointment</h5>
                    </div>
                    <div class="headerButtons">
                        <img class="saveimgbtn_inpatinfo" (click)="back()"
                            src="../../../assets/ui_icons/buttons/Back_button.svg" />
                        <!-- <img class="saveimgbtn_inpatinfo" (click)="next()"
                            src="../../../assets/ui_icons/buttons/Next_button.svg" /> -->
                        <img *ngIf="saveFlag" class="saveimgbtn_inpatinfo" (click)="book()" src="../../../assets/ui_icons/buttons/Book_now.svg" />
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>
                <div class="cover_div" style="padding-bottom: 13px;">
                    <div class="header_lable">
                        Personal information
                    </div>
                    <div class="content_cover">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                *ngIf="appNursePhysio == 'nurse'">
                                <mat-label class="matlabel">Appointment Type : </mat-label>&nbsp;
                                <mat-radio-group class="dietradio" labelPosition="before" aria-label="Select an option" 
                                    color="primary" [(ngModel)]="appType">
                                    <mat-radio-button style="width: 70px;" name="apptype" value="New">New
                                    </mat-radio-button>
                                    <mat-radio-button style="width: 70px;" name="apptype" value="Follow-up">Follow-up
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div class="row">
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Appointment For &nbsp;
                                            <mat-select required class="ipcss" name="relationship"
                                                [(ngModel)]="relationship" disableOptionCentering
                                                (ngModelChange)="changeRelation($event)">
                                                <mat-option *ngFor="let relation of relationList"
                                                    value="{{relation.relationship_name}}">
                                                    {{relation.relationship_name}}</mat-option>
                                            </mat-select>
                                        </mat-label>
                                    </div>
                                    <div *ngIf="relativeDetailsFlag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label *ngIf="relativeDetailsFlag" class="matlabel">Relation name<br>
                                            <mat-select disableOptionCentering class="ipcss" required
                                                [(ngModel)]="relationName">
                                                <mat-option disabled>Select</mat-option>
                                                <mat-option *ngFor="let relation of otherRelationList"
                                                    (click)="changeOtherRelation(relation.name)"
                                                    value={{relation.name}}>
                                                    {{relation.name}}</mat-option>
                                            </mat-select>
                                        </mat-label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel">Frist name
                                    <input class="ipcss" matInput type="text" [(ngModel)]="clntFirstName" disabled />
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel">Middle name
                                    <input class="ipcss" matInput type="text" [(ngModel)]="clntMiddleName" disabled />
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel">Last name
                                    <input class="ipcss" matInput type="text" [(ngModel)]="clntLastName" disabled />
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel"> Age (yrs)
                                    <input class="ipcss" matInput type="text" [(ngModel)]="clntAge" disabled />
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel">Blood Group
                                    <input class="ipcss" matInput type="text" [(ngModel)]="clntBldgrp" disabled />
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel">Gender
                                    <input class="ipcss" matInput type="text" [(ngModel)]="clntGender" disabled />
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel">Father / Guardian Name
                                    <input class="ipcss" matInput type="text" [(ngModel)]="clntFatherName" disabled />
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel">Income
                                    <input class="ipcss" matInput type="text" [(ngModel)]="clntIncome" />
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel">Occupation
                                    <input class="ipcss" matInput type="text" [(ngModel)]="clntOccupation" />
                                </mat-label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="cover_div" style="padding-bottom: 13px;">
                    <div class="header_lable">
                        Address details
                    </div>
                    <div class="content_cover">
                        <div class="row">
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel">Address1
                                    <textarea style="height: 80px;" class="ipcss" [(ngModel)]="clntAddr1"
                                        disabled></textarea>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel">Address2
                                    <textarea style="height: 80px;" class="ipcss" [(ngModel)]="clntAddr2"
                                        disabled></textarea>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel">Location
                                    <input class="ipcss" matInput type="text" [(ngModel)]="clntLocation" disabled />
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel">District / City
                                    <input class="ipcss" matInput type="text" [(ngModel)]="clntCity" disabled />
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel">State
                                    <input class="ipcss" matInput type="text" [(ngModel)]="clntState" disabled />
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel">Country
                                    <input class="ipcss" matInput type="text" [(ngModel)]="clntCountry" disabled />
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel">Zipcode
                                    <input class="ipcss" matInput type="text" [(ngModel)]="clntZipcode" disabled />
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel">Contact Number
                                    <input class="ipcss" matInput type="text" [(ngModel)]="clntPhoneNo" disabled />
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel">Symptoms
                                    <input class="ipcss" matInput type="text" [(ngModel)]="clntSymptom" />
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel">Referred By
                                    <input type="text" class="ipcss" [(ngModel)]="referredDoctor"
                                        (keyup)="getReferralData($event)" matInput [matAutocomplete]="auto" />
                                    <mat-autocomplete #auto="matAutocomplete">
                                        <mat-option (click)="selectReferredDoctor(item)" *ngFor="let item of doctorList"
                                            [value]="item">
                                            {{item}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="cover_div" style="padding-bottom: 13px;" *ngIf="!selectDoctorFlag">
                    <div class="header_lable">
                        Doctor list
                    </div>
                    <div class="content_cover">
                        <div class="row" style="padding: 14px;">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <mat-card id="cardcontent">
                                    <mat-card-title id="cardheading">Search by</mat-card-title>
                                    <mat-card-content>
                                        <div class="row" style="padding: 5px;">
                                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                                <mat-label class="matlabel">Country<br>
                                                    <mat-select disableOptionCentering class="ipcss widthappt"
                                                        [(ngModel)]="filtCountry"
                                                        (selectionChange)="changeCountry(filtCountry, '0')">
                                                        <mat-option disabled>Select</mat-option>
                                                        <mat-option *ngFor="let country of clntCountryList"
                                                            value={{country.description}}>
                                                            {{country.description}}</mat-option>
                                                    </mat-select>
                                                </mat-label>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                                <mat-label class="matlabel">State<br>
                                                    <mat-select disableOptionCentering class="ipcss widthappt" required
                                                        [(ngModel)]="filtState"
                                                        (selectionChange)="changeState(filtState, '0')">
                                                        <mat-option disabled>Select</mat-option>
                                                        <mat-option *ngFor="let state of clntStateList"
                                                            value={{state.description}}>
                                                            {{state.description}}</mat-option>
                                                    </mat-select>
                                                </mat-label>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottomy">
                                                <mat-label class="matlabel">City<br>
                                                    <mat-select disableOptionCentering class="ipcss widthappt" required
                                                        [(ngModel)]="filtCity"
                                                        (selectionChange)="changeCity(filtCity, '0')">
                                                        <mat-option disabled>Select</mat-option>
                                                        <mat-option *ngFor="let city of clntCityList"
                                                            value="{{city.description}}">
                                                            {{city.description}}</mat-option>
                                                    </mat-select>
                                                </mat-label>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                                <mat-label class="matlabel">location<br>
                                                    <input type="text" class="ipcss widthappt" required maxlength="50"
                                                        (keyup)="changeLocation(locationName)"
                                                        [(ngModel)]="locationName" matInput [matAutocomplete]="auto" />
                                                    <mat-autocomplete #auto="matAutocomplete">
                                                        <mat-option (click)="selectLocation(location)"
                                                            *ngFor="let location of locationList" [value]="location">
                                                            {{location}}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                </mat-label>
                                            </div>
                                        </div>
                                    </mat-card-content>
                                </mat-card>
                            </div><br>
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="margin:10px">
                                <div *ngIf="physioDoctorList.length == 0">
                                    <p class="nodata">No data found</p>
                                </div>
                            </div>
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                *ngIf="physioDoctorList.length != 0">
                                <table *ngIf="physioDoctorList.length" mdbTable datatable [dtOptions]="dtOptions"
                                    class="table table-nowrap table-sm dataTable">
                                    <thead>
                                        <tr>
                                            <th> </th>
                                            <th>{{typeLable}} name</th>
                                            <th>Hospital name</th>
                                            <th>Location</th>
                                            <th>Gender </th>
                                            <th>Experience </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <!--  -->
                                        <tr *ngFor="let physio of physioDoctorList;let i = index"
                                            (click)="selectDoctor(physio)">
                                            <td>
                                                <img src="{{physio.profile_image}}" onerror="this.src='./././assets/img/default.jpg'" height="40px" width="40px"
                                                    class="img-fluid z-depth-1 rounded-circle mr-1">
                                            </td>
                                            <td class="align_left">{{physio.name}}</td>
                                            <td class="align_left">{{physio.hospital_name}}</td>
                                            <td class="align_left">{{physio.location}}</td>
                                            <td class="align_left">{{physio.gender}}</td>
                                            <td>{{physio.experience}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="cover_div" style="padding-bottom: 13px;" *ngIf="selectDoctorFlag">
                    <div class="header_lable">
                        App details
                    </div>
                    <div class="content_cover">
                        <img src="../../../assets/img/captcha_refresh.png"
                            style="width: 25px; height:25px; float: right;cursor: pointer;" (click)="resetDoctor()" alt="">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div class="row">
                                    <div class="col-2 my-auto">
                                        <div style="width: fit-content; margin: auto;">
                                            <img src="{{profileImg}}" onerror="this.src='./././assets/img/default.jpg'" class="physio_profile_img" width="110px"
                                                height="110px" />
                                        </div>
                                    </div>
                                    <div class="col-9 col-sm-9 col-md-9 col-xl-9 col-lg-9 my-auto">
                                        <div>  {{name}} <sub>  </sub> </div>
                                        <div><mat-label class="matlabel"><b>Work Experience : </b></mat-label> <span>{{workExperience}}</span> </div>
                                        <div> <mat-label class="matlabel"><b>Address : </b></mat-label> <br> <mat-label class="matlabel">{{hospitalName}}</mat-label> <mat-label class="matlabel">,{{hospitalLocation}} </mat-label> </div>
                                        <!-- <div class="row">
                                            <div class="col-6">
                                                <mat-card id="cardcontent_name" class="clscardcontnet_name">
                                                    <mat-card-title id="cardheading">
                                                        <img src="../../../assets/img/doctor.png" onerror="this.src='./././assets/img/default.jpg'"
                                                            style="margin: 11% 5px;" height="30px" width="30px" />
                                                    </mat-card-title>
                                                    <mat-card-content>
                                                        <div class="row" style="padding: 0 0 0 9px;">
                                                            <div class="col-12">
                                                                <mat-label class="matlabel"><b>Name</b></mat-label>
                                                            </div>
                                                            <div class="col-12"><mat-label class="matlabel">{{name}}</mat-label>
                                                            </div>
                                                        </div>
                                                    </mat-card-content>
                                                </mat-card>
                                            </div>
                                            <div class="col-6">
                                                <mat-card id="cardcontent_name" [hidden]="!addressFlag"
                                                    class="clscardcontnet_name">
                                                    <mat-card-title id="cardheading">
                                                        <img src="../../../assets/img/arrow.png"
                                                            style="margin: 41% 5px;" height="30px" width="30px" />
                                                    </mat-card-title>
                                                    <mat-card-content>
                                                        <div class="row" style="padding: 0 0 0 9px;">
                                                            <div class="col-12">
                                                                <mat-label class="matlabel"><b>Address</b></mat-label>
                                                            </div>
                                                            <div class="col-12">
                                                                <mat-label class="matlabel">{{hospitalName}}</mat-label>
                                                            </div>
                                                            <div class="col-12">
                                                                <mat-label class="matlabel">{{hospitalLocation}}
                                                                </mat-label>
                                                            </div>
                                                        </div>
                                                    </mat-card-content>
                                                </mat-card>
                                            </div>
                                            <div class="col-6">
                                                <mat-card id="cardcontent_name">
                                                    <mat-card-title id="cardheading">
                                                        <img src="../../../assets/img/briefcase.png"
                                                            style="margin: 11% 5px;" height="30px" width="30px" />
                                                    </mat-card-title>
                                                    <mat-card-content>
                                                        <div class="row" style="padding: 0 0 0 9px;">
                                                            <div class="col-12">
                                                                <mat-label class="matlabel"><b>Work experience</b></mat-label>
                                                            </div>
                                                            <div class="col-12">
                                                                <mat-label class="matlabel">{{workExperience}}
                                                                </mat-label>
                                                            </div>
                                                        </div>
                                                    </mat-card-content>
                                                </mat-card>
                                            </div>
                                        </div> -->
                                    </div>
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        <div class="row" style="margin: 10px;">
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                <div class="table-responsive dig_table_overflow">
                                                    <table id="card_tbl " class="table table-hover table-dynamic"
                                                        *ngIf="doctorDetailData.length != 0">
                                                        <thead>
                                                            <tr>
                                                                <th><b>Hospital name</b></th>
                                                                <th><b>Location</b></th>
                                                                <th><b>Day</b></th>
                                                                <th><b>Session</b></th>
                                                                <th><b>Starts from</b></th>
                                                                <th><b>Ends at</b></th>
                                                                <th><b>Fee</b></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let doctor of doctorDetailData">
                                                                <td data-th="Hospital Name">{{doctor.hospital}}</td>
                                                                <td data-th="Location">{{doctor.location}}</td>
                                                                <td data-th="Day">{{doctor.day}}</td>
                                                                <td data-th="Session">{{doctor.session}}</td>
                                                                <td data-th="Starts from">{{doctor.available_from}}</td>
                                                                <td data-th="Ends at">{{doctor.available_to}}</td>
                                                                <td data-th="Fee">{{doctor.fees}}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <table id="card_tbl dig_table_overflow"
                                                        class="table table-hover table-dynamic"
                                                        *ngIf="homecareData.length != 0">
                                                        <thead>
                                                            <tr>
                                                                <th><b>Session</b></th>
                                                                <th><b>Time interval</b></th>
                                                                <th><b>Starts from</b></th>
                                                                <th><b>Ends at</b></th>
                                                                <th><b>Fee</b></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let doctor of homecareData">
                                                                <td data-th="Session">{{doctor.Session}}</td>
                                                                <td data-th="Day">{{doctor.Time_Inter}}</td>
                                                                <td data-th="Starts from">{{doctor.Avail_from}}</td>
                                                                <td data-th="Ends at">{{doctor.Avail_to}}</td>
                                                                <td data-th="Fee">{{doctor.Fees}}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        <div class="row" style="margin: 10px;">
                                            <div class="col-6">
                                                <div class="row">
                                                    <div class="col-10">
                                                        <div class="row">
                                                            <div *ngIf="homecare == '0'" class="col-6">
                                                                <mat-label *ngIf="homecare == '0'" class="matlabel1">
                                                                    Hospital name<br>

                                                                    <mat-select class="ipcss widthappt"
                                                                        [(ngModel)]="hospitalId" disableOptionCentering
                                                                        (ngModelChange)="changeHospital(hospitalList)">
                                                                        <mat-option *ngFor="let hospit of hospitalList"
                                                                            value="{{hospit.hospital_id}}">
                                                                            {{hospit.hospname}}</mat-option>
                                                                    </mat-select>
                                                                </mat-label>
                                                            </div>
                                                            <div *ngIf="homecare == '0'"
                                                                class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                                                <mat-label *ngIf="homecare == '0'" class="matlabel">
                                                                    Date<br>
                                                                    <input type="date" class="ipcss_date"
                                                                        min="{{presentdate}}"
                                                                        [(ngModel)]="appointmentDate" (change)="selectAppointmentDate(appointmentDate,'Appointment_Date')"
                                                                        matInput>
                                                                </mat-label>
                                                            </div>
                                                            <div *ngIf="homecare == '1'" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6"
                                                                style="margin-top: 10px;     margin-left: 23px;">
                                                                <mat-label *ngIf="homecare == '1'" class="matlabel">
                                                                    Date<br>
                                                                    <input type="date" class="ipcss_date"
                                                                        min="{{presentdate}}"
                                                                        [(ngModel)]="homecareAppDate" (change)="selectAppointmentDate(homecareAppDate,'Home_App_Date')"
                                                                        matInput>
                                                                </mat-label>
                                                            </div>
                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                                                *ngIf="timeCardFlag && timeList.length !=0">
                                                                <mat-card id="cardcontent">
                                                                    <mat-card-content style="background-color: #fff;">
                                                                        <div
                                                                            *ngFor="let session of timeList;let j=index">
                                                                            <mat-label class="appcreatenw_appttime">
                                                                                {{session.session}}</mat-label>
                                                                            <div class="row">
                                                                                <div class="timediv col-3"
                                                                                    *ngFor="let time of session.time;let i=index ">
                                                                                    <a  (click)="selectAppointmentTime(time,session.session,i)">
                                                                                        <p [id]="'time_'+j+'_'+i"
                                                                                            class="apptime">{{time}}</p>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </mat-card-content>
                                                                </mat-card>
                                                            </div>
                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                                                *ngIf="homecare == '0' && timeList.length == 0"
                                                                style="padding-left: 28px;">
                                                                <div class="nodata">No data found</div>
                                                            </div>
                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                                                *ngIf="timeCardFlag && homecareTimeList.length != 0"
                                                                style="padding-left: 28px;">
                                                                <mat-card id="cardcontent">
                                                                    <mat-card-content style="background-color: #fff;">
                                                                        <div
                                                                            *ngFor="let session of homecareTimeList;let j=index">
                                                                            <mat-label class="appcreatenw_appttime">
                                                                                {{session.session}}</mat-label>
                                                                            <div class="row">
                                                                                <div class="timediv col-3"
                                                                                    *ngFor="let time of session.time;let i=index "
                                                                                    style=" margin-left: 5px;">
                                                                                    <a
                                                                                        (click)="selectAppointmentTime(time,session.session,i)">
                                                                                        <p [id]="'hometime_'+j+'_'+i"
                                                                                            class="apptime">{{time}}</p>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </mat-card-content>
                                                                </mat-card>
                                                            </div>
                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                                                *ngIf="homecare == '1' && homecareTimeList.length == 0"
                                                                style="padding-left: 28px;">
                                                                <div class="nodata">No data found</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-2 my-auto" style="text-align: center;">
                                                        <a (click)="addApptSession()" *ngIf="addSessionFlag"><img src="../../../assets/img/pagination_next.png" style="width: 25px; height:auto;" /></a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="table_cover">
                                                    <div class="col-12">
                                                        <div class="dig_table_overflow">
                                                            <table class="table table-hover table-dynamic"
                                                                id="card_tbl">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Date</th>
                                                                        <th>Session</th>
                                                                        <th>Time</th>
                                                                        <th>Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr
                                                                        *ngFor="let addsession of sessionList;let i=index">
                                                                        <td>{{addsession.date}}</td>
                                                                        <td>{{addsession.session}}</td>
                                                                        <td>{{addsession.dispalyTime}}</td>
                                                                        <td><a (click)="deleteSession(i)"><img src="../../../assets/ui_icons/buttons/trash_icon.svg" class="editDelicon" /></a>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" *ngIf="paymentType">
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Payment mode : </mat-label>
                                        <mat-radio-group class="dietradio" [(ngModel)]="payType" labelPosition="before" 
                                            aria-label="Select an option" color="primary" [(ngModel)]="payType">
                                            <mat-radio-button *ngIf="payCash == '1'" name="paytype" value="Cash" style="padding: 0 8px 0 0px;">Cash
                                            </mat-radio-button>
                                            <mat-radio-button *ngIf="payOnline == '1'" name="paytype" value="Online" checked style="padding: 0 8px 0 0px;">
                                                Online
                                            </mat-radio-button>
                                            <mat-radio-button *ngIf="payInsurance == '1'" name="paytype" value="insurance" style="padding: 0 8px 0 0px;">
                                                Insurance
                                            </mat-radio-button>
                                        </mat-radio-group>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>