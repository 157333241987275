import { Component, OnInit, HostListener, Input } from '@angular/core';
import {Helper_Class} from '../../helper_class';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { Observable, observable, Subscription } from 'rxjs';
import { CasesheetService } from '../casesheet/casesheet.service';
import { DiabeticpopuphistoryComponent } from '../diabeticpopuphistory/diabeticpopuphistory.component';
import { MatDialog } from '@angular/material/dialog';
import { Doc_Helper } from '../Doc_Helper';

@Component({
  selector: 'app-pediatrics-head-cardiovascular',
  templateUrl: './pediatrics-head-cardiovascular.component.html',
  styleUrls: ['./pediatrics-head-cardiovascular.component.css']
})
export class PediatricsHeadCardiovascularComponent implements OnInit {
  public pediaClinicalFlag ="min";
  subscription:Subscription;
  public minflag:boolean=true;
  public header_footer_flag:boolean;
  public head;
  public hearing;
  public vision;
  public nose;
  public mouth;
  public circumference;
  public chest;
  public abdominal;
  public genitalia;
  public extremities;
  public neurologic;
  public skin;
  public gen_appearnce;
  public Cardiohist;
  public Headhist;
  public life_style_id;
  public diab_case_hist_id;
  public head_hidden:boolean;
  public head_heading:boolean;
  public vision_hidden:boolean;
  public hearning_hidden:boolean;
  public nose_hidden:boolean;
  public mouth_hidden:boolean;
  public circum_hidden:boolean;
  public chest_hidden:boolean;
  public cardio_head:boolean;
  public abdominal_examhidden:boolean;
  public genita_hidden:boolean;
  public extremi_hidden:boolean;
  public neuro_hidden:boolean;
  public skin_hidden:boolean;
  public general_hidden:boolean;
  public client:boolean;

  constructor(public dialog:MatDialog,public messageservice:CasesheetService,private http:Http,private routes:ActivatedRoute,private router:Router,public toastr:ToastrService) {
    this.head_hidden=true;
    this.head_heading=true;
    this.vision_hidden=true;
    this.hearning_hidden=true;
    this.nose_hidden=true;
    this.mouth_hidden=true;
    this.circum_hidden=true;
    this.chest_hidden=true;
    this.abdominal_examhidden=true;
    this.cardio_head=true;
    this.genita_hidden=true;  
    this.extremi_hidden=true;
    this.neuro_hidden=true;
    this.skin_hidden=true;
    this.general_hidden=true;
   
   }

  ngOnInit(): void {
    this.subscription = this.messageservice.getCasesheetMessage().subscribe(message => {
      if (message) {
          this.pediaClinicalFlag = message;
      }
    });

    if(Doc_Helper.getAppFlow() == "client" ){
      this.client = true;
    }else{
      this.client = false;
    }

    var getHopitalfieldsRet=Helper_Class.getHopitalfieldsRet();
    if(getHopitalfieldsRet!=undefined){
      for(var i=0; i<getHopitalfieldsRet.length; i++){
        if(getHopitalfieldsRet[i].page_name=="Head & Cardiovascular"){
          if(getHopitalfieldsRet[i].fields!=undefined){
            if(getHopitalfieldsRet[i].fields!=undefined){
              for(var j=0; j<getHopitalfieldsRet[i].fields.length;j++){
                if(getHopitalfieldsRet[i].fields[j].field_name=="Head"){
                  this.head_hidden = false;
                }
                if(getHopitalfieldsRet[i].fields[j].field_name=="Vision"){
                  this.vision_hidden=false;
                }
                if(getHopitalfieldsRet[i].fields[j].field_name=="Hearing"){
                  this.hearning_hidden=false;
                }
                if(getHopitalfieldsRet[i].fields[j].field_name=="Nose"){
                  this.nose_hidden=false;
                }
                if(getHopitalfieldsRet[i].fields[j].field_name=="Mouth & throat"){
                  this.mouth_hidden=false;
                }
                if(getHopitalfieldsRet[i].fields[j].field_name=="Head circumference"){
                  this.circum_hidden=false;
                }
                if( this.head_hidden==false || this.vision_hidden==false ||  this.hearning_hidden==false ||  this.nose_hidden==false ||  this.mouth_hidden==false ||  this.circum_hidden==false){
                  this.head_heading=false;
                }
                if(getHopitalfieldsRet[i].fields[j].field_name=="Chest"){
                  this.chest_hidden=false;
                }
                if(getHopitalfieldsRet[i].fields[j].field_name=="Abdominal examination"){
                  this.abdominal_examhidden=false;
                }
                if(getHopitalfieldsRet[i].fields[j].field_name=="Genitalia"){
                  this.genita_hidden=false;
                }
                if(getHopitalfieldsRet[i].fields[j].field_name=="Extremities"){
                  this.extremi_hidden=false;
                }
                if(getHopitalfieldsRet[i].fields[j].field_name=="Neurologic"){
                  this.neuro_hidden=false;
                }
                if(getHopitalfieldsRet[i].fields[j].field_name=="Skin"){
                  this.skin_hidden=false;
                }
                if(getHopitalfieldsRet[i].fields[j].field_name=="General appearance"){
                  this.general_hidden=false;
                }
                if( this.general_hidden==false || this.skin_hidden==false ||  this.neuro_hidden==false ||  this.extremi_hidden==false ||  this.genita_hidden==false ||  this.abdominal_examhidden==false || this.chest_hidden==false){
                  this.cardio_head=false;
                }
              }
            }
          }
        }
      }
    }
    this.Get_Head_cardio_retrivel();
  }

  //send data
  @HostListener('window:click', ['$event'])
    onclick(event: any): void {
        if(this.client == false){
            this.send_data();
        }
  }
  @HostListener('window:blur', ['$event'])
    onBlur(event: any): void {
        if(this.client == false){
            this.send_data();
        }  }

  send_data() {
    var Pedia_head = null;
    Pedia_head = {
      head: this.head,
      hearing:this.hearing,
      vision:this.vision,
      nose:this.nose,
      mouth_throat:this.mouth,
      head_circum:this.circumference,
      chest:this.chest,
      admonial:this.abdominal,
      genitalia:this.genitalia,
      extermities:this.extremities,
      neurological:this.neurologic,
      skin:this.skin,
      geneal_appearance:this.gen_appearnce
    }
    Helper_Class.setPediaheadcardio(Pedia_head);
    
  }

  //retrivel
  Get_Head_cardio_retrivel(){
    var getpediaRet = Helper_Class.getpediaRet();
    if(getpediaRet != undefined){
      if (getpediaRet.case_hist_id != null) {
        this.diab_case_hist_id = getpediaRet.case_hist_id;
      }
      if (getpediaRet.life_style_id != null) {
        this.life_style_id=getpediaRet.life_style_id;
      }
      if (getpediaRet.head != null) {
        this.head=getpediaRet.head;
      }
      if (getpediaRet.head != null) {
        this.head=getpediaRet.head;
      }
      if (getpediaRet.vision != null) {
        this.vision=getpediaRet.vision;
      }
      if (getpediaRet.hearing != null) {
        this.hearing=getpediaRet.hearing;
      }
      if (getpediaRet.nose != null) {
        this.nose=getpediaRet.nose;
      }
      if (getpediaRet.mouth_throat != null) {
        this.mouth=getpediaRet.mouth_throat;
      }
      if (getpediaRet.head_circum != null) {
        this.circumference=getpediaRet.head_circum;
      }
      if (getpediaRet.chest != null) {
        this.chest=getpediaRet.chest;
      }
      if (getpediaRet.admonial != null) {
        this.abdominal=getpediaRet.admonial;
      }
      if (getpediaRet.genitalia != null) {
        this.genitalia=getpediaRet.genitalia;
      }
      if (getpediaRet.extermities != null) {
        this.extremities=getpediaRet.extermities;
      }
      if (getpediaRet.neurological != null) {
        this.neurologic=getpediaRet.neurological;
      }
      if (getpediaRet.skin != null) {
        this.skin=getpediaRet.skin;
      }
      if (getpediaRet.geneal_appearance != null) {
        this.gen_appearnce=getpediaRet.geneal_appearance;
      }
      this.send_data();
    }
  }

  Head_history_click() {//Head history click
    this.Headhist = "Headhist";
    const dialogRef = this.dialog.open(DiabeticpopuphistoryComponent, {
      width: '700px',
      height: '300px',
      data:{
          Diab_case_hist_id: this.diab_case_hist_id,
          Life_style_id: this.life_style_id,
          History: this.Headhist
        }
    });
  
    dialogRef.afterClosed().subscribe(result => {});
  }
  
   Cardio_history_click() {//Cardio history click
    this.Cardiohist = "Cardiohist";
    const dialogRef1 = this.dialog.open(DiabeticpopuphistoryComponent, {
      width: '700px',
      height: '300px',
      data:{
          Diab_case_hist_id: this.diab_case_hist_id,
          Life_style_id: this.life_style_id,
          History: this.Cardiohist
        }
    });
  
    dialogRef1.afterClosed().subscribe(result => {});
  }
}
