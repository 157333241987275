import { Component, OnInit } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Data, Route, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from 'src/app/providers/common-data.service';
import { Helper_Class } from 'src/app/helper_class';
// import { PharmacyService } from '../pharmacy/pharmacy.service';
import { PharmacyService } from 'src/app/pharma/pharmacy/pharmacy.service';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
import { ipaddress } from '../../../ipaddress';
// import { Helper_Class } from 'src/app/helper_class';
import { pharmacy_helper } from '../../../pharma/Pharmcy_Helper';
import { Message_data } from 'src/assets/js/Message_data';
@Component({
  selector: 'app-pharma-supplier-mst',
  templateUrl: './pharma-supplier-mst.component.html',
  styleUrls: ['./pharma-supplier-mst.component.css']
})
export class PharmaSupplierMstComponent implements OnInit {
  public sendData
  private pharmacyID;
  public supplerIndex;
  supplerMasterArray = [];
  public countryArray = [];
  public cityArray = [];
  public stateArray = [];
  public supplierState: any;
  public supplierCity: any;
  public supplierTelephone: any;
  public supplierMobile: any;
  public countryID: string;
  public supplierName: string;
  public supplierCode: string;
  public suppliergst: string;
  public supplierAddress1: string;
  public supplierAddress2: string;
  public supplierCountry: any;
  public get: string;
  public supplyData;
  public suppID = "0";
  public PharmauserID;
  public add: any;
  public address1;
  public address2;
  public mgfValue;
  tempArray: any = []
  //mdbTable
  dtOptions: DataTables.Settings = {};
  public imei;
  public hospitalid;
  public userinfo;
  public showSupplierFlag: boolean;
  public saveFlag: boolean = true;
  public creditdays;
  public refil_dur:number;
  public invoice_colm:string='';
  public editbutton:boolean=false;
  public newbutton:boolean=false;
  public deletebutton:boolean=false;
  public printbutton:boolean=false;
  public viewbutton:boolean=false;
  public inventoryColumnArray = [];
  public realignColumnsArray = [];
  public data_flag: boolean;
  public pharColumn;
  public drug_lic;
  public acct_no;
  public ifscode;
  public mail_id1;
  public mail_id2;
  public mail_id3;
  public whatsapp_no;
  public bnkname;
  public selectedColumn:any='';
  
  country(arg0: any): any {
    throw new Error("Method not implemented.");
  }

  constructor(public comm_Data: CommonDataService, public toastr: ToastrService, public http: Http, 
    public router: Router, public dialog: MatDialog,public messageservice: MenuViewService) {
      this.data_flag = true;
  }

  ngOnInit(): void {
    this.showSupplierFlag = pharmacy_helper.getSupplier() == true ? false : true;
    this.userinfo = Helper_Class.getInfo();
    if (Helper_Class.getInfo().user_type == 'Admin') {
      this.hospitalid = Helper_Class.getInfo().pharma_id;

    }
    else {
      this.hospitalid = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
   

    }
    this.supplierCountry = this.userinfo.hospitals[0].country;
    this.supplierCity = this.userinfo.hospitals[0].city;
    this.supplierState = this.userinfo.hospitals[0].state;
    this.imei = Helper_Class.getIPAddress();
    this.PharmauserID = this.userinfo.user_id;
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 10,
      "lengthMenu": [[5, 10, 25, 50, -1], [5, 10, 25, 50, "All"]],
      "columnDefs": [
        { "orderable": false, "targets": [8] },
      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search by name, code, or address"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    this.getCountry();
    this.supplerMaster();
    this.inventoryColumnArray.push(
      "Product_name",
      "Batch_no",
      "Package",
      "UOM",
      "Quantity",
      "Free_qty",
      "Rate",
      "MRP",
      "Tax",
      "Discount",
      "Mfg_date",
      "Received_date",
      "Expiry_date",
      "Amount",
    )
    
    if(Helper_Class.getmodulelist() != undefined){
    
      console.log("id"+JSON.stringify(Helper_Class.getmodulelist()))
      for (var i = 0; i < Helper_Class.getmodulelist().length; i++) {
        if (Helper_Class.getmodulelist()[i].module_id == "51" && Helper_Class.getmodulelist()[i].description == "Supplier master"){
         
          if(Helper_Class.getmodulelist()[i].edit == "1"){
              this.editbutton=true;
            }
            if(Helper_Class.getmodulelist()[i].create == "1"){
              this.newbutton=true;
            }
            if(Helper_Class.getmodulelist()[i].delete == "1"){
              this.deletebutton=true;
            }
            if(Helper_Class.getmodulelist()[i].print == "1"){
              this.printbutton=true;
            }
            if(Helper_Class.getmodulelist()[i].view == "1"){
              this.viewbutton=true;
            }
            
            
        }
       
      }
    }
  }

  columnOrder(selectedColumn){
    console.log("selected column  = "+selectedColumn);
    let text = selectedColumn.toString();
     this.invoice_colm += text+",";
    if (!this.realignColumnsArray.includes(selectedColumn)) {
      this.realignColumnsArray.push(selectedColumn)
      this.data_flag = false;
    }
  
  }

  clear_data() {
this.invoice_colm="";
}

  supplerMaster() { //Retrival Table data
    this.supplerMasterArray = [];
    this.supplerIndex = 0;
    this.sendData = JSON.stringify({
      pharmacist_id: this.PharmauserID,
      pharmacy_id: this.hospitalid,
      imei: this.imei,
    })
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'inv/gsupp/', this.sendData,
      { headers: headers })
      .subscribe(
        data => {
          var obj = data.json();
          if (obj.suppliers != null) {
            console.log("SUPPLIER"+JSON.stringify(obj.suppliers))
            for (var i = 0; i < obj.suppliers.length; i++) {
              var add_txt = obj.suppliers[i].address2 != null && obj.suppliers[i].address2 != undefined ? obj.suppliers[i].address1 + "," + obj.suppliers[i].address2 : obj.suppliers[i].address1;
              this.supplerMasterArray.push({
                Index: this.supplerIndex,
                name: obj.suppliers[i].name,
                supp_code: obj.suppliers[i].supp_code,
                gst_no:obj.suppliers[i].gst_no,
                address: add_txt,
                city_desc: obj.suppliers[i].city_desc,
                state_desc: obj.suppliers[i].state_desc,
                country_desc: obj.suppliers[i].country_desc,
                telephone: obj.suppliers[i].telephone,
                mobile: obj.suppliers[i].mobile,
                supp_id: obj.suppliers[i].supp_id,
                city: obj.suppliers[i].city,
                state: obj.suppliers[i].state,
                country: obj.suppliers[i].country,
                creditdays:obj.suppliers[i].creditdays,
                refil_dur:obj.suppliers[i].refil_dur,
                inv_col_order:obj.suppliers[i].inv_col_order,
                license_no:obj.suppliers[i].license_no,
                account_no:obj.suppliers[i].account_no,
                ifsc_code:obj.suppliers[i].ifsc_code,
                email:obj.suppliers[i].email,
                email1:obj.suppliers[i].email1,
                email2:obj.suppliers[i].email2,
                bank_name:obj.suppliers[i].bank_name,
                whatsapp_no:obj.suppliers[i].whatsapp_no

              });
            

              this.supplerIndex++;
            }
            this.tempArray = this.supplerMasterArray;
            console.log(JSON.stringify(this.supplerMasterArray))
          }
        },
        error => { });
  }

  getCountry() { //code for get country
    this.http.get(ipaddress.getIp + "usercontroller/count/").subscribe(
      data => {
        if (data.json().countries != null) {
          this.countryArray = data.json().countries;
          this.getState(this.supplierCountry, '1', this.supplierState);
        }
      },
      error => { });
  }

  getState(country, flag, state) { //code for get state
    this.stateArray = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "usercontroller/state/", JSON.stringify({ country_id: country }), { headers: headers }).subscribe(
      data => {
        if (data.json().states != null) {
          this.stateArray = data.json().states;
          if (flag != null && flag == "1") { // Retrival
            this.stateArray.forEach(stateid => {
              if (stateid.state_id == state) {
                this.supplierState = stateid.state_id;
                this.getCity(this.supplierState, '1');
              }
            });
          }
        }
      },
      error => { });
  }

  getCity(state, flag) { //code for get city
    this.cityArray = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "usercontroller/city/", JSON.stringify({ state_id: state }), { headers: headers }).subscribe(
      data => {
        if (data.json().cities != null) {
          this.cityArray = data.json().cities;
          if (flag != null && flag == '1') {
            this.cityArray.forEach(element => {
              if (element.district_id == this.supplierCity)
                this.supplierCity = element.district_id;
            });
          }
        }
      },
      error => { });
  }

  clickSupplerMaster() { //Add Table
    this.supplyData = [];
    this.saveFlag = true;
    if (this.supplierName == undefined || this.supplierName == "" || this.supplierCode == undefined
      || this.supplierCode == "" || this.supplierAddress1 == undefined || this.supplierAddress1 == ""
      || this.supplierCountry == undefined || this.supplierCountry == "" || this.supplierState == undefined
      || this.supplierState == undefined || this.supplierCity == undefined || this.supplierCity == undefined) {
      this.toastr.error(Message_data.mandatory);
    }else if(this.suppliergst == undefined || this.suppliergst == ""){
      this.toastr.error("Fill the GST number is empty")
    } 
    else {
      var sup_flag = true;
      if (this.supplerMasterArray.length != 0) {
        for (var i = 0; i < this.supplerMasterArray.length; i++) {
          if (this.supplerMasterArray[i].supp_code == this.supplierCode) {
            sup_flag = false;
            break;
          }
        }
      }
      var add_txt = undefined;
      if (sup_flag == true) {
        if (this.supplierAddress2 != undefined && this.supplierAddress2 != "") {
          add_txt = this.supplierAddress2;
        }
        
        if(this.mail_id1 != undefined && this.mail_id2 != undefined && this.mail_id3 != undefined ){
          this.supplyData = ({
          name: this.supplierName,
          supp_code: this.supplierCode,
          gst_no:this.suppliergst,
          license_no:this.drug_lic,
          whatsapp_no:this.whatsapp_no,
          bank_name:this.bnkname,
          account_no:this.acct_no,
          ifsc_code:this.ifscode,
          email:this.mail_id1,
          email1:this.mail_id2,
          email2:this.mail_id3,
          address1: this.supplierAddress1,
          address2: add_txt,
          country: this.supplierCountry,
          state: this.supplierState,
          city: this.supplierCity,
          telephone: this.supplierTelephone,
          mobile: this.supplierMobile,
          pharmacy_id: this.hospitalid,
          supp_id: this.suppID,
          creditdays:this.creditdays,
          refil_dur:this.refil_dur,
          inv_col_order:this.invoice_colm,
        });
      }
      else if(this.mail_id1 != undefined && this.mail_id2 != undefined){
        this.supplyData = ({
          name: this.supplierName,
          supp_code: this.supplierCode,
          gst_no:this.suppliergst,
          license_no:this.drug_lic,
          account_no:this.acct_no,
          ifsc_code:this.ifscode,
          email:this.mail_id1,
          email1:this.mail_id2,
          address1: this.supplierAddress1,
          address2: add_txt,
          country: this.supplierCountry,
          state: this.supplierState,
          city: this.supplierCity,
          telephone: this.supplierTelephone,
          mobile: this.supplierMobile,
          pharmacy_id: this.hospitalid,
          supp_id: this.suppID,
          creditdays:this.creditdays,
          refil_dur:this.refil_dur,
          inv_col_order:this.invoice_colm,
          whatsapp_no:this.whatsapp_no,
          bank_name:this.bnkname,
        });
      }else if(this.mail_id1 != undefined && this.mail_id3 != undefined){
        this.supplyData = ({
          name: this.supplierName,
          supp_code: this.supplierCode,
          gst_no:this.suppliergst,
          license_no:this.drug_lic,
          account_no:this.acct_no,
          ifsc_code:this.ifscode,
          email:this.mail_id1,
          email1:this.mail_id3,
          address1: this.supplierAddress1,
          address2: add_txt,
          country: this.supplierCountry,
          state: this.supplierState,
          city: this.supplierCity,
          telephone: this.supplierTelephone,
          mobile: this.supplierMobile,
          pharmacy_id: this.hospitalid,
          supp_id: this.suppID,
          creditdays:this.creditdays,
          refil_dur:this.refil_dur,
          inv_col_order:this.invoice_colm,
          whatsapp_no:this.whatsapp_no,
          bank_name:this.bnkname,
        });
      }
      else{
        this.supplyData = ({
          name: this.supplierName,
          supp_code: this.supplierCode,
          gst_no:this.suppliergst,
          license_no:this.drug_lic,
          account_no:this.acct_no,
          ifsc_code:this.ifscode,
          email:this.mail_id1,
          address1: this.supplierAddress1,
          address2: add_txt,
          country: this.supplierCountry,
          state: this.supplierState,
          city: this.supplierCity,
          telephone: this.supplierTelephone,
          mobile: this.supplierMobile,
          pharmacy_id: this.hospitalid,
          supp_id: this.suppID,
          creditdays:this.creditdays,
          refil_dur:this.refil_dur,
          bank_name:this.bnkname,
          whatsapp_no:this.whatsapp_no,
          inv_col_order:this.invoice_colm,

        });

      }
      console.log("data = "+JSON.stringify(this.supplyData))
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp + "inv/ssupp/", this.supplyData, { headers: headers }).subscribe(
          data => {
            console.log(" response = "+JSON.stringify(data))
            if (data.json().key == "1") {
              this.toastr.success(Message_data.suppDetAddedSuccess);

              this.saveFlag = true;
              if (pharmacy_helper.getSupplier() == true) {
                if (pharmacy_helper.getProductMst() != undefined) {
                  pharmacy_helper.getProductMst().supp_id = data.json().supp_id;
                  this.messageservice.sendMessage("product");
                }
              }
              // this.saveFlag=false;
              this.supplierName = "";
              this.supplierCode = "";
              this.suppliergst="";
              this.supplierAddress1 = "";
              this.supplierAddress2 = "";
              this.supplierTelephone = "";
              this.supplierMobile = "";
              this.supplierCountry = "";
              this.supplierState = "";
              this.supplierCity = "";
              this.refil_dur = parseInt("");
              this.invoice_colm="";
              this.drug_lic="";
              this.acct_no="";
              this.ifscode="";
              this.mail_id1="";
              this.mail_id2="";
              this.mail_id3="";
              this.creditdays="";
              this.whatsapp_no="";

              this.supplerMaster();

            } else {
              this.saveFlag = true;
              this.toastr.error(Message_data.unableToSave);
            }
          },
          error => {
            this.saveFlag = true;
            this.toastr.error(Message_data.unableToSave);
          });
      }
    }
  }

  editSuppler(data) {
    this.invoice_colm=undefined;
    this.supplierState="";
    for (var i = 0; i < this.supplerMasterArray.length; i++) {
      if (this.supplerMasterArray[i].Index == data) {
        console.log(" supplier master array "+this.supplerMasterArray[i])
        
        var flag = true;
        var coun_id = null;
        this.suppID = this.supplerMasterArray[i].supp_id;
        console.log("SUPPLIER DATA = "+JSON.stringify(this.supplerMasterArray[i]))
        if (this.suppID != "0") {
          var satte_txt = this.supplerMasterArray[i].state;
          var city_txt = this.supplerMasterArray[i].city;
          var supp_add = this.supplerMasterArray[i].address.split(',');
          this.supplierName = this.supplerMasterArray[i].name;
          this.supplierCode = this.supplerMasterArray[i].supp_code;
          this.suppliergst = this.supplerMasterArray[i].gst_no;
          this.creditdays = this.supplerMasterArray[i].creditdays;
          this.supplierAddress1 = supp_add[0];
          this.supplierAddress2 = supp_add[1];
          this.drug_lic = this.supplerMasterArray[i].license_no;
          this.supplierTelephone = this.supplerMasterArray[i].telephone;
          this.supplierMobile = this.supplerMasterArray[i].mobile;
          this.drug_lic = this.supplerMasterArray[i].license_no,
          this.acct_no=this.supplerMasterArray[i].account_no,
          this.ifscode=this.supplerMasterArray[i].ifsc_code,
          this.mail_id1=this.supplerMasterArray[i].email,
          this.mail_id2=this.supplerMasterArray[i].email1,
          this.mail_id3 = this.supplerMasterArray[i].email2,
          this.refil_dur=this.supplerMasterArray[i].refil_dur;
          this.bnkname=this.supplerMasterArray[i].bank_name;
          this.whatsapp_no=this.supplerMasterArray[i].whatsapp_no;
          if(this.supplerMasterArray[i].inv_col_order != undefined){
          this.invoice_colm = this.supplerMasterArray[i].inv_col_order;
          var lengofinvoice=this.invoice_colm.length;
          var lastString = this.invoice_colm.trim().split(' ').pop();
          var lastLetter = lastString.charAt(lastString.length - 1);
        }
          if(lastLetter == ","){
            this.invoice_colm=this.supplerMasterArray[i].inv_col_order;
          } else {
            this.invoice_colm += this.supplerMasterArray[i].inv_col_order+',';
          }

          for (var j = 0; j < this.countryArray.length; j++) {
            if (this.countryArray[j].country_id == this.supplerMasterArray[i].country) {
              this.supplierCountry = this.countryArray[j].country_id;
              coun_id = this.countryArray[j].country_id;
              break;
            }
          }
          var headers = new Headers();
          headers.append('Content-Type', 'application/json');
          this.http.post(ipaddress.getIp + "usercontroller/state/", JSON.stringify({
            country_id: coun_id
          }), { headers: headers }).subscribe(
            data => {
              var obj = JSON.parse(data["_body"]);
              this.stateArray = [];
              this.cityArray = [];
              this.stateArray = obj.states;
              console.log("state array = "+JSON.stringify(obj))
              for (var j = 0; j < this.stateArray.length; j++) {
                if (this.stateArray[j].state_id == satte_txt) {
                 this.supplierState = this.stateArray[j].state_id;
                  var headers = new Headers();
                  headers.append('Content-Type', 'application/json');
                  this.http.post(ipaddress.getIp + "usercontroller/city/", JSON.stringify({
                    state_id: this.stateArray[j].state_id
                  }), { headers: headers }).subscribe(
                    data => {
                      var obj = JSON.parse(data["_body"]);
                      this.cityArray = [];
                      this.cityArray = obj.cities;
                      for (var j = 0; j < this.cityArray.length; j++) {
                        if (this.cityArray[j].district_id == city_txt) {
                          this.supplierCity = this.cityArray[j].district_id;
                          break;
                        }
                      }
                    },
                    error => { });
                  break;
                }
              }
            },
            error => { });
          this.supplerMasterArray.splice(i, 1);
        }
        
      }
    }
  }

  reset() {
    this.supplierName = "";
    this.supplierCode = "";
    this.suppliergst="";
    this.supplierAddress1 = "";
    this.supplierAddress2 = "";
    this.supplierCountry = "";
    this.supplierState = "";
    this.supplierCity = "";
    this.supplierTelephone = "";
    this.supplierMobile = "";
    this.supplerMaster();
  }
  
  back() {
    this.messageservice.sendMessage("product");
  }
}
