import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { CommonDataService } from '../../providers/common-data.service';
import { Http, Headers } from '@angular/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { ToastrService } from 'ngx-toastr';
import { Date_Formate } from '../../../assets/js/common.js';
import { Router, ActivatedRoute } from '@angular/router';
import { ClientViewService } from '../clientview/client-view.service';
import { Client_Helper } from '../Client_helper';
import { Helper_Class } from '../../helper_class';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';

@Component({
  selector: 'app-doctor-appointment-conform',
  templateUrl: './doctor-appointment-conform.component.html',
  styleUrls: ['./doctor-appointment-conform.component.scss']
})
export class DoctorAppointmentConformComponent implements OnInit {

  public homecareFlag: Boolean = false;
  public apptBookUrl: string;
  public dataForApptBook;

  public hospitalList = [];
  public clientList = [];
  public hospDoctDetailData: any;
  public clientDetailData: any;
  public relationName: string;
  public treatType;
  public speciality: string;
  public hospitalName: string;
  public hospitalLocation: string;
  public hospitalCity: string;
  public appointmentDate: string;
  public appointmentSession: string;
  public appType: string;
  public clnt_af_app_time: string;
  public appTypeName: string;
  public homecareData = [];
  public sendHomecareData = [];
  public timeOfAppt: string;
  public sendAppType: string;
  public sendSplId: string;
  public nursePhysioAppFlag: Boolean;
  public homecare: string;
  public homecareHospFlag: Boolean;
  public bookFlag:boolean;

  constructor(public messageservice: ClientViewService, public datepipe: DatePipe, public gservice: CommonDataService, public http: Http, public routes: ActivatedRoute, public router: Router, public toastr: ToastrService) {
  }

  ngOnInit(): void {
    this.apptBookUrl = ipaddress.getIp + "appointment/booking";
    this.hospitalList = Client_Helper.getPhysio_hospital_id();;
    this.clientList = this.gservice.get_appoint_book_data();
    this.bookFlag = true;
    this.clientDetailData = this.gservice.get_appoint_client_data();
    this.hospDoctDetailData = this.gservice.get_appoint_doctor_data();
    this.relationName = this.clientDetailData.relation[0].relationship_name;
    this.appTypeName = this.hospDoctDetailData.app_type;
    this.homecare = Helper_Class.getTreatmentList().homecare;
    
    if(this.homecare == undefined)
      this.homecare="0";
    
    if (this.homecare != "0") {
      this.homecareFlag = true;
      this.homecareHospFlag = true;

      if (this.hospDoctDetailData.homecare_data != null) {
        this.homecareData = this.hospDoctDetailData.homecare_data;
        for (var i = 0; i < this.homecareData.length; i++) {
          var newdate = Date_Formate(this.homecareData[i].date);
          this.sendHomecareData.push({
            date: newdate,
            session: encrypt_decript.Encript(this.homecareData[i].session).toString(),
            app_time: this.homecareData[i].visit_Time,
          });
        }
      }
    } else {
      this.homecareFlag = false;
      this.hospitalName = this.hospDoctDetailData.hosp_name;
      this.hospitalLocation = this.hospDoctDetailData.hosp_locat;
      this.hospitalCity = this.hospDoctDetailData.hosp_city;
      this.appointmentDate = Date_Formate(this.hospDoctDetailData.date);
      this.appointmentSession = this.hospDoctDetailData.session;
    }
  }

  book() {// App Booking
    var valid_flag = false;

    if (this.speciality == "Pediatrics") {
      if (16 < parseInt(this.clientDetailData.clnt_age)) {
        this.toastr.error(Message_data.splOnlyForChild);
        valid_flag = true;
      }
    } else if (this.speciality == "Gynecology") {
      if (this.clientDetailData.clnt_gender == "Male") {
        this.toastr.error(Message_data.splOnlyForwomen);
        valid_flag = true;
      }
    }

    if (valid_flag == false) {
      var Provider = "doctor";
      if (this.hospDoctDetailData.tokenortime != "Token") {
        if (this.hospDoctDetailData.tokenortime != undefined) {
          var time_split = this.hospDoctDetailData.tokenortime.split(" ");
          this.timeOfAppt = time_split[0] + ":00";
        }
      } else {
        this.timeOfAppt = "1";
      }

      this.sendSplId = (this.hospDoctDetailData.speciality != null) ? this.hospDoctDetailData.speciality.specialization_id : "1";
      this.sendAppType = (this.clientDetailData.app_type == "New") ? "1" : "2";

      if (this.clientDetailData.clnt_age != null) 
        var client_app_age = this.clientDetailData.clnt_age;

      if (this.clientDetailData.clnt_blood_id != null) 
        var clnt_blood_id = this.clientDetailData.clnt_blood_id;

      if (this.clientDetailData.clnt_symptom != null) 
        var clnt_symtom_data = (this.clientDetailData.clnt_symptom);

      if (this.clientDetailData.clnt_fg_name != null) 
        var clnt_fatgad_name = encrypt_decript.Encript(this.clientDetailData.clnt_fg_name).toString();

      if (this.clientDetailData.clnt_occupation != null) 
        var clnt_fgoccup_data = encrypt_decript.Encript(this.clientDetailData.clnt_occupation).toString();

      if (this.clientDetailData.clnt_income != null) 
        var clnt_fgincome_data = encrypt_decript.Encript(this.clientDetailData.clnt_income).toString();

      if (this.clientDetailData.clnt_mname != null) 
        var clnt_middle_name = encrypt_decript.Encript(this.clientDetailData.clnt_mname).toString();

      if (this.clientDetailData.clnt_dob != null) 
        var client_send_dob = this.clientDetailData.clnt_dob;

      if (this.clientDetailData.clnt_address2 != null) 
        var client_send_address2 = encrypt_decript.Encript(this.clientDetailData.clnt_address2).toString();

      if (this.clientDetailData.sub_rel_id != null) 
        var client_sub_rel_id = this.clientDetailData.sub_rel_id;

      if (this.hospDoctDetailData.treatement_type != null) 
        var medicare_id = this.hospDoctDetailData.treatement_type.medicare_id;

      var sendHomecareData = [];
      sendHomecareData = (this.sendHomecareData.length != 0) ? this.sendHomecareData : undefined;

      var fname;
      if(this.clientDetailData.clnt_fname != undefined)
        fname = encrypt_decript.Encript(this.clientDetailData.clnt_fname).toString();

      var lname;
      if(this.clientDetailData.clnt_lname != undefined)
        lname = encrypt_decript.Encript(this.clientDetailData.clnt_lname).toString();

      var gender;
      if(this.clientDetailData.clnt_gender != undefined)
        gender = encrypt_decript.Encript(this.clientDetailData.clnt_gender).toString();

      var mobile;
      if(this.clientDetailData.clnt_cont_number != undefined)
        mobile = encrypt_decript.Encript(this.clientDetailData.clnt_cont_number).toString();

      var addr1;
      if(this.clientDetailData.clnt_address1 != undefined)
        addr1 = encrypt_decript.Encript(this.clientDetailData.clnt_address1).toString();

      var zipcode;
      if(this.clientDetailData.clnt_zipcode != undefined)
        zipcode = encrypt_decript.Encript(this.clientDetailData.clnt_zipcode).toString();

      var session;
      if(this.hospDoctDetailData.session != undefined)
        session = encrypt_decript.Encript(this.hospDoctDetailData.session).toString();

      var location;
      if(this.hospDoctDetailData.hosp_locat != undefined)
        location = encrypt_decript.Encript(this.hospDoctDetailData.hosp_locat).toString();

      var city;
      if(this.hospDoctDetailData.hosp_city != undefined)
        city = encrypt_decript.Encript(this.hospDoctDetailData.hosp_city).toString();

      var relation_id;
      for(var i=0;i<this.clientDetailData.relation.length;i++)
        relation_id = this.clientDetailData.relation[i].relation_id;

      this.dataForApptBook = {
        prov_id: this.hospDoctDetailData.doc_id,
        provider: Provider,
        client_id: this.gservice.Client_login_detail_data.Client_Id,
        relation_id: relation_id,
        sub_relation_id: client_sub_rel_id,
        // Client
        first_name: fname,
        middle_name: clnt_middle_name,
        last_name: lname,
        gender: gender,
        age: client_app_age,
        dob: client_send_dob,
        blood_group: clnt_blood_id,
        mem_mobile: mobile,
        symptom: clnt_symtom_data,
        guardian: clnt_fatgad_name,
        occupation: clnt_fgoccup_data,
        income: clnt_fgincome_data,
        app_type: this.sendAppType,
        rel_address1: addr1,
        rel_address2: client_send_address2,
        rel_country: this.clientDetailData.clnt_country,
        rel_state: this.clientDetailData.clnt_state,
        rel_city: this.clientDetailData.clnt_city,
        rel_location: this.clientDetailData.clnt_location,
        rel_zipcode: zipcode,
        // Doctor
        medicare_id: medicare_id,
        specialization_id: this.sendSplId,
        session: session,
        date: this.hospDoctDetailData.date,
        token: this.hospDoctDetailData.token_flag,
        time: this.timeOfAppt,
        hptl_id: this.hospDoctDetailData.hosp_id,
        location: location,
        city: city,
        home_care: this.homecare,
        home_app_date: sendHomecareData, // Home app date
      };
      this.bookAppt();
    }
  }
  
  bookAppt() {
    this.bookFlag=false;
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.apptBookUrl, JSON.stringify(this.dataForApptBook), { headers: headers }).subscribe(
      data => {
        if (data.json().status == "1") {
          this.toastr.success(data.json().result);
            if(this.homecare != "0"){
              Client_Helper.setHomecare("1");
              Client_Helper.setHomecare_nurse_physio("");
            } else {
              Client_Helper.setHomecare("0");
              Client_Helper.setHomecare_nurse_physio("");
            }
            this.messageservice.sendMessage("doctor");
        } else {
          this.bookFlag=true;
          this.toastr.error(data.json().result);
        }
      },error => {
        this.toastr.error(error.json().result);
        this.bookFlag=true;
      });
  }
  
  myCallbackFunction = function (_params) {// callback...
    return new Promise((resolve, reject) => {
      resolve(_params);
    });
  }
 
  back() {
    var send_data ={
      callback: this.myCallbackFunction,
    }
    this.router.navigateByUrl("/doctorsearch");
  }


}
