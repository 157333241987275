<div class="container-fluid g-0" style="height: 100%;"  >
    <div class="warpper_div">
        <div class="asied_section" #asied_section_width>
            <section id="aside_section">
                <div class="logopanel" id="logo" #logo>
                    <h1>
                        <a (click)="menuNav('appointment');"></a>
                    </h1>
                </div>
                <div class="menu_list">
                    <div class="menu menu_active" id="appointment" [hidden]="appointmentData"
                        (click)="menuNav('appointment');">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/appointment.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsiedFlag  && minimizedAsiedFlag !== undefined">
                            <span>Appointments</span>
                        </div>
                    </div>
                    <div class="menu" id="tests" [hidden]="test" (click)="menuNav('tests');">
                        <div class="menu_icon"> <i style="transform: rotate(-55deg);" class="fa fa-puzzle-piece"></i>
                        </div>
                        <div class="menu_title" *ngIf="!minimizedAsiedFlag  && minimizedAsiedFlag !== undefined">
                            <span>Tests</span><span style="float: right;padding-right: 7px;"> <i
                                    class="fa fa-angle-right"></i></span>
                        </div>
                    </div>
                    <div class="menu" id="report_upload" [hidden]="reportFlag" (click)="menuNav('report_upload');">
                        <div class="menu_icon"><i class="icon-menu"><img
                                    src="../../assets/global/images/appointment.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsiedFlag  && minimizedAsiedFlag !== undefined">
                            <span>Test Report</span><span style="float: right;padding-right: 7px;"> <i
                                    class="fa fa-angle-right"></i></span></div>
                        <ul *ngIf="subMenuFlag" class="nav-children">
                            <li (click)="menuNav('pending');"><a id="nav-a"><i class="fa fa-angle-right"></i>
                                    Pending</a></li>
                            <li (click)="menuNav('rejected');"><a><i class="fa fa-angle-right"></i> Rejected</a></li>
                            <li (click)="menuNav('completed');"><a><i class="fa fa-angle-right"></i>
                                    Completed</a></li>
                        </ul>
                    </div>
                    <div class="menu" id="report_approval" [hidden]="repAppFlag" (click)="menuNav('report_approval');">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/doctor.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsiedFlag  && minimizedAsiedFlag !== undefined">
                            <span>Report
                                Approval</span><span style="float: right;padding-right: 7px;"> <i
                                    class="fa fa-angle-right"></i></span></div>
                    </div>
                    <!-- <div class="menu" id="nephrology_readings" (click)="menuNav('nephrology_readings');">
                        <div class="menu_icon"><i class="icon-menu"><img
                                    src="../../assets/global/images/permission.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsiedFlag  && minimizedAsiedFlag !== undefined">
                            <span>Nephrology Readings</span><span style="float: right;padding-right: 7px;"> <i
                                    class="fa fa-angle-right"></i></span></div>
                    </div> -->
                    <div class="menu" id="diabetics_readings" [hidden]="diabFlag"
                        (click)="menuNav('diabetics_readings');">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/billing.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsiedFlag  && minimizedAsiedFlag !== undefined">
                            <span>Diabetics Readings</span><span style="float: right;padding-right: 7px;"> <i
                                    class="fa fa-angle-right"></i></span>
                        </div>
                    </div>
                    <div class="menu" id="billing" [hidden]="diagBillFlag" (click)="menuNav('billing');">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/billing.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsiedFlag  && minimizedAsiedFlag !== undefined">
                            <span>Billing</span><span style="float: right;padding-right: 7px;"> <i
                                    class="fa fa-angle-right"></i></span>
                        </div>
                    </div>
                    <div class="menu" id="users" [hidden]="userHidden" (click)="menuNav('users');">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/home.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsiedFlag  && minimizedAsiedFlag !== undefined">
                            <span>Users</span><span style="float: right;padding-right: 7px;"> <i
                                    class="fa fa-angle-right"></i></span>
                        </div>
                    </div>
                    <div class="menu" id="dashboard" (click)="menuNav('dashboard');">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/dashboard.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsiedFlag  && minimizedAsiedFlag !== undefined">
                            <span>Dashboard</span><span style="float: right;padding-right: 7px;"> <i
                                    class="fa fa-angle-right"></i></span>
                        </div>
                        <ul *ngIf="dashSubMenuFlag" class="nav-children">
                            <li (click)="menuNav('patient_list');"><a id="nav-a"><i class="fa fa-angle-right"></i>
                                    Patient List</a></li>
                            <li (click)="menuNav('reports');"><a><i class="fa fa-angle-right"></i> Reports</a></li>
                        </ul>
                    </div>
                    <!-- <div class="menu" id="listPackage" (click)="menuNav('listPackage');">
                        <div class="menu_icon"> <i class="icon-menu"><img src="../../assets/global/images/doctor.svg"/></i></div>
                        <div class="menu_title" > <span>Packages</span><span style="float: right;padding-right: 7px;"> <i class="fa fa-angle-right"></i></span></div>
                    </div>
                    <div class="menu" id="clientPackageList" (click)="menuNav('clientPackageList');">
                        <div class="menu_icon"> <i class="icon-menu"><img src="../../assets/global/images/doctor.svg"/></i></div>
                        <div class="menu_title" > <span>Subscribed User </span><span style="float: right;padding-right: 7px;"> <i class="fa fa-angle-right"></i></span></div>
                    </div> -->
                    <div [hidden]="reference_video" class="menu" id="reference_video" (click)="menuNav('reference_video');">
                        <div class="menu_icon"> <i class="icon-menu"><img src="../../assets/global/images/reference_video.png" /></i></div>
                        <div class="menu_title" > <span> Reference video</span><span style="float: right;padding-right: 7px;"> <i class="fa fa-angle-right"></i></span></div>
                    </div>
                    <div class="menu" id="help" (click)="menuNav('help');">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/help.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsiedFlag  && minimizedAsiedFlag !== undefined">
                            <span>Help</span><span style="float: right;padding-right: 7px;"> <i
                                    class="fa fa-angle-right"></i></span>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div class="right_content_section" #right_section_width>
            <section id="headar_section">
                <div class="header-left" style="position: relative;">
                    <div class="topnav">
                        <div class="openbtn_desktop" (click)="minimizing()"><i class="fas fa-bars"></i></div>
                        <div class="openbtn" (click)="openMenumodel()"><i class="fas fa-bars"></i></div>
                        <div class="hospital_logo">
                            <img class="hospital_logo" src="{{hospitalLogo}}"
                                onerror="this.src='../../../assets/img/default_hosp_logo.png';" alt="user image">
                        </div>
                        <!-- <i class="far fa-bell"></i> 
                        <i class="far fa-paper-plane"></i>-->
                        <div class="hospital_name">{{hospitalName}}</div>
                        <div class="bell"></div>
                        <div class="share"></div>
                        <div class="doctor_profile_pc" data-bs-toggle="modal" data-bs-target="#exampleModal"><img
                                _ngcontent-ore-c119="" src="{{profileIMG}}"
                                onerror="this.src='./././assets/img/default.jpg'"> </div>
                        <div class="doctor_name" data-bs-toggle="modal" data-bs-target="#exampleModal">
                            {{userName}}&nbsp;{{userLastName}}</div>
                        <!-- Modal -->
                        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                            aria-hidden="true">
                            <div class="modal-dialog profile_dialog">
                                <div class="modal-content">
                                    <div class="modal-header" style="display: inline-block;text-align: center">
                                        <!-- src="{{profile_image}}" -->
                                        <div class="modal-title" id="exampleModalLabel"><img class="model_profile"
                                                src="{{profileIMG}}" onerror="this.src='./././assets/img/default.jpg'">
                                        </div>
                                    </div>
                                    <div class="modal-body">
                                        <div class="menu_div" (click)="menuModelAction('diagnosis_home');"> <i  class="far fa-user"></i> <span class="header_model_menu">My Profile</span> </div>
                                        <div class="menu_div" (click)="menuModelAction('changePassword');"><i class="fas fa-cog"></i><span class="header_model_menu">Change Password</span></div>
                                        <div class="menu_div" (click)="menuModelAction('logout');" style="border-bottom: none;"><i class="fas fa-sign-out-alt"></i><span class="header_model_menu">Log Out</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="body_section">
                <div class="body_warpper">
                    <div class="card shadow-lg">
                        <div [ngSwitch]="displayPage">
                            <div *ngSwitchCase="'appointment'">
                                <app-diagnmosis-appointments></app-diagnmosis-appointments>
                            </div>
                            <div *ngSwitchCase="'tests'">
                                <app-diag-test-home></app-diag-test-home>
                            </div>
                            <div *ngSwitchCase="'nephrology_readings'">
                                <app-nephrologyreadings></app-nephrologyreadings>
                            </div>
                            <div *ngSwitchCase="'diabetics_readings'">
                                <app-diabeticsreading></app-diabeticsreading>
                            </div>
                            <div *ngSwitchCase="'billing'">
                                <app-diagbilllist></app-diagbilllist>
                            </div>
                            <div *ngSwitchCase="'pending'">
                                <app-diagnosis-report-upload></app-diagnosis-report-upload>
                            </div>
                            <div *ngSwitchCase="'rejected'">
                                <app-diagnosis-modify-report></app-diagnosis-modify-report>
                            </div>
                            <div *ngSwitchCase="'report_upload_edit'">
                                <app-diagnosis-report-edit></app-diagnosis-report-edit>
                            </div>
                            <div *ngSwitchCase="'completed'">
                                <app-diagnosis-complete-report></app-diagnosis-complete-report>
                            </div>
                            <div *ngSwitchCase="'report_upload_view'">
                                <app-diagnosis-report-detailview></app-diagnosis-report-detailview>
                            </div>
                            <div *ngSwitchCase="'diagnosisview'">
                                <app-diagnosisappointmentsview></app-diagnosisappointmentsview>
                            </div>
                            <div *ngSwitchCase="'nephrologyreadingviewpage'">
                                <app-nephrologyreadingviewpage></app-nephrologyreadingviewpage>
                            </div>
                            <div *ngSwitchCase="'diagnosis_home'">
                                <app-diagnosis-home></app-diagnosis-home>
                            </div>
                            <div *ngSwitchCase="'users'">
                                <app-diagnosis-user-list></app-diagnosis-user-list>
                            </div>
                            <div *ngSwitchCase="'users_view'">
                                <app-diagnosis-user-view></app-diagnosis-user-view>
                            </div>
                            <div *ngSwitchCase="'report_approval'">
                                <app-diagnosis-report-approval></app-diagnosis-report-approval>
                            </div>
                            <div *ngSwitchCase="'report_approval_detailed'">
                                <app-diagnosis-report-approval-detailedview> </app-diagnosis-report-approval-detailedview>
                            </div>
                            <div *ngSwitchCase="'help'">
                                <app-diagnosis-help></app-diagnosis-help>
                            </div>
                            <div *ngSwitchCase="'diagtestadd'">
                                <app-diag-test-add></app-diag-test-add>
                            </div>
                            <div *ngSwitchCase="'diabeticsreading'">
                                <app-diabeticsreading></app-diabeticsreading>
                            </div>
                            <div *ngSwitchCase="'diagbillcreate'">
                                <app-diagbillcreate></app-diagbillcreate>
                            </div>
                            <div *ngSwitchCase="'diagbilldetailview'">
                                <app-diagbilldetailview></app-diagbilldetailview>
                            </div>
                            <div *ngSwitchCase="'diag_app_create'">
                                <app-diag-app-create></app-diag-app-create>
                            </div>
                            <div *ngSwitchCase="'patient_list'">
                                <app-diag-patient-list></app-diag-patient-list>
                            </div>
                            <div *ngSwitchCase="'reports'">
                                <app-diag-reports></app-diag-reports>
                            </div>
                            <div *ngSwitchCase="'billedit'">
                                <app-diagbilledit></app-diagbilledit>
                            </div>
                            <div *ngSwitchCase="'reference_video'">
                                <app-reference-video></app-reference-video>
                            </div>
                            <div *ngSwitchCase="'createPackage'">
                                <app-create-package></app-create-package>
                            </div>
                            <div *ngSwitchCase="'listPackage'">
                                <app-list-package></app-list-package>
                            </div>  
                            <div *ngSwitchCase="'clientPackageList'">
                                <app-user-package-list></app-user-package-list>
                            </div>
                            <div *ngSwitchCase="'createUserPackage'">
                                <app-create-package></app-create-package>
                            </div>
                            <div *ngSwitchCase="'diagPackageCreate'">
                                <app-diag-package-create></app-diag-package-create>
                            </div>
                            
                            <div *ngSwitchDefault="'appointment'">
                                <!-- <app-diagnosis-home></app-diagnosis-home> -->
                                <app-diagnmosis-appointments></app-diagnmosis-appointments>
                            </div>
                            
                        </div>
                        <!-- <div id="content_div" (sendData)="menuNav($event)"></div> -->
                    </div>
                </div>
            </section>
            <footer id="footer_section">
                <div class="footer_left">Copyright © 2021 All rights reserved </div>
                <div class="footer_right">
                    <p class="pull-right sm-pull-reset">
                        <span>
                            <a href="#" class="m-r-10">Support</a> | <a href="#" class="m-l-10 m-r-10">Terms of use</a>
                            | <a href="#" class="m-l-10">Privacy Policy</a>
                        </span>
                    </p>
                </div>
            </footer>
        </div>
    </div>
</div>