<div mat-dialog-title class="popup_border_icd" style="position: relative;">
    <h5 class="header_txt">Teeth mapping</h5>
    <div mat-dialog-actions>
        <!-- <button mat-icon-button  class="btnclose"> -->
        <!-- <span (click)="ClosePopup()" id="close_icd">X</span> -->
        <img src="../../../assets/img/close_icon.png" (click)="ClosePopup()" width="20px" height="20px"
            id="close_icd" />
    </div>
</div>
<div mat-dialog-content style="height: 386px;">
    <div class="popup_box">
        <!-- <div mat-dialog-actions style="float: right;" [hidden]="saveflag">
        <a >
          <button [mat-dialog-close]="selectedList" cdkFocusInitial mat-button>
            <img src="../../../assets/ui_icons/buttons/Done_button.svg" class="saveimgbtn_inpatinfo" />
          </button></a>&nbsp;
      </div><br>  
        
        

   -->
        <div class="container">


            <div [hidden]="TeethNo_18_28">
                <div class="row">

                    <div class="col-12">
                        <div style="margin: 0 auto; width: fit-content;">

                            <img id="Main_Image" src="{{PopupImage}}" usemap="#Thirdmolor_reverse_map" />
                            <map name="Thirdmolor_reverse_map">
                                <area shape="poly" id="poly0"
                                    coords="163,77,180,77,191,81,181,101,177,123,173,146,165,151,146,146,133,145,119,149,116,149,114,123,123,100,135,89"
                                    (click)="Upper_Data_click('1')">
                                <area shape="poly" id="poly1"
                                    coords="116,167,127,157,141,154,152,160,165,163,174,186,175,201,166,216,147,220,127,223,113,224"
                                    (click)="Upper_Data_click('2')">
                                <area shape="poly" id="poly2"
                                    coords="114,233,135,228,148,225,157,225,165,232,167,252,159,268,144,271,140,270"
                                    (click)="Upper_Data_click('3')">
                                <area shape="poly" id="poly3"
                                    coords="175,264,171,237,177,215,188,194,197,183,209,180,227,182,242,190,238,234,230,262,208,267"
                                    (click)="Upper_Data_click('4')">
                                <area shape="poly" id="poly4"
                                    coords="235,179,222,176,202,161,184,150,179,143,185,114,193,98,199,87,207,85,220,89,229,113,242,131"
                                    (click)="Upper_Data_click('5')">
                                <area shape="poly" id="poly5" coords="179,180,175,165,175,153,181,152,194,157,197,170"
                                    (click)="Upper_Data_click('6')">
                            </map>
                        </div>
                    </div>

                </div>
                <br />
                <div class="dental_align">
                    <img src="../../../assets/ui_icons/buttons/Done_button.svg" class="saveimgbtn_inpatinfo" width="100" height="40"
                        (click)="dismiss()">
                </div>
            </div>
            <div [hidden]="TeethNo_17_27">
                <div class="row">

                    <div class="col-12">
                        <div style="margin: 0 auto; width: fit-content;">
                            <img id="Img1" src="{{PopupImage}}" usemap="#Secondmolor_reverse_map" />
                            <map name="Secondmolor_reverse_map">
                                <area shape="poly" id="Area1"
                                    coords="163,82,182,82,193,85,183,93,168,99,158,94,147,91,143,90,141,89"
                                    (click)="Upper_Data_click('1')">
                                <area shape="poly" id="Area2"
                                    coords="162,133,164,120,164,107,155,100,138,98,127,103,119,116,111,127,107,140,104,151,97,163,94,172,120,175,137,174,151,173,163,162,168,143"
                                    (click)="Upper_Data_click('2')">
                                <area shape="poly" id="Area3"
                                    coords="88,176,109,182,135,184,142,203,155,223,160,249,150,265,128,259,101,250,89,214"
                                    (click)="Upper_Data_click('3')">
                                <area shape="poly" id="Area4"
                                    coords="193,167,181,146,169,128,171,108,178,96,196,93,214,103,230,127,238,147,237,171,226,181"
                                    (click)="Upper_Data_click('4')">
                                <area shape="poly" id="Area5"
                                    coords="167,267,169,254,166,233,163,217,173,204,182,184,190,174,204,180,215,189,225,191,228,221,228,239,206,269,185,273"
                                    (click)="Upper_Data_click('5')">
                                <area shape="poly" id="Area6"
                                    coords="147,203,143,178,152,166,165,158,173,152,179,163,175,181,171,197,162,205"
                                    (click)="Upper_Data_click('6')">
                            </map>
                        </div>
                    </div>

                </div>
                <br />
                <div class="dental_align">
                    <img src="../../../assets/ui_icons/buttons/Done_button.svg" class="saveimgbtn_inpatinfo" width="100" height="40"
                        (click)="dismiss()">
                </div>
            </div>
            <div [hidden]="TeethNo_16_26">
                <div class="row">

                    <div class="col-12">
                        <div style="margin: 0 auto; width: fit-content;">
                            <img id="Img2" src="{{PopupImage}}" usemap="#firstmolor_reverse_map" />
                            <map name="firstmolor_reverse_map">
                                <area shape="poly" id="Area7"
                                    coords="110,143,107,115,117,103,131,92,146,80,156,75,170,75,184,77,185,89,182,103,181,114,181,128,178,134,173,139,160,144,139,150,125,150"
                                    (click)="Upper_Data_click('1')">
                                <area shape="poly" id="Area8"
                                    coords="101,155,112,158,128,159,141,157,146,165,147,176,140,192,131,200,114,201,98,201,90,201"
                                    (click)="Upper_Data_click('2')">
                                <area shape="poly" id="Area9"
                                    coords="89,208,106,208,136,203,157,201,164,209,164,227,169,243,176,249,178,257,152,257,131,256,105,247,96,230,94,231"
                                    (click)="Upper_Data_click('3')">
                                <area shape="poly" id="Area10"
                                    coords="189,254,179,244,173,231,173,212,176,205,186,200,195,192,200,186,201,180,212,186,222,188,229,191,232,210,226,230"
                                    (click)="Upper_Data_click('4')">
                                <area shape="poly" id="Area11"
                                    coords="204,173,198,160,188,149,181,143,184,128,190,110,189,92,194,83,198,77,208,81,222,96,236,121,240,143,238,165,233,179,230,183"
                                    (click)="Upper_Data_click('5')">
                                <area shape="poly" id="Area12"
                                    coords="148,196,151,184,153,173,153,161,151,156,157,153,166,152,174,152,181,154,190,162,192,178,186,190,175,200"
                                    (click)="Upper_Data_click('6')">
                            </map>
                        </div>
                    </div>

                </div>
                <br />
                <div class="dental_align">
                    <img src="../../../assets/ui_icons/buttons/Done_button.svg" class="saveimgbtn_inpatinfo" width="100" height="40"
                        (click)="dismiss()">
                </div>
            </div>
            <div [hidden]="TeethNo_15_25">
                <div class="row">

                    <div class="col-12">
                        <div style="margin: 0 auto; width: fit-content;">
                            <img id="Img3" src="{{PopupImage}}" usemap="#secondpremolor_reverse_map" />
                            <map name="secondpremolor_reverse_map">
                                <area shape="poly" id="Area13"
                                    coords="108,191,102,173,115,146,129,124,140,109,163,97,188,95,193,114,183,135,174,156,173,144,172,161,161,176,164,168,152,185,127,191"
                                    (click)="Upper_Data_click('1')">
                                <area shape="poly" id="Area14"
                                    coords="96,183,104,199,117,202,137,202,145,210,140,238,125,258,104,252,96,250,87,218"
                                    (click)="Upper_Data_click('2')">
                                <area shape="poly" id="Area16"
                                    coords="137,261,147,237,150,218,165,218,180,204,186,195,187,192,199,198,216,210,227,220,203,245,171,263"
                                    (click)="Upper_Data_click('3')">
                                <area shape="poly" id="Area17"
                                    coords="226,212,215,204,198,189,187,172,182,159,190,142,197,121,209,110,215,123,230,142"
                                    (click)="Upper_Data_click('4')">
                                <area shape="poly" id="Area18"
                                    coords="155,216,148,200,148,183,160,176,179,167,185,179,173,202"
                                    (click)="Upper_Data_click('5')">
                            </map>
                        </div>
                    </div>

                </div>
                <br />
                <div class="dental_align">
                    <img src="../../../assets/ui_icons/buttons/Done_button.svg" class="saveimgbtn_inpatinfo" width="100" height="40"
                        (click)="dismiss()">
                </div>
            </div>
            <div [hidden]="TeethNo_14_24">
                <div class="row">

                    <div class="col-12">
                        <div style="margin: 0 auto; width: fit-content;">
                            <img id="Img4" src="{{PopupImage}}" usemap="#firstpremolor_reverse_map" />
                            <map name="firstpremolor_reverse_map">
                                <area shape="poly" id="Area19"
                                    coords="134,249,119,242,104,230,89,210,86,198,92,187,104,181,111,175,116,172,134,181,148,203,153,225"
                                    (click)="Upper_Data_click('1')">
                                <area shape="poly" id="Area20"
                                    coords="88,182,88,168,90,146,97,120,103,103,119,91,137,84,151,84,127,144,119,167"
                                    (click)="Upper_Data_click('2')">
                                <area shape="poly" id="Area21"
                                    coords="140,120,141,110,148,97,158,89,169,101,180,120,189,129,196,135,179,138,158,133"
                                    (click)="Upper_Data_click('3')">
                                <area shape="poly" id="Area22"
                                    coords="150,189,137,174,133,160,135,141,139,127,147,136,159,140,182,144,192,143,202,151,208,166,209,178,163,195"
                                    (click)="Upper_Data_click('4')">
                                <area shape="poly" id="Area23"
                                    coords="148,252,161,231,156,214,159,199,180,197,199,186,209,189,210,216,188,242"
                                    (click)="Upper_Data_click('5')">
                            </map>
                        </div>
                    </div>

                </div>
                <br />
                <div class="dental_align">
                    <img src="../../../assets/ui_icons/buttons/Done_button.svg" class="saveimgbtn_inpatinfo" width="100" height="40"
                        (click)="dismiss()">
                </div>
            </div>
            <div [hidden]="TeethNo_13_23">
                <div class="row">

                    <div class="col-12">
                        <div style="margin: 0 auto; width: fit-content;">
                            <img id="Img5" src="{{PopupImage}}" usemap="#canine_reverse_map" />
                            <map name="canine_reverse_map">
                                <area shape="poly" id="Area25"
                                    coords="120,162,167,153,201,155,228,157,243,161,235,179,201,183,157,184,131,187,102,183,82,179,72,177,60,174,54,171,50,170,99,170,64,168,95,169,61,167,98,171"
                                    (click)="Upper_Data_click('1')">
                                <area shape="poly" id="Area26"
                                    coords="94,189,123,195,139,201,162,197,194,194,234,188,220,208,216,229,205,246,188,252,171,263,149,256,130,240,107,220,88,206,74,195,60,182,86,188,72,186,60,183"
                                    (click)="Upper_Data_click('2')">
                            </map>
                        </div>
                    </div>

                </div>
                <br />
                <div class="dental_align">
                    <img src="../../../assets/ui_icons/buttons/Done_button.svg" class="saveimgbtn_inpatinfo" width="100" height="40"
                        (click)="dismiss()">
                </div>
            </div>
            <div [hidden]="TeethNo_12_22">
                <div class="row">

                    <div class="col-12">
                        <div style="margin: 0 auto; width: fit-content;">
                            <img id="Img6" src="{{PopupImage}}" usemap="#laterel_insisor_reverse_map" />
                            <map name="laterel_insisor_reverse_map">
                                <area shape="poly" id="Area31"
                                    coords="30,162,46,149,70,144,100,141,136,137,162,137,184,137,205,137,221,140,235,145,247,154,247,164,246,171,235,179,218,182,203,176,140,170,134,176,117,180,102,182,82,184,63,184,47,181,33,175"
                                    (click)="Upper_Data_click('1')">
                                <area shape="poly" id="Area32"
                                    coords="79,191,97,189,122,189,138,181,158,178,174,183,197,187,222,187,238,184,236,195,228,210,219,226,206,241,188,254,177,257,166,257,145,252,125,242,116,235,112,225"
                                    (click)="Upper_Data_click('2')">
                            </map>
                        </div>
                    </div>

                </div>
                <br />
                <div class="dental_align">
                    <img src="../../../assets/ui_icons/buttons/Done_button.svg" class="saveimgbtn_inpatinfo" width="100" height="40"
                        (click)="dismiss()">
                </div>
            </div>
            <div [hidden]="TeethNo_11_21">
                <div class="row">

                    <div class="col-12">
                        <div style="margin: 0 auto; width: fit-content;">
                            <img id="Img7" src="{{PopupImage}}" usemap="#central_reverse_map" />
                            <map name="central_reverse_map">
                                <area shape="poly" id="Area37"
                                    coords="40,202,41,186,50,180,71,177,95,176,111,172,129,171,161,168,196,172,215,175,244,179,255,184,256,191,245,199,159,194,79,205,53,211"
                                    (click)="Upper_Data_click('1')">
                                <area shape="poly" id="Area38"
                                    coords="65,217,92,215,128,208,160,202,195,205,215,208,234,212,221,229,201,243,179,255,144,262,112,255,90,248,79,241,67,222,63,220"
                                    (click)="Upper_Data_click('2')">
                            </map>
                        </div>
                    </div>

                </div>
                <br />
                <div class="row">
                    <div class="col-12">
                        <div style="margin: 0 auto; width: fit-content;">
                            <img src="../../../assets/ui_icons/buttons/Done_button.svg" class="saveimgbtn_inpatinfo" width="100"
                                height="40" (click)="dismiss()">
                        </div>

                    </div>
                </div>
            </div>

            <!-- lower teeth-->


            <div [hidden]="TeethNo_48_38">
                <div class="row">


                    <div class="col-12">
                        <div style="margin: 0 auto; width: fit-content;">
                            <img id="Img8" src="{{PopupImage}}" usemap="#Thirdmolor_map" />
                            <map name="Thirdmolor_map">
                                <area shape="poly" id="Area15"
                                    coords="116,67,123,49,132,41,137,34,150,34,159,33,166,45,164,65,156,73,157,76"
                                    (click)="Lower_Data_click('1')">
                                <area shape="poly" id="Area24"
                                    coords="112,75,125,77,144,82,155,86,171,86,174,106,170,123,168,139,152,145,131,150,119,139"
                                    (click)="Lower_Data_click('2')">
                                <area shape="poly" id="Area27"
                                    coords="119,154,142,157,161,150,169,157,178,175,184,205,190,225,159,220,126,211,112,178"
                                    (click)="Lower_Data_click('3')">
                                <area shape="poly" id="Area28"
                                    coords="180,156,196,143,213,131,236,123,241,140,241,169,230,193,214,214,197,217"
                                    (click)="Lower_Data_click('4')">
                                <area shape="poly" id="Area29"
                                    coords="236,113,213,123,185,108,175,83,171,49,178,31,197,34,216,34,226,36,237,56,242,77"
                                    (click)="Lower_Data_click('5')">
                                <area shape="poly" id="Area30" coords="173,144,175,133,176,118,187,125,201,132,189,144"
                                    (click)="Lower_Data_click('6')">
                            </map>
                        </div>
                    </div>

                </div>
                <br />
                <div class="dental_align">
                    <img src="../../../assets/ui_icons/buttons/Done_button.svg" class="saveimgbtn_inpatinfo" width="100" height="40"
                        (click)="dismiss()">
                </div>
            </div>
            <div [hidden]="TeethNo_47_37">
                <div class="row">

                    <div class="col-12">
                        <div style="margin: 0 auto; width: fit-content;">
                            <img id="Img9" src="{{PopupImage}}" usemap="#Secondmolor_map" />
                            <map name="Secondmolor_map">
                                <area shape="poly" id="Area33"
                                    coords="172,27,148,31,125,35,115,45,106,55,98,74,92,95,92,116,92,122,106,122,92,127,122,120,145,118,160,99,155,87"
                                    (click)="Lower_Data_click('1')">
                                <area shape="poly" id="Area34"
                                    coords="94,136,115,127,139,130,156,145,169,160,166,181,163,198,138,201,127,201,108,178"
                                    (click)="Lower_Data_click('2')">
                                <area shape="poly" id="Area35"
                                    coords="144,212,153,208,164,205,169,202,182,208,189,215,178,221,159,222"
                                    (click)="Lower_Data_click('3')">
                                <area shape="poly" id="Area36"
                                    coords="173,193,173,173,173,156,186,143,192,133,206,125,216,119,225,115,237,126,239,144,232,175,216,194,199,202,191,210"
                                    (click)="Lower_Data_click('4')">
                                <area shape="poly" id="Area39"
                                    coords="188,125,179,104,171,88,166,73,167,44,168,28,184,25,192,28,200,29,219,41,222,63,230,75,227,100,219,114"
                                    (click)="Lower_Data_click('5')">
                                <area shape="poly" id="Area40"
                                    coords="174,148,158,135,151,131,141,115,143,101,143,95,154,94,159,95,172,110,180,135"
                                    (click)="Lower_Data_click('6')">
                            </map>
                        </div>
                    </div>

                </div>
                <br />
                <div class="dental_align">
                    <img src="../../../assets/ui_icons/buttons/Done_button.svg" class="saveimgbtn_inpatinfo" width="100" height="40"
                        (click)="dismiss()">
                </div>
            </div>
            <div [hidden]="TeethNo_46_36">
                <div class="row">

                    <div class="col-12">
                        <div style="margin: 0 auto; width: fit-content;">
                            <img id="Img10" src="{{PopupImage}}" usemap="#firstmolor_map" />
                            <map name="firstmolor_map">
                                <area shape="poly" id="Area41"
                                    coords="99,84,92,85,95,68,105,51,117,45,133,40,151,41,170,40,181,41,171,56,166,73,167,86,160,95"
                                    (click)="Lower_Data_click('1')">
                                <area shape="poly" id="Area42"
                                    coords="103,141,101,130,97,114,92,100,92,97,107,98,121,99,134,101,140,106,144,116,151,131,148,139,141,142"
                                    (click)="Lower_Data_click('2')">
                                <area shape="poly" id="Area43"
                                    coords="108,180,108,165,110,155,121,151,136,150,144,149,154,153,166,155,173,161,182,179,183,199,183,216,175,226,159,226,146,220,109,187"
                                    (click)="Lower_Data_click('3')">
                                <area shape="poly" id="Area44"
                                    coords="193,223,190,202,188,188,183,169,181,158,193,148,197,139,201,128,210,125,222,122,228,117,236,128,240,147,241,164,230,192,207,223"
                                    (click)="Lower_Data_click('4')">
                                <area shape="poly" id="Area45"
                                    coords="201,121,197,111,186,96,174,91,172,77,174,69,178,55,187,51,194,46,204,52,213,60,225,71,229,86,231,104,227,110"
                                    (click)="Lower_Data_click('5')">
                                <area shape="poly" id="Area46"
                                    coords="153,143,155,132,153,114,152,108,157,103,166,103,176,103,187,111,195,129,190,135,174,145,187,142,164,146,160,149"
                                    (click)="Lower_Data_click('6')">
                            </map>
                        </div>
                    </div>

                </div>
                <br />
                <div class="dental_align">
                    <img src="../../../assets/ui_icons/buttons/Done_button.svg" class="saveimgbtn_inpatinfo" width="100" height="40"
                        (click)="dismiss()">
                </div>
            </div>
            <div [hidden]="TeethNo_45_35">
                <div class="row">

                    <div class="col-12">
                        <div style="margin: 0 auto; width: fit-content;">
                            <img id="Img11" src="{{PopupImage}}" usemap="#Secondpremolor_map" />
                            <map name="Secondpremolor_map">
                                <area shape="poly" id="Area47"
                                    coords="93,110,108,100,115,100,134,104,156,106,147,80,137,56,132,42,120,42,104,49,91,60,87,75,87,67"
                                    (click)="Lower_Data_click('1')">
                                <area shape="poly" id="Area48"
                                    coords="101,126,109,109,127,104,135,106,147,115,156,124,166,134,180,143,180,152,186,164,191,184,186,207,168,208,138,191,117,162"
                                    (click)="Lower_Data_click('2')">
                                <area shape="poly" id="Area49"
                                    coords="184,143,191,161,197,179,207,195,218,178,226,157,232,130,229,99,225,90,193,115"
                                    (click)="Lower_Data_click('3')">
                                <area shape="poly" id="Area50"
                                    coords="190,108,207,97,220,86,223,80,213,68,199,55,183,40,165,39,148,40,140,41,133,44,150,81"
                                    (click)="Lower_Data_click('4')">
                                <area shape="poly" id="Area51"
                                    coords="177,134,157,121,151,103,146,90,162,85,170,96,182,108,187,119"
                                    (click)="Lower_Data_click('5')">
                            </map>
                        </div>
                    </div>

                </div>
                <br />
                <div class="dental_align">
                    <img src="../../../assets/ui_icons/buttons/Done_button.svg" class="saveimgbtn_inpatinfo" width="100" height="40"
                        (click)="dismiss()">
                </div>
            </div>
            <div [hidden]="TeethNo_44_34">
                <div class="row">

                    <div class="col-12">
                        <div style="margin: 0 auto; width: fit-content;">
                            <img id="Img12" src="{{PopupImage}}" usemap="#firstpremolor_map" />
                            <map name="firstpremolor_map">
                                <area shape="poly" id="Area52"
                                    coords="136,53,146,61,153,70,148,93,139,110,131,120,125,128,115,128,109,120,97,116,89,113,88,93,101,74,122,54"
                                    (click)="Lower_Data_click('1')">
                                <area shape="poly" id="Area53"
                                    coords="84,118,103,128,115,140,126,146,130,168,135,188,141,205,148,220,154,220,134,222,112,207,102,195"
                                    (click)="Lower_Data_click('2')">
                                <area shape="poly" id="Area54"
                                    coords="139,181,156,172,168,168,180,166,195,164,188,176,175,192,165,207,155,214,144,196"
                                    (click)="Lower_Data_click('3')">
                                <area shape="poly" id="Area55"
                                    coords="139,174,137,160,132,143,135,127,153,114,163,107,170,111,197,120,203,121,204,130,206,144,201,158"
                                    (click)="Lower_Data_click('4')">
                                <area shape="poly" id="Area56"
                                    coords="154,101,157,82,158,68,152,53,161,48,179,56,193,64,200,74,209,92,211,115"
                                    (click)="Lower_Data_click('5')">
                            </map>
                        </div>
                    </div>

                </div>
                <br />
                <div class="dental_align">
                    <img src="../../../assets/ui_icons/buttons/Done_button.svg" class="saveimgbtn_inpatinfo" width="100" height="40"
                        (click)="dismiss()">
                </div>
            </div>
            <div [hidden]="TeethNo_43_33">
                <div class="row">

                    <div class="col-12">
                        <div style="margin: 0 auto; width: fit-content;">
                            <img id="Img13" src="{{PopupImage}}" usemap="#Canine_map" />
                            <map name="Canine_map">
                                <area shape="poly" id="Area57"
                                    coords="141,104,182,108,204,110,235,116,222,99,216,85,210,65,197,54,181,45,161,41,141,51,122,67,106,83,90,93,81,104,73,110,59,121,58,123"
                                    (click)="Lower_Data_click('1')">
                                <area shape="poly" id="Area58"
                                    coords="62,137,102,133,131,145,163,149,200,148,235,143,244,141,239,128,227,120,171,117,127,115,87,122,68,128,52,131,50,134"
                                    (click)="Lower_Data_click('2')">
                            </map>
                        </div>
                    </div>

                </div>
                <br />
                <div class="dental_align">
                    <img src="../../../assets/ui_icons/buttons/Done_button.svg" class="saveimgbtn_inpatinfo" width="100" height="40"
                        (click)="dismiss()">
                </div>
            </div>
            <div [hidden]="TeethNo_42_32">
                <div class="row">

                    <div class="col-12">
                        <div style="margin: 0 auto; width: fit-content;">
                            <img id="Img14" src="{{PopupImage}}" usemap="#Laterel_insisor_map" />
                            <map name="Laterel_insisor_map">
                                <area shape="poly" id="Area60"
                                    coords="80,114,91,102,99,93,109,82,119,69,135,60,147,54,166,48,180,48,193,52,204,62,213,71,219,83,229,96,238,108,239,120,212,117,179,119,170,125,157,129,144,125,125,120,114,117"
                                    (click)="Lower_Data_click('1')">
                                <area shape="poly" id="Area59"
                                    coords="139,167,152,168,164,167,184,167,212,166,234,159,248,149,247,140,246,131,232,126,222,122,197,129,170,133,149,133,134,129,116,124,88,122,68,122,49,127,38,129,32,133,30,142,28,142,38,149,49,157"
                                    (click)="Lower_Data_click('2')">
                            </map>
                        </div>
                    </div>

                </div>
                <br />
                <div class="dental_align">
                    <img src="../../../assets/ui_icons/buttons/Done_button.svg" class="saveimgbtn_inpatinfo" width="100" height="40"
                        (click)="dismiss()">
                </div>
            </div>
            <div [hidden]="TeethNo_41_31">
                <div class="row">

                    <div class="col-12">
                        <div style="margin: 0 auto; width: fit-content;">
                            <img id="Img15" src="{{PopupImage}}" usemap="#Central_map" />
                            <map name="Central_map">
                                <area shape="poly" id="Area61"
                                    coords="112,128,162,131,196,133,223,125,249,121,258,109,241,99,176,104,130,101,92,98,61,94,40,99,43,118,71,124"
                                    (click)="Lower_Data_click('1')">
                                <area shape="poly" id="Area62"
                                    coords="62,84,88,86,123,93,166,98,199,93,231,90,219,68,199,55,177,46,146,40,105,46,79,58"
                                    (click)="Lower_Data_click('2')">
                            </map>
                        </div>
                    </div>

                </div>
                <br />
                <div class="dental_align">
                    <img src="../../../assets/ui_icons/buttons/Done_button.svg" class="saveimgbtn_inpatinfo" width="100" height="40"
                        (click)="dismiss()">
                </div>
            </div>

            <!--end lower teeth-->




            <br>
            <br>
        </div>
    </div>