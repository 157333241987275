import { Component, OnInit, ElementRef,Inject } from '@angular/core';
// import { ipaddress.video_ip, video_port } from '../../../assets/js/ipaddress';
import Peer from 'peerjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { encrypt_decript } from 'src/assets/js/Encription_Decription';
import { Doc_Helper } from '../Doc_Helper';
import { VideocallComponent } from '../videocall/videocall.component';
import { ipaddress } from '../../ipaddress' ;
@Component({
  selector: 'app-video-link',
  templateUrl: './video-link.component.html',
  styleUrls: ['./video-link.component.scss']
})
export class VideoLinkComponent implements OnInit {
  public videolink;
  public videolink_paste;
  public videolink1;
  public textboxvalue;
  loginId: string;
  calleeId: string;
  public generate:boolean = true;
  public readonly:boolean = false;

  constructor(public dialog: MatDialog,private elRef: ElementRef,@Inject(MAT_DIALOG_DATA) public data: any ,public dialogRef: MatDialogRef<VideoLinkComponent>) { 
    dialogRef.disableClose = true;

  }

  ngOnInit(): void {
    var userid=encrypt_decript.Encript(this.data.login_id);
    var docid=encrypt_decript.Encript(this.data.callee_id);
    this.videolink = ipaddress.video_ip + "/" + userid + "/" + docid;  
  }

  copyToClipboard() {
    var textBox =  this.elRef.nativeElement.querySelector("#myvalue");
    textBox.select();
    this.textboxvalue=  textBox.select();
    
    // this.videolink1 = this.textboxvalue;
    document.execCommand("copy");
    this.readonly = true;
  }

  videolinkpage(){
    this.videolink1 =this.videolink;
    if( this.videolink1 != ' ' && this.videolink1 != null && this.videolink1 != undefined) {
      this.videolink_paste=this.videolink;
    }
    var link:boolean;
    if(this.videolink1 !=""){
        link = true;
    }else{
      link = false;
    }

    Doc_Helper.setVideoLink(this.videolink_paste);
    
    const dialogRef1 = this.dialog.open(VideocallComponent, {
      width: '100%',
      height: '100%',
      data: {
        login_id: this.data.login_id,
        callee_id: this.data.callee_id,
      }
    });
    dialogRef1.afterClosed().subscribe(result => {
      
      
     
    });
    this.ClosePopup();
   // this.router.navigateByUrl("/video-call-page");
  }

  ClosePopup(){
    this.dialogRef.close();
  }

}
