import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
// import {ipaddress.getIp,ipaddress.Ip_with_img_address,country_code} from '../../../assets/js/ipaddress.js';
import { Http, Headers } from '@angular/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import {Helper_Class} from '../../helper_class';
import * as $ from 'jquery/dist/jquery.min.js';
import { ToastrService } from 'ngx-toastr';

import { Session_Return,seesion_nameid, day_nameid, Day_Return ,Time_Formate, ConvertTimeformat, Nurse_Day_Return } from '../../../assets/js/common';
import { Message_data } from '../../../assets/js/Message_data';
import { session_based_time_fun } from '../../../assets/js/session_based_time';
import { CommonDataService } from '../../providers/common-data.service';

import { MatDialog } from '@angular/material/dialog';
import { ipaddress } from '../../ipaddress';
import { MasterHelperService } from 'src/app/Doctor_module/MasterHelper.service';

@Component({
  selector: 'app-profile-update',
  templateUrl: './profile-update.component.html',
  styleUrls: ['./profile-update.component.scss']
})
export class ProfileUpdateComponent implements OnInit {
  public img_str;
  public homecare_session;
  public homecare_fee;
  public togg_home;
  public spl_col;
  public subspl_col;
  public loader;
  public sameasabove_check;
  public perm_div;
  public start_time1;
  public start_time2;
  public start_time3;
  public end_time1;
  public end_time2;
  public end_time3;
  public city_url;
  public state_url;
  public country_url;
  public Homecare_show;
  public starts_from_array:any = [];
  public ends_at_array:any = [];
  public hosp_name_txt;
  public hosp_loc_txt;
  public hosp_dist;
  public hosp_state;
  public hosp_country;
  public hosp_zip;
  public hosp_address1;
  public hosp_address2;
  public hosp_contact_num;
  public hop_website;
  public hosp_fee;
  public hosp_days;
  public hosp_session;
  public hops_consult;
  public count_txt;
  public app_type_lbl;
  public app_token;
  public start_from1;
  public start_from2;
  public start_from3;
  public end_at1;
  public end_at2;
  public end_at3;
  public hosp_id;
  public cancelwork;
  public qualification_degree;
  public qualification_uni;
  public qualification_year;
  public userinfo;
  public user_id;
  public pro_img;
  public first_name;
  public middle_name;
  public last_name;
  public dob;
  public mobile;
  public email;
  public gender;
  public registeration_code;
  public registeration_year;
  public registeration_state;
  public send_subspl_array:any = [];
  public pres_address1;
  public pres_address2;
  public pres_city;
  public pres_loc_id;
  public pres_country;
  public pres_location_txt;
  public pres_state;
  public pres_telephone;
  public pres_zipcode;
  public perm_address1;
  public perm_address2;
  public perm_city;
  public perm_loc_id;
  public perm_country;
  public perm_location_txt;
  public perm_state;
  public perm_telephone;
  public perm_zipcode;
  public work_arry:any = [];
  public qualification_array:any = [];
  public grit_package_id;
  public pres_city_list_data:any = [];
  public pres_city_select;
  public filt_city_desc;
  public perm_city_list_data:any = [];
  public pres_state_list_data:any = [];
  public filt_state_desc;
  public pres_country_list_data:any = [];
  public perm_state_list_data:any = [];
  public perm_country_list_data:any = [];
  public country_id;
  public city_list_data:any = [];
  public state_list_data:any = [];
  public country_list_data:any = [];
  public filteredlocList:any = [];
  public location_list_data:any = [];
  public hospital_txt:any = [];
  public hospital_filterlist:any = [];
  public filteredhospList:any = [];
  public app_time;
  public qualification_clg;
  public profile_tab;
  public profile_work_tab;
  public profile_work_add;
  public profile_qual;
  public send_url;
  public mobile_num;
  public email_id;
  public mobile_str;
  public email_str;
  
  constructor( public dialog:MatDialog,public gservice:CommonDataService,public http:Http,public routes:ActivatedRoute,public router:Router,
    public toastr:ToastrService,public masterData:MasterHelperService) { }

  ngOnInit(): void {
    this.img_str = null;
    this.homecare_session = "";
    this.homecare_fee = "";
    this.togg_home = false;

    this.spl_col = true;
    this.subspl_col = true;
    this.loader = false;
    this.sameasabove_check = false;
    this.perm_div = false;
    //default value

    this.start_time1 = "06";
    this.start_time2 = "00";
    this.start_time3 = "AM";
    this.end_time1 = "08";
    this.end_time2 = "00";
    this.end_time3 = "AM";

    this.city_url = ipaddress.getIp + "gen/city";
    this.state_url = ipaddress.getIp + "gen/state";
    this.country_url = ipaddress.getIp + "gen/count";

    this.Homecare_show = true;
    for (var i = 1; i <= 12; i++) {
      if (i < 10) {
        this.starts_from_array.push("0" + i);
      } else {
        this.starts_from_array.push(i);
      }
    }

    for (var j = 0; j <= 55; j += 5) {
      if (j < 10) {
        this.ends_at_array.push("0" + j);
      } else {
        this.ends_at_array.push(j);
      }
    }
    //work_location
    this.hosp_name_txt = "";
    this.hosp_loc_txt = "";
    this.hosp_dist = "";
    this.hosp_state = "";
    this.hosp_country = "";
    this.hosp_zip = "";
    this.hosp_address1 = "",
    this.hosp_address2 = "";
    this.hosp_contact_num = "";
    this.hop_website = '';
    this.hosp_fee = "";
    this.hosp_days = "";
    this.hosp_session = "";
    this.hops_consult = false;
    this.count_txt = "";
    this.app_type_lbl = "Count";
    this.app_token = true;
    this.start_from1 = "06";
    this.start_from2 = "00";
    this.start_from3 = "AM";
    this.end_at1 = "08";
    this.end_at2 = "00";
    this.end_at3 = "AM";
    this.hosp_id = "0";
    this.city_url = ipaddress.getIp + "gen/city";
    this.state_url = ipaddress.getIp + "gen/state";
    this.country_url = ipaddress.getIp + "gen/count";
    this.cancelwork = ipaddress.getIp + "usercontroller/cancelwork";
    //qualification
    this.qualification_degree = "";
    this.qualification_uni = "";
    this.qualification_year = "";
    this.userinfo = Helper_Class.getInfo();
    this.user_id = this.userinfo.user_id;
    this.profile_info();
  }
  profile_info() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'usercontroller/providerdetails',
      JSON.stringify({
        dietician: this.user_id,
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          Helper_Class.setProfile_info(obj);
          if (obj.profile_image != null) {
            this.loader = true;
            this.pro_img = obj.profile_image;
            var img1 = document.getElementById("profile_img1") as HTMLImageElement;
            img1.src = ipaddress.Ip_with_img_address + obj.profile_image;
          } else {
            this.loader = true;
            this.toastr.error(Message_data.getNetworkMessage());
          }

          if (obj.first_name != null) {
            this.first_name = obj.first_name;
          }

          if (obj.middle_name != null) {
            this.middle_name = obj.middle_name;
          }

          if (obj.last_name != null) {
            this.last_name = obj.last_name;
          }

          if (obj.dob != null) {
            var dobval = obj.dob.split('-');
            this.dob = dobval[2]+"-"+dobval[1]+"-"+dobval[0];

          } else {
            this.dob="";
          }

          if (obj.mobile != null) {
            this.mobile = encrypt_decript.Decript(obj.mobile);
          }

          if (obj.email != null) {
            this.email = encrypt_decript.Decript(obj.email);
          }

          if (obj.gender != null) {
            this.gender = obj.gender;
          }

          if (obj.registeration_code != null) {
            this.registeration_code = obj.registeration_code;
          }

          if (obj.registeration_year != null) {
            this.registeration_year = obj.registeration_year;
          }

          if (obj.registeration_state != null) {
            this.registeration_state = obj.registeration_state;
          }
        
          if (obj.home_address != null) {
            if (obj.home_address.length != 0) {
              for (var l = 0; l < obj.home_address.length; l++) {
                if (obj.home_address[l].type == "pres") {
                  this.pres_address1 = obj.home_address[l].address1;
                  this.pres_address2 = obj.home_address[l].address2;
                  this.pres_city = obj.home_address[l].city_desc;
                  this.pres_loc_id = obj.home_address[l].location;
                  this.pres_country = obj.home_address[l].country_desc;
                  this.pres_location_txt = obj.home_address[l].location_desc;
                  this.pres_state = obj.home_address[l].state_desc;
                  this.pres_telephone = obj.home_address[l].telephone;
                  this.pres_zipcode = obj.home_address[l].zipcode;
                  this.Get_Present_City(obj.home_address[l].location_desc);

                } else {
                  this.perm_address1 = obj.home_address[l].address1;
                  this.perm_address2 = obj.home_address[l].address2;
                  this.perm_city = obj.home_address[l].city_desc;
                  this.perm_loc_id = obj.home_address[l].location;
                  this.perm_country = obj.home_address[l].country_desc;
                  this.perm_location_txt = obj.home_address[l].location_desc;
                  this.perm_state = obj.home_address[l].state_desc;
                  this.perm_telephone = obj.home_address[l].telephone;
                  this.perm_zipcode = obj.home_address[l].zipcode;
                  this.Get_Permanent_City(obj.home_address[l].location_desc);
                }
              }

              if (obj.home_address.length == 1) {
                this.sameasabove_check = true;
                this.perm_div = true;
              }
            }
          }

          // set work location array
          if (obj.consultation != null) {
            for (var i = 0; i < obj.consultation.length; i++) {
              var token_name;
              var time_dure;
              if (obj.consultation[i].token != "0") {
                token_name = obj.consultation[i].token;
                time_dure = "0";

              } else {
                token_name = "0";
                time_dure = obj.consultation[i].app_duration;
              }

              this.work_arry.push({
                hospital_id: obj.consultation[i].hospital_clinic_id,
                name: obj.consultation[i].name,

                location: obj.consultation[i].location,
                city: obj.consultation[i].city,
                state: obj.consultation[i].state,
                country: obj.consultation[i].country,
                zipcode: obj.consultation[i].zipcode,
                address1: obj.consultation[i].address1,
                address2: obj.consultation[i].address2,
                mobilenum: obj.consultation[i].telephone,
                website: obj.consultation[i].hospital_website,
                fee: obj.consultation[i].fee,
                day_desc: Day_Return(obj.consultation[i].day),
                day: obj.consultation[i].day,
                session_desc: Session_Return(obj.consultation[i].day_session),
                day_session: obj.consultation[i].day_session,
                available_from: Time_Formate(obj.consultation[i].available_from),
                available_to: Time_Formate(obj.consultation[i].available_to),
                consultant: obj.consultation[i].consultant,
                token: token_name,
                app_duration: time_dure,
                consult_time_id: obj.consultation[i].doc_cons_time_id,
              })
            }

            // set work location
            if (this.work_arry.length != 0) {
              var work_arry1:any = [];
              for (var i = 0; i < this.work_arry.length; i++) {
                if (this.work_arry[i].consult_time_id != "0") {
                  var consult_time_id = this.work_arry[i].consult_time_id;

                } else {
                  consult_time_id = "0";
                }

                work_arry1.push({
                  hospital_id: this.work_arry[i].hospital_id,
                  available_from: ConvertTimeformat("", this.work_arry[i].available_from),
                  available_to: ConvertTimeformat("", this.work_arry[i].available_to),
                  fee: this.work_arry[i].fee,
                  day: this.work_arry[i].day,
                  day_session: this.work_arry[i].day_session,
                  token: this.work_arry[i].token,
                  app_duration: this.work_arry[i].app_duration,
                  consultant: this.work_arry[i].consultant,
                  consultation_time_id: consult_time_id,
                });
              }
              Helper_Class.set_work_array(work_arry1);
              
            }
          }

          if(obj.qualification != null){
            this.qualification_array = [];
            // set default qualfication send array
            for (var i = 0; i < obj.qualification.length; i++) {
              var clg_name;
              if (obj.qualification[i].institute != null) {
                clg_name = obj.qualification[i].institute;
              }
              else {
                clg_name = "-";
              }
              this.qualification_array.push({
                degree: obj.qualification[i].degree_name,
                institute: clg_name,
                university: obj.qualification[i].university,
                year: obj.qualification[i].graduation_year,
              })
            }
            var qual_arr;
            qual_arr = {
              qualification: this.qualification_array
            }
            Helper_Class.setprofile_qual_array(qual_arr);
            this.grit_package_id= obj.package_id;
            
          }
          //this.send_Personaldata();
        },
        error => {
          this.loader = true;
          this.toastr.error(Message_data.getNetworkMessage());
        }
      )
  }
   // present address - city
   Get_Present_City(data) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');

    this.http.post(this.city_url, JSON.stringify({ loc_name: data }), { headers: headers }).subscribe(
      data => {
        
        if (data.json().cities != null && data.json().cities.length != 0) {
          this.pres_city_list_data = data.json().cities;
          this.pres_city_select = this.pres_city_list_data[0].city_id;
          this.filt_city_desc = this.pres_city_list_data[0].city_desc;
          this.pres_loc_id = this.pres_city_list_data[0].location_id;
          this.pres_zipcode = this.pres_city_list_data[0].zipcode;
          this.Pres_City_change(this.pres_city_list_data[0].city_id);
        }
      },
      error => {
        this.toastr.error(Message_data.getNetworkMessage());
      })
  }
  Pres_City_change(data) {
    if (data != null) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');

      this.http.post(this.state_url, JSON.stringify({ city_id: data }), { headers: headers }).subscribe(
        data => {
          
          if (data.json().states != null && data.json().states.length != 0) {
            this.pres_state_list_data = data.json().states;
            this.pres_state = this.pres_state_list_data[0].state_id;
            this.filt_state_desc = this.pres_state_list_data[0].state_desc;
            this.Present_state_change(this.pres_state_list_data[0].state_id);
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        })

    } else {
      this.pres_state_list_data = [];
      this.pres_city_list_data = [];
      this.pres_country_list_data = [];
    }
  }
  //permanent address- city
  Get_Permanent_City(data) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');

    this.http.post(this.city_url, JSON.stringify({ loc_name: data }), { headers: headers }).subscribe(
      data => {
        
        if (data.json().cities != null && data.json().cities.length != 0) {
          this.perm_city_list_data = data.json().cities;
          this.perm_city = this.perm_city_list_data[0].city_id;
          this.filt_city_desc = this.perm_city_list_data[0].city_desc;
          this.perm_loc_id = this.perm_city_list_data[0].location_id;
          this.perm_zipcode = this.perm_city_list_data[0].zipcode;
          this.Perm_City_change(this.perm_city_list_data[0].city_id);
        }
      },
      error => {
        this.toastr.error(Message_data.getNetworkMessage());
      })
  }
  Perm_City_change(data) {
    if (data != null) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');

      this.http.post(this.state_url, JSON.stringify({ city_id: data }), { headers: headers }).subscribe(
        data => {
          if (data.json().states != null && data.json().states.length != 0) {
            this.perm_state_list_data = data.json().states;
            this.perm_state = this.perm_state_list_data[0].state_id;
            this.filt_state_desc = this.perm_state_list_data[0].state_desc;
            this.Permanent_state_change(this.perm_state_list_data[0].state_id);
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        })

    } else {
      this.perm_state_list_data = [];
      this.perm_city_list_data = [];
      this.perm_country_list_data = [];
    }
  }
  // present address - state
  Present_state_change(data) {
    if (data != null) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');

      this.http.post(this.country_url, JSON.stringify({ state_id: data }), { headers: headers }).subscribe(
        data => {

          if (data.json().countries != null && data.json().countries.length != 0) {
            this.pres_country_list_data = data.json().countries;
            this.country_id = this.pres_country_list_data[0].country_id;
            this.pres_country = this.pres_country_list_data[0].country_id;
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        })

    } else {
      this.pres_state_list_data = [];
      this.pres_country_list_data = [];
    }
  }
  //permanent address- state
  Permanent_state_change(data) {
    if (data != null) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');

      this.http.post(this.country_url, JSON.stringify({ state_id: data }), { headers: headers }).subscribe(
        data => {
          if (data.json().countries != null && data.json().countries.length != 0) {
            this.perm_country_list_data = data.json().countries;
            this.country_id = this.perm_country_list_data[0].country_id;
            this.perm_country = this.perm_country_list_data[0].country_id;
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        })

    } else {
      this.perm_state_list_data = [];
      this.perm_country_list_data = [];
    }
  }
  hospital_filter(e) {
    this.city_list_data = [];
    this.state_list_data = [];
    this.country_list_data = [];
    this.filteredlocList = [];
    this.location_list_data = [];

    this.hosp_loc_txt = '';
    this.hosp_dist = '';
    this.hosp_state = '';
    this.hosp_country = '';
    this.hosp_zip = '';
    this.hosp_address1 = '';
    this.hosp_address2 = '';
    this.hosp_contact_num = '';
    this.hop_website = '';

    var tmp_arr = [];
    if (this.hosp_name_txt !== "" && this.hosp_name_txt.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'usercontroller/hospital/',
        JSON.stringify({
          hosp_name: this.hosp_name_txt
        }),
        { headers: headers })
        .subscribe(
          response => {
            this.hospital_txt = [];
            this.hospital_filterlist = [];

            var obj = response.json();
            
            if (obj.hospitals != null) {
              this.filteredhospList = [];
              this.hospital_filterlist = obj.hospitals;

              for (var i = 0; i < this.hospital_filterlist.length; i++) {
                this.hospital_txt.push(this.hospital_filterlist[i].hosp_name);
              }

              this.filteredhospList = this.hospital_txt.filter(function (this:any,el) {
                return el.toLowerCase().indexOf(this.hosp_name_txt.toLowerCase()) > -1;
              }.bind(this));

              if (this.hospital_txt.length == 0) {
                this.toastr.error(Message_data.noHosptlFound);
              }

            } else {
              this.toastr.error(Message_data.noHosptlFound);
            }
          },
          error => {
          }
        )

    } else {
      this.filteredhospList = [];
    }
  }
  select_hospital(data) {
    this.hosp_name_txt = data;
    this.filteredhospList = [];
    if (this.hosp_name_txt != undefined) {
      this.getLocationBasedonhospital();
    }
  }
  Loc_change(e) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'gen/haddr/',
      JSON.stringify({
        hosp_name: this.hosp_name_txt,
        location: this.hosp_loc_txt
      }),
      { headers: headers })
      .subscribe(
        response => {

          var obj = JSON.parse(response["_body"]);
          
          if (obj.hptl_clinic_id != null) {
            this.hosp_id = obj.hptl_clinic_id;
            this.hosp_address1 = obj.address1;
            this.hosp_address2 = obj.address2;
            this.hosp_dist = obj.city;
            this.hosp_state = obj.state;
            this.hosp_country = obj.country;
            this.hosp_contact_num = obj.telephone;
            this.hop_website = obj.website;
            this.hosp_zip = obj.zipcode;

          }

        },
        error => {

        }
      )
  }
  fileChange($event): void {
    this.readThis($event.target);
  }

  readThis(inputValue: any): void {
    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      var fileSize = Math.round(inputValue.files[0].size / 1024);

      if (fileSize >= 50) {
        this.toastr.error(Message_data.maxImgSize);
        $("#file").val('');
        this.img_str = null;

      } else {
        this.img_str = myReader.result.toString();
       
        $('#profile_img1').attr('src', myReader.result);
      }
    }
    myReader.readAsDataURL(inputValue.files[0]);
  }
  app_token_fun() {
    this.count_txt = '';
    if (this.app_token == true) {
      this.app_time = false;
      this.app_type_lbl = "Count";
      // this.app_token = true;
    }
  }

  app_time_fun() {
    this.count_txt = '';
    if (this.app_time == true) {
      this.app_token = false;
      this.app_type_lbl = "Minutes";
      // this.app_token = true;
    }
  }
  
  // add table
  work_btn_click() {
    var cosult_id = null;
    if (this.hops_consult == true) {
      cosult_id = 1;

    } else {
      cosult_id = 0;
    }

    var token_name = "0", time_dure = "0";
    if (this.app_token == true) {
      token_name = this.count_txt;
      time_dure = "0";

    } else {
      token_name = "0";
      time_dure = this.count_txt;
    }

    var fee = 0;

    if (this.hosp_fee != "") {
      fee = parseInt(this.hosp_fee);
    }

    if (this.hosp_name_txt == "" || this.hosp_loc_txt == "" ||
      this.hosp_dist == undefined || this.hosp_state == "" || this.hosp_country == "" ||
      this.hosp_zip == "" || this.hosp_address1 == "" || this.hosp_contact_num == "" ||
      this.hosp_days == "" || this.hosp_session == "") {
        this.toastr.error(Message_data.mandatory);

    } else {
      var f_time = ConvertTimeformat("", this.start_from1 + ":" + this.start_from2 + " " + this.start_from3);
      var t_time = ConvertTimeformat("", this.end_at1 + ":" + this.end_at2 + " " + this.end_at3);
      var ret_value = session_based_time_fun(f_time, t_time, this.hosp_session);
      if (ret_value == true) {

        this.work_arry.push({
          hospital_id: this.hosp_id,
          name: this.hosp_name_txt,
          location: this.hosp_loc_txt,
          city: this.hosp_dist,
          state: this.hosp_state,
          country: this.hosp_country,
          zipcode: this.hosp_zip,
          address1: this.hosp_address1,
          address2: this.hosp_address2,
          mobilenum: this.hosp_contact_num,
          website: this.hop_website,
          fee: fee,
          day_desc: this.hosp_days,
          day: day_nameid(this.hosp_days),
          session_desc: this.hosp_session,
          day_session: seesion_nameid(this.hosp_session),
          available_from: this.start_from1 + ":" + this.start_from2 + " " + this.start_from3,
          available_to: this.end_at1 + ":" + this.end_at2 + " " + this.end_at3,
          consultant: cosult_id,
          appointment: this.app_token + "" + this.app_time,
          token: token_name,
          app_duration: time_dure,
          consult_time_id: "0"
        })
        this.hosp_days = "";
        this.hosp_session = "";
        this.hops_consult = false;
        this.count_txt = "";
        this.start_from1 = "06";
        this.start_from2 = "00";
        this.start_from3 = "AM";
        this.end_at1 = "08";
        this.end_at2 = "00";
        this.end_at3 = "AM";

      } else {
        this.toastr.error(Message_data.invalidTime);
      }
    }
  }

    //table delecte action
    work_delete(name_data,name, day, session, start_time) {
      for (var i = 0; i < this.work_arry.length; i++) {
        if (name_data == this.work_arry[i].consult_time_id && name == this.work_arry[i].name && day == this.work_arry[i].day_desc 
          && session == this.work_arry[i].session_desc && start_time == this.work_arry[i].available_from) {
          this.work_arry.splice(i, 1);
        }
      }
  
    }
  
    clearHospital(){
      this.hosp_name_txt=null;
      this.hosp_id=undefined;
      this.hospital_filterlist=[];
    }
    //qualification table
  qualification_btn_click() {
    if (this.qualification_degree == "" || this.qualification_uni == "" || this.qualification_year == "") {
      this.toastr.error(Message_data.mandatory);

    } else {
      this.qualification_array.push({
        degree: this.qualification_degree,
        institute: this.qualification_clg,
        university: this.qualification_uni,
        year: this.qualification_year,
      })
      //Resets the form 
      this.qualification_degree = "";
      this.qualification_uni = "";
      this.qualification_year = "";
      this.qualification_clg = "";
    }
  }

  //delete action
  qua_delete(degree_da, year_da) {
    for (var i = 0; i < this.qualification_array.length; i++) {
      if (degree_da == this.qualification_array[i].degree) {
        this.qualification_array.splice(i, 1);
      }
    }
  }
  //personal tab
  checkemail(){
    if(this.email != undefined){
      this.email = this.email.trim()
      var x = this.email;
      var re = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/igm;
  
      if (!re.test(x)) {
        this.toastr.error(Message_data.validEmail);
  
      } else{
        this.email = this.email.trim();
      }
    }
  }

  public pathForImage(img) {
    if (img === null) {
      return '';
    } else {
      // return cordova.file.dataDirectory + img;
    }
  }
  getLocationBasedonhospital() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'gen/hloc/',
      JSON.stringify({
        hosp_name: this.hosp_name_txt
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          
          if (obj.hosp_locations != null) {
            this.location_list_data = obj.hosp_locations;
          }
        },
        error => {
          
        }
      )
  }
  //update
  Update_click() {
    this.send_Personaldata();
    this.send_workdata();
    this.send_Qualificationdata();
    this.profile_tab = Helper_Class.getprofile_array();
    this.profile_work_tab = Helper_Class.get_work_array();
    this.profile_work_add = Helper_Class.get_work_add_array();
    this.profile_qual = Helper_Class.getprofile_qual_array();
     var homecareArray=[];
    this.send_url = "usercontroller/updprovweb/";
    // if(this.profile_tab.email != undefined && encrypt_decript.Decript(this.profile_tab.email).toString() != undefined ){
    //   var x = encrypt_decript.Decript(this.profile_tab.email).toString();
    //   var re = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/igm;

    //   if(!re.test(x)){
    //     this.toastr.error('Enter valid email address');
    //   }
    // }
    
    if (encrypt_decript.Decript(this.profile_tab.mobile).toString().trim() == "" || encrypt_decript.Decript(this.profile_tab.mobile).length!=10) {
      this.toastr.error(Message_data.validMobileNo);

    } else if (this.profile_tab.home_care == "1" && this.profile_tab.homecare_val == false) {
      this.toastr.error(Message_data.enterHomeCareMandatory);

    } else if (this.profile_tab.home_care == "1" && this.profile_tab.homecare_val == true && homecareArray.length==0) {
      this.toastr.error(Message_data.addHomeCareConsult);

    } else if (this.profile_work_tab == undefined || this.profile_work_tab.length == 0) {
      this.toastr.error(Message_data.verifyWrkLocation);

    } else if (this.profile_qual == undefined || this.profile_qual.length == 0) {
      this.toastr.error(Message_data.verfiyQualf);

    } else if (this.profile_tab.mobile != Helper_Class.getProfile_info().mobile) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'usercontroller/ismobileexist',
        JSON.stringify({
          mobile: this.mobile_num,
          flag: "1"
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);

            if (obj.result == true) {
              this.toastr.error(Message_data.existMobileNo);

            } else {
              this.emailCheck();
            }
          },
          error => {
            
          }
        )

    } else {
      this.emailCheck();
    }
  }

  emailCheck() {
    if (this.profile_tab.email != Helper_Class.getProfile_info().email) {

      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'usercontroller/isemailexist',
        JSON.stringify({
          email: this.email_id,
          flag: "1"
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);

            if (obj.result == true) {
              this.toastr.error(Message_data.existEmailID);

            } else {
              this.final_update();
            }
          },
          error => {

          }
        )

    } else {
      this.final_update();
    }
  }

  final_update() {
    var send_work = null;
    send_work = {
      consultation: this.profile_work_tab
    }

    var finalObj = $.extend(true, this.profile_tab, send_work);
    var finalObj1 = $.extend(true, finalObj, this.profile_qual);
    var output = null;
    output = finalObj1;

    // send foot ass
    var send_foot = null, getfoot_ass = null, qual;
    getfoot_ass = Helper_Class.get_work_add_array();

    if (getfoot_ass!=undefined && getfoot_ass.length != 0) {
      send_foot = {
        address: getfoot_ass,
      }
      output =  $.extend (true,finalObj1,send_foot);
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + this.send_url, output,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.key != null && obj.key=="1") {
            this.toastr.success(Message_data.prflUpdSuccess);
            this.router.navigateByUrl("loginpage");
            //this.navCtrl.setRoot('HomePage');

          } else {
            this.toastr.error(Message_data.profileUpdFailed);
          }
        },
        error => {
        }
      )
  }
  send_Personaldata() {
    this.mobile_str = encrypt_decript.Encript(this.mobile).toString();
    if(this.email.trim() != undefined){
      this.email_str = encrypt_decript.Encript(this.email).toString();
    }
    var img_path = this.pro_img, upd_url = "0";
    if (this.img_str != null) {
      img_path = this.img_str.split(',')[1];
      upd_url = "1";
    }
    else {
      upd_url = undefined;
    }
    var send_array = null;
      send_array = {
        upd_url: upd_url,
        profile_image: img_path,
        serv_provider_reg_id: this.user_id,
        mobile: this.mobile_str,
        language: "",
        provider: "dietician",
        email: this.email_str,
        grit_package_subscribe_id:this.grit_package_id,
      }
    
    Helper_Class.setprofile_array(send_array);
  }
  send_workdata() {
    //this.work_arry
    var work_arry1:any = [];
    var work_address_array:any = [];

    for (var i = 0; i < this.work_arry.length; i++) {
      if (this.work_arry[i].hospital_id == "0") {
        var address2_txt = undefined
        if (this.work_arry[i].address2 != undefined) {
          address2_txt = this.work_arry[i].address2;
        }
        else {
          address2_txt = "";
        }

        work_address_array.push({
          name: this.work_arry[i].name,
          address1: this.work_arry[i].address1,
          address2: address2_txt,
          city: this.work_arry[i].city,
          state: this.work_arry[i].state,
          zipcode: this.work_arry[i].zipcode,
          country: this.work_arry[i].country,
          telephone: this.work_arry[i].mobilenum,
          website: this.work_arry[i].hospital_website,
          location: this.work_arry[i].location,

        });

        var hosp_nursename = this.work_arry[i].name;
        var hosp_nurseloc = this.work_arry[i].location;

      }

      if (this.work_arry[i].consult_time_id != "0") {
        var consult_time_id = this.work_arry[i].consult_time_id;
      }
      else {
        consult_time_id = "0";
      }

      work_arry1.push({
        hospital_id: this.work_arry[i].hospital_id,
        available_from: ConvertTimeformat("", this.work_arry[i].available_from),
        available_to: ConvertTimeformat("", this.work_arry[i].available_to),
        fee: this.work_arry[i].fee,
        day: this.work_arry[i].day,
        day_session: this.work_arry[i].day_session,
        token: this.work_arry[i].token,
        app_duration: this.work_arry[i].app_duration,
        consultant: this.work_arry[i].consultant,
        consultation_time_id: consult_time_id,
        hosp_name: hosp_nursename,
        location: hosp_nurseloc,

      });
    }
    Helper_Class.set_work_array(work_arry1);
    Helper_Class.set_work_add_array(work_address_array);
  }
  send_Qualificationdata() {
    var qual_arr=null;
    qual_arr={
      qualification:this.qualification_array
    }
    Helper_Class.setprofile_qual_array(qual_arr);
  }
}
