<div class="container-fluid g-0" style="height: 100%;">
    <div class="warpper_div">
        <div class="asied_section" #asied_section_width>
            <section id="aside_section">
                <div class="logopanel" id="logo" #logo>
                    <h1>
                        <a (click)="menuNav('home');"></a>
                    </h1>
                </div>
                <div class="doc_menu_list">

                    <div class="doc_menu menu_active" id="home" (click)="menuNav('home');"
                        [hidden]='Dashboard_professional'>
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/dashboard.svg" /></i></div>
                        <div class="doc_menu_title" *ngIf="!minimized_asied  && minimized_asied != undefined">
                            <span>Home</span>
                        </div>
                    </div>
                    <div class="doc_menu" id="patient" (click)="menuNav('patient');" [hidden]='inpat_data'>
                        <div class="menu_icon"> <i style="transform: rotate(-55deg);" class="fa fa-puzzle-piece"></i>
                        </div>
                        <div class="doc_menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                            <span>In-Patient</span><span style="float: right;padding-right: 7px;"> <i
                                    class="fa fa-angle-right"></i></span>
                        </div>
                        <!-- {{submenu_flag}} -->
                        <!-- <div ng-hide="!submenu_flag"> -->
                        <ul *ngIf="submenu_flag" class="nav-children">
                            <li (click)="menuNav('in_patient');"><a id="nav-a"><i class="fa fa-angle-right"></i>
                                    Patients</a></li>
                            <li (click)="setAppoinmentHelper('ipbilling');menuNav('ipbilling');"><a><i
                                        class="fa fa-angle-right"></i> Billing</a></li>
                            <li (click)="menuNav('patient_dashboard');"><a><i class="fa fa-angle-right"></i>
                                    Dashboard</a></li>
                            <li [hidden]="consolidatedFlag" (click)="menuNav('consolidatedBills');"><a><i class="fa fa-angle-right"></i>
                                Consolidated Bills</a></li>
                                    
                            <!-- <li (click)="menuNav('ipcard');"><a><i class="fa fa-angle-right"></i>
                                Inpatient card</a></li> -->

                        </ul>
                        <!-- </div> -->
                    </div>
                    <div class="doc_menu" id="appointment_list"
                        (click)="setAppoinmentHelper('appinment');menuNav('appointment_list');"
                        [hidden]="appointment_data">
                        <div class="menu_icon"><i class="icon-menu"><img
                                    src="../../assets/global/images/appointment.svg" /></i></div>
                        <div class="doc_menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                            <span>Appointments</span><span style="float: right;padding-right: 7px;"> <i
                                    class="fa fa-angle-right"></i></span>
                        </div>
                    </div>
                    <div class="doc_menu" id="walkin" (click)="menuNav('walkin');" [hidden]="noapp_data">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/doctor.svg" /></i></div>
                        <div class="doc_menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                            <span>Walk-in</span><span style="float: right;padding-right: 7px;"> <i
                                    class="fa fa-angle-right"></i></span>
                        </div>
                    </div>
                    <div class="doc_menu" id="permission" (click)="menuNav('permission');"
                        [hidden]="Nurse_permssionicon">
                        <div class="menu_icon"><i class="icon-menu"><img
                                    src="../../assets/global/images/permission.svg" /></i></div>
                        <div class="doc_menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                            <span>Permissions</span><span style="float: right;padding-right: 7px;"> <i
                                    class="fa fa-angle-right"></i></span>
                        </div>
                    </div>
                    <div class="doc_menu" id="billing" (click)="setAppoinmentHelper('billing');menuNav('billing');"
                        [hidden]="billing_icon">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/billing.svg" /></i></div>
                        <div class="doc_menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                            <span>Billing</span><span style="float: right;padding-right: 7px;"> <i
                                    class="fa fa-angle-right"></i></span>
                        </div>
                    </div>
                    
                    <div class="doc_menu" id="secondopinion" (click)="menuNav('secondopinion');"
                        [hidden]="Secondopinion_icon">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/secondopinion.svg" /></i></div>
                        <div class="doc_menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                            <span>Second Opinion</span><span style="float: right;padding-right: 7px;"> <i
                                    class="fa fa-angle-right"></i></span></div>
                    </div>
                    <div class="doc_menu" id="homecare" (click)="setAppoinmentHelper('homecare');menuNav('homecare');"
                        [hidden]="Homecare_icon">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/home.svg" /></i></div>
                        <div class="doc_menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                            <span>Homecare</span><span style="float: right;padding-right: 7px;"> <i
                                    class="fa fa-angle-right"></i></span>
                        </div>
                    </div>
                    <div class="doc_menu" id="schedule" (click)="menuNav('schedule');">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/Schedule.svg" /></i></div>
                        <div class="doc_menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                            <span>Schedule</span><span style="float: right;padding-right: 7px;"> <i
                                    class="fa fa-angle-right"></i></span>
                        </div>
                        <ul *ngIf="schdule_submenu_flag" class="nav-children">
                            <li (click)="menuNav('calender');"><a id="nav-a"><i class="fa fa-angle-right"></i> My
                                    Calender</a></li>
                            <li (click)="setAppoinmentHelper('doctor_availability');menuNav('doctor_availability');">
                                <a><i class="fa fa-angle-right"></i> Doctor Availability</a></li>
                            <li (click)="menuNav('manage_schedule');"><a><i class="fa fa-angle-right"></i> View
                                    Schedule</a></li>
                        </ul>
                    </div>
                    <div class="doc_menu" id="notification" (click)="menuNav('notification');">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/notification.svg" /></i></div>
                        <div class="doc_menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                            <span>Notification</span><span style="float: right;padding-right: 7px;"> <i
                                    class="fa fa-angle-right"></i></span>
                        </div>
                    </div>
                    <div class="doc_menu" id="help" (click)="menuNav('help');">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/help.svg" /></i></div>
                        <div class="doc_menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                            <span>Help</span><span style="float: right;padding-right: 7px;"> <i
                                    class="fa fa-angle-right"></i></span>
                        </div>
                    </div>
                    <div class="doc_menu" id="reference_video" (click)="menuNav('reference_video');" [hidden]="reference_video">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/reference_video.png" /></i></div>
                        <div class="doc_menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                            <span>Reference video</span><span style="float: right;padding-right: 7px;"> <i
                                    class="fa fa-angle-right"></i></span>
                        </div>
                    </div>
                    <div class="doc_menu" id="reportsMenu" (click)="menuNav('reportsMenu');" [hidden]="mis_report">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/mis_report_icon.svg" /></i></div>
                        <div class="doc_menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                            <span>MIS Reports</span><span style="float: right;padding-right: 7px;"> <i
                                    class="fa fa-angle-right"></i></span>
                        </div>
                        <ul *ngIf="reportsFlag" class="nav-children">
                            <li (click)="setAppoinmentHelper('salseReport');menuNav('salseReport');"><a><i
                                        class="fa fa-angle-right"></i> Sales Reports</a></li>
                            <li (click)="setAppoinmentHelper('stockReport');menuNav('stockReport');"><a><i
                                        class="fa fa-angle-right"></i> Stock Reports</a></li>
                            <li (click)="setAppoinmentHelper('receivablesReport');menuNav('receivablesReport');"><a><i
                                        class="fa fa-angle-right"></i> Receivable Reports</a></li>

                        </ul>
                    </div>
                </div>
            </section>
            <!-- <span class="sting_function" *ngIf = "minimized_asied" style="position: absolute;top: 10%;right: -20px;padding: 0px 3px; border: 1px solid #8e8e8e;" (click) = "minimizing()" ><i style="font-size: 20px;vertical-align: middle;color: #848484fa;" class="fas fa-angle-right"></i></span>
            <span class="sting_function" *ngIf = "!minimized_asied" style="position: absolute;top: 10%;right: -20px;padding: 0px 3px;border: 1px solid #8e8e8e;" (click) = "minimizing()" ><i style="font-size: 20px;color: #848484fa;vertical-align: middle;" class="fas fa-angle-left"></i></span> -->
        </div>

        <div class="right_content_section" #right_section_width>
            <section id="headar_section">
                <div class="header-left" style="position: relative;">
                    <div class="topnav">
                        <div class="openbtn_desktop" (click)="minimizing()"><i class="fas fa-bars"></i></div>
                        <div class="openbtn" (click)="openMenumodel()"><i class="fas fa-bars"></i></div>
                        <div class="hospital_logo">
                            <img class="hospital_logo" src="{{hospital_logo}}"  onerror="this.src='../../../assets/img/default_hosp_logo.png';" alt="user image">
                        </div>
                        <div class="hospital_name"><span
                                class="d-none d-sm-inline-block d-md-inline-block d-lg-inline-block d-xl-inline-block ">{{hospital_name}}</span>
                        </div>
                        <div class="headerCover">
                            <div class="bell" (click)="menuNav('notification');"><i class="far fa-bell"></i><span
                                    class="notificatio_count" *ngIf="notification_flag">{{notificationCount}}</span>
                            </div>
                            <!-- <div class="share"><i class="far fa-paper-plane"></i></div>   -->
                            <div class="doctor_profile_pc" data-bs-toggle="modal" data-bs-target="#exampleModal"><img
                                    src="{{profile_image}}" onerror="this.src='./././assets/img/default.jpg'"> </div>
                            <div class="doctor_name" data-bs-toggle="modal" data-bs-target="#exampleModal">Dr.
                                {{first_name}}&nbsp;{{last_name}}</div>
                        </div>

                        <!-- Modal -->
                        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                            aria-hidden="true">
                            <div class="modal-dialog profile_dialog">
                                <div class="modal-content">
                                    <div class="modal-header" style="display: inline-block;text-align: center">
                                        <!-- src="{{profile_image}}" -->
                                        <div class="modal-title" id="exampleModalLabel"><img class="model_profile"
                                                src="{{profile_image}}"
                                                onerror="this.src='./././assets/img/default.jpg'"></div>

                                    </div>
                                    <div class="modal-body">
                                        <div class="menu_div" (click)="MenuModelAction('profile_update');"> <i
                                                class="far fa-user"></i> <span class="header_model_menu">My
                                                Profile</span> </div>
                                        <div class="menu_div" (click)="MenuModelAction('changePassword');"><i
                                                class="fas fa-cog"></i><span class="header_model_menu">Change
                                                Password</span></div>
                                        <div class="menu_div" (click)="MenuModelAction('terms_conditions');"><i
                                                class="fas fa-cog"></i><span class="header_model_menu">Privacy & Data
                                                protection

                                            </span></div>
                                        <div class="menu_div" (click)="MenuModelAction('logout');"
                                            style="border-bottom: none;"><i class="fas fa-sign-out-alt"></i><span
                                                class="header_model_menu">Log Out</span></div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="body_section">
                <div class="body_warpper">
                    <div class="card shadow-lg" id="card_id" #scrol_traget>
                        <!-- <h1>{{displayPage}}</h1> -->
                        <div [ngSwitch]="displayPage">
                            <div *ngSwitchDefault="'dashboard'">
                                <app-doctorhome></app-doctorhome>
                            </div>
                            <div *ngSwitchCase="'dashboard'">
                                <app-doctorhome></app-doctorhome>
                            </div>

                            <div *ngSwitchCase="'in_patient'">
                                <app-inpat-patient-list></app-inpat-patient-list>
                            </div>
                            <div *ngSwitchCase="'inPatientViewInfo'">
                                <app-inpat-patient-info></app-inpat-patient-info>
                            </div>
                            <div *ngSwitchCase="'appointment_list'">
                                <app-doctor-app-list></app-doctor-app-list>
                            </div>
                            <div *ngSwitchCase="'walkin'">
                                <app-noappointment></app-noappointment>
                            </div>
                            <div *ngSwitchCase="'billing'">
                                <app-doctorbilllistpage></app-doctorbilllistpage>
                            </div>
                            <div *ngSwitchCase="'billcreate'">
                                <app-bill-create></app-bill-create>
                            </div>
                            <div *ngSwitchCase="'billdetail'">
                                <app-bill-detail-view></app-bill-detail-view>
                            </div>
                            <div *ngSwitchCase="'billedit'">
                                <app-bill-edit></app-bill-edit>
                            </div>
                            <div *ngSwitchCase="'inPatientDS'">
                                <app-inpat-discharge-summary></app-inpat-discharge-summary>
                            </div>
                            <div *ngSwitchCase="'patient_dashboard'">
                                <app-inpat-dashboard></app-inpat-dashboard>
                            </div>
                            <!-- <div *ngSwitchCase="'patient_ward'">
                                <app-inpatient-ward></app-inpatient-ward>
                            </div>
                            <div *ngSwitchCase="'patient_patwise'">
                                <app-inpatient-patientwise></app-inpatient-patientwise>
                            </div> -->
                            <div *ngSwitchCase="'inpatient_Discharge_view'">
                                <app-inpat-discharge-view></app-inpat-discharge-view>
                            </div>
                            <div *ngSwitchCase="'inpatient_guno_Discharge_view'">
                                <app-gynac-dis-summary></app-gynac-dis-summary>
                            </div>
                            <div *ngSwitchCase="'notification'">
                                <app-doctor-notification></app-doctor-notification>
                            </div>
                            <div *ngSwitchCase="'help'">
                                <app-help></app-help>
                            </div>
                            <div *ngSwitchCase="'casesheet'">
                                <app-casesheet></app-casesheet>
                            </div>
                            <div *ngSwitchCase="'calender'">
                                <app-doctor-schedule-list-page></app-doctor-schedule-list-page>
                            </div>
                            <div *ngSwitchCase="'doctor_availability'">
                                <app-doctor-available-nonavailable-scedule-page>
                                </app-doctor-available-nonavailable-scedule-page>
                            </div>
                            <div *ngSwitchCase="'manage_schedule'">
                                <app-doctor-manage-schedule-main></app-doctor-manage-schedule-main>
                            </div>
                            <div *ngSwitchCase="'homecare'">
                                <app-homecare></app-homecare>
                            </div>
                            <div *ngSwitchCase="'secondopinion'">
                                <app-second-opinion></app-second-opinion>
                            </div>
                            <div *ngSwitchCase="'secondopinion_casesheet'">
                                <app-second-opinion-casesheet></app-second-opinion-casesheet>
                            </div>
                            <div *ngSwitchCase="'profile_update'">
                                <app-doctor-profile-update></app-doctor-profile-update>
                            </div>
                            <div *ngSwitchCase="'permission'">
                                <app-permission></app-permission>
                            </div>
                            <div *ngSwitchCase="'medprescription'">
                                <app-med-diag-prescription></app-med-diag-prescription>
                            </div>
                            <div *ngSwitchCase="'ModalPage'">
                                <app-dropdown-modelpage></app-dropdown-modelpage>
                            </div>
                            <div *ngSwitchCase="'casesheet_list'">
                                <app-casesheet-list></app-casesheet-list>
                            </div>
                            <div *ngSwitchCase="'cardio_casesheet_view'">
                                <app-cardio-casesheet-view></app-cardio-casesheet-view>
                            </div>
                            <div *ngSwitchCase="'appointment_view'">
                                <app-appoinmentview></app-appoinmentview>
                            </div>

                            <div *ngSwitchCase="'reference_video'">
                                <app-reference-video></app-reference-video>
                            </div>
                            <div *ngSwitchCase="'salseReport'">
                                <app-doc-sales-report></app-doc-sales-report>
                            </div>
                            <div *ngSwitchCase="'receivablesReport'">
                                <app-doc-receivables-report></app-doc-receivables-report>
                            </div>
                            <div *ngSwitchCase="'stockReport'">
                                <app-doc-stock-report></app-doc-stock-report>
                            </div>
                            <div *ngSwitchCase="'ipbillcreate'">
                                <app-ipatient-bill-create></app-ipatient-bill-create>
                            </div>
                            <div *ngSwitchCase="'ipbilling'">
                                <app-doctorbilllistpage></app-doctorbilllistpage>
                            </div>
                            <div *ngSwitchCase="'ipcard'">
                                <app-inpatien-card></app-inpatien-card>
                            </div>
                            <div *ngSwitchCase="'consolidatedBills'">
                                <app-consolidated-bills></app-consolidated-bills>
                            </div>
                            

                        </div>

                        <!-- <div id="content_div" (sendData)="menuNav($event)"></div> -->
                    </div>
                </div>
            </section>
            <footer id="footer_section">
                <div class="footer_left">Copyright © 2022 All rights reserved </div>
                <div class="footer_right">
                    <p class="pull-right sm-pull-reset">
                        <span>
                            <a href="#" class="m-r-10">Support</a> | <a href="#" class="m-l-10 m-r-10">Terms of use</a>
                            | <a href="#" class="m-l-10">Privacy Policy</a>
                        </span>
                    </p>
                </div>
            </footer>
        </div>

    </div>

</div>