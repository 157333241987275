import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from 'src/app/providers/common-data.service';
import { Helper_Class } from 'src/app/helper_class';
import { ipaddress } from '../../ipaddress';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Component({
  selector: 'app-pharma-help',
  templateUrl: './pharma-help.component.html',
  styleUrls: ['./pharma-help.component.css']
})
export class PharmaHelpComponent implements OnInit {
  public packID: string;
  public userType: string;
  public moduleArray = [];
  public helpTopic = [];
  public module: any;
  public userInfo;
  constructor(public gservice: CommonDataService, public http: HttpClient, public routes: ActivatedRoute, public router: Router, public toastr: ToastrService) {
    this.userType = "5";
  }
  ngOnInit(): void {
    this.userInfo = Helper_Class.getInfo();
    this.packID = this.userInfo.package_id;
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "usercontroller/gmodules",
      ({
        user_type: this.userType,
        package_id: this.packID
      }),
      { headers: headers })
      .subscribe(
        response => {
          this.moduleArray = [];
          var obj = JSON.parse(JSON.stringify(response));
          if (obj.length != 0 && obj.modules != undefined) {
            for (var i = 0; i < obj.modules.length; i++) {
              this.moduleArray.push({
                module_id: obj.modules[i].module_id,
                description: obj.modules[i].description
              });
            }
          }
        });
  }
  changeTopic(data) {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "usercontroller/ghelp",
      ({
        module_id: data,
      }),
      { headers: headers })
      .subscribe(
        response => {
          this.helpTopic = [];
          var obj = JSON.parse(JSON.stringify(response));
          if (obj.length != 0 && obj.help_topics != undefined) {
            for (var i = 0; i < obj.help_topics.length; i++) {
              this.helpTopic.push({
                helpdesc: obj.help_topics[i].help_desc,
              });
            }
          }
        });
  }
}
