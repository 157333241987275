<div class="row" style="margin:0 auto; width:100%">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">{{title}} </h5>
          </div>
          <div class="headerButtons">
            <img src="../../../assets/ui_icons/buttons/Back_button.svg" (click)="back()"
            class="saveimgbtn_inpatinfo" />
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="cover_div" >
          <div class="header_lable">
             Location filter
          </div>
          <div class="content_cover">
            <div class="row">
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Search country<br>
                  <mat-select disableOptionCentering class="ipcss widthappt" [(ngModel)]="filtCountry"
                    (selectionChange)="changeCountry(filtCountry, '0')">
                    <mat-option disabled>Select</mat-option>
                    <mat-option *ngFor="let country of clntCountryList" value={{country.description}}>
                      {{country.description}}</mat-option>
                  </mat-select>
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Search state<br>
                  <mat-select disableOptionCentering class="ipcss widthappt" required [(ngModel)]="filtState"
                    (selectionChange)="changeState(filtState, '0')">
                    <mat-option disabled>Select</mat-option>
                    <mat-option *ngFor="let state of clntStateList" value={{state.description}}>{{state.description}}
                    </mat-option>
                  </mat-select>
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottomy">
                <mat-label class="matlabel">Search city<br>
                  <mat-select disableOptionCentering class="ipcss widthappt" required [(ngModel)]="filtCity"
                    (selectionChange)="changeCity(filtCity, '0')">
                    <mat-option disabled>Select</mat-option>
                    <mat-option *ngFor="let city of clntCityList" value="{{city.description}}">{{city.description}}
                    </mat-option>
                  </mat-select>
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Search location<br>
                  <input type="text" class="ipcss widthappt" required maxlength="50" (keyup)="changeLocation($event)"
                    [(ngModel)]="locationName" matInput [matAutocomplete]="auto" />
                  <mat-autocomplete #auto="matAutocomplete">
                    <mat-option (click)="selectLocation(location)" *ngFor="let location of clntLocationList"
                      value={{location.description}}>
                      {{location.description}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-label>
              </div>
            </div>
          </div>
      </div>
        <div class="nodata col-12" *ngIf="pharmacyList.length == 0 && diagList.length == 0">
          No pharmacies found
        </div>
          <div class="row" *ngFor="let pharma of pharmacyList">
            <div class="col-12  col-sm-6 col-md-6 col-lg-4 col-xl-3">
            <div class="cover_div" style="padding-bottom: 13px;" *ngIf="pharmaFlag && pharmacyList.length!=0">
              <div class="content_cover">
                  <p>
                    <b>{{pharma.pharmacy_name}}</b>
                  </p>
                  <p class="margin_0">{{pharma.phar_addr1}}, {{pharma.phar_addr2}}</p>
                  <p class="margin_0">{{pharma.phar_loc}}</p>
                  <p class="margin_0">{{pharma.phar_city}} - {{pharma.phar_zipcode}}</p>
                  <p class="margin_0">{{pharma.phar_state}}, {{pharma.phar_cnty}}</p>
                  <p class="margin_0">{{pharma.telephone}}</p>
                  <p class="margin_0">Working days : {{pharma.phar_word_desc}}</p>
                  <p class="margin_0">{{pharma.phar_work_time}}</p>
                  <p class="margin_0">Store pickup validity : {{pharma.phar_valid}}</p>
                  <div *ngIf="pharma.is_del_option">
                    <b>Delivery options</b> <br>
                    <img src="../../../assets/ui_icons/buttons/home_delivery.svg" class="mob_button_image_class_new" *ngIf="pharma.is_home_delivery" (click)="viewHomeDeliveryDetails(pharma.pharma_id,pharma.pharmacy_name,pharma.phar_addr1,pharma.phar_addr2,pharma.phar_loc,pharma.phar_city,pharma.phar_zipcode,pharma.phar_state,pharma.phar_cnty,pharma.telephone)">
                    <img src="../../../assets/ui_icons/buttons/store_pickup_button.svg"  class="mob_button_image_class_new" *ngIf="pharma.is_store_pick" (click)="viewStorePickupDetails(pharma.pharma_id,pharma.pharmacy_name,pharma.phar_addr1,pharma.phar_addr2,pharma.phar_loc,pharma.phar_city,pharma.phar_zipcode,pharma.phar_state,pharma.phar_cnty,pharma.telephone,pharma.phar_valid,pharma.phar_work_time)">
                  </div>
                </div>
              </div>
          </div>
        </div>
        <div class="row diag_row" *ngIf="diagFlag && diagList.length!=0">
          <div class="col-12 col-sm-6 col-lg-6 col-md-6 col-xl-6" *ngFor="let diag of diagList">
            <div>
              <a (click)="viewDiagDetails(diag.center_id,diag.profile_image,diag.center_name,diag.address,diag.location,diag.city,
                diag.state,diag.telephone,diag.website,diag.available,diag.diagTestsList)">
                <mat-card id="cardcontent" style="margin:5px">
                  <mat-card-content>
                    <div class="row">
                      <div class="col-4">
                        <img class="diagimage" src="{{diag.profile_image}}">
                      </div>
                      <div class="col-8">
                        <div class="row">
                          <div class="col-12">
                            <mat-label class="matlabel"><b>{{diag.center_name}}</b></mat-label>
                          </div>
                          <div class="col-12">
                            <mat-label class="matlabel">{{ diag.address}}</mat-label>
                          </div>
                          <div class="col-12">
                            <mat-label class="matlabel">{{diag.location}}</mat-label>
                          </div>
                          <div class="col-12">
                            <mat-label class="matlabel">{{diag.city}}</mat-label>
                          </div>
                          <div class="col-12">
                            <mat-label class="matlabel">{{diag.state}}</mat-label>
                          </div>
                          <div class="col-12">
                            <mat-label class="matlabel">Telephone :{{diag.telephone}}</mat-label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </mat-card-content>
                </mat-card>
              </a>
            </div>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>