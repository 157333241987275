import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from 'src/app/helper_class';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ServerApi } from 'src/app/server-api';
import moment from 'moment';
// import { PharmacyService } from '../pharmacy/pharmacy.service';
import { PharmacyService } from 'src/app/pharma/pharmacy/pharmacy.service';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
import { ipaddress } from '../../../ipaddress';
import { Message_data } from '../../../../assets/js/Message_data';


@Component({
  selector: 'app-pharma-returns',
  templateUrl: './pharma-returns.component.html',
  styleUrls: ['./pharma-returns.component.scss']
})
export class PharmaReturnsComponent implements OnInit {
  public userInfo;
  public pharmacistID;
  public pharmacyID;
  public LoadLocationArray;
  public locationList;
  public location;
  public stockData = [];
  dtOptions: DataTables.Settings = {};
  public checklist = [];
  public checklistid = [];
  public appin_open_count;
  public masterSelected:boolean = false;
  public nodata_flag: boolean = true;
  public productReturn;
  public expiryDate;
  public resonListArray:any=[];
  public resonurl="pharmacontrol/gsrr";
  public returnsdata:boolean=true;
  public reasondata;
  public return_reason:any=[];
  public editbutton:boolean=false;
  public newbutton:boolean=false;
  public deletebutton:boolean=false;
  public printbutton:boolean=false;
  public viewbutton:boolean=false;
  public pagelist: any;
  public sno;
  public currentPage: any;
  public pageSize;
  public pageNavigate: boolean;
  public clientCount
  public prevPageCount: boolean;
  public nextPageCount: boolean;
  public noOfPages: any;
  public returnsproductname;
  


  constructor(public http: HttpClient,public serviceAPI: ServerApi, public toastr: ToastrService,private messageServive : MenuViewService,private messageServive1 : MenuViewService) 
  { 
    this.pageSize = 50;
    this.currentPage = 1;
    this.pageNavigate = true


  }

  ngOnInit(): void {
    // this.dtOptions = {
    //   pagingType: 'simple_numbers_no_ellipses',
    //   pageLength: 10,
    //   "lengthMenu": [[5, 10, 25, 50, -1], [5, 10, 25, 50, "All"]],
    //   "columnDefs": [
    //   ],
    //   // "language": {
    //   //   "search": "<img src='../../../assets/img/search.png' width='18px'/>",
    //   //   searchPlaceholder: "Search by medicine, supplier or batch"
    //   // },
    //   // dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    // };
    this.userInfo = Helper_Class.getInfo();
    if (Helper_Class.getInfo().user_type == 'Admin') {
      this.pharmacyID = this.userInfo.pharma_id;
      this.pharmacistID = this.userInfo.po_approver;
    }
    else {
      this.pharmacyID = this.userInfo.hospitals[0].hptl_clinic_id;
      this.pharmacistID = this.userInfo.user_id;
    }
    this.returnsproductname=undefined;
    this.productReturn = this.userInfo.hospitals[0].product_return;
    this.expiryDate = moment().add(this.productReturn, 'months').format('MM-YYYY');
   // console.log("date" + expiryDate);
   this.pagelist="25"
   this.navpagelist() 
  //  this.get_stock();
    this.reason();
    if(Helper_Class.getmodulelist() != undefined){
    
      console.log("id"+JSON.stringify(Helper_Class.getmodulelist()))
      for (var i = 0; i < Helper_Class.getmodulelist().length; i++) {
        if (Helper_Class.getmodulelist()[i].module_id == "59" && Helper_Class.getmodulelist()[i].description == "Stock returns"){
         
          if(Helper_Class.getmodulelist()[i].edit == "1"){
              this.editbutton=true;
            }
            if(Helper_Class.getmodulelist()[i].create == "1"){
              this.newbutton=true;
            }
            if(Helper_Class.getmodulelist()[i].delete == "1"){
              this.deletebutton=true;
            }
            if(Helper_Class.getmodulelist()[i].print == "1"){
              this.printbutton=true;
            }
            if(Helper_Class.getmodulelist()[i].view == "1"){
              this.viewbutton=true;
            }
            
            
        }
       
      }
    }

  }
  prod_name


  // get_stock() {

  //   var send_data;
  //   send_data = {
  //     pharmacy_id: this.pharmacyID,
  //     pharmacist_id: this.pharmacistID,
  //     product_return: this.productReturn,
  //     expiry_date : this.expiryDate,
  //     limit: this.pagelist,
  //     sno:this.sno
  //   }
  //   var response = await this.serviceAPI.PostData('pharmacontrol/gpstockl', send_data).toPromise();
  //   if (response) {
  //     var obj = JSON.parse(JSON.stringify(response));
  //     console.log("response"+JSON.stringify(obj))
  //     //  this.stockData = obj.stock_onhand;
  //     this.appin_open_count = obj.stock_onhand.length;
      
  //     this.pageNavigate = obj.stock_count > 50 ? false : true;
  //     this.clientCount = obj.stock_count
      
  //     for (var i = 0; i < obj.stock_onhand.length; i++) {
  //       if(obj.stock_onhand[i].qty_onhand !="0") {
  //         this.stockData.push({
  //           id: i + 1,
  //           product_id: obj.stock_onhand[i].product_id,
  //           medicine: obj.stock_onhand[i].short_name + "-" + obj.stock_onhand[i].name,
  //           genericName: obj.stock_onhand[i].generic_name,
  //           uomDesc: obj.stock_onhand[i].uom_desc,
  //           mfgName: obj.stock_onhand[i].mfg_name,
  //           batchNo: obj.stock_onhand[i].batch_no,
  //           exp_date: obj.stock_onhand[i].exp_date,
  //           qty_onhand: obj.stock_onhand[i].qty_onhand,
  //           cost_price: obj.stock_onhand[i].cost_price,
  //           sales_price: obj.stock_onhand[i].sales_price,
  //           mrp: obj.stock_onhand[i].mrp,
  //           mfg_date: obj.stock_onhand[i].mfg_date,
  //           checked: false,
  //         });
  //       }
  //     }
  //     this.nodata_flag = true;
  //     console.log("this.stockData" + JSON.stringify(this.stockData));
  //   }
  // }




  async get_stock() {

    var send_data;


    if(this.returnsproductname != undefined && this.returnsproductname.length !=0){
      send_data = {
        pharmacy_id: this.pharmacyID,
        pharmacist_id: this.pharmacistID,
        product_return: this.productReturn,
        expiry_date : this.expiryDate,
        prod_name:this.returnsproductname
      }
    }else{
send_data = {
      pharmacy_id: this.pharmacyID,
      pharmacist_id: this.pharmacistID,
      product_return: this.productReturn,
      expiry_date : this.expiryDate,
      limit: this.pagelist,
      sno:this.sno,
    }
    }
    
    
    
    
    var response = await this.serviceAPI.PostData('pharmacontrol/gpstockl', send_data).toPromise();
    if (response) {
      var obj = JSON.parse(JSON.stringify(response));
      console.log("response"+JSON.stringify(obj))
      //  this.stockData = obj.stock_onhand;
      this.appin_open_count = obj.stock_onhand.length;
      
      this.pageNavigate = obj.stock_count > 50 ? false : true;
      this.clientCount = obj.stock_count
      this.stockData=[];
      for (var i = 0; i < obj.stock_onhand.length; i++) {
        if(obj.stock_onhand[i].qty_onhand !="0") {
          this.stockData.push({
            id: i + 1,
            product_id: obj.stock_onhand[i].product_id,
            medicine: obj.stock_onhand[i].short_name + "-" + obj.stock_onhand[i].name,
            genericName: obj.stock_onhand[i].generic_name,
            uomDesc: obj.stock_onhand[i].uom_desc,
            // supplier_id:obj.stock_onhand[i].
            mfgName: obj.stock_onhand[i].supplier,
            batchNo: obj.stock_onhand[i].batch_no,
            exp_date: obj.stock_onhand[i].exp_date,
            qty_onhand: obj.stock_onhand[i].qty_onhand,
            cost_price: obj.stock_onhand[i].cost_price,
            sales_price: obj.stock_onhand[i].sales_price,
            mrp: obj.stock_onhand[i].mrp,
            mfg_date: obj.stock_onhand[i].mfg_date,
            checked: false,
          });
        }
      }
      this.nodata_flag = true;
      console.log("this.stockData" + JSON.stringify(this.stockData));
    }
  }
  checkUncheckAll(action) {
    if (action == "select") {
      if (this.checklistid.length == 0) {
        for (var i = 0; i < this.stockData.length; i++) {
        

          this.checklistid.push({
            index: this.stockData[i].id,
            product_id: this.stockData[i].product_id,
            batch_no: this.stockData[i].batchNo,
            qty_return: this.stockData[i].qty_onhand,
            cost_price: this.stockData[i].cost_price,
            sales_price: this.stockData[i].sales_price,
            supplier_id:this.stockData[i].supplier_id,
            mrp: this.stockData[i].mrp,
            mfg_date: this.stockData[i].mfg_date,
            mfg_name : this.stockData[i].mfgName,
            expiry_date: this.stockData[i].exp_date,
            return_reason :this.stockData[i].return_reason
          });
          this.stockData[i].checked = true;
        }
      }
      else {
        for (var i = 0; i < this.stockData.length; i++) {
          for (var j = 0; j < this.checklistid.length; j++) {
            if( this.stockData[i].id == this.checklistid[j].index ){
              this.checklistid.push({
                index: this.stockData[i].id,
                product_id: this.stockData[i].product_id,
                batch_no: this.stockData[i].batchNo,
                qty_return: this.stockData[i].qty_onhand,
                cost_price: this.stockData[i].cost_price,
                supplier_id:this.stockData[i].supplier_id,
                sales_price: this.stockData[i].sales_price,
                mrp: this.stockData[i].mrp,
                mfg_date: this.stockData[i].mfg_date,
                mfg_name : this.stockData[i].mfgName,
                expiry_date: this.stockData[i].exp_date,
                return_reason :this.stockData[i].return_reason
              });
              this.stockData[i].checked = true;
              break;
            }
          }
        }
      }
      this.masterSelected = true;
      
    } else {
      this.checklistid = [];
      for (var i = 0; i < this.stockData.length; i++) {
        this.stockData[i].checked = false;
      }
      this.masterSelected = false;
    }
    console.log("this.stockData" + JSON.stringify(this.stockData.length));
    console.log("this.checklistid" + JSON.stringify(this.checklistid));
  }

  isAllSelected(list, action) {
    
    if(list.id == undefined){
      this.toastr.error(Message_data.returnindex);

      }
      else if(list.return_reason == undefined){
        this.toastr.error(Message_data.returnreason);
      }
      else if(list.product_id  == undefined){
        this.toastr.error(Message_data.returnproduct_id);
      }
      else if(list.batchNo == undefined){
        this.toastr.error(Message_data.returnbatchno);
      }
      else if(list.exp_date == undefined){
        this.toastr.error(Message_data.returnexpiry_date);
      }
      else if(list.mfgName == undefined){
        this.toastr.error(Message_data.returnmfg_name);
      }
      else if(list.qty_onhand == undefined){
        this.toastr.error(Message_data.returnqty_return);}
     else{


    for (var i = 0; i < this.stockData.length; i++) {
      console.log(JSON.stringify(this.stockData[i]))
      if (this.stockData[i].id == list.id) {
        if (action == "select") {
          this.checklistid.push({
            index: list.id,
            product_id: list.product_id,
            batch_no: list.batchNo,
            qty_return: list.qty_onhand,
            cost_price: list.cost_price,
            sales_price: list.sales_price,
            mrp: list.mrp,
            mfg_date: list.mfg_date,
            mfg_name : list.mfgName,
            expiry_date: list.exp_date,
            return_reason:list.return_reason
          });
          this.stockData[i].checked = true;
        } else {
          for (var j = 0; j < this.checklistid.length; j++) {
            if (this.checklistid[j].index == list.id) {
              this.checklistid.splice(j,1);
              this.stockData[i].checked = false;
              break;
            }
          }
        }
        break;
      }
    }
  }
    console.log("this.stockData = " + JSON.stringify(this.stockData));
    console.log("this.checklistid = " + JSON.stringify(this.checklistid));
  }
  quantity(list){
    if( list.checked == true ){
      for( var i = 0;i < this.checklistid.length; i++){
          if( this.checklistid[i].index == list.id ){
            this.checklistid[i].qty_return = list.qty_onhand;
          }
      }
    }
    console.log("this.checklistid" + JSON.stringify(this.checklistid));
  }

  async saveOrder(){

     if(this.checklistid.length != 0 ){
      // for(var i=0;i<this.checklistid.length;i++){
        
      
      //   console.log(" check id = "+JSON.stringify(this.checklistid[i]));
      //   // ,,,,,

       

      // }
      
      
      
      var send_data;
      send_data = {
        pharma_id: this.pharmacyID,
        products: this.checklistid,
        country : "IN"
      }
      console.log("send data" + JSON.stringify(send_data));
      var response = await this.serviceAPI.PostData('pharmacontrol/sprt', send_data).toPromise();
      if (response) {
        var obj = JSON.parse(JSON.stringify(response));
        console.log("obj" + JSON.stringify(obj));
        if( obj.key == 1 ){
          this.messageServive.sendMessage("productReturns");
          this.toastr.success("Product return as been intiated successfully");
          for(var q=0;q<this.stockData.length;q++){
            if(this.stockData[q].checked == true){
              this.stockData[q].checked = false;
              this.stockData[q].return_reason=""
            }
          }
        }else{
          this.toastr.error("Unable to save  product returns");
        }
      }
     }else{
      this.toastr.error("Please select atleast one product to return");
     }
  }
 reason(){
  var headers=new HttpHeaders();
  headers.append('Content-Type', 'application/json');
  this.http.get(ipaddress.getIp.toString() + this.resonurl,{ headers: headers })
.subscribe(
response => {
var obj=JSON.parse(JSON.stringify(response));
this.reasondata=obj.return_reasons


console.log("obj resons "+JSON.stringify(this.reasondata))


} 
)}
 

printreturndata(){
}



navpagelist() {
  this.currentPage = 1;
  this.sno = 1;
  this.pageSize = this.pagelist;
  this.get_stock();
  console.log(this.stockData.length);
  if (this.pagelist == "25") {
    if (this.clientCount > 25) {
      this.pageNavigate = false;
      this.nextPageCount = false;
      this.prevPageCount = false;
    } else {
      this.pageNavigate = false;
      this.nextPageCount = false;
      this.prevPageCount = false;
    }
  } else if (this.pagelist == "50") {
    if (this.clientCount > 50) {
      this.pageNavigate = false;
      this.nextPageCount = false;
      this.prevPageCount = false;
    } else {
      this.pageNavigate = true;
      this.nextPageCount = true;
      this.prevPageCount = true;
    }
  } else if (this.pagelist == "100") {
    if (this.clientCount > 100) {
      this.pageNavigate = false;
      this.nextPageCount = false;
      this.prevPageCount = false;
    } else {
      this.pageNavigate = true;
      this.nextPageCount = true;
      this.prevPageCount = true;
    }
  }
}

pageLimitnext() {


  if (this.currentPage == this.noOfPages){
    this.nextPageCount = true;}
  else{
  this.currentPage = parseInt(this.currentPage);
  // console.log("check --"+this.currentPage)
  this.pageSize = this.pagelist;
  this.prevPageCount = false;
  this.sno = (parseInt(this.currentPage) * parseInt(this.pagelist)) + 1;
  this.get_stock();
  if (this.currentPage == this.noOfPages)
    this.nextPageCount = true;

  
  this.currentPage = this.currentPage + 1;
  }
}

pageLimitPrevious() {
  if (this.currentPage == "1"){
    this.prevPageCount = true;}
  else{
    this.currentPage = this.currentPage - 1;
  // console.log("check --"+this.currentPage);
  this.pageSize = this.pagelist;
  if (this.currentPage != 1)
    this.sno = ((parseInt(this.currentPage) - 1) * parseInt(this.pagelist)) + 1;
  else if (this.currentPage == 1)
    this.sno = 1;

  // console.log(this.currentLimit);
  this.get_stock();
  this.nextPageCount = false;
  }
}

numberOfPages() {
  this.noOfPages = Math.ceil(this.clientCount / this.pagelist);
  return Math.ceil(this.clientCount / this.pagelist);
  // console.log("client count "+this.clientCount+"...."+this.pagelist)
  // this.noOfPages = Math.floor(this.clientCount / this.pagelist);
  console.log("client count " + this.noOfPages)

  // return Math.floor(this.clientCount / this.pagelist);
}



}
