<div mat-dialog-title class="popup_border">
  <h5 class="header_txt">Diet plan</h5>
  <div mat-dialog-actions>
    <img src="../../../assets/img/close_icon.png" (click)="ClosePopup()" width="20px" height="20px" id="close_icd" />
  </div>
</div>
<div mat-dialog-content class="diet_content">
  <div [hidden]="dietarray.length !=0" class="nodata">No data found</div>
  <div class="row" style="max-height: 400px;margin-top: 10px;">
    <table [hidden]="dietarray.length == 0" id="tbl">
      <thead [hidden]="dietarray.length == 0">
        <tr>
          <th>Category</th>
          <th>Food type</th>
          <th>Food item</th>
          <th>Qty</th>
          <th>Measure</th>
          <th>Weight(g)</th>
          <th>Kcals</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody *ngFor="let diet of dietarray">
        <tr *ngIf="diet.day == day">
          <td>{{diet.category}}</td>
          <td>{{diet.food_sub_type}}</td>
          <td>{{diet.description}}</td>
          <td>{{diet.quantity}}</td>
          <td>{{diet.measure}}</td>
          <td>{{diet.weight}}</td>
          <td>{{diet.energy}}</td>
          <td>
            <a (click)="delete_item(diet.description)"><img src="../../../assets/ui_icons/trash_icon.svg" width="17px" height="auto"/></a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

</div>
<div mat-dialog-actions align="end" style="width: auto;right: 19px;">
  <button id="add_btn" [mat-dialog-close]="data" cdkFocusInitial mat-button (click)="submit_click()">
    <img src="../../../assets/ui_icons/buttons/ok_button.svg" class="addimgbtn_icd" />
  </button>
</div>