import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { MatDialog } from '@angular/material/dialog';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';

@Component({
  selector: 'app-bloodeligibility',
  templateUrl: './bloodeligibility.component.html',
  styleUrls: ['./bloodeligibility.component.css']
})
export class BloodeligibilityComponent implements OnInit {
  public currentDate;
  public getDate;
  public currentMaxDate;
  public currentYear;
  public date;
  public currentTime;
  public KidneyDisease;
  public toothInfected = "no";
  public toothDate;
  public toothInfectedFlag: Boolean = false;
  public toothToggle;
  public fillInfected = "no";
  public fillDate;
  public fillInfectedFlag: Boolean = false;
  public fillToggle;
  public scaleInfected = "no";
  public scaleDate;
  public scaleInfectedFlag: Boolean = false;
  public scaleToggle;
  public rootInfected = "no";
  public rootDate;
  public rootInfectedFlag: Boolean = false;
  public rootToggle;
  public teethInfected;
  public teethControl: Boolean;
  public teethInfectedFlag: Boolean;
  public teethToggle;
  public tbControl;
  public tbCured;
  public tbCuredFlag: Boolean = false;
  public tbToggle;
  public bp;
  public bpFlag: Boolean;
  public bpToggle;
  public curedDate;
  public cured;
  public curedFlag: Boolean;
  public curedToggle;
  public seizurFreeDate;
  public seizurFree;
  public seizurFreeFlag: Boolean = false;
  public seizurFreeToggle;
  public kidneyDiseaseFlag: Boolean = false;
  public kidneyDiseaseToggle;
  public malariaDate;
  public malariaCured;
  public malariaCuredFlag: Boolean = false;
  public malariaToggle;
  public heptisDate;
  public heptisCured;
  public heptisCuredFlag: Boolean = false;
  public heptisToggle;
  public bloodDate;
  public bloodToggle;
  public asthma;
  public asthmaCuredFlag: Boolean = false;
  public asthmaToggle;
  public cancerDate;
  public cancerCured = "no";
  public cancerCuredFlag: Boolean = false;
  public cancerToggle;
  public hivToggle;
  public hivCuredFlag: Boolean = false;
  public hivCured = "no";
  public hivDate;
  public bloodEligibltySaveUrl;
  public heartToggle;
  public hivDateMod;
  public cancerDateMod;
  public bloodDateMod;
  public hepatisDateMod;
  public malariaDateMod;
  public seizurFreeDateMod;
  public curedDateMod;
  public tbDateMod;
  public teethDateMod;
  public rootDateMod;
  public scaleDateMod;
  public fillDateMod;
  public toothDateMod;
  public sendData;
  public bloodEligibltyRtrvUrl;
  public sendRetrivData;
  public check;
  @Input() id: string; parm: string;
  public hivOpt: boolean;
  public cancerOpt: boolean;
  public asthmaOpt: boolean;
  public bloodTransOpt: boolean;
  public heptisOpt: boolean;
  public malariaOpt: boolean;
  public kidneyOpt: boolean;
  public heartDisOpt: boolean;
  public epilepsyOpt: boolean;
  public coldFluOpt: boolean;
  public bpOpt: boolean;
  public tbOpt: boolean;
  public teethOpt: boolean;
  public rootOpt: boolean;
  public scaleOpt: boolean;
  public fillOpt: boolean;
  public toothOpt: boolean;
  public asthmaDate: boolean;
  public bpflage: boolean;
  public saveFlag: boolean = true;

  constructor(public gservice: CommonDataService, public toastr: ToastrService, public http: Http, public router: Router, public dialog: MatDialog) {
    this.bloodEligibltySaveUrl = ipaddress.getIp + "bd/sdclist";
    this.bloodEligibltyRtrvUrl = ipaddress.getIp + "bd/gdclist";
    this.sendRetrivData = {
      client_reg_id: gservice.Client_login_detail_data.Client_Id
    }
  }

  ngOnInit() {
    this.saveFlag = true;
    this.getCurrentDate();
    this.appretreivData();
  }

  myCallbackFunction = function (_params) {
    return new Promise((resolve, reject) => {
      resolve(_params);
    });
  }

  appretreivData() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.bloodEligibltyRtrvUrl, JSON.stringify(this.sendRetrivData), { headers: headers }).subscribe(
      data => {
        if (data.json().checklist_id != null && data.json().checklist_id != undefined)
          this.check = data.json().checklist_id;

        if (data.json().HIV != null && data.json().HIV == "yes") {
          this.hivToggle = true;
          this.hivToggle = "yes";
          this.hivOpt = true;
          this.hivCuredFlag = true;
          if (data.json().HIV_cured != null && data.json().HIV_cured == "yes") {
            this.hivCured = "yes";
            this.hivDate = true;
            if (data.json().HIV_cured_date != undefined)
              this.hivDateMod = (data.json().HIV_cured_date)
          } else {
            this.hivCured = "no"
            this.hivDate = false;
          }
        } else {
          this.hivToggle = false;
          this.hivToggle = "no";
          this.hivOpt = false;
        }

        if (data.json().cancer != null && data.json().cancer == "yes") {
          this.cancerToggle = "yes";
          this.cancerCuredFlag = true;
          this.cancerOpt = true;
          if (data.json().cancer_cure != null && data.json().cancer_cure == "yes") {
            this.cancerCured = "yes";
            this.cancerDate = true;
            if (data.json().cancer_cure_date != undefined)
              this.cancerDateMod = (data.json().cancer_cure_date)
          } else {
            this.cancerCured = "no"
            this.cancerDate = false;
          }
        } else {
          this.cancerOpt = false;
          this.cancerToggle = "no";
          this.cancerCuredFlag = false;
        }

        if (data.json().astma != null && data.json().astma == "yes") {
          this.asthmaToggle = "yes";
          this.asthmaCuredFlag = true;
          this.asthmaOpt = true;

          if (data.json().astma_control != null && data.json().astma_control == "yes") {
            this.asthmaDate = true;
            this.asthma = "yes";
          } else {
            this.asthmaDate = false;
            this.asthma = "no"
          }
        } else {
          this.asthmaToggle = "no";
          this.asthmaCuredFlag = false;
          this.asthmaOpt = false;
        }

        if (data.json().blood_transfusion != null && data.json().blood_transfusion == "yes") {
          this.bloodToggle = "yes";
          this.bloodDate = true;
          this.bloodTransOpt = true;
          if (data.json().blood_trans_date != undefined)
            this.bloodDateMod = (data.json().blood_trans_date)
        } else {
          this.bloodToggle = false;
          this.bloodDate = false;
          this.bloodTransOpt = false;
        }
        if (data.json().hepatitis != null && data.json().hepatitis == "yes") {
          this.heptisToggle = "yes";
          this.heptisCuredFlag = true;
          this.heptisOpt = true;

          if (data.json().hepatitis_cure != null && data.json().hepatitis_cure == "yes") {
            this.heptisCured = "yes";
            this.heptisDate = true;
            if (data.json().hepatitis_cure_date != undefined)
              this.heptisCured = (data.json().hepatitis)
          } else {
            this.heptisCured = "no"
            this.heptisDate = false;
          }
        } else {
          this.heptisToggle = false;
          this.heptisCuredFlag = false;
          this.heptisOpt = false;
        }
        if (data.json().malaria != null && data.json().malaria == "yes") {
          this.malariaToggle = "yes";
          this.malariaCuredFlag = true;
          this.malariaOpt = true;

          if (data.json().malaria_cure != null && data.json().malaria_cure == "yes") {
            this.malariaCured = "yes";
            this.malariaDate = true;
            if (data.json().malaria_cure_date != undefined)
              this.malariaDateMod = (data.json().malaria_cure_date)
          } else {
            this.malariaCured = "no"
            this.malariaDate = false;
          }
        } else {
          this.malariaToggle = "no";
          this.malariaCuredFlag = false;
          this.malariaOpt = false;
        }

        if (data.json().kidney != null && data.json().kidney == "yes") {
          this.kidneyDiseaseToggle = "yes";
          this.kidneyDiseaseFlag = true;
          this.kidneyOpt = true;
          if (data.json().kidney_stage != null) {
            this.KidneyDisease = data.json().kidney_stage
          }
        } else {
          this.kidneyDiseaseToggle = "no";
          this.kidneyDiseaseFlag = false;
          this.kidneyOpt = false;
        }

        if (data.json().heart_disease != null && data.json().heart_disease == "yes") {
          this.heartToggle = "yes";
          this.heartDisOpt = true;
        } else {
          this.heartToggle = "no";
          this.heartDisOpt = false;
        }

        if (data.json().epilepsy != null && data.json().epilepsy == "yes") {
          this.seizurFreeToggle = "yes";
          this.seizurFreeFlag = true;
          this.epilepsyOpt = true;
          if (data.json().seizure_free != null && data.json().seizure_free == "yes") {
            this.seizurFree = "yes";
            this.seizurFreeDate = true;
            if (data.json().epilepsy_cure_date != undefined)
              this.seizurFreeDateMod = (data.json().epilepsy_cure_date)
          } else {
            this.seizurFree = "no"
            this.seizurFreeDate = false;
          }
        } else {
          this.seizurFreeToggle = "no";
          this.seizurFreeFlag = false;
          this.epilepsyOpt = false;
        }

        if (data.json().cold_flu_sorethroat != null && data.json().cold_flu_sorethroat == "yes") {
          this.coldFluOpt = true;
          this.curedToggle = true;
          this.curedFlag = true;

          if (data.json().cold_cure != null && data.json().cold_cure == "yes") {
            this.cured = "yes";
            this.curedDate = true;
            if (data.json().cold_cure_date != undefined)
              this.curedDateMod = (data.json().cold_cure_date)
          } else {
            this.cured = "no"
            this.curedDate = false;
          }
        } else {
          this.curedToggle = false;
          this.curedFlag = false;
          this.coldFluOpt = false;
        }

        if (data.json().BP != null && data.json().BP == "yes") {
          this.bpToggle = "yes";
          this.bpOpt = true;
          
          if (data.json().BP_under_control != null && data.json().BP_under_control == "yes") {
            this.bpflage = true;
            this.bpFlag = true;
            this.bp = "yes";
          } else {
            this.bpflage = false;
            this.bpFlag = false;
            this.bp = "no"
          }
        } else {
          this.bpToggle = "no";
          this.bpOpt = false;
        }
        if (data.json().tb != null && data.json().tb == "yes") {
          this.tbToggle = "yes";
          this.tbCuredFlag = true;
          this.tbOpt = true;

          if (data.json().tb_cure != null && data.json().tb_cure == "yes") {
            this.tbCured = "yes";
            this.tbControl = true;
            if (data.json().tb_cure_date != undefined)
              this.tbDateMod = (data.json().tb_cure_date)
          } else {
            this.tbCured = "no"
            this.tbControl = false;
          }
        } else {
          this.tbToggle = "no";
          this.tbCuredFlag = false;
          this.tbOpt = false;
        }
        if (data.json().teeth_cleaning != null && data.json().teeth_cleaning == "yes") {
          this.teethToggle = "yes";
          this.teethInfectedFlag = true;
          this.teethOpt = true;
          this.teethControl = true;
          if (data.json().teeth_cleaning_infect != null && data.json().teeth_cleaning_infect == "yes") {
            this.teethInfectedFlag = true;
            this.teethControl = true;
            this.teethInfected = "yes";
          } else {
            this.teethControl = false;
            this.teethInfected = "no"
          }
          if (data.json().teeth_clean_date != undefined) {
            this.teethDateMod = (data.json().teeth_clean_date)
          }
        } else {
          this.teethToggle = "no";
          this.teethInfectedFlag = false;
          this.teethControl = false;
          this.teethOpt = false;
        }

        if (data.json().root_canal != null && data.json().root_canal == "yes") {
          this.rootToggle = "yes";
          this.rootInfectedFlag = true;
          this.rootDate = true;
          this.rootOpt = true;
          if (data.json().root_canal_infect != null && data.json().root_canal_infect == "yes") {
            this.rootDate = true;
            this.rootInfected = "yes";
          } else {
            this.rootDate = false;
            this.rootInfected = "no";
          }
          if (data.json().root_canal_date != undefined)
            this.rootDateMod = (data.json().root_canal_date)
        } else {
          this.rootToggle = "np";
          this.rootInfectedFlag = false;
          this.rootDate = false;
          this.rootOpt = false;
        }

        if (data.json().scalling != null && data.json().scalling == "yes") {
          this.scaleToggle = "yes";
          this.scaleInfectedFlag = true;
          this.scaleDate = true;
          this.scaleOpt = true;
          if (data.json().scalling_infect != null && data.json().scalling_infect == "yes") {
            this.scaleDate = true;
            this.scaleInfected = "yes";
          } else {
            this.scaleDate = false;
            this.scaleInfected = "no"
          }
          if (data.json().scalling_infect_date != undefined)
            this.scaleDateMod = (data.json().scalling_infect_date)
        } else {
          this.scaleToggle = "no";
          this.scaleInfectedFlag = false;
          this.scaleDate = false;
          this.scaleOpt = false;
        }

        if (data.json().filling != null && data.json().filling == "yes") {
          this.fillToggle = "yes";
          this.fillInfectedFlag = true;
          this.fillDate = true;
          this.fillOpt = true;
          if (data.json().filling_infect != null && data.json().filling_infect == "yes") {
            this.fillDate = true;
            this.fillInfected = "yes";
          } else {
            this.fillDate = false;
            this.fillInfected = "no"
          }
          if (data.json().filling_date! = null) {
            this.fillDateMod = (data.json().filling_date)
          }
        } else {
          this.fillToggle = "no";
          this.fillInfectedFlag = false;
          this.fillDate = false;
          this.fillOpt = false;
        }

        if (data.json().tooth_extract != null && data.json().tooth_extract == "yes") {
          this.toothToggle = "yes";
          this.toothInfectedFlag = true;
          this.toothDate = true;
          this.toothOpt = true;
          if (data.json().tooth_extract_infect != null && data.json().tooth_extract_infect == "yes") {
            this.toothDate = true;
            this.toothInfected = "yes";
          } else {
            this.toothDate = false;
            this.toothInfected = "no"
          }
          if (data.json().tooth_extract_date != undefined)
            this.toothDateMod = (data.json().tooth_extract_date)
        } else {
          this.toothToggle = "no";
          this.toothInfectedFlag = false;
          this.toothDate = false;
          this.toothOpt = false;
        }
      }, error => {});
  }

  getHiv() {
    if (this.hivToggle == "yes") {
      this.hivToggle = "yes";
      this.hivCuredFlag = true;
      this.hivCured = "no";
    }else {
      this.getHivCured('no');
      this.hivToggle = "no";
      this.hivCuredFlag = false;
      this.hivDate = false;
    }
  }

  getHivCured(e) {
    if (e == "yes") {
      this.hivCured = "yes"
      this.hivDate = true;
    } else {
      this.hivDate = false;
    }
  }

  getCancer() {
    if (this.cancerToggle == "Yes" || this.cancerToggle == "yes") {
      this.cancerCuredFlag = true;
      this.getCancerCured('yes')
    }
    if (this.cancerToggle == "No" || this.cancerToggle == "no") {
      this.cancerCuredFlag = false;
      this.cancerDate = false;
    }
  }

  getCancerCured(e) {
    if (e == "yes") {
      this.cancerCured = "yes";
      this.cancerDate = true;
    } else {
      this.cancerCured = "no";
      this.cancerDate = false;
    }
  }

  getAsthma() {
    if (this.asthmaToggle == "yes") {
      this.getAsthmaCured('yes');
      this.asthmaCuredFlag = true;
      this.asthma = "no";
    } if (this.asthmaToggle == "no") {
      this.asthmaToggle = "no";
      this.asthmaCuredFlag = false;
    }
  }

  getBpCured(e) {
    if (e == 'yes') {
      this.bpflage = true;
      this.bp = "yes";
    } else {
      this.bpflage = false;
      this.bp = "no";
    }
  }

  getAsthmaCured(e) {
    if (e == "yes") {
      this.asthmaDate = true;
      this.asthma="yes";
    } else {
      this.asthmaDate = false;
      this.asthma="no";
    }
  }

  getTeethCured(e) {
    if (e == 'yes') {
      this.teethInfected = "yes"
      this.teethControl = true;
    } else {
      this.teethInfected = "no"
      this.teethControl = false;
    }
  }

  getRootCured(e) {
    if (e == "yes") {
      this.rootDate = true;
      this.rootInfected = "yes";
    } else {
      this.rootDate = false;
      this.rootInfected = "no";
    }
  }

  getScaleCured(e) {
    if (e == 'yes') {
      this.scaleInfected = 'yes';
      this.scaleDate = true;
    } else {
      this.scaleDate = false;
      this.scaleInfected = 'no';
    }
  }

  getFillCured(e) {
    if (e == 'yes') {
      this.fillInfected = 'yes';
      this.fillDate = true;
    } else {
      this.fillDate = false;
      this.fillInfected = 'no';
    }
  }

  getToothCured(e) {
    if (e == 'yes') {
      this.toothDate = true;
      this.toothInfected = 'yes';
    } else {
      this.toothDate = false;
      this.toothInfected = 'no';
    }
  }

  getBlood() {
    if (this.bloodToggle == "yes" || this.bloodToggle == "Yes") {
      this.bloodDate = true;
    }else if (this.bloodTransOpt == false) {
      this.bloodToggle = "no"
      this.bloodDate = false;
    }
  }

  getHepatis() {
    if (this.heptisToggle == "Yes" || this.heptisToggle == "yes") {
      this.heptisCuredFlag = true;
      this.getHepatisCured('yes')
    }else {
      this.heptisCured = "no"
      this.heptisCuredFlag = false;
      this.heptisDate = false;
    }
  }

  getHepatisCured(e) {
    if (e == "yes") {
      this.heptisCured = "yes"
      this.heptisDate = true;
    } else {
      this.heptisCured = "no"
      this.heptisDate = false;
    }
  }

  getMalaria() {
    if (this.malariaToggle == "yes" || this.malariaToggle == "Yes") {
      this.malariaCuredFlag = true;
      this.getMalariaCured('yes')
      this.malariaCured = "yes";
    }else if (this.malariaToggle == "no" || this.malariaToggle == "No") {
      this.malariaToggle = "no"
      this.malariaCuredFlag = false;
      this.malariaDate = false;
      this.malariaCured = "no";
    }
  }

  getMalariaCured(e) {
    this.malariaDate = e == "yes" ? true : false;
  }

  getKidney() {
    if (this.kidneyDiseaseToggle == "yes" || this.kidneyDiseaseToggle == "Yes") {
      this.kidneyDiseaseFlag = true;
    } else {
      this.kidneyDiseaseToggle = "no";
      this.kidneyDiseaseFlag = false;
    }
  }

  getEpilepsy() {
    if (this.seizurFreeToggle == "yes" || this.seizurFreeToggle == "Yes") {
      this.seizurFreeFlag = true;
      this.getEpilepsyCured('yes');
    } else {
      this.getEpilepsyCured('no');
      this.seizurFreeToggle = "no"
      this.seizurFreeFlag = false;
      this.seizurFreeDate = false;
    }
  }

  getEpilepsyCured(e) {
    if (e == "yes") {
      this.seizurFree="yes";
      this.seizurFreeDate = true;
    } else {
      this.seizurFree="no";
      this.seizurFreeDate = false;
    }
  }

  getCold() {
    if (this.curedToggle == "yes" || this.curedToggle == "Yes") {
      this.curedFlag = true;
      this.getColdCured('yes')
      this.cured = "yes";
    } else {
      this.curedFlag = false;
      this.curedDate = false;
      this.cured = "no";
    }
  }

  getColdCured(e) {
    if (e == "yes") {
      this.cured = "yes";
      this.curedDate = true;
    } else {
      this.cured = "no";
      this.curedDate = false;
    }
  }

  getBp() {
    if (this.bpToggle == "yes" || this.bpToggle == "Yes") {
      this.bpFlag = true;
      this.getBpCured('yes');
    } else {
      this.getBpCured('no');
      this.bpFlag = false;
    }
  }

  getTb() {
    if (this.tbToggle == "yes" || this.tbToggle == "Yes") {
      this.tbCuredFlag = true;
      this.getTbCured('yes')
    } else {
      this.tbCuredFlag = false;
      this.tbControl = false;
    }
  }

  getTbCured(e) {
    if (e == "yes") {
      this.tbCured = "yes";
      this.tbControl = true;
    } else {
      this.tbCured = "no";
      this.tbControl = false;
    }
  }

  getTeeth() {
    if (this.teethToggle == "yes" || this.teethToggle == "Yes") {
      this.teethInfectedFlag = true;
      this.teethControl = true;
      this.teethInfected = "yes";
      this.getTeethCured('yes')
    } else {
      this.teethInfectedFlag = false;
      this.teethControl = false;
      this.teethInfected = "no"
    }
  }

  getRoot() {
    if (this.rootToggle == "yes") {
      this.rootInfectedFlag = true;
      this.rootDate = true;
      this.rootInfected = "yes";
      this.getRootCured('yes');
    } else {
      this.rootInfectedFlag = false;
      this.rootInfected = "no";
      this.rootDate = false;
    }
  }

  getScale() {
    if (this.scaleToggle == "yes") {
      this.scaleInfectedFlag = true;
      this.scaleDate = true;
      this.scaleInfected = "yes";
      this.getScaleCured('yes');
    } else {
      this.scaleInfected = "no";
      this.scaleInfectedFlag = false;
      this.scaleDate = false;
    }
  }

  getFill() {
    if (this.fillToggle == "yes") {
      this.fillInfectedFlag = true;
      this.fillDate = true;
      this.fillInfected = "yes";
      this.getFillCured('yes');
    } else {
      this.fillInfectedFlag = false;
      this.fillInfected = "no";
      this.fillDate = false;
    }
  }

  getTooth() {
    if (this.toothToggle == "yes") {
      this.toothInfectedFlag = true;
      this.toothDate = true;
      this.toothInfected = "yes";
    } else {
      this.toothInfectedFlag = false;
      this.toothInfected = "no";
      this.toothDate = false;
    }
  }

  save() {
    this.sendData = {};
    this.sendData.client_reg_id = this.gservice.Client_login_detail_data.Client_Id;
    if (this.check != null && this.check != "" && this.check != undefined) {
      this.sendData.checklist_id = this.check;
    }
    if (this.hivToggle == "yes") {
      this.sendData.HIV = "yes";
      this.sendData.HIV_cured = this.hivCured;
      if (this.hivCured == "yes") {
        this.sendData.HIV_cured_date = (this.hivDateMod)
      }
    } else {
      this.sendData.HIV = "no";
    }

    if (this.cancerToggle == "yes") {
      this.sendData.cancer = "yes";
      this.sendData.cancer_cure = this.cancerCured;
      if (this.cancerCured == "yes") {
        this.sendData.cancer_cure_date = (this.cancerDateMod)
      }
    } else {
      this.sendData.cancer = "no";
    }

    if (this.asthmaToggle == "yes") {
      this.sendData.astma = "yes";
      this.sendData.astma_control = this.asthma;
    } else {
      this.sendData.astma = "no";
    }
    if (this.bloodToggle == "yes") {
      this.sendData.blood_transfusion = "yes";
      this.sendData.blood_trans_date = (this.bloodDateMod);
    } else {
      this.sendData.blood_transfusion = "no";
    }

    if (this.heptisToggle == "yes") {
      this.sendData.hepatitis = "yes";
      this.sendData.hepatitis_cure = this.heptisCured;
      if (this.heptisCured == "yes") {
        this.sendData.hepatitis_cure_date = (this.hepatisDateMod)
      }
    } else {
      this.sendData.hepatitis = "no";
    }

    if (this.malariaToggle == "yes") {
      this.sendData.malaria = "yes";
      this.sendData.malaria_cure = this.malariaCured;

      if (this.malariaCured == "yes") {
        this.sendData.malaria_cure_date = (this.malariaDateMod)
      }
    } else {
      this.sendData.malaria = "no";
    }

    if (this.kidneyDiseaseToggle == "yes") {
      this.sendData.kidney = "yes";
      this.sendData.kidney_stage = this.KidneyDisease;  
    } else {
      this.sendData.kidney = "no";
    }

    this.sendData.heart_disease = this.heartToggle == "yes" ? "yes" : "no";

    if (this.seizurFreeToggle == "yes") {
      this.sendData.epilepsy = "yes";
     this.sendData.seizure_free = this.seizurFree;
      if (this.seizurFree == "yes") {
        this.sendData.epilepsy_cure_date = (this.seizurFreeDateMod)
      }
    } else {
      this.sendData.epilepsy = "no";
    }

    if (this.curedToggle == "yes") {
      this.sendData.cold_flu_sorethroat = "yes";
      this.sendData.cold_cure = this.cured == "yes" ? "yes" : "no";

      if (this.cured == "yes") {
        this.sendData.cold_cure_date = (this.curedDateMod)
      }
    } else {
      this.sendData.cold_flu_sorethroat = "no";
    }

    if (this.bpToggle == "yes") {
      this.sendData.BP = "yes";
      this.sendData.BP_under_control = this.bp == "yes" ? "yes" : "no";
    } else {
      this.sendData.BP = "no";
    }

    if (this.tbToggle == "yes") {
      this.sendData.tb = "yes";
      this.sendData.tb_cure = this.tbCured;
      if (this.tbCured == "yes") {
        this.sendData.tb_cure_date = (this.tbDateMod)
      }
    } else {
      this.sendData.tb = "no";
    }

    if (this.teethToggle == "yes") {
      this.sendData.teeth_cleaning = "yes";
      this.sendData.teeth_cleaning_infect = this.teethInfected;
      this.sendData.teeth_clean_date = (this.teethDateMod)
    } else {
      this.sendData.teeth_cleaning = "no";
    }

    if (this.scaleToggle == "yes") {
      this.sendData.scalling = "yes";
      this.sendData.scalling_infect = this.scaleInfected;
      this.sendData.scalling_infect_date = (this.scaleDateMod)
    } else {
      this.sendData.scalling = "no";
    }

    if (this.rootToggle == "yes") {
      this.sendData.root_canal = "yes";
      this.sendData.root_canal_infect = this.rootInfected;
      this.sendData.root_canal_date = (this.rootDateMod);
    } else {
      this.sendData.root_canal = "no";
    }

    if (this.fillToggle == "yes") {
      this.sendData.filling = "yes";
      this.sendData.filling_infect = this.fillInfected;
      this.sendData.filling_date = (this.fillDateMod)
    } else {
      this.sendData.filling = "no";
    }

    if (this.toothToggle == "yes") {
      this.sendData.tooth_extract = "yes";
      this.sendData.tooth_extract_infect = this.toothInfected;
      this.sendData.tooth_extract_date = (this.toothDateMod)
    } else {
      this.sendData.tooth_extract = "no";
    }
    
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.bloodEligibltySaveUrl, JSON.stringify(this.sendData), { headers: headers }).subscribe(
      data => {
        this.saveFlag = false;
        this.toastr.success(data.json().result);
        this.ngOnInit();
      },error => {this.saveFlag = true;});
  }

  selectDate(value) {
    if (value == "hiv_date_mod")
      this.hivDateMod = value;
    else if (value == "cancer_date_mod")
      this.cancerDateMod = value;
    else if (value == "blood_date_mod") 
      this.bloodDateMod = value
    else if (value == "hepatis_date_mod")
      this.hepatisDateMod = value;
    else if (value == "malaria_date_mod") 
      this.malariaDateMod = value;
    else if (value == "epilepsy_date_mod") 
      this.seizurFreeDateMod = value;
    else if (value == "cold_date_mod") 
      this.curedDateMod = value;
    else if (value == "tb_date_mod") 
      this.tbDateMod = value;
    else if (value == "teeth_date_mod") 
      this.teethDateMod = value;
    else if (value == "root_date_mod") 
      this.rootDateMod = value;
    else if (value == "scale_date_mod")
      this.scaleDateMod = value;
    else if (value == "fill_date_mod") 
      this.fillDateMod = value;
    else if (value == "tooth_date_mod")
      this.toothDateMod = value;

    if (value != null) {
      this.appDateChange((value));
    }
  }

  appDateChange(hiv_date_mod) {
    if (this.currentDate != hiv_date_mod) {
      var gsDayNames = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
      ];

      var get_date = hiv_date_mod.split("-");
      var result = new Date(get_date[2] + "-" + get_date[1] + "-" + get_date[0]);
      var dayName = gsDayNames[result.getDay()];
      var Available_flag_1 = false;
    }
  }

  changeHIv(id, value) {
    if (value == "yes") {
      this.hivOpt = true;
      this.hivToggle = "yes"
      this.getHiv();
      this.hivCuredFlag = true;
    }else {
      this.hivOpt = false;
      this.hivToggle = "yes";
      this.hivCured = "no";
      this.hivCuredFlag = false;
    }
  }

  changeCancer(id, value) {
    if (value == "yes") {
      this.cancerOpt = true;
      this.cancerToggle = "yes"
      this.getCancer();
      this.cancerCured = "yes";
      this.cancerCuredFlag = true;
    }else {
      this.cancerOpt = false;
      this.cancerToggle = "no";
      this.cancerCured = "no";
      this.cancerCuredFlag = false;
    }
  }

  changeAsthma(id, value) {
    if (value == "yes") {
      this.asthmaOpt = true;
      this.asthmaToggle = "yes"
      this.getAsthma();
      this.asthmaCuredFlag = true;
    }else {
      this.asthmaOpt = false;
      this.asthmaToggle = "no";
      this.asthmaCuredFlag = false
    }
  }

  changeBlood(id, value) {
    if (value == "yes") {
      this.bloodTransOpt = true;
      this.bloodToggle = "yes"
      this.getBlood();
      this.bloodDate = true;
    }else {
      this.bloodTransOpt = false;
      this.bloodToggle = "no";
      this.bloodDate = false;
    }
  }

  changeHepatitis(id, value) {
    if (value == "yes") {
      this.heptisOpt = true;
      this.heptisToggle = "yes"
      this.getHepatis();
    }else {
      this.heptisOpt = false;
      this.heptisToggle = "no";
      this.heptisCured = "no";
      this.heptisCuredFlag = false;
    }
  }

  changeMalaria(id, value) {
    if (value == "yes") {
      this.malariaOpt = true;
      this.malariaToggle = "yes"
      this.getMalaria();
    }else {
      this.malariaOpt = false;
      this.malariaToggle = "no";
      this.malariaCuredFlag = false;
      this.malariaCured = "no";
    }
  }

  changeKidney(id, value) {
    if (value == "yes") {
      this.kidneyOpt = true;
      this.kidneyDiseaseToggle = "yes"
      this.getKidney();
      this.kidneyDiseaseFlag = true;
    }else {
      this.kidneyOpt = false;
      this.kidneyDiseaseToggle = "no";
      this.kidneyDiseaseFlag = false;
    }
  }

  changeHeart(id, value) {
    if (value == "yes") {
      this.heartDisOpt = true;
      this.heartToggle = "yes"
    }else {
      this.heartDisOpt = false;
      this.heartToggle = "no";
    }
  }

  changeEpilepsy(id, value) {
    if (value == "yes") {
      this.epilepsyOpt = true;
      this.seizurFreeToggle = "yes"
      this.getEpilepsy();
    }else {
      this.epilepsyOpt = false;
      this.seizurFreeToggle = "no";
      this.getEpilepsy();
    }
  }

  changeCold(id, value) {
    if (value == "yes") {
      this.coldFluOpt = true;
      this.curedToggle = "yes";
      this.getCold();
    }else {
      this.coldFluOpt = false;
      this.curedToggle = "no";
      this.getCold();
    }
  }

  changeBp(id, value) {
    if (value == "yes") {
      this.bpOpt = true;
      this.bpToggle = "yes"
      this.getBp()
    }else {
      this.bpOpt = false;
      this.bpToggle = "no";
      this.getBp();
    }
  }

  changeTb(id, value) {
    if (value == "yes") {
      this.tbOpt = true;
      this.tbToggle = "yes"
      this.getTb();
    }else {
      this.tbOpt = false;
      this.tbToggle = "no";
      this.getTb()
    }
  }

  changeTeeth(id, value) {
    if (value == "yes") {
      this.teethOpt = true;
      this.teethToggle = "yes";
      this.getTeeth();
    }else {
      this.teethOpt = false;
      this.teethToggle = "no";
      this.getTeeth();
    }
  }

  changeRoot(id, value) {
    if (value == "yes") {
      this.rootOpt = true;
      this.rootToggle = "yes";
      this.getRoot();
    }else {
      this.rootOpt = false;
      this.rootToggle = "no";
      this.getRoot();
    }
  }

  changeScale(id, value) {
    if (value == "yes") {
      this.scaleOpt = true;
      this.scaleToggle = "yes";
      this.getScale();
    }else {
      this.scaleOpt = false;
      this.scaleToggle = "no";
      this.getScale();
    }
  }

  changeTooth(id, value) {
    if (value == "yes") {
      this.toothOpt = true;
      this.toothToggle = "yes";
      this.getTooth();
    }else {
      this.toothOpt = false;
      this.toothToggle = "no";
      this.getTooth()
    }
  }

  changeFill(id, value) {
    if (value == "yes") {
      this.fillOpt = true;
      this.fillToggle = "yes";
      this.getFill();
    }else {
      this.fillOpt = false;
      this.fillToggle = "no";
      this.getFill();
    }
  }

  getCurrentDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate',
      JSON.stringify({
        country: ipaddress.country_code
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          
          //City_change
          this.currentDate = obj.current_date;
          this.getDate = obj.current_date.split('-');
          this.currentYear = this.getDate[0];
          this.currentTime = obj.current_time;
          this.currentMaxDate = obj.current_date;
          
          this.date = this.currentDate;
          this.hivDateMod = this.currentDate;
          this.cancerDateMod = this.currentDate;
          this.bloodDateMod = this.currentDate;
          this.hepatisDateMod = this.currentDate;
          this.malariaDateMod = this.currentDate;
          this.seizurFreeDateMod = this.currentDate;
          this.curedDateMod = this.currentDate;
          this.tbDateMod = this.currentDate;
          this.teethDateMod = this.currentDate;
          this.rootDateMod = this.currentDate;
          this.scaleDateMod = this.currentDate;
          this.fillDateMod = this.currentDate;
          this.toothDateMod = this.currentDate;
        },error => {
          this.toastr.error(Message_data.defaultErr);
        }
      )
  }
}
