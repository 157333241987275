<div class="row">
   <div class="col-12">
      <mdb-card>
         <mdb-card-header class="bg-white ">
            <div class="headerCover">
               <div class="headerTilte app_list_header">
                  <h5 class="m-0" class="mainHeadingStyle">Outsource Tests</h5>
               </div>
               <div class="headerButtons">
                  <img src="../../../assets/ui_icons/buttons/save_button.svg" class="saveimgbtn_inpatinfo" *ngIf="savebutton" (click)="uploadReport()"/>
                  <!-- <img src="../../../assets/ui_icons/buttons/Back_button.svg" class="saveimgbtn_inpatinfo" /> -->
               </div>
            </div>

         </mdb-card-header>
         <mdb-card-body>
            <div>
               <p class="nodata" [hidden]='outsourceTestsList.length!=0'>No test(s) found</p>
               <div class="container-fluid" *ngIf="outsourceTestsList.length">
                  <div class="row">
                     <div class="col-12">
                        <div>
                           <mat-accordion displayMode="flat" multi="false" class="mat-table">
                              <section matSort class="mat-elevation-z2 mat-header-row"
                                 style="background-color: #c1e6fb;">
                                 <span class="mat-header-cell">S.No</span>
                                 <span class="mat-header-cell">MR No</span>
                                 <span class="mat-header-cell">Patient name</span>
                                 <span class="mat-header-cell"></span>
                                 <span class="mat-header-cell"></span>
                                 
                              </section>

                              <mat-expansion-panel *ngFor="let report of outsourceTestsList">
                                 <mat-expansion-panel-header class="mat-row align-items-left">
                                    <span class="mat-cell">{{report.s_no}}</span>
                                    <span class="mat-cell">{{report.dmrno}}</span>
                                    <span class="mat-cell">{{report.patient_name}}</span>
                                    <span class="mat-cell"></span>
                                    <span class="mat-cell"></span>
                                    <span>
                                       <img width="30px" height="auto" style="margin-right: 12px;"
                                          src="../../../assets/ui_icons/awaiting_approval.svg">
                                    </span>
                                    
                                 </mat-expansion-panel-header>
                                 <div>
                                    <p class="nodata" [hidden]='outsourceTestsList.length!=0'>No test(s)
                                       found</p>
                                    <table *ngIf="outsourceTestsList.length">
                                       <thead>
                                          <tr>
                                             <th>Test id</th>
                                             <th>Test Category</th>
                                             <th>Test name</th>
                                             <th>Bill date & time</th>
                                             <th>Collection date & time</th>
                                             <th>Assert date & time</th>
                                             <th>Visited date & time</th>
                                             <th>Outsource center</th>
                                             <th style="width: 12%; "></th>
                                          </tr>
                                       </thead>
                                       <tbody>
                                          <tr *ngFor="let test of report.tests">
                                             <td>{{test.test_id}}</td>
                                             <td>{{test.test_cat}}</td>
                                             <td>{{test.test_name}}</td>
                                             <td>{{test.bill_date}} {{test.bill_time}}</td>
                                             <td>{{test.sample_collect_d_t}}</td>
                                             <td>{{test.assert_d_t}}</td>
                                             <td>{{test.visited_d_t}}</td>
                                             <td>{{test.outsource_desc}}</td>
                                             <td style="font-size: 12px;width: 5%; padding-left: 30px;">
                                                <div style="width: 160px;">
                                                   <img *ngIf="test.status == '0'" width="30px" height="auto" src="../../../assets/ui_icons/Completed_icon.svg" />
                                                   <div *ngIf="test.status == '1'" class="col-12" style="margin-top: 10px;">
                                                      <input type="file" name="filUpload" accept="application/pdf,application/vnd.ms-excel" (change)="fileChange($event,report,test)" class="u1" />
                                                   </div>
                                                </div>
                                             </td>
                                          </tr>
                                       </tbody>
                                    </table>
                                 </div>

                              </mat-expansion-panel>
                           </mat-accordion>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

         </mdb-card-body>
      </mdb-card>
   </div>
</div>