import { Component, OnInit } from '@angular/core';
import { ipaddress } from 'src/app/ipaddress';
import { ServerApi } from 'src/app/server-api';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from 'src/app/helper_class';
import { Master_Helper } from 'src/app/Doctor_module/Master_Helper';
import { MasterHelperService } from 'src/app/Doctor_module/MasterHelper.service';
import { Date_Formate } from '../../../assets/js/common';
declare var $: any;
import { Client_Helper } from '../Client_helper';
import { ClientViewService } from '../clientview/client-view.service';
import moment from 'moment';
@Component({
  selector: 'app-menu-diet-plan',
  templateUrl: './menu-diet-plan.component.html',
  styleUrls: ['./menu-diet-plan.component.scss']
})
export class MenuDietPlanComponent implements OnInit {
  public region;
  public regionArray:any = [];
  public foodArray:any = [];
  public appointmentType;
  public sendFromDate;
  public currentMaxDate;
  public dateFlag:boolean = false;
  public fromDate;
  public toDate;
  public sendToDate;
  public sessionArray = [
    { session : "Morning", selected : true },
    { session : "Breakfast", selected : false },
    { session : "Mid-day", selected : false },
    { session : "Lunch", selected : false },
    { session : "Evening", selected : false },
    { session : "Dinner", selected : false },
    { session : "Night", selected : false },
  ];
  public morningFoodItem:any = [];
  public breadfastFoodItem:any = [];
  public midDayFoodItem:any = [];
  public lunchFoodItem:any = [];
  public eveningFoodItem:any = [];
  public dinnerFoodItem:any = [];
  public nightFoodItem:any = [];
  public session;
  public getClientDetails;
  constructor(public serviceAPI: ServerApi,public toastr: ToastrService, public masterData: MasterHelperService,public messageservice: ClientViewService) { }

  ngOnInit(): void {
    this.getCurrentDate();
    this.getClientDetails =  Client_Helper.getMenuDiet();
    console.log("getClientDetails" + JSON.stringify(this.getClientDetails));
  }
  async getCurrentDate() {
    if (Master_Helper.getMasterCurrentDate() == undefined) {
      await this.masterData.getCurrentDate();
    }
    var obj = Master_Helper.getMasterCurrentDate();
    if (obj != undefined) {
      this.currentMaxDate = obj.current_date;
      this.sendFromDate = Date_Formate(this.currentMaxDate);
      this.toDate = Date_Formate(this.currentMaxDate);
      this.fromDate = obj.current_date;
      this.getRegion();
    }
  }
  async getRegion(){
    var response = await this.serviceAPI.getData("lifestyle/grf").toPromise();
    if (response) {
      var obj = JSON.parse(JSON.stringify(response)); 
      this.regionArray = obj.regions
      this.region = this.regionArray[0];
      this.getFoodList();
      this.appointmentType = "Today";
      this.changeAppointmentType();
    }    
  }
  changeRegion(e){
    this.region = e;
    this.getFoodList(); 
  }
  selectedSession(session){
    this.sessionArray.forEach(element => {
      element.selected = session.session == element.session ? true :false;  
    });
    this.getFoodList();
  }                               

  async getFoodList(){  
    this.session = (this.sessionArray.filter((x) => { return x.selected == true }).map((x) => { return x.session })).toString();
    var sendData = {
      region : this.region,
      session : this.session,
    }
    var response = await this.serviceAPI.PostData("lifestyle/gfrs", sendData).toPromise();
    if (response) {
      var obj = JSON.parse(JSON.stringify(response)); 
      this.foodArray = obj.menu_items.map((x) => { return {...x, "checked" : false } });
      console.log("this.foodArray" + JSON.stringify(this.foodArray));
      this.checkSelectedItem();
    } 
  }
  getDateFormate(d) {
    var month = ((d.getMonth() + 1) < 10 ? '0' : '') + (d.getMonth() + 1);
    var day = (d.getDate() < 10 ? '0' : '') + d.getDate();
    var temp = d.getFullYear() + "-" + month + "-" + day;
    return temp
  }
  selectToDate(e) {
    this.toDate = e;
    this.sendToDate = Date_Formate(e);
  }
  selectFromDate(e) {
    this.fromDate = e;
    this.sendFromDate = Date_Formate(e);
  }
  changeAppointmentType() {
    var e;
    this.sendFromDate = Date_Formate(this.currentMaxDate);
    this.dateFlag = false;
    let days = [];
    if (this.appointmentType == "Today") {
      e = this.currentMaxDate;
    } else if (this.appointmentType == "Weekly") {
      var weekly, d = new Date();
      d.setDate(d.getDate() + 7);
      e = this.getDateFormate(d);
    } else if (this.appointmentType == "Fort Night") {
      var weekly, d = new Date();
      d.setDate(d.getDate() + 14);
      e = this.getDateFormate(d);
    } else if (this.appointmentType == "Monthly") {
      var weekly, d = new Date();
      d.setDate(d.getDate() + 30)
      e = this.getDateFormate(d);
    } else if (this.appointmentType == "3 Months") {
      var d = new Date();
      d.setDate(d.getDate() + 92)
      e = this.getDateFormate(d);
    } else if (this.appointmentType == "6 Months") {
      var weekly, d = new Date();
      d.setDate(d.getDate() + 183)
      e = this.getDateFormate(d);
    } else if (this.appointmentType == "Yearly") {
      var weekly, d = new Date();
      d.setDate(d.getDate() + 365)
      e = this.getDateFormate(d);
    } else if (this.appointmentType == "Date"){
      this.dateFlag = true;
      this.sendFromDate = Date_Formate(this.currentMaxDate);
    }
    this.sendToDate = e;
    console.log("this.sendFromDate" + this.sendFromDate + "this.sendToDate" + this.sendToDate)
  }
  checkedFood(food, action, session){
    food.checked = action == "add" ? true : false;
    switch (session) {
      case 'Morning':
        if(action == "add" ){
          this.morningFoodItem.push(food);
        }else{
          var index = this.morningFoodItem.findIndex(x => x.item_id == food.item_id);
          this.morningFoodItem.splice(index,1);
        }
        break;
      case 'Breakfast':
        if(action == "add" ){
          this.breadfastFoodItem.push(food);
        }else{
          var index = this.breadfastFoodItem.findIndex(x => x.item_id == food.item_id);
          this.breadfastFoodItem.splice(index,1);
        }
        break;
      case 'Mid-day':
        if(action == "add" ){
          this.midDayFoodItem.push(food);
        }else{
          var index = this.midDayFoodItem.findIndex(x => x.item_id == food.item_id);
          this.midDayFoodItem.splice(index,1);
        }
        break;
      case 'Lunch':
        if(action == "add" ){
          this.lunchFoodItem.push(food);
        }else{
          var index = this.lunchFoodItem.findIndex(x => x.item_id == food.item_id);
          this.lunchFoodItem.splice(index,1);
        }
        break;
      case 'Evening':
        if(action == "add" ){
          this.eveningFoodItem.push(food);
        }else{
          var index = this.eveningFoodItem.findIndex(x => x.item_id == food.item_id);
          this.eveningFoodItem.splice(index,1);
        }
        break;
      case 'Dinner':
        if(action == "add" ){
          this.dinnerFoodItem.push(food);
        }else{
          var index = this.dinnerFoodItem.findIndex(x => x.item_id == food.item_id);
          this.dinnerFoodItem.splice(index,1);
        }
        break;
      case 'Night':
        if(action == "add" ){
          this.nightFoodItem.push(food);
        }else{
          var index = this.nightFoodItem.findIndex(x => x.item_id == food.item_id);
          this.nightFoodItem.splice(index,1);
        }
        break;
    }
  }
  checkSelectedItem(){
    var session = (this.sessionArray.filter((x) => { return x.selected == true }).map((x) => { return x.session })).toString();
    for( let i = 0; i < this.foodArray.length; i++ ){
      switch (session) {
        case 'Morning':
          var index = this.morningFoodItem.findIndex(x => x.item_id == this.foodArray[i].item_id);
          this.foodArray[i].checked = index != -1 ? true : false;
          break;
        case 'Breakfast':
            var index = this.breadfastFoodItem.findIndex(x => x.item_id == this.foodArray[i].item_id);
            this.foodArray[i].checked = index != -1 ? true : false;
          break;
        case 'Mid-day':
            var index = this.midDayFoodItem.findIndex(x => x.item_id == this.foodArray[i].item_id);
            this.foodArray[i].checked = index != -1 ? true : false;
          break;
        case 'Lunch':
            var index = this.lunchFoodItem.findIndex(x => x.item_id == this.foodArray[i].item_id);
            this.foodArray[i].checked = index != -1 ? true : false;
          break;
        case 'Evening':
            var index = this.eveningFoodItem.findIndex(x => x.item_id == this.foodArray[i].item_id);
            this.foodArray[i].checked = index != -1 ? true : false;
          break;
        case 'Dinner':
            var index = this.dinnerFoodItem.findIndex(x => x.item_id == this.foodArray[i].item_id);
            this.foodArray[i].checked = index != -1 ? true : false;
          break;
        case 'Night':
            var index = this.nightFoodItem.findIndex(x => x.item_id == this.foodArray[i].item_id);
            this.foodArray[i].checked = index != -1 ? true : false;
        break;
      }
    }
  }
 async generatePlan(){
    var flag = false;
    if( this.morningFoodItem.length != 0 || this.breadfastFoodItem.length != 0 ||this.midDayFoodItem.length != 0 ||this.lunchFoodItem.length != 0 ||this.eveningFoodItem.length != 0 ||this.dinnerFoodItem.length != 0 ||this.nightFoodItem.length != 0 ){
      flag = true;
    }else{
      this.toastr.error("select atleast one items");
    }
    if(flag == true){
      var subRelId =  this.getClientDetails.sunRelID != undefined ? this.getClientDetails.sunRelID : undefined;
      var morningFoodItem = this.morningFoodItem.length != 0 ? this.morningFoodItem : undefined;
      var breadfastFoodItem = this.breadfastFoodItem.length != 0 ? this.breadfastFoodItem : undefined;
      var midDayFoodItem = this.midDayFoodItem.length != 0 ? this.midDayFoodItem : undefined;
      var lunchFoodItem = this.lunchFoodItem.length != 0 ? this.lunchFoodItem : undefined;
      var eveningFoodItem = this.eveningFoodItem.length != 0 ? this.eveningFoodItem : undefined;
      var dinnerFoodItem = this.dinnerFoodItem.length != 0 ? this.dinnerFoodItem : undefined;
      var nightFoodItem = this.nightFoodItem.length != 0 ? this.nightFoodItem : undefined;
      var send = {
        client_reg_id: this.getClientDetails.client_reg_id,
        relation_id: this.getClientDetails.relation_id,
        sub_rel_id: subRelId,
        country: "IN",
        life_style_id: this.getClientDetails.life_style_id,
        dplan_morning: morningFoodItem,
        dplan_breakfast: breadfastFoodItem,
        dplan_midday: midDayFoodItem,
        dplan_lunch: lunchFoodItem,
        dplan_evening: eveningFoodItem,
        dplan_dinner: dinnerFoodItem,
        dplan_night: nightFoodItem,
        from_date : Date_Formate(this.sendFromDate),
        to_date : this.sendToDate,
      }
      console.log("send" + JSON.stringify(send))
      var response = await this.serviceAPI.PostData("lifestyle/gfibc", send).toPromise();
      if (response) {
        var obj = JSON.parse(JSON.stringify(response));
        console.log("obj" + JSON.stringify(obj));
        if( obj.key != "0" ){
          Client_Helper.setGenerateDite(obj);
          this.toastr.success("Diet save successfully");
          this.messageservice.sendMessage("getDateDiet");
        }else{
          this.toastr.error(obj.result);
        }
      } else {
        this.toastr.error("Something went wrong, try later")
      }
    }
  }
}
