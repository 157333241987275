import { Component, OnInit, ElementRef, Input, Inject, ViewEncapsulation, HostListener } from '@angular/core';
import { Http, Headers } from '@angular/http';
// import { getIp, Ip_with_img_address, country_code } from '../../../assets/js/ipaddress';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-order-confirm-popup',
  templateUrl: './order-confirm-popup.component.html',
  styleUrls: ['./order-confirm-popup.component.scss']
})
export class OrderConfirmPopupComponent implements OnInit {
  startFrom1: string;
  startFrom2: string;
  startFrom3: string;
  startsFromArray = [];
  appointmentDate: any;
  endsAtArray = [];
  public isMobile: boolean;
  public type: string;
  public isHome: boolean;
  public view;
  constructor(public http: Http, public el: ElementRef, public dialogRef: MatDialogRef<OrderConfirmPopupComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    dialogRef.disableClose = true;
    this.startFrom1 = "06";
    this.startFrom2 = "00";
    this.startFrom3 = "AM";
    if (data.today != null) {
      this.appointmentDate = data.today;
    }
    this.type = data.type;
    this.isHome = this.type == "Home delivery" ? true : false;
    // time format
    for (var i = 1; i <= 12; i++) {
      if (i < 10) {
        this.startsFromArray.push("0" + i);
      } else {
        this.startsFromArray.push(i);
      }
    }
    for (var j = 0; j <= 55; j += 5) {
      if (j < 10) {
        this.endsAtArray.push("0" + j);
      } else {
        this.endsAtArray.push(j);
      }
    }
  }
  ngOnInit(): void {
  }
  getDate(data) {
    this.appointmentDate = data;
  }
  filter() {
    this.view = {
      selecteddate: this.appointmentDate,
      selectedtime: this.startFrom1 + ":" + this.startFrom2 + " " + this.startFrom3
    };
    this.dialogRef.close(this.view);
  }
  ClosePopup() {
    this.dialogRef.close();
  }
}
