<div class="row">
   <div class="col-12">
      <mdb-card>
         <mdb-card-header class="bg-white ">
            <div class="headerCover">
               <div class="headerTilte app_list_header">
                  <h5 class="m-0" class="mainHeadingStyle">Pending</h5>
               </div>
               <div class="headerButtons">
                  <img src="../../../assets/ui_icons/buttons/Back_button.svg" (click)="back()" *ngIf="backBtnFlag"
                     class="saveimgbtn_inpatinfo" />
                  <img src="../../../assets/ui_icons/buttons/save_button.svg" (click)="uploadData()" *ngIf="saveFlag"
                     class="saveimgbtn_inpatinfo" />
                  <!-- <img src="../../../assets/ui_icons/buttons/save_button.svg" (click)="uploadData2()" *ngIf="saveFlag2"
                     class="saveimgbtn_inpatinfo" /> -->
                  <!-- <button class="btn btn-primary" (click)="getSpecimenReport()">Speciment Report</button> -->
               </div>
            </div>
         </mdb-card-header>
         <mdb-card-body>
            <div class="row" [hidden]="true">
               <div class="col-12 mt-3" *ngIf="pendinglistFlag">
                  <p class="nodata" [hidden]='pendingLists.length != 0'>No test(s) found</p>
                  <table *ngIf="pendingLists.length" mdbTable datatable [dtOptions]="dtOptions"
                     class="table table-nowrap table-sm dataTable" style="width: 100%;">
                     <thead>
                        <tr>
                           <th style="width: 10%;">S.No</th>
                           <!-- <th style="width: 5%;">&nbsp;</th> -->
                           <th style="width: 10%;">Mr No</th>
                           <!-- <th style="width: 10%;">Test No</th> -->
                           <th style="width: 15%;">Patient Name</th>
                           <!-- <th style="width: 15%;">Test Type</th>
                           <th *ngIf="reporttype != 'radiology'" style="width: 15%;">Specimen Type</th>
                           <th style="width: 15%;">Test Category</th> -->
                           <!-- <th style="width: 15%; text-align: left;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              Test id &nbsp;&nbsp;&nbsp;&nbsp; Test
                              Category &nbsp;&nbsp;&nbsp;&nbsp; Test type &nbsp;&nbsp;&nbsp;&nbsp; Test name </th> -->
                           <th style="width: 45%;">
                              <table class="table table-nowrap table-sm" style="width: 100%;">
                                 <thead>
                                    <tr>
                                       <th style="width: 5%;">&nbsp;</th>
                                       <th style="width: 12%;">Test id</th>
                                       <th style="width: 20%;">Test Category</th>
                                       <th style="width: 15%;">Test Type</th>
                                       <th style="width: 15%;">Test Name</th>
                                       <th style="width: 22%;">&nbsp;</th>
                                       <th style="width: 0%;">&nbsp;</th>
                                    </tr>
                                 </thead>
                              </table>
                           </th>
                           <!-- <th *ngIf="newbutton" style="width: 10%;">Status</th> -->
                           <!-- <th style="width: 5%;">&nbsp;</th> -->
                        </tr>
                     </thead>
                     <tbody>
                        <tr *ngFor="let report of pendingLists">
                           <td style="text-align: center !important;">{{report.s_no}}</td>
                           <!-- <td><img *ngIf="report.priority == '1'" src="../../../assets/ui_icons/red_flag_icon.png"
                                 style="width: 25px;" />
                              <img *ngIf="report.priority != '1'" src="../../../assets/ui_icons/green_flag_icon.png"
                                 style="width: 25px;" />
                           </td> -->
                           <td style="text-align: center !important;">{{report.dmrno}}</td>
                           <!-- <td style="text-align: center !important;">{{report.test_no}}</td> -->
                           <td style="text-align: center !important;">{{report.patient_name}}</td>
                           <!-- <td style="text-align: center !important;">{{report.test_type_name}}</td>
                           <td *ngIf="reporttype != 'radiology'" style="text-align: center !important;">{{report.specimen_names}}</td>
                           <td style="text-align: center !important;">{{report.test_cat}}</td> -->
                           <td style="text-align: left !important;">
                              <table style="width: 100%;">
                                 <!-- <thead>
                                    <tr>
                                       <th style="width: 5%;">&nbsp;</th>
                                       <th>Test id</th>
                                       <th>Test Category</th>
                                       <th>Test Type</th>
                                       <th>Test Name</th>
                                    </tr>
                                 </thead> -->
                                 <tbody>
                                    <tr *ngFor="let test of report.tests">
                                       <td style="text-align: left !important; width: 10%;"><img
                                             *ngIf="test.priority == '1'"
                                             src="../../../assets/ui_icons/red_flag_icon.png" style="width: 25px;" />
                                          <img *ngIf="test.priority != '1'"
                                             src="../../../assets/ui_icons/green_flag_icon.png" style="width: 25px;" />
                                       </td>
                                       <td style="text-align: left !important; width: 12%;">{{test.test_id}}</td>
                                       <td style="text-align: left !important; width: 20%;">{{test.test_cat}}</td>
                                       <td style="text-align: left !important; width: 20%;">{{test.test_type_name}}</td>
                                       <td style="text-align: left !important; width: 20%;">{{test.test_name}}</td>
                                       <td style="text-align: left !important; width: 20%;" *ngIf="newbutton"
                                          style="font-size: 12px; text-align: right">
                                          <div style="position: relative;top: 9px;">
                                             <img *ngIf="test.status == '0'" width="30px" height="auto"
                                                src="../../../assets/ui_icons/Completed_icon.svg" />
                                             <img *ngIf="test.status == '1'" width="30px" height="auto"
                                                src="../../../assets/ui_icons/awaiting_approval.svg"
                                                (click)="changeSubTest(report.diag_appointment_id, test.test_type_name, test.test_id, report, test)" />
                                             <p *ngIf="test.status == '0'">Completed</p>
                                             <p *ngIf="test.status == '1'">Upload</p>
                                          </div>
                                       </td>
                                       <td style="text-align: left !important; width: 10%;" *ngIf="newbutton"
                                          style="font-size: 12px; text-align: right">
                                          <button mat-icon-button matTooltip="Assertion reasons"
                                             [matMenuTriggerFor]="menu">
                                             <mat-icon>more_vert</mat-icon>
                                          </button>
                                          <mat-menu #menu="matMenu">
                                             <button mat-menu-item>Sample Value Low</button>
                                             <button mat-menu-item>Sample Value high</button>
                                             <button mat-menu-item>Sample Clot</button>
                                             <button mat-menu-item>Wrong Container</button>
                                             <button mat-menu-item>Sample Heomolysed</button>
                                             <button mat-menu-item>Mislabled</button>
                                             <button mat-menu-item>Sample Lipemic</button>
                                             <button mat-menu-item>Diluted Sample</button>
                                          </mat-menu>
                                       </td>
                                    </tr>
                                 </tbody>
                              </table>
                           </td>
                           <!-- <td style="text-align: center !important;">{{report.status}}</td> -->

                           <!-- <td style="text-align: center !important;">{{report.patient_name}}</td>  -->
                        </tr>
                     </tbody>
                  </table>
               </div>
               <!-- <div class="col-12" *ngIf="pendingLists.length == 0">
                  <p class="nodata">No data found</p>
               </div> -->
            </div>
            <div class="row" *ngIf="pendinglistFlag">
               <div class="col-2" style="margin-top: 20px; margin-bottom: 20px;margin-left: 22px !important;">
                  <select [(ngModel)]="filter_type" class="ipcss" (change)="changeFilter(filter_type)">
                     <option value="date">Date</option>
                     <option value="mr_no">MR No</option>
                     <option value="name">Name</option>
                  </select>
               </div>
               <div class="col-3" style="margin-top: 17px;" *ngIf="mr_no_nameFlag">
                  <input type="text" class="ipcss" aria-label="Number" [(ngModel)]="searchInput"
                     (keyup)="filterPendingListArray(searchInput)" placeholder={{filter_placeholder}}
                     matInput />
               </div>
               <div class="col-2" style="margin-bottom: 12px;" *ngIf="date_Flag">
                  <mat-label class="matlabel">From
                     <input type="date" class="ipcss_date" id="appt_date1" [(ngModel)]="from_date" #matInput>
                  </mat-label>
               </div>
               <div class="col-2" style="margin-bottom: 12px;" *ngIf="date_Flag">
                  <mat-label class="matlabel">To
                     <input type="date" class="ipcss_date" id="appt_date2" [(ngModel)]="to_date" #matInput>
                  </mat-label>
               </div>
               <div class="col-2" style="margin-top: 17px;" *ngIf="date_Flag">
                  <img src="../../../assets/ui_icons/buttons/search_button.svg" (click)="getPendingList()"
                     class="saveimgbtn_inpatinfo" />
               </div>
            </div>
            <div *ngIf="pendinglistFlag">
               <p class="nodata" [hidden]='pendingLists.length!=0'>No test(s) found</p>
               <div class="container-fluid" *ngIf="pendingLists.length">
                 
                  <div class="row">
                     <!-- <div class="col-2 m-3">
                        <input type="text" class="ipcss" aria-label="Number"
                           [(ngModel)]="mr_no" (keyup)="filterSamplelistArray(mr_no)"
                           placeholder="Search MR.No" matInput />
                     </div>  -->
                     <div class="col-12">
                        <div>
                           <mat-accordion displayMode="flat" multi="false" class="mat-table">
                              <section matSort class="mat-elevation-z2 mat-header-row" style="background-color: #c1e6fb; position: sticky;
                                 top: 0;
                                 z-index: 10;">
                                 <span class="mat-header-cell">S.No</span>
                                 <span class="mat-header-cell">MR No</span>
                                 <span class="mat-header-cell">Patient name</span>
                                 <span *ngIf="reporttype != 'radiology'" class="mat-header-cell">Sample date &
                                    time</span>
                                 <!-- <span class="mat-header-cell">Collected date</span> -->
                                 <!-- <span *ngIf="reporttype == 'radiology'" class="mat-header-cell"></span> -->
                                 <span class="mat-header-cell"></span>
                              </section>

                              <mat-expansion-panel *ngFor="let report of filteredPendingList">
                                 <mat-expansion-panel-header class="mat-row align-items-center">
                                    <span class="mat-cell">{{report.s_no}}</span>
                                    <span class="mat-cell">{{report.dmrno}}</span>
                                    <span class="mat-cell">{{report.patient_name}}</span>
                                    <span class="mat-cell"
                                       style="margin-right: -15px;">{{report.sample_collect_d_t}}</span>
                                    <!-- <span class="mat-cell">{{report.collected_date}}</span> -->
                                    <span *ngIf="reporttype != 'radiology'" class="mat-cell"></span>
                                    <span *ngIf="reporttype == 'pathology'">
                                       <img width="30px" height="auto" style="margin-right: 12px;"
                                          src="../../../assets/ui_icons/awaiting_approval.svg"
                                          (click)="changeSubTestNew(report)">
                                    </span>
                                 </mat-expansion-panel-header>
                                 <div>
                                    <p class="nodata" [hidden]='pendingLists.length!=0'>No test(s) found</p>
                                    <table *ngIf="pendingLists.length">
                                       <thead>
                                          <tr>
                                             <th>Id</th>
                                             <th>Test id</th>
                                             <th>Test Category</th>
                                             <th>Test name</th>
                                             <th>Bill date & time</th>
                                             <th *ngIf="reporttype != 'radiology'">Sample date & time</th>
                                             <th *ngIf="reporttype != 'radiology'">Assert date & time</th>
                                             <th *ngIf="reporttype == 'radiology'">Visited date & time</th>
                                             <!-- <th *ngIf="reporttype == 'radiology'">Visited date & time</th> -->
                                             <th style="width: 7%; "></th>
                                          </tr>
                                       </thead>
                                       <!-- *ngFor="let row of rows; let i = index" -->
                                       <tbody>
                                          <tr *ngFor="let test of report.tests; let i = index" draggable="true"
                                             (dragstart)="onDragStart($event, test, report)"
                                             (dragover)="onDragOver($event)" (drop)="onDrop($event, i)">
                                             <td>{{test.id}}</td>
                                             <td>{{test.test_id}}</td>
                                             <td>{{test.test_cat}}</td>
                                             <td>{{test.test_name}}</td>
                                             <td>{{test.bill_date}} {{test.bill_time}}</td>
                                             <td *ngIf="reporttype != 'radiology'">{{test.sample_collect_d_t}}</td>
                                             <td *ngIf="reporttype != 'radiology'">{{test.assert_d_t}}</td>
                                             <td *ngIf="reporttype == 'radiology'">{{test.visited_d_t}}</td>
                                             <td style="font-size: 12px;width: 5%; padding-left: 30px;">
                                                <div style="position: relative;top: 9px;">
                                                   <img *ngIf="test.status == '0'" width="30px" height="auto"
                                                      src="../../../assets/ui_icons/Completed_icon.svg" />
                                                   <img *ngIf="test.status == '1'" width="30px" height="auto"
                                                      src="../../../assets/ui_icons/awaiting_approval.svg"
                                                      (click)="changeSubTest(report.diag_appointment_id, test.test_type_name, test.test_id, report, test)" />
                                                   <p *ngIf="test.status == '0'">Completed</p>
                                                   <p *ngIf="test.status == '1'">Upload</p>
                                                </div>
                                             </td>
                                          </tr>
                                       </tbody>
                                    </table>
                                 </div>
                              </mat-expansion-panel>
                           </mat-accordion>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            <div class="row mt-3" *ngIf="subTestViewNew">
               <div class="col-12 mb-3">
                  <div class="row" style="padding: 10px;">
                     <div class="cover_div">
                        <div class="header_lable">Personal Details</div>
                        <div class="content_cover">
                           <div class="row">
                              <div class="col-3">
                                 <table style="width: 100%;" class="head-table">
                                    <tbody>
                                       <tr class="head-tr">
                                          <td class="head-td" style="vertical-align: top;width: 100px"><strong>MR No</strong></td>
                                          <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                                          <td class="head-td">{{mrno}}</td>
                                       </tr>
                                       <tr class="head-tr">
                                          <td class="head-td" style="vertical-align: top;width: 100px"><strong>Name</strong></td>
                                          <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                                          <td class="head-td">{{patientName}}</td>
                                       </tr>
                                       <tr class="head-tr">
                                          <td class="head-td" style="vertical-align: top;width: 100px"><strong>Age / Gender</strong></td>
                                          <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                                          <td class="head-td">{{patAge}} / {{patGender}}</td>
                                       </tr>
                                       <!-- <tr *ngIf="diag_report_type != 'radiology'">
                                          <td><strong>Specimen</strong></td>
                                          <td>: {{sample_type}}</td>
                                       </tr> -->
                                    </tbody>
                                 </table>
                              </div>
                              <div class="col-3">
                                 <table style="width: 100%;" class="head-table">
                                    <tbody>
                                       <tr class="head-tr">
                                          <td class="head-td" style="vertical-align: top;width: 100px"><strong>Mobile</strong></td>
                                          <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                                          <td class="head-td">{{mobile}}</td>
                                       </tr>
                                       <tr class="head-tr">
                                          <td class="head-td" style="vertical-align: top;width: 100px"><strong>Patient type</strong></td>
                                          <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                                          <td class="head-td">{{patient_type}}</td>
                                       </tr>
                                       <tr class="head-tr">
                                          <td class="head-td" style="vertical-align: top;width: 100px"><strong>Doctor</strong></td>
                                          <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                                          <td class="head-td">{{doctorname}}</td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </div>
                              <div class="col-3">
                                 <table style="width: 100%;" class="head-table">
                                    <tbody>
                                       <tr class="head-tr">
                                          <td class="head-td" style="vertical-align: top;width: 100px"><strong>Address1</strong></td>
                                          <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                                          <td class="head-td">{{address1}}</td>
                                       </tr>
                                       <tr class="head-tr">
                                          <td class="head-td" style="vertical-align: top;width: 100px"><strong>Location</strong></td>
                                          <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                                          <td class="head-td">{{location}}</td>
                                       </tr>
                                       <tr class="head-tr">
                                          <td class="head-td" style="vertical-align: top;width: 100px"><strong>City</strong></td>
                                          <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                                          <td class="head-td">{{district}}</td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </div>
                              <div class="col-3">
                                 <table style="width: 100%;" class="head-table">
                                    <tbody>
                                       <tr class="head-tr">
                                          <td class="head-td" style="vertical-align: top; width: 100px;"><strong>State</strong></td>
                                          <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                                          <td class="head-td">{{state}}</td>
                                       </tr>
                                       <tr class="head-tr">
                                          <td class="head-td" style="vertical-align: top; width: 100px"><strong>Country</strong></td>
                                          <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                                          <td class="head-td">{{clntcountry}}</td>
                                       </tr>
                                       <tr class="head-tr">
                                          <td class="head-td" style="vertical-align: top; width: 100px"><strong>Zipcode</strong></td>
                                          <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                                          <td class="head-td">{{zipcode}}</td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </div>                              
                           </div>
                        </div>
                     </div>
                  </div>
                  <table class="mt-3">
                     <thead>
                        <tr>
                           <th style="width: 75px;">Test order</th>
                           <th style="width: 200px;">Test name</th>
                           <th style="width: 170px;">Reading</th>
                           <th style="width: 120px;">UOM</th>
                           <th style="width: 150px;">Reference range</th>
                           <th style="width: 180px;">Severity</th>
                           <th style="width: 120px;">Test history </th>
                           <th style="width: 120px;">Reject reason</th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr *ngFor="let sub of SubtestRangeArray let i = index" draggable="true"
                           (dragstart)="onDragStart1($event, sub)" (dragover)="onDragOver1($event)"
                           (drop)="onDrop1($event, i)">
                           <td colspan="8" *ngIf="sub.tests.length != 0">
                              <div style="padding: 0px;">
                                 <div class="d-flex flex-row" style="height: 35px;">
                                    <p style="margin-top: 5px; margin-bottom: 0px;">{{sub.id}}</p>
                                    <h2 class="subtest-head">{{sub.sub_test_name}}</h2>
                                 </div>
                                 <table style="width: 100%;">
                                    <tbody>
                                       <tr *ngFor="let subtest of sub.tests let i = index">
                                          <td style="width: 75px;">{{i + 1}}</td>
                                          <td style="width: 200px;">{{subtest.sub_test_name}}</td>
                                          <td style="width: 170px;" *ngIf="!subtest.dropvalflag">
                                             <input placeholder="reading" class="input__email"
                                                [(ngModel)]="subtest.test_range_value" matInput
                                                (blur)="checkRef(subtest.test_range_value, subtest)"
                                                [ngStyle]="{ 'background-color': subtest.inputBgColor }">
                                          </td>
                                          <td *ngIf="subtest.dropvalflag" style="width: 170px;">
                                             <div>
                                                <input placeholder="reading" type="text" class="input__email" required
                                                   maxlength="50" [(ngModel)]="subtest.test_range_value"
                                                   aria-label="Number" matInput [matAutocomplete]="auto7" />
                                                <mat-autocomplete #auto7="matAutocomplete" [panelWidth]="'auto'">
                                                   <mat-option id="optionfont"
                                                      *ngFor="let defval of subtest.drop_default" [value]="defval">
                                                      {{defval}}
                                                   </mat-option>
                                                </mat-autocomplete>
                                             </div>
                                          </td>
                                          <td style="width: 120px;">{{subtest.unit}}</td>
                                          <td style="width: 150px;">{{subtest.test_range}}</td>
                                          <td style="width: 180px;">
                                             <mat-label class="matlabel"></mat-label>
                                             <mat-select class="ipcss" [(ngModel)]="subtest.range_value">
                                                <mat-option value="Normal">Normal</mat-option>
                                                <mat-option value="Min_Abnor">Minimum abnormal</mat-option>
                                                <mat-option value="Max-Abnor">Maximum abnormal</mat-option>
                                                <mat-option value="Min-crit">Minimum critical</mat-option>
                                                <mat-option value="Max-crit">Maximum critical</mat-option>
                                                <mat-option value="Min-impro">Minimum improbable</mat-option>
                                                <mat-option value="Max-impro">Maximum improbable</mat-option>
                                             </mat-select>
                                          </td>
                                          <td style="width: 120px;">
                                             <div
                                                class="col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 d-flex justify-content-center"
                                                style="width: 100%;">
                                                <div class="row d-flex justify-content-center">
                                                   <div class="col-4 d-flex justify-content-center">
                                                      <img data-bs-toggle="modal" data-bs-target="#graph_Modal" (click)="getPatientHistory_new(subtest.test_id)"
                                                         style="width:22px;" src="../../../assets/ui_icons/graph.png">
                                                   </div>
                                                   <div class="col-4 d-flex justify-content-center">
                                                      <img data-bs-toggle="modal" data-bs-target="#Pat_hist_Modal" (click)="getPatientHistory_new(subtest.test_id)"
                                                         style="width: 22px;"
                                                         src="../../../assets/ui_icons/history.png">
                                                   </div>
                                                </div>
                                             </div>
                                          </td>
                                          <td style="width: 120px;">
                                             <mat-label class="matlabel"></mat-label>
                                             <mat-select class="ipcss" [(ngModel)]="sub.rejected_reason"
                                                (selectionChange)="changeSeverity(sub)">
                                                <mat-option value="clotted">Clotted samples</mat-option>
                                                <mat-option value="mislabled">Mislabled samples</mat-option>
                                                <mat-option value="heamolysed">Heamolysed samples</mat-option>
                                                <mat-option value="inadequate-samples">Inadequate samples</mat-option>
                                                <mat-option value="expired">Expired tubes</mat-option>
                                                <mat-option value="low-values">Low values</mat-option>
                                                <mat-option value="high-values">High values</mat-option>
                                             </mat-select>
                                          </td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </div>
                           </td>
                           <td *ngIf="sub.tests.length == 0">{{sub.id}}</td>
                           <td *ngIf="sub.tests.length == 0" style="font-weight: 600;"> {{sub.sub_test_name}} </td>
                           <td *ngIf="!sub.dropvalflag && sub.tests.length == 0">
                              <input placeholder="reading" class="input__email" [(ngModel)]="sub.test_range_value"
                                 matInput (blur)="checkRef(sub.test_range_value, sub)"
                                 [ngStyle]="{ 'background-color': sub.inputBgColor }">
                              <!-- <select disableOptionCentering class="ipcss " [(ngModel)]="sub.test_range_value" *ngIf="dropvalflag">
                                 <option *ngFor="let defval of sub.drop_default" value={{defval}}>
                                    {{defval}}
                                 </option>
                              </select> -->
                           </td>
                           <td *ngIf="sub.dropvalflag && sub.tests.length == 0">
                              <!-- <input placeholder="reading" class="input__email" [(ngModel)]="sub.test_range_value"
                                 matInput list="dropValues" [ngStyle]="{ 'background-color': sub.inputBgColor }">
                              <datalist [(ngModel)]="sub.test_range_value" id="dropValues">
                                 <option *ngFor="let defval of sub.drop_default" value={{defval}}>
                                    
                                 </option>
                              </datalist> -->
                              <div>
                                 <input placeholder="reading" type="text" class="input__email" required maxlength="50"
                                    [(ngModel)]="sub.test_range_value" aria-label="Number" matInput
                                    [matAutocomplete]="auto6" />
                                 <mat-autocomplete #auto6="matAutocomplete" [panelWidth]="'auto'">
                                    <mat-option id="optionfont" *ngFor="let defval of sub.drop_default"
                                       [value]="defval">
                                       {{defval}}
                                    </mat-option>
                                 </mat-autocomplete>
                              </div>
                           </td>
                           <td *ngIf="sub.tests.length == 0">
                              <div>{{sub.unit}}</div>
                           </td>
                           <td *ngIf="sub.tests.length == 0">
                              <div *ngIf="sub.test_range != undefined" [innerHtml]="sub.test_range | safeHtml">
                              </div>
                           </td>
                           <td *ngIf="sub.tests.length == 0">
                              <mat-label class="matlabel"></mat-label>
                              <mat-select class="ipcss" [(ngModel)]="sub.range_value"
                                 (selectionChange)="changeSeverity(sub)">
                                 <mat-option value="Normal">Normal</mat-option>
                                 <mat-option value="Min_Abnor">Minimum abnormal</mat-option>
                                 <mat-option value="Max-Abnor">Maximum abnormal</mat-option>
                                 <mat-option value="Min-crit">Minimum critical</mat-option>
                                 <mat-option value="Max-crit">Maximum critical</mat-option>
                                 <mat-option value="Min-impro">Minimum improbable</mat-option>
                                 <mat-option value="Max-impro">Maximum improbable</mat-option>
                              </mat-select>
                           </td>
                           <td *ngIf="sub.tests.length == 0">
                              <div class="col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 d-flex justify-content-center"
                                 style="width: 100%;">
                                 <div class="row d-flex justify-content-center">
                                    <div class="col-4 d-flex justify-content-center">
                                       <!-- <i class="fa fa-bar-chart" aria-hidden="true"></i> -->
                                       <img data-bs-toggle="modal" data-bs-target="#graph_Modal" style="width:22px;" (click)="getPatientHistory_new(sub.sub_test_id_data)"
                                          src="../../../assets/ui_icons/graph.png">
                                    </div>
                                    <div class="col-4 d-flex justify-content-center">
                                       <!-- <i class="fa fa-bar-chart" aria-hidden="true"></i> -->
                                       <img data-bs-toggle="modal" data-bs-target="#Pat_hist_Modal" style="width: 22px;" (click)="getPatientHistory_new(sub.sub_test_id_data)"
                                          src="../../../assets/ui_icons/history.png">
                                    </div>
                                 </div>
                              </div>
                           </td>
                           <td *ngIf="sub.tests.length == 0">
                              <mat-label class="matlabel"></mat-label>
                              <mat-select class="ipcss" [(ngModel)]="sub.rejected_reason">
                                 <mat-option value="clotted">Clotted samples</mat-option>
                                 <mat-option value="mislabled">Mislabled samples</mat-option>
                                 <mat-option value="heamolysed">Heamolysed samples</mat-option>
                                 <mat-option value="inadequate-samples">Inadequate samples</mat-option>
                                 <mat-option value="expired">Expired tubes</mat-option>
                                 <mat-option value="low-values">Low values</mat-option>
                                 <mat-option value="high-values">High values</mat-option>
                              </mat-select>
                           </td>
                        </tr>
                     </tbody>
                  </table>
               </div>
            </div>

            <div class="row" *ngIf="subTestView">
               <div class="col-12">
                  <div class="row" style="padding: 10px;">
                     <div class="cover_div">
                        <div class="header_lable">Personal Details</div>
                        <div class="content_cover">
                           <div class="row">
                              <div class="col-3">
                                 <table style="width: 100%;" class="head-table">
                                    <tbody>
                                       <tr class="head-tr">
                                          <td class="head-td" style="vertical-align: top;width: 100px"><strong>MR No</strong></td>
                                          <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                                          <td class="head-td">{{mrno}}</td>
                                       </tr>
                                       <tr class="head-tr">
                                          <td class="head-td" style="vertical-align: top;width: 100px"><strong>Name</strong></td>
                                          <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                                          <td class="head-td">{{patientName}}</td>
                                       </tr>
                                       <tr class="head-tr">
                                          <td class="head-td" style="vertical-align: top;width: 100px"><strong>Age / Gender</strong></td>
                                          <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                                          <td class="head-td">{{patAge}} / {{patGender}}</td>
                                       </tr>
                                       <!-- <tr *ngIf="diag_report_type != 'radiology'">
                                          <td><strong>Specimen</strong></td>
                                          <td>: {{sample_type}}</td>
                                       </tr> -->
                                    </tbody>
                                 </table>
                              </div>
                              <div class="col-3">
                                 <table style="width: 100%;" class="head-table">
                                    <tbody>
                                       <tr class="head-tr">
                                          <td class="head-td" style="vertical-align: top;width: 100px"><strong>Mobile</strong></td>
                                          <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                                          <td class="head-td">{{mobile}}</td>
                                       </tr>
                                       <tr class="head-tr">
                                          <td class="head-td" style="vertical-align: top;width: 100px"><strong>Patient type</strong></td>
                                          <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                                          <td class="head-td">{{patient_type}}</td>
                                       </tr>
                                       <tr class="head-tr">
                                          <td class="head-td" style="vertical-align: top;width: 100px"><strong>Doctor</strong></td>
                                          <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                                          <td class="head-td">{{doctorname}}</td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </div>
                              <div class="col-3">
                                 <table style="width: 100%;" class="head-table">
                                    <tbody>
                                       <tr class="head-tr">
                                          <td class="head-td" style="vertical-align: top;width: 100px"><strong>Address1</strong></td>
                                          <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                                          <td class="head-td">{{address1}}</td>
                                       </tr>
                                       <tr class="head-tr">
                                          <td class="head-td" style="vertical-align: top;width: 100px"><strong>Location</strong></td>
                                          <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                                          <td class="head-td">{{location}}</td>
                                       </tr>
                                       <tr class="head-tr">
                                          <td class="head-td" style="vertical-align: top;width: 100px"><strong>City</strong></td>
                                          <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                                          <td class="head-td">{{district}}</td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </div>
                              <div class="col-3">
                                 <table style="width: 100%;" class="head-table">
                                    <tbody>
                                       <tr class="head-tr">
                                          <td class="head-td" style="vertical-align: top; width: 100px;"><strong>State</strong></td>
                                          <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                                          <td class="head-td">{{state}}</td>
                                       </tr>
                                       <tr class="head-tr">
                                          <td class="head-td" style="vertical-align: top; width: 100px"><strong>Country</strong></td>
                                          <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                                          <td class="head-td">{{clntcountry}}</td>
                                       </tr>
                                       <tr class="head-tr">
                                          <td class="head-td" style="vertical-align: top; width: 100px"><strong>Zipcode</strong></td>
                                          <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                                          <td class="head-td">{{zipcode}}</td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </div>                              
                           </div>
                        </div>
                     </div>
                  </div>
                  <!-- <div class="p-3">
                     <p class="mb-1"><strong>Patient Name: </strong> {{patientName}}</p>
                     <p class="mb-1"><strong>Gender: </strong> {{patAge}}</p>
                     <p class="mb-1"><strong>Age: </strong> {{patGender}}</p>
                  </div> -->
                  <table>
                     <thead>
                        <tr>
                           <th style="width: 75px;">Test order</th>
                           <th style="width: 200px;">Test name</th>
                           <th style="width: 170px;">Reading</th>
                           <th style="width: 120px;">UOM</th>
                           <th style="width: 150px;">Reference range</th>
                           <th style="width: 180px;">Severity</th>
                           <th style="width: 120px;">Test history </th>
                           <th style="width: 120px;">Reject reason</th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr *ngFor="let sub of SubtestRangeArray let i = index" draggable="false">
                           <td colspan="8" *ngIf="sub.tests.length != 0">
                              <div style="padding: 0px;">
                                 <div class="d-flex flex-row">
                                    <p style="margin-top: 5px; margin-bottom: 0px;">{{sub.id}}</p>
                                    <h2 class="subtest-head">{{sub.sub_test_name}}</h2>
                                 </div>
                                 <table style="width: 100%;">
                                    <tbody>
                                       <tr *ngFor="let subtest of sub.tests let i = index">
                                          <td style="width: 75px">{{i + 1}}</td>
                                          <td style="width: 200px">{{subtest.sub_test_name}}</td>
                                          <td style="width: 170px" *ngIf="subtest.conduction_format != '4'">
                                             <input placeholder="reading" class="input__email"
                                                [(ngModel)]="subtest.test_range_value" matInput
                                                (blur)="checkRef(subtest.test_range_value, subtest)"
                                                [ngStyle]="{ 'background-color': subtest.inputBgColor }">
                                          </td>
                                          <td style="width: 170px;" *ngIf="subtest.conduction_format == '4'">
                                             <div>
                                                <input placeholder="reading" type="text" class="input__email" required
                                                   maxlength="50" [(ngModel)]="subtest.test_range_value"
                                                   aria-label="Number" matInput [matAutocomplete]="auto7" />
                                                <mat-autocomplete #auto7="matAutocomplete" [panelWidth]="'auto'">
                                                   <mat-option id="optionfont"
                                                      *ngFor="let defval of subtest.drop_default" [value]="defval">
                                                      {{defval}}
                                                   </mat-option>
                                                </mat-autocomplete>
                                             </div>
                                          </td>
                                          <td style="width: 120px;">{{subtest.unit}}</td>
                                          <td style="width: 150px;">{{subtest.test_range}}</td>
                                          <td style="width: 180px;">
                                             <mat-label class="matlabel"></mat-label>
                                             <mat-select class="ipcss" [(ngModel)]="subtest.range_value">
                                                <mat-option value="Normal">Normal</mat-option>
                                                <mat-option value="Min_Abnor">Minimum abnormal</mat-option>
                                                <mat-option value="Max-Abnor">Maximum abnormal</mat-option>
                                                <mat-option value="Min-crit">Minimum critical</mat-option>
                                                <mat-option value="Max-crit">Maximum critical</mat-option>
                                                <mat-option value="Min-impro">Minimum improbable</mat-option>
                                                <mat-option value="Max-impro">Maximum improbable</mat-option>
                                             </mat-select>
                                          </td>
                                          <td style="width: 120px;">
                                             <div
                                                class="col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 d-flex justify-content-center"
                                                style="width: 100%;">
                                                <div class="row d-flex justify-content-center">
                                                   <div class="col-4 d-flex justify-content-center">
                                                      <img data-bs-toggle="modal" data-bs-target="#graph_Modal"
                                                         style="width:22px;" src="../../../assets/ui_icons/graph.png"
                                                         (click)="getPatientHistory_new(subtest.tests_list_id)">
                                                   </div>
                                                   <div class="col-4 d-flex justify-content-center">
                                                      <img data-bs-toggle="modal" data-bs-target="#Pat_hist_Modal"
                                                         style="width: 22px;" src="../../../assets/ui_icons/history.png"
                                                         (click)="getPatientHistory_new(subtest.tests_list_id)">
                                                   </div>
                                                </div>
                                             </div>
                                          </td>
                                          <td style="width: 120px;">
                                             <mat-label class="matlabel"></mat-label>
                                             <mat-select class="ipcss" [(ngModel)]="subtest.rejected_reason">
                                                <mat-option value="clotted">Clotted samples</mat-option>
                                                <mat-option value="mislabled">Mislabled samples</mat-option>
                                                <mat-option value="heamolysed">Heamolysed samples</mat-option>
                                                <mat-option value="inadequate-samples">Inadequate samples</mat-option>
                                                <mat-option value="expired">Expired tubes</mat-option>
                                                <mat-option value="low-values">Low values</mat-option>
                                                <mat-option value="high-values">High values</mat-option>
                                             </mat-select>
                                          </td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </div>
                           </td>
                           <td *ngIf="sub.tests.length == 0">{{sub.id}}</td>
                           <td *ngIf="sub.tests.length == 0" style="font-weight: 600;"> {{sub.sub_test_name}} </td>
                           <td *ngIf="!sub.dropvalflag && sub.tests.length == 0">
                              <input placeholder="reading" class="input__email" [(ngModel)]="sub.test_range_value"
                                 matInput (blur)="checkRef(sub.test_range_value, sub)"
                                 [ngStyle]="{ 'background-color': sub.inputBgColor }">
                              <!-- <select disableOptionCentering class="ipcss " [(ngModel)]="sub.test_range_value" *ngIf="dropvalflag">
                                 <option *ngFor="let defval of sub.drop_default" value={{defval}}>
                                    {{defval}}
                                 </option>
                              </select> -->
                           </td>
                           <td *ngIf="sub.dropvalflag && sub.tests.length == 0">
                              <!-- <input placeholder="reading" class="input__email" [(ngModel)]="sub.test_range_value"
                                 matInput list="dropValues" [ngStyle]="{ 'background-color': sub.inputBgColor }">
                              <datalist [(ngModel)]="sub.test_range_value" id="dropValues">
                                 <option *ngFor="let defval of sub.drop_default" value={{defval}}>
                                    
                                 </option>
                              </datalist> -->
                              <div>
                                 <input placeholder="reading" type="text" class="input__email" required maxlength="50"
                                    [(ngModel)]="sub.test_range_value" aria-label="Number" matInput
                                    [matAutocomplete]="auto6" />
                                 <mat-autocomplete #auto6="matAutocomplete" [panelWidth]="'auto'">
                                    <mat-option id="optionfont" *ngFor="let defval of sub.drop_default"
                                       [value]="defval">
                                       {{defval}}
                                    </mat-option>
                                 </mat-autocomplete>
                              </div>
                           </td>
                           <td *ngIf="sub.tests.length == 0">
                              <div>{{sub.unit}}</div>
                           </td>
                           <td *ngIf="sub.tests.length == 0">
                              <div *ngIf="sub.test_range != undefined" [innerHtml]="sub.test_range | safeHtml">
                              </div>
                           </td>
                           <td *ngIf="sub.tests.length == 0">
                              <mat-label class="matlabel"></mat-label>
                              <mat-select class="ipcss" [(ngModel)]="sub.range_value"
                                 (selectionChange)="changeSeverity(sub)">
                                 <mat-option value="Normal">Normal</mat-option>
                                 <mat-option value="Min_Abnor">Minimum abnormal</mat-option>
                                 <mat-option value="Max-Abnor">Maximum abnormal</mat-option>
                                 <mat-option value="Min-crit">Minimum critical</mat-option>
                                 <mat-option value="Max-crit">Maximum critical</mat-option>
                                 <mat-option value="Min-impro">Minimum improbable</mat-option>
                                 <mat-option value="Max-impro">Maximum improbable</mat-option>
                              </mat-select>
                           </td>
                           <td *ngIf="sub.tests.length == 0">
                              <div class="col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 d-flex justify-content-center"
                                 style="width: 100%;">
                                 <div class="row d-flex justify-content-center">
                                    <div class="col-4 d-flex justify-content-center">
                                       <!-- <i class="fa fa-bar-chart" aria-hidden="true"></i> -->
                                       <img data-bs-toggle="modal" data-bs-target="#graph_Modal" style="width:22px;"
                                          (click)="getPatientHistory_new(sub.sub_test_id_data)"
                                          src="../../../assets/ui_icons/graph.png">
                                    </div>
                                    <div class="col-4 d-flex justify-content-center">
                                       <!-- <i class="fa fa-bar-chart" aria-hidden="true"></i> -->
                                       <img data-bs-toggle="modal" data-bs-target="#Pat_hist_Modal" style="width: 22px;"
                                          (click)="getPatientHistory_new(sub.sub_test_id_data)"
                                          src="../../../assets/ui_icons/history.png">
                                    </div>
                                 </div>
                              </div>
                           </td>
                           <td *ngIf="sub.tests.length == 0">
                              <mat-label class="matlabel"></mat-label>
                              <mat-select class="ipcss" [(ngModel)]="sub.rejected_reason">
                                 <mat-option value="clotted">Clotted samples</mat-option>
                                 <mat-option value="mislabled">Mislabled samples</mat-option>
                                 <mat-option value="heamolysed">Heamolysed samples</mat-option>
                                 <mat-option value="inadequate-samples">Inadequate samples</mat-option>
                                 <mat-option value="expired">Expired tubes</mat-option>
                                 <mat-option value="low-values">Low values</mat-option>
                                 <mat-option value="high-values">High values</mat-option>
                              </mat-select>
                           </td>
                        </tr>

                     </tbody>
                  </table>
                  <!-- <mat-card *ngFor="let sub of SubtestRangeArray" class="matcardtest" [hidden]="true">
                     <mat-card-content>
                        <div class="row p-3">
                           <div class="d-flex align-items-center justify-content-between">
                              <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-2 d-flex justify-content-center">
                                 <mat-label style="font-size: 14px; font-weight: 600;"
                                    class="matlabel">{{sub.sub_test_name}}</mat-label>
                              </div>
                              <div class="col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 d-flex justify-content-center">
                                 <input placeholder="reading" class="input__email" [(ngModel)]="sub.test_range_value"
                                    matInput (keyup)="checkRef($event, sub)">
                              </div>
                              <div class="col-12 col-sm-12 col-md-4 col-lg-1 col-xl-1 d-flex justify-content-center">
                                 <div>{{sub.unit}}</div>
                              </div>
                              <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 d-flex justify-content-center">
                                 <mat-label class="matlabel"></mat-label>
                                 <mat-select class="ipcss" [(ngModel)]="sub.range_value"
                                    (selectionChange)="changeSeverity(sub)">
                                    <mat-option value="Normal">Normal</mat-option>
                                    <mat-option value="Min_Abnor">Minimum Abnormal</mat-option>
                                    <mat-option value="Max-Abnor">Maximum Abnormal</mat-option>
                                    <mat-option value="Min-crit">Minimum Critical</mat-option>
                                    <mat-option value="Max-crit">Maximum Critical</mat-option>
                                 </mat-select>
                              </div>
                              <div class="col-12 col-sm-12 col-md-3 col-lg-2 col-xl-2 d-flex justify-content-center">
                                 <div *ngIf="sub.test_range != undefined" [innerHtml]="sub.test_range | safeHtml">
                                 </div>
                              </div>
                              <div class="col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 d-flex justify-content-center">
                                 <div class="row d-flex justify-content-center">
                                    <div class="col-4 d-flex justify-content-center">
                                       <img data-bs-toggle="modal" data-bs-target="#graph_Modal" style="width:22px;"
                                          src="../../../assets/ui_icons/graph.png">
                                    </div>
                                    <div class="col-4 d-flex justify-content-center">
                                       <img data-bs-toggle="modal" data-bs-target="#Pat_hist_Modal" style="width: 22px;"
                                          src="../../../assets/ui_icons/history.png">
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </mat-card-content>
                  </mat-card> -->

                  <!-- <div class="row p-3">
                     <div class="d-flex align-items-center justify-content-center">
                        <div [hidden]="false" class="col-12" style="margin-top: 10px;">
                           <p class="tervys_heading"><strong>Document Upload </strong></p>
                           <input type="file" name="filUpload" (change)="fileChange($event)" class="u1" />
                        </div>
                     </div>
                  </div> -->
                  <!-- <div class="row p-3">
                     <div class="d-flex align-items-center justify-content-center">
                        <div class="col-12">
                           <quill-editor id="editor1" [(ngModel)]="cbt_Template" [style]="editorStyle"
                              [modules]="modules" placeholder="Enter Text"
                              (onContentChanged)="onTemplateChanged($event)"></quill-editor>
                           <div>
                              
                              <quill-view-html hidden id="viewhtml1" [content]="cbt_Template"></quill-view-html>
                           </div>
                        </div>
                     </div>
                  </div> -->
               </div>
            </div>

            <div *ngIf="subTestView2" class="row" style="padding: 10px;">
               <div class="row" style="padding: 10px;">
                  <div class="cover_div">
                     <div class="header_lable">Personal Details</div>
                     <div class="content_cover">
                        <div class="row">
                           <div class="col-3">
                              <table style="width: 100%;" class="head-table">
                                 <tbody>
                                    <tr class="head-tr">
                                       <td class="head-td" style="vertical-align: top;width: 100px"><strong>MR No</strong></td>
                                       <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                                       <td class="head-td">{{mrno}}</td>
                                    </tr>
                                    <tr class="head-tr">
                                       <td class="head-td" style="vertical-align: top;width: 100px"><strong>Name</strong></td>
                                       <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                                       <td class="head-td">{{patientName}}</td>
                                    </tr>
                                    <tr class="head-tr">
                                       <td class="head-td" style="vertical-align: top;width: 100px"><strong>Age / Gender</strong></td>
                                       <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                                       <td class="head-td">{{patAge}} / {{patGender}}</td>
                                    </tr>
                                    <!-- <tr *ngIf="diag_report_type != 'radiology'">
                                       <td><strong>Specimen</strong></td>
                                       <td>: {{sample_type}}</td>
                                    </tr> -->
                                 </tbody>
                              </table>
                           </div>
                           <div class="col-3">
                              <table style="width: 100%;" class="head-table">
                                 <tbody>
                                    <tr class="head-tr">
                                       <td class="head-td" style="vertical-align: top;width: 100px"><strong>Mobile</strong></td>
                                       <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                                       <td class="head-td">{{mobile}}</td>
                                    </tr>
                                    <tr class="head-tr">
                                       <td class="head-td" style="vertical-align: top;width: 100px"><strong>Patient type</strong></td>
                                       <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                                       <td class="head-td">{{patient_type}}</td>
                                    </tr>
                                    <tr class="head-tr">
                                       <td class="head-td" style="vertical-align: top;width: 100px"><strong>Doctor</strong></td>
                                       <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                                       <td class="head-td">{{doctorname}}</td>
                                    </tr>
                                 </tbody>
                              </table>
                           </div>
                           <div class="col-3">
                              <table style="width: 100%;" class="head-table">
                                 <tbody>
                                    <tr class="head-tr">
                                       <td class="head-td" style="vertical-align: top;width: 100px"><strong>Address1</strong></td>
                                       <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                                       <td class="head-td">{{address1}}</td>
                                    </tr>
                                    <tr class="head-tr">
                                       <td class="head-td" style="vertical-align: top;width: 100px"><strong>Location</strong></td>
                                       <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                                       <td class="head-td">{{location}}</td>
                                    </tr>
                                    <tr class="head-tr">
                                       <td class="head-td" style="vertical-align: top;width: 100px"><strong>City</strong></td>
                                       <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                                       <td class="head-td">{{district}}</td>
                                    </tr>
                                 </tbody>
                              </table>
                           </div>
                           <div class="col-3">
                              <table style="width: 100%;" class="head-table">
                                 <tbody>
                                    <tr class="head-tr">
                                       <td class="head-td" style="vertical-align: top; width: 100px;"><strong>State</strong></td>
                                       <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                                       <td class="head-td">{{state}}</td>
                                    </tr>
                                    <tr class="head-tr">
                                       <td class="head-td" style="vertical-align: top; width: 100px"><strong>Country</strong></td>
                                       <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                                       <td class="head-td">{{clntcountry}}</td>
                                    </tr>
                                    <tr class="head-tr">
                                       <td class="head-td" style="vertical-align: top; width: 100px"><strong>Zipcode</strong></td>
                                       <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                                       <td class="head-td">{{zipcode}}</td>
                                    </tr>
                                 </tbody>
                              </table>
                           </div>                              
                        </div>
                     </div>
                  </div>
               </div>
               <div *ngIf="!show" class="col-12">
                  <mat-label class="matlable">Observation

                     <quill-editor id="editor" [(ngModel)]="sequence" [styles]="{height: 'calc(30vh - 100px)'}"
                        [modules]="modules" (onEditorCreated)="editorCreated($event)" placeholder="Enter Text"
                        (onSelectionChanged)="onSelectionChanged($event)"
                        (onContentChanged)="onContentChanged($event)"></quill-editor>
                     <div>
                        <quill-view-html hidden id="viewhtml" [content]="sequence"></quill-view-html>
                     </div>
                  </mat-label>
               </div>
               <div *ngIf="show" class="col-12">
                  <mat-label class="matlable">Sequences

                     <quill-editor id="editor1" [(ngModel)]="sequence" [styles]="{height: 'calc(30vh - 100px)'}"
                        [modules]="modules" (onEditorCreated)="editorCreated($event)" placeholder="Enter Text"
                        (onSelectionChanged)="onSelectionChanged($event)"
                        (onContentChanged)="onContentChanged($event)"></quill-editor>
                     <div>
                        <quill-view-html hidden id="viewhtml1" [content]="sequence"></quill-view-html>
                     </div>

                  </mat-label>
               </div>
               <div class="col-12" style="margin-top: 10px;">
                  <mat-label class="matlable">Impression

                     <quill-editor id="editor2" [(ngModel)]="impression" [styles]="{height: 'calc(30vh - 100px)'}"
                        [modules]="modules" (onEditorCreated)="editorCreated($event)" placeholder="Enter Text"
                        (onSelectionChanged)="onSelectionChanged($event)"
                        (onContentChanged)="onContentChanged2($event)"></quill-editor>
                     <div>
                        <quill-view-html hidden id="viewhtml2" [content]="impression"></quill-view-html>
                     </div>
                  </mat-label>
               </div>
               <div [hidden]="chooseFileFlag" class="col-12" style="margin-top: 10px;">
                  <p class="tervys_heading"><strong>Document Upload </strong></p>
                  <input type="file" name="filUpload" (change)="fileChange($event)" class="u1" />
               </div>
            </div>
            <div *ngIf="show_culture_test" class="row" style="padding: 10px;">
               <div class="col-12" style="margin-top: 10px;">
                  <mat-label class="matlable">Report details

                     <quill-editor [(ngModel)]="culture" [styles]="{height: 'calc(30vh - 100px)'}" [modules]="modules"
                        (onEditorCreated)="editorCreated($event)" placeholder="Enter Text"
                        (onSelectionChanged)="onSelectionChanged($event)"
                        (onContentChanged)="onContentChanged2($event)"></quill-editor>
                  </mat-label>
               </div>
            </div>

            <div class="row p-3" [hidden]="template_flag">
               <div class="cover_div" style="margin-bottom: 15px;">
                  <div class="header_lable">Personal Details</div>
                  <div class="content_cover">
                     <div class="row">
                        <div class="col-3">
                           MR No: {{mrno}}
                        </div>
                        <div class="col-3">
                           Name: {{patientName}}
                        </div>
                        <div class="col-3">
                           Age / Gender: {{patAge}} / {{patGender}}
                        </div>
                        <div class="col-3">
                           Mobile: {{mobile}}
                        </div>
                        <div class="col-3">
                           Patient type: {{patient_type}}
                        </div>
                        <div class="col-3">
                           Doctor: {{doctorname}}
                        </div>
                        <div class="col-3">
                           Address1: {{address1}}
                        </div>
                        <!-- <div class="col-3">
                              Address1: {{address2}}
                           </div> -->
                        <div class="col-3">
                           Location: {{location}}
                        </div>
                        <div class="col-3">
                           City: {{district}}
                        </div>
                        <div class="col-3">
                           State: {{state}}
                        </div>
                        <div class="col-3">
                           Country: {{clntcountry}}
                        </div>
                        <div class="col-3">
                           Zipcode: {{zipcode}}
                        </div>

                        <!-- <div class="col-3">
                              Sample no: {{sampleNo}}
                            </div> -->
                     </div>
                  </div>
               </div>

               <div class="d-flex align-items-center justify-content-center">
                  <div class="col-12">
                     <quill-editor id="editor1" [(ngModel)]="cbt_Template" [style]="editorStyle" [modules]="modules"
                        placeholder="Enter Text" (onContentChanged)="onTemplateChanged($event)"></quill-editor>
                     <div>
                        <!-- Preview -->
                        <quill-view-html hidden id="viewhtml1" [content]="cbt_Template"></quill-view-html>
                     </div>
                     <div [hidden]="false" class="col-12" style="margin-top: 10px;">
                        <p class="tervys_heading"><strong>Document Upload </strong></p>
                        <input type="file" name="filUpload" [(ngModel)]="uploaded_file" (change)="fileChange($event)"
                           class="u1" />
                     </div>
                  </div>
               </div>
            </div>

            <div class="modal fade" id="Pat_hist_Modal" tabindex="-1" aria-labelledby="exampleModalLabel"
               aria-hidden="true">
               <div class="modal-dialog modal-lg">
                  <div class="modal-content">
                     <div class="modal-body">
                        <p class="nodata" [hidden]='patient_historyList.length != 0'>No previous history found</p>
                        <table *ngIf="patient_historyList.length">
                           <thead>
                              <tr>
                                 <th style="text-align: left;">Date</th>
                                 <th style="text-align: left;">Test id</th>
                                 <th style="text-align: left;">Test name</th>
                                 <th style="text-align: left;">Reading</th>
                                 <!-- <th style="text-align: left;">Entry date</th> -->
                              </tr>
                           </thead>
                           <tbody>
                              <tr *ngFor="let history of patient_historyList" data-bs-dismiss="modal">
                                 <td style="text-align: left;">{{history.entry_date}}</td>
                                 <td style="text-align: left;">{{history.diag_tests_id}}</td>
                                 <td style="text-align: left;">{{history.test_name}}</td>
                                 <td style="text-align: left;">{{history.reading}}</td>
                                 <!-- <td style="text-align: left;">{{history.entry_date}}</td> -->
                              </tr>
                           </tbody>
                        </table>
                     </div>
                  </div>
               </div>
            </div>
            <div class="modal fade" id="graph_Modal" tabindex="-1" aria-labelledby="exampleModalLabel"
               aria-hidden="true">
               <div class="modal-dialog">
                  <div class="modal-content">
                     <div class="modal-body">
                        <!-- <p class="nodata" [hidden]='patient_historyList.length != 0'>No previous history found</p> -->
                        <canvas id="myChart"></canvas>
                     </div>
                  </div>
               </div>
            </div>
         </mdb-card-body>
      </mdb-card>
   </div>
</div>