import { Component, OnInit, ElementRef, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { MatDialog } from '@angular/material/dialog';
import { ClientViewService } from '../clientview/client-view.service';
import { Client_Helper } from '../Client_helper';
import { PharmacyorderdetailcancelComponent } from '../pharmacyorderdetailcancel/pharmacyorderdetailcancel.component';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';
declare var $ : any; 

@Component({
  selector: 'app-pharmacyorder',
  templateUrl: './pharmacyorder.component.html',
  styleUrls: ['./pharmacyorder.component.css']
})
export class PharmacyorderComponent implements OnInit {
  public pharmacyOrderURL: string;
  public pharmaClientId;
  public drugOrdersList = [];
  public clientName;
  public cientDescription: string;
  public cientOrderdate: string;
  public cientOrdertime: string;
  public cientDelivereddate: string;
  public cientDeliveredtime: string;
  public clientCancellation: string;
  public drugPurchaseId: string;
  public validDays;
  public pharmacyName: string;
  public pharmacyAddress1: string;
  public pharmacyLocation: string;
  public pharmacyCity: string;
  public pharmacyZipcode: string;
  public pharmacyState: string;
  public pharmacyTelephone: string;
  public pharmacyCountry: string;
  public sendData;
  public pharmacyDeliverName: string;
  public pharmacyDeliverAddress1: string;
  public pharmacyDeliverAddress2: string;
  public pharmacyDeliverlocation: string;
  public pharmacyDelivercity: string;
  public pharmacyDeliverzipcode: string;
  public pharmacyDeliverstate: string;
  public pharmacyDelivercountry: string;
  public pharmacyDelivercontactno: string;
  public pharmacyDelivertelephone: string;
  public pharmacyDelivercitydesc: string;
  public pharmaOrderTax = [];
  public pharmatotalAmount: any;
  public totalAmountValue;
  public totalAmountValue1;
  public cancelFlag;
  public updateFlag;
  public rateFlag;
  public storeDaysFlag: boolean;
  public deliveryStatusFlag: boolean;
  public exeptationStatusFlag: boolean;
  public homecare;
  public preferredStatusFlag: boolean;
  public cancelStatusFlag: boolean;
  public preferredTime;
  public middleName;
  public pharmaAddrStyle;
  public pharmaClientName;
  public cancelUrl;
  public clientNameFlag: boolean;
  public getPharmaData;
  orderId;
  @Input() id: string; parm: string;
  public element: any;
  public header_footer_flag: boolean;

  constructor(public clientservice: ClientViewService, public comm_Data: CommonDataService, private el: ElementRef, public toastr: ToastrService, public http: Http, public router: Router, public dialog: MatDialog) {
    this.element = el.nativeElement;
  }
  
  ngOnInit(): void {
    this.getPharmaData= Client_Helper.getpharmacydetails();
    this.pharmacyOrderURL = ipaddress.getIp + "pharmacontrol/vieworderdetails";
    this.pharmaClientId = {
      order_id:  this.getPharmaData.orderid,
      pharmacy_id: this.getPharmaData.pharma_id,
    }
    this.orderId = this.getPharmaData.orderid;
    this.pharmaCurrentOrdDetails();
    this.cancelUrl = ipaddress.getIp + "pharmacontrol/cancelord";
    this.clientNameFlag = true;
  }

  update() {
  }

  pharmaCurrentOrdDetails() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.pharmacyOrderURL, JSON.stringify(this.pharmaClientId), { headers: headers }).subscribe(
      data => {
        var obj = JSON.stringify(data.json());
        if (data.json().drug_purchase_id != null) {
          this.drugPurchaseId = data.json().drug_purchase_id;
        }
        if (data.json().name != null) {
          this.clientName = data.json().name;
          this.clientNameFlag = false;
        }
        if (data.json().description != null) {
          this.cientDescription = data.json().description;
        }
        if (data.json().order_date != null && data.json().order_time != null) {
          this.cientOrderdate = this.comm_Data.get_Date_Formate(data.json().order_date) + " at " + this.comm_Data.get_Timeformate(data.json().order_time);
        }
        if (data.json().delivered_date != null && data.json().delivered_time != null) {
          this.cientDelivereddate = this.comm_Data.get_Date_Formate(data.json().delivered_date) + " at " + this.comm_Data.get_Timeformate(data.json().delivered_time);
        }
        if (data.json().reject_text != null) {
          this.clientCancellation = data.json().reject_text;
        }
        if (data.json().validity_days != null) {
          this.validDays = data.json().validity_days;
        }
        if (data.json().pharmacy_name != null) 
        {
          if (data.json().pharmacy_name != null) {
            this.pharmacyName = data.json().pharmacy_name;
          }
          if (data.json().phar_address2 != null) {
            this.pharmacyAddress1 = data.json().phar_address1 + " ," + data.json().phar_address2;
          }
          else {
            this.pharmacyAddress1 = data.json().phar_address1 + " ,";
          }
          if (data.json().pharlocation != null) {
            this.pharmacyLocation = data.json().phar_location + " ,";
          }
          if (data.json().phar_city != null) {
            this.pharmacyCity = data.json().phar_city + " -";
          }
          if (data.json().phar_zipcode != null) {
            this.pharmacyZipcode = data.json().phar_zipcode + " ,";
          }
          if (data.json().phar_state != null) {
            this.pharmacyState = data.json().phar_state + " ,";
          }
          if (data.json().phar_country != null) {
            this.pharmacyCountry = data.json().phar_country + " ,";
          }
          if (data.json().phar_telephone != null) {
            this.pharmacyTelephone = data.json().phar_telephone;
          }
        }
        if (data.json().name != null)  
        {
          this.pharmacyDeliverName = data.json().name;
          if (data.json().address2 != null) {
            this.pharmacyDeliverAddress1 = data.json().address1 + " ," + data.json().address2 + " ,";
          }
          else {
            this.pharmacyDeliverAddress1 = data.json().address1 + " ,";
          }
          if (data.json().location_desc != null) {
            this.pharmacyDeliverlocation = data.json().location_desc + " ,";
          }
          if (data.json().city_desc != null) {
            this.pharmacyDelivercitydesc = data.json().city_desc + " -";
          }
          if (data.json().zipcode != null) {
            this.pharmacyDeliverzipcode = data.json().zipcode + " ,";
          }
          if (data.json().phar_state != null) {
            this.pharmacyDeliverstate = data.json().phar_state + " ,";
          }
          if (data.json().phar_country != null) {
            this.pharmacyDelivercountry = data.json().phar_country + " ,";
          }
          if (data.json().contact_no != null) {
            this.pharmacyDelivertelephone = data.json().contact_no;
          }
        }
        var dataValue = 0;
        var gstAmount = 0;
        this.totalAmountValue = 0;
        for (var i = 0; i < data.json().drug_details.length; i++) {
          dataValue = parseFloat(dataValue.toString()) + parseFloat(data.json().drug_details[i].amount);
          this.drugOrdersList.push({
            pharamcyamount: data.json().drug_details[i].amount,
            pharamcydrugid: data.json().drug_details[i].drug_id,
            pharamcydrug_name: data.json().drug_details[i].drug_name,
            pharamcyquantity: data.json().drug_details[i].quantity,
            pharamcytypename: data.json().drug_details[i].type_name,
          })
        }
        for (var j = 0; j < data.json().tax.length; j++) {
          gstAmount = dataValue * (data.json().tax[j].tax / 100);
          this.totalAmountValue = this.totalAmountValue + gstAmount;
          this.totalAmountValue1 = this.totalAmountValue.toFixed(2);
          this.pharmaOrderTax.push({
            pharmataxdesc: data.json().tax[j].tax_desc,
            pharamtax: data.json().tax[j].tax,
            pharmatotalAmount: gstAmount.toFixed(2),
          })
        }
        if (data.json().description == "Store pickup") 
        {
          this.storeDaysFlag = true;
          if (data.json().status == "1" && data.json().is_confirm == "0") {
            this.cancelFlag = true;
          }
          else if (data.json().status == "0" && data.json().is_confirm == "0") {
            this.cancelFlag = true;
            this.updateFlag = false;
          } else if (data.json().status == "1" && data.json().is_confirm == "1") {
            this.cancelFlag = false;
            this.updateFlag = false;
          }
          if (data.json().delivered == "1") {
            this.deliveryStatusFlag = true;
          }
          if (data.json().is_confirm == "1" || data.json().delivered == "1") {
            this.exeptationStatusFlag = true;
          }
          if (data.json().status == "0" && data.json().is_cancelled == "0" && data.json().rating == null) {
            this.rateFlag = true;
          }
          if (data.json().status == "1" && data.json().is_reject == "1" && data.json().is_cancelled == "0" && data.json().rating == null) {
            this.rateFlag = true;
          }
          this.homecare = false;
        }
        else 
        {
          if (data.json().middle_name != null) {
            this.middleName = encrypt_decript.Decript(data.json().middle_name);
          }
          else {
            this.middleName = "";
          }
          if (data.json().address1 != null) {
            this.pharmaAddrStyle = {
              "display": "block"
            }
            this.pharmaClientName = encrypt_decript.Decript(data.json().first_name) + " " + this.middleName + " " + encrypt_decript.Decript(data.json().last_name) + ",";
          }
          else {
            this.pharmaAddrStyle = {
              "display": "none"
            }
            this.pharmaClientName = encrypt_decript.Decript(data.json().first_name) + " " + this.middleName + " " + encrypt_decript.Decript(data.json().last_name);
          }
          if (data.json().status == "1" && data.json().is_confirm == "0") {
            this.cancelFlag = true;
            this.updateFlag = false;
          } else if (data.json().status == "1" && data.json().is_confirm == "1") {
            this.cancelFlag = false;
            if (data.json().is_allocate != null) {
              this.updateFlag = true;
            }
          }
          if (data.json().preferred_from_time != null) {
            this.preferredTime = this.comm_Data.get_Timeformate(data.json().preferred_from_time) + " to " + this.comm_Data.get_Timeformate(data.json().preferred_to_time);
            this.preferredStatusFlag = true;
          } else {
            this.preferredStatusFlag = false;
          }
          if (data.json().reject_text != null) {
            this.cancelStatusFlag = true;
          }
          if (data.json().delivered == "1" || data.json().is_confirm == "1") {
            this.exeptationStatusFlag = true;
          }
          if (data.json().status == "0" && data.json().is_cancelled == "0" && data.json().rating == null) {
            this.rateFlag = true;
          }
          if (data.json().status == "1" && data.json().is_reject == "1" && data.json().is_cancelled == "0" && data.json().rating == null) {
            this.rateFlag = true;
          }
          this.homecare = true;
        }
        if (data.json().is_reject != null && data.json().is_reject == "1") {
          this.cancelFlag = false;
        }
        this.totalAmountValue = parseFloat(this.totalAmountValue.toString()) + parseFloat(dataValue.toString());
        this.totalAmountValue1 = this.totalAmountValue.toFixed(2);
      },
      error => {
      }
    )
  }

  cancel(){
    const dialogRef = this.dialog.open(PharmacyorderdetailcancelComponent, {
      width: '40%',
      height: '180px',
      data:{ 
       }
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data == "" && data.length == 0) {
        this.toastr.error(Message_data.appcancelreason);
      }else if (data.length > 150) {
        this.toastr.error(Message_data.maxlen150);
      }else if (data != undefined) {
        this.sendData = {
          reject_text: data,
          order_id: this.getPharmaData.orderid,
          pharma_id: this.getPharmaData.pharma_id,
        }
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(this.cancelUrl, JSON.stringify(this.sendData), { headers: headers }).subscribe(
          data => {
            if (data.json().result == "Order cancelled") {
             this.toastr.success(Message_data.orderCancelSuccess);
             this.router.navigateByUrl('pharmacycurrent')
            } else {
              this.toastr.show(data.json().result);
            }
          }, error => {});
      }
    });
  }

  back() {
    this.clientservice.sendMessage('clientpharmacycurrent')
  }

  rateUs() {
  }

  myCallbackFunction = function (_params) {
    return new Promise((resolve, reject) => {
      resolve(_params);
    });
  }
}
