import { Component, OnInit ,ElementRef,Input,Inject,ViewEncapsulation,HostListener, ViewChild} from '@angular/core';
import { Http, Headers } from '@angular/http';
// import { getIp, Ip_with_img_address, country_code } from '../../../assets/js/ipaddress';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
// import { PreviousprescriptionComponent } from '../previousprescription/previousprescription.component';
import {MatSort} from '@angular/material/sort';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from '../../helper_class';
import { DentalexaminationComponent } from '../dentalexamination/dentalexamination.component';
@Component({
  selector: 'app-dentalteethpopup',
  templateUrl: './dentalteethpopup.component.html',
  styleUrls: ['./dentalteethpopup.component.css']
})
export class DentalteethpopupComponent implements OnInit {

  PopupImage: any;
  public img_path;
  public upperimagelist;

  public lowerimagelist;

  public index;

  public teeth_no;

  upper_teeth: boolean;
  TeethNo_18_28: boolean;
  TeethNo_17_27: boolean;
  TeethNo_16_26: boolean;
  TeethNo_15_25: boolean;
  TeethNo_14_24: boolean;
  TeethNo_13_23: boolean;
  TeethNo_12_22: boolean;
  TeethNo_11_21: boolean;

  TeethNo_48_38: boolean;
  TeethNo_47_37: boolean;
  TeethNo_46_36: boolean;
  TeethNo_45_35: boolean;
  TeethNo_44_34: boolean;
  TeethNo_43_33: boolean;
  TeethNo_42_32: boolean;
  TeethNo_41_31: boolean;

  private Upper_ImageIndex = "0";
  private Lower_ImageIndex = "0";
  private Upper_Image_List = [];
  private Lower_Image_List = [];
  
  constructor(public http:Http,public dialogRef: MatDialogRef<DentalteethpopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public el: ElementRef, public toastr:ToastrService) { 
      dialogRef.disableClose = true;
     // this.element = el.nativeElement;
     
     this.TeethNo_18_28 = true;
     this.TeethNo_17_27 = true;
     this.TeethNo_16_26 = true;
     this.TeethNo_15_25 = true;
     this.TeethNo_14_24 = true;
     this.TeethNo_13_23 = true;
     this.TeethNo_12_22 = true;
     this.TeethNo_11_21 = true;
 
 
     this.TeethNo_48_38 = true;
     this.TeethNo_47_37 = true;
     this.TeethNo_46_36 = true;
     this.TeethNo_45_35 = true;
     this.TeethNo_44_34 = true;
     this.TeethNo_43_33 = true;
     this.TeethNo_42_32 = true;
     this.TeethNo_41_31 = true;
   
     // perio chart teeth imags
    this.img_path =data.img_path;
    this.upperimagelist=data.Upper_Image_List;
    this.lowerimagelist=data.Lower_Image_List;
    this.index=data.index;
    this.teeth_no=data.teeth_no;
 
     if (data.img_path != null) {
       // Upper Teeth Image
 
       this.Upper_Image_List  = this.upperimagelist;
       this.Lower_Image_List =   this.lowerimagelist;
 
       this.PopupImage =  this.img_path;
 
       this.Upper_ImageIndex =    this.index;
       this.Lower_ImageIndex =    this.index;
 
       if (  this.teeth_no == "18" ||   this.teeth_no == "28") {
         this.TeethNo_18_28 = false;
       }
       else if (  this.teeth_no == "16" ||   this.teeth_no == "26") {
         this.TeethNo_16_26 = false;
       }
       else if (  this.teeth_no == "17" ||   this.teeth_no == "27") {
         this.TeethNo_17_27 = false;
       }
       else if (  this.teeth_no == "15" ||   this.teeth_no == "25") {
         this.TeethNo_15_25 = false;
       }
       else if (  this.teeth_no == "14" ||   this.teeth_no == "24") {
         this.TeethNo_14_24 = false;
       }
       else if (  this.teeth_no == "13" ||   this.teeth_no == "23") {
         this.TeethNo_13_23 = false;
       }
       else if (  this.teeth_no == "12" ||   this.teeth_no == "22") {
         this.TeethNo_12_22 = false;
       }
       else if (  this.teeth_no == "11" ||   this.teeth_no == "21") {
         this.TeethNo_11_21 = false;
       }
       if (  this.teeth_no == "48" ||   this.teeth_no == "38") {

         this.TeethNo_48_38 = false;

       }
       else if (  this.teeth_no == "47" ||   this.teeth_no == "37") {

         this.TeethNo_47_37 = false;
   
       }
       else if (  this.teeth_no == "46" ||   this.teeth_no == "36") {
   
         this.TeethNo_46_36 = false;
   
       }
       else if (  this.teeth_no == "45" ||   this.teeth_no == "35") {
   
         this.TeethNo_45_35 = false;
   
       }
       else if (  this.teeth_no == "44" ||   this.teeth_no == "34") {
         this.TeethNo_44_34 = false;
       }
       else if (  this.teeth_no == "43" ||   this.teeth_no == "33") {
   
         this.TeethNo_43_33 = false;
   
       }
       else if (  this.teeth_no == "42" ||   this.teeth_no == "32") {
         this.TeethNo_42_32 = false;
       }
       else if (  this.teeth_no == "41" ||   this.teeth_no == "31") {
   
         this.TeethNo_41_31 = false;
       }
     }
    }
  ngOnInit(): void {
  }
  dismiss() {
      var getdata={
        Upper_Image_List: this.Upper_Image_List,
        Lower_Image_List: this.Lower_Image_List
      }
    this.dialogRef.close({   Upper_Image_List: this.Upper_Image_List,
      Lower_Image_List: this.Lower_Image_List})
    // this.viewCtrl.dismiss({
    //   Upper_Image_List: this.Upper_Image_List,
    //   Lower_Image_List: this.Lower_Image_List
    // });
  }
  ionViewDidLoad() {
    
  }

  // upper teeth click
  Upper_Data_click(datapoint) {

    if (datapoint == "1") {
      if (DentalexaminationComponent.Upper_DataArray[0] == "") {
        DentalexaminationComponent.Upper_DataArray[0] = "1";
      }
      else {
        DentalexaminationComponent.Upper_DataArray[0] = "";
      }
    }
    else if (datapoint == "2") {
      if (DentalexaminationComponent.Upper_DataArray[1] == "") {
        DentalexaminationComponent.Upper_DataArray[1] = "2";
      }
      else {
        DentalexaminationComponent.Upper_DataArray[1] = "";
      }
    }
    else if (datapoint == "3") {
      if (DentalexaminationComponent.Upper_DataArray[2] == "") {
        DentalexaminationComponent.Upper_DataArray[2] = "3";
      }
      else {
        DentalexaminationComponent.Upper_DataArray[2] = "";
      }
    }
    else if (datapoint == "4") {
      if (DentalexaminationComponent.Upper_DataArray[3] == "") {
        DentalexaminationComponent.Upper_DataArray[3] = "4";
      }
      else {
        DentalexaminationComponent.Upper_DataArray[3] = "";
      }
    }
    else if (datapoint == "5") {
      if (DentalexaminationComponent.Upper_DataArray[4] == "") {
        DentalexaminationComponent.Upper_DataArray[4] = "5";
      }
      else {
        DentalexaminationComponent.Upper_DataArray[4] = "";
      }
    }
    else if (datapoint == "6") {
      if (DentalexaminationComponent.Upper_DataArray[5] == "") {
        DentalexaminationComponent.Upper_DataArray[5] = "6";
      }
      else {
        DentalexaminationComponent.Upper_DataArray[5] = "";
      }
    }

    var Imagevalue = DentalexaminationComponent.Upper_DataArray[0] + DentalexaminationComponent.Upper_DataArray[1] + DentalexaminationComponent.Upper_DataArray[2] + DentalexaminationComponent.Upper_DataArray[3] + DentalexaminationComponent.Upper_DataArray[4] + DentalexaminationComponent.Upper_DataArray[5] + "c.png";

    if (this.teeth_no == "18" || this.teeth_no == "28") 
    {
      if (DentalexaminationComponent.Upper_DataArray[0] == "" && DentalexaminationComponent.Upper_DataArray[1] == "" && DentalexaminationComponent.Upper_DataArray[2] == "" && DentalexaminationComponent.Upper_DataArray[3] == "" && DentalexaminationComponent.Upper_DataArray[4] == "" && DentalexaminationComponent.Upper_DataArray[5] == "") {
        this.PopupImage = "assets/img/Teeth_Small_img/18_third_molar_reverse.png";
        this.Upper_Image_List[this.Upper_ImageIndex].Imgpath = this.PopupImage;
        this.Upper_Image_List[this.Upper_ImageIndex].Value = "";
      }
      else {
        this.PopupImage = "assets/img/Teethimg/TSI_Upper_18_third_molar_rev/" +  DentalexaminationComponent.Upper_DataArray[0] + DentalexaminationComponent.Upper_DataArray[1] + DentalexaminationComponent.Upper_DataArray[2] + DentalexaminationComponent.Upper_DataArray[3] + DentalexaminationComponent.Upper_DataArray[4] + DentalexaminationComponent.Upper_DataArray[5] + "c.png";;
        this.Upper_Image_List[this.Upper_ImageIndex].Imgpath = this.PopupImage;
        this.Upper_Image_List[this.Upper_ImageIndex].Value = Imagevalue;
      }
    }
    else if (this.teeth_no=="17" || this.teeth_no=="27") // Teeth 17 & 27
    {
      if (DentalexaminationComponent.Upper_DataArray[0] == "" && DentalexaminationComponent.Upper_DataArray[1] == "" && DentalexaminationComponent.Upper_DataArray[2] == "" && DentalexaminationComponent.Upper_DataArray[3] == "" && DentalexaminationComponent.Upper_DataArray[4] == "" && DentalexaminationComponent.Upper_DataArray[5] == "") {
        this.PopupImage = "assets/img/Teeth_Small_img/17_second_molar_reverse.png";
        this.Upper_Image_List[this.Upper_ImageIndex].Imgpath = this.PopupImage;
        this.Upper_Image_List[this.Upper_ImageIndex].Value = "";
      }
      else {
        this.PopupImage = "assets/img/Teethimg/TSI_Upper_17_Sec_molar_rev/" + DentalexaminationComponent.Upper_DataArray[0] + DentalexaminationComponent.Upper_DataArray[1] + DentalexaminationComponent.Upper_DataArray[2] + DentalexaminationComponent.Upper_DataArray[3] + DentalexaminationComponent.Upper_DataArray[4] + DentalexaminationComponent.Upper_DataArray[5] + "c.png";
        this.Upper_Image_List[this.Upper_ImageIndex].Imgpath = this.PopupImage;
        this.Upper_Image_List[this.Upper_ImageIndex].Value = Imagevalue;
      }
    }
    else if (this.teeth_no=="16" || this.teeth_no=="26") // Teeth 16 & 26
    {
      if (DentalexaminationComponent.Upper_DataArray[0] == "" && DentalexaminationComponent.Upper_DataArray[1] == "" && DentalexaminationComponent.Upper_DataArray[2] == "" && DentalexaminationComponent.Upper_DataArray[3] == "" && DentalexaminationComponent.Upper_DataArray[4] == "" && DentalexaminationComponent.Upper_DataArray[5] == "") {
        this.PopupImage = "assets/img/Teeth_Small_img/16_first_molar_reverse.png";
        this.Upper_Image_List[this.Upper_ImageIndex].Imgpath = this.PopupImage;
        this.Upper_Image_List[this.Upper_ImageIndex].Value = "";
      }
      else {
        this.PopupImage = "assets/img/Teethimg/TSI_Upper_16_Fir_molar_rev/" + DentalexaminationComponent.Upper_DataArray[0] + DentalexaminationComponent.Upper_DataArray[1] + DentalexaminationComponent.Upper_DataArray[2] + DentalexaminationComponent.Upper_DataArray[3] + DentalexaminationComponent.Upper_DataArray[4] + DentalexaminationComponent.Upper_DataArray[5] + "c.png";
        this.Upper_Image_List[this.Upper_ImageIndex].Imgpath = this.PopupImage;
        this.Upper_Image_List[this.Upper_ImageIndex].Value = Imagevalue;
      }
    }
    else if (this.teeth_no=="15" || this.teeth_no=="25") // Teeth 15 & 25
    {
      if (DentalexaminationComponent.Upper_DataArray[0] == "" && DentalexaminationComponent.Upper_DataArray[1] == "" && DentalexaminationComponent.Upper_DataArray[2] == "" && DentalexaminationComponent.Upper_DataArray[3] == "" && DentalexaminationComponent.Upper_DataArray[4] == "" && DentalexaminationComponent.Upper_DataArray[5] == "") {
        this.PopupImage = "assets/img/Teeth_Small_img/15_second_premolar_reverse.png";
        this.Upper_Image_List[this.Upper_ImageIndex].Imgpath = this.PopupImage;
        this.Upper_Image_List[this.Upper_ImageIndex].Value = "";
      }
      else {
        this.PopupImage = "assets/img/Teethimg/TSI_Upper_15_SecPre_molar_rev/" + DentalexaminationComponent.Upper_DataArray[0] + DentalexaminationComponent.Upper_DataArray[1] + DentalexaminationComponent.Upper_DataArray[2] + DentalexaminationComponent.Upper_DataArray[3] + DentalexaminationComponent.Upper_DataArray[4] + DentalexaminationComponent.Upper_DataArray[5] + "c.png";
        this.Upper_Image_List[this.Upper_ImageIndex].Imgpath = this.PopupImage;
        this.Upper_Image_List[this.Upper_ImageIndex].Value = Imagevalue;
      }
    }
    else if (this.teeth_no=="14" || this.teeth_no=="24") // Teeth 14 & 24
    {
      if (DentalexaminationComponent.Upper_DataArray[0] == "" && DentalexaminationComponent.Upper_DataArray[1] == "" && DentalexaminationComponent.Upper_DataArray[2] == "" && DentalexaminationComponent.Upper_DataArray[3] == "" && DentalexaminationComponent.Upper_DataArray[4] == "" && DentalexaminationComponent.Upper_DataArray[5] == "") {
        this.PopupImage = "assets/img/Teeth_Small_img/14_first_premolar_reverse.png";
        this.Upper_Image_List[this.Upper_ImageIndex].Imgpath = this.PopupImage;
        this.Upper_Image_List[this.Upper_ImageIndex].Value = "";
      }
      else {
        this.PopupImage = "assets/img/Teethimg/TSI_Upper_14_FirPre_molar_rev/" + DentalexaminationComponent.Upper_DataArray[0] + DentalexaminationComponent.Upper_DataArray[1] + DentalexaminationComponent.Upper_DataArray[2] + DentalexaminationComponent.Upper_DataArray[3] + DentalexaminationComponent.Upper_DataArray[4] + DentalexaminationComponent.Upper_DataArray[5] + "c.png";
        this.Upper_Image_List[this.Upper_ImageIndex].Imgpath = this.PopupImage;
        this.Upper_Image_List[this.Upper_ImageIndex].Value = Imagevalue;
      }
    }
    else if (this.teeth_no=="13" || this.teeth_no=="23") // Teeth 13 & 23
    {
      if (DentalexaminationComponent.Upper_DataArray[0] == "" && DentalexaminationComponent.Upper_DataArray[1] == "" && DentalexaminationComponent.Upper_DataArray[2] == "" && DentalexaminationComponent.Upper_DataArray[3] == "" && DentalexaminationComponent.Upper_DataArray[4] == "" && DentalexaminationComponent.Upper_DataArray[5] == "") {
        this.PopupImage = "assets/img/Teeth_Small_img/13_canine_reverse.png";
        this.Upper_Image_List[this.Upper_ImageIndex].Imgpath = this.PopupImage;
        this.Upper_Image_List[this.Upper_ImageIndex].Value = "";
      }
      else {
        this.PopupImage = "assets/img/Teethimg/TSI_Upper_13_canine_rev/" + DentalexaminationComponent.Upper_DataArray[0] + DentalexaminationComponent.Upper_DataArray[1] + DentalexaminationComponent.Upper_DataArray[2] + DentalexaminationComponent.Upper_DataArray[3] + DentalexaminationComponent.Upper_DataArray[4] + DentalexaminationComponent.Upper_DataArray[5] + "c.png";
        this.Upper_Image_List[this.Upper_ImageIndex].Imgpath = this.PopupImage;
        this.Upper_Image_List[this.Upper_ImageIndex].Value = Imagevalue;
      }
    }
    else if (this.teeth_no=="12" || this.teeth_no=="22") // Teeth 12 & 22
    {
      if (DentalexaminationComponent.Upper_DataArray[0] == "" && DentalexaminationComponent.Upper_DataArray[1] == "" && DentalexaminationComponent.Upper_DataArray[2] == "" && DentalexaminationComponent.Upper_DataArray[3] == "" && DentalexaminationComponent.Upper_DataArray[4] == "" && DentalexaminationComponent.Upper_DataArray[5] == "") {
        this.PopupImage = "assets/img/Teeth_Small_img/12_latrel_insisor_reverse.png";
        this.Upper_Image_List[this.Upper_ImageIndex].Imgpath = this.PopupImage;
        this.Upper_Image_List[this.Upper_ImageIndex].Value = "";
      }
      else {
        this.PopupImage = "assets/img/Teethimg/TSI_Upper_12_Lat_insisor_rev/" + DentalexaminationComponent.Upper_DataArray[0] + DentalexaminationComponent.Upper_DataArray[1] + DentalexaminationComponent.Upper_DataArray[2] + DentalexaminationComponent.Upper_DataArray[3] + DentalexaminationComponent.Upper_DataArray[4] + DentalexaminationComponent.Upper_DataArray[5] + "c.png";
        this.Upper_Image_List[this.Upper_ImageIndex].Imgpath = this.PopupImage;
        this.Upper_Image_List[this.Upper_ImageIndex].Value = Imagevalue;
      }
    }
    else if (this.teeth_no=="11" || this.teeth_no=="21") // Teeth 11 & 21
    {
      if (DentalexaminationComponent.Upper_DataArray[0] == "" && DentalexaminationComponent.Upper_DataArray[1] == "" && DentalexaminationComponent.Upper_DataArray[2] == "" && DentalexaminationComponent.Upper_DataArray[3] == "" && DentalexaminationComponent.Upper_DataArray[4] == "" && DentalexaminationComponent.Upper_DataArray[5] == "") {
        this.PopupImage = "assets/img/Teeth_Small_img/11_central_insisor_reverse.png";
        this.Upper_Image_List[this.Upper_ImageIndex].Imgpath = this.PopupImage;
        this.Upper_Image_List[this.Upper_ImageIndex].Value = "";
      }
      else {
        this.PopupImage = "assets/img/Teethimg/TSI_Upper_11_Central_insisor_rev/" + DentalexaminationComponent.Upper_DataArray[0] + DentalexaminationComponent.Upper_DataArray[1] + DentalexaminationComponent.Upper_DataArray[2] + DentalexaminationComponent.Upper_DataArray[3] + DentalexaminationComponent.Upper_DataArray[4] + DentalexaminationComponent.Upper_DataArray[5] + "c.png";
        this.Upper_Image_List[this.Upper_ImageIndex].Imgpath = this.PopupImage;
        this.Upper_Image_List[this.Upper_ImageIndex].Value = Imagevalue;
      }
    }
  }

  //lower teeth click
  Lower_Data_click(datapoint) {

    if (datapoint == "1") {
      if (DentalexaminationComponent.Lower_DataArray[0] == "") {
        DentalexaminationComponent.Lower_DataArray[0] = "1";
      }
      else {
        DentalexaminationComponent.Lower_DataArray[0] = "";
      }
    }
    else if (datapoint == "2") {
      if (DentalexaminationComponent.Lower_DataArray[1] == "") {
        DentalexaminationComponent.Lower_DataArray[1] = "2";
      }
      else {
        DentalexaminationComponent.Lower_DataArray[1] = "";
      }
    }
    else if (datapoint == "3") {
      if (DentalexaminationComponent.Lower_DataArray[2] == "") {
        DentalexaminationComponent.Lower_DataArray[2] = "3";
      }
      else {
        DentalexaminationComponent.Lower_DataArray[2] = "";
      }
    }
    else if (datapoint == "4") {
      if (DentalexaminationComponent.Lower_DataArray[3] == "") {
        DentalexaminationComponent.Lower_DataArray[3] = "4";
      }
      else {
        DentalexaminationComponent.Lower_DataArray[3] = "";
      }
    }
    else if (datapoint == "5") {
      if (DentalexaminationComponent.Lower_DataArray[4] == "") {
        DentalexaminationComponent.Lower_DataArray[4] = "5";
      }
      else {
        DentalexaminationComponent.Lower_DataArray[4] = "";
      }
    }
    else if (datapoint == "6") {
      if (DentalexaminationComponent.Lower_DataArray[5] == "") {
        DentalexaminationComponent.Lower_DataArray[5] = "6";
      }
      else {
        DentalexaminationComponent.Lower_DataArray[5] = "";
      }
    }

    var Imagevalue = DentalexaminationComponent.Lower_DataArray[0] + DentalexaminationComponent.Lower_DataArray[1] + DentalexaminationComponent.Lower_DataArray[2] + DentalexaminationComponent.Lower_DataArray[3] + DentalexaminationComponent.Lower_DataArray[4] + DentalexaminationComponent.Lower_DataArray[5] + "c.png";

    
    if (this.teeth_no=="48" || this.teeth_no=="38") // Teeth 18 & 28
    {
      if (DentalexaminationComponent.Lower_DataArray[0] == "" && DentalexaminationComponent.Lower_DataArray[1] == "" && DentalexaminationComponent.Lower_DataArray[2] == "" && DentalexaminationComponent.Lower_DataArray[3] == "" && DentalexaminationComponent.Lower_DataArray[4] == "" && DentalexaminationComponent.Lower_DataArray[5] == "") {
        this.PopupImage = "assets/img/Teeth_Small_img/48_third_molar.png";
        this.Lower_Image_List[this.Lower_ImageIndex].Imgpath = this.PopupImage;
        this.Lower_Image_List[this.Lower_ImageIndex].Value = "";
      }
      else {
        this.PopupImage = "assets/img/Teethimg/TSI_Lower_48_third_molar/" + DentalexaminationComponent.Lower_DataArray[0] + DentalexaminationComponent.Lower_DataArray[1] + DentalexaminationComponent.Lower_DataArray[2] + DentalexaminationComponent.Lower_DataArray[3] + DentalexaminationComponent.Lower_DataArray[4] + DentalexaminationComponent.Lower_DataArray[5] + "c.png";
        this.Lower_Image_List[this.Lower_ImageIndex].Imgpath = this.PopupImage;
        this.Lower_Image_List[this.Lower_ImageIndex].Value = Imagevalue;
      }
    }
    else if (this.teeth_no=="47" || this.teeth_no=="37") // Teeth 17 & 27
    {
      if (DentalexaminationComponent.Lower_DataArray[0] == "" && DentalexaminationComponent.Lower_DataArray[1] == "" && DentalexaminationComponent.Lower_DataArray[2] == "" && DentalexaminationComponent.Lower_DataArray[3] == "" && DentalexaminationComponent.Lower_DataArray[4] == "" && DentalexaminationComponent.Lower_DataArray[5] == "") {
        this.PopupImage = "assets/img/Teeth_Small_img/47_second_molar.png";
        this.Lower_Image_List[this.Lower_ImageIndex].Imgpath = this.PopupImage;
        this.Lower_Image_List[this.Lower_ImageIndex].Value = "";
      }
      else {
        this.PopupImage = "assets/img/Teethimg/TSI_Lower_47_Sec_molar/" + DentalexaminationComponent.Lower_DataArray[0] + DentalexaminationComponent.Lower_DataArray[1] + DentalexaminationComponent.Lower_DataArray[2] + DentalexaminationComponent.Lower_DataArray[3] + DentalexaminationComponent.Lower_DataArray[4] + DentalexaminationComponent.Lower_DataArray[5] + "c.png";
        this.Lower_Image_List[this.Lower_ImageIndex].Imgpath = this.PopupImage;
        this.Lower_Image_List[this.Lower_ImageIndex].Value = Imagevalue;
      }
    }
    else if (this.teeth_no=="46" || this.teeth_no=="36") // Teeth 16 & 26
    {
      if (DentalexaminationComponent.Lower_DataArray[0] == "" && DentalexaminationComponent.Lower_DataArray[1] == "" && DentalexaminationComponent.Lower_DataArray[2] == "" && DentalexaminationComponent.Lower_DataArray[3] == "" && DentalexaminationComponent.Lower_DataArray[4] == "" && DentalexaminationComponent.Lower_DataArray[5] == "") {
        this.PopupImage = "assets/img/Teeth_Small_img/46_first_molar.png";
        this.Lower_Image_List[this.Lower_ImageIndex].Imgpath = this.PopupImage;
        this.Lower_Image_List[this.Lower_ImageIndex].Value = "";
      }
      else {
        this.PopupImage = "assets/img/Teethimg/TSI_Lower_46_Fir_molar/" + DentalexaminationComponent.Lower_DataArray[0] + DentalexaminationComponent.Lower_DataArray[1] + DentalexaminationComponent.Lower_DataArray[2] + DentalexaminationComponent.Lower_DataArray[3] + DentalexaminationComponent.Lower_DataArray[4] + DentalexaminationComponent.Lower_DataArray[5] + "c.png";
        this.Lower_Image_List[this.Lower_ImageIndex].Imgpath = this.PopupImage;
        this.Lower_Image_List[this.Lower_ImageIndex].Value = Imagevalue;
      }
    }
    else if (this.teeth_no=="45" || this.teeth_no=="35") // Teeth 15 & 25
    {
      if (DentalexaminationComponent.Lower_DataArray[0] == "" && DentalexaminationComponent.Lower_DataArray[1] == "" && DentalexaminationComponent.Lower_DataArray[2] == "" && DentalexaminationComponent.Lower_DataArray[3] == "" && DentalexaminationComponent.Lower_DataArray[4] == "" && DentalexaminationComponent.Lower_DataArray[5] == "") {
        this.PopupImage = "assets/img/Teeth_Small_img/45_second_premolar.png";
        this.Lower_Image_List[this.Lower_ImageIndex].Imgpath = this.PopupImage;
        this.Lower_Image_List[this.Lower_ImageIndex].Value = "";
      }
      else {
        this.PopupImage = "assets/img/Teethimg/TSI_Lower_45_SecPre_molar/" + DentalexaminationComponent.Lower_DataArray[0] + DentalexaminationComponent.Lower_DataArray[1] + DentalexaminationComponent.Lower_DataArray[2] + DentalexaminationComponent.Lower_DataArray[3] + DentalexaminationComponent.Lower_DataArray[4] + DentalexaminationComponent.Lower_DataArray[5] + "c.png";
        this.Lower_Image_List[this.Lower_ImageIndex].Imgpath = this.PopupImage;
        this.Lower_Image_List[this.Lower_ImageIndex].Value = Imagevalue;
      }
    }
    else if (this.teeth_no=="34" ||this.teeth_no=="34") // Teeth 14 & 24
    {
      if (DentalexaminationComponent.Lower_DataArray[0] == "" && DentalexaminationComponent.Lower_DataArray[1] == "" && DentalexaminationComponent.Lower_DataArray[2] == "" && DentalexaminationComponent.Lower_DataArray[3] == "" && DentalexaminationComponent.Lower_DataArray[4] == "" && DentalexaminationComponent.Lower_DataArray[5] == "") {
        this.PopupImage = "assets/img/Teeth_Small_img/44_first_premolar.png";
        this.Lower_Image_List[this.Lower_ImageIndex].Imgpath = this.PopupImage;
        this.Lower_Image_List[this.Lower_ImageIndex].Value = "";
      }
      else {
        this.PopupImage = "assets/img/Teethimg/TSI_Lower_44_FirPre_molar/" + DentalexaminationComponent.Lower_DataArray[0] + DentalexaminationComponent.Lower_DataArray[1] + DentalexaminationComponent.Lower_DataArray[2] + DentalexaminationComponent.Lower_DataArray[3] + DentalexaminationComponent.Lower_DataArray[4] + DentalexaminationComponent.Lower_DataArray[5] + "c.png";
        this.Lower_Image_List[this.Lower_ImageIndex].Imgpath = this.PopupImage;
        this.Lower_Image_List[this.Lower_ImageIndex].Value = Imagevalue;
      }
    }
    else if (this.teeth_no=="43" || this.teeth_no=="33") // Teeth 13 & 23
    {
      if (DentalexaminationComponent.Lower_DataArray[0] == "" && DentalexaminationComponent.Lower_DataArray[1] == "" && DentalexaminationComponent.Lower_DataArray[2] == "" && DentalexaminationComponent.Lower_DataArray[3] == "" && DentalexaminationComponent.Lower_DataArray[4] == "" && DentalexaminationComponent.Lower_DataArray[5] == "") {
        this.PopupImage = "assets/img/Teeth_Small_img/43_canine.png";
        this.Lower_Image_List[this.Lower_ImageIndex].Imgpath = this.PopupImage;
        this.Lower_Image_List[this.Lower_ImageIndex].Value = "";
      }
      else {
        this.PopupImage = "assets/img/Teethimg/TSI_Lower_43_canine/" + DentalexaminationComponent.Lower_DataArray[0] + DentalexaminationComponent.Lower_DataArray[1] + DentalexaminationComponent.Lower_DataArray[2] + DentalexaminationComponent.Lower_DataArray[3] + DentalexaminationComponent.Lower_DataArray[4] + DentalexaminationComponent.Lower_DataArray[5] + "c.png";
        this.Lower_Image_List[this.Lower_ImageIndex].Imgpath = this.PopupImage;
        this.Lower_Image_List[this.Lower_ImageIndex].Value = Imagevalue;
      }
    }
    else if (this.teeth_no=="42" || this.teeth_no=="32") // Teeth 12 & 22
    {
      if (DentalexaminationComponent.Lower_DataArray[0] == "" && DentalexaminationComponent.Lower_DataArray[1] == "" && DentalexaminationComponent.Lower_DataArray[2] == "" && DentalexaminationComponent.Lower_DataArray[3] == "" && DentalexaminationComponent.Lower_DataArray[4] == "" && DentalexaminationComponent.Lower_DataArray[5] == "") {
        this.PopupImage = "assets/img/Teeth_Small_img/42_latrel_insisor.png";
        this.Lower_Image_List[this.Lower_ImageIndex].Imgpath = this.PopupImage;
        this.Lower_Image_List[this.Lower_ImageIndex].Value = "";
      }
      else {
        this.PopupImage = "assets/img/Teethimg/TSI_Lower_42_Lat_insisor/" + DentalexaminationComponent.Lower_DataArray[0] + DentalexaminationComponent.Lower_DataArray[1] + DentalexaminationComponent.Lower_DataArray[2] + DentalexaminationComponent.Lower_DataArray[3] + DentalexaminationComponent.Lower_DataArray[4] + DentalexaminationComponent.Lower_DataArray[5] + "c.png";
        this.Lower_Image_List[this.Lower_ImageIndex].Imgpath = this.PopupImage;
        this.Lower_Image_List[this.Lower_ImageIndex].Value = Imagevalue;
      }
    }
    else if (this.teeth_no=="41" || this.teeth_no=="31") // Teeth 41 & 31
    {
      if (DentalexaminationComponent.Lower_DataArray[0] == "" && DentalexaminationComponent.Lower_DataArray[1] == "" && DentalexaminationComponent.Lower_DataArray[2] == "" && DentalexaminationComponent.Lower_DataArray[3] == "" && DentalexaminationComponent.Lower_DataArray[4] == "" && DentalexaminationComponent.Lower_DataArray[5] == "") {
        this.PopupImage = "assets/img/Teeth_Small_img/41_central_insisor.png";
        this.Lower_Image_List[this.Lower_ImageIndex].Imgpath = this.PopupImage;
        this.Lower_Image_List[this.Lower_ImageIndex].Value = "";
      }
      else {
        this.PopupImage = "assets/img/Teethimg/TSI_Lower_41_Central_insisor/" + DentalexaminationComponent.Lower_DataArray[0] + DentalexaminationComponent.Lower_DataArray[1] + DentalexaminationComponent.Lower_DataArray[2] + DentalexaminationComponent.Lower_DataArray[3] + DentalexaminationComponent.Lower_DataArray[4] + DentalexaminationComponent.Lower_DataArray[5] + "c.png";
        this.Lower_Image_List[this.Lower_ImageIndex].Imgpath = this.PopupImage;
        this.Lower_Image_List[this.Lower_ImageIndex].Value = Imagevalue;
      }
    }
  }
  ClosePopup(){
    this.dialogRef.close();
  }

}
