import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Time_Formate } from '../../../assets/js/common.js';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { CommonDataService } from '../../providers/common-data.service';
import { MatDialog } from '@angular/material/dialog';
import { DiagRescheduleComponent } from '../diag-reschedule/diag-reschedule.component';
import { ClientCancelAppointmentComponent } from '../client-cancel-appointment/client-cancel-appointment.component';
import { Client_Helper } from '../Client_helper';
import { ClientViewService } from '../clientview/client-view.service';
import { ipaddress } from '../../ipaddress';

@Component({
  selector: 'app-diagnosis-detailed-view',
  templateUrl: './diagnosis-detailed-view.component.html',
  styleUrls: ['./diagnosis-detailed-view.component.css']
})
export class DiagnosisDetailedViewComponent implements OnInit {

  public currentDateUrl: string;
  public currentDate: string;
  public sendData;
  public diagAppId: string;
  public status: string;
  public appViewUrl: string;
  public sendApptViewData;
  public relation: string;
  public firstName: string;
  public middleName: string;
  public lastName: string;
  public age: string;
  public gender: string;
  public bgrp: string;
  public phone: string;
  public refDrName: string;
  public centerName: string;
  public centerAddr1: string;
  public centerAddr2: string;
  public apptDate: string;
  public apptSession: string;
  public apptTime: string;
  public testName = [];
  public testNameList = [];
  public deleteAppList = [];
  public deleteAppUrl;
  public checkCancel: Boolean = false;
  public checkReschedule: Boolean = false;
  public checkRate: Boolean = false;
  public diagCenterId: string;
  public sentdata;
  public cancelApptUrl;
  public testFlag: Boolean = false;

  constructor(public messageservice:ClientViewService,public dialog: MatDialog,public gservice:CommonDataService,public datepipe:DatePipe,public http:Http,public routes:ActivatedRoute,public router:Router,public toastr:ToastrService) {
    this.currentDateUrl = ipaddress.getIp + "adm/curdate/";
    this.appViewUrl = ipaddress.getIp + "appointment/viewapp/";
    this.deleteAppUrl = ipaddress.getIp + "appointment/delapps/";
    this.cancelApptUrl = ipaddress.getIp + "appointment/cancelapp/";
    this.sendData = {
      country: ipaddress.country_code
    }//759
  }

  ngOnInit(): void {
    this.diagAppId = Client_Helper.getDoctorApptdetail().app_id;
    this.status = Client_Helper.getDoctorApptdetail().app_status;
    if (this.status == "Open" || this.status == "Confirmed" || this.status == "Time not alloted" || this.status == "Time alloted") {
      this.checkCancel = true;
      this.checkReschedule = true;
    }
    if (this.status == "Awaiting for rating" || this.status == "Report yet to be finalized") {
      this.checkRate = true;
    }

    this.sendApptViewData = {
      diag_app_id: this.diagAppId,
      type: "diagnosis",
      country: ipaddress.country_code,
      home_care: "0"
    }
    this.getCurrentDate();
    this.appGetDetails();  
  }

  getCurrentDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.currentDateUrl, JSON.stringify(this.sendData), { headers: headers }).subscribe(
      data => {
        this.currentDate = data.json().current_date;
      }, error => {});
  }

  appGetDetails() {
    function StringBuilder(this:any,value) {
      this.strings = new Array("");
      this.append(value);
    }
    
    StringBuilder.prototype.append = function (value) {// Appends the given value to the end of this instance.
      if (value) {
        this.strings.push(value);
      }
    }
   
    StringBuilder.prototype.clear = function () { // Clears the string buffer
      this.strings.length = 1;
    }
    
    StringBuilder.prototype.toString = function () {// Converts this instance to a String.
      return this.strings.join("");
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.appViewUrl, JSON.stringify(this.sendApptViewData), { headers: headers }).subscribe(
      data => {
        var obj = JSON.parse(data["_body"]);
        this.relation = obj.relation;
        this.firstName = encrypt_decript.Decript(obj.first_name);
        this.middleName = "";
        if (obj.middle_name != null) {
          this.middleName = encrypt_decript.Decript(obj.middle_name);
        }
        this.lastName = encrypt_decript.Decript(obj.last_name);
        this.age = obj.age != null ? obj.age : "";
        this.gender = encrypt_decript.Decript(obj.gender);
        if (obj.centre_id != null) {
          this.diagCenterId = obj.centre_id;
        }
        this.bgrp = "";
        if (obj.blood_desc != null) {
          this.bgrp = obj.blood_desc;
        }
        this.phone = "";
        if (obj.mobile != null) {
          this.phone = encrypt_decript.Decript(obj.mobile);
        }
        this.refDrName = "";
        if (obj.dr_first_name != undefined) {
          if (obj.dr_middle_name != null) {
            this.refDrName = obj.dr_first_name + " " + obj.dr_middle_name + " " + obj.dr_last_name;
          } else {
            this.refDrName = obj.dr_first_name + " " + obj.dr_last_name;
          }
        }
        var sb = new StringBuilder("");
        if ((obj.centre_name) != null) {
          sb.append((obj.centre_name));
          sb.append("\n");
          sb.append((obj.address1));
          sb.append("\n");

          if ((obj.address1) != null && ((obj.address2)) != "") {
            sb.append((obj.address2));
            sb.append("\n");
          }
          sb.append(obj.location);
          sb.append("\n");
          sb.append(obj.city + "-" + (obj.zipcode));
          sb.append("\n");
          sb.append(obj.state);
          sb.append("\n");
          sb.append(obj.country);
          sb.append("\n");
          sb.append((obj.telephone));
          this.centerName = sb.toString();
        }

        var sb1 = new StringBuilder("");
        if (obj.date != null) {
          this.apptDate = "Date : " + obj.date.split('-')[2] + "-" + obj.date.split('-')[1] + "-" + obj.date.split('-')[0];
          sb1.append((this.apptDate));
          sb1.append("\n");
        }

        if (obj.session != null) {
          this.apptSession = "Session : " + obj.session;
          sb1.append((this.apptSession));
          sb1.append("\n");
        }

        if (obj.f_time != null) {
          this.apptTime = "Allotted time : " + Time_Formate(obj.f_time);
          sb1.append((this.apptTime));
          sb1.append("\n");
        }

        this.centerAddr1 = sb1.toString();
        if (obj.rating != null) {
          this.checkRate = false;
        }

        if (obj.tests != null) {      
          this.testFlag = true;
          this.testName = [];
          this.testNameList = [];

          for (var i = 0; i < obj.tests.length; i++) {
            this.testName.push(obj.tests[i]);
            var t_name = this.testName[i].replace("_", " ");
            t_name = t_name.charAt(0).toUpperCase() + t_name.substr(1).toLowerCase();
            this.testNameList.push({ group_name: t_name });
            if (obj.tests != null) {
              var value = obj.tests[i];
              for (var g = 0; g < obj[value].length; g++) {
                var data_obj = obj[value][g];
                this.testNameList.push({ sub_test: data_obj.diag_test_name });
              }
            }
          }
        }
      }, error => {});
  }

  reschedule() {
    const dialog = this.dialog.open(DiagRescheduleComponent, {
              width: '50%',
              height: '300px',
              data:{diag_app_id: this.diagAppId,}
    });
  }

  rateus() {
    // const dialogRef = this.dialog.open(RatingComponent, {
    //   width: '50%',
    //   height: '500px',
    //   data:{
    //       doc_app_id: this.diag_app_id,
    //       center_id: this.center_id,
    //       flag: "flag",
    //       AppFlow: "diagnosis"
    //     }
    // });

    // var modalPage = this.modalCtrl.create('RatingPage', {
    //   doc_app_id: this.diag_app_id,
    //   center_id: this.center_id,
    //   flag: "flag",
    //   "AppFlow": "diagnosis"
    // });

    // modalPage.present();
    // modalPage.onDidDismiss(data => {
    // }
    // );
  }

  cancel() {  
    const dialogRef = this.dialog.open(ClientCancelAppointmentComponent, {
      width: '50%',
      height: '300px',
      data:{diag_app_id: this.diagAppId,}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        if (result != undefined && result != undefined) {
          this.sentdata = {
            reason: result,
            diag_app_id: this.diagAppId,
          }
  
          var headers = new Headers();
          headers.append('Content-Type', 'application/json');
          this.http.post(this.cancelApptUrl, JSON.stringify(this.sentdata), { headers: headers }).subscribe(
            data => {
              if (data.json().key != null && data.json().key == "1") {
                this.toastr.success(data.json().result);
                this.messageservice.sendMessage("diagnosis");
              }else
                this.toastr.error(data.json().result);
            }, error => {});
        }
      }
    });
  }

  back(){
    this.messageservice.sendMessage("diagnosis");
  }
}
