import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
// import { ipaddress.getIp } from '../../../assets/js/ipaddress';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ipaddress } from '../../ipaddress';

declare var require: any;

var FileSaver = require('file-saver');

@Component({
  selector: 'app-rdu',
  templateUrl: './rdu.component.html',
  styleUrls: ['./rdu.component.scss']
})
export class RduComponent implements OnInit {

  rddlpram;
  
  constructor(public http:HttpClient,public router:ActivatedRoute) { 
    let splitURL = document.URL.split("?");
    this.rddlpram =  splitURL[1].toString().slice(0, -1);
  }

  ngOnInit(): void {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'us/glink',{slink: this.rddlpram},
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          
          FileSaver.saveAs(obj.org_url,"prescription");
        },error => {});
      window.close();
  }
}
