import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Helper_Class } from '../helper_class';
import { ipaddress } from '../ipaddress';
import { ServerApi } from '../server-api';
import { Master_Helper } from './Master_Helper';

@Injectable({ providedIn: 'root' })
export class MasterHelperService {

  constructor(public http:HttpClient,public serverProvider: ServerApi){
  }

  public getData(url){
    var headers = new HttpHeaders();
       headers.append('Content-Type', 'application/json;');
       return this.http.get( ipaddress.getIp.toString() + url, { headers: headers });
  }
  
  public PostData(url, data) {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post(ipaddress.getIp.toString() + url, data, { headers: headers });
  }

  clearmasterData(){
    Master_Helper.setMasterRelationData(undefined);
    Master_Helper.setMasterToken(undefined);
    Master_Helper.setMasterHospital(undefined);
    Master_Helper.setMasterSalutation(undefined);
    Master_Helper.setMasterSpecialization(undefined);
    Master_Helper.setMasterGeneralSurgeries(undefined);
    Master_Helper.setMasterCountries(undefined);
    Master_Helper.setMasterStates(undefined);
    Master_Helper.setMasterCities(undefined);
    Master_Helper.setMasterLocation(undefined);
    Master_Helper.setMasterCovidSymptoms(undefined);
    Master_Helper.setMasterComorbidities(undefined);
    Master_Helper.setMasterWardData(undefined);
    Master_Helper.setMasterDoctorProfileInfo(undefined);
    Master_Helper.setMasterHospitalList(undefined);
    Master_Helper.setMasterPaymentType(undefined);

    Master_Helper.setMasterGSTData(undefined);
    Master_Helper.setMasterInsurers(undefined);
    Master_Helper.setMasterCityDetails(undefined);
    Master_Helper.setMasterTreatement(undefined);
    Master_Helper.setMasterSubspecilities(undefined);
    Master_Helper.setMasterIdProofType(undefined);
  }

  callMasterData(){
      if(Helper_Class.getInfo().provider == "doctor"){
        this.getTreatement();
        this.getSubspecilities();
        this.get_hosp_list();
      }
      this.getRelationData();
      this.getHospitalDetails();
      this.getSalutations();
      this.getSpeciality();
      this.getGeneralSurgeries();
      this.getCountries();
      this.getStates();
      this.getCities();
      this.getLocations();
      this.getCovidSymptoms();
      this.getComorbidities();
      this.getWardData();
      this.getPaymentType();
      this.getGstData();
      this.getInsurers();
      this.getCityDetails();
      this.getIdProofType();
  }

  async getCurrentDate() {
    await this.PostData('adm/curdate',{country: ipaddress.country_code.toString()}).toPromise().then(
      (data) => Master_Helper.setMasterCurrentDate(JSON.parse(JSON.stringify(data))));
  }

  async getRelationData() {
    await this.getData('gen/getrel').toPromise().then(
      (data) => Master_Helper.setMasterRelationData(JSON.parse(JSON.stringify(data))));
  }

  async getHospitalDetails() {
    await this.PostData('usercontroller/hospdetails',{hosp_clinic_id: Helper_Class.getInfo().hospitals[0].hptl_clinic_id}).toPromise().then(
      (data) => Master_Helper.setMasterHospital(JSON.parse(JSON.stringify(data))));
  }

  async getSalutations() {
    await this.getData('gen/sal').toPromise().then(
      (data) => Master_Helper.setMasterSalutation(JSON.parse(JSON.stringify(data))));
  }

  async getSpeciality() {
    await this.getData('usercontroller/getspecialization/').toPromise().then(
      (data) => Master_Helper.setMasterSpecialization(JSON.parse(JSON.stringify(data))));
  }

  async getGeneralSurgeries() {
    await this.PostData('cardio/medproc',{ "spl_id": "29"}).toPromise().then(
      (data) => Master_Helper.setMasterGeneralSurgeries(JSON.parse(JSON.stringify(data))));
  }

  async getCountries() {  
    await this.getData('usercontroller/count').toPromise().then(
      (data) => Master_Helper.setMasterCountries(JSON.parse(JSON.stringify(data))));
  }

  async getStates() {
    await this.PostData('usercontroller/state',{ country_id: Helper_Class.getInfo().hospitals[0].country }).toPromise().then(
      (data) => Master_Helper.setMasterStates(JSON.parse(JSON.stringify(data))));
  }

  async getCities() {
    this.PostData('usercontroller/city',{ state_id: Helper_Class.getInfo().hospitals[0].state }).toPromise().then(
      (data) => Master_Helper.setMasterCities(JSON.parse(JSON.stringify(data))));
  }

  async getLocations() {
    this.PostData('usercontroller/loc',{ city_id: Helper_Class.getInfo().hospitals[0].city }).toPromise().then(
      (data) => Master_Helper.setMasterLocation(JSON.parse(JSON.stringify(data))));
  }

  async getCovidSymptoms() {
    this.getData('gen/covsym').toPromise().then(
      (data) => Master_Helper.setMasterCovidSymptoms(JSON.parse(JSON.stringify(data))));
  }

  async getComorbidities() {
    this.getData('gen/gcomorb').toPromise().then(
      (data) => Master_Helper.setMasterComorbidities(JSON.parse(JSON.stringify(data))));
  }

  async getWardData(){
    this.PostData('inpat/gward',{ hptl_clinic_id:Helper_Class.getInfo().hospitals[0].hptl_clinic_id }).toPromise().then(
      (data) => Master_Helper.setMasterWardData(JSON.parse(JSON.stringify(data))));
  }

  async doctorProfileInfo(){
    await this.PostData('usercontroller/providerdetails',{doctor:Helper_Class.getInfo().user_id}).toPromise().then(
      (data) => Master_Helper.setMasterDoctorProfileInfo(JSON.parse(JSON.stringify(data))));
  }

  async get_hosp_list() {
    await this.PostData('usercontroller/hospbydoc/',{doc_reg_id:Helper_Class.getInfo().user_id,imei: Helper_Class.getIPAddress()}).toPromise().then(
      (data) => Master_Helper.setMasterHospitalList(JSON.parse(JSON.stringify(data))));
  }

  async getPaymentType() {
    await this.getData('bill/paytype').toPromise().then(
      (data) => Master_Helper.setMasterPaymentType(JSON.parse(JSON.stringify(data))));
  }

  async getGstData() {
    await this.getData('gen/tax/').toPromise().then(
      (data) => Master_Helper.setMasterGSTData(JSON.parse(JSON.stringify(data))));
  }

  async getInsurers() {
    await this.getData('bill/gins').toPromise().then(
      (data) => Master_Helper.setMasterInsurers(JSON.parse(JSON.stringify(data))));
  }

  async getCityDetails() {
    await this.PostData('dash/city/',{reg_id: Helper_Class.getInfo().user_id,app_type: "doctor"}).toPromise().then(
      (data) => Master_Helper.setMasterCityDetails(JSON.parse(JSON.stringify(data))));
  }

  async getTreatement() {
    await this.getData('usercontroller/getmedicare').toPromise().then(
      (data) => Master_Helper.setMasterTreatement(JSON.parse(JSON.stringify(data))));
  }

  async getSubspecilities() {
    await this.getData('dental/denspl').toPromise().then(
      (data) => Master_Helper.setMasterSubspecilities(JSON.parse(JSON.stringify(data))));
  }

  async getIdProofType() {
    await this.getData('adm/gidps').toPromise().then(
      (data) => Master_Helper.setMasterIdProofType(JSON.parse(JSON.stringify(data))));
  }

  async getpurposeofvisits() {
    await this.PostData('mas/gvpp',{hosp_clinic_id: Helper_Class.getInfo().hospitals[0].hptl_clinic_id,prov_type:'doctor'}).toPromise().then(
      (data) => Master_Helper.setmasterpurposeofvisit(JSON.parse(JSON.stringify(data))));
  }


}