<div *ngIf="observation == 'min'">
    <mat-label class="tervys_heading"><strong>Observations</strong> 
        <textarea type="text" class="ipcss scpersonal_width"  [hidden]="general_editor" maxlength="750" [(ngModel)]="gen_obser" matInput></textarea>
        <p [hidden]="general_text" class="matlabel">{{general_observation}}</p>
    </mat-label>
</div>

<div *ngIf="observation == 'max'"> 
    <div  class="personaldetails" style="min-height: 400px;">
        <a [hidden]="save_button" (click)="save_opn()"><span class="cardsave"><img src="../../../assets/img/save.svg" style="width:20px" style="width:20px" /></span> </a>


      
        <div class="row">
        <div class="col-12 col-md-12 col-lg-12 col-sm-12 col-xl-12">
            <mat-label class="matlabel"><strong>General Observations</strong>
                <textarea type="text" class="ipcss scpersonal_width"  [hidden]="general_editor" maxlength="750" [(ngModel)]="gen_obser" matInput></textarea>
                <p [hidden]="general_text" class="matlabel">{{general_observation}}</p>
            </mat-label>
        </div>
        </div>
        <div  class="row">
            <div class="col-md-12 col-lg-12 col-sm-12 col-xl-12">
                <mat-label class="matlabel"><strong>Medical Observations</strong>
                    <textarea type="text" class="ipcss scpersonal_width"  [hidden]="Medical_editor" maxlength="750" [(ngModel)]="med_obser" matInput></textarea>
                    <p [hidden]="Medical_text" class="matlabel">{{med_observation}}</p>
                </mat-label>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 col-lg-12 col-sm-12 col-xl-12">
                <mat-label class="matlabel"><strong>Diagnosis Observations</strong>
                    <textarea type="text" class="ipcss scpersonal_width"  [hidden]="Diag_editor" maxlength="750" [(ngModel)]="diag_obser" matInput></textarea>
                    <p [hidden]="Diag_text" class="matlabel">{{diag_observation}}</p>
                </mat-label>
            </div>
        </div>
        <div class="col-12 col-md-9 col-lg-5 col-xl-3" style="float: right; width: max-content;">
            <img src="../../../assets/ui_icons/buttons/save_button.svg" [hidden]="save_button" (click)="save_opn()" class="saveimgbtn_inpatinfo" />
            <a [hidden]="can_btn"  (click)="canel_opn()"><img src="../../../assets/ui_icons/buttons/cancel_button.svg" class="saveimgbtn_inpatinfo"/></a>   
        </div>
        <br>
    </div>
</div>

