<div *ngIf="nephDiagFlag == 'min'">
    <div class="block1_grid">
        <mat-label class="matlabel" [hidden]="diabetic_type">Disease<br>
            <select class="ipcss " [(ngModel)]="diag_disease" required disableOptionCentering>
                <option *ngFor="let disease of disease_array" (click)="disease_change(disease.nephro_dis_id)"
                    value="{{disease.nephro_dis_id}}">{{disease.description}}</option>
            </select>
        </mat-label>

        <mat-label class="matlabel" [hidden]="stage_hidden">Treatment<br>
            <select class="ipcss " [(ngModel)]="diag_treat" required disableOptionCentering>
                <option *ngFor="let treat of treat_array" (click)="treatment_change(treat.nephro_treattype_id)"
                    value="{{treat.nephro_treattype_id}}">{{treat.description}}</option>
            </select>
        </mat-label>

    </div>
</div>

<!-- nephrology fields -->
<div *ngIf="nephDiagFlag == 'max'">
    <div class="row">
        <div class="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 d-flex justify-content-center" style="display:grid;">
            <p><mat-checkbox color="primary" (change)="drawImage($event);send_data();" [(ngModel)]="isDraw"> Draw image</mat-checkbox></p>

            <div id="svgeditor" class="svgeditor" *ngIf="isDraw">
                <div id="svg_editor">
                    <div id="rulers">
                        <div id="ruler_corner" style="display: none;"></div>
                        <div id="ruler_x" style="display: none;">
                            <div id="ruler_x_cursor"></div>
                            <div>
                                <canvas height="15"></canvas>
                            </div>
                        </div>
                        <div id="ruler_y" style="display: none;">
                            <div id="ruler_y_cursor"></div>
                            <div>
                                <canvas width="15"></canvas>
                            </div>
                        </div>
                    </div>

                    <div id="workarea" style="width: fit-content !important;top:30px">
                        <div id="svgcanvas" style="position:relative">
                            <img src="{{image}}" id="neph" hidden />
                        </div>
                    </div>

                    <div id="menu_bar">
                        <div class="menu">
                            <div id="file_menu">
                                <div class="menu_title">
                                </div>
                                <!-- <div class="menu_title">
                                    <div id="" class="menu_item" title="Export as SVG">
                                        <img (click)="savesend_data()"
                                            src="../../../assets/img/save.svg"
                                            style="width:20px"
                                            style="width:20px" />
                                    </div>
                                </div> -->
                                <div class="menu_title">
                                    <div id="tool_export" class="menu_item" title="Export as PNG" style="position: relative;top: -6px;">
                                        <img data-bs-toggle="modal" data-bs-target="#nephModal" (click)="save_graph()"
                                            src="../../../assets/img/save.svg" style="width:20px" style="width:20px" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="menu">
                            <!-- <div id="edit_menu">
                                <div class="menu_title">
                                    <div class="menu_item" id="tool_undo"
                                        title="Undo"><img
                                            src="../../../assets/dist/images/undo.png" />
                                    </div>
                                </div>
                                <div class="menu_title">
                                    <div class="menu_item" id="tool_redo"
                                        title="Redo"><img
                                            src="../../../assets/dist/images/redo.png" />
                                    </div>
                                </div>
                                <div class="menu_title">
                                    <div class="menu_item action_selected disabled"
                                        id="tool_cut" title="Cut"><img
                                            src="../../../assets/dist/images/scissors.png" />
                                    </div>
                                </div>
                                <div class="menu_title">
                                    <div class="menu_item action_selected disabled"
                                        id="tool_copy" title="Copy"><img
                                            src="../../../assets/dist/images/copy.png" />
                                    </div>
                                </div>
                                <div class="menu_title">
                                    <div class="menu_item action_selected disabled"
                                        id="tool_paste" title="Paste"><img
                                            src="../../../assets/dist/images/paste.png" />
                                    </div>
                                </div>
                                <div class="menu_title">
                                    <div class="menu_item action_selected disabled"
                                        id="tool_clone" title="Clone"><img
                                            src="../../../assets/dist/images/rubber-stamp.png" />
                                    </div>
                                </div>
                                <div class="menu_title">
                                    <div class="menu_item action_selected disabled"
                                        id="tool_delete" title="Delete"><img
                                            src="../../../assets/dist/images/delete.png" />
                                    </div>
                                </div>
                            </div> -->
                        </div>
                        <div class="menu">
                            <div id="view_menu">
                                <!-- <div class="menu_title">
                                    <div class="menu_item push_button_pressed" id="tool_rulers" title="View Rulers"><img
                                            src="../../../assets/dist/images/ruler.png" />
                                    </div>
                                </div>
                                <div class="menu_title">
                                    <div class="menu_item" id="tool_wireframe" title="View Wireframe"><img
                                            src="../../../assets/dist/images/wireframe.png" />
                                    </div>
                                </div> -->
                                <!-- <div class="menu_title">
                            <div class="menu_item" id="tool_snap">Snap to Grid</div>
                          </div> -->
                            </div>
                        </div>
                        <div class="menu">
                            <div class="menu_title">
                                <!-- Zoom buttons -->
                                <!-- <div id="zoom_panel" class="toolset"
                                    title="Change zoom level">
                                    <div class="draginput select"
                                        id="zoom_label">
                                        <span id="zoomLabel"
                                            class="zoom_tool icon_label">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                height="24"
                                                viewBox="2 2 20 20"
                                                width="27">
                                                <path
                                                    d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
                                            </svg>
                                        </span>
                                        <select id="zoom_select">
                                            <option value="6">6%</option>
                                            <option value="12">12%</option>
                                            <option value="16">16%</option>
                                            <option value="25">25%</option>
                                            <option value="50">50%</option>
                                            <option value="75">75%</option>
                                            <option value="100"
                                                selected="selected">
                                                100%</option>
                                            <option value="150">150%
                                            </option>
                                            <option value="200">200%
                                            </option>
                                            <option value="300">300%
                                            </option>
                                            <option value="400">400%
                                            </option>
                                            <option value="600">600%
                                            </option>
                                            <option value="800">800%
                                            </option>
                                            <option value="1600">1600%
                                            </option>
                                        </select>
                                        <div class="caret"></div>
                                        <input id="zoom" size="3"
                                            value="100%" type="text"
                                            readonly="readonly" />
                                    </div>
                                </div> -->
                            </div>

                        </div>

                        <div>
                            <!-- <div class="toggle_full_screen menu_title">
                                <i class="fa fa-arrows-alt"></i>
                            </div> -->
                        </div>
                    </div>

                    <div id="tools_top" class="tools_panel" style="width: 5px !important;">

                        <div id="canvas_panel" class="context_panel">

                            <h4 class="clearfix">Canvas</h4>

                            <label data-title="Change canvas width">
                                <input size="3" id="canvas_width" type="text" pattern="[0-9]*" (blur)="send_data()"/>
                                <span class="icon_label">Width</span>
                            </label>
                            <label data-title="Change canvas height">
                                <input id="canvas_height" size="3" type="text" pattern="[0-9]*" (blur)="send_data()"/>
                                <span class="icon_label">Height</span>
                            </label>


                            <label data-title="Change canvas color" class="draginput">
                                <span>Color</span>
                                <div id="color_canvas_tools">
                                    <div class="color_tool active" id="tool_canvas">
                                        <div class="color_block">
                                            <div id="canvas_bg"></div>
                                            <div id="canvas_color"></div>
                                        </div>
                                    </div>
                                </div>
                            </label>

                            <div class="draginput">
                                <span>Sizes</span>
                                <select id="resolution" (change)="send_data()">
                                    <option id="selectedPredefined" selected="selected">Custom</option>
                                    <option>640x480</option>
                                    <option>800x600</option>
                                    <option>1024x768</option>
                                    <option>1280x960</option>
                                    <option>1600x1200</option>
                                    <option id="fitToContent" value="content">Fit to
                                        Content</option>
                                </select>
                                <div class="caret"></div>
                                <label id="resolution_label">Custom</label>
                            </div>

                        </div>

                        <div id="rect_panel" class="context_panel">
                            <h4 class="clearfix">Rectangle</h4>
                            <label>
                                <input id="rect_x" class="attr_changer" data-title="Change X coordinate" size="3" (blur)="send_data()"
                                    data-attr="x" pattern="[0-9]*" />
                                <span>X</span>
                            </label>
                            <label>
                                <input id="rect_y" class="attr_changer" data-title="Change Y coordinate" size="3" (blur)="send_data()"
                                    data-attr="y" pattern="[0-9]*" />
                                <span>Y</span>
                            </label>
                            <label id="rect_width_tool attr_changer" data-title="Change rectangle width">
                                <input id="rect_width" class="attr_changer" size="3" data-attr="width" type="text" (blur)="send_data()"
                                    pattern="[0-9]*" />
                                <span class="icon_label">Width</span>
                            </label>
                            <label id="rect_height_tool" data-title="Change rectangle height">
                                <input id="rect_height" class="attr_changer" size="3" data-attr="height" type="text" (blur)="send_data()"
                                    pattern="[0-9]*" />
                                <span class="icon_label">Height</span>
                            </label>
                        </div>

                        <div id="path_panel" class="context_panel clearfix">
                            <h4 class="clearfix">Path</h4>
                            <label>
                                <input id="path_x" class="attr_changer" data-title="Change ellipse's cx coordinate" (blur)="send_data()"
                                    size="3" data-attr="x" pattern="[0-9]*" />
                                <span>X</span>
                            </label>
                            <label>
                                <input id="path_y" class="attr_changer" data-title="Change ellipse's cy coordinate" (blur)="send_data()"
                                    size="3" data-attr="y" pattern="[0-9]*" />
                                <span>Y</span>
                            </label>
                        </div>

                        <!-- <div id="image_panel"
                            class="context_panel clearfix">
                            <h4>Image</h4>
                            <label>
                                <input id="image_x" class="attr_changer"
                                    data-title="Change X coordinate"
                                    size="3" data-attr="x"
                                    pattern="[0-9]*" />
                                <span>X</span>
                            </label>
                            <label>
                                <input id="image_y" class="attr_changer"
                                    data-title="Change Y coordinate"
                                    size="3" data-attr="y"
                                    pattern="[0-9]*" />
                                <span>Y</span>
                            </label>
                            <label>
                                <input id="image_width" class="attr_changer"
                                    data-title="Change image width" size="3"
                                    data-attr="width" pattern="[0-9]*" />
                                <span class="icon_label">Width</span>
                            </label>
                            <label>
                                <input id="image_height"
                                    class="attr_changer"
                                    data-title="Change image height"
                                    size="3" data-attr="height"
                                    pattern="[0-9]*" />
                                <span class="icon_label">Height</span>
                            </label>
                        </div> -->

                        <div id="circle_panel" class="context_panel">
                            <h4>Circle</h4>
                            <label id="tool_circle_cx">
                                <span>Center X</span>
                                <input id="circle_cx" class="attr_changer" title="Change circle's cx coordinate" (blur)="send_data()"
                                    size="3" data-attr="cx" />
                            </label>
                            <label id="tool_circle_cy">
                                <span>Center Y</span>  
                                <input id="circle_cy" class="attr_changer" title="Change circle's cy coordinate" (blur)="send_data()"
                                    size="3" data-attr="cy" />
                            </label>
                            <label id="tool_circle_r">
                                <span>Radius</span>
                                <input id="circle_r" class="attr_changer" title="Change circle's radius" size="3" (blur)="send_data()"
                                    data-attr="r" />
                            </label>
                        </div>

                        <div id="ellipse_panel" class="context_panel clearfix">
                            <h4>Ellipse</h4>
                            <label id="tool_ellipse_cx">
                                <input id="ellipse_cx" class="attr_changer" data-title="Change ellipse's cx coordinate" (blur)="send_data()"
                                    size="3" data-attr="cx" pattern="[0-9]*" />
                                <span>X</span>
                            </label>
                            <label id="tool_ellipse_cy">
                                <input id="ellipse_cy" class="attr_changer" data-title="Change ellipse's cy coordinate" (blur)="send_data()"
                                    size="3" data-attr="cy" pattern="[0-9]*" />
                                <span>Y</span>
                            </label>
                            <label id="tool_ellipse_rx">
                                <input id="ellipse_rx" class="attr_changer" data-title="Change ellipse's x radius" (blur)="send_data()"
                                    size="3" data-attr="rx" pattern="[0-9]*" />
                                <span>Radius X</span>
                            </label>
                            <label id="tool_ellipse_ry"> 
                                <input id="ellipse_ry" class="attr_changer" data-title="Change ellipse's y radius" (blur)="send_data()"
                                    size="3" data-attr="ry" pattern="[0-9]*" />
                                <span>Radius Y</span>
                            </label>
                        </div>

                        <div id="line_panel" class="context_panel clearfix">
                            <h4>Line</h4>
                            <label id="tool_line_x1">
                                <input id="line_x1" class="attr_changer"
                                    data-title="Change line's starting x coordinate" size="3" data-attr="x1" (blur)="send_data()"
                                    pattern="[0-9]*" />
                                <span>Start X</span>
                            </label>
                            <label id="tool_line_y1">
                                <input id="line_y1" class="attr_changer"
                                    data-title="Change line's starting y coordinate" size="3" data-attr="y1" (blur)="send_data()"
                                    pattern="[0-9]*" />
                                <span>Start Y</span>
                            </label>
                            <label id="tool_line_x2">
                                <input id="line_x2" class="attr_changer" data-title="Change line's ending x coordinate" (blur)="send_data()"
                                    size="3" data-attr="x2" pattern="[0-9]*" />
                                <span>End X</span>
                            </label>
                            <label id="tool_line_y2">
                                <input id="line_y2" class="attr_changer" data-title="Change line's ending y coordinate" (blur)="send_data()"
                                    size="3" data-attr="y2" pattern="[0-9]*" />
                                <span>End Y</span>
                            </label>
                        </div>

                        <div id="text_panel" class="context_panel">
                            <h4>Text</h4>
                            <label>
                                <input id="text_x" class="attr_changer" data-title="Change text x coordinate" size="3" (blur)="send_data()"
                                    data-attr="x" pattern="[0-9]*" />
                                <span>X</span>
                            </label>
                            <label>
                                <input id="text_y" class="attr_changer" data-title="Change text y coordinate" size="3" (blur)="send_data()"
                                    data-attr="y" pattern="[0-9]*" />
                                <span>Y</span>
                            </label>

                            <div class="toolset draginput select twocol" id="tool_font_family">
                                <!-- Font family -->
                                <span>Font</span>
                                <div id="preview_font" style="font-family: Helvetica, Arial, sans-serif;">
                                    Helvetica</div>
                                <div class="caret"></div>
                                <input id="font_family" data-title="Change Font Family" size="12" type="hidden" (blur)="send_data()"/>
                                <select id="font_family_dropdown" (change)="send_data()">
                                    <option value="Arvo, sans-serif">Arvo
                                    </option>
                                    <option value="'Courier New', Courier, monospace">
                                        Courier</option>
                                    <option value="Euphoria, sans-serif">
                                        Euphoria
                                    </option>
                                    <option value="Georgia, Times, 'Times New Roman', serif">
                                        Georgia</option>
                                    <option value="Helvetica, Arial, sans-serif" selected="selected">Helvetica
                                    </option>
                                    <option value="Junction, sans-serif">
                                        Junction
                                    </option>
                                    <option value="'League Gothic', sans-serif">
                                        League Gothic</option>
                                    <option value="Oswald, sans-serif">
                                        Oswald
                                    </option>
                                    <option value="'Palatino Linotype', 'Book Antiqua', Palatino, serif">
                                        Palatino</option>
                                    <option value="'Trebuchet MS', Gadget, sans-serif">
                                        Trebuchet</option>
                                    <option value="'Shadows Into Light', serif">
                                        Shadows Into Light</option>
                                    <option value="'Simonetta', serif">
                                        Simonetta
                                    </option>
                                    <option value="'Times New Roman', Times, serif">
                                        Times</option>
                                </select>
                                <div class="tool_button" id="tool_bold" data-title="Bold Text [B]">B</div>
                                <div class="tool_button" id="tool_italic" data-title="Italic Text [I]">i</div>
                            </div>

                            <label id="tool_font_size" data-title="Change Font Size">
                                <input id="font_size" size="3" value="0" (blur)="send_data()"/>
                                <span id="font_sizeLabel" class="icon_label">Font
                                    Size</span>
                            </label>
                            <!-- Not visible, but still used -->
                            <input id="text" type="text" size="35" (blur)="send_data()"/>
                        </div>

                        <!-- formerly gsvg_panel -->
                        <div id="container_panel" class="context_panel clearfix">
                        </div>

                        <div id="use_panel" class="context_panel clearfix">
                            <div class="tool_button clearfix" id="tool_unlink_use"
                                data-title="Break link to reference element (make unique)">
                                Break link reference</div>
                        </div>

                        <div id="g_panel" class="context_panel clearfix">
                            <h4>Group</h4>
                            <label>
                                <input id="g_x" class="attr_changer" data-title="Change groups's x coordinate" size="3"
                                    data-attr="x" pattern="[0-9]*"  (blur)="send_data()"/>
                                <span>X</span>
                            </label>
                            <label>
                                <input id="g_y" class="attr_changer" data-title="Change groups's y coordinate" size="3"
                                    data-attr="y" pattern="[0-9]*" (blur)="send_data()" />
                                <span>Y</span>
                            </label>
                        </div>

                        <div id="path_node_panel" class="context_panel clearfix">
                            <h4>Edit Path</h4>

                            <label id="tool_node_x">
                                <input id="path_node_x" class="attr_changer" data-title="Change node's x coordinate"
                                    size="3" data-attr="x" (blur)="send_data()"/>
                                <span>X</span>
                            </label>
                            <label id="tool_node_y">
                                <input id="path_node_y" class="attr_changer" data-title="Change node's y coordinate"
                                    size="3" data-attr="y" (blur)="send_data()"/>
                                <span>Y</span>
                            </label>

                            <div id="segment_type" class="draginput label">
                                <span>Segment Type</span>
                                <select id="seg_type" data-title="Change Segment type" (change)="send_data()">
                                    <option id="straight_segments" selected="selected" value="4">
                                        Straight
                                    </option>
                                    <option id="curve_segments" value="6">
                                        Curve
                                    </option>
                                </select>
                                <div class="caret"></div>
                                <label id="seg_type_label">Straight</label>
                            </div>

                            <div class="clearfix"></div>
                            <div class="tool_button" id="tool_node_clone" title="Adds a node">Add Node</div>
                            <div class="tool_button" id="tool_node_delete" title="Delete Node">Delete Node</div>
                            <div class="tool_button" id="tool_openclose_path" title="Open/close sub-path">Open Path
                            </div>
                        </div>

                        <!-- Buttons when a single element is selected -->
                        <!-- <div id="selected_panel" class="context_panel">

                            <label id="tool_angle"
                                data-title="Change rotation angle"
                                class="draginput">
                                <input id="angle" class="attr_changer"
                                    size="2" value="0" data-attr="transform"
                                    data-min="-180" data-max="180"
                                    type="text" />
                                <span class="icon_label">Rotation</span>
                                <div id="tool_angle_indicator">
                                    <div id="tool_angle_indicator_cursor">
                                    </div>
                                </div>
                            </label>

                            <label class="toolset" id="tool_opacity"
                                data-title="Change selected item opacity">
                                <input id="group_opacity"
                                    class="attr_changer" data-attr="opacity"
                                    data-multiplier="0.01" size="3"
                                    value="100" step="5" min="0"
                                    max="100" />
                                <span id="group_opacityLabel"
                                    class="icon_label">Opacity</span>
                            </label>

                            <div class="toolset" id="tool_blur"
                                data-title="Change gaussian blur value">
                                <label>
                                    <input id="blur" size="2" value="0"
                                        step=".1" min="0" max="10" />
                                    <span class="icon_label">Blur</span>
                                </label>
                            </div>

                            <label id="cornerRadiusLabel"
                                data-title="Change Rectangle Corner Radius">
                                <input id="rect_rx" size="3" value="0"
                                    data-attr="rx" class="attr_changer"
                                    type="text" pattern="[0-9]*" />
                                <span class="icon_label">Roundness</span>
                            </label>

                            <div class="clearfix"></div>
                            <div id="align_tools">
                                <h4>Align</h4>
                                <div class="toolset align_buttons"
                                    id="tool_position">
                                    <label>
                                        <div class="col last clear"
                                            id="position_opts">
                                            <div class="draginput_cell"
                                                id="tool_posleft"
                                                title="Align Left">
                                                <svg viewBox="0 0 27 27"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="27" height="27">
                                                    <path
                                                        d="M 2 1 L 2 5 L 14 5 L 14 11 L 2 11 L 2 16 L 20 16 L 20 22 L 2 22 L 2 26 L 1 26 L 1 1 L 2 1 Z" />
                                                </svg>
                                            </div>
                                            <div class="draginput_cell"
                                                id="tool_poscenter"
                                                title="Align Center">
                                                <svg viewBox="0 0 27 27"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="27" height="27">
                                                    <path
                                                        d="M 13 1 L 14 1 L 14 6 L 22 6 L 22 12 L 14 12 L 14 15 L 19 15 L 19 21 L 14 21 L 14 26 L 13 26 L 13 21 L 8 21 L 8 15 L 13 15 L 13 12 L 5 12 L 5 6 L 13 6 L 13 1 Z" />
                                                </svg>
                                            </div>
                                            <div class="draginput_cell"
                                                id="tool_posright"
                                                title="Align Right">
                                                <svg viewBox="0 0 27 27"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="27" height="27">
                                                    <path
                                                        d="M 25 1 L 25 5 L 13 5 L 13 11 L 25 11 L 25 16 L 7 16 L 7 22 L 25 22 L 25 26 L 26 26 L 26 1 L 25 1 Z" />
                                                </svg>
                                            </div>
                                            <div class="draginput_cell"
                                                id="tool_postop"
                                                title="Align Top">
                                                <svg viewBox="0 0 27 27"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="27" height="27">
                                                    <path
                                                        d="M 1 2 L 5 2 L 5 14 L 11 14 L 11 2 L 16 2 L 16 20 L 22 20 L 22 2 L 26 2 L 26 1 L 1 1 L 1 2 Z" />
                                                </svg>
                                            </div>
                                            <div class="draginput_cell"
                                                id="tool_posmiddle"
                                                title="Align Middle">
                                                <svg viewBox="0 0 27 27"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="27" height="27">
                                                    <path
                                                        d="M 26 13 L 26 14 L 21 14 L 21 22 L 15 22 L 15 14 L 12 14 L 12 19 L 6 19 L 6 14 L 1 14 L 1 13 L 6 13 L 6 8 L 12 8 L 12 13 L 15 13 L 15 5 L 21 5 L 21 13 L 26 13 Z" />
                                                </svg>
                                            </div>
                                            <div class="draginput_cell"
                                                id="tool_posbottom"
                                                title="Align Bottom">
                                                <svg viewBox="0 0 27 27"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="27" height="27">
                                                    <path
                                                        d="M 1 25 L 5 25 L 5 13 L 11 13 L 11 25 L 16 25 L 16 7 L 22 7 L 22 25 L 26 25 L 26 26 L 1 26 L 1 25" />
                                                </svg>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div> -->

                        <!-- Buttons when multiple elements are selected -->
                        <div id="multiselected_panel" class="context_panel clearfix">
                            <h4 class="hidable">Multiple Elements</h4>

                            <div class="toolset align_buttons" style="position: relative">
                                <label id="tool_align_relative" style="margin-top: 10px;">
                                    <div class="select-input">
                                        <select id="align_relative_to" title="Align relative to ..."
                                            class="select-input" (change)="send_data()">
                                            <option id="selected_objects" value="selected">Align to
                                                objects
                                            </option>
                                            <option id="page" value="page">
                                                Align to
                                                page</option>
                                        </select>
                                    </div>
                                </label>
                                <!-- <h4>.</h4> -->
                                <div class="col last clear" id="align_opts">
                                    <div class="draginput_cell p-0" id="tool_alignleft" title="Align Left">
                                        <svg viewBox="0 0 27 27" xmlns="http://www.w3.org/2000/svg" width="27"
                                            height="27">
                                            <path
                                                d="M 2 1 L 2 5 L 14 5 L 14 11 L 2 11 L 2 16 L 20 16 L 20 22 L 2 22 L 2 26 L 1 26 L 1 1 L 2 1 Z" />
                                        </svg>
                                    </div>
                                    <div class="draginput_cell" id="tool_aligncenter" title="Align Center">
                                        <svg viewBox="0 0 27 27" xmlns="http://www.w3.org/2000/svg" width="27"
                                            height="27">
                                            <path
                                                d="M 13 1 L 14 1 L 14 6 L 22 6 L 22 12 L 14 12 L 14 15 L 19 15 L 19 21 L 14 21 L 14 26 L 13 26 L 13 21 L 8 21 L 8 15 L 13 15 L 13 12 L 5 12 L 5 6 L 13 6 L 13 1 Z" />
                                        </svg>
                                    </div>
                                    <div class="draginput_cell" id="tool_alignright" title="Align Right">
                                        <svg viewBox="0 0 27 27" xmlns="http://www.w3.org/2000/svg" width="27"
                                            height="27">
                                            <path
                                                d="M 25 1 L 25 5 L 13 5 L 13 11 L 25 11 L 25 16 L 7 16 L 7 22 L 25 22 L 25 26 L 26 26 L 26 1 L 25 1 Z" />
                                        </svg>
                                    </div>
                                    <div class="draginput_cell" id="tool_aligntop" title="Align Top">
                                        <svg viewBox="0 0 27 27" xmlns="http://www.w3.org/2000/svg" width="27"
                                            height="27">
                                            <path
                                                d="M 1 2 L 5 2 L 5 14 L 11 14 L 11 2 L 16 2 L 16 20 L 22 20 L 22 2 L 26 2 L 26 1 L 1 1 L 1 2 Z" />
                                        </svg>
                                    </div>
                                    <div class="draginput_cell" id="tool_alignmiddle" title="Align Middle">
                                        <svg viewBox="0 0 27 27" xmlns="http://www.w3.org/2000/svg" width="27"
                                            height="27">
                                            <path
                                                d="M 26 13 L 26 14 L 21 14 L 21 22 L 15 22 L 15 14 L 12 14 L 12 19 L 6 19 L 6 14 L 1 14 L 1 13 L 6 13 L 6 8 L 12 8 L 12 13 L 15 13 L 15 5 L 21 5 L 21 13 L 26 13 Z" />
                                        </svg>
                                    </div>
                                    <div class="draginput_cell" id="tool_alignbottom" title="Align Bottom">
                                        <svg viewBox="0 0 27 27" xmlns="http://www.w3.org/2000/svg" width="27"
                                            height="27">
                                            <path
                                                d="M 1 25 L 5 25 L 5 13 L 11 13 L 11 25 L 16 25 L 16 7 L 22 7 L 22 25 L 26 25 L 26 26 L 1 26 L 1 25" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div class="clearfix"></div>

                        </div>

                        <!-- <div id="stroke_panel"
                            class="context_panel clearfix">
                            <div class="clearfix"></div>
                            <h4>Stroke</h4>
                            <div class="toolset" data-title="Change stroke">
                                <label>
                                    <input id="stroke_width" size="2"
                                        value="5" data-attr="stroke-width"
                                        min="0" max="99" step="1" />
                                    <span class="icon_label">Width</span>
                                </label>
                            </div>
                            <div class="stroke_tool draginput">
                                <span>Dash</span>
                                <select id="stroke_style"
                                    data-title="Change stroke dash style">
                                    <option selected="selected"
                                        value="none">—
                                    </option>
                                    <option value="2,2">···</option>
                                    <option value="5,5">- -</option>
                                    <option value="5,2,2,2">-·-</option>
                                    <option value="5,2,2,2,2,2">-··-
                                    </option>
                                </select>
                                <div class="caret"></div>
                                <label id="stroke_style_label">—</label>
                            </div>

                            <label style="display: none;">
                                <span class="icon_label">Stroke Join</span>
                            </label>

                            <label style="display: none;">
                                <span class="icon_label">Stroke Cap</span>
                            </label>
                        </div> -->

                    </div> <!-- tools_top -->
                    <div id="cur_context_panel">

                    </div>

                    <div id="tools_left" class="tools_panel">
                        <div class="tool_button" id="tool_select" title="Select Tool [V]">
                            <svg viewBox="0 0 24 24" width="24" height="24">
                                <path d="M17.15 20.76l-2.94 1.5-3.68-6-4.41 3V1.24l12.5 12.01-4.41 1.5 2.94 6z" />
                            </svg>
                        </div>
                        <div class="tool_button" id="tool_fhpath" title="Pencil Tool [P]">
                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"
                                style="transform: scale(-1,1)">
                                <path
                                    d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" />
                            </svg>
                        </div>
                        <!-- <div class="tool_button" id="tool_line"
                            title="Line Tool [L]">
                            <svg viewBox="0 0 27 27"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24" height="24">
                                <path
                                    d="M 3 1 L 26 24 L 24 26 L 1 3 L 3 1 Z">
                                </path>
                            </svg>
                        </div>
                        <div class="tool_button" id="tool_rect"
                            title="Square/Rect Tool [R]">
                            <svg viewBox="0 0 27 27"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24" height="24">
                                <path
                                    d="M 0 8 L 0 24 L 24 24 L 25 8 L 0 8 Z" />
                            </svg>
                        </div>
                        <div class="tool_button" id="tool_ellipse"
                            title="Ellipse/Circle Tool [C]">
                            <svg viewBox="0 0 27 27"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24" height="24">
                                <ellipse cx="13" cy="13" rx="13" ry="9">
                                </ellipse>
                            </svg>
                        </div>
                        <div class="tool_button" id="tool_path"
                            title="Path Tool [P]">
                            <svg xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 27 27" width="27" height="27">
                                <path
                                    d="M12.2 1.9c0-.36.86 0 .86 0V14a1.3 1.3 0 10.88 0V1.9s.87-.36.87 0c0 6.81 5.22 11.68 5.22 11.68l-3.25 8.2h-6.55l-3.26-8.2s5.22-4.87 5.22-11.68zM7.83 25.26v-2.61h11.32v2.6H7.84z" />
                            </svg>
                        </div>
                        <div class="tool_button" id="tool_text"
                            title="Text Tool [T]">
                            <svg xmlns="http://www.w3.org/2000/svg"
                                height="24" viewBox="2 2 20 20" width="27"
                                height="27">
                                <path d="M5 4v3h5.5v12h3V7H19V4z" />
                            </svg>
                        </div>

                        <div class="tool_button" id="tool_zoom"
                            title="Zoom Tool [Z]">
                            <svg xmlns="http://www.w3.org/2000/svg"
                                height="24" viewBox="2 2 20 20" width="27">
                                <path
                                    d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
                            </svg>
                        </div> -->

                        <div class="tool_button" id="tool_eyedropper" title="Eyedropper Tool [Z]">
                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="2 2 20 20" width="27"
                                style="transform: scale(-1, 1)">
                                <path
                                    d="M20.71 5.63l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-3.12 3.12-1.93-1.91-1.41 1.41 1.42 1.42L3 16.25V21h4.75l8.92-8.92 1.42 1.42 1.41-1.41-1.92-1.92 3.12-3.12c.4-.4.4-1.03.01-1.42zM6.92 19L5 17.08l8.06-8.06 1.92 1.92L6.92 19z" />
                            </svg>
                        </div>

                        <div id="color_tools">
                            <div id="tool_switch" title="Switch stroke and fill colors [X]">
                            </div>
                            <div class="color_tool active" id="tool_fill">
                                <label class="icon_label" title="Change fill color"></label>
                                <div class="color_block">
                                    <div id="fill_bg"></div>
                                    <div id="fill_color" class="color_block"></div>
                                </div>
                            </div>

                            <div class="color_tool" id="tool_stroke">
                                <label class="icon_label" title="Change stroke color"></label>
                                <div class="color_block">
                                    <div id="stroke_bg"></div>
                                    <div id="stroke_color" class="color_block" title="Change stroke color"></div>
                                </div>
                            </div>
                        </div>
                    </div> <!-- tools_left -->

                    <div id="tools_bottom" class="tools_panel">
                        <div id="palette" title="Click to change fill color, shift-click to change stroke color">
                        </div>
                    </div>

                    <!-- hidden divs -->
                    <div id="color_picker"></div>

                </div> <!-- svg_editor -->

                <div id="svg_source_editor">
                    <div id="svg_source_overlay"></div>
                    <div id="svg_source_container">
                        <div id="save_output_btns">
                            <p id="copy_save_note">Copy the contents of this
                                box
                                into a text editor, then save the file
                                with a .svg extension.</p>
                            <button id="copy_save_done">Done</button>
                        </div>
                        <form>
                            <textarea id="svg_source_textarea" spellcheck="false"></textarea>
                        </form>
                        <div id="tool_source_back" class="toolbar_button">
                            <button id="tool_source_cancel" class="cancel">Cancel</button>
                            <button id="tool_source_save" class="ok">Apply
                                Changes</button>
                        </div>
                    </div>
                </div>

                <div id="dialog_box">
                    <div id="dialog_box_overlay"></div>
                    <div id="dialog_container">
                        <div id="dialog_content"></div>
                        <div id="dialog_buttons"></div>
                    </div>
                </div>

                <div class="tools_flyout" id="tools_shapelib">
                    <div id="shape_buttons"></div>
                </div>
            </div>

            <ul id="cmenu_canvas" class="contextMenu"  *ngIf="isDraw">
                <li><a data-href="#cut">Cut <span class="shortcut">⌘X;</span></a>
                </li>
                <li><a data-href="#copy">Copy<span class="shortcut">⌘C</span></a>
                </li>
                <li><a data-href="#paste">Paste<span class="shortcut">⌘V</span></a>
                </li>
                <li class="separator"><a data-href="#delete">Delete<span class="shortcut">⌫</span></a></li>
                <li class="separator"><a data-href="#group">Group<span class="shortcut">⌘G</span></a></li>
                <li><a data-href="#ungroup">Ungroup<span class="shortcut">⌘⇧G</span></a></li>
                <li class="separator"><a data-href="#move_front">Bring to
                        Front<span class="shortcut">⌘⇧↑</span></a>
                </li>
                <li><a data-href="#move_up">Bring Forward<span class="shortcut">⌘↑</span></a></li>
                <li><a data-href="#move_down">Send Backward<span class="shortcut">⌘↓</span></a></li>
                <li><a data-href="#move_back">Send to Back<span class="shortcut">⌘⇧↓</span></a></li>
            </ul>
            <img id="theimage" hidden />
        </div>
        <div class="col-12 col-sm-12 col-md-6 col-lg-7 col-xl-8">
            <div class="row">
                <div [hidden]="icd_hidden" class="col-11 col-sm-11 col-md-5 col-lg-5 col-xl-5">
                    <mat-label class="matlabel">ICD code</mat-label>
                    <input class="ipcss" [readonly]="isReadonly()" [(ngModel)]="Icd_code" matInput (blur)="send_data()" />
                </div>
                <div [hidden]="icd_hidden" class="col-1 p-0">
                    <img src="../../../assets/img/icd.png" class="icd_img" (click)="Icd_click()" />
                </div>
                <div [hidden]="icddis_hidden" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <mat-label class="matlabel">ICD description</mat-label>
                    <textarea class="ipcss" rows="1" [(ngModel)]="Icd_Descript" matInput (keyup)=lookupICDCodes() (blur)="send_data()"
                        [matAutocomplete]="auto10"></textarea>
                    <mat-autocomplete #auto10="matAutocomplete">
                        <mat-option (click)="select_icdcode(item.desc)" *ngFor="let item of icdList"
                            value="{{item.desc}}">
                            {{item.desc}}
                        </mat-option>
                    </mat-autocomplete>
                    <!-- <input type="text" #myInput id="myInput" rows="3" [(ngModel)]="Icd_Descript" (keyup)=lookupICDCodes()> -->
                    <!-- <div class="auto_complete_text" *ngIf="icdList.length > 0">
                        <ul *ngFor="let item of icdList">
                          <li>
                            <a (click)="select_icdcode(item.desc)">{{item.desc}}</a>
                          </li>
                        </ul>
                      </div> -->
                </div>
            </div>
            <div class="row">
                <div [hidden]="diabetic_type" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <mat-label class="matlabel" [hidden]="diabetic_type">Disease<br>
                        <select class="ipcss " [(ngModel)]="diag_disease" required (change)="send_data()"
                            (ngModelChange)="disease_change($event)" [disabled]="client">
                            <option *ngFor="let disease of disease_array" value="{{disease.nephro_dis_id}}">
                                {{disease.description}}</option>
                        </select>
                    </mat-label>
                </div>
                <div [hidden]="stage_type_hide" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <mat-label class="matlabel" [hidden]="stage_type_hide">Stage<br>
                        <select class="ipcss " [(ngModel)]="stage_type" disableOptionCentering [disabled]="client" (change)="send_data()">
                            <option disabled>Select</option>
                            <option value="I">I</option>
                            <option value="II">II</option>
                            <option value="III">III</option>
                            <option value="IV">IV</option>
                            <option value="V">V</option>
                        </select>
                    </mat-label>
                </div>
                <div [hidden]="stage_hidden" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <mat-label class="matlabel" [hidden]="stage_hidden">Treatment<br>
                        <select class="ipcss " [(ngModel)]="diag_treat" required (change)="send_data()"
                            (ngModelChange)="treatment_change($event)" [disabled]="client">
                            <option *ngFor="let treat of treat_array" value="{{treat.nephro_treattype_id}}">
                                {{treat.description}}</option>
                        </select>
                    </mat-label>
                </div>
                <div [hidden]="dys_type_hide" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <mat-label class="matlabel" [hidden]="dys_type_hide">Dialysis Type<br>
                        <select class="ipcss " [(ngModel)]="dys_type" disableOptionCentering [disabled]="client" (change)="send_data()">
                            <option disabled>Select</option>
                            <option value="Hemodialysis">Hemodialysis</option>
                            <option value="Pediatric dialysis">Pediatric dialysis</option>
                            <option value="Peritoneal dialysis">Peritoneal dialysis</option>
                            <option value="Hemofiltration">Hemofiltration</option>
                            <option value="Hemodiafiltration">Hemodiafiltration</option>
                            <option value="Intestinal dialysis">Intestinal dialysis</option>
                        </select>
                    </mat-label>
                </div>
                <div [hidden]="dys_freq_hide" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <mat-label [hidden]="dys_freq_hide" class="matlabel">Frequency<br>
                        <input class="ipcss " matInput [(ngModel)]="diag_freq" [disabled]="client" (blur)="send_data()">
                    </mat-label>
                </div>
            </div>
            <div class="row" [hidden]="phyex_hidden" style="margin-top: 10px;">
                <div class="col-12 col-sm-6 col-xl-6 col-lg-6 col-md-6">
                    <p class="tervys_heading">Physical Activities</p>
                </div>
            </div>
            <div class="row" [hidden]="phyex_hidden">
                <div class="col-12 col-sm-12 col-xl-4 col-lg-4 col-md-5">
                    <mat-label class="matlabel">Exercise<br>
                        <select class="ipcss" [(ngModel)]="life_exer" (change)="Exercise_change(life_exer)" (change)="send_data()"
                            [disabled]="client">
                            <option disabled value="Select">Select</option>
                            <option *ngFor="let exercise of Physcial_exercise" value={{exercise.exercise_id}}>
                                {{exercise.description}}</option>
                        </select>
                    </mat-label>
                    <div class="scroll_checkbox test_box" id="Div8" style="margin-top: 12px;" *ngIf="!yoga_div">
                        <table style="margin-top: -5px;">
                            <tr *ngFor="let yoga of Get_theyoga">
                                <td class="test">
                                    <mat-checkbox multiple [checked]="yoga_check.indexOf(yoga.yoga_asana_id) > -1"
                                        (change)="yoga_click1(yoga.yoga_asana_id,$event.checked)"></mat-checkbox>
                                </td>
                                <td style="text-align: left !important;font-size: 10px;">
                                    <span>{{yoga.description}}</span>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <!-- <div *ngIf="!yoga_div" class="col-12 col-sm-6 col-xl-3 col-lg-3 col-md-4">
                    <mat-label class="matlabel">Yoga</mat-label>

                </div> -->
                <div [hidden]="phydure_hidden" class="col-12 col-sm-6 col-xl-4 col-lg-4 col-md-3">
                    <mat-label class="matlabel" [hidden]="phydure_hidden">Duration<br>
                        <select class="ipcss " [(ngModel)]="life_exerdure" disableOptionCentering [disabled]="client" (change)="send_data()">
                            <option disabled>Select</option>
                            <option value="10 mins">10 mins</option>
                            <option value="15 mins">15 mins</option>
                            <option value="30 mins">30 mins</option>
                            <option value="45 mins">45 mins</option>
                        </select>
                    </mat-label>
                </div>
                <div [hidden]="phyfreq_hidden" class="col-12 col-sm-6 col-xl-4 col-lg-4 col-md-4">
                    <mat-label class="matlabel" [hidden]="phyfreq_hidden">Frequency<br>
                        <select class="ipcss " [(ngModel)]="life_exe_freq" disableOptionCentering [disabled]="client" (change)="send_data()">
                            <option disabled value="Select">Select</option>
                            <option value="Daily">Daily</option>
                            <option value="Thrice a week">Thrice a week</option>
                            <option value="4 days a week">4 days a week</option>
                            <option value="5 days a week">5 days a week</option>
                        </select>
                    </mat-label>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="row">
        <div class="col-6 my-auto">

        </div>
        <div class="col-6">

        </div>
    </div> -->

    <div class="row" style="margin-top: 15px;">
        <div class="col-6">
            <ul class="nav nav-tabs nav-fill" id="myTab" role="tablist">
                <li class="nav-item" role="presentation"  [hidden]="follow_hidden && avoid_hidden">
                    <button class="nav-link " id="diet-tab" data-bs-toggle="tab" data-bs-target="#diet" type="button"
                        role="tab" aria-controls="diet" aria-selected="true" (click)="changetab('diet')">Diet</button>
                </li>
                <li class="nav-item" role="presentation" >
                    <button class="nav-link " id="therapy-tab" data-bs-toggle="tab" data-bs-target="#therapy"
                        type="button" role="tab" aria-controls="therapy" aria-selected="true"
                        (click)="changetab('therapy')">Physio</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link active" id="Prognosis-tab" data-bs-toggle="tab" data-bs-target="#Prognosis"
                        type="button" role="tab" aria-controls="Prognosis" aria-selected="false"
                        (click)="changetab('prognosis')">Prognosis</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="Notes-tab" data-bs-toggle="tab" data-bs-target="#Notes" type="button"
                        role="tab" aria-controls="Notes" aria-selected="false"
                        (click)="changetab('notes')">Notes</button>
                </li>
            </ul>
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane " id="diet" role="tabpanel" aria-labelledby="home-tab">
                    <div class="row">
                        <div [hidden]="follow_hidden" class="col-10">
                            <mat-card id="cardcontent_follow" style="position: relative;">
                                <mat-card-title id="cardheading_follow">Follow</mat-card-title>
                                <mat-card-content style="padding:5px;margin-bottom: 4px;height: 117px;">
                                    <div class="row" style="height: 100%;">
                                        <div class="col-12 col-sm-6 col-xl-6 col-lg-6 col-md-6 my-auto">
                                            <mat-label class="matlabel">Food type<br>
                                                <select class="ipcss " required [(ngModel)]="food_type_name"
                                                    disableOptionCentering [disabled]="client">
                                                    <option disabled value="Select">Select</option>
                                                    <option *ngFor="let food of get_foodtype_arry"
                                                        value={{food.description}}>
                                                        {{food.description}}</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-xl-6 col-lg-6 col-md-6 my-auto">
                                            <mat-label class="matlabel">Food item
                                                <input type="text" class="ipcss " required id="country"
                                                    [(ngModel)]="Foot_Item_txt" (keyup)="followfilter($event)" matInput
                                                    [matAutocomplete]="auto7" [readonly]="client" />
                                                <mat-autocomplete #auto7="matAutocomplete">
                                                    <mat-option id="optionfont" (click)="select_follow_item(item)"
                                                        *ngFor="let item of filteredList" value="{{item}}">{{item}}
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </mat-label>
                                        </div>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </div>
                        <div [hidden]="follow_hidden" class="col-2 my-auto" style="text-align: center;">
                            <a (click)="DietToFollowAdd()" [disabled]="client"><img
                                    src="../../../assets/ui_icons/arrow_green.svg" class="saveimgbtn_inpatinfo" /></a>
                        </div>
                        <!-- <div [hidden]="avoid_hidden" class="col-10">
                            <mat-card id="cardcontent_avoid" style="position: relative;">
                                <mat-card-title id="cardheading_avoid">Avoid</mat-card-title>
                                <mat-card-content style="padding:5px;height: 117px;">
                                    <div class="row">
                                        <div class="col-12 col-sm-6 col-xl-6 col-lg-6 col-md-6 my-auto">
                                            <mat-label class="matlabel">Food type<br>
                                                <select class="ipcss " required [(ngModel)]="Food_type_avoid"
                                                    disableOptionCentering [disabled]="client">
                                                    <option disabled value="Select">Select</option>
                                                    <option *ngFor="let food of get_foodtype_arry"
                                                        value={{food.description}}>
                                                        {{food.description}}</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-xl-6 col-lg-6 col-md-6 my-auto">
                                            <mat-label class="matlabel">Food item
                                                <input type="text" class="ipcss " required
                                                    [(ngModel)]="Foot_Item_avoidtxt" (keyup)="avoidfilter($event)"
                                                    aria-label="Number" matInput [matAutocomplete]="auto8"
                                                    [disabled]="client" />
                                                <mat-autocomplete #auto8="matAutocomplete">
                                                    <mat-option id="optionfont" (click)="select_avoid_item(item)"
                                                        *ngFor="let item of filteredList1" value="{{item}}">
                                                        {{item}}
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </mat-label>
                                        </div>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </div>
                        <div [hidden]="avoid_hidden" class="col-2 my-auto" style="text-align: center;">
                            <a (click)="DietToAvoidAdd()" [disabled]="client"><img
                                    src="../../../assets/ui_icons/arrow_red.svg" class="saveimgbtn_inpatinfo" /></a>
                        </div> -->
                    </div>
                </div>
                <div class="tab-pane " id="therapy" role="tabpanel" aria-labelledby="home-tab"
                    [hidden]="therapy_hidden">
                    <div class="row ">
                        <div class="col-10">
                            <div class="row therapyCover">
                                <div class="col-6" style="display: table;">
                                    <mat-label class="matlabel" style="display: table-cell; vertical-align: middle;">Therapy
                                        <select required class="ipcss" [(ngModel)]="therapy_txt">
                                            <option [ngValue]="Select">Select</option>
                                            <option *ngFor="let therapy of therapy_array" value={{therapy.value}}>
                                                {{therapy.label}}</option>
                                        </select>
                                    </mat-label>
                                </div>
                                <div class="col-6">
                                    <mat-label class="matlabel">No.of sitting
                                        <input class="ipcss" required [(ngModel)]="sittings" maxlength="3"
                                            onkeypress="return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)">
                                    </mat-label>
                                </div>

                                <div class="col-6">
                                    <mat-label class="matlabel">Frequency
                                        <select class="ipcss" required [(ngModel)]="visit_freq" required>
                                            <option>Every day</option>
                                            <option>Alternative days</option>
                                            <option>3 days once</option>
                                            <option>Once in a week</option>
                                            <option>Once in fortnight</option>
                                            <option>Once in a month</option>
                                        </select>
                                    </mat-label>
                                </div>
                                <div class="col-6" *ngIf="therapyhist_array.length != 0">
                                    <mat-label class="matlabel">Physio Theraphist
                                        <select class="ipcss" required [(ngModel)]="physio_name" required>
                                            <option [ngValue]="Select">Select</option>
                                            <option *ngFor="let name of therapyhist_array" value={{name.prov_id}}>
                                                {{name.name}}</option>
                                        </select>
                                    </mat-label>
                                </div>
                            </div>
                        </div>
                        <div class="col-2 my-auto" style="text-align: center;">
                            <a (click)="addTherapy()" [disabled]="client"><img
                                    src="../../../assets/img/pagination_next.png" class="saveimgbtn_inpatinfo" /></a>
                        </div>
                       
                    </div>
                </div>
                <div class="tab-pane  show active" id="Prognosis" role="tabpanel" aria-labelledby="Prognosis-tab">
                    <div class="row">
                        <div class="col-10" style="padding: 12px;">
                            <textarea style="height: 150px !important;" class="ipcss" required maxlength="750"
                                [(ngModel)]="prog_txt" [disabled]="client"></textarea>
                        </div>
                        <div class="col-2 my-auto" style="text-align: center;">
                            <a (click)="addPrognosis()" [disabled]="client"><img
                                    src="../../../assets/img/pagination_next.png" class="saveimgbtn_inpatinfo" /></a>
                        </div>

                    </div>
                </div>
                <div class="tab-pane" id="Notes" role="tabpanel" aria-labelledby="Notes-tab">
                    <div class="row">
                        <div class="col-10" style="padding: 12px;">
                            <textarea class="ipcss txtarea" style="height: 150px !important;" rows="1" maxlength="750"
                                [(ngModel)]="remarks" [disabled]="client"></textarea>
                        </div>
                        <div class="col-2 my-auto" style="text-align: center;">
                            <a (click)="addRemarks()" [disabled]="client"><img
                                    src="../../../assets/img/pagination_next.png" class="saveimgbtn_inpatinfo" /></a>
                        </div>

                    </div>
                </div>
                <div class="table_cover " *ngIf="therapy_table" style="margin-top: 55px;">
                    <div class="col-12" style="position: relative;">
                        
    <!--                     
                        <img src="../../../assets/ui_icons/update_icon.svg" (click)="updateTherapy()" class="updateIcon" style="float: right;"/> -->
    
                        <div [hidden]="therapyList.length == 0" class="dig_table_overflow">
                            <table id="card_tbl" class="table table-hover table-dynamic">
                                <thead>
                                    <tr>
                                        <th>Theraphy</th>
                                        <th>No of sittings</th>
                                        <th>Frequency</th>
                                        <th>&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let therapy of therapyList">
                                        <td>{{therapy.therapy_name}}</td>
                                        <td>{{therapy.sittings}}</td>
                                        <td>{{therapy.frequency}}</td>
                                        <td>
                                          <a (click)="delete_therapy(therapy.therapy_id)"><img
                                            src="../../../assets/ui_icons/buttons/trash_icon.svg"
                                            class="editDelicon" /></a>
                                        </td>
                                      </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col-12" >
                        
                        <!-- <img src="../../../assets/ui_icons/buttons/history.svg" (click)="getTherapyHist()" class="saveimgbtn_inpatinfo" style="float: right;"/> -->
                    </div>
                </div>

            </div>
        </div>
        <div class="col-6 ">
            <div class="table_cover " *ngIf="diet_table" style="margin-top: 55px;">
                <div class="col-12" style="margin-top: 5px; height: 160px;">
                    <div [hidden]="dietfollowadd.length == 0" class="dig_table_overflow">
                        <table id="card_tbl" class="table table-hover table-dynamic">
                            <thead>
                                <tr>
                                    <th>Category</th>
                                    <th>Food type</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let dietfollw of dietfollowadd">
                                    <td>
                                        <div class="dia_table_left">{{dietfollw.food_type_name}}</div>
                                    </td>
                                    <td>
                                        <div class="dia_table_left">{{dietfollw.food_item}}</div>
                                    </td>
                                    <td>
                                        <a [disabled]="client"
                                            (click)="DeleteDietFollow(dietfollw.food_type_name,dietfollw.food_item)"><img
                                                src="../../../assets/ui_icons/buttons/trash_icon.svg"
                                                class="editDelicon" /></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="col-12" style="margin-top: 5px; height: 160px;">
                    <div [hidden]="dietavoidwadd.length == 0" class="dig_table_overflow">
                        <table id="card_tbl" class="table table-hover table-dynamic">
                            <thead>
                                <tr>
                                    <th>Category</th>
                                    <th>Food type</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let dietavoid of dietavoidwadd">
                                    <td>
                                        <div class="dia_table_left">{{dietavoid.food_type_name}}</div>
                                    </td>
                                    <td>
                                        <div class="dia_table_left">{{dietavoid.food_item}}</div>
                                    </td>
                                    <td>
                                        <a [disabled]="client"
                                            (click)="DeleteDietAvoid(dietavoid.Food_type_avoid,dietavoid.food_item)"><img
                                                src="../../../assets/ui_icons/buttons/trash_icon.svg"
                                                class="editDelicon" /></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="table_cover" *ngIf="prognosis_table" style="margin-top: 55px;">
                <div class="col-12" *ngIf="Nepro_Prognosis_List.length != 0" style="height: 150px;">
                    <div class="dig_table_overflow" *ngIf="Nepro_Prognosis_List.length != 0">
                        <table id="card_tbl" class="table table-hover table-dynamic">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Doctor Name</th>
                                    <th>Description</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let prognosis of Nepro_Prognosis_List">
                                    <td style="width: 16%;font-size: 10px;">{{prognosis.Date}}</td>
                                    <td style="width: 27%;text-align: left;font-size: 10px;">{{prognosis.Doct_Name}}
                                    </td>
                                    <td style="text-align: left;font-size: 10px;">{{prognosis.Prog_Data}}</td>
                                    <td style="width: 40px;" *ngIf="!prognosis_array_flag"></td>
                                    <td style="width: 40px;" *ngIf="prognosis_array_flag" [disabled]="client"><img
                                            src="../../../assets/ui_icons/buttons/pencil_icon.svg" class="editDelicon"
                                            (click)="edit_prognosis(prognosis)" /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="table_cover" *ngIf="notes_table" style="margin-top: 55px;">
                <div class="col-12" *ngIf="remarksList.length != 0" style="height: 150px;">
                    <div class="dig_table_overflow" *ngIf="remarksList.length != 0">
                        <table id="card_tbl" class="table table-hover table-dynamic">
                            <thead>
                                <tr>
                                    <th style="width: 150px;">Date</th>
                                    <th>Note</th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let remark of remarksList">
                                    <td style="font-size: 10px;">{{remark.Date}}</td>
                                    <td style="text-align: left;font-size: 10px;">{{remark.remarks_data}}</td>
                                    <td style="width: 40px;" *ngIf="!remarksList_flag"></td>
                                    <td style="width: 40px;" *ngIf="remarksList_flag"><img
                                            src="../../../assets/ui_icons/buttons/pencil_icon.svg" class="editDelicon"
                                            (click)="edit_remarks(remark)" [disabled]="client" /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </div>

    </div>

    <div class="row" style="padding-top: 20px;">
        <div [hidden]="next_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel" [hidden]="next_hidden">Next visit<br>
                <input type="date" class="ipcss_date " min={{currentDate}} id="appt_date"
                    (change)="next_dateclick(next_data);send_data();" [disabled]="client" [(ngModel)]="next_data" #matInput>
            </mat-label>
        </div>
    </div>

    <div style="margin-top: 20px;">
        <div class="row">
            <div class="col-12">
                <p class="tervys_heading"><strong>Referred Doctor</strong></p>
            </div>
        </div>
        <div class="row">
            <div [hidden]="Hospital_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel" [hidden]="Hospital_hidden">Hospital name<br>
                    <input type="text" class="ipcss " maxlength="50" [(ngModel)]="hosp_name" [disabled]="client"
                        (keyup)="hospital_filter($event)" aria-label="Number" matInput [matAutocomplete]="auto1" (change)="send_data()" />
                    <mat-autocomplete #auto1="matAutocomplete">
                        <mat-option id="optionfont" *ngFor="let item of filteredhospList"
                            (click)="select_hospital(item)" [value]="item">
                            {{item}}
                        </mat-option>
                    </mat-autocomplete>

                </mat-label>
            </div>
            <div [hidden]="spl_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel" [hidden]="spl_hidden">Speciality<br>
                    <select class="ipcss " [(ngModel)]="speclization_txt" [disabled]="client" (change)="send_data()">
                        <option *ngFor="let spl of get_speclization_arry" value="{{spl.spl_name}}">{{spl.spl_name}}
                        </option>
                    </select>
                </mat-label>
            </div>
            <div [hidden]="refer_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel" [hidden]="refer_hidden">Referred to<br>
                    <input type="text" class="ipcss " maxlength="50" [(ngModel)]="refer_txt" [disabled]="client"
                        (keyup)="Get_referal($event)" aria-label="Number" matInput [matAutocomplete]="auto2" (change)="send_data()" />
                    <mat-autocomplete #auto2="matAutocomplete">
                        <mat-option id="optionfont" *ngFor="let item of Refered_doctor"
                            (click)="Select_refer_doctor(item)" [value]="item">
                            {{item}}
                        </mat-option>
                    </mat-autocomplete>

                </mat-label>
            </div>
        </div>
    </div>

    <div class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" id="nephModal" tabindex="-1"
        aria-labelledby="nephModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content" style="border-radius: 20px !important;top: 166px;padding: 0;">
                <div class="modal-body">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 d-flex align-items-end">
                                <p class="noRx_content">Want to save?</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer" style="border-top: none !important;padding: 4px 0 10px 0px;">
                    <div class="closeoption"><span (click)="send_graph('yes')" class="noRx_yes_no">Yes</span>
                    </div>
                    <div class="closeoption"><span (click)="send_graph('no')" class="noRx_yes_no">No</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Graph <div class="row">
            <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-9">
              <div #fixedContainer ion-fixed>
                <canvas #myCanvas id="can" ></canvas>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
              <div class="new_bord">
                <div class="sve_nephiamge">
                  <img src="../../../assets/img/sve_neph.png" id="btn" size="30" (click)="save_graph()" class="image_chec" />
                </div>
                <br />
                <div class="erase_div">
                  <img src="../../../assets/img/refresh_image.png" size="23" class="erase_image" (click)="reset_image()" />
                </div>
                <div class="green_color" id="green" (click)="select_click('green')"></div>
                <div class="blue_color" id="blue" (click)="select_click('blue')"></div>
                <div class="red_color" id="red" (click)="select_click('red')"></div>
                <div class="yellow_color" id="yellow" (click)="select_click('yellow')"></div>
                <div class="orange_color" id="orange" (click)="select_click('orange')"></div>
                <div class="black_color" id="black" (click)="select_click('black')"></div>
                <div class="brown_color" id="brown" (click)="select_click('brown')"></div>
                <div class="gray_color" id="gray" (click)="select_click('gray')"></div>             
              </div>
            </div>
    </div> -->
    <!-- <a><img src="../../../assets/ui_icons/buttons/History_button.svg" class="histimgbtn"/></a> -->
</div>