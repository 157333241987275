<div *ngIf="diabCompFlag == 'min'">
    <div class="block1_grid">
        <mat-label class="matlabel">{{cardio_label}}<br>
            <textarea class="ipcss"   [(ngModel)]="cardio_txt" (click)="medical_popup()"></textarea>
        </mat-label>

         <mat-label class="matlabel">{{neuropathy_label}}<br>
            <textarea class="ipcss"   [(ngModel)]="Neuropathy" (click)="neuro()"></textarea>
        </mat-label>
    </div> 
</div>

<div *ngIf="diabCompFlag == 'max'">
    <div class="row">
        <div class="col-12">
            <div class="cover_div">
                <div class="content_cover">
                    <div class="row">
                        <div class="col-12 col-md-6 col-lg-4 col-xl-3">
                            <mat-label class="matlabel">{{cardio_label}}<br>
                                <textarea class="ipcss"  [(ngModel)]="cardio_txt" (click)="medical_popup()" [disabled]="client" (change)="send_data()" ></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4 col-xl-3">
                            <mat-label  class="matlabel">{{neuropathy_label}}<br>
                                <textarea class="ipcss"  [(ngModel)]="Neuropathy" (click)="neuro()" [disabled]="client" (change)="send_data()"></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4 col-xl-3">
                            <mat-label class="matlabel">{{nephropathy_label}}<br>
                                <textarea class="ipcss" [(ngModel)]="nephropathy" (click)="neph()" [disabled]="client" (change)="send_data()"></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4 col-xl-3">
                            <mat-label class="matlabel">{{retinopathy_label}}<br>
                                <textarea class="ipcss" [(ngModel)]="retino_pathy" (click)="retino_pathy1()" [disabled]="client" (change)="send_data()"></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4 col-xl-3">
                            <mat-label class="matlabel">{{footdamage_label}}<br>
                                <textarea class="ipcss" [(ngModel)]="foot_damage" (click)="footdamage()" [disabled]="client" (change)="send_data()"></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4 col-xl-3">
                            <mat-label class="matlabel">{{skincon_label}}<br>
                                <textarea class="ipcss" [(ngModel)]="skin_txt" (click)="skin()" [disabled]="client" (change)="send_data()"></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4 col-xl-3">
                            <mat-label class="matlabel">{{hearing_label}}<br>
                                <textarea class="ipcss" [(ngModel)]="hearing_txt" (click)="hear()" [disabled]="client" (change)="send_data()"></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4 col-xl-3">
                            <mat-label class="matlabel">{{mobility_label}}<br>
                                <textarea class="ipcss" [(ngModel)]="mobility_txt" (click)="mobi_flag1()" [disabled]="client" (change)="send_data()"></textarea>
                            </mat-label>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="assessment" style="float: right;">
        <a [hidden]="clinic_histhidden" (click)="history()"><img src="../../../assets/ui_icons/buttons/History_button.svg" class="saveimgbtn_inpatinfo"/></a>
    </div><br>
</div>
