<div class="row" style="width:100%;margin:0 auto;">
   <div class="col-12">
      <mdb-card>
         <mdb-card-header class="bg-white ">
            <div class="headerCover">
               <div class="headerTilte app_list_header">
                  <h5 class="m-0" class="mainHeadingStyle">Inventory Configuration</h5>
               </div>
               <div class="headerButtons">
                  <img class="saveimgbtn_inpatinfo" src="../../../assets/ui_icons/buttons/save_button.svg"
                     (click)="saveConfig()" />
               </div>
            </div>
         </mdb-card-header>
         <mdb-card-body>
            <div class="cover_div" style="padding-bottom: 13px;">
               <div class="header_lable">
                  Inventory Configuration details
               </div>
               <div class="content_cover">
                  <div class="row">
                     <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 card"
                        style="border:1px solid gainsboro;border-radius: 10px;padding:10px">
                        <div class="row">
                           <div class="col-12">
                              <mat-label class="matlabel">Indent Request
                                 <!-- <input id="cbx-12" type="checkbox" style=" position:relative;
                              top:4px;" (change)="indentrequest($event)" [(ngModel)]="isIndentReq_Checked"> -->
                                 <mat-checkbox color="primary" (change)="indentrequest()"
                                    [(ngModel)]="isIndentReq_Checked" labelPosition="before"></mat-checkbox>
                              </mat-label>
                           </div>
                        </div>
                     </div>
                     <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 card"
                        style="border:1px solid gainsboro;border-radius: 10px;padding:10px" *ngIf="indentapprovalflag">
                        <div class="row">
                           <div class="col-12">
                              <mat-label class="matlabel"> Indent Approval needed
                                 <!-- <input id="cbx-14" type="checkbox" style=" position:relative;top:4px;"
                                    (change)="indentapproval($event)"></mat-label> -->
                                 <mat-checkbox color="primary" (change)="indentapproval()"
                                    [(ngModel)]="isIndentApprv_Checked" labelPosition="before"></mat-checkbox>
                              </mat-label>
                           </div>
                        </div>
                     </div>
                     <div class="col-12 col-sm-6 col-md-4 col-lg-6 col-xl-5 card"
                        style="border:1px solid gainsboro;border-radius: 10px;" *ngIf="storesaveflag">
                        <div class="row">
                           <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                              <mat-label class="matlabel">Store Name<br>
                                 <mat-select id="paytype" class="ipcss  widthbillcreate" [(ngModel)]="storename">
                                    <mat-option *ngFor="let store of storeListArray"
                                       value={{store.name}}>{{store.name}}</mat-option>
                                 </mat-select>
                              </mat-label>
                           </div>
                           <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                              <mat-label class="matlabel">User Name<br>
                                 <mat-select id="paytype" class="ipcss  widthbillcreate" [(ngModel)]="username">
                                    <mat-option *ngFor="let user of userListArray"
                                       value={{user.id}}>{{user.name}}</mat-option>
                                 </mat-select>
                              </mat-label>
                           </div>
                           <div class="col-xl-4" style="padding: 20px 4px;">
                              <img src="../../../assets/ui_icons/buttons/add_button.svg"
                                 (click)="approvestorename(storename,username)" class="saveimgbtn_inpatinfo  ">
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div style="margin-top: 15px;" [hidden]="approvedstorenamearray.length==0">

                  <div class="content_cover">
                     <div class="row">
                        <div class="col-12 col-sm-5 col-md-5 col-lg-5 col-xl-6">
                           <div class="col-12 col-md-12 col-sm-12 col-xl-12 col-lg-12">
                              <div class="dig_table_overflow" style="height: 200px !important;">
                                 <div class="table-responsive ">
                                    <!-- [dtOptions]="dtOptions" -->
                                    <!-- mdbTable datatable -->
                                    <table class="table table-nowrap table-sm dataTable billcreae_table table_border"
                                       style="margin-top: 10px;">
                                       <thead class="tableheading">
                                          <tr>
                                             <th>Store id</th>
                                             <th>Store name</th>
                                             <th>User name</th>
                                             <th>Action</th>
                                          </tr>
                                       </thead>
                                       <tbody>
                                          <tr *ngFor="let store of approvedstorenamearray">
                                             <td class="align_left">{{store.store_name_id}}</td>
                                             <td class="align_left">{{store.store_name}}</td>
                                             <td>{{store.store_approver}}
                                             <td>
                                                <a class="curser_pointer">
                                                   <img src="../../../assets/ui_icons/buttons/trash_icon.svg"
                                                      height="22px" class=" deletebtn saveimgbtn_doctreg" alt="">
                                                </a>
                                             </td>
                                          </tr>
                                       </tbody>
                                    </table>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="cover_div" style="padding-bottom: 13px;height: 100px;width: 100%;">
               <div class="header_lable">Approval Request</div>
               <div class="content_cover">
                  <div class="row">
                     <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 card"
                        style="border:1px solid gainsboro;border-radius: 10px;padding:10px">
                        <div class="row">
                           <div class="col-12">
                              <mat-label class="matlabel">PO Approval Request
                                 <mat-checkbox color="primary" [(ngModel)]="isPOApprv_Checked" labelPosition="before"></mat-checkbox>
                              </mat-label>
                           </div>
                        </div>
                     </div>
                     <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                        <mat-label class="matlabel">User Name<br>
                           <mat-select id="paytype" class="ipcss  widthbillcreate" [(ngModel)]="username">
                              <mat-option *ngFor="let user of userListArray"
                                 value={{user.id}}>{{user.name}}</mat-option>
                           </mat-select>
                        </mat-label>
                     </div>
                  </div>
               </div>
            </div>
            <div class="cover_div" style="padding-bottom: 13px;height: 100px;width: 100%;">
               <div class="header_lable">Batch Expiry</div>
               <div class="content_cover">
                  <div class="row">
                     <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                        <mat-label class="matlabel">Return product batch expiry<br>
                           <mat-select id="paytype" class="ipcss  widthbillcreate" [(ngModel)]="ReturnProd_Exp">
                              <mat-option value='3'>3 Months</mat-option>
                              <mat-option value='6'>6 Months</mat-option>
                              <mat-option value='9'>9 Months</mat-option>
                           </mat-select>
                        </mat-label>
                     </div>
                  </div>
               </div>
            </div>
            <div class="cover_div" style="padding-bottom: 13px;height: 200px;width: 100%;">
               <div class="header_lable">Turn Around Time</div>
               <div class="content_cover">
                  <div class="row">
                     <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 select_bottom">
                        <div class="row">
                           <div class="col-12 col-sm-6 col-md-6 col-lg-7 col-xl-7 select_bottom">
                              <mat-label class="matlabel">Appointment booking TAT
                                 <input class="ipcss" type="number" #matInput [(ngModel)]="app_book_TAT" />
                              </mat-label>
                           </div>
                           <div class="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-5 select_bottom"
                              style="margin-top: 18px;">
                              <!-- <mat-label class="matlabel">
                                 <select disableOptionCentering class="ipcss " required>
                                    <option value="Hour(s)">Hour(s)</option>
                                    <option value="Minutes(s)">Minutes(s)</option>
                                    <option value="Day(s)">Day(s)</option>
                                 </select>
                              </mat-label> -->
                              <p>Mins</p>
                           </div>
                        </div>
                     </div>
                     <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 select_bottom">
                        <div class="row ">
                           <div class="col-12 col-sm-6 col-md-6 col-lg-7 col-xl-7 select_bottom">
                              <mat-label class="matlabel">Consulting TAT
                                 <input class="ipcss" #matInput [(ngModel)]="consult_TAT" />
                              </mat-label>
                           </div>
                           <div class="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-5 select_bottom"
                              style="margin-top: 18px;">
                              <p>Mins</p>
                           </div>
                        </div>
                     </div>
                     <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 select_bottom">
                        <div class="row">
                           <div class="col-12 col-sm-6 col-md-6 col-lg-7 col-xl-7 select_bottom">
                              <mat-label class="matlabel">Pharma Estimation TAT
                                 <input class="ipcss" #matInput [(ngModel)]="pharmaEst_TAT" />
                              </mat-label>
                           </div>
                           <div class="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-5 select_bottom"
                              style="margin-top: 18px;">
                              <p>Mins</p>
                           </div>
                        </div>
                     </div>
                     <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 select_bottom">
                        <div class="row">
                           <div class="col-12 col-sm-6 col-md-6 col-lg-7 col-xl-7 select_bottom">
                              <mat-label class="matlabel">Pharma Disposal TAT
                                 <input class="ipcss" #matInput [(ngModel)]="pharmaDisp_TAT" />
                              </mat-label>
                           </div>
                           <div class="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-5 select_bottom"
                              style="margin-top: 18px;">
                              <p>Mins</p>
                           </div>
                        </div>
                     </div>
                     <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 select_bottom">
                        <div class="row">
                           <div class="col-12 col-sm-6 col-md-6 col-lg-7 col-xl-7 select_bottom">
                              <mat-label class="matlabel">Pharma Bill Create TAT
                                 <input class="ipcss" #matInput [(ngModel)]="pharmaBillGen_TAT" />
                              </mat-label>
                           </div>
                           <div class="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-5 select_bottom"
                              style="margin-top: 18px;">
                              <p>Mins</p>
                           </div>
                        </div>
                     </div>
                     <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 select_bottom">
                        <div class="row">
                           <div class="col-12 col-sm-6 col-md-6 col-lg-7 col-xl-7 select_bottom">
                              <mat-label class="matlabel">Inward drug batch max expiry duration
                                 <input class="ipcss" #matInput [(ngModel)]="inwardDrug_EXP" />
                              </mat-label>
                           </div>
                           <div class="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-5 select_bottom"
                              style="margin-top: 18px;">
                              <p>Months</p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="cover_div" style="padding-bottom: 13px;height: 100px;width: 100%;">
               <div class="header_lable">Bill date time edit</div>
               <div class="content_cover">
                  <div class="row">
                     <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 card"
                        style="border:1px solid gainsboro;border-radius: 10px;padding:10px">
                        <div class="row">
                           <div class="col-12">
                              <mat-label class="matlabel">Required Estimation
                                 <!-- <input id="cbx-12" type="checkbox" style=" position:relative;
                              top:4px;"> -->
                                 <mat-checkbox color="primary" [(ngModel)]="req_estimation"
                                    labelPosition="before"></mat-checkbox>
                              </mat-label>
                           </div>
                        </div>
                     </div>
                     <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 card"
                        style="border:1px solid gainsboro;border-radius: 10px;padding:10px">
                        <div class="row">
                           <div class="col-12">
                              <mat-label class="matlabel">Required round off
                                 <mat-checkbox color="primary" [(ngModel)]="req_roundOff"
                                    labelPosition="before"></mat-checkbox>
                              </mat-label>
                           </div>
                        </div>
                     </div>
                     <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 card"
                        style="border:1px solid gainsboro;border-radius: 10px;padding:10px">
                        <div class="row">
                           <div class="col-12">
                              <mat-label class="matlabel">Required registration fee
                                 <mat-checkbox color="primary" [(ngModel)]="req_regFee"
                                    labelPosition="before"></mat-checkbox>
                              </mat-label>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

         </mdb-card-body>
      </mdb-card>
   </div>
</div>