import { Component, OnInit,Input } from '@angular/core';
import {Helper_Class} from '../../helper_class';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
// import {ipaddress.getIp,Ip_with_img_address,ipaddress.country_code} from '../../../assets/js/ipaddress.js';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Date_Formate } from '../../../assets/js/common';
import { Doc_Helper } from '../Doc_Helper';
import { DoctorViewService } from '../doctor-view/doctor-view.service';
import { CasesheetService } from '../casesheet/casesheet.service';
import { InpatMedprescViewComponent } from '../inpat-medpresc-view/inpat-medpresc-view.component';
import { MatDialog } from '@angular/material/dialog';
import { ipaddress } from '../../ipaddress' ;import { Message_data } from 'src/assets/js/Message_data';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
@Component({
  selector: 'app-gynac-dis-summary',
  templateUrl: './gynac-dis-summary.component.html',
  styleUrls: ['./gynac-dis-summary.component.scss']
})
export class GynacDisSummaryComponent implements OnInit {

  public header_footer_flag: boolean;
  public patient_name: string;
  public patient_identifier: string;
  public gender: string;
  public age: string;
  public admis_date: string;
  public dis_date: string;
  public doctor_name: string;
  public mobile_no: string;
  public symptoms: string;
  public complaints: string;
  public medical_hist: string;
  public physical_exam: string;

  public loader: boolean;
  public pres_pop_flag: string;
  public mobile: string;
  public next_data: string;
  public inpatient_id: string;

  public dis_diag: string;
  public hosp_course: string;
  public hospital_followup: string;
  public confirm_btn: boolean;
  public druglist=[];
  
  public hosp_name: string;
  public hosp_addr: string;
  public hosp_csz: string;
  public hosp_tel: string;
  public hosp_web: string;
  public hosp_web_flag: boolean;

  public hosp_count: string;
  public life_style_id: string;

  public marital_status:string;
  public menstrual_hist: string;
  public last_mens_period: string;
  public edd_value: string;
  public med_hist: string;

  public system_exam: string;
  public pv_exam: string;
  public lab_invest: string;
  public treatment: string;
  public anaesthetia: string;
  public page_title: string;
  public currentDate;
  public userinfo;
  public user_id;
  public nodischarge_flag:boolean = false;

  constructor(public dialog:MatDialog,public http:Http,public routes:ActivatedRoute,public router:Router,public toastr:ToastrService,public messageservice:MenuViewService,public cashsheet: CasesheetService) { 
    this.loader = false;
    this.confirm_btn= false;
    this.getCurrentDate();
  }

  ngOnInit(): void {
    this.userinfo= Helper_Class.getInfo();
    this.user_id=this.userinfo.user_id;
    this.life_style_id = Doc_Helper.getGynoInpatDS().life_style_id;

    if(this.life_style_id == undefined){
      this.nodischarge_flag = false;
    }

    if(this.life_style_id != undefined){
      this.nodischarge_flag = true;
      this.getDischargeSummaryDetails();
    }
  }

  getCurrentDate(){
    var get_date;
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate',
      JSON.stringify({
        country: ipaddress.country_code.toString()
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          
          if (obj.current_date != null) {
            get_date = obj.current_date.split('-');
            this.dis_date = get_date[2] + "-" + get_date[1] + "-" +get_date[0];
            this.currentDate = get_date[2] + "-" + get_date[1] + "-" +get_date[0];
          }
        },
        error => {}
      )
   }

  getDischargeSummaryDetails(){
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + "goi/gdsum", {life_style_id: this.life_style_id},
        { headers: headers })
        .subscribe(
            response => {
              var values = response.json();
              
              if(values["key"] != 0 && values != undefined && values != null){
                this.nodischarge_flag = true;

                if(values.first_name != undefined){
                  this.loader = true;

                  if(values.middle_name != undefined && values.middle_name !="" && values.middle_name !=null){
                    this.patient_name =encrypt_decript.Decript(values.first_name)+" "+encrypt_decript.Decript(values.middle_name)+" "+encrypt_decript.Decript(values.last_name);
                  } else {
                    this.patient_name =encrypt_decript.Decript(values.first_name)+" "+encrypt_decript.Decript(values.last_name);
                  }
    
                  this.patient_identifier= values.patient_id;
                  this.gender= encrypt_decript.Decript(values.gender);
                  this.age = values.age;
                  var dateval = values.admission_date.split('-');
                  this.admis_date= dateval[2]+"-"+dateval[1]+"-"+dateval[0];

                  var lmperiod= values.last_mens_period.split('-');
                  this.last_mens_period=lmperiod[2]+"-"+lmperiod[1]+"-"+lmperiod[0];
                  
                  var eddval= values.edd.split('-');
                  this.edd_value=eddval[2]+"-"+eddval[1]+"-"+eddval[0];

                  this.dis_date=localStorage.getItem("current_date");
    
                  this.doctor_name = values.dr_mname != undefined ? values.dr_fname + " " + values.dr_mname + " " + values.dr_lname : values.dr_fname + " " + values.dr_lname;
    
                  this.marital_status = values.marital_status;
                  var menshist = values.cycle_length.split(' ');
                  this.menstrual_hist = menshist[1]+"/"+menshist[0]+" "+values.periods;
                  this.complaints = values.complaints;

                  if(values.med_hist != undefined){
                    this.medical_change(values.med_hist);
                  }
                  
                  this.physical_exam = values.physical_exam;
                  this.inpatient_id = values.inpatient_id;

                  this.dis_diag = values.discharge_diagnosis;
                  this.med_hist=values.med_hist;
                  this.physical_exam=values.physical_exam;
                  this.system_exam=values.system_exam;
                  this.pv_exam=values.pv_exam;
                  this.lab_invest = values.lab_invest;
                  this.treatment = values.treatment;
                  this.hospital_followup = values.follow_summary;
                  this.anaesthetia=values.anaesthisia_doc;

                  if(values.next_visit != undefined){
                    var ndate = values.next_visit.split('-');
                    this.next_data = ndate[2]+"-"+ndate[1]+"-"+ndate[0];
                  }
                  
                  this.confirm_btn = values.gynob_discharge_id != undefined ? true : false;
                  this.hosp_name = values.hptl_name;

                  var addval = values.address2 != undefined ? values.address1 + "," + values.address2 : values.address1;
                  this.hosp_addr = addval;
                  this.hosp_csz=values.city+", "+values.state+" - "+values.zipcode+"  "+values.country;
                  this.hosp_count = values.country;
                  this.hosp_tel = values.telephone != undefined ? values.telephone : "-";
                 
                  if(values.website != undefined){
                    this.hosp_web = values.website;
                    this.hosp_web_flag=false;
                  } else {
                    this.hosp_web_flag= true;
                  }

                  if(values.drug_list != undefined){
                    for(var i=0;i<values.drug_list.length;i++){
                      var master_tablet_data = values.drug_list[i];
                      var medicine_dosage_data = "";

                      if (master_tablet_data.intake_desc == "Every 6 hours") {
                        medicine_dosage_data = master_tablet_data.morning +master_tablet_data.short_form+ '-' + master_tablet_data.morning +master_tablet_data.short_form+ '-' + master_tablet_data.morning+master_tablet_data.short_form+master_tablet_data.short_form + '-' + master_tablet_data.morning+master_tablet_data.short_form;
                      } else {
                        medicine_dosage_data = master_tablet_data.morning +master_tablet_data.short_form+ '-' + master_tablet_data.afternoon +master_tablet_data.short_form+ '-' + master_tablet_data.evening+master_tablet_data.short_form;
                      }

                      var master_timeDuration = "";
                      if (master_tablet_data.drug_intake == "With food") {
                        master_timeDuration = master_tablet_data.drug_intake;
                      } else {
                        if (master_tablet_data.time_duration != null && master_tablet_data.time_duration != "0") {
                          master_timeDuration = master_tablet_data.time_duration + ' mins ' + master_tablet_data.drug_intake;
                        } else {
                          master_timeDuration = master_tablet_data.drug_intake;
                        }
                      }

                      this.druglist.push({
                        medicine_name: master_tablet_data.short_name+" "+master_tablet_data.drug_name,
                        medicine_dosage: medicine_dosage_data,
                        medicine_intakedays: master_tablet_data.days + ' days ',
                        medicine_food: master_timeDuration
                      })               
                    }
                  }
                }
            }
            else{
              this.nodischarge_flag = false;
            }
            },
            error => {
                this.loader = true;
            }
        )
  }

  medical_change(selected) {
    this.med_hist = "";

    function StringBuilder(this:any,value) {
        this.strings = new Array("");
        this.append(value);
    }

    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
        if (value) {
            this.strings.push(value);
        }
    }

    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
        this.strings.length = 1;
    }

    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
        return this.strings.join("");
    }

    var selected_data = new StringBuilder("");
    var med_array = selected;

    if (med_array.length != 0) {
      var n = 0;
        for (var i = 0; i < med_array.length; i++) {
          n = n + 1;
          if (n > 1) {
              selected_data.append(",");
              selected_data.append("\n");
          }
          selected_data.append(med_array[i]);
        }
      this.med_hist = selected_data.toString();
    }
}

  //Redirect to Prescription 
  Write_prescription() {
    var data = Doc_Helper.getGynoMedPres();
    Doc_Helper.setClient_Info(data);
    this.messageservice.sendMessage("medprescription");
  }

  //next vixit date
  NextDate(data) {
    this.next_data = data;
    // let search_model = this.modalCtrl.create('CalendarPage',{flow:"future"});
    // search_model.present();

    // search_model.onDidDismiss(
    //   data => {
    //     if (data != null) {
    //       this.next_data = data;
    //     }
    //   });
  }

  saveDischargeSummary(){
    var dateval;
    var newdate;
    if(this.next_data != undefined){
      dateval= this.next_data.split('-');
      newdate = dateval[2]+"-"+dateval[1]+"-"+dateval[0];
    } 

    var medpres;
    if(Helper_Class.get_med_pres_id() != null){
      medpres = Helper_Class.get_med_pres_id();
    }
    var diagpres;
    if(Helper_Class.get_diag_pres_id() != null){
      diagpres = Helper_Class.get_diag_pres_id();
    }
    
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'goi/sdissum',JSON.stringify({
      life_style_id: this.life_style_id,
      discharge_diagnosis: this.dis_diag,
      med_hist: this.med_hist,
      physical_exam: this.physical_exam,
      system_exam: this.system_exam,
      pv_exam: this.pv_exam,
      lab_invest: this.lab_invest,
      treatment: this.treatment,
      follow_summary: this.hospital_followup,
      next_visit: newdate,
      pres_drug_id: medpres,
      pres_diag_id: diagpres,
      anaesthisia_doc: this.anaesthetia,
      flag: this.confirm_btn,
    }),
      { headers: headers })
      .subscribe(
        response => {
          var data = JSON.parse(response["_body"]);
          
          if(data.result !="0"){
            this.confirm_btn=true;
            this.toastr.success(Message_data.disSummarySavedSuccess);

          } else {
            this.toastr.error(Message_data.unabltToSaveDisSummary);
          }
        })
  }

  print_area() {
    this.getDischargeSummaryDetails();
    
    let printContents, popupWin;
    printContents = document.getElementById('discharge_summary').innerHTML;
    popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Bill</title>
          <link rel="stylesheet" media="screen" href="">
          <style>
          @page { size: auto;  margin: 0mm; }
          .table_class {
            background-color: rgb(108, 180, 248);
          }
          .casesheet_container {
            width: 211px;
            padding: 1px;
            background: #277196;
            display: inline-flex;
            position: relative;
            padding-left: 5px;
            top: 21px;
            color: #FFFFFF;
          }
          .border_style{
            border:solid 1px;
            border-color: #488aff;
          }
          .width_style{
            margin-top:4px;
            width:600px;
          }
          .space_style {
            margin-top: -15px;
          }
          </style>
        </head>
        <body onload="window.print();window.onfocus=function(){ window.close();}">${printContents}</body>
      </html>`
    );
    popupWin.document.close();

    // var headers = new Headers();
    // headers.append('Content-Type', 'application/json');
    // this.http.post(ipaddress.getIp.toString() + "inpat/gdsv", {inpatient_id: this.inpatient_id},
    //     { headers: headers })
    //     .subscribe(
    //         response => {
    //           var values = JSON.parse(response["_body"]);
    //           if(values != undefined){
    //             this.loader = true;

    //             if(values.middle_name != undefined){
    //               this.patient_name =encrypt_decript.Decript(values.first_name)+" "+encrypt_decript.Decript(values.middle_name)+" "+encrypt_decript.Decript(values.last_name);
  
    //             } else {
    //               this.patient_name =encrypt_decript.Decript(values.first_name)+" "+encrypt_decript.Decript(values.last_name);
    //             }
  
    //             this.patient_identifier= values.patient_id;
    //             this.gender= encrypt_decript.Decript(values.gender);
    //             this.age = values.age;
    //             var dateval = values.admission_date.split('-');
  
    //             this.admis_date= dateval[2]+"-"+dateval[1]+"-"+dateval[0];
    //             this.dis_date=localStorage.getItem("current_date");
    //             if(values.dr_mname != undefined){
    //               this.doctor_name=values.dr_fname+" "+values.dr_mname+" "+values.dr_lname;
  
    //             } else {
    //               this.doctor_name=values.dr_fname+" "+values.dr_lname;
    //             }
  
    //             this.symptoms=values.symptoms;
    //             this.complaints=values.complaints;

    //             this.medical_hist = values.medical_history;
    //             this.physical_exam = values.physical_exam;

    //             this.dis_diag = values.discharge_diagnosis; 
    //             this.hospital_followup= values.follow_summary;

    //             var nextdate = values.next_visit.split('-');
    //             this.next_data = nextdate[2]+"-"+nextdate[1]+"-"+nextdate[0];

    //             if(values.drug_list != undefined){
    //               for(var i=0;i<values.drug_list.length;i++){
    //                 var master_tablet_data = values.drug_list[i];
    //                 var medicine_dosage_data = "";

    //                 if (master_tablet_data.intake_desc == "Every 6 hours") {
    //                   medicine_dosage_data = master_tablet_data.morning +master_tablet_data.short_form+ '-' + master_tablet_data.morning +master_tablet_data.short_form+ '-' + master_tablet_data.morning+master_tablet_data.short_form+master_tablet_data.short_form + '-' + master_tablet_data.morning+master_tablet_data.short_form;

    //                 } else {
    //                   medicine_dosage_data = master_tablet_data.morning +master_tablet_data.short_form+ '-' + master_tablet_data.afternoon +master_tablet_data.short_form+ '-' + master_tablet_data.evening+master_tablet_data.short_form;
    //                   
    //                 }

    //                 var master_timeDuration = "";
    //                 if (master_tablet_data.drug_intake == "With food") {
    //                   master_timeDuration = master_tablet_data.drug_intake;

    //                 } else {
    //                   if (master_tablet_data.time_duration != null && master_tablet_data.time_duration != "0") {
    //                     master_timeDuration = master_tablet_data.time_duration + ' mins ' + master_tablet_data.drug_intake;
                      
    //                   } else {
    //                     master_timeDuration = master_tablet_data.drug_intake;
    //                   }
    //                 }

    //                 this.druglist.push({
    //                   medicine_name: master_tablet_data.short_name+" "+master_tablet_data.drug_name,
    //                   medicine_dosage: medicine_dosage_data,
    //                   medicine_intakedays: master_tablet_data.days + ' days ',
    //                   medicine_food: master_timeDuration
    //                 })               
    //               }
    //             }

    //             this.hosp_name = values.hptl_name;
    //             var addval;
    //             if(values.address2 != undefined){
    //               addval = values.address1+","+values.address2;

    //             } else {
    //               addval = values.address1;
    //             }
    //             this.hosp_addr = addval;
    //             this.hosp_csz=values.city+", "+values.state+" - "+values.zipcode+"  "+values.country;
    //             this.hosp_count = values.country;
    //             if(values.telephone != undefined){
    //               this.hosp_tel = values.telephone;

    //             } else {
    //               this.hosp_tel = "-";
    //             }
                
    //             if(values.website != undefined){
    //               this.hosp_web = values.website;
    //               this.hosp_web_flag=false;

    //             } else {
    //               this.hosp_web_flag= true;
    //             }
                 

    //           } else {
    //             this.toastr.error("Something weng wrong, try again");
    //           }
    //         },
    //         error => {
    //             this.loader = true;
    //             this.toastr.error("Internet is too slow or no connection, try again later");
    //         }
    //     )
    
  }

  view_prescription() {
    const dialogRef5 = this.dialog.open(InpatMedprescViewComponent, {
      width: '700px',
      height: '300px',
      data:{inpatient_id: this.inpatient_id}
    });
  
    dialogRef5.afterClosed().subscribe(result => {});
  }

  back_Nav() {
    this.messageservice.sendMessage("inPatientViewInfo");
  }

}
