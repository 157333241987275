import { Component, OnInit } from '@angular/core';
import { Helper_Class } from '../../helper_class';
import { ToastrService } from 'ngx-toastr';
import { ipaddress } from 'src/app/ipaddress';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Http, Headers } from '@angular/http';



@Component({
  selector: 'app-diagnosismaster',
  templateUrl: './diagnosismaster.component.html',
  styleUrls: ['./diagnosismaster.component.scss']
})
export class DiagnosismasterComponent implements OnInit {
  DiagnosticRarraytype: any;
  changetestid: any;
  DiagnostictRarray: any;
  testtube_data: any[];
  diagnosisapp_id: any;
  tube_color_data: any = [];
  userId: any;
  user_type: string;
  provider_type: any;
  hptl_clinic_id: any;
  diag_centre_id: any;
  pharma_id: any;
  doctorList: any[];
  user_ids: any;
  user_id: any;
  doctorid: any;
  userinfo: any;
  Diag_Test_list_Data: any[];
  center_id: any;
  test_name: any;
  diag_tests_id: any;
  price: any;
  conduct_doc_pay_id: any;
  discount: any;
  cost: any;
  doc_reg_id: any;
  getdoctortestfeearray: any[] = [];
  costprice: any;
  moduleList: any;
  editbutton: boolean = false;
  public newbutton: boolean = false;
  public deletebutton: boolean = false;
  public printbutton: boolean = false;
 public viewbutton: boolean = false;
  constructor(public http: HttpClient, public toastr: ToastrService, public httpP: Http) { }
  public DiagnosticRarray: any[] = [];
  public DiagnosticRinputname: string = '';
  public reagent_type_id: string = '';
  public diag_reagent_id: string;
  public masterType;
  public masterpageheading;
  public unityinputname;
  public sampletinputname;
  public iteminputname;
  public itemforminputname;
  public pharmainputname;
  public manufactinputname;
  public specimen_type_id;
  public diag_department_id;
  public tube_type_id;
  public UserInfo;
  public diaag;
  public masterurl;
  public samplearray = [];
  public samplet = [];
  public itemarray = [];
  public pharmaarray = [];
  public manufactarray = [];
  public itemformarray = [];
  public DiagnosticDinputnamearry = [];
  public samplelistarray = [];
  public samplelistinputname;
  public DiagnosticDarray = [];
  public DiagnosticDinputname;
  public Diagnostictarray = [];
  public Diagnostictinputname;
  public DiagnostictypeRarray = [];
  public color_code;
  public tube_size;
  public addflag: boolean = false;

  ngOnInit(): void {
    this.moduleList = Helper_Class.getmodulelist();
    this.changeProviderType()
    this.DiagnostictRarray = [];
    this.UserInfo = Helper_Class.getInfo();
    this.userinfo = Helper_Class.getInfo();
    this.user_id = this.userinfo.user_id;
    this.user_type = this.userinfo.user_type,

    this.masterType = Helper_Class.getReportType()
    if (this.masterType == 'Conductiondoctor') {
      this.masterpageheading = "Conduction doctor";
      var sendata = {
        diag_centre_id: this.diaag
      }
      this.getdoctortestfee();
      this.getDoctors();
      this.getTestList();

    }
    else {
      this.masterpageheading = "errror"
    }
    if (Helper_Class.getInfo().user_type == 'Admin') {
      this.editbutton = true;
      this.deletebutton = true;
      this.viewbutton = true;
      this.newbutton = true;

    } else {
      if (this.moduleList != undefined) {
        for (var i = 0; i < this.moduleList.length; i++) {
          if (this.moduleList[i].module_id == "147") {
            console.log("this is full module", JSON.stringify(this.moduleList[i]));
            if (this.moduleList[i].edit == "1") {
              this.editbutton = true;
            }
            if (this.moduleList[i].create == "1") {
              this.newbutton = true;
              
            }
            if (this.moduleList[i].delete == "1") {
              this.deletebutton = true;
              
            }
            if (this.moduleList[i].print == "1") {
              this.printbutton = true;
            }
            if (this.moduleList[i].view == "1") {
              this.viewbutton = true;
            }
          }
        }
      }
    }
  }

  changeProviderType() {
    if (Helper_Class.getInfo().user_type == 'Admin') {
      this.hptl_clinic_id = Helper_Class.getInfo().hptl_clinic_id;
      this.diaag = Helper_Class.getInfo().diag_centre_id;
      this.pharma_id = Helper_Class.getInfo().pharma_id;
      this.provider_type = "Admin"

    } else if (Helper_Class.getInfo().user_type == "front-desk") {
      this.hptl_clinic_id = Helper_Class.getInfo().hptl_clinic_id;
      this.diaag = Helper_Class.getInfo().diag_centre_id;
      this.pharma_id = Helper_Class.getInfo().pharma_id;
      this.provider_type = "front-desk"

    } else if (Helper_Class.getInfo().user_type == "doctor") {
      this.hptl_clinic_id = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.diaag = Helper_Class.getInfo().hospitals[0].diag_centre_id;
      this.pharma_id = Helper_Class.getInfo().hospitals[0].pharma_id;
      this.provider_type = "doctor";

    } else if (Helper_Class.getInfo().user_type == "pharmacy") {
      this.hptl_clinic_id = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.diaag = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.pharma_id = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.provider_type = "pharmacy";

    } else if (Helper_Class.getInfo().user_type == "diagnosis") {
      this.hptl_clinic_id = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.diaag = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.pharma_id = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.provider_type = "diagnosis";

    } else if (Helper_Class.getInfo().user_type == "physio") {
      this.hptl_clinic_id = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.diaag = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.pharma_id = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.provider_type = "physio";

    } else if (Helper_Class.getInfo().user_type == "nurse") {
      this.hptl_clinic_id = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.diaag = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.pharma_id = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.provider_type = "physio";
    }
  }
  
  // Conduction doctor
  getdoctortestfee() {
    this.getdoctortestfeearray = []
    var sendata = {
      diag_centre_id: this.diaag
    };
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/gtcd', sendata,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          var getdoctortest = obj.conduct_doc;
          for (var i = 0; i < getdoctortest.length; i++) {
            this.getdoctortestfeearray.push({
              conduct_doc_pay_id: getdoctortest[i].conduct_doc_pay_id,
              cost: getdoctortest[i].cost,
              doc_reg_id: getdoctortest[i].doc_reg_id,
              first_name: getdoctortest[i].first_name,
              last_name: getdoctortest[i].last_name,
              status: getdoctortest[i].status,
              test_name: getdoctortest[i].test_name,
              diag_tests_id: getdoctortest[i].diag_tests_id
            })
          }
        },

        error => {
          this.toastr.error('error')
        }
      )
  }

  getDoctors() {
    this.doctorList = [];
    var send_data = {
      country: 'IN',
      doc_reg_id: this.user_id,
      type: this.user_type,
      hptl_clinic_id: this.hptl_clinic_id,
    };
    
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(ipaddress.getIp + 'adm/docbyhosp', send_data, { headers: headers })
      .subscribe(
        (response) => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log('check---' + JSON.stringify(obj));
          if (obj != null) {
            for (var i = 0; i < obj.doctors.length; i++) {
              var doctorName = '';
              var fname, mname, lname;
              if (
                obj.doctors[i].middle_name != '' &&
                obj.doctors[i].middle_name != undefined
              ) {
                doctorName =
                  obj.doctors[i].first_name +
                  ' ' +
                  obj.doctors[i].middle_name +
                  ' ' +
                  obj.doctors[i].last_name;
                fname = obj.doctors[i].first_name;
                mname = obj.doctors[i].middle_name;
                lname = obj.doctors[i].last_name;
              } else {
                doctorName =
                  obj.doctors[i].first_name + ' ' + obj.doctors[i].last_name;
                fname = obj.doctors[i].first_name;
                lname = obj.doctors[i].last_name;
              }

              this.doctorList.push({
                Doc_Name: doctorName,
                Doc_ID: obj.doctors[i].prov_id,
                type: this.userinfo.user_type,
              });
            }
            this.doctorid = this.userinfo.user_id;
            this.user_id = this.userinfo.user_id;
            for (var i = 0; i < this.doctorList.length; i++) {
              if (this.doctorList[i].Doc_ID == this.userinfo.user_id) {

              }
            }
          }
        },
        (error) => {
        }
      );
  }

  getTestList() {
    this.Diag_Test_list_Data = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.httpP.post(ipaddress.getIp.toString() + 'diagnosiscontroller/tbcd/',
      JSON.stringify({
        diag_id: this.diaag
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          if (obj.diag_tests != null) {
            this.Diag_Test_list_Data = obj.diag_tests;
            this.Diag_Test_list_Data.push({
              test_name: obj.test_name,
              diag_tests_id: obj.diag_tests_id,
              cost: obj.price,
            });
          }
        }, error => { });
  }

  deleteConductiondoctor(diag_tests_id, doc_reg_id, conduct_doc_pay_id, cost, data) {
    var datatobackend=[];
    datatobackend.push({
      diag_tests_id: diag_tests_id,
      doc_reg_id: doc_reg_id,
      conduct_doc_pay_id:conduct_doc_pay_id,
      cost: cost,
      status: "0",
    });
    var send_data ={
      "conduct_doc":datatobackend
    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/stcd', send_data,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log("datatobackend"+JSON.stringify(obj));
          this.toastr.success('Successfully save data');
          this.getdoctortestfee();

        }, error => {
          this.toastr.warning("error");
        }
      );
  }

  saveConductiondoctor(diag_tests_id, doc_reg_id, conduct_doc_pay_id, cost, data) {
    var datatobackend=[];
    if(this.doctorid.length !=0){
      for(var i=0;i<this.doctorid.length;i++) {
        //if (conduct_doc_pay_id != undefined) {
          if (this.getdoctortestfeearray.length != 0) {
            for (let i = 0; i < this.getdoctortestfeearray.length; i++) {
              if (this.getdoctortestfeearray[i].doc_reg_id == doc_reg_id && this.getdoctortestfeearray[i].diag_tests_id == this.changetestid) {
                this.toastr.error("Test has already been assigned to Doctor");
                this.addflag = true;
                break;
              } 
            }
            if(this.addflag == false) {
              datatobackend.push({
                diag_tests_id: this.changetestid,
                doc_reg_id: this.doctorid[i],
                cost: this.costprice,
                status: "1",
              });
            }

          } else {
            datatobackend.push({
              diag_tests_id: this.changetestid,
              doc_reg_id: this.doctorid[i],
              cost: this.costprice,
              status: "1",
            });
          }
        //}
      }
    }

    //if (data == 'save') {
      // if (this.addflag == false) {
      //   datatobackend.push({
      //     conduct_doc_pay_id:this.conduct_doc_pay_id,
      //     diag_tests_id: this.changetestid,
      //     doc_reg_id: this.doctorid,
      //     cost: this.costprice,
      //     status: "1",
      //   });
      // }
      // if (this.addflag == true) {
      //   datatobackend = {
      //     diag_tests_id: this.changetestid,
      //     doc_reg_id: this.doctorid,
      //     cost: this.costprice,
      //     status: "1",
      //   };
      // }
    //}
    //console.log("the_frant ", datatobackend)
    if(this.addflag == false) {
      var send_data={
        "conduct_doc":datatobackend
      }
      var headers = new HttpHeaders();

      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/stcd', send_data,
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));
            console.log("datatobackend"+JSON.stringify(obj));
            this.toastr.success('Successfully save data');
            this.getdoctortestfee();
  
          }, error => {
            this.toastr.warning("error");
          }
        );
      conduct_doc_pay_id = '';
      this.changetestid = '';
      this.doctorid = '';
      this.costprice = '';
    }
  }

  public objectvalue;
  editConductiondoctor(diag_tests_id, cost, conduct_doc_pay_id, doc_reg_id) {
    this.conduct_doc_pay_id = conduct_doc_pay_id;
    this.changetestid = diag_tests_id;
    this.costprice = cost;
    this.doctorid = doc_reg_id;
    for (var i = 0; i < this.getdoctortestfeearray.length; i++) {
      if (this.getdoctortestfeearray[i].conduct_doc_pay_id == conduct_doc_pay_id) {
        this.getdoctortestfeearray.splice(i, 1);
        break;
      }
    }
  }
}


