<div mat-dialog-title class="popup_border_icd">
  <h5 class="header_txt">Medicine Details</h5>
  <div mat-dialog-actions>
    <img src="../../../assets/img/close_icon.png" width="20px" (click)="ClosePopup()" height="20px" id="close_prev" />
  </div>
</div>
<div mat-dialog-content class="icd_content">
  <div class="container">
    <div [hidden]="nodataapp" class="nodata">No medicines found</div>
    <div class="row" style="margin-top: 7px;">
      <div class="col-12 col-sm-12 col-lg-12 col-md-12 col-xl-12 text_style">
        <table *ngIf="listProducts.length" mdbTable datatable [dtOptions]="dtOptions"
          class="table table-nowrap table-sm dataTable previous_prescription">
          <thead>
            <tr>
              <th style="width:133px">{{'Medicine type'}}</th>
              <th>{{'Medicine name'}}
                <!-- <input matInput type="text" class="ipcss iplen" id="searchmed_name" placeholder="Search Here" (keyup)="searchMedName()"/> -->
              </th>
              <th style="width:133px">{{'Duration'}}</th>
              <th>{{'Intake session'}}</th>
              <th style="width:133px">{{'Frequency'}}</th>
              <th>{{'Intake'}}</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let product of listProducts">
              <td>{{product.med_typetxt}}</td>
              <td style="text-align: left;">{{product.drug_name}}</td>
              <td>{{product.days}} {{product.period }}</td>
              <td>{{product.every_six}}</td>
              <td>{{product.frequency }}</td>
              <td style="text-align: left;">{{product.dure_txt_table}} ({{product.drug_intake }})</td>
              <td>
                <mat-checkbox color="primary" (change)="selectMed(product, product.drug_id,product.rowid,$event.checked)"
                  [(ngModel)]="product.checked"></mat-checkbox>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
 
</div>
<div mat-dialog-actions align="end" style="width: auto;right: 19px;">
  <a [hidden]="saveflag">
    <button id="add_btn"  [mat-dialog-close]="selectedList" cdkFocusInitial mat-button>
      <img src="../../../assets/ui_icons/buttons/Done_button.svg" class="addimgbtn_icd" />
    </button></a>&nbsp;
</div>