import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Date_Formate } from '../../../assets/js/common.js';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { CommonDataService } from '../../providers/common-data.service';
import { FormControl } from '@angular/forms';
import { Helper_Class } from '../../helper_class';
import { Message_data } from '../../../assets/js/Message_data';
import { ClientViewService } from '../clientview/client-view.service';
import { MatDialog } from '@angular/material/dialog';
import { DropdownModelpageComponent } from '../../Doctor_module/dropdown-modelpage/dropdown-modelpage.component';
import { ipaddress } from '../../ipaddress';
@Component({
  selector: 'app-lifestyle-personal',
  templateUrl: './lifestyle-personal.component.html',
  styleUrls: ['./lifestyle-personal.component.css']
})
export class LifestylePersonalComponent implements OnInit {
  public toggFamilyFlag:boolean;
  public currentDate;
  public currentMaxDate;
  public relationsList: any;
  public specializationList: any;
  public mainConcernRetrv = [];
  public mainConcernMasterList = [];
  public medicalHistoryRetrv = [];
  public medicalHistoryMasterList = [];
  public presentIllnessMasterList = [];
  public presentIllnessRetrv = [];
  public bodytTypeList = [];
  public childHabitMasterList = [];
  public childHabitRetrv = [];
  public infectionList = [];
  public painFreqlist = [];
  public charOfPainList = [];
  public menstrualStageList = [];
  public contraceptiveList = [];
  public exerRoutineList = [];
  public alcMissingList = [];
  public alcMix = [];
  public alcQuantityList = [];
  public alcQuantity = [];
  public vaccineListRetrv=[];
  public cognitiveListRetrv=[];
  public languageListRetrv=[];
  public childDevelopmenListRetrv=[];
  public presentIllness;
  public clientRegId;
  public relationId;
  public subRelId;
  public relationName: string;
  public splId: string;
  public currentOccupation: string;
  public maritialStatus: string;
  public physicalActivities: string;
  public mainConcern;
  public pastMedical: string;
  public medhistflag: string;
  public bodyType: string;
  public height;
  public heightMeasure: string;
  public weight;
  public weightMeasure: string;
  public gender;
  public age;
  public exerRoutine;
  public anyMedicine: string;
  public medicine: string;
  public feelStress: string;
  public symptom: string;
  public hdisorder: string;
  public treat: string;
  public hosp: string;
  public hospDetails: string;
  public dietHabits: string;
  public childHabits: string;
  public fastingHalfhour: string;
  public fastingOnehour: string;
  public fastingOnehalfhour: string;
  public postprandial: string;
  public HbA1c: string;
  public drugs;
  public sexInfectionId;
  public sendReasonForDiscont;
  public sexOthdata;
  public stageId;
  public sendMensStageDate;
  public sendLastMensDate;
  public sendMensFreqLength;
  public sendMensPain;
  public sendMensTimeOfPain;
  public sendCharOfPainId;
  public sendMensDisorder;
  public dc;
  public sendDC;
  public ovarianSurgery;
  public sendOvarianSurgery;
  public hysteroscopy;
  public sendHysteroscopy;
  public infertilitySurgery;
  public sendInfertilitySurgery;
  public leftCystRemoved;
  public sendLeftCystRemoved;
  public rightCystRemoved;
  public sendRightCystRemoved;
  public tuboplasty;
  public sendTuboplasty;
  public leftOvaryRemoved;
  public sendLeftOvaryRemoved;
  public tubalLigation;
  public sendTubalLigation;
  public rightOvaryRemoved;
  public sendRightOvaryRemoved;
  public laparoscopy;
  public sendLaparoscopy;
  public hysterectomyVaginal;
  public sendHysterectomyVaginal;
  public hysterectomyAbdominal;
  public sendHysterectomyAbdominal;
  public myomectomy;
  public sendMyomectomy;
  public bladderRepair;
  public sendBladderRepair;
  public others;
  public surgeHistId;
  public surgeryFlag;
  public infection;
  public sendmedicalHistory;
  public sendpainFreqlistId;
  public drugAllergy;
  public stageDesc: string;
  public mensStageDate: string;
  public menstrualPeriod: string;
  public lastMensPeriod: string;
  public cycleLength: string;
  public frequencyLength: string;
  public eddRedd: string
  public trimester: string;
  public noOfWeeks;
  public menstrualPain: string;
  public mensTimeOfPain: string;
  public mensCharOfPain: string;
  public mensDisorder: string;
  public mensNoOfDays: string;
  public mensPainFreq: string;
  public sexualStatus: string;
  public sexualDifficulties: string;
  public sexualTypeOfPain: string;
  public sexualSatisfaction: string;
  public sexualActive: string;
  public typeOfSex: string;
  public contracept: string;
  public typeOfContracept: string;
  public reasonForDiscont: string;
  public complicationInSex: string;
  public otherIssueSex: string;
  public alcholDuration: string;
  public alcholFrequency: string;
  public smokeDuration: string;
  public smokeFrequency: string;
  public gutkaDuration: string;
  public gutkaFrequency: string;
  public exerciseDuration: string;
  public sendSexualSatisfaction: string;
  public sendSexualActive: string;
  public sendTypeOfSex: string;
  public sendContracept: string;
  public stressFactor: string;
  public sendContraceptiveId;
  public keys;
  public medicineFlag: boolean;
  public currentOccupationFlag: boolean;
  public maritialFlag: boolean;
  public physActivitiesFlag: boolean;
  public mainConcernFlag: boolean;
  public drugRequiredFlag: boolean;
  public drugDisabledFlag: boolean;
  public stressFactorFlag: boolean;
  public bodyTypeFlag: boolean;
  public anyMedFlag: boolean;
  public feelStressFlag: boolean;
  public hdisorderFlag: boolean;
  public hospFlag: boolean;
  public hospDisabledFlag: boolean;
  public prevHospDisabledFlag: boolean;
  public hdisorderDisabledFlag: boolean;
  public hDisTreatDisabledFlag: boolean;
  public sexualStatusflag: boolean;
  public typeofpainFlag: boolean;
  public sexualSatisfactionFlag:boolean;
  public difficultiesflag: boolean;
  public sexualActiveFlag: boolean;
  public typeOfSexFlag: boolean;
  public contraceptFlag: boolean;
  public typeOfContraceptFlag: boolean;
  public infectionFlag: boolean;
  public reasonForDiscontFlag: boolean;
  public otherIssueSexFlag: boolean;
  public menstrualPainFlag: boolean;
  public mensTimeOfPainFlag: boolean;
  public mensCharOfPainFlag: boolean;
  public mensDisorderFlag: boolean;
  public mensNoOfDaysFlag: boolean;
  public mensPainFreqFlag: boolean;
  public complicationInSexDisabledFlag: boolean;
  public statdescRequiredFlag: boolean;
  public mensTimeOfPainRequiredFlag: boolean;
  public mensCharOfPainRequiredFlag: boolean;
  public mensTimeOfPainDisabledFlag: boolean;
  public mensCharOfPainDisabledFlag: boolean;
  public mensNoOfDaysRequiredFlag: boolean;
  public frequencyRequiredFlag: boolean;
  public mensNoOfDaysDisabledFlag: boolean;
  public freuencyDisabledFlag: boolean;
  public difficultDisabledFlag: boolean;
  public complicationInSexFlag: boolean;
  public stageDisabledFlag: boolean;
  public menstrualPeriodDisabledFlag: boolean;
  public sexualStatusDisabledFlag: boolean;
  public mensDisorderDisabledFlag: boolean;
  public maritialDisabledFlag: boolean;
  public cycleDisabledFlag: boolean;
  public frequencyLengthDisabledFlag: boolean;
  public menstrualPainDisbaledFlag: boolean;
  public feelStressDisabledFlag: boolean;
  public drugAllergyDisabledFlag: boolean;
  public sexualSatDisabledFlag: boolean;
  public sexualActiveDisabledFlag: boolean;
  public typeOfSexDisabledFlag: boolean;
  public contraceptDisabledFlag: boolean;
  public infectDisabledFlag: boolean;
  public otherIssueSexDisabledFlag: boolean;
  public sexTypeOfPainDisabledFlag: boolean;
  public typeOfContraceptDisabledFlag: boolean;
  public reasonForDiscontDisabledFlag: boolean;
  public typeOfContraceptRequiredFlag: boolean;
  public reasonForDiscontRequiredFlag: boolean;
  public fasting: boolean;
  public ogtt: boolean;
  public fastOneDisabledFlag: boolean;
  public fastOnehalfDisabledFlag: boolean;
  public toggAlchol = "No";
  public toggSmoking = "No";
  public toggGutka = "No";
  public toggExercise = "No";
  public toggFamily = "No";
  public toggleAlcholFlag: boolean;
  public toggleSmokingFlag: boolean;
  public toggGutkaFlag: boolean;
  public toggExerciseFlag: boolean;
  public alcholShowFlag: boolean;
  public smokeShowFlag: boolean;
  public gutkaShowFlag: boolean;
  public exerciseShowFlag: boolean;
  public pastMedicalFlag: boolean;
  public presentIllnessFlag: boolean;
  public drugAllergyFlag: boolean;
  public drugsFlag: boolean;
  public childHabitsFlag: boolean;
  public observationFlag: boolean;
  public surgicalHistoryFlag: boolean;
  public bloodGlucoseFlag: boolean;
  public habitualFlag: boolean;
  public pediaFlag: boolean;
  public sexualStatusFlag:boolean;
  public sendsexualStatusFlag: string;
  public mensStageDateFlag: string;
  public sendPresentIllnessFlag: string;
  public menstrualFlag: boolean;
  public sexualHistoryFlag: boolean;
  public pediaVaccineFlag: boolean;
  public pediaDevlpFlag: boolean;
  public stressRequiredFlag;
  public saveFlag: boolean;
  public updateFlag: boolean;
  public develCog1To2monthData = [];
  public develCog3monthData = [];
  public develCog4To5monthData = [];
  public develCog6To7monthData = [];
  public develCog8To9monthData = [];
  public develCog10To12monthData = [];
  public develCog15monthData = [];
  public develCog18monthData = [];
  public develCog24monthData = [];
  public develCog3yearData = [];
  public develCog4yearData = [];
  public develCog5yearData = [];
  public develLang1To2monthData = [];
  public develLang3monthData = [];
  public develLang4To5monthData = [];
  public develLang6To7monthData = [];
  public develLang8To9monthData = [];
  public develLang10To12monthData = [];
  public develLang15monthData = [];
  public develLang18monthData = [];
  public develLang24monthData = [];
  public develLang3yearData = [];
  public develLang4yearData = [];
  public develLang5yearData = [];
  public mainInfant = [];
  public oneYearDecideTeethRemarks;
  public oneYearDecideTeeth;
  public condition;
  public oneYearDentalCaries;
  public dentalCaries;
  public oneYearDentalCariesRemarks;
  public oneYearObjectPerformance;
  public objectPerformance;
  public oneYearObjectRemarks;
  public oneYearSleepPattern;
  public sleepPattren;
  public oneYearSleepRemarks;
  public twoYearDentionTeeth;
  public twoYearDention;
  public twoYearDentionRemarks;
  public twoYearMoralDevlp;
  public twoYearMoral;
  public twoYearMoralRemarks;
  public twoYearPhyschoSocial;
  public twoYearPhyscio;
  public twoYearPhyscoRemarks;
  public twoYearToiletTraining;
  public twoYearToiletRemarks;
  public twoYearToilet;
  public twoYearSleep;
  public twoYearSleeps;
  public twoYearSleepRemarks;
  public twoYearPlayRemarks;
  public twoYearPlayingPattren;
  public twoYearPlay;
  public threeYearDentionRemarks;
  public threeYearDentionTeeth;
  public threeDental;
  public threeYearPhyschoRemarks;
  public threeYearPhyschoSocial;
  public threePhysco;
  public threeYearMoralRemarks;
  public threeYearmoral;
  public threeMoral;
  public threeYearToiletRemarks;
  public threeYearToiletTraining
  public threeToilet;
  public threeYearSleepRemarks;
  public threeYearSleep;
  public threeSleep;
  public threeYearPlayingRemarks;
  public threeYearPlayingPattren;
  public threePlaying;
  public remark;
  public cognitiveList = [];
  public develpCog;
  public devlpCogThreeMon;
  public devlpCogFiveMon;
  public devlpCogSixToSevenMon;
  public devlpCogTenMon;
  public lanOneMon;
  public lanThreeMon;
  public languageList = [];
  public lanFiveMon;
  public lanSixMon;
  public lanEightMon;
  public lanTenMon;
  public devlpCogFifteeneMon;
  public devlpCogEighteenMon;
  public devlpCogtwenfourMon;
  public lanFifteenMon;
  public lanEightteenMon;
  public lanTwentfMon;
  public cogThreeYear;
  public cogFourYear;
  public cogFiveYear;
  public lanThreeYear;
  public lanFourYear;
  public lanFiveYear;
  public devlpCogEightMon;
  public cogOneDisabledFlag: boolean;
  public cogThreeDisabledFlag: boolean;
  public cogFiveDisabledFlag: boolean;
  public cogSixDisabledFlag: boolean;
  public cogEightDisabledFlag: boolean;
  public cogTenDisabledFlag: boolean;
  public devFifDisabledFlag: boolean;
  public devEighteenDisabledFlag: boolean;
  public devTwenDisabledFlag: boolean;
  public devThreeYearDisabledFlag: boolean;
  public devFourYearDisabledFlag: boolean;
  public devFiveYearDisabledFlag: boolean;
  public langOneDisabledFlag: boolean;
  public langThreeDisabledFlag: boolean;
  public langFourDisabledFlag: boolean;
  public langSixDisabledFlag: boolean;
  public langeightDisabledFlag: boolean;
  public langTenDisabledFlag: boolean;
  public langFifteenDisabledFlag: boolean;
  public langEighteenDisabledFlag: boolean;
  public langTwnFourDisabledFlag: boolean;
  public langThreeYearDisabledFlag: boolean;
  public langFourYearDisabledFlag: boolean;
  public langFiveYearDisabledFlag: boolean;
  public decideDisabledFlag: boolean;
  public dentalDisabledFlag: boolean;
  public objectDisabledFlag: boolean;
  public sleepDisabledFlag: boolean;
  public twoDenDisabledFlag: boolean;
  public twoMoralDisabledFlag: boolean;
  public twoPsychoDisabledFlag: boolean;
  public twoToiletDisabledFlag: boolean;
  public twoSleepDisabledFlag: boolean;
  public twoPlayDisabledFlag: boolean;
  public preDenDisabledFlag: boolean;
  public preMoralDisabledFlag: boolean;
  public prePyschoDisabledFlag: boolean;
  public preToiletDisabledFlag: boolean;
  public preSleepDisabledFlag: boolean;
  public prePlayDisabledFlag: boolean;
  public oneDecide;
  public oneYearCarRemarks;
  public objectRemark;
  public oneSleep;
  public twoDention;
  public twoMoral;
  public twoPhysco;
  public twoToilet;
  public twoSleep;
  public twoPlay;
  public threeDent;
  public threeMoralData;
  public threeSleepData;
  public threePlayingData;
  public threeToiletData;
  public threePhyschoData;
  public oneYearAccordianFlag: boolean;
  public twoYearAccordianFlag: boolean;
  public threeYearAccordianFlag: boolean;
  public cognitiveOneMonthFlag: boolean;
  public cognitiveThreeMonthFlag: boolean;
  public cognitiveFourMonthFlag: boolean;
  public cognitiveSixMonthFlag: boolean;
  public cognitiveEightMonthFlag: boolean;
  public cognitiveTenMonthFlag: boolean;
  public langSixFlag: boolean;
  public langOneFlag: boolean;
  public langFourFlag: boolean;
  public langThreeFlag: boolean;
  public lanEightFlag: boolean;
  public langTenFlag: boolean;
  public cogFifFlag: boolean;
  public cogEightFlag: boolean;
  public cogTwntyFlag: boolean;
  public langFifMonthFlag: boolean;
  public langEightMonthFlag: boolean;
  public langTwntyMonthFlag: boolean;
  public cogThreeFlag: boolean;
  public cogFourFlag: boolean;
  public cogFiveFlag: boolean;
  public langThreeYearFlag: boolean;
  public langFourYearFlag: boolean;
  public langFiveYearFlag: boolean;
  public mainVaccineData:any = [];
  public clientDob;
  public clientAge;
  public givenDateAge;
  public vaccineDate;
  public vaccineList = [];
  public dueDate;
  public giveDate;
  public duedate1;
  public giveDate1;
  public dueDate2;
  public giveDate2;
  public dueDate3;
  public giveDate3;
  public dueDate4;
  public giveDate4;
  public dueDate5;
  public giveDate5;
  public dueDate6;
  public giveDate6;
  public dueDate7;
  public giveDate7;
  public dueDate8;
  public giveDate8;
  public dueDate9;
  public giveDate9;
  public dueDate10;
  public giveDate10;
  public clientInfo;
  public refreshFlag:boolean = false;
  public panelOpenState1:boolean;
  public panelOpenState3:boolean;
  public panelOpenState2:boolean;
  public panelOpenState4:boolean;
  public panelOpenState5:boolean;
  constructor(public dialog:MatDialog,public clientservice:ClientViewService,public gservice:CommonDataService,public datepipe:DatePipe,public http:Http,public routes:ActivatedRoute,public router:Router,public toastr:ToastrService) { 
     this.observationFlag = true;
     this.pediaFlag = true;
     this.alcholShowFlag=true;
     this.smokeShowFlag=true;
     this.gutkaShowFlag=true;
     this.exerciseShowFlag=true;
     this.toggFamilyFlag = false;
     this.toggleAlcholFlag = false;
     this.toggleSmokingFlag = false;
     this.toggGutkaFlag = false;
     this.toggExerciseFlag = false;
     this.menstrualFlag = true;
     this.sexualHistoryFlag = true;
     this.pediaVaccineFlag = true;
     this.pediaDevlpFlag = true;
     this.relationId = "1";
     this.oneYearDecideTeeth = "yes";
     this.oneYearDentalCaries = "no";
     this.oneYearObjectPerformance = "yes";
     this.oneYearSleepPattern = "yes";
     this.twoYearDentionTeeth = "yes";
     this.twoYearMoralDevlp = "no";
     this.twoYearPhyschoSocial = "yes";
     this.twoYearToiletTraining = "yes";
     this.twoYearSleep = "yes";
     this.twoYearPlayingPattren = "yes";
     this.twoYearPlayingPattren = "yes";
     this.threeYearDentionTeeth = "yes";
     this.threeYearmoral = "no";
     this.threeYearPhyschoSocial = "yes";
     this.threeYearToiletTraining = "yes";
     this.threeYearSleep = "yes";
     this.threeYearPlayingPattren = "yes";
     this.heightMeasure = "cms";
     this.weightMeasure = "kgs";
     this.alcholDuration = "<1 Year";
     this.alcholFrequency = "<5";
     this.smokeDuration = "<1 Year";
     this.smokeFrequency = "<5";
     this.gutkaDuration = "<1 Year";
     this.gutkaFrequency = "<5";
     this.exerciseDuration = "15 Mins";
     this.saveFlag = false;
     this.updateFlag = true; 
  }
  ngOnInit(): void {
    this.clientInfo = Helper_Class.getClientInfo();
    this.gender = encrypt_decript.Decript(this.gservice.Client_login_detail_data.gender);
    this.clientAge = this.gservice.Client_login_detail_data.age;
    this.clientRegId= this.gservice.Client_login_detail_data.Client_Id;
    this.getRelationDetails();
    this.getSpecilizationType();
    this.getMedicalHistory();
    this.getPresentIllness();
    this.getBodyTypes();
    this.getInfection();
    this.getChildHabits();
    this.getFrequency();
    this.getCharacterOfpain();
    this.getStage();
    this.contraceptiveTypes();
    this.getExerciseRoutine();
    this.getMixingtype();
    this.getQuantity();
    this.getMainConcern();
    this.getCurrentDate()
    this.getClientDetails();
    this.displayFields();
    if(this.bodytTypeList.length != 0){
      this.bodyType = this.bodytTypeList[0].bodytype_name;
    }
  }
  splChange() {
    if (this.splId == "3") {
      this.currentOccupationFlag = false;
      this.maritialFlag = false;
      this.mainConcernFlag = false;
      this.observationFlag = false;
      this.pastMedicalFlag = false;
      this.presentIllnessFlag = false;
      this.ogtt = false;
      this.bloodGlucoseFlag = true;
      this.physActivitiesFlag = true;
      this.drugAllergyFlag = true;
      this.drugsFlag = true;
      this.drugDisabledFlag = true;
      this.hospFlag = true;
      this.bodyTypeFlag = false;
      this.anyMedFlag = false;
      this.stressFactorFlag = false;
      this.feelStressFlag = true;
      this.hdisorderFlag = false;
      this.hDisTreatDisabledFlag = false;
      this.hospDisabledFlag = false;
      this.fasting = true;
      this.fastOneDisabledFlag = true;
      this.fastOnehalfDisabledFlag = true;
      this.childHabitsFlag = true;
      this.surgicalHistoryFlag = true;
      this.habitualFlag = false;
      this.pediaFlag = true;
    } else if (this.splId == "8") {
      this.currentOccupationFlag = false;
      this.physActivitiesFlag = false;
      this.maritialFlag = true;
      this.mainConcernFlag = true;
      this.observationFlag = false;
      this.pastMedicalFlag = true;
      this.presentIllnessFlag = true;
      this.ogtt = false;
      this.bloodGlucoseFlag = true;
      this.drugAllergyFlag = true;
      this.drugsFlag = true;
      this.drugDisabledFlag = true;
      this.hospFlag = true;
      this.bodyTypeFlag = true;
      this.anyMedFlag = true;
      this.stressFactorFlag = true;
      this.feelStressFlag = true;
      this.hdisorderFlag = true;
      this.hDisTreatDisabledFlag = true;
      this.hospDisabledFlag = true;
      this.hospDisabledFlag = true;
      this.fasting = true;
      this.fastOneDisabledFlag = true;
      this.fastOnehalfDisabledFlag = true;
      this.childHabitsFlag = true;
      this.surgicalHistoryFlag = true;
      this.pediaFlag = true;
      this.habitualFlag = false;
    } else if (this.splId == "13") {
      this.currentOccupationFlag = false;
      this.physActivitiesFlag = false;
      this.maritialFlag = true;
      this.mainConcernFlag = false;
      this.observationFlag = false;
      this.mainConcernFlag = true;
      this.pastMedicalFlag = true;
      this.presentIllnessFlag = true;
      this.ogtt = false;
      this.bloodGlucoseFlag = false;
      this.drugAllergyFlag = true;
      this.drugsFlag = true;
      this.drugDisabledFlag = true;
      this.hospFlag = true;
      this.bodyTypeFlag = true;
      this.anyMedFlag = true;
      this.stressFactorFlag = false;
      this.feelStressFlag = true;
      this.hdisorderFlag = true;
      this.hDisTreatDisabledFlag = true;
      this.hospDisabledFlag = true;
      this.childHabitsFlag = true;
      this.surgicalHistoryFlag = true;
      this.pediaFlag = true;
      this.habitualFlag = false;
    } else if (this.splId == "11") {
      if (this.gender == "Female") {
        this.currentOccupationFlag = false;
        this.physActivitiesFlag = true;
        this.maritialFlag = false;
        this.mainConcernFlag = false;
        this.observationFlag = false;
        this.mainConcernFlag = true;
        this.pastMedicalFlag = false;
        this.presentIllnessFlag = true;
        this.ogtt = false;
        this.bloodGlucoseFlag = true;
        this.drugAllergyFlag = false;
        this.drugsFlag = false;
        this.drugDisabledFlag = true;
        this.hospFlag = true;
        this.bodyTypeFlag = true;
        this.anyMedFlag = true;
        this.stressFactorFlag = false;
        this.feelStressFlag = true;
        this.hdisorderFlag = true;
        this.hDisTreatDisabledFlag = true;
        this.hospDisabledFlag = true;
        this.childHabitsFlag = true;
        this.surgicalHistoryFlag = false;
        this.habitualFlag = false;
        this.pediaFlag = true;
      } else {
        this.toastr.error(Message_data.splOnlyForFemale)
      }
    } else if (this.splId == "10") {
      if (this.clientAge == undefined || this.clientAge <= 13) {
        this.mainConcernFlag = false;
        this.pastMedicalFlag = false;
        this.childHabitsFlag = false;
        this.pediaFlag = false;
        this.currentOccupationFlag = true;
        this.physActivitiesFlag = true;
        this.maritialFlag = true;
        this.observationFlag = true;
        this.mainConcernFlag = true;
        this.presentIllnessFlag = true;
        this.ogtt = true;
        this.bloodGlucoseFlag = true;
        this.drugAllergyFlag = true;
        this.drugsFlag = true;
        this.drugDisabledFlag = true;
        this.hospFlag = true;
        this.bodyTypeFlag = true;
        this.anyMedFlag = true;
        this.stressFactorFlag = true;
        this.feelStressFlag = true;
        this.hdisorderFlag = true;
        this.hDisTreatDisabledFlag = true;
        this.hospDisabledFlag = true;
        this.hospDisabledFlag = true;
        this.surgicalHistoryFlag = true;
        this.habitualFlag = true;
        this.getImmunizations();
      } else {
        this.toastr.error(Message_data.splOnlyForChild)
      }
    } else {
      this.currentOccupationFlag = false;
      this.physActivitiesFlag = false;
      this.maritialFlag = true;
      this.mainConcernFlag = false;
      this.observationFlag = false;
      this.mainConcernFlag = true;
      this.pastMedicalFlag = true;
      this.presentIllnessFlag = true;
      this.ogtt = false;
      this.bloodGlucoseFlag = false;
      this.drugAllergyFlag = true;
      this.drugsFlag = true;
      this.drugDisabledFlag = true;
      this.hospFlag = true;
      this.bodyTypeFlag = true;
      this.anyMedFlag = true;
      this.stressFactorFlag = false;
      this.feelStressFlag = true;
      this.hdisorderFlag = true;
      this.hDisTreatDisabledFlag = true;
      this.hospDisabledFlag = true;
      this.hospDisabledFlag = true;
      this.childHabitsFlag = true;
      this.surgicalHistoryFlag = true;
      this.habitualFlag = false;
      this.pediaFlag = true;
    }
  }
  selectVaccGivenDate(key_txt, index_txt, type, selected_date, vaccine_id, due_date) {
    this.mainVaccineData[key_txt][index_txt].given_date = Date_Formate(selected_date);
    this.immunizationGivenDateChange(key_txt, Date_Formate(selected_date), vaccine_id, due_date)
  }
  selectVaccDueDate(key_txt,date, index_txt, type) {
     this.mainVaccineData[key_txt][index_txt].due_date = Date_Formate(date);
  }
  immunizationGivenDateChange(Age_txt, selected_date, vaccine_id, due_date) {
    if (due_date != undefined && due_date != null) {
      var New_date = new Date(Date_Formate(selected_date));
      if (Age_txt == "15-16 years") {
      } else if (Age_txt == "10 years") {
        New_date.setFullYear(New_date.getFullYear() + 5);
        this.vaccineDate = new Date(New_date.getTime() + ((1) * (24 * 60 * 60 * 1000)));
        for (var i = 0; i < this.mainVaccineData["15-16 years"].length; i++) {
          var d = new Date(this.vaccineDate);
          this.mainVaccineData["15-16 years"][i].due_date = this.datepipe.transform(this.vaccineDate, 'dd-MM-yyyy');
        }
      } else if (Age_txt == "5 years") {
        New_date.setFullYear(New_date.getFullYear() + 5);
        this.vaccineDate = new Date(New_date.getTime() + ((1) * (24 * 60 * 60 * 1000)));
        for (var i = 0; i < this.mainVaccineData["10 years"].length; i++) {
          var d = new Date(this.vaccineDate);
          this.mainVaccineData["10 years"][i].due_date = this.datepipe.transform(this.vaccineDate, 'dd-MM-yyyy');
        }
      } else if (Age_txt == "2 years") {
        New_date.setFullYear(New_date.getFullYear() + 3);
        this.vaccineDate = new Date(New_date.getTime() + ((1) * (24 * 60 * 60 * 1000)));
        for (var i = 0; i < this.mainVaccineData["5 years"].length; i++) {
          var d = new Date(this.vaccineDate);
          this.mainVaccineData["5 years"][i].due_date = this.datepipe.transform(this.vaccineDate, 'dd-MM-yyyy');
        }
      } else if (Age_txt == "15 - 18 months") {
        New_date.setMonth(New_date.getMonth() + 8);
        this.vaccineDate = new Date(New_date.getTime() + ((1) * (24 * 60 * 60 * 1000)));
        for (var i = 0; i < this.mainVaccineData["2 years"].length; i++) {
          var d = new Date(this.vaccineDate);
          this.mainVaccineData["2 years"][i].due_date = this.datepipe.transform(this.vaccineDate, 'dd-MM-yyyy');
        }
      } else if (Age_txt == "9 months") {
        New_date.setMonth(New_date.getMonth() + 6);
        this.vaccineDate = new Date(New_date.getTime() + ((1) * (24 * 60 * 60 * 1000)));
        for (var i = 0; i < this.mainVaccineData["15 - 18 months"].length; i++) {
          var d = new Date(this.vaccineDate);
          this.mainVaccineData["15 - 18 months"][i].due_date = this.datepipe.transform(this.vaccineDate, 'dd-MM-yyyy');
        }
      } else if (Age_txt == "6 months") {
        New_date.setMonth(New_date.getMonth() + 3);
        this.vaccineDate = new Date(New_date.getTime() + ((1) * (24 * 60 * 60 * 1000)));
        for (var i = 0; i < this.mainVaccineData["9 months"].length; i++) {
          var d = new Date(this.vaccineDate);
          this.mainVaccineData["9 months"][i].due_date = this.datepipe.transform(this.vaccineDate, 'dd-MM-yyyy');
        }
      } else if (Age_txt == "14 weeks") {
        this.vaccineDate = new Date(New_date.getTime() + ((86) * (24 * 60 * 60 * 1000)));
        for (var i = 0; i < this.mainVaccineData["6 months"].length; i++) {
          var d = new Date(this.vaccineDate);
          this.mainVaccineData["6 months"][i].due_date = this.datepipe.transform(this.vaccineDate, 'dd-MM-yyyy');
        }
      } else if (Age_txt == "10 weeks") {
        this.vaccineDate = new Date(New_date.getTime() + ((29) * (24 * 60 * 60 * 1000)));
        for (var i = 0; i < this.mainVaccineData["14 weeks"].length; i++) {
          var d = new Date(this.vaccineDate);
          this.mainVaccineData["14 weeks"][i].due_date = this.datepipe.transform(this.vaccineDate, 'dd-MM-yyyy');
        }
      } else if (Age_txt == "6 weeks") {
        this.vaccineDate = new Date(New_date.getTime() + ((29) * (24 * 60 * 60 * 1000)));
        for (var i = 0; i < this.mainVaccineData["10 weeks"].length; i++) {
          var d = new Date(this.vaccineDate);
          this.mainVaccineData["10 weeks"][i].due_date = this.datepipe.transform(this.vaccineDate, 'dd-MM-yyyy');
        }
      } else if (Age_txt == "Birth") {
        this.vaccineDate = new Date(New_date.getTime() + ((43) * (24 * 60 * 60 * 1000)));
        for (var i = 0; i < this.mainVaccineData["6 weeks"].length; i++) {
          var d = new Date(this.vaccineDate);
          this.mainVaccineData["6 weeks"][i].due_date = this.datepipe.transform(this.vaccineDate, 'dd-MM-yyyy');
        }
      }
    } else {
      if (Age_txt == "Birth") {
        for (var i = 0; i < this.mainVaccineData.Birth.length; i++) {
          if (vaccine_id == this.mainVaccineData.Birth[i].ped_vaccine_age_id) {
            this.mainVaccineData.Birth[i].given_date = undefined;
            break;
          }
        }
      } else if (Age_txt == "6 weeks") {
        for (var i = 0; i < this.mainVaccineData["6 weeks"].length; i++) {
          if (vaccine_id == this.mainVaccineData["6 weeks"][i].ped_vaccine_age_id) {
            this.mainVaccineData["6 weeks"][i].given_date = undefined;
            break;
          }
        }
      } else if (Age_txt == "10 weeks") {
        for (var i = 0; i < this.mainVaccineData["10 weeks"].length; i++) {
          if (vaccine_id == this.mainVaccineData["10 weeks"][i].ped_vaccine_age_id) {
            this.mainVaccineData["10 weeks"][i].given_date = undefined;
            break;
          }
        }
      } else if (Age_txt == "14 weeks") {
        for (var i = 0; i < this.mainVaccineData["14 weeks"].length; i++) {
          if (vaccine_id == this.mainVaccineData["14 weeks"][i].ped_vaccine_age_id) {
            this.mainVaccineData["14 weeks"][i].given_date = undefined;
            break;
          }
        }
      } else if (Age_txt == "6 months") {
        for (var i = 0; i < this.mainVaccineData["6 months"].length; i++) {
          if (vaccine_id == this.mainVaccineData["6 months"][i].ped_vaccine_age_id) {
            this.mainVaccineData["6 months"][i].given_date = undefined;
            break;
          }
        }
      } else if (Age_txt == "9 months") {
        for (var i = 0; i < this.mainVaccineData["9 months"].length; i++) {
          if (vaccine_id == this.mainVaccineData["9 months"][i].ped_vaccine_age_id) {
            this.mainVaccineData["9 months"][i].given_date = undefined;
            break;
          }
        }
      } else if (Age_txt == "15 - 18 months") {
        for (var i = 0; i < this.mainVaccineData["15 - 18 months"].length; i++) {
          if (vaccine_id == this.mainVaccineData["15 - 18 months"][i].ped_vaccine_age_id) {
            this.mainVaccineData["15 - 18 months"][i].given_date = undefined;
            break;
          }
        }
      } else if (Age_txt == "2 years") {
        for (var i = 0; i < this.mainVaccineData["2 years"].length; i++) {
          if (vaccine_id == this.mainVaccineData["2 years"][i].ped_vaccine_age_id) {
            this.mainVaccineData["2 years"][i].given_date = undefined;
            break;
          }
        }
      } else if (Age_txt == "5 years") {
        for (var i = 0; i < this.mainVaccineData["5 years"].length; i++) {
          if (vaccine_id == this.mainVaccineData["5 years"][i].ped_vaccine_age_id) {
            this.mainVaccineData["5 years"][i].given_date = undefined;
            break;
          }
        }
      } else if (Age_txt == "10 years") {
        for (var i = 0; i < this.mainVaccineData["10 years"].length; i++) {
          if (vaccine_id == this.mainVaccineData["10 years"][i].ped_vaccine_age_id) {
            this.mainVaccineData["10 years"][i].given_date = undefined;
            break;
          }
        }
      } else if (Age_txt == "15-16 years") {
        for (var i = 0; i < this.mainVaccineData["15-16 years"].length; i++) {
          if (vaccine_id == this.mainVaccineData["15-16 years"][i].ped_vaccine_age_id) {
            this.mainVaccineData["15-16 years"][i].given_date = undefined;
            break;
          }
        }
      }
      this.toastr.error(Message_data.sltDueDate);
    }
  }
  displayFields(){
    if(this.clientAge <= 13){
      if(this.gender =="Male"){
        this.currentOccupationFlag = true;
        this.maritialFlag = true;
        this.mainConcernFlag = false;
        this.observationFlag = false;
        this.pastMedicalFlag = false;
        this.presentIllnessFlag = false;
        this.ogtt = false;
        this.bloodGlucoseFlag = false;
        this.physActivitiesFlag = false;
        this.drugAllergyFlag = false;
        this.drugsFlag = false;
        this.drugDisabledFlag = false;
        this.hospFlag = false
        this.bodyTypeFlag = false;
        this.anyMedFlag = false;
        this.stressFactorFlag = false;
        this.feelStressFlag = true;
        this.hdisorderFlag = false;
        this.hDisTreatDisabledFlag = false;
        this.hospDisabledFlag = false;
        this.fasting = true;
        this.fastOneDisabledFlag = true;
        this.fastOnehalfDisabledFlag = true;
        this.childHabitsFlag = false;
        this.surgicalHistoryFlag = true;
        this.habitualFlag = true;
        this.pediaFlag = false;
      } else {
        this.currentOccupationFlag = true;
        this.maritialFlag = true;
        this.mainConcernFlag = false;
        this.observationFlag = false;
        this.pastMedicalFlag = false;
        this.presentIllnessFlag = false;
        this.ogtt = false;
        this.bloodGlucoseFlag = false;
        this.physActivitiesFlag = false;
        this.drugAllergyFlag = false;
        this.drugsFlag = false;
        this.drugDisabledFlag = false;
        this.hospFlag = false
        this.bodyTypeFlag = false;
        this.anyMedFlag = false;
        this.stressFactorFlag = false;
        this.feelStressFlag = true;
        this.hdisorderFlag = false;
        this.hDisTreatDisabledFlag = false;
        this.hospDisabledFlag = false;
        this.fasting = true;
        this.fastOneDisabledFlag = true;
        this.fastOnehalfDisabledFlag = true;
        this.childHabitsFlag = false;
        this.surgicalHistoryFlag = false;
        this.habitualFlag = true;
        this.pediaFlag = false;
        this.sexualHistoryFlag=true;
      }
    } else {
      if(this.gender =="Male"){
        this.currentOccupationFlag = false;
        this.maritialFlag = false;
        this.mainConcernFlag = false;
        this.observationFlag = false;
        this.pastMedicalFlag = false;
        this.presentIllnessFlag = false;
        this.ogtt = false;
        this.bloodGlucoseFlag = false;
        this.physActivitiesFlag = false;
        this.drugAllergyFlag = false;
        this.drugsFlag = false;
        this.drugDisabledFlag = false;
        this.hospFlag = false
        this.bodyTypeFlag = false;
        this.anyMedFlag = false;
        this.stressFactorFlag = false;
        this.feelStressFlag = false;
        this.hdisorderFlag = false;
        this.hDisTreatDisabledFlag = false;
        this.hospDisabledFlag = false;
        this.fasting = false;
        this.fastOneDisabledFlag = false;
        this.fastOnehalfDisabledFlag = false;
        this.childHabitsFlag = false;
        this.surgicalHistoryFlag = true;
        this.habitualFlag = false;
        this.pediaFlag = true;
      } else {
        this.currentOccupationFlag = false;
        this.maritialFlag = false;
        this.mainConcernFlag = false;
        this.observationFlag = false;
        this.pastMedicalFlag = false;
        this.presentIllnessFlag = false;
        this.ogtt = false;
        this.bloodGlucoseFlag = false;
        this.physActivitiesFlag = false;
        this.drugAllergyFlag = false;
        this.drugsFlag = false;
        this.drugDisabledFlag = false;
        this.hospFlag = false
        this.bodyTypeFlag = false;
        this.anyMedFlag = false;
        this.stressFactorFlag = false;
        this.feelStressFlag = false;
        this.hdisorderFlag = false;
        this.hDisTreatDisabledFlag = false;
        this.hospDisabledFlag = false;
        this.fasting = false;
        this.fastOneDisabledFlag = false;
        this.fastOnehalfDisabledFlag = false;
        this.childHabitsFlag = false;
        this.surgicalHistoryFlag = false;
        this.habitualFlag = false;
        this.menstrualFlag = false;
        this.sexualHistoryFlag = false;
        this.pediaFlag = true;
      }
    }
  }
  getCognitiveDevelopment() {
    this.develCog1To2monthData = [];
    this.develCog3monthData = [];
    this.develCog4To5monthData = [];
    this.develCog6To7monthData = [];
    this.develCog8To9monthData = [];
    this.develCog10To12monthData = [];
    this.develCog15monthData = [];
    this.develCog18monthData = [];
    this.develCog24monthData = [];
    this.develCog3yearData = [];
    this.develCog4yearData = [];
    this.develCog5yearData = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'pedia/cdev',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj["1-2 months"] != null) {
            this.remark = "";
            for (var i = 0; i < obj["1-2 months"].length; i++) {
              this.develCog1To2monthData.push({
                Cognitive_ID: obj["1-2 months"][i].cognitive_id,
                Description: obj["1-2 months"][i].description,
                Yes: true,
                No: false,
                remarks: this.remark,
              });
            }
          }
          if (obj["3 months"] != null) {
            this.remark = "";
            for (var i = 0; i < obj["3 months"].length; i++) {
              this.develCog3monthData.push({
                Cognitive_ID: obj["3 months"][i].cognitive_id,
                Description: obj["3 months"][i].description,
                Yes: true,
                No: false,
                remarks: this.remark
              });
            }
          }
          if (obj["4-5 months"] != null) {
            this.remark = "";
            for (var i = 0; i < obj["4-5 months"].length; i++) {
              this.develCog4To5monthData.push({
                Cognitive_ID: obj["4-5 months"][i].cognitive_id,
                Description: obj["4-5 months"][i].description,
                Yes: true,
                No: false,
                remarks: this.remark
              });
            }
          }
          if (obj["6-7 months"] != null) {
            this.remark = "";
            for (var i = 0; i < obj["6-7 months"].length; i++) {
              this.develCog6To7monthData.push({
                Cognitive_ID: obj["6-7 months"][i].cognitive_id,
                Description: obj["6-7 months"][i].description,
                Yes: true,
                No: false,
                remarks: this.remark
              });
            }
          }
          if (obj["8-9 months"] != null) {
            this.remark = "";
            for (var i = 0; i < obj["8-9 months"].length; i++) {
              this.develCog8To9monthData.push({
                Cognitive_ID: obj["8-9 months"][i].cognitive_id,
                Description: obj["8-9 months"][i].description,
                Yes: true,
                No: false,
                remarks: this.remark
              });
            }
          }
          if (obj["10-12 months"] != null) {
            this.remark = "";
            for (var i = 0; i < obj["10-12 months"].length; i++) {
              this.develCog10To12monthData.push({
                Cognitive_ID: obj["10-12 months"][i].cognitive_id,
                Description: obj["10-12 months"][i].description,
                Yes: true,
                No: false,
                remarks: this.remark
              });
            }
          }
          if (obj["15 months"] != null) {
            this.remark = "";
            for (var i = 0; i < obj["15 months"].length; i++) {
              this.develCog15monthData.push({
                Cognitive_ID: obj["15 months"][i].cognitive_id,
                Description: obj["15 months"][i].description,
                Yes: true,
                No: false,
                remarks: this.remark
              });
            }
          }
          if (obj["18 months"] != null) {
            this.remark = "";
            for (var i = 0; i < obj["18 months"].length; i++) {
              this.develCog18monthData.push({
                Cognitive_ID: obj["18 months"][i].cognitive_id,
                Description: obj["18 months"][i].description,
                Yes: true,
                No: false,
                remarks: this.remark
              });
            }
          }
          if (obj["24 months"] != null) {
            this.remark = "";
            for (var i = 0; i < obj["24 months"].length; i++) {
              this.develCog24monthData.push({
                Cognitive_ID: obj["24 months"][i].cognitive_id,
                Description: obj["24 months"][i].description,
                Yes: true,
                No: false,
                remarks: this.remark
              });
            }
          }
          if (obj["3 years"] != null) {
            this.remark = "";
            for (var i = 0; i < obj["3 years"].length; i++) {
              this.develCog3yearData.push({
                Cognitive_ID: obj["3 years"][i].cognitive_id,
                Description: obj["3 years"][i].description,
                Yes: true,
                No: false,
                remarks: this.remark
              });
            }
          }
          if (obj["4 years"] != null) {
            this.remark = "";
            for (var i = 0; i < obj["4 years"].length; i++) {
              this.develCog4yearData.push({
                Cognitive_ID: obj["4 years"][i].cognitive_id,
                Description: obj["4 years"][i].description,
                Yes: true,
                No: false,
                remarks: this.remark
              });
            }
          }
          if (obj["5 years"] != null) {
            this.remark = "";
            for (var i = 0; i < obj["5 years"].length; i++) {
              this.develCog5yearData.push({
                Cognitive_ID: obj["5 years"][i].cognitive_id,
                Description: obj["5 years"][i].description,
                Yes: true,
                No: false,
                remarks: this.remark
              });
            }
          }
          this.getLanguageDevelopment();
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      )
  }
  getLanguageDevelopment() {
    this.develLang1To2monthData = [];
    this.develLang3monthData = [];
    this.develLang4To5monthData = [];
    this.develLang6To7monthData = [];
    this.develLang8To9monthData = [];
    this.develLang10To12monthData = [];
    this.develLang15monthData = [];
    this.develLang18monthData = [];
    this.develLang24monthData = [];
    this.develLang3yearData = [];
    this.develLang4yearData = [];
    this.develLang5yearData = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'pedia/ldev',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj["1-2 months"] != null) {
            this.remark = "";
            for (var i = 0; i < obj["1-2 months"].length; i++) {
              this.develLang1To2monthData.push({
                Cognitive_ID: obj["1-2 months"][i].language_id,
                Description: obj["1-2 months"][i].description,
                Yes: true,
                No: false,
                remarks: this.remark
              });
            }
          }
          if (obj["3 months"] != null) {
            this.remark = "";
            for (var i = 0; i < obj["3 months"].length; i++) {
              this.develLang3monthData.push({
                Cognitive_ID: obj["3 months"][i].language_id,
                Description: obj["3 months"][i].description,
                Yes: true,
                No: false,
                remarks: this.remark
              });
            }
          }
          if (obj["4-5 months"] != null) {
            this.remark = "";
            for (var i = 0; i < obj["4-5 months"].length; i++) {
              this.develLang4To5monthData.push({
                Cognitive_ID: obj["4-5 months"][i].language_id,
                Description: obj["4-5 months"][i].description,
                Yes: true,
                No: false,
                remarks: this.remark
              });
            }
          }
          if (obj["6-7 months"] != null) {
            this.remark = "";
            for (var i = 0; i < obj["6-7 months"].length; i++) {
              this.develLang6To7monthData.push({
                Cognitive_ID: obj["6-7 months"][i].language_id,
                Description: obj["6-7 months"][i].description,
                Yes: true,
                No: false,
                remarks: this.remark
              });
            }
          }
          if (obj["8-9 months"] != null) {
            this.remark = "";
            for (var i = 0; i < obj["8-9 months"].length; i++) {
              this.develLang8To9monthData.push({
                Cognitive_ID: obj["8-9 months"][i].language_id,
                Description: obj["8-9 months"][i].description,
                Yes: true,
                No: false,
                remarks: this.remark
              });
            }
          }
          if (obj["10-12 months"] != null) {
            this.remark = "";
            for (var i = 0; i < obj["10-12 months"].length; i++) {
              this.develLang10To12monthData.push({
                Cognitive_ID: obj["10-12 months"][i].language_id,
                Description: obj["10-12 months"][i].description,
                Yes: true,
                No: false,
                remarks: this.remark
              });
            }
          }
          if (obj["15 months"] != null) {
            this.remark = "";
            for (var i = 0; i < obj["15 months"].length; i++) {
              this.develLang15monthData.push({
                Cognitive_ID: obj["15 months"][i].language_id,
                Description: obj["15 months"][i].description,
                Yes: true,
                No: false,
                remarks: this.remark
              });
            }
          }
          if (obj["18 months"] != null) {
            this.remark = "";
            for (var i = 0; i < obj["18 months"].length; i++) {
              this.develLang18monthData.push({
                Cognitive_ID: obj["18 months"][i].language_id,
                Description: obj["18 months"][i].description,
                Yes: true,
                No: false,
                remarks: this.remark
              });
            }
          }
          if (obj["24 months"] != null) {
            this.remark = "";
            for (var i = 0; i < obj["24 months"].length; i++) {
              this.develLang24monthData.push({
                Cognitive_ID: obj["24 months"][i].language_id,
                Description: obj["24 months"][i].description,
                Yes: true,
                No: false,
                remarks: this.remark
              });
            }
          }
          if (obj["3 years"] != null) {
            this.remark = "";
            for (var i = 0; i < obj["3 years"].length; i++) {
              this.develLang3yearData.push({
                Cognitive_ID: obj["3 years"][i].language_id,
                Description: obj["3 years"][i].description,
                Yes: true,
                No: false,
                remarks: this.remark
              });
            }
          }
          if (obj["4 years"] != null) {
            this.remark = "";
            for (var i = 0; i < obj["4 years"].length; i++) {
              this.develLang4yearData.push({
                Cognitive_ID: obj["4 years"][i].language_id,
                Description: obj["4 years"][i].description,
                Yes: true,
                No: false,
                remarks: this.remark
              });
            }
          }
          if (obj["5 years"] != null) {
            this.remark = "";
            for (var i = 0; i < obj["5 years"].length; i++) {
              this.develLang5yearData.push({
                Cognitive_ID: obj["5 years"][i].language_id,
                Description: obj["5 years"][i].description,
                Yes: true,
                No: false,
                remarks: this.remark
              });
            }
          }
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        }
      )
  }
  setVaccinationData() {
    if (this.vaccineListRetrv != undefined) {
      if (this.vaccineListRetrv != null) {
        this.givenDateAge = "";
        if (this.vaccineListRetrv[0].Birth != null) {
          for (var i = 0; i < this.vaccineListRetrv[0].Birth.length; i++) {
            for (var j = 0; j < this.mainVaccineData["Birth"].length; j++) {
              if (this.vaccineListRetrv[0].Birth[i].ped_vaccine_age_id == this.mainVaccineData["Birth"][j].ped_vaccine_age_id) {
                var index = this.mainVaccineData["Birth"].indexOf(this.mainVaccineData["Birth"][j]);
                this.mainVaccineData["Birth"].splice(index, 1); 
                this.mainVaccineData["Birth"].splice(index, 0, this.vaccineListRetrv[0].Birth[i]); 
                if (this.mainVaccineData["Birth"][j].due_date != null || this.mainVaccineData["Birth"][j].due_date != undefined) {
                  this.mainVaccineData["Birth"][j].due_date = Date_Formate(this.mainVaccineData["Birth"][j].due_date);
                  this.mainVaccineData["Birth"][j].due_flag = "0";
                }
                if (this.mainVaccineData["Birth"][j].given_date != null || this.mainVaccineData["Birth"][j].given_date != undefined) {
                  var d = this.mainVaccineData["Birth"][j].given_date;
                  this.mainVaccineData["Birth"][j].given_date = Date_Formate(d);
                  this.mainVaccineData["Birth"][j].given_flag = "0";
                }
                if (this.vaccineListRetrv[0].Birth[i].given_date != null) {
                  this.givenDateAge = "Birth";
                }
                if (this.mainVaccineData["Birth"][j].remarks != null) {
                  this.mainVaccineData["Birth"][j].remarks = this.mainVaccineData["Birth"][j].remarks;
                }
              }
            }
          }
        }
        if (this.vaccineListRetrv[0]["6 weeks"] != null) {
          for (var i = 0; i < this.vaccineListRetrv[0]["6 weeks"].length; i++) {
            for (var j = 0; j < this.mainVaccineData["6 weeks"].length; j++) {
              if (this.vaccineListRetrv[0]["6 weeks"][i].ped_vaccine_age_id == this.mainVaccineData["6 weeks"][j].ped_vaccine_age_id) {
                var index = this.mainVaccineData["6 weeks"].indexOf(this.mainVaccineData["6 weeks"][j]);
                this.mainVaccineData["6 weeks"].splice(index, 1); 
                this.mainVaccineData["6 weeks"].splice(index, 0, this.vaccineListRetrv[0]["6 weeks"][i]); 
                if (this.mainVaccineData["6 weeks"][j].due_date != null || this.mainVaccineData["6 weeks"][j].due_date != undefined) {
                  var d = this.mainVaccineData["6 weeks"][j].due_date;
                  this.mainVaccineData["6 weeks"][j].due_date = Date_Formate(d);
                  this.mainVaccineData["6 weeks"][j].due_flag = "0";
                }
                if (this.mainVaccineData["6 weeks"][j].given_date != null || this.mainVaccineData["6 weeks"][j].given_date != undefined) {
                  var d = this.mainVaccineData["6 weeks"][j].given_date;
                  this.mainVaccineData["6 weeks"][j].given_date = Date_Formate(d);
                  this.mainVaccineData["6 weeks"][j].given_flag = "0";
                }
                if (this.vaccineListRetrv[0]["6 weeks"][i].given_date != null) {
                  this.givenDateAge = "6 weeks";
                }
                if (this.mainVaccineData["6 weeks"][j].remarks != null) {
                  this.mainVaccineData["6 weeks"][j].remarks = this.mainVaccineData["6 weeks"][j].remarks;
                }
              }
            }
          }
        }
        if (this.vaccineListRetrv[0]["10 weeks"] != null) {
          for (var i = 0; i < this.vaccineListRetrv[0]["10 weeks"].length; i++) {
            for (var j = 0; j < this.mainVaccineData["10 weeks"].length; j++) {
              if (this.vaccineListRetrv[0]["10 weeks"][i].ped_vaccine_age_id == this.mainVaccineData["10 weeks"][j].ped_vaccine_age_id) {
                var index = this.mainVaccineData["10 weeks"].indexOf(this.mainVaccineData["10 weeks"][j]);
                this.mainVaccineData["10 weeks"].splice(index, 1); 
                this.mainVaccineData["10 weeks"].splice(index, 0, this.vaccineListRetrv[0]["10 weeks"][i]); 
                if (this.mainVaccineData["10 weeks"][j].due_date != null || this.mainVaccineData["10 weeks"][j].due_date != undefined) {
                  var d = this.mainVaccineData["10 weeks"][j].due_date;
                  this.mainVaccineData["10 weeks"][j].due_date = Date_Formate(d);
                  this.mainVaccineData["10 weeks"][j].due_flag = "0";
                }
                if (this.mainVaccineData["10 weeks"][j].given_date != null || this.mainVaccineData["10 weeks"][j].given_date != undefined) {
                  var d = this.mainVaccineData["10 weeks"][j].given_date;
                  this.mainVaccineData["10 weeks"][j].given_date = Date_Formate(d);
                  this.mainVaccineData["10 weeks"][j].given_flag = "0";
                }
                if (this.vaccineListRetrv[0]["10 weeks"][i].given_date != null) {
                  this.givenDateAge = "10 weeks";
                }
                if (this.mainVaccineData["10 weeks"][j].remarks != null) {
                  this.mainVaccineData["10 weeks"][j].remarks = this.mainVaccineData["10 weeks"][j].remarks;
                }
              }
            }
          }
        }
        if (this.vaccineListRetrv[0]["14 weeks"] != null) {
          for (var i = 0; i < this.vaccineListRetrv[0]["14 weeks"].length; i++) {
            for (var j = 0; j < this.mainVaccineData["14 weeks"].length; j++) {
              if (this.vaccineListRetrv[0]["14 weeks"][i].ped_vaccine_age_id == this.mainVaccineData["14 weeks"][j].ped_vaccine_age_id) {
                var index = this.mainVaccineData["14 weeks"].indexOf(this.mainVaccineData["14 weeks"][j]);
                this.mainVaccineData["14 weeks"].splice(index, 1); 
                this.mainVaccineData["14 weeks"].splice(index, 0, this.vaccineListRetrv[0]["14 weeks"][i]); 
                if (this.mainVaccineData["14 weeks"][j].due_date != null || this.mainVaccineData["14 weeks"][j].due_date != undefined) {
                  var d = this.mainVaccineData["14 weeks"][j].due_date;
                  this.mainVaccineData["14 weeks"][j].due_date = Date_Formate(d);
                  this.mainVaccineData["14 weeks"][j].due_flag = "0";
                }
                if (this.mainVaccineData["14 weeks"][j].given_date != null || this.mainVaccineData["14 weeks"][j].given_date != undefined) {
                  var d = this.mainVaccineData["14 weeks"][j].given_date;
                  this.mainVaccineData["14 weeks"][j].given_date = Date_Formate(d);
                  this.mainVaccineData["14 weeks"][j].given_flag = "0";
                }
                if (this.vaccineListRetrv[0]["14 weeks"][i].given_date != null) {
                  this.givenDateAge = "14 weeks";
                }
                if (this.mainVaccineData["14 weeks"][j].remarks != null) {
                  this.mainVaccineData["14 weeks"][j].remarks = this.mainVaccineData["14 weeks"][j].remarks;
                }
              }
            }
          }
        }
        if (this.vaccineListRetrv[0]["6 months"] != null) {
          for (var i = 0; i < this.vaccineListRetrv[0]["6 months"].length; i++) {
            for (var j = 0; j < this.mainVaccineData["6 months"].length; j++) {
              if (this.vaccineListRetrv[0]["6 months"][i].ped_vaccine_age_id == this.mainVaccineData["6 months"][j].ped_vaccine_age_id) {
                var index = this.mainVaccineData["6 months"].indexOf(this.mainVaccineData["6 months"][j]);
                this.mainVaccineData["6 months"].splice(index, 1); 
                this.mainVaccineData["6 months"].splice(index, 0, this.vaccineListRetrv[0]["6 months"][i]); 
                if (this.mainVaccineData["6 months"][j].due_date != null || this.mainVaccineData["6 months"][j].due_date != undefined) {
                  var d = this.mainVaccineData["6 months"][j].due_date;
                  this.mainVaccineData["6 months"][j].due_date = Date_Formate(d);
                  this.mainVaccineData["6 months"][j].due_flag = "0";
                }
                if (this.mainVaccineData["6 months"][j].given_date != null || this.mainVaccineData["6 months"][j].given_date != undefined) {
                  var d = this.mainVaccineData["6 months"][j].given_date;
                  this.mainVaccineData["6 months"][j].given_date = Date_Formate(d);
                  this.mainVaccineData["6 months"][j].given_flag = "0";
                }
                if (this.vaccineListRetrv[0]["6 months"][i].given_date != null) {
                  this.givenDateAge = "6 months";
                }
                if (this.mainVaccineData["6 months"][j].remarks != null) {
                  this.mainVaccineData["6 months"][j].remarks = this.mainVaccineData["6 months"][j].remarks;
                }
              }
            }
          }
        }
        if (this.vaccineListRetrv[0]["9 months"] != null) {
          for (var i = 0; i < this.vaccineListRetrv[0]["9 months"].length; i++) {
            for (var j = 0; j < this.mainVaccineData["9 months"].length; j++) {
              if (this.vaccineListRetrv[0]["9 months"][i].ped_vaccine_age_id == this.mainVaccineData["9 months"][j].ped_vaccine_age_id) {
                var index = this.mainVaccineData["9 months"].indexOf(this.mainVaccineData["9 months"][j]);
                this.mainVaccineData["9 months"].splice(index, 1); 
                this.mainVaccineData["9 months"].splice(index, 0, this.vaccineListRetrv[0]["9 months"][i]); 
                if (this.mainVaccineData["9 months"][j].due_date != null || this.mainVaccineData["9 months"][j].due_date != undefined) {
                  var d = this.mainVaccineData["9 months"][j].due_date;
                  this.mainVaccineData["9 months"][j].due_date = Date_Formate(d);
                  this.mainVaccineData["9 months"][j].due_flag = "0";
                }
                if (this.mainVaccineData["9 months"][j].given_date != null || this.mainVaccineData["9 months"][j].given_date != undefined) {
                  var d = this.mainVaccineData["9 months"][j].given_date;
                  this.mainVaccineData["9 months"][j].given_date = Date_Formate(d);
                  this.mainVaccineData["9 months"][j].given_flag = "0";
                }
                if (this.vaccineListRetrv[0]["9 months"][i].given_date != null) {
                  this.givenDateAge = "9 months";
                }
                if (this.mainVaccineData["9 months"][j].remarks != null) {
                  this.mainVaccineData["9 months"][j].remarks = this.mainVaccineData["9 months"][j].remarks;
                }
              }
            }
          }
        }
        if (this.vaccineListRetrv[0]["15 - 18 months"] != null) {
          for (var i = 0; i < this.vaccineListRetrv[0]["15 - 18 months"].length; i++) {
            for (var j = 0; j < this.mainVaccineData["15 - 18 months"].length; j++) {
              if (this.vaccineListRetrv[0]["15 - 18 months"][i].ped_vaccine_age_id == this.mainVaccineData["15 - 18 months"][j].ped_vaccine_age_id) {
                var index = this.mainVaccineData["15 - 18 months"].indexOf(this.mainVaccineData["15 - 18 months"][j]);
                this.mainVaccineData["15 - 18 months"].splice(index, 1); 
                this.mainVaccineData["15 - 18 months"].splice(index, 0, this.vaccineListRetrv[0]["15 - 18 months"][i]); 
                if (this.mainVaccineData["15 - 18 months"][j].due_date != null || this.mainVaccineData["15 - 18 months"][j].due_date != undefined) {
                  var d = this.mainVaccineData["15 - 18 months"][j].due_date;
                  this.mainVaccineData["15 - 18 months"][j].due_date = Date_Formate(d);
                  this.mainVaccineData["15 - 18 months"][j].due_flag = "0";
                }
                if (this.mainVaccineData["15 - 18 months"][j].given_date != null || this.mainVaccineData["15 - 18 months"][j].given_date != undefined) {
                  var d = this.mainVaccineData["15 - 18 months"][j].given_date;
                  this.mainVaccineData["15 - 18 months"][j].given_date = Date_Formate(d);
                  this.mainVaccineData["15 - 18 months"][j].given_flag = "0";
                }
                if (this.vaccineListRetrv[0]["15 - 18 months"][i].given_date != null) {
                  this.givenDateAge = "15 - 18 months";
                }
                if (this.mainVaccineData["15 - 18 months"][j].remarks != null) {
                  this.mainVaccineData["15 - 18 months"][j].remarks = this.mainVaccineData["15 - 18 months"][j].remarks;
                }
              }
            }
          }
        }
        if (this.vaccineListRetrv[0]["2 years"] != null) {
          for (var i = 0; i < this.vaccineListRetrv[0]["2 years"].length; i++) {
            for (var j = 0; j < this.mainVaccineData["2 years"].length; j++) {
              if (this.vaccineListRetrv[0]["2 years"][i].ped_vaccine_age_id == this.mainVaccineData["2 years"][j].ped_vaccine_age_id) {
                var index = this.mainVaccineData["2 years"].indexOf(this.mainVaccineData["2 years"][j]);
                this.mainVaccineData["2 years"].splice(index, 1); 
                this.mainVaccineData["2 years"].splice(index, 0, this.vaccineListRetrv[0]["2 years"][i]); 
                if (this.mainVaccineData["2 years"][j].due_date != null || this.mainVaccineData["2 years"][j].due_date != undefined) {
                  var d = this.mainVaccineData["2 years"][j].due_date;
                  this.mainVaccineData["2 years"][j].due_date = Date_Formate(d);
                  this.mainVaccineData["2 years"][j].due_flag = "0";
                }
                if (this.mainVaccineData["2 years"][j].given_date != null || this.mainVaccineData["2 years"][j].given_date != undefined) {
                  var d = this.mainVaccineData["2 years"][j].given_date;
                  this.mainVaccineData["2 years"][j].given_date = Date_Formate(d);
                  this.mainVaccineData["2 years"][j].given_flag = "0";
                }
                if (this.vaccineListRetrv[0]["2 years"][i].given_date != null) {
                  this.givenDateAge = "2 years";
                }
                if (this.mainVaccineData["2 years"][j].remarks != null) {
                  this.mainVaccineData["2 years"][j].remarks = this.mainVaccineData["2 years"][j].remarks;
                }
              }
            }
          }
        }
        if (this.vaccineListRetrv[0]["5 years"] != null) {
          for (var i = 0; i < this.vaccineListRetrv[0]["5 years"].length; i++) {
            for (var j = 0; j < this.mainVaccineData["5 years"].length; j++) {
              if (this.vaccineListRetrv[0]["5 years"][i].ped_vaccine_age_id == this.mainVaccineData["5 years"][j].ped_vaccine_age_id) {
                var index = this.mainVaccineData["5 years"].indexOf(this.mainVaccineData["5 years"][j]);
                this.mainVaccineData["5 years"].splice(index, 1); 
                this.mainVaccineData["5 years"].splice(index, 0, this.vaccineListRetrv[0]["5 years"][i]); 
                if (this.mainVaccineData["5 years"][j].due_date != null || this.mainVaccineData["5 years"][j].due_date != undefined) {
                  var d = this.mainVaccineData["5 years"][j].due_date;
                  this.mainVaccineData["5 years"][j].due_date = Date_Formate(d);
                  this.mainVaccineData["5 years"][j].due_flag = "0";
                }
                if (this.mainVaccineData["5 years"][j].given_date != null || this.mainVaccineData["5 years"][j].given_date != undefined) {
                  var d = this.mainVaccineData["5 years"][j].given_date;
                  this.mainVaccineData["5 years"][j].given_date = Date_Formate(d);
                  this.mainVaccineData["5 years"][j].given_flag = "0";
                }
                if (this.vaccineListRetrv[0]["5 years"][i].given_date != null) {
                  this.givenDateAge = "5 years";
                }
                if (this.mainVaccineData["5 years"][j].remarks != null) {
                  this.mainVaccineData["5 years"][j].remarks = this.mainVaccineData["5 years"][j].remarks;
                }
              }
            }
          }
        }
        if (this.vaccineListRetrv[0]["10 years"] != null) {
          for (var i = 0; i < this.vaccineListRetrv[0]["10 years"].length; i++) {
            for (var j = 0; j < this.mainVaccineData["10 years"].length; j++) {
              if (this.vaccineListRetrv[0]["10 years"][i].ped_vaccine_age_id == this.mainVaccineData["10 years"][j].ped_vaccine_age_id) {
                var index = this.mainVaccineData["10 years"].indexOf(this.mainVaccineData["10 years"][j]);
                this.mainVaccineData["10 years"].splice(index, 1); 
                this.mainVaccineData["10 years"].splice(index, 0, this.vaccineListRetrv[0]["10 years"][i]); 
                if (this.mainVaccineData["10 years"][j].due_date != null || this.mainVaccineData["10 years"][j].due_date != undefined) {
                  var d = this.mainVaccineData["10 years"][j].due_date;
                  this.mainVaccineData["10 years"][j].due_date = Date_Formate(d);
                  this.mainVaccineData["10 years"][j].due_flag = "0";
                }
                if (this.mainVaccineData["10 years"][j].given_date != null || this.mainVaccineData["10 years"][j].given_date != undefined) {
                  var d = this.mainVaccineData["10 years"][j].given_date;
                  this.mainVaccineData["10 years"][j].given_date = Date_Formate(d);
                  this.mainVaccineData["10 years"][j].given_flag = "0";
                }
                if (this.vaccineListRetrv[0]["10 years"][i].given_date != null) {
                  this.givenDateAge = "10 years";
                }
                if (this.mainVaccineData["10 years"][j].remarks != null) {
                  this.mainVaccineData["10 years"][j].remarks = this.mainVaccineData["10 years"][j].remarks;
                }
              }
            }
          }
        }
        if (this.vaccineListRetrv[0]["15-16 years"] != null) {
          for (var i = 0; i < this.vaccineListRetrv[0]["15-16 years"].length; i++) {
            for (var j = 0; j < this.mainVaccineData["15-16 years"].length; j++) {
              if (this.vaccineListRetrv[0]["15-16 years"][i].ped_vaccine_age_id == this.mainVaccineData["15-16 years"][j].ped_vaccine_age_id) {
                var index = this.mainVaccineData["15-16 years"].indexOf(this.mainVaccineData["15-16 years"][j]);
                this.mainVaccineData["15-16 years"].splice(index, 1); 
                this.mainVaccineData["15-16 years"].splice(index, 0, this.vaccineListRetrv[0]["15-16 years"][i]); 
                if (this.mainVaccineData["15-16 years"][j].due_date != null || this.mainVaccineData["15-16 years"][j].due_date != undefined) {
                  var d = this.mainVaccineData["15-16 years"][j].due_date;
                  this.mainVaccineData["15-16 years"][j].due_date = Date_Formate(d);
                  this.mainVaccineData["15-16 years"][j].due_flag = "0";
                }
                if (this.mainVaccineData["15-16 years"][j].given_date != null || this.mainVaccineData["15-16 years"][j].given_date != undefined) {
                  var d = this.mainVaccineData["15-16 years"][j].given_date;
                  this.mainVaccineData["15-16 years"][j].given_date = Date_Formate(d);
                  this.mainVaccineData["15-16 years"][j].given_flag = "0";
                }
                if (this.vaccineListRetrv[0]["15-16 years"][i].given_date != null) {
                  this.givenDateAge = "15-16 years";
                }
                if (this.mainVaccineData["15-16 years"][j].remarks != null) {
                  this.mainVaccineData["15-16 years"][j].remarks = this.mainVaccineData["15-16 years"][j].remarks;
                }
              }
            }
          }
        }
        if (this.givenDateAge != "" && this.clientDob != null && this.clientDob != "") {
          var New_date = new Date(this.clientDob);
          if (this.givenDateAge == "15-16 years") {
          } else if (this.givenDateAge == "10 years") {
            New_date.setFullYear(New_date.getFullYear() + 15);
            this.vaccineDate = new Date(New_date.getTime() + ((1) * (24 * 60 * 60 * 1000)));
            if (this.mainVaccineData["15-16 years"] != undefined) {
              for (var i = 0; i < this.mainVaccineData["15-16 years"].length; i++) {
                this.mainVaccineData["15-16 years"][i].due_date = this.datepipe.transform(this.vaccineDate, 'dd-MM-yyyy');
              }
            }
          } else if (this.givenDateAge == "5 years") {
            New_date.setFullYear(New_date.getFullYear() + 10);
            this.vaccineDate = new Date(New_date.getTime() + ((1) * (24 * 60 * 60 * 1000)));
            if (this.mainVaccineData["10 years"] != undefined) {
              for (var i = 0; i < this.mainVaccineData["10 years"].length; i++) {
                this.mainVaccineData["10 years"][i].due_date = this.datepipe.transform(this.vaccineDate, 'dd-MM-yyyy');;
              }
            }
          } else if (this.givenDateAge == "2 years") {
            New_date.setFullYear(New_date.getFullYear() + 5);
            this.vaccineDate = new Date(New_date.getTime() + ((1) * (24 * 60 * 60 * 1000)));
            if (this.mainVaccineData["5 years"] != undefined) {
              for (var i = 0; i < this.mainVaccineData["5 years"].length; i++) {
                this.mainVaccineData["5 years"][i].due_date = this.datepipe.transform(this.vaccineDate, 'dd-MM-yyyy');;
              }
            }
          } else if (this.givenDateAge == "15 - 18 months") {
            New_date.setFullYear(New_date.getFullYear() + 2);
            this.vaccineDate = new Date(New_date.getTime() + ((1) * (24 * 60 * 60 * 1000)));
            if (this.mainVaccineData["2 years"] != undefined) {
              for (var i = 0; i < this.mainVaccineData["2 years"].length; i++) {
                this.mainVaccineData["2 years"][i].due_date = this.datepipe.transform(this.vaccineDate, 'dd-MM-yyyy');;
              }
            }
          } else if (this.givenDateAge == "9 months") {
            New_date.setMonth(New_date.getMonth() + 15);
            this.vaccineDate = new Date(New_date.getTime() + ((1) * (24 * 60 * 60 * 1000)));
            if (this.mainVaccineData["15 - 18 months"] != undefined) {
              for (var i = 0; i < this.mainVaccineData["15 - 18 months"].length; i++) {
                this.mainVaccineData["15 - 18 months"][i].due_date = this.datepipe.transform(this.vaccineDate, 'dd-MM-yyyy');;
              }
            }
          } else if (this.givenDateAge == "6 months") {
            New_date.setMonth(New_date.getMonth() + 9);
            this.vaccineDate = new Date(New_date.getTime() + ((1) * (24 * 60 * 60 * 1000)));
            if (this.mainVaccineData["9 months"] != undefined) {
              for (var i = 0; i < this.mainVaccineData["9 months"].length; i++) {
                this.mainVaccineData["9 months"][i].due_date = this.datepipe.transform(this.vaccineDate, 'dd-MM-yyyy');;
              }
            }
          } else if (this.givenDateAge == "14 weeks") {
            New_date.setMonth(New_date.getMonth() + 6);
            this.vaccineDate = new Date(New_date.getTime() + ((86) * (24 * 60 * 60 * 1000)));
            if (this.mainVaccineData["6 months"] != undefined) {
              for (var i = 0; i < this.mainVaccineData["6 months"].length; i++) {
                this.mainVaccineData["6 months"][i].due_date = this.datepipe.transform(this.vaccineDate, 'dd-MM-yyyy');;
              }
            }
          } else if (this.givenDateAge == "10 weeks") {
            this.vaccineDate = new Date(New_date.getTime() + ((29) * (24 * 60 * 60 * 1000)));
            if (this.mainVaccineData["14 weeks"] != undefined) {
              for (var i = 0; i < this.mainVaccineData["14 weeks"].length; i++) {
                this.mainVaccineData["14 weeks"][i].due_date = this.datepipe.transform(this.vaccineDate, 'dd-MM-yyyy');;
              }
            }
          } else if (this.givenDateAge == "6 weeks") {
            this.vaccineDate = new Date(New_date.getTime() + ((29) * (24 * 60 * 60 * 1000)));
            if (this.mainVaccineData["10 weeks"] != undefined) {
              for (var i = 0; i < this.mainVaccineData["10 weeks"].length; i++) {
                this.mainVaccineData["10 weeks"][i].due_date = this.datepipe.transform(this.vaccineDate, 'dd-MM-yyyy');;
              }
            }
          } else if (this.givenDateAge == "Birth") {
            this.vaccineDate = new Date(New_date.getTime() + ((43) * (24 * 60 * 60 * 1000)));
            if (this.mainVaccineData["6 weeks"] != undefined) {
              for (var i = 0; i < this.mainVaccineData["6 weeks"].length; i++) {
                this.mainVaccineData["6 weeks"][i].due_date = this.datepipe.transform(this.vaccineDate, 'dd-MM-yyyy');;
              }
            }
          }
          this.getPediatricDateDisable(this.givenDateAge);
        }
      }
    }
  }
  getPediatricDateDisable(Age_given) { 
    if (Age_given == "15-16 years") {
      this.disableImmuniBirthData(); 
      this.disableImmuniSixWeeksData(); 
      this.disableImmuniTenWeeksData(); 
      this.disableImmuniFourWeeksData(); 
      this.disableImmuniSixMonsData(); 
      this.disableImmuniNineMonsData(); 
      this.disableImmuniFiftMonsData(); 
      this.disableImmuniTwoYearData(); 
      this.disableImmuniFiveYearData(); 
      this.disableImmuniTenYearData(); 
    } else if (Age_given == "10 years") {
      this.disableImmuniBirthData(); 
      this.disableImmuniSixWeeksData(); 
      this.disableImmuniTenWeeksData(); 
      this.disableImmuniFourWeeksData(); 
      this.disableImmuniSixMonsData(); 
      this.disableImmuniNineMonsData(); 
      this.disableImmuniFiftMonsData(); 
      this.disableImmuniTwoYearData(); 
      this.disableImmuniFiveYearData(); 
    } else if (Age_given == "5 years") {
      this.disableImmuniBirthData(); 
      this.disableImmuniSixWeeksData(); 
      this.disableImmuniTenWeeksData(); 
      this.disableImmuniFourWeeksData(); 
      this.disableImmuniSixMonsData(); 
      this.disableImmuniNineMonsData(); 
      this.disableImmuniFiftMonsData(); 
      this.disableImmuniTwoYearData(); 
    } else if (Age_given == "2 years") {
      this.disableImmuniBirthData(); 
      this.disableImmuniSixWeeksData(); 
      this.disableImmuniTenWeeksData(); 
      this.disableImmuniFourWeeksData(); 
      this.disableImmuniSixMonsData(); 
      this.disableImmuniNineMonsData(); 
      this.disableImmuniFiftMonsData(); 
    } else if (Age_given == "15 - 18 months") {
      this.disableImmuniBirthData(); 
      this.disableImmuniSixWeeksData(); 
      this.disableImmuniTenWeeksData(); 
      this.disableImmuniFourWeeksData(); 
      this.disableImmuniSixMonsData(); 
      this.disableImmuniNineMonsData(); 
    } else if (Age_given == "9 months") {
      this.disableImmuniBirthData(); 
      this.disableImmuniSixWeeksData(); 
      this.disableImmuniTenWeeksData(); 
      this.disableImmuniFourWeeksData(); 
      this.disableImmuniSixMonsData(); 
    } else if (Age_given == "6 months") {
      this.disableImmuniBirthData(); 
      this.disableImmuniSixWeeksData(); 
      this.disableImmuniTenWeeksData(); 
      this.disableImmuniFourWeeksData(); 
    } else if (Age_given == "14 weeks") {
      this.disableImmuniBirthData(); 
      this.disableImmuniSixWeeksData(); 
      this.disableImmuniTenWeeksData(); 
    } else if (Age_given == "10 weeks") {
      this.disableImmuniBirthData(); 
      this.disableImmuniSixWeeksData(); 
    } else if (Age_given == "6 weeks") {
      this.disableImmuniBirthData(); 
    }
  }
  disableImmuniBirthData() {
    for (var i = 0; i < this.mainVaccineData.Birth.length; i++) {
      this.mainVaccineData.Birth[i].given_flag = "0";
      this.mainVaccineData.Birth[i].due_flag = "0";
    }
  }
  disableImmuniSixWeeksData() {
    for (var i = 0; i < this.mainVaccineData["6 weeks"].length; i++) {
      this.mainVaccineData["6 weeks"][i].given_flag = "0";
      this.mainVaccineData["6 weeks"][i].due_flag = "0";
    }
  }
  disableImmuniTenWeeksData() {
    for (var i = 0; i < this.mainVaccineData["10 weeks"].length; i++) {
      this.mainVaccineData["10 weeks"][i].given_flag = "0";
      this.mainVaccineData["10 weeks"][i].due_flag = "0";
    }
  }
  disableImmuniFourWeeksData() {
    for (var i = 0; i < this.mainVaccineData["14 weeks"].length; i++) {
      this.mainVaccineData["14 weeks"][i].given_flag = "0";
      this.mainVaccineData["14 weeks"][i].due_flag = "0";
    }
  }
  disableImmuniSixMonsData() {
    for (var i = 0; i < this.mainVaccineData["6 months"].length; i++) {
      this.mainVaccineData["6 months"][i].given_flag = "0";
      this.mainVaccineData["6 months"][i].due_flag = "0";
    }
  }
  disableImmuniNineMonsData() {
    for (var i = 0; i < this.mainVaccineData["9 months"].length; i++) {
      this.mainVaccineData["9 months"][i].given_flag = "0";
      this.mainVaccineData["9 months"][i].due_flag = "0";
    }
  }
  disableImmuniFiftMonsData() {
    for (var i = 0; i < this.mainVaccineData["15 - 18 months"].length; i++) {
      this.mainVaccineData["15 - 18 months"][i].given_flag = "0";
      this.mainVaccineData["15 - 18 months"][i].due_flag = "0";
    }
  }
  disableImmuniTwoYearData() {
    for (var i = 0; i < this.mainVaccineData["2 years"].length; i++) {
      this.mainVaccineData["2 years"][i].given_flag = "0";
      this.mainVaccineData["2 years"][i].due_flag = "0";
    }
  }
  disableImmuniFiveYearData() {
    for (var i = 0; i < this.mainVaccineData["5 years"].length; i++) {
      this.mainVaccineData["5 years"][i].given_flag = "0";
      this.mainVaccineData["5 years"][i].due_flag = "0";
    }
  }
  disableImmuniTenYearData() {
    for (var i = 0; i < this.mainVaccineData["10 years"].length; i++) {
      this.mainVaccineData["10 years"][i].given_flag = "0";
      this.mainVaccineData["10 years"][i].due_flag = "0";
    }
  }
  disableImmuniFifteenYearData() {
    for (var i = 0; i < this.mainVaccineData["15-16 years"].length; i++) {
      this.mainVaccineData["15-16 years"][i].given_flag = "0";
      this.mainVaccineData["15-16 years"][i].due_flag = "0";
    }
  }
  selectMainConcern() { 
    for (var j = 0; j < this.mainConcernMasterList.length; j++) {
      this.mainConcernMasterList[j].checked = false;
    }
    if (this.mainConcernRetrv != undefined && this.mainConcernRetrv.length != 0) {
      for (var i = 0; i < this.mainConcernRetrv.length; i++) {
        for (var j = 0; j < this.mainConcernMasterList.length; j++) {
          if (this.mainConcernRetrv[i] == this.mainConcernMasterList[j].value) {
            this.mainConcernMasterList[j].checked = true;
          }
        }
      }
    }
    const dialogRef = this.dialog.open(DropdownModelpageComponent, {
      width: '500px',
      height: '400px',
      data:{
        title: "Main Concerns",
        main_array: this.mainConcernMasterList,
        selected_list:this.mainConcernRetrv,
      } 
    });
    dialogRef.afterClosed().subscribe(result => {
        if(result != undefined){
        this.addMainConcern(result);
        }
    });
  }
  addMainConcern(selected) {
    this.mainConcern = "";
    function StringBuilder(this:any,value) {
      this.strings = new Array("");
      this.append(value);
    }
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }
    var selected_data = new StringBuilder("");
    this.mainConcernRetrv = selected;
    if (this.mainConcernRetrv.length != 0) {
      var n = 0;
      for (var j = 0; j < this.mainConcernRetrv.length; j++) {
        for (var i = 0; i < this.mainConcernMasterList.length; i++) {
          if (this.mainConcernRetrv[j] == this.mainConcernMasterList[i].value) {
            n = n + 1;
            if (n > 1) {
              selected_data.append(",");
              selected_data.append("\n");
            }
            selected_data.append(this.mainConcernMasterList[i].label);
          }
        }
      }
      this.mainConcern = selected_data.toString();
    }
  }
  getMedicalHistory() {
    this.medicalHistoryMasterList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'gen/pmedhis',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.med_hist != null) {
            for (var i = 0; i < obj.med_hist.length; i++) {
              this.medicalHistoryMasterList.push({
                type: 'checkbox',
                value: obj.med_hist[i].med_hist_id,
                label: obj.med_hist[i].description,
                checked: false
              });
              if(obj.med_hist[i].med_hist_id == 32){
                this.pastMedical = obj.med_hist[i].description;
              }
            }
          }
        },error => {});
  }

  selectMedicalHistory() {
    for (var j = 0; j < this.medicalHistoryMasterList.length; j++) {
      this.medicalHistoryMasterList[j].checked = false;
    }
    if (this.medicalHistoryRetrv != null && this.medicalHistoryRetrv != undefined && this.medicalHistoryRetrv.length != 0) {
      for (var i = 0; i < this.medicalHistoryRetrv.length; i++) {
        for (var j = 0; j < this.medicalHistoryMasterList.length; j++) {
          if (this.medicalHistoryRetrv[i] == this.medicalHistoryMasterList[j].value) {
            this.medicalHistoryMasterList[j].checked = true;
          }
        }
      }
    }
    const dialogRef = this.dialog.open(DropdownModelpageComponent, {
      width: '500px',
      height: '400px',
      data:{
        title: "Past Medical History",
        main_array: this.medicalHistoryMasterList,
        selected_list:this.medicalHistoryRetrv,
      } 
    });
    dialogRef.afterClosed().subscribe(result => {
        if(result != undefined){
        this.addMedicalHistory(result);
        }
    });
  }
  addMedicalHistory(selected) { 
    this.pastMedical = "";
    function StringBuilder(this:any,value) {
      this.strings = new Array("");
      this.append(value);
    }
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }
    StringBuilder.prototype.toString = function () { 
      return this.strings.join("");
    }
    var selected_data = new StringBuilder("");
    if (selected != "" && selected != null) {
      this.medicalHistoryRetrv = selected;
    }
    if (this.medicalHistoryRetrv.length != 0) {
      var n = 0;
      for (var j = 0; j < this.medicalHistoryRetrv.length; j++) {
        for (var i = 0; i < this.medicalHistoryMasterList.length; i++) {
          if (this.medicalHistoryRetrv[j] == this.medicalHistoryMasterList[i].value) {
            n = n + 1;
            if (n > 1) {
              selected_data.append(",");
              selected_data.append("\n");
            }
            selected_data.append(this.medicalHistoryMasterList[i].label);
          }
        }
      }
      this.pastMedical = selected_data.toString();
    }
  }
  getRelationDetails() {
    this.relationsList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'usercontroller/grel',
      JSON.stringify({
        client_reg_id: this.gservice.Client_login_detail_data.Client_Id
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          if (obj != undefined) {
            this.relationsList.push({
              client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
              relation_id: "1",
              name: this.gservice.Client_login_detail_data.Full_Name,
              sub_rel_id: undefined,
              gender: encrypt_decript.Decript(this.gservice.Client_login_detail_data.gender),
              age: this.gservice.Client_login_detail_data.age,
              dob:this.gservice.Client_login_detail_data.dob,
            })
            this.relationName = this.relationsList[0].name;
            for (var i = 0; i < obj.relations.length; i++) {
              var name;
              if (obj.relations[i].middle_name != undefined) {
                name = encrypt_decript.Decript(obj.relations[i].first_name) + " " + encrypt_decript.Decript(obj.relations[i].middle_name) + " " + encrypt_decript.Decript(obj.relations[i].last_name);
              } else {
                name = encrypt_decript.Decript(obj.relations[i].first_name) + " " + encrypt_decript.Decript(obj.relations[i].last_name);
              }
              this.relationsList.push({
                client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
                relation_id: obj.relations[i].relation_id,
                sub_rel_id: obj.relations[i].sub_rel_id,
                name: name,
                gender: encrypt_decript.Decript(obj.relations[i].gender),
                age: obj.relations[i].age,
                dob:obj.relations[i].dob,
              })
            }
            this.relationName = this.relationsList[0].name;
          }
        },error => {});
  }
  getCurrentDate() {
    var get_date;
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate',
      JSON.stringify({
        country: ipaddress.country_code.toString()
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.current_date != null) {
            this.currentMaxDate = obj.current_date;
            get_date = obj.current_date.split('-');
            this.currentDate = obj.current_date;
          }
        },error => {});
  }
  getSpecilizationType() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'usercontroller/getspecialization/',
      { headers: headers })
      .subscribe(
        response => {
          this.specializationList = [];
          var obj = response.json();
          for (var i = 0; i < obj.specialization.length; i++) {
            if (obj.specialization[i].spl_name != "-") {
              this.specializationList = obj.specialization;
            }
          }
          if (this.specializationList.length != 0) {
            this.splId = this.specializationList[0].spl_id;
          }
        },error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  };
  getMainConcern() {
    this.mainConcernMasterList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'gen/mcorn',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.main_concerns != null) {
            for (var i = 0; i < obj.main_concerns.length; i++) {
              this.mainConcernMasterList.push({
                type: 'checkbox',
                value: obj.main_concerns[i].main_concerns_id,
                label: obj.main_concerns[i].description,
                checked: false
              });
            }
          }
        },error => {});
  }
  getPresentIllness() {
    this.presentIllnessMasterList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'goi/pidet',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.present_illnesses != null) {
            for (var i = 0; i < obj.present_illnesses.length; i++) {
              this.presentIllnessMasterList.push({
                type: 'checkbox',
                value: obj.present_illnesses[i].present_illness_id,
                label: obj.present_illnesses[i].description,
                checked: false
              });
            }
          }
        },error => {
            this.toastr.error(Message_data.getNetworkMessage());
        });
  }
  selectPresentIllness() {
    for (var j = 0; j < this.presentIllnessMasterList.length; j++) {
      this.presentIllnessMasterList[j].checked = false;
    }
    if (this.presentIllnessRetrv != undefined && this.presentIllnessRetrv.length != 0) {
      for (var i = 0; i < this.presentIllnessRetrv.length; i++) {
        for (var j = 0; j < this.presentIllnessMasterList.length; j++) {
          if (this.presentIllnessRetrv[i] == this.presentIllnessMasterList[j].value) {
            this.presentIllnessMasterList[j].checked = true;
          }
        }
      }
    }
    const dialogRef = this.dialog.open(DropdownModelpageComponent, {
      width: '500px',
      height: '400px',
      data:{
        title: "Present Illness",
        main_array: this.presentIllnessMasterList,
        selected_list:this.presentIllnessRetrv,
      } 
    });
    dialogRef.afterClosed().subscribe(result => {
        if(result != undefined){
        this.changePresentIllness(result);
        }
    });
  }
  changePresentIllness(selected) {
    this.presentIllness = "";
    function StringBuilder(this:any,value) {
      this.strings = new Array("");
      this.append(value);
    }
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }
    var selected_data = new StringBuilder("");
    this.presentIllnessRetrv = selected;
    if (this.presentIllnessRetrv.length != 0) {
      var n = 0;
      for (var j = 0; j < this.presentIllnessRetrv.length; j++) {
        for (var i = 0; i < this.presentIllnessMasterList.length; i++) {
          if (this.presentIllnessRetrv[j] == this.presentIllnessMasterList[i].value) {
            n = n + 1;
            if (n > 1) {
              selected_data.append(",");
              selected_data.append("\n");
            }
            selected_data.append(this.presentIllnessMasterList[i].label);
          }
        }
      }
      this.presentIllness = selected_data.toString();
    }
  }
  drugAllergyChange(data) {
    if (data == "Yes") {
      this.drugRequiredFlag = true;
      this.drugDisabledFlag = false;
    } else {
      this.drugRequiredFlag = false;
      this.drugDisabledFlag = true;
      this.drugs = undefined;
    }
  }
  anyMedicineChange() {
    if (this.anyMedicine == "Yes") {
      this.medicineFlag = false;
    } else {
      this.medicineFlag = true;
      this.medicine = "";
    }
  }
  hdisorderChange() {
    if (this.hdisorder == "Yes") {
      this.hDisTreatDisabledFlag = false;
    } else {
      this.hDisTreatDisabledFlag = true;
      this.treat = "";
    }
  }
  prevhospChange() {
    if (this.hosp == "Yes") {
      this.hospDisabledFlag = false;
    } else {
      this.hospDisabledFlag = true;
      this.hospDetails = "";
    }
  }
  getBodyTypes() {
    this.bodytTypeList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'cardio/btypes',
      JSON.stringify({
        gender: this.gender,
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj != null) {
            this.bodytTypeList = obj.body_types;
          }
        },error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }
  relationChange(name) {
    for (var i = 0; i < this.relationsList.length; i++) {
      if (name == this.relationsList[i].name) {
        this.gender = this.relationsList[i].gender;
        this.clearData();
        this.masterData();
        this.clientRegId = this.relationsList[i].client_reg_id;
        this.relationId = this.relationsList[i].relation_id;
        this.subRelId = this.relationsList[i].sub_rel_id;
        this.clientDob = this.relationsList[i].dob;
        this.clientAge = this.relationsList[i].age;
        this.getClientDetails();
        this.splChange();
        this.displayFields();
      }
    }
    this.getBodyTypes();
  }
  selectDcDate(e) {
    this.dc=e;
  }
  selectOvarainDate(e) {
    this.ovarianSurgery = e;
  }
  selectHysteroscopyDate(e) {
    this.hysteroscopy = e;
  }
  selectInfertilitySurgeryDate(e) {
    this.infertilitySurgery = e;
  }
  selectLeftCystDate(e) {
    this.leftCystRemoved = e;
  }
  selectRightCystDate(e) {
    this.rightCystRemoved = e;
  }
  selectTuboplastyDate(e) {
    this.tuboplasty = e;
  }
  selectTubalLigationDate(e) {
    this.tubalLigation = e;
  }
  selectLeftOvaryDate(e) {
    this.leftOvaryRemoved = e;
  }
  selectRightOvaryDate(e) {
    this.rightOvaryRemoved = e;
  }
  selectLaparoscopyDate(e) {
    this.laparoscopy = e;
  }
  selectHysterectomyDate(e) {
    this.hysterectomyVaginal = e;
  }
  selectHysterectomyAbdominalDate(e) {
    this.hysterectomyAbdominal = e;
  }
  selectMyomectomyDate(e) {
    this.myomectomy = e;
  }
  selectBladderRepairDate(e) {
    this.bladderRepair = e;
  }
  getImmunizations() {
    this.mainVaccineData = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'pedia/vaclist',
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          this.mainVaccineData = obj;
          this.keys = Object.keys(this.mainVaccineData);
          this.setVaccinationData();
        });
  }
  getChildHabits() {
    this.childHabitMasterList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'pedia/chabit',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.habits != null) {
            for (var i = 0; i < obj.habits.length; i++) {
              this.childHabitMasterList.push({
                type: 'checkbox',
                value: obj.habits[i].ped_habit_id,
                label: obj.habits[i].description,
                checked: false
              });
            }
          }
        },error => {
            this.toastr.error(Message_data.getNetworkMessage());
        });
  }
  selectChildHabit() {
    for (var j = 0; j < this.childHabitMasterList.length; j++) {
      this.childHabitMasterList[j].checked = false;
    }
    if (this.childHabitRetrv != undefined && this.childHabitRetrv.length != 0) {
      for (var i = 0; i < this.childHabitRetrv.length; i++) {
        for (var j = 0; j < this.childHabitMasterList.length; j++) {
          if (this.childHabitRetrv[i] == this.childHabitMasterList[j].value) {
            this.childHabitMasterList[j].checked = true;
          }
        }
      }
    }
    const dialogRef = this.dialog.open(DropdownModelpageComponent, {
      width: '500px',
      height: '400px',
      data:{
        title: "Child habits",
        main_array: this.childHabitMasterList,
        selected_list:this.childHabitRetrv,
      } 
    });
    dialogRef.afterClosed().subscribe(result => {
        if(result != undefined){
        this.changeChildHabit(result);
        }
    });
  }
  changeChildHabit(selected) {
    this.childHabits = "";
    function StringBuilder(this:any,value) {
      this.strings = new Array("");
      this.append(value);
    }
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }
    StringBuilder.prototype.clear = function () { 
      this.strings.length = 1;
    }
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }
    var selected_data = new StringBuilder("");
    this.childHabitRetrv = selected;
    if (this.childHabitRetrv.length != 0) {
      var n = 0;
      for (var j = 0; j < this.childHabitRetrv.length; j++) {
        for (var i = 0; i < this.childHabitMasterList.length; i++) {
          if (this.childHabitRetrv[j] == this.childHabitMasterList[i].value) {
            n = n + 1;
            if (n > 1) {
              selected_data.append(",");
              selected_data.append("\n");
            }
            selected_data.append(this.childHabitMasterList[i].label);
          }
        }
      }
      this.childHabits = selected_data.toString();
    }
  }
  changeStage(data) {
    this.statdescRequiredFlag = (data == "Perimenopause") ? true : false;
  }
  selectStageDate(data) {
    this.mensStageDate = data;
  }

  selectLastMensPeriodDate(data) {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; 
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = 0 + dd
    }
    if (mm < 10) {
      mm = 0 + mm
    }
    var today1 = yyyy + '-' + mm + '-' + dd;
    var Selecteddate = data.toString().split('-');
    var Selected_Date = Selecteddate[2].toString() + "-" + Selecteddate[1].toString() + "-" + Selecteddate[0].toString();
    var curr_date = new Date(today);
    curr_date.setDate(curr_date.getDate() + 7);
    var startDay = new Date(today1);
    var Endday = new Date(Selected_Date);
    var millisecondsPerDay = 1000 * 60 * 60 * 24;
    var millisBetween1 = curr_date.getTime() - Endday.getTime();
    var days2 = millisBetween1 / millisecondsPerDay;
    var Days1 = Math.floor(days2);
    var Weeks1 = (Days1 / 7)
    var Weeks_New1 = Math.floor(Weeks1);
    this.noOfWeeks = Weeks_New1;
    if (Days1 <= 91) {
      this.trimester = "I";
    } else if (Days1 > 91 && Days1 <= 183) {
      this.trimester = "II";
    } else if (Days1 > 183) {
      this.trimester = "III";
    }
    if (this.frequencyLength != "") {
      var Cycleday = 280 - (28 - parseInt(this.frequencyLength));
      var EDD_Day = new Date(Endday.getTime() + ((Cycleday) * 24 * 60 * 60 * 1000));
      this.eddRedd = toYYYYMMDD(EDD_Day);
    }
    function toYYYYMMDD(d) {
      var yyyy = d.getFullYear().toString();
      var mm = (d.getMonth() + 101).toString().slice(-2);
      var dd = (d.getDate() + 100).toString().slice(-2);
      return dd + "-" + mm + "-" + yyyy;
    }
  }

  selectMenstrualPainDate(data) {
    if (data == "Yes") {
      this.mensTimeOfPainRequiredFlag = true;
      this.mensCharOfPainRequiredFlag = true;
      this.mensTimeOfPainDisabledFlag = false;
      this.mensCharOfPainDisabledFlag = false;
    } else {
      this.mensTimeOfPainRequiredFlag = false;
      this.mensCharOfPainRequiredFlag = false;
      this.mensTimeOfPainDisabledFlag = true;
      this.mensCharOfPainDisabledFlag = true;
      this.mensTimeOfPain = undefined;
      this.mensCharOfPain = undefined;
    }
  }

  changeMensDisorder(data) {
    if (data == "No") {
      this.mensNoOfDaysRequiredFlag = false;
      this.frequencyRequiredFlag = false;
      this.mensNoOfDaysDisabledFlag = true;
      this.freuencyDisabledFlag = true;
      this.mensNoOfDays = undefined;
      this.mensPainFreq = undefined;
    } else {
      this.mensNoOfDaysRequiredFlag = true;
      this.frequencyRequiredFlag = true;
      this.mensNoOfDaysDisabledFlag = false;
      this.freuencyDisabledFlag = false;
    }
  }

  changeSexualStatus(sexual) {
    if (sexual == "None") {
      this.difficultDisabledFlag = true;
      this.sexualSatDisabledFlag = true;
      this.sexualActiveDisabledFlag = true;
      this.typeOfSexDisabledFlag = true;
      this.contraceptDisabledFlag = true;
      this.infectDisabledFlag = true;
      this.otherIssueSexDisabledFlag = true;
      this.sexTypeOfPainDisabledFlag = true;
      this.typeOfContraceptDisabledFlag = true;
      this.reasonForDiscontDisabledFlag = true;
      this.complicationInSexDisabledFlag = true;
      this.reasonForDiscont = "";
      this.otherIssueSex = "";
      this.sexualDifficulties = "None";
      this.sexualTypeOfPain = "Superficial";
      this.sexualSatisfaction = undefined;
      this.sexualActive = undefined;
      this.typeOfSex = undefined;
      this.contracept = "None";
      this.typeOfContracept = undefined;
      this.reasonForDiscont = undefined;
      this.infection = "None";
      this.otherIssueSex = undefined;
      this.typeOfContraceptRequiredFlag = false;
      this.reasonForDiscontRequiredFlag = false;
    } else {
      this.difficultDisabledFlag = false;
      this.sexualSatDisabledFlag = false;
      this.sexualActiveDisabledFlag = false;
      this.typeOfSexDisabledFlag = false;
      this.contraceptDisabledFlag = false;
      this.infectDisabledFlag = false;
      this.otherIssueSexDisabledFlag = false;
    }
  }

  changeSexualDifficulties(diificult) {
    if (diificult == "None") {
      this.sexualTypeOfPain = "Superficial";
      this.sexTypeOfPainDisabledFlag = true;
    } else {
      this.sexTypeOfPainDisabledFlag = false;
    }
  }

  changeContracept(contra) {
    if (contra == "None") {
      this.reasonForDiscont = undefined;
      this.infection = this.infectionList[0].description;
      this.typeOfContraceptRequiredFlag = false;
      this.reasonForDiscontRequiredFlag = false;
      this.typeOfContraceptDisabledFlag = true;
      this.reasonForDiscontDisabledFlag = true;
      this.complicationInSexDisabledFlag = true;
    } else {
      this.typeOfContraceptRequiredFlag = true;
      this.reasonForDiscontRequiredFlag = false;
      this.typeOfContraceptDisabledFlag = false;
      this.reasonForDiscontDisabledFlag = false;
      this.complicationInSexDisabledFlag = false;
    }
  }

  getInfection() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'goi/sinf',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          for (var i = 0; i < obj.sexual_infections.length; i++) {
            this.infectionList = obj.sexual_infections;
            this.infection = this.infectionList[0].description;
          }
        },error => {
            this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  getFrequency() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'goi/pfreq',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          for (var i = 0; i < obj.pain_frequencies.length; i++) {
            this.painFreqlist = obj.pain_frequencies;
          }
        },error => {
            this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  getCharacterOfpain() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'goi/mpdet',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          for (var i = 0; i < obj.mens_pains.length; i++) {
            this.charOfPainList = obj.mens_pains;
          }
        },error => {
            this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  getStage() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'goi/msdet',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          for (var i = 0; i < obj.mens_stages.length; i++) {
            this.menstrualStageList = obj.mens_stages;
          }
        },error => {
            this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  contraceptiveTypes() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'goi/cceptive',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          for (var i = 0; i < obj.contraceptives.length; i++) {
            this.contraceptiveList = obj.contraceptives;
            this.typeOfContracept = this.contraceptiveList[0].description;
          }
        },error => {
            this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  changefasting() {
    if (this.fasting == true) {
      this.ogtt = false;
      this.fastOneDisabledFlag = true;
      this.fastOnehalfDisabledFlag = true;
    }
  }

  changeOgtt() {
    if (this.ogtt == true) {
      this.fasting = false;
      this.fastOneDisabledFlag = false;
      this.fastOnehalfDisabledFlag = false;
      this.fastingOnehour = "";
      this.fastingHalfhour = "";
    }
  }

  toggleExercise(e) {
    if (e == "Yes") {
      this.exerciseShowFlag = false;
      this.toggExerciseFlag = true
    } else {
      this.exerciseShowFlag = true;
      this.toggExerciseFlag = false;
    }
  }

  getQuantity() {
    this.alcQuantityList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'dental/aqty',
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          this.alcQuantityList = obj.mixing_quantity;
          this.alcQuantity = this.alcQuantityList[0].description;
        },error => {});
  }

  getMixingtype() {
    this.alcMissingList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'dental/amix',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.alcMissingList = obj.mixing_types;
          this.alcMix = this.alcMissingList[0].description;
        },error => {});
  }

  getExerciseRoutine() {
    this.exerRoutineList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'gen/exesrot/',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.exerRoutineList = obj.exercise_routine;
          this.exerRoutine = this.exerRoutineList[0].description;
        },error => {});
  }

  save() {
    var fam_supp = "no", alch = "no", smoke = "no", gutka = "no", gut_dur = undefined,
      gut_frq = undefined, smk_dur = undefined, smk_frq = undefined, alc_dur = undefined,
      alc_frq = undefined, qty_id = undefined, mix_id = undefined, exe_rout_id = undefined,
      exe_dur = undefined;
    if (this.toggFamilyFlag == true) {
      fam_supp = "yes";
    }
    if (this.toggGutkaFlag == true) {
      gut_dur = this.gutkaDuration;
      gut_frq = this.gutkaFrequency;
      gutka = "yes";
    }
    if (this.toggleSmokingFlag == true) {
      smk_dur = this.smokeDuration;
      smk_frq = this.smokeFrequency;
      smoke = "yes";
    }
    if (this.toggleAlcholFlag == true) {
      alch = "yes";
      alc_dur = this.alcholDuration;
      alc_frq = this.alcholFrequency;
      for (var i = 0; i < this.alcQuantityList.length; i++) {
        if (this.alcQuantity == this.alcQuantityList[i].description) {
          qty_id = this.alcQuantityList[i].alcohol_qty_id;
        }
      }
      for (var i = 0; i < this.alcMissingList.length; i++) {
        if (this.alcMix == this.alcMissingList[i].description) {
          mix_id = this.alcMissingList[i].mixing_id;
        }
      }
    }
    var exe_dur = undefined;
    if (this.toggExerciseFlag == true) {
      exe_dur = this.exerciseDuration;
      for (var i = 0; i < this.exerRoutineList.length; i++) {
        if (this.exerRoutine == this.exerRoutineList[i].description) {
          exe_rout_id = this.exerRoutineList[i].exer_rout_id;
        }
      }
    }
    if (this.currentOccupation != undefined) {
      this.currentOccupation = this.currentOccupation;
    } else {
      this.currentOccupation = null;
    }
    if (this.medicalHistoryRetrv != null) {
      this.sendmedicalHistory = this.medicalHistoryRetrv;
    } else {
      this.sendmedicalHistory = null;
    }
    var Pedia_surgical = null;
    var sur_flag = false;
    if (this.dc != undefined) {
      this.sendDC = (this.dc);
      sur_flag = true;
    }
    if (this.ovarianSurgery != undefined) {
      this.sendOvarianSurgery = (this.ovarianSurgery);
      sur_flag = true;
    }
    if (this.hysteroscopy != undefined) {
      this.sendHysteroscopy = (this.hysteroscopy);
      sur_flag = true;
    }
    if (this.infertilitySurgery != undefined) {
      this.sendInfertilitySurgery = (this.infertilitySurgery);
      sur_flag = true;
    }
    if (this.leftCystRemoved != undefined) {
      this.sendLeftCystRemoved = (this.leftCystRemoved);
      sur_flag = true;
    }
    if (this.rightCystRemoved != undefined) {
      this.sendRightCystRemoved = (this.rightCystRemoved);
      sur_flag = true;
    }
    if (this.tuboplasty != undefined) {
      this.sendTuboplasty = (this.tuboplasty);
      sur_flag = true;
    }
    if (this.leftOvaryRemoved != undefined) {
      this.sendLeftOvaryRemoved = (this.leftOvaryRemoved);
      sur_flag = true;
    }
    if (this.tubalLigation != undefined) {
      this.sendTubalLigation = (this.tubalLigation);
      sur_flag = true;
    }
    if (this.rightOvaryRemoved != undefined) {
      this.sendRightOvaryRemoved = (this.rightOvaryRemoved);
      sur_flag = true;
    }
    if (this.laparoscopy != undefined) {
      this.sendLaparoscopy = (this.laparoscopy);
      sur_flag = true;
    }
    if (this.hysterectomyVaginal != undefined) {
      sur_flag = true;
      this.sendHysterectomyVaginal = (this.hysterectomyVaginal);
    }
    if (this.hysterectomyAbdominal != undefined) {
      sur_flag = true;
      this.sendHysterectomyAbdominal = (this.hysterectomyAbdominal);
    }
    if (this.myomectomy != undefined) {
      sur_flag = true;
      this.sendMyomectomy = (this.myomectomy);
    }
    if (this.bladderRepair != undefined) {
      sur_flag = true;
      this.sendBladderRepair = (this.bladderRepair);
    }
    if (this.surgeHistId != null) {
      this.surgeryFlag = "yes"
    } else {
      if (sur_flag == true) {
        this.surgeryFlag = "yes"
      } else {
        this.surgeryFlag = "no"
      }
    }
    if (this.sexualSatisfaction != undefined && this.sexualSatisfaction != "") {
      this.sendSexualSatisfaction = this.sexualSatisfaction;
    } else {
      this.sendSexualSatisfaction = null;
    }
    if (this.sexualActive != undefined) {
      this.sendSexualActive = this.sexualActive;
    } else {
      this.sendSexualActive = null
    }
    if (this.typeOfSex != undefined && this.typeOfSex != "") {
      this.sendTypeOfSex = this.typeOfSex;
    } else {
      this.sendTypeOfSex = "";
    }
    if (this.contracept != undefined && this.contracept != "") {
      this.sendContracept = this.contracept;
    } else {
      this.sendContracept = "";
    }
    if (this.typeOfContracept != undefined && this.typeOfContracept != "") {
      for (var i = 0; i < this.contraceptiveList.length; i++) {
        if (this.typeOfContracept == this.contraceptiveList[i].description) {
          this.sendContraceptiveId = this.contraceptiveList[i].contraceptive_id;
        }
      }
    } else {
      this.sendContraceptiveId = null;
    }
    if (this.infection != undefined && this.infection != "") {
      for (var i = 0; i < this.infectionList.length; i++) {
        if (this.infection == this.infectionList[i].description) {
          this.sexInfectionId = this.infectionList[i].sex_infect_id;
        }
      }
    } else {
      this.sexInfectionId = "";
    }
    if (this.reasonForDiscont != undefined) {
      this.sendReasonForDiscont = this.reasonForDiscont;
    } else {
      this.sendReasonForDiscont = "";
    }
    if (this.otherIssueSex != undefined) {
      this.sexOthdata = this.otherIssueSex;
    } else {
      this.sexOthdata = "";
    }
    for (var i = 0; i < this.menstrualStageList.length; i++) {
      if (this.stageDesc == this.menstrualStageList[i].description) {
        this.stageId = this.menstrualStageList[i].mens_stage_id;
      }
    }
    if (this.mensStageDate != undefined) {
      this.mensStageDateFlag = "yes";
      this.sendMensStageDate = this.mensStageDate;
    }
    var cyclefreq_obstric = this.frequencyLength + " " + this.cycleLength;
    if (this.lastMensPeriod != undefined) {
      this.sendLastMensDate = this.lastMensPeriod;
    } else {
      this.sendLastMensDate = null;
    }
    this.sendMensFreqLength = this.frequencyLength + " " + this.cycleLength;
    this.sendMensPain = this.menstrualPain;
    if (this.menstrualPain == "Yes") {
      this.sendMensTimeOfPain = this.mensTimeOfPain;
    }
    if (this.menstrualPain == "Yes") {
      for (var i = 0; i < this.charOfPainList.length; i++) {
        if (this.mensCharOfPain == this.charOfPainList[i].description) {
          this.sendCharOfPainId = this.charOfPainList[i].mens_pain_id;
        }
      }
    }
    this.sendMensDisorder = this.mensDisorder;
    for (var i = 0; i < this.painFreqlist.length; i++) {
      if (this.mensPainFreq == this.painFreqlist[i].description) {
        this.sendpainFreqlistId = this.painFreqlist[i].pain_freq_id;
      }
    }
    if (this.medicalHistoryRetrv != null) {
      this.sendmedicalHistory = this.medicalHistoryRetrv;
    } else {
      this.sendmedicalHistory = null;
    }
    var Immunizaion_send = null;
    this.vaccineList = [];
    this.mainInfant = [];
    this.cognitiveList = [];
    this.languageList = [];
      if (this.mainVaccineData["Birth"] != undefined) {
        for (var i = 0; i < this.mainVaccineData["Birth"].length; i++) {
          if (this.mainVaccineData["Birth"][i].given_date != null) {
            if (this.mainVaccineData["Birth"][i].due_date != undefined) {
              this.dueDate = Date_Formate(this.mainVaccineData["Birth"][i].due_date)
            }
            if (this.mainVaccineData["Birth"][i].given_date != undefined) {
              this.giveDate = Date_Formate(this.mainVaccineData["Birth"][i].given_date)
            }
            var Temp_Data = {
              ped_vaccine_age_id: this.mainVaccineData["Birth"][i].ped_vaccine_age_id,
              due_date: this.dueDate,
              given_date: this.giveDate,
              remarks: this.mainVaccineData["Birth"][i].remarks,
            }
            this.vaccineList.push(Temp_Data);
          }
        }
      }
      if (this.mainVaccineData["6 weeks"] != undefined) {
        for (var i = 0; i < this.mainVaccineData["6 weeks"].length; i++) {
          if (this.mainVaccineData["6 weeks"][i].given_date != null) {
            if (this.mainVaccineData["6 weeks"][i].due_date != undefined) {
              this.duedate1 = Date_Formate(this.mainVaccineData["6 weeks"][i].due_date)
            }
            if (this.mainVaccineData["6 weeks"][i].given_date != undefined) {
              this.giveDate1 = Date_Formate(this.mainVaccineData["6 weeks"][i].given_date)
            }
            Temp_Data = {
              ped_vaccine_age_id: this.mainVaccineData["6 weeks"][i].ped_vaccine_age_id,
              due_date: this.duedate1,
              given_date: this.giveDate1,
              remarks: this.mainVaccineData["6 weeks"][i].remarks,
            }
            this.vaccineList.push(Temp_Data);
          }
        }
      }
      if (this.mainVaccineData["10 weeks"] != undefined) {
        for (var i = 0; i < this.mainVaccineData["10 weeks"].length; i++) {
          if (this.mainVaccineData["10 weeks"][i].given_date != null) {
            if (this.mainVaccineData["10 weeks"][i].due_date != undefined) {
              this.dueDate2 = Date_Formate(this.mainVaccineData["10 weeks"][i].due_date)
            }
            if (this.mainVaccineData["10 weeks"][i].given_date != undefined) {
              this.giveDate2 = Date_Formate(this.mainVaccineData["10 weeks"][i].given_date)
            }
            Temp_Data = {
              ped_vaccine_age_id: this.mainVaccineData["10 weeks"][i].ped_vaccine_age_id,
              due_date: this.dueDate2,
              given_date: this.giveDate2,
              remarks: this.mainVaccineData["10 weeks"][i].remarks,
            }
            this.vaccineList.push(Temp_Data);
          }
        }
      }
      if (this.mainVaccineData["14 weeks"] != undefined) {
        for (var i = 0; i < this.mainVaccineData["14 weeks"].length; i++) {
          if (this.mainVaccineData["14 weeks"][i].given_date != null) {
            if (this.mainVaccineData["14 weeks"][i].due_date != undefined) {
              this.dueDate3 = Date_Formate(this.mainVaccineData["14 weeks"][i].due_date)
            }
            if (this.mainVaccineData["14 weeks"][i].given_date != undefined) {
              this.giveDate3 = Date_Formate(this.mainVaccineData["14 weeks"][i].given_date)
            }
            Temp_Data = {
              ped_vaccine_age_id: this.mainVaccineData["14 weeks"][i].ped_vaccine_age_id,
              due_date: this.dueDate3,
              given_date: this.giveDate3,
              remarks: this.mainVaccineData["14 weeks"][i].remarks,
            }
            this.vaccineList.push(Temp_Data);
          }
        }
      }
      if (this.mainVaccineData["6 months"] != undefined) {
        for (var i = 0; i < this.mainVaccineData["6 months"].length; i++) {
          if (this.mainVaccineData["6 months"][i].given_date != null) {
            if (this.mainVaccineData["6 months"][i].due_date != undefined) {
              this.dueDate4 = Date_Formate(this.mainVaccineData["6 months"][i].due_date)
            }
            if (this.mainVaccineData["6 months"][i].given_date != undefined) {
              this.giveDate4 = Date_Formate(this.mainVaccineData["6 months"][i].given_date)
            }
            Temp_Data = {
              ped_vaccine_age_id: this.mainVaccineData["6 months"][i].ped_vaccine_age_id,
              due_date: this.dueDate4,
              given_date: this.giveDate4,
              remarks: this.mainVaccineData["6 months"][i].remarks,
            }
            this.vaccineList.push(Temp_Data);
          }
        }
      }
      if (this.mainVaccineData["9 months"] != undefined) {
        for (var i = 0; i < this.mainVaccineData["9 months"].length; i++) {
          if (this.mainVaccineData["9 months"][i].given_date != null) {
            if (this.mainVaccineData["9 months"][i].due_date != undefined) {
              this.dueDate5 = Date_Formate(this.mainVaccineData["9 months"][i].due_date)
            }
            if (this.mainVaccineData["9 months"][i].given_date != undefined) {
              this.giveDate5 = Date_Formate(this.mainVaccineData["9 months"][i].given_date)
            }
            Temp_Data = {
              ped_vaccine_age_id: this.mainVaccineData["9 months"][i].ped_vaccine_age_id,
              due_date: this.dueDate5,
              given_date: this.giveDate5,
              remarks: this.mainVaccineData["9 months"][i].remarks,
            }
            this.vaccineList.push(Temp_Data);
          }
        }
      }
      if (this.mainVaccineData["15 - 18 months"] != undefined) {
        for (var i = 0; i < this.mainVaccineData["15 - 18 months"].length; i++) {
          if (this.mainVaccineData["15 - 18 months"][i].given_date != null) {
            if (this.mainVaccineData["15 - 18 months"][i].due_date != undefined) {
              this.dueDate6 = Date_Formate(this.mainVaccineData["15 - 18 months"][i].due_date)
            }
            if (this.mainVaccineData["15 - 18 months"][i].given_date != undefined) {
              this.giveDate6 = Date_Formate(this.mainVaccineData["15 - 18 months"][i].given_date)
            }
            Temp_Data = {
              ped_vaccine_age_id: this.mainVaccineData["15 - 18 months"][i].ped_vaccine_age_id,
              due_date: this.dueDate6,
              given_date: this.giveDate6,
              remarks: this.mainVaccineData["15 - 18 months"][i].remarks,
            }
            this.vaccineList.push(Temp_Data);
          }
        }
      }
      if (this.mainVaccineData["2 years"] != undefined) {
        for (var i = 0; i < this.mainVaccineData["2 years"].length; i++) {
          if (this.mainVaccineData["2 years"][i].given_date != null) {
            if (this.mainVaccineData["2 years"][i].due_date != undefined) {
              this.dueDate7 = Date_Formate(this.mainVaccineData["2 years"][i].due_date)
            }
            if (this.mainVaccineData["2 years"][i].given_date != undefined) {
              this.giveDate7 = Date_Formate(this.mainVaccineData["2 years"][i].given_date)
            }
            Temp_Data = {
              ped_vaccine_age_id: this.mainVaccineData["2 years"][i].ped_vaccine_age_id,
              due_date: this.dueDate7,
              given_date: this.giveDate7,
              remarks: this.mainVaccineData["2 years"][i].remarks,
            }
            this.vaccineList.push(Temp_Data);
          }
        }
      }
      if (this.mainVaccineData["5 years"] != undefined) {
        for (var i = 0; i < this.mainVaccineData["5 years"].length; i++) {
          if (this.mainVaccineData["5 years"][i].due_date != undefined) {
            this.dueDate8 = Date_Formate(this.mainVaccineData["5 years"][i].due_date)
          }
          if (this.mainVaccineData["5 years"][i].given_date != undefined) {
            this.giveDate8 = Date_Formate(this.mainVaccineData["5 years"][i].given_date)
          }
          if (this.mainVaccineData["5 years"][i].given_date != null) {
            Temp_Data = {
              ped_vaccine_age_id: this.mainVaccineData["5 years"][i].ped_vaccine_age_id,
              due_date: this.dueDate8,
              given_date: this.giveDate8,
              remarks: this.mainVaccineData["5 years"][i].remarks,
            }
            this.vaccineList.push(Temp_Data);
          }
        }
      }
      if (this.mainVaccineData["10 years"] != undefined) {
        for (var i = 0; i < this.mainVaccineData["10 years"].length; i++) {
          if (this.mainVaccineData["10 years"][i].due_date != undefined) {
            this.dueDate9 = Date_Formate(this.mainVaccineData["10 years"][i].due_date)
          }
          if (this.mainVaccineData["10 years"][i].given_date != undefined) {
            this.giveDate9 = Date_Formate(this.mainVaccineData["10 years"][i].given_date)
          }
          if (this.mainVaccineData["10 years"][i].given_date != null) {
            Temp_Data = {
              ped_vaccine_age_id: this.mainVaccineData["10 years"][i].ped_vaccine_age_id,
              due_date: this.dueDate9,
              given_date: this.giveDate9,
              remarks: this.mainVaccineData["10 years"][i].remarks,
            }
            this.vaccineList.push(Temp_Data);
          }
        }
      }
      if (this.mainVaccineData["15-16 years"] != undefined) {
        for (var i = 0; i < this.mainVaccineData["15-16 years"].length; i++) {
          if (this.mainVaccineData["15-16 years"][i].due_date != undefined) {
            this.dueDate10 = Date_Formate(this.mainVaccineData["15-16 years"][i].due_date)
          }
          if (this.mainVaccineData["15-16 years"][i].given_date != undefined) {
            this.giveDate10 = Date_Formate(this.mainVaccineData["15-16 years"][i].given_date)
          }
          if (this.mainVaccineData["15-16 years"][i].given_date != null) {
            Temp_Data = {
              ped_vaccine_age_id: this.mainVaccineData["15-16 years"][i].ped_vaccine_age_id,
              due_date: this.dueDate10,
              given_date: this.giveDate10,
              remarks: this.mainVaccineData["15-16 years"][i].remarks,
            }
            this.vaccineList.push(Temp_Data);
          }
        }
      }
      if (this.oneYearDecideTeeth == "yes") {
        this.condition = "Normal";
      } else {
        this.condition = "Abnormal";
      }
      if (this.oneYearDecideTeethRemarks != undefined) {
        this.oneDecide = this.oneYearDecideTeethRemarks;
      } else {
        this.oneDecide = "";
      }
      var child_decided = {
        age_group: "Infant",
        develop_type: "Deciduous teeth",
        remarks: this.oneDecide,
        condition: this.condition,
      };
      this.mainInfant.push(child_decided);
      if (this.oneYearDentalCaries == "yes") {
        this.dentalCaries = "Yes";
      } else {
        this.dentalCaries = "No";
      }
      if (this.oneYearDentalCariesRemarks != undefined) {
        this.oneYearCarRemarks = this.oneYearDentalCariesRemarks;
      } else {
        this.oneYearCarRemarks = "";
      }
      var dental_caries_data = {
        age_group: "Infant",
        develop_type: "Dental caries",
        remarks: this.oneYearCarRemarks,
        condition: this.dentalCaries,
      };
      this.mainInfant.push(dental_caries_data);
      if (this.oneYearObjectPerformance == "yes") {
        this.objectPerformance = "Normal";
      } else {
        this.objectPerformance = "Abnormal";
      }
      if (this.oneYearObjectRemarks != undefined) {
        this.objectRemark = this.oneYearObjectRemarks;
      } else {
        this.objectRemark = "";
      }
      var object_perfomance_array = {
        age_group: "Infant",
        develop_type: "Object permanence",
        remarks: this.objectRemark,
        condition: this.objectPerformance,
      };
      this.mainInfant.push(object_perfomance_array);
      if (this.oneYearSleepPattern == "yes") {
        this.sleepPattren = "Normal";
      } else {
        this.sleepPattren = "Abnormal";
      }
      if (this.oneYearSleepRemarks != undefined) {
        this.oneSleep = this.oneYearSleepRemarks;
      } else {
        this.oneSleep = "";
      }
      var sleep_pattren_array = {
        age_group: "Infant",
        develop_type: "Sleep pattern",
        remarks: this.oneSleep,
        condition: this.sleepPattren,
      };
      this.mainInfant.push(sleep_pattren_array);
      if (this.twoYearDentionTeeth == "yes") {
        this.twoYearDention = "Normal";
      } else {
        this.twoYearDention = "Abnormal";
      }
      if (this.twoYearDentionRemarks != undefined) {
        this.twoDention = this.twoYearDentionRemarks;
      } else {
        this.twoDention = "";
      }
      var twoyear_dental_array = {
        age_group: "Toddler",
        develop_type: "Dentition",
        remarks: this.twoDention,
        condition: this.twoYearDention,
      };
      this.mainInfant.push(twoyear_dental_array);
      if (this.twoYearMoralDevlp == "yes") {
        this.twoYearMoral = "Yes";
      } else {
        this.twoYearMoral = "No";
      }
      if (this.twoYearMoralRemarks != undefined) {
        this.twoMoral = this.twoYearMoralRemarks;
      } else {
        this.twoMoral = "";
      }
      var twoyear_moral_array = {
        age_group: "Toddler",
        develop_type: "Moral development",
        remarks: this.twoMoral,
        condition: this.twoYearMoral,
      };
      this.mainInfant.push(twoyear_moral_array);
      if (this.twoYearPhyschoSocial == "yes") {
        this.twoYearPhyscio = "Normal";
      } else {
        this.twoYearPhyscio = "Abnormal";
      }
      if (this.twoYearPhyscoRemarks != undefined) {
        this.twoPhysco = this.twoYearMoralRemarks;
      } else {
        this.twoPhysco = "";
      }
      var twoyear_physco_array = {
        age_group: "Toddler",
        develop_type: "Psycho-social development",
        remarks: this.twoPhysco,
        condition: this.twoYearPhyscio,
      };
      this.mainInfant.push(twoyear_physco_array);
      if (this.twoYearToiletTraining == "yes") {
        this.twoYearToilet = "Normal";
      } else {
        this.twoYearToilet = "Abnormal";
      }
      if (this.twoYearToiletRemarks != undefined) {
        this.twoToilet = this.twoYearToiletRemarks;
      } else {
        this.twoToilet = "";
      }
      var twoyear_toilet_array = {
        age_group: "Toddler",
        develop_type: "Toilet training",
        remarks: this.twoToilet,
        condition: this.twoYearToilet,
      };
      this.mainInfant.push(twoyear_toilet_array);
      if (this.twoYearSleep == "yes") {
        this.twoYearSleeps = "Normal";
      } else {
        this.twoYearSleeps = "Abnormal";
      }
      if (this.twoYearSleepRemarks != undefined) {
        this.twoSleep = this.twoYearSleepRemarks;
      } else {
        this.twoSleep = "";
      }
      var twoyear_sleep_array = {
        age_group: "Toddler",
        develop_type: "Sleep pattern",
        remarks: this.twoSleep,
        condition: this.twoYearSleeps,
      };
      this.mainInfant.push(twoyear_sleep_array);
      if (this.twoYearPlayingPattren == "yes") {
        this.twoYearPlay = "Normal";
      } else {
        this.twoYearPlay = "Abnormal";
      }
      if (this.twoYearPlayRemarks != undefined) {
        this.twoPlay = this.twoYearPlayRemarks;
      } else {
        this.twoPlay = "";
      }
      var twoyear_playing_array = {
        age_group: "Toddler",
        develop_type: "Playing pattern",
        remarks: this.twoPlay,
        condition: this.twoYearPlay,
      };
      this.mainInfant.push(twoyear_playing_array);
      if (this.threeYearDentionTeeth == "yes") {
        this.threeDental = "Normal";
      } else {
        this.threeDental = "Abnormal";
      }
      if (this.threeYearDentionRemarks != undefined) {
        this.threeDent = this.threeYearDentionRemarks;
      } else {
        this.threeDent = "";
      }
      var three_dental_array = {
        age_group: "Preschool",
        develop_type: "Dentition",
        remarks: this.threeDent,
        condition: this.threeDental,
      };
      this.mainInfant.push(three_dental_array);
      if (this.threeYearmoral == "yes") {
        this.threeMoral = "Yes";
      } else {
        this.threeMoral = "No";
      }
      if (this.threeYearMoralRemarks != undefined) {
        this.threeMoralData = this.threeYearMoralRemarks;
      } else {
        this.threeMoralData = "";
      }
      var three_moral_array = {
        age_group: "Preschool",
        develop_type: "Moral development",
        remarks: this.threeMoralData,
        condition: this.threeMoral,
      };
      this.mainInfant.push(three_moral_array);
      if (this.threeYearPhyschoSocial == "yes") {
        this.threePhysco = "Normal";
      } else {
        this.threePhysco = "Abnormal";
      }
      if (this.threeYearPhyschoRemarks != undefined) {
        this.threePhyschoData = this.threeYearPhyschoRemarks;
      } else {
        this.threePhyschoData = "";
      }
      var three_physio_array = {
        age_group: "Preschool",
        develop_type: "Psycho-social development",
        remarks: this.threePhyschoData,
        condition: this.threePhysco,
      };
      this.mainInfant.push(three_physio_array);
      if (this.threeYearToiletTraining == "yes") {
        this.threeToilet = "Normal";
      } else {
        this.threeToilet = "Abnormal";
      }
      if (this.threeYearToiletRemarks != undefined) {
        this.threeToiletData = this.threeYearToiletRemarks;
      } else {
        this.threeToiletData = "";
      }
      var three_toilet_array = {
        age_group: "Preschool",
        develop_type: "Toilet training",
        remarks: this.threeToiletData,
        condition: this.threeToilet,
      };
      this.mainInfant.push(three_toilet_array);
      if (this.threeYearSleep == "yes") {
        this.threeSleep = "Normal";
      } else {
        this.threeSleep = "Abnormal";
      }
      if (this.threeYearSleepRemarks != undefined) {
        this.threeSleepData = this.threeYearSleepRemarks;
      } else {
        this.threeSleepData = "";
      }
      var three_sleep_array = {
        age_group: "Preschool",
        develop_type: "Sleep pattern",
        remarks: this.threeSleepData,
        condition: this.threeSleep,
      };
      this.mainInfant.push(three_sleep_array);
      if (this.threeYearPlayingPattren == "yes") {
        this.threePlaying = "Normal";
      } else {
        this.threePlaying = "Abnormal";
      }
      if (this.threeYearPlayingRemarks != undefined) {
        this.threePlayingData = this.threeYearPlayingRemarks;
      } else {
        this.threePlayingData = "";
      }
      var three_playing_array = {
        age_group: "Preschool",
        develop_type: "Playing pattern",
        remarks: this.threePlayingData,
        condition: this.threePlaying,
      };
      this.mainInfant.push(three_playing_array);
      for (var i = 0; i < this.develCog1To2monthData.length; i++) {
        if (this.develCog1To2monthData[i].No == true) {
          this.develpCog = "Abnormal";
        } else {
          this.develpCog = "Normal";
        }
        var cognitive_onetwo = {
          condition: this.develpCog,
          cognitive_id: this.develCog1To2monthData[i].Cognitive_ID,
          remarks: this.develCog1To2monthData[i].remarks,
        };
        this.cognitiveList.push(cognitive_onetwo);
      }
      for (var i = 0; i < this.develCog3monthData.length; i++) {
        if (this.develCog3monthData[i].No == true) {
          this.devlpCogThreeMon = "No";
        } else {
          this.devlpCogThreeMon = "Yes";
        }
        var cognitive_lan = {
          condition: this.devlpCogThreeMon,
          cognitive_id: this.develCog3monthData[i].Cognitive_ID,
          remarks: this.develCog3monthData[i].remarks,
        };
        this.cognitiveList.push(cognitive_lan);
      }
      for (var i = 0; i < this.develCog4To5monthData.length; i++) {
        if (this.develCog4To5monthData[i].No == true) {
          this.devlpCogFiveMon = "No";
        } else {
          this.devlpCogFiveMon = "Yes";
        }
        var cognitive_four = {
          condition: this.devlpCogFiveMon,
          cognitive_id: this.develCog4To5monthData[i].Cognitive_ID,
          remarks: this.develCog4To5monthData[i].remarks,
        };
        this.cognitiveList.push(cognitive_four);
      }
      for (var i = 0; i < this.develCog6To7monthData.length; i++) {
        if (this.develCog6To7monthData[i].No == true) {
          this.devlpCogSixToSevenMon = "No";
        } else {
          this.devlpCogSixToSevenMon = "Yes";
        }
        var cognitive_six = {
          condition: this.devlpCogSixToSevenMon,
          cognitive_id: this.develCog6To7monthData[i].Cognitive_ID,
          remarks: this.develCog6To7monthData[i].remarks,
        };
        this.cognitiveList.push(cognitive_six);
      }
      for (var i = 0; i < this.develCog8To9monthData.length; i++) {
        if (this.develCog8To9monthData[i].No == true) {
          this.devlpCogEightMon = "No";
        } else {
          this.devlpCogEightMon = "Yes";
        }
        var cognitive_eight = {
          condition: this.devlpCogEightMon,
          cognitive_id: this.develCog8To9monthData[i].Cognitive_ID,
          remarks: this.develCog8To9monthData[i].remarks,
        };
        this.cognitiveList.push(cognitive_eight);
      }
      for (var i = 0; i < this.develCog10To12monthData.length; i++) {
        if (this.develCog10To12monthData[i].No == true) {
          this.devlpCogTenMon = "No";
        } else {
          this.devlpCogTenMon = "Yes";
        }
        var cognitive_ten = {
          condition: this.devlpCogTenMon,
          cognitive_id: this.develCog10To12monthData[i].Cognitive_ID,
          remarks: this.develCog10To12monthData[i].remarks,
        };
        this.cognitiveList.push(cognitive_ten);
      }
      for (var i = 0; i < this.develLang1To2monthData.length; i++) {
        if (this.develLang1To2monthData[i].No == true) {
          this.lanOneMon = "No";
        } else {
          this.lanOneMon = "Yes";
        }
        var lan_onelist = {
          condition: this.lanOneMon,
          language_id: this.develLang1To2monthData[i].Cognitive_ID,
          remarks: this.develLang1To2monthData[i].remarks,
        };
        this.languageList.push(lan_onelist);
      }
      for (var i = 0; i < this.develLang3monthData.length; i++) {
        if (this.develLang3monthData[i].No == true) {
          this.lanThreeMon = "No";
        } else {
          this.lanThreeMon = "Yes";
        }
        var lan_threelist = {
          condition: this.lanThreeMon,
          language_id: this.develLang3monthData[i].Cognitive_ID,
          remarks: this.develLang3monthData[i].remarks,
        };
        this.languageList.push(lan_threelist);
      }
      for (var i = 0; i < this.develLang4To5monthData.length; i++) {
        if (this.develLang4To5monthData[i].No == true) {
          this.lanFiveMon = "No";
        } else {
          this.lanFiveMon = "Yes";
        }
        var lan_fivelist = {
          condition: this.lanFiveMon,
          language_id: this.develLang4To5monthData[i].Cognitive_ID,
          remarks: this.develLang4To5monthData[i].remarks,
        };
        this.languageList.push(lan_fivelist);
      }
      for (var i = 0; i < this.develLang6To7monthData.length; i++) {
        if (this.develLang6To7monthData[i].No == true) {
          this.lanSixMon = "No";
        } else {
          this.lanSixMon = "Yes";
        }
        var lan_sixlist = {
          condition: this.lanSixMon,
          language_id: this.develLang6To7monthData[i].Cognitive_ID,
          remarks: this.develLang6To7monthData[i].remarks,
        };
        this.languageList.push(lan_sixlist);
      }
      for (var i = 0; i < this.develLang8To9monthData.length; i++) {
        if (this.develLang8To9monthData[i].No == true) {
          this.lanEightMon = "No";
        } else {
          this.lanEightMon = "Yes";
        }
        var lan_eightlist = {
          condition: this.lanEightMon,
          language_id: this.develLang8To9monthData[i].Cognitive_ID,
          remarks: this.develLang8To9monthData[i].remarks,
        };
        this.languageList.push(lan_eightlist);
      }
      for (var i = 0; i < this.develLang10To12monthData.length; i++) {
        if (this.develLang10To12monthData[i].No == true) {
          this.lanTenMon = "No";
        } else {
          this.lanTenMon = "Yes";
        }
        var lan_tenlist = {
          condition: this.lanTenMon,
          language_id: this.develLang10To12monthData[i].Cognitive_ID,
          remarks: this.develLang10To12monthData[i].remarks,
        };
        this.languageList.push(lan_tenlist);
      }
      for (var i = 0; i < this.develCog15monthData.length; i++) {
        if (this.develCog15monthData[i].No == true) {
          this.devlpCogFifteeneMon = "No";
        } else {
          this.devlpCogFifteeneMon = "Yes";
        }
        var dev_fiflist = {
          condition: this.devlpCogFifteeneMon,
          cognitive_id: this.develCog15monthData[i].Cognitive_ID,
          remarks: this.develCog15monthData[i].remarks,
        };
        this.cognitiveList.push(dev_fiflist);
      }
      for (var i = 0; i < this.develCog18monthData.length; i++) {
        if (this.develCog18monthData[i].No == true) {
          this.devlpCogEighteenMon = "No";
        } else {
          this.devlpCogEighteenMon = "Yes";
        }
        var dev_eighteenlist = {
          condition: this.devlpCogEighteenMon,
          cognitive_id: this.develCog18monthData[i].Cognitive_ID,
          remarks: this.develCog18monthData[i].remarks,
        };
        this.cognitiveList.push(dev_eighteenlist);
      }
      for (var i = 0; i < this.develCog24monthData.length; i++) {
        if (this.develCog24monthData[i].No == true) {
          this.devlpCogtwenfourMon = "No";
        } else {
          this.devlpCogtwenfourMon = "Yes";
        }
        var dev_twenflist = {
          condition: this.devlpCogtwenfourMon,
          cognitive_id: this.develCog24monthData[i].Cognitive_ID,
          remarks: this.develCog24monthData[i].remarks,
        };
        this.cognitiveList.push(dev_twenflist);
      }
      for (var i = 0; i < this.develLang15monthData.length; i++) {
        if (this.develLang15monthData[i].No == true) {
          this.lanFifteenMon = "No";
        } else {
          this.lanFifteenMon = "Yes";
        }
        var lan_fiflist = {
          condition: this.lanFifteenMon,
          language_id: this.develLang15monthData[i].Cognitive_ID,
          remarks: this.develLang15monthData[i].remarks,
        };
        this.languageList.push(lan_fiflist);
      }
      for (var i = 0; i < this.develLang18monthData.length; i++) {
        if (this.develLang18monthData[i].No == true) {
          this.lanEightteenMon = "No";
        } else {
          this.lanEightteenMon = "Yes";
        }
        var lan_eighteenlist = {
          condition: this.lanEightteenMon,
          language_id: this.develLang18monthData[i].Cognitive_ID,
          remarks: this.develLang18monthData[i].remarks,
        };
        this.languageList.push(lan_eighteenlist);
      }
      for (var i = 0; i < this.develLang24monthData.length; i++) {
        if (this.develLang24monthData[i].No == true) {
          this.lanTwentfMon = "No";
        } else {
          this.lanTwentfMon = "Yes";
        }
        var lan_twenflist = {
          condition: this.lanTwentfMon,
          language_id: this.develLang24monthData[i].Cognitive_ID,
          remarks: this.develLang24monthData[i].remarks,
        };
        this.languageList.push(lan_twenflist);
      }
      for (var i = 0; i < this.develCog3yearData.length; i++) {
        if (this.develCog3yearData[i].No == true) {
          this.cogThreeYear = "No";
        } else {
          this.cogThreeYear = "Yes";
        }
        var cog_threeylist = {
          condition: this.cogThreeYear,
          cognitive_id: this.develCog3yearData[i].Cognitive_ID,
          remarks: this.develCog3yearData[i].remarks,
        };
        this.cognitiveList.push(cog_threeylist);
      }
      for (var i = 0; i < this.develCog4yearData.length; i++) {
        if (this.develCog4yearData[i].No == true) {
          this.cogFourYear = "No";
        } else {
          this.cogFourYear = "Yes";
        }
        var cog_fouryearlist = {
          condition: this.cogFourYear,
          cognitive_id: this.develCog4yearData[i].Cognitive_ID,
          remarks: this.develCog4yearData[i].remarks,
        };
        this.cognitiveList.push(cog_fouryearlist);
      }
      for (var i = 0; i < this.develCog5yearData.length; i++) {
        if (this.develCog5yearData[i].No == true) {
          this.cogFiveYear = "No";
        } else {
          this.cogFiveYear = "Yes";
        }
        var cog_fiveyearlist = {
          condition: this.cogFiveYear,
          cognitive_id: this.develCog5yearData[i].Cognitive_ID,
          remarks: this.develCog5yearData[i].remarks,
        };
        this.cognitiveList.push(cog_fiveyearlist);
      }
      for (var i = 0; i < this.develLang3yearData.length; i++) {
        if (this.develLang3yearData[i].No == true) {
          this.lanThreeYear = "No";
        } else {
          this.lanThreeYear = "Yes";
        }
        var lan_threeyearlist = {
          condition: this.lanThreeYear,
          language_id: this.develLang3yearData[i].Cognitive_ID,
          remarks: this.develLang3yearData[i].remarks,
        };
        this.languageList.push(lan_threeyearlist);
      }
      for (var i = 0; i < this.develLang4yearData.length; i++) {
        if (this.develLang4yearData[i].No == true) {
          this.lanFourYear = "No";
        } else {
          this.lanFourYear = "Yes";
        }
        var lan_fouryearlist = {
          condition: this.lanFourYear,
          language_id: this.develLang4yearData[i].Cognitive_ID,
          remarks: this.develLang4yearData[i].remarks,
        };
        this.languageList.push(lan_fouryearlist);
      }
      for (var i = 0; i < this.develLang5yearData.length; i++) {
        if (this.develLang5yearData[i].No == true) {
          this.lanFiveYear = "No";
        } else {
          this.lanFiveYear = "Yes";
        }
        var lan_fiveyearlist = {
          condition: this.lanFiveYear,
          language_id: this.develLang5yearData[i].Cognitive_ID,
          remarks: this.develLang5yearData[i].remarks,
        };
        this.languageList.push(lan_fiveyearlist);
      }
      var pedia_development = null;
      if (this.mainInfant.length == 0) {
        this.mainInfant = undefined;
      }
    if (this.sexualStatus != undefined && this.sexualStatus != "None") {
      this.sendsexualStatusFlag = "yes";
    }
    this.sendMensFreqLength = this.frequencyLength + " " + this.cycleLength;
    if(this.presentIllnessRetrv.length != 0){
      this.sendPresentIllnessFlag = "yes";
    }
    var send_data = {
      client_reg_id: this.clientRegId,
      relation_id: this.relationId,
      sub_rel_id: this.subRelId,
      gender: this.gender,
      spl_id: this.splId,
      current_occupation: this.currentOccupation,
      maritial_status: this.maritialStatus,
      physical_activities: this.physicalActivities,
      takingmed: this.anyMedicine,
      medicines: this.medicine,
      stressed: this.feelStress,
      stress_factor: this.stressFactor,
      prev_hosp_visit: this.hosp,
      hosp_visit_det: this.hospDetails,
      symptom: this.symptom,
      heartdisorder: this.hdisorder,
      hdisordertreat: this.treat,
      habits: this.childHabitRetrv,
      marital_status: this.maritialStatus,
      drug_allergy: this.drugAllergy,
      drugs: this.drugs,
      height: this.height,
      height_measure: this.heightMeasure,
      weight: this.weight,
      weight_measure: this.weightMeasure,
      body_type: this.bodyType,
      diet_habits: this.dietHabits,
      gutka: gutka,
      gutka_duration: gut_dur,
      gutka_frequency: gut_frq,
      smoke: smoke,
      smoke_duration: smk_dur,
      smoke_frequency: smk_frq,
      mixing_type: mix_id,
      quantity: qty_id,
      alc_duration: alc_dur,
      alc_frequency: alc_frq,
      alcohol: alch,
      family_support: fam_supp,
      exercise_dur: exe_dur,
      exercise_routine_des: this.exerRoutine,
      exercise_routine: exe_rout_id,
      main_concern: this.mainConcernRetrv,
      medical_history: this.sendmedicalHistory,
      present_illness: this.presentIllnessRetrv,
      present_illness_flag:this.sendPresentIllnessFlag,
      d_c: this.sendDC,
      ovarian_surgery: this.sendOvarianSurgery,
      hysteroscopy: this.sendHysteroscopy,
      infertility_surgery: this.sendInfertilitySurgery,
      left_cyst_removed: this.sendLeftCystRemoved,
      right_cyst_removed: this.sendRightCystRemoved,
      tuboplasty: this.sendTuboplasty,
      left_ovary_removed: this.sendLeftOvaryRemoved,
      tubal_ligation: this.sendTubalLigation,
      right_ovary_removed: this.sendRightOvaryRemoved,
      laparoscopy: this.sendLaparoscopy,
      hysterectomy_vaginal: this.sendHysterectomyVaginal,
      hysterectomy_abdominal: this.sendHysterectomyAbdominal,
      myomectomy: this.sendMyomectomy,
      bladder_repair: this.sendBladderRepair,
      surgery_others: this.others,
      mens_stage: this.stageId,
      stage_date: this.sendMensStageDate,
      periods: this.menstrualPeriod,
      gync_periods: this.menstrualPeriod,
      last_mens_period: this.sendLastMensDate,
      gync_last_mens_period: this.sendLastMensDate,
      cycle_length: cyclefreq_obstric,
      mens_pain: this.sendMensPain,
      pain_timing: this.sendMensTimeOfPain,
      pain_character: this.sendCharOfPainId,
      menorragia: this.sendMensDisorder,
      no_of_days: this.mensNoOfDays,
      pain_frequency: this.sendpainFreqlistId,
      gync_cycle_length: this.sendMensFreqLength,
      status: this.sexualStatus,
      obs_status: this.sexualStatus,
      satisfaction: this.sendSexualSatisfaction,
      difficulties: this.sexualDifficulties,
      pain_type: this.sexualTypeOfPain,
      age_sex_active: this.sendSexualActive,
      sex_type: this.sendTypeOfSex,
      complications: this.complicationInSex,
      contraceptive: this.sendContracept,
      contraceptives: this.sendContracept,
      contraceptive_type: this.sendContraceptiveId,
      infections: this.sexInfectionId,
      discont_reason: this.sendReasonForDiscont,
      sex_others: this.sexOthdata,
      gync_periods_flag: this.mensStageDateFlag,
      gynasex_flag: this.sendsexualStatusFlag,
      surgery_flag: this.surgeryFlag,
      fasting: this.fastingHalfhour,
      onehour: this.fastingOnehour,
      onehalfhour: this.fastingOnehalfhour,
      twohours: this.postprandial,
      HbA1c: this.HbA1c,
      vaccination: this.vaccineList,
      child_develop: this.mainInfant,
      cognitive: this.cognitiveList,
      language: this.languageList,
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "lifestyle/schealth/", JSON.stringify(send_data), { headers: headers }).subscribe(
      data => {
        var obj= data.json();
        if(obj["key"] != 0){
          if (obj != null && obj != undefined) {
            this.toastr.success(Message_data.dataSavedSuccess);
            this.clientservice.sendMessage("default");
          }
        }
      });
  }

  getClientDetails(){
    var send_data;
    if(this.subRelId !=null && this.subRelId != undefined && this.subRelId != ""){
      send_data = JSON.stringify({
        client_reg_id: this.clientRegId,
        relation_id: this.relationId,
        sub_rel_id:this.subRelId
      })
    }
    else{
      send_data = JSON.stringify({
        client_reg_id: this.clientRegId,
        relation_id: this.relationId,
      })
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'lifestyle/gchealth/',
    send_data,
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          if(obj != undefined && obj.spl_id != undefined){
            this.saveFlag = true;
            this.updateFlag = false;
            this.splId = obj.spl_id;
            this.currentOccupation=obj.currentOccupation;
            this.maritialStatus=obj.marital_status;
            this.physicalActivities=obj.physical_activities;
            this.feelStress=obj.stressed;
            this.changeStress(this.feelStress);
            if(obj.stress_factor != undefined){
              this.stressFactor=obj.stress_factor;
            }
            this.anyMedicine=obj.takingmed;
            this.anyMedicineChange();
            this.medicine=obj.medicines;
            this.symptom=obj.symptom;
            this.hdisorder=obj.heartdisorder;
            this.treat=obj.hdisordertreat;
            this.hosp = obj.hospitalization;
            this.changePrevHosp();
            this.hospDetails=obj.hosp_details;
            this.height=obj.height;
            this.heightMeasure=obj.height_measure;
            this.weight=obj.weight;
            this.weightMeasure=obj.weight_measure;
            this.bodyType=obj.body_type;
            this.dietHabits=obj.diet_habits;
            if(obj.habits != null){
              for (var j = 0; j < obj.habits.length; j++) {
                this.childHabitRetrv.push(obj.habits[j].ped_habit_id);
              }
              this.changeChildHabit(this.childHabitRetrv)
            }
            if (obj.d_c != null && obj.d_c != undefined) {
              this.dc = (obj.d_c);
            }
            if (obj.ovarian_surgery != null && obj.ovarian_surgery != undefined) {
              this.ovarianSurgery = (obj.ovarian_surgery);
            }
            if (obj.hysteroscopy != null && obj.hysteroscopy != undefined) {
              this.hysteroscopy = (obj.hysteroscopy);
            }
            if (obj.infertility_surgery != null && obj.infertility_surgery != undefined) {
              this.infertilitySurgery = (obj.infertility_surgery);
            }
            if (obj.left_cyst_removed != null && obj.left_cyst_removed != undefined) {
              this.leftCystRemoved = (obj.left_cyst_removed);
            }
            if (obj.right_cyst_removed != null && obj.right_cyst_removed != undefined) {
              this.rightCystRemoved = (obj.right_cyst_removed);
            }
            if (obj.tuboplasty != null && obj.tuboplasty != undefined) {
              this.tuboplasty = (obj.tuboplasty);
            }
            if (obj.tubal_ligation != null && obj.tubal_ligation != undefined) {
              this.tubalLigation = (obj.tubal_ligation);
            }
            if (obj.left_ovary_removed != null && obj.left_ovary_removed != undefined) {
              this.leftOvaryRemoved = (obj.left_ovary_removed);
            }
            if (obj.right_ovary_removed != null && obj.right_ovary_removed != undefined) {
              this.rightOvaryRemoved = (obj.right_ovary_removed);
            }
            if (obj.laparoscopy != null && obj.laparoscopy != undefined) {
              this.laparoscopy = (obj.laparoscopy);
            }
            if (obj.hysterectomy_vaginal != null && obj.hysterectomy_vaginal != undefined) {
              this.hysterectomyVaginal = (obj.hysterectomy_vaginal);
            }
            if (obj.hysterectomy_abdominal != null && obj.hysterectomy_abdominal != undefined) {
              this.hysterectomyAbdominal = (obj.hysterectomy_abdominal);
            }
            if (obj.myomectomy != null && obj.myomectomy != undefined) {
              this.myomectomy = (obj.myomectomy);
            }
            if (obj.bladder_repair != null && obj.bladder_repair != undefined) {
              this.bladderRepair = (obj.bladder_repair);
            }
            if (obj.surgery_others != null && obj.surgery_others != undefined) {
              this.others = obj.surgery_others;
            }
            if (obj.surgical_hist_id != null && obj.surgical_hist_id != undefined) {
              this.surgeHistId = obj.surgical_hist_id;
            }
            if (obj.periods != null) {
              this.menstrualPeriod = obj.periods;
            }
            if (obj.last_mens_period != null) {
              var last_date_ret = obj.last_mens_period.split("-");
              this.lastMensPeriod = obj.last_mens_period;
              this.selectLastMensPeriodDate(this.lastMensPeriod);
            }
            if (obj.cycle_length != null) {
              var cyclesplit = obj.cycle_length.split(" ");
              this.frequencyLength = cyclesplit[0];
              this.cycleLength = cyclesplit[1];
            }
            if (obj.sex_status != null) {
              this.sexualStatus = obj.sex_status;
              if (this.sexualStatus == "None") {
                this.difficultDisabledFlag = true;
                this.sexualSatDisabledFlag = true;
                this.sexualActiveDisabledFlag = true;
                this.typeOfSexDisabledFlag = true;
                this.contraceptDisabledFlag = true;
                this.infectDisabledFlag = true;
                this.otherIssueSexDisabledFlag = true;
                this.sexualDifficulties = "None";
                this.sexualTypeOfPain = "Superficial";
                this.sexualSatisfaction = undefined;
                this.sexualActive = undefined;
                this.typeOfSex = undefined;
                this.contracept = "None";
                this.typeOfContracept = undefined;
                this.reasonForDiscont = undefined;
                this.infection = "None";
                this.otherIssueSex = undefined;
                this.typeOfContraceptRequiredFlag = false;
                this.reasonForDiscontRequiredFlag = false;
              } else {
                this.difficultDisabledFlag = false;
                this.sexualSatDisabledFlag = false;
                this.sexualActiveDisabledFlag = false;
                this.typeOfSexDisabledFlag = false;
                this.contraceptDisabledFlag = false;
                this.infectDisabledFlag = false;
                this.otherIssueSexDisabledFlag = false;
              }
            }
            if (obj.difficulties != null) {
              this.sexualDifficulties = obj.difficulties;
              if (this.sexualDifficulties == "None") {
                this.sexualTypeOfPain = "Superficial";
                this.sexTypeOfPainDisabledFlag = true;
              } else {
                this.sexTypeOfPainDisabledFlag = false;
              }
            } else {
                this.sexualDifficulties = "None";
            }
            if (obj.pain_type != null) {
              this.sexualTypeOfPain = obj.pain_type;
            }
            if (obj.mens_stage != null) {
              for (var i = 0; i < this.menstrualStageList.length; i++) {
                for (var j = 0; j < obj.mens_stage.length; j++) {
                  if (obj.mens_stage[j] == this.menstrualStageList[i].mens_stage_id) {
                    this.stageDesc = this.menstrualStageList[i].description;
                  }
                }
              }
            }
            if (obj.stage_date != null) {
              var stage_datesplit = obj.stage_date.split("-");
              this.mensStageDate = obj.stage_date;
            }
            if (obj.edd != null) {
              var edddate_ret = obj.edd.split('-');
            }
            if (obj.mens_pain != null) {
              this.menstrualPain = obj.mens_pain;
              if (this.menstrualPain == "Yes") {
                this.mensTimeOfPainRequiredFlag = true;
                this.mensCharOfPainRequiredFlag = true;
                this.mensTimeOfPainDisabledFlag = false;
                this.mensCharOfPainDisabledFlag = false;
              } else {
                this.mensTimeOfPainRequiredFlag = false;
                this.mensCharOfPainRequiredFlag = false;
                this.mensTimeOfPainDisabledFlag = true;
                this.mensCharOfPainDisabledFlag = true;
                this.mensTimeOfPain = undefined;
                this.mensCharOfPain = undefined;
              }
            }
            if (obj.pain_timing != null) {
              this.mensTimeOfPain = obj.pain_timing;
            }
            if (obj.pain_timing != null) {
              this.mensTimeOfPain = obj.pain_timing;
            }
            if (obj.pain_character != null) {
              for (var i = 0; i < this.charOfPainList.length; i++) {
                for (var j = 0; j < obj.pain_character.length; j++) {
                  if (obj.pain_character[j] == this.charOfPainList[i].mens_pain_id) {
                    this.mensCharOfPain = this.charOfPainList[i].description;
                  }
                }
              }
            }
            this.mensDisorder = obj.menorragia;
            if (this.mensDisorder == "Yes") {
              this.mensNoOfDaysRequiredFlag = true;
              this.frequencyRequiredFlag = true;
              this.mensNoOfDaysDisabledFlag = false;
              this.freuencyDisabledFlag = false;
            } else {
              this.mensNoOfDaysRequiredFlag = false;
              this.frequencyRequiredFlag = false;
              this.mensNoOfDaysDisabledFlag = true;
              this.freuencyDisabledFlag = true;
              this.mensNoOfDays = undefined;
              this.mensPainFreq = undefined;
            }
            this.mensNoOfDays = obj.no_of_days;
            if (obj.pain_frequency != null) {
              for (var i = 0; i < this.painFreqlist.length; i++) {
                for (var j = 0; j < obj.pain_frequency.length; j++) {
                  if (obj.pain_frequency[j] == this.painFreqlist[i].pain_freq_id) {
                    this.mensPainFreq = this.painFreqlist[i].description;
                  }
                }
              }
            }
            if (obj.satisfaction != null) {
              this.sexualSatisfaction = obj.satisfaction;
            }
            if (obj.age_sex_active != null) {
              this.sexualActive = obj.age_sex_active;
            }
            if (obj.sex_type != null) {
              this.typeOfSex = obj.sex_type;
            }
            if (obj.contraceptives != null) {
              this.contracept = obj.contraceptives;
              if (this.contracept == "None") {
                this.reasonForDiscont = undefined;
                this.infection = this.infectionList[0].description;
                this.typeOfContraceptRequiredFlag = false;
                this.reasonForDiscontRequiredFlag = false;
                this.typeOfContraceptDisabledFlag = true;
                this.reasonForDiscontDisabledFlag = true;
                this.complicationInSexDisabledFlag = true;
              } else {
                this.typeOfContraceptRequiredFlag = true;
                this.reasonForDiscontRequiredFlag = false;
                this.typeOfContraceptDisabledFlag = false;
                this.reasonForDiscontDisabledFlag = false;
                this.complicationInSexDisabledFlag = false;
              }
            }
            if (obj.contraceptive_type != null) {
              for (var i = 0; i < this.contraceptiveList.length; i++) {
                for (var j = 0; j < obj.contraceptive_type.length; j++) {
                  if (obj.contraceptive_type[j] == this.contraceptiveList[i].contraceptive_id) {
                    this.typeOfContracept = this.contraceptiveList[i].description;
                  }
                }
              }
            }
            if (obj.infections != null) {
              for (var i = 0; i < this.infectionList.length; i++) {
                for (var j = 0; j < obj.infections.length; j++) {
                  if (obj.infections[j] == this.infectionList[i].sex_infect_id) {
                    this.infection = this.infectionList[i].description;
                  }
                }
              }
            }
            if (obj.discont_reason != null) {
              this.reasonForDiscont = obj.discont_reason;
            }
            if (obj.complications != null) {
              this.complicationInSex = obj.complications;
            }
            if (obj.sex_others != null) {
              this.otherIssueSex = obj.sex_others;
            }
            if (obj.alcohol_mixing_id != null && obj.alcohol_mixing_id != undefined) {
              for (var i = 0; i < this.alcMissingList.length; i++) {
                for (var j = 0; j < obj.alcohol_mixing_id.length; j++) {
                  if (obj.alcohol_mixing_id[j] == this.alcMissingList[i].mixing_id) {
                    this.alcMix = this.alcMissingList[i].description;
                  }
                }
              }
              this.alcholShowFlag = false;
              this.toggleAlcholFlag = true;
              this.panelOpenState1 = true;
              this.toggAlchol = 'Yes';
            }
            if (obj.alcohol_qty_id != null && obj.alcohol_qty_id != undefined) {
              for (var i = 0; i < this.alcQuantityList.length; i++) {
                  for (var j = 0; j < obj.alcohol_qty_id.length; j++) {
                      if (obj.alcohol_qty_id[j] == this.alcQuantityList[i].alcohol_qty_id) {
                          this.alcQuantity = this.alcQuantityList[i].description;
                      }
                  }
              }
              this.alcholShowFlag = false;
              this.toggleAlcholFlag = true;
              this.toggAlchol = 'Yes';
              this.panelOpenState1 = true;
            }
            if (obj.alc_duration != null) {
              this.alcholDuration = obj.alc_duration;
              this.alcholShowFlag = false;
              this.toggleAlcholFlag = true;
              this.toggAlchol = 'Yes';
              this.panelOpenState1 = true;
            }
            if (obj.alc_frequency != null) {
              this.alcholFrequency = obj.alc_frequency;
              this.alcholShowFlag = false;
              this.toggleAlcholFlag = true;
              this.toggAlchol = 'Yes';
              this.panelOpenState1 = true;
            }
            if (obj.smoke_duration != null) {
              this.smokeDuration = obj.smoke_duration;
              this.smokeShowFlag = false;
              this.toggleSmokingFlag = true;
              this.toggSmoking = 'Yes';
              this.panelOpenState2 = true;
            }
            if (obj.smoke_frequency != null) {
              this.smokeFrequency = obj.smoke_frequency;
              this.smokeShowFlag = false;
              this.toggleSmokingFlag = true;
              this.toggSmoking = 'Yes';
              this.panelOpenState2 = true;
            }
            if (obj.gutka_duration != null) {
              this.gutkaDuration = obj.gutka_duration;
              this.gutkaShowFlag = false;
              this.toggGutkaFlag = true;
              this.toggGutka = 'Yes';
              this.panelOpenState3 = true;
            }
            if (obj.gutka_frequency != null) {
              this.gutkaFrequency = obj.gutka_frequency;
              this.gutkaShowFlag = false;
              this.toggGutkaFlag = true;
              this.toggGutka = 'Yes';
              this.panelOpenState3 = true;
            }
            if (obj.exercise_dur != null) {
              this.exerciseDuration = obj.exercise_dur;
              this.exerciseShowFlag = false;
              this.toggExerciseFlag = true;
              this.toggExercise = 'Yes';
              this.panelOpenState4 = true;
            }
            if (obj.exercise_dur != null) {
              for (var i = 0; i < this.exerRoutineList.length; i++) {
                for (var j = 0; j < obj.exercise_routine.length; j++) {
                  if (obj.exercise_routine[j] == this.exerRoutineList[i].exer_rout_id) {
                    this.exerRoutine = this.exerRoutineList[i].description;
                  }
                }
              }
              this.exerciseShowFlag = false;
              this.toggExerciseFlag = true;
              this.toggExercise = 'Yes';
              this.panelOpenState4 = true;
            }
            if (obj.main_concerns != null) {
              for (var j = 0; j < obj.main_concerns.length; j++) {
                this.mainConcernRetrv.push(obj.main_concerns[j].main_concerns_id);
              }
              this.addMainConcern(this.mainConcernRetrv)
            }
            if (obj.medical_history != null && obj.medical_history.length !=0) {
              for (var j = 0; j < obj.medical_history.length; j++) {
                this.medicalHistoryRetrv.push(obj.medical_history[j].medical_hist_id);
              }
              this.addMedicalHistory(this.medicalHistoryRetrv)
            }
            if (obj.present_illness != null) {
              for (var j = 0; j < obj.present_illness.length; j++) {
                  this.presentIllnessRetrv.push(obj.present_illness[j].present_illness);
              }
              this.changePresentIllness(this.presentIllnessRetrv)
            }
            if (obj.drug_allergy != null) {
              this.drugAllergy = obj.drug_allergy;
              if (this.drugAllergy == "No") {
                this.drugDisabledFlag = true;
              } else {
                this.drugDisabledFlag = false;
              }
            }
            if (obj.drugs != null) {
              this.drugs = obj.drugs;
            }
            this.fastingHalfhour=obj.fast_halfhour;
            this.fastingOnehour=obj.fast_onehourl
            this.fastingOnehalfhour=obj.fast_onehalfhour;
            this.postprandial= obj.fast_twohours;
            this.HbA1c=obj.HbA1c;
            if(obj.vaccination != undefined){
              this.vaccineListRetrv = obj.vaccination;
              this.setVaccinationData();
            }
            if(obj.cognitive != undefined){
              this.cognitiveListRetrv = obj.cognitive;
            }
            if(obj.language != undefined){
              this.languageListRetrv = obj.language;
            }
            if(obj.child_develop != undefined){
              this.childDevelopmenListRetrv = obj.child_develop;
            }
            if(this.cognitiveListRetrv != undefined || this.languageListRetrv != undefined || this.childDevelopmenListRetrv != undefined){
              this.setDevelopmentData();
            }
          } else {
            this.saveFlag = false;
            this.updateFlag = true;
          }
        },
        error => {
        }
      )
  }

  changeStress(e) {
    if (e == "Yes") {
      this.stressRequiredFlag = true;
    } else {
      this.stressFactor = "";
      this.stressRequiredFlag = false;
    }
  }

  changePrevHosp() {
    this.hospDisabledFlag = (this.hosp == "Yes") ? false : true;
  }

  setDevelopmentData() {
    if (this.cognitiveListRetrv != undefined) {
      if (this.cognitiveListRetrv != null) {
        if (this.cognitiveListRetrv[0]["1-2 months"] != null) {
          for (var i = 0; i < this.cognitiveListRetrv[0]["1-2 months"].length; i++) {
            for (var j = 0; j < this.develCog1To2monthData.length; j++) {
              if (this.cognitiveListRetrv[0]["1-2 months"][i].cognitive_id == this.develCog1To2monthData[j].Cognitive_ID) {
                if (this.cognitiveListRetrv[0]["1-2 months"][i].cog_condition == "Abnormal") {
                  this.develCog1To2monthData[j].Yes = false;
                  this.develCog1To2monthData[j].No = true;
                  this.develCog1To2monthData[j].remarks = this.cognitiveListRetrv[0]["1-2 months"][i].remarks;
                  this.cogOneDisabledFlag = true;
                } else {
                  this.develCog1To2monthData[j].Yes = true;
                  this.develCog1To2monthData[j].No = false;
                  this.cogOneDisabledFlag = false;
                }
              }
            }
          }
        }
        if (this.cognitiveListRetrv[0]["3 months"] != null) {
          for (var i = 0; i < this.cognitiveListRetrv[0]["3 months"].length; i++) {
            for (var j = 0; j < this.develCog3monthData.length; j++) {
              if (this.cognitiveListRetrv[0]["3 months"][i].cognitive_id == this.develCog3monthData[j].Cognitive_ID) {
                if (this.cognitiveListRetrv[0]["3 months"][i].cog_condition != "Yes") {
                  this.develCog3monthData[j].Yes = false;
                  this.develCog3monthData[j].No = true;
                  this.develCog3monthData[j].remarks = this.cognitiveListRetrv[0]["3 months"][i].remarks;
                  this.cogThreeDisabledFlag = true;
                } else {
                  this.develCog3monthData[j].Yes = true;
                  this.develCog3monthData[j].No = false;
                  this.cogThreeDisabledFlag = false;
                }
              }
            }
          }
        }
        if (this.cognitiveListRetrv[0]["4-5 months"] != null) {
          for (var i = 0; i < this.cognitiveListRetrv[0]["4-5 months"].length; i++) {
            for (var j = 0; j < this.develCog4To5monthData.length; j++) {
              if (this.cognitiveListRetrv[0]["4-5 months"][i].cognitive_id == this.develCog4To5monthData[j].Cognitive_ID) {
                if (this.cognitiveListRetrv[0]["4-5 months"][i].cog_condition != "Yes") {
                  this.develCog4To5monthData[j].Yes = false;
                  this.develCog4To5monthData[j].No = true;
                  this.develCog4To5monthData[j].remarks = this.cognitiveListRetrv[0]["4-5 months"][i].remarks;
                  this.cogFiveDisabledFlag = true;
                } else {
                  this.develCog4To5monthData[j].Yes = true;
                  this.develCog4To5monthData[j].No = false;
                  this.cogFiveDisabledFlag = false;
                }
              }
            }
          }
        }
        if (this.cognitiveListRetrv[0]["6-7 months"] != null) {
          for (var i = 0; i < this.cognitiveListRetrv[0]["6-7 months"].length; i++) {
            for (var j = 0; j < this.develCog6To7monthData.length; j++) {
              if (this.cognitiveListRetrv[0]["6-7 months"][i].cognitive_id == this.develCog6To7monthData[j].Cognitive_ID) {
                if (this.cognitiveListRetrv[0]["6-7 months"][i].cog_condition != "Yes") {
                  this.develCog6To7monthData[j].Yes = false;
                  this.develCog6To7monthData[j].No = true;
                  this.develCog6To7monthData[j].remarks = this.cognitiveListRetrv[0]["6-7 months"][i].remarks;
                  this.cogSixDisabledFlag = true;
                } else {
                  this.develCog6To7monthData[j].Yes = true;
                  this.develCog6To7monthData[j].No = false;
                  this.cogSixDisabledFlag = false;
                }
              }
            }
          }
        }
        if (this.cognitiveListRetrv[0]["8-9 months"] != null) {
          for (var i = 0; i < this.cognitiveListRetrv[0]["8-9 months"].length; i++) {
            for (var j = 0; j < this.develCog8To9monthData.length; j++) {
              if (this.cognitiveListRetrv[0]["8-9 months"][i].cognitive_id == this.develCog8To9monthData[j].Cognitive_ID) {
                if (this.cognitiveListRetrv[0]["8-9 months"][i].cog_condition != "Yes") {
                  this.develCog8To9monthData[j].Yes = false;
                  this.develCog8To9monthData[j].No = true;
                  this.develCog8To9monthData[j].remarks = this.cognitiveListRetrv[0]["8-9 months"][i].remarks;
                  this.cogEightDisabledFlag = true;
                } else {
                  this.develCog8To9monthData[j].Yes = true;
                  this.develCog8To9monthData[j].No = false;
                  this.cogEightDisabledFlag = false;
                }
              }
            }
          }
        }
        if (this.cognitiveListRetrv[0]["10-12 months"] != null) {
          for (var i = 0; i < this.cognitiveListRetrv[0]["10-12 months"].length; i++) {
            for (var j = 0; j < this.develCog10To12monthData.length; j++) {
              if (this.cognitiveListRetrv[0]["10-12 months"][i].cognitive_id == this.develCog10To12monthData[j].Cognitive_ID) {
                if (this.cognitiveListRetrv[0]["10-12 months"][i].cog_condition != "Yes") {
                  this.develCog10To12monthData[j].Yes = false;
                  this.develCog10To12monthData[j].No = true;
                  this.develCog10To12monthData[j].remarks = this.cognitiveListRetrv[0]["10-12 months"][i].remarks;
                  this.cogTenDisabledFlag = true;
                } else {
                  this.develCog10To12monthData[j].Yes = true;
                  this.develCog10To12monthData[j].No = false;
                  this.cogTenDisabledFlag = false;
                }
              }
            }
          }
        }
        if (this.cognitiveListRetrv[0]["15 months"] != null) {
          for (var i = 0; i < this.cognitiveListRetrv[0]["15 months"].length; i++) {
            for (var j = 0; j < this.develCog15monthData.length; j++) {
              if (this.cognitiveListRetrv[0]["15 months"][i].cognitive_id == this.develCog15monthData[j].Cognitive_ID) {
                if (this.cognitiveListRetrv[0]["15 months"][i].cog_condition != "Yes") {
                  this.develCog15monthData[j].Yes = false;
                  this.develCog15monthData[j].No = true;
                  this.develCog15monthData[j].remarks = this.cognitiveListRetrv[0]["15 months"][i].remarks;
                  this.devFifDisabledFlag = true;
                } else {
                  this.develCog15monthData[j].Yes = true;
                  this.develCog15monthData[j].No = false;
                  this.devFifDisabledFlag = false;
                }
              }
            }
          }
        }
        if (this.cognitiveListRetrv[0]["18 months"] != null) {
          for (var i = 0; i < this.cognitiveListRetrv[0]["18 months"].length; i++) {
            for (var j = 0; j < this.develCog18monthData.length; j++) {
              if (this.cognitiveListRetrv[0]["18 months"][i].cognitive_id == this.develCog18monthData[j].Cognitive_ID) {
                if (this.cognitiveListRetrv[0]["18 months"][i].cog_condition != "Yes") {
                  this.develCog18monthData[j].Yes = false;
                  this.develCog18monthData[j].No = true;
                  this.develCog18monthData[j].remarks = this.cognitiveListRetrv[0]["18 months"][i].remarks;
                  this.devEighteenDisabledFlag = true;
                } else {
                  this.develCog18monthData[j].Yes = true;
                  this.develCog18monthData[j].No = false;
                  this.devEighteenDisabledFlag = false;
                }
              }
            }
          }
        }
        if (this.cognitiveListRetrv[0]["24 months"] != null) {
          for (var i = 0; i < this.cognitiveListRetrv[0]["24 months"].length; i++) {
            for (var j = 0; j < this.develCog24monthData.length; j++) {
              if (this.cognitiveListRetrv[0]["24 months"][i].cognitive_id == this.develCog24monthData[j].Cognitive_ID) {
                if (this.cognitiveListRetrv[0]["24 months"][i].cog_condition != "Yes") {
                  this.develCog24monthData[j].Yes = false;
                  this.develCog24monthData[j].No = true;
                  this.develCog24monthData[j].remarks = this.cognitiveListRetrv[0]["24 months"][i].remarks;
                  this.devTwenDisabledFlag = true;
                } else {
                  this.develCog24monthData[j].Yes = true;
                  this.develCog24monthData[j].No = false;
                  this.devTwenDisabledFlag = false;
                }
              }
            }
          }
        }
        if (this.cognitiveListRetrv[0]["3 years"] != null) {
          for (var i = 0; i < this.cognitiveListRetrv[0]["3 years"].length; i++) {
            for (var j = 0; j < this.develCog3yearData.length; j++) {
              if (this.cognitiveListRetrv[0]["3 years"][i].cognitive_id == this.develCog3yearData[j].Cognitive_ID) {
                if (this.cognitiveListRetrv[0]["3 years"][i].cog_condition != "Yes") {
                  this.develCog3yearData[j].Yes = false;
                  this.develCog3yearData[j].No = true;
                  this.develCog3yearData[j].remarks = this.cognitiveListRetrv[0]["3 years"][i].remarks;
                  this.devThreeYearDisabledFlag = true;
                } else {
                  this.develCog3yearData[j].Yes = true;
                  this.develCog3yearData[j].No = false;
                  this.devThreeYearDisabledFlag = false;
                }
              }
            }
          }
        }
        if (this.cognitiveListRetrv[0]["4 years"] != null) {
          for (var i = 0; i < this.cognitiveListRetrv[0]["4 years"].length; i++) {
            for (var j = 0; j < this.develCog4yearData.length; j++) {
              if (this.cognitiveListRetrv[0]["4 years"][i].cognitive_id == this.develCog4yearData[j].Cognitive_ID) {
                if (this.cognitiveListRetrv[0]["4 years"][i].cog_condition != "Yes") {
                  this.develCog4yearData[j].Yes = false;
                  this.develCog4yearData[j].No = true;
                  this.develCog4yearData[j].remarks = this.cognitiveListRetrv[0]["4 years"][i].remarks;
                  this.devFourYearDisabledFlag = true;
                } else {
                  this.develCog4yearData[j].Yes = true;
                  this.develCog4yearData[j].No = false;
                  this.devFourYearDisabledFlag = false;
                }
              }
            }
          }
        }
        if (this.cognitiveListRetrv[0]["5 years"] != null) {
          for (var i = 0; i < this.cognitiveListRetrv[0]["5 years"].length; i++) {
            for (var j = 0; j < this.develCog5yearData.length; j++) {
              if (this.cognitiveListRetrv[0]["5 years"][i].cognitive_id == this.develCog5yearData[j].Cognitive_ID) {
                if (this.cognitiveListRetrv[0]["5 years"][i].cog_condition != "Yes") {
                  this.develCog5yearData[j].Yes = false;
                  this.develCog5yearData[j].No = true;
                  this.develCog5yearData[j].remarks = this.cognitiveListRetrv[0]["5 years"][i].remarks;
                  this.devFiveYearDisabledFlag = true;
                } else {
                  this.develCog5yearData[j].Yes = true;
                  this.develCog5yearData[j].No = false;
                  this.devFiveYearDisabledFlag = false;
                }
              }
            }
          }
        }
      }
      if (this.languageListRetrv != null) {
        if (this.languageListRetrv[0]["1-2 months"] != null) {
          for (var i = 0; i < this.languageListRetrv[0]["1-2 months"].length; i++) {
            for (var j = 0; j < this.develLang1To2monthData.length; j++) {
              if (this.languageListRetrv[0]["1-2 months"][i].language_id == this.develLang1To2monthData[j].Cognitive_ID) {
                if (this.languageListRetrv[0]["1-2 months"][i].condition != "Yes") {
                  this.develLang1To2monthData[j].Yes = false;
                  this.develLang1To2monthData[j].No = true;
                  this.develLang1To2monthData[j].remarks = this.languageListRetrv[0]["1-2 months"][i].remarks;
                  this.langOneDisabledFlag = true;
                } else {
                  this.develLang1To2monthData[j].Yes = true;
                  this.develLang1To2monthData[j].No = false;
                  this.langOneDisabledFlag = false;
                }
              }
            }
          }
        }
        if (this.languageListRetrv[0]["3 months"] != null) {
          for (var i = 0; i < this.languageListRetrv[0]["3 months"].length; i++) {
            for (var j = 0; j < this.develLang3monthData.length; j++) {
              if (this.languageListRetrv[0]["3 months"][i].language_id == this.develLang3monthData[j].Cognitive_ID) {
                if (this.languageListRetrv[0]["3 months"][i].condition != "Yes") {
                  this.develLang3monthData[j].Yes = false;
                  this.develLang3monthData[j].No = true;
                  this.develLang3monthData[j].remarks = this.languageListRetrv[0]["3 months"][i].remarks;
                  this.langThreeDisabledFlag = true;
                } else {
                  this.develLang3monthData[j].Yes = true;
                  this.develLang3monthData[j].No = false;
                  this.langThreeDisabledFlag = false;
                }
              }
            }
          }
        }
        if (this.languageListRetrv[0]["4-5 months"] != null) {
          for (var i = 0; i < this.languageListRetrv[0]["4-5 months"].length; i++) {
            for (var j = 0; j < this.develLang4To5monthData.length; j++) {
              if (this.languageListRetrv[0]["4-5 months"][i].language_id == this.develLang4To5monthData[j].Cognitive_ID) {
                if (this.languageListRetrv[0]["4-5 months"][i].condition != "Yes") {
                  this.develLang4To5monthData[j].Yes = false;
                  this.develLang4To5monthData[j].No = true;
                  this.develLang4To5monthData[j].remarks = this.languageListRetrv[0]["4-5 months"][i].remarks;
                  this.langFourDisabledFlag = true;
                } else {
                  this.develLang4To5monthData[j].Yes = true;
                  this.develLang4To5monthData[j].No = false;
                  this.langFourDisabledFlag = false;
                }
              }
            }
          }
        }
        if (this.languageListRetrv[0]["6-7 months"] != null) {
          for (var i = 0; i < this.languageListRetrv[0]["6-7 months"].length; i++) {
            for (var j = 0; j < this.develLang6To7monthData.length; j++) {
              if (this.languageListRetrv[0]["6-7 months"][i].language_id == this.develLang6To7monthData[j].Cognitive_ID) {
                if (this.languageListRetrv[0]["6-7 months"][i].condition != "Yes") {
                  this.develLang6To7monthData[j].Yes = false;
                  this.develLang6To7monthData[j].No = true;
                  this.develLang6To7monthData[j].remarks = this.languageListRetrv[0]["6-7 months"][i].remarks;
                  this.langSixDisabledFlag = true;
                } else {
                  this.develLang6To7monthData[j].Yes = true;
                  this.develLang6To7monthData[j].No = false;
                  this.langSixDisabledFlag = false;
                }
              }
            }
          }
        }
        if (this.languageListRetrv[0]["8-9 months"] != null) {
          for (var i = 0; i < this.languageListRetrv[0]["8-9 months"].length; i++) {
            for (var j = 0; j < this.develLang8To9monthData.length; j++) {
              if (this.languageListRetrv[0]["8-9 months"][i].language_id == this.develLang8To9monthData[j].Cognitive_ID) {
                if (this.languageListRetrv[0]["8-9 months"][i].condition != "Yes") {
                  this.develLang8To9monthData[j].Yes = false;
                  this.develLang8To9monthData[j].No = true;
                  this.develLang8To9monthData[j].remarks = this.languageListRetrv[0]["8-9 months"][i].remarks;
                  this.langeightDisabledFlag = true;
                } else {
                  this.develLang8To9monthData[j].Yes = true;
                  this.develLang8To9monthData[j].No = false;
                  this.langeightDisabledFlag = false;
                }
              }
            }
          }
        }
        if (this.languageListRetrv[0]["10-12 months"] != null) {
          for (var i = 0; i < this.languageListRetrv[0]["10-12 months"].length; i++) {
            for (var j = 0; j < this.develLang10To12monthData.length; j++) {
              if (this.languageListRetrv[0]["10-12 months"][i].language_id == this.develLang10To12monthData[j].Cognitive_ID) {
                if (this.languageListRetrv[0]["10-12 months"][i].condition != "Yes") {
                  this.develLang10To12monthData[j].Yes = false;
                  this.develLang10To12monthData[j].No = true;
                  this.develLang10To12monthData[j].remarks = this.languageListRetrv[0]["10-12 months"][i].remarks;
                  this.langTenDisabledFlag = true;
                } else {
                  this.develLang10To12monthData[j].Yes = true;
                  this.develLang10To12monthData[j].No = false;
                  this.langTenDisabledFlag = false;
                }
              }
            }
          }
        }
        if (this.languageListRetrv[0]["15 months"] != null) {
          for (var i = 0; i < this.languageListRetrv[0]["15 months"].length; i++) {
            for (var j = 0; j < this.develLang15monthData.length; j++) {
              if (this.languageListRetrv[0]["15 months"][i].language_id == this.develLang15monthData[j].Cognitive_ID) {
                if (this.languageListRetrv[0]["15 months"][i].condition != "Yes") {
                  this.develLang15monthData[j].Yes = false;
                  this.develLang15monthData[j].No = true;
                  this.develLang15monthData[j].remarks = this.languageListRetrv[0]["15 months"][i].remarks;
                  this.langFifteenDisabledFlag = true;
                } else {
                  this.develLang15monthData[j].Yes = true;
                  this.develLang15monthData[j].No = false;
                  this.langFifteenDisabledFlag = false;
                }
              }
            }
          }
        }
        if (this.languageListRetrv[0]["18 months"] != null) {
          for (var i = 0; i < this.languageListRetrv[0]["18 months"].length; i++) {
            for (var j = 0; j < this.develLang18monthData.length; j++) {
              if (this.languageListRetrv[0]["18 months"][i].language_id == this.develLang18monthData[j].Cognitive_ID) {
                if (this.languageListRetrv[0]["18 months"][i].condition != "Yes") {
                  this.develLang18monthData[j].Yes = false;
                  this.develLang18monthData[j].No = true;
                  this.develLang18monthData[j].remarks = this.languageListRetrv[0]["18 months"][i].remarks;
                  this.langEighteenDisabledFlag = true;
                } else {
                  this.develLang18monthData[j].Yes = true;
                  this.develLang18monthData[j].No = false;
                  this.langEighteenDisabledFlag = false;
                }
              }
            }
          }
        }
        if (this.languageListRetrv[0]["24 months"] != null) {
          for (var i = 0; i < this.languageListRetrv[0]["24 months"].length; i++) {
            for (var j = 0; j < this.develLang24monthData.length; j++) {
              if (this.languageListRetrv[0]["24 months"][i].language_id == this.develLang24monthData[j].Cognitive_ID) {
                if (this.languageListRetrv[0]["24 months"][i].condition != "Yes") {
                  this.develLang24monthData[j].Yes = false;
                  this.develLang24monthData[j].No = true;
                  this.develLang24monthData[j].remarks = this.languageListRetrv[0]["24 months"][i].remarks;
                  this.langTwnFourDisabledFlag = true;
                } else {
                  this.develLang24monthData[j].Yes = true;
                  this.develLang24monthData[j].No = false;
                  this.langTwnFourDisabledFlag = false;
                }
              }
            }
          }
        }
        if (this.languageListRetrv[0]["3 years"] != null) {
          for (var i = 0; i < this.languageListRetrv[0]["3 years"].length; i++) {
            for (var j = 0; j < this.develLang3yearData.length; j++) {
              if (this.languageListRetrv[0]["3 years"][i].language_id == this.develLang3yearData[j].Cognitive_ID) {
                if (this.languageListRetrv[0]["3 years"][i].condition != "Yes") {
                  this.develLang3yearData[j].Yes = false;
                  this.develLang3yearData[j].No = true;
                  this.develLang3yearData[j].remarks = this.languageListRetrv[0]["3 years"][i].remarks;
                  this.langThreeYearDisabledFlag = true;
                } else {
                  this.develLang3yearData[j].Yes = true;
                  this.develLang3yearData[j].No = false;
                  this.langThreeYearDisabledFlag = false;
                }
              }
            }
          }
        }
        if (this.languageListRetrv[0]["4 years"] != null) {
          for (var i = 0; i < this.languageListRetrv[0]["4 years"].length; i++) {
            for (var j = 0; j < this.develLang4yearData.length; j++) {
              if (this.languageListRetrv[0]["4 years"][i].language_id == this.develLang4yearData[j].Cognitive_ID) {
                if (this.languageListRetrv[0]["4 years"][i].condition != "Yes") {
                  this.develLang4yearData[j].Yes = false;
                  this.develLang4yearData[j].No = true;
                  this.develLang4yearData[j].remarks = this.languageListRetrv[0]["4 years"][i].remarks;
                  this.langFourYearDisabledFlag = true;
                } else {
                  this.develLang4yearData[j].Yes = true;
                  this.develLang4yearData[j].No = false;
                  this.langFourYearDisabledFlag = false;
                }
              }
            }
          }
        }
        if (this.languageListRetrv[0]["5 years"] != null) {
          for (var i = 0; i < this.languageListRetrv[0]["5 years"].length; i++) {
            for (var j = 0; j < this.develLang5yearData.length; j++) {
              if (this.languageListRetrv[0]["5 years"][i].language_id == this.develLang5yearData[j].Cognitive_ID) {
                if (this.languageListRetrv[0]["5 years"][i].condition != "Yes") {
                  this.develLang5yearData[j].Yes = false;
                  this.develLang5yearData[j].No = true;
                  this.develLang5yearData[j].remarks = this.languageListRetrv[0]["5 years"][i].remarks;
                  this.langFiveYearDisabledFlag = true;
                } else {
                  this.develLang5yearData[j].Yes = true;
                  this.develLang5yearData[j].No = false;
                  this.langFiveYearDisabledFlag = false;
                }
              }
            }
          }
        }
      }
      if (this.childDevelopmenListRetrv != null) {
        for (var i = 0; i < this.childDevelopmenListRetrv.length; i++) {
          if (this.childDevelopmenListRetrv[i].age_group == "Infant") {
            if (this.childDevelopmenListRetrv[i].develop_type == "Deciduous teeth") {
              if (this.childDevelopmenListRetrv[i].ped_condition == "Abnormal") {
                this.oneYearDecideTeeth = "no"
                this.decideDisabledFlag = true;
              }
              this.condition = this.childDevelopmenListRetrv[i].ped_condition;
              this.oneYearDecideTeethRemarks = this.childDevelopmenListRetrv[i].remarks;
            }
            if (this.childDevelopmenListRetrv[i].develop_type == "Dental caries") {
              if (this.childDevelopmenListRetrv[i].ped_condition == "Yes") {
                this.oneYearDentalCaries = "yes";
                this.dentalDisabledFlag = true;
              }
              this.dentalCaries = this.childDevelopmenListRetrv[i].ped_condition;
              this.oneYearDentalCariesRemarks = this.childDevelopmenListRetrv[i].remarks;
            }
            if (this.childDevelopmenListRetrv[i].develop_type == "Object permanence") {
              if (this.childDevelopmenListRetrv[i].ped_condition == "Abnormal") {
                this.oneYearObjectPerformance = "no";
                this.objectDisabledFlag = true;
              }
              this.objectPerformance = this.childDevelopmenListRetrv[i].ped_condition;
              this.oneYearObjectRemarks = this.childDevelopmenListRetrv[i].remarks;
            }
            if (this.childDevelopmenListRetrv[i].develop_type == "Sleep pattern") {
              if (this.childDevelopmenListRetrv[i].ped_condition == "Abnormal") {
                this.oneYearSleepPattern = "no"
                this.sleepDisabledFlag = true;
              }
              this.sleepPattren = this.childDevelopmenListRetrv[i].ped_condition;
              this.oneYearSleepRemarks = this.childDevelopmenListRetrv[i].remarks;
            }
          }
          if (this.childDevelopmenListRetrv[i].age_group == "Toddler") {
            if (this.childDevelopmenListRetrv[i].develop_type == "Dentition") {
              if (this.childDevelopmenListRetrv[i].ped_condition == "Abnormal") {
                this.twoYearDentionTeeth = "no";
                this.twoDenDisabledFlag = true;
              }
              this.twoYearDention = this.childDevelopmenListRetrv[i].ped_condition;
              this.twoYearDentionRemarks = this.childDevelopmenListRetrv[i].remarks;
            }
            if (this.childDevelopmenListRetrv[i].develop_type == "Moral development") {
              if (this.childDevelopmenListRetrv[i].ped_condition == "Yes") {
                this.twoYearMoralDevlp = "yes";
                this.twoMoralDisabledFlag = true;
              }
              this.twoYearMoral = this.childDevelopmenListRetrv[i].ped_condition;
              this.twoYearMoralRemarks = this.childDevelopmenListRetrv[i].remarks;
            }
            if (this.childDevelopmenListRetrv[i].develop_type == "Psycho-social development") {
              if (this.childDevelopmenListRetrv[i].ped_condition == "Abnormal") {
                this.twoYearPhyschoSocial = "no";
                this.twoPsychoDisabledFlag = true;
              }
              this.twoYearPhyscio = this.childDevelopmenListRetrv[i].ped_condition;
              this.twoYearPhyscoRemarks = this.childDevelopmenListRetrv[i].remarks;
            }
            if (this.childDevelopmenListRetrv[i].develop_type == "Toilet training") {
              if (this.childDevelopmenListRetrv[i].ped_condition == "Abnormal") {
                this.twoYearToiletTraining = "no";
                this.twoToiletDisabledFlag = true;
              }
              this.twoYearToilet = this.childDevelopmenListRetrv[i].ped_condition;
              this.twoYearToiletRemarks = this.childDevelopmenListRetrv[i].remarks;
            }
            if (this.childDevelopmenListRetrv[i].develop_type == "Sleep pattern") {
              if (this.childDevelopmenListRetrv[i].ped_condition == "Abnormal") {
                this.twoYearSleep = "No";
                this.twoSleepDisabledFlag = true;
              }
              this.twoYearSleeps = this.childDevelopmenListRetrv[i].ped_condition;
              this.twoYearSleepRemarks = this.childDevelopmenListRetrv[i].remarks;
            }
            if (this.childDevelopmenListRetrv[i].develop_type == "Playing pattern") {
              if (this.childDevelopmenListRetrv[i].ped_condition == "Abnormal") {
                this.twoYearPlayingPattren = "no";
                this.twoPlayDisabledFlag = true;
              }
              this.twoYearPlay = this.childDevelopmenListRetrv[i].ped_condition;
              this.twoYearPlayRemarks = this.childDevelopmenListRetrv[i].remarks;
            }
          }
          if (this.childDevelopmenListRetrv[i].age_group == "Preschool") {
            if (this.childDevelopmenListRetrv[i].develop_type == "Dentition") {
              if (this.childDevelopmenListRetrv[i].ped_condition == "Abnormal") {
                this.threeYearDentionTeeth = "no";
                this.preDenDisabledFlag = true;
              }
              this.threeDental = this.childDevelopmenListRetrv[i].ped_condition;
              this.threeYearDentionRemarks = this.childDevelopmenListRetrv[i].remarks;
            }
            if (this.childDevelopmenListRetrv[i].develop_type == "Moral development") {
              if (this.childDevelopmenListRetrv[i].ped_condition == "Yes") {
                this.threeYearmoral = "yes";
                this.preMoralDisabledFlag = true;
              }
              this.threePhysco = this.childDevelopmenListRetrv[i].ped_condition;
              this.threeYearMoralRemarks = this.childDevelopmenListRetrv[i].remarks;
            }
            if (this.childDevelopmenListRetrv[i].develop_type == "Psycho-social development") {
              if (this.childDevelopmenListRetrv[i].ped_condition == "Abnormal") {
                this.threeYearPhyschoSocial = "no";
                this.prePyschoDisabledFlag = true;
              }
              this.threeMoral = this.childDevelopmenListRetrv[i].ped_condition;
              this.threeYearPhyschoRemarks = this.childDevelopmenListRetrv[i].remarks;
            }
            if (this.childDevelopmenListRetrv[i].develop_type == "Toilet training") {
              if (this.childDevelopmenListRetrv[i].ped_condition == "Abnormal") {
                this.threeYearToiletTraining = "no";
                this.preToiletDisabledFlag = true;
              }
              this.threeToilet = this.childDevelopmenListRetrv[i].ped_condition;
              this.threeYearToiletRemarks = this.childDevelopmenListRetrv[i].remarks;
            }
            if (this.childDevelopmenListRetrv[i].develop_type == "Sleep pattern") {
              if (this.childDevelopmenListRetrv[i].ped_condition == "Abnormal") {
                this.threeYearSleep = "no";
                this.preSleepDisabledFlag = true;
              }
              this.threeSleep = this.childDevelopmenListRetrv[i].ped_condition;
              this.threeYearSleepRemarks = this.childDevelopmenListRetrv[i].remarks;
            }
            if (this.childDevelopmenListRetrv[i].develop_type == "Playing pattern") {
              if (this.childDevelopmenListRetrv[i].ped_condition == "Abnormal") {
                this.threeYearPlayingPattren = "no";
                this.prePlayDisabledFlag = true;
              }
              this.threePlaying = this.childDevelopmenListRetrv[i].ped_condition;
              this.threeYearPlayingRemarks = this.childDevelopmenListRetrv[i].remarks;
            }
          }
        }
      }
    }
  }

  clearData(){
    this.currentOccupation = "";
    this.maritialStatus = "";
    this.physicalActivities = "";
    this.mainConcern = "";
    this.mainConcernMasterList = [];
    this.presentIllnessMasterList = [];
    this.medicalHistoryMasterList = [];
    this.presentIllness = "";
    this.presentIllnessRetrv = [];
    this.childHabitRetrv = [];
    this.medicalHistoryRetrv = [];
    this.mainConcernRetrv = [];
    this.childHabitMasterList = [];
    this.pastMedical = "";
    this.drugAllergy = "";
    this.drugs = "";
    this.feelStress = "";
    this.stressFactor = "";
    this.anyMedicine = "";
    this.medicine = "";
    this.symptom = "";
    this.hdisorder = "";
    this.treat = "";
    this.hosp = "";
    this.hospDetails = "";
    this.childHabits = "";
    this.height = "";
    this.heightMeasure = "cms";
    this.weight ="";
    this.weightMeasure = "kgs";
    this.dietHabits = "";
    this.dc = undefined;
    this.ovarianSurgery = undefined;
    this.hysteroscopy  = undefined;
    this.infertilitySurgery  = undefined;
    this.leftCystRemoved  = undefined;
    this.rightCystRemoved  = undefined;
    this.tuboplasty  = undefined;
    this.leftOvaryRemoved  = undefined;
    this.tubalLigation  = undefined;
    this.rightOvaryRemoved  = undefined;
    this.laparoscopy  = undefined;
    this.hysterectomyVaginal  = undefined;
    this.hysterectomyAbdominal  = undefined;
    this.myomectomy  = undefined;
    this.bladderRepair  = undefined;
    this.others = "";
    this.sexualStatus = "";
    this.sexualDifficulties = "";
    this.sexualTypeOfPain = "";
    this.sexualSatisfaction = "";
    this.sexualActive = "";
    this.typeOfSex = "";  
    this.contracept = "";  
    this.typeOfContracept = "";
    this.reasonForDiscont = "";
    this.complicationInSex = "";
    this.infection = "";
    this.otherIssueSex = "";
    this.fasting = false;
    this.ogtt = false;
    this.fastingHalfhour = "";
    this.fastingOnehour = "";
    this.fastingOnehalfhour = "";
    this.postprandial = "";
    this.HbA1c = "";
    this.observationFlag = true;
    this.pediaFlag = true;
    this.alcholShowFlag=true;
    this.smokeShowFlag=true;
    this.gutkaShowFlag=true;
    this.exerciseShowFlag=true;
    this.toggFamilyFlag = false;
    this.toggleAlcholFlag = false;
    this.toggleSmokingFlag = false;
    this.toggGutkaFlag = false;
    this.toggExerciseFlag = false;
    this.menstrualFlag = true;
    this.sexualHistoryFlag = true;
    this.pediaVaccineFlag = true;
    this.pediaDevlpFlag = true;
    this.relationId = "1";
    this.subRelId = undefined;
    this.oneYearDecideTeeth = "yes";
    this.oneYearDentalCaries = "no";
    this.oneYearObjectPerformance = "yes";
    this.oneYearSleepPattern = "yes";
    this.twoYearDentionTeeth = "yes";
    this.twoYearMoralDevlp = "no";
    this.twoYearPhyschoSocial = "yes";
    this.twoYearToiletTraining = "yes";
    this.twoYearSleep = "yes";
    this.twoYearPlayingPattren = "yes";
    this.twoYearPlayingPattren = "yes";
    this.threeYearDentionTeeth = "yes";
    this.threeYearmoral = "no";
    this.threeYearPhyschoSocial = "yes";
    this.threeYearToiletTraining = "yes";
    this.threeYearSleep = "yes";
    this.threeYearPlayingPattren = "yes";
    this.saveFlag = false;
    this.updateFlag = true; 
    this.mensStageDate = undefined;
    this.menstrualPeriod = "";
    this.lastMensPeriod = "";
    this.cycleLength = "";
    this.frequencyLength = "";
    this.menstrualPain = "";
    this.mensTimeOfPain = "";
    this.mensCharOfPain = "";
    this.mensDisorder = "";
    this.mensNoOfDays = "";
    this.mensPainFreq= "";
    this.alcholDuration = "<1 Year";
    this.alcholFrequency = "<5";
    this.smokeDuration = "<1 Year";
    this.smokeFrequency = "<5";
    this.gutkaDuration = "<1 Year";
    this.gutkaFrequency = "<5";
    this.exerciseDuration = "15 Mins";  
    this.toggAlchol = "No";
    this.toggSmoking = "No";
    this.toggExercise = "No";
    this.toggGutka = "No";
    this.toggFamily = "No";
  }

  masterData(){
    this.getSpecilizationType();
    this.getMedicalHistory();
    this.getPresentIllness();
    this.getBodyTypes();
    this.getInfection();
    this.getChildHabits();
    this.getFrequency();
    this.getCharacterOfpain();
    this.getStage();
    this.contraceptiveTypes();
    this.getExerciseRoutine();
    this.getMixingtype();
    this.getQuantity();
    this.getMainConcern();
  }
}
