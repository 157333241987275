import { Component, OnInit, ViewChild } from '@angular/core';
import { Http, Headers } from '@angular/http';
// import { ipaddress.getIp, Ip_with_img_address, ipaddress.country_code } from '../../../assets/js/ipaddress';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Helper_Class } from '../../helper_class';
import { Date_Formate, Time_Formate_Session } from '../../../assets/js/common';
import { ipaddress } from '../../ipaddress' ;import { Location } from '@angular/common';
import { Message_data } from '../../../assets/js/Message_data';
import { ToastrService } from 'ngx-toastr';
import { Nurse_Helper } from '../Nurse_Helper';
import { NurseService } from '../nurse-view/nurse.service';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
declare var $: any;
@Component({
  selector: 'app-nurse-appointment-detailspage',
  templateUrl: './nurse-appointment-detailspage.component.html',
  styleUrls: ['./nurse-appointment-detailspage.component.scss']
})
export class NurseAppointmentDetailspageComponent implements OnInit {
  public curentDateURL: string;
  public currentAppointmentURL: string;
  public currentcancelURL;
  public clientRegID;
  public nurseHomeCareView;
  public currentDate;
  public currentTime;
  //public activedata;
  public nurseCancelFlag;
  public cancelReason;
  public location;
  public date;
  public time;
  public reason;
  public gender;
  public age;
  public bloodGroup;
  public relation;
  public mobile;
  public clientName;
  public Address;
  public sample: any;
  public add1;
  public add2;
  public city;
  public locations;
  public retriveClientListArray;
  public sendURL: string;
  public nurseConfirmFlag: boolean;
  public nurseDoneFlag: boolean;
  public reasonContent: boolean;
  public personalDetails;
  public strings;
  public rea_txt;
  public symtoms;
  constructor(public http: Http, public toastr: ToastrService, public messageService: MenuViewService) {
  }
  ngOnInit(): void {
    this.curentDateURL = ipaddress.getIp + 'adm/curdate';
    this.currentAppointmentURL = ipaddress.getIp + 'appointment/npappsdet/';
    this.sendURL = ipaddress.getIp + "adm/curdate";
    this.personalDetails = Nurse_Helper.getClient_Info();
    this.currendDateData();
    this.currentAppoiontData();
    this.nurseCancelFlag = false;
    this.reasonContent = false;
  }
  currendDateData() {
    this.clientRegID = {
      country: ipaddress.country_code
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.curentDateURL, JSON.stringify(this.clientRegID), { headers: headers }).subscribe(
      data => {
        this.curentDateURL = data.json().result;
        if (data.json().current_date != null) {
          this.currentDate = data.json().current_date;
          this.currentTime = data.json().current_time;
        }
        else {
          //this.toast_msg(Message_data.getNetworkMessage(), 3);
        }
      }
    )
  }
  currentAppoiontData() {
    this.nurseHomeCareView = {
      type: 'nurse',
      app_id: this.personalDetails.app_id,
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.currentAppointmentURL, JSON.stringify(this.nurseHomeCareView), { headers: headers }).subscribe(
      data => {
        this.curentDateURL = JSON.stringify(data);
        var dateSplit = data.json().date.split("-");
        this.date = dateSplit[2] + "-" + dateSplit[1] + "-" + dateSplit[0];
        this.time = data.json().f_time;
        if (data.json().reason != null) {
          this.reason = data.json().reason;
          this.reasonContent = true;
        }
        this.gender = encrypt_decript.Decript(data.json().gender);
        this.age = data.json().age;
        this.bloodGroup = data.json().blood_group_desc;
        this.relation = data.json().relation;
        this.mobile = encrypt_decript.Decript(data.json().mobile);
        this.symtoms = data.json().symtoms;
        const StringBuilder = (value) => {
          this.strings = new Array("");
          this.strings.append(value);
        }
        if (data.json().middle_name != null && data.json().last_name != null) {
          this.clientName = encrypt_decript.Decript(data.json().first_name) + " " + encrypt_decript.Decript(data.json().middle_name) + " " + encrypt_decript.Decript(data.json().last_name);
        }
        else {
          if (data.json().last_name != null) {
            this.clientName = encrypt_decript.Decript(data.json().first_name) + " " + encrypt_decript.Decript(data.json().last_name);
          } else {
            this.clientName = encrypt_decript.Decript(data.json().first_name);
          }
        }
        if (encrypt_decript.Decript(data.json().address1) != null && (encrypt_decript.Decript(data.json().address2)) != "") {
          this.sample = encrypt_decript.Decript(data.json().address1) + "\n" + encrypt_decript.Decript(data.json().address2) + "\n";
        } else {
          this.sample = encrypt_decript.Decript(data.json().address1) + "\n";
        }
        this.sample = this.sample + data.json().location + "\n" + this.sample + data.json().city + "\n" + data.json().state + "\n" + data.json().country + "-" + encrypt_decript.Decript(data.json().zipcode);
        this.retriveClientListArray = data.json();
        var new_datalist = this.retriveClientListArray;
        var middle = "";
        if (new_datalist.status == "1") {
          this.nurseCancelFlag = true;
          this.nurseConfirmFlag = true;
        } else if (new_datalist.status == "0") {
          this.nurseCancelFlag = false;
          this.nurseConfirmFlag = false;
          this.nurseDoneFlag = false;
        } else if (new_datalist.status == "2") {
          this.nurseDoneFlag = true;
        }
        if (new Date(this.currentDate) > new Date(new_datalist.date) && new_datalist.status == "1") {
          this.nurseCancelFlag = false;
        }
      }
    )
  }
  cancelModel() {
    $("#staticBackdrop").modal('hide');
  }
  cancel_click() {
    $("#staticBackdrop").modal('show');
  }
  submitCancel() {
    if (this.rea_txt.toString().trim() == "" && this.rea_txt.length == 0) {
      this.toastr.error(Message_data.appcancelreason);
    }
    else if (this.rea_txt.length > 150) {
      this.toastr.error(Message_data.maxlen150);
    }
    else {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'appointment/cancelapp',
        JSON.stringify({
          type: "nurse",
          np_app_id: this.personalDetails.app_id,
          reason: this.rea_txt
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            if (obj.key != null) {
              if (obj.key == "1") {
                this.toastr.success(Message_data.appcancel);
                this.messageService.sendMessage("appointment");
              }
            }
            $("#staticBackdrop").modal('hide');
          },
          error => {
            this.toastr.error(Message_data.getNetworkMessage());
            $("#staticBackdrop").modal('hide');
          }
        )
    }
  }
}
