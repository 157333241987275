<div class="row" style="margin:0 auto; width:100%">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Appointments</h5>
          </div>
          <div class="headerButtons">
            <img class="saveimgbtn_inpatinfo" *ngIf="!saveUpdateFlag" (click)="second_opinion_save()" float-right
              src="../../../assets/ui_icons/buttons/save_button.svg" />
            <img class="saveimgbtn_inpatinfo" *ngIf="saveUpdateFlag" (click)="Report_Update_click()" float-right
              src="../../../assets/ui_icons/buttons/update_button.svg" />
            <img class="saveimgbtn_inpatinfo" (click)="back_Nav()"
              src="../../../assets/ui_icons/buttons/Back_button.svg" />
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="">
          <mat-accordion class="mataccordion">
            <mat-expansion-panel class="expandpanel">
              <mat-expansion-panel-header class="exppanel width">
                <mat-panel-title class="title width">
                  General
                </mat-panel-title>
              </mat-expansion-panel-header>
              <hr class="hr">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div class="cover_div">
                    <div class="header_lable">
                      Personal
                    </div>
                    <div class="content_cover">
                      <div class="row">
                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <mat-label class="matlabel">Opinion type<br>
                            <mat-select disableOptionCentering class="ipcss " [(ngModel)]="opinionType">
                              <mat-option *ngFor="let opinion of opinionTypeList" value={{opinion.opinion_type_id}}>
                                {{opinion.description}}</mat-option>
                            </mat-select>
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <mat-label class="matlabel">Marital status<br>
                            <mat-select id="genderid" disableOptionCentering class="ipcss " [(ngModel)]="maritalStatus">
                              <mat-option value="Single" [selected]="'Single'== maritalStatus">Single</mat-option>
                              <mat-option value="Bachelor" [selected]="'Bachelor'== maritalStatus">Bachelor</mat-option>
                              <mat-option value="Spinster" [selected]="'Spinster'== maritalStatus">Spinster</mat-option>
                              <mat-option value="Married" [selected]="'Married'== maritalStatus">Married</mat-option>
                              <mat-option value="Divorced" [selected]="'Divorced'== maritalStatus">Divorced</mat-option>
                              <mat-option value="Seperated" [selected]="'Seperated'== maritalStatus">Seperated
                              </mat-option>
                              <mat-option value="Widowed" [selected]="'Widowed'== maritalStatus">Widowed</mat-option>
                            </mat-select>
                          </mat-label>
                        </div>
                        <div class="col-12">
                          <mat-label class="matlabel">Stress factors<br>
                            <textarea #matInput class="ipcss  " [(ngModel)]="stressFactor"></textarea>
                          </mat-label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div class="cover_div" style="padding-bottom: 32px;">
                    <div class="header_lable">
                      BMI
                    </div>
                    <div class="content_cover">
                      <div class="row">
                        <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                          <mat-label class="matlabel">Height<br>
                            <input class="ipcss" matInput type="text" (change)="calories_data()" [(ngModel)]="height"
                              (keyup)="calories_data()" type="number" pattern="[0-9]*" required />
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                          <mat-label class="matlabel">Measure<br>
                            <mat-select class="ipcss" required (selectionChange)="calories_data()"
                              [(ngModel)]="heightMeasure">
                              <mat-option value="cms">cms</mat-option>
                              <mat-option value="inch">inch</mat-option>
                            </mat-select>
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                          <mat-label class="matlabel">Weight<br>
                            <input class="ipcss" matInput (change)="calories_data()" requiredtype="number"
                              pattern="[0-9]*" required [(ngModel)]="weight" />
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                          <mat-label class="matlabel">Measure<br>
                            <mat-select class="ipcss" (selectionChange)="calories_data()" required
                              [(ngModel)]="weightMeasure ">
                              <mat-option value="kgs">kgs</mat-option>
                              <mat-option value="lbs">lbs</mat-option>
                            </mat-select>
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <mat-label class="matlabel">BMI<br>
                            <input class="ipcss " matInput [(ngModel)]="bmi" disabled />
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <mat-label class="matlabel">Blood pressure<br>
                            <mat-select class="ipcss" (selectionChange)="calories_data()" required
                              [(ngModel)]="bloodPressure">
                              <mat-option value="Normal" [selected]="'Normal'== bloodPressure">Normal
                              </mat-option>
                              <mat-option value="Low" [selected]="'Low'== bloodPressure">Low</mat-option>
                              <mat-option value="High" [selected]="'High'== bloodPressure">High</mat-option>
                            </mat-select>
                          </mat-label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div class="cover_div">
                    <div class="header_lable">
                      Concerns
                    </div>
                    <div class="content_cover">
                      <div class="row">
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <mat-label class="matlabel">Concerns<br>
                            <textarea #matInput class="ipcss " [(ngModel)]="mainconcern" required
                              (click)="mainconcern_popup()" readonly></textarea>
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <mat-label class="matlabel">Past Medical History<br>
                            <textarea #matInput class="ipcss " [(ngModel)]="medhist" (click)="medical_popup()"
                              readonly></textarea>
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <mat-label class="matlabel">Religious<br>
                            <textarea #matInput class="ipcss " [(ngModel)]="religiousBelief" required
                              (click)="religious()" readonly></textarea>
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <mat-label class="matlabel">Any other isuue<br>
                            <textarea #matInput class="ipcss " [(ngModel)]="anyOtherIssue"></textarea>
                          </mat-label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div class="cover_div">
                    <div class="header_lable">
                      Diet
                    </div>
                    <div class="content_cover">
                      <div class="row">
                        <div class="col-12">
                          <mat-label class="matlabel">Dietary habits<br>
                            <textarea #matInput class="ipcss " required [(ngModel)]="dietaryHabits"
                              style="height: 132px !important;"></textarea>
                          </mat-label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" style="margin-top: 20px;">
                <div class="col-3">
                  <div class="hebitual_cover">
                    <div class="header_lable">
                      Alcohol
                    </div>
                    <div class="content_cover">
                      <mat-accordion class="mataccordion">
                        <div class="vl"></div>
                        <mat-expansion-panel [expanded]="panelOpenState1" hideToggle (opened)="panelOpenState1 = true"
                          (closed)="panelOpenState1 = false" class="exp_panel_cover">
                          <mat-expansion-panel-header class="exppanel">
                            <div class="hab_exp_image_cover">
                              <img
                                [src]="!panelOpenState1 ? '../../../assets/ui_icons/alcohol_red.svg' : '../../../assets/ui_icons/alcohol.svg'">
                            </div>
                          </mat-expansion-panel-header> <!-- heading  -->
                          <div class="hab_content_cover">
                            <div class="row">
                              <mat-label class="col-12" class="hab_conter_text">Quantity
                                <select class="sdropdown" [(ngModel)]="alcQuant">
                                  <option disabled value="Select">Select</option>
                                  <option value={{quan.description}} *ngFor="let quan of alcQuantityList">
                                    {{quan.description}}</option>
                                </select>
                              </mat-label>
                              <mat-label class="col-12" class="hab_conter_text">Mixing type
                                <select class="sdropdown" [(ngModel)]="alcMix">
                                  <option disabled value="Select">Select</option>
                                  <option value={{Mix.description}} *ngFor="let Mix of mixingList">
                                    {{Mix.description}}
                                  </option>
                                </select>
                              </mat-label>
                              <mat-label class="col-12" class="hab_conter_text">Duration
                                <select class="sdropdown" [(ngModel)]="alcholDuration">
                                  <option value="&lt;1 Year">
                                    &lt;1 Year</option>
                                  <option value="&lt;5 Years">
                                    &lt;5 Years</option>
                                  <option value=">5 Years"> >5 Years</option>
                                </select>
                              </mat-label>
                              <mat-label class="col-12" class="hab_conter_text">Frequency/day
                                <select class="sdropdown" [(ngModel)]="alcoholFrequency">
                                  <option value=&lt;5>
                                    &lt;5</option>
                                  <option value=">5 to 10">>5 to 10</option>
                                  <option value="10+">10+</option>
                                </select>
                              </mat-label>
                            </div>
                          </div>
                        </mat-expansion-panel>
                      </mat-accordion>
                    </div>
                  </div>
                </div>
                <div class="col-3">
                  <div class="hebitual_cover">
                    <div class="header_lable">
                      Smoke
                    </div>
                    <div class="content_cover">
                      <mat-accordion class="mataccordion">
                        <div class="vl"></div>
                        <mat-expansion-panel [expanded]="panelOpenState2" hideToggle (opened)="panelOpenState2 = true"
                          (closed)="panelOpenState2 = false" class="exp_panel_cover">
                          <mat-expansion-panel-header class="exppanel">
                            <div class="hab_exp_image_cover">
                              <img
                                [src]="!panelOpenState2 ? '../../../assets/ui_icons/smoke_red.svg' : '../../../assets/ui_icons/smoke.svg'">
                            </div>
                          </mat-expansion-panel-header> <!-- heading  -->
                          <div class="hab_content_cover">
                            <div class="row">
                              <mat-label class="col-12" class="hab_conter_text">Duration
                                <select class="sdropdown" [(ngModel)]="smokeDuration">
                                  <option value="&lt;1 Year"> &lt;1 Year</option>
                                  <option value="&lt;5 Years "> &lt;5 Years </option>
                                  <option value="&gt;5 Years">&gt;5 Years </option>
                                </select>
                              </mat-label>
                              <mat-label class="col-12" class="hab_conter_text">Frequency/day(times)
                                <select class="sdropdown" [(ngModel)]="smokeFrequency">
                                  <option value="&lt;5">&lt;5</option>
                                  <option value="&gt;5 to &lt;10">&gt;5 to &lt;10 </option>
                                  <option value="10+">10+</option>
                                </select>
                              </mat-label>
                            </div>
                          </div>
                        </mat-expansion-panel>
                      </mat-accordion>
                    </div>
                  </div>
                </div>
                <div class="col-3">
                  <div class="hebitual_cover">
                    <div class="header_lable">
                      Gutka
                    </div>
                    <div class="content_cover">
                      <mat-accordion class="mataccordion">
                        <div class="vl"></div>
                        <mat-expansion-panel hideToggle [expanded]="panelOpenState3" (opened)="panelOpenState3 = true"
                          (closed)="panelOpenState3 = false" class="exp_panel_cover">
                          <mat-expansion-panel-header class="exppanel">
                            <div class="hab_exp_image_cover">
                              <img
                                [src]="!panelOpenState3 ? '../../../assets/ui_icons/gutkha_red.svg' : '../../../assets/ui_icons/gutkha.svg'">
                            </div>
                          </mat-expansion-panel-header> <!-- heading  -->
                          <div class="hab_content_cover">
                            <div class="row">
                              <mat-label class="col-12" class="hab_conter_text">Duration
                                <select class="sdropdown" [(ngModel)]="gutkaDuration">
                                  <option value="&lt;1 Year">&lt;1 Year</option>
                                  <option value="&lt;5 Years ">&lt;5 Years </option>
                                  <option value="&gt;5 Years">&gt;5 Years</option>
                                </select>
                              </mat-label>
                              <mat-label class="col-12" class="hab_conter_text">Frequency/day (times)
                                <select class="sdropdown" [(ngModel)]="gutkaFrequency">
                                  <option value="&lt;5">&lt;5</option>
                                  <option value="&gt;5 to &lt;10">&gt;5 to &lt;10</option>
                                  <option value="10+">10+</option>
                                </select>
                              </mat-label>
                            </div>
                          </div>
                        </mat-expansion-panel>
                      </mat-accordion>
                    </div>
                  </div>
                </div>
                <div class="col-3">
                  <div class="hebitual_cover">
                    <div class="header_lable">
                      Exercise
                    </div>
                    <div class="content_cover">
                      <mat-accordion class="mataccordion">
                        <mat-expansion-panel hideToggle [expanded]="panelOpenState4" (opened)="panelOpenState4 = true"
                          (closed)="panelOpenState4 = false" class="exp_panel_cover">
                          <mat-expansion-panel-header class="exppanel">
                            <div class="hab_exp_image_cover">
                              <img
                                [src]="!panelOpenState4 ? '../../../assets/ui_icons/exercise_red.svg' : '../../../assets/ui_icons/exercise.svg'">
                            </div>
                          </mat-expansion-panel-header> <!-- heading  -->
                          <div class="hab_content_cover">
                            <div class="row">
                              <mat-label class="col-12" class="hab_conter_text" *ngIf="!exerciseFlag">Exercise
                                <textarea matInput class="ipcss " [(ngModel)]="exerciseTxt"
                                  (click)="selectExercise()"></textarea>
                              </mat-label>
                              <mat-label class="col-12" class="hab_conter_text" *ngIf="yogaFlag">Yoga<br>
                                <select class="sdropdown" disableOptionCentering multiple>
                                  <option disabled value="Select">Select</option>
                                  <option #matoption color="primary" (click)="yoga_click(yoga.yoga_asana_id,matoption)"
                                    *ngFor="let yoga of yogaList" value={{yoga.description}}>
                                    {{yoga.description}}
                                  </option>
                                </select>
                              </mat-label>
                              <mat-label class="col-12" class="hab_conter_text">Duration <br>
                                <select class="sdropdown" [(ngModel)]="exerDuration" [disabled]="diagnosisFlag"
                                  disableOptionCentering>
                                  <option disabled value="Select">Select</option>
                                  <option value="10mins">10mins</option>
                                  <option value="15mins">15mins</option>
                                  <option value="30mins">30mins</option>
                                  <option value="45mins">45mins</option>
                                </select>
                              </mat-label>
                              <mat-label class="col-12" class="hab_conter_text">Frequency<br>
                                <select class="sdropdown" [(ngModel)]="exerFreq" [disabled]="diagnosisFlag"
                                  disableOptionCentering>
                                  <option disabled value="Select">Select</option>
                                  <option value="Daily">Daily</option>
                                  <option value="Thrice a week">Thrice a week</option>
                                  <option value="4 days a week">4 days a week</option>
                                  <option value="5 days a week">5 days a week</option>
                                </select>
                              </mat-label>
                              <mat-label class="col-12" class="hab_conter_text">Exercise routine<br>
                                <select class="sdropdown" [(ngModel)]="exerRoutine" disableOptionCentering>
                                  <option disabled value="Select">Select</option>
                                  <option *ngFor="let routine of exerciseRoutineList" value="{{routine.description}}">
                                    {{routine.description}}</option>
                                </select>
                              </mat-label>
                            </div>
                          </div>
                        </mat-expansion-panel>
                      </mat-accordion>
                    </div>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion><br>
          <mat-accordion class="mataccordion">
            <mat-expansion-panel class="expandpanel ">
              <mat-expansion-panel-header class="exppanel width">
                <mat-panel-title class="title width">
                  Health and medication
                </mat-panel-title>
              </mat-expansion-panel-header>
              <hr class="hr">
              <div style="margin-top: 10px;">
                <div class="row" style="padding-left: 13px;">
                  <div class="col-12">
                    <div class="row">
                      <div class="col-8 col-sm-7 col-md-8 col-xl-6 col-lg-8">
                        <mat-label class="matlabel">Have you had recent unexplained weight gain?</mat-label>
                      </div>
                      <div class="col-3 col-sm-4 col-md-2 col-xl-1 col-lg-2">
                        <mat-label class="matlabel" *ngIf="wgtGainFlag"><a (click)="change_wgt_gain_value('Yes')">
                            <p id="hm_yes"><img class="family_toggle_img" src="../../../assets/ui_icons/tick_mark.svg">
                            </p>
                          </a></mat-label>
                        <mat-label class="matlabel" *ngIf="!wgtGainFlag"><a (click)="change_wgt_gain_value('No')">
                            <p id="hm_no"><img class="family_toggle_img" src="../../../assets/ui_icons/cross_mark.svg">
                            </p>
                          </a></mat-label>
                      </div>
                      <div class="col-8 col-sm-7 col-md-8 col-xl-6 col-lg-8">
                        <mat-label class="matlabel">Have you had recent unexplained weight loss?</mat-label>
                      </div>
                      <div class="col-3 col-sm-4 col-md-2 col-xl-1 col-lg-2">
                        <mat-label class="matlabel" *ngIf="wgtLossFlag"><a (click)="chnageweightloss('Yes')">
                            <p id="hm_yes"><img class="family_toggle_img" src="../../../assets/ui_icons/tick_mark.svg">
                            </p>
                          </a></mat-label>
                        <mat-label class="matlabel" *ngIf="!wgtLossFlag"><a (click)="chnageweightloss('No')">
                            <p id="hm_no"><img class="family_toggle_img" src="../../../assets/ui_icons/cross_mark.svg">
                            </p>
                          </a></mat-label>
                      </div>
                      <div class="col-8 col-sm-7 col-md-8 col-xl-6 col-lg-8">
                        <mat-label class="matlabel">Are you frequently tired?</mat-label>
                      </div>
                      <div class="col-3 col-sm-4 col-md-2 col-xl-1 col-lg-2">
                        <mat-label class="matlabel" *ngIf="freqFlag"><a (click)="changetired('Yes')">
                            <p id="hm_yes"><img class="family_toggle_img" src="../../../assets/ui_icons/tick_mark.svg">
                            </p>
                          </a></mat-label>
                        <mat-label class="matlabel" *ngIf="!freqFlag"><a (click)="changetired('No')">
                            <p id="hm_no"><img class="family_toggle_img" src="../../../assets/ui_icons/cross_mark.svg">
                            </p>
                          </a></mat-label>
                      </div>
                      <div class="col-8 col-sm-7 col-md-8 col-xl-6 col-lg-8">
                        <mat-label class="matlabel">Have you had recent fever, chills?</mat-label>
                      </div>
                      <div class="col-3 col-sm-4 col-md-2 col-xl-1 col-lg-2">
                        <mat-label class="matlabel" *ngIf="recentFlag"><a (click)="changechills('Yes')">
                            <p id="hm_yes"><img class="family_toggle_img" src="../../../assets/ui_icons/tick_mark.svg">
                            </p>
                          </a></mat-label>
                        <mat-label class="matlabel" *ngIf="!recentFlag"><a (click)="changechills('No')">
                            <p id="hm_no"><img class="family_toggle_img" src="../../../assets/ui_icons/cross_mark.svg">
                            </p>
                          </a></mat-label>
                      </div>
                      <div class="col-8 col-sm-7 col-md-8 col-xl-6 col-lg-8">
                        <mat-label class="matlabel">Are you taking any prescribed medications?</mat-label>
                      </div>
                      <div class="col-3 col-sm-4 col-md-2 col-xl-3 col-lg-3" style="position: relative;">
                        <mat-label class="matlabel" *ngIf="anyMedFlag"><a (click)="changemed('Yes')">
                            <p id="hm_yes"><img class="family_toggle_img" src="../../../assets/ui_icons/tick_mark.svg">
                            </p>
                          </a>
                          <input type="file" (change)="fileChange($event)" name="filUpload" id="file"
                            *ngIf="anyPresMedFlag" style="position: absolute; visibility: hidden;" />
                          <label class="closeoption" (change)="fileChange($event)" for="file"
                            style="position: absolute;top: 1px;width: 100%;left: 74px;"><span
                              class="excr_confirm matlabel"><i class="fa fa-upload" aria-hidden="true"></i> Upload
                              Prescription</span> </label>
                        </mat-label>
                        <mat-label class="matlabel" *ngIf="!anyMedFlag"><a (click)="changemed('No')">
                            <p id="hm_no"><img class="family_toggle_img" src="../../../assets/ui_icons/cross_mark.svg">
                            </p>
                          </a></mat-label>
                      </div>
                      <div *ngIf="!anyPresMedFlag" class="col-4 col-sm-3 col-md-2 col-lg-2 col-xl-3 view">
                        <mat-label>
                          <a (click)="ViewDocument()"><img src="../../../assets/img/nephro_icons/reports.png"
                              style="width: 30px;height: 30px;"></a>
                        </mat-label>
                      </div>
                      <div class="col-8 col-sm-7 col-md-8 col-xl-6 col-lg-8">
                        <mat-label class="matlabel">Are you taking any OTC / non-prescribed medications?</mat-label>
                      </div>
                      <div class="col-3 col-sm-4 col-md-2 col-xl-3 col-lg-3">
                        <mat-label class="matlabel" *ngIf="otcFlag"><a>
                            <p id="hm_yes" (click)="navigate_otc_herbal1('Yes')"><img class="family_toggle_img"
                                src="../../../assets/ui_icons/tick_mark.svg"></p>
                          </a>
                          <img src="../../../assets/img/OTC.png" (click)="navigate_otc_herbal('OTC')" class="so_icon" />
                        </mat-label>
                        <mat-label class="matlabel" *ngIf="!otcFlag"><a>
                            <p id="hm_no" (click)="navigate_otc_herbal1('No')"><img class="family_toggle_img"
                                src="../../../assets/ui_icons/cross_mark.svg"></p>
                          </a>
                        </mat-label>
                      </div>
                      <div class="col-8 col-sm-7 col-md-8 col-xl-6 col-lg-8">
                        <mat-label class="matlabel">Are you taking any vitamins or herbal supplements?</mat-label>
                      </div>
                      <div class="col-3 col-sm-4 col-md-2 col-xl-3 col-lg-3">
                        <mat-label class="matlabel" *ngIf="vitaminsFlag"><a (click)="vit_change('Yes')">
                            <p id="hm_yes"><img class="family_toggle_img" src="../../../assets/ui_icons/tick_mark.svg">
                            </p>
                          </a>
                          <img src="../../../assets/img/Page_icon/VitaminorHerbal_icon.png"
                            (click)="navigate_otc_herbal('Herbal')" class="so_icon" />
                        </mat-label>
                        <mat-label class="matlabel" *ngIf="!vitaminsFlag"><a (click)="vit_change('No')">
                            <p id="hm_no"><img class="family_toggle_img" src="../../../assets/ui_icons/cross_mark.svg">
                            </p>
                          </a>
                        </mat-label>
                      </div>
                      <div class="col-8 col-sm-7 col-md-8 col-xl-6 col-lg-8">
                        <mat-label class="matlabel">Have you ever had reaction to medication or supplements?</mat-label>
                      </div>
                      <div class="col-3 col-sm-4 col-md-2 col-xl-3 col-lg-3">
                        <mat-label class="matlabel" *ngIf="reactFlag"><a (click)="react_change('Yes')">
                            <p id="hm_yes"><img class="family_toggle_img" src="../../../assets/ui_icons/tick_mark.svg">
                            </p>
                          </a>
                          <img src="../../../assets/img/Page_icon/Reaction_Icon.png"
                            (click)="navigate_react_allergy('reaction')" class="so_icon" />
                        </mat-label>
                        <mat-label class="matlabel" *ngIf="!reactFlag"><a (click)="react_change('No')">
                            <p id="hm_no"><img class="family_toggle_img" src="../../../assets/ui_icons/cross_mark.svg">
                            </p>
                          </a>
                        </mat-label>
                      </div>
                      <div class="col-8 col-sm-7 col-md-8 col-xl-6 col-lg-8">
                        <mat-label class="matlabel">Are you allergic to any substance?</mat-label>
                      </div>
                      <div class="col-3 col-sm-4 col-md-2 col-xl-3 col-lg-3">
                        <mat-label class="matlabel" *ngIf="allergyFlag"><a (click)="allergy_change('No')">
                            <p id="hm_yes"><img class="family_toggle_img" src="../../../assets/ui_icons/tick_mark.svg">
                            </p>
                          </a>
                          <img src="../../../assets/img/Page_icon/Allergy_Icon.png"
                            (click)="navigate_react_allergy('allergy')" class="so_icon" />
                        </mat-label>
                        <mat-label class="matlabel" *ngIf="!allergyFlag"><a (click)="allergy_change('No')">
                            <p id="hm_no"><img class="family_toggle_img" src="../../../assets/ui_icons/cross_mark.svg">
                            </p>
                          </a>
                        </mat-label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
          <mat-accordion class="mataccordion">
            <mat-expansion-panel class="expandpanel">
              <mat-expansion-panel-header class="exppanel width">
                <mat-panel-title class="title width">
                  Family
                </mat-panel-title>
              </mat-expansion-panel-header>
              <hr class="hr">
              <div class="personaldetails" style="padding-top: 10px;margin-top: 10px;">
                <div class="row">
                  <div class="col-4">
                    <div class="cover_div">
                      <div class="content_cover">
                        <div class="row" style="justify-content: center;">
                          <div class="col-7 margin-bottomvalue">
                            <mat-label class="matlabel ">Diabetics</mat-label>
                          </div>
                          <div class="col-2  margin-bottomvalue">
                            <mat-button-toggle id="toggid1" class="togglebtn" [(ngModel)]="toggDiabFlag"
                              ngDefaultControl [checked]="toggDiabFlag" (change)="changediab($event.source.checked)">
                              <span class="familytab" *ngIf="!toggDiabFlag"><img class="family_toggle_img"
                                  src="../../../assets/ui_icons/cross_mark.svg"></span>
                              <span class="familytab" *ngIf="toggDiabFlag"><img class="family_toggle_img"
                                  src="../../../assets/ui_icons/tick_mark.svg"></span>
                            </mat-button-toggle>
                          </div>
                          <div class="col-7">
                            <mat-label class="matlabel ">Heart attack</mat-label>
                          </div>
                          <div class="col-2 margin-bottomvalue">
                            <mat-button-toggle id="toggid2" class="togglebtn" [(ngModel)]="toggHeartAttkFlag"
                              ngDefaultControl [checked]="toggHeartAttkFlag"
                              (change)="changeheart($event.source.checked)">
                              <span class="familytab" *ngIf="!toggHeartAttkFlag"><img class="family_toggle_img"
                                  src="../../../assets/ui_icons/cross_mark.svg"></span>
                              <span class="familytab" *ngIf="toggHeartAttkFlag"><img class="family_toggle_img"
                                  src="../../../assets/ui_icons/tick_mark.svg"></span>
                            </mat-button-toggle>
                          </div>
                          <div class="col-7 margin-bottomvalue">
                            <mat-label class="matlabel ">Stroke</mat-label>
                          </div>
                          <div class="col-2 margin-bottomvalue">
                            <mat-button-toggle id="toggid3" class="togglebtn" [(ngModel)]="strokeFlag" ngDefaultControl
                              [checked]="strokeFlag" (change)="changestroke($event.source.checked)">
                              <span class="familytab" *ngIf="!strokeFlag"><img class="family_toggle_img"
                                  src="../../../assets/ui_icons/cross_mark.svg"></span>
                              <span class="familytab" *ngIf="strokeFlag"><img class="family_toggle_img"
                                  src="../../../assets/ui_icons/tick_mark.svg"></span>
                            </mat-button-toggle>
                          </div>
                          <div class="col-7 margin-bottomvalue">
                            <mat-label class="matlabel ">Arterial</mat-label>
                          </div>
                          <div class="col-2">
                            <mat-button-toggle id="toggid4" class="togglebtn" [(ngModel)]="toggArterFlag"
                              ngDefaultControl [checked]="toggArterFlag" (change)="changearter($event.source.checked)">
                              <span class="familytab" *ngIf="!toggArterFlag"><img class="family_toggle_img"
                                  src="../../../assets/ui_icons/cross_mark.svg"></span>
                              <span class="familytab" *ngIf="toggArterFlag"><img class="family_toggle_img"
                                  src="../../../assets/ui_icons/tick_mark.svg"></span>
                            </mat-button-toggle>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-4" style="position: relative;left: -18px;">
                    <div class="cover_div">
                      <div class="content_cover">
                        <div class="row" style="justify-content: center;">
                          <div class="col-7 margin-bottomvalue">
                            <mat-label class="matlabel ">Endocrine</mat-label>
                          </div>
                          <div class="col-2  margin-bottomvalue">
                            <mat-button-toggle id="toggid5" class="togglebtn" [(ngModel)]="toggEndoFlag"
                              ngDefaultControl [checked]="toggEndoFlag" (change)="changeendro($event.source.checked)">
                              <span class="familytab" *ngIf="!toggEndoFlag"><img class="family_toggle_img"
                                  src="../../../assets/ui_icons/cross_mark.svg"></span>
                              <span class="familytab" *ngIf="toggEndoFlag"><img class="family_toggle_img"
                                  src="../../../assets/ui_icons/tick_mark.svg"></span>
                            </mat-button-toggle>
                          </div>
                          <div class="col-7">
                            <mat-label class="matlabel ">Autoimmune</mat-label>
                          </div>
                          <div class="col-2 margin-bottomvalue">
                            <mat-button-toggle id="toggid6" class="togglebtn" [(ngModel)]="toggAutoFlag"
                              ngDefaultControl [checked]="toggAutoFlag"
                              (change)="changeautoimmune($event.source.checked)">
                              <span class="familytab" *ngIf="!toggAutoFlag"><img class="family_toggle_img"
                                  src="../../../assets/ui_icons/cross_mark.svg"></span>
                              <span class="familytab" *ngIf="toggAutoFlag"><img class="family_toggle_img"
                                  src="../../../assets/ui_icons/tick_mark.svg"></span>
                            </mat-button-toggle>
                          </div>
                          <div class="col-7 margin-bottomvalue">
                            <mat-label class="matlabel ">Hypertension</mat-label>
                          </div>
                          <div class="col-2 margin-bottomvalue">
                            <mat-button-toggle id="toggid7" class="togglebtn" [(ngModel)]="toggHyperFlag"
                              ngDefaultControl [checked]="toggHyperFlag"
                              (change)="changehypertension($event.source.checked)">
                              <span class="familytab" *ngIf="!toggHyperFlag"><img class="family_toggle_img"
                                  src="../../../assets/ui_icons/cross_mark.svg"></span>
                              <span class="familytab" *ngIf="toggHyperFlag"><img class="family_toggle_img"
                                  src="../../../assets/ui_icons/tick_mark.svg"></span>
                            </mat-button-toggle>
                          </div>
                          <div class="col-7 margin-bottomvalue">
                            <mat-label class="matlabel ">Hemophilia</mat-label>
                          </div>
                          <div class="col-2 margin-bottomvalue">
                            <mat-button-toggle id="toggid8" class="togglebtn" [(ngModel)]="toggHemoFlag"
                              ngDefaultControl [checked]="toggHemoFlag"
                              (change)="changehemophilia($event.source.checked)">
                              <span class="familytab" *ngIf="!toggHemoFlag"><img class="family_toggle_img"
                                  src="../../../assets/ui_icons/cross_mark.svg"></span>
                              <span class="familytab" *ngIf="toggHemoFlag"><img class="family_toggle_img"
                                  src="../../../assets/ui_icons/tick_mark.svg"></span>
                            </mat-button-toggle>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-4" style="position: relative;left: -36px;">
                    <div class="cover_div">
                      <div class="content_cover">
                        <div class="row" style="justify-content: center;">
                          <div class="col-7 margin-bottomvalue">
                            <mat-label class="matlabel ">Tuberculosis</mat-label>
                          </div>
                          <div class="col-2 margin-bottomvalue">
                            <mat-button-toggle id="toggid9" class="togglebtn" [(ngModel)]="toggTuberFlag"
                              ngDefaultControl [checked]="toggTuberFlag" (change)="changetuber($event.source.checked)">
                              <span class="familytab" *ngIf="!toggTuberFlag"><img class="family_toggle_img"
                                  src="../../../assets/ui_icons/cross_mark.svg"></span>
                              <span class="familytab" *ngIf="toggTuberFlag"><img class="family_toggle_img"
                                  src="../../../assets/ui_icons/tick_mark.svg"></span>
                            </mat-button-toggle>
                          </div>
                          <div class="col-7 margin-bottomvalue">
                            <mat-label class="matlabel ">HIV</mat-label>
                          </div>
                          <div class="col-2 margin-bottomvalue">
                            <mat-button-toggle id="toggid10" class="togglebtn" [(ngModel)]="toggHivFlag"
                              ngDefaultControl [checked]="toggHivFlag" (change)="changehiv($event.source.checked)">
                              <span class="familytab" *ngIf="!toggHivFlag"><img class="family_toggle_img"
                                  src="../../../assets/ui_icons/cross_mark.svg"></span>
                              <span class="familytab" *ngIf="toggHivFlag"><img class="family_toggle_img"
                                  src="../../../assets/ui_icons/tick_mark.svg"></span>
                            </mat-button-toggle>
                          </div>
                          <div class="col-7 margin-bottomvalue">
                            <mat-label class="matlabel">Hepatitis</mat-label>
                          </div>
                          <div class="col-2  margin-bottomvalue">
                            <mat-button-toggle id="toggid11" class="togglebtn" [(ngModel)]="toggHepatFlag"
                              ngDefaultControl [checked]="toggHepatFlag"
                              (change)="changehepatitis($event.source.checked)">
                              <span class="familytab" *ngIf="!toggHepatFlag"><img class="family_toggle_img"
                                  src="../../../assets/ui_icons/cross_mark.svg"></span>
                              <span class="familytab" *ngIf="toggHepatFlag"><img class="family_toggle_img"
                                  src="../../../assets/ui_icons/tick_mark.svg"></span>
                            </mat-button-toggle>
                          </div>
                          <div class="col-7 margin-bottomvalue">
                            <mat-label class="matlabel">Glaucoma</mat-label>
                          </div>
                          <div class="col-2 margin-bottomvalue">
                            <mat-button-toggle id="toggid12" class="togglebtn" [(ngModel)]="toggGlaFlag"
                              ngDefaultControl [checked]="toggGlaFlag" (change)="changegla($event.source.checked)">
                              <span class="familytab" *ngIf="!toggGlaFlag"><img class="family_toggle_img"
                                  src="../../../assets/ui_icons/cross_mark.svg"></span>
                              <span class="familytab" *ngIf="toggGlaFlag"><img class="family_toggle_img"
                                  src="../../../assets/ui_icons/tick_mark.svg"></span>
                            </mat-button-toggle>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-sm-12 col-lg-12 col-md-12 col-xl-12">
                    <mat-label class="matlabel">Others<br>
                      <textarea class="habitstawidth ipcss" [(ngModel)]="familyOther">
                      </textarea>
                    </mat-label>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
          <mat-accordion class="mataccordion">
            <mat-expansion-panel class="expandpanel">
              <mat-expansion-panel-header class="exppanel width">
                <mat-panel-title class="title width">
                  Document
                </mat-panel-title>
              </mat-expansion-panel-header>
              <hr class="hr">
              <div style="margin-top: 10px;">
                <p class="tervys_heading"><b>Upload document reports</b></p>
                <p class="tervys_heading" [hidden]="retrvImageList.length==0"><b>Documents</b></p>
                <div [hidden]="retrvImageList.length == 0" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <table id="patienttable" class="table table-hover table-dynamic">
                    <thead>
                      <tr>
                        <th>Preview document</th>
                        <th>View document</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let viewdoc of  retrvImageList">
                        <td>
                          <img id="doc_image" src="{{viewdoc.show_image}}" class="document_img" />
                        </td>
                        <td>
                          <a (click)="report_imagepdf_detail(viewdoc.document_image)">View</a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <p class="tervys_heading" *ngIf="retrvBloodReportList.length != 0 || retrvUrineReportList.length != 0 ||
                retrvFeacesReportList.length != 0 || retrvXrayReportList.length != 0 ||
                retrvScanReportList.length != 0 || retrvUltrasoundReportList.length != 0">
                  <b>Report upload</b>
                </p>
                <div *ngIf="retrvBloodReportList.length != 0" style="margin-bottom: 5px;"
                  class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <mat-accordion class="mataccordion">
                    <mat-expansion-panel class="expandpanel" [expanded]="true">
                      <mat-expansion-panel-header class="exppanel ">
                        <mat-panel-title class="title width">
                          Blood &nbsp;&nbsp;
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="block1_grid">
                        <mat-card *ngFor="let blood_report of retrvBloodReportList"
                          (click)="report_view(blood_report.test_id,'Blood',blood_report.app_id)" class="List_border">
                          <mat-card-header></mat-card-header>
                          <mat-card-content>
                            <mat-label class="matlabel"> {{blood_report.test_name+" "+"test"}}</mat-label><br>
                          </mat-card-content>
                        </mat-card><br>
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>
                </div>
                <div [hidden]="retrvUrineReportList.length == 0" style="margin-bottom: 5px;"
                  class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <mat-accordion class="mataccordion">
                    <mat-expansion-panel class="expandpanel" [expanded]="true">
                      <mat-expansion-panel-header class="exppanel ">
                        <mat-panel-title class="title width">
                          Urine
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="block1_grid">
                        <mat-card *ngFor="let urine_report of retrvUrineReportList"
                          (click)="report_view(urine_report.test_id,'urine',urine_report.app_id)" class="List_border">
                          <mat-card-header></mat-card-header>
                          <mat-card-content>
                            <mat-label>{{urine_report.test_name+" "+"test"}}</mat-label><br>
                          </mat-card-content>
                        </mat-card><br>
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>
                </div>
                <div [hidden]="retrvFeacesReportList.length == 0" style="margin-bottom: 5px;"
                  class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <mat-accordion class="mataccordion">
                    <mat-expansion-panel class="expandpanel" [expanded]="true">
                      <mat-expansion-panel-header class="exppanel ">
                        <mat-panel-title class="title width">
                          Faeces
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="block1_grid">
                        <mat-card *ngFor="let feaces_report of retrvFeacesReportList;"
                          (click)="report_view(feaces_report.test_id,'faeces',feaces_report.app_id)"
                          class="List_border">
                          <mat-card-header></mat-card-header>
                          <mat-card-content>
                            {{feaces_report.test_name+" "+"test"}}
                          </mat-card-content>
                        </mat-card><br>
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>
                </div>
                <div [hidden]="retrvXrayReportList.length == 0" style="margin-bottom: 5px;"
                  class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <mat-accordion class="mataccordion">
                    <mat-expansion-panel class="expandpanel" [expanded]="true">
                      <mat-expansion-panel-header class="exppanel ">
                        <mat-panel-title class="title width">
                          X-ray
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="block1_grid">
                        <mat-card *ngFor="let xray_report of retrvXrayReportList"
                          (click)="report_view(xray_report.test_id,'X-ray',xray_report.app_id)" class="List_border">
                          <mat-card-header></mat-card-header>
                          <mat-card-content>
                            {{xray_report.test_name+" "+"test"}}
                          </mat-card-content>
                        </mat-card><br>
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>
                </div>
                <div [hidden]="retrvScanReportList.length == 0" style="margin-bottom: 5px;"
                  class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <mat-accordion class="mataccordion">
                    <mat-expansion-panel class="expandpanel" [expanded]="true">
                      <mat-expansion-panel-header class="exppanel ">
                        <mat-panel-title class="title width">
                          Scans
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="block1_grid">
                        <mat-card *ngFor="let scan_report of retrvScanReportList"
                          (click)="report_view(scan_report.test_id,'scans',scan_report.app_id)" class="List_border">
                          <mat-card-header></mat-card-header>
                          <mat-card-content>
                            <mat-label class="matlabel"> {{scan_report.test_name+" "+"test"}}</mat-label>
                          </mat-card-content>
                        </mat-card><br>
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>
                </div>
                <div [hidden]="retrvUltrasoundReportList.length == 0" style="margin-bottom: 5px;"
                  class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <mat-accordion class="mataccordion">
                    <mat-expansion-panel class="expandpanel" [expanded]="true">
                      <mat-expansion-panel-header class="exppanel ">
                        <mat-panel-title class="title width">
                          Ultra-sound
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="block1_grid">
                        <mat-card *ngFor="let Ultra_report of retrvUltrasoundReportList"
                          (click)="report_view(Ultra_report.test_id,'Ultra-Sound',Ultra_report.app_id)"
                          class="List_border">
                          <mat-card-header></mat-card-header>
                          <mat-card-content>
                            <p text-wrap>{{Ultra_report.test_name+" "+"test"}}</p>
                          </mat-card-content>
                        </mat-card><br>
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>
                </div>
                <p class="tervys_heading"><b>Medical Prescription</b></p>
                <div class="nodata" [hidden]="retrvPrescriptionList.length != 0">No prescription(s) found</div>
                <div class="col-12" [hidden]="retrvPrescriptionList.length == 0">
                  <table id="patienttable" class="table table-hover table-dynamic">
                    <thead>
                      <tr>
                        <th></th>
                        <th> </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let pres of medPresList">
                        <td>
                          <mat-label>{{pres.data}}</mat-label>
                        </td>
                        <td>
                          <mat-checkbox class="chk widthcheck" (change)="getpres(pres.id,$event.checked)">
                          </mat-checkbox>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <ng-container>
                <div class="col-8" (click)="documentFlag != documentFlag">
                  <p class="tervys_heading">Continue upload documents,reports</p>
                  <button id="matbtn" *ngIf="newUploadFlag" [(ngModel)]="newUpload" [style.color]="getColor('Yes')"
                    [ngStyle]="{'border':'1px solid green'}" mat-raised-button
                    (click)="UploadNewReports(13)">Yes</button>
                  <button id="matbtn" *ngIf="!newUploadFlag" [style.color]="getColor('No')"
                    [ngStyle]="{'border':'1px solid red'}" [(ngModel)]="newUpload" mat-raised-button
                    (click)="UploadNewReports(13)">No</button>
                </div>
              </ng-container>
              <!-- Add Documents -->
              <ng-container *ngIf="documentFlag">
                <p class="casesheet_para">Document upload</p>
                <div class="row">
                  <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" style="position: relative;">
                    <input type="file" (change)="fileChange($event)" name="filUpload" id="file1" *ngIf="anyPresMedFlag"
                      style="position: absolute; visibility: hidden;" />
                    <label class="closeoption" (change)="fileChange($event)" for="file1" style="    position: absolute;
                            top: 7px;
                            width: 100%;
                            left: 18px;"><span class="excr_confirm1 matlabel"><i class="fa fa-upload"
                          aria-hidden="true"></i> Upload prescription</span> </label>
                  </div>
                  <div class="col-12 col-sm-6 col-md-3 col-lg-2 col-xl-2">
                    <img class="saveimgbtn_inpatinfo" src="../../../assets/ui_icons/buttons/add_button.svg"
                      (click)="Document_Image_add()">
                  </div>
                </div>
                <br />
                <div *ngIf="imageList.length != 0" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <table id="patienttable" class="table table-hover table-dynamic">
                    <thead>
                      <tr>
                        <th>File name</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let image of imageList; let i=index">
                        <td data-th="File name">{{image.image_name}}</td>
                        <td data-th="Action"><a (click)="Document_Image_delete(i)"> <img src="../../../assets/ui_icons/buttons/trash_icon.svg" style="width: 25px; height: 25px;"> </a></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div *ngIf="documentList.length!=0">
                  <p class="tervys_heading">My documents</p>
                </div>
                <div class="row" *ngIf="documentList.length != 0">
                  <table id="patienttable" class="table table-hover table-dynamic">
                    <tbody>
                      <tr *ngFor="let doc of documentList">
                        <td data-th="File name" style="text-align: left;">{{doc.data}}</td>
                        <td data-th="Action" style="text-align: left;">
                          <p (click)="viewdoc(doc.id,doc.name)" class="textcolor">
                            <mat-checkbox item-right (change)="getdoc(doc.id,doc.name)"></mat-checkbox>
                            <u style="padding-left: 5px;">View document</u>
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <p *ngIf="bloodReportList.length != 0 || urineReportList.length != 0 ||
                feacesReportList.length != 0 || xrayReportList.length != 0 ||
                scanReportList.length != 0 || ultrasoundReportList.length != 0" class="tervys_heading">
                  <b>Report upload</b>
                </p>
                <div *ngIf="bloodReportList.length != 0 ">
                  <div style="margin-bottom: 5px;">
                    <mat-accordion class="mataccordion">
                      <mat-expansion-panel class="expandpanel" [expanded]="true">
                        <mat-expansion-panel-header class="exppanel ">
                          <mat-panel-title class="title width">
                            Blood &nbsp;&nbsp;
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="block1_grid">
                          <mat-card *ngFor="let blood_report of bloodReportList; let i=index;" class="List_border">
                            <mat-card-header></mat-card-header>
                            <mat-card-content>
                              <!-- <mat-label class="matlabel">{{list.test_name+" "+"test"}}</mat-label><br> -->
                              <p text-wrap><span>{{blood_report.Date+" : Report taken for "+blood_report.Client_Name +"
                                  in "+
                                  blood_report.Centre_Name +" referred by Dr."+ blood_report.Doctor_Name}}</span>
                                <span class="check_box_class">
                                  <mat-checkbox item-right (change)="Blood_Checked($event, blood_report.Diag_Test_Id)">
                                  </mat-checkbox>
                                </span>
                              </p>
                            </mat-card-content>
                          </mat-card><br>
                        </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                  </div>
                  <div style="margin-bottom: 5px;">
                    <mat-accordion class="mataccordion">
                      <mat-expansion-panel class="expandpanel" [expanded]="true">
                        <mat-expansion-panel-header class="exppanel ">
                          <mat-panel-title class="title width">
                            Urine
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="block1_grid">
                          <mat-card *ngFor="let urine_report of urineReportList; let i=index;" class="List_border">
                            <mat-card-header></mat-card-header>
                            <mat-card-content>
                              <p text-wrap><span>{{urine_report.Date+" : Report taken for "+urine_report.Client_Name +"
                                  in "+
                                  urine_report.Centre_Name +" referred by Dr."+ urine_report.Doctor_Name}}</span>
                                <span class="check_box_class">
                                  <mat-checkbox item-right (change)="Urine_Checked($event, urine_report.Diag_Test_Id)">
                                  </mat-checkbox>
                                </span>
                              </p>
                            </mat-card-content>
                          </mat-card><br>
                        </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                  </div>
                  <div style="margin-bottom: 5px;">
                    <mat-accordion class="mataccordion">
                      <mat-expansion-panel class="expandpanel" [expanded]="true">
                        <mat-expansion-panel-header class="exppanel ">
                          <mat-panel-title class="title width">
                            Faeces
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="block1_grid">
                          <mat-card *ngFor="let feaces_report of feacesReportList; let i=index;" class="List_border">
                            <mat-card-header></mat-card-header>
                            <mat-card-content>
                              <p text-wrap><span>{{feaces_report.Date+" : Report taken for "+feaces_report.Client_Name
                                  +" in "+
                                  feaces_report.Centre_Name +" referred by Dr."+ feaces_report.Doctor_Name}}</span>
                                <span class="check_box_class">
                                  <mat-checkbox item-right
                                    (change)="Feaces_Checked($event, feaces_report.Diag_Test_Id)"></mat-checkbox>
                                </span>
                              </p>
                            </mat-card-content>
                          </mat-card><br>
                        </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                  </div>
                  <div style="margin-bottom: 5px;">
                    <mat-accordion class="mataccordion">
                      <mat-expansion-panel class="expandpanel" [expanded]="true">
                        <mat-expansion-panel-header class="exppanel ">
                          <mat-panel-title class="title width">
                            X-ray
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="block1_grid">
                          <mat-card *ngFor="let xray_report of xrayReportList; let i=index;" class="List_border">
                            <mat-card-header></mat-card-header>
                            <mat-card-content>
                              <!-- <a (click)="Report_list_Select(xray_report.check, i)"> -->
                              <p text-wrap><span>{{xray_report.Date+" : Report taken for "+xray_report.Client_Name +"
                                  in "+
                                  xray_report.Centre_Name +" referred by Dr."+ xray_report.Doctor_Name}}</span>
                                <span class="check_box_class">
                                  <mat-checkbox item-right (change)="Xray_Checked($event, xray_report.Diag_Test_Id)">
                                  </mat-checkbox>
                                </span>
                              </p>
                            </mat-card-content>
                          </mat-card><br>
                        </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                  </div>
                  <div style="margin-bottom: 5px;">
                    <mat-accordion class="mataccordion">
                      <mat-expansion-panel class="expandpanel" [expanded]="true">
                        <mat-expansion-panel-header class="exppanel ">
                          <mat-panel-title class="title width">
                            Scans
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="block1_grid">
                          <mat-card *ngFor="let scan_report of scanReportList; let i=index;" class="List_border">
                            <mat-card-header></mat-card-header>
                            <mat-card-content>
                              <p text-wrap><span>{{scan_report.Date+" : Report taken for "+scan_report.Client_Name +"
                                  in "+
                                  scan_report.Centre_Name +" referred by Dr."+ scan_report.Doctor_Name}}</span>
                                <span class="check_box_class">
                                  <mat-checkbox item-right (change)="Scan_Checked($event, scan_report.Diag_Test_Id)">
                                  </mat-checkbox>
                                </span>
                              </p>
                            </mat-card-content>
                          </mat-card><br>
                        </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                  </div>
                  <div style="margin-bottom: 5px;">
                    <mat-accordion class="mataccordion">
                      <mat-expansion-panel class="expandpanel" [expanded]="true">
                        <mat-expansion-panel-header class="exppanel ">
                          <mat-panel-title class="title width">
                            Ultra-sound
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="block1_grid">
                          <mat-card *ngFor="let Ultra_report of ultrasoundReportList; let i=index;" class="List_border">
                            <mat-card-header></mat-card-header>
                            <mat-card-content>
                              <p text-wrap><span>{{Ultra_report.Date+" : Report taken for "+Ultra_report.Client_Name +"
                                  in "+
                                  Ultra_report.Centre_Name +" referred by Dr."+ Ultra_report.Doctor_Name}}</span>
                                <span class="check_box_class">
                                  <mat-checkbox item-right
                                    (change)="UltraSound_Checked($event, Ultra_report.Diag_Test_Id)"></mat-checkbox>
                                </span>
                              </p>
                            </mat-card-content>
                          </mat-card><br>
                        </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <p class="tervys_heading" *ngIf="retrvPrescriptionList.length!=0"><b>Medical Prescription</b></p>
                    <table id="patienttable" *ngIf="retrvPrescriptionList.length != 0"
                      class="table table-hover table-dynamic">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Delivery type </th>
                          <th>Order date </th>
                          <th>Order status </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let list of retrvPrescriptionList" (click)="view_med(list.test_id)">
                          <td><b>{{list.clientName}}</b></td>
                          <td> {{list.deliverType}}</td>
                          <td>{{list.orderDate}} - {{list.orderTime}}</td>
                          <td> {{list.orderStatus}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <p *ngIf="medPresList.length!=0" class="tervys_heading">Medical prescription</p>
                <div *ngIf="medPresList.length!=0">
                  <table id="patienttable" class="table table-hover table-dynamic">
                    <thead>
                      <tr>
                        <th></th>
                        <th> </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let pres of medPresList">
                        <td style="text-align: left;">
                          <mat-label>{{pres.data}}</mat-label>
                        </td>
                        <td>
                          <mat-checkbox class="chk widthcheck" (change)="getpres(pres.id,$event.checked)">
                          </mat-checkbox>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </ng-container>
              <ng-container>
                <div class="row" style="margin-top: 10px;">
                  <mat-label class="matlabel">
                    <mat-checkbox class="center" labelPosition="before" [(ngModel)]="agreeBox"></mat-checkbox>
                    I confirm that the information given in the form is true, complete and accurate.
                  </mat-label>
                </div>
              </ng-container>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>