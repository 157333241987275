import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
  })
  export class PatientRegistrationService {
  
    constructor(private http: HttpClient) { }
  
    postData(url: any, data: any): Observable<any> {
      // const url = 'your_api_endpoint_here';
      return this.http.post(url, data);
    }
  }