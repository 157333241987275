import { Component, OnInit , HostListener, ViewChild, Input} from '@angular/core';
import {Helper_Class} from '../../helper_class';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { GynecologyService } from '../service/gyna_obsservice';
import { Doc_Helper } from '../Doc_Helper';
import { CasesheetService } from '../casesheet/casesheet.service';
import { DiabeticpopuphistoryComponent } from '../diabeticpopuphistory/diabeticpopuphistory.component';
import { MatDialog } from '@angular/material/dialog';
import { ApgarscoreComponent } from '../apgarscore/apgarscore.component';
import { BmiandbmrService } from '../service/bmiandbmr.service';
import { masterCSData_Helper } from '../casesheet/masterCSData_Helper';
import { MasterCSHelperService } from '../casesheet/masterCSData.service';

@Component({
  selector: 'app-gyn-clinical-exam',
  templateUrl: './gyn-clinical-exam.component.html',
  styleUrls: ['./gyn-clinical-exam.component.css']
})
export class GynClinicalExamComponent implements OnInit {
  public gynClinicalFlag ="min";
  @ViewChild('bloodGlucoseCanvas') bloodGlucoseCanvas;
  public baby_weighthidden;
  public bp_txt;
  public dynamic_border:boolean;
  public skin_abnormal_array = [];
  public foot_details_array = [];
  public anemia_array = [];
  public fetus_array = [];
  public type_emergency_array = [];
  public Mode_del_array = [];
  public gest_age_array = [];
  public delivery_term;
  public reason_array = [];
  public preg_related_condition_array = [];
  public send_pregnancy_array = [];
  public send_issues_array = [];
  public preg_related_issues_array = [];
  public contracept_array = [];
  public gyna_fields;
  public obstric_hidden: boolean;
  public postnatal_hidden: boolean;
  public babywei_disabled;
  public skin_txt;
  public skin_abnormal;
  public eye_txt;
  public mouth;
  public nail;
  public lungs;
  public heart_rate;
  public BP_01;
  public BP_02;
  public heart_rythm;
  public legs;
  public foot_details;
  public thyroid;
  public thyroid_medication;
  public anemia;
  public anemia_data;
  public breast_txt;
  public mood_swing;
  public general_appearence = "Good";
  public preg_condtxt;
  public striae;
  public linea_nigra;
  public stariae_data;
  public linea_data;
  public fetel_movement;
  public fetel_movedata;
  public fetel_growth;
  public fetel_grow;
  public ante_flg;
  public volume_txt;
  public liquor_volume;
  public fet_lie;
  public fetes_lie;
  public fetus_presentation;
  public fet_present;
  public issue_pregdat;
  public issue_flg;
  public preg_flg;
  public emergency_type;
  public emergentxt;
  public emergency_txt;
  public emergency_others;
  public emer_other;
  public any_emergecny;
  public emerany_data;
  public treatement;
  public treatement_data;
  public onset_labour;
  public lbour_data;
  public baby_gender;
  public baby_gentxt;
  public baby_weight;
  public baby_weight1;
  public baby_clinic_measure;
  public babyweigt_clinictxt;
  public delivery_termid;
  public Mode_delivery;
  public delivery_modeid;
  public reason_csection;
  public reason_csect;
  public head_cirucmference;
  public head_circum;
  public delivery_location;
  public delivery_loctxt;
  public apgar_onemin;
  public apgar_onetxt;
  public height_baby;
  public height_measure;
  //public height_babytxt;
  public fundal_heightdata;
  public fundal_height;
  public apgar_five;
  public apgar_fivemin;
  public height_matchtxt;
  public height_match;
  public fetus_hearttxt;
  public fetus_heart;
  public baby_viability;
  public baby_viatxt;
  public baby_abnordata;
  public baby_abnormalities;
  public counse_data;
  public counselling_need;
  public bloodbp_data;
  public blood_pressure;
  public heart_data;
  public pulse_txt;
  public anemia_clinic;
  public anemia_clinicdata;
  public mood_data;
  public postmood_txt;
  public chest_data;
  public postchest_txt;
  public chest_condidata;
  public chest_condition;
  public breast_data;
  public postobs_breasts;
  public breast_conditiondata;
  public breast_condition;
  public purper_data;
  public puerperium_pain;
  public breastfeed_data;
  public breast_feeding;
  public breastfeed_conditiondata;
  public breastfeed_condition;
  public plan_cdata;
  public puerperium_paintxt;
  public plancond_data;
  public puerperium_cond;
  public uterine_involve;
  public uterine_involution;
  public uterine_condition;
  public utercond_data;
  public type_contracept;
  public type_contraceptdata;
  public bladder_data;
  public palpable_bladder;
  public bladdercond_data;
  public palpable_bladdercondition;
  public skin_abnormalid;
  public foot_detailstxt;
  public pregnancy_issue;
  public pregnacy_condition;
  public post_natflg;
  public skin_abnormaldis: boolean;
  public skin_required: boolean;
  public thyroid_meddisabled: boolean;
  public thyroid_required: boolean;
  public typ_emerdisabled: boolean;
  public treatement_disabled: boolean;
  public emergency_required: boolean;
  public treatment_required: boolean;
  public treat_otherdisabled: boolean;
  public treatother_required: boolean;
  public baby_viadisabled: boolean;
  public counsiling_disabled: boolean;
  public chest_required: boolean;
  public feed_required: boolean;
  public breast_required: boolean;
  public puerper_required: boolean;
  public uterine_required: boolean;
  public palpable_required: boolean;
  public gyna_case_hist_id;
  public life_style_id;
  public clinicalhist;
  public antenatal;
  public postnatal;
  
  public skin_hidden: boolean;
  public skinabnormal_hidden: boolean;
  public eye_hidden: boolean;
  public mouth_hidden: boolean;
  public nails_hidden: boolean;
  public lungs_hidden: boolean;
  public heart_hidden: boolean;
  public blood_preshidden: boolean;
  public heart_rhythmhidden: boolean;
  public legs_hidden: boolean;
  public foot_hidden: boolean;
  public thyroid_hidden: boolean;
  public thyroid_medhidden: boolean;
  public anemia_hidden: boolean;
  public breast_hidden: boolean;
  public moodswing_hidden: boolean;
  public generalapp_hidden: boolean;
  public striae_hidden: boolean;
  public linea_nighidden: boolean;
  public liquor_volhidden: boolean;
  public fetal_movehidden: boolean;
  public fetus_grohidden: boolean;
  public fetus_liehidden: boolean;
  public fetus_presenthidden: boolean;
  public anyemer_hidden: boolean;
  public typeof_emergehidden: boolean;
  public emerother_hidden: boolean;
  public onset_labhidden: boolean;
  public baby_viahidden: boolean;
  public treat_hidden: boolean;
  public modeofdel_hidden: boolean;
  public babygen_hidden: boolean;
  public babyabnormal_hidden: boolean;
  public delivery_termhidden: boolean;
  public counselling_needhidden: boolean;
  public babyweight_hidden: boolean;
  public reasonc_hidden: boolean;
  public birth_hidden: boolean;
  public head_circumhidden: boolean;
  public birth_lochidden: boolean;
  public apgar_scorefivemin: boolean;
  public apgar_scorefivemininfo: boolean;
  public length_retushidden: boolean;
  public apgar_scoreonemin: boolean;
  public apgar_scoreonemininfo: boolean;
  public fundal_matchhidden: boolean;
  public heart_fetushidden: boolean;
  public pregnancy_medhidden: boolean;
  public pregnancy_relhidden: boolean;
  public postblood_hidden: boolean;
  public pulse_hidden: boolean;
  public anaemic_hidden: boolean;
  public mood_hidden: boolean;
  public painin_perhidden: boolean;
  public typeof_contrahidden: boolean;
  public chest_hidden: boolean;
  public chestcond_hidden: boolean;
  public breast_feedhidden: boolean;
  public breast_feedcondhidden: boolean;
  public breats_hidden: boolean;
  public breatscond_hidden: boolean;
  public puerper_planhidden: boolean;
  public puerper_conditionhidden: boolean;
  public uterine_hidden: boolean;
  public uterineinvol_hidden: boolean;
  public palpable_hidden: boolean;
  public palpablecon_hidden: boolean;
  public height_disabled: boolean;
  public heightmeasure_disabled: boolean;
  public weight_disabled: boolean;
  public weightmeasure_disabled: boolean;
  public skin_disabled: boolean;
  public eye_disabled: boolean;
  public mouth_disabled: boolean;
  public nail_disabled: boolean;
  public lung_disabled: boolean;
  public heart_disabled: boolean;
  public blood_disabled: boolean;
  public heartrythm_disabled: boolean;
  public legs_disabled: boolean;
  public foot_disbaled: boolean;
  public thyroid_disabled: boolean;
  public anemia_disabled: boolean;
  public breasts_disabled: boolean;
  public mood_swingdis: boolean;
  public genapp_disabled: boolean;
  public striae_disabled: boolean;
  public linea_nigdisabled: boolean;
  public liquor_voldisabled: boolean;
  public fetal_disabled: boolean;
  public fetus_grodisabled: boolean;
  public fetus_liedisabled: boolean;
  public fetus_presdisabled: boolean;
  public anyemer_disabled: boolean;
  public oneset_oflabdis: boolean;
  public reason_disabled: boolean;
  public birth_hedis: boolean;
  public head_cirdisabled: boolean;
  public birthloc_disabled: boolean;
  public length_uterusdisabled: boolean;
  public fundal_heightdisabled: boolean;
  public heartrate_fetdisabled: boolean;
  public pregrel_disabled: boolean;
  public pregrel_issuedisabled: boolean;
  public post_blooddis: boolean;
  public pulse_disabled: boolean;
  public anaemic_disabled: boolean;
  public moodpost_disabled: boolean;
  public painper_disabled: boolean;
  public typecon_disabled: boolean;
  public chest_disabled: boolean;
  public chest_condisabled: boolean;
  public breats_feeddis: boolean;
  public breats_feedcondids: boolean;
  public breast_dis: boolean;
  public breastcond_dis: boolean;
  public puerper_disabled: boolean;
  public puerpercond_disabled: boolean;
  public uterine_disabled: boolean;
  public uterine_condisabled: boolean;
  public palpable_disabled: boolean;
  public palpable_condiiondis: boolean;
  public apgar_scoretx;
  public apgar_scoretx_five;
  public apgar_style;
  public apgarfive_style;
  public apgar_txt;
  public obstric_hidden_history: boolean;
  public postnatal_hidden_history: boolean;
  public clinic_histhidden: boolean;
  public header_footer_flag: boolean;
  public bglucosedis: boolean;
  public blood_glucose;
  public diabglucose_hidden:boolean;
  public jaundice_txt;
  public edema_txt;
  public sinus_txt;
  public pallor_txt;
  public spo2;
  public rs_rate;
  public cvs_rate;
  public heart_sound;
  public gravida;
  public parity;
  public living;
  public abortus;
  public minflag:boolean=true;
  public subscription:Subscription;
  public subscription1:Subscription;

  public height_txt;
  public weight_txt;
  public txt_bmr;
  public txt_bmi;
  public age_txt;
  public weight_measure;
  public exer_run;
  public cal_txt;
  public patient_list;
  public userinfo:any;
  public user_id:any;

  public gen_phy_tab:boolean;
  public antenatal_tab:boolean;
  public postnatal_tab:boolean;

  public client:boolean = false;

  constructor(public bmiservice:BmiandbmrService,public dialog:MatDialog,private http:Http,private routes:ActivatedRoute,private router:Router,
    public toastr:ToastrService, public msgservice:GynecologyService,public messageservice:CasesheetService,public masterCSdata:MasterCSHelperService) {
    this.jaundice_txt="No";
    this.edema_txt="No";
    this.sinus_txt="No";
    this.pallor_txt="No";
    this.skin_txt = "Normal";
    this.eye_txt = "Normal";
    this.mouth = "Normal";
    this.nail = "Normal";
    this.lungs = "Normal";
    this.heart_rythm = "Normal";
    this.legs = "Normal";
    this.thyroid = "Normal";
    this.thyroid_medication = "Yes";
    this.breast_txt = "Normal";
    this.mood_swing = "No";
    this.striae = "No";
    this.linea_nigra = "No";
    this.liquor_volume = "Normal";
    this.fetel_movement = "Yes";
    this.fetel_growth = "Normal";
    this.fetes_lie = "Longitude";
    this.any_emergecny = "No";
    this.onset_labour = "None";
    this.counselling_need = "No";
    this.baby_clinic_measure = "kgs";
    this.height_match = "Yes";
    this.blood_pressure = "Normal";
    this.pulse_txt = "Normal";
    this.postmood_txt = "Normal";
    this.puerperium_pain = "No";
    this.postchest_txt = "Normal";
    this.breast_feeding = "Normal";
    this.postobs_breasts = "Normal";
    this.puerperium_paintxt = "Yes";
    this.uterine_involution = "Normal";
    this.palpable_bladder = "Normal";
    this.baby_viability = "Live birth"
    this.anemia_clinic="None";
    this.Mode_delivery="Normal";
    this.height_measure="kgs";

    this.anemia_disabled=true;
    this.skin_hidden = true;
    this.skinabnormal_hidden = true;
    this.eye_hidden = true;
    this.mouth_hidden = true;
    this.nails_hidden = true;
    this.lungs_hidden = true;
    this.heart_hidden = true;
    this.blood_preshidden = true;
    this.heart_rhythmhidden = true;
    this.legs_hidden = true;
    this.foot_hidden = true;
    this.thyroid_hidden = true;
    this.thyroid_medhidden = true;
    this.anemia_hidden = true;
    this.breast_hidden = true;
    this.moodswing_hidden = true;
    this.generalapp_hidden = true;
    this.striae_hidden = true;
    this.linea_nighidden = true;
    this.liquor_volhidden = true;
    this.fetal_movehidden = true;
    this.fetus_grohidden = true;
    this.fetus_liehidden = true;
    this.fetus_presenthidden = true;
    this.anyemer_hidden = true;
     this.typeof_emergehidden=true;
     this.uterineinvol_hidden=true;
    this.emerother_hidden = true;
    this.onset_labhidden = true;
    this.baby_viahidden = true;
    this.treat_hidden = true;
    this.modeofdel_hidden = true;
    this.babygen_hidden = true;
    this.babyabnormal_hidden = true;
    this.delivery_termhidden = true;
    this.counselling_needhidden = true;
    this.babyweight_hidden = true;
    this.reasonc_hidden = true;
    this.birth_hidden = true;
    this.head_circumhidden = true;
    this.birth_lochidden = true;
    this.apgar_scorefivemin = true;
    this.apgar_scorefivemininfo = true;
    this.length_retushidden = true;
    this.apgar_scoreonemin = true;
    this.apgar_scoreonemininfo = true;
    this.fundal_matchhidden = true;
    this.heart_fetushidden = true;
    this.pregnancy_medhidden = true;
    this.pregnancy_relhidden = true;
    this.postblood_hidden = true;
    this.pulse_hidden = true;
    this.anaemic_hidden = true;
    this.mood_hidden = true;
    this.painin_perhidden = true;
    this.typeof_contrahidden = true;
    this.chest_hidden = true;
    this.chestcond_hidden = true;
    this.breast_feedhidden = true;
    this.breast_feedcondhidden = true;
    this.breats_hidden = true;
    this.breatscond_hidden = true;
    this.puerper_planhidden = true;
    this.puerper_conditionhidden = true;
    this.uterine_hidden = true;
    this.palpable_hidden = true;
    this.palpablecon_hidden = true;
    this.bglucosedis = true;
    this.diabglucose_hidden=true;
    this.baby_weighthidden=true;
    this.dynamic_border = true;
    this.ChangeGynofield("Gynaecology");
    this.changetab('gen_phy');
   }

  async ngOnInit(){
    this.userinfo = Helper_Class.getInfo();
   
    this.subscription = this.messageservice.getCasesheetMessage().subscribe(message => {
      if (message) {
          this.gynClinicalFlag = message;
      }
    });
    if(Doc_Helper.getAppFlow() == "client" ){
      this.client = true;
    }else{
      this.client = false;
    }

    if(masterCSData_Helper.getMasterSkinAbnormalities() == undefined){
      await this.masterCSdata.getSkinAbnormalities();
      this.Get_skin_abnormalities();
    }else{
      this.Get_skin_abnormalities();
    }

    if(masterCSData_Helper.getMasterGynoFoot() == undefined){
      await this.masterCSdata.getGynoFoot();
      this.Get_foot();
    }else{
      this.Get_foot();
    }

    if(masterCSData_Helper.getMasterAnemia() == undefined){
      await this.masterCSdata.getAnemia();
      this.Get_anemia();
    }else{
      this.Get_anemia();
    }

    if(masterCSData_Helper.getMasterFetuspresentation() == undefined){
      await this.masterCSdata.getFetuspresentation();
      this.Get_fetuspresentation();
    }else{
      this.Get_fetuspresentation();
    }

    if(masterCSData_Helper.getMasterTypeEmergency() == undefined){
      await this.masterCSdata.getTypeEmergency();
      this.Get_type_emergency();
    }else{
      this.Get_type_emergency();
    }

    if(masterCSData_Helper.getMasterModeofdelivery() == undefined){
      await this.masterCSdata.getModeofdelivery();
      this.Get_mode_delivery();
    }else{
      this.Get_mode_delivery();
    }
    if(masterCSData_Helper.getMasterGestationstage() == undefined){
      await this.masterCSdata.getGestationage();
      this.Get__delivery_term();
    }else{
      this.Get__delivery_term();
    }
    if(masterCSData_Helper.getMasterReasonCsection() == undefined){
      await this.masterCSdata.getReasonCsection();
      this.Get_reason_csection();
    }else{
      this.Get_reason_csection();
    }
    if(masterCSData_Helper.getMasterPregnancyRelatedCondition() == undefined){
      await this.masterCSdata.getPregnancyRelatedCondition();
      this.Get_pregnancy_related_condition();
    }else{
      this.Get_pregnancy_related_condition();
    }
    if(masterCSData_Helper.getMasterPregnancyMedicalCondition() == undefined){
      await this.masterCSdata.getPregnancyMedicalCondition();
      this.Get_pregnancy_medical_condition();
    }else{
      this.Get_pregnancy_medical_condition();
    }
    if(masterCSData_Helper.getMasterTypeContraceptive() == undefined){
      await this.masterCSdata.getTypeContraceptive();
      this.Get_type_contraceptive();
    }else{
      this.Get_type_contraceptive();
    }

    this.ChangeGynofield(Doc_Helper.getGyna_fields());
    var getHopitalfieldsRet=Helper_Class.getHopitalfieldsRet();
    if (getHopitalfieldsRet != undefined) {
      for (var i = 0; i < getHopitalfieldsRet.length; i++) {
        if (getHopitalfieldsRet[i].page_name == "Clinical examination") {
          if (getHopitalfieldsRet[i].fields != undefined) {

            for (var j = 0; j < getHopitalfieldsRet[i].fields.length; j++) {
              if (getHopitalfieldsRet[i].fields[j].field_name == "Blood pressure") {
                this.blood_preshidden = false;
                if(this.postnatal_hidden==true)
                    this.postblood_hidden = true;
                else
                this.postblood_hidden = false;
              }
              if (getHopitalfieldsRet[i].fields[j].field_name == "Skin") {
                this.skin_hidden = false;
                this.skinabnormal_hidden = false;
              }
              if (getHopitalfieldsRet[i].fields[j].field_name == "Eyes") {
                this.eye_hidden = false;
              }
              if (getHopitalfieldsRet[i].fields[j].field_name == "Mouth") {
                this.mouth_hidden = false;
              }
              if (getHopitalfieldsRet[i].fields[j].field_name == "Nails") {
                this.nails_hidden = false;
              }
              if (getHopitalfieldsRet[i].fields[j].field_name == "Lungs") {
                this.lungs_hidden = false;
              }
              if (getHopitalfieldsRet[i].fields[j].field_name == "Heart rate") {
                this.heart_hidden = false;
              }
              if (getHopitalfieldsRet[i].fields[j].field_name == "Heart rhythm") {
                this.heart_rhythmhidden = false;
              }
              if (getHopitalfieldsRet[i].fields[j].field_name == "Legs") {
                this.legs_hidden = false;
              }
              if (getHopitalfieldsRet[i].fields[j].field_name == "Foot") {
                this.foot_hidden = false;
              }
              if (getHopitalfieldsRet[i].fields[j].field_name == "Thyroid") {
                this.thyroid_hidden = false;
                this.thyroid_medhidden = false;
              }
              if (getHopitalfieldsRet[i].fields[j].field_name == "Anaemia") {
                this.anemia_hidden = false;
              }
              if (getHopitalfieldsRet[i].fields[j].field_name == "Breasts") {

                this.breast_hidden = false;
              }
              if (getHopitalfieldsRet[i].fields[j].field_name == "Mood swing") {
                this.moodswing_hidden = false;
              }
              if (getHopitalfieldsRet[i].fields[j].field_name == "General appearance") {
                this.generalapp_hidden = false;
              }
              if (this.gyna_fields == "Obstetrics") {
                if (getHopitalfieldsRet[i].fields[j].field_name == "Striae") {
                  this.striae_hidden = false;
                }
                if (getHopitalfieldsRet[i].fields[j].field_name == "Linea nigra") {
                  this.linea_nighidden = false;
                }
                if (getHopitalfieldsRet[i].fields[j].field_name == "Liquor volume") {
                  this.liquor_volhidden = false;
                }
                if (getHopitalfieldsRet[i].fields[j].field_name == "Fetal movements") {
                  this.fetal_movehidden = false;
                }
                if (getHopitalfieldsRet[i].fields[j].field_name == "Fetus growth") {
                  this.fetus_grohidden = false;
                }
                if (getHopitalfieldsRet[i].fields[j].field_name == "Fetus lie") {
                  this.fetus_liehidden = false;
                }
                if (getHopitalfieldsRet[i].fields[j].field_name == "Fetus presentation") {
                  this.fetus_presenthidden = false;
                }
                if (getHopitalfieldsRet[i].fields[j].field_name == "Any emergencies") {
                  this.anyemer_hidden = false;
                  this.typeof_emergehidden = false;
                  this.treat_hidden = false;
                  this.emerother_hidden = false;
                }
                if (getHopitalfieldsRet[i].fields[j].field_name == "onset of labour") {
                  this.onset_labhidden = false;
                }
                if (getHopitalfieldsRet[i].fields[j].field_name == "Baby's viability") {
                  this.baby_viahidden = false;
                }
                if (getHopitalfieldsRet[i].fields[j].field_name == "Mode of delivery") {
                  this.modeofdel_hidden = false;
                }
                if (getHopitalfieldsRet[i].fields[j].field_name == "Baby's gender") {
                  this.babygen_hidden = false;
                }
                if (getHopitalfieldsRet[i].fields[j].field_name == "Baby's abnormalities") {
                  this.babyabnormal_hidden = false;
                }
                if (getHopitalfieldsRet[i].fields[j].field_name == "Delivery term") {
                  this.delivery_termhidden = false;
                }
                if (getHopitalfieldsRet[i].fields[j].field_name == "Counselling needed") {
                  this.counselling_needhidden = false;
                }
                if (getHopitalfieldsRet[i].fields[j].field_name == "Baby's weight") {
                  this.babyweight_hidden = false;
                  this.baby_weighthidden=false;
                }
                if (getHopitalfieldsRet[i].fields[j].field_name == "Birth height") {
                  this.birth_hidden = false;
                }
                if (getHopitalfieldsRet[i].fields[j].field_name == "Head circumfrence") {
                  this.head_circumhidden = false;
                }
                if (getHopitalfieldsRet[i].fields[j].field_name == "Birth location") {
                  this.birth_lochidden = false;
                }
                if (getHopitalfieldsRet[i].fields[j].field_name == "Apgar score at 5min") {
                  this.apgar_scorefivemin = false;
                  //this.apgar_scorefivemininfo = false;
                }
                if (getHopitalfieldsRet[i].fields[j].field_name == "Length of the uterus") {
                  this.length_retushidden = false;
                }
                if (getHopitalfieldsRet[i].fields[j].field_name == "Apgar score at 1min") {
                  this.apgar_scoreonemin = false;
                  //this.apgar_scoreonemininfo = false;
                }
                if (getHopitalfieldsRet[i].fields[j].field_name == "Fundal height match") {
                  this.fundal_matchhidden = false;
                }
                if (getHopitalfieldsRet[i].fields[j].field_name == "heat rate of fetus") {
                  this.heart_fetushidden = false;
                }
                if (getHopitalfieldsRet[i].fields[j].field_name == "pregnancy related medical") {
                  this.pregnancy_medhidden = false;
                }
                if (getHopitalfieldsRet[i].fields[j].field_name == "pregnancy related issues") {
                  this.pregnancy_relhidden = false;
                }
                if (getHopitalfieldsRet[i].fields[j].field_name == "Pulse") {
                  this.pulse_hidden = false;
                }
                if (getHopitalfieldsRet[i].fields[j].field_name == "Anaemic") {
                  this.anaemic_hidden = false;
                }
                if (getHopitalfieldsRet[i].fields[j].field_name == "Mood") {
                  this.mood_hidden = false;
                }
                if (getHopitalfieldsRet[i].fields[j].field_name == "Pain in perineum") {
                  this.painin_perhidden = false;
                }
                if (getHopitalfieldsRet[i].fields[j].field_name == "Type of contraceptive") {
                  this.typeof_contrahidden = false;
                }
                if (getHopitalfieldsRet[i].fields[j].field_name == "Chest") {
                  this.chest_hidden = false;
                  this.chestcond_hidden=false;
                }
                // if (getHopitalfieldsRet[i].fields[j].field_name == "Chest condition") {
                //   this.chestcond_hidden = false;
                // }
                if (getHopitalfieldsRet[i].fields[j].field_name == "Breast feeding") {
                  this.breast_feedhidden = false;
                  this.breast_feedcondhidden = false;
                }
                // if(getHopitalfieldsRet[i].fields[j].field_name=="Breast feeding condition"){

                // }
                if (getHopitalfieldsRet[i].fields[j].field_name == "Breasts") {
                  this.breats_hidden = false;
                  this.breatscond_hidden = false;
                }
                // if(getHopitalfieldsRet[i].fields[j].field_name=="Breasts condition"){


                // }
                if (getHopitalfieldsRet[i].fields[j].field_name == "Puerperium plan") {
                  this.puerper_planhidden = false;
                  this.puerper_conditionhidden = false;
                }
                if (getHopitalfieldsRet[i].fields[j].field_name == "Uterine involution") {
                  this.uterine_hidden = false;
                  this.uterineinvol_hidden = false;
                }
                if (getHopitalfieldsRet[i].fields[j].field_name == "Palpable bladder") {
                  this.palpable_hidden = false;
                  this.palpablecon_hidden = false;
                }
              }
            }
          }
        }
      }
    }
    this.patient_list = Doc_Helper.getClient_Info();
  }

  ChangeGynofield(e){
    this.gyna_fields = "";
    this.gyna_fields = e;

    if (this.gyna_fields == "Gynaecology") {
      this.obstric_hidden = true;
      this.postnatal_hidden = true;
      this.postnatal_hidden_history = true;
      this.obstric_hidden_history = true;
      this.diabglucose_hidden=true;
    } else if (this.gyna_fields == "Obstetrics") {
      this.obstric_hidden = false;
      this.striae_hidden = false;
      this.linea_nighidden = false;
      this.liquor_volhidden = false;
      this.fetal_movehidden = false;
      this.fetus_grohidden = false;
      this.fetus_liehidden = false;
      this.fetus_presenthidden = false;
      this.anyemer_hidden = false;
      this.typeof_emergehidden = false;
      this.emerother_hidden = false;
      this.onset_labhidden = false;
      this.baby_viahidden = false;
      this.treat_hidden = false;
      this.modeofdel_hidden = false;
      this.babygen_hidden = false;
      this.babyabnormal_hidden = false;
      this.delivery_termhidden = false;
      // this.reasonc_hidden = false;
      this.counselling_needhidden = false;
      this.head_circumhidden = false;
      this.birth_lochidden = false;
      this.apgar_scoreonemin = false;
      this.apgar_scorefivemin = false;
      this.length_retushidden = false;
      this.fundal_matchhidden = false;
      this.heart_fetushidden = false;
      this.birth_lochidden = false;
      this.baby_weighthidden = false;
      this.babyweight_hidden = false;
      this.birth_hidden = false;
      this.pregnancy_medhidden = false;
      this.pregnancy_relhidden = false;
      this.postnatal_hidden = false;
      this.postnatal_hidden_history = false;
      this.postblood_hidden = false;
      this.pulse_hidden = false;
      this.anaemic_hidden = false;
      this.mood_hidden = false;
      this.painin_perhidden = false;
      this.typeof_contrahidden = false;
      this.chest_hidden = false;
      this.chestcond_hidden = false;
      this.breast_feedhidden = false;
      this.breast_feedcondhidden = false;
      this.breats_hidden = false;
      this.breatscond_hidden = false;
      this.puerper_planhidden = false;
      this.puerper_conditionhidden = false;
      this.uterine_hidden = false;
      this.uterineinvol_hidden = false;
      this.palpable_hidden = false;
      this.palpablecon_hidden = false;
      this.obstric_hidden_history = false;
      this.diabglucose_hidden=false;
    }
  }

  formatNumber(e: any, separador: string = '.', decimais: number = 2) {
    let a:any = e.value.split('');
    let ns:string = '';
    a.forEach((c:any) => { if (!isNaN(c)) ns = ns + c; });
    ns = parseInt(ns).toString();
    if (ns.length < (decimais+1)) { ns = ('0'.repeat(decimais+1) + ns); ns = ns.slice((decimais+1)*-1); }
    let ans = ns.split('');
    let r = '';
    for (let i=0; i < ans.length; i++) if (i == ans.length - decimais) r = r + separador + ans[i]; else r = r + ans[i];
    e.value = r;
  }

  //Read only(disabeld)
  isReadonly() {
     return true; 
    }
  // @HostListener('window:click', ['$event'])
  // onclick(event: any): void {
  //   this.send_data();

  // }
  // @HostListener('window:blur', ['$event'])
  // onBlur(event: any): void {
  //   this.send_data();
  // }

  pallor_change(data){
    if(data =="Present"){
      this.anemia_disabled=false;

    } else {
      this.anemia_disabled=true;
    }
  }

  //validations
  modedel_change(data) {
    if (data == "C-section") {
      this.reasonc_hidden = false;

    } else {
      this.reasonc_hidden = true;
    }
  }

  skin_change(data) {

    if (data == "Abnormal") {
      this.skin_abnormaldis = false;

    } else {
      this.skin_abnormaldis = true;
    }
  }

  thyroid_change(data) {
    if (data == "Normal") {
      this.thyroid_meddisabled = true;

    } else {
      this.thyroid_meddisabled = false;
    }
  }

  any_emergencychange(data) {
    if (data == "No") {
      this.typ_emerdisabled = true;
      this.treatement_disabled = true;
      this.emergency_required = false;
      this.treatment_required = false;

    } else {
      this.typ_emerdisabled = false;
      this.treatement_disabled = false;
      this.emergency_required = true;
      this.treatment_required = true;
    }
  }

  typeofemergency_change(data) {
    if (data == "Others") {
      this.treat_otherdisabled = false;
      this.treatother_required = true;

    } else {
      this.treat_otherdisabled = true;
      this.treatother_required = false;
    }
  }

  onsetlabour_change(data) {
    if (data == "None") {
      this.baby_viadisabled = true;
      this.postnatal_hidden = true;
      this.postnatal_hidden_history = true;
      this.apgar_scorefivemininfo = true;
      this.apgar_scoreonemininfo = true;

    } else {
      this.baby_viadisabled = false;
      this.postnatal_hidden = false;
      this.postnatal_hidden_history = false;
      this.apgar_scorefivemininfo = false;
      this.apgar_scoreonemininfo = false;
    }
    if(this.apgar_onemin == ""){
      this.dynamic_border = true; 
    }else{
      this.dynamic_border = false; 
    }
    

  }

  baby_viachange(data) {
    if (data == "Live birth") {
      this.counsiling_disabled = true;

    } else {
      this.counsiling_disabled = false;
    }
  }

  chest_change(data) {
    if (data == "Abnormal") {
      this.chest_required = true;
      this.chest_condisabled = false;

    } else {
      this.chest_required = false;
      this.chest_condisabled = true;
    }
  }

  breastfeed_change(data) {
    if (data == "Abnormal") {
      this.feed_required = true;
      this.breats_feedcondids = false;

    } else {
      this.feed_required = false;
      this.breats_feedcondids = true;
    }
  }

  breast_change(data) {
    if (data == "Abnormal") {
      this.breast_required = true;
      this.breastcond_dis = false;

    } else {
      this.breast_required = false;
      this.breastcond_dis = true;
    }
  }

  purperium_change(data) {
    if (data == "No") {
      this.puerper_required = true;
      this.puerpercond_disabled = false;

    } else {
      this.puerper_required = false;
      this.puerpercond_disabled = true;
    }
  }

  uterine_change(data) {
    if (data == "Abnormal") {
      this.uterine_required = true;
      this.uterine_condisabled = false;

    } else {
      this.uterine_required = false;
      this.uterine_condisabled = true;
    }
  }

  palpable_change(data) {
    if (data == "Abnormal") {
      this.palpable_required = true;
      this.palpable_condiiondis = false;

    } else {
      this.palpable_required = false;
      this.palpable_condiiondis = true;
    }
  }
  
  //retrieval

  clinical_retrieval() {
    var getgynaRet=Helper_Class.getgynaRet();
    
    if (getgynaRet != undefined) {
      //if (Helper_class.getgynaRet().height != null) {
      if (getgynaRet.case_hist_id != null) {
        this.gyna_case_hist_id = getgynaRet.case_hist_id;
      }

      if (getgynaRet.life_style_id != null) {
        this.life_style_id = getgynaRet.life_style_id;
      }

      if(getgynaRet.rs != null){
        this.rs_rate = getgynaRet.rs;
      }

      if(getgynaRet.cvs != null){
        this.cvs_rate = getgynaRet.cvs;
      }
      
      if (getgynaRet.skin != null) {
        this.skin_txt = getgynaRet.skin;
        if (this.skin_txt == "Abnormal") {
          this.skin_abnormaldis = false;

        } else {
          this.skin_abnormaldis = true;
        }
      }

      //skin abnormalities
      if (getgynaRet.skin_abnorm != null) {
        for (var i = 0; i < this.skin_abnormal_array.length; i++) {
          for (var j = 0; j < getgynaRet.skin_abnorm.length; j++) {
            if (getgynaRet.skin_abnorm[j] == this.skin_abnormal_array[i].skin_abnorm_id) {
              this.skin_abnormal = this.skin_abnormal_array[i].description;
            }
          }
        }
      }

      if (getgynaRet.eye != null) {
        this.eye_txt = getgynaRet.eye;
      }

      if (getgynaRet.mouth != null) {
        this.mouth = getgynaRet.mouth;
      }

      if (getgynaRet.nails != null) {
        this.nail = getgynaRet.nails;
      }

      if (getgynaRet.lungs != null) {
        this.lungs = getgynaRet.lungs;
      }

      if (getgynaRet.pulse != null) {
        this.heart_rate = getgynaRet.pulse;
      }

      if (getgynaRet.clinical_bp != null) {
        var bloodprespli = getgynaRet.clinical_bp.split('/');
        this.BP_01 = bloodprespli[0];
        this.BP_02 = bloodprespli[1];
      }

      if (getgynaRet.heart_rhythm != null) {
        this.heart_rythm = getgynaRet.heart_rhythm;
      }

      if (getgynaRet.legs != null) {
        this.legs = getgynaRet.legs;
      }

      //foot
      if (getgynaRet.foot != null) {
        for (var i = 0; i < this.foot_details_array.length; i++) {
          for (var j = 0; j < getgynaRet.foot.length; j++) {
            if (getgynaRet.foot[j] == this.foot_details_array[i].foot_id) {
              this.foot_details = this.foot_details_array[i].description;
            }
          }
        }
      }

      if (getgynaRet.thyroid != null) {
        this.thyroid = getgynaRet.thyroid;
        if (this.thyroid == "Normal") {
          this.thyroid_meddisabled = true;

        } else {
          this.thyroid_meddisabled = false;
        }
      }

      if (getgynaRet.thyroid_medication != null) {
        this.thyroid_medication = getgynaRet.thyroid_medication;
      }

      if (getgynaRet.anaemia != null) {
        for (var i = 0; i < this.anemia_array.length; i++) {
          for (var j = 0; j < getgynaRet.anaemia.length; j++) {
            if (getgynaRet.anaemia[j] == this.anemia_array[i].anaemia_id) {
              this.anemia = this.anemia_array[i].description;
            }
          }
        }
      }

      if (getgynaRet.breasts != null) {
        this.breast_txt = getgynaRet.breasts;
      }

      if (getgynaRet.mood_swing != null) {
        this.mood_swing = getgynaRet.mood_swing;
      }

      if (getgynaRet.general_apearnace != null) {
        this.general_appearence = getgynaRet.general_apearnace;
      }

      if (getgynaRet.striae != null) {
        this.striae = getgynaRet.striae;
      }

      if (getgynaRet.linea_nigra != null) {
        this.linea_nigra = getgynaRet.linea_nigra;
      }

      if (getgynaRet.liq_vol != null) {
        this.liquor_volume = getgynaRet.liq_vol;
      }

      if (getgynaRet.fetal_movement != null) {
        this.fetel_movement = getgynaRet.fetal_movement;
      }

      if (getgynaRet.fetus_growth != null) {
        this.fetel_growth = getgynaRet.fetus_growth;
      }

      if (getgynaRet.fetus_lie != null) {
        this.fetes_lie = getgynaRet.fetus_lie;
      }

      //Fetus presentation
      if (getgynaRet.fetus_pres != null) {
        for (var i = 0; i < this.fetus_array.length; i++) {
          for (var j = 0; j < getgynaRet.fetus_pres.length; j++) {
            if (getgynaRet.fetus_pres[j] == this.fetus_array[i].fetus_present_id) {
              this.fetus_presentation = this.fetus_array[i].description;
            }
          }
        }
      }

      if (getgynaRet.emergency != null) {
        this.any_emergecny = getgynaRet.emergency;
        if (this.any_emergecny == "No") {
          this.typ_emerdisabled = true;
          this.treatement_disabled = true;
          this.emergency_required = false;
          this.treatment_required = false;

        } else {
          this.typ_emerdisabled = false;
          this.treatement_disabled = false;
          this.emergency_required = true;
          this.treatment_required = true;
        }
      }

      if (getgynaRet.emerg_type != null) {
        for (var i = 0; i < this.type_emergency_array.length; i++) {
          for (var j = 0; j < getgynaRet.emerg_type.length; j++) {
            if (getgynaRet.emerg_type[j] == this.type_emergency_array[i].emerg_id) {
              this.emergency_type = this.type_emergency_array[i].description;
              if (this.emergency_type == "Others") {
                this.treat_otherdisabled = false;
                this.treatother_required = true;

              } else {
                this.treat_otherdisabled = true;
                this.treatother_required = false;
              }
            }
          }
        }
      }

      if (getgynaRet.emerg_others != null) {
        this.emergency_others = getgynaRet.emerg_others;
      }

      if (getgynaRet.preg_med_cond != null) {
        for (var j = 0; j < getgynaRet.preg_med_cond.length; j++) {
          this.send_pregnancy_array.push(getgynaRet.preg_med_cond[j].preg_med_cond_id);
        }
        this.pregnancy_change(this.send_pregnancy_array)
      }

      if (getgynaRet.labour_onset != null) {
        this.onset_labour = getgynaRet.labour_onset;
        
        this.onsetlabour_change(this.onset_labour);
        if (this.onset_labour != "None") {
          this.baby_viadisabled = false;
          if (this.gyna_fields == "Obstetrics") {
            this.postnatal_hidden = false;
            this.postnatal_hidden_history = false;
          }
          else {
            this.baby_viadisabled = true;
            this.postnatal_hidden = true;
            this.postnatal_hidden_history = true;
          }
        }else{
          this.apgar_scorefivemininfo = false;
          this.apgar_scoreonemininfo = false;
        }
        
      } 

      if (getgynaRet.baby_viab != null) {
        this.baby_viability = getgynaRet.baby_viab;
        if (this.baby_viability == "Live birth") {
          this.counsiling_disabled = true;

        } else {
          this.counsiling_disabled = false;
        }
      }

      if (getgynaRet.pregnancy_issues != null) {
        for (var j = 0; j < getgynaRet.pregnancy_issues.length; j++) {
          this.send_issues_array.push(getgynaRet.pregnancy_issues[j].preg_issue_id);
        }
        this.issue_change(this.send_issues_array)
      }

      if (getgynaRet.baby_viab != null) {
        this.baby_viability = getgynaRet.baby_viab;
      }

      if (getgynaRet.treat_type != null) {
        this.treatement = getgynaRet.treat_type;
      }

      //Modeof delivery
      if (getgynaRet.del_mode != null) {
        for (var i = 0; i < this.Mode_del_array.length; i++) {
          for (var j = 0; j < getgynaRet.del_mode.length; j++) {
            if (getgynaRet.del_mode[j] == this.Mode_del_array[i].labour_id) {
              this.Mode_delivery = this.Mode_del_array[i].description;

              if (this.Mode_delivery == "C-section") {
                this.reasonc_hidden = false;

              } else {
                this.reasonc_hidden = true;
              }
            }
          }
        }
      }

      if (getgynaRet.baby_gender != null) {
        this.baby_gender = getgynaRet.baby_gender;
      }

      if (getgynaRet.baby_abnorm != null) {
        this.baby_abnormalities = getgynaRet.baby_abnorm;
      }

      if (getgynaRet.baby_abnorm != null) {
        this.baby_abnormalities = getgynaRet.baby_abnorm;
      }

      //delivery term
      if (getgynaRet.del_term != null) {
        for (var i = 0; i < this.gest_age_array.length; i++) {
          for (var j = 0; j < getgynaRet.del_term.length; j++) {
            if (getgynaRet.del_term[j] == this.gest_age_array[i].gestation_age_id) {
              this.delivery_term = this.gest_age_array[i].description;
            }
          }
        }
      }

      if (getgynaRet.counsel != null) {
        this.counselling_need = getgynaRet.counsel;
      }

      if (getgynaRet.baby_weight != null) {
        var babaywe = getgynaRet.baby_weight.split('.');
        this.baby_weight = babaywe[0];
        var babaywe_dataagain = babaywe[1].split(' ');;
      
        this.baby_weight1 = babaywe_dataagain[0];
        this.baby_clinic_measure = babaywe_dataagain[1];
      }

      if (getgynaRet.reason_c_section != null) {
        for (var i = 0; i < this.reason_array.length; i++) {
          for (var j = 0; j < getgynaRet.reason_c_section.length; j++) {
            if (getgynaRet.reason_c_section[j] == this.reason_array[i].csec_reason_id) {
              this.reason_csection = this.reason_array[i].description;
            }
          }
        }
      }

      if (getgynaRet.baby_height != null) {
        var babayheight = getgynaRet.baby_height.split(' ');
        this.height_baby = babayheight[0];
        this.height_measure = babayheight[1];
      }

      if (getgynaRet.baby_head_circum != null) {
        this.head_circum = getgynaRet.baby_head_circum;
      }

      if (getgynaRet.delivery_location != null) {
        this.delivery_location = getgynaRet.delivery_location;
      }

      if (getgynaRet.apgar_score_five_min != null) {
        this.apgar_fivemin = getgynaRet.apgar_score_five_min;
      }

      if (getgynaRet.fundal_height != null) {
        this.fundal_height = getgynaRet.fundal_height;
      }

      if (getgynaRet.apgar_score_one_min != null) {
        this.apgar_onemin = getgynaRet.apgar_score_one_min;
        this.dynamic_border = true;
      }

      
      if (getgynaRet.fun_height_match_GA != null) {
        this.height_match = getgynaRet.fun_height_match_GA;
      }

      if (getgynaRet.fetus_heart_rate != null) {
        this.fetus_heart = getgynaRet.fetus_heart_rate;
      }

      if (getgynaRet.blood_pressure != null) {
        this.blood_pressure = getgynaRet.blood_pressure;
      }

      if (getgynaRet.heart_rate != null) {
        this.pulse_txt = getgynaRet.heart_rate;
      }

      if (getgynaRet.heart_rate != null) {
        this.pulse_txt = getgynaRet.heart_rate;
      }

      //Anaemic
      if (getgynaRet.anaemic != null) {
        for (var i = 0; i < this.anemia_array.length; i++) {
          for (var j = 0; j < getgynaRet.anaemic.length; j++) {
            if (getgynaRet.anaemic[j] == this.anemia_array[i].anaemia_id) {
              this.anemia_clinic = this.anemia_array[i].description;
            }
          }
        }
      }

      // Type of contraceptive
      if (getgynaRet.contraceptive != null) {
        for (var i = 0; i < this.contracept_array.length; i++) {
          if (getgynaRet.contraceptive == this.contracept_array[i].contraceptive_id) {
            this.type_contracept = this.contracept_array[i].description;
          }
        }
      }

      if (getgynaRet.heart_rate != null) {
        this.postmood_txt = getgynaRet.heart_rate;
      }

      if (getgynaRet.perinium_pain != null) {
        this.puerperium_pain = getgynaRet.perinium_pain;
      }

      if (getgynaRet.chest != null) {
        this.postchest_txt = getgynaRet.chest;
        if (this.postchest_txt == "Abnormal") {
          this.chest_required = true;
          this.chest_condisabled = false;

        } else {
          this.chest_required = false;
          this.chest_condisabled = true;
        }
      }

      if (getgynaRet.chest_cond != null) {
        this.chest_condition = getgynaRet.chest_cond;
      }

      if (getgynaRet.breast_feeding != null) {
        this.breast_feeding = getgynaRet.breast_feeding;
        if (this.breast_feeding == "Abnormal") {
          this.feed_required = true;
          this.breats_feedcondids = false;

        } else {
          this.feed_required = false;
          this.breats_feedcondids = true;
        }
      }

      if (getgynaRet.breast_feeding_cond != null) {
        this.breastfeed_condition = getgynaRet.breast_feeding_cond;
      }

      if (getgynaRet.obs_breasts != null) {
        this.postobs_breasts = getgynaRet.obs_breasts;
        if (this.postobs_breasts == "Abnormal") {
          this.breast_required = true;
          this.breastcond_dis = false;

        } else {
          this.breast_required = false;
          this.breastcond_dis = true;
        }
      }

      if (getgynaRet.breast_cond != null) {
        this.breast_condition = getgynaRet.breast_cond;
      }

      if (getgynaRet.puerperium != null) {
        this.puerperium_paintxt = getgynaRet.puerperium;
        if (this.puerperium_paintxt == "No") {
          this.puerper_required = true;
          this.puerpercond_disabled = false;

        } else {
          this.puerper_required = false;
          this.puerpercond_disabled = true;
        }
      }

      if (getgynaRet.puerperium_cond != null) {
        this.puerperium_cond = getgynaRet.puerperium_cond;
      }

      if (getgynaRet.jaundice != null) {
        this.jaundice_txt = getgynaRet.jaundice;
      }

      if (getgynaRet.edema != null) {
        this.edema_txt = getgynaRet.edema;
      }

      if (getgynaRet.sinus != null) {
        this.sinus_txt = getgynaRet.sinus;
      }

      if (getgynaRet.pallor != null) {
        this.pallor_txt = getgynaRet.pallor;
      }

      if (getgynaRet.spo2 != null) {
        this.spo2 = getgynaRet.spo2;
      }

      if (getgynaRet.rs_rate != null) {
        this.rs_rate = getgynaRet.rs_rate;
      }

      if (getgynaRet.cvs_rate != null) {
        this.cvs_rate = getgynaRet.cvs_rate;
      }

      if (getgynaRet.heart_sound != null) {
        this.heart_sound = getgynaRet.heart_sound;
      }

      if (getgynaRet.gravida != null) {
        this.gravida = getgynaRet.gravida;
      }

      if (getgynaRet.parity != null) {
        this.parity = getgynaRet.parity;
      }

      if (getgynaRet.living != null) {
        this.living = getgynaRet.living;
      }
      if (getgynaRet.abortus != null) {
        this.abortus = getgynaRet.abortus;
      }

      if (getgynaRet.uterine_evolution != null) {
        this.uterine_involution = getgynaRet.uterine_evolution;
        if (this.uterine_involution == "Abnormal") {
          this.uterine_required = true;
          this.uterine_condisabled = false;

        } else {
          this.uterine_required = false;
          this.uterine_condisabled = true;
        }
      }

      if (getgynaRet.uterine_evolve_cond != null) {
        this.uterine_condition = getgynaRet.uterine_evolve_cond;
      }

      if (getgynaRet.palpable_bladder != null) {
        this.palpable_bladder = getgynaRet.palpable_bladder;
        if (this.palpable_bladder == "Abnormal") {
          this.palpable_required = true;
          this.palpable_condiiondis = false;

        } else {
          this.palpable_required = false;
          this.palpable_condiiondis = true;
        }
      }

      if (getgynaRet.palpable_bladder_cond != null) {
        this.palpable_bladdercondition = getgynaRet.palpable_bladder_cond;
      }
      //}
    }
  }

  //send data to server
  send_data() {
    var Pedia_clinic = null;

    for (var i = 0; i < this.anemia_array.length; i++) {
      if (this.anemia == this.anemia_array[i].description) {
        this.anemia_data = this.anemia_array[i].anaemia_id;
      }
    }

    for (var i = 0; i < this.foot_details_array.length; i++) {
      if (this.foot_details == this.foot_details_array[i].description) {
        this.foot_detailstxt = this.foot_details_array[i].foot_id;
      }
    }

    for (var i = 0; i < this.skin_abnormal_array.length; i++) {
      if (this.skin_abnormal == this.skin_abnormal_array[i].description) {
        this.skin_abnormalid = this.skin_abnormal_array[i].skin_abnorm_id;
      }
    }

    if (this.gyna_fields == "Gynaecology") {
      this.ante_flg = "no";
      this.post_natflg = "no";

    } else if (this.gyna_fields == "Obstetrics") {
      if (this.send_pregnancy_array.length != 0) {
        this.preg_condtxt = this.send_pregnancy_array;
        this.preg_flg = "yes"

      } else {
        this.preg_flg = "no"
      }

      this.stariae_data = this.striae;
      this.linea_data = this.linea_nigra;
      this.fetel_movedata = this.fetel_movement;
      this.fetel_grow = this.fetel_growth;

      this.ante_flg = "yes";
      this.volume_txt = this.liquor_volume;
      this.fet_lie = this.fetes_lie;

      if (this.fetus_presentation != undefined) {
        for (var i = 0; i < this.fetus_array.length; i++) {
          if (this.fetus_presentation == this.fetus_array[i].description) {
            this.fet_present = this.fetus_array[i].fetus_present_id;
          }
        }

      } else {
        this.fet_present = null;
      }

      if (this.send_issues_array.length != 0) {
        this.issue_pregdat = this.send_issues_array;
        this.issue_flg = "yes"

      } else {
        this.issue_flg = "no"
      }

      for (var i = 0; i < this.type_emergency_array.length; i++) {
        if (this.emergency_type == this.type_emergency_array[i].description) {
          this.emergency_txt = this.type_emergency_array[i].emerg_id;
        }
      }

      this.emer_other = this.emergency_others;
      this.emerany_data = this.any_emergecny;
      this.treatement_data = this.treatement;

      if (this.onset_labour != undefined && this.onset_labour != "None") {
        this.lbour_data = this.onset_labour;
        if (this.baby_gender != undefined) {
          this.baby_gentxt = this.baby_gender;
        }

        if (this.baby_weight != undefined) {
          this.babyweigt_clinictxt = this.baby_weight + "." + this.baby_weight1 + " " + this.baby_clinic_measure;
          //this.babyweigt_clinictxt = this.baby_weight + " " + this.baby_clinic_measure;
        }

        for (var i = 0; i < this.gest_age_array.length; i++) {
          if (this.delivery_term == this.gest_age_array[i].description) {
            this.delivery_termid = this.gest_age_array[i].gestation_age_id;
          }
        }

        for (var i = 0; i < this.Mode_del_array.length; i++) {
          if (this.Mode_delivery == this.Mode_del_array[i].description) {
            this.delivery_modeid = this.Mode_del_array[i].labour_id;
          }
        }

        this.baby_viatxt = this.baby_viability;
        this.baby_abnordata = this.baby_abnormalities;
        if (this.baby_viability == "Still birth") {
          this.counse_data = this.counselling_need;
        }

        this.bloodbp_data = this.blood_pressure;
        this.heart_data = this.pulse_txt;

        for (var i = 0; i < this.anemia_array.length; i++) {
          if (this.anemia_clinic == this.anemia_array[i].description) {
            this.anemia_clinicdata = this.anemia_array[i].anaemia_id;
          }
        }

        this.mood_data = this.postmood_txt;
        this.chest_data = this.postchest_txt;

        if (this.postchest_txt == "Abnormal") {
          this.chest_condidata = this.chest_condition;
        }

        this.breast_data = this.postobs_breasts;
        if (this.postobs_breasts == "Abnormal") {
          this.breast_conditiondata = this.breast_condition;
        }

        this.purper_data = this.puerperium_pain;
        this.breastfeed_data = this.breast_feeding;
        if (this.breast_feeding == "Abnormal") {
          this.breastfeed_conditiondata = this.breastfeed_condition;
        }

        this.plan_cdata = this.puerperium_paintxt;
        if (this.puerperium_paintxt == "No") {
          this.plancond_data = this.puerperium_cond;
        }

        this.uterine_involve = this.uterine_involution;
        if (this.uterine_involution == "Abnormal") {
          this.utercond_data = this.uterine_condition;
        }

        for (var i = 0; i < this.contracept_array.length; i++) {
          if (this.type_contracept == this.contracept_array[i].description) {
            this.type_contraceptdata = this.contracept_array[i].contraceptive_id;
          }
        }

        this.bladder_data = this.palpable_bladder;
        if (this.palpable_bladder == "Abnormal") {
          this.bladdercond_data = this.palpable_bladdercondition;
        }
        this.post_natflg = "yes";
      }

      for (var i = 0; i < this.reason_array.length; i++) {
        if (this.reason_csection == this.reason_array[i].description) {
          this.reason_csect = this.reason_array[i].csec_reason_id;
        }
      }

      this.head_cirucmference = this.head_circum;
      this.delivery_loctxt = this.delivery_location;
      this.apgar_onetxt = this.apgar_onemin;
      //this.height_babytxt = this.height_baby;
      this.fundal_heightdata = this.fundal_height;
      this.apgar_five = this.apgar_fivemin;
      this.height_matchtxt = this.height_match;
      this.fetus_hearttxt = this.fetus_heart;
    }

    // this.subscription = this.bmiservice.getMessage().subscribe(message =>{
    //   if(message != undefined){
    //     if(message.flag == "vitals"){
    //       this.bp_txt = message.bp;
    //       this.heart_rate = message.pulse;
    //     }
    //   }
    // });

    if (this.BP_01 != undefined) {
      this.bp_txt = this.BP_01 + "/" + this.BP_02;
    }

    if(this.height_baby != undefined){
      var babyheight=this.height_baby+" "+this.height_measure;

    } else {
      babyheight="";
    }

    Pedia_clinic = {
      skin: this.skin_txt,
      skin_abnorm: this.skin_abnormalid,
      eye: this.eye_txt,
      mouth: this.mouth,
      nails: this.nail,
      lungs: this.lungs,
      pulse: this.heart_rate,
      clinic_bp: this.bp_txt,
      heart_rhythm: this.heart_rythm,
      legs: this.legs,
      foot: this.foot_detailstxt,
      thyroid: this.thyroid,
      thyroid_medication: this.thyroid_medication,
      anaemia: this.anemia_data,
      breasts: this.breast_txt,
      mood_swing: this.mood_swing,
      general_apearnace: this.general_appearence,
      preg_med_cond: this.preg_condtxt,
      preg_med_cond_flag: this.preg_flg,
      striae: this.stariae_data,
      linea_nigra: this.linea_nigra,
      fetal_movement: this.fetel_movedata,
      fetus_growth: this.fetel_grow,
      antnatal_flag: this.ante_flg,
      liq_vol: this.volume_txt,
      fetus_lie: this.fet_lie,
      fetus_pres: this.fet_present,
      preg_issues: this.issue_pregdat,
      preg_issues_flag: this.issue_flg,
      emerg_type: this.emergency_txt,
      emerg_others: this.emer_other,
      emergency: this.emerany_data,
      treat_type: this.treatement_data,
      labour_onset: this.lbour_data,
      baby_gender: this.baby_gentxt,
      baby_weight: this.babyweigt_clinictxt,
      del_term: this.delivery_termid,
      del_mode: this.delivery_modeid,
      reason_c_section: this.reason_csect,
      baby_head_circum: this.head_cirucmference,
      delivery_location: this.delivery_loctxt,
      apgar_score_one_min: this.apgar_onetxt,
      baby_height: babyheight,
      // baby_height_measure:this.height_measure,
      fundal_height: this.fundal_heightdata,
      apgar_score_five_min: this.apgar_five,
      fun_height_match_GA: this.height_matchtxt,
      fetus_heart_rate: this.fetus_hearttxt,
      baby_viab: this.baby_viatxt,
      baby_abnorm: this.baby_abnordata,
      counsel: this.counse_data,
      blood_pressure: this.bloodbp_data,
      heart_rate: this.heart_data,
      anaemic: this.anemia_clinicdata,
      mood: this.mood_data,
      chest: this.chest_data,
      chest_cond: this.chest_condidata,
      obs_breasts: this.breast_data,
      breast_cond: this.breast_conditiondata,
      perinium_pain: this.purper_data,
      breast_feeding: this.breastfeed_data,
      breast_feeding_cond: this.breastfeed_conditiondata,
      puerperium: this.plan_cdata,
      puerperium_cond: this.plancond_data,
      uterine_evolution: this.uterine_involve,
      uterine_evolve_cond: this.utercond_data,
      contraceptive: this.type_contraceptdata,
      palpable_bladder: this.bladder_data,
      palpable_bladder_cond: this.bladdercond_data,
      postnatal_flag: this.post_natflg,
      jaundice: this.jaundice_txt,
      edema: this.edema_txt,
      sinus: this.sinus_txt,
      pallor: this.pallor_txt,
      spo2: this.spo2,
      rs_rate: this.rs_rate,
      cvs_rate: this.cvs_rate,
      heart_sound: this.heart_sound,
      gravida:this.gravida,
      parity:this.parity,
      living: this.living,
      abortus: this.abortus,
    }
    console.log("Pedia_clinic >> "+JSON.stringify(Pedia_clinic))
    Helper_Class.setclinicexam(Pedia_clinic);
    this.disabled_field();
  }

  disabled_field() {
    if (this.skin_txt == "Normal") {
      this.skin_abnormaldis = true;

    } else {
      this.skin_abnormaldis = false;
    }

    if (this.thyroid == "Normal") {
      this.thyroid_meddisabled = true;

    } else {
      this.thyroid_meddisabled = false;
    }

    if (this.any_emergecny == "No") {
      this.typ_emerdisabled = true;
      this.treatement_disabled = true;

    } else {
      this.typ_emerdisabled = false;
      this.treatement_disabled = false;
    }

    if (this.emergency_type == "Others") {
      this.treat_otherdisabled = false;

    } else {
      this.treat_otherdisabled = true;
    }

    if (this.onset_labour == "None") {
      this.baby_viadisabled = true;
      this.postnatal_hidden = true;
      this.postnatal_hidden_history = true;
    
    } else {
      this.baby_viadisabled = false;
    }
    

    if (this.baby_viability == "Live birth") {
      this.counsiling_disabled = true;
    
    } else {
      this.counsiling_disabled = false;
    }

    if (this.postchest_txt == "Abnormal") {
      this.chest_condisabled = false;

    } else {
      this.chest_condisabled = true;
    }

    if (this.breast_feeding == "Abnormal") {
      this.breats_feedcondids = false;

    } else {
      this.breats_feedcondids = true;
    }

    if (this.postobs_breasts == "Abnormal") {
      this.breastcond_dis = false;

    } else {
      this.breastcond_dis = true;
    }

    if (this.puerperium_paintxt == "No") {
      this.puerpercond_disabled = false;

    } else {
      this.puerpercond_disabled = true;
    }

    if (this.uterine_involution == "Abnormal") {
      this.uterine_condisabled = false;

    } else {
      this.uterine_condisabled = true;
    }

    if (this.palpable_bladder == "Abnormal") {
      this.palpable_required = true;
      this.palpable_condiiondis = false;

    } else {
      this.palpable_required = false;
      this.palpable_condiiondis = true;
    }
  }

  
  apgar_oneclick() {
    this.apgar_txt = "Apgarone";
    const dialogRef = this.dialog.open(ApgarscoreComponent, {
      width: '700px',
      height: '300px',
      data:{
        Apgar: this.apgar_txt
      },
    });
  
    dialogRef.afterClosed().subscribe(result => {
      
      this.apgar_onemin = result;
      this.apgar_scoretx = this.apgar_onemin;
      this.dynamic_border = true;
    });
    this.send_data();
    // let RelationModal = this.modalCtrl.create('ApgarScorepopPage',
    //   {
    //     Apgar: this.apgar_txt
    //   }
    // )

    // RelationModal.present();
    // RelationModal.onDidDismiss(data => {
    //   this.apgar_onemin = data;
    //   this.apgar_scoretx = this.apgar_onemin;
    // })
  }

  apgar_fiveclick() {
    this.apgar_txt = "Apgarfive";
    const dialogRef = this.dialog.open(ApgarscoreComponent, {
      width: '700px',
      height: '300px',
      data:{
        Apgar: this.apgar_txt
      },
    });
  
    dialogRef.afterClosed().subscribe(result => {
      
      this.apgar_fivemin = result;
      this.apgar_scoretx_five = this.apgar_fivemin;
    });
        this.send_data();
    // let RelationModal = this.modalCtrl.create('ApgarScorepopPage', {
    //   Apgar: this.apgar_txt
    // })

    // RelationModal.present();
    // RelationModal.onDidDismiss(data => {
    //   this.apgar_fivemin = data;
    //   this.apgar_scoretx_five = this.apgar_fivemin;
    // })
  }

  //Skin abnormalities
  Get_skin_abnormalities() {
    var obj = masterCSData_Helper.getMasterSkinAbnormalities();
    if(obj != undefined){
      for (var i = 0; i < obj.present_illnesses.length; i++) {
        this.skin_abnormal_array = obj.present_illnesses;
        this.skin_abnormal = this.skin_abnormal_array[0].description;
      }
    }
  }

  //Skin abnormalities
  Get_foot() {
    var obj = masterCSData_Helper.getMasterGynoFoot();
    if(obj != undefined){
      for (var i = 0; i < obj.foot_details.length; i++) {
        this.foot_details_array = obj.foot_details;
        this.foot_details = this.foot_details_array[0].description;
      }
    }
  }

  //Get anemia
  Get_anemia() {
    var obj = masterCSData_Helper.getMasterAnemia();
    if(obj != undefined){
      for (var i = 0; i < obj.anaemic_details.length; i++) {
        this.anemia_array = obj.anaemic_details;
        this.anemia = this.anemia_array[0].description;
      }
    }
  }

  //Get fetuspresentation
  Get_fetuspresentation() {
    var obj = masterCSData_Helper.getMasterFetuspresentation();
    if(obj != undefined){
      for (var i = 0; i < obj.fetus_presentations.length; i++) {
        this.fetus_array = obj.fetus_presentations;
      }
    }
  }

  //Get Type emergency
  Get_type_emergency() {
    var obj = masterCSData_Helper.getMasterTypeEmergency();
    if(obj != undefined){
      for (var i = 0; i < obj.preg_emergencies.length; i++) {
        this.type_emergency_array = obj.preg_emergencies;
      }
    }
  }

  //Get Mode of delivery
  Get_mode_delivery() {
    var obj = masterCSData_Helper.getMasterModeofdelivery();
    if(obj != undefined){
      for (var i = 0; i < obj.labour_types.length; i++) {
        this.Mode_del_array = obj.labour_types;
      }
    }
  }

  //Mode delivery term
  Get__delivery_term() {
    var obj = masterCSData_Helper.getMasterGestationstage();
    if(obj != undefined){
      for (var i = 0; i < obj.gestation_ages.length; i++) {
        this.gest_age_array = obj.gestation_ages;
        this.delivery_term = this.gest_age_array[0];
      }
    }
  }
  
  Get_reason_csection() {//Reason for c section
    var obj = masterCSData_Helper.getMasterReasonCsection();
    if(obj != undefined){
      for (var i = 0; i < obj.csec_reasons.length; i++) {
        this.reason_array = obj.csec_reasons;
      }
    }
  }

  //Pregnancy related medical condition
  Get_pregnancy_related_condition() {
    var obj = masterCSData_Helper.getMasterPregnancyRelatedCondition();
    if(obj != undefined){
      if (obj.preg_medical_conds != null) {
        for (var i = 0; i < obj.preg_medical_conds.length; i++) {
          this.preg_related_condition_array.push({
            type: 'checkbox',
            value: obj.preg_medical_conds[i].preg_med_cond_id,
            label: obj.preg_medical_conds[i].description,
            checked: false
          });
        }
      }
    }
  }

  pregnancy_relatedmedpop() {
    for (var j = 0; j < this.preg_related_condition_array.length; j++) {
      this.preg_related_condition_array[j].checked = false;
    }

    if (this.send_pregnancy_array != undefined && this.send_pregnancy_array.length != 0) {
      for (var i = 0; i < this.send_pregnancy_array.length; i++) {
        for (var j = 0; j < this.preg_related_condition_array.length; j++) {
          if (this.send_pregnancy_array[i] == this.preg_related_condition_array[j].value) {
            this.preg_related_condition_array[j].checked = true;
          }
        }
      }
    }
  }

  pregnancy_change(selected) {
    this.pregnacy_condition = "";

    function StringBuilder(this:any,value) {
      this.strings = new Array("");
      this.append(value);
    }

    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }

    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }

    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }

    var selected_data = new StringBuilder("");
    this.send_pregnancy_array = selected;

    if (this.send_pregnancy_array.length != 0) {
      var n = 0;
      for (var i = 0; i < this.preg_related_condition_array.length; i++) {
        for (var j = 0; j < this.send_pregnancy_array.length; j++) {
          if (this.send_pregnancy_array[j] == this.preg_related_condition_array[i].value) {
            n = n + 1;
            if (n > 1) {
              selected_data.append(",");
              selected_data.append("\n");
            }
            
            selected_data.append(this.preg_related_condition_array[i].label);
          }
        }
      }
      this.pregnacy_condition = selected_data.toString();
    }
  }

  //Pregnancy related medical condition
  Get_pregnancy_medical_condition() {
    var obj = masterCSData_Helper.getMasterPregnancyMedicalCondition();
    if(obj != undefined){
      if (obj.preg_issues != null) {
        for (var i = 0; i < obj.preg_issues.length; i++) {
          this.preg_related_issues_array.push({
            type: 'checkbox',
            // multiple:true,
            value: obj.preg_issues[i].preg_issue_id,
            label: obj.preg_issues[i].description,
            checked: false
          });
        }
      }
    }
  }

  issue_change(selected) {
    this.pregnancy_issue = "";
    
    function StringBuilder(this:any,value) {
      this.strings = new Array("");
      this.append(value);
    }

    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }

    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }

    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }

    var selected_data = new StringBuilder("");
    this.send_issues_array = selected;

    if (this.send_issues_array.length != 0) {
      var n = 0;

      for (var j = 0; j < this.send_issues_array.length; j++) {
        for (var i = 0; i < this.preg_related_issues_array.length; i++) {
          if (this.send_issues_array[j] == this.preg_related_issues_array[i].value) {
            n = n + 1;
            if (n > 1) {
              selected_data.append(",");
              selected_data.append("\n");
            }
            selected_data.append(this.preg_related_issues_array[i].label);
          }
        }
      }
      this.pregnancy_issue = selected_data.toString();
    }
  }

  pregnancy_issuepop() {
    for (var j = 0; j < this.preg_related_issues_array.length; j++) {
      this.preg_related_issues_array[j].checked = false;
    }

    if (this.send_issues_array != undefined && this.send_issues_array.length != 0) {
      for (var i = 0; i < this.send_issues_array.length; i++) {
        for (var j = 0; j < this.preg_related_issues_array.length; j++) {
          if (this.send_issues_array[i] == this.preg_related_issues_array[j].value) {
            this.preg_related_issues_array[j].checked = true;
          }
        }
      }
    }

    // let alert_data = this.alert_ctrl.create({
    //   title: 'Medical history',
    //   cssClass: 'alertCustomCss',
    //   inputs: this.preg_related_issues_array,
    //   buttons: [
    //     {
    //       // text: 'ok',
    //       cssClass: 'alert_done_btn',
    //       handler: data => {
    //         
    //         this.issue_change(data);
    //       }
    //     },
    //     {
    //       //text: 'cancel',
    //       cssClass: 'alert_cancel_btn',
    //       role: 'cancel',
    //       handler: data => {
    //         

    //       }
    //     }
    //   ],
    // });
    // alert_data.present();
  }

  //Get types of contraceptives
  Get_type_contraceptive() {
    var obj = masterCSData_Helper.getMasterTypeContraceptive();
    if(obj != undefined){
      for (var i = 0; i < obj.contraceptives.length; i++) {
        this.contracept_array = obj.contraceptives;
      }
      this.type_contracept = this.contracept_array[0].description;
    }
    this.clinical_retrieval();
  }

  //Alchol history
  systemic_history() {
    this.clinicalhist = "Clinical_hist";
    const dialogRef = this.dialog.open(DiabeticpopuphistoryComponent, {
      width: '700px',
      height: '300px',
      data:{
          Diab_case_hist_id: this.gyna_case_hist_id,
      Life_style_id: this.life_style_id,
      History: this.clinicalhist,
      },
    });
  
    dialogRef.afterClosed().subscribe(result => {
      
      //this.dis_txt = result;
    });
    // let RelationModal = this.modalCtrl.create('DiabeticPopupHistoryPage', {
    //   Diab_case_hist_id: this.gyna_case_hist_id,
    //   Life_style_id: this.life_style_id,
    //   History: this.clinicalhist
    // });

    // RelationModal.present();
  }

  //antenal history
  antenatal_historyclick() {
    this.antenatal = "ante_natal";
    const dialogRef = this.dialog.open(DiabeticpopuphistoryComponent, {
      width: '700px',
      height: '300px',
      data:{
          Diab_case_hist_id: this.gyna_case_hist_id,
      Life_style_id: this.life_style_id,
      History: this.antenatal,
      delivery_arry: this.gest_age_array
      },
    });
  
    dialogRef.afterClosed().subscribe(result => {
      
      //this.dis_txt = result;
    });
    Doc_Helper.setDelivery_array(this.gest_age_array);
    // let RelationModal = this.modalCtrl.create('DiabeticPopupHistoryPage', {
    //   Diab_case_hist_id: this.gyna_case_hist_id,
    //   Life_style_id: this.life_style_id,
    //   History: this.antenatal,
    //   delivery_arry: this.gest_age_array,
    // });

    // RelationModal.present();
  }

  //post natal history
  postnatal_history() {
    this.postnatal = "post_natal";
    const dialogRef = this.dialog.open(DiabeticpopuphistoryComponent, {
      width: '700px',
      height: '300px',
      data:{
          Diab_case_hist_id: this.gyna_case_hist_id,
      Life_style_id: this.life_style_id,
      History: this.postnatal,
      anemic_array: this.anemia_array,
      },
    });
  
    dialogRef.afterClosed().subscribe(result => {
      
      //this.dis_txt = result;
    });
    Doc_Helper.setAnemic_array(this.anemia_array);
    // let RelationModal = this.modalCtrl.create('DiabeticPopupHistoryPage', {
    //   Diab_case_hist_id: this.gyna_case_hist_id,
    //   Life_style_id: this.life_style_id,
    //   History: this.postnatal,
    //   anemic_array: this.anemia_array,
    // });

    // RelationModal.present();
  }

  //bmi and bmr
  calories_data() {
    if (this.height_txt == undefined || this.height_txt == "" || this.weight_txt == undefined || this.weight_txt == "") {
      this.txt_bmr = "";
      this.txt_bmi = "";

    } else {
      if (this.height_txt != undefined) {
        if (this.height_measure == "cms") {
          this.height_txt = this.height_txt.replace(/[^0-9]/g, '');

        } else {
          this.height_txt = this.height_txt.replace('/[^-0-9\.]/g', '');

          if (this.height_txt.indexOf('.') !== -1) {
            if (this.height_txt.split('.').length > 2) {
              this.height_txt = this.height_txt.slice(0, -1);
            }
          }
        }

        if (this.height_measure == "cms") {
          if (parseFloat(this.height_txt) > 300) {
            this.height_txt = this.height_txt.slice(0, -1);
          }

        } else {
          if (parseFloat(this.height_txt) > 100) {
            this.height_txt = this.height_txt.slice(0, -1);
          }
        }
      }
      this.age_txt = Helper_Class.getgynapersonal().age;

      if (this.weight_measure == "kgs" && this.height_measure == "inch") {
        var pound = this.weight_txt * 2.20462;
        var Height_txt = parseFloat(this.height_txt)
        var dat = pound / (Height_txt * Height_txt);
        var to = dat * 703;
        this.txt_bmi = Math.round(to);
        var get_bmr = (10 * this.weight_txt) + (6.25 * (this.height_txt * 2.54)) - (5 * this.age_txt) - 161;
        this.txt_bmr = Math.round(get_bmr);

      } else if (this.weight_measure == "kgs" && this.height_measure == "cms") {
        var meter = parseFloat(this.height_txt) / 100.00;
        var to = parseFloat(this.weight_txt) / (meter * meter);
        this.txt_bmi = Math.round(to);

        var get_bmr = (10 * this.weight_txt) + (6.25 * this.height_txt) - (5 * this.age_txt) - 161;
        this.txt_bmr = Math.round(get_bmr);

      } else if (this.weight_measure == "lbs" && this.height_measure == "inch") {
        Height_txt = this.height_txt;
        var dat = this.weight_txt / (Height_txt * Height_txt);
        var to = dat * 703;
        this.txt_bmi = Math.round(to);
        var get_bmr = (10 * (this.weight_txt * 0.453592)) + (6.25 * (this.height_txt * 2.54)) - (5 * this.age_txt) - 161;
        this.txt_bmr = Math.round(get_bmr);

      } else if (this.weight_measure == "lbs" && this.height_measure == "cms") {
        Height_txt = this.height_txt;
        var inch = Height_txt * 0.393701;
        var dat = this.weight_txt / (inch * inch);
        var to = dat * 703;
        this.txt_bmi = Math.round(to);
        var get_bmr = (10 * (this.weight_txt * 0.453592)) + (6.25 * this.height_txt) - (5 * this.age_txt) - 161;
        this.txt_bmr = Math.round(get_bmr);

      }

      var SelectedText1 = this.exer_run;
      if (SelectedText1 == "Light") {
        var get_txt = this.txt_bmr * 1.375;
        this.cal_txt = Math.round(get_txt);

      } else if (SelectedText1 == "Moderate") {
        var get_txt = this.txt_bmr * 1.55;
        this.cal_txt = Math.round(get_txt);

      } else if (SelectedText1 == "Heavy") {
        var get_txt = this.txt_bmr * 1.725;
        this.cal_txt = Math.round(get_txt);

      } else if (SelectedText1 == "Very Heavy") {
        var get_txt = this.txt_bmr * 1.9;
        this.cal_txt = Math.round(get_txt);

      } else {
        var get_txt = parseFloat(this.txt_bmr) * 1.375;
        this.cal_txt = Math.round(get_txt);
      }
    }
  }

  changetab(e){
    if(e == "gen_phy"){
      this.gen_phy_tab = true;
      this.antenatal_tab = false;
      this.postnatal_tab = false;
    }
    else if(e == "antenatal"){
      this.gen_phy_tab = false;
      this.antenatal_tab = true;
      this.postnatal_tab = false;
    }else{
      this.gen_phy_tab = false;
      this.antenatal_tab = false;
      this.postnatal_tab = true;
    }
  }

}
