<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 style="color:black" class="m-0" class="mainHeadingStyle">Bill list</h5>
          </div>
          <div class="headerButtons">
            <a (click)="create() "><img src="../../../assets/ui_icons/Add_icon.svg" class="saveimgbtn_inpatinfo"></a>
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row">
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" [hidden]="hospitalFlag">
            <mat-label class="matlabel">
              <img src='../../../assets/ui_icons/Bill_list/Hospital_icon.svg' width="20px" height="auto" />&nbsp;
              Hospital
              <select [(ngModel)]="hptlClinicId" class="ipcss">
                <option>All</option>
                <option *ngFor="let hosp of hospitalList" value={{hosp.hptl_clinic_id}}>{{hosp.hptl_nam}}</option>
              </select>
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" style="width: fit-content;">
            <mat-label class="matlabel"><br></mat-label>
            <div class="full-input">
              <mat-label class="matlabel">From : &nbsp;</mat-label>
              <input type="date" class="pointer" (change)="selectFromDate(fromDate)" [(ngModel)]="fromDate" #matInput
                max="{{currentMaxDate}}" style="border:none;outline:none; border:none;    font-size: 12px;
                       font-family: Arial;" >
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 padding_right" style="width: fit-content;">
            <mat-label class="matlabel"><br></mat-label>
            <div class="full-input">
              <mat-label class="matlabel">To : &nbsp;</mat-label>
              <input type="date" class="pointer" id="appt_date" (change)="selectToDate(toDate)" [(ngModel)]="toDate"
                max="{{currentMaxDate}}"  matInput style="border:none;outline:none; border:none;    font-size: 12px;
                      font-family: Arial;">
            </div>
          </div>
          <div class="col-1 d-flex justify-content-center my-auto">
            <mat-label class="matlabel"><br></mat-label>
            <a (click)="filter()">
              <img src="../../../assets/ui_icons/search_icon_new.svg" width='18px' height="auto" class="topvalue" /></a>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-12 col-md-12 col-xl-6 col-lg-6">
            <mat-card class="tot_revenue">
              <mat-card-content>
                <div class="container" style="padding-left: 5px;padding-right: 5px;">
                  <div class="row">
                    <div class="col-2"></div>
                    <div class="col-7">
                      <mat-label class="matlabel" style="font-size: 12px !important;"><strong>Total revenue for the
                          selected period: र &nbsp;</strong>
                      </mat-label>
                    </div>
                    <div class="col-3">
                      <mat-label class="matlabel"><b>{{totalAmount}}</b></mat-label>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-xl-6 col-lg-6">
            <mat-card class="tot_bal">
              <mat-card-content>
                <div class="container" style="padding-left: 5px;padding-right: 5px;">
                  <div class="row">
                    <div class="col-2"></div>
                    <div class="col-7">
                      <mat-label class="matlabel" style="font-size: 12px !important;"><strong>Total balance for the
                          selected period: र &nbsp;</strong>
                      </mat-label>
                    </div>
                    <div class="col-3">
                      <mat-label class="matlabel"><b>{{totalBalance}}</b></mat-label>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
        <div class="row" style="margin-top: 15px;">
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <p class="nodata" *ngIf="billingList.length == 0">No bill(s) found</p>
            <table *ngIf="billingList.length" mdbTable datatable [dtOptions]="dtOptions"
              class="table table-nowrap table-sm dataTable billlisttable">
              <thead>
                <tr>
                  <th class="delete">Bill no</th>
                  <th>Date</th>
                  <th>Client name</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let person of billingList; let i = index" (click)="viewBill(person.bill_id)">
                  <td style="font-size: 12px;text-align: center;">{{person.bill_id}}
                  </td>
                  <td style="font-size: 12px;text-align: center;">{{person.date}}
                  </td>
                  <td style="font-size: 12px;text-align: left !important;">
                    {{person.salutation}} {{person.client_name}}</td>
                  <td style="font-size: 12px;text-align: right;">{{person.amount}}
                  </td>
                </tr>
              </tbody>
            </table>
            <br><br><br>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>