import { Component, OnInit } from '@angular/core';
import { Helper_Class } from '../../helper_class';
import { Router, ActivatedRoute } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import * as $ from 'jquery/dist/jquery.min.js';
import { Neprology_graph } from '../../../assets/js/Neprology_Graph';
import { Subscription } from 'rxjs';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';
import { DietCasesheetService } from '../diet-casesheet/diet-casesheet.service';
import { Diet_Helper } from '../Diet_Helper';
import { Master_Helper } from 'src/app/Doctor_module/Master_Helper';

@Component({
  selector: 'app-diet-case-summary',
  templateUrl: './diet-case-summary.component.html',
  styleUrls: ['./diet-case-summary.component.scss']
})
export class DietCaseSummaryComponent implements OnInit {
  public dischargeSummaryFlag = "min";
  public lineChartType: string = 'line';
  public isMobile: boolean;
  public mobile: string;
  //gyno_case_summary
  public visitPurpose;
  public mainConcern;
  public disease;
  public presentIllness;
  public menstrualPeriods;
  public icdDesc;
  public gynaList = [];
  //flag values
  public gynecologyFlag: boolean = false;
  public pediatricsFlag: boolean = false;
  public nephrologyFlag: boolean = false;
  public othersFlag: boolean = false;
  public diabetesFlag: boolean = false;
  //diab_case_summary
  public clientId;
  public relId;
  public subId;
  public specilization;
  public diseaseType: string;
  public caseDietaryHab: string;
  public caseConcern: string;
  public caseCardio: string;
  public caseCardioFlag: boolean = false;
  public caseMobility: string;
  public caseMobilityFlag: boolean = false;
  public caseNephro: string;
  public caseNephroFlag: boolean = false;
  public caseRetino: string;
  public caseRetinoFlag: boolean = false;
  public complicationsFlag: boolean;
  public medPresListForDiab = [];
  public medPresListForPedia = [];
  public timeDuration: string;
  public medPresForDiabFlag: boolean = false;
  public diagTempTestListForDiab = [];
  public diagTestListForDiab = [];
  public diagTestFlag: boolean = true;
  public diagnosisForDiabFlag: boolean;
  public bloodName: string;
  public scanName: string;
  public urineName: string;
  public faecesName: string;
  public xrayName: string;
  public ultraName: string;
  public icdCode: string;
  public caseRight: string;
  public caseRightFlag: boolean = false;
  public footAssmtFlag: boolean = false;
  public caseLeft: string;
  public caseLeftFlag: boolean = false;
  public docAppId;
  //nephro_case_summary
  public medPresListForNeph = [];
  public dietHabits: string;
  public nephDisease: string;
  public nephIcd: string;
  public nephIcdDesc: string;
  public nephTreat: string;
  public nephDysType: string;
  public nephDysFreq: string;
  public nephSkin: string;
  public nephHear: string;
  public nephAbdom: string;
  public nephRetino: string;
  public nephCardioVas: string;
  public nephPulm: string;
  public nephCauseOfDis: string;
  diabGraphdivFlag: boolean = true;
  public medPresForNephFlag: boolean = false;
  //pediatrics_case_summary
  public pediaChildName;
  public pediaMotherAge;
  public pediaNoOfPreg;
  public pediaDeliveryType;
  public pediaGestationAge;
  public pediaComplication;
  public pediaLivingCondition;
  public pediaFeedingHabits;
  public pediaAllergies;
  public pediaDiseases;
  public pediaIcd;
  public medPresForPediaFlag: boolean = false;
  //General_case_summary
  public fName: string;
  public nameFlag: boolean;
  public age: string;
  public ageFlag: boolean;
  public gender: string;
  public genderFlag: boolean;
  public occupation: string;
  public occupationFlag: boolean;
  public stressFactor: string;
  public stressFactorFlag: boolean;
  public physicalAct: string;
  public physicalActFlag: boolean;
  public sgaScore: string;
  public sgaScoreFlag: boolean;
  public height: string;
  public heightFlag: boolean;
  public heightMeasure: string;
  public weight: string;
  public weightFlag: boolean;
  public weightMeasure: string;
  public bmi: string;
  public bmiFlag: boolean;
  public medHist: string;
  public medHistFlag: boolean;
  public alcQty: string;
  public alcQtyFlag: boolean;
  public smkFreq: string;
  public smkFreqFlag: boolean;
  public gutkFreq: string;
  public gutkFreqFlag: boolean;
  public exerDuration: string;
  public exerDurationFlag: boolean;
  public alcFreq: string;
  public alcFreqFlag: boolean;
  public habtiualInfoFlag: boolean;
  public userInfo;
  public patientList;
  public userId;
  subscription: Subscription;
  public currentDate;

  constructor(private http: Http, private routes: ActivatedRoute, private router: Router, public toastr: ToastrService,
    public messageservice: DietCasesheetService) {
  }

  ngOnInit(): void {
    this.subscription = this.messageservice.getCasesheetMessage().subscribe(message => {
      this.dischargeSummaryFlag = message;
    });
    this.userInfo = Helper_Class.getInfo();
    this.patientList = Diet_Helper.getClient_Info();
    this.userId = this.userInfo.user_id;
    this.getCurrentDate();
    this.mobile = this.patientList.mobile;
    this.getCaseSummaryDetails();

    if (this.patientList.Age_data != null) {
      if (this.patientList.Age_data != "0") {
        this.age = this.patientList.Age_data;
      } else {
        this.age = "<1";
      }
    } else {
      this.age = "";
    }
    Helper_Class.setClntGender(this.patientList.Gender_data);
    //diab_case_summary
    this.clientId = this.patientList.Client_id;
    this.relId = this.patientList.rel_id;
    this.subId = this.patientList.sub_id;
    //this.specilization_txt = this.patient_list.spl;
    this.docAppId = this.patientList.app_id;
  }

  getCurrentDate() {
    var obj = Master_Helper.getMasterCurrentDate();
    if (obj.current_date != null) {
      this.currentDate = obj.current_date;
    }
  }

  getCaseSummaryDetails() {
    var getcasesumret = Helper_Class.getcasesumret();
    function StringBuilder(this: any, value) {
      this.strings = new Array("");
      this.append(value);
    }
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }
    var visit_purposetxt = new StringBuilder("");
    //general_
    if (getcasesumret != undefined && getcasesumret.first_name != null) {
      this.othersFlag = true;
      this.nameFlag = true;
      if (getcasesumret.middle_name != null && encrypt_decript.Decript(getcasesumret.middle_name) != undefined) {
        this.fName = encrypt_decript.Decript(getcasesumret.first_name) + " " + encrypt_decript.Decript(getcasesumret.middle_name) + " " + encrypt_decript.Decript(getcasesumret.last_name);
      }
      else {
        this.fName = encrypt_decript.Decript(getcasesumret.first_name) + " " + encrypt_decript.Decript(getcasesumret.last_name);
      }
      this.gender = encrypt_decript.Decript(getcasesumret.gender);
      if (this.gender.length != 0) {
        this.genderFlag = true;
      }
      else {
        this.genderFlag = false;
      }
      Helper_Class.setClntGender(this.gender);
      if (getcasesumret.age != undefined) {
        if (getcasesumret.age == "0") {
          this.age = "<1";
        } else {
          this.age = getcasesumret.age;
          this.ageFlag = true;
        }
        Helper_Class.setClntAge(this.age);
      }
      // else if(this.patient_list.Age_data != 'undefined' ){
      //   if(this.patient_list.Age_data !="0"){
      //     Helper_class.setClntAge(this.patient_list.Age_data);
      //     this.age_txt=this.patient_list.Age_data;
      //   } else {
      //     Helper_class.setClntAge("<1");
      //   }
      // } else {
      //   Helper_class.setClntAge("");
      //   this.age_txt="";
      // }
      this.occupation = encrypt_decript.Decript(getcasesumret.occupation);
      if (this.occupation.length != 0) {
        this.occupationFlag = true;
      }
      else {
        this.occupationFlag = false;
      }
      if (getcasesumret.stress_factor != null) {
        this.stressFactor = getcasesumret.stress_factor;
        this.stressFactorFlag = true;
      }
      else {
        this.stressFactorFlag = false;
      }
      if (getcasesumret.physical_activity != null) {
        this.physicalAct = getcasesumret.physical_activity;
        this.physicalActFlag = true;
      }
      else {
        this.physicalActFlag = false;
      }
      if (getcasesumret.sga_score != null) {
        this.sgaScore = getcasesumret.sga_score;
        this.sgaScoreFlag = true;
      }
      else {
        this.sgaScoreFlag = false;
      }
      if (getcasesumret.height != null) {
        this.height = getcasesumret.height;
        this.heightFlag = true;
      }
      else {
        this.heightFlag = false;
      }
      if (getcasesumret.height_measure != null) {
        this.heightMeasure = getcasesumret.height_measure;
      }
      if (getcasesumret.weight != null) {
        this.weight = getcasesumret.weight;
        this.weightFlag = true;
      }
      else {
        this.weightFlag = false;
      }
      if (getcasesumret.weight_measure != null) {
        this.weightMeasure = getcasesumret.weight_measure;
        if (getcasesumret.weight_measure == "kgs" && getcasesumret.height_measure == "inch") {
          var pound = getcasesumret.weight * 2.20462;
          var Height_txt = parseFloat(getcasesumret.height)
          var dat = pound / (Height_txt * Height_txt);
          var to = dat * 703;
          this.bmi = Math.round(to).toString();
        } else if (getcasesumret.weight_measure == "kgs" && getcasesumret.height_measure == "cms") {
          var meter = parseFloat(getcasesumret.height) / 100.00;
          var to = parseFloat(getcasesumret.weight) / (meter * meter);
          this.bmi = Math.round(to).toString();
        } else if (getcasesumret.weight_measure == "lbs" && getcasesumret.height_measure == "inch") {
          var Height_txt1 = getcasesumret.height;
          var dat = getcasesumret.weight / (Height_txt1 * Height_txt1);
          var to = dat * 703;
          this.bmi = Math.round(to).toString();
        } else if (getcasesumret.weight_measure == "lbs" && getcasesumret.height_measure == "cms") {
          var Height_txt1 = getcasesumret.height;
          var inch = Height_txt1 * 0.393701;
          var dat = getcasesumret.weight / (inch * inch);
          var to = dat * 703;
          this.bmi = Math.round(to).toString();
        }
      }
      var med_hist = new StringBuilder("");
      if (getcasesumret.medical_history != null) {
        var n = 0;
        for (var i = 0; i < getcasesumret.medical_history.length; i++) {
          n = n + 1;
          if (n > 1) {
            if (getcasesumret.medical_history[i] != "") {
              med_hist.append(",");
              med_hist.append("\n");
            }
          }
          med_hist.append(getcasesumret.medical_history[i]);
        }
        this.medHist = med_hist.toString();
        if (this.medHist.length != 0) {
          this.medHistFlag = true;
        }
        else {
          this.medHistFlag = false;
        }
      }
      if (getcasesumret.alc_frequency != null) {
        this.alcFreq = getcasesumret.alc_frequency;
        this.alcFreqFlag = true;
      }
      else {
        this.alcFreqFlag = false;
      }
      if (getcasesumret.alc_qty_desc != null) {
        this.alcQty = getcasesumret.alc_qty_desc;
        this.alcQtyFlag = true;
      }
      else {
        this.alcQtyFlag = false;
      }
      if (getcasesumret.smoke_frequency != null) {
        this.smkFreq = getcasesumret.smoke_frequency;
        this.smkFreqFlag = true;
      }
      else {
        this.smkFreqFlag = false;
      }
      if (getcasesumret.gutka_frequency != null) {
        this.gutkFreq = getcasesumret.gutka_frequency;
        this.gutkFreqFlag = true;
      }
      else {
        this.gutkFreqFlag = false;
      }
      // exe duration
      if (getcasesumret.exer_duration != null) {
        this.exerDuration = getcasesumret.exer_duration;
        this.exerDurationFlag = true;
      }
      else {
        this.exerDurationFlag = false;
      }
      if (this.alcFreqFlag == true || this.alcQtyFlag == true || this.smkFreqFlag == true || this.gutkFreqFlag == true || this.exerDurationFlag == true) {
        this.habtiualInfoFlag = true;
      }
      else {
        this.habtiualInfoFlag = false;
      }
    } else {
      if (Helper_Class.Get_client_view_app_data().middle_name != undefined) {
        this.fName = encrypt_decript.Decript(Helper_Class.Get_client_view_app_data().first_name) + " " + encrypt_decript.Decript(Helper_Class.Get_client_view_app_data().middle_name) + " " + encrypt_decript.Decript(Helper_Class.Get_client_view_app_data().last_name);
      }else {
        this.fName = encrypt_decript.Decript(Helper_Class.Get_client_view_app_data().first_name) + " " + encrypt_decript.Decript(Helper_Class.Get_client_view_app_data().last_name);
      }
      this.gender = encrypt_decript.Decript(Helper_Class.Get_client_view_app_data().gender);
      this.age = Helper_Class.getClntAge();
    }
    //gyno
    if (getcasesumret != undefined && getcasesumret.gynaecology != undefined) {
      this.gynecologyFlag = true;
      if (getcasesumret.gynaecology.visit_purpose != null) {
        var n = 0;
        for (var i = 0; i < getcasesumret.gynaecology.visit_purpose.length; i++) {
          n = n + 1;
          if (n > 1) {
            if (getcasesumret.gynaecology.visit_purpose[i] != "") {
              visit_purposetxt.append(",");
              visit_purposetxt.append("\n");
            }
          }
          visit_purposetxt.append(getcasesumret.gynaecology.visit_purpose[i]);
        }
        this.visitPurpose = visit_purposetxt.toString();
      }
      var main_concentxt = new StringBuilder("");
      if (getcasesumret.gynaecology.main_concern != null) {
        var n = 0;
        for (var i = 0; i < getcasesumret.gynaecology.main_concern.length; i++) {
          n = n + 1;
          if (n > 1) {
            if (getcasesumret.gynaecology.main_concern[i] != "") {
              main_concentxt.append(",");
              main_concentxt.append("\n");
            }
          }
          main_concentxt.append(getcasesumret.gynaecology.main_concern[i]);
        }
        this.mainConcern = main_concentxt.toString();
      }
      var dis_data = new StringBuilder("");
      if (getcasesumret.gynaecology.disease != null) {
        var n = 0;
        for (var i = 0; i < getcasesumret.gynaecology.disease.length; i++) {
          n = n + 1;
          if (n > 1) {
            if (getcasesumret.gynaecology.disease[i] != "") {
              dis_data.append(",");
              dis_data.append("\n");
            }
          }
          dis_data.append(getcasesumret.gynaecology.disease[i]);
        }
        this.disease = dis_data.toString();
      }
      var present_data = new StringBuilder("");
      if (getcasesumret.gynaecology.present_illness != null) {
        var n = 0;
        for (var i = 0; i < getcasesumret.gynaecology.present_illness.length; i++) {
          n = n + 1;
          if (n > 1) {
            if (getcasesumret.gynaecology.present_illness[i] != "" && getcasesumret.gynaecology.present_illness[i] != null) {
              present_data.append(",");
              present_data.append("\n");
            }
          }
          present_data.append(getcasesumret.gynaecology.present_illness[i]);
        }
        this.presentIllness = present_data.toString();
      }
      var periods_data = new StringBuilder("");
      if (getcasesumret.gynaecology.periods != null) {
        var n = 0;
        for (var i = 0; i < getcasesumret.gynaecology.periods.length; i++) {
          n = n + 1;
          if (n > 1) {
            if (getcasesumret.gynaecology.periods[i] != "") {
              periods_data.append(",");
              periods_data.append("\n");
            }
          }
          periods_data.append(getcasesumret.gynaecology.periods[i]);
        }
        this.menstrualPeriods = periods_data.toString();
      }
      if (getcasesumret.gynaecology.icd10 != null) {
        for (var i = 0; i < getcasesumret.gynaecology.icd10.length; i++) {
          if (getcasesumret.gynaecology.icd10[i].icd_code != undefined
            && getcasesumret.gynaecology.icd10[i].icd_desc != undefined) {
            this.icdDesc = getcasesumret.gynaecology.icd10[i].icd_code + " - " + getcasesumret.gynaecology.icd10[i].icd_desc;
          }
        }
      }
      //surgieries
      if (getcasesumret.gynaecology.Surgeries != null) {
        this.gynaList = [];
        for (var i = 0; i < getcasesumret.gynaecology["Surgeries"].length; i++) {
          if (getcasesumret.gynaecology["Surgeries"][i]["D&C"] != null) {
            this.gynaList.push({
              Surgeries: "D&C",
              Surgeries_date: getcasesumret.gynaecology["Surgeries"][i]["D&C"],
            })
          }
          if (getcasesumret.gynaecology["Surgeries"][i]["Hysteroscopy"] != null) {
            this.gynaList.push({
              Surgeries: "Hysteroscopy",
              Surgeries_date: getcasesumret.gynaecology["Surgeries"][i]["Hysteroscopy"],
            })
          }
          if (getcasesumret.gynaecology["Surgeries"][i]["Ovarian_Surgery"] != null) {
            this.gynaList.push({
              Surgeries: "Ovarian surgery",
              Surgeries_date: getcasesumret.gynaecology["Surgeries"][i]["Ovarian_Surgery"],
            })
          }
          if (getcasesumret.gynaecology["Surgeries"][i]["infertility_surgery"] != null) {
            this.gynaList.push({
              Surgeries: "Infertility surgery",
              Surgeries_date: getcasesumret.gynaecology["Surgeries"][i]["infertility_surgery"],
            })
          }
          if (getcasesumret.gynaecology["Surgeries"][i]["right_cyst_removed"] != null) {
            this.gynaList.push({
              Surgeries: "Right cyst removed",
              Surgeries_date: getcasesumret.gynaecology["Surgeries"][i]["right_cyst_removed"],
            })
          }
          if (getcasesumret.gynaecology["Surgeries"][i]["left_cyst_removed"] != null) {
            this.gynaList.push({
              Surgeries: "Left cyst removed",
              Surgeries_date: getcasesumret.gynaecology["Surgeries"][i]["left_cyst_removed"],
            })
          }
          if (getcasesumret.gynaecology["Surgeries"][i]["right_ovary_removed"] != null) {
            this.gynaList.push({
              Surgeries: "Right ovary removed",
              Surgeries_date: getcasesumret.gynaecology["Surgeries"][i]["right_ovary_removed"],
            })
          }
          if (getcasesumret.gynaecology["Surgeries"][i]["left_ovary_removed"] != null) {
            this.gynaList.push({
              Surgeries: "Left ovary removed",
              Surgeries_date: getcasesumret.gynaecology["Surgeries"][i]["left_ovary_removed"],
            })
          }
          if (getcasesumret.gynaecology["Surgeries"][i]["Tuboplasty"] != null) {
            this.gynaList.push({
              Surgeries: "Tuboplasty",
              Surgeries_date: getcasesumret.gynaecology["Surgeries"][i]["Tuboplasty"],
            })
          }
          if (getcasesumret.gynaecology["Surgeries"][i]["tubal_ligation"] != null) {
            this.gynaList.push({
              Surgeries: "Tubal ligation",
              Surgeries_date: getcasesumret.gynaecology["Surgeries"][i]["tubal_ligation"],
            })
          }
          if (getcasesumret.gynaecology["Surgeries"][i]["hysterectomy_abdominal"] != null) {
            this.gynaList.push({
              Surgeries: "Hysterectomy - abdominal",
              Surgeries_date: getcasesumret.gynaecology["Surgeries"][i]["hysterectomy_abdominal"],
            })
          }
          if (getcasesumret.gynaecology["Surgeries"][i]["hysterectomy_vaginal"] != null) {
            this.gynaList.push({
              Surgeries: "Hysterectomy - vaginal",
              Surgeries_date: getcasesumret.gynaecology["Surgeries"][i]["hysterectomy_vaginal"],
            })
          }
          if (getcasesumret.gynaecology["Surgeries"][i]["bladder_repair"] != null) {
            this.gynaList.push({
              Surgeries: "Bladder repair",
              Surgeries_date: getcasesumret.gynaecology["Surgeries"][i]["bladder_repair"],
            })
          }
          if (getcasesumret.gynaecology["Surgeries"][i]["myomectomy"] != null) {
            this.gynaList.push({
              Surgeries: "Myomectomy",
              Surgeries_date: getcasesumret.gynaecology["Surgeries"][i]["myomectomy"],
            })
          }
          if (getcasesumret.gynaecology["Surgeries"][i]["others"] != null) {
            this.gynaList.push({
              Surgeries: "others",
              Surgeries_date: getcasesumret.gynaecology["Surgeries"][i]["others"],
            })
          }
        }
      }
    }
    //diab
    if (getcasesumret != undefined && getcasesumret.diabetes != undefined) {
      this.diabetesFlag = true;
      if (getcasesumret.diabetes.disease_type != null) {
        this.diseaseType = getcasesumret.diabetes.disease_type;
      }
      if (getcasesumret.diabetes.foot_assessment != null) {
        var footright = new StringBuilder("");
        var n = 0;
        for (var i = 0; i < getcasesumret.diabetes.foot_assessment.length; i++) {
          n = n + 1;
          if (n > 1) {
            if (getcasesumret.diabetes.foot_assessment[i].foot_type == "right") {
              if (getcasesumret.diabetes.foot_assessment[i].risk_level != "") {
                footright.append(",");
                footright.append("\n");
              }
            }
          }
          if (getcasesumret.diabetes.foot_assessment[i].foot_type == "right") {
            footright.append(getcasesumret.diabetes.foot_assessment[i].risk_level);
          }
        }
        this.caseRight = footright.toString();
      }
      if (this.caseRight.length != 0) {
        this.caseRightFlag = true;
      }
      else {
        this.caseRightFlag = false;
      }
      if (getcasesumret.diabetes.foot_assessment != null) {
        var footleft = new StringBuilder("");
        var n = 0;
        for (var i = 0; i < getcasesumret.diabetes.foot_assessment.length; i++) {
          if (getcasesumret.diabetes.foot_assessment[i].foot_type == "left") {
            n = n + 1;
          }
          if (n > 1) {
            if (getcasesumret.diabetes.foot_assessment[i].foot_type == "left") {
              if (getcasesumret.diabetes.foot_assessment[i].risk_level != "") {
                footleft.append(",");
                footleft.append("\n");
              }
            }
          }
          if (getcasesumret.diabetes.foot_assessment[i].foot_type == "left") {
            footleft.append(getcasesumret.diabetes.foot_assessment[i].risk_level);
          }
        }
        this.caseLeft = footleft.toString();
      }
      if (this.caseLeft.length != 0) {
        this.caseLeftFlag = true;
      }
      else {
        this.caseLeftFlag = false;
      }
      if (this.caseRightFlag == true || this.caseLeftFlag == true) {
        this.footAssmtFlag = true;
      }
      else {
        this.footAssmtFlag = false;
      }
      if (getcasesumret.diabetes.icd_codes != null) {
        var icd = new StringBuilder("");
        if (getcasesumret.diabetes.icd_codes != null) {
          var n = 0;
          for (var i = 0; i < getcasesumret.diabetes.icd_codes.length; i++) {
            n = n + 1;
            if (n > 1) {
              if (getcasesumret.diabetes.icd_codes[i] != "") {
                icd.append(",");
                icd.append("\n");
              }
            }
            if (getcasesumret.diabetes.icd_codes[i].icd10_code != undefined
              && getcasesumret.diabetes.icd_codes[i].icd10_desc != undefined) {
              icd.append(getcasesumret.diabetes.icd_codes[i].icd10_code + " - " + getcasesumret.diabetes.icd_codes[i].icd10_desc);
            }
          }
          this.icdCode = icd.toString();
        }
      }
      if (getcasesumret.diabetes.diet_habits != null) {
        this.caseDietaryHab = getcasesumret.diabetes.diet_habits;
      }
      var concern_txt = new StringBuilder("");
      if (getcasesumret.diabetes.main_concerns != null) {
        var n = 0;
        for (var i = 0; i < getcasesumret.diabetes.main_concerns.length; i++) {
          n = n + 1;
          if (n > 1) {
            if (getcasesumret.diabetes.main_concerns[i] != "") {
              concern_txt.append(",");
              concern_txt.append("\n");
            }
          }
          concern_txt.append(getcasesumret.diabetes.main_concerns[i]);
        }
        this.caseConcern = concern_txt.toString();
      }
      var cardio_list = new StringBuilder("");
      if (getcasesumret.diabetes.cardio_dis != null) {
        var n = 0;
        for (var i = 0; i < getcasesumret.diabetes.cardio_dis.length; i++) {
          n = n + 1;
          if (n > 1) {
            if (getcasesumret.diabetes.cardio_dis[i] != "") {
              cardio_list.append(",");
              cardio_list.append("\n");
            }
          }
          cardio_list.append(getcasesumret.diabetes.cardio_dis[i].description);
        }
        this.caseCardio = cardio_list.toString();
      }
      if (this.caseCardio.length != 0) {
        this.caseCardioFlag = true;
      }
      else {
        this.caseCardioFlag = false;
      }
      var mobil_list = new StringBuilder("");
      if (getcasesumret.diabetes.mobility != null) {
        var n = 0;
        for (var i = 0; i < getcasesumret.diabetes.mobility.length; i++) {
          n = n + 1;
          if (n > 1) {
            if (getcasesumret.diabetes.mobility[i] != "") {
              mobil_list.append(",");
              mobil_list.append("\n");
            }
          }
          mobil_list.append(getcasesumret.diabetes.mobility[i].description);
        }
        this.caseMobility = mobil_list.toString();
      }
      if (this.caseMobility.length != 0) {
        this.caseMobilityFlag = true;
      }
      else {
        this.caseMobilityFlag = false;
      }
      var neph_list = new StringBuilder("");
      if (getcasesumret.diabetes.neprho_dis != null) {
        var n = 0;
        for (var i = 0; i < getcasesumret.diabetes.neprho_dis.length; i++) {
          n = n + 1;
          if (n > 1) {
            if (getcasesumret.diabetes.neprho_dis[i] != "") {
              neph_list.append(",");
              neph_list.append("\n");
            }
          }
          neph_list.append(getcasesumret.diabetes.neprho_dis[i].description);
        }
        this.caseNephro = neph_list.toString();
      }
      if (this.caseNephro.length != 0) {
        this.caseNephroFlag = true;
      }
      else {
        this.caseNephroFlag = false;
      }
      var retion_list = new StringBuilder("");
      if (getcasesumret.diabetes.retino_dis != null) {
        var n = 0;
        for (var i = 0; i < getcasesumret.diabetes.retino_dis.length; i++) {
          n = n + 1;
          if (n > 1) {
            if (getcasesumret.diabetes.retino_dis[i] != "") {
              retion_list.append(",");
              retion_list.append("\n");
            }
          }
          retion_list.append(getcasesumret.diabetes.retino_dis[i].description);
        }
        this.caseRetino = retion_list.toString();
      }
      if (this.caseRetino.length != 0) {
        this.caseRetinoFlag = true;
      }
      else {
        this.caseRetinoFlag = false;
      }
      if (this.caseCardioFlag == true || this.caseNephroFlag == true || this.caseRetinoFlag == true || this.caseMobilityFlag == true) {
        this.complicationsFlag = true;
      }
      else {
        this.complicationsFlag = false;
      }
      this.medPresListForDiab = [];
      if (getcasesumret.diabetes.med_prescriptions != null) {
        for (var i = 0; i < getcasesumret.diabetes.med_prescriptions.length; i++) {
          if (getcasesumret.diabetes.med_prescriptions[i].intake_desc == "Every 6 hours") {
            if (getcasesumret.diabetes.med_prescriptions[i].drug_intake == "With Food") {
              this.timeDuration = "-"
            } else {
              this.timeDuration = getcasesumret.diabetes.med_prescriptions[i].time_duration + " " + "mins"
            }
          } else {
            if (getcasesumret.diabetes.med_prescriptions[i].drug_intake == "With Food") {
              if (getcasesumret.diabetes.med_prescriptions[i].time_duration != "0") {
                this.timeDuration = getcasesumret.diabetes.med_prescriptions[i].time_duration
                this.timeDuration = "-"
              }
            } else {
              if (getcasesumret.diabetes.med_prescriptions[i].time_duration != "0") {
                this.timeDuration = getcasesumret.diabetes.med_prescriptions[i].time_duration + " " + "mins"
              } else {
                this.timeDuration = "-"
              }
            }
          }
          this.medPresListForDiab.push({
            drug_type_name: getcasesumret.diabetes.med_prescriptions[i].drug_type_name,
            drug_name: getcasesumret.diabetes.med_prescriptions[i].drug_name,
            days: getcasesumret.diabetes.med_prescriptions[i].days,
            quan_tx: getcasesumret.diabetes.med_prescriptions[i].intake_desc + " " + getcasesumret.diabetes.med_prescriptions[i].morning + " - " + getcasesumret.diabetes.med_prescriptions[i].afternoon + " - " + getcasesumret.diabetes.med_prescriptions[i].evening,
            with_time: this.timeDuration,
            drug_intake: getcasesumret.diabetes.med_prescriptions[i].drug_intake,
          });
        }
      }
      if (this.medPresListForDiab.length != 0) {
        this.medPresForDiabFlag = true;
      } else {
        this.medPresForDiabFlag = false;
      }
      if (getcasesumret.diabetes.tests != null) {
        for (var i = 0; i < getcasesumret.diabetes.tests.length; i++) {
          this.diagTempTestListForDiab.push(
            getcasesumret.diabetes.tests[i]
          )
        }
        if (this.diagTempTestListForDiab.length != 0) {
          this.diagnosisForDiabFlag = false;
        } else {
          this.diagnosisForDiabFlag = true;
        }
      }
      for (var i = 0; i < this.diagTempTestListForDiab.length; i++) {
        if (this.diagTempTestListForDiab[i] == "blood_tests") {
          if (getcasesumret.diabetes.blood_tests != null) {
            var sb = new StringBuilder("");
            var n = 0;
            for (var j = 0; j < getcasesumret.diabetes.blood_tests.length; j++) {
              if (this.diagTempTestListForDiab[i] == "blood_tests") {
                this.bloodName = "Blood Test"
              }
              n = n + 1;
              if (n > 1) {
                sb.append(", ");
              }
              sb.append(getcasesumret.diabetes.blood_tests[j].test_name);
            }
            this.diagTestListForDiab.push({
              testself: this.bloodName,
              test_name: sb.toString()
            });
          }
        } else if (this.diagTempTestListForDiab[i] == "scan_tests") {
          if (getcasesumret.diabetes.scan_tests != null) {
            var sb = new StringBuilder("");
            var n = 0;
            for (var j = 0; j < getcasesumret.diabetes.scan_tests.length; j++) {
              if (this.diagTempTestListForDiab[i] == "scan_tests") {
                this.scanName = "Scan Test"
              }
              n = n + 1;
              if (n > 1) {
                sb.append(", ");
              }
              sb.append(getcasesumret.diabetes.scan_tests[j].test_name);
            }
            this.diagTestListForDiab.push({
              testself: this.scanName,
              test_name: sb.toString()
            });
          }
        } else if (this.diagTempTestListForDiab[i] == "urine_tests") {
          if (getcasesumret.diabetes.urine_tests != null) {
            var sb = new StringBuilder("");
            var n = 0;
            for (var j = 0; j < getcasesumret.diabetes.urine_tests.length; j++) {
              if (this.diagTempTestListForDiab[i] == "urine_tests") {
                this.urineName = "Urine Test"
              }
              n = n + 1;
              if (n > 1) {
                sb.append(", ");
              }
              sb.append(getcasesumret.diabetes.urine_tests[j].test_name);
            }
            this.diagTestListForDiab.push({
              testself: this.urineName,
              test_name: sb.toString()
            });
          }
        } else if (this.diagTempTestListForDiab[i] == "faeces_tests") {
          if (getcasesumret.diabetes.faeces_tests != null) {
            var sb = new StringBuilder("");
            var n = 0;
            for (var j = 0; j < getcasesumret.diabetes.faeces_tests.length; j++) {
              if (this.diagTempTestListForDiab[i] == "faeces_tests") {
                this.faecesName = "Faeces Test"
              }
              n = n + 1;
              if (n > 1) {
                sb.append(", ");
              }
              sb.append(getcasesumret.diabetes.faeces_tests[j].test_name);
            }
            this.diagTestListForDiab.push({
              testself: this.faecesName,
              test_name: sb.toString()
            });
          }
        } else if (this.diagTempTestListForDiab[i] == "xray_tests") {
          if (getcasesumret.diabetes["xray_tests"] != null) {
            var sb = new StringBuilder("");
            var n = 0;
            for (var j = 0; j < getcasesumret.diabetes["xray_tests"].length; j++) {
              if (this.diagTempTestListForDiab[i] == "xray_tests") {
                this.xrayName = "Xray Test"
              }
              n = n + 1;
              if (n > 1) {
                sb.append(", ");
              }
              sb.append(getcasesumret.diabetes.xray_tests[j].test_name);
            }
            this.diagTestListForDiab.push({
              testself: this.xrayName,
              test_name: sb.toString()
            });
          }
        } else if (this.diagTempTestListForDiab[i] == "ultra-sound_tests") {
          if (getcasesumret.diabetes["ultra-sound_tests"] != null) {
            var sb = new StringBuilder("");
            var n = 0;
            for (var j = 0; j < getcasesumret.diabetes["ultra-sound_tests"].length; j++) {
              if (this.diagTempTestListForDiab[i] == "ultra-sound_tests") {
                this.ultraName = "Ultra Sound"
              }
              n = n + 1;
              if (n > 1) {
                sb.append(", ");
              }
              sb.append(getcasesumret.diabetes["ultra-sound_tests"][j].test_name);
            }
            this.diagTestListForDiab.push({
              testself: this.ultraName,
              test_name: sb.toString()
            });
          }
        }
      }
      if (this.diagTestListForDiab.length != 0) {
        this.diagTestFlag = false;
      }
      else {
        this.diagTestFlag = true;
      }

    } 
    //nephrology
    if (getcasesumret != undefined && getcasesumret != undefined) {
      //Graph Data
      if (getcasesumret.diag_readings != undefined) {
        for (let value of getcasesumret.diag_readings) {
          // $scope.Nepro_Graph = true;
          this.diabGraphdivFlag = false;
          $("#eGFR_Chart_Unavail").hide();
          $("#eGFR_Chart").show();
          $("#Serum_Chart_Unavail").hide();
          $("#Serum_Chart").show();
          $("#Albuminuria_Unavail").hide();
          $("#Albuminuria_Chart").show();
          $("#Proteinuria_Unavail").hide();
          $("#Proteinuria_Chart").show();
          var Datelist = new Array();
          var eGFR_list = new Array();
          var Serum_Creatinine_list = new Array();
          var Albuminuria_list = new Array();
          var Proteinuria_list = new Array();
          var Serum_Measure_list = new Array();
          var Albumin_Measure_List = new Array();
          Datelist.push(value.created_date);
          eGFR_list.push(value.egfr);
          Serum_Creatinine_list.push(value.serum_creatinine);
          Albuminuria_list.push(value.albumin);
          Proteinuria_list.push(value.protein);
          Neprology_graph.Neprograph(Datelist, eGFR_list, Serum_Creatinine_list, Albuminuria_list, Proteinuria_list);
        }
      } else {
        $("#eGFR_Chart_Unavail").show();
        $("#eGFR_Chart").hide();
        $("#Serum_Chart_Unavail").show();
        $("#Serum_Chart").hide();
        $("#Albuminuria_Unavail").show();
        $("#Albuminuria_Chart").hide();
        $("#Proteinuria_Unavail").show();
        $("#Proteinuria_Chart").hide();
      }
      if (getcasesumret.nephrology != undefined) {
        this.nephrologyFlag = true;
        if (getcasesumret.nephrology.main_data != undefined) {
          for (var i = 0; i < getcasesumret.nephrology.main_data.length; i++) {
            var dietlist = new StringBuilder("");
            if (getcasesumret.nephrology.main_data[i].diet_habits != null) {
              var n = 0;
              n = n + 1;
              if (n > 1) {
                if (getcasesumret.nephrology.main_data[i].diet_habits != "") {
                  dietlist.append(",");
                  dietlist.append("\n");
                }
              }
              dietlist.append(getcasesumret.nephrology.main_data[i].diet_habits);
            }
            this.dietHabits = dietlist.toString();
            var nephdisstr = new StringBuilder("");
            if (getcasesumret.nephrology.main_data[i].disease != null) {
              var n = 0;
              n = n + 1;
              if (n > 1) {
                if (getcasesumret.nephrology.main_data[i].disease != "") {
                  nephdisstr.append(",");
                  nephdisstr.append("\n");
                }
              }
              nephdisstr.append(getcasesumret.nephrology.main_data[i].disease);
            }
            this.nephDisease = nephdisstr.toString();
            var icdcodelist = new StringBuilder("");
            var icddislist = new StringBuilder("");
            if (getcasesumret.nephrology.main_data[i].icd_code != null
              && getcasesumret.nephrology.main_data[i].icd_desc != null) {
              var n = 0;
              n = n + 1;
              if (n > 1) {
                if (getcasesumret.nephrology.main_data[i].icd_code != "") {
                  icdcodelist.append(",");
                  icdcodelist.append("\n");
                }
              }
              icdcodelist.append(getcasesumret.nephrology.main_data[i].icd_code);
              var n = 0;
              n = n + 1;
              if (n > 1) {
                if (getcasesumret.nephrology.main_data[i].icd_desc != "") {
                  icddislist.append(",");
                  icddislist.append("\n");
                }
              }
              icddislist.append(getcasesumret.nephrology.main_data[i].icd_desc);
            }
            this.nephIcd = icdcodelist.toString();
            this.nephIcdDesc = icddislist.toString(); //icd desc
            var nephtreatlist = new StringBuilder("");
            if (getcasesumret.nephrology.main_data[i].treatment != null) {
              var n = 0;
              n = n + 1;
              if (n > 1) {
                if (getcasesumret.nephrology.main_data[i].treatment != "") {
                  nephtreatlist.append(",");
                  nephtreatlist.append("\n");
                }
              }
              nephtreatlist.append(getcasesumret.nephrology.main_data[i].treatment);
            }
            this.nephTreat = nephtreatlist.toString();
            var nephdialist = new StringBuilder("");
            if (getcasesumret.nephrology.main_data[i].dialysis_type != null) {
              var n = 0;
              n = n + 1;
              if (n > 1) {
                if (getcasesumret.nephrology.main_data[i].dialysis_type != "") {
                  nephdialist.append(",");
                  nephdialist.append("\n");
                }
              }
              nephdialist.append(getcasesumret.nephrology.main_data[i].dialysis_type);
            }
            this.nephDysType = nephdialist.toString(); //dialysis_type
            var nephdiafreqlist = new StringBuilder("");
            if (getcasesumret.nephrology.main_data[i].dialysis_freq != null) {
              var n = 0;
              n = n + 1;
              if (n > 1) {
                if (getcasesumret.nephrology.main_data[i].dialysis_freq != "") {
                  nephdiafreqlist.append(",");
                  nephdiafreqlist.append("\n");
                }
              }
              nephdiafreqlist.append(getcasesumret.nephrology.main_data[i].dialysis_freq);
            }
            this.nephDysFreq = nephdiafreqlist.toString();
            var nephsinlist = new StringBuilder("");
            if (getcasesumret.nephrology.skin_concerns != null) {
              var n = 0;
              n = n + 1;
              if (n > 1) {
                if (getcasesumret.nephrology.skin_concerns != "") {
                  nephsinlist.append(",");
                  nephsinlist.append("\n");
                }
              }
              nephsinlist.append(getcasesumret.nephrology.skin_concerns);
            }
            this.nephSkin = nephsinlist.toString();
            var nephhearlist = new StringBuilder("");
            if (getcasesumret.nephrology.hearing_concerns != null) {
              var n = 0;
              n = n + 1;
              if (n > 1) {
                if (getcasesumret.nephrology.hearing_concerns != "") {
                  nephhearlist.append(",");
                  nephhearlist.append("\n");
                }
              }
              nephhearlist.append(getcasesumret.nephrology.hearing_concerns);
            }
            this.nephHear = nephhearlist.toString();
            var abdomaenlist = new StringBuilder("");
            if (getcasesumret.nephrology.abdomen_concerns != null) {
              var n = 0;
              n = n + 1;
              if (n > 1) {
                if (getcasesumret.nephrology.abdomen_concerns != "") {
                  abdomaenlist.append(",");
                  abdomaenlist.append("\n");
                }
              }
              abdomaenlist.append(getcasesumret.nephrology.abdomen_concerns);
            }
            this.nephAbdom = abdomaenlist.toString();
            var retinolist = new StringBuilder("");
            if (getcasesumret.nephrology.retino_concerns != null) {
              var n = 0;
              n = n + 1;
              if (n > 1) {
                if (getcasesumret.nephrology.retino_concerns != "") {
                  retinolist.append(",");
                  retinolist.append("\n");
                }
              }
              retinolist.append(getcasesumret.nephrology.retino_concerns);
            }
            this.nephRetino = retinolist.toString();
            var carslist = new StringBuilder("");
            if (getcasesumret.nephrology.cardio_concerns != null) {
              var n = 0;
              n = n + 1;
              if (n > 1) {
                if (getcasesumret.nephrology.cardio_concerns != "") {
                  carslist.append(",");
                  carslist.append("\n");
                }
              }
              carslist.append(getcasesumret.nephrology.cardio_concerns);
            }
            this.nephCardioVas = carslist.toString();
            var pulmlist = new StringBuilder("");
            if (getcasesumret.nephrology.respiratory_concerns != null) {
              var n = 0;
              n = n + 1;
              if (n > 1) {
                if (getcasesumret.nephrology.respiratory_concerns != "") {
                  pulmlist.append(",");
                  pulmlist.append("\n");
                }
              }
              pulmlist.append(getcasesumret.nephrology.respiratory_concerns);
            }
            this.nephPulm = pulmlist.toString();
            var disclist = new StringBuilder("");
            if (getcasesumret.nephrology.disease_causes != null) {
              var n = 0;
              n = n + 1;
              if (n > 1) {
                if (getcasesumret.nephrology.disease_causes != "") {
                  disclist.append(",");
                  disclist.append("\n");
                }
              }
              disclist.append(getcasesumret.nephrology.disease_causes);
            }
            this.nephCauseOfDis = disclist.toString();
            //Nephrology medical Prescription
            var medicalnephtable = [];
            if (getcasesumret.nephrology.med_prescriptions != null && getcasesumret.nephrology.med_prescriptions != undefined) {
              getcasesumret.nephrology.med_prescriptions.forEach(function (value) {
                if (value.intake_desc == "Every 6 hours") {
                  if (value.drug_intake == "With Food") {
                    var time_txt = "-"
                  } else {
                    time_txt = value.time_duration + " " + "mins"
                  }
                }
                else {
                  if (value.drug_intake == "With Food") {
                    if (value.time_duration != "0") {
                      time_txt = value.time_duration
                      time_txt = "-"
                    }
                  } else {
                    if (value.time_duration != "0") {
                      time_txt = value.time_duration + " " + "mins"
                    } else {
                      time_txt = "-"
                    }
                  }
                }
                medicalnephtable.push({
                  drug_type_name: value.drug_type_name,
                  drug_name: value.drug_name,
                  days: value.days,
                  quan_tx: value.intake_desc + " " + value.morning + " - " + value.afternoon + " - " + value.evening,
                  with_time: time_txt,
                  drug_intake: value.drug_intake,
                });
              });
            }
            if (this.medPresListForNeph.length != 0) {
              this.medPresForNephFlag = true;
            }
            else {
              this.medPresForNephFlag = false;
            }
          }
        }
      }
    }
    //pediatrics
    if (getcasesumret != undefined && getcasesumret.pediatrics != undefined) {
      this.pediatricsFlag = true;
      if (getcasesumret.pediatrics != null) {
        if (getcasesumret.pediatrics.name != null) {
          this.pediaChildName = getcasesumret.pediatrics.name;
        }
        if (getcasesumret.pediatrics.mother_age != null) {
          this.pediaMotherAge = getcasesumret.pediatrics.mother_age;
        }
        if (getcasesumret.pediatrics.no_of_pregnancy != null) {
          this.pediaNoOfPreg = getcasesumret.pediatrics.no_of_pregnancy;
        }
        if (getcasesumret.pediatrics.delivery_type != null) {
          this.pediaDeliveryType = getcasesumret.pediatrics.delivery_type;
        }
        if (getcasesumret.pediatrics.gestational_age != null) {
          this.pediaGestationAge = getcasesumret.pediatrics.gestational_age;
        }
        var complication_string = new StringBuilder("");
        if (getcasesumret.pediatrics.complications != null) {
          var n = 0;
          for (var i = 0; i < getcasesumret.pediatrics.complications.length; i++) {
            n = n + 1;
            if (n > 1) {
              if (getcasesumret.pediatrics.complications[i] != "") {
                complication_string.append(",");
                complication_string.append("\n");
              }
            }
            complication_string.append(getcasesumret.pediatrics.complications[i]);
          }
          this.pediaComplication = complication_string.toString();
        }
        var living_conditionst = new StringBuilder("");
        if (getcasesumret.pediatrics.living_conditions != null) {
          var n = 0;
          for (var i = 0; i < getcasesumret.pediatrics.living_conditions.length; i++) {
            n = n + 1;
            if (n > 1) {
              if (getcasesumret.pediatrics.living_conditions[i] != "") {
                living_conditionst.append(",");
                living_conditionst.append("\n");
              }
            }
            living_conditionst.append(getcasesumret.pediatrics.living_conditions[i]);
          }
          this.pediaLivingCondition = living_conditionst.toString();
        }
        var feed_string = new StringBuilder("");
        if (getcasesumret.pediatrics.feeding_habits != null) {
          var n = 0;
          for (var i = 0; i < getcasesumret.pediatrics.feeding_habits.length; i++) {
            n = n + 1;
            if (n > 1) {
              if (getcasesumret.pediatrics.feeding_habits[i] != "") {
                feed_string.append(",");
                feed_string.append("\n");
              }
            }
            feed_string.append(getcasesumret.pediatrics.feeding_habits[i]);
          }
          this.pediaFeedingHabits = feed_string.toString();
        }
        var allergy_string = new StringBuilder("");
        if (getcasesumret.pediatrics.allergies != null) {
          var n = 0;
          for (var i = 0; i < getcasesumret.pediatrics.allergies.length; i++) {
            n = n + 1;
            if (n > 1) {
              if (getcasesumret.pediatrics.allergies[i] != "") {
                allergy_string.append(",");
                allergy_string.append("\n");
              }
            }
            allergy_string.append(getcasesumret.pediatrics.allergies[i]);
          }
          this.pediaAllergies = allergy_string.toString();
        }
        var dis_string = new StringBuilder("");
        if (getcasesumret.pediatrics.disease != null) {
          var n = 0;
          for (var i = 0; i < getcasesumret.pediatrics.disease.length; i++) {
            n = n + 1;
            if (n > 1) {
              if (getcasesumret.pediatrics.disease[i] != "") {
                dis_string.append(",");
                dis_string.append("\n");
              }
            }
            dis_string.append(getcasesumret.pediatrics.disease[i]);
          }
          this.pediaDiseases = dis_string.toString();
        }
        if (getcasesumret.pediatrics.icd10 != null) {
          for (var i = 0; i < getcasesumret.pediatrics.icd10.length; i++) {
            if (getcasesumret.pediatrics.icd10[i].icd_code != undefined
              && getcasesumret.pediatrics.icd10[i].icd_desc != undefined) {
              this.pediaIcd = getcasesumret.pediatrics.icd10[i].icd_code + " - " + getcasesumret.pediatrics.icd10[i].icd_desc;
            }
          }
        }
        this.medPresListForPedia = [];
        if (getcasesumret.pediatrics.med_prescriptions != null) {
          for (var i = 0; i < getcasesumret.pediatrics.med_prescriptions.length; i++) {
            if (getcasesumret.pediatrics.med_prescriptions[i].intake_desc == "Every 6 hours") {
              if (getcasesumret.pediatrics.med_prescriptions[i].drug_intake == "With Food") {
                this.timeDuration = "-"
              } else {
                this.timeDuration = getcasesumret.pediatrics.med_prescriptions[i].time_duration + " " + "mins"
              }
            } else {
              if (getcasesumret.pediatrics.med_prescriptions[i].drug_intake == "With Food") {
                if (getcasesumret.diabetes.med_prescriptions[i].time_duration != "0") {
                  this.timeDuration = getcasesumret.pediatrics.med_prescriptions[i].time_duration
                  this.timeDuration = "-"
                }
              } else {
                if (getcasesumret.pediatrics.med_prescriptions[i].time_duration != undefined) {
                  this.timeDuration = getcasesumret.pediatrics.med_prescriptions[i].time_duration + " " + "mins"
                }
              }
            }
            this.medPresListForPedia.push({
              drug_type_name: getcasesumret.pediatrics.med_prescriptions[i].drug_type_name,
              drug_name: getcasesumret.pediatrics.med_prescriptions[i].drug_name,
              days: getcasesumret.pediatrics.med_prescriptions[i].days,
              quan_tx: getcasesumret.pediatrics.med_prescriptions[i].intake_desc + " " + getcasesumret.pediatrics.med_prescriptions[i].morning + " - " + getcasesumret.pediatrics.med_prescriptions[i].afternoon + " - " + getcasesumret.pediatrics.med_prescriptions[i].evening,
              with_time: this.timeDuration,
              drug_intake: getcasesumret.pediatrics.med_prescriptions[i].drug_intake,
            });
          }
        }
        if (this.medPresListForPedia.length != 0) {
          this.medPresForPediaFlag = true;
        }
        else {
          this.medPresForPediaFlag = false;
        }
      }
    }
  }
}
