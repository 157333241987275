<div *ngIf="pediavitalFlag == 'min'">
    <div class="block1_grid">
        <mat-label class="matlabel ">At Birth
            <mat-checkbox color="primary" [(ngModel)]="At_birth"></mat-checkbox>
        </mat-label>
        <mat-label class="matlabel">Height<br>
            <input matInput class="ipcss " required [(ngModel)]="Height_txt">
        </mat-label>
        <mat-label class="matlabel">Measure<br>
            <select class="ipcss " [(ngModel)]="Height_measure" disableOptionCentering>
                <option value="cms">cms</option>
                <option value="inch">inch</option>
            </select>
        </mat-label>
        <mat-label class="matlabel">Weight<br>
            <input matInput class="ipcss " required [(ngModel)]="weight_txt">
        </mat-label>
    </div>
</div>

<div *ngIf="pediavitalFlag == 'max'">
    <div class="cover_div">
        <div class="content_cover">
            <div class="row">
                <div [hidden]="atbirth_hidden" class="col-12 col-md-12 col-lg-12 col-sm-12 col-xl-12">
                    <mat-label class="matlabel " [hidden]="atbirth_hidden">At Birth
                        <mat-checkbox [disabled]="client" color="primary" [(ngModel)]="At_birth"></mat-checkbox>
                    </mat-label>
                </div>
                <div [hidden]="height_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                    <div [hidden]="height_measurehidden" class="row">
                        <div class="col-6">
                            <mat-label class="matlabel" [hidden]="height_hidden">Height<br>
                                <input [readonly]="client" matInput class="ipcss " required [(ngModel)]="Height_txt">
                            </mat-label>
                        </div>
                        <div [hidden]="height_measurehidden" class="col-6">
                            <mat-label class="matlabel" [hidden]="height_measurehidden">Measure<br>
                                <select [disabled]="client" class="ipcss " [(ngModel)]="Height_measure"
                                    disableOptionCentering>
                                    <option value="cms">cms</option>
                                    <option value="inch">inch</option>
                                </select>
                            </mat-label>
                        </div>
                    </div>
                    <div [hidden]="!height_measurehidden">
                        <mat-label class="matlabel" [hidden]="height_hidden">Height<br>
                            <input [readonly]="client" matInput class="ipcss " required [(ngModel)]="Height_txt">
                        </mat-label>
                    </div>
                </div>

                <div [hidden]="weight_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                    <div [hidden]="weight_measurehidden" class="row">
                        <div class="col-6">
                            <mat-label class="matlabel" [hidden]="weight_hidden">Weight<br>
                                <input [readonly]="client" matInput class="ipcss " required [(ngModel)]="weight_txt">
                            </mat-label>
                        </div>
                        <div [hidden]="weight_measurehidden" class="col-6">
                            <mat-label class="matlabel" [hidden]="weight_measurehidden">Measure<br>
                                <select [disabled]="client" class="ipcss " [(ngModel)]="weight_measure"
                                    disableOptionCentering>
                                    <option value="kgs">kgs</option>
                                    <option value="lbs">lbs</option>
                                </select>
                            </mat-label>
                        </div>
                    </div>
                    <div [hidden]="!weight_measurehidden">
                        <mat-label class="matlabel" [hidden]="weight_hidden">Weight<br>
                            <input [readonly]="client" matInput class="ipcss " required [(ngModel)]="weight_txt">
                        </mat-label>
                    </div>
                </div>

                <div [hidden]="temp_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                    <mat-label class="matlabel" [hidden]="temp_hidden">Temperature<br>
                        <input [readonly]="client" matInput class="ipcss " [(ngModel)]="Temperature">
                    </mat-label>
                </div>
                <div [hidden]="blood_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                    <mat-label class="matlabel" [hidden]="blood_hidden">Blood Pressure<br>
                        <div class="row">
                            <div class="col-6">
                                <input [readonly]="client" matInput class="ipcss " placeholder="mm Hg" maxlength="3"
                                    [(ngModel)]="BP_01"
                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                            </div>
                            <div class="col-6">
                                <input [readonly]="client" matInput class="ipcss " placeholder="mm Hg" maxlength="3"
                                    [(ngModel)]="BP_02"
                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                            </div>
                        </div>
                    </mat-label>
                </div>

                <div [hidden]="heart_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                    <mat-label class="matlabel" [hidden]="heart_hidden">Heart rate<br>
                        <input [readonly]="client" matInput class="ipcss " placeholder="Pulse" [(ngModel)]="Heart_Beat">
                    </mat-label>
                </div>
                <div [hidden]="respi_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                    <mat-label class="matlabel" [hidden]="respi_hidden">Respiratory rate<br>
                        <input [readonly]="client" matInput class="ipcss " placeholder="Rate / minute"
                            [(ngModel)]="Respiratory_rate">
                    </mat-label>
                </div>
                <div class="col-12">
                    <a style="float: right;" [hidden]="client" (click)="vital_historytemp()"><img
                            src="../../../assets/ui_icons/buttons/History_button.svg" class="saveimgbtn_inpatinfo" /></a>
                    <a style="float: right;" [hidden]="client" (click)="save_click()"><img
                            src="../../../assets/ui_icons/buttons/save_button.svg" class="saveimgbtn_inpatinfo" /></a>
                </div>
            </div>
        </div>
    </div>

    <br>
    <div class="row" style="padding-left: 13px;">
        <div class="col-12 col-md-6 col-lg-6 col-sm-12 col-xl-6">
            <mat-card class="">
                <div class="appint_graph2">
                    <div [hidden]="Height_Chart_Unavail" class="graph_dataunavail">
                        <p class="Paraclass">
                            <img src="../../../assets/img/Warning.png" class="Imageclass" />
                            <span class="notxt">No data found</span>
                        </p>
                    </div>

                    <div id="Height_Chart" [hidden]="Height_zoomhidden" class="chart_div"></div>
                    <div class="row">
                        <div class="col-2">
                            <img src="../../../assets/img/zoom_in.png" height="30px" width="40px"
                                (click)="reading_poup('1','Height_Chartzooom')" [hidden]="Height_zoomhidden">
                        </div>
                        <div class="col-10">
                            <p class="text-center cahrat_para">Height</p>
                        </div>
                    </div>
                </div>
            </mat-card>
        </div>
        <div class="col-12 col-md-6 col-lg-6 col-sm-12 col-xl-6">
            <mat-card class="">
                <div class="appint_graph2">
                    <div [hidden]="Weight_Chart_Unavail" class="graph_dataunavail">
                        <p class="Paraclass">
                            <img src="../../../assets/img/Warning.png" class="Imageclass" />
                            <span class="notxt">No data found</span>
                        </p>
                    </div>
                    <div id="Weight_Chart" [hidden]="weight_zoomhidden" class="chart_div"></div>
                    <div class="row">
                        <div class="col-2">
                            <img src="../../../assets/img/zoom_in.png" height="30px" width="40px"
                                (click)="reading_poup('2','Weight_Chartzoom')" [hidden]="weight_zoomhidden">
                        </div>
                        <div class="col-10">
                            <p class="text-center cahrat_para">Weight</p>
                        </div>
                    </div>
                </div>
            </mat-card>
        </div>
        <div class="col-12 col-md-6 col-lg-6 col-sm-12 col-xl-6">
            <mat-card class="">
                <div class="appint_graph2 graphalign">
                    <div [hidden]="Unavail_Temp_Chart" class="graph_dataunavail">
                        <p class="Paraclass">
                            <img src="../../../assets/img/Warning.png" class="Imageclass" />
                            <span class="notxt">No data found</span>
                        </p>
                    </div>
                    <div id="Temp_Chart" [hidden]="temp_zoomhidden" class="chart_div"></div>
                    <div class="row">
                        <div class="col-2">
                            <img src="../../../assets/img/zoom_in.png" height="30px" width="40px"
                                (click)="reading_poup('3','Temp_Chartzoom')" [hidden]="temp_zoomhidden">
                        </div>
                        <div class="col-10">
                            <p class="text-center cahrat_para">Temperature</p>
                        </div>
                    </div>
                </div>
            </mat-card>
        </div>
        <div class="col-12 col-md-6 col-lg-6 col-sm-12 col-xl-6">
            <mat-card class="">
                <div class="appint_graph2 graphalign">
                    <div [hidden]="Heart_Chart_Unavail" class="graph_dataunavail">
                        <p class="Paraclass">
                            <img src="../../../assets/img/Warning.png" class="Imageclass" />
                            <span class="notxt">No data found</span>
                        </p>
                    </div>
                    <div id="Heart_Chart" [hidden]="Heart_zoomhidden" class="chart_div"></div>
                    <div class="row">
                        <div class="col-2">
                            <img src="../../../assets/img/zoom_in.png" height="30px" width="40px"
                                (click)="reading_poup('4','Heart_Chartzoom')" [hidden]="Heart_zoomhidden">
                        </div>
                        <div class="col-10">
                            <p class="text-center cahrat_para">Heart rate</p>
                        </div>
                    </div>
                </div>
            </mat-card>
        </div>
    </div>
</div>