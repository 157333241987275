import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { ipaddress } from '../../../ipaddress';
import moment from 'moment';
import { Helper_Class } from '../../../helper_class';
import { Message_data } from 'src/assets/js/Message_data';

@Component({
  selector: 'app-stockadjustment',
  templateUrl: './stockadjustment.component.html',
  styleUrls: ['./stockadjustment.component.scss']
})
export class StockadjustmentComponent implements OnInit {

  // constructor() { }

  // ngOnInit(): void {
  // }

  public locationArray = [];
  public loadLocationList = [];
  public pharmacyFacID: any;
  public location: any;
  public locationIP = null;
  public stock: any = [];
  public pharmacistID;
  public userInfo;
  public pharmacyID;
  public reasondata = [];
  dtOptions: DataTables.Settings = {};
  isDisabled: boolean = false;
  public stockadjst;
  public reasonurl = "mas/gsar";
  public departmentarray = [];
  public departname;
  public storename;
  public hsp_id;
  public storearray = [];
  public bulkstock = [];
  public selectedOption;
  public MedicineNameArrayreturns = [];
  public medicineArrayreturns = [];
  public MedicneDatareturns = [];
  public pharmacyVendorArray: any = [];
  public sendData;
  public batchFlag;
  public rackId;
  public binId;
  public drugArray = [];
  public productReturn;
  public tempBatch;
  public batcharray = [];
  public onhand;
  public ynopt1: boolean = true;
  public isButtonsEnabled = false;
  public reference_no;
  public adjustitedetails = [];
  public reasonss;
  public user_type;
  public stockadjustmentdata = [];
  public saveandedit_stock;
  public all_stock_adj_dta;
  public backbuttonflag;
  public newbuttonflag;
  public calculation_value;
  public checkkeyfunction;
  public savebuttonflag;
  public confirmbuttonflag;
  public editbutton: boolean = false;
  public newbutton: boolean = false;
  public deletebutton: boolean = false;
  public printbutton: boolean = false;
  public viewbutton: boolean = false;
  public calvalue: string;
  public pageSize;
  public pageNavigate: boolean;
  public prevPageCount: boolean;
  public nextPageCount: boolean;
  public noOfPages: any;
  public currentPage: any;
  public pagelist: any;
  public sno;
  public usertype: string;
  public clientCount;
  public isadmin;
  public productname;

  constructor(public http: Http, public httpclient: HttpClient, public routes: ActivatedRoute, public router: Router, public toastr: ToastrService) {
    this.pageSize = 50;
    this.currentPage = 1;
    this.pageNavigate = true
  }
  ngOnInit(): void {
    this.checkkeyfunction = "";
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 25,
      "lengthMenu": [[25, 50, -1], [25, 50, "All"]],
      order: [[0, 'desc']],
      "columnDefs": [
        // { "orderable": false, "targets": [0] },
      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search supplier or batch no"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    this.userInfo = Helper_Class.getInfo();
    this.usertype = this.userInfo.user_type;
    this.user_type = Helper_Class.getInfo().user_type;
    this.pharmacistID = this.userInfo.user_id;
    this.hsp_id = this.userInfo.hosp_id;
    this.productReturn = this.userInfo.hospitals[0].product_return;
    this.saveandedit_stock = true;
    this.all_stock_adj_dta = false;
    this.backbuttonflag = true;
    this.newbuttonflag = false;
    this.isadmin = this.userInfo.is_admin;
    // this.userInfo = Helper_Class.getInfo();
    // this.pharmacistID = this.userInfo.user_id;
    // this.pharmacyID = this.userInfo.hospitals[0].hptl_clinic_id;

    for (var i = 0; i < this.userInfo.hospitals.length; i++) {
      this.pharmacyID = this.userInfo.hospitals[0].hptl_clinic_id;
    }
    this.savebuttonflag = false;
    this.confirmbuttonflag = false;
    // this.loadstocks()
    // this.pharmaLocation();
    this.pagelist = "25"
    this.navpagelist()
    this.reason();
    this.getdepart();
    this.getstockdetails();
    if (Helper_Class.getmodulelist() != undefined) {
      for (var i = 0; i < Helper_Class.getmodulelist().length; i++) {
        if ((Helper_Class.getmodulelist()[i].module_id == "32" && Helper_Class.getmodulelist()[i].description == "Stock adjustment reasons")) {
          if (Helper_Class.getmodulelist()[i].edit == "1") {
            this.editbutton = true;
          }
          if (Helper_Class.getmodulelist()[i].create == "1") {
            this.newbutton = true;
          }
          if (Helper_Class.getmodulelist()[i].delete == "1") {
            this.deletebutton = true;
          }
          if (Helper_Class.getmodulelist()[i].print == "1") {
            this.printbutton = true;
          }
          if (Helper_Class.getmodulelist()[i].view == "1") {
            this.viewbutton = true;
          }


        }

      }


    }
  }


  getstockdetails() {
    var send_data = JSON.stringify({
      center_id: this.pharmacyID
    })
    console.log("get adjustement data" + send_data)
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "inv/gasaj", send_data,
      { headers: headers }).subscribe(
        data => {
          var obj = data.json();
          console.log("adjusted stack=" + JSON.stringify(obj))
          var adjstmentdata = obj.stock_adjustments

          for (var j = 0; j < adjstmentdata.length; j++) {
            var status = adjstmentdata[j].is_draft == "0" ? "Confirm" : "Draft";

            this.stockadjustmentdata.push({
              reference_no: adjstmentdata[j].reference_no,
              prod_adj: adjstmentdata[j].product_adjustments_id,
              depat_name: adjstmentdata[j].dept_name,
              store_name: adjstmentdata[j].store_name,
              is_draft: status,

            })


          }

          // if (obj.key != 0) {
          //   if (obj.stock_onhand != null) {
          //     // this.stock=obj.stock_onhand
          //     this.bulkstock = obj.stock_onhand;

          //   }
          // }


        },
        error => { });
  }



  showeditpage() {
    this.saveandedit_stock = false;
    this.newbutton = false;
    this.newbuttonflag = true
    // this.all_stock_adj_dta=!this.all_stock_adj_dta
    this.all_stock_adj_dta = true;
    this.backbuttonflag = false;
    this.savebuttonflag = true;
    this.confirmbuttonflag = true;

  }
  back() {
    this.all_stock_adj_dta = false;
    this.saveandedit_stock = true;
    this.newbuttonflag = false;
    this.backbuttonflag = true;
    this.savebuttonflag = false;
    this.confirmbuttonflag = false;
    this.newbutton = true;
    var value;

    this.stock = [];

  }


  view_detailsad_justdata(value, rf_no) {
    this.savebuttonflag = true;
    this.confirmbuttonflag = true;

    var send_data = JSON.stringify({
      stock_adjustment_id: value
    })
    console.log("get adjustement data" + send_data)
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "inv/gsabi", send_data,
      { headers: headers }).subscribe(
        data => {
          this.all_stock_adj_dta = true;
          this.saveandedit_stock = false
          this.newbuttonflag = true;
          this.backbuttonflag = false;
          var obj = data.json();
          console.log("get details of stock adjustment" + JSON.stringify(obj))
          var adjustdata = obj.stock_adjustment_det;
          this.reference_no = rf_no;

          for (var q = 0; q < adjustdata.length; q++) {
            this.stock.push({
              product_id: adjustdata[q].product_id,
              name: adjustdata[q].product_name,
              supplier: adjustdata[q].supplier_id,
              batch_no: adjustdata[q].batch_no,
              qty_onhand: adjustdata[q].stock_on_hand,
              adjust: adjustdata[q].adjusted_stock,


            })
          }

        },
        error => { });


  }







  loadstocks() {
    var index = 0;
    this.stock.push({
      index: index,
      name: "",
      mfg_name: "",
      qty_avaliable: "",
      adjust: "",
      batcharray: "",
      show_batcharray: false,
    })
  }
  pharmaLocation() {
    var send_data;
    if (Helper_Class.getIsAdmin() != undefined) {
      send_data = JSON.stringify({
        hptl_clinic_id: this.pharmacyID,
      });
    } else {
      send_data = JSON.stringify({
        pharmacy_id: this.pharmacyID,
        pharmacist_id: this.pharmacistID
      });
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/pharlocbyid/", send_data, { headers: headers }).subscribe(
      data => {
        var obj = data.json();
        if (data.json().pharma_locations != null) {
          this.locationArray = data.json().pharma_locations;
          for (var i = 0; i < this.locationArray.length; i++) {
            this.loadLocationList.push({
              location_id: this.locationArray[i].pharma_facility_id + " - " + this.locationArray[i].location,
              pharma_facility_id: this.locationArray[i].pharma_facility_id,
            });
            if (i == 0) {
              this.get_stock();
            }
          }
          if (this.loadLocationList.length != 0) {
            this.location = this.loadLocationList[0].location_id;
          }
        }
      },
      error => { });
  }

  // get_stock(pharmacy_fac_id) {
  //   var send_data;
  //   if (Helper_Class.getIsAdmin() != undefined) {
  //     send_data = JSON.stringify({
  //       hptl_clinic_id: this.pharmacyID,
  //       is_admin: "1"
  //     });
  //   } else {
  //     send_data = JSON.stringify({
  //       pharmacy_id: this.pharmacyID,
  //       pharmacist_id: this.pharmacistID,
  //       is_admin: "1"
  //     });
  //   }
  //   var headers = new Headers();
  //   headers.append('Content-Type', 'application/json');
  //   this.http.post(ipaddress.getIp + "pharmacontrol/gpstock/", send_data,
  //     { headers: headers }).subscribe(
  //       data => {
  //         var obj = data.json();
  //         console.log("full stock ="+JSON.stringify(obj))
  //         if (obj.key != 0) {

  //           if (obj.stock_onhand != null) {



  //             var bulkstocklist=obj.stock_onhand;
  //             console.log("bull stock list"+JSON.stringify(bulkstocklist))
  //             for(var i=0;i< bulkstocklist.length;i++){

  //               console.log(JSON.stringify("stock data ="+bulkstocklist[i]))
  //               this.bulkstock.push({
  //                 product_id:bulkstocklist[i].product_id,
  //                 name:bulkstocklist[i].name,
  //                 batch_no:bulkstocklist[i].batch_no,
  //                 qty_onhand:bulkstocklist[i].qty_onhand,
  //                 supplier:bulkstocklist[i].supplier,
  //                 supplier_id:bulkstocklist[i].supplier_id,
  //                 exp_date:bulkstocklist[i].exp_date,
  //                 rec_item_id:bulkstocklist[i].rec_item_id		
  //               })


  //             }



  //           }
  //         }
  //       },
  //       error => { });
  // }



  get_stock() {

    // if(this.pagelist == "25"){
    //   this.sno="1"
    // }else if(this.pagelist == "50"){
    //   this.sno="51"
    // }else if(this.pagelist == "100"){
    //   this.sno="101"
    // }else if(this.pagelist == "full"){
    //   this.sno="etc"
    // }

    var send_data;
    if (this.usertype == "admin") {
      send_data = {
        hptl_clinic_id: this.userInfo.hptl_clinic_id,
        limit: this.pagelist,
        sno: this.sno,
        is_admin: "1"
      }
    } else {
      send_data = {
        pharmacy_id: this.pharmacyID,
        limit: this.pagelist,
        sno: this.sno,
        is_admin: "0"
      }
    }
    console.log(" SEND DATA = " + JSON.stringify(send_data))
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "inv/gpsbl", send_data,
      { headers: headers }).subscribe(
        data => {
          var obj = data.json();
          console.log("stock data 2 = " + JSON.stringify(obj))
          if (obj.key != 0) {
            if (obj.stock_onhand != null) {
              this.bulkstock = [];
              this.pageNavigate = obj.stock_count > 50 ? false : true;
              this.clientCount = obj.prod_count
              var bulkstocklist = obj.stock_onhand;

              for (var i = 0; i < bulkstocklist.length; i++) {
                var count = this.sno + (i)
                console.log(JSON.stringify("stock data =" + bulkstocklist[i]))
                this.bulkstock.push({
                  sn: count,
                  product_id: bulkstocklist[i].product_id,
                  name: bulkstocklist[i].name,
                  batch_no: bulkstocklist[i].batch_no,
                  qty_onhand: bulkstocklist[i].qty_onhand,
                  supplier: bulkstocklist[i].supplier,
                  supplier_id: bulkstocklist[i].supplier_id,
                  exp_date: bulkstocklist[i].exp_date,
                  rec_item_id: bulkstocklist[i].rec_item_id,
                  selected: false
                })

                // var long_name=obj.stock_on_hand[i].short_name+''+obj.stock_on_hand[i].name 
              }



              // this.stock=[];
              // this.stock = obj.stock_onhand;
            }
          }
        },
        error => { });

  }

  getdatausingname(value) {
    if (value.length > 3) {
      var send_data
      if (this.usertype == "admin") {
        if (this.isadmin == "1") {
          send_data = {
            pharmacy_id: this.pharmacyID,
            prod_name: value,
            is_admin: "1"
          }

        } else {
          send_data = {
            pharmacy_id: this.pharmacyID,
            prod_name: value,
            is_admin: "0"
          }
        }

      } else {
        if (this.isadmin == "1") {
          send_data = {
            pharmacy_id: this.pharmacyID,
            prod_name: value,
            is_admin: "1"
          }
        } else {
          send_data = {
            pharmacy_id: this.pharmacyID,
            prod_name: value,
            is_admin: "0"
          }
        }
      }
      console.log("data =" + JSON.stringify(send_data))
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "inv/gpsbl", send_data,
        { headers: headers }).subscribe(
          data => {
            var obj = data.json();
            console.log("stock data 3 = " + JSON.stringify(obj))
            if (obj.key != 0) {
              // if (obj.stock_onhand != null) {

              // }
              this.bulkstock = [];
              this.pageNavigate = obj.stock_count > 50 ? false : true;
              this.clientCount = obj.prod_count
              var bulkstocklist = obj.stock_onhand;

              for (var i = 0; i < bulkstocklist.length; i++) {

                console.log(JSON.stringify("stock data =" + bulkstocklist[i]))
                this.bulkstock.push({
                  product_id: bulkstocklist[i].product_id,
                  name: bulkstocklist[i].name,
                  batch_no: bulkstocklist[i].batch_no,
                  qty_onhand: bulkstocklist[i].qty_onhand,
                  supplier: bulkstocklist[i].supplier,
                  supplier_id: bulkstocklist[i].supplier_id,
                  exp_date: bulkstocklist[i].exp_date,
                  rec_item_id: bulkstocklist[i].rec_item_id,
                  selected: false
                })
              }
            }
          },
          error => { });

    } else if (value.length == 0) {
      this.get_stock()
    }

  }
  public accepted: boolean;
  public checkeddata = [];
  list(bulkstocklist) {
    this.checkeddata = [];
    if (bulkstocklist.selected == false) {
      this.checkeddata.push({
        product_id: bulkstocklist.product_id,
        name: bulkstocklist.name,
        batch_no: bulkstocklist.batch_no,
        qty_onhand: bulkstocklist.qty_onhand,
        supplier: bulkstocklist.supplier,
        supplier_id: bulkstocklist.supplier_id,
        exp_date: bulkstocklist.exp_date,
        rec_item_id: bulkstocklist.rec_item_id,
        selected: true
      })
    }
    else {
      this.checkeddata.push({
        product_id: bulkstocklist.product_id,
        name: bulkstocklist.name,
        batch_no: bulkstocklist.batch_no,
        qty_onhand: bulkstocklist.qty_onhand,
        supplier: bulkstocklist.supplier,
        supplier_id: bulkstocklist.supplier_id,
        exp_date: bulkstocklist.exp_date,
        rec_item_id: bulkstocklist.rec_item_id,
        selected: false
      })
    }

  }


  reason() {
    var send_data = {
      pharma_id: this.pharmacyID
    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.httpclient.post(ipaddress.getIp.toString() + this.reasonurl, send_data, { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log("obj modiefreason" + JSON.stringify(obj))

          this.reasondata = obj.stock_adj_reasons


          console.log("obj resons " + JSON.stringify(this.reasondata))


        }
      )
  }


  getdepart() {
    console.log("pharmacy Id =" + this.pharmacyID)
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "gen/gdbh", JSON.stringify({ hptl_clinic_id: this.pharmacyID, type: this.user_type }), { headers: headers }).subscribe(
      response => {
        var obj = response.json()
        console.log(" DEPARTMENT DATA" + JSON.stringify(obj))
        this.departmentarray = obj.departments;


      }
    )
  }


  prbaseon_departement(value) {

    this.getstorename(this.departname)
  }

  getstorename(value) {


    var senddata = {
      hptl_clinic_id: this.hsp_id,
      department_id: value
    }
    console.log("send data" + JSON.stringify(senddata))
    // console.log("pharmacy Id ="+this.pharmacyID)
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "gen/gsdbh", JSON.stringify({ hptl_clinic_id: this.hsp_id, department_id: value }), { headers: headers }).subscribe(

      response => {
        var obj = response.json()
        console.log(" store DATA" + JSON.stringify(obj));
        this.storearray = obj.stores;
        // this.departmentarray=obj.departments;

      },
      error => {
        console.log("error on retriving the store name")
      }

    )
  }


  updateStock() {
    for (var i = 0; i < this.checkeddata.length; i++) {
      if (this.stock.length != 0) {
        for (var j = 0; j < this.stock.length; j++) {
          if (this.checkeddata[i].selected == true) {
            if ((this.checkeddata[i].name != this.stock[j].name) && (this.checkeddata[i].batch_no != this.stock[j].batch_no)) {
              this.stock.push({
                product_id: this.checkeddata[i].product_id,
                name: this.checkeddata[i].name,
                supplier: this.checkeddata[i].supplier,
                batch_no: this.checkeddata[i].batch_no,
              })
              break;
            } else {
              this.toastr.error("Medicine already added");
              break;
            }
          }
        }

      } else {
        console.log(this.stock.length)
        if (this.checkeddata[i].selected == true) {

          this.stock.push({
            product_id: this.checkeddata[i].product_id,
            name: this.checkeddata[i].name,
            supplier: this.checkeddata[i].supplier,
            batch_no: this.checkeddata[i].batch_no,
          })
        }
      }
    }
  }


  changeMedicineNamereturns(e, type) {
    var senddetails = JSON.stringify({
      drug_name: e.target.value.toString(),
      pharmacy_id: this.pharmacyID,
      medicare_name: "1",
      type: type
    })
    if (e.target.value.toString() != null && e.target.value.toString().length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/nmeddetails/', senddetails,
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            console.log("obj" + JSON.stringify(obj));
            this.get_stock()
            if (obj.med_details != null) {
              this.MedicineNameArrayreturns = [];
              this.medicineArrayreturns = [];
              this.MedicneDatareturns = [];
              for (var i = 0; i < obj.med_details.length; i++) {
                var medname = obj.med_details[i].short_name + " " + obj.med_details[i].med_name
                // this.MedicneData.push(medname);
                this.MedicneDatareturns.push({ name: medname, quantity: obj.med_details[i].quantity, generic: obj.med_details[i].generic_name });
                this.medicineArrayreturns.push({
                  med_id: obj.med_details[i].med_id,
                  med_name: obj.med_details[i].med_name,
                  med_type_id: obj.med_details[i].med_type_id,
                  type_name: obj.med_details[i].type_name,
                  short_name: obj.med_details[i].short_name,
                  short_form: obj.med_details[i].short_form,
                  show_short_form: obj.med_details[i].show_short_form,
                  show_intake: obj.med_details[i].show_intake,
                  medname: medname,
                  cgst: obj.med_details[i].cgst,
                  sgst: obj.med_details[i].sgst,
                  rack_id: obj.med_details[i].rack_id,
                  bin_id: obj.med_details[i].bin_id
                })

                // this.MedicineNameArray = this.MedicneData.filter(function (this: any, el) {
                //   return el.toLowerCase().indexOf(e.target.value.toString().toLowerCase()) > -1;
                // }.bind(this));
              }
            }

          },
          error => { });
    } else {
      this.MedicineNameArrayreturns = [];
    }
  }

  getVendor(value) {
    if (value != undefined && value.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "pharmacontrol/gsup/", JSON.stringify({ pharmacy_id: this.pharmacyID, supp_name: value }), { headers: headers }).subscribe(
        data => {
          if (data.json().supplier_details != null) {
            this.pharmacyVendorArray = data.json().supplier_details;
          }
        },
      )
    }
  }


  selected_vendor(vendor) {
    // this.vendor = vendor.name;
    // this.vendorID = vendor.supp_id;
    // this.pharmacyVendorArray=[];
  }

  subtractFromStock(value) {
    if (value == 'plus') {
      this.calculation_value = 'plus'
      this.calvalue = "increase";
    }
    else {
      this.calculation_value = 'minus'
      this.calvalue = "decrease";
    }
  }

  isAdjustValid(stock: any) {
    return stock.adjust !== undefined && stock.adjust !== null && stock.adjust.trim() !== '';
  }

  adjustChange(stock: any) {
    var adjqty;

    if (stock.adjust != undefined && stock.adjust != "") {

      adjqty = stock.adjust;

    } else {

      adjqty = 0
    }
    if (this.calculation_value == "plus") {
      stock.avilable = parseInt(stock.qty_onhand) + parseInt(adjqty);
      stock.adjusted_value = "decrease";

    } else {
      stock.avilable = parseInt(stock.qty_onhand) - parseInt(adjqty);
      stock.adjusted_value = "increase";
    }

    this.isButtonsEnabled = this.isAdjustValid(stock);
  }

  selectedBatchreturns(batch, drug_detailsarray, index) {

    console.log("batch ==== " + JSON.stringify(batch))
    console.log("drugDetailsarray ==== " + JSON.stringify(drug_detailsarray))
    console.log("index ==== " + JSON.stringify(index))
    // var checkList = this.fineDrugListreturns(batch.batch_no, drug_detailsarray.drug_id);
    var checkList;
    // this.backenddrugquantity = drug_detailsarray.quantity
    // console.log("drug quantity",parseInt(drug_detailsarray.quantity))
    console.log("back end drug quantity", drug_detailsarray)
    console.log("drug array", this.drugArray)
    if (checkList != true) {
      this.batchFlag = false;
      this.stock.batch_required = { "border-color": "#2ca8fe", };
      drug_detailsarray.batch_no = batch.batch_no;
      if (drug_detailsarray.batch_no != "")
        drug_detailsarray.quantityDisable = false;
      for (var i = 0; i < this.drugArray.length; i++) {

        if (batch.batch_no == this.drugArray[i].batch_no) {
          if (batch.index == this.drugArray[i].index) {
            if (this.tempBatch != undefined) {
              drug_detailsarray.price = this.drugArray[i].price_txt;
              // drug_detailsarray.quantity = this.drugArray[i].quantity;
              // drug_detailsarray.tempquantity = this.drugArray[i].quantity;
            }
            else {
              drug_detailsarray.price = this.drugArray[i].price_txt;
              // drug_detailsarray.quantity = this.drugArray[i].quantity;
              // drug_detailsarray.tempquantity = this.drugArray[i].quantity;
              drug_detailsarray.per_unit = this.drugArray[i].per_unit;
              drug_detailsarray.cgst = this.drugArray[i].cgst;
              drug_detailsarray.sgst = this.drugArray[i].sgst;
              drug_detailsarray.discount = this.drugArray[i].discount;
              drug_detailsarray.expiry_date = this.drugArray[i].expiry_date;
              drug_detailsarray.rec_item_id = this.drugArray[i].rec_item_id
            }
            // this.calculateamountreturns(drug_detailsarray);
          }
        }
      }
      this.stock.show_batcharray = false;

      document.getElementById(index).style.display = 'none';
      document.getElementById("pharma_tbl_overlay").style.overflowY = "auto";
      document.getElementById("tbl_med").style.overflowY = "auto";

    } else {
      this.toastr.error("Medicine with same batch no already added");
    }

  }

  updateAvailable(stockesarray) {

    if (this.calculation_value != null || this.calculation_value != undefined || this.calculation_value == "") {
      this.checkkeyfunction = "value";
      if (this.calculation_value == "plus") {
        stockesarray.avilable = parseInt(stockesarray.qty_onhand) + parseInt(stockesarray.adjust);

      } else if (this.calculation_value == 'minus') {
        stockesarray.avilable = parseInt(stockesarray.qty_onhand) - parseInt(stockesarray.adjust);

      } else {
        console.log("error to be calculated symbol")
      }
    }
  }

  createRow() {
    this.loadstocks();
    this.medicineArrayreturns = [];
  }

  deleteMedicinereturn(drugId, index) {



    for (var i = 0; i < this.stock.length; i++) {
      console.log(this.stock[i])

      if (this.stock[i].drug_id != "" && this.stock[i].drug_id != undefined && this.stock[i].drug_id != null) {
        if (this.stock[i].drug_id == drugId) {
          if (this.stock[i].index == index) {

            const itemToDelete = this.stock.find(drug => drug.drug_id === drugId && drug.index === index);
            console.log("item to delete" + itemToDelete)

            if (itemToDelete) {
              const indexToDelete = this.stock.indexOf(itemToDelete);
              console.log("item to delete 2" + indexToDelete)
              if (indexToDelete !== -1) {
                this.stock.splice(indexToDelete, 1);


              }
            }
          }

        }
      }
    }
    // console.log(" return 2"+this.returnbilltotalAmount)
    console.log("stock =" + this.stock)
  }
  deleteMedicine(index: number) {
    if (index >= 0 && index < this.stock.length) {
      this.stock.splice(index, 1); // Remove the row at the specified index
    }
  }

  save_data(value) {


    console.log("added stock =" + JSON.stringify(this.stock))

    if (this.stock.length != 0) {
      for (var i = 0; i < this.stock.length; i++) {

        this.adjustitedetails.push({
          supplier_id: this.stock[i].supplier_id,
          product_id: this.stock[i].product_id,
          product_name: this.stock[i].name,
          batch_no: this.stock[i].batch_no,
          stock_on_hand: this.stock[i].qty_onhand,
          adjusted_stock: this.stock[i].adjust,
          adjusted_value: this.stock[i].adjusted_value

        })

      }
      var send_data = JSON.stringify({
        center_id: this.pharmacyID,
        center_type: "pharmacy",
        department_id: this.departname,
        store_id: this.storename,
        created_by: this.pharmacistID,
        country: ipaddress.country_code,
        is_draft: value,
        reference_no: this.reference_no,
        prod_details: this.adjustitedetails,
        reason: this.reasonss
      })

      console.log("savedraft data" + send_data)
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "inv/spa/", send_data,
        { headers: headers }).subscribe(
          data => {
            var obj = data.json();
            console.log("adjust obj =" + JSON.stringify(obj))
            if (obj.key != 0) {
              this.toastr.success(Message_data.successstockadjustedsavedraft);
              this.stock = [];
            }
            else {
              this.toastr.error(Message_data.errorstockadjustedsavedraft);
            }
            this.isDisabled = false;
          },
          error => { });






    }

  }

  pageLimitnext() {

    if (this.currentPage == this.noOfPages) {
      this.nextPageCount = true;
    }
    else {
      this.currentPage = parseInt(this.currentPage);
      // console.log("check --"+this.currentPage)
      this.pageSize = this.pagelist;
      this.prevPageCount = false;
      this.sno = (parseInt(this.currentPage) * parseInt(this.pagelist)) + 1;
      this.get_stock();

      if (this.currentPage == this.noOfPages)
        this.nextPageCount = true;


      this.currentPage = this.currentPage + 1;
    }
  }

  pageLimitPrevious() {
    if (this.currentPage == "1") {
      this.prevPageCount = true;
    }
    else {
      this.currentPage = this.currentPage - 1;
      // console.log("check --"+this.currentPage);
      this.pageSize = this.pagelist;
      if (this.currentPage != 1)
        this.sno = ((parseInt(this.currentPage) - 1) * parseInt(this.pagelist)) + 1;
      else if (this.currentPage == 1)
        this.sno = 1;

      // console.log(this.currentLimit);
      this.get_stock();

      this.nextPageCount = false;
    }
  }



  navpagelist() {
    this.currentPage = 1;
    this.sno = 1;
    this.pageSize = this.pagelist;
    this.get_stock();
    // console.log(this.stoc.length);
    if (this.pagelist == "25") {
      if (this.clientCount > 25) {
        this.pageNavigate = false;
        this.nextPageCount = false;
        this.prevPageCount = false;
      } else {
        this.pageNavigate = false;
        this.nextPageCount = false;
        this.prevPageCount = false;
      }
    } else if (this.pagelist == "50") {
      if (this.clientCount > 50) {
        this.pageNavigate = false;
        this.nextPageCount = false;
        this.prevPageCount = false;
      } else {
        this.pageNavigate = true;
        this.nextPageCount = true;
        this.prevPageCount = true;
      }
    } else if (this.pagelist == "100") {
      if (this.clientCount > 100) {
        this.pageNavigate = false;
        this.nextPageCount = false;
        this.prevPageCount = false;
      } else {
        this.pageNavigate = true;
        this.nextPageCount = true;
        this.prevPageCount = true;
      }
    }
  }
  numberOfPages() {
    this.noOfPages = Math.ceil(this.clientCount / this.pagelist);
    return Math.ceil(this.clientCount / this.pagelist);
    // console.log("client count "+this.clientCount+"...."+this.pagelist)
    // this.noOfPages = Math.floor(this.clientCount / this.pagelist);
    console.log("client count " + this.noOfPages)

    // return Math.floor(this.clientCount / this.pagelist);
  }
}







