import { Component, OnInit, ViewChild } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Helper_Class } from '../../helper_class';
import { Date_Formate } from '../../../assets/js/common';
import { Message_data } from '../../../assets/js/Message_data';
import { ToastrService } from 'ngx-toastr';
import { Physio_Helper } from '../Physio_Helper';
import { PhysioService } from '../physio/physio.service';
import { ipaddress } from '../../ipaddress';
@Component({
  selector: 'app-walking',
  templateUrl: './walking.component.html',
  styleUrls: ['./walking.component.scss']
})
export class WalkingComponent implements OnInit {
  public dobFlag:boolean;
  public dobDisabledFlag:boolean;
  public ageDisabledFlag:boolean;
  public saveFlag:boolean = false;
  public historyFlag:boolean = true;
  public locationUrl: string;
  public locationNameByUrl: string;
  public cityUrl: string;
  public stateUrl: string;
  public countryUrl: string;
  public clntFirstName: string;
  public clntMiddleName: string;
  public clntLastName: string;
  public clntAge;
  public clntGender: string;
  public clntBloodGrp: string;
  public clntBloodID: string;
  public clntFatherOrGuardName: string;
  public clntIncome: string;
  public clntOccupation: string;
  public clntAddress1!: string | null;
  public clntAddress2!: string | null;
  public clntLocation: string;
  public clntCity: string;
  public clntState: string;
  public clntCountry: string;
  public clntLocationId!: string | undefined;
  public clntCityId!: string | undefined;
  public clntStateId: string;
  public clntCountryId: string;
  public clntZipcode: string;
  public clntContactNo;
  public clntSymptom: string;
  public newSymptomsFlag:boolean = false;
  public newsymptoms:any =[];
  public clntDOB;
  public treatementType;
  public clntLocationList:any = [];
  public clntCityList:any = [];
  public clntStateList:any = [];
  public clntCountryList:any = [];
  public clntSalList:any = [];
  public appType: string;
  public relationName;
  public homecare: string;
  public clntDetailUrl: string;
  public hptlUrl: string;
  public salUrl: string;
  public userTypeFlag: boolean = true;
  public newUserFlag:boolean;
  public existUserFlag:boolean;
  public clientType: string;
  public height;
  public hmeasure: string;
  public weight;
  public wmeasure: string;
  public temperature: string;
  public bmi;
  public bmr;
  public chiefComplaint;
  public presentIllness: string;
  public medicalHistory: string;
  public familyHisttory: string;
  public physicalExam: string;
  public clientRegId: string;
  public clntSal: string;
  public ageUrl: string;
  public currentDate: string;
  public splId: string;
  public appId:string;
  public bloodPressure: string;
  public pulse: string;
  public cvs: string;
  public rs: string;
  public kidney: string;
  public spo: string;
  public arthritisType: string;
  public deformities: string;
  public treatment: string;
  public surgeryId: string;
  public surgeryType: string;
  public surgeryProcedure:string;
  public surgeryInvestigation:string;
  public pft: string;
  public referredDoctor: string;
  public advice: string;
  public sendImge: string;
  public confirmFlag: boolean;
  public hospitalId: string;
  public updateFlag: boolean;
  public viewrxFlag: boolean;
  public followFlag: boolean;
  public requireFlag: boolean;
  public hospName;
  public referredBy!: string | null;
  public speclization;
  public userInfo:any;
  public userId:any;
  public hospitalDetails;
  public patientName;
  public currentMaxDate;
  public currentTime;
  public currentYear;
  public observList = [];
  public covidSympList = [];
  public comorbList = [];
  public finalCovidSymp = [];
  public finalcomorbList = [];
  public lNameRequiredFlag: boolean;
  public clientList = [];
  public relationList=[];
  public nameFlag:boolean;
  public respiratory: string;
  public covidType: string;
  public covidDuration: string;
  public covidFlag: boolean;
  public observetable: boolean;
  public rtpcrResult: string;
  public BP01: string=null;
  public BP02: string=null;
  public otherFlag:boolean;
  public relId: string;
  public relationUrl: string;
  public hLocation;
  public hAddress1;
  public hAddress2;
  public hCity;
  public hCountry;
  public hState;
  public hZipcode;
  public hCityId;
  public hCountryId;
  public hStateId;  
  public symptomListData = [];
  public symptomList = [];
  public symptomsMainList = [];
  public selectedSymptoms = [];
  public imagePath:any;
  public relationId;
  public subRelId;
  public sugeryImage;
  public clientDetails: any = [];
  public sugeryImges:boolean;
  public surgeryNewFlag:boolean = false;
  public reportImg = [];
  content;
  htmlString: string = '';
  modules = {
    toolbar: [
      ['bold'], 
      [{ 'color': [] }], 
    ]
  };
  editorStyle = {
    height: '300px;',
  };
  public therapyList=[];
  public sittings: string;
  public therapies;
  public Select = "Select";
  public therapyhistData = [];
  public physioStartDate;
  public physioAppDate = [];
  public therapyMasterList = [];
  public therapy: string;
  public caseHistId: string;
  public visitFreq: string;
  public startTime;
  public endTime;
  public timeSession;
  public timeStartsFrom :any= [];
  public timeEndsAt :any= [];
  public session;
  public sessionFlag;
  public visitingSessionList = [];
  public remarks;
  physionameList: any;
  is_admin: boolean = false;
  hptl_clinic_ids: any;
  doctorid: any;

  constructor(public http: Http, public toastr: ToastrService, public messageService: PhysioService) { 
    this.clntDetailUrl = ipaddress.getIp + "usercontroller/cdetnoapph/";
    this.hptlUrl = ipaddress.getIp + "usercontroller/hospdetails";
    this.ageUrl = ipaddress.getIp + "adm/ddif";
    this.salUrl = ipaddress.getIp + "gen/sal";
    this.locationUrl = ipaddress.getIp + "usercontroller/loc";
    this.locationNameByUrl = ipaddress.getIp + "usercontroller/locbyname";
    this.cityUrl = ipaddress.getIp + "usercontroller/city";
    this.stateUrl = ipaddress.getIp + "usercontroller/state";
    this.countryUrl = ipaddress.getIp + "usercontroller/count";
    this.relationUrl = ipaddress.getIp.toString()+"gen/getrel";
    this.hmeasure = "cms";
    this.wmeasure = "kgs";
  }

  ngOnInit(): void {
    $(document).ready(function () {
      $("input").attr("autocomplete", "off");
    });
    this.userInfo = Helper_Class.getInfo();
    this.userId = this.userInfo.user_id;
    this.hospitalDetails = this.userInfo.hospitals[0];
    this.hospitalId = this.hospitalDetails.hptl_clinic_id;
    this.clntLocationId = this.hospitalDetails.location;
    this.clntCityId = this.hospitalDetails.city;
    this.clntStateId = this.hospitalDetails.state;
    this.clntCountryId = this.hospitalDetails.country;
    this.clientType = "Existing";
    for (var i = 1; i <= 12; i++) {
      if (i < 10) 
        this.timeStartsFrom.push("0" + i);
      else
        this.timeStartsFrom.push(i);
    }
    for (var i = 0; i <= 55; i += 5) {
      if (i < 10) 
        this.timeEndsAt.push("0" + i);
      else
        this.timeEndsAt.push(i);
    }
    Helper_Class.setPageFlow("noapp");
    this.getRelationList();
    this.getCurrentDate();
    this.getHospitalDetails();
    this.getSalutations();
    this.getCountries("0");
    this.getTherapyHistory();
    this.userTypeSelect("Existing");
    this.checkAvilablity();
    this.confirmFlag = false;
    this.updateFlag=true;
    this.viewrxFlag=true;
    this.dobFlag = true; 
    this.changeProviderType();
  }

  changeProviderType() {

    if (Helper_Class.getInfo().user_type == 'Admin') {
      this.hptl_clinic_ids = Helper_Class.getInfo().hptl_clinic_id;
      this.is_admin = true;
      this.getphysioList();
    }

  }
  changephysio() {
    this.userId = this.doctorid;
    this.getRelationList();
    this.getCurrentDate();
    this.getHospitalDetails();
    this.getSalutations();
    this.getCountries("0");
    this.getTherapyHistory();
    this.userTypeSelect("Existing");
    this.checkAvilablity();
  }
  getphysioList() {
    this.physionameList = [];
    var send_data = {
      center_id: this.hptl_clinic_ids,
      type: "6"
    };
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(ipaddress.getIp.toString() + 'adm/guth', JSON.stringify(send_data), { headers: headers })
      .subscribe(
        (response) => {
          var obj = JSON.parse(response["_body"]);
          console.log('check---' + JSON.stringify(obj));
          if (obj != null) {
            for (var i = 0; i < obj.users.length; i++) {
              var physioName = '';
              var fname, mname, lname;
              if (
                obj.users[i].middle_name != '' &&
                obj.users[i].middle_name != undefined
              ) {
                physioName =
                  obj.users[i].first_name +
                  ' ' +
                  obj.users[i].middle_name +
                  ' ' +
                  obj.users[i].last_name;
                fname = obj.users[i].first_name;
                mname = obj.users[i].middle_name;
                lname = obj.users[i].last_name;
              } else {
                physioName =
                  obj.users[i].first_name + ' ' + obj.users[i].last_name;
                fname = obj.users[i].first_name;
                lname = obj.users[i].last_name;
              }
              this.physionameList.push({
                PhysioName: physioName,
                physiodata_id: obj.users[i].user_id,

              });
            }
          }
        },
        (error) => {

        }
      );
  }
  getSymptoms(){
    var searchString;
    if (this.clntSymptom.indexOf(',') > -1) { 
      var dataval = this.clntSymptom.split(","); 
      searchString = dataval[dataval.length-1].trim();
    } else {
      searchString = this.clntSymptom;
    }
    if(searchString.length >2){
      var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp.toString() + 'adm/gsymbn/',
          JSON.stringify({
            symp_name: searchString
          }),
          { headers: headers })
          .subscribe(
            response => {
              var obj = JSON.parse(response["_body"]);
              if (obj.symptoms != null && obj.symptoms != undefined && obj.symptoms != ""  && obj.symptoms.length != 0) {
                this.newSymptomsFlag = false; 
                this.symptomsMainList = [];
                this.symptomList = [];
                this.symptomListData = [];
                this.symptomsMainList = obj.symptoms;
                for (var i = 0; i < obj.symptoms.length; i++) {
                  this.symptomListData.push(obj.symptoms[i].description);
                }
                this.symptomList = this.symptomListData.filter(function (el) {
                  return el.toLowerCase().indexOf(searchString.toLowerCase()) > -1;
                }.bind(this));
              }else{
                this.symptomList = [];
                this.newSymptomsFlag = true; 
              }
            },error => {});
    }
  }

  selectSymptom(data){
    this.newsymptoms.push(data);
    for(var j=0;j<this.symptomsMainList.length;j++){
      if(this.symptomsMainList[j].description == data){
        this.selectedSymptoms.push(data);
        break;
      }
    }
    if (this.clntSymptom.indexOf(',') > -1) { 
      var dataval = this.clntSymptom.split(","); 
      dataval.pop();
      this.clntSymptom = dataval.join(",");
      this.clntSymptom = this.clntSymptom+","+data;
    } else {
      this.clntSymptom = data;
    }
    this.symptomList=[];    
  }

  selectSession(event){
    if(event == "Morning"){
      this.timeSession = "AM";
      this.startTime = "06";
      this.endTime = "00";
    }else if(event == "Evening"){
      this.timeSession = "PM";
      this.startTime = "06";
      this.endTime = "00";
    }else{
      this.timeSession = "PM";
      this.startTime = "12";
      this.endTime = "00";
    }
  }

  checkAvilablity(){
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'physio/gpavail',
      JSON.stringify({
        physio_id : this.userId,
        hptl_clinic_id: this.hospitalId
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          if (obj.physio_avail != null) {
              for( var i=0; i < obj.physio_avail.length; i++){
                  if(obj.physio_avail[i].day_session == "1"){
                      this.visitingSessionList.push("Morning");
                  }else if(obj.physio_avail[i].day_session == "2") {
                    this.visitingSessionList.push("Afternoon");
                  }else if(obj.physio_avail[i].day_session == "3"){
                    this.visitingSessionList.push("Evening");
                  }
              }
              this.selectSession(this.visitingSessionList[0])
              this.session = this.visitingSessionList[0];
          }
        },error => {
            this.toastr.error(Message_data.defaultErr);
        });
  }

  getSalutations(){
    var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.get(this.salUrl, { headers: headers }).subscribe(
        data => {
          var obj = data.json();
          if(obj !=undefined){
            for(var i=0;i<obj.salutations.length;i++){
              this.clntSalList.push({
                sal_id:obj.salutations[i].salutation_id,
                sal_desc:obj.salutations[i].salutation_desc,
              });
            }
           this.clntSal= "select";
            this.changeSalutation(this.clntSal);
          }
        });
  }

  getHospitalDetails(){
    var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.hptlUrl, JSON.stringify({
        hosp_clinic_id: this.hospitalId
      }), { headers: headers }).subscribe(
        data => {
          var obj = data.json();
          if(obj !=undefined){
            this.hLocation = obj.location;
            this.hAddress1 = obj.address1;
            this.hAddress2 = obj.address2;
            this.hCity = obj.city;
            this.hState = obj.state;
            this.hCountry = obj.country;
            this.hZipcode = obj.zipcode;
            this.hCityId = obj.city_id;
            this.hStateId = obj.state_id;
            this.hCountryId = obj.country_id;
            if (obj.hptl_logo != undefined) 
              Physio_Helper.setHospital_logo(ipaddress.Ip_with_img_address+obj.hptl_logo);
            if(obj.hptl_pres_logo != undefined)
              Physio_Helper.setHospital_pres_logo(ipaddress.Ip_with_img_address+obj.hptl_pres_logo);
            if(obj.bg_image != undefined)
              Physio_Helper.setHospital_bg_image(ipaddress.Ip_with_img_address+obj.bg_image);
            if (obj.address2 != null && obj.address2 != "")
              Physio_Helper.setHospital_address(obj.address1 + " " + obj.address2);
            else
              Physio_Helper.setHospital_address(obj.address1);
            Physio_Helper.setHospital_name(obj.hosp_name);
            Physio_Helper.setHospital_print_template(obj.print_template);
            Physio_Helper.setHospital_location(obj.location);
            Physio_Helper.setHospital_city_zip(obj.city + " - " + obj.zipcode);
            Physio_Helper.setHospital_state_country(obj.state + " , " + obj.country);
          }
        });
  }

  getRelationList() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(this.relationUrl, { headers: headers }).subscribe(
      data => {
        if (data.json().relation != null && data.json().relation != undefined) {
          this.relationList = data.json().relation;
          this.relationName = data.json().relation[0].relationship_name;
          this.relId = data.json().relation[0].relationship_id;
        }
      },error => {
        this.toastr.error(Message_data.unableToFetchData);
      });
  }

  getCurrentDate(){
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate',
      JSON.stringify({
        country: ipaddress.country_code.toString()
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          if (obj.current_date != null) {
            this.currentDate = obj.current_date;
            this.currentMaxDate = obj.current_date;
            this.currentTime = obj.current_time;
            var get_date = obj.current_date.split('-');
            this.currentYear = get_date[0];
            this.physioStartDate = this.currentDate;
            Helper_Class.setKey(obj.provider+obj.clientid+obj.messageid);
            Helper_Class.setIV(obj.random);
          }
        },error => {
            this.toastr.error(Message_data.defaultErr);
        });
  }

  userTypeSelect(e){
    this.saveFlag = false;
    this.clientType = e;
    if(this.clientType =="New"){
      this.userTypeFlag=false;
      this.newUserFlag=false;
      this.existUserFlag=true;
      this.clientRegId="0";
      this.relationId = "1";
      this.subRelId = "";
      this.clntGender="Male";
      this.dobFlag = false; 
      this.clntFirstName=null;
      this.clntMiddleName=null;
      this.clntLastName=null;
      this.clntAge="";
      this.clntGender="";
      this.clntBloodGrp="";
      this.clntBloodID="";
      this.clntFatherOrGuardName="";
      this.clntIncome="";
      this.clntOccupation="";
      this.clntAddress1=null;
      this.clntAddress2=null;
      this.clntContactNo= null;
      this.clntSymptom="";
      this.clntDOB=null;
      this.height="";
      this.hmeasure="cms";
      this.weight="";
      this.wmeasure="kgs";
      this.bmi=""
      this.temperature="";
      this.bmi = "";
      this.chiefComplaint="";
      this.presentIllness="";
      this.medicalHistory="";
      this.familyHisttory="";
      this.physicalExam="";
      this.hospName="";
      this.referredBy = null;
      this.covidType="no";
      this.rtpcrResult="Positive";
      this.covidFlag=false;
      this.observList = [];
      this.BP01 = "";
      this.BP02 = "";
      this.spo = "";
      this.pulse = "";
      this.cvs = "";
      this.rs = "";
      this.kidney = "";
      this.respiratory= "";
      this.pft = "";
      this.deformities = "";
      this.surgeryType ="";
      this.speclization = "";
      this.advice = "";
      this.clntSymptom = "";
      this.ageDisabledFlag = false;
      this.dobDisabledFlag = false;
      for(var i=0; i<this.comorbList.length;i++){
        this.comorbList[i].checked=false;
      }
      for(var i=0; i<this.covidSympList.length;i++){
        this.covidSympList[i].checked=false;
      }
      if(this.clntCountryList.length !=0){
        this.changeCountry(this.clntCountryId,"0");
      } else {
        this.getCountries("0");
      }
      this.clntSal=this.clntSalList[0].sal_id;
    }else {
      this.clntFirstName=null;
      this.clntMiddleName=null;
      this.clntLastName=null;
      this.clntContactNo= null;
      this.clntAge="";
      this.clntGender="";
      this.clntBloodGrp="";
      this.clntBloodID="";
      this.clntFatherOrGuardName="";
      this.clntIncome="";
      this.clntOccupation="";
      this.clntAddress1=null;
      this.clntAddress2=null;
      this.height="";
      this.hmeasure="cms";
      this.weight="";
      this.wmeasure="kgs";
      this.bmi="";
      this.relationId = "1";
      this.subRelId = "";
      this.temperature="";
      this.bmi;
      this.chiefComplaint="";
      this.presentIllness="";
      this.medicalHistory="";
      this.familyHisttory="";
      this.physicalExam="";
      this.hospName="";
      this.covidType="no";
      this.rtpcrResult="Positive";
      this.covidFlag=false;
      this.observList = [];
      this.userTypeFlag=true;
      this.newUserFlag=true;
      this.existUserFlag=false;
      this.dobFlag = true; 
      this.BP01 = "";
      this.BP02 = "";
      this.spo = "";
      this.pulse = "";
      this.cvs = "";
      this.rs = "";
      this.kidney = "";
      this.respiratory= "";
      this.pft = "";
      this.deformities = "";
      this.surgeryType ="";
      this.speclization = "";
      this.referredBy = "";
      this.advice = "";
      this.clntSymptom = "";
      this.clntDOB=null;
      this.ageDisabledFlag = false;
      this.dobDisabledFlag = false;
      for(var i=0; i<this.comorbList.length;i++){
        this.comorbList[i].checked=false;
      }
      for(var i=0; i<this.covidSympList.length;i++){
        this.covidSympList[i].checked=false;
      }
    }
  }

  changeRelation(rel_data) {
    for(var i=0;i<this.relationList.length;i++){
      if(this.relationList[i].relationship_name == rel_data){
        this.relId = this.relationList[i].relationship_id;
      }
    }
  }

  changeSalutation(e){
    if(e == "1"|| e == "6")
      this.clntGender = "Male";
    else if(e == "2" || e == "3" || e == "4" || e == "7")
      this.clntGender = "Female";
    else if(e == "5")
      this.clntGender = "Transgender";
    else
      this.clntGender = "";
  }

  acitvateSaveBtn(){
    if(this.clntFirstName != null && this.clntLastName != null && this.clntContactNo != null && this.clntAge != ""){
      if(this.clientType == "New" ){
        this.saveFlag = true;
      }
    }
  }

  fucusMobile(){
    this.acitvateSaveBtn();
  }

  fnameToUpper(){
    if(this.clntFirstName != undefined){
      this.clntFirstName = this.clntFirstName.toLocaleUpperCase();
    }
  }

  mnameToUpper(){
    if(this.clntMiddleName != undefined){
      this.clntMiddleName = this.clntMiddleName.toLocaleUpperCase();
    }
  }

  lnameToUpper(){
    this.lNameRequiredFlag = true;
    if(this.clntLastName != undefined){
      this.clntLastName = this.clntLastName.toLocaleUpperCase();
      this.clientList=[];
      this.getClientDetails();
    } else {
      this.clntLastName==null;
      this.lNameRequiredFlag=true;
    }
  }

  selectClient(data){
    for(var i=0;i<this.clientList.length;i++){
      if(this.clientList[i].name == data){
        var mname;
        if(this.clientList[i].middle_name != undefined){
          mname= this.clientList[i].middle_name;
        }
        var senddata = {
          mobile: this.clientList[i].mobile,
          fname:this.clientList[i].first_name,
          mname: mname,
          lname: this.clientList[i].last_name,
          country: "IN",
          hptl_id: this.hospitalId,
          type:this.clientType,
          is_doc: 0
        }
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(this.clntDetailUrl, JSON.stringify(senddata), { headers: headers }).subscribe(
          data => {
            if(data.json().client_reg_id !=undefined){
              this.setClientDetails(data);
            } else {
              this.toastr.error(Message_data.unableToFetchData);
            }
          },error => {
            this.toastr.error(Message_data.unableToFetchData);
          });
      } 
    }
    this.clientList=[];
  }

  getUserDetails(){
    var name_flag = true;
    if(this.clntFirstName== undefined && this.clntLastName == undefined && this.clntContactNo == undefined){
      name_flag=false;
    } 
    if(name_flag == true){
      var fname,mname,lname,mnumber;
      if(this.clntFirstName!= null)
        fname = encrypt_decript.Encript(this.clntFirstName.trim()).toString();
      if(this.clntMiddleName!= null && this.clntMiddleName != "")
        mname = encrypt_decript.Encript(this.clntMiddleName.trim()).toString();
      if(this.clntLastName!= null && this.clntLastName !="")
        lname = encrypt_decript.Encript(this.clntLastName.trim()).toString();
      if(this.clntContactNo != undefined)
        mnumber = encrypt_decript.Encript(this.clntContactNo).toString();
      var senddata = {
        mobile: mnumber,
        fname:fname,
        mname: mname,
        lname: lname,
        country: "IN",
        hptl_id: this.hospitalId,
        type:this.clientType,
        rel_id: this.relId,
        is_doc: 0
      }
      senddata = this.removeEmptyStringsFrom(senddata);
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.clntDetailUrl, JSON.stringify(senddata), { headers: headers }).subscribe(
        data => {
         if(data.json() != undefined && data.json().length != 0 ){ 
            this.saveFlag = true;
            this.historyFlag = false;
            this.setClientDetails(data);
          } else {
            this.toastr.error(Message_data.unableToFetchData);
          }
        },error => {
          this.toastr.error(Message_data.unableToFetchData);
        });
    } else {
      this.toastr.error(Message_data.enterNameOrOtherDet);
    }
  }

  selectDob(e){
    var l = e.split("-");
    this.clntDOB = e;
    if(e != null){
      this.ageDisabledFlag = true;
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.ageUrl, JSON.stringify({
      dobdate:  Date_Formate(this.clntDOB),
      curdate:  Date_Formate(this.currentDate),
      spl:this.splId,
    }), { headers: headers }).subscribe(
      data => {
        var obj = data.json();
        if(obj !=undefined){
          this.clntAge=obj.value;
          this.acitvateSaveBtn();
        }
      });
  }

  changeAge(){
    if(this.clntAge != ""){
      this.dobDisabledFlag = true;
      this.clntDOB = "";
    }else{
      this.dobDisabledFlag = false;
    }
    this.acitvateSaveBtn();
  }

  changeGender(e){
    if(this.clntAge != undefined && this.clntAge != null && this.clntAge != ""){
      if(e == "Male"){
        if(this.clntAge <= 15)
          this.clntSal = "6";
        else
          this.clntSal = "1";
      }else if(e == "Female"){
        if(this.clntAge <= 15)
          this.clntSal = "7";
        else
          this.clntSal = "2";
      }else if(e == "Transgender")
          this.clntSal = "5";
      else
        this.clntSal = "";
    }else{
      if(e == "Male")
          this.clntSal = "1";
      else if(e == "Female")
          this.clntSal = "2";
      else if(e == "Transgender")
          this.clntSal = "5";
      else
        this.clntSal = "";
    }
  }

  getCountries(flag) {
    this.clntCountryList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(this.countryUrl, { headers: headers }).subscribe(
      data => {
        this.clntCountryList = [];
        if (data.json().countries != null) {
          this.clntCountryList = data.json().countries;
          for (var c = 0; c < this.clntCountryList.length; c++) {
            if (this.clntCountryList[c].country_id == this.clntCountryId) {
              this.clntCountry = this.clntCountryList[c].description;
              this.clntCountryId = this.clntCountryList[c].country_id;
              this.getStates(this.clntCountryId, flag);
              break;
            }
          }
        }
      },error => {
        this.toastr.error(Message_data.unableToFetchData);
      });
  }

  changeCountry(clnt_country, flag){
    for (var c = 0; c < this.clntCountryList.length; c++) {
      if (this.clntCountryList[c].description == clnt_country) {
        this.clntCountry = this.clntCountryList[c].description;
        this.clntCountryId = this.clntCountryList[c].country_id;
        this.clntStateList=[];
        this.clntCityList=[];
        this.clntLocationId = undefined;
        this.clntLocation="";
        this.clntZipcode="";
        this.getStates(this.clntCountryId, flag);
      }
    }
  }

  getStates(country, flag){
    this.clntStateList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.stateUrl,JSON.stringify({country_id : country }), 
    { headers: headers }).subscribe(
      data => {
        if (data.json().states != null) {
          this.clntStateList = data.json().states;
          for (var i = 0; i < this.clntStateList.length; i++) {
            if (this.clntStateList[i].state_id == this.clntStateId) {
              this.clntStateId = this.clntStateList[i].state_id;
              this.clntState = this.clntStateList[i].description;
              this.getCities(this.clntStateId, flag);
              break;
            }
          }
        }
      });
  }

  changeState(clnt_state, flag){
    for (var i = 0; i < this.clntStateList.length; i++) {
      if (this.clntStateList[i].description == clnt_state) {
        this.clntStateId = this.clntStateList[i].state_id;
        this.clntState = this.clntStateList[i].description;
        this.clntCityList=[];
        this.clntCityId=undefined;
        this.clntLocationId=undefined;
        this.clntLocation="";
        this.clntZipcode="";
        this.getCities(this.clntStateId, flag);
      }
    }
  }

  getCities(state, flag){
    this.clntCityList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.cityUrl,JSON.stringify({state_id : state }), 
    { headers: headers }).subscribe(
      data => {
        if (data.json().cities != null) {
          this.clntCityList = data.json().cities;
          for (var i = 0; i < this.clntCityList.length; i++) {
            if (this.clntCityList[i].district_id == this.clntCityId) {
              this.clntCityId = this.clntCityList[i].district_id;
              this.clntCity = this.clntCityList[i].description;
              this.getLocations(this.clntCityId);
              break;
            } else {
              this.clntCity = this.clntCityList[0].description;
            }
          }
        }
      });
  }

  changeCity(clnt_city, flag){
    this.clntLocationList=[];
    for (var i = 0; i < this.clntCityList.length; i++) {
      if (this.clntCityList[i].description == clnt_city) {
        this.clntCityId = this.clntCityList[i].district_id;
        this.clntCity = this.clntCityList[i].description;
        this.clntLocationId=undefined;
        this.clntLocation="";
        this.clntZipcode="";
      }
    }
  }

  getLocations(city){
    this.clntLocationList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.locationUrl,JSON.stringify({city_id : city }), 
    { headers: headers }).subscribe(
      data => {
        if (data.json().locations != null && data.json().locations.length != 0) {
          this.clntLocationList = data.json().locations;
          for (var i = 0; i < this.clntLocationList.length; i++) {
            if (this.clntLocationList[i].location_id == this.clntLocationId) {
              this.clntLocationId = this.clntLocationList[i].location_id;
              this.clntLocation = this.clntLocationList[i].description;
              this.clntZipcode=this.clntLocationList[i].pincode;
              this.clntLocationList=[];
              break;
            }
          }
        }
      });
  }

  changeLocation(locat_desc) {
    this.clntLocationList =[];
    if (locat_desc != null && locat_desc.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.locationNameByUrl, JSON.stringify({
        name: locat_desc,
        city_id: this.clntCityId,
      }), { headers: headers }).subscribe(
        data => {
          if (data.json().locations != null && data.json().locations.length != 0) {
            this.clntLocationList = data.json().locations;
          }
        },error => {
          this.toastr.error(Message_data.unableToFetchDocDet);
        });
    } else {
      this.clntLocationList = [];
    }
  }

  selectLocation(location){
    this.clntLocation = location;
    for (var i = 0; i < this.clntLocationList.length; i++) {
      if (this.clntLocationList[i].location_id == location.location_id) {
        this.clntLocationId = this.clntLocationList[i].location_id;
        this.clntLocation = this.clntLocationList[i].description;
        this.clntZipcode=this.clntLocationList[i].pincode;
      }
    }
    this.clntLocationList = [];
  }

  getClientDetails(){
    if(this.clientType=="New"){
      var clntname = encrypt_decript.Encript(this.clntFirstName).toString();
      var lname;
      if(this.clntLastName !=undefined && this.clntLastName.trim() != undefined){
        lname = encrypt_decript.Encript(this.clntLastName).toString();
      }
      var mobiledata;
      if(this.clntContactNo !=undefined){
        mobiledata = encrypt_decript.Encript(this.clntContactNo).toString();
      }
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'usercontroller/clntbyname/',
        JSON.stringify({
          first_name:clntname,
          last_name:lname,
          mobile:mobiledata,
          type:"New",
          hosp_id:this.hospitalId,
        }),
        { headers: headers })
        .subscribe(
          data => {
            var obj = JSON.parse(data["_body"]);
            if(obj.clients != null){
              this.toastr.error(obj.clients);
              if(obj.key =="1")
                this.lNameRequiredFlag = true;
              else 
                this.confirmFlag=false
            }
          },error => {});
    }
  }

  removeEmptyStringsFrom(obj) {
    const clone = { ...obj };
    Object.entries(clone).forEach(([key, val]) => val === '' && delete clone[key]);
    return clone;
  }

  setClientDetails(data){
    this.viewrxFlag = false;
    this.clientRegId = data.json().client_reg_id;
    Physio_Helper.setClient_Info(data.json());
    this.clientDetails = Physio_Helper.getClient_Info();
    Physio_Helper.setClient_id(this.clientRegId);
    if(data.json().relation_id != undefined){
      Physio_Helper.setRel_id("1");
      this.relationId = data.json().relation_id;
    }else{
      this.relationId = "1";
    }
    if(data.json().sub_rel_id != undefined)
      this.subRelId = data.json().sub_rel_id;
    else
      this.subRelId = "";
    Physio_Helper.setClient_gender( data.json().gender);
    Physio_Helper.setRefer_by(data.json().ref_by);
    this.surgeryId = this.clientDetails.surgery_id;
    this.surgeryType = this.clientDetails.surgery_type;
    this.surgeryInvestigation = this.clientDetails.surgery_inv;
    this.surgeryProcedure = this.clientDetails.surgery_proc;
    this.sugeryImage = ipaddress.Ip_with_img_address + this.clientDetails.surgery_image;
    this.sendImge = this.clientDetails.surgery_image;
    if(this.clientDetails.surgery_image != "" && this.clientDetails.surgery_image != undefined && this.clientDetails.surgery_image != null){
      this.reportImg = [];
      this.reportImg.push({
        image:ipaddress.Ip_with_img_address.toString() + this.clientDetails.surgery_image,
      });
      this.sugeryImges = true;
    }else{
      this.sugeryImges = false;
    }
    if(data.json().dob != undefined && data.json().dob != "" && data.json().dob != null){
      var ddata = data.json().dob.split("-");
      this.clntDOB = data.json().dob;
      if(this.splId =="10"){
        this.selectDob(this.clntDOB);
      }
    }else{
      this.dobDisabledFlag = true;
    }
    if( data.json().age != 0){
      this.clntAge = data.json().age;
    } else {
      this.clntAge = "<1";
    }
    Physio_Helper.setClient_age(this.clntAge);
    if(this.splId=="15"){
      var cvs:any = data.json().cvs;
      var rs:any = data.json().rs;
      var kidney:any = data.json().kidney;
      var deformities:any = data.json().deformities;
      var arthritis_type:any = data.json().arthritis_type;
    } else {
      cvs =null;
      rs = null;
      kidney = null;
      deformities = null;
      arthritis_type = null;
    }
    this.clntSal= data.json().salutation;
    this.clntFirstName = encrypt_decript.Decript(data.json().first_name);
    this.clntLastName = encrypt_decript.Decript(data.json().last_name);
    if (data.json().middle_name != null && data.json().last_name != null) {
      this.clntMiddleName = encrypt_decript.Decript(data.json().middle_name);
      this.clntLastName = encrypt_decript.Decript(data.json().last_name);
      Physio_Helper.setClient_name(this.clntFirstName + " " + this.clntMiddleName + " " + encrypt_decript.Decript(data.json().last_name));
    }else if(data.json().last_name != null){
      Physio_Helper.setClient_name(this.clntFirstName + " " + encrypt_decript.Decript(data.json().last_name));
      this.clntLastName = encrypt_decript.Decript(data.json().last_name);
    }else{
      Physio_Helper.setClient_name(this.clntFirstName);
    }
    this.clntGender = encrypt_decript.Decript(data.json().gender);
    this.clntBloodGrp = data.json().blood_desc;
    this.clntBloodID = data.json().blood_group;
    if (data.json().family_guardian != null) 
      this.clntFatherOrGuardName = encrypt_decript.Decript(data.json().family_guardian);
    if (data.json().family_income != null) 
      this.clntIncome = encrypt_decript.Decript(data.json().family_income);
    if (data.json().Occupation != null && data.json().Occupation != "null" && data.json().Occupation != "") 
      this.clntOccupation = encrypt_decript.Decript(data.json().Occupation);
    this.clntAddress1 = encrypt_decript.Decript(data.json().address1);
    if (data.json().address2 != null) 
      this.clntAddress2 = encrypt_decript.Decript(data.json().address2);
    this.clntLocation = data.json().location_desc;
    this.clntCity = data.json().city_desc;
    this.clntState = data.json().state_desc;
    this.clntCountry = data.json().country_desc;
    this.clntLocationId = data.json().location;
    this.clntCityId = data.json().city;
    this.clntStateId = data.json().state;
    this.clntCountryId = data.json().country;
    this.clntZipcode = encrypt_decript.Decript(data.json().zipcode);
    this.clntContactNo = encrypt_decript.Decript(data.json().mobile);
    this.height = data.json().height;
    this.hmeasure = data.json().hmeasure;
    this.weight = data.json().weight;
    this.wmeasure = data.json().wmeasure;
    this.bmi = data.json().bmi;
    this.appId = data.json().app_id;
    this.clntBloodGrp = data.json().blood_group;
    this.referredDoctor = data.json().ref_by;
    this.covidType = data.json().covid_patient;
    this.covidDuration = data.json().covid_dur;
    if(data.json().refer_txt != null && data.json().refer_txt != "null" && data.json().refer_txt != "")
      this.referredBy = data.json().refer_txt;
    this.hospName = data.json().hosp_name;
    this.speclization = data.json().spl_txt;
    this.covidFlag = this.covidType =="yes" ? true : false;
    this.finalCovidSymp = [];
    this.finalcomorbList = [];
    if(data.json().covid_symp != undefined)
      this.finalCovidSymp = data.json().covid_symp;
    if(data.json().covid_comorb != undefined)
      this.finalcomorbList = data.json().covid_comorb;
    if(data.json().covid_observ != undefined){
      this.observList= data.json().covid_observ;
      this.observetable=false;
    }
    if(this.finalCovidSymp!= undefined && this.finalCovidSymp.length !=0){
      if (this.covidSympList.length!=0) {
        for(var i=0;i<this.covidSympList.length;i++){
          for(var j=0;j<this.finalCovidSymp.length;j++){
            if(this.covidSympList[i].symp_id == this.finalCovidSymp[j]){
              this.covidSympList[i].checked=true;
            }
          }
        }
      }
    }
    if(this.finalcomorbList != undefined && this.finalcomorbList.length !=0){
      if (this.comorbList.length!=0) {
        for(var i=0;i<this.comorbList.length;i++){
          for(var j=0;j<this.finalcomorbList.length;j++){
            if(this.comorbList[i].comorb_id == this.finalcomorbList[j]){
              this.comorbList[i].checked=true;
            }
          }
        }
      }
    }
    this.clntFatherOrGuardName = data.json().family_guardian != undefined ? data.json().family_guardian : data.json().care_taker;
    if(this.followFlag == true){
      this.physicalExam = data.json().physical_exam;
      this.temperature = data.json().temparature;
      this.clntSymptom = encrypt_decript.Decript(data.json().symptom);
      this.presentIllness = data.json().present_illness;
      this.referredBy = data.json().refer_txt;
      this.hospName = data.json().hosp_name;
      this.speclization = data.json().spl_txt;
      this.bloodPressure = data.json().blood_pressure;
      this.advice = data.json().advice;
      this.pulse = data.json().pulse;
      this.cvs = data.json().cvs;
      this.rs = data.json().rs;
      this.kidney = data.json().kidney;
      this.spo = data.json().spo2;
      this.arthritisType = data.json().arthritis_type;
      this.deformities = data.json().deformities;
      this.treatment = data.json().treatment;
      this.surgeryType = data.json().surgery_type;
      this.pft=data.json().pft;
      this.surgeryId = data.json().surgery_id;
      this.clntSymptom = data.json().symptom;
      if(data.json().blood_pressure != undefined){
        var bpre = data.json().blood_pressure.split('/');
        this.BP01 = bpre[0];
        this.BP02 = bpre[1]
      }
    }
    if(this.appId != undefined){
      Physio_Helper.setApp_id(this.appId);
    }
     var bpval;
     if(this.BP01 != null && this.BP01 != undefined && this.BP01 != ""){
       bpval = this.BP01+ "/" +this.BP02;
     }
    this.clntLocation = data.json().location_desc;
    this.clntCity = data.json().city_desc;
    this.clntState = data.json().state_desc;
    this.clntCountry = data.json().country_desc;
    var walkin_info:any =[];
    walkin_info ={
       Gender_data:this.clntGender,
       client_name:this.patientName,
       Client_id: this.clientRegId,
       app_id:this.appId,
       Age_data:this.clntAge,
       Case_Clnt_dob:this.clntDOB,
       spl_id: this.splId,
       hptl_clinic_id:this.hospitalId,
       mobile: this.clntContactNo.toString(),
       medicare_name:this.treatementType,
       AppFlow: "Walkin",
       location:this.clntLocation,
        city: this.clntCity,
        state:this.clntState,
        country:this.clntCountry,
        zipcode:this.clntZipcode,
       rel_id: this.relationId,
       sub_id:this.subRelId,
       bp:bpval,
       pulse:this.pulse,
       height:this.height,
       height_measure:this.hmeasure,
       weight: this.weight,
       weight_measure: this.wmeasure,
       bmi: this.bmi,
       bmr:this.bmr,
       temparature:this.temperature,
       symptoms:this.clntSymptom,
       treatment:this.treatment,
       refer_by: this.referredBy,
       cvs : cvs,
       rs : rs,
       kidney : kidney,
       deformities : deformities,
       arthritis_type : arthritis_type,
    };
    Physio_Helper.setWalkin(walkin_info);
  }

  checkClient(){
    if(this.clntContactNo != undefined){
      this.getClientDetails();
    }
  }

  clearMobile(){
    this.clntContactNo=null;
  }

  getTherapyHistory() {
    this.therapyMasterList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'physio/gtherapy',
    JSON.stringify({
      hptl_clinic_id:JSON.parse(this.userInfo.hospitals[0].hptl_clinic_id),
      status: "1",
    }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.therapies != null) {
            for (var i = 0; i < obj.therapies.length; i++) {
              this.therapyMasterList.push({
                type: 'checkbox',
                value: obj.therapies[i].therapy_id,
                label: obj.therapies[i].therapy,
                checked: false
              });
            }
          }
          this.getThetraphist();
        },
        error => {
        }
      )
  }

  getThetraphist(){
    this.therapyhistData = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'search/npbyhs',
    JSON.stringify({
      hptl_clinic_id:JSON.parse(this.userInfo.hospitals[0].hptl_clinic_id),
      type: "physio"
    }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          var name;
          if (obj.physio_nurses != null) {
            for (var i = 0; i < obj.physio_nurses.length; i++) {
              if(obj.physio_nurses[i].middle_name != undefined){
                name = obj.physio_nurses[i].first_name + " " + obj.physio_nurses[i].middle_name +" "+ obj.physio_nurses[i].last_name;
              }else{
                if(obj.physio_nurses[i].last_name != undefined){
                  name = obj.physio_nurses[i].first_name + " " + obj.physio_nurses[i].last_name;
                }else{
                  name = obj.physio_nurses[i].first_name;
                }
              }
              this.therapyhistData.push({
                name: name,
                prov_id: obj.physio_nurses[i].prov_id,
              });
            }
          }
        },
        error => {
        }
      )
  }

  addTherapy(){
    if(this.therapy == undefined || this.sittings == undefined || this.visitFreq == undefined){
      this.toastr.error(Message_data.mandatory);
    } else {
      var therapyname;
      for(var i=0;i<this.therapyMasterList.length;i++){
        if(this.therapyMasterList[i].value == this.therapy){
          therapyname = this.therapyMasterList[i].label;
        }
      }
      this.therapyList.push({
        therapy_id:this.therapy,
        therapy_name:therapyname,
        sittings: this.sittings,
        frequency: this.visitFreq,
        remarks: this.remarks,
        app_time : this.startTime + ":" + this.endTime + " " + this.timeSession,
        start_from : Date_Formate(this.physioStartDate) 
      });
      var thrrapyId = [];
      var startDate = new Date(this.physioStartDate);
      var getMonth = this.physioStartDate.split("-");
      var year =  getMonth[0];
      var month = getMonth[1];
      var day = getMonth[2]-1;
      var nextdate:any;
      var jan312009 = new Date(year, month, day);
      var sessionId = this.session == "Morning" ? 1 : this.session == "Afternoon" ? 1 : 2 ;
      var app_time = this.startTime + ":" + this.endTime;
      for(var i = 0; i < parseInt(this.sittings); i++ ){
        thrrapyId = [];
        if(i == 0){
          nextdate = new Date(startDate.getTime() + 0 * 24 * 60 * 60 * 1000);
        }else{
          nextdate =  this.visitFreq == "Every day" ? new Date(startDate.getTime() + 1 * 24 * 60 * 60 * 1000) : 
                      this.visitFreq == "Alternative days" ? new Date(startDate.getTime() + 2 * 24 * 60 * 60 * 1000) :
                      this.visitFreq == "3 days once" ? new Date(startDate.getTime() + 3 * 24 * 60 * 60 * 1000) : 
                      this.visitFreq == "Once in a week" ?  new Date(startDate.getTime() + 7 * 24 * 60 * 60 * 1000) : 
                      this.visitFreq == "Once in fortnight" ?  new Date(startDate.getTime() + 14 * 24 * 60 * 60 * 1000) : 
                      startDate.setMonth(startDate.getMonth() + 1);
        }
        var validateDat = this.getDateMatePicker(nextdate);
        if( this.physioAppDate.length != 0 ){
          var checkArray =  this.physioAppDate.find(o => o.app_date == validateDat);
          if( checkArray != undefined ){
            if( checkArray.session == this.session &&  checkArray.time == app_time ){
              for( var j=0; j < this.physioAppDate.length; j++){
                  if(checkArray.app_date == this.physioAppDate[j].app_date && checkArray.session == this.physioAppDate[j].session && checkArray.time == this.physioAppDate[j].time){
                    this.physioAppDate[j].therapy_id.push(this.therapy);
                  }
              }
            }else{
              thrrapyId.push(this.therapy)
                this.physioAppDate.push({
                  app_date: validateDat,
                  session : sessionId,
                  app_time : app_time,
                  therapyarray: thrrapyId,
                  remarks : this.remarks,
                });
            }
          }else{
            thrrapyId.push(this.therapy)
            this.physioAppDate.push({
              app_date: validateDat,
              session : sessionId,
              app_time : app_time,
              therapyarray: thrrapyId,
              remarks : this.remarks,
            });
          }
        }else{
          thrrapyId.push(this.therapy)
          this.physioAppDate.push({
            app_date: validateDat,
            session : sessionId,
            app_time : app_time,
            therapyarray: thrrapyId,
            remarks : this.remarks,
          });
        }
        startDate = new Date(validateDat);
      }
    }
  }

  getDateMatePicker(e){
      var month = ((e.getMonth() + 1) < 10 ? '0' : '') + (e.getMonth() + 1);
      var day = (e.getDate() < 10 ? '0' : '') + e.getDate();
      var apt =e.getFullYear()+"-"+ month +"-" + day;
      return apt.trim();
  }

  deleteTherapy(data){
    for(var i=0; i<this.therapyList.length; i++){
      if(this.therapyList[i].therapy_id == data){
        this.therapyList.splice(i,1);
      }
    }
    for(var i=0; i<this.physioAppDate.length; i++){
      for(var j=0; j < this.physioAppDate[i].therapy_id.length; j++ ){
        var index = this.physioAppDate[i].therapy_id.indexOf(data);
          if (index !== -1) {
            if( this.physioAppDate[i].therapy_id.length == 1){
              this.physioAppDate.splice(i,1);
            }else{
              this.physioAppDate[i].therapy_id.splice(index, 1);
            }
          }
      }
    }
  }

  updateTherapy(){
    var therapylistval=[];
    for (var j = 0; j < this.therapyList.length; j++) {
      this.therapyList[j].checked = true;
      therapylistval.push({
        type: 'checkbox',
        value: this.therapyList[j].therapy_id,
        label: this.therapyList[j].therapy_name,
        remarks: this.remarks,
        checked: true
      })
    }
              var headers = new Headers();
              headers.append('Content-Type', 'application/json');
              this.http.post(ipaddress.getIp.toString() + 'physio/scphist',
              JSON.stringify({
                case_hist_id: this.caseHistId,
                therapies: therapylistval,
              }),
                { headers: headers })
                .subscribe(
                  response => {
                    var obj = JSON.parse(response["_body"]);
                    this.toastr.error(obj.result);
                  },
                  error => {
                  }
                )
  }

  selectStartDate(e){
    this.physioStartDate = e;
  }
  
  createAppointment(){
    this.homecare = "0";
    var create_flag=true; var dob;
    if(this.clntContactNo.length != 10){
      create_flag = false;
    }
    if(this.clntAddress1 == null || this.clntAddress1 == undefined || this.clntAddress1 == ""){
      this.clntAddress1 = "";
    }
    if(this.clntAddress2 == null || this.clntAddress2 == undefined || this.clntAddress2 == ""){
      this.clntAddress2 = "";
    }
    if(this.clientType =="New"){    
      if(this.clntContactNo==undefined || this.clntFirstName ==undefined || this.clntLastName == undefined 
        || this.clntGender==undefined || this.clntLocation == null || this.clntLocation == ""){
          create_flag = false;
        }
        if(this.requireFlag == true){
          if(this.clntAge == ""){
            create_flag = false;
          }
        }
    } else {
      if(this.clntContactNo==undefined){
          create_flag = false;
        }
    }
    if(this.physioAppDate == undefined || this.physioAppDate.length == 0){
      create_flag = false;
    }
    if(create_flag == false ){
      if(this.clntContactNo.length != 10){
        this.toastr.error(Message_data.validMobileNo);
      }else if(this.physioAppDate == undefined || this.physioAppDate.length == 0){
        this.toastr.error(Message_data.enterPhysioDet);
      }else{
        this.toastr.error(Message_data.mandatory);
      }
      this.saveFlag = true;
    } else {
      this.saveFlag = false;
      this.patientName = null;
      if (this.clntMiddleName != undefined && this.clntMiddleName != null) {
        if (this.clntMiddleName != "") {
          this.patientName = this.clntFirstName.trim() + " " + this.clntMiddleName.trim() + " " + this.clntLastName.trim();
        } else {
          this.patientName = this.clntFirstName.trim() + " " + this.clntLastName.trim();
        }
      } else {
        this.patientName = this.clntFirstName.trim() + " " + this.clntLastName.trim();
      }
      Physio_Helper.setClient_name(this.patientName);
      Physio_Helper.setClient_gender(this.clntGender);
      Physio_Helper.setClient_age(this.clntAge);
      var apptype;
      if(this.appType =="New"){
        apptype="1";
      } else {
        apptype="2";
      }
      var midname;
      if(this.clntMiddleName !=undefined){
        midname=encrypt_decript.Encript(this.clntMiddleName.trim()).toString();
      } else {
        midname="";
      }
      var occupation;
      if(this.clntOccupation !=undefined){
        occupation = encrypt_decript.Encript(this.clntOccupation.trim()).toString();
      }
      var addr1;
      if(this.clntAddress1 != null){
        addr1 = encrypt_decript.Encript(this.clntAddress1).toString()
      } else {
        addr1 = null;
      }
      var addr2;
      if(this.clntAddress2 != null){
        addr2 = encrypt_decript.Encript(this.clntAddress2).toString()
      } else {
        addr2 = null;
      }
      if(this.clntDOB !=undefined && this.clntDOB !="" && this.clntDOB.length != 0 &&  this.clntDOB !=null){
           dob = this.clntDOB;
      }
      var address:any =[];
      address.push({
        address1:addr1,
        address2:addr2,
        location:this.clntLocationId,
        city:this.clntCityId,
        state:this.clntStateId,
        country:this.clntCountryId,
        zipcode:encrypt_decript.Encript(this.clntZipcode).toString(),
        type:"pres"
      });
      var save_data;
        save_data = JSON.stringify({
          prov_id: this.userInfo.user_id,
          client_id:this.clientRegId,
          relation_id: this.relId,
          salutation: this.clntSal,
          first_name:encrypt_decript.Encript(this.clntFirstName.trim()).toString(),
          middle_name:midname,
          last_name: encrypt_decript.Encript(this.clntLastName.trim()).toString(),
          age: this.clntAge,
          dob: dob,
          gender: encrypt_decript.Encript(this.clntGender).toString(),
          mem_mobile: encrypt_decript.Encript(this.clntContactNo).toString(),
          hptl_id:this.hospitalId,
          date: this.currentDate,
          app_type:apptype,
          symptoms:this.clntSymptom,
          home_care:this.homecare,
          ref_doc : this.referredDoctor,
          occupation: occupation,
          citizenship:encrypt_decript.Encript("Indian").toString(),
          nationality:encrypt_decript.Encript("Indian").toString(),
          address:address,
          hosp_name: this.hospName,
          location:this.hLocation,
          address1:this.hAddress1,
          address2:this.hAddress2,
          city: this.hCity,
          state:this.hState,
          country:this.hCountry,
          zipcode:this.hZipcode,
          date_array : this.physioAppDate
        });
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp.toString() + 'appointment/cpapp', save_data,
          { headers: headers })
          .subscribe(
            response => {
              var obj = response.json();
              if(obj.status != undefined){
                this.saveFlag = false;
                this.toastr.success(Message_data.apptSavedSuccess);
                this.messageService.sendMessage("appointment");
              }else{
                this.saveFlag = true;
              }
            },error =>{
              this.saveFlag = true;
            });
    }
  }
}
