import { Component, OnInit, ViewChild, Input } from '@angular/core';
import {Helper_Class} from '../../helper_class';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DoctorViewService } from '../doctor-view/doctor-view.service';
declare var $ : any; 
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Date_Formate } from '../../../assets/js/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Doc_Helper } from '../Doc_Helper';
import { Helper_nephro_casesheet } from '../../../assets/js/Helper_nephro_casesheet';
import { GynaObestricPopSelectionComponent } from '../gyna-obestric-pop-selection/gyna-obestric-pop-selection.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ipaddress } from '../../ipaddress' ;import { MenuViewService } from '../../../app/common-module/menu-view/menu-view.service';

@Component({
  selector: 'app-casesheet-list',
  templateUrl: './casesheet-list.component.html',
  styleUrls: ['./casesheet-list.component.scss']
})

export class CasesheetListComponent implements OnInit {
  public send_data;
  public case_list_url: string;
  public relation_id:string;
  public sub_rel_id: string;
  public spl_id: string;
  public spl_name: string;
  public hptl_clinic_id;
  public spl_url;
  public user_id;
  public case_hist_id;
  public created_date;
  public created_time;
  public header_footer_flag: boolean;
  public nodapp: boolean;
  public mobile_layout: boolean;

  public casesheet_list=[];
  public personalList;
  public Gynecology_flag: boolean = false;
  public Pediatrics_flag: boolean = false;
  public Cardio_flag: boolean = false;
  public Nephrology_flag: boolean = false;
  public Others_flag: boolean = false;
  public Dental_flag: boolean = false;
  public Diabetes_flag: boolean = false;
  public General_flag: boolean = false;
  public ENT_flag: boolean = false;
  public gastro_flag: boolean=false;
  public userinfo;

  constructor(public dialog: MatDialog,public doctorviewservice:MenuViewService,public http:HttpClient,public routes:ActivatedRoute,public router:Router,public toastr:ToastrService) {
  }

  ngOnInit(): void {
    this.userinfo = Helper_Class.getInfo();
    this.personalList = Doc_Helper.getClient_Info();
    this.spl_id = this.personalList.spl_id;

    if(Helper_Class.getInpatientFlow() == "nurse"){
      this.user_id = this.personalList.user_id;
    }else{
      this.user_id = this.userinfo.user_id;
    }

    if (this.spl_id == "2") {
      this.case_list_url=ipaddress.getIp + "record/clist";

    } else if (this.spl_id == "3") {
      this.case_list_url=ipaddress.getIp + "record/cordlist";

    } else if (this.spl_id == "7") {
      this.case_list_url=ipaddress.getIp + "record/gelist";

    } else if (this.spl_id == "8") {
      this.case_list_url=ipaddress.getIp + "record/ncaselist";

    } else if (this.spl_id == "10") {
      this.case_list_url=ipaddress.getIp + "record/pcaselist";

    } else if (this.spl_id == "11") {
      this.case_list_url=ipaddress.getIp + "record/gcaselist";

    } else if (this.spl_id == "12") {
      this.case_list_url=ipaddress.getIp + "record/gencaselist";

    } else if (this.spl_id == "13") {
      this.case_list_url=ipaddress.getIp + "record/dcaselist";

    } else if (this.spl_id == "29") {
      this.case_list_url=ipaddress.getIp + "record/gencaselist";

    } else if (this.spl_id == "27") {
      this.case_list_url=ipaddress.getIp + "record/entlist";

    }else{
      this.case_list_url = ipaddress.getIp + "record/otherlist";
    }
    
    this.nodapp = true;
    this.getCasesheetList();
  }

  getCasesheetList(){
    this.send_data = {};
    if(this.personalList.sub_id != null && this.personalList.sub_id != undefined && this.personalList.sub_id != ""
    && this.personalList.sub_id.length != 0) {
      this.send_data = {
        doc_reg_id: this.user_id,
        client_reg_id:this.personalList.Client_id,
        relation_id: this.personalList.rel_id,
        sub_rel_id: this.personalList.sub_id,
        country: ipaddress.country_code
      };

    } else {
      this.send_data = {
        doc_reg_id: this.user_id,
        client_reg_id: this.personalList.Client_id,
        relation_id: this.personalList.rel_id,
        country: ipaddress.country_code
      };
    }
    console.log("this.send_data"+JSON.stringify(this.send_data))

    this.casesheet_list=[];
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.case_list_url, this.send_data, { headers: headers }).subscribe(
      data => {
        var dataval = JSON.parse(JSON.stringify(data));
        console.log("this.send_data"+JSON.stringify(dataval.case_history.length))
        this.casesheet_list = [];
        if (dataval.case_history != undefined && dataval.case_history.length != 0) {
          for (var i = 0; i < dataval.case_history.length; i++) {
            var new_datalist = dataval.case_history[i];
            var Docname = "", case_list_text;
            var res_clientname = "";
            if (new_datalist.dr_middle_name != undefined && new_datalist.dr_middle_name != "") {
              Docname = new_datalist.dr_first_name + " " + new_datalist.dr_middle_name + " " + new_datalist.dr_last_name;
            } else {
              Docname = new_datalist.dr_first_name + " " + new_datalist.dr_last_name;
            }

            if(new_datalist.middle_name != undefined){
              if (encrypt_decript.Decript(new_datalist.middle_name) != undefined && encrypt_decript.Decript(new_datalist.middle_name) != "") {
                res_clientname = encrypt_decript.Decript(new_datalist.first_name) + " " + encrypt_decript.Decript(new_datalist.middle_name) + " " + encrypt_decript.Decript(new_datalist.last_name);
              } else {
                res_clientname = encrypt_decript.Decript(new_datalist.first_name) + " " + encrypt_decript.Decript(new_datalist.last_name);
              }
            } else {
              res_clientname = encrypt_decript.Decript(new_datalist.first_name) + " " + encrypt_decript.Decript(new_datalist.last_name);
            }

            var App_date = "", gender, age, case_hist_id, relationship_name, Sub_Special, modified = "0";

            if (new_datalist.created_date != null) {
              App_date = new_datalist.created_date.split('-')[2] + "-" + new_datalist.created_date.split('-')[1] + "-" + new_datalist.created_date.split('-')[0];
            }

            if (new_datalist.modified_date != null) {
              modified = new_datalist.modified_date.split('-')[2] + "-" + new_datalist.modified_date.split('-')[1] + "-" + new_datalist.modified_date.split('-')[0];
            } else {
              modified = "0";
            }

            if (encrypt_decript.Decript(new_datalist.gender) != undefined && encrypt_decript.Decript(new_datalist.gender) != "") {
              gender = encrypt_decript.Decript(new_datalist.gender);
            }

            if ((new_datalist.age) != undefined && (new_datalist.age) != "") {
              age = (new_datalist.age);
            }

            if ((new_datalist.case_hist_id) != undefined && (new_datalist.case_hist_id) != "") {
              case_hist_id = (new_datalist.case_hist_id);
            }

            if ((new_datalist.spl_desc) != undefined && (new_datalist.spl_desc) != "") {
              Sub_Special = (new_datalist.spl_desc);
            }

            if ((new_datalist.relationship_name) != undefined && (new_datalist.relationship_name) != "") {
              relationship_name = (new_datalist.relationship_name);
            }

            if ((new_datalist.relation_id) != undefined && (new_datalist.relation_id) != "") {
              this.relation_id = (new_datalist.relation_id);
            }

            var created_time;
            if ((new_datalist.sub_rel_id) != undefined && (new_datalist.sub_rel_id) != "") {
              this.sub_rel_id = (new_datalist.sub_rel_id);
            }

            if ((new_datalist.created_time) != undefined && (new_datalist.created_time) != "") {
              created_time = new_datalist.created_time;
            }

            if (new_datalist.modified_date != null) {
              case_list_text = "Case sheet for " + res_clientname + " assessed by " + "Dr. " + Docname+" modified on "+ Date_Formate(new_datalist.modified_date);
            } else {
              case_list_text = "Case sheet for " + res_clientname + " assessed by " + "Dr. " + Docname+" on "+App_date;
            }
            //Helper_Class.setClientName(res_clientname);
            
            this.casesheet_list.push({
              case_list_data: case_list_text,
              case_hist_id: case_hist_id,
              modified: modified,
              client_reg_id: this.personalList.Client_id,
              relation_id: this.relation_id,
              sub_rel_id: this.sub_rel_id,
              created_time: created_time,
              gender: gender,
              age: age,
              date: App_date,
              name: res_clientname,
              Sub_Special: Sub_Special,
              DOB: new_datalist.dob,
              hptl_clinic_id:new_datalist.hptl_clinic_id,
              spl_id:new_datalist.spl_id,
              doctor_name:Docname,
            });
            this.nodapp = this.casesheet_list.length !=0 ? true : false;
          }
        } else {
          this.nodapp = false;
        }
      },
      error => {});
  }

  casesheetDetails(caselist){
    var oflag: boolean = false;
    if (this.spl_id == "2") {
      this.Dental_flag = true;
      oflag = true;

    } else if (this.spl_id == "3") {
      this.Cardio_flag = true;
      oflag = true;

    } else if (this.spl_id == "7") {
      this.gastro_flag = true;
      oflag = true;

    } else if (this.spl_id == "8") {
      this.Nephrology_flag = true;
      oflag = true;

    } else if (this.spl_id == "10") {
      this.Pediatrics_flag = true;
      oflag = true;

    } else if (this.spl_id == "11") {
      this.Gynecology_flag = true;
      oflag = true;

    } else if (this.spl_id == "12" || this.spl_id == "29") {
      this.General_flag = true;
      oflag = true;

    } else if (this.spl_id == "13") {
      this.Diabetes_flag = true;
      oflag = true;

    } else if (this.spl_id == "27") {
      this.ENT_flag = true;
      oflag = true;

    } else {
      if (oflag == false) {
        this.Others_flag = true;
      }
    }

    this.spl_id = caselist.spl_id;
    this.hptl_clinic_id = caselist.hptl_clinic_id;
    this.case_hist_id = caselist.case_hist_id;
    this.created_date = caselist.date;
    this.created_time = caselist.created_time;
    Helper_Class.setspl_id(this.spl_id);
    if (this.Gynecology_flag == true) {
      this.Get_gynaretrieval_data();
    }
    if (this.Gynecology_flag == false) {
      this.getcasesheetDetails();
    }
  }

  getCaseSheetFields(hptl_id, spl_id) {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'adm/gpfhsp/',
      {
        hosp_id: hptl_id,
        spl_id: spl_id
      },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          Helper_Class.setHopitalfieldsRet(null);
          Helper_Class.setHopitalfieldsRet(obj.pages);
          console.log("c gpfhspgpfhsp"+JSON.stringify(response))
          this.get_case_sheet_data();
        },//response
      )
  }

  get_case_sheet_data() {
    // call cbook
    Doc_Helper.setDischarge_Summary(undefined);
    var sen_pass
    if (Doc_Helper.getClient_Info().sub_id != null && Doc_Helper.getClient_Info().sub_id != "" && Doc_Helper.getClient_Info().sub_id) {
      sen_pass = {
        doc_reg_id: this.user_id,
        client_reg_id: Doc_Helper.getClient_Info().Client_id,
        relation_id: Doc_Helper.getClient_Info().rel_id,
        sub_rel_id: Doc_Helper.getClient_Info().sub_id,
        country: ipaddress.country_code
      };
    } else {
      sen_pass = {
        doc_reg_id: this.user_id,
        client_reg_id: Doc_Helper.getClient_Info().Client_id,
        relation_id: Doc_Helper.getClient_Info().rel_id,
        country: ipaddress.country_code
      };
    }

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'gen/cbook', sen_pass,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log("c book"+JSON.stringify(response))
          if (obj["key"] != 0) {
            if (obj != null && obj.length != 0 && obj != undefined && obj != "") {
              Doc_Helper.setDischarge_Summary("S");
              Helper_Class.setcasesumret(obj);
            }else {
              Helper_Class.setcasesumret(undefined);
            }
          } else {
            Helper_Class.setcasesumret(undefined);
          }
          if (this.Gynecology_flag == true) {
            const dialogRef = this.dialog.open(GynaObestricPopSelectionComponent, {
              width: '280px',
              height: '182px'
            });
          }
          else {
            this.doctorviewservice.sendMessage("casesheet")
          }
        },
        error => {});
  }

  getcasesheetDetails() {
    Helper_Class.setRet(null);
    Helper_Class.setpediaRet(null);
    
    var send_data;
    var created_date;
    if(this.created_date != null){
      created_date = Date_Formate(this.created_date);
    }
    
    if (Doc_Helper.getClient_Info().sub_id != null && Doc_Helper.getClient_Info().sub_id != undefined &&
      Doc_Helper.getClient_Info().sub_id != "" && Doc_Helper.getClient_Info().sub_id.length != 0) {
       send_data = {
        doc_reg_id: this.user_id,
        client_reg_id: Doc_Helper.getClient_Info().Client_id,
        case_hist_id: this.case_hist_id,
        created_date: created_date,
        created_time:this.created_time,
        relation_id: Doc_Helper.getClient_Info().rel_id,
        sub_rel_id: Doc_Helper.getClient_Info().sub_id,
        country: "IN",
      }
    }else {
      send_data = {
        doc_reg_id: this.user_id,
        client_reg_id: Doc_Helper.getClient_Info().Client_id,
        case_hist_id: this.case_hist_id,
        created_date: created_date,
        created_time:this.created_time,
        relation_id: Doc_Helper.getClient_Info().rel_id,
        country: "IN",
      }
    }
    
    if (this.Pediatrics_flag == true) {
      this.spl_url = 'pedia/chist';

    } else if (this.Cardio_flag == true) {
      this.spl_url = 'cardio/chist';

    } else if (this.gastro_flag == true) {
      this.spl_url = 'gast/chist';

    } else if (this.Nephrology_flag == true) {
      this.spl_url = 'neph/chist';

    } else if (this.Dental_flag == true) {
      this.spl_url = 'dental/perdet/';

    } else if (this.Diabetes_flag == true) {
      this.spl_url = 'diab/chist';

    } else if (this.General_flag == true) {
      this.spl_url = 'gen/chist';

    }else if (this.ENT_flag == true) {
      this.spl_url = 'ent/chist';

    } else {
      this.spl_url = 'other/chist';
    } 
    console.log("RETRIVAL *RR*****"+JSON.stringify(this.spl_url)+JSON.stringify(send_data))

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + this.spl_url, send_data,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          if (obj != null || obj != "" || obj != undefined) {
            Helper_Class.setRet(obj);
            console.log("RETRIVAL ******"+JSON.stringify(this.Nephrology_flag))
            if (this.Pediatrics_flag == true) {
              Helper_Class.setpediaRet(obj);
              console.log("RETRIVAL *RR*****"+JSON.stringify(Helper_Class.getpediaRet()))
            }
            if (this.Nephrology_flag == true) {
              Helper_nephro_casesheet.nephro_get_ret = obj;
            }
            if (this.General_flag == true) {
              if (obj.spl_name != undefined) {
                Helper_Class.setOtherSpl(obj.spl_name);
              }
            }
            console.log("Dental_flag ******"+JSON.stringify(this.Dental_flag))
            if (this.Dental_flag != true) { 
              this.getCaseSheetFields(Doc_Helper.getClient_Info().hptl_clinic_id, Doc_Helper.getClient_Info().spl_id);
            }else{
              this.getCaseSheetFields(Doc_Helper.getClient_Info().hptl_clinic_id, Doc_Helper.getClient_Info().spl_id);
            }
          }
        },//response
        error => {});
  }

  Get_gynaretrieval_data() {
    Helper_Class.setgynaRet(undefined)
    Helper_Class.setRet(undefined)
    var sendata;
    if (Doc_Helper.getClient_Info().sub_id.length != 0 && Doc_Helper.getClient_Info().sub_id !=null && Doc_Helper.getClient_Info().sub_id != undefined) {
      var sub_id_data = Doc_Helper.getClient_Info().sub_id;
      sendata = {
        doc_reg_id: this.user_id,
        client_reg_id: Doc_Helper.getClient_Info().Client_id,
        relation_id: Doc_Helper.getClient_Info().rel_id,
        sub_rel_id: sub_id_data,
        country: ipaddress.country_code
      };
    } else {
      sendata = {
        doc_reg_id: this.user_id,
        client_reg_id: Doc_Helper.getClient_Info().Client_id,
        relation_id: Doc_Helper.getClient_Info().rel_id,
        country: ipaddress.country_code
      }
    }

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'goi/chist', sendata,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          Helper_Class.setgynaRet(obj);
          Helper_Class.setRet(obj);
        },
        error => {});
    this.getCaseSheetFields(Doc_Helper.getClient_Info().hptl_clinic_id, Doc_Helper.getClient_Info().spl_id);
  }

  backFunction(){
    this.doctorviewservice.sendMessage("appointment_list")
  }

  viewCasesheet(caselist){
    var send_data={
      case_hist_id: caselist.case_hist_id,
      relation_id: caselist.relation_id,
      modified: caselist.modified,
      date: caselist.date,
      sub_rel_id: caselist.sub_rel_id,
      gender: caselist.gender,
      age: caselist.age,
      created_time: caselist.created_time,
      casesheet: this.personalList.spl,
      spl_id: this.spl_id,
      name:caselist.name
    }
    Doc_Helper.setViewCasesheet(send_data);
    this.doctorviewservice.sendMessage("cardio_casesheet_view");
  }

  createCasesheet(){
    var oflag:boolean = false
    if (this.spl_id == "2") {
      this.Dental_flag = true;
      oflag = true;

    } else if (this.spl_id == "3") {
      this.Cardio_flag = true;
      oflag = true;

    } else if (this.spl_id == "7") {
      this.gastro_flag = true;
      oflag = true;

    } else if (this.spl_id == "8") {
      this.Nephrology_flag = true;
      oflag = true;

    } else if (this.spl_id == "10") {
      this.Pediatrics_flag = true;
      oflag = true;

    } else if (this.spl_id == "11") {
      this.Gynecology_flag = true;
      oflag = true;

    } else if (this.spl_id == "12" || this.spl_id == "29") {
      this.General_flag = true;
      oflag = true;

    } else if (this.spl_id == "13") {
      this.Diabetes_flag = true;
      oflag = true;

    } else if (this.spl_id == "27") {
      this.ENT_flag = true;
      oflag = true;

    } else {
      if (oflag == false) {
        this.Others_flag = true;
      }
    }
    var casedetails = Doc_Helper.getClient_Info();
    this.getCaseSheetFields(Doc_Helper.getClient_Info().hptl_clinic_id, Doc_Helper.getClient_Info().spl_id);
  }
}
