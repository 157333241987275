<div class="row" style="width:100%;margin:0 auto;">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Inward</h5>
                    </div>
                    <div class="headerButtons" *ngIf="viewOrderFlag && drugDetailsArray.length != 0">
                        <img *ngIf="saveFlag" src="../../../assets/ui_icons/buttons/save_button.svg"
                            class="saveimgbtn_inpatinfo" (click)="save()">

                            <img *ngIf="saveFlag" [hidden]="printerflag" printSectionId="print" (click)="printArea()" src="../../../assets/img/printer.svg" class="saveimgbtn_inpatinfo" style="width:25px">
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>
                <!-- <div class="row">
              
                    <div class="col-12">
                      <div class="switch-field" *ngIf="ynopt1 != undefined">
                        <input type="radio" id="radio-three" (click)="change_type(1,'yes')" />
                        <label for="radio-three" [ngClass]="ynopt1 ? 'radio_active':'radio_inactive'">
                          <div class="tab_cover">
                            <div class="tab_icon_cover"><img src="../../../assets/ui_icons/Outpatient.svg" class="iocinStyle"
                                alt=""></div>
                            <div class="lableStyle">Out-patient</div>
                          </div>
                        </label>
                        <input type="radio" id="radio-four" (click)="change_type(1,'no')" />
                        <label for="radio-four" [ngClass]="!ynopt1 ? 'radio_active':'radio_inactive'">
                          <div class="tab_cover">
                            <div class="tab_icon_cover"><img src="../../../assets/ui_icons/Inpatient.svg" class="iocinStyle"
                                alt=""></div>
                            <div class="lableStyle">In-patient</div>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div> -->

                <div class="row">
                    <!-- <div class="col-12 col-sm-6 col-md-4 col-xl-3 col-lg-3">
                       
                    </div> -->

                    <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2 select_bottom">
                        <mat-label class="matlabel">Filter by<br>
                          <select required class="ipcss" [(ngModel)]="filter" required (change)="changeFilter()"
                                  disableOptionCentering>                               
                            <!-- <option value="Doctor">Doctor</option> -->
                            <option value="bill_no">Bill no</option>
                            <option value="Date">Date</option>
                            <option value="Name">Name</option>
                            <option value="Patient_ID">MR no</option>
                            <option value="Mobile_No">Mobile No</option>
                          </select>
                        </mat-label>
                      </div>
                     
                      <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2 select_bottom">
                        <!-- <mat-label class="matlabel" [hidden]="doctorFlag">Doctor<br>
                          <select required class="ipcss" [hidden]="doctorFlag" (change)="getAppointments()" [(ngModel)]="docRegId" disableOptionCentering>
                            <option *ngFor="let doctors of doctorList" value="{{doctors.doc_id}}"> {{doctors.doc_name}} </option>
                          </select>
                        </mat-label> -->
                        <mat-label class="matlabel" [hidden]="billnoFlag">Bill Number
                            <input type="text" class="ipcss" [(ngModel)]="orderNo" matInput />
                        </mat-label>

                        <mat-label class="matlabel" [hidden]="toFlag">From Date<br>
                          <input type="date" class="ipcss widthbilllist datecss" (change)="selectFromDate(fromDate)"
                            [hidden]="toFlag" [(ngModel)]="fromDate" #matInput max="{{currentDate}}">
                        </mat-label>

                        <!-- <mat-label class="matlabel" [hidden]="toFlag">To Date<br>
                          <input type="date" class="ipcss widthbilllist datecss" (change)="selectFromDate(fromDate)"
                            [hidden]="toFlag" [(ngModel)]="toDate" #matInput max="{{currentDate}}">
                        </mat-label> -->
                      
                        <mat-label class="matlabel" [hidden]="firstNameFlag">First name<br>
                          <input matInput class="ipcss widthbilllist" type="text" [hidden]="firstNameFlag" required
                            [(ngModel)]="firstName" (blur)="fnameToUpper()" style="margin-bottom: 10px;" />
                        </mat-label>
                        
                        <mat-label class="matlabel" [hidden]="uhidFlag">MR.No<br>
                          <input matInput class="ipcss widthbilllist" type="text" [hidden]="uhidFlag" required
                            [(ngModel)]="patientId" />
                        </mat-label>
            
                        <mat-label class="matlabel" [hidden]="mobileFlag">Mobile No<br>
                          <input matInput class="ipcss widthbilllist" type="text" [hidden]="mobileFlag" required
                            [(ngModel)]="mobileNo" />
                        </mat-label>                            
                       </div>
                      <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2 select_bottom" [hidden]="firstNameFlag"> 
                        <mat-label class="matlabel" [hidden]="firstNameFlag">Last name<br>
                          <input matInput class="ipcss widthbilllist" type="text" [hidden]="firstNameFlag" required
                            [(ngModel)]="lastName" (blur)="lnameToUpper()" />
                        </mat-label>
                       </div>



                    <div class="col-12 col-sm-6 col-md-4 col-xl-3 col-lg-3">
                        <img src="../../assets/img/search.png" *ngIf="this.filter == 'bill_no'" (click)="searchByOrderNo(this.orderNo)" class="seacrhicon" width="25"
                            height="auto" class="searchIcon" />
                            <img src="../../assets/img/search.png" *ngIf="this.filter != 'bill_no'"  (click)="searchBill()" class="seacrhicon" width="25"
                            height="auto" class="searchIcon" data-bs-toggle="modal" data-bs-target="#billdetails"/>    
                        
                  </div>
                  <div class="modal fade" id="billdetails" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                  aria-hidden="true">
                  <div class="modal-dialog modal-md">
                    <div class="modal-content" style="width:165%">
                      <div class="modal-header">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                        </button>
                      </div>
                      <div class="modal-body">
                        <div class="col-12">
                          <div class="patientdetails-responsive">
                           <table class="patientdetailstable">
                              <thead>
                                <tr style="background-color:#c1e6fb;color: rgba(0,0,0,.54);min-height: 56px;">
                                  <th class="delete" style="width:15%">Bill no</th>
                                  <th style="width:20%">Date</th>
                                  <th style="width:20%">Client name</th>
                                  <th style="width:20%">Pay type</th>
                                  <th>Amount</th>
                                  <th>Paid</th>
                                  <th>Balance</th>
                                  <th>Status</th>
                                 </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let bill of billList"  (click)="searchByOrderNo(bill.bill_id)"  data-bs-dismiss="modal" aria-label="Close">
                                  <td >
                            
                                      {{bill.bill_id}}
                                    </td>
                                    <td >
                                        
                                      {{bill.date}}
                                    </td>
                                    <td >
                                        
                                      {{bill.salutation}} {{bill.client_name}}</td>
                                    <td >
                                        {{bill.pay_type}}
                                    </td>
                                    <td>{{bill.bill_amount}}</td>
                                    <td>{{bill.paid_amount}}</td>
                                    <td>{{bill.balance}}</td>
                                    <td>
                                        {{bill.paid_flag}}
                                    </td>
                                </tr>                          
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                       
                   
                    <div class="col-12" *ngIf="viewOrderFlag && drugDetailsArray.length != 0">
                        <div class="cover_div">
                            <div class="header_lable">
                                Billing details
                            </div>
                            <div class="content_cover">
                                <div class="row">
                                    <!-- <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                        <mat-label class="matlabel"> <b>{{OrderByName}}</b></mat-label><br>
                                        <mat-label class="matlabel">Bill number :{{orderNumber}}</mat-label><br>
                                        <mat-label class="matlabel">Bill type :{{PurchaseType}}</mat-label><br>
                                        <mat-label class="matlabel" [hidden]="preferredTimeFlag">Preferred time
                                            :{{totalTime}}</mat-label><br>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                        <mat-label class="matlabel"> Bill date :{{orderDate}}</mat-label><br>
                                        <mat-label class="matlabel">Bill time :{{orderTime}}</mat-label><br>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                      <mat-label class="matlabel"> Bill date :{{orderDate}}</mat-label><br>
                                      <mat-label class="matlabel">Bill time :{{orderTime}}</mat-label><br>
                                  </div>
                                  <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                    <mat-label class="matlabel"> Bill date :{{orderDate}}</mat-label><br>
                                    <mat-label class="matlabel">Bill time :{{orderTime}}</mat-label><br>
                                </div> -->

                                <div class="col-3">
                                  <p class="textformat"> <span style="font-weight: 500;">MR No:</span> {{mr_no}}  </p>
                                 
                                </div>                 
                                <!-- {{this.clntDOB}}   {{this.clntEmergNumber}} {{this.clntEmergNumber1}} {{this.aadharNumber}} {{this.whatsappNumber}} -->
                                <div class="col-3">
                                  <p class="textformat"> <span style="font-weight: 500;">Patient name: </span> {{salutation}}.&nbsp;{{OrderByName}}</p>
                                </div>
                                <div class="col-3">
                                  <p class="textformat"> <span style="font-weight: 500;">Age/Gender: </span> {{clntAge}}/{{clntGender}}</p>
                                </div>
                                <div class="col-3">
                                  <p class="textformat"> <span style="font-weight: 500;">Doctor: </span><span>{{drsal}}. </span> {{doctorName}}</p>
                                </div>
                                <div class="col-3" >
                                  <p class="textformat"> <span style="font-weight: 500;">Bill no: </span> {{orderNumber}}</p>
                                </div>
                                <div class="col-3">
                                  <p class="textformat"><span style="font-weight: 500;">Bill type: </span> {{PurchaseType}}</p>
                                </div>
                                <div class="col-3">
                                  <p class="textformat"><span style="font-weight: 500;">Payment type: </span> {{paytype}}</p>
                                </div>
                             
                                <div class="col-3">
                                  <p class="textformat"><span style="font-weight: 500;">Bill date/time: </span> {{orderDate}}/{{orderTime}} </p>
                                </div>
          
                                <div class="row">
                                  <div class="col-2">
                                    <span> <span style="font-weight: 500;">Rate plan: </span> </span>
                                  </div>
                                  <div class="col-6">
                                     
                                    <select style="width:125px" disableOptionCentering class="ipcss " required
                                      [(ngModel)]="discountType" (change)="discountChange(discountType)">
                                      <option disabled>Select</option>
                                      <option *ngFor="let disc of discountDatasArray" value="{{disc.disc_type_id}}">
                                        {{disc.description}} ({{disc.discount_type}})</option>
                                    </select>
            
            
                                    <!-- <mat-label class="matlabel">Rate plan: <br>
                                        <select disableOptionCentering class="ipcss " required [(ngModel)]="discountType"
                                          (change)="discountChange(discountType)">
                                          <option disabled>Select</option>
                                          <option *ngFor="let disc of discountDatasArray" value="{{disc.disc_type_id}}">{{disc.description}}
                                          </option>
                                        </select>
                                      </mat-label> -->
            
            
            
                                  </div>
                                </div>


                                </div>
                            </div>
                        </div>
                    </div>
                
                </div>

                <div class="row" *ngIf="viewOrderFlag && drugDetailsArray.length != 0">
                    <!-- <div class="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-2">
                        <mat-label class="matlabel">Payment type<br>
                            <mat-select id="paytype" class="ipcss  widthbillcreate" [(ngModel)]="payType"
                                (selectionChange)="pay_change()" disableOptionCentering>
                                <mat-option *ngFor="let pay of payTypeArray" value={{pay.pay_id}}>{{pay.pay_desc}}
                                </mat-option>
                            </mat-select>
                        </mat-label>
                    </div> -->
                    <div class="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-2" [hidden]="insurFlag">
                        <mat-label class="matlabel">Insurer<br>
                            <mat-select required class="ipcss  widthbillcreate" [(ngModel)]="insurerID"
                                disableOptionCentering>
                                <mat-option *ngFor="let ins of insurerArray" value={{ins.insurer_id}}>
                                    {{ins.insurer_desc}}</mat-option>
                            </mat-select>
                        </mat-label>
                    </div>
                    <div class="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-2" [hidden]="cardFlag">
                        <mat-label class="matlabel">Card No <br>
                            <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardNumber" />
                        </mat-label>
                    </div>
                    <div class="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-2" [hidden]="cardFlag">
                        <mat-label class="matlabel">Holder name<br>
                            <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardHolderName" />
                        </mat-label>
                    </div>
                    <div class="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-2" [hidden]="tranFlag">
                        <mat-label class="matlabel">Transaction ID<br>
                            <input class="ipcss  widthbillcreate" matInput [(ngModel)]="transactionID" />
                        </mat-label>
                    </div>
                    <div class="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-2" [hidden]="chequeFlag">
                        <mat-label class="matlabel">Cheque No <br>
                            <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardNumber" />
                        </mat-label>
                    </div>
                    <div class="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-2" [hidden]="chequeFlag">
                        <mat-label class="matlabel">Bank Name <br>
                            <input class="ipcss  widthbillcreate" matInput [(ngModel)]="bankName" />
                        </mat-label>
                    </div>
                    <div class="col-12" style="margin-top: 15px;" *ngIf="drugDetailsArray.length != 0">
                        <div class="dig_table_overflow" style="margin-bottom: 20px;">
                            <div class="table-resposive">
                                <table cellspacing="0" cellpadding="0" 
                                    class="table table-nowrap table-sm pharmacycurrentorder">
                                    <thead class="tableheading">
                                        <tr>
                                            <th>Medicine type</th>
                                            <th>Medicine name</th>
                                            <th style="width: 12%;">Batch</th>
                                            <th style="width: 8%;">Quantity</th>
                                            <th>Price</th>
                                            <th> Discount (%)</th>
                                            <th>CGST</th>
                                            <th>SGST</th>
                                            <th> Amount</th>
                                            <th> Action</th>
                                            <!-- [hidden]="!orderType" -->
                                        </tr>
                                    </thead>
                                    <tbody>
                                        
                                        <tr *ngFor="let medpre of drugDetailsArray">
                                            <td style="text-align: left;">{{medpre.type_name}}</td>
                                            <td style="text-align: left;">{{medpre.drug_name}}</td>
                                            <td>
                                                <p [hidden]="medpre.batch_hide">{{medpre.batch_no}}</p>
                                                <!-- <select [(ngModel)]="medpre.batch_no" class="ipcss " required
                                                        style="margin-top: 10px;" (change)="retrieveBatch(medpre)">
                                                        <option *ngFor="let batch of medpre.batchnos" value={{batch}}>
                                                            {{batch}}</option>
                                                    </select> -->
                                            </td>
                                            <td>
                                                <input type="text" matInput class="ipcss " [(ngModel)]="medpre.quantity"
                                                    (focus)="clearField(medpre)"
                                                    style="width: 65%;margin: 0 auto !important;" maxlength="3"
                                                    [hidden]="confirmFlag" class="ipcss "
                                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                    (keyup)="CalculatePrice(medpre.drug_id,medpre.type_name,medpre.drug_name,medpre.quantity,medpre.price,medpre.per_unit,medpre.discount,medpre.prod_gst)">
                                                <p [hidden]="!confirmFlag">{{medpre.quantity}} </p>
                                            </td>
                                            <td>
                                                <p style="float: right; margin-right: 10px;">{{medpre.price}} </p>
                                            </td>
                                            <td>
                                                <p style="float: right; margin-right: 10px;">{{medpre.discount}}
                                                </p>
                                            </td>
                                            <td>
                                                <p style="float: right; margin-right: 10px;">{{medpre.cgst}}
                                                </p>
                                            </td>
                                            <td>
                                                <p style="float: right; margin-right: 10px;">{{medpre.sgst}}
                                                </p>
                                            </td>
                                            <td>
                                                <p style="float: right; margin-right: 10px;">{{medpre.amount}}</p>
                                            </td>
                                            <td style="text-align: center;">
                                                <!-- <a (click)="deleteMedicine(medpre.drug_id)">
                                                    <img src="../../../assets/ui_icons/buttons/trash_icon.svg"
                                                        width="20" height="20" /></a> -->
                                                        <img *ngIf="medpre.checked == false" (click)="isAllSelected(medpre,'select')" src="../../../assets/ui_icons/yellow_box.svg" style="cursor: pointer;"  width="15px" />
                                                        <img *ngIf="medpre.checked == true" (click)="isAllSelected(medpre,'unselect')" src="../../../assets/ui_icons/popup_tick_mark.svg" style="cursor: pointer;"  width="15px" />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="viewOrderFlag && drugDetailsArray.length != 0">
                    <div class="col-12">
                        <table class="price_table" style="float:right ;">
                          <tr style="position: relative;">
                            <td>
                                <mat-label>
                                    <b>Total amount</b>
                                    <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                        style="width: 10px; height: 10px;" />
                                </mat-label>
                            </td>
                            <td>
                                <mat-label class="matlabel">
                                    <p class="bill_amt_style" style="text-align: center;">{{this.actualamount}}</p>
                                </mat-label>
                            </td>
                        </tr>
                          <tr style="position: relative;">
                            <td>
                                <mat-label>
                                    <b>Discount</b>
                                    <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                        style="width: 10px; height: 10px;" />
                                </mat-label>
                            </td>
                            <td>
                                <mat-label class="matlabel">
                                    <p class="bill_amt_style" style="text-align: center;">{{this.prodDiscount}}</p>
                                </mat-label>
                            </td>
                        </tr>
                        <tr style="position: relative;">
                          <td>
                              <mat-label>
                                  <b>GST</b>
                                  <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                      style="width: 10px; height: 10px;" />
                              </mat-label>
                          </td>
                          <td>
                              <mat-label class="matlabel">
                                  <p class="bill_amt_style" style="text-align: center;">{{this.GSTamount}}</p>
                              </mat-label>
                          </td>
                      </tr>  
                          <tr style="position: relative;">
                                <td>
                                    <mat-label>
                                        <b>Refund amount</b>
                                        <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                            style="width: 10px; height: 10px;" />
                                    </mat-label>
                                </td>
                                <td>
                                    <mat-label class="matlabel">
                                        <p class="bill_amt_style" style="text-align: center;">{{totalvalue}}</p>
                                    </mat-label>
                                </td>
                          </tr>

                          <tr style="position: relative;" *ngIf="this.pendingamt != 0.00">
                            <td>
                                <mat-label>
                                    <b>Pending amount</b>
                                    <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                                        style="width: 10px; height: 10px;" />
                                </mat-label>
                            </td>
                            <td>
                                <mat-label class="matlabel">
                                    <p class="bill_amt_style" style="text-align: center;">{{this.pendingamt}}</p>
                                </mat-label>
                            </td>
                      </tr>
                        </table>
                    </div>
                </div>
           
                <!-- <p class="nodata" *ngIf="billList.length == 0">No records found</p>  -->
                 <!-- <table *ngIf="billList.length" mdbTable datatable [dtOptions]="dtOptions" class="table table-nowrap table-sm billlisttable">
                    <thead>
                      <tr>
                        <th class="delete">Bill no</th>
                        <th>Date</th>
                        <th>Client name</th>
                        <th>Pay type</th>
                        <th>Paid</th>
                        <th>Balance</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let bill of billList; let i = index">
                        <td style="font-size: 12px;text-align: center;">
                          
                          {{bill.bill_id}}
                        </td>
                        <td style="font-size: 12px;text-align: center;">
                            
                          {{bill.date}}
                        </td>
                        <td style="font-size: 12px;text-align: left !important;">
                            
                          {{bill.salutation}} {{bill.client_name}}</td>
                        <td style="font-size: 12px;text-align: right;">
                          {{bill.}}
                        </td>
                      
                      </tr>
                    </tbody>
                  </table> -->


                  <div [hidden]="true">

       
                    
            <div #logowithname id="logowithname"
            style="background-image: url({{bgImage}}); background-position: center;">
            <table class="report-container" style="width: 100%;">
              <thead class="report-header">
                <tr>
                  <td style="display: flex;justify-content: center;">
                    <div>
                      <img alt="image" src={{pharmaLogo}} width="100px" height="100px"
                        style="vertical-align: middle;">
                    </div>
                    <div style="position: relative;top: 20px;left: 10px;text-align: left;">
                      <p><b>{{pharmaName}}</b>,</p>
                      <span *ngIf="pharmaAddress1 != undefined">{{pharmaAddress1}},</span><span
                        *ngIf="pharmaAddress2 != undefined">{{pharmaAddress2}},</span><br />
                      <span>{{location}},</span> <span>{{city}},</span>
                      <span>{{state}},</span><span>{{country}}.</span><br>
                      <p><span>DL No : {{dlNO}} &nbsp;&nbsp;</span><span>GST : {{GSTNo}}</span></p>
                    </div>
                  </td>
                </tr>
              </thead>
              <tbody class="report-content">
                <tr>
                  <td class="report-content-cell">
                    <div class="main">
                      
                      <!-- <table style="margin-left: 75px;margin-top: 20px;">
                        <td>
                          <p>Name :{{clientName}}</p>
                          <p #billid id="billid"></p>
                          <p>Bill type :{{PurchaseType}}</p>
                        </td>
                        <td style="width: 250px;"></td>
                        <td>
                          <p>Bill date :{{orderDate | date: 'dd-MM-yyyy'}}</p>
                          <p>Bill time :{{orderTime}}</p>
                        </td>
                      </table> -->

                      <table style="margin-left:20px;width: 100%;">
                        <tr>
                          <td style="width: 50%;vertical-align: top;">
                            <div class="row">
                              <div class="col-12 col-md-7 col-lg-8 col-xl-8">
                                <table>
                                  <tr>
                                    <th style="text-align: left !important;width: 104px !important;vertical-align: top;"><span><strong>Patient name</strong></span></th>                            
                                    <td style="text-align: left !important;width: 5px !important;vertical-align: top;">
                                      : 
                                    </td>
                                    <td style="text-align: left !important;">
                                      {{OrderByName}}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th style="text-align: left !important;width: 104px !important;"><span><strong>Age/Gender</strong></span></th>    
                                    <td style="text-align: left !important;width: 5px !important;">
                                      : 
                                    </td>                        
                                    <td style="text-align: left !important;">
                                     {{clntAge}}/{{clntGender}}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th style="text-align: left !important;width: 104px !important;"><span><strong>Doctor</strong></span></th>  
                                    <td style="text-align: left !important;width: 5px !important;">
                                      : 
                                    </td>                           
                                    <td style="text-align: left !important;">
                                      {{this.drsal}}   {{doctorName}}
                                    </td>
                                  </tr>
                                 
                                
                                
                                </table>    
                              </div>
                            </div>
                          </td>
                          <td style="width: 50%;vertical-align:top">
                            <div class="row">
                              <div class="amount_due col-12 col-md-5 col-lg-4 col-xl-4" style="margin-right: 20px;" >
                                <table>
                                  <tr>
                                    <th style="text-align: left !important;width: 104px !important;"><span><strong>Bill No</strong></span></th> 
                                    <td style="text-align: left !important;width: 5px !important;">
                                      : 
                                    </td>                            
                                    <td style="text-align: left !important;">
                                      {{this.orderNumber}} 
                                    </td>
                                  </tr>
                                  <tr>
                                    <th style="text-align: left !important;width: 110px !important;"><span><strong>Bill Date/Time</strong></span></th> 
                                    <td style="text-align: left !important;width: 5px !important;">
                                      : 
                                    </td>                            
                                    <td style="text-align: left !important;">
                                      {{this.dateformate}} {{this.orderTime}}
                                    </td>
                                  </tr>
                                 
                                  <tr *ngIf="patienttype != 'undefined'">
                                    <th style="text-align: left !important;width: 110px !important;"><span><strong>Patient type</strong></span></th> 
                                    <td style="text-align: left !important;width: 5px !important;">
                                      : 
                                    </td>                            
                                    <td style="text-align: left !important;">
                                      {{ this.patienttype}} 
                                    </td>
                                  </tr>
                                 
         
                                </table>                            
                              </div>
                            
                            </div>
                          </td>
                        </tr>
                      </table>

                      <br />
                      <table style="border: 1px solid black;border-collapse: collapse;width: 95%;margin-right: auto;margin-left:auto">
                        <thead>
                              <tr style="border:1px solid black;">
                                <th style="border:1px solid black;width:6%">Si.No</th>
                                <th style="border:1px solid black;width:15%">Medicine name</th>
                                <th style="border:1px solid black;width:10%">Qty</th>
                                <th style="border:1px solid black;width:10%">Batch No</th>
                                <th style="border:1px solid black;">Exp date</th>
                                <th style="border:1px solid black;">Price</th>
                                <th style="border:1px solid black;">CGST(%)</th>
                                <th style="border:1px solid black;">SGST(%)</th>
                                
                                <th style="border:1px solid black;"> Amount</th>
                              </tr>
                        </thead>
                        <tbody>                   
                              <tr *ngFor="let billOrder of insurerArray" style="border:1px solid">
                                <td style="border:1px solid black;width:6%">{{billOrder.sno}}</td>
                                <td style="border:1px solid black;"> {{billOrder.drug_disp_name}} </td>
                                <td style="border:1px solid black;"><p style="float: right; margin-right: 10px;"> {{billOrder.quantity}} </p></td>
                                <td style="border:1px solid black;"><p style="float: right; margin-right: 10px;"> {{billOrder.batch_no}} </p></td>
                                <td style="border:1px solid black;"><p style="float: right; margin-right: 10px;"> {{billOrder.expiry_date }}</p></td>
                                <td style="border:1px solid black;"><p style="float: right; margin-right: 0px;"> {{billOrder.price }}</p></td>
                                <td style="border:1px solid black;"><p style="float: right; margin-right: 0px;"> {{billOrder.cgst}} </p></td>
                                <td style="border:1px solid black;"><p style="float: right; margin-right: 0px;"> {{billOrder.sgst}} </p></td>
                                <td style="border:1px solid black;text-align:right"><p style="float: right; margin-right: 0px;"> {{billOrder.amount}} </p></td>
                              </tr>
                        </tbody>                                                      
                      </table>

                      <div class="bill_border">
                        <div>
                             
                         
                          <div style="width: 100%;">
                           
                            <div class="row" >
                            <div class="col-6">
                              <!-- class="col-3 col-md-5 col-lg-3 col-xl-3"  -->
                              <table style="margin-left:30px">
                                <tr>
                                  <td><strong>In Words:</strong>&nbsp;Received Rupees {{numToWords}} &nbsp;&nbsp;Only</td>
                                </tr>
                              </table>
                              </div>
                            
                            <div class="col-6" style="margin-left:auto">
                            <table>
                              <tr>
                                                      
                                <td style="vertical-align: top;width: 31%;">
                                  <table  style="float: right;margin-left: 110px;margin-right: 25px;" >             
                                    
                                      
                                    <tr [hidden]="" > 
                                      <th style="text-align: left;">Total (र)</th>
                                      <td style="text-align: right;width: 100px;" data-th="Previous balance र"
                                        class="fee_txt">
                                        {{this.actualamount}}
                                      </td>
                                    </tr>  
                                    <tr >
                                      <th style="text-align: left;">Discount <span [hidden]=""></span> (र) </th>
                                      <td style="text-align: right;width: 100px;" data-th="Total र" class="fee_txt">
                                        {{this.prodDiscount}}
                                      </td>
                                    </tr>
                                    <tr >
                                      <th style="text-align: left;">GST(र) </th>
                                      <td style="text-align: right;width: 100px;" data-th="Discount र" class="fee_txt">
                                        {{this.GSTamount}}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th style="text-align: left;">Refund Amount (र)</th>
                                      <td style="text-align: right;width: 100px;" data-th="Bill amount"
                                        class="fee_txt">
                                        {{totalvalue}}
                                      </td>
                                    </tr>
                                    <tr *ngIf="pendingamt != 0.00">
                                      <th style="text-align: left;">Balance (र)</th>
                                      <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                        class="fee_txt">
                                        {{pendingamt}}
                                      </td>
                                    </tr>
                                   
                                  </table>
                               
                                </td>                        
                              </tr>
                            </table>
                            </div>
                            </div>

                          </div> 
                          <!-- <div  style="width: 100%;margin-left: 20px;margin-top: 15px;">
                            <div class="row">
                            
                            </div>
                          </div> -->
                      
                        </div>
                      </div>

                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div>
            </div>
          </div>

                    <div [hidden]="true" #printbanner id="printbanner">
                      <table style="border:'0';width:100%">  
                        <thead>  
                         <tr>  
                          <th style="width:100%;height: 100px;">
                            <div>
                              <table style="width: 100%;">
                                  <tr>
                                    <td style="display: flex;justify-content: center;">
                                      <div>
                                        <img alt="image" src={{this.pharmaLogo}}>
                                      </div>
                                    </td>
                                  </tr>
                              </table>
                            </div>
                          </th>  
                        </tr>  
                       </thead>  
                       <tfoot> 
                        <tr>  
                         <td width="100%">  
                       </tfoot> 
                       <tbody>  
                         <!-- <tr>  
                           <td width="100%">  
                                                 
                           </td>
                         </tr> -->
                         <div class="main">
                          <div style="display: flex; justify-content: space-between; width: 100%;margin-left:15px;margin-top: 10px;border-radius: 10px;">
                       
                        
                         <table style="margin-left:20px;width: 100%;">
                          <tr>
                            <td style="width: 50%;vertical-align: top;">
                              <div class="row">
                                <div class="col-12 col-md-7 col-lg-8 col-xl-8">
                                  <table>
                                    <tr>
                                      <th style="text-align: left !important;width: 104px !important;vertical-align: top;"><span><strong>Patient name</strong></span></th>                            
                                      <td style="text-align: left !important;width: 5px !important;vertical-align: top;">
                                        : 
                                      </td>
                                      <td style="text-align: left !important;">
                                        {{OrderByName}}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th style="text-align: left !important;width: 104px !important;"><span><strong>Age/Gender</strong></span></th>    
                                      <td style="text-align: left !important;width: 5px !important;">
                                        : 
                                      </td>                        
                                      <td style="text-align: left !important;">
                                       {{clntAge}}/{{clntGender}}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th style="text-align: left !important;width: 104px !important;"><span><strong>Doctor</strong></span></th>  
                                      <td style="text-align: left !important;width: 5px !important;">
                                        : 
                                      </td>                           
                                      <td style="text-align: left !important;">
                                        {{this.drsal}}   {{doctorName}}
                                      </td>
                                    </tr>
                                   
                                  
                                  
                                  </table>    
                                </div>
                              </div>
                            </td>
                            <td style="width: 50%;vertical-align:top">
                              <div class="row">
                                <div class="amount_due col-12 col-md-5 col-lg-4 col-xl-4" style="margin-right: 20px;" >
                                  <table>
                                    <tr>
                                      <th style="text-align: left !important;width: 104px !important;"><span><strong>Bill No</strong></span></th> 
                                      <td style="text-align: left !important;width: 5px !important;">
                                        : 
                                      </td>                            
                                      <td style="text-align: left !important;">
                                        {{this.orderNumber}} 
                                      </td>
                                    </tr>
                                    <tr>
                                      <th style="text-align: left !important;width: 110px !important;"><span><strong>Bill Date/Time</strong></span></th> 
                                      <td style="text-align: left !important;width: 5px !important;">
                                        : 
                                      </td>                            
                                      <td style="text-align: left !important;">
                                        {{this.dateformate}} {{this.orderTime}}
                                      </td>
                                    </tr>
                                   
                                    <tr *ngIf="patienttype != 'undefined'">
                                      <th style="text-align: left !important;width: 110px !important;"><span><strong>Patient type</strong></span></th> 
                                      <td style="text-align: left !important;width: 5px !important;">
                                        : 
                                      </td>                            
                                      <td style="text-align: left !important;">
                                        {{ this.patienttype}} 
                                      </td>
                                    </tr>
                                   
           
                                  </table>                            
                                </div>
                              
                              </div>
                            </td>
                          </tr>
                        </table>
                        
                        
                        
                        </div>
                      </div>

                      

                    <tbody style="width: 100%;" >  

                      <table style="border: 1px solid black;border-collapse: collapse;width: 95%;margin-right: auto;margin-left:auto">
                        <thead>
                              <tr style="border:1px solid black;">
                                <th style="border:1px solid black;width:6%">Si.No</th>
                                <th style="border:1px solid black;width:15%">Medicine name</th>
                                <th style="border:1px solid black;width:10%">Qty</th>
                                <th style="border:1px solid black;width:10%">Batch No</th>
                                <th style="border:1px solid black;">Exp date</th>
                                <th style="border:1px solid black;">Price</th>
                                <th style="border:1px solid black;">CGST(%)</th>
                                <th style="border:1px solid black;">SGST(%)</th>
                                
                                <th style="border:1px solid black;"> Amount</th>
                              </tr>
                        </thead>
                        <tbody>                   
                              <tr *ngFor="let billOrder of insurerArray" style="border:1px solid">
                                <td style="border:1px solid black;width:6%">{{billOrder.sno}}</td>
                                <td style="border:1px solid black;"> {{billOrder.drug_disp_name}} </td>
                                <td style="border:1px solid black;"><p style="float: right; margin-right: 10px;"> {{billOrder.quantity}} </p></td>
                                <td style="border:1px solid black;"><p style="float: right; margin-right: 10px;"> {{billOrder.batch_no}} </p></td>
                                <td style="border:1px solid black;"><p style="float: right; margin-right: 10px;"> {{billOrder.expiry_date }}</p></td>
                                <td style="border:1px solid black;"><p style="float: right; margin-right: 0px;"> {{billOrder.price }}</p></td>
                                <td style="border:1px solid black;"><p style="float: right; margin-right: 0px;"> {{billOrder.cgst}} </p></td>
                                <td style="border:1px solid black;"><p style="float: right; margin-right: 0px;"> {{billOrder.sgst}} </p></td>
                                <td style="border:1px solid black;text-align:right"><p style="float: right; margin-right: 0px;"> {{billOrder.amount}} </p></td>
                              </tr>
                        </tbody>                                                      
                      </table>

                          <div class="bill_border">
                            <div>
                                 
                             
                              <div style="width: 100%;">
                               
                                <div class="row" >
                                <div class="col-6">
                                  <!-- class="col-3 col-md-5 col-lg-3 col-xl-3"  -->
                                  <table style="margin-left:30px">
                                    <tr>
                                      <td><strong>In Words:</strong>&nbsp;Received Rupees {{numToWords}} &nbsp;&nbsp;Only</td>
                                    </tr>
                                  </table>
                                  </div>
                                
                                <div class="col-6" style="margin-left:auto">
                                <table>
                                  <tr>
                                                          
                                    <td style="vertical-align: top;width: 31%;">
                                      <table  style="float: right;margin-left: 110px;margin-right: 25px;" >             
                                        
                                          
                                        <tr [hidden]="" > 
                                          <th style="text-align: left;">Total (र)</th>
                                          <td style="text-align: right;width: 100px;" data-th="Previous balance र"
                                            class="fee_txt">
                                            {{this.actualamount}}
                                          </td>
                                        </tr>  
                                        <tr >
                                          <th style="text-align: left;">Discount <span [hidden]=""></span> (र) </th>
                                          <td style="text-align: right;width: 100px;" data-th="Total र" class="fee_txt">
                                            {{this.prodDiscount}}
                                          </td>
                                        </tr>
                                        <tr >
                                          <th style="text-align: left;">GST(र) </th>
                                          <td style="text-align: right;width: 100px;" data-th="Discount र" class="fee_txt">
                                            {{this.GSTamount}}
                                          </td>
                                        </tr>
                                        <tr>
                                          <th style="text-align: left;">Refund Amount (र)</th>
                                          <td style="text-align: right;width: 100px;" data-th="Bill amount"
                                            class="fee_txt">
                                            {{totalvalue}}
                                          </td>
                                        </tr>
                                        <tr *ngIf="pendingamt != 0.00">
                                          <th style="text-align: left;">Balance (र)</th>
                                          <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                            class="fee_txt">
                                            {{pendingamt}}
                                          </td>
                                        </tr>
                                       
                                      </table>
                                   
                                    </td>                        
                                  </tr>
                                </table>
                                </div>
                                </div>

                              </div> 
                              <!-- <div  style="width: 100%;margin-left: 20px;margin-top: 15px;">
                                <div class="row">
                                
                                </div>
                              </div> -->
                          
                            </div>
                          </div>
                    
                    </tbody>
                      
                      </table>
                    </div>
          
                    <div [hidden]="true" #printnoheader id="printnoheader">
                      <table style="border:'0';width:100%;">  
                        
                        <tbody  border="1">
                           
                          
                              <div class="main">
                              <div style="display: flex; justify-content: space-between; width: 90%;margin-left:15px;margin-top: 10px;border-radius: 10px;">
                           
                            
                             <table style="margin-left:20px;width: 100%;">
                              <tr>
                                <td style="width: 50%;vertical-align: top;">
                                  <div class="row">
                                    <div class="col-12 col-md-7 col-lg-8 col-xl-8">
                                      <table>
                                        <tr>
                                          <th style="text-align: left !important;width: 104px !important;vertical-align: top;"><span><strong>Patient name</strong></span></th>                            
                                          <td style="text-align: left !important;width: 5px !important;vertical-align: top;">
                                            : 
                                          </td>
                                          <td style="text-align: left !important;">
                                            {{OrderByName}}
                                          </td>
                                        </tr>
                                        <tr>
                                          <th style="text-align: left !important;width: 104px !important;"><span><strong>Age/Gender</strong></span></th>    
                                          <td style="text-align: left !important;width: 5px !important;">
                                            : 
                                          </td>                        
                                          <td style="text-align: left !important;">
                                           {{clntAge}}/{{clntGender}}
                                          </td>
                                        </tr>
                                        <tr>
                                          <th style="text-align: left !important;width: 104px !important;"><span><strong>Doctor</strong></span></th>  
                                          <td style="text-align: left !important;width: 5px !important;">
                                            : 
                                          </td>                           
                                          <td style="text-align: left !important;">
                                            {{this.drsal}}   {{doctorName}}
                                          </td>
                                        </tr>
                                       
                                      
                                      
                                      </table>    
                                    </div>
                                  </div>
                                </td>
                                <td style="width: 50%;vertical-align:top">
                                  <div class="row">
                                    <div class="amount_due col-12 col-md-5 col-lg-4 col-xl-4" style="margin-right: 20px;" >
                                      <table>
                                        <tr>
                                          <th style="text-align: left !important;width: 104px !important;"><span><strong>Bill No</strong></span></th> 
                                          <td style="text-align: left !important;width: 5px !important;">
                                            : 
                                          </td>                            
                                          <td style="text-align: left !important;">
                                            {{this.orderNumber}} 
                                          </td>
                                        </tr>
                                        <tr>
                                          <th style="text-align: left !important;width: 110px !important;"><span><strong>Bill Date/Time</strong></span></th> 
                                          <td style="text-align: left !important;width: 5px !important;">
                                            : 
                                          </td>                            
                                          <td style="text-align: left !important;">
                                            {{this.dateformate}} {{this.orderTime}}
                                          </td>
                                        </tr>
                                       
                                        <tr *ngIf="patienttype != 'undefined'">
                                          <th style="text-align: left !important;width: 110px !important;"><span><strong>Patient type</strong></span></th> 
                                          <td style="text-align: left !important;width: 5px !important;">
                                            : 
                                          </td>                            
                                          <td style="text-align: left !important;">
                                            {{ this.patienttype}} 
                                          </td>
                                        </tr>
               
                                      </table>                            
                                    </div>
                                  
                                  </div>
                                </td>
                              </tr>
                            </table>
                            
                            
                            
                            </div>
                          </div>

                          

                        <tbody style="width: 100%;" >  

                          <table style="border: 1px solid black;border-collapse: collapse;margin-right: 20px;">
                            <thead>
                                  <tr style="border:1px solid black;">
                                    <th style="border:1px solid black;width:6%">Si.No</th>
                                    <th style="border:1px solid black;width:15%">Medicine name</th>
                                    <th style="border:1px solid black;width:10%">Qty</th>
                                    <th style="border:1px solid black;width:10%">Batch No</th>
                                    <th style="border:1px solid black;">Exp date</th>
                                    <th style="border:1px solid black;">Price</th>
                                    <th style="border:1px solid black;">CGST(%)</th>
                                    <th style="border:1px solid black;">SGST(%)</th>
                                    <th style="border:1px solid black;"> Amount</th>
                                  </tr>
                            </thead>
                            <tbody>                   
                                  <tr *ngFor="let billOrder of insurerArray" style="border:1px solid">
                                    <td style="border:1px solid black;width:6%">{{billOrder.sno}}</td>
                                    <td style="border:1px solid black;"> {{billOrder.drug_disp_name}} </td>
                                    <td style="border:1px solid black;"><p style="float: right; margin-right: 10px;"> {{billOrder.quantity}} </p></td>
                                    <td style="border:1px solid black;"><p style="float: right; margin-right: 10px;"> {{billOrder.batch_no}} </p></td>
                                    <td style="border:1px solid black;"><p style="float: right; margin-right: 10px;"> {{billOrder.expiry_date }}</p></td>
                                    <td style="border:1px solid black;"><p style="float: right; margin-right: 0px;"> {{billOrder.price }}</p></td>
                                    <td style="border:1px solid black;"><p style="float: right; margin-right: 0px;"> {{billOrder.cgst}} </p></td>
                                    <td style="border:1px solid black;"><p style="float: right; margin-right: 0px;"> {{billOrder.sgst}} </p></td>
                                    <td style="border:1px solid black;text-align:right"><p style="float: right; margin-right: 0px;"> {{billOrder.amount}} </p></td>
                                  </tr>
                            </tbody>                                                      
                          </table>

                              <div class="bill_border">
                                <div>
                                     
                                 
                                  <div style="width: 100%;">
                                   
                                    <div class="row">
                                    <div class="col-6">
                                      <!-- class="col-3 col-md-5 col-lg-3 col-xl-3"  -->
                                      <table>
                                        <tr>
                                          <td><strong>In Words:</strong>&nbsp;Received Rupees {{numToWords}} &nbsp;&nbsp;Only</td>
                                        </tr>
                                      </table>
                                      </div>
                                    
                                    <div class="col-6">
                                    <table>
                                      <tr>
                                                              
                                        <td style="vertical-align: top;width: 31%;">
                                          <table  style="float: right;margin-left: 110px;margin-right: 25px;" >             
                                            
                                              
                                            <tr [hidden]="" > 
                                              <th style="text-align: left;">Total (र)</th>
                                              <td style="text-align: right;width: 100px;" data-th="Previous balance र"
                                                class="fee_txt">
                                                {{this.actualamount}}
                                              </td>
                                            </tr>  
                                            <tr >
                                              <th style="text-align: left;">Discount <span [hidden]=""></span> (र) </th>
                                              <td style="text-align: right;width: 100px;" data-th="Total र" class="fee_txt">
                                                {{this.prodDiscount}}
                                              </td>
                                            </tr>
                                            <tr >
                                              <th style="text-align: left;">GST(र) </th>
                                              <td style="text-align: right;width: 100px;" data-th="Discount र" class="fee_txt">
                                                {{this.GSTamount}}
                                              </td>
                                            </tr>
                                            <tr>
                                              <th style="text-align: left;">Refund Amount (र)</th>
                                              <td style="text-align: right;width: 100px;" data-th="Bill amount"
                                                class="fee_txt">
                                                {{totalvalue}}
                                              </td>
                                            </tr>
                                            <tr >
                                              <th style="text-align: left;">Balance (र)</th>
                                              <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                                class="fee_txt">
                                                {{pendingamt}}
                                              </td>
                                            </tr>
                                           
                                          </table>
                                       
                                        </td>                        
                                      </tr>
                                    </table>
                                    </div>
                                    </div>

                                  </div> 
                                  <!-- <div  style="width: 100%;margin-left: 20px;margin-top: 15px;">
                                    <div class="row">
                                    
                                    </div>
                                  </div> -->
                              
                                </div>
                              </div>
                        
                        </tbody>
                      </table>
                    </div> 
          
                  
                   
          
          
                  </div>
                  
                  
            
            </mdb-card-body>
        </mdb-card>
    </div>
</div>




<!-- <div class="bill_border">
  <div>
        <table style="border: 1px solid black;border-collapse: collapse;margin-right: 20px;">
          <thead>
            <tr>
                <tr style="border:1px solid black;">
                  <th style="border:1px solid black;width:6%">Si.No</th>
                  <th style="border:1px solid black;width:7%">HSN No</th>
                  <th style="border:1px solid black;width:15%">Medicine name</th>
                  <th style="border:1px solid black;width:10%">Qty</th>
                  <th style="border:1px solid black;width:10%">Batch No</th>
                  <th style="border:1px solid black;">Exp date</th>
                  <th style="border:1px solid black;">CGST(%)</th>
                  <th style="border:1px solid black;">SGST(%)</th>
                  <th style="border:1px solid black;">Price</th>
                  <th style="border:1px solid black;"> Amount</th>
                </tr>
          </thead>
          <tbody>                   
                <tr *ngfor="let getOrder of insurerArray" style="border:1px solid"><td style="border:1px solid black;"></td>
                  <td style="border:1px solid black;"> {{getOrder.hsn_no}} </td>
                  <td style="border:1px solid black;"> {{getOrder.drug_disp_name}} </td>
                  <td style="border:1px solid black;"><p style="float: right; margin-right: 10px;"> {{getOrder.quantity}} </p></td>
                  <td style="border:1px solid black;"><p style="float: right; margin-right: 10px;"> {{getOrder.batch_no}} </p></td>
                  <td style="border:1px solid black;"><p style="float: right; margin-right: 10px;"> {{getOrder.expiry_date }}</p></td>
                  <td style="border:1px solid black;"><p style="float: right; margin-right: 0px;"> {{getOrder.price }}</p></td>
                  <td style="border:1px solid black;"><p style="float: right; margin-right: 0px;"> {{getOrder.cgst}} </p></td>
                  <td style="border:1px solid black;"><p style="float: right; margin-right: 0px;"> {{getOrder.sgst}} </p></td>
                  <td style="border:1px solid black;text-align:right"><p style="float: right; margin-right: 0px;"> {{getOrder.amount}} </p></td>
                </tr>
          </tbody>                                                      
        </table>
   
    <div style="width: 100%;">
      <table style="margin-bottom: -20px;">
        <tr>
                                
          <td style="vertical-align: top;width: 31%;">
            <table  style="float: right;margin-left: 110px;margin-right: 25px;" >             
              
                
              <tr [hidden]="" > 
                <th style="text-align: left;">Total (र)</th>
                <td style="text-align: right;width: 100px;" data-th="Previous balance र"
                  class="fee_txt">
                  {{this.actualamount}}
                </td>
              </tr>  
              <tr >
                <th style="text-align: left;">Discount <span [hidden]=""></span> (र) </th>
                <td style="text-align: right;width: 100px;" data-th="Total र" class="fee_txt">
                  {{this.prodDiscount}}
                </td>
              </tr>
              <tr >
                <th style="text-align: left;">GST(र) </th>
                <td style="text-align: right;width: 100px;" data-th="Discount र" class="fee_txt">
                  {{this.GSTamount}}
                </td>
              </tr>
              <tr>
                <th style="text-align: left;">Refund Amount (र)</th>
                <td style="text-align: right;width: 100px;" data-th="Bill amount"
                  class="fee_txt">
                  totalvalue
                </td>
              </tr>
              <tr >
                <th style="text-align: left;">Balance (र)</th>
                <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                  class="fee_txt">
                  pendingamt
                </td>
              </tr>
             
            </table>
         
          </td>                        
        </tr>
      </table>
    </div> 
    <div  style="width: 100%;margin-left: 20px;margin-top: 15px;">
      <div class="row">
        <table class="col-3 col-md-5 col-lg-3 col-xl-3" style="float: left;">
          <tr>
            <td><strong>In Words:</strong>&nbsp;Received Rupees&nbsp;&nbsp;Only</td>
          </tr>
        </table>
      </div>
    </div>

  </div>
</div>    -->



<!-- @page {
  size: auto;
  margin-top: 122px;
  margin-right: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
  overflow: visible;
} 
* {
    font-size: 12px !important;
    line-height: 1 !important;
    page-break-inside: always;
    overflow: visible;
  }                                     
@media print {           
  body {
    height: auto; /* Allow body height to adjust dynamically */
  }          
  .page-break {
    page-break-before: always; /* or page-break-after: always; */
    page-break-inside: always;
  }
}           
.address_visibility {
  visibility: hidden;
}        
table.report-container {
  page-break-after: always;
}
thead.report-header {
  display: table-header-group;
}
tfoot.report-footer {
  display: table-footer-group;
}
.print:last-child {
  page-break-after: auto;
}
.alignRight {
  text-align: right;
}
.col-print-1 {width:8%;  float:left;}
.col-print-2 {width:16%; float:left;}
.col-print-3 {width:25%; float:left;}
.col-print-4 {width:33%; float:left;}
.col-print-5 {width:42%; float:left;}
.col-print-6 {width:50%; float:left;}
.col-print-7 {width:58%; float:left;}
.col-print-8 {width:66%; float:left;}
.col-print-9 {width:75%; float:left;}
.col-print-10 {width:83%; float:left;}
.col-print-11 {width:92%; float:left;}
.col-print-12 {width:100%; float:left;} -->