<div mat-dialog-title class="popup_border_postcovid">
  <h5 class="header_txt">Post covid tracker</h5>
  <div mat-dialog-actions>
    <img src="../../../assets/img/close_icon.png" (click)="close()" width="20px" height="20px" id="close_postcovid" />
  </div>
</div>
<div mat-dialog-content class="covid_content">
  <div class="row">
    <div class="col-12" *ngIf="!noData">
      <mat-accordion [hidden]="symptomFlag" class="mataccordion">
        <mat-expansion-panel class="expandpanel">
          <mat-expansion-panel-header class="exppanel width">
            <mat-panel-title class="title">
              Symptoms
            </mat-panel-title>
          </mat-expansion-panel-header>
          <hr class="hr">
          <div class="row">
            <div class="col-12">
              <div *ngIf="dailySympList.length == 0" class="nodata">No data found</div>
              <div class="table-responsive">
                <table *ngIf="dailySympList.length != 0" id="tbl" cellpadding="5"
                  cellspacing="0" border="1">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Symptom</th>
                      <th>Severity</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let dailySymp of dailySympList">
                      <td>{{dailySymp.date | date: 'dd-MM-yyyy'}}</td>
                      <td style="text-align: left;">{{dailySymp.symptom}}</td>
                      <td style="text-align: left;">{{dailySymp.severity}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      <mat-accordion [hidden]="sleepFlag" class="mataccordion">
        <mat-expansion-panel class="expandpanel">
          <mat-expansion-panel-header class="exppanel width">
            <mat-panel-title class="title">
              Vitals
            </mat-panel-title>
          </mat-expansion-panel-header>
          <hr class="hr">
          <div class="row">
            <div class="col-12">
              <p class="tervys_heading"><b>Observations</b></p>
            </div>
            <div class="col-12">
              <div *ngIf="observList.length == 0" class="nodata">No data found</div>
              <div class="table-responsive" *ngIf="observList.length != 0">
                <table id="tbl" cellpadding="5" cellspacing="0" border="1">
                  <thead>
                    <tr>
                      <th>Temperature</th>
                      <th>Blood pressure</th>
                      <th>Saturation</th>
                      <th>Pulse</th>
                      <th>Respiratory rate</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let observ of observList">
                      <td>{{observ.temp}}</td>
                      <td>{{observ.bp}}</td>
                      <td>{{observ.saturation}}</td>
                      <td>{{observ.pulse}}</td>
                      <td>{{observ.resp_rate}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-12">
              <p class="tervys_heading"><b>Sleep</b></p>
            </div>
            <div class="col-12">
              <div *ngIf="sleepTimeList.length == 0" class="nodata">No data found</div>
              <div class="table-responsive" *ngIf="sleepTimeList.length != 0">
                <table id="tbl" cellpadding="5" cellspacing="0" border="1">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Bed time</th>
                      <th>wakeup time</th>
                      <th>duration</th>
                      <th>Pattern</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let sleep of sleepTimeList">
                      <td>{{sleep.date | date: 'dd-MM-yyyy'}}</td>
                      <td>{{sleep.bedtime}}</td>
                      <td>{{sleep.wakeup}}</td>
                      <td>{{sleep.duration}}</td>
                      <td>{{sleep.pattern}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      <mat-accordion [hidden]="dietFlag" class="mataccordion">
        <mat-expansion-panel class="expandpanel">
          <mat-expansion-panel-header class="exppanel width">
            <mat-panel-title class="title">
              Diet
            </mat-panel-title>
          </mat-expansion-panel-header>
          <hr class="hr">
          <div class="row">
            <div class="col-12">
              <div *ngIf="dietHistList.length == 0" class="nodata">No data found</div>
              <div class="table-responsive">
                <table ng-table="DietHist_Table" id="tbl"
                  [hidden]="dietHistList.length==0">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Break fast</th>
                      <th>Morning</th>
                      <th>Mid-day</th>
                      <th>Lunch</th>
                      <th>Evening</th>
                      <th>Dinner1</th>
                      <th>Night</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let diet of dietHistList">
                      <td class="diet_histtable_date_td">{{diet.created_date | date:'dd-MM-yyyy'}}</td>
                      <td style="text-align: left;">{{diet.break_fast}}</td>
                      <td style="text-align: left;">{{diet.morning}}</td>
                      <td style="text-align: left;">{{diet.midday}}</td>
                      <td style="text-align: left;">{{diet.lunch}}</td>
                      <td style="text-align: left;">{{diet.evening}}</td>
                      <td style="text-align: left;">{{diet.dinner}}</td>
                      <td style="text-align: left;">{{diet.night}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      <mat-accordion [hidden]="medicationFlag" class="mataccordion">
        <mat-expansion-panel class="expandpanel">
          <mat-expansion-panel-header class="exppanel width">
            <mat-panel-title class="title">
              Medication
            </mat-panel-title>
          </mat-expansion-panel-header>
          <hr class="hr">
          <div class="row">
            <div class="col-12">
              <div *ngIf="medicineList.length == 0" class="nodata">No data found</div>
              <div class="table-responsive" *ngIf="medicineList.length !=0">
                <table id="tbl" cellpadding="5" cellspacing="0" border="0">
                  <thead>
                    <tr>
                      <th class="pad_mar">Date</th>
                      <th class="pad_mar">Medicine name</th>
                      <th class="pad_mar">Intake</th>
                      <th class="pad_mar">Quantity</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let medicine of medicineList">
                      <td data-th="Medicine name">{{medicine.Date | date: 'dd-MM-yyyy'}}</td>
                      <td style="text-align: left;" data-th="Medicine name">{{medicine.drug_name}}</td>
                      <td style="text-align: left;" data-th="Medicine name">{{medicine.intake}}</td>
                      <td data-th="Quantity" style="width: 150px;">{{medicine.quantity}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      <mat-accordion [hidden]="physicalFlag" class="mataccordion">
        <mat-expansion-panel class="expandpanel">
          <mat-expansion-panel-header class="exppanel width">
            <mat-panel-title class="title">
              Physical Activities
            </mat-panel-title>
          </mat-expansion-panel-header>
          <hr class="hr">
          <div class="row">
            <div class="col-12">
              <div *ngIf="physicalList.length == 0" class="nodata">No data found</div>
              <div class="table-responsive" *ngIf="physicalList.length !=0">
                <table id="tbl" cellpadding="5" 
                  cellspacing="0" border="0">
                  <thead>
                    <tr>
                      <th class="date">Date</th>
                      <th class="exercise">Exercise</th>
                      <th class="duration">Duration</th>
                      <th class="frequency">Frequency</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let physical of physicalList">
                      <td data-th="date">{{physical.date  | date: 'dd-MM-yyyy'}}</td>
                      <td style="text-align: left;" data-th="exercise">{{physical.exercise}}</td>
                      <td data-th="duration">{{physical.duration}}</td>
                      <td style="text-align: left;" data-th="frequency">{{physical.frequency}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>
<div mat-dialog-actions align="end" style="width: auto;right: 19px;">
</div>