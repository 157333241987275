import { Component, OnInit } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { ipaddress } from '../../../ipaddress';
import { Helper_Class } from '../../../helper_class';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Message_data } from 'src/assets/js/Message_data';

@Component({
  selector: 'app-pharma-racks-bins',
  templateUrl: './pharma-racks-bins.component.html',
  styleUrls: ['./pharma-racks-bins.component.scss']
})
export class PharmaRacksBinsComponent implements OnInit {
  public pharmacistID:string;
  public userInfo;
  public pharma_id;
  public rackslist=[];
  public racknames;
  public binslist=[];
  public selectedBins:any= [];
  constructor(public http: Http,public routes: ActivatedRoute, public router: Router,public toastr: ToastrService) { }

  ngOnInit(): void {
    this.userInfo = Helper_Class.getInfo();
    this.pharmacistID = Helper_Class.getIsAdmin() != undefined ? this.userInfo.hospitals[0].hptl_clinic_id : this.userInfo.user_id;
    this.pharma_id=this.userInfo.hospitals[0].hptl_clinic_id
    this.getracksbin();
  }
getracksbin(){
  var senddata={
    pharma_id:this.pharma_id
  }
  console.log(senddata)
var headers=new Headers();
headers.append('Content-Type', 'application/json');
this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/grbd', JSON.stringify(senddata),{ headers: headers })
.subscribe(
  response => {
    var dataracksbins = response.json();
    console.log("whole data",JSON.stringify(dataracksbins))
    var dataracks=dataracksbins.racks
    var databins=dataracksbins.bins		
    console.log("racks data"+JSON.stringify(dataracks));
    console.log("bins ",JSON.stringify(databins))
    if (dataracksbins.racks != null) {
      for(var i=0; i < dataracks.length;i++){
        this.rackslist.push({
          rackid:dataracks[i].rackid,
          rackname:dataracks[i].rackname,
          rackstatus:dataracks[i].status
        })

      }

    }
    if(dataracksbins.bins != null){
      for(var j=0; j < databins.length; j++){
        this.binslist.push({
          binid:databins[j].binid,
          binname:databins[j].binname,
          status:databins[j].status
        })
      }
    }



  },
  error => {
    this.toastr.error(Message_data.defaultErr);

  }
  
  
  )
}

onCheckboxChange(binid) {
  // if (isChecked) {
  //   this.selectedBins.push(binId);
  // } else {
  //   const index = this.selectedBins.indexOf(binId);
  //   if (index !== -1) {
  //     this.selectedBins.splice(index, 1);
  //   }
  // }


  if (this.selectedBins != "" && this.selectedBins.length != 0) {
    if (this.selectedBins.indexOf(binid) !== -1) {
      var index = this.selectedBins.indexOf(binid);
      this.selectedBins.splice(index, 1);
    } else {
      this.selectedBins.push(binid);
    }
  } else {
    this.selectedBins.push(binid);
  }


}


sendbinandracks(){
var send_data={
pharma_id:this.pharma_id,
rackid:this.racknames,
bindet:this.selectedBins
}
var headers=new Headers();
headers.append('Content-Type', 'application/json');
console.log(JSON.stringify(send_data))
this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/srbm', JSON.stringify(send_data),{ headers: headers })
.subscribe(
  response => {
    var repsonsedata = response.json();
    console.log(JSON.stringify(repsonsedata))
  })
}
}
