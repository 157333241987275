import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import * as $ from 'jquery/dist/jquery.min.js';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Message_data } from '../../../assets/js/Message_data';
import { ipaddress } from '../../ipaddress';
@Component({
  selector: 'app-client-regist',
  templateUrl: './client-regist.component.html',
  styleUrls: ['./client-regist.component.css']
})

export class ClientRegistComponent implements OnInit {
  blood: boolean = false;
  down: boolean = false;
  regClientAddressFlag: boolean;
  public clientPersonalListArray: any = {};
  public bloodGroupTestArray: any;
  public languageDataArray: any;
  public maritalStatusArray: any = [];
  private imageFileSTR: any;
  public profileImgFlag: boolean = false;
  public clientRetrivelAddressArray: any;
  public clientPresentAddress: any = {};
  public clientPermenantAddress: any = {};
  public sendClientAddressArray: any = {};
  public locationURL: string;
  public cityURL: string;
  public stateURL: string;
  public countryURL: string;
  public presentLocationArray = [];
  public presentCityArray;
  public presentStateArray;
  public presentCountryArray;
  public permenantLocationArray = [];
  public permenantCityArray;
  public permenantStateArray;
  public permenantCountryArray;
  public sameasFlag: boolean;
  presentLocationId: string;
  presentCityId: string;
  presentStateId: string;
  presentCountryId: string;
  premLocationId: string;
  premCityId: string;
  premStateId: string;
  premCountryId: string;
  agreeBox: boolean;
  public registerURL: string;
  public sendRegisterArray: any = {};
  public registerPersonalArray: any;
  public registerAddressArray: any;
  public bothModulArray = [];
  public submenuDrawerModulArray = [];
  public moduleDataArray = [];
  public loginData = [];
  public currentDatetime;
  public saveFlag: boolean = false;
  constructor(private http: Http, private routes: ActivatedRoute, private router: Router, private toastr: ToastrService) {
    this.clientPersonalListArray.citizenShip = "Indian";
    this.clientPersonalListArray.nationality = "Indian";
    this.maritalStatusArray = ["Single", "Bachelor", "Spinster", "Married", "Divorced", "Seperated", "Widowed"];
    this.sameasFlag = false;
    this.get_Language_Data('0');
    this.get_Bloodgroup_Data('0');
  }
  ngOnInit(): void {
    $(document).ready(function () {
      $("input").attr("autocomplete", "off");
    });
    this.locationURL = ipaddress.getIp + "gen/loc";
    this.cityURL = ipaddress.getIp + "gen/city";
    this.stateURL = ipaddress.getIp + "gen/state";
    this.countryURL = ipaddress.getIp + "gen/count";
    this.clientPresentAddress.city = null;
    this.clientPresentAddress.state = null;
    this.clientPresentAddress.country = null;
    this.clientPermenantAddress.city = null;
    this.clientPermenantAddress.state = null;
    this.clientPermenantAddress.country = null;
    this.Get_current_date();
    this.addressRetrival();
  }

  salutationChage(){
    if(this.clientPersonalListArray.salutation == "Mr" || this.clientPersonalListArray.salutation == "Master"){
      this.clientPersonalListArray.gender="Male";

    } else if(this.clientPersonalListArray.salutation == "Ms" || this.clientPersonalListArray.salutation == "Miss" 
    || this.clientPersonalListArray.salutation == "Mrs"|| this.clientPersonalListArray.salutation == "Baby"){
      this.clientPersonalListArray.gender="Female";

    } else if(this.clientPersonalListArray.salutation == "Mx"){
      this.clientPersonalListArray.gender="Transgender";

    }
  }

  Get_current_date() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate',
      JSON.stringify({
        country: ipaddress.country_code.toString()
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          if (obj.current_date != null)
            this.currentDatetime = obj.current_date;
        }, error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }
  openclose() {
    this.down = !this.down;
    if (this.down == true)
      document.getElementById("block").style.display = "block";
    else
      document.getElementById("block").style.display = "none";
  }
  fileChange(data) {
    this.profileImgFlag = true;
    this.readThis(data.target);
  }
  readThis(inputValue: any): void {
    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();
    myReader.onloadend = (e) => {
      var fileSize = Math.round(inputValue.files[0].size / 1024);
      if (fileSize >= 50) {
        this.toastr.error(Message_data.maxImgSize);
        $("#file").val('');
        this.clientPersonalListArray.profile_image = "default";
        this.imageFileSTR = "assets/img/default.jpg";
      }
      else {
        this.imageFileSTR = myReader.result;
        this.clientPersonalListArray.profile_image = this.imageFileSTR.split(',')[1];
        $('#profile_img').attr('src', myReader.result);
      }
    }
    myReader.readAsDataURL(inputValue.files[0]);
  }
  get_Bloodgroup_Data(retrivel_data) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp + "bd/bgroup/", { headers: headers }).subscribe(
      data => {
        if (data.json().blood_groups != null) {
          this.bloodGroupTestArray = data.json().blood_groups;
          if (retrivel_data != "null" && retrivel_data != null) {
            for (var t = 0; t < this.bloodGroupTestArray.length; t++) {
              if (this.bloodGroupTestArray[t].blood_group_id == retrivel_data) {
                this.clientPersonalListArray.bloodGroup = this.bloodGroupTestArray[t];
                break;
              }
            }
          }
        }
      }, error => { });
  }
  get_Language_Data(retrivel_data) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp + "usercontroller/lng/", { headers: headers }).subscribe(
      data => {
        if (data.json().languages != null) {
          this.languageDataArray = data.json().languages;
          if (retrivel_data != "0") {
            for (var s = 0; s < this.languageDataArray.length; s++) {
              if (this.languageDataArray[s].language_id == retrivel_data) {
                this.clientPersonalListArray.language = this.languageDataArray[s];
                break;
              }
            }
          }
        }
      }, error => { });
  }
  Location_change(data, type) {
    console.log( data );
    if ((data.target.value.toString()).length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.locationURL, JSON.stringify({ loc_name: (data.target.value.toString()) }), { headers: headers }).subscribe(
        data => {
          if (data.json().locations != null) {
            if (type == "0")
              this.presentLocationArray = data.json().locations;
            else
              this.permenantLocationArray = data.json().locations;
          }
        }, error => { });
    }
  }
  location_selected(data, type) {
    if (type == "0") {
      this.clientPresentAddress.location = data;
      this.presentLocationArray = [];
      this.Get_City(data, type, '0');
    } else {
      this.clientPermenantAddress.location = data;
      this.permenantLocationArray = [];
      this.Get_City(data, type, '0');
    }
  }
  Get_City(data, type, flag) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.cityURL, JSON.stringify({ loc_name: data }), { headers: headers }).subscribe(
      data => {
        if (data.json().cities != null) {
          if (type == "0") { //  Present address
            this.presentCityArray = data.json().cities;
            if (flag != null && flag == "1") {
              for (var t = 0; t < this.presentCityArray.length; t++) {
                if (this.presentCityArray[t].city_id == this.clientPresentAddress.id_city) {
                  this.presentLocationId = this.presentCityArray[t].location_id;
                  this.presentCityId = this.presentCityArray[t].city_id;
                  this.clientPresentAddress.city = this.presentCityArray[t];
                  this.clientPresentAddress.zipcode = this.presentCityArray[t].zipcode;
                  this.City_change(this.presentCityArray[t].city_id, type, flag);
                  break;
                }
              }
            } else {
              this.presentLocationId = this.presentCityArray[0].location_id;
              this.presentCityId = this.presentCityArray[0].city_id;
              this.clientPresentAddress.city = this.presentCityArray[0];
              this.clientPresentAddress.zipcode = this.presentCityArray[0].zipcode;
              this.City_change(this.presentCityArray[0].city_id, type, '0');
            }
          } else {// Permenant address
            this.permenantCityArray = data.json().cities;
            if (flag != null && flag == "1") {
              for (var t = 0; t < this.permenantCityArray.length; t++) {
                if (this.permenantCityArray[t].city_id == this.clientPermenantAddress.id_city) {
                  this.premLocationId = this.permenantCityArray[t].location_id;
                  this.premCityId = this.permenantCityArray[t].city_id;
                  this.clientPermenantAddress.city = this.permenantCityArray[t];
                  this.clientPermenantAddress.zipcode = this.permenantCityArray[t].zipcode;
                  this.City_change(this.permenantCityArray[t].city_id, type, flag);
                  break;
                }
              }
            } else {
              this.premLocationId = this.permenantCityArray[0].location_id;
              this.premCityId = this.permenantCityArray[0].city_id;
              this.clientPermenantAddress.city = this.permenantCityArray[0];
              this.clientPermenantAddress.zipcode = this.permenantCityArray[0].zipcode;
              this.City_change(this.permenantCityArray[0].city_id, type, '0');
            }
          }
        }
      }, error => { });
  }
  City_change(data, type, flag) {
    if (data != null && data != undefined) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.stateURL, JSON.stringify({ city_id: data }), { headers: headers }).subscribe(
        data => {
          if (data.json().states != null) {
            if (type == "0") { //  Present address
              this.presentStateArray = data.json().states;
              if (flag != null && flag == "1") {
                for (var t = 0; t < this.presentStateArray.length; t++) {
                  if (this.presentStateArray[t].state_id == this.clientPresentAddress.id_state) {
                    this.presentStateId = this.presentStateArray[t].state_id;
                    this.clientPresentAddress.state = this.presentStateArray[t];
                    this.State_change(this.presentStateArray[t].state_id, type, flag);
                    break;
                  }
                }
              } else {
                this.presentStateId = this.presentStateArray[0].state_id;
                this.clientPresentAddress.state = this.presentStateArray[0];
                this.State_change(this.presentStateArray[0].state_id, type, '0');
              }
            }
            else { // Permenant address
              this.permenantStateArray = data.json().states;
              if (flag != null && flag == "1") {
                for (var t = 0; t < this.permenantStateArray.length; t++) {
                  if (this.permenantStateArray[t].state_id == this.clientPermenantAddress.id_state) {
                    this.premStateId = this.permenantStateArray[t].state_id;
                    this.clientPermenantAddress.state = this.permenantStateArray[t];
                    this.State_change(this.permenantStateArray[t].state_id, type, flag);
                    break;
                  }
                }
              }
              else {
                this.premStateId = this.permenantStateArray[0].state_id;
                this.clientPermenantAddress.state = this.permenantStateArray[0];
                this.State_change(this.permenantStateArray[0].state_id, type, '0');
              }
            }
          }
        }, error => { });
    }
  }
  State_change(data, type, flag) {
    if (data != null && data != undefined) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.countryURL, JSON.stringify({ state_id: data }), { headers: headers }).subscribe(
        data => {
          if (data.json().countries != null) {
            if (type == "0") { //  Present address
              this.presentCountryArray = data.json().countries;
              this.presentCountryId = this.presentCountryArray[0].country_id;
              if (flag != null && flag == "1") {
                for (var t = 0; t < this.presentCountryArray.length; t++) {
                  if (this.presentCountryArray[t].country_id == this.clientPresentAddress.id_country) {
                    this.clientPresentAddress.country = this.presentCountryArray[t];
                 }
                }
              } else {
                this.clientPresentAddress.country = this.presentCountryArray[0];
              }
            } else {
              this.permenantCountryArray = data.json().countries;
              this.premCountryId = this.permenantCountryArray[0].country_id;
              if (flag != null && flag == "1") {
                for (var t = 0; t < this.permenantCountryArray.length; t++) {
                  if (this.permenantCountryArray[t].country_id == this.clientPermenantAddress.country) {
                    this.clientPermenantAddress.country = this.permenantCountryArray[t];
                  }
                }
              } else {
                this.clientPermenantAddress.country = this.permenantCountryArray[0];
              }
            }
          }
        }, error => { });
    }
  }
  addressRetrival() {
    if (this.clientRetrivelAddressArray != null && this.clientRetrivelAddressArray != undefined) {
      if (this.clientRetrivelAddressArray.presentAddress != null) {
        this.clientPresentAddress = this.clientRetrivelAddressArray.presentAddress;
        this.Get_City(this.clientPresentAddress.location, '0', '1');
        if (this.clientRetrivelAddressArray.permenantAddress != null) {
          this.sameasFlag = false;
          this.clientPermenantAddress = this.clientRetrivelAddressArray.permenantAddress;
          this.Get_City(this.clientPermenantAddress.location, '1', '1');
        } else {
          this.sameasFlag = true;
        }
      }
    }
  }
  agreechanges(e) {
    this.agreeBox = e;
  }
  sameaddress(e) {
    this.sameasFlag = e;
  }
  Register() {
    var valid_flag = false;
    if (this.registerPersonalArray.first_name != null) {
      this.sendRegisterArray.first_name = encrypt_decript.Encript(this.registerPersonalArray.first_name).toString();
      if (this.registerPersonalArray.middle_name != null) {
        this.sendRegisterArray.middle_name = encrypt_decript.Encript(this.registerPersonalArray.middle_name).toString();
      }
      this.sendRegisterArray.last_name = encrypt_decript.Encript(this.registerPersonalArray.last_name).toString();
      this.sendRegisterArray.gender = encrypt_decript.Encript(this.registerPersonalArray.gender).toString();
      if (this.registerPersonalArray.education != null) {
        this.sendRegisterArray.education = encrypt_decript.Encript(this.registerPersonalArray.education).toString();
      }
      if (this.registerPersonalArray.doB != null) {
        this.sendRegisterArray.dob = this.registerPersonalArray.doB;
      }
      if (this.registerPersonalArray.bloodGroup != null) {
        this.sendRegisterArray.blood_group = this.registerPersonalArray.bloodGroup.blood_group_id;
      }
      if (this.registerPersonalArray.language != null) {
        this.sendRegisterArray.language = this.registerPersonalArray.language.language_id;
      }
      if (this.registerPersonalArray.email != null) {
        this.sendRegisterArray.email = encrypt_decript.Encript(this.registerPersonalArray.email).toString();
      }
      this.sendRegisterArray.mobile = encrypt_decript.Encript(this.registerPersonalArray.mobile.toString()).toString();
      if (this.registerPersonalArray.motherName != null) {
        this.sendRegisterArray.mother_maiden_name = encrypt_decript.Encript(this.registerPersonalArray.motherName).toString();
      }
      if (this.registerPersonalArray.Occupation != undefined) {
        this.sendRegisterArray.occupation = this.registerPersonalArray.Occupation;
      }
      if (this.registerPersonalArray.marital_status != undefined) {
        this.sendRegisterArray.marital_status = encrypt_decript.Encript(this.registerPersonalArray.marital_status).toString();
        if (this.registerPersonalArray.marital_status == "Married" || this.registerPersonalArray.marital_status == "Widowed") {
          this.sendRegisterArray.spouse_name = encrypt_decript.Encript(this.registerPersonalArray.spouseName).toString();
        }
      }
      this.sendRegisterArray.citizenship = encrypt_decript.Encript(this.registerPersonalArray.citizenShip).toString();
      this.sendRegisterArray.nationality = encrypt_decript.Encript(this.registerPersonalArray.nationality).toString();
      if (this.registerPersonalArray.blood_donation != null && this.registerPersonalArray.blood_donation == true) {
        this.sendRegisterArray.blood_donation = "1";
      } else {
        this.sendRegisterArray.blood_donation = "0";
      }
      this.sendRegisterArray.password = this.registerPersonalArray.con_password;
      // Address Data 
      if (this.registerAddressArray.presentAddress.address2 != null) {
        var pres_address2 = encrypt_decript.Encript(this.registerAddressArray.presentAddress.address2).toString();
      }
      if (this.registerAddressArray.presentAddress.tel_home != null) {
        var pres_home_num = encrypt_decript.Encript(this.registerAddressArray.presentAddress.tel_home).toString();
      }
      if (this.registerAddressArray.presentAddress.tel_business != null) {
        var pres_buss_num = encrypt_decript.Encript(this.registerAddressArray.presentAddress.tel_business).toString();
      }
      if (this.registerAddressArray.permenantAddress != null) {
        if (this.registerAddressArray.permenantAddress.address2 != null) {
          var perm_address2 = encrypt_decript.Encript(this.registerAddressArray.permenantAddress.address2).toString();
        }
        if (this.registerAddressArray.permenantAddress.tel_home != null) {
          var perm_home_num = encrypt_decript.Encript(this.registerAddressArray.permenantAddress.tel_home).toString();
        }
        if (this.registerAddressArray.permenantAddress.tel_business != null) {
          var perm_buss_num = encrypt_decript.Encript(this.registerAddressArray.permenantAddress.tel_business).toString();
        }
        this.sendRegisterArray.address = [{
          address1: encrypt_decript.Encript(this.registerAddressArray.presentAddress.address1).toString(),
          address2: pres_address2,
          location: this.registerAddressArray.presentAddress.city["location_id"],
          city: this.registerAddressArray.presentAddress.city["city_id"],
          state: this.registerAddressArray.presentAddress.state["state_id"],
          country: this.registerAddressArray.presentAddress.country["country_id"],
          zipcode: encrypt_decript.Encript(this.registerAddressArray.presentAddress.zipcode).toString(),
          tel_business: pres_buss_num,
          tel_home: pres_home_num,
          type: this.registerAddressArray.presentAddress.type,
          permanent: this.registerAddressArray.presentAddress.permanent,
        },
        {
          address1: encrypt_decript.Encript(this.registerAddressArray.permenantAddress.address1).toString(),
          address2: perm_address2,
          location: this.registerAddressArray.permenantAddress.city["location_id"],
          city: this.registerAddressArray.permenantAddress.city["city_id"],
          state: this.registerAddressArray.permenantAddress.state["state_id"],
          country: this.registerAddressArray.permenantAddress.country["country_id"],
          zipcode: encrypt_decript.Encript(this.registerAddressArray.permenantAddress.zipcode).toString(),
          tel_business: perm_buss_num,
          tel_home: perm_home_num,
          type: this.registerAddressArray.permenantAddress.type,
          permanent: this.registerAddressArray.permenantAddress.permanent,
        }]
      } else {
        this.sendRegisterArray.address = [{
          address1: encrypt_decript.Encript(this.registerAddressArray.presentAddress.address1).toString(),
          address2: pres_address2,
          location: this.registerAddressArray.presentAddress.city["location_id"],
          city: this.registerAddressArray.presentAddress.city["city_id"],
          state: this.registerAddressArray.presentAddress.state["state_id"],
          country: this.registerAddressArray.presentAddress.country["country_id"],
          zipcode: encrypt_decript.Encript(this.registerAddressArray.presentAddress.zipcode).toString(),
          tel_business: pres_buss_num,
          tel_home: pres_home_num,
          type: this.registerAddressArray.presentAddress.type,
          permanent: this.registerAddressArray.presentAddress.permanent,
        }]
      }
    }
    if (valid_flag == false) {
      if (this.registerPersonalArray.profile_image_flag == "default") {
        this.registerURL = ipaddress.getIp + "usercontroller/regclnt/";
        this.sendRegisterArray.profile_image = this.registerPersonalArray.profile_image;
      } else {
        this.registerURL = ipaddress.getIp + "usercontroller/regclntweb/";
        this.sendRegisterArray.profile_image = this.registerPersonalArray.profile_image;
      }
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.registerURL, JSON.stringify(this.sendRegisterArray), { headers: headers }).subscribe(
        data => {
          var SaveResponse = data.json();
          if (SaveResponse.key != null && SaveResponse.key == "1") {
            this.saveFlag = true;
            this.toastr.success(Message_data.regtrSuccess);
            this.router.navigateByUrl('/loginpage');
          } else {
            this.toastr.error(Message_data.unableToRegtr);
          }
        }, error => { });
    }
  }

  Register_click() {
    console.log("clientPersonalListArray.doB" + this.clientPersonalListArray.doB);
    this.registerPersonalArray = this.clientPersonalListArray;
    this.registerAddressArray = this.dataLoad();
    if (this.registerPersonalArray == null) {
      this.toastr.error(Message_data.mandatory);
    } else if (this.registerAddressArray == null || this.registerAddressArray == undefined) {
      this.toastr.error(Message_data.mandatory);
    } else if (this.agreeBox == null || this.agreeBox == false) {
      this.toastr.error(Message_data.sltIAgree);
    } else if (this.profileImgFlag == true) {
      this.toastr.error(Message_data.sltProfileImg);
    } else {
      var validation_flag = false;
      if (this.registerPersonalArray.first_name == undefined || this.registerPersonalArray.last_name == undefined 
        || this.registerPersonalArray.gender == undefined || this.clientPersonalListArray.doB == undefined 
        || this.registerPersonalArray.mobile == undefined || this.registerPersonalArray.password == undefined ) {
        validation_flag = true;
        this.toastr.error(Message_data.personalFieldMandtory);

      } else if ((this.registerPersonalArray.marital_status != undefined && this.registerPersonalArray.marital_status == "Married" && this.registerPersonalArray.spouseName == undefined) ||
        (this.registerPersonalArray.marital_status != undefined && this.registerPersonalArray.marital_status == "Widowed" && this.registerPersonalArray.spouseName == undefined)) {
        validation_flag = true;
        this.toastr.error(Message_data.enterSpouseName);

      } else if (this.registerPersonalArray.mobile.toString().length != 10) {
        validation_flag = true;
        this.toastr.error(Message_data.validMobileNo);

      } else if (this.registerAddressArray.presentAddress == undefined) {
        for (var j = 0; j < this.registerAddressArray.length; j++) {
          for (var l = 0; l < this.registerAddressArray[j].presentAddress.length; l++) {
            if (this.registerAddressArray[j].presentAddress[l].address.address1 == undefined || this.registerAddressArray[j].presentAddress[l].address.location == undefined || this.registerAddressArray[j].presentAddress[l].address.city == undefined ||
              this.registerAddressArray[j].presentAddress[l].address.country == undefined || this.registerAddressArray[j].presentAddress[l].address.zipcode == undefined) {
              validation_flag = true;
              this.toastr.error(Message_data.enterPresentAddrMandatory);
            }
          }
        }
      } else if (this.registerAddressArray.presentAddress.permanent == "0") {  // Sameas unchecked
        if (this.registerAddressArray.permenantAddress.length == 0) {
          for (var j = 0; j < this.registerAddressArray.length; j++) {
            for (var l = 0; l < this.registerAddressArray[j].permenantAddress.length; l++) {
              if (this.registerAddressArray[j].permenantAddress[l].address.address1 == undefined || this.registerAddressArray[j].permenantAddress[l].address.location == undefined || this.registerAddressArray[j].permenantAddress[l].address.city == undefined ||
                this.registerAddressArray[j].permenantAddress[l].address.country == undefined || this.registerAddressArray[j].permenantAddress[l].address.zipcode == undefined) {
                validation_flag = true;
                this.toastr.error(Message_data.enterPermenantAddrMandatory);
              }
            }
          }
        }
      } else if (this.registerPersonalArray.first_name == null || this.registerPersonalArray.last_name == null) {
        validation_flag = true;
      } else if (this.registerPersonalArray.password != this.registerPersonalArray.con_password) {
        this.toastr.error(Message_data.pwdNotMatched);
        validation_flag = true;
      }
      if (validation_flag == false) { // Validation completed
        if (this.registerPersonalArray.email != null) {
          var valid_email: boolean = true; // Check whether email is valid or not
          if (valid_email == true)
            this.Email_check(this.registerPersonalArray.email);
          else
            this.toastr.error(Message_data.validEmail);
        }
        else {
          this.Mobile_check();
        }
      }
    }
  }
  Check_valid_Email() { // Check whether email is valid or not
    return true;
  }
  Email_check(data) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "usercontroller/isclient/",
      JSON.stringify({
        input: encrypt_decript.Encript(data).toString(),
        flag: "65"
      }), { headers: headers }).subscribe(
        data => {
          if (data.json().result == true)
            this.toastr.error(Message_data.existEmailID);
          else
            this.Mobile_check();
        }, error => { });
  }
  Mobile_check() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "usercontroller/isclient/",
      JSON.stringify({
        input: encrypt_decript.Encript(this.registerPersonalArray.mobile.toString()).toString(),
        flag: "1"
      }), { headers: headers }).subscribe(
        data => {
          if (data.json().result == true)
            this.toastr.error(Message_data.existMobileNo);
          else {
            this.set_image_data();
            this.Register();
          }
          // else {
          // var headers = new Headers();
          // headers.append('Content-Type', 'application/json');
          // this.http.post(ipaddress.getIp.toString() + 'gen/otp/',
          //   JSON.stringify({
          //     mobile: encrypt_decript.Encript(this.register_personal_data.mobile).toString(),
          //     country: ipaddress.country_code
          //   }),
          //   { headers: headers })
          //   .subscribe(
          //     response => {
          //       
          //       var obj = JSON.parse(response["_body"]);
          //       if (obj.otp != null) {
          //         this.otp_open(obj.otp);
          //       }
          //     },
          //     error => {
          //       this.toastr.error("Internet is too slow or no connection, try again later");
          //     }
          //   )
          // }
        }, error => { });
  }
  // otp_open() {
  //   this.Register();
  //   // var otpval;
  //   // if(otp_data !="false"){
  //   //   otpval =encrypt_decript.Decript(otp_data);
  //   // }else{
  //   //   otpval = otp_data;
  //   // }
  // }
  dataLoad() {
    // this.send_client_address_data=null;
    if (this.clientPresentAddress.address1 == undefined || this.clientPresentAddress.location == undefined || this.clientPresentAddress.city == undefined ||
      this.clientPresentAddress.state == undefined || this.clientPresentAddress.country == undefined || this.clientPresentAddress.zipcode == undefined) {
      this.toastr.error(Message_data.enterPermenantAddrMandatory);
    } else if ((this.sameasFlag == undefined || this.sameasFlag == false) && (this.clientPermenantAddress.address1 == undefined || this.clientPermenantAddress.location == undefined || this.clientPermenantAddress.city == undefined ||
      this.clientPermenantAddress.state == undefined || this.clientPermenantAddress.country == undefined || this.clientPermenantAddress.zipcode == undefined)) {
      this.toastr.error(Message_data.enterPermenantAddrMandatory);
    } else { // validation Completed
      this.regClientAddressFlag = true;
      if (this.clientPresentAddress.address1 != null) {
        this.sendClientAddressArray.presentAddress = this.clientPresentAddress;
        this.sendClientAddressArray.presentAddress.type = "pres";
        if (this.sameasFlag != true) {
          this.sendClientAddressArray.presentAddress.permanent = "0";
          this.sendClientAddressArray.permenantAddress = this.clientPermenantAddress;
          this.sendClientAddressArray.permenantAddress.type = "perm";
          this.sendClientAddressArray.permenantAddress.permanent = "0";
        } else {
          this.sendClientAddressArray.presentAddress.permanent = "1";
        }
        return this.sendClientAddressArray;
      }
    }
  }
  donateblood() {
    this.blood = !this.blood;
    if (this.clientPersonalListArray.blood_donation == undefined || this.clientPersonalListArray.blood_donation == false) {
      this.clientPersonalListArray.blood_donation = true;
    } else if (this.clientPersonalListArray.blood_donation == true) {
      this.clientPersonalListArray.blood_donation = false;
    }
  }
  set_image_data() {
    if (this.imageFileSTR == "../../assets/img/default.jpg") {
      this.clientPersonalListArray.profile_image = "default";
      this.clientPersonalListArray.profile_image_flag = "default";
    } else {
      if (this.imageFileSTR != undefined) {
        this.clientPersonalListArray.profile_image = this.imageFileSTR.split(',')[1];
        this.clientPersonalListArray.profile_image_flag = "image";
      } else {
        this.clientPersonalListArray.profile_image = "default";
        this.clientPersonalListArray.profile_image_flag = "default";
      }
    }
  }
  gender() {
    document.getElementById("genderid").style.border = "1px solid #2ca8fe";
  }
  patName_toUpper() {
    if (this.clientPersonalListArray.first_name != undefined)
      this.clientPersonalListArray.first_name = this.clientPersonalListArray.first_name.toLocaleUpperCase();
    if (this.clientPersonalListArray.middle_name != undefined)
      this.clientPersonalListArray.middle_name = this.clientPersonalListArray.middle_name.toLocaleUpperCase();
    if (this.clientPersonalListArray.last_name != undefined)
      this.clientPersonalListArray.last_name = this.clientPersonalListArray.last_name.toLocaleUpperCase();
  }
}
