import { Component, OnInit } from '@angular/core';
import { Helper_Class } from '../../helper_class';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ipaddress } from '../../ipaddress';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-pharmamaster',
  templateUrl: './pharmamaster.component.html',
  styleUrls: ['./pharmamaster.component.scss']
})
export class PharmamasterComponent implements OnInit {
  provider_type: string;
  hptl_clinic_id: any;
  diaag: any;
  user_type: any;

  constructor(public http: HttpClient, public toastr: ToastrService,) { }
  public masterType;
  public masterpageheading;
  public genericinputname;
  public scheduleinputname;
  public iteminputname;
  public itemforminputname;
  public pharmainputname;
  public manufactinputname;
  public generic_id;
  public schedule_type_id;
  public item_form_id;
  public UserInfo;
  public pharma_id;
  public masterurl;
  public genericarray = [];
  public schedulearray = [];
  public itemarray = [];
  public pharmaarray = [];
  public manufactarray = [];
  public itemformarray = [];
  isSuccess: boolean = false;
  public pharmaid;

  ngOnInit(): void {
    this.changeProviderType();
    // this.UserInfo = Helper_Class.getInfo();
    var sendata;
    this.masterType = Helper_Class.getReportType()
    // this.pharma_id = this.UserInfo.pharma_id
   
  
    if (this.masterType == 'GM') {
      this.masterpageheading = "Generic Master"
      
      sendata = {
        pharma_id: this.pharmaid
      }
      this.genericdata(sendata)
    }
    else if (this.masterType == 'SM') {
      this.masterpageheading = "Schedule Master"
      sendata = {
        pharma_id: this.pharmaid
      }
      this.scheduledata(sendata)
    }
    else if (this.masterType == 'IM') {
      this.masterpageheading = "Item Master"
    }
    else if (this.masterType == 'IFM') {
      this.masterpageheading = "Item Form Master"
      sendata = {
        pharma_id: this.pharmaid
      }
      this.itemformdata(sendata)
    }
    else if (this.masterType == 'PM') {
      this.masterpageheading = "Pharma Master"
    }
    else if (this.masterType == 'MM') {
      this.masterpageheading = "Manufacturer Master"
    }
    else {
      this.masterpageheading = "errror"
    }
  }
  changeProviderType() {
    if (Helper_Class.getInfo().user_type === 'Admin' || Helper_Class.getInfo().user_type === 'front-desk') {
      this.hptl_clinic_id = Helper_Class.getInfo().hptl_clinic_id;
      this.diaag = Helper_Class.getInfo().diag_centre_id;
      this.pharmaid = Helper_Class.getInfo().pharma_id;
      if (Helper_Class.getInfo().user_type === 'Admin') {
        this.provider_type = 'Admin'; 
      } else {
        this.provider_type = 'front-desk';
      
      }
    } else if (Helper_Class.getInfo().user_type === 'doctor') {
      this.hptl_clinic_id = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.diaag = Helper_Class.getInfo().hospitals[0].diag_centre_id;
      this.pharmaid = Helper_Class.getInfo().hospitals[0].pharma_id;
      this.provider_type = 'doctor';
    } else if (Helper_Class.getInfo().user_type === 'pharmacy' || Helper_Class.getInfo().user_type === 'diagnosis' || Helper_Class.getInfo().user_type === 'physio') {
      this.hptl_clinic_id = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.diaag = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.pharmaid = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      if (Helper_Class.getInfo().user_type === 'pharmacy') {
        this.provider_type = 'pharmacy';
      } else if (Helper_Class.getInfo().user_type === 'diagnosis') {
        this.provider_type = 'diagnosis';
      } else {
        this.provider_type = 'physio';
      }
    }
    
    // if  (Helper_Class.getInfo().user_type == 'Admin') {
    //   this.hptl_clinic_id = Helper_Class.getInfo().hptl_clinic_id;
    //   this.diaag = Helper_Class.getInfo().diag_centre_id;
    //   this.pharmaid = Helper_Class.getInfo().pharma_id;
    //   this.provider_type = "Admin"
    // } 
    // else if (Helper_Class.getInfo().user_type == "front-desk") {
    //   this.hptl_clinic_id = Helper_Class.getInfo().hptl_clinic_id;
    //   this.diaag = Helper_Class.getInfo().diag_centre_id;
    //   this.pharmaid = Helper_Class.getInfo().pharma_id;
    //   this.provider_type = "front-desk"
    // }
    // else if (Helper_Class.getInfo().user_type == "doctor") {
    //   this.hptl_clinic_id = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
    //   this.diaag = Helper_Class.getInfo().hospitals[0].diag_centre_id;
    //   this.pharmaid = Helper_Class.getInfo().hospitals[0].pharma_id;
    //   this.provider_type = "doctor";
      
    // }
    // else if (Helper_Class.getInfo().user_type == "pharmacy") {
    //   this.hptl_clinic_id = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
    //   // this.diaag = Helper_Class.getInfo().hospitals[0].diag_centre_id;
    //   // this.pharmaid = Helper_Class.getInfo().hospitals[0].pharma_id;
    //   this.provider_type = "pharmacy";
    // } 
    // else if (Helper_Class.getInfo().user_type == "diagnosis") {
    //   this.hptl_clinic_id = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
    //   // this.diaag = Helper_Class.getInfo().hospitals[0].diag_centre_id;
    //   // this.pharmaid = Helper_Class.getInfo().hospitals[0].pharma_id;
    //   this.provider_type = "diagnosis";
    // }
    // else if (Helper_Class.getInfo().user_type == "physio") {
    //   this.hptl_clinic_id = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
    //   // this.diaag = Helper_Class.getInfo().hospitals[0].diag_centre_id;
    //   // this.pharmaid = Helper_Class.getInfo().hospitals[0].pharma_id;
    //   this.provider_type = "physio";
    // }
    // else if (Helper_Class.getInfo().user_type == "nurse") {
    //   this.hptl_clinic_id = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
    //   // this.diaag = Helper_Class.getInfo().hospitals[0].diag_centre_id;
    //   // this.pharmaid = Helper_Class.getInfo().hospitals[0].pharma_id;
    //   this.provider_type = "physio";
    // }
  
  }
  //generic
  genericdata(sendata) {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'mas/ggm', sendata,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          var genericlist = obj.generic_list
          console.log(JSON.stringify(obj))
          for (var i = 0; i < genericlist.length; i++) {
            console.log(obj.generic_list[i].generic_name)
            this.genericarray.push({
              genericname: genericlist[i].generic_name,
              generic_id: genericlist[i].generic_id
            })
          }
        }, error => {
          console.log("error in generic data")
        }
      )
  }

  addgeneric() {
    if (this.genericinputname != undefined) {
      if (this.genericarray.length != 0) {
        for (let i = 0; i < this.genericarray.length; i++) {

          if (this.genericarray[i].genericname == this.genericinputname) {
            this.toastr.error("Rack already exists");
            break;
          }
          else 
          {

            this.genericarray.push({

              genericname: this.genericinputname,
              generic_id: this.genericarray[i].generic_id,
              status: "1",
            });
            break;
          }
        }
      }
      else 
      {
        for (let i = 0; i < this.genericarray.length; i++) {
          this.genericarray.push({

            genericname: this.genericinputname,
            generic_id: this.genericarray[i].generic_id,
            status: "1",
          });
        }
      }

      this.savedata(this.genericinputname)
      this.genericarray = [];
      this.genericinputname = null;
    }

  }

  editgenericDetails(genericname, generic_id) {
    this.generic_id = generic_id
    for (var i = 0; i < this.genericarray.length; i++) {
      if (this.genericarray[i].genericname == genericname) {
        this.genericinputname = this.genericarray[i].genericname;

        this.genericarray.splice(i, 1);
      }
    }
  }

  deletegenericDetails(generic_id) {
    var deletegenericDetails
    var deletegenericstatus;
    var deletegenericid;
    var genericname;
    for (var i = 0; i < this.genericarray.length; i++) {
      console.log(JSON.stringify(this.genericarray[i]))
      if (this.genericarray[i].generic_id == generic_id) {
        this.genericarray[i].status = "0";
        deletegenericstatus = this.genericarray[i].status;
        deletegenericid = this.genericarray[i].generic_id;
        genericname = this.genericarray[i].genericname
      }
      deletegenericDetails = {
        generic_name: genericname,
        generic_id: deletegenericid,
        pharma_id: this.pharma_id,
        status: deletegenericstatus
      }
      console.log("after deleting genericed data", JSON.stringify(deletegenericDetails))
    }

    // console.log(datatobackend)
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + "mas/sgm", deletegenericDetails,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log(JSON.stringify(obj))


        }, error => {
          console.log("error in deleting item form data")
        }

      )
    this.genericarray = this.genericarray.filter(sample => sample.genericname !== genericname);
  }

  //schedule
  scheduledata(sendata) {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'mas/gst', sendata,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          var schedulelist = obj.schedule_list
          console.log(JSON.stringify(obj))
          for (var i = 0; i < schedulelist.length; i++) {
            console.log(obj.schedule_list[i].category)
            this.schedulearray.push({
              schedulename: schedulelist[i].category,
              schedule_type_id: schedulelist[i].schedule_type_id,
              status: "1"
            })
          }
        }, error => {
          console.log("error in schedule data")
        }
      )
  }

  addschedule() {
    if (this.scheduleinputname != undefined) {
      if (this.schedulearray.length != 0) {
        for (let i = 0; i < this.schedulearray.length; i++) {

          if (this.schedulearray[i].schedulename == this.scheduleinputname) {
            this.toastr.error("Rack already exists");
            break;
          }
          else {

            this.schedulearray.push({

              schedulename: this.scheduleinputname,
              schedule_type_id: this.schedulearray[i].schedule_type_id,
              status: "1",
            });
            break;
          }
        }
      }
      else {
        for (let i = 0; i < this.schedulearray.length; i++) {
          this.genericarray.push({
            // rackid:this.rackid,
            schedulename: this.scheduleinputname,
            schedule_type_id: this.schedulearray[i].schedule_type_id,
            status: "1",
          });
        }
      }
      this.savedata(this.scheduleinputname)
      this.schedulearray = [];
      // 
      this.scheduleinputname = null;
    }
  }

  editscheduleDetails(schedule_type_id, schedulename) {
    this.schedule_type_id = schedule_type_id;
    for (var i = 0; i < this.schedulearray.length; i++) {
      if (this.schedulearray[i].schedulename == schedulename) {
        this.scheduleinputname = this.schedulearray[i].schedulename;

        this.schedulearray.splice(i, 1);
      }
    }
  }

  deletescheduleDetails(schedule_type_id) {
    var deletescheduleDetails
    var deleteschedulestatus;
    var deletescheduleid;
    var deleteschedulename;
    for (var i = 0; i < this.schedulearray.length; i++) {
      if (this.schedulearray[i].schedule_type_id == schedule_type_id) {
        this.schedulearray[i].status = "0";
        deleteschedulestatus = this.schedulearray[i].status;
        deletescheduleid = this.schedulearray[i].schedule_type_id;
        deleteschedulename = this.schedulearray[i].schedulename;

      }
      deletescheduleDetails = {
        category: deleteschedulename,
        schedule_type_id: deletescheduleid,
        pharma_id: this.pharma_id,
        status: deleteschedulestatus
      }
      console.log("after saveing delete schedule data", JSON.stringify(deletescheduleDetails))
    }
    // console.log(datatobackend)
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + "mas/sst", deletescheduleDetails,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log(JSON.stringify(obj))

        }, error => {
          console.log("error in deleting item form data")
        }

      )
    this.schedulearray = this.schedulearray.filter(sample => sample.schedule_type_id !== schedule_type_id);
  }

  //itemformmaster
  itemformdata(sendata) {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'mas/gif', sendata,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          var pharmaitemformlist = obj.pharma_item_forms;
          console.log("product name", JSON.stringify(obj))
          console.log("product name", JSON.stringify(pharmaitemformlist))
          for (var i = 0; i < pharmaitemformlist.length; i++) {
            console.log(pharmaitemformlist[i].item_form_desc)
            this.itemformarray.push({
              itemformname: pharmaitemformlist[i].item_form_desc,
              item_form_id: pharmaitemformlist[i].item_form_id,
              status: "1"
            })
          }
        }, error => {
          console.log("error in schedule data")
        }
      )
  }

  additemform() {
    if (this.itemforminputname != undefined) {
      if (this.itemformarray.length != 0) {
        for (let i = 0; i < this.itemformarray.length; i++) {
          if (this.itemformarray[i].itemformname == this.itemforminputname) {
            this.toastr.error("Rack already exists");
            break;
          }
          else {
            this.itemformarray.push({
              // genericid:this.rackid,
              itemformname: this.itemforminputname,
              item_form_id: this.itemformarray[i].item_form_id,
              status: "1",
            });
            break;
          }
        }
      }
      else {
        for (let i = 0; i < this.itemformarray.length; i++) {
          this.itemformarray.push({
            itemformname: this.itemforminputname,
            item_form_id: this.itemformarray[i].item_form_id,
            status: "1",
          });
        }
      }
      this.savedata(this.itemforminputname)
      this.itemformarray = [];
      this.itemforminputname = null;
    }
  }

  edititemformDetails(itemformname, item_form_id) {
    this.item_form_id = item_form_id;
    for (var i = 0; i < this.itemformarray.length; i++) {
      if (this.itemformarray[i].itemformname == itemformname) {
        this.itemforminputname = this.itemformarray[i].itemformname;
        this.itemformarray.splice(i, 1);
      }
    }
  }

  deleteitemformDetails(item_form_id) {
    var deleteitemformDetails
    var deleteformstatus;
    var deleteitemformid;
    var deleteitemformname;
    for (var i = 0; i < this.itemformarray.length; i++) {
      console.log(JSON.stringify(this.itemformarray[i]))
      if (this.itemformarray[i].item_form_id == item_form_id) {
        this.itemformarray[i].status = "0";
        deleteformstatus = this.itemformarray[i].status;
        deleteitemformid = this.itemformarray[i].item_form_id;
        deleteitemformname = this.itemformarray[i].itemformname;
      }
      deleteitemformDetails = {
        item_form_desc: deleteitemformname,
        item_form_id: deleteitemformid,
        pharma_id: this.pharma_id,
        status: deleteformstatus
      }
      console.log("after saveing delete data", JSON.stringify(deleteitemformDetails))
    }
    // console.log(datatobackend)
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + "mas/sif", deleteitemformDetails,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log(JSON.stringify(obj))

        }, error => {
          console.log("error in deleting item form data")
        })
    this.itemformarray = this.itemformarray.filter(sample => sample.item_form_id !== item_form_id);
  }

  savedata(name) {
    var sendata = {
      pharma_id: this.pharma_id
    }
    var datatobackend;
    if (this.masterType == 'GM') {
      this.masterurl = "mas/sgm";
      if (this.generic_id != undefined) {
        console.log(this.generic_id)
        datatobackend = {
          generic_name: name,
          pharma_id: this.pharma_id,
          generic_id: this.generic_id,
          status: "1"
        }
      }
      else {
        datatobackend = {
          generic_name: name,
          pharma_id: this.pharma_id,
        }
      }
    }
    else if (this.masterType == 'SM') {
      this.masterurl = "mas/sst";
      if (this.schedule_type_id != undefined) {
        console.log(this.generic_id)
        datatobackend = {
          category: name,
          pharma_id: this.pharma_id,
          schedule_type_id: this.schedule_type_id,
          status: "1"
        }
      }
      else {
        datatobackend = {
          category: name,
          pharma_id: this.pharma_id,
        }
      }
    }
    else if (this.masterType == 'IFM') {
      this.masterurl = "mas/sif";
      if (this.item_form_id != undefined) {
        console.log(this.item_form_id)
        datatobackend = {
          item_form_desc: name,
          item_form_id: this.item_form_id,
          pharma_id: this.pharma_id,
          status: "1"
        }
      }
      else {
        datatobackend = {
          item_form_desc: name,
          pharma_id: this.pharma_id
        }
      }
    }
    else {
      console.log("error")
    }
    console.log(datatobackend)
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + this.masterurl, datatobackend,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log(JSON.stringify(obj))
          if (this.masterType == 'GM') {
            this.genericdata(sendata)
          } else if (this.masterType == 'SM') {
            this.scheduledata(sendata)
          } else if (this.masterType == 'IFM') {
            this.itemformdata(sendata)
          }
          else {
            console.log("error")
          }
        }, error => {
          console.log("error in generic data")
        }
      )
  }
}





