const ConnectorPluginV3 = (() => {
    /**
     * A class to interact with plugin v3
     * from TypeScript
     *
     * @date 2022-12-01
     * @author parzibyte
     * @see https://parzibyte.me/blog
     */
    class Operation {
        name: string;
        arguments: any[];
        constructor(name: string, argumentos: any[]) {
            this.name = name;
            this.arguments = argumentos;
        }
    }
    class ConectorPlugin {
        path: string;
        serial: string;
        operations: Operation[];
        static DEFAULT_PLUGIN_URL = "http://localhost:4200";
        static Operation = Operation;
        static NORMAL_IMAGE_SIZE = 0;
        static DOUBLE_WIDTH_IMAGE_SIZE = 1;
        static DOUBLE_HEIGHT_IMAGE_SIZE = 2;
        static DOUBLE_WIDTH_AND_HEIGHT_IMAGE_SIZE = 3;
        static LEFT_ALIGNMENT = 0;
        static CENTER_ALIGNMENT = 1;
        static RIGHT_ALIGNMENT = 2;
        static LOW_QR_RECOVERY = 0;
        static MEDIUM_QR_RECOVERY = 1;
        static HIGH_QR_RECOVERY = 2;
        static BEST_QR_RECOVERY = 3;
        constructor(path: string = ConectorPlugin.DEFAULT_PLUGIN_URL, serial: string = "") {
            this.path = path;
            this.serial = serial;
            this.operations = [];
            return this;
        }
        LoadLocalImageAndPrint(path: string, size: number, maxWidth: number) {
            this.operations.push(new ConectorPlugin.Operation("LoadLocalImageAndPrint", Array.from(arguments)));
            return this;
        }

        Cut(lines: number) {
            this.operations.push(new ConectorPlugin.Operation("Cut", Array.from(arguments)));
            return this;
        }

        PartialCut() {
            this.operations.push(new ConectorPlugin.Operation("PartialCut", Array.from(arguments)));
            return this;
        }

        DefineCustomCharacter(replacementCharacter: string, matrix: string) {
            this.operations.push(new ConectorPlugin.Operation("DefineCustomCharacter", Array.from(arguments)));
            return this;
        }
        DownloadImageFromInternetAndPrint(imageUrl: string, size: number, maxWidth: number) {
            this.operations.push(new ConectorPlugin.Operation("DownloadImageFromInternetAndPrint", Array.from(arguments)));
            return this;
        }
        DisableCustomCharacters() {
            this.operations.push(new ConectorPlugin.Operation("DisableCustomCharacters", Array.from(arguments)));
            return this;
        }
        DisableChineseCharacterMode() {
            this.operations.push(new ConectorPlugin.Operation("DisableChineseCharacterMode", Array.from(arguments)));
            return this;
        }
        WriteText(text: string) {
            this.operations.push(new ConectorPlugin.Operation("WriteText", Array.from(arguments)));
            return this;
        }
        SetAlignment(alignment: number) {
            this.operations.push(new ConectorPlugin.Operation("SetAlignment", Array.from(arguments)));
            return this;
        }
        SetEmphasis(emphasis: boolean) {
            this.operations.push(new ConectorPlugin.Operation("SetEmphasis", Array.from(arguments)));
            return this;
        }
        SetFont(font: number) {
            this.operations.push(new ConectorPlugin.Operation("SetFont", Array.from(arguments)));
            return this;
        }
        SetInversePrinting(invert: boolean) {
            this.operations.push(new ConectorPlugin.Operation("SetInversePrinting", Array.from(arguments)));
            return this;
        }

        SetInverseBlackAndWhitePrinting(invert: boolean) {
            this.operations.push(new ConectorPlugin.Operation("SetInverseBlackAndWhitePrinting", Array.from(arguments)));
            return this;
        }

        Set90DegreeRotation(rotate: boolean) {
            this.operations.push(new ConectorPlugin.Operation("Set90DegreeRotation", Array.from(arguments)));
            return this;
        }

        SetUnderline(underline: boolean) {
            this.operations.push(new ConectorPlugin.Operation("SetUnderline", Array.from(arguments)));
            return this;
        }

        SetFontSize(widthMultiplier: number, heightMultiplier: number) {
            this.operations.push(new ConectorPlugin.Operation("SetFontSize", Array.from(arguments)));
            return this;
        }

        Feed(lines: number) {
            this.operations.push(new ConectorPlugin.Operation("Feed", Array.from(arguments)));
            return this;
        }

        EnableCustomCharacters() {
            this.operations.push(new ConectorPlugin.Operation("EnableCustomCharacters", Array.from(arguments)));
            return this;
        }

        EnableChineseCharacterMode() {
            this.operations.push(new ConectorPlugin.Operation("EnableChineseCharacterMode", Array.from(arguments)));
            return this;
        }

        PrintCodabarBarcode(content: string, height: number, width: number, imageSize: number) {
            this.operations.push(new ConectorPlugin.Operation("PrintCodabarBarcode", Array.from(arguments)));
            return this;
        }

        PrintCode128Barcode(content: string, height: number, width: number, imageSize: number) {
            this.operations.push(new ConectorPlugin.Operation("PrintCode128Barcode", Array.from(arguments)));
            return this;
        }

        PrintCode39Barcode(content: string, includeChecksum: boolean, fullAsciiMode: boolean, height: number, width: number, imageSize: number) {
            this.operations.push(new ConectorPlugin.Operation("PrintCode39Barcode", Array.from(arguments)));
            return this;
        }

        PrintCode93Barcode(content: string, height: number, width: number, imageSize: number) {
            this.operations.push(new ConectorPlugin.Operation("PrintCode93Barcode", Array.from(arguments)));
            return this;
        }

        PrintEanBarcode(content: string, height: number, width: number, imageSize: number) {
            this.operations.push(new ConectorPlugin.Operation("PrintEanBarcode", Array.from(arguments)));
            return this;
        }

        PrintEan8Barcode(content: string, height: number, width: number, imageSize: number) {
            this.operations.push(new ConectorPlugin.Operation("PrintEan8Barcode", Array.from(arguments)));
            return this;
        }

        PrintPdf417Barcode(content: string, securityLevel: number, height: number, width: number, imageSize: number) {
            this.operations.push(new ConectorPlugin.Operation("PrintPdf417Barcode", Array.from(arguments)));
            return this;
        }

        PrintTwoOfFiveITFBarcode(content: string, interleaved: boolean, height: number, width: number, imageSize: number) {
            this.operations.push(new ConectorPlugin.Operation("PrintTwoOfFiveITFBarcode", Array.from(arguments)));
            return this;
        }

        PrintUpcABarcode(content: string, height: number, width: number, imageSize: number) {
            this.operations.push(new ConectorPlugin.Operation("PrintUpcABarcode", Array.from(arguments)));
            return this;
        }

        PrintUpcEBarcode(content: string, height: number, width: number, imageSize: number) {
            this.operations.push(new ConectorPlugin.Operation("PrintUpcEBarcode", Array.from(arguments)));
            return this;
        }

        PrintQrCode(content: string, maxWidth: number, recoveryLevel: number, imageSize: number) {
            this.operations.push(new ConectorPlugin.Operation("PrintQrCode", Array.from(arguments)));
            return this;
        }

        PrintImageInBase64(base64EncodedImage: string, size: number, maxWidth: number) {
            this.operations.push(new ConectorPlugin.Operation("PrintImageInBase64", Array.from(arguments)));
            return this;
        }
        ///////
        Start() {
            this.operations.push(new ConectorPlugin.Operation("Start", Array.from(arguments)));
            return this;
        }

        Pulse(pin: number, onTime: number, offTime: number) {
            this.operations.push(new ConectorPlugin.Operation("Pulse", Array.from(arguments)));
            return this;
        }

        TextAccordingToCodePage(pageNumber: number, page: string, text: string) {
            this.operations.push(new ConectorPlugin.Operation("TextAccordingToCodePage", Array.from(arguments)));
            return this;
        }


        static async getPrinters(path: string = ConectorPlugin.DEFAULT_PLUGIN_URL) {
            const response = await fetch(ConectorPlugin.DEFAULT_PLUGIN_URL + "/printers");
            return await response.json();
        }

        static async getRemotePrinters(path: string, remotePath: string = ConectorPlugin.DEFAULT_PLUGIN_URL) {
            const response = await fetch(ConectorPlugin.DEFAULT_PLUGIN_URL + "/forward?host=" + remotePath);
            return await response.json();
        }


        async printToRemotePrinter(printerName: string, remotePath: string) {
            const payload = {
                operations: this.operations,
                printerName,
                serial: this.serial,
            };
            const response = await fetch(this.path + "/forward?host=" + remotePath, {
                method: "POST",
                body: JSON.stringify(payload),
            });
            return await response.json();
        }

        async printTo(printerName: string) {
            const payload = {
                operations: this.operations,
                printerName,
                serial: this.serial,
            };
            const response = await fetch(this.path + "/print", {
                method: "POST",
                body: JSON.stringify(payload),
            });
            return await response.json();
        }
    }
    return ConectorPlugin;
})();
export default ConnectorPluginV3;