import { Component, OnInit } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { CommonDataService } from '../../providers/common-data.service';
import { ConvertTimeformat, Date_Formate, Time_Formate_Session } from '../../../assets/js/common';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from '../../helper_class';
import { DiagnosiscancelComponent } from '../diagnosiscancel/diagnosiscancel.component';
import { Diagnosis_Helper } from '../Diagnosis_Helper';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';

@Component({
  selector: 'app-diagnosisappointmentsview',
  templateUrl: './diagnosisappointmentsview.component.html',
  styleUrls: ['./diagnosisappointmentsview.component.css']
})
export class DiagnosisappointmentsviewComponent implements OnInit {
  public appID;
  public websiteflag:boolean=false;
  public diagStatus;
  public fulladress;
  public docname;
  public patientid;
  public totName;
  public diagTestArray = [];
  public relationFalg: boolean;
  public clientName;
  public clientBlood;
  public agegenderflag:boolean=false;
  public clientGender;
  public clientMobile;
  public gendervalue;
  public referedBy: boolean;
  public diagDoctorName;
  public session;
  public centerName;
  public diagAddress;
  public location;
  public cityZip;
  public state;
  public phone;
  public website;
  public appointDate;
  public get12Time;
  public fromFirstTimeArray;
  public fromtimeArray;
  public relationType;
  public timeAmpmHome;
  public time1: boolean;
  public time2: boolean;
  public time3: boolean;
  public disableSessionFlag: boolean;
  public cancelAppFlag: boolean;
  public sampleCollectFlag: boolean;
  public allotFlag: boolean;
  public sessionArray = [];
  public currentDateTime: string;
  public currentTime;
  public curtTime;
  public allotTime;
  public toTimingArray = [];
  public fromTimeArray = [];
  public sessionFlag: boolean;
  public age;
  public getDiagnosis;
  public userType;
  public userInfo;
  public date;
  public dateRequiredFlag: boolean;
  public bloodFlag: boolean = false;
  constructor(public messageservice: MenuViewService, public toastr: ToastrService, public gservice: CommonDataService, public http: Http, public dialog: MatDialog, public frontdeskservice: MenuViewService) {
    this.relationFalg = true;
    this.referedBy = true;
    this.allotFlag = true;
    this.sampleCollectFlag = true;
    this.sessionArray.push("Morning");
    this.sessionArray.push("Afternoon");
    this.sessionArray.push("Evening");
    this.date;
  }

  ngOnInit(): void {
    this.userInfo = Helper_Class.getInfo();
    if (this.userInfo.user_type == 'Admin') {
      this.userType = Helper_Class.getInfo().user_type;
      this.getDiagnosis = "frontdesk";
      this.appID = this.userInfo.diag_center_id;
      this.sampleCollectFlag == true;

    } else{
      this.userType = Diagnosis_Helper.getUserID_Type().user_type;
      this.getDiagnosis = Helper_Class.getdiagnosisappview();
      this.appID = this.getDiagnosis.diag_id;
    }
   
    this.diagStatus = this.getDiagnosis.status_txt;
    if (this.diagStatus == "Closed" || this.diagStatus == "Sample collected") {
      this.allotFlag = true;
      this.sampleCollectFlag = true;
      this.cancelAppFlag = true;
    }
    if (this.getDiagnosis.flow == "frontdesk") {
      this.sampleCollectFlag == true;
    }
    this.toTimingArray = [];
    for (var i = 0; i <= 55; i += 5) {
      if (i < 10)
        this.toTimingArray.push("0" + i);
      else
        this.toTimingArray.push(i);
    }
    this.fromTimeArray = [];
    for (var i = 1; i <= 12; i++) {
      if (i < 10)
        this.fromTimeArray.push("0" + i);
      else
        this.fromTimeArray.push(i);
    }
    this.timeAmpmHome = "AM";
    this.fromtimeArray = "00";
    this.fromFirstTimeArray = "06";
    //Get current date
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate',
      JSON.stringify({
        country: ipaddress.country_code.toString()
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.current_date != null) {
            this.currentDateTime = obj.current_date;
            this.appointDate = obj.current_date;
          }
          if (obj.current_time != null) {
            this.currentTime = obj.current_time;
          }
        }, error => { });
    this.getApp();
  }

  getApp() {//Get appoiintment view
    // Initializes a new instance of the StringBuilder class and appends the given value if supplied
    function StringBuilder(this: any, value) {
      this.strings = new Array("");
      this.append(value);
    }
    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }
    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }
    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'appointment/viewapp/',
      JSON.stringify({
        diag_app_id: this.appID,
        type: "diagnosis",
        home_care: "0",
        country: "IN",
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          console.log(">>>>>>>>>>>>>>>>>"+JSON.stringify(obj))
          if (obj != null) {
            this.date = Date_Formate(obj.date);
            if (obj.relation != undefined && obj.relation != "") {
              this.relationFalg = true;
              this.relationType = obj.relation;
            } else {
              this.relationFalg = false;
              this.relationType = obj.relation;
            }
            if (obj.relation != undefined && obj.relation != "") {
              this.relationFalg = true;
              this.relationType = obj.relation;
            }
            if (obj.middle_name != null && encrypt_decript.Decript(obj.middle_name) != "") {
              this.clientName = encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.middle_name) + " " + encrypt_decript.Decript(obj.last_name);
            } else if (obj.first_name != null && obj.first_name != undefined) {
              if (obj.last_name != null && obj.last_name != undefined) {
                this.clientName = encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.last_name);
              } else {
                this.clientName = encrypt_decript.Decript(obj.first_name);
              }
            }

            if(obj.dr_first_name != undefined) {
              if(obj.dr_middle_name != undefined){
                this.docname="Dr"+" "+obj.dr_first_name+" "+obj.dr_middle_name+" "+obj.dr_last_name;
              } else{
                this.docname="Dr"+" "+obj.dr_first_name+" "+obj.dr_last_name;
              }
            }
            
            if (obj.patient_id != undefined) {
              this.patientid = obj.patient_id;
              //this.bloodFlag = true;
            }
            if (obj.blood_group != "" && obj.blood_group != null) {
              this.clientBlood = obj.blood_group;
              this.bloodFlag = true;
            }
            if (obj.gender != null && obj.gender != undefined) {
              this.agegenderflag =true;
              this.clientGender = encrypt_decript.Decript(obj.gender);
              if(this.clientGender == "Male"){
                this.gendervalue = "M"
              }else if(this.clientGender == "Female"){
                this.gendervalue = "F"
              }else{
                this.gendervalue = "T"
              }
            } if (obj.age != null && obj.age != undefined) {
              this.agegenderflag =true;
              this.age = encrypt_decript.Decript(obj.gender);
            }
          
            if (obj.mobile != null && obj.mobile != undefined) {
              this.clientMobile = encrypt_decript.Decript(obj.mobile);
            }
            if (obj.referred_by != "" && obj.referred_by != undefined && obj.referred_by != null) {
              this.referedBy = false;
              this.diagDoctorName = "Dr." + obj.referred_by;
            } else {
              this.referedBy = true;
            }
            if (obj.centre_name != undefined) {
              this.centerName = obj.centre_name;
            }
            if (obj.address2 != undefined) {
              this.diagAddress = encrypt_decript.Decript(obj.address1) + " " + encrypt_decript.Decript(obj.address2);
            } else {
              this.diagAddress = encrypt_decript.Decript(obj.address1);
            }
            if(this.diagAddress != undefined ){
              this.fulladress = this.diagAddress+","+obj.location+","+obj.city + "-" + encrypt_decript.Decript(obj.zipcode)+","+obj.state + "," + obj.country
            }
            if (obj.location != undefined) {
              this.location = obj.location;
            }
            if (obj.city != undefined) {
              this.cityZip = obj.city + "-" + encrypt_decript.Decript(obj.zipcode);
            }
            if (obj.state != undefined) {
              this.state = obj.state + "," + obj.country;
            }
            if (obj.phone != undefined) {
              this.phone = obj.phone;
            }
        
           // this.website = obj.website != undefined ? obj.website : "";
            if(obj.website  != undefined){
              this.websiteflag=true
            }
           
            this.session = obj.session;
            this.sessionFlag = this.session == null && this.session == undefined ? true : false;
            if (obj.f_time != null) {
              var appdate = obj.date.split;
              var newdate = appdate[2] + "-" + appdate[1] + "-" + appdate[0];
              this.appointDate = obj.date;
              var Timesplit = Time_Formate_Session(obj.f_time).split(':');
              this.get12Time = (Timesplit[0] + ":" + Timesplit[1]);
              var getTime12hours = this.get12Time.split(":"); // 12:00 PM
              var timeconvert1 = getTime12hours[1].split(" ");
              this.fromFirstTimeArray = getTime12hours[0];
              this.fromtimeArray = timeconvert1[0];
              this.timeAmpmHome = timeconvert1[1];
              this.time1 = true;
              this.time2 = true;
              this.time3 = true;
              this.disableSessionFlag = true;
              if (this.userType != null) {
                if (this.userType == "Chief lab technician" || this.userType == "Lab technician") {
                  this.cancelAppFlag = false;
                  this.sampleCollectFlag = false;
                  this.allotFlag = true;
                } else {// Others
                  this.cancelAppFlag = true;
                  this.sampleCollectFlag = true;
                  this.allotFlag = true;
                }
              } else {// Diag admin
                this.sampleCollectFlag = false;
                this.allotFlag = true;
              }
            } else {
              this.allotFlag = false;
              this.sampleCollectFlag = true;
            }
            if (obj.status == 0 && obj.is_cancelled == 0) {
              this.toastr.success(Message_data.apptProcessCompleted);
            }
            else if (obj.status == 2 && obj.is_cancelled == 0) {
              this.toastr.error(Message_data.pastApptCantbeEdited);
              this.dateRequiredFlag = false;
              this.sampleCollectFlag = true;
            }
            else if (obj.status == 3 || obj.status == 0) {
              this.sampleCollectFlag = true;
              this.allotFlag = true;
              this.cancelAppFlag = true;
              this.dateRequiredFlag = false;
              this.toastr.error(Message_data.pastApptCantbeEdited);
            }
            // Test Table data
            this.diagTestArray = [];
            if (obj.lab_test != null) {
              for (var i = 0; i < obj.lab_test.length; i++) {
                this.diagTestArray.push({
                  Groupname: "Laboratory",
                  Testname: obj.lab_test[i].diag_test_name,
                  Pres_test_id: obj.lab_test[i].pres_test_id,
                });
              }
            }
            if (obj.radio_test != null) {
              for (var i = 0; i < obj.radio_test.length; i++) {
                this.diagTestArray.push({
                  Groupname: "Radiology",
                  Testname: obj.radio_test[i].diag_test_name,
                  Pres_test_id: obj.radio_test[i].pres_test_id,
                });
              }
            }
            if (obj.profile_test != null) {
              for (var i = 0; i < obj.profile_test.length; i++) {
                this.diagTestArray.push({
                  Groupname: "Laboratory",
                  Testname: obj.profile_test[i].diag_test_name,
                  Pres_test_id: obj.profile_test[i].pres_test_id,
                });
              }
            }

            if (obj.packages != null) {
              for (var i = 0; i < obj.packages.length; i++) {
                this.diagTestArray.push({
                  Groupname: "Package",
                  Testname: obj.packages[i].diag_test_name,
                  Pres_test_id: obj.packages[i].pres_test_id,
                });
              }
            }
          }
        }, error => {
          this.toastr.error(Message_data.error);
        });
  }
  cancel() {   // cancel app popup
    const dialogRef = this.dialog.open(DiagnosiscancelComponent, {
      width: '30%',
      height: '200px',
      data: {
      }
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data.toString().trim() == "" && data.length == 0)
        this.toastr.error(Message_data.appcancelreason);
      else if (data.length > 150)
        this.toastr.error(Message_data.maxlen150);
      else {
        var cancel_list = [];
        cancel_list.push(
          this.appID
        );
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp.toString() + 'appointment/cancelapp',
          JSON.stringify({
            diag_cancel: cancel_list,
            reason: data
          }),
          { headers: headers })
          .subscribe(
            response => {
              var obj = JSON.parse(response["_body"]);
              if (obj.key != null) {
                if (obj.key == "1") {
                  this.toastr.success(Message_data.appcancel);
                  this.messageservice.sendMessage("appointment");
                }
              }
            })
      }
    });
  }
  
  sample() {//sample collected
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/updatediag/',
      JSON.stringify({
        appointment_id: this.appID,
        country: ipaddress.country_code
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.result == "Updated successfully") {
            this.toastr.success(Message_data.sampleCollectedSuccess);
            this.messageservice.sendMessage("appointment");
          } else {
            this.toastr.error(obj.result);
          }
        },
        error => {
          this.toastr.error(Message_data.error);
        });
  }
  allot() {//allot
    var Valid_flag = true;
    if (this.appointDate == undefined) {
      this.toastr.error(Message_data.sltDate);
      var Valid_flag = false;
    } else if (this.session == undefined) {
      this.toastr.error(Message_data.sltSession);
      var Valid_flag = false;
    } else {
      this.allotTime = ConvertTimeformat("", this.fromFirstTimeArray + ":" + this.fromtimeArray + " " + this.timeAmpmHome);
      var Timesplit = this.allotTime.split(':');
      this.curtTime = this.currentTime.split(':');
      if (new Date(this.currentDateTime) < new Date((this.appointDate))) { // Date not Equal
        if (this.session == "Morning") {
          if (parseInt(Timesplit[0]) >= 12) {
            this.toastr.error(Message_data.enterValidTime);
            var Valid_flag = false;
          }
        } else if (this.session == "Afternoon") {
          if (parseInt(Timesplit[0]) < 12 || parseInt(Timesplit[0]) > 16) {
            this.toastr.error(Message_data.enterValidTime)
            var Valid_flag = false;
          }
        } else if (this.session == "Evening") {
          if (parseInt(Timesplit[0]) < 16) {
            this.toastr.error(Message_data.enterValidTime)
            var Valid_flag = false;
          }
        }
      } else {
        if (this.session == "Morning") {
          if (this.timeAmpmHome == "PM") {
            this.toastr.error(Message_data.enterValidTime)
            var Valid_flag = false;
          } else if (Timesplit[0] >= 12) {
            this.toastr.error(Message_data.enterValidTime)
            var Valid_flag = false;
          } else {
            if (parseInt(this.curtTime[0]) > parseInt(Timesplit[0])) {
              this.toastr.error(Message_data.enterValidTime)
              var Valid_flag = false;
            } else if (parseInt(this.curtTime[0]) == parseInt(Timesplit[0])) {
              if (parseInt(this.curtTime[1]) >= parseInt(Timesplit[1])) {
                this.toastr.error(Message_data.enterValidTime)
                var Valid_flag = false;
              }
            }
          }
        } else if (this.session == "Afternoon") {
          if (this.timeAmpmHome == "AM") {
            this.toastr.error(Message_data.enterValidTime)
            var Valid_flag = false;
          } else if (parseInt(Timesplit[0]) < 12 || parseInt(Timesplit[0]) > 16) {
            this.toastr.error(Message_data.enterValidTime)
            var Valid_flag = false;
          } else {
            if (parseInt(this.curtTime[0]) > parseInt(Timesplit[0])) {
              this.toastr.error(Message_data.enterValidTime)
              var Valid_flag = false;
            } else if (parseInt(this.curtTime[0]) == parseInt(Timesplit[0])) {
              if (parseInt(this.curtTime[1]) >= parseInt(Timesplit[1])) {
                this.toastr.error(Message_data.enterValidTime)
                var Valid_flag = false;
              }
            }
          }
        } else if (this.session == "Evening") {
          if (this.timeAmpmHome == "AM") {
            this.toastr.error(Message_data.enterValidTime)
            var Valid_flag = false;
          } else if (parseInt(Timesplit[0]) < 16) {
            this.toastr.error(Message_data.enterValidTime)
            var Valid_flag = false;
          } else {
            if (parseInt(this.curtTime[0]) > parseInt(Timesplit[0])) {
              this.toastr.error(Message_data.enterValidTime)
              var Valid_flag = false;
            } else if (parseInt(this.curtTime[0]) == parseInt(Timesplit[0])) {
              if (parseInt(this.curtTime[1]) >= parseInt(Timesplit[1])) {
                this.toastr.error(Message_data.enterValidTime)
                var Valid_flag = false;
              }
            }
          }
        }
      }
    }
    if (Valid_flag == true) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'appointment/reschedule/',
        JSON.stringify({
          diag_app_id: this.appID,
          date: this.appointDate,
          session: this.session,
          f_time: this.allotTime
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            if (obj.result == "Success") {
              this.toastr.success(Message_data.timeAllottedSuccess);
              this.messageservice.sendMessage("appointment");
            } else {
              this.toastr.error(obj.result);
            }
          }, error => {
            this.toastr.error(Message_data.error);
          });
    }
  }
  changeAppoinmentDate(selecteddate) {//appointmnet date
    this.appointDate = selecteddate;
    if (new Date(this.currentDateTime) < new Date((selecteddate))) {
      this.sessionArray = [];
      this.sessionArray.push("Morning");
      this.sessionArray.push("Afternoon");
      this.sessionArray.push("Evening");
    } else {
      var Timesplit = this.currentTime.split(':');
      if (Timesplit[0] < 12) {
        this.sessionArray = [];
        this.sessionArray.push("Morning");
        this.sessionArray.push("Afternoon");
        this.sessionArray.push("Evening");
      } else if (Timesplit[0] >= 12 && Timesplit[0] < 16) {
        this.sessionArray = [];
        this.sessionArray.push("Afternoon");
        this.sessionArray.push("Evening");
      } else if (Timesplit[0] >= 16) {
        this.sessionArray = [];
        this.sessionArray.push("Evening");
      }
    }
  }
  backClicked() {
    if(this.getDiagnosis.flow == "frontdesk")
      this.frontdeskservice.sendMessage("manageappointments");
    else
      this.messageservice.sendMessage("appointment");
  }
}
