<div class="row">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white" style="padding:7px 20px;">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Prescription details</h5>
          </div>
          <div class="headerButtons">
            <img src="../../../assets/ui_icons/buttons/Back_button.svg" (click)="back()" class="saveimgbtn_inpatinfo" />
            <img [hidden]="seacrhFlag" class="saveimgbtn_inpatinfo" (click)="search()"
              src="../../../assets/ui_icons/buttons/search_button.svg" />
            <img [hidden]="bookFlag" class="saveimgbtn_inpatinfo"  (click)="book()"
              src="../../../assets/ui_icons/buttons/Next_button.svg" />
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row" style="padding-left: 23px;padding-right: 23px;">
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngFor="let address of doctorAddrData">
            <div class="row">
              <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <div class="cover_div">
                  <div class="header_lable">
                    Refered Doctor
                  </div>
                  <div class="content_cover">
                    <div class="row" >
                      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <mat-label class="matlabel"><b>Dr. {{address.name}}</b></mat-label>
                      </div>
                      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <mat-label class="matlabel" *ngIf="hospitalFlag"><b>{{address.hosp_name}}</b></mat-label>
                      </div>
                      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <mat-label class="matlabel" *ngIf="hospitalFlag">{{address.hos_address}} {{address.hos_location + ", " + address.hos_city}}</mat-label>
                      </div>
                      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <mat-label class="matlabel" *ngIf="hospitalFlag">{{address.hos_state}}</mat-label>
                      </div>
                      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <mat-label class="matlabel" *ngIf="hospitalFlag">{{address.hos_telephone}}</mat-label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <div class="cover_div" style="padding-bottom: 13px;">
                  <div class="header_lable">
                    Suggested Center
                  </div>
                  <div class="content_cover">
                    <div class="row" >
                      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <mat-label class="matlabel">{{address.hos_center }}</mat-label>
                      </div>
                      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <mat-label class="matlabel">{{address.address1}}</mat-label>
                      </div>
                      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <mat-label class="matlabel">{{address.location + ", " + address.city}}</mat-label>
                      </div>
                      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <mat-label class="matlabel">{{address.state}}</mat-label>
                      </div>
                      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <mat-label class="matlabel">{{address.telephone}}</mat-label>
                      </div>
                    </div>
                  </div>
              </div>
              </div>
             </div>
          </div>
          <div class="row" style="margin-top: 5px;">
            <div *ngFor="let address of doctorAddrData">
              <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <mat-label class="matlabel"><b>Relationship :</b> {{address.relation}}</mat-label>
              </div>
              <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <mat-label class="matlabel"><b>Date : </b>{{address.date}}</mat-label>
              </div>
            </div>
          </div>
          <div class="row" style="margin-top: 5px;">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div class="table-responsive dig_table_overflow">
                <table id="tbl" class="table table-hover table-dynamic">
                  <thead>
                    <tr>
                      <th>Test type</th>
                      <th>Test name</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let tests of testType">
                      <td class="align_left" st data-th="Test type">{{tests.test}}</td>
                      <td class="align_left" data-th="Test name">{{tests.subtest}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>