import { Component, OnInit } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Time_Formate } from '../../../assets/js/common';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Helper_Class} from '../../helper_class';
import { ConvertTimeformat, session_based_time_fun, Date_Formate } from '../../../assets/js/common';
import { PopupDoctorScheduleListComponent } from '../popup-doctor-schedule-list/popup-doctor-schedule-list.component';
import { ipaddress } from '../../ipaddress' ;
import { Message_data } from '../../../assets/js/Message_data';

@Component({
  selector: 'app-doctor-schedule-list-page',
  templateUrl: './doctor-schedule-list-page.component.html',
  styleUrls: ['./doctor-schedule-list-page.component.css'],
  
})
export class DoctorScheduleListPageComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  public doctor_schdule_date:any = [];
  public ret_ftime;
  public ret_ttime;
  public userinfo:any;
  public user_id:any;
  private usertype:string;
  public deletebutton:boolean=false;
  hptl_clinic_id: any;
  user_ids: any;
  user_type: any;
  is_admin: boolean;
  doctorid: any;
  doctorList: any[];
  constructor(public dialog: MatDialog,public  http:Http,public  router:Router, public  toastr:ToastrService) {
  }

  ngOnInit(): void {
    this.dtOptions = {
      pageLength: 25,
      "lengthMenu": [ [25, 50, -1], [25, 50, "All"] ],
      "columnDefs": [{ "orderable": false, "targets": [1,2,3] },],
      "language": {"search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: ""
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 d-flex justify-content-end text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    if(Helper_Class.getmodulelist() != undefined){
      for (var i = 0; i < Helper_Class.getmodulelist().length; i++) {
        if (Helper_Class.getmodulelist()[i].module_id == "9"){
            if(Helper_Class.getmodulelist()[i].edit == "1"){
             // this.editbutton=true;
            }
            if(Helper_Class.getmodulelist()[i].create == "1"){
              //this.newbutton=true;
            }
            if(Helper_Class.getmodulelist()[i].delete == "1"){
             this.deletebutton=true;
            }
            if(Helper_Class.getmodulelist()[i].print == "1"){
             // this.printbutton=true;
            }
            if(Helper_Class.getmodulelist()[i].view == "1"){
             // this.viewbutton=true;
            }
            
            
        }
       
      }
    }
    this.userinfo = Helper_Class.getInfo();
   
    if (Helper_Class.getInfo().user_type == 'doctor') {
      this.user_id = this.userinfo.user_id;
      this.is_admin=false;
      this.usertype="doctor";
      this.getDocSchedules();

    } else {
      this.is_admin=true;
      this.usertype="admin";
      this.changeProviderType();
    }
    this.doctorid="All";
  }

  backClicked() {
    this.router.navigateByUrl("/schedule")
  }

  openSchedulePopup(){
    const dialogRef = this.dialog.open(PopupDoctorScheduleListComponent, {
      width: '650px',
      height: '500px',
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getDocSchedules();
    });
  }

  changeProviderType() {
    this.hptl_clinic_id = Helper_Class.getInfo().hptl_clinic_id;
    this.is_admin = true;
    this.getDoctors();
    
    // if (Helper_Class.getInfo().user_type == 'Admin') {
    //   this.hptl_clinic_id = Helper_Class.getInfo().hptl_clinic_id;
    //   this.user_ids = Helper_Class.getInfo().user_id;
    //   this.user_type = Helper_Class.getInfo().user_type;
    //   this.is_admin = true;
    //   this.getDoctors();
    // }
  }

  changedoc(){
    this.user_id = this.doctorid;
    this.userinfo.user_id = this.doctorid;
    this.getDocSchedules();
  }

  getDoctors() {
    this.doctorList = [];
    var send_data = {
      country: 'IN',
      doc_reg_id: this.user_ids,
      type: this.usertype,
      hptl_clinic_id: this.hptl_clinic_id,
    };
    
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(ipaddress.getIp.toString() + 'adm/docbyhosp', JSON.stringify(send_data), { headers: headers })
      .subscribe(
        (response) => {
          var obj = JSON.parse(response["_body"]);
          console.log('check---' +  JSON.stringify(obj));
          if (obj != null) {
            for (var i = 0; i < obj.doctors.length; i++) {
              var doctorName = '';
              var fname, mname, lname;
              if (obj.doctors[i].middle_name != '' &&obj.doctors[i].middle_name != undefined) {
                doctorName = obj.doctors[i].first_name + ' ' + obj.doctors[i].middle_name + ' ' + obj.doctors[i].last_name;
                fname = obj.doctors[i].first_name;
                mname = obj.doctors[i].middle_name;
                lname = obj.doctors[i].last_name;

              } else {
                doctorName = obj.doctors[i].first_name + ' ' + obj.doctors[i].last_name;
                fname = obj.doctors[i].first_name;
                lname = obj.doctors[i].last_name;
              }

              this.doctorList.push({
                Doc_Name: doctorName,
                Doc_ID: obj.doctors[i].prov_id,
                type: this.userinfo.user_type,
              });
            }
            //this.doctorid = this.doctorList[0].Doc_ID;
            //this.user_id = this.doctorList[0].Doc_ID;
            this.getDocSchedules();

            // this.doctorid = this.userinfo.user_id;
            // this.user_id = this.userinfo.user_id;
            // for (var i = 0; i < this.doctorList.length; i++) {
            //   if (this.doctorList[i].Doc_ID == this.userinfo.user_id) {

            //   }
            // }
          }
        },
        (error) => {

        }
      );
  }

  //Get retrivel doctor schedule
  getDocSchedules() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'usercontroller/gdocavail/',
      JSON.stringify({
        hptl_clinic_id:this.hptl_clinic_id,
        doc_reg_id:this.doctorid,
        type: "doctor",
      }),
      { headers: headers })
      .subscribe(
        response => {
          
          this.doctor_schdule_date = [];
          var obj = response.json()
          console.log("doctor availability --"+JSON.stringify(obj))
          if (obj.doc_avail != null) {
            for (var i = 0; i < obj.doc_avail.length; i++) {
              var equal_flag=false;
              if(Date_Formate(obj.doc_avail[i].from_date) === Date_Formate(obj.doc_avail[i].to_date)){
                equal_flag = true;
              }
              
              if (obj.doc_avail[i].from_time != null) {
                this.ret_ftime = Time_Formate(obj.doc_avail[i].from_time);
                this.ret_ttime = Time_Formate(obj.doc_avail[i].to_time);
                this.doctor_schdule_date.push({
                  from_date: obj.doc_avail[i].from_date,
                  to_date: obj.doc_avail[i].to_date,
                  from_date_d: Date_Formate(obj.doc_avail[i].from_date),
                  to_date_d: Date_Formate(obj.doc_avail[i].to_date),
                  session: obj.doc_avail[i].session,
                  from_time: obj.doc_avail[i].from_time,
                  from_time_data: this.ret_ftime + " to " + this.ret_ttime,
                  to_time: obj.doc_avail[i].to_time,
                  reason: obj.doc_avail[i].reason,
                  full_day: obj.doc_avail[i].full_day,
                  doc_reg_id: this.userinfo.user_id,
                  doc_name:obj.doc_avail[i].doc_name,
                  equal_flag:equal_flag,
                });

              } else {
                this.doctor_schdule_date.push({
                  Index: obj.doc_avail[i].availability_id,
                  from_date: obj.doc_avail[i].from_date,
                  to_date: obj.doc_avail[i].to_date,
                  from_date_d: Date_Formate(obj.doc_avail[i].from_date),
                  to_date_d: Date_Formate(obj.doc_avail[i].to_date),
                  session: obj.doc_avail[i].session,
                  reason: obj.doc_avail[i].reason,
                  full_day: obj.doc_avail[i].full_day,
                  doc_reg_id:this.userinfo.user_id,
                  doc_name:obj.doc_avail[i].doc_name,
                  equal_flag:equal_flag,
                });
              }
            }
          }
        },
        error => {
            this.toastr.error(Message_data.defaultErr);
        });
  }

  deleteSchedule(reasontxt, fromdate, todate) {
    for (var i = 0; i < this.doctor_schdule_date.length; i++) {
      if (this.doctor_schdule_date[i].reason == reasontxt && this.doctor_schdule_date[i].from_date == fromdate && this.doctor_schdule_date[i].to_date == todate){
        this.doctor_schdule_date.splice(i, 1);
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp.toString() + 'usercontroller/updocavail/',
        JSON.stringify({
          doc_reg_id:this.userinfo.user_id,
          doc_avail: this.doctor_schdule_date
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json()
            if (obj["key"] != null) {
              if (obj["key"] == "1") {
                this.toastr.success(Message_data.dataUpdSuccess);
              }
            }
          },
          error => {
              this.toastr.error(Message_data.defaultErr);
          });
      }
    }
  }
}
