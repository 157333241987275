<div class="row">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Help Portal</h5>
                    </div>
                    <div class="headerButtons">
                                <!-- <a (click)="Bill_update()"><img src="../../../assets/ui_icons/buttons/save_button.svg"
                        class="saveimgbtn_inpatinfo" /></a> -->

                                <!-- <button type="button" mdbBtn color="primary" size="sm" [outline]="true" mdbWavesEffect (click)="clearAll()">Cancel</button>
                        <button type="button" mdbBtn color="primary" size="sm" mdbWavesEffect (click)="getCheckedItemList()">Confirm</button>   
                            -->
                    </div>
                </div>

            </mdb-card-header>
            <mdb-card-body>
                <div class="row">
                    <div class="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 select_bottom">
                        <mat-label class="matlabel">App help required for<br>
                            <select class="ipcss " disableOptionCentering [(ngModel)]="module">
                                <option *ngFor="let module of moduleArray" value={{module.module_id}}
                                    (click)="changeTopic(module.module_id)">{{module.description}}</option>
                            </select>
                        </mat-label>
                    </div>
                </div>
                <div class="row">
                    <div *ngIf="helpArray.length == 0" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div *ngIf="helpArray.length == 0" class="nodata">No data(s) found</div>
                    </div>
                    <div *ngIf="helpArray.length != 0" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div class="col-12" *ngIf="helpArray.length != 0">
                            <p>Steps to follow </p>
                        </div>

                        <div class="col-12" *ngFor="let person of helpArray">
                            <mat-card class="cardhelp">
                                <mat-card-conent>
                                    <p>{{person.helpdesc}}</p>
                                </mat-card-conent>
                            </mat-card>
                        </div>
                    </div>
                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>