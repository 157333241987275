import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatSelectModule} from '@angular/material/select';
import { MatFormFieldModule} from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import {MatDialogModule} from '@angular/material/dialog';
import { RouterModule } from '@angular/router';

import { GynaObestricPopSelectionComponent } from './gyna-obestric-pop-selection.component';


@NgModule({
    imports:[RouterModule,MatDialogModule,CommonModule,MatInputModule ,MatSelectModule,MatFormFieldModule,MatIconModule,MatButtonModule,FormsModule],
    declarations:[GynaObestricPopSelectionComponent],
    exports:[GynaObestricPopSelectionComponent]
})
export class GynoObestricPopupModule{   
   
}