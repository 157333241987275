import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
// import { DoctorViewComponent } from './doctor-view.component';

@Injectable({
  providedIn: 'root'
})
export class NurseService {

    private subject = new Subject<any>();
    constructor(public router:Router){}
    // public doctor: DoctorViewComponent;
    sendMessage(message: string) {
        this.subject.next(message);
        this.router.navigateByUrl("/nurse");
        message == null;
    }

    clearMessages() {
        this.subject.next();
    }

    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }
}
