import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from 'src/app/helper_class';
import { ServerApi } from 'src/app/server-api';
import { DietService } from '../diet-view/diet-view.service';
import * as HighCharts from 'highcharts';
import { Diab_graph } from '../../../assets/js/diab_graph';
import { ipaddress } from 'src/app/ipaddress';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
@Component({
  selector: 'app-diab-readings',
  templateUrl: './diab-readings.component.html',
  styleUrls: ['./diab-readings.component.scss']
})
export class DiabReadingsComponent implements OnInit {
  
  public readingTab = "min";
  public flag1: boolean = false;
  public flag2: boolean = false;
  public flag3: boolean = false;
  public flag4: boolean = false;
  public flag5: boolean = false;
  public flag6: boolean = false;

  public panelOpenState1: boolean;
  public panelOpenState3: boolean;
  public panelOpenState2: boolean;
  public panelOpenState4: boolean;
  public panelOpenState5: boolean;
  public panelOpenState6: boolean;

  public life_style_id;
  public asgdet: any;
  public Urine_Unavailable: boolean;
  public HbA1c_no_data: boolean;
  public HbA1c_yes_data: boolean;
  public Cur_Urine_unavail: boolean;
  public Cur_Urine_avail: boolean;
  public Cur_Ketone_unavail: boolean;
  public Cur_Ketone_avail: boolean;
  public Cur_Protein_unavail: boolean;
  public Cur_Protein_avail: boolean;
  public Cur_Cholesterol_unavail: boolean;
  public Cur_Cholesterol_avail: boolean;

  public HbA1c_one_no_data: boolean;
  public HbA1c_one_graph: boolean;

  public blood_glu_one_notavail: boolean;
  public blood_glu_one_avail: boolean;

  public coles_one_notavail: boolean;
  public coles_one_avail: boolean;

  public HbA1c_three_no_data: boolean;
  public HbA1c_three_graph: boolean;

  public urine_glu_notavail: boolean;
  public urine_glu_avail: boolean;

  public coles_three_notavail: boolean;
  public coles_three_avail: boolean;

  public HbA1c_six_no_data: boolean;
  public HbA1c_six_graph: boolean;

  public blood_glu_six_notavail: boolean;
  public blood_glu_six_avail: boolean;

  public coles_six_notavail: boolean;
  public coles_six_avail: boolean;

  public HbA1c_1year_no_data: boolean;
  public HbA1c_1year_graph: boolean;

  public blood_glu_1year_notavail: boolean;
  public blood_glu_1year_avail: boolean;

  public coles_1year_notavail: boolean;
  public coles_1year_avail: boolean;

  public HbA1c_2year_no_data: boolean;
  public HbA1c_2year_graph: boolean;

  public blood_glu_2year_notavail: boolean;
  public blood_glu_2year_avail: boolean;

  public coles_2year_notavail: boolean;
  public coles_2year_avail: boolean;

  public get_data: any;

  public nodapp: boolean;
  public accord_graph: boolean;
  public header_footer_flag: boolean;
  public life_id;
  public getclientinfo;
  public lifeStyleID;
  constructor(public serviceAPI: ServerApi, public dietservice: MenuViewService,public toastr: ToastrService,) {
    this.flag1 = false;
    this.flag2 = false;
    this.flag3 = false;
    this.flag4 = false;
    this.flag5 = false;
    this.flag6 = false;
    this.nodapp = true;
    this.accord_graph = true;
   }

  ngOnInit(): void {
      this.lifeStyleID = Helper_Class.getDiabReadingsLifeStyle();
      this.default_graph(this.lifeStyleID);
  }
//graph
 async default_graph(life_style_id) {
    if (life_style_id != null) {
      var sen_pass = {
        life_style_id : life_style_id
      }
      var response = await this.serviceAPI.PostData('diab/cgread', sen_pass).toPromise();
    if (response) {
      var obj = JSON.parse(JSON.stringify(response));
      if (obj.fasting_halfhour != null) {
        this.nodapp = true;
        this.accord_graph = false;
        var curren_array_list = [];
        if (obj.temparature != null) {
          curren_array_list.push({
            temparature: obj.temparature,
            blood_pressure: obj.blood_pressure,
            heart_rate: obj.heart_rate,
            respiratory_rate: obj.respiratory_rate,
          });
        }

        var Blood_Data = [];
        if (obj.onehalf_hours != null && obj.onehalf_hours != "") {
          Blood_Data.push({ name: 'Fasting', y: parseFloat(obj.fasting_halfhour) });  // 0
          Blood_Data.push({ name: 'One hour', y: parseFloat(obj.one_hour) });  // 1
          Blood_Data.push({ name: 'One and half hour', y: parseFloat(obj.onehalf_hours) });  // 2
          Blood_Data.push({ name: 'Postprandial', y: parseFloat(obj.two_hours) });  // 3
        } else {
          Blood_Data.push({ name: 'Fasting', y: parseFloat(obj.fasting_halfhour) });  // 0
          Blood_Data.push({ name: 'Postprandial', y: parseFloat(obj.two_hours) });  // 3
        }

        HighCharts.setOptions({
          colors: ['rgb(8,96,0)', 'rgb(255,50,50)', 'rgb(0,47,135)', 'rgb(135, 0, 135)']
        });

        // current Blood Glucose chart
        var aChart1 = new HighCharts.Chart({
          chart: {
            type: 'column',
            renderTo: "Third_lvl2",
            options3d: {
              enabled: true,
              alpha: 0,
              beta: 0,
              depth: 20
            }
          },
          exporting: {
            enabled: false
          },
          title: {
            text: ''
          },
          subtitle: {
            text: ''
          },
          xAxis: {
            type: 'category',

          },
          yAxis: {
            title: {
              text: ' '
            },
          },
          legend: {
            enabled: false
          },
          depth3D: 20, angle: 30,
          plotOptions: {
            series: {
              borderWidth: 0,
              dataLabels: {
                enabled: true,
                format: '{point.y}'
              },
              column: {
                depth: 20
              }
            }
          },

          tooltip: {
            headerFormat: '<br>',
            pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b> mg/dl<br/>'
          },

          series: [{
            colorByPoint: true,
            maxPointWidth: 35,
            data: Blood_Data
          }],

        });

        // HbA1C current graph
        var HbA1c_Value = null;
        var HbA1c_Nmin = null;
        var HbA1c_Nmax = null;
        var bararray1 = new Array();

        if (obj.HbA1c != null && obj.HbA1c != "") {
          this.HbA1c_no_data = true;
          this.HbA1c_yes_data = false;

          HbA1c_Value = parseFloat(obj.HbA1c);
          bararray1.push(parseFloat(obj.HbA1c));
        } else {
          this.HbA1c_no_data = false;
          this.HbA1c_yes_data = true;

          HbA1c_Value = parseFloat("0");
          bararray1.push(parseFloat("0"));
        }

        HbA1c_Nmin = parseFloat(obj.HbA1c_normal_low);
        HbA1c_Nmax = parseFloat(obj.HbA1c_normal_high);
        bararray1.push(parseFloat(obj.HbA1c_normal_low));

        var Low_Range;
        var Normal_Range;
        var High_Range;

        if (HbA1c_Value < HbA1c_Nmin) {
          Low_Range = HbA1c_Value;
        } else if (HbA1c_Nmin < HbA1c_Value && HbA1c_Value < HbA1c_Nmax) {
          Low_Range = HbA1c_Nmin;
          Normal_Range = HbA1c_Value - Low_Range;
        } else if (HbA1c_Value > HbA1c_Nmax) {
          Low_Range = HbA1c_Nmin;
          Normal_Range = HbA1c_Nmax - HbA1c_Nmin;
          High_Range = HbA1c_Value - HbA1c_Nmax;
        }

        var low1 = parseFloat(Low_Range);
        var normal1 = parseFloat(Normal_Range);
        var high1 = parseFloat(High_Range);

        HighCharts.setOptions({
          colors: ['rgb(255,50,50)', 'rgb(8,96,0)', 'rgb(0,47,135)']
        });

        var aChart1 = new HighCharts.Chart({
          chart: {
            type: 'column',
            renderTo: "Third_lvl1",
            options3d: {
              enabled: true,
              alpha: 0,
              beta: 0,
              depth: 20
            }
          },
          exporting: {
            enabled: false
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: ['HbA1c']
          },
          yAxis: {
            min: 0,
            title: {
              text: ''
            },
            stackLabels: {
              enabled: true,
              style: {
                fontWeight: 'bold',
                color: (HighCharts.theme && HighCharts.theme.textColor) || 'gray'
              }
            }
          },

          tooltip: {
            valueDecimals: 2,
            headerFormat: '<b>{point.x}</b><br/>',
            pointFormat: '{series.name}: {point.y:.2f}</b> mg/dl<br/>Total: {point.stackTotal}'
          },
          plotOptions: {
            column: {
              stacking: 'normal',
            }
          },
          series: [{
            name: 'High',
            data: [high1],
            maxPointWidth: 35,
          },
          {
            name: 'Normal',
            data: [normal1],
            maxPointWidth: 35,
          },
          {
            name: 'Low',
            data: [low1],
            maxPointWidth: 35,
          }]
        });

        // curent urine glucose
        if (obj.glucose != null && obj.glucose != "") {
          this.Cur_Urine_unavail = true;
          this.Cur_Urine_avail = false;
        } else {
          this.Cur_Urine_unavail = false;
          this.Cur_Urine_avail = true;
        }

        var bararray2 = new Array();
        bararray2.push(parseFloat(obj.glucose));
        bararray2.push(parseFloat(obj.glucose_normal));
        var normal_level_ug = null, Medium_Level_ug = null, High_Level_ug = null;
        var UrineGlocose = bararray2[0].toString();

        if (UrineGlocose > 0 && UrineGlocose <= 0.8) {
          normal_level_ug = UrineGlocose;
        } else if (UrineGlocose > 0.8) {
          normal_level_ug = 0.8;
          High_Level_ug = UrineGlocose - normal_level_ug;
        }

        var normal_lvl_ug = parseFloat(normal_level_ug);
        var high_lvl_ug = parseFloat(High_Level_ug);

        HighCharts.setOptions({
          colors: ['rgb(255,50,50)', 'rgb(0,47,135)', 'rgb(8,96,0)']
        });

        var aChart1 = new HighCharts.Chart({
          chart: {
            type: 'column',
            renderTo: "Urine_Glucose",
            options3d: {
              enabled: true,
              alpha: 0,
              beta: 0,
              depth: 20
            }
          },
          exporting: {
            enabled: false
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: ['Urine glucose']
          },
          yAxis: {
            min: 0,
            title: {
              text: ''
            },
            stackLabels: {
              enabled: true,
              style: {
                fontWeight: 'bold',
                color: (HighCharts.theme && HighCharts.theme.textColor) || 'gray'
              }
            }
          },

          tooltip: {
            valueDecimals: 2,
            headerFormat: '<b>{point.x}</b><br/>',
            pointFormat: '{series.name}: <b>{point.y:.2f}</b> mg/dl<br/>Total: {point.stackTotal}'
          },
          plotOptions: {
            column: {
              stacking: 'normal',
            }
          },
          series: [{
            name: 'High',
            data: [high_lvl_ug],
            maxPointWidth: 35,
          },
          {
            name: 'Normal',
            data: [normal_lvl_ug],
            maxPointWidth: 35,
          }]
        });
        // Ketone graph
        if (obj.ketones != null && obj.ketones != "") {
          this.Cur_Ketone_unavail = true;
          this.Cur_Ketone_avail = false;
        } else {
          this.Cur_Ketone_unavail = false;
          this.Cur_Ketone_avail = true;
        }

        var low_level_ket = null, normal_level_ket = null, Medium_Level_ket = null, High_Level_ket = null;
        var Ketone_Reading = parseFloat(obj.ketones);

        if (Ketone_Reading > 0 && Ketone_Reading < 0.6) {
          low_level_ket = Ketone_Reading;
        } else if (Ketone_Reading >= 0.6 && Ketone_Reading <= 1.5) {
          low_level_ket = 0.5;
          normal_level_ket = Ketone_Reading - low_level_ket;
        } else if (Ketone_Reading > 1.5 && Ketone_Reading <= 3.0) {
          low_level_ket = 0.5;
          normal_level_ket = 1.0;
          Medium_Level_ket = Ketone_Reading - normal_level_ket - low_level_ket;
        } else if (Ketone_Reading > 3.0) {
          low_level_ket = 0.5;
          normal_level_ket = 1.0;
          Medium_Level_ket = 1.5;
          High_Level_ket = Ketone_Reading - normal_level_ket - Medium_Level_ket - low_level_ket;
        }

        var low_lvl_kt = parseFloat(low_level_ket);
        var normal_lvl_kt = parseFloat(normal_level_ket);
        var medium_lvl_kt = parseFloat(Medium_Level_ket);
        var high_lvl_kt = parseFloat(High_Level_ket);

        HighCharts.setOptions({
          colors: ['rgb(255,50,50)', 'rgb(0,47,135)', 'rgb(8,96,0)', 'rgb(135, 0, 135)']
        });

        var aChart1 = new HighCharts.Chart({
          chart: {
            type: 'column',
            renderTo: "cur_ketone",
            options3d: {
              enabled: true,
              alpha: 0,
              beta: 0,
              depth: 20
            }
          },
          exporting: {
            enabled: false
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: ['Ketone']
          },
          yAxis: {
            min: 0,
            title: {
              text: ''
            },
            stackLabels: {
              enabled: true,
              style: {
                fontWeight: 'bold',
                color: (HighCharts.theme && HighCharts.theme.textColor) || 'gray'
              }
            }
          },

          tooltip: {
            valueDecimals: 2,
            headerFormat: '<b>{point.x}</b><br/>',
            pointFormat: '{series.name}: <b>{point.y:.2f}</b> mmol/L<br/>Total: {point.stackTotal}'
          },
          plotOptions: {
            column: {
              stacking: 'normal',
            }
          },
          series: [{
            name: 'High',
            data: [high_lvl_kt],
            maxPointWidth: 35,
          },
          {
            name: 'Moderate',
            data: [medium_lvl_kt],
            maxPointWidth: 35,
          },
          {
            name: 'Normal',
            data: [normal_lvl_kt],
            maxPointWidth: 35,
          },
          {
            name: 'Low',
            data: [low_lvl_kt],
            maxPointWidth: 35,
          }]
        });

        // protein charts
        if (obj.proteins != null && obj.proteins != "") {
          this.Cur_Protein_unavail = true;
          this.Cur_Protein_avail = false;
        } else {
          this.Cur_Protein_unavail = false;
          this.Cur_Protein_avail = true;
        }

        var normal_level_pro = null, Medium_Level_pro = null, High_Level_pro = null;
        var bararray4 = new Array();
        bararray4.push(parseFloat(obj.proteins));
        var Protein_Reading = bararray4[0];

        if (Protein_Reading > 0 && Protein_Reading <= 8.3) {
          normal_level_pro = Protein_Reading;
        } else if (Protein_Reading > 8.3) {
          normal_level_pro = 8.3;
          High_Level_pro = Protein_Reading - normal_level_pro;
        }

        var normal_lvl_pro = parseFloat(normal_level_pro);
        var high_lvl_pro = parseFloat(High_Level_pro);

        HighCharts.setOptions({
          colors: ['rgb(255,50,50)', 'rgb(0,47,135)', 'rgb(8,96,0)']
        });

        var aChart1 = new HighCharts.Chart({
          chart: {
            type: 'column',
            renderTo: "cur_protein",
            options3d: {
              enabled: true,
              alpha: 0,
              beta: 0,
              depth: 20
            }
          },
          exporting: {
            enabled: false
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: ['Protein']
          },
          yAxis: {
            min: 0,
            title: {
              text: ''
            },
            stackLabels: {
              enabled: true,
              style: {
                fontWeight: 'bold',
                color: (HighCharts.theme && HighCharts.theme.textColor) || 'gray'
              }
            }
          },

          tooltip: {
            valueDecimals: 2,
            headerFormat: '<b>{point.x}</b><br/>',
            pointFormat: '{series.name}: <b>{point.y:.2f}</b> g/dL<br/>Total: {point.stackTotal}'
          },
          plotOptions: {
            column: {
              stacking: 'normal',
            }
          },
          series: [{
            name: 'High',
            data: [high_lvl_pro],
            maxPointWidth: 35,
          },
          {
            name: 'Normal',
            data: [normal_lvl_pro],
            maxPointWidth: 35,
          }]
        });

        //   Cholesterol grpah
        if (obj.hdl != null && obj.ldl != null && obj.trigs != null && obj.vldl != null) {
          this.Cur_Cholesterol_unavail = true;
          this.Cur_Cholesterol_avail = false;
        } else {
          this.Cur_Cholesterol_unavail = false;
          this.Cur_Cholesterol_avail = true;
        }

        HighCharts.setOptions({
          colors: ['rgb(8,96,0)', 'rgb(255,50,50)', 'rgb(0,47,135)', 'rgb(135, 0, 135)']
        });

        var bararray5 = new Array();
        bararray5.push(parseFloat(obj.hdl));
        bararray5.push(parseFloat(obj.ldl));
        bararray5.push(parseFloat(obj.trigs));
        bararray5.push(parseFloat(obj.vldl));

        // current Blood Glucose chart
        var aChart11 = new HighCharts.Chart({
          chart: {
            type: 'column',
            renderTo: "cur_colestrol",
            options3d: {
              enabled: true,
              alpha: 0,
              beta: 25,
              depth: 70
            }
          },
          exporting: {
            enabled: false
          },
          title: {
            text: ''
          },
          subtitle: {
            text: ''
          },
          xAxis: {
            type: 'category',
          },
          yAxis: {
            title: {
              text: ' '
            },
          },
          legend: {
            enabled: false,
            location: 'top'
          },
          plotOptions: {
            series: {
              borderWidth: 0,
              dataLabels: {
                enabled: true,
                format: '{point.y}'
              }, showInLegend: false,
            }
          },

          tooltip: {
            headerFormat: '<br>',
            pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b> mg/dl<br/>'
          },

          series: [{
            name: 'Cholestrol',
            colorByPoint: true,
            maxPointWidth: 35,
            data: [{
              name: 'HDL',
              y: bararray5[0],
            },
            {
              name: 'LDL',
              y: bararray5[1],
            },
            {
              name: 'Trigs',
              y: bararray5[2],
            },
            {
              name: 'VLDL',
              y: bararray5[3],
            }]
          }],
        });
      } else {
        this.nodapp = false;
        this.accord_graph = true;
        this.HbA1c_no_data = false;
        this.HbA1c_yes_data = true;
        this.Cur_Urine_unavail = false;
        this.Cur_Urine_avail = true;
        this.Cur_Ketone_unavail = false;
        this.Cur_Ketone_avail = true;
        this.Cur_Cholesterol_unavail = false;
        this.Cur_Cholesterol_avail = true;
        this.Cur_Protein_unavail = false;
        this.Cur_Protein_avail = true;
      }
    }
  } else {
      this.nodapp = false;
      this.accord_graph = true;
    }
  }

 async tab_click(dat) {
    if (dat == "1") { // current graph
      this.default_graph(this.lifeStyleID);

    } else if (dat == "2") { // one month graph
      var sen_pass = {
        life_style_id : this.lifeStyleID,
        interval: "30",
        country: ipaddress.country_code,
      }
      var response = await this.serviceAPI.PostData('diab/pgread', sen_pass).toPromise();
      if (response) {
        console.log("get the food history" + JSON.stringify(response));
        var obj = JSON.parse(JSON.stringify(response));
        if (obj.bgdate.length != 0) {
          var onemon_array_list = [];
          for (var i = 0; i < obj.vitaldate.length; i++) {
            if (obj.heartrate != null) {
              var heart_graph = obj.heartrate[i];
            }

            if (obj.resprate != null) {
              var respi_graph = obj.resprate[i];
            }

            if (obj.temparatur != null) {
              onemon_array_list.push({
                date_three: obj.vitaldate[i],
                temparature: obj.temparatur[i],
                blood_pressure: obj.bp[i],
                heart_rate: heart_graph,
                respiratory_rate: respi_graph
              })
            }
          }

          var fasting = Array();
          var onehour = Array();
          var onehalf = Array();
          var twohours = Array();
          var hba1c = Array();
          var keto = Array();
          var prot = Array();
          var urineglucose = Array();
          var ugdate = Array();
          var bgdate_array = new Array();
          var coldate = Array();
          var hdl = Array();
          var ldl = Array();
          var trigs = Array();
          var vldl = Array();

          if (obj.coldate != null) {
            for (var i = 0; i < obj.coldate.length; i++) {
              coldate.push(obj.coldate[i]);
            }
          }

          if (obj.ugdate != null) {
            for (var i = 0; i < obj.ugdate.length; i++) {
              ugdate.push(obj.ugdate[i]);
            }
          }

          if (obj.bgdate != null) {
            for (var i = 0; i < obj.bgdate.length; i++) {
              bgdate_array.push(obj.bgdate[i]);
            }
          }

          if (obj.fasting != null) {
            for (var i = 0; i < obj.fasting.length; i++) {
              fasting.push(obj.fasting[i]);
            }
          }

          if (obj.onehour != null) {
            for (var i = 0; i < obj.onehour.length; i++) {
              onehour.push(obj.onehour[i]);
            }
          }

          if (obj.onehalf != null) {
            for (var i = 0; i < obj.onehalf.length; i++) {
              onehalf.push(obj.onehalf[i]);
            }
          }

          var hba1ccheck = new Array();
          if (obj.hba1c != null) {
            for (var i = 0; i < obj.hba1c.length; i++) {
              if (obj.hba1c[i] != null) {
                hba1c.push(obj.hba1c[i]);

                if (hba1c[i] == "") {
                  hba1ccheck.push(hba1c[i]);
                }
              }
            }
          }

          if (obj.ketones != null) {
            for (var i = 0; i < obj.ketones.length; i++) {
              keto.push(obj.ketones[i]);
            }
          }

          if (obj.proteins != null) {
            for (var i = 0; i < obj.proteins.length; i++) {
              prot.push(obj.proteins[i]);
            }
          }

          if (obj.twohours != null) {
            for (var i = 0; i < obj.twohours.length; i++) {
              twohours.push(obj.twohours[i]);
            }
          }

          var Urinecheck = new Array();
          if (obj.urineglucose != null) {
            for (var i = 0; i < obj.urineglucose.length; i++) {
              urineglucose.push(obj.urineglucose[i]);

              if (urineglucose[i] == "") {
                Urinecheck.push(urineglucose[i]);
              }
            }
          }

          var hdlcheck = new Array();
          if (obj.hdl != null) {
            for (var i = 0; i < obj.hdl.length; i++) {
              hdl.push(obj.hdl[i]);

              if (hdl[i] == "") {
                hdlcheck.push(hdl[i]);
              }
            }
          }

          var ldlcheck = new Array();
          if (obj.ldl != null) {
            for (var i = 0; i < obj.ldl.length; i++) {
              ldl.push(obj.ldl[i]);
              if (ldl[i] == "") {
                ldlcheck.push(ldl[i]);
              }
            }
          }

          var trigscheck = new Array();
          if (obj.trigs != null) {
            for (var i = 0; i < obj.trigs.length; i++) {
              trigs.push(obj.trigs[i]);
              if (trigs[i] == "") {
                trigscheck.push(trigs[i]);
              }
            }
          }

          var vldlcheck = new Array();
          if (obj.vldl != null) {
            for (var i = 0; i < obj.vldl.length; i++) {
              vldl.push(obj.vldl[i]);
              if (vldl[i] == "") {
                vldlcheck.push(ldl[i]);
              }
            }
          }

          var get_fast_dat = Array();
          for (var i = 0; i < bgdate_array.length; i++) {
            var res = bgdate_array[i].split("-");
            if (fasting[i] != "") {
              get_fast_dat.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(fasting[i]) });
            }
          }

          var get_one_dat = Array();
          for (var i = 0; i < bgdate_array.length; i++) {
            var res = bgdate_array[i].split("-");
            if (obj.onehour != null) {
              for (var j = 0; j < obj.onehour.length; j++) {
                if (j == i) {
                  get_one_dat.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(onehour[j]) });
                }
              }
            }
          }

          var get_onehalf_dat = Array();
          for (var i = 0; i < bgdate_array.length; i++) {
            var res = bgdate_array[i].split("-");

            if (obj.onehalf != null) {
              for (var j = 0; j < obj.onehalf.length; j++) {
                if (j == i) {
                  get_onehalf_dat.push({ x: new Date(res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(onehalf[j]) });
                }
              }
            }
          }

          var get_two_dat = Array();
          for (var i = 0; i < bgdate_array.length; i++) {
            var res = bgdate_array[i].split("-");
            if (twohours[i] != "") {
              get_two_dat.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(twohours[i]) });
            }
          }

          var get_urine_dat = Array();
          for (var i = 0; i < ugdate.length; i++) {
            var res = ugdate[i].split("-");
            if (urineglucose[i] != "") {
              get_urine_dat.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(urineglucose[i]) });
            }
          }

          var get_keto_dat = Array();
          for (var i = 0; i < ugdate.length; i++) {
            var res = ugdate[i].split("-");
            if (keto[i] != "") {
              get_keto_dat.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(keto[i]) });
            }
          }

          var get_prot_dat = Array();
          for (var i = 0; i < ugdate.length; i++) {
            var res = ugdate[i].split("-");
            if (prot[i] != "") {
              get_prot_dat.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(prot[i]) });
            }
          }

          Diab_graph.Blood_Graph(get_fast_dat, get_one_dat, get_onehalf_dat, get_two_dat);

          var get_hba1_dat = Array();
          
          for (var i = 0; i < bgdate_array.length; i++) {
            var res = bgdate_array[i].split("-");
            if (hba1c[i] != "" && hba1c[i] != undefined) {
              get_hba1_dat.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(hba1c[i]) });
              
            }
          }

          if (get_hba1_dat.length != 0) {

            this.HbA1c_one_no_data = true;
            this.HbA1c_one_graph = false;

          } else {

            this.HbA1c_one_no_data = false;
            this.HbA1c_one_graph = true;
          }

          Diab_graph.HbA1c_Graph(get_hba1_dat);

          // urine data
          var get_urine_dat = Array();
          for (var i = 0; i < ugdate.length; i++) {
            var res = ugdate[i].split("-");
            if (urineglucose[i] != "") {
              get_urine_dat.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(urineglucose[i]) });
            }
          }

          var get_keto_dat = Array();
          for (var i = 0; i < ugdate.length; i++) {
            var res = ugdate[i].split("-");
            if (keto[i] != "") {
              get_keto_dat.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(keto[i]) });
            }
          }

          var get_prot_dat = Array();
          for (var i = 0; i < ugdate.length; i++) {
            var res = ugdate[i].split("-");
            if (prot[i] != "") {
              get_prot_dat.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(prot[i]) });
            }
          }

          if (get_urine_dat.length == 0 && get_keto_dat.length == 0 && get_prot_dat.length == 0) {
            this.blood_glu_one_notavail = false;
            this.blood_glu_one_avail = true;

          } else {
            this.blood_glu_one_notavail = true;
            this.blood_glu_one_avail = false;
            Diab_graph.Urine_Graph(get_urine_dat, get_keto_dat, get_prot_dat);
          }

          var get_hdl = Array();
          for (var i = 0; i < coldate.length; i++) {
            var res = coldate[i].split("-");
            if (hdl[i] != "") {
              get_hdl.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(hdl[i]) });
            }
          }

          var get_ldl = Array();
          for (var i = 0; i < coldate.length; i++) {
            var res = coldate[i].split("-");
            if (ldl[i] != "") {
              get_ldl.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(ldl[i]) });
            }
          }

          var get_trigs = Array();
          for (var i = 0; i < coldate.length; i++) {
            var res = coldate[i].split("-");
            if (trigs[i] != "") {
              get_trigs.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(trigs[i]) });
            }
          }

          var get_vldl = Array();
          for (var i = 0; i < coldate.length; i++) {
            var res = coldate[i].split("-");
            if (vldl[i] != "") {
              get_vldl.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(vldl[i]) });
            }
          }

          if (get_hdl.length == 0 && get_ldl.length == 0 && get_trigs.length == 0 && get_vldl.length == 0) {
            this.coles_one_notavail = false;
            this.coles_one_avail = true;

          } else {
            this.coles_one_notavail = true;
            this.coles_one_avail = false;
            Diab_graph.Cholesterol_Graph(get_hdl, get_ldl, get_trigs, get_vldl);
          }
        }
        else {
          this.HbA1c_one_no_data = false;
          this.HbA1c_one_graph = true;
          this.blood_glu_one_notavail = true;
          this.blood_glu_one_avail = false;
          this.coles_one_notavail = true;
          this.coles_one_avail = false;
        }
      }
      

    } else if (dat == "3") { // one month graph
      
      var sen_pass = {
        life_style_id : this.lifeStyleID,
        interval: "93",
        country: ipaddress.country_code,
      }
      var response = await this.serviceAPI.PostData('diab/pgread', sen_pass).toPromise();
      if (response) {
        console.log("get the food history" + JSON.stringify(response));
        var obj = JSON.parse(JSON.stringify(response)); 
        if (obj.bgdate.length != 0) {
          var onemon_array_list = [];

          for (var i = 0; i < obj.vitaldate.length; i++) {
            if (obj.heartrate != null) {
              var heart_graph = obj.heartrate[i];
            }

            if (obj.resprate != null) {
              var respi_graph = obj.resprate[i];
            }
            if (obj.temparatur != null) {
              var temp_graph = obj.temparatur[i];
            }

            if (obj.bp != null) {
              var bp_graph = obj.bp[i];
            }

            onemon_array_list.push({
              date_three: obj.vitaldate[i],
              temparature: temp_graph,
              blood_pressure: bp_graph,
              heart_rate: heart_graph,
              respiratory_rate: respi_graph
            })
          }

          var fasting = Array();
          var onehour = Array();
          var onehalf = Array();
          var twohours = Array();
          var hba1c = Array();
          var keto = Array();
          var prot = Array();
          var urineglucose = Array();
          var ugdate = Array();
          var bgdate_array = new Array();
          var coldate = Array();
          var hdl = Array();
          var ldl = Array();
          var trigs = Array();
          var vldl = Array();

          if (obj.coldate != null) {
            for (var i = 0; i < obj.coldate.length; i++) {
              coldate.push(obj.coldate[i]);
            }
          }

          if (obj.ugdate != null) {
            for (var i = 0; i < obj.ugdate.length; i++) {
              ugdate.push(obj.ugdate[i]);
            }
          }

          if (obj.bgdate != null) {
            for (var i = 0; i < obj.bgdate.length; i++) {
              bgdate_array.push(obj.bgdate[i]);
            }
          }

          if (obj.fasting != null) {
            for (var i = 0; i < obj.fasting.length; i++) {
              fasting.push(obj.fasting[i]);
            }
          }

          if (obj.onehour != null) {
            for (var i = 0; i < obj.onehour.length; i++) {
              onehour.push(obj.onehour[i]);
            }
          }

          if (obj.onehalf != null) {
            for (var i = 0; i < obj.onehalf.length; i++) {
              onehalf.push(obj.onehalf[i]);
            }
          }

          var hba1ccheck = new Array();
          if (obj.hba1c != null) {
            for (var i = 0; i < obj.hba1c.length; i++) {
              if (obj.hba1c[i] != null) {
                hba1c.push(obj.hba1c[i]);

                if (hba1c[i] == "") {
                  hba1ccheck.push(hba1c[i]);
                }
              }
            }
          }

          if (obj.ketones != null) {
            for (var i = 0; i < obj.ketones.length; i++) {
              keto.push(obj.ketones[i]);
            }
          }

          if (obj.proteins != null) {
            for (var i = 0; i < obj.proteins.length; i++) {
              prot.push(obj.proteins[i]);
            }
          }

          if (obj.twohours != null) {
            for (var i = 0; i < obj.twohours.length; i++) {
              twohours.push(obj.twohours[i]);
            }
          }

          var Urinecheck = new Array();
          if (obj.urineglucose != null) {
            for (var i = 0; i < obj.urineglucose.length; i++) {
              urineglucose.push(obj.urineglucose[i]);

              if (urineglucose[i] == "") {
                Urinecheck.push(urineglucose[i]);
              }
            }
          }

          var hdlcheck = new Array();
          if (obj.hdl != null) {
            for (var i = 0; i < obj.hdl.length; i++) {
              hdl.push(obj.hdl[i]);

              if (hdl[i] == "") {
                hdlcheck.push(hdl[i]);
              }
            }
          }

          var ldlcheck = new Array();
          if (obj.ldl != null) {
            for (var i = 0; i < obj.ldl.length; i++) {
              ldl.push(obj.ldl[i]);

              if (ldl[i] == "") {
                ldlcheck.push(ldl[i]);
              }
            }
          }

          var trigscheck = new Array();
          if (obj.trigs != null) {
            for (var i = 0; i < obj.trigs.length; i++) {
              trigs.push(obj.trigs[i]);

              if (trigs[i] == "") {
                trigscheck.push(trigs[i]);
              }
            }
          }

          var vldlcheck = new Array();
          if (obj.vldl != null) {
            for (var i = 0; i < obj.vldl.length; i++) {
              vldl.push(obj.vldl[i]);

              if (vldl[i] == "") {
                vldlcheck.push(ldl[i]);
              }
            }
          }

          var get_fast_dat = Array();

          for (var i = 0; i < bgdate_array.length; i++) {
            var res = bgdate_array[i].split("-");
            if (fasting[i] != "") {
              get_fast_dat.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(fasting[i]) });
            }
          }

          var get_one_dat = Array();

          for (var i = 0; i < bgdate_array.length; i++) {
            var res = bgdate_array[i].split("-");
            if (obj.onehour != null) {
              for (var j = 0; j < obj.onehour.length; j++) {
                if (j == i) {
                  get_one_dat.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(onehour[j]) });
                }
              }
            }
          }

          var get_onehalf_dat = Array();
          for (var i = 0; i < bgdate_array.length; i++) {
            var res = bgdate_array[i].split("-");

            if (obj.onehalf != null) {
              for (var j = 0; j < obj.onehalf.length; j++) {
                if (j == i) {
                  get_onehalf_dat.push({ x: new Date(res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(onehalf[j]) });
                }
              }
            }
          }

          var get_two_dat = Array();
          for (var i = 0; i < bgdate_array.length; i++) {
            var res = bgdate_array[i].split("-");
            if (twohours[i] != "") {
              get_two_dat.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(twohours[i]) });
            }
          }

          var get_urine_dat = Array();
          for (var i = 0; i < ugdate.length; i++) {
            var res = ugdate[i].split("-");
            if (urineglucose[i] != "") {
              get_urine_dat.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(urineglucose[i]) });
            }
          }

          var get_keto_dat = Array();
          for (var i = 0; i < ugdate.length; i++) {
            var res = ugdate[i].split("-");
            if (keto[i] != "") {
              get_keto_dat.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(keto[i]) });
            }
          }

          var get_prot_dat = Array();
          for (var i = 0; i < ugdate.length; i++) {
            var res = ugdate[i].split("-");
            if (prot[i] != "") {
              get_prot_dat.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(prot[i]) });
            }
          }
          //three_Blood_Graph(get_fast_dat, get_one_dat, get_onehalf_dat, get_two_dat);
          Diab_graph.three_Blood_Graph(get_fast_dat, get_one_dat, get_onehalf_dat, get_two_dat);

          var get_hba1_dat = Array();
          for (var i = 0; i < bgdate_array.length; i++) {
            var res = bgdate_array[i].split("-");
            if (hba1c[i] != "" && hba1c[i] != undefined) {
              get_hba1_dat.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(hba1c[i]) });
            }
          }

          if (get_hba1_dat.length != 0) {
            this.HbA1c_three_no_data = true;
            this.HbA1c_three_graph = false;

          } else {
            this.HbA1c_three_no_data = false;
            this.HbA1c_three_graph = true;
          }
          Diab_graph.threeHbA1c_Graph(get_hba1_dat);

          // urine data
          var get_urine_dat = Array();
          for (var i = 0; i < ugdate.length; i++) {
            var res = ugdate[i].split("-");
            if (urineglucose[i] != "") {
              get_urine_dat.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(urineglucose[i]) });
            }
          }

          var get_keto_dat = Array();
          for (var i = 0; i < ugdate.length; i++) {
            var res = ugdate[i].split("-");
            if (keto[i] != "") {
              get_keto_dat.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(keto[i]) });
            }
          }

          var get_prot_dat = Array();
          for (var i = 0; i < ugdate.length; i++) {
            var res = ugdate[i].split("-");
            if (prot[i] != "") {
              get_prot_dat.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(prot[i]) });
            }
          }

          if (get_urine_dat.length == 0 && get_keto_dat.length == 0 && get_prot_dat.length == 0) {
            this.urine_glu_notavail = false;
            this.urine_glu_avail = true;

          } else {
            this.urine_glu_notavail = true;
            this.urine_glu_avail = false;
            Diab_graph.threeUrine_Graph(get_urine_dat, get_keto_dat, get_prot_dat);
          }

          var get_hdl = Array();
          for (var i = 0; i < coldate.length; i++) {
            var res = coldate[i].split("-");
            if (hdl[i] != "") {
              get_hdl.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(hdl[i]) });
            }
          }

          var get_ldl = Array();
          for (var i = 0; i < coldate.length; i++) {
            var res = coldate[i].split("-");
            if (ldl[i] != "") {
              get_ldl.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(ldl[i]) });
            }
          }

          var get_trigs = Array();
          for (var i = 0; i < coldate.length; i++) {
            var res = coldate[i].split("-");
            if (trigs[i] != "") {
              get_trigs.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(trigs[i]) });
            }
          }

          var get_vldl = Array();
          for (var i = 0; i < coldate.length; i++) {
            var res = coldate[i].split("-");
            if (vldl[i] != "") {
              get_vldl.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(vldl[i]) });
            }
          }

          if (get_hdl.length == 0 && get_ldl.length == 0 && get_trigs.length == 0 && get_vldl.length == 0) {
            this.coles_three_notavail = false;
            this.coles_three_avail = true;

          } else {
            this.coles_three_notavail = true;
            this.coles_three_avail = false;
            Diab_graph.threreCholesterol_Graph(get_hdl, get_ldl, get_trigs, get_vldl);
          }
        }
      }
    } else if (dat == "6") { // six month graph
      var sen_pass = {
        life_style_id : this.lifeStyleID,
        interval: "183",
          country: ipaddress.country_code,
      }
      var response = await this.serviceAPI.PostData('diab/pgread', sen_pass).toPromise();
      if (response) {
        console.log("get the food history" + JSON.stringify(response));
        var obj = JSON.parse(JSON.stringify(response));
        if (obj.bgdate.length != 0) {
          var onemon_array_list = [];
          for (var i = 0; i < obj.vitaldate.length; i++) {
            if (obj.heartrate != null) {
              var heart_graph = obj.heartrate[i];
            }

            if (obj.resprate != null) {
              var respi_graph = obj.resprate[i];
            }

            if (obj.temparatur != null) {
              var temp_graph = obj.temparatur[i];
            }

            if (obj.bp != null) {
              var bp_graph = obj.bp[i];
            }

            onemon_array_list.push({
              date_three: obj.vitaldate[i],
              temparature: obj.temp_graph,
              blood_pressure: bp_graph,
              heart_rate: heart_graph,
              respiratory_rate: respi_graph
            })
          }

          var fasting = Array();
          var onehour = Array();
          var onehalf = Array();
          var twohours = Array();
          var hba1c = Array();
          var keto = Array();
          var prot = Array();
          var urineglucose = Array();
          var ugdate = Array();
          var bgdate_array = new Array();
          var coldate = Array();
          var hdl = Array();
          var ldl = Array();
          var trigs = Array();
          var vldl = Array();

          if (obj.coldate != null) {
            for (var i = 0; i < obj.coldate.length; i++) {
              coldate.push(obj.coldate[i]);
            }
          }

          if (obj.ugdate != null) {
            for (var i = 0; i < obj.ugdate.length; i++) {
              ugdate.push(obj.ugdate[i]);
            }
          }

          if (obj.bgdate != null) {
            for (var i = 0; i < obj.bgdate.length; i++) {
              bgdate_array.push(obj.bgdate[i]);
            }
          }

          if (obj.fasting != null) {
            for (var i = 0; i < obj.fasting.length; i++) {
              fasting.push(obj.fasting[i]);
            }
          }

          if (obj.onehour != null) {
            for (var i = 0; i < obj.onehour.length; i++) {
              onehour.push(obj.onehour[i]);
            }
          }

          if (obj.onehalf != null) {
            for (var i = 0; i < obj.onehalf.length; i++) {
              onehalf.push(obj.onehalf[i]);
            }
          }

          var hba1ccheck = new Array();
          if (obj.hba1c != null) {
            for (var i = 0; i < obj.hba1c.length; i++) {
              if (obj.hba1c[i] != null) {
                hba1c.push(obj.hba1c[i]);

                if (hba1c[i] == "") {
                  hba1ccheck.push(hba1c[i]);
                }
              }
            }
          }

          if (obj.ketones != null) {
            for (var i = 0; i < obj.ketones.length; i++) {
              keto.push(obj.ketones[i]);
            }
          }

          if (obj.proteins != null) {
            for (var i = 0; i < obj.proteins.length; i++) {
              prot.push(obj.proteins[i]);
            }
          }

          if (obj.twohours != null) {
            for (var i = 0; i < obj.twohours.length; i++) {
              twohours.push(obj.twohours[i]);
            }
          }

          var Urinecheck = new Array();
          if (obj.urineglucose != null) {
            for (var i = 0; i < obj.urineglucose.length; i++) {
              urineglucose.push(obj.urineglucose[i]);

              if (urineglucose[i] == "") {
                Urinecheck.push(urineglucose[i]);
              }
            }
          }

          var hdlcheck = new Array();
          if (obj.hdl != null) {
            for (var i = 0; i < obj.hdl.length; i++) {
              hdl.push(obj.hdl[i]);

              if (hdl[i] == "") {
                hdlcheck.push(hdl[i]);
              }
            }
          }

          var ldlcheck = new Array();
          if (obj.ldl != null) {
            for (var i = 0; i < obj.ldl.length; i++) {
              ldl.push(obj.ldl[i]);

              if (ldl[i] == "") {
                ldlcheck.push(ldl[i]);
              }
            }
          }

          var trigscheck = new Array();
          if (obj.trigs != null) {
            for (var i = 0; i < obj.trigs.length; i++) {
              trigs.push(obj.trigs[i]);

              if (trigs[i] == "") {
                trigscheck.push(trigs[i]);
              }
            }
          }

          var vldlcheck = new Array();
          if (obj.vldl != null) {
            for (var i = 0; i < obj.vldl.length; i++) {
              vldl.push(obj.vldl[i]);

              if (vldl[i] == "") {
                vldlcheck.push(ldl[i]);
              }
            }
          }

          var get_fast_dat = Array();
          for (var i = 0; i < bgdate_array.length; i++) {
            var res = bgdate_array[i].split("-");
            if (fasting[i] != "") {
              get_fast_dat.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(fasting[i]) });
            }
          }

          var get_one_dat = Array();
          for (var i = 0; i < bgdate_array.length; i++) {
            var res = bgdate_array[i].split("-");
            if (obj.onehour != null) {
              for (var j = 0; j < obj.onehour.length; j++) {
                if (j == i) {
                  get_one_dat.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(onehour[j]) });
                }
              }
            }
          }

          var get_onehalf_dat = Array();
          for (var i = 0; i < bgdate_array.length; i++) {
            var res = bgdate_array[i].split("-");

            if (obj.onehalf != null) {
              for (var j = 0; j < obj.onehalf.length; j++) {
                if (j == i) {
                  get_onehalf_dat.push({ x: new Date(res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(onehalf[j]) });
                }
              }
            }
          }

          var get_two_dat = Array();
          for (var i = 0; i < bgdate_array.length; i++) {
            var res = bgdate_array[i].split("-");
            if (twohours[i] != "") {
              get_two_dat.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(twohours[i]) });
            }
          }

          var get_urine_dat = Array();
          for (var i = 0; i < ugdate.length; i++) {

            var res = ugdate[i].split("-");
            if (urineglucose[i] != "") {
              get_urine_dat.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(urineglucose[i]) });
            }
          }

          var get_keto_dat = Array();
          for (var i = 0; i < ugdate.length; i++) {
            var res = ugdate[i].split("-");
            if (keto[i] != "") {
              get_keto_dat.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(keto[i]) });
            }
          }

          var get_prot_dat = Array();
          for (var i = 0; i < ugdate.length; i++) {
            var res = ugdate[i].split("-");
            if (prot[i] != "") {
              get_prot_dat.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(prot[i]) });
            }
          }
          Diab_graph.six_Blood_Graph(get_fast_dat, get_one_dat, get_onehalf_dat, get_two_dat);

          var get_hba1_dat = Array();
          for (var i = 0; i < bgdate_array.length; i++) {
            var res = bgdate_array[i].split("-");
            if (hba1c[i] != "" && hba1c[i] != undefined) {
              get_hba1_dat.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(hba1c[i]) });
            }
          }

          if (get_hba1_dat.length != 0) {
            this.HbA1c_six_no_data = true;
            this.HbA1c_six_graph = false;

          } else {
            this.HbA1c_six_no_data = false;
            this.HbA1c_six_graph = true;
          }
          Diab_graph.sixHbA1c_Graph(get_hba1_dat);

          // urine data
          var get_urine_dat = Array();
          for (var i = 0; i < ugdate.length; i++) {
            var res = ugdate[i].split("-");
            if (urineglucose[i] != "") {
              get_urine_dat.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(urineglucose[i]) });
            }
          }

          var get_keto_dat = Array();
          for (var i = 0; i < ugdate.length; i++) {
            var res = ugdate[i].split("-");
            if (keto[i] != "") {
              get_keto_dat.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(keto[i]) });
            }
          }

          var get_prot_dat = Array();
          for (var i = 0; i < ugdate.length; i++) {
            var res = ugdate[i].split("-");
            if (prot[i] != "") {
              get_prot_dat.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(prot[i]) });
            }
          }

          if (get_urine_dat.length == 0 && get_keto_dat.length == 0 && get_prot_dat.length == 0) {
            this.blood_glu_six_notavail = false;
            this.blood_glu_six_avail = true;

          } else {
            this.blood_glu_six_notavail = true;
            this.blood_glu_six_avail = false;
            Diab_graph.sixUrine_Graph(get_urine_dat, get_keto_dat, get_prot_dat);
          }

          var get_hdl = Array();
          for (var i = 0; i < coldate.length; i++) {
            var res = coldate[i].split("-");
            if (hdl[i] != "") {
              get_hdl.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(hdl[i]) });
            }
          }

          var get_ldl = Array();
          for (var i = 0; i < coldate.length; i++) {
            var res = coldate[i].split("-");
            if (ldl[i] != "") {
              get_ldl.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(ldl[i]) });
            }
          }

          var get_trigs = Array();
          for (var i = 0; i < coldate.length; i++) {
            var res = coldate[i].split("-");
            if (trigs[i] != "") {
              get_trigs.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(trigs[i]) });
            }
          }

          var get_vldl = Array();
          for (var i = 0; i < coldate.length; i++) {
            var res = coldate[i].split("-");
            if (vldl[i] != "") {
              get_vldl.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(vldl[i]) });
            }
          }

          if (get_hdl.length == 0 && get_ldl.length == 0 && get_trigs.length == 0 && get_vldl.length == 0) {
            this.coles_six_notavail = false;
            this.coles_six_avail = true;

          } else {
            this.coles_six_notavail = true;
            this.coles_six_avail = false;
            Diab_graph.sixCholesterol_Graph(get_hdl, get_ldl, get_trigs, get_vldl);
          }
        }
        else {
          this.HbA1c_six_no_data = false;
          this.HbA1c_six_graph = true;
          this.blood_glu_six_notavail = true;
          this.blood_glu_six_avail = false;
          this.coles_six_notavail = true;
          this.coles_six_avail = false;
        }
      }
    }
    // 1year
    else if (dat == "1year") { // six month graph
      var sen_pass = {
        life_style_id : this.lifeStyleID,
        interval: "365",
        country: ipaddress.country_code,
      }
      var response = await this.serviceAPI.PostData('diab/pgread', sen_pass).toPromise();
      if (response) {
        console.log("get the food history" + JSON.stringify(response));
        var obj = JSON.parse(JSON.stringify(response));
        if (obj.bgdate.length != 0) {
          var onemon_array_list = [];

          for (var i = 0; i < obj.vitaldate.length; i++) {
            if (obj.heartrate != null) {
              var heart_graph = obj.heartrate[i];
            }

            if (obj.resprate != null) {
              var respi_graph = obj.resprate[i];
            }

            if (obj.temparatur != null) {
              var temp_graph = obj.temparatur[i];
            }

            if (obj.bp != null) {
              var bp_graph = obj.bp[i];
            }

            onemon_array_list.push({
              date_three: obj.vitaldate[i],
              temparature: obj.temp_graph,
              blood_pressure: bp_graph,
              heart_rate: heart_graph,
              respiratory_rate: respi_graph
            })
          }

          var fasting = [];
          var onehour = [];
          var onehalf = [];
          var twohours = [];
          var hba1c = [];
          var keto = [];
          var prot = [];
          var urineglucose = [];
          var ugdate = [];
          var bgdate_array = [];
          var coldate = [];
          var hdl = [];
          var ldl = [];
          var trigs = [];
          var vldl = [];

          if (obj.coldate.length != 0) {
            for (var i = 0; i < obj.coldate.length; i++) {
              coldate.push(obj.coldate[i]);
            }
          }

          if (obj.ugdate.length != 0) {
            for (var i = 0; i < obj.ugdate.length; i++) {
              ugdate.push(obj.ugdate[i]);
            }
          }

          if (obj.bgdate.length != 0) {
            for (var i = 0; i < obj.bgdate.length; i++) {
              bgdate_array.push(obj.bgdate[i]);
            }
            
          }

          if (obj.fasting.length != 0) {
            for (var i = 0; i < obj.fasting.length; i++) {
              fasting.push(obj.fasting[i]);
            }
          }

          if (obj.onehour.length != 0) {
            for (var i = 0; i < obj.onehour.length; i++) {
              onehour.push(obj.onehour[i]);
            }
          }

          if (obj.onehalf.length != 0) {
            for (var i = 0; i < obj.onehalf.length; i++) {
              onehalf.push(obj.onehalf[i]);
            }
          }

          var hba1ccheck = [];
          if (obj.hba1c.length != 0) {
            for (var i = 0; i < obj.hba1c.length; i++) {
              if (obj.hba1c[i].length != 0) {
                hba1c.push(obj.hba1c[i]);

                if (hba1c[i] == "") {
                  hba1ccheck.push(hba1c[i]);
                }
              }
            }
          }

          if (obj.ketones != null) {
            for (var i = 0; i < obj.ketones.length; i++) {
              keto.push(obj.ketones[i]);
            }
          }

          if (obj.proteins != null) {
            for (var i = 0; i < obj.proteins.length; i++) {
              prot.push(obj.proteins[i]);
            }
          }

          if (obj.twohours != null) {
            for (var i = 0; i < obj.twohours.length; i++) {
              twohours.push(obj.twohours[i]);
            }
          }

          var Urinecheck = [];
          if (obj.urineglucose != null) {
            for (var i = 0; i < obj.urineglucose.length; i++) {
              urineglucose.push(obj.urineglucose[i]);

              if (urineglucose[i] == "") {
                Urinecheck.push(urineglucose[i]);
              }
            }
          }

          var hdlcheck = [];
          if (obj.hdl != null) {
            for (var i = 0; i < obj.hdl.length; i++) {
              hdl.push(obj.hdl[i]);

              if (hdl[i] == "") {
                hdlcheck.push(hdl[i]);
              }
            }
          }

          var ldlcheck = [];
          if (obj.ldl != null) {
            for (var i = 0; i < obj.ldl.length; i++) {
              ldl.push(obj.ldl[i]);

              if (ldl[i] == "") {
                ldlcheck.push(ldl[i]);
              }
            }
          }

          var trigscheck = [];
          if (obj.trigs != null) {
            for (var i = 0; i < obj.trigs.length; i++) {
              trigs.push(obj.trigs[i]);

              if (trigs[i] == "") {
                trigscheck.push(trigs[i]);
              }
            }
          }

          var vldlcheck = [];
          if (obj.vldl != null) {
            for (var i = 0; i < obj.vldl.length; i++) {
              vldl.push(obj.vldl[i]);

              if (vldl[i] == "") {
                vldlcheck.push(ldl[i]);
              }
            }
          }

          var get_fast_dat = [];
          for (var i = 0; i < bgdate_array.length; i++) {
            var res = bgdate_array[i].split("-");
            if (fasting[i] != "") {
              get_fast_dat.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(fasting[i]) });
            }
          }

          var get_one_dat = [];
          for (var i = 0; i < bgdate_array.length; i++) {
            var res = bgdate_array[i].split("-");
            if (obj.onehour != null) {
              for (var j = 0; j < obj.onehour.length; j++) {
                if (j == i) {
                  get_one_dat.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(onehour[j]) });
                }
              }
            }
          }

          var get_onehalf_dat = [];
          for (var i = 0; i < bgdate_array.length; i++) {
            var res = bgdate_array[i].split("-");

            if (obj.onehalf != null) {
              for (var j = 0; j < obj.onehalf.length; j++) {
                if (j == i) {
                  get_onehalf_dat.push({ x: new Date(res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(onehalf[j]) });
                }
              }
            }
          }

          var get_two_dat = [];
          for (var i = 0; i < bgdate_array.length; i++) {
            var res = bgdate_array[i].split("-");
            if (twohours[i] != "") {
              get_two_dat.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(twohours[i]) });
            }
          }

          var get_urine_dat = [];
          for (var i = 0; i < ugdate.length; i++) {
            var res = ugdate[i].split("-");
            if (urineglucose[i] != "") {
              get_urine_dat.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(urineglucose[i]) });
            }
          }

          var get_keto_dat = [];
          for (var i = 0; i < ugdate.length; i++) {
            var res = ugdate[i].split("-");
            if (keto[i] != "") {
              get_keto_dat.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(keto[i]) });
            }
          }

          var get_prot_dat = [];
          for (var i = 0; i < ugdate.length; i++) {
            var res = ugdate[i].split("-");
            if (prot[i] != "") {
              get_prot_dat.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(prot[i]) });
            }
          }
          
          if (get_fast_dat != undefined) {
            Diab_graph.oneyear_Blood_Graph(get_fast_dat, get_one_dat, get_onehalf_dat, get_two_dat);
          }
          

          var get_hba1_dat = [];
          for (var i = 0; i < bgdate_array.length; i++) {
            var res = bgdate_array[i].split("-");
            if (hba1c[i] != "" && hba1c[i] != undefined) {
              get_hba1_dat.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(hba1c[i]) });
              
            }
          }
          
          if (get_hba1_dat != undefined) {
            this.HbA1c_1year_no_data = true;
            this.HbA1c_1year_graph = false;

          } else {
            this.HbA1c_1year_no_data = false;
            this.HbA1c_1year_graph = true;
          }
          Diab_graph.oneyearHbA1c_Graph(get_hba1_dat);

          // urine data
          var get_urine_dat = [];
          for (var i = 0; i < ugdate.length; i++) {
            var res = ugdate[i].split("-");
            if (urineglucose[i] != "") {
              get_urine_dat.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(urineglucose[i]) });
            }
          }

          var get_keto_dat = [];
          for (var i = 0; i < ugdate.length; i++) {
            var res = ugdate[i].split("-");
            if (keto[i] != "") {
              get_keto_dat.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(keto[i]) });
            }
          }

          var get_prot_dat = [];
          for (var i = 0; i < ugdate.length; i++) {
            var res = ugdate[i].split("-");
            if (prot[i] != "") {
              get_prot_dat.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(prot[i]) });
            }
          }

          if (get_urine_dat.length == 0 && get_keto_dat.length == 0 && get_prot_dat.length == 0) {
            this.blood_glu_1year_notavail = false;
            this.blood_glu_1year_avail = true;

          } else {
            this.blood_glu_1year_notavail = true;
            this.blood_glu_1year_avail = false;
            Diab_graph.oneyearUrine_Graph(get_urine_dat, get_keto_dat, get_prot_dat);
          }

          var get_hdl = [];
          for (var i = 0; i < coldate.length; i++) {
            var res = coldate[i].split("-");
            if (hdl[i] != "") {
              get_hdl.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(hdl[i]) });
            }
          }

          var get_ldl = [];
          for (var i = 0; i < coldate.length; i++) {
            var res = coldate[i].split("-");
            if (ldl[i] != "") {
              get_ldl.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(ldl[i]) });
            }
          }

          var get_trigs = [];
          for (var i = 0; i < coldate.length; i++) {
            var res = coldate[i].split("-");
            if (trigs[i] != "") {
              get_trigs.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(trigs[i]) });
            }
          }

          var get_vldl = Array();
          for (var i = 0; i < coldate.length; i++) {
            var res = coldate[i].split("-");
            if (vldl[i] != "") {
              get_vldl.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(vldl[i]) });
            }
          }

          if (get_hdl.length == 0 && get_ldl.length == 0 && get_trigs.length == 0 && get_vldl.length == 0) {
            this.coles_1year_notavail = false;
            this.coles_1year_avail = true;

          } else {
            this.coles_1year_notavail = true;
            this.coles_1year_avail = false;
            Diab_graph.oneyearCholesterol_Graph(get_hdl, get_ldl, get_trigs, get_vldl);
          }
        } else {
          this.HbA1c_1year_no_data = false;
          this.HbA1c_1year_graph = true;
          this.blood_glu_1year_notavail = true;
          this.blood_glu_1year_avail = false;
          this.coles_1year_notavail = true;
          this.coles_1year_avail = false;
        }
      }

    } else if (dat == "2year") { // six month graph
      var sen_pass = {
        life_style_id : this.lifeStyleID,
        interval: "730",
        country: ipaddress.country_code,
      }
      var response = await this.serviceAPI.PostData('diab/pgread', sen_pass).toPromise();
      if (response) {
        console.log("get the food history" + JSON.stringify(response));
        var obj = JSON.parse(JSON.stringify(response));
        if (obj.bgdate.length != 0) {
          var onemon_array_list = [];

          for (var i = 0; i < obj.vitaldate.length; i++) {
            if (obj.heartrate != null) {
              var heart_graph = obj.heartrate[i];
            }

            if (obj.resprate != null) {
              var respi_graph = obj.resprate[i];
            }

            if (obj.temparatur != null) {
              var temp_graph = obj.temparatur[i];
            }

            if (obj.bp != null) {
              var bp_graph = obj.bp[i];
            }

            onemon_array_list.push({
              date_three: obj.vitaldate[i],
              temparature: obj.temp_graph,
              blood_pressure: bp_graph,
              heart_rate: heart_graph,
              respiratory_rate: respi_graph
            })
          }

          var fasting = Array();
          var onehour = Array();
          var onehalf = Array();
          var twohours = Array();
          var hba1c = Array();
          var keto = Array();
          var prot = Array();
          var urineglucose = Array();
          var ugdate = Array();
          var bgdate_array = new Array();
          var coldate = Array();
          var hdl = Array();
          var ldl = Array();
          var trigs = Array();
          var vldl = Array();

          if (obj.coldate != null) {
            for (var i = 0; i < obj.coldate.length; i++) {
              coldate.push(obj.coldate[i]);
            }
          }

          if (obj.ugdate != null) {
            for (var i = 0; i < obj.ugdate.length; i++) {
              ugdate.push(obj.ugdate[i]);
            }
          }

          if (obj.bgdate != null) {
            for (var i = 0; i < obj.bgdate.length; i++) {
              bgdate_array.push(obj.bgdate[i]);
            }
          }

          if (obj.fasting != null) {
            for (var i = 0; i < obj.fasting.length; i++) {
              fasting.push(obj.fasting[i]);
            }
          }

          if (obj.onehour != null) {
            for (var i = 0; i < obj.onehour.length; i++) {
              onehour.push(obj.onehour[i]);
            }
          }

          if (obj.onehalf != null) {
            for (var i = 0; i < obj.onehalf.length; i++) {
              onehalf.push(obj.onehalf[i]);
            }
          }

          var hba1ccheck = new Array();
          if (obj.hba1c != null) {
            for (var i = 0; i < obj.hba1c.length; i++) {
              if (obj.hba1c[i] != null) {
                hba1c.push(obj.hba1c[i]);

                if (hba1c[i] == "") {
                  hba1ccheck.push(hba1c[i]);
                }
              }
            }
          }

          if (obj.ketones != null) {
            for (var i = 0; i < obj.ketones.length; i++) {
              keto.push(obj.ketones[i]);
            }
          }

          if (obj.proteins != null) {
            for (var i = 0; i < obj.proteins.length; i++) {
              prot.push(obj.proteins[i]);
            }
          }

          if (obj.twohours != null) {
            for (var i = 0; i < obj.twohours.length; i++) {
              twohours.push(obj.twohours[i]);
            }
          }

          var Urinecheck = new Array();
          if (obj.urineglucose != null) {
            for (var i = 0; i < obj.urineglucose.length; i++) {
              urineglucose.push(obj.urineglucose[i]);

              if (urineglucose[i] == "") {
                Urinecheck.push(urineglucose[i]);
              }
            }
          }

          var hdlcheck = new Array();
          if (obj.hdl != null) {
            for (var i = 0; i < obj.hdl.length; i++) {
              hdl.push(obj.hdl[i]);

              if (hdl[i] == "") {
                hdlcheck.push(hdl[i]);
              }
            }
          }

          var ldlcheck = new Array();
          if (obj.ldl != null) {
            for (var i = 0; i < obj.ldl.length; i++) {
              ldl.push(obj.ldl[i]);

              if (ldl[i] == "") {
                ldlcheck.push(ldl[i]);
              }
            }
          }

          var trigscheck = new Array();
          if (obj.trigs != null) {
            for (var i = 0; i < obj.trigs.length; i++) {
              trigs.push(obj.trigs[i]);

              if (trigs[i] == "") {
                trigscheck.push(trigs[i]);
              }
            }
          }

          var vldlcheck = new Array();
          if (obj.vldl != null) {
            for (var i = 0; i < obj.vldl.length; i++) {
              vldl.push(obj.vldl[i]);

              if (vldl[i] == "") {
                vldlcheck.push(ldl[i]);
              }
            }
          }

          var get_fast_dat = Array();
          for (var i = 0; i < bgdate_array.length; i++) {
            var res = bgdate_array[i].split("-");
            if (fasting[i] != "") {
              get_fast_dat.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(fasting[i]) });
            }
          }

          var get_one_dat = Array();
          for (var i = 0; i < bgdate_array.length; i++) {
            var res = bgdate_array[i].split("-");
            if (obj.onehour != null) {
              for (var j = 0; j < obj.onehour.length; j++) {
                if (j == i) {
                  get_one_dat.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(onehour[j]) });
                }
              }
            }
          }

          var get_onehalf_dat = Array();
          for (var i = 0; i < bgdate_array.length; i++) {
            var res = bgdate_array[i].split("-");

            if (obj.onehalf != null) {
              for (var j = 0; j < obj.onehalf.length; j++) {
                if (j == i) {
                  get_onehalf_dat.push({ x: new Date(res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(onehalf[j]) });
                }
              }
            }
          }

          var get_two_dat = Array();
          for (var i = 0; i < bgdate_array.length; i++) {
            var res = bgdate_array[i].split("-");
            if (twohours[i] != "") {
              get_two_dat.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(twohours[i]) });
            }
          }

          var get_urine_dat = Array();
          for (var i = 0; i < ugdate.length; i++) {
            var res = ugdate[i].split("-");
            if (urineglucose[i] != "") {
              get_urine_dat.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(urineglucose[i]) });
            }
          }

          var get_keto_dat = Array();
          for (var i = 0; i < ugdate.length; i++) {
            var res = ugdate[i].split("-");
            if (keto[i] != "") {
              get_keto_dat.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(keto[i]) });
            }
          }

          var get_prot_dat = Array();
          for (var i = 0; i < ugdate.length; i++) {
            var res = ugdate[i].split("-");
            if (prot[i] != "") {
              get_prot_dat.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(prot[i]) });
            }
          }
          Diab_graph.twoyear_Blood_Graph(get_fast_dat, get_one_dat, get_onehalf_dat, get_two_dat);

          var get_hba1_dat = Array();
          for (var i = 0; i < bgdate_array.length; i++) {
            var res = bgdate_array[i].split("-");
            if (hba1c[i] != "" && hba1c[i] != undefined) {
              get_hba1_dat.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(hba1c[i]) });
            }
          }

          if (get_hba1_dat.length != 0) {
            this.HbA1c_2year_no_data = true;
            this.HbA1c_2year_graph = false;

          } else {
            this.HbA1c_2year_no_data = false;
            this.HbA1c_2year_graph = true;
          }
          Diab_graph.twoyearHbA1c_Graph(get_hba1_dat);

          // urine data
          var get_urine_dat = Array();
          for (var i = 0; i < ugdate.length; i++) {
            var res = ugdate[i].split("-");
            if (urineglucose[i] != "") {
              get_urine_dat.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(urineglucose[i]) });
            }
          }

          var get_keto_dat = Array();
          for (var i = 0; i < ugdate.length; i++) {
            var res = ugdate[i].split("-");
            if (keto[i] != "") {
              get_keto_dat.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(keto[i]) });
            }
          }

          var get_prot_dat = Array();
          for (var i = 0; i < ugdate.length; i++) {
            var res = ugdate[i].split("-");
            if (prot[i] != "") {
              get_prot_dat.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(prot[i]) });
            }
          }

          if (get_urine_dat.length == 0 && get_keto_dat.length == 0 && get_prot_dat.length == 0) {
            this.blood_glu_2year_notavail = false;
            this.blood_glu_2year_avail = true;

          } else {
            this.blood_glu_2year_notavail = true;
            this.blood_glu_2year_avail = false;
            Diab_graph.twoyearUrine_Graph(get_urine_dat, get_keto_dat, get_prot_dat);
          }

          var get_hdl = Array();
          for (var i = 0; i < coldate.length; i++) {
            var res = coldate[i].split("-");
            if (hdl[i] != "") {
              get_hdl.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(hdl[i]) });
            }
          }

          var get_ldl = Array();
          for (var i = 0; i < coldate.length; i++) {
            var res = coldate[i].split("-");
            if (ldl[i] != "") {
              get_ldl.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(ldl[i]) });
            }
          }

          var get_trigs = Array();
          for (var i = 0; i < coldate.length; i++) {
            var res = coldate[i].split("-");
            if (trigs[i] != "") {
              get_trigs.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(trigs[i]) });
            }
          }

          var get_vldl = Array();
          for (var i = 0; i < coldate.length; i++) {
            var res = coldate[i].split("-");
            if (vldl[i] != "") {
              get_vldl.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(vldl[i]) });
            }
          }

          if (get_hdl.length == 0 && get_ldl.length == 0 && get_trigs.length == 0 && get_vldl.length == 0) {
            this.coles_2year_notavail = false;
            this.coles_2year_avail = true;

          } else {
            this.coles_2year_notavail = true;
            this.coles_2year_avail = false;
            Diab_graph.twoyearCholesterol_Graph(get_hdl, get_ldl, get_trigs, get_vldl);
          }
        } else {
          this.HbA1c_2year_no_data = false;
          this.HbA1c_2year_graph = true;
          this.blood_glu_2year_notavail = true;
          this.blood_glu_2year_avail = false;
          this.coles_2year_notavail = true;
          this.coles_2year_avail = false;
        }
      }
      
    }
  }
}
