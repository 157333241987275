<div *ngIf="hm == 'min'">
    <table id="tbl_scpersonal_width">
        <tr>
            <th style="text-align:left;">Have you had recent unexplained weight gain?</th>
            <td><input type="text" class="ipcss scpersonal_width" [readonly]="isReadonly()" [(ngModel)]="gain_txt"
                    matInput /></td>
        </tr>
        <tr>
            <th style="text-align:left;">Have you had recent unexplained weight loss?</th>
            <td><input type="text" class="ipcss scpersonal_width" [readonly]="isReadonly()"
                    [(ngModel)]="loss_weight_txt" matInput /></td>
        </tr>
    </table>
</div>

<div *ngIf="hm == 'max'">
    <div class="Details">
        <div class="row" style="padding-left: 13px;">
            <div class="col-12">
                <div class="row">
                    <div class="col-8 col-sm-7 col-md-8 col-xl-6 col-lg-8">
                        <mat-label class="matlabel">Have you had recent unexplained weight gain?</mat-label>
                    </div>
                    <div class="col-3 col-sm-4 col-md-2 col-xl-1 col-lg-2">
                        <input type="text" class="ipcss scpersonal_width" [readonly]="isReadonly()"
                            [(ngModel)]="gain_txt" matInput />
                    </div>
                    <div class="col-8 col-sm-7 col-md-8 col-xl-6 col-lg-8">
                        <mat-label class="matlabel">Have you had recent unexplained weight loss?</mat-label>
                    </div>
                    <div class="col-3 col-sm-4 col-md-2 col-xl-1 col-lg-2">
                        <input type="text" class="ipcss scpersonal_width" [readonly]="isReadonly()"
                            [(ngModel)]="loss_weight_txt" matInput />
                    </div>

                    <div class="col-8 col-sm-7 col-md-8 col-xl-6 col-lg-8">
                        <mat-label class="matlabel">Have you had recent fever, chills?</mat-label>
                    </div>
                    <div class="col-3 col-sm-4 col-md-2 col-xl-1 col-lg-2">
                        <input type="text" class="ipcss scpersonal_width" [readonly]="isReadonly()"
                            [(ngModel)]="fever_cills" matInput />
                    </div>
                    <div class="col-8 col-sm-7 col-md-8 col-xl-6 col-lg-8">
                        <mat-label class="matlabel">Are you frequently tired?</mat-label>
                    </div>
                    <div class="col-3 col-sm-4 col-md-2 col-xl-1 col-lg-2">
                        <input type="text" class="ipcss scpersonal_width" [readonly]="isReadonly()"
                            [(ngModel)]="freq_tired" matInput />
                    </div>

                    <div class="col-8 col-sm-7 col-md-8 col-xl-6 col-lg-8">
                        <mat-label class="matlabel">Are you taking any prescribed medications?</mat-label>
                    </div>
                    <div class="col-3 col-sm-4 col-md-2 col-xl-1 col-lg-2">
                        <input type="text" class="ipcss scpersonal_width" [readonly]="isReadonly()"
                            [(ngModel)]="pres_medici" matInput />
                    </div>

                    <div class="col-8 col-sm-7 col-md-8 col-xl-6 col-lg-8">
                        <mat-label class="matlabel">Are you taking any OTC / non-prescribed medications?</mat-label>
                    </div>
                    <div class="col-3 col-sm-4 col-md-2 col-xl-1 col-lg-2" [hidden]="otc_label">
                        <input type="text" class="ipcss scpersonal_width"  [readonly]="isReadonly()"
                            [(ngModel)]="otc_txt" matInput />
                    </div>
                    <div class="col-3 col-sm-4 col-md-2 col-xl-1 col-lg-2" [hidden]="otc_image" style="text-align: center;">
                        <img src="../../../assets/img/OTC.png" (click)="Otc_click('1')"
                            class="so_icon" />
                    </div>

                    <div class="col-8 col-sm-7 col-md-8 col-xl-6 col-lg-8">
                        <mat-label class="matlabel">Are you taking any vitamins or herbal supplements?</mat-label>
                    </div>
                    <div class="col-3 col-sm-4 col-md-2 col-xl-1 col-lg-2" [hidden]="vitamin_label">
                        <input type="text" class="ipcss scpersonal_width"
                            [readonly]="isReadonly()" [(ngModel)]="vite_txt" matInput />
                    </div>
                    <div class="col-3 col-sm-4 col-md-2 col-xl-1 col-lg-2" [hidden]="vitamin_image" style="text-align: center;">
                        <img src="../../../assets/img/VitaminorHerbal_icon.png"
                            (click)="Vitamin_click('2')" class="so_icon" />
                    </div>

                    <div class="col-8 col-sm-7 col-md-8 col-xl-6 col-lg-8">
                        <mat-label class="matlabel">Have you ever had reaction to medication or supplements?</mat-label>
                    </div>
                    <div class="col-3 col-sm-4 col-md-2 col-xl-1 col-lg-2"  [hidden]="reaction_label">
                        <input type="text" class="ipcss scpersonal_width"
                            [readonly]="isReadonly()" [(ngModel)]="resc_txt" matInput />
                    </div>
                    <div class="col-3 col-sm-4 col-md-2 col-xl-1 col-lg-2" [hidden]="reaction_image" style="text-align: center;">
                        <img src="../../../assets/img/Reaction_Icon.png"
                            (click)="Medication_click('3')" class="so_icon" />
                    </div>

                    <div class="col-8 col-sm-7 col-md-8 col-xl-6 col-lg-8">
                        <mat-label class="matlabel">Are you allergic to any substance?</mat-label>
                    </div>
                    <div class="col-3 col-sm-4 col-md-2 col-xl-1 col-lg-2" [hidden]="allergic_label">
                        <input type="text" class="ipcss scpersonal_width"
                            [readonly]="isReadonly()" [(ngModel)]="all_txt" matInput />
                    </div>
                    <div class="col-3 col-sm-4 col-md-2 col-xl-1 col-lg-2" [hidden]="allergic_image" style="text-align: center;">
                        <img src="../../../assets/img/Allergy_Icon.png"
                            (click)="Allergic_click('4')" class="so_icon" />
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>