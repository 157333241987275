
	<div class="container-fulid">
    
        <app-header></app-header>
        
        <section>
        
                <div class="row">
                    <div class="col-7 tex">
                        
                         <h1 class="text-center th" style="color: #033D28;">Your health care is<br>our proposel</h1><br>
                        <h5 class="text-center tp" style="color: #033D28;">We work to take care of your health & body</h5><br>
                        <button class="topbutton btn mx-auto d-block text-light">Free Consulation</button>
                        
                       <!-- <div class="dashb"></div>  <div class="dasha"></div> -->
                       
                     
                        <!-- <div class="dashc"></div> -->
                  
                           </div>
                    <div class="col-5">
                        <img src="../../../assets/img/Shape 50.png" class="img-fluid imgw">
                    
                    </div>
                </div>
            
        </section>
        
        <section>
            <h1 class="text-center he" >OUR SERVICES</h1>
           <div class="container-fulid">
             <div class="row">
                 
                 <div class="col-6 ">
                     
    
    
    <!-- 
                    <div class="mb-3">
                        <div class="shap1">
                            <h1 class="shap1head text-center"> Client</h1>
                            <p class="shap1content">Clients can use Tervys platform to analyse their health parameters, device plans for their optimal health using activity, nutritional, healthcare device integrations and vital information. They can book appointment with service providers and view prescription, diagnosis reports and service provider's suggestions and investigation in the form of notes / casesheets etc,.</p>
                        </div>
                    </div>
                    <div class="mb-3">
                        <div class="shap2">
                            <h1 class="shap2head text-center">Front-desk</h1>
                            <p class="shap2content">Front desk receptionists usually act as gatekeepers for hospitals or clinics. They can manage appointments,  print bills or prescriptions etc.</p>
                        </div>
                    </div>
                    <div class="mb-3">
                        <div class="shap1">
                            <h1 class="shap1head text-center">Doctor</h1>
                            <p class="shap1content">Using this module, doctors can capture patient's health information to provide better and optimal treatment, they can generate bills, view current appointment details etc.</p>
                        </div>
                    </div>
                    <div class="mb-3">
                        <div class="shap2">
                            <h1 class="shap2head text-center">Diagnosis</h1>
                            <p class="shap2content">Diagnosis centers will be encouraged to use our platform to provide services like, diagnosis appointment booking sample collections and providing reports digitally or manually. Manage their own test categories and test offering from each center etc,.</p>
                        </div>
                    </div>
                    <div class="mb-3">
                        <div class="shap1">
                            <h1 class="shap1head text-center">Pharmacy</h1>
                            <p class="shap1content">Its a comprehensive and complete piece of digital information regarding hospitals or clinics or phamacies order, stock details, purchase orders etc,.</p>
                        </div>
                    </div>
                    <div class="mb-3">
                        <div class="shap2">
                            <h1 class="shap2head text-center">Homecare</h1>
                            <p class="shap2content">This service is provided at the patient location and is to make the access to quality medical care at their convenient and designated time.</p>
                        </div>
                    </div> -->
    
    
    
                 <div class="card border-0">
                     <img src="../../../assets/img/Subtraction 5.png" class="card-img" alt="...">
                       <div class="card-img-overlay shap1">
                           <h1 class="card-title tita text">Client</h1>
                               <p class="card-text txa text-white" >Clients can use Tervys platform to analyse their health parameters, device plans for their optimal health using activity, nutritional, healthcare device integrations and vital information. They can book appointment with service providers and view prescription, diagnosis reports and service provider's suggestions and investigation in the form of notes / casesheets etc,.  </p>
        
                              </div>
                  </div>
                        
                <div class="card border-0">
                 <img src="../../../assets/img/Subtraction 13.png" class="card-img" alt="...">
                     <div class="card-img-overlay shap2">
                        
                               <h1 class="card-title titb ">Front-desk</h1>
                            
                             <p class="card-text txb text-white" >Front desk receptionists usually act as gatekeepers for hospitals or clinics. They can manage appointments,  print bills or prescriptions etc.</p>
                          
                       </div>
                   </div>
                   <div class="card border-0">
                        <img src="../../../assets/img/Subtraction 5.png" class="card-img" alt="...">
                      <div class="card-img-overlay shap1">
                          <h1 class="card-title tita">Doctor</h1>
                              <p class="card-text txa text-white text-center">Using this module, doctors can capture patient's health information to provide better and optimal treatment, they can generate bills, view current appointment details etc.</p>
        
                             </div>
                    </div>
                    <div class="card border-0">
                        <img src="../../../assets/img/Subtraction 13.png" class="card-img" alt="...">
                        <div class="card-img-overlay shap2">
                           
                                  <h1 class="card-title titb "> Diagnosis</h1>
                             
                                <p class="card-text txb text-white" >Diagnosis centers will be encouraged to use our platform to provide services like, diagnosis appointment booking sample collections and providing reports digitally or manually. Manage their own test categories and test offering from each center etc,.
                                </p>
                            
                          </div>
                    </div> 
        
                    <div class="card border-0">
                        <img src="../../../assets/img/Subtraction 5.png" class="card-img" alt="...">
                          <div class="card-img-overlay shap1">
                              <h1 class="card-title tita">Pharmacy</h1>
                                  <p class="card-text txa text-white text-center " >Its a comprehensive and complete piece of digital information regarding hospitals or clinics or phamacies order, stock details, purchase orders etc,.
                                </p>
           
                                 </div>
                    </div>
                    <div class="card border-0">
                        <img src="../../../assets/img/Subtraction 13.png" class="card-img" alt="...">
                            <div class="card-img-overlay shap2">
                               
                                      <h1 class="card-title titb"> Homecare</h1>
                                  
                                    <p class="card-text txb text-white" >This service is provided at the patient location and is to make the access to quality medical care at their convenient and designated time.</p>
                                 
                              </div>
                    </div>
                </div>
                <div class="col-6">
                   
                   
                        <img src="../../../assets/img/Shape 33.png" class="img-fluid img" >
                    
                </div>
              </div>
            </div>
        </section> 
        
        <app-footer></app-footer>
        </div>