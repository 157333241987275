import { Component, OnInit ,ElementRef,Input,Inject,ViewEncapsulation,HostListener} from '@angular/core';
import { Http, Headers } from '@angular/http';
// import { ipaddress.getIp, Ip_with_img_address, country_code } from '../../../assets/js/ipaddress';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ipaddress } from '../../ipaddress' ;import { Diagnosis_Helper } from '../Diagnosis_Helper';
import { Date_Formate } from 'src/assets/js/common';

@Component({
  selector: 'app-diagnosispopup-component',
  templateUrl: './diagnosispopup-component.component.html',
  styleUrls: ['./diagnosispopup-component.component.scss']
})
export class DiagnosispopupComponentComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  public listProducts=[];
  public referedNameArray
  public diagId;
  public hptl_clinic_id;
  public hosp_diag;
  public patient_type;  persons: any = [];
  masterSelected: boolean = false;
  checklist: any;
  checkedList: any;

  constructor(public http:Http, public el: ElementRef,public dialogRef: MatDialogRef<DiagnosispopupComponentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      dialogRef.disableClose = true;
      console.log("di dguyguig g"+JSON.stringify(data))
      this.hptl_clinic_id=data.hptl_clinic_id;
      this.hosp_diag =data.hptl_clinic_id;
      this.patient_type=data.patient_type;
    //   if(this.History == "walkin"){
    //     this.client_reg_id = data.client_reg_id;
    //     this.relation_id = data.relation_id;
    //     this.sub_rel_id = data.sub_rel_id;
    //     this.doc_reg_id = data.doc_reg_id;
    // }
   }


  ngOnInit() {
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',

      pageLength: 25,
      "lengthMenu": [[25, 50, -1], [25, 50, "All"]],
      //"order": [[2, 'asc']],
      "columnDefs": [
        { "orderable": false, "targets": [ 3, 5,6] },
        { "targets": [0, 2, 3, 4, 5], "searchable": false }

      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        // searchPlaceholder: "Search patients with name or mobile no or patient id"
      },
      dom: '<"row"<"col-sm-12 col-xs-12"f>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10">>'
    };
    if (Diagnosis_Helper.getCreateDiagAppt().flow == "frontdesk") {
      this.diagId = Diagnosis_Helper.getCreateDiagAppt().center_id;
    }
    var sendata={
      hptl_clinic_id:this.diagId,
      hosp_diag :this.diagId,
      pattype:this.patient_type,
    }
    this.inpatientlist(sendata)
  }
  ClosePopup(){
    this.dialogRef.close();
  }
     // Icd submit
     submit() {
      this.dialogRef.close();
    }
    inpatientlist(sendata){
      console.log("check data --"+JSON.stringify(sendata))

      this.listProducts=[];
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'adm/cid',sendata,
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            this.referedNameArray = obj.clients;
            console.log("check data --"+JSON.stringify(this.referedNameArray))
            if(obj.clients.length  != 0){
              for (var i = 0; i < this.referedNameArray.length; i++) {
                var patientname;
                  if (this.referedNameArray[i].middle_name != undefined) {
                    patientname = this.referedNameArray[i].first_name + " " + this.referedNameArray[i].middle_name + " " + this.referedNameArray[i].last_name;
                  } else {
                    if (this.referedNameArray[i].last_name != undefined) {
                      patientname = this.referedNameArray[i].first_name + " " + this.referedNameArray[i].last_name;
                    } else {
                      patientname = this.referedNameArray[i].first_name
                    }
                  }
                  if (this.referedNameArray[i].mobile != undefined) {
                    var mobile =this.referedNameArray[i].mobile
                  }
                  if (this.referedNameArray[i].admission_date != undefined) {
                    var Datecheck =Date_Formate(this.referedNameArray[i].admission_date)
                  }
                  if (this.referedNameArray[i].doctor_name != undefined) {
                    var doctor_name =this.referedNameArray[i].doctor_name
                  }
                  // if (this.referedNameArray[i].middle_name != undefined) {
                  //   docname = this.referedNameArray[i].first_name + " " + this.referedNameArray[i].middle_name + " " + this.referedNameArray[i].last_name;
                  // } else {
                  //   if (this.referedNameArray[i].last_name != undefined) {
                  //     docname = this.referedNameArray[i].first_name + " " + this.referedNameArray[i].last_name;
                  //   } else {
                  //     docname = this.referedNameArray[i].first_name
                  //   }
                  // }
                  this.listProducts.push({
                    patientname: patientname,
                    mobile: this.referedNameArray[i].mobile,
                    patient_id:this.referedNameArray[i].client_reg_id,
                    Date:(Datecheck),
                    doctor_name:doctor_name
                  });
                  console.log("this.listProducts.length"+this.listProducts.length)
                  console.log("JS"+JSON.stringify(this.listProducts))
              }
            }
            console.log("JS"+JSON.stringify(this.listProducts))

          
           
      
            
          },
          error => {
  
          }
          
        ) 
    }
    view_bill(person){
      console.log("check data --"+JSON.stringify(person))
      this.dialogRef.close(person);
    }
    dismiss(){
      this.dialogRef.close();
    }
}
