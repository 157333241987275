import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { ipaddress } from '../../ipaddress';
import { Client_Helper } from '../Client_helper';
import { Chart } from 'chart.js';
import { Date_Formate, Time_Formate } from 'src/assets/js/common';
import { Helper_Class } from 'src/app/helper_class';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ClientViewService } from '../clientview/client-view.service';
@Component({
  selector: 'app-view-activity',
  templateUrl: './view-activity.component.html',
  styleUrls: ['./view-activity.component.scss']
})
export class ViewActivityComponent implements OnInit {
  public viewTotalExerDetails = 'no';
  public dayGraph:any;
  public showGraph;
  public othersGraph:any;
  public userInfo;
  public currentdate;
  public activityTracks = [];
  public chartData = [];
  public chartHeadingArray:any = ["Day","Week","Month","Quarterly","Half","Yearly"];

  constructor(public gservice:CommonDataService,public http:HttpClient,public routes:ActivatedRoute,public router:Router,public toastr:ToastrService
    ,public clientservice:ClientViewService) { 
  }

  ngOnInit(): void {
    this.userInfo = Helper_Class.getInfo();
    this.viewTotalExerDetails = Client_Helper.getViewActivity() == "yes" ? Client_Helper.getViewActivity() : 'no';
    this.fromHomePage();
    this.changeforHomeType("Day");
  }

  fromHomePage(){
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "adm/curdate", { country: "IN" }, { headers: headers }).subscribe(
      data => {
          var obj = JSON.parse(JSON.stringify(data));
          this.currentdate = obj.current_date;
          this.changeforHomeType("Day");
        },
      error => {});
  }

  changeforHomeType(id){  
    $("#"+id+"-home").removeClass("active");
    $("#"+id+"-home").addClass("active");
    switch (id) {      
        case 'Day': 
        this.getHomePageChart("0");
        break;
        case 'Week': 
        this.getHomePageChart("6");
        break;
        case 'Month': 
        this.getHomePageChart("30");
        break;
        case 'Quarterly': 
        this.getHomePageChart("90");
         break;
        case 'Half': 
        this.getHomePageChart("120");
          break;
        case 'Yearly': 
        this.getHomePageChart("120");       
          break;
      }
  }

  getHomePageChart(value){
    var send_data = {
      life_style_id:this.userInfo.life_style_id,
      interval:value,
      created_date:this.currentdate,
    }
    this.activityTracks = []; 
    this.chartData = [];
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'lifestyle/gact', (send_data),
      { headers: headers })
      .subscribe(
        response => {   
          var obj = JSON.parse(JSON.stringify(response));
          if(obj != null){   
            if(obj.activity_tracks.length != 0){
              this.activityTracks = []; 
              this.chartData = [];
              this.showGraph = "block";               
              for( var i=0; i < obj.activity_tracks.length;i++){
                this.activityTracks.push({
                  activity_type_id:obj.activity_tracks[i].activity_type_id,
                  act_type_name:obj.activity_tracks[i].act_type_name,
                  activity_id:obj.activity_tracks[i].activity_id,
                  activity_name:obj.activity_tracks[i].activity_name,
                  exercise_dur:obj.activity_tracks[i].activity_dur,
                  session:obj.activity_tracks[i].session,
                  kcal:obj.activity_tracks[i].kcal,
                  created_date:obj.activity_tracks[i].created_date,
                  created_time:obj.activity_tracks[i].created_time,
                  exerc_image:obj.activity_tracks[i].image,
                });
              }           
              if(obj != null){
                var Data = [];
                var DataSets:any;
                var xAxis = [];
                var dataLabels = [],bgColor = [];
                this.chartData = [];
                if(this.activityTracks.length != 0){    
                    var  exer = Client_Helper.getExerciseTracker() ; 
                    for(var i = 0; i < this.activityTracks.length; i++){
                        var duration = this.activityTracks[i].exercise_dur.split(":");
                        var duration2 = parseFloat(duration[0])+ "." +duration[1];
                        this.chartData.push({
                          exercise_dur: this.activityTracks[i].exercise_dur,
                          activity_id:this.activityTracks[i].activity_id,
                          date:Date_Formate(this.activityTracks[i].created_date),
                          exerc_image: ipaddress.Ip_with_img_address + this.activityTracks[i].exerc_image,
                          created_time:Time_Formate(this.activityTracks[i].created_time),
                          kcal:this.activityTracks[i].kcal,
                        });
                        var backgroundColor,borderColor;
                        if(this.activityTracks[i].session == "Morning"){
                          backgroundColor = "#01452c";
                          borderColor = "#01452c";
                        }else if(this.activityTracks[i].session == "Afternoon"){
                          backgroundColor = "#ff8b00";
                          borderColor = "#ff8b00";
                        }else{
                          backgroundColor = "#b00505";
                          borderColor = "#b00505";
                        }
                        Data[i] = this.activityTracks[i].kcal;
                        if(Data[i] == null)
                          Data[i].splice(1);
                        dataLabels[i] = this.activityTracks[i].session,
                        bgColor[i] = backgroundColor;                    
                        var created_date = this.activityTracks[i].created_date.split("-");
                        xAxis[i] = (created_date[2]+ " - " + created_date[1]);
                    }
                    DataSets = [{
                      label:dataLabels,
                      data:Data,
                      backgroundColor: bgColor,
                      borderColor:bgColor,
                      borderWidth: 1,
                    }];
                   if(this.dayGraph){
                    this.dayGraph.destroy();
                  }
                    let options:any;
                    options = {
                      responsive: true,
                      legend: {
                        position: "top" 
                      },
                      layout: {
                        padding: 15,
                      },
                      scales: {
                        xAxes: [
                          {
                            barThickness: 6,  
                            maxBarThickness: 8,
                            stacked: true,
                            gridLines: {
                              display: true,
                            },
                            ticks: {
                              display: true,
                              fontSize: 12,
                            },
                          },
                        ],
                        yAxes: [
                          {
                            stacked: true,
                            gridLines: {
                              display: true,
                            },
                            ticks: {
                              display: true,
                              fontSize: 12,
                              beginAtZero: true,
                              suggestedMin: 0,
                            },
                          },
                        ],
                      },
                    };
                    var ctx = <HTMLCanvasElement>document.getElementById("dayGraph");
                    this.dayGraph = new Chart(ctx, {
                      type: 'line',
                      data: {
                        labels: xAxis,
                        datasets: [
                          {
                            data:Data,
                            label: "Calories burned",
                            backgroundColor: "#01452c",
                            borderColor:"#484db3",
                            pointBackgroundColor: 'white',
                            fill: false,
                            borderWidth: 2,
                            spanGaps: true,
                          }]
                      },
                      options:options,
                    });
                }
              }     
            }else{
              if(this.dayGraph){
                this.dayGraph.destroy();
              }
              this.showGraph = "none"; 
            }
          }else{
            this.showGraph = "none";
          }      
        });
  }
}
