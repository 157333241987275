import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Http, Headers } from '@angular/http';
import { MatDialog } from '@angular/material/dialog';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { CommonDataService } from '../../providers/common-data.service';
import { Date_Formate } from '../../../assets/js/common.js';
import { ipaddress } from '../../ipaddress';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Client_Helper } from '../Client_helper';
import { ClientViewService } from '../clientview/client-view.service';
import { Message_data } from 'src/assets/js/Message_data';
import { Helper_Class } from 'src/app/helper_class';
declare var $: any;

@Component({
  selector: 'app-relationdetails',
  templateUrl: './relationdetails.component.html',
  styleUrls: ['./relationdetails.component.css']
})
export class RelationdetailsComponent implements OnInit {
  public dob1;
  public currentDate: string;
  public currentTime: string;
  public sameasFlag: boolean = false;
  public currentDateUrl: string;
  public getDate;
  public currentYear;
  public currentMaxDate;
  public relationId: string;
  public subRelId: string;
  public mobile: string;
  public gender: string;
  public addr1: string;
  public genderDisableFlag: boolean;
  public getdata: any;
  public addr2: string;
  public locationId: string;
  public locationName: string = "";
  public stateId: string;
  public countryId: string;
  public zipcode: string;
  public sameAsSelf: string;
  public relationUrl: string;
  public relationList = [];
  public bgrpList = [];
  public updateFlag: boolean = false;
  public relFName: string;
  public relMName: string;
  public relLName: string;
  public bloodId: string;
  public bloodDesc: string;
  public bgrpUrl: string;
  public toastMessage: string;
  public updateUrl: string;
  public dob: string = "";
  public genderdata = [];
  public sendUpdateData: any;
  public saverel;
  public locationList = [];
  public locationListData;
  public locationMainList;
  public cityUrl: string;
  public stateUrl: string;
  public countryUrl: string;
  public cityList;
  public stateList;
  public countryList;
  public filtCity;
  public filtState;
  public filtCountry;
  public filtCityDesc;
  public filtStateDesc;
  public isNewFlowFlag: boolean = true;
  public imageFileStr: string;
  public imgStr = null;
  public retrvImg = null;
  public masterRelationList = [];
  bloodfieldDisabled: boolean;
  dobfieldDisabled: boolean;
  public dobhide: boolean;
  public dobhidecal: boolean;
  public clntSalList = [];
  public clntSal;
  public salutation;
  public locationUrl;
  public locationNameByUrl;
  // public cityUrl;
  // public stateUrl;
  // public countryUrl;
  constructor(public clientservice: ClientViewService, public datepipe: DatePipe, public gservice: CommonDataService, public toastr: ToastrService, public http: Http, public router: Router, public dialog: MatDialog) {
    this.locationUrl = ipaddress.getIp + "usercontroller/loc";
    this.locationNameByUrl = ipaddress.getIp + "usercontroller/locbyname";
    this.cityUrl = ipaddress.getIp + "usercontroller/city";
    this.stateUrl = ipaddress.getIp + "usercontroller/state";
    this.countryUrl = ipaddress.getIp + "usercontroller/count";
  }

  ngOnInit(): void {
    this.currentDateUrl = ipaddress.getIp + "adm/curdate";
    this.bloodfieldDisabled = false;
    this.dobfieldDisabled = false;
    this.dobhide = true;
    this.dobhidecal = false;
    this.bloodId = undefined;
    this.bloodDesc = "Select";
    this.filtCity = "Select";
    
    if (Client_Helper.getrelationlist() != null) {
      var obj1 = (Client_Helper.getrelationlist());
      this.relFName = obj1.rel_f_name;
      this.relMName = obj1.rel_m_name;
      this.relLName = obj1.rel_l_name;
      this.relationId = obj1.relation_id
      this.subRelId = obj1.sub_rel_id;
      if (obj1.prof_img != undefined) {
        this.imageFileStr = ipaddress.Ip_with_img_address + obj1.prof_img;
        this.retrvImg = obj1.prof_img;
      } else {
        this.imageFileStr = "assets/img/default.jpg";
      }
      this.mobile = obj1.mobile;
      this.gender = obj1.gender_val;
      this.salutation = obj1.salutation;
      this.addr1 = obj1.addr1;
      this.addr2 = obj1.addr2;
      this.locationId = obj1.loc_id;
      this.locationName = obj1.loc_name;
      this.filtCity = obj1.city_id;
      this.filtCityDesc = obj1.city_desc;
      this.stateId = obj1.state_id;
      this.countryId = obj1.country_id;
      this.zipcode = obj1.zipcode;
      this.sameAsSelf = obj1.same_as_self;
      this.updateFlag = obj1.update_flag;
      this.bloodId = obj1.blood_id;
      this.bloodDesc = obj1.blood_desc;
      if (this.bloodDesc != undefined && this.bloodDesc != "") {
        this.bloodfieldDisabled = true;
      }
      this.isNewFlowFlag = obj1.flow;
      var dob = obj1.dateofbirth;
      if (dob !== undefined) {
        this.dobfieldDisabled = true;
        this.dob1 = Date_Formate(dob)
      } else {
        this.dob = "";
      }
      this.relationUrl = ipaddress.getIp + "usercontroller/getrelation/";
      this.bgrpUrl = ipaddress.getIp + "bd/bgroup/";
      this.getRelationList();
      this.getRelations();
      this.getBloodgrp();
      this.genderdata.push({
        "name": "Male",
      });
      this.genderdata.push({ "name": "Female" });
      this.genderdata.push({ "name": "Transgender" });
      if (this.sameAsSelf == "1") {
        this.sameasFlag = true;
      }
      if (this.isNewFlowFlag == true) {
        this.dobhide = true;
        this.dobhidecal = false;
      } else {
        this.dobhide = false;
        this.dobhidecal = true;
      }
      // this.cityUrl = ipaddress.getIp + "gen/city";
      // this.stateUrl = ipaddress.getIp + "gen/state";
      // this.countryUrl = ipaddress.getIp + "gen/count";
      // if (this.locationName != undefined && this.locationName != "") {
      //   this.selectLocation(this.locationName);
      // }
    } else if (Client_Helper.getrelationlist().flow == true) {
      this.imageFileStr = "../../../assets/img/default.jpg";
      this.relFName = "";
      this.relLName = "";
      this.relMName = "";
      this.mobile = "";
      this.gender = "";
      this.bloodId = "";
      this.dob = "";
      this.addr1 = "";
      this.addr2 = "";
    }
    this.getSalutations();
    this.getCurrentDate();
  }

  changeLocation(loc_name) {
    this.locationList = [];
    this.locationListData = [];
    if (this.locationName != undefined && this.locationName !== "" && this.locationName.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "gen/loc",
        JSON.stringify({
          loc_name: this.locationName
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            this.locationMainList = obj.locations;
            for (var i = 0; i < this.locationMainList.length; i++) {
              this.locationList.push(this.locationMainList[i]);
            }
            // this.locationList = this.locationListData.filter(function (this: any, el) {
            //   return el.toLowerCase().indexOf(this.loc_name.toLowerCase()) > -1;
            // }.bind(this));
          }, error => { });
    } else {
      this.locationList = [];
      this.cityList = [];
    }
  }

  selectLocation(loc_name) {
    this.locationName = loc_name;
    this.locationList = [];
    this.getCity(this.locationName);
    this.stateList = [];
    this.cityList = [];
    this.countryList = [];
  }

  codeInput(event) {
    let pass = /[4][8-9]{1}/.test(event.charCode) || /[5][0-7]{1}/.test(event.charCode);
    if (!pass) {
      return false;
    }
  }

  getRelationList() {
    var relation_url = ipaddress.getIp + "usercontroller/grel/";
    var send_client_reg_id = {
      client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(relation_url, JSON.stringify(send_client_reg_id), { headers: headers }).subscribe(
      data => {
        if (data.json().relations != null) {
          this.masterRelationList = data.json().relations;
        }
      }, error => { });
  }

  changeRelation() {
    this.gender = "0: undefined";
    this.genderDisableFlag = false;
    if (this.relationId != undefined) {
      if (this.masterRelationList.length != 0) {
        for (var i = 0; i < this.masterRelationList.length; i++) {
          if (this.masterRelationList[i].relationship_name == "Father" && this.relationId == "2") {
            this.relationId = "0: undefined";
            this.toastr.error(Message_data.existUrFatherDet);
          } else if (this.masterRelationList[i].relationship_name == "Mother" && this.relationId == "3") {
            this.relationId = "0: undefined";
            this.toastr.error(Message_data.existUrMomDet)
          } else if (this.masterRelationList[i].relationship_name == "Spouse" && this.relationId == "6") {
            this.relationId = "0: undefined";
            this.genderDisableFlag = false;
            this.toastr.error(Message_data.existUrSpouseDet);
          }
        }
      }
      if (this.relationId == "2" || this.relationId == "4" || this.relationId == "7") {
        this.gender = "Male";
        this.genderDisableFlag = true;
      } else if (this.relationId == "3" || this.relationId == "5" || this.relationId == "8") {
        this.gender = "Female";
        this.genderDisableFlag = true;
      } else {
        this.gender = "0: undefined";
        this.genderDisableFlag = false;
      }
    }
  }

  getRelations() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(this.relationUrl, { headers: headers }).subscribe(
      data => {
        this.relationList = [];
        for (var b = 0; b < data.json().relation.length; b++) {
          var obj = data.json().relation[b];
          if (obj.relationship_name !== "Self") {
            this.relationList.push({
              relation_name: obj.relationship_name,
              relation_id: obj.relationship_id
            });

          }
        }
        this.relationId = this.relationList[0].relation_id;
      }, error => { });
  }

  getBloodgrp() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(this.bgrpUrl, { headers: headers }).subscribe(
      data => {
        this.bgrpList = [];
        for (var b = 0; b < data.json().blood_groups.length; b++) {
          var obj = data.json().blood_groups[b];
          this.bgrpList.push({
            bgrp_name: obj.description,
            bgrp_id: obj.blood_group_id
          });
        }
      }, error => { });
  }

  update() {
    if (this.mobile == undefined || this.mobile == "") {
      this.toastr.error(Message_data.mandatory);
    } else if (this.sameasFlag == false && (this.addr1 == "" || this.addr1 == undefined)) {
      this.toastr.error(Message_data.enterAddr1)
    } else if (this.sameasFlag == false && (this.locationName == "" || this.locationName == undefined)) {
      this.toastr.error(Message_data.enterLoc)
    } else {
      var img_path = "default", url = ipaddress.getIp + "usercontroller/ureld/";
      if (this.imgStr != null) {
        img_path = this.imgStr.split(',')[1];
        url = ipaddress.getIp + "usercontroller/ureldweb/";
      } else {
        if (this.retrvImg != null) {
          img_path = this.retrvImg;
        }
      }
      if (this.dob !== undefined && this.dob != "") {
        var dobdata = Client_Helper.getrelationlist().dateofbirth;
      } else
        dobdata = undefined;
      var address2;
      if (this.addr2 != undefined) {
        address2 = encrypt_decript.Encript(this.addr2).toString();
      } else {
        address2 = " ";
      }
      if (this.sameasFlag == true) {
        if (this.relFName != undefined) {
          var first_name = encrypt_decript.Encript(this.relFName).toString();
        }
        if (this.relLName != undefined) {
          var last_name = encrypt_decript.Encript(this.relLName).toString();
        }
        if (this.mobile != undefined) {
          var mobile = encrypt_decript.Encript(this.mobile).toString();
        }
        if (this.gender != undefined) {
          var gender = encrypt_decript.Encript(this.gender).toString();
        }
        if (this.bloodId != undefined) {
          this.bloodId = this.bloodId
        }
        this.sendUpdateData = {
          client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
          sub_rel_id: this.subRelId,
          relation_id: this.relationId,
          first_name: first_name,
          last_name: last_name,
          mobile: mobile,
          dob: Client_Helper.getrelationlist().dateofbirth,
          gender: gender,
          profile_image: img_path,
          blood_group: this.bloodId,
          same_as_self: "1",
          salutation: this.clntSal,
        };
      } else {
        if (this.relFName != undefined) {
          var first_name = encrypt_decript.Encript(this.relFName).toString()
        }
        if (this.relLName != undefined) {
          var last_name = encrypt_decript.Encript(this.relLName).toString()
        }
        if (this.mobile != undefined) {
          var mobile = encrypt_decript.Encript(this.mobile).toString()
        }
        if (this.gender != undefined) {
          var gender = encrypt_decript.Encript(this.gender).toString()
        }
        if (this.bloodId != undefined) {
          this.bloodId = this.bloodId
        }
        if (this.addr1 != undefined) {
          var address1 = encrypt_decript.Encript(this.addr1).toString()
        }
        if (this.addr2 != undefined) {
          var address2 = encrypt_decript.Encript(this.addr2).toString()
        }
        if (this.zipcode != undefined) {
          var zip = encrypt_decript.Encript(this.zipcode).toString()
        }
        if (this.subRelId != null) {
          this.subRelId = this.subRelId;
        }
        if (Client_Helper.getrelationlist().dateofbirth != null && Client_Helper.getrelationlist().dateofbirth != undefined) {
          var dobdata = Client_Helper.getrelationlist().dateofbirth
        }
        var send_update_data = null;
        send_update_data = {
          client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
          sub_rel_id: this.subRelId,
          relation_id: this.relationId,
          first_name: first_name,
          last_name: last_name,
          mobile: mobile,
          dob: dobdata,
          gender: gender,
          profile_image: img_path,
          blood_group: this.bloodId,
          address1: address1,
          address2: address2,
          state: this.filtState,
          city: this.filtCity,
          location: this.locationId,
          zipcode: zip,
          country: this.countryId,
          salutation: this.clntSal,
        };
      }
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(url, JSON.stringify(this.sendUpdateData), { headers: headers }).subscribe(
        data => {
          if (data.json().key == "1") {
            this.toastr.success(Message_data.dataUpdSuccess);
            this.getRelation();
            this.clientservice.sendMessage('relations');
          } else {
            this.toastr.error(Message_data.dataNotUpd);
          }
        }, error => {
        }
      )
    }
  }

  getCity(data) {
    this.zipcode = "";
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.cityUrl, JSON.stringify({ loc_name: data }), { headers: headers }).subscribe(
      data => {
        if (data.json().cities != null) {
          this.cityList = data.json().cities;
          this.filtCity = this.cityList[0].city_id;
          this.filtCityDesc = this.cityList[0].city_desc;
          this.locationId = this.cityList[0].location_id;
          this.zipcode = this.cityList[0].zipcode;
          this.changeCity(this.cityList[0].city_id);
        }
      }, error => { });
  }

  changeCity(data) {
    if (data != null) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.stateUrl, JSON.stringify({ city_id: data }), { headers: headers }).subscribe(
        data => {
          if (data.json().states != null) {
            this.stateList = data.json().states;
            this.filtState = this.stateList[0].state_id;
            this.filtStateDesc = this.stateList[0].state_desc;
            this.changeState(this.stateList[0].state_id);
          }
        },
        error => {
        })
    } else {
      this.stateList = [];
      this.cityList = [];
      this.countryList = [];
    }
  }

  changeState(data) {
    if (data != null) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.countryUrl, JSON.stringify({ state_id: data }), { headers: headers }).subscribe(
        data => {
          if (data.json().countries != null) {
            this.countryList = data.json().countries;
            this.countryId = this.countryList[0].country_id;
            this.filtCountry = this.countryList[0].country_desc;
          }
        },
        error => {
        })
    } else {
      this.stateList = [];
      this.countryList = [];
    }
  }

  save() {
    if (this.relFName == "" || this.relFName == undefined) {
      this.toastr.error("Enter first name");
    } else if (this.relLName == "" && this.relLName == undefined) {
      this.toastr.error("Enter last name");
    } else if (this.mobile == "" && this.relLName == undefined) {
      this.toastr.error("Enter mobile no");
    } else if (this.dob == null || this.dob == undefined || this.dob == "") {
      this.toastr.error("Select dob");
    } else if (this.gender == null || this.gender == undefined || this.gender == "0: undefined") {
      this.toastr.error("Select gender");
    } else if (this.sameasFlag == false && (this.addr1 == "" || this.addr1 == undefined)) {
      this.toastr.error("Enter address1");
    } else if (this.sameasFlag == false && (this.clntLocation == "" || this.clntLocation == undefined)) {
      this.toastr.error("Enter location");
    } else {
      var sub_rel_id;
      if (this.subRelId != null && this.subRelId != undefined && this.subRelId != "") {
        sub_rel_id = this.subRelId
      }
      if (this.addr2 != null && this.addr2 != undefined) {
        var address2 = encrypt_decript.Encript(this.addr2).toString();
      }
      if (this.relFName != undefined) {
        var first_name1 = encrypt_decript.Encript(this.relFName).toString()
      }
      if (this.relMName != undefined) {
        var middle_name1 = encrypt_decript.Encript(this.relMName).toString()
      }
      if (this.relLName != null && this.relLName != undefined) {
        var last_name1 = encrypt_decript.Encript(this.relLName).toString();
      }
      if (this.mobile != undefined) {
        var mobile1 = encrypt_decript.Encript(this.mobile).toString()
      }
      if (this.gender != undefined) {
        var gender_val1 = encrypt_decript.Encript(this.gender).toString()
      }
      if (this.addr1 != undefined) {
        var address1 = encrypt_decript.Encript(this.addr1).toString()
      }
      if (this.zipcode != undefined) {
        var zip = encrypt_decript.Encript(this.zipcode).toString()
      }
      if (this.imageFileStr.split(',')[1] == undefined) {
        this.imageFileStr = "default";
      } else {
        this.imageFileStr = this.imageFileStr.split(',')[1];
      }
      if (this.dob !== undefined && this.dob != "") {
        var dobdata = (this.dob);
      } else {
        dobdata = undefined;
      }
      var img_path = "default", url = ipaddress.getIp + "usercontroller/sreld/";
      if (this.imgStr != null) {
        img_path = this.imgStr.split(',')[1];
        url = ipaddress.getIp + "usercontroller/sreldweb/";
      }
      if (this.sameasFlag == true) {
        this.getdata = null;
        this.getdata = {
          client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
          sub_rel_id: sub_rel_id,
          relation_id: this.relationId,
          first_name: first_name1,
          middle_name: middle_name1,
          last_name: last_name1,
          mobile: mobile1,
          dob: dobdata,
          gender: gender_val1,
          profile_image: img_path,
          blood_group: this.bloodId,
          salutation: this.clntSal,
          same_as_self: "1"
        };
      }
      else {
        this.getdata = null;
        if (sub_rel_id != undefined) {
          this.getdata = {
            client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
            sub_rel_id: this.subRelId,
            relation_id: this.relationId,
            first_name: first_name1,
            middle_name: middle_name1,
            last_name: last_name1,
            mobile: mobile1,
            dob: dobdata,
            gender: gender_val1,
            profile_image: img_path,
            blood_group: this.bloodId,
            address1: address1,
            address2: address2,
            country: this.countryId,
            state: this.filtState,
            city: this.filtCity,
            location: this.locationId,
            salutation: this.clntSal,
            zipcode: zip,
          };
        }
        else {
          this.getdata = {
            client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
            relation_id: this.relationId,
            first_name: first_name1,
            middle_name: middle_name1,
            last_name: last_name1,
            mobile: mobile1,
            dob: dobdata,
            gender: gender_val1,
            profile_image: img_path,
            blood_group: this.bloodId,
            address1: address1,
            address2: address2,
            country: this.countryId,
            state: this.filtState,
            city: this.filtCity,
            location: this.locationId,
            salutation: this.clntSal,
            zipcode: zip,
          };
        }
      }
      console.log("send dta--" + JSON.stringify(this.getdata))
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(url, JSON.stringify(this.getdata), { headers: headers }).subscribe(
        data => {
          if (data.json().key == "1") {
            this.toastMessage = data.json().result;
            this.toastr.success(Message_data.relSavedSuccess);
          //  this.getRelation();
            this.clientservice.sendMessage('relations');
            this.bloodId = undefined;
            this.bloodDesc = "Select";
          } else {
            this.toastMessage = data.json().result;
          }
        }, error => {
        }
      )
    }
  }

  async getRelation() {
    Client_Helper.setrelationlistDiet(undefined);
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    await this.http.post(ipaddress.getIp + "usercontroller/grel/", JSON.stringify({
      client_reg_id: Helper_Class.getInfo().client,
    }), { headers: headers }).toPromise().then(
      data => {
        Client_Helper.setrelationlistDiet(data.json());
      });
  }

  fileChange(data) {
    this.readThis(data.target);
  }

  readThis(inputValue: any): void {
    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();
    myReader.onloadend = (e) => {
      var fileSize = Math.round(inputValue.files[0].size / 1024);
      if (fileSize >= 50) {
        this.toastr.error(Message_data.maxImgSize);
        $("#file").val('');
        this.imageFileStr = "../../../assets/img/default.jpg";
        this.imgStr = null;
      } else {
        this.imgStr = myReader.result;
        $('#profile_img').attr('src', myReader.result);
      }
    }
    myReader.readAsDataURL(inputValue.files[0]);
  }

  changeAppDate(Appointment_Date) {
    if (this.currentDate != Appointment_Date) {
      var gsDayNames = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
      ];
      var get_date = Appointment_Date.split("-");
      var result = new Date(get_date[2] + "-" + get_date[1] + "-" + get_date[0]);
      var dayName = gsDayNames[result.getDay()];
      var Available_flag_1 = false;
    }
  }

  tcknOnInputChange() {
    $("#tcknInput").keyup(function (this: any) {
      $("#tcknInput").val(this.value.match(/[0-9]*/));
    });
  }

  myCallbackFunction = function (this: any, _params) {
    return new Promise((resolve, reject) => {
      resolve(this.any);
    });
  }

  back() {
    this.router.navigateByUrl('/relations')
  }

  sameaddress(e) {
    this.sameasFlag = e;
  }

  getCurrentDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.currentDateUrl, { country: "IN" }, { headers: headers }).subscribe(
      data => {
        this.currentDate = Date_Formate(data.json().current_date);
        this.currentTime = data.json().current_time;
        this.getDate = data.json().current_date.split('-');
        this.currentYear = this.getDate[0];
        this.currentMaxDate = data.json().current_date;
        //this.dob = data.json().current_date;
        this.getCountries("0");
      },
      error => {
      })
  }

  selectDob(date) {
    this.dob = date;
    if (this.dob != null) {
      this.changeAppDate((this.dob));
    }
  }
  getSalutations() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp + "gen/sal", { headers: headers }).subscribe(
      data => {
        var obj = JSON.parse(data["_body"]);
        if (obj != undefined) {
          for (var i = 0; i < obj.salutations.length; i++) {
            this.clntSalList.push({
              sal_id: obj.salutations[i].salutation_id,
              sal_desc: obj.salutations[i].salutation_desc,
            });
            if( this.salutation ==  obj.salutations[i].salutation_id ) {
              this.clntSal = obj.salutations[i].salutation_id
            }  
          }
          this.changeSalutation(this.clntSal);
        }
      });
  }
  changeSalutation(e) {
    if (e == "1" || e == "6")
      this.gender = "Male";
    else if (e == "2" || e == "3" || e == "4" || e == "7")
      this.gender = "Female";
    else if (e == "5")
      this.gender = "Transgender";
    else
      this.gender = "";
  }

public locationID;
  public clntLocation: string;
  public clntCity: string;
  public clntCityDesc: string;
  public clntState: string;
  public clntStateDesc: string;
  public clntCountry: string;
  public clntLocationList = [];
  public clntCityList = [];
  public clntStateList = [];
  public clntCountryList = [];
public clntCountryID;

public clntCountryDesc;
  getCountries(flag) {// Doctor Search
    this.clntCountryList = [];

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(this.countryUrl, { headers: headers }).subscribe(
      data => {
        this.clntCountryList = [];
        if (data.json().countries != null) {
          this.clntCountryList = data.json().countries;
          for (var c = 0; c < this.clntCountryList.length; c++) {
            if (this.clntCountryList[c].country_id == this.gservice.Client_login_detail_data.Country_ID) {
              this.clntCountry = this.clntCountryList[c].description;
              this.clntCountryID = this.clntCountryList[c].country_id;
              this.countryId = this.clntCountryList[c].country_id;
              this.getStates(this.clntCountryID, flag);
              break;
            }
          }
        }
      },error => {
        this.toastr.error(Message_data.unableToFetchData);
      });
  }

  countryChange(clnt_country, flag) {
    for (var c = 0; c < this.clntCountryList.length; c++) {
      if (this.clntCountryList[c].description == clnt_country) {
        this.clntCountryDesc = this.clntCountryList[c].description;
        this.clntCountryID = this.clntCountryList[c].country_id;
        this.countryId = this.clntCountryList[c].country_id;
        this.clntStateList = [];
        this.clntCityList = [];
        this.clntCity = undefined;
        this.clntLocation = "";
        this.getStates(this.clntCountryID, flag);
      }
    }
  }

  getStates(country, flag) {
    this.clntStateList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.stateUrl, JSON.stringify({ country_id: country }),
      { headers: headers }).subscribe(
        data => {
          if (data.json().states != null) {
            this.clntStateList = data.json().states;
            for (var i = 0; i < this.clntStateList.length; i++) {
              if (this.clntStateList[i].state_id == this.gservice.Client_login_detail_data.State_ID) {
                this.clntState = this.clntStateList[i].state_id;
                this.clntStateDesc = this.clntStateList[i].descrition;
                this.filtState = this.clntStateList[i].state_id;
                this.getCities(this.clntState, flag);
                break;
              }
            }
          }
        });
  }

  stateChange(clnt_state, flag) {
    for (var i = 0; i < this.clntStateList.length; i++) {
      if (this.clntStateList[i].state_id == clnt_state) {
        this.clntState = this.clntStateList[i].state_id;
        this.clntStateDesc = this.clntStateList[i].description;
        this.filtState = this.clntStateList[i].state_id;
        this.clntCityList = [];
        this.clntCity = undefined;
        this.clntLocation = "";
        this.getCities(this.clntState, flag);
      }
    }
  }

  getCities(state, flag) {
    this.clntCityList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.cityUrl, JSON.stringify({ state_id: state }),
      { headers: headers }).subscribe(
        data => {
          if (data.json().cities != null) {
            this.clntCityList = data.json().cities;
            for (var i = 0; i < this.clntCityList.length; i++) {
              if (this.clntCityList[i].district_id == this.gservice.Client_login_detail_data.City_ID) {
                this.clntCity = this.clntCityList[i].district_id;
                this.clntCityDesc = this.clntCityList[i].city_desc;
                this.filtCity = this.clntCityList[i].city_id;
                this.zipcode = this.clntCityList[i].zipcode;
                this.getLocations(this.clntCity);
                break;
              } else {
                this.clntCity = this.clntCityList[0].district_id;
              }
            }
          }
        });
  }

  cityChange(clnt_city, flag) {
    this.clntLocationList = [];
    for (var i = 0; i < this.clntCityList.length; i++) {
      if (this.clntCityList[i].district_id == clnt_city) {
        this.clntCity = this.clntCityList[i].district_id;
        this.clntCityDesc = this.clntCityList[0].city_desc;
        this.filtCity = this.clntCityList[i].city_id;
        this.zipcode = this.clntCityList[i].zipcode;
        this.clntLocation = "";
      }
    }
  }

  getLocations(city) {
    this.clntLocationList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.locationUrl, JSON.stringify({ city_id: city }),
      { headers: headers }).subscribe(
        data => {
          if (data.json().locations != null && data.json().locations.length != 0) {
            this.clntLocationList = data.json().locations;
            for (var i = 0; i < this.clntLocationList.length; i++) {
              if (this.clntLocationList[i].location_id == this.gservice.Client_login_detail_data.Location_ID) {
                this.locationID = this.clntLocationList[i].location_id;
                this.clntLocation = this.clntLocationList[i].description;
                this.clntLocationList = [];
                break;
              }
            }
          }
        });
  }

  locationChange(e) {
    this.clntLocationList = [];
    var locat_desc = e.target.value.toString();

    if (locat_desc != null && locat_desc.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.locationNameByUrl, JSON.stringify({
        name: locat_desc,
        city_id: this.clntCity,
      }), { headers: headers }).subscribe(
        data => {
          if (data.json().locations != null && data.json().locations.length != 0) {
            this.clntLocationList = data.json().locations;
          }
        },error => {
          this.toastr.error(Message_data.unableToFetchDocDet);
        });
    } else {
      this.clntLocationList = [];
    }
  }

  selectedLocation(location) {
    this.clntLocation = location;
    for (var i = 0; i < this.clntLocationList.length; i++) {
      if (this.clntLocationList[i].location_id == location.location_id) {
        this.clntLocation = this.clntLocationList[i].description;
        this.locationID = this.clntLocationList[i].location_id;
       
        
      }
    }
    this.clntLocationList = [];
  }
}
