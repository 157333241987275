<div class="row" style="margin: 0 auto;">
    <div class="Details"> 
      <mat-tab-group [disableRipple]=true mat-align-tabs="start" [selectedIndex]="0"
        (selectedTabChange)="selectTab($event)"> 
        <mat-tab label="outpatient">
          <ng-template mat-tab-label style="margin-left:10px !important;" (click)="selectTab('outpatient')">
            <img src="../../../assets/ui_icons/second_opinion/Pending_icon.svg" width="18px" height="auto" />
            &nbsp; {{displayType}}
          </ng-template>
          <mdb-card style="width: 99% !important;margin-left: 3px;">
            <mdb-card-body>
              <div class="row mb-3">
                <div class="col-12 col-sm-6 col-md-6  col-lg-2 col-xl-2" [hidden]="adminbased"
                  style="margin-top: 5px;width: auto;">
                  <mat-label class="matlabel" style="font-size: 15px; font-weight: 600;">View</mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2" [hidden]="adminbased">
                  <select disableOptionCentering class="ipcss widthappt" (change)="viewChange()" [(ngModel)]="view_type"
                    style="border: 1px solid black !important;">
                    <option value="graph">Graphical</option>
                    <option value="tabular">Tabular</option>
                  </select>
                </div>
                <div class="col-12 col-sm-6 col-md-6  col-lg-2 col-xl-2" style="margin-top: 5px;width: auto;">
                  <mat-label class="matlabel" style="font-size: 15px; font-weight: 600;">Duration</mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                  <select disableOptionCentering class="ipcss widthappt" (change)="Appointment_type_change()"
                    [(ngModel)]="appointment_type" style="border: 1px solid black !important;">
                    <option value="Today">Today</option>
                    <option value="Weekly">Weekly</option>
                    <option value="Fort Night">Fort Night</option>
                    <option value="Monthly">Monthly</option>
                    <option value="3 Months">3 Months</option>
                    <option value="6 Months">6 Months</option>
                    <option value="Yearly">Yearly</option>
                  </select>
                </div>
                <div class="col-12 col-sm-6 col-md-6  col-lg-2 col-xl-2" style="margin-top: 5px;width: auto;">
                  <mat-label class="matlabel" style="font-size: 15px; font-weight: 600;">From :</mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                  <input type="date" [(ngModel)]="from_date" class="ipcss widthappt" [disabled]="true">
                </div>
                <div class="col-12 col-sm-6 col-md-6  col-lg-2 col-xl-2" style="margin-top: 5px;width: auto;">
                  <mat-label class="matlabel" style="font-size: 15px; font-weight: 600;">To :</mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                  <input type="date" [(ngModel)]="to_date" class="ipcss widthappt" [disabled]="true">
                </div>
                <!-- <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3" style="margin-top: 5px;width: auto;"
                  *ngIf="is_admin">
                  <mat-label class="matlabel" style="font-size: 15px; font-weight: 600;">Doctor</mat-label>
                </div> -->
                <!-- <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3" *ngIf="is_admin">
                  <select required [(ngModel)]="doctorid" (change)="changeDoctor()" class="ipcss widthappt">
                    <option value="All">All</option>
                    <option *ngFor="let doctors of doctorList" value={{doctors.Doc_ID}}>{{doctors.Doc_Name}}
                    </option>
                  </select>
                </div> -->
              </div>
              <div class="row">
                <div class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
                  <mat-card id="card_tappt">
                    <mat-card-content>
                      <div class="row" style="position: relative;top: 1px;">
                        <div class="col-1">
                          <!-- <img src='../../../assets/ui_icons/total_appointments_logo.svg' width="20px" height="auto"
                            style="position: relative;left: 6px;" /> -->
                        </div>
                        <div class="col-2">
                        </div>
                        <div class="col-8 my-auto">
                          <mat-label class="matlabel">{{appointment_type}} apps:
                          </mat-label>
                          <mat-label class="matlabel">&nbsp;&nbsp;<b>{{totalapp}}</b></mat-label>
                        </div>
                      </div>
                    </mat-card-content>
                  </mat-card>
                </div>
                <div class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
                  <mat-card id="card_pendappt">
                    <mat-card-content>
                      <div class="row" style="position: relative;top: 1px;">
                        <div class="col-1">
                          <!-- <img src='../../../assets/ui_icons/Pending_appointments_logo.svg' width="20px" height="auto"
                            style="position: relative;left: 6px;" /> -->
                        </div>
                        <div class="col-1">
                        </div>
                        <div class="col-1">
                        </div>
                        <div class="col-8 my-auto">
                          <mat-label class="matlabel">Pending apps:</mat-label>
                          <mat-label class="matlabel">&nbsp;&nbsp;<b>{{pendingapp}}</b>
                          </mat-label>
                        </div>
                      </div>
                    </mat-card-content>
                  </mat-card>
                </div>
                <div class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
                  <mat-card id="card_newpatient">
                    <mat-card-content>
                      <div class="row" style="position: relative;top: 1px;">
                        <div class="col-1">
                          <!-- <img src='../../../assets/ui_icons/new_patients_logo.svg' width="20px" height="auto"
                            style="position: relative;left: 6px;" /> -->
                        </div>
                        <div class="col-2">
                        </div>
                        <div class="col-8 my-auto">
                          <mat-label class="matlabel">New patients:</mat-label>
                          <mat-label class="matlabel">&nbsp;&nbsp;<b>{{newpatientapp}}</b>
                          </mat-label>
                        </div>
                      </div>
                    </mat-card-content>
                  </mat-card>
                </div>
                <div class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
                  <mat-card id="card_newpatient">
                    <mat-card-content>
                      <div class="row" style="position: relative;top: 1px;">
                        <div class="col-1">
                          <!-- <img src='../../../assets/ui_icons/new_patients_logo.svg' width="20px" height="auto"
                            style="position: relative;left: 6px;" /> -->
                        </div>
                        <div class="col-2">
                        </div>
                        <div class="col-8 my-auto">
                          <mat-label class="matlabel">Follow patients:</mat-label>
                          <mat-label class="matlabel">&nbsp;&nbsp;<b>{{followApp}}</b>
                          </mat-label>
                        </div>
                      </div>
                    </mat-card-content>
                  </mat-card>
                </div>
              </div>
              <div class="row" *ngIf="!viewflag">
                <div class="row">
                  <div class="col-12 col-sm-12 col-md-6 col-lg-10 col-xl-10" [ngStyle]="getStyle()">
                    <div class="row align-items-center" style="width:100%;">
                      <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" style="margin: 10px 0;" *ngIf="is_admin">
                        <mat-card id="cardcontent" style="border: 1px solid red;">
                          <mat-card-title style="font-size: 14px;">
                            <img src='../../../assets/ui_icons/home_page_icon/location_icon.svg' width="20px"
                              height="auto" />
                            Hospital bills
                          </mat-card-title>
                          <mat-card-content style="background-color: #fff; position: relative; padding: 15px;"
                            [ngStyle]="">
                            <canvas id="hospitalbillCanvas"  style="display: block; "></canvas><!--width="361" height="175"-->
                          </mat-card-content>
                        </mat-card>
                      </div>
                      <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" style="margin: 10px 0;" *ngIf="is_admin">
                        <mat-card id="cardcontent" style="border: 1px solid red;">
                          <mat-card-title style="font-size: 14px;">
                            <img src='../../../assets/ui_icons/home_page_icon/location_icon.svg' width="20px"
                              height="auto" />
                            Diagnosis bills
                          </mat-card-title>
                          <mat-card-content style="background-color: #fff; position: relative; padding: 15px;"
                            [ngStyle]="">
                            <canvas id="diagnosisCanvas"  style="display: block; "></canvas><!--width="361" height="175"-->
                          </mat-card-content>
                        </mat-card>
                      </div>
                      <!-- <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" style="margin: 10px 0;" *ngIf="is_admin">
                        <mat-card id="cardcontent" style="border: 1px solid red;">
                          <mat-card-title style="font-size: 14px;">
                            <img src='../../../assets/ui_icons/home_page_icon/location_icon.svg' width="20px"
                              height="auto" />
                            Pharmacy bills
                          </mat-card-title>
                          <mat-card-content style="background-color: #fff; position: relative; padding: 15px;"
                            [ngStyle]="">
                            <canvas id="pharmacyCanvas" width="361" height="175" style="display: block; "></canvas>
                          </mat-card-content>
                        </mat-card>
                      </div> -->
  
                      <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" style="margin: 10px 0;">
                        <mat-card id="cardcontent" style="border: 1px solid red;">
                          <mat-card-title style="font-size: 14px;">
                            <img src='../../../assets/ui_icons/home_page_icon/location_icon.svg' width="20px"
                              height="auto" />
                            Location
                          </mat-card-title>
                          <mat-card-content style="background-color: #fff; position: relative; padding: 15px;"
                            [ngStyle]="getlocation()">
                            <canvas id="LocationCanvas" width="361" height="175" style="display: block; "></canvas>
                          </mat-card-content>
                        </mat-card>
                      </div>
                      <!-- <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" style="margin: 10px 0"
                        [ngStyle]="{'display':spl_display}">
                        <mat-card id="cardcontent" style="border: 1px solid red;">
                          <mat-card-title style="font-size: 14px;">
                            <img src='../../../assets/ui_icons/home_page_icon/specialization_icon.svg' width="18px"
                              height="auto" />
                            Specialization
                          </mat-card-title>
                          <mat-card-content style="background-color: #fff;position: relative; padding: 15px;"
                            [ngStyle]='getspecilization()'>
                            <canvas id="specialization_canvas" width="361" height="175" style="display: block;"></canvas>
                          </mat-card-content>
                        </mat-card>
                      </div> -->
                      <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" style="margin: 10px 0" *ngIf="!is_admin">
                        <mat-card id="cardcontent" style="border: 1px solid rgb(127, 155, 127);">
                          <mat-card-title style="font-size: 14px;">
                            <img src='../../../assets/ui_icons/home_page_icon/age_icon.svg' width="25px" height="auto" />
                            Age
                          </mat-card-title>
                          <mat-card-content style="background-color: #fff; padding: 15px;">
                            <canvas id="AgeCanvas" width="361" height="175" style="display: block;"></canvas>
                          </mat-card-content>
                        </mat-card>
                      </div>
                      <!-- <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" style="margin: 10px 0" *ngIf="is_admin">
                        <mat-card id="cardcontent" style="border: 1px solid rgb(127, 155, 127); ">
                          <mat-card-title style="font-size: 14px;">
                            <img src='../../../assets/ui_icons/home_page_icon/age_icon.svg' width="25px" height="auto" />
                            Doctor wise
                          </mat-card-title>
                          <mat-card-content style="background-color: #fff; padding: 15px;">
                            <canvas id="docCanvas" width="361" height="175" style="display: block;"></canvas>
                          </mat-card-content>
                        </mat-card>
                      </div> -->
  
                      <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" style="margin: 10px 0" > <!--*ngIf="is_admin"-->
                        <mat-card id="cardcontent" style="border: 1px solid black; ">
                          <mat-card-title style="font-size: 14px;">
                            <img src='../../../assets/ui_icons/home_page_icon/gender_icon.svg' width="25px"
                              height="auto" />
                            Gender
                          </mat-card-title>
                          <mat-card-content style="background-color: #fff; padding: 15px;">
                            <canvas id="piechart_gen" width="361" height="175" style="display: block;"></canvas>
                          </mat-card-content>
                        </mat-card>
                      </div>
  
                      <!-- <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" style="margin: 10px 0" *ngIf="is_admin">
                        <mat-card id="cardcontent" style="border: 1px solid black;">
                          <mat-card-title style="font-size: 14px;">
                            <img src='../../../assets/ui_icons/home_page_icon/gender_icon.svg' width="25px"
                              height="auto" />
                            Patients
                          </mat-card-title>
                          <mat-card-content style="background-color: #fff; position:relative; padding: 15px;">
                            <canvas id="piechart_patients" width="361" height="175" style="display: block;"></canvas>
  
                          </mat-card-content>
                        </mat-card>
                      </div> -->
                    </div>
                  </div>
                  <div class="col-12 col-sm-12 col-md-6 col-lg-2 col-xl-2"
                    style="width:25%;padding:1%;position: relative;left: 0px">
                    <div>
                      <div *ngIf="is_admin" style="border-radius:5px;padding:5px;border:1px solid gainsboro;width: 110%">
                        <p style="font-weight:bold">Gender</p>
                        <div class="row">
                          <div class="col-xl-12 text-center"
                            style="display: flex;flex-direction: column-start-nowarp;justify-content: space-evenly;">
                            <div style="border-radius: 5px;border:1px solid #97d0f1 !important;margin:5px;width: 100px;
                            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;">
                              <p *ngIf="Malecount == undefined">0</p>
                              <P>{{Malecount}}</P>
                              <p>Male</p>
                            </div>
  
                            <div style="border-radius: 5px;border:1px solid #97d0f1 !important;margin:5px;width: 100px;position: relative;right:7px;
                            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;">
                              <p *ngIf="Femalecount == undefined">0</p>
                              <P>{{Femalecount}}</P>
                              <p>Female</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div style="padding:0" *ngIf="is_admin">
                        <div class="col-xl-12" style="border-radius: 5px; border: 1px solid #97d0f1 !important; height: 150px !important;box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; margin-top: 15px; padding: 20px; width: 110%"
                          (click)="hospitalbill('HBR')">
                          <span style="font-weight: bold">Hospital Bills</span>
                          <div class="billsamount" style="clear: inline-end;" *ngIf="hospital_bills != undefined">
                            <p>Amount</p>
                            <p>{{ hospital_bills }}</p>
                          </div>
                          <div class="billsamount" style="clear: inline-end;" *ngIf="hospital_bills == undefined">
                            <p>Amount</p>
                            <p>0</p>
                          </div>
                          <div class="billsamount" style="clear: inline-end;" *ngIf="hospital_bill_count != undefined">
                            <p>Bills</p>
                            <p>{{ hospital_bill_count }}</p>
                          </div>
                          <div class="billsamount" style="clear: inline-end;" *ngIf="hospital_bill_count == undefined">
                            <p>Bills</p>
                            <p>0</p>
                          </div>
                        </div>
                        <!-- <div class="col-xl-12" style="border-radius: 5px;border:1px solid #97d0f1 !important;height: 150px !important;      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;margin-top: 15px;padding:20px;width: 110%"
                          (click)="pharmacybill('OTC')">
                          <span style="font-weight:bold">Pharmacy Bills</span>
                          <div class="billsamount" *ngIf="pharma_bills != undefined" style="clear: inline-end;">
                            <p>Amount </p>
                            <p>{{pharma_bills}}</p>
                          </div>
                          <div class="billsamount" *ngIf="pharma_bills == undefined" style="clear: inline-end;">
                            <p>Amount </p>
                            <p>0</p>
                          </div>
                          <div class="billsamount" *ngIf="pharma_bill_count != undefined" style="clear: inline-end;">
                            <p>Bills </p>
                            <p>{{pharma_bill_count}}</p>
                          </div>
                          <div class="billsamount" *ngIf="pharma_bill_count == undefined" style="clear: inline-end;">
                            <p>Bills</p>
                            <p>0</p>
                          </div>
                        </div> -->
                        <div class="col-xl-12" style="border-radius: 5px;border:1px solid #97d0f1 !important;height: 150px !important;        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;margin-top: 15px;padding:20px;width: 110%"
                          (click)="diagnosisbill('DBR')">
                          <span style="font-weight:bold">Diagnosis Bills</span>
                          <div class="billsamount" style="clear: inline-end;" *ngIf="diagnosis_bills != undefined">
                            <p>Amount </p>
                            <p>{{diagnosis_bills}}</p>
                          </div>
                          <div class="billsamount" style="clear: inline-end;" *ngIf="diagnosis_bills == undefined">
                            <p>Amount </p>
                            <p>0</p>
                          </div>
                          <div class="billsamount" style="clear: inline-end;" *ngIf="diagnosis_bill_count != undefined">
                            <p>Bills </p>
                            <p>{{diagnosis_bill_count}}</p>
                          </div>
                          <div class="billsamount" style="clear: inline-end;" *ngIf="diagnosis_bill_count == undefined">
                            <p>Bills </p>
                            <p>0</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="viewflag">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="margin: 10px 0 0 0px;">
                  <p class="nodata" *ngIf="appointmentList.length == 0">No Appointments(s) Found</p>
                  <table *ngIf="appointmentList.length != 0" mdbTable datatable [dtOptions]="dtOptions"
                    class="table table-nowrap table-sm dataTable billlisttable">
                    <thead>
                      <tr>
                        <th>Profile image</th>
                        <th>Patient id</th>
                        <th>Name</th>
                        <th>Token/Time</th>
                        <th>Contact no</th>
                        <th>Session</th>
                        <th>Status </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let person of appointmentList; let i = index">
                        <td><img src="{{person.profile_image}}" onerror="this.src='../../../assets/img/default.jpg';"
                            class="img-fluid z-depth-1 rounded-circle mr-1"></td>
                        <td style="font-size: 12px;">{{ person.patient_id }}</td>
                        <td style="font-size: 12px;text-align: left;">{{ person.patient_name }}</td>
                        <td style="font-size: 12px;">{{ person.token }}</td>
                        <td style="font-size: 12px;">{{ person.contact }}</td>
                        <td style="font-size: 12px;">{{ person.session }}</td>
                        <td style="font-size: 12px;">
                          <div style="position: relative;top: 9px;">
                            <img *ngIf="person.status_txt == 'Completed'" width="30px" height="auto"
                              src="../../../assets/ui_icons/Completed_icon.svg" />
                            <img *ngIf="person.status_txt == 'Cancel'" width="30px" height="auto"
                              src="../../../assets/ui_icons/cancel_icon.svg" />
                            <img *ngIf="person.status_txt == 'Not visited'" width="30px" height="auto"
                              src="../../../assets/ui_icons/not_visited_icon.svg" />
                            <img *ngIf="person.status_txt == 'Confirmed'" width="30px" height="auto"
                              src="../../../assets/ui_icons/confirmed_icon.svg" />
                            <img *ngIf="person.status_txt == 'Open'" width="30px" height="32px"
                              src="../../../assets/ui_icons/opened_icon.svg" />
                            <p>{{person.status_txt}}</p>
                          </div>
                      </tr>
                    </tbody>
                  </table>
                  <br><br><br>
                </div>
              </div>
            </mdb-card-body>
          </mdb-card>
        </mat-tab>
        
        

        <mat-tab label="inpatient" *ngIf="type == 'doctor'">
          <ng-template mat-tab-label (click)="selectTab('inpatient')">
            <img src="../../../assets/ui_icons/second_opinion/Completed_icon.svg" width="20px" height="auto" />
            &nbsp; Inpatient
          </ng-template>
          <mdb-card style="width: 99% !important;margin-left: 3px;">
            <mdb-card-body>
              <div class="row mb-3">
                <div class="col-12 col-sm-6 col-md-6  col-lg-2 col-xl-2" [hidden]="adminbased"
                  style="margin-top: 5px;width: auto;">
                  <mat-label class="matlabel" style="font-size: 15px; font-weight: 600;">View</mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2" [hidden]="adminbased">
                  <select disableOptionCentering class="ipcss widthappt" (change)="viewChange()" [(ngModel)]="view_type"
                    style="border: 1px solid black !important;">
                    <option value="graph">Graphical</option>
                    <option value="tabular">Tabular</option>
                  </select>
                </div>
                <div class="col-12 col-sm-6 col-md-6  col-lg-2 col-xl-2" style="margin-top: 5px;width: auto;">
                  <mat-label class="matlabel" style="font-size: 15px; font-weight: 600;">Duration</mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                  <select disableOptionCentering class="ipcss widthappt" (change)="Appointment_type_change()"
                    [(ngModel)]="appointment_type" style="border: 1px solid black !important;">
                    <option value="Today">Today</option>
                    <option value="Weekly">Weekly</option>
                    <option value="Fort Night">Fort Night</option>
                    <option value="Monthly">Monthly</option>
                    <option value="3 Months">3 Months</option>
                    <option value="6 Months">6 Months</option>
                    <option value="Yearly">Yearly</option>
                  </select>
                </div>
                <div class="col-12 col-sm-6 col-md-6  col-lg-2 col-xl-2" style="margin-top: 5px;width: auto;">
                  <mat-label class="matlabel" style="font-size: 15px; font-weight: 600;">From :</mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                  <input type="date" [(ngModel)]="from_date" class="ipcss widthappt" [disabled]="true">
                </div>
                <div class="col-12 col-sm-6 col-md-6  col-lg-2 col-xl-2" style="margin-top: 5px;width: auto;">
                  <mat-label class="matlabel" style="font-size: 15px; font-weight: 600;">To :</mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                  <input type="date" [(ngModel)]="to_date" class="ipcss widthappt" [disabled]="true">
                </div>
                <!-- <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3" style="margin-top: 5px;width: auto;"
                  *ngIf="is_admin">
                  <mat-label class="matlabel" style="font-size: 15px; font-weight: 600;">Doctor</mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3" *ngIf="is_admin">
                  <select required [(ngModel)]="doctorid" (change)="changeDoctor()" class="ipcss widthappt">
                    <option value="All">All</option>
                    <option *ngFor="let doctors of doctorList" value={{doctors.Doc_ID}}>{{doctors.Doc_Name}}
                    </option>
                  </select>
                </div> -->
              </div>
              <div class="row">
                <div class="row">
                  <div [hidden]="graphchart" class="col-12 col-sm-12 col-md-6 col-lg-10 col-xl-10"
                  [ngStyle]="getStyle()">
                    <div class="row align-items-center" style="width:100%;">
                      <!-- <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                        <mat-card id="cardcontent" style="border: 1px solid red;">
                          <mat-card-title style="font-size: 14px;">
                            <img src='../../../assets/ui_icons/home_page_icon/specialization_icon.svg' width="18px"
                              height="auto" />
                            Specialization
                          </mat-card-title>
                          <mat-card-content style="background-color: #fff;">
                            <div>
                              <div id="inPatientGraphCont">
                                <canvas id="InpatientSpecializationCanvas" width="361" height="175" style="display: block;"
                                  (click)="inSpalData($event)"></canvas>
                              </div>
                            </div>
                          </mat-card-content>
                        </mat-card>
                      </div> -->
                      <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" style="margin: 10px 0;" *ngIf="is_admin">
                        <mat-card id="cardcontent" style="border: 1px solid red;">
                          <mat-card-title style="font-size: 14px;">
                            <img src='../../../assets/ui_icons/home_page_icon/location_icon.svg' width="20px"
                              height="auto" />
                            Hospital bills
                          </mat-card-title>
                          <mat-card-content style="background-color: #fff; position: relative; padding: 15px;"
                            [ngStyle]="">
                            <canvas id="inpathospitalbillCanvas"  style="display: block; "></canvas><!--width="361" height="175"-->
                          </mat-card-content>
                        </mat-card>
                      </div>
                      <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" style="margin: 10px 0;" *ngIf="is_admin">
                        <mat-card id="cardcontent" style="border: 1px solid red;">
                          <mat-card-title style="font-size: 14px;">
                            <img src='../../../assets/ui_icons/home_page_icon/location_icon.svg' width="20px"
                              height="auto" />
                            Diagnosis bills
                          </mat-card-title>
                          <mat-card-content style="background-color: #fff; position: relative; padding: 15px;"
                            [ngStyle]="">
                            <canvas id="inpatdiagnosisCanvas" style="display: block; "></canvas><!-- width="361" height="175"-->
                          </mat-card-content>
                        </mat-card>
                      </div>
                      <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <mat-card id="cardcontent" style="border: 1px solid red;">
                          <mat-card-title style="font-size: 14px;">
                            <img src='../../../assets/ui_icons/home_page_icon/ward_icon.svg' width="18px" height="auto" />
                            Ward
                          </mat-card-title>
                          <mat-card-content style="background-color: #fff;">
                            <canvas id="WardCanvas" (click)="inWardData($event)" 
                              style="display: block;"></canvas> <!--width="361" height="175"-->
                          </mat-card-content>
                        </mat-card>
                      </div>
                      <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <mat-card id="cardcontent" style="border: 1px solid red;">
                          <mat-card-title style="font-size: 14px;">
                            <img src='../../../assets/ui_icons/home_page_icon/ward_icon.svg' width="18px" height="auto" />
                            Occupation ratio
                          </mat-card-title>
                          <mat-card-content style="background-color: #fff;">
                            <canvas id="bedratioCanvas" width="361" height="177" style="display: block;"></canvas>
                          </mat-card-content>
                        </mat-card>
                      </div>
                      <!-- <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style="margin: 10px 0">
                        <div class="tableCover" *ngIf="!inSplFlag" style="height:100%;">
                          <div class="listHeading">
                            <div class="headingText">
                              Specialization based patient list
                            </div>
                            <div class="headingClose">
                              <img src="../../../assets/img/close_icon.png" (click)="closeList('Specialization')"
                                class="closeButton" alt="">
                            </div>
                          </div>
                          <div style="    padding: 10px 10px 10px 10px;">
                            <table style="width: 100%; " *ngIf="inSpalDataArray.length != 0" mdbTable datatable
                              [dtOptions]="dtOptions" class="table table-nowrap table-sm dataTable billlisttable">
                              <thead>
                                <tr>
                                  <th>Image</th>
                                  <th>Name</th>
                                  <th>Bed no</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let person of inSpalDataArray; let i = index">
                                  <td><img src="{{person.profile_image}}"
                                      onerror="this.src='../../../assets/img/default.jpg';" height="40px" width="40px"
                                      class="img-fluid z-depth-1 rounded-circle mr-1"></td>
                                  <td style="font-size: 12px;text-align: left;">{{person.name}} </td>
                                  <td style="font-size: 12px;text-align: right;padding-right: 8%;">{{person.bed_no}}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>   
                        </div>
                      </div>
                      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style="margin: 10px 0">
                        <div class="tableCover" *ngIf="!inWardFlag">
                          <div class="listHeading">
                            <div class="headingText">
                              Ward based patient list
                            </div>
                            <div class="headingClose">
                              <img src="../../../assets/img/close_icon.png" (click)="closeList('ward')" class="closeButton"
                                alt="">
                            </div>
                          </div>
                          <div style="padding: 10px 10px 10px 10px;">
                            <div class="matlabel" style="display: inline-block;">
                              Total beds count : {{totalBeds}}
                            </div>
                            <div class="matlabel" style="display: inline-block; margin-left: 20px;">
                              Vacant beds : {{availableBeds}}
                            </div>
                            <table style="width: 100%; margin: 0 auto;" *ngIf="inWardDataArray.length != 0" mdbTable
                              datatable [dtOptions]="dtOptions" class="table table-nowrap table-sm dataTable billlisttable">
                              <thead>
                                <tr>
                                  <th>Image</th>
                                  <th>Name</th>
                                  <th>Bed no</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let person of inWardDataArray; let i = index">
                                  <td><img src="{{person.profile_image}}"
                                      onerror="this.src='../../../assets/img/default.jpg';" height="40px" width="40px"
                                      class="img-fluid z-depth-1 rounded-circle mr-1"></td>
                                  <td style="font-size: 12px;text-align: left;">{{person.name}} </td>
                                  <td style="font-size: 12px;text-align: right;padding-right: 8%;">{{person.bed_no}}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div> -->
                    </div>
                  </div>
                  <div class="col-12 col-sm-12 col-md-6 col-lg-2 col-xl-2" style="width:25%;padding:1%;position: relative;left: 0px" *ngIf="is_admin">
                    <div>
                      <div *ngIf="is_admin" style="border-radius: 5px;border:1px solid #97d0f1 !important;
                        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;padding:20px;margin-bottom: 10px;">
                        <p style="font-weight:bold">No.of.Patients</p>
                        <div class="row">
                          <div class="col-xl-12 text-center"
                            style="display: flex;flex-direction: column-start-nowarp;justify-content: space-evenly;">
                            <div  style="border-radius: 5px;border:1px solid #97d0f1 !important;margin:5px;width: 100px;
                                  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;">
                              <h2 class="title">{{noofinpat}}</h2>
                            </div>
                          </div>
                        </div>
                      </div> 
                      <div style="padding:0" *ngIf="is_admin">
                        <div class="col-xl-12"
                          style="border-radius: 5px;border:1px solid #97d0f1 !important;
                          box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;padding:20px;margin-bottom: 10px;" (click)="hospitalbill('HBR')">
                          <span style="font-weight:bold"> Hospital Bills </span>
                          <div class="billsamount" *ngIf="inpathospital_bills != undefined">
                            <p>Amount </p>
                            <p>{{inpathospital_bills}}</p>
                          </div>
                          <div class="billsamount" *ngIf="inpathospital_bills == undefined">
                            <p>Amount </p>
                            <p>0</p>
                          </div>
                          <div class="billsamount" *ngIf="inpathospital_bills_count == undefined">
                            <p>Bills </p>
                            <p>0</p>
                          </div>
                          <div class="billsamount" *ngIf="inpathospital_bills_count != undefined">
                            <p>Bills </p>
                            <p>{{inpathospital_bills_count}}</p>
                          </div>
                        </div>
                        <div class="col-xl-12" style="border-radius: 5px;border:1px solid #97d0f1 !important;
                            box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;padding:20px;
                            " (click)="diagnosisbill('DBR')">
                          <span style="font-weight:bold">Diagnosis Bills</span>
                          <div class="billsamount" *ngIf="inpatdiagnosis_bills != undefined">
                            <p>Amount </p>
                            <p>{{inpatdiagnosis_bills}}</p>
                          </div>
                          <div class="billsamount" *ngIf="inpatdiagnosis_bills == undefined">
                            <p>Amount </p>
                            <p>0</p>
                          </div>
                          <div class="billsamount" *ngIf="inpatdiagnosis_bills_count == undefined">
                            <p>Bills </p>
                            <p>0</p>
                          </div>
                          <div class="billsamount" *ngIf="inpatdiagnosis_bills_count != undefined">
                            <p>Bills </p>
                            <p>{{inpatdiagnosis_bills_count}}</p>
                          </div>
                        </div>
                      </div>                    
                    </div>   
                                   
                   
                    <!-- <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-3 bill-card"
                      style="border-radius: 5px;border:1px solid #97d0f1 !important;
                      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;padding:20px;" (click)="pharmacybill('OTC')">
                      <span style="font-weight:bold">Pharmacy Bills</span>
                      <div class="billsamount" *ngIf="inpatpharma_bills != undefined">
                        <p>Amount </p>
                        <p>{{inpatpharma_bills}}</p>
                      </div>
                      <div class="billsamount" *ngIf="inpatpharma_bills == undefined">
                        <p>Amount </p>
                        <p>0</p>
                      </div>
                      <div class="billsamount" *ngIf="inpatpharma_bills_count == undefined">
                        <p>Bills </p>
                        <p>0</p>
                      </div>
                      <div class="billsamount" *ngIf="inpatpharma_bills_count != undefined">
                        <p>Bills </p>
                        <p>{{inpatpharma_bills_count}}</p>
                      </div>
                    </div> -->                    
                  </div>
                </div>
              </div>
              
              <div class="row justify-content-center" [hidden]="inpattabular">
                <div class="col-6 col-lg-6 col-md-5 col-xl-5 d-flex flex-wrap">
                  <div class="table-responsive">
                    <table>
                      <thead>
                        <tr>
                          <th>Speciality Id</th>
                          <th>Speciality name</th>
                          <th>Inpatient</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let special of specialityarray">
                          <td>{{special.specialid}}</td>
                          <td>{{special.specialname}}</td>
                          <td>{{special.specialpat}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="col-6 col-lg-6 col-md-7 col-xl-7 d-flex flex-wrap">
                  <div class="table-responsive">
                    <table>
                      <thead>
                        <tr>
                          <th>Ward Id</th>
                          <th>Ward name</th>
                          <th>Patient Count</th>
                          <th>Bed Count</th>
                          <th>Bed Occupancy Ratio</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let ward of wardarray">
                          <td>{{ward.wardid}}</td>
                          <td>{{ward.wardname}}</td>
                          <td>{{ward.patcount}}</td>
                          <td>{{ward.bed_count}}</td>
                          <td>{{ward.bed_occupt}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </mdb-card-body>
          </mdb-card>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
