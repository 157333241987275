import { Component, OnInit, ElementRef, Input, Inject, ViewEncapsulation, HostListener, ViewChild } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from 'src/app/helper_class';
import { Doc_Helper } from '../Doc_Helper';
import { Date_Formate } from 'src/assets/js/common';
import { ipaddress } from '../../ipaddress' ;
@Component({
  selector: 'app-med-pres-popup-page',
  templateUrl: './med-pres-popup-page.component.html',
  styleUrls: ['./med-pres-popup-page.component.scss']
})
export class MedPresPopupPageComponent implements OnInit {
  public element: any;
  public docid: string;
  public symptoms;
  public splid: string;
  public client_id: string;
  public relation_id: string;
  public sub_rel_id: string;
  public age: string;
  public every_six: string;
  public dure_write: string;
  public afterfood_txt: string;
  public generic;
  public dure_flag: boolean;
  public saveflag: boolean;
  public param_four:boolean;

  public listProducts = [];
  public selectedList = [];
  public getvalue;
  public userinfo;
  dtOptions: DataTables.Settings = {};
  public intake_txt: string;
  public nodataapp: boolean;
  public clientarray=[];
  public send_data;
  public short_form;
  constructor(public http: Http, public dialogRef: MatDialogRef<MedPresPopupPageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public el: ElementRef, public toastr: ToastrService) {
      dialogRef.disableClose = true;
    this.element = el.nativeElement;
    this.userinfo = Helper_Class.getInfo();
    this.saveflag = true;
  }
  
  ngOnInit() {
    this.clientarray = Doc_Helper.getClient_Info();
    if(Doc_Helper.getClient_Info() != undefined){
      if(Doc_Helper.getClient_Info().AppFlow != undefined){
        if(Doc_Helper.getClient_Info().AppFlow == "InpatMedPres_dis" || Doc_Helper.getClient_Info().AppFlow == "Inpatient"){
          this.getvalue = Doc_Helper.getClient_Info();
          this.docid = this.getvalue.doc_reg_id;
          this.splid = this.getvalue.spl;
          this.age = this.getvalue.Age_data;
          this.client_id = Doc_Helper.getClient_Info().Client_id
          this.relation_id = Doc_Helper.getClient_Info().rel_id;
        
          if (this.getvalue.sub_id != undefined && this.getvalue.sub_id != null && this.getvalue.sub_id != '') {
            this.sub_rel_id = this.getvalue.sub_id;
          }
        }else{
          this.getvalue = Doc_Helper.getClient_Info();
          this.docid = this.userinfo.user_id;
          this.splid = this.getvalue.spl;
          this.age = this.getvalue.client_age;
          this.client_id = Doc_Helper.getClient_Info().Client_id
          this.relation_id = Doc_Helper.getClient_Info().rel_id;
          
          if (this.getvalue.sub_id != undefined && this.getvalue.sub_id != null && this.getvalue.sub_id != '') {
            this.sub_rel_id = this.getvalue.sub_id;
          }
        }
      }

    }else{
      this.getvalue = Doc_Helper.getClient_Info();
      this.docid = this.userinfo.user_id;
      this.splid = this.getvalue.spl;
      this.age = this.getvalue.client_age;
      this.client_id = Doc_Helper.getClient_Info().Client_id
      this.relation_id = Doc_Helper.getClient_Info().rel_id;
      
      if (this.getvalue.sub_id != undefined && this.getvalue.sub_id != null && this.getvalue.sub_id != '') {
        this.sub_rel_id = this.getvalue.sub_id;
      }
    }
  
    console.log("chekc dghfyf"+JSON.stringify(this.clientarray))
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 25,
      "lengthMenu": [[25, 50, -1], [25, 50, "All"]],
      "columnDefs": [
        { "orderable": false, "targets": [0, 3, 5,6] },
        { "targets": [0, 2, 3, 4, 5], "searchable": false }
      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10">>'
    };

   
    var agerange;
    if (this.age == "<1") {
      agerange = "<1";
    } else if (this.age == "1" || this.age == "2" || this.age == "3") {
      agerange = "between 1 and 3 ";
    } else if (this.age == "4" || this.age == "5") {
      agerange = "between 4 and 5 ";
    } else if (this.age == "6" || this.age == "7") {
      agerange = "between 6 and 7 ";
    } else if (this.age == "8" || this.age == "9" || this.age == "10") {
      agerange = "between 8 and 10 ";
    } else if (this.age == "11" || this.age == "12" || this.age == "13") {
      agerange = "between 11 and 13 ";
    } else if (this.age == "14" || this.age == "15") {
      agerange = "between 14 and 15 ";
    } else if (this.age == "16" || this.age == "17" || this.age == "18") {
      agerange = "between 16 and 18 ";
    } else if (this.age == "19" || this.age == "20" || this.age == "21") {
      agerange = "between 19 and 21 ";
    } else {
      agerange = "between 21 and 120 ";
    }

   
    if(this.sub_rel_id != undefined){
      if(this.getvalue.symptoms != undefined){
        this.symptoms=this.getvalue.symptoms
      }
      this.send_data = {

        doc_reg_id: this.docid,
        spl_id: this.splid,
        client_reg_id: this.client_id,
        relation_id: this.relation_id,
        sub_rel_id: this.sub_rel_id,
        agerange: agerange,
        symptoms: this.symptoms,
      }
    }else{
      if(this.getvalue.symptoms != undefined){
        this.symptoms=this.getvalue.symptoms
      }
      this.send_data = {

        doc_reg_id: this.docid,
        spl_id: this.splid,
        client_reg_id: this.client_id,
        relation_id: this.relation_id,
        // sub_rel_id: this.sub_rel_id,
        agerange: agerange,
        symptoms: this.symptoms,
      }
    }
    console.log("check data "+JSON.stringify(this.send_data))
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'prescription/gmpbydoc/', this.send_data,
      { headers: headers })
      .subscribe(
        response => {
          console.log("check data "+JSON.stringify(response))
          var obj = response.json();
          
          if (obj.prescriptions != null) {
            var rowcount = 1;
            for (var j = 0; j < obj.prescriptions.length; j++) {
              var drugs = obj.prescriptions[j].drug_list;
              var medicines = [];
              if(drugs != undefined){
                for (var i = 0; i < drugs.length; i++) {
                  var med_format = null;
                  var morning = " ", afternoon = " ", evening = " ", night = " ";
                  var shortform = obj.prescriptions[j].drug_list[i].short_form;
                  this.short_form= obj.prescriptions[j].drug_list[i].short_form;
                  // var short_form = obj.prescriptions[j].drug_list[i].short_form;
                  if(this.short_form != undefined){
                    this.short_form= obj.prescriptions[j].drug_list[i].short_form;
                  }else{
                    this.short_form= " ";
                  }
                  if (obj.prescriptions[j].drug_list[i].show_short_form == "1") {
                   
                    var morning = " ", afternoon = " ", evening = " ", night = " "
                    if (obj.prescriptions[j].drug_list[i].intake == "1") {
                      this.param_four = false;
                      if (obj.prescriptions[j].drug_list[i].morning !== "0") {
                        morning = obj.prescriptions[j].drug_list[i].morning + " " + this.short_form + " - ";
                      } else {
                        morning = obj.prescriptions[j].drug_list[i].morning + " - ";
                      }
                      if (obj.prescriptions[j].drug_list[i].afternoon !== "0") {
                        afternoon = obj.prescriptions[j].drug_list[i].afternoon + " " + this.short_form + " - ";
                      } else {
                        afternoon = obj.prescriptions[j].drug_list[i].afternoon + " - ";
                      }
                      if (obj.prescriptions[j].drug_list[i].evening !== "0") {
                        evening = obj.prescriptions[j].drug_list[i].evening + " " + this.short_form + " - ";
                      } else {
                        evening = obj.prescriptions[j].drug_list[i].evening + " - ";
                      }
                      if (obj.prescriptions[j].drug_list[i].night !== "0") {
                        night = obj.prescriptions[j].drug_list[i].night + " " + this.short_form;
                      } else {
                        night = obj.prescriptions[j].drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night;
                      // this.every_six =  obj.prescriptions[j].drug_list[i].morning + shortform  + " - " + obj.prescriptions[j].drug_list[i].afternoon+ " - "  + obj.prescriptions[j].drug_list[i].evening  + " - 0";
                    } else if (obj.prescriptions[j].drug_list[i].intake == "2") {
                      this.param_four = false;
                      if (obj.prescriptions[j].drug_list[i].morning !== "0") {
                        morning = obj.prescriptions[j].drug_list[i].morning + " " + this.short_form + " - ";
                      } else {
                        morning = obj.prescriptions[j].drug_list[i].morning + " - ";
                      }
                      if (obj.prescriptions[j].drug_list[i].afternoon !== "0") {
                        afternoon = obj.prescriptions[j].drug_list[i].afternoon + " " + this.short_form + " - ";
                      } else {
                        afternoon = obj.prescriptions[j].drug_list[i].afternoon + " - ";
                      }
                      if (obj.prescriptions[j].drug_list[i].evening !== "0") {
                        evening = obj.prescriptions[j].drug_list[i].evening + " " + this.short_form + " - ";
                      } else {
                        evening = obj.prescriptions[j].drug_list[i].evening + " - ";
                      }
                      if (obj.prescriptions[j].drug_list[i].night !== "0") {
                        night = obj.prescriptions[j].drug_list[i].night + " " + this.short_form;
                      } else {
                        night = obj.prescriptions[j].drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night;
                    } else if (obj.prescriptions[j].drug_list[i].intake == "3") {
                      this.param_four = false;
                      this.every_six = obj.prescriptions[j].drug_list[i].morning + " - " + obj.prescriptions[j].drug_list[i].afternoon + " - " + obj.prescriptions[j].drug_list[i].evening + " - " + obj.prescriptions[j].drug_list[i].night + shortform;

                      // this.every_six = "0 - 0 - "+ obj.prescriptions[j].drug_list[i].evening + shortform  + obj.prescriptions[j].drug_list[i].night + shortform;

                    } else if (obj.prescriptions[j].drug_list[i].intake == "4") {
                      if (obj.prescriptions[j].drug_list[i].morning !== "0") {
                        morning = obj.prescriptions[j].drug_list[i].morning + " " + this.short_form + " - ";
                      } else {
                        morning = obj.prescriptions[j].drug_list[i].morning + " - ";
                      }
                      if (obj.prescriptions[j].drug_list[i].afternoon !== "0") {
                        afternoon = obj.prescriptions[j].drug_list[i].afternoon + " " + this.short_form + " - ";
                      } else {
                        afternoon = obj.prescriptions[j].drug_list[i].afternoon + " - ";
                      }
                      if (obj.prescriptions[j].drug_list[i].evening !== "0") {
                        evening = obj.prescriptions[j].drug_list[i].evening + " " + this.short_form + " - ";
                      } else {
                        evening = obj.prescriptions[j].drug_list[i].evening + " - ";
                      }
                      if (obj.prescriptions[j].drug_list[i].night !== "0") {
                        night = obj.prescriptions[j].drug_list[i].night + " " + this.short_form;
                      } else {
                        night = obj.prescriptions[j].drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night; this.param_four = false;

                    } else if (obj.prescriptions[j].drug_list[i].intake == "5") {
                      if (obj.prescriptions[j].drug_list[i].morning !== "0") {
                        morning = obj.prescriptions[j].drug_list[i].morning + " " + this.short_form + " - ";
                      } else {
                        morning = obj.prescriptions[j].drug_list[i].morning + " - ";
                      }
                      if (obj.prescriptions[j].drug_list[i].afternoon !== "0") {
                        afternoon = obj.prescriptions[j].drug_list[i].afternoon + " " + this.short_form + " - ";
                      } else {
                        afternoon = obj.prescriptions[j].drug_list[i].afternoon + " - ";
                      }
                      if (obj.prescriptions[j].drug_list[i].evening !== "0") {
                        evening = obj.prescriptions[j].drug_list[i].evening + " " + this.short_form + " - ";
                      } else {
                        evening = obj.prescriptions[j].drug_list[i].evening + " - ";
                      }
                      if (obj.prescriptions[j].drug_list[i].night !== "0") {
                        night = obj.prescriptions[j].drug_list[i].night + " " + this.short_form;
                      } else {
                        night = obj.prescriptions[j].drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night;
                      this.param_four = false;

                    } else if (obj.prescriptions[j].drug_list[i].intake == "6") {
                      if (obj.prescriptions[j].drug_list[i].morning !== "0") {
                        morning = obj.prescriptions[j].drug_list[i].morning + " " + this.short_form + " - ";
                      } else {
                        morning = obj.prescriptions[j].drug_list[i].morning + " - ";
                      }
                      if (obj.prescriptions[j].drug_list[i].afternoon !== "0") {
                        afternoon = obj.prescriptions[j].drug_list[i].afternoon + " " + this.short_form + " - ";
                      } else {
                        afternoon = obj.prescriptions[j].drug_list[i].afternoon + " - ";
                      }
                      if (obj.prescriptions[j].drug_list[i].evening !== "0") {
                        evening = obj.prescriptions[j].drug_list[i].evening + " " + this.short_form + " - ";
                      } else {
                        evening = obj.prescriptions[j].drug_list[i].evening + " - ";
                      }
                      if (obj.prescriptions[j].drug_list[i].night !== "0") {
                        night = obj.prescriptions[j].drug_list[i].night + " " + this.short_form;
                      } else {
                        night = obj.prescriptions[j].drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night; this.param_four = false;

                    } else if (obj.prescriptions[j].drug_list[i].intake == "7") {
                      this.param_four = false;
                      if (obj.prescriptions[j].drug_list[i].morning !== "0") {
                        morning = obj.prescriptions[j].drug_list[i].morning + " " + this.short_form + " - ";
                      } else {
                        morning = obj.prescriptions[j].drug_list[i].morning + " - ";
                      }
                      if (obj.prescriptions[j].drug_list[i].afternoon !== "0") {
                        afternoon = obj.prescriptions[j].drug_list[i].afternoon + " " + this.short_form + " - ";
                      } else {
                        afternoon = obj.prescriptions[j].drug_list[i].afternoon + " - ";
                      }
                      if (obj.prescriptions[j].drug_list[i].evening !== "0") {
                        evening = obj.prescriptions[j].drug_list[i].evening + " " + this.short_form + " - ";
                      } else {
                        evening = obj.prescriptions[j].drug_list[i].evening + " - ";
                      }
                      if (obj.prescriptions[j].drug_list[i].night !== "0") {
                        night = obj.prescriptions[j].drug_list[i].night + " " + this.short_form;
                      } else {
                        night = obj.prescriptions[j].drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night;
                    } else if (obj.prescriptions[j].drug_list[i].intake == "8") {
                      this.param_four = false;
                      if (obj.prescriptions[j].drug_list[i].morning !== "0") {
                        morning = obj.prescriptions[j].drug_list[i].morning + " " + this.short_form + " - ";
                      } else {
                        morning = obj.prescriptions[j].drug_list[i].morning + " - ";
                      }
                      if (obj.prescriptions[j].drug_list[i].afternoon !== "0") {
                        afternoon = obj.prescriptions[j].drug_list[i].afternoon + " " + this.short_form + " - ";
                      } else {
                        afternoon = obj.prescriptions[j].drug_list[i].afternoon + " - ";
                      }
                      if (obj.prescriptions[j].drug_list[i].evening !== "0") {
                        evening = obj.prescriptions[j].drug_list[i].evening + " " + this.short_form + " - ";
                      } else {
                        evening = obj.prescriptions[j].drug_list[i].evening + " - ";
                      }
                      if (obj.prescriptions[j].drug_list[i].night !== "0") {
                        night = obj.prescriptions[j].drug_list[i].night + " " + this.short_form;
                      } else {
                        night = obj.prescriptions[j].drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night;
                    } else if (obj.prescriptions[j].drug_list[i].intake == "9") {
                      this.param_four = true;
                      this.every_six = obj.prescriptions[j].drug_list[i].morning + shortform + " - Every 6 hours";

                    } else if (obj.prescriptions[j].drug_list[i].intake == "10") {
                      this.param_four = true;
                      this.every_six = obj.prescriptions[j].drug_list[i].morning + shortform + " - Every 8 hours";

                    } else if (obj.prescriptions[j].drug_list[i].intake == "11") {
                      this.param_four = true;
                      this.every_six = obj.prescriptions[j].drug_list[i].morning + shortform + " - Every 12 hours ";

                    } else if (obj.prescriptions[j].drug_list[i].intake == "12") {
                      this.every_six = obj.prescriptions[j].drug_list[i].morning + shortform + " - Once a day";
                      this.param_four = true;

                    } else if (obj.prescriptions[j].drug_list[i].intake == "13") {
                      this.every_six = obj.prescriptions[j].drug_list[i].morning + shortform + " SOS - if required";
                      this.param_four = true;

                    } else if (obj.prescriptions[j].drug_list[i].intake == "14") {
                      this.param_four = false;
                      if (obj.prescriptions[j].drug_list[i].morning !== "0") {
                        morning = obj.prescriptions[j].drug_list[i].morning + " " + this.short_form + " - ";
                      } else {
                        morning = obj.prescriptions[j].drug_list[i].morning + " - ";
                      }
                      if (obj.prescriptions[j].drug_list[i].afternoon !== "0") {
                        afternoon = obj.prescriptions[j].drug_list[i].afternoon + " " + this.short_form + " - ";
                      } else {
                        afternoon = obj.prescriptions[j].drug_list[i].afternoon + " - ";
                      }
                      if (obj.prescriptions[j].drug_list[i].evening !== "0") {
                        evening = obj.prescriptions[j].drug_list[i].evening + " " + this.short_form + " - ";
                      } else {
                        evening = obj.prescriptions[j].drug_list[i].evening + " - ";
                      }
                      if (obj.prescriptions[j].drug_list[i].night !== "0") {
                        night = obj.prescriptions[j].drug_list[i].night + " " + this.short_form;
                      } else {
                        night = obj.prescriptions[j].drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night;
                    } else if (obj.prescriptions[j].drug_list[i].intake == "15") {
                      this.param_four = false;
                      if (obj.prescriptions[j].drug_list[i].morning !== "0") {
                        morning = obj.prescriptions[j].drug_list[i].morning + " " + this.short_form + " - ";
                      } else {
                        morning = obj.prescriptions[j].drug_list[i].morning + " - ";
                      }
                      if (obj.prescriptions[j].drug_list[i].afternoon !== "0") {
                        afternoon = obj.prescriptions[j].drug_list[i].afternoon + " " + this.short_form + " - ";
                      } else {
                        afternoon = obj.prescriptions[j].drug_list[i].afternoon + " - ";
                      }
                      if (obj.prescriptions[j].drug_list[i].evening !== "0") {
                        evening = obj.prescriptions[j].drug_list[i].evening + " " + this.short_form + " - ";
                      } else {
                        evening = obj.prescriptions[j].drug_list[i].evening + " - ";
                      }
                      if (obj.prescriptions[j].drug_list[i].night !== "0") {
                        night = obj.prescriptions[j].drug_list[i].night + " " + this.short_form;
                      } else {
                        night = obj.prescriptions[j].drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night;
                    } else if (obj.prescriptions[j].drug_list[i].intake == "16") {
                      this.param_four = false;
                      if (obj.prescriptions[j].drug_list[i].morning !== "0") {
                        morning = obj.prescriptions[j].drug_list[i].morning + " " + this.short_form + " - ";
                      } else {
                        morning = obj.prescriptions[j].drug_list[i].morning + " - ";
                      }
                      if (obj.prescriptions[j].drug_list[i].afternoon !== "0") {
                        afternoon = obj.prescriptions[j].drug_list[i].afternoon + " " + this.short_form + " - ";
                      } else {
                        afternoon = obj.prescriptions[j].drug_list[i].afternoon + " - ";
                      }
                      if (obj.prescriptions[j].drug_list[i].evening !== "0") {
                        evening = obj.prescriptions[j].drug_list[i].evening + " " + this.short_form + " - ";
                      } else {
                        evening = obj.prescriptions[j].drug_list[i].evening + " - ";
                      }
                      if (obj.prescriptions[j].drug_list[i].night !== "0") {
                        night = obj.prescriptions[j].drug_list[i].night + " " + this.short_form;
                      } else {
                        night = obj.prescriptions[j].drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night;
                    } else if (obj.prescriptions[j].drug_list[i].intake == "17") {
                      this.param_four = true;
                      this.every_six = obj.prescriptions[j].drug_list[i].morning + shortform + " STAT";
                    }
                    else if (obj.prescriptions[j].drug_list[i].intake == "18") {
                      this.param_four = false;
                      if (obj.prescriptions[j].drug_list[i].morning !== "0") {
                        morning = obj.prescriptions[j].drug_list[i].morning + " " + this.short_form + " - ";
                      } else {
                        morning = obj.prescriptions[j].drug_list[i].morning + " - ";
                      }
                      if (obj.prescriptions[j].drug_list[i].afternoon !== "0") {
                        afternoon = obj.prescriptions[j].drug_list[i].afternoon + " " + this.short_form + " - ";
                      } else {
                        afternoon = obj.prescriptions[j].drug_list[i].afternoon + " - ";
                      }
                      if (obj.prescriptions[j].drug_list[i].evening !== "0") {
                        evening = obj.prescriptions[j].drug_list[i].evening + " " + this.short_form + " - ";
                      } else {
                        evening = obj.prescriptions[j].drug_list[i].evening + " - ";
                      }
                      if (obj.prescriptions[j].drug_list[i].night !== "0") {
                        night = obj.prescriptions[j].drug_list[i].night + " " + this.short_form;
                      } else {
                        night = obj.prescriptions[j].drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night;
                    }
                    else if (obj.prescriptions[j].drug_list[i].intake == "19") {
                      this.param_four = false;
                      if (obj.prescriptions[j].drug_list[i].morning !== "0") {
                        morning = obj.prescriptions[j].drug_list[i].morning + " " + this.short_form + " - ";
                      } else {
                        morning = obj.prescriptions[j].drug_list[i].morning + " - ";
                      }
                      if (obj.prescriptions[j].drug_list[i].afternoon !== "0") {
                        afternoon = obj.prescriptions[j].drug_list[i].afternoon + " " + this.short_form + " - ";
                      } else {
                        afternoon = obj.prescriptions[j].drug_list[i].afternoon + " - ";
                      }
                      if (obj.prescriptions[j].drug_list[i].evening !== "0") {
                        evening = obj.prescriptions[j].drug_list[i].evening + " " + this.short_form + " - ";
                      } else {
                        evening = obj.prescriptions[j].drug_list[i].evening + " - ";
                      }
                      if (obj.prescriptions[j].drug_list[i].night !== "0") {
                        night = obj.prescriptions[j].drug_list[i].night + " " + this.short_form;
                      } else {
                        night = obj.prescriptions[j].drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night;
                    }
                    else if (obj.prescriptions[j].drug_list[i].intake == "20") {
                      this.param_four = false;
                      if (obj.prescriptions[j].drug_list[i].morning !== "0") {
                        morning = obj.prescriptions[j].drug_list[i].morning + " " + this.short_form + " - ";
                      } else {
                        morning = obj.prescriptions[j].drug_list[i].morning + " - ";
                      }
                      if (obj.prescriptions[j].drug_list[i].afternoon !== "0") {
                        afternoon = obj.prescriptions[j].drug_list[i].afternoon + " " + this.short_form + " - ";
                      } else {
                        afternoon = obj.prescriptions[j].drug_list[i].afternoon + " - ";
                      }
                      if (obj.prescriptions[j].drug_list[i].evening !== "0") {
                        evening = obj.prescriptions[j].drug_list[i].evening + " " + this.short_form + " - ";
                      } else {
                        evening = obj.prescriptions[j].drug_list[i].evening + " - ";
                      }
                      if (obj.prescriptions[j].drug_list[i].night !== "0") {
                        night = obj.prescriptions[j].drug_list[i].night + " " + this.short_form;
                      } else {
                        night = obj.prescriptions[j].drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night;
                    }


                    if (obj.prescriptions[j].drug_list[i].intake != "17" && (obj.prescriptions[j].drug_list[i].time_duration != undefined && obj.prescriptions[j].drug_list[i].time_duration != "")) {
                      this.dure_write = obj.prescriptions[j].drug_list[i].time_duration + " " + "mins ";
                    } else {
                      if (obj.prescriptions[j].drug_list[i].intake != "17" && (obj.prescriptions[j].drug_list[i].time_duration != undefined && obj.prescriptions[j].drug_list[i].time_duration != " ")) {
                        this.dure_write = obj.prescriptions[j].drug_list[i].time_duration + " " + "mins ";
                      } else {
                        this.dure_write = "";
                        this.dure_flag = true;
                      }
                    }

                    if (this.afterfood_txt == undefined) {
                      this.afterfood_txt = "";
                    }
                    var frequency;
                    if (obj.prescriptions[j].drug_list[i].intake == "Alternative day" || obj.prescriptions[j].drug_list[i].intake == "Once a week" || obj.prescriptions[j].drug_list[i].intake == "Once in two weeks" ||obj.prescriptions[j].drug_list[i].intake == "Twice a week" || obj.prescriptions[j].drug_list[i].intake == "Once a month") {
                      frequency = drugs[i].intake_desc;
                    } else {
                      if (obj.prescriptions[j].drug_list[i].days == "1" && obj.prescriptions[j].drug_list[i].day_dur == "day") {
                        frequency = "";
                      } if (obj.prescriptions[j].drug_list[i].intake == "Stat" || obj.prescriptions[j].drug_list[i].intake == "SOS") {
                        frequency = " ";
                      } else {
                        frequency = "Daily";
                      }
                    }
                    if (obj.prescriptions[j].drug_list[i].generic_name !== undefined) {
                      var genericname = obj.prescriptions[j].drug_list[i].generic_name;
                    }
                    if (drugs[i].time_duration != undefined && drugs[i].time_duration != "") {
                      this.dure_write = drugs[i].time_duration + " " + "mins";
    
                    } else {
                      this.dure_write = "";
                      this.dure_flag= true;
                    }
    
                    if(this.afterfood_txt == undefined){
                      this.afterfood_txt="";
                    }
                    // var frequency;
                    // if(obj.prescriptions[j].drug_list[i].intake == "Alternative day" || obj.prescriptions[j].drug_list[i].intake == "Once a week" || obj.prescriptions[j].drug_list[i].intake == "Once in two weeks" ||obj.prescriptions[j].drug_list[i].intake == "Twice a week" || obj.prescriptions[j].drug_list[i].intake == "Once a month"){
                    //   frequency = drugs[i].intake_desc;
    
                    // } else {
                    //   if (drugs[i].days == "1" && drugs[i].day_dur == "day") {
                    //     frequency ="";
                    //   } else {
                    //     frequency = "Daily";
                    //   }
                    // }
    
                    if (drugs[i].time_duration != null && drugs[i].time_duration != undefined) {
                      med_format = this.every_six + "  -  " + drugs[i].time_duration + " mins " + drugs[i].drug_intake;
  
                    } else {
                      med_format = this.every_six + "  -  " + drugs[i].drug_intake;
                    }
  
                    if(drugs[i].note != undefined){
                      med_format = med_format+" ("+drugs[i].note+")";
                    }
                    if(drugs[i].generic_name != undefined){
                      this.generic = drugs[i].generic_name;
                    }
                    medicines.push({
                      genericname: this.generic,
                      med_typetxt: drugs[i].drug_type_name,
                      drug_id: drugs[i].drug_id,
                      drug_name: drugs[i].drug_name,
                      days: drugs[i].days,
                      period: drugs[i].day_dur,
                      drug_intake: drugs[i].drug_intake,
                      intake: drugs[i].intake,
                      every_six: this.every_six,
                      dure_txt_table: this.dure_write,
                      time_duration: drugs[i].time_duration,
                      morning: obj.prescriptions[j].drug_list[i].morning ,
                      afternoon: obj.prescriptions[j].drug_list[i].afternoon,
                      evening: obj.prescriptions[j].drug_list[i].evening,
                      night: obj.prescriptions[j].drug_list[i].night,
                      morning1: obj.prescriptions[j].drug_list[i].morning !== "0" && shortform ? `${obj.prescriptions[j].drug_list[i].morning} ${shortform}` : obj.prescriptions[j].drug_list[i].morning,
                      afternoon1: obj.prescriptions[j].drug_list[i].afternoon !== "0" && shortform ? `${obj.prescriptions[j].drug_list[i].afternoon} ${shortform}` : obj.prescriptions[j].drug_list[i].afternoon,
                      evening1: obj.prescriptions[j].drug_list[i].evening !== "0" && shortform ? `${obj.prescriptions[j].drug_list[i].evening} ${shortform}` : obj.prescriptions[j].drug_list[i].evening,
                      night1: obj.prescriptions[j].drug_list[i].night !== "0" && shortform ? `${obj.prescriptions[j].drug_list[i].night} ${shortform}` : obj.prescriptions[j].drug_list[i].night,
                      drug_type_id: drugs[i].drug_type_id,
                      frequency: frequency,
                      show_intake: drugs[i].show_intake,
                      short_name: drugs[i].short_name,
                      rowid:rowcount,
                      checked:false,
                      drug: drugs[i].short_name + " " + drugs[i].drug_name+" {"+this.every_six + "} " + drugs[i].days+" "+drugs[i].day_dur + "  "+frequency,
                      category: med_format,
                      param_four:this.param_four
                    });


                    // if (obj.prescriptions[j].drug_list[i].generic_name !== undefined) {
                    //   product.genericname = obj.prescriptions[j].drug_list[i].generic_name;
                    // }
                    // this.listProducts.push(product);
                  } else {
                  
                    if (obj.prescriptions[j].drug_list[i].intake == "1") {
                      this.param_four = false;
                      if(obj.prescriptions[j].drug_list[i].morning !== "0"){
                        morning=obj.prescriptions[j].drug_list[i].morning+" " + this.short_form + " - ";
                      }else{
                        morning =obj.prescriptions[j].drug_list[i].morning+ " - ";
                      }
                      if(obj.prescriptions[j].drug_list[i].afternoon !== "0"){
                        afternoon=obj.prescriptions[j].drug_list[i].afternoon+" " + this.short_form + " - ";
                      }else{
                        afternoon =obj.prescriptions[j].drug_list[i].afternoon+ " - ";
                      }
                      if(obj.prescriptions[j].drug_list[i].evening !== "0"){
                        evening=obj.prescriptions[j].drug_list[i].evening+" " + this.short_form + " - ";
                      }else{
                        evening =obj.prescriptions[j].drug_list[i].evening+ " - ";
                      }
                      if(obj.prescriptions[j].drug_list[i].night !== "0"){
                        night=obj.prescriptions[j].drug_list[i].night+" " + this.short_form ;
                      }else{
                        night =obj.prescriptions[j].drug_list[i].night;
                      }
                      
                      this.every_six = morning+afternoon+evening+night;  
                    } else if (obj.prescriptions[j].drug_list[i].intake == "2") {
                      this.param_four = false;
                      if(obj.prescriptions[j].drug_list[i].morning !== "0"){
                        morning=obj.prescriptions[j].drug_list[i].morning+" " + this.short_form + " - ";
                      }else{
                        morning =obj.prescriptions[j].drug_list[i].morning+ " - ";
                      }
                      if(obj.prescriptions[j].drug_list[i].afternoon !== "0"){
                        afternoon=obj.prescriptions[j].drug_list[i].afternoon+" " + this.short_form + " - ";
                      }else{
                        afternoon =obj.prescriptions[j].drug_list[i].afternoon+ " - ";
                      }
                      if(obj.prescriptions[j].drug_list[i].evening !== "0"){
                        evening=obj.prescriptions[j].drug_list[i].evening+" " + this.short_form + " - ";
                      }else{
                        evening =obj.prescriptions[j].drug_list[i].evening+ " - ";
                      }
                      if(obj.prescriptions[j].drug_list[i].night !== "0"){
                        night=obj.prescriptions[j].drug_list[i].night+" " + this.short_form ;
                      }else{
                        night =obj.prescriptions[j].drug_list[i].night;
                      }
                      
                      this.every_six = morning+afternoon+evening+night;  
                    } else if (obj.prescriptions[j].drug_list[i].intake == "3") {
                      this.param_four = false;
                      if(obj.prescriptions[j].drug_list[i].morning !== "0"){
                        morning=obj.prescriptions[j].drug_list[i].morning+" " + this.short_form + " - ";
                      }else{
                        morning =obj.prescriptions[j].drug_list[i].morning+ " - ";
                      }
                      if(obj.prescriptions[j].drug_list[i].afternoon !== "0"){
                        afternoon=obj.prescriptions[j].drug_list[i].afternoon+" " + this.short_form + " - ";
                      }else{
                        afternoon =obj.prescriptions[j].drug_list[i].afternoon+ " - ";
                      }
                      if(obj.prescriptions[j].drug_list[i].evening !== "0"){
                        evening=obj.prescriptions[j].drug_list[i].evening+" " + this.short_form + " - ";
                      }else{
                        evening =obj.prescriptions[j].drug_list[i].evening+ " - ";
                      }
                      if(obj.prescriptions[j].drug_list[i].night !== "0"){
                        night=obj.prescriptions[j].drug_list[i].night+" " + this.short_form ;
                      }else{
                        night =obj.prescriptions[j].drug_list[i].night;
                      }
                      
                      this.every_six = morning+afternoon+evening+night;  
                    } else if (obj.prescriptions[j].drug_list[i].intake == "4") {
                      this.param_four = false;
                      if(obj.prescriptions[j].drug_list[i].morning !== "0"){
                        morning=obj.prescriptions[j].drug_list[i].morning+" " + this.short_form + " - ";
                      }else{
                        morning =obj.prescriptions[j].drug_list[i].morning+ " - ";
                      }
                      if(obj.prescriptions[j].drug_list[i].afternoon !== "0"){
                        afternoon=obj.prescriptions[j].drug_list[i].afternoon+" " + this.short_form + " - ";
                      }else{
                        afternoon =obj.prescriptions[j].drug_list[i].afternoon+ " - ";
                      }
                      if(obj.prescriptions[j].drug_list[i].evening !== "0"){
                        evening=obj.prescriptions[j].drug_list[i].evening+" " + this.short_form + " - ";
                      }else{
                        evening =obj.prescriptions[j].drug_list[i].evening+ " - ";
                      }
                      if(obj.prescriptions[j].drug_list[i].night !== "0"){
                        night=obj.prescriptions[j].drug_list[i].night+" " + this.short_form ;
                      }else{
                        night =obj.prescriptions[j].drug_list[i].night;
                      }
                      
                      this.every_six = morning+afternoon+evening+night;  
                    } else if (obj.prescriptions[j].drug_list[i].intake == "5") {
                      this.param_four = false;
                      if(obj.prescriptions[j].drug_list[i].morning !== "0"){
                        morning=obj.prescriptions[j].drug_list[i].morning+" " + this.short_form + " - ";
                      }else{
                        morning =obj.prescriptions[j].drug_list[i].morning+ " - ";
                      }
                      if(obj.prescriptions[j].drug_list[i].afternoon !== "0"){
                        afternoon=obj.prescriptions[j].drug_list[i].afternoon+" " + this.short_form + " - ";
                      }else{
                        afternoon =obj.prescriptions[j].drug_list[i].afternoon+ " - ";
                      }
                      if(obj.prescriptions[j].drug_list[i].evening !== "0"){
                        evening=obj.prescriptions[j].drug_list[i].evening+" " + this.short_form + " - ";
                      }else{
                        evening =obj.prescriptions[j].drug_list[i].evening+ " - ";
                      }
                      if(obj.prescriptions[j].drug_list[i].night !== "0"){
                        night=obj.prescriptions[j].drug_list[i].night+" " + this.short_form ;
                      }else{
                        night =obj.prescriptions[j].drug_list[i].night;
                      }
                      
                      this.every_six = morning+afternoon+evening+night;  
                    } else if (obj.prescriptions[j].drug_list[i].intake == "6") {
                      this.param_four = false;
                      if(obj.prescriptions[j].drug_list[i].morning !== "0"){
                        morning=obj.prescriptions[j].drug_list[i].morning+" " + this.short_form + " - ";
                      }else{
                        morning =obj.prescriptions[j].drug_list[i].morning+ " - ";
                      }
                      if(obj.prescriptions[j].drug_list[i].afternoon !== "0"){
                        afternoon=obj.prescriptions[j].drug_list[i].afternoon+" " + this.short_form + " - ";
                      }else{
                        afternoon =obj.prescriptions[j].drug_list[i].afternoon+ " - ";
                      }
                      if(obj.prescriptions[j].drug_list[i].evening !== "0"){
                        evening=obj.prescriptions[j].drug_list[i].evening+" " + this.short_form + " - ";
                      }else{
                        evening =obj.prescriptions[j].drug_list[i].evening+ " - ";
                      }
                      if(obj.prescriptions[j].drug_list[i].night !== "0"){
                        night=obj.prescriptions[j].drug_list[i].night+" " + this.short_form ;
                      }else{
                        night =obj.prescriptions[j].drug_list[i].night;
                      }
                      
                      this.every_six = morning+afternoon+evening+night;  
                    } else if (obj.prescriptions[j].drug_list[i].intake == "7") {
                      this.param_four = false;
                      if(obj.prescriptions[j].drug_list[i].morning !== "0"){
                        morning=obj.prescriptions[j].drug_list[i].morning+" " + this.short_form + " - ";
                      }else{
                        morning =obj.prescriptions[j].drug_list[i].morning+ " - ";
                      }
                      if(obj.prescriptions[j].drug_list[i].afternoon !== "0"){
                        afternoon=obj.prescriptions[j].drug_list[i].afternoon+" " + this.short_form + " - ";
                      }else{
                        afternoon =obj.prescriptions[j].drug_list[i].afternoon+ " - ";
                      }
                      if(obj.prescriptions[j].drug_list[i].evening !== "0"){
                        evening=obj.prescriptions[j].drug_list[i].evening+" " + this.short_form + " - ";
                      }else{
                        evening =obj.prescriptions[j].drug_list[i].evening+ " - ";
                      }
                      if(obj.prescriptions[j].drug_list[i].night !== "0"){
                        night=obj.prescriptions[j].drug_list[i].night+" " + this.short_form ;
                      }else{
                        night =obj.prescriptions[j].drug_list[i].night;
                      }
                      
                      this.every_six = morning+afternoon+evening+night;  
                    } else if (obj.prescriptions[j].drug_list[i].intake == "8") {
                      this.param_four = false;
                      if(obj.prescriptions[j].drug_list[i].morning !== "0"){
                        morning=obj.prescriptions[j].drug_list[i].morning+" " + this.short_form + " - ";
                      }else{
                        morning =obj.prescriptions[j].drug_list[i].morning+ " - ";
                      }
                      if(obj.prescriptions[j].drug_list[i].afternoon !== "0"){
                        afternoon=obj.prescriptions[j].drug_list[i].afternoon+" " + this.short_form + " - ";
                      }else{
                        afternoon =obj.prescriptions[j].drug_list[i].afternoon+ " - ";
                      }
                      if(obj.prescriptions[j].drug_list[i].evening !== "0"){
                        evening=obj.prescriptions[j].drug_list[i].evening+" " + this.short_form + " - ";
                      }else{
                        evening =obj.prescriptions[j].drug_list[i].evening+ " - ";
                      }
                      if(obj.prescriptions[j].drug_list[i].night !== "0"){
                        night=obj.prescriptions[j].drug_list[i].night+" " + this.short_form ;
                      }else{
                        night =obj.prescriptions[j].drug_list[i].night;
                      }
                      
                      this.every_six = morning+afternoon+evening+night;  
                    } else if (obj.prescriptions[j].drug_list[i].intake == "9") {
                      this.every_six = obj.prescriptions[j].drug_list[i].morning + " - Every 6 hours";
                      this.param_four = true;

                    } else if (obj.prescriptions[j].drug_list[i].intake == "10") {
                      this.every_six = obj.prescriptions[j].drug_list[i].morning + " - Every 8 hours";
                      this.param_four = true;

                    } else if (obj.prescriptions[j].drug_list[i].intake == "11") {
                      this.every_six = obj.prescriptions[j].drug_list[i].morning + " - Every 12 hours";
                      this.param_four = true;

                    } else if (obj.prescriptions[j].drug_list[i].intake == "12") {
                      this.every_six = obj.prescriptions[j].drug_list[i].morning + " - Once a day";
                      this.param_four = true;

                    } else if (obj.prescriptions[j].drug_list[i].intake == "13") {
                      this.every_six = obj.prescriptions[j].drug_list[i].morning + " SOS - if required";
                      this.param_four = true;

                    } else if (obj.prescriptions[j].drug_list[i].intake == "14") {
                      if(obj.prescriptions[j].drug_list[i].morning !== "0"){
                        morning=obj.prescriptions[j].drug_list[i].morning+" " + this.short_form + " - ";
                      }else{
                        morning =obj.prescriptions[j].drug_list[i].morning+ " - ";
                      }
                      if(obj.prescriptions[j].drug_list[i].afternoon !== "0"){
                        afternoon=obj.prescriptions[j].drug_list[i].afternoon+" " + this.short_form + " - ";
                      }else{
                        afternoon =obj.prescriptions[j].drug_list[i].afternoon+ " - ";
                      }
                      if(obj.prescriptions[j].drug_list[i].evening !== "0"){
                        evening=obj.prescriptions[j].drug_list[i].evening+" " + this.short_form + " - ";
                      }else{
                        evening =obj.prescriptions[j].drug_list[i].evening+ " - ";
                      }
                      if(obj.prescriptions[j].drug_list[i].night !== "0"){
                        night=obj.prescriptions[j].drug_list[i].night+" " + this.short_form ;
                      }else{
                        night =obj.prescriptions[j].drug_list[i].night;
                      }
                      
                      this.every_six = morning+afternoon+evening+night;                        this.param_four = false;
                    } else if (obj.prescriptions[j].drug_list[i].intake == "15") {
                      if(obj.prescriptions[j].drug_list[i].morning !== "0"){
                        morning=obj.prescriptions[j].drug_list[i].morning+" " + this.short_form + " - ";
                      }else{
                        morning =obj.prescriptions[j].drug_list[i].morning+ " - ";
                      }
                      if(obj.prescriptions[j].drug_list[i].afternoon !== "0"){
                        afternoon=obj.prescriptions[j].drug_list[i].afternoon+" " + this.short_form + " - ";
                      }else{
                        afternoon =obj.prescriptions[j].drug_list[i].afternoon+ " - ";
                      }
                      if(obj.prescriptions[j].drug_list[i].evening !== "0"){
                        evening=obj.prescriptions[j].drug_list[i].evening+" " + this.short_form + " - ";
                      }else{
                        evening =obj.prescriptions[j].drug_list[i].evening+ " - ";
                      }
                      if(obj.prescriptions[j].drug_list[i].night !== "0"){
                        night=obj.prescriptions[j].drug_list[i].night+" " + this.short_form ;
                      }else{
                        night =obj.prescriptions[j].drug_list[i].night;
                      }
                      
                      this.every_six = morning+afternoon+evening+night;                        this.param_four = false;
                    } else if (obj.prescriptions[j].drug_list[i].intake == "16") {
                      this.param_four = false;
                      if(obj.prescriptions[j].drug_list[i].morning !== "0"){
                        morning=obj.prescriptions[j].drug_list[i].morning+" " + this.short_form + " - ";
                      }else{
                        morning =obj.prescriptions[j].drug_list[i].morning+ " - ";
                      }
                      if(obj.prescriptions[j].drug_list[i].afternoon !== "0"){
                        afternoon=obj.prescriptions[j].drug_list[i].afternoon+" " + this.short_form + " - ";
                      }else{
                        afternoon =obj.prescriptions[j].drug_list[i].afternoon+ " - ";
                      }
                      if(obj.prescriptions[j].drug_list[i].evening !== "0"){
                        evening=obj.prescriptions[j].drug_list[i].evening+" " + this.short_form + " - ";
                      }else{
                        evening =obj.prescriptions[j].drug_list[i].evening+ " - ";
                      }
                      if(obj.prescriptions[j].drug_list[i].night !== "0"){
                        night=obj.prescriptions[j].drug_list[i].night+" " + this.short_form ;
                      }else{
                        night =obj.prescriptions[j].drug_list[i].night;
                      }
                      
                      this.every_six = morning+afternoon+evening+night;  
                    } else if (obj.prescriptions[j].drug_list[i].intake == "17") {
                      this.param_four = true;
                      this.every_six = obj.prescriptions[j].drug_list[i].morning + " STAT";
                    }
                    else if (obj.prescriptions[j].drug_list[i].intake == "18") {
                      this.param_four = false;
                      if(obj.prescriptions[j].drug_list[i].morning !== "0"){
                        morning=obj.prescriptions[j].drug_list[i].morning+" " + this.short_form + " - ";
                      }else{
                        morning =obj.prescriptions[j].drug_list[i].morning+ " - ";
                      }
                      if(obj.prescriptions[j].drug_list[i].afternoon !== "0"){
                        afternoon=obj.prescriptions[j].drug_list[i].afternoon+" " + this.short_form + " - ";
                      }else{
                        afternoon =obj.prescriptions[j].drug_list[i].afternoon+ " - ";
                      }
                      if(obj.prescriptions[j].drug_list[i].evening !== "0"){
                        evening=obj.prescriptions[j].drug_list[i].evening+" " + this.short_form + " - ";
                      }else{
                        evening =obj.prescriptions[j].drug_list[i].evening+ " - ";
                      }
                      if(obj.prescriptions[j].drug_list[i].night !== "0"){
                        night=obj.prescriptions[j].drug_list[i].night+" " + this.short_form ;
                      }else{
                        night =obj.prescriptions[j].drug_list[i].night;
                      }
                      
                      this.every_six = morning+afternoon+evening+night; 
                    }
                    else if (obj.prescriptions[j].drug_list[i].intake == "19") {
                      this.param_four = false;
                      if(obj.prescriptions[j].drug_list[i].morning !== "0"){
                        morning=obj.prescriptions[j].drug_list[i].morning+" " + this.short_form + " - ";
                      }else{
                        morning =obj.prescriptions[j].drug_list[i].morning+ " - ";
                      }
                      if(obj.prescriptions[j].drug_list[i].afternoon !== "0"){
                        afternoon=obj.prescriptions[j].drug_list[i].afternoon+" " + this.short_form + " - ";
                      }else{
                        afternoon =obj.prescriptions[j].drug_list[i].afternoon+ " - ";
                      }
                      if(obj.prescriptions[j].drug_list[i].evening !== "0"){
                        evening=obj.prescriptions[j].drug_list[i].evening+" " + this.short_form + " - ";
                      }else{
                        evening =obj.prescriptions[j].drug_list[i].evening+ " - ";
                      }
                      if(obj.prescriptions[j].drug_list[i].night !== "0"){
                        night=obj.prescriptions[j].drug_list[i].night+" " + this.short_form ;
                      }else{
                        night =obj.prescriptions[j].drug_list[i].night;
                      }
                      
                      this.every_six = morning+afternoon+evening+night;  
                                        }
                    else if (obj.prescriptions[j].drug_list[i].intake == "20") {
                      this.param_four = false;
                      if(obj.prescriptions[j].drug_list[i].morning !== "0"){
                        morning=obj.prescriptions[j].drug_list[i].morning+" " + this.short_form + " - ";
                      }else{
                        morning =obj.prescriptions[j].drug_list[i].morning+ " - ";
                      }
                      if(obj.prescriptions[j].drug_list[i].afternoon !== "0"){
                        afternoon=obj.prescriptions[j].drug_list[i].afternoon+" " + this.short_form + " - ";
                      }else{
                        afternoon =obj.prescriptions[j].drug_list[i].afternoon+ " - ";
                      }
                      if(obj.prescriptions[j].drug_list[i].evening !== "0"){
                        evening=obj.prescriptions[j].drug_list[i].evening+" " + this.short_form + " - ";
                      }else{
                        evening =obj.prescriptions[j].drug_list[i].evening+ " - ";
                      }
                      if(obj.prescriptions[j].drug_list[i].night !== "0"){
                        night=obj.prescriptions[j].drug_list[i].night+" " + this.short_form ;
                      }else{
                        night =obj.prescriptions[j].drug_list[i].night;
                      }
                      
                      this.every_six = morning+afternoon+evening+night;                      }


                    else {
                      if (obj.prescriptions[j].drug_list[i].evening == 0 && obj.prescriptions[j].drug_list[i].night != 0) {
                        if(obj.prescriptions[j].drug_list[i].morning !== "0"){
                          morning=obj.prescriptions[j].drug_list[i].morning+" " + this.short_form + " - ";
                        }else{
                          morning =obj.prescriptions[j].drug_list[i].morning+ " - ";
                        }
                        if(obj.prescriptions[j].drug_list[i].afternoon !== "0"){
                          afternoon=obj.prescriptions[j].drug_list[i].afternoon+" " + this.short_form + " - ";
                        }else{
                          afternoon =obj.prescriptions[j].drug_list[i].afternoon+ " - ";
                        }
                        if(obj.prescriptions[j].drug_list[i].evening !== "0"){
                          evening=obj.prescriptions[j].drug_list[i].evening+" " + this.short_form + " - ";
                        }else{
                          evening =obj.prescriptions[j].drug_list[i].evening+ " - ";
                        }
                        if(obj.prescriptions[j].drug_list[i].night !== "0"){
                          night=obj.prescriptions[j].drug_list[i].night+" " + this.short_form ;
                        }else{
                          night =obj.prescriptions[j].drug_list[i].night;
                        }
                        
                        this.every_six = morning+afternoon+evening+night;  
                      } else if (obj.prescriptions[j].drug_list[i].evening != 0 && obj.prescriptions[j].drug_list[i].night == 0) {
                        if(obj.prescriptions[j].drug_list[i].morning !== "0"){
                          morning=obj.prescriptions[j].drug_list[i].morning+" " + this.short_form + " - ";
                        }else{
                          morning =obj.prescriptions[j].drug_list[i].morning+ " - ";
                        }
                        if(obj.prescriptions[j].drug_list[i].afternoon !== "0"){
                          afternoon=obj.prescriptions[j].drug_list[i].afternoon+" " + this.short_form + " - ";
                        }else{
                          afternoon =obj.prescriptions[j].drug_list[i].afternoon+ " - ";
                        }
                        if(obj.prescriptions[j].drug_list[i].evening !== "0"){
                          evening=obj.prescriptions[j].drug_list[i].evening+" " + this.short_form + " - ";
                        }else{
                          evening =obj.prescriptions[j].drug_list[i].evening+ " - ";
                        }
                        if(obj.prescriptions[j].drug_list[i].night !== "0"){
                          night=obj.prescriptions[j].drug_list[i].night+" " + this.short_form ;
                        }else{
                          night =obj.prescriptions[j].drug_list[i].night;
                        }
                        
                        this.every_six = morning+afternoon+evening+night;  
                       }
                      this.param_four = false;
                    }

                    if (obj.prescriptions[j].drug_list[i].intake != "17" && (obj.prescriptions[j].drug_list[i].time_duration != undefined && obj.prescriptions[j].drug_list[i].time_duration != "")) {
                      this.dure_write = obj.prescriptions[j].drug_list[i].time_duration + " " + "mins ";
                    } else {
                      if (obj.prescriptions[j].drug_list[i].intake != "17" && (obj.prescriptions[j].drug_list[i].time_duration != undefined && obj.prescriptions[j].drug_list[i].time_duration != " ")) {
                        this.dure_write = obj.prescriptions[j].drug_list[i].time_duration + " " + "mins ";
                      } else {
                        this.dure_write = "";
                        this.dure_flag = true;
                      }
                    }
                    if (this.afterfood_txt == undefined) {
                      this.afterfood_txt = "";
                    }
                    if (obj.prescriptions[j].drug_list[i].generic_name !== undefined) {
                      var genericname = obj.prescriptions[j].drug_list[i].generic_name;
                    }
                    var frequency;
                    if (obj.prescriptions[j].drug_list[i].intake == "Alternative day" || obj.prescriptions[j].drug_list[i].intake == "Once a week" || obj.prescriptions[j].drug_list[i].intake == "Once in two weeks" ||obj.prescriptions[j].drug_list[i].intake == "Twice a week" || obj.prescriptions[j].drug_list[i].intake == "Once a month") {
                      frequency = drugs[i].intake_desc;
                    } else {
                      if (obj.prescriptions[j].drug_list[i].days == "1" && obj.prescriptions[j].drug_list[i].day_dur == "day") {
                        frequency = "";
                      } if (obj.prescriptions[j].drug_list[i].intake == "Stat" || obj.prescriptions[j].drug_list[i].intake == "SOS") {
                        frequency = " ";
                      } else {
                        frequency = "Daily";
                      }
                    }
                    if (obj.prescriptions[j].drug_list[i].generic_name !== undefined) {
                      var genericname = obj.prescriptions[j].drug_list[i].generic_name;
                    }
                    if (drugs[i].time_duration != undefined && drugs[i].time_duration != "") {
                      this.dure_write = drugs[i].time_duration + " " + "mins";
    
                    } else {
                      this.dure_write = "";
                      this.dure_flag= true;
                    }
    
                    if(this.afterfood_txt == undefined){
                      this.afterfood_txt="";
                    }
                    // var frequency;
                    // if(obj.prescriptions[j].drug_list[i].intake == "Alternative day" || obj.prescriptions[j].drug_list[i].intake == "Once a week" || obj.prescriptions[j].drug_list[i].intake == "Once in two weeks" ||obj.prescriptions[j].drug_list[i].intake == "Twice a week" || obj.prescriptions[j].drug_list[i].intake == "Once a month"){
                    //   frequency = drugs[i].intake_desc;
    
                    // } else {
                    //   if (drugs[i].days == "1" && drugs[i].day_dur == "day") {
                    //     frequency ="";
                    //   } else {
                    //     frequency = "Daily";
                    //   }
                    // }
    
                    if (drugs[i].time_duration != null && drugs[i].time_duration != undefined) {
                      med_format = this.every_six + "  -  " + drugs[i].time_duration + " mins " + drugs[i].drug_intake;
  
                    } else {
                      med_format = this.every_six + "  -  " + drugs[i].drug_intake;
                    }
  
                    if(drugs[i].note != undefined){
                      med_format = med_format+" ("+drugs[i].note+")";
                    }
                    if(drugs[i].generic_name != undefined){
                      this.generic = drugs[i].generic_name;
                    }
                    var product: any = {
                      med_typetxt: obj.prescriptions[j].drug_list[i].drug_type_name,
                      drug_id: obj.prescriptions[j].drug_list[i].drug_id,
                      genericname: genericname,
                      drug_name: obj.prescriptions[j].drug_list[i].drug_name,
                      days: obj.prescriptions[j].drug_list[i].days,
                      period: obj.prescriptions[j].drug_list[i].day_dur,
                      drug_intake: obj.prescriptions[j].drug_list[i].drug_intake,
                      intake: obj.prescriptions[j].drug_list[i].intake,
                      every_six: this.every_six,
                      dure_txt_table: this.dure_write,
                      time_duration: obj.prescriptions[j].drug_list[i].time_duration,
                      morning: obj.prescriptions[j].drug_list[i].morning ,
                      afternoon: obj.prescriptions[j].drug_list[i].afternoon,
                      evening: obj.prescriptions[j].drug_list[i].evening,
                      night: obj.prescriptions[j].drug_list[i].night,
                      morning1: obj.prescriptions[j].drug_list[i].morning !== "0" && shortform ? `${obj.prescriptions[j].drug_list[i].morning} ${shortform}` : obj.prescriptions[j].drug_list[i].morning,
                      afternoon1: obj.prescriptions[j].drug_list[i].afternoon !== "0" && shortform ? `${obj.prescriptions[j].drug_list[i].afternoon} ${shortform}` : obj.prescriptions[j].drug_list[i].afternoon,
                      evening1: obj.prescriptions[j].drug_list[i].evening !== "0" && shortform ? `${obj.prescriptions[j].drug_list[i].evening} ${shortform}` : obj.prescriptions[j].drug_list[i].evening,
                      night1: obj.prescriptions[j].drug_list[i].night !== "0" && shortform ? `${obj.prescriptions[j].drug_list[i].night} ${shortform}` : obj.prescriptions[j].drug_list[i].night,
                      // night: obj.prescriptions[j].drug_list[i].night,
                      drug_type_id: obj.prescriptions[j].drug_list[i].drug_type_id,
                      frequency: frequency,
                      show_intake: obj.prescriptions[j].drug_list[i].show_intake,
                      remarks: obj.prescriptions[j].drug_list[i].remarks,
                      short_name: obj.prescriptions[j].drug_list[i].short_name,
                      param_four: this.param_four
                    }
                    if (obj.prescriptions[j].drug_list[i].generic_name !== undefined) {
                      product.genericname = obj.prescriptions[j].drug_list[i].generic_name;
                    }
                    medicines.push({
                      genericname:this.generic,
                      med_typetxt: drugs[i].drug_type_name,
                      drug_id: drugs[i].drug_id,
                      drug_name: drugs[i].drug_name,
                      days: drugs[i].days,
                      period: drugs[i].day_dur,
                      drug_intake: drugs[i].drug_intake,
                      intake: drugs[i].intake,
                      every_six: this.every_six,
                      dure_txt_table: this.dure_write,
                      time_duration: drugs[i].time_duration,
                      morning: drugs[i].morning,
                      afternoon: drugs[i].afternoon,
                      evening: drugs[i].evening,
                      // evening: obj.prescriptions[j].drug_list[i].evening,
                    // morning: obj.prescriptions[j].drug_list[i].morning ,
                    // afternoon: obj.prescriptions[j].drug_list[i].afternoon,
                    // evening: obj.prescriptions[j].drug_list[i].evening,
                    // night: obj.prescriptions[j].drug_list[i].night,
                    morning1: obj.prescriptions[j].drug_list[i].morning !== "0" && shortform ? `${obj.prescriptions[j].drug_list[i].morning} ${shortform}` : obj.prescriptions[j].drug_list[i].morning,
                    afternoon1: obj.prescriptions[j].drug_list[i]. afternoon !== "0" && shortform ? `${obj.prescriptions[j].drug_list[i].afternoon} ${shortform}` : obj.prescriptions[j].drug_list[i].afternoon,
                    evening1: obj.prescriptions[j].drug_list[i].evening !== "0" && shortform ? `${obj.prescriptions[j].drug_list[i].evening} ${shortform}` : obj.prescriptions[j].drug_list[i].evening,
                    night1: obj.prescriptions[j].drug_list[i].night !== "0" && shortform ? `${obj.prescriptions[j].drug_list[i].night} ${shortform}` : obj.prescriptions[j].drug_list[i].night,
                      drug_type_id: drugs[i].drug_type_id,
                      frequency: frequency,
                      show_intake: drugs[i].show_intake,
                      short_name: drugs[i].short_name,
                      rowid:rowcount,
                      checked:false,
                      drug: drugs[i].short_name + " " + drugs[i].drug_name+" {"+this.every_six + "} " + drugs[i].days+" "+drugs[i].day_dur + "  "+frequency,
                      category: med_format,
                      param_four:this.param_four
                    });
                    console.log("cj check 00---->" + JSON.stringify(this.listProducts))
                  }
                  
                  rowcount ++;
                }
              }else {
                this.nodataapp=false;
              }  
              
              var dateval = obj.prescriptions[j].date.split('-');
  
              this.listProducts.push({
                pres_id: obj.prescriptions[j].pres_drug_id,
                pres_date: dateval[2]+"-"+dateval[1]+"-"+dateval[0],
                disease: obj.prescriptions[j].disease,
                medicines: medicines,
                checked:false,
                genericname: obj.prescriptions[j].genericname
                
              })
              console.log("listProducts"+JSON.stringify(this.listProducts))

            }
          }
        })
  }

  // selectedPrescription(product, select) {
  //   if(product.checked == true){
  //     if(this.selectedList.length !=0){
  //       var flag=false;
  //       for(var j=0;j<this.selectedList.length;j++){
  //         if(this.selectedList[j].drug_id == product.drug_id){
  //           flag=true;
  //         } 
  //       }
  //       if(flag == true){
  //         this.toastr.warning("medicine already selected, are you sure want to add retain both else unselect unwanted");
  //         this.selectedList.push(product);  
  //       } else {
  //         this.selectedList.push(product);  
  //       }
  //     } else {
  //       this.selectedList.push(product);
  //     }     
  //   } else {
  //     for(var j=0;j<this.selectedList.length;j++){
  //       if(this.selectedList[j].drug_id == product.drug_id  
  //         && this.selectedList[j].rowid == product.rowid){
  //         this.selectedList.splice(j,1);
  //       }
  //     }
  //   }    
  //   this.saveflag = this.selectedList.length != 0 ? false : true;
  // }

  selectedPrescription(product, select){
    if(select ==true){
      for(var i=0;i<product.length;i++){
        this.selectedList.push(product[i]);
      }

    } else {
      for(var i=0;i<product.length;i++){
        this.selectedList.splice(product[i],1);
      }
    }
    
    if(this.selectedList.length != 0){
      this.saveflag = false;
    }
  }

  addtoprescription() {
    this.dialogRef.close(this.selectedList);
  }

  ClosePopup() {
    this.dialogRef.close();
  }
}
