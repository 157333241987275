import { Component, OnInit } from '@angular/core';
import { Message_data } from '../../../assets/js/Message_data';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ipaddress } from '../../ipaddress' ;
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Http, Headers } from '@angular/http';
import { Master_Helper } from 'src/app/Doctor_module/Master_Helper';
import { MasterHelperService } from 'src/app/Doctor_module/MasterHelper.service';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Helper_Class } from 'src/app/helper_class';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';

@Component({
  selector: 'app-insuranceprovider',
  templateUrl: './insuranceprovider.component.html',
  styleUrls: ['./insuranceprovider.component.scss']
})
export class InsuranceproviderComponent implements OnInit {
  public company_name;
  public insurer_name;
  public address;
  public address1;
  public email_id;
  public rohini_id;
  public mobileno;
  public insurancelist;

  public clnt_location_list: any = [];
  public clnt_city_list: any = [];
  public clnt_state_list: any = [];
  public clnt_country_list: any = [];
  public clnt_location;
  public clnt_city;
  public clnt_state;
  public clnt_country;
  public clnt_location_id;
  public clnt_city_id;
  public clnt_state_id;
  public clnt_country_id;
  public hospital_details;
  public userinfo;
  public hospital_id;
  public clnt_zipcode;
  public address2;
  public locationUrl: string;
  public locationNameByUrl: string;
  public cityUrl: string;
  public stateUrl: string;
  public countryUrl: string;
  public zipcode_url: string;
  public get_locname_url;
  
  constructor( public messageservice:MenuViewService, public router: Router,public toastr: ToastrService,public http: Http,public https: HttpClient,public masterData: MasterHelperService) { }

  ngOnInit() {
    this.address1="NA"
    this.get_locname_url = ipaddress.getIp + "usercontroller/locbyname";
    this.locationUrl = ipaddress.getIp + 'usercontroller/loc';
    this.locationNameByUrl = ipaddress.getIp + 'usercontroller/locbyname';
    this.cityUrl = ipaddress.getIp + 'gen/city';
    this.stateUrl = ipaddress.getIp + 'gen/state';
    this.countryUrl = ipaddress.getIp + 'gen/count';
    this.zipcode_url = ipaddress.getIp + 'usercontroller/locbyzipcode';
    this.userinfo = Helper_Class.getInfo();

    this.hospital_details = this.userinfo.hospitals[0];
    this.hospital_id = this.hospital_details.hptl_clinic_id;
    console.log("check countr"+JSON.stringify(this.hospital_details))

    this.clnt_location_id = this.hospital_details.location;
    this.clnt_city_id = this.hospital_details.city;
    this.clnt_state_id = this.hospital_details.state;
    this.clnt_country_id = this.hospital_details.country;
    this.getCountries("1");
 //   this.getinsurence()
    
  }

  async getCountries(flag) {
    this.clnt_country_list = [];
    if (Master_Helper.getMasterCountries() == undefined) {
      await this.masterData.getCountries();
    }
    var countryList = Master_Helper.getMasterCountries();
    if (countryList.countries != null) {

      this.clnt_country_list = countryList.countries;
      for (var c = 0; c < this.clnt_country_list.length; c++) {
        if (this.clnt_country_list[c].country_id == this.clnt_country_id) {
          this.clnt_country = this.clnt_country_list[c].description;
          this.clnt_country_id = this.clnt_country_list[c].country_id;
          this.getStates(this.clnt_country_id, flag);
          break;
        }
      }
    }
  }

  countryChange(clnt_country, flag) {
    for (var c = 0; c < this.clnt_country_list.length; c++) {
      if (this.clnt_country_list[c].description == clnt_country) {
        this.clnt_country = this.clnt_country_list[c].description;
        this.clnt_country_id = this.clnt_country_list[c].country_id;

        this.clnt_state_list = [];
        this.clnt_city_list = [];
        this.clnt_location_id = undefined;
        this.clnt_location = "";
        this.clnt_zipcode = "";
        this.getStates(this.clnt_country_id, flag);
      }
    }
  }

  getStates(country, flag) {
    this.clnt_state_list = [];
    var data;
    if (flag == "1") {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "usercontroller/state", JSON.stringify({ country_id: country }),
        { headers: headers }).subscribe(
          response => {
            data = response.json();
            if (data.states != null) {
              this.clnt_state_list = data.states;
              console.log("chec ---"+JSON.stringify
              (this.clnt_state_list))
              if (this.clnt_state_list.length != 0) {
                for (var i = 0; i < this.clnt_state_list.length; i++) {
                  console.log(this.clnt_state_list[i].description + this.clnt_state_id)
                  if (this.clnt_state_list[i].state_id == this.clnt_state_id) {
                    this.clnt_state_id = this.clnt_state_list[i].state_id;
                    this.clnt_state = this.clnt_state_list[i].description;
                    this.getCities(this.clnt_state_id, flag);
                    break;
                  }
                }
              }
            }
          });
    } else {
      data = Master_Helper.getMasterStates();
      if (data.states != null) {
        this.clnt_state_list = data.states;
      }
    }
   
  }

  stateChange(clnt_state, flag) {
    for (var i = 0; i < this.clnt_state_list.length; i++) {

      if (this.clnt_state_list[i].description == clnt_state) {
        this.clnt_state_id = this.clnt_state_list[i].state_id;
        this.clnt_state = this.clnt_state_list[i].description;
        this.clnt_city_list = [];
        this.clnt_city_id = undefined;
        this.clnt_location_id = undefined;
        this.clnt_location = "";
        this.clnt_zipcode = "";
        this.getCities(this.clnt_state_id, flag);
      }
    }
  }

  getCities(state, flag) {
    this.clnt_city_list = [];
    if (flag == "1") {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "usercontroller/city", JSON.stringify({ state_id: state }),
        { headers: headers }).subscribe(
          response => {
            var data = response.json();
            if (data.cities != null)
              this.clnt_city_list = data.cities;
              if (this.clnt_city_list.length != 0) {
                for (var i = 0; i < this.clnt_city_list.length; i++) {
                  if (this.clnt_city_list[i].district_id == this.clnt_city_id) {
                    this.clnt_city_id = this.clnt_city_list[i].district_id;
                    this.clnt_city = this.clnt_city_list[i].description;
                    this.getLocations(this.clnt_city_id, flag);
                    break;
                  } else {
                    this.clnt_city = this.clnt_city_list[0].description;
                  }
                }
              }

          });
    } else {
      var data = Master_Helper.getMasterCities();
      if (data.cities != null)
        this.clnt_city_list = data.cities;
    }

   
  }

  cityChange(clnt_city, flag) {
    this.clnt_location_list = [];
    for (var i = 0; i < this.clnt_city_list.length; i++) {
      if (this.clnt_city_list[i].description == clnt_city) {
        this.clnt_city_id = this.clnt_city_list[i].district_id;
        this.clnt_city = this.clnt_city_list[i].description;
        this.clnt_location_id = undefined;
        this.clnt_location = "";
        this.clnt_zipcode = "";
      }
    }
  }

  getLocations(city, flag) {
    this.clnt_location_list = [];

    if (flag == "1") {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "usercontroller/loc", JSON.stringify({ city_id: city }),
        { headers: headers }).subscribe(
          response => {
            var data = response.json();
            if (data.locations != null && data.locations.length != 0)
              this.clnt_location_list = data.locations;
              if (this.clnt_location_list != null && this.clnt_location_list.length != 0) {
                for (var i = 0; i < this.clnt_location_list.length; i++) {
                  if (this.clnt_location_list[i].location_id == this.clnt_location_id) {
                    this.clnt_location_id = this.clnt_location_list[i].location_id;
                    this.clnt_location = this.clnt_location_list[i].description;
                    this.clnt_zipcode = this.clnt_location_list[i].pincode;
                    this.clnt_location_list = [];
                    break;
                  }
                }
              }
          });
    } else {
      var data = Master_Helper.getMasterLocation();
      if (data.locations != null && data.locations.length != 0)
        this.clnt_location_list = data.locations;
    }
   
  }

  location_change(locat_desc) {
    this.clnt_location_list = [];
    if (locat_desc != null && locat_desc.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.get_locname_url, JSON.stringify({
        name: locat_desc,
        city_id: this.clnt_city_id,
      }), { headers: headers }).subscribe(
        data => {
          if (data.json().locations != null && data.json().locations.length != 0) {
            this.clnt_location_list = data.json().locations;
          }
        },
        error => {
          this.toastr.error(Message_data.unableToFetchDocDet);
        }
      )
    } else {
      this.clnt_location_list = [];
    }
  }

  location_selected(location) {
    this.clnt_location = location;
    for (var i = 0; i < this.clnt_location_list.length; i++) {
      if (this.clnt_location_list[i].location_id == location.location_id) {
        this.clnt_location_id = this.clnt_location_list[i].location_id;
        this.clnt_location = this.clnt_location_list[i].description;
        this.clnt_zipcode = this.clnt_location_list[i].pincode;
      }
    }
    this.clnt_location_list = [];
  }

 
  save(){
    var address: any = [];
    address.push({
      address1: this.address1,
      address2: this.address2,
      location_desc:this.clnt_location,
      location: this.clnt_location_id,      
      city: this.clnt_city_id,
      state: this.clnt_state_id,
      country: this.clnt_country_id,
      zipcode: encrypt_decript.Encript(this.clnt_zipcode).toString(),
      type: "pres"
    });
    var flag=false;
    if(this.mobileno == undefined){
      this.toastr.error(Message_data.mandatory);
      flag=true;
    }
    if(this.email_id == undefined){
      this.toastr.error(Message_data.mandatory);
      flag=true;
    }
    if(this.rohini_id == undefined){
      this.toastr.error(Message_data.mandatory);
      flag=true;
    }
    if(flag == false){
      var save_data = JSON.stringify({
        hptl_clinic_id: this.hospital_id,
        insurer_desc:this.company_name,
        name:this.insurer_name,
        address1:this.address1,
        address2:this.address2,
        location :this.clnt_location_id,
        city:this.clnt_city_id,
        state:this.clnt_state_id,
        country:this.clnt_country_id,zipcode:this.clnt_zipcode,mobile:this.mobileno,email:this.email_id,rohini_id:this.rohini_id
     
        // mobileno:this.mobileno,
        // email_id:this.email_id,rohini_id:this.rohini_id
        
        // prov_id: ,
        // client_id: this.client_reg_id,
       
       
      });
      console.log("check response"+JSON.stringify(save_data))

      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'gen/sibh', save_data, {
        headers: headers,
      })
        .subscribe((response) => {
          var obj = response.json();
          this.messageservice.sendMessage("insurerlist");
          this.toastr.success(Message_data.saveSuccess)
          console.log("check response"+JSON.stringify(obj))
          
          //this.toastr.success(Message_data.addmissionSavedSuccess);
        });
    }
  }
 

}
