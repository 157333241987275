import { Component, OnInit, Input,ViewChild,ElementRef} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Helper_Class } from '../../helper_class';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { MatDialog } from '@angular/material/dialog';
import { Date_Formate, Time_Formate, convertNumberToWords } from '../../../assets/js/common.js';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { AdvanceCreateComponent } from '../advance-create/advance-create.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FrontDeskService } from '../front-desk/front-desk.service';
import { ipaddress } from '../../ipaddress' ;import { Message_data } from 'src/assets/js/Message_data';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
import { FrontDesk_Helper } from '../FrontDesk_Helper';
declare var $ : any; 

@Component({
  selector: 'app-bill-advances',
  templateUrl: './bill-advances.component.html',
  styleUrls: ['./bill-advances.component.scss']
})
export class BillAdvancesComponent implements OnInit {
  @ViewChild('printbanner') printbanner: ElementRef;
  @ViewChild('printnoheader') printnoheader: ElementRef;
  public ClientFilterList = [];
  public clientList = [];
  public clntName: string;
  public middleName: string;
  public lastName: string;
  public clientId: string;
  public advanceCategory: string;
  public advanceCategoryflag:boolean;
  public filterFlag: Boolean;
  public fromDate: string;
  public toDate: string;
  public advanceList = [];
  @Input() max: any;
  public currentDate;
  public currentYear;
  public currentTime;
  public getDate;
  public hospId;
  public hospitalLogo;
  public hospitalName;
  public hospitalLocation;
  public hospitalCity;
  public hospitalState;
  public hospitalCountry;
  public hospitalPhNo;
  public hospitalAddr;
  public printTemplate;
  public patientId;
  public bill_head: any;
  public editbuttondie: boolean = false;
  public newbuttondie: boolean = false;
  public deletebuttondie:boolean = false;
  public printbuttondie: boolean = false;
  public viewbuttondie:boolean = false;
  public printlogostyle: any;
  public billsTable: string;
  public print_template: any;
  public getBillsList: any = [];
  public totalAdvance: any;
  public totalBalance: any;
  public salutation: any;
  public patient_id: any;
  public age: any;
  public gen_short: any;
  public client: any;
  public state: any;
  public country: any;
  public zipcode: any;
  public location: any;
  public city: any;
  public clientAddr1: any;
  public clientAddr2: any;
  public numToWords: string;
  public print_size;
  public barcode_flag:boolean = false;
  public barcode_url;
  public logUserName;
  public displayValue = true;
  public lineColor = '#000000';
  public elementType: 'svg';
  public format:
     | ''
     | 'CODE128'
     | 'CODE128A'
     | 'CODE128B'
     | 'CODE128C'
     | 'EAN'
     | 'UPC'
     | 'EAN8'
     | 'EAN5'
     | 'EAN2'
     | 'CODE39'
     | 'ITF14'
     | 'MSI'
     | 'MSI10'
     | 'MSI11'
     | 'MSI1010'
     | 'MSI1110'
     | 'pharmacode'
     | 'codabar';
  public width = 1.0;
  public barcodeheight = 23;
  public fontOptions = '';
  public font = 'monospace';
  public textAlign = 'center';
  public textPosition = 'bottom';
  public textMargin = 2;
  public fontSize = 15;
  public background = '#ffffff';
  
  constructor(public gservice:CommonDataService,public http:HttpClient,public routes:ActivatedRoute,public router:Router,public toastr:ToastrService,  public messageservice:MenuViewService,public dialog:MatDialog) {       
  }

  ngOnInit(): void {
    $(document).ready(function () {
      $("input").attr("autocomplete", "off");
    });

    this.advanceCategoryflag = true;
    this.hospId = Helper_Class.getInfo().hptl_clinic_id;
    this.advanceCategory = "All";
    this.getCurrentDate();
    this.hospitalName = Helper_Class.getInfo().hospitals[0].hptl_name;
    this.hospitalLocation = Helper_Class.getInfo().hospitals[0].location_desc;
    this.hospitalCity = Helper_Class.getInfo().hospitals[0].city_desc;
    this.hospitalState = Helper_Class.getInfo().hospitals[0].state_desc;
    this.hospitalCountry = Helper_Class.getInfo().hospitals[0].country_desc;
    this.hospitalPhNo = Helper_Class.getInfo().hospitals[0].telephone;
    this.hospitalAddr = Helper_Class.getInfo().hospitals[0].address2 != undefined ? Helper_Class.getInfo().hospitals[0].address1 + "," + Helper_Class.getInfo().hospitals[0].address2 : Helper_Class.getInfo().hospitals[0].address1;
    this.hospitalLogo = ipaddress.Ip_with_img_address + Helper_Class.getInfo().bill_print_logo;
    this.printlogostyle = Helper_Class.getInfo().bill_print_logo_style;
    this.print_template = Helper_Class.getInfo().bill_print_template;
    console.log(this.print_template);
    this.bill_head = Helper_Class.getInfo().bill_heading;
    this.printTemplate = "logowithname";
    this.hospId = Helper_Class.getInfo().hptl_clinic_id;
    this.print_size = Helper_Class.getInfo().bill_print_paper;

    if (FrontDesk_Helper.getmodules() != null && FrontDesk_Helper.getmodules() != undefined) {
      for (var i = 0; i < FrontDesk_Helper.getmodules().length; i++) {
        if (FrontDesk_Helper.getmodules()[i].module_id == "19") {
          if (FrontDesk_Helper.getmodules()[i].edit == "1" ) {
            this.editbuttondie = true;
          }
          if (FrontDesk_Helper.getmodules()[i].create == "1"  ) {
            this.newbuttondie = true;
          }
          if (FrontDesk_Helper.getmodules()[i].delete == "1") {
            this.deletebuttondie = true;
          }
          if (FrontDesk_Helper.getmodules()[i].print == "1") {
            this.printbuttondie = true; 
          }
          if (FrontDesk_Helper.getmodules()[i].view == "1" ) {
            this.viewbuttondie = true;
          }
        }
      }
    } 

    if (Helper_Class.getInfo().user_type == 'Admin') {
      this.editbuttondie = true;
      this.newbuttondie = true;
      this.deletebuttondie = true;
      this.printbuttondie = true;
      this.viewbuttondie = true;
    }

    if (Helper_Class.getInfo().first_name != undefined) {
      this.logUserName = Helper_Class.getInfo().first_name;
      if (Helper_Class.getInfo().last_name != undefined) {
        this.logUserName = Helper_Class.getInfo().first_name+" "+Helper_Class.getInfo().last_name;
      }
    }
  }

  fnameToUpper(){
    if(this.clntName != undefined){
      this.clntName = this.clntName.toLocaleUpperCase();
    }
  }

  mnameToUpper(){
    if(this.middleName != undefined){
      this.middleName = this.middleName.toLocaleUpperCase();
    }
  }

  lnameToUpper(){
    if(this.lastName != undefined){
      this.lastName = this.lastName.toLocaleUpperCase();
    } 
  }

  getCurrentDate() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate',
     {
        country: ipaddress.country_code
      },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.getDate = obj.current_date.split('-');
          this.currentYear = this.getDate[0];
          this.currentTime = obj.current_time;
          this.currentDate = obj.current_date;
          this.fromDate = this.currentDate;
          this.toDate = this.currentDate;
          this.getAllAdvances();
        },
        error => {});
  }

  clientFilter() {
    this.ClientFilterList = [];
    this.clientList = [];
    this.clntName = this.clntName.toLocaleUpperCase();
    var clntname = this.clntName;

    if( this.clntName == ""){
      this.lastName = "";
      this.clientList = [];
      this.getAllAdvances();
    }else{
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'usercontroller/clntbynamea/',{"client_name": clntname},
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));         
            if (obj.clients != null) {
              this.clientList = [];
              this.ClientFilterList = obj.clients;
              for (var i = 0; i < this.ClientFilterList.length; i++) {
                this.clientList.push({
                  firstName : this.ClientFilterList[i].first_name,
                  lastName : this.ClientFilterList[i].last_name,
                  prov_id : this.ClientFilterList[i].prov_id
                });
              }
            }else{
              this.clientList = [];
            }
          },
          error => {});
    }
  }

  selectClient(data) {
    for (var i = 0; i < this.ClientFilterList.length; i++) {
      if(this.ClientFilterList[i].prov_id == data){
        this.clntName = this.ClientFilterList[i].first_name; 
        this.middleName = this.ClientFilterList[i].middle_name != undefined && this.ClientFilterList[i].middle_name != null && this.ClientFilterList[i].middle_name != ""  ? this.ClientFilterList[i].middle_name : "";
        this.lastName = this.ClientFilterList[i].last_name != undefined && this.ClientFilterList[i].last_name != null && this.ClientFilterList[i].last_name != ""  ? this.ClientFilterList[i].last_name : "";
        this.clientId = this.ClientFilterList[i].prov_id;
      }
    }
    this.clientList = [];
  }

  selectAdvanceCategory(){
    if(this.advanceCategory == "All"){
      this.advanceCategoryflag = true;
    } else{
      this.advanceCategoryflag = false;
      this.fromDate = this.currentDate;
      this.toDate = this.currentDate;
    }
  }

  getAllAdvances(){
    this.clientList = [];
    if(this.advanceCategory == undefined){
      this.advanceCategory = "All";
    }

    this.advanceList = [];
    var sentdata = {
      hptl_clinic_id: this.hospId,
        adv_cat_type: this.advanceCategory
    }

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/gaadv/',sentdata,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log("advanceList",obj);
          if (obj.advances != null ) {
            for(var i = 0; i < obj.advances.length; i++){
              var salutation;
              if (obj.advances[i].salutation != null) {
                salutation = obj.advances[i].salutation;
              }
              salutation = salutation != undefined ? salutation+"." : "";

              var clientname = "";
              if(obj.advances[i].middle_name != undefined && obj.advances[i].middle_name != null){
                clientname = encrypt_decript.Decript(obj.advances[i].first_name)+" "+encrypt_decript.Decript(obj.advances[i].middle_name)+" "+encrypt_decript.Decript(obj.advances[i].last_name);
              } else {
                clientname = encrypt_decript.Decript(obj.advances[i].first_name)+" "+encrypt_decript.Decript(obj.advances[i].last_name);
              }

              var clientAddr1 = "",clientAddr2 = "";                
              if( obj.advances[i].address2 != undefined && obj.advances[i].address1 != undefined){
                clientAddr1 = encrypt_decript.Decript(obj.advances[i].address1) + ",";
                if(obj.advances[i].address2 != " " && obj.advances[i].address2 != "null") {
                  if (encrypt_decript.Decript(obj.advances[i].address2) == "") {
                    clientAddr2 == "";
                  } else {
                    clientAddr2 = encrypt_decript.Decript(obj.advances[i].address2)+",";
                  }
                }             
              }else if (obj.advances[i].address1 != undefined) {
                clientAddr1 =  encrypt_decript.Decript(obj.advances[i].address1)+ ",";
              } else if (obj.advances[i].address2 != undefined) {
                clientAddr2  =  encrypt_decript.Decript(obj.advances[i].address2)+ ",";
              } else {
                clientAddr1 = "";
                clientAddr2 = "";
              }
            
              var gender;            
              if(obj.advances[i].gender != undefined && obj.advances[i].gender != "" )
                gender = encrypt_decript.Decript(obj.advances[i].gender);

              var gender_short;
              if ( obj.advances[i].gender != null &&  obj.advances[i].gender != undefined) {
                gender_short = encrypt_decript.Decript(obj.advances[i].gender);
                gender_short = gender_short[0];
                if (gender_short != undefined && gender_short[0] == "T") {
                  gender_short = "Tgen";
                }
              }

              var clocation = '',czipcode = '',ccity = '',cstate = '',ccountry = '';
              if (obj.advances[i].location != undefined) {
                clocation = obj.advances[i].location+",";
              }
    
              if (obj.advances[i].district != undefined) {
                ccity = obj.advances[i].district+"-";
              }

              if(obj.advances[i].zipcode  != undefined) {
                czipcode = encrypt_decript.Decript(obj.advances[i].zipcode)+",";
              }
    
              if (obj.advances[i].state != undefined) {
                cstate = obj.advances[i].state+"-";
              }   
              
              if(obj.advances[i].country != undefined) {
                ccountry = obj.advances[i].country+".";
              }

              if (obj.advances[i].barcode != undefined && obj.advances[i].barcode != null) {
                this.barcode_flag = true;
                this.barcode_url = ipaddress.Ip_with_img_address + obj.advances[i].barcode;
              }

              var appID = obj.advances[i].appointment_id != undefined ? obj.advances[i].appointment_id : "";
                var docName;
                if( obj.advances[i].dr_first_name != undefined){
                    if( obj.advances[i].dr_middle_name != undefined ){
                        docName = obj.advances[i].dr_first_name +" "+obj.advances[i].dr_middle_name +" "+obj.advances[i].dr_last_name;
                    }else  {
                      if( obj.advances[i].dr_last_name != undefined ){
                        docName = obj.advances[i].dr_first_name +" "+obj.advances[i].dr_last_name;
                      }else{
                        docName = obj.advances[i].dr_first_name
                      }
                    }
                }else{
                  docName = " ";
                }
              var payType = obj.advances[i].pay_desc != undefined ? obj.advances[i].pay_desc : " ";
              this.advanceList.push({
                patient_id:obj.advances[i].patient_id,
                salutation: salutation,
                client: clientname,
                advance: obj.advances[i].advance,
                balance: obj.advances[i].balance,
                created_date: Date_Formate(obj.advances[i].created_date),
                created_time: Time_Formate(obj.advances[i].created_time),
                age:obj.advances[i].age,
                gender:gender,
                gen_short: gender_short,
                clientAddr1: clientAddr1,
                clientAddr2: clientAddr2,
                location: clocation,
                city: ccity,
                state: cstate,
                country: ccountry,
                app_id : appID,
                zipcode: czipcode,
                paymentType : payType              
              });               
            }
            console.log(this.advanceList);
          }
        },error => {});
  }

  filter(){
    this.advanceList=[];
    if(this.advanceCategory == undefined){
      this.advanceCategory ="All";
    }
    
    if(this.advanceCategory != undefined && this.advanceCategory =="Date"){
      if(this.fromDate != undefined && this.toDate != undefined){
        this.filterFlag = false;
      } else{
        this.filterFlag = true;
      }
    } else{
      this.filterFlag = false;
    }

    var fdate;
    var tdate;
    if(this.fromDate != undefined && this.toDate != undefined){
      fdate = this.fromDate;
      tdate = this.toDate;
    }

    if(this.filterFlag == true){
      this.toastr.error(Message_data.selectFromAndToDate);
    } else{
      this.advanceList = [];
      var sentdata = {
        hptl_clinic_id: this.hospId,
        client_reg_id: this.clientId,
        adv_cat_type: this.advanceCategory,
        from_date: fdate,
        to_date: tdate,
      }
      
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'bill/gaadv/',sentdata,
        { headers: headers })
        .subscribe(
          response => {
            this.advanceList=[];
            var obj = JSON.parse(JSON.stringify(response));
            console.log("advanceList",obj);
            if (obj.advances != null) {
              for(var i=0; i<obj.advances.length;i++){
                var salutation;
                if (obj.advances[i].salutation != null) {
                  salutation = obj.advances[i].salutation;
                }

                salutation = salutation != undefined ? salutation+"." : "";
                var clientname;
                if(obj.advances[i].middle_name != undefined && obj.advances[i].middle_name != null){
                  clientname = encrypt_decript.Decript(obj.advances[i].first_name)+" "+encrypt_decript.Decript(obj.advances[i].middle_name)+" "+encrypt_decript.Decript(obj.advances[i].last_name);
                } else {
                  if(obj.advances[i].last_name != undefined && obj.advances[i].last_name != null){
                    clientname = encrypt_decript.Decript(obj.advances[i].first_name)+" "+encrypt_decript.Decript(obj.advances[i].last_name);
                  }else{
                    clientname = encrypt_decript.Decript(obj.advances[i].first_name);
                  }
                }

                var docName;
                if( obj.advances[i].dr_first_name != undefined){
                    if( obj.advances[i].dr_middle_name != undefined ){
                        docName = obj.advances[i].dr_first_name +" "+obj.advances[i].dr_middle_name +" "+obj.advances[i].dr_last_name;
                    }else  {
                      if( obj.advances[i].dr_last_name != undefined ){
                        docName = obj.advances[i].dr_first_name +" "+obj.advances[i].dr_last_name;
                      }else{
                        docName = obj.advances[i].dr_first_name
                      }
                    }
                }else{
                  docName = " ";
                }

                var clientAddr1 = "";
                var clientAddr2 = "";
                if (obj.advances[i].address2 != null && obj.advances[i].address2 != undefined && obj.advances[i].address1 != undefined) {
                  clientAddr1 = encrypt_decript.Decript(obj.advances[i].address1)+ ",";
                  if(obj.advances[i].address2 != " " && obj.advances[i].address2 != "null") {
                    if (encrypt_decript.Decript(obj.advances[i].address2) == "") {
                      clientAddr2 == "";
                    } else {
                      clientAddr2 = encrypt_decript.Decript(obj.advances[i].address2)+",";
                    }
                  }
                } else if (obj.advances[i].address1 != undefined) {
                  clientAddr1 = encrypt_decript.Decript(obj.advances[i].address1)+",";
                } else if (obj.advances[i].address2 != undefined) {
                  clientAddr2 = encrypt_decript.Decript(obj.advances[i].address2)+",";
                }    
                
                var clocation = '',czipcode = '',ccity = '',cstate = '',ccountry = '';
                if (obj.advances[i].location != undefined) {
                  clocation = obj.advances[i].location+",";
                }
      
                if (obj.advances[i].district != undefined) {
                  ccity = obj.advances[i].district+"-";
                }
  
                if(obj.advances[i].zipcode  != undefined) {
                  czipcode = encrypt_decript.Decript(obj.advances[i].zipcode)+",";
                }
      
                if (obj.advances[i].state != undefined) {
                  cstate = obj.advances[i].state+"-";
                }   
                
                if(obj.advances[i].country != undefined) {
                  ccountry = obj.advances[i].country+".";
                }

                var gender;
                if(obj.advances[i].gender != undefined && obj.advances[i].gender != "" )
                gender = encrypt_decript.Decript(obj.advances[i].gender);
                
                var gender_short;
                if ( obj.advances[i].gender != null &&  obj.advances[i].gender != undefined) {
                  gender_short = encrypt_decript.Decript(obj.advances[i].gender);
                  gender_short = gender_short[0];
                  if (gender_short != undefined && gender_short[0] == "T") {
                    gender_short = "Tgen";
                  }
                }

                var payType = obj.advances[i].pay_desc != undefined ? obj.advances[i].pay_desc : " ";
                this.advanceList.push({
                  patient_id:obj.advances[i].patient_id,
                  salutation: salutation,
                  client: clientname,
                  advance: obj.advances[i].advance,
                  balance: obj.advances[i].balance,
                  created_date: Date_Formate(obj.advances[i].created_date),
                  created_time: Time_Formate(obj.advances[i].created_time),
                  age:obj.advances[i].age,
                  gender:gender,
                  gen_short: gender_short,
                  clientAddr1:clientAddr1,
                  clientAddr2:clientAddr2,
                  location: clocation,
                  city: ccity,
                  state: cstate,
                  country: ccountry,
                  zipcode: czipcode,
                  app_id : obj.advances[i].appointment_id,
                  paymentType : payType
                });               
              }
              console.log("Advance List",this.advanceList);
            }
          },
          error => {});
    }
  }

  advCreate() {
    const dialogRef = this.dialog.open(AdvanceCreateComponent, {
      width: '650px',
      height: '500px',
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  print(advance: any) {
    var margin_top;
    let printContents, popupWin;    
    this.getBillsList = this.advanceList.filter((x: any) => x.app_id == advance.app_id); 
    if(this.getBillsList != undefined && this.getBillsList.length != 0) {
      this.getBillsList.forEach(a => {
        this.salutation = a.salutation;
        this.client = a.client;
        this.patient_id = a.patient_id;
        this.age = a.age;
        this.gen_short = a.gen_short;
        this.clientAddr1 = a.clientAddr1;
        this.clientAddr2 = a.clientAddr2;
        this.location = a.location;
        this.city = a.city;
        this.state = a.state;
        this.country = a.country;
        this.zipcode = a.zipcode;
      });
    }   
    console.log("GetBillList",this.getBillsList);
    this.totalAdvance = this.getBillsList.reduce((partialSum, a) => partialSum + parseFloat(a.advance), 0).toFixed(2);
    console.log("TotalAdvance",this.totalAdvance);
    this.numToWords = this.numToWords = convertNumberToWords(this.totalAdvance,ipaddress.country_code);
    this.totalBalance = this.getBillsList.reduce((partialSum, a) => partialSum + parseFloat(a.balance), 0).toFixed(2);
    console.log("TotalBalance",this.totalBalance);

    setTimeout(()=>{
      if (this.print_template != undefined && this.print_template == "banner") {
        printContents = this.printbanner.nativeElement.innerHTML; 
        margin_top = '0px';
      } else {
        printContents = this.printnoheader.nativeElement.innerHTML;
        margin_top = '122px';
      }
      popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
      popupWin.document.open();     
      popupWin.document.write(`
      <html>
      <head>
        <title>Bill</title>
        <link rel="stylesheet" media="screen" href="">
        <style>                        
          @page {
            size: auto;
            margin-top: ${margin_top};
            margin-right: 0px;
            margin-left: 0px;
            margin-bottom: 0px;
            overflow: visible;
          } 
          * {
              font-size: 12px !important;
              line-height: 1 !important;
              page-break-inside: always;
              overflow: visible;
            }                                     
          @media print {   
            body {
              height: auto; /* Allow body height to adjust dynamically */
            }          
            .page-break {
              page-break-before: always; /* or page-break-after: always; */
              page-break-inside: always;
            }
          }           
          .address_visibility {
            visibility: hidden;
          }        
          table.report-container {
            page-break-after: always;
          }
          thead.report-header {
            display: table-header-group;
          }
          tfoot.report-footer {
            display: table-footer-group;
          }
          .print:last-child {
            page-break-after: auto;
          }
          .alignRight {
            text-align: right;
          }
          .col-print-1 {width:8%;  float:left;}
          .col-print-2 {width:16%; float:left;}
          .col-print-3 {width:25%; float:left;}
          .col-print-4 {width:33%; float:left;}
          .col-print-5 {width:42%; float:left;}
          .col-print-6 {width:50%; float:left;}
          .col-print-7 {width:58%; float:left;}
          .col-print-8 {width:66%; float:left;}
          .col-print-9 {width:75%; float:left;}
          .col-print-10 {width:83%; float:left;}
          .col-print-11 {width:92%; float:left;}
          .col-print-12 {width:100%; float:left;}
        </style>
      </head>
      <body onload="window.print(); window.onfocus=function(){ window.close();}">${printContents}</body>
    </html>`);
      popupWin.document.close();
    },500);  
  }  
    
}
