<div class="row" style="width:100%;margin:0 auto;">
   <div class="col-12">
      <mdb-card>
         <mdb-card-header class="bg-white ">
            <div class="headerCover">
               <div class="headerTilte app_list_header">
                  <h5 class="m-0" class="mainHeadingStyle">Outsource tests</h5>
               </div>
               <div class="headerButtons">
                  <img src="../../../assets/ui_icons/buttons/Back_button.svg" (click)="backClicked()"
                     class="saveimgbtn_inpatinfo " />
                  <img class="saveimgbtn_inpatinfo" (click)="saveTestDetails()"
                     src="../../../assets/ui_icons/buttons/save_button.svg" />
               </div>
            </div>
         </mdb-card-header>
         <mdb-card-body>
            <div class="nodata" *ngIf="test_flag">No data found</div>
            <div class="row">
               <div class="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12">
                  <div class="row" style="margin-bottom: 20px;margin-left: 5px;">
                     <h2 class="titletest" style="color: black;">{{oursource.centre_name}}</h2>
                  </div>
                  <div class="row">
                     <div class="row">
                        <div class="col-12 col-sm-6 col-xl-4 col-lg-4 col-md-4">
                           <div class="diagnosis_testgrid testtype ">
                              <p class="titletest"><strong>Laboratory tests</strong></p>
                              <input type="text" style="width: 80%; margin-left: 9px; margin-bottom: 10px;" class="ipcss" aria-label="Number"
                                 [(ngModel)]="LabtestName" (keyup)="filterLabArray(LabtestName)"
                                 placeholder="Search tests" matInput />
                              <div class="diag_test_content_cover p-3">
                                 <div *ngFor="let subtest of filteredLabArray" id="testlbel">
                                    <span *ngIf="subtest.checked == true" class="check_img"><img
                                          (click)="ChangeTestType(false,subtest.diag_tests_id, subtest.test_name, subtest.test_cat, subtest, subtest.test_type, 'labtest')"
                                          src="../../../assets/ui_icons/popup_tick_mark.svg" style="cursor: pointer;"
                                          width="12px" /></span>

                                    <span *ngIf="subtest.checked == false" class="check_img"><img
                                          (click)="ChangeTestType(true,subtest.diag_tests_id, subtest.test_name, subtest.test_cat, subtest, subtest.test_type, 'labtest')"
                                          src="../../../assets/ui_icons/yellow_box.svg" style="cursor: pointer;"
                                          width="12px" /></span>
                                    {{subtest.test_name}}
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-12 col-sm-6 col-xl-4 col-lg-4 col-md-4">
                           <div class="diagnosis_testgrid testtype ">
                              <p class="titletest"><strong>Radiology tests</strong></p>
                              <input type="text" style="width: 80%; margin-left: 9px; margin-bottom: 10px;" class="ipcss" aria-label="Number"
                                 [(ngModel)]="radiotestName" (keyup)="filterRadioArray(radiotestName)"
                                 placeholder="Search tests" matInput />
                              <div class="diag_test_content_cover p-3">
                                 <div *ngFor="let subtest of filteredRadioArray" id="testlbel">
                                    <span *ngIf="subtest.checked == true" class="check_img"><img
                                          (click)="ChangeTestType(false,subtest.diag_tests_id, subtest.test_name, subtest.test_cat, subtest, subtest.test_type, 'radiotest')"
                                          src="../../../assets/ui_icons/popup_tick_mark.svg" style="cursor: pointer;"
                                          width="12px" /></span>

                                    <span *ngIf="subtest.checked == false" class="check_img"><img
                                          (click)="ChangeTestType(true,subtest.diag_tests_id, subtest.test_name, subtest.test_cat, subtest, subtest.test_type, 'radiotest')"
                                          src="../../../assets/ui_icons/yellow_box.svg" style="cursor: pointer;"
                                          width="12px" /></span>
                                    {{subtest.test_name}}
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-12 col-sm-6 col-xl-4 col-lg-4 col-md-4">
                           <div class="diagnosis_testgrid testtype ">
                              <p class="titletest"><strong>Microbiology tests</strong></p>
                              <input type="text" class="ipcss" style="width: 80%; margin-left: 9px; margin-bottom: 10px;" aria-label="Number"
                                 [(ngModel)]="microtestName" (keyup)="filterMicroArray(microtestName)"
                                 placeholder="Search tests" matInput />
                              <div class="diag_test_content_cover p-3">
                                 <div *ngFor="let subtest of filteredMicroArray" id="testlbel">
                                    <span *ngIf="subtest.checked == true" class="check_img"><img
                                          (click)="ChangeTestType(false,subtest.diag_tests_id, subtest.test_name, subtest.test_cat, subtest, subtest.test_type, 'microtest')"
                                          src="../../../assets/ui_icons/popup_tick_mark.svg" style="cursor: pointer;"
                                          width="12px" /></span>

                                    <span *ngIf="subtest.checked == false" class="check_img"><img
                                          (click)="ChangeTestType(true,subtest.diag_tests_id, subtest.test_name, subtest.test_cat, subtest, subtest.test_type, 'microtest')"
                                          src="../../../assets/ui_icons/yellow_box.svg" style="cursor: pointer;"
                                          width="12px" /></span>
                                    {{subtest.test_name}}
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </mdb-card-body>
      </mdb-card>
   </div>
</div>