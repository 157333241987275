<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Referrals</h5>
          </div>
          <div class="headerButtons">
            <img *ngIf="saveFlag" src="../../../assets/ui_icons/buttons/save_button.svg" class="saveimgbtn_inpatinfo"
              (click)="saveReferral()" />
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="cover_div" style="padding-bottom: 13px;">
          <div class="header_lable">
            Referral details
          </div>
          <div class="content_cover">
            <div class="row">
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Name
                  <input class="ipcss" [(ngModel)]="name" required matInput>
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Mobile
                  <input class="ipcss" [(ngModel)]="contNumber" required maxlength="10"
                    onkeypress="return event.charCode >= 48 && event.charCode <= 57" matInput>
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" style="margin: 20px 0px;">
                <mat-label class="matlabel">
                  <img src="../../../assets/ui_icons/buttons/add_button.svg" class="saveimgbtn_inpatinfo"
                    (click)="addReferral()" />
                </mat-label>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div [hidden]="referalTableFlag" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            style="margin-top: 10px;">
            <div class="table-responsive dig_table_overflow">
              <table id="tbl" class="table table-hover table-dynamic table-sm">
                <tr>
                  <th><b>Name</b></th>
                  <th><b>Mobile</b></th>
                  <th colspan="2"><b>Action</b></th>
                </tr>
                <tr *ngFor="let referal of referalArray">
                  <td class="align_left">{{referal.name}}</td>
                  <td>{{referal.mobile}}</td>
                  <td>
                    <a (click)="editReferral(referal.name,referal.mobile)">
                      <img src="../../../assets/ui_icons/buttons/pencil_icon.svg" width="20" height="20"
                        style="margin-right: 10px;" /></a>
                    <a (click)="deleteReferral(referal.name,referal.mobile)">
                      <img src="../../../assets/ui_icons/buttons/trash_icon.svg" width="20" height="20" /></a>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div [hidden]="referalTableFlag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel">Message</mat-label>
            <textarea #myInput class="ipcss" [(ngModel)]="message" [maxlength]="maxChars" required></textarea>
            <mat-label class="count_style"> Characters entered: {{message.length}}/{{maxChars}}</mat-label>
          </div>
          <div [hidden]="referalTableFlag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel">Referral type</mat-label>
            <select disableOptionCentering class="ipcss" [(ngModel)]="refType">
              <option value="Doctor">Doctor</option>
              <option value="Patient">Patient</option>
              <option value="Pharmacy">Pharmacy</option>
              <option value="Diagnosis">Diagnosis</option>
            </select>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>