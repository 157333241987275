<div class="Details container" id="Begin_content_others" style="padding: 0 15px 0 15px; max-width: 100%;">
  <div class="col-12">
    <div class="row" style="margin-top: 15px;">
      <div class="col-1" style="width: fit-content;">
        <mat-label class="matlabel" style="position: relative;top: 6px;">Date : </mat-label>
      </div>
      <div class="col-8" style="padding-left: 0;">
        <input type="date" class="ipcss_date widthappt" id="appt_date" (change)="OnDateMatePicker(Appointment_Date)" [(ngModel)]="Appointment_Date"  #matInput style="width: 140px;">
      </div>
    </div>
  </div>
</div>
<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">

    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header" >
            <h5 class="m-0" class="mainHeadingStyle">Appointment List</h5>
          </div>
          <div class="headerButtons">
            <img src="../../../assets/ui_icons/buttons/cancel_button.svg" (click)="cancel()" width="85px" style="margin-right: 9px;"/>
            <img src="../../../assets/ui_icons/buttons/confirm_button.svg" (click)="Appointment_confirm()" width="85px" /> 
          </div> 
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <p class="nodata" *ngIf="appointment_list.length == 0">No Appointment(s) Found</p>
        <table *ngIf="appointment_list.length" mdbTable datatable [dtOptions]="dtOptions"
          class="table table-nowrap table-sm dataTable">
          <thead>
            <tr>
              <th>Image</th>
              <th>ID</th>
              <th>Name</th>
              <th>Age</th>
              <th>Mobile</th>
              <th>Session</th>
              <th>Token / Time</th>
              <th>Symptoms</th>
              <th>Status</th>
              <th style="width: 5%;">
                <mdb-checkbox [default]="true" [(ngModel)]="masterSelected" name="list_name" value="m1"
                  (change)="checkUncheckAll()"></mdb-checkbox>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let person of appointment_list; let i = index"
              (click)='Doctor_View(person,person.doc_app_id,person.client_reg_id,person.relation_id,person.sub_rel,person.spl,person.rel_count,person.rel_stat,person.rel_city,person.rel_loc,person.age,person.gender_data,person.dob_data,person.patient_name,person.medicare_name,person.hospital_name,person.rel_loc_desc,person.address1,person.address2,person.hptl_name,person.state_desc,person.city_desc,person.country_desc,person.rel_zipcode,person.hptl_clinic_id,person.app_data,person.spl_id,person.lastvisit,person.profile_image,person.relationship_name,person.contact,person.symptoms,person.ref_by,person.height,person.height_measure,person.weight,person.weight_measure)'>
              <td><img src="{{person.profile_image}}" height="40px" width="40px"
                  class="img-fluid z-depth-1 rounded-circle mr-1"></td>
              <td style="font-size: 12px;">{{ person.patient_id }}</td>
              <td style="font-size: 12px;text-align: left;">{{ person.patient_name }}</td>
              <td style="font-size: 12px;">{{ person.age }}</td>
              <td style="font-size: 12px;">{{ person.contact }}</td>
              <td style="font-size: 12px;">{{person.session}}</td>
              <td style="font-size: 12px;text-align: center;">{{ person.tot_app_count }}</td>
              <td style="font-size: 12px;">{{ person.symptoms }}</td>
              <td style="font-size: 12px;">
                <!-- <button id="appt_status1" [ngStyle]="{'height':height}"
                  [style.backgroundColor]="getBColor(person.status)" [style.color]="getColor(person.status)"
                  style="height: 27px;width: 100px;border: none;border-radius: 3%;"><Span>{{person.status}}</Span></button> -->
                  <div style="position: relative;top: 9px;">
                    <img *ngIf="person.status == 'Completed'" width="30px" height="auto" src="../../../assets/ui_icons/Completed_icon.svg" />
                    <img *ngIf="person.status == 'Cancel'"  width="30px" height="auto" src="../../../assets/ui_icons/cancel_icon.svg" />
                    <img *ngIf="person.status == 'Not visited'"  width="30px" height="auto" src="../../../assets/ui_icons/not_visited_icon.svg" />
                    <img *ngIf="person.status == 'Confirmed'"  width="30px" height="auto" src="../../../assets/ui_icons/confirmed_icon.svg" />
                    <img *ngIf="person.status == 'Open'"  width="30px" height="32px" src="../../../assets/ui_icons/opened_icon.svg" />
                    <p>{{person.status}}</p>
                  </div>
              </td>
              <td style="font-size: 12px;width: 5%;">
                <mdb-checkbox [default]="true" [(ngModel)]="person.isSelected" name="list_name" value="{{person.id}}"
                  (change)="isAllSelected(person.doc_app_id)" [disabled]="person.status != 'Open'"></mdb-checkbox>
              </td>
            </tr>
          </tbody>
        </table>

        <br><br><br>

      </mdb-card-body>
    </mdb-card>
  </div>
</div>