<div *ngIf="clinicalExam == 'min'">
    <mat-accordion class="mataccordion">
        <mat-expansion-panel class="expandpanel">
            <mat-expansion-panel-header class="exppanel width">
                <mat-panel-title class="title">
                    Ear
                </mat-panel-title>
            </mat-expansion-panel-header>
            <hr class="hr">

            <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div class="row">
                        <div [hidden]="Ota_hidden" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3">
                            <mat-label class="matlabel"><b>Otalgia</b></mat-label>
                        </div>
                        <div [hidden]="block_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2">
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="ota_left">Left
                            </mat-checkbox>
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="ota_right">Right
                            </mat-checkbox>
                        </div>
                        <div [hidden]="ota_dur_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
                            <mat-label class="matlabel">Duration</mat-label>
                            <div class="row">
                                <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                    <input class="ipcss" maxlength="5"
                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                        [(ngModel)]="ota_day_dur" matInput />
                                </div>
                                <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                                    <mat-select disableOptionCentering [(ngModel)]="ota_day_txt">
                                        <mat-option [ngValue]="Select">Select</mat-option>
                                        <mat-option value="day(s)">day(s)</mat-option>
                                        <mat-option value="week(s)">week(s)</mat-option>
                                        <mat-option value="month(s)">month(s)</mat-option>
                                    </mat-select>
                                </div>
                            </div>
                        </div>
                        <div [hidden]="ota_severe_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2">
                            <mat-label class="matlabel">Severity</mat-label>
                            <mat-select class="ipcss" disableOptionCentering [(ngModel)]="ota_severity">
                                <mat-option value="Mild">Mild</mat-option>
                                <mat-option value="Severe">Severe</mat-option>
                            </mat-select>
                        </div>
                        <div [hidden]="ota_disc_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2">
                            <mat-label class="matlabel">Frequency</mat-label>
                            <mat-select class="ipcss" [(ngModel)]="ota_discharge">
                                <mat-option value="Continuous">Continuous</mat-option>
                                <mat-option value="Intermittent">Intermittent</mat-option>
                            </mat-select>
                        </div>
                    </div>

                    <div class="row">
                        <div [hidden]="Ota_hidden" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3">
                            <mat-label class="matlabel"><b>Otorrhea</b></mat-label>
                        </div>
                        <div [hidden]="block_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2">
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="oto_left">Left
                            </mat-checkbox>
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="oto_right">Right
                            </mat-checkbox>
                        </div>
                        <div [hidden]="ota_dur_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
                            <mat-label class="matlabel">Duration</mat-label>
                            <div class="row">
                                <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                    <input class="ipcss" maxlength="5"
                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                        [(ngModel)]="oto_day_txt" matInput />
                                </div>
                                <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                                    <mat-select class="ipcss" disableOptionCentering [(ngModel)]="oto_day_dur">
                                        <mat-option [ngValue]="Select">Select</mat-option>
                                        <mat-option value="day(s)">day(s)</mat-option>
                                        <mat-option value="week(s)">week(s)</mat-option>
                                        <mat-option value="month(s)">month(s)</mat-option>
                                    </mat-select>
                                </div>
                            </div>
                        </div>
                        <div [hidden]="ota_severe_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2">
                            <mat-label class="matlabel">Severity</mat-label>
                            <mat-select class="ipcss" disableOptionCentering [(ngModel)]="oto_severity">
                                <mat-option value="Serious">Serious</mat-option>
                                <mat-option value="Purulent">Purulent</mat-option>
                                <mat-option value="Blood">Blood</mat-option>
                            </mat-select>
                        </div>
                        <div [hidden]="ota_disc_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2">
                            <mat-label class="matlabel">Drainage</mat-label>
                            <mat-select class="ipcss" [(ngModel)]="oto_discharge">
                                <mat-option value="Seropurulent">Seropurulent</mat-option>
                                <mat-option value="Serosanguinous">Serosanguinous</mat-option>
                            </mat-select>
                        </div>
                    </div>

                    <div class="row">
                        <div [hidden]="Ota_hidden" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3">
                            <mat-label class="matlabel"><b>Hearing loss</b></mat-label>
                        </div>
                        <div [hidden]="block_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2">
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="hear_left">Left
                            </mat-checkbox>
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="hear_right">Right
                            </mat-checkbox>
                        </div>
                        <div [hidden]="ota_dur_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
                            <mat-label class="matlabel">Duration</mat-label>
                            <div class="row">
                                <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                    <input class="ipcss" maxlength="5"
                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                        [(ngModel)]="hear_day_txt" matInput />
                                </div>
                                <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                                    <mat-select class="ipcss" disableOptionCentering [(ngModel)]="hear_day_dur">
                                        <mat-option [ngValue]="Select">Select</mat-option>
                                        <mat-option value="day(s)">day(s)</mat-option>
                                        <mat-option value="week(s)">week(s)</mat-option>
                                        <mat-option value="month(s)">month(s)</mat-option>
                                    </mat-select>
                                </div>
                            </div>
                        </div>
                        <div [hidden]="ota_severe_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2">
                            <mat-label class="matlabel">Severity of HL</mat-label>
                            <mat-select class="ipcss" disableOptionCentering [(ngModel)]="hear_freq">
                                <mat-option value="Normal">Normal</mat-option>
                                <mat-option value="Slight">Slight</mat-option>
                                <mat-option value="Mild">Mild</mat-option>
                                <mat-option value="Moderate">Moderate</mat-option>
                                <mat-option value="Moderately severe">Moderately severe</mat-option>
                                <mat-option value="Severe">Severe</mat-option>
                                <mat-option value="Profound">Profound</mat-option>
                            </mat-select>
                        </div>
                        <div [hidden]="ota_disc_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2">
                            <mat-label class="matlabel">Frequency</mat-label>
                            <mat-select class="ipcss" [(ngModel)]="hear_severity">
                                <mat-option value="Continuous">Continuous</mat-option>
                                <mat-option value="Fluctuant">Fluctuant</mat-option>
                            </mat-select>
                        </div>
                    </div>

                    <div class="row">
                        <div [hidden]="Ota_hidden" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3">
                            <mat-label class="matlabel"><b>Tinnitus</b></mat-label>
                        </div>
                        <div [hidden]="block_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2">
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="tin_left">Left
                            </mat-checkbox>
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="tin_right">Right
                            </mat-checkbox>
                        </div>
                        <div [hidden]="ota_dur_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
                            <mat-label class="matlabel">Duration</mat-label>
                            <div class="row">
                                <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                    <input class="ipcss" maxlength="5"
                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                        [(ngModel)]="tin_txt" matInput />
                                </div>
                                <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                                    <mat-select class="ipcss" disableOptionCentering [(ngModel)]="tin_sel">
                                        <mat-option [ngValue]="Select">Select</mat-option>
                                        <mat-option value="day(s)">day(s)</mat-option>
                                        <mat-option value="week(s)">week(s)</mat-option>
                                        <mat-option value="month(s)">month(s)</mat-option>
                                    </mat-select>
                                </div>
                            </div>
                        </div>

                        <div [hidden]="ota_severe_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2">
                            <mat-label class="matlabel">Frequency</mat-label>
                            <mat-select class="ipcss" [(ngModel)]="Freq_sel">
                                <mat-option value="Continuous">Continuous</mat-option>
                                <mat-option value="Intermittent">Intermittent</mat-option>
                            </mat-select>
                        </div>
                    </div>

                    <div class="row">
                        <div [hidden]="Ota_hidden" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3">
                            <mat-label class="matlabel"><b>Dizziness</b></mat-label>
                        </div>
                        <div [hidden]="block_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-5">
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="diz_true">True
                            </mat-checkbox>
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="diz_sway">Sway
                            </mat-checkbox>
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="diz_assloc">Assoc LOC
                            </mat-checkbox>
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="diz_assveg">Assoc veg
                                sx
                            </mat-checkbox>
                        </div>
                        <div [hidden]="ota_dur_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
                            <mat-label class="matlabel">Duration</mat-label>
                            <div class="row">
                                <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                    <input class="ipcss" maxlength="5"
                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                        [(ngModel)]="dizz_txt" matInput />
                                </div>
                                <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                                    <mat-select class="ipcss" disableOptionCentering [(ngModel)]="dizz_sel">
                                        <mat-option [ngValue]="Select">Select</mat-option>
                                        <mat-option value="day(s)">day(s)</mat-option>
                                        <mat-option value="week(s)">week(s)</mat-option>
                                        <mat-option value="month(s)">month(s)</mat-option>
                                    </mat-select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div [hidden]="Ota_hidden" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3">
                            <mat-label class="matlabel"><b>Mastoid tenderness</b></mat-label>
                        </div>
                        <div [hidden]="block_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2">
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="mast_left">Left
                            </mat-checkbox>
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="mast_right">Right
                            </mat-checkbox>
                        </div>
                        <div [hidden]="ota_dur_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
                            <mat-label class="matlabel">Duration</mat-label>
                            <div class="row">
                                <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                    <input class="ipcss" maxlength="5"
                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                        [(ngModel)]="mast_txt" matInput />
                                </div>
                                <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                                    <mat-select class="ipcss" disableOptionCentering [(ngModel)]="mast_sel">
                                        <mat-option [ngValue]="Select">Select</mat-option>
                                        <mat-option value="day(s)">day(s)</mat-option>
                                        <mat-option value="week(s)">week(s)</mat-option>
                                        <mat-option value="month(s)">month(s)</mat-option>
                                    </mat-select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div [hidden]="Ota_hidden" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                            <mat-label class="matlabel"><b>Notes</b></mat-label>
                            <textarea class="ipcss" [(ngModel)]="ear_notes" maxlength="250"></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>

    <mat-accordion class="mataccordion">
        <mat-expansion-panel class="expandpanel">
            <mat-expansion-panel-header class="exppanel width">
                <mat-panel-title class="title">
                    Nose
                </mat-panel-title>
            </mat-expansion-panel-header>
            <hr class="hr">

            <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div class="row">
                        <div [hidden]="Ota_hidden" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3">
                            <mat-label class="matlabel"><b>Blockage</b></mat-label>
                        </div>
                        <div [hidden]="block_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2">
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="block_left">Left
                            </mat-checkbox>
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="block_right">Right
                            </mat-checkbox>
                        </div>
                        <div [hidden]="ota_dur_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
                            <mat-label class="matlabel">Duration</mat-label>
                            <div class="row">
                                <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                    <input class="ipcss" maxlength="5"
                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                        [(ngModel)]="block_txt" matInput />
                                </div>
                                <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                                    <mat-select class="ipcss" disableOptionCentering [(ngModel)]="block_sel">
                                        <mat-option [ngValue]="Select">Select</mat-option>
                                        <mat-option value="day(s)">day(s)</mat-option>
                                        <mat-option value="week(s)">week(s)</mat-option>
                                        <mat-option value="month(s)">month(s)</mat-option>
                                    </mat-select>
                                </div>
                            </div>
                        </div>
                        <div [hidden]="ota_severe_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2">
                            <mat-label class="matlabel">Severity</mat-label>
                            <mat-select class="ipcss" disableOptionCentering [(ngModel)]="ota_severity_sel">
                                <mat-option value="Mild">Mild</mat-option>
                                <mat-option value="Severe">Severe</mat-option>
                            </mat-select>
                        </div>
                        <div [hidden]="ota_disc_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2">
                            <mat-label class="matlabel">Frequency</mat-label>
                            <mat-select class="ipcss" [(ngModel)]="seve_Freq_sel">
                                <mat-option value="Continuous">Continuous</mat-option>
                                <mat-option value="Intermittent">Intermittent</mat-option>
                            </mat-select>
                        </div>
                    </div>

                    <div class="row">
                        <div [hidden]="Ota_hidden" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3">
                            <mat-label class="matlabel"><b>Discharge</b></mat-label>
                        </div>
                        <div [hidden]="block_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2">
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="dis_left">Left
                            </mat-checkbox>
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="dis_right">Right
                            </mat-checkbox>
                        </div>
                        <div [hidden]="ota_dur_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
                            <mat-label class="matlabel">Duration</mat-label>
                            <div class="row">
                                <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                    <input class="ipcss" maxlength="5"
                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                        [(ngModel)]="dis_txt" matInput />
                                </div>
                                <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                                    <mat-select class="ipcss" disableOptionCentering [(ngModel)]="dis_sel">
                                        <mat-option [ngValue]="Select">Select</mat-option>
                                        <mat-option value="day(s)">day(s)</mat-option>
                                        <mat-option value="week(s)">week(s)</mat-option>
                                        <mat-option value="month(s)">month(s)</mat-option>
                                    </mat-select>
                                </div>
                            </div>
                        </div>
                        <div [hidden]="ota_severe_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2">
                            <mat-label class="matlabel">Discharge type</mat-label>
                            <mat-select class="ipcss" disableOptionCentering [(ngModel)]="dis_type">
                                <mat-option value="Watery">Watery</mat-option>
                                <mat-option value="Mucus">Mucus</mat-option>
                                <mat-option value="Blood stained">Blood stained</mat-option>
                                <mat-option value="Mucopurulent">Mucopurulent</mat-option>
                                <mat-option value="Purulent">Purulent</mat-option>
                            </mat-select>
                        </div>
                    </div>

                    <div class="row">
                        <div [hidden]="Ota_hidden" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3">
                            <mat-label class="matlabel"><b>Epistaixis</b></mat-label>
                        </div>
                        <div [hidden]="block_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2">
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="epitax_left">Left
                            </mat-checkbox>
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="epitax_right">Right
                            </mat-checkbox>
                        </div>
                        <div [hidden]="ota_dur_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
                            <mat-label class="matlabel">Duration</mat-label>
                            <div class="row">
                                <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                    <input class="ipcss" maxlength="5"
                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                        [(ngModel)]="epi_txt" matInput />
                                </div>
                                <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                                    <mat-select class="ipcss" disableOptionCentering [(ngModel)]="epi_sel">
                                        <mat-option [ngValue]="Select">Select</mat-option>
                                        <mat-option value="day(s)">day(s)</mat-option>
                                        <mat-option value="week(s)">week(s)</mat-option>
                                        <mat-option value="month(s)">month(s)</mat-option>
                                    </mat-select>
                                </div>
                            </div>
                        </div>
                        <div [hidden]="ota_severe_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2">
                            <mat-label class="matlabel">Discharge type</mat-label>
                            <mat-select class="ipcss" disableOptionCentering [(ngModel)]="dis_sel_type">
                                <mat-option value="Mild">Mild</mat-option>
                                <mat-option value="Moderate">Moderate</mat-option>
                                <mat-option value="Severe">Severe</mat-option>
                                <mat-option value="Traumatic">Traumatic</mat-option>
                                <mat-option value="Post nose pricking">Post nose pricking</mat-option>
                            </mat-select>
                        </div>
                    </div>

                    <div class="row">
                        <div [hidden]="Ota_hidden" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3">
                            <mat-label class="matlabel"><b>Nasal vestibulitis</b></mat-label>
                        </div>
                        <div [hidden]="block_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2">
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="nasal_left">Left
                            </mat-checkbox>
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="nasal_right">Right
                            </mat-checkbox>
                        </div>
                        <div [hidden]="ota_dur_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
                            <mat-label class="matlabel">Duration</mat-label>
                            <div class="row">
                                <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                    <input class="ipcss" maxlength="5"
                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                        [(ngModel)]="nasal_txt" matInput />
                                </div>
                                <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                                    <mat-select class="ipcss" disableOptionCentering [(ngModel)]="nasal_sel">
                                        <mat-option [ngValue]="Select">Select</mat-option>
                                        <mat-option value="day(s)">day(s)</mat-option>
                                        <mat-option value="week(s)">week(s)</mat-option>
                                        <mat-option value="month(s)">month(s)</mat-option>
                                    </mat-select>
                                </div>
                            </div>
                        </div>

                        <div [hidden]="block_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2">
                            <mat-checkbox color="primary" [(ngModel)]="nose_pick">H/o nose picking</mat-checkbox>
                        </div>
                    </div>

                    <div class="row">
                        <div [hidden]="Ota_hidden" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-2">
                            <mat-label class="matlabel"><b>Pain</b></mat-label>
                        </div>
                        <div [hidden]="Ota_hidden" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-2">
                            <mat-label class="matlabel"><b>Frontal</b></mat-label>
                        </div>
                        <div [hidden]="block_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2">
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="front_left">Left
                            </mat-checkbox>
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="front_right">Right
                            </mat-checkbox>
                        </div>
                        <div [hidden]="ota_dur_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
                            <mat-label class="matlabel">Duration</mat-label>
                            <div class="row">
                                <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                    <input class="ipcss" maxlength="5"
                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                        [(ngModel)]="front_txt" matInput />
                                </div>
                                <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                                    <mat-select class="ipcss" disableOptionCentering [(ngModel)]="front_sel">
                                        <mat-option [ngValue]="Select">Select</mat-option>
                                        <mat-option value="day(s)">day(s)</mat-option>
                                        <mat-option value="week(s)">week(s)</mat-option>
                                        <mat-option value="month(s)">month(s)</mat-option>
                                    </mat-select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div [hidden]="Ota_hidden" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3">
                            <mat-label class="matlabel"><b></b></mat-label>
                        </div>
                        <div [hidden]="Ota_hidden" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-2">
                            <mat-label class="matlabel"><b>Maxillary</b></mat-label>
                        </div>

                        <div [hidden]="block_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2">
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="maxi_left">Left
                            </mat-checkbox>
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="maxi_right">Right
                            </mat-checkbox>
                        </div>
                        <div [hidden]="ota_dur_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
                            <mat-label class="matlabel">Duration</mat-label>
                            <div class="row">
                                <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                    <input class="ipcss" maxlength="5"
                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                        [(ngModel)]="maxi_txt_8" matInput />
                                </div>
                                <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                                    <mat-select class="ipcss" disableOptionCentering [(ngModel)]="maxi_sel_8">
                                        <mat-option [ngValue]="Select">Select</mat-option>
                                        <mat-option value="day(s)">day(s)</mat-option>
                                        <mat-option value="week(s)">week(s)</mat-option>
                                        <mat-option value="month(s)">month(s)</mat-option>
                                    </mat-select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div [hidden]="Ota_hidden" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3">
                            <mat-label class="matlabel"><b></b></mat-label>
                        </div>
                        <div [hidden]="Ota_hidden" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-2">
                            <mat-label class="matlabel"><b>Root of nose</b></mat-label>
                        </div>

                        <div [hidden]="block_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2">
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="root_left">Left
                            </mat-checkbox>
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="root_right">Right
                            </mat-checkbox>
                        </div>
                        <div [hidden]="ota_dur_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
                            <mat-label class="matlabel">Duration</mat-label>
                            <div class="row">
                                <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                    <input class="ipcss" maxlength="5"
                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                        [(ngModel)]="root_txt" matInput />
                                </div>
                                <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                                    <mat-select class="ipcss" disableOptionCentering [(ngModel)]="root_sel">
                                        <mat-option [ngValue]="Select">Select</mat-option>
                                        <mat-option value="day(s)">day(s)</mat-option>
                                        <mat-option value="week(s)">week(s)</mat-option>
                                        <mat-option value="month(s)">month(s)</mat-option>
                                    </mat-select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div [hidden]="Ota_hidden" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3">
                            <mat-label class="matlabel"><b></b></mat-label>
                        </div>
                        <div [hidden]="Ota_hidden" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-2">
                            <mat-label class="matlabel"><b>Temporal</b></mat-label>
                        </div>

                        <div [hidden]="block_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2">
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="temp_left">Left
                            </mat-checkbox>
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="temp_right">Right
                            </mat-checkbox>
                        </div>
                        <div [hidden]="ota_dur_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
                            <mat-label class="matlabel">Duration</mat-label>
                            <div class="row">
                                <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                    <input class="ipcss" maxlength="5"
                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                        [(ngModel)]="temp_txt" matInput />
                                </div>
                                <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                                    <mat-select class="ipcss" disableOptionCentering [(ngModel)]="temp_sel">
                                        <mat-option [ngValue]="Select">Select</mat-option>
                                        <mat-option value="day(s)">day(s)</mat-option>
                                        <mat-option value="week(s)">week(s)</mat-option>
                                        <mat-option value="month(s)">month(s)</mat-option>
                                    </mat-select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div [hidden]="Ota_hidden" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3">
                            <mat-label class="matlabel"><b></b></mat-label>
                        </div>
                        <div [hidden]="Ota_hidden" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-2">
                            <mat-label class="matlabel"><b>Parietal</b></mat-label>
                        </div>

                        <div [hidden]="block_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2">
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="pari_left">Left
                            </mat-checkbox>
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="pari_right">Right
                            </mat-checkbox>
                        </div>
                        <div [hidden]="ota_dur_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
                            <mat-label class="matlabel">Duration</mat-label>
                            <div class="row">
                                <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                    <input class="ipcss" maxlength="5"
                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                        [(ngModel)]="pari_txt" matInput />
                                </div>
                                <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                                    <mat-select class="ipcss" disableOptionCentering [(ngModel)]="pari_sel">
                                        <mat-option [ngValue]="Select">Select</mat-option>
                                        <mat-option value="day(s)">day(s)</mat-option>
                                        <mat-option value="week(s)">week(s)</mat-option>
                                        <mat-option value="month(s)">month(s)</mat-option>
                                    </mat-select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div [hidden]="Ota_hidden" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3">
                            <mat-label class="matlabel"><b>Post nasal drip</b></mat-label>
                        </div>

                        <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-2">
                            <mat-label class="matlabel">Discharge</mat-label>
                            <mat-select class="ipcss" disableOptionCentering [(ngModel)]="post_dis_type">
                                <mat-option value="Mild">Mild</mat-option>
                                <mat-option value="Moderate">Moderate</mat-option>
                                <mat-option value="Blood stained">Blood stained</mat-option>
                                <mat-option value="Foul">Foul</mat-option>
                            </mat-select>
                        </div>

                        <div [hidden]="ota_dur_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
                            <mat-label class="matlabel">Duration</mat-label>
                            <div class="row">
                                <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                    <input class="ipcss" maxlength="5"
                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                        [(ngModel)]="post_txt" matInput />
                                </div>
                                <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                                    <mat-select class="ipcss" disableOptionCentering [(ngModel)]="post_sel">
                                        <mat-option [ngValue]="Select">Select</mat-option>
                                        <mat-option value="day(s)">day(s)</mat-option>
                                        <mat-option value="week(s)">week(s)</mat-option>
                                        <mat-option value="month(s)">month(s)</mat-option>
                                    </mat-select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div [hidden]="Ota_hidden" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3">
                            <mat-label class="matlabel"><b>Clear rhinorrhea</b></mat-label>
                        </div>

                        <div [hidden]="block_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2">
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="clear_left">Left
                            </mat-checkbox>
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="clear_right">Right
                            </mat-checkbox>
                        </div>

                        <div [hidden]="ota_dur_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
                            <mat-label class="matlabel">Duration</mat-label>
                            <div class="row">
                                <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                    <input class="ipcss" maxlength="5"
                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                        [(ngModel)]="clear_txt" matInput />
                                </div>
                                <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                                    <mat-select class="ipcss" disableOptionCentering [(ngModel)]="clear_sel">
                                        <mat-option [ngValue]="Select">Select</mat-option>
                                        <mat-option value="day(s)">day(s)</mat-option>
                                        <mat-option value="week(s)">week(s)</mat-option>
                                        <mat-option value="month(s)">month(s)</mat-option>
                                    </mat-select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div [hidden]="Ota_hidden" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3">
                            <mat-label class="matlabel"><b>Sneezing / Pruritus</b></mat-label>
                        </div>

                        <div [hidden]="ota_dur_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
                            <mat-label class="matlabel">Duration</mat-label>
                            <div class="row">
                                <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                    <input class="ipcss" maxlength="5"
                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                        [(ngModel)]="snez_txt" matInput />
                                </div>
                                <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                                    <mat-select class="ipcss" disableOptionCentering [(ngModel)]="snez_sel">
                                        <mat-option [ngValue]="Select">Select</mat-option>
                                        <mat-option value="day(s)">day(s)</mat-option>
                                        <mat-option value="week(s)">week(s)</mat-option>
                                        <mat-option value="month(s)">month(s)</mat-option>
                                    </mat-select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div [hidden]="Ota_hidden" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3">
                            <mat-label class="matlabel"><b>Associated skin pruritus</b></mat-label>
                        </div>

                        <div [hidden]="ota_dur_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
                            <mat-label class="matlabel">Duration</mat-label>
                            <div class="row">
                                <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                    <input class="ipcss" maxlength="5"
                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                        [(ngModel)]="skin_txt" matInput />
                                </div>
                                <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                                    <mat-select class="ipcss" disableOptionCentering [(ngModel)]="skin_sel">
                                        <mat-option [ngValue]="Select">Select</mat-option>
                                        <mat-option value="day(s)">day(s)</mat-option>
                                        <mat-option value="week(s)">week(s)</mat-option>
                                        <mat-option value="month(s)">month(s)</mat-option>
                                    </mat-select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div [hidden]="Ota_hidden" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3">
                            <mat-label class="matlabel"><b>Associated bronchial allergy</b></mat-label>
                        </div>

                        <div [hidden]="ota_dur_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
                            <mat-label class="matlabel">Duration</mat-label>
                            <div class="row">
                                <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                    <input class="ipcss" maxlength="5"
                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                        [(ngModel)]="bron_txt" matInput />
                                </div>
                                <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                                    <mat-select class="ipcss" disableOptionCentering [(ngModel)]="bron_sel">
                                        <mat-option [ngValue]="Select">Select</mat-option>
                                        <mat-option value="day(s)">day(s)</mat-option>
                                        <mat-option value="week(s)">week(s)</mat-option>
                                        <mat-option value="month(s)">month(s)</mat-option>
                                    </mat-select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div [hidden]="Ota_hidden" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                            <mat-label class="matlabel"><b>Notes</b></mat-label>
                            <textarea class="ipcss" [(ngModel)]="bron_ear_notes" maxlength="250"></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>

    <mat-accordion class="mataccordion">
        <mat-expansion-panel class="expandpanel">
            <mat-expansion-panel-header class="exppanel width">
                <mat-panel-title class="title">
                    Oral cavity, throat
                </mat-panel-title>
            </mat-expansion-panel-header>
            <hr class="hr">

            <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div class="row">
                        <div [hidden]="Ota_hidden" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3">
                            <mat-label class="matlabel"><b>Aphthous ulcers</b></mat-label>
                        </div>

                        <div [hidden]="ota_dur_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
                            <mat-label class="matlabel">Duration</mat-label>
                            <div class="row">
                                <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                    <input class="ipcss" maxlength="5"
                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                        [(ngModel)]="apht_txt" matInput />
                                </div>
                                <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                                    <mat-select class="ipcss" disableOptionCentering [(ngModel)]="apht_sel">
                                        <mat-option [ngValue]="Select">Select</mat-option>
                                        <mat-option value="day(s)">day(s)</mat-option>
                                        <mat-option value="week(s)">week(s)</mat-option>
                                        <mat-option value="month(s)">month(s)</mat-option>
                                    </mat-select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div [hidden]="Ota_hidden" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3">
                            <mat-label class="matlabel"><b>Dysphagia</b></mat-label>
                        </div>
                        <div [hidden]="block_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2">
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="dys_solid">Solid
                            </mat-checkbox>
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="dys_liquid">Liquid
                            </mat-checkbox>
                        </div>
                        <div [hidden]="ota_dur_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
                            <mat-label class="matlabel">Duration</mat-label>
                            <div class="row">
                                <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                    <input class="ipcss" maxlength="5"
                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                        [(ngModel)]="dys_txt" matInput />
                                </div>
                                <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                                    <mat-select class="ipcss" disableOptionCentering [(ngModel)]="dys_sel">
                                        <mat-option [ngValue]="Select">Select</mat-option>
                                        <mat-option value="day(s)">day(s)</mat-option>
                                        <mat-option value="week(s)">week(s)</mat-option>
                                        <mat-option value="month(s)">month(s)</mat-option>
                                    </mat-select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div [hidden]="Ota_hidden" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3">
                            <mat-label class="matlabel"><b>Odynophagia</b></mat-label>
                        </div>
                        <div [hidden]="block_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2">
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="odyno_solid">Solid
                            </mat-checkbox>
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="odyno_liquid">Liquid
                            </mat-checkbox>
                        </div>
                        <div [hidden]="ota_dur_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
                            <mat-label class="matlabel">Duration</mat-label>
                            <div class="row">
                                <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                    <input class="ipcss" maxlength="5"
                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                        [(ngModel)]="ody_txt" matInput />
                                </div>
                                <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                                    <mat-select class="ipcss" disableOptionCentering [(ngModel)]="ody_sel">
                                        <mat-option [ngValue]="Select">Select</mat-option>
                                        <mat-option value="day(s)">day(s)</mat-option>
                                        <mat-option value="week(s)">week(s)</mat-option>
                                        <mat-option value="month(s)">month(s)</mat-option>
                                    </mat-select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div [hidden]="Ota_hidden" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3">
                            <mat-label class="matlabel"><b>Hoarseness of voice</b></mat-label>
                        </div>

                        <div [hidden]="ota_dur_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
                            <mat-label class="matlabel">Duration</mat-label>
                            <div class="row">
                                <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                    <input class="ipcss" maxlength="5"
                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                        [(ngModel)]="hoar_dur_txt" matInput />
                                </div>
                                <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                                    <mat-select class="ipcss" disableOptionCentering [(ngModel)]="hoar_dur_sel">
                                        <mat-option [ngValue]="Select">Select</mat-option>
                                        <mat-option value="day(s)">day(s)</mat-option>
                                        <mat-option value="week(s)">week(s)</mat-option>
                                        <mat-option value="month(s)">month(s)</mat-option>
                                    </mat-select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div [hidden]="Ota_hidden" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3">
                            <mat-label class="matlabel"><b>Noisy breathing</b></mat-label>
                        </div>

                        <div [hidden]="ota_dur_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
                            <mat-label class="matlabel">Duration</mat-label>
                            <div class="row">
                                <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                    <input class="ipcss" maxlength="5"
                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                        [(ngModel)]="noisy_txt_dur" matInput />
                                </div>
                                <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                                    <mat-select class="ipcss" disableOptionCentering [(ngModel)]="noisy_sel_dur">
                                        <mat-option [ngValue]="Select">Select</mat-option>
                                        <mat-option value="day(s)">day(s)</mat-option>
                                        <mat-option value="week(s)">week(s)</mat-option>
                                        <mat-option value="month(s)">month(s)</mat-option>
                                    </mat-select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div [hidden]="Ota_hidden" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3">
                            <mat-label class="matlabel"><b>Stridor</b></mat-label>
                        </div>

                        <div [hidden]="ota_dur_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
                            <mat-label class="matlabel">Duration</mat-label>
                            <div class="row">
                                <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                    <input class="ipcss" maxlength="5"
                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                        [(ngModel)]="strid_txt_dur" matInput />
                                </div>
                                <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                                    <mat-select class="ipcss" disableOptionCentering [(ngModel)]="strid_sel_dur">
                                        <mat-option [ngValue]="Select">Select</mat-option>
                                        <mat-option value="day(s)">day(s)</mat-option>
                                        <mat-option value="week(s)">week(s)</mat-option>
                                        <mat-option value="month(s)">month(s)</mat-option>
                                    </mat-select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div [hidden]="Ota_hidden" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3">
                            <mat-label class="matlabel"><b>Stertor</b></mat-label>
                        </div>

                        <div [hidden]="ota_dur_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
                            <mat-label class="matlabel">Duration</mat-label>
                            <div class="row">
                                <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                    <input class="ipcss" maxlength="5"
                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                        [(ngModel)]="ster_txt_dur" matInput />
                                </div>
                                <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                                    <mat-select class="ipcss" disableOptionCentering [(ngModel)]="ster_sel_dur">
                                        <mat-option [ngValue]="Select">Select</mat-option>
                                        <mat-option value="day(s)">day(s)</mat-option>
                                        <mat-option value="week(s)">week(s)</mat-option>
                                        <mat-option value="month(s)">month(s)</mat-option>
                                    </mat-select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div [hidden]="Ota_hidden" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3">
                            <mat-label class="matlabel"><b>Snoring</b></mat-label>
                        </div>

                        <div [hidden]="ota_dur_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
                            <mat-label class="matlabel">Duration</mat-label>
                            <div class="row">
                                <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                    <input class="ipcss" maxlength="5"
                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                        [(ngModel)]="snorin_txt_dur" matInput />
                                </div>
                                <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                                    <mat-select class="ipcss" disableOptionCentering [(ngModel)]="snorin_sel_dur">
                                        <mat-option [ngValue]="Select">Select</mat-option>
                                        <mat-option value="day(s)">day(s)</mat-option>
                                        <mat-option value="week(s)">week(s)</mat-option>
                                        <mat-option value="month(s)">month(s)</mat-option>
                                    </mat-select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div [hidden]="Ota_hidden" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3">
                            <mat-label class="matlabel"><b>Mouth breathing</b></mat-label>
                        </div>

                        <div [hidden]="ota_dur_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
                            <mat-label class="matlabel">Duration</mat-label>
                            <div class="row">
                                <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                    <input class="ipcss" maxlength="5"
                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                        [(ngModel)]="mouth_txt_dur" matInput />
                                </div>
                                <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                                    <mat-select class="ipcss" disableOptionCentering [(ngModel)]="mouth_sel_dur">
                                        <mat-option [ngValue]="Select">Select</mat-option>
                                        <mat-option value="day(s)">day(s)</mat-option>
                                        <mat-option value="week(s)">week(s)</mat-option>
                                        <mat-option value="month(s)">month(s)</mat-option>
                                    </mat-select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div [hidden]="Ota_hidden" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3">
                            <mat-label class="matlabel"><b>GERD</b></mat-label>
                        </div>

                        <div [hidden]="ota_dur_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
                            <mat-label class="matlabel">Duration</mat-label>
                            <div class="row">
                                <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                    <input class="ipcss" maxlength="5"
                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                        [(ngModel)]="gred_txt_dur" matInput />
                                </div>
                                <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                                    <mat-select class="ipcss" disableOptionCentering [(ngModel)]="gred_sel_dur">
                                        <mat-option [ngValue]="Select">Select</mat-option>
                                        <mat-option value="day(s)">day(s)</mat-option>
                                        <mat-option value="week(s)">week(s)</mat-option>
                                        <mat-option value="month(s)">month(s)</mat-option>
                                    </mat-select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div [hidden]="Ota_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
                            <mat-label class="matlabel"><b>Oxygen Deficiency Hazard</b></mat-label>
                        </div>

                        <div [hidden]="block_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2">
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="oxy_good">Good
                            </mat-checkbox>
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="oxy_fair">Fair
                            </mat-checkbox>
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="oxy_bad">Bad
                            </mat-checkbox>
                        </div>
                    </div>

                    <div class="row">
                        <div [hidden]="Ota_hidden" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3">
                            <mat-label class="matlabel"><b>Tongue</b></mat-label>
                        </div>

                        <div [hidden]="block_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2">
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="tong_nor">Normal
                            </mat-checkbox>
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="tong_ulc">Ulcer
                            </mat-checkbox>
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="tong_coat">Coated
                            </mat-checkbox>
                        </div>
                    </div>

                    <div class="row">
                        <div [hidden]="Ota_hidden" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3">
                            <mat-label class="matlabel"><b>Retromolar trigone</b></mat-label>
                        </div>

                        <div [hidden]="block_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2">
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="retro_norm">Normal
                            </mat-checkbox>
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="retro_ulc">Ulcer
                            </mat-checkbox>
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="retro_grow">Coated
                            </mat-checkbox>
                        </div>
                    </div>

                    <div class="row">
                        <div [hidden]="Ota_hidden" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3">
                            <mat-label class="matlabel"><b>Lips</b></mat-label>
                        </div>

                        <div [hidden]="block_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2">
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="lip_norm">Normal
                            </mat-checkbox>
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="lip_ulc">Ulcer
                            </mat-checkbox>
                        </div>
                    </div>

                    <div class="row">
                        <div [hidden]="Ota_hidden" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3">
                            <mat-label class="matlabel"><b>Palate</b></mat-label>
                        </div>

                        <div [hidden]="block_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2">
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="palte_norm">Normal
                            </mat-checkbox>
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="palte_ulc">Ulcer
                            </mat-checkbox>
                        </div>
                    </div>

                    <div class="row">
                        <div [hidden]="Ota_hidden" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3">
                            <mat-label class="matlabel"><b>Fauces, Tonsils</b></mat-label>
                        </div>

                        <div [hidden]="block_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2">
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="fauces_norm">Normal
                            </mat-checkbox>
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="fauces_ulc">Ulcer
                            </mat-checkbox>
                        </div>
                    </div>

                    <div class="row">
                        <div [hidden]="Ota_hidden" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3">
                            <mat-label class="matlabel"><b>JDL Enlargement</b></mat-label>
                        </div>

                        <div [hidden]="block_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2">
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="jd_left">Left
                            </mat-checkbox>
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="jd_right">Right
                            </mat-checkbox>
                        </div>
                    </div>

                    <div class="row">
                        <div [hidden]="Ota_hidden" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3">
                            <mat-label class="matlabel"><b>ILS palsy</b></mat-label>
                        </div>

                        <div [hidden]="block_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2">
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="ils_left">Left
                            </mat-checkbox>
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="ils_right">Right
                            </mat-checkbox>
                        </div>
                    </div>

                    <div class="row">
                        <div [hidden]="Ota_hidden" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3">
                            <mat-label class="matlabel"><b>VC growth</b></mat-label>
                        </div>

                        <div [hidden]="block_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2">
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="vc_left">Left
                            </mat-checkbox>
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="vc_right">Right
                            </mat-checkbox>
                        </div>
                    </div>

                    <div class="row">
                        <div [hidden]="Ota_hidden" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3">
                            <mat-label class="matlabel"><b>FVC growth</b></mat-label>
                        </div>

                        <div [hidden]="block_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2">
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="fvc_left">Left
                            </mat-checkbox>
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="fvc_right">Right
                            </mat-checkbox>
                        </div>
                    </div>

                    <div class="row">
                        <div [hidden]="Ota_hidden" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3">
                            <mat-label class="matlabel"><b>Growths</b></mat-label>
                        </div>

                        <div [hidden]="block_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2">
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="grow_ton">tongue
                            </mat-checkbox>
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="grow_ver">vestibule
                            </mat-checkbox>
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="grow_flo">Floor
                            </mat-checkbox>
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="grow_rmt">RMT
                            </mat-checkbox>
                        </div>
                    </div>

                    <div class="row">
                        <div [hidden]="Ota_hidden" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3">
                            <mat-label class="matlabel"><b>Fauces</b></mat-label>
                        </div>

                        <div [hidden]="block_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2">
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="fa_left">Left
                            </mat-checkbox>
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="fa_right">Right
                            </mat-checkbox>
                        </div>
                    </div>

                    <div class="row">
                        <div [hidden]="Ota_hidden" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3">
                            <mat-label class="matlabel"><b>Vallecular</b></mat-label>
                        </div>

                        <div [hidden]="block_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2">
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="va_left">Left
                            </mat-checkbox>
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="va_right">Right
                            </mat-checkbox>
                        </div>
                    </div>

                    <div class="row">
                        <div [hidden]="Ota_hidden" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3">
                            <mat-label class="matlabel"><b>PF</b></mat-label>
                        </div>

                        <div [hidden]="block_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2">
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="pf_left">Left
                            </mat-checkbox>
                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="pf_right">Right
                            </mat-checkbox>
                        </div>
                    </div>

                    <div class="row">
                        <div [hidden]="Ota_hidden" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                            <mat-label class="matlabel"><b>Epiglottis</b></mat-label>
                        </div>
                        <div [hidden]="block_hidden" class="col-12 col-sm-6col-md-6 col-lg-6 col-xl-4">
                            <input class="ipcss" [(ngModel)]="va_epi" maxlength="250">
                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>

    <mat-accordion class="mataccordion">
        <mat-expansion-panel class="expandpanel">
            <mat-expansion-panel-header class="exppanel width">
                <mat-panel-title class="title">
                    Migraine
                </mat-panel-title>
            </mat-expansion-panel-header>
            <hr class="hr">

            <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div class="row">
                        <div [hidden]="mig_unilate_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2">
                            <mat-checkbox color="primary" [(ngModel)]="mig_unilate">Unilateral</mat-checkbox>
                        </div>

                        <div [hidden]="mig_uni_dur_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
                            <mat-label class="matlabel">Duration</mat-label>
                            <div class="row">
                                <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                    <input class="ipcss" maxlength="5"
                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                        [(ngModel)]="mig_uni_dur" matInput />
                                </div>
                                <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                                    <mat-select class="ipcss" disableOptionCentering [(ngModel)]="mig_uni_dur_day">
                                        <mat-option [ngValue]="Select">Select</mat-option>
                                        <mat-option value="day(s)">day(s)</mat-option>
                                        <mat-option value="week(s)">week(s)</mat-option>
                                        <mat-option value="month(s)">month(s)</mat-option>
                                    </mat-select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div [hidden]="mig_bilate_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2">
                            <mat-checkbox color="primary" [(ngModel)]="mig_bilate">Bilateral</mat-checkbox>
                        </div>

                        <div [hidden]="mig_bi_dur_hidden" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
                            <mat-label class="matlabel">Duration</mat-label>
                            <div class="row">
                                <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                    <input class="ipcss" maxlength="5"
                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                        [(ngModel)]="mig_bi_dur" matInput />
                                </div>
                                <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                                    <mat-select class="ipcss" disableOptionCentering [(ngModel)]="mig_bi_dur_day">
                                        <mat-option [ngValue]="Select">Select</mat-option>
                                        <mat-option value="day(s)">day(s)</mat-option>
                                        <mat-option value="week(s)">week(s)</mat-option>
                                        <mat-option value="month(s)">month(s)</mat-option>
                                    </mat-select>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>

<div *ngIf="clinicalExam == 'max'">
    <p class="tervys_heading">Examination</p>
    <mat-accordion class="mataccordion">
        <mat-expansion-panel class="expandpanel">
            <mat-expansion-panel-header class="exppanel width">
                <mat-panel-title class="title">
                    Ear
                </mat-panel-title>
            </mat-expansion-panel-header>
            <hr class="hr">

            <div class="row" style="margin-top: 5px;">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                            <div class="cover_div">
                                <div [hidden]="Ota_hidden" class="header_lable">
                                    <b>Otalgia</b>
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div [hidden]="block_hidden" class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                            <mat-checkbox class="checkbox_leftRight" color="primary" (change)="send_data()"
                                                [(ngModel)]="ota_left" [disabled]="client">Left</mat-checkbox>
                                            <mat-checkbox class="checkbox_leftRight" color="primary" (change)="send_data()"
                                                [(ngModel)]="ota_right" [disabled]="client">Right</mat-checkbox>
                                        </div>
                                        <div [hidden]="ota_dur_hidden" class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                            <mat-label class="matlabel">Duration</mat-label>
                                            <div class="row">
                                                <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                                    <input class="ipcss" maxlength="5" (change)="send_data()"
                                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                        [(ngModel)]="ota_day_dur" matInput [readonly]="client" />
                                                </div>
                                                <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                                                    <select class="ipcss" class="ipcss" disableOptionCentering (change)="send_data()"
                                                        [(ngModel)]="ota_day_txt" [disabled]="client">
                                                        <option [ngValue]="Select">Select</option>
                                                        <option value="day(s)">day(s)</option>
                                                        <option value="week(s)">week(s)</option>
                                                        <option value="month(s)">month(s)</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div [hidden]="ota_severe_hidden" class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            <mat-label class="matlabel">Severity</mat-label>
                                            <mat-select class="ipcss" disableOptionCentering [(ngModel)]="ota_severity" (ngModelChange)="send_data()"
                                                [disabled]="client">
                                                <mat-option value="Mild">Mild</mat-option>
                                                <mat-option value="Severe">Severe</mat-option>
                                            </mat-select>
                                        </div>
                                        <div [hidden]="ota_disc_hidden" class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            <mat-label class="matlabel">Frequency</mat-label>
                                            <mat-select class="ipcss" [(ngModel)]="ota_discharge" [disabled]="client" (ngModelChange)="send_data()">
                                                <mat-option value="Continuous">Continuous</mat-option>
                                                <mat-option value="Intermittent">Intermittent</mat-option>
                                            </mat-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <div class="cover_div">
                                <div [hidden]="Ota_hidden" class="header_lable">
                                    <b>Otorrhea</b>
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div [hidden]="block_hidden" class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="oto_left" (change)="send_data()"
                                                [disabled]="client">Left</mat-checkbox>
                                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="oto_right" (change)="send_data()"
                                                [disabled]="client">Right
                                            </mat-checkbox>
                                        </div>
                                        <div [hidden]="ota_dur_hidden" class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                            <mat-label class="matlabel">Duration</mat-label>
                                            <div class="row">
                                                <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                                    <input class="ipcss" maxlength="5" (change)="send_data()"
                                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                        [(ngModel)]="oto_day_txt" matInput [readonly]="client" />
                                                </div>
                                                <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                                                    <mat-select class="ipcss" disableOptionCentering [(ngModel)]="oto_day_dur" (ngModelChange)="send_data()"
                                                        [disabled]="client">
                                                        <mat-option [ngValue]="Select">Select</mat-option>
                                                        <mat-option value="day(s)">day(s)</mat-option>
                                                        <mat-option value="week(s)">week(s)</mat-option>
                                                        <mat-option value="month(s)">month(s)</mat-option>
                                                    </mat-select>
                                                </div>
                                            </div>
                                        </div>
                                        <div [hidden]="ota_severe_hidden" class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            <mat-label class="matlabel">Severity</mat-label>
                                            <mat-select class="ipcss" disableOptionCentering [(ngModel)]="oto_severity" (ngModelChange)="send_data()"
                                                [disabled]="client">
                                                <mat-option value="Serious">Serious</mat-option>
                                                <mat-option value="Purulent">Purulent</mat-option>
                                                <mat-option value="Blood">Blood</mat-option>
                                            </mat-select>
                                        </div>
                                        <div [hidden]="ota_disc_hidden" class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            <mat-label class="matlabel">Drainage</mat-label>
                                            <mat-select class="ipcss" [(ngModel)]="oto_discharge" [disabled]="client" (ngModelChange)="send_data()">
                                                <mat-option value="Seropurulent">Seropurulent</mat-option>
                                                <mat-option value="Serosanguinous">Serosanguinous</mat-option>
                                            </mat-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <div class="cover_div">
                                <div [hidden]="Ota_hidden" class="header_lable">
                                    <b>Hearing loss</b>
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div [hidden]="block_hidden" class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="hear_left" (change)="send_data()"
                                                [disabled]="client">Left</mat-checkbox>
                                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="hear_right" (change)="send_data()"
                                                [disabled]="client">Right
                                            </mat-checkbox>
                                        </div>
                                        <div [hidden]="ota_dur_hidden" class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                            <mat-label class="matlabel">Duration</mat-label>
                                            <div class="row">
                                                <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                                    <input class="ipcss" maxlength="5" (change)="send_data()"
                                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                        [(ngModel)]="hear_day_txt" matInput [readonly]="client" />
                                                </div>
                                                <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                                                    <mat-select class="ipcss" disableOptionCentering [(ngModel)]="hear_day_dur"
                                                        [disabled]="client" (ngModelChange)="send_data()">
                                                        <mat-option [ngValue]="Select">Select</mat-option>
                                                        <mat-option value="day(s)">day(s)</mat-option>
                                                        <mat-option value="week(s)">week(s)</mat-option>
                                                        <mat-option value="month(s)">month(s)</mat-option>
                                                    </mat-select>
                                                </div>
                                            </div>
                                        </div>
                                        <div [hidden]="ota_severe_hidden" class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            <mat-label class="matlabel">Severity of HL</mat-label>
                                            <mat-select class="ipcss" disableOptionCentering [(ngModel)]="hear_freq" (ngModelChange)="send_data()"
                                                [disabled]="client">
                                                <mat-option value="Normal">Normal</mat-option>
                                                <mat-option value="Slight">Slight</mat-option>
                                                <mat-option value="Mild">Mild</mat-option>
                                                <mat-option value="Moderate">Moderate</mat-option>
                                                <mat-option value="Moderately severe">Moderately severe</mat-option>
                                                <mat-option value="Severe">Severe</mat-option>
                                                <mat-option value="Profound">Profound</mat-option>
                                            </mat-select>
                                        </div>
                                        <div [hidden]="ota_disc_hidden" class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            <mat-label class="matlabel">Frequency</mat-label>
                                            <mat-select class="ipcss" [(ngModel)]="hear_severity" [disabled]="client" (ngModelChange)="send_data()"> 
                                                <mat-option value="Continuous">Continuous</mat-option>
                                                <mat-option value="Fluctuant">Fluctuant</mat-option>
                                            </mat-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <div class="cover_div">
                                <div [hidden]="Ota_hidden" class="header_lable">
                                    <b>Tinnitus</b>
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div [hidden]="block_hidden" class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="tin_left" (change)="send_data()"
                                                [disabled]="client">Left</mat-checkbox>
                                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="tin_right" (change)="send_data()"
                                                [disabled]="client">Right
                                            </mat-checkbox>
                                        </div>
                                        <div [hidden]="ota_dur_hidden" class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                            <mat-label class="matlabel">Duration</mat-label>
                                            <div class="row">
                                                <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                                    <input class="ipcss" maxlength="5" (change)="send_data()"
                                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                        [(ngModel)]="tin_txt" matInput [readonly]="client" />
                                                </div>
                                                <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                                                    <mat-select class="ipcss" disableOptionCentering [(ngModel)]="tin_sel" (ngModelChange)="send_data()"
                                                        [disabled]="client">
                                                        <mat-option [ngValue]="Select">Select</mat-option>
                                                        <mat-option value="day(s)">day(s)</mat-option>
                                                        <mat-option value="week(s)">week(s)</mat-option>
                                                        <mat-option value="month(s)">month(s)</mat-option>
                                                    </mat-select>
                                                </div>
                                            </div>
                                        </div>
                
                                        <div [hidden]="ota_severe_hidden" class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            <mat-label class="matlabel">Frequency</mat-label>
                                            <mat-select class="ipcss" [(ngModel)]="Freq_sel" [disabled]="client" (ngModelChange)="send_data()">
                                                <mat-option value="Continuous">Continuous</mat-option>
                                                <mat-option value="Intermittent">Intermittent</mat-option>
                                            </mat-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <div class="cover_div">
                                <div [hidden]="Ota_hidden" class="header_lable">
                                    <b>Dizziness</b>
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div [hidden]="block_hidden" class="col-12">
                                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="diz_true" (change)="send_data()"
                                                [disabled]="client">True</mat-checkbox>
                                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="diz_sway" (change)="send_data()"
                                                [disabled]="client">Sway</mat-checkbox>
                                            <mat-checkbox class="checkbox_leftRight" style="width: 100px !important;" color="primary" (change)="send_data()"
                                                [(ngModel)]="diz_assloc" [disabled]="client">Assoc LOC</mat-checkbox>
                                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="diz_assveg" (change)="send_data()"
                                                [disabled]="client">Assoc veg sx</mat-checkbox>
                                        </div>
                                        <div [hidden]="ota_dur_hidden" class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6" style="margin-top: 5px;">
                                            <mat-label class="matlabel">Duration</mat-label>
                                            <div class="row">
                                                <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                                    <input class="ipcss" maxlength="5" (change)="send_data()"
                                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                        [(ngModel)]="dizz_txt" matInput [readonly]="client" />
                                                </div>
                                                <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                                                    <mat-select class="ipcss" disableOptionCentering [(ngModel)]="dizz_sel" (ngModelChange)="send_data()"
                                                        [disabled]="client">
                                                        <mat-option [ngValue]="Select">Select</mat-option>
                                                        <mat-option value="day(s)">day(s)</mat-option>
                                                        <mat-option value="week(s)">week(s)</mat-option>
                                                        <mat-option value="month(s)">month(s)</mat-option>
                                                    </mat-select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <div class="cover_div" style="padding-bottom: 54px;">
                                <div [hidden]="Ota_hidden" class="header_lable">
                                    <b>Mastoid tenderness</b>
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div [hidden]="block_hidden" class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="mast_left" (change)="send_data()"
                                                [disabled]="client">Left</mat-checkbox>
                                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="mast_right" (change)="send_data()"
                                                [disabled]="client">Right
                                            </mat-checkbox>
                                        </div>
                                        <div [hidden]="ota_dur_hidden" class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                            <mat-label class="matlabel">Duration</mat-label>
                                            <div class="row">
                                                <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                                    <input class="ipcss" maxlength="5" (change)="send_data()"
                                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                        [(ngModel)]="mast_txt" matInput [readonly]="client" />
                                                </div>
                                                <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                                                    <mat-select class="ipcss" disableOptionCentering [(ngModel)]="mast_sel" (ngModelChange)="send_data()"
                                                        [disabled]="client">
                                                        <mat-option [ngValue]="Select">Select</mat-option>
                                                        <mat-option value="day(s)">day(s)</mat-option>
                                                        <mat-option value="week(s)">week(s)</mat-option>
                                                        <mat-option value="month(s)">month(s)</mat-option>
                                                    </mat-select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div [hidden]="Ota_hidden" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="margin-top: 5px;">
                            <mat-label class="matlabel"><b>Notes</b></mat-label>
                            <textarea class="ipcss" [(ngModel)]="ear_notes" maxlength="250" (change)="send_data()"
                                [readonly]="client"></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>

    <mat-accordion class="mataccordion">
        <mat-expansion-panel class="expandpanel">
            <mat-expansion-panel-header class="exppanel width">
                <mat-panel-title class="title">
                    Nose
                </mat-panel-title>
            </mat-expansion-panel-header>
            <hr class="hr">

            <div class="row" style="margin-top: 5px;">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                            <div class="cover_div">
                                <div [hidden]="Ota_hidden" class="header_lable">
                                    <b>Blockage</b>
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div [hidden]="block_hidden" class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="block_left" (change)="send_data()"
                                                [disabled]="client">Left
                                            </mat-checkbox>
                                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="block_right" (change)="send_data()"
                                                [disabled]="client">Right
                                            </mat-checkbox>
                                        </div>
                                        <div [hidden]="ota_dur_hidden" class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                            <mat-label class="matlabel">Duration</mat-label>
                                            <div class="row">
                                                <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                                    <input class="ipcss" maxlength="5"
                                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57" (change)="send_data()"
                                                        [(ngModel)]="block_txt" matInput [readonly]="client" />
                                                </div>
                                                <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                                                    <mat-select class="ipcss" disableOptionCentering [(ngModel)]="block_sel" (ngModelChange)="send_data()"
                                                        [disabled]="client">
                                                        <mat-option [ngValue]="Select">Select</mat-option>
                                                        <mat-option value="day(s)">day(s)</mat-option>
                                                        <mat-option value="week(s)">week(s)</mat-option>
                                                        <mat-option value="month(s)">month(s)</mat-option>
                                                    </mat-select>
                                                </div>
                                            </div>
                                        </div>
                                        <div [hidden]="ota_severe_hidden" class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            <mat-label class="matlabel">Severity</mat-label>
                                            <mat-select class="ipcss" disableOptionCentering [(ngModel)]="ota_severity_sel" (ngModelChange)="send_data()"
                                                [disabled]="client">
                                                <mat-option value="Mild">Mild</mat-option>
                                                <mat-option value="Severe">Severe</mat-option>
                                            </mat-select>
                                        </div>
                                        <div [hidden]="ota_disc_hidden" class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            <mat-label class="matlabel">Frequency</mat-label>
                                            <mat-select class="ipcss" [(ngModel)]="seve_Freq_sel" [disabled]="client" (ngModelChange)="send_data()">
                                                <mat-option value="Continuous">Continuous</mat-option>
                                                <mat-option value="Intermittent">Intermittent</mat-option>
                                            </mat-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                            <div class="cover_div">
                                <div [hidden]="Ota_hidden" class="header_lable">
                                    <b>Discharge</b>
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div [hidden]="block_hidden" class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="dis_left" (change)="send_data()"
                                                [disabled]="client">Left</mat-checkbox>
                                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="dis_right" (change)="send_data()"
                                                [disabled]="client">Right
                                            </mat-checkbox>
                                        </div>
                                        <div [hidden]="ota_dur_hidden" class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                            <mat-label class="matlabel">Duration</mat-label>
                                            <div class="row">
                                                <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                                    <input class="ipcss" maxlength="5" (change)="send_data()"
                                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                        [(ngModel)]="dis_txt" matInput [readonly]="client" />
                                                </div>
                                                <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                                    <mat-select class="ipcss" disableOptionCentering [(ngModel)]="dis_sel" (ngModelChange)="send_data()"
                                                        [disabled]="client">
                                                        <mat-option [ngValue]="Select">Select</mat-option>
                                                        <mat-option value="day(s)">day(s)</mat-option>
                                                        <mat-option value="week(s)">week(s)</mat-option>
                                                        <mat-option value="month(s)">month(s)</mat-option>
                                                    </mat-select>
                                                </div>
                                            </div>
                                        </div>
                                        <div [hidden]="ota_severe_hidden" class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                            <mat-label class="matlabel">Discharge type</mat-label>
                                            <mat-select class="ipcss" disableOptionCentering [(ngModel)]="dis_type" (ngModelChange)="send_data()" [disabled]="client">
                                                <mat-option value="Watery">Watery</mat-option>
                                                <mat-option value="Mucus">Mucus</mat-option>
                                                <mat-option value="Blood stained">Blood stained</mat-option>
                                                <mat-option value="Mucopurulent">Mucopurulent</mat-option>
                                                <mat-option value="Purulent">Purulent</mat-option>
                                            </mat-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                            <div class="cover_div">
                                <div [hidden]="Ota_hidden" class="header_lable">
                                    <b>Epistaixis</b>
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div [hidden]="block_hidden" class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="epitax_left" (change)="send_data()"
                                                [disabled]="client">Left
                                            </mat-checkbox>
                                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="epitax_right" (change)="send_data()"
                                                [disabled]="client">Right
                                            </mat-checkbox>
                                        </div>
                                        <div [hidden]="ota_dur_hidden" class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                            <mat-label class="matlabel">Duration</mat-label>
                                            <div class="row">
                                                <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                                    <input class="ipcss" maxlength="5" (change)="send_data()"
                                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                        [(ngModel)]="epi_txt" matInput [readonly]="client" />
                                                </div>
                                                <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                                                    <mat-select class="ipcss" disableOptionCentering [(ngModel)]="epi_sel" (ngModelChange)="send_data()"
                                                        [disabled]="client">
                                                        <mat-option [ngValue]="Select">Select</mat-option>
                                                        <mat-option value="day(s)">day(s)</mat-option>
                                                        <mat-option value="week(s)">week(s)</mat-option>
                                                        <mat-option value="month(s)">month(s)</mat-option>
                                                    </mat-select>
                                                </div>
                                            </div>
                                        </div>
                                        <div [hidden]="ota_severe_hidden" class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                            <mat-label class="matlabel">Discharge type</mat-label>
                                            <mat-select class="ipcss" disableOptionCentering [(ngModel)]="dis_sel_type" (ngModelChange)="send_data()"
                                                [disabled]="client">
                                                <mat-option value="Mild">Mild</mat-option>
                                                <mat-option value="Moderate">Moderate</mat-option>
                                                <mat-option value="Severe">Severe</mat-option>
                                                <mat-option value="Traumatic">Traumatic</mat-option>
                                                <mat-option value="Post nose pricking">Post nose pricking</mat-option>
                                            </mat-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                            <div class="cover_div" style="padding-bottom: 43px;">
                                <div [hidden]="Ota_hidden" class="header_lable">
                                    <b>Nasal vestibulitis</b>
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div [hidden]="block_hidden" class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="nasal_left" (change)="send_data()"
                                                [disabled]="client">Left
                                            </mat-checkbox>
                                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="nasal_right" (change)="send_data()"
                                                [disabled]="client">Right
                                            </mat-checkbox>
                                        </div>
                                        <div [hidden]="ota_dur_hidden" class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                            <mat-label class="matlabel">Duration</mat-label>
                                            <div class="row">
                                                <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                                    <input class="ipcss" maxlength="5" (change)="send_data()"
                                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                        [(ngModel)]="nasal_txt" matInput [readonly]="client" />
                                                </div>
                                                <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                                                    <mat-select class="ipcss" disableOptionCentering [(ngModel)]="nasal_sel" (ngModelChange)="send_data()"
                                                        [disabled]="client">
                                                        <mat-option [ngValue]="Select">Select</mat-option>
                                                        <mat-option value="day(s)">day(s)</mat-option>
                                                        <mat-option value="week(s)">week(s)</mat-option>
                                                        <mat-option value="month(s)">month(s)</mat-option>
                                                    </mat-select>
                                                </div>
                                            </div>
                                        </div>             
                                        <div [hidden]="block_hidden" class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                            <mat-checkbox color="primary" [(ngModel)]="nose_pick" [disabled]="client">H/o nose picking
                                            </mat-checkbox>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div class="cover_div">
                                <div [hidden]="Ota_hidden" class="header_lable">
                                    <b>Pain</b>
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="cover_div">
                                                <div [hidden]="Ota_hidden" class="header_lable">
                                                    <b>Frontal</b>
                                                </div>
                                                <div class="content_cover">
                                                    <div class="row">
                                                        <div [hidden]="block_hidden" class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="front_left" (change)="send_data()"
                                                                [disabled]="client">Left
                                                            </mat-checkbox>
                                                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="front_right" (change)="send_data()"
                                                                [disabled]="client">Right
                                                            </mat-checkbox>
                                                        </div>
                                                        <div [hidden]="ota_dur_hidden" class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                                            <mat-label class="matlabel">Duration</mat-label>
                                                            <div class="row">
                                                                <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                                                    <input class="ipcss" maxlength="5"
                                                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57" (change)="send_data()"
                                                                        [(ngModel)]="front_txt" matInput [readonly]="client" />
                                                                </div>
                                                                <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                                                                    <mat-select class="ipcss" disableOptionCentering [(ngModel)]="front_sel" (ngModelChange)="send_data()"
                                                                        [disabled]="client">
                                                                        <mat-option [ngValue]="Select">Select</mat-option>
                                                                        <mat-option value="day(s)">day(s)</mat-option>
                                                                        <mat-option value="week(s)">week(s)</mat-option>
                                                                        <mat-option value="month(s)">month(s)</mat-option>
                                                                    </mat-select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="cover_div">
                                                <div [hidden]="Ota_hidden" class="header_lable">
                                                    <b>Maxillary</b>
                                                </div>
                                                <div class="content_cover">
                                                    <div class="row">
                                                        <div [hidden]="block_hidden" class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="maxi_left" (change)="send_data()"
                                                                [disabled]="client">Left
                                                            </mat-checkbox>
                                                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="maxi_right" (change)="send_data()"
                                                                [disabled]="client">Right
                                                            </mat-checkbox>
                                                        </div>
                                                        <div [hidden]="ota_dur_hidden" class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                                            <mat-label class="matlabel">Duration</mat-label>
                                                            <div class="row">
                                                                <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                                                    <input class="ipcss" maxlength="5" (change)="send_data()"
                                                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                                        [(ngModel)]="maxi_txt_8" matInput [readonly]="client" />
                                                                </div>
                                                                <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                                                                    <mat-select class="ipcss" disableOptionCentering [(ngModel)]="maxi_sel_8" (ngModelChange)="send_data()"
                                                                        [disabled]="client">
                                                                        <mat-option [ngValue]="Select">Select</mat-option>
                                                                        <mat-option value="day(s)">day(s)</mat-option>
                                                                        <mat-option value="week(s)">week(s)</mat-option>
                                                                        <mat-option value="month(s)">month(s)</mat-option>
                                                                    </mat-select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="cover_div">
                                                <div [hidden]="Ota_hidden" class="header_lable">
                                                    <b>Root of nose</b>
                                                </div>
                                                <div class="content_cover">
                                                    <div class="row">
                                                        <div [hidden]="block_hidden" class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="root_left" (change)="send_data()"
                                                                [disabled]="client">Left
                                                            </mat-checkbox>
                                                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="root_right" (change)="send_data()"
                                                                [disabled]="client">Right
                                                            </mat-checkbox>
                                                        </div>
                                                        <div [hidden]="ota_dur_hidden" class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                                            <mat-label class="matlabel">Duration</mat-label>
                                                            <div class="row">
                                                                <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                                                    <input class="ipcss" maxlength="5" (change)="send_data()"
                                                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                                        [(ngModel)]="root_txt" matInput [readonly]="client" />
                                                                </div>
                                                                <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                                                                    <mat-select class="ipcss" disableOptionCentering [(ngModel)]="root_sel" (ngModelChange)="send_data()"
                                                                        [disabled]="client">
                                                                        <mat-option [ngValue]="Select">Select</mat-option>
                                                                        <mat-option value="day(s)">day(s)</mat-option>
                                                                        <mat-option value="week(s)">week(s)</mat-option>
                                                                        <mat-option value="month(s)">month(s)</mat-option>
                                                                    </mat-select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="cover_div">
                                                <div [hidden]="Ota_hidden" class="header_lable">
                                                    <b>Temporal</b>
                                                </div>
                                                <div class="content_cover">
                                                    <div class="row">
                                                        <div [hidden]="block_hidden" class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6"> 
                                                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="temp_left" (change)="send_data()"
                                                                [disabled]="client">Left
                                                            </mat-checkbox>
                                                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="temp_right" (change)="send_data()"
                                                                [disabled]="client">Right
                                                            </mat-checkbox>
                                                        </div>
                                                        <div [hidden]="ota_dur_hidden" class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                                            <mat-label class="matlabel">Duration</mat-label>
                                                            <div class="row">
                                                                <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                                                    <input class="ipcss" maxlength="5" (change)="send_data()"
                                                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                                        [(ngModel)]="temp_txt" matInput [readonly]="client" />
                                                                </div>
                                                                <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                                                                    <mat-select class="ipcss" disableOptionCentering [(ngModel)]="temp_sel" (ngModelChange)="send_data()"
                                                                        [disabled]="client">
                                                                        <mat-option [ngValue]="Select">Select</mat-option>
                                                                        <mat-option value="day(s)">day(s)</mat-option>
                                                                        <mat-option value="week(s)">week(s)</mat-option>
                                                                        <mat-option value="month(s)">month(s)</mat-option>
                                                                    </mat-select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="cover_div">
                                                <div [hidden]="Ota_hidden" class="header_lable">
                                                    <b>Parietal</b>
                                                </div>
                                                <div class="content_cover">
                                                    <div class="row">
                                                        <div [hidden]="block_hidden" class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="pari_left" (change)="send_data()"
                                                                [disabled]="client">Left
                                                            </mat-checkbox>
                                                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="pari_right" (change)="send_data()"
                                                                [disabled]="client">Right
                                                            </mat-checkbox>
                                                        </div>
                                                        <div [hidden]="ota_dur_hidden" class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                                            <mat-label class="matlabel">Duration</mat-label>
                                                            <div class="row">
                                                                <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                                                    <input class="ipcss" maxlength="5" (change)="send_data()"
                                                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                                        [(ngModel)]="pari_txt" matInput [readonly]="client" />
                                                                </div>
                                                                <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                                                                    <mat-select class="ipcss" disableOptionCentering [(ngModel)]="pari_sel" (ngModelChange)="send_data()"
                                                                        [disabled]="client">
                                                                        <mat-option [ngValue]="Select">Select</mat-option>
                                                                        <mat-option value="day(s)">day(s)</mat-option>
                                                                        <mat-option value="week(s)">week(s)</mat-option>
                                                                        <mat-option value="month(s)">month(s)</mat-option>
                                                                    </mat-select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <div class="cover_div">
                                <div [hidden]="Ota_hidden" class="header_lable">
                                    <b>Post nasal drip</b>
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            <mat-label class="matlabel">Discharge</mat-label>
                                            <mat-select class="ipcss" disableOptionCentering [(ngModel)]="post_dis_type" (ngModelChange)="send_data()"
                                                [disabled]="client">
                                                <mat-option value="Mild">Mild</mat-option>
                                                <mat-option value="Moderate">Moderate</mat-option>
                                                <mat-option value="Blood stained">Blood stained</mat-option>
                                                <mat-option value="Foul">Foul</mat-option>
                                            </mat-select>
                                        </div>
                                        <div [hidden]="ota_dur_hidden" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            <mat-label class="matlabel">Duration</mat-label>
                                            <div class="row">
                                                <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                                    <input class="ipcss" maxlength="5" (change)="send_data()"
                                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                        [(ngModel)]="post_txt" matInput [readonly]="client" />
                                                </div>
                                                <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                                                    <mat-select class="ipcss" disableOptionCentering [(ngModel)]="post_sel" (ngModelChange)="send_data()"
                                                        [disabled]="client">
                                                        <mat-option [ngValue]="Select">Select</mat-option>
                                                        <mat-option value="day(s)">day(s)</mat-option>
                                                        <mat-option value="week(s)">week(s)</mat-option>
                                                        <mat-option value="month(s)">month(s)</mat-option>
                                                    </mat-select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <div class="cover_div">
                                <div [hidden]="Ota_hidden" class="header_lable">
                                    <b>Clear rhinorrhea</b>
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div [hidden]="block_hidden" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="clear_left" (change)="send_data()"
                                                [disabled]="client">Left
                                            </mat-checkbox>
                                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="clear_right" (change)="send_data()"
                                                [disabled]="client">Right
                                            </mat-checkbox>
                                        </div>       
                                        <div [hidden]="ota_dur_hidden" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            <mat-label class="matlabel">Duration</mat-label>
                                            <div class="row">
                                                <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                                    <input class="ipcss" maxlength="5" (change)="send_data()"
                                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                        [(ngModel)]="clear_txt" matInput [readonly]="client" />
                                                </div>
                                                <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                                                    <mat-select class="ipcss" disableOptionCentering [(ngModel)]="clear_sel" (ngModelChange)="send_data()"
                                                        [disabled]="client">
                                                        <mat-option [ngValue]="Select">Select</mat-option>
                                                        <mat-option value="day(s)">day(s)</mat-option>
                                                        <mat-option value="week(s)">week(s)</mat-option>
                                                        <mat-option value="month(s)">month(s)</mat-option>
                                                    </mat-select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <div class="cover_div">
                                <div [hidden]="Ota_hidden" class="header_lable">
                                    <b>Sneezing / Pruritus</b>
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div [hidden]="ota_dur_hidden" class="col-12">
                                            <mat-label class="matlabel">Duration</mat-label>
                                            <div class="row">
                                                <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                                    <input class="ipcss" maxlength="5" (change)="send_data()"
                                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                        [(ngModel)]="snez_txt" matInput [readonly]="client" />
                                                </div>
                                                <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                                                    <mat-select class="ipcss" disableOptionCentering [(ngModel)]="snez_sel" (ngModelChange)="send_data()"
                                                        [disabled]="client">
                                                        <mat-option [ngValue]="Select">Select</mat-option>
                                                        <mat-option value="day(s)">day(s)</mat-option>
                                                        <mat-option value="week(s)">week(s)</mat-option>
                                                        <mat-option value="month(s)">month(s)</mat-option>
                                                    </mat-select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <div class="cover_div">
                                <div [hidden]="Ota_hidden" class="header_lable">
                                    <b>Associated skin pruritus</b>
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div [hidden]="ota_dur_hidden" class="col-12">
                                            <mat-label class="matlabel">Duration</mat-label>
                                            <div class="row">
                                                <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                                    <input class="ipcss" maxlength="5" (change)="send_data()"
                                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                        [(ngModel)]="skin_txt" matInput [readonly]="client" />
                                                </div>
                                                <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                                                    <mat-select class="ipcss" disableOptionCentering [(ngModel)]="skin_sel" (ngModelChange)="send_data()"
                                                        [disabled]="client">
                                                        <mat-option [ngValue]="Select">Select</mat-option>
                                                        <mat-option value="day(s)">day(s)</mat-option>
                                                        <mat-option value="week(s)">week(s)</mat-option>
                                                        <mat-option value="month(s)">month(s)</mat-option>
                                                    </mat-select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <div class="cover_div">
                                <div [hidden]="Ota_hidden" class="header_lable">
                                    <b>Associated bronchial allergy</b>
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div [hidden]="ota_dur_hidden" class="col-12">
                                            <mat-label class="matlabel">Duration</mat-label>
                                            <div class="row">
                                                <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                                    <input class="ipcss" maxlength="5" (change)="send_data()"
                                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                        [(ngModel)]="bron_txt" matInput [readonly]="client" />
                                                </div>
                                                <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                                                    <mat-select class="ipcss" disableOptionCentering [(ngModel)]="bron_sel" (ngModelChange)="send_data()"
                                                        [disabled]="client">
                                                        <mat-option [ngValue]="Select">Select</mat-option>
                                                        <mat-option value="day(s)">day(s)</mat-option>
                                                        <mat-option value="week(s)">week(s)</mat-option>
                                                        <mat-option value="month(s)">month(s)</mat-option>
                                                    </mat-select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div [hidden]="Ota_hidden" class="col-12">
                            <mat-label class="matlabel"><b>Notes</b></mat-label>
                            <textarea class="ipcss" [(ngModel)]="bron_ear_notes" maxlength="250" (change)="send_data()"
                                [readonly]="client"></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>

    <mat-accordion class="mataccordion">
        <mat-expansion-panel class="expandpanel">
            <mat-expansion-panel-header class="exppanel width">
                <mat-panel-title class="title">
                    Oral cavity, throat
                </mat-panel-title>
            </mat-expansion-panel-header>
            <hr class="hr">

            <div class="row" style="margin-top: 5px;">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                            <div class="cover_div">
                                <div [hidden]="Ota_hidden" class="header_lable">
                                    <b>Dysphagia</b>
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div [hidden]="block_hidden" class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-6">
                                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="dys_solid" (change)="send_data()"
                                                [disabled]="client">Solid
                                            </mat-checkbox>
                                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="dys_liquid" (change)="send_data()"
                                                [disabled]="client">Liquid
                                            </mat-checkbox>
                                        </div>
                                        <div [hidden]="ota_dur_hidden" class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-6">
                                            <mat-label class="matlabel">Duration</mat-label>
                                            <div class="row">
                                                <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                                    <input class="ipcss" maxlength="5" (change)="send_data()"
                                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                        [(ngModel)]="dys_txt" matInput [readonly]="client" />
                                                </div>
                                                <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                                                    <mat-select class="ipcss" disableOptionCentering [(ngModel)]="dys_sel" (ngModelChange)="send_data()"
                                                        [disabled]="client">
                                                        <mat-option [ngValue]="Select">Select</mat-option>
                                                        <mat-option value="day(s)">day(s)</mat-option>
                                                        <mat-option value="week(s)">week(s)</mat-option>
                                                        <mat-option value="month(s)">month(s)</mat-option>
                                                    </mat-select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                            <div class="cover_div">
                                <div [hidden]="Ota_hidden" class="header_lable">
                                    <b>Odynophagia</b>
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div [hidden]="block_hidden" class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="odyno_solid" (change)="send_data()"
                                                [disabled]="client">Solid
                                            </mat-checkbox>
                                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="odyno_liquid" (change)="send_data()"
                                                [disabled]="client">Liquid
                                            </mat-checkbox>
                                        </div>
                                        <div [hidden]="ota_dur_hidden" class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                            <mat-label class="matlabel">Duration</mat-label>
                                            <div class="row">
                                                <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                                    <input class="ipcss" maxlength="5" (change)="send_data()"
                                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                        [(ngModel)]="ody_txt" matInput [readonly]="client" />
                                                </div>
                                                <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                                                    <mat-select class="ipcss" disableOptionCentering [(ngModel)]="ody_sel" (ngModelChange)="send_data()"
                                                        [disabled]="client">
                                                        <mat-option [ngValue]="Select">Select</mat-option>
                                                        <mat-option value="day(s)">day(s)</mat-option>
                                                        <mat-option value="week(s)">week(s)</mat-option>
                                                        <mat-option value="month(s)">month(s)</mat-option>
                                                    </mat-select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <div class="cover_div">
                                <div [hidden]="Ota_hidden" class="header_lable">
                                    <b>Aphthous ulcers</b>
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div [hidden]="ota_dur_hidden" class="col-12">
                                            <mat-label class="matlabel">Duration</mat-label>
                                            <div class="row">
                                                <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                                    <input class="ipcss" maxlength="5" (change)="send_data()"
                                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                        [(ngModel)]="apht_txt" matInput [readonly]="client" />
                                                </div>
                                                <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                                                    <mat-select class="ipcss" disableOptionCentering [(ngModel)]="apht_sel" (ngModelChange)="send_data()"
                                                        [disabled]="client">
                                                        <mat-option [ngValue]="Select">Select</mat-option>
                                                        <mat-option value="day(s)">day(s)</mat-option>
                                                        <mat-option value="week(s)">week(s)</mat-option>
                                                        <mat-option value="month(s)">month(s)</mat-option>
                                                    </mat-select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <div class="cover_div">
                                <div [hidden]="Ota_hidden" class="header_lable">
                                    <b>Hoarseness of voice</b>
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div [hidden]="ota_dur_hidden" class="col-12">
                                            <mat-label class="matlabel">Duration</mat-label>
                                            <div class="row">
                                                <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                                    <input class="ipcss" maxlength="5" (change)="send_data()"
                                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                        [(ngModel)]="hoar_dur_txt" matInput [readonly]="client" />
                                                </div>
                                                <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                                                    <mat-select class="ipcss" disableOptionCentering [(ngModel)]="hoar_dur_sel" (ngModelChange)="send_data()"
                                                        [disabled]="client">
                                                        <mat-option [ngValue]="Select">Select</mat-option>
                                                        <mat-option value="day(s)">day(s)</mat-option>
                                                        <mat-option value="week(s)">week(s)</mat-option>
                                                        <mat-option value="month(s)">month(s)</mat-option>
                                                    </mat-select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <div class="cover_div">
                                <div [hidden]="Ota_hidden" class="header_lable">
                                    <b>Noisy breathing</b>
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div [hidden]="ota_dur_hidden" class="col-12">
                                            <mat-label class="matlabel">Duration</mat-label>
                                            <div class="row">
                                                <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                                    <input class="ipcss" maxlength="5" (change)="send_data()"
                                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                        [(ngModel)]="noisy_txt_dur" matInput [readonly]="client" />
                                                </div>
                                                <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                                                    <mat-select class="ipcss" disableOptionCentering [(ngModel)]="noisy_sel_dur" (ngModelChange)="send_data()"
                                                        [disabled]="client">
                                                        <mat-option [ngValue]="Select">Select</mat-option>
                                                        <mat-option value="day(s)">day(s)</mat-option>
                                                        <mat-option value="week(s)">week(s)</mat-option>
                                                        <mat-option value="month(s)">month(s)</mat-option>
                                                    </mat-select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <div class="cover_div">
                                <div [hidden]="Ota_hidden" class="header_lable">
                                    <b>Stridor</b>
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div [hidden]="ota_dur_hidden" class="col-12">
                                            <mat-label class="matlabel">Duration</mat-label>
                                            <div class="row">
                                                <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                                    <input class="ipcss" maxlength="5" (change)="send_data()"
                                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                        [(ngModel)]="strid_txt_dur" matInput [readonly]="client" />
                                                </div>
                                                <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                                                    <mat-select class="ipcss" disableOptionCentering [(ngModel)]="strid_sel_dur" (ngModelChange)="send_data()"
                                                        [disabled]="client">
                                                        <mat-option [ngValue]="Select">Select</mat-option>
                                                        <mat-option value="day(s)">day(s)</mat-option>
                                                        <mat-option value="week(s)">week(s)</mat-option>
                                                        <mat-option value="month(s)">month(s)</mat-option>
                                                    </mat-select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <div class="cover_div">
                                <div [hidden]="Ota_hidden" class="header_lable">
                                    <b>Stertor</b>
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div [hidden]="ota_dur_hidden" class="col-12">
                                            <mat-label class="matlabel">Duration</mat-label>
                                            <div class="row">
                                                <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                                    <input class="ipcss" maxlength="5" (change)="send_data()"
                                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                        [(ngModel)]="ster_txt_dur" matInput [readonly]="client" />
                                                </div>
                                                <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                                                    <mat-select class="ipcss" disableOptionCentering [(ngModel)]="ster_sel_dur" (ngModelChange)="send_data()"
                                                        [disabled]="client">
                                                        <mat-option [ngValue]="Select">Select</mat-option>
                                                        <mat-option value="day(s)">day(s)</mat-option>
                                                        <mat-option value="week(s)">week(s)</mat-option>
                                                        <mat-option value="month(s)">month(s)</mat-option>
                                                    </mat-select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <div class="cover_div">
                                <div [hidden]="Ota_hidden" class="header_lable">
                                    <b>Snoring</b>
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div [hidden]="ota_dur_hidden" class="col-12">
                                            <mat-label class="matlabel">Duration</mat-label>
                                            <div class="row">
                                                <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                                    <input class="ipcss" maxlength="5" (change)="send_data()"
                                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                        [(ngModel)]="snorin_txt_dur" matInput [readonly]="client" />
                                                </div>
                                                <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                                                    <mat-select class="ipcss" disableOptionCentering [(ngModel)]="snorin_sel_dur" (ngModelChange)="send_data()"
                                                        [disabled]="client"> 
                                                        <mat-option [ngValue]="Select">Select</mat-option>
                                                        <mat-option value="day(s)">day(s)</mat-option>
                                                        <mat-option value="week(s)">week(s)</mat-option>
                                                        <mat-option value="month(s)">month(s)</mat-option>
                                                    </mat-select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <div class="cover_div">
                                <div [hidden]="Ota_hidden" class="header_lable">
                                    <b>Mouth breathing</b>
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div [hidden]="ota_dur_hidden" class="col-12">
                                            <mat-label class="matlabel">Duration</mat-label>
                                            <div class="row">
                                                <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                                    <input class="ipcss" maxlength="5" (change)="send_data()"
                                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                        [(ngModel)]="mouth_txt_dur" matInput [readonly]="client" />
                                                </div>
                                                <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                                                    <mat-select class="ipcss" disableOptionCentering [(ngModel)]="mouth_sel_dur" (ngModelChange)="send_data()"
                                                        [disabled]="client"> 
                                                        <mat-option [ngValue]="Select">Select</mat-option>
                                                        <mat-option value="day(s)">day(s)</mat-option>
                                                        <mat-option value="week(s)">week(s)</mat-option>
                                                        <mat-option value="month(s)">month(s)</mat-option>
                                                    </mat-select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <div class="cover_div">
                                <div [hidden]="Ota_hidden" class="header_lable">
                                    <b>GERD</b>
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div [hidden]="ota_dur_hidden" class="col-12">
                                            <mat-label class="matlabel">Duration</mat-label>
                                            <div class="row">
                                                <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                                    <input class="ipcss" maxlength="5" (change)="send_data()"
                                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                        [(ngModel)]="gred_txt_dur" matInput [readonly]="client" />
                                                </div>
                                                <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                                                    <mat-select class="ipcss" disableOptionCentering [(ngModel)]="gred_sel_dur" (ngModelChange)="send_data()"
                                                        [disabled]="client">
                                                        <mat-option [ngValue]="Select">Select</mat-option>
                                                        <mat-option value="day(s)">day(s)</mat-option>
                                                        <mat-option value="week(s)">week(s)</mat-option>
                                                        <mat-option value="month(s)">month(s)</mat-option>
                                                    </mat-select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <div class="cover_div">
                                <div [hidden]="Ota_hidden" class="header_lable">
                                    <b>Oxygen Deficiency Hazard</b>
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div [hidden]="block_hidden" class="col-12">
                                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="oxy_good" (change)="send_data()"
                                                [disabled]="client">Good
                                            </mat-checkbox>
                                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="oxy_fair" (change)="send_data()"
                                                [disabled]="client">Fair
                                            </mat-checkbox> 
                                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="oxy_bad" (change)="send_data()"
                                                [disabled]="client">Bad
                                            </mat-checkbox>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <div class="cover_div">
                                <div [hidden]="Ota_hidden" class="header_lable">
                                    <b>Tongue</b>
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div [hidden]="block_hidden" class="col-12">
                                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="tong_nor" (change)="send_data()"
                                                [disabled]="client">Normal
                                            </mat-checkbox>
                                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="tong_ulc" (change)="send_data()"
                                                [disabled]="client">Ulcer
                                            </mat-checkbox>
                                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="tong_coat" (change)="send_data()"
                                                [disabled]="client">Coated
                                            </mat-checkbox>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <div class="cover_div">
                                <div [hidden]="Ota_hidden" class="header_lable">
                                    <b>Retromolar trigone</b>
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div [hidden]="block_hidden" class="col-12"> 
                                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="retro_norm" (change)="send_data()"
                                                [disabled]="client">Normal
                                            </mat-checkbox>
                                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="retro_ulc" (change)="send_data()"
                                                [disabled]="client">Ulcer
                                            </mat-checkbox>
                                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="retro_grow" (change)="send_data()"
                                                [disabled]="client">Coated
                                            </mat-checkbox>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <div class="cover_div">
                                <div [hidden]="Ota_hidden" class="header_lable">
                                    <b>Lips</b>
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div [hidden]="block_hidden" class="col-12">
                                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="lip_norm" (change)="send_data()"
                                                [disabled]="client">Normal
                                            </mat-checkbox>
                                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="lip_ulc" (change)="send_data()"
                                                [disabled]="client">Ulcer
                                            </mat-checkbox>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <div class="cover_div">
                                <div [hidden]="Ota_hidden" class="header_lable">
                                    <b>Palate</b>
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div [hidden]="block_hidden" class="col-12">
                                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="palte_norm" (change)="send_data()"
                                                [disabled]="client">Normal
                                            </mat-checkbox>
                                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="palte_ulc" (change)="send_data()"
                                                [disabled]="client">Ulcer
                                            </mat-checkbox>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <div class="cover_div">
                                <div [hidden]="Ota_hidden" class="header_lable">
                                    <b>Fauces, Tonsils</b>
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div [hidden]="block_hidden" class="col-12">
                                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="fauces_norm" (change)="send_data()"
                                                [disabled]="client">Normal
                                            </mat-checkbox>
                                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="fauces_ulc" (change)="send_data()"
                                                [disabled]="client">Ulcer
                                            </mat-checkbox>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <div class="cover_div">
                                <div [hidden]="Ota_hidden" class="header_lable">
                                    <b>JDL Enlargement</b>
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div [hidden]="block_hidden" class="col-12">
                                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="jd_left" (change)="send_data()"
                                                [disabled]="client">Left
                                            </mat-checkbox>
                                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="jd_right" (change)="send_data()"
                                                [disabled]="client">Right
                                            </mat-checkbox>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <div class="cover_div">
                                <div [hidden]="Ota_hidden" class="header_lable">
                                    <b>ILS palsy</b>
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div [hidden]="block_hidden" class="col-12">
                                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="ils_left" (change)="send_data()"
                                                [disabled]="client">Left
                                            </mat-checkbox>  
                                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="ils_right" (change)="send_data()"
                                                [disabled]="client">Right
                                            </mat-checkbox>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <div class="cover_div">
                                <div [hidden]="Ota_hidden" class="header_lable">
                                    <b>VC growth</b>
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div [hidden]="block_hidden" class="col-12">
                                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="vc_left" (change)="send_data()"
                                                [disabled]="client">Left
                                            </mat-checkbox>
                                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="vc_right" (change)="send_data()"
                                                [disabled]="client">Right
                                            </mat-checkbox>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <div class="cover_div">
                                <div [hidden]="Ota_hidden" class="header_lable">
                                    <b>FVC growth</b>
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div [hidden]="block_hidden" class="col-12">
                                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="fvc_left" (change)="send_data()"
                                                [disabled]="client">Left
                                            </mat-checkbox>
                                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="fvc_right" (change)="send_data()"
                                                [disabled]="client">Right
                                            </mat-checkbox>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <div class="cover_div">
                                <div [hidden]="Ota_hidden" class="header_lable">
                                    <b>Growths</b>
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div [hidden]="block_hidden" class="col-12">
                                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="grow_ton" (change)="send_data()"
                                                [disabled]="client">tongue
                                            </mat-checkbox>
                                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="grow_ver" (change)="send_data()"
                                                [disabled]="client" style="width:85px">vestibule
                                            </mat-checkbox>
                                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="grow_flo" (change)="send_data()"
                                                [disabled]="client">Floor
                                            </mat-checkbox>
                                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="grow_rmt" (change)="send_data()"
                                                [disabled]="client">RMT
                                            </mat-checkbox>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <div class="cover_div">
                                <div [hidden]="Ota_hidden" class="header_lable">
                                    <b>Fauces</b>
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div [hidden]="block_hidden" class="col-12">
                                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="fa_left" (change)="send_data()"
                                                [disabled]="client">Left
                                            </mat-checkbox>
                                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="fa_right" (change)="send_data()"
                                                [disabled]="client">Right
                                            </mat-checkbox>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <div class="cover_div">
                                <div [hidden]="Ota_hidden" class="header_lable">
                                    <b>Vallecular</b>
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div [hidden]="block_hidden" class="col-12">
                                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="va_left" (change)="send_data()"
                                                [disabled]="client">Left
                                            </mat-checkbox>
                                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="va_right" (change)="send_data()"
                                                [disabled]="client">Right
                                            </mat-checkbox>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <div class="cover_div">
                                <div [hidden]="Ota_hidden" class="header_lable">
                                    <b>PF</b>
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div [hidden]="block_hidden" class="col-12">
                                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="pf_left" (change)="send_data()"
                                                [disabled]="client">Left
                                            </mat-checkbox>
                                            <mat-checkbox class="checkbox_leftRight" color="primary" [(ngModel)]="pf_right" (change)="send_data()"
                                                [disabled]="client">Right
                                            </mat-checkbox>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <div class="cover_div">
                                <div [hidden]="Ota_hidden" class="header_lable">
                                    <b>Epiglottis</b>
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div [hidden]="block_hidden" class="col-12">
                                            <input class="ipcss" [(ngModel)]="va_epi" maxlength="250" [readonly]="client" (change)="send_data()">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>

    <mat-accordion class="mataccordion">
        <mat-expansion-panel class="expandpanel">
            <mat-expansion-panel-header class="exppanel width">
                <mat-panel-title class="title">
                    Migraine
                </mat-panel-title>
            </mat-expansion-panel-header>
            <hr class="hr">

            <div class="row" style="margin-top: 5px;">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                            <div class="cover_div">
                                <div class="content_cover">
                                    <div class="row">
                                        <div [hidden]="mig_unilate_hidden" class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                            <mat-checkbox color="primary" [(ngModel)]="mig_unilate" [disabled]="client" (change)="send_data()">Unilateral
                                            </mat-checkbox>
                                        </div>
                
                                        <div [hidden]="mig_uni_dur_hidden" class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                            <mat-label class="matlabel">Duration</mat-label>
                                            <div class="row">
                                                <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                                    <input class="ipcss" maxlength="5" (change)="send_data()"
                                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                        [(ngModel)]="mig_uni_dur" matInput [readonly]="client" />
                                                </div>
                                                <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                                                    <mat-select class="ipcss" disableOptionCentering [(ngModel)]="mig_uni_dur_day" (ngModelChange)="send_data()"
                                                        [disabled]="client">
                                                        <mat-option [ngValue]="Select">Select</mat-option>
                                                        <mat-option value="day(s)">day(s)</mat-option>
                                                        <mat-option value="week(s)">week(s)</mat-option>
                                                        <mat-option value="month(s)">month(s)</mat-option>
                                                    </mat-select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                            <div class="cover_div">
                                <div class="content_cover">
                                    <div class="row">
                                        <div [hidden]="mig_bilate_hidden" class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                            <mat-checkbox color="primary" [(ngModel)]="mig_bilate" [disabled]="client" (change)="send_data()">Bilateral
                                            </mat-checkbox>
                                        </div>
                
                                        <div [hidden]="mig_bi_dur_hidden" class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                            <mat-label class="matlabel">Duration</mat-label>
                                            <div class="row">
                                                <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                                    <input class="ipcss" maxlength="5" (change)="send_data()"
                                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                        [(ngModel)]="mig_bi_dur" matInput [readonly]="client" />
                                                </div>
                                                <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                                                    <mat-select class="ipcss" disableOptionCentering [(ngModel)]="mig_bi_dur_day" (ngModelChange)="send_data()"
                                                        [disabled]="client">
                                                        <mat-option [ngValue]="Select">Select</mat-option>
                                                        <mat-option value="day(s)">day(s)</mat-option>
                                                        <mat-option value="week(s)">week(s)</mat-option>
                                                        <mat-option value="month(s)">month(s)</mat-option>
                                                    </mat-select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>

    <p class="tervys_heading">Diagnosis</p>
    <app-entdiagnosis></app-entdiagnosis>
</div>