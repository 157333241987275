import { Component, OnInit ,ElementRef,Input,Inject,ViewEncapsulation,HostListener} from '@angular/core';
import { Http, Headers } from '@angular/http';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Doc_Helper } from '../Doc_Helper';
import { Helper_Class } from '../../helper_class';
import { ipaddress } from '../../ipaddress' ;
@Component({
  selector: 'app-cardio-popup-history',
  templateUrl: './cardio-popup-history.component.html',
  styleUrls: ['./cardio-popup-history.component.scss']
})
export class CardioPopupHistoryComponent implements OnInit {
  public Diab_case_hist_id;
  public Life_style_id;
  public habitual_history_data = [];
  public family_history_data = [];
  
  public History;
  public habitual_table: boolean;
  public clinical_table:boolean;
  public family_table: boolean;
  public pain_table:boolean;
  public breath_table: boolean;
  public physical_table: boolean;
  public diagnosis_table: boolean;
  public dietplan_table: boolean;
  public diettracker_table: boolean;
  public client_reg_id;
  public relation_id;
  public sub_rel_id;
  public DietHist_Table_Data = [];
  public Trackhist_Table_Data = [];
  public clinical_data=[];
  public pain_data=[];
  public breath_data=[];
  public physical_data=[];
  public diagnosis_data=[];
  public nodataapp: boolean;
  public personaldata;
  public personaldatavalue;

  constructor(public http:Http, public el: ElementRef,public dialogRef: MatDialogRef<CardioPopupHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      dialogRef.disableClose = true;
    this.History = data.History;
    this.personaldata = Helper_Class.getRet();
    this.personaldatavalue = Doc_Helper.getClient_Info();
    this.Diab_case_hist_id =   data.Diab_case_hist_id;
    this.Life_style_id =  data.Life_style_id;
    this.client_reg_id = this.personaldatavalue.Client_id;
    this.relation_id = this.personaldatavalue.rel_id;
    this.sub_rel_id = this.personaldatavalue.sub_id;
    this.History = data.History;
    this.clinical_table = true;
    this.pain_table = true;
    this.breath_table = true;
    this.physical_table = true;
    this.dietplan_table = true;
    this.diettracker_table = true;
    this.habitual_table = true;
    this.family_table = true;
    this.diagnosis_table = true;
  }

  ngOnInit() {
    this.habitual_history_data = [];
    this.family_history_data = [];
    this.DietHist_Table_Data = [];
    this.Trackhist_Table_Data = [];
    this.clinical_data = [];
    this.pain_data = [];
    this.breath_data =  [];
    this.physical_data = [];
    this.diagnosis_data = [];
    
    if (this.History == "clinical_hist") {
      this.clinical_history();
    } else if (this.History == "pain_hist") {
      this.pain_history();
    } else if (this.History == "breath_hist") {
      this.breath_history();
    } else if (this.History == "phy_hist") {
      this.physical_history();
    } else if (this.History == "diagnosis_hist") {
      this.diagnosis_history();
    } 
  }

  diagnosis_history(){
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'cardio/clinicalhist/',
        JSON.stringify({
            case_hist_id: this.Diab_case_hist_id,
            type:"diagnosis"
        }),
        { headers: headers })
        .subscribe(
            response => {
                var obj = JSON.parse(response["_body"]);
                if (obj != null && obj !=undefined) {
                    for (var i = 0; i < obj.diagnosis_hist.length; i++) {
                        if (obj.diagnosis_hist[i].created_date != null) {
                          this.diagnosis_table=false;
                          this.diagnosis_data.push({
                            disease_type: obj.diagnosis_hist[i].disease_type,
                            opt_complex: obj.diagnosis_hist[i].opt_complex,
                            med_procedure: obj.diagnosis_hist[i].med_procedure,
                            exec_desc: obj.diagnosis_hist[i].exec_desc,
                            exec_dur: obj.diagnosis_hist[i].exec_dur,
                            exec_freq: obj.diagnosis_hist[i].exec_freq,
                            created_date: obj.diagnosis_hist[i].created_date,
                          });
                        }
                    }
                    this.nodataapp = this.diagnosis_data.length == 0 ? false : true;
                } else {
                  this.diagnosis_table=true;
                  this.nodataapp = false;
                }
            },
            error => {});
  }

  physical_history(){
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'cardio/clinicalhist/',
        JSON.stringify({
            case_hist_id: this.Diab_case_hist_id,
            type:"physical"
        }),
        { headers: headers })
        .subscribe(
            response => {
                var obj = JSON.parse(response["_body"]);
                if (obj != null && obj !=undefined) {
                    for (var i = 0; i < obj.physical_hist.length; i++) {
                        if (obj.physical_hist[i].created_date != null) {
                          this.physical_table=false;
                          this.physical_data.push({
                            eyes: obj.physical_hist[i].eyes,
                            nails: obj.physical_hist[i].nails,
                            lip_tongue: obj.physical_hist[i].lip_tongue,
                            face: obj.physical_hist[i].face,
                            hand_arm: obj.physical_hist[i].hand_arm,
                            cap_refill: obj.physical_hist[i].cap_refill,
                            created_date: obj.physical_hist[i].created_date,
                          });
                        }
                    }
                    this.nodataapp = this.physical_data.length == 0 ? false : true;
                } else {
                  this.physical_table=true;
                  this.nodataapp = false;
                }
            },
            error => {});
  }

  breath_history(){
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'cardio/clinicalhist/',
        JSON.stringify({
            case_hist_id: this.Diab_case_hist_id,
            type:"breath"
        }),
        { headers: headers })
        .subscribe(
            response => {
                var obj = JSON.parse(response["_body"]);
                if (obj != null && obj !=undefined) {
                    for (var i = 0; i < obj.breath_hist.length; i++) {
                        if (obj.breath_hist[i].created_date != null) {
                          this.breath_table=false;
                          this.breath_data.push({
                            trigger: obj.breath_hist[i].trigger,
                            onset: obj.breath_hist[i].onset,
                            dizziness: obj.breath_hist[i].dizziness,
                            palpitation: obj.breath_hist[i].palpitation,
                            caugh: obj.breath_hist[i].caugh,
                            created_date: obj.breath_hist[i].created_date,
                          });
                        }
                    }
                    this.nodataapp = this.breath_data.length == 0 ? false : true;
                } else {
                  this.breath_table=true;
                  this.nodataapp = false;
                }
            },
            error => {});
  }

  pain_history(){
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'cardio/clinicalhist/',
        JSON.stringify({
            case_hist_id: this.Diab_case_hist_id,
            type:"pain"
        }),
        { headers: headers })
        .subscribe(
            response => {
                var obj = JSON.parse(response["_body"]);
                
                if (obj != null && obj !=undefined) {
                    for (var i = 0; i < obj.pain_causes.length; i++) {
                        if (obj.pain_causes[i].created_date != null) {
                          this.pain_table=false;
                          this.pain_data.push({
                            pain: obj.pain_causes[i].pain,
                            location: obj.pain_causes[i].location,
                            radiation: obj.pain_causes[i].radiation,
                            character: obj.pain_causes[i].character,
                            cause: obj.pain_causes[i].cause,
                            created_date: obj.pain_causes[i].created_date,
                          });
                        }
                    }
                    this.nodataapp = this.pain_data.length == 0 ? false : true;
                } else {
                  this.pain_table=true;
                  this.nodataapp = false;
                }
            },
            error => {});
  }

  clinical_history() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'cardio/clinicalhist/',
        JSON.stringify({
            case_hist_id: this.Diab_case_hist_id,
            type:"clinical"
        }),
        { headers: headers })
        .subscribe(
            response => {
                var obj = JSON.parse(response["_body"]);
               
                if (obj != null) {
                  for (var i = 0; i < obj.clinical_hist.length; i++) {
                      if (obj.clinical_hist[i].created_date != null) {
                        this.clinical_table=false;
                        this.clinical_data.push({
                          stress_factor: obj.clinical_hist[i].stress_factor,
                          heart_sound: obj.clinical_hist[i].heart_sound,
                          murmurs: obj.clinical_hist[i].murmurs,
                          bp_right: obj.clinical_hist[i].bp_right,
                          bp_left: obj.clinical_hist[i].bp_left,
                          created_date: obj.clinical_hist[i].created_date,
                        });
                      }
                  }
                  this.nodataapp = this.clinical_data.length == 0 ? false : true;
                } else {
                  this.clinical_table= true;
                }
            },
            error => {});
  }

  dismiss() {
    this.dialogRef.close();
  }
}
