import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Http, Headers, JSONPBackend } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from 'src/app/providers/common-data.service';
import { Helper_Class } from 'src/app/helper_class';
// import { PharmacyService } from '../pharmacy/pharmacy.service';
import { PharmacyService } from 'src/app/pharma/pharmacy/pharmacy.service';
import { ipaddress } from '../../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';

@Component({
  selector: 'app-pharma-rackbin',
  templateUrl: './pharma-rackbin.component.html',
  styleUrls: ['./pharma-rackbin.component.scss']
})
export class PharmaRackbinComponent implements OnInit {
  public racks: string;
  public bins: string;

  public rackid:string="0";
  public binid: string="0";
  
  public saveFlag: boolean = true;
  public userInfo;
  public pharmacistID;
  public pharmacyID;

  public racksArray:any=[];
  public binsArray:any=[];
  public rackBinsArray:any=[];
  public editbutton:boolean=false;
  public newbutton:boolean=false;
  public deletebutton:boolean=false;
  public printbutton:boolean=false;
  public viewbutton:boolean=false;


  constructor(public messageservice: MenuViewService, public gservice: CommonDataService, 
    public http: Http, public routes: ActivatedRoute, public router: Router, 
    public toastr: ToastrService) { 


  }

  ngOnInit(): void {
    this.userInfo = Helper_Class.getInfo();
    if (Helper_Class.getInfo().user_type == 'Admin') {
      this.pharmacyID = Helper_Class.getInfo().pharma_id;
      // this.poapproved = Helper_Class.getInfo().po_approval_req;
      // this.pr_approver = Helper_Class.getInfo().po_approver;
      this.pharmacistID = Helper_Class.getInfo().po_approver;
    }
    else {
      this.pharmacyID = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      // this.poapproved = Helper_Class.getInfo().hospitals[0].po_approval_req;
      // this.pr_approver = Helper_Class.getInfo().hospitals[0].indent_approver;
      this.pharmacistID = this.userInfo.user_id;

    }

    this.getRackBinDetails();

    if(Helper_Class.getmodulelist() != undefined){
    
      console.log("id"+JSON.stringify(Helper_Class.getmodulelist()))
      for (var i = 0; i < Helper_Class.getmodulelist().length; i++) {
        if (Helper_Class.getmodulelist()[i].module_id == "60" && Helper_Class.getmodulelist()[i].description == " Supplier payments"){
         
          if(Helper_Class.getmodulelist()[i].edit == "1"){
              this.editbutton=true;
            }
            if(Helper_Class.getmodulelist()[i].create == "1"){
              this.newbutton=true;
            }
            if(Helper_Class.getmodulelist()[i].delete == "1"){
              this.deletebutton=true;
            }
            if(Helper_Class.getmodulelist()[i].print == "1"){
              this.printbutton=true;
            }
            if(Helper_Class.getmodulelist()[i].view == "1"){
              this.viewbutton=true;
            }
        }
      }
    }
  }

  getRackBinDetails() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/grbd/", JSON.stringify({
      pharma_id: this.pharmacyID,
    }), { headers: headers }).subscribe(
      data => {
        var obj = JSON.parse(data["_body"]);
        if (obj.racks != undefined && obj.racks.length != 0) {
          this.racksArray = obj.racks;
        }
  
        if (obj.bins != undefined && obj.bins.length != 0) {
          this.binsArray = obj.bins.sort((a, b) => {
            const numA = parseInt(a.binname.match(/\d+/)[0], 10);
            const numB = parseInt(b.binname.match(/\d+/)[0], 10);
            return numA - numB;
          });
        }
      });
  }
  sortRacksArray() {
    this.racksArray.sort((a, b) => {
      const numA = parseInt(a.rackname.match(/\d+/)[0], 10);
      const numB = parseInt(b.rackname.match(/\d+/)[0], 10);
      return numA - numB;
    });
  }
  sortBinsArray() {
    this.binsArray.sort((a, b) => {
      const numA = parseInt(a.binname.match(/\d+/)[0], 10);
      const numB = parseInt(b.binname.match(/\d+/)[0], 10);
      return numA - numB;
    });
  }
  saveData(value){
    if (this.racksArray.length != 0 || this.binsArray.length != 0) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "pharmacontrol/srbd/", JSON.stringify({
        racks: this.racksArray,
        bins: this.binsArray,
        pharma_id: this.pharmacyID,
      }), { headers: headers }).subscribe(
        data => {
          var obj = JSON.parse(data["_body"]);
          if (obj != undefined && obj.key == "1" && value == 'save') {
            this.saveFlag = true;
            this.toastr.success(Message_data.dataSavedSuccess);
            this.getRackBinDetails();
          } 
          else if(obj != undefined && obj.key == "1" && value == 'rackdelete')
          {
            this.saveFlag = true;
            this.toastr.success(Message_data.deleterack);
            this.getRackBinDetails();
          }
          else if(obj != undefined && obj.key == "1" && value == 'bindelete')
          {
            this.saveFlag = true;
            this.toastr.success(Message_data.deletebin);
            this.getRackBinDetails();
          }
          else {
            this.saveFlag = true;
            this.toastr.error(Message_data.notSavedSuccess);
          }
        }, error => { this.saveFlag = true; });

    } else {
      this.saveFlag = true;
      this.toastr.error(Message_data.addDiscount);
    }
  }

  addRacks(){
    if (this.racks != undefined) {
      if (this.racksArray.length != 0) {
        var existflag=false;
        for (var i = 0; i < this.racksArray.length; i++) {
          if (this.racksArray[i].rackname == this.racks) {
            this.toastr.error("Rack already exists");
            break;
          } else {
            this.racksArray.push({
              rackid:this.rackid,
              rackname: this.racks,
              status:"1",
            });
            break;
          }
        }
      } else {
        this.racksArray.push({
          rackid:this.rackid,
          rackname: this.racks,
          status:"1",
        });
      }
      this.racks = null;
    }
    this.sortRacksArray();
  
  }

  editRackDetails(rackname){
    for (var i = 0; i < this.racksArray.length; i++) {
      if (this.racksArray[i].rackname == rackname) {
        this.racks = this.racksArray[i].rackname;
        this.rackid=this.racksArray[i].rackid;
        this.racksArray.splice(i, 1);
      }
    }
  }

  deleteRackDetails(rackname,index){
   
    for (var i = 0; i < this.racksArray.length; i++) {
      if (this.racksArray[i].rackname == rackname) {
        this.racksArray[i].status="0";
        this.racksArray[i].deleted = true;
        
         
      }
    
    

    }
  
    this.saveData("rackdelete");

    // for (var i = 0; i < this.racksArray.length; i++) {
    //   if (this.racksArray[i].rackname == rackname) {
    //     this.racks = this.racksArray[i].rackname;
    //     this.racksArray.splice(i, 1);
    //   }
    // }
  
  
  }

  addBins(){
    if (this.bins != undefined) {
      if (this.binsArray.length != 0) {
        
        for (var i = 0; i < this.binsArray.length; i++) {
          if (this.binsArray[i].binname == this.bins) {
            this.toastr.error("Bin already exists");
            break;

          } else {
            this.binsArray.push({
              binid:this.binid,
              binname: this.bins,
              status:"1",
            });
            break;
          }
        }
      } else {
        this.binsArray.push({
          binid:this.binid,
          binname: this.bins,
          status:"1",
        });
      }
      this.bins = null;
    }
    this.sortRacksArray();
  
  }

  
  editBins(binname){
    for (var i = 0; i < this.binsArray.length; i++) {
      if (this.binsArray[i].binname == binname) {
        this.binsArray[i].status="0";

      }
    }
  }

  
  deleteBins(binname){
    
    // for (var i = 0; i < this.binsArray.length; i++) {
    //   if (this.binsArray[i].binname == binname) {
    //     this.bins = this.binsArray[i].binname;
    //     this.binsArray.splice(i, 1);
    //   }
    // }

    for (var i = 0; i < this.binsArray.length; i++) {
      if (this.binsArray[i].binname == binname) {
        this.binsArray[i].status="0";
        
        // this.racksArray.splice(i, 1);
      }


    }
      this.saveData("bindelete")
  }


}

























