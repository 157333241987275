<div class="row" style="width: 100%;">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white" style="padding:7px 20px;">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Profile Details</h5>
                    </div>
                    <div class="headerButtons">
                        <img src="../../../assets/ui_icons/buttons/update_button.svg" (click)="clickUpdate()"
                            class="saveimgbtn_inpatinfo" />
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>
                <mat-accordion class="mataccordion">
                    <mat-expansion-panel class="expandpanel " [expanded]="true">
                        <mat-expansion-panel-header class="exppanel width">
                            <mat-panel-title class="title">
                                Profile
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <hr class="hr">
                        <p class="tervys_heading col-12">Personal information</p>
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-2"
                                style="text-align: center;position: relative;">
                                <div style="position: relative;width: 160px;margin: 0 auto;">
                                    <img id="profile_img" class="profileimg" src="{{profileImg}}"
                                        onerror="this.src='./././assets/img/default.jpg'" />
                                    <input type="file" (change)="fileChange($event)" name="filUpload" id="profile_pic">
                                    <label for="profile_pic" class="edit_profile_icon"><i
                                            class="fas fa-pencil-alt"></i></label>
                                </div>
                            </div>
                            <div class="col-12 col-sm-12 col-md-8 col-lg-9 col-xl-10">
                                <div class="row">
                                    <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">First Name<br>
                                            <input type="text" class="ipcss " [(ngModel)]="firstName" disabled="true"
                                                matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-12  col-sm-6  col-md-6 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Middle Name(optional)<br>
                                            <input type="text" class="ipcss " [(ngModel)]="middleName" disabled="true"
                                                matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-12  col-sm-6  col-md-6 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Last Name<br>
                                            <input type="text" class="ipcss " [(ngModel)]="lastName" disabled="true"
                                                matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-12  col-sm-6  col-md-6 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Gender<br>
                                            <input type="text" class="ipcss " [(ngModel)]="gender" disabled="true"
                                                matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-12  col-sm-6  col-md-6 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">DOB<br>
                                            <input type="text" class="ipcss " [(ngModel)]="DOB" disabled="true"
                                                matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-12  col-sm-6  col-md-6 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Phone Number<br>
                                            <input type="text" class="ipcss " [(ngModel)]="mobile" maxlength="15"
                                                myNumberOnly required matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-12  col-sm-6  col-md-6 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Email<br>
                                            <input type="text" class="ipcss " [(ngModel)]="email" maxlength="50"
                                                required matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-12">
                                        <div class="row">
                                            <div class="col-lg-3">
                                                <mat-label class="matlabel">Homecare</mat-label>
                                            </div>
                                            <div class="col-6">
                                                <input type="checkbox" name="onoffswitch" class="onoffswitch-checkbox"
                                                    [(ngModel)]="toggHomeFlag" (click)="clickHome()" id="home" checked>
                                                <label class="onoffswitch-label" for="home">
                                                    <span class="onoffswitch-inner"></span>
                                                    <span class="onoffswitch-switch"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12" [hidden]="homeCareFlag">
                                <div class="row">
                                    <div class="casesheet_container dental_head">
                                        <div class="casesheet_icon">
                                            <div name="contact"></div>
                                            <!-- <span class="fab fa-hubspot" aria-hidden="true"></span> -->
                                        </div>
                                        <div class="casesheet_heading">
                                            <p class="casesheet_para">Homecare consultation</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-md-6 col-lg-6 col-xl-2">
                                        <mat-label class="matlabel">Session<br>
                                            <select [(ngModel)]="homeCareSession" class="ipcss">
                                                <option value="">Select</option>
                                                <option>Morning</option>
                                                <option>Afternoon</option>
                                                <option>Evening</option>
                                            </select>
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-md-6 col-lg-6 col-xl-3">
                                        <mat-label class="matlabel">Starts from<br></mat-label>
                                        <div class="row">
                                            <div class="col-4">
                                                <select [(ngModel)]="startTime1" class="ipcss">
                                                    <option *ngFor="let name of startsFromArray" [ngValue]="">{{name}}
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col-4">
                                                <select [(ngModel)]="startTime2" class="ipcss">
                                                    <option *ngFor="let name of startsFromArray" [ngValue]="">{{name}}
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col-4">
                                                <select [(ngModel)]="startTime3" class="ipcss">
                                                    <option [ngValue]="">AM</option>
                                                    <option>PM</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6 col-lg-6 col-xl-3">
                                        <mat-label class="matlabel">Ends at<br></mat-label>
                                        <div class="row">
                                            <div class="col-4">
                                                <select [(ngModel)]="endTime1" class="ipcss">
                                                    <option *ngFor="let name of endsAtArray" [ngValue]="">{{name}}
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col-4">
                                                <select [(ngModel)]="endTime2" class="ipcss">
                                                    <option *ngFor="let name of endsAtArray" [ngValue]="">{{name}}
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col-4">
                                                <select [(ngModel)]="endTime3" class="ipcss">
                                                    <option [ngValue]="">AM</option>
                                                    <option>PM</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-8 col-md-4 col-lg-4 col-xl-2">
                                        <mat-label class="matlabel">Fee<br>
                                            <input [(ngModel)]="homeCareFee" maxlength="5" class="ipcss"
                                                onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                                        </mat-label>
                                    </div>
                                    <div class="col-4 col-md-2 col-lg-2 col-xl-1" style="margin: 22px 0;">
                                        <img src="../../../assets/ui_icons/buttons/add_button.svg"
                                            class="button_width add_btn" (click)="clickHomeCare()" width="87px" />
                                    </div>
                                    <div class="col-12" [hidden]="homeCareConsultArray.length == 0">
                                        <div class="table-responsive dig_table_overflow">
                                            <table class="table table-hover table-dynamic addTable "
                                                [hidden]="homeCareConsultArray.length == 0">
                                                <thead>
                                                    <tr>
                                                        <th>Session</th>
                                                        <th>Starts from</th>
                                                        <th>Ends at</th>
                                                        <th>Fee</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let getda of homeCareConsultArray">
                                                        <td>{{getda.session}}</td>
                                                        <td>{{getda.available_from1}}</td>
                                                        <td>{{getda.available_to1}}</td>
                                                        <td>{{getda.home_care_fee}}</td>
                                                        <td>
                                                            <div>
                                                                <img src="../../../assets/ui_icons/trash_icon.svg"
                                                                    (click)="deleteHomeConsult(getda.session,getda.fee)"
                                                                    class="button_width qualification_button"
                                                                    width="20px" />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion><br>
                <mat-accordion class="mataccordion">
                    <mat-expansion-panel class="expandpanel " [expanded]="true">
                        <mat-expansion-panel-header class="exppanel width">
                            <mat-panel-title class="title">
                                Qualification details
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="row">
                            <div class="col-12 col-md-6 col-lg-6 col-xl-3">
                                <mat-label class="matlabel">Degree<br>
                                    <input type="text" class="ipcss " [(ngModel)]="qualificationDegree" maxlength="25"
                                        matInput />
                                </mat-label>
                            </div>
                            <div class="col-12 col-md-6 col-lg-6 col-xl-3">
                                <mat-label class="matlabel">School/College<br>
                                    <input type="text" class="ipcss " [(ngModel)]="qualificationClg" maxlength="25"
                                        matInput />
                                </mat-label>
                            </div>
                            <div class="col-12 col-md-6 col-lg-6 col-xl-3">
                                <mat-label class="matlabel">University<br>
                                    <input type="text" class="ipcss " [(ngModel)]="qualificationUni" maxlength="25"
                                        matInput />
                                </mat-label>
                            </div>
                            <div class="col-12 col-md-6 col-lg-6 col-xl-3">
                                <mat-label class="matlabel">Year of completion<br>
                                    <input type="text" class="ipcss " [(ngModel)]="qualificationYear" maxlength="25"
                                        matInput />
                                </mat-label>
                            </div>
                            <div class="col-12">
                                <div style="text-align: right;">
                                    <img src="../../../assets/ui_icons/buttons/add_button.svg"
                                        (click)="clickQualification()" class="button_width qualification_button"
                                        width="87px" />
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="">
                                    <div class="table-responsive dig_table_overflow" *ngIf="qualificationArray != 0">
                                        <table class="table table-hover table-dynamic addTable "
                                            *ngIf="qualificationArray != 0">
                                            <thead>
                                                <tr>
                                                    <th>Degree name</th>
                                                    <th>School/College name</th>
                                                    <th>University name</th>
                                                    <th>Year</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let qualification of qualificationArray">
                                                    <td>{{qualification.degree}}</td>
                                                    <td>{{qualification.institute}}</td>
                                                    <td>{{qualification.university}}</td>
                                                    <td>{{qualification.year}}</td>
                                                    <td>
                                                        <div>
                                                            <img src="../../../assets/ui_icons/trash_icon.svg"
                                                                (click)="quaDelete(qualification.degree,qualification.year)"
                                                                class="button_width qualification_button"
                                                                width="20px" />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
                <mat-accordion class="mataccordion">
                    <mat-expansion-panel class="expandpanel " [expanded]="true">
                        <mat-expansion-panel-header class="exppanel width">
                            <mat-panel-title class="title">
                                Working details
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div>
                            <p class="tervys_heading col-12">&nbsp; Work location details &nbsp;&nbsp;</p>
                            <div class="row">
                                <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                    <mat-label class="matlabel">Hospital / Clinic Name<br>
                                        <input type="text" class="ipcss " required maxlength="50"
                                            [(ngModel)]="hospitalName" (keyup)="hospitalFilter()" matInput
                                            [matAutocomplete]="auto10" />
                                        <mat-autocomplete #auto10="matAutocomplete">
                                            <mat-option (click)="selectHospital(item)"
                                                *ngFor="let item of filteredhospList" value={{item}}>
                                                {{item}}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-label>
                                </div>
                                <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                    <mat-label class="matlabel">Location<br>
                                        <input type="text" class="ipcss" required maxlength="50"
                                            [(ngModel)]="hospitalLocation" (keyup)="locfilter()" matInput
                                            [matAutocomplete]="auto11" />
                                        <mat-autocomplete #auto11="matAutocomplete">
                                            <mat-option (click)="select_location(item)"
                                                *ngFor="let item of filteredlocListArray" value={{item}}>
                                                {{item}}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-label>
                                </div>
                                <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                    <mat-label class="matlabel">District / City<br>
                                        <select disableOptionCentering class="ipcss" [(ngModel)]="hospDist" required
                                            (ngModelChange)="cityChange($event)">
                                            <option disabled>Select</option>
                                            <option *ngFor="let city of cityArray" value={{city.city_id}}>
                                                {{city.city_desc}}
                                            </option>
                                        </select>
                                    </mat-label>
                                </div>
                                <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                    <mat-label class="matlabel">State<br>
                                        <select disableOptionCentering class="ipcss" [(ngModel)]="hospState" required
                                            (ngModelChange)="stateChange($event)">
                                            <option disabled>Select</option>
                                            <option *ngFor="let state of stateArray" value="{{state.state_id}}">
                                                {{state.state_desc}}
                                            </option>
                                        </select>
                                    </mat-label>
                                </div>
                                <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                    <mat-label cl ass="matlabel">Country<br>
                                        <select disableOptionCentering class="ipcss"
                                            (ngModelChange)="changeSerCountry()" required [(ngModel)]="hospCountry">
                                            <option disabled>Select</option>
                                            <option *ngFor="let country of countryArray" value={{country.country_desc}}>
                                                {{country.country_desc}}</option>
                                        </select>
                                    </mat-label>
                                </div>
                                <div class="col-12  col-sm-6  col-md-6 col-lg-3 col-xl-3">
                                    <mat-label class="matlabel">Zip code<br>
                                        <input type="text" class="ipcss " [(ngModel)]="hospZIp" matInput />
                                    </mat-label>
                                </div>
                                <div class="col-12  col-sm-6  col-md-6 col-lg-3 col-xl-3">
                                    <mat-label class="matlabel">Address 1<br>
                                        <input type="text" class="ipcss " [(ngModel)]="hospitalAddress1" matInput />
                                    </mat-label>
                                </div>
                                <div class="col-12  col-sm-6  col-md-6 col-lg-3 col-xl-3">
                                    <mat-label class="matlabel">Contact number <br>
                                        <input type="text" class="ipcss " [(ngModel)]="hospitalContactNumber"
                                            matInput />
                                    </mat-label>
                                </div>
                                <div class="col-12  col-sm-6  col-md-6 col-lg-3 col-xl-3">
                                    <mat-label class="matlabel">Website<br>
                                        <input type="text" class="ipcss " [(ngModel)]="hospitalWebsite" matInput />
                                    </mat-label>
                                </div>
                                <div class="col-12  col-sm-6  col-md-6 col-lg-3 col-xl-3">
                                    <mat-label class="matlabel">Consultation fee<br>
                                        <input type="text" class="ipcss " [(ngModel)]="hospFees" maxlength="5"
                                            onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
                                    </mat-label>
                                </div>
                                <div class="col-12  col-sm-6  col-md-6 col-lg-3 col-xl-3">
                                    <mat-label class="matlabel">Day<br>
                                        <select disableOptionCentering class="ipcss" [(ngModel)]="hospDays" required>
                                            <option>Sunday</option>
                                            <option>Monday</option>
                                            <option>Tuesday</option>
                                            <option>Wednesday</option>
                                            <option>Thursday</option>
                                            <option>Friday</option>
                                            <option>Saturday</option>
                                            <option>Mon - Sat</option>
                                            <option>All Days</option>
                                        </select>
                                    </mat-label>
                                </div>
                                <div class="col-12  col-sm-6  col-md-6 col-lg-3 col-xl-3">
                                    <mat-label class="matlabel">Session<br>
                                        <select [(ngModel)]="hospSession" required class="ipcss">
                                            <option value="">Select </option>
                                            <option>Morning</option>
                                            <option>Afternoon</option>
                                            <option>Evening</option>
                                        </select>
                                    </mat-label>
                                </div>
                                <div class="col-12  col-sm-6  col-md-6 col-lg-3 col-xl-3">
                                    <mat-label class="matlabel">Starts from</mat-label>
                                    <div class="row">
                                        <div class="col-4">
                                            <div class="dropdown">
                                                <select [(ngModel)]="startFrom1" class="ipcss">
                                                    <option *ngFor="let name of startsFromArray" [ngValue]="">{{name}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="dropdown">
                                                <select [(ngModel)]="startFrom2" class="ipcss">
                                                    <option *ngFor="let name of endsAtArray" [ngValue]="">{{name}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="dropdown">
                                                <select [(ngModel)]="startFrom3" class="ipcss">
                                                    <option [ngValue]="">AM</option>
                                                    <option>PM</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12  col-sm-6  col-md-6 col-lg-3 col-xl-3">
                                    <mat-label class="matlabel">Ends from</mat-label>
                                    <div class="row">
                                        <div class="col-4">
                                            <div class="dropdown">
                                                <select [(ngModel)]="endAt1" class="ipcss">
                                                    <option *ngFor="let name of startsFromArray" [ngValue]="">{{name}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="dropdown">
                                                <select [(ngModel)]="endAt2" class="ipcss">
                                                    <option *ngFor="let name of endsAtArray" [ngValue]="">{{name}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="dropdown">
                                                <select [(ngModel)]="endAt3" class="ipcss">
                                                    <option [ngValue]="">AM</option>
                                                    <option>PM</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div style="text-align: right;">
                                        <img src="../../../assets/ui_icons/buttons/add_button.svg" (click)="clickWork()"
                                            class="button_width work_add_btn" width="87px" />
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="table-responsive dig_table_overflow" *ngIf="workAarry.length != 0">
                                        <table class="table table-hover table-dynamic addTable">
                                            <thead>
                                                <tr>
                                                    <th hidden></th>
                                                    <th>Hospital / Clinic name</th>
                                                    <th>Location</th>
                                                    <th>Day</th>
                                                    <th>Session</th>
                                                    <th>Starts from</th>
                                                    <th>Ends at</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let work of  workAarry">
                                                    <td hidden>{{work.consult_time_id}}</td>
                                                    <td>{{work.name}}</td>
                                                    <td>{{work.location}}</td>
                                                    <td>{{work.day_desc}}</td>
                                                    <td>{{work.session_desc}}</td>
                                                    <td>{{work.available_from}}</td>
                                                    <td>{{work.available_to}}</td>
                                                    <td>
                                                        <div>
                                                            <img src="../../../assets/ui_icons/trash_icon.svg"
                                                                (click)="deleteWork(work.consult_time_id,work.session_desc,work.available_to)"
                                                                class="button_width work_add_btn" width="20px" />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>

