<mat-accordion class="mataccordion" >
    <mat-expansion-panel   class="expandpanel"(opened)="panelOpenState13 = true"
    (closed)="panelOpenState13 = false">
       <mat-expansion-panel-header class="exppanel ">
          <mat-panel-title class="title width">
            <span class=""> 
            Natal history</span>
           
          </mat-panel-title>
       </mat-expansion-panel-header>
    
    <div  [hidden]="natalTab">
        <div class="row">
        <div class="row">
            <div class="col-12">
                <p class="casesheet_para">Pre natal history (during pregnancy)</p>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Narrator<br>
                    <select class=" ipcss "[(ngModel)]="narr_txt" (change)="natal_change()" disableOptionCentering [disabled]="client">
                        <option disabled>Select</option>
                        <option value="Self">Self</option>
                        <option value="Father">Father</option>
                        <option value="Mother">Mother</option>
                        <option value="Any other">Any other</option>
                    </select>
                </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3"  [hidden]="dey_hidden">
                <mat-label class="matlabel" >Type of delivery<br>
                    <select  class=" ipcss "[(ngModel)]="type_dev" [required]="nat_delivery_typ_req"  (change)="natal_change()" disableOptionCentering [disabled]="client">
                        <option disabled>Select</option>
                        <option value="Normal">Normal</option>
                        <option value="Forceps">Forceps</option>
                        <option value="Cesarean">Cesarean</option>
                       
                    </select>
                </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="dis_mot_hidden">
                <mat-label class="matlabel"  >Infectious disease of mother<br>
                    <select  class=" ipcss " [(ngModel)]="infect_dis_mot"  (change)="natal_change()" disableOptionCentering [disabled]="client">
                        <option disabled>Select</option>
                        <option value="Bacterial">Bacterial</option>
                        <option value="Viral">Viral</option>
                        <option value="Parasitic">Parasitic</option>
                       
                    </select>
                </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="gst_hidden">
                
        <mat-label class="matlabel"  >Gestation period<br>
            <select  class=" ipcss " [(ngModel)]="infect_dis_mot"  (change)="natal_change()" disableOptionCentering [disabled]="client">
                <option disabled>Select</option>
                <option value="Full Term">Full term</option>
                <option value="Premature">Premature</option>
                
            </select>
        </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3"  [hidden]="health_hidden">
                <mat-label class="matlabel">Health of mother<br>
                    <textarea #matInput maxlength="50" class="ipcss obsttxtareaheight widappt"   [required]="nat_mot_health_req" (change)="natal_change()" [disabled]="client"
                    [(ngModel)]="health_mot"></textarea>
                </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3"  [hidden]="drug_mot_hidden">
                <mat-label class="matlabel" >Drug taken by mother<br> 
                    <textarea #matInput maxlength="50" class="ipcss obsttxtareaheight widappt"   maxlength="250" [disabled]="client"
                    (change)="natal_change()" [(ngModel)]="drug_taken_mot"></textarea>
                </mat-label>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <p [hidden]="Natal_Hist" class="tervys_heading">Natal history (at birth)</p>
            </div>
        </div>
        <div class="row" [hidden]="birth_hidden">
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="last_vit_hidden">
                <mat-label class="matlabel" > Birth weight<br>
                    <input matInput  class="ipcss " maxlength="3" (change)="natal_change()" [(ngModel)]="birth_weight_txt" [disabled]="client"/>
                </mat-label> 
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="rh_hidden">
                <mat-label class="matlabel" > Rh compatibility / other diseases<br>
                    <input matInput  class="ipcss " maxlength="25" (change)="natal_change()" [(ngModel)]="rh_comp_dis_txt" [disabled]="client" />
                </mat-label> 
            </div>
           
        </div>
        <div class="row">
            <div class="col-12">
                <p  [hidden]="post_txt" class="casesheet_para">Post natal history</p>
            </div>
        </div>
        <div class="row"  [hidden]="post_txt">
            <div class="col-12">
                <mat-label class="matlabel" >  <b class="mil_dev">Milestones of development</b></mat-label>
               
            </div>
        </div>
        <div class="row " >
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 box" >
                <div class="row" >

                           
            <div class="row">
                <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                    
                    <!-- <mat-label class="matlabel dental_material">Normal</mat-label> -->
                 
               
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                    <!-- <input #matInput maxlength="2"  class="ipcss " [required]="nat_sit_req"
                    (change)="natal_change()" [(ngModel)]="sitting_txt" /> -->
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                    <mat-label class="matlabel ">Normal</mat-label>
                </div>
            </div>
            <div class="row">
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                
                <mat-label class="matlabel dental_material">Sitting</mat-label>
             
           
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <input #matInput maxlength="2"  class="ipcss " [required]="nat_sit_req"
                (change)="natal_change()" [(ngModel)]="sitting_txt" [disabled]="client"/>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel ">6 Months</mat-label>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel dental_material ">Crawling</mat-label>
             
           
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                
                <input #matInput maxlength="2"  class="ipcss " [required]="nat_craw_req"
                (change)="natal_change()" [(ngModel)]="craw_txt" [disabled]="client"/>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel ">9 Months</mat-label>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel dental_material ">Standing</mat-label>
             
           
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <input #matInput maxlength="2"   class="ipcss " [required]="nat_stand_req"
                (change)="natal_change()" [(ngModel)]="stand_txt" [disabled]="client"/>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel ">12 Months</mat-label>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel  dental_material">Walking</mat-label>
             
           
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <input #matInput maxlength="2" [required]="nat_walk_req"  class="ipcss "
                (change)="natal_change()" [(ngModel)]="walk_txt" [disabled]="client"/>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel ">15 Months</mat-label>
            </div>
        </div>
       
        <div class="row">
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel dental_material ">Running</mat-label>
             
           
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <input #matInput maxlength="2" [required]="nat_run_req"  class="ipcss "
                (change)="natal_change()" [(ngModel)]="run_txt" [disabled]="client"/>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel ">18 Months</mat-label>
            </div>
        </div>
        <div class="row">
          
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel dental_material ">Phonation</mat-label>
             
           
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <input #matInput [required]="nat_phon_req" (change)="natal_change()"  class="ipcss "
                maxlength="2" [(ngModel)]="phon_txt" [disabled]="client"/>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel ">20 Months</mat-label>
            </div>
            </div>
            </div>
             </div>
           
             <!--  -->
             <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 box" >
                <div class="row">
                  
                    <div class="row">
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <!-- <mat-label class="matlabel" style="float: right;">Normal</mat-label> -->
                        <mat-label class="matlabel ">Finger / Thumb sticking</mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <input #matInput maxlength="15" (change)="natal_change()"  class="ipcss "
                        [(ngModel)]="fing_txt" [disabled]="client"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                         <mat-label class="matlabel ">Nail / Lip biting</mat-label>
                         </div>
                         <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <input #matInput maxlength="15" (ionChange)="natal_change()"  class="ipcss "
                        [(ngModel)]="nail_txt" [disabled]="client"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <mat-label class="matlabel ">Bruxism</mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <input #matInput   maxlength="15" (ionChange)="natal_change()"  class="ipcss "
                        [(ngModel)]="brux_txt" [disabled]="client"/>
                    </div>
                    </div>
                    <div class="row">
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <mat-label class="matlabel ">Tongue biting / thrusting</mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <input #matInput (change)="natal_change()"  class="ipcss "
                        maxlength="50" [(ngModel)]="tong_thru_txt" [disabled]="client"/>
                    </div>
                    </div>
                    <mat-label class="tervys_heading col-12">Pubertal status</mat-label>
                    <div class="row">
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <mat-label class="matlabel ">Injuries</mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <input #matInput (change)="natal_change()"  class="ipcss "
                        maxlength="50" [(ngModel)]="inju_txt" [disabled]="client"/>
                    </div>   
                    </div>               
                
                </div>
             </div>
        
       
        </div>    
        
          <div class="row">
              <div class="col-12" [hidden]="history_txt">
                  <p class="tervysheading"> History</p>
                <!-- <div >
                    <div class="casesheet_container dental_head tonsil_head">
                        <div class="casesheet_icon">
                            <img src="../../assets/imgs/dental_icons/teeth.png" width="25px" height="22" />
                        </div>
                        <div class="casesheet_heading">
                            <p class="casesheet_para">History</p>
                        </div>
    
                    </div>
                </div> -->
              </div>
          </div>
          <div class="row">

            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3"  [hidden]="tons_hidden">
                <mat-label class="matlabel" >History of tonsillectomy and adenoidectomy<br>
                    <textarea #matInput  class="ipcss " maxlength="15" [(ngModel)]="his_tonsil_ande" (change)="natal_change()" [disabled]="client"></textarea>
                </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" [hidden]="tons_hidden">
                <mat-label class="matlabel"  >Familial malocclusion history<br>
                    <textarea #matInput maxlength="50" class="ipcss "   maxlength="250" [disabled]="client"
                    (change)="natal_change()" [(ngModel)]="famil_mal_occ"></textarea>
                </mat-label> 
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3"  [hidden]="tons_hidden">
                <mat-label class="matlabel" >Parents (Type of malocclusion history)<br>
                   
                    <select  class=" ipcss "  [(ngModel)]="parent_mal_hist" [disabled]="client">
                        <option value="Similar">Similar</option>
                        <option value="Dissimilar">Dissimilar</option>
                    </select>
                </mat-label>
            </div>
          </div>
        
        </div>
    </div>
    </mat-expansion-panel>
</mat-accordion>
