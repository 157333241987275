<div class="row">
    <div class="col-12">
      <mdb-card>
        <mdb-card-header class="bg-white">
          <div class="headerCover">
            <div class="headerTilte app_list_header">
              <h5 class="m-0" class="mainHeadingStyle">Recepies List </h5>
            </div>
            <div class="headerButtons">
              <a (click)="create()"><img src="../../../assets/ui_icons/buttons/new_button.svg" class="saveimgbtn_inpatinfo" /></a>
            </div>
          </div>
         </mdb-card-header>
        <mdb-card-body>
          <div class="row" style="margin-top: 15px;">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <p class="nodata" *ngIf="recepiesListArray.length == 0">No package(s) found</p>
                <table *ngIf="recepiesListArray.length != 0" mdbTable datatable [dtOptions]="dtOptions" class="table table-nowrap table-sm dataTable billlisttable">
                    <thead>
                        <tr>
                            <th>SI.NO</th>
                            <th>Recepies name</th>
                            <th>Total kcal</th>
                            <th>Total quantity<th>
                            <th class="delete">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let recepies of recepiesListArray; let i = index" >
                            <td style="font-size: 12px;text-align: center;">
                                {{i + 1}}
                            </td>
                           
                            <td style="font-size: 12px;text-align: center;">
                                {{recepies.recipe_name}}
                            </td>
                            <td style="font-size: 12px;text-align: center;">
                                {{recepies.total_kcal}}
                            </td>
                            <td style="font-size: 12px;text-align: center;">
                                {{recepies.total_weight}}
                            </td>
                            <td style="font-size: 12px;text-align: center;">
                                <img src="../../../assets/img/edit.png" style="width: 20px; height: 20px;" alt="" (click)="editPackage(recepies)">
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        </mdb-card-body>
      </mdb-card>
    </div>
  </div>