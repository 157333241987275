import { Component, OnInit } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { MatDialog } from '@angular/material/dialog';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { CommonDataService } from '../../providers/common-data.service';
import { ipaddress } from '../../ipaddress';
import { Router } from '@angular/router';
import { ClientViewService } from '../clientview/client-view.service';
import { Client_Helper } from '../Client_helper';
declare var $: any;

@Component({
  selector: 'app-second-opinion-list',
  templateUrl: './second-opinion-list.component.html',
  styleUrls: ['./second-opinion-list.component.css']
})
export class SecondOpinionListComponent implements OnInit {
  dtOptions: DataTables.Settings = {};

  public secOpinionUrl;
  public sendDataForopinionlist;
  public opinionList;

  constructor(public clientservice: ClientViewService, public gservice: CommonDataService, public http: Http, public router: Router, public dialog: MatDialog) {
    this.opinionList = [];
    this.secOpinionUrl = ipaddress.getIp + "secopn/opnlist/";
    this.sendDataForopinionlist = {
      reg_id: this.gservice.Client_login_detail_data.Client_Id,
      type: "client",
      flag: "all",
      country: ipaddress.country_code
    }
  }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 10,
      "lengthMenu": [[5, 10, 25, 50, -1], [5, 10, 25, 50, "All"]],
      "order": [[2, 'desc']],
      "columnDefs": [
        { "orderable": false, "targets": [3] },
        {"targets":2, "type":"date-eu"}
      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search by client name, doctor name, or date"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    this.get_opinion_List();
  }

  get_opinion_List() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.secOpinionUrl, JSON.stringify(this.sendDataForopinionlist), { headers: headers }).subscribe(
      data => {
        Client_Helper.setopnionlist(data.json())
        this.opinionList = [];
        if (data.json().opinions != undefined && data.json().opinions.length == 0)
        var obj;
        var m_name, f_name, l_name, mem_name;
        if (data.json().opinions != null && data.json().opinions.length != 0) {
          for (var t = 0; t < data.json().opinions.length; t++) {
            mem_name = ""; f_name = ""; l_name = "";
            if (data.json().opinions[t].mem_first_name != null && data.json().opinions[t].mem_first_name != undefined) {
              f_name = encrypt_decript.Decript(data.json().opinions[t].mem_first_name).toString()
            }
            if (data.json().opinions[t].mem_last_name != null && data.json().opinions[t].mem_last_name != undefined) {
              l_name = encrypt_decript.Decript(data.json().opinions[t].mem_last_name).toString()
            }
            if (data.json().opinions[t].mem_middle_name != null && data.json().opinions[t].mem_middle_name != undefined) {
              mem_name = f_name + " " + encrypt_decript.Decript(data.json().opinions[t].mem_middle_name).toString()
                + " " + l_name;
            }else {
              mem_name = f_name
                + " " + l_name;
            }
            var ClientName = "";
            ClientName = (data.json().opinions[t].sub_relation_id != null) ? mem_name : "Self";

            var Appstatus;
            if (data.json().opinions[t].is_cancelled != null) {
              Appstatus = "Cancelled";
            }else {
              if (data.json().opinions[t].status == "1") {
                Appstatus = (data.json().opinions[t].from_time != null) ? "Time alloted" : "Yet to be reviewed";
              }else {
                Appstatus = "Review completed";
              }
            }
            var Doctor_name_data;
            if (data.json().opinions[t].dr_middle_name != null) {
              Doctor_name_data = data.json().opinions[t].dr_first_name + " " + data.json().opinions[t].dr_middle_name + " " + data.json().opinions[t].dr_last_name;
            }else {
              Doctor_name_data = data.json().opinions[t].dr_first_name + " " + data.json().opinions[t].dr_last_name;
            }
            this.opinionList.push({
              client_Name: ClientName,
              doctor_Name: Doctor_name_data,
              date: this.gservice.get_Date_Formate(data.json().opinions[t].created_date),
              status: Appstatus,
              doc_Reg_id: data.json().opinions[t].doc_reg_id,
              opinion_ID: data.json().opinions[t].opinion_id,
              relation_id: data.json().opinions[t].relation_id,
              relationship_name: data.json().opinions[t].relationship_name
            });
            var opinion_array: any;
            for (var b = 0; b < this.opinionList.length; b++) {
              var status_data = this.opinionList[b].status;
              var clientname = this.opinionList[b].client_Name;
              var doc_reg_id = this.opinionList[b].doc_reg_id;
              var opinionid = this.opinionList[b].opinion_ID;
              var Date = this.opinionList[b].date;
              opinion_array = {
                client_Name: clientname,
                doctor_Name: Doctor_name_data,
                date: Date,
                status: status_data,
                doc_Reg_id: doc_reg_id,
                opinion_ID: opinionid
              }
              Client_Helper.setopnionlistarray(this.opinionList);
            }
          }
        }
      },error => {});
  }

  opinion_Checked(opinion_id, doc_Reg_id, relation_id, relationship_name) {
    Client_Helper.setSO_client_details(null);
    Client_Helper.setopinionchecked(retrival);
    var retrival1 = [];
    retrival1.push({
      opinion_id: opinion_id, doc_id: doc_Reg_id,
      relation_id: relation_id, relationship_name: relationship_name
    });
    Client_Helper.setSO_client_details(retrival1);
    var retrival = null;
    retrival = {
      opinion_id: opinion_id, doc_id: doc_Reg_id,
      relation_id: relation_id,
      relationship_name: relationship_name
    };
    Client_Helper.setopinionchecked(retrival);
    this.clientservice.sendMessage("secondopinionretrival");
  }

  New_appointment() {
    this.clientservice.sendMessage('appnewsecondopinion');
  }
}
