<div class="container-fluid g-0" style="height: 100%;">
    <div class="warpper_div">
        <div class="asied_section" #asied_section_width>
            <section id="aside_section">
                <div class="logopanel" id="logo" #logo>
                    <h1>
                      <a (click)="menuNav('sales_report','');"></a>
                    </h1>
                </div>
                <div class="menu_list">
                    <div class="menu" id="billingList" (click)="menuNav('billingList','');">
                        <div class="menu_icon"> <i style="transform: rotate(-55deg);" class="fa fa-puzzle-piece"></i>
                        </div>
                        <div class="doc_menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                            <span>Billing</span><span style="float: right;padding-right: 7px;"> <i class="fa fa-angle-right"></i></span>
                        </div>
                        <ul *ngIf="submenu_flag" class="nav-children">
                            <li (click)="menuNav('bill_list','')"><a id="nav-a"><i class="fa fa-angle-right"></i>
                                    Doctor Billing</a></li>
                            <li (click)="menuNav('pharmaBillingList','');"><a><i class="fa fa-angle-right"></i>
                                    Pharmacy Billing</a></li>
                            <li (click)="menuNav('diagBillingList','');"><a><i class="fa fa-angle-right"></i> Diagnosis Billing</a></li>
                            <li [hidden]="consolidatedFlag" (click)="menuNav('consolidatedBills','');"><a><i class="fa fa-angle-right"></i> Consolidated Bills</a></li>
                        </ul>
                    </div>

                    <div class="menu" id="reports" (click)="menuNav('reports','');">
                        <div class="menu_icon">  <i class="icon-menu"><img src=" ../../assets/global/images/mis_report_icon.svg"/></i>
                        </div>
                        <div class="doc_menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                            <span>Reports</span><span style="float: right;padding-right: 7px;"> <i class="fa fa-angle-right"></i></span>
                        </div>
                        <ul *ngIf="submenuFlag1" class="nav-children">
                            <li [hidden]="salesReportFlag" (click)="menuNav('sales_report','');"><a id="nav-a"><i class="fa fa-angle-right"></i>
                                Sales report</a></li>
                            <li [hidden]="salesReportGstFlag" (click)="menuNav('salesGst','salesGst');"><a><i class="fa fa-angle-right"></i>
                                Sales report GST</a></li>
                            <li [hidden]="stockReceivableFlag" (click)="menuNav('stockReceivable','stockReceivable');"><a><i class="fa fa-angle-right"></i> Stock Receivables</a></li>
                            <li [hidden]="schdForDrugInspFlag" (click)="menuNav('gsd','gsd');"><a><i class="fa fa-angle-right"></i> Schedule Register</a></li>
                            <li [hidden]="salesForDrugInspFlag" (click)="menuNav('SDI','SDI');"><a><i class="fa fa-angle-right"></i> Sales For Drug Inspector</a></li>
                            <li [hidden]="hospBillsReportFlag" (click)="menuNav('HBR','HBR');"><a><i class="fa fa-angle-right"></i> Hospital Bills Report</a></li>
                            <li [hidden]="diagBillsReportFlag" (click)="menuNav('DBR','DBR');"><a><i class="fa fa-angle-right"></i> Diagnosis Bills Report</a></li>
                            <li [hidden]="inwardReportFlag" (click)="menuNav('IR','IR');"><a><i class="fa fa-angle-right"></i> Inward Reports</a></li>
                            <li [hidden]="inpatReportFlag" (click)="menuNav('IPR','IPR');"><a><i class="fa fa-angle-right"></i> Inpatient Reports</a></li>
                            <li [hidden]="stockReportFlag" (click)="menuNav('stock_report','');"><a><i class="fa fa-angle-right"></i> Inpatient Reports</a></li>
                        </ul>
                    </div>

                    <!-- <div  class="menu menu_active" id="sales_report" (click)="menuNav('billingList','');">
                        <div class="menu_icon" > <i class="icon-menu"><img src="../../assets/global/images/dashboard.svg"/></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined" > <span>Billing</span>
                            <span style="float: right;padding-right: 7px;"> <i class="fa fa-angle-right"></i></span></div>
                    </div> -->
                    <div class="menu" id="listPackage" *ngIf="packageCreateFlag"  (click)="menuNav('listPackage','');">
                        <div class="menu_icon"> <i class="icon-menu"><img src="../../assets/global/images/doctor.svg"/></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined"> <span>Package</span><span style="float: right;padding-right: 7px;"> <i class="fa fa-angle-right"></i></span></div>
                    </div>
                    <div class="menu" *ngIf="packageCreateFlag"  id="clientPackageList" (click)="menuNav('clientPackageList','');">
                        <div class="menu_icon"> <i class="icon-menu"><img src="../../assets/global/images/doctor.svg"/></i></div>
                        <div class="menu_title" > <span>Subscribed User </span><span style="float: right;padding-right: 7px;"> <i class="fa fa-angle-right"></i></span></div>
                    </div>
                    <div [hidden]="stockEditFlag" class="menu" id="stock_edit" (click)="menuNav('stock_edit','');">
                        <div class="menu_icon"> <i class="icon-menu"><img src="../../assets/global/images/doctor.svg"/></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined"> <span>Stock edit</span><span style="float: right;padding-right: 7px;"> <i class="fa fa-angle-right"></i></span></div>
                    </div>
                </div>
            </section>
        </div>
        <div class="right_content_section" #right_section_width>  
            <section id="headar_section">
                <div class="header-left" style="position: relative;">
                    <div class="topnav">
                        <div class="openbtn_desktop" (click) = "minimizing()"><i class="fas fa-bars"></i></div>
                        <div class="openbtn" (click)="openMenumodel()"><i class="fas fa-bars"></i></div>
                        <div class="hospital_logo">
                            <img class="hospital_logo" src="{{hospitalLogo}}" onerror="this.src='../../../assets/img/default_hosp_logo.png';" alt="user image"> 
                        </div>
                        <div class="hospital_name"><span class="d-none d-sm-inline-block d-md-inline-block d-lg-inline-block d-xl-inline-block ">{{hospitalName}}</span></div>
                         <div class="doctor_profile_pc" data-bs-toggle="modal" data-bs-target="#exampleModal"><img  src="{{profileImage}}"  onerror="this.src='./././assets/img/default.jpg'"> </div>
                         <div class="doctor_name" data-bs-toggle="modal" data-bs-target="#exampleModal">{{firstName}}&nbsp;{{lastName}}</div>
                         <!-- Modal -->
                            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div class="modal-dialog profile_dialog">
                                <div class="modal-content">
                                    <div class="modal-header" style="display: inline-block;text-align: center">
                                        <!-- src="{{profile_image}}" -->
                                    <div class="modal-title" id="exampleModalLabel"><img class="model_profile"  src="{{profileImage}}" onerror="this.src='./././assets/img/default.jpg'"></div>
                                    </div>
                                    <div class="modal-body">
                                            <div class="menu_div" (click)="menuModelAction('profile');"> <i class="far fa-user"></i> <span class="header_model_menu">My Profile</span>  </div>
                                            <div class="menu_div" (click)="menuModelAction('changePassword');"><i class="fas fa-cog"></i><span class="header_model_menu">Change Password</span></div>
                                            <div class="menu_div" (click)="menuModelAction('logout');" style="border-bottom: none;"><i class="fas fa-sign-out-alt"></i><span class="header_model_menu">Log Out</span></div>
                                    </div>
                                </div>
                                </div>
                            </div>
                    </div>
                  </div>  
            </section>
            <section id="body_section">
                <div class="body_warpper">
                    <div class="card shadow-lg">
                        <div [ngSwitch]="displayPage">
                            <div *ngSwitchDefault="'sales_report'">
                                <app-pharma-sales-report></app-pharma-sales-report>
                            </div>
                            <div *ngSwitchCase="'sales_report'">
                                <app-pharma-sales-report></app-pharma-sales-report>
                            </div>
                            <div *ngSwitchCase="'stock_report'">
                                <app-pharma-stock-status></app-pharma-stock-status>
                            </div>
                            <div *ngSwitchCase="'stock_edit'">
                                <app-pharma-stock-status-admin></app-pharma-stock-status-admin>
                            </div>
                            <div *ngSwitchCase="'bill_list'">
                                <app-recept-bill-list></app-recept-bill-list>
                            </div>
                            <div *ngSwitchCase="'salesGst'">
                                <app-sales-report-gst></app-sales-report-gst>
                            </div>
                            <div *ngSwitchCase="'stockReceivable'">
                                <app-sales-report-gst></app-sales-report-gst>
                            </div>
                            <div *ngSwitchCase="'gsd'">
                                <app-sales-report-gst></app-sales-report-gst>
                            </div>
                            <div *ngSwitchCase="'recreceivableReport'">
                                <app-sales-report-gst></app-sales-report-gst>
                            </div>
                            <div *ngSwitchCase="'SDI'">
                                <app-sales-report-gst></app-sales-report-gst>
                            </div>
                            <div *ngSwitchCase="'HBR'">
                                <app-sales-report-gst></app-sales-report-gst>
                            </div>
                            <div *ngSwitchCase="'DBR'">
                                <app-sales-report-gst></app-sales-report-gst>
                            </div>
                            <div *ngSwitchCase="'IR'">
                                <app-sales-report-gst></app-sales-report-gst>
                            </div>
                            <div *ngSwitchCase="'IPR'">
                                <app-sales-report-gst></app-sales-report-gst>
                            </div>
                            <div *ngSwitchCase="'consolidatedBills'">
                                <app-consolidated-bills></app-consolidated-bills>
                            </div>
                            <div *ngSwitchCase="'billingEdit'">
                                <app-recept-bill-edit></app-recept-bill-edit>
                            </div>
                            <div *ngSwitchCase="'billingCreate'">
                                <app-recept-bill-create></app-recept-bill-create>
                            </div>
                            <div *ngSwitchCase="'inPatientCreate'">
                                <app-ipatient-bill-create></app-ipatient-bill-create>
                            </div>
                            <div *ngSwitchCase="'billingDetailsView'">
                                <app-recept-bill-detailview></app-recept-bill-detailview>
                            </div>
                            <div *ngSwitchCase="'diagBillingList'">
                                <app-diagbilllist></app-diagbilllist>
                            </div>
                            <div *ngSwitchCase="'diagBillingView'">
                                <app-diagbilldetailview></app-diagbilldetailview>
                            </div>
                            <div *ngSwitchCase="'diagBillingEdit'">
                                <app-diagbilledit></app-diagbilledit>
                            </div>
                            <div *ngSwitchCase="'pharmaBillingList'">
                                <app-pharmacypastinformation></app-pharmacypastinformation>
                            </div>
                            <div *ngSwitchCase="'pharmaBillingView'">
                                <app-pharmacymodule-orderdetiledview></app-pharmacymodule-orderdetiledview>
                            </div>
                            <div *ngSwitchCase="'createPackage'">
                                <app-create-package></app-create-package>
                            </div>
                            <div *ngSwitchCase="'listPackage'">
                                <app-list-package></app-list-package>
                            </div>
                            <div *ngSwitchCase="'clientPackageList'">
                                <app-user-package-list></app-user-package-list>
                            </div>
                            <div *ngSwitchCase="'createUserPackage'">
                                <app-create-package></app-create-package>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <footer id="footer_section">
                <div class="footer_left">Copyright © 2023 All rights reserved </div>
                <div class="footer_right">
                    <p class="pull-right sm-pull-reset">
                    <span>
                        <a href="#" class="m-r-10">Support</a> | <a href="#" class="m-l-10 m-r-10">Terms of use</a> | <a href="#" class="m-l-10">Privacy Policy</a>
                    </span>
                  </p>
                </div>
            </footer>
        </div>
    </div>
</div>