<div mat-dialog-title class="popup_border_icd">
    <h5 class="header_txt">Medicine details</h5>
    <div mat-dialog-actions>        
        <img src="../../../assets/img/close_icon.png" (click)="ClosePopup()" width="20px" height="20px" id="close_icd"/>
    </div>
</div>

<div mat-dialog-content class="icd_content">
    <div class="container">
        <div class="row">
            <div class="block1">
                <mat-label class="matlabel heightmsr"><b>Doctor : </b></mat-label>{{total_name}}
            </div>
            <div class="block1">
                <mat-label class="matlabel heightmsr"><b>Date : </b></mat-label>{{date_prescription}}
            </div>
            <hr style="border: 1px solid #06438a;">
            <div class="block1">
                <mat-label class="matlabel heightmsr">Patient : </mat-label>{{dr_total_name}}
            </div>
            <div class="block1">
                <mat-label class="matlabel heightmsr">Disease : </mat-label>{{disease_name}} {{disease_describtion}}
            </div><br>
            <div class="block1">
                <table id="tbl">
                    <thead>
                        <tr>
                            <th><b>Medicine</b></th>
                            <th><b>Dosage</b></th>
                            <th><b></b></th>
                            <th><b></b></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let medicene of tablet_tracker">
                            <td>{{medicene.medicene_name}}</td>
                            <td>{{medicene.medicene_dosage}}</td>
                            <td>{{medicene.medicene_intakedays}}</td>
                            <td>{{medicene.medicene_food}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        
        </div>
    </div>
</div>
<div mat-dialog-actions align="end" style="width: auto;right: 19px;">
</div>