<div class="row">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white">
                <div class="d-flex justify-content-between align-items-center">
                    <h5 class="m-0" style="font-size: 17px;">Appointments</h5>
                    <div>
                        <img src="../../../assets/ui_icons/buttons/save_button.svg" class="saveimgbtn_inpatinfo"
                            (click)="readingSave()" />
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>
                <!-- <div class="border_box">
                        
                </div> -->
                <div class="row border_box">
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                        <mat-label class="matlabel">First name
                            <input matInput class="ipcss widthappt" [readonly]="isReadonly()"
                                [(ngModel)]="firstName" />
                        </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                        <mat-label class="matlabel">Middle name
                            <input matInput class="ipcss widthappt" [readonly]="isReadonly()"
                                [(ngModel)]="middleName" />
                        </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                        <mat-label class="matlabel">Last name
                            <input matInput class="ipcss widthappt" [readonly]="isReadonly()"
                                [(ngModel)]="lastName" />
                        </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                        <mat-label class="matlabel">Gender
                            <input matInput class="ipcss widthappt" [readonly]="isReadonly()"
                                [(ngModel)]="gender" />
                        </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                        <mat-label class="matlabel">Age
                            <input matInput class="ipcss widthappt" [readonly]="isReadonly()"
                                [(ngModel)]="age" />
                        </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                        <mat-label class="matlabel">Weight
                            <input matInput class="ipcss widthappt" [readonly]="isReadonly()"
                                [(ngModel)]="weight" />
                        </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                        <mat-label class="matlabel">Ethnicity
                            <input matInput class="ipcss widthappt" [readonly]="isReadonly()"
                                [(ngModel)]="clientEthic" />
                        </mat-label>
                    </div>
                </div>
                <div class="row border_box">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <mat-label class="matlabel">Serum creatinine
                                <input matInput class="ipcss widthappt" placeholder="(Mg)"
                                    (keyup)="serumCreatChange(serumCreatinine)" [(ngModel)]="serumCreatinine" />
                            </mat-label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <mat-label class="matlabel">eGFR
                                <input matInput class="ipcss widthappt" [readonly]="isReadonly()"
                                    [(ngModel)]="eGFR" />
                            </mat-label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <mat-label class="matlabel">Albumin excretion rate
                                <input matInput class="ipcss widthappt" 
                                    placeholder="(g/dl)" [(ngModel)]="readAlbmExrate" />
                            </mat-label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <mat-label class="matlabel">Protein excretion rate
                                <input matInput class="ipcss widthappt" placeholder="(Grams)"
                                    [(ngModel)]="readProtExrate" />
                            </mat-label>
                        </div>
                    </div>
                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>