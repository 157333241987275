<app-header></app-header>

<div class=" pt-5 pb-5 mt-5" id="contactusSection">
    <div class="container">
       <div class="row">
          <div class="col-12 col-md-5">
             <h1 class="aboutus-heading pb-3">Contact Us</h1>
             <p class="aboutus-para">No: 31, Vembuliamman koil street, <br> Pazhavanthangal, Chennai 600114, <br> Tamil Nadu, India
            </p>
            <p class="aboutus-para">Phone: <span style="color:#687F77">044-49526808</span></p>
            <p class="aboutus-para">Email: <span style="color:#687F77">info@gritsys.com</span></p>              
          </div>
          <div class="col-12 col-md-7">
             <h1 class="aboutus-heading pb-3">Get Connected</h1>
             <div class="row g-3">
                <div class="col-md-6">
                  <label for="inputEmail4" class="form-label">Name</label>
                  <input type="text" class="form-control"  [(ngModel)]="name">
                </div>
                <div class="col-md-6">
                  <label for="inputPassword4" class="form-label">Email</label>
                  <input type="text" class="form-control"  [(ngModel)]="email">
                </div>
                <div class="col-12">
                   <label class="form-label">Additional Remarks</label>
                   <textarea type="text" class="form-control" rows="50" cols="100" id="textarea" [(ngModel)]="comment">
                   </textarea>
                 </div>
                <div class="col-12">
                  <button type="submit" class="btn btn-outline-dark" (click)="submitInfo()">Send</button>
                </div>
              </div>
          </div>   
       </div>
    </div>
 </div>

 <app-footer></app-footer>