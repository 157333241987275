import { Component,ViewChild, OnInit, Input } from '@angular/core';
import {Helper_Class} from '../../helper_class';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { SecondOpinionMedicationPopupComponent } from '../second-opinion-medication-popup/second-opinion-medication-popup.component';
import { Observable, observable, Subscription } from 'rxjs';
import { SecondOpinionService } from '../second-opinion-casesheet/SecondOpinion.Service';
import { ipaddress } from '../../ipaddress' ;
@Component({
  selector: 'app-second-opinion-casesheet-hm',
  templateUrl: './second-opinion-casesheet-hm.component.html',
  styleUrls: ['./second-opinion-casesheet-hm.component.css']
})
export class SecondOpinionCasesheetHmComponent implements OnInit {

  public hm = 'min';
  public gain_txt;
  public loss_weight_txt;
  public freq_tired;
  public fever_cills;
  public pres_medici;
  public pres_imagetxt: boolean;
  public presimage;
  public otc_mainlist = [];
  public med_format;
  public vitamin_list = [];
  public reaction_list = [];
  public allergy_list = [];
  public otc_label: boolean;
  public otc_txt;
  public otc_image: boolean;
  public vitamin_label: boolean;
  public vite_txt;
  public vitamin_image: boolean;
  public reaction_label: boolean;
  public resc_txt;
  public reaction_image: boolean;
  public allergic_label: boolean;
  public all_txt;
  public allergic_image: boolean;
  public header_footer_flag: boolean;
  public webcolflag : boolean;
  public subscription:Subscription;
  //Redaonly 
  isReadonly() { return true; }

  constructor(public dialog: MatDialog,private http:Http,private routes:ActivatedRoute,private router:Router,public toastr:ToastrService, public messageservice1: SecondOpinionService) { }

  ngOnInit(): void {
    this.subscription = this.messageservice1.getSecondOpinionMessage().subscribe(message => {
        if (message) {
            this.hm = message;
        }
    });
    this.otc_mainlist = [];
    this.vitamin_list = [];
    this.reaction_list = [];
    this.allergy_list = [];
    this.Get_Medication_retrivel();
  }
  
  //Get health and medication retrivel
  Get_Medication_retrivel() {
    var getOpnRet = Helper_Class.getOpnRet();
    this.gain_txt = getOpnRet.recent_weight_gain == 'false' ? 'No' : 'Yes';
    this.loss_weight_txt = getOpnRet.recent_weight_loss == 'false' ? 'No' : 'Yes';
    this.freq_tired = getOpnRet.tiredness_frequency == 'false' ? 'No' : 'Yes';
    this.fever_cills = getOpnRet.recent_fever_chills == 'false' ? 'No' : 'Yes';
    this.pres_medici = getOpnRet.prescribed_medicine == 'false' ? 'No' : 'Yes';

    if (getOpnRet.pres_med_doc != null && getOpnRet.pres_med_doc != "") {
      this.pres_imagetxt = false;
      this.presimage = ipaddress.Ip_with_img_address + getOpnRet.pres_med_doc;
    } else {
      this.pres_imagetxt = true;
    }

    //Otcc
    if (getOpnRet.otc_medicines != null) {
      for (var i = 0; i < getOpnRet.otc_medicines.length; i++) {
        if (getOpnRet.otc_medicines[i].intake_desc == "Every 6 hours") {
          this.med_format = getOpnRet.otc_medicines[i].morning;
        } else {
          this.med_format = getOpnRet.otc_medicines[i].morning + "-" + getOpnRet.otc_medicines[i].afternoon + "-" + getOpnRet.otc_medicines[i].evening;
        }

        this.otc_mainlist.push({
          medicine_name: getOpnRet.otc_medicines[i].medicine_name,
          dosage: getOpnRet.otc_medicines[i].dosage,
          side_effects: getOpnRet.otc_medicines[i].side_effects,
          intake_desc: getOpnRet.otc_medicines[i].intake_desc,
          medicine_total: this.med_format
        });
      }
    }
    if (this.otc_mainlist.length == 0) {
      this.otc_label = false;
      this.otc_txt = "No";
      this.otc_image = true;
    }
    else {
      this.otc_txt = "Yes";
      this.otc_image = false;
      this.otc_label = true;
    }

    //Vitamins or herbal 
    if (getOpnRet.vitamins != null) {
      for (var i = 0; i < getOpnRet.vitamins.length; i++) {
        if (getOpnRet.vitamins[i].intake_desc == "Every 6 hours") {
          this.med_format = getOpnRet.vitamins[i].morning;
        }else {
          this.med_format = getOpnRet.vitamins[i].morning + "-" + getOpnRet.vitamins[i].afternoon + "-" + getOpnRet.vitamins[i].evening;
        }
        this.vitamin_list.push({
          supplement_name: getOpnRet.vitamins[i].supplement_name,
          dosage: getOpnRet.vitamins[i].dosage,
          side_effects: getOpnRet.vitamins[i].side_effects,
          intake_desc: getOpnRet.vitamins[i].intake_desc,
          medicine_total: this.med_format
        });
      }
    }

    if (this.vitamin_list.length == 0) {
      this.vitamin_label = false;
      this.vite_txt = "No";
      this.vitamin_image = true;
    }else {
      this.vite_txt = "Yes";
      this.vitamin_label = true;
      this.vitamin_image = false;
    }
    //reaction
    if (getOpnRet.med_reaction != null) {
      for (var i = 0; i < getOpnRet.med_reaction.length; i++) {
        this.reaction_list.push({
          supplement_name: getOpnRet.med_reaction[i].supplement_name,
          reaction: getOpnRet.med_reaction[i].reaction,
        });
     }
    }
    if (this.reaction_list.length == 0) {
      this.reaction_label = false;
      this.resc_txt = "No";
      this.reaction_image = true;
    }else {
      this.reaction_label = true;
      this.reaction_image = false;
    }
    //Allergtic
    if (getOpnRet.allergies != null) {
      for (var i = 0; i < getOpnRet.allergies.length; i++) {
        this.allergy_list.push({
          medicine_name: getOpnRet.allergies[i].medicine_name,
          allergies: getOpnRet.allergies[i].allergies,
        });
      }
    }

    if (this.allergy_list.length == 0) {
      this.allergic_label = false;
      this.all_txt = "No";
      this.allergic_image = true;
    }else {
      this.allergic_label = true;
      this.allergic_image = false;
    }
  }

  Otc_click(data) {
    const dialogRef = this.dialog.open(SecondOpinionMedicationPopupComponent, {
      width: '600px',
      height: '400px',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  Vitamin_click(data) {
    const dialogRef = this.dialog.open(SecondOpinionMedicationPopupComponent, {
      width: '600px',
      height: '400px',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  Medication_click(data) {
    const dialogRef = this.dialog.open(SecondOpinionMedicationPopupComponent, {
      width: '600px',
      height: '400px',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  Allergic_click(data) {
    const dialogRef = this.dialog.open(SecondOpinionMedicationPopupComponent, {
      width: '600px',
      height: '400px',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  report_detail(report) {
    var get_file_type = null;
    if (report.indexOf("#") >= 0) {
      var report_data = report.split('#');
      get_file_type = report_data[1].split('.');

      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'adm/curdate',
        JSON.stringify({
          client_reg_id: Helper_Class.getOpnRet().client_reg_id,
          client_doc_id: report_data[0],
          document_name: report_data[1],
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
          },
          error => {
          }
        )

    }
    else {}
  }
}
