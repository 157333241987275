import { Component, OnInit } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { DiagnosisService } from '../diagnosis/diagnosis.service';
import { Diagnosis_Helper } from '../Diagnosis_Helper';
import { Message_data } from '../../../assets/js/Message_data';
import { ipaddress } from '../../ipaddress';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
@Component({
  selector: 'app-diagnosis-user-list',
  templateUrl: './diagnosis-user-list.component.html',
  styleUrls: ['./diagnosis-user-list.component.scss']
})
export class DiagnosisUserListComponent implements OnInit {

  public userType;
  public locationArray=[];
  public userArray=[];
  public location;
  public createcentre_id;
  public loader:boolean;
  public currentDate;
  public appDate;
  public diagLocation: boolean;
  dtOptions: DataTables.Settings = {};

  constructor(public messageservice:MenuViewService,public gservice:CommonDataService,public toastr:ToastrService, public  http:Http) {
  }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      "lengthMenu": [ [25, 50, -1], [25, 50, "All"] ],
      "columnDefs": [
        { "orderable": false, "targets": [2] },
      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search user by name or type"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    this.getLocation();
    this.getDate();
  }

  getLocation(){ //Get location
    this.locationArray = [];
    this.userType = Diagnosis_Helper.getUserID_Type().user_type != null ? "diag_user" : "diagnosis";

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/diagloc/',
      JSON.stringify({
        diag_name: Diagnosis_Helper.getUserID_Type().user_id, 
        flag: "id",
          imei: localStorage.getItem("ipaddress"), 
          prov_id: Diagnosis_Helper.getUserID_Type().user_id,
          type: this.userType 
      }),
      { headers: headers })
      .subscribe(
        response => {
            var obj = JSON.parse(response["_body"]);
            for (var i = 0; i <obj.diag_loc.length; i++) {
              this.locationArray.push({
                  centre_id: obj.diag_loc[i].diag_centre_id,
                  centre_name: obj.diag_loc[i].diag_centre_name,
                  centre_location: obj.diag_loc[i].diag_loc,
                  view_data: obj.diag_loc[i].diag_centre_name + " - " + obj.diag_loc[i].diag_loc
              });
            }

            if (this.locationArray.length > 1) {
              this.diagLocation = true;
            } else {
              this.diagLocation = false;
            }


            this.GetUser(this.locationArray[0].centre_id);
            this.location=this.locationArray[0].view_data
        },error => {
          this.toastr.error(Message_data.getNetworkMessage());
          this.loader=true;
        });
  }
  
  changeLocation(data){//based on location get Userslist
    if(this.locationArray!=undefined){
      for(var i=0; i<this.locationArray.length; i++){
        if(this.locationArray[i].view_data==data){
          this.GetUser(this.locationArray[i].centre_id);
        }
      }
    }
  }

  GetUser(centre_id){//Get tests
    this.loader=false;
    this.userArray=[];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/gadu/',
      JSON.stringify({
        diag_centre_id:centre_id
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.users != null)
            this.userArray = obj.users;
        },error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }
  
  deleteUser(userid){ //delete User list
    if(userid!=null){
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/ddu/',
        JSON.stringify({
          diag_users_id:userid
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            if (obj != null && obj.key == "1"){
              this.toastr.success(Message_data.userDetDeletedSuccess);
              if(this.locationArray!=undefined){
                for(var i=0; i<this.locationArray.length; i++){
                  if(this.locationArray[i].view_data==this.location){
                    this.GetUser(this.locationArray[i].centre_id);
                  }
                }
              }
              this.messageservice.sendMessage("users");
            }else{
              if (obj.result != null)
                this.toastr.error(obj.result);
              else 
                this.toastr.error(Message_data.testDetNotDeleted);
            }
          },error => {
            this.toastr.error(Message_data.getNetworkMessage());
          });
    }
  }
  
  createUser(){ //Create users
    Diagnosis_Helper.setDiag_user_reg_id(null);
    if(this.locationArray!=undefined){
      for(var i=0; i<this.locationArray.length; i++){
        if(this.locationArray[i].view_data==this.location){
          this.createcentre_id=this.locationArray[i].centre_id;
        }
      }
    }
    if (this.location != undefined){
      Diagnosis_Helper.setDiagnosis_id(this.createcentre_id);
      this.messageservice.sendMessage("users_view");
    }else
      this.toastr.error(Message_data.sltDiagCenter);
  }
  
  viewUser(data){
    Diagnosis_Helper.setDiag_user_reg_id(data);
    this.messageservice.sendMessage("users_view");
  }

  getDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'adm/curdate',
      JSON.stringify({
        country: ipaddress.country_code
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          
          if (obj.current_date != null) {
            this.currentDate = obj.current_date;
            this.appDate = this.currentDate;
            this.changeLocation(this.appDate);
          }
        },error => {});
  }
}
