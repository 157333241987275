<div class="row" style="margin:0 auto; width:100%">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Notification</h5>
          </div>
          <div class="headerButtons">
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="">
          <div class="row">
            <div [hidden]="notificationList.length!=0" class="nodata col-10">No notification(s) found</div>
            <div class="col-12" [hidden]="notificationList.length==0">
              <div class="table-responsive dig_table_overflow" *ngIf="notificationList.length">
                <table   mdbTable datatable [dtOptions]="dtOptions" id="tbl" class="table table-nowrap table-sm dataTable billcreae_table">
                <tr *ngFor="let list of notificationList">
                  <td (click)="expandNotification(list.notification_id)">Prescription extension request sent to Dr.
                    {{list.dr_name}}</td>
                </tr>
              </table>
              </div>
            </div>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>