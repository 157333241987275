<div class="row" style="margin:0 auto; width:100%">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Bill Details</h5>
          </div>
          <div class="headerButtons">
            <a printSectionId="print" (click)="print()"><img src="../../../assets/img/printer.svg"
                style="width: 25px !important;height: 25px !important;" class="printimagecss" /></a>
            <a (click)="back()"><img src="../../../assets/ui_icons/buttons/Back_button.svg"
                class="saveimgbtn_inpatinfo" /></a>
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div *ngFor="let billprint of billingDetails">
          <div class="row">
            <div class="col-12 col-sm-6 col-md-8 col-lg-8 col-xl-8">
              <div [hidden]="hospitalAddrFlag">
                <table>
                  <tr>
                    <td class="changefont"><b>{{billprint.hptl_name}}</b></td>
                  </tr>
                  <tr>
                    <td class="changefont">{{billprint.address}}</td>
                  </tr>
                  <tr>
                    <td class="changefont">{{billprint.location}}{{billprint.city}}</td>
                  </tr>
                  <tr>
                    <td class="changefont">{{billprint.state}}</td>
                  </tr>
                  <tr>
                    <td class="changefont"> <img class="phone_img" src="../../../assets/img/phone.png" width="20px"
                        height="20px" />
                      {{billprint.telephone}}</td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
              <table>
                <tr>
                  <th style="font-size: 12px;text-align: left !important;">Bill date : </th>
                  <td>
                    {{billprint.created_date}}
                  </td>
                </tr>
                <tr [hidden]="billAmtFlag">
                  <th style="font-size: 12px;text-align: left !important;">Bill Amount (र) :</th>
                  <td style="font-size: 12px;text-align: left !important;">
                    {{billprint.tot_amt}}
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="row chwidth">
            <div class="col-12 col-sm-6 col-md-8 col-lg-8 col-xl-8">
              <p class="col-12 col-md-6 col-lg-3 col-xl-3 col-sm-12" class="subheadingvalue ">Patient details</p>
              <table>
                <tr>
                  <th class="thvalue">Patient name </th>
                  <td class="thvalue">: {{billprint.client_name}}</td>
                </tr>
                <tr>
                  <th class="thvalue">Patient id </th>
                  <td class="thvalue">: {{billprint.client_reg_id}}</td>
                </tr>
              </table>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
              <p class="col-12 col-md-6 col-lg-3 col-xl-3 col-sm-12" class="subheadingvalue">Address</p>
              <table>
                <tr>
                  <td class="thvalue align_left">{{billprint.caddress}}</td>
                </tr>
                <tr>
                  <td class="thvalue align_left">{{billprint.clocation}}{{ billprint.clientcity}}</td>
                </tr>
                <tr>
                  <td class="thvalue align_left">{{billprint.clestare}}</td>
                </tr>
                <tr>
                  <td class="thvalue">{{billprint.ccountry}}</td>
                </tr>
              </table>
            </div>
          </div>
          <div class="row">
            <div class="col-12" [hidden]="billDetailViewFlag">
              <p class=" col-md-3 col-lg-3 col-xl-3 col-sm-3" *ngIf="billDetailView.length !=0"
                class="tervys_heading" style="font-weight: 500;">Bill particulars</p>
            </div>
            <div class="table-responsive dig_table_overflow" *ngIf="billDetailView.length">
              <table id="card_tbl" class="table table-nowrap table-sm">
                <thead class="thvalues">
                  <tr style="height: 30px;">
                    <th>Type</th>
                    <th>Fee</th>
                    <th>Concession</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr style="height: 30px;" *ngFor="let person of billDetailView">
                    <td style="font-size: 12px;text-align: left;">{{person.charge_desc}}</td>
                    <td style="font-size: 12px;text-align: right;">{{person.fee}}</td>
                    <td style="font-size: 12px;text-align: right;">{{person.concession}}</td>
                    <td class="billlistleft" style="font-size: 12px;text-align: right;">{{person.fee}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div [hidden]="treatmentPlanFlag">
            <p class="casesheet_para">Treatement plan</p>
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" [hidden]="treatmentPlanFlag">
            <table mdbTable datatable [dtOptions]="dtOptions" class="table table-nowrap table-sm dataTable">
              <thead>
                <tr>
                  <th>Plan</th>
                  <th class="tbl_txt_center">Estimate</th>
                  <th class="tbl_txt_center">Select</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let person of estimatedBill let i = index">
                  <td>{{person.treatment_desc}}</td>
                  <td class="tbl_txt_center">{{person.estimate}}</td>
                </tr>
              </tbody>
            </table>
            <br><br><br>
          </div>
          <div class="row">
            <div class="col-4 col-sm-4 col-md-8 col-lg-8 col-xl-8"></div>
            <div class="col-8 col-sm-8 col-md-4 col-lg-4 col-xl-4" style="padding-top: 20px;">
              <div class="row">
                <div class="col-8 col-sm-8 col-md-6 col-lg-8 col-xl-8 alignRight" [hidden]="totalFlag">
                  <mat-label class="matlabel ">Total र </mat-label>
                </div>
                <div class="col-4 col-sm-4 col-md-5 col-lg-4 col-xl-4 d-flex justify-content-end" [hidden]="totalFlag"
                  style="padding-right: 16px ;">
                  <td style="font-size: 12px;"><span class="">{{totalAmt}}</span></td>
                </div>
                <div class="col-8 col-sm-8 col-md-6 col-lg-8 col-xl-8 alignRight" [hidden]="cgstFlag">
                  <mat-label class="matlabel">Central GST {{billprint.cgst}}% </mat-label>
                </div>
                <div class="col-4 col-sm-4 col-md-5 col-lg-4 col-xl-4  d-flex justify-content-end" [hidden]="cgstFlag"
                  style="padding-right: 16px ;">
                  <td style="font-size: 12px;"><span class="">{{cgst}} </span></td>
                </div>
                <div class="col-8 col-sm-8 col-md-6 col-lg-8 col-xl-8 alignRight" [hidden]="sgstFlag">
                  <mat-label class="matlabel">State GST {{billprint.cgst}} %</mat-label>
                </div>
                <div class="col-4 col-sm-4 col-md-5 col-lg-4 col-xl-4 d-flex justify-content-end" [hidden]="sgstFlag"
                  style="padding-right: 16px ;">
                  <td style="font-size: 12px;"><span class="">{{sgst}} </span></td>
                </div>
                <div class="col-8 col-sm-8 col-md-6 col-lg-8 col-xl-8 alignRight" [hidden]="prevBalanceFlag">
                  <mat-label class="matlabel"> Previous balance र</mat-label>
                </div>
                <div class="col-4 col-sm-4 col-md-5 col-lg-4 col-xl-4  d-flex justify-content-end"
                  [hidden]="prevBalanceFlag" style="padding-right: 16px ;">
                  <td style="font-size: 12px;"><span class="">{{prevBalance}}</span></td>
                </div>
                <div class="col-8 col-sm-8 col-md-6 col-lg-8 col-xl-8 alignRight" [hidden]="totalFlag">
                  <mat-label class="matlabel "> Bill amount र</mat-label>
                </div>
                <div class="col-4 col-sm-4 col-md-5 col-lg-4 col-xl-4  d-flex justify-content-end" [hidden]="totalFlag"
                  style="padding-right: 16px ;">
                  <td style="font-size: 12px;"><span class="">{{billAmt}} </span></td>
                </div>
                <div class="col-8 col-sm-8 col-md-6 col-lg-8 col-xl-8 alignRight" [hidden]="advanceFlag">
                  <mat-label class="matlabel">Advance / Paid र</mat-label>
                </div>
                <div class="col-4 col-sm-4 col-md-5 col-lg-4 col-xl-4 d-flex justify-content-end"
                  [hidden]="advanceFlag" style="padding-right: 16px ;">
                  <td style="font-size: 12px;"><span class="">{{billprint.advance}} </span></td>
                </div>
                <div class="col-8 col-sm-8 col-md-6 col-lg-8 col-xl-8 alignRight">
                  <mat-label class="matlabel">Balance र </mat-label>
                </div>
                <div class="col-4 col-sm-4 col-md-5 col-lg-4 col-xl-4 d-flex justify-content-end"
                  style="padding-right: 16px ;">
                  <td style="font-size: 12px;"><span class="">{{billprint.balance}}</span></td>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div [hidden]="true">
          <div id="bill-print-section">
            <div class="bill_border">
              <div *ngFor="let billprint of billingDetails">
                <!-- Doctor details -->
                <table style="width: 100%;">
                  <tr>
                    <td style="display: flex;justify-content: center;">
                      <div [hidden]="hospitalAddrFlag">
                        <span><strong>{{billprint.hptl_name}}</strong>
                        </span>
                        <br/>
                        <span>{{billprint.address}}</span>
                        <span>{{billprint.location}}</span>
                        <br/>
                        <span>{{billprint.city}}{{billprint.state}}</span>
                        <br/>
                        <span>
                          <img src="../../../assets/img/phone.png" width="15px" height="15px" />
                          {{billprint.telephone}}
                        </span>
                      </div>
                    </td>
                  </tr>
                </table>
                <br />
                <!-- <h3 class="heading">Patient details</h3> -->
                <!-- Patient details -->
                <table style="margin-left: 75px;width: 100%;">
                  <tr>
                    <td style="width: 50%;">
                      <div class="row">
                        <div class="col-12 col-md-7 col-lg-8 col-xl-8">
                          <span><strong>Patient name : </strong>{{billprint.client_name}} </span>
                          <br/>
                          <span><strong> Patient no : </strong>{{billprint.client_reg_id}}</span>
                          <br/>
                          <span><strong>Bill date : </strong>{{billprint.created_date}} </span>
                          <br />
                        </div>
                      </div>
                    </td>
                    <td style="width: 50%;">
                      <div class="row">
                        <div class="amount_due col-12 col-md-5 col-lg-4 col-xl-4">
                          <span><strong><br />Address</strong></span>
                          <br />
                          <span> {{billprint.caddress}}</span>
                          <br />
                          <span>{{billprint.clocation}}{{ billprint.clientcity}}</span>
                          <br />
                          <span>{{billprint.clestare}}</span>
                          <br />
                          <span>{{billprint.ccountry}}</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                </table>
                <div [hidden]="treatmentPlanFlag">
                  <div class="">
                    <div class="casesheet_icon">
                      <i class="fas fa-medkit" aria-hidden="true"></i>
                    </div>
                    <h3 class="heading">Treatment plan</h3>
                  </div>
                  <div>
                    <div class=" row main_class">
                      <div class=" col-12 col-md-12 col-lg-12 col-xl-12">
                        <table class="rwd-table normal_table_class">
                          <tr>
                            <th>Plan</th>
                            <th>Estimate</th>
                          </tr>
                          <tr *ngFor="let estimate of estimatedBill">
                            <td data-th="Plan">{{estimate.treatment_desc}}</td>
                            <td data-th="Estimate">{{estimate.estimate}}</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Bill particulars -->
                <div [hidden]="billDetailViewFlag" style="margin-left: 75px;margin-right: 40px; width: 800px;">
                  <h3 class="heading">Bill particulars</h3>
                  <div class="row main_class">
                    <table style=" border: 1px solid black;border-collapse: collapse;margin-right: 40px;">
                      <tr class="table_class">
                        <th style="width: 200px;border: 1px solid black;border-collapse: collapse;">Service</th>
                        <th style="width: 200px;border: 1px solid black;border-collapse: collapse;">Fee</th>
                        <th style="width: 200px;border: 1px solid black;border-collapse: collapse;"> Concession</th>
                        <th style="width: 200px;border: 1px solid black;border-collapse: collapse;">Amount</th>
                      </tr>
                      <tr *ngFor="let billprinttable of billDetailView">
                        <td style="width: 200px; text-align: left;border: 1px solid black;border-collapse: collapse;" data-th="Type">{{billprinttable.charge_desc}}</td>
                        <td style="width: 200px; text-align: right;border: 1px solid black;border-collapse: collapse;" data-th="Fee">{{billprinttable.fee}}</td>
                        <td style="width: 200px; text-align: right;border: 1px solid black;border-collapse: collapse;" data-th="Concession">{{billprinttable.concession}}
                        </td>
                        <td style="width: 200px; text-align: right;border: 1px solid black;border-collapse: collapse;" data-th="Amount" class="fee_txt">
                          {{billprinttable.fee}}
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <br />
                <!-- Total calculation table -->
                <table class="col-3 col-md-5 col-lg-3 col-xl-3" style="float: right;margin-right: 36px;">
                  <tr [hidden]="totalFlag">
                    <td style="width: 510px;background-color: #ffffff;"></td>
                    <th style="text-align: left;">Total (र) </th>
                    <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="Total र" class="fee_txt">
                      {{totalAmt}}</td>
                  </tr>
                  <tr [hidden]="cgstFlag">
                    <td style="width: 510px;background-color: #ffffff;"></td>
                    <th style="text-align: left;"><strong>Central GST</strong> % </th>
                    <td style="text-align: right; width: 100px;margin-right: 10px;" data-th="Central GST"
                      class="fee_txt">
                      {{cgst}}</td>
                  </tr>
                  <tr [hidden]="sgstFlag">
                    <td style="width: 300px;background-color: #ffffff;"></td>
                    <th style="text-align: left;"><strong>State GST </strong> % </th>
                    <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="State GST" class="fee_txt">
                      {{sgst}}</td>
                  </tr>
                  <tr [hidden]="prevBalanceFlag">
                    <td style="width: 300px;background-color: #ffffff;"></td>
                    <th style="text-align: left;">Previous balance (र)</th>
                    <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="Previous balance र"
                      class="fee_txt">{{prevBalance}}</td>
                  </tr>
                  <tr [hidden]="totalFlag">
                    <td style="width: 300px;background-color: #ffffff;"></td>
                    <th style="text-align: left;">Bill amount (र)</th>
                    <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="Bill amount"
                      class="fee_txt">
                      {{billAmt}}</td>
                  </tr>
                  <tr [hidden]="advanceFlag">
                    <td style="width: 300px;background-color: #ffffff"></td>
                    <th style="text-align: left;">Advance / Paid (र)</th>
                    <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="Advance / Paid र"
                      class="fee_txt">
                      {{billprint.advance}}</td>
                  </tr>
                  <tr>
                    <td style="width: 300px;background-color: #ffffff;"></td>
                    <th style="text-align: left;">Balance (र)</th>
                    <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="Balance" class="fee_txt">
                      {{billprint.balance}}</td>
                  </tr>
                </table><br /><br /><br />
              </div>
              <br />
              <!-- //Treate ment plan table -->
              <br />
              <br />
              <br /><br />
            </div>
            <br />
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>