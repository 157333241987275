import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { ipaddress.getIp, ipaddress.Ip_with_img_address, ipaddress.country_code } from '../../../assets/js/ipaddress';
import { Http, Headers } from '@angular/http';
import { Helper_Class } from '../../helper_class';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
// import { Time_Formate_Session } from '../../assets/js/common';
import { Date_Formate, Time_Formate, Time_Formate_Session } from '../../../assets/js/common.js';
import { Data, Route, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Helper_nephro_casesheet } from '../../../assets/js/Helper_nephro_casesheet';
import { FormControl } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { Doc_Helper } from '../Doc_Helper';
import { DoctorViewService } from '../doctor-view/doctor-view.service';
import { GynaObestricPopSelectionComponent } from '../gyna-obestric-pop-selection/gyna-obestric-pop-selection.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ipaddress } from '../../ipaddress' ;import { Master_Helper } from '../Master_Helper';
import { Message_data } from 'src/assets/js/Message_data';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
@Component({
  selector: 'app-homecare',
  templateUrl: './homecare.component.html',
  styleUrls: ['./homecare.component.css']
})
export class HomecareComponent implements OnInit {

  tomorrow = new Date();

  public Is_Gynecology;
  public Is_specilization;
  public Gynecology_flag: boolean = false;
  public Pediatrics_flag: boolean = false;
  public Cardio_flag: boolean = false;
  public Nephrology_flag: boolean = false;
  public Others_flag: boolean = false;
  public Dental_flag: boolean = false;
  public Diabetes_flag: boolean = false;
  public submenu_flag: boolean = false;
  public submenu_flag1: boolean = false;
  public General_flag: boolean = false;
  public ENT_flag: boolean = false;
  public checklistid: any = [];
  public nodapp: boolean;
  public home_care: string;
  public CurrentDatetime;
  public Appointment_Date;
  public calendardate;
  public flagval: boolean;
  public date_pick: string[] = ["docapp"];
  public spl_txtname;
  public spl_txt = [];
  public statuscolor;
  public appt;
  public sub_rel

  public hospital_info = [];
  public hospital_id;

  public get_app_date;
  public specializations = [];
  public age_txt: string;
  public appointment_list = [];

  public first_name: string;
  public url = ipaddress.getIp + "usercontroller/providerdetails";
  public last_name: string;
  public messages;
  public msgflag: boolean = false;
  public notifications;
  public notifyflag: boolean = false;

  public hospital_logo;
  public hospital_name;
  public hospital_details;
  public spl_id: string;
  public case_sheet: string;
  public doctor_profile_img: string;
  public doc_reg;
  public userinfo: any;
  public user_id: any;
  public obj: any;
  public data: any;
  public urlt = ipaddress.getIp + 'appointment/getdocapp';
  public spl_url;

  public hptl_clinic_id;
  public doc_app_id;
  public currentDate;
  public textalign;
  public height;
  public width;
  public appin_open_count: number = 0;
  //mdbTable
  dtOptions: DataTables.Settings = {};
  persons: any = [];
  masterSelected: boolean = false;
  checklist: any;
  checkedList: any

  constructor(public dialog: MatDialog,private http: Http, private router: Router, private toastr: ToastrService, public doctormessageservice: MenuViewService) {
    Doc_Helper.setAppFlow("home_case_sheet");
    Doc_Helper.setClient_Info(null);
    Doc_Helper.setCasesheet_flag("1");
  }

  ngOnInit(): void {
    this.Appointment_Date = Doc_Helper.getHomecareDate();
    this.getCurrentDate();
    Doc_Helper.setHome_care("1");
    Helper_Class.setapp_flow("1");

    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 25,
      "lengthMenu": [[25, 50, -1], [25, 50, "All"]],
      "order": [[3, 'asc']],
      "columnDefs": [
        { "orderable": false, "targets": [0, 1, 2, 4, 5, 6] },
      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search by name or mobile no or patient id"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 d-flex justify-content-end text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };

    this.userinfo = Helper_Class.getInfo();
    this.user_id = this.userinfo.user_id;
    this.hospital_id = this.userinfo.hospitals[0].hptl_clinic_id;

    for (var j = 0; j < this.userinfo.specializations.length; j++) {
      this.specializations[j] = this.userinfo.specializations[j].spl_name;
    }
  }

  Appointment_confirm() {
    if (this.checklistid != "" && this.checklistid.length != 0) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'appointment/confappb',
        JSON.stringify({
          type: "doctor",
          doc_app_ids: this.checklistid,
          home_care: "1",
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            
            if (obj.key != null) {
              if (obj.key == "1") {
                this.toastr.success(Message_data.appconf);
                this.get_doc_apps(this.user_id);
              }
            }
          },
          error => {
            this.toastr.error(Message_data.defaultErr);
          }
        )
    } else {
      this.toastr.error(Message_data.sltPatientForConfirm);
    }

  }

  cancel() {
    if (this.checklistid != "" && this.checklistid.length != 0) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'appointment/cancelapp',
        JSON.stringify({
          doc_cancel: this.checklistid,
          home_care: "1",
          // reason: data.rea_txt
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            if (obj.key != null) {
              if (obj.key == "1") {
                this.toastr.success(Message_data.appcancel);
                this.get_doc_apps(this.user_id);
              }
            }
          },
          error => {
            this.toastr.error(Message_data.defaultErr);
          }
        )
    } else {
      this.toastr.error(Message_data.sltPatientForCancel);
    }
  }

  ChangeMatePicker(date_value) {
    var apt = new FormControl(new Date(date_value));
    return apt;
  }

  spiltdate(date) {
    var get_tmp = date.split("-");
    var date_appt = get_tmp[2] + "-" + get_tmp[1] + "-" + get_tmp[0];
    return date_appt;
  }

  OnDateMatePicker(e) {
    Doc_Helper.setHomecareDate(null);
    this.Appointment_Date = e;
    Doc_Helper.setHomecareDate(e);
    this.get_doc_apps(this.user_id);
  }

  getDateMatePicker(e) {
    var month = ((e.value.getMonth() + 1) < 10 ? '0' : '') + (e.value.getMonth() + 1);
    var day = (e.value.getDate() < 10 ? '0' : '') + e.value.getDate();
    var apt = e.value.getFullYear() + "-" + month + "-" + day;
    return apt.trim();
  }

  isReadonly() { return true; }

  get_doc_apps(user_id): void {
    this.home_care = "1";
    this.appointment_list = [];
    var sen_data = JSON.stringify({
      home_care: this.home_care,
      date: this.Appointment_Date,
      doc_prov_id: user_id,
      country: "IN",
      type: "doctor"
    });
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'appointment/getdocapp', sen_data, { headers: headers }).subscribe(
      response => {
        
        var data = response.json();
        Helper_Class.setpatient_information(data);

        if (data != null || data != undefined || data != "") {
          if (data.appointments != null && data.appointments.length != 0) {
            this.appt = data.appointments;
            this.nodapp = false;
            for (var i = 0; i < this.appt.length; i++) {
              var get_age;
              if (this.appt[i].age != null && this.appt[i].age != undefined && this.appt[i].age != " ") {
                get_age = this.appt[i].age;
              } else {
                get_age = "";
              }
              var Patient_name;
              if (this.appt[i].middle_name != undefined && this.appt[i].middle_name != null) // middle name 
              {
                Patient_name = encrypt_decript.Decript(this.appt[i].first_name) + " " + encrypt_decript.Decript(this.appt[i].middle_name) + " " + encrypt_decript.Decript(this.appt[i].last_name);
              } else {
                Patient_name = encrypt_decript.Decript(this.appt[i].first_name) + " " + encrypt_decript.Decript(this.appt[i].last_name);
              }
              if (this.appt[i].mobile != null) {
                var contactno = encrypt_decript.Decript(this.appt[i].mobile);
              }
              var profile_image = ipaddress.Ip_with_img_address + this.appt[i].profile_image;
              var lastvisit;
              if (this.appt[i].date != null) {
                var last_visit = this.appt[i].date;
                var gettmp = last_visit.split("-");
                lastvisit = gettmp[2] + "-" + gettmp[1] + "-" + gettmp[0];
              }
              var status_txt;
              if (this.appt[i].status == "0") {
                status_txt = "Completed";
              } else if (this.appt[i].status == "1") {
                if (this.CurrentDatetime <= this.appt[i].date) {
                  if (this.appt[i].f_time != "1") {
                   
                    var get_time = new Date(this.appt[i].date + " " + this.CurrentDatetime);
                    var cur_time = new Date(this.CurrentDatetime + " " + this.CurrentDatetime);
                    if (get_time > cur_time) {
                      status_txt = "Not visited";
                    } else {
                      status_txt = "Open";
                    }
                  } else {
                    var get_time = new Date(this.appt[i].date);
                    var cur_time = new Date(this.CurrentDatetime);
                    if (get_time >= cur_time) {
                      status_txt = "Open";
                    } else {
                      status_txt = "Not visited";
                    }
                  }
                } else {
                  if (this.appt[i].date > this.CurrentDatetime || this.appt[i].date == this.CurrentDatetime) {
                    status_txt = "Open";

                  } else {
                    status_txt = "Not visited";
                  }
                }
              } else if (this.appt[i].status == "2") {
                if (this.appt[i].date == this.CurrentDatetime || this.CurrentDatetime < this.appt[i].date) {
                  var get_time = new Date(this.appt[i].date + " " + this.CurrentDatetime);
                  var cur_time = new Date(this.CurrentDatetime + " " + this.CurrentDatetime);
                  status_txt = "Confirmed";
                } else {
                  if (this.appt[i].date > this.CurrentDatetime || this.appt[i].date == this.CurrentDatetime) {
                    status_txt = "Open";
                  } else {
                    status_txt = "Not visited";
                  }
                }
              }
              if (this.appt[i].sub_rel_id != null) {
                this.sub_rel = this.appt[i].sub_rel_id;

              } else {
                this.sub_rel = "";
              }
              var dateofbirth
              if (this.appt[i].dob != null) {
                dateofbirth = this.spiltdate(this.appt[i].dob);
              }
              if (this.appt[i].symptoms != null) {
                var symptom = this.appt[i].symptoms
              }

              if (this.appt[i].session != null) {
                var session = encrypt_decript.Decript(this.appt[i].session);
              }

              if (this.appt[i].token != null) {
                var token_time_label = this.appt[i].token;

              } else {
                var Temptimesplit = this.appt[i].f_time;
                var offTime = Temptimesplit.split(':');
                offTime[0] = offTime[0] % 12;
                var output = offTime.join(':');

                var get_timeq = output.split(":");
                if (get_timeq[0] == "0") {
                  // output="12:00:00";
                  output = "12" + ":" + get_timeq[1];
                }

                token_time_label = Time_Formate_Session(output, encrypt_decript.Decript(this.appt[i].session));
              }

              if( status_txt == 'Open'){
                this.appin_open_count =  this.appin_open_count + 1; 
              }

              if(this.appt[i].location != undefined){
                var location_desc=this.appt[i].location
              }
              if(this.appt[i].city != undefined){
                var city=this.appt[i].city
              }
              if(this.appt[i].state != undefined){
                var state=this.appt[i].state
              }
              if( this.appt[i].country != undefined){
                var country=this.appt[i].country
              }
              if( this.appt[i].zipcode != undefined){
                var zipcode=this.appt[i].zipcode
              }
              if( this.appt[i].height != undefined){
                var height=this.appt[i].height
              }
              if( this.appt[i].height_measure != undefined){
                var height_measure=this.appt[i].height_measure
              }
              if( this.appt[i].weight != undefined){
                var weight=this.appt[i].weight
              }
              if( this.appt[i].weight_measure != undefined){
                var weight_measure=this.appt[i].weight_measure
              }

              this.appointment_list.push({
                app_data: this.appt[i],
                patient_name: Patient_name,
                patient_id: this.appt[i].pat_id,
                age: get_age,
                status: status_txt,
                session: session,
                contact: contactno,
                lastvisit: lastvisit,
                profile_image: profile_image,
                spl_id: this.appt[i].specialization_id,
                hptl_clinic_id: this.appt[i].hptl_clinic_id,
                doc_app_id: this.appt[i].doc_app_id,
                gender_data: encrypt_decript.Decript(this.appt[i].gender),
                medicare_name: this.appt[i].medicare_name,
                client_reg_id: this.appt[i].client_reg_id,
                relation_id: this.appt[i].relation_id,
                sub_rel: this.sub_rel,
                spl: this.appt[i].specialization,
                relationship_name: this.appt[i].relationship_name,
                dob_data: dateofbirth,
                tot_app_count: token_time_label,
                // token: this.appt[i].token,
                symptoms: this.appt[i].symptoms,
                refer_by: this.appt[i].referred_by,
                address1:this.appt[i].address1,
                address2:this.appt[i].address2,
                location:location_desc,
                city:city,
                state:state,
                country:country,
                country_id:this.appt[i].country_id,
                state_id:this.appt[i].state_id,
                city_id:this.appt[i].city_id,
                zipcode:zipcode,
                height:height,
                weight:weight,
                height_measure:height_measure,
                weight_measure:weight_measure,
                temparature:this.appt[i].temparature,
                bp:this.appt[i].blood_pressure,
                pulse:this.appt[i].pulse,
              });
              Helper_Class.setPatientAppointment(this.appointment_list);
            }
          } else {
            this.appointment_list = [];
            this.nodapp = true;
           
          }
        }

      })
  }

  Doctor_View(list, doc_app_id, cnt_id, rel_id, sub_id, spl, count, stat, cit, loc, age, gen, dob, patient_name, medicare_name, hosp_name, rel_loc_desc, address1, address2, state_desc, hptl_name, city_desc, country_desc, rel_zipcode, hptl_clinic_id, app_data, spl_id, l_visit, image, relationship_name, mobile, symptoms, ref_by, height, height_measure, weight, weight_measure) {
    var patient_list: any = [];
    patient_list = {
      Age_data: list.age,
      Gender_data:list.gender_data,
      app_id: list.doc_app_id,
      client_name: list.patient_name,
      image: list.profile_image,
      sub_id: list.sub_rel,
      Client_id: list.client_reg_id,
      rel_id: list.relation_id,
      Case_Clnt_dob: list.dob_data,
      relationship_name:list.relationship_name,
      hptl_clinic_id:list.hptl_clinic_id,
      spl_id: list.spl_id,
      spl: list.spl,
      mobile:list.contact,
      symptoms:list.symptoms,
      Appoint_Date: list.lastvisit,
      medicare_name : medicare_name,
      refer_by:list.refer_txt,
      address1:list.address1,
      address2:list.address2,
      location:list.location,
      city:list.city,
      state:list.state,
      country:list.country,
      zipcode:list.zipcode,
      hptl_name:hosp_name,
      height:list.height,
      weight:list.weight,
      height_measure:list.height_measure,
      weight_measure:list.weight_measure,
      patient_id:list.patient_id,
      AppFlow: "home_case_sheet",
      country_id:list.country_id,
      state_id:list.state_id,
      city_id:list.city_id,

    };

    Doc_Helper.setClient_Info(patient_list);

    this.spl_id = spl_id;
    this.hptl_clinic_id = hptl_clinic_id;
    this.doc_app_id = this.doc_app_id;
    Helper_Class.setspl_id(this.spl_id);
    var oflag: boolean = false;
    this.Is_specilization = spl;
    if (this.Is_specilization == "Gynecology") {
      this.Gynecology_flag = true;
      oflag = true;
    }
    else if (this.Is_specilization == "Pediatrics") {
      this.Pediatrics_flag = true;
      oflag = true;
    }
    else if (this.Is_specilization == "Cardiology") {
      this.Cardio_flag = true;
      oflag = true;
    }
    else if (this.Is_specilization == "Nephrology") {
      this.Nephrology_flag = true;
      oflag = true;
    }
    else if (this.Is_specilization == "Dental") {
      this.Dental_flag = true;
      oflag = true;
    }
    else if (this.Is_specilization == "Diabetes") {
      this.Diabetes_flag = true;
      oflag = true;
    }
    else if (this.Is_specilization == "General" || this.Is_specilization == "General surgery") {
      this.General_flag = true;
      oflag = true;
    }else if (this.Is_specilization == "ENT") {
      this.ENT_flag = true;
      oflag = true;
    }
    else {
      if (oflag == false) {
        this.Others_flag = true;
      }
    }

    this.spl_id = spl_id;
    this.hptl_clinic_id = hptl_clinic_id;
    this.doc_app_id = this.doc_app_id;
    Helper_Class.setspl_id(this.spl_id);

    if (this.Gynecology_flag == true) {
      this.Get_gynaretrieval_data();
    }
    if (this.Gynecology_flag == false) {
      this.getcasesheetDetails();
    }
  }

  getcasesheetDetails() {
    Helper_Class.setRet(null);
    Helper_Class.setpediaRet(null);

    if (Doc_Helper.getClient_Info().sub_id.length == 0) {
      var send_data = JSON.stringify({
        doc_reg_id: this.user_id,
        client_reg_id: Doc_Helper.getClient_Info().Client_id,
        relation_id: Doc_Helper.getClient_Info().rel_id,
        country: "IN",
      })
    }
    else {
      send_data = JSON.stringify({
        doc_reg_id: this.user_id,
        client_reg_id: Doc_Helper.getClient_Info().Client_id,
        relation_id: Doc_Helper.getClient_Info().rel_id,
        sub_rel_id: Doc_Helper.getClient_Info().sub_id,
        country: "IN",
      })
    }
    if (this.Pediatrics_flag == true) {
      this.spl_url = 'pedia/chist';
    }
    else if (this.Cardio_flag == true) {
      this.spl_url = 'cardio/chist';
    }
    else if (this.Nephrology_flag == true) {
      this.spl_url = 'neph/chist';
    }
    else if (this.Dental_flag == true) {
      this.spl_url = 'dental/perdet/';
    }
    else if (this.Diabetes_flag == true) {
      this.spl_url = 'diab/chist';
    }
    else if (this.General_flag == true) {
      this.spl_url = 'gen/chist';
    }else if (this.ENT_flag == true) {
      this.spl_url = 'ent/chist';
    }
    else {
      this.spl_url = 'other/chist';
    }
    
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + this.spl_url, send_data,
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          
          if (obj["key"] != 0) {
            if (obj != null || obj != "" || obj != undefined) {
              
              Helper_Class.setRet(obj);
              if (this.Pediatrics_flag == true) {
                Helper_Class.setpediaRet(obj);
              }
              if (this.Nephrology_flag == true) {
                Helper_nephro_casesheet.nephro_get_ret = undefined;
                Helper_nephro_casesheet.personal_hist_array = undefined;
                Helper_nephro_casesheet.bmi_hist_array = undefined;
                Helper_nephro_casesheet.vitals_sit_array = undefined;
                
                Helper_nephro_casesheet.nephro_get_ret = obj;
              }

              if (this.Dental_flag == true) {
                
                Helper_Class.Set_den_ret1(obj);
                this.Dental_Retrieval_2();
              }
              if (this.General_flag == true) {
                if (obj.spl_name != undefined) {
                  Helper_Class.setOtherSpl(obj.spl_name);
                }
              }
              if (this.Dental_flag != true && this.Nephrology_flag != true) { 
                this.doctormessageservice.sendMessage("casesheet_list");
              }else{
                this.getCaseSheetFields(Doc_Helper.getClient_Info().hptl_clinic_id, Doc_Helper.getClient_Info().spl_id, Doc_Helper.getClient_Info().doc_app_id);
              }
              
            }
          }else {
            if(Doc_Helper.getCasesheet_flag() == "1"){
                this.getCaseSheetFields(Doc_Helper.getClient_Info().hptl_clinic_id, Doc_Helper.getClient_Info().spl_id, Doc_Helper.getClient_Info().doc_app_id);
            }else{
              this.getCaseSheetFields(Doc_Helper.getClient_Info().hptl_clinic_id, Doc_Helper.getClient_Info().spl_id, Doc_Helper.getClient_Info().doc_app_id);
            }
          }        
        },//response
        error => {
          //this.toastr.error("Internet is too slow or no connection, try again");
        })
  }

  Get_gynaretrieval_data() {
    Helper_Class.setgynaRet(undefined)
    Helper_Class.setRet(undefined)

    if (Doc_Helper.getClient_Info().sub_id.length != 0) {
      var sub_id_data = Doc_Helper.getClient_Info().sub_id;
      var sendata = JSON.stringify({
        doc_reg_id: this.user_id,
        client_reg_id: Doc_Helper.getClient_Info().Client_id,
        relation_id: Doc_Helper.getClient_Info().rel_id,
        sub_rel_id: sub_id_data,
        country: ipaddress.country_code
      });

    } else {
      sendata = JSON.stringify({
        doc_reg_id: this.user_id,
        client_reg_id: Doc_Helper.getClient_Info().Client_id,
        relation_id: Doc_Helper.getClient_Info().rel_id,
        country: ipaddress.country_code
      })
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'goi/chist', sendata,
      { headers: headers })
      .subscribe(
        response => {
          
          
          var obj = response.json();
          if(obj["key"] != 0){
            if(obj != null && obj != undefined && obj != ""){
              Helper_Class.setgynaRet(obj);
              Helper_Class.setRet(obj);
              this.doctormessageservice.sendMessage("casesheet_list");
            }
          }else{
            this.getCaseSheetFields(Doc_Helper.getClient_Info().hptl_clinic_id, Doc_Helper.getClient_Info().spl_id, Doc_Helper.getClient_Info().doc_app_id);
          }
        },
        error => {
          //this.toastr.error("Internet is too slow or no connection, try again");
        }
      )
    // this.getCaseSheetFields(Doc_Helper.getClient_Info().hptl_clinic_id, Doc_Helper.getClient_Info().spl_id, Doc_Helper.getClient_Info().doc_app_id);
  }

  getCaseSheetFields(hptl_id, spl_id, doc_app_id) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/gpfhsp/',
      JSON.stringify({
        hosp_id: hptl_id,
        spl_id: spl_id
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          
          Helper_Class.setHopitalfieldsRet(null);
          Helper_Class.setHopitalfieldsRet(obj.pages);
          this.get_case_sheet_data(this.doc_app_id)
        },//response
      )
  }

  get_case_sheet_data(doc_app_id) {
    // call cbook
    var sen_pass
    if (Doc_Helper.getClient_Info().sub_id != null) {
      sen_pass = JSON.stringify({
        doc_reg_id: this.user_id,
        client_reg_id: Doc_Helper.getClient_Info().Client_id,
        relation_id: Doc_Helper.getClient_Info().rel_id,
        sub_rel_id: Doc_Helper.getClient_Info().sub_id,
        country: ipaddress.country_code
      });

    } else {
      sen_pass = JSON.stringify({
        doc_reg_id: this.user_id,
        client_reg_id: Doc_Helper.getClient_Info().Client_id,
        relation_id: Doc_Helper.getClient_Info().rel_id,
        country: ipaddress.country_code
      });
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'gen/cbook', sen_pass,
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          
          if (obj["key"] != 0) {
            if (obj != null && obj.length != 0 && obj != undefined && obj != "") {
              Doc_Helper.setDischarge_Summary("S");
              Helper_Class.setcasesumret(obj);
            }else {
              Helper_Class.setcasesumret(undefined);
            }
          } else {
            Helper_Class.setcasesumret(undefined);
          }
          if (this.Gynecology_flag == true) {
            const dialogRef = this.dialog.open(GynaObestricPopSelectionComponent, {
              width: '280px',
              height: '182px'
            });
          }
          else {
            this.doctormessageservice.sendMessage("casesheet");
          }

        },
        error => {
          //this.toastr.error("Internet is too slow or no connection, try again");
        }
      )
  }

  Dental_Retrieval_2() {
    // this.loader = false;
    if (Doc_Helper.getClient_Info().sub_id.length != 0) {
      var sen_pass = JSON.stringify({
        client_reg_id: Doc_Helper.getClient_Info().Client_id,
        relation_id: Doc_Helper.getClient_Info().rel_id,
        sub_rel_id: Doc_Helper.getClient_Info().sub_id,
        country: ipaddress.country_code,
        den_case_hist_id: Helper_Class.Get_den_ret1().den_case_hist_id,
      });

    } else {
      var sen_pass = JSON.stringify({
        client_reg_id: Doc_Helper.getClient_Info().Client_id,
        relation_id: Doc_Helper.getClient_Info().rel_id,
        country: ipaddress.country_code,
        den_case_hist_id: Helper_Class.Get_den_ret1().den_case_hist_id,
      });
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'dental/patdet/', sen_pass,
      { headers: headers })
      .subscribe(
        response => {
          
          //  this.loader = true;
          var obj = response.json();
          Helper_Class.Set_den_ret2(obj);
        }
      )
  }

  getCurrentDate() {
    var obj = Master_Helper.getMasterCurrentDate();
    
    if (obj.current_date != null) {
      this.currentDate = obj.current_date;
      this.CurrentDatetime = obj.current_date;
      this.get_doc_apps(this.user_id);
    }
  }

  checkUncheckAll() {
    this.checklist = this.appointment_list;
    if (this.checklistid != "" && this.checklistid.length != 0) {
      if (this.checklistid.length == this.appin_open_count) {
        // uncheck all
        this.checklistid = [];
        for (var i = 0; i < this.checklist.length; i++) {
          this.checklist[i].isSelected = this.masterSelected;
        }
      } else {
        //  check all with array not empty        
        this.checklistid = [];
        for (var i = 0; i < this.checklist.length; i++) {
          if (this.checklist[i].status == 'Open') {
            var doc_id = this.checklist[i].doc_app_id;
            this.checklistid.push(doc_id);
            this.checklist[i].isSelected = this.masterSelected;
          }
        }
      }

    } else {
      // array empty
      for (var i = 0; i < this.checklist.length; i++) {
        if (this.checklist[i].status == 'Open') {
          var doc_id = this.checklist[i].doc_app_id;
          this.checklistid.push(doc_id);
          this.checklist[i].isSelected = this.masterSelected;
        }
      }

    }

  }

  isAllSelected(appid) {
    if (this.checklistid != "" && this.checklistid.length != 0) {
      if (this.checklistid.indexOf(appid) !== -1) {
        var index = this.checklistid.indexOf(appid);
        this.checklistid.splice(index, 1);
      } else {
        this.checklistid.push(appid);
      }
    } else {
      this.checklistid.push(appid);
    }
  }

  getCheckedItemList() {
    this.checkedList = [];
    for (var i = 0; i < this.checklist.length; i++) {
      if (this.checklist[i].isSelected)
        this.checkedList.push(this.checklist[i].patient_id);
    }
    //this.checkedList = this.checkedList;
    if (this.checklist.length == this.checkedList.length) {
      //this.checkedList = `All rows are selected`;
      this.toastr.success(Message_data.allRowSlt);
    } else if (this.checkedList.length != 0) {
      //this.checkedList = `selected Id: ${this.checkedList}`;
      this.toastr.success('Thank you...', `selected Id: ${this.checkedList}`);
    } else {
      this.checkedList = `No row seleted`;
      this.toastr.success(Message_data.noRowSlt);
    }
  }

  clearAll() {
    this.checklist = this.appointment_list;
    for (var i = 0; i < this.checklist.length; i++) {
      this.checklist[i].isSelected = false;
    }
    this.masterSelected = false;
    this.checkedList = [];
  }
 
}
