import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { ipaddress } from '../../ipaddress';

@Component({
  selector: 'app-client-help',
  templateUrl: './client-help.component.html',
  styleUrls: ['./client-help.component.css']
})
export class ClientHelpComponent implements OnInit {
  
  dtOptions: DataTables.Settings = {};
  public packId: string;
  public userType: string;
  public helpModule=[];
  public helpList = [];
  public module;

  constructor(public gservice:CommonDataService,public http:Http,public routes:ActivatedRoute,public router:Router,public toastr:ToastrService) { 
    this.userType ="1";
  }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 5,
      "lengthMenu": [[5, 10, 25, 50, -1], [5, 10, 25, 50, "All"]],
      "columnDefs": [
        { "orderable": false, "targets": [0, 1, 2, 3] },
      ],
      "language": {
        "search": "Filter"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12">><"table-responsive"t><"row mt-1 d-flex"<"col-xs-12 col-sm-6 col-md-4"l><"col-xs-12 col-sm-12 col-md-4 text-center sm-order-last"i><"col-xs-12 col-sm-6 col-md-4"p>>'
    };

    this.packId = this.gservice.Client_login_detail_data.package_id;
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "usercontroller/gmodules",
      JSON.stringify({
        user_type: this.userType,
        package_id:this.packId
      }),
      { headers: headers })
      .subscribe(
        response => {
          this.helpModule=[];
          var obj = response.json();
          if(obj.length !=0 && obj.modules != undefined){
            for(var i=0;i<obj.modules.length;i++){
              this.helpModule.push({
                module_id: obj.modules[i].module_id,
                description: obj.modules[i].description
              });
            }
            this.module = this.helpModule[0].module_id;
          }
        });
  }

  helpModuleChange(data){
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "usercontroller/ghelp",
      JSON.stringify({
        module_id: data,
      }),
      { headers: headers })
      .subscribe(
        response => {
          this.helpList=[];
          var obj = response.json();
          
          if(obj.length !=0 && obj.help_topics != undefined){
            for(var i = 0; i < obj.help_topics.length; i++){
              this.helpList.push({
                helpdesc: obj.help_topics[i].help_desc,
              });
            }
          }
        });
  }
}
