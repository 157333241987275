import { Component, OnInit, HostListener, Directive, ViewChild, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from '../../helper_class';
import { Http, Headers } from '@angular/http';
import { Observable, Observer, Subscription } from 'rxjs';
import { DentalService, TreatmentService } from '../service';
import { Doc_Helper } from '../Doc_Helper';
import { CasesheetService } from '../casesheet/casesheet.service';
import { ipaddress } from '../../ipaddress' ;import { masterCSData_Helper } from '../casesheet/masterCSData_Helper';
import { MasterCSHelperService } from '../casesheet/masterCSData.service';
import { Message_data } from '../../../assets/js/Message_data';

@Component({
  selector: 'app-dental-specality',
  templateUrl: './dental-specality.component.html',
  styleUrls: ['./dental-specality.component.css'],
  //Directive:[CasesheetPersonalComponent],
})
export class DentalSpecalityComponent implements OnInit, OnDestroy {
  public getret1;
  public getret2;
  public dentalSpecFlag = 'min';
  //H/O sound
  public ortho_eva_any1;
  public fun_tog_mus1;
  public bleed_togg1;
  public ill_opert1;
  public pat_mottxt1;
  public reg_atend1;

  public getdata
  public child_hood_dis_array = [];
  public panelOpenState4: boolean;
  public loc_required;
  public phy_tons_txt;
  public intra_no_teeth;
  public food_type: string;
  public quantity_txt: string;
  public med_prepara: string;
  public qty_sugar_data: string;
  public fun_left;
  public lym_node_txt;
  public muscleflag: boolean;
  public specific_flag: boolean = true;
  public illflag: boolean = true;
  public hopainflag: boolean;
  public intra_dec_teeth;
  public specificdiseases = [];
  public relieve_factors1 = [];
  public gridsize: number;
  public jawflag: boolean = false;
  public Temporomandibular: boolean;
  public panelOpenState3: boolean;
  public panelOpenState2: boolean;
  public panelOpenState1: boolean;
  public panelOpenState5: boolean;
  public panelOpenState6: boolean;
  public panelOpenState7: boolean;
  public panelOpenState8: boolean;
  public panelOpenState10: boolean;
  public panelOpenState9: boolean; public panelOpenState11: boolean;
  public panelOpenState13: boolean;
  public panelOpenState12: boolean;
  public panelOpenState14: boolean;
  public panelOpenState15: boolean;
  public panelOpenState16: boolean;
  public ynoption: boolean = false;
  public ynopt1: boolean = false;
  public ynopt2: boolean = false;
  public ynopt3: boolean = false;
  public ynopt4: boolean = false;
  public ynopt5: boolean = false;
  public ynopt6: boolean = false;
  public ynopt7: boolean = false;
  public ynopt8: boolean = false;
  public ynopt9: boolean = false;
  public ynopt10: boolean = false;
  public ynopt11: boolean = false;
  public ynopt17: boolean = false;
  public Birth_First_Div: boolean;
  public specific_flag1: boolean = false;
  public flag1: boolean = false;
  public diet_details_array = [];

  public dur_flag: boolean;
  public loader: boolean;
  public spl_speci_dis_type;
  public Extra_Condtion_Data_type;
  public Extra_JawsTMJ_Data_type;
  public reli_factor_type;
  public reli_factor = [];
  public send_reli_factor = [];
  public spl_speci_dis = [];
  public Extra_Condtion_Data = [];
  public send_conditions_array = [];
  public Extra_JawsTMJ_Data = [];
  public send_jaw_tmj_array = [];
  public area_spec_dis_array = [];
  public age_spec_dis_array = [];
  public gen_spec_dis_array = [];
  public occ_haz_array = [];
  public body_type_array = [];
  public rating_type_array = [];
  public unerupted_teeth_array = [];
  public inerupted_teeth_array = [];
  public ging_col_array = [];
  public circum_oral_mus_tone_array = [];
  public fren_upper_array = [];
  public lip_posture_array = [];
  public chin_position_array = [];
  public naso_lab_angle_array = [];
  public mento_lab_scl_array = [];
  public facial_profile_array = [];
  public shape_of_head_array = [];
  public facial_form_array = [];
  public facial_divergence_array = [];
  public facial_sym_array = [];
  public lip_type_array = [];
  public molar_right_array = [];
  public send_spl_speci_dis = [];
  public habit_hidden: boolean;
  // variable declaration
  public pulse_txt;
  public respi_txt;
  public con_note;
  public jaw_note;
  public tmj_exam;
  public area_spec_dis: string;
  public age_spec_dis: string;
  public gen_spec_dis: string;
  public occ_haz: string;
  public mast_mus_tri: string;
  public body_type: string;
  public rating_type: string;
  public unerupted_teeth: string;
  public inerupted_teeth: string;
  public ging_col: string;
  public circum_oral_mus_tone: string;
  public fren_upper: string;
  public lip_posture: string;
  public chin_position: string;
  public naso_lab_angle: string;
  public mento_lab_scl: string;
  public facial_profile: string;
  public shape_of_head: string;
  public facial_form: string;
  public facial_divergence: string;
  public facial_sym: string;
  public lip_type: string;
  public molar_right: string;
  public disabled: boolean;

  // Disabled Fields
  //public child_hood_dis: string;
  public Tot_Causes_Div;
  public dietsession_txt: string;
  public type_of_habit;
  public oral_hyg_sta;
  public molar_can_left;
  public molar_can_right;
  public molar_left;
  subscribers = [];
  public n: number = 1;
  public tog_pat_att;
  public intra_mouth;
  public intra_peri;
  public intra_alv_mus;
  public intra_lab_mous;
  public intra_buc_mou;
  public intra_paltal;
  public intra_tong;
  public walk_txt;
  public run_txt;
  public phon_txt;
  public famil_mal_occ;
  public intra_flo_mou;
  public parent_mal_hist;
  public his_tonsil_ande;
  public ceph_ind_txt;
  public facial_index_txt
  public upp_lip_txt;
  public low_lip_txt;
  public inter_labial_gap_txt;
  public clinical_fma_txt;
  public skeletal_relation_txt;

  public dec_teeth_present_txt;
  public perm_teeth_present_txt;

  public intra_key;
  public intra_size;
  public intra_texture;
  public intra_rest;
  public intra_unrest;
  public intra_occ;
  public intra_super;
  public intra_oral_muos;
  public intra_ton_anod;
  public intra_pal_cont;
  public intra_ging_txt;
  public intra_max_mouth;
  public fren_lower;
  public intra_move;
  public intra_post;
  public intra_shape;
  public togg_endo_treat;
  public his_ortho;
  public gest_period;
  public in_comp;
  public phy_build;
  public phy_ht;
  public phy_wt;
  public phy_gait;
  public phy_post;
  public phy_pre_hel;
  public gnathic_curve;
  public gnathic_over;
  public gnathic_over_val;
  public gnathic_over_bite;
  public gnathic_bite_val;
  public fun_res;
  public fun_path_clo;
  public fun_deg;
  public fun_perio_mus_act;
  public fun_speech;
  public fun_masti;
  public fun_int_cle;
  public fun_dur_speech;
  public fun_du_smi;
  public fun_togg_jaw;
  public fun_jaw_txt;
  public fun_ho_pain;
  public fun_ho_son;
  public fun_ho_sound_txt;
  public fun_tj_trend;
  public fun_tog_mus;
  public fun_mus_wh;
  public fun_open_txt;
  public fun_prot;
  public fun_right_ex;
  public fun_left_ex;
  public ped_spc_des;
  public ped_spc_rea;
  public ped_spc_pro_bot;
  public ped_spc_tooth_app;
  public ped_spc_rel_hist;
  public ortho_eva_overjet;
  public ortho_eva_overbite;
  public ortho_eva_crossbite;
  public ortho_eva_openbite;
  public ortho_eva_sup_teetht;
  public ortho_eva_ect_erop;
  public ortho_eva_rot;
  public ortho_eva_crow;
  public ortho_eva_mid;
  public ortho_eva_arc;
  public ortho_eva_arc_mand;
  public ortho_eva_rig;
  public ortho_eva_lef;
  public ortho_eva_mol_rit;
  public ortho_eva_mol_left;
  //public den_hist_last_vit;
  public intra_vest;
  public intra_sup_ging;
  public intra_sub_ging;
  public intra_att;
  public intra_abart;
  public intra_discri;
  public ero_txt;
  public intra_des1;
  public intra_over;
  public intra_cross;
  public intra_open;
  public intra_plung;
  public intra_pri;
  public intra_sec;
  public occ_txt;
  public teeth_sup;
  public gnathic_ske_left;
  public gnathic_ske;
  public gnathic_den_mid_left;
  public gnathic_den_mid;
  public gnathic_other_indi_left;
  public gnathic_other_indi;
  public gnathic_axial_incli_left;
  public gnathic_axial_incli;
  public gnathic_arc_ali_left;
  public gnathic_arc_ali;
  public gnathic_arc_left;
  public gnathic_arc;
  public gnathic_shape_left;
  public gnathic_shape;
  public fun_pain_txt;
  public pain_txt;
  public pain_episode;
  public intnsity_txt
  public quality_pain;
  public bledd_injure;
  public ill_desc_data;
  public reg_atend;
  public ill_opert;
  public pat_mottxt;
  public social_txt;
  public location_txt;
  public intra_tons;
  public intra_ext_tract;
  public intra_ease;
  public intra_fac_swell;
  public ped_rate;
  public intra_lip_lab;
  public intra_hard_soft;
  public intra_pocket;
  public intra_bleed;
  public intra_fern;
  public ext_asy;
  public ext_speech;
  public pressure_txt;
  public pedbody_type;
  public individual_txt;
  public bit_data;

  public bleed_togg;
  public tmj_rt;
  public tmj_lt;
  public grade_txt: string;

  public Get_client_view_app_data;
  public ortho_eva_any;

  //boolean function declaration
  public ease_access_Txt_Div: boolean;
  public jaw_function_txt: boolean;
  public ho_pain_text: boolean;
  public ho_sound_text: boolean;
  public tmj_trend_txt: boolean;
  public muscle_trend_txt: boolean;

  public biting_hide: boolean;
  public temp_array1 = [];
  public temp_jaw_array1 = [];

  public extraflag: boolean = false;
  public Intra_Oral_Pane: boolean;
  public Extraoral_Pane: boolean;
  public Gnathic_Pane: boolean;
  public ic_Pane: boolean;
  public Functional_Pane: boolean;
  public Orthodontic_Pane: boolean;
  public Gums_Teeth_Pane: boolean;
  public Dental_Hist_Pane: boolean;
  public Birth_Hist_Pane: boolean;
  public General_Hist_Pane: boolean;

  public Natal_Hist_Pane: boolean;
  public Natal_Hist: boolean;
  public Pediatric_Spec_Pane: boolean;
  public Endodontic_Hist_Pane: boolean;
  public perio_specific: boolean;
  public ortho_physical: boolean;
  public vital_signs_public: boolean;
  public Soft_tissue_Div: boolean;
  public Fernum_Div: boolean;
  public Frenal_Attach_Div: boolean;
  public Hardtissue_Div: boolean;
  public Cephalic_Index_Div: boolean;
  public Tonsils_Div: boolean;
  public oral_txt_hyg: boolean;
  public Tmjexam_Div: boolean;
  public Vestible_Row_Div: boolean;
  public Vestible_Div: boolean;
  public Tonsils_txt_Div: boolean;
  public Tonsils_Third_Div: boolean;
  public Intra_Keyridge_Div: boolean;
  public Intra_Endotreat_Div: boolean;

  public key_ridge: boolean;
  public Hardtissue_Div_Head: boolean;
  public Hard_Gingival_Cal_div: boolean;
  public Tot_Occlusal_Div: boolean;
  public Trauma_Div: boolean;
  public Hardtissue_First_Div: boolean;
  public Caries_Div: boolean;
  public Tot_Tongue_Div: boolean;
  public Tot_Soft_Div: boolean;

  public dental_txt: boolean;
  public pedia_asym: boolean;
  public Pharynx_tonsils_txt_div: boolean;
  public EXtra_lip_txt_div: boolean;
  public Facial_sym_txt_div: boolean;
  public Lymphnode_txt_div: boolean;
  public pre_natal: boolean;
  public post_txt: boolean;
  public history_txt: boolean;
  public extra_div: boolean;
  public skeletal: boolean;
  public Bruxisms_txt_Div: boolean;
  public injuries_div: boolean;
  public Fernum_txt_Div: boolean;
  public Extrersinus_Txt_Div: boolean;
  public sewl_txt: boolean;
  public diet_hist_title: boolean;
  public fac_data_div: boolean;
  public oral_hab_div: boolean;

  public ortho_fields: boolean;
  public bleed_txt: boolean;
  public ill_operation_txt: boolean;
  public get_spl_name;
  public la = [];
  public child_visit_fields: boolean;
  public Tonsils_Third_Div_new: boolean;

  public extra_facial_sym_req: boolean;
  public extra_skeletal_req: boolean;
  public extra_facial_form_req: boolean;
  public extra_shape_head_req: boolean;
  public extra_face_prof_req: boolean;
  public extra_intra_lab_req: boolean;
  public extra_mento_scl_req: boolean;
  public extra_naso_lab_req: boolean;
  public extra_chin_posi_req: boolean;
  public extra_lower_lip_req: boolean;
  public extra_upper_lip_req: boolean;
  public extra_clinical_fma_req: boolean;
  public extra_in_comp_req: boolean;
  public extra_lip_post_req: boolean;
  public extra_facial_div_req: boolean;
  public extra_facial_index_req: boolean;
  public extra_ceph_req: boolean;
  //intra
  public intra_oral_hyg_req: boolean;
  public intra_max_mouth_req: boolean;
  public intra_ging_col_req: boolean;
  public intra_ging_txt_req: boolean;
  public intra_pal_cont_req: boolean;
  public intra_cir_oral_req: boolean;
  public intra_tons_ando_req: boolean;
  public intra_oral_mous_req: boolean;
  public intra_tong_size_req: boolean;
  public intra_tong_post_req: boolean;
  public intra_tong_shape_req: boolean;
  public intra_tong_move_req: boolean;
  //functional
  public fun_res_req: boolean;
  public fun_path_clos_req: boolean;
  public fun_degul_req: boolean;
  public fun_per_mus_act_req: boolean;
  public fun_speech_req: boolean;
  public fun_mast_req: boolean;
  public fun_int_clear_req: boolean;

  public fun_dur_speech_req: boolean;
  public fun_fun_du_smi_req: boolean;

  public fun_jaw_req: boolean;
  public fun_pain_du_req: boolean;
  public fun_sound_du_req: boolean;
  public fun_trend_rt_req: boolean;
  public fun_trend_lt_req: boolean;
  public fun_mus_wh_req: boolean;
  public fun_open_req: boolean;
  public fun_port_req: boolean;
  public fun_rt_ex_req: boolean;
  public fun_lt_ex_req: boolean;

  public nat_delivery_typ_req: boolean;
  public nat_mot_health_req: boolean;
  public nat_sit_req: boolean;
  public nat_craw_req: boolean;
  public nat_stand_req: boolean;
  public nat_walk_req: boolean;
  public nat_run_req: boolean;
  public nat_phon_req: boolean;
  public endo_pain_wrose_req: boolean;
  public endo_pain_scale_req: boolean;
  public endo_pain_episode_req: boolean;
  public endo_loc_req: boolean;
  public endo_int_pain_req: boolean;
  public endo_bleed_inj_req: boolean;
  public endo_ill_oper_req: boolean;
  public intra_ext_sinus_req: boolean;
  public intra_ease_access_req: boolean;
  public intra_face_swell_req: boolean;
  public ped_reason_req: boolean;
  public ped_pro_use_req: boolean;
  public ped_tooth_decay_req: boolean;
  public intra_lip_lab_req: boolean;
  public intra_hard_soft_req: boolean;
  public intra_per_teeth_req: boolean;
  public intra_dec_teeth_req: boolean;
  public intra_texture_req: boolean;
  public intra_un_erupt_teeth_req: boolean;
  public intra_in_erupt_teeth_req: boolean;
  public intra_key_post_req: boolean;
  public extra_tmj_exam_req: boolean;
  public ortho_over_jet_req: boolean;
  public ortho_over_bite_req: boolean;
  public ortho_cross_bite_req: boolean;
  public ortho_open_bite_req: boolean;
  public ortho_etho_erop_req: boolean;
  public ortho_prime_rt_req: boolean;
  public ortho_prime_lt_req: boolean;
  public ortho_perm_rt_req: boolean;
  public ortho_perm_lt_req: boolean;
  public sweet_score: boolean;
  public intra_tonsils_req: boolean;
  public extra_phy_tonsils_req: boolean;
  public extra_lip_req: boolean;
  public extra_lymph_node_req: boolean;
  public header_footer_flag;

  public gait_hidden: boolean;
  public soft_tissue: boolean;
  public axial_hidden: boolean;
  public other_ind_hidden: boolean;
  public dental_hidden: boolean;
  public ske_hidden: boolean;
  public cure_hidden: boolean;
  public antero_hidden: boolean;
  public incisor_hidden: boolean;
  public resp_hidden: boolean;
  public path_hidden: boolean;
  public deg_hidden: boolean;
  public perio_hidden: boolean;
  public speech_hidden: boolean;
  public mast_hidden: boolean;
  public inter_hidden: boolean;
  public amt_expo_hidden: boolean;
  public jaw_hidden: boolean;
  public pain_hidden: boolean;
  public sound_hidden: boolean;
  public tmj_trend_hidden: boolean;
  public muscle_hidden: boolean;
  public range_hidden: boolean;

  public narrator_hidden: boolean;
  public dey_hidden: boolean;
  public dis_mot_hidden: boolean;
  public drug_mot_hidden: boolean;
  public tongue_hidden: boolean;
  public tons_hidden: boolean;
  public fam_mal_hidden: boolean;
  public mal_hist_hidden: boolean;

  public freq_episode_hidden: boolean;
  public loc_hidden: boolean;
  public rel_hidden: boolean;
  public soc_hist_hidden: boolean;
  public int_pain_hidden: boolean;
  public type_pain_hidden: boolean;
  public spl_dis_hidden: boolean;
  public bleed_hidden: boolean;
  public serious_hidden: boolean;
  public pat_att_hidden: boolean;
  public pat_mot_hidden: boolean;

  public rating_hidden: boolean;
  public rating_type_hidden: boolean;
  public description_hidden: boolean;
  public reason_hidden: boolean;
  public pro_hidden: boolean;
  public tooth_hidden: boolean;
  public rel_med_hidden: boolean;
  public overjet_hidden: boolean;
  public overbite_hidden: boolean;
  public cross_hidden: boolean;
  public open_hidden: boolean;
  public sup_hidden: boolean;
  public ectopic_hidden: boolean;
  public rotations_hidden: boolean;
  public crowding_hidden: boolean;
  public midline_hidden: boolean;
  public arc_length_max_hidden: boolean;
  public arc_length_mand_hidden: boolean;
  public any_hidden: boolean;
  public molar_prime_hidden: boolean;
  public molar_permenent_hidden: boolean;

  public facial_index_hidden: boolean;
  public facial_div_hidden: boolean;
  public lip_hidden: boolean;
  public incomp_hidden: boolean;
  public clinical_hidden: boolean;
  public upper_hidden: boolean;
  public low_lip_hidden: boolean;
  public chin_hidden: boolean;
  public naso_hidden: boolean;
  public mento_hidden: boolean;
  public inter_fun_hidden: boolean;
  rangeSettings: any;

  public slider_grade;

  public MaleDisable: boolean;
  public FeMaleDisable: boolean;

  // Disabled Fields
  public facialssym_disabled: boolean;

  // accordion id
  spdiseaseTab: boolean = true;
  phyststusTab: boolean = true;
  vitalTab: boolean = true;
  pedsplTab: boolean = true;
  endohistTab: boolean = true;
  intraTab: boolean = false;
  extraTab: boolean = false;
  gnathicTab: boolean = true;
  funTab: boolean = true;
  orthoTab: boolean = true;
  dentalTab: boolean = true;
  birthTab: boolean = true;
  genTab: boolean = true;
  natalTab: boolean = true;
  gumsTab: boolean = true;

  subscription: Subscription;
  public client: boolean = false;
  messages: any[] = [];

  constructor(public dentalservice: DentalService, public http: Http, public toastr: ToastrService, public messageservice: TreatmentService,
    public messageservice1: CasesheetService, public masterData: MasterCSHelperService) {
    this.MaleDisable = true;
    this.FeMaleDisable = true;
    this.Orthodontic_Pane = true;
    this.Birth_Hist_Pane = true;

    this.get_spl_name = Doc_Helper.get_dental_spl_name();
    this.slider_grade = "0";

    this.facial_index_hidden = true;
    this.facial_div_hidden = true;
    this.lip_hidden = true;
    this.incomp_hidden = true;
    this.clinical_hidden = true;
    this.upper_hidden = true;
    this.low_lip_hidden = true;
    this.chin_hidden = true;
    this.naso_hidden = true;
    this.mento_hidden = true;
    this.gait_hidden = true;
    this.soft_tissue = true;
    this.axial_hidden = true;
    this.other_ind_hidden = true;
    this.dental_hidden = true;
    this.ske_hidden = true;
    this.cure_hidden = true;
    this.antero_hidden = true;
    this.incisor_hidden = true;
    this.resp_hidden = true;
    this.path_hidden = true;
    this.deg_hidden = true;
    this.perio_hidden = true;
    this.speech_hidden = true;
    this.mast_hidden = true;
    this.inter_fun_hidden = true;
    this.amt_expo_hidden = true;
    this.jaw_hidden = true;
    this.pain_hidden = true;
    this.sound_hidden = true;
    this.tmj_trend_hidden = true;
    this.muscle_hidden = true;
    this.range_hidden = true;
    this.narrator_hidden = true;
    this.dey_hidden = true;
    this.dis_mot_hidden = true;
    this.tongue_hidden = true;
    this.tons_hidden = true;
    this.fam_mal_hidden = true;
    this.mal_hist_hidden = true;
    this.freq_episode_hidden = true;
    this.loc_hidden = true;
    this.rel_hidden = true;
    this.soc_hist_hidden = true;
    this.int_pain_hidden = true;
    this.type_pain_hidden = true;
    this.spl_dis_hidden = true;
    this.bleed_hidden = true;
    this.serious_hidden = true;
    this.pat_att_hidden = true;
    this.pat_mot_hidden = true;

    this.rating_hidden = true;
    this.rating_type_hidden = true;
    this.description_hidden = true;
    this.reason_hidden = true;
    this.pro_hidden = true;
    this.tooth_hidden = true;
    this.rel_med_hidden = true;
    this.overjet_hidden = true;
    this.overbite_hidden = true;
    this.cross_hidden = true;
    this.open_hidden = true;
    this.sup_hidden = true;
    this.ectopic_hidden = true;
    this.rotations_hidden = true;
    this.crowding_hidden = true;
    this.midline_hidden = true;
    this.arc_length_max_hidden = true;
    this.arc_length_mand_hidden = true;
    this.any_hidden = true;
    this.molar_prime_hidden = true;
    this.molar_permenent_hidden = true;
    this.ease_access_Txt_Div = true;
    this.loader = true;
    //Pediatrics
    this.ped_reason_req = false;
    this.ped_pro_use_req = false;
    this.ped_tooth_decay_req = false;
    this.intra_lip_lab_req = false;
    this.intra_hard_soft_req = false;
    this.extra_tmj_exam_req = false;
    //ortho
    this.ortho_over_jet_req = false;
    this.ortho_over_bite_req = false;
    this.ortho_cross_bite_req = false;
    this.ortho_open_bite_req = false;
    this.ortho_etho_erop_req = false;
    this.ortho_prime_rt_req = false;
    this.ortho_prime_lt_req = false;
    this.ortho_perm_rt_req = false;
    this.ortho_perm_lt_req = false;
    //endo
    this.endo_pain_scale_req = false;
    this.endo_pain_wrose_req = false;
    this.endo_pain_episode_req = false;
    this.endo_loc_req = false;
    this.endo_int_pain_req = false;
    this.endo_bleed_inj_req = false;
    this.endo_ill_oper_req = false;
    this.intra_ext_sinus_req = false;
    this.intra_ease_access_req = false;
    this.intra_face_swell_req = false;
    this.intra_tonsils_req = false;

    if (this.get_spl_name == "Periodontics") {
      this.extra_tmj_exam_req = true;
    }
    if (this.get_spl_name == "Endodontics") {
      this.intra_ext_sinus_req = true;
      this.intra_ease_access_req = true;
      this.intra_face_swell_req = true;
      this.Natal_Hist_Pane = true;
    }
    if (this.get_spl_name == "Pediatrics") {
      this.intra_lip_lab_req = true;
      this.intra_hard_soft_req = true;
      this.extra_tmj_exam_req = true;
    }
    if (this.get_spl_name == "Public health") {
      this.intra_tonsils_req = true;
    }
    //natal
    this.nat_delivery_typ_req = false;
    this.nat_mot_health_req = false;
    this.nat_sit_req = false;
    this.nat_craw_req = false;
    this.nat_stand_req = false;
    this.nat_walk_req = false;
    this.nat_run_req = false;
    this.nat_phon_req = false;
    //extra oral
    this.extra_facial_sym_req = false;
    this.extra_skeletal_req = false;
    this.extra_facial_form_req = false;
    this.extra_shape_head_req = false;
    this.extra_face_prof_req = false;
    this.extra_intra_lab_req = false;
    this.extra_mento_scl_req = false;
    this.extra_naso_lab_req = false;
    this.extra_chin_posi_req = false;
    this.extra_lower_lip_req = false;
    this.extra_upper_lip_req = false;
    this.extra_clinical_fma_req = false;
    this.extra_in_comp_req = false;
    this.extra_lip_post_req = false;
    this.extra_facial_div_req = false;
    this.extra_facial_index_req = false;
    this.extra_ceph_req = false;
    this.extra_tmj_exam_req = true;
    //intra oral 
    this.intra_oral_hyg_req = false;
    this.intra_max_mouth_req = false;
    this.intra_ging_col_req = false;
    this.intra_ging_txt_req = false;
    this.intra_pal_cont_req = false;
    this.intra_cir_oral_req = false;
    this.intra_tons_ando_req = false;
    this.intra_oral_mous_req = false;
    this.intra_tong_size_req = false;
    this.intra_tong_post_req = false;
    this.intra_tong_shape_req = false;
    this.intra_tong_move_req = false;
    this.ortho_fields = true;
    this.ill_operation_txt = true;
    this.bleed_txt = true;
    this.child_visit_fields = true;
    //functonal
    this.fun_res_req = false;
    this.fun_path_clos_req = false;
    this.fun_degul_req = false;
    this.fun_per_mus_act_req = false;
    this.fun_speech_req = false;
    this.fun_mast_req = false;
    this.fun_int_clear_req = false;
    this.fun_dur_speech_req = false;
    this.fun_fun_du_smi_req = false;
    this.fun_jaw_req = false;
    this.fun_pain_du_req = false;
    this.fun_sound_du_req = false;
    this.fun_trend_rt_req = false;
    this.fun_trend_lt_req = false;
    this.fun_mus_wh_req = false;
    this.fun_open_req = false;
    this.fun_port_req = false;
    this.fun_rt_ex_req = false;
    this.fun_lt_ex_req = false;
    // birth
    this.jaw_function_txt = true;
    this.ho_pain_text = true;
    this.ho_sound_text = true;
    this.tmj_trend_txt = true;
    this.muscle_trend_txt = true;
    this.hopainflag = true;
    this.dur_flag = true;
    this.biting_hide = true;
    this.Intra_Oral_Pane = true;
    this.Extraoral_Pane = true;
    this.Gnathic_Pane = true;
    this.Functional_Pane = true;
    this.Gums_Teeth_Pane = true;
    this.Dental_Hist_Pane = true;
    this.Birth_Hist_Pane = true;
    this.General_Hist_Pane = true;
    this.Natal_Hist_Pane = true;
    this.Pediatric_Spec_Pane = true;
    this.Endodontic_Hist_Pane = true;
    this.perio_specific = true;
    this.ortho_physical = true;
    this.vital_signs_public = true;
    this.Soft_tissue_Div = true;
    this.Fernum_Div = true;
    this.Frenal_Attach_Div = true;
    this.Hardtissue_Div_Head = true;
    this.Hard_Gingival_Cal_div = true;
    this.Hardtissue_Div = true;
    this.Cephalic_Index_Div = true;
    this.Tonsils_Div = true;
    this.oral_txt_hyg = true;
    this.Tmjexam_Div = true;
    this.Vestible_Row_Div = true;
    this.Vestible_Div = true;
    this.Tonsils_txt_Div = true;
    this.Tonsils_Third_Div = true;
    this.Intra_Keyridge_Div = true;
    this.Intra_Endotreat_Div = true;
    this.Pharynx_tonsils_txt_div = true;
    this.EXtra_lip_txt_div = true;
    this.Facial_sym_txt_div = true;
    this.Lymphnode_txt_div = true;
    this.pre_natal = true;
    this.post_txt = true;
    this.history_txt = true;
    this.extra_div = true;
    this.skeletal = true;
    this.Bruxisms_txt_Div = true;
    this.injuries_div = true;
    this.diet_hist_title = true;
    this.sewl_txt = true;
    this.Extrersinus_Txt_Div = true;
    this.fac_data_div = true;
    this.oral_hab_div = true;

    //default option
    this.intra_mouth = "None";
    this.intra_lab_mous = "None";
    this.intra_paltal = "None";
    this.intra_buc_mou = "None";
    this.intra_tong = "None";
    this.intra_alv_mus = "None";
    this.intra_peri = "None";
    this.intra_flo_mou = "None";
    this.intra_vest = "None";
    this.con_note = "None";
    this.jaw_note = "None";

    this.Orthodontic_Pane = true;
    this.Birth_Hist_Pane = true;
    this.loader = false;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  async ngOnInit() {
    if (Doc_Helper.getAppFlow() == "client") {
      this.client = true;
    } else {
      this.client = false;
    }
    this.getdata = Doc_Helper.getClient_Info();
    this.getret1 = Helper_Class.Get_den_ret1();
    this.getret2 = Helper_Class.Get_den_ret2();
    console.log("RETRIEVAL FUNCTION ================ " + JSON.stringify(this.getret1))
    console.log("RETRIEVAL FUNCTION 2 ================ " + JSON.stringify(this.getret2))
    this.Get_client_view_app_data = Helper_Class.Get_client_view_app_data();

    this.subscription = this.messageservice1.getCasesheetMessage().subscribe(message => {
      if (message) {
        this.dentalSpecFlag = message;
      }
    });

    this.FilterFields();

    this.subscription = this.dentalservice.getMessage().subscribe(message => {
      if (message) {
        var data = null;
        data = message.spl;
        this.speciality_change(data);
      } else {
        // clear messages when empty message received
        this.la = [];
      }
    });

    if (masterCSData_Helper.getMasterUpperType() == undefined) {
      await this.masterData.getUpperType();
      this.get_fren_upper_array();
    } else {
      this.get_fren_upper_array();
    }
    this.get_body_type_array();
    if (masterCSData_Helper.getMasterUneruptedTeeth() == undefined) {
      await this.masterData.getUneruptedTeeth();
      this.get_unerupted_teeth_array();
    } else {
      this.get_unerupted_teeth_array();
    }
    if (masterCSData_Helper.getMasterUneruptedTeeth() == undefined) {
      await this.masterData.getUneruptedTeeth();
      this.get_inerupted_teeth_array();
    } else {
      this.get_inerupted_teeth_array();
    }
    if (masterCSData_Helper.getMasterGingivalColor() == undefined) {
      await this.masterData.getGingivalColor();
      this.get_ging_col_array();
    } else {
      this.get_ging_col_array();
    }
    if (masterCSData_Helper.getMasterCircumOralMusTone() == undefined) {
      await this.masterData.getCircumOralMusTone();
      this.get_circum_oral_mus_tone_array();
    } else {
      this.get_circum_oral_mus_tone_array();
    }
    if (masterCSData_Helper.getMasterLipPosture() == undefined) {
      await this.masterData.getLipPosture();
      this.get_lip_posture_array();
    } else {
      this.get_lip_posture_array();
    }
    if (masterCSData_Helper.getMasterChinPosition() == undefined) {
      await this.masterData.getChinPosition();
      this.get_chin_position_array();
    } else {
      this.get_chin_position_array();
    }
    if (masterCSData_Helper.getMasterNasoLabelAngle() == undefined) {
      await this.masterData.getNasoLabelAngle();
      this.get_naso_lab_angle_array();
    } else {
      this.get_naso_lab_angle_array();
    }
    if (masterCSData_Helper.getMasterMentolabialSclcus() == undefined) {
      await this.masterData.getMentolabialSclcus();
      this.get_mento_lab_scl_array();
    } else {
      this.get_mento_lab_scl_array();
    }
    if (masterCSData_Helper.getMasterFacialProfile() == undefined) {
      await this.masterData.getFacialProfile();
      this.get_facial_profile_array();
    } else {
      this.get_facial_profile_array();
    }
    if (masterCSData_Helper.getMasterShapeofHead() == undefined) {
      await this.masterData.getShapeofHead();
      this.get_shape_of_head_array();
    } else {
      this.get_shape_of_head_array();
    }
    if (masterCSData_Helper.getMasterFacialForm() == undefined) {
      await this.masterData.getFacialForm();
      this.get_facial_form_array();
    } else {
      this.get_facial_form_array();
    }
    if (masterCSData_Helper.getMasterFacialDivergence() == undefined) {
      await this.masterData.getFacialDivergence();
      this.get_facial_divergence_array();
    } else {
      this.get_facial_divergence_array();
    }
    if (masterCSData_Helper.getMasterFacialSymmetry() == undefined) {
      await this.masterData.getFacialSymmetry();
      this.get_facial_sym_array();
    } else {
      this.get_facial_sym_array();
    }
    if (masterCSData_Helper.getMasterLip() == undefined) {
      await this.masterData.getLip();
      this.get_lip_type_array();
    } else {
      this.get_lip_type_array();
    }
    if (masterCSData_Helper.getMasterMolarRight() == undefined) {
      await this.masterData.getMolarRight();
      this.get_molar_right_array();
    } else {
      this.get_molar_right_array();
    }
    if (masterCSData_Helper.getMasterChildhoodDisease() == undefined) {
      await this.masterData.getChildhoodDisease();
      this.get_child_hood_dis_array();
    } else {
      this.get_child_hood_dis_array();
    }
    // this.get_type_of_habit_array();
    if (masterCSData_Helper.getMasterExtraOralCondition() == undefined) {
      await this.masterData.getExtraOralCondition();
      this.extra_oral_cond();
    } else {
      this.extra_oral_cond();
    }

    if (masterCSData_Helper.getMasterExtraOralTmj() == undefined) {
      await this.masterData.getExtraOralTmj();
      this.extra_oral_tmj();
    } else {
      this.extra_oral_tmj();
    }
    if (masterCSData_Helper.getMasterReliFactor() == undefined) {
      await this.masterData.getReliFactor();
      this.get_reli_factor();
    } else {
      this.get_reli_factor();
    }
    if (masterCSData_Helper.getMasterSplSpeciDisease() == undefined) {
      await this.masterData.getSplSpeciDisease();
      this.get_spl_speci_dis();
    } else {
      this.get_spl_speci_dis();
    }
    if (masterCSData_Helper.getMasterAreaSpecificDisease() == undefined) {
      await this.masterData.getAreaSpecificDisease();
      this.get_area_spec_dis_array();
    } else {
      this.get_area_spec_dis_array();
    }
    if (masterCSData_Helper.getMasterAgeSpecificDisease() == undefined) {
      await this.masterData.getAgeSpecificDisease();
      this.get_age_spec_dis_array();
    } else {
      this.get_age_spec_dis_array();
    }
    if (masterCSData_Helper.getMasterGenderSpecificDisease() == undefined) {
      await this.masterData.getGenderSpecificDisease();
      this.get_gen_spec_dis_array();
    } else {
      this.get_gen_spec_dis_array();
    }
    if (masterCSData_Helper.getMastergetOccupationalhazards() == undefined) {
      await this.masterData.getOccupationalhazards();
      this.get_occ_haz_array();
    } else {
      this.get_occ_haz_array();
    }
    if (masterCSData_Helper.getMastergetRatingType() == undefined) {
      await this.masterData.getRatingType();
      this.get_rating_type_array();
    } else {
      this.get_rating_type_array();
    }
    this.RetrivalFunction();
  }

  speciality_change(e) {
    this.get_spl_name = "";
    this.get_spl_name = e;
    if (this.get_spl_name == "General") {
      this.fac_data_div = true

      this.ease_access_Txt_Div = true;
      this.extraTab = false;
      this.Orthodontic_Pane = true;
      this.Birth_Hist_Pane = true;
      this.Intra_Oral_Pane = false;
      this.Extraoral_Pane = false;
      this.Gnathic_Pane = true;
      this.Functional_Pane = true;
      this.Gums_Teeth_Pane = true;
      this.Dental_Hist_Pane = true;
      this.General_Hist_Pane = true;
      this.Natal_Hist_Pane = true;
      this.Pediatric_Spec_Pane = true;
      this.Endodontic_Hist_Pane = true;
      this.perio_specific = true;
      this.ortho_physical = true;
      this.vital_signs_public = true;
      this.extra_div = true;
      this.facial_index_hidden = true;
      this.facial_div_hidden = true;
      this.incomp_hidden = true;
      this.inter_hidden = true;
      this.Soft_tissue_Div = true;
      this.Fernum_Div = true;
      this.Frenal_Attach_Div = true;
      this.Hardtissue_Div = true;
      this.Cephalic_Index_Div = true;
      this.Tonsils_Div = true;
      this.oral_txt_hyg = true;
      this.Tmjexam_Div = true;
      this.Vestible_Row_Div = true;
      this.Vestible_Div = true;
      this.Tonsils_txt_Div = true;
      this.Tonsils_Third_Div = true;
      this.Tonsils_Third_Div_new = true;
      this.Intra_Keyridge_Div = true;
      this.Intra_Endotreat_Div = true;
      this.key_ridge = true;
      this.Soft_tissue_Div = true;
      this.Fernum_Div = true;
      // this.Frenal_Attach_Div = true;
      this.Hardtissue_Div = true;
      this.Hardtissue_Div_Head = true;
      this.Hard_Gingival_Cal_div = true;
      this.Tot_Causes_Div = true;
      this.Tot_Occlusal_Div = true;
      this.Trauma_Div = true;
      this.Tot_Tongue_Div = true
      this.Hardtissue_First_Div = true;
      this.Caries_Div = true;
      this.Tot_Soft_Div = true;
      this.dental_txt = true;
      this.pedia_asym = true;

      this.skeletal = true;
      this.Facial_sym_txt_div = true
      this.EXtra_lip_txt_div = true;
      this.Lymphnode_txt_div = true;
      this.Pharynx_tonsils_txt_div = true;
      this.gumsTab = false

    } else if (this.get_spl_name == "Periodontics") {

      this.skeletal = true;//update
      this.fac_data_div = true;//update
      this.oral_hab_div = true;
      this.extraTab = false;
      this.Extraoral_Pane = false;
      this.spdiseaseTab = false;
      this.Intra_Oral_Pane = false;
      this.fac_data_div = true;

      this.Gnathic_Pane = true;
      this.Functional_Pane = true;
      this.Orthodontic_Pane = true;
      this.Gums_Teeth_Pane = false;
      this.gumsTab = false;
      this.Dental_Hist_Pane = true;
      this.Birth_Hist_Pane = true;
      this.General_Hist_Pane = true;
      this.Natal_Hist_Pane = true;
      this.Pediatric_Spec_Pane = true;
      this.Endodontic_Hist_Pane = true;
      this.perio_specific = false;
      this.key_ridge = false;
      this.ease_access_Txt_Div = true;

      this.Soft_tissue_Div = true;
      this.Fernum_Div = true;
      this.Frenal_Attach_Div = false;
      this.Hardtissue_Div = true;
      this.Hardtissue_Div_Head = false;
      this.Hard_Gingival_Cal_div = false;
      this.Tot_Causes_Div = false;
      this.Tot_Occlusal_Div = false;
      this.Trauma_Div = false;

      this.Hardtissue_First_Div = true;
      this.Caries_Div = true;
      this.Tot_Tongue_Div = true;
      this.Tot_Soft_Div = true;
      this.ortho_physical = true;
      this.Cephalic_Index_Div = true;
      this.Tonsils_Div = false;
      this.dental_txt = true;
      this.pedia_asym = true;
      this.vital_signs_public = true;
      this.Vestible_Row_Div = false;
      this.Vestible_Div = false;
      this.Tonsils_txt_Div = true;
      this.Tonsils_Third_Div = true;
      this.Tonsils_Third_Div_new = true;
      this.Intra_Keyridge_Div = true;
      this.Intra_Endotreat_Div = true;
      this.Tmjexam_Div = false;
      this.Pharynx_tonsils_txt_div = false;
      this.EXtra_lip_txt_div = false;
      this.Lymphnode_txt_div = false;
      this.Facial_sym_txt_div = false;
      this.skeletal = false;
      this.fac_data_div = false;
      // this.Gums_Others_txt = true;
      // this.Prosthesis_txt_div = true;
      // this.Fractured_txt_div = true;
      // this.Reason_Loss_Txt_div = true;
      // this.Surface_Size_txt_Div = false;
      // this.Opencontact_Pathmig_Div = false;
      // this.Supraaerupted_txt_div = false;
      this.facial_index_hidden = true;
      this.facial_div_hidden = true;
      this.lip_hidden = true;
      this.incomp_hidden = true;
      this.clinical_hidden = true;
      this.upper_hidden = true;
      this.low_lip_hidden = true;
      this.chin_hidden = true;
      this.naso_hidden = true;
      this.mento_hidden = true;
      this.extra_div = true;
      this.inter_hidden = true;

    } else if (this.get_spl_name == "Orthodontics") {

      this.phyststusTab = false;
      this.Trauma_Div = true;
      this.Birth_Hist_Pane = false;
      this.biting_hide = false;
      this.Birth_First_Div = true;
      this.Tot_Causes_Div = true;
      this.Tot_Soft_Div = true;

      this.orthoTab = false;
      this.Orthodontic_Pane = false;
      this.overjet_hidden = false;
      this.overbite_hidden = false;
      this.cross_hidden = false;
      this.open_hidden = false;
      this.sup_hidden = false;
      this.ectopic_hidden = false;
      this.rotations_hidden = false;
      this.crowding_hidden = false;
      this.midline_hidden = false;
      this.arc_length_max_hidden = false;
      this.arc_length_mand_hidden = false;
      this.any_hidden = false;
      this.molar_prime_hidden = false;
      this.molar_permenent_hidden = false;
      this.intra_fac_swell = false;
      this.intra_ext_tract = false;
      this.Caries_Div = false;
      //this.Birth_First_Div=false;

      this.ortho_physical = false;
      this.Fernum_txt_Div = false;
      this.extraTab = false;
      this.funTab = false;
      this.gnathicTab = false;
      this.Intra_Oral_Pane = false;
      this.Extraoral_Pane = false;
      this.Gnathic_Pane = false;
      this.Functional_Pane = false;
      this.Dental_Hist_Pane = true;
      this.Gums_Teeth_Pane = true;
      //  this.Birth_Hist_Pane = false;
      this.General_Hist_Pane = false;
      this.ease_access_Txt_Div = true;
      this.Natal_Hist_Pane = false;
      this.Pediatric_Spec_Pane = true;
      this.Endodontic_Hist_Pane = true;
      this.perio_specific = true;
      this.ortho_physical = false;
      this.Tot_Soft_Div = false;
      this.Soft_tissue_Div = true;
      this.Fernum_Div = true;
      this.Frenal_Attach_Div = false;
      this.soft_tissue = false;

      this.Hardtissue_Div_Head = false;
      this.Hardtissue_First_Div = false;
      this.Hardtissue_Div = true;

      // this.Caries_Div = false;
      this.Tot_Tongue_Div = false;
      this.Tot_Soft_Div = false;
      // this.Tot_Causes_Div = true;
      this.Tot_Occlusal_Div = true;
      this.Trauma_Div = false;
      this.Natal_Hist = false;
      this.pre_natal = false;

      //this.post_txt = false;
      this.history_txt = false;


      this.Cephalic_Index_Div = false;
      this.Facial_sym_txt_div = false;
      this.Tonsils_Div = false;
      this.extra_div = false;
      this.skeletal = false;

      //  this.oral_txt_hyg = false;
      this.dental_txt = false;
      this.pedia_asym = true;
      this.vital_signs_public = true;

      this.Tmjexam_Div = true;
      this.Vestible_Row_Div = false;
      this.Vestible_Div = true;
      this.Tonsils_txt_Div = false;
      this.Tonsils_Third_Div = true;
      this.Tonsils_Third_Div_new = true;

      this.Intra_Keyridge_Div = false;
      this.Intra_Endotreat_Div = false;

      this.Hard_Gingival_Cal_div = true;
      this.Pharynx_tonsils_txt_div = true;
      this.Lymphnode_txt_div = true;

      this.Bruxisms_txt_Div = false;
      this.injuries_div = false;


      this.key_ridge = false;
      this.fac_data_div = false;
      this.facial_index_hidden = false;
      this.facial_div_hidden = false;
      this.lip_hidden = false;
      this.incomp_hidden = false;
      this.clinical_hidden = false;
      this.upper_hidden = false;
      this.low_lip_hidden = false;
      this.chin_hidden = false;
      this.naso_hidden = false;
      this.mento_hidden = false;
      this.extra_div = false;
      this.inter_hidden = false;
      this.axial_hidden = false;
      this.other_ind_hidden = false;
      this.dental_hidden = false;
      this.ske_hidden = false;
      this.ske_hidden = false;
      this.cure_hidden = false;
      this.antero_hidden = false;
      this.incisor_hidden = false;
      this.resp_hidden = false;
      this.path_hidden = false;
      this.deg_hidden = false;
      this.perio_hidden = false;
      this.speech_hidden = false;
      this.mast_hidden = false;
      this.inter_fun_hidden = false;
      this.amt_expo_hidden = false;
      this.jaw_hidden = false;
      this.pain_hidden = false;
      this.sound_hidden = false;
      this.tmj_trend_hidden = false;
      this.muscle_hidden = false;
      this.range_hidden = false;
      this.narrator_hidden = false;
      this.dey_hidden = false;
      this.dis_mot_hidden = false;
      // this.gst_hidden = false;
      // this.health_hidden = false;
      this.drug_mot_hidden = false;
      // this.birth_hidden = false;
      // this.rh_hidden = false;
      this.tons_hidden = false;
      this.fam_mal_hidden = false;
      this.mal_hist_hidden = false;
      this.gait_hidden = false;
      //$scope.perio_div = false;a
      if ((Helper_Class.Get_client_view_app_data()["age"]) != undefined && (Helper_Class.Get_client_view_app_data()["age"]) != "") {
        if (parseInt((Helper_Class.Get_client_view_app_data()["age"])) >= 6 && parseInt((Helper_Class.Get_client_view_app_data()["age"])) <= 23) {
          this.Orthodontic_Pane = false;
          this.overjet_hidden = false;
          this.overbite_hidden = false;
          this.cross_hidden = false;
          this.open_hidden = false;
          this.sup_hidden = false;
          this.ectopic_hidden = false;
          this.rotations_hidden = false;
          this.crowding_hidden = false;
          this.midline_hidden = false;
          this.arc_length_max_hidden = false;
          this.arc_length_mand_hidden = false;
          this.any_hidden = false;
          this.molar_prime_hidden = false;
          this.molar_permenent_hidden = false;
        }
      } else {
        this.Orthodontic_Pane = false;
        this.overjet_hidden = false;
        this.overbite_hidden = false;
        this.cross_hidden = false;
        this.open_hidden = false;
        this.sup_hidden = false;
        this.ectopic_hidden = false;
        this.rotations_hidden = false;
        this.crowding_hidden = false;
        this.midline_hidden = false;
        this.arc_length_max_hidden = false;
        this.arc_length_mand_hidden = false;
        this.any_hidden = false;
        this.molar_prime_hidden = false;
        this.molar_permenent_hidden = false;
      }

    } else if (this.get_spl_name == "Endodontics") {
      this.Endodontic_Hist_Pane = false;
      this.endohistTab = false;
      this.extraTab = false;
      this.Extraoral_Pane = false;
      this.Hardtissue_First_Div = true;
      this.Tonsils_Third_Div_new = true;
      this.Hard_Gingival_Cal_div = true;
      this.Intra_Endotreat_Div = true;
      this.Intra_Oral_Pane = false;
      this.intraTab = false;
      this.Tot_Causes_Div = true;
      this.Extrersinus_Txt_Div = false;
      this.ease_access_Txt_Div = false;
      this.sewl_txt = false;
      this.Caries_Div = true;
      this.Tot_Occlusal_Div = true;
      this.Trauma_Div = true;
      this.Tot_Soft_Div = true;
      this.Tot_Tongue_Div = true;
      this.extra_div = true;
      this.facial_index_hidden = true;
      this.facial_div_hidden = true;
      this.incomp_hidden = true;
      this.inter_hidden = true;
      this.pedia_asym = true;
      this.pain_hidden = false;
      this.pain_hidden = false;
      this.freq_episode_hidden = false;
      this.loc_hidden = false;
      this.soc_hist_hidden = false;
      this.int_pain_hidden = false;
      this.Intra_Keyridge_Div = true;
      this.type_pain_hidden = false;
      this.Hardtissue_Div_Head = true;
      this.Frenal_Attach_Div = true;
      this.lip_hidden = true;
      this.incomp_hidden = true;
      this.clinical_hidden = true;
      this.upper_hidden = true;
      this.chin_hidden = true;
      this.naso_hidden = true;
      this.mento_hidden = true;
      this.fac_data_div = true;
      this.skeletal = true;
      this.Pharynx_tonsils_txt_div = false;
      this.Facial_sym_txt_div = true;
      this.EXtra_lip_txt_div = true;
      this.Lymphnode_txt_div = false;
      // this.Natal_Hist_Pane=false;
      this.Natal_Hist_Pane = true;

    } else if (this.get_spl_name == "Pediatrics") {
      this.Tot_Causes_Div = true;
      this.Endodontic_Hist_Pane = true;
      this.oral_hab_div = true;
      this.pedsplTab = false;
      this.habit_hidden = false;
      this.Birth_Hist_Pane = false;
      this.Natal_Hist = true;
      this.Facial_sym_txt_div = false
      this.Lymphnode_txt_div = false;
      this.pedia_asym = false;
      this.Tonsils_Third_Div = false;
      this.fac_data_div = false;
      this.biting_hide = true;
      this.Fernum_txt_Div = false;
      this.Soft_tissue_Div = false;
      this.Tonsils_Third_Div_new = false;
      this.Tot_Soft_Div = true;
      this.Tot_Tongue_Div = true;
      // this.Tot_Causes_Div=true;
      this.Tot_Occlusal_Div = true;
      this.Trauma_Div = true;
      this.rotations_hidden = false;
      this.crowding_hidden = false;
      this.midline_hidden = false;
      this.overjet_hidden = false;
      this.overbite_hidden = false;
      this.cross_hidden = false;
      this.arc_length_max_hidden = false;
      this.arc_length_mand_hidden = false;
      this.any_hidden = false;
      this.molar_prime_hidden = false;
      this.antero_hidden = false;
      this.extra_div = true;
      // this.pedia_asym=true;
      this.sweet_score = true;
      this.open_hidden = false;
      this.sup_hidden = false;
      this.ectopic_hidden = false;
      this.Hardtissue_Div_Head = false;
      this.Intra_Keyridge_Div = false;
      this.Intra_Endotreat_Div = false;
      this.Pediatric_Spec_Pane = false;
      this.Orthodontic_Pane = false;
      this.orthoTab = false;
      this.dental_txt = false;
      this.Dental_Hist_Pane = false;
      this.perio_specific = true;
      this.endohistTab = true;
      this.extraTab = false;
      this.Natal_Hist_Pane = false;
      this.pre_natal = false;
      this.sweet_score = true;
      this.EXtra_lip_txt_div = true;
      this.inter_hidden = true;
      this.ortho_physical = true;
      this.Intra_Oral_Pane = false;
      this.Extraoral_Pane = false;
      this.Gnathic_Pane = true;
      this.Functional_Pane = true;
      this.Extrersinus_Txt_Div = true;
      this.rating_hidden = false;
      this.rating_type_hidden = false;
      this.description_hidden = false;
      this.reason_hidden = false;
      this.pro_hidden = false;
      this.tooth_hidden = false;
      this.rel_med_hidden = false;


      this.facial_index_hidden = true;
      this.facial_div_hidden = true;
      this.lip_hidden = true;
      this.incomp_hidden = true;
      this.clinical_hidden = true;
      this.upper_hidden = true;
      this.low_lip_hidden = true;
      this.chin_hidden = true;
      this.naso_hidden = true;
      this.mento_hidden = true;
      // this.oral_txt_hyg = false;    
      this.narrator_hidden = false;
      this.dey_hidden = false;
      this.dis_mot_hidden = false;
      this.drug_mot_hidden = false;
      this.ease_access_Txt_Div = true;
      this.sewl_txt = true;
      this.Hardtissue_Div = false;
      this.Hardtissue_First_Div = false;
      this.Caries_Div = false;
      if (this.Get_client_view_app_data["age"] != undefined && this.Get_client_view_app_data["age"] != "") {
        if (parseInt(this.Get_client_view_app_data["age"]) >= 6 && parseInt(this.Get_client_view_app_data["age"]) <= 23) {
          this.Orthodontic_Pane = false;
          this.overjet_hidden = false;
          this.overbite_hidden = false;
          this.cross_hidden = false;
          this.open_hidden = false;
          this.sup_hidden = false;
          this.ectopic_hidden = false;
          this.rotations_hidden = false;
          this.crowding_hidden = false;
          this.midline_hidden = false;
          this.arc_length_max_hidden = false;
          this.arc_length_mand_hidden = false;
          this.any_hidden = false;
          this.molar_prime_hidden = false;
          this.molar_permenent_hidden = false;
        }

      } else {
        this.Orthodontic_Pane = false;
        this.overjet_hidden = false;
        this.overbite_hidden = false;
        this.cross_hidden = false;
        this.open_hidden = false;
        this.sup_hidden = false;
        this.ectopic_hidden = false;
        this.rotations_hidden = false;
        this.crowding_hidden = false;
        this.midline_hidden = false;
        this.arc_length_max_hidden = false;
        this.arc_length_mand_hidden = false;
        this.any_hidden = false;
        this.molar_prime_hidden = false;
        this.molar_permenent_hidden = false;
      }
      this.Gums_Teeth_Pane = true;
      this.vital_signs_public = true;
      this.Dental_Hist_Pane = false;
      //this.Birth_Hist_Pane = false;
      this.General_Hist_Pane = true;
      this.Natal_Hist_Pane = false;
      this.Pediatric_Spec_Pane = false;
      this.Endodontic_Hist_Pane = true;
      this.perio_specific = true;
      this.Tonsils_Third_Div = false;
      this.Tonsils_Third_Div_new = false;
      this.Intra_Keyridge_Div = false;
      this.Intra_Endotreat_Div = false;
      this.fac_data_div = false;
      this.Facial_sym_txt_div = false;
      this.Lymphnode_txt_div = false;
      //this.Birth_First_Div = false;
      this.Vestible_Row_Div = false;

      this.Soft_tissue_Div = false;
      this.Fernum_Div = false;
      this.Frenal_Attach_Div = true;


      this.Hardtissue_Div_Head = false;
      this.oral_txt_hyg = false;
      // this.Post_Natal_div = false;
      this.Tot_Tongue_Div = true;
      this.Tot_Soft_Div = true;
      //this.Tot_Causes_Div = true;
      this.Tot_Occlusal_Div = true;
      this.Trauma_Div = true;
      this.Cephalic_Index_Div = false;
      this.Tonsils_Div = false;

      // this.Birth_First_Div = false;
      // this.Post_Natal_div = false;

      this.Natal_Hist = true;

      this.history_txt = true;
      this.pre_natal = false;
      this.dental_txt = false;
      //this.diet_any_hidden = false;
      this.diet_hist_title = true;

      this.extra_div = true;
      this.skeletal = true;
      this.pedia_asym = false;
      this.oral_hab_div = false;

      if (this.Get_client_view_app_data["age"] != undefined && this.Get_client_view_app_data["age"] != "") {
        if (parseInt(this.Get_client_view_app_data["age"]) <= 6) {
          //  this.post_txt = false;

        }
        else {
          //  this.post_txt = true;
        }
      }
      else {
        //this.post_txt = false;
      }

    } else if (this.get_spl_name == "Prosthodontics") {
      this.Fernum_txt_Div = false;
      this.Trauma_Div = false;
      this.facial_index_hidden = false;
      this.facial_div_hidden = false;
      this.lip_hidden = false;
      this.incomp_hidden = false;
      this.clinical_hidden = false;
      this.upper_hidden = false;
      this.low_lip_hidden = false;
      this.chin_hidden = false;
      this.naso_hidden = false;
      this.mento_hidden = false;
      this.extra_div = false;
      this.inter_hidden = false;
      this.ortho_physical = true;
      this.Intra_Oral_Pane = false;
      this.Extraoral_Pane = false;
      this.Gnathic_Pane = true;

      // Functional content
      this.Functional_Pane = false;
      this.resp_hidden = false;
      this.path_hidden = false;
      this.deg_hidden = false;
      this.perio_hidden = false;
      this.speech_hidden = false;
      this.mast_hidden = false;
      this.inter_fun_hidden = false;
      this.amt_expo_hidden = false;
      this.jaw_hidden = false;
      this.pain_hidden = false;
      // this.ho_pain_text = false;
      this.sound_hidden = false;
      //this.ho_sound_text = false;
      this.tmj_trend_hidden = false;
      //this.tmj_trend_txt = false;
      this.muscle_hidden = false;
      //this.muscle_trend_txt = false;
      this.range_hidden = false;
      this.funTab = false;
      this.extraTab = false;
      this.intraTab = false;
      this.Tot_Causes_Div = true;

      this.Fernum_txt_Div = true;
      this.Extrersinus_Txt_Div = true;
      this.ease_access_Txt_Div = true;
      this.sewl_txt = true;
      this.Orthodontic_Pane = true;
      this.Gums_Teeth_Pane = true;
      this.vital_signs_public = true;
      this.Dental_Hist_Pane = true;
      //this.Birth_Hist_Pane = true;
      this.General_Hist_Pane = true;
      this.Natal_Hist_Pane = true;
      this.Pediatric_Spec_Pane = true;
      this.Endodontic_Hist_Pane = true;
      this.perio_specific = true;

      //this.Caries_Div = false;
      this.Hardtissue_First_Div = false;
      this.Frenal_Attach_Div = false;

      this.Hardtissue_Div = true;
      this.Hardtissue_Div_Head = false;
      this.dental_txt = true;
      this.Cephalic_Index_Div = false;
      this.extra_div = false;
      this.Tonsils_Div = false;
      this.pedia_asym = true;

      this.Tot_Causes_Div = true;
      this.Tot_Occlusal_Div = true;

      this.Natal_Hist = true;
      this.history_txt = true;


      this.pre_natal = true;

      this.Tmjexam_Div = true;

      this.Vestible_Row_Div = true;
      this.Vestible_Div = true;
      this.Tonsils_txt_Div = true;
      this.Tonsils_Third_Div = true;
      this.Tonsils_Third_Div_new = true;

      this.Soft_tissue_Div = false;
      this.soft_tissue = false;

      this.Fernum_Div = false;
      //  this.diet_hist_hidden = false;
      this.Tot_Tongue_Div = false;
      this.Tot_Soft_Div = false;

      this.key_ridge = false;

      this.Intra_Keyridge_Div = false;
      this.Intra_Endotreat_Div = false;

      this.Hard_Gingival_Cal_div = true;
      this.Pharynx_tonsils_txt_div = true;

      this.EXtra_lip_txt_div = false;
      this.Lymphnode_txt_div = true;

      this.extra_div = false;
      this.skeletal = false;
      this.fac_data_div = false;
      this.Facial_sym_txt_div = false;

    } else if (this.get_spl_name == "Public health") {
      this.Fernum_txt_Div = true;
      this.Trauma_Div = true;
      this.facial_index_hidden = true;
      this.facial_div_hidden = true;
      this.lip_hidden = true;
      this.incomp_hidden = true;
      this.clinical_hidden = true;
      this.upper_hidden = true;
      this.low_lip_hidden = true;
      this.chin_hidden = true;
      this.naso_hidden = true;
      this.mento_hidden = true;
      this.extra_div = true;
      this.inter_hidden = true;
      this.Intra_Oral_Pane = false;
      this.Extraoral_Pane = false;
      this.ortho_physical = true;
      this.Orthodontic_Pane = true;
      this.Gnathic_Pane = true;
      this.Functional_Pane = true;
      this.Gums_Teeth_Pane = false;
      this.Dental_Hist_Pane = false;
      this.vitalTab = false;
      this.Birth_Hist_Pane = true;
      //    this.Birth_Hist_Pane = true;
      this.General_Hist_Pane = true;

      this.Pediatric_Spec_Pane = true;
      this.Endodontic_Hist_Pane = true;
      this.perio_specific = true;
      this.vital_signs_public = false;
      // this.Caries_Div = true;
      this.Tot_Occlusal_Div = true;
      this.Soft_tissue_Div = true;
      this.Tot_Tongue_Div = true;
      this.Pharynx_tonsils_txt_div = false;
      this.Facial_sym_txt_div = false;
      this.EXtra_lip_txt_div = false;
      this.Lymphnode_txt_div = false;

      this.Frenal_Attach_Div = false;
      this.key_ridge = true;
      this.Hardtissue_Div = true;
      this.Tonsils_Div = false;
      this.Tmjexam_Div = false;
      this.pedia_asym = true;
      // this.Gums_Others_txt = false;
      // this.Prosthesis_txt_div = false;
      // this.Fractured_txt_div = false;
      // this.Reason_Loss_Txt_div = false;
      this.dental_txt = true;
      this.Tot_Soft_Div = true;
      this.Tonsils_Third_Div_new = false;
      this.Vestible_Row_Div = false;

      this.Hardtissue_Div_Head = true;
      this.Hardtissue_First_Div = true;
      this.Tot_Causes_Div = false;
      this.oral_txt_hyg = true;
      this.oral_hab_div = true

      // this.diet_hist_hidden = false;
      // this.diet_any_hidden = false;

      this.Natal_Hist_Pane = false;

      if (this.Get_client_view_app_data["age"] != undefined && this.Get_client_view_app_data["age"] != "") {
        if (parseInt(this.Get_client_view_app_data["age"]) <= 6) {
          // this.post_txt = false;
          this.Natal_Hist_Pane = false;
          this.Natal_Hist = true;
        }
        else {
          // this.post_txt = true;
        }
      }
      else {
        // this.post_txt = false;
        this.Natal_Hist_Pane = false;
        this.Natal_Hist = true;
      }
    }
  }

  getColor(v) {
    if (v == "Yes") {
      return "green";
    }
    if (v = "No") {
      return "red";
    }
  }

  FilterFields() {
    var getret = Helper_Class.getHopitalfieldsRet();
    var dental_spl = Doc_Helper.get_dental_spl_name();
    var getInfo = Helper_Class.getInfo();
    if (getInfo.provider == "Doctor") {
      if (getret != undefined) {
        for (var i = 0; i < getret.length; i++) {
          if (getret[i].page_name == "Speciality") {
            if (getret[i].fields != undefined) {
              if (getret[i].fields != undefined) {
                for (var j = 0; j < getret[i].fields.length; j++) {
                  if (getret[i].fields[j].field_name == "Gait") {
                    this.gait_hidden = false;
                  }
                  if (getret[i].fields[j].field_name == "Soft tissue examination") {
                    this.soft_tissue = false;
                  }
                  if (getret[i].fields[j].field_name == "Tongue size") {
                    this.Tot_Tongue_Div = false;
                  }
                  if (getret[i].fields[j].field_name == "Tongue posture") {
                    this.Tot_Tongue_Div = false;
                  }
                  if (getret[i].fields[j].field_name == "Tongue shape") {
                    this.Tot_Tongue_Div = false;
                  }
                  if (getret[i].fields[j].field_name == "Tongue movement") {
                    this.Tot_Tongue_Div = false;
                  }
                  if (getret[i].fields[j].field_name == "Cephalic index") {
                    this.extra_div = false;
                  }
                  if (getret[i].fields[j].field_name == "Facial index") {
                    this.facial_index_hidden = false;
                  }
                  if (getret[i].fields[j].field_name == "Facial divergence") {
                    this.facial_div_hidden = false;
                  }
                  if (getret[i].fields[j].field_name == "Lip posture") {
                    this.lip_hidden = false;
                  }
                  if (getret[i].fields[j].field_name == "Incompetent") {
                    this.incomp_hidden = false;
                  }
                  if (getret[i].fields[j].field_name == "Clinical FMA") {
                    this.clinical_hidden = false;
                  }
                  if (getret[i].fields[j].field_name == "Upper lip") {
                    this.upper_hidden = false;
                  }
                  if (getret[i].fields[j].field_name == "Lower lip") {
                    this.low_lip_hidden = false;
                  }
                  if (getret[i].fields[j].field_name == "Chin position") {
                    this.chin_hidden = false;
                  }
                  if (getret[i].fields[j].field_name == "Naso labial angle") {
                    this.naso_hidden = false;
                  } if (getret[i].fields[j].field_name == "Mentolabial sclcus") {
                    this.mento_hidden = false;

                  }
                  if (getret[i].fields[j].field_name == "Facial profile") {
                    this.fac_data_div = false;
                  }
                  if (getret[i].fields[j].field_name == "Shape of head") {
                    this.fac_data_div = false;
                  }
                  if (getret[i].fields[j].field_name == "Facial form") {
                    this.fac_data_div = false;
                  }
                  if (getret[i].fields[j].field_name == "Skeletal relationship") {
                    this.skeletal = false;
                  }
                  if (getret[i].fields[j].field_name == "Axial inclination") {
                    this.axial_hidden = false;
                  }
                  if (getret[i].fields[j].field_name == "Other individual regularities") {
                    this.other_ind_hidden = false;
                  }
                  if (getret[i].fields[j].field_name == "Dental midline") {
                    // this.dental_hidden = false;
                  }
                  if (getret[i].fields[j].field_name == "Skeletal midline") {
                    this.ske_hidden = false;
                  }
                  if (getret[i].fields[j].field_name == "Curve of spee") {
                    this.cure_hidden = false;
                  }
                  if (getret[i].fields[j].field_name == "Antero") {
                    this.antero_hidden = false;
                  }
                  if (getret[i].fields[j].field_name == "Incisor relation") {
                    this.incisor_hidden = false;
                  }
                  if (getret[i].fields[j].field_name == "Respiration") {
                    this.resp_hidden = false;
                  }
                  if (getret[i].fields[j].field_name == "Path of closure") {
                    this.path_hidden = false;
                  }
                  if (getret[i].fields[j].field_name == "Deglutition") {
                    this.deg_hidden = false;
                  }
                  if (getret[i].fields[j].field_name == "Perioral muscle activity") {
                    this.perio_hidden = false;
                  }
                  if (getret[i].fields[j].field_name == "Speech") {
                    this.speech_hidden = false;
                  }
                  if (getret[i].fields[j].field_name == "Mastication") {
                    this.mast_hidden = false;
                  }
                  if (getret[i].fields[j].field_name == "Interocclusal clearance") {
                    this.inter_fun_hidden = false;
                  }
                  if (getret[i].fields[j].field_name == "Amount of incisor exposure") {
                    this.amt_expo_hidden = false;
                  }
                  if (getret[i].fields[j].field_name == "Jaw function") {
                    this.jaw_hidden = false;
                  }
                  if (getret[i].fields[j].field_name == "HO pain") {
                    this.pain_hidden = false;
                  }
                  if (getret[i].fields[j].field_name == "HO sounds") {
                    this.sound_hidden = false;
                  }
                  if (getret[i].fields[j].field_name == "TMJ tenderness") {
                    this.tmj_trend_hidden = false;
                  }
                  if (getret[i].fields[j].field_name == "Muscle tenderness") {
                    this.muscle_hidden = false;
                  }
                  if (getret[i].fields[j].field_name == "Mandibular motion") {
                    this.range_hidden = false;
                  }
                  if (getret[i].fields[j].field_name == "Birth history" && (this.get_spl_name == "Orthodontics" || this.get_spl_name == "Pediatrics")) {
                    // this.Birth_Hist_Pane = false;
                  }
                  if (getret[i].fields[j].field_name == "Narrator") {
                    this.narrator_hidden = false;
                  }
                  if (getret[i].fields[j].field_name == "Type of delivery") {
                    this.dey_hidden = false;
                  }
                  if (getret[i].fields[j].field_name == "Infectious disease of mother") {
                    this.dis_mot_hidden = false;
                  }
                  if (getret[i].fields[j].field_name == "Gestation period") {
                    // this.gst_hidden = false;
                  }
                  if (getret[i].fields[j].field_name == "Health Of mother") {
                    //  this.health_hidden = false;
                  }
                  if (getret[i].fields[j].field_name == "Drug taken by mother") {
                    this.drug_mot_hidden = false;
                  }

                  if (getret[i].fields[j].field_name == "Birth weight") {
                    //this.birth_hidden = false;
                  }
                  if (getret[i].fields[j].field_name == "Rh compatibility") {
                    // this.rh_hidden = false;
                  }

                  if (getret[i].fields[j].field_name == "Bruxism") {
                    this.tongue_hidden = false;
                  }
                  if (getret[i].fields[j].field_name == "Pubertal status") {
                    this.injuries_div = false;
                  }
                  if (getret[i].fields[j].field_name == "History of tonsillectomy") {
                    this.tons_hidden = false;
                  }
                  if (getret[i].fields[j].field_name == "Pain worse") {
                    this.pain_hidden = false;
                  }
                  if (getret[i].fields[j].field_name == "Frequency of painful episode") {
                    this.freq_episode_hidden = false;
                  }
                  if (getret[i].fields[j].field_name == "Location") {
                    this.loc_hidden = false;
                  }
                  if (getret[i].fields[j].field_name == "Relaving factor") {
                    this.rel_hidden = false;
                  }
                  if (getret[i].fields[j].field_name == "Social history") {
                    this.soc_hist_hidden = false;
                  }
                  if (getret[i].fields[j].field_name == "Intensity of pain") {
                    this.int_pain_hidden = false;
                  }

                  if (getret[i].fields[j].field_name == "Type of pain") {
                    this.type_pain_hidden = false;
                  }
                  if (getret[i].fields[j].field_name == "Speciality specific disease") {
                    this.spl_dis_hidden = false;
                  }
                  if (getret[i].fields[j].field_name == "Special treatment") {
                    this.bleed_hidden = false;
                  }
                  if (getret[i].fields[j].field_name == "Operations") {
                    this.serious_hidden = false;
                  }
                  if (getret[i].fields[j].field_name == "Patient regular attender") {
                    this.pat_att_hidden = false;
                  }
                  if (getret[i].fields[j].field_name == "Patient motivation") {
                    this.pat_mot_hidden = false;
                  }
                  // if (getret[i].fields[j].field_name == "Grade scale") {
                  //   this.mal_hist_hidden = false;
                  // }
                  if (getret[i].fields[j].field_name == "Rating") {
                    this.rating_hidden = false;
                  }
                  if (getret[i].fields[j].field_name == "Rating type") {
                    this.rating_type_hidden = false;
                  }
                  if (getret[i].fields[j].field_name == "Description") {
                    this.description_hidden = false;
                  }
                  if (getret[i].fields[j].field_name == "Reason") {
                    this.reason_hidden = false;
                  }
                  if (getret[i].fields[j].field_name == "Prolonged use of bottle at bed") {
                    this.pro_hidden = false;
                  }
                  if (getret[i].fields[j].field_name == "Tooth decay appearance") {
                    this.tooth_hidden = false;
                  }
                  if (getret[i].fields[j].field_name == "Relevant medical history") {
                    this.rel_med_hidden = false;
                  }
                  if (getret[i].fields[j].field_name == "Over jet" && (dental_spl == "Pediatrics" || dental_spl == "Orthodontics")) {
                    this.overjet_hidden = false;
                    this.Orthodontic_Pane = false;
                  }
                  if (getret[i].fields[j].field_name == "Over bite" && (dental_spl == "Pediatrics" || dental_spl == "Orthodontics")) {
                    this.overbite_hidden = false;
                    this.Orthodontic_Pane = false;
                  }
                  if (getret[i].fields[j].field_name == "Cross bite" && (dental_spl == "Pediatrics" || dental_spl == "Orthodontics")) {
                    this.cross_hidden = false;
                    this.Orthodontic_Pane = false;
                  }
                  if (getret[i].fields[j].field_name == "Cross bite" && (dental_spl == "Pediatrics" || dental_spl == "Orthodontics")) {
                    this.open_hidden = false;
                    this.Orthodontic_Pane = false;
                  }
                  if (getret[i].fields[j].field_name == "Supernumerary teeth" && (dental_spl == "Pediatrics" || dental_spl == "Orthodontics")) {
                    this.sup_hidden = false;
                    this.Orthodontic_Pane = false;
                  }
                  if (getret[i].fields[j].field_name == "Ectopic eruption" && (dental_spl == "Pediatrics" || dental_spl == "Orthodontics")) {
                    this.ectopic_hidden = false;
                    this.Orthodontic_Pane = false;
                  }
                  if (getret[i].fields[j].field_name == "Rotations" && (dental_spl == "Pediatrics" || dental_spl == "Orthodontics")) {
                    this.rotations_hidden = false;
                    this.Orthodontic_Pane = false;
                  }
                  if (getret[i].fields[j].field_name == "Crowding / Spacing" && (dental_spl == "Pediatrics" || dental_spl == "Orthodontics")) {
                    this.crowding_hidden = false;
                    this.Orthodontic_Pane = false;
                  }
                  if (getret[i].fields[j].field_name == "Midline" && (dental_spl == "Pediatrics" || dental_spl == "Orthodontics")) {
                    this.midline_hidden = false;
                    this.Orthodontic_Pane = false;
                  }
                  if (getret[i].fields[j].field_name == "Arch length maxilla" && (dental_spl == "Pediatrics" || dental_spl == "Orthodontics")) {
                    this.arc_length_max_hidden = false;
                    this.Orthodontic_Pane = false;
                  }
                  if (getret[i].fields[j].field_name == "Arch length mandible" && (dental_spl == "Pediatrics" || dental_spl == "Orthodontics")) {
                    this.arc_length_mand_hidden = false;
                    this.Orthodontic_Pane = false;
                  }
                  if (getret[i].fields[j].field_name == "Analysis required " && (dental_spl == "Pediatrics" || dental_spl == "Orthodontics")) {
                    this.any_hidden = false;
                    this.Orthodontic_Pane = false;
                  }
                  if (getret[i].fields[j].field_name == "Molar relation primary" && (dental_spl == "Pediatrics" || dental_spl == "Orthodontics")) {
                    this.molar_prime_hidden = false;
                    this.Orthodontic_Pane = false;
                  }
                  if (getret[i].fields[j].field_name == "Molar relation permanent" && (dental_spl == "Pediatrics" || dental_spl == "Orthodontics")) {
                    this.molar_permenent_hidden = false;
                    this.Orthodontic_Pane = false;
                  }
                  if (getret[i].fields[j].field_name == "Last visit") {
                    // this.last_vit_hidden = false;
                  }
                  if (getret[i].fields[j].field_name == "Pleasant / Unpleasant experience?") {
                    //this.ple_hidden = false;
                  }
                  if (getret[i].fields[j].field_name == "Previous treatment") {
                    //this.pre_treat_hidden = false;
                  }
                  if (getret[i].fields[j].field_name == "Behaviour during last visit") {
                    //.beh_last_vit_hidden = false;
                  }

                  if (getret[i].fields[j].field_name == "Oral hygiene practices") {
                    this.oral_txt_hyg = false;
                  }

                  if (getret[i].fields[j].field_name == "Oral habitual history") {
                    this.oral_hab_div = false;
                  }
                }
              }
            }
          }
        }
      }

    } else {

      this.disabled = true;
      this.get_spl_name = Doc_Helper.get_dental_spl_name();

      if (this.get_spl_name == "General") {
        this.ease_access_Txt_Div = true;
        this.Orthodontic_Pane = true;
        this.Birth_Hist_Pane = true;
        this.Intra_Oral_Pane = false;
        this.Extraoral_Pane = false;
        this.Gnathic_Pane = true;
        this.Functional_Pane = true;
        this.Gums_Teeth_Pane = true;
        this.Dental_Hist_Pane = true;
        this.General_Hist_Pane = true;
        this.Natal_Hist_Pane = true;
        this.Pediatric_Spec_Pane = true;
        this.Endodontic_Hist_Pane = true;
        this.perio_specific = true;
        this.ortho_physical = true;
        this.vital_signs_public = true;
        this.extra_div = true;
        this.facial_index_hidden = true;
        this.facial_div_hidden = true;
        this.incomp_hidden = true;
        this.inter_hidden = true;
        this.Soft_tissue_Div = true;
        this.Fernum_Div = true;
        this.Frenal_Attach_Div = true;
        this.Hardtissue_Div = true;
        this.Cephalic_Index_Div = true;
        this.Tonsils_Div = true;
        this.oral_txt_hyg = true;

        this.Tmjexam_Div = true;
        this.Vestible_Row_Div = true;
        this.Vestible_Div = true;
        this.Tonsils_txt_Div = true;
        this.Tonsils_Third_Div = true;
        this.Tonsils_Third_Div_new = true;
        this.Intra_Keyridge_Div = true;
        this.Intra_Endotreat_Div = true;
        this.key_ridge = true;

        this.Soft_tissue_Div = true;
        this.Fernum_Div = true;
        // this.Frenal_Attach_Div = true;
        this.Hardtissue_Div = true;
        this.Hardtissue_Div_Head = true;
        this.Hard_Gingival_Cal_div = true;
        this.Tot_Causes_Div = true;
        this.Tot_Occlusal_Div = true;
        this.Trauma_Div = true;

        this.Hardtissue_First_Div = true;
        this.Caries_Div = true;
        this.Tot_Tongue_Div = true;
        this.Tot_Soft_Div = true;
        this.Birth_First_Div = true;
        // this.Post_Natal_div = true;

        this.oral_txt_hyg = true;
        this.dental_txt = true;

        this.pedia_asym = true;
        //   this.Gums_Others_txt = true;


        // $scope.perio_div = false;

      }
      else if (this.get_spl_name == "Periodontics") {

        this.Intra_Oral_Pane = false;
        this.Extraoral_Pane = false;
        this.Gnathic_Pane = true;
        this.Functional_Pane = true;
        this.Orthodontic_Pane = true;
        this.Gums_Teeth_Pane = true;
        this.Dental_Hist_Pane = true;
        this.Birth_Hist_Pane = true;
        this.General_Hist_Pane = true;
        this.Natal_Hist_Pane = true;
        this.Pediatric_Spec_Pane = true;
        this.Endodontic_Hist_Pane = true;
        this.perio_specific = false;
        this.key_ridge = false;
        this.ease_access_Txt_Div = true;

        this.Soft_tissue_Div = true;
        this.Fernum_Div = true;
        this.Frenal_Attach_Div = false;
        this.Hardtissue_Div = true;
        this.Hardtissue_Div_Head = false;
        this.Hard_Gingival_Cal_div = false;
        this.Tot_Causes_Div = false;
        this.Tot_Occlusal_Div = false;
        this.Trauma_Div = false;

        this.Hardtissue_First_Div = true;
        this.Caries_Div = true;
        this.Tot_Tongue_Div = true;
        this.Tot_Soft_Div = true;

        this.ortho_physical = true;
        this.Cephalic_Index_Div = true;
        this.Tonsils_Div = false;

        this.Birth_First_Div = true;
        //  this.Post_Natal_div = true;

        this.oral_txt_hyg = true;
        this.dental_txt = true;

        this.pedia_asym = true;
        this.vital_signs_public = true;

        this.Vestible_Row_Div = false;
        this.Vestible_Div = false;

        this.Tonsils_txt_Div = true;
        this.Tonsils_Third_Div = true;
        this.Tonsils_Third_Div_new = true;
        this.Intra_Keyridge_Div = true;
        this.Intra_Endotreat_Div = true;

        this.Tmjexam_Div = false;
        this.Pharynx_tonsils_txt_div = false;
        this.EXtra_lip_txt_div = false;
        this.Lymphnode_txt_div = false;
        this.Facial_sym_txt_div = false;

        // this.Gums_Others_txt = true;
        // this.Prosthesis_txt_div = true;
        // this.Fractured_txt_div = true;
        // this.Reason_Loss_Txt_div = true;
        // this.Surface_Size_txt_Div = false;
        // this.Opencontact_Pathmig_Div = false;
        // this.Supraaerupted_txt_div = false;
        this.facial_index_hidden = true;
        this.facial_div_hidden = true;
        this.lip_hidden = true;
        this.incomp_hidden = true;
        this.clinical_hidden = true;
        this.upper_hidden = true;
        this.low_lip_hidden = true;
        this.chin_hidden = true;
        this.naso_hidden = true;
        this.mento_hidden = true;
        this.extra_div = true;
        this.inter_hidden = true;
      }
      else if (this.get_spl_name == "Orthodontics") {

        this.Intra_Oral_Pane = false;
        this.Extraoral_Pane = false;
        this.Gnathic_Pane = false;
        this.Functional_Pane = false;
        this.Dental_Hist_Pane = true;
        this.Gums_Teeth_Pane = false;
        this.Birth_Hist_Pane = false;

        this.General_Hist_Pane = false;
        this.ease_access_Txt_Div = true;
        this.Natal_Hist_Pane = false;
        this.Pediatric_Spec_Pane = true;
        this.Endodontic_Hist_Pane = true;
        this.perio_specific = true;
        this.ortho_physical = false;
        this.Tot_Soft_Div = false;
        this.Soft_tissue_Div = true;
        this.Fernum_Div = true;
        this.Frenal_Attach_Div = false;
        this.soft_tissue = false;

        this.Hardtissue_Div_Head = false;
        this.Hardtissue_First_Div = false;
        this.Hardtissue_Div = true;

        this.Caries_Div = false;
        this.Tot_Tongue_Div = false;
        this.Tot_Soft_Div = false;
        this.Tot_Causes_Div = true;
        this.Tot_Occlusal_Div = true;
        this.Trauma_Div = true;
        this.Natal_Hist = false;
        this.pre_natal = false;

        this.post_txt = false;
        this.history_txt = false;
        //this.Post_Natal_div = false;

        this.Cephalic_Index_Div = false;
        this.Facial_sym_txt_div = false;
        this.Tonsils_Div = false;
        this.extra_div = false;
        this.skeletal = false;

        this.oral_txt_hyg = false;
        this.dental_txt = false;
        this.pedia_asym = true;
        this.vital_signs_public = true;

        this.Tmjexam_Div = true;
        this.Vestible_Row_Div = false;
        this.Vestible_Div = true;
        this.Tonsils_txt_Div = false;
        this.Tonsils_Third_Div = true;
        this.Tonsils_Third_Div_new = true;

        this.Intra_Keyridge_Div = false;
        this.Intra_Endotreat_Div = false;

        this.Hard_Gingival_Cal_div = true;
        this.Pharynx_tonsils_txt_div = true;
        this.Lymphnode_txt_div = true;

        this.Bruxisms_txt_Div = false;
        this.injuries_div = false;

        this.Birth_First_Div = true;

        this.key_ridge = false;
        this.fac_data_div = false;
        this.facial_index_hidden = false;
        this.facial_div_hidden = false;
        this.lip_hidden = false;
        this.incomp_hidden = false;
        this.clinical_hidden = false;
        this.upper_hidden = false;
        this.low_lip_hidden = false;
        this.chin_hidden = false;
        this.naso_hidden = false;
        this.mento_hidden = false;
        this.extra_div = false;
        this.inter_hidden = false;
        this.axial_hidden = false;
        this.other_ind_hidden = false;
        // this.dental_hidden = false;
        this.ske_hidden = false;
        this.ske_hidden = false;
        this.cure_hidden = false;
        this.antero_hidden = false;
        this.incisor_hidden = false;
        this.resp_hidden = false;
        this.path_hidden = false;
        this.deg_hidden = false;
        this.perio_hidden = false;
        this.speech_hidden = false;
        this.mast_hidden = false;
        this.inter_fun_hidden = false;
        this.amt_expo_hidden = false;
        this.jaw_hidden = false;
        this.pain_hidden = false;
        this.sound_hidden = false;
        this.tmj_trend_hidden = false;
        this.muscle_hidden = false;
        this.range_hidden = false;
        this.narrator_hidden = false;
        this.dey_hidden = false;
        this.dis_mot_hidden = false;
        // this.gst_hidden = false;
        // this.health_hidden = false;
        this.drug_mot_hidden = false;
        // this.birth_hidden = false;
        // this.rh_hidden = false;
        this.tons_hidden = false;
        this.fam_mal_hidden = false;
        this.mal_hist_hidden = false;
        this.gait_hidden = false;
        //$scope.perio_div = false;
        var age = Helper_Class.Get_client_view_app_data().Age_data
        if (age != undefined && age != "") {
          if (parseInt(age) >= 6 && parseInt(age) <= 23) {
            this.Orthodontic_Pane = false;
            this.overjet_hidden = false;
            this.overbite_hidden = false;
            this.cross_hidden = false;
            this.open_hidden = false;
            this.sup_hidden = false;
            this.ectopic_hidden = false;
            this.rotations_hidden = false;
            this.crowding_hidden = false;
            this.midline_hidden = false;
            this.arc_length_max_hidden = false;
            this.arc_length_mand_hidden = false;
            this.any_hidden = false;
            this.molar_prime_hidden = false;
            this.molar_permenent_hidden = false;
          }

        }
        else {
          this.Orthodontic_Pane = false;
          this.overjet_hidden = false;
          this.overbite_hidden = false;
          this.cross_hidden = false;
          this.open_hidden = false;
          this.sup_hidden = false;
          this.ectopic_hidden = false;
          this.rotations_hidden = false;
          this.crowding_hidden = false;
          this.midline_hidden = false;
          this.arc_length_max_hidden = false;
          this.arc_length_mand_hidden = false;
          this.any_hidden = false;
          this.molar_prime_hidden = false;
          this.molar_permenent_hidden = false;
        }
      }
      else if (this.get_spl_name == "Endodontics") {

        this.Intra_Oral_Pane = false;
        this.Extraoral_Pane = false;
        this.intraTab = false;

        this.Gnathic_Pane = true;
        this.Functional_Pane = true;
        this.Orthodontic_Pane = true;
        this.Dental_Hist_Pane = true;
        this.Gums_Teeth_Pane = true;
        this.Birth_Hist_Pane = true;
        this.General_Hist_Pane = true;
        this.Hard_Gingival_Cal_div = true;
        this.ease_access_Txt_Div = false;
        this.Tot_Tongue_Div = true;


        this.Natal_Hist_Pane = true;
        this.Pediatric_Spec_Pane = true;
        this.Endodontic_Hist_Pane = false;
        this.extra_div = true;
        this.facial_index_hidden = true;
        this.facial_div_hidden = true;
        this.incomp_hidden = true;
        this.inter_hidden = true;
        this.pain_hidden = false;
        this.freq_episode_hidden = false;
        this.loc_hidden = false;
        this.rel_hidden = false;
        this.soc_hist_hidden = false;
        this.int_pain_hidden = false;
        this.type_pain_hidden = false;
        this.spl_dis_hidden = false;
        this.bleed_hidden = false;
        this.serious_hidden = false;
        this.ill_operation_txt = false;
        this.pat_att_hidden = false;
        this.pat_mot_hidden = false;
        this.perio_specific = true;
        this.ortho_physical = true;
        this.vital_signs_public = true;
        this.Hardtissue_Div_Head = true;

        this.Soft_tissue_Div = true;

        this.Fernum_Div = false;
        this.Frenal_Attach_Div = true;
        this.Hardtissue_Div = true;
        this.Hardtissue_First_Div = true;

        this.Caries_Div = true;
        this.Tot_Tongue_Div = true;
        this.Tot_Soft_Div = true;
        this.Tot_Causes_Div = true;
        this.Tot_Occlusal_Div = true;
        this.Trauma_Div = true;
        this.oral_txt_hyg = true;

        this.Natal_Hist = true;
        this.history_txt = true;
        this.post_txt = true;
        this.pre_natal = true;
        this.sewl_txt = false;
        this.dental_txt = true;

        this.pedia_asym = true;
        this.Tmjexam_Div = true;
        this.Vestible_Row_Div = false;
        this.Vestible_Div = true;
        this.Tonsils_txt_Div = true;
        this.Tonsils_Third_Div = true;
        this.Tonsils_Third_Div_new = true;
        this.Intra_Keyridge_Div = true;
        this.Intra_Endotreat_Div = true;
        this.Cephalic_Index_Div = true;
        this.Tonsils_Div = true;
        this.diet_hist_title = false;
        this.Fernum_txt_Div = true;
        this.facial_index_hidden = true;
        this.facial_div_hidden = true;
        this.lip_hidden = true;
        this.incomp_hidden = true;
        this.clinical_hidden = true;
        this.upper_hidden = true;
        this.low_lip_hidden = true;
        this.chin_hidden = true;
        this.naso_hidden = true;
        this.mento_hidden = true;
        this.extra_div = true;
        this.inter_hidden = true;
        this.fac_data_div = true;
        this.skeletal = true;
        this.Facial_sym_txt_div = true;
        this.EXtra_lip_txt_div = true;

        this.Extrersinus_Txt_Div = false;

        //$scope.perio_div = true;
      }
      else if (this.get_spl_name == "Pediatrics") {
        this.sweet_score = true;
        this.EXtra_lip_txt_div = true;
        this.inter_hidden = true;
        this.ortho_physical = true;
        this.Intra_Oral_Pane = false;
        this.Extraoral_Pane = false;
        this.Gnathic_Pane = true;
        this.Functional_Pane = true;
        this.Extrersinus_Txt_Div = true;
        this.rating_hidden = false;
        this.rating_type_hidden = false;
        this.description_hidden = false;
        this.reason_hidden = false;
        this.pro_hidden = false;
        this.tooth_hidden = false;
        this.rel_med_hidden = false;



        this.oral_txt_hyg = false;
        // this.diet_hist_hidden = false;
        // this.diet_hist_hidden = false;
        // this.qty_sugar_hidden = false;
        this.narrator_hidden = false;
        this.dey_hidden = false;
        this.dis_mot_hidden = false;
        // this.gst_hidden = false;
        // this.health_hidden = false;
        this.drug_mot_hidden = false;

        this.ease_access_Txt_Div = true;
        this.sewl_txt = true;
        this.Caries_Div = false;
        this.Hardtissue_Div = false;
        var age = Helper_Class.Get_client_view_app_data().age;

        if (age != undefined && age != "") {
          if (parseInt(age) >= 6 && parseInt(age) <= 23) {
            this.Orthodontic_Pane = false;
            this.overjet_hidden = false;
            this.overbite_hidden = false;
            this.cross_hidden = false;
            this.open_hidden = false;
            this.sup_hidden = false;
            this.ectopic_hidden = false;
            this.rotations_hidden = false;
            this.crowding_hidden = false;
            this.midline_hidden = false;
            this.arc_length_max_hidden = false;
            this.arc_length_mand_hidden = false;
            this.any_hidden = false;
            this.molar_prime_hidden = false;
            this.molar_permenent_hidden = false;
            this.Caries_Div = false;
            this.Hardtissue_Div = false;
          }
        }
        else {
          this.Orthodontic_Pane = false;
          this.overjet_hidden = false;
          this.overbite_hidden = false;
          this.cross_hidden = false;
          this.open_hidden = false;
          this.sup_hidden = false;
          this.ectopic_hidden = false;
          this.rotations_hidden = false;
          this.crowding_hidden = false;
          this.midline_hidden = false;
          this.arc_length_max_hidden = false;
          this.arc_length_mand_hidden = false;
          this.any_hidden = false;
          this.molar_prime_hidden = false;
          this.molar_permenent_hidden = false;
        }
        this.Gums_Teeth_Pane = false;
        this.vital_signs_public = true;
        this.Dental_Hist_Pane = false;
        this.Birth_Hist_Pane = false;
        this.General_Hist_Pane = true;
        this.Natal_Hist_Pane = false;
        this.Pediatric_Spec_Pane = false;
        this.Endodontic_Hist_Pane = true;
        this.perio_specific = true;
        this.Tonsils_Third_Div = false;
        this.Tonsils_Third_Div_new = false;
        this.Intra_Keyridge_Div = false;
        this.Intra_Endotreat_Div = false;
        this.fac_data_div = false;
        this.Facial_sym_txt_div = false;
        this.Lymphnode_txt_div = false;
        this.Birth_First_Div = false;
        this.Vestible_Row_Div = false;

        this.Soft_tissue_Div = false;
        this.Fernum_Div = false;
        this.Frenal_Attach_Div = true;

        this.Hardtissue_First_Div = false;
        this.Hardtissue_Div_Head = false;
        this.Caries_Div = false;
        this.Hardtissue_Div = false;
        this.oral_txt_hyg = false;
        //this.Post_Natal_div = false;
        this.Fernum_txt_Div = false;
        this.Tot_Tongue_Div = true;
        this.Tot_Soft_Div = true;
        this.Tot_Causes_Div = true;
        this.Tot_Occlusal_Div = true;
        this.Trauma_Div = true;
        this.Tot_Tongue_Div = true;
        this.Cephalic_Index_Div = false;
        this.Tonsils_Div = false;

        this.Birth_First_Div = false;
        //this.Post_Natal_div = false;

        this.Natal_Hist = true;

        this.history_txt = true;
        this.pre_natal = false;
        this.dental_txt = false;
        //  this.diet_any_hidden = false;
        this.diet_hist_title = true;

        this.extra_div = true;
        this.skeletal = true;
        this.pedia_asym = false;
        this.oral_hab_div = false;

        if (age != undefined && age != "") {
          if (parseInt(age) <= 6) {
            this.post_txt = false;

          }
          else {
            this.post_txt = true;
          }
        }
        else {
          this.post_txt = false;
        }



      }
      else if (this.get_spl_name == "Prosthodontics") {
        this.Fernum_txt_Div = false;
        this.facial_index_hidden = false;
        this.facial_div_hidden = false;
        this.lip_hidden = false;
        this.incomp_hidden = false;
        this.clinical_hidden = false;
        this.upper_hidden = false;
        this.low_lip_hidden = false;
        this.chin_hidden = false;
        this.naso_hidden = false;
        this.mento_hidden = false;
        this.extra_div = false;
        this.inter_hidden = false;
        this.ortho_physical = true;
        this.Intra_Oral_Pane = false;
        this.Extraoral_Pane = false;
        this.Gnathic_Pane = true;

        // Functional content
        this.Functional_Pane = false;
        this.resp_hidden = false;
        this.path_hidden = false;
        this.deg_hidden = false;
        this.perio_hidden = false;
        this.speech_hidden = false;
        this.mast_hidden = false;
        this.inter_fun_hidden = false;
        this.amt_expo_hidden = false;
        this.jaw_hidden = false;
        this.pain_hidden = false;
        // this.ho_pain_text = false;
        this.sound_hidden = false;
        //this.ho_sound_text = false;
        this.tmj_trend_hidden = false;
        //this.tmj_trend_txt = false;
        this.muscle_hidden = false;
        //this.muscle_trend_txt = false;
        this.range_hidden = false;


        this.Fernum_txt_Div = true;
        this.Extrersinus_Txt_Div = true;
        this.ease_access_Txt_Div = true;
        this.sewl_txt = true;
        this.Orthodontic_Pane = true;
        this.Gums_Teeth_Pane = false;
        this.vital_signs_public = true;
        this.Dental_Hist_Pane = true;
        this.Birth_Hist_Pane = true;
        this.General_Hist_Pane = true;
        this.Natal_Hist_Pane = true;
        this.Pediatric_Spec_Pane = true;
        this.Endodontic_Hist_Pane = true;
        this.perio_specific = true;

        this.Caries_Div = false;
        this.Hardtissue_First_Div = false;
        this.Frenal_Attach_Div = false;

        this.Hardtissue_Div = true;
        this.Hardtissue_Div_Head = false;
        this.dental_txt = true;
        this.Cephalic_Index_Div = false;
        this.extra_div = false;
        this.Tonsils_Div = false;
        this.pedia_asym = true;
        this.Tot_Causes_Div = true;
        this.Tot_Occlusal_Div = true;

        this.Trauma_Div = true;
        this.Natal_Hist = true;
        this.history_txt = true;

        this.post_txt = true;
        this.pre_natal = true;

        this.Tmjexam_Div = true;

        this.Vestible_Row_Div = true;
        this.Vestible_Div = true;
        this.Tonsils_txt_Div = true;
        this.Tonsils_Third_Div = true;
        this.Tonsils_Third_Div_new = true;

        this.Soft_tissue_Div = false;
        this.soft_tissue = false;

        this.Fernum_Div = false;

        this.Tot_Tongue_Div = false;
        this.Tot_Soft_Div = false;

        this.key_ridge = false;

        this.Intra_Keyridge_Div = false;
        this.Intra_Endotreat_Div = false;

        this.Hard_Gingival_Cal_div = true;
        this.Pharynx_tonsils_txt_div = true;

        this.EXtra_lip_txt_div = false;
        this.Lymphnode_txt_div = true;

        this.extra_div = false;
        this.skeletal = false;
        this.fac_data_div = false;
        this.Facial_sym_txt_div = false;

        //this.perio_div = true;
      }
      else if (this.get_spl_name == "Public health") {
        this.Fernum_txt_Div = true;
        this.facial_index_hidden = true;
        this.facial_div_hidden = true;
        this.lip_hidden = true;
        this.incomp_hidden = true;
        this.clinical_hidden = true;
        this.upper_hidden = true;
        this.low_lip_hidden = true;
        this.chin_hidden = true;
        this.naso_hidden = true;
        this.mento_hidden = true;
        this.extra_div = true;
        this.inter_hidden = true;
        this.Intra_Oral_Pane = false;
        this.Extraoral_Pane = false;
        this.ortho_physical = true;
        this.Orthodontic_Pane = true;
        this.Gnathic_Pane = true;
        this.Functional_Pane = true;
        this.Gums_Teeth_Pane = true;
        this.Dental_Hist_Pane = false;

        this.Birth_Hist_Pane = true;
        this.General_Hist_Pane = true;

        this.Pediatric_Spec_Pane = true;
        this.Endodontic_Hist_Pane = true;
        this.perio_specific = true;
        this.vital_signs_public = false;
        this.Caries_Div = true;
        this.Tot_Occlusal_Div = true;
        this.Trauma_Div = true;
        this.Soft_tissue_Div = true;
        this.Tot_Tongue_Div = true;
        this.Pharynx_tonsils_txt_div = false;
        this.Facial_sym_txt_div = false;
        this.EXtra_lip_txt_div = false;
        this.Lymphnode_txt_div = false;
        this.Trauma_Div = true;

        this.Frenal_Attach_Div = false;
        this.key_ridge = true;
        this.Hardtissue_Div = true;
        this.Tonsils_Div = false;
        this.Tmjexam_Div = false;
        this.pedia_asym = true;
        // this.Gums_Others_txt = false;
        // this.Prosthesis_txt_div = false;
        // this.Fractured_txt_div = false;
        // this.Reason_Loss_Txt_div = false;
        this.dental_txt = true;
        this.Tot_Soft_Div = true;
        this.Tonsils_Third_Div_new = false;
        this.Vestible_Row_Div = false;
        this.Tonsils_Third_Div = false
        this.Hardtissue_Div_Head = true;
        this.Hardtissue_First_Div = true;
        this.Tot_Causes_Div = false;
        this.oral_txt_hyg = true;
        this.oral_hab_div = true
        this.Natal_Hist_Pane = false;
        this.Caries_Div = true;
        // this.diet_hist_hidden = false;
        // this.diet_any_hidden = false;

        if (this.Get_client_view_app_data.age != undefined && this.Get_client_view_app_data.age != "") {

          if (parseInt(this.Get_client_view_app_data.age) <= 6) {
            this.post_txt = false;
            this.Natal_Hist_Pane = false;
            this.Natal_Hist = true;
          }
          else {
            this.post_txt = true;
          }
        }
        else {
          this.post_txt = false;
          this.Natal_Hist_Pane = false;
          this.Natal_Hist = true;
        }

      }



    }

  }


  send_data() {
    var endo_treat = "no", child_visit = "no", diet_flag_arry = "no", ortho_treat = "no", any_req = "no", area_spec_dis = undefined, bleed_injury = "no", serious_operation = "no";

    if (this.ill_opert == true) {
      serious_operation = "yes"
    }
    if (this.bleed_togg == true) {
      bleed_injury = "yes"
    }
    if (this.togg_endo_treat == "yes" || this.togg_endo_treat == "Yes") {

      endo_treat = "yes"
    }

    if (this.his_ortho != undefined && this.his_ortho == true) {
      ortho_treat = "yes"
    }
    if (this.ortho_eva_any == true) {
      any_req = "yes"
    }

    // general history spl
    var gen_hist = null;

    // validation color all spl
    this.pediatric_change();
    this.endo_change();
    this.functional_change();
    this.intra_oral_change();
    this.extra_oral_change();
    this.Ortho_change();
    // this.Dental_change();

    if (this.get_spl_name == "General") {
      var intr_flg = "no"

      if (this.intra_mouth != undefined && this.intra_mouth != "") {
        intr_flg = "Yes";
      }

      gen_hist = {
        int_oral_flag: intr_flg,
        //intra oral
        mouth: this.intra_mouth,
        labial_mucosa: this.intra_lab_mous,
        palatal: this.intra_paltal,
        buccal_mucosa: this.intra_buc_mou,
        tongue: this.intra_tong,
        alveolar_mucosa: this.intra_alv_mus,
        periodintium: this.intra_peri,
        floor_mouth: this.intra_flo_mou,
        //extra oral
        extra_oral_con_note: this.con_note,
        extra_oral_jaw_note: this.jaw_note,
        TMJ_exam: this.tmj_exam,
        eoral_conditions: this.send_conditions_array,
        tmj: this.send_jaw_tmj_array,
      }

    }
    else if (this.get_spl_name == "Periodontics") {


      var super_tx = "no", med_flg = "no", over_flg = "no", gin_exfig = "no", ging_flg = "no", texam_flg = "no", teet_exampubflg = "no";

      if (this.area_spec_dis != undefined || this.age_spec_dis != undefined || this.gen_spec_dis != undefined || this.occ_haz != undefined || this.mast_mus_tri != undefined) {
        med_flg = "yes";
      }

      if (this.intra_over != undefined || this.intra_cross != undefined || this.intra_open != undefined || this.intra_plung != undefined) {
        over_flg = "yes";
      }


      if ((this.intra_sup_ging != undefined && this.intra_sup_ging != "") || (this.intra_sub_ging != undefined && this.intra_sub_ging != "") || (this.intra_pri != undefined && this.intra_pri != "") ||
        (this.intra_sec != undefined && this.intra_sec != "")) {
        super_tx = "yes";
      }
      var intr_flg = "no"
      if (this.intra_mouth != undefined && this.intra_mouth != "") {
        intr_flg = "Yes";
      }
      var denwa_flg = "no";
      if (this.intra_des1 != undefined || this.intra_att != undefined || this.intra_abart != undefined || this.intra_discri != undefined || this.ero_txt != undefined) {
        denwa_flg = "yes";
      }
      gen_hist = {
        den_was_flag: denwa_flg,
        int_oral_flag: intr_flg,
        supra_flag: super_tx,
        overbite_flag: over_flg,
        medi_hist_dis_flag: med_flg,
        // perio specific disease
        den_area_specific_id: this.area_spec_dis,
        den_age_spec_id: this.age_spec_dis,
        den_gen_spec_id: this.gen_spec_dis,
        den_occ_haz_id: this.occ_haz,
        mastigatory_muscle: this.mast_mus_tri,
        //intra oral
        mouth: this.intra_mouth,
        labial_mucosa: this.intra_lab_mous,
        palatal: this.intra_paltal,
        buccal_mucosa: this.intra_buc_mou,
        tongue: this.intra_tong,
        alveolar_mucosa: this.intra_alv_mus,
        periodintium: this.intra_peri,
        floor_mouth: this.intra_flo_mou,
        vestibule: this.intra_vest,
        supragingival: this.intra_sup_ging,
        subgingival: this.intra_sub_ging,
        wast_attrition: this.intra_att,
        wast_abration: this.intra_abart,
        abr_desc: this.intra_discri,
        wast_erosion: this.ero_txt,
        ero_desc: this.intra_des1,
        overbite: this.intra_over,
        crossbite: this.intra_cross,
        openbite: this.intra_open,
        overjet: "",
        cups: this.intra_plung,
        tra_primary: this.intra_pri,
        tra_secondary: this.intra_sec,
        frenal_upper: this.fren_upper,
        frenal_lower: this.fren_lower,
        //extra oral
        extra_oral_con_note: this.con_note,
        extra_oral_jaw_note: this.jaw_note,
        TMJ_exam: this.tmj_exam,
        eoral_conditions: this.send_conditions_array,
        tmj: this.send_jaw_tmj_array,
        pharynx_tonsils: this.phy_tons_txt,
        den_face_sym_id: this.facial_sym,
        lip: this.lip_type,
        oralym: this.lym_node_txt,
        //gums and teeth
        occlusion: this.occ_txt,
        supraaerupted: this.teeth_sup,
        oral_hygiene: this.oral_hyg_sta,
      }
    }
    else if (this.get_spl_name == "Orthodontics") {

      var hab_txt = "no", post_ex = "no", pre_txt = "no", birth_flag = "no", oral_falg = "no", intra_orl = "no", intr_flg = "no", tmj_fun = "no", ho_pain = "no", ho_sound = "no", tmj_trend = "no", mus_trend = "no", physical_status_flag = "no";
      var bite_conflg = "no", resp_flgtxt = "no", hab_txt = "no", post_ex = "no", pre_txt = "no", birth_flag = "no", oral_falg = "no", intra_orl = "no", intr_flg = "no", tmj_fun = "no", ho_pain = "no", ho_sound = "no", tmj_trend = "no", mus_trend = "no", physical_status_flag = "no";
      if (this.fun_togg_jaw == "yes") {
        tmj_fun = "yes";
      }
      if (this.fun_ho_pain == "yes") {
        ho_pain = "yes";
      }
      if (this.fun_ho_son == "yes") {
        ho_sound = "yes";
      }
      if (this.fun_tj_trend == "yes") {
        tmj_trend = "yes";
      }
      if (this.fun_tog_mus == "yes") {
        mus_trend = "yes";
      }



      if (this.pedbody_type != undefined) {
        physical_status_flag = "yes"
      }
      if (this.intra_mouth != undefined && this.intra_mouth != "") {
        intr_flg = "Yes";
      }

      if (this.oral_hyg_sta != undefined || this.intra_pal_cont != undefined || this.circum_oral_mus_tone != undefined || this.intra_pal_cont != undefined ||
        this.intra_oral_muos != undefined || this.intra_max_mouth != undefined || this.intra_ging_txt != undefined || this.intra_size != undefined ||
        this.intra_post != undefined || this.intra_shape != undefined || this.intra_move != undefined || this.intra_ton_anod != undefined) {
        oral_falg = "yes";
      }

      var maxmand_arrylist = [{

        den_position_id: "1",
        shape: this.gnathic_shape,
        arch_symm: this.gnathic_arc,
        arch_align: this.gnathic_arc_ali,
        axial_inclin: this.gnathic_axial_incli,
        oth_indiv_irregular: this.gnathic_other_indi,
        den_midline: this.gnathic_den_mid,
        skeletal_midline: this.gnathic_ske,
      },

      {
        den_position_id: "2",
        shape: this.gnathic_shape_left,
        arch_symm: this.gnathic_arc_left,
        arch_align: this.gnathic_arc_ali_left,
        axial_inclin: this.gnathic_axial_incli_left,
        oth_indiv_irregular: this.gnathic_other_indi_left,
        den_midline: this.gnathic_den_mid_left,
        skeletal_midline: this.gnathic_ske_left,

      }];


      if (this.gnathic_shape == undefined) {
        maxmand_arrylist = undefined;
      }
      var ante_poster_arry = [{

        den_position_id: "5",
        ant_post_molar: this.molar_right,
        ant_post_canine: this.molar_can_right,

      },

      {
        den_position_id: "6",
        ant_post_molar: this.molar_left,
        ant_post_canine: this.molar_can_left,
      }];



      if (ante_poster_arry.length != 0) {

        var den_anflag = "Yes"
      }
      else {
        den_anflag = "No"
      }



      if ((this.fun_res != undefined || this.fun_res != "") || (this.fun_path_clo != undefined && this.fun_path_clo != "") || (this.fun_deg != undefined && this.fun_deg != "") ||
        (this.fun_perio_mus_act != undefined && this.fun_perio_mus_act != "") || (this.fun_speech != undefined && this.fun_speech != "") || (this.fun_masti != undefined && this.fun_masti != "") ||
        (this.fun_dur_speech != undefined && this.fun_dur_speech != "") || (this.fun_int_cle != undefined && this.fun_int_cle != "") || (this.fun_du_smi != undefined && this.fun_du_smi != "") ||
        this.fun_togg_jaw == "yes" || (this.fun_jaw_txt != undefined && this.fun_jaw_txt != "") || (this.fun_pain_txt != undefined && this.fun_pain_txt != "") || this.fun_ho_pain != undefined || this.fun_ho_son != this.unerupted_teeth ||
        (this.fun_ho_sound_txt != undefined && this.fun_ho_sound_txt != "") ||
        this.fun_tj_trend != undefined || (this.tmj_rt != undefined && this.tmj_rt != "") || (this.tmj_rt != undefined && this.tmj_rt != "") ||
        (this.fun_mus_wh != undefined && this.fun_mus_wh != "") || (this.fun_open_txt != undefined && this.fun_open_txt != "") ||
        (this.fun_right_ex != undefined && this.fun_right_ex != "") ||
        (this.fun_right_ex != undefined && this.fun_right_ex != "") || (this.fun_left_ex != undefined && this.fun_left_ex != "")) {


        resp_flgtxt = "yes";
      }


      if (this.gnathic_curve != undefined || this.gnathic_over != undefined || this.gnathic_over_val != undefined ||
        this.gnathic_over_bite != undefined || this.gnathic_bite_val != undefined) {

        bite_conflg = "Yes";
      }
      else {
        bite_conflg = "No";
      }

      var gaint_data = this.phy_gait;
      var intra_sizedata = this.intra_size;
      var intra_postdata = this.intra_post;
      var intra_shapedata = this.intra_shape;
      var intra_movedata = this.intra_move;
      var ceph_ind_txtdata = this.ceph_ind_txt;
      var facial_index_txtdata = this.facial_index_txt;

      var facial_divergencedata = this.facial_divergence;
      var lip_posturedata = this.lip_posture;
      var in_compdata = this.in_comp;
      var clinical_fma_txtdata = this.clinical_fma_txt;
      var upp_lip_txtdata = this.upp_lip_txt;
      var low_lip_txtdata = this.low_lip_txt;
      var chin_positiondata = this.chin_position;
      var naso_lab_angledata = this.naso_lab_angle;
      var mento_lab_scldata = this.mento_lab_scl;


      var inter_labial_gap_txtdata = this.inter_labial_gap_txt;
      var facial_profiledata = this.facial_profile;
      var shape_of_headdata = this.shape_of_head;
      var facial_formdata = this.facial_form;
      var facial_symdata = this.facial_sym;
      var lip_typedata = this.lip_type;

      if (this.gait_hidden == true) {
        gaint_data = "";
      }
      if (this.Tot_Tongue_Div == true) {
        intra_sizedata = "";
      }
      if (this.Tot_Tongue_Div == true) {
        intra_postdata = "";
      }
      if (this.Tot_Tongue_Div == true) {
        intra_shapedata = "";
      }
      if (this.Tot_Tongue_Div == true) {
        intra_movedata = "";
      }
      if (this.extra_div == true) {
        ceph_ind_txtdata = "";
      }
      if (this.facial_index_hidden == true) {
        facial_index_txtdata = "";
      }

      if (this.facial_div_hidden == true) {
        facial_divergencedata = "";
      }
      if (this.lip_hidden == true) {
        lip_posturedata = "";
      }
      if (this.incomp_hidden == true) {
        in_compdata = "";
      }
      if (this.clinical_hidden == true) {
        clinical_fma_txtdata = "";
      }
      if (this.upper_hidden == true) {
        upp_lip_txtdata = "";
      }
      if (this.low_lip_hidden == true) {
        low_lip_txtdata = "";
      }
      if (this.chin_hidden == true) {
        chin_positiondata = "";
      }
      if (this.naso_hidden == true) {
        naso_lab_angledata = '';
      }
      if (this.mento_hidden == true) {
        mento_lab_scldata = "";
      }


      if (this.inter_hidden == true) {
        inter_labial_gap_txtdata = '';
      }
      if (this.fac_data_div == true) {
        facial_profiledata = '';
      }
      if (this.fac_data_div == true) {
        shape_of_headdata = "";
      }
      if (this.fac_data_div == true) {
        facial_formdata = "";
      }
      if (this.skeletal == true) {
        this.skeletal_relation_txt = "";
      }
      if (this.Facial_sym_txt_div == true) {
        facial_symdata = '';
      }
      if (this.EXtra_lip_txt_div == true) {
        lip_typedata = '';
      }
      var fun_resdata = this.fun_res;
      var fun_path_clodata = this.fun_path_clo;
      var fun_degdata = this.fun_deg;
      var fun_perio_mus_actdata = this.fun_perio_mus_act;

      if (this.resp_hidden == true) {
        fun_resdata = '';
      }
      if (this.path_hidden == true) {
        fun_path_clodata = '';
      }
      if (this.deg_hidden == true) {
        fun_degdata = '';
      }
      if (this.perio_hidden == true) {
        fun_perio_mus_actdata = "";
      }
      if (this.speech_hidden == true) {
        this.fun_speech = "";
      }
      if (this.mast_hidden == true) {
        this.fun_masti = "";
      }
      if (this.inter_fun_hidden == true) {
        this.fun_int_cle = '';
      }
      if (this.amt_expo_hidden == true) {
        this.fun_dur_speech = "";
      }
      if (this.amt_expo_hidden == true) {
        this.fun_du_smi = "";
      }
      if (this.jaw_hidden == true) {
        this.fun_togg_jaw = "";
      }
      if (this.jaw_function_txt == true) {
        this.fun_jaw_txt = "";
      }
      if (this.pain_hidden == true) {
        this.fun_ho_pain = "";
      }
      if (this.sound_hidden == true) {
        this.fun_ho_son = "";
      }
      if (this.tmj_trend_hidden == true) {
        this.fun_tj_trend = "";
      }
      if (this.muscle_hidden == true) {
        this.fun_tog_mus = "";
      }
      if (this.range_hidden == true) {
        this.fun_open_txt = "";
      }
      if (this.range_hidden == true) {
        this.fun_prot = "";
      }
      if (this.range_hidden == true) {
        this.fun_right_ex = "";
      }
      if (this.range_hidden == true) {
        this.fun_left_ex = "";
      }
      // if(this.narrator_hidden==true){
      //   this.narr_txt=null;
      // }
      // if(this.dey_hidden==true){
      //   this.type_dev=null;
      // }
      // if(this.health_hidden==true){
      //   this.health_mot=null;
      // }
      if (this.pain_hidden == true) {
        this.pain_txt = "";
      }
      if (this.freq_episode_hidden == true) {
        this.pain_episode = "";
      }
      if (this.loc_hidden == true) {
        this.location_txt = "";
      }
      if (this.int_pain_hidden == true) {
        this.intnsity_txt = "";
      }
      if (this.bleed_hidden == true) {
        this.bleed_togg = '';
      }
      if (this.serious_hidden == true) {
        this.ill_opert = '';
      }
      if (this.reason_hidden == true) {
        this.ped_spc_rea = '';
      }
      if (this.pro_hidden == true) {
        this.ped_spc_pro_bot = '';
      }
      if (this.tooth_hidden == true) {
        this.ped_spc_tooth_app = '';
      }
      if (this.overjet_hidden == true) {
        this.ortho_eva_overjet = '';
      }
      if (this.overbite_hidden == true) {
        this.ortho_eva_overbite = '';
      }
      if (this.cross_hidden == true) {
        this.ortho_eva_crossbite = '';
      }
      if (this.open_hidden == true) {
        this.ortho_eva_openbite = "";
      }
      if (this.ectopic_hidden == true) {
        this.ortho_eva_ect_erop = '';
      }
      if (this.molar_prime_hidden == true) {
        this.ortho_eva_rig = "";
      }
      if (this.molar_prime_hidden == true) {
        this.ortho_eva_lef = '';
      }
      if (this.molar_permenent_hidden == true) {
        this.ortho_eva_mol_rit = "";
      }
      if (this.molar_permenent_hidden == true) {
        this.ortho_eva_mol_left = "";
      }

      if (this.phy_ht == "") {
        this.phy_ht = undefined;
      }
      if (this.phy_wt == "") {
        this.phy_wt = undefined;
      }

      if (this.phy_gait == "" || this.phy_gait == null) {
        this.phy_gait = "";
      }



      var over_biteflg = "no";
      if (this.ortho_eva_overbite != undefined || this.ortho_eva_crossbite != undefined || this.ortho_eva_openbite != undefined || this.ortho_eva_overjet != undefined) {

        over_biteflg = "yes";
      }


      gen_hist = {
        overbite_flag: over_biteflg,
        bite_condition_flag: bite_conflg,
        resp_flag: resp_flgtxt,
        habit_exists: hab_txt,
        int_oral_flag: intr_flg,
        oral_hygeine_flag: oral_falg,
        physical_status_flag: physical_status_flag,

        //physical status
        build: this.phy_build,
        height: this.phy_ht,
        weight: this.phy_wt,
        status_gaint: gaint_data,
        posture: this.phy_post,
        body_type: this.pedbody_type,
        health: this.phy_pre_hel,
        //intra oral
        mouth: this.intra_mouth,
        labial_mucosa: this.intra_lab_mous,
        palatal: this.intra_paltal,
        buccal_mucosa: this.intra_buc_mou,
        tongue: this.intra_tong,
        alveolar_mucosa: this.intra_alv_mus,
        periodintium: this.intra_peri,
        floor_mouth: this.intra_flo_mou,
        den_ging_colour: this.ging_col,

        //hard tissue
        perm_teeth_present: this.perm_teeth_present_txt,
        dec_teeth_present: this.dec_teeth_present_txt,
        texture: this.intra_texture,
        unerupted_teeth: this.unerupted_teeth,
        ineruption_teeth: this.inerupted_teeth,
        size_shape_form: this.intra_super,
        key_ridge_position: this.intra_key,
        endo_treat: this.togg_endo_treat,
        //caries
        restorable: this.intra_rest,
        unrestorable: this.intra_unrest,
        occulusal_facet_wear: this.intra_occ,
        //soft tissue
        oral_hygeine: this.oral_hyg_sta,
        max_mouth_open: this.intra_max_mouth,
        gingival_texture: this.intra_ging_txt,
        palatal_contour: this.intra_pal_cont,

        oral_muscle_tone: this.circum_oral_mus_tone,
        tonsils_adenoids: this.intra_ton_anod,
        oral_mucosa: this.intra_oral_muos,
        //frenal
        frenal_upper: this.fren_upper,
        frenal_lower: this.fren_lower,
        //tongue
        tongue_size: intra_sizedata,
        tongue_posture: intra_postdata,
        tongue_shape: intra_shapedata,
        tongue_movements: intra_movedata,
        //extra oral
        extra_oral_con_note: this.con_note,
        extra_oral_jaw_note: this.jaw_note,
        TMJ_exam: this.tmj_exam,
        eoral_conditions: this.send_conditions_array,
        tmj: this.send_jaw_tmj_array,
        cephalic_index: ceph_ind_txtdata,
        facial_index: facial_index_txtdata,
        den_facial_divg_id: facial_divergencedata,
        lip_posturetxt: lip_posturedata,
        den_lip_post_id: lip_posturedata,
        lip_pos_incomp: in_compdata,
        clinical_fma: clinical_fma_txtdata,
        upper_lip: upp_lip_txtdata,
        lower_lip: low_lip_txtdata,
        den_chin_posi_id: chin_positiondata,
        den_naso_angle_id: naso_lab_angledata,
        den_ment_sulcus_id: mento_lab_scldata,
        inter_labial_gap: inter_labial_gap_txtdata,
        den_facial_profile_id: facial_profiledata,
        den_head_shape_id: shape_of_headdata,
        den_facial_form_id: facial_formdata,
        skeletal_relation: this.skeletal_relation_txt,
        den_face_sym_id: facial_symdata,
        //Gnathic
        shape: this.gnathic_shape,
        shapeleft: this.gnathic_shape_left,
        arch_symm: this.gnathic_arc,
        arch_symmleft: this.gnathic_arc_left,
        arch_align: this.gnathic_arc_ali,
        arch_alignleft: this.gnathic_arc_ali_left,
        axial_inclin: this.gnathic_axial_incli,
        axialleft: this.gnathic_axial_incli_left,
        oth_indiv_irregular: this.gnathic_other_indi,
        othleft: this.gnathic_other_indi_left,
        den_midline: this.gnathic_den_mid,
        denmidleft: this.gnathic_den_mid_left,
        skeletal_midline: this.gnathic_ske,
        skelleft: this.gnathic_ske_left,
        gnathic: maxmand_arrylist,
        curve_spce: this.gnathic_curve,
        moleright_txt: this.molar_right,
        moleleft_txt: this.molar_left,
        caniright_txt: this.molar_can_right,
        canileft_txt: this.molar_can_left,
        den_ant_post: ante_poster_arry,
        den_ant_post_flag: den_anflag,
        jet_condition: this.gnathic_over,
        jet_val: this.gnathic_over_val,
        bite_condition: this.gnathic_over_bite,
        bite_value: this.gnathic_bite_val,
        //functional
        respiration: fun_resdata,
        path_closure: fun_path_clodata,
        deglutition: fun_degdata,
        perioral_mus_act: fun_perio_mus_actdata,
        fun_speech: this.fun_speech,
        mastication: this.fun_masti,
        inter_clearance: this.fun_int_cle,
        expo_speech: this.fun_dur_speech,
        expo_smile: this.fun_du_smi,
        TMJ_desc: this.fun_jaw_txt,
        pain_dur: this.fun_pain_txt,
        sounds_dur: this.fun_ho_sound_txt,
        tend_palp_rt: this.tmj_rt,
        tend_palp_lt: this.tmj_rt,
        tend_palp_desc: this.fun_mus_wh,
        mandi_mt_open: this.fun_open_txt,
        mandi_mt_pro: this.fun_right_ex,
        mandi_mt_rt_excur: this.fun_right_ex,
        mandi_mt_lt_excur: this.fun_left_ex,
        TMJ_option: tmj_fun,
        pain_condition: ho_pain,
        sounds_cond: ho_sound,
        tend_palp_cond: tmj_trend,
        muscle_tend_palp: mus_trend,

        //ortho evaluation
        overbite: this.ortho_eva_overbite,
        crossbite: this.ortho_eva_crossbite,
        openbite: this.ortho_eva_openbite,
        overjet: this.ortho_eva_overjet,
        pri_mol_rel_rt: this.ortho_eva_rig,
        pri_mol_rel_lt: this.ortho_eva_lef,
        pre_mol_rel_rt: this.ortho_eva_mol_rit,
        pre_mol_rel_lt: this.ortho_eva_mol_left,
        sup_num_teeth: this.ortho_eva_sup_teetht,
        ectopic_eruption: this.ortho_eva_ect_erop,
        rotation: this.ortho_eva_rot,
        crowding_spacing: this.ortho_eva_crow,
        midline: this.ortho_eva_mid,
        arc_len_maxi: this.ortho_eva_arc,
        arc_len_mandi: this.ortho_eva_arc_mand,
        analysis_required: any_req,

        gestation_peroid: this.gest_period,
        delivery_mtd: this.gest_period,

        walking: this.walk_txt,
        running: this.run_txt,
        phonation: this.phon_txt,


        his_tonsill_adenoid: this.his_tonsil_ande,
        familial_maloc_his: this.famil_mal_occ,
        parents_maloc_type: this.parent_mal_hist,
      }

    } else if (this.get_spl_name == "Endodontics") {
      var dent_pat_mot = "no", reg_attent_data = "no", case_endotxt = "no", spl_flg = "no", exete_flg = "no";
      if (this.reg_atend == true) {
        reg_attent_data = "yes";
      }

      if (this.pain_txt != undefined && this.pain_txt != "") {
        case_endotxt = "yes";
      }
      if (this.send_spl_speci_dis.length != 0) {
        spl_flg = "yes";
      }
      if (this.intra_ext_tract != undefined || this.intra_ease != undefined || this.intra_fac_swell != undefined) {
        exete_flg = "yes";
      }
      if (this.pat_mottxt == true) {
        dent_pat_mot = "yes";
      }

      var intr_flg = "no"

      if (this.intra_mouth != undefined && this.intra_mouth != "") {
        intr_flg = "Yes";
      }

      gen_hist = {
        int_oral_flag: intr_flg,
        dent_pain_grade: this.gridsize,
        extendo_flag: exete_flg,
        spl_spec_dis_flag: spl_flg,
        case_endo_flag: case_endotxt,
        //endo
        pain_worse: this.pain_txt,
        pain_episode: this.pain_episode,
        intensity: this.intnsity_txt,
        location: this.location_txt,
        quality_pain: this.quality_pain,
        bleeding_spl_treat: bleed_injury,
        spl_treat_desc: this.bledd_injure,
        ill_operation: serious_operation,

        ill_oper_desc: this.ill_desc_data,
        dent_is_reg_attend: reg_attent_data,
        dent_patient_motive: dent_pat_mot,
        social_history: this.social_txt,
        relieve_factors: this.send_reli_factor,
        den_spl_spec_dis: this.send_spl_speci_dis,
        //intra oral
        mouth: this.intra_mouth,
        labial_mucosa: this.intra_lab_mous,
        palatal: this.intra_paltal,
        buccal_mucosa: this.intra_buc_mou,
        tongue: this.intra_tong,
        alveolar_mucosa: this.intra_alv_mus,
        periodintium: this.intra_peri,
        floor_mouth: this.intra_flo_mou,
        sinus_tracts: this.intra_ext_tract,
        ease_access: this.intra_ease,
        facial_swelling: this.intra_fac_swell,
        //extra oral
        extra_oral_con_note: this.con_note,
        extra_oral_jaw_note: this.jaw_note,
        TMJ_exam: this.tmj_exam,
        eoral_conditions: this.send_conditions_array,
        tmj: this.send_jaw_tmj_array,
      }

    } else if (this.get_spl_name == "Pediatrics") {
      var ped_flag = "no", diet_nalsis_flg = "no", gen_flag = "no", case_pedflg = "no", condition_txt = "", descrption_data = "", freq_data = "", inten_data = "", dure_dataoral = "", age_what = "", cond_suck = "";

      if (this.type_of_habit == "Intrinsic pressure habits ") {
        oral_habit = "yes"
        descrption_data = "";

      } else if (this.type_of_habit == "Pressure habits") {
        oral_habit = "yes"
        condition_txt = this.pressure_txt;
        descrption_data = "";
        freq_data = "";
        inten_data = "";
        dure_dataoral = "";
        age_what = "";

      } else if (this.type_of_habit == "Non pressure habit") {
        oral_habit = "yes"
        inten_data = "";
        freq_data = "";
        dure_dataoral = "";
        age_what = "";

      } else if (this.type_of_habit == "Biting habits ") {
        oral_habit = "yes"
        freq_data = "";
        inten_data = "";
        dure_dataoral = "";
        age_what = "";

      } else if (this.type_of_habit == "Individual habits") {
        oral_habit = "yes"
        condition_txt = "";
        descrption_data = this.individual_txt;
        freq_data = "";
        inten_data = "";
        dure_dataoral = "";
        age_what = "";

      } else {
        oral_habit = "no"
        condition_txt = "";
        descrption_data = "";
        freq_data = "";
        inten_data = "";
        dure_dataoral = "";
        age_what = "";

      }

      var over_biteflg = "no", hab_txt = "no", ped_histflg = "no", over_flg = "no", soft_flag = "no", oral_habit = "Yes", type_habit_id = 0, child_went_home = "no";
      if (this.ped_spc_rea != undefined) {
        case_pedflg = "yes";
      }

      if (this.intra_fac_swell != undefined || this.intra_pocket != undefined || this.intra_bleed != undefined) {
        gen_flag = "yes";
      }
      if (this.intra_tons != undefined || this.intra_hard_soft != undefined || this.intra_lip_lab != undefined || this.intra_fern != undefined) {
        soft_flag = "yes";
      } if (this.ortho_eva_arc != undefined || this.ortho_eva_arc_mand != undefined || this.ortho_eva_any != undefined || this.ortho_eva_rot != undefined || this.ortho_eva_crow != undefined || this.ortho_eva_mid != undefined || this.ortho_eva_ect_erop != undefined || this.ortho_eva_sup_teetht != undefined || this.ortho_eva_rig != undefined || this.ortho_eva_lef != undefined || this.ortho_eva_mol_rit != undefined || this.ortho_eva_mol_left != undefined || this.ortho_eva_overjet != undefined || this.intra_over != undefined || this.intra_cross != undefined || this.intra_open != undefined || this.intra_plung != undefined) {
        over_flg = "yes";
      }
      if ((this.ext_asy != undefined && this.ext_asy != "") && (this.ext_speech != undefined && this.ext_asy != "")) {
        ped_flag = "yes";
      }

      if (this.ortho_eva_overbite != undefined || this.ortho_eva_crossbite != undefined || this.ortho_eva_openbite != undefined || this.ortho_eva_overjet != undefined) {

        over_biteflg = "yes";
      }

      var intr_flg = "no"

      if (this.intra_mouth != undefined && this.intra_mouth != "") {
        intr_flg = "Yes";
      }

      gen_hist = {
        int_oral_flag: intr_flg,
        overbite_flag: over_biteflg,
        ped_oth_flag: ped_flag,

        walking: this.walk_txt,
        running: this.run_txt,
        phonation: this.phon_txt,
        his_tonsill_adenoid: this.his_tonsil_ande,
        familial_maloc_his: this.famil_mal_occ,
        parents_maloc_type: this.parent_mal_hist,

        ort_eval_exists: over_flg,
        gin_ped_flag: gen_flag,
        softisoth_flag: soft_flag,
        case_ped_flag: case_pedflg,
        //ped speci
        rate_type: this.rating_type,
        beh_rate: this.ped_rate,
        beh_desc: this.ped_spc_des,
        beh_reason: this.ped_spc_rea,
        pro_bottle_use: this.ped_spc_pro_bot,
        tooth_decay: this.ped_spc_tooth_app,
        rel_med_history: this.ped_spc_rel_hist,
        //intra
        mouth: this.intra_mouth,
        labial_mucosa: this.intra_lab_mous,
        palatal: this.intra_paltal,
        buccal_mucosa: this.intra_buc_mou,
        tongue: this.intra_tong,
        alveolar_mucosa: this.intra_alv_mus,
        periodintium: this.intra_peri,
        floor_mouth: this.intra_flo_mou,
        ped_lips_labial: this.intra_lip_lab,
        ped_tonsils: this.intra_tons,
        ped_hard_soft_palate: this.intra_hard_soft,
        pocket: this.intra_pocket,
        bleed: this.intra_bleed,
        ped_frenum: this.intra_fern,
        //hard tissue
        perm_teeth_present: this.perm_teeth_present_txt,
        dec_teeth_present: this.dec_teeth_present_txt,
        texture: this.intra_texture,
        unerupted_teeth: this.unerupted_teeth,
        ineruption_teeth: this.inerupted_teeth,
        size_shape_form: this.intra_super,
        key_ridge_position: this.intra_key,
        endo_treat: this.togg_endo_treat,
        //caries
        restorable: this.intra_rest,
        unrestorable: this.intra_unrest,
        occulusal_facet_wear: this.intra_occ,
        //extra oral
        extra_oral_con_note: this.con_note,
        extra_oral_jaw_note: this.jaw_note,
        TMJ_exam: this.tmj_exam,
        eoral_conditions: this.send_conditions_array,
        tmj: this.send_jaw_tmj_array,
        den_facial_profile_id: this.facial_profile,
        den_head_shape_id: this.shape_of_head,
        den_facial_form_id: this.facial_form,
        den_face_sym_id: this.facial_sym,
        // oralym: this.lym_node_txt,
        asymmetries: this.ext_asy,
        speech: this.ext_speech,
        ped_body_type: this.body_type,
        //ortho evaluation
        overbite: this.ortho_eva_overbite,
        crossbite: this.ortho_eva_crossbite,
        openbite: this.ortho_eva_openbite,
        overjet: this.ortho_eva_overjet,
        pri_mol_rel_rt: this.ortho_eva_rig,
        pri_mol_rel_lt: this.ortho_eva_lef,
        pre_mol_rel_rt: this.ortho_eva_mol_rit,
        pre_mol_rel_lt: this.ortho_eva_mol_left,
        sup_num_teeth: this.ortho_eva_sup_teetht,
        ectopic_eruption: this.ortho_eva_ect_erop,
        rotation: this.ortho_eva_rot,
        crowding_spacing: this.ortho_eva_crow,
        midline: this.ortho_eva_mid,
        arc_len_maxi: this.ortho_eva_arc,
        arc_len_mandi: this.ortho_eva_arc_mand,
        analysis_required: any_req,
        // ort_eval_exists: this.orat_evalflg
        //birth

        den_ging_colour: this.ging_col,
        child_health_cond: child_went_home,



        den_habit_type_id: type_habit_id,
        condition: condition_txt,
        problem: cond_suck,
        description: descrption_data,
        frequency: freq_data,
        duration: dure_dataoral,
        intensity: inten_data,
        age_habit_stop: age_what,
        //diet_details: this.diet_details_array,
        diet_flag: diet_flag_arry,
        // ped_per_hist_flag:ped_histflg,
        diet_analysis_flag: diet_nalsis_flg,
        oral_habit_flag: oral_habit,
        //den_personal_hist_id: this.den_personal_hist_id,
        //den_diet_hist_id: this.den_diet_hist_id,
        //natal
        gestation_peroid: this.gest_period,
        delivery_mtd: this.gest_period,
      }

    }
    else if (this.get_spl_name == "Prosthodontics") {
      var resp_flgtxt = "no", oral_falg = "no", tmj_fun = "no", ho_pain = "no", ho_sound = "no", tmj_trend = "no", mus_trend = "no";


      if (this.fun_togg_jaw == "yes") {
        tmj_fun = "yes";
      }
      if (this.fun_ho_pain == "yes") {
        ho_pain = "yes";
      }
      if (this.fun_ho_son == "yes") {
        ho_sound = "yes";
      }
      if (this.fun_tj_trend == "yes") {
        tmj_trend = "yes";
      }
      if (this.fun_tog_mus == "yes") {
        mus_trend = "yes";
      }

      if (this.oral_hyg_sta != undefined || this.intra_pal_cont != undefined || this.circum_oral_mus_tone != undefined || this.intra_pal_cont != undefined ||
        this.intra_oral_muos != undefined || this.intra_max_mouth != undefined || this.intra_ging_txt != undefined || this.intra_size != undefined ||
        this.intra_post != undefined || this.intra_shape != undefined || this.intra_move != undefined || this.intra_ton_anod != undefined) {
        oral_falg = "yes";
      }
      if ((this.fun_res != undefined || this.fun_res != "") || (this.fun_path_clo != undefined && this.fun_path_clo != "") || (this.fun_deg != undefined && this.fun_deg != "") ||
        (this.fun_perio_mus_act != undefined && this.fun_perio_mus_act != "") || (this.fun_speech != undefined && this.fun_speech != "") || (this.fun_masti != undefined && this.fun_masti != "") ||
        (this.fun_dur_speech != undefined && this.fun_dur_speech != "") || (this.fun_int_cle != undefined && this.fun_int_cle != "") || (this.fun_du_smi != undefined && this.fun_du_smi != "") ||
        this.fun_togg_jaw == "yes" || (this.fun_jaw_txt != undefined && this.fun_jaw_txt != "") || (this.fun_pain_txt != undefined && this.fun_pain_txt != "") || this.fun_ho_pain != undefined || this.fun_ho_son != this.unerupted_teeth ||
        (this.fun_ho_sound_txt != undefined && this.fun_ho_sound_txt != "") ||
        this.fun_tj_trend != undefined || (this.tmj_rt != undefined && this.tmj_rt != "") || (this.tmj_rt != undefined && this.tmj_rt != "") ||
        (this.fun_mus_wh != undefined && this.fun_mus_wh != "") || (this.fun_open_txt != undefined && this.fun_open_txt != "") ||
        (this.fun_right_ex != undefined && this.fun_right_ex != "") ||
        (this.fun_right_ex != undefined && this.fun_right_ex != "") || (this.fun_left_ex != undefined && this.fun_left_ex != "")) {


        resp_flgtxt = "yes";
      }

      var intr_flg = "no"

      if (this.intra_mouth != undefined && this.intra_mouth != "") {
        intr_flg = "Yes";
      }

      gen_hist = {
        int_oral_flag: intr_flg,
        oral_hygeine_flag: oral_falg,
        resp_flag: resp_flgtxt,

        //intra oral
        mouth: this.intra_mouth,
        labial_mucosa: this.intra_lab_mous,
        palatal: this.intra_paltal,
        buccal_mucosa: this.intra_buc_mou,
        tongue: this.intra_tong,
        alveolar_mucosa: this.intra_alv_mus,
        periodintium: this.intra_peri,
        floor_mouth: this.intra_flo_mou,
        //hard tissue
        perm_teeth_present: this.perm_teeth_present_txt,
        dec_teeth_present: this.dec_teeth_present_txt,
        texture: this.intra_texture,
        unerupted_teeth: this.unerupted_teeth,
        ineruption_teeth: this.inerupted_teeth,
        size_shape_form: this.intra_super,
        key_ridge_position: this.intra_key,
        endo_treat: this.togg_endo_treat,
        //caries
        restorable: this.intra_rest,
        unrestorable: this.intra_unrest,
        occulusal_facet_wear: this.intra_occ,
        //soft tissue
        oral_hygeine: this.oral_hyg_sta,
        max_mouth_open: this.intra_max_mouth,
        den_ging_colour: this.ging_col,
        gingival_texture: this.intra_ging_txt,
        palatal_contour: this.intra_pal_cont,

        oral_muscle_tone: this.circum_oral_mus_tone,
        tonsils_adenoids: this.intra_ton_anod,
        oral_mucosa: this.intra_oral_muos,
        //frenal
        frenal_upper: this.fren_upper,
        frenal_lower: this.fren_lower,
        //tongue
        tongue_size: this.intra_size,

        tongue_posture: this.intra_post,
        tongue_shape: this.intra_shape,
        tongue_movements: this.intra_move,
        //extra oral
        extra_oral_con_note: this.con_note,
        extra_oral_jaw_note: this.jaw_note,
        TMJ_exam: this.tmj_exam,
        eoral_conditions: this.send_conditions_array,
        tmj: this.send_jaw_tmj_array,
        cephalic_index: this.ceph_ind_txt,
        facial_index: this.facial_index_txt,
        den_facial_divg_id: this.facial_divergence,
        lip_posturetxt: this.lip_posture,
        den_lip_post_id: this.lip_posture,
        lip_pos_incomp: this.in_comp,
        clinical_fma: this.clinical_fma_txt,
        upper_lip: this.upp_lip_txt,
        lower_lip: this.low_lip_txt,
        den_chin_posi_id: this.chin_position,
        den_naso_angle_id: this.naso_lab_angle,
        den_ment_sulcus_id: this.mento_lab_scl,
        inter_labial_gap: this.inter_labial_gap_txt,
        den_facial_profile_id: this.facial_profile,
        den_head_shape_id: this.shape_of_head,
        den_facial_form_id: this.facial_form,
        skeletal_relation: this.skeletal_relation_txt,
        den_face_sym_id: this.facial_sym,
        lip_txt: this.lip_type,
        //functional
        respiration: this.fun_res,
        path_closure: this.fun_path_clo,
        deglutition: this.fun_deg,
        perioral_mus_act: this.fun_perio_mus_act,
        fun_speech: this.fun_speech,
        mastication: this.fun_masti,
        inter_clearance: this.fun_int_cle,
        expo_speech: this.fun_dur_speech,
        expo_smile: this.fun_du_smi,
        TMJ_desc: this.fun_jaw_txt,
        pain_dur: this.fun_pain_txt,
        sounds_dur: this.fun_ho_sound_txt,
        tend_palp_rt: this.tmj_rt,
        tend_palp_lt: this.tmj_rt,
        tend_palp_desc: this.fun_mus_wh,
        mandi_mt_open: this.fun_open_txt,
        mandi_mt_pro: this.fun_right_ex,
        mandi_mt_rt_excur: this.fun_right_ex,
        mandi_mt_lt_excur: this.fun_left_ex,
        TMJ_option: tmj_fun,
        pain_condition: ho_pain,
        sounds_cond: ho_sound,
        tend_palp_cond: tmj_trend,
        muscle_tend_palp: mus_trend,

      }
    }

    Doc_Helper.Set_den_spl(gen_hist);



  }
  hist_prev_treat() {
    if (this.ortho_fields == true) {
      this.ortho_fields = false;
    }
    else {
      this.ortho_fields = true;

    }
  }


  // den_hist_togg_change() {

  //   if (this.den_hist_togg == false || this.den_hist_togg == undefined) {
  //     this.child_visit_fields = false;
  //     this.ple_hidden = false;
  //     this.pre_treat_hidden = false;
  //     // this.last_vit_hidden = false;
  //     this.beh_last_vit_hidden = false;

  //   }
  //   else {
  //     this.child_visit_fields = true;
  //     this.ple_hidden = true;
  //     this.pre_treat_hidden = true;
  //     // this.last_vit_hidden = true;
  //     this.beh_last_vit_hidden = true;
  //   }

  // }

  ill_operation_txt_chage() {
    if (this.ill_opert == "no" || this.ill_opert == undefined || this.ill_opert == "No") {

      this.ill_operation_txt = false;
    }
    else {
      this.ill_operation_txt = true;
    }

  }
  //jaw
  jaw_function() {

    if (this.jaw_function_txt == true) {
      this.jaw_function_txt = false;
    } else {
      this.jaw_function_txt = true;
    }
  }

  //add table
  diet_add_click() {
    var chk_falg = true;

    if (chk_falg == true) {
      this.diet_details_array.push({
        day: "",
        session: this.dietsession_txt,
        type_food: (this.food_type),
        quantity: this.quantity_txt,
        met_prepare: this.med_prepara,
        qty_sugar: this.qty_sugar_data,
        data: false
      })

    }
    else {
      this.toastr.error(Message_data.mandatory);
    }
  }

  //master data
  reli_factor_popup() {
    for (var j = 0; j < this.reli_factor.length; j++) {
      this.reli_factor[j].checked = false;
    }


    if (this.send_reli_factor != undefined && this.send_reli_factor.length != 0) {
      for (var i = 0; i < this.send_reli_factor.length; i++) {
        for (var j = 0; j < this.reli_factor.length; j++) {
          if (this.send_reli_factor[i] == this.reli_factor[j].value) {
            this.reli_factor[j].checked = true;
          }
        }
      }
    }
  }

  // releving factor
  relievef(data, id, e) {
    if (e == true) {
      this.relieve_factors1.push(id)
    }
    else {
      if (this.relieve_factors1.length != 0) {
        for (var k = 0; k < this.relieve_factors1.length; k++) {
          if (id == this.relieve_factors1[k]) {
            this.relieve_factors1.splice(k);
          }
        }
      }
    }
    this.reli_factor_change(this.relieve_factors1)
  }

  closerel_popup() {
    this.flag1 = false;
  }

  relieve() {
    this.flag1 = true;
    for (var j = 0; j < this.reli_factor.length; j++) {
      this.reli_factor[j].checked = false;
    }

    if (this.send_reli_factor != undefined && this.send_reli_factor.length != 0) {
      for (var i = 0; i < this.send_reli_factor.length; i++) {
        for (var j = 0; j < this.reli_factor.length; j++) {
          if (this.send_reli_factor[i] == this.reli_factor[j].value) {
            this.reli_factor[j].checked = true;
          }
        }
      }
    }
  }

  get_reli_factor() {
    var obj = masterCSData_Helper.getMasterReliFactor();
    if (obj != undefined) {
      for (var i = 0; i < obj.relieve_factors.length; i++) {
        this.reli_factor.push({
          type: 'checkbox',
          // multiple:true,
          value: obj.relieve_factors[i].relieve_factor_id,
          label: obj.relieve_factors[i].description,
          checked: false
        });
      }
    }
  }
  //click event


  specificdiaseasepop(data, id, e) {
    if (e == true) {
      this.specificdiseases.push(id)
    }
    else {
      if (this.specificdiseases.length != 0) {
        for (var k = 0; k < this.specificdiseases.length; k++) {
          if (id == this.specificdiseases[k]) {
            this.specificdiseases.splice(k);
          }
        }
      }
    }
    this.spl_speci_dis_change(this.specificdiseases);
    this.send_data();
  }

  closespecificdisease() {
    this.specific_flag1 = false;
  }

  specificdiasease() {
    this.specific_flag1 = true;
    for (var j = 0; j < this.spl_speci_dis.length; j++) {
      this.spl_speci_dis[j].checked = false;
    }

    if (this.send_spl_speci_dis != undefined && this.send_spl_speci_dis.length != 0) {
      for (var i = 0; i < this.send_spl_speci_dis.length; i++) {
        for (var j = 0; j < this.spl_speci_dis.length; j++) {
          if (this.send_spl_speci_dis[i] == this.spl_speci_dis[j].value) {
            this.spl_speci_dis[j].checked = true;
          }
        }
      }
    }
  }
  fact_click(relieve_factor_id, flag) {
    if (flag == true) {
      this.send_reli_factor.push(

        relieve_factor_id
      )
    }
    else {
      for (var i = 0; this.send_reli_factor.length; i++) {
        if (this.send_reli_factor[i] == relieve_factor_id) {
          this.send_reli_factor.splice(i, 1);
          break;
        }
      }
    }
  }
  spl_speci_dis_popup() {
    for (var j = 0; j < this.spl_speci_dis.length; j++) {
      this.spl_speci_dis[j].checked = false;
    }


    if (this.send_spl_speci_dis != undefined && this.send_spl_speci_dis.length != 0) {
      for (var i = 0; i < this.send_spl_speci_dis.length; i++) {
        for (var j = 0; j < this.spl_speci_dis.length; j++) {
          if (this.send_spl_speci_dis[i] == this.spl_speci_dis[j].value) {
            this.spl_speci_dis[j].checked = true;
          }
        }
      }
    }

  }
  // speciality specific disease
  get_spl_speci_dis() {
    var obj = masterCSData_Helper.getMasterSplSpeciDisease();
    if (obj != undefined) {
      for (var i = 0; i < obj.splspec_diseases.length; i++) {
        this.spl_speci_dis.push({
          type: 'checkbox',
          // multiple:true,
          value: obj.splspec_diseases[i].splspec_disid,
          label: obj.splspec_diseases[i].description,
          checked: false
        });
      }
    }
  }
  spl_click(splspec_disid, flag) {
    if (flag == true) {
      this.send_spl_speci_dis.push(

        splspec_disid
      )
    }
    else {
      for (var i = 0; this.send_spl_speci_dis.length; i++) {
        if (this.send_spl_speci_dis[i] == splspec_disid) {
          this.send_spl_speci_dis.splice(i, 1);
          break;
        }
      }
    }
  }
  //click event
  condition_click(splspec_disid, flag) {
    if (flag == true) {
      this.send_conditions_array.push(

        splspec_disid
      )
    }
    else {
      for (var i = 0; this.send_conditions_array.length; i++) {
        if (this.send_conditions_array[i] == splspec_disid) {
          this.send_conditions_array.splice(i, 1);
          break;
        }
      }
    }
  }
  // area specific disease

  get_area_spec_dis_array() {
    var obj = masterCSData_Helper.getMasterAreaSpecificDisease();
    if (obj != undefined)
      this.area_spec_dis_array = obj.area_spec;
  }
  // age specific disease

  get_age_spec_dis_array() {
    var obj = masterCSData_Helper.getMasterAgeSpecificDisease();
    if (obj != undefined)
      this.age_spec_dis_array = obj.age_spec;
  }

  // gender specific disease

  get_gen_spec_dis_array() {
    var obj = masterCSData_Helper.getMasterGenderSpecificDisease();
    if (obj != undefined)
      this.gen_spec_dis_array = obj.gender_spec;
  }

  //Get the Occupational hazards

  get_occ_haz_array() {
    var obj = masterCSData_Helper.getMastergetOccupationalhazards();
    if (obj != undefined)
      this.occ_haz_array = obj.occu_hazards;
  }
  //Get the Body type

  get_body_type_array() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'dental/btype',
      JSON.stringify({
        gender: this.getdata.Gender_data,
      }),
      { headers: headers })
      .subscribe(
        response => {

          var obj = JSON.parse(response["_body"]);
          this.body_type_array = obj.body_types;

        },
        error => {

        }
      )
  }
  //Get the rating type

  get_rating_type_array() {
    var obj = masterCSData_Helper.getMastergetRatingType();
    this.rating_type_array = obj.den_pratings;
  }
  //Get the unerupted teeth
  get_unerupted_teeth_array() {
    var obj = masterCSData_Helper.getMasterUneruptedTeeth();
    if (obj != undefined)
      this.unerupted_teeth_array = obj.teeth_names;
  }
  //Get the inerupted teeth

  get_inerupted_teeth_array() {
    var obj = masterCSData_Helper.getMasterUneruptedTeeth();
    if (obj != undefined)
      this.inerupted_teeth_array = obj.teeth_names;
  }
  //Get Gingival colour

  get_ging_col_array() {
    var obj = masterCSData_Helper.getMasterGingivalColor();
    if (obj != undefined)
      this.ging_col_array = obj.ging_colors;
  }
  //Get Gingival colour

  get_circum_oral_mus_tone_array() {
    var obj = masterCSData_Helper.getMasterCircumOralMusTone();
    if (obj != undefined)
      this.circum_oral_mus_tone_array = obj.circum_tone;
  }
  //Get Upper type

  get_fren_upper_array() {
    var obj = masterCSData_Helper.getMasterUpperType();
    if (obj != undefined)
      this.fren_upper_array = obj.frenal_attachments;
  }
  //Lip posture

  get_lip_posture_array() {
    var obj = masterCSData_Helper.getMasterLipPosture();
    if (obj != undefined)
      this.lip_posture_array = obj.lip_postures;
  }

  //Get Chin position

  get_chin_position_array() {
    var obj = masterCSData_Helper.getMasterChinPosition();
    if (obj != undefined)
      this.chin_position_array = obj.chin_positions;
  }
  //Get naso label

  get_naso_lab_angle_array() {
    var obj = masterCSData_Helper.getMasterNasoLabelAngle();
    if (obj != undefined)
      this.naso_lab_angle_array = obj.naso_lobial;
  }
  //Get Mentolabial sclcus

  get_mento_lab_scl_array() {
    var obj = masterCSData_Helper.getMasterMentolabialSclcus();
    if (obj != undefined)
      this.mento_lab_scl_array = obj.mento_sulcus;
  }
  //Get Facial profile

  get_facial_profile_array() {
    var obj = masterCSData_Helper.getMasterFacialProfile();
    if (obj != undefined)
      this.facial_profile_array = obj.facial_profiles;
  }
  //Get Shape of Head

  get_shape_of_head_array() {
    var obj = masterCSData_Helper.getMasterShapeofHead();
    if (obj != undefined)
      this.shape_of_head_array = obj.head_shapes;
  }
  //Get facial form

  get_facial_form_array() {
    var obj = masterCSData_Helper.getMasterFacialForm();
    if (obj != undefined)
      this.facial_form_array = obj.facial_forms;
  }
  //Get Facial divergence

  get_facial_divergence_array() {
    var obj = masterCSData_Helper.getMasterFacialDivergence();
    if (obj != undefined)
      this.facial_divergence_array = obj.facial_divergencies;
  }
  //Get Facial symmetry

  get_facial_sym_array() {
    var obj = masterCSData_Helper.getMasterFacialSymmetry();
    if (obj != undefined)
      this.facial_sym_array = obj.facial_syms;
  }
  //Get the lip

  get_lip_type_array() {
    var obj = masterCSData_Helper.getMasterLip();
    if (obj != undefined)
      this.lip_type_array = obj.lip_types;
  }
  //molar right

  get_molar_right_array() {
    var obj = masterCSData_Helper.getMasterMolarRight();
    if (obj != undefined)
      this.molar_right_array = obj.den_molar;
  }

  //Get Childhood disease


  // Rating specific 
  onRatingChange(value) {
    this.ped_rate = value;
  }

  reli_factor_change(selectedValue) {
    this.reli_factor_type = "";
    function StringBuilder(this: any, value) {
      this.strings = new Array("");
      this.append(value);
    }

    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }

    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }

    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }

    var selected_data = new StringBuilder("");
    this.send_reli_factor = selectedValue;

    if (this.send_reli_factor.length != 0) {
      var n = 0;

      for (var j = 0; j < this.send_reli_factor.length; j++) {
        for (var i = 0; i < this.reli_factor.length; i++) {
          if (this.send_reli_factor[j] == this.reli_factor[i].value) {
            n = n + 1;
            if (n > 1) {
              selected_data.append(",");
              selected_data.append("\n");
            }
            selected_data.append(this.reli_factor[i].label);
          }
        }
      }
      this.reli_factor_type = selected_data.toString();
    }
  }

  spl_speci_dis_change(selectedValue) {
    this.spl_speci_dis_type = "";
    function StringBuilder(this: any, value) {
      this.strings = new Array("");
      this.append(value);
    }

    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }

    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }

    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }

    var selected_data = new StringBuilder("");
    this.spl_speci_dis_type = "";
    this.send_spl_speci_dis = selectedValue;

    if (this.send_spl_speci_dis.length != 0) {

      var n = 0;

      for (var j = 0; j < this.send_spl_speci_dis.length; j++) {
        for (var i = 0; i < this.spl_speci_dis.length; i++) {

          if (this.send_spl_speci_dis[j] == this.spl_speci_dis[i].value) {

            n = n + 1;
            if (n > 1) {
              selected_data.append(",");
              selected_data.append("\n");

            }
            selected_data.append(this.spl_speci_dis[i].label);

          }

        }
      }
      this.spl_speci_dis_type = selected_data.toString();

    }
  }
  extra_oral_tmj() {
    var obj = masterCSData_Helper.getMasterExtraOralTmj();
    if (obj.jt_conditions != undefined) {
      // this.Extra_Condtion_Data = obj.jt_conditions ;

      for (var i = 0; i < obj.jt_conditions.length; i++) {
        this.Extra_JawsTMJ_Data.push({
          type: 'checkbox',
          // multiple:true,
          value: obj.jt_conditions[i].den_jaws_tmj_con_id,
          label: obj.jt_conditions[i].description,
          checked: false
        });
      }

    }
  }

  extra_oral_cond() {
    var obj = masterCSData_Helper.getMasterExtraOralCondition();
    if (obj != undefined) {
      if (obj.extra_conditions != undefined) {
        for (var i = 0; i < obj.extra_conditions.length; i++) {
          this.Extra_Condtion_Data.push({
            type: 'checkbox',
            // multiple:true,
            value: obj.extra_conditions[i].den_extra_oral_con_id,
            label: obj.extra_conditions[i].description,
            checked: false
          });
        }
      }
    }
    this.RetrivalFunction();
  }
  extra_cond_popup(data, id) {
    this.temp_array1.push(id)
    this.Extra_Condtion_Data_change(this.temp_array1)
  }
  Extra_Condtion_Data_change(seleted) {

    function StringBuilder(this: any, value) {
      this.strings = new Array("");
      this.append(value);
    }

    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }

    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }

    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }

    var selected_data = new StringBuilder("");
    this.Extra_Condtion_Data_type = "";
    this.send_conditions_array = seleted;

    if (this.send_conditions_array.length != 0) {

      var n = 0;

      for (var j = 0; j < this.send_conditions_array.length; j++) {
        for (var i = 0; i < this.Extra_Condtion_Data.length; i++) {

          if (this.send_conditions_array[j] == this.Extra_Condtion_Data[i].value) {

            n = n + 1;
            if (n > 1) {
              selected_data.append(",");
              selected_data.append("\n");

            }
            selected_data.append(this.Extra_Condtion_Data[i].label);

          }

        }
      }
      this.Extra_Condtion_Data_type = selected_data.toString();

    }
    this.send_data();
  }

  closeextra() {
    this.extraflag = false;
  }

  extra() {
    this.extraflag = true;
    for (var j = 0; j < this.Extra_Condtion_Data.length; j++) {
      this.Extra_Condtion_Data[j].checked = false;
    }

    if (this.send_conditions_array != undefined && this.send_conditions_array.length != 0) {
      for (var i = 0; i < this.send_conditions_array.length; i++) {
        for (var j = 0; j < this.Extra_Condtion_Data.length; j++) {
          if (this.send_conditions_array[i] == this.Extra_Condtion_Data[j].value) {
            this.Extra_Condtion_Data[j].checked = true;
          }
        }
      }
    }
    this.send_data()
  }
  Extra_JawsTMJ_Data_change(selector) {

    this.Extra_JawsTMJ_Data_type = "";
    function StringBuilder(this: any, value) {
      this.strings = new Array("");
      this.append(value);
    }

    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }

    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }

    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }

    var selected_data = new StringBuilder("");

    this.send_jaw_tmj_array = selector;

    if (this.send_jaw_tmj_array.length != 0) {

      var n = 0;

      for (var j = 0; j < this.send_jaw_tmj_array.length; j++) {
        for (var i = 0; i < this.Extra_JawsTMJ_Data.length; i++) {
          if (this.send_jaw_tmj_array[j] == this.Extra_JawsTMJ_Data[i].value) {

            n = n + 1;
            if (n > 1) {
              selected_data.append(",");
              selected_data.append("\n");

            }
            selected_data.append(this.Extra_JawsTMJ_Data[i].label);

          }

        }
      }
      this.Extra_JawsTMJ_Data_type = selected_data.toString();
    }
    this.send_data();
  }

  extra_jaws_popup(data, id) {
    this.temp_jaw_array1.push(id)
    this.Extra_JawsTMJ_Data_change(this.temp_jaw_array1)
  }

  closejaw() {
    this.jawflag = false;
  }

  extrajaw() {
    this.jawflag = true;
    for (var j = 0; j < this.Extra_JawsTMJ_Data.length; j++) {
      this.Extra_JawsTMJ_Data[j].checked = false;
    }

    if (this.send_jaw_tmj_array != undefined && this.send_jaw_tmj_array.length != 0) {
      for (var i = 0; i < this.send_jaw_tmj_array.length; i++) {
        for (var j = 0; j < this.Extra_JawsTMJ_Data.length; j++) {
          if (this.send_jaw_tmj_array[i] == this.Extra_JawsTMJ_Data[j].value) {
            this.Extra_JawsTMJ_Data[j].checked = true;
          }
        }
      }
    }
    this.send_data();
  }

  extra_oral_change() {
    if (this.get_spl_name == "Orthodontics") {
      if ((this.ceph_ind_txt != undefined && this.ceph_ind_txt.length != 0) || (this.facial_index_txt != undefined && this.facial_index_txt.length != 0) || this.facial_divergence != undefined ||
        this.lip_posture != undefined || this.in_comp != undefined || this.clinical_fma_txt != undefined || this.upp_lip_txt != undefined ||
        this.low_lip_txt != undefined || this.chin_position != undefined || this.naso_lab_angle != undefined || this.mento_lab_scl != undefined ||
        (this.inter_labial_gap_txt != undefined && this.inter_labial_gap_txt.length != 0) || this.facial_profile != undefined || this.shape_of_head != undefined ||
        this.facial_form != undefined || (this.skeletal_relation_txt != undefined && this.skeletal_relation_txt.length != 0) || this.facial_sym != undefined) {

        this.extra_facial_sym_req = true;
        this.extra_skeletal_req = true;
        this.extra_facial_form_req = true;
        this.extra_shape_head_req = true;
        this.extra_face_prof_req = true;
        this.extra_intra_lab_req = true;
        this.extra_mento_scl_req = true;
        this.extra_naso_lab_req = true;
        this.extra_chin_posi_req = true;
        this.extra_lower_lip_req = true;
        this.extra_upper_lip_req = true;
        this.extra_clinical_fma_req = true;
        this.extra_in_comp_req = true;
        this.extra_lip_post_req = true;
        this.extra_facial_div_req = true;
        this.extra_facial_index_req = true;
        this.extra_ceph_req = true;

      } else {
        this.extra_facial_sym_req = false;
        this.extra_skeletal_req = false;
        this.extra_facial_form_req = false;
        this.extra_shape_head_req = false;
        this.extra_face_prof_req = false;
        this.extra_intra_lab_req = false;
        this.extra_mento_scl_req = false;
        this.extra_naso_lab_req = false;
        this.extra_chin_posi_req = false;
        this.extra_lower_lip_req = false;
        this.extra_upper_lip_req = false;
        this.extra_clinical_fma_req = false;
        this.extra_in_comp_req = false;
        this.extra_lip_post_req = false;
        this.extra_facial_div_req = false;
        this.extra_facial_index_req = false;
        this.extra_ceph_req = false;
      }

    } else if (this.get_spl_name == "Prosthodontics") {
      if ((this.ceph_ind_txt != undefined && this.ceph_ind_txt.length != 0) || (this.facial_index_txt != undefined && this.facial_index_txt.length != 0) || this.facial_divergence != undefined ||
        this.lip_posture != undefined || this.in_comp != undefined || this.clinical_fma_txt != undefined || this.upp_lip_txt != undefined ||
        this.low_lip_txt != undefined || this.chin_position != undefined || this.naso_lab_angle != undefined || this.mento_lab_scl != undefined ||
        (this.inter_labial_gap_txt != undefined && this.inter_labial_gap_txt.length != 0) || this.facial_profile != undefined || this.shape_of_head != undefined ||
        this.facial_form != undefined || (this.skeletal_relation_txt != undefined && this.skeletal_relation_txt.length != 0) || this.facial_sym != undefined || this.lip_type != undefined) {

        this.extra_facial_sym_req = true;
        this.extra_skeletal_req = true;
        this.extra_facial_form_req = true;
        this.extra_shape_head_req = true;
        this.extra_face_prof_req = true;
        this.extra_intra_lab_req = true;
        this.extra_mento_scl_req = true;
        this.extra_naso_lab_req = true;
        this.extra_chin_posi_req = true;
        this.extra_lower_lip_req = true;
        this.extra_upper_lip_req = true;
        this.extra_clinical_fma_req = true;
        this.extra_in_comp_req = true;
        this.extra_lip_post_req = true;
        this.extra_facial_div_req = true;
        this.extra_facial_index_req = true;
        this.extra_ceph_req = true;
        this.extra_lip_req = true;

      } else {
        this.extra_facial_sym_req = false;
        this.extra_skeletal_req = false;
        this.extra_facial_form_req = false;
        this.extra_shape_head_req = false;
        this.extra_face_prof_req = false;
        this.extra_intra_lab_req = false;
        this.extra_mento_scl_req = false;
        this.extra_naso_lab_req = false;
        this.extra_chin_posi_req = false;
        this.extra_lower_lip_req = false;
        this.extra_upper_lip_req = false;
        this.extra_clinical_fma_req = false;
        this.extra_in_comp_req = false;
        this.extra_lip_post_req = false;
        this.extra_facial_div_req = false;
        this.extra_facial_index_req = false;
        this.extra_ceph_req = false;
        this.extra_lip_req = false;
      }

    } else if (this.get_spl_name == "Public health") {
      if (this.facial_sym != undefined || this.lip_type != undefined || this.lym_node_txt != undefined || (this.phy_tons_txt != undefined && this.phy_tons_txt.length != 0)) {
        this.extra_phy_tonsils_req = true;
        this.extra_facial_sym_req = true;
        this.extra_lip_req = true;
        this.extra_lymph_node_req = true;

      } else {
        this.extra_phy_tonsils_req = false;
        this.extra_facial_sym_req = false;
        this.extra_lip_req = false;
        this.extra_lymph_node_req = false;
      }

    } else if (this.get_spl_name == "Periodontics") {
      this.skeletal = true;
      this.fac_data_div = true;
      if (this.facial_sym != undefined || this.lip_type != undefined || this.lym_node_txt != undefined || (this.phy_tons_txt != undefined && this.phy_tons_txt.length != 0)) {
        this.extra_phy_tonsils_req = true;
        this.extra_facial_sym_req = true;
        this.extra_lip_req = true;
        this.extra_lymph_node_req = true;

      } else {
        this.extra_phy_tonsils_req = false;
        this.extra_facial_sym_req = false;
        this.extra_lip_req = false;
        this.extra_lymph_node_req = false;
      }
    }
  }

  intra_oral_change() {
    if (this.get_spl_name == "Orthodontics") {
      if (this.oral_hyg_sta != undefined || (this.intra_max_mouth != undefined && this.intra_max_mouth != 0) || this.ging_col != undefined ||
        this.intra_ging_txt != undefined || this.intra_pal_cont != undefined || this.circum_oral_mus_tone != undefined || (this.intra_ton_anod != undefined && this.intra_ton_anod != 0) ||
        (this.intra_oral_muos != undefined && this.intra_oral_muos != 0) || (this.intra_size != undefined && this.intra_size != 0) || (this.intra_post != undefined && this.intra_post != 0) ||
        (this.intra_shape != undefined && this.intra_shape != 0) || (this.intra_move != undefined && this.intra_move != 0)) {
        this.intra_oral_hyg_req = true;
        this.intra_max_mouth_req = true;
        this.intra_ging_col_req = true;
        this.intra_ging_txt_req = true;
        this.intra_pal_cont_req = true;
        this.intra_cir_oral_req = true;
        this.intra_tons_ando_req = true;
        this.intra_oral_mous_req = true;
        this.intra_tong_size_req = true;
        this.intra_tong_post_req = true;
        this.intra_tong_shape_req = true;
        this.intra_tong_move_req = true;

      }
      else {
        this.intra_oral_hyg_req = false;
        this.intra_max_mouth_req = false;
        this.intra_ging_col_req = false;
        this.intra_ging_txt_req = false;
        this.intra_pal_cont_req = false;
        this.intra_cir_oral_req = false;
        this.intra_tons_ando_req = false;
        this.intra_oral_mous_req = false;
        this.intra_tong_size_req = false;
        this.intra_tong_post_req = false;
        this.intra_tong_shape_req = false;
        this.intra_tong_move_req = false;
      }
    }
    else if (this.get_spl_name == "Pediatrics") {
      if ((this.perm_teeth_present_txt != undefined && this.perm_teeth_present_txt.length != 0) || (this.dec_teeth_present_txt != undefined && this.dec_teeth_present_txt.length != 0) ||
        this.intra_texture != undefined || this.unerupted_teeth != undefined || this.inerupted_teeth != undefined || (this.intra_super != undefined && this.intra_super.length != 0) ||
        (this.intra_key != undefined && this.intra_key.length != 0) || this.togg_endo_treat == "yes") {
        this.intra_per_teeth_req = true;
        this.intra_dec_teeth_req = true;
        this.intra_texture_req = true;
        this.intra_un_erupt_teeth_req = true;
        this.intra_in_erupt_teeth_req = true;
        this.intra_key_post_req = true;
      }
      else {
        this.intra_per_teeth_req = false;
        this.intra_dec_teeth_req = false;
        this.intra_texture_req = false;
        this.intra_un_erupt_teeth_req = false;
        this.intra_in_erupt_teeth_req = false;
        this.intra_key_post_req = false;
      }

    }
    if (this.get_spl_name == "Prosthodontics") {
      if (this.oral_hyg_sta != undefined || (this.intra_max_mouth != undefined && this.intra_max_mouth != 0) || this.ging_col != undefined ||
        this.intra_ging_txt != undefined || this.intra_pal_cont != undefined || this.circum_oral_mus_tone != undefined || (this.intra_ton_anod != undefined && this.intra_ton_anod != 0) ||
        (this.intra_oral_muos != undefined && this.intra_oral_muos != 0) || (this.intra_size != undefined && this.intra_size != 0) || (this.intra_post != undefined && this.intra_post != 0) ||
        (this.intra_shape != undefined && this.intra_shape != 0) || (this.intra_move != undefined && this.intra_move != 0)) {
        this.intra_oral_hyg_req = true;
        this.intra_max_mouth_req = true;
        this.intra_ging_col_req = true;
        this.intra_ging_txt_req = true;
        this.intra_pal_cont_req = true;
        this.intra_cir_oral_req = true;
        this.intra_tons_ando_req = true;
        this.intra_oral_mous_req = true;
        this.intra_tong_size_req = true;
        this.intra_tong_post_req = true;
        this.intra_tong_shape_req = true;
        this.intra_tong_move_req = true;

      }
      else {
        this.intra_oral_hyg_req = false;
        this.intra_max_mouth_req = false;
        this.intra_ging_col_req = false;
        this.intra_ging_txt_req = false;
        this.intra_pal_cont_req = false;
        this.intra_cir_oral_req = false;
        this.intra_tons_ando_req = false;
        this.intra_oral_mous_req = false;
        this.intra_tong_size_req = false;
        this.intra_tong_post_req = false;
        this.intra_tong_shape_req = false;
        this.intra_tong_move_req = false;
      }
    }
  }
  functional_change() {
    if (this.get_spl_name == "Orthodontics") {
      if (this.fun_res != undefined || this.fun_path_clo != undefined || this.fun_deg != undefined || this.fun_perio_mus_act != undefined || this.fun_speech != undefined && this.fun_speech != ""
        || this.fun_masti != undefined && this.fun_masti != "" || this.fun_int_cle != undefined && this.fun_int_cle != "" || this.fun_togg_jaw == true || (this.fun_jaw_txt != undefined && this.fun_jaw_txt.length != 0) ||
        this.fun_ho_pain == "yes" || (this.fun_pain_txt != undefined && this.fun_pain_txt.length != 0) || this.fun_ho_son == "yes" || (this.fun_ho_sound_txt != undefined && this.fun_ho_sound_txt.length != 0)
        || this.fun_tj_trend == "yes" || (this.tmj_rt != undefined && this.tmj_rt.length != 0) || (this.tmj_lt != undefined && this.tmj_lt.length != 0) || this.fun_tog_mus == "yes" || (this.fun_mus_wh != undefined && this.fun_mus_wh.length != 0) ||
        (this.fun_open_txt != undefined && this.fun_open_txt.length != 0) || (this.fun_prot != undefined && this.fun_prot.length != 0) || (this.fun_right_ex != undefined && this.fun_right_ex.length != 0) || (this.fun_left_ex != undefined && this.fun_left_ex.length != 0) ||
        (this.fun_dur_speech != undefined && this.fun_dur_speech.length != 0) || (this.fun_du_smi != undefined && this.fun_du_smi.length != 0)) {
        this.fun_res_req = true;
        this.fun_path_clos_req = true;
        this.fun_degul_req = true;
        this.fun_per_mus_act_req = true;
        this.fun_speech_req = true;
        this.fun_mast_req = true;
        this.fun_int_clear_req = true;

        this.fun_dur_speech_req = true;
        this.fun_fun_du_smi_req = true;

        this.fun_jaw_req = true;
        this.fun_pain_du_req = true;
        this.fun_sound_du_req = true;
        this.fun_trend_rt_req = true;
        this.fun_trend_lt_req = true;
        this.fun_mus_wh_req = true;
        this.fun_open_req = true;
        this.fun_port_req = true;
        this.fun_rt_ex_req = true;
        this.fun_lt_ex_req = true;
      }

      else {
        this.fun_res_req = false;
        this.fun_path_clos_req = false;
        this.fun_degul_req = false;
        this.fun_per_mus_act_req = false;
        this.fun_speech_req = false;
        this.fun_mast_req = false;
        this.fun_int_clear_req = false;

        this.fun_dur_speech_req = false;
        this.fun_fun_du_smi_req = false;

        this.fun_jaw_req = false;
        this.fun_pain_du_req = false;
        this.fun_sound_du_req = false;
        this.fun_trend_rt_req = false;
        this.fun_trend_lt_req = false;
        this.fun_mus_wh_req = false;
        this.fun_open_req = false;
        this.fun_port_req = false;
        this.fun_rt_ex_req = false;
        this.fun_lt_ex_req = false;
      }
    }
    if (this.get_spl_name == "Prosthodontics") {
      if (this.fun_res != undefined || this.fun_path_clo != undefined || this.fun_deg != undefined || this.fun_perio_mus_act != undefined || this.fun_speech != undefined && this.fun_speech != ""
        || this.fun_masti != undefined && this.fun_masti != "" || this.fun_int_cle != undefined && this.fun_int_cle != "" || this.fun_togg_jaw == "yes" || (this.fun_jaw_txt != undefined && this.fun_jaw_txt.length != 0) ||
        this.fun_ho_pain == "yes" || (this.fun_pain_txt != undefined && this.fun_pain_txt.length != 0) || this.fun_ho_son == "yes" || (this.fun_ho_sound_txt != undefined && this.fun_ho_sound_txt.length != 0)
        || this.fun_tj_trend == "yes" || (this.tmj_rt != undefined && this.tmj_rt.length != 0) || (this.tmj_lt != undefined && this.tmj_lt.length != 0) || this.fun_tog_mus == "yes" || (this.fun_mus_wh != undefined && this.fun_mus_wh.length != 0) ||
        (this.fun_open_txt != undefined && this.fun_open_txt.length != 0) || (this.fun_prot != undefined && this.fun_prot.length != 0) || (this.fun_right_ex != undefined && this.fun_right_ex.length != 0) || (this.fun_left_ex != undefined && this.fun_left_ex.length != 0) ||
        (this.fun_dur_speech != undefined && this.fun_dur_speech.length != 0) || (this.fun_du_smi != undefined && this.fun_du_smi.length != 0)) {
        this.fun_res_req = true;
        this.fun_path_clos_req = true;
        this.fun_degul_req = true;
        this.fun_per_mus_act_req = true;
        this.fun_speech_req = true;
        this.fun_mast_req = true;
        this.fun_int_clear_req = true;

        this.fun_dur_speech_req = true;
        this.fun_fun_du_smi_req = true;

        this.fun_jaw_req = true;
        this.fun_pain_du_req = true;
        this.fun_sound_du_req = true;
        this.fun_trend_rt_req = true;
        this.fun_trend_lt_req = true;
        this.fun_mus_wh_req = true;
        this.fun_open_req = true;
        this.fun_port_req = true;
        this.fun_rt_ex_req = true;
        this.fun_lt_ex_req = true;
      }

      else {
        this.fun_res_req = false;
        this.fun_path_clos_req = false;
        this.fun_degul_req = false;
        this.fun_per_mus_act_req = false;
        this.fun_speech_req = false;
        this.fun_mast_req = false;
        this.fun_int_clear_req = false;

        this.fun_dur_speech_req = false;
        this.fun_fun_du_smi_req = false;


        this.fun_jaw_req = false;
        this.fun_pain_du_req = false;
        this.fun_sound_du_req = false;
        this.fun_trend_rt_req = false;
        this.fun_trend_lt_req = false;
        this.fun_mus_wh_req = false;
        this.fun_open_req = false;
        this.fun_port_req = false;
        this.fun_rt_ex_req = false;
        this.fun_lt_ex_req = false;
      }
    }
  }

  // endo
  endo_change() {
    if (this.get_spl_name == "Endodontics") {
      if ((this.pain_txt != undefined && this.pain_txt.length != 0) || (this.pain_episode != undefined && this.pain_episode.length != 0) || (this.location_txt != undefined && this.location_txt.length != 0) ||
        (this.social_txt != undefined && this.social_txt.length != 0) || (this.intnsity_txt != undefined && this.intnsity_txt.length != 0) || (this.quality_pain != undefined && this.quality_pain.length != 0)
        || this.bleed_togg == true || this.ill_opert == "yes" || this.reg_atend == true || this.pat_mottxt == "yes" || (this.intnsity_txt != undefined && this.intnsity_txt.length != 0) || (this.grade_txt != undefined && this.grade_txt.length != 0)) {
        this.endo_pain_wrose_req = true;
        this.endo_pain_episode_req = true;
        this.endo_loc_req = true;
        this.endo_int_pain_req = true;
        this.endo_bleed_inj_req = true;
        this.endo_ill_oper_req = true;

        this.endo_pain_scale_req = true;
      }
      else {
        this.endo_pain_scale_req = false;

        this.endo_pain_wrose_req = false;
        this.endo_pain_episode_req = false;
        this.endo_loc_req = false;
        this.endo_int_pain_req = false;
        this.endo_bleed_inj_req = false;
        this.endo_ill_oper_req = false;
      }

    }
  }

  //Pediatrics
  pediatric_change() {
    if (this.get_spl_name == "Pediatrics") {
      if (this.rating_type != undefined || (this.ped_spc_des != undefined && this.ped_spc_des.length != 0) || (this.ped_spc_rea != undefined && this.ped_spc_rea.length != 0)
        || (this.ped_spc_pro_bot != undefined && this.ped_spc_pro_bot.length != 0) || (this.ped_spc_tooth_app != undefined && this.ped_spc_tooth_app.length != 0) || (this.ped_spc_rel_hist != undefined && this.ped_spc_rel_hist.length != 0)) {


        this.ped_reason_req = true;
        this.ped_pro_use_req = true;
        this.ped_tooth_decay_req = true;
      }
      else {
        this.ped_reason_req = false;
        this.ped_pro_use_req = false;
        this.ped_tooth_decay_req = false;
      }
    }
  }

  Ortho_change() {
    if (this.get_spl_name == "Pediatrics") {
      if ((this.ortho_eva_overjet != undefined && this.ortho_eva_overjet.length != 0) || (this.ortho_eva_overbite != undefined && this.ortho_eva_overbite.length != 0) ||
        (this.ortho_eva_crossbite != undefined && this.ortho_eva_crossbite.length != 0) || (this.ortho_eva_openbite != undefined && this.ortho_eva_openbite.length != 0) ||
        (this.ortho_eva_sup_teetht != undefined && this.ortho_eva_sup_teetht.length != 0) || (this.ortho_eva_ect_erop != undefined && this.ortho_eva_ect_erop.length != 0)
        || (this.ortho_eva_rot != undefined && this.ortho_eva_rot.length != 0) || (this.ortho_eva_crow != undefined && this.ortho_eva_crow.length != 0)
        || this.ortho_eva_mid != undefined || this.ortho_eva_arc != undefined || this.ortho_eva_arc_mand != undefined || this.ortho_eva_any == true ||
        (this.ortho_eva_rig != undefined && this.ortho_eva_rig.length != 0) || (this.ortho_eva_lef != undefined && this.ortho_eva_lef.length != 0)
        || (this.ortho_eva_mol_rit != undefined && this.ortho_eva_mol_rit.length != 0) || (this.ortho_eva_mol_left != undefined && this.ortho_eva_mol_left.length != 0)) {
        this.ortho_over_jet_req = true;
        this.ortho_over_bite_req = true;
        this.ortho_cross_bite_req = true;
        this.ortho_open_bite_req = true;
        this.ortho_etho_erop_req = true;
        this.ortho_prime_rt_req = true;
        this.ortho_prime_lt_req = true;
        this.ortho_perm_rt_req = true;
        this.ortho_perm_lt_req = true;
      }
      else {
        this.ortho_over_jet_req = false;
        this.ortho_over_bite_req = false;
        this.ortho_cross_bite_req = false;
        this.ortho_open_bite_req = false;
        this.ortho_etho_erop_req = false;
        this.ortho_prime_rt_req = false;
        this.ortho_prime_lt_req = false;
        this.ortho_perm_rt_req = false;
        this.ortho_perm_lt_req = false;
      }
    }
  }

  RetrivalFunction() {
    this.subscription = this.dentalservice.getMessage().subscribe(message => {
      if (message) {
        // var data = message.spl
        this.speciality_change(message.spl);
        Doc_Helper.Set_dent_spl(message.spl);

      } else {
        // clear messages when empty message received
        var la = [];
        la = [];
      }
    });

    if (this.getret1 != undefined) {
      if (this.getret1.ext_oral_cond != null || this.getret1.ext_oral_cond != undefined) {
        for (var i = 0; i < this.getret1.ext_oral_cond.length; i++) {
          this.send_conditions_array.push(this.getret1.ext_oral_cond[i].condition)
          this.temp_array1.push(this.getret1.ext_oral_cond[i].condition);
        }
        this.Extra_Condtion_Data_change(this.send_conditions_array)
      }
      if (this.getret1.tmj != null && this.getret1.tmj.length != 0) {
        for (var i = 0; i < this.getret1.tmj.length; i++) {
          this.send_jaw_tmj_array.push(this.getret1.tmj[i].jaw_TMJ);
          this.temp_jaw_array1.push(this.getret1.tmj[i].jaw_TMJ);
        }
        if (this.send_jaw_tmj_array.length != 0) {
          this.Extra_JawsTMJ_Data_change(this.send_jaw_tmj_array);
        }


        // if (this.send_jaw_tmj_array.length != 0) {
        //   this.Extra_JawsTMJ_Data_change(this.send_jaw_tmj_array);
        // }
      }
      // perio -spl disease
      if (this.getret1.den_area_specific_id != null) {
        this.area_spec_dis = this.getret1.den_area_specific_id;
      }
      if (this.getret1.den_age_spec_id != null) {
        this.age_spec_dis = this.getret1.den_age_spec_id;
      }
      if (this.getret1.den_gen_spec_id != null) {
        this.gen_spec_dis = this.getret1.den_gen_spec_id;
      }
      if (this.getret1.den_occ_haz_id != null) {
        this.occ_haz = this.getret1.den_occ_haz_id;
      }
      if (this.getret1.mastigatory_muscle != null) {
        this.mast_mus_tri = this.getret1.mastigatory_muscle;
      }
    }

    if (this.getret2 != undefined) {

      this.loader = true;
      if (this.getret2.rate_type != null) {
        this.rating_type = this.getret2.rate_type;
      }

      if (this.getret1.pain_worse != null) {
        this.pain_txt = this.getret1.pain_worse;
      }
      if (this.getret1.pain_episode != null) {
        this.pain_episode = this.getret1.pain_episode;
      }
      if (this.getret1.location != null) {
        this.location_txt = this.getret1.location;
      }
      if (this.getret1.intensity != null) {
        this.intnsity_txt = this.getret1.intensity;
      }
      if (this.getret1.social_history != null) {
        this.social_txt = this.getret1.social_history;
      }
      if (this.getret1.quality_pain != null) {
        this.quality_pain = this.getret1.quality_pain;
      }
      if (this.getret1.spl_treat_desc != null) {
        this.bledd_injure = this.getret1.spl_treat_desc;
        //this.bleed_txt = false;
        this.bleed_togg = "yes";
        this.bleed_togg1 = true;
        this.bleed_txt = false;

      }
      if (this.getret1.bleeding_spl_treat != null) {
        this.bleed_togg1 = true;
        this.bleed_togg = "yes";
      }
      else {
        this.bleed_togg1 = false;
      }
      if (this.getret1.ill_operation != null) {
        this.ill_opert1 = true;
        this.ill_operation_txt = false;
        //this.ill_opert = true;
      }
      if (this.getret1.ill_oper_desc != null) {
        this.ill_desc_data = this.getret1.ill_oper_desc;
      }
      if (this.getret1.dent_patient_motive != null) {
        this.pat_mottxt1 = true;
        this.pat_mottxt = "yes";
      }
      else {
        this.pat_mottxt1 = false;
        this.pat_mottxt = "no";
      }
      if (this.getret1.dent_is_reg_attend != null) {
        this.reg_atend1 = true;
        this.reg_atend = "yes";
      }

      else {
        this.reg_atend1 = false;
      }
      if (this.getret1.dent_pain_grade != undefined) {
        this.grade_txt = this.getret1.dent_pain_grade;
      }

      if (this.getret2.beh_desc != null) {
        this.ped_spc_des = this.getret2.beh_desc;
      }
      if (this.getret2.beh_reason != null) {
        this.ped_spc_rea = this.getret2.beh_reason;
      }
      if (this.getret2.pro_bottle_use != null) {
        this.ped_spc_pro_bot = this.getret2.pro_bottle_use;
      }
      if (this.getret2.tooth_decay != null) {
        this.ped_spc_tooth_app = this.getret2.tooth_decay;
      }
      if (this.getret2.rel_med_history != null) {
        this.ped_spc_rel_hist = this.getret2.rel_med_history;
      }
      if (this.getret2.ped_tonsils != null) {
        this.intra_tons = this.getret2.ped_tonsils;
      }
      if (this.getret2.ped_lips_labial != null) {
        this.intra_lip_lab = this.getret2.ped_lips_labial;
      }
      if (this.getret2.ped_hard_soft_palate != null) {
        this.intra_hard_soft = this.getret2.ped_hard_soft_palate;
      }
      if (this.getret2.pocket != null) {
        this.intra_pocket = this.getret2.pocket;
      }
      if (this.getret2.bleed != null) {
        this.intra_bleed = this.getret2.bleed;
      }
      if (this.getret2.ped_frenum != null) {
        this.intra_fern = this.getret2.ped_frenum;
      }
      if (this.getret2.sinus_tracts != null) {
        this.intra_ext_tract = this.getret2.sinus_tracts;
      }
      if (this.getret2.ease_access != null) {
        this.intra_ease = this.getret2.ease_access;
      }
      if (this.getret2.facial_swelling != null) {
        this.intra_fac_swell = this.getret2.facial_swelling;
      }
      if (this.getret1.special_dis != null && this.getret1.special_dis.length != 0) {
        this.spl_speci_dis_type = this.getret1.special_dis;
        for (var i = 0; i < this.getret1.special_dis.length; i++) {
          this.send_spl_speci_dis.push(this.getret1.special_dis[i]);
          this.specificdiseases.push(this.send_spl_speci_dis)
        }
        this.spl_speci_dis_change(this.send_spl_speci_dis);
      }
      if (this.getret2.relieve_factors != null && this.getret2.relieve_factors.length != 0) {
        this.reli_factor_type = this.getret2.relieve_factors;
        for (var i = 0; i < this.getret2.relieve_factors.length; i++) {
          // if (this.getret2.relieve_factors[i] == this.reli_factor[i].relieve_factor_id) {
          this.send_reli_factor.push(this.reli_factor[i].relieve_factor_id);
          this.relieve_factors1.push(this.reli_factor[i].relieve_factor_id)
          //}        
          this.reli_factor_change(this.send_reli_factor);
        }

      }

      if (this.getret2.dent_is_reg_attend != null && (this.getret2.dent_is_reg_attend == "yes" || this.getret2.dent_is_reg_attend == "Yes")) {
        this.reg_atend = "yes";
      }
      // if (this.getret2.body_type != null) {
      //   this.pedbody_type = this.getret2.body_type;
      // }
      // if (this.getret2.delivery_mtd != null) {
      //   this.health_mot = this.getret2.delivery_mtd;
      // }
      if (this.getret2.delivery_mtd != null) {
        this.gest_period = this.getret2.delivery_mtd;
      }
      if (this.getret2.his_orthodontic_treatment != null && (this.getret2.his_orthodontic_treatment == "yes" || this.getret2.his_orthodontic_treatment == "Yes")) {
        this.his_ortho = true;
        this.ortho_fields = false;
      }

      //functional
      if (this.getret2.respiration != null) {
        this.fun_res = this.getret2.respiration;
      }
      if (this.getret2.deglutition != null) {
        this.fun_deg = this.getret2.deglutition;
      }
      if (this.getret2.fun_speech != null) {
        this.fun_speech = this.getret2.fun_speech;
      }
      if (this.getret2.mastication != null) {
        this.fun_masti = this.getret2.mastication;
      }
      if (this.getret2.path_closure != null) {
        this.fun_path_clo = this.getret2.path_closure;
      }
      if (this.getret2.perioral_mus_act != null) {
        this.fun_perio_mus_act = this.getret2.perioral_mus_act;
      }
      if (this.getret2.expo_speech != null) {
        this.fun_dur_speech = this.getret2.expo_speech;
      }
      if (this.getret2.expo_smile != null) {
        this.fun_du_smi = this.getret2.expo_smile;
      }
      if (this.getret2.inter_occ_clear != null) {
        this.fun_int_cle = this.getret2.inter_occ_clear;
      }
      if (this.getret2.TMJ_option != null) {
        if (this.getret2.TMJ_option == "yes") {
          this.fun_togg_jaw1 = true;
          this.jaw_function_txt = true;
          this.fun_jaw_txt = this.getret2.TMJ_desc;
        }
        else {
          this.fun_togg_jaw1 = false;
          this.jaw_function_txt = false;

        }
        //  this.fun_togg_jaw1 = true;

      }
      if (this.getret2.TMJ_desc != null) {
        this.fun_jaw_txt = this.getret2.TMJ_desc;
      }
      if (this.getret2.pain_cond != null) {
        if (this.getret2.pain_cond == "yes") {
          this.fun_ho_pain1 = this.getret2.pain_cond;
          this.ho_pain_text = false;
          this.fun_pain_txt = this.getret2.pain_dur;
        }
        else {
          this.fun_ho_pain1 = false;
          this.ho_pain_text = true;

        }
      }
      // if (this.getret2.pain_dur != null) {
      //   this.ho_pain_text = false;
      //   this.fun_pain_txt = this.getret2.pain_dur;
      // }
      if (this.getret2.sounds_cond != null) {
        if (this.getret2.sounds_cond == "yes") {
          this.fun_ho_son1 = true;
          this.fun_ho_son = this.getret2.sounds_cond;
          this.ho_sound_text = false;
          this.fun_ho_sound_txt = this.getret2.sounds_dur;
        }
        else {
          this.fun_ho_son1 = false;
        }
      }
      if (this.getret2.sounds_dur != null) {
        this.fun_ho_sound_txt = this.getret2.sounds_dur;
      }
      if (this.getret2.tmj_tend_palp_cond != null) {
        if (this.getret2.tmj_tend_palp_cond == "yes") {
          //if(this.fun_tj_trend = "yes")
          this.fun_tj_trend1 = true;
          this.fun_tj_trend = "yes";
          this.tmj_trend_txt = false;
        }
        else {
          this.fun_tj_trend1 = false;

        }
      }
      else {
        this.fun_tj_trend1 = false;
      }
      if (this.getret2.tmj_tend_palp_rt != null) {
        this.tmj_rt = this.getret2.tmj_tend_palp_rt;
      }
      if (this.getret2.tmj_tend_palp_lt != null) {
        this.tmj_lt = this.getret2.tmj_tend_palp_lt;
      }
      if (this.getret2.mus_tend_palp != null) {
        this.fun_tog_mus1 = true;
        this.fun_tog_mus = this.getret2.mus_tend_palp;
        this.muscle_trend_txt = false;
      }
      if (this.getret2.mus_tend_palp_desc != null) {
        this.fun_mus_wh = this.getret2.mus_tend_palp_desc;
      }
      if (this.getret2.ra_mandi_mt_op != null) {
        this.fun_open_txt = this.getret2.ra_mandi_mt_op;
      }
      if (this.getret2.ra_mandi_mt_pro != null) {
        this.fun_prot = this.getret2.ra_mandi_mt_pro;
      }
      if (this.getret2.ra_mandi_mt_rt_exc != null) {
        this.fun_right_ex = this.getret2.ra_mandi_mt_rt_exc;
      }
      if (this.getret2.ra_mandi_mt_lt_exc != null) {
        this.fun_left_ex = this.getret2.ra_mandi_mt_lt_exc;
      }


      //physical status
      if (this.getret2.build != null) {
        this.phy_build = this.getret2.build;
      }
      if (this.getret2.height != null) {
        this.phy_ht = this.getret2.height;
      }
      if (this.getret2.weight != null) {
        this.phy_wt = this.getret2.weight;
      }
      if (this.getret2.gaint != null) {
        this.phy_gait = this.getret2.gaint;
      }
      if (this.getret2.posture != null) {
        this.phy_post = this.getret2.posture;
      }
      if (this.getret2.body_type != null) {
        this.pedbody_type = this.getret2.body_type;
      }

      if (this.getret2.health != null) {
        this.phy_pre_hel = this.getret2.health;
      }
      //gnathic
      if (this.getret2.curve_spce != null) {
        this.gnathic_curve = this.getret2.curve_spce;
      }
      if (this.getret2.jet_condition != null) {
        this.gnathic_over = this.getret2.jet_condition;
      }
      if (this.getret2.jet_val != null) {
        this.gnathic_over_val = this.getret2.jet_val;
      }
      if (this.getret2.bite_condition != null) {
        this.gnathic_over_bite = this.getret2.bite_condition;
      }
      if (this.getret2.bite_value != null) {
        this.gnathic_bite_val = this.getret2.bite_value;
      }
      //pediatrics specific
      if (this.getret2.beh_desc != null) {
        this.ped_spc_des = this.getret2.beh_desc;
      }
      if (this.getret2.beh_reason != null) {
        this.ped_spc_rea = this.getret2.beh_reason;
      }
      if (this.getret2.pro_bottle_use != null) {
        this.ped_spc_pro_bot = this.getret2.pro_bottle_use;
      }
      if (this.getret2.tooth_decay != null) {
        this.ped_spc_tooth_app = this.getret2.tooth_decay;
      }
      if (this.getret2.rel_med_history != null) {
        this.ped_spc_rel_hist = this.getret2.rel_med_history;
      }
      if (this.getret2.rate_type != null) {
        this.rating_type = this.getret2.rate_type;
      }
      if (this.getret2.beh_rate != null) {
        this.ped_rate = this.getret2.beh_rate;
      }

      //ortho evaluation
      if (this.getret2.overjet != null) {
        this.ortho_eva_overjet = this.getret2.overjet;
      }
      if (this.getret2.overbite != null) {
        this.ortho_eva_overbite = this.getret2.overbite;
      }
      if (this.getret2.crossbite != null) {
        this.ortho_eva_crossbite = this.getret2.crossbite;
      }
      if (this.getret2.openbite != null) {
        this.ortho_eva_openbite = this.getret2.openbite;
      }
      if (this.getret2.sup_num_teeth != null) {
        this.ortho_eva_sup_teetht = this.getret2.sup_num_teeth;
      }
      if (this.getret2.crowding_spacing != null) {
        this.ortho_eva_crow = this.getret2.crowding_spacing;
      }
      if (this.getret2.rotation != null) {
        this.ortho_eva_rot = this.getret2.rotation;
      }
      if (this.getret2.midline != null) {
        this.ortho_eva_mid = this.getret2.midline;
      }
      if (this.getret2.analysis_required != null && (this.getret2.analysis_required == "yes" || this.getret2.analysis_required == "Yes")) {
        this.ortho_eva_any1 = true;
        this.ortho_eva_any = "yes";


      }
      if (this.getret2.arc_len_maxi != null) {
        this.ortho_eva_arc = this.getret2.arc_len_maxi;
      }
      if (this.getret2.arc_len_mandi != null) {
        this.ortho_eva_arc_mand = this.getret2.arc_len_mandi;
      }
      if (this.getret2.arc_len_maxi != null) {
        this.ortho_eva_arc = this.getret2.arc_len_maxi;
      }
      if (this.getret2.den_pri_mol_rel_rt != null) {
        this.ortho_eva_rig = this.getret2.den_pri_mol_rel_rt;
      }
      if (this.getret2.den_pri_mol_rel_lt != null) {
        this.ortho_eva_lef = this.getret2.den_pri_mol_rel_lt;
      }
      if (this.getret2.den_pre_mol_rel_rt != null) {
        this.ortho_eva_mol_rit = this.getret2.den_pre_mol_rel_rt;
      }
      if (this.getret2.den_pre_mol_rel_lt != null) {
        this.ortho_eva_mol_left = this.getret2.den_pre_mol_rel_lt;
      }
      if (this.getret2.ectopic_eruption != null) {
        this.ortho_eva_ect_erop = this.getret2.ectopic_eruption;
      }
      //dental history



      // if (this.getret2.child_first_visit != null && (this.getret2.child_first_visit == "yes" || this.getret2.child_first_visit == "Yes")) {
      //   this.child_visit_fields = false;

      // }
      if (this.getret2.vital_pulse_rate != null) {
        this.pulse_txt = this.getret2.vital_pulse_rate;
      }
      if (this.getret2.vital_resp_rate != null) {
        this.respi_txt = this.getret2.vital_resp_rate;
      }


      //intra and extra
      if (this.getret2.unerupted_teeth != null) {
        this.unerupted_teeth = this.getret2.unerupted_teeth;
      }
      if (this.getret2.ineruption_teeth != null) {
        this.inerupted_teeth = this.getret2.ineruption_teeth;
      }
      if (this.getret2.den_facial_profile_id != null) {
        this.facial_profile = this.getret2.den_facial_profile_id;
      }
      if (this.getret2.den_head_shape_id != null) {
        this.shape_of_head = this.getret2.den_head_shape_id;
      }
      if (this.getret2.den_facial_form_id != null) {
        this.facial_form = this.getret2.den_facial_form_id;
      }

      if (this.getret2.den_face_sym_id != null) {
        this.facial_sym = this.getret2.den_face_sym_id;
        //this.facialssym_disabled=true;
      }
      if (this.getret2.den_facial_divg_id != null) {
        this.facial_divergence = this.getret2.den_facial_divg_id;
      }
      if (this.getret2.den_ment_sulcus_id != null) {
        this.mento_lab_scl = this.getret2.den_ment_sulcus_id;
      }
      if (this.getret2.den_naso_angle_id != null) {
        this.naso_lab_angle = this.getret2.den_naso_angle_id;
      }
      if (this.getret2.lip_pos_incomp != null) {
        this.in_comp = this.getret2.lip_pos_incomp;
      }
      if (this.getret2.den_chin_posi_id != null) {
        this.chin_position = this.getret2.den_chin_posi_id;
      }
      if (this.getret2.den_circum_tone_id != null) {
        this.circum_oral_mus_tone = this.getret2.den_circum_tone_id;
      }
      if (this.getret2.den_child_disease_id != null) {
        //this.child_hood_dis = this.getret2.den_child_disease_id;
      }
      if (this.getret2.den_ging_colour != null) {
        this.ging_col = this.getret2.den_ging_colour;
      }
      if (this.getret2.type != null && this.getret2.type != "") {
        // this.type_dev = this.getret2.type;
      }
      if (this.getret2.delivery_mtd != null) {
        this.gest_period = this.getret2.delivery_mtd;
      }

      if (this.getret2.endo_treat != null) {

        if (this.getret2.endo_treat != null) {
          this.togg_endo_treat1 = true;
          this.togg_endo_treat = "yes"
          //this.togg_endo_treat = this.getret2.endo_treat

        }
        else {
          this.togg_endo_treat = "no"
        }
      }
      if (this.getret2.weaned_out != null) {
        // this.weaned_out_txt = this.getret2.weaned_out;
      }
      if (this.getret2.mouth != null) {
        this.intra_mouth = this.getret2.mouth;
      }
      if (this.getret2.periodontium != null) {
        this.intra_peri = this.getret2.periodontium;
      }
      if (this.getret2.alveolar_mucosa != null) {
        this.intra_alv_mus = this.getret2.alveolar_mucosa;
      }
      if (this.getret2.labial_mucosa != null) {
        this.intra_lab_mous = this.getret2.labial_mucosa;
      }
      if (this.getret2.buccal_mucosa != null) {
        this.intra_buc_mou = this.getret2.buccal_mucosa;
      }
      if (this.getret2.palatal != null) {
        this.intra_paltal = this.getret2.palatal;
      }
      if (this.getret2.tongue != null) {
        this.intra_tong = this.getret2.tongue;
      }
      if (this.getret2.floor_mouth != null) {
        this.intra_flo_mou = this.getret2.floor_mouth;
      }
      if (this.getret2.extra_oral_con_note != null) {
        this.con_note = this.getret2.extra_oral_con_note;
      }
      // if (this.getret2.TMJ_note != null) {
      //   this.jaw_note = this.getret2.TMJ_note;
      // }
      if (this.getret2.condition_note != null) {
        this.con_note = this.getret2.condition_note;
      }
      if (this.getret2.extra_oral_jaw_note != null) {
        this.jaw_note = this.getret2.TMJ_note;
      }
      if (this.getret2.TMJ_exam != null) {
        this.tmj_exam = this.getret2.TMJ_exam;
      }
      if (this.getret2.con_desc != null) {
        this.con_note = this.getret2.con_desc;
      }

      if (this.getret2.walking != null) {
        this.walk_txt = this.getret2.walking;
      }
      if (this.getret2.running != null) {
        this.run_txt = this.getret2.running;
      }
      if (this.getret2.phonation != null) {
        this.phon_txt = this.getret2.phonation;
      }
      if (this.getret2.familial_malocclusion_his != null) {
        this.famil_mal_occ = this.getret2.familial_malocclusion_his;
      }
      if (this.getret2.parents_malocclusion_type != null) {
        this.parent_mal_hist = this.getret2.parents_malocclusion_type;
      }
      if (this.getret2.his_tonsill_adenoid != null) {
        this.his_tonsil_ande = this.getret2.his_tonsill_adenoid;
      }

      // if (this.getret2.delivery_mtd != null) {
      //   this.health_mot = this.getret2.delivery_mtd;
      // }
      if (this.getret2.lip_post_id != null) {
        this.lip_posture = this.getret2.lip_post_id;
      }

      if (this.getret2.cephalic_index != null) {
        this.ceph_ind_txt = this.getret2.cephalic_index;
      }
      if (this.getret2.facial_index != null) {
        this.facial_index_txt = this.getret2.facial_index;
      }
      if (this.getret2.lip_post_id != null) {
        this.lip_posture = this.getret2.lip_post_id;
      }
      if (this.getret2.upper_lip != null) {
        this.upp_lip_txt = this.getret2.upper_lip;
      }
      if (this.getret2.lower_lip != null) {
        this.low_lip_txt = this.getret2.lower_lip;
      }



      if (this.getret2.inter_labial_gap != null) {
        this.inter_labial_gap_txt = this.getret2.inter_labial_gap;
      }
      if (this.getret2.clinical_fma != null) {
        this.clinical_fma_txt = this.getret2.clinical_fma;
      }
      if (this.getret2.skeletal_relation != null) {
        this.skeletal_relation_txt = this.getret2.skeletal_relation;
      }

      if (this.getret2.type_nipple != null) {
        // this.type_nipple_txt = this.getret2.type_nipple;
      }
      if (this.getret2.bottle_fed_dur != null) {
        //this.bottle_fed_dur_txt = this.getret2.bottle_fed_dur;
      }
      if (this.getret2.bottle_fed_freq != null) {
        // this.bottle_fed_freq_txt = this.getret2.bottle_fed_freq;
      }
      if (this.getret2.breast_fed_dur != null) {
        //this.breast_fed_dur_txt = this.getret2.breast_fed_dur;
      }

      if (this.getret2.breast_fed_freq != null) {
        // this.breast_fed_freq_txt = this.getret2.breast_fed_freq;
      }
      if (this.getret2.perm_teeth_present != null) {
        this.perm_teeth_present_txt = this.getret2.perm_teeth_present;
      }
      if (this.getret2.dec_teeth_present != null) {
        this.dec_teeth_present_txt = this.getret2.dec_teeth_present;
      }
      if (this.getret2.key_ridge_position != null) {
        this.intra_key = this.getret2.key_ridge_position;
      }
      if (this.getret2.size_shape_form != null) {
        this.intra_super = this.getret2.size_shape_form;
      }
      if (this.getret2.texture != null) {
        this.intra_texture = this.getret2.texture;
      }


      if (this.getret2.restorable != null) {
        this.intra_rest = this.getret2.restorable;
      }
      if (this.getret2.unrestorable != null) {
        this.intra_unrest = this.getret2.unrestorable;
      }
      if (this.getret2.occ_facet_wear != null) {
        this.intra_occ = this.getret2.occ_facet_wear;
      }
      // if (this.getret2.endo_treat != null) {
      //   this.intra_texture = this.getret2.endo_treat;
      // }
      if (this.getret2.frenal_upper != null) {
        this.fren_upper = this.getret2.frenal_upper;
      }
      if (this.getret2.frenal_upper != null) {
        this.fren_lower = this.getret2.frenal_upper;
      }
      if (this.getret2.oral_hygeine_status != null) {
        this.oral_hyg_sta = this.getret2.oral_hygeine_status;
      }
      if (this.getret2.max_mouth_opening != null) {
        this.intra_max_mouth = this.getret2.max_mouth_opening;
      }
      // if (this.getret2.ext_oral_cond != null) {
      //   for (var i = 0; i < this.getret2.ext_oral_cond.length; i++) {
      //     this.send_conditions_array.push(this.getret2.ext_oral_cond[i].condition)
      //    this.temp_array1.push(this.getret2.ext_oral_cond[i].condition);
      //   }

      //   this.Extra_Condtion_Data_change(this.send_conditions_array)
      //   
      // }


      // if (this.getret2.tmj != undefined) {
      //   for (var i = 0; i < this.getret2.tmj.length; i++) {
      //     this.send_jaw_tmj_array.push(this.getret2.tmj[i].jaw_TMJ);
      //    // this.temp_jaw_array1.push(this.getret2.tmj[i].jaw_TMJ);
      //   }
      //   this.Extra_JawsTMJ_Data_change(this.send_jaw_tmj_array);
      // }


      if (this.getret2.gingival_texture != null) {
        this.intra_ging_txt = this.getret2.gingival_texture;
      }
      if (this.getret2.palatal_contour != null) {
        this.intra_pal_cont = this.getret2.palatal_contour;
      }
      if (this.getret2.cirum_oral_muscle_tone != null) {
        this.circum_oral_mus_tone = this.getret2.cirum_oral_muscle_tone;
      }
      if (this.getret2.tonsils_adenoids != null) {
        this.intra_ton_anod = this.getret2.tonsils_adenoids;
      }
      if (this.getret2.oral_mucosa != null) {
        this.intra_oral_muos = this.getret2.oral_mucosa;
      }
      if (this.getret2.tongue_size != null) {
        this.intra_size = this.getret2.tongue_size;
      }
      if (this.getret2.tongue_shape != null) {
        this.intra_shape = this.getret2.tongue_shape;
      }
      if (this.getret2.tongue_posture != null) {
        this.intra_post = this.getret2.tongue_posture;
      }
      if (this.getret2.tongue_movements != null) {
        this.intra_move = this.getret2.tongue_movements;
      }


      if (this.getret2.ortho_treat != null) {
        this.his_ortho = true;
      }
      if (this.getret2.den_lip_post_id != null) {
        this.lip_posture = this.getret2.den_lip_post_id;
      }

      if (this.getret2.supraaerupted != null) {
        this.teeth_sup = this.getret2.supraaerupted;
      }



      if (this.getret2.pharynx_tonsils != null) {
        this.phy_tons_txt = this.getret2.pharynx_tonsils;
      }
      if (this.getret2.lip != null) {
        this.lip_type = this.getret2.lip;
      }
      if (this.getret2.oralym != null) {
        this.lym_node_txt = this.getret2.oralym;
      }

      if (this.getret2.asymmetries != null) {
        this.ext_asy = this.getret2.asymmetries;
      }

      if (this.getret2.speech != null) {
        this.ext_speech = this.getret2.speech;
      }

      if (this.getret2.body_type_id != null) {
        this.body_type = this.getret2.body_type_id;
      }

      if (this.getret2.ped_body_type != null) {
        this.body_type = this.getret2.ped_body_type;
      }

      //body_type_id
      if (this.getret2.vestibule != null) {
        this.intra_vest = this.getret2.vestibule;
      }
      if (this.getret2.supragingival != null) {
        this.intra_sup_ging = this.getret2.supragingival;
      }
      if (this.getret2.subgingival != null) {
        this.intra_sub_ging = this.getret2.subgingival;
      }
      if (this.getret2.wast_attrition != null) {
        this.intra_att = this.getret2.wast_attrition;
      }
      if (this.getret2.wast_abration != null) {
        this.intra_abart = this.getret2.wast_abration;
      }

      if (this.getret2.abr_desc != null) {
        this.intra_discri = this.getret2.abr_desc;
      }
      if (this.getret2.wast_erosion != null) {
        this.ero_txt = this.getret2.wast_erosion;
      }

      if (this.getret2.overbite != null) {

        this.intra_over = this.getret2.overbite;

      }


      if (this.getret2.openbite != null) {
        this.intra_open = this.getret2.openbite;
      }
      if (this.getret2.crossbite != null) {
        this.intra_cross = this.getret2.crossbite;
      }
      if (this.getret2.cups != null) {
        this.intra_plung = this.getret2.cups;
      }
      if (this.getret2.tra_primary != null) {
        this.intra_pri = this.getret2.tra_primary;
      }
      if (this.getret2.tra_secondary != null) {
        this.intra_sec = this.getret2.tra_secondary;
      }
      if (this.getret2.ero_desc != null) {
        this.intra_des1 = this.getret2.ero_desc;
      }
      if (this.getret2.agesp_dis != null) {
        this.age_spec_dis = this.getret2.agesp_dis;
      }
      if (this.getret2.gend_dis != null) {
        this.gen_spec_dis = this.getret2.gend_dis;
      }
      if (this.getret2.occu_hazard != null) {
        this.occ_haz = this.getret2.occu_hazard;
      }
      if (this.getret2.mastigatory_muscle != null) {
        this.mast_mus_tri = this.getret2.mastigatory_muscle;
      }
      if (this.getret2.area_spec_dis != null) {
        this.area_spec_dis = this.getret2.area_spec_dis;
      }
      if (this.getret2.unerupted_teeth != null) {
        this.unerupted_teeth = this.getret2.unerupted_teeth;
      }
      if (this.getret2.ineruption_teeth != null) {
        this.inerupted_teeth = this.getret2.ineruption_teeth;
      }
      if (this.getret2.den_facial_profile_id != null) {
        this.facial_profile = this.getret2.den_facial_profile_id;
      }
      if (this.getret2.den_head_shape_id != null) {
        this.shape_of_head = this.getret2.den_head_shape_id;
      }
      if (this.getret2.den_facial_form_id != null) {
        this.facial_form = this.getret2.den_facial_form_id;
      }

      if (this.getret2.den_face_sym_id != null) {
        this.facial_sym = this.getret2.den_face_sym_id;
        //this.facialssym_disabled=true;
      }
      if (this.getret2.den_facial_divg_id != null) {
        this.facial_divergence = this.getret2.den_facial_divg_id;
      }
      if (this.getret2.den_ment_sulcus_id != null) {
        this.mento_lab_scl = this.getret2.den_ment_sulcus_id;
      }
      if (this.getret2.den_naso_angle_id != null) {
        this.naso_lab_angle = this.getret2.den_naso_angle_id;
      }
      if (this.getret2.lip_pos_incomp != null) {
        this.in_comp = this.getret2.lip_pos_incomp;
      }
      if (this.getret2.den_chin_posi_id != null) {
        this.chin_position = this.getret2.den_chin_posi_id;
      }
      if (this.getret2.den_circum_tone_id != null) {
        this.circum_oral_mus_tone = this.getret2.den_circum_tone_id;
      }
      if (this.getret2.den_child_disease_id != null) {
        //this.child_hood_dis = this.getret2.den_child_disease_id;
      }
      if (this.getret2.den_ging_colour != null) {
        this.ging_col = this.getret2.den_ging_colour;
      }
      if (this.getret2.type != null && this.getret2.type != "") {
        // this.type_dev = this.getret2.type;
      }
      if (this.getret2.delivery_mtd != null) {
        this.gest_period = this.getret2.delivery_mtd;
      }

      // if (this.getret2.endo_treat != null) {
      //   this.togg_endo_treat = true;
      // }
      if (this.getret2.weaned_out != null) {
        // this.weaned_out_txt = this.getret2.weaned_out;
      }
      if (this.getret2.mouth != null) {
        this.intra_mouth = this.getret2.mouth;
      }
      if (this.getret2.periodontium != null) {
        this.intra_peri = this.getret2.periodontium;
      }
      if (this.getret2.alveolar_mucosa != null) {
        this.intra_alv_mus = this.getret2.alveolar_mucosa;
      }
      if (this.getret2.labial_mucosa != null) {
        this.intra_lab_mous = this.getret2.labial_mucosa;
      }
      if (this.getret2.buccal_mucosa != null) {
        this.intra_buc_mou = this.getret2.buccal_mucosa;
      }
      if (this.getret2.palatal != null) {
        this.intra_paltal = this.getret2.palatal;
      }
      if (this.getret2.tongue != null) {
        this.intra_tong = this.getret2.tongue;
      }
      if (this.getret2.floor_mouth != null) {
        this.intra_flo_mou = this.getret2.floor_mouth;
      }
      // if (this.getret2.condition_note != null) {
      //   this.con_note = this.getret2.condition_note;
      // }
      if (this.getret2.TMJ_note != null) {
        this.jaw_note = this.getret2.TMJ_note;
      }
      if (this.getret2.TMJ_exam != null) {
        this.tmj_exam = this.getret2.TMJ_exam;
      }
      if (this.getret2.con_desc != null) {
        this.con_note = this.getret2.con_desc;
      }
      //birth
      if (this.getret2.gestation_duration != null) {
        //this.birth_dur_ges = this.getret2.gestation_duration;
      }
      if (this.getret2.mot_age_deli != null) {
        // this.birth_mother_age = this.getret2.mot_age_deli;
      }
      if (this.getret2.parity_times != null) {
        //this.birth_parity_times = this.getret2.parity_times;
      }
      if (this.getret2.maternal_insults != null) {
        //this.birth_mate_ill = this.getret2.maternal_insults;
      }
      if (this.getret2.where_born != null) {
        //this.birth_wh_born = this.getret2.where_born;
      }
      if (this.getret2.diff_delivery != null) {
        // this.birth_dif_deli = this.getret2.diff_delivery;
      }
      if (this.getret2.resuscitation != null) {
        /// this.birth_res = this.getret2.resuscitation;
      }
      if (this.getret2.int_care_req != null) {
        // this.birth_int_score = this.getret2.int_care_req;
      }
      if (this.getret2.diseases != null) {
        //  this.birth_dis = this.getret2.diseases;
      }
      if (this.getret2.apgar_score != null) {
        // this.birth_apg_score = this.getret2.apgar_score;
      }
      if (this.getret2.child_health_cond != null) {
        // this.child_wtxt = this.getret2.child_health_cond;
      }
      if (this.getret2.reason != null) {
        //this.birth_reason = this.getret2.reason;
      }
      if (this.getret2.baby_fed != null) {
        // this.birth_baby_fed = this.getret2.baby_fed;
      }

      if (this.getret2.gnathic != null) {
        for (var i = 0; i < this.getret2.gnathic.length; i++) {
          if (this.getret2.gnathic[i].position_id == "1") {
            this.gnathic_shape = this.getret2.gnathic[i].shape;
            this.gnathic_arc = this.getret2.gnathic[i].arch_symm;
            this.gnathic_arc_ali = this.getret2.gnathic[i].arch_align;
            this.gnathic_axial_incli = this.getret2.gnathic[i].axial_inclin;
            this.gnathic_other_indi = this.getret2.gnathic[i].indiv_irregular;
            this.gnathic_den_mid = this.getret2.gnathic[i].den_midline;
            this.gnathic_ske = this.getret2.gnathic[i].skeletal_midline;

          }
          else {
            this.gnathic_shape_left = this.getret2.gnathic[i].shape;
            this.gnathic_arc_left = this.getret2.gnathic[i].arch_symm;
            this.gnathic_arc_ali_left = this.getret2.gnathic[i].arch_align;
            this.gnathic_axial_incli_left = this.getret2.gnathic[i].axial_inclin;
            this.gnathic_other_indi_left = this.getret2.gnathic[i].indiv_irregular;
            this.gnathic_den_mid_left = this.getret2.gnathic[i].den_midline;
            this.gnathic_ske_left = this.getret2.gnathic[i].skeletal_midline;

          }
        }
      }
      if (this.getret2.ant_pos != null) {
        for (var i = 0; i < this.getret2.ant_pos.length; i++) {

          if (this.getret2.ant_pos[i].position_id == "5") {
            this.molar_right = this.getret2.ant_pos[i].ant_post_molar;
            this.molar_can_right = this.getret2.ant_pos[i].ant_post_canine;
          }
          else {
            this.molar_left = this.getret2.ant_pos[i].ant_post_molar;
            this.molar_can_left = this.getret2.ant_pos[i].ant_post_canine;
          }
        }
      }

      if (this.getret2.curve_spce != null) {
        this.gnathic_curve = this.getret2.curve_spce;
      }
    }
    else {
      this.loader = true;
    }
  }

  get_child_hood_dis_array() {
    var obj = masterCSData_Helper.getMasterChildhoodDisease();
    if (obj != undefined)
      this.child_hood_dis_array = obj.childhood;
  }


  option(d) {
    this.ynoption = !this.ynoption;
    if (this.ynoption == true) {
      switch (d) {
        case 1: {
          this.ynopt1 = true;
          this.bleed_togg = "yes";
          this.specific_flag = false;
          break;
        }
        case 2: {
          this.ynopt2 = true;
          this.ill_opert = "yes";
          this.illflag = false;
          break;
        }
        case 3: {
          this.ynopt3 = true; this.reg_atend = "yes";
          break;
        }
        case 4: { this.ynopt4 = true; this.pat_mottxt = "yes"; break; }
        case 5: { this.ynopt5 = true; this.togg_endo_treat = "yes"; break; }
        case 6: {
          this.ynopt6 = true; this.fun_togg_jaw = "yes";
          this.jaw_function_txt = false;
          break;
        }
        case 7: {
          this.ynopt7 = true; this.fun_ho_pain = "yes";
          this.hopainflag = false;
          break;
        }
        case 8: {
          this.ynopt8 = true; this.fun_ho_son = "yes";
          this.dur_flag = false; break;
        }

        case 9: {
          this.ynopt9 = true;
          this.fun_tj_trend = "yes";
          this.tmj_trend_txt = false; break;
        }
        case 10: {
          this.ynopt10 = true; this.fun_tog_mus = "yes";
          this.muscleflag = false; break;
        }
        case 11: { this.ynopt11 = true; this.tog_pat_att = "yes"; break; }
        case 13: { this.ynopt17 = true; this.ortho_eva_any = "yes"; break; }
        // case 12:{this.ynopt12=true;this.child_wtxt="yes";break;}
      }
    }
    if (this.ynoption == false) {
      switch (d) {
        case 1: {
          this.ynopt1 = false;
          this.bleed_togg = "no";
          this.specific_flag = true;
          break;
        }
        case 2: {
          this.ynopt2 = false;
          this.illflag = true;
          break;
        }
        case 3: { this.ynopt3 = false; this.reg_atend = "no"; break; }
        case 4: { this.ynopt4 = false; this.pat_mottxt = "no"; break; }
        case 5: { this.ynopt5 = false; this.togg_endo_treat = "no"; break; }
        case 6: {
          this.ynopt6 = false; this.fun_togg_jaw = "no";
          this.jaw_function_txt = true; break;
        }
        case 7: {
          this.ynopt7 = false; this.fun_ho_pain = "no";
          this.hopainflag = true;
          break;
        }
        case 8: {
          this.ynopt8 = false; this.fun_ho_son = "no";
          this.dur_flag = true; break;
        }
        case 9: {
          this.ynopt9 = false; this.fun_tj_trend = "no";
          this.tmj_trend_txt = true; break;
        }
        case 10: {
          this.ynopt10 = false; this.fun_tog_mus = "no";
          this.muscleflag = true; break;
        }
        case 11: { this.ynopt11 = false; this.tog_pat_att = "no"; break; }
        case 17: { this.ynopt17 = false; this.ortho_eva_any = "no"; break; }

      }

    }


  }

  tgridsize: number = 30;
  updateSetting(event) {
    this.gridsize = event.value;
  }

  public specalitymnin: boolean = true
  widthchange(e) {
    var flag2 = "minimize";
    if (flag2 == e) {
      this.specalitymnin = true;
    }
    else if (flag2 != e) {
      this.specalitymnin = false;
    }
  }
  public fun_togg_jaw1;
  public fun_ho_pain1;
  public fun_ho_son1;
  public fun_tj_trend1;
  changejawvalue(e) {
    this.fun_togg_jaw1 = e;
    if (e == false) {
      document.getElementById("fun_togg_jaw1").style.background = "red";
      this.fun_togg_jaw = "no";
    }
    else {
      document.getElementById("fun_togg_jaw1").style.background = "green";
      this.fun_togg_jaw = "yes"
    }
  }

  ho_pain_function(e) {
    this.fun_ho_pain1 = e;
    if (e == false) {
      this.hopainflag = true;

      document.getElementById("fun_ho_pain1").style.background = "red";
      this.fun_ho_pain = "no";

    }
    else {
      this.hopainflag = false;

      document.getElementById("fun_ho_pain1").style.background = "green";
      this.fun_ho_pain = "yes";

    }


  }

  //H/O sound
  ho_sound_function(e) {
    this.fun_ho_son1 = e;
    if (e == false) {
      this.dur_flag = true;

      document.getElementById("fun_ho_son1").style.background = "red";
      this.fun_ho_son = "no";
      // this.ho_sound_text = false;
    }
    else {
      this.dur_flag = false;
      document.getElementById("fun_ho_son1").style.background = "green";
      this.fun_ho_son = "yes";

      // this.ho_sound_text = true;
    }

  }


  //tmj trend
  tmj_trend_function(e) {

    this.fun_tj_trend1 = e;
    if (e == false) {

      this.tmj_trend_txt = true;
      document.getElementById("fun_tj_trend1").style.background = "red";
      this.fun_tj_trend = "no";

    }
    else {
      this.tmj_trend_txt = false;
      document.getElementById("fun_tj_trend1").style.background = "green";
      this.fun_tj_trend = "yes";

    }

  }
  //muscle



  changepatientmotivation(e) {
    this.pat_mottxt1 = e;
    if (e == false) {
      // this.muscle_trend_txt=true;
      this.pat_mottxt = false;
      document.getElementById("pat_mottxt1").style.background = "red";

      // this.ho_sound_text = false;
    }
    else {
      this.pat_mottxt = true
      // this.muscle_trend_txt=false;
      document.getElementById("pat_mottxt1").style.background = "green";


      // this.ho_sound_text = true;
    }

  }
  changepatientreg(e) {
    this.reg_atend1 = e;
    if (e == false) {
      // this.muscle_trend_txt=true;
      this.reg_atend = false;
      document.getElementById("reg_atend1").style.background = "red";

      // this.ho_sound_text = false;
    }
    else {
      // this.muscle_trend_txt=false;
      this.reg_atend = true;

      document.getElementById("reg_atend1").style.background = "green";

      // this.ho_sound_text = true;
    }

  }
  changemuscle(e) {
    this.fun_tog_mus1 = e;
    if (e == false) {
      this.muscle_trend_txt = true;

      document.getElementById("fun_tog_mus1").style.background = "red";
      this.fun_tog_mus = "no";
      // this.ho_sound_text = false;
    }
    else {
      this.muscle_trend_txt = false;
      document.getElementById("fun_tog_mus1").style.background = "green";
      this.fun_tog_mus = "yes";

      // this.ho_sound_text = true;
    }

  }
  chnagebleedtogg(e) {


    this.bleed_togg1 = e;
    if (e == false) {
      this.bleed_txt = true;
      this.bleed_togg = false;
      document.getElementById("bleed_togg1").style.background = "red";

      // this.ho_sound_text = false;
    }
    else {
      this.bleed_txt = false;
      this.bleed_togg = true;
      document.getElementById("bleed_togg1").style.background = "green";



      // this.ho_sound_text = true;
    }
  }

  changeilloper(e) {

    this.ill_opert1 = e;
    if (e == false) {
      this.ill_operation_txt = true;
      this.ill_opert = false;
      document.getElementById("ill_opert1").style.background = "red";

      // this.ho_sound_text = false;
    }
    else {
      this.ill_operation_txt = false;
      this.ill_opert = true;
      document.getElementById("ill_opert1").style.background = "green";


      // this.ho_sound_text = true;
    }

  }
  public togg_endo_treat1;
  public tog_pat_att1;
  changeendotreat(e) {

    this.togg_endo_treat1 = e;
    if (e == false) {
      //this.ill_operation_txt=true;
      this.togg_endo_treat = "no";
      document.getElementById("togg_endo_treat1").style.background = "red";

      // this.ho_sound_text = false;
    }
    else {
      // this.ill_operation_txt=false;
      this.togg_endo_treat = "yes";
      document.getElementById("togg_endo_treat1").style.background = "green";


      // this.ho_sound_text = true;
    }

  }

  changepatientatt(e) {

    this.tog_pat_att1 = e;
    if (e == false) {
      //this.ill_operation_txt=true;
      this.tog_pat_att = "no";
      document.getElementById("tog_pat_att1").style.background = "red";

      // this.ho_sound_text = false;
    }
    else {
      // this.ill_operation_txt=false;
      this.tog_pat_att = "yes";
      document.getElementById("tog_pat_att1").style.background = "green";


      // this.ho_sound_text = true;
    }

  }
  changeanalysis(e) {

    this.ortho_eva_any1 = e;
    if (e == false) {
      //this.ill_operation_txt=true;
      this.ortho_eva_any = "no";
      document.getElementById("ortho_eva_any1").style.background = "red";

      // this.ho_sound_text = false;
    }
    else {
      // this.ill_operation_txt=false;
      this.ortho_eva_any = "yes";
      document.getElementById("ortho_eva_any1").style.background = "green";


      // this.ho_sound_text = true;
    }

  }
}
