<div class="row" style="margin:0 auto; width:100%">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Diet Plan </h5>
                    </div>
                    <div class="headerButtons">
                        <div style="width: 385px;">
                            <img src="../../../assets/ui_icons/diet_plan/Generate_icon.svg" class="" style="position: relative;width: 85px;" (click)="generatePlan()"/>
                            <!-- <img class="saveimgbtn_inpatinfo" (click)="save()"  src="../../../assets/ui_icons/buttons/save_button.svg" /> -->
                        </div>
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-2 col-xxl-2">
                        <mat-label class="matlabel">Region<br>
                            <select class="ipcss widthappt" (ngModelChange)="changeRegion($event)" [(ngModel)]="region">
                                <option *ngFor="let region of regionArray" [value]="region">{{region}} </option>
                            </select>
                        </mat-label>
                    </div>
                    <div class="col-12 col-sm-4 col-md-3 col-xl-2 col-lg-2">
                        <mat-label class="matlabel" style="font-size: 15px; font-weight: 600;">Range</mat-label><br>
                        <select disableOptionCentering class="ipcss" (change)="changeAppointmentType()"
                            [(ngModel)]="appointmentType">
                            <option value="Today">Today</option>
                            <option value="Weekly">Weekly</option>
                            <option value="Fort Night">Fort Night</option>
                            <option value="Monthly">Monthly</option>
                            <option value="3 Months">3 Months</option>
                            <option value="6 Months">6 Months</option>
                            <option value="Yearly">Yearly</option>
                            <option value="Date">Date</option>
                        </select>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2 select_bottom" *ngIf="dateFlag">
                        <mat-label class="matlabel">From<br>
                            <input type="date" class="ipcss widthbilllist datecss" (change)="selectFromDate(fromDate)"
                                [(ngModel)]="fromDate" #matInput max="{{currentMaxDate}}">
                        </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2" *ngIf="dateFlag">
                        <mat-label class="matlabel">To<br>
                            <input type="date" class="ipcss widthbilllist datecss" id="appt_date"
                                (change)="selectToDate(toDate)" [(ngModel)]="toDate" max="{{currentMaxDate}}" matInput>
                        </mat-label>
                    </div>

                </div>
                <div class="billAreaCover">
                    <div class="contanier-flud p-0">
                        <div class="row cardCover">
                            <div style="display: flex;">
                                <div style=" width:14.25%" *ngFor="let session of sessionArray;let i = index" [ngStyle]="i == 0 ? {'border-left': '2px solid #54b1e7'} : {'border-left' : 'none'}"> 
                                    <button [ngStyle]="session.selected == true ? {'background': '#81caf4', 'color' : '#fff'} : {'background' : '#fff', 'color' : '#000'}" (click)="selectedSession(session)" class="buttonFile" type="button" role="tab" >{{session.session}} </button>
                                </div>
                            </div>
                            <!-- <div class="col-2 "
                                style="text-align:center; padding-right: 0; margin: 13px 0;border-right: 1px solid #81caf4;">
                                <div class="dateWarpper">
                                    <div>
                                        <div *ngFor="let session of sessionArray;let i = index" class="dateCover">
                                            <div (click)="selectedSession(session)"
                                                [ngStyle]="session.selected == true ? {'width': '100%' } : {'width' : '90%'}">
                                                <div class="dateLable"
                                                    [ngStyle]="session.selected == true ? {'border': '1px solid #81caf4', 'border-right' : 'none'} : {}">
                                                    {{session.session}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <div class="col-12"
                                style="margin: 12px 0 14px 0px; border-top: 1px solid #81caf4; border-bottom: 1px solid #81caf4;">
                                <div class="dateWarpper">
                                    <div class="row" style="margin: 7px 0px;">
                                        <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3"
                                            *ngFor="let foodList of foodArray;let i = index"
                                            style="margin-top: 7px; display: flex;">
                                            <div class="foodListCover" (click)="checkedFood(foodList,'remove',session)" style="background: #d6f0ff;" *ngIf="foodList.checked == true" >
                                                <div class="foodLable"> {{ foodList.food_item }}</div>
                                                <span style="">
                                                    <img *ngIf="foodList.checked == true" src="../../../assets/ui_icons/popup_tick_mark.svg"
                                                        style="cursor: pointer;margin: 0px 17%;" width="15px"
                                                         />
                                                    <!-- <img *ngIf="foodList.checked == false"
                                                        src="../../../assets/ui_icons/yellow_box.svg"
                                                        style="cursor: pointer;margin: 0px 17%;" width="15px"
                                                        (click)="checkedFood(foodList,'add',session)" /> -->
                                                </span>
                                            </div>
                                            <div class="foodListCover" (click)="checkedFood(foodList,'add',session)" style="background: #fff;" *ngIf="foodList.checked == false" >
                                                <div class="foodLable"> {{ foodList.food_item }}</div>
                                                <span style="">
                                                    <!-- <img *ngIf="foodList.checked == true"
                                                        src="../../../assets/ui_icons/popup_tick_mark.svg"
                                                        style="cursor: pointer;margin: 0px 17%;" width="15px"
                                                        (click)="checkedFood(foodList,'remove',session)" /> -->
                                                    <img *ngIf="foodList.checked == false" src="../../../assets/ui_icons/yellow_box.svg"
                                                        style="cursor: pointer;margin: 0px 17%;" width="15px"
                                                         />
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>