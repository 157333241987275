import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogModule} from '@angular/material/dialog';

@Component({
  selector: 'app-pres-dialog',
  templateUrl: './pres-dialog.component.html',
  styleUrls: ['./pres-dialog.component.scss']
})
export class PresDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<PresDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }

}
