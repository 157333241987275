<div class="row" style="margin:0 auto; width:100%">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">{{pageHeading}}</h5>
                    </div>
                    <div class="headerButtons">
                        <div style="width: 385px;">
                            <img class="saveimgbtn_inpatinfo" (click)="trackerHistory()"
                                src="../../../assets/ui_icons/buttons/wrong.svg" />
                            <img class="saveimgbtn_inpatinfo" (click)="dietPlanHistory()"
                                src="../../../assets/ui_icons/buttons/history.svg" />
                            <img *ngIf="saveFlag" class="saveimgbtn_inpatinfo" (click)="save()"
                                src="../../../assets/ui_icons/buttons/save_button.svg" />
                            <img src="../../../assets/ui_icons/diet_plan/diet_track_icon.svg" *ngIf="dietFlow != 'dietTracker'" class=""
                                style="position: relative;top: 8px;margin: 0 2%;" width="22px" height="22px" (click)="dietMenu()"/>
                        </div>
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>
                <div class="row">
                    <div class="col-12" id="diet">
                        <owl-carousel-o [options]="customOptions">
                            <ng-template carouselSlide *ngFor="let relation of relationlistData;">
                                <div class="slide">
                                    <div class="cu_slider_cover">
                                        <div class="card_border1"
                                            (click)="selectRelation(relation.rel_name,relation.rel_id,relation.sub_rel_id,relation.life_style_id)">
                                            <div class="d-flex justify-content-center">
                                                <img class="img-fluid" class="image" src="{{relation.prof_img}}"
                                                    alt="Card image cap">
                                            </div>
                                            <p class="carousel_label">{{relation.rel_name}}</p>
                                        </div>
                                        <a tabindex="0">
                                            <div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </ng-template>
                        </owl-carousel-o>
                    </div>
                </div>
                <div class="dite_content_cover">
                    <div class="row" *ngIf="dietFlow != 'dietTracker'">
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4" style="margin: 5px 0;">
                            <div class="diet_lable_cover">
                                <mat-label class="dite_input">
                                    Caloried required
                                    <span style="float: right;padding: 0 10px 0 0;">{{calories}}</span>
                                </mat-label>
                                <div class="progress progress-xxs">
                                    <div class="progress-bar progress-bar-success" role="progressbar" aria-valuenow="77"
                                        aria-valuemin="0" aria-valuemax="100"
                                        [ngStyle]="{'border-bottom':'1px solid green','width':''}"></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4" style="margin: 5px 0;">
                            <div class="diet_lable_cover">
                                <mat-label class="dite_input">
                                    Total calories(Kcal)
                                    <span style="float: right;padding: 0 10px 0 0;">{{totalCalories}}</span>
                                </mat-label>
                                <div id="progress"></div>
                            </div>
                        </div>
                        <div class="col-9 col-sm-11 col-md-5 col-lg-3 col-xl-3" style="margin: 5px 0;">
                            <div class="diet_lable_cover">
                                <mat-label class="dite_input" id="sga">
                                    SGA score
                                    <span style="float: right;padding: 0 10px 0 0;">{{sgaOverallScore}}</span>
                                </mat-label>
                                <div class="progress progress-xxs">
                                    <div class="progress-bar progress-bar-info" role="progressbar" aria-valuenow="23"
                                        aria-valuemin="0" aria-valuemax="100" [ngStyle]="{'width':''}"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="dietFlow == 'dietTracker'" style="margin: 9px 0px;font-weight: 500;">
                        <span style="margin: 3px 8px 0 0;">
                            <img *ngIf="checkedFlag == false" (click)="checkedSuggested('checked')"
                                src="../../../assets/ui_icons/yellow_box.svg" style="cursor: pointer;" width="15px" />
                            <img *ngIf="checkedFlag == true" (click)="checkedSuggested('unchecked')"
                                src="../../../assets/ui_icons/popup_tick_mark.svg" style="cursor: pointer;"
                                width="15px" />
                        </span>
                        Only suggested diet
                    </div>
                    <!--  [disabled]="person.isDisable" -->
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <ng-container *ngFor="let person of displayArray; let i = index">
                            <li class="nav-item" role="presentation" *ngIf="person.weekNo == week" >
                                <button class="nav-link" id="{{person.date}}-tab" data-bs-toggle="tab" attr.data-bs-target="#{{person.date}}" type="button" role="tab" attr.aria-controls="{{person.date}}" aria-selected="true" (click)="dayClick(person.day,person.date,true)">{{person.date | date:'dd/MM/yyyy'}} <br> ({{person.dayLable}}) </button>
                            </li>
                        </ng-container>
                    </ul>
                    <div class="row" *ngIf="dietFlow != 'dietTracker'">
                        <div class="col-4">
                            <div class="switch-field" *ngIf="ynopt1 != undefined">
                                <input type="radio" id="radio-three" (click)="changeType(1,'yes')" />
                                <label for="radio-three" [ngClass]="ynopt1 ? 'radio_active':'radio_inactive'">
                                    <div class="tab_cover">
                                        <div class="tab_icon_cover"><img src="../../../assets/ui_icons/Self.svg"
                                                class="iocinStyle" alt=""></div>
                                        <div class="lableStyle">Self</div>
                                    </div>
                                </label>
                                <input type="radio" id="radio-four" (click)="changeType(1,'no')" />
                                <label for="radio-four" [ngClass]="!ynopt1 ? 'radio_active':'radio_inactive'">
                                    <div class="tab_cover">
                                        <div class="tab_icon_cover"><img src="../../../assets/ui_icons/others.svg"
                                                class="iocinStyle" alt=""></div>
                                        <div class="lableStyle">Suggested</div>
                                    </div>
                                </label>
                                <img *ngIf="pdfFlag" (click)="getPdf()"
                                    src="../../../assets/ui_icons/diet_plan/pdf_viewer.svg" alt="" height="20px"
                                    style="margin: 4px 10px;">
                            </div>
                        </div>
                        <div class="col-12 col-sm-4 col-md-3 col-xl-2 col-lg-2">
                            <mat-label class="matlabel" style="font-size: 15px; font-weight: 600;">Range</mat-label><br>
                            <select disableOptionCentering class="ipcss" (change)="changeAppointmentType()"
                                [(ngModel)]="appointmentType">
                                <option value="Today">Today</option>
                                <option value="Weekly">Weekly</option>
                                <option value="Fort Night">Fort Night</option>
                                <option value="Monthly">Monthly</option>
                                <option value="3 Months">3 Months</option>
                                <option value="6 Months">6 Months</option>
                                <option value="Yearly">Yearly</option>
                                <option value="Date">Date</option>
                            </select>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2 select_bottom" *ngIf="dateFlag">
                            <mat-label class="matlabel">From<br>
                                <input type="date" class="ipcss widthbilllist datecss" (change)="selectFromDate(fromDate)"
                                    [(ngModel)]="fromDate" #matInput max="{{currentDate}}">
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2" *ngIf="dateFlag">
                            <mat-label class="matlabel">To<br>
                                <input type="date" class="ipcss widthbilllist datecss" id="appt_date"
                                    (change)="selectToDate(toDate)" [(ngModel)]="toDate" max="{{currentDate}}" matInput>
                            </mat-label>
                        </div>
                    </div>
                    <div class="tab-content" id="myTabContent">
                        <img *ngIf="previousFlag && displayArray.length != 0" src="../../../assets/ui_icons/diet_plan/Previous_icon.svg"
                            (click)="copyDietPlan('previous')" style="left: 8px;z-index: 2;" class="navIcon" alt="">
                        <img *ngIf="nextFlag && displayArray.length != 0" src="../../../assets/ui_icons/diet_plan/Next_icon.svg"
                            style="right: 8px;" class="navIcon" alt="" (click)="copyDietPlan('next')">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngIf="displayArray.length == 0 "> <div class="nodata">No diet found</div>  </div>
                        <div class="tab-pane fade show" [ngClass]="i == 0 ? 'active' : ''"
                            *ngFor="let person1 of displayArray; let i = index" id="{{person1.date}}" role="tabpanel"
                            attr.aria-labelledby="{{person1.date}}-tab"
                            style="height: 560px; overflow-y: auto;  overflow-x: hidden;">
                            <div class="row" *ngFor="let session of person1.session;"
                                [style]="dietFlow == 'dietTracker' ? 'margin-top: 25px;' : 'margin-bottom: 25px;'">
                                <div class="col-8 col-sm-7 col-md-12 col-lg-5 col-xl-4">
                                    <div class="card cardCover">
                                        <div class="card-header cardHeader">
                                            {{session.lableName}}
                                            <span style="float: right;">
                                                {{session.energy}} Kcal
                                            </span>
                                        </div>
                                        <div class="card-body">
                                            <div *ngIf="session.type == 'Morning'">
                                               
                                                    <!-- <div style="display: inline-block;width: fit-content; "
                                                        *ngIf="dietFlow == 'dietTracker'">
                                                        <img *ngIf="session.docChecked == true"
                                                            (click)="getArrayList('Doctor',session,person1.day,'remove')"
                                                            src="../../../assets/ui_icons/diet_plan/diet_doctor_selected.svg"
                                                            height="20px" alt="" class="listImg">
                                                        <img *ngIf="session.docChecked == false"
                                                            (click)="getArrayList('Doctor',session,person1.day,'add')"
                                                            src="../../../assets/ui_icons/diet_plan/diet_doctor_unselected.svg"
                                                            height="20px" alt="" class="listImg">
                                                    </div>
                                                    <div style="display: inline-block;width: fit-content;"
                                                        *ngIf="dietFlow == 'dietTracker'">
                                                        <img *ngIf="session.patintChecked == true"
                                                            (click)="getArrayList('patient',session,person1.day,'remove')"
                                                            src="../../../assets/ui_icons/diet_plan/diet_self_selected.svg"
                                                            height="20px" alt="" class="listImg">
                                                        <img *ngIf="session.patintChecked == false"
                                                            (click)="getArrayList('patient',session,person1.day,'add')"
                                                            src="../../../assets/ui_icons/diet_plan/diet_self_unselected.svg"
                                                            height="20px" alt="" class="listImg">
                                                    </div> -->
                                                    <div class="row" *ngIf="dietFlow == 'dietTracker'">
                                                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6" style="text-align: center;">
                                                            <mat-label class="matlabel"> Suggested  </mat-label>
                                                            <img *ngIf="session.docChecked == true"
                                                            (click)="getArrayList('Doctor',session,person1.day,person1.date,'remove')"
                                                            src="../../../assets/ui_icons/tick_mark.svg"
                                                            height="20px" alt="" class="listImg">
                                                            <img *ngIf="session.docChecked == false"
                                                            (click)="getArrayList('Doctor',session,person1.day,person1.date,'add')"
                                                            src="../../../assets/ui_icons/cross_mark.svg"
                                                            height="20px" alt="" class="listImg">
                                                        </div>
                                                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6" style="text-align: center;">
                                                            <mat-label class="matlabel"> Self  </mat-label>
                                                            <img *ngIf="session.patintChecked == true"
                                                            (click)="getArrayList('patient',session,person1.day,person1.date,'remove')"
                                                            src="../../../assets/ui_icons/tick_mark.svg"
                                                            height="20px" alt="" class="listImg">
                                                            <img *ngIf="session.patintChecked == false"
                                                            (click)="getArrayList('patient',session,person1.day,person1.date,'add')"
                                                            src="../../../assets/ui_icons/cross_mark.svg"
                                                            height="20px" alt="" class="listImg">
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <mat-label class="matlabel">Add food item   </mat-label><br>
                                                        <input type="text" class="ipcss mainDishInput"
                                                            [(ngModel)]="morningFoods" name="morningFoods"
                                                            (keyup)="foodType(morningFoods, session.type, session)"
                                                            type="text" aria-label="Number" matInput
                                                            [matAutocomplete]="autoMorning" />
                                                        <div style="display: inline-block;">
                                                            <img *ngIf="session.mainDish == true"
                                                                src="../../../assets/ui_icons/main_course_green_icon.png"
                                                                height="20px" alt="" class="mainDishImg">
                                                            <img *ngIf="session.mainDish == false"
                                                                (click)="mainDish(session,person1.day,person1.date)"
                                                                src="../../../assets/ui_icons/main_course_blue_icon.png"
                                                                height="20px" alt="" class="mainDishImg">
                                                        </div>
                                                        <mat-autocomplete #autoMorning="matAutocomplete">
                                                            <mat-option id="optionfont"
                                                                (click)="selctedFood(person1.day,person1.date,food, 1)"
                                                                *ngFor="let food of morningFoodItem"
                                                                [value]="food.description">
                                                                {{food.displayContent}}
                                                            </mat-option>
                                                        </mat-autocomplete>
                                                    </div>
                                             
                                            </div>
                                            <div *ngIf="session.type == 'Breakfast'">
                                                <div class="row" *ngIf="dietFlow == 'dietTracker'">
                                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6" style="text-align: center;">
                                                        <mat-label class="matlabel"> Suggested  </mat-label>
                                                        <img *ngIf="session.docChecked == true"
                                                        (click)="getArrayList('Doctor',session,person1.day,person1.date,'remove')"
                                                        src="../../../assets/ui_icons/tick_mark.svg"
                                                        height="20px" alt="" class="listImg">
                                                        <img *ngIf="session.docChecked == false"
                                                        (click)="getArrayList('Doctor',session,person1.day,person1.date,'add')"
                                                        src="../../../assets/ui_icons/cross_mark.svg"
                                                        height="20px" alt="" class="listImg">
                                                    </div>
                                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6" style="text-align: center;">
                                                        <mat-label class="matlabel"> Self  </mat-label>
                                                        <img *ngIf="session.patintChecked == true"
                                                        (click)="getArrayList('patient',session,person1.day,person1.date,'remove')"
                                                        src="../../../assets/ui_icons/tick_mark.svg"
                                                        height="20px" alt="" class="listImg">
                                                        <img *ngIf="session.patintChecked == false"
                                                        (click)="getArrayList('patient',session,person1.day,person1.date,'add')"
                                                        src="../../../assets/ui_icons/cross_mark.svg"
                                                        height="20px" alt="" class="listImg">
                                                    </div>
                                                </div>
                                                <!-- <div style="display: inline-block;width: fit-content; "
                                                    *ngIf="dietFlow == 'dietTracker'">
                                                    <img *ngIf="session.docChecked == true"
                                                        (click)="getArrayList('Doctor',session,person1.day,'remove')"
                                                        src="../../../assets/ui_icons/diet_plan/diet_doctor_selected.svg"
                                                        height="20px" alt="" class="listImg">
                                                    <img *ngIf="session.docChecked == false"
                                                        (click)="getArrayList('Doctor',session,person1.day,'add')"
                                                        src="../../../assets/ui_icons/diet_plan/diet_doctor_unselected.svg"
                                                        height="20px" alt="" class="listImg">
                                                </div>
                                                <div style="display: inline-block;width: fit-content;"
                                                    *ngIf="dietFlow == 'dietTracker'">
                                                    <img *ngIf="session.patintChecked == true"
                                                        (click)="getArrayList('patient',session,person1.day,'remove')"
                                                        src="../../../assets/ui_icons/diet_plan/diet_self_selected.svg"
                                                        height="20px" alt="" class="listImg">
                                                    <img *ngIf="session.patintChecked == false"
                                                        (click)="getArrayList('patient',session,person1.day,'add')"
                                                        src="../../../assets/ui_icons/diet_plan/diet_self_unselected.svg"
                                                        height="20px" alt="" class="listImg">
                                                </div> -->
                                                <mat-label class="matlabel">Add food item</mat-label>
                                                
                                                <br>
                                                <input type="text" class="ipcss mainDishInput"
                                                    [(ngModel)]="BreakfastFoods" name="BreakfastFoods"
                                                    (keyup)="foodType(BreakfastFoods, session.type,session)" type="text"
                                                    aria-label="Number" matInput [matAutocomplete]="autoBreakfast" />
                                                <div style="display: inline-block;">
                                                    <img *ngIf="session.mainDish == true"
                                                        src="../../../assets/ui_icons/main_course_green_icon.png"
                                                        height="20px" alt="" class="mainDishImg">
                                                    <img *ngIf="session.mainDish == false"
                                                        (click)="mainDish(session,person1.day,person1.date)"
                                                        src="../../../assets/ui_icons/main_course_blue_icon.png"
                                                        height="20px" alt="" class="mainDishImg">
                                                </div>
                                                <mat-autocomplete #autoBreakfast="matAutocomplete">
                                                    <mat-option id="optionfont"
                                                        (click)="selctedFood(person1.day,person1.date,food, 1)"
                                                        *ngFor="let food of breadfastFoodItem"
                                                        [value]="food.description">
                                                        {{food.displayContent}}
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </div>
                                            <div *ngIf="session.type == 'Mid-day'">
                                                <div class="row" *ngIf="dietFlow == 'dietTracker'">
                                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6" style="text-align: center;">
                                                        <mat-label class="matlabel"> Suggested  </mat-label>
                                                        <img *ngIf="session.docChecked == true"
                                                        (click)="getArrayList('Doctor',session,person1.day,person1.date,'remove')"
                                                        src="../../../assets/ui_icons/tick_mark.svg"
                                                        height="20px" alt="" class="listImg">
                                                        <img *ngIf="session.docChecked == false"
                                                        (click)="getArrayList('Doctor',session,person1.day,person1.date,'add')"
                                                        src="../../../assets/ui_icons/cross_mark.svg"
                                                        height="20px" alt="" class="listImg">
                                                    </div>
                                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6" style="text-align: center;">
                                                        <mat-label class="matlabel"> Self  </mat-label>
                                                        <img *ngIf="session.patintChecked == true"
                                                        (click)="getArrayList('patient',session,person1.day,person1.date,'remove')"
                                                        src="../../../assets/ui_icons/tick_mark.svg"
                                                        height="20px" alt="" class="listImg">
                                                        <img *ngIf="session.patintChecked == false"
                                                        (click)="getArrayList('patient',session,person1.day,person1.date,'add')"
                                                        src="../../../assets/ui_icons/cross_mark.svg"
                                                        height="20px" alt="" class="listImg">
                                                    </div>
                                                </div>
                                                <mat-label class="matlabel">Add food item</mat-label>
                                                <!-- <div style="display: inline-block;width: fit-content; "
                                                    *ngIf="dietFlow == 'dietTracker'">
                                                    <img *ngIf="session.docChecked == true"
                                                        (click)="getArrayList('Doctor',session,person1.day,'remove')"
                                                        src="../../../assets/ui_icons/diet_plan/diet_doctor_selected.svg"
                                                        height="20px" alt="" class="listImg">
                                                    <img *ngIf="session.docChecked == false"
                                                        (click)="getArrayList('Doctor',session,person1.day,'add')"
                                                        src="../../../assets/ui_icons/diet_plan/diet_doctor_unselected.svg"
                                                        height="20px" alt="" class="listImg">
                                                </div>
                                                <div style="display: inline-block;width: fit-content;"
                                                    *ngIf="dietFlow == 'dietTracker'">
                                                    <img *ngIf="session.patintChecked == true"
                                                        (click)="getArrayList('patient',session,person1.day,'remove')"
                                                        src="../../../assets/ui_icons/diet_plan/diet_self_selected.svg"
                                                        height="20px" alt="" class="listImg">
                                                    <img *ngIf="session.patintChecked == false"
                                                        (click)="getArrayList('patient',session,person1.day,'add')"
                                                        src="../../../assets/ui_icons/diet_plan/diet_self_unselected.svg"
                                                        height="20px" alt="" class="listImg">
                                                </div> -->
                                                <br>
                                                <input type="text" class="ipcss mainDishInput" [(ngModel)]="MidDayFoods"
                                                    name="MidDayFoods"
                                                    (keyup)="foodType(MidDayFoods, session.type, session)" type="text"
                                                    aria-label="Number" matInput [matAutocomplete]="autoMidDay" />
                                                <div style="display: inline-block;">
                                                    <img *ngIf="session.mainDish == true"
                                                        src="../../../assets/ui_icons/main_course_green_icon.png"
                                                        height="20px" alt="" class="mainDishImg">
                                                    <img *ngIf="session.mainDish == false"
                                                        (click)="mainDish(session,person1.day,person1.date)"
                                                        src="../../../assets/ui_icons/main_course_blue_icon.png"
                                                        height="20px" alt="" class="mainDishImg">
                                                </div>
                                                <mat-autocomplete #autoMidDay="matAutocomplete">
                                                    <mat-option id="optionfont"
                                                        (click)="selctedFood(person1.day,person1.date,food, 1)"
                                                        *ngFor="let food of midDayFoodItem" [value]="food.description">
                                                        {{food.displayContent}}
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </div>
                                            <div *ngIf="session.type == 'Lunch'">
                                                <div class="row" *ngIf="dietFlow == 'dietTracker'">
                                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6" style="text-align: center;">
                                                        <mat-label class="matlabel"> Suggested  </mat-label>
                                                        <img *ngIf="session.docChecked == true"
                                                        (click)="getArrayList('Doctor',session,person1.day,person1.date,'remove')"
                                                        src="../../../assets/ui_icons/tick_mark.svg"
                                                        height="20px" alt="" class="listImg">
                                                        <img *ngIf="session.docChecked == false"
                                                        (click)="getArrayList('Doctor',session,person1.day,person1.date,'add')"
                                                        src="../../../assets/ui_icons/cross_mark.svg"
                                                        height="20px" alt="" class="listImg">
                                                    </div>
                                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6" style="text-align: center;">
                                                        <mat-label class="matlabel"> Self  </mat-label>
                                                        <img *ngIf="session.patintChecked == true"
                                                        (click)="getArrayList('patient',session,person1.day,person1.date,'remove')"
                                                        src="../../../assets/ui_icons/tick_mark.svg"
                                                        height="20px" alt="" class="listImg">
                                                        <img *ngIf="session.patintChecked == false"
                                                        (click)="getArrayList('patient',session,person1.day,person1.date,'add')"
                                                        src="../../../assets/ui_icons/cross_mark.svg"
                                                        height="20px" alt="" class="listImg">
                                                    </div>
                                                </div>
                                                <mat-label class="matlabel">Add food item</mat-label>
                                                <!-- <div style="display: inline-block;width: fit-content; "
                                                    *ngIf="dietFlow == 'dietTracker'">
                                                    <img *ngIf="session.docChecked == true"
                                                        (click)="getArrayList('Doctor',session,person1.day,'remove')"
                                                        src="../../../assets/ui_icons/diet_plan/diet_doctor_selected.svg"
                                                        height="20px" alt="" class="listImg">
                                                    <img *ngIf="session.docChecked == false"
                                                        (click)="getArrayList('Doctor',session,person1.day,'add')"
                                                        src="../../../assets/ui_icons/diet_plan/diet_doctor_unselected.svg"
                                                        height="20px" alt="" class="listImg">
                                                </div>
                                                <div style="display: inline-block;width: fit-content;"
                                                    *ngIf="dietFlow == 'dietTracker'">
                                                    <img *ngIf="session.patintChecked == true"
                                                        (click)="getArrayList('patient',session,person1.day,'remove')"
                                                        src="../../../assets/ui_icons/diet_plan/diet_self_selected.svg"
                                                        height="20px" alt="" class="listImg">
                                                    <img *ngIf="session.patintChecked == false"
                                                        (click)="getArrayList('patient',session,person1.day,'add')"
                                                        src="../../../assets/ui_icons/diet_plan/diet_self_unselected.svg"
                                                        height="20px" alt="" class="listImg">
                                                </div> -->
                                                <br>
                                                <input type="text" class="ipcss mainDishInput" [(ngModel)]="LunchFoods"
                                                    name="LunchFoods"
                                                    (keyup)="foodType(LunchFoods, session.type, session)" type="text"
                                                    aria-label="Number" matInput [matAutocomplete]="autoLunchFoods" />
                                                <div style="display: inline-block;">
                                                    <img *ngIf="session.mainDish == true"
                                                        src="../../../assets/ui_icons/main_course_green_icon.png"
                                                        height="20px" alt="" class="mainDishImg">
                                                    <img *ngIf="session.mainDish == false"
                                                        (click)="mainDish(session,person1.day,person1.date)"
                                                        src="../../../assets/ui_icons/main_course_blue_icon.png"
                                                        height="20px" alt="" class="mainDishImg">
                                                </div>
                                                <mat-autocomplete #autoLunchFoods="matAutocomplete">
                                                    <mat-option id="optionfont"
                                                        (click)="selctedFood(person1.day,person1.date,food, 1)"
                                                        *ngFor="let food of lunchFoodItem" [value]="food.description">
                                                        {{food.displayContent}}
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </div>
                                            <div *ngIf="session.type == 'Evening'">
                                                <div class="row" *ngIf="dietFlow == 'dietTracker'">
                                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6" style="text-align: center;">
                                                        <mat-label class="matlabel"> Suggested  </mat-label>
                                                        <img *ngIf="session.docChecked == true"
                                                        (click)="getArrayList('Doctor',session,person1.day,person1.date,'remove')"
                                                        src="../../../assets/ui_icons/tick_mark.svg"
                                                        height="20px" alt="" class="listImg">
                                                        <img *ngIf="session.docChecked == false"
                                                        (click)="getArrayList('Doctor',session,person1.day,person1.date,'add')"
                                                        src="../../../assets/ui_icons/cross_mark.svg"
                                                        height="20px" alt="" class="listImg">
                                                    </div>
                                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6" style="text-align: center;">
                                                        <mat-label class="matlabel"> Self  </mat-label>
                                                        <img *ngIf="session.patintChecked == true"
                                                        (click)="getArrayList('patient',session,person1.day,person1.date,'remove')"
                                                        src="../../../assets/ui_icons/tick_mark.svg"
                                                        height="20px" alt="" class="listImg">
                                                        <img *ngIf="session.patintChecked == false"
                                                        (click)="getArrayList('patient',session,person1.day,person1.date,'add')"
                                                        src="../../../assets/ui_icons/cross_mark.svg"
                                                        height="20px" alt="" class="listImg">
                                                    </div>
                                                </div>
                                                <mat-label class="matlabel">Add food item</mat-label>
                                                <div style="display: inline-block;width: fit-content; "
                                                    *ngIf="dietFlow == 'dietTracker'">
                                                    <img *ngIf="session.docChecked == true"
                                                        (click)="getArrayList('Doctor',session,person1.day,person1.date,'remove')"
                                                        src="../../../assets/ui_icons/diet_plan/diet_doctor_selected.svg"
                                                        height="20px" alt="" class="listImg">
                                                    <img *ngIf="session.docChecked == false"
                                                        (click)="getArrayList('Doctor',session,person1.day,person1.date,'add')"
                                                        src="../../../assets/ui_icons/diet_plan/diet_doctor_unselected.svg"
                                                        height="20px" alt="" class="listImg">
                                                </div>
                                                <div style="display: inline-block;width: fit-content;"
                                                    *ngIf="dietFlow == 'dietTracker'">
                                                    <img *ngIf="session.patintChecked == true"
                                                        (click)="getArrayList('patient',session,person1.day,person1.date,'remove')"
                                                        src="../../../assets/ui_icons/diet_plan/diet_self_selected.svg"
                                                        height="20px" alt="" class="listImg">
                                                    <img *ngIf="session.patintChecked == false"
                                                        (click)="getArrayList('patient',session,person1.day,person1.date,'add')"
                                                        src="../../../assets/ui_icons/diet_plan/diet_self_unselected.svg"
                                                        height="20px" alt="" class="listImg">
                                                </div>
                                                <br>
                                                <input type="text" class="ipcss mainDishInput"
                                                    [(ngModel)]="EveningFoods" name="EveningFoods"
                                                    (keyup)="foodType(EveningFoods, session.type, session)" type="text"
                                                    aria-label="Number" matInput [matAutocomplete]="autoEveningFoods" />
                                                <div style="display: inline-block;">
                                                    <img *ngIf="session.mainDish == true"
                                                        src="../../../assets/ui_icons/main_course_green_icon.png"
                                                        height="20px" alt="" class="mainDishImg">
                                                    <img *ngIf="session.mainDish == false"
                                                        (click)="mainDish(session,person1.day,person1.date)"
                                                        src="../../../assets/ui_icons/main_course_blue_icon.png"
                                                        height="20px" alt="" class="mainDishImg">
                                                </div>
                                                <mat-autocomplete #autoEveningFoods="matAutocomplete">
                                                    <mat-option id="optionfont"
                                                        (click)="selctedFood(person1.day,person1.date,food, 1)"
                                                        *ngFor="let food of eveningFoodItem" [value]="food.description">
                                                        {{food.displayContent}}
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </div>
                                            <div *ngIf="session.type == 'Dinner'">
                                                <div class="row" *ngIf="dietFlow == 'dietTracker'">
                                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6" style="text-align: center;">
                                                        <mat-label class="matlabel"> Suggested  </mat-label>
                                                        <img *ngIf="session.docChecked == true"
                                                        (click)="getArrayList('Doctor',session,person1.day,person1.date,'remove')"
                                                        src="../../../assets/ui_icons/tick_mark.svg"
                                                        height="20px" alt="" class="listImg">
                                                        <img *ngIf="session.docChecked == false"
                                                        (click)="getArrayList('Doctor',session,person1.day,person1.date,'add')"
                                                        src="../../../assets/ui_icons/cross_mark.svg"
                                                        height="20px" alt="" class="listImg">
                                                    </div>
                                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6" style="text-align: center;">
                                                        <mat-label class="matlabel"> Self  </mat-label>
                                                        <img *ngIf="session.patintChecked == true"
                                                        (click)="getArrayList('patient',session,person1.day,person1.date,'remove')"
                                                        src="../../../assets/ui_icons/tick_mark.svg"
                                                        height="20px" alt="" class="listImg">
                                                        <img *ngIf="session.patintChecked == false"
                                                        (click)="getArrayList('patient',session,person1.day,person1.date,'add')"
                                                        src="../../../assets/ui_icons/cross_mark.svg"
                                                        height="20px" alt="" class="listImg">
                                                    </div>
                                                </div>
                                                <mat-label class="matlabel">Add food item</mat-label>
                                                <!-- <div style="display: inline-block;width: fit-content; "
                                                    *ngIf="dietFlow == 'dietTracker'">
                                                    <img *ngIf="session.docChecked == true"
                                                        (click)="getArrayList('Doctor',session,person1.day,'remove')"
                                                        src="../../../assets/ui_icons/diet_plan/diet_doctor_selected.svg"
                                                        height="20px" alt="" class="listImg">
                                                    <img *ngIf="session.docChecked == false"
                                                        (click)="getArrayList('Doctor',session,person1.day,'add')"
                                                        src="../../../assets/ui_icons/diet_plan/diet_doctor_unselected.svg"
                                                        height="20px" alt="" class="listImg">
                                                </div>
                                                <div style="display: inline-block;width: fit-content;"
                                                    *ngIf="dietFlow == 'dietTracker'">
                                                    <img *ngIf="session.patintChecked == true"
                                                        (click)="getArrayList('patient',session,person1.day,'remove')"
                                                        src="../../../assets/ui_icons/diet_plan/diet_self_selected.svg"
                                                        height="20px" alt="" class="listImg">
                                                    <img *ngIf="session.patintChecked == false"
                                                        (click)="getArrayList('patient',session,person1.day,'add')"
                                                        src="../../../assets/ui_icons/diet_plan/diet_self_unselected.svg"
                                                        height="20px" alt="" class="listImg">
                                                </div> -->
                                                <br>
                                                <input type="text" class="ipcss mainDishInput" [(ngModel)]="DinnerFoods"
                                                    name="DinnerFoods"
                                                    (keyup)="foodType(DinnerFoods, session.type, session)" type="text"
                                                    aria-label="Number" matInput [matAutocomplete]="autoDinnerFoods" />
                                                <div style="display: inline-block;">
                                                    <img *ngIf="session.mainDish == true"
                                                        src="../../../assets/ui_icons/main_course_green_icon.png"
                                                        height="20px" alt="" class="mainDishImg">
                                                    <img *ngIf="session.mainDish == false"
                                                        (click)="mainDish(session,person1.day,person1.date)"
                                                        src="../../../assets/ui_icons/main_course_blue_icon.png"
                                                        height="20px" alt="" class="mainDishImg">
                                                </div>
                                                <mat-autocomplete #autoDinnerFoods="matAutocomplete">
                                                    <mat-option id="optionfont"
                                                        (click)="selctedFood(person1.day,person1.date,food, 1)"
                                                        *ngFor="let food of dinnerFoodItem" [value]="food.description">
                                                        {{food.displayContent}}
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </div>
                                            <div *ngIf="session.type == 'Night'">
                                                <div class="row" *ngIf="dietFlow == 'dietTracker'">
                                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6" style="text-align: center;">
                                                        <mat-label class="matlabel"> Suggested  </mat-label>
                                                        <img *ngIf="session.docChecked == true"
                                                        (click)="getArrayList('Doctor',session,person1.day,person1.date,'remove')"
                                                        src="../../../assets/ui_icons/tick_mark.svg"
                                                        height="20px" alt="" class="listImg">
                                                        <img *ngIf="session.docChecked == false"
                                                        (click)="getArrayList('Doctor',session,person1.day,person1.date,'add')"
                                                        src="../../../assets/ui_icons/cross_mark.svg"
                                                        height="20px" alt="" class="listImg">
                                                    </div>
                                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6" style="text-align: center;">
                                                        <mat-label class="matlabel"> Self  </mat-label>
                                                        <img *ngIf="session.patintChecked == true"
                                                        (click)="getArrayList('patient',session,person1.day,person1.date,'remove')"
                                                        src="../../../assets/ui_icons/tick_mark.svg"
                                                        height="20px" alt="" class="listImg">
                                                        <img *ngIf="session.patintChecked == false"
                                                        (click)="getArrayList('patient',session,person1.day,person1.date,'add')"
                                                        src="../../../assets/ui_icons/cross_mark.svg"
                                                        height="20px" alt="" class="listImg">
                                                    </div>
                                                </div>
                                                <mat-label class="matlabel">Add food item</mat-label>
                                                <!-- <div style="display: inline-block;width: fit-content; "
                                                    *ngIf="dietFlow == 'dietTracker'">
                                                    <img *ngIf="session.docChecked == true"
                                                        (click)="getArrayList('Doctor',session,person1.day,'remove')"
                                                        src="../../../assets/ui_icons/diet_plan/diet_doctor_selected.svg"
                                                        height="20px" alt="" class="listImg">
                                                    <img *ngIf="session.docChecked == false"
                                                        (click)="getArrayList('Doctor',session,person1.day,'add')"
                                                        src="../../../assets/ui_icons/diet_plan/diet_doctor_unselected.svg"
                                                        height="20px" alt="" class="listImg">
                                                </div>
                                                <div style="display: inline-block;width: fit-content;"
                                                    *ngIf="dietFlow == 'dietTracker'">
                                                    <img *ngIf="session.patintChecked == true"
                                                        (click)="getArrayList('patient',session,person1.day,'remove')"
                                                        src="../../../assets/ui_icons/diet_plan/diet_self_selected.svg"
                                                        height="20px" alt="" class="listImg">
                                                    <img *ngIf="session.patintChecked == false"
                                                        (click)="getArrayList('patient',session,person1.day,'add')"
                                                        src="../../../assets/ui_icons/diet_plan/diet_self_unselected.svg"
                                                        height="20px" alt="" class="listImg">
                                                </div> -->
                                                <br>
                                                <input type="text" class="ipcss mainDishInput" [(ngModel)]="NightFoods"
                                                    name="NightFoods"
                                                    (keyup)="foodType(NightFoods, session.type, session)" type="text"
                                                    aria-label="Number" matInput [matAutocomplete]="autoNightFoods" />
                                                <div style="display: inline-block;">
                                                    <img *ngIf="session.mainDish == true"
                                                        src="../../../assets/ui_icons/main_course_green_icon.png"
                                                        height="20px" alt="" class="mainDishImg">
                                                    <img *ngIf="session.mainDish == false"
                                                        (click)="mainDish(session,person1.day,person1.date)"
                                                        src="../../../assets/ui_icons/main_course_blue_icon.png"
                                                        height="20px" alt="" class="mainDishImg">
                                                </div>
                                                <mat-autocomplete #autoNightFoods="matAutocomplete">
                                                    <mat-option id="optionfont"
                                                        (click)="selctedFood(person1.day,person1.date,food, 1)"
                                                        *ngFor="let food of nightFoodItem" [value]="food.description">
                                                        {{food.displayContent}}
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </div>
                                            <div class="foodItemCover">
                                                <div *ngIf="session.docChecked == true">
                                                    <div *ngFor="let foodList of session.foodItems" style="padding: 5px 0px;">
                                                        <div *ngIf="foodList.type == 'Doctor'" style="display: flex;">
                                                            <span>
                                                                <img *ngIf="foodList.checked == false"
                                                                    (click)="isSelected(foodList,'add')"
                                                                    src="../../../assets/ui_icons/yellow_box.svg"
                                                                    style="cursor: pointer;" width="15px" />
                                                                <img *ngIf="foodList.checked == true"
                                                                    (click)="isSelected(foodList,'remove')"
                                                                    src="../../../assets/ui_icons/popup_tick_mark.svg"
                                                                    style="cursor: pointer;" width="15px" />
                                                            </span>
                                                            <div class="foodLable"> {{ foodList.description }} (
                                                                {{foodList.cals_weight}} / {{foodList.wightMesure}} )
                                                            </div>
                                                            <div style="border: 1px solid #67b9eb;min-width: 150px;margin-left: auto;height: fit-content;border-radius: 10px;">
                                                                <div class="input-group" style="border-radius: 10px;">
                                                                    <input type="text" (blur)="changeQuantity(foodList.cals_weight,foodList.day,foodList.date,foodList,session.type,'weight')" style="border: none;width: 40px;font-size: 13px;border-radius: 10px;outline: none;padding: 0px 0 0 5px;" [(ngModel)]="foodList.cals_weight" onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                                                                    <span class="input-group-text" style="padding: 0 5px;font-size: 13px;">{{foodList.wightMesure}}</span>
                                                                    <input type="text" (blur)="changeQuantity(foodList.quantity,foodList.day,foodList.date,foodList,session.type,'quantity')" style="width: 30px;border: none;font-size: 13px;outline: none;"  [(ngModel)]="foodList.quantity" onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                                                                     <span class="input-group-text" style="padding: 1px 4px; font-size: 13px; border-bottom-right-radius: 10px; border-top-right-radius: 10px;margin-left: auto;">{{foodList.measure}}</span>
                                                                  </div>
                                                            </div>
                                                            <!-- <div class="foodInputCover"> <input type="text"
                                                                    (blur)="changeQuantity(foodList.quantity,foodList.day,foodList,session.type,'quantity')"
                                                                    [(ngModel)]="foodList.quantity"
                                                                    class="foodMesureInput"
                                                                    [disabled]="!foodList.checked"
                                                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                                                                <div class="foodMeasure">{{foodList.measure}}</div>
                                                            </div> -->
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="session.patintChecked == true">
                                                    <div *ngFor="let foodList of session.foodItems" style="padding: 5px 0px;">
                                                        <div *ngIf="foodList.type == 'patient'" style="display: flex;">
                                                            <span>
                                                                <img *ngIf="foodList.checked == false"
                                                                    (click)="isSelected(foodList,'add')"
                                                                    src="../../../assets/ui_icons/yellow_box.svg"
                                                                    style="cursor: pointer;" width="15px" />
                                                                <img *ngIf="foodList.checked == true"
                                                                    (click)="isSelected(foodList,'remove')"
                                                                    src="../../../assets/ui_icons/popup_tick_mark.svg"
                                                                    style="cursor: pointer;" width="15px" />
                                                            </span>
                                                            <div class="foodLable"> {{ foodList.description }} 
                                                                <!-- ( {{foodList.cals_weight}} / {{foodList.wightMesure}} ) -->
                                                            </div>
                                                            <div style="border: 1px solid #67b9eb;min-width: 150px;margin-left: auto;height: fit-content;border-radius: 10px;">
                                                                <div class="input-group" style="border-radius: 10px;">
                                                                    <input type="text" (blur)="changeQuantity(foodList.cals_weight,foodList.day,foodList.date,foodList,session.type,'weight')" style="border: none;width: 40px;font-size: 13px;border-radius: 10px;outline: none;padding: 0px 0 0 5px;" [(ngModel)]="foodList.cals_weight" onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                                                                    <span class="input-group-text" style="padding: 0 5px;font-size: 13px;">{{foodList.wightMesure}}</span>
                                                                    <input type="text" (blur)="changeQuantity(foodList.quantity,foodList.day,foodList.date,foodList,session.type,'quantity')" style="width: 30px;border: none;font-size: 13px;outline: none;"  [(ngModel)]="foodList.quantity" onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                                                                     <span class="input-group-text" style="padding: 1px 4px; font-size: 13px; border-bottom-right-radius: 10px; border-top-right-radius: 10px;margin-left: auto;">{{foodList.measure}}</span>
                                                                  </div>
                                                            </div>
                                                          
                                                            <!-- <div class="foodInputCover"> 
                                                                <input type="text" (blur)="changeQuantity(foodList.cals_weight,foodList.day,foodList,session.type,'weight')" [(ngModel)]="foodList.cals_weight" class="foodMesureInput"  [disabled]="!foodList.checked" onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                                                                <div class="foodMeasure">{{foodList.wightMesure}}</div>
                                                            </div>

                                                            <div class="foodInputCover"> 
                                                                <input type="text" (blur)="changeQuantity(foodList.quantity,foodList.day,foodList,session.type,'quantity')" [(ngModel)]="foodList.quantity" class="foodMesureInput"  [disabled]="!foodList.checked" onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                                                                <div class="foodMeasure">{{foodList.measure}}</div>
                                                            </div> -->
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- (window:resize)="onResize($event)" -->
                                <div class="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-8 ">
                                    <div class="row foodItemsCover">
                                        <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 p-0"
                                            style="display: flex;align-items: center;justify-content: center;">
                                            <div class="plateCover">
                                                <div id="{{person1.date}}_{{session.type}}" class="main"
                                                    [innerHTML]="session.plateContent">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-12 col-md-6 col-lg-8 col-xl-8 p-0 my-auto"
                                            style="height:fit-content">
                                            <div class="foodValues">
                                                <div style="width: 584px;">
                                                    <div class="foodItems"> <span> Energy (Kcal)</span> <br>
                                                        <span>{{session.energy}}</span>
                                                    </div>
                                                    <div class="foodItems"> <span> Protien (gm)</span> <br>
                                                        <span>{{session.Protien}}</span>
                                                    </div>
                                                    <div class="foodItems"> <span> Calcium (mg)</span> <br>
                                                        <span>{{session.Ca}}</span>
                                                    </div>
                                                    <div class="foodItems"> <span> Iron (mg)</span> <br>
                                                        <span>{{session.Fe}}</span>
                                                    </div>
                                                    <div class="foodItems"> <span> Fiber (mg)</span> <br>
                                                        <span>{{session.fiber}}</span>
                                                    </div>
                                                    <div class="foodItems"> <span> Vit A (mg)</span> <br>
                                                        <span>{{session.vaitamina}}</span>
                                                    </div>
                                                    <hr />
                                                    <div class="foodItems"> <span> Fat (gm)</span> <br>
                                                        <span>{{session.Fat}}</span>
                                                    </div>
                                                    <div class="foodItems"> <span> H<sub>2</sub>O (gm)</span>
                                                        <br>
                                                        <span>{{session.h2o}}</span>
                                                    </div>
                                                    <div class="foodItems"> <span> Folic (mcg)</span> <br>
                                                        <span>{{session.Folic}}</span>
                                                    </div>
                                                    <div class="foodItems"> <span> Carbos (mg)</span> <br>
                                                        <span>{{session.Carbohydrates}}</span>
                                                    </div>
                                                    <div class="foodItems"> <span> Vit C (mg)</span> <br>
                                                        <span>{{session.Vitaminc}}</span>
                                                    </div>
                                                    <div class="foodItems"> <span> Vit B12 (mg)</span> <br>
                                                        <span>{{session.vitaminb12}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Modal -->
                <!-- <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false"
                    tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="titleStyle">
                                <h5 class="modal-title" id="staticBackdropLabel">Diet Conformation</h5>
                                <span class="closeicon" data-bs-dismiss="modal" aria-label="Close"
                                    style="float: right;"><img src="../../../assets/img/close_icon.png"
                                        alt="Card image cap" style="width:19px"> </span>
                            </div>
                            <div class="modal-body">
                                Whould you like to copy {{today}} diet plan to other days?
                            </div>
                            <div class="modal-footer">
                                <img src="../../../assets/ui_icons/buttons/No_button.svg" alt="Card image cap"
                                    style="width:85px; height: 25px;" (click)="assingData('no')">
                                <img src="../../../assets/ui_icons/buttons/yes_button.svg" alt="Card image cap"
                                    style="width:85px; height: 25px;" (click)="assingData('yes')">
                            </div>
                        </div>
                    </div>
                </div> -->
                <!-- Modal -->
                <div class="modal fade" id="pdfModel" tabindex="-1" aria-labelledby="exampleModalLabel"
                    aria-hidden="true">
                    <div class="modal-dialog " style="width: 950px; max-width: 950px;">
                        <div class="modal-content">
                            <div class="modal-body">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8" style="margin-top: 10px;" *ngIf="pdfURL != ''">
                                    <iframe class="controls" [src]="pdfURL" width="900px" height="700px" frameborder="0"
                                        webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>