

<div class="modal-content">
    <div class="modal-body">
       <div style="height: 400px; overflow-y: scroll; margin-top: 20px;">
          <table>
             <thead  style="position: sticky; top: 0;">
                <tr>
                   <th style="text-align: left;">Antibiotic</th>
                   <th style="text-align: left; width: 25%;">Sensitivity</th>
                   <th style="text-align: left;">MIC</th>
                </tr>
             </thead>
             <tbody>
                <tr *ngFor="let medicine of medicineList">
                   <td style="text-align: left;">{{medicine.sensitive_drug_desc}}</td>
                   <td style="text-align: left;">
                      <mat-label class="formbold-form-label">
                         <select disableOptionCentering class="formbold-form-input"
                            [ngStyle]="{'color': medicine.isSensitive ? 'red' : '#536387'}"
                            style="margin-top: 8px;" [(ngModel)]="medicine.culture_sensitive_id"
                            (change)="onSensitivyChange(medicine.culture_sensitive_id, medicine.sensitive_drug_desc, medicine.sensitive_drug_id)">
                            <option *ngFor="let sensitivity of sensitivityTypeList"
                               value="{{sensitivity.culture_sensitive_id}}">
                               {{sensitivity.culture_sensitive_desc}}</option>
                         </select>
                      </mat-label>
                   </td>
                   <td style="text-align: left; font-size: 15px;"><input [(ngModel)]="medicine.mic"
                         type="text" class="formbold-form-input" /></td>
                </tr>
             </tbody>
          </table>
       </div>
       <div class="container-fluid mt-3">
          <div class="row ">
             <div *ngFor="let Sense of sensitivityCardList" class="col-2">
                <div class="card-container mb-2">
                   <p class="ref-values">{{Sense.med_Name}}</p>
                </div>
             </div>
          </div>
       </div>
    </div>
    <div class="modal-footer">

       <!-- <img src="../../../assets/ui_icons/buttons/Done_button.svg" data-bs-dismiss="modal"
          (click)="saveColonyCountData()" class="saveimgbtn_inpatinfo" /> -->

          <img src="../../../assets/img/mobile_buttons/done.png" data-bs-dismiss="modal"
          (click)="saveColonyCountData()" class="saveimgbtn_inpatinfo" />

          <!-- C:\development\svn_new\TervysWebAdmin\src\assets\img\mobile_buttons\done.png -->
    </div>
 </div>
