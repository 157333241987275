<app-header></app-header>

<div class="banner-section-bg d-flex flex-column justify-content-center mt-5">
    <div class="px-5 text-center">
       <h1 class="banner-head"> Hospital Management system</h1>
    </div>
 </div>
 <div class="pt-5 pb-5">
    <div class="container">
       <div class="row">
          <div class="col-12 col-md-4">
              <img src="../../../assets/images/solutions/Hospmanageicon.png" class="hosp-img">   
          </div>
          <div class="col-12 col-md-8">
             <p class="para">Gritsys clinic management system is a software solution that utilizes artificial intelligence (AI) technologies to streamline and optimize the management of clinics and other healthcare facilities. It provides a range of 
                features and functionalities that are designed to improve the efficiency, accuracy, and effectiveness of clinic operations. 
                The key features and benefits of Gritsys AI clinic management system
                <br>
                <br>
                Gritsys AI clinic management systems provide a range of features and functionalities that are designed to streamline and optimize clinic operations, improve patient outcomes, and reduce costs. By utilizing advanced technologies such as machine learning and predictive analytics, This system can provide healthcare providers with real-time insights into patient care and resource utilization, allowing them to make informed decisions and provide the best possible care to their patients.
             </p>
          </div>
       </div>
    </div>
 </div>
 <div class="wcu-section pt-5 pb-5" id="wcuSection">
    <div class="container">
       <div class="row justify-content-center">
          <div class="col-12 col-md-4">
             <div class="wcu-card text-center">
                <img src="../../../assets/images/solutions/patientengage.png" class="wcu-card-icon" />
                <h2 class="wcu-heading pt-3">Patient management</h2>
                <p class="wcu-para">Offers advanced patient management capabilities, including patient registration, appointment scheduling, medical records management, and patient history tracking. The system can also automate processes such as billing and insurance claims management, making it easier for staff to manage patient care and administrative tasks.</p>
             </div>
          </div>
          <div class="col-12 col-md-4">
             <div class="wcu-card text-center">
                <img src="../../../assets/images/solutions/clinical-decision.png" class="wcu-card-icon" />
                <h2 class="wcu-heading pt-3">Clinical decision support</h2>
                <p class="wcu-para">Use machine learning algorithms to analyze patient data and provide clinical decision support to healthcare providers. This includes identifying potential diagnoses, recommending treatment plans, and providing real-time alerts for critical events such as adverse drug reactions or abnormal vital signs.</p>
             </div>
          </div>
          <div class="col-12 col-md-4">
             <div class="wcu-card text-center">
                <img src="../../../assets/images/solutions/resource-management.png" class="wcu-card-icon" />
                <h2 class="wcu-heading pt-3">Resource management</h2>
                <p class="wcu-para">Optimize resource utilization by automating the management of clinic resources such as equipment, supplies, and staff scheduling. This helps clinics reduce waste, improve operational efficiency, and enhance patient outcomes.</p>
             </div>
          </div>
          <div class="col-12 col-md-4">
             <div class="wcu-card text-center">
                <img src="../../../assets/images/solutions/predictive-analytics.png" class="wcu-card-icon" />
                <h2 class="wcu-heading pt-3">Predictive analytics</h2>
                <p class="wcu-para">Uses predictive analytics to forecast patient needs and trends, allowing healthcare providers to proactively identify and address potential health issues before they become serious. This can help reduce hospital readmissions and improve patient outcomes.</p>
             </div>
          </div>
          <div class="col-12 col-md-4">
             <div class="wcu-card text-center">
                <img src="../../../assets/images/solutions/Telemedicine.png" class="wcu-card-icon" />
                <h2 class="wcu-heading pt-3">Telemedicine</h2>
                <p class="wcu-para">Offers telemedicine capabilities, allowing patients to access healthcare services remotely. This helps clinics provide care to patients who may not be able to visit in-person, such as those in rural or remote areas, and can improve patient access to care.</p>
             </div>
          </div>
          <div class="col-12 col-md-4">
             <div class="wcu-card text-center">
                <img src="../../../assets/images/solutions/patientmanage.png" class="wcu-card-icon" />
                <h2 class="wcu-heading pt-3">Patient engagement</h2>
                <p class="wcu-para">Offers patient engagement features such as mobile apps and patient portals, allowing patients to access their health information and communicate with their healthcare providers remotely. This helps patients stay engaged in their care and can improve patient satisfaction and outcomes.</p>
             </div>
          </div>
          <div class="col-12 col-md-4">
             <div class="wcu-card text-center">
                <img src="../../../assets/images/solutions/fraud.png" class="wcu-card-icon" />
                <h2 class="wcu-heading pt-3">Fraud detection</h2>
                <p class="wcu-para">Uses machine learning algorithms to detect and prevent fraudulent activities such as insurance fraud or identity theft. This can help hospitals protect patient data and reduce financial losses due to fraud.</p>
             </div>
          </div>
       </div>
    </div>
 </div>

 <app-footer></app-footer>