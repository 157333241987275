<div class="row" style="margin:0 auto; width:100%">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Packages</h5>
          </div>
          <div class="headerButtons">
            <div style="width: 385px;">
              <img class="saveimgbtn_inpatinfo" src="../../../assets/ui_icons/buttons/save_button.svg" />
            </div>
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row">
          <div class="col-12" id="diet">
              <owl-carousel-o [options]="customOptionsRel">
                  <ng-template carouselSlide *ngFor="let relation of relationlistData;">
                      <div class="slide">
                          <div class="cu_slider_cover">
                              <div class="card_border1"
                                  (click)="selectRelation(relation.rel_name,relation.rel_id,relation.sub_rel_id,relation.life_style_id)">
                                  <div class="d-flex justify-content-center">
                                      <img class="img-fluid" class="image" src="{{relation.prof_img}}"
                                          alt="Card image cap">
                                  </div>
                                  <p class="carousel_label">{{relation.rel_name}}</p>
                              </div>
                              <a tabindex="0">
                                  <div>
                                  </div>
                              </a>
                          </div>
                      </div>
                  </ng-template>
              </owl-carousel-o>
          </div>
      </div>
        <div class="billAreaCover">
          <div class="contanier-flud p-0">
           
            <div style="display: inline-block">
              <div [ngStyle]="{'background': type.isActive ? '#e5f3fb' : '#fff' }"  class="tabCover" *ngFor="let type of packageTypeArray; let i = index" (click)="getPackage(type)">
                <img class="tabImg" [src]="imgURL + type.image" alt="" style="width: 20px; height:20px;">
                <div class="tabLable">{{type.description}}</div>
              </div>
            </div>
            <div class="row" style="margin-bottom: 17px;">
              <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-2 col-xxl-2">
                <input type="text" [(ngModel)]="location" class="packageInput" placeholder="Enter location" (keyup)="keyUpLocation($event)" maxlength="50" [matAutocomplete]="auto1">
                <!-- (click)="selectedLocation(location)" -->
                    <mat-autocomplete #auto1="matAutocomplete">
                        <mat-option style="padding: 0 3px 0px 8px;line-height: normal;" *ngFor="let location of clntLocationList" value="{{location.location_desc}}" (click)="selectedLocation(location)">
                            <div class="getSuggestion">{{location.location_desc}} <br> <span style="font-size: 12px; font-weight: 500;">{{location.sub_data}}</span></div>
                        </mat-option>
                    </mat-autocomplete>
              </div>
              <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-2 col-xxl-2">
                <select class="packageInput" placeholder="Search by Center" [(ngModel)]="diagCenter" (change)="changeGender()">
                  <option value="0" selected disabled>Search by Center</option>
                    <option *ngFor="let center of centerArray" [value]="center.hosp_id"  >{{center.hptl_name}}</option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col-12" id="diet">

                <owl-carousel-o [options]="customOptions">
                  <ng-template carouselSlide *ngFor="let package of packageArray; let i = index">
                    <div class="slide">
                      <div class="card cardCover">
                        <div class="card-header cardHeader">
                          <div class="cardHeaderImg">
                            <img [src]="imgURL + package.image" alt="">
                          </div>
                          <div class="cardLable my-auto"> {{package.package_name}} <br>
                            <span>
                              {{package.package_short_desc}}
                            </span>
                          </div>
                        </div>
                        <div class="card-body cardBody">
                          <div class="testLable " *ngFor="let offer of package.health_details; let i = index">
                            <img src="this.imgURL + offer.image  " class="testIcon" alt=""> <span>{{ offer.description }}</span>
                          </div>
                          <div *ngFor="let test of package.health_tests; let i = index" style="margin: 0px 0 9px 0px">
                            <div class="testLable1" (click)="showSubList(test)">
                              <img [src]="test.test_type == 'Blood Test' ? '../../../assets/ui_icons/packages/test/Culture_test.svg' :
                              test.test_type == 'Urine Test' ? '../../../assets/ui_icons/packages/test/Urine_test.svg' :
                                test.test_type == 'Faeces Test' ? '../../../assets/ui_icons/packages/test/Faeces_test.svg' :
                                  test.test_type == 'Scan' ? '../../../assets/ui_icons/packages/test/Scan.svg' :
                                    test.test_type == 'Ultra Sound' ? '../../../assets/ui_icons/packages/test/ultrasound_test.svg' :
                                      test.test_type == 'X-ray' ? '../../../assets/ui_icons/packages/test/X-ray.svg' :
                                        test.test_type == 'Biopsy' ? '../../../assets/ui_icons/packages/test/Biopsy_test.svg' :
                                          '../../../assets/ui_icons/packages/test/Culture_test.svg'" class="testIcon" alt=""> <span>{{test.test_name}} </span> <span> ({{test.count}})</span>
                            </div>
                            <div class="subMenu" [id]="'test' + test.test_id ">
                                <div *ngFor="let testSub of test.sub_tests; let i = index">
                                    {{testSub}}
                                </div> 
                            </div>
                          </div>
                        </div>
                        <div class="card-footer cardFooter">
                          <div class="footerAmount"> <span> &#x20b9; {{package.price}} </span> </div>
                          <img src="../../../assets/ui_icons/packages/book_now_button.svg" style="width:85px;" (click)="bookNow(package)" alt="">
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </owl-carousel-o>
              </div>
            </div>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>