<div class="row" style="width:100%;margin:0 auto;">
    <div class="col-12">
      <mdb-card>
        <mdb-card-header class="bg-white">
          <div class="headerCover">
            <div class="headerTilte app_list_header">
              <h5 class="m-0" class="mainHeadingStyle">Receivable report</h5>
            </div>
            <div class="headerButtons">
              
            </div>
          </div>
        </mdb-card-header>
        <mdb-card-body>
          <div class="cover_div" style="padding-bottom: 13px;">
            <div class="header_lable">
               Receivable details
            </div>
            <div class="content_cover">
              <div class="row">
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                  <mat-label class="matlabel" style="margin-top: 7px;">PO from
                    <input type="date"  class="ipcss_date " (change)="dateSelect(from_date,'1')" [(ngModel)] ="from_date" max="{{currentDate}}" #matInput>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                  <mat-label class="matlabel" style="margin-top: 7px;">PO to
                    <input type="date"  class="ipcss_date " (change)="dateSelect(to_date,'2')" [(ngModel)] ="to_date" max="{{currentDate}}" #matInput>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                  <mat-label class="matlabel"><br>
                    <img src="../../../assets/ui_icons/buttons/search_button.svg" class="saveimgbtn_inpatinfo"
                      (click)="getReceivablesData()" />
                  </mat-label>
                </div>
              </div>
            </div>
        </div>
        <p class="nodata" *ngIf="receive_data.length ==0">No records found</p>
          <div class="row">
            <div class="col-12" style="margin-top: 10px;">
              <div class="table-responsive dig_table_overflow" *ngIf="receive_data.length !=0">
                <table  id="card_tbl" class="table table-hover table-dynamic"
                style="font-size: 12px; width: 95%;margin-left: 5px;">
                <thead>
                  <tr>
                    <th>Product name</th>
                    <th>PO no</th>
                    <th>PO quantity</th>
                    <th>Received qty</th>
                    <th>Pending Qty</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let receive of receive_data">
                    <td class="align_left">{{receive.product_name}}</td>
                    <td>{{receive.po_code}}</td>
                    <td>{{receive.po_qty}}</td>
                    <td>{{receive.qty_received}}</td>
                    <td>{{receive.balance}}</td>
                  </tr>
                </tbody>
              </table>
              </div>
              
            </div>
          </div>
        </mdb-card-body>
      </mdb-card>
    </div>
  </div>