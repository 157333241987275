<div class="row" style="width:100%;margin:0 auto;">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Doctor referral</h5>
                    </div>
                    <div class="headerButtons">
                        <a (click)="goToPatientlist()"><img src="../../../assets/ui_icons/buttons/Back_button.svg"
                            class="save_button"  /></a>
                        
                        <a style="margin:0 5px;" (click)="saveDocRef()"><img src="../../../assets/ui_icons/buttons/save_button.svg"
                            class="save_button" /></a>

                        <a style="margin:0 5px;" (click)="print_area()"><img src="../../../assets/img/printer.svg"
                            class="backimgbtn_inpatinfo" style="width: 25px; height: 25px;" /></a>
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>
                <div class="row">                   
                    <div class="cover_div" style="padding-bottom: 13px;">
                        <div class="header_lable">
                            Patient details
                        </div>
                        <div class="content_cover">
                            <div class="row">
                                <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                                    <mat-label class="matlabel">Filter
                                        <select disableOptionCentering class="ipcss " (change)="getPatientList()"
                                            [(ngModel)]="filter">
                                            <option value="name">First name</option>
                                            <option value="mobile">Mobile</option>
                                            <option value="patient_id">Patient Id</option>
                                        </select>
                                    </mat-label>
                                </div>
                                <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 ">
                                    <div class="row">
                                        <div class="col-10">
                                            <mat-label class="matlabel "><br>
                                                <input class="ipcss " [(ngModel)]="patientFName"
                                                    (blur)="patientNameToUpper()"
                                                    placeholder={{somePlaceholder}} >
                                            </mat-label>
                                        </div>
                                        <div class="col-2 p-0">
                                            <mat-label class="matlabel ">
                                                <a (click)="changePatientName(patientFName)"><img
                                                        src="../../../assets/img/search.png" class="seacrhicon"
                                                        width="25" height="auto" /></a>
                                            </mat-label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                                    <mat-label class="matlabel">Patient id
                                        <!-- class="form-control" -->
                                        <mat-select [(ngModel)]="clientId" class="ipcss widthappt">
                                            <mat-option *ngFor="let client of clientList" value={{client.patient}}
                                                (click)="changeClient(client.patient)">
                                                {{client.patient}}</mat-option>
                                        </mat-select>
                                    </mat-label>
                                </div>
                                <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                                    <mat-label class="matlabel">Name
                                        <input class="ipcss widthappt" [(ngModel)]="clntName" readonly matInput />
                                    </mat-label>
                                </div>
                                <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                                    <mat-label class="matlabel">Age
                                        <input [(ngModel)]="clntAge" class="ipcss widthappt" readonly matInput />
                                    </mat-label>
                                </div>
                                <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                                    <mat-label class="matlabel">Mobile
                                        <input [(ngModel)]="clntContNumber" maxlength="10" class="ipcss widthappt"
                                            readonly matInput />
                                    </mat-label>
                                </div>
                                <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                                    <mat-label class="matlabel">Gender
                                        <input class="ipcss widthappt" [(ngModel)]="clntGender" readonly matInput />
                                    </mat-label>
                                </div>
                                <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                                    <mat-label class="matlabel" >Referred by
                                        <mat-select class="ipcss widthappt" [(ngModel)]="ref_by" >
                                            <mat-option value="{{doc.Doc_Name}}" *ngFor="let doc of doctorList">
                                                {{doc.Doc_Name}}
                                                </mat-option>
                                        </mat-select>
                                    </mat-label>
                                </div>
                                <div class="col-12 col-sm-6 col-md-3 col-lg-4">
                                    <mat-label class="matlabel">Notes
                                        <textarea class="ipcss widthappt" [(ngModel)]="notes" required matInput ></textarea>
                                    </mat-label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="headerButtons">
                        <img class="saveimgbtn_inpatinfo" src="../../../assets/ui_icons/buttons/new_button.svg" (click)="create()"
                            style="margin-left: 9px;margin-top: 10px;" />
                    </div>
                </div>
                <div class="row" [hidden]="create_flag">
                    <div class="cover_div" style="padding-bottom: 13px;">
                        <div class="header_lable">
                            Referral doctor
                        </div>
                        <div class="content_cover">
                            <div class="row">
                                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                    <mat-label class="matlabel">Doctor name<br>
                                        <input type="text" class="ipcss inpat_width" maxlength="50"
                                            [(ngModel)]="refer_txt" (keyup)="getReferralData($event)"
                                            aria-label="Number" matInput [matAutocomplete]="auto2"
                                            />
                                        <mat-autocomplete #auto2="matAutocomplete">
                                            <mat-option id="optionfont" *ngFor="let item of Refered_doctor"
                                                (click)="Select_refer_doctor(item)" value="{{item}}">
                                                {{item}}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-label>
                                </div>
                                <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                                    <mat-label class="matlabel">Specialization
                                        <mat-select [(ngModel)]="special_txt" class="ipcss widthappt">
                                            <mat-option *ngFor="let spl of specializationList" value={{spl.spl_name}}
                                                (click)="changeSpeciality(spl.spl_id,spl.spl_name)">
                                                {{spl.spl_name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-label>
                                </div>
                                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                    <mat-label class="matlabel">Hospital name<br>
                                        <input type="text" class="ipcss inpat_width" maxlength="50" [(ngModel)]="hosp_txt" 
                                            (keyup)="getHospitals($event)" aria-label="Number" matInput [matAutocomplete]="auto3" />
                                        <mat-autocomplete #auto3="matAutocomplete">
                                            <mat-option id="optionfont" *ngFor="let hospital of hosplist"
                                                (click)="selecteHospital(hospital)" value="{{hospital}}">
                                                {{hospital}}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-label>
                                </div>

                                <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                                    <mat-label class="matlabel">State<br>
                                      <mat-select required class="ipcss widthappt" [(ngModel)]="hospState" (ngModelChange)="changeState(hospState, '0')">
                                        <mat-option *ngFor="let state of hospStateList" value={{state.description}}>{{state.description}}
                                        </mat-option>
                                      </mat-select>
                                    </mat-label>
                                  </div>
                                  <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                                    <mat-label class="matlabel">District / City<br>
                                      <mat-select required class="ipcss widthappt" [(ngModel)]="hospCity" (ngModelChange)="changeCity(hospCity, '0')">
                                        <mat-option *ngFor="let city of hospCityList" value="{{city.description}}">{{city.description}}
                                        </mat-option>
                                      </mat-select>
                                    </mat-label>
                                  </div>
                                  <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                                    <mat-label class="matlabel">Location<br>
                                        <input type="text" class="ipcss widthappt" required maxlength="50" [(ngModel)]="hospLocation"
                                          (keyup)="changeLocation($event)" matInput [matAutocomplete]="auto1" />
                                        <mat-autocomplete #auto1="matAutocomplete">
                                          <mat-option (click)="selectLocation(location)" *ngFor="let location of hospLocationList" value={{location.location_id}}>
                                            {{location.description}}
                                          </mat-option>
                                        </mat-autocomplete>
                                      </mat-label>
                                  </div>
                                  <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                                    <mat-label class="matlabel">Zipcode
                                      <input type="text" class="ipcss widthappt" [(ngModel)]="hospZipcode" matInput />
                                    </mat-label>
                                  </div>
                                  <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                                    <mat-label class="matlabel">Telephone
                                      <input type="text" class="ipcss widthappt" [(ngModel)]="hosptelno" matInput />
                                    </mat-label>
                                  </div>

                                  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <mat-label class="matlabel"  style="cursor:pointer"><br>
                                      <img src="../../../assets/ui_icons/buttons/save_button.svg" class="save_button"
                                      (click)="saveRefDocDetails()"/>
                                    </mat-label>
                                  </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <p class="nodata" *ngIf="doctor_list.length == 0" style="width: 33% !important;">No doctor(s) found</p>
                    <div class="col-12">
                      <table *ngIf="doctor_list.length" mdbTable datatable [dtOptions]="dtOptions"
                        class="table table-nowrap table-sm dataTable billlisttable">
                        <thead class="tableheading">
                          <tr>
                            <th scope="col"></th>
                                <th scope="col">Doctor name</th>
                                <th scope="col">Speciality</th>
                                <th scope="col">Hospital Name</th>
                                <th scope="col">Hospital address</th>
                          </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let docList of doctor_list">
                                <td><input type="checkbox" (click)="getDoctor(docList)" [checked]="docList.checked"></td>
                                <td>{{docList.doc_name}}</td>
                                <td>{{docList.spl_name}}</td>
                                <td>{{docList.Hptl_name}}</td>
                                <td>{{docList.hptl_adrs}}</td>
                            </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
            </mdb-card-body>
        </mdb-card>
    </div>
    <div [hidden]="true">
        <div #printref id="printref">
            
            <div style="margin-left: 40px;margin-top: 40px;">
                <p style="font-family: 'Times New Roman', Times, serif;font-weight: 500;font-size: 17px;">PATIENT
                    NAME <span style="font-weight: 700;margin-left: 78px;">:</span><span
                        style="text-transform: uppercase;"> {{clntName}}</span></p>
                <p style="font-family: 'Times New Roman', Times, serif;font-weight: 500;font-size: 17px;">UUID
                    NUMBER <span style="font-weight: 700;margin-left: 79px;">:</span> {{clientId}}</p>
            </div>
            <div style="margin-top: 20px; margin-left: 20px;">
                <p style="font-family: 'Times New Roman', Times, serif;font-weight: 700;font-size: 17px;">
                    <u>REFERRED BY</u></p>
            </div>
            <div style="margin-left: 40px;margin-top: 30px;">
                <p style="font-family: 'Times New Roman', Times, serif;font-weight: 500;font-size: 17px;">CONSULTANT
                    NAME<span style="font-weight: 700;margin-left: 37px;">:</span><span
                        style="text-transform: uppercase;"> {{ref_by}}</span></p>
                <p style="font-family: 'Times New Roman', Times, serif;font-weight: 500;font-size: 17px;">
                    HOSPITAL/CLINIC <span style="font-weight: 700;margin-left: 55px;">:</span><span
                        style="text-transform: uppercase;"> {{Hptl_name}}</span></p>
                <p style="font-family: 'Times New Roman', Times, serif;font-weight: 500;font-size: 17px;">EMAIL ID
                    <span style="font-weight: 700;margin-left: 122px;">:</span><span
                        style="text-transform: uppercase;"></span></p>
                <p style="font-family: 'Times New Roman', Times, serif;font-weight: 500;font-size: 17px;">CONTACT
                    NUMBER <span style="font-weight: 700;margin-left: 43px;">:</span><span
                        style="text-transform: uppercase;"> </span></p>
            </div>

            <div style="margin-top: 20px;margin-left: 20px;">
                <p style="font-family: 'Times New Roman', Times, serif;font-weight: 700;font-size: 17px;">
                    <u>REFERRED TO</u></p>
            </div>
            <div style="margin-left: 40px;margin-top: 20px;">
                <p style="font-family: 'Times New Roman', Times, serif;font-weight: 500;font-size: 17px;">
                    SPECIALITY<span style="font-weight: 700;margin-left: 102px;">:</span> <span
                        style="text-transform: uppercase;"> {{speciality}}</span></p>
                <p style="font-family: 'Times New Roman', Times, serif;font-weight: 500;font-size: 17px;">CONSULTANT
                    NAME <span style="font-weight: 700;margin-left: 30px;">:</span><span
                        style="text-transform: uppercase;"> {{ref_to}}</span></p>
                <p style="font-family: 'Times New Roman', Times, serif;font-weight: 500;font-size: 17px;">ADMISSION
                    DONE <span style="font-weight: 700;margin-left: 48px;">:</span></p>
                <p style="font-family: 'Times New Roman', Times, serif;font-weight: 500;font-size: 17px;">WARD
                    DETAILS <span style="font-weight: 700;margin-left: 70px;">:</span></p>
                <p style="font-family: 'Times New Roman', Times, serif;font-weight: 500;font-size: 17px;">TREATED AS
                    OP <span style="font-weight: 700;margin-left: 67px;">:</span></p>
                <p style="font-family: 'Times New Roman', Times, serif;font-weight: 500;font-size: 17px;">REVIEW ON
                    <span style="font-weight: 700;margin-left: 97px;">:</span></p>
            </div>
            <div style="margin-top: 20px;margin-left: 20px;">
                <p style="font-family: 'Times New Roman', Times, serif;font-weight: 700;font-size: 17px;"><u>PATIENT
                        CONDITION</u></p>
                <p style="font-family: 'Times New Roman', Times, serif;font-weight: 500;font-size: 17px;">(TO BE
                    FILLED BY TREATING CONSULTANT)</p>
            </div>
            <div style="margin-top:60px;">
                <p
                    style="text-align: center; font-family: 'Times New Roman', Times, serif;font-weight: 500;font-size: 17px;">
                    Note:<span style="text-align: center;">{{notes}}</span>
                </p>
            </div>
        </div>
    </div>
</div>