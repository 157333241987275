import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../../app/providers/common-data.service';
import { ConvertTimeformat, Date_Formate, Time_Formate, convertNumberToWords } from '../../../assets/js/common.js';
import { Helper_Class } from '../../../app/helper_class';
import { OrdercancelComponent } from '../ordercancel/ordercancel.component';
import { ViewmedprespageComponent } from '../viewmedprespage/viewmedprespage.component';
import { pharmacy_helper } from '../Pharmcy_Helper';
import { PharmacyService } from '../pharmacy/pharmacy.service';
import { OrderConfirmPopupComponent } from '../order-confirm-popup/order-confirm-popup.component';
import { ipaddress } from '../../ipaddress';
import { PharmaPrintService } from '../pharmacy-print.service';
import { Message_data } from 'src/assets/js/Message_data';
import moment from 'moment';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
import { TranslateService } from '@ngx-translate/core';
// import { requestDevicecoverprint } from '../../../assets/js/common';

@Component({
  selector: 'app-pharmacymodule-orderdetiledview',
  templateUrl: './pharmacymodule-orderdetiledview.component.html',
  styleUrls: ['./pharmacymodule-orderdetiledview.component.css']
})
export class PharmacymoduleOrderdetiledviewComponent implements OnInit {
  @ViewChild('billprint') printsection: ElementRef;
  @ViewChild('noheader') noheader: ElementRef;
  @ViewChild('banner') banner: ElementRef;
  @ViewChild('logowithname') logowithname: ElementRef;
  @ViewChild('printsmallcover') printsmallcover: ElementRef;
  @ViewChild('printsmallcovereng') printsmallcovereng: ElementRef;
  dureFlag: boolean;
  preferredTimeFlag: boolean;
  public discountDatasArray: any = [];
  public totAmount;
  dtOptions: DataTables.Settings = {};
  public discSalesVol: any = [];
  public discTransVol: any = [];
  public discRegSalesVol = [];
  public paid = "0.00";
  public remaingBalance;
  orderByName: String;
  prepareTime: String;
  orderNum: string;
  totTime: string;
  orderDate: string;
  orderTime: string;
  drugDetailsArray = [];
  insuranceDetailsArray = [];
  taxDetail = [];
  taxPerc = [];
  amount: string;
  finalAmount: any;
  totalAmount: any;
  validityDays: string;
  availableTo: string;
  todayDate: string;
  purchaseType: string;
  addDelivery: boolean;
  presFlag: boolean = true;
  public orderType: boolean
  name: any;
  address1: any;
  address2: any;
  locationDesc: any;
  cityDesc: any;
  stateDesc: any;
  zipcode: any;
  contactNo: any;
  countryDesc: any;
  storeDiv: boolean;
  delDiv: boolean;
  deldate: any;
  deliverTime: any;
  public cancelBtn: boolean = true;
  confirmBtn: boolean = true;
  deliverBtn: boolean = true;
  printBtn: boolean;
  presobj: any;
  shortName: string;
  public confirmFlag: boolean;
  public hospName: string;
  public hospitalAddress: string;
  public hospCSZ: string;
  public hospTel: string;
  public hospitalCount: string;
  public batchNo: string;
  public medicalPrescription: any = [];
  public MedicineNameList: any = [];
  public MedicineList: any = [];
  public MedicneData: any = [];
  public batchArray: any = [];
  public medIDs: any = [];
  public drugArray: any = [];
  public medicareName: string;
  public medType: string;
  public drugType: string;
  public medicineName: string;
  public medID: string;
  public pharmaLogo: string;
  public billTemplate: string;
  public discType: string;
  public discAmount: string;
  public prodDisc;
  public amountdisc;
  public sendData;
  public price;
  public qty;
  public disc;
  public gst;
  public amountText;
  public perUnit;
  public amountBdGST;
  public productAmout;
  public getData
  public getDataValue;
  public pharmacistID;
  public pharmacyID;
  public ipaddress;

  public viewFlag: boolean;
  public discountValue: boolean;
  pdiscFlag: boolean;
  discFlag: boolean;
  public orderID;
  public totalValue1;
  public printFlag: boolean;
  public stateGST;
  public centralGST;
  public location;
  public city;
  public state;
  public country;
  public pharmacyName;
  public pharmaAddress1;
  public pharmaAddress2;
  public dlNO;
  public GSTNumber;
  public tranFlag: boolean;
  public transactionID;
  public cardFlag: boolean;
  public cardNumber;
  public cardHolderName;
  public chequeFlag: boolean;
  public insurFlag: boolean;
  public bankName;
  public paytypeList: any = [];
  public insurerList: any = [];
  public payType: string;
  public insurerID: string;
  public prodFlag: boolean = false;
  public drName;
  public expiryDate;
  public userName;
  public batchRequired = { "border-color": "rgb(250, 40, 2)" };
  public recItemID;
  public MedicineNameArray: any = [];
  public medicineArray: any = [];
  public productReturn;
  public tempBatch;
  public batchFlag: boolean = false;
  public billEdit: boolean = false;
  public actuval: any;
  public payDes;
  public finyear: string;
  public backendquantity;
  public detailsofdrug: any = [];
  public inputquantity: number;
  public balancequantity;
  public backendindexvalue: any = [];
  public drugname;
  public drugcgst;
  public drugsgst;
  public drugexpiry_date;
  public drugdiscount;
  public drugamount;
  public drugprice;
  public totalamount;
  public nextBatchNo;
  public drugshortname;
  public drugfullname;
  public rackid;
  public binId;
  public mrnumber;
  public editbutton: boolean = false;
  public newbutton: boolean = false;
  public deletebutton: boolean = false;
  public printbutton: boolean = false;
  public viewbutton: boolean = false;
  public alternatsession = true;
  public intakesession;
  public sessionhide: boolean = false;
  public sessionhrs: boolean = true;
  public afterfoodText: string;
  public getIntakeArray = [];
  public intake_id;
  public languagetypeflag = false;
  public language;
  public intake;
  public checkindex;
  public checkbatchNo;
  public batcharraybatchno;
  public subractnumber;
  public morID: boolean;
  public afterID: boolean;
  public eveID: boolean;
  public daydurationShow: boolean;
  public languagetype: boolean;
  public age;
  public gender;
  public tokenno;
  public Ward_name;
  public Bed_no;
  public admissiondate;
  public admission_time;
  public ipop;
  public paymentsarray = [];
  public numToWords: string;
  public headergen;
  public clntsalutation;
  public mobileno;
  public totalAmt;
  public remainingamt;
  public bill_amount = "0.00";
  public total_billflag: boolean;
  public isAdmin;
  public payments: any = [];
  public paytypreq: boolean = true;
  public rateplaneflag: boolean = false;
  public discountdesc;
  public discount_id;
  public discountvalue_amount;
  public disperce;
  public GSTamountflag: boolean;
  public discamount: number = 0;
  public GSTamount: number = 0.00;
  public gstdecimalamt: number = 0.00;
  public amountDicount = "0.00";
  public aftdiscamt: number = 0;
  public prodDiscount: any = 0.00;
  public discoutFlag: boolean;
  public paidAmount = "0.00";
  public remainBalance = "0.00";
  public credit_flag: boolean = true;
  public credit_bill;
  public credit_type;
  public credit_person;
  public hosp_credits: any = [];
  public payType1: string;
  public payTypeListArrray: any = [];
  public amountval: any;
  private tamt: number = 0;
  public priceflag: boolean;
  public discountflag: boolean;
  public mrnumberflag: boolean;
  public tokennumberflag: boolean;
  public ordercreatedetailview;
  public paymentpastorder: boolean;
  public paymentpast_ord_array = [];
  public paytypreqtp: boolean=true;
  public patienttype;
  public lineitmdisflag: boolean;
  public rateplanetype;
  public drugins_allow;
  public insuranceshowflag: boolean;
  public insurancetotal = "0.00";
  public duesponser: any = 0.00;
  public duesponserflag: boolean = true;


  constructor(private translate: TranslateService, public messageservice: MenuViewService, public comm_Data: CommonDataService, public toastr: ToastrService, public http: Http, public router: Router, public dialog: MatDialog, public printService: PharmaPrintService, public adminService: MenuViewService) {
    this.lineitmdisflag = true;
    this.delDiv = true;
    this.printBtn = true;
    this.discFlag = true;
    this.viewFlag = false;
    this.insurFlag = true;
    this.tranFlag = true;
    this.cardFlag = true;
    this.chequeFlag = true;


    this.medicareName = "allopathy";
    this.languagetype = false;
    this.translate.setDefaultLang('tamil');
    this.translate.use('tamil');
    this.language = "Tamil";
    this.getPaymentType();
  }

  @HostListener('window:click', ['$event'])
  onclick(event: any): void {
    if (this.batchFlag == false) {
      if (this.drugDetailsArray.length >= 1) {
        for (var i = 0; i < this.drugDetailsArray.length; i++) {
          $('#drug_' + this.drugDetailsArray[i].index).css("display", "none");
          //  document.getElementsByClassName('auto_complete_text1').style.display = 'none'; 
          // const tempDiv =  document.getElementById('drug_' + this.drugDetailsArray[i].index);
          // tempDiv.style.display = 'none';
        }
      }
    } else {
      this.batchFlag = false;
    }
  }

  ngOnInit(): void {
    this.getDataValue = Helper_Class.getInfo();
    this.total_billflag = true;
    this.paymentpastorder = true;

    if (this.getDataValue.user_type == "Admin") {
      this.editbutton = true;
      this.newbutton = true;
      this.deletebutton = true;
      this.printbutton = true;
      this.viewbutton = true;
    } else {
      if (Helper_Class.getmodulelist() != undefined) {
        for (var i = 0; i < Helper_Class.getmodulelist().length; i++) {
          if (Helper_Class.getmodulelist()[i].module_id == "46") {
            if (Helper_Class.getmodulelist()[i].edit == "1") {
              this.editbutton = true;
            }

            if (Helper_Class.getmodulelist()[i].create == "1") {
              this.newbutton = true;
            }

            if (Helper_Class.getmodulelist()[i].delete == "1") {
              this.deletebutton = true;
            }

            if (Helper_Class.getmodulelist()[i].print == "1") {
              this.printbutton = true;
            }

            if (Helper_Class.getmodulelist()[i].view == "1") {
              this.viewbutton = true;
            }
          }
        }
      }
    }

    this.ordercreatedetailview = pharmacy_helper.getorderview().value;
    this.patienttype = pharmacy_helper.getorderview().patienttype;

    this.discountDatasArray = Helper_Class.getDiscountTypes();
    console.log("discount data " + JSON.stringify(this.discountDatasArray))
    for (var i = 0; i < this.discountDatasArray.length; i++) {
      if (this.discountDatasArray[i].default_val == "1") {
        this.discType = this.discountDatasArray[i].disc_type_id;
        break;
      } else {
        this.discType = this.discountDatasArray[0].disc_type_id;
      }
    }

    //this.getDiscTypes();

    this.rateplaneflag = false;
    this.GSTamountflag = false;
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 10,
      "lengthMenu": [[5, 10, 25, 50, -1], [5, 10, 25, 50, "All"]],
      "columnDefs": [
        { "orderable": false, "targets": [0, 1, 2] },
      ],
      "language": {
        "search": "Filter"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12">><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"l><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };

    if (Helper_Class.getIsAdmin() != undefined) {
      this.pharmacyID = this.getDataValue.pharma_id;
    } else {
      this.pharmacyID = this.getDataValue.hospitals[0].hptl_clinic_id;
    }

    if (pharmacy_helper.getorderview().value == "past") {
      this.orderType = false;
      this.discountValue = true;
      this.rateplaneflag = false;
      this.discountChange(this.discType)
      // this.getDiscTypes();
    } else if (pharmacy_helper.getorderview().value == "past_edit") {
      this.orderType = true;
      this.rateplaneflag = true;

    } else if (pharmacy_helper.getorderview().value == "current") {
      this.orderType = true;
      this.priceflag = true;
      this.discountflag = true;



    } else {
      this.orderType = false;
    }




    this.rackid = pharmacy_helper.getorderview().rack_id
    this.binId = pharmacy_helper.getorderview().bin_id

    this.paid = "0.00";




    if (this.getDataValue.user_type == "Admin") {
      this.isAdmin = true;
    }

    this.getDataValue = Helper_Class.getInfo();
    this.pharmacistID = this.getDataValue.user_id;
    this.finyear = pharmacy_helper.getorderview().fin_year;
    this.printFlag = Helper_Class.getBillPrint() == "1" ? true : false;



    this.pharmacyName = this.getDataValue.hospitals[0].hptl_name;
    this.pharmaAddress1 = this.getDataValue.hospitals[0].address1 != undefined && this.getDataValue.hospitals[0].address1 != null && this.getDataValue.hospitals[0].address1 != "" && this.getDataValue.hospitals[0].address1 != "undefined" ? this.getDataValue.hospitals[0].address1 : undefined;
    this.pharmaAddress2 = this.getDataValue.hospitals[0].address2 != undefined && this.getDataValue.hospitals[0].address2 != null && this.getDataValue.hospitals[0].address2 != "" && this.getDataValue.hospitals[0].address2 != "undefined" ? this.getDataValue.hospitals[0].address2 : undefined;
    this.location = this.getDataValue.hospitals[0].location_desc;
    this.city = this.getDataValue.hospitals[0].city_desc;
    this.state = this.getDataValue.hospitals[0].state_desc;
    this.country = this.getDataValue.hospitals[0].country_desc;
    this.dlNO = this.getDataValue.hospitals[0].dl_no != undefined && this.getDataValue.hospitals[0].dl_no != null && this.getDataValue.hospitals[0].dl_no != "" ? this.getDataValue.hospitals[0].dl_no : undefined;
    this.GSTNumber = this.getDataValue.hospitals[0].gst_no != undefined && this.getDataValue.hospitals[0].gst_no != null && this.getDataValue.hospitals[0].gst_no != "" ? this.getDataValue.hospitals[0].gst_no : undefined;
    if (this.getDataValue.middle_name != undefined) {
      this.userName = this.getDataValue.first_name + " " + this.getDataValue.middle_name + " " + this.getDataValue.last_name;

    } else {
      if (this.getDataValue.last_name != undefined) {
        this.userName = this.getDataValue.first_name + " " + this.getDataValue.last_name;

      } else {
        this.userName = this.getDataValue.first_name;
      }
    }

    this.ipaddress = Helper_Class.getIPAddress();
    this.orderID = pharmacy_helper.getorderview().orderid;
    this.checkPrescriptionType();
    this.getData = pharmacy_helper.getorderview();

    if (this.getData.status != null) {
      if (this.getData.status == "0") {
        this.cancelBtn = false;
        this.confirmBtn = false;
        this.deliverBtn = true;

      } else if (this.getData.status == "1") {
        this.cancelBtn = true;
        this.confirmBtn = true;
        this.deliverBtn = false;
        this.printBtn = false;

      } else if (this.getData.status == "2") {
        this.confirmBtn = false;
        this.printBtn = true;

      } else {
        this.cancelBtn = true;
        this.confirmBtn = true;
        this.deliverBtn = false;
        this.printBtn = true;
      }
    }

    this.billEdit = Helper_Class.getBillEdit() == "1" ? true : false;
    this.addDelivery = true;

    this.currentDate();

    this.getDiscountDetails();
    this.getIntakeSession();
    var send_data = {
      order_id: this.orderID,
      pharmacy_id: this.pharmacyID,
      fin_year: this.finyear,
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/vieworderdetails/", JSON.stringify(send_data),
      { headers: headers }).subscribe(
        response => {
          var obj = response.json();
          console.log("object value of object", JSON.stringify(obj))

          this.detailsofdrug.push(obj.drug_details)
          console.log(JSON.stringify(this.detailsofdrug))

          if (obj != null) {
            this.viewFlag = false;
            if (obj.dr_first_name != undefined) {
              this.drName = obj.dr_middle_name != undefined ? obj.dr_first_name + " " + obj.dr_middle_name + " " + obj.dr_last_name : obj.dr_first_name + " " + obj.dr_last_name;
            }
            else {
              this.drName = obj.dr_name;
            }
            this.confirmFlag = obj.is_confirm == "1" ? true : false;

            this.prepareTime = Time_Formate(obj.order_time);
            if (obj.middle_name != undefined) {
              this.orderByName = encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.middle_name) + " " + encrypt_decript.Decript(obj.last_name);
            } else {
              this.orderByName = encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.last_name);
            }

            if (obj.mobile != undefined) {
              this.mobileno = encrypt_decript.Decript(obj.mobile)
            }
            else {
              this.mobileno = ""
            }

            this.clntsalutation = obj.salutation
            this.presFlag = obj.pres_drug_id != undefined ? false : true;
            this.orderNum = obj.drug_purchase_id != undefined ? obj.drug_purchase_id : "";
            this.purchaseType = obj.description != undefined ? obj.description : "";
            if (obj.preferred_from_time != undefined) {
              this.totTime = (obj.preferred_from_time) + " " + (obj.preferred_to_time);
              this.preferredTimeFlag = false;
            } else {
              this.preferredTimeFlag = true;
            }

            if (obj.mr_no != undefined) {
              this.mrnumber = obj.mr_no
            } else {
              this.mrnumber = ""
            }

            if (obj.order_date != undefined) {
              var dateval = obj.order_date.split("-");
              this.orderDate = obj.order_date != undefined ? dateval[2] + "-" + dateval[1] + "-" + dateval[0] : undefined;
            }
            if (obj.age != undefined) {
              this.age = obj.age;
            } else {
              this.age = "";
            }
            if (obj.gender != undefined) {
              this.gender = obj.gender;
            } else {
              this.gender = "";
            }

            if (obj.ins_allow_flag != undefined && obj.ins_allow_flag == "1") {
              this.insuranceshowflag = false;
            } else {
              this.insuranceshowflag = true;
            }

            if (this.gender == "Male" || this.gender == "M") {
              this.headergen = "M";
            } else if (this.gender == "Female" || this.gender == "F") {
              this.headergen = "F";
            } else if (this.gender == "Transgender" || this.gender == "M") {
              this.headergen = "TG";
            } else {
              this.headergen = "";
            }

            if (obj.token != undefined) {
              this.tokenno = obj.token;
            } else {
              this.tokennumberflag = false;
              this.tokenno = "";
            }
            if (obj.bed_no != undefined) {
              this.Bed_no = obj.bed_no;
            } else {
              this.Bed_no = "";
            }

            if (obj.ward_name != undefined) {
              this.Ward_name = obj.ward_name;

            } else {
              this.Ward_name = "";
            }

            this.ipop = obj.pat_type;
            if (obj.admission_date != undefined) {
              this.admissiondate = obj.admission_date
            } else {
              this.admissiondate = ""
            }

            if (obj.admission_time != undefined) {
              this.admission_time = Time_Formate(obj.admission_time)
            } else {
              this.admission_time = ""
            }
            if (obj.payments != undefined) {
              this.paymentsarray = obj.payments;

            } else {
              this.paymentsarray = undefined

            }

            this.orderTime = obj.order_time != undefined ? Time_Formate(obj.order_time) : "";
            this.remaingBalance = obj.balance;
            this.paid = obj.paid_amount != undefined ? obj.paid_amount : "0.00";
            this.numToWords = this.paid == "0.00" ? " " : convertNumberToWords(this.paid, ipaddress.country_code);

            if (pharmacy_helper.getorderview().value == "past") {
              this.paytypreq = true;
              this.paymentpast_ord_array = [];

              if (this.getDataValue.hospitals[0].bill_pay_type_req == "1") {
                this.paymentpastorder = true
                this.paytypreqtp = false
                this.paytypreq=true

              } else {
                this.paymentpastorder = false
                this.paytypreqtp = true
                this.paytypreq=true
              }

              if (this.paid == "") {
                this.paid = "0.00";
              }
              this.actuval = parseFloat(obj.bill_amount).toFixed(2);
              if (obj.actual_amt != undefined) {
                this.totalValue1 = parseFloat(obj.actual_amt).toFixed(2);
              } else {
                obj.actual_amt = 0;
                this.totalValue1 = parseFloat(obj.actual_amt).toFixed(2);
              }
              if (obj.sponsor_due != undefined && obj.sponsor_due != "0.00") {
                this.duesponserflag = false
                this.duesponser = parseFloat(obj.sponsor_due).toFixed(2);
              }
              else {
                this.duesponserflag = true;
                obj.sponsor_due = 0.00
                this.duesponser = parseFloat(obj.sponsor_due).toFixed(2);
              }
              if (obj.gst_amt != undefined) {
                var gst = obj.gst_amt;
                this.GSTamount = gst;
              } else {
                gst = 0;
                this.GSTamount = gst;
              }
              if (obj.bill_amount != undefined) {
                this.bill_amount = parseFloat(obj.bill_amount).toFixed(2);
              } else {
                this.bill_amount = parseFloat(obj.bill_amount).toFixed(2);
              }

              if (this.totalValue1 == 0.00) {
                this.total_billflag = true
              } else {
                this.total_billflag = false
              }

              if (obj.rate_card != undefined && obj.rate_card != 1) {
                this.discType = obj.rate_card;
              }

              if (obj.disc_type != undefined && obj.disc_type != 1) {
                this.discType = obj.disc_type;
              }


              this.bill_amount = parseFloat(this.bill_amount).toFixed(2);
              this.totalValue1 = parseFloat(this.totalValue1).toFixed(2);
              this.totalAmt = parseFloat(obj.bill_amount).toFixed(2);
              this.remainingamt = parseFloat(obj.balance).toFixed(2);
              if (obj.payments != undefined) {
                if (obj.payments.length != 0)
                  for (var i = 0; i < obj.payments.length; i++) {
                    this.paymentpast_ord_array.push({
                      pay_desc: obj.payments[i].pay_desc,
                      amount: obj.payments[i].amount,
                      pay_id: obj.payments[i].pay_id,
                    })
                  }
              }
            }
            else if (pharmacy_helper.getorderview().value == "past_edit") {
             

              if (this.getDataValue.hospitals[0].bill_pay_type_req == "1") {
                this.paytypreq = true
                this.paytypreqtp = false
                this.paymentpastorder = true
              } else {
                this.paytypreq = false
                this.paymentpastorder = true
                this.paytypreqtp = true
              }

              if (this.paid == "") {
                this.paid = "0.00";
              }
              this.actuval = parseFloat(obj.bill_amount).toFixed(2);
              if (obj.actual_amt != undefined) {
                this.totalValue1 = parseFloat(obj.actual_amt).toFixed(2);
              } else {
                obj.actual_amt = 0;
                this.totalValue1 = parseFloat(obj.actual_amt).toFixed(2);
              }

              if (obj.bill_amount != undefined) {
                this.bill_amount = parseFloat(obj.bill_amount).toFixed(2);
              } else {
                this.bill_amount = parseFloat(obj.bill_amount).toFixed(2);
              }

              if (obj.gst_amt != undefined) {
                this.GSTamount = parseFloat(obj.gst_amt);
              } else {
                this.GSTamount = parseFloat(obj.gst_amt);
              }

              if (this.totalValue1 == 0.00) {
                this.total_billflag = true
              } else {
                this.total_billflag = false
              }
              this.bill_amount = parseFloat(this.bill_amount).toFixed(2);
              this.totalValue1 = parseFloat(this.totalValue1).toFixed(2);
              this.totalAmt = parseFloat(obj.bill_amount).toFixed(2);
              this.remainingamt = parseFloat(obj.balance).toFixed(2);


            }
            else if (pharmacy_helper.getorderview().value == "current") {
            
              this.total_billflag = false;
              this.discFlag = false;
              this.bill_amount = "0.00";
              this.totalValue1 = "0.00";
              this.totalAmt = "0.00";
              this.remainingamt = "0.00";
              this.remaingBalance = "0.00"
              this.prodDisc = 0.00;
              this.paymentpastorder = true;
              if (this.getDataValue.hospitals[0].bill_pay_type_req == "1") {
                this.paymentpastorder = true
                this.paytypreq=true
                this.paytypreqtp = false

              } else {
                this.paymentpastorder = true
                this.paytypreq=false
                this.paytypreqtp = true
              }
            }



          



            if (obj.pay_type != undefined) {
              this.payDes = obj.pay_type;
              this.getPaymentType()
              if (this.paytypreqtp == false) {
                this.payType = obj.pay_type;

              }
            }
            if (obj.drug_details != null) {
              var index = 0;
              for (var i = 0; i < obj.drug_details.length; i++) {
                var discount;
                if (obj.drug_details[i].discount != "0") {
                  discount = obj.drug_details[i].discount;
                } else {
                  discount = obj.drug_details[i].prod_disc != undefined ? obj.drug_details[i].prod_disc : "0";
                }
                var amtaftergst;
                if (pharmacy_helper.getorderview().AppFlow == "current_orders") {
                  if (discount != "") {
                    var value = parseInt(obj.drug_details[i].amount) - (parseInt(obj.drug_details[i].amount) * (parseInt(discount) / 100));
                    amtaftergst = (value + (value)).toFixed(2);
                  } else {
                    amtaftergst = parseInt(obj.drug_details[i].amount) + (parseInt(obj.drug_details[i].amount));
                  }
                } else {
                  amtaftergst = obj.drug_details[i].amount;
                }
                var price = parseFloat("0.00");
                var amount;
                if (pharmacy_helper.getorderview().value == "past" || pharmacy_helper.getorderview().value == "past_edit") {
                  price = obj.drug_details[i].price;
                  amount = amtaftergst;
                } else {
                  price = 0.00;
                  amount = 0.00;
                }

                if (obj.drug_details[i].expiry_date != undefined) {
                  var expdate = obj.drug_details[i].expiry_date.split("-");
                  obj.drug_details[i].expiry_date = expdate[1] + "-" + expdate[0];
                  console.log("1" + expdate[1]);
                  console.log("2" + expdate[0]);
                }
                var batchno;
                var rec_item_id;

                if (obj.drug_details[i].batchnos.length != 0 && obj.drug_details[i].batchnos != undefined) {
                  batchno = obj.drug_details[i].batchnos[0].batch_no
                } else {
                  batchno = ""
                }

                if (obj.drug_details[i].batchnos.length != 0 && obj.drug_details[i].batchnos != undefined) {
                  rec_item_id = obj.drug_details[i].batchnos[0].rec_item_id
                } else {
                  rec_item_id = ""
                }

                var hsn_no
                if (obj.drug_details[i].hsn_no != undefined) {
                  if (obj.drug_details[i].hsn_no != 0) {
                    hsn_no = obj.drug_details[i].hsn_no
                  } else {
                    hsn_no = ""
                  }
                } else {
                  hsn_no = ""
                }

                var tempDrugDetails = {
                  drug_id: obj.drug_details[i].drug_id,
                  hsn_no: hsn_no,
                  type_name: obj.drug_details[i].type_name,
                  drug_name: obj.drug_details[i].drug_name,
                  drug_disp_name: obj.drug_details[i].short_name + " " + obj.drug_details[i].drug_name,
                  quantity: obj.drug_details[i].quantity,
                  amount: parseFloat(amount).toFixed(2),
                  price: price,
                  discount: discount,
                  per_unit: obj.drug_details[i].per_unit,
                  prod_disc: obj.drug_details[i].prod_disc,
                  batch_no: batchno,
                  batchnos: obj.drug_details[i].batchnos,
                  cgst: obj.drug_details[i].cgst,
                  sgst: obj.drug_details[i].sgst,
                  batch_hide: this.orderType,
                  expiry_date: obj.drug_details[i].expiry_date != undefined ? obj.drug_details[i].expiry_date : '',
                  rec_item_id: rec_item_id,
                  index: index,
                  fromRetrivel: true,
                  rack_id: this.rackid,
                  bin_id: this.binId,
                  days: obj.drug_details[i].days,
                  daydur_txt: obj.drug_details[i].day_dur,
                  time_duration: obj.drug_details[i].time_duration,
                  intake: obj.drug_details[i].intake,
                  drug_intake: obj.drug_details[i].drug_intake,
                  show_intake: obj.drug_details[i].show_intake,
                  drug_typetxt: obj.drug_details[i].product_type_id,
                  morning: obj.drug_details[i].morning,
                  afternoon: obj.drug_details[i].afternoon,
                  evening: obj.drug_details[i].evening,
                  night: obj.drug_details[i].night,
                  ins_allow: obj.drug_details[i].ins_allow
                }

                // if (pharmacy_helper.getorderview().AppFlow != "current_orders") {

                // }
                this.getIntakeName(tempDrugDetails)
                console.log("temp drug details" + JSON.stringify(tempDrugDetails))
                this.drugDetailsArray.push(tempDrugDetails);
                this.insuranceDetailsArray.push(tempDrugDetails);
                index++;

                this.getItemPrices(tempDrugDetails);

              }


              var prec_tx = 0;
              for (var i = 0; i < this.drugDetailsArray.length; i++) {
                prec_tx = prec_tx + parseFloat(this.drugDetailsArray[i].amount)
              }
              this.finalAmount = 0;
              var x = prec_tx + parseFloat(this.finalAmount);
              x = Math.round(x * 100) / 100;
              this.totalAmount = x.toFixed(2);

              if (this.totalAmount == "NaN") {
                this.totalAmount = "0.00";
              }



              if (obj.disc_amt != undefined && obj.disc_amt != null && obj.disc_amt != "" && pharmacy_helper.getorderview().value != "past_edit") {
                this.prodDisc = obj.disc_amt;

                if (this.prodDisc != 0.00) {
                  this.discFlag = false;

                } else {
                  this.discFlag = true;
                }

                this.amountdisc = this.totalAmount - this.prodDisc;
                // this.totalValue1 = Math.round(this.totalAmount - this.prodDisc).toFixed(2);           
                this.actuval = (this.totalAmount - this.prodDisc).toFixed(2);
                //  this.totalValue1 = Math.round(parseFloat(this.totalValue1)).toFixed(2);
              }

              else if (pharmacy_helper.getorderview().value == "past_edit") {
                this.prodDisc = obj.disc_amt;
                this.discFlag = false;
                this.amountdisc = this.prodDisc;
                this.actuval = (this.totalAmount - this.prodDisc).toFixed(2);

              }


              else {



                this.amountdisc = 0.00;
                // this.totalValue1 = Math.round(parseFloat(this.totalAmount)).toFixed(2);

                this.actuval = parseFloat(this.totalAmount).toFixed(2);
                // this.totalValue1 = Math.round(parseFloat(this.totalValue1)).toFixed(2);
                this.discFlag = true;
              }
              this.remaingBalance = Math.round(parseFloat(this.remainingamt)).toFixed(2)
              // (parseFloat(this.totalValue1) - parseFloat(this.paid)).toFixed(2);



            }




            if (obj.description == "Store pickup") {
              this.storeDiv = false;
              this.delDiv = true;
              this.addDelivery = true;
              var someDate = new Date(obj.order_date);
              if (obj.validity_days != undefined) {
                someDate.setDate(someDate.getDate() + parseFloat(obj.validity_days));
              }
              var dateFormated = someDate.toISOString().substr(0, 10);
              var validdate = dateFormated.split('-');
              this.validityDays = validdate[2] + "-" + validdate[1] + "-" + validdate[0]
              this.availableTo = Time_Formate(obj.available_to);
            } else {
              this.addDelivery = false;
              this.storeDiv = true;
              if (obj.delivered_date != null) {
                var deldate = obj.delivered_date.split('-');
                var deldate_txt = deldate[2] + "-" + deldate[1] + "-" + deldate[0]
                var timeString = obj.delivered_time;
                var H = +timeString.substr(0, 2);
                var h = (H % 12) || 12;
                var ampm = H < 12 ? "AM" : "PM";
                timeString = h + timeString.substr(2, 3) + " " + ampm;
                this.deliverTime = timeString;
                this.deldate = deldate_txt;
                this.delDiv = false;
              }
              this.name = obj.name;
              this.address1 = obj.address2 != undefined ? this.address1 + "," + obj.address2 : this.address1;
              this.locationDesc = obj.location;
              this.cityDesc = obj.city_desc;
              this.stateDesc = obj.state_desc;
              this.zipcode = obj.zipcode;
              this.countryDesc = obj.country_desc != null && obj.country_desc != undefined ? "-" + obj.country_desc : "";
              this.contactNo = obj.contact_no;
            }
            this.hospName = obj.pharmacy_name;
            this.hospitalAddress = obj.phar_address2 != undefined ? obj.phar_address1 + "," + obj.phar_address2 : obj.phar_address1;
            this.hospCSZ = obj.location + ", " + obj.phar_city + " - " + obj.phar_zipcode;
            this.hospitalCount = obj.phar_state + ", " + obj.phar_country;
            this.hospTel = obj.phar_telephone != undefined ? obj.phar_telephone : "-";
            this.pharmaLogo = ipaddress.Ip_with_img_address + obj.bill_logo;
            this.billTemplate = obj.bill_template;
            console.log("this.drugDetailsArray" + JSON.stringify(this.drugDetailsArray));
          }


        });


  }

  getIntakeSession() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'prescription/dintypes/',
      { headers: headers })
      .subscribe(
        response => {

          var obj = response.json();
          console.log("GET SESSION S" + JSON.stringify(obj));
          this.getIntakeArray = obj.drug_intake;
          this.intake = "Thrice a day";
        },
        error => {
        }
      )
  }

  getIntakeName(drug_detailsarray) {


    var send_data = {
      product_id: drug_detailsarray.drug_id
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/gis', send_data,
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();

          console.log("this.getIntakeArray" + JSON.stringify(this.getIntakeArray));

          for (var i = 0; i < this.getIntakeArray.length; i++) {
            if (obj.intake == this.getIntakeArray[i].drug_int_id) {
              drug_detailsarray.intake_txt = this.getIntakeArray[i].description;
              drug_detailsarray.intakeid = this.getIntakeArray[i].drug_int_id;


              this.intake_id = this.getIntakeArray[i].drug_int_id;
            }
          }
          this.sesssionBaseChange(drug_detailsarray);
        })
  }

  languageChange() {
    if (this.language == "English") {
      this.translate.use('english');
      this.languagetype = true;
      // English
    }
    else if (this.language == "Tamil") {
      this.translate.use('tamil');

      this.languagetype = false;

    } else if (this.language == "Telugu") {
      this.translate.use('telugu');

      this.languagetype = false;
    } else if (this.language == "Malayalam") {
      this.translate.use('malayalam');
      this.languagetype = false;

    } else if (this.language == "Kannada") {
      this.translate.use('kannada');
      this.languagetype = false;

    } else if (this.language == "Oriya") {
      this.translate.use('oriya');
      this.languagetype = false;


    } else if (this.language == "Bengali") {
      this.translate.use('bangla');
      this.languagetype = false;

    } else if (this.language == "Hindi") {
      this.translate.use('hindi');
      this.languagetype = false;
    }
  }


  getPaymentType() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'bill/paytype',
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          console.log("payment type = " + JSON.stringify(obj))
          this.payTypeListArrray = obj.payments;
          this.paytypeList = obj.payments;
          this.payType1 = this.payTypeListArrray[0].pay_id;

          if (pharmacy_helper.getorderview().value == "current") {
            for (var i = 0; i < this.paytypeList.length; i++) {
              if (this.payDes == this.paytypeList[i].pay_desc) {
                this.payType = this.paytypeList[i].pay_id;
              }
            }
          }


          this.payDes = this.payTypeListArrray[0].pay_desc;
          this.getInsurers();
        },
        error => {
          this.toastr.error(Message_data.network);
        });
  }

  getInsurers() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'bill/gins',
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          this.insurerList = obj.insurers;
        },
        error => {
          this.toastr.error(Message_data.network);
        });
  }
  changePay() {
    document.getElementById("paytype")!.style.borderColor = '#2ca8fe';
    this.insurFlag = true;
    this.tranFlag = true;
    this.cardFlag = true;
    this.chequeFlag = true;
    this.payTypeListArrray.forEach(element => {
      if (element.pay_id == this.payType) {
        this.payDes = element.pay_desc;
      }
    });
    if (this.payType == "3") {
      this.insurFlag = false;
    } else if (this.payType == "4") {
      this.tranFlag = false;
    } else if (this.payType == "2") {
      this.tranFlag = false;
      this.cardFlag = false;
    } else if (this.payType == "5") {
      this.chequeFlag = false;
    } else {
      this.insurFlag = true;
    }
  }
  clearmedtype() {
    this.medType = null;
    this.drugType = undefined;
    this.medicineName = null;
    this.MedicineNameList = [];
  }
  clearmedname() {
    this.medicineName = null;
    this.medID = undefined;
    this.MedicineNameList = [];
  }
  getMedicineType(e) {
    var headers = new Headers();
    if (this.medType != null && this.medType != undefined && this.medType.length > 2) {
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/medtypes/',
        JSON.stringify({
          medicare_id: this.medicareName,
          medicine_name: this.medType
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            this.medicalPrescription = [];
            this.medicalPrescription = obj.med_details;
          },
          error => { });
    }
  }
  selectMedicineType(item) {
    this.medType = item.med_name;
    this.drugType = item.med_code;
    this.medicalPrescription = [];
  }
  changeMedicineName(e) {
    var senddetails = JSON.stringify({
      drug_name: e.target.value.toString(),
      pharmacy_id: this.pharmacyID,
      medicare_name: "1",
      type: "medicine"
    })
    if (e.target.value.toString() != null && e.target.value.toString().length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/nmeddetails/', senddetails,
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            if (obj.med_details != null) {
              this.MedicineNameArray = [];
              this.medicineArray = [];
              this.MedicneData = [];
              for (var i = 0; i < obj.med_details.length; i++) {
                var medname = obj.med_details[i].short_name + " " + obj.med_details[i].med_name
                this.MedicneData.push(medname);
                this.medicineArray.push({
                  med_id: obj.med_details[i].med_id,
                  med_name: obj.med_details[i].med_name,
                  med_type_id: obj.med_details[i].med_type_id,
                  type_name: obj.med_details[i].type_name,
                  short_name: obj.med_details[i].short_name,
                  short_form: obj.med_details[i].short_form,
                  show_short_form: obj.med_details[i].show_short_form,
                  show_intake: obj.med_details[i].show_intake,
                  medname: medname,
                  cgst: obj.med_details[i].cgst,
                  sgst: obj.med_details[i].sgst,
                })
              }
              this.MedicineNameArray = this.MedicneData.filter(function (this: any, el) {
                return el.toLowerCase().indexOf(e.target.value.toString().toLowerCase()) > -1;
              }.bind(this));
            }
          },
          error => { });
    } else {
      this.MedicineNameArray = [];
    }
  }
  selectMedicineItem(item, drug_detailsarray) {
    for (var i = 0; i < this.medicineArray.length; i++) {
      if (item == this.medicineArray[i].medname) {
        drug_detailsarray.drug_name = item;
        drug_detailsarray.drug_disp_name = this.medicineArray[i].short_name + " " + this.medicineArray[i].med_name;
        drug_detailsarray.drug_id = this.medicineArray[i].med_id;
        this.medicineName = this.medicineArray[i].med_name;
        drug_detailsarray.cgst = this.medicineArray[i].cgst;
        drug_detailsarray.sgst = this.medicineArray[i].sgst;
      }
    }
    this.getItemPrices(drug_detailsarray);
    drug_detailsarray.batcharray = [];
    drug_detailsarray.batch_no = "";
    drug_detailsarray.quantityDisable = true;
    this.MedicineNameArray = [];
  }
  getItemPrices(drug_detailsarray) {
    this.sendData = {
      pharma_id: this.pharmacyID,
      drug_ids: drug_detailsarray.drug_id
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/ipnopres/", JSON.stringify(this.sendData), { headers: headers }).subscribe(
      data => {

        var dataval = data.json();
        console.log("drugdata-price" + JSON.stringify(dataval))

        if (this.orderType == true) {
          if (dataval != undefined && dataval.length != 0) {
            // for (var j = 0; j < this.drugDetailsArray.length; j++) {
            // if (this.drugDetailsArray[j].drug_id == drug_detailsarray.drug_id) {
            drug_detailsarray.batcharray = [];
            var index = 0;
            for (var i = 0; i < dataval.pharma_price.length; i++) {
              var color = this.checkExpiryDate(dataval.pharma_price[i].expiry_date);
              this.drugArray.push({
                drug_id: dataval.pharma_price[i].pharma_price,
                drug_name: dataval.pharma_price[i].drug_name,
                price_txt: (dataval.pharma_price[i].price / dataval.pharma_price[i].per_unit).toFixed(2),
                per_unit: dataval.pharma_price[i].per_unit,
                batch_no: dataval.pharma_price[i].batch_no,
                quantity: dataval.pharma_price[i].avail_qty,
                discount: dataval.pharma_price[i].prod_disc,
                cgst: dataval.pharma_price[i].prod_cgst,
                sgst: dataval.pharma_price[i].prod_sgst,
                mrp: (dataval.pharma_price[i].price / dataval.pharma_price[i].per_unit).toFixed(2),
                sales_price: dataval.pharma_price[i].sales_price,
                cost_price: dataval.pharma_price[i].cost_price,
                expiry_date: dataval.pharma_price[i].expiry_date,
                rec_item_id: dataval.pharma_price[i].rec_item_id,
                index: index,
              });


              if (this.discountDatasArray.length != 0) {
                if (this.discountdesc == "cost plus gst") {
                  drug_detailsarray.price = (dataval.pharma_price[0].cost_price / dataval.pharma_price[0].per_unit).toFixed(2);
                }
                else if (this.discountdesc == "sales plus gst") {
                  drug_detailsarray.price = (dataval.pharma_price[0].sales_price / dataval.pharma_price[0].per_unit).toFixed(2);
                }
                else if (this.discountdesc == "MRP") {
                  drug_detailsarray.price = (dataval.pharma_price[0].price / dataval.pharma_price[0].per_unit).toFixed(2);
                } else {
                  drug_detailsarray.price = (dataval.pharma_price[0].price / dataval.pharma_price[0].per_unit).toFixed(2)
                }
              }

              drug_detailsarray.cgst = dataval.pharma_price[0].prod_cgst;
              drug_detailsarray.sgst = dataval.pharma_price[0].prod_sgst;
              drug_detailsarray.cost_price = dataval.pharma_price[0].cost_price;
              drug_detailsarray.sales_price = dataval.pharma_price[0].sales_price;
              drug_detailsarray.mrp = (dataval.pharma_price[0].price / dataval.pharma_price[0].per_unit).toFixed(2);
              drug_detailsarray.per_unit = dataval.pharma_price[0].per_unit;

              drug_detailsarray.batcharray.push({
                index: index,
                batch_no: dataval.pharma_price[i].batch_no,
                rec_item_id: dataval.pharma_price[i].rec_item_id,
                mrp: (dataval.pharma_price[i].price / dataval.pharma_price[i].per_unit).toFixed(2),
                quantity: dataval.pharma_price[i].avail_qty,
                expiry_date: dataval.pharma_price[i].expiry_date,
                color: color,

              });
              index++;
            }
            this.discountChange(this.discType);
            //   break;
            // }
            // }

            console.log("VERIFY DRUG PURCHASE " + JSON.stringify(this.drugDetailsArray))
          }
        }

      }, error => { }
    );

  }

  retrieveBatch(medpre) {
    this.medIDs = [];
    this.medIDs.push(medpre.drug_id);
    this.sendData = {
      pharma_id: this.pharmacyID,
      drug_ids: this.medIDs,
      batch_no: medpre.batch_no,
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/itemprice/", JSON.stringify(this.sendData), { headers: headers }).subscribe(
      data => {
        var obj = JSON.parse(data["_body"]);
        if (obj.pharma_price != undefined) {
          for (var i = 0; i < obj.pharma_price.length; i++) {
            for (var j = 0; j < this.drugDetailsArray.length; j++) {
              if (this.drugDetailsArray[j].drug_id == medpre.drug_id) {
                var rate_per_unit = (parseFloat(obj.pharma_price[i].price) / parseInt(obj.pharma_price[i].per_unit)).toFixed(2);
                this.drugDetailsArray[j].amt_bdgst = this.drugDetailsArray[j].quantity * parseFloat(rate_per_unit);
                this.drugDetailsArray[j].price = rate_per_unit;
                this.drugDetailsArray[j].discount = obj.pharma_price[i].discount != undefined ? obj.pharma_price[i].discount : "0";
                this.drugDetailsArray[j].cgst = obj.pharma_price[i].prod_cgst != undefined && obj.pharma_price[i].prod_cgst != "" ? obj.pharma_price[i].prod_cgst : "0";
                this.drugDetailsArray[j].sgst = obj.pharma_price[i].prod_sgst != undefined && obj.pharma_price[i].prod_sgst != "" ? obj.pharma_price[i].prod_sgst : "0";
                this.drugDetailsArray[j].rec_item_id = obj.pharma_price[i].rec_item_id;
                this.drugDetailsArray[j].expiry_date = obj.pharma_price[i].expiry_date;
                this.calculatePrice(medpre.drug_id, medpre.type_name, medpre.drug_name, medpre.quantity, rate_per_unit, medpre.per_unit, medpre.discount, medpre.index)
              }
            }
          }
        }
      })
  }

  clearQuantity() {
    this.qty = undefined;
  }

  isReadonly() { return true; }
  currentDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "adm/curdate", JSON.stringify({
      country: ipaddress.country_code,
    }),
      { headers: headers }).subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.todayDate = obj.current_date;
        },
      )
  }
  clearField(data) {
    for (var i = 0; i < this.drugDetailsArray.length; i++) {
      if (this.drugDetailsArray[i].drug_id == data.drug_id) {
        this.drugDetailsArray[i].quantity = "";
        break;
      }
    }
  }

  calculatePrice(drugid, typename, drugname, quantity, price, per_unit, discount, index) {
    if (quantity != "") {
      var priceval = parseFloat(price);
      var pricecal: any = priceval * quantity;
      if (discount != null) {
        var disc: any = (pricecal * (discount / 100)).toFixed(2);
      }
      var pricegst = parseFloat(pricecal) - parseFloat(disc);
      for (var i = 0; i < this.drugDetailsArray.length; i++) {
        if (this.checkindex == i) {
          this.inputquantity = parseInt(this.drugDetailsArray[i].quantity)
          this.batcharraybatchno = parseInt(this.drugDetailsArray[i].batch_no)
        }

        if (drugid == this.drugDetailsArray[i].drug_id) {
          if (index == this.drugDetailsArray[i].index) {
            this.drugDetailsArray[i].quantity = quantity;
            this.drugDetailsArray[i].amount = pricegst.toFixed(2);
            this.drugDetailsArray[i].price = price;
            this.drugDetailsArray[i].discount = discount;
            this.drugDetailsArray[i].amt_bdgst = pricecal;
          }
        }
      }

      var totamount = 0;
      for (var j = 0; j < this.drugDetailsArray.length; j++) {
        totamount += parseFloat(this.drugDetailsArray[j].amount);
      }
      //
      // this.totalValue1 = totamount.toFixed(2);

      this.actuval = totamount.toFixed(2);
      // this.totalValue1 =parseFloat(this.totalValue1).toFixed(2);

      this.remaingBalance = (parseFloat(this.totalValue1) - parseFloat(this.paid)).toFixed(2);
      //
      this.taxDetail = [];
      if (this.taxPerc != null) {
        for (var i = 0; i < this.taxPerc.length; i++) {
          var taxtxt = ((totamount * this.taxPerc[i].tax) / 100).toFixed(2);
          this.taxDetail.push({
            tax_des: this.taxPerc[i].tax_des,
            tax_id: taxtxt,
          })
        }
      }
      this.finalAmount = 0;
      for (var i = 0; i < this.taxDetail.length; i++) {
        this.amount = this.taxDetail[i].tax_id;
        this.finalAmount = (parseFloat(this.finalAmount) + parseFloat(this.amount)).toFixed(2);
      }
      var x = totamount + parseFloat(this.finalAmount);
      x = Math.round(x * 100) / 100;
      this.totalAmount = x.toFixed(2);
    }
    // this.calculateDiscount();
  }
  public nextbatchquantity;
  public singledrugbactch;
  public checknextbatchquantity;

  sesssionBaseChange(drug_detailsarray) {

    console.log(" session BaseChange == " + JSON.stringify(drug_detailsarray))

    this.alternatsession = true;




    for (var j = 0; j < this.getIntakeArray.length; j++) {
      if (drug_detailsarray.intake == this.getIntakeArray[j].drug_int_id) {

        drug_detailsarray.drug_typetxt = this.getIntakeArray[j].description

      }
    }


    if (drug_detailsarray.intake_txt == "Morning only" && drug_detailsarray.show_intake == "1") {
      this.intakesession = "Morning only"
      this.sessionhide = false;
      this.sessionhrs = true;
      if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
        || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
        || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
        || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
        || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
        || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
        || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
        || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
        drug_detailsarray.morning = "1";
        drug_detailsarray.afternoon = "0";
        drug_detailsarray.evening = "0";
        drug_detailsarray.night = "0";

      } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
        drug_detailsarray.morning = "5";
        drug_detailsarray.afternoon = "0";
        drug_detailsarray.evening = "0";
        drug_detailsarray.night = "0";
      }

      drug_detailsarray.morning_dis = false;
      drug_detailsarray.afternoon_dis = true;
      drug_detailsarray.even_dis = true;
      drug_detailsarray.ngt_dis = true;

      drug_detailsarray.drug_detailsarray = true;

      this.morID = false;
      this.afterID = false;
      this.eveID = true;

      drug_detailsarray.eve_eventxt = true;
      drug_detailsarray.night_eventxt = false;
      this.daydurationShow = false;

      drug_detailsarray.drug_detailsarray = true;
      drug_detailsarray.even_dis = true;
      drug_detailsarray.ngt_dis = true;

      drug_detailsarray.mor_lable = false;
      drug_detailsarray.after_eventxt = false;
      drug_detailsarray.quan_lable = true;

      drug_detailsarray.morning_req = true;
      drug_detailsarray.afternoon_req = false;
      drug_detailsarray.evening_req = false;
      drug_detailsarray.night_req = false;

      drug_detailsarray.intake_show = false;
      if (this.afterfoodText != "With food") {
        drug_detailsarray.dure_show = false;
      }
      drug_detailsarray.morn_eventxt = false;
    } else if (drug_detailsarray.intake_txt == "Afternoon only" && drug_detailsarray.show_intake == "1") {
      this.sessionhide = false;
      this.sessionhrs = true;


      this.intakesession = "Afternoon only"
      if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
        || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
        || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
        || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
        || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
        || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
        || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
        || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
        drug_detailsarray.morning = "0";
        drug_detailsarray.afternoon = "1";
        drug_detailsarray.evening = "0";
        drug_detailsarray.night = "0";

      } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
        drug_detailsarray.morning = "0";
        drug_detailsarray.afternoon = "5";
        drug_detailsarray.evening = "0";
        drug_detailsarray.night = "0";
      }

      drug_detailsarray.afternoon_dis = false;
      drug_detailsarray.morning_dis = true;
      drug_detailsarray.even_dis = true;
      drug_detailsarray.ngt_dis = true;

      this.morID = false;
      this.afterID = false;
      this.eveID = true;

      drug_detailsarray.eve_eventxt = true;
      drug_detailsarray.night_eventxt = false;
      this.daydurationShow = false;

      drug_detailsarray.mor_lable = false;
      drug_detailsarray.after_eventxt = false;
      drug_detailsarray.quan_lable = true;

      drug_detailsarray.morning_req = false;
      drug_detailsarray.afternoon_req = true;
      drug_detailsarray.evening_req = false;
      drug_detailsarray.night_req = true;

      drug_detailsarray.intake_show = false;
      if (this.afterfoodText != "With food") {
        drug_detailsarray.dure_show = false;
      }
      drug_detailsarray.morn_eventxt = false;

    } else if (drug_detailsarray.intake_txt == "Evening only" && drug_detailsarray.show_intake == "1") {
      this.sessionhide = false;
      this.sessionhrs = true;

      this.intakesession = "Evening only"
      if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
        || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
        || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
        || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
        || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
        || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
        || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
        || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
        drug_detailsarray.morning = "0";
        drug_detailsarray.afternoon = "0";
        drug_detailsarray.evening = "1";
        drug_detailsarray.night = "0";

      }
      else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
        drug_detailsarray.morning = "0";
        drug_detailsarray.afternoon = "0";
        drug_detailsarray.evening = "5";
        drug_detailsarray.night = "0";
      }

      drug_detailsarray.morning_dis = true;
      drug_detailsarray.afternoon_dis = true;
      drug_detailsarray.even_dis = false;
      drug_detailsarray.ngt_dis = true;

      this.morID = false;
      this.afterID = false;
      this.eveID = true;

      drug_detailsarray.eve_eventxt = false;
      drug_detailsarray.night_eventxt = true;
      this.daydurationShow = false;

      drug_detailsarray.mor_lable = false;
      drug_detailsarray.after_eventxt = false;
      drug_detailsarray.quan_lable = true;

      drug_detailsarray.morning_req = false;
      drug_detailsarray.afternoon_req = false;
      drug_detailsarray.evening_req = true;
      drug_detailsarray.night_req = false;

      drug_detailsarray.intake_show = false;
      if (this.afterfoodText != "With food") {
        drug_detailsarray.dure_show = false;
      }
      drug_detailsarray.morn_eventxt = false;

    }
    else if (drug_detailsarray.intake_txt == "Night only" && drug_detailsarray.show_intake == "1") {
      this.sessionhide = false;
      this.sessionhrs = true;

      this.intakesession = "Night only"
      if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
        || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
        || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
        || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
        || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
        || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
        || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
        || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
        drug_detailsarray.morning = "0";
        drug_detailsarray.afternoon = "0";
        drug_detailsarray.evening = "0";
        drug_detailsarray.night = "1";

      } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
        drug_detailsarray.morning = "0";
        drug_detailsarray.afternoon = "0";
        drug_detailsarray.evening = "0";
        drug_detailsarray.night = "5";
      }

      drug_detailsarray.afternoon_dis = true;
      drug_detailsarray.morning_dis = true;
      drug_detailsarray.even_dis = true;
      drug_detailsarray.ngt_dis = false;

      this.morID = false;
      this.afterID = false;
      this.eveID = true;

      drug_detailsarray.eve_eventxt = true;
      drug_detailsarray.night_eventxt = false;
      this.daydurationShow = false;

      drug_detailsarray.morning_req = false;
      drug_detailsarray.afternoon_req = false;
      drug_detailsarray.evening_req = false;
      drug_detailsarray.night_req = true;

      drug_detailsarray.mor_lable = false;
      drug_detailsarray.after_eventxt = false;
      drug_detailsarray.quan_lable = true;
      drug_detailsarray.intake_show = false;

      if (this.afterfoodText != "With food") {
        drug_detailsarray.dure_show = false;
      }
      drug_detailsarray.morn_eventxt = false;

    } else if (drug_detailsarray.intake_txt == "Morning & Afternoon" && drug_detailsarray.show_intake == "1") {

      this.sessionhide = false;
      this.sessionhrs = true;
      this.intakesession = "Morning & Afternoon"
      if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
        || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
        || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
        || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
        || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
        || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
        || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
        || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
        drug_detailsarray.morning = "1";
        drug_detailsarray.afternoon = "1";
        drug_detailsarray.evening = "0";
        drug_detailsarray.night = "0";

      } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
        drug_detailsarray.morning = "5";
        drug_detailsarray.afternoon = "5";
        drug_detailsarray.evening = "0";
        drug_detailsarray.night = "0";
      }

      drug_detailsarray.morning_dis = false;
      drug_detailsarray.afternoon_dis = false;
      drug_detailsarray.even_dis = true;
      drug_detailsarray.ngt_dis = true;

      this.morID = false;
      this.afterID = false;
      this.eveID = true;

      drug_detailsarray.eve_eventxt = true;
      drug_detailsarray.night_eventxt = false;
      this.daydurationShow = false;

      drug_detailsarray.morning_req = true;
      drug_detailsarray.afternoon_req = true;
      drug_detailsarray.evening_req = false;
      drug_detailsarray.night_req = false;

      drug_detailsarray.mor_lable = false;
      drug_detailsarray.after_eventxt = false;
      drug_detailsarray.quan_lable = true;
      drug_detailsarray.intake_show = false;

      if (this.afterfoodText != "With food") {
        drug_detailsarray.dure_show = false;
      }
      drug_detailsarray.morn_eventxt = false;

    }
    else if (drug_detailsarray.intake_txt == "Morning & Evening" && drug_detailsarray.show_intake == "1") {

      this.sessionhide = false;
      this.sessionhrs = true;
      this.intakesession = "Morning & Evening"
      if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
        || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
        || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
        || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
        || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
        || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
        || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
        || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
        drug_detailsarray.morning = "1";
        drug_detailsarray.afternoon = "0";
        drug_detailsarray.evening = "1";
        drug_detailsarray.night = "0";

      } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
        drug_detailsarray.morning = "5";
        drug_detailsarray.afternoon = "0";
        drug_detailsarray.evening = "5";
        drug_detailsarray.night = "0";
      }

      drug_detailsarray.morning_dis = false;
      drug_detailsarray.afternoon_dis = true;
      drug_detailsarray.even_dis = false;
      drug_detailsarray.ngt_dis = true;

      this.morID = false;
      this.afterID = false;
      this.eveID = true;

      drug_detailsarray.eve_eventxt = false;
      drug_detailsarray.night_eventxt = true;
      this.daydurationShow = false;

      drug_detailsarray.morning_req = true;
      drug_detailsarray.afternoon_req = false;
      drug_detailsarray.evening_req = true;
      drug_detailsarray.night_req = false;

      drug_detailsarray.mor_lable = false;
      drug_detailsarray.after_eventxt = false;
      drug_detailsarray.quan_lable = true;
      drug_detailsarray.intake_show = false;

      if (this.afterfoodText != "With food") {
        drug_detailsarray.dure_show = false;
      }
      drug_detailsarray.morn_eventxt = false;

    }
    else if (drug_detailsarray.intake_txt == "Morning & Night" && drug_detailsarray.show_intake == "1") {

      this.sessionhide = false;
      this.sessionhrs = true;
      this.intakesession = "Morning & Night"
      if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
        || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
        || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
        || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
        || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
        || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
        || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
        || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
        drug_detailsarray.morning = "1";
        drug_detailsarray.afternoon = "0";
        drug_detailsarray.evening = "0";
        drug_detailsarray.night = "1";

      } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
        drug_detailsarray.morning = "5";
        drug_detailsarray.afternoon = "0";
        drug_detailsarray.evening = "0";
        drug_detailsarray.night = "5";
      }

      drug_detailsarray.morning_dis = false;
      drug_detailsarray.afternoon_dis = true;
      drug_detailsarray.even_dis = true;
      drug_detailsarray.ngt_dis = false;

      this.morID = false;
      this.afterID = false;
      this.eveID = true;

      drug_detailsarray.eve_eventxt = true;
      drug_detailsarray.night_eventxt = false;
      this.daydurationShow = false;

      drug_detailsarray.morning_req = true;
      drug_detailsarray.afternoon_req = false;
      drug_detailsarray.evening_req = false;
      drug_detailsarray.night_req = true;

      drug_detailsarray.mor_lable = false;
      drug_detailsarray.after_eventxt = false;
      drug_detailsarray.quan_lable = true;
      drug_detailsarray.intake_show = false;

      if (this.afterfoodText != "With food") {
        drug_detailsarray.dure_show = false;
      }
      drug_detailsarray.morn_eventxt = false;

    }
    else if (drug_detailsarray.intake_txt == "Afternoon & Night" && drug_detailsarray.show_intake == "1") {

      this.sessionhide = false;
      this.sessionhrs = true;
      this.intakesession = "Afternoon & Night"
      if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
        || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
        || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
        || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
        || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
        || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
        || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
        || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
        drug_detailsarray.morning = "0";
        drug_detailsarray.afternoon = "1";
        drug_detailsarray.evening = "0";
        drug_detailsarray.night = "1";
      } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
        drug_detailsarray.morning = "0";
        drug_detailsarray.afternoon = "5";
        drug_detailsarray.evening = "0";
        drug_detailsarray.night = "5";
      }

      drug_detailsarray.morning_dis = true;
      drug_detailsarray.afternoon_dis = false;
      drug_detailsarray.even_dis = true;
      drug_detailsarray.ngt_dis = false;

      this.morID = false;
      this.afterID = false;
      this.eveID = true;

      drug_detailsarray.eve_eventxt = true;
      drug_detailsarray.night_eventxt = false;
      this.daydurationShow = false;

      drug_detailsarray.morning_req = false;
      drug_detailsarray.afternoon_req = true;
      drug_detailsarray.evening_req = false;
      drug_detailsarray.night_req = true;

      drug_detailsarray.mor_lable = false;
      drug_detailsarray.after_eventxt = false;
      drug_detailsarray.quan_lable = true;
      drug_detailsarray.intake_show = false;

      if (this.afterfoodText != "With food") {
        drug_detailsarray.dure_show = false;
      }
      drug_detailsarray.morn_eventxt = false;

    }
    else if (drug_detailsarray.intake_txt == "Afternoon & Evening" && drug_detailsarray.show_intake == "1") {
      this.sessionhide = false;
      this.sessionhrs = true;
      this.intakesession = "Afternoon & Evening"
      if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
        || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
        || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
        || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
        || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
        || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
        || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
        || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
        drug_detailsarray.morning = "0";
        drug_detailsarray.afternoon = "1";
        drug_detailsarray.evening = "1";
        drug_detailsarray.night = "0";

      } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
        drug_detailsarray.morning = "0";
        drug_detailsarray.afternoon = "5";
        drug_detailsarray.evening = "5";
        drug_detailsarray.night = "";
      }

      drug_detailsarray.morning_dis = true;
      drug_detailsarray.afternoon_dis = false;
      drug_detailsarray.even_dis = false;
      drug_detailsarray.ngt_dis = true;

      this.morID = false;
      this.afterID = false;
      this.eveID = true;

      drug_detailsarray.eve_eventxt = false;
      drug_detailsarray.night_eventxt = true;
      this.daydurationShow = false;

      drug_detailsarray.morning_req = false;
      drug_detailsarray.afternoon_req = true;
      drug_detailsarray.evening_req = true;
      drug_detailsarray.night_req = false;

      drug_detailsarray.mor_lable = false;
      drug_detailsarray.after_eventxt = false;
      drug_detailsarray.quan_lable = true;
      drug_detailsarray.intake_show = false;

      if (this.afterfoodText != "With food") {
        drug_detailsarray.dure_show = false;
      }
      drug_detailsarray.morn_eventxt = false;

    }
    else if (drug_detailsarray.intake_txt == "Morning,Afternoon & Evening" && drug_detailsarray.show_intake == "1") {
      if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
        || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
        || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
        || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
        || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
        || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
        || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
        || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
        drug_detailsarray.morning = "1";
        drug_detailsarray.afternoon = "1";
        drug_detailsarray.evening = "1";
        drug_detailsarray.night = "0";

      } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
        drug_detailsarray.morning = "5";
        drug_detailsarray.afternoon = "5";
        drug_detailsarray.evening = "5";
        drug_detailsarray.night = "";
      }

      drug_detailsarray.morning_dis = false;
      drug_detailsarray.afternoon_dis = false;
      drug_detailsarray.even_dis = false;
      drug_detailsarray.ngt_dis = true;

      this.morID = false;
      this.afterID = false;
      this.eveID = true;

      drug_detailsarray.eve_eventxt = false;
      drug_detailsarray.night_eventxt = true;
      this.daydurationShow = false;

      drug_detailsarray.morning_req = true;
      drug_detailsarray.afternoon_req = true;
      drug_detailsarray.evening_req = true;
      drug_detailsarray.night_req = false;

      drug_detailsarray.mor_lable = false;
      drug_detailsarray.after_eventxt = false;
      drug_detailsarray.quan_lable = true;
      drug_detailsarray.intake_show = false;

      if (this.afterfoodText != "With food") {
        drug_detailsarray.dure_show = false;
      }
      drug_detailsarray.morn_eventxt = false;

    }
    else if (drug_detailsarray.drug_intake == "7" || (drug_detailsarray.intake_txt == "Thrice a day" && drug_detailsarray.show_intake == "1")) {
      this.intakesession = "Morning only"
      if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
        || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
        || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
        || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
        || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
        || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
        || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
        || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
        drug_detailsarray.morning = "1";
        drug_detailsarray.afternoon = "1";
        drug_detailsarray.evening = "0";
        drug_detailsarray.night = "1";

      } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
        drug_detailsarray.morning = "5";
        drug_detailsarray.afternoon = "5";
        drug_detailsarray.evening = "0";
        drug_detailsarray.night = "5";
      }

      drug_detailsarray.morning_dis = false;
      drug_detailsarray.afternoon_dis = false;
      drug_detailsarray.even_dis = true;
      drug_detailsarray.ngt_dis = false;

      this.morID = false;
      this.afterID = false;
      this.eveID = true;

      drug_detailsarray.eve_eventxt = true;
      drug_detailsarray.night_eventxt = false;
      this.daydurationShow = false;

      drug_detailsarray.morning_req = true;
      drug_detailsarray.afternoon_req = true;
      drug_detailsarray.evening_req = false;
      drug_detailsarray.night_req = true;

      drug_detailsarray.mor_lable = false;
      drug_detailsarray.after_eventxt = false;
      drug_detailsarray.quan_lable = true;
      drug_detailsarray.intake_show = false;

      if (this.afterfoodText != "With food") {
        drug_detailsarray.dure_show = false;
      }
      drug_detailsarray.morn_eventxt = false;


    }
    else if (drug_detailsarray.intake_txt == "Morning,Afternoon & Night" && drug_detailsarray.show_intake == "1") {

      if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
        || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
        || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
        || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
        || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
        || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
        || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
        || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
        drug_detailsarray.morning = "1";
        drug_detailsarray.afternoon = "1";
        drug_detailsarray.evening = "0";
        drug_detailsarray.night = "1";

      } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
        drug_detailsarray.morning = "5";
        drug_detailsarray.afternoon = "5";
        drug_detailsarray.evening = "0";
        drug_detailsarray.night = "5";
      }

      drug_detailsarray.morning_dis = false;
      drug_detailsarray.afternoon_dis = false;
      drug_detailsarray.even_dis = true;
      drug_detailsarray.ngt_dis = false;

      this.morID = false;
      this.afterID = false;
      this.eveID = true;

      drug_detailsarray.eve_eventxt = true;
      drug_detailsarray.night_eventxt = false;
      this.daydurationShow = false;

      drug_detailsarray.morning_req = true;
      drug_detailsarray.afternoon_req = true;
      drug_detailsarray.evening_req = false;
      drug_detailsarray.night_req = true;

      drug_detailsarray.mor_lable = false;
      drug_detailsarray.after_eventxt = false;
      drug_detailsarray.quan_lable = true;
      drug_detailsarray.intake_show = false;

      if (this.afterfoodText != "With food") {
        drug_detailsarray.dure_show = false;
      }
      drug_detailsarray.morn_eventxt = false;

    }
    else if (drug_detailsarray.intake_txt == "Morning,Afternoon,Evening & Night" && drug_detailsarray.show_intake == "1") {
      if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
        || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
        || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
        || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
        || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
        || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
        || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
        || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
        drug_detailsarray.morning = "1";
        drug_detailsarray.afternoon = "1";
        drug_detailsarray.evening = "1";
        drug_detailsarray.night = "1";

      } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
        drug_detailsarray.morning = "5";
        drug_detailsarray.afternoon = "5";
        drug_detailsarray.evening = "5";
        drug_detailsarray.night = "5";
      }

      drug_detailsarray.morning_dis = false;
      drug_detailsarray.afternoon_dis = false;
      drug_detailsarray.even_dis = false;
      drug_detailsarray.ngt_dis = false;

      this.morID = false;
      this.afterID = false;
      this.eveID = false;

      drug_detailsarray.eve_eventxt = false;
      drug_detailsarray.night_eventxt = false;
      this.daydurationShow = false;

      drug_detailsarray.morning_req = true;
      drug_detailsarray.afternoon_req = true;
      drug_detailsarray.evening_req = true;
      drug_detailsarray.night_req = true;

      drug_detailsarray.mor_lable = false;
      drug_detailsarray.after_eventxt = false;
      drug_detailsarray.quan_lable = true;
      drug_detailsarray.intake_show = false;

      if (this.afterfoodText != "With food") {
        drug_detailsarray.dure_show = false;
      }
      drug_detailsarray.morn_eventxt = false;

    }
    else if (drug_detailsarray.intake_txt == "Four times a day" && drug_detailsarray.show_intake == "1") {
      this.intakesession = "Four times a day"
      if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
        || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
        || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
        || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
        || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
        || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
        || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
        || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
        drug_detailsarray.morning = "1";
        drug_detailsarray.afternoon = "1";
        drug_detailsarray.evening = "1";
        drug_detailsarray.night = "1";

      } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
        drug_detailsarray.morning = "5";
        drug_detailsarray.afternoon = "5";
        drug_detailsarray.evening = "5";
        drug_detailsarray.night = "5";
      }

      drug_detailsarray.morning_dis = false;
      drug_detailsarray.afternoon_dis = false;
      drug_detailsarray.even_dis = false;
      drug_detailsarray.ngt_dis = false;

      this.morID = false;
      this.afterID = false;
      this.eveID = false;

      drug_detailsarray.eve_eventxt = false;
      drug_detailsarray.night_eventxt = false;
      this.daydurationShow = false;

      drug_detailsarray.morning_req = true;
      drug_detailsarray.afternoon_req = true;
      drug_detailsarray.evening_req = true;
      drug_detailsarray.night_req = true;

      drug_detailsarray.mor_lable = false;
      drug_detailsarray.after_eventxt = false;
      drug_detailsarray.quan_lable = true;
      drug_detailsarray.intake_show = false;

      if (this.afterfoodText != "With food") {
        drug_detailsarray.dure_show = false;
      }
      drug_detailsarray.morn_eventxt = false;

    }
    else if (drug_detailsarray.intake_txt == "Afternoon,Evening & Night" && drug_detailsarray.show_intake == "1") {
      if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
        || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
        || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
        || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
        || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
        || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
        || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
        || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
        drug_detailsarray.morning = "0";
        drug_detailsarray.afternoon = "1";
        drug_detailsarray.evening = "1";
        drug_detailsarray.night = "1";

      } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
        drug_detailsarray.morning = "0";
        drug_detailsarray.afternoon = "5";
        drug_detailsarray.evening = "5";
        drug_detailsarray.night = "5";
      }

      drug_detailsarray.morning_dis = true;
      drug_detailsarray.afternoon_dis = false;
      drug_detailsarray.even_dis = false;
      drug_detailsarray.ngt_dis = false;

      this.morID = false;
      this.afterID = false;
      this.eveID = false;

      drug_detailsarray.eve_eventxt = false;
      drug_detailsarray.night_eventxt = false;
      this.daydurationShow = false;

      drug_detailsarray.morning_req = false;
      drug_detailsarray.afternoon_req = true;
      drug_detailsarray.evening_req = true;
      drug_detailsarray.night_req = true;

      drug_detailsarray.mor_lable = false;
      drug_detailsarray.after_eventxt = false;
      drug_detailsarray.quan_lable = true;
      drug_detailsarray.intake_show = false;

      if (this.afterfoodText != "With food") {
        drug_detailsarray.dure_show = false;
      }
      drug_detailsarray.morn_eventxt = false;

    }
    else if (drug_detailsarray.intake_txt == "Evening & Night" && drug_detailsarray.show_intake == "1") {
      this.intakesession = "Evening & Night"
      this.sessionhide = false;
      this.sessionhrs = true;
      if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
        || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
        || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
        || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
        || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
        || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
        || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
        || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
        drug_detailsarray.morning = "0";
        drug_detailsarray.afternoon = "0";
        drug_detailsarray.evening = "1";
        drug_detailsarray.night = "1";

      } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
        drug_detailsarray.morning = "0";
        drug_detailsarray.afternoon = "0";
        drug_detailsarray.evening = "5";
        drug_detailsarray.night = "5";
      }

      drug_detailsarray.morning_dis = true;
      drug_detailsarray.afternoon_dis = true;
      drug_detailsarray.even_dis = false;
      drug_detailsarray.ngt_dis = false;

      this.morID = false;
      this.afterID = false;
      this.eveID = false;

      drug_detailsarray.eve_eventxt = false;
      drug_detailsarray.night_eventxt = false;
      this.daydurationShow = false;

      drug_detailsarray.morning_req = false;
      drug_detailsarray.afternoon_req = false;
      drug_detailsarray.evening_req = true;
      drug_detailsarray.night_req = true;

      drug_detailsarray.mor_lable = false;
      drug_detailsarray.after_eventxt = false;
      drug_detailsarray.quan_lable = true;
      drug_detailsarray.intake_show = false;

      if (this.afterfoodText != "With food") {
        drug_detailsarray.dure_show = false;
      }
      drug_detailsarray.morn_eventxt = false;

    }
    else if ((drug_detailsarray.intake_txt == "Every 6 hours" || drug_detailsarray.intake_txt == "Every 8 hours"
      || drug_detailsarray.intake_txt == "Every 12 hours") && drug_detailsarray.show_intake == "1") {
      this.sessionhide = true;
      this.sessionhrs = false;
      if (drug_detailsarray.intake_txt == "Every 6 hours") {
        this.intakesession = "Every 6 hours";
      }
      else if (drug_detailsarray.intake_txt == "Every 8 hours") {
        this.intakesession = "Every 8 hours";
      }
      else if (drug_detailsarray.intake_txt == "Every 12 hours") {
        this.intakesession = "Every 12 hours";
      } else {
        this.intakesession = "";
      }
      if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
        || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
        || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
        || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
        || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
        || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
        || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
        || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
        drug_detailsarray.morning = "1";
        drug_detailsarray.afternoon = "0";
        drug_detailsarray.evening = "0";
        drug_detailsarray.night = "0";

      } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
        drug_detailsarray.morning = "5";
        drug_detailsarray.afternoon = "0";
        drug_detailsarray.evening = "0";
        drug_detailsarray.night = "0";
      }

      drug_detailsarray.quan_lable = false;
      drug_detailsarray.morning_dis = false;

      drug_detailsarray.afternoon_dis = true;
      drug_detailsarray.even_dis = true;
      drug_detailsarray.ngt_dis = true;


      this.morID = true;
      this.afterID = true;
      this.eveID = true;

      drug_detailsarray.eve_eventxt = true;
      drug_detailsarray.night_eventxt = true;
      this.daydurationShow = false;

      drug_detailsarray.mor_lable = true;
      drug_detailsarray.after_eventxt = true;
      drug_detailsarray.morning_req = true;
      drug_detailsarray.intake_show = false;
      if (this.afterfoodText != "With food") {
        drug_detailsarray.dure_show = false;
      }
      drug_detailsarray.morn_eventxt = false;

    }
    else if (drug_detailsarray.intake_txt == "Stat") {
      this.intakesession = "stat";
      this.sessionhide = true;
      this.sessionhrs = false;
      if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
        || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
        || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
        || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
        || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
        || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
        || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
        || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
        drug_detailsarray.morning = "1";
        drug_detailsarray.afternoon = "0";
        drug_detailsarray.evening = "0";
        drug_detailsarray.night = "0";

      } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
        drug_detailsarray.morning = "5";
        drug_detailsarray.afternoon = "0";
        drug_detailsarray.evening = "0";
        drug_detailsarray.night = "0";
      }

      drug_detailsarray.quan_lable = false;

      drug_detailsarray.morning_dis = false;
      drug_detailsarray.afternoon_dis = true;
      drug_detailsarray.even_dis = true;
      drug_detailsarray.ngt_dis = true;

      this.morID = true;
      this.afterID = true;
      this.eveID = true;
      drug_detailsarray.intake_show = true;
      drug_detailsarray.dure_show = true;

      drug_detailsarray.eve_eventxt = true;
      drug_detailsarray.night_eventxt = true;
      this.daydurationShow = true;

      drug_detailsarray.mor_lable = true;
      drug_detailsarray.after_eventxt = true;
      drug_detailsarray.morning_req = true;
      drug_detailsarray.morn_eventxt = false;

    }
    else if (drug_detailsarray.intake_txt == "Once a day") {

      this.intakesession = "Once a day"
      this.sessionhide = true;
      this.sessionhrs = false;
      if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
        || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
        || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
        || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
        || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
        || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
        || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
        || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
        drug_detailsarray.morning = "1";
        drug_detailsarray.afternoon = "0";
        drug_detailsarray.evening = "0";
        drug_detailsarray.night = "0";

      } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
        drug_detailsarray.morning = "5";
        drug_detailsarray.afternoon = "0";
        drug_detailsarray.evening = "0";
        drug_detailsarray.night = "0";
      }

      drug_detailsarray.quan_lable = false;

      drug_detailsarray.morning_dis = false;
      drug_detailsarray.afternoon_dis = true;
      drug_detailsarray.even_dis = true;
      drug_detailsarray.ngt_dis = true;

      this.morID = true;
      this.afterID = true;
      this.eveID = true;

      drug_detailsarray.eve_eventxt = true;
      drug_detailsarray.night_eventxt = true;
      this.daydurationShow = false;
      drug_detailsarray.mor_lable = true;
      drug_detailsarray.after_eventxt = true;
      drug_detailsarray.morning_req = true;
      drug_detailsarray.intake_show = false;
      if (this.afterfoodText != "With food") {
        drug_detailsarray.dure_show = false;
      }
      drug_detailsarray.morn_eventxt = false;

    }
    else if (drug_detailsarray.intake_txt == "SOS") {
      this.intakesession = "SOS"
      this.sessionhide = true;
      this.sessionhrs = false;
      if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
        || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
        || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
        || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
        || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
        || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
        || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
        || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
        drug_detailsarray.morning = "1";
        drug_detailsarray.afternoon = "0";
        drug_detailsarray.evening = "0";
        drug_detailsarray.night = "0";

      } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
        drug_detailsarray.morning = "5";
        drug_detailsarray.afternoon = "0";
        drug_detailsarray.evening = "0";
        drug_detailsarray.night = "0";


      } else {
        drug_detailsarray.morning = "0";
        drug_detailsarray.afternoon = "0";
        drug_detailsarray.evening = "0";
        drug_detailsarray.night = "0";
      }

      drug_detailsarray.quan_lable = false;

      this.morID = true;
      this.afterID = true;
      this.eveID = true;

      drug_detailsarray.eve_eventxt = true;
      drug_detailsarray.night_eventxt = true;
      this.daydurationShow = false;

      drug_detailsarray.mor_lable = true;
      drug_detailsarray.after_eventxt = true;
      drug_detailsarray.morning_req = true;
      drug_detailsarray.intake_show = false;

      drug_detailsarray.morning_dis = false;
      drug_detailsarray.afternoon_dis = true;
      drug_detailsarray.even_dis = true;
      drug_detailsarray.ngt_dis = true;

      if (this.afterfoodText != "With food") {
        drug_detailsarray.dure_show = false;
      }
      drug_detailsarray.morn_eventxt = false;

    }
    else if (drug_detailsarray.intake_txt == "Alternative day" || drug_detailsarray.intake_txt == "Once a week"
      || drug_detailsarray.intake_txt == "Once in two weeks" || drug_detailsarray.intake_txt == "Once a month") {
      this.alternatsession = false;
      this.sessionhide = false;
      this.sessionhrs = true;
      if (drug_detailsarray.intake_txt == "Alternative day") {
        this.intakesession = "Alternative day";
      }
      else if (drug_detailsarray.intake_txt == "Once a week") {
        this.intakesession = "Once a week";
      }
      else if (drug_detailsarray.intake_txt == "Once in two weeks") {
        this.intakesession = "Once in two weeks";
      }
      else if (drug_detailsarray.intake_txt == "Once a month") {
        this.intakesession = "Once a month";
      }
      else {
        this.intakesession = "";
      }
      if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
        || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
        || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
        || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
        || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
        || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
        || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
        || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
        drug_detailsarray.morning = "1";
        drug_detailsarray.afternoon = "1";
        drug_detailsarray.evening = "0";
        drug_detailsarray.night = "1";

      } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
        drug_detailsarray.morning = "5";
        drug_detailsarray.afternoon = "5";
        drug_detailsarray.evening = "0";
        drug_detailsarray.night = "5";
      }
      drug_detailsarray.drug_detailsarray = false;

      this.morID = false;
      drug_detailsarray.mor_lable = false;
      this.afterID = false;
      this.eveID = false;

      drug_detailsarray.eve_eventxt = false;
      drug_detailsarray.night_eventxt = false;
      this.daydurationShow = false;

      drug_detailsarray.morning_dis = false;
      drug_detailsarray.afternoon_dis = false;
      drug_detailsarray.even_dis = false;
      drug_detailsarray.ngt_dis = false;

      drug_detailsarray.after_eventxt = false;
      drug_detailsarray.quan_lable = true;
      drug_detailsarray.evening_req = true;
      drug_detailsarray.afternoon_req = true;
      drug_detailsarray.morning_req = true;
      drug_detailsarray.intake_show = false;
      if (this.afterfoodText != "With food") {
        drug_detailsarray.dure_show = false;
      }
      drug_detailsarray.morn_eventxt = false;
    }

  }

  comparedrug(drug_id, medpre) {
    var totamount;
    this.checkindex = drug_id
    console.log("singlel medpre" + JSON.stringify(medpre))
    var singeldrugbatchno = medpre.batch_no
    console.log("check index value", drug_id)
    console.log("the drug id value", this.checkindex)

    console.log("details of drug---------->>" + this.detailsofdrug)
    const selectedDrug = this.drugDetailsArray.find(drug => drug.drug_id === this.checkindex);

    if (selectedDrug) {
      selectedDrug.batcharray.forEach(batch => {
        this.checkbatchNo = batch.batch_no;
        var batchNo = batch.batch_no
        var batchquantity = batch.quantity


        if (batchNo == singeldrugbatchno) {
          this.backendquantity = batch.quantity;
        }

        var subract = this.inputquantity - this.subractnumber
        if (batchNo != singeldrugbatchno) {
          this.nextBatchNo = batchNo
          this.checknextbatchquantity = batchquantity
        }
      });
    }

    for (var n = 0; n < this.detailsofdrug.length; n++) {
      for (var k = 0; k < this.detailsofdrug[n].length; k++) {
        if (this.checkindex == this.detailsofdrug[n][k].drug_id) {
          this.backendindexvalue = this.detailsofdrug[n][k].drug_id,
            this.drugname = this.detailsofdrug[n][k].drug_name,
            this.drugshortname = this.detailsofdrug[n][k].short_name
          this.drugprice = this.detailsofdrug[n][k].price
          this.drugsgst = this.detailsofdrug[n][k].sgst,
            this.drugcgst = this.detailsofdrug[n][k].cgst,
            this.drugexpiry_date = this.detailsofdrug[n][k].expiry_date,
            this.drugdiscount = this.detailsofdrug[n][k].discount,
            this.drugfullname = this.drugshortname + " " + this.drugname
        }
      }

      if (this.checkindex == this.backendindexvalue) {
        if (this.inputquantity > this.backendquantity) {
          this.balancequantity = this.inputquantity - this.backendquantity
          this.totalamount = this.drugprice * this.balancequantity
          medpre.quantity = this.backendquantity
          this.checkquantity()
        }
      }
    }
  }

  checkquantity() {
    var index = 1;

    this.drugDetailsArray.push({
      drug_id: this.checkindex,
      type_name: "",
      drug_name: this.drugfullname,
      drug_disp_name: "",
      quantity: this.balancequantity,
      amount: this.totalamount.toFixed(2),
      price: this.drugprice,
      discount: this.drugdiscount,
      per_unit: "",
      prod_disc: "",
      batch_no: this.nextBatchNo,
      amt_bdgst: "",
      cgst: this.drugcgst,
      sgst: this.drugsgst,
      expiry_date: this.drugexpiry_date,
      rec_item_id: "",
      index: index,
      balance: this.remaingBalance,
      batch_required: { "border-color": "rgb(250, 40, 2)" },
      batcharray: "",
      show_batcharray: false,
      quantityDisable: true,
      fromRetrivel: false

    });
    //
    // this.totalValue1 = parseInt(this.totalValue1) + parseInt(this.totalamount.toFixed(2));
    this.remaingBalance = (parseFloat(this.totalValue1) - parseFloat(this.paid)).toFixed(2);
    //

    this.drugDetailsArray.sort((a, b) => {
      return b.drug_id.localeCompare(a.drug_id);
    });
  }

  cancelProduct() {
    const dialogRef = this.dialog.open(OrdercancelComponent, {
      width: '40%',
      height: '180px',
      data: {}
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data != undefined) {
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/rejeord',
          JSON.stringify({
            order_id: this.orderID,
            reject_text: data
          }),
          { headers: headers })
          .subscribe(
            response => {
              var obj = JSON.parse(response["_body"]);
              if (obj.result != null) {
                if (obj.result == "Order has been rejected") {
                  this.toastr.success(Message_data.cancelOrderSuccess);
                  this.messageservice.sendMessage('pharmacycurentinformation');
                } else {
                  this.toastr.error(Message_data.orderNotRejected);
                }
              }
            },
            error => {
              this.toastr.error(Message_data.network);
            });
      }
    });
  }
  checkPrescriptionType() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'gen/durlbyord',
      JSON.stringify({
        order_id: this.orderID
      }),
      { headers: headers })
      .subscribe(
        response => {
          this.presobj = JSON.parse(response["_body"]);
          if (this.presobj.key == "1" && localStorage.getItem("currentorderflowstatus")) {
            this.toastr.success(Message_data.viewPresBfrSave);
          }
        });
  }
  viewPrescription() {
    const dialogRef = this.dialog.open(ViewmedprespageComponent, {
      width: '65%',
      height: '510px',
      data: { order: this.orderID, pharmacy_id: this.pharmacyID }
    });
    dialogRef.afterClosed().subscribe(data => { });
  }
  confirmProduct() {
    var delup = this.todayDate.split('-');
    var delviery = this.todayDate;
    var delivery_time = "06:00:00";
    var SendJSON = null;
    if (pharmacy_helper.getorderview().value == "past" || pharmacy_helper.getorderview().value == "past_edit") {
      if (this.purchaseType != "Store pickup") {
        const dialogRef = this.dialog.open(OrderConfirmPopupComponent, {
          width: '320px',
          height: '400px',
          data: {
            today: this.todayDate,
            type: this.purchaseType
          }
        });
        dialogRef.afterClosed().subscribe(data => {
          if (data != undefined && data.selecteddate != undefined) {
            delup = data.selecteddate.split('-');
            delviery = delup[2] + "-" + delup[1] + "-" + delup[0];
            delivery_time = ConvertTimeformat("", data.selectedtime);


            if (!this.credit_flag) {
              if (this.paid != "") {
                this.paid;
              } else {
                this.paid = "0.00"
              }

              if (this.prodDisc != "") {
                this.prodDisc;
              } else {
                this.prodDisc = "0.00"
              }

              if (this.bill_amount != "") {
                this.bill_amount;
              } else {
                this.bill_amount = "0.00"
              }

              if (this.remaingBalance != "") {
                this.remaingBalance;
              } else {
                this.remaingBalance = "0.00"
              }

              if (this.totalValue1 != "") {
                this.totalValue1;
              } else {
                this.totalValue1 = "0.00"
              }
              SendJSON = JSON.stringify({
                delivery_date: data.selecteddate,
                delivery_time: delivery_time,
                order_id: this.orderID,
                drug_details: this.drugDetailsArray,
                disc_type: this.discType,
                rate_card: this.discType,
                disc_amt: this.prodDisc,
                pharma_id: this.pharmacyID,
                bill_amt: this.bill_amount,
                paid_amt: this.paid,
                gst_amt: this.GSTamount,
                // actuval: this.actuval,
                actual_amt: this.totalValue1,
                balance: this.remaingBalance,
                pay_type: this.payType,
                insurer: this.insurerID,
                card_holder_name: this.cardHolderName,
                transaction_no: this.transactionID,
                bank_name: this.bankName,
                purchase_type_id: "2",
                fin_year: this.finyear,
                payments: this.paymentsarray,
                credit_type: this.credit_type,
                credit_reason: this.credit_person,
              });
            } else {

              if (this.paid != "") {
                this.paid;
              } else {
                this.paid = "0.00"
              }

              if (this.prodDisc != "") {
                this.prodDisc;
              } else {
                this.prodDisc = "0.00"
              }

              if (this.bill_amount != "") {
                this.bill_amount;
              } else {
                this.bill_amount = "0.00"
              }

              if (this.remaingBalance != "") {
                this.remaingBalance;
              } else {
                this.remaingBalance = "0.00"
              }

              if (this.totalValue1 != "") {
                this.totalValue1;
              } else {
                this.totalValue1 = "0.00"
              }

              SendJSON = JSON.stringify({
                delivery_date: data.selecteddate,
                delivery_time: delivery_time,
                order_id: this.orderID,
                drug_details: this.drugDetailsArray,
                disc_type: this.discType,
                rate_card: this.discType,
                disc_amt: this.prodDisc,
                pharma_id: this.pharmacyID,
                bill_amt: this.bill_amount,
                paid_amt: this.paid,
                gst_amt: this.GSTamount,
                actual_amt: this.totalValue1,
                balance: this.remaingBalance,
                pay_type: this.payType,
                insurer: this.insurerID,
                card_holder_name: this.cardHolderName,
                transaction_no: this.transactionID,
                bank_name: this.bankName,
                purchase_type_id: "2",
                fin_year: this.finyear,
                payments: this.paymentsarray,

              });
            }








            console.log("SendJSON" + SendJSON);
            var headers = new Headers();
            headers.append('Content-Type', 'application/json');
            this.http.post(ipaddress.getIp + "pharmacontrol/upord", SendJSON,
              { headers: headers }).subscribe(
                response => {
                  var obj = JSON.parse(response["_body"]);
                  if (obj.key == "1") {
                    this.confirmBtn = true;
                    this.toastr.success(Message_data.orderConfirmSuccess);
                    if (Helper_Class.getIsAdmin() != undefined) {
                      this.adminService.sendMessage("pharmaBillingList")
                    } else {
                      this.messageservice.sendMessage("pharmacycurentinformation");
                    }

                  } else {
                    this.confirmBtn = false;
                    this.toastr.error(Message_data.orderConfirmFailure);
                  }
                },
              )
          }
        });
      } else {
        this.prodFlag = false;
        for (var i = 0; i < this.drugDetailsArray.length; i++) {
          if (this.drugDetailsArray[i].batch_no == undefined) {
            this.prodFlag = true;
            this.toastr.error(Message_data.fillBatchNoPrd);
            break;
          }
        }
        if (this.prodFlag == false) {
          var paid_flag;
          if (this.totalValue1 == this.remaingBalance) {
            paid_flag = "Un paid";
          } else if (this.totalValue1 != "0.00" && (this.paid != "0.00") && this.totalValue1 != this.paid) {
            paid_flag = "Partially paid";
          } else if (this.remaingBalance == "0.00" || this.remaingBalance == "0") {
            paid_flag = "Fully paid";
          }

          var senddata
          if (!this.credit_flag) {

            if (this.paid != "") {
              this.paid;
            } else {
              this.paid = "0.00"
            }

            if (this.prodDisc != "") {
              this.prodDisc;
            } else {
              this.prodDisc = "0.00"
            }

            if (this.bill_amount != "") {
              this.bill_amount;
            } else {
              this.bill_amount = "0.00"
            }

            if (this.remaingBalance != "") {
              this.remaingBalance;
            } else {
              this.remaingBalance = "0.00"
            }

            if (this.totalValue1 != "") {
              this.totalValue1;
            } else {
              this.totalValue1 = "0.00"
            }


            senddata = {
              delivery_date: delviery,
              delivery_time: delivery_time,
              order_id: this.orderID,
              medicines: this.drugDetailsArray,
              pharma_id: this.pharmacyID,
              disc_type: this.discType,
              disc_amt: this.prodDisc,
              rate_card: this.discType,
              bill_amt: this.bill_amount,
              paid_amt: this.paid,
              actual_amt: this.totalValue1,
              balance: this.remaingBalance,
              gst_amt: this.GSTamount,
              paid_flag: paid_flag,
              pay_type: this.payType,
              insurer: this.insurerID,
              card_holder_name: this.cardHolderName,
              transaction_no: this.transactionID,
              bank_name: this.bankName,
              purchase_type_id: "1",
              created_by: this.userName,
              fin_year: this.finyear,
              credit_type: this.credit_type,
              credit_reason: this.credit_person,
              payments: this.paymentsarray,
            }

          } else {

            if (this.paid != "") {
              this.paid;
            } else {
              this.paid = "0.00"
            }

            if (this.prodDisc != "") {
              this.prodDisc;
            } else {
              this.prodDisc = "0.00"
            }

            if (this.bill_amount != "") {
              this.bill_amount;
            } else {
              this.bill_amount = "0.00"
            }

            if (this.remaingBalance != "") {
              this.remaingBalance;
            } else {
              this.remaingBalance = "0.00"
            }

            if (this.totalValue1 != "") {
              this.totalValue1;
            } else {
              this.totalValue1 = "0.00"
            }


            senddata = {
              delivery_date: delviery,
              delivery_time: delivery_time,
              order_id: this.orderID,
              medicines: this.drugDetailsArray,
              pharma_id: this.pharmacyID,
              disc_type: this.discType,
              disc_amt: this.prodDisc,
              bill_amt: this.bill_amount,
              paid_amt: this.paid,
              actual_amt: this.totalValue1,
              balance: this.remaingBalance,
              gst_amt: this.GSTamount,
              paid_flag: paid_flag,
              pay_type: this.payType,
              insurer: this.insurerID,
              card_holder_name: this.cardHolderName,
              transaction_no: this.transactionID,
              bank_name: this.bankName,
              purchase_type_id: "1",
              created_by: this.userName,
              fin_year: this.finyear,
              payments: this.paymentsarray,
            }

          }






          console.log("SendJSON" + JSON.stringify(senddata));

          var headers = new Headers();
          headers.append('Content-Type', 'application/json');
          this.http.post(ipaddress.getIp + "pharmacontrol/upord", JSON.stringify(senddata),
            { headers: headers }).subscribe(
              response => {
                var obj = response.json();
                if (obj.key == "1") {
                  this.confirmBtn = true;
                  this.toastr.success(Message_data.orderConfirmSuccess);
                  if (Helper_Class.getIsAdmin() != undefined) {
                    this.adminService.sendMessage("pharmaBillingList")
                  } else {
                    this.messageservice.sendMessage("pharmacycurentinformation");
                  }
                } else {
                  this.confirmBtn = false;
                  this.toastr.error(Message_data.orderConfirmFailure);
                }
              }, error => { this.confirmBtn = false; })
        }
      }
    } else {
      this.prodFlag = false;
      for (var i = 0; i < this.drugDetailsArray.length; i++) {
        if (this.drugDetailsArray[i].batch_no == undefined) {
          this.prodFlag = true;
          this.toastr.error(Message_data.fillBatchNoPrd);
          break;
        }
      }
      if (this.prodFlag == false) {
        var paid_flag;
        if (this.totalValue1 == this.remaingBalance) {
          paid_flag = "Un paid";
        } else if (this.totalValue1 != "0.00" && (this.paid != "0.00") && this.totalValue1 != this.paid) {
          paid_flag = "Partially paid";
        } else if (this.remaingBalance == "0.00" || this.remaingBalance == "0") {
          paid_flag = "Fully paid";
        }


        var send_data;

        if (!this.credit_flag) {

          if (this.paid != "") {
            this.paid;
          } else {
            this.paid = "0.00"
          }

          if (this.prodDisc != "") {
            this.prodDisc;
          } else {
            this.prodDisc = "0.00"
          }

          if (this.bill_amount != "") {
            this.bill_amount;
          } else {
            this.bill_amount = "0.00"
          }

          if (this.remaingBalance != "") {
            this.remaingBalance;
          } else {
            this.remaingBalance = "0.00"
          }

          if (this.totalValue1 != "") {
            this.totalValue1;
          } else {
            this.totalValue1 = "0.00"
          }

          for (var i = 0; i < this.drugDetailsArray.length; i++) {
            if (this.drugDetailsArray[i].drug_id == "" || this.drugDetailsArray[i].drug_id == undefined || this.drugDetailsArray[i].drug_id == null || this.drugDetailsArray[i].batch_no == "" || this.drugDetailsArray[i].batch_no == undefined || this.drugDetailsArray[i].batch_no == null) {
              this.drugDetailsArray.splice(i, 1);
            }
          }

          send_data = {
            delivery_date: delviery,
            delivery_time: delivery_time,
            order_id: this.orderID,
            medicines: this.drugDetailsArray,
            pharma_id: this.pharmacyID,
            disc_type: this.discType,
            rate_card: this.discType,
            disc_amt: this.prodDisc,
            bill_amt: this.bill_amount,
            paid_amt: this.paid,
            actual_amt: this.totalValue1,
            balance: this.remaingBalance,
            gst_amt: this.GSTamount,
            paid_flag: paid_flag,
            pay_type: this.payType,
            insurer: this.insurerID,
            card_holder_name: this.cardHolderName,
            transaction_no: this.transactionID,
            bank_name: this.bankName,
            fin_year: this.finyear,
            payments: this.paymentsarray,
            credit_type: this.credit_type,
            credit_reason: this.credit_person,
          }
        } else {

          if (this.paid != "") {
            this.paid;
          } else {
            this.paid = "0.00"
          }

          if (this.prodDisc != "") {
            this.prodDisc;
          } else {
            this.prodDisc = "0.00"
          }

          if (this.bill_amount != "") {
            this.bill_amount;
          } else {
            this.bill_amount = "0.00"
          }

          if (this.remaingBalance != "") {
            this.remaingBalance;
          } else {
            this.remaingBalance = "0.00"
          }

          if (this.totalValue1 != "") {
            this.totalValue1;
          } else {
            this.totalValue1 = "0.00"
          }


          for (var i = 0; i < this.drugDetailsArray.length; i++) {
            if (this.drugDetailsArray[i].drug_id == "" || this.drugDetailsArray[i].drug_id == undefined || this.drugDetailsArray[i].drug_id == null || this.drugDetailsArray[i].batch_no == "" || this.drugDetailsArray[i].batch_no == undefined || this.drugDetailsArray[i].batch_no == null) {
              this.drugDetailsArray.splice(i, 1);
            }
          }

          send_data = {
            delivery_date: delviery,
            delivery_time: delivery_time,
            order_id: this.orderID,
            medicines: this.drugDetailsArray,
            pharma_id: this.pharmacyID,
            disc_type: this.discType,
            rate_card: this.discType,
            disc_amt: this.prodDisc,
            bill_amt: this.bill_amount,
            paid_amt: this.paid,
            actual_amt: this.totalValue1,
            balance: this.remaingBalance,
            gst_amt: this.GSTamount,
            paid_flag: paid_flag,
            pay_type: this.payType,
            insurer: this.insurerID,
            card_holder_name: this.cardHolderName,
            transaction_no: this.transactionID,
            bank_name: this.bankName,
            fin_year: this.finyear,
            payments: this.paymentsarray,
          }
        }






        console.log("SendJSON" + JSON.stringify(send_data));
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp + "pharmacontrol/orderconfirm", JSON.stringify(send_data),
          { headers: headers }).subscribe(
            response => {
              var obj = response.json();
              if (obj.key == "1") {
                this.confirmBtn = true;
                this.toastr.success(Message_data.orderConfirmSuccess);
                if (Helper_Class.getIsAdmin() != undefined) {
                  this.adminService.sendMessage("pharmaBillingList")
                } else {
                  this.messageservice.sendMessage("pharmacycurentinformation");
                }
              } else {
                this.confirmBtn = false;
                this.toastr.error(Message_data.orderConfirmFailure);
              }
            }, error => { this.confirmBtn = false; })
      }
    }
  }

  deliverProduct() {
    var delup = this.todayDate.split('-');
    var delviery = delup[2] + "-" + delup[1] + "-" + delup[0]
    var delivery_time = "06:00:00";
    var SendJSON = null;
  }
  addMedicines() {
    var index = 0;
    if (this.drugDetailsArray.length != 0) {
      for (var i = (this.drugDetailsArray.length - 1); i < this.drugDetailsArray.length; i++) {
        index = this.drugDetailsArray[i].index + 1;
      }
    }
    this.drugDetailsArray.push({
      drug_id: this.medID,
      type_name: this.medType,
      drug_name: this.medicineName,
      drug_disp_name: this.shortName + " " + this.medicineName,
      quantity: this.qty,
      amount: this.amountText,
      price: this.price,
      discount: this.disc,
      per_unit: this.perUnit,
      prod_disc: this.disc,
      batch_no: this.batchNo,
      batch_hide: false,
      amt_bdgst: parseFloat(this.productAmout).toFixed(2),
      cgst: this.centralGST,
      sgst: this.stateGST,
      expiry_date: this.expiryDate != undefined ? this.expiryDate : '',
      rec_item_id: this.recItemID,
      index: index,
    });
    var prec_tx = 0;
    for (var i = 0; i < this.drugDetailsArray.length; i++) {
      prec_tx = prec_tx + parseFloat(this.drugDetailsArray[i].amount)
    }

    //
    // this.totalValue1 = prec_tx.toFixed(2);
    // this.actuval = prec_tx.toFixed(2);
    // this.totalValue1 = (Math.round(parseFloat(this.totalValue1))).toFixed(2);
    //

    this.medType = "";
    this.medicineName = "";
    this.qty = "";
    this.disc = "";
    this.gst = "";
    this.amountText = "";
    this.price = "";
    this.centralGST = "";
    this.stateGST = "";
    this.expiryDate = "";
    this.recItemID = "";
    this.batchNo = "";
    this.batchRequired = { "border-color": "rgb(250, 40, 2)", }
    this.changeAdvance();
  }
  deleteMedicine(drug_id, index) {
    for (var i = 0; i < this.drugDetailsArray.length; i++) {
      if (this.drugDetailsArray[i].drug_id == drug_id) {
        if (this.drugDetailsArray[i].index == index) {
          this.drugDetailsArray.splice(i, 1);
        }
      }
    }
    var prec_tx = 0;
    for (var i = 0; i < this.drugDetailsArray.length; i++) {
      prec_tx = prec_tx + parseFloat(this.drugDetailsArray[i].amount);
    }
    if (this.drugDetailsArray.length == 0) {
      this.totalAmount = "0.00";
      this.totalValue1 = "0.00";

      this.actuval = "0.00";
      this.remaingBalance = "0.00";
    } else {
      this.totalAmount = prec_tx;
      // this.totalValue1 = prec_tx;

      this.actuval = prec_tx;
      // this.totalValue1 = parseFloat(this.totalValue1).toFixed(2);

      this.remaingBalance = prec_tx;
    }
    this.changeAdvance();
  }
  printArea() {

    var billnumber = {
      no: "1"
    }
    Helper_Class.setbillprintgen(billnumber);

    var pharmaAddr = this.pharmaAddress2 != undefined ? this.pharmaAddress1 + "," + this.pharmaAddress2 : this.pharmaAddress1;
    var template;
    if (this.billTemplate != undefined && this.billTemplate == "banner") {
      template = '<div #banner id="banner">'
    } else if (this.billTemplate != undefined && this.billTemplate == "noheader") {
      template = '<div #noheader id="noheader">'
    } else if (this.billTemplate != undefined && this.billTemplate == "logowithname") {
      template = '<div #logowithname id="logowithname"style="background-image: url({{bg_image}}); background-position: center;">';
    } else {
      template = '<div #billprint id="billprint">';
    }

    var printData;

    if (this.clntsalutation == undefined) {
      this.clntsalutation = ""
    }

    if (pharmacy_helper.getorderview().value == "past") {
      printData = {
        clntsal: this.clntsalutation,
        doctor_desc: "Dr",
        template: this.billTemplate,
        content: template,
        pharma_logo: this.pharmaLogo,
        pharma_name: this.pharmacyName,
        pharma_addr: pharmaAddr,
        location: this.location,
        city: this.city,
        mrno: this.mrnumber,
        tokenno: this.tokenno,
        state: this.state,
        country: this.country,
        patienttype: this.ipop,
        dl_no: this.dlNO,
        gst_no: this.GSTNumber,
        patient_name: this.orderByName,
        age: this.age,
        gender: this.headergen,
        bill_no: this.orderNum,
        PurchaseType: this.purchaseType,
        doc_name: this.drName,
        order_date: this.orderDate,
        order_time: this.orderTime,
        drugList: this.drugDetailsArray,
        discount: this.prodDisc != null ? this.prodDisc : '0',
        total: this.totalAmt,
        cs_gst: this.GSTamount,
        billamount: this.totalValue1,
        discountprest: this.disperce,
        paid: this.paid,
        balance: this.remaingBalance,
        sponsor_due: this.duesponser,
        // order_type: this.orderType,
        show_discount: false,
        pay_type: this.payType,
        payments: this.paymentsarray,
        numtowords: this.numToWords,
        pagetype: "Past-Order",
        wardname: this.Ward_name,
        bedno: this.Bed_no
      }

    } else if ((pharmacy_helper.getorderview().value == "current")) {

      for (var i = 0; i < this.drugDetailsArray.length; i++) {
        if (this.drugDetailsArray[i].drug_id == "" || this.drugDetailsArray[i].drug_id == undefined || this.drugDetailsArray[i].drug_id == null || this.drugDetailsArray[i].batch_no == "" || this.drugDetailsArray[i].batch_no == undefined || this.drugDetailsArray[i].batch_no == null) {
          this.drugDetailsArray.splice(i, 1);
        }
      }

      printData = {
        clntsal: this.clntsalutation,
        doctor_desc: "Dr",
        template: this.billTemplate,
        content: template,
        pharma_logo: this.pharmaLogo,
        pharma_name: this.pharmacyName,
        pharma_addr: pharmaAddr,
        location: this.location,
        city: this.city,
        mrno: this.mrnumber,
        tokenno: this.tokenno,
        state: this.state,
        country: this.country,
        dl_no: this.dlNO,
        gst_no: this.GSTNumber,
        patient_name: this.orderByName,
        age: this.age,
        gender: this.headergen,
        bill_no: this.orderNum,
        PurchaseType: this.purchaseType,
        doc_name: this.drName,
        order_date: this.orderDate,
        order_time: this.orderTime,
        drugList: this.drugDetailsArray,
        sponsor_due: this.duesponser,
        discountprest: this.disperce,
        discount: this.prodDisc != null ? this.prodDisc : '0',
        total: this.bill_amount,
        billamount: this.totalValue1,
        paid: this.paid,
        balance: this.remaingBalance,
        cs_gst: this.GSTamount,
        // order_type: this.orderType,
        show_discount: false,
        pay_type: this.payType,
        payments: this.paymentsarray,
        numtowords: this.numToWords,

      }

    } else {

      for (var i = 0; i < this.drugDetailsArray.length; i++) {
        if (this.drugDetailsArray[i].drug_id == "" || this.drugDetailsArray[i].drug_id == undefined || this.drugDetailsArray[i].drug_id == null || this.drugDetailsArray[i].batch_no == "" || this.drugDetailsArray[i].batch_no == undefined || this.drugDetailsArray[i].batch_no == null) {
          this.drugDetailsArray.splice(i, 1);
        }
      }

      printData = {
        clntsal: this.clntsalutation,
        doctor_desc: "Dr",
        template: this.billTemplate,
        content: template,
        pharma_logo: this.pharmaLogo,
        pharma_name: this.pharmacyName,
        pharma_addr: pharmaAddr,
        location: this.location,
        city: this.city,
        mrno: this.mrnumber,
        tokenno: this.tokenno,
        state: this.state,
        country: this.country,
        // dl_no: this.dlNO,
        // gst_no: this.GSTNumber,
        dl_no: "",
        gst_no: "",
        patient_name: this.orderByName,
        age: this.age,
        gender: this.headergen,
        bill_no: this.orderNum,
        PurchaseType: this.purchaseType,
        doc_name: this.drName,
        order_date: this.orderDate,
        order_time: this.orderTime,
        drugList: this.drugDetailsArray,
        discount: this.prodDisc != null ? this.prodDisc : '0',
        total: this.totalAmt,
        billamount: this.totalValue1,
        discountprest: this.disperce,
        paid: this.paid,
        balance: this.remaingBalance,
        order_type: this.orderType,
        show_discount: false,
        pay_type: this.payType,
        payments: this.paymentsarray,
        numtowords: this.numToWords,
        pagetype: "pastorder"
      }
    }
    this.printService.print(printData);
    if (this.confirmBtn == false) {
      this.confirmProduct();
    }
  }


  discountChange(distype) {

    for (let k = 0; k < this.drugDetailsArray.length; k++) {
      if (this.drugDetailsArray[k].drug_id != "") {
        this.rateplaneflag = true;
      }
    }

    for (var i = 0; i < this.discountDatasArray.length; i++) {
      if (distype == this.discountDatasArray[i].disc_type_id) {
        this.rateplanetype = this.discountDatasArray[i].description;
        this.discountdesc = this.discountDatasArray[i].discount_type;
        this.discount_id = this.discountDatasArray[i].disc_type_id;
        this.disperce = this.discountDatasArray[i].discount;
        this.discountvalue_amount = this.discountDatasArray[i].discount;

        if (this.ordercreatedetailview == "current") {
          this.discountcalculatestaff();
        }
      }
    }

    // if (this.discType != "1" && this.discType != "2") {
    //   this.discFlag = false;
    //   if (this.discRegSalesVol != undefined && this.discRegSalesVol.length == 0) {
    //     this.getDiscountDetails();
    //   }
    // } else {
    //   this.discFlag = true;
    // }
    // this.calculateDiscount();
  }

  calculateDiscount() {

    var tamtbg = 0.00;
    var amtval = 0.00;
    this.prodDisc = null;
    for (var i = 0; i < this.drugDetailsArray.length; i++) {
      if (this.drugDetailsArray[i].amt_bdgst != 'undefined' && this.drugDetailsArray[i].amt_bdgst != undefined) {
        tamtbg = parseFloat(tamtbg + this.drugDetailsArray[i].amt_bdgst);
      }
      if (this.drugDetailsArray[i].amount != 'undefined' && this.drugDetailsArray[i].amount != undefined) {
        amtval = amtval + parseFloat(this.drugDetailsArray[i].amount);
      }
    }


    if (this.discType == "3") {
      this.amountBdGST = tamtbg.toFixed(2);
      for (var j = 0; j < this.discRegSalesVol.length; j++) {
        if (parseInt(this.amountBdGST) >= this.discRegSalesVol[j].volume) {
          var disamt = parseInt(this.amountBdGST) * (this.discRegSalesVol[j].discount / 100);
          this.prodDisc = Math.floor(disamt).toFixed(2);
          this.totalValue1 = (Math.round(this.totalAmount)).toFixed(2);

          this.actuval = (this.totalAmount - this.prodDisc).toFixed(2);
          this.totalValue1 = (Math.round(parseFloat(this.totalValue1))).toFixed(2);
        }
      }
    } else if (this.discType == "4") {
      this.amountBdGST = tamtbg.toFixed(2);
      for (var j = 0; j < this.discSalesVol.length; j++) {
        if (parseInt(this.amountBdGST) >= this.discSalesVol[j].volume) {
          var disamt = parseInt(this.amountBdGST) * (this.discSalesVol[j].discount / 100);
          this.prodDisc = Math.floor(disamt).toFixed(2);

          this.totalValue1 = (this.totalAmount - this.prodDisc).toFixed(2);

          this.actuval = (this.totalAmount - this.prodDisc).toFixed(2);
          this.totalValue1 = (Math.round(parseFloat(this.totalValue1))).toFixed(2);
        }
      }
    } else if (this.discType == "5") {
      this.amountBdGST = tamtbg.toFixed(2);
      for (var j = 0; j < this.discTransVol.length; j++) {
        if (parseInt(this.amountBdGST) >= this.discTransVol[j].volume) {
          var disamt = parseInt(this.amountBdGST) * (this.discTransVol[j].discount / 100);
          this.prodDisc = Math.floor(disamt).toFixed(2);
          this.totalValue1 = (this.totalAmount - this.prodDisc).toFixed(2);

          this.actuval = (this.totalAmount - this.prodDisc).toFixed(2);
          this.totalValue1 = (Math.round(parseFloat(this.totalValue1))).toFixed(2);
        }
      }
    } else {
      // this.totalValue1 = amtval.toFixed(2);

      this.actuval = amtval.toFixed(2);
      // this.totalValue1 = (Math.round(parseFloat(this.totalValue1))).toFixed(2);
    }
    this.changeTotal();

  }

  changeTotal() {
    this.amountdisc = (parseFloat(this.totalAmount) - parseFloat(this.prodDisc)).toFixed(2);
    this.prodDisc = this.prodDisc == "" || this.prodDisc == null ? "0.00" : this.prodDisc;
    this.remaingBalance = (parseFloat(this.totalValue1) - ((parseFloat(this.paid)) + (parseFloat(this.prodDisc)))).toFixed(2);
  }

  getDiscountDetails() {
    this.discRegSalesVol = [];
    this.discSalesVol = [];
    this.discTransVol = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/gpdis/",
      JSON.stringify({
        pharma_id: this.pharmacyID,
        imei: this.ipaddress,
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.discRegSalesVol = obj.regsal_vol;
          this.discSalesVol = obj.sales_vol;
          this.discTransVol = obj.trans_vol;
        });
  }

  changeAdvance() {
    this.prodDisc = this.prodDisc != undefined && this.prodDisc != "" ? this.prodDisc : "0.00";
    // if (this.paid != undefined && this.paid != "") {

    //   this.remaingBalance = (parseFloat(this.totalValue1) - ((parseFloat(this.paid)) + (parseFloat(this.prodDisc)))).toFixed(2);

    //   this.paid = parseFloat(this.paid);
    // } else {
    //   this.paid = "0.00";
    //   this.remaingBalance = (parseFloat(this.totalValue1) - ((parseFloat(this.paid)) + (parseFloat(this.prodDisc)))).toFixed(2);
    // }
    if (this.paid != undefined && this.paid != "") {
      if (this.rateplaneflag == true) {

        this.remaingBalance = (parseFloat(this.bill_amount) - (parseFloat(this.paid))).toFixed(2);
      }
      else {
        this.remaingBalance = (parseFloat(this.bill_amount) - (parseFloat(this.paid) + parseFloat(this.prodDisc))).toFixed(2);
      }


    } else {
      this.paid = "0.00";
      this.remaingBalance = this.totalValue1;
    }
  }

  paidText() {
    this.paid = parseFloat(this.paid).toFixed(2);
  }

  back_Nav() {
    if (Helper_Class.getIsAdmin() != undefined) {
      this.adminService.sendMessage("pharmaBillingList")
    } else {
      if (pharmacy_helper.getorderview().value == "past" || pharmacy_helper.getorderview().value == "past_edit") {
        this.messageservice.sendMessage("pharmacypastinformation");
      } else {
        this.messageservice.sendMessage("pharmacycurentinformation");
      }
    }
  }

  checkExpiryDate(expiryDate) {
    var exp = "";
    if (expiryDate.split("-").length != 1)
      exp = expiryDate.split("-");
    var d = new Date();
    var a = moment(exp[1] + "-" + exp[0]);
    var b = moment(d);
    var intervals: any = ['years', 'months', 'weeks', 'days'];
    var out = [];

    for (var i = 0; i < intervals.length; i++) {
      var diff = a.diff(b, intervals[i]);
      b.add(diff, intervals[i]);
      out.push(diff + ' ' + intervals[i]);
    }

    console.log("out ==> " + out);
    var expD: any = out.toString().split(",");
    var yy = expD[0].split(/\s+/g);
    console.log("yy ==> " + yy);
    var mmm = expD[1].split(/\s+/g);
    console.log("mmm ==> " + mmm);
    var subtract = moment().add(mmm[0], mmm[1].toString()).format('YYYY-MM');
    var sub1 = moment(subtract).add(yy[0], yy[1].toString()).format('YYYY-MM');
    var final = moment().add(this.productReturn, 'months').format('YYYY-MM');
    console.log("subtract ==> " + subtract + "   sub1 ==> " + sub1 + "final ==> " + final);
    var color;
    if (sub1 <= final) {
      color = "red";
    } else {
      color = "black";
    }
    return color;
  }

  getCORD(e, i) {
    this.batchFlag = true;
    var x = e.clientX;
    var y = e.clientY;
    var coords = "X coords: " + x + ", Y coords: " + y;
    $("#" + i).css("position", "fixed");
    $("#" + i).css("display", "block");
    $("#" + i).css("top", y + 'px');
    $("#pharma_tbl_overlay").css("overflowY", "overflowY");
  }

  selectedBatch(batch, drug_detailsarray, index) {
    this.batchFlag = false;
    drug_detailsarray.batch_required = { "border-color": "#2ca8fe", };
    drug_detailsarray.batch_no = batch.batch_no;
    if (drug_detailsarray.batch_no != "")
      drug_detailsarray.quantityDisable = false;
    console.log("drugArray = " + JSON.stringify(this.drugArray))
    for (var i = 0; i < this.drugArray.length; i++) {
      if (batch.batch_no == this.drugArray[i].batch_no) {
        if (batch.index == this.drugArray[i].index) {
          if (this.tempBatch != undefined) {
            if (this.discountdesc == "cost plus gst") {

              drug_detailsarray.price = (this.drugArray[i].cost_price / this.drugArray[i].per_unit).toFixed(2);
            }
            else if (this.discountdesc == "sales plus gst") {

              drug_detailsarray.price = (this.drugArray[i].sales_price / this.drugArray[i].per_unit).toFixed(2);
            }
            else if (this.discountdesc == "MRP") {

              drug_detailsarray.price = (this.drugArray[i].price / this.drugArray[i].per_unit).toFixed(2);
            } else {

              drug_detailsarray.price = this.drugArray[i].price_txt
            }
            console.log("selcted price " + JSON.stringify(drug_detailsarray.price))
            // drug_detailsarray.price = this.drugArray[i].price_txt;
            // drug_detailsarray.price = price;
            drug_detailsarray.per_unit = this.drugArray[i].per_unit;
            //newly added
            drug_detailsarray.stripprice = this.drugArray[i].stripprice;
            drug_detailsarray.sales_price = this.drugArray[i].sales_price;
            drug_detailsarray.cost_price = this.drugArray[i].cost_price;
            drug_detailsarray.mrp = this.drugArray[i].mrp;
            drug_detailsarray.rec_item_id = this.drugArray[i].rec_item_id;
          } else {
            if (this.discountdesc == "cost plus gst") {

              drug_detailsarray.price = (this.drugArray[i].cost_price / this.drugArray[i].per_unit).toFixed(2);
            }
            else if (this.discountdesc == "sales plus gst") {

              drug_detailsarray.price = (this.drugArray[i].sales_price / this.drugArray[i].per_unit).toFixed(2);
            }
            else if (this.discountdesc == "MRP") {

              drug_detailsarray.price = (this.drugArray[i].price / this.drugArray[i].per_unit).toFixed(2);
            } else {

              drug_detailsarray.price = this.drugArray[i].price_txt
            }
            ;
            console.log("selcted price " + JSON.stringify(drug_detailsarray.price))
            // drug_detailsarray.price = price;
            drug_detailsarray.per_unit = this.drugArray[i].per_unit;
            drug_detailsarray.cgst = this.drugArray[i].cgst;
            drug_detailsarray.sgst = this.drugArray[i].sgst;
            drug_detailsarray.discount = this.drugArray[i].discount;
            drug_detailsarray.expiry_date = this.drugArray[i].expiry_date;
            drug_detailsarray.rec_item_id = this.drugArray[i].rec_item_id;
            drug_detailsarray.stripprice = this.drugArray[i].stripprice;
            drug_detailsarray.sales_price = this.drugArray[i].sales_price;
            drug_detailsarray.cost_price = this.drugArray[i].cost_price;
            drug_detailsarray.mrp = this.drugArray[i].mrp;
          }
          //this.calculateamount(drug_detailsarray);
          // this.calculatePrice(drug_detailsarray.drug_id, drug_detailsarray.type_name, drug_detailsarray.drug_name, drug_detailsarray.quantity, drug_detailsarray.price, drug_detailsarray.per_unit, drug_detailsarray.discount, drug_detailsarray.index)

          this.discountcalculatestaff();

        }
      }
    }
    drug_detailsarray.show_batcharray = false;
    // document.getElementById(index).style.display = 'none';
    // document.getElementById("pharma_tbl_overlay").style.overflowY = "auto";
    // document.getElementById("tbl_med").style.overflowY = "auto";
  }



  discountcalculatestaff() {
    if (this.rateplanetype == "Product wise") {
      this.lineitmdisflag = false
    }
    else {
      this.lineitmdisflag = true
    }
    if (this.discountdesc == "cost plus gst") {
      this.GSTamountflag = false;
      this.discamount = 0;
      this.totalValue1 = 0;
      this.GSTamount = 0;
      this.bill_amount = "0.00";
      this.remaingBalance = 0.00;
      this.paid = "0.00";
      this.amountval = 0;
      this.gstdecimalamt = 0;
      for (var i = 0; i < this.drugDetailsArray.length; i++) {
        this.aftdiscamt = 0;
        var salesprice = 0;
        var costprice = 0;
        var discgst = 0;
        var dissgst = 0;
        var cgstcal = 0;
        var sgstcal = 0;
        var calTotalamount = 0;
        var costpricesubstute;
        var salsepricesubstute;
        var costlineamount = 0;
        console.log(" drugs details = " + this.drugDetailsArray)
        if (this.drugDetailsArray[i].drug_id != "") {
          console.log(" drugs details = " + JSON.stringify(this.drugDetailsArray))
          if (this.drugDetailsArray[i].cost_price != undefined && this.drugDetailsArray[i].cost_price != null && this.drugDetailsArray[i].cost_price != "" && this.ordercreatedetailview == "current") {
            console.log("COST PRICE = " + JSON.stringify(this.drugDetailsArray[i].cost_price) + " " + JSON.stringify(this.drugDetailsArray[i].cost_price))
            //costprice=
            if (this.ordercreatedetailview != "current") {
              costprice = parseFloat(this.drugDetailsArray[i].price)
            } else {
              costprice = parseFloat(this.drugDetailsArray[i].cost_price) / parseInt(this.drugDetailsArray[i].per_unit);
            }
            console.log(JSON.stringify(this.drugDetailsArray[i].cost_price) + " " + JSON.stringify(this.drugDetailsArray[i].per_unit))
            costlineamount = costprice * this.drugDetailsArray[i].quantity;
            costpricesubstute = costlineamount - (parseFloat(this.discountvalue_amount) / 100);
            //salesprice
            salesprice = parseInt(this.drugDetailsArray[i].sales_price) / parseInt(this.drugDetailsArray[i].per_unit);
            saleslineamount = salesprice * this.drugDetailsArray[i].quantity;
            // discount cal
            var disamt = costlineamount * (parseFloat(this.discountvalue_amount) / 100)
            this.discamount += disamt
            this.aftdiscamt += (costlineamount - disamt);
            console.log("afterdisamount = " + this.aftdiscamt + " " + costlineamount + " " + disamt)
          }
          else if (this.ordercreatedetailview != "current") {
            costprice = parseFloat(this.drugDetailsArray[i].price)
            costlineamount = costprice * this.drugDetailsArray[i].quantity;
            costpricesubstute = costlineamount - (parseFloat(this.discountvalue_amount) / 100);
            // discount cal
            var disamt = costlineamount * (parseFloat(this.discountvalue_amount) / 100)
            this.discamount += disamt
            this.aftdiscamt += (costlineamount - disamt);
            console.log("afterdisamount = " + this.aftdiscamt + " " + costlineamount + " " + disamt)
          }
          else {
            costpricesubstute = 0;
            salsepricesubstute = 0;
          }
          if (this.drugDetailsArray[i].cgst != undefined && this.drugDetailsArray[i].cgst != null && this.drugDetailsArray[i].cgst != "") {
            discgst = parseFloat(this.drugDetailsArray[i].cgst)
          } else {
            discgst = 0
          }
          if (this.drugDetailsArray[i].sgst != undefined && this.drugDetailsArray[i].sgst != null && this.drugDetailsArray[i].sgst != "") {
            dissgst = parseFloat(this.drugDetailsArray[i].sgst)
          } else {
            dissgst = 0
          }
          this.drugDetailsArray[i].price = costprice.toFixed(2);
          this.drugDetailsArray[i].amount = costlineamount.toFixed(2);
          this.drugDetailsArray[i].discountstaff = this.discamount;
          cgstcal = parseFloat((this.aftdiscamt * (discgst / 100)).toFixed(2));
          sgstcal = parseFloat((this.aftdiscamt * (dissgst / 100)).toFixed(2));
          this.gstdecimalamt += parseFloat((cgstcal + sgstcal).toFixed(2));
          this.GSTamount = this.spliceDecimal(this.gstdecimalamt);
          if (this.GSTamount == 0) {
            this.GSTamountflag = true
          } else {
            this.GSTamountflag = false
          }


          calTotalamount += this.aftdiscamt + cgstcal + sgstcal;

          console.log(calTotalamount + " " + this.aftdiscamt + " " + cgstcal + " " + sgstcal)
          this.drugDetailsArray[i].calamt = calTotalamount;

          this.totalValue1 = parseFloat(this.totalValue1) + parseFloat(costlineamount.toFixed(2));
          this.totalValue1 = Math.round(this.totalValue1).toFixed(2); // Convert totalAmount to a string with 2 decimal places
          this.prodDisc = this.discamount.toFixed(2);

          if (this.prodDisc == 0.00) {
            this.discFlag = true
          } else {
            this.discFlag = false
          }

          if (this.bill_amount != "") {
            this.bill_amount = Math.round(parseFloat(this.bill_amount) + calTotalamount).toFixed(2)
          } else {
            this.bill_amount = calTotalamount.toFixed(2)
            // Math.round(amtval).toFixed(2);
          }

          if (this.paid != "") {

            this.remaingBalance = Math.round(parseFloat(this.bill_amount) - parseFloat(this.paid)).toFixed(2);

          } else {

            this.remaingBalance = Math.round(parseFloat(this.bill_amount) - 0).toFixed(2);

          }
        }
      }
    } else if (this.discountdesc == "sales plus gst") {
      //salesplucgst----	Own staff
      this.GSTamountflag = false;
      this.discamount = 0;
      this.totalValue1 = 0;
      this.bill_amount = "0.00";
      this.remaingBalance = 0.00;
      this.paid = "0.00";
      this.GSTamount = 0;
      this.amountval = 0;
      this.gstdecimalamt = 0;
      for (var i = 0; i < this.drugDetailsArray.length; i++) {
        this.aftdiscamt = 0;
        var salesprice = 0;
        var costprice = 0;
        var discgst = 0;
        var dissgst = 0;
        var cgstcal = 0;
        var sgstcal = 0;
        var calTotalamount = 0;
        var saleslineamount = 0;
        var costpricesubstute;
        var salsepricesubstute;
        console.log("DISCOUNT VALUE sa = " + JSON.stringify(this.drugDetailsArray[i]))



        if (this.drugDetailsArray[i].drug_id != "") {
          if (this.drugDetailsArray[i].sales_price != undefined && this.drugDetailsArray[i].sales_price != null && this.drugDetailsArray[i].sales_price != "" && this.ordercreatedetailview == "current") {
            costprice = parseInt(this.drugDetailsArray[i].cost_price) / parseInt(this.drugDetailsArray[i].per_unit);
            var costlineamount = costprice * this.drugDetailsArray[i].quantity;
            costpricesubstute = costlineamount - (parseFloat(this.discountvalue_amount) / 100);

            salesprice = parseFloat(this.drugDetailsArray[i].sales_price) / parseInt(this.drugDetailsArray[i].per_unit);

            saleslineamount = salesprice * this.drugDetailsArray[i].quantity;
            var disamt = saleslineamount * (parseFloat(this.discountvalue_amount) / 100);
            this.aftdiscamt += (saleslineamount - disamt);
            this.discamount += disamt
            console.log("saleslineamount" + saleslineamount + " sales_price = " + salesprice + " quantity " + this.drugDetailsArray[i].quantity)
            console.log("disamt = " + disamt + " sales_price = " + saleslineamount + " discountvalue _amount " + parseFloat(this.discountvalue_amount) / 100)
            console.log("after discamt = " + this.aftdiscamt + " salesline = " + saleslineamount + " disamt " + disamt)
            console.log("sales price = " + salesprice + " sales_price = " + this.drugDetailsArray[i].sales_price + "" + this.drugDetailsArray[i].per_unit)
            console.log("discamount = " + this.discamount + " salesline amount  = " + saleslineamount + " discount amount =  " + parseFloat(this.discountvalue_amount) / 100)
          } else if (this.ordercreatedetailview != "current") {
            salesprice = parseFloat(this.drugDetailsArray[i].price)
            saleslineamount = salesprice * this.drugDetailsArray[i].quantity;
            var disamt = saleslineamount * (parseFloat(this.discountvalue_amount) / 100);
            this.aftdiscamt += (saleslineamount - disamt);
            this.discamount += disamt
          }
          else {
            costprice = 0;
            salesprice = 0;
          }
          if (this.drugDetailsArray[i].cgst != undefined && this.drugDetailsArray[i].cgst != null && this.drugDetailsArray[i].cgst != "") {
            discgst = parseFloat(this.drugDetailsArray[i].cgst)
          } else {
            discgst = 0
          }
          if (this.drugDetailsArray[i].sgst != undefined && this.drugDetailsArray[i].sgst != null && this.drugDetailsArray[i].sgst != "") {
            dissgst = parseFloat(this.drugDetailsArray[i].sgst)
          } else {
            dissgst = 0
          }
          this.drugDetailsArray[i].price = salesprice.toFixed(2)
          this.drugDetailsArray[i].amount = saleslineamount.toFixed(2);
          this.drugDetailsArray[i].discountstaff = this.discamount;
          cgstcal = parseFloat((this.aftdiscamt * (discgst / 100)).toFixed(2));
          sgstcal = parseFloat((this.aftdiscamt * (dissgst / 100)).toFixed(2));
          this.gstdecimalamt += parseFloat((cgstcal + sgstcal).toFixed(2));
          this.GSTamount = this.spliceDecimal(this.gstdecimalamt);
          if (this.GSTamount == 0) {
            this.GSTamountflag = true
          } else {
            this.GSTamountflag = false
          }
          calTotalamount += this.aftdiscamt + cgstcal + sgstcal
          console.log("afterdiscamt = " + this.aftdiscamt + " cgst " + cgstcal + " sgst " + sgstcal)
          console.log("calTotalamount = " + calTotalamount)
          this.drugDetailsArray[i].calamt = calTotalamount

          console.log("afterdiscamt = " + this.totalAmount + " cgst " + saleslineamount)
          this.totalValue1 = parseFloat(this.totalValue1) + parseFloat(saleslineamount.toFixed(2));
          this.totalValue1 = Math.round(this.totalValue1).toFixed(2); // Convert totalAmount to a string with 2 decimal places

          // this.totalAmount += saleslineamount.toFixed(2);
          this.prodDisc = this.discamount.toFixed(2);

          if (this.prodDisc == 0.00) {
            this.discFlag = true
          } else {
            this.discFlag = false
          }


          if (this.bill_amount != "") {
            this.bill_amount = Math.round(parseFloat(this.bill_amount) + calTotalamount).toFixed(2)
          } else {
            this.bill_amount = Math.round(calTotalamount).toFixed(2)
          }

          console.log("amount dicont = " + this.amountDicount + " calTotalamount = " + calTotalamount)
          if (this.paid != "") {
            this.remaingBalance = Math.round(parseFloat(this.bill_amount) - parseFloat(this.paid)).toFixed(2);
          } else {
            this.remaingBalance = Math.round(parseFloat(this.bill_amount) - 0).toFixed(2);
          }
        }
      }

    } else if (this.discountdesc == "MRP") {
      this.discamount = 0;
      this.GSTamountflag = true;
      this.totalValue1 = 0;
      this.remaingBalance = 0.00;
      this.paid = "0.00";
      this.GSTamount = 0;
      this.bill_amount = "0.00";
      this.amountval = 0;

      for (var i = 0; i < this.drugDetailsArray.length; i++) {
        this.aftdiscamt = 0;
        var salesprice = 0;
        var costprice = 0;
        var discgst = 0;
        var dissgst = 0;
        var cgstcal = 0;
        var sgstcal = 0;
        var calTotalamount = 0;
        var saleslineamount = 0;
        var costpricesubstute;
        var salsepricesubstute;
        var mrpprice = 0;
        var mrpcallineamount = 0;
        var mrpdisamt;

        console.log("DISCOUNT VALUE mr = " + JSON.stringify(this.drugDetailsArray[i]))
        if (this.drugDetailsArray[i].drug_id != "") {
          if (this.drugDetailsArray[i].mrp != undefined && this.drugDetailsArray[i].mrp != null && this.drugDetailsArray[i].mrp != "" && this.ordercreatedetailview == "current") {
            mrpprice = this.drugDetailsArray[i].mrp
          } else if (this.ordercreatedetailview != "current") {
            mrpprice = this.drugDetailsArray[i].price
          }
          else {
            costprice = 0;
            salesprice = 0;
            mrpprice = 0;
          }
          if (this.drugDetailsArray[i].cgst != undefined && this.drugDetailsArray[i].cgst != null && this.drugDetailsArray[i].cgst != "") {
            discgst = parseFloat(this.drugDetailsArray[i].cgst)
          } else {
            discgst = 0
          }
          if (this.drugDetailsArray[i].sgst != undefined && this.drugDetailsArray[i].sgst != null && this.drugDetailsArray[i].sgst != "") {
            dissgst = parseFloat(this.drugDetailsArray[i].sgst)
          } else {
            dissgst = 0
          }
          if (this.ordercreatedetailview == "current") {
            this.drugDetailsArray[i].price = this.drugDetailsArray[i].mrp
          }
          console.log("mRP PRICE IN DISCONT =  = " + JSON.stringify(this.drugDetailsArray[i].price))
          this.drugDetailsArray[i].amount = (mrpprice * this.drugDetailsArray[i].quantity).toFixed(2);
          console.log("aMOUNT =  = " + JSON.stringify(this.drugDetailsArray[i].amount))
          mrpcallineamount = mrpprice * ((this.drugDetailsArray[i].quantity));

          if (this.rateplanetype == "Product wise") {
            if (this.drugDetailsArray[i].discount != undefined && this.drugDetailsArray[i].discount != null && this.drugDetailsArray[i].discount != "") {
              console.log("lineitemdiscount" + this.drugDetailsArray[i].discount)
            }
            else {
              this.drugDetailsArray[i].discount = 0
            }
            mrpdisamt = mrpcallineamount * (parseFloat(this.drugDetailsArray[i].discount) / 100)
            this.discamount += (mrpcallineamount * (parseFloat(this.drugDetailsArray[i].discount) / 100))
          } else {
            mrpdisamt = mrpcallineamount * (parseFloat(this.discountvalue_amount) / 100)
            this.discamount += (mrpcallineamount * (parseFloat(this.discountvalue_amount) / 100))
            if (this.prodDisc == 0.00) {
              this.discFlag = true

            } else {
              this.discFlag = false
            }
          }

          this.aftdiscamt += (mrpcallineamount - mrpdisamt);
          this.drugDetailsArray[i].discountstaff = this.discamount;
          calTotalamount += this.aftdiscamt
          this.drugDetailsArray[i].calamt = calTotalamount
          console.log("mrpcallineamount =  = " + mrpcallineamount)
          console.log("After discount   = " + this.aftdiscamt + " mrpcallineamount = " + mrpcallineamount + " mrpdisamt =  " + mrpdisamt)
          console.log("discamount  = " + this.discamount + " mrpcallineamount = " + mrpcallineamount + " discountvalue =  " + (parseFloat(this.discountvalue_amount) / 100))
          console.log("mrpdisamt = " + mrpdisamt + " mrpcallineamount = " + mrpcallineamount + " discountvalue =  " + (parseFloat(this.discountvalue_amount) / 100))
          console.log("After discount   = " + this.aftdiscamt + " calTotalamount = " + calTotalamount)
          console.log("this.totalAmount    = " + this.totalAmount + " mrpcallineamount = " + parseFloat(mrpcallineamount.toFixed(2)))
          this.totalValue1 = parseFloat(this.totalValue1) + parseFloat(mrpcallineamount.toFixed(2));
          this.totalValue1 = Math.round(this.totalValue1).toFixed(2); // Convert totalAmount to a string with 2 decimal places
          this.prodDisc = this.discamount.toFixed(2);
          if (this.bill_amount != "") {
            this.bill_amount = Math.round(parseFloat(this.bill_amount) + calTotalamount).toFixed(2)
          } else {
            this.bill_amount = Math.round(calTotalamount).toFixed(2)
          }
          if (this.paid != "") {
            this.remaingBalance = Math.round(parseFloat(this.bill_amount) - parseFloat(this.paid)).toFixed(2);
          } else {
            this.remaingBalance = Math.round(parseFloat(this.bill_amount) - 0).toFixed(2);
          }
        }
      }
    }
  }

  spliceDecimal(number) {
    // Convert the number to a string
    let numberStr = number.toString();
    // Split the number into integer and decimal parts
    let parts = numberStr.split('.');
    // Check if there's a decimal part
    if (parts.length > 1) {
      // Take the first two digits of the decimal part
      let newDecimal = parts[1].slice(0, 2);
      // Combine the integer part with the truncated decimal part
      var newNumberStr = parts[0] + '.' + newDecimal;
      // Convert back to a number

      return parseFloat(newNumberStr);

    } else {
      // If there's no decimal part, return the original number
      return number;
    }
    // 
  }

  calculateamount(drug_detailsarray) {
    if (drug_detailsarray.quantity != "") {
      var priceval = parseFloat(drug_detailsarray.price);
      if (drug_detailsarray.quantity != undefined) {
        var pricecal: any = priceval * drug_detailsarray.quantity;
      }
      if (drug_detailsarray.discount != null) {
        var disc: any = (pricecal * (drug_detailsarray.discount / 100)).toFixed(2);
        drug_detailsarray.amount = pricecal - disc;
      } else {
        disc = 0;
      }
      var pricegst;
      pricegst = pricecal - disc;
      drug_detailsarray.amount = pricegst.toFixed(2);
    }
  }
  addNewDrugDetails() {
    var index = 0;
    if (this.drugDetailsArray.length == 0) {
      this.drugDetailsArray.push({
        drug_id: "",
        type_name: "",
        drug_name: "",
        drug_disp_name: "",
        quantity: "",
        amount: "",
        price: "",
        discount: "",
        per_unit: "",
        prod_disc: "",
        batch_no: "",
        amt_bdgst: "",
        cgst: "",
        sgst: "",
        expiry_date: "",
        rec_item_id: "",
        index: index,
        batch_required: { "border-color": "rgb(250, 40, 2)" },
        batcharray: [],
        show_batcharray: false,
        quantityDisable: true,
        fromRetrivel: false,
        balance: this.remaingBalance
      });
    } else {
      for (var i = (this.drugDetailsArray.length - 1); i < this.drugDetailsArray.length; i++) {
        if (this.drugDetailsArray[i].drug_name != "" && this.drugDetailsArray[i].batch_no != "" && this.drugDetailsArray[i].quantity != "") {
          index = this.drugDetailsArray[i].index + 1;
          this.drugDetailsArray.push({
            drug_id: "",
            type_name: "",
            drug_name: "",
            drug_disp_name: "",
            quantity: "",
            amount: "",
            price: "",
            discount: "",
            per_unit: "",
            prod_disc: "",
            batch_no: "",
            amt_bdgst: "",
            cgst: "",
            sgst: "",
            expiry_date: "",
            rec_item_id: "",
            index: index,
            batch_required: { "border-color": "rgb(250, 40, 2)" },
            batcharray: "",
            show_batcharray: false,
            quantityDisable: true,
            fromRetrivel: false,
            balance: this.remaingBalance
          });
        } else {
          this.toastr.error(Message_data.checkDrugListEmpty);
        }
        break;
      }
    }
    setTimeout(function () {
      $(".getMedcien").last().focus();
    }, 500);
  }

  printcover() {
    var count = 0

    console.log("array " + JSON.stringify(this.drugDetailsArray))
    for (var i = 0; i < this.drugDetailsArray.length; i++) {

      if ((this.drugDetailsArray[i].drug_id == "" && this.drugDetailsArray[i].drug_name == "")) {
        count = + 1

      }
    }

    if (count == 0) {

      this.printsmallcov();
    } else {
      this.toastr.error(Message_data.removerow);
    }
  }

  printsmallcov() {
    console.log("print cover" + JSON.stringify(this.drugDetailsArray))
    let printContents, popupWin;

    if (this.languagetype == false) {

      printContents = this.printsmallcover.nativeElement.innerHTML;
    }
    else {

      printContents = this.printsmallcovereng.nativeElement.innerHTML;
    }
    popupWin = window.open('', '_blank', 'top=100,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`<head>
  <title>Reports</title>
  
<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">
<style>

 
.covertablebig thead td {
  border: 1px solid black;

}
.covertablebig thead th {
  border: 1px solid black;

}
.covertablebig tfoot th {
  border: 1px solid black;
}
.covertablebig tbody td{
  border: 1px solid black;
}

  </style>
</head>
    <body onload="window.print();window.onfocus=function(){ window.close();}">
 
    ${printContents}
   
 </body>
  </html>`
    );
    popupWin.document.close();

  }

  device_request() {
    let printContents
    if (this.languagetype == false) {

      printContents = this.printsmallcover.nativeElement.innerHTML;
    }
    else {

      printContents = this.printsmallcovereng.nativeElement.innerHTML;
    }

    var printcoverdetails = {
      patientname: this.clntsalutation + ". " + this.orderByName,
      Mrnumber: this.mrnumber,
      drugArray: this.drugDetailsArray,

    }

    // requestDevicecoverprint(printContents)
  }

  showCreditOption() {
    this.credit_flag = !this.credit_flag;

    this.paymentsarray = [];
    this.getCreditTypes()
  }

  getCreditTypes() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(
        ipaddress.getIp.toString() + 'bill/ghco',
        JSON.stringify({
          country: ipaddress.country_code.toString(),
          hptl_clinic_id: this.getData.hptl_clinic_id,
          type: 'hospital',
        }),
        { headers: headers }
      )
      .subscribe(
        (response) => {
          var obj = response.json();

          this.hosp_credits = [];
          if (obj.hosp_credits != null) {
            for (var i = 0; i < obj.hosp_credits.length; i++) {
              this.hosp_credits.push({
                credit_id: obj.hosp_credits[i].credit_id,
                credit_desc: obj.hosp_credits[i].credit_desc,
              });
            }
          }
        },
        (error) => { }
      );
  }

  finekeyInArray_Payment(array, id) {
    var flag = false;
    for (var i = 0; i < array.length; i++) {
      if (array[i].pay_id === id) {
        flag = true;
        break;
      }
    }
    return flag;
  }



  paidBlur() {
    if (this.paid != "") {
      this.paid = (parseFloat(this.paid)).toFixed(2);
    }
  }


  addPaymentDetails() {



    var bamt: number = 0,
      balance = 0;
    var results = this.finekeyInArray_Payment(
      this.paymentsarray = [],
      this.payType1
    );
    for (var i = 0; i < this.payTypeListArrray.length; i++) {
      if (this.payType1 == this.payTypeListArrray[i].pay_id) {
        if (this.paymentsarray.length != 0) {
          for (var j = 0; j < this.paymentsarray.length; j++) {
            bamt += parseInt(this.paymentsarray[j].amount);
          }
          bamt += parseFloat(this.amountval);
        } else {
          bamt += parseFloat(this.amountval.toFixed(2));
        }



        if (bamt > parseInt(this.paid)) {
          this.toastr.error('Amount cannot be more than bill amount');
        } else if (this.amountval == 0 || this.amountval < 0) {
          this.toastr.error('Amount cannot be zero or minus amount');
        } else if (results == true) {
          this.toastr.error('Payment type already exists');
        } else {
          if (
            this.amountval < parseFloat(this.paid) ||
            this.amountval == parseFloat(this.paid)
          ) {
            if (parseFloat(this.paid) > parseFloat(this.bill_amount)) {
              if (bamt > parseFloat(this.bill_amount)) {
                this.toastr.error('Amount cannot be more than bill amount');
              } else {
                this.paymentsarray.push({
                  pay_id: this.payTypeListArrray[i].pay_id,
                  pay_desc: this.payTypeListArrray[i].pay_desc,
                  amount: parseFloat(this.amountval).toFixed(2),
                });
                this.amountval = parseFloat(this.bill_amount) - bamt;
                this.amountval = parseFloat(this.amountval).toFixed(2);

                // this.payType1 = '';
              }
            } else {
              if (bamt > parseFloat(this.paid)) {
                this.toastr.error('Amount cannot be more than bill amount');
              } else {
                this.paymentsarray.push({
                  pay_id: this.payTypeListArrray[i].pay_id,
                  pay_desc: this.payTypeListArrray[i].pay_desc,
                  amount: parseFloat(this.amountval).toFixed(2),
                });
                this.amountval = parseFloat(this.paid) - bamt;
                this.amountval = parseFloat(this.amountval).toFixed(2);

                // this.payType1 = '';
              }
            }
          } else {
            this.amountval = 0;
          }
        }
      }
    }
  }


  editPayment(data) {
    this.payType1 = data.pay_id;
    this.amountval = parseInt(data.amount);

    for (var i = 0; i < this.paymentsarray.length; i++) {
      if (data.pay_id == this.paymentsarray[i].pay_id) {
        this.tamt -= this.paymentsarray[i].amount;
        this.paymentsarray.splice(i, 1);
      }
    }
  }
  deletePayment(data) {
    for (var i = 0; i < this.paymentsarray.length; i++) {
      if (data.pay_id == this.paymentsarray[i].pay_id) {



        this.paymentsarray.splice(i, 1);
      }
    }
  }



  changePaid() {

    this.paid = this.paid == "" ? "0.00" : this.paid;
    this.numToWords = this.paid == "" ? " " : convertNumberToWords(this.paid, ipaddress.country_code);
    if (this.rateplaneflag == true) {
      this.remaingBalance = ((parseFloat(this.bill_amount)) - (parseFloat(this.paid))).toFixed(2);
    } else {

      this.remaingBalance = ((parseFloat(this.bill_amount) - parseFloat(this.prodDisc)) - (parseFloat(this.paid))).toFixed(2);
    }

    if ((this.paid) > this.bill_amount) {
      this.amountval = parseFloat(this.bill_amount);
    } else {
      this.amountval = parseFloat(this.paid);
    }
  }

  paidFocus() {
    this.paid = "";
  }
  insurancechg(value) {
    // alert(value)
    // console.log(" insurance array = "+JSON.stringify(this.insuranceDetailsArray))
    // this.drugDetailsArray .push(this.insuranceDetailsArray.filter((drug) => {drug.ins_allow == value}));
    // console.log(" insurance array = "+JSON.stringify(this.drugDetailsArray))
    this.drugDetailsArray = [];
    console.log("insurance array = " + JSON.stringify(this.insuranceDetailsArray));
    const filteredDrugs = this.insuranceDetailsArray.filter((drug) => drug.ins_allow == value);
    // if (!Array.isArray(this.drugDetailsArray)) {
    //     this.drugDetailsArray = [];
    // }
    this.drugDetailsArray = this.drugDetailsArray.concat(filteredDrugs);
    console.log("drug details array = " + JSON.stringify(this.drugDetailsArray));
    this.discountcalculatestaff()
  }
}
