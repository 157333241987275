import { Component, OnInit ,HostListener, Input} from '@angular/core';
import {Helper_Class} from '../../helper_class';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { HabitualService } from '../service/habitualservice';
import { Observable, observable, Subscription } from 'rxjs';
import { Doc_Helper } from '../Doc_Helper';
import { CasesheetService } from '../casesheet/casesheet.service';
import { DiabeticpopuphistoryComponent } from '../diabeticpopuphistory/diabeticpopuphistory.component';
import { MatDialog } from '@angular/material/dialog';
import { BmiandbmrService } from '../service/bmiandbmr.service';
import { Helper_nephro_casesheet } from '../../../assets/js/Helper_nephro_casesheet';
import { masterCSData_Helper } from '../casesheet/masterCSData_Helper';
import { MasterCSHelperService } from '../casesheet/masterCSData.service';

@Component({
  selector: 'app-habitualinfo',
  templateUrl: './habitualinfo.component.html',
  styleUrls: ['./habitualinfo.component.css']
})
export class HabitualinfoComponent implements OnInit {
  public person_hist;
  public habitualFlag = "min";
  public data_bmr;
  public bodynatute;
  public bmi;
  public userinfo:any;
  public user_id:any;
  subscription:Subscription;
  public togg_family:string="no"
  public life_exerdure: string;
  public life_exe_freq: string;

  public casesheet_case_hist_id: string;
  public life_style_id: string;
  public exer_run: string;
  public Diabetes_habitual:boolean=false;

  // public life_exerdure: string;
  // public life_exe_freq: string;
  public Icd_code: string;
  public Icd_Descript: string;
  public phyfreq_label;
  public phyex_hidden:boolean
  public Diabetes_flag:boolean=false;

  public Gynecology_flag:boolean=false;
  public cardio_flag:boolean=false;

  public exp1:boolean;
  public exp2:boolean;
  public exp3:boolean;
  public exp4:boolean;
  public exp5:boolean;
  public exp6:boolean;
  public exp7:boolean;
  public exp8:boolean;
  public smoke_duration: string;
  public smoke_frequency: string;
  public alchol_duration: string;
  public alcohol_frequency: string;
  public gutka_duration: string;
  public gutka_frequency: string;
  public Alc_Quant: string;
  public Alc_Mix: string;
  
  public ynoption:boolean=false;

  public ynopt1:boolean=false;
  public ynopt2:boolean=false;
  public exercise_dur: string;
  public Quantity_array=[];
  public Mixing_array = [];
  public exercise_routine_arry = [];
  public yoga_check=[];
  public panelOpenState1:boolean;
  public panelOpenState3:boolean;
  public panelOpenState2:boolean;
  public panelOpenState4:boolean;
  public panelOpenState5:boolean;
  public agefield: boolean;
  public alchol_label: string;
  public smoke_label: string;
  public gutka_label: string;
  public exercise_label: string;
  public alchol_hidden: boolean;
  public smoke_hidden: boolean;
  public gutka_hidden: boolean;
  public exercise_hidden: boolean;
  public togg_diab:string="no";
  public med_flag: boolean =true;  public cal_txt: any;

  public phydure_label;
  public C_age;
  public C_gender;public C_image;
  public life_exer;
  public yoga_div:boolean=true;
  public Get_theyoga;
  public get_speclization_arry;
  public Physcial_exercise=[];
  yoga_send_data=[];
  public disease_typetxt;

  public diagnosis_flag;
  public Alcohol_Mix;
  public Alcohol_Quantity;
  public exmin:boolean=false;
  public gutkamin:boolean=false;
  public alcoholmin:boolean=false;
  public smokemin:boolean=false;
  public personaldata:any;
  public family;
  public habitualflag ="min";
  public client:boolean = false;
  public togg_family1:boolean = false;
  public familytab1:boolean =  true;

  constructor(public habitualservice:HabitualService,public bmiservice:BmiandbmrService,
    public dialog:MatDialog,public messageservice:CasesheetService,private http:Http,
    private routes:ActivatedRoute,private router:Router,public toastr:ToastrService
    ,public masterCSdata:MasterCSHelperService){
    this.togg_family ="no"; 
    this.alchol_duration = "<1 Year";
    this.alcohol_frequency = "<5";
    this.smoke_duration = "<1 Year";
    this.smoke_frequency = "<5";
    this.gutka_duration = "<1 Year";
    this.gutka_frequency = "<5";
    this.exercise_dur = "15 Mins";
    //Helper_Class.setAsg(undefined);
  }

  async ngOnInit() {
    this.subscription = this.messageservice.getCasesheetMessage().subscribe(message => {
      if (message) {
          this.habitualFlag = message;
      }
    });

    this.subscription = this.bmiservice.getMessage().subscribe(message => {
      if (message) {
        if(message.flag == "vitals"){
          this.data_bmr = message.txt_bmr;
          this.bodynatute = message.bodynatute;
          this.bmi = message.txt_bmi
          this.calories_data(this.data_bmr);
        }
      }
    });
    if(Doc_Helper.getAppFlow() == "client" ){
      this.client = true;
      this.personaldata = Helper_Class.getInfo();
      this.userinfo = Helper_Class.getclient_hospitalDetails();
      this.C_age = this.personaldata.Age_data;
      this.C_gender = this.personaldata.Gender_data;
      this.C_image = this.personaldata.image;
    }else{
      this.client = false;
      this.personaldata = Doc_Helper.getClient_Info();
      this.userinfo = Helper_Class.getInfo();

      if(Helper_Class.getInpatientFlow() == "nurse"){
          this.user_id = this.personaldata.user_id;
      }else{
          this.user_id = this.userinfo.user_id;
      }
      this.C_age = this.personaldata.Age_data;
      this.C_gender = this.personaldata.Gender_data;
      this.C_image = this.personaldata.image;
    }
    
    if(masterCSData_Helper.getMasterMixingtype() == undefined){
      await this.masterCSdata.getMixingtype();
      this.Get_Mixingtype();
    }else{
      this.Get_Mixingtype();
    }

    if(masterCSData_Helper.getMasterExerRoutine() == undefined){
      await this.masterCSdata.getExerciseRoutine();
      this.Get_exercise_routine();
    }else{
      this.Get_exercise_routine();
    }

    if(masterCSData_Helper.getMasterQunatity() == undefined){
      await this.masterCSdata.getQuantity();
      this.Get_quantity();
    }else{
      this.Get_quantity();
    }

    if(masterCSData_Helper.getMasterExerDuration() == undefined){
      await this.masterCSdata.getDuration();
      this.get_duration();
    }else{
      this.get_duration();
    }

    if(masterCSData_Helper.getMasterExercise() == undefined){
      await this.masterCSdata.getExercise();
      this.get_exercise();
    }else{
      this.get_exercise();
    }

    
    this.family = Helper_Class.getRet();
    
    if(this.personaldata.spl == "Diabetes"){
      this.Diabetes_habitual = false;
    }else if(this.personaldata.spl =="General" || this.personaldata.spl =="General surgery"){
      this.Diabetes_habitual = false;
    }else if(this.personaldata.spl =="Dental"){
      this.Diabetes_habitual = true;
    }else if(this.personaldata.spl =="Gynaecology" || this.personaldata.spl =="Obstetrics" || this.personaldata.spl == "Nephrology" || this.personaldata.spl == "Pediatrics"){
      this.Diabetes_habitual = true;
    }else{
      this.Diabetes_habitual = false;
    }

    var getHopitalfieldsRet=Helper_Class.getHopitalfieldsRet();
    if(getHopitalfieldsRet != null){
      for (var i = 0; i < getHopitalfieldsRet.length; i++) {
        if (getHopitalfieldsRet[i].page_name == "CardioPersonal") {
          this.Diabetes_habitual=true;
          if (getHopitalfieldsRet[i].fields != undefined) {
            for (var j = 0; j < getHopitalfieldsRet[i].fields.length; j++) {
                  if (getHopitalfieldsRet[i].fields[j].field_name == "Alcohol") {
                    this.alchol_label = getHopitalfieldsRet[i].fields[j].field_name;
                    this.alchol_hidden = false;
                  }
    
                  if (getHopitalfieldsRet[i].fields[j].field_name == "Smoke") {
                    this.smoke_label = getHopitalfieldsRet[i].fields[j].field_name;
                    this.smoke_hidden = false;
                  }
    
                  if (getHopitalfieldsRet[i].fields[j].field_name == "Gutka") {
                    this.gutka_label = getHopitalfieldsRet[i].fields[j].field_name;
                    this.gutka_hidden = false;
                  }
    
                  if (getHopitalfieldsRet[i].fields[j].field_name == "Exercises") {
                    this.exercise_label = getHopitalfieldsRet[i].fields[j].field_name;
                    this.exercise_hidden = false;
                  }
            }
          }
        }
      }
    }
  }

  isReadonly() {
    if(Doc_Helper.getAppFlow() == "client" ){
      return true;
    }else{
      return false;
    }
  }

  // @HostListener('window:click', ['$event'])
  // onclick(event: any): void {
  //   this.send_data();
  // }
 
  // @HostListener('window:blur', ['$event'])
  // onBlur(event: any): void {
  //   this.send_data();
  // }

  //Get common data for all case sheets
  getCommonData() {
    var obj = Helper_Class.getAsg();
    if(obj != undefined){
        //Alchol mixinag
        if (obj.alcohol_mixing_id != null) {
          for (var i = 0; i < this.Mixing_array.length; i++) {
            for (var j = 0; j < obj.alcohol_mixing_id.length; j++) {
              if (obj.alcohol_mixing_id[j] == this.Mixing_array[i].mixing_id) {
                this.Alc_Mix = this.Mixing_array[i].description;
              }
            }
          }
          var yes=true;
          this.panelOpenState1 = true;
        }
    
        //Alchol quantity
        if (obj.alcohol_qty_id != null) {
          for (var i = 0; i < this.Quantity_array.length; i++) {
            for (var j = 0; j < obj.alcohol_qty_id.length; j++) {
              if (obj.alcohol_qty_id[j] == this.Quantity_array[i].alcohol_qty_id) {
                this.Alc_Quant = this.Quantity_array[i].description;
              }
            }
          }
          yes=true;
          this.panelOpenState1 = true;
        }else{
          yes = false;
        }
    
        //alchol duration
        if (obj.alc_duration != null) {
          this.alchol_duration = obj.alc_duration;
          this.panelOpenState1 = true;
          yes = true;
        }
    
        //Alchol frequency
        if (obj.alc_frequency != null) {
          this.alcohol_frequency = obj.alc_frequency;
          this.panelOpenState1 = true;
          yes = true;
        }else{
          yes = false;
        }
    
        //Smoke
        if (obj.smoke_duration != null) {
          this.smoke_duration = obj.smoke_duration;
          this.panelOpenState2 = true;
        }
    
        if (obj.smoke_frequency != null) {
          this.smoke_frequency = obj.smoke_frequency;
          this.panelOpenState2 = true;
        }
    
        //Gutka
        if (obj.gutka_duration != null) {
          this.gutka_duration = obj.gutka_duration;
          this.panelOpenState3 = true;            
        }
    
        if (obj.gutka_frequency != null) {
          this.gutka_frequency = obj.gutka_frequency;
          this.panelOpenState3 = true;
        }
    
        if (obj.recom_exec_id != null && obj.recom_exec_id != undefined) {
          for (var i = 0; i < this.Physcial_exercise.length; i++) {
            for (var j = 0; j < obj.recom_exec_id.length; j++) {
              if (obj.recom_exec_id[j] == this.Physcial_exercise[i].exercise_id) {
                this.life_exer = this.Physcial_exercise[i].description;
              }
            }
          }
          this.panelOpenState4 = true;
        }
        //Yoga
        this.yoga_check = [];
        if (obj.yogasanas != null && obj.yogasanas != undefined) {
          for (var i = 0; i < this.Get_theyoga.length; i++) {
            for (var j = 0; j < obj.yogasanas.length; j++) {
              if (obj.yogasanas[j].yoga_asana_id == this.Get_theyoga[i].yoga_asana_id) {
                this.yoga_check.push(this.Get_theyoga[i].yoga_asana_id);
              }
            }
            this.panelOpenState4 = true;
          }
          this.yoga_div = this.yoga_check.length != 0 ? false : true;
        } 
        //Exercise
        if (obj.exercise_dur != null) {
          this.exercise_dur = obj.exercise_dur;
          this.panelOpenState4 = true;
        }
    
        if (obj.exercise_routine != null) {
          for (var i = 0; i < this.exercise_routine_arry.length; i++) {
            for (var j = 0; j < obj.exercise_routine.length; j++) {
              if (obj.exercise_routine[j] == this.exercise_routine_arry[i].exer_rout_id) {
                this.exer_run = this.exercise_routine_arry[i].description;
              }
            }
          }
          this.panelOpenState4 = true;
        }
    
        if (obj.recom_exec_dur != null && obj.recom_exec_dur != undefined) {
          this.life_exerdure = obj.recom_exec_dur;
          this.panelOpenState4 = true;
        }
    
        if (obj.recom_exec_freq != null && obj.recom_exec_freq != undefined) {
          this.life_exe_freq = obj.recom_exec_freq;
          this.panelOpenState4 = true;
        }
    
        if (obj.family_support != null) {
          this.ynopt1 = true;
          this.togg_family ="yes";
        } else {
          this.ynopt1 = false;
          this.togg_family ="no";
        }
    
        if (obj.life_style_id != null) {
          this.life_style_id = obj.life_style_id;
        }
    
        if(this.personaldata.spl != "Gynecology" && this.personaldata.spl=="Cardiology"){
          if(Helper_Class.getRet().family_support =="yes" || Helper_Class.getRet().family_support =="Yes" ){
            this.togg_family1 = true;
            this.ynopt1 = true;
            this.togg_family = "yes";
          }
        }
        this.send_data();

    }
  }

  send_data(){
    var casesheet_habitual=null, case_hist_id = null,yoga_txt = [], exercise_id, yoga_flag;
    var alch = "no", smoke = "no", gutka = "no", exercise ="no", gutka_frequency = undefined, smk_dur = undefined, smk_frq = undefined, alc_dur = undefined, 
    alc_frq = undefined, qty_id = undefined, mix_id = undefined, exe_rout_id = undefined, 
    exe_dur = undefined ,gutka_dur = undefined;

    case_hist_id = this.casesheet_case_hist_id != null ? this.casesheet_case_hist_id : undefined;
    var lif_stl_id = this.life_style_id != null ? this.life_style_id : undefined;

    if (this.panelOpenState1 == true) {
      alch = "yes";
      alc_dur = this.alchol_duration;
      alc_frq = this.alcohol_frequency;
        
      for (var i = 0; i < this.Quantity_array.length; i++) {
        if (this.Alc_Quant == this.Quantity_array[i].description) {
          qty_id = this.Quantity_array[i].alcohol_qty_id;
        } 
      }
    
      for (var i = 0; i < this.Mixing_array.length; i++) {
        if (this.Alc_Mix == this.Mixing_array[i].description) {
          mix_id = this.Mixing_array[i].mixing_id;
        }
      }
    }
      if (this.panelOpenState3 == true) {
        gutka_dur = this.gutka_duration;
        gutka_frequency = this.gutka_frequency;
        gutka = "yes";
      }

      if (this.panelOpenState2 == true) {
        smk_dur = this.smoke_duration;
        smk_frq = this.smoke_frequency;
        smoke = "yes";
      }

      var exe_dur = undefined;
      if (this.panelOpenState4== true) {
        exe_dur = this.exercise_dur;
        exercise="yes"
  
        for (var i = 0; i < this.exercise_routine_arry.length; i++) {
          if (this.exer_run == this.exercise_routine_arry[i].description) {
            exe_rout_id = this.exercise_routine_arry[i].exer_rout_id;
          }
        }
        if (this.life_exer != undefined) {
          if (this.life_exer == "Yoga") {
            yoga_txt = this.yoga_send_data;
            yoga_flag = "yes";
          } else {
            yoga_flag = "no";
          }   
        }else{
          yoga_flag = "";
        }
      }
      
    for (var i = 0; i < this.Physcial_exercise.length; i++) {
      if (this.life_exer == this.Physcial_exercise[i].description) {
        exercise_id = this.Physcial_exercise[i].exercise_id;
      }
    }

    casesheet_habitual={
      case_hist_id: case_hist_id  ,  
      life_style_id: lif_stl_id,
      app_id:this.personaldata.app_id,
      doc_reg_id:this.personaldata.user_id,
      client_reg_id: this.personaldata.Client_id,
      relation_id: this.personaldata.rel_id,
      gutka: gutka,
      gutka_duration: gutka_dur,
      gutka_frequency: gutka_frequency,
      smoke: smoke,
      smoke_duration: smk_dur,
      smoke_frequency: smk_frq,
      mixing_type: mix_id,
      quantity: qty_id,
      alc_duration: alc_dur,
      alc_frequency: alc_frq,
      alcohol: alch,
      exercise_dur: exe_dur,
      exercise_routine_des: this.exer_run,
      exercise_routine: exe_rout_id,
      yoga_asana_id: yoga_txt,
      family_support:this.togg_family,
      // yogasanas_flag: yoga_flag,
      recom_exec_id: exercise_id,
      recom_exec_dur: this.life_exerdure,
      recom_exec_freq: this.life_exe_freq,
      diseases: this.disease_typetxt,
      }


    if(this.personaldata != undefined && this.personaldata != null && this.personaldata != ""){
      if (this.personaldata.sub_id != "" && this.personaldata.sub_id != undefined && this.personaldata.sub_id != null) {
        var sub_rel = this.personaldata.sub_id;
        casesheet_habitual.sub_rel_id = sub_rel;
      } 
    }


    if(this.personaldata.spl == "Nephrology"){
       Helper_nephro_casesheet.gutka_data = gutka;
          Helper_nephro_casesheet.gutka_duration_drop = gutka_dur;
          Helper_nephro_casesheet.gutka_frq_drop = gutka_frequency;
   
          Helper_nephro_casesheet.smoke_data = smoke;
          Helper_nephro_casesheet.smoke_duration_drop = smk_dur;
          Helper_nephro_casesheet.smoke_frq_drop = smk_frq;
   
          Helper_nephro_casesheet.mix_type_array = mix_id;
          Helper_nephro_casesheet.qty_array = qty_id;
          Helper_nephro_casesheet.alc_duration_drop = alc_dur;
          Helper_nephro_casesheet.alc_frq_drop = alc_frq;
          Helper_nephro_casesheet.alc_data = alch;
   
         Helper_nephro_casesheet.exe_data = exe_rout_id;
          Helper_nephro_casesheet.exe_duration_drop = exe_dur;
          Helper_nephro_casesheet.exe_route_drop = this.exer_run;
         // Helper_nephro_casesheet.family_support = this.togg_family,
    }
    Helper_Class.sethabitual(casesheet_habitual)
  }

  //excercise//diagnosis
  Exercise_change(description) {
    this.yoga_div = description == "Yoga" ? false : true;
  }

  // get yoga data
  get_yoga_type() {
    var obj = masterCSData_Helper.getMasterYogaType();
    if(obj != undefined)
      this.Get_theyoga = obj.yogasanas;
    this.getSpecilizationType();
  }

  yoga_click(valuecard, event) {
    if (event == true) {
      this.yoga_send_data.push(
        valuecard
      )
    } else {
      for (var i = 0; this.yoga_send_data.length; i++) {
        if (this.yoga_send_data[i] == valuecard) {
          this.yoga_send_data.splice(i, 1);
          break;
        }
      }
    }
  }
    
  //Get specilization
  getSpecilizationType() {
    var obj = masterCSData_Helper.getMasterSpecilizationType();
    this.get_speclization_arry=[];
    if(obj != undefined){
      for (var i = 0; i < obj.specialization.length; i++) {
        if (obj.specialization[i].spl_name != "-") {
          this.get_speclization_arry.push({
            spl_id: obj.specialization[i].spl_id,
            spl_name: obj.specialization[i].spl_name
          });
        }
      }
    }
    this.display();
  }

  async get_exercise() {
    var obj = masterCSData_Helper.getMasterExercise();
    if(obj != undefined){
      this.Physcial_exercise = obj.exercises;
      if(masterCSData_Helper.getMasterYogaType() == undefined){
        await this.masterCSdata.getYogaType();
        this.get_yoga_type();
      }else{
        this.get_yoga_type();
      }    
    }
  }

  // get duration
  get_duration() {
    var obj = masterCSData_Helper.getMasterExerDuration();
    if(obj != undefined)
      this.Physcial_exercise = obj.exercises;
  }

  //Get exercise routine
  Get_exercise_routine() {
    this.exercise_routine_arry = [];
    var obj = masterCSData_Helper.getMasterExerRoutine();
    if(obj != undefined){
      this.exercise_routine_arry = obj.exercise_routine;
      this.exer_run = this.exercise_routine_arry[0].description;
    }
  }

  //Get quantity data
  Get_quantity() {
    this.Quantity_array = [];

    var obj = masterCSData_Helper.getMasterQunatity();
    if(obj != undefined){
      this.Quantity_array = obj.mixing_quantity;
      this.Alc_Quant = this.Quantity_array[0].description;
    }
  }

  //Get mixing type
  Get_Mixingtype() {
    this.Mixing_array = [];

    var obj = masterCSData_Helper.getMasterMixingtype();
    if(obj != undefined){
      this.Mixing_array = obj.mixing_types;
      this.Alc_Mix = this.Mixing_array[0].description;
    }
  }

  calories_data(data_bmr){
    this.data_bmr=data_bmr;
    var SelectedText1 = this.exer_run;
    var caloriesd=Helper_Class.getCasesheetPersonal();
    if (SelectedText1 == "Light") {
      var get_txt =this.data_bmr * 1.375;
      this.cal_txt = Math.round(get_txt);
      this.habitualservice.sendMessage(this.cal_txt);
    } else if (SelectedText1 == "Moderate") {
      var get_txt = this.data_bmr * 1.55;
      this.cal_txt = Math.round(get_txt);
      this.habitualservice.sendMessage(this.cal_txt);
    } else if (SelectedText1 == "Heavy") {
      var get_txt = this.data_bmr * 1.725;
      this.cal_txt = Math.round(get_txt);
      this.habitualservice.sendMessage(this.cal_txt);
    } else if (SelectedText1 == "Very Heavy") {
      var get_txt =  this.data_bmr * 1.9;
      this.cal_txt = Math.round(get_txt);
      this.habitualservice.sendMessage(this.cal_txt);
    } else {
      var get_txt = parseFloat( this.data_bmr) * 1.375;
      this.cal_txt = Math.round(get_txt);
      this.habitualservice.sendMessage(this.cal_txt);
    }

    if( this.data_bmr<18.5){
      this.bodynatute="Under weight";
    } else if( this.data_bmr>=18.5 && this.data_bmr<=24.9){
      this.bodynatute="Normal weight";
    } else if(this.bmi>=25 && this.data_bmr<=29.9){
      this.bodynatute="Over weight";
    } else{
      this.bodynatute="Obase";
    }
  }

  display(){
    if(this.family != undefined && this.family['key'] != 0){
      if (this.family.recom_exec_id != null && this.family.recom_exec_id != undefined) {
        for (var i = 0; i < this.Physcial_exercise.length; i++) {
          for (var j = 0; j < this.family.recom_exec_id.length; j++) {
            if (this.family.recom_exec_id[j] == this.Physcial_exercise[i].exercise_id) {
              this.life_exer = this.Physcial_exercise[i].description;
            }
          }
        }
        this.panelOpenState4 = true;
      }
      //Yoga
      this.yoga_check = [];
      if (this.family.yogasanas != null && this.family.yogasanas != undefined) {
        for (var i = 0; i < this.Get_theyoga.length; i++) {
          for (var j = 0; j < this.family.yogasanas.length; j++) {
            if (this.family.yogasanas[j].yoga_asana_id == this.Get_theyoga[i].yoga_asana_id) {
              this.yoga_check.push(this.Get_theyoga[i].yoga_asana_id);
            }
          }
          this.panelOpenState4 = true;
        }

        if (this.yoga_check.length != 0) {
          this.yoga_div = false;
        } else {
          this.yoga_div = true;
        }
      } 
      //Exercise
      if (this.family.exercise_dur != null) {
        this.exercise_dur =this.family.exercise_dur;
        this.panelOpenState4 = true;
      }

      if (this.family.exercise_routine != null) {
        for (var i = 0; i < this.exercise_routine_arry.length; i++) {
          for (var j = 0; j < this.family.exercise_routine.length; j++) {
            if (this.family.exercise_routine[j] == this.exercise_routine_arry[i].exer_rout_id) {
              this.exer_run = this.exercise_routine_arry[i].description;
            }
          }
        }
        this.panelOpenState4 = true;
      }

      if (this.family.recom_exec_dur != null && this.family.recom_exec_dur != undefined) {
          this.life_exerdure= this.family.recom_exec_dur;
          this.panelOpenState4 = true;
      }
      if (this.family.recom_exec_freq != null && this.family.recom_exec_freq != undefined) {
          this.life_exe_freq = this.family.recom_exec_freq;
          this.panelOpenState4 = true;
      }

      if (this.family.family_support != null) {
        this.ynopt1 = true;
        this.togg_family ="yes";
        this.changefamily(true);
      } else {
        this.ynopt1 = false;
        this.togg_family ="no";
        this.changefamily(false);
      }

      if (this.family.alcohol_mixing_id != null) {
        for (var i = 0; i < this.Mixing_array.length; i++) {
          for (var j = 0; j < this.family.alcohol_mixing_id.length; j++) {
            if (this.family.alcohol_mixing_id[j] == this.Mixing_array[i].mixing_id) {
              this.Alc_Mix = this.Mixing_array[i].description;
            }
          }
        }
        var yes=true;
        this.panelOpenState1 = true;
      }

      //Alchol quantity
      if (this.family.alcohol_qty_id != null) {
        for (var i = 0; i < this.Quantity_array.length; i++) {
          for (var j = 0; j < this.family.alcohol_qty_id.length; j++) {
            if (this.family.alcohol_qty_id[j] == this.Quantity_array[i].alcohol_qty_id) {
              this.Alc_Quant = this.Quantity_array[i].description;
            }
          }
        }
        yes=true;
        this.panelOpenState1 = true;
      }else{
        yes=false;
      }

      //alchol duration
      if (this.family.alc_duration != null) {
        this.alchol_duration = this.family.alc_duration;
        this.panelOpenState1 = true;
        yes=true;
      }

      //Alchol frequency
      if (this.family.alc_frequency != null) {
        this.alcohol_frequency = this.family.alc_frequency;
        this.panelOpenState1 = true;
        yes=true;
      }else{
        yes=false;
      }

      //Smoke
      if (this.family.smoke_duration != null) {
        this.smoke_duration = this.family.smoke_duration;
        this.panelOpenState2 = true;
      }

      if (this.family.smoke_frequency != null) {
        this.smoke_frequency = this.family.smoke_frequency;
        this.panelOpenState2 = true;
      }

        //Gutka
      if (this.family.gutka_duration != null) {
        this.gutka_duration = this.family.gutka_duration;
        this.panelOpenState3 = true;            
      }

      if (this.family.gutka_frequency != null) {
        this.gutka_frequency = this.family.gutka_frequency;
        this.panelOpenState3 = true;
      }

      if (this.family.life_style_id != null) {
        this.life_style_id = this.family.life_style_id;
      }

      if(this.family.case_hist_id != null && this.family.case_hist_id != undefined){
        this.casesheet_case_hist_id=this.family.case_hist_id;
      }
    }
    this.getCommonData();
  }

  option(d){
    this.ynoption=!this.ynoption;
    if(this.ynoption==true){
      switch(d){
        case 1:{this.ynopt1=true;
          this.togg_family ="yes";
          break;}
      }
    }
    if(this.ynoption==false){
      switch(d){
        case 1:{this.ynopt1=false;
          this.togg_family ="no";break;}
      }
    }
  }

  history(){   
    this.person_hist="Alchol_hist"
    const dialogRef = this.dialog.open(DiabeticpopuphistoryComponent, {
      width: '700px',
      height: '300px',
      data:{
        Diab_case_hist_id: this.casesheet_case_hist_id,
    Life_style_id: this.life_style_id,
    History: this.person_hist,
    }
    });
    dialogRef.afterClosed().subscribe(result => {});
  }

  changefamily(e) {
    this.familytab1 = e;
    this.togg_family = e == false ? "no" : "yes";
  }
}
