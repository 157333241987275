import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { ClientViewService } from '../clientview/client-view.service';
import { ipaddress } from '../../ipaddress';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { DomSanitizer } from '@angular/platform-browser';
import { MindfulnessService } from './mindfulness.service';

@Component({
  selector: 'app-mindfulness',
  templateUrl: './mindfulness.component.html',
  styleUrls: ['./mindfulness.component.scss']
})
export class MindfulnessComponent implements OnInit {
  public mindfulList = [];
  public videoList = [];
  public mind = [];
  public contentList = [];
  public step;
  public substep;
  public mindfulnessOptionFlag:boolean = true;
  public isVideoOnFlag:boolean =false;
  public videoPlaylist;
  customOptions: OwlOptions = {
   autoplay: false,
   autoplaySpeed: 1500,
   loop: true,
   pullDrag: false,
   dots: false,
   navSpeed: 750,
   navText: ['<i class="fa fa-chevron-circle-left left" aria-hidden="true" style="font-size: 50px;color: #bfbbbb;position: absolute;left: 16px;top: 46%;z-index: 1;"></i>', '<i class="fa fa-chevron-circle-right right" aria-hidden="true"></i>'],
   responsive: {
     0: {
       items: 4
     },
     400: {
       items: 6
     },
     740: {
       items: 8
     },
     940: {
       items: 10
     }
   },
   nav: true,
  }

  constructor(public mindService:MindfulnessService,public sanitize:DomSanitizer,public clientservice:ClientViewService,public gservice:CommonDataService,public http:HttpClient,public routes:ActivatedRoute,public router:Router,public toastr:ToastrService) { 
  }

  ngOnInit(): void {
    this.getVediosType('calm');
  }

  getMindFullData(type) {
    this.mindfulList = [];
    this.videoList = [];
    var senddata={
      type:type
    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'lifestyle/gms',senddata,
      { headers: headers })
      .subscribe(
        response => {
          var obj1 = JSON.parse(JSON.stringify(response));
          var obj = obj1.mindfull_list;
          var obj2 = obj1.video_list;
          if (obj != null) {
            for(var i=0; i < obj.length; i++){
                this.mindfulList.push({
                  id: obj[i].mindfullness_id,
                  header : obj[i].header,
                  mindful_desc : obj[i].mindful_desc,
                  mind_image : ipaddress.Ip_with_img_address + obj[i].mind_image,
              })
            }
            for(var i=0; i<obj2.length; i++){
              this.videoList.push({
                video_name: obj2[i].video_name,                
                video_image	 : ipaddress.Ip_with_img_address + obj2[i].video_image, 
                video_path	 : ipaddress.Ip_with_img_address + obj2[i].video_path,                                 
              });
            }
          }
        },
        error => {});
  }

  getVediosType(type){
    this.step = undefined;
    $(".buttonStyle").css("background-color", "#fff");
    if(type == "calm" ){
      $(".calm").css("background-color", "#fff4ea");
      this.getMindFullData(type);
    }else if(type == "relax"){
      $(".relax").css("background-color", "#f8ffea");
      this.getMindFullData(type);
    }else if(type == "focus"){
      $(".focus").css("background-color", "#fff2ef");
      this.getMindFullData(type);
    }
    else if(type == "sleep"){
      $(".sleep").css("background-color", "#e0c3d6");
      this.getMindFullData(type);
    }
  }

  setStep(i,id){
    this.step = i;
    this.getData(id);
  }

  getData(id){
    this.contentList = [];
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "lifestyle/gmd",{mindfullness_id:id}, { headers: headers }).subscribe(
      data => {
        var obj=JSON.parse(JSON.stringify(data));
        if (obj != null) {
            for( var i=0; i < obj.mindfull.length; i++){
                var innerHtml =  obj.mindfull[i].long_description != undefined ? obj.mindfull[i].long_description.replaceAll("\/", '') : "";
                this.contentList.push({
                  mind_id:id,
                  header: obj.mindfull[i].header,
                  long_description : this.sanitize.bypassSecurityTrustHtml(innerHtml),
                  video_audio_link : ipaddress.Ip_with_img_address + obj.mindfull[i].video_audio_link,
                  mediaType : obj.mindfull[i].type,
                })
            }
        }
      },
      error => {});
  }

  setSubStep(i){
    this.substep = i;
  }

  startVedio(link) {
    this.videoPlaylist = "";
    this.isVideoOnFlag = true;
    this.videoPlaylist = link;
    this.mindfulnessOptionFlag = false;
  }

  stop(){
    this.mindfulnessOptionFlag = !this.mindfulnessOptionFlag;
    this.isVideoOnFlag = false;
  }
}
