<div *ngIf="generalsurgFlag == 'min'" class="block1_grid">
    <div class="row" style="padding: 10px 32px;height: 64px">
        <div class="col-md-12 col-lg-12 col-sm-12 col-xl-12 nopadding">
            <mat-label class="matlabel" [hidden]="surgery_hidden">Surgery<br>
                <select class="ipcss" required [(ngModel)]="surgery">
                    <option disabled>select</option>
                    <option *ngFor="let surgery of surgerylist" value={{surgery.procedure_id}}>{{surgery.description}}
                    </option>
                </select>
            </mat-label>
        </div>
    </div>
    <div class="row" style="padding: 10px 32px;height: 64px">
        <div class="col-md-12 col-lg-12 col-sm-12 col-xl-12  nopadding">
            <mat-label class="matlabel" [hidden]="surproc_hidden">Procedure<br>
                <textarea class="ipcss" #myInput id="myInput" rows="1" [(ngModel)]="sur_procedure"
                    maxlength="500"></textarea>
            </mat-label>
        </div>
    </div>
</div>

<div *ngIf="generalsurgFlag == 'max'">
    <div class="row">
        <div class="col-6">
            <div class="cover_div">
                <div class="header_lable">
                    Surgery
                </div>
                <div class="content_cover">
                    <div class="row">
                        <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <mat-label class="matlabel" [hidden]="surgery_hidden">Surgery<br>
                                <select class="ipcss " required [(ngModel)]="surgery" (change)="send_data()">
                                    <option disabled>select</option>
                                    <option *ngFor="let surgery of surgerylist" value={{surgery.procedure_id}}>
                                        {{surgery.description}}
                                    </option>
                                </select>
                            </mat-label>
                        </div>
                        <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <mat-label class="matlabel">Surgery type
                                <select disableOptionCentering class="ipcss noappt_width" [(ngModel)]="surgery_type" (change)="send_data()">
                                    <option value="invasive">Invasive</option>
                                    <option value="non-invasive">Non-Invasive</option>
                                </select>
                            </mat-label>
                        </div>
                        <div class="col-6">
                            <mat-label class="matlabel" [hidden]="surproc_hidden">Surgery procedure<br>
                                <textarea class="ipcss " #myInput id="myInput" rows="1" [(ngModel)]="sur_procedure" (change)="send_data()"
                                    maxlength="500"></textarea>
                            </mat-label>
                        </div>
                        <div class="col-6">
                            <mat-label class="matlabel" [hidden]="surproc_hidden">Investigation<br>
                                <textarea class="ipcss " #myInput id="myInput" rows="1" [(ngModel)]="sug_investigation" (change)="send_data()"
                                    maxlength="500"></textarea>
                            </mat-label>
                        </div>

                        <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <mat-label [hidden]="next_hidden" class="matlabel">Surgery date<br>
                                <!-- <input type="date"  class="ipcss_date" [(ngModel)]="next_data"/> -->
                                <input type="date" class="ipcss" (change)="OnDateMatePicker(next_data);send_data();" [(ngModel)]="next_data" min={{currentDate}} 
                                    #matInput>
                            </mat-label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-6">
            <div class="cover_div">
                <div class="header_lable">
                    Implant details
                </div>
                <div class="content_cover">
                    <div class="row">
                        <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <mat-label class="matlabel" [hidden]="surgery_hidden">Implant name<br>
                                <input class="ipcss" [(ngModel)]="implant_name" matInput />
                            </mat-label>
                        </div>
                        <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <mat-label class="matlabel">Implant make
                                <input class="ipcss" [(ngModel)]="implant_make" matInput [matAutocomplete]="auto" (change)="send_data()" />
                                <mat-autocomplete #auto="matAutocomplete">
                                    <mat-option (click)="selectImplantMaterial(material.material_id)"
                                        *ngFor="let material of implant_materialList"
                                        value="{{material.material_name}}">{{material.material_name}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-label>
                        </div>
                        <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <mat-label class="matlabel">Implant company<br>
                                <input #autoCompleteInput class="ipcss" [(ngModel)]="implant_company" matInput
                                    [matAutocomplete]="auto1" (change)="send_data()"/>
                                <mat-autocomplete #auto1="matAutocomplete">
                                    <mat-option (click)="selectImplantCompany(company.company_id)"
                                        *ngFor="let company of implant_companyList" value="{{company.company_name}}">
                                        {{company.company_name}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-label>
                        </div>
                        <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <mat-label class="matlabel">Others (if any)<br>
                                <textarea class="ipcss " #myInput id="myInput" rows="1" [(ngModel)]="implant_others"
                                    maxlength="500" (change)="send_data()"></textarea>
                            </mat-label>
                        </div>

                        <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <mat-label class="matlabel">Stock if available<br>
                                <!-- <input type="date"  class="ipcss_date" [(ngModel)]="next_data"/> -->
                                <select disableOptionCentering class="ipcss noappt_width" [(ngModel)]="implant_stock" (change)="send_data()">
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </mat-label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-9">
            <div class="cover_div">
                <div class="header_lable">
                    Rehabilitation
                </div>
                <div class="content_cover">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <mat-label class="matlabel" [hidden]="rehab_hidden">Rehab required?<br>
                                <select class="ipcss " required [(ngModel)]="rehab_require" (change)="send_data()">
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-12 col-md-8 col-lg-9 col-xl-9">
                            <mat-label class="matlabel" [hidden]="rehab_hidden">Rehabilitation procedure<br>
                                <textarea class="ipcss" #myInput id="myInput" rows="1" maxlength="500" (change)="send_data()"
                                    [(ngModel)]="rehab_procedure"></textarea>
                            </mat-label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-3">
            <div class="cover_div">
                <div class="header_lable">
                    Anesthesia
                </div>
                <div class="content_cover" style="margin-bottom: 23px;">
                    <div class="row">
                        <div class="col-12">
                            <mat-label class="matlabel" [hidden]="rehab_hidden">Anesthesia<br>
                                <select class="ipcss " required [(ngModel)]="anesthesia" (change)="send_data()">
                                    <option disabled value="Select">Select</option>
                                    <option value="General anesthetics (GA)">General anesthetics (GA)</option>
                                    <option value="Regional anesthetics (RA)">Regional anesthetics (RA)</option>
                                    <option value="Spinal anesthetics (SA)">Spinal anesthetics (SA)</option>
                                    <option value="Laryngeal mask airways(LMA)">Laryngeal mask airways(LMA)</option>
                                </select>
                            </mat-label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12">
            <div class="cover_div">
                <div class="header_lable">
                    Image
                </div>
                <div class="content_cover">
                    <div class="row">
                        <div class="col-12">
                            <mat-checkbox color="primary" (change)="drawImage($event);send_data();" [(ngModel)]="isDraw"> Draw image</mat-checkbox>
                        </div>
                        <div class="col-12" *ngIf="isDraw">
                            <mdb-card>
                                <mdb-card-body class=" p-0 inverted">
                                    <div class="bg-white" *ngIf="viewImgesFlag">
                                        <div class="d-flex justify-content-between">
                                            <div class="d-flex">
                                                <div>
                                                    <img src="{{image}}" id="report_img" class="selectImage img-thumbnail m-1"
                                                        style="width: 70px; height: 55px;" />
                                                </div>
                                                <!-- <div class="position-relative">
                                            <div id="tool_import" class="menu_item import_btn" style="display: none;">
                                                <div id="fileinputs_import"></div>
                                                <img src="../../../assets/dist/images/add-image.png" />
                                            </div>
                                            </div> -->
                                            </div>
                                            <!-- <div></div> -->
                                        </div>
                                    </div>
                                    <div id="svgeditor" class="svgeditor" style="height: 320px !important;">
                
                                        <div id="svg_editor">
                                            <div id="rulers">
                                                <div id="ruler_corner"></div>
                                                <div id="ruler_x">
                                                    <div id="ruler_x_cursor"></div>
                                                    <div>
                                                        <canvas height="15"></canvas>
                                                    </div>
                                                </div>
                                                <div id="ruler_y">
                                                    <div id="ruler_y_cursor"></div>
                                                    <div>
                                                        <canvas width="15"></canvas>
                                                    </div>
                                                </div>
                                            </div>
                
                                            <div id="workarea">
                                                <div id="svgcanvas" style="position:relative">
                
                                                </div>
                                            </div>
                
                                            <div id="menu_bar">
                                                <!-- <a class="menu">
                                                <div class="menu_title" id="logo">
                                                <svg viewBox="0 0 16 16" width="16" height="16" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M-.1 16.1L16 .04V16.1H-.1z" fill="#ccc"/>
                                                    <path d="M0 16.1V.1l16 16H0z" fill="#666"/>
                                                </svg>
                                                </div>
                                                <div class="menu_list">
                                                <div id="tool_about" class="menu_item">About this Editor...</div>
                                                <div class="separator"></div>
                                                <div id="tool_about" class="menu_item">Keyboard Shortcuts...</div>
                                                </div>
                                            </a> -->
                                                <div class="menu">
                                                    <div id="file_menu">
                                                        <div class="menu_title">
                                                            <!-- <div id="tool_open" class="menu_item" style="display: none;" title="Open">
                                                    <div id="fileinputs"></div><img src="../../../assets/dist/images/folder.png" />
                                                </div> -->
                                                        </div>
                                                        <div class="menu_title">
                                                            <div id="" class="menu_item" title="Export as SVG">
                                                                <img (click)="savesend_data();send_data();" src="../../../assets/img/save.svg"
                                                                    style="width:20px" style="width:20px" />
                                                            </div>
                                                        </div>
                                                        <div class="menu_title">
                                                            <div id="tool_export" class="menu_item" title="Export as PNG">
                                                                <img src="../../../assets/dist/images/png.png" />
                                                            </div>
                                                        </div>
                                                        <div class="menu_title">
                                                            <div id="tool_clear" class="menu_item" title="Clear"><img
                                                                    src="../../../assets/dist/images/cancel.png" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="menu">
                                                    <div id="edit_menu">
                                                        <div class="menu_title">
                                                            <div class="menu_item" id="tool_undo" title="Undo"><img
                                                                    src="../../../assets/dist/images/undo.png" />
                                                            </div>
                                                        </div>
                                                        <div class="menu_title">
                                                            <div class="menu_item" id="tool_redo" title="Redo"><img
                                                                    src="../../../assets/dist/images/redo.png" />
                                                            </div>
                                                        </div>
                                                        <!-- <div class="menu_title">
                                                            <div class="menu_item action_selected disabled"
                                                                id="tool_cut" title="Cut"><img
                                                                    src="../../../assets/dist/images/scissors.png" />
                                                            </div>
                                                        </div>
                                                        <div class="menu_title">
                                                            <div class="menu_item action_selected disabled"
                                                                id="tool_copy" title="Copy"><img
                                                                    src="../../../assets/dist/images/copy.png" />
                                                            </div>
                                                        </div>
                                                        <div class="menu_title">
                                                            <div class="menu_item action_selected disabled"
                                                                id="tool_paste" title="Paste"><img
                                                                    src="../../../assets/dist/images/paste.png" />
                                                            </div>
                                                        </div>
                                                        <div class="menu_title">
                                                            <div class="menu_item action_selected disabled"
                                                                id="tool_clone" title="Clone"><img
                                                                    src="../../../assets/dist/images/rubber-stamp.png" />
                                                            </div>
                                                        </div> -->
                                                        <div class="menu_title">
                                                            <div class="menu_item action_selected disabled" id="tool_delete"
                                                                title="Delete"><img src="../../../assets/dist/images/delete.png" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="menu">
                                                    <div id="view_menu">
                                                        <div class="menu_title">
                                                            <div class="menu_item push_button_pressed" id="tool_rulers"
                                                                title="View Rulers"><img src="../../../assets/dist/images/ruler.png" />
                                                            </div>
                                                        </div>
                                                        <div class="menu_title">
                                                            <div class="menu_item" id="tool_wireframe" title="View Wireframe"><img
                                                                    src="../../../assets/dist/images/wireframe.png" />
                                                            </div>
                                                        </div>
                                                        <!-- <div class="menu_title">
                                                    <div class="menu_item" id="tool_snap">Snap to Grid</div>
                                                </div> -->
                                                    </div>
                                                </div>
                                                <div class="menu">
                                                    <div class="menu_title">
                                                        <!-- Zoom buttons -->
                                                        <div id="zoom_panel" class="toolset" title="Change zoom level">
                                                            <div class="draginput select" id="zoom_label">
                                                                <span id="zoomLabel" class="zoom_tool icon_label">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" height="24"
                                                                        viewBox="2 2 20 20" width="27">
                                                                        <path
                                                                            d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
                                                                    </svg>
                                                                </span>
                                                                <select id="zoom_select" >
                                                                    <option value="6">6%</option>
                                                                    <option value="12">12%</option>
                                                                    <option value="16">16%</option>
                                                                    <option value="25">25%</option>
                                                                    <option value="50" selected="selected">50%</option>
                                                                    <option value="75">75%</option>
                                                                    <option value="100"> 100%</option>
                                                                    <option value="150">150%
                                                                    </option>
                                                                    <option value="200">200%
                                                                    </option>
                                                                    <option value="300">300%
                                                                    </option>
                                                                    <option value="400">400%
                                                                    </option>
                                                                    <option value="600">600%
                                                                    </option>
                                                                    <option value="800">800%
                                                                    </option>
                                                                    <option value="1600">1600%
                                                                    </option>
                                                                </select>
                                                                <div class="caret"></div>
                                                                <input id="zoom" size="3" value="100%" type="text"
                                                                    readonly="readonly" />
                                                            </div>
                                                        </div>
                                                    </div>
                
                                                </div>
                
                                                <div>
                                                    <div class="toggle_full_screen menu_title">
                                                        <i class="fa fa-arrows-alt"></i>
                                                    </div>
                                                </div>
                                            </div>
                
                                            <div id="tools_top" class="tools_panel">
                
                                                <div id="canvas_panel" class="context_panel">
                
                                                    <h4 class="clearfix">Canvas</h4>
                
                                                    <label data-title="Change canvas width">
                                                        <input size="3" id="canvas_width" type="text" pattern="[0-9]*" />
                                                        <span class="icon_label">Width</span>
                                                    </label>
                                                    <label data-title="Change canvas height">
                                                        <input id="canvas_height" size="3" type="text" pattern="[0-9]*" />
                                                        <span class="icon_label">Height</span>
                                                    </label>
                
                
                                                    <label data-title="Change canvas color" class="draginput">
                                                        <span>Color</span>
                                                        <div id="color_canvas_tools">
                                                            <div class="color_tool active" id="tool_canvas">
                                                                <div class="color_block">
                                                                    <div id="canvas_bg"></div>
                                                                    <div id="canvas_color"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </label>
                
                                                    <div class="draginput">
                                                        <span>Sizes</span>
                                                        <select id="resolution">
                                                            <option id="selectedPredefined" selected="selected">Custom</option>
                                                            <option>640x480</option>
                                                            <option>800x600</option>
                                                            <option>1024x768</option>
                                                            <option>1280x960</option>
                                                            <option>1600x1200</option>
                                                            <option id="fitToContent" value="content">Fit to
                                                                Content</option>
                                                        </select>
                                                        <div class="caret"></div>
                                                        <label id="resolution_label">Custom</label>
                                                    </div>
                
                                                </div>
                
                                                <div id="rect_panel" class="context_panel">
                                                    <h4 class="clearfix">Rectangle</h4>
                                                    <label>
                                                        <input id="rect_x" class="attr_changer" data-title="Change X coordinate"
                                                            size="3" data-attr="x" pattern="[0-9]*" />
                                                        <span>X</span>
                                                    </label>
                                                    <label>
                                                        <input id="rect_y" class="attr_changer" data-title="Change Y coordinate"
                                                            size="3" data-attr="y" pattern="[0-9]*" />
                                                        <span>Y</span>
                                                    </label>
                                                    <label id="rect_width_tool attr_changer" data-title="Change rectangle width">
                                                        <input id="rect_width" class="attr_changer" size="3" data-attr="width"
                                                            type="text" pattern="[0-9]*" />
                                                        <span class="icon_label">Width</span>
                                                    </label>
                                                    <label id="rect_height_tool" data-title="Change rectangle height">
                                                        <input id="rect_height" class="attr_changer" size="3" data-attr="height"
                                                            type="text" pattern="[0-9]*" />
                                                        <span class="icon_label">Height</span>
                                                    </label>
                                                </div>
                
                                                <div id="path_panel" class="context_panel clearfix">
                                                    <h4 class="clearfix">Path</h4>
                                                    <label>
                                                        <input id="path_x" class="attr_changer"
                                                            data-title="Change ellipse's cx coordinate" size="3" data-attr="x"
                                                            pattern="[0-9]*" />
                                                        <span>X</span>
                                                    </label>
                                                    <label>
                                                        <input id="path_y" class="attr_changer"
                                                            data-title="Change ellipse's cy coordinate" size="3" data-attr="y"
                                                            pattern="[0-9]*" />
                                                        <span>Y</span>
                                                    </label>
                                                </div>
                
                                                <div id="image_panel" class="context_panel clearfix">
                                                    <h4>Image</h4>
                                                    <label>
                                                        <input id="image_x" class="attr_changer" data-title="Change X coordinate"
                                                            size="3" data-attr="x" pattern="[0-9]*" />
                                                        <span>X</span>
                                                    </label>
                                                    <label>
                                                        <input id="image_y" class="attr_changer" data-title="Change Y coordinate"
                                                            size="3" data-attr="y" pattern="[0-9]*" />
                                                        <span>Y</span>
                                                    </label>
                                                    <label>
                                                        <input id="image_width" class="attr_changer" data-title="Change image width"
                                                            size="3" data-attr="width" pattern="[0-9]*" />
                                                        <span class="icon_label">Width</span>
                                                    </label>
                                                    <label>
                                                        <input id="image_height" class="attr_changer" data-title="Change image height"
                                                            size="3" data-attr="height" pattern="[0-9]*" />
                                                        <span class="icon_label">Height</span>
                                                    </label>
                                                </div>
                
                                                <div id="circle_panel" class="context_panel">
                                                    <h4>Circle</h4>
                                                    <label id="tool_circle_cx">
                                                        <span>Center X</span>
                                                        <input id="circle_cx" class="attr_changer" title="Change circle's cx coordinate"
                                                            size="3" data-attr="cx" />
                                                    </label>
                                                    <label id="tool_circle_cy">
                                                        <span>Center Y</span>
                                                        <input id="circle_cy" class="attr_changer" title="Change circle's cy coordinate"
                                                            size="3" data-attr="cy" />
                                                    </label>
                                                    <label id="tool_circle_r">
                                                        <span>Radius</span>
                                                        <input id="circle_r" class="attr_changer" title="Change circle's radius"
                                                            size="3" data-attr="r" />
                                                    </label>
                                                </div>
                
                                                <div id="ellipse_panel" class="context_panel clearfix">
                                                    <h4>Ellipse</h4>
                                                    <label id="tool_ellipse_cx">
                                                        <input id="ellipse_cx" class="attr_changer"
                                                            data-title="Change ellipse's cx coordinate" size="3" data-attr="cx"
                                                            pattern="[0-9]*" />
                                                        <span>X</span>
                                                    </label>
                                                    <label id="tool_ellipse_cy">
                                                        <input id="ellipse_cy" class="attr_changer"
                                                            data-title="Change ellipse's cy coordinate" size="3" data-attr="cy"
                                                            pattern="[0-9]*" />
                                                        <span>Y</span>
                                                    </label>
                                                    <label id="tool_ellipse_rx">
                                                        <input id="ellipse_rx" class="attr_changer"
                                                            data-title="Change ellipse's x radius" size="3" data-attr="rx"
                                                            pattern="[0-9]*" />
                                                        <span>Radius X</span>
                                                    </label>
                                                    <label id="tool_ellipse_ry">
                                                        <input id="ellipse_ry" class="attr_changer"
                                                            data-title="Change ellipse's y radius" size="3" data-attr="ry"
                                                            pattern="[0-9]*" />
                                                        <span>Radius Y</span>
                                                    </label>
                                                </div>
                
                                                <div id="line_panel" class="context_panel clearfix">
                                                    <h4>Line</h4>
                                                    <label id="tool_line_x1">
                                                        <input id="line_x1" class="attr_changer"
                                                            data-title="Change line's starting x coordinate" size="3" data-attr="x1"
                                                            pattern="[0-9]*" />
                                                        <span>Start X</span>
                                                    </label>
                                                    <label id="tool_line_y1">
                                                        <input id="line_y1" class="attr_changer"
                                                            data-title="Change line's starting y coordinate" size="3" data-attr="y1"
                                                            pattern="[0-9]*" />
                                                        <span>Start Y</span>
                                                    </label>
                                                    <label id="tool_line_x2">
                                                        <input id="line_x2" class="attr_changer"
                                                            data-title="Change line's ending x coordinate" size="3" data-attr="x2"
                                                            pattern="[0-9]*" />
                                                        <span>End X</span>
                                                    </label>
                                                    <label id="tool_line_y2">
                                                        <input id="line_y2" class="attr_changer"
                                                            data-title="Change line's ending y coordinate" size="3" data-attr="y2"
                                                            pattern="[0-9]*" />
                                                        <span>End Y</span>
                                                    </label>
                                                </div>
                
                                                <div id="text_panel" class="context_panel">
                                                    <h4>Text</h4>
                                                    <label>
                                                        <input id="text_x" class="attr_changer" data-title="Change text x coordinate"
                                                            size="3" data-attr="x" pattern="[0-9]*" />
                                                        <span>X</span>
                                                    </label>
                                                    <label>
                                                        <input id="text_y" class="attr_changer" data-title="Change text y coordinate"
                                                            size="3" data-attr="y" pattern="[0-9]*" />
                                                        <span>Y</span>
                                                    </label>
                
                                                    <div class="toolset draginput select twocol" id="tool_font_family">
                                                        <!-- Font family -->
                                                        <span>Font</span>
                                                        <div id="preview_font" style="font-family: Helvetica, Arial, sans-serif;">
                                                            Helvetica</div>
                                                        <div class="caret"></div>
                                                        <input id="font_family" data-title="Change Font Family" size="12"
                                                            type="hidden" />
                                                        <select id="font_family_dropdown">
                                                            <option value="Arvo, sans-serif">Arvo
                                                            </option>
                                                            <option value="'Courier New', Courier, monospace">
                                                                Courier</option>
                                                            <option value="Euphoria, sans-serif">
                                                                Euphoria
                                                            </option>
                                                            <option value="Georgia, Times, 'Times New Roman', serif">
                                                                Georgia</option>
                                                            <option value="Helvetica, Arial, sans-serif" selected="selected">Helvetica
                                                            </option>
                                                            <option value="Junction, sans-serif">
                                                                Junction
                                                            </option>
                                                            <option value="'League Gothic', sans-serif">
                                                                League Gothic</option>
                                                            <option value="Oswald, sans-serif">
                                                                Oswald
                                                            </option>
                                                            <option value="'Palatino Linotype', 'Book Antiqua', Palatino, serif">
                                                                Palatino</option>
                                                            <option value="'Trebuchet MS', Gadget, sans-serif">
                                                                Trebuchet</option>
                                                            <option value="'Shadows Into Light', serif">
                                                                Shadows Into Light</option>
                                                            <option value="'Simonetta', serif">
                                                                Simonetta
                                                            </option>
                                                            <option value="'Times New Roman', Times, serif">
                                                                Times</option>
                                                        </select>
                                                        <div class="tool_button" id="tool_bold" data-title="Bold Text [B]">B</div>
                                                        <div class="tool_button" id="tool_italic" data-title="Italic Text [I]">i</div>
                                                    </div>
                
                                                    <label id="tool_font_size" data-title="Change Font Size">
                                                        <input id="font_size" size="3" value="0" />
                                                        <span id="font_sizeLabel" class="icon_label">Font
                                                            Size</span>
                                                    </label>
                                                    <!-- Not visible, but still used -->
                                                    <input id="text" type="text" size="35" />
                                                </div>
                
                                                <!-- formerly gsvg_panel -->
                                                <div id="container_panel" class="context_panel clearfix">
                                                </div>
                
                                                <div id="use_panel" class="context_panel clearfix">
                                                    <div class="tool_button clearfix" id="tool_unlink_use"
                                                        data-title="Break link to reference element (make unique)">
                                                        Break link reference</div>
                                                </div>
                
                                                <div id="g_panel" class="context_panel clearfix">
                                                    <h4>Group</h4>
                                                    <label>
                                                        <input id="g_x" class="attr_changer" data-title="Change groups's x coordinate"
                                                            size="3" data-attr="x" pattern="[0-9]*" />
                                                        <span>X</span>
                                                    </label>
                                                    <label>
                                                        <input id="g_y" class="attr_changer" data-title="Change groups's y coordinate"
                                                            size="3" data-attr="y" pattern="[0-9]*" />
                                                        <span>Y</span>
                                                    </label>
                                                </div>
                
                                                <div id="path_node_panel" class="context_panel clearfix">
                                                    <h4>Edit Path</h4>
                
                                                    <label id="tool_node_x">
                                                        <input id="path_node_x" class="attr_changer"
                                                            data-title="Change node's x coordinate" size="3" data-attr="x" />
                                                        <span>X</span>
                                                    </label>
                                                    <label id="tool_node_y">
                                                        <input id="path_node_y" class="attr_changer"
                                                            data-title="Change node's y coordinate" size="3" data-attr="y" />
                                                        <span>Y</span>
                                                    </label>
                
                                                    <div id="segment_type" class="draginput label">
                                                        <span>Segment Type</span>
                                                        <select id="seg_type" data-title="Change Segment type">
                                                            <option id="straight_segments" selected="selected" value="4">
                                                                Straight
                                                            </option>
                                                            <option id="curve_segments" value="6">
                                                                Curve
                                                            </option>
                                                        </select>
                                                        <div class="caret"></div>
                                                        <label id="seg_type_label">Straight</label>
                                                    </div>
                
                                                    <!--
                                                <label class="draginput checkbox" data-title="Link Control Points">
                                                <span>Linked Control Points</span>
                                                <div class="push_bottom"><input type="checkbox" id="tool_node_link" checked="checked" /></div>
                                                </label>
                                            -->
                
                                                    <div class="clearfix"></div>
                                                    <div class="tool_button" id="tool_node_clone" title="Adds a node">Add Node</div>
                                                    <div class="tool_button" id="tool_node_delete" title="Delete Node">Delete Node</div>
                                                    <div class="tool_button" id="tool_openclose_path" title="Open/close sub-path">Open
                                                        Path
                                                    </div>
                                                    <!--<div class="tool_button" id="tool_add_subpath" title="Add sub-path"></div>-->
                                                </div>
                
                                                <!-- Buttons when a single element is selected -->
                                                <div id="selected_panel" class="context_panel">
                
                                                    <label id="tool_angle" data-title="Change rotation angle" class="draginput">
                                                        <input id="angle" class="attr_changer" size="2" value="0" data-attr="transform"
                                                            data-min="-180" data-max="180" type="text" />
                                                        <span class="icon_label">Rotation</span>
                                                        <div id="tool_angle_indicator">
                                                            <div id="tool_angle_indicator_cursor">
                                                            </div>
                                                        </div>
                                                    </label>
                
                                                    <label class="toolset" id="tool_opacity" data-title="Change selected item opacity">
                                                        <input id="group_opacity" class="attr_changer" data-attr="opacity"
                                                            data-multiplier="0.01" size="3" value="100" step="5" min="0" max="100" />
                                                        <span id="group_opacityLabel" class="icon_label">Opacity</span>
                                                    </label>
                
                                                    <div class="toolset" id="tool_blur" data-title="Change gaussian blur value">
                                                        <label>
                                                            <input id="blur" size="2" value="0" step=".1" min="0" max="10" />
                                                            <span class="icon_label">Blur</span>
                                                        </label>
                                                    </div>
                
                                                    <label id="cornerRadiusLabel" data-title="Change Rectangle Corner Radius">
                                                        <input id="rect_rx" size="3" value="0" data-attr="rx" class="attr_changer"
                                                            type="text" pattern="[0-9]*" />
                                                        <span class="icon_label">Roundness</span>
                                                    </label>
                
                                                    <div class="clearfix"></div>
                                                    <div id="align_tools">
                                                        <h4>Align</h4>
                                                        <div class="toolset align_buttons" id="tool_position">
                                                            <label>
                                                                <div class="col last clear" id="position_opts">
                                                                    <div class="draginput_cell" id="tool_posleft" title="Align Left">
                                                                        <svg viewBox="0 0 27 27" xmlns="http://www.w3.org/2000/svg"
                                                                            width="27" height="27">
                                                                            <path
                                                                                d="M 2 1 L 2 5 L 14 5 L 14 11 L 2 11 L 2 16 L 20 16 L 20 22 L 2 22 L 2 26 L 1 26 L 1 1 L 2 1 Z" />
                                                                        </svg>
                                                                    </div>
                                                                    <div class="draginput_cell" id="tool_poscenter"
                                                                        title="Align Center">
                                                                        <svg viewBox="0 0 27 27" xmlns="http://www.w3.org/2000/svg"
                                                                            width="27" height="27">
                                                                            <path
                                                                                d="M 13 1 L 14 1 L 14 6 L 22 6 L 22 12 L 14 12 L 14 15 L 19 15 L 19 21 L 14 21 L 14 26 L 13 26 L 13 21 L 8 21 L 8 15 L 13 15 L 13 12 L 5 12 L 5 6 L 13 6 L 13 1 Z" />
                                                                        </svg>
                                                                    </div>
                                                                    <div class="draginput_cell" id="tool_posright" title="Align Right">
                                                                        <svg viewBox="0 0 27 27" xmlns="http://www.w3.org/2000/svg"
                                                                            width="27" height="27">
                                                                            <path
                                                                                d="M 25 1 L 25 5 L 13 5 L 13 11 L 25 11 L 25 16 L 7 16 L 7 22 L 25 22 L 25 26 L 26 26 L 26 1 L 25 1 Z" />
                                                                        </svg>
                                                                    </div>
                                                                    <div class="draginput_cell" id="tool_postop" title="Align Top">
                                                                        <svg viewBox="0 0 27 27" xmlns="http://www.w3.org/2000/svg"
                                                                            width="27" height="27">
                                                                            <path
                                                                                d="M 1 2 L 5 2 L 5 14 L 11 14 L 11 2 L 16 2 L 16 20 L 22 20 L 22 2 L 26 2 L 26 1 L 1 1 L 1 2 Z" />
                                                                        </svg>
                                                                    </div>
                                                                    <div class="draginput_cell" id="tool_posmiddle"
                                                                        title="Align Middle">
                                                                        <svg viewBox="0 0 27 27" xmlns="http://www.w3.org/2000/svg"
                                                                            width="27" height="27">
                                                                            <path
                                                                                d="M 26 13 L 26 14 L 21 14 L 21 22 L 15 22 L 15 14 L 12 14 L 12 19 L 6 19 L 6 14 L 1 14 L 1 13 L 6 13 L 6 8 L 12 8 L 12 13 L 15 13 L 15 5 L 21 5 L 21 13 L 26 13 Z" />
                                                                        </svg>
                                                                    </div>
                                                                    <div class="draginput_cell" id="tool_posbottom"
                                                                        title="Align Bottom">
                                                                        <svg viewBox="0 0 27 27" xmlns="http://www.w3.org/2000/svg"
                                                                            width="27" height="27">
                                                                            <path
                                                                                d="M 1 25 L 5 25 L 5 13 L 11 13 L 11 25 L 16 25 L 16 7 L 22 7 L 22 25 L 26 25 L 26 26 L 1 26 L 1 25" />
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                
                                                <!-- Buttons when multiple elements are selected -->
                                                <div id="multiselected_panel" class="context_panel clearfix">
                                                    <h4 class="hidable">Multiple Elements</h4>
                
                                                    <div class="toolset align_buttons" style="position: relative">
                                                        <label id="tool_align_relative" style="margin-top: 10px;">
                                                            <div class="select-input">
                                                                <select id="align_relative_to" title="Align relative to ..."
                                                                    class="select-input">
                                                                    <option id="selected_objects" value="selected">Align to
                                                                        objects
                                                                    </option>
                                                                    <option id="page" value="page">
                                                                        Align to
                                                                        page</option>
                                                                </select>
                                                            </div>
                                                        </label>
                                                        <!-- <h4>.</h4> -->
                                                        <div class="col last clear" id="align_opts">
                                                            <div class="draginput_cell p-0" id="tool_alignleft" title="Align Left">
                                                                <svg viewBox="0 0 27 27" xmlns="http://www.w3.org/2000/svg" width="27"
                                                                    height="27">
                                                                    <path
                                                                        d="M 2 1 L 2 5 L 14 5 L 14 11 L 2 11 L 2 16 L 20 16 L 20 22 L 2 22 L 2 26 L 1 26 L 1 1 L 2 1 Z" />
                                                                </svg>
                                                            </div>
                                                            <div class="draginput_cell" id="tool_aligncenter" title="Align Center">
                                                                <svg viewBox="0 0 27 27" xmlns="http://www.w3.org/2000/svg" width="27"
                                                                    height="27">
                                                                    <path
                                                                        d="M 13 1 L 14 1 L 14 6 L 22 6 L 22 12 L 14 12 L 14 15 L 19 15 L 19 21 L 14 21 L 14 26 L 13 26 L 13 21 L 8 21 L 8 15 L 13 15 L 13 12 L 5 12 L 5 6 L 13 6 L 13 1 Z" />
                                                                </svg>
                                                            </div>
                                                            <div class="draginput_cell" id="tool_alignright" title="Align Right">
                                                                <svg viewBox="0 0 27 27" xmlns="http://www.w3.org/2000/svg" width="27"
                                                                    height="27">
                                                                    <path
                                                                        d="M 25 1 L 25 5 L 13 5 L 13 11 L 25 11 L 25 16 L 7 16 L 7 22 L 25 22 L 25 26 L 26 26 L 26 1 L 25 1 Z" />
                                                                </svg>
                                                            </div>
                                                            <div class="draginput_cell" id="tool_aligntop" title="Align Top">
                                                                <svg viewBox="0 0 27 27" xmlns="http://www.w3.org/2000/svg" width="27"
                                                                    height="27">
                                                                    <path
                                                                        d="M 1 2 L 5 2 L 5 14 L 11 14 L 11 2 L 16 2 L 16 20 L 22 20 L 22 2 L 26 2 L 26 1 L 1 1 L 1 2 Z" />
                                                                </svg>
                                                            </div>
                                                            <div class="draginput_cell" id="tool_alignmiddle" title="Align Middle">
                                                                <svg viewBox="0 0 27 27" xmlns="http://www.w3.org/2000/svg" width="27"
                                                                    height="27">
                                                                    <path
                                                                        d="M 26 13 L 26 14 L 21 14 L 21 22 L 15 22 L 15 14 L 12 14 L 12 19 L 6 19 L 6 14 L 1 14 L 1 13 L 6 13 L 6 8 L 12 8 L 12 13 L 15 13 L 15 5 L 21 5 L 21 13 L 26 13 Z" />
                                                                </svg>
                                                            </div>
                                                            <div class="draginput_cell" id="tool_alignbottom" title="Align Bottom">
                                                                <svg viewBox="0 0 27 27" xmlns="http://www.w3.org/2000/svg" width="27"
                                                                    height="27">
                                                                    <path
                                                                        d="M 1 25 L 5 25 L 5 13 L 11 13 L 11 25 L 16 25 L 16 7 L 22 7 L 22 25 L 26 25 L 26 26 L 1 26 L 1 25" />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="clearfix"></div>
                
                                                </div>
                
                                                <div id="stroke_panel" class="context_panel clearfix">
                                                    <div class="clearfix"></div>
                                                    <h4>Stroke</h4>
                                                    <div class="toolset" data-title="Change stroke">
                                                        <label>
                                                            <input id="stroke_width" size="2" value="5" data-attr="stroke-width" min="0"
                                                                max="99" step="1" />
                                                            <span class="icon_label">Width</span>
                                                        </label>
                                                    </div>
                                                    <div class="stroke_tool draginput">
                                                        <span>Dash</span>
                                                        <select id="stroke_style" data-title="Change stroke dash style">
                                                            <option selected="selected" value="none">—
                                                            </option>
                                                            <option value="2,2">···</option>
                                                            <option value="5,5">- -</option>
                                                            <option value="5,2,2,2">-·-</option>
                                                            <option value="5,2,2,2,2,2">-··-
                                                            </option>
                                                        </select>
                                                        <div class="caret"></div>
                                                        <label id="stroke_style_label">—</label>
                                                    </div>
                
                                                    <label style="display: none;">
                                                        <span class="icon_label">Stroke Join</span>
                                                    </label>
                
                                                    <label style="display: none;">
                                                        <span class="icon_label">Stroke Cap</span>
                                                    </label>
                                                </div>
                
                                            </div> <!-- tools_top -->
                                            <div id="cur_context_panel">
                
                                            </div>
                
                                            <div id="tools_left" class="tools_panel">
                                                <div class="tool_button" id="tool_select" title="Select Tool [V]">
                                                    <svg viewBox="0 0 24 24" width="24" height="24">
                                                        <path
                                                            d="M17.15 20.76l-2.94 1.5-3.68-6-4.41 3V1.24l12.5 12.01-4.41 1.5 2.94 6z" />
                                                    </svg>
                                                </div>
                                                <div class="tool_button" id="tool_fhpath" title="Pencil Tool [P]">
                                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"
                                                        style="transform: scale(-1,1)">
                                                        <path
                                                            d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" />
                                                    </svg>
                                                </div>
                                                <div class="tool_button" id="tool_line" title="Line Tool [L]">
                                                    <svg viewBox="0 0 27 27" xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                                                        <path d="M 3 1 L 26 24 L 24 26 L 1 3 L 3 1 Z">
                                                        </path>
                                                    </svg>
                                                </div>
                                                <div class="tool_button" id="tool_rect" title="Square/Rect Tool [R]">
                                                    <svg viewBox="0 0 27 27" xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                                                        <path d="M 0 8 L 0 24 L 24 24 L 25 8 L 0 8 Z" />
                                                    </svg>
                                                </div>
                                                <div class="tool_button" id="tool_ellipse" title="Ellipse/Circle Tool [C]">
                                                    <svg viewBox="0 0 27 27" xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                                                        <ellipse cx="13" cy="13" rx="13" ry="9">
                                                        </ellipse>
                                                    </svg>
                                                </div>
                                                <div class="tool_button" id="tool_path" title="Path Tool [P]">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 27" width="27" height="27">
                                                        <path
                                                            d="M12.2 1.9c0-.36.86 0 .86 0V14a1.3 1.3 0 10.88 0V1.9s.87-.36.87 0c0 6.81 5.22 11.68 5.22 11.68l-3.25 8.2h-6.55l-3.26-8.2s5.22-4.87 5.22-11.68zM7.83 25.26v-2.61h11.32v2.6H7.84z" />
                                                    </svg>
                                                </div>
                                                <div class="tool_button" id="tool_text" title="Text Tool [T]">
                                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="2 2 20 20" width="27"
                                                        height="27">
                                                        <path d="M5 4v3h5.5v12h3V7H19V4z" />
                                                    </svg>
                                                </div>
                
                                                <div class="tool_button" id="tool_zoom" title="Zoom Tool [Z]">
                                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="2 2 20 20" width="27">
                                                        <path
                                                            d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
                                                    </svg>
                                                </div>
                
                                                <div class="tool_button" id="tool_eyedropper" title="Eyedropper Tool [Z]">
                                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="2 2 20 20" width="27"
                                                        style="transform: scale(-1, 1)">
                                                        <path
                                                            d="M20.71 5.63l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-3.12 3.12-1.93-1.91-1.41 1.41 1.42 1.42L3 16.25V21h4.75l8.92-8.92 1.42 1.42 1.41-1.41-1.92-1.92 3.12-3.12c.4-.4.4-1.03.01-1.42zM6.92 19L5 17.08l8.06-8.06 1.92 1.92L6.92 19z" />
                                                    </svg>
                                                </div>
                
                                                <div id="color_tools">
                                                    <div id="tool_switch" title="Switch stroke and fill colors [X]">
                                                    </div>
                                                    <div class="color_tool active" id="tool_fill">
                                                        <label class="icon_label" title="Change fill color"></label>
                                                        <div class="color_block">
                                                            <div id="fill_bg"></div>
                                                            <div id="fill_color" class="color_block"></div>
                                                        </div>
                                                    </div>
                
                                                    <div class="color_tool" id="tool_stroke">
                                                        <label class="icon_label" title="Change stroke color"></label>
                                                        <div class="color_block">
                                                            <div id="stroke_bg"></div>
                                                            <div id="stroke_color" class="color_block" title="Change stroke color">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> <!-- tools_left -->
                
                                            <div id="tools_bottom" class="tools_panel">
                                                <div id="palette"
                                                    title="Click to change fill color, shift-click to change stroke color">
                                                </div>
                                            </div>
                
                                            <!-- hidden divs -->
                                            <div id="color_picker"></div>
                
                                        </div> <!-- svg_editor -->
                
                                        <div id="svg_source_editor">
                                            <div id="svg_source_overlay"></div>
                                            <div id="svg_source_container">
                                                <div id="save_output_btns">
                                                    <p id="copy_save_note">Copy the contents of this
                                                        box
                                                        into a text editor, then save the file
                                                        with a .svg extension.</p>
                                                    <button id="copy_save_done">Done</button>
                                                </div>
                                                <form>
                                                    <textarea id="svg_source_textarea" spellcheck="false"></textarea>
                                                </form>
                                                <div id="tool_source_back" class="toolbar_button">
                                                    <button id="tool_source_cancel" class="cancel">Cancel</button>
                                                    <button id="tool_source_save" class="ok">Apply
                                                        Changes</button>
                                                </div>
                                            </div>
                                        </div>
                
                                        <div id="dialog_box">
                                            <div id="dialog_box_overlay"></div>
                                            <div id="dialog_container">
                                                <div id="dialog_content"></div>
                                                <div id="dialog_buttons"></div>
                                            </div>
                                        </div>
                
                                        <div class="tools_flyout" id="tools_shapelib">
                                            <div id="shape_buttons"></div>
                                        </div>
                                    </div>
                                    <ul id="cmenu_canvas" class="contextMenu">
                                        <li><a data-href="#cut">Cut <span class="shortcut">⌘X;</span></a>
                                        </li>
                                        <li><a data-href="#copy">Copy<span class="shortcut">⌘C</span></a>
                                        </li>
                                        <li><a data-href="#paste">Paste<span class="shortcut">⌘V</span></a>
                                        </li>
                                        <li class="separator"><a data-href="#delete">Delete<span class="shortcut">⌫</span></a></li>
                                        <li class="separator"><a data-href="#group">Group<span class="shortcut">⌘G</span></a></li>
                                        <li><a data-href="#ungroup">Ungroup<span class="shortcut">⌘⇧G</span></a></li>
                                        <li class="separator"><a data-href="#move_front">Bring to
                                                Front<span class="shortcut">⌘⇧↑</span></a>
                                        </li>
                                        <li><a data-href="#move_up">Bring Forward<span class="shortcut">⌘↑</span></a></li>
                                        <li><a data-href="#move_down">Send Backward<span class="shortcut">⌘↓</span></a></li>
                                        <li><a data-href="#move_back">Send to Back<span class="shortcut">⌘⇧↓</span></a></li>
                                    </ul>
                                    <img id="theimage" hidden />
                                </mdb-card-body>
                            </mdb-card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row" style="margin-top: 20px;">
        <div class="col-6">
            <ul class="nav nav-tabs nav-fill" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                    <button class="nav-link active" id="surgery_notes-tab" data-bs-toggle="tab" data-bs-target="#surgery_notes"
                        type="button" role="tab" aria-controls="surgery_notes" aria-selected="false"
                        (click)="changetab('surgery_notes')"><div class="tab_cover">
                            <div class="tab_icon_cover"><img src="../../../assets/ui_icons/Self.svg" class="iocinStyle"
                                alt=""></div>
                            <div class="lableStyle">Surgery notes</div>
                          </div></button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link " id="post_surgery-tab" data-bs-toggle="tab"
                        data-bs-target="#post_surgery" type="button" role="tab" aria-controls="post_surgery"
                        aria-selected="true" (click)="changetab('post_surgery')"><div class="tab_cover">
                            <div class="tab_icon_cover"><img src="../../../assets/ui_icons/Self.svg" class="iocinStyle"
                                alt=""></div>
                            <div class="lableStyle">Post surgery</div>
                          </div></button>
                </li>
                
            </ul>
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane show active" id="post_surgery" role="tabpanel" aria-labelledby="post_surgery-tab">
                    <div class="row">
                        <div class="col-10">
                            <div class="row">
                                <div class="col-6">
                                    <mat-label class="matlabel" [hidden]="pain_hidden">Do you feel pain?<br>
                                        <select class="ipcss" required [(ngModel)]="feel_pain">
                                            <option value="Yes">Yes</option>
                                            <option selected value="No">No</option>
                                        </select>
                                    </mat-label>
                                </div>
                                <div class="col-6">
                                    <mat-label class="matlabel" [hidden]="severity_hidden">Severity<br>
                                        <select class="ipcss " required [(ngModel)]="severity">
                                            <option value="Severe">Severe</option>
                                            <option value="High">High</option>
                                            <option value="Moderate">Moderate</option>
                                            <option value="Tolerable">Tolerable</option>
                                            <option value="No">No</option>
                                        </select>
                                    </mat-label>
                                </div>
                                <div class="col-12">
                                    <mat-label class="matlabel" [hidden]="treat_hidden">Treatment<br>
                                        <textarea class="ipcss" #myInput id="myInput" required rows="1"
                                            [(ngModel)]="treatment" maxlength="500"></textarea>
                                    </mat-label>
                                </div>
                                <div class="col-12">
                                    <mat-label class="matlabel" [hidden]="sideeffect_hidden">Any side effect<br>
                                        <textarea class="ipcss" #myInput id="myInput" rows="1" required [(ngModel)]="side_effect"
                                            maxlength="500"></textarea>
                                    </mat-label>
                                </div>
                            </div>
                        </div>
                        <div class="col-2 my-auto">
                            <img src="../../../assets/img/pagination_next.png" class="saveimgbtn_inpatinfo"
                                (click)="addFeelPain()" />
                        </div>
                    </div>

                </div>
                <div class="tab-pane " id="surgery_notes" role="tabpanel" aria-labelledby="surgery_notes-tab">
                    <div class="row">
                        <div class="col-10">
                            <textarea class="ipcss" #myInput id="myInput" rows="1" maxlength="750"
                                [(ngModel)]="surg_note" style="height: 150px !important;"></textarea>
                        </div>
                        <div class="col-2 my-auto">
                            <a (click)="addsurgnote()"><img src="../../../assets/img/pagination_next.png"
                                    class="saveimgbtn_inpatinfo" /></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="table_cover " *ngIf="postsurgery_table" style="margin-top: 55px;">
                <div class="col-12" [hidden]="feelpainarray.length==0">
                    <div class="dig_table_overflow " [hidden]="feelpainarray.length==0">
                        <div class="table-responsive">
                            <table class="table table-hover table-dynamic">
                                <thead>
                                    <tr>
                                        <th style="width: 200px;">Date</th>
                                        <th style="width: 200px;">Do you feel pain</th>
                                        <th style="width: 100px;">Severity</th>
                                        <th style="width: 300px;">Treatment</th>
                                        <th style="width: 300px;">Side effects</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let feelpain of feelpainarray">
                                        <td style="width: 200px;">{{feelpain.date}}</td>
                                        <td style="width: 200px;">{{feelpain.pain}}</td>
                                        <td style="width: 100px;">{{feelpain.severity}}</td>
                                        <td style="width: 300px;">{{feelpain.treatment}}</td>
                                        <td style="width: 300px;">{{feelpain.side_effect}}</td>
                                        <td style="width: 40px;" *ngIf="feelpain.add_new=='0'"></td>
                                        <td style="width: 40px; text-align: center;" *ngIf="feelpain.add_new=='1'">
                                            <img src="../../../assets/ui_icons/buttons/pencil_icon.svg"
                                                class="edit_icon" (click)="edit_feelpaint(feelpain)" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </div>
            <div class="table_cover " *ngIf="surgery_table" style="margin-top: 55px;">
                <div class="col-12" [hidden]="surgnoteList.length==0">
                    <div class="dig_table_overflow" [hidden]="surgnoteList.length==0">
                        <div class="table-responsive">
                            <table class="table table-hover table-dynamic">
                                <thead>
                                    <tr>
                                        <th style="width: 120px;">Date</th>
                                        <th style="width: 200px;">Surgery notes</th>
                                        <th>&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let surgnote of surgnoteList">
                                        <td style="width: 120px;">{{surgnote.Date}}</td>
                                        <td style="width: 300px;">{{surgnote.surg_note}}</td>
                                        <td style="width: 40px;" *ngIf="surgnote.add_new=='0'"></td>
                                        <td style="width: 40px;" *ngIf="surgnote.add_new=='1'"><img
                                                src="../../../assets/ui_icons/buttons/pencil_icon.svg" class="edit_icon"
                                                (click)="edit_surgnote(surgnote)" /></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>