<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white ">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Consolidated Bill</h5>
          </div>
          <div class="headerButtons">
            <a (click)="printBill()"><img src="../../../assets/img/printer.svg" width="25px" height="25px" /></a>
            <a (click)="Bill_save()" *ngIf="saveFlag"><img src="../../../assets/ui_icons/buttons/save_button.svg"
                class="saveimgbtn_inpatinfo" style="margin-left: 7px;" /></a>
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row" *ngIf="Insuranceflag">
          <!-- <div class="col-12 col-sm-6 col-md-8 col-lg-8 col-xl-6">
            <div class="cover_div">
              <div class="header_lable">
                 Insurer Details
              </div>
              <div class="content_cover">
                <table>
                  <tr>
                    <th class="basic_tbl">Insurer</th>
                    <td style="text-align: left;"> : {{insurer}}
                    </td>
                  </tr>
                  <tr>
                    <th class="basic_tbl">Card number</th>
                    <td style="text-align: left;"> : {{ins_card_no}} 
                    </td>
                  </tr>
                  <tr>
                    <th class="basic_tbl">CNN number</th>
                    <td style="text-align: left;"> : {{cnnno}} 
                    </td>
                  </tr>
                 
                </table>
              </div>
          </div>
          </div> -->
          <div class="col-12 col-sm-6 col-md-12 col-lg-12 col-xl-12">
            <div class="cover_div">
              <div class="header_lable">
                Patient Details
              </div>
              <div class="content_cover">
                <div class="row">
                  <div class="col-3">
                    <p class="textformat"><span style="font-weight: 550;">MR no:</span> {{this.inpatientidnew}}</p>
                  </div>
                  <div class="col-3">
                    <p class="textformat"><span style="font-weight: 550;">Name:</span> {{this.clientnamenew}}</p>
                  </div>
                  <div class="col-3">
                    <p class="textformat"><span style="font-weight: 550;">Age/gender:</span> {{this.agegendervalue}}</p>
                  </div>
                  <!-- <div class="col-3">
                  <p class="textformat"> Gender: {{this.gender}}</p>
                </div> -->
                  <div class="col-3">
                    <p class="textformat"><span style="font-weight: 550;">Visit id
                        :</span> {{this.inpid}}</p>
                  </div>

                  <div class="col-3">
                    <p class="textformat"><span style="font-weight: 550;">Mobile:</span> {{this.mobilenumber}}</p>
                  </div>
                  <div class="col-3">
                    <p class="textformat"><span style="font-weight: 550;">Doctor:</span> {{this.doctorname}}</p>
                  </div>
                  <div class="col-3">
                    <p class="textformat"><span style="font-weight: 550;">Ward name:</span> {{this.ward_name}}</p>
                  </div>
                  <div class="col-3">
                    <p class="textformat"><span style="font-weight: 550;">Bed no:</span> {{this.bedno}}</p>
                  </div>
                  <div class="col-3">
                    <p class="textformat"><span style="font-weight: 550;">ADT:</span> {{this.admitted_date}}
                      {{this.admitted_timevalue}}</p>
                  </div>
                  <!-- <div class="col-3">
                  <p class="textformat"> Admission time: </p>
                </div> -->
                </div>
                <!-- <table>
                  <tr>
                    <th class="basic_tbl">Patient name</th>
                    <td style="text-align: left;"> : {{clientnamenew}}
                    </td>
                  </tr>
                  <tr>
                    <th class="basic_tbl">Inpatient id </th>
                    <td style="text-align: left;"> : {{inpatientidnew}} 
                    </td>
                  </tr>
                  <tr>
                    <th class="basic_tbl">MR number</th>
                    <td style="text-align: left;"> : {{cnnno}} 
                    </td>
                  </tr>
                  <tr>
                    <th class="basic_tbl">Mobile</th>
                    <td style="text-align: left;"> : {{mobilenumber}} 
                    </td>
                  </tr>
                  <tr>
                    <th class="basic_tbl">Doctor name</th>
                    <td style="text-align: left;"> : {{doctorname}} 
                    </td>
                  </tr>
                 
                </table> -->
              </div>
            </div>
          </div>

        </div>
        <div class="cover_div">
          <div class="header_lable">
            Inpatient details
          </div>
          <div class="content_cover">
            <div class="row">
              <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 select_bottom" style="margin-top: 28px;">
                <input style="margin-right: 5px;" type="checkbox" id="frIns" [(ngModel)]="is_insurance">
                <label for="frIns">For insurance</label><br>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2 select_bottom">
                <mat-label class="matlabel">Filter by<br>
                  <select required class="ipcss" [(ngModel)]="Filter_based" required
                    (change)="Filter_changename(Filter_based)" disableOptionCentering>
                    <!-- <option value="date">Date</option> -->
                    <option value="mrno">MR no</option>
                    <option value="name">Name</option>
                    <option value="mobile">Mobile</option>
                    <!-- <option value="All">location</option> -->
                  </select>
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2" *ngIf="mrnoflag">
                <mat-label class="matlabel">MR no<br>
                  <input type="text" class="ipcss" [(ngModel)]="mr_no" name="mrno"
                    (keyup)="getAppointments($event,'mrno')" type="text" aria-label="Number" matInput
                    [matAutocomplete]="auto" />
                  <mat-autocomplete #auto="matAutocomplete">
                    <mat-option id="optionfont" *ngFor="let location of appointment_list"
                      (click)="Appointment_change(location.client_reg_id,location.relation_id,location.sub_rel_id,location,location.approved_amount)"
                      [value]="location.docid_name">
                      {{location.docid_name}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2" *ngIf="mobileflag">
                <mat-label class="matlabel">Mobile<br>
                  <input type="text" class="ipcss" [(ngModel)]="mobile" name="mobile"
                    (keyup)="getAppointments($event,'mobile')" type="text" aria-label="Number" matInput
                    [matAutocomplete]="auto" />
                  <mat-autocomplete #auto="matAutocomplete">
                    <mat-option id="optionfont" *ngFor="let location of appointment_list"
                      (click)="Appointment_change(location.client_reg_id,location.relation_id,location.sub_rel_id,location,location.approved_amount)"
                      [value]="location.docid_name">
                      {{location.docid_name}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2" *ngIf="nameflag">
                <!-- (click)="location_selected(location) -->
                <mat-label class="matlabel">Patient name<br>
                  <input type="text" class="ipcss" [(ngModel)]="appoint_txt" name="loc_name"
                    (keyup)="getAppointments($event,'name')" type="text" aria-label="Number" matInput
                    [matAutocomplete]="auto" />
                  <mat-autocomplete #auto="matAutocomplete">
                    <mat-option id="optionfont" *ngFor="let location of appointment_list"
                      (click)="Appointment_change(location.client_reg_id,location.relation_id,location.sub_rel_id,location,location.approved_amount)"
                      [value]="location.docid_name">
                      {{location.docid_name}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-label>
              </div>
              
            </div>
          </div>
        </div>
        <div class="billTypeCover">
          <img class="billTypeImg" id="hospBillIMG" (click)="billType('doctor')"
            src="../../../assets/ui_icons/hospital_bill_selected_icon.png" alt="">
          <img class="billTypeImg" id="pharBillIMG" (click)="billType('pharma')"
            src="../../../assets/ui_icons/pharmacy_bill_non_selected_icon.png" alt="">
          <img class="billTypeImg" id="diagBillIMG" (click)="billType('dignosis')"
            src="../../../assets/ui_icons/diagnosis_bill_non_selected_icon.png" alt="">
        </div>
        <div class="billAreaCover">
          <div class="accordion accordion-flush" id="accordionFlushExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingOne">
                <div class="coverRow">
                  <div class="row" style="text-align: center;">
                    <div class="col-1 my-auto ConHeading" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne"
                      aria-expanded="true" aria-controls="flush-collapseOne">Bill ID</div>
                    <div class="col-2 my-auto ConHeading" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne"
                      aria-expanded="true" aria-controls="flush-collapseOne">Bill Date</div>
                    <div class="col-2 my-auto ConHeading" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne"
                      aria-expanded="true" aria-controls="flush-collapseOne">Bill Amount</div>
                    <div class="col-1 my-auto ConHeading" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne"
                      aria-expanded="true" aria-controls="flush-collapseOne">Discount</div>
                    <div class="col-2 my-auto ConHeading" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne"
                      aria-expanded="true" aria-controls="flush-collapseOne">Paid Amount</div>
                    <div class="col-1 my-auto ConHeading" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne"
                      aria-expanded="true" aria-controls="flush-collapseOne">Advance</div>
                    <div class="col-2 my-auto ConHeading" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne"
                      aria-expanded="true" aria-controls="flush-collapseOne">Balance</div>
                    <div class="col-1 my-auto">
                      <img *ngIf="selectedAllFlage == false" (click)="allSelected(true)"
                        src="../../../assets/ui_icons/yellow_box.svg" style="cursor: pointer;" width="15px" />

                      <img *ngIf="selectedAllFlage == true" (click)="allSelected(false)"
                        src="../../../assets/ui_icons/popup_tick_mark.svg" style="cursor: pointer;" width="15px" />
                    </div>
                  </div>
                </div>
              </h2>
            </div>
            <!-- {{DisplayBills |json}} -->
            <div class="accordion-item" *ngFor="let bills of DisplayBills;let i = index">
              <h2 class="accordion-header" id="flush-heading{{i}}">
                <div class="coverRow">
                  <div class="row" style="text-align: center;">
                    <div class="col-1 my-auto ConContent " data-bs-toggle="collapse"
                      [attr.data-bs-target]="'#flush-collapse'+i" aria-expanded="false"
                      aria-controls="flush-collapse{{i}}">{{bills.billing_id}}</div>
                    <div class="col-2 my-auto ConContent" data-bs-toggle="collapse"
                      [attr.data-bs-target]="'#flush-collapse'+i" aria-expanded="false"
                      aria-controls="flush-collapse{{i}}">{{bills.bill_date}}</div>
                    <div class="col-2 my-auto ConContent" data-bs-toggle="collapse"
                      [attr.data-bs-target]="'#flush-collapse'+i" aria-expanded="false"
                      aria-controls="flush-collapse{{i}}">{{bills.bill_amount}}</div>
                    <div class="col-1 my-auto ConContent" data-bs-toggle="collapse"
                      [attr.data-bs-target]="'#flush-collapse'+i" aria-expanded="false"
                      aria-controls="flush-collapse{{i}}">{{bills.discount}}</div>
                    <div class="col-2 my-auto ConContent" data-bs-toggle="collapse"
                      [attr.data-bs-target]="'#flush-collapse'+i" aria-expanded="false"
                      aria-controls="flush-collapse{{i}}">{{bills.paid_amount}}</div>
                    <div class="col-1 my-auto ConContent" data-bs-toggle="collapse"
                      [attr.data-bs-target]="'#flush-collapse'+i" aria-expanded="false"
                      aria-controls="flush-collapse{{i}}">{{bills.advance}}</div>
                    <div class="col-2 my-auto ConContent" data-bs-toggle="collapse"
                      [attr.data-bs-target]="'#flush-collapse'+i" aria-expanded="false"
                      aria-controls="flush-collapse{{i}}">{{bills.balance}}</div>
                    <div class="col-1 my-auto ConContent">

                      <img *ngIf="bills.checked == false" (click)="isAllSelected(bills)"
                        src="../../../assets/ui_icons/yellow_box.svg" style="cursor: pointer;" width="15px" />

                      <img *ngIf="bills.checked == true" (click)="isAllSelected(bills)"
                        src="../../../assets/ui_icons/popup_tick_mark.svg" style="cursor: pointer;" width="15px" />
                      <!-- <mdb-checkbox [default]="true" [(ngModel)]="bills.checked" name="list_name" (change)="isAllSelected(bills)"
                                                value="{{bills.checked}}" [disabled]="bills.balance == '0.00' || bills.balance == '0'"></mdb-checkbox> -->
                    </div>

                  </div>
                </div>
              </h2>
              <div id="flush-collapse{{i}}" class="accordion-collapse collapse" aria-labelledby="flush-heading{{0}}"
                data-bs-parent="#accordionFlushExample">
                <div class="accordion-body" style="border-top: 1px solid #84ccf5;">
                  <table class="table" *ngIf="bills.type == 'doctor' || bills.type == 'diagnosis' ">
                    <thead class="table-light">
                      <tr>
                        <th> S.No </th>
                        <th> Product Name </th>
                        <th> Quantity </th>
                        <th> Amount </th>
                        <th> Advance </th>
                        <th> Total Amount </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let billslist of bills.billsList;let j = index">

                        <td> {{j+1}} </td>
                        <td> {{billslist.name}} </td>
                        <td> {{billslist.quantity}} </td>
                        <td style="text-align: right;padding-right:20px"> {{billslist.amount}} </td>
                        <td style="text-align: right;padding-right:20px"> {{billslist.fee}} </td>
                      </tr>
                    </tbody>
                  </table>
                  <table class="table" *ngIf="bills.type == 'pharma'">
                    <thead class="table-light">
                      <tr>
                        <th> S.No </th>
                        <th> Product Name </th>
                        <th> Batch No </th>
                        <th> Quantity </th>
                        <th> Amount </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let billslist of bills.billsList;let j = index">

                        <td> {{j+1}} </td>
                        <td> {{billslist.name}} </td>
                        <td> {{billslist.batch_no}} </td>
                        <td> {{billslist.quantity}} </td>
                        <td style="text-align: right;padding-right:20px"> {{billslist.amount}} </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" [hidden]="!paytypreq">
          <div class="col-0 col-sm-3 col-md-7 col-lg-7 col-xl-7">
            <div class="cover_div" [hidden]="patient_selectionflag">
              <div class="row">
                <div class="col-12 col-sm-5 col-md-5 col-lg-4 col-xl-4 select_bottom">
                  <mat-label class="matlabel">Payment mode<br>
                    <select class="ipcss " [(ngModel)]="payType1" disableOptionCentering>
                      <option *ngFor="let pay of paytypelist" value={{pay.pay_id}}>{{pay.pay_desc}}</option>
                    </select>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-5 col-md-5 col-lg-4 col-xl-4">
                  <mat-label class="matlabel">Amount<br>
                    <input type=number class="ipcss billing_text_align" [(ngModel)]="amountval" matInput required
                      maxlength="7" />
                  </mat-label>
                </div>
                <div class="col-12 col-sm-2 col-md-3 col-lg-2 col-xl-2">
                  <mat-label class="matlabel ">
                    <img src="../../../assets/ui_icons/buttons/add_button.svg"
                      class="saveimgbtn_inpatinfo_add add_align" (click)="addPaymentDetails()" />

                    <!-- <img src="../../../assets/ui_icons/buttons/next_button.svg" class="amt_button add_align"
                      (click)="addPaymentDetails()" /> -->
                  </mat-label>
                </div>
              </div>

              <div class="row">
                <table>
                  <tr *ngFor="let pay of paymentsarray">
                    <td style="width: 150px !important;">{{pay.pay_desc}}</td>
                    <td style="width: 150px !important;">{{pay.amount}}</td>
                    <td>
                      <img src="../../../assets/img/edit.png" class="billing_edit" (click)="editPayment(pay)">
                      &nbsp;&nbsp;
                      <img src="../../../assets/img/Delete.png" class="billing_edit" (click)="deletePayment(pay)">
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div class="col-0 col-sm-9 col-md-5 col-lg-5 col-xl-5">
            <div class="row">

              <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                <mat-label class="matlabel heightmsr">Total </mat-label>
              </div>
              <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                <input class="ipcss billcreatech heightmsr bill_txt" matInput text-right disabled="true"
                  [(ngModel)]="totalBillAmountvalue" style="text-align: right;" />
              </div>
              <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                <mat-label class="matlabel heightmsr">Advance</mat-label>
              </div>
              <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                <input class="ipcss  heightmsr bill_txt billcreatech" matInput text-right (keyup)="advance_change()"
                  [(ngModel)]="total_advance" maxlength="10" disabled="true" style="text-align: right;" />
              </div>
              <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                <mat-label class="matlabel heightmsr">Discount</mat-label>
              </div>
              <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                <input class="ipcss  heightmsr bill_txt billcreatech" matInput text-right (blur)="discountvalue()"
                  (focus)="checkdiscount()" [(ngModel)]="discountvaluecheck" maxlength="10"
                  style="text-align: right;" />
              </div>
              <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                <mat-label class="matlabel heightmsr">Paid</mat-label>
              </div>
              <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                <input class="ipcss  heightmsr bill_txt billcreatech" matInput text-right (blur)="checkBalance()"
                  [(ngModel)]="paid_bill" maxlength="10" style="text-align: right;" (focus)="checkBalance1()" />
              </div>
              <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6" *ngIf="sponsorDueflag">
                <mat-label class="matlabel heightmsr">Sponsor Due </mat-label>
              </div>
              <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6" *ngIf="sponsorDueflag">
                <input class="ipcss billcreatech heightmsr bill_txt" matInput text-right disabled="true"
                  [(ngModel)]="sponsorDue" style="text-align: right;" />
              </div>

              <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                <mat-label class="matlabel heightmsr">Balance</mat-label>
              </div>
              <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                <input class="ipcss billcreatech heightmsr bill_txt" matInput text-right [(ngModel)]="remaining_billbal"
                  maxlength="10" disabled="true" style="text-align: right;" />
              </div>
            </div>
          </div>
        </div>
        <!-- print -->
        <div [hidden]="true">
          <div #printlogowithname id="printlogowithname">
            <div class="bill_border">
              <div *ngFor="let billprint of billing_print_array">
                <div>
                  <table>
                    <thead>
                      <tr>
                        <th style="width:100%;height: 100px;"></th>
                      </tr>
                    </thead>
                    <tfoot>
                      <tr>
                        <td width="100%">
                          <table style="border:'0';width:100%">
                            <tr>
                              <td style="width:100%;height: 100px;">
                                <table style="width: 100%;">
                                  <tr>
                                    <td style="display: flex;justify-content: center;">
                                      <div>
                                        <img alt="image" src={{hospital_logo}} width="100px" height="100px"
                                          style="vertical-align: middle;">
                                      </div>
                                      <div style="position: relative;top: 10px;left: 10px;text-align: left;">
                                        <span><b>{{hospital_name}}</b>,</span><br />
                                        <span>{{hospitalAddress}}</span><br />
                                        <span>{{hosp_location}}</span> <span>{{hosp_district}}</span><br />
                                        <span>{{hosp_state}} {{hosp_zip}}</span><span
                                          *ngIf="hosp_telephone != undefined"><img src="../../../assets/img/phone.png"
                                            width="15px" height="15px" />{{hosp_telephone}}.</span><br>
                                      </div>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </tfoot>
                    <tbody>
                      <tr>
                        <td class="report-content-cell">
                          <div class="main">
                            <div style="width: 98%; margin: 0 auto;">
                              <h3 style="margin:10px 0 ;" class="heading">Patient details</h3>
                              <table style="width: 100%;">
                                <tr>
                                  <td style="width: 58%; vertical-align: top;">
                                    <div>
                                      <span><strong>MR no: </strong>{{inpatientidnew}} </span>
                                      <br />
                                      <span><strong>Patient name: </strong>{{billprint.client_name}} </span>
                                      <br />
                                      <span><strong>Age / gender: </strong>{{age}} / {{gender}}</span>
                                      <br />
                                      <span><strong>Visit id: </strong>{{billprint.inpatientId}}</span>
                                      <br />
                                      <span><strong>Speciality: </strong>{{specialization_name}}</span>
                                      <br />
                                      <span><strong>Address : </strong></span>
                                      <span *ngIf="billprint.address != ''">{{billprint.address}}</span>
                                      <br />
                                      <span>{{billprint.location}}{{billprint.city}}</span>
                                      <br />
                                      <span>{{billprint.state}}</span>
                                      <br />
                                      <span *ngIf="billprint.telephone != undefined">
                                        <img src="../../../assets/img/phone.png" width="15px" height="15px" />
                                        {{billprint.telephone}}
                                      </span>
                                      <span *ngIf="Insuranceflag"><strong>Insurance corp: </strong>{{insurer}}</span>
                                      <br />
                                    </div>
                                  </td>
                                  <td style="width: 42%;vertical-align: top;">
                                    <div>
                                      <span><strong>Doctor :</strong>{{billprint.doctorname}} </span>
                                      <br />
                                      <span><strong>DOA:</strong> {{admissionDate}} {{admitted_timevalue}} </span>
                                      <br />
                                      <span><strong>DOD:</strong> {{dischargeDate}} {{dischargeTime}}</span>
                                      <br />
                                      <span><strong>Rate plan: </strong>{{rate_plan}}</span>
                                      <br />
                                      <span><strong>Ward & bed no:</strong> {{ward_name}} / {{bedno}}</span>
                                      <br />
                                      <span><strong>Surgery name:</strong> {{surgery_name}}</span>
                                      <br />
                                    </div>

                                    <img *ngIf="barcode_flag" style="width: 130px; height: 35px; margin-top: 5px"
                                      src={{barcode_url}} />
                                  </td>
                                </tr>

                              </table>
                            </div>
                            <div style="width: 98%; margin: 0 auto;" *ngIf="insuranceflag1">
                              <h3 style="margin:10px 0 ;" class="heading">Insurer details</h3>
                              <table style="width: 100%;">
                                <tr>
                                  <td style="width: 60%; vertical-align: top;">
                                    <div>
                                      <span><strong>Patient name : </strong>{{insurer}} </span>
                                      <br />
                                      <span><strong> Insurance card no : </strong>{{ins_card_no}}</span>
                                      <br />
                                      <span><strong>CNN number : </strong></span>
                                      <span>{{cnnno}}</span>
                                      <br />

                                    </div>
                                  </td>


                                </tr>

                              </table>
                            </div>
                            <!-- Bill list particulars -->
                            <div *ngIf="sendDoctor.length != 0" style="width: 98%; margin: 0 auto;">
                              <h3 class="heading">Service Charges</h3>

                              <div class="row">
                                <table
                                  style=" border: 1px solid black;border-collapse: collapse;margin-right: 20px;width:100%;">
                                  <thead>
                                    <tr>
                                      <th style="width: 30%;border: 1px solid black;border-collapse: collapse;">
                                        <b>Service</b>
                                      </th>
                                      <th style="width: 15%;border: 1px solid black;border-collapse: collapse;">
                                        <b>Bill date</b>
                                      </th>
                                      <th style="width: 15%;border: 1px solid black;border-collapse: collapse;">
                                        <b>Quantity</b>
                                      </th>
                                      <!-- <th style="width: 15%;border: 1px solid black;border-collapse: collapse;"> 
                                        <b>Concession (%)</b> 
                                      </th>  -->
                                      <th style="width: 15%;border: 1px solid black;border-collapse: collapse;">
                                        <b>Amount</b>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let billprinttable of docBillDetails">
                                      <td
                                        style="width: 200px; text-align: left;border: 1px solid black;border-collapse: collapse;padding:5px 5px;"
                                        data-th="Type">{{billprinttable.name}}</td>
                                      <td
                                        style="width: 100px;border: 1px solid black;border-collapse: collapse;padding:5px 5px;text-align: center;"
                                        data-th="Type">{{billprinttable.bill_date}} </td>
                                      <td
                                        style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                                        data-th="Fee"> {{billprinttable.quantity}} x {{billprinttable.amount}}</td>
                                      <td
                                        style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                                        data-th="Amount" class="fee_txt">{{billprinttable.fee}}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>

                            <!-- Madical Bills particulars -->
                            <div *ngIf="medcalDetailsArray.length != 0" style="width: 98%; margin: 0 auto;">
                              <h3 class="heading">Medicine bills </h3>
                              <div class="row" *ngIf="insuranceFlag">
                                <table style=" border: 1px solid black;border-collapse: collapse;margin-right: 20px;">
                                  <thead>
                                    <tr>
                                      <th style="width: 200px;border: 1px solid black;border-collapse: collapse;">
                                        <b>Service</b>
                                      </th>
                                      <th style="width: 200px;border: 1px solid black;border-collapse: collapse;">Bill
                                        date
                                      </th>
                                      <th style="width: 200px;border: 1px solid black;border-collapse: collapse;">
                                        Payment
                                        type</th>
                                      <th style="width: 150px;border: 1px solid black;border-collapse: collapse;">
                                        <b>Fee</b>
                                      </th>
                                      <th style="width: 150px;border: 1px solid black;border-collapse: collapse;">
                                        <b>Concession
                                          (%)</b>
                                      </th>
                                      <th style="width: 150px;border: 1px solid black;border-collapse: collapse;">
                                        <b>Amount</b>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let billprinttable of medcalDetailsArray">
                                      <td
                                        style="width: 200px; text-align: left;border: 1px solid black;border-collapse: collapse;padding:5px 5px;"
                                        data-th="Type">{{billprinttable.bill_type}}</td>
                                      <td
                                        style="width: 15%; border: 1px solid black;border-collapse: collapse;text-align: center;"
                                        data-th="Type">{{billprinttable.bill_date}}</td>
                                      <td
                                        style="width: 200px; text-align: left;border: 1px solid black;border-collapse: collapse;"
                                        data-th="Type">{{billprinttable.pay_type}}</td>
                                      <td
                                        style="width: 200px; text-align: left;border: 1px solid black;border-collapse: collapse;"
                                        data-th="Type">{{billprinttable.quantity}}</td>
                                      <td
                                        style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                                        data-th="Fee">{{billprinttable.amount_txt}}</td>
                                      <td
                                        style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                                        data-th="Concession">{{billprinttable.concession}}</td>
                                      <td
                                        style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                                        data-th="Amount" class="fee_txt">{{billprinttable.fee}}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div class="row" *ngIf="!insuranceFlag">
                                <table
                                  style=" width: 100%; border: 1px solid black;border-collapse: collapse;margin-right: 20px;">
                                  <thead>
                                    <tr>
                                      <th style="width: 60%;border: 1px solid black;border-collapse: collapse;">
                                        <b>Bill No</b>
                                      </th>
                                      <th style="width: 15%;border: 1px solid black;border-collapse: collapse;">
                                        <b>Bill date</b>
                                      </th>
                                      <th style="width: 15%;border: 1px solid black;border-collapse: collapse;">
                                        <b>Amount</b>
                                      </th>

                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let billprinttable of medcalDetailsArray">
                                      <td
                                        style="width: 60%;text-align: left;border: 1px solid black;border-collapse: collapse;padding:5px 5px;">
                                        {{billprinttable.name}}
                                      </td>
                                      <td
                                        style="width: 15%;border: 1px solid black;border-collapse: collapse;padding:5px 5px;">
                                        {{billprinttable.bill_date}}
                                      </td>
                                      <td
                                        style="width: 15%;text-align: right;border: 1px solid black;border-collapse: collapse;">
                                        {{billprinttable.amount}}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>

                            <!-- radiology Bills particulars -->
                            <div style="width: 98%;" *ngIf="digDetailsArray.length !=0">
                              <h3 class="heading">Pathology & Radiology</h3>
                              <div class="row">
                                <table style="border-collapse: collapse;margin-right: 20px;width:100%;">
                                  <thead>
                                    <tr>
                                      <th [style]="service_style">
                                        <b>Test Name</b>
                                      </th>
                                      <th [style]="date_style">
                                        <b>Bill date</b>
                                      </th>
                                      <th [style]="quant_style" *ngIf="!bill_qty_flag">
                                        <b>Quantity</b>
                                      </th>
                                      <!-- <th style="width: 15%;border: 1px solid black;border-collapse: collapse;"> 
                                        <b></b> 
                                      </th>  -->
                                      <!-- <th style="width: 15%;border: 1px solid black;border-collapse: collapse;"> 
                                        <b>Concession (%)</b> 
                                      </th>  -->
                                      <th [style]="Amt_style">
                                        <b>Amount</b>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let billprinttable of digDetailsArray">
                                      <td [style]="service_td_style">
                                        {{billprinttable.name}}
                                      </td>
                                      <td [style]="date_td_style">
                                        {{billprinttable.bill_date}}
                                      </td>
                                      <td [style]="quant_td_style" *ngIf="!bill_qty_flag">
                                        {{billprinttable.quantity}} x {{billprinttable.fee}}
                                      </td>

                                      <td [style]="amt_td_style">
                                        {{billprinttable.amount}}
                                      </td>
                                    </tr>
                                    <tr *ngIf="!bill_qty_flag">
                                      <td colspan="2"></td>
                                      <td [style]="subtotal_style">
                                        <div style="width: 100%; text-align: end;"><b>Subtotal</b></div>
                                      </td>
                                      <td [style]="subtotal_style">
                                        <div style="width: 100%; text-align: end;">{{diagBillSubtotal}}</div>
                                      </td>
                                    </tr>
                                    <tr *ngIf="bill_qty_flag">
                                      <td colspan="1"></td>
                                      <td [style]="subtotal_style">
                                        <div style="width: 100%; text-align: end;"><b>Subtotal</b></div>
                                      </td>
                                      <td [style]="subtotal_style">
                                        <div style="width: 100%; text-align: end;">{{diagBillSubtotal}}</div>
                                      </td>
                                    </tr>

                                    <!-- <tr>
                                      <td colspan="2"></td>
                                      <td [style]="subtotal_style">
                                        <div style="width: 100%; text-align: end;"><b>Subtotal</b></div>
                                      </td>
                                      <td [style]="subtotal_style">
                                        <div style="width: 100%; text-align: end;">{{diagBillSubtotal}}</div>
                                      </td>
                                    </tr> -->
                                  </tbody>
                                </table>
                              </div>
                            </div>

                            <!-- Total calculation  -->
                            <div style="width: 100%;">
                              <table>
                                <tr>
                                  <td style="width: 71.5%;background-color: #ffffff;"></td>
                                  <th style="text-align: left;">Bill amount (र)</th>
                                  <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="Bill amount"
                                    class="fee_txt">{{totalBillAmountvalue}}</td>
                                </tr>
                                <tr *ngIf="sponsorDueflag">
                                  <td style="width: 71.5%;background-color: #ffffff;"></td>
                                  <th style="text-align: left;">Sponsor Due (र)</th>
                                  <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="Bill amount"
                                    class="fee_txt">{{sponsorDue}}</td>
                                </tr>


                                <tr>
                                  <td style="width: 71.5%;background-color: #ffffff"></td>
                                  <th style="text-align: left;">Advance (र)</th>
                                  <td style="text-align: right;width: 100px;margin-right: 10px;"
                                    data-th="Advance / Paid र" class="fee_txt">{{total_advance}}</td>
                                </tr>
                                <tr>
                                  <td style="width: 71.5%;background-color: #ffffff"></td>
                                  <th style="text-align: left;"> Paid (र)</th>
                                  <td style="text-align: right;width: 100px;margin-right: 10px;"
                                    data-th="Advance / Paid र" class="fee_txt">
                                    {{paid_bill}}</td>
                                </tr>
                                <tr>
                                  <td style="width: 71.5%;background-color: #ffffff;"></td>
                                  <th style="text-align: left;">Balance (र)</th>
                                  <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="Balance"
                                    class="fee_txt">
                                    {{remaining_billbal}}</td>
                                </tr>
                                <tr *ngIf="discountflag">
                                  <td style="width: 71.5%;background-color: #ffffff;"></td>
                                  <th style="text-align: left;">Discount (र)</th>
                                  <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="Balance"
                                    class="fee_txt">
                                    {{discountvaluecheck}}</td>
                                </tr>
                              </table>
                            </div>
                            <br />
                            <br /><br />
                            <br />

                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div #printbanner id="printbanner">
            <div class="bill_border">
              <div *ngFor="let billprint of billing_print_array">
                <div>
                  <table style="margin-left: 15px !important;margin-right: 15px !important;">
                    <thead>
                      <th>
                        <div style="height: 270px">
                          <div style="height: 120px">
                            <img alt="image" src={{hospital_logo}} width="90%" height="120px"
                              style="vertical-align: middle;">
                          </div>
                          <div style="margin-top: 5px; height: 130px;">
                            <!-- <h3  class="heading">Patient details</h3> -->
                            <table style="width: 98%; border: 1px solid #000;">
                              <tr>
                                <td style="width: 58%; vertical-align: top;">
                                  <div>
                                    <span><strong>MR no: </strong>{{inpatientidnew}} </span>
                                    <br />
                                    <span><strong>Patient name: </strong>{{billprint.client_name}} </span>
                                    <br />
                                    <span><strong>Age / gender: </strong>{{age}} / {{gender}}</span>
                                    <br />
                                    <span><strong>Visit id: </strong>{{billprint.inpatientId}}</span>
                                    <br />
                                    <span><strong>Speciality: </strong>{{specialization_name}}</span>
                                    <br />
                                    <span><strong>Address : </strong></span>
                                    <span *ngIf="billprint.address != ''">{{billprint.address}}</span>
                                    <br />
                                    <span>{{billprint.location}}{{billprint.city}}</span>
                                    <br />
                                    <span>{{billprint.state}}</span>
                                    <br />
                                    <span *ngIf="billprint.telephone != undefined">
                                      <img src="../../../assets/img/phone.png" width="15px" height="15px" />
                                      {{billprint.telephone}}
                                    </span>
                                    <span *ngIf="Insuranceflag"><strong>Insurance corp: </strong>{{insurer}}</span>
                                    <br />
                                    <!-- <span *ngIf="Insuranceflag"><strong>Sponsor: </strong>{{insurer}}</span>
                                    <br /> -->
                                  </div>
                                </td>
                                <td style="width: 42%;vertical-align: top;">
                                  <!-- /dr_name -->
                                  <div>
                                    <span><strong>Doctor :</strong>{{billprint.doctorname}} {{doc_qualif}}</span>
                                    <br />
                                    <span><strong>DOA:</strong> {{admissionDate}} {{admitted_timevalue}} </span>
                                    <br />
                                    <span><strong>DOD:</strong> {{dischargeDate}} {{dischargeTime}}</span>
                                    <br />
                                    <span><strong>Rate plan: </strong>{{rate_plan}}</span>
                                    <br />
                                    <span><strong>Ward & bed no:</strong> {{ward_name}} / {{bedno}}</span>
                                    <br />
                                    <span><strong>Surgery name:</strong> {{surgery_name}}</span>
                                    <br />
                                  </div>

                                  <img *ngIf="barcode_flag" style="width: 130px; height: 35px; margin-top: 5px"
                                    src={{barcode_url}} />
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </th>
                    </thead>
                    <tfoot>
                      <tr>
                        <td width="100%">
                          <table style="border:'0';width:100%">
                            <tr>
                              <td style="width:100%;height: 100px;">&nbsp;</td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </tfoot>
                    <tbody>
                      <tr>
                        <td>
                          <div>
                            <!-- Bill list particulars -->
                            <div *ngIf="sendDoctor.length != 0" style="width: 98%; margin-top: 15px !important;">
                              <h3 class="heading">Service Charges</h3>

                              <div class="row">
                                <table style="border-collapse: collapse;margin-right: 20px;width:100%;">
                                  <thead>
                                    <tr>
                                      <th [style]="service_style">
                                        <b>Service</b>
                                      </th>
                                      <th [style]="date_style">
                                        <b>Bill date</b>
                                      </th>
                                      <th [style]="quant_style" *ngIf="!bill_qty_flag">
                                        <b>Quantity</b>
                                      </th>
                                      <!-- <th [style]="quant_style" *ngIf="bill_qty_flag">
                                        <b>Fees</b>
                                      </th> -->
                                      <!-- <th style="width: 15%;border: 1px solid black;border-collapse: collapse;"> 
                                        <b>Concession (%)</b> 
                                      </th>  -->
                                      <th [style]="Amt_style">
                                        <b>Amount</b>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let billprinttable of docBillDetails">
                                      <td [style]="service_td_style" data-th="Type">{{billprinttable.name}}
                                      </td>
                                      <td [style]="date_td_style" data-th="Type">{{billprinttable.bill_date}}
                                      </td>
                                      <td [style]="quant_td_style" *ngIf="!bill_qty_flag" data-th="Fee">
                                        <div *ngIf="!bill_qty_flag">{{billprinttable.fee}} x {{billprinttable.quantity}}
                                        </div>

                                      </td>
                                      <td [style]="amt_td_style" data-th="Amount" class="fee_txt">{{billprinttable.fee}}
                                      </td>
                                    </tr>
                                    <tr *ngIf="!bill_qty_flag">
                                      <td colspan="2"></td>
                                      <td [style]="subtotal_style">
                                        <div style="width: 100%; text-align: end;"><b>Subtotal</b></div>
                                      </td>
                                      <td [style]="subtotal_style">
                                        <div style="width: 100%; text-align: end;">{{docBillSubtotal}}</div>
                                      </td>
                                    </tr>
                                    <tr *ngIf="bill_qty_flag">
                                      <td colspan="1"></td>
                                      <td [style]="subtotal_style">
                                        <div style="width: 100%; text-align: end;"><b>Subtotal</b></div>
                                      </td>
                                      <td [style]="subtotal_style">
                                        <div style="width: 100%; text-align: end;">{{docBillSubtotal}}</div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>

                            <!-- radiology Bills particulars -->
                            <div style="width: 98%;" *ngIf="digDetailsArray.length !=0">
                              <h3 class="heading">Pathology & Radiology</h3>
                              <div class="row">
                                <table style="border-collapse: collapse;margin-right: 20px;width:100%;">
                                  <thead>
                                    <tr>
                                      <th [style]="service_style">
                                        <b>Test Name</b>
                                      </th>
                                      <th [style]="date_style">
                                        <b>Bill date</b>
                                      </th>
                                      <th [style]="quant_style" *ngIf="!bill_qty_flag">
                                        <b>Quantity</b>
                                      </th>
                                      <!-- <th style="width: 15%;border: 1px solid black;border-collapse: collapse;"> 
                                        <b></b> 
                                      </th>  -->
                                      <!-- <th style="width: 15%;border: 1px solid black;border-collapse: collapse;"> 
                                        <b>Concession (%)</b> 
                                      </th>  -->
                                      <th [style]="Amt_style">
                                        <b>Amount</b>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let billprinttable of digDetailsArray">
                                      <td [style]="service_td_style">
                                        {{billprinttable.name}}
                                      </td>
                                      <td [style]="date_td_style">
                                        {{billprinttable.bill_date}}
                                      </td>
                                      <td [style]="quant_td_style" *ngIf="!bill_qty_flag">
                                        {{billprinttable.quantity}} x {{billprinttable.fee}}
                                      </td>

                                      <td [style]="amt_td_style">
                                        {{billprinttable.amount}}
                                      </td>
                                    </tr>
                                    <tr *ngIf="!bill_qty_flag">
                                      <td colspan="2"></td>
                                      <td [style]="subtotal_style">
                                        <div style="width: 100%; text-align: end;"><b>Subtotal</b></div>
                                      </td>
                                      <td [style]="subtotal_style">
                                        <div style="width: 100%; text-align: end;">{{diagBillSubtotal}}</div>
                                      </td>
                                    </tr>
                                    <tr *ngIf="bill_qty_flag">
                                      <td colspan="1"></td>
                                      <td [style]="subtotal_style">
                                        <div style="width: 100%; text-align: end;"><b>Subtotal</b></div>
                                      </td>
                                      <td [style]="subtotal_style">
                                        <div style="width: 100%; text-align: end;">{{diagBillSubtotal}}</div>
                                      </td>
                                    </tr>

                                    <!-- <tr>
                                      <td colspan="2"></td>
                                      <td [style]="subtotal_style">
                                        <div style="width: 100%; text-align: end;"><b>Subtotal</b></div>
                                      </td>
                                      <td [style]="subtotal_style">
                                        <div style="width: 100%; text-align: end;">{{diagBillSubtotal}}</div>
                                      </td>
                                    </tr> -->
                                  </tbody>
                                </table>
                              </div>
                            </div>

                            <!-- Madical Bills particulars -->
                            <div *ngIf="medcalDetailsArray.length != 0" style="width: 98%;">
                              <h3 class="heading">Medicine bills </h3>
                              <div class="row" *ngIf="insuranceFlag">
                                <table style="width: 100%; border-collapse: collapse;margin-right: 20px;">
                                  <thead>
                                    <tr>
                                      <th [style]="service_style">
                                        <b>Medicine</b>
                                      </th>
                                      <th [style]="date_style">Bill
                                        date</th>
                                      <th [style]="quant_style">
                                        Quantity</th>

                                      <!-- <th style="width: 200px;border: 1px solid black;border-collapse: collapse;">Payment type</th> -->
                                      <!-- <th style="width: 150px;border: 1px solid black;border-collapse: collapse;"><b>Fee</b></th> -->
                                      <!-- <th style="width: 150px;border: 1px solid black;border-collapse: collapse;"><b>Concession (%)</b></th> -->
                                      <th [style]="Amt_style">
                                        <b>Bill Amount</b>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let billprinttable of medcalDetailsArray">
                                      <td [style]="service_td_style" data-th="Type">{{billprinttable.name}}</td>
                                      <td [style]="date_td_style" data-th="Type">{{billprinttable.bill_date}}</td>
                                      <td [style]="quant_td_style" data-th="Type">{{billprinttable.quantity}}</td>
                                      <td [style]="amt_td_style" data-th="Fee">{{billprinttable.amount}}</td>
                                    </tr>
                                    <tr>
                                      <td colspan="2"></td>
                                      <td [style]="subtotal_style">
                                        <div style="width: 100%; text-align: end;"><b>Subtotal</b></div>
                                      </td>
                                      <td [style]="subtotal_style">
                                        <div style="width: 100%; text-align: end;">{{medBillSubtotal}}</div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div class="row" *ngIf="!insuranceFlag">
                                <table style=" width: 100%; border-collapse: collapse;margin-right: 20px;">
                                  <thead>
                                    <tr>
                                      <th [style]="not_ins_serviceStyle"><b>Bill
                                          No</b></th>
                                      <th [style]="not_ins_dateStyle"><b>Bill
                                          date</b></th>
                                      <th [style]="not_ins_amtStyle">
                                        <b>Amount</b>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let billprinttable of medcalDetailsArray">
                                      <td [style]="service_td_style">
                                        {{billprinttable.name}}
                                      </td>
                                      <td [style]="date_td_style">
                                        {{billprinttable.bill_date}}
                                      </td>
                                      <td [style]="not_ins_td_amtStyle">
                                        {{billprinttable.amount}}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td></td>
                                      <td [style]="subtotal_style">
                                        <div style="width: 100%; text-align: end;"><b>Subtotal</b></div>
                                      </td>
                                      <td [style]="subtotal_style">
                                        <div style="width: 100%; text-align: end;">{{medBillSubtotal}}</div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>

                            <!-- Total calculation  -->
                            <div style="width: 98%; margin-top: 5px;">
                              <table>
                                <tr>
                                  <td style="width: 560px;background-color: #ffffff;"></td>
                                  <th style="text-align: left;width: 105px;">Bill amount (र)</th>
                                  <td style="text-align: right;width: 105px;margin-right: 20px;" data-th="Bill amount"
                                    class="fee_txt">{{totalBillAmountvalue}}</td>
                                </tr>
                                <tr *ngIf="sponsorDueflag">
                                  <td style="width: 560px;background-color: #ffffff;"></td>
                                  <th style="text-align: left;width: 105px;">Sponsor Due (र)</th>
                                  <td style="text-align: right;width: 105px;margin-right: 20px;" data-th="Bill amount"
                                    class="fee_txt">{{sponsorDue}}</td>
                                </tr>

                                <tr *ngIf="advanceflag">
                                  <td style="width: 560px;background-color: #ffffff" *ngIf="advanceflag"></td>
                                  <th style="text-align: left;width: 105px;">Advance (र)</th>
                                  <td style="text-align: right;width: 105px;margin-right: 20px;"
                                    data-th="Advance / Paid र" class="fee_txt">{{total_advance}}</td>
                                </tr>
                                <tr *ngIf="discountflag">
                                  <td style="width: 560px;background-color: #ffffff;"></td>
                                  <th style="text-align: left;width: 105px;">Discount (र)</th>
                                  <td style="text-align: right;width: 105px;margin-right: 20px;" data-th="Balance"
                                    class="fee_txt">
                                    {{discountvaluecheck}}</td>
                                </tr>
                                <tr *ngIf="paidflag">
                                  <td style="width: 560px;background-color: #ffffff" *ngIf="paidflag"></td>
                                  <th style="text-align: left;width: 105px;"> Paid (र)</th>
                                  <td style="text-align: right;width: 105px;margin-right: 20px;"
                                    data-th="Advance / Paid र" class="fee_txt">
                                    {{paid_bill}}</td>
                                </tr>
                                <tr *ngIf="balanceflag">
                                  <td style="width: 560px;background-color: #ffffff;" *ngIf="balanceflag"></td>
                                  <th style="text-align: left;width: 105px;">Balance (र)</th>
                                  <td style="text-align: right;width: 105px;margin-right: 20px;" data-th="Balance"
                                    class="fee_txt">
                                    {{remaining_billbal}}</td>
                                </tr>

                              </table>
                            </div>
                            <br />
                            <br /><br />
                            <br />
                            <!-- <table>
                              <tr>
                                <td>
                                  <p style="margin-left: 350px;font-size: 13px"><b><i>This is digitally generated bill</i></b></p>
                                  <br />
                                </td>
                              </tr>
                            </table> -->
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div #printnoheader id="printnoheader">
            <div class="bill_border">
              <div *ngFor="let billprint of billing_print_array">
                <div>
                  <table
                    style="margin-left: 15px !important;margin-right: 15px !important; font-size: 15px !important;">
                    <thead>
                      <th>
                        <div style="height: 145px">&nbsp;</div>
                        <div style="margin-top: 5px; height: 125px;">
                          <!-- <h3  class="heading">Patient details</h3> -->
                          <table style="width: 98%; border: 1px solid #000;">
                            <tr>
                              <td style="width: 58%; vertical-align: top;">
                                <div>
                                  <span><strong>MR no: </strong>{{inpatientidnew}} </span>
                                  <br />
                                  <span><strong>Patient name: </strong>{{billprint.client_name}} </span>
                                  <br />
                                  <span><strong>Age / gender: </strong>{{age}} / {{gender}}</span>
                                  <br />
                                  <span><strong>Visit id: </strong>{{billprint.inpatientId}}</span>
                                  <br />
                                  <span><strong>Speciality: </strong>{{specialization_name}}</span>
                                  <br />
                                  <span><strong>Address : </strong></span>
                                  <span *ngIf="billprint.address != ''">{{billprint.address}}</span>
                                  <br />
                                  <span>{{billprint.location}}{{billprint.city}}</span>
                                  <br />
                                  <span>{{billprint.state}}</span>
                                  <br />
                                  <span *ngIf="billprint.telephone != undefined">
                                    <img src="../../../assets/img/phone.png" width="15px" height="15px" />
                                    {{billprint.telephone}}
                                  </span>
                                  <span *ngIf="Insuranceflag"><strong>Insurance corp: </strong>{{insurer}}</span>
                                  <br />
                                  <!-- <span *ngIf="Insuranceflag"><strong>Sponsor: </strong>{{insurer}}</span>
                                  <br /> -->
                                </div>
                              </td>
                              <td style="width: 42%;vertical-align: top;">
                                <!-- /dr_name -->
                                <div>
                                  <span><strong>Doctor :</strong>{{billprint.doctorname}} {{doc_qualif}}</span>
                                  <br />
                                  <span><strong>DOA:</strong> {{admissionDate}} {{admitted_timevalue}} </span>
                                  <br />
                                  <span><strong>DOD:</strong> {{dischargeDate}} {{dischargeTime}}</span>
                                  <br />
                                  <span><strong>Rate plan: </strong>{{rate_plan}}</span>
                                  <br />
                                  <span><strong>Ward & bed no:</strong> {{ward_name}} / {{bedno}}</span>
                                  <br />
                                  <span><strong>Surgery name:</strong> {{surgery_name}}</span>
                                  <br />
                                </div>

                                <img *ngIf="barcode_flag" style="width: 130px; height: 35px; margin-top: 5px"
                                  src={{barcode_url}} />
                              </td>
                            </tr>
                          </table>
                        </div>
                        <div style="width: 98%; margin: 0 auto;" *ngIf="insuranceFlag">
                          <h3 style="margin:10px 0 ;" class="heading">Insurer details</h3>
                          <table style="width: 100%;">
                            <tr>
                              <td style="width: 60%; vertical-align: top;">
                                <div>
                                  <span><strong>Patient name : </strong>{{insurer}} </span>
                                  <br />
                                  <span><strong> Insurance card no : </strong>{{ins_card_no}}</span>
                                  <br />
                                  <span><strong>CNN number : </strong></span>
                                  <span>{{cnnno}}</span>
                                  <br />
                                </div>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </th>
                    </thead>

                    <tbody>
                      <tr>
                        <td>
                          <div>
                            <!-- Bill list particulars -->
                            <div *ngIf="sendDoctor.length != 0" style="width: 98%; margin-top: 10px !important;">
                              <h3 class="heading">Service Charges</h3>

                              <div class="row">
                                <table style="border-collapse: collapse;margin-right: 20px;width:100%;">
                                  <thead>
                                    <tr>
                                      <th [style]="service_style">
                                        <b>Service</b>
                                      </th>
                                      <th [style]="date_style">
                                        <b>Bill date</b>
                                      </th>
                                      <th [style]="quant_style" *ngIf="!bill_qty_flag">
                                        <b>Quantity</b>
                                      </th>

                                      <!-- <th style="width: 15%;border: 1px solid black;border-collapse: collapse;"> 
                                        <b>Concession (%)</b> 
                                      </th>  -->
                                      <th [style]="Amt_style">
                                        <b>Amount</b>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let billprinttable of docBillDetails">
                                      <td [style]="service_td_style" data-th="Type">{{billprinttable.name}}
                                      </td>
                                      <td [style]="date_td_style" data-th="Type">
                                        {{billprinttable.bill_date}}
                                      </td>
                                      <td [style]="quant_td_style" *ngIf="!bill_qty_flag" data-th="Fee">
                                        <div *ngIf="!bill_qty_flag">{{billprinttable.fee}} x {{billprinttable.quantity}}
                                        </div>

                                      </td>

                                      <td [style]="amt_td_style" data-th="Amount" class="fee_txt">{{billprinttable.fee}}
                                      </td>
                                    </tr>
                                    <tr *ngIf="!bill_qty_flag">
                                      <td colspan="2"></td>
                                      <td [style]="subtotal_style">
                                        <div style="width: 100%; text-align: end;"><b>Subtotal</b></div>
                                      </td>
                                      <td [style]="subtotal_style">
                                        <div style="width: 100%; text-align: end;">{{docBillSubtotal}}</div>
                                      </td>
                                    </tr>
                                    <tr *ngIf="bill_qty_flag">
                                      <td colspan="1"></td>
                                      <td [style]="subtotal_style">
                                        <div style="width: 100%; text-align: end;"><b>Subtotal</b></div>
                                      </td>
                                      <td [style]="subtotal_style">
                                        <div style="width: 100%; text-align: end;">{{docBillSubtotal}}</div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>

                            <!-- radiology Bills particulars -->
                            <div style="width: 98%;" *ngIf="digDetailsArray.length !=0">
                              <h3 class="heading">Pathology & Radiology</h3>
                              <div class="row">
                                <table style="border-collapse: collapse;margin-right: 20px;width:100%;">
                                  <thead>
                                    <tr>
                                      <th [style]="service_style">
                                        <b>Test Name</b>
                                      </th>
                                      <th [style]="date_style">
                                        <b>Bill date</b>
                                      </th>
                                      <th [style]="quant_style" *ngIf="!bill_qty_flag">
                                        <b>Quantity</b>
                                      </th>
                                      <!-- <th style="width: 15%;border: 1px solid black;border-collapse: collapse;"> 
                                        <b></b> 
                                      </th>  -->
                                      <!-- <th style="width: 15%;border: 1px solid black;border-collapse: collapse;"> 
                                        <b>Concession (%)</b> 
                                      </th>  -->
                                      <th [style]="Amt_style">
                                        <b>Amount</b>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let billprinttable of digDetailsArray">
                                      <td [style]="service_td_style">
                                        {{billprinttable.name}}
                                      </td>
                                      <td [style]="date_td_style">
                                        {{billprinttable.bill_date}}
                                      </td>
                                      <td [style]="quant_td_style" *ngIf="!bill_qty_flag">
                                        {{billprinttable.quantity}} x {{billprinttable.fee}}
                                      </td>

                                      <td [style]="amt_td_style">
                                        {{billprinttable.amount}}
                                      </td>
                                    </tr>
                                    <tr *ngIf="!bill_qty_flag">
                                      <td colspan="2"></td>
                                      <td [style]="subtotal_style">
                                        <div style="width: 100%; text-align: end;"><b>Subtotal</b></div>
                                      </td>
                                      <td [style]="subtotal_style">
                                        <div style="width: 100%; text-align: end;">{{diagBillSubtotal}}</div>
                                      </td>
                                    </tr>
                                    <tr *ngIf="bill_qty_flag">
                                      <td colspan="1"></td>
                                      <td [style]="subtotal_style">
                                        <div style="width: 100%; text-align: end;"><b>Subtotal</b></div>
                                      </td>
                                      <td [style]="subtotal_style">
                                        <div style="width: 100%; text-align: end;">{{diagBillSubtotal}}</div>
                                      </td>
                                    </tr>

                                    <!-- <tr>
                                      <td colspan="2"></td>
                                      <td [style]="subtotal_style">
                                        <div style="width: 100%; text-align: end;"><b>Subtotal</b></div>
                                      </td>
                                      <td [style]="subtotal_style">
                                        <div style="width: 100%; text-align: end;">{{diagBillSubtotal}}</div>
                                      </td>
                                    </tr> -->
                                  </tbody>
                                </table>
                              </div>
                            </div>

                            <!-- Madical Bills particulars -->
                            <div *ngIf="medcalDetailsArray.length != 0" style="width: 98%;">
                              <h3 class="heading">Medicine bills </h3>
                              <div class="row" *ngIf="insuranceFlag">
                                <table style="width: 100%; border-collapse: collapse;margin-right: 20px;">
                                  <thead>
                                    <tr>
                                      <th [style]="service_style">
                                        <b>Medicine</b>
                                      </th>
                                      <th [style]="date_style">Bill
                                        date</th>
                                      <th [style]="quant_style" *ngIf="!bill_qty_flag">
                                        Quantity</th>

                                      <!-- <th style="width: 200px;border: 1px solid black;border-collapse: collapse;">Payment type</th> -->
                                      <!-- <th style="width: 150px;border: 1px solid black;border-collapse: collapse;"><b>Fee</b></th> -->
                                      <!-- <th style="width: 150px;border: 1px solid black;border-collapse: collapse;"><b>Concession (%)</b></th> -->
                                      <th [style]="Amt_style">
                                        <b>Bill Amount</b>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let billprinttable of medcalDetailsArray">
                                      <td [style]="service_td_style" data-th="Type">{{billprinttable.name}}</td>
                                      <td [style]="date_td_style" data-th="Type">{{billprinttable.bill_date}}</td>
                                      <td [style]="quant_td_style" data-th="Type">{{billprinttable.quantity}}</td>

                                      <!-- <td
                                        style="width: 200px; text-align: left;border: 1px solid black;border-collapse: collapse;"
                                        data-th="Type">{{billprinttable.pay_type}}</td> -->
                                      <!-- <td
                                        style="width: 200px; text-align: left;border: 1px solid black;border-collapse: collapse;"
                                        data-th="Type">{{billprinttable.quantity}}</td> -->
                                      <td [style]="amt_td_style" data-th="Fee">{{billprinttable.amount}}</td>
                                      <!-- <td
                                        style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                                        data-th="Concession">{{billprinttable.concession}}</td>
                                      <td
                                        style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                                        data-th="Amount" class="fee_txt">{{billprinttable.fee}}</td> -->
                                    </tr>
                                    <tr>
                                      <td colspan="2"></td>
                                      <td [style]="subtotal_style">
                                        <div style="width: 100%; text-align: end;"><b>Subtotal</b></div>
                                      </td>
                                      <td [style]="subtotal_style">
                                        <div style="width: 100%; text-align: end;">{{medBillSubtotal}}</div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div class="row" *ngIf="!insuranceFlag">
                                <table style=" width: 100%; border-collapse: collapse;margin-right: 20px;">
                                  <thead>
                                    <tr>
                                      <th [style]="not_ins_serviceStyle"><b>Bill
                                          No</b></th>
                                      <th [style]="not_ins_dateStyle"><b>Bill
                                          date</b></th>
                                      <th [style]="not_ins_amtStyle">
                                        <b>Amount</b>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let billprinttable of medcalDetailsArray">
                                      <td [style]="service_td_style">
                                        {{billprinttable.name}}
                                      </td>
                                      <td [style]="date_td_style">
                                        {{billprinttable.bill_date}}
                                      </td>
                                      <td [style]="not_ins_td_amtStyle">
                                        {{billprinttable.amount}}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td></td>
                                      <td [style]="subtotal_style">
                                        <div style="width: 100%; text-align: end;"><b>Subtotal</b></div>
                                      </td>
                                      <td [style]="subtotal_style">
                                        <div style="width: 100%; text-align: end;">{{medBillSubtotal}}</div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>

                            <!-- Total calculation  -->
                            <div style="width: 100%; margin-top: 5px;">
                              <table>
                                <tr>
                                  <td style="width: 570px;background-color: #ffffff;"></td>
                                  <th style="text-align: left;">Bill amount (र)</th>
                                  <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="Bill amount"
                                    class="fee_txt">{{totalBillAmountvalue}}</td>
                                </tr>
                                <tr *ngIf="sponsorDueflag">
                                  <td style="width: 570px;background-color: #ffffff;"></td>
                                  <th style="text-align: left;">Sponsor Due (र)</th>
                                  <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="Bill amount"
                                    class="fee_txt">{{sponsorDue}}</td>
                                </tr>

                                <tr *ngIf="advanceflag">
                                  <td style="width: 570px;background-color: #ffffff" *ngIf="advanceflag"></td>
                                  <th style="text-align: left;">Advance (र)</th>
                                  <td style="text-align: right;width: 100px;margin-right: 10px;"
                                    data-th="Advance / Paid र" class="fee_txt">{{total_advance}}</td>
                                </tr>
                                <tr *ngIf="discountflag">
                                  <td style="width: 570px;background-color: #ffffff;"></td>
                                  <th style="text-align: left;">Discount (र)</th>
                                  <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="Balance"
                                    class="fee_txt">
                                    {{discountvaluecheck}}</td>
                                </tr>
                                <tr *ngIf="paidflag">
                                  <td style="width: 570px;background-color: #ffffff" *ngIf="paidflag"></td>
                                  <th style="text-align: left;"> Paid (र)</th>
                                  <td style="text-align: right;width: 100px;margin-right: 10px;"
                                    data-th="Advance / Paid र" class="fee_txt">
                                    {{paid_bill}}</td>
                                </tr>
                                <tr *ngIf="balanceflag">
                                  <td style="width: 570px;background-color: #ffffff;" *ngIf="balanceflag"></td>
                                  <th style="text-align: left;">Balance (र)</th>
                                  <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="Balance"
                                    class="fee_txt">
                                    {{remaining_billbal}}</td>
                                </tr>

                              </table>
                            </div>
                            <br />
                            <br /><br />
                            <br />
                            <!-- <table>
                              <tr>
                                <td>
                                  <p style="margin-left: 350px;font-size: 13px"><b><i>This is digitally generated bill</i></b></p>
                                  <br />
                                </td>
                              </tr>
                            </table> -->
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td width="100%">
                          <table style="border:'0';width:100%">
                            <tr>
                              <td style="width:100%;height: 100px;">&nbsp;</td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div #printbanner_llh id="printbanner_llh">
            <div class="bill_border">
              <div *ngFor="let billprint of billing_print_array">
                <div>
                  <table style="margin-left: 15px !important;margin-right: 15px !important;">
                    <thead>
                      <th>
                        <div style="height: 270px">
                          <div style="height: 130px">
                            <img alt="image" src={{hospital_logo}} width="90%" height="130px"
                              style="vertical-align: middle;">
                          </div>
                          <div style="margin-top: 5px; height: 130px;">
                            <!-- <h3  class="heading">Patient details</h3> -->
                            <table style="width: 98%; border: 1px solid #000;">
                              <tr>
                                <td style="width: 58%; vertical-align: top;">
                                  <table>
                                    <tr>
                                      <td style="margin-bottom: 5px !important;"><strong>MR No:
                                          {{inpatientidnew}}</strong></td>
                                    </tr>
                                    <tr>
                                      <td style="margin-bottom: 5px !important;"><strong>Patient Name:
                                          {{billprint.client_name}}</strong></td>
                                    </tr>
                                    <tr>
                                      <td style="margin-bottom: 5px !important;"><strong>Age / Gender: {{age}} /
                                          {{gender}}</strong></td>
                                    </tr>
                                    <tr>
                                      <td style="margin-bottom: 5px !important;"><strong>Doctor:
                                          {{billprint.doctorname}} {{doc_qualif}}</strong></td>
                                    </tr>
                                    <tr>
                                      <td style="margin-bottom: 5px !important;"><strong>Ward & Bed no: {{ward_name}} /
                                          {{bedno}}</strong></td>
                                    </tr>
                                    <tr *ngIf="insuranceFlag">
                                      <td><strong>Insurance Corp: {{insurer}}</strong></td>
                                    </tr>
                                  </table>
                                </td>
                                <td style="width: 42%;vertical-align: top;">
                                  <table>
                                    <tr>
                                      <td style="margin-bottom: 5px !important;"><strong>Bill type:
                                          {{pay_type_desc}}</strong></td>
                                    </tr>
                                    <tr>
                                      <td style="margin-bottom: 5px !important;"><strong>Bill no: {{bill_no}}</strong>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="margin-bottom: 5px !important;"><strong>DOA: {{admissionDate}}
                                          {{admitted_timevalue}}</strong></td>
                                    </tr>
                                    <tr>
                                      <td style="margin-bottom: 5px !important;"><strong>DOD: {{dischargeDate}}
                                          {{dischargeTime}}</strong></td>
                                    </tr>
                                    <tr>
                                      <td style="margin-bottom: 5px !important;"><strong>Surgery Name:
                                          {{surgery_name}}</strong></td>
                                    </tr>
                                    <!-- <tr *ngIf="insuranceFlag">
                                      <td><strong>Insurance Corp: {{insurer}}</strong></td>
                                    </tr> -->
                                  </table>
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </th>
                    </thead>
                    <tfoot>
                      <tr>
                        <td width="100%">
                          <table style="border:'0';width:100%">
                            <tr>
                              <td style="width:100%;height: 100px;">
                                <div style="display: flex; justify-content: end;">
                                  <div><b>{{billprint.doctorname}} {{doc_qualif}}</b></div>
                                </div>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </tfoot>
                    <tbody>
                      <tr>
                        <td>
                          <div>
                            <!-- Bill list particulars -->
                            <div *ngIf="sendDoctor.length != 0" style="width: 98%; margin-top: 10px !important;">
                              <h3 class="heading">Service Charges</h3>

                              <div class="row">
                                <table style="border-collapse: collapse;margin-right: 20px;width:100%;">
                                  <thead>
                                    <tr>
                                      <th [style]="sno_style">
                                        <b>S.no</b>
                                      </th>
                                      <th [style]="service_style">
                                        <b>Service</b>
                                      </th>
                                      <th [style]="Amt_style">
                                        <b>Cost</b>
                                      </th>
                                      <th [style]="quant_style">
                                        <b>No of days/times</b>
                                      </th>
                                      <th [style]="Amt_style">
                                        <b>Total cost</b>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let billprinttable of docBillDetails">
                                      <td [style]="sno_td_style" data-th="Type">{{billprinttable.sno}}
                                      </td>
                                      <td [style]="service_td_style" data-th="Type">{{billprinttable.name}}
                                      </td>
                                      <td [style]="amt_td_style" data-th="Amount" class="fee_txt">
                                        {{billprinttable.amount }}
                                      </td>
                                      <td [style]="quant_td_style" data-th="Fee"> {{billprinttable.quantity}}
                                      </td>
                                      <td [style]="amt_td_style" data-th="Amount" class="fee_txt">{{billprinttable.fee}}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="3"></td>
                                      <td [style]="subtotal_style">
                                        <div style="width: 100%; text-align: end;"><b>Subtotal</b></div>
                                      </td>
                                      <td [style]="subtotal_style">
                                        <div style="width: 100%; text-align: end;font-weight: bold;">{{docBillSubtotal}}
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>

                            <!-- radiology Bills particulars -->
                            <div style="width: 98%;" *ngIf="digDetailsArray.length !=0">
                              <h3 class="heading">Pathology & Radiology</h3>
                              <div class="row">
                                <table style="border-collapse: collapse;margin-right: 20px;width:100%;">
                                  <thead>
                                    <tr>
                                      <th [style]="sno_style">
                                        <b>S.no</b>
                                      </th>
                                      <th [style]="service_style">
                                        <b>Test Name</b>
                                      </th>
                                      <!-- <th [style]="quant_style">
                                        &nbsp;
                                      </th> -->
                                      <th [style]="Amt_style">
                                        <b>Amount</b>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let billprinttable of digDetailsArray">
                                      <td [style]="sno_td_style" data-th="Type">{{billprinttable.sno}}
                                      </td>
                                      <td [style]="service_td_style">
                                        {{billprinttable.name}}
                                      </td>
                                      <!-- <td [style]="quant_td_style">
                                        &nbsp;
                                      </td> -->
                                      <td [style]="amt_td_style">
                                        {{billprinttable.amount}}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="1"></td>
                                      <td [style]="subtotal_style">
                                        <div style="width: 100%; text-align: end;"><b>Subtotal</b></div>
                                      </td>
                                      <td [style]="subtotal_style">
                                        <div style="width: 100%; text-align: end;font-weight: bold;">
                                          {{diagBillSubtotal}}</div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>

                            <!-- Madical Bills particulars -->
                            <div *ngIf="medcalDetailsArray.length != 0" style="width: 98%;">
                              <h3 class="heading">Medicine bills </h3>
                              <div class="row" *ngIf="insuranceFlag">
                                <table style="width: 100%; border-collapse: collapse;margin-right: 20px;">
                                  <thead>
                                    <tr>
                                      <th [style]="sno_style">
                                        <b>S.no</b>
                                      </th>
                                      <th [style]="service_style">
                                        <b>Medicine</b>
                                      </th>

                                      <th [style]="quant_style">
                                        Quantity</th>

                                      <!-- <th style="width: 200px;border: 1px solid black;border-collapse: collapse;">Payment type</th> -->
                                      <!-- <th style="width: 150px;border: 1px solid black;border-collapse: collapse;"><b>Fee</b></th> -->
                                      <!-- <th style="width: 150px;border: 1px solid black;border-collapse: collapse;"><b>Concession (%)</b></th> -->
                                      <th [style]="Amt_style">
                                        <b>Amount</b>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let billprinttable of medcalDetailsArray">
                                      <td [style]="sno_td_style" data-th="Type">{{billprinttable.sno}}</td>
                                      <td [style]="service_td_style" data-th="Type">{{billprinttable.name}}</td>
                                      <td [style]="quant_td_style" data-th="Type">{{billprinttable.quantity}}</td>
                                      <td [style]="amt_td_style" data-th="Fee">{{billprinttable.amount}}</td>
                                    </tr>
                                    <tr>
                                      <td colspan="2"></td>
                                      <td [style]="subtotal_style">
                                        <div style="width: 100%; text-align: end;"><b>Subtotal</b></div>
                                      </td>
                                      <td [style]="subtotal_style">
                                        <div style="width: 100%; text-align: end;">{{medBillSubtotal}}</div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div class="row" *ngIf="!insuranceFlag">
                                <table style=" width: 100%; border-collapse: collapse;margin-right: 20px;">
                                  <thead>
                                    <tr>
                                      <th [style]="sno_style">
                                        <b>S.no</b>
                                      </th>
                                      <th [style]="not_ins_serviceStyle"><b>Bill
                                          No</b></th>
                                      <th [style]="not_ins_amtStyle">
                                        <b>Amount</b>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let billprinttable of medcalDetailsArray">
                                      <td [style]="sno_td_style" data-th="Type">{{billprinttable.sno}}</td>
                                      <td [style]="service_td_style">
                                        {{billprinttable.name}}
                                      </td>
                                      <td [style]="not_ins_td_amtStyle">
                                        {{billprinttable.amount}}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="1"></td>
                                      <td [style]="subtotal_style">
                                        <div style="width: 100%; text-align: end;"><b>Subtotal</b></div>
                                      </td>
                                      <td [style]="subtotal_style">
                                        <div style="width: 100%; text-align: end;">{{medBillSubtotal}}</div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>

                            <!-- Total calculation  -->
                            <div style="width: 98%; margin-top: 5px;">
                              <table>
                                <tr>
                                  <td style="width: 560px;background-color: #ffffff;"></td>
                                  <th style="text-align: left;width: 105px;">Bill amount (र)</th>
                                  <td style="text-align: right;width: 105px;margin-right: 20px;" data-th="Bill amount"
                                    class="fee_txt">{{totalBillAmountvalue}}</td>
                                </tr>
                                <tr *ngIf="sponsorDueflag">
                                  <td style="width: 560px;background-color: #ffffff;"></td>
                                  <th style="text-align: left;width: 105px;">Sponsor Due (र)</th>
                                  <td style="text-align: right;width: 105px;margin-right: 20px;" data-th="Bill amount"
                                    class="fee_txt">{{sponsorDue}}</td>
                                </tr>

                                <tr *ngIf="advanceflag">
                                  <td style="width: 560px;background-color: #ffffff" *ngIf="advanceflag"></td>
                                  <th style="text-align: left;width: 105px;">Advance (र)</th>
                                  <td style="text-align: right;width: 105px;margin-right: 20px;"
                                    data-th="Advance / Paid र" class="fee_txt">{{total_advance}}</td>
                                </tr>
                                <tr *ngIf="discountflag">
                                  <td style="width: 560px;background-color: #ffffff;"></td>
                                  <th style="text-align: left;width: 105px;">Discount (र)</th>
                                  <td style="text-align: right;width: 105px;margin-right: 20px;" data-th="Balance"
                                    class="fee_txt">
                                    {{discountvaluecheck}}</td>
                                </tr>
                                <tr *ngIf="paidflag">
                                  <td style="width: 560px;background-color: #ffffff" *ngIf="paidflag"></td>
                                  <th style="text-align: left;width: 105px;"> Paid (र)</th>
                                  <td style="text-align: right;width: 105px;margin-right: 20px;"
                                    data-th="Advance / Paid र" class="fee_txt">
                                    {{paid_bill}}</td>
                                </tr>
                                <tr *ngIf="balanceflag">
                                  <td style="width: 560px;background-color: #ffffff;" *ngIf="balanceflag"></td>
                                  <th style="text-align: left;width: 105px;">Balance (र)</th>
                                  <td style="text-align: right;width: 105px;margin-right: 20px;" data-th="Balance"
                                    class="fee_txt">
                                    {{remaining_billbal}}</td>
                                </tr>

                              </table>
                            </div>
                            <br />
                            <br /><br />
                            <br />
                            <!-- <table>
                              <tr>
                                <td>
                                  <p style="margin-left: 350px;font-size: 13px"><b><i>This is digitally generated bill</i></b></p>
                                  <br />
                                </td>
                              </tr>
                            </table> -->
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>