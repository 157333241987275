<div *ngIf="vitalFlag == 'min'">
    <div class="row">
        <div [hidden]="height_hidden" class="col-12">
            <mat-label class="matlabel">Height<br>
                <input [readonly]="client" matInput class="ipcss " (change)="calories_data()" required
                    [(ngModel)]="height_txt">
            </mat-label>
        </div>
        <div [hidden]="height_measurehidden" class="col-12">
            <mat-label class="matlabel" [hidden]="height_measurehidden">Measure<br>
                <select [disabled]="client" class="ipcss " (change)="calories_data()" [(ngModel)]="height_measure"
                    disableOptionCentering>
                    <option value="cms">cms</option>
                    <option value="inch">inch</option>
                </select>
            </mat-label>
        </div>

        <div [hidden]="weight_hidden" class="col-12">
            <mat-label class="matlabel">Weight<br>
                <input [readonly]="client" matInput class="ipcss " (change)="calories_data()" required
                    [(ngModel)]="weight_txt">
            </mat-label>
        </div>
        <div [hidden]="weight_measurehidden" class="col-12">
            <mat-label class="matlabel" [hidden]="weight_measurehidden">Measure<br>
                <select [disabled]="client" class="ipcss " [(ngModel)]="weight_measure" disableOptionCentering>
                    <option value="kgs">kgs</option>
                    <option value="lbs">lbs</option>
                </select>
            </mat-label>
        </div>
    </div>
</div>
<div *ngIf="vitalFlag == 'max'">
    <div class="cover_div">
        <div class="content_cover">
            <div class="row">
                <div [hidden]="height_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                    <div class="row">
                        <div [hidden]="height_hidden" class="col-6">
                            <mat-label class="matlabel">Height<br>
                                <input [readonly]="client" matInput class="ipcss " (change)="calories_data()" required
                                    [(ngModel)]="height_txt" (keydown)=(validate($event))>
                            </mat-label>
                        </div>
                        <div [hidden]="height_measurehidden" class="col-6">
                            <mat-label class="matlabel" [hidden]="height_measurehidden">Measure<br>
                                <select [disabled]="client" class="ipcss " (change)="calories_data()"
                                    [(ngModel)]="height_measure" disableOptionCentering>
                                    <option value="cms">cms</option>
                                    <option value="inch">inch</option>
                                </select>
                            </mat-label>
                        </div>
                    </div>
                </div>

                <div [hidden]="weight_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                    <div class="row">
                        <div class="col-6">
                            <mat-label class="matlabel" [hidden]="weight_hidden">Weight<br>
                                <input [readonly]="client" matInput class="ipcss " (change)="calories_data()" required
                                    [(ngModel)]="weight_txt" (keydown)=(validate($event))>
                            </mat-label>
                        </div>
                        <div [hidden]="weight_measurehidden" class="col-6">
                            <mat-label class="matlabel" [hidden]="weight_measurehidden">Measure<br>
                                <select [disabled]="client" class="ipcss " [(ngModel)]="weight_measure"
                                    disableOptionCentering>
                                    <option value="kgs">kgs</option>
                                    <option value="lbs">lbs</option>
                                </select>
                            </mat-label>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                    <mat-label class="matlabel">BMI
                        <input class="ipcss bmi_lenght" matInput type="text" [readonly]="client" [(ngModel)]="txt_bmi"
                            [readonly]="client" />
                    </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                    <mat-label class="matlabel">BMR
                        <input class="ipcss" style="width: 100%;" matInput [readonly]="client" [(ngModel)]="txt_bmr"
                            [readonly]="client" />
                    </mat-label>
                </div>

                <div [hidden]="temp_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                    <mat-label class="matlabel" [hidden]="temp_hidden">Temperature<br>
                        <input [readonly]="client" matInput class="ipcss " [(ngModel)]="Temperature" [ngStyle]="{'color': colorstyle ? 'red' : 'black'}" (input)="tempcolor(Temperature)">
                    </mat-label>
                </div>
                <div [hidden]="blood_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                    <mat-label class="matlabel" [hidden]="blood_hidden">Blood Pressure<br>
                        <div class="row">
                            <div class="col-6">
                                <input [readonly]="client" matInput class="ipcss " placeholder="mm Hg" maxlength="3"
                                    [(ngModel)]="BP_01"
                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"  (input)="sistol(BP_01)" [ngStyle]="{'color': siscolorstyle ? 'red' : 'black'}">
                            </div>
                            <div class="col-6">
                                <input [readonly]="client" matInput class="ipcss " placeholder="mm Hg" maxlength="3"
                                    [(ngModel)]="BP_02" [ngStyle]="{'color': diocolorstyle ? 'red' : 'black'}"
                                    (input)="diostol(BP_02)"
                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                            </div>
                        </div>
                    </mat-label>
                </div>
                <div [hidden]="cvs_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                    <mat-label class="matlabel">Spo2<br>
                        <input [readonly]="client" matInput class="ipcss " placeholder="saturation" [(ngModel)]="spo2_txt" (input)="spo2(spo2_txt)"
                        [ngStyle]="{'color': spo2colorstyle ? 'red' : 'black'}">
                    </mat-label>
                </div>
                <div [hidden]="cvs_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                    <mat-label class="matlabel">CVS<br>
                        <input [readonly]="client" matInput class="ipcss " placeholder="cvs" [(ngModel)]="cvs_txt">
                    </mat-label>
                </div>
                <div [hidden]="rs_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                    <mat-label class="matlabel">RS<br>
                        <input [readonly]="client" matInput class="ipcss " placeholder="rs" [(ngModel)]="rstxt">
                    </mat-label>
                </div>
                <div [hidden]="cns_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                    <mat-label class="matlabel">CNS<br>
                        <input [readonly]="client" matInput class="ipcss " placeholder="cns" [(ngModel)]="cns_txt">
                    </mat-label>
                </div>
                <div [hidden]="abd_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                    <mat-label class="matlabel">ABD<br>
                        <input [readonly]="client" matInput class="ipcss " placeholder="ABD" [(ngModel)]="abd_txt">
                    </mat-label>
                </div>
                <div [hidden]="pr_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                    <mat-label class="matlabel">P/R<br>
                        <input [readonly]="client" matInput class="ipcss " placeholder="p/r" [(ngModel)]="pr_txt">
                    </mat-label>
                </div>
                <div class="col-12">
                    <a style="float: right;" [hidden]="client" (click)="save_click()"><img
                            src="../../../assets/ui_icons/buttons/save_button.svg" class="saveimgbtn_inpatinfo" /></a>
                </div>
            </div>
        </div>
    </div>
</div>


