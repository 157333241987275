<div class="row" style="width:100%;margin:0 auto;">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Assets List</h5>
                    </div>


                    <div class="headerButtons">
                        <button (click)="backtoassetslist()">Back</button>
                    </div>

                </div>

            </mdb-card-header>
            <mdb-card-body>
                <div class="table-responsive">
             
             
             
                    <table style="width:100%;">
                      <thead>
                        <tr style="background-color:#c1e6fb;color: rgba(0,0,0,.54);min-height: 56px;">
                      
                          <th style="width:10%;">Sino</th>
                          <th style="width:15%;">Asset code</th>
                          <th style="width:20%;">Asset name</th>
                          <th style="width:20%;">Acquisition date</th>
                          <th style="width:20%;">Asset category</th>
                          <th style="width:20%;">Supplier name</th>
                          
                        </tr>
                      </thead>
                      <tbody>
                       <tr *ngFor="let ast of assetsdetaillistarray" (click)="retrive_data(ast)">
                          <td style="width:10%">{{ast.sn}}</td>
                          <td style="width:15%">{{ast.assetid}}</td>
                          <td style="width:20%">{{ast.assetName}}</td>
                          <td style="width:20%">{{ast.acq_date}}</td>
                          <td style="width:20%">{{ast.asset_category_desc}}</td>
                          <td style="width:20%">{{ast.supname}}</td>
                          
                        </tr>
                      </tbody>
                    </table>
      
          
         
               
                 
                 
                 
                 
              
    
        
            </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>