import { Component, OnInit } from '@angular/core';
import { ipaddress } from 'src/app/ipaddress';
import { ServerApi } from 'src/app/server-api';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from 'src/app/helper_class';
import { Master_Helper } from 'src/app/Doctor_module/Master_Helper';
import { MasterHelperService } from 'src/app/Doctor_module/MasterHelper.service';
import { Date_Formate } from '../../../assets/js/common';
declare var $: any;
import moment from 'moment';
import { Client_Helper } from '../Client_helper';
import { ClientViewService } from '../clientview/client-view.service';
@Component({
  selector: 'app-create-datevice-dietplan',
  templateUrl: './create-datevice-dietplan.component.html',
  styleUrls: ['./create-datevice-dietplan.component.scss']
})
export class CreateDateviceDietplanComponent implements OnInit {
  public region;
  public regionArray: any = [];
  public foodArray: any = [];
  public sessionArray: any = [
    { session: "Morning", selected: true },
    { session: "Breakfast", selected: false },
    { session: "Mid-day", selected: false },
    { session: "Lunch", selected: false },
    { session: "Evening", selected: false },
    { session: "Dinner", selected: false },
    { session: "Night", selected: false }
  ];
  public appointmentType;
  public sendFromDate;
  public currentMaxDate;
  public dateFlag: boolean = false;
  public fromDate;
  public toDate;
  public sendToDate;
  public datesArray: any = [];
  public morningFoodList: any = [];
  public breakFastFoodList: any = [];
  public midDayFoodList: any = [];
  public lunchFoodList: any = [];
  public eveningFoodList: any = [];
  public dinnerFoodList: any = [];
  public nightFoodList: any = [];
  public morningFoodItem: any = [];
  public breadfastFoodItem: any = [];
  public midDayFoodItem: any = [];
  public lunchFoodItem: any = [];
  public eveningFoodItem: any = [];
  public dinnerFoodItem: any = [];
  public nightFoodItem: any = [];
  public morningFoods;
  public breakFastFoods;
  public midDayFoods;
  public lunchFoods;
  public eveingFoods;
  public dinnerFoods;
  public nightFoods;
  public selectedDate;
  public week = 1;
  public previousFlag: boolean;
  public nextFlag: boolean;
  public viewType = "date"
  public session = [
    { name: "Morning", checked: true },
    { name: "Breakfast", checked: false },
    { name: "Mid-day", checked: false },
    { name: "Lunch", checked: false },
    { name: "Evening", checked: false },
    { name: "Dinner", checked: false },
    { name: "Night", checked: false },
  ];
  public sessionType = "Morning";
  public getDietPlan;
  public getClientDetails;
  public key = 1; // for demo

  constructor(public serviceAPI: ServerApi, public toastr: ToastrService, public masterData: MasterHelperService, public messageservice: ClientViewService) { }
  
  ngOnInit(): void {
    this.getDietPlan = Client_Helper.getGenerateDite();
    console.log("getDietPlan" + JSON.stringify(this.getDietPlan));
    this.getClientDetails = Client_Helper.getMenuDiet();
    this.getCurrentDate();
  }

  getRange(startDate, endDate, type) {
    console.log("this.startDate" + startDate + "endDate" + endDate)
    var getDates = [];
    let fromDate = moment((startDate));
    let toDate = moment((endDate));
    let diff = toDate.diff(fromDate, type)
    let range = [];
    for (let i = 0; i <= diff; i++) {
      range.push({
        days: moment(startDate).add(i, type).format('dddd'),
        date: moment(startDate).add(i, type).format('DD-MM-YYYY'),
        checked: i == 0 ? true : false,
        morning: [],
        breakFast: [],
        midDay: [],
        lunch: [],
        evening: [],
        dinner: [],
        night: [],
      })
    }
    console.log("range" + JSON.stringify(range));
    var temp = []; var week = 1;
    range.forEach((element, index) => {
      console.log("index" + JSON.stringify(index));
      if ((index != 0) && ((index % 7) == 0)) {
        week++
      }
      element.weekNo = week;
    });
    console.log("range" + JSON.stringify(range));
    return range;
  }

  async getCurrentDate() {
    if (Master_Helper.getMasterCurrentDate() == undefined) {
      await this.masterData.getCurrentDate();
    }
    var obj = Master_Helper.getMasterCurrentDate();
    if (obj != undefined) {
      this.currentMaxDate = obj.current_date;
      this.sendFromDate = Date_Formate(this.currentMaxDate);
      this.toDate = Date_Formate(this.currentMaxDate);
      this.fromDate = obj.current_date;
      // this.getRegion();
      this.getRetivel();
    }
  }

  getRetivel() {
    this.week = 1;
    var tempDate = this.getRange(this.getDietPlan.from_date, this.getDietPlan.to_date, "days");
    this.datesArray = tempDate;
    this.selectedDate = Date_Formate(this.getDietPlan.from_date);
    console.log("datesArray" + JSON.stringify(this.datesArray));
    if (this.getDietPlan.dplan_morning != undefined) {
      this.getDietPlan.dplan_morning.forEach(element => {
        var date = Date_Formate(element.date)
        var index = this.datesArray.findIndex(x => x.date == date);
        if (index != -1) {
          this.datesArray[index].morning.push({ ...element, food_item_id: element.item_id, date: Date_Formate(element.date), checked: true });
        }
      });
    }
    // break fast 
    if (this.getDietPlan.dplan_breakfast != undefined) {
      this.getDietPlan.dplan_breakfast.forEach(element => {
        var date = Date_Formate(element.date)
        var index = this.datesArray.findIndex(x => x.date == date);
        if (index != -1) {
          this.datesArray[index].breakFast.push({ ...element, food_item_id: element.item_id, date: Date_Formate(element.date), checked: true });
        }
      });
    }
    // mid day 
    if (this.getDietPlan.dplan_midday != undefined) {
      this.getDietPlan.dplan_midday.forEach(element => {
        var date = Date_Formate(element.date)
        var index = this.datesArray.findIndex(x => x.date == date);
        if (index != -1) {
          this.datesArray[index].midDay.push({ ...element, food_item_id: element.item_id, date: Date_Formate(element.date), checked: true });
        }
      });
    }
    // lunch 
    if (this.getDietPlan.dplan_lunch != undefined) {
      this.getDietPlan.dplan_lunch.forEach(element => {
        var date = Date_Formate(element.date)
        var index = this.datesArray.findIndex(x => x.date == date);
        if (index != -1) {
          this.datesArray[index].lunch.push({ ...element, food_item_id: element.item_id, date: Date_Formate(element.date), checked: true });
        }
      });
    }
    // lunch 
    if (this.getDietPlan.dplan_evening != undefined) {
      this.getDietPlan.dplan_evening.forEach(element => {
        var date = Date_Formate(element.date)
        var index = this.datesArray.findIndex(x => x.date == date);
        if (index != -1) {
          this.datesArray[index].evening.push({ ...element, food_item_id: element.item_id, date: Date_Formate(element.date), checked: true });
        }
      });
    }
    // dinner 
    if (this.getDietPlan.dplan_dinner != undefined) {
      this.getDietPlan.dplan_dinner.forEach(element => {
        var date = Date_Formate(element.date)
        var index = this.datesArray.findIndex(x => x.date == date);
        if (index != -1) {
          this.datesArray[index].dinner.push({ ...element, food_item_id: element.item_id, date: Date_Formate(element.date), checked: true });
        }
      });
    }
    // night 
    if (this.getDietPlan.dplan_night != undefined) {
      this.getDietPlan.dplan_night.forEach(element => {
        var date = Date_Formate(element.date)
        var index = this.datesArray.findIndex(x => x.date == date);
        if (index != -1) {
          this.datesArray[index].night.push({ ...element, food_item_id: element.item_id, date: Date_Formate(element.date), checked: true });
        }
      });
    }
    console.log("this.datesArray" + JSON.stringify(this.datesArray));
    this.setNav();
  }

  async getRegion() {
    var response = await this.serviceAPI.getData("lifestyle/grf").toPromise();
    if (response) {
      var obj = JSON.parse(JSON.stringify(response));
      this.regionArray = obj.regions
      this.region = this.regionArray[0];
      this.getFoodList();
      this.appointmentType = "Today";
      this.changeAppointmentType();

    }
  }

  changeRegion(e) {
    this.region = e;
    this.getFoodList();
  }

  async getFoodList() {
    var session = (this.sessionArray.filter((x) => { return x.selected == true }).map((x) => { return x.session })).toString();
    var sendData = {
      region: this.region,
      session: session,
    }
    var response = await this.serviceAPI.PostData("lifestyle/gfrs", sendData).toPromise();
    if (response) {
      var obj = JSON.parse(JSON.stringify(response));
      this.foodArray = obj.menu_items.map((x) => { return { ...x, "checked": false } })
    }
  }

  selectedSession(session, type) {
    if (type == "date") {
      this.datesArray.forEach(element => {
        element.checked = element.date == session.date ? true : false;
      });
      this.selectedDate = (this.datesArray.filter((x) => { return x.checked == true }).map((x) => { return x.date })).toString();
      
    } else {
      this.session.forEach(element => {
        element.checked = element.name == session.name ? true : false;
      });
      this.sessionType = (this.session.filter((x) => { return x.checked == true }).map((x) => { return x.name })).toString();
    }
  }

  isSelected(food, action) {
    food.checked = action == "add" ? true : false;
  }

  changeAppointmentType() {
    var e;
    this.sendFromDate = Date_Formate(this.currentMaxDate);
    this.dateFlag = false;
    let days = [];
    if (this.appointmentType == "Today") {
      e = this.currentMaxDate;
    } else if (this.appointmentType == "Weekly") {
      var weekly, d = new Date();
      d.setDate(d.getDate() + 7);
      e = this.getDateFormate(d);

    } else if (this.appointmentType == "Fort Night") {
      var weekly, d = new Date();
      d.setDate(d.getDate() + 14);
      e = this.getDateFormate(d);

    } else if (this.appointmentType == "Monthly") {
      var weekly, d = new Date();
      d.setDate(d.getDate() + 30)
      e = this.getDateFormate(d);

    } else if (this.appointmentType == "3 Months") {
      var d = new Date();
      d.setDate(d.getDate() + 92)
      e = this.getDateFormate(d);

    } else if (this.appointmentType == "6 Months") {
      var weekly, d = new Date();
      d.setDate(d.getDate() + 183)
      e = this.getDateFormate(d);

    } else if (this.appointmentType == "Yearly") {
      var weekly, d = new Date();
      d.setDate(d.getDate() + 365)
      e = this.getDateFormate(d);

    } else if (this.appointmentType == "Date") {
      this.dateFlag = true;
      this.sendFromDate = Date_Formate(this.currentMaxDate);
    }
    this.sendToDate = e;
    console.log("this.sendFromDate" + this.sendFromDate + "this.sendToDate" + this.sendToDate)
    var tempDate = this.getRange(Date_Formate(this.sendFromDate), this.sendToDate, "days");
    if (this.datesArray.length != 0) {
      tempDate.forEach(element => {
        var index = this.datesArray.findIndex(x => x.date == element.date);
        if (index == -1) {
          this.datesArray.push(element)
        }
      });
      
    } else {
      this.datesArray = tempDate;
    }

    this.selectedDate = this.datesArray[0].date;
    this.setNav();
    console.log("test ==>" + JSON.stringify(this.datesArray))
  }

  setNav() {
    var lastWeek = this.datesArray.findLast(x => true);
    this.previousFlag = (this.week != 1 && lastWeek.weekNo != 1) ? true : false;
    this.nextFlag = (lastWeek.weekNo != 1 && this.week != lastWeek.weekNo) ? true : false;
  }

  getDateFormate(d) {
    var month = ((d.getMonth() + 1) < 10 ? '0' : '') + (d.getMonth() + 1);
    var day = (d.getDate() < 10 ? '0' : '') + d.getDate();
    var temp = d.getFullYear() + "-" + month + "-" + day;
    return temp
  }

  selectToDate(e) {
    this.toDate = e;
    this.sendToDate = Date_Formate(e);
  }

  selectFromDate(e) {
    this.fromDate = e;
    this.sendFromDate = Date_Formate(e);
  }

  async foodType(itemname: any, session: any, date) {
    if (itemname != undefined && itemname != "" && itemname.length > 2) {
      var sendData = {
        item_name: itemname,
      }
      var response = await this.serviceAPI.PostData("lifestyle/gdbn", sendData).toPromise();
      if (response) {
        var obj = JSON.parse(JSON.stringify(response));
        var food_items = obj.food_items;
        if (food_items.length != 0) {
          var tempFoodItems = [];
          this.morningFoodItem = [];
          this.breadfastFoodItem = [];
          this.midDayFoodItem = [];
          this.lunchFoodItem = [];
          this.eveningFoodItem = [];
          this.dinnerFoodItem = [];
          this.nightFoodItem = [];
          for (var i = 0; i < food_items.length; i++) {
            var tempWeight = food_items[i].weight.split(" ");
            var calsWight = tempWeight[0];
            if (food_items[i].weight != undefined) {
              var tempWeight = food_items[i].weight.split(" ");
              var calsWight = tempWeight[0];
              var wightMesure = tempWeight[1] != undefined ? tempWeight[1] : "--";
            }
            
            tempFoodItems.push({
              food_item_id: food_items[i].food_item_id,
              food_item: food_items[i].description,
              food_type_id: food_items[i].food_type_id,
              food_sub_type: food_items[i].food_sub_type,
              quantity: food_items[i].quantity,
              measure: food_items[i].measure,
              weight: food_items[i].weight,
              cals_weight: calsWight,
              wightMesure: wightMesure,
              energy: food_items[i].energy,
              proteins: food_items[i].proteins,
              carbohydrates: food_items[i].carbohydrates,
              fat: food_items[i].fat,
              calcium: food_items[i].calcium,
              iron: food_items[i].iron,
              vitaminC: food_items[i].vitaminC,
              vitaminA: food_items[i].vitaminA,
              vitaminB12: food_items[i].vitaminB12,
              fiber: food_items[i].fiber,
              folic: food_items[i].folic,
              h2o: food_items[i].h2o,
              session: session,
              displayContent: food_items[i].description + " (" + food_items[i].quantity + " " + food_items[i].measure + " - " + food_items[i].weight + " )",
              // type: foodType,
              image: food_items[i].image,
              checked: true,
              date: date,
            });
          }
          switch (session) {
            case 'Morning':
              this.morningFoodItem = tempFoodItems;
              break;
            case 'Breakfast':
              this.breadfastFoodItem = tempFoodItems;
              break;
            case 'Mid-day':
              this.midDayFoodItem = tempFoodItems;
              break;
            case 'Lunch':
              this.lunchFoodItem = tempFoodItems;
              break;
            case 'Evening':
              this.eveningFoodItem = tempFoodItems;
              break;
            case 'Dinner':
              this.dinnerFoodItem = tempFoodItems;
              break;
            case 'Night':
              this.nightFoodItem = tempFoodItems;
              break;
          }
          console.log("GDBNNNNN value-----" + JSON.stringify(this.morningFoodItem));
        }
      }
    }
  }

  selctedFood(session, foodArray, flag) {
    this.datesArray.forEach(element => {
      if (element.date == foodArray.date) {
        switch (session) {
          case 'Morning':
            element.morning.push(foodArray);
            this.morningFoodItem = [];
            this.morningFoods = undefined;
            break;
          case 'Breakfast':
            element.breakFast.push(foodArray);
            this.breadfastFoodItem = [];
            this.breakFastFoods = undefined;
            break;
          case 'Mid-day':
            element.midDay.push(foodArray);
            this.midDayFoodItem = [];
            this.midDayFoods = undefined;
            break;
          case 'Lunch':
            element.lunch.push(foodArray);
            this.lunchFoodItem = [];
            this.lunchFoods = undefined;
            break;
          case 'Evening':
            element.evening.push(foodArray);
            this.eveningFoodItem = [];
            this.eveingFoods = undefined;
            break;
          case 'Dinner':
            element.dinner.push(foodArray);
            this.dinnerFoodItem = [];
            this.dinnerFoods = undefined;
            break;
          case 'Night':
            element.night.push(foodArray);
            this.nightFoodItem = [];
            this.nightFoods = undefined;
            break;
        }
      }
    });
    console.log("this.morningFoodList-----" + JSON.stringify(this.datesArray));
  }

  checkedFood(food, action, session) {
    food.checked = action == "add" ? true : false;
  }
 
  copyDietPlan(action) {
    console.log("datesArray" + JSON.stringify(this.datesArray));
    if (this.key == 0) {
      var weekkk = this.week;
      const getWeekOne = this.datesArray.filter((x) => { return x.weekNo == weekkk })
      console.log("getWeekOne" + JSON.stringify(getWeekOne));
      for (var i = 0; i < this.datesArray.length; i++) {
        if (this.datesArray[i].weekNo != weekkk) {
          // morning 
          var morning = getWeekOne.filter((food) => { return food.days == this.datesArray[i].days }).map((y) => { return y.morning });
          var temp = morning[0].map(obj => { return { ...obj, date: this.datesArray[i].date } });
          this.datesArray[i].morning = temp;
          // breakfast 
          var breakFast = getWeekOne.filter((food) => { return food.days == this.datesArray[i].days }).map((y) => { return y.breakFast });
          var temp = breakFast[0].map(obj => { return { ...obj, date: this.datesArray[i].date } });
          this.datesArray[i].breakFast = temp;
          // midDay 
          var midDay = getWeekOne.filter((food) => { return food.days == this.datesArray[i].days }).map((y) => { return y.midDay });
          var temp = midDay[0].map(obj => { return { ...obj, date: this.datesArray[i].date } });
          this.datesArray[i].midDay = temp;
          // lunch 
          var lunch = getWeekOne.filter((food) => { return food.days == this.datesArray[i].days }).map((y) => { return y.lunch });
          var temp = lunch[0].map(obj => { return { ...obj, date: this.datesArray[i].date } });
          this.datesArray[i].lunch = temp;
          // evening 
          var evening = getWeekOne.filter((food) => { return food.days == this.datesArray[i].days }).map((y) => { return y.evening });
          var temp = evening[0].map(obj => { return { ...obj, date: this.datesArray[i].date } });
          this.datesArray[i].evening = temp;
          // dinner 
          var dinner = getWeekOne.filter((food) => { return food.days == this.datesArray[i].days }).map((y) => { return y.dinner });
          var temp = dinner[0].map(obj => { return { ...obj, date: this.datesArray[i].date } });
          this.datesArray[i].dinner = temp;
          // night 
          var night = getWeekOne.filter((food) => { return food.days == this.datesArray[i].days }).map((y) => { return y.night });
          var temp = night[0].map(obj => { return { ...obj, date: this.datesArray[i].date } });
          this.datesArray[i].night = temp;
          this.key = 1;
        }
      }
    }
    this.week = action == "next" ? this.week + 1 : this.week - 1;
    this.setNav();
    console.log("datesArray1111" + JSON.stringify(this.datesArray));
  }
  
  async save() {
    var morning = [], brekfast = [], midday = [], lunch = [], evening = [], dinner = [], night = [];
    this.datesArray.forEach(element => {
      morning = morning.concat(element.morning.filter((x) => { return x.checked == true }).map((x) => { return { ...x, date: Date_Formate(x.date) } }));
      brekfast = brekfast.concat(element.breakFast.filter((x) => { return x.checked == true }).map((x) => { return { ...x, date: Date_Formate(x.date) } }));
      midday = midday.concat(element.midDay.filter((x) => { return x.checked == true }).map((x) => { return { ...x, date: Date_Formate(x.date) } }));
      lunch = lunch.concat(element.lunch.filter((x) => { return x.checked == true }).map((x) => { return { ...x, date: Date_Formate(x.date) } }));
      evening = evening.concat(element.evening.filter((x) => { return x.checked == true }).map((x) => { return { ...x, date: Date_Formate(x.date) } }));
      dinner = dinner.concat(element.dinner.filter((x) => { return x.checked == true }).map((x) => { return { ...x, date: Date_Formate(x.date) } }));
      night = night.concat(element.night.filter((x) => { return x.checked == true }).map((x) => { return { ...x, date: Date_Formate(x.date) } }));
    });
    console.log(Client_Helper.getMenuDiet())
    var diet_plan_url = "lifestyle/sdplan";
    if (Client_Helper.getMenuDiet().update == "1") {
      diet_plan_url = "lifestyle/updplan";
    }

    var sunRelID = this.getClientDetails.sunRelID != undefined ? this.getClientDetails.sunRelID : undefined;
    var send = {
      is_doctor: "0",
      client_reg_id: this.getClientDetails.client_reg_id,
      relation_id: this.getClientDetails.relation_id,
      country: "IN",
      sub_rel_id: sunRelID,
      life_style_id: this.getClientDetails.life_style_id,
      from_date: this.getDietPlan.from_date,
      to_date: this.getDietPlan.to_date,
      dplan_morning: morning,
      dplan_breakfast: brekfast,
      dplan_midday: midday,
      dplan_lunch: lunch,
      dplan_evening: evening,
      dplan_dinner: dinner,
      dplan_night: night,

    }
    console.log("send" + JSON.stringify(send));
    var response = await this.serviceAPI.PostData("lifestyle/sdplan", send).toPromise();
    if (response) {
      var obj = JSON.parse(JSON.stringify(response));
      this.toastr.success("Diet save successfully");
      this.messageservice.sendMessage("diet_paln");
    } else {
      this.toastr.error("Something went wrong, try later")
    }
    console.log("morning" + JSON.stringify(morning));
  }
}
