<div class="row" style="width:100%;margin:0 auto;">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white ">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Consultant</h5>
                    </div>
                    <div class="headerButtons">
                        <img *ngIf="!showCreateEditFlag" class="saveimgbtn_inpatinfo" src="../../../assets/ui_icons/buttons/new_button.svg"
                        (click)="createConsultant()" style="margin-left: 9px ;"/>   
                        <img *ngIf="showCreateEditFlag" class="saveimgbtn_inpatinfo" src="../../../assets/ui_icons/buttons/Back_button.svg"
                        (click)="back()" style="margin-left: 9px ;"/>    
                        <img *ngIf="!editFlag && showCreateEditFlag" style="float: right;" src="../../../assets/ui_icons/buttons/save_button.svg" (click)="addConsultant('add')" class="saveimgbtn_inpatinfo" />
                        <img *ngIf="editFlag && showCreateEditFlag" style="float: right;" src="../../../assets/ui_icons/buttons/update_button.svg" (click)="addConsultant('edit')" class="saveimgbtn_inpatinfo" />
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body style="padding:0">
                <div class="container">
                    <div class="row">
                        <div class="col-12" *ngIf="showCreateEditFlag">
                            <div class="cover_div">
                                <div class="header_lable">
                                    Consultant details
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel">First name
                                                <input type="text" class="ipcss" [(ngModel)]="consultantFName" required matInput />
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel">Middle name
                                                <input type="text" class="ipcss" [(ngModel)]="consultantMName"  matInput />
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel">Last name
                                                <input type="text" class="ipcss" [(ngModel)]="consultantLName" required matInput />
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel">Gender
                                                <mat-select [(ngModel)]="consultantGender" disableOptionCentering class="ipcss" required>
                                                    <!-- <mat-option disabled value="Select">Select</mat-option> -->
                                                    <mat-option value="Male" > Male</mat-option>
                                                    <mat-option value="Female">Female</mat-option>
                                                    <mat-option value="Transgender">Transgender</mat-option>
                                                </mat-select>
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel">Mobile no
                                                <input type="text" class="ipcss" [(ngModel)]="consultantMobile" maxlength="10"  required matInput />
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel">License code
                                                <input type="text" class="ipcss" (click)="consultantLicense = ''" [(ngModel)]="consultantLicense" maxlength="15" required matInput />
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel">Qualification
                                                <input type="text" class="ipcss" [(ngModel)]="consultantQualification" matInput />
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel">Experience
                                                <input type="text" id="" class="ipcss" [(ngModel)]="consultantExp" onkeypress="return (event.charCode !=8 && event.charCode ==0 || ( event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)))" matInput autocomplete="new-password" />
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel">Speciality
                                                <select id="mandatory_specfication" class="ipcss" [(ngModel)]="consultantSpeciality" required>
                                                <option *ngFor="let spl of splList" value="{{spl.spl_id}}">{{spl.spl_name}}</option>
                                              </select>
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel">Password
                                                <input type="password" class="ipcss" (click)="consultantPwd = ''"  [(ngModel)]="consultantPwd" required matInput autocomplete="new-password" />
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 d-flex align-items-center">
                                            <div class="switch-field">
                                                <input type="radio" id="radio-one" (click)="changeType('casewise','yes')" />
                                                <label for="radio-one" [ngClass]="ynopt1 ? 'radio_active':'radio_inactive'">Case wise</label>
                                                <input type="radio" id="radio-two" (click)="changeType('all','no')" />
                                                <label for="radio-two" [ngClass]="!ynopt1 ? 'radio_active':'radio_inactive'">All</label>
                                              </div>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3"></div>                                        
                                        <div class="col-6">
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="cover_div" style="padding-bottom: 13px;">
                                                        <div class="header_lable">
                                                            Add session
                                                        </div>
                                                        <div class="content_cover">
                                                            <div class="row">
                                                                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                                                    <mat-label class="matlabel">Day
                                                                        <select disableOptionCentering class="ipcss " [(ngModel)]="consultantDays" required>
                                                                            <option disabled value="Select">Select</option>
                                                                            <option value="Sunday">Sunday</option>
                                                                            <option value="Monday">Monday</option>
                                                                            <option value="Tuesday">Tuesday</option>
                                                                            <option value="Wednesday">Wednesday</option>
                                                                            <option value="Thursday">Thursday</option>
                                                                            <option value="Friday">Friday</option>
                                                                            <option value="Saturday">Saturday</option>
                                                                            <option value="Saturday">Saturday</option>
                                                                            <option value="Mon - Sat">Mon - Sat</option>
                                                                            <option value="All Days">All Days</option>
                                                                        </select>                                            
                                                                    </mat-label>
                                                                </div>
                                                                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                                                    <mat-label class="matlabel">Session
                                                                        <select disableOptionCentering class="ipcss "
                                                                        (change)="changeSession()" [(ngModel)]="consultantSession" required>
                                                                            <option disabled value="Select">Select</option>
                                                                            <option value="Morning">Morning</option>
                                                                            <option value="Afternoon">Afternoon</option>
                                                                            <option value="Evening">Evening</option>
                                                                        </select>                                            
                                                                    </mat-label>
                                                                </div>
                                                                <div class="col-12 col-xl-6">
                                                                    <mat-label class="matlabel">Starts from</mat-label>
                                                                    <div class="row">
                                                                        <div class="col-4">
                                                                            <select disableOptionCentering [(ngModel)]="timeStarts1" class="ipcss ">
                                                                                <option *ngFor="let name of timeStartsFrom" value={{name}}>{{name}}
                                                                                </option>
                                                                            </select>
                                                                        </div>
                                                                        <div class="col-4">
                                                                            <select disableOptionCentering [(ngModel)]="timeStarts2" class="ipcss ">
                                                                                <option *ngFor="let name of timeEndsAt" value={{name}}>{{name}}
                                                                                </option>
                                                                            </select>
                                                                        </div>
                                                                        <div class="col-4">
                                                                            <select disableOptionCentering [(ngModel)]="timeStarts3" class="ipcss ">
                                                                                <option value="AM">AM</option>
                                                                                <option value="PM">PM</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-12 col-xl-6">
                                                                    <mat-label class="matlabel">Ends at</mat-label>
                                                                    <div class="row">
                                                                        <div class="col-4">
                                                                            <select disableOptionCentering [(ngModel)]="timeEnds1" class="ipcss ">
                                                                                <option *ngFor="let name of timeStartsFrom" value={{name}}>{{name}}
                                                                                </option>
                                                                            </select>
                                                                        </div>
                                                                        <div class="col-4">
                                                                            <select disableOptionCentering [(ngModel)]="timeEnds2" class="ipcss ">
                                                                                <option *ngFor="let name of timeEndsAt" value={{name}}>{{name}}
                                                                                </option>
                                                                            </select>
                                                                        </div>
                                                                        <div class="col-4">
                                                                            <select disableOptionCentering [(ngModel)]="timeEnds3" class="ipcss ">
                                                                                <option value="AM">AM</option>
                                                                                <option value="PM">PM</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                                                    <mat-label class="matlabel">Fee
                                                                        <input type="text" class="ipcss" [(ngModel)]="consultantFee" required matInput style="text-align: right;"/>
                                                                    </mat-label>
                                                                </div>
                                                                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 align-self-center">
                                                                    <mat-label class="matlabel">&nbsp;
                                                                    <img style="float: right;" src="../../../assets/ui_icons/buttons/add_button.svg" (click)="addSession()" class="saveimgbtn_inpatinfo" />
                                                                    </mat-label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="dig_table_overflow sessionTable">
                                                <div class="table-responsive">
                                                    <table class="table table-hover table-dynamic">
                                                        <thead>
                                                            <tr>
                                                                <th>Day</th>
                                                                <th>Session</th>
                                                                <th>Starts from</th>
                                                                <th>Ends at</th>
                                                                <th>fee</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let session of sessionList">
                                                                <td style="width: 200px;">{{session.work_days}}</td>
                                                                <td style="width: 200px;">{{session.day_session}}</td>
                                                                <td style="width: 100px;">{{session.available_from}}</td>
                                                                <td style="width: 300px;">{{session.available_to}}</td>
                                                                <td style="width: 300px;">{{session.fee}}</td>
                                                                <td style="text-align: center;">
                                                                    <img src="../../../assets/ui_icons/buttons/trash_icon.svg" class="edit_icon" (click)="deleteConsultant(session)" />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12" style="height: 294px; overflow: auto;">
                                            <table *ngIf="patientList.length" mdbTable datatable [dtOptions]="dtOptions" class="table table-nowrap table-sm dataTable">
                                                <thead>
                                                  <tr>
                                                    <th>Image</th>
                                                    <th>Name</th>
                                                    <th>Mobile</th>
                                                    <th>Location</th>
                                                    <th>City</th>
                                                    <th style="width: 5%;"><mdb-checkbox [default]="true" [(ngModel)]="masterSelected" name="list_name" value="m1" (change)="checkUncheckAll()"></mdb-checkbox></th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr *ngFor="let person of patientList; let i = index">
                                                    <td><img src="{{person.profile_image}}" onerror="this.src='../../../assets/img/default.jpg';" height="40px" width="40px" class="img-fluid z-depth-1 rounded-circle mr-1"></td>
                                                    <td style="font-size: 12px;text-align: left;">{{ person.patient_name }}</td>
                                                    <td style="font-size: 12px;">{{ person.contact }}</td>
                                                    <td style="font-size: 12px;text-align: left !important;">{{person.location}}</td>
                                                    <td style="font-size: 12px;text-align: center;">{{ person.city }}</td>
                                                    <td style="font-size: 12px;width: 5%;"><mdb-checkbox [default]="true" [(ngModel)]="person.isSelected" name="list_name" value="{{person.id}}" (change)="isAllSelected(person)"></mdb-checkbox></td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12" *ngIf="!showCreateEditFlag" style="margin-top:10px;">
                            <div class="dig_table_overflow ">
                                <div class="table-responsive">
                                    <table class="table table-hover table-dynamic">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>License code</th>
                                                <th>Mobile</th>
                                                <th>Gender</th>
                                                <th>Speciality</th>
                                                <th>Action</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let consultant of consultantList">
                                                <td style="width: 200px;text-align: left;">{{consultant.doc_name}}</td>
                                                <td style="width: 200px;">{{consultant.license_code}}</td>
                                                <td style="width: 100px;">{{consultant.mobile}}</td>
                                                <td style="width: 300px;">{{consultant.gender}}</td>
                                                <td style="width: 300px;">{{consultant.spl_name}}</td>
                                                <td style="text-align: center;">
                                                    <img src="../../../assets/ui_icons/buttons/pencil_icon.svg"
                                                        class="edit_icon" (click)="editConsultant(consultant)"/>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>
