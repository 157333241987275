import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { Time_Formate } from '../../../assets/js/common';
import { Client_Helper } from '../Client_helper';
import { ClientViewService } from '../clientview/client-view.service';
import { ipaddress } from '../../ipaddress';

@Component({
  selector: 'app-diagnosis-pharmcay-list',
  templateUrl: './diagnosis-pharmcay-list.component.html',
  styleUrls: ['./diagnosis-pharmcay-list.component.css']
})

export class DiagnosisPharmcayListComponent implements OnInit {

  public diagListView = [];
  public diagDetails;
  public diagList = [];
  public sendDiagData;
  public pharmaUrl: string;
  public sendPharmaData;
  public drugIdList = [];
  public pharmaDetails = [];
  public locationList = [];
  public locationListData = [];
  public locationName: string;
  public searchLocationList;
  public cityList;
  public stateUrl: string;
  public cityUrl: string;
  public countryUrl: string;
  public stateList;
  public filtState;
  public filtStateDesc;
  public countryList;
  public countryId: string;
  public filtCountry;
  public filtCity: string;
  public filtCityDesc: string;
  public locationId: string;
  public zipcode: string;
  public tabletList;
  public diagTestsList;
  public diagUrl;
  public diagFlag: Boolean = false;
  public pharmaFlag: Boolean = false;
  public presId;
  Titile: any;
  public pharmacylist;

  constructor(public messageservice:ClientViewService,public gservice:CommonDataService,public http:Http,public routes:ActivatedRoute,public router:Router,public toastr:ToastrService) { 
    this.pharmaUrl = ipaddress.getIp + "pharmacontrol/allpharma";
    this.diagUrl = ipaddress.getIp + "search/diagnosis";
    this.cityUrl = ipaddress.getIp + "gen/city";
    this.stateUrl = ipaddress.getIp + "gen/state";
    this.countryUrl = ipaddress.getIp + "gen/count";
    this.locationName = this.gservice.Client_login_detail_data.Client_Location;
  }

  ngOnInit(): void {
    this.pharmacylist = Client_Helper.getPharmacyList();
    this.presId = this.pharmacylist.pres_id;
    if (this.pharmacylist.diag_tests_list != null) {
      this.diagTestsList = this.pharmacylist.diag_tests_list;
      this.Titile = "Diagnosis center(s)"
    } else {
      this.Titile = "Pharmacies"
    }

    this.filtCityDesc = this.gservice.Client_login_detail_data.Client_City;
    
    if (this.gservice.Client_login_detail_data.Client_Location != null) {
      this.locationName = this.gservice.Client_login_detail_data.Client_Location;
      this.selectLocation(this.locationName);
    }
    
    if (this.diagTestsList != null && this.diagTestsList != undefined) {
     this.getDiagDetail();
     this.diagFlag = true;
    } else {
      this.getPharmacies();
      this.pharmaFlag = true;
    }
  }

  selectLocation(loc_name) {
    this.locationName = loc_name;
    this.locationList = [];
    this.stateList = [];
    this.cityList = [];
    this.countryList = [];
    this.getCity(this.locationName);
  }

  getPharmacies() {
    this.diagList = [];
    this.pharmaDetails = [];
    this.drugIdList = [];

    if (this.tabletList.length != 0) {
      for (var g = 0; g < this.tabletList.length; g++) {
        this.drugIdList.push(this.tabletList[g].drug_id);
      }
      this.sendPharmaData = {
        city: this.filtCityDesc,
        location: this.locationName,
        drug_ids: this.drugIdList
      };
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.pharmaUrl, JSON.stringify(this.sendPharmaData), { headers: headers }).subscribe(
        data => {
          this.pharmaDetails = [];
          if (data.json().pharma_list.length != 0 && data.json().pharma_list[0] != undefined) {
            for (var f = 0; f < data.json().pharma_list.length; f++) {
              var phar_data = data.json().pharma_list[f];
              var address1 = "", address2 = "", location = "", city = "", zipcode = "", state = "", country = "", telephone, work_desc, work_id, avail_from, avail_to, valid, work_time;
              var home_del: boolean = false;
              var store_pick: boolean = false;
              var delopt: boolean = false;
              address1 = phar_data.address1;
              if (phar_data.address2 != null) {
                address2 = "\n" + phar_data.address2;
              }
              location = phar_data.location;
              city = phar_data.city;
              zipcode = phar_data.zipcode;
              state = phar_data.state;
              country = phar_data.country;
              if (phar_data.telephone != null) {
                telephone = "Telephone : " + phar_data.telephone;
              }
              work_id = phar_data.work_days;
              if (phar_data.home_delivery != null && phar_data.home_delivery == "1") {
                home_del = true;
                delopt = true;
              }
              if (phar_data.store_pickup != null && phar_data.store_pickup == "1") {
                store_pick = true;
                delopt = true;
              }
              if (work_id == "1") {
                work_desc = "Mon - Sat";
              } else if (work_id == "2") {
                work_desc = "All days";
              }
              
              if (phar_data.available_from != null) {
                avail_from = phar_data.available_from;
                if (phar_data.available_to != null) {
                  avail_to = phar_data.available_to;
                }
                work_time = Time_Formate(avail_from) + " - " + Time_Formate(avail_to);
              }

              if (phar_data.validity != null) {
                valid = phar_data.validity + " day(s)"
              }
              
              this.pharmaDetails.push({
                pharma_id: phar_data.pharma_id,
                pharmacy_name: phar_data.pharmacy_name,
                phar_addr1: address1,
                phar_addr2: address2,
                phar_loc: location,
                phar_city: city,
                phar_zipcode: zipcode,
                phar_state: state,
                phar_cnty: country,
                phar_tele: telephone,
                phar_work: work_id,
                phar_word_desc: work_desc,
                phar_work_time: work_time,
                phar_avail_from: avail_from,
                phar_avail_to: avail_to,
                phar_valid: valid,
                is_home_delivery: home_del,
                is_store_pick: store_pick,
                is_del_option: delopt
              });
            }
          }
        }
      );
    }
  }

  followFilter(e) {
    this.locationList = [];
    this.locationListData = [];
    this.pharmaDetails = [];
    this.locationName =e.target.value.toString();
    if (this.locationName !== "" && this.locationName.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "gen/loc",
        JSON.stringify({
          loc_name: this.locationName
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            this.searchLocationList = obj.locations;

            for (var i = 0; i < this.searchLocationList.length; i++) {
              this.locationListData.push(this.searchLocationList[i]);
            }

            this.locationList = this.locationListData.filter(function (this:any,el) {
              return el.toLowerCase().indexOf(this.loc_name.toLowerCase()) > -1;
            }.bind(this));
          },
          error => {
          }
        )
    } else {
      this.locationList = [];
      this.cityList = [];
    }
  }

  getCity(data) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.cityUrl, JSON.stringify({ loc_name: data }), { headers: headers }).subscribe(
      data => {
        if (data.json().cities != null) {
          this.cityList = data.json().cities;
          this.filtCity = this.cityList[0].city_id;
          this.filtCityDesc = this.cityList[0].city_desc;
          this.filtState = this.cityList[0].state_id;
          this.filtStateDesc = this.cityList[0].state_desc;
          this.locationId = this.cityList[0].location_id;
          this.zipcode = this.cityList[0].zipcode;
          this.cityChange(this.cityList[0].city_id);
        }
      },error => {});
  }

  cityChange(data) {
    if (data != null) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.stateUrl, JSON.stringify({ city_id: data }), { headers: headers }).subscribe(
        data => {
          if (data.json().states != null) {
            this.stateList = data.json().states;
            this.filtState = this.stateList[0].state_id;
            this.filtStateDesc = this.stateList[0].state_desc;
            this.stateChange(this.stateList[0].state_id);
          }
        },error => {});
    } else {
      this.stateList = [];
      this.cityList = [];
      this.countryList = [];
    }

    if (this.tabletList != null && this.tabletList != undefined) {
      this.getPharmacies();
    }
  }

  stateChange(data) {
    if (data != null) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.countryUrl, JSON.stringify({ state_id: data }), { headers: headers }).subscribe(
        data => {
          if (data.json().countries != null) {
            this.countryList = data.json().countries;
            this.countryId = this.countryList[0].country_id;
            this.filtCountry = this.countryList[0].country_desc;
          }
        },error => {});
    } else {
      this.stateList = [];
      this.countryList = [];
    }

    if (this.diagTestsList != null && this.diagTestsList != undefined) {
      this.getDiagDetail();
      this.diagFlag = true;
    }
  }

  getDiagDetail() {
    this.diagList = [];
    this.pharmaDetails = [];
    this.sendDiagData = {};
    this.sendDiagData.city = this.filtCityDesc;
    this.sendDiagData.location = this.locationName;
    this.sendDiagData.state = this.filtStateDesc;
    this.sendDiagData.country = this.filtCountry;

    if (this.diagTestsList != null && this.diagTestsList.length != 0) {
      for (var i = 0; i < this.diagTestsList.length; i++) {
        var test = this.diagTestsList[i].test;
        var temp_test = [];
        var sub_test = this.diagTestsList[i].subtest.split(",");
        for (var j = 0; j < sub_test.length; j++) {
          temp_test.push(sub_test[j]);
        }

        if (test == "Blood tests") 
          this.sendDiagData.blood_tests = temp_test;
        else if (test == "Urine tests") 
          this.sendDiagData.urine_tests = temp_test;
        else if (test == "Scan tests") 
          this.sendDiagData.scan_tests = temp_test;
        else if (test == "Faeces tests") 
          this.sendDiagData.faeces_tests = temp_test;
        else if (test == "Xray tests") 
          this.sendDiagData.xray_tests = temp_test;
        else if (test == "Ultra-sound tests") 
          this.sendDiagData.ultrasound_tests = temp_test;
      }
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.diagUrl, JSON.stringify(this.sendDiagData), { headers: headers }).subscribe(
      data => {
        this.diagDetails = data.json().centres;
        if (this.diagDetails[0].centre_id != undefined) {
          var address, telephone, website, workday, available, time_available;
          for (var i = 0; i < this.diagDetails.length; i++) {
            if (this.diagDetails[i].address2 != null && this.diagDetails[i].address2 != undefined) {
              address = (this.diagDetails[i].address1) + ', ' + (this.diagDetails[i].address2 + ', ');
            } else {
              if (this.diagDetails[i].address1 != null && this.diagDetails[i].address1 != undefined) {
                address = (this.diagDetails[i].address1) + ', ';
              }
            }
            
            if (this.diagDetails[i].telephone != null && this.diagDetails[i].telephone != undefined) {
              telephone = this.diagDetails[i].telephone;
            }
            
            if (this.diagDetails[i].website != null && this.diagDetails[i].website != undefined) {
              website = this.diagDetails[i].website;
            } else {
              website = " - ";
            }
            
            if (this.diagDetails[i].workday != null && this.diagDetails[i].workday != undefined) {
              workday = this.gservice.get_diabday(this.diagDetails[i].workday);
            }
            
            if (workday != null && workday != undefined || this.diagDetails[i].available_from != null && this.diagDetails[i].available_from != undefined &&
              this.diagDetails[i].available_to != null && this.diagDetails[i].available_to != undefined) {
              available = workday + ", " + this.gservice.get_Timeformate(this.diagDetails[i].available_from) + "- " +
                this.gservice.get_Timeformate(this.diagDetails[i].available_to)
            }

            this.diagList.push({
              center_id: this.diagDetails[i].centre_id,
              center_name: this.diagDetails[i].centre_name,
              city: this.diagDetails[i].city + "- " + this.diagDetails[i].zipcode,
              location: this.diagDetails[i].location,
              state: this.diagDetails[i].state + ", " + this.diagDetails[i].country,
              telephone: telephone,
              profile_image: ipaddress.Ip_with_img_address + this.diagDetails[i].profile_image,
              website: website,
              available: available,
              diag_tests_list: this.diagTestsList,
            });
          }
        }
      },error => {});
  }

  homeDel(pharma_id, pharmacy_name, phar_addr1, phar_addr2, phar_loc, phar_city, phar_zipcode, phar_state, phar_cnty, telephone) {
    // this.navCtrl.push('QuantityChangePage', {
    //   flow: this.navParams.get("flow"),
    //   pharma_id: pharma_id,
    //   del_type: "Home delivery",
    //   pharmacy_name: pharmacy_name,
    //   phar_addr1: phar_addr1,
    //   phar_addr2: phar_addr2,
    //   phar_loc: phar_loc,
    //   phar_city: phar_city,
    //   phar_zipcode: phar_zipcode,
    //   phar_state: phar_state,
    //   phar_cnty: phar_cnty,
    //   telephone: telephone,
    //   tabletList: this.tabletList,
    //   rel_id: this.rel_id,
    //   rel_name: this.rel_name,
    //   sub_rel_id: this.sub_rel_id,
    //   Pres_drug_id: this.prescription_pres_id,
    //   pres_id: this.prescription_pres_id,
    // });
  }

  storePickup(pharma_id, pharmacy_name, phar_addr1, phar_addr2, phar_loc, phar_city, phar_zipcode, phar_state, phar_cnty, telephone, validity, work_time) {
    // this.navCtrl.push('QuantityChangePage', {
    //   flow: this.navParams.get("flow"),
    //   pharma_id: pharma_id,
    //   del_type: "Store pickup",
    //   pharmacy_name: pharmacy_name,
    //   phar_addr1: phar_addr1,
    //   phar_addr2: phar_addr2,
    //   phar_loc: phar_loc,
    //   phar_city: phar_city,
    //   phar_zipcode: phar_zipcode,
    //   phar_state: phar_state,
    //   phar_cnty: phar_cnty,
    //   telephone: telephone,
    //   validity: validity,
    //   work_time: work_time,
    //   tabletList: this.tabletList,
    //   rel_id: this.rel_id,
    //   rel_name: this.rel_name,
    //   sub_rel_id: this.sub_rel_id,
    //   Pres_drug_id: this.prescription_pres_id,
    //   pres_id: this.prescription_pres_id,
    // })
  }

  viewAppt(centre_id, profile_image, center_name, address, location, city,
    state, telephone, website, available, diag_tests_list) {
    this.diagListView.push({
      center_id: centre_id,
      center_name: center_name,
      city: city,
      location: location,
      state: state,
      telephone: telephone,
      profile_image: profile_image,
      website: website,
      available: available,
      diag_tests_list: diag_tests_list,
    });

    var diagbooknow = {
      diag_list_details: this.diagListView,
      pres_id: this.presId,
      flow:this.pharmacylist.flow,
      flag:this.pharmacylist.flag,
      center_id:centre_id,
    }
    Client_Helper.setDiagBookNow(diagbooknow);
    this.messageservice.sendMessage("diagnosis_booking");
  }
}