import { Component, OnInit ,ElementRef,Input,Inject,ViewEncapsulation,HostListener} from '@angular/core';
import { Http, Headers } from '@angular/http';
// import { getIp, Ip_with_img_address, country_code } from '../../../assets/js/ipaddress';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Doc_Helper } from '../Doc_Helper';

@Component({
  selector: 'app-dropdown-modelpage',
  templateUrl: './dropdown-modelpage.component.html',
  styleUrls: ['./dropdown-modelpage.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class DropdownModelpageComponent implements OnInit {

  public listData = [];
  public mainListData = [];
  public header_txt;

  constructor(public http:Http, public el: ElementRef,public dialogRef: MatDialogRef<DropdownModelpageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {   
      dialogRef.disableClose = true;
      this.mainListData = [];
      this.listData = [];
      this.mainListData = data.main_array;
      this.header_txt = data.title;
      this.listData = data.selected_list;
  }

  ngOnInit(): void {
  }

  ClosePopup(){
    this.dialogRef.close();
  }

  selectData(data, id, e){
    if(e == true){
      this.listData.push(id);
      for (var j = 0; j < this.mainListData.length; j++) {
        this.mainListData[j].checked = false;
      }
  
      if (this.listData != undefined && this.listData.length != 0) {
        for (var i = 0; i < this.listData.length; i++) {
          for (var j = 0; j < this.mainListData.length; j++) {
            if (this.listData[i] == this.mainListData[j].value) {
              this.mainListData[j].checked = true;
            }
          }
        }
      }  
    }
    else{
      if(this.listData.length != 0){
        for( var k=0; k<this.listData.length; k++){
          if(id == this.listData[k]){
            for (var j = 0; j < this.mainListData.length; j++) {
              if (this.listData[k] == this.mainListData[j].value) {
                this.mainListData[j].checked = false;
              }
            }
            this.listData.splice(k,1);
          }
        }
      }
    }
  }
}
