import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NurseViewComponent } from './nurse-view/nurse-view.component';
// import { DiagnosisComponent } from './diagnosis/diagnosis.component';

const routes: Routes = [
    
      {path: 'nurse', component: NurseViewComponent},

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NurseRoutingModule { }
