import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { CommonDataService } from '../../providers/common-data.service';
import { Http, Headers } from '@angular/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from '../../helper_class';
import { Router, ActivatedRoute } from '@angular/router';
import { ClientViewService } from '../clientview/client-view.service';
import { Client_Helper } from '../Client_helper';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';
import { Date_Formate } from '../../../assets/js/common.js';
@Component({
  selector: 'app-physio-appcreate-new',
  templateUrl: './physio-appcreate-new.component.html',
  styleUrls: ['./physio-appcreate-new.component.css']
})
export class PhysioAppcreateNewComponent implements OnInit {
  public relationship;
  public relativeDetailsFlag:boolean = false;
  public selfRelationData;
  public clntPhoneNo: string;
  public referredDoctor: string;
  public doctorList = [];
  public referredDoctorListData = [];
  public sendData =[];
  public clntCountry: string;
  public curDateUrl: string;
  public clntFirstName: string;
  public clntMiddleName: string;
  public clntLastName: string;
  public clntAge: string;
  public clntGender: string;
  public clntDOB: string;
  public clntSubrelID: string;
  public clntBldgrp;
  public clntFatherName;
  public clntIncome;
  public clntOccupation;
  public clntAddr1;
  public clntAddr2;
  public clntLocation;
  public clntCity;
  public clntState;
  public clntSymptom;
  public clntZipcode;
  public appType: string;
  public homecare: string;
  public clientRegId;
  public otherRelationData;
  public relationList = [];
  public clntDetailedUrl: string;
  public otherRelDetUrl: string;
  public otherRelationList = [];
  public appNursePhysio;
  public relationName;

  public selectDoctorFlag:boolean = false
  //doctor list page
  public physioDoctorList:any = [];
  public clntCountryList = [];
  public locationNameByUrl: string;
  public clntLocationList = [];
  public clntCityList = [];
  public clntStateList = [];
  public locationUrl: string;
  public cityUrl: string;
  public stateUrl: string;
  public countryUrl: string;
  public clntCountryId: string;
  public clntStateId: string;
  public clntCityId: string;
  public clntLocationId: string;
  public filtCity: string;
  public locationName: string;
  public filtCountry;
  public filtState;

  public locationList = [];
  public locationListData = [];
  dtOptions: DataTables.Settings = {};
  public typeLable;

  // get app doctor details
  public name;
  public workExperience;
  public profileImg;
  public doctorDetailData = [];
  public hospitalList = [];
  public tempFee;
  public hospitalId;
  public hospitalName;
  public hospitalLocation;
  public hospitalFee;
  public currentDate;
  public currentDateUrl;
  public currentTime;
  public appointmentDate;
  public currentYear;
  public homecareAppDate;
  public visitingSessionData = [];
  public homecareSessionData;
  public timeList = [];
  public visitSession;
  public visitTime;
  public presentdate;
  public sessionList = [];
  public timeCardFlag:boolean = false;
  public addSessionFlag:boolean = false;
  public homecareData = [];
  public homecareTimeList = [];
  public addressFlag:boolean;
  public availableFlag:boolean;
  public nonAvailableFlag:boolean;
  public title;

  // set comform page 
  public clntBldGrp;
  public physiotherapist;
  public nurse;
  public clientInfo;
  public clntRelationship;
  public sendAppointmentDetails;
  public time;
  public hospitalCity;
  public relationId;
  public sendAppType;
  public homecareAppList = [];
  public presDrugId;
  public paymentType:boolean = false;
  public payCash;
  public payOnline;
  public payInsurance;
  public payType;
  // save flag
  public saveFlag:boolean = false;
  constructor(public messageservice:ClientViewService,public datepipe:DatePipe,public gservice:CommonDataService,public http:Http,public routes:ActivatedRoute,public router:Router,public toastr:ToastrService) {
    
  }
   
  ngOnInit(): void {
    this.curDateUrl = ipaddress.getIp + "adm/curdate";
    this.clntDetailedUrl = ipaddress.getIp + "usercontroller/clientdet/";
    this.otherRelDetUrl = ipaddress.getIp + "appointment/clnrelweb/";
    this.appType = "New";
    this.locationUrl = ipaddress.getIp + "usercontroller/loc";
    this.locationNameByUrl = ipaddress.getIp + "usercontroller/locbyname";
    this.cityUrl = ipaddress.getIp + "usercontroller/city";
    this.stateUrl = ipaddress.getIp + "usercontroller/state";
    this.countryUrl = ipaddress.getIp + "usercontroller/count";
    this.currentDateUrl = ipaddress.getIp + "adm/curdate";
    this.appNursePhysio = Client_Helper.getHomecare_nurse_physio();
    this.homecare = Client_Helper.getHomecare();
    if (this.gservice.Client_login_detail_data.Client_Id != undefined) {
      this.clientRegId = {
        client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
      }
      this.otherRelationData = {
        client_id: this.gservice.Client_login_detail_data.Client_Id,
      }
    }
    this.getRelationList();   
    // get doctor list
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 25,
      "lengthMenu": [[25, 50, -1], [25, 50, "All"]],
      "order": [[2, 'asc']],
      "columnDefs": [
        { "orderable": false, "targets": [0,4,5] },
      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search Physiotherapist with name or hospital name or location or Gender or exp"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    this.clntCountryId = this.gservice.Client_login_detail_data.Country_ID;
    this.clntStateId = this.gservice.Client_login_detail_data.State_ID;
    this.clntCityId = this.gservice.Client_login_detail_data.City_ID;
    this.clntLocationId = this.gservice.Client_login_detail_data.Location_ID;
    this.filtCity = this.gservice.Client_login_detail_data.Client_City;
    this.locationName = this.gservice.Client_login_detail_data.Client_Location;
    this.typeLable =  this.appNursePhysio == "physio" ? "Physiotherapist"  : "Dietician";
    this.getDoctors();
    this.getCountries(0);

    // get doctor details
    this.homecare = Client_Helper.getHomecare();
    this.appNursePhysio= Client_Helper.getHomecare_nurse_physio();
    this.addressFlag = (this.homecare == '0') ? true : false;
    this.title = (this.appNursePhysio == 'nurse') ? "Nurse" : "Doctor";

   
   
  }

  getRelationList() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "usercontroller/grels/",this.clientRegId, { headers: headers }).subscribe(
      data => {
        var obj=JSON.stringify(data)
        if (data.json().relationships != null && data.json().relationships != undefined) {
          this.relationList = data.json().relationships;
          Helper_Class.setrelation(this.relationList)
          this.relationship = data.json().relationships[0].relationship_name;
          this.changeRelation(this.relationship);
        }
      },
      error => {
        this.toastr.error(Message_data.unableToFetchData);
      }
    )
  }

  changeRelation(rel_data) {
    this.otherRelationList = [];
    this.clntFirstName = null;
    this.clntMiddleName = null;
    this.clntLastName = null;
    this.clntAge = null;
    this.clntGender = null;
    this.clntDOB = null;
    if (rel_data == "Self") {
      this.relativeDetailsFlag = false;
      rel_id="1"
      if (this.gservice.Client_login_detail_data.Client_Id != undefined) {
        this.selfRelationData = {
          client_id: this.gservice.Client_login_detail_data.Client_Id,
          relationship: "Self",
          country: "IN"
        }
      } else {
        this.selfRelationData = {
          client_id: this.gservice.Client_login_detail_data.Client_Id,
          relationship: rel_data,
          country: "IN"
        }
      }
      this.otherRelationData = {
        client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
        relationship: rel_data,
        relation_id: rel_id,
        country: "IN",
      }
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.clntDetailedUrl,(this.selfRelationData), { headers: headers }).subscribe(
        data => {
          var obj=JSON.stringify(data);
          Helper_Class.setclientbasicinfo(obj)
          if (data.json().length != 0) {
          this.clntFirstName = encrypt_decript.Decript(data.json().first_name);
            if (data.json().middle_name != null) {
              this.clntMiddleName = encrypt_decript.Decript(data.json().middle_name);
            }
            this.clntLastName = encrypt_decript.Decript(data.json().last_name);
            if (data.json().age != 0) {
              this.clntAge = data.json().age;
            } else {
              this.clntAge = "<1";
            }
            if (data.json().Occupation != null) {  
              this.clntOccupation = encrypt_decript.Decript(data.json().Occupation);
            }
            if(data.json().location_desc != null){
              this.clntLocation = data.json().location_desc;
            }
            if(data.json().city_desc != null){
              this.clntCity = data.json().city_desc;
            }
            if(data.json().state_desc != null){
              this.clntState = data.json().state_desc;
            }
            if(data.json().country_desc != null){
              this.clntCountry = data.json().country_desc;
            }
            if(data.json().address1 != null){
              this.clntAddr1 = encrypt_decript.Decript(data.json().address1);
            }
            if(data.json().address2 != null){
              this.clntAddr2 = encrypt_decript.Decript(data.json().address2);
            }
            if(data.json().zipcode != null){
              this.clntZipcode = encrypt_decript.Decript(data.json().zipcode);
            }
            if(data.json().symptom != null){
              this.clntSymptom = data.json().symptom;
            }
            if (data.json().gender != null && data.json().gender != "0: undefined") {
              this.clntGender = encrypt_decript.Decript(data.json().gender);
            }
            if (data.json().dob) {
              this.clntDOB = data.json().dob;
            }
            if (data.json().blood_desc) {
              this.clntBldgrp = data.json().blood_desc;
            }
            if(data.json().mobile != null){
              this.clntPhoneNo = encrypt_decript.Decript(data.json().mobile);
            }
          } else {
            this.toastr.error(Message_data.unableToFetchData);
          }
        },error => {
          this.toastr.error(Message_data.unableToFetchData);
        });
    } else {
      var rel_id = ""; 
      this.relationList=Helper_Class.getrelation()
      for (var i = 0; i < this.relationList.length; i++) {
        if (this.relationList[i].relationship_name ==  rel_data) {
          rel_id = this.relationList[i].relation_id;
          this.relationId = this.relationList[i].relation_id;
          break;
        }
      }
      this.otherRelationData = {
        client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
        relationship: rel_data,
        relation_id: rel_id,
        country: "IN",
      }
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.otherRelDetUrl, JSON.stringify(this.otherRelationData), { headers: headers }).subscribe(
        data => {
          var obj=JSON.stringify(data)
          if (data.json().rel_details != null && data.json().rel_details.length != 0) {
            if (rel_id == "2" || rel_id == "3" || rel_id == "6") {
              this.clntSubrelID = data.json().rel_details[0].cln_rel_id;
              this.clntFirstName = data.json().rel_details[0].first_name;
              if (data.json().rel_details[0].middlename != null && data.json().rel_details[0].middlename != undefined) {
                this.clntMiddleName = data.json().rel_details[0].middlename;
              }
              this.clntLastName = data.json().rel_details[0].last_name;
              if (data.json().rel_details[0].age != 0) {
                this.clntAge = data.json().rel_details[0].age;
              } else {
                this.clntAge = "<1";
              }
              if (data.json().rel_details[0].gender != null && data.json().rel_details[0].gender != "0: undefined") {
                this.clntGender = data.json().rel_details[0].gender;
              }
              if (data.json().rel_details[0].dob) {
                this.clntDOB = data.json().rel_details[0].dob;
              }
              if(data.json().mobile != undefined){
                this.clntPhoneNo = data.json().mobile;
              }
            } else {
              this.otherRelationList = [];
              for (var i = 0; i < data.json().rel_details.length; i++) {
                if (data.json().rel_details[i].address2 != null) {
                  var orel_address_2 = encrypt_decript.Decript(data.json().rel_details[i].address2);
                }
                if (data.json().rel_details[i].dob != null) {
                  var orel_dob = data.json().rel_details[i].dob;
                }
                var midname = "";var name;
                if (data.json().rel_details[i].middle_name != undefined) {
                  midname = data.json().rel_details[i].middle_name;
                  name = (data.json().rel_details[i].first_name).toString() + " " + (data.json().rel_details[i].middle_name) + " " + data.json().rel_details[i].last_name;
                }
                else{
                  name = (data.json().rel_details[i].first_name).toString() + " " + data.json().rel_details[i].last_name;
                }
                this.otherRelationList.push({
                  cln_rel_id: data.json().rel_details[i].cln_rel_id,
                  sub_rel_id:data.json().rel_details[i].cln_rel_id,
                  relation_id: rel_id,
                  name:name,
                  first_name: data.json().rel_details[i].first_name,
                  middlename: midname,
                  last_name: data.json().rel_details[i].last_name,
                  age: data.json().rel_details[i].age,
                  gender: data.json().rel_details[i].gender,
                  blood_group: data.json().rel_details[i].blood_desc,
                  blood_group_id: data.json().rel_details[i].blood_group,
                  father_guardian_name: data.json().rel_details[i].family_guardian,
                  income: data.json().rel_details[i].family_income,
                  occupation: data.json().rel_details[i].occupation,
                  address1: encrypt_decript.Decript(data.json().rel_details[i].address1),
                  address2: orel_address_2,
                  location: data.json().rel_details[i].location_desc,
                  city: data.json().rel_details[i].city_desc,
                  state: data.json().rel_details[i].state_desc,
                  country: data.json().rel_details[i].country_desc,
                  location_id: data.json().rel_details[i].location,
                  city_id: data.json().rel_details[i].city,
                  state_id: data.json().rel_details[i].state,
                  country_id: data.json().rel_details[i].country,
                  zipcode: encrypt_decript.Decript(data.json().rel_details[i].zipcode),
                  contact_num: data.json().rel_details[i].mobile,
                  dob: orel_dob,
                  mobile: data.json().mobile,
                });
              }
              this.relativeDetailsFlag = true;
            }
          }
        },
        error => {
          this.toastr.error(Message_data.unableToFetchData);
        }
      )
    }
  }

  getReferralData(e) {
    this.doctorList = [];
    this.referredDoctorListData = [];
    this.referredDoctor = e.target.value.toString();
    if (this.referredDoctor != "" && this.referredDoctor.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'search/docbyhns/',
        JSON.stringify({
          doc_name: this.referredDoctor
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            this.referredDoctorListData = obj.doctors;
            if (this.referredDoctorListData != undefined) {
              for (var i = 0; i < this.referredDoctorListData.length; i++) {
                var docname;
                if(this.referredDoctorListData[i].middle_name !=undefined){
                  docname=this.referredDoctorListData[i].first_name + " " + this.referredDoctorListData[i].middle_name + " " + this.referredDoctorListData[i].last_name;
                } else {
                  docname=this.referredDoctorListData[i].first_name + " " + this.referredDoctorListData[i].last_name;
                }
                this.doctorList.push(docname);
              }
            }
            this.doctorList = this.doctorList.filter(function (this:any,el) {
              return el.toLowerCase().indexOf(this.refer_txt.toLowerCase()) > -1;
            }.bind(this));
          },error => {
            this.toastr.error(Message_data.defaultErr);
          });
    } else {
      this.doctorList = [];
    }
  }

  selectReferredDoctor(item) {
    this.referredDoctor = item;
    this.doctorList = [];
  }

  next(){
    var rel_id;
    for(var i=0; i<this.relationList.length; i++){
      if(this.relationship == this.relationList[i].relationship_name){
        rel_id = this.relationList[i].relation_id;
      }
    }
    this.sendData.push({
      relationship:this.relationship,
      rel: rel_id,
      clnt_subrel_id :this.clntSubrelID,
      clnt_fname : this.clntFirstName,
      clnt_mname : this.clntMiddleName,
      clnt_lname : this.clntLastName,
      clnt_age : this.clntAge,
      clnt_bldgrp : this.clntBldgrp,
      clnt_gender : this.clntGender,
      clnt_fathername : this.clntFatherName,
      clnt_income : this.clntIncome,
      clnt_occupation : this.clntOccupation,
      clnt_addr1 : this.clntAddr1,
      clnt_addr2 : this.clntAddr2,
      clnt_location : this.clntLocation,
      clnt_city : this.clntCity,
      clnt_state : this.clntState,
      clnt_zipcode : this.clntZipcode,
      clnt_country : this.clntCountry,
      clnt_phoneno : this.clntPhoneNo,
      clnt_symptom : this.clntSymptom,
      clnt_dob: this.clntDOB,
      referredby : this.referredDoctor,
      app_type: this.appType,
    });
    Client_Helper.setPhysioClientInfo(this.sendData);
    if(this.appNursePhysio == "physio" || this.appNursePhysio == "dietician"){
      this.messageservice.sendMessage("physio_doctor_list");
    }
    if(this.appNursePhysio == "nurse"){
      this.messageservice.sendMessage("physio_appointment_conform");
    }
  }

  changeOtherRelation(name) {
    for (var i = 0; i < this.otherRelationList.length; i++) {
      if (name == this.otherRelationList[i].name) {
        this.clntSubrelID = this.otherRelationList[i].sub_rel_id;
        this.clntFirstName = this.otherRelationList[i].first_name;
        if (this.otherRelationList[i].middle_name != null && this.otherRelationList[i].middle_name != "") {
          this.clntMiddleName = this.otherRelationList[i].middle_name;
        }
        this.clntLastName = this.otherRelationList[i].last_name;
        if (this.otherRelationList[i].age != 0) {
          this.clntAge = this.otherRelationList[i].age;
        } else {
          this.clntAge = "<1";
        }
        this.relationId = this.otherRelationList[i].relation_id;
        this.clntGender = this.otherRelationList[i].gender;
        this.clntDOB = this.otherRelationList[i].dob;
        this.clntOccupation = this.otherRelationList[i].occupation;
        this.clntAddr1 = this.otherRelationList[i].address1;
        this.clntAddr2 = this.otherRelationList[i].address2;
        if(this.otherRelationList[i].contact_num != undefined){
          this.clntPhoneNo = this.otherRelationList[i].contact_num;
        }
      }
    }
  }

  back(){
    if (Client_Helper.getHomecare_nurse_physio() == "nurse" ) {
      Client_Helper.setHomecare_nurse_physio(this.appNursePhysio);
      Client_Helper.setHomecare(this.homecare);
      this.messageservice.sendMessage("physio_doctor_detail");
      this.router.navigateByUrl("/physiodoctordetail");
    }
    if (Client_Helper.getHomecare_nurse_physio() == "physio" || Client_Helper.getHomecare_nurse_physio() == "dietician" ) {
        Client_Helper.setHomecare_nurse_physio(this.appNursePhysio);
        Client_Helper.setHomecare(this.homecare);
        this.messageservice.sendMessage("physio");
    }
  }

  // get doctor list
  getCountries(flag) {
    this.clntCountryList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(this.countryUrl, { headers: headers }).subscribe(
      data => {
        this.clntCountryList = [];
        if (data.json().countries != null) {
          this.clntCountryList = data.json().countries;
          for (var c = 0; c < this.clntCountryList.length; c++) {
            if (this.clntCountryList[c].country_id == this.clntCountryId) {
              this.filtCountry = this.clntCountryList[c].description;
              this.clntCountryId = this.clntCountryList[c].country_id;
              this.getStates(this.clntCountryId, flag);
              break;
            }
          }
        }
      },error => {
        this.toastr.error(Message_data.unableToFetchData);
      });
  }

  changeCountry(clnt_country, flag) {
    for (var c = 0; c < this.clntCountryList.length; c++) {
      if (this.clntCountryList[c].description == clnt_country) {
        this.filtCountry = this.clntCountryList[c].description;
        this.clntCountryId = this.clntCountryList[c].country_id;
        this.clntStateList = [];
        this.clntCityList = [];
        this.clntLocationId = undefined;
        this.locationName = "";
        this.getStates(this.clntCountryId, flag);
      }
    }
    this.filtCountry=this.clntCountryList[0].description;
  }

  getStates(country, flag) {
    this.clntStateList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.stateUrl, JSON.stringify({ country_id: country }),
      { headers: headers }).subscribe(
        data => {
          if (data.json().states != null) {
            this.clntStateList = data.json().states;
            for (var i = 0; i < this.clntStateList.length; i++) {
              if (this.clntStateList[i].state_id == this.clntStateId) {
                this.clntStateId = this.clntStateList[i].state_id;
                this.filtState = this.clntStateList[i].description;
                this.getCities(this.clntStateId, flag);
                break;
              }
            }
          }
        });
  }

  changeState(clnt_state, flag) {
    for (var i = 0; i < this.clntStateList.length; i++) {
      if (this.clntStateList[i].description == clnt_state) {
        this.clntStateId = this.clntStateList[i].state_id;
        this.filtState = this.clntStateList[i].description;
        this.clntCityList = [];
        this.clntCityId = undefined;
        this.clntLocationId = undefined;
        this.locationName = "";
        this.getCities(this.clntStateId, flag);
      }
    }
  }

  getCities(state, flag) {
    this.clntCityList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.cityUrl, JSON.stringify({ state_id: state }),
      { headers: headers }).subscribe(
        data => {
          if (data.json().cities != null) {
            this.clntCityList = data.json().cities;
            for (var i = 0; i < this.clntCityList.length; i++) {
              if (this.clntCityList[i].district_id == this.clntCityId) {
                this.clntCityId = this.clntCityList[i].district_id;
                this.filtCity = this.clntCityList[i].description;
                break;
              } else {
                this.filtCity = this.clntCityList[0].description;
              }
            }
          }
        });
  }

  changeCity(clnt_city, flag) {
    this.clntLocationList = [];
    for (var i = 0; i < this.clntCityList.length; i++) {
      if (this.clntCityList[i].description == clnt_city) {
        this.clntCityId = this.clntCityList[i].district_id;
        this.filtCity = this.clntCityList[i].description;
        this.clntLocationId = undefined;
        this.locationName = "";
      }
    }
  }

  changeLocation(locat_desc) {
    this.clntLocationList = [];
    this.locationListData=[];
    this.locationList=[];
    if (locat_desc != null && locat_desc.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.locationNameByUrl, JSON.stringify({
        name: locat_desc,
        city_id: this.clntCityId,
      }), { headers: headers }).subscribe(
        data => {
          if (data.json().locations != null && data.json().locations.length != 0) {
            this.clntLocationList = data.json().locations;
          }
          for (var i = 0; i < this.clntLocationList.length; i++) {
            this.locationListData.push(this.clntLocationList[i].description);
          }
          this.locationList = this.locationListData.filter(function (this: any, el) {
            return el.toLowerCase().indexOf(this.locationName.toLowerCase()) > -1;
          }.bind(this));
        },
        error => {
          this.toastr.error(Message_data.unableToFetchDocDet);
        }
      )
    } else {
      this.clntLocationList = [];
    }
  }

  selectLocation(location) {
    this.locationName = location;
    for (var i = 0; i < this.clntLocationList.length; i++) {
      if (this.clntLocationList[i].location_id == location.location_id) {
        this.clntLocationId = this.clntLocationList[i].location_id;
        this.locationName = this.clntLocationList[i].description;
      }
    }
    this.clntLocationList = [];
    this.getDoctors()
  }

  getDoctors(){
     var sendData = {
      location: this.locationName,
      city:this.filtCity,
      type:this.appNursePhysio,
      homecare:this.homecare
    }
    var name,hname,lname;
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "search/npsear", 
    JSON.stringify(sendData), { headers: headers }).subscribe(
      data => {   
        var obj = data.json().info;
        console.log("obj" + JSON.stringify(obj));
        this.physioDoctorList=[];
        for(var i = 0; i < obj.length; i++){
          if(obj[i].middle_name != null){
            name = obj[i].first_name + " " + obj[i].middle_name + " " + obj[i].last_name;
          }
          else{
            name = obj[i].first_name + " " + obj[i].last_name;
          }
          for(var j = 0; j < obj[i].hospital.length;j++){
            hname = obj[i].hospital[j].hospital_name;
            lname = obj[i].hospital[j].location;
          }
          this.physioDoctorList.push({
            prov_id :obj[i].prov_id,
            first_name :obj[i].first_name,
            middle_name :obj[i].middle_name,
            last_name :obj[i].last_name,
            name : name,
            gender : obj[i].gender,
            experience : obj[i].experience,
            profile_image : ipaddress.Ip_with_img_address + obj[i].profile_image,
            hospital_name : hname,
            location : lname,
            hospital : obj[i] .hospital,
          })
        }
      },
      error => {
      })
  }
  selectDoctor(physiolist){
      Client_Helper.setPhysio_Doctor_List(null);
      Client_Helper.setPhysio_Doctor_List(physiolist);
      console.log("physiolist" + JSON.stringify(physiolist));
      this.selectDoctorFlag = true;
      this.physioDoctorList = physiolist;
      this.name = this.physioDoctorList.name;
      this.workExperience = this.physioDoctorList.experience;
      this.profileImg = this.physioDoctorList.profile_image;
      this.timeList = []; 
      this.doctorDetailData = [];
      this.hospitalList = [];
      this.visitingSessionData = [];
      this.sessionList = [];
      this.homecareData = [];
      this.homecareTimeList = [];
      this.getCurrentDate();
  }
  resetDoctor(){
    this.selectDoctorFlag = false;
    this.getDoctors()
  }
  
  getCurrentDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.currentDateUrl,{ country: "IN" }, { headers: headers }).subscribe(
      data => {
        this.currentDate = Date_Formate(data.json().current_date);
        var current_date1 = data.json().current_date;
        this.presentdate = Date_Formate(this.currentDate);
        this.currentTime = data.json().current_time;
        this.currentYear = data.json().current_date.split('-')[0];
        this.appointmentDate = data.json().current_date;
        this.homecareAppDate = this.appointmentDate;
        this.getDoctorDetails(this.currentDate, this.currentTime)
      },error => {});
  }

  getDoctorDetails(curt_date, curt_time){
    var doctor_send_data =JSON.stringify({
      prov_id: this.physioDoctorList.prov_id,
      type: this.appNursePhysio,
      location: this.physioDoctorList.location,
      country: ipaddress.country_code,
      homecare: this.homecare
    });
    var headers = new Headers;
    headers.append('Content-Type','application/json');
    this.http.post(ipaddress.getIp + "search/npsbyid", doctor_send_data , {headers:headers}).subscribe(
      data => {
        var obj = data.json();
        if(obj.consultation != null){
          for(var i = 0; i < obj.consultation.length; i++){
            if (obj.consultation[i].fees != "0") {
              this.tempFee = obj.consultation[i].fees;
            } else {
              this.tempFee = "NA";
            }
            this.hospitalList.push({
              hospital_id: obj.consultation[i].hospital_id,
              hospname: obj.consultation[i].hospital,
              Location: obj.consultation[i].location,
              City: obj.consultation[i].city,
              fees: this.tempFee,
            });
            if( obj.consultation[i].pay_cash != undefined || obj.consultation[i].pay_online != undefined || obj.consultation[i].pay_insurance != undefined){
              this.paymentType = true;
              this.payCash = obj.consultation[i].pay_cash != undefined ? obj.consultation[i].pay_cash : "0";
              this.payOnline = obj.consultation[i].pay_online != undefined ? obj.consultation[i].pay_online : "0";
              this.payInsurance  = obj.consultation[i].pay_insurance != undefined ? obj.consultation[i].pay_insurance : "0";
            }
            this.doctorDetailData.push({
              hospitalid: obj.consultation[i].hospital_id,
              hospital: obj.consultation[i].hospital,
              location: obj.consultation[i].location,
              day: this.gservice.get_Day(obj.consultation[i].day),
              day_num: obj.consultation[i].day,
              session: this.gservice.get_Session(obj.consultation[i].session),
              available_from: this.gservice.get_Timeformate(obj.consultation[i].available_from),
              available_to: this.gservice.get_Timeformate(obj.consultation[i].available_to),
              fees: this.tempFee,
              TokenorTime: obj.consultation[i].token_flag,
              Time_Duration: obj.consultation[i].time_duration,
              Avail_from: obj.consultation[i].available_from,
              Avail_to: obj.consultation[i].available_to,
              pay_cash : obj.consultation[i].pay_cash,
              pay_online : obj.consultation[i].pay_online,
              pay_insurance : obj.consultation[i].pay_insurance,
            });
          }
          this.hospitalId = this.hospitalList[0].hospital_id;
          this.hospitalName = this.hospitalList[0].hospname;
          this.hospitalLocation = this.hospitalList[0].Location;
          this.hospitalFee = this.hospitalList[0].fees;
          this.addSession(this.hospitalId);
          var parts = curt_date.split('-');
          this.available(parts[2] + "-" + parts[1] + "-" + parts[0], curt_time); 
          this.timeCardFlag = true;
          this.changeAppDate(curt_date);
        }
        if (obj.home_consult != null) {
          this.homecareData = [];
          this.homecareSessionData = [];
          for (var i = 0; i < obj.home_consult.length; i++) {
            this.homecareData.push({
              Session: this.gservice.get_Session(obj.home_consult[i].session),
              Avail_from: this.gservice.get_Timeformate(obj.home_consult[i].available_from),
              Avail_to: this.gservice.get_Timeformate(obj.home_consult[i].available_to),
              Fees: obj.home_consult[i].fees,
              Time_Inter: obj.home_consult[i].time_interval,
              avail_from: obj.home_consult[i].available_from,
              avail_to: obj.home_consult[i].available_to
            });
            this.homecareSessionData.push(this.gservice.get_Session(obj.home_consult[i].session));
            this.addSession("");
            this.timeCardFlag = true;
            this.changeHomecareDate(curt_date);
          }
        }
      });
  }

  addSession(Hospital) {
    this.visitingSessionData = [];
    var gsDayNames = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday'
    ];
    if (Hospital == "") {
      var get_date = Date_Formate(this.homecareAppDate);
      var curr_datetime = new Date(Date_Formate(get_date) + " " + this.currentTime);
      var cuur_mor_sess = "", cuur_afetr_sess = "", cuur_eve_sess = "";
      if (curr_datetime.getHours() >= 0 && curr_datetime.getHours() < 12) {
        cuur_mor_sess = "Morning";
        cuur_afetr_sess = "Afternoon";
        cuur_eve_sess = "Evening";
      }
      if (curr_datetime.getHours() >= 12 && curr_datetime.getHours() < 16) {
        cuur_afetr_sess = "Afternoon";
        cuur_eve_sess = "Evening";
      }
      if (curr_datetime.getHours() >= 16 && curr_datetime.getHours() < 24) {
        cuur_eve_sess = "Evening";
      }
      for (var a = 0; a < this.homecareSessionData.length; a++) {
        if (this.currentDate.trim() == get_date.trim()) 
        {
          if (this.homecareSessionData[a] == "Morning" && cuur_mor_sess == "Morning") {
            this.visitingSessionData.push({
              description: "Morning",
              id: "1"
            });
          }
          if (this.homecareSessionData[a] == "Afternoon" && cuur_afetr_sess == "Afternoon") {
            this.visitingSessionData.push({
              description: "Afternoon",
              id: "2"
            });
          }
          if (this.homecareSessionData[a] == "Evening" && cuur_eve_sess == "Evening") {
            this.visitingSessionData.push({
              description: "Evening",
              id: "3"
            });
          }
        } else {
          if (this.homecareSessionData[a] == "Morning") {
            this.visitingSessionData.push({
              description: "Morning",
              id: "1"
            });
          }
          if (this.homecareSessionData[a] == "Afternoon") {
            this.visitingSessionData.push({
              description: "Afternoon",
              id: "2"
            });
          }
          if (this.homecareSessionData[a] == "Evening") {
            this.visitingSessionData.push({
              description: "Evening",
              id: "3"
            });
          }
        }
      }
    } else {
      var get_date:string = Date_Formate(this.appointmentDate);
      var curr_datetime = new Date(Date_Formate(get_date) + " " + this.currentTime);
      var cuur_mor_sess = "", cuur_afetr_sess = "", cuur_eve_sess = "";
      if (curr_datetime.getHours() >= 0 && curr_datetime.getHours() < 12) {
        cuur_mor_sess = "Morning";
        cuur_afetr_sess = "Afternoon";
        cuur_eve_sess = "Evening";
      }
      if (curr_datetime.getHours() >= 12 && curr_datetime.getHours() < 16) {
        cuur_afetr_sess = "Afternoon";
        cuur_eve_sess = "Evening";
      }
      if (curr_datetime.getHours() >= 16 && curr_datetime.getHours() < 24) {
        cuur_eve_sess = "Evening";
      }
      for (var a = 0; a < this.doctorDetailData.length; a++) {
        var dayName = gsDayNames[curr_datetime.getDay()];
        var sel_day_num = this.gservice.get_Day_num(dayName);
        var day_num = this.doctorDetailData[a].day_num;
        let dayflag = false;
        if (dayName == this.doctorDetailData[a].day) {
          dayflag = true;
        } else if (this.doctorDetailData[a].day == "All Days") {
          dayflag = true;
        } else if (sel_day_num != 1 && day_num == 8) {
          dayflag = true;
        }
        if (this.doctorDetailData[a].hospitalid == Hospital
          && dayflag == true) {
          if (this.currentDate.trim() == get_date.trim()) 
          {
            if (this.doctorDetailData[a].session == "Morning" && cuur_mor_sess == "Morning") {
              this.visitingSessionData.push({
                description: "Morning",
                id: "1"
              });
            }
            if (this.doctorDetailData[a].session == "Afternoon" && cuur_afetr_sess == "Afternoon") {
              this.visitingSessionData.push({
                description: "Afternoon",
                id: "2"
              });
            }
            if (this.doctorDetailData[a].session == "Evening" && cuur_eve_sess == "Evening") {
              this.visitingSessionData.push({
                description: "Evening",
                id: "3"
              });
            }
          } else {
            if (this.doctorDetailData[a].session == "Morning") {
              this.visitingSessionData.push({
                description: "Morning",
                id: "1"
              });
            }
            if (this.doctorDetailData[a].session == "Afternoon") {
              this.visitingSessionData.push({
                description: "Afternoon",
                id: "2"
              });
            }
            if (this.doctorDetailData[a].session == "Evening") {
              this.visitingSessionData.push({
                description: "Evening",
                id: "3"
              });
            }
          }
        }
      }
    }
  }

  checkAvailability(get_sel_date, sess, f_time, t_time) {
    var Available_flag = true;
    var get_date = get_sel_date.split('-');
    var check_date = new Date(get_sel_date);
    return Available_flag;
  }

  selectAppointmentDate(data,value) {
    this.timeCardFlag = true;
    if (data != null) {
            if (this.homecare == "1") {
              this.homecareAppDate = data;
              this.addSession("");
              this.changeHomecareDate(Date_Formate(data));
            } 
            if (this.homecare == "0") {
              this.appointmentDate = data;
              this.addSession(this.hospitalId);
              this.changeAppDate(Date_Formate(data));
            }
     }
  }

  changeHomecareDate(appdate) {
    this.homecareTimeList = [];
    this.changeHomecareVisitsession(appdate);
  }

  changeHomecareVisitsession(appdate) {
    this.homecareTimeList = [];
    var time_t =[];
    var session;
    for(var j=0; j<this.visitingSessionData.length; j++){
      session="";
      session = this.visitingSessionData[j].description;
      time_t=[];
      for (var i = 0; i < this.homecareData.length; i++) {
        if (session == this.homecareData[i].Session) {
          var From_Split = this.homecareData[i].avail_from.split(':');
          var To_Split = this.homecareData[i].avail_to.split(':');
          var parts = (appdate).split('-');
          var fromday = new Date();
          fromday.setHours(From_Split[0]);
          fromday.setMinutes(From_Split[1]);
          var today = new Date();
          today.setHours(To_Split[0]);
          today.setMinutes(To_Split[1]);
          var New_time1 = this.datepipe.transform(fromday, 'hh:mm a');
          var chk_time = this.datepipe.transform(fromday, 'hh:mm');
          var end_time = this.datepipe.transform(today, 'hh:mm');
          if (this.currentDate == appdate && fromday < today) { 
            time_t.push(New_time1);
          } else {
            if (fromday < today) {
              if (this.currentDate != appdate) {
                time_t.push(New_time1);
              }
            }
          }
          var substr = this.homecareData[i].Time_Duration;
          while (fromday < today) {
            var Day_Now = new Date();
            if (this.currentDate == appdate) {
              var Now_Split = this.currentTime.split(':');
              Day_Now.setHours(parseInt(Now_Split[0]));
              Day_Now.setMinutes(parseInt(Now_Split[1]));
            } else {
              Day_Now.setHours(From_Split[0]);
              Day_Now.setMinutes(From_Split[1]);
            }
            fromday.setMinutes(fromday.getMinutes() + parseInt(this.homecareData[i].Time_Inter));
            var chk_time1 = this.datepipe.transform(fromday, 'hh:mm');
            var Avail_flag = this.checkAvailability(appdate, session, chk_time1, end_time);
            if (Day_Now <= fromday && fromday < today) {
              var New_time = this.datepipe.transform(fromday, 'hh:mm a');
              if (New_time != this.gservice.get_Timeformate(this.homecareData[i].Avail_to)) {
                time_t.push(New_time);
              }
            } 
          }
        }
      }
      if(time_t.length !=0){
        this.homecareTimeList.push({
          session:session,
          time:time_t
        });
      }
    } 
  }

  available(curtdate, curttime) {
    var Available_flag = false;
    var gsDayNames = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday'
    ];
    var get_date = (curtdate).split('-');
    var get_sel_date = new Date(get_date[0] + "-" + get_date[1] + "-" + get_date[2]);
    var get_date = (curtdate).split('-');
    var result = new Date(get_date[2] + "-" + get_date[1] + "-" + get_date[0]);
    var dayName = gsDayNames[result.getDay()];
    var get_sel_day_num = this.gservice.get_Day_num(dayName);
    for (var i = 0; i < this.doctorDetailData.length; i++) {
      var get_day_num = this.gservice.get_Day_num(this.doctorDetailData[i].day);
      if (this.doctorDetailData[i].hospital == this.hospitalName) {
        var Cur_timesplit = [];
        Cur_timesplit = curttime.split(':');
        var Curre_time = new Date(get_sel_date);
        Curre_time.setHours(Cur_timesplit[0]);
        Curre_time.setMinutes(Cur_timesplit[1]);
        var Avtotimesplit = [];
        Avtotimesplit = this.doctorDetailData[i].Avail_to.split(':');
        var Retrivel_time = new Date(get_sel_date);
        Retrivel_time.setHours(Avtotimesplit[0]);
        Retrivel_time.setMinutes(Avtotimesplit[1]);
        if (this.doctorDetailData[i].day_num == "9" || (get_sel_day_num != 1 && get_day_num == 8)) {
          if (this.currentDate == (curtdate)) {
            if (Retrivel_time > Curre_time) {
              Available_flag = true;
            }
          } else {
            Available_flag = true;
          }
        } else {
          if ((new Date(curtdate)).getDay() + 1 == this.doctorDetailData[i].day_num) {
            if (this.currentDate == Date_Formate(curtdate)) {
              if (Retrivel_time > Curre_time && this.currentDate == this.gservice.get_Date_Formate(curtdate)) {
                Available_flag = true;
              }
            } else {
              Available_flag = true;
            }
          }
        }
      }
    }
    Available_flag = this.checkAvailability(curtdate, null, null, null);
    if (this.visitingSessionData.length == 0) {
      Available_flag = false;
    }
    if (Available_flag == false) {
      this.availableFlag = false;
      this.nonAvailableFlag = true;
    } else {
      this.availableFlag = true;
      this.nonAvailableFlag = false;
    }
  }

  changeVisitSessionForTime(appdate){
    this.timeList = [];
    var time_t =[];
    var session;
    for(var j=0; j < this.visitingSessionData.length; j++){
      session ="";
      session = this.visitingSessionData[j].description;
      time_t = [];
      for (var i = 0; i < this.doctorDetailData.length; i++) {
        if (session == this.doctorDetailData[i].session) {
          var From_Split = this.doctorDetailData[i].Avail_from.split(':');
          var To_Split = this.doctorDetailData[i].Avail_to.split(':');
          var fromday = new Date();
          var parts = (appdate).split('-');
          var datetime = new Date(parts[2] + "-" + parts[1] + "-" + parts[0] + " " + this.currentTime);
          var curr_datetime = new Date(datetime + " " + this.currentTime);
          var curr_get_ftime = new Date(datetime + " " + this.doctorDetailData[i].Avail_from);
          fromday.setHours(From_Split[0]);
          fromday.setMinutes(From_Split[1]);
          var today = new Date();
          today.setHours(To_Split[0]);
          today.setMinutes(To_Split[1]);
          var New_time1 = this.datepipe.transform(fromday, 'hh:mm a');
          var chk_time = this.datepipe.transform(fromday, 'hh:mm');
          var end_time = this.datepipe.transform(today, 'hh:mm');
          var Available_flag = this.checkAvailability(appdate, session, chk_time, end_time);
          if (Available_flag == true && this.currentDate == appdate && curr_get_ftime > curr_datetime) {
            time_t.push(New_time1);
          } else {
            if (Available_flag == true && this.currentDate != appdate) {
              time_t.push(New_time1);
            }
          }
          var substr = this.doctorDetailData[i].Time_Duration;
          while (fromday < today) {
            var Day_Now = new Date();
            if (this.currentDate == appdate) {
              var Now_Split = this.currentTime.split(':');
              Day_Now.setHours(parseInt(Now_Split[0]));
              Day_Now.setMinutes(parseInt(Now_Split[1]));
            } else {
              Day_Now.setHours(From_Split[0]);
              Day_Now.setMinutes(From_Split[1]);
            }
            fromday.setMinutes(fromday.getMinutes() + parseInt(this.doctorDetailData[i].Time_Duration));
            var chk_time1 = this.datepipe.transform(fromday, 'hh:mm');
            var Avail_flag = this.checkAvailability(appdate, session, chk_time1, end_time);
            if (Avail_flag == true && Day_Now <= fromday && fromday < today) {
              var New_time = this.datepipe.transform(fromday, 'hh:mm a');
              if (New_time != this.gservice.get_Timeformate(this.doctorDetailData[i].Avail_to)) {
                time_t.push(New_time);
              }
            } 
          }
        }
      }
      if(time_t.length !=0){
        this.timeList.push({
          session:session,
          time:time_t
        });
      }
    } 
  }

  changeAppDate(appdate) {
    if ((this.currentDate) != appdate) {
      var gsDayNames = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
      ];
      var get_date = appdate.split("-");
      var result = new Date(get_date[2] + "-" + get_date[1] + "-" + get_date[0]);
      var dayName = gsDayNames[result.getDay()];
      var get_sel_day_num = this.gservice.get_Day_num(dayName);
      var Available_flag_1 = false;
      for (var i = 0; i < this.doctorDetailData.length; i++) {
        if (this.doctorDetailData[i].hospitalid == this.hospitalId) {
          if (this.doctorDetailData[i].day_num == "8" || (get_sel_day_num != 1 && this.doctorDetailData[i].day_num == "9")) {
            Available_flag_1 = true;
          } else {
            if (((new Date(appdate)).getDay() + 1) == this.doctorDetailData[i].day_num) {
              Available_flag_1 = true;
            }
          }
        }
      }
    this.addSession(this.hospitalId);
    this.changeVisitSessionForTime(appdate);
    this.available(Date_Formate(appdate), this.currentTime);
    }
    else{
      this.addSession(this.hospitalId);
      this.changeVisitSessionForTime(appdate);
      this.available(Date_Formate(appdate), this.currentTime);
    }
  }

  selectAppointmentTime(e,s,index){
    this.visitSession = s;
    this.visitTime = e;
    var t;
    if(this.homecare == '0'){
      for(var i= 0; i<this.timeList.length; i++){
        t=[];
        t= this.timeList[i].time;
        for(var j=0;j<t.length;j++){
          if(t[j] == e){
              document.getElementById("time_"+[i]+"_"+index).style.backgroundColor ="#868383";
              document.getElementById("time_"+[i]+"_"+[j]).style.boxShadow ="0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)";  
          }
          else{
              document.getElementById("time_"+[i]+"_"+[j]).style.backgroundColor ="#e0e0e0";
              document.getElementById("time_"+[i]+"_"+[j]).style.boxShadow ="none";
          }
        }
      }
    }
    else{
      for(var i= 0; i<this.homecareTimeList.length; i++){
        t=[];
        t= this.homecareTimeList[i].time;
        for(var j=0;j<t.length;j++){
          if(t[j] == e){
              document.getElementById("hometime_"+[i]+"_"+index).style.backgroundColor ="#868383";
              document.getElementById("hometime_"+[i]+"_"+[j]).style.boxShadow ="0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)";  
          }
          else{
              document.getElementById("hometime_"+[i]+"_"+[j]).style.backgroundColor ="#e0e0e0";
              document.getElementById("hometime_"+[i]+"_"+[j]).style.boxShadow ="none";
          }
        }
      }
    }
    if( this.appNursePhysio == "dietician" ){
      this.addSessionFlag = false;
      this.addApptSession();
    }else{
      this.addSessionFlag = true;
    }
  }

  changeHospital(Hospital) {
    this.addSession(Hospital);
    this.available(Date_Formate(this.appointmentDate), this.currentTime);
    this.changeVisitSessionForTime(this.currentDate);
    for (var r = 0; r < this.hospitalList.length; r++) {
      if (this.hospitalList[r].hospital_id == Hospital) {
        this.hospitalId = this.hospitalList[r].hospital_id;
        this.hospitalLocation = this.hospitalList[r].Location;
        this.hospitalFee = this.hospitalList[r].fees;
        break;
      }
    }
  }

  setTimeAddSession(visitTime){
    if(visitTime != undefined){
      var table_visit_time,tokenorTime;
      var app_time = visitTime.toString().split(" ");
      tokenorTime = visitTime.toString();
      if (tokenorTime != "true") {
        if (tokenorTime != undefined) {
          table_visit_time = app_time[0] + ":00";
        }
      } else {
        table_visit_time = "1";
      }
    } else {
      table_visit_time = "1";
    }
    return table_visit_time;
  }

  addApptSession(){
    var flag:boolean = false;
    if(this.appNursePhysio == "dietician"){
      this.sessionList = [];
    }
    if(this.sessionList.length != 0){
      for(var i =0 ; i < this.sessionList.length; i++){
        if(this.visitTime == this.sessionList[i].visit_Time){
          this.toastr.error(Message_data.timeSlotsAlrdyBooked);
          flag = true;
        }else{
          flag = false;
        }
      }
    }
    if(flag == false){
      var apptdate;
      if(this.homecare == '0'){
        apptdate = Date_Formate(this.appointmentDate)
      }
      else{
        apptdate = Date_Formate(this.homecareAppDate)
      }
      this.sessionList.push({
        date: apptdate,
        session : this.visitSession,
        dispalyTime : this.visitTime,
        time : this.setTimeAddSession(this.visitTime),
        visit_Time: this.visitTime
      });
    }
    if( this.sessionList.length != 0 ){
        this.getRetriveData();
        this.saveFlag = true;
    }else{
        this.saveFlag = false;
    }
  }
  
  deleteSession(i){
    for(var j= 0; j < this.sessionList.length;j++){
      if( i == j){
        this.sessionList.splice(j,1);
      }
    }
    if( this.sessionList.length != 0 ){
      this.saveFlag = true;
    }else{
      this.saveFlag = false;
    }
  }

  getRetriveData() {
    if (this.homecare == '0') {
      for (var j = 0; j < this.hospitalList.length; j++) {
        this.hospitalId = this.hospitalList[j].hospital_id;
        this.hospitalLocation = this.hospitalList[j].Location;
        this.hospitalCity = this.hospitalList[j].City;
      }
      this.hospitalId = this.hospitalList[0].hospital_id;
    }
    if (this.homecare == '1' && this.appNursePhysio == 'nurse') {
      this.presDrugId = Client_Helper.getNurse_Prescription_list();
    }
    for (var i = 0; i < this.sessionList.length; i++) {
      this.appointmentDate = Date_Formate(this.sessionList[i].date);
      this.visitSession = this.sessionList[i].session;
      this.time = this.sessionList[i].time;
      this.homecareAppList.push({
        date: Date_Formate(this.sessionList[i].date),
        session: encrypt_decript.Encript(this.sessionList[i].session).toString(),
        app_time: this.sessionList[i].time,
        hospital: this.hospitalId,
      });
    }
    if (this.appNursePhysio == 'nurse') {
      this.nurse = this.physioDoctorList.name;
      this.presDrugId = Client_Helper.getmedialpres().pres_id;
    }else {
      this.physiotherapist = this.physioDoctorList.name;
    }
  }
  book() {
    var Provider = Client_Helper.getHomecare_nurse_physio();
    var occenc, addr1, addr2;
    if (this.clntMiddleName != null) {
      var clnt_middle_name = encrypt_decript.Encript(this.clntMiddleName).toString();
    }
    if (this.clntOccupation != undefined) {
      occenc = encrypt_decript.Encript(this.clntOccupation).toString();
    }
    if (this.clntAddr1 != undefined) {
      addr1 = encrypt_decript.Encript(this.clntAddr1).toString();
    }
    if (this.clntAddr2 != undefined) {
      addr2 = encrypt_decript.Encript(this.clntAddr2).toString();
    }
    if (this.clntPhoneNo != undefined) {
      this.clntPhoneNo = encrypt_decript.Encript(this.clntPhoneNo).toString();
    }
    if (this.clntSubrelID != null ) {
      var client_sub_rel_id = this.clntSubrelID;
    }
    var relid;
    if (this.relationId != undefined) {
      relid = this.relationId;
    } else {
      relid = "1"
    }
    if (this.appType == "New") {
      this.sendAppType = "1";
    } else {
      this.sendAppType = "2";
    }
    if(Client_Helper.getHomecare_nurse_physio() == "dietician"){
      this.sendAppointmentDetails = {
        prov_id: this.physioDoctorList.prov_id,
        provider: Provider,
        client_id: this.gservice.Client_login_detail_data.Client_Id,
        relation_id: relid,
        sub_relation_id: client_sub_rel_id,
        first_name: encrypt_decript.Encript(this.clntFirstName).toString(),
        middle_name: clnt_middle_name,
        last_name: encrypt_decript.Encript(this.clntLastName).toString(),
        gender: encrypt_decript.Encript(this.clntGender).toString(),
        age: this.clntAge,
        dob: this.clntDOB,
        mem_mobile: this.clntPhoneNo,
        symptom: this.clntSymptom,
        occupation: occenc,
        guardian: this.clntFatherName,
        rel_address1: addr1,
        rel_address2: addr2,
        rel_country: this.clntCountry,
        rel_state: this.clntState,
        rel_city: this.clntCity,
        rel_location: this.clntLocation,
        rel_zipcode: this.clntZipcode,
        app_type: this.sendAppType,
        session: encrypt_decript.Encript(this.visitSession).toString(),
        app_date: this.appointmentDate,
        app_time: this.time,
        hptl_clinic_id: this.hospitalId,
        location: this.hospitalLocation,
        city: this.hospitalCity,
        home_care: this.homecare,
        ref_by: this.referredDoctor,
        payment_type: this.payType,
      };
    }else{
      this.sendAppointmentDetails = {
        prov_id: this.physioDoctorList.prov_id,
        provider: Provider,
        client_id: this.gservice.Client_login_detail_data.Client_Id,
        relation_id: relid,
        sub_relation_id: client_sub_rel_id,
        first_name: encrypt_decript.Encript(this.clntFirstName).toString(),
        middle_name: clnt_middle_name,
        last_name: encrypt_decript.Encript(this.clntLastName).toString(),
        gender: encrypt_decript.Encript(this.clntGender).toString(),
        age: this.clntAge,
        dob: this.clntDOB,
        mem_mobile: this.clntPhoneNo,
        symptom: this.clntSymptom,
        occupation: occenc,
        guardian: this.clntFatherName,
        rel_address1: addr1,
        rel_address2: addr2,
        rel_country: this.clntCountry,
        rel_state: this.clntState,
        rel_city: this.clntCity,
        rel_location: this.clntLocation,
        rel_zipcode: this.clntZipcode,
        app_type: this.sendAppType,
        session: encrypt_decript.Encript(this.visitSession).toString(),
        date: this.appointmentDate,
        time: this.time,
        hptl_id: this.hospitalId,
        location: this.hospitalLocation,
        city: this.hospitalCity,
        home_care: this.homecare,
        home_app_date: this.homecareAppList,
        pres_drug_id: this.presDrugId,
        ref_by: this.referredDoctor,
        payment_type: this.payType,
      };
    }
    
    console.log("this.sendAppointmentDetails" + JSON.stringify(this.sendAppointmentDetails));
   this.bookPhysioAppointment();
  }

  bookPhysioAppointment() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "appointment/booking", (this.sendAppointmentDetails), { headers: headers }).subscribe(
      data => {
        var obj = data.json();
        if (obj.status == "1") {
          this.toastr.success(data.json().result);
          if (Client_Helper.getHomecare_nurse_physio() == "nurse") {
            this.messageservice.sendMessage("nurse");
          } else {
            this.messageservice.sendMessage("physio");
          }
        } else {
          this.toastr.success(data.json().result);
          if (Client_Helper.getHomecare_nurse_physio() == "nurse") {
            this.messageservice.sendMessage("nurse");
          } else {
            this.messageservice.sendMessage("physio");
          }          
        }
      },
      error => {
        this.toastr.error(error.json().result);
      });
  }
  // next(){
  //   if(this.homecare == '0'){
  //     Client_Helper.setPhysio_hospital_id(this.hospitalList);
  //   }
  //   Client_Helper.setAdd_Session_table(this.sessionList);
  //   if(this.appNursePhysio == "nurse"){
  //     this.messageservice.sendMessage("physio_appcreate_new");
  //   }
  //   if(this.appNursePhysio == "physio"){
  //     Client_Helper.setHomecare_nurse_physio(this.appNursePhysio);
  //     this.messageservice.sendMessage("physio_appointment_conform");
  //   }
  //   if(this.appNursePhysio == "dietician"){
  //     Client_Helper.setHomecare_nurse_physio(this.appNursePhysio);
  //     this.messageservice.sendMessage("physio_appointment_conform");
  //   }
  // }
}
