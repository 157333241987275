import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Helper_Class } from '../../helper_class';
import * as $ from 'jquery/dist/jquery.min.js';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from 'src/app/providers/common-data.service';
import { Time_Formate, ConvertTimeformat, Nurse_Day_Return } from '../../../assets/js/common';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';
@Component({
  selector: 'app-pharma-home',
  templateUrl: './pharma-home.component.html',
  styleUrls: ['./pharma-home.component.css']
})
export class PharmaHomeComponent implements OnInit {
  public proImg;
  public firstName;
  public middleName;
  public lastName;
  public gender;
  public dob;
  public mobile;
  public email;
  public regCode;
  public regYear;
  public regState;
  public workArray = [];
  public imgString = null;
  public startsFromArray = [];
  public endsAtArray = [];
  tab2Params: any;
  public pharmacyDist;
  public pharmacyLocation: string;
  public pharmacyState;
  public pharmacyCountry;
  public pharmacyZip: string;
  public pharmacyAddress1: string;
  public pharmacyAddress2: string;
  public pharmacyContactNumber: string;
  public pharmacyWebsite: string;
  public pharmacyDays: string;
  public locationListArray = [];
  public startFrom1: string;
  public startFrom2: string;
  public startFrom3: string;
  public endAt1: string;
  public endAt2: string;
  public endAt3: string;
  public hospitalArray = [];
  public locationArray = []
  public filterCityDescription;
  public filterStateDescription;
  public locationID: string;
  public countryID: string;
  public countryDesc: string;
  public locationFilterArray = [];
  public filteredLocArray = [];
  public filteredHospArray = [];
  public hospitalFilterArray = [];
  public cityURL: string;
  public stateURL: string;
  public countryURL: string;
  public cityList;
  public stateData;
  public countryData;
  public clntLocation;
  public pharmaName;
  public hospNurseID;
  public pharmaID;
  public LocationExits: boolean;
  public HomeDelivery: any;
  public StorePickup: any;
  public pharmacyStore: any;
  public storePickupDisabled: boolean;
  public cancelWork: any;
  public profileTab: any;
  public profileWorkTab: any;
  public profileWorkAdd: any;
  public sendURL = "usercontroller/updprov/";
  public mobileNumber = null;
  public emailID = null;
  public userInfo;
  public userID;
  dtOptions: DataTables.Settings = {};
  constructor(public gservice: CommonDataService, public http: Http, public routes: ActivatedRoute, public router: Router, public toastr: ToastrService) {
    this.startFrom1 = "06";
    this.startFrom2 = "00";
    this.startFrom3 = "AM";
    this.endAt1 = "08";
    this.endAt2 = "00";
    this.endAt3 = "AM";
    this.pharmaID = "0";
    this.storePickupDisabled = true;
    this.cityURL = ipaddress.getIp + "gen/city";
    this.stateURL = ipaddress.getIp + "gen/state";
    this.countryURL = ipaddress.getIp + "gen/count";
    this.cancelWork = ipaddress.getIp + "usercontroller/cancelwork";
    for (var i = 1; i <= 12; i++) {
      if (i < 10) {
        this.startsFromArray.push("0" + i);
      } else {
        this.startsFromArray.push(i);
      }
    }
    for (var j = 0; j <= 55; j += 5) {
      if (j < 10) {
        this.endsAtArray.push("0" + j);
      } else {
        this.endsAtArray.push(j);
      }
    }
  }
  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 10,
      "lengthMenu": [[5, 10, 25, 50, -1], [5, 10, 25, 50, "All"]],
      "columnDefs": [
        { "orderable": false, "targets": [0, 1, 2] },
      ],
      "language": {
        "search": "Filter"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12">><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"l><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    this.userInfo = Helper_Class.getInfo();
    this.userID = this.userInfo.user_id;
    this.infoProfile();
  }
  send_data() {
    this.profileInfo();
  }
  changeFile($event): void {
    this.readThis($event.target);
  }
  readThis(inputValue: any): void {
    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();
    myReader.onloadend = (e) => {
      var fileSize = Math.round(inputValue.files[0].size / 1024);
      if (fileSize >= 50) {
        this.toastr.error(Message_data.maxImgSize);
        $("#file").val('');
        this.imgString = null;
      }
      else {
        this.imgString = myReader.result;
        $('#profile_img').attr('src', myReader.result);
      }
    }
    myReader.readAsDataURL(inputValue.files[0]);
  }
  infoProfile() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'usercontroller/providerdetails',
      JSON.stringify({
        pharma: this.userID,
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          Helper_Class.setProfile_info(obj);
          if (obj.profile_image != null) {
            this.proImg = obj.profile_image
            try {
              var img = document.getElementById("profile_img") as HTMLImageElement;
              img.src = ipaddress.Ip_with_img_address + obj.profile_image;
            }
            catch (error) { }
            try {
              var img1 = document.getElementById("profile_img1") as HTMLImageElement;
              img1.src = ipaddress.Ip_with_img_address + obj.profile_image;
            }
            catch (error) { }
          }
          if (obj.first_name != null) {
            this.firstName = obj.first_name;
          }
          if (obj.middle_name != null) {
            this.middleName = obj.middle_name;
          }
          if (obj.last_name != null) {
            this.lastName = obj.last_name;
          }
          if (obj.gender != null) {
            this.gender = obj.gender;
          }
          if (obj.dob != null) {
            this.dob = obj.dob;
          }
          if (obj.mobile != null) {
            this.mobile = encrypt_decript.Decript(obj.mobile);
          }
          if (obj.email != null) {
            this.email = encrypt_decript.Decript(obj.email);
          }
          if (obj.registeration_code != null) {
            this.regCode = obj.registeration_code;
          }
          if (obj.registeration_year != null) {
            this.regYear = obj.registeration_year;
          }
          if (obj.registeration_state != null) {
            this.regState = obj.registeration_state;
          }
          this.updateLocation();
          this.profileInfo();
          this.working();
        });
  }
  profileInfo() {
    var img_path = this.proImg, upd_url = undefined;
    if (this.imgString != null) {
      img_path = this.imgString.split(',')[1];
      upd_url = "1";
    }
    if (this.email != undefined && this.mobile != undefined) {
      var send_array = null;
      var emailId = encrypt_decript.Encript(this.email).toString();
      var mobilenum = encrypt_decript.Encript(this.mobile).toString();
      send_array = {
        serv_provider_reg_id: this.userID,
        upd_url: upd_url,
        profile_image: img_path,
        email: emailId,
        mobile: mobilenum,
        provider: "pharma",
        language: "English",
      }
      Helper_Class.setprofile_array(send_array);
    }
  }
  working() {
    if (Helper_Class.getProfile_info() != null) {
      for (var i = 0; i < Helper_Class.getProfile_info().consultation.length; i++) {
        var token_name = undefined, time_dure = undefined;
        if (Helper_Class.getProfile_info().consultation[i].token != "0") {
          token_name = Helper_Class.getProfile_info().consultation[i].token;
          time_dure = "0";
        } else {
          token_name = "0";
          time_dure = Helper_Class.getProfile_info().consultation[i].app_duration;
        }
        this.workArray.push({
          hospital_id: Helper_Class.getProfile_info().consultation[i].hospital_clinic_id,
          name: Helper_Class.getProfile_info().consultation[i].name,
          location: Helper_Class.getProfile_info().consultation[i].location,
          city: Helper_Class.getProfile_info().consultation[i].city,
          state: Helper_Class.getProfile_info().consultation[i].state,
          country: Helper_Class.getProfile_info().consultation[i].country,
          zipcode: Helper_Class.getProfile_info().consultation[i].zipcode,
          address1: Helper_Class.getProfile_info().consultation[i].address1,
          address2: Helper_Class.getProfile_info().consultation[i].address2,
          mobilenum: Helper_Class.getProfile_info().consultation[i].telephone,
          website: Helper_Class.getProfile_info().consultation[i].hospital_website,
          day_desc: Nurse_Day_Return(Helper_Class.getProfile_info().consultation[i].day),
          day: Helper_Class.getProfile_info().consultation[i].day,
          available_from: Time_Formate(Helper_Class.getProfile_info().consultation[i].available_from),
          available_to: Time_Formate(Helper_Class.getProfile_info().consultation[i].available_to),
          home_delivery: Helper_Class.getProfile_info().consultation[i].home_delivery,
          store_pickup: Helper_Class.getProfile_info().consultation[i].store_pickup,
          store_pickup_validity_days: Helper_Class.getProfile_info().consultation[i].store_pickup_validity_days,
          consul_id: Helper_Class.getProfile_info().consultation[i].pharma_facility_id,
        })
      }
    }
  }
  StorePickupChanger() {
    if (this.StorePickup == true) {
      this.storePickupDisabled = false;
    }
    else {
      this.pharmacyStore = "";
      this.storePickupDisabled = true;
    }
  }
  clickWork() {
    if (this.LocationExits == true) {
      this.toastr.error(Message_data.locationExist);
    }
    else if (this.pharmacyLocation == "" ||
      this.pharmacyDist == undefined || this.pharmacyState == "" || this.pharmacyCountry == "" ||
      this.pharmacyZip == "" || this.pharmacyAddress1 == "" || this.pharmacyContactNumber == "" ||
      this.pharmacyDays == "") {
      this.toastr.error(Message_data.mandatory)
    }
    else if ((this.HomeDelivery == undefined && this.StorePickup == undefined) || (this.HomeDelivery == false && this.StorePickup == false)) {
      this.toastr.error(Message_data.homeORstorepickup);
    }
    else if (this.StorePickup != undefined && this.StorePickup == true && (this.pharmacyStore == undefined || this.pharmacyStore == "")) {
      this.toastr.error(Message_data.enterStorepickupdays);
    }
    else {
      if (this.HomeDelivery != undefined && this.HomeDelivery == true) {
        var homedel = "1";
      } else {
        homedel = "0";
      }
      if (this.StorePickup != undefined && this.StorePickup == true) {
        var storepick = "1";
      } else {
        storepick = undefined;
      }
      if (this.pharmacyStore != undefined && this.pharmacyStore != "") {
        var store_days_data = this.pharmacyStore;
      } else {
        store_days_data = undefined;
      }
      this.workArray.push({
        hospital_id: this.pharmaID,
        name: this.pharmaName,
        location: this.pharmacyLocation,
        city: this.filterCityDescription,
        state: this.filterStateDescription,
        country: this.pharmacyCountry,
        zipcode: this.pharmacyZip,
        address1: this.pharmacyAddress1,
        address2: this.pharmacyAddress2,
        mobilenum: this.pharmacyContactNumber,
        website: this.pharmacyWebsite,
        day_desc: this.pharmacyDays,
        day: this.pharmacyDays,
        home_delivery: homedel,
        store_pickup: storepick,
        store_pickup_validity_days: store_days_data,
        available_from: this.startFrom1 + ":" + this.startFrom2 + " " + this.startFrom3,
        available_to: this.endAt1 + ":" + this.endAt2 + " " + this.endAt3,
        consul_id: "0"
      })
      this.pharmacyLocation = "";
      this.pharmacyDist = "";
      this.pharmacyState = "";
      this.pharmacyCountry = "";
      this.pharmacyZip = "";
      this.pharmacyAddress1 = "",
        this.pharmacyAddress2 = "";
      this.pharmacyContactNumber = "";
      this.pharmacyWebsite = '';
      this.pharmacyDays = "";
      this.startFrom1 = "06";
      this.startFrom2 = "00";
      this.startFrom3 = "AM";
      this.endAt1 = "08";
      this.endAt2 = "00";
      this.endAt3 = "AM";
    }
  }
  sendWork() {
    this.updateLocation();
  }
  updateLocation() {
    var work_arry1 = [];
    var work_address_array = [];
    for (var i = 0; i < this.workArray.length; i++) {
      var consul_id_data = this.workArray[i].consul_id != "0" ? this.workArray[i].consul_id : undefined;
      work_arry1.push({
        hospital_id: this.workArray[i].hospital_id,
        available_from: ConvertTimeformat("", this.workArray[i].available_from),
        available_to: ConvertTimeformat("", this.workArray[i].available_to),
        day: this.workArray[i].day,
        day_session: "1",
        home_delivery: this.workArray[i].home_delivery,
        store_pickup: this.workArray[i].store_pickup,
        store_pickup_validity: this.workArray[i].store_pickup_validity_days,
        hosp_name: this.workArray[i].name,
        location: this.workArray[i].location,
        consultation_time_id: consul_id_data,
      });
    }
    Helper_Class.set_work_array(work_arry1);
    Helper_Class.set_work_add_array(work_address_array);
  }
  deleteWork(consul_id) {
    for (var i = 0; i < this.workArray.length; i++) {
      if (consul_id == this.workArray[i].consul_id) {
        this.workArray.splice(i, 1);
      }
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.cancelWork, JSON.stringify({
      cons_time_id: consul_id,
      flag: "pharma"
    }), { headers: headers }).subscribe(
      data => {
        if (data.json().key !== null && data.json().key == "1") {
          this.toastr.error(Message_data.deleteWorkLoca);
        } else {
          this.toastr.error(Message_data.unDeleteWorkLoca);
        }
      }, error => { });
  }
  changePharma(e) {
    this.pharmacyLocation = '';
    this.pharmacyDist = '';
    this.pharmacyState = '';
    this.pharmacyCountry = '';
    this.pharmacyZip = '';
    this.pharmacyAddress1 = '';
    this.pharmacyAddress2 = '';
    this.pharmacyContactNumber = '';
    this.pharmacyWebsite = '';
    this.hospitalArray = [];
    this.filteredLocArray = [];
    this.cityList = [];
    this.stateData = [];
    this.countryData = [];
    this.hospitalFilterArray = [];
    this.pharmaName = e.target.value.toString();
    if (this.pharmaName != undefined && this.pharmaName !== "" && this.pharmaName.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/pharma/',
        JSON.stringify({
          pharma_name: this.pharmaName
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            if (obj.pharmacies != null) {
              this.hospitalFilterArray = obj.pharmacies;
              for (var i = 0; i < this.hospitalFilterArray.length; i++) {
                this.hospitalArray.push(this.hospitalFilterArray[i].pharma_name);
              }
              this.filteredHospArray = this.hospitalArray.filter(function (this: any, el) {
                return el.toLowerCase().indexOf(this.pharma_name.toLowerCase()) > -1;
              }.bind(this));
            }
          },
          error => { });
    } else {
      this.filteredHospArray = [];
    }
  }
  selectHospital(data) {
    this.pharmaName = data;
    this.filteredHospArray = [];
    this.getLocationBasedonPharma();
  }
  getLocationBasedonPharma() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'gen/ploc/',
      JSON.stringify({
        pharma_name: this.pharmaName
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.pharma_locations != null) {
            this.locationListArray = obj.pharma_locations;
          }
        },
        error => { });
  }
  changeLocation() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'gen/paddr/',
      JSON.stringify({
        pharma_name: this.pharmaName,
        location: this.pharmacyLocation
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.pharma_id != null) {
            this.pharmaID = obj.pharma_id;
            this.pharmacyAddress1 = obj.address1;
            this.pharmacyAddress2 = obj.address2;
            this.pharmacyDist = obj.city;
            this.pharmacyState = obj.state;
            this.pharmacyCountry = obj.country;
            this.pharmacyContactNumber = obj.telephone;
            this.pharmacyWebsite = obj.website;
            this.pharmacyZip = obj.zipcode;
          }
        },
        error => { });
  }
  clickUpdate() {
    this.send_data();
    this.sendWork();
    this.profileTab = Helper_Class.getprofile_array();
    this.profileWorkTab = Helper_Class.get_work_array();
    this.profileWorkAdd = Helper_Class.get_work_add_array();
    for (var key in this.profileTab) {
      if (key == "upd_url" && this.profileTab[key] != undefined) {
        this.sendURL = "usercontroller/updprovweb/";
      } else if (key == "mobile" && this.profileTab[key] != undefined) {
        this.mobileNumber = this.profileTab[key];
      } else if (key == "email" && this.profileTab[key] != undefined) {
        this.emailID = this.profileTab[key];
      }
    }
    var x = encrypt_decript.Decript(this.emailID).toString();
    var re = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/igm;
    if (!re.test(x) || encrypt_decript.Decript(this.emailID).toString().trim() == "") {
      this.toastr.error(Message_data.validEmail);
    } else if (encrypt_decript.Decript(this.mobileNumber).toString().trim() == "" || encrypt_decript.Decript(this.mobileNumber).length != 10) {
      this.toastr.error(Message_data.validMobileNo);
    } else if (this.profileWorkTab == undefined || this.profileWorkTab.length == 0) {
      this.toastr.error(Message_data.verifyWrkLocation);
    } else if (this.mobileNumber != Helper_Class.getProfile_info().mobile) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'usercontroller/ismobileexist',
        JSON.stringify({
          mobile: this.mobileNumber,
          flag: "5"
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            if (obj.result == true) {
              this.toastr.error(Message_data.existMobileNo);
            } else {
              this.checkEmail();
            }
          },
          error => { });
    } else {
      this.checkEmail();
    }
  }
  checkEmail() {
    if (this.emailID != Helper_Class.getProfile_info().email) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'usercontroller/isemailexist',
        JSON.stringify({
          email: this.emailID,
          flag: "1"
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            if (obj.result == true) {
              this.toastr.error(Message_data.existEmailID);
            } else {
              this.updateFinal();
            }
          },
          error => { });
    } else {
      this.updateFinal();
    }
  }
  updateFinal() {
    var send_work = null;
    send_work = {
      consultation: this.profileWorkTab
    }
    var finalObj = $.extend(true, this.profileTab, send_work);
    var output = null;
    output = finalObj;
    var send_foot = null, getfoot_ass = null, qual;
    getfoot_ass = Helper_Class.get_work_add_array();
    if (getfoot_ass != undefined && getfoot_ass.length != 0) {
      send_foot = {
        address: getfoot_ass,
      }
      output = $.extend(true, finalObj, send_foot);
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + this.sendURL, output,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.key != null && obj.key == "1") {
            this.toastr.success(Message_data.profileUpdSuccess);
            this.router.navigateByUrl("/loginpage");
          }
          else {
            this.toastr.error(Message_data.profileUpdFailed);
          }
        },
        error => { });
  }
  jsonConcat(o1, o2) {
    for (var key in o2) {
      o1[key] = o2[key];
    }
    return o1;
  }
}
