import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { CommonDataService } from '../../providers/common-data.service';
import { Http, Headers } from '@angular/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { ToastrService } from 'ngx-toastr';
import { Date_Formate } from '../../../assets/js/common.js';
import { Router, ActivatedRoute } from '@angular/router';
import { ClientViewService } from '../clientview/client-view.service';
import { Client_Helper } from '../Client_helper';
import { ipaddress } from '../../ipaddress';
@Component({
  selector: 'app-physio-doctor-appointment-conform',
  templateUrl: './physio-doctor-appointment-conform.component.html',
  styleUrls: ['./physio-doctor-appointment-conform.component.css']
})
export class PhysioDoctorAppointmentConformComponent implements OnInit {
  public clntPhoneNo: string;
  public clntCountry: string;
  public clntFirstName: string;
  public clntMiddleName: string;
  public clntLastName: string;
  public clntAge: string;
  public clntGender: string;
  public clntDOB: string;
  public clntSubrelID: string;
  public clntBldGrp;
  public clntFatherName;
  public clntIncome;
  public clntOccupation;
  public clntSymptom;
  public physiotherapist;
  public nurse;
  public clientInfo;
  public sessionList;
  public clntRelationship;
  public physioDoctorList;
  public sendAppointmentDetails;
  public visitSession;
  public appointmentDate;
  public time;
  public homecare;
  public hospitalList;
  public hospitalId;
  public hospitalLocation;
  public hospitalCity;
  public referredDoctor;
  public clntAddr1;
  public clntAddr2;
  public clntState;
  public clntCity;
  public clntLocation;
  public clntZipcode;
  public relationId;
  public appType;
  public sendAppType;
  public homecareAppList = [];
  public appNursePhysio;
  public presDrugId;

  constructor(public messageservice: ClientViewService, public datepipe: DatePipe, public gservice: CommonDataService, public http: Http, public routes: ActivatedRoute, public router: Router, public toastr: ToastrService) {
    this.homecare = Client_Helper.getHomecare();
    
  }

  ngOnInit(): void {
    this.appNursePhysio = Client_Helper.getHomecare_nurse_physio();
    this.clientInfo = Client_Helper.getPhysioClientInfo();
    this.sessionList = Client_Helper.getAdd_Session_table();
    this.physioDoctorList = Client_Helper.getPhysio_Doctor_List();
    if (this.homecare == '0') {
      this.hospitalList = Client_Helper.getPhysio_hospital_id();
    }
    this.getRetriveData();
  }

  getRetriveData() {
    if (this.homecare == '0') {
      for (var j = 0; j < this.hospitalList.length; j++) {
        this.hospitalId = this.hospitalList[j].hospital_id;
        this.hospitalLocation = this.hospitalList[j].Location;
        this.hospitalCity = this.hospitalList[j].City;
      }
      this.hospitalId = this.hospitalList[0].hospital_id;
    }
    if (this.homecare == '1' && this.appNursePhysio == 'nurse') {
      this.presDrugId = Client_Helper.getNurse_Prescription_list();
    }
    for (var i = 0; i < this.sessionList.length; i++) {
      this.appointmentDate = Date_Formate(this.sessionList[i].date);
      this.visitSession = this.sessionList[i].session;
      this.time = this.sessionList[i].time;

      this.homecareAppList.push({
        date: Date_Formate(this.sessionList[i].date),
        session: encrypt_decript.Encript(this.sessionList[i].session).toString(),
        app_time: this.sessionList[i].time,
        hospital: this.hospitalId,
      });

    }
    for (var i = 0; i < this.clientInfo.length; i++) {
      this.clntRelationship = this.clientInfo[i].relationship;
      this.clntFirstName = this.clientInfo[i].clnt_fname;
      this.clntMiddleName = this.clientInfo[i].clnt_mname;
      this.clntLastName = this.clientInfo[i].clnt_lname;
      this.clntAge = this.clientInfo[i].clnt_age;
      this.clntDOB = this.clientInfo[i].clnt_dob;
      this.clntBldGrp = this.clientInfo[i].clnt_bldgrp;
      this.clntGender = this.clientInfo[i].clnt_gender;
      this.clntFatherName = this.clientInfo[i].clnt_fathername;
      this.clntIncome = this.clientInfo[i].clnt_income;
      this.clntOccupation = this.clientInfo[i].clnt_occupation;
      this.clntPhoneNo = this.clientInfo[i].clnt_phoneno;
      this.clntSymptom = this.clientInfo[i].clnt_symptom;
      this.referredDoctor = this.clientInfo[i].referredby;
      this.relationId = this.clientInfo[i].rel;
      this.clntSubrelID = this.clientInfo[i].clnt_subrel_id;
      this.appType = this.clientInfo[i].app_type;
    }
    if (this.appNursePhysio == 'nurse') {
      this.nurse = this.physioDoctorList.name;
      this.presDrugId = Client_Helper.getmedialpres().pres_id;
    }else {
      this.physiotherapist = this.physioDoctorList.name;
    }
  }

  book() {
    var Provider = Client_Helper.getHomecare_nurse_physio();
    var occenc, addr1, addr2;
    if (this.clntMiddleName != null) {
      var clnt_middle_name = encrypt_decript.Encript(this.clntMiddleName).toString();
    }
    if (this.clntOccupation != undefined) {
      occenc = encrypt_decript.Encript(this.clntOccupation).toString();
    }
    if (this.clntAddr1 != undefined) {
      addr1 = encrypt_decript.Encript(this.clntAddr1).toString();
    }
    if (this.clntAddr2 != undefined) {
      addr2 = encrypt_decript.Encript(this.clntAddr2).toString();
    }
    if (this.clntPhoneNo != undefined) {
      this.clntPhoneNo = encrypt_decript.Encript(this.clntPhoneNo).toString();
    }
    if (this.clntSubrelID != null) {
      var client_sub_rel_id = this.clntSubrelID;
    }
    var relid;
    if (this.relationId != undefined) {
      relid = this.relationId;
    } else {
      relid = "1"
    }
    if (this.appType == "New") {
      this.sendAppType = "1";
    } else {
      this.sendAppType = "2";
    }
    if(Client_Helper.getHomecare_nurse_physio() == "dietician"){
      this.sendAppointmentDetails = {
        prov_id: this.physioDoctorList.prov_id,
        provider: Provider,
        client_id: this.gservice.Client_login_detail_data.Client_Id,
        relation_id: relid,
        sub_relation_id: client_sub_rel_id,
        first_name: encrypt_decript.Encript(this.clntFirstName).toString(),
        middle_name: clnt_middle_name,
        last_name: encrypt_decript.Encript(this.clntLastName).toString(),
        gender: encrypt_decript.Encript(this.clntGender).toString(),
        age: this.clntAge,
        dob: this.clntDOB,
        mem_mobile: this.clntPhoneNo,
        symptom: this.clntSymptom,
        occupation: occenc,
        guardian: this.clntFatherName,
        rel_address1: addr1,
        rel_address2: addr2,
        rel_country: this.clntCountry,
        rel_state: this.clntState,
        rel_city: this.clntCity,
        rel_location: this.clntLocation,
        rel_zipcode: this.clntZipcode,
        app_type: this.sendAppType,
        session: encrypt_decript.Encript(this.visitSession).toString(),
        app_date: this.appointmentDate,
        app_time: this.time,
        hptl_clinic_id: this.hospitalId,
        location: this.hospitalLocation,
        city: this.hospitalCity,
        home_care: this.homecare,
        ref_by: this.referredDoctor,
      };
    }else{
      this.sendAppointmentDetails = {
        prov_id: this.physioDoctorList.prov_id,
        provider: Provider,
        client_id: this.gservice.Client_login_detail_data.Client_Id,
        relation_id: relid,
        sub_relation_id: client_sub_rel_id,
        first_name: encrypt_decript.Encript(this.clntFirstName).toString(),
        middle_name: clnt_middle_name,
        last_name: encrypt_decript.Encript(this.clntLastName).toString(),
        gender: encrypt_decript.Encript(this.clntGender).toString(),
        age: this.clntAge,
        dob: this.clntDOB,
        mem_mobile: this.clntPhoneNo,
        symptom: this.clntSymptom,
        occupation: occenc,
        guardian: this.clntFatherName,
        rel_address1: addr1,
        rel_address2: addr2,
        rel_country: this.clntCountry,
        rel_state: this.clntState,
        rel_city: this.clntCity,
        rel_location: this.clntLocation,
        rel_zipcode: this.clntZipcode,
        app_type: this.sendAppType,
        session: encrypt_decript.Encript(this.visitSession).toString(),
        date: this.appointmentDate,
        time: this.time,
        hptl_id: this.hospitalId,
        location: this.hospitalLocation,
        city: this.hospitalCity,
        home_care: this.homecare,
        home_app_date: this.homecareAppList,
        pres_drug_id: this.presDrugId,
        ref_by: this.referredDoctor,
      };
    }
    console.log("this.sendAppointmentDetails" + JSON.stringify(this.sendAppointmentDetails));
   // this.bookPhysioAppointment();
  }

  bookPhysioAppointment() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "appointment/booking", (this.sendAppointmentDetails), { headers: headers }).subscribe(
      data => {
        var obj = data.json();
        if (obj.status == "1") {
          this.toastr.success(data.json().result);
          if (Client_Helper.getHomecare_nurse_physio() == "nurse") {
            this.messageservice.sendMessage("nurse");
          } else {
            this.messageservice.sendMessage("physio");
          }
        } else {
          this.toastr.success(data.json().result);
          if (Client_Helper.getHomecare_nurse_physio() == "nurse") {
            this.messageservice.sendMessage("nurse");
          } else {
            this.messageservice.sendMessage("physio");
          }          
        }
      },
      error => {
        this.toastr.error(error.json().result);
      });
  }

  back() {
    if (Client_Helper.getHomecare_nurse_physio() == "nurse") {
      Client_Helper.setHomecare_nurse_physio(this.appNursePhysio);
      Client_Helper.setHomecare(this.homecare);
      this.messageservice.sendMessage("physio_doctor_detail");
    }
    if (Client_Helper.getHomecare_nurse_physio() == "physio") {
      Client_Helper.setHomecare_nurse_physio(this.appNursePhysio);
      Client_Helper.setHomecare(this.homecare);
      this.messageservice.sendMessage("physio_doctor_detail");
    }
  }
}
