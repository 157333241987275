import { Component, OnInit, HostListener, Input } from '@angular/core';
import { Helper_Class } from '../../helper_class';
import { Observable, observable, Subscription } from 'rxjs';
import { CasesheetService } from '../casesheet/casesheet.service';
import { DiabeticpopuphistoryComponent } from '../diabeticpopuphistory/diabeticpopuphistory.component';
import { MatDialog } from '@angular/material/dialog';
import { Doc_Helper } from '../Doc_Helper';

@Component({
  selector: 'app-footassessment',
  templateUrl: './footassessment.component.html',
  styleUrls: ['./footassessment.component.css']
})
export class FootassessmentComponent implements OnInit {
  public foot_value;
  public reqflag: boolean = false;
  public userinfo;
  public diabFootFlag = "min";
  public riskR;
  public riskL;
  public limbR;
  public limbL;
  public limb_ped_R;
  public limb_ped_L;
  public foodassess;
  public clinic_histhidden: boolean;
  subscription: Subscription;
  public footassessmentminflag: boolean = true;

  public limb_tibia_R: string;
  public limb_tibia_L: string;

  public limb_pedis_R: string;
  public limb_pedis_L: string;

  public limb_skin_discolor_R: string;
  public limb_skin_discolor_L: string;

  public limb_aching_R: string;
  public limb_aching_L: string;

  public limb_aching_Ri;
  public limb_aching_Li;

  public limb_numbness_R: string;
  public limb_numbness_L: string;

  public limb_numbness_Ri;
  public limb_numbness_Li;

  public limb_burning_R: string;
  public limb_burning_L: string;

  public limb_burning_Ri;
  public limb_burning_Li;

  public limb_tingling_R: string;
  public limb_tingling_L: string;

  public limb_tingling_Ri;
  public limb_tingling_Li;

  public deformity_R: string;
  public deformity_L: string;

  public sensory_vibration_R: string;
  public sensory_vibration_L: string;

  public sensory_10g_R: string;
  public sensory_10g_L: string;

  public pinprick_R: string;
  public pinprick_L: string;

  public ankle_reflex_R: string;
  public ankle_reflex_L: string;

  public wound_previous_R: string;
  public wound_previous_L: string;

  public wound_previous_Ri;
  public wound_previous_Li;

  public wound_current_fore_R: string;
  public wound_current_fore_L: string;

  public wound_current_mid_R: string;
  public wound_current_mid_L: string;

  public wound_current_hind_R: string;
  public wound_current_hind_L: string;

  public skin_dry_R: string;
  public skin_dry_L: string;

  public skin_plantar_R: string;
  public skin_plantar_L: string;

  public nail_ingrowing_R: string;
  public nail_ingrowing_L: string;

  public nail_thickened_R: string;
  public nail_thickened_L: string;

  public footwear_gfit_R: string;
  public footwear_gfit_L: string;

  public footwear_gshape_R: string;
  public footwear_gshape_L: string;

  public limb_amputation_R: string;
  public limb_amputation_L: string;

  public risk_level_R: string;
  public risk_level_L: string;

  public limb_skin_discolor_Ri;
  public limb_skin_discolor_Li;

  public wound_current_fore_Ri;
  public wound_current_fore_Li;

  public wound_current_mid_Li;
  public wound_current_mid_Ri;

  public wound_current_hind_Ri;
  public wound_current_hind_Li;

  public skin_dry_Ri;
  public skin_dry_Li;

  public skin_plantar_Ri;
  public skin_plantar_Li;

  public nail_thickened_Ri;
  public nail_thickened_Li;

  public footwear_gfit_Li = true;
  public footwear_gfit_Ri = true;

  public footwear_gshape_Ri = true;
  public footwear_gshape_Li = true;

  public nail_ingrowing_Ri;
  public nail_ingrowing_Li;

  public diab_case_hist_id;
  public life_style_id;
  public header_footer_flag: boolean;
  public foodasse = [];
  public posterior_label;
  public posterior_hidden: boolean;
  public dorsalis_label;
  public dorsalis_hidden: boolean;
  public skindis_label;
  public skindis_hidden: boolean;
  public aching_label;
  public aching_hidden: boolean;
  public numbeness_label;
  public numbeness_hidden: boolean;
  public buring_label;
  public buring_hidden: boolean;
  public tingling_label;
  public tingling_hidden: boolean;
  public footdefor_label;
  public footdefor_hidden: boolean;
  public vibration_label;
  public vibration_lable_1;
  public vibration_lable_2;
  public vibration_hidden: boolean;
  public monofilament_label;
  public monofilament_hidden: boolean;
  public pinprick_label;
  public pinprick_hidden: boolean;
  public ankle_reflexeslabel;
  public ankle_reflexeshidden: boolean;
  public foot_ulcerlabel;
  public foot_ulcerhidden: boolean;
  public currentfore_ulcerlabel;
  public currentfore_ulcerhidden: boolean;
  public currentmid_ulcerlabel;
  public currentmid_hidden: boolean;
  public currenthind_footlabel;
  public currenthind_hidden: boolean;
  public skindry_label;
  public skindry_hidden: boolean;
  public plantar_label;
  public plantar_hidden: boolean;
  public ignoring_label;
  public ignoring_hidden: boolean;
  public thickednail_label;
  public thickednail_hidden: boolean;
  public fitting_label;
  public fitting_hidden: boolean;
  public shape_label;
  public shape_hidden: boolean;
  public diabetes_relatedlabel;
  public diabetes_relatedhidden: boolean;
  public risk_label;
  public risk_hidden: boolean;
  public lowerlimb: boolean;
  public periperalhidden: boolean;
  public footwoundhidden: boolean;
  public skinnailhidden: boolean;
  public footwearhidden: boolean;
  public footheader: boolean;
  public getretrival;
  public client:boolean = false;
  public App_flow;

  constructor(public messagesevice: CasesheetService, public dialog: MatDialog) {
    this.limb_tibia_R = "None";
    this.limb_tibia_L = "None";
    this.limb_pedis_R = "None";
    this.limb_pedis_L = "None";
    this.limb_skin_discolor_R = "No";
    this.limb_skin_discolor_L = "No";
    this.limb_aching_R = "No";
    this.limb_aching_L = "No";
    this.limb_numbness_R = "No";
    this.limb_numbness_L = "No";
    this.limb_burning_R = "No";
    this.limb_burning_L = "No";
    this.limb_tingling_R = "No";
    this.limb_tingling_L = "No";
    this.deformity_R = "None";
    this.deformity_L = "None";
    this.sensory_vibration_R = "None";
    this.sensory_vibration_L = "None";
    this.sensory_10g_R = "None";
    this.sensory_10g_L = "None";
    this.pinprick_R = "None";
    this.pinprick_L = "None";
    this.ankle_reflex_R = "None";
    this.ankle_reflex_L = "None";
    this.wound_previous_R = "No";
    this.wound_previous_L = "No";
    this.wound_current_fore_R = "No";
    this.wound_current_fore_L = "No";
    this.wound_current_mid_R = "No";
    this.wound_current_mid_L = "No";
    this.wound_current_hind_R = "No";
    this.wound_current_hind_L = "No";
    this.skin_dry_R = "No";
    this.skin_dry_L = "No";
    this.skin_plantar_R = "No";
    this.skin_plantar_L = "No";
    this.nail_ingrowing_R = "No";
    this.nail_ingrowing_L = "No";
    this.nail_thickened_R = "No";
    this.nail_thickened_L = "No";
    this.footwear_gfit_R = "Good";
    this.footwear_gfit_L = "Good";
    this.footwear_gshape_R = "Good";
    this.footwear_gshape_L = "Good";
    this.limb_amputation_R = "None";
    this.limb_amputation_L = "None";
    this.risk_level_R = "None";
    this.risk_level_L = "None";
    this.posterior_hidden = true;
    this.dorsalis_hidden = true;
    this.skindis_hidden = true;
    this.aching_hidden = true;
    this.numbeness_hidden = true;
    this.buring_hidden = true;
    this.tingling_hidden = true;
    this.footdefor_hidden = true;
    this.vibration_hidden = true;
    this.monofilament_hidden = true;
    this.pinprick_hidden = true;
    this.ankle_reflexeshidden = true;
    this.foot_ulcerhidden = true;
    this.currentfore_ulcerhidden = true;
    this.currentmid_hidden = true;
    this.currenthind_hidden = true;
    this.skindry_hidden = true;
    this.plantar_hidden = true;
    this.ignoring_hidden = true;
    this.thickednail_hidden = true;
    this.fitting_hidden = true;
    this.shape_hidden = true;
    this.diabetes_relatedhidden = true;
    this.risk_hidden = true;
    this.lowerlimb = true;
    this.periperalhidden = true;
    this.footwoundhidden = true;
    this.skinnailhidden = true;
    this.footwearhidden = true;
  }

  ngOnInit() {
    this.subscription = this.messagesevice.getCasesheetMessage().subscribe(message => {
      if (message) {
        this.diabFootFlag = message;
      }
    });

    if(Doc_Helper.getAppFlow() == "client" ){
      this.client = true;
      this.App_flow = Doc_Helper.getAppFlow();
    }else{
      this.App_flow = "case_sheet";
      this.client = false;
    }
    
    this.userinfo = Helper_Class.getInfo();
    this.foodassess = Helper_Class.getHopitalfieldsRet();

    for (var i = 0; i < this.foodassess.length; i++) {
      if (this.foodassess[i].page_name == "Foot assessment") {
        if (this.foodassess[i].fields != undefined) {
          if (this.foodassess[i].fields != undefined) {
            for (var j = 0; j < this.foodassess[i].fields.length; j++) {
              if (this.foodassess[i].fields[j].field_name == "Posterior tibial artery") {
                this.posterior_label = this.foodassess[i].fields[j].field_name;
                this.posterior_hidden = false;
              }
              if (this.foodassess[i].fields[j].field_name == "Dorsalis pedis artery") {
                this.dorsalis_label = this.foodassess[i].fields[j].field_name;
                this.dorsalis_hidden = false;
              }
              if (this.foodassess[i].fields[j].field_name == "Skin discolouration") {
                this.skindis_label = this.foodassess[i].fields[j].field_name;
                this.skindis_hidden = false;
              }
              if (this.foodassess[i].fields[j].field_name == "Tender,aching limbs") {
                this.aching_label = this.foodassess[i].fields[j].field_name;
                this.aching_hidden = false;
              }
              if (this.foodassess[i].fields[j].field_name == "Numbness") {
                this.numbeness_label = this.foodassess[i].fields[j].field_name;
                this.numbeness_hidden = false;
              }
              if (this.foodassess[i].fields[j].field_name == "Burning") {
                this.buring_label = this.foodassess[i].fields[j].field_name;
                this.buring_hidden = false;
              }
              if (this.foodassess[i].fields[j].field_name == "Tingling") {
                this.tingling_label = this.foodassess[i].fields[j].field_name;
                this.tingling_hidden = false;
              }
              if (this.foodassess[i].fields[j].field_name == "Foot deformity") {
                this.footdefor_label = this.foodassess[i].fields[j].field_name;
                this.footdefor_hidden = false;
              }
              if (this.posterior_hidden == false || this.dorsalis_hidden == false || this.skindis_hidden == false || this.aching_hidden == false || this.numbeness_hidden == false || this.buring_hidden == false || this.tingling_hidden == false || this.footdefor_hidden == false) {
                this.lowerlimb = false;
              }
              if (this.foodassess[i].fields[j].field_name == "Vibration sensation(tuning fork 128HZ)") {
                this.vibration_label = this.foodassess[i].fields[j].field_name;
                var index = this.vibration_label.indexOf("(");  // Gets the first index where a space occours
                this.vibration_lable_1 = this.vibration_label.substr(0, index); // Gets the first part
                this.vibration_lable_2 = "("+this.vibration_label.substr(index + 1);
                this.vibration_hidden = false;
              }
              if (this.foodassess[i].fields[j].field_name == "10g Monofilament") {
                this.monofilament_label = this.foodassess[i].fields[j].field_name;
                this.monofilament_hidden = false;
              }
              if (this.foodassess[i].fields[j].field_name == "Pinprick sensation") {
                this.pinprick_label = this.foodassess[i].fields[j].field_name;
                this.pinprick_hidden = false;
              }
              if (this.foodassess[i].fields[j].field_name == "Ankle reflexes") {
                this.ankle_reflexeslabel = this.foodassess[i].fields[j].field_name;
                this.ankle_reflexeshidden = false;
              }
              if (this.vibration_hidden == false || this.monofilament_hidden == false || this.pinprick_hidden == false || this.ankle_reflexeshidden == false) {
                this.periperalhidden = false;
              }
              if (this.foodassess[i].fields[j].field_name == "Previous foot ulcer") {
                this.foot_ulcerlabel = this.foodassess[i].fields[j].field_name;
                this.foot_ulcerhidden = false;
              }
              if (this.foodassess[i].fields[j].field_name == "Current fore foot ulcer") {
                this.currentfore_ulcerlabel = this.foodassess[i].fields[j].field_name;
                this.currentfore_ulcerhidden = false;
              }
              if (this.foodassess[i].fields[j].field_name == "Current mid foot ulcer") {
                this.currentmid_ulcerlabel = this.foodassess[i].fields[j].field_name;
                this.currentmid_hidden = false;
              }
              if (this.foodassess[i].fields[j].field_name == "Current hind foot ulcer") {
                this.currenthind_footlabel = this.foodassess[i].fields[j].field_name;
                this.currenthind_hidden = false;
              }
              if (this.foot_ulcerhidden == false || this.currentfore_ulcerhidden == false || this.currentmid_hidden == false || this.currenthind_hidden == false) {
                this.footwoundhidden = false;
              }
              if (this.foodassess[i].fields[j].field_name == "Skin Dry") {
                this.skindry_label = this.foodassess[i].fields[j].field_name;
                this.skindry_hidden = false;
              }
              if (this.foodassess[i].fields[j].field_name == "Plantar callous") {
                this.plantar_label = this.foodassess[i].fields[j].field_name;
                this.plantar_hidden = false;
              }
              if (this.foodassess[i].fields[j].field_name == "Ingrowing nail(s)") {
                this.ignoring_label = this.foodassess[i].fields[j].field_name;
                this.ignoring_hidden = false;
              }
              if (this.foodassess[i].fields[j].field_name == "Thickened nail(s)") {
                this.thickednail_label = this.foodassess[i].fields[j].field_name;
                this.thickednail_hidden = false;
              }
              if (this.skindry_hidden == false || this.plantar_hidden == false || this.ignoring_hidden == false || this.thickednail_hidden == false) {
                this.skinnailhidden = false;
              }
              if (this.foodassess[i].fields[j].field_name == "Fitting") {
                this.fitting_label = this.foodassess[i].fields[j].field_name;
                this.fitting_hidden = false;
              }
              if (this.foodassess[i].fields[j].field_name == "Shape") {
                this.shape_label = this.foodassess[i].fields[j].field_name;
                this.shape_hidden = false;
              }
              if (this.foodassess[i].fields[j].field_name == "Diabetes related amputation") {
                this.diabetes_relatedlabel = this.foodassess[i].fields[j].field_name;
                this.diabetes_relatedhidden = false;
              }
              if (this.foodassess[i].fields[j].field_name == "Risk level") {
                this.risk_label = this.foodassess[i].fields[j].field_name;
                this.risk_hidden = false;
              }
              if (this.fitting_hidden == false || this.shape_hidden == false || this.diabetes_relatedhidden == false || this.risk_hidden == false) {
                this.footwearhidden = false;
              }
            }
          }
        }
      }
    }
    this.Get_Foot_assesment_retrivel()
  }

  foot_ass_valid() {
    if (this.limb_tibia_R == "None") {
      this.limbL = {
        "border-color": "#2ca8fe",
      }
      this.limb_ped_R = {
        "border-color": "#2ca8fe",
      }
      this.limb_ped_L = {
        "border-color": "#2ca8fe",
      }
      this.riskL = {
        "border-color": "#2ca8fe",
      }
      this.riskR = {
        "border-color": "#2ca8fe",
      }
    }
    else {
      this.limbL = {
        "border-color": "rgb(250, 40, 2)",
      }
      this.limb_ped_R = {
        "border-color": "rgb(250, 40, 2)",
      }
      this.limb_ped_L = {
        "border-color": "rgb(250, 40, 2)",
      }
      this.riskL = {
        "border-color": "rgb(250, 40, 2)",
      }
      this.riskR = {
        "border-color": "rgb(250, 40, 2)",
      }
    }
  }

  foot_ass_valid1() {
    //foot_ass_valid1() //2nd condition
    if (this.limb_tibia_L == "None") {

      this.limbR = {
        "border-color": "#2ca8fe"
      }
      this.limb_ped_R = {
        "border-color": "#2ca8fe"
      }
      this.limb_ped_L = {
        "border-color": "#2ca8fe",
      }
      this.riskL = {
        "border-color": "#2ca8fe",
      }
      this.riskR = {
        "border-color": "#2ca8fe",
      }
    }
    else {
      this.limbR = {
        "border-color": "rgb(250, 40, 2)"
      }
      this.limb_ped_R = {
        "border-color": "rgb(250, 40, 2)"
      }
      this.limb_ped_L = {
        "border-color": "rgb(250, 40, 2)"
      }
      this.riskL = {
        "border-color": "rgb(250, 40, 2)"
      }
      this.riskR = {
        "border-color": "rgb(250, 40, 2)"
      }
    }
  }

  foot_ass_valid2() {
    //foot_ass_valid1() //2nd condition
    if (this.limb_pedis_R == "None") {
      this.limbR = {
        "border-color": "#2ca8fe"
      }
      this.limbL = {
        "border-color": "#2ca8fe"
      }
      this.limb_ped_L = {
        "border-color": "#2ca8fe",
      }
      this.riskL = {
        "border-color": "#2ca8fe",
      }
      this.riskR = {
        "border-color": "#2ca8fe",
      }
    }else {
      this.limbR = {
        "border-color": "rgb(250, 40, 2)"
      }
      this.limbL = {
        "border-color": "rgb(250, 40, 2)"
      }
      this.limb_ped_L = {
        "border-color": "rgb(250, 40, 2)"
      }
      this.riskL = {
        "border-color": "rgb(250, 40, 2)"
      }
      this.riskR = {
        "border-color": "rgb(250, 40, 2)"
      }
    }
  }

  foot_ass_valid3() {
    //foot_ass_valid1() //2nd condition
    if (this.limb_pedis_L == "None") {
      this.limbR = {
        "border-color": "#2ca8fe"
      }
      this.limbL = {
        "border-color": "#2ca8fe"
      }
      this.limb_ped_R = {
        "border-color": "#2ca8fe",
      }
      this.riskL = {
        "border-color": "#2ca8fe",
      }
      this.riskR = {
        "border-color": "#2ca8fe",
      }
    }else {
      this.limbR = {
        "border-color": "rgb(250, 40, 2)"
      }
      this.limbL = {
        "border-color": "rgb(250, 40, 2)"
      }
      this.limb_ped_R = {
        "border-color": "rgb(250, 40, 2)"
      }
      this.riskL = {
        "border-color": "rgb(250, 40, 2)"
      }
      this.riskR = {
        "border-color": "rgb(250, 40, 2)"
      }
    }
  }

  foot_ass_valid4() {
    //foot_ass_valid1() //2nd condition
    if (this.risk_level_R == "None") {
      this.limbR = {
        "border-color": "#2ca8fe"
      }
      this.limbL = {
        "border-color": "#2ca8fe"
      }
      this.limb_ped_R = {
        "border-color": "#2ca8fe"
      }
      this.limb_ped_L = {
        "border-color": "#2ca8fe"
      }
      this.riskL = {
        "border-color": "#2ca8fe"
      }
    }else {
      this.limbR = {
        "border-color": "rgb(250, 40, 2)"
      }
      this.limbL = {
        "border-color": "rgb(250, 40, 2)"
      }
      this.limb_ped_R = {
        "border-color": "rgb(250, 40, 2)"
      }
      this.limb_ped_L = {
        "border-color": "rgb(250, 40, 2)"
      }
      this.riskL = {
        "border-color": "rgb(250, 40, 2)"
      }
    }
  }

  foot_ass_valid5() {
    //foot_ass_valid1() //2nd condition
    if (this.risk_level_L == "None") {
      this.limbR = {
        "border-color": "#2ca8fe"
      }
      this.limbL = {
        "border-color": "#2ca8fe"
      }
      this.limb_ped_R = {
        "border-color": "#2ca8fe"
      }
      this.limb_ped_L = {
        "border-color": "#2ca8fe"
      }
      this.riskR = {
        "border-color": "#2ca8fe"
      }
    }else {
      this.limbR = {
        "border-color": "rgb(250, 40, 2)"
      }
      this.limbL = {
        "border-color": "rgb(250, 40, 2)"
      }
      this.limb_ped_R = {
        "border-color": "rgb(250, 40, 2)"
      }
      this.limb_ped_L = {
        "border-color": "rgb(250, 40, 2)"
      }
      this.riskR = {
        "border-color": "rgb(250, 40, 2)"
      }
    }
  }

  @HostListener('window:click', ['$event'])
  onclick(event: any): void {
    this.send_data();

  }
  @HostListener('window:blur', ['$event'])
  onBlur(event: any): void {
    this.send_data();
  }

  send_data() {
    this.foodasse = [];
    if (this.limb_tibia_R != "None" || this.limb_tibia_L != "None" || this.limb_pedis_R != "None" || this.limb_pedis_L != "None" ||
      this.risk_level_R != "None" || this.risk_level_L != "None") {
      // right foot ass
      this.foodasse.push({
        foot_type: "right",
        limb_tibia: this.limb_tibia_R,
        limb_pedis: this.limb_pedis_R,
        limb_skin_discolor: this.limb_skin_discolor_R,
        limb_aching: this.limb_aching_R,
        limb_numbness: this.limb_numbness_R,
        limb_burning: this.limb_burning_R,
        limb_tingling: this.limb_tingling_R,
        sensory_vibration: this.sensory_vibration_R,
        sensory_10g: this.sensory_10g_R,
        pinprick: this.pinprick_R,
        ankle_reflex: this.ankle_reflex_R,
        wound_previous: this.wound_previous_R,
        wound_current_fore: this.wound_current_fore_R,
        wound_current_mid: this.wound_current_mid_R,
        wound_current_hind: this.wound_current_hind_R,
        deformity: this.deformity_R,
        skin_dry: this.skin_dry_R,
        skin_plantar: this.skin_plantar_R,
        nail_ingrowing: this.nail_ingrowing_R,
        nail_thickened: this.nail_thickened_R,
        footwear_gfit: this.footwear_gfit_R,
        footwear_gshape: this.footwear_gshape_R,
        limb_amputation: this.limb_amputation_R,
        risk_level: this.risk_level_R,
      });

      // left foot ass
      this.foodasse.push({
        foot_type: "left",
        limb_tibia: this.limb_tibia_L,
        limb_pedis: this.limb_pedis_L,
        limb_skin_discolor: this.limb_skin_discolor_L,
        limb_aching: this.limb_aching_L,
        limb_numbness: this.limb_numbness_L,
        limb_burning: this.limb_burning_L,
        limb_tingling: this.limb_tingling_L,
        sensory_vibration: this.sensory_vibration_L,
        sensory_10g: this.sensory_10g_L,
        pinprick: this.pinprick_L,
        ankle_reflex: this.ankle_reflex_L,
        wound_previous: this.wound_previous_L,
        wound_current_fore: this.wound_current_fore_L,
        wound_current_mid: this.wound_current_mid_L,
        wound_current_hind: this.wound_current_hind_L,
        deformity: this.deformity_L,
        skin_dry: this.skin_dry_L,
        skin_plantar: this.skin_plantar_L,
        nail_ingrowing: this.nail_ingrowing_L,
        nail_thickened: this.nail_thickened_L,
        footwear_gfit: this.footwear_gfit_L,
        footwear_gshape: this.footwear_gshape_L,
        limb_amputation: this.limb_amputation_L,
        risk_level: this.risk_level_L,
      });
    }
    Helper_Class.setdiabfoot(this.foodasse);
    console.log("foot assessment " +JSON.stringify(Helper_Class.getdiabfoot()))
  }

  Get_Foot_assesment_retrivel() {
    this.getretrival = Helper_Class.getRet();
    if (this.getretrival != undefined) {
      if (this.getretrival.case_hist_id != null) {
        this.diab_case_hist_id = this.getretrival.case_hist_id;
      }
      if (this.getretrival.life_style_id != null) {
        this.life_style_id = this.getretrival.life_style_id;
      }
      if (this.getretrival.foot_assessment != null) {
        for (var i = 0; i < this.getretrival.foot_assessment.length; i++) {
          if (this.getretrival.foot_assessment[i].foot_type == "right") {
            this.limb_tibia_R = this.getretrival.foot_assessment[i].limb_tibia;
            this.limb_pedis_R = this.getretrival.foot_assessment[i].limb_pedis;
            this.limb_skin_discolor_R = this.getretrival.foot_assessment[i].limb_skin_discolor;
            if (this.limb_skin_discolor_R == "yes" || this.limb_skin_discolor_R == "Yes") {
              this.limb_skin_discolor_Ri = true;
            }
            this.limb_aching_R = this.getretrival.foot_assessment[i].limb_aching;
            if (this.limb_aching_R == "yes" || this.limb_aching_R == "Yes") {
              this.limb_aching_Ri = true;
            }
            this.limb_numbness_R = this.getretrival.foot_assessment[i].limb_numbness;
            if (this.limb_numbness_R == "yes" || this.limb_numbness_R == "Yes") {
              this.limb_numbness_Ri = true;
            }

            this.limb_burning_R = this.getretrival.foot_assessment[i].limb_burning;
            if (this.limb_burning_R == "yes" || this.limb_burning_R == "Yes") {
              this.limb_burning_Ri = true;
            }
            this.limb_tingling_R = this.getretrival.foot_assessment[i].limb_tingling;
            if (this.limb_tingling_R == "yes" || this.limb_tingling_R == "Yes") {
              this.limb_tingling_Ri = true;
            }
            this.deformity_R = this.getretrival.foot_assessment[i].deformity;
            this.sensory_vibration_R = this.getretrival.foot_assessment[i].sensory_vibration;
            this.sensory_10g_R = this.getretrival.foot_assessment[i].sensory_10g;
            this.pinprick_R = this.getretrival.foot_assessment[i].pinprick;
            this.ankle_reflex_R = this.getretrival.foot_assessment[i].ankle_reflex;
            this.wound_previous_R = this.getretrival.foot_assessment[i].wound_previous;
            if (this.wound_previous_R == "yes" || this.wound_previous_R == "Yes") {
              this.wound_previous_Ri = true;
            }
            this.wound_current_fore_R = this.getretrival.foot_assessment[i].wound_current_fore;
            if (this.wound_current_fore_R == "yes" || this.wound_current_fore_R == "Yes") {
              this.wound_current_fore_Ri = true;
            }
            this.wound_current_mid_R = this.getretrival.foot_assessment[i].wound_current_mid;
            if (this.wound_current_mid_R == "yes" || this.wound_current_mid_R == "Yes") {
              this.wound_current_mid_Ri = true;
            }
            this.wound_current_hind_R = this.getretrival.foot_assessment[i].wound_current_hind;
            if (this.wound_current_hind_R == "yes" || this.wound_current_hind_R == "Yes") {
              this.wound_current_hind_Ri = true;
            }
            this.skin_dry_R = this.getretrival.foot_assessment[i].skin_dry;
            if (this.skin_dry_R == "yes" || this.skin_dry_R == "Yes") {
              this.skin_dry_Ri = true;
            }
            this.skin_plantar_R = this.getretrival.foot_assessment[i].skin_plantar;
            if (this.skin_plantar_R == "yes" || this.skin_plantar_R == "Yes") {
              this.skin_plantar_Ri = true;
            }
            this.nail_ingrowing_R = this.getretrival.foot_assessment[i].nail_ingrowing;
            if (this.nail_ingrowing_R == "yes" || this.nail_ingrowing_R == "Yes") {
              this.nail_ingrowing_Ri = true;
            }
            this.nail_thickened_R = this.getretrival.foot_assessment[i].nail_thickened;
            if (this.nail_thickened_R == "yes" || this.nail_thickened_R == "Yes") {
              this.nail_thickened_Ri = true;
            }
            this.footwear_gfit_R = this.getretrival.foot_assessment[i].footwear_gfit;
            if (this.footwear_gfit_R == "good" || this.footwear_gfit_R == "Good") {
              this.footwear_gfit_Ri = true;
            }else {
              this.footwear_gfit_Ri = false;
            }
            this.footwear_gshape_R = this.getretrival.foot_assessment[i].footwear_gshape;
            if (this.footwear_gshape_R == "good" || this.footwear_gshape_R == "Good") {
              this.footwear_gshape_Ri = true;
            }else {
              this.footwear_gshape_Ri = false;
            }
            this.limb_amputation_R = this.getretrival.foot_assessment[i].limb_amputation;
            this.risk_level_R = this.getretrival.foot_assessment[i].risk_level;
          }
          else if (this.getretrival.foot_assessment[i].foot_type == "left") {
            this.limb_tibia_L = this.getretrival.foot_assessment[i].limb_tibia;
            this.limb_pedis_L = this.getretrival.foot_assessment[i].limb_pedis;
            this.limb_skin_discolor_L = this.getretrival.foot_assessment[i].limb_skin_discolor;
            if (this.limb_skin_discolor_L == "yes" || this.limb_skin_discolor_L == "Yes") {
              this.limb_skin_discolor_Li = true;
            }
            this.limb_aching_L = this.getretrival.foot_assessment[i].limb_aching;
            if (this.limb_aching_L == "yes" || this.limb_aching_L == "Yes") {
              this.limb_aching_Li = true;
            }
            this.limb_numbness_L = this.getretrival.foot_assessment[i].limb_numbness;
            if (this.limb_numbness_L == "yes" || this.limb_numbness_L == "Yes") {
              this.limb_numbness_Li = true;
            }
            this.limb_burning_L = this.getretrival.foot_assessment[i].limb_burning;
            if (this.limb_burning_L == "yes" || this.limb_burning_L == "Yes") {
              this.limb_burning_Li = true;
            }
            this.limb_tingling_L = this.getretrival.foot_assessment[i].limb_tingling;
            if (this.limb_tingling_L == "yes" || this.limb_tingling_L == "Yes") {
              this.limb_tingling_Li = true;
            }
            this.deformity_L = this.getretrival.foot_assessment[i].deformity;
            this.sensory_vibration_L = this.getretrival.foot_assessment[i].sensory_vibration;
            this.sensory_10g_L = this.getretrival.foot_assessment[i].sensory_10g;
            this.pinprick_L = this.getretrival.foot_assessment[i].pinprick;
            this.ankle_reflex_L = this.getretrival.foot_assessment[i].ankle_reflex;
            this.wound_previous_L = this.getretrival.foot_assessment[i].wound_previous;
            if (this.wound_previous_L == "yes" || this.wound_previous_L == "Yes") {
              this.wound_previous_Li = true;
            }
            this.wound_current_fore_L = this.getretrival.foot_assessment[i].wound_current_fore;
            if (this.wound_current_fore_L == "yes" || this.wound_current_fore_L == "Yes") {
              this.wound_current_fore_Li = true;
            }
            this.wound_current_mid_L = this.getretrival.foot_assessment[i].wound_current_mid;

            if (this.wound_current_mid_L == "yes" || this.wound_current_mid_L == "Yes") {
              this.wound_current_mid_Li = true;
            }
            this.wound_current_hind_L = this.getretrival.foot_assessment[i].wound_current_hind;
            if (this.wound_current_hind_L == "yes" || this.wound_current_hind_L == "Yes") {
              this.wound_current_hind_Li = true;
            }
            this.skin_dry_L = this.getretrival.foot_assessment[i].skin_dry;
            if (this.skin_dry_L == "yes" || this.skin_dry_L == "Yes") {
              this.skin_dry_Li = true;
            }
            this.skin_plantar_L = this.getretrival.foot_assessment[i].skin_plantar;
            if (this.skin_plantar_L == "yes" || this.skin_plantar_L == "Yes") {
              this.skin_plantar_Li = true;
            }
            this.nail_ingrowing_L = this.getretrival.foot_assessment[i].nail_ingrowing;
            if (this.nail_ingrowing_L == "yes" || this.nail_ingrowing_L == "Yes") {
              this.nail_ingrowing_Li = true;
            }
            this.nail_thickened_L = this.getretrival.foot_assessment[i].nail_thickened;
            if (this.nail_thickened_L == "yes" || this.nail_thickened_L == "Yes") {
              this.nail_thickened_Li = true;
            }
            this.footwear_gfit_L = this.getretrival.foot_assessment[i].footwear_gfit;
            if (this.footwear_gfit_L == "good" || this.footwear_gfit_L == "Good") {
              this.footwear_gfit_Li = true;
            }else {
              this.footwear_gfit_Li = false;
            }
            this.footwear_gshape_L = this.getretrival.foot_assessment[i].footwear_gshape;
            if (this.footwear_gshape_L == "good" || this.footwear_gshape_L == "Good") {
              this.footwear_gshape_Li = true;
            }else {
              this.footwear_gshape_Li = false;
            }
            this.limb_amputation_L = this.getretrival.foot_assessment[i].limb_amputation;
            this.risk_level_L = this.getretrival.foot_assessment[i].risk_level;
          }
        }
      }
    }
  }

  history() {
    this.foot_value = "Foot_asses";
    const dialogRef = this.dialog.open(DiabeticpopuphistoryComponent, {
      width: '700px',
      height: '300px',
      data: {
        Diab_case_hist_id: this.diab_case_hist_id,
        Life_style_id: this.life_style_id,
        History: this.foot_value,
        AppFlow: this.App_flow,
      }
    });

    dialogRef.afterClosed().subscribe(result => {});
  }

  //toggle
  changeskindiscolor(e) {
    this.limb_skin_discolor_Ri = e;
    if (e == false) {
      this.limb_skin_discolor_R = "no";
      document.getElementById("limb_skin_discolor_Ri").style.backgroundColor = "red";
    }
    if (e == true) {
      this.limb_skin_discolor_R = "yes";
      document.getElementById("limb_skin_discolor_Ri").style.backgroundColor = "green";
    }
  }

  changeskindiscolorleft(e) {
    this.limb_skin_discolor_Li = e;
    if (e == false) {
      this.limb_skin_discolor_L = "no";
      document.getElementById("limb_skin_discolor_Li").style.backgroundColor = "red";
    }
    if (e == true) {
      this.limb_skin_discolor_L = "yes";
      document.getElementById("limb_skin_discolor_Li").style.backgroundColor = "green";
    }
  }
  //achoing

  changeachingright(e) {
    this.limb_aching_Ri = e;
    if (e == false) {
      this.limb_aching_R = "no";
      document.getElementById("limb_aching_Ri").style.backgroundColor = "red";
    }
    if (e == true) {
      this.limb_aching_R = "yes";
      document.getElementById("limb_aching_Ri").style.backgroundColor = "green";
    }
  }

  changeachingleft(e) {
    this.limb_aching_Li = e;
    if (e == false) {
      this.limb_aching_L = "no";
      document.getElementById("limb_aching_Li").style.backgroundColor = "red";
    }
    if (e == true) {
      this.limb_aching_L = "yes";
      document.getElementById("limb_aching_Li").style.backgroundColor = "green";
    }
  }
  // changenumbnessleft()

  changenumbnessright(e) {
    this.limb_numbness_Ri = e;
    if (e == false) {
      this.limb_numbness_R = "no";
      document.getElementById("limb_numbness_Ri").style.backgroundColor = "red";
    }
    if (e == true) {
      this.limb_numbness_R = "yes";
      document.getElementById("limb_numbness_Ri").style.backgroundColor = "green";
    }
  }

  changenumbnessleft(e) {
    this.limb_numbness_Li = e;
    if (e == false) {
      this.limb_numbness_L = "no";
      document.getElementById("limb_numbness_Li").style.backgroundColor = "red";
    }
    if (e == true) {
      this.limb_numbness_L = "yes";
      document.getElementById("limb_numbness_Li").style.backgroundColor = "green";
    }
  }

  //buring
  changeburningright(e) {
    this.limb_burning_Ri = e;
    if (e == false) {
      this.limb_burning_R = "no";
      document.getElementById("limb_burning_Ri").style.backgroundColor = "red";
    }
    if (e == true) {
      this.limb_burning_R = "yes";
      document.getElementById("limb_burning_Ri").style.backgroundColor = "green";
    }
  }

  changeburningleft(e) {
    this.limb_burning_Li = e;
    if (e == false) {
      this.limb_burning_L = "no";
      document.getElementById("limb_burning_Li").style.backgroundColor = "red";
    }
    if (e == true) {
      this.limb_burning_L = "yes";
      document.getElementById("limb_burning_Li").style.backgroundColor = "green";
    }
  }
 
  changetinglingright(e) { //changetinglingright
    this.limb_tingling_Ri = e;
    if (e == false) {
      this.limb_tingling_R = "no";
      document.getElementById("limb_tingling_Ri").style.backgroundColor = "red";
    }
    if (e == true) {
      this.limb_tingling_R = "yes";
      document.getElementById("limb_tingling_Ri").style.backgroundColor = "green";
    }
  }

  changetinglingleft(e) {
    this.limb_tingling_Li = e;
    if (e == false) {
      this.limb_tingling_L = "no";
      document.getElementById("limb_tingling_Li").style.backgroundColor = "red";
    }
    if (e == true) {
      this.limb_tingling_L = "yes";
      document.getElementById("limb_tingling_Li").style.backgroundColor = "green";
    }
  }
  
  changewoundright(e) {//wound
    this.wound_previous_Ri = e;
    if (e == false) {
      this.wound_previous_R = "no";
      document.getElementById("wound_previous_Ri").style.backgroundColor = "red";
    }
    if (e == true) {
      this.wound_previous_R = "yes";
      document.getElementById("wound_previous_Ri").style.backgroundColor = "green";
    }
  }

  changewoundleft(e) {
    this.wound_previous_Li = e;
    if (e == false) {
      this.wound_previous_L = "no";
      document.getElementById("wound_previous_Li").style.backgroundColor = "red";
    }
    if (e == true) {
      this.wound_previous_L = "yes";
      document.getElementById("wound_previous_Li").style.backgroundColor = "green";
    }
  }
  
  changecurrentmidright(e) {//mid
    this.wound_current_mid_Ri = e;
    if (e == false) {
      this.wound_current_mid_R = "no";
      document.getElementById("wound_current_mid_Ri").style.backgroundColor = "red";
    }
    if (e == true) {
      this.wound_current_mid_R = "yes";
      document.getElementById("wound_current_mid_Ri").style.backgroundColor = "green";
    }
  }

  changecurrentmidleft(e) {
    this.wound_current_mid_Li = e;
    if (e == false) {
      this.wound_current_mid_L = "no";
      document.getElementById("wound_current_mid_Li").style.backgroundColor = "red";
    }
    if (e == true) {
      this.wound_current_mid_L = "yes";
      document.getElementById("wound_current_mid_Li").style.backgroundColor = "green";
    }
  }

  changecurrenthintright(e) {// hint
    this.wound_current_hind_Ri = e;
    if (e == false) {
      this.wound_current_hind_R = "no";
      document.getElementById("wound_current_hind_Ri").style.backgroundColor = "red";
    }
    if (e == true) {
      this.wound_current_hind_R = "yes";
      document.getElementById("wound_current_hind_Ri").style.backgroundColor = "green";
    }
  }

  changecurrenthintleft(e) {
    this.wound_current_hind_Li = e;
    if (e == false) {
      this.wound_current_hind_L = "no";
      document.getElementById("wound_current_hind_Li").style.backgroundColor = "red";
    }
    if (e == true) {
      this.wound_current_hind_L = "yes";
      document.getElementById("wound_current_hind_Li").style.backgroundColor = "green";
    }
  }
  
  changeskindryright(e) {//skin dry
    this.skin_dry_Ri = e;
    if (e == false) {
      this.skin_dry_R = "no";
      document.getElementById("skin_dry_Ri").style.backgroundColor = "red";
    }
    if (e == true) {
      this.skin_dry_R = "yes";
      document.getElementById("skin_dry_Ri").style.backgroundColor = "green";
    }
  }

  changeskindryleft(e) {
    this.skin_dry_Li = e;
    if (e == false) {
      this.wound_current_mid_L = "no";
      document.getElementById("skin_dry_Li").style.backgroundColor = "red";
    }
    if (e == true) {
      this.wound_current_mid_L = "yes";
      document.getElementById("skin_dry_Li").style.backgroundColor = "green";
    }
  }

  changecurrentforeright(e) {//fore
    this.wound_current_fore_Ri = e;
    if (e == false) {
      this.wound_current_fore_R = "no";
      document.getElementById("wound_current_fore_Ri").style.backgroundColor = "red";
    }
    if (e == true) {
      this.wound_current_fore_R = "yes";
      document.getElementById("wound_current_fore_Ri").style.backgroundColor = "green";
    }
  }

  changecurrentforeleft(e) {
    this.wound_current_fore_Li = e;
    if (e == false) {
      this.wound_current_fore_L = "no";
      document.getElementById("wound_current_fore_Li").style.backgroundColor = "red";
    }
    if (e == true) {
      this.wound_current_fore_L = "yes";
      document.getElementById("wound_current_fore_Li").style.backgroundColor = "green";
    }
  }

  changeskinplantarright(e) {//plantar
    this.skin_plantar_Ri = e;
    if (e == false) {
      this.skin_plantar_R = "no";
      document.getElementById("skin_plantar_Ri").style.backgroundColor = "red";
    }
    if (e == true) {
      this.skin_plantar_R = "yes";
      document.getElementById("skin_plantar_Ri").style.backgroundColor = "green";
    }
  }

  changeskinplantarleft(e) {
    this.skin_plantar_Li = e;
    if (e == false) {
      this.skin_plantar_L = "no";
      document.getElementById("skin_plantar_Li").style.backgroundColor = "red";
    }
    if (e == true) {
      this.skin_plantar_L = "yes";
      document.getElementById("skin_plantar_Li").style.backgroundColor = "green";
    }
  }
  
  changenailingrowingright(e) {//nailgrowing
    this.nail_ingrowing_Ri = e;
    if (e == false) {
      this.nail_ingrowing_R = "no";
      document.getElementById("nail_ingrowing_Ri").style.backgroundColor = "red";
    }
    if (e == true) {
      this.nail_ingrowing_R = "yes";
      document.getElementById("nail_ingrowing_Ri").style.backgroundColor = "green";
    }
  }

  changenailingrowingleft(e) {
    this.nail_ingrowing_Li = e;
    if (e == false) {
      this.nail_ingrowing_L = "no";
      document.getElementById("nail_ingrowing_Li").style.backgroundColor = "red";
    }
    if (e == true) {
      this.nail_ingrowing_L = "yes";
      document.getElementById("nail_ingrowing_Li").style.backgroundColor = "green";
    }
  }
  
  changenailthickendright(e) {//nailthickened
    this.nail_thickened_Ri = e;
    if (e == false) {
      this.nail_thickened_R = "no";
      document.getElementById("nail_thickened_Ri").style.backgroundColor = "red";
    }
    if (e == true) {
      this.nail_thickened_R = "yes";
      document.getElementById("nail_thickened_Ri").style.backgroundColor = "green";
    }
  }

  changenailthickendleft(e) {
    this.nail_thickened_Li = e;
    if (e == false) {
      this.nail_thickened_L = "no";
      document.getElementById("nail_thickened_Li").style.backgroundColor = "red";
    }
    if (e == true) {
      this.nail_thickened_L = "yes";
      document.getElementById("nail_thickened_Li").style.backgroundColor = "green";
    }
  }

  changefootweargfitright(e) {//footweargfit
    this.footwear_gfit_Ri = e;
    if (e == false) {
      this.footwear_gfit_R = "Bad";
      document.getElementById("footwear_gfit_Ri").style.backgroundColor = "red";
    }
    if (e == true) {
      this.footwear_gfit_R = "Good";
      document.getElementById("footwear_gfit_Ri").style.backgroundColor = "green";
    }
  }

  changefootweargfitleft(e) {
    this.footwear_gfit_Li = e;
    if (e == false) {
      this.footwear_gfit_L = "Bad";
      document.getElementById("footwear_gfit_Li").style.backgroundColor = "red";
    }
    if (e == true) {
      this.footwear_gfit_L = "Good";
      document.getElementById("footwear_gfit_Li").style.backgroundColor = "green";
    }
  }

  changefootweargshaperight(e) {//footweargfitdhape
    this.footwear_gshape_Ri = e;
    if (e == false) {
      this.footwear_gshape_R = "Bad";
      document.getElementById("footwear_gshape_Ri").style.backgroundColor = "red";
    }
    if (e == true) {
      this.footwear_gshape_R = "Good";
      document.getElementById("footwear_gshape_Ri").style.backgroundColor = "green";
    }
  }

  changefootweargshapeleft(e) {
    this.footwear_gshape_Li = e;
    if (e == false) {
      this.footwear_gshape_L = "Bad";
      document.getElementById("footwear_gshape_Li").style.backgroundColor = "red";
    }
    if (e == true) {
      this.footwear_gshape_L = "Good";
      document.getElementById("footwear_gshape_Li").style.backgroundColor = "green";
    }
  }
}
