import { Component, OnInit, ElementRef, Input, ViewEncapsulation, OnDestroy, Inject } from '@angular/core';
// import { LoggingService } from '../logging.service';
// import { ipaddress.getIp, Ip_with_img_address, country_code } from '../../../assets/js/ipaddress.js';
import { Http, Headers } from '@angular/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Helper_Class } from '../../helper_class';
import { ToastrService } from 'ngx-toastr'
import { CommonDataService } from '../../providers/common-data.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Data, Route, Router } from '@angular/router';
import { Client_Helper } from '../Client_helper';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';

declare var $: any;

@Component({
  selector: 'app-otc-nonpres-page',
  templateUrl: './otc-nonpres-page.component.html',
  styleUrls: ['./otc-nonpres-page.component.css']
})
export class OtcNonpresPageComponent implements OnInit {

  @Input() id: string; parm: string;
  public element: any;
  public quantity: boolean;
  public eveningflag: boolean = true;

  public popup_heading: string;
  public medicine_supply_name: string;
  public table_medsup_name: string;

  public med_name: string;
  public dosage: string;
  public session: string;
  public mor_qty = 0;
  public aft_qty = 0;
  public eve_qty = 0;
  public ngt_qty = 0;
  public side_effect;
  public sess_url: string;
  public sessions;
  public sess_id = 1;

  public pres_datas = [];

  public add_view: boolean = false;

  public toastMessage: string;

  public retrive_table = [];

  public mor_flag: boolean = false;
  public aft_flag: boolean = true;
  public eve_flag: boolean = true;
  public ngt_flag: boolean = true;
  public mob_or_web_flag: boolean;
  public retrival_data_flag: boolean;
  public data1

  constructor(public comm_Data: CommonDataService, public el: ElementRef, public toastr: ToastrService, public router: Router, public http: Http, public dialog: MatDialog, public dialogRef: MatDialogRef<OtcNonpresPageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {// var data1=JSON.stringify(data)
      dialogRef.disableClose = true;
    Client_Helper.setherbalotcvalue(null);
    Client_Helper.setherbalotcvalue(data);
    this.pres_datas = [];
  }
  ngOnInit(): void {
    this.pres_datas = [];
    this.sess_url = ipaddress.getIp + "prescription/dintypes";
    this.data1 = Client_Helper.getherbalotcvalue();
    
    this.quantity = true;
    if (this.data1.type != null && this.data1.type == "OTC") {
      this.popup_heading = "Medicine details";
      this.medicine_supply_name = "Name of medicine";
      this.table_medsup_name = "Medicine name";
      this.pres_datas=[];

    } else {
      this.popup_heading = "Vitamins & supplements";
      this.medicine_supply_name = "Supplement";
      this.table_medsup_name = "Supplement name";
      this.pres_datas=[];
    }
    this.GetSessions();


  }
get_retrival(){
  this.data1 = Client_Helper.getherbalotcvalue();
   
    if (this.data1 == true)
    //  if (this.navParams.get('retrival') == true) 
    {// Retrieve 
      this.retrival_data_flag = true;

    } else {
      this.retrival_data_flag = false;
    }

    this.mob_or_web_flag = this.data1.flag

    if (this.data1.retrival == true) {// Retrieve 
      this.retrival_data_flag = true;

    } else {
      this.retrival_data_flag = false;
    }

    this.mob_or_web_flag = this.data1.flag

    if (this.data1.table_data != null) {
      this.retrive_table = this.data1.table_data
      this.pres_datas = [];
      
      for (var k = 0; k < this.retrive_table.length; k++) {
        var sess_value;
        var intake = this.retrive_table[k].morning + "-" + this.retrive_table[k].afternoon + "-" + this.retrive_table[k].evening +"-" + this.retrive_table[k].night;

        sess_value={
          drug_id: this.retrive_table[k].intake,
        }  
        
        var sess_value1 ;
        for (var d = 0; d < this.sessions.length; d++) { //array
         if (this.sessions[d].drug_id == this.retrive_table[k].intake) {
           sess_value1 =this.sessions[d].drug_name;
           break;
         }
        
        }
       
        var med_name_txt;
        if (this.data.type == "OTC") {
          med_name_txt = this.retrive_table[k].medicine_name;
        }
        else {
          med_name_txt = this.retrive_table[k].supplement;
        }
        this.pres_datas.push({
          type: this.data.type,
          med_name: med_name_txt,
          dosage: this.retrive_table[k].dosage,
          session: this.retrive_table[k].intake,
          intake_qty: intake,
          side_effect: this.retrive_table[k].side_effects,
        });
      }
    }

}
  // }
  GetSessions() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(this.sess_url).subscribe(
      data => {
        
        if (data.json().drug_intake != null && data.json().length != 0) {
          this.sessions = [];
          for (var d = 0; d < data.json().drug_intake.length; d++) {
            var sess = data.json().drug_intake[d];
            this.sessions.push({
              drug_id: sess.drug_int_id,
              drug_name: sess.description
            });
           
          }
        }
        this.get_retrival();
      }, error => {

      }
    )
  }

  sessionselection() {
    this.mor_qty = 0;
    this.aft_qty = 0;
    this.eve_qty = 0;
    this.ngt_qty = 0;
    if (this.sess_id == 1 || this.sess_id == 4 || this.sess_id == 5 || this.sess_id == 7) {
      // this.morning=false;
      this.mor_flag = false;

    } else {
      this.mor_flag = true;
      // this.quantity = false;
    }
    if (this.sess_id == 2 || this.sess_id == 4 || this.sess_id == 6 || this.sess_id == 7) {
      //this.aft=false;
      this.aft_flag = false;

    } else {
      this.aft_flag = true;
    }
    if (this.sess_id == 3 || this.sess_id == 5 || this.sess_id == 6 || this.sess_id == 7) {
      this.ngt_flag = false;

    } else {
      this.ngt_flag = true;
    }
    if (this.sess_id == 8) {
      this.mor_flag = false;
      this.ngt_flag = false;
      this.eveningflag = false;
      this.aft_flag = false;
      this.eve_flag = false;
    } else {
      this.eve_flag = true;
    }
    if (this.sess_id == 9 || this.sess_id == 10 || this.sess_id == 11 || this.sess_id == 12 || this.sess_id == 13 || this.sess_id == 17) {
      this.quantity = false;
    } else {
      this.quantity = true;
    }
    if (this.sess_id == 15 || this.sess_id == 16) {
      this.eveningflag = false;
      this.eve_flag = false;
      this.ngt_flag = false;
      this.aft_flag = false;
      this.mor_flag = false;
    } else {
    }
    if (this.sess_id == 12) {
      // this.quantity = true;
      this.eve_flag = false;
      this.ngt_flag = false;
      this.aft_flag = false;
      this.mor_flag = false;
    } else {
    }
  }

  AddDatas() {
    if (this.med_name == undefined || this.med_name == "") {
      this.toastr.error(Message_data.enterMedName);
      //this.presentToast();

    } else if (this.dosage == undefined || this.dosage == "") {
      this.toastr.error(Message_data.enterDosage);
      // this.presentToast();

    } else if (this.side_effect == undefined || this.side_effect == "") {
      this.toastr.error(Message_data.enterSideEffect);
      // this.presentToast();

    } else if (this.mor_qty == 0 && this.aft_qty == 0 && this.ngt_qty == 0 && this.eve_qty == 0) {
      this.toastr.error(Message_data.validQty);
      // this.presentToast();

    } else {
      this.add_view = true;
      var intake = this.mor_qty + "-" + this.aft_qty + "-" + this.eve_qty + "-" + this.ngt_qty ;
      var sess_value;

      for (var d = 0; d < this.sessions.length; d++) {
        if (this.sessions[d].drug_id == this.sess_id) {
          sess_value = this.sessions[d].drug_name;
          break;
        }
      }

      this.pres_datas.push({
        type: this.data.type,
        med_name: this.med_name,
        dosage: this.dosage,
        session: this.sess_id,
        intake_qty: intake,
        side_effect: this.side_effect
      });
      Client_Helper.setherbalotcvalue(this.pres_datas);

      this.med_name = "";
      this.dosage = "";
      this.sess_id = 1;
      this.mor_qty = 0;
      this.aft_qty = 0;
      this.eve_qty = 0;
      this.side_effect = "";
      
    }
  }

  DeleteMedication(index) {
    this.pres_datas.splice(index, 1);
  }

  otc_herbel_close() {
    this.dialogRef.close(this.pres_datas)
    // this.viewcontrl.dismiss(this.pres_datas);
  }


}