<div class="row">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white" >
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Appointment Details</h5>
          </div>
          <div class="headerButtons">
            <img class="saveimgbtn_inpatinfo" (click)="back()" src="../../../assets/ui_icons/buttons/Back_button.svg" />
            <img class="saveimgbtn_inpatinfo" (click)="book()" src="../../../assets/ui_icons/buttons/Book_now.svg" />
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row">
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="row">
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Appointment For &nbsp;&nbsp;
                  <input class="ipcss widthappt" matInput type="text" [(ngModel)]="clntRelationship" disabled />
                </mat-label>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel">First name
              <input class="ipcss widthappt" matInput type="text" [(ngModel)]="clntFirstName" disabled />
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel">Middle name
              <input class="ipcss widthappt" matInput type="text" [(ngModel)]="clntMiddleName" disabled />
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel">Last name
              <input class="ipcss widthappt" matInput type="text" [(ngModel)]="clntLastName" disabled />
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel"> Age (yrs)
              <input class="ipcss widthappt" matInput type="text" [(ngModel)]="clntAge" disabled />
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel">Gender
              <input class="ipcss widthappt" matInput type="text" [(ngModel)]="clntGender" disabled />
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel">Blood Group
              <input class="ipcss widthappt" matInput type="text" [(ngModel)]="clntBldGrp" disabled />
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel">Contact Number
              <input class="ipcss widthappt" matInput type="text" [(ngModel)]="clntPhoneNo" disabled />
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel">Symptom
              <input class="ipcss widthappt" matInput type="text" [(ngModel)]="clntSymptom" disabled />
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel">Father / Guardian Name
              <input class="ipcss widthappt" matInput type="text" [(ngModel)]="clntFatherName" disabled />
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel">Income
              <input class="ipcss widthappt" matInput type="text" [(ngModel)]="clntIncome" disabled />
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel">Occupation
              <input class="ipcss widthappt" matInput type="text" [(ngModel)]="clntOccupation" disabled />
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label *ngIf="appNursePhysio == 'physio'" class="matlabel">Physiotherapist Name
              <input class="ipcss widthappt" matInput type="text" [(ngModel)]="physiotherapist" disabled />
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label *ngIf="appNursePhysio == 'dietician'" class="matlabel">Dietician Name
              <input class="ipcss widthappt" matInput type="text" [(ngModel)]="physiotherapist" disabled />
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label *ngIf="appNursePhysio == 'nurse'" class="matlabel">Nurse Name
              <input class="ipcss widthappt" matInput type="text" [(ngModel)]="nurse" disabled />
            </mat-label>
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="table-responsive">
              <table id="card_tbl" class="table table-hover table-dynamic">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Session</th>
                    <th>Time</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let addsession of sessionList;let i=index">
                    <td>{{addsession.date}}</td>
                    <td>{{addsession.session}}</td>
                    <td>{{addsession.time}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>