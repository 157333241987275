import { AfterViewInit, Component, OnInit, ViewChild,  ElementRef, HostListener, Input } from '@angular/core';
import { Helper_nephro_casesheet } from '../../../assets/js/Helper_nephro_casesheet';
import { load_img, clear_click, brown_click, gray_click, green_click, blue_click, red_click, yellow_click, orange_click, black_click } from '../../../assets/js/canvas_img';
import { fromEvent } from 'rxjs';
import { switchMap, takeUntil, pairwise } from 'rxjs/operators';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from '../../helper_class';
import { formattedDate } from '../../../assets/js/common';
import { Observable, observable, Subscription } from 'rxjs';
import { CasesheetService } from '../casesheet/casesheet.service';
import { Platform } from '@angular/cdk/platform';
import { Doc_Helper } from '../Doc_Helper';
import { MatDialog } from '@angular/material/dialog';
import { IcdCodePopupComponent } from '../icd-code-popup/icd-code-popup.component';
import { ipaddress } from '../../ipaddress' ;import { Master_Helper } from '../Master_Helper';
import { masterCSData_Helper } from '../casesheet/masterCSData_Helper';
import { MasterCSHelperService } from '../casesheet/masterCSData.service';
import { Message_data } from 'src/assets/js/Message_data';

declare var $ : any; 

@Component({
  selector: 'app-nephrology-diagnosis',
  templateUrl: './nephrology-diagnosis.component.html',
  styleUrls: ['./nephrology-diagnosis.component.css']
})
export class NephrologyDiagnosisComponent implements OnInit {
  public nephDiagFlag ="min";
  subscription:Subscription;
  public minflag:boolean=true;

  public header_footer_flag: boolean;
  public ismobile : boolean;
  public image_path;
  public diag_disease;
  public diag_treat;
  public prog_txt;
  public Icd_Descript;
  public Icd_code;
  public diag_freq;
  public dys_type;
  public stage_type;
  public dys_type_hide: boolean;
  public dys_freq_hide: boolean;
  public stage_type_hide: boolean;
  public disease_array = [];
  public treat_array = [];
  public Nepro_Prognosis_List = [];
  public diabetic_type: boolean;
  public stage_hidden: boolean;
  public icd_hidden: boolean;
  public icddis_hidden: boolean;
  public Prognosis_data: boolean;
  public dialy_type_hidden: boolean;
  public prog_hidden:boolean;
  public remark_hidden:boolean;
  public remarks_hidden:boolean;
  public icdList = [];
  public remarks;
  public remarksList=[];
  public remarksList_flag:boolean;
  public prognosis_array_flag:boolean;

  public phyex_hidden: boolean;
  public yoga_hidden: boolean;
  public phydure_hidden: boolean;
  public phyfreq_hidden: boolean;
  public follow_hidden: boolean;
  public avoid_hidden: boolean;
  public next_hidden: boolean;
  public Hospital_hidden: boolean;
  public spl_hidden: boolean;
  public refer_hidden: boolean;
  public refer_doctor_hidden;

  public Physcial_exercise = [];
  public yoga_type_array = [];
  public send_yoga_type_array = [];
  public get_foodtype_arry = [];
  public filteredList1 = [];
  public hospital_txt=[];
  public filteredhospList=[];
  public hospital_filterlist=[];
  public yoga_type;
  public get_speclization_arry = [];
  public food_txt;
  public Foot_Item_avoidtxt;
  public get_selected_follow_item = null;
  public get_selected_avoid_item = null;
  public Food_type_avoid;
  public follow_avoid_search_items;
  public filteredList = [];
  public Foot_Item_txt;
  public food_type_name;
  public follow_food_search_items;
  public food_type;
  public dietfollowadd = [];
  public food_type_id;
  public diet_followtable:boolean=true;
  public Refered_doctor = [];
  public Refered_name;
  public refer_txt;
  public speclization_txt;
  public life_exer;
  public yoga_type_hide: boolean;
  public dietavoidwadd = [];
  public food_type_avoiddes;
  public food_typeavoid_id;
  public diet_avoidtable:boolean=true;
  public pres_pop_flag;
  public spl_type_array = [];
  public life_exerdure;
  public life_exe_freq;
  public next_data: string;
  public hosp_name;
  public hosp_id: string;
  public quan_lable: boolean;
  public Get_theyoga = [];
  public yoga_send_data = [];
  public yoga_div: boolean=true;
  public yoga_check = [];
  public yogadescription;
  public client:boolean = false;
  public userinfo;
  // cordova image 
  @ViewChild('myCanvas') canvas: any;
  @ViewChild('spaceshipimg') spaceshipAlly: ElementRef;
  // @ViewChild(Content) content: Content;
  @ViewChild('fixedContainer') fixedContainer: any;
  canvasElement: any;

  saveX: number;
  saveY: number;
  public ctx;
  public currentDate;

  selectedColor = '#000000';
  public diet_table:boolean;
  public prognosis_table:boolean;
  public notes_table:boolean;
  public image;

  public therapy_array = [];
  public therapy_check = [];
  public therapy_txt: string;
  public case_hist_id: string;
  public visit_freq: string;
  public therapyList=[];
  public therapy_table:boolean;
  public sittings: string;
  public therapyflag: string;
  public therapies;
  public therapydata;
  public therapy_label: string;
  public therapy_hidden:boolean;
  public Select = "Select";
  public visit_label;
  public visit_hidden:boolean;
  public sitting_label;
  public sitting_hidden: boolean;
  public booknowFlag:boolean = false; 
  public therapyhist_array = [];
  public physio_name;
  public client_reg_id;
  public relation_id;
  public sub_id;
  public doc_reg_id;
  public personalinfo;
  public isDraw:boolean = false;
  
  constructor(public dialog: MatDialog,public http: Http, public toastr: ToastrService,public messageservice:CasesheetService, 
    public plt:Platform,public masterCSdata:MasterCSHelperService) {
    this.dys_type_hide = true;
    this.dys_freq_hide = true;
    this.stage_type_hide = true;

    this.diabetic_type = true;
    this.stage_hidden = true;
    this.icd_hidden = true;
    this.icddis_hidden = true;
    this.Prognosis_data = true;
    this.dialy_type_hidden = true;
    this.phyex_hidden = true;
    this.yoga_hidden = true;
    this.phydure_hidden = true;
    this.phyfreq_hidden = true;
    this.follow_hidden = true;
    this.avoid_hidden = true;
    this.next_hidden = true;
    this.Hospital_hidden = true;
    this.spl_hidden = true;
    this.refer_hidden = true;
    this.refer_doctor_hidden = true;
    this.follow_hidden = true;
    this.avoid_hidden = true;
    this.changetab('prognosis');
   }

   @HostListener('window:click', ['$event'])
   onclick(event: any): void {
     this.send_data();
   }
 
   @HostListener('window:blur', ['$event'])
   onBlur(event: any): void {
     this.send_data();
   }

  send_data() {
    var send_prog_data = undefined;

    if (this.Nepro_Prognosis_List.length != 0) {
      for (var i = 0; i < this.Nepro_Prognosis_List.length; i++) {
        if (this.Nepro_Prognosis_List[i].add_new == "1") {
          send_prog_data = this.Nepro_Prognosis_List[i].Prog_Data;
          break;
        }
      }
    }

    var remarks_data = undefined;
    if (this.remarksList != undefined) {
      for (var i = 0; i < this.remarksList.length; i++) {
        if (this.remarksList[i].add_new == "1") {
          remarks_data = this.remarksList[i].remarks_data;
          break;
        }
      }
    }

    Helper_nephro_casesheet.image_path = this.image_path;
    console.log("njhdsg this.image_path"+JSON.stringify(this.image_path))
    console.log("njhdsg"+JSON.stringify(Helper_nephro_casesheet.image_path))
    Helper_nephro_casesheet.neph_prg_array = send_prog_data;

    if(this.diabetic_type==false) {
      Helper_nephro_casesheet.neph_disease = this.diag_disease;
    } else {
      Helper_nephro_casesheet.neph_disease = null;
    }
    var med_pres_id = undefined, diag_pres_id = undefined;
    if (Helper_Class.get_med_pres_id() != undefined && Helper_Class.get_med_pres_id() != null) {
      med_pres_id = Helper_Class.get_med_pres_id();
    }

    if (Helper_Class.get_diag_pres_id() != undefined && Helper_Class.get_diag_pres_id() != null) {
      diag_pres_id = Helper_Class.get_diag_pres_id();
    }

    Helper_nephro_casesheet.exercise_array = this.life_exer;
    if (this.yoga_div == false && this.send_yoga_type_array.length != 0) {
      Helper_nephro_casesheet.yoga_array = this.send_yoga_type_array;
    }
    else{
      Helper_nephro_casesheet.yoga_array = [];
    }
  
    Helper_nephro_casesheet.duration_drop = this.life_exerdure;
    Helper_nephro_casesheet.freq_drop = this.life_exe_freq;
    if (this.dietfollowadd.length != 0) {
      Helper_nephro_casesheet.diet_follow_array = this.dietfollowadd;
    }

    if (this.dietavoidwadd.length != 0) {
      Helper_nephro_casesheet.diet_avoid_array = this.dietavoidwadd;
    }

    var send_next_visit = undefined;
    if (this.next_data != undefined && this.next_data != "") {
      send_next_visit = this.next_data;
    }
      
    Helper_nephro_casesheet.next_vit = (send_next_visit);
    Helper_nephro_casesheet.hosp_name = this.hosp_name;
    Helper_nephro_casesheet.neph_ref = this.refer_txt;
    Helper_nephro_casesheet.spl_array = this.speclization_txt;
    Helper_nephro_casesheet.prescription = med_pres_id;
    Helper_nephro_casesheet.diag_prescription = diag_pres_id;
  
    Helper_nephro_casesheet.neph_icdcode = this.Icd_code;   
    Helper_nephro_casesheet.neph_icddes = this.Icd_Descript;
    Helper_nephro_casesheet.neph_treat = this.diag_treat;
    Helper_nephro_casesheet.neph_dia_freq = this.diag_freq;
    Helper_nephro_casesheet.neph_dia_type = this.dys_type;
    Helper_nephro_casesheet.neph_stage = this.stage_type;
    Helper_nephro_casesheet.neph_remarks = remarks_data;
    if( this.therapyList.length != 0 ){
      Helper_nephro_casesheet.physio_therapy = this.therapyList;
      Helper_nephro_casesheet.hptl_clinic_id = JSON.parse(this.userinfo.hospitals[0].hptl_clinic_id);
      Helper_nephro_casesheet.physio_id = this.physio_name;
      Helper_nephro_casesheet.client_reg_id = this.client_reg_id;
      Helper_nephro_casesheet.relation_id = this.relation_id;
      Helper_nephro_casesheet.sub_rel_id = this.sub_id;
      Helper_nephro_casesheet.created_by = this.doc_reg_id;
    } 
  }

  Exercise_change(e) {
    for(var i = 0; i < this.Physcial_exercise.length; i++){
      if(this.Physcial_exercise[i].exercise_id == e){
        var set = this.Physcial_exercise[i].description
      }
    }
    if (set == "Yoga") {
      this.yoga_div = false;
    } else {
      this.yoga_check = [];
      this.yoga_type_array = [];
      this.yoga_div = true;
    }
  }

  async ngOnInit(){
    this.subscription = this.messageservice.getCasesheetMessage().subscribe(message => {
      if (message) {
          this.nephDiagFlag = message;
          // if(message == "max"){
          //   this.loadCss();
          //   this.loadScript();
          // }
      }
    });
    
    Doc_Helper.setImageFlag(true);
    if (Doc_Helper.getAppFlow() == "client") {      
      this.client = true;
    }else{
      this.client = false;
    }

    this.userinfo = Helper_Class.getInfo();
      this.personalinfo = Doc_Helper.getClient_Info();
      this.client_reg_id = this.personalinfo.Client_id;
      this.relation_id = this.personalinfo.rel_id;
      this.sub_id = this.personalinfo.sub_id != "" ? this.personalinfo.sub_id :  undefined ;
      
    if(Helper_Class.getInpatientFlow() == "nurse"){
      this.doc_reg_id = this.personalinfo.user_id;
    }else{
      this.doc_reg_id = this.userinfo.user_id;
    }
    if(masterCSData_Helper.getMasterExercise() == undefined){
      await this.masterCSdata.getExercise();
      this.get_exercise();
    }else{
      this.get_exercise();
    }
    if(masterCSData_Helper.getMasterFoodType() == undefined){
      await this.masterCSdata.getFoodType();
      this.Get_food_type();
    }else{
      this.Get_food_type();
    }

    this.appGetdate();  
    this.getThetraphist();
    this.getTherapyHistory();
    // show/hide fields
    var getHopitalfieldsRet = Helper_Class.getHopitalfieldsRet();
    if (getHopitalfieldsRet != undefined) {
      for (var i = 0; i < getHopitalfieldsRet.length; i++) {
        if (getHopitalfieldsRet[i].page_name == "Diagnosis outcome") {
          if (getHopitalfieldsRet[i].fields != undefined) {
              for (var j = 0; j < getHopitalfieldsRet[i].fields.length; j++) {
                if (getHopitalfieldsRet[i].fields[j].field_name == "Diabetes type" || getHopitalfieldsRet[i].fields[j].field_name == "Dialysis type") {
                  this.diabetic_type = false;
                }
                if (getHopitalfieldsRet[i].fields[j].field_name == "Stage") {
                  this.stage_hidden = false;
                }

                if (getHopitalfieldsRet[i].fields[j].field_name == "ICD code") {
                  this.icd_hidden = false;
                }

                if (getHopitalfieldsRet[i].fields[j].field_name == "ICD description") {
                  this.icddis_hidden = false;
                }

                if (getHopitalfieldsRet[i].fields[j].field_name == "Prognosis") {
                  this.Prognosis_data = false;
                }

                if (getHopitalfieldsRet[i].fields[j].field_name == "Remarks") {
                  this.remarks_hidden = false;
                }

                if (getHopitalfieldsRet[i].fields[j].field_name == "Dialysis type") {
                  this.dialy_type_hidden = false;
                }
              }
            }
        }
      }
    }
    var treatment = Helper_Class.getHopitalfieldsRet();
    if (treatment != undefined) {
      for (var i = 0; i < treatment.length; i++) {
        if (treatment[i].page_name == "Treatment plan") {
          if (treatment[i].fields != undefined) {
            if (treatment[i].fields != undefined) {
              for (var j = 0; j < treatment[i].fields.length; j++) {
                if (treatment[i].fields[j].field_name == "Physical excercise") {
                  this.phyex_hidden = false;
                }

                if (treatment[i].fields[j].field_name == "Physical duration") {
                  this.phydure_hidden = false;
                }

                if (treatment[i].fields[j].field_name == "Physical frequency") {
                  this.phyfreq_hidden = false;
                }

                if (treatment[i].fields[j].field_name == "Diet to follow") {
                  this.follow_hidden = false;
                }

                if (treatment[i].fields[j].field_name == "Diet to avoid") {
                  this.avoid_hidden = false;
                }

                if (treatment[i].fields[j].field_name == "Next visit") {
                  this.next_hidden = false;
                }

                if (treatment[i].fields[j].field_name == "Hospital name") {
                  this.Hospital_hidden = false;
                }

                if (treatment[i].fields[j].field_name == "Speciality") {
                  this.spl_hidden = false;
                }

                if (treatment[i].fields[j].field_name == "Reffered to") {
                  this.refer_hidden = false;
                }
              }
            }
          }
        }
      }
    }
   

    this.diag_disease = "1";
    if (Helper_nephro_casesheet.nephro_get_ret.icd_code != undefined) {
      this.Icd_code = Helper_nephro_casesheet.nephro_get_ret.icd_code;
    }

    if (Helper_nephro_casesheet.nephro_get_ret.icd_desc != undefined) {
      this.Icd_Descript = Helper_nephro_casesheet.nephro_get_ret.icd_desc;
    }
  }

  getTherapyHistory() {//Get therapy history
    this.therapy_array = [];
    
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'physio/gtherapy',
    JSON.stringify({
      hptl_clinic_id:JSON.parse(this.userinfo.hospitals[0].hptl_clinic_id),
      status: "1",
    }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          
          if (obj.therapies != null) {
            for (var i = 0; i < obj.therapies.length; i++) {
              this.therapy_array.push({
                type: 'checkbox',
                value: obj.therapies[i].therapy_id,
                label: obj.therapies[i].therapy,
                checked: false
              });
            }
            
          }
        },
        error => {});
  }

  getThetraphist(){
    this.therapyhist_array = [];
    
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'search/npbyhs',
    JSON.stringify({
      hptl_clinic_id:JSON.parse(this.userinfo.hospitals[0].hptl_clinic_id),
      type: "physio"
    }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          var name;
          
          if (obj.physio_nurses != null) {
            for (var i = 0; i < obj.physio_nurses.length; i++) {
              if(obj.physio_nurses[i].middle_name != undefined){
                name = obj.physio_nurses[i].first_name + " " + obj.physio_nurses[i].middle_name +" "+ obj.physio_nurses[i].last_name;
              }else{
                if(obj.physio_nurses[i].last_name != undefined){
                  name = obj.physio_nurses[i].first_name + " " + obj.physio_nurses[i].last_name;
                }else{
                  name = obj.physio_nurses[i].first_name;
                }
              }
              // var first_name = obj.physio_nurses[i].first_name != undefined
              this.therapyhist_array.push({
                name: name,
                prov_id: obj.physio_nurses[i].prov_id,
              
              });
            }
            
          }
        },
        error => {
          
        }
      )
  }

  addTherapy(){
    if(this.therapy_txt == undefined || this.sittings == undefined || this.visit_freq == undefined){
      this.toastr.error(Message_data.mandatory);

    } else {
      var therapyname;
      for(var i=0;i<this.therapy_array.length;i++){
        if(this.therapy_array[i].value == this.therapy_txt){
          therapyname = this.therapy_array[i].label;
        }
      }
      this.therapyList.push({
        therapy_id:this.therapy_txt,
        therapy_name:therapyname,
        sittings: this.sittings,
        frequency: this.visit_freq
      });
      this.therapy_table = true;
    }
    this.therapyflag = "yes";
    this.send_data();
  }

  delete_therapy(data){
    for(var i=0; i<this.therapyList.length; i++){
      if(this.therapyList[i].therapy_id == data){
        this.therapyList.splice(i,1);
      }
    }
    if(this.therapyList.length ==0){
      // this.therapy_table = true;
      this.therapyflag = "no"
    }
    this.send_data();
  }

  updateTherapy(){
    var therapylistval=[];

    for (var j = 0; j < this.therapyList.length; j++) {
      this.therapyList[j].checked = true;
      therapylistval.push({
        type: 'checkbox',
        value: this.therapyList[j].therapy_id,
        label: this.therapyList[j].therapy_name,
        checked: true
      })
    }
    
              var headers = new Headers();
              headers.append('Content-Type', 'application/json');
              this.http.post(ipaddress.getIp.toString() + 'physio/scphist',
              JSON.stringify({
                case_hist_id: this.case_hist_id,
                therapies: therapylistval,
              }),
                { headers: headers })
                .subscribe(
                  response => {
                    var obj = JSON.parse(response["_body"]);
                    
                    this.toastr.error(obj.result);
                    
                  },
                  error => {
                    
                  }
                )
                this.send_data();
  }

  public loadScript() {
    let body = <HTMLDivElement> document.body;
    let script = document.createElement('script');
    script.innerHTML = '';
    script.src = 'assets/dist/neph_svg.js';
    script.async = true;
    script.defer = true;
    body.appendChild(script);
    //nephrology(this.image);
  }

  public loadCss(){
    $("<link/>", {
      rel: "stylesheet",
      type: "text/css",
      href: "assets/dist/all.css"
    }).appendTo("mdb-card");

  }

  appGetdate(){
    var obj = Master_Helper.getMasterCurrentDate();
    if (obj.current_date != null) {
      var get_date = obj.current_date.split('-');    
      this.currentDate = (obj.current_date);     
      this.next_data = this.currentDate;     
    }
  }

  Get_food_type() {
    var obj = masterCSData_Helper.getMasterFoodType();
    if(obj != undefined)
      this.get_foodtype_arry = obj.food_categories;
  }

  DietToFollowAdd() {
    var flag = true;

    if (this.food_type_name == undefined) {
      this.toastr.error(Message_data.sltFoodType);
      flag = false;
    }

    if (flag == true && this.Foot_Item_txt == undefined && this.Foot_Item_txt == undefined) {
      this.toastr.error(Message_data.sltFoodItem);
      flag = false;
    }

    for (var i = 0; i < this.dietfollowadd.length; i++) {
      if (this.dietfollowadd[i].food_item == this.Foot_Item_txt) {
        flag = false;
        break;
      }
    }

    for (var i = 0; i < this.get_foodtype_arry.length; i++) {
      if (this.food_type_name == this.get_foodtype_arry[i].description) {
        this.food_type = this.get_foodtype_arry[i].description;
        this.food_type_id = this.get_foodtype_arry[i].type_id;
      }
    }

    if (flag == true) {
      this.diet_followtable = false;
      this.dietfollowadd.push({
        food_type_name: this.food_type,
        food_item: this.Foot_Item_txt,
        food_type_id: this.food_type_id
      });
    }

    //Resets the form 
    this.food_type_name = undefined;
    this.Foot_Item_txt = '';
    this.send_data();
  }

  //Add diet to avoid
  DietToAvoidAdd() {
    var flag = true;

    if (this.Food_type_avoid == undefined) {
      this.toastr.error(Message_data.sltFoodType);
      flag = false;
    }

    if (flag == true && this.Foot_Item_avoidtxt == undefined && this.Foot_Item_avoidtxt == "") {
      this.toastr.error(Message_data.sltFoodItem);
      flag = false;
    }

    for (var i = 0; i < this.dietavoidwadd.length; i++) {
      if (this.dietavoidwadd[i].food_item_avoid == this.Foot_Item_avoidtxt) {
        flag = false;
        break;
      }
    }

    for (var i = 0; i < this.get_foodtype_arry.length; i++) {
      if (this.Food_type_avoid == this.get_foodtype_arry[i].description) {
        this.food_type_avoiddes = this.get_foodtype_arry[i].description;
        this.food_typeavoid_id = this.get_foodtype_arry[i].type_id;
      }
    }

    if (flag == true) {
      this.diet_avoidtable = false;
      this.dietavoidwadd.push({
        food_type_name: this.food_type_avoiddes,
        food_item: this.Foot_Item_avoidtxt,
        food_type_id: this.food_typeavoid_id
      });
    }

    //Resets the form 
    this.Food_type_avoid = undefined;
    this.Foot_Item_avoidtxt = '';
    this.send_data();
  }

  DeleteDietFollow(food, item) {
    for (var i = 0; i < this.dietfollowadd.length; i++) {
      if (this.dietfollowadd[i].food_type_name == food && this.dietfollowadd[i].food_item == item) {
        this.dietfollowadd.splice(i, 1);
        break;
      }
    }
    this.diet_followtable = this.dietfollowadd.length != 0 ? false : true;
    this.send_data();
  }

  //Diet to avoid delete
  DeleteDietAvoid(foodavoid, itemavoid) {
    for (var i = 0; i < this.dietavoidwadd.length; i++) {
      if (this.dietavoidwadd[i].diet_food_type_name == foodavoid && this.dietavoidwadd[i].food_item == itemavoid) {
        this.dietavoidwadd.splice(i, 1);
        break;
      }
    }
    this.diet_avoidtable = this.dietavoidwadd.length != 0 ? false : true;
    this.send_data();
  }

  // get exercise
  async get_exercise() {
    var obj = masterCSData_Helper.getMasterExercise();
    
    if (obj != undefined) {
      this.Physcial_exercise = obj.exercises;
      if(masterCSData_Helper.getMasterYogaType() == undefined){
        await this.masterCSdata.getYogaType();
        this.get_yoga_type();
      }else{
        this.get_yoga_type();
      }    
    }
  }

  // get yoga data
  async get_yoga_type() {
    var obj = masterCSData_Helper.getMasterYogaType();
    if(obj != undefined){
      this.Get_theyoga = obj.yogasanas;
      if (obj.yogasanas != null) {
        for (var i = 0; i < obj.yogasanas.length; i++) {
          this.yoga_type_array.push({
            type: 'checkbox',
            // multiple:true,
            value: obj.yogasanas[i].yoga_asana_id,
            label: obj.yogasanas[i].description,
            checked: false
          });
        }
      }
    }
    this.send_yoga_type_array = [];
    if (Helper_nephro_casesheet.nephro_get_ret != undefined && Helper_nephro_casesheet.nephro_get_ret.yogasanas != null && Helper_nephro_casesheet.nephro_get_ret.yogasanas.length != 0) {
      for (var j = 0; j < Helper_nephro_casesheet.nephro_get_ret.yogasanas.length; j++) {
        this.send_yoga_type_array.push(Helper_nephro_casesheet.nephro_get_ret.yogasanas[j].yoga_asana_id);
      }
      this.yoga_send_data = this.yoga_check;
      this.yoga_click(this.send_yoga_type_array);
      this.yoga_div = this.send_yoga_type_array.length != 0 ? false : true;
    }
    if(masterCSData_Helper.getMasterSpecilizationType() == undefined){
      await this.masterCSdata.getSpecilizationType();
      this.getSpecilizationType()    
    }else{
      this.getSpecilizationType();
    }
  }
  
  //Get specilization
  async getSpecilizationType() {
    this.get_speclization_arry = [];
    var obj = masterCSData_Helper.getMasterSpecilizationType();
    if(obj != undefined){
      for (var i = 0; i < obj.specialization.length; i++) {
        if (obj.specialization[i].spl_name != "-") {
          this.get_speclization_arry.push({
            spl_id: obj.specialization[i].spl_id,
            spl_name: obj.specialization[i].spl_name
          });
        }
      }
    }
    if(masterCSData_Helper.getMasterNephroDisease() == undefined){
      await this.masterCSdata.getNephroDisease();
      this.Get_Diag_disease();
    }else{
      this.Get_Diag_disease();
    }
  }

  yoga_click1(valuecard,event) {
    if (event == true) {
      this.yoga_send_data.push(valuecard);
    } else {
      for (var i = 0; this.yoga_send_data.length; i++) {
        if (this.yoga_send_data[i] == valuecard) {
          this.yoga_send_data.splice(i, 1);
          break;
        }
      }
    }
    this.yoga_click(this.yoga_send_data);
  }

  yoga_click(selected) {
    function StringBuilder(this:any,value) {
      this.strings = new Array("");
      this.append(value);
    }

    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }

    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }

    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }

    var selected_data = new StringBuilder("");
    this.yoga_type = "";
    this.send_yoga_type_array = selected;

    if (this.send_yoga_type_array.length != 0) {
      var n = 0;
      for (var j = 0; j < this.send_yoga_type_array.length; j++) {
        for (var i = 0; i < this.yoga_type_array.length; i++) {
          if (this.send_yoga_type_array[j] == this.yoga_type_array[i].value) {
            n = n + 1;
            if (n > 1) {
              selected_data.append(",");
              selected_data.append("\n");
            }
            selected_data.append(this.yoga_type_array[i].label);
          }
        }
      }
      this.yoga_type = selected_data.toString();
    }
    this.send_data();
  }

  Get_referal(e) {
    this.Refered_doctor = [];
    this.Refered_name = [];
    this.refer_txt=e.target.value.toString();
    
    if (this.refer_txt != "" && this.refer_txt.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'search/docbyhns/',
        JSON.stringify({
          hospital_id:this.hosp_id,
          spl_name: this.speclization_txt,
          doc_name: this.refer_txt
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();

            this.Refered_name = obj.doctors;
            if (obj.doctors != null) {
              for (var i = 0; i < this.Refered_name.length; i++) {
                var docname;
                if(this.Refered_name[i].middle_name != undefined){
                  docname=this.Refered_name[i].first_name + " " + this.Refered_name[i].middle_name + " " + this.Refered_name[i].last_name;
                } else {
                  docname=this.Refered_name[i].first_name + " " + this.Refered_name[i].last_name;
                }
                this.Refered_doctor.push(docname);
              }
            }
            this.Refered_doctor = this.Refered_doctor.filter(function (this:any,el) {
              return el.toLowerCase().indexOf(this.refer_txt.toLowerCase()) > -1;
            }.bind(this));
            if (this.Refered_doctor.length == 0) {
              this.toastr.error(Message_data.noDocFound);
            }
          },
          error => {
              this.toastr.error(Message_data.defaultErr);
          }
        )
    }
    else {
      this.Refered_doctor = [];
    }
  }

  select_hospital(data) {
    this.hosp_name = data;
    for(var i=0;i<this.hospital_filterlist.length;i++){
      if(this.hospital_filterlist[i].hosp_name == data){
        this.hosp_id=this.hospital_filterlist[i].hosp_id;
      }
    }
    this.filteredhospList = [];
    this.refer_txt=null;
  }
  
  Select_refer_doctor(item) {
    this.refer_txt = item;
    this.Refered_doctor = [];
  }

  split(date) {
    var get_date = date.split('-');
    var date_mod = get_date[2] + "-" + get_date[1] + "-" + get_date[0];
    return date_mod;
  }

  // ngAfterViewInit(): void {
  //   let isDrawing = false;
  //   if (Helper_nephro_casesheet.nephro_get_ret != undefined) {
  //       let _window = (<any>window);
  //        this.canvasElement = this.canvas.nativeElement;
  //         this.canvasElement.width = this.canvas.width + '';
  //         this.canvasElement.height = this.canvas.height;
  //         this.ctx = this.canvasElement.getContext('2d');
  //         this.ctx.lineWidth = 3;
  //         this.ctx.lineCap = 'round';
  //         this.ctx.strokeStyle = this.selectedColor;

  //         if (Helper_nephro_casesheet.nephro_get_ret.image_path != undefined) {
  //           this.loadDefalutImage(ipaddress.Ip_with_img_address + Helper_nephro_casesheet.nephro_get_ret.image_path);
  //         } else {
  //           this.loadDefalutImage("../../../assets/img/nephro_icons/kidney.jpg");
  //         }
  //       this.captureEvents(this.canvasElement);
  //   } else {
  //     this.loadDefalutImage("../../../assets/img/nephro_icons/kidney.jpg");
  //     // Set the Canvas Element and its size
  //     this.canvasElement = this.canvas.nativeElement;
  //     this.canvasElement.width = this.canvas.width + '';
  //     this.canvasElement.height = this.canvas.height;
  //     this.ctx = this.canvasElement.getContext('2d');
  //     this.ctx.lineWidth = 3;
  //     this.ctx.lineCap = 'round';
  //     this.ctx.strokeStyle = this.selectedColor;
  //     this.captureEvents(this.canvasElement);
  //   }
  // }

  private captureEvents(canvasEl: HTMLCanvasElement){
    fromEvent(this.canvasElement, 'mousedown')
      .pipe(
        switchMap((e) => {
          return fromEvent(canvasEl, 'mousemove')
            .pipe(
              takeUntil(fromEvent(canvasEl, 'mouseup')),
              takeUntil(fromEvent(canvasEl, 'mouseleave')),
              pairwise()
            )
        })
      )
      .subscribe((res: any) => {
        const rect = canvasEl.getBoundingClientRect();
        // previous and current position with the offset
        const prevPos = {
          x: res[0].clientX - rect.left,
          y: res[0].clientY - rect.top
        };
  
        const currentPos = {
          x: res[1].clientX - rect.left,
          y: res[1].clientY - rect.top
        };
  
        // this method we'll implement soon to do the actual drawing
        this.drawOnCanvas(prevPos, currentPos);
      });
  }

  private drawOnCanvas(prevPos: { x: number, y: number }, currentPos: { x: number, y: number }) {
    if (!this.ctx) { return; }
    this.ctx.strokeStyle = this.selectedColor;
    this.ctx.beginPath();
    if (prevPos) {
      this.ctx.moveTo(prevPos.x, prevPos.y); // from
      this.ctx.lineTo(currentPos.x, currentPos.y);
      this.ctx.stroke();
    }
  }

  // cordova image
  loadDefalutImage(img_url) {
    var img = new Image();
    img.setAttribute('crossOrigin', 'anonymous');

    img.src = img_url;
    img.width = 200;
    img.height = 235;
    let context: CanvasRenderingContext2D = this.canvas.nativeElement.getContext("2d");

    this.canvas.height = img.height;
    this.canvas.width = img.width;
    // context.drawImage(img, 0, 0);
    setTimeout(() => {
      context.drawImage(img, 0, 0);
    },300);
  }
  
  // get diagnosis disease
  async Get_Diag_disease() {
    this.disease_array = [];
    var obj = masterCSData_Helper.getMasterNephroDisease();
    if (obj != undefined) {
      if (obj.neprho_diseases != null) {
      this.disease_array = obj.neprho_diseases;
      }
    }
    if(masterCSData_Helper.getMasterNephroTreatment() == undefined){
      await this.masterCSdata.getNephroTreatment();
      this.Get_Diag_treatment();
    }else{
      this.Get_Diag_treatment();
    }
  }

   // get diagnosis treatments
  Get_Diag_treatment() {
    this.treat_array = [];
    var obj = masterCSData_Helper.getMasterNephroTreatment();
    if (obj != undefined) {
      if (obj.treatment_types != null) {
        this.treat_array = obj.treatment_types;
      }
    }
    this.diagnosis_display();
  }

  diagnosis_display(){     
    if (Helper_nephro_casesheet.nephro_get_ret != undefined) {
      if (Helper_nephro_casesheet.nephro_get_ret.RECOM_EXEC_ID != undefined) {
        this.life_exer = Helper_nephro_casesheet.nephro_get_ret.RECOM_EXEC_ID;
      }
      this.yoga_check = [];
      if (Helper_nephro_casesheet.nephro_get_ret.yogasanas != null && Helper_nephro_casesheet.nephro_get_ret.yogasanas != undefined) {
        for (var i = 0; i < this.Get_theyoga.length; i++) {
          for (var j = 0; j < Helper_nephro_casesheet.nephro_get_ret.yogasanas.length; j++) {
            if (Helper_nephro_casesheet.nephro_get_ret.yogasanas[j].yoga_asana_id == this.Get_theyoga[i].yoga_asana_id) {
              this.yoga_check.push(this.Get_theyoga[i].yoga_asana_id);
              this.yogadescription=this.Get_theyoga[i].description;
              this.yoga_send_data = this.yoga_check;
            }
          }
        }

        if (this.yoga_check.length != 0) {
          this.yoga_div = false;

        } else {
          this.yoga_div = true;
        }
      }
      
      if (Helper_nephro_casesheet.nephro_get_ret.ref_hospital != undefined) {
        this.hosp_name = Helper_nephro_casesheet.nephro_get_ret.ref_hospital;
      }
      if (Helper_nephro_casesheet.nephro_get_ret.ref_speciality != undefined) {
        this.speclization_txt = Helper_nephro_casesheet.nephro_get_ret.ref_speciality;
      }
      if (Helper_nephro_casesheet.nephro_get_ret.ref_hospital != undefined) {
        this.refer_txt = Helper_nephro_casesheet.nephro_get_ret.refered_to;
      }

      if (Helper_nephro_casesheet.nephro_get_ret.RECOM_EXEC_DUR != undefined) {
        this.life_exerdure = Helper_nephro_casesheet.nephro_get_ret.RECOM_EXEC_DUR;
      }

      if (Helper_nephro_casesheet.nephro_get_ret.RECOM_EXEC_FREQ != undefined) {
        this.life_exe_freq = Helper_nephro_casesheet.nephro_get_ret.RECOM_EXEC_FREQ;
      }

      if (Helper_nephro_casesheet.nephro_get_ret.next_visit != undefined) {
        this.next_data = Helper_nephro_casesheet.nephro_get_ret.next_visit;
        // var next_date = Helper_nephro_casesheet.nephro_get_ret.next_visit.split("-");
        // this.next_data = next_date[2]+"-"+next_date[1]+"-"+next_date[0];
      }

      //Diet follw
      this.dietfollowadd = [];

      if (Helper_nephro_casesheet.nephro_get_ret.diet_follow != null && Helper_nephro_casesheet.nephro_get_ret.diet_follow.length != 0) {
        this.diet_followtable = false;
        for (var i = 0; i < Helper_nephro_casesheet.nephro_get_ret.diet_follow.length; i++) {
          this.dietfollowadd.push({
            food_type_name: Helper_nephro_casesheet.nephro_get_ret.diet_follow[i].food_type_name,
            food_item: Helper_nephro_casesheet.nephro_get_ret.diet_follow[i].food_item,
            food_type_id: Helper_nephro_casesheet.nephro_get_ret.diet_follow[i].food_type_id
          });
        }
      }

      //Diet avoid
      this.dietavoidwadd = [];
      if (Helper_nephro_casesheet.nephro_get_ret.diet_avoid != null && Helper_nephro_casesheet.nephro_get_ret.diet_avoid.length != 0) {
        this.diet_avoidtable = false;
        for (var i = 0; i < Helper_nephro_casesheet.nephro_get_ret.diet_avoid.length; i++) {
          this.dietavoidwadd.push({
            food_type_name: Helper_nephro_casesheet.nephro_get_ret.diet_avoid[i].food_type_name,
            food_item: Helper_nephro_casesheet.nephro_get_ret.diet_avoid[i].food_item,
            food_type_id: Helper_nephro_casesheet.nephro_get_ret.diet_avoid[i].food_type_id
          });
        }
      }

      if (Helper_nephro_casesheet.nephro_get_ret.disease != undefined) {
        this.diag_disease = Helper_nephro_casesheet.nephro_get_ret.disease;
      }

      if (Helper_nephro_casesheet.nephro_get_ret.stage_type != undefined) {
        if(this.stage_hidden == false){
          this.stage_type_hide=false;
          this.stage_type = Helper_nephro_casesheet.nephro_get_ret.stage_type;
        }
      }

      if (Helper_nephro_casesheet.nephro_get_ret.icd_code != undefined) {
        this.Icd_code = Helper_nephro_casesheet.nephro_get_ret.icd_code;
      }

      if (Helper_nephro_casesheet.nephro_get_ret.icd_desc != undefined) {
        this.Icd_Descript = Helper_nephro_casesheet.nephro_get_ret.icd_desc;
      }

      if (Helper_nephro_casesheet.nephro_get_ret.treatment != undefined) {
        this.diag_treat = Helper_nephro_casesheet.nephro_get_ret.treatment;
        this.stage_hidden=false;
      }

      if (Helper_nephro_casesheet.nephro_get_ret.dialysis_type != undefined) {
        this.dys_type = Helper_nephro_casesheet.nephro_get_ret.dialysis_type;
        this.dys_type_hide=false;
      }

      if (Helper_nephro_casesheet.nephro_get_ret.dialysis_freq != undefined) {
        this.diag_freq = Helper_nephro_casesheet.nephro_get_ret.dialysis_freq;
        this.dys_freq_hide=false;
      }
      
      this.Nepro_Prognosis_List = [];
      if (Helper_nephro_casesheet.nephro_get_ret.prognosis != null) {
        for (var j = 0; j < Helper_nephro_casesheet.nephro_get_ret.prognosis.length; j++) {
          var get_data = Helper_nephro_casesheet.nephro_get_ret.prognosis[j].date.split('-');
          var doc_name;
          if(Helper_nephro_casesheet.nephro_get_ret.prognosis[j].middle_name != undefined && Helper_nephro_casesheet.nephro_get_ret.prognosis[j].middle_name != null){
            doc_name = Helper_nephro_casesheet.nephro_get_ret.prognosis[j].first_name + " " + Helper_nephro_casesheet.nephro_get_ret.prognosis[j].middle_name + " " + Helper_nephro_casesheet.nephro_get_ret.prognosis[j].last_name;
          
          } else {
            doc_name = Helper_nephro_casesheet.nephro_get_ret.prognosis[j].first_name + " " + Helper_nephro_casesheet.nephro_get_ret.prognosis[j].last_name;
          }

          this.Nepro_Prognosis_List.push({
            Prog_Data: Helper_nephro_casesheet.nephro_get_ret.prognosis[j].prognosis,
            Doct_Name: doc_name,
            Date: get_data[2] + '-' + get_data[1] + '-' + get_data[0],
            add_new: "0"
          })
        }
        this.prognosis_array_flag=true;
      }
    }

    this.remarksList = [];
    if (Helper_nephro_casesheet.nephro_get_ret.remarks != undefined) {
      for (var j = 0; j < Helper_nephro_casesheet.nephro_get_ret.remarks.length; j++) {
        var get_data = Helper_nephro_casesheet.nephro_get_ret.remarks[j].date.split('-');
        var doc_name;
        
        this.remarksList.push({
          remarks_data: Helper_nephro_casesheet.nephro_get_ret.remarks[j].remarks,
          Date: get_data[2] + '-' + get_data[1] + '-' + get_data[0],
          add_new: "0"
        })
      }
      this.remarksList_flag=true;
    }  

    if (Helper_nephro_casesheet.nephro_get_ret.therapies != undefined && Helper_nephro_casesheet.nephro_get_ret.therapies != null) {
      for (var j = 0; j < Helper_nephro_casesheet.nephro_get_ret.therapies.length; j++) {
        this.therapyList.push({
          therapy_id:Helper_nephro_casesheet.nephro_get_ret.therapies[j].therapy_id,
          therapy_name:Helper_nephro_casesheet.nephro_get_ret.therapies[j].therapy_name,
          sittings: Helper_nephro_casesheet.nephro_get_ret.therapies[j].sittings,
          frequency: Helper_nephro_casesheet.nephro_get_ret.therapies[j].frequency
        });
      }

      this.therapy_table = true;
    }
  }

  addRemarks() {
    var today = new Date().toJSON().slice(0, 10).replace(/-/g, '/');
    var get_date = today.split('/');
    var doc_name;
      if(this.userinfo.middle_name){
        doc_name= this.userinfo.first_name + " " + this.userinfo.middle_name + " " + this.userinfo.last_name
      } else {
        doc_name= this.userinfo.first_name + " " + this.userinfo.last_name
      }
      if (this.remarksList.length == 0 && (this.remarks == undefined && this.remarks == "")) {
        this.remarksList_flag = false;
      }else {
        this.remarksList_flag = true;
      }

    if(this.remarks != undefined){
      this.remarksList.push({
        remarks_data: this.remarks,
        Doct_Name: doc_name,
        Date: get_date[2]+"-"+get_date[1]+"-"+get_date[0],
        add_new: "1"
      });
    } else {
      if (this.remarks == undefined) {
          this.toastr.error(Message_data.enterRmk);
        }
    }
    this.remark_hidden = true;
    this.remarks = undefined;
    this.send_data();
  }

  edit_prognosis(data){
    if(data.add_new=="1"){
      this.prog_txt = data.Prog_Data;
      for (var i=0;i<this.Nepro_Prognosis_List.length;i++){
        if(this.Nepro_Prognosis_List[i].add_new == data.add_new){
          this.Nepro_Prognosis_List.splice(i);
        }
      }
      this.prog_hidden=false;
    }
    this.send_data();
  }

  select_follow_item(item) {
    this.Foot_Item_txt = item;
    this.filteredList = [];
  }

  select_avoid_item(item) {
    this.Foot_Item_avoidtxt = item;
    this.filteredList1 = [];
  }

  edit_remarks(data){
    if(data.add_new=="1"){
      this.remarks = data.remarks_data;
      for (var i=0;i<this.remarksList.length;i++){
        if(this.remarksList[i].add_new == data.add_new){
          this.remarksList.splice(i);
        }
      }
      this.remark_hidden=false;
    }
    this.send_data();
  }

  hospital_filter(e) {
    var tmp_arr = [];
    this.hosp_name=e.target.value.toString();
    if (this.hosp_name !== "" && this.hosp_name.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'usercontroller/hospital/',
        JSON.stringify({
          hosp_name: this.hosp_name
        }),
        { headers: headers })
        .subscribe(
          response => {
            this.hospital_txt = [];
            this.hospital_filterlist = [];
            var obj = JSON.parse(response["_body"]);
            if (obj.hospitals != null) {
              this.hospital_filterlist = obj.hospitals;
              for (var i = 0; i < this.hospital_filterlist.length; i++) {
                this.hospital_txt.push(this.hospital_filterlist[i].hosp_name);
              }
              this.filteredhospList = this.hospital_txt.filter(function (this:any,el) {
                return el.toLowerCase().indexOf(this.hosp_name.toLowerCase()) > -1;
              }.bind(this));
            } 
          },
          error => {});
    } else {
      this.filteredhospList = [];
    }
  }

  followfilter(e) {
    this.filteredList = [];
    this.food_txt = [];
    this.Foot_Item_txt=e.target.value.toString();
    if (this.Foot_Item_txt != "" && this.Foot_Item_txt.length > 2) {
      var type_id = null;
      for (var i = 0; i < this.get_foodtype_arry.length; i++) {
        if (this.food_type_name == this.get_foodtype_arry[i].description) {
          type_id = this.get_foodtype_arry[i].type_id;
        }
      }

      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'lifestyle/fdiet/',
        JSON.stringify({
          food_category: type_id,
          name: this.Foot_Item_txt
        }),
        { headers: headers })
        .subscribe(
          response => {
            this.follow_avoid_search_items=[];
            this.food_txt=[];
            var obj = JSON.parse(response["_body"]);
            this.follow_food_search_items = obj.diet_values;
            for (var i = 0; i < this.follow_food_search_items.length; i++) {
              this.food_txt.push(this.follow_food_search_items[i].name);
            }

            this.filteredList = this.food_txt.filter(function (this:any,el) {
              return el.toLowerCase().indexOf(this.Foot_Item_txt.toLowerCase()) > -1;
            }.bind(this));
          },
          error => {
              this.toastr.error(Message_data.defaultErr);
          }
        )
    } else {
      this.filteredList = [];
    }
  }
  
  avoidfilter(e) {
    this.filteredList1 = [];
    this.food_txt = [];
    this.Foot_Item_avoidtxt=e.target.value.toString();
    if (this.Foot_Item_avoidtxt !== "" && this.Foot_Item_avoidtxt.length > 2) {
      var type_id = null;
      for (var i = 0; i < this.get_foodtype_arry.length; i++) {
        if (this.Food_type_avoid == this.get_foodtype_arry[i].description) {
          type_id = this.get_foodtype_arry[i].type_id;
        }
      }

      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'lifestyle/fdiet/',
        JSON.stringify({
          food_category: type_id,
          name: this.Foot_Item_avoidtxt
        }),
        { headers: headers })
        .subscribe(
          response => {
            this.follow_avoid_search_items=[];
            this.food_txt=[];
            var obj = JSON.parse(response["_body"]);
            this.follow_avoid_search_items = obj.diet_values;

            for (var i = 0; i < this.follow_avoid_search_items.length; i++) {
              this.food_txt.push(this.follow_avoid_search_items[i].name);
            }

            this.filteredList1 = this.food_txt.filter(function (this:any,el) {
              return el.toLowerCase().indexOf(this.Foot_Item_avoidtxt.toLowerCase()) > -1;
            }.bind(this));
          },
          error => {
              this.toastr.error(Message_data.defaultErr);
          }
        )
    } else {
      this.filteredList1 = [];
    }
  }

  addPrognosis() {
    if (this.prog_txt == undefined || this.prog_txt == "") {
      this.toastr.error(Message_data.addProg);
    } else {
      var today = new Date().toJSON().slice(0, 10).replace(/-/g, '/');
      var get_date = today.split('/');
      var doc_name;

      if(this.userinfo.middle_name != "undefined"){
        doc_name= this.userinfo.first_name + " " + this.userinfo.middle_name + " " + this.userinfo.last_name
      } else {
        doc_name= this.userinfo.first_name + " " + this.userinfo.last_name
      }
      if (this.Nepro_Prognosis_List.length == 0 && (this.prog_txt == undefined || this.prog_txt == "")) {
        this.prognosis_array_flag = false;
      }else {
        this.prognosis_array_flag = true;
      }
  
      this.Nepro_Prognosis_List.push({
        Prog_Data: this.prog_txt,
        Doct_Name: doc_name,
        Date:  get_date[2]+"-"+get_date[1]+"-"+get_date[0],
        add_new: "1"
      });
      this.prog_hidden=true;
      this.prog_txt = "";
    }
    this.send_data();
  }

  disease_change(e) {
    this.diag_disease=e;
    if (this.diag_disease == "6") {
      if(this.stage_hidden == false){
        this.stage_type_hide = false;
      }
    } else {
      this.stage_type_hide = true;
      this.stage_type = undefined;
    }
  }

  treatment_change(e) {
    this.diag_treat=e;
    this.dys_freq_hide = true;
    this.dys_type_hide = true;
    if (this.diag_treat == "2") {
      if(this.dialy_type_hidden==false) {
        this.dys_type_hide = false;
        this.dys_freq_hide = false;
      }
    } else if (this.diag_treat == "7") {
      if(this.dialy_type_hidden==false) {
        this.dys_type_hide = false;
        this.dys_freq_hide = false;
      }
    }
  }

  next_dateclick(date) {
    this.next_data = date;
  }
  
  save_graph() {
    $('#nephModal').modal('show');
  }

  send_graph(e){
    if(e == "yes"){
      if((document.getElementById("theimage")) != null){
        var img_str = (document.getElementById("theimage")as HTMLImageElement).src;
      }

      var img_path ="";
      if (img_str != null) {
        img_path = (img_str.toString()).split(',')[1];
      }else{
        img_path = "";
      }
     this.image_path = img_path;
    }else{
      this.toastr.error(Message_data.cancelClicked);
    }
    $('#nephModal').modal('hide');
  }

  changetab(e){
    if( e == "diet"){
      this.diet_table = true;
      this.prognosis_table = false;
      this.notes_table = false;
    }
    else if( e == "prognosis"){
      this.diet_table = false;
      this.prognosis_table = true;
      this.notes_table = false;
    }
    else if( e == "notes"){
      this.diet_table = false;
      this.prognosis_table = false;
      this.notes_table = true;
    }else if( e == "exercise"){     
      this.diet_table = false;
      this.prognosis_table = false;
      this.notes_table = false;
    }
  }

  lookupICDCodes() {
    this.Icd_code="";
    if (this.Icd_Descript.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'icd/afillcode/',
        JSON.stringify({
          text: this.Icd_Descript
        }),
        { headers: headers })
        .subscribe(
          response => {
            this.icdList = [];
            var obj = JSON.parse(response["_body"]);
            
            for (var i = 0; i < obj.icdcodes.length; i++) {
              this.icdList.push({
                code: obj.icdcodes[i].code,
                desc :obj.icdcodes[i].description
              });
            }
          },
          error => {
            this.toastr.error(Message_data.network);
          }
        )
    }
  }

  select_icdcode(item) {
    this.Icd_code="";
    
    for (var i = 0; i < this.icdList.length; i++) {
      if (this.icdList[i].desc == item) {
        this.Icd_code = this.icdList[i].code;
      }
    }
    this.Icd_Descript = item;
    this.icdList = [];
  }

  Icd_click() {
    if(this.client == false){
      const dialogRef = this.dialog.open(IcdCodePopupComponent, {
        width: '700px',
        height: '300px'
      });
  
      dialogRef.afterClosed().subscribe(result => {
        
        if (result != undefined) {
          var Icd_data = result.split("-")
          this.Icd_code = Icd_data[0];
          this.Icd_Descript = Icd_data[1];
        }    
      });
    }
  }

  //Read only(disabeld)
  isReadonly() { return true; }
  
  savesend_data(){}

  drawImage(e){
    if(this.isDraw == true){
      this.loadCss();
      this.loadScript();
      if (Helper_nephro_casesheet.nephro_get_ret != undefined) {
        if (Helper_nephro_casesheet.nephro_get_ret.image_path != undefined) {
          this.image = ipaddress.Ip_with_img_address + Helper_nephro_casesheet.nephro_get_ret.image_path;
          load_img(ipaddress.Ip_with_img_address + Helper_nephro_casesheet.nephro_get_ret.image_path);
        } else {
          this.image = "assets/img/nephro_icons/kidney.jpg";
          // nephrology(this.image);
          load_img("assets/img/nephro_icons/kidney.jpg");
        }
      } else {
        this.image = "assets/img/nephro_icons/kidney.jpg";
        load_img("assets/img/nephro_icons/kidney.jpg");
      }
    }

  }
}
