<div *ngIf="medPresFlag == 'min'">
   <p *ngIf="medPresList.length == 0">No Prescription Details Found</p>
   <table id="card_tbl" class="table table-hover table-dynamic" *ngIf="medPresList.length != 0">
      <tr>
         <th style="padding-left: 8px !important;">Date</th>
         <th>Prescription</th>
         <th></th>
      </tr>
      <tr *ngFor="let prescription of medPresList | slice: 0 : 2">
         <td style="font-size: 11px !important;">{{prescription.date}}</td>
         <td style="font-size: 11px !important;">{{"Dr. " + prescription.doctorname}} prescribed for
            {{prescription.disease}}</td>
      </tr>
   </table>
</div>
<div *ngIf="medPresFlag == 'max'">
   <div *ngIf="!addmed" class="splitter">
      <div style="float: right;margin-bottom: 10px;">
         <img *ngIf="!addMedPresFlag" style="width: 25px !important; position: absolute;top: 5px; right: 30px;"
            src="../../../assets/img/minussybl.svg" (click)="addMedPrescription()" />
      </div>
      <p class="nodata" *ngIf="medPresList.length == 0">No Prescription Details Found</p>
      <as-split direction="vertical" *ngIf="medPresList.length != 0">
         <as-split-area [size]="30" style="margin-top: -42px;">
            <div class="block1 floatright">
            </div><br>
            <div class="personaldetails">
               <div class="block1">
                  <p class="nodata" *ngIf="medPresList.length == 0">No Prescription Details Found</p>
                  <table id="card_tbl" class="table table-hover table-dynamic" *ngIf="medPresList.length != 0">
                     <tr>
                        <th style="padding-left: 9px !important;">Date</th>
                        <th>Prescription</th>
                        <th></th>
                     </tr>
                     <tr *ngFor="let prescription of medPresList">
                        <td>{{prescription.date}}</td>
                        <td style="text-align: left;padding-left: 20px;">{{"Dr. " + prescription.doctorname}} prescribed
                           for {{prescription.disease}}</td>
                        <td *ngIf="medPresFlag == 'max'"><a
                              (click)="prescriptionGetvalue(prescription.pres_drug_id)"><img
                                 src="../../../assets/img/images.png" class="editDelicon" /></a></td>
                     </tr>
                  </table>
               </div>
            </div>
         </as-split-area>
         <as-split-area [size]="70" *ngIf="medPresFlag == 'max'">
            <p *ngIf="medViewListFlag "><strong>Prescription details</strong></p>
            <div class="personaldetails">
               <div *ngIf="medViewListFlag">
                  <div class="row">
                     <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" *ngIf="!retrvHospFlag">
                        <mat-label class="matlabel"><b>{{retrvHsptlName}}</b></mat-label><br>
                        <mat-label class="matlabel">{{retrvHsptlAddress}}</mat-label><br>
                        <mat-label class="matlabel">{{retrvHsptlLocation}},{{retrvHsptlCity}}</mat-label><br>
                        <mat-label class="matlabel">{{retrvHsptlState}},{{retrvHsptlCountry}}</mat-label><br>
                        <mat-label class="matlabel">{{retrvHsptlTelephone }}</mat-label><br>
                     </div>
                     <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <mat-label *ngIf="retrvDoctorNameFlag"><b>Doctor : {{retrvDoctorName}}
                              {{retrvDoctorQualify}}</b>
                        </mat-label><br>
                        <mat-label><b>Date : </b>{{retrvPrescriptionDate}}</mat-label><br>
                     </div>
                  </div>
                  <hr class="borderStylehr">
                  <div class="row">
                     <div class="col-12">
                        <mat-label><b>Patient :</b> {{retrvPatientName}}</mat-label><br>
                        <mat-label><b>Disease :</b> {{retrvDiseaseName}} {{retrvDiseaseDesc}}</mat-label><br>
                        <mat-label [hidden]="instructionsFlag"> <b *ngIf="instructions!= undefined">{{'Instructions'
                              |translate}}: </b> {{instructions}}</mat-label><br>
                     </div>
                  </div>
                  <div class="col-12">
                     <table id="card_tbl" class="table table-hover table-dynamic">
                        <thead>
                           <tr>
                              <th>Medcine Name</th>
                              <th>Dosage</th>
                              <th>Duration</th>
                              <th>Intake</th>
                           </tr>
                        </thead>
                        <tbody>
                           <tr *ngFor="let medicene of listProducts;  let i=index">
                              <td>{{medicene.drug_name}}</td>
                              <td>{{medicene.every_six}}</td>
                              <td>{{medicene.days}} {{medicene.day_dur}}</td>
                              <td>{{medicene.dure_txt_table}} {{medicene.drug_intake }}</td>
                           </tr>
                        </tbody>
                     </table>
                  </div>
                  <div *ngIf="!pharmacyFlag">
                     <div class="details1" *ngFor="let pharma of pharmacyDetails">
                        <mat-label><b>{{pharma.pharmacy_name}}</b></mat-label>
                        <mat-label>{{pharma.phar_addr1}}, {{pharma.phar_addr2}}</mat-label>
                        <mat-label>{{pharma.phar_loc}}</mat-label>
                        <mat-label>{{pharma.phar_city}} - {{pharma.phar_zipcode}}</mat-label>
                        <mat-label>{{pharma.phar_state}}, {{pharma.phar_cnty}}</mat-label>
                        <mat-label>{{pharma.telephone}}</mat-label>
                     </div>
                  </div>
               </div>
            </div>
         </as-split-area>
      </as-split>
   </div>
   <!-- medical  -->
   <div *ngIf="addmed">
      <a printSectionId="print" (click)="print()" ngxPrint><span class="cardsave" style="right: 62px;"><img
               src="../../../assets/img/printer.svg" style="width:25px" /></span> </a>
      <a [hidden]="!saveflag" (click)="savePrescription('noprint')"><span class="cardsave"><img
               src="../../../assets/img/save.svg" style="width:20px" style="width:25px" /></span> </a>
      <div id="pdffile">
         <div class="personaldetails">
            <div style="float: right;margin-bottom: 10px;">
            </div>
            <div class="row">
            </div>
            <!-- <hr class="borderStylehr"> -->
            <!-- medical  -->
            <div *ngIf="addmed">
               <a (click)="print()"><span class="cardsave" style="right: 60px;"><img
                        src="../../../assets/img/printer.svg" style="width:25px" /></span> </a>
               <!-- (click)="savePrescription('noprint')" -->
               <a [hidden]="!saveflag" (click)="savePrescription('noprint')"><span class="cardsave"><img
                        src="../../../assets/img/save.svg" style="width:20px" style="width:25px" /></span> </a>
               <div id="pdffile">
                  <div class="personaldetails">
                     <mat-accordion class="mataccordion">
                        <mat-expansion-panel class="expandpanel" [expanded]="true">
                           <mat-expansion-panel-header class="exppanel width">
                              <mat-panel-title class="title">
                                 Medical prescription
                              </mat-panel-title>
                           </mat-expansion-panel-header>
                           <hr class="hr">
                           <div style="float: right;margin-bottom: 10px; position: relative;">
                              <a style="margin-right: 5px;  position: absolute;  top: -30px; right: 78px;"
                                 *ngIf="backFlag" (click)="save(null)"><img
                                    src="../../../assets/ui_icons/buttons/save_button.svg"
                                    class="backimgbtn_inpatinfo" /></a>
                           </div>
                           <div class="row">
                              <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                 <div class="cover_div">
                                    <div class="header_lable">
                                       Investigation details
                                    </div>
                                    <div class="content_cover">
                                       <div class="row">
                                          <div class="col-12">
                                             <mat-label class="matlabel">{{'Language'|translate}}<br>
                                                <mat-select disableOptionCentering class="ipcss" [(ngModel)]="language"
                                                   (selectionChange)="changeLanguage()">
                                                   <mat-option value="English">English</mat-option>
                                                   <mat-option value="Tamil">Tamil</mat-option>
                                                   <mat-option value="Telugu">Telugu</mat-option>
                                                   <mat-option value="Kannada">Kannada</mat-option>
                                                   <mat-option value="Malayalam">Malayalam</mat-option>
                                                   <mat-option value="Oriya">Oriya</mat-option>
                                                   <mat-option value="Bengali">Bengali</mat-option>
                                                   <mat-option value="Hindi">Hindi</mat-option>
                                                </mat-select>
                                             </mat-label>
                                          </div>
                                          <div class="col-12 ">
                                             <mat-label class="matlabel">
                                                <mat-radio-group class="radiobtngrp" color="primary"
                                                   labelPosition="before">
                                                   <mat-radio-button value="autoRx"
                                                      (change)="changeFillType($event.value)">{{'Auto
                                                      Rx'|translate}}
                                                   </mat-radio-button>
                                                   &nbsp;&nbsp;
                                                   <mat-radio-button value="refill"
                                                      (change)="changeFillType($event.value)">
                                                      {{'Refill'|translate}}
                                                   </mat-radio-button>
                                                   <mat-radio-button value="previous"
                                                      (click)="changeFillType('previous')" style="padding-left: 13px;">
                                                      {{'Previous Rx'|translate}}</mat-radio-button>
                                                </mat-radio-group>
                                             </mat-label>
                                          </div>
                                          <div class="col-12">
                                             <div class="row">
                                                <div class="col-12">
                                                   <mat-label class="matlabel">{{'Diagnosis'|translate}}
                                                      <input type="text" class="ipcss noappt_width"
                                                         [(ngModel)]="diagnosis" (keyup)="getSymptoms()" matInput
                                                         [matAutocomplete]="auto8" required maxlength="50" />
                                                      <mat-autocomplete #auto8="matAutocomplete">
                                                         <mat-option (click)="selectSymptom(symptom)"
                                                            *ngFor="let symptom of symptomList" value="{{symptom}}">
                                                            {{symptom}}
                                                         </mat-option>
                                                      </mat-autocomplete>
                                                   </mat-label>
                                                </div>
                                             </div>
                                          </div>
                                          <div class="col-12">
                                             <mat-label class="matlabel">{{'Investigation' |translate}}<br>
                                                <textarea class="ipcss txtarea" id="myInput" rows="1" maxlength="250"
                                                   [(ngModel)]="investigation"></textarea>
                                             </mat-label>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-8">
                                 <div class="cover_div">
                                    <div class="header_lable">
                                       Medicine details
                                    </div>
                                    <div class="content_cover">
                                       <div class="row">
                                          <div class="col-12 col-sm-12 col-md-5 col-lg-6 col-xl-3">
                                             <mat-checkbox color="primary" [(ngModel)]="newMed"
                                                (change)="showMedType(newMed)">
                                             </mat-checkbox>
                                             <mat-label class="matlabel">
                                                {{'New medicine'|translate}}
                                             </mat-label>
                                          </div>
                                          <div class="col-12 col-sm-12 col-md-7 col-lg-6 col-xl-9">
                                             <mat-label class="matlabel" [hidden]="newMedFlag">{{'Medicine type'
                                                |translate}}
                                                <input type="text" class="ipcss " required maxlength="50"
                                                   [(ngModel)]="medType" (keyup)="getMedicineType($event)"
                                                   (focus)="clearMedicineType()" aria-label="Number" matInput
                                                   autocomplete="on" [matAutocomplete]="auto2"
                                                   style="max-width: 175px;" />
                                                <mat-autocomplete #auto2="matAutocomplete">
                                                   <mat-option id="optionfont" (click)="selectMedicineType(medtype)"
                                                      *ngFor="let medtype of medicalPrescriptionList"
                                                      value={{medtype.med_name}}>
                                                      {{medtype.med_name}}
                                                   </mat-option>
                                                </mat-autocomplete>
                                             </mat-label>
                                          </div>
                                          <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                             <mat-label class="matlabel">{{'Medicine name'|translate}}
                                                <input type="text" class="ipcss " required maxlength="50"
                                                   [(ngModel)]="medicineName" (keyup)="changeMedicineName($event)"
                                                   aria-label="Number" matInput [matAutocomplete]="auto6" />
                                                <mat-autocomplete #auto6="matAutocomplete">
                                                   <mat-option id="optionfont" (click)="selectMedicine(medicine)"
                                                      *ngFor="let medicine of medicineList" [value]="medicine">
                                                      {{medicine}}
                                                   </mat-option>
                                                </mat-autocomplete>
                                             </mat-label>
                                          </div>
                                          <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4"
                                             [hidden]="durationFlag">
                                             <mat-label class="matlabel">{{'Duration'|translate}}</mat-label>
                                             <div class="row">
                                                <div class="col-6 col-xl-6 col-sm-6 col-md-6 col-lg-6">
                                                   <input type="text" class="ipcss" matInput required maxlength="5"
                                                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                      [(ngModel)]="dayDuration1" />
                                                </div>
                                                <div class="col-6 col-xl-6 col-sm-6 col-md-6 col-lg-6"
                                                   [hidden]="durationFlag">
                                                   <mat-select disableOptionCentering class="ipcss "
                                                      (selectionChange)="changeDuration()" [hidden]="durationFlag"
                                                      [(ngModel)]="dayDuration2">
                                                      <mat-option disabled>Select</mat-option>
                                                      <mat-option value="day(s)">day(s)</mat-option>
                                                      <mat-option value="week(s)">week(s)</mat-option>
                                                      <mat-option value="month(s)">month(s)</mat-option>
                                                   </mat-select>
                                                </div>
                                             </div>
                                          </div>
                                          <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-3" [hidden]="intakeFlag">
                                             <mat-label class="matlabel">{{'Intake'|translate}}<br>
                                                <mat-select disableOptionCentering class="ipcss " required
                                                   (selectionChange)="changeIntake()" [(ngModel)]="intake">
                                                   <mat-option value="Empty stomach">Empty stomach</mat-option>
                                                   <mat-option value="After food">After food</mat-option>
                                                   <mat-option value="Before food">Before food</mat-option>
                                                   <mat-option value="With food">With Food</mat-option>
                                                </mat-select>
                                             </mat-label>
                                          </div>
                                          <div class="col-6 col-sm-6 col-md-3 col-lg-6 col-xl-1"
                                             [hidden]="minuteDurFlag">
                                             <mat-label [hidden]="minuteDurFlag" class="matlabel">{{'Min'|translate}}
                                                <input type="text" class="ipcss " matInput
                                                   [required]="minuteDurRequiredFlag"
                                                   onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                   maxlength="3" [(ngModel)]="minuteDuration" />
                                             </mat-label>
                                          </div>
                                          <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                             <mat-label class="matlabel">{{'Intake session'|translate}}<br>
                                                <mat-select disableOptionCentering class="ipcss " required
                                                   [(ngModel)]="intakeSession">
                                                   <mat-option disabled>Select</mat-option>
                                                   <mat-option *ngFor="let intake of intakeSessionList"
                                                      (click)="changeIntakeSession()" value={{intake.description}}>
                                                      {{intake.description}}</mat-option>
                                                </mat-select>
                                             </mat-label>
                                          </div>
                                          <div [hidden]="mornFlag" class="col-3 col-sm-3 col-md-3 col-lg-2 col-xl-2">
                                             <div [hidden]="morFlag">
                                                <mat-label class="matlabel input">{{'Mor'|translate}}
                                                   <input type="text" class="ipcss " matInput
                                                      [required]="morRequiredFlag" [(ngModel)]="mor"
                                                      [disabled]="morDisabledFlag" maxlength="5"
                                                      onkeypress="return event.charCode == 32 || event.charCode == 46 || event.charCode == 47 || (event.charCode >= 48 && event.charCode <= 57)" />
                                                </mat-label>
                                             </div>
                                             <div [hidden]="quantityFlag">
                                                <mat-label class="matlabel">{{'Qty'|translate}}
                                                   <input type="text" class="ipcss " matInput
                                                      [required]="morRequiredFlag" maxlength="5"
                                                      onkeypress="return event.charCode == 32 || event.charCode == 46 || event.charCode == 47 || (event.charCode >= 48 && event.charCode <= 57)"
                                                      [disabled]="morDisabledFlag" [(ngModel)]="mor" />
                                                </mat-label>
                                             </div>
                                          </div>
                                          <div class="col-3 col-sm-3 col-md-3 col-lg-2 col-xl-2 "
                                             [hidden]="afterEveFlag">
                                             <span class="iocn_position">-</span>
                                             <mat-label class="matlabel">{{'Aft'|translate}}
                                                <input type="text" class="ipcss " matInput
                                                   [disabled]="afternoonDisabledFlag" maxlength="5"
                                                   onkeypress="return event.charCode == 32 || event.charCode == 46 || event.charCode == 47 || (event.charCode >= 48 && event.charCode <= 57)"
                                                   [required]="afternoonRequiredFlag" [(ngModel)]="aft" />
                                             </mat-label>
                                          </div>
                                          <div class="col-3 col-sm-3 col-md-3 col-lg-2 col-xl-2" [hidden]="eveFlag">
                                             <span class="iocn_position ">-</span>
                                             <mat-label class="matlabel">{{'Eve'|translate}}
                                                <input type="text" class="ipcss " matInput
                                                   [disabled]="eveningDisabledFlag" [required]="eveningRequiredFlag"
                                                   maxlength="5"
                                                   onkeypress="return event.charCode == 32 || event.charCode == 46 || event.charCode == 47 || (event.charCode >= 48 && event.charCode <= 57)"
                                                   [(ngModel)]="eve" />
                                             </mat-label>
                                          </div>
                                          <div class="col-3 col-sm-3 col-md-3 col-lg-2 col-xl-2" [hidden]="nightFlag">
                                             <span class="iocn_position">-</span>
                                             <mat-label class="matlabel">{{'Ngt'|translate}}
                                                <input type="text" class="ipcss " matInput [disabled]="ngtDisabledFlag"
                                                   [required]="eveningRequiredFlag" maxlength="5"
                                                   onkeypress="return event.charCode == 32 || event.charCode == 46 || event.charCode == 47 || (event.charCode >= 48 && event.charCode <= 57)"
                                                   [(ngModel)]="ngt" />
                                             </mat-label>
                                          </div>
                                          <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                             <mat-label class="matlabel">{{'Note'|translate}}<br>
                                                <textarea class="ipcss widthappt" id="myInput" maxlength="250"
                                                   [(ngModel)]="remark"></textarea>
                                             </mat-label>
                                          </div>
                                          <div *ngIf="addMedicineFlag"
                                             class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 my-auto"
                                             style="text-align: right;">
                                             <a (click)="addMedicalPrescription()"><img
                                                   src="../../../assets/ui_icons/buttons/add_button.svg"
                                                   class="saveimgbtn_inpatinfo" /></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div class="col-12" style="margin: 20px 0;">
                                 <div class="col-12 dig_table_overflow" [hidden]="medicineFlag">
                                    <div class="table-responsive">
                                       <table id="tbl" *ngIf="listProducts.length !=0 ">
                                          <thead>
                                             <tr>
                                                <th style="width: 11%;">{{'Medicine type'|translate}}</th>
                                                <th style="width: 16%;">{{'Medicine name'|translate}}</th>
                                                <th style="width: 7%;">{{'Duration'|translate}}</th>
                                                <th style="width: 12%;">{{'Intake session'|translate}}</th>
                                                <th style="width: 10%;">{{'Frequency'|translate}}</th>
                                                <th style="width: 15%;">{{'Intake'|translate}}</th>
                                                <th style="width: 22%;">{{'Note'|translate}}</th>
                                                <th>{{'Action'|translate}}</th>
                                             </tr>
                                          </thead>
                                          <tbody>
                                             <tr *ngFor="let product of listProducts">
                                                <td style="text-align: left;">{{product.med_typetxt}}</td>
                                                <td style="text-align: left;">{{product.drug_name}}</td>
                                                <td style="text-align: left;">{{product.days}} {{product.period
                                                   |translate}}</td>
                                                <td>{{product.every_six}}</td>
                                                <td style="text-align: left;">{{product.frequency |translate}}</td>
                                                <td style="text-align: left;">{{product.dure_txt_table}}
                                                   {{product.drug_intake
                                                   |translate}}</td>
                                                <td style="text-align: left;">{{product.remarks}}</td>
                                                <td>
                                                   <a (click)="editMedicalPres(product.drug_id,product.rowid)">
                                                      <img src="../../../assets/ui_icons/buttons/pencil_icon.svg"
                                                         class="editDelicon1" /></a>&nbsp;&nbsp;
                                                   <a
                                                      (click)="deleteMedicalPrescription(product.med_typetxt,product.drug_name)"><img
                                                         src="../../../assets/ui_icons/buttons/trash_icon.svg"
                                                         class="editDelicon1" /></a>
                                                </td>
                                             </tr>
                                          </tbody>
                                       </table>
                                    </div>
                                 </div>
                              </div>
                              <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                 <div class="cover_div">
                                    <div class="header_lable">
                                       Pharmacy details
                                    </div>
                                    <div class="content_cover">
                                       <div class="row">
                                          <div class="col-12">
                                             <mat-label class="matlabel">{{'Preferred pharmacy' |translate}}
                                                <input type="text" class="ipcss" maxlength="50"
                                                   [(ngModel)]="prferPharma" (keyup)="changePreferPharmacy($event)"
                                                   aria-label="Number" matInput [matAutocomplete]="auto1" />
                                                <mat-autocomplete #auto1="matAutocomplete">
                                                   <mat-option id="optionfont"
                                                      (click)="selectPreferPharmacy(prferPharma)"
                                                      *ngFor="let pharma of pharmacyList" [value]="pharma">
                                                      {{pharma}}
                                                   </mat-option>
                                                </mat-autocomplete>
                                             </mat-label>
                                          </div>
                                          <div class="col-12">
                                             <mat-label class="matlabel">{{'Preferred pharmacy address' |translate}}<br>
                                                <textarea class=" ipcss  txtarea" disabled="true" id="myInput" rows="1"
                                                   [(ngModel)]="prferPharmaAddress"></textarea>
                                             </mat-label>
                                          </div>
                                          <div class="col-12">
                                             <div>
                                                <mat-label class="matlabel">
                                                   <mat-checkbox labelPosition="before" [(ngModel)]="orderNow"> {{'Would
                                                      u like
                                                      to place order now'|translate}} </mat-checkbox>
                                                </mat-label><br>
                                                <mat-label class="matlabel"><strong>{{"Dr."+doctorName}} <span
                                                         style="font-size: 10px;">{{qualification}}</span></strong>
                                                </mat-label>
                                                <br>
                                                <mat-label class="matlabel"> Registration no: {{licenceCode}}
                                                </mat-label><br>
                                             </div>
                                             <div>
                                                <mat-label class="matlabel">{{'Find your prescription online
                                                   at'|translate}}
                                                   www.tervys.com
                                                </mat-label><br>
                                                <mat-label class="matlabel"><b><i>This is digitally generated
                                                         Prescription</i></b>
                                                </mat-label>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-8">
                                 <div class="cover_div">
                                    <div class="header_lable">
                                       Instructions
                                    </div>
                                    <div class="content_cover">
                                       <div class="row">
                                          <div class="col-12">
                                             <quill-editor id="editor" [(ngModel)]="instructions" [style]="editorStyle"
                                                [modules]="modules" placeholder="Enter Text"
                                                (onSelectionChanged)="onSelectionChanged($event)"
                                                (onContentChanged)="onContentChanged($event)"></quill-editor>
                                          </div>
                                          <div class="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                                             <mat-label class="matlabel">{{'Next visit' |translate}}<br>
                                                <input type="date" class="ipcss_date widthappt" id="appt_date"
                                                   (change)="selectNextVisit(nextVisit)" [(ngModel)]="nextVisit"
                                                   min={{currentMinDate}} #matInput style="width: 140px;">
                                             </mat-label>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </mat-expansion-panel>
                     </mat-accordion>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
   <!-- expand  -->
   <div [hidden]="true">
      <div #printlogowithname id="printlogowithname"
         style="background-image: url({{bgImage}}); background-position: center;">
         <table style="margin-left:50px;margin-top:70px;">
            <tr>
               <td style="width: 450px; vertical-align: top;">
                  <img alt="image" src={{hospImg}} width="100px" height="100px">
                  <p style="margin-top: -100px;margin-left: 110px;">{{hospitalName}}</p>
                  <p style="margin-left: 110px;">{{hospAddress}},{{hospLocation}}</p>
                  <p style="margin-left: 110px;">{{hospCity}}</p>
                  <p style="margin-left: 110px;">{{hospState}}</p>
               </td>
               <td style="width: 50px;margin-top: 165px;">&nbsp;</td>
               <td style="width: 300px;vertical-align: top;">
                  <p><b>{{'Doctor'|translate}} : {{"Dr."+doctorName}} {{doctorQualif}}</b></p>
                  <p><b>{{'Date'|translate}} : </b>{{appointDate}}</p>
                  <p *ngIf="referredDoctorFlag">{{'Referred by'|translate}}: {{referredDoctor}}</p>
               </td>
            </tr>
         </table>
         <br /><br />
         <table style="margin-left: 50px;">
            <tr>
               <td style="width: 450px;">
                  <p><b>{{'Name'| translate}} :</b> {{clientName}}</p>
                  <p><b>{{'Age'| translate}} :</b> {{clntAge}}
                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{{'Gender'|translate}}:
                     </b>{{clntGender}} </p>
                  <p *ngIf="investigation!=undefined"><b>{{'Investigation'|translate}} :</b> {{investigation}}</p>
               </td>
               <td>
                  <p *ngIf="bpFlag">{{'BP'|translate}}: {{clntBp}} &nbsp;&nbsp;&nbsp;{{'Pulse'|translate}}:
                     {{clntPulse}}</p>
                  <p *ngIf="orthoFlag">{{'CVS'|translate}}: {{clntCvs}} &nbsp;&nbsp;&nbsp;{{'RS'|translate}}:
                     {{clntRs}}</p>
                  <p *ngIf="orthoFlag">{{'Kidney'|translate}}: {{clntKidney}}</p>
               </td>
            </tr>
         </table>
         <div style="margin-left: 50px;">
            <p><b>{{'Diagnosis' |translate}}: </b>{{diagnosis}}</p>
         </div>
         <br />
         <table style="margin-left: 50px; border: 1px solid black;border-collapse: collapse;">
            <tr>
               <th style="width: 200px;border: 1px solid black;border-collapse: collapse;">
                  <b>{{'Medicine'|translate}}</b>
               </th>
               <th style="width: 100px;border: 1px solid black;border-collapse: collapse;"><b>{{'Dosage'|translate}}</b>
               </th>
               <th style="width: 50px;border: 1px solid black;border-collapse: collapse;">
                  <b>{{'Duration'|translate}}</b>
               </th>
               <th style="width: 50px;border: 1px solid black;border-collapse: collapse;">
                  <b>{{'Frequency'|translate}}</b>
               </th>
               <th style="border: 1px solid black;border-collapse: collapse;">&nbsp;</th>
               <th style="width: 50px;border: 1px solid black;border-collapse: collapse;"><b>{{'Note'|translate}}</b>
               </th>
            </tr>
            <tr *ngFor="let product of listProducts">
               <td style="border: 1px solid black;border-collapse: collapse;">
                  {{product.med_typetxt}}-{{product.drug_name}}
               </td>
               <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{product.every_six}}
               </td>
               <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{product.days}}
                  {{product.period|translate}}</td>
               <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{product.frequency |
                  translate}}</td>
               <td style="border: 1px solid black;border-collapse: collapse;">{{product.dure_txt_table}}
                  ({{product.drug_intake|translate}})</td>
               <td style="border: 1px solid black;border-collapse: collapse;">{{product.remarks}}</td>
            </tr>
         </table>
         <div style="margin-left: 50px;">
            <p *ngIf="instructions!=undefined"><b>{{'Instructions' |translate}}: </b>{{instructions}}</p>
         </div>
         <br />
         <div style="margin-left: 50px;" *ngIf="treatFlag">
            <p *ngIf="treatment!=undefined"><b>{{'Treatment' |translate}}: </b>{{treatment}}</p>
         </div>
         <div style="margin-left: 50px;">
            <p *ngIf="printNextVisit != undefined"><b>{{'Next visit' |translate}}: </b>{{printNextVisit}}</p>
         </div>
         <br />
         <div>
            <p style="margin-left: 50px;font-size: 13px;"> <strong>{{"Dr."+doctorName}} {{doctorQualif}}</strong> </p>
            <p style="margin-left: 50px;font-size: 13px">Registration no: {{licenceCode}}</p>
            <p style="margin-left: 50px;font-size: 13px">Find your prescription online at <i>www.tervys.com</i></p>
            <p style="margin-left: 50px;font-size: 13px"><b><i>This is digitally generated prescription</i></b></p>
            <br />
         </div>
         <!-- <footer [hidden]="printf_flag">
           <img src={{footer_image}}>
         </footer> -->
      </div>
      <div #printbanner id="printbanner" style="background-image: url({{bgImage}}); background-position: center;">
         <table style="margin-left:10px;margin-top:10px;margin-right: 10px;">
            <tr>
               <td>
                  <img alt="image" src={{hptlPresLogo}} width="750px" height="250px">
               </td>
            </tr>
         </table>
         <br /><br />
         <table style="margin-left: 50px; margin-top:20px;">
            <tr>
               <td style="width: 500px;">
                  <p><b>{{'Name'|translate}} :</b> {{clientName}}</p>
                  <p><b>{{'Age'|translate}} :</b> {{clntAge}}
                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{{'Gender'|translate}}:
                     </b>{{clntGender}} </p>
                  <p *ngIf="investigation!=undefined"><b>{{'Investigation'|translate}} :</b> {{investigation}}</p>
               </td>
               <td style="width: 300px;vertical-align: top;">
                  <p><b>{{'Date'|translate}} : </b>{{appointDate}}</p>
                  <p *ngIf="referredDoctorFlag">{{'Referred by'|translate}}: {{referredDoctor}}</p>
                  <p *ngIf="bpFlag">{{'BP'|translate}}: {{clntBp}} &nbsp;&nbsp;&nbsp;{{'Pulse'|translate}}:
                     {{clntPulse}}</p>
                  <p *ngIf="orthoFlag">{{'CVS'|translate}}: {{clntCvs}} &nbsp;&nbsp;&nbsp;{{'RS'|translate}}:
                     {{clntRs}}</p>
                  <p *ngIf="orthoFlag">{{'Kidney'|translate}}: {{clntKidney}}</p>
               </td>
            </tr>
         </table>
         <div style="margin-left: 50px;">
            <p><b>{{'Diagnosis' |translate}}: </b>{{diagnosis}}</p>
         </div>
         <br />
         <table style="margin-left: 50px; border: 1px solid black;border-collapse: collapse;">
            <tr>
               <th style="width: 200px;border: 1px solid black;border-collapse: collapse;">
                  <b>{{'Medicine'|translate}}</b>
               </th>
               <th style="width: 100px;border: 1px solid black;border-collapse: collapse;"><b>{{'Dosage'|translate}}</b>
               </th>
               <th style="width: 50px;border: 1px solid black;border-collapse: collapse;">
                  <b>{{'Duration'|translate}}</b>
               </th>
               <th style="width: 50px;border: 1px solid black;border-collapse: collapse;">
                  <b>{{'Frequency'|translate}}</b>
               </th>
               <th style="border: 1px solid black;border-collapse: collapse;">&nbsp;</th>
               <th style="width: 50px;border: 1px solid black;border-collapse: collapse;"><b>{{'Note'|translate}}</b>
               </th>
            </tr>
            <tr *ngFor="let product of listProducts">
               <td style="border: 1px solid black;border-collapse: collapse;">
                  {{product.med_typetxt}}-{{product.drug_name}}
               </td>
               <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{product.every_six}}
               </td>
               <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{product.days}}
                  {{product.period|translate}}</td>
               <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{product.frequency |
                  translate}}</td>
               <td style="border: 1px solid black;border-collapse: collapse;">{{product.dure_txt_table}}
                  {{product.drug_intake|translate}}</td>
               <td style="border: 1px solid black;border-collapse: collapse;">{{product.remarks}}</td>
            </tr>
         </table>
         <div style="margin-left: 50px;">
            <p *ngIf="instructions!=undefined"><b>{{'Instructions' |translate}}: </b>{{instructions}}</p>
         </div>
         <div style="margin-left: 50px;" *ngIf="treatFlag">
            <p><b>{{'Treatment' |translate}}: </b>{{treatment}}</p>
         </div>
         <div style="margin-left: 50px;">
            <p *ngIf="printNextVisit != undefined"><b>{{'Next visit' |translate}}: </b>{{printNextVisit}}</p>
         </div>
         <br />
         <div>
            <p style="margin-left: 50px;font-size: 13px;"> <strong>{{"Dr."+doctorName}} {{doctorQualif}}</strong> </p>
            <p style="margin-left: 50px;font-size: 13px">Registration no: {{licenceCode}}</p>
            <p style="margin-left: 50px;font-size: 13px">Find your prescription online at <i>www.tervys.com</i></p>
            <p style="margin-left: 50px;font-size: 13px"><b><i>This is digitally generated prescription</i></b></p>
            <br />
         </div>
      </div>
      <div #printnoheader id="printnoheader" style="background-image: url({{bgImage}}); background-position: center;">
         <table style="margin-left: 50px; margin-top:220px;">
            <tr>
               <td style="width: 500px;">
                  <p><b>{{'Name'|translate}} :</b> {{clientName}}</p>
                  <p><b>{{'Age'|translate}} :</b> {{clntAge}}
                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{{'Gender'|translate}}:
                     </b>{{clntGender}} </p>
                  <p *ngIf="investigation!=undefined"><b>{{'Investigation'|translate}} :</b> {{investigation}}</p>
               </td>
               <td style="width: 300px;vertical-align: top;">
                  <p><b>{{'Date'|translate}} : </b>{{appointDate}}</p>
                  <p *ngIf="referredDoctorFlag">{{'Referred by'|translate}}: {{referredDoctor}}</p>
                  <p *ngIf="bpFlag">{{'BP'|translate}}: {{clntBp}} &nbsp;&nbsp;&nbsp;{{'Pulse'|translate}}:
                     {{clntPulse}}</p>
                  <p *ngIf="orthoFlag">{{'CVS'|translate}}: {{clntCvs}} &nbsp;&nbsp;&nbsp;{{'RS'|translate}}:
                     {{clntRs}}</p>
                  <p *ngIf="orthoFlag">{{'Kidney'|translate}}: {{clntKidney}}</p>
               </td>
            </tr>
         </table>
         <div style="margin-left: 50px;">
            <p><b>{{'Diagnosis' |translate}}: </b>{{diagnosis}}</p>
         </div>
         <br />
         <table style="margin-left: 50px; border: 1px solid black;border-collapse: collapse;">
            <tr>
               <th style="width: 200px;border: 1px solid black;border-collapse: collapse;">
                  <b>{{'Medicine'|translate}}</b>
               </th>
               <th style="width: 100px;border: 1px solid black;border-collapse: collapse;"><b>{{'Dosage'|translate}}</b>
               </th>
               <th style="width: 50px;border: 1px solid black;border-collapse: collapse;">
                  <b>{{'Duration'|translate}}</b>
               </th>
               <th style="width: 50px;border: 1px solid black;border-collapse: collapse;">
                  <b>{{'Frequency'|translate}}</b>
               </th>
               <th style="border: 1px solid black;border-collapse: collapse;">&nbsp;</th>
               <th style="width: 50px;border: 1px solid black;border-collapse: collapse;"><b>{{'Note'|translate}}</b>
               </th>
            </tr>
            <tr *ngFor="let product of listProducts">
               <td style="border: 1px solid black;border-collapse: collapse;">
                  {{product.med_typetxt}}-{{product.drug_name}}
               </td>
               <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{product.every_six}}
               </td>
               <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{product.days}}
                  {{product.period|translate}}</td>
               <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{product.frequency |
                  translate}}</td>
               <td style="border: 1px solid black;border-collapse: collapse;">{{product.dure_txt_table}}
                  {{product.drug_intake|translate}}</td>
               <td style="border: 1px solid black;border-collapse: collapse;">{{product.remarks}}</td>
            </tr>
         </table>
         <div style="margin-left: 50px;">
            <p *ngIf="instructions!=undefined"><b>{{'Instructions' |translate}}: </b>{{instructions}}</p>
         </div>
         <br />
         <div style="margin-left: 50px;" *ngIf="treatFlag">
            <p><b>{{'Treatment' |translate}}: </b>{{treatment}}</p>
         </div>
         <div style="margin-left: 50px;">
            <p *ngIf="printNextVisit != undefined"><b>{{'Next visit' |translate}}: </b>{{printNextVisit}}</p>
         </div>
         <br />
         <div>
            <p style="margin-left: 50px;font-size: 13px;"> <strong>{{"Dr."+doctorName}} {{doctorQualif}}</strong> </p>
            <p style="margin-left: 50px;font-size: 13px">Registration no: {{licenceCode}}</p>
            <p style="margin-left: 50px;font-size: 13px">Find your prescription online at <i
                  style="text-transform: lowercase;">www.tervys.com</i></p>
            <p style="margin-left: 50px;font-size: 13px"><b><i>This is digitally generated prescription</i></b></p>
            <br />
         </div>
         <!-- <footer [hidden]="printf_flag">
               <img src={{footer_image}}>
             </footer> -->
      </div>
      <div #printnoheadsidebar id="printnoheadsidebar"
         style="background-image: url({{bgImage}}); background-position: center;">
         <table style="margin-left: 220px; margin-top:350px;">
            <tr>
               <td style="width: 500px;">
                  <p><b>{{'Name'|translate}} :</b> {{clientName}}</p>
                  <p><b>{{'Age'|translate}} :</b> {{clntAge}}
                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{{'Gender'|translate}}:
                     </b>{{clntGender}} </p>
                  <p *ngIf="investigation!=undefined"><b>{{'Investigation'|translate}} :</b> {{investigation}}</p>
               </td>
               <td style="width: 300px;vertical-align: top;">
                  <p><b>{{'Date'|translate}} : </b>{{appointDate}}</p>
                  <p *ngIf="referredDoctorFlag">{{'Referred by'|translate}}: {{referredDoctor}}</p>
                  <p *ngIf="bpFlag">{{'BP'|translate}}: {{clntBp}} &nbsp;&nbsp;&nbsp;{{'Pulse'|translate}}:
                     {{clntPulse}}</p>
                  <p *ngIf="orthoFlag">{{'CVS'|translate}}: {{clntCvs}} &nbsp;&nbsp;&nbsp;{{'RS'|translate}}:
                     {{clntRs}}</p>
                  <p *ngIf="orthoFlag">{{'Kidney'|translate}}: {{clntKidney}}</p>
               </td>
            </tr>
         </table>
         <div style="margin-left: 220px;">
            <p><b>{{'Diagnosis' |translate}}: </b>{{diagnosis}}</p>
         </div>
         <br />
         <table style="margin-left: 220px; border: 1px solid black;border-collapse: collapse;">
            <tr>
               <th style="width: 200px;border: 1px solid black;border-collapse: collapse;">
                  <b>{{'Medicine'|translate}}</b>
               </th>
               <th style="width: 100px;border: 1px solid black;border-collapse: collapse;"><b>{{'Dosage'|translate}}</b>
               </th>
               <th style="width: 50px;border: 1px solid black;border-collapse: collapse;">
                  <b>{{'Duration'|translate}}</b>
               </th>
               <th style="width: 50px;border: 1px solid black;border-collapse: collapse;">
                  <b>{{'Frequency'|translate}}</b>
               </th>
               <th style="border: 1px solid black;border-collapse: collapse;">&nbsp;</th>
               <th style="width: 50px;border: 1px solid black;border-collapse: collapse;"><b>{{'Note'|translate}}</b>
               </th>
            </tr>
            <tr *ngFor="let product of listProducts">
               <td style="border: 1px solid black;border-collapse: collapse;">
                  {{product.med_typetxt}}-{{product.drug_name}}
               </td>
               <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{product.every_six}}
               </td>
               <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{product.days}}
                  {{product.period|translate}}</td>
               <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{product.frequency |
                  translate}}</td>
               <td style="border: 1px solid black;border-collapse: collapse;">{{product.dure_txt_table}}
                  {{product.drug_intake|translate}}</td>
               <td style="border: 1px solid black;border-collapse: collapse;">{{product.remarks}}</td>
            </tr>
         </table>
         <div style="margin-left: 220px;">
            <p *ngIf="instructions!=undefined"><b>{{'Instructions' |translate}}: </b>{{instructions}}</p>
         </div>
         <br />
         <div style="margin-left: 220px;" *ngIf="treatFlag">
            <p><b>{{'Treatment' |translate}}: </b>{{treatment}}</p>
         </div>
         <div style="margin-left: 220px;">
            <p *ngIf="printNextVisit != undefined"><b>{{'Next visit' |translate}}: </b>{{printNextVisit}}</p>
         </div>
         <br />
         <table>
            <tr>
               <td style="width: 35%;"> &nbsp;</td>
               <td>
                  <p style="margin-left: 220px;font-size: 13px;"> <strong>{{"Dr."+doctorName}} {{doctorQualif}}</strong>
                  </p>
                  <p style="margin-left: 220px;font-size: 13px">Registration no: {{licenceCode}}</p>
                  <p style="margin-left: 220px;font-size: 13px">Find your prescription online at <i>www.tervys.com</i>
                  </p>
                  <p style="margin-left: 220px;font-size: 13px"><b><i>This is digitally generated prescription</i></b>
                  </p>
                  <br />
               </td>
            </tr>
         </table>
      </div>
   </div>
</div>