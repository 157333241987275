import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { Message_data } from 'src/assets/js/Message_data';

@Component({
  selector: 'app-client-cancel-appointment',
  templateUrl: './client-cancel-appointment.component.html',
  styleUrls: ['./client-cancel-appointment.component.css']
})
export class ClientCancelAppointmentComponent implements OnInit {

  public cancelTxt:any;

  constructor(public dialogRef: MatDialogRef<ClientCancelAppointmentComponent>,public toastr:ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any,public gservice:CommonDataService) { 
      dialogRef.disableClose = true;
  }

  ngOnInit(): void {
  }

  onNoClick(){
    this.dialogRef.close();
  }

  cancelApps(){
    if(this.cancelTxt == undefined && this.cancelTxt == "")
      this.toastr.error(Message_data.appcancelreason);
  }
}
