<div class="row">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Discharge Summary</h5>
                    </div>
                    <div class="headerButtons">
                        <a (click)="goToPatientlist()"><img src="../../../assets/ui_icons/buttons/Back_button.svg"
                                class="saveimgbtn_inpatinfo" /></a>
                                <a style="margin:0 5px;" (click)="print_area()"><img src="../../../assets/img/printer.svg"
                                    class="backimgbtn_inpatinfo" style="width: 25px; height: 25px;" /></a>
                    </div>
                </div>

            </mdb-card-header>
            <mdb-card-body>
                <div class="row">
                    <div class="Details">
                        <div class="row">
                            <div class="col-6" style="float: right;">
                                <div class="col-6">
                                    <mat-label class="matlabel"><b class="inpat_ds_b">{{hosp_name}}</b></mat-label>
                                </div>
                                <div class="col-10">
                                    <mat-label class="matlabel">{{hosp_addr}}</mat-label>
                                </div>
                                <div class="col-6">
                                    <mat-label class="matlabel">{{hosp_csz}}</mat-label>
                                </div>
                                <div class="col-6">
                                    <mat-label class="matlabel">{{hosp_tel}}</mat-label>
                                </div>
                            </div>
                        </div>
                        <div class="row" style="border: 1px solid #c5d7e6">
                            <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12">
                                <div class="row">
                                    <div class="col-sm-6 col-lg-4 col-xl-4 col-md-6">
                                        <mat-label class="matlabel">Patient Name :</mat-label>
                                    </div>
                                    <div class="col-sm-6 col-lg-6 col-xl-6 col-md-6 nopadding">
                                        <mat-label class="matlabel">{{patient_name}}</mat-label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6 col-lg-4 col-xl-4 col-md-6">
                                        <mat-label class="matlabel">Inpatient ID:</mat-label>
                                    </div>
                                    <div class="col-sm-6 col-lg-6 col-xl-6 col-md-6 nopadding">
                                        <mat-label class="matlabel">{{inpId}}</mat-label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6 col-lg-4 col-xl-4 col-md-6">
                                        <mat-label class="matlabel">Patient Identifier :
                                        </mat-label>
                                    </div>
                                    <div class="col-sm-6 col-lg-6 col-xl-6 col-md-6 nopadding">
                                        <mat-label class="matlabel">{{patient_identifier}}</mat-label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6 col-lg-4 col-xl-4 col-md-6">
                                        <mat-label class="matlabel">Age/gender :</mat-label>
                                    </div>
                                    <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6 nopadding">
                                        <mat-label class="matlabel">{{age}}/{{gender}}</mat-label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12">
                                <div class="row">
                                    <div class="col-sm-6 col-lg-4 col-xl-4 col-md-6">
                                        <mat-label class="matlabel">Admission Date :</mat-label>
                                    </div>
                                    <div class="col-sm-6 col-lg-6 col-xl-6 col-md-6 nopadding">
                                        <mat-label class="matlabel">{{admis_date}}</mat-label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6 col-lg-4 col-xl-4 col-md-6">
                                        <mat-label class="matlabel">Discharge Date :</mat-label>
                                    </div>
                                    <div class="col-sm-6 col-lg-6 col-xl-6 col-md-6 nopadding">
                                        <mat-label class="matlabel">{{dis_date}}</mat-label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6 col-lg-4 col-xl-4 col-md-6">
                                        <mat-label class="matlabel">Consultant - Opinion :</mat-label>
                                    </div>
                                    <div class="col-sm-6 col-lg-6 col-xl-6 col-md-6 nopadding">
                                        <table class="table1">
                                            <tr *ngFor="let name of consultarray">
                                                <td style="text-align: left;">{{name.name}}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" style="border: 1px solid #c5d7e6" *ngIf="surgery_date != undefined">
                            <div >
                                <div class="row">
                                    <div class="col-sm-6 col-lg-4 col-xl-4 col-md-6">
                                        <mat-label class="matlabel">Date of surgery :</mat-label>
                                    </div>
                                    <div class="col-sm-6 col-lg-6 col-xl-6 col-md-6 nopadding">
                                        <mat-label class="matlabel">{{surgery_date}}</mat-label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6 col-lg-4 col-xl-4 col-md-6">
                                        <mat-label class="matlabel">Surgeon :
                                        </mat-label>
                                    </div>
                                    <div class="col-sm-6 col-lg-6 col-xl-6 col-md-6 nopadding">
                                        <mat-label class="matlabel"><table>
                                            <tr *ngFor="let surgeon of surgeon_list;">
                                                <td style="text-align: left;" class="matlabel">{{surgeon.name}}</td>
                                            </tr>
                                        </table></mat-label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6 col-lg-4 col-xl-4 col-md-6">
                                        <mat-label class="matlabel">Surgery procedure :</mat-label>
                                    </div>
                                    <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6 nopadding">
                                        <mat-label class="matlabel">{{surgery_proc}}</mat-label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6 col-lg-4 col-xl-4 col-md-6">
                                        <mat-label class="matlabel">Anaestetist :</mat-label>
                                    </div>
                                    <div class="col-sm-6 col-lg-6 col-xl-6 col-md-6 nopadding">
                                        <mat-label class="matlabel"><table>
                                            <tr *ngFor="let anaest of anaestetist;">
                                                <td style="text-align: left;" class="matlabel">{{anaest.name}}</td>
                                            </tr>
                                        </table></mat-label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6 col-lg-4 col-xl-4 col-md-6">
                                        <mat-label class="matlabel">Anaestesia :</mat-label>
                                    </div>
                                    <div class="col-sm-6 col-lg-6 col-xl-6 col-md-6 nopadding">
                                        <mat-label class="matlabel">{{anaestetype}}</mat-label>
                                    </div>
                                </div>
                                <div class="row" *ngIf="anaest_proc != undefined">
                                    <div class="col-sm-6 col-lg-4 col-xl-4 col-md-6">
                                        <mat-label class="matlabel">Anaestesia procedure :</mat-label>
                                    </div>
                                    <div class="col-sm-6 col-lg-6 col-xl-6 col-md-6 nopadding">
                                        <mat-label class="matlabel">{{anaest_proc}}</mat-label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-6" *ngIf="dis_diag != ''">
                                <div class="row">
                                    <div class="col-12 margin_10">
                                        <mat-label class="matlabel">Diagnosis
                                        </mat-label>
                                    </div>
                                    <div class="col-12">
                                        <div class="ipcss dischargeContent" [innerHTML]="dis_diag"> </div>
                                        <!-- <textarea class="ipcss dischargeContent" maxlength="500" ></textarea> -->
                                    </div>
                                </div>
                            </div>
                            <div class="col-6" *ngIf="complaints != ''">
                                <div class="row">
                                    <div class="col-12 margin_10">
                                        <mat-label class="matlabel">Complaints
                                        </mat-label>
                                    </div>
                                    <div class="col-12">
                                        <div class="ipcss dischargeContent" [innerHTML]="complaints"> </div>

                                    </div>
                                </div>
                            </div>

                            <div class="col-6" *ngIf="medical_hist != ''">
                                <div class="row">
                                    <div class="col-12 margin_10">
                                        <mat-label class="matlabel">Previous Medical History
                                        </mat-label>
                                    </div>
                                    <div class="col-12">
                                        <div class="ipcss dischargeContent" [innerHTML]="medical_hist"> </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-6" *ngIf="physical_exam != ''">
                                <div class="row">
                                    <div class="col-12 margin_10">
                                        <mat-label class="matlabel">Physical Examination
                                        </mat-label>
                                    </div>
                                    <div class="col-12">
                                        <div class="ipcss dischargeContent" [innerHTML]="physical_exam"> </div>

                                    </div>
                                </div>
                            </div>

                            <div class="col-6" *ngIf="Treatmetnt_done != ''">
                                <div class="row">
                                    <div class="col-12 margin_10">
                                        <mat-label class="matlabel">Treatment/Procedure done
                                        </mat-label>
                                    </div>
                                    <div class="col-12">
                                        <div class="ipcss dischargeContent" [innerHTML]="Treatmetnt_done"> </div>

                                    </div>
                                </div>
                            </div>

                            <div class="col-6" *ngIf="hosp_course != ''">
                                <div class="row">
                                    <div class="col-12 margin_10">
                                        <mat-label class="matlabel">Summary Course in Hospital
                                        </mat-label>
                                    </div>
                                    <div class="col-12">
                                        <div class="ipcss dischargeContent" [innerHTML]="hosp_course"> </div>

                                    </div>
                                </div>
                            </div>

                            <div class="col-6" *ngIf="investigation != ''">
                                <div class="row">
                                    <div class="col-12 margin_10">
                                        <mat-label class="matlabel">Investigation
                                        </mat-label>
                                    </div>
                                    <div class="col-12">
                                        <div class="ipcss dischargeContent" [innerHTML]="investigation"> </div>

                                    </div>
                                </div>
                            </div>

                            <div class="col-6" *ngIf="ot_notes != ''">
                                <div class="row">
                                    <div class="col-12 margin_10">
                                        <mat-label class="matlabel">OT Notes
                                        </mat-label>
                                    </div>
                                    <div class="col-12">
                                        <div class="ipcss dischargeContent" [innerHTML]="ot_notes"> </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-6" *ngIf="emergency_notes != ''">
                                <div class="row">
                                    <div class="col-12 margin_10">
                                        <mat-label class="matlabel">In-case of Emergency
                                        </mat-label>
                                    </div>
                                    <div class="col-12">
                                        <div class="ipcss dischargeContent" [innerHTML]="emergency_notes"> </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6" *ngIf="hospital_followup != ''">
                                <div class="row">
                                    <div class="col-12 margin_10">
                                        <mat-label class="matlabel">Discharge advice
                                        </mat-label>
                                    </div>
                                    <div class="col-12">
                                        <div class="ipcss dischargeContent" [innerHTML]="hospital_followup"> </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-3 col-md-3 col-xl-2 col-lg-2" style="width: 9%;">
                                        <mat-label class="matlabel">Next Visit</mat-label>
                                    </div>
                                    <div class="col-6 col-md-6 col-xl-4 col-lg-4">
                                        {{next_data}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="row" *ngIf="druglist.length !=0">
                                    <div class="col-12">
                                        <table id="tbl" cellpadding="5" cellspacing="0">
                                            <tr>
                                                <th>Medicine</th>
                                                <th>Dosage</th>
                                                <th>Days</th>
                                            </tr>
                                            <tr *ngFor="let medicine of druglist">
                                                <td>{{medicine.medicine_name}}</td>
                                                <td>{{medicine.medicine_dosage}}</td>
                                                <td>{{medicine.medicine_intakedays+" - "+medicine.medicine_food}}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>

<!-- print section -->
<div id="print" hidden>
    <div #printlogowithname id="printlogowithname">
        <table style="border:'0';width:100%">  
            <thead>  
             <tr>  
              <th style="width:100%;height: 100px;">
                <table style="width: 100%;margin-top: -100px;">
                    <tr>
                        <td style="display: flex;justify-content: center;">
                            <div>
                                <img alt="image" src={{hospital_logo}} style='{{dis_style}}'>
                            </div>
                            <div style="position: relative;top: 20px;left: 10px;text-align: left;">
                                <span><b>{{hosp_name}}</b>,</span><br />
                                <span *ngIf="hosp_addr != ''">{{hosp_addr}}</span><br />
                                <span>{{hosp_csz}}</span><br />
                                <span *ngIf="hosp_tel != undefined"><img src="../../../assets/img/phone.png"
                                        width="15px" height="15px" />{{hosp_tel}}.</span><br>
                            </div>
                        </td>
                    </tr>
                </table>
            </th>  
            </tr>  
           </thead>  
           <tfoot> 
            <tr>  
             <td width="100%">  
              <table style="border:'0';width:100%">  
                <tr>  
                  <td style="width:100%;height: 100px;">&nbsp;</td>  
                </tr>  
             </table>  
           </tfoot> 
           <tbody>  
             <tr>  
               <td width="100%">  
                <div class="bill_border">
                    <div>
                        <table style="width: 90%; margin: 0 auto;">
                            <tr>
                                <td style="width: 50%;">
                                    <div class="row">
                                        <div class="col-12 col-md-7 col-lg-8 col-xl-8">
                                            <span><strong>Patient name : </strong>{{patient_name}} </span>
                                            <br />
                                            <span><strong>Inpatient ID : </strong>{{inpId}} </span>
                                            <br />
                                            <span><strong> UHID : </strong>{{patient_identifier}}</span>
                                            <br />
                                            <span><strong> Consultant(s) : </strong>
                                                <table class="table1" style="margin-left: 50px;">
                                                    <tr *ngFor="let name of consultarray">
                                                        <td style="text-align: left;">{{name.name}}</td>
                                                    </tr>
                                                </table>
                                            </span>
                                        </div>
                                    </div>
                                </td>
            
                                <td style="width: 50%;" valign="top">
                                    <div class="row" style="margin-left: 60px;">
                                        <div class="amount_due col-12 col-md-5 col-lg-4 col-xl-5">
                                            <span><strong>Date of admission: </strong>{{admis_date}} {{admis_time}}</span><br />
                                            <span><strong>Date of discharge : </strong>{{dis_date}} {{dis_time}}</span><br />
                                            <span><strong>Age/gender : </strong>{{age}}/{{gender}} </span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </table>
                        
                        <div class="block1_grid">
                            <div style="margin-bottom: 10px;" *ngIf="dis_diag_flag">
                                <mat-label class="matlabel" style="font-weight: 600;">Discharge Diagnosis :</mat-label>
                                <div style="margin-top:-15px;text-align: justify;" [innerHTML]="dis_diag">
                                </div>
                            </div>
            
                            <div style="margin-bottom: 10px;" *ngIf="treat_flag">
                                <mat-label class="matlabel" style="font-weight: 600;">Treatment/Procedure done : </mat-label>
                                <div style="margin-top:-15px;text-align: justify;"  [innerHTML]="Treatmetnt_done"></div>
                            </div>
                           
                            <div style="margin-bottom: 10px;" *ngIf="comp_flag">
                                <mat-label class="matlabel" style="font-weight: 600;">Complaints : </mat-label>
                                <div style="text-align: justify;"  [innerHTML]="complaints"></div>
                            </div>
            
                            <div style="margin-bottom: 10px;" *ngIf="prev_med_flag">
                                <mat-label class="matlabel" style="font-weight: 600;">Previous Medical History : </mat-label>
                                <div style="text-align: justify;" [innerHTML]="medical_hist">
                                </div>
                            </div>
                            
                            <div style="margin-bottom: 10px;" *ngIf="phy_exam_flag">
                                <mat-label class="matlabel" style="font-weight: 600;">Physical Examination : </mat-label>
                                <div style="text-align: justify;" [innerHTML]="physical_exam">
                                </div>
                            </div>
                        </div>
                        
                        <div class="block1_grid">
                            <div style="margin-bottom: 10px;" *ngIf="inv_flag">
                                <mat-label class="matlabel" style="font-weight: 600;">Investigation : </mat-label>
                                <div style="margin-top:-15px;text-align: justify;" [innerHTML]="investigation">
                                </div>
                            </div>
                           
                            <div style="margin-bottom: 10px;" *ngIf="surgery_date">
                                <mat-label class="matlabel" style="font-weight: 600;">Operative Notes : </mat-label>
                                <div style="margin-bottom: 5px;" >
                                    <span><strong>Surgery Date : </strong>{{surgery_date}} </span><br />
                                    <span><strong>Surgery : </strong>{{surgery_proc}} </span><br />
                                    <span><strong>Surgeon : </strong><table>
                                        <tr *ngFor="let surgeon of surgeon_list;">
                                            <td style="text-align: left;" class="matlabel">{{surgeon.name}}</td>
                                        </tr>
                                    </table></span><br />
                                    <span><strong>Anaestetist : </strong><table>
                                        <tr *ngFor="let anaest of anaestetist;">
                                            <td style="text-align: left;" class="matlabel">{{anaest.name}}</td>
                                        </tr>
                                    </table></span><br />
                                    <span><strong>Anaestesia : </strong>{{anaestetype}} </span><br />
                                    <span><strong>Findings : </strong>{{anaest_findings}} </span><br />
                                    <span><strong>Procedure done : </strong>{{anaest_proc}} </span><br />
                                </div>
                            </div>
                            
                            <div style="margin-bottom: 10px;" *ngIf="sum_course_flag">
                                <mat-label class="matlabel" style="font-weight: 600;"> Summary Course in Hospital : </mat-label>
                                <div style="margin-top:-15px;text-align: justify;" [innerHTML]="hosp_course">
                                </div>
                            </div>
                            
                            <div style="margin-bottom: 10px;" *ngIf="dis_adv_flag">
                                <mat-label class="matlabel" style="font-weight: 600;">Discharge Advice : </mat-label>
                                <div style="margin-top:-15px;text-align: justify;" [innerHTML]="hospital_followup">
                                </div>
                            </div>
                        </div>
                        <div style="margin-left: 40px;" *ngIf="druglist.length !=0">
                            <div class="col-12">
                                <table id="tbl" cellpadding="5" cellspacing="0">
                                    <tr>
                                        <th>Medicine</th>
                                        <th>Dosage</th>
                                        <th>Days</th>
                                    </tr>
                                    <tr *ngFor="let medicine of druglist">
                                        <td>{{medicine.medicine_name}}</td>
                                        <td>{{medicine.medicine_dosage}}</td>
                                        <td>{{medicine.medicine_intakedays+" - "+medicine.medicine_food}}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <br/>
                        <div style="width: 90%; margin: 0 auto;">
                            <mat-label class="matlabel"> <b>Next Visit :</b> {{next_data}}</mat-label>
                            <br/>
                            <br/>
                            <mat-label class="matlabel"> <b>In case of emergency contact :</b> {{hosp_tel}}</mat-label>
                        </div>
                    </div>
                </div>
                <br />
                <table style="width: 100%; margin-top: 80px;">
                    <tr>
                        <td style="width: 50%;"></td>
                        <td style="width: 50%;">
                            <p style="text-align: center;font-size: 13px"><b>( Dr. {{doctor_name}} )</b></p>
                        </td>
                    </tr>
                </table>
               </td>  
            </tr> 
          </tbody>  
        </table>
    </div>

    <div #printbanner id="printbanner">
        <table style="border:'0';width:100%">  
            <thead>  
             <tr>  
              <th style="width:100%;height: 100px;">
                <table style="margin-left:10px;margin-top:-100px;margin-right: 10px;">
                    <tr>
                       <td>
                          <img alt="image" src={{hptl_dis_logo}} style='{{dis_style}}'>
                       </td>
                    </tr>
                </table>
              </th>  
            </tr>  
           </thead>  
           <tfoot> 
            <tr>  
             <td width="100%">  
              <table style="border:'0';width:100%">  
                <tr>  
                  <td style="width:100%;height: 100px;">&nbsp;</td>  
               </tr>  
             </table>  
           </tfoot> 
           <tbody>  
             <tr>  
               <td width="100%">  
                <div >
                    
                     <table style="width: 90%; margin: 0 auto;">
                        <tr>
                            <td colspan="2">
                              <div [innerHTML]="headerstyle"></div>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 50%;">
                                <div class="row">
                                    <div class="col-12 col-md-7 col-lg-8 col-xl-8">
                                        <span><strong>Patient name : </strong>{{patient_name}} </span>
                                        <br />
                                        <span><strong>Inpatient ID : </strong>{{inpId}} </span>
                                        <br />
                                        <span><strong> UHID : </strong>{{patient_identifier}}</span>
                                        <br />
                                        <span><strong> Consultant(s) : </strong>
                                            <table class="table1" style="margin-left: 50px;">
                                                <tr *ngFor="let name of consultarray">
                                                    <td style="text-align: left;">{{name.name}}</td>
                                                </tr>
                                            </table>
                                        </span>
                                    </div>
                                </div>
                            </td>
        
                            <td style="width: 50%;" valign="top">
                                <div class="row" style="margin-left: 60px;">
                                    <div class="amount_due col-12 col-md-5 col-lg-4 col-xl-5">
                                        <span><strong>Date of admission: </strong>{{admis_date}} {{admis_time}}</span><br />
                                        <span><strong>Date of discharge : </strong>{{dis_date}} {{dis_time}}</span><br />
                                        <span><strong>Age/gender : </strong>{{age}}/{{gender}} </span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </table>
        
                    <div class="block1_grid">
                        <div style="margin-bottom: 10px;" *ngIf="dis_diag_flag">
                            <mat-label class="matlabel" style="font-weight: 600;">Discharge Diagnosis :</mat-label>
                            <div style="margin-top:-15px;text-align: justify;" [innerHTML]="dis_diag">
                            </div>
                        </div>
        
                        <div style="margin-bottom: 10px;" *ngIf="treat_flag">
                            <mat-label class="matlabel" style="font-weight: 600;">Treatment/Procedure done : </mat-label>
                            <div style="margin-top:-15px;text-align: justify;"  [innerHTML]="Treatmetnt_done"></div>
                        </div>
                       
                        <div style="margin-bottom: 10px;" *ngIf="comp_flag">
                            <mat-label class="matlabel" style="font-weight: 600;">Complaints : </mat-label>
                            <div style="text-align: justify;"  [innerHTML]="complaints"></div>
                        </div>
        
                        <div style="margin-bottom: 10px;" *ngIf="prev_med_flag">
                            <mat-label class="matlabel" style="font-weight: 600;">Previous Medical History : </mat-label>
                            <div style="text-align: justify;" [innerHTML]="medical_hist">
                            </div>
                        </div>
                        
                        <div style="margin-bottom: 10px;" *ngIf="phy_exam_flag">
                            <mat-label class="matlabel" style="font-weight: 600;">Physical Examination : </mat-label>
                            <div style="text-align: justify;" [innerHTML]="physical_exam">
                            </div>
                        </div>
                    </div>
                    
                    <div class="block1_grid">
                        <div style="margin-bottom: 10px;" *ngIf="inv_flag">
                            <mat-label class="matlabel" style="font-weight: 600;">Investigation : </mat-label>
                            <div style="margin-top:-15px;text-align: justify;" [innerHTML]="investigation">
                            </div>
                        </div>
                       
                        <div style="margin-bottom: 10px;" *ngIf="surgery_date">
                            <mat-label class="matlabel" style="font-weight: 600;">Operative Notes : </mat-label>
                            <div style="margin-bottom: 5px;" >
                                <span><strong>Surgery Date : </strong>{{surgery_date}} </span><br />
                                <span><strong>Surgery : </strong>{{surgery}} </span><br />
                                <span><strong>Surgery procedure : </strong>{{surgery_proc}} </span><br />
                                <span><strong>Surgeon : </strong><table>
                                    <tr *ngFor="let surgeon of surgeon_list;">
                                        <td style="text-align: left;" class="matlabel">{{surgeon.name}}</td>
                                    </tr>
                                </table></span><br />
                                <span><strong>Anaestetist : </strong><table>
                                    <tr *ngFor="let anaest of anaestetist;">
                                        <td style="text-align: left;" class="matlabel">{{anaest.name}}</td>
                                    </tr>
                                </table> </span><br />
                                <span><strong>Anaestesia : </strong>{{anaestetype}} </span><br />
                                <span><strong>Findings : </strong>{{anaest_findings}} </span><br />
                                <span><strong>Procedure done : </strong>{{anaest_proc}} </span><br />
                            </div>
                        </div>
                        
                        <div style="margin-bottom: 10px;" *ngIf="sum_course_flag">
                            <mat-label class="matlabel" style="font-weight: 600;"> Summary Course in Hospital : </mat-label>
                            <div style="margin-top:-15px;text-align: justify;" [innerHTML]="hosp_course">
                            </div>
                        </div>
                        
                        <div style="margin-bottom: 10px;" *ngIf="dis_adv_flag">
                            <mat-label class="matlabel" style="font-weight: 600;">Discharge Advice : </mat-label>
                            <div style="margin-top:-15px;text-align: justify;" [innerHTML]="hospital_followup">
                            </div>
                        </div>
                    </div>
        
                    <div style="margin-left: 40px;" *ngIf="druglist.length !=0">
                        <div class="col-12">
                            <table id="tbl" cellpadding="5" cellspacing="0">
                                <tr>
                                    <th>Medicine</th>
                                    <th>Dosage</th>
                                    <th>Days</th>
                                </tr>
                                <tr *ngFor="let medicine of druglist">
                                    <td>{{medicine.medicine_name}}</td>
                                    <td>{{medicine.medicine_dosage}}</td>
                                    <td>{{medicine.medicine_intakedays+" - "+medicine.medicine_food}}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <br/>
                    <div style="width: 90%; margin: 0 auto;">
                        <mat-label class="matlabel"> <b>Next Visit :</b> {{next_data}}</mat-label>
                        <br/>
                        <br/>
                        <mat-label class="matlabel"> <b>In case of emergency contact :</b> {{hosp_tel}}</mat-label>
                    </div>
                </div>
                <br />
                <table style="width: 100%; margin-top: 80px;">
                    <tr>
                        <td style="width: 50%;"></td>
                        <td style="width: 50%;">
                            <p style="text-align: center;font-size: 13px"><b>( Dr. {{doctor_name}} )</b></p>
                        </td>
                    </tr>
                </table>
               </td>  
            </tr> 
          </tbody>  
        </table>
    </div>
    
    <div #printnoheader id="printnoheader">
        <table style="border:'0';width:100%">  
            <thead>  
             <tr>  
              <th style="width:100%;height: 100px;"></th>  
            </tr>  
           </thead>  
           <tfoot> 
            <tr>  
             <td width="100%">  
              <table style="border:'0';width:100%">  
                <tr>  
                  <td style="width:100%;height: 100px;">&nbsp;</td>  
               </tr>  
             </table>  
           </tfoot> 
           <tbody>  
             <tr>  
               <td width="100%">  
                <div >
                    <table style="margin-left:10px;margin-top:-10px;margin-right: 10px;">
                        <tr style="margin-top: 80px;"></tr>
                     </table>
                     <br /><br />
                     <table style="width: 90%; margin: 0 auto;">
                        <tr>
                            <td colspan="2">
                              <div [innerHTML]="headerstyle"></div>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 50%;">
                                <div class="row">
                                    <div class="col-12 col-md-7 col-lg-8 col-xl-8">
                                        <span><strong>Patient name : </strong>{{patient_name}} </span>
                                        <br />
                                        <span><strong>Inpatient ID : </strong>{{inpId}} </span>
                                        <br />
                                        <span><strong> UHID : </strong>{{patient_identifier}}</span>
                                        <br />
                                        <span><strong> Consultant(s) : </strong>
                                            <table class="table1" style="margin-left: 50px;">
                                                <tr *ngFor="let name of consultarray">
                                                    <td style="text-align: left;">{{name.name}}</td>
                                                </tr>
                                            </table>
                                        </span>
                                    </div>
                                </div>
                            </td>
        
                            <td style="width: 50%;" valign="top">
                                <div class="row" style="margin-left: 60px;">
                                    <div class="amount_due col-12 col-md-5 col-lg-4 col-xl-5">
                                        <span><strong>Date of admission: </strong>{{admis_date}} {{admis_time}}</span><br />
                                        <span><strong>Date of discharge : </strong>{{dis_date}} {{dis_time}}</span><br />
                                        <span><strong>Age/gender : </strong>{{age}}/{{gender}} </span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </table>
        
                    <div class="block1_grid">
                        <div style="margin-bottom: 10px;" *ngIf="dis_diag_flag">
                            <mat-label class="matlabel" style="font-weight: 600;">Discharge Diagnosis :</mat-label>
                            <div style="margin-top:-15px;text-align: justify;" [innerHTML]="dis_diag">
                            </div>
                        </div>
        
                        <div style="margin-bottom: 10px;" *ngIf="treat_flag">
                            <mat-label class="matlabel" style="font-weight: 600;">Treatment/Procedure done : </mat-label>
                            <div style="margin-top:-15px;text-align: justify;"  [innerHTML]="Treatmetnt_done"></div>
                        </div>
                       
                        <div style="margin-bottom: 10px;" *ngIf="comp_flag">
                            <mat-label class="matlabel" style="font-weight: 600;">Complaints : </mat-label>
                            <div style="text-align: justify;"  [innerHTML]="complaints"></div>
                        </div>
        
                        <div style="margin-bottom: 10px;" *ngIf="prev_med_flag">
                            <mat-label class="matlabel" style="font-weight: 600;">Previous Medical History : </mat-label>
                            <div style="text-align: justify;" [innerHTML]="medical_hist">
                            </div>
                        </div>
                        
                        <div style="margin-bottom: 10px;" *ngIf="phy_exam_flag">
                            <mat-label class="matlabel" style="font-weight: 600;">Physical Examination : </mat-label>
                            <div style="text-align: justify;" [innerHTML]="physical_exam">
                            </div>
                        </div>
                    </div>
                    
                    <div class="block1_grid">
                        <div style="margin-bottom: 10px;" *ngIf="inv_flag">
                            <mat-label class="matlabel" style="font-weight: 600;">Investigation : </mat-label>
                            <div style="margin-top:-15px;text-align: justify;" [innerHTML]="investigation">
                            </div>
                        </div>
                       
                        <div style="margin-bottom: 10px;" *ngIf="surgery_date">
                            <mat-label class="matlabel" style="font-weight: 600;">Operative Notes : </mat-label>
                            <div style="margin-bottom: 5px;" >
                                <span><strong>Surgery Date : </strong>{{surgery_date}} </span><br />
                                <span><strong>Surgery : </strong>{{surgery}} </span><br />
                                <span><strong>Surgery procedure : </strong>{{surgery_proc}} </span><br />
                                <span><strong>Surgeon : </strong><table>
                                    <tr *ngFor="let surgeon of surgeon_list;">
                                        <td style="text-align: left;" class="matlabel">{{surgeon.name}}</td>
                                    </tr>
                                </table></span><br />
                                <span><strong>Anaestetist : </strong><table>
                                    <tr *ngFor="let anaest of anaestetist;">
                                        <td style="text-align: left;" class="matlabel">{{anaest.name}}</td>
                                    </tr>
                                </table> </span><br />
                                <span><strong>Anaestesia : </strong>{{anaestetype}} </span><br />
                                <span><strong>Findings : </strong>{{anaest_findings}} </span><br />
                                <span><strong>Procedure done : </strong>{{anaest_proc}} </span><br />
                            </div>
                        </div>
                        
                        <div style="margin-bottom: 10px;" *ngIf="sum_course_flag">
                            <mat-label class="matlabel" style="font-weight: 600;"> Summary Course in Hospital : </mat-label>
                            <div style="margin-top:-15px;text-align: justify;" [innerHTML]="hosp_course">
                            </div>
                        </div>
                        
                        <div style="margin-bottom: 10px;" *ngIf="dis_adv_flag">
                            <mat-label class="matlabel" style="font-weight: 600;">Discharge Advice : </mat-label>
                            <div style="margin-top:-15px;text-align: justify;" [innerHTML]="hospital_followup">
                            </div>
                        </div>
                    </div>
        
                    <div style="margin-left: 40px;" *ngIf="druglist.length !=0">
                        <div class="col-12">
                            <table id="tbl" cellpadding="5" cellspacing="0">
                                <tr>
                                    <th>Medicine</th>
                                    <th>Dosage</th>
                                    <th>Days</th>
                                </tr>
                                <tr *ngFor="let medicine of druglist">
                                    <td>{{medicine.medicine_name}}</td>
                                    <td>{{medicine.medicine_dosage}}</td>
                                    <td>{{medicine.medicine_intakedays+" - "+medicine.medicine_food}}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <br/>
                    <div style="width: 90%; margin: 0 auto;">
                        <mat-label class="matlabel"> <b>Next Visit :</b> {{next_data}}</mat-label>
                        <br/>
                        <br/>
                        <mat-label class="matlabel"> <b>In case of emergency contact :</b> {{hosp_tel}}</mat-label>
                    </div>
                </div>
                <br />
                <table style="width: 100%; margin-top: 80px;">
                    <tr>
                        <td style="width: 50%;"></td>
                        <td style="width: 50%;">
                            <p style="text-align: center;font-size: 13px"><b>( Dr. {{doctor_name}} )</b></p>
                        </td>
                    </tr>
                </table>
               </td>  
            </tr> 
          </tbody>  
        </table>  
    </div>
</div>
<!-- end of print section -->