<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Prescription list</h5>
          </div>
          <div class="headerButtons">
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row">
          <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-2 select_bottom" *ngIf="repflag">
            <mat-label class="matlabel">Date<br>
              <input type="date" class="ipcss_date" id="appt_date" (change)="selectAppointmentDate(appointmentDate)"
                [(ngModel)]="appointmentDate" max="{{currentDate}}" #matInput style="width: 140px;margin-bottom: 10px;">
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 select_bottom" *ngIf="doctorflag">
            <mat-label class="matlabel">Doctor<br>
              <select disableOptionCentering class="ipcss widthappt" [(ngModel)]="doctor"
                (change)="changeDoctor(doctor)">
                <option *ngFor="let doctors of doctorList" value={{doctors.doc_id}}>
                  {{doctors.doc_name}}</option>
              </select>
            </mat-label>
          </div>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <p class="nodata" *ngIf="medPresList.length == 0">No Prescription(s) Found</p>
          <table *ngIf="medPresList.length" mdbTable datatable [dtOptions]="dtOptions"
            class="table table-nowrap table-sm dataTable">
            <thead>
              <tr>
                <th>Image</th>
                <th>Doctor</th>
                <th>Patient's name</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let prescription of medPresList; let i = index">
                <td style="padding: 0px 10px;"  (click)="viewPrescription(prescription.pres_drug_id)"><img src="{{prescription.prof_image}}" class="img-fluid z-depth-1 rounded-circle mr-1"></td>
                <td style="font-size: 12px; text-align: left;padding: 0px 10px;"  (click)="viewPrescription(prescription.pres_drug_id)"> {{ prescription.doctorname }}</td>
                <td style="font-size: 12px;text-align: left;padding: 0px 10px;"  (click)="viewPrescription(prescription.pres_drug_id)">{{ prescription.clientname }}</td>
                <td style="padding: 0px 10px;"  (click)="viewPrescription(prescription.pres_drug_id)">
                  <img *ngIf="editflag && editbutton" src="../../../assets/img/edit.png" class="billing_edit"
                    style="z-index: 9;" alt="" (click)="editPrescription(prescription.pres_drug_id,prescription)">
                  <!-- <img *ngIf="viewbutton" src="../../../assets/ui_icons/search_icon.svg" width="20px"
                    /> -->
                </td>
              </tr>
            </tbody>
          </table>
          <br><br><br>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>