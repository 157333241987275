import { Component, OnInit } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { MatDialog } from '@angular/material/dialog';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { CommonDataService } from '../../providers/common-data.service';
import { Router } from '@angular/router';
import { ClientViewService } from '../clientview/client-view.service';
import { Client_Helper } from '../Client_helper';
import { ipaddress } from '../../ipaddress';

@Component({
  selector: 'app-relationlist',
  templateUrl: './relationlist.component.html',
  styleUrls: ['./relationlist.component.css']
})
export class RelationlistComponent implements OnInit {
  public relationUrl: string;
  public sendClientRegId;
  public relationListData = [];
  public relationName: string;
  public relationFName: string;
  public relationMName: string;
  public relationLName: string;
  public subRelId: string;
  public mobileNo: string;
  public address1: string;
  public address2: string;
  public bgrpId: string;
  public bgrpDesc: string;
  public zipcode: string;
  public dob: string;
  public currentYear;
  public currentDate;
  public currentTime
public createReationFlag:boolean = false;
  constructor(public gservice: CommonDataService, public clientservice: ClientViewService, public http: Http, public router: Router, public dialog: MatDialog) {
    this.relationUrl = ipaddress.getIp + "usercontroller/grel/";
    if (this.gservice.Client_login_detail_data.Client_Id != undefined) {
      this.sendClientRegId = {
        client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
      }
    }
  }
  ngOnInit(): void {
    this.getRelation();
  }
  
  getRelation() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.relationUrl, JSON.stringify(this.sendClientRegId), { headers: headers }).subscribe(
      data => {
        var obj1 =  JSON.parse(JSON.stringify(data.json()));
        if (data.json().relations != null || (data.json().relations != null && data.json().relations.length != 0)) {
          for (var f = 0; f < data.json().relations.length; f++) {
            var obj = data.json().relations[f];
            Client_Helper.setdetails(obj);
            this.subRelId = null;
            this.mobileNo = null;
            var same_self = "0";
            this.address1 = "";
            this.relationFName = "";
            this.relationMName = "";
            this.relationLName = "";
            this.zipcode = "";
            this.dob = "";
            this.bgrpId = undefined;
            this.bgrpDesc = undefined;
            if (obj.sub_rel_id != null) {
              this.subRelId = obj.sub_rel_id;
            }
            if (obj.middle_name != null) {
              this.relationFName = encrypt_decript.Decript(obj.first_name.toString());
              this.relationMName = encrypt_decript.Decript(obj.middle_name.toString());
              this.relationLName = encrypt_decript.Decript(obj.last_name.toString());
              this.relationName = encrypt_decript.Decript(obj.first_name.toString()) + " " + encrypt_decript.Decript(obj.middle_name.toString()) + " " + encrypt_decript.Decript(obj.last_name.toString());
            } else {
              this.relationFName = encrypt_decript.Decript(obj.first_name.toString());
              this.relationLName = encrypt_decript.Decript(obj.last_name.toString());
              this.relationName = encrypt_decript.Decript(obj.first_name.toString()) + " " + encrypt_decript.Decript(obj.last_name.toString());
            }
            if (obj.mobile != null) {
              this.mobileNo = encrypt_decript.Decript(obj.mobile.toString());
            }
            if (obj.dob != undefined) {
              this.dob = obj.dob.split('-')[2] + "-" + obj.dob.split('-')[1] + "-" + obj.dob.split('-')[0];
            } else {
              this.dob = undefined;
            }
            if (obj.address1 != null) {
              this.address1 = encrypt_decript.Decript(obj.address1.toString());
            }
            if (obj.address2 != undefined) {
              this.address2 = encrypt_decript.Decript(obj.address2.toString());
            }
            else {
              this.address2 = ""
            }
            if (obj.same_as_self != null) {
              same_self = obj.same_as_self;
            } else {
              same_self = "0";
            }
            if (obj.blood_group != undefined) {
              this.bgrpId = obj.blood_group;
            }
            if (obj.blood_desc != undefined) {
              this.bgrpDesc = obj.blood_desc;
            }
            if (obj.zipcode != null) {
              this.zipcode = encrypt_decript.Decript(obj.zipcode.toString());
            }
            var img = "assets/img/default.jpg", imgpath = "assets/img/default.jpg";
            if (obj.profile_image !== "default") {
              imgpath = obj.profile_image;
              img = ipaddress.Ip_with_img_address + obj.profile_image;
            }
            this.relationListData.push({
              rel_name: this.relationName,
              rel_f_name: this.relationFName,
              rel_m_name: this.relationMName,
              rel_l_name: this.relationLName,
              rel_id: obj.relation_id,
              sub_rel_id: this.subRelId,
              mobile: this.mobileNo,
              gender_val: encrypt_decript.Decript(obj.gender),
              prof_img: img,
              imgpath: imgpath,
              addr1: this.address1,
              addr2: this.address2,
              loc_id: obj.location,
              loc_name: obj.location_desc,
              city_id: obj.city,
              city_desc: obj.city_desc,
              state_id: obj.state,
              state_desc: obj.state_desc,
              country_id: obj.country,
              country_desc: obj.country_desc,
              zipcode: this.zipcode,
              update_flag: true,
              same_as_self: same_self,
              bgrp_id: this.bgrpId,
              bgrp_desc: this.bgrpDesc,
              dob: this.dob,
              salutation : obj.salutation,
            });
            Client_Helper.setrelationlistdata(this.relationListData);
          }
          this.createReationFlag =  this.relationListData.length >= 5 ? false : true ;
        }
      }, error => {
      }
    );
  }

  selectRelation(relation_name, relation_f_name, relation_m_name, relation_l_name, rel_id, sub_rel_id, mobile, gender_val, prof_img, addr1, addr2, loc_id, loc_name, city_id, city_desc, state_id, state_desc, country_id, country_desc, zipcode, same_as_self, bgrp_id, bgrp_desc, dob, imgpath,salutation) {
    Client_Helper.setrelationlist(null);
    if (dob != undefined) {
      var dobdate = dob.split('-');
      var dobformat = dobdate[2] + "-" + dobdate[1] + "-" + dobdate[0];
    } else {
      dobformat = undefined;
    }
    var relationlist = null;
    relationlist = {
      flow: false,
      rel_name: relation_name,
      rel_f_name: relation_f_name,
      rel_m_name: relation_m_name,
      rel_l_name: relation_l_name,
      relation_id: rel_id,
      sub_rel_id: sub_rel_id,
      mobile: mobile,
      gender_val: gender_val,
      prof_img: imgpath,
      addr1: addr1,
      addr2: addr2,
      loc_id: loc_id,
      loc_name: loc_name,
      city_id: city_id,
      city_desc: city_desc,
      state_id: state_id,
      state_desc: state_desc,
      country_id: country_id,
      country_desc: country_desc,
      zipcode: zipcode,
      same_as_self: same_as_self,
      blood_id: bgrp_id,
      blood_desc: bgrp_desc,
      dateofbirth: dobformat,
      type: "detailview",
      salutation : salutation
    }
    Client_Helper.setrelationlist(relationlist);
    this.clientservice.sendMessage("relationdetails");
  }

  create() {
    Client_Helper.setrelationlist(null);
    var relationlist = null;
    relationlist = {
      flow: true,
      type: "relationlistcreate"
    }
    Client_Helper.setrelationlist(relationlist);
    this.clientservice.sendMessage("relationdetails");
  }

  myCallbackFunction = function (this: any, _params) {
    return new Promise((resolve, reject) => {
      resolve(this.any);
    });
  }
}
