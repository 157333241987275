<div *ngIf="dischargeSummaryFlag == 'min'">
    <div class="dig_table_overflow" style="height: 200px;">
      <table id="case_summary_tbl_min" *ngIf="othersFlag" style="border: none;">
      <tr *ngIf="nameFlag">
        <th>Name</th>
        <td style="text-align: left;">{{fName}}</td>
      </tr>
      <tr *ngIf="genderFlag">
        <th>Gender</th>
        <td style="text-align: left;">{{gender}}</td>
      </tr>
      <tr *ngIf="ageFlag">
        <th>Age</th>
        <td style="text-align: left;">{{age}}</td>
      </tr>
      <tr *ngIf="occupationFlag">
        <th>Occupation</th>
        <td style="text-align: left;">{{occupation}}</td>
      </tr>
      <tr *ngIf="stressFactorFlag">
        <th>Stress factors</th>
        <td style="text-align: left;">{{stressFactor}}</td>
      </tr>
    </table>
    </div>
  </div>
  <div *ngIf="dischargeSummaryFlag == 'max'">
    <div class="Details">
        <div class="row">
          <div class="col-12 col-lg-12 col-md-12 col-sm-12 col-xl-12">
            <a class="print cardsave"><button printSectionId="print_case_summary" [useExistingCss]="true" ngxPrint class="borderdiv"
                class="print_btn" mat-icon-button>
                <img src="../../../assets/img/printer.svg" width="30px" height="30px">
              </button></a>
          </div>
        </div>
        <div class="row">
          <p *ngIf="othersFlag" class="tervys_heading">General</p>
          <p *ngIf="othersFlag">Personal history</p>
          <div class="col-12 col-lg-12 col-md-12 col-sm-12 col-xl-12" *ngIf="othersFlag">
            <table id="case_summary_tbl">
              <tr *ngIf="nameFlag">
                <th>Name</th>
                <td style="text-align: left;">{{fName}}</td>
              </tr>
              <tr *ngIf="genderFlag">
                <th>Gender</th>
                <td style="text-align: left;">{{gender}}</td>
              </tr>
              <tr *ngIf="ageFlag">
                <th>Age</th>
                <td style="text-align: left;">{{age}}</td>
              </tr>
              <tr *ngIf="occupationFlag">
                <th>Occupation</th>
                <td style="text-align: left;">{{occupation}}</td>
              </tr>
              <tr *ngIf="stressFactorFlag">
                <th>Stress factors</th>
                <td style="text-align: left;">{{stressFactor}}</td>
              </tr>
              <tr *ngIf="physicalActFlag">
                <th>Physical activities</th>
                <td style="text-align: left;">{{physicalAct}}</td>
              </tr>
              <tr *ngIf="sgaScoreFlag">
                <th>SGA score</th>
                <td style="text-align: left;">{{sgaScore}}</td>
              </tr>
              <tr *ngIf="heightFlag">
                <th>Height</th>
                <td style="text-align: left;">{{height}} &nbsp; {{heightMeasure}}</td>
              </tr>
              <tr *ngIf="weightFlag">
                <th>Weight</th>
                <td style="text-align: left;">{{weight}} &nbsp; {{weightMeasure}}</td>
              </tr>
              <tr *ngIf="bmiFlag">
                <th>BMI</th>
                <td style="text-align: left;">{{bmi}}</td>
              </tr>
              <tr *ngIf="medHistFlag">
                <th>Medical history</th>
                <td style="text-align: left;">{{medHist}}</td>
              </tr>
            </table>
          </div>
          <p *ngIf="habtiualInfoFlag" class="tervys_heading">Habitual history</p>
          <div class="col-12 col-lg-12 col-md-12 col-sm-12 col-xl-12" *ngIf="habtiualInfoFlag">
            <table id="case_summary_tbl" *ngIf="habtiualInfoFlag">
              <tr *ngIf="alcFreqFlag">
                <th>Alcohol Frequency/day</th>
                <td style="text-align: left;">{{alcFreq}}</td>
              </tr>
              <tr *ngIf="alcQtyFlag">
                <th>Alcohol qty</th>
                <td style="text-align: left;">{{alcQty}}</td>
              </tr>
              <tr *ngIf="smkFreqFlag">
                <th>Smoke frequency/day</th>
                <td style="text-align: left;">{{smkFreq}}</td>
              </tr>
              <tr *ngIf="gutkFreqFlag">
                <th>Gutka frequency/day</th>
                <td style="text-align: left;">{{gutkFreq}}</td>
              </tr>
              <tr *ngIf="exerDurationFlag">
                <th>Exercises duration</th>
                <td style="text-align: left;">{{exerDuration}}</td>
              </tr>
            </table>
          </div>
          <p *ngIf="gynecologyFlag" class="tervys_heading">Gynecology</p>
          <div class="col-12 col-lg-12 col-md-12 col-sm-12 col-xl-12" *ngIf="gynecologyFlag">
            <table id="case_summary_tbl" *ngIf="gynecologyFlag">
              <tr>
                <th>Visit purpose</th>
                <td style="text-align: left;">{{visitPurpose}}</td>
              </tr>
              <tr>
                <th>Main concern</th>
                <td style="text-align: left;">{{mainConcern}}</td>
              </tr>
              <tr>
                <th>Disease</th>
                <td style="text-align: left;">{{disease}}</td>
              </tr>
              <tr>
                <th>Present illness</th>
                <td style="text-align: left;">{{presentIllness}}</td>
              </tr>
              <tr>
                <th>Menstrual periods</th>
                <td style="text-align: left;">{{menstrualPeriods}}</td>
              </tr>
              <tr>
                <th>Icd10</th>
                <td style="text-align: left;">{{icdDesc}}</td>
              </tr>
            </table>
          </div>
          <p *ngIf="diabetesFlag" class="tervys_heading">Diabetes</p>
          <div class="col-12 col-lg-12 col-md-12 col-sm-12 col-xl-12" *ngIf="diabetesFlag">
            <table id="case_summary_tbl" *ngIf="diabetesFlag">
              <tr>
                <th>Concerns</th>
                <td style="text-align: left;">{{caseConcern}}</td>
              </tr>
              <tr>
                <th>Dietary habits</th>
                <td style="text-align: left;">{{caseDietaryHab}}</td>
              </tr>
              <tr>
                <th>Disease type</th>
                <td style="text-align: left;">{{diseaseType}}</td>
              </tr>
              <tr>
                <th>Icd10</th>
                <td style="text-align: left;">{{icdCode}}</td>
              </tr>
            </table>
          </div>
          <p *ngIf="nephrologyFlag" class="tervys_heading">Nephrology</p>
          <div class="col-12 col-lg-12 col-md-12 col-sm-12 col-xl-12" *ngIf="nephrologyFlag">
            <table id="case_summary_tbl" *ngIf="nephrologyFlag">
              <tr>
                <th>Dietary habits</th>
                <td style="text-align: left;">{{dietHabits}}</td>
              </tr>
              <tr>
                <th>Disease</th>
                <td style="text-align: left;">{{nephDisease}}</td>
              </tr>
              <tr>
                <th>ICD code</th>
                <td style="text-align: left;">{{nephIcd}}</td>
              </tr>
              <tr>
                <th>ICD description</th>
                <td style="text-align: left;">{{nephIcdDesc}}</td>
              </tr>
              <tr>
                <th>Treatement</th>
                <td style="text-align: left;">{{nephTreat}}</td>
              </tr>
              <tr>
                <th>Dialysis type</th>
                <td style="text-align: left;">{{nephDysType}}</td>
              </tr>
              <tr>
                <th>Dialysis frequency / type</th>
                <td style="text-align: left;">{{nephDysFreq}}</td>
              </tr>
              <tr>
                <th>Skin</th>
                <td style="text-align: left;">{{nephSkin}}</td>
              </tr>
              <tr>
                <th>Hearing</th>
                <td style="text-align: left;">{{nephHear}}</td>
              </tr>
              <tr>
                <th>Eye</th>
                <td style="text-align: left;">{{nephRetino}}</td>
              </tr>
              <tr>
                <th>Cardiovascular</th>
                <td style="text-align: left;">{{nephCardioVas}}</td>
              </tr>
              <tr>
                <th>Respiratory / pulmonary</th>
                <td style="text-align: left;">{{nephPulm}}</td>
              </tr>
              <tr>
                <th>Causes of disease</th>
                <td style="text-align: left;">{{nephCauseOfDis}}</td>
              </tr>
            </table>
          </div>
          <p *ngIf="pediatricsFlag" class="tervys_heading">Pediatrics</p>
          <div class="col-12 col-lg-12 col-md-12 col-sm-12 col-xl-12" *ngIf="pediatricsFlag">
            <table id="case_summary_tbl" *ngIf="pediatricsFlag">
              <tr>
                <th>Child name</th>
                <td style="text-align: left;">{{pediaChildName}}</td>
              </tr>
              <tr>
                <th>Mother age</th>
                <td style="text-align: left;">{{pediaMotherAge}}</td>
              </tr>
              <tr>
                <th>No of pregnancy</th>
                <td style="text-align: left;">{{pediaNoOfPreg}}</td>
              </tr>
              <tr>
                <th>Delivery type</th>
                <td style="text-align: left;">{{pediaDeliveryType}}</td>
              </tr>
              <tr>
                <th>Gestational age(weeks)</th>
                <td style="text-align: left;">{{pediaGestationAge}}</td>
              </tr>
              <tr>
                <th>Complications</th>
                <td style="text-align: left;">{{pediaComplication}}</td>
              </tr>
              <tr>
                <th>Living condition</th>
                <td style="text-align: left;">{{pediaLivingCondition}}</td>
              </tr>
              <tr>
                <th>Feeding habits</th>
                <td style="text-align: left;">{{pediaFeedingHabits}}</td>
              </tr>
              <tr>
                <th>Allergies</th>
                <td style="text-align: left;">{{pediaAllergies}}</td>
              </tr>
              <tr>
                <th>Diseases</th>
                <td style="text-align: left;">{{pediaDiseases}}</td>
              </tr>
              <tr>
                <th>ICD</th>
                <td style="text-align: left;">{{pediaIcd}}</td>
              </tr>
            </table>
          </div>
          <div [hidden]="gynaList.length==0" class="col-12 col-lg-12 col-md-12 col-sm-12 col-xl-12">
            <table cellpadding="5" cellspacing="0" id="case_sum_tbl">
              <tr>
                <th>Surgeries</th>
                <th style="text-align: center;">Date</th>
              </tr>
              <tr *ngFor="let surger of gynaList">
                <td>{{surger.Surgeries}}</td>
                <td>{{surger.Surgeries_date}}</td>
              </tr>
            </table>
          </div>
          <p *ngIf="footAssmtFlag" class="tervys_heading">Foot assessment</p>
          <div *ngIf="footAssmtFlag" class="col-12 col-lg-12 col-md-12 col-sm-12 col-xl-12">
            <table id="case_summary_tbl" *ngIf="diabetesFlag">
              <tr *ngIf="caseRightFlag">
                <th>Right</th>
                <td style="text-align: left;">{{caseRight}}</td>
              </tr>
              <tr *ngIf="caseLeftFlag">
                <th>Left</th>
                <td style="text-align: left;">{{caseLeft}}</td>
              </tr>
            </table>
          </div>
          <p *ngIf="complicationsFlag" class="tervys_heading">Complications</p>
          <div *ngIf="complicationsFlag" class="col-12 col-lg-12 col-md-12 col-sm-12 col-xl-12">
            <table id="case_summary_tbl" *ngIf="complicationsFlag">
              <tr *ngIf="caseCardioFlag">
                <th>Cardio</th>
                <td  style="text-align: left;">{{caseCardio}}</td>
              </tr>
              <tr *ngIf="caseNephroFlag">
                <th>Nephro</th>
                <td style="text-align: left;">{{caseNephro}}</td>
              </tr>
              <tr *ngIf="caseRetinoFlag">
                <th>Retino</th>
                <td style="text-align: left;">{{caseRetino}}</td>
              </tr>
              <tr *ngIf="caseMobilityFlag">
                <th>Mobility</th>
                <td style="text-align: left;">{{caseMobility}}</td>
              </tr>
            </table>
          </div>
          <p *ngIf="medPresForDiabFlag" class="tervys_heading">Medical Prescription</p>
          <div *ngIf="medPresForDiabFlag" class="col-12 col-lg-12 col-md-12 col-sm-12 col-xl-12">
            <table id="tbl">
              <thead>
                <tr>
                  <th>Medicine type</th>
                  <th>Medicine name</th>
                  <th>Days</th>
                  <th>Intake</th>
                  <th>Duration</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let diabmed of medPresListForDiab">
                  <td style="text-align:left;">{{diabmed.drug_type_name}}</td>
                  <td style="text-align:left;">{{diabmed.drug_name}}</td>
                  <td>{{diabmed.days}}</td>
                  <td>{{diabmed.quan_tx}}</td>
                  <td>{{diabmed.with_time+" "+diabmed.drug_intake}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <p [hidden]="diagTestFlag" class="tervys_heading">Diagnosis prescription</p>
          <div [hidden]="diagnosisForDiabFlag" class="col-12 col-lg-12 col-md-12 col-sm-12 col-xl-12">
            <table id="case_summary_tbl" [hidden]="diagTestFlag">
              <tr *ngFor="let diagpres of diagTestListForDiab">
                <td style="text-align:left;">{{diagpres.testself}}</td>
                <td style="text-align:left;">{{diagpres.test_name}}</td>
              </tr>
            </table>
          </div>
          <p *ngIf="medPresForNephFlag" class="tervys_heading">Medical prescriptions</p>
          <div *ngIf="medPresForNephFlag" class="col-12 col-lg-12 col-md-12 col-sm-12 col-xl-12">
            <table cellpadding="5" cellspacing="0" id="#tbl">
              <tr>
                <th>Medicine type</th>
                <th>Medicine name</th>
                <th>Days</th>
                <th>Intake</th>
                <th>Duration</th>
              </tr>
              <tr *ngFor="let nepgmed of medPresListForNeph">
                <td style="text-align:left;">{{nepgmed.drug_type_name}}</td>
                <td style="text-align:left;">{{nepgmed.drug_name}}</td>
                <td>{{nepgmed.days}}</td>
                <td>{{nepgmed.quan_tx}}</td>
                <td style="text-align:left;">{{nepgmed.with_time+" "+nepgmed.drug_intake}}</td>
              </tr>
            </table>
          </div>
          <div *ngIf="medPresForPediaFlag" class="col-12 col-lg-12 col-md-12 col-sm-12 col-xl-12">
            <table id="tbl">
              <thead>
                <tr>
                  <th>Medicine type</th>
                  <th>Medicine name</th>
                  <th>Days</th>
                  <th>Intake</th>
                  <th>Duration</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let diabmed of medPresListForPedia">
                  <td style="text-align:left;">{{diabmed.drug_type_name}}</td>
                  <td style="text-align:left;">{{diabmed.drug_name}}</td>
                  <td>{{diabmed.days}}</td>
                  <td>{{diabmed.quan_tx}}</td>
                  <td>{{diabmed.with_time+" "+diabmed.drug_intake}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
  </div>
  <!-- print sec case summ -->
  <div id="print_case_summary" hidden>
    <div class="Details">
      <p *ngIf="othersFlag">General</p>
      <p *ngIf="othersFlag">Personal history</p>
      <table id="case_summary_tbl" *ngIf="othersFlag">
        <tr *ngIf="nameFlag">
          <th>Name</th>
          <td>{{fName}}</td>
        </tr>
        <tr *ngIf="genderFlag">
          <th>Gender</th>
          <td>{{gender}}</td>
        </tr>
        <tr *ngIf="ageFlag">
          <th>Age</th>
          <td>{{age}}</td>
        </tr>
        <tr *ngIf="occupationFlag">
          <th>Occupation</th>
          <td>{{occupation}}</td>
        </tr>
        <tr *ngIf="stressFactorFlag">
          <th>Stress factors</th>
          <td>{{stressFactor}}</td>
        </tr>
        <tr *ngIf="physicalActFlag">
          <th>Physical activities</th>
          <td>{{physicalAct}}</td>
        </tr>
        <tr *ngIf="sgaScoreFlag">
          <th>SGA score</th>
          <td>{{sgaScore}}</td>
        </tr>
        <tr *ngIf="heightFlag">
          <th>Height</th>
          <td>{{height}} &nbsp; {{heightMeasure}}</td>
        </tr>
        <tr *ngIf="weightFlag">
          <th>Weight</th>
          <td>{{weight}} &nbsp; {{weightMeasure}}</td>
        </tr>
        <tr *ngIf="bmiFlag">
          <th>BMI</th>
          <td>{{bmi}}</td>
        </tr>
        <tr *ngIf="medHistFlag">
          <th>Medical history</th>
          <td>{{medHist}}</td>
        </tr>
      </table>
      <p *ngIf="habtiualInfoFlag">Habitual history</p>
      <table id="case_summary_tbl" *ngIf="habtiualInfoFlag">
        <tr *ngIf="alcFreqFlag">
          <th>Alcohol frequency/day</th>
          <td>{{alcFreq}}</td>
        </tr>
        <tr *ngIf="alcQtyFlag">
          <th>Alcohol qty</th>
          <td>{{alcQty}}</td>
        </tr>
        <tr *ngIf="smkFreqFlag">
          <th>Smoke frequency/day</th>
          <td>{{smkFreq}}</td>
        </tr>
        <tr *ngIf="gutkFreqFlag">
          <th>Gutka frequency/day</th>
          <td>{{gutkFreq}}</td>
        </tr>
        <tr *ngIf="exerDurationFlag">
          <th>Exercises duration</th>
          <td>{{exerDuration}}</td>
        </tr>
      </table>
      <p *ngIf="gynecologyFlag">Gynecology</p>
      <table id="case_summary_tbl" *ngIf="gynecologyFlag">
        <tr>
          <th>Visit purpose</th>
          <td>{{visitPurpose}}</td>
        </tr>
        <tr>
          <th>Main concern</th>
          <td>{{mainConcern}}</td>
        </tr>
        <tr>
          <th>Disease</th>
          <td>{{disease}}</td>
        </tr>
        <tr>
          <th>Present illness</th>
          <td>{{presentIllness}}</td>
        </tr>
        <tr>
          <th>Menstrual periods</th>
          <td>{{menstrualPeriods}}</td>
        </tr>
        <tr>
          <th>Icd10</th>
          <td>{{icdDesc}}</td>
        </tr>
      </table>
      <p *ngIf="diabetesFlag">Diabetes</p>
      <table id="case_summary_tbl" *ngIf="diabetesFlag">
        <tr>
          <th>Concerns</th>
          <td>{{caseConcern}}</td>
        </tr>
        <tr>
          <th>Dietary habits</th>
          <td>{{caseDietaryHab}}</td>
        </tr>
        <tr>
          <th>Disease type</th>
          <td>{{diseaseType}}</td>
        </tr>
        <tr>
          <th>Icd10</th>
          <td>{{icdCode}}</td>
        </tr>
      </table>
      <p *ngIf="nephrologyFlag">Nephrology</p>
      <table id="case_summary_tbl" *ngIf="nephrologyFlag">
        <tr>
          <th>Dietary habits</th>
          <td>{{dietHabits}}</td>
        </tr>
        <tr>
          <th>Disease</th>
          <td>{{nephDisease}}</td>
        </tr>
        <tr>
          <th>ICD code</th>
          <td>{{nephIcd}}</td>
        </tr>
        <tr>
          <th>ICD description</th>
          <td>{{nephIcdDesc}}</td>
        </tr>
        <tr>
          <th>Treatement</th>
          <td>{{nephTreat}}</td>
        </tr>
        <tr>
          <th>Dialysis type</th>
          <td>{{nephDysType}}</td>
        </tr>
        <tr>
          <th>Dialysis frequency / type</th>
          <td>{{nephDysFreq}}</td>
        </tr>
        <tr>
          <th>Skin</th>
          <td>{{nephSkin}}</td>
        </tr>
        <tr>
          <th>Hearing</th>
          <td>{{nephHear}}</td>
        </tr>
        <tr>
          <th>Eye</th>
          <td>{{nephRetino}}</td>
        </tr>
        <tr>
          <th>Cardiovascular</th>
          <td>{{nephCardioVas}}</td>
        </tr>
        <tr>
          <th>Respiratory / Pulmonary</th>
          <td>{{nephPulm}}</td>
        </tr>
        <tr>
          <th>Causes of disease</th>
          <td>{{nephCauseOfDis}}</td>
        </tr>
      </table>
      <p *ngIf="pediatricsFlag">Pediatrics</p>
      <table id="case_summary_tbl" *ngIf="pediatricsFlag">
        <tr>
          <th>Child name</th>
          <td>{{pediaChildName}}</td>
        </tr>
        <tr>
          <th>Mother age</th>
          <td>{{pediaMotherAge}}</td>
        </tr>
        <tr>
          <th>No of pregnancy</th>
          <td>{{pediaNoOfPreg}}</td>
        </tr>
        <tr>
          <th>Delivery type</th>
          <td>{{pediaDeliveryType}}</td>
        </tr>
        <tr>
          <th>Gestational age(weeks)</th>
          <td>{{pediaGestationAge}}</td>
        </tr>
        <tr>
          <th>Complications</th>
          <td>{{pediaComplication}}</td>
        </tr>
        <tr>
          <th>Living condition</th>
          <td>{{pediaLivingCondition}}</td>
        </tr>
        <tr>
          <th>Feeding habits</th>
          <td>{{pediaFeedingHabits}}</td>
        </tr>
        <tr>
          <th>Allergies</th>
          <td>{{pediaAllergies}}</td>
        </tr>
        <tr>
          <th>Diseases</th>
          <td>{{pediaDiseases}}</td>
        </tr>
        <tr>
          <th>ICD</th>
          <td>{{pediaIcd}}</td>
        </tr>
      </table>
    </div><br><br>
    <div [hidden]="gynaList.length==0">
      <table cellpadding="5" cellspacing="0" id="case_sum_tbl">
        <tr>
          <th>Surgeries</th>
          <th style="text-align: center;">Date</th>
        </tr>
        <tr *ngFor="let surger of gynaList">
          <td>{{surger.Surgeries}}</td>
          <td>{{surger.Surgeries_date}}</td>
        </tr>
      </table>
    </div>
    <div *ngIf="footAssmtFlag">
      <p>Foot assessment</p>
      <table id="case_summary_tbl" *ngIf="diabetesFlag">
        <tr *ngIf="caseRightFlag">
          <th>Right</th>
          <td>{{caseRight}}</td>
        </tr>
        <tr *ngIf="caseLeftFlag">
          <th>Left</th>
          <td>{{caseLeft}}</td>
        </tr>
      </table>
    </div>
    <div *ngIf="complicationsFlag">
      <p>Complications</p>
      <table id="case_summary_tbl" *ngIf="complicationsFlag">
        <tr *ngIf="caseCardioFlag">
          <th>Cardio</th>
          <td>{{caseCardio}}</td>
        </tr>
        <tr *ngIf="caseNephroFlag">
          <th>Nephro</th>
          <td>{{caseNephro}}</td>
        </tr>
        <tr *ngIf="caseRetinoFlag">
          <th>Retino</th>
          <td>{{caseRetino}}</td>
        </tr>
        <tr *ngIf="caseMobilityFlag">
          <th>Mobility</th>
          <td>{{caseMobility}}</td>
        </tr>
      </table>
    </div>
    <div *ngIf="medPresForDiabFlag">
      <p>Medical prescription</p>
      <table id="tbl">
        <thead>
          <tr>
            <th>Medicine type</th>
            <th>Medicine name</th>
            <th>Days</th>
            <th>Intake</th>
            <th>Duration</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let diabmed of medPresListForDiab">
            <td>{{diabmed.drug_type_name}}</td>
            <td>{{diabmed.drug_name}}</td>
            <td>{{diabmed.days}}</td>
            <td>{{diabmed.quan_tx}}</td>
            <td>{{diabmed.with_time+" "+diabmed.drug_intake}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div [hidden]="diagnosisForDiabFlag">
      <p [hidden]="diagTestFlag">Diagnosis prescription</p>
      <table id="case_summary_tbl" [hidden]="diagTestFlag">
        <tr *ngFor="let diagpres of diagTestListForDiab">
          <td>{{diagpres.testself}}</td>
          <td>{{diagpres.test_name}}</td>
        </tr>
      </table>
    </div>
    <div *ngIf="medPresForNephFlag">
      <p>Medical prescriptions</p>
      <table cellpadding="5" cellspacing="0" id="#tbl">
        <tr>
          <th>Medicine type</th>
          <th>Medicine name</th>
          <th>Days</th>
          <th>Intake</th>
          <th>Duration</th>
        </tr>
        <tr *ngFor="let nepgmed of medPresListForNeph">
          <td>{{nepgmed.drug_type_name}}</td>
          <td>{{nepgmed.drug_name}}</td>
          <td>{{nepgmed.days}}</td>
          <td>{{nepgmed.quan_tx}}</td>
          <td>{{nepgmed.with_time+" "+nepgmed.drug_intake}}</td>
        </tr>
      </table>
    </div>
    <div *ngIf="medPresForPediaFlag">
      <table id="tbl">
        <thead>
          <tr>
            <th>Medicine type</th>
            <th>Medicine name</th>
            <th>Days</th>
            <th>Intake</th>
            <th>Duration</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let diabmed of medPresListForPedia">
            <td>{{diabmed.drug_type_name}}</td>
            <td>{{diabmed.drug_name}}</td>
            <td>{{diabmed.days}}</td>
            <td>{{diabmed.quan_tx}}</td>
            <td>{{diabmed.with_time+" "+diabmed.drug_intake}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>