import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Helper_Class } from '../../helper_class';
import { ipaddress } from '../../ipaddress';
import { MenuViewService } from '../../../app/common-module/menu-view/menu-view.service';
import { Message_data } from 'src/assets/js/Message_data';

@Component({
  selector: 'app-outsource-test-map',
  templateUrl: './outsource-test-map.component.html',
  styleUrls: ['./outsource-test-map.component.scss']
})
export class OutsourceTestMapComponent implements OnInit {
  public diagid: string;
  public test_flag: boolean;

  public culturetest: boolean;
  public faecestest: boolean;
  public urinetest: boolean;
  public biopsytest: boolean;
  public xraytest: boolean;
  public ultratest: boolean;
  public scantest: boolean;
  public bloodtest: boolean;
  public checked: boolean;



  public subtestcname: any = [];
  public subtestfname: any = [];
  public subtesturname: any = [];
  public subtestbname: any = [];
  public subtestxname: any = [];
  public subtestuname: any = [];
  public subtestsname: any = [];
  public subtestname: any = [];
  public testtypename: any = [];
  public all_tests: any = [];

  public blood_test_head;
  public blood_test_content;
  public scan_test_head;
  public scan_test_content;
  public us_test_head;
  public us_test_content;
  public xray_test_head;
  public xray_test_content;
  public bio_test_content;
  public bio_test_head;
  public ut_test_content;
  public ut_test_head;
  public ft_test_head;
  public ft_test_content;
  public culture_test_head;
  public culture_test_content;
  public diag_centre_id: string;
  public oursource: any = [];

  public blood_test: any = [];
  public urine_test: any = [];
  public feace_test: any = [];
  public scan_test: any = [];
  public xray_test: any = [];
  public ultra_test: any = [];
  public culture_test: any = [];
  public biopsy_test: any = [];
  public isChecked: boolean = false;

  public labTest_array: any = [];
  public radioTest_array: any = [];
  public microTest_array: any = [];
  public profiles: any = [];
  public labarray: any = [];
  public radioarray: any = [];
  public LabtestName: any;
  public radiotestName: any;
  public microtestName: any;

  public filteredLabArray: any = []; 
  public filteredRadioArray: any = [];
  public filteredMicroArray: any = [];
  userinfo: any;
  hosp_id: any;
  

  constructor(public diagnosisservice: MenuViewService, public messageservice: MenuViewService, public toastr: ToastrService, public http: HttpClient) {

  }

  ngOnInit(): void {
    this.userinfo = Helper_Class.getInfo()
    if(this.userinfo.user_type == "Admin"){
      this.diag_centre_id = this.userinfo.diag_center_id;
    }else{
      this.diag_centre_id = this.userinfo.hospitals[0].hptl_clinic_id;
      this.hosp_id = this.userinfo.hospitals[0].hosp_id;
    }
    this.oursource = Helper_Class.getOutsource();
    this.getDiagTests();

  }

  getDiagTests() {
    this.all_tests = [];
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/dtsub/',
      {
        diag_centre_id: this.diag_centre_id,
        hptl_clinic_id: this.hosp_id,
        outsource: true,
        outsourceid: this.oursource.outsource_id
      },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));

          console.log("getDiagTests()-Obj", obj)

          this.labTest_array = obj.labtests;
          this.radioTest_array = obj.radiotests;
          this.microTest_array = obj.microtests;
          this.profiles = obj.profiles;

          var outlabtest = obj.outlabtest;
          var outradiotest = obj.outradiotest;

          this.filteredLabArray = obj.labtests;
          this.filteredRadioArray = obj.radiotests;
          this.filteredMicroArray = obj.microtests;

          

          if ( outlabtest != undefined){
            for( var i = 0; i < outlabtest.length; i++){
              for (var j = 0; j < this.labTest_array.length; j++){
                if(outlabtest[i].test_id == this.labTest_array[j].diag_tests_id){
                  this.labTest_array[j].checked = true;

                  this.labarray.push({
                    test_id: this.labTest_array[j].diag_tests_id,
                    test_name: this.labTest_array[j].test_name,
                    price: this.labTest_array[j].price,
                    diag_type: this.labTest_array[j].test_cat,
                  })
                }
              }

              for (var k = 0; k < this.microTest_array.length; k++){
                if(outlabtest[i].test_id == this.microTest_array[k].diag_tests_id){
                  this.microTest_array[k].checked = true;

                  this.labarray.push({
                    test_id: this.microTest_array[k].diag_tests_id,
                    test_name: this.microTest_array[k].test_name,
                    price: this.microTest_array[k].price,
                    diag_type: this.microTest_array[k].test_cat,
                  })
                }
              }
            }
          }

          if ( outradiotest != undefined){
            for( var i = 0; i < outradiotest.length; i++){
              for (var j = 0; j < this.radioTest_array.length; j++){
                if(outradiotest[i].test_id == this.radioTest_array[j].diag_tests_id){
                  this.radioTest_array[j].checked = true;

                  this.radioarray.push({
                    test_id: this.radioTest_array[j].diag_tests_id,
                    test_name: this.radioTest_array[j].test_name,
                    price: this.radioTest_array[j].price,
                    diag_type: this.radioTest_array[j].test_cat,
                  })
                }
              }
            }
          }

          if (obj.labTest_array.length == 0 && obj.radioTest_array.length && obj.microTest_array.length && obj.profiles.length) {
            this.test_flag = true;
          } else {
            this.test_flag = false;
          }
        },
        error => { }
      )
  }

  finekeyInArray(array, id) {
    var flag = false;
    for (var i in array) {
      if (array[i].test_id === id) {
        flag = true;
        break;
      }
    }
    return flag;
  }

  ChangeTestType(event, id, data, type, subtest, test_type, testdept) {
    if (event == true) {
      if (testdept == 'labtest') {
        for (var k = 0; k < this.labTest_array.length; k++) {
          if (this.labTest_array[k].diag_tests_id == id) {
            this.labTest_array[k].checked = true;
          }
        }
      } else if (testdept == 'radiotest') {
        for (var k = 0; k < this.radioTest_array.length; k++) {
          if (this.radioTest_array[k].diag_tests_id == id) {
            this.radioTest_array[k].checked = true;
          }
        }
      } else if (testdept == 'microtest') {
        for (var k = 0; k < this.microTest_array.length; k++) {
          if (this.microTest_array[k].diag_tests_id == id) {
            this.microTest_array[k].checked = true;
          }
        }
      } else {
        for (var k = 0; k < this.profiles.length; k++) {
          if (this.profiles[k].diag_tests_id == id) {
            this.profiles[k].checked = true;
          }
        }
      }

      if (subtest.department == "Laboratory") {
        // new array
        this.labarray.push({
          test_id: id,
          test_name: data,
          price: subtest.price,
          diag_type: type,
        })
      } else {
        this.radioarray.push({
          test_id: id,
          test_name: data,
          price: subtest.price,
          diag_type: type,
        })
      }

      console.log("New Arrays", this.labarray, this.radioarray)

    } else if (event == false) {
      if (testdept == 'labtest') {
        for (var k = 0; k < this.labTest_array.length; k++) {
          if (this.labTest_array[k].diag_tests_id == id) {
            this.labTest_array[k].checked = false;
          }
        }
      } else if (testdept == 'radiotest') {
        for (var k = 0; k < this.radioTest_array.length; k++) {
          if (this.radioTest_array[k].diag_tests_id == id) {
            this.radioTest_array[k].checked = false;
          }
        }
      } else if (testdept == 'microtest') {
        for (var k = 0; k < this.microTest_array.length; k++) {
          if (this.microTest_array[k].diag_tests_id == id) {
            this.microTest_array[k].checked = false;
          }
        }
      } else {
        for (var k = 0; k < this.profiles.length; k++) {
          if (this.profiles[k].diag_tests_id == id) {
            this.profiles[k].checked = false;
          }
        }
      }


      for (var h = 0; h < this.labarray.length; h++) {
        if (this.labarray[h].test_id == id) {
          this.labarray.splice(h, 1);
        }
      }

      for (var h = 0; h < this.radioarray.length; h++) {
        if (this.radioarray[h].test_id == id) {
          this.radioarray.splice(h, 1);
        }
      }

      for (var h = 0; h < this.subtestname.length; h++) {
        if (this.subtestname[h].test_id == id) {
          this.subtestname.splice(h, 1);
          this.checked = false;
          if (this.subtestname.length == 0) {
            this.bloodtest = true;
          }
        }
      }
      for (var h = 0; h < this.subtestsname.length; h++) {
        if (this.subtestsname[h].test_id == id) {
          this.subtestsname.splice(h, 1);
          if (this.subtestsname.length == 0) {
            this.scantest = true;
          }
        }
      }
      for (var h = 0; h < this.subtestuname.length; h++) {
        if (this.subtestuname[h].test_id == id) {
          this.subtestuname.splice(h, 1);
          if (this.subtestuname.length == 0) {
            this.ultratest = true;
          }
        }
      }
      for (var h = 0; h < this.subtestxname.length; h++) {
        if (this.subtestxname[h].test_id == id) {
          this.subtestxname.splice(h, 1);
          if (this.subtestxname.length == 0) {
            this.xraytest = true;
          }
        }
      }
      for (var h = 0; h < this.subtestbname.length; h++) {
        if (this.subtestbname[h].test_id == id) {
          this.subtestbname.splice(h, 1);
          if (this.subtestbname.length == 0) {
            this.biopsytest = true;
          }
        }
      }
      for (var h = 0; h < this.subtesturname.length; h++) {
        if (this.subtesturname[h].test_id == id) {
          this.subtesturname.splice(h, 1);
          if (this.subtesturname.length == 0) {
            this.urinetest = true;
          }
        }
      }
      for (var h = 0; h < this.subtestfname.length; h++) {
        if (this.subtestfname[h].test_id == id) {
          this.subtestfname.splice(h, 1);
          if (this.subtestfname.length == 0) {
            this.faecestest = true;
          }
        }
      }
      for (var h = 0; h < this.subtestcname.length; h++) {
        if (this.subtestcname[h].test_id == id) {
          this.subtestcname.splice(h, 1);
          if (this.subtestcname.length == 0) {
            this.culturetest = true;
          }
        }
      }
    }
  }

  backClicked() {
    this.diagnosisservice.sendMessage("out-centre-list")
  }

  saveTestDetails() {
    var sen_pass = {
      diagnosis_id: this.diag_centre_id,
      outsourceid: this.oursource.outsource_id,
      "labtest": this.labarray,
      "radiotest": this.radioarray,
    }

    console.log("sen_pass", sen_pass);

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/soctm/', sen_pass,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          if (obj.key == "1") {
            this.toastr.success("Successfully mapped tests")
            this.messageservice.sendMessage("out-centre-list");
          } else {
            this.toastr.success("Something went wrong, try later")
          }
        },
        error => { });

  }

  filterLabArray(LabtestName){
    this.filteredLabArray = this.labTest_array.filter(item =>
      item.test_name.toLowerCase().includes(LabtestName.toLowerCase())
    );
  }

  filterRadioArray(radiotestName){
    this.filteredRadioArray = this.radioTest_array.filter(item =>
      item.test_name.toLowerCase().includes(radiotestName.toLowerCase())
    );
  }

  filterMicroArray(microtestName){
    this.filteredMicroArray = this.microTest_array.filter(item =>
      item.test_name.toLowerCase().includes(microtestName.toLowerCase())
    );
  }

}
