<div *ngIf="habitualFlag == 'min'">
   <div class="row" style="margin-bottom: 5px;">
      <div class="col-12 col-md-6 col-sm-6 col-lg-6 col-xl-6">
         <mat-card>
            <mat-card-content>                     
               <mat-label class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">Alcohol
               </mat-label>
               <span style="float: right;">
                  <mat-label class="yesopt" *ngIf="panelOpenState1">Yes</mat-label>
                  <mat-label class="noopt" *ngIf="!panelOpenState1">No</mat-label>       
                  </span>  
            </mat-card-content>
         </mat-card>
      </div>
      <div class="col-12 col-md-6 col-sm-6 col-lg-6 col-xl-6">
         <mat-card>
            <mat-card-content>                     
               <mat-label class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">Smoking
               </mat-label>
               <span style="float: right;">
                  <mat-label class="yesopt" *ngIf="panelOpenState2">Yes</mat-label>
                  <mat-label class="noopt" *ngIf="!panelOpenState2">No</mat-label>       
                  </span>  
            </mat-card-content>
         </mat-card>
      </div>
   </div>

   <div class="row">
      <div class="col-12 col-md-6 col-sm-6 col-lg-6 col-xl-6">
         <mat-card>
            <mat-card-content>                     
               <mat-label class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">Gutka / Areca
               </mat-label>
               <span style="float: right;">
                  <mat-label class="yesopt" *ngIf="panelOpenState3">Yes</mat-label>
                  <mat-label class="noopt" *ngIf="!panelOpenState3">No</mat-label>       
                  </span>  
            </mat-card-content>
         </mat-card>
      </div>

      <div class="col-12 col-md-6 col-sm-6 col-lg-6 col-xl-6">
         <mat-card>
            <mat-card-content>                     
               <mat-label class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">Exercises
               </mat-label>
               <span style="float: right;">
                  <mat-label class="yesopt" *ngIf="panelOpenState4">Yes</mat-label>
                  <mat-label class="noopt" *ngIf="!panelOpenState4">No</mat-label>       
                  </span>  
            </mat-card-content>
         </mat-card>
      </div>
   </div>
</div>

<!-- maximize///// -->
<div *ngIf="habitualFlag == 'max'">
   <div class="row">
      <div class="col-3">
         <div class="hebitual_cover">
            <div class="header_lable">
               Alcohol
            </div>
            <div class="content_cover">
               <mat-accordion class="mataccordion">
                  <div class="vl"></div>
                  <mat-expansion-panel  [expanded]="panelOpenState1" hideToggle (opened)="panelOpenState1 = true;send_data();"
                     (closed)="panelOpenState1 = false;send_data();" class="exp_panel_cover">
                     <mat-expansion-panel-header class="exppanel">
                        <div class="hab_exp_image_cover">
                           <img
                              [src]="!panelOpenState1 ? '../../../assets/ui_icons/alcohol_red.svg' : '../../../assets/ui_icons/alcohol.svg'">
                        </div>
   
                     </mat-expansion-panel-header> <!-- heading  -->
                     <div class="hab_content_cover">
                        <div class="row">
                           <mat-label class="col-12" class="hab_conter_text">Quantity 
                              <select class="sdropdown" ngDefaultControl [(ngModel)]="Alc_Quant" >
                                 <option disabled value="Select">Select</option>
                                 <option value={{quan.description}} *ngFor="let quan of Quantity_array">
                                    {{quan.description}}</option>
                              </select>
                           </mat-label>
   
                           <mat-label class="col-12" class="hab_conter_text">Mixing Type
                              <select class="sdropdown" [(ngModel)]="Alc_Mix" >
                                 <option disabled value="Select">Select</option>
                                 <option value={{Mix.description}} *ngFor="let Mix of Mixing_array">{{Mix.description}}
                                 </option>
                              </select>
                           </mat-label>
   
                           <mat-label class="col-12" class="hab_conter_text">Duration
                              <select class="sdropdown" [(ngModel)]="alchol_duration" >
                                 <option value="&lt;1 Year">&lt;1 Year</option>
                                 <option value="1 - 2 Years"> 1 - 2 Years</option>
                                 <option value="3 - 5 Years"> 3 - 5 Years</option>
                                 <!-- <option value="&lt;5 Years">&lt;5 Years</option> -->
                                 <option value=">5 Years"> >5 Years</option>
                              </select>
                           </mat-label>
   
                           <mat-label class="col-12" class="hab_conter_text">Freqency/day
                              <select class="sdropdown" [(ngModel)]="alcohol_frequency" >
                                 <option value=1>1</option>
                                 <option value="2"> 2</option>
                                 <option value="3"> 3</option>
                                 <option value="4"> 4</option>
                                 <option value=">5 to 10">>5 to 10</option>
                                 <option value="10+">10+</option>
                              </select>
                           </mat-label>
   
   
                        </div>
                     </div>
                  </mat-expansion-panel>
               </mat-accordion>
            </div>           
         </div>
      </div>
      <div class="col-3">
         <div class="hebitual_cover">
            <div class="header_lable">
               Smoking
            </div>
            <div class="content_cover">
               <mat-accordion class="mataccordion">
                  <div class="vl"></div>
                  <mat-expansion-panel [expanded]="panelOpenState2" hideToggle (opened)="panelOpenState2 = true;send_data();" (closed)="panelOpenState2 = false;send_data();" class="exp_panel_cover">
                     <mat-expansion-panel-header class="exppanel">
                        <div class="hab_exp_image_cover">
                           <img [src]="!panelOpenState2 ? '../../../assets/ui_icons/smoke_red.svg' : '../../../assets/ui_icons/smoke.svg'">
                        </div>
   
                     </mat-expansion-panel-header> <!-- heading  -->
                     <div class="hab_content_cover">
                        <div class="row">
                           <mat-label class="col-12" class="hab_conter_text" >Duration
                              <select class="sdropdown" [(ngModel)]="smoke_duration" >
                                 <option value="&lt;1 Year"> &lt;1 Year</option>
                                 <option value="1 - 2 Years"> 1 - 2 Years</option>
                                 <option value="3 - 5 Years"> 3 - 5 Years</option>
                                 <!-- <option value="&lt;5 Years "> &lt;5 Years </option> -->
                                 <option value="&gt;5 Years">&gt;5 Years </option>
                              </select>
                           </mat-label>
                           <mat-label class="col-12" class="hab_conter_text">Freqency/day(times)
                              <select class="sdropdown" [(ngModel)]="smoke_frequency" >
                                 <option value=1>1</option>
                                 <option value="2"> 2</option>
                                 <option value="3"> 3</option>
                                 <option value="4"> 4</option>
                                 <option value=">5 to 10">>5 to 10</option>
                                 <option value="10+">10+</option>
                              </select>
                           </mat-label>
                        </div>
                     </div>
                  </mat-expansion-panel>
               </mat-accordion>
            </div>          
         </div>
      </div>
      <div class="col-3">
         <div class="hebitual_cover">
            <div class="header_lable">
               Gutka / Areca
            </div>
            <div class="content_cover">
               <mat-accordion class="mataccordion">
                  <div class="vl"></div>
                  <mat-expansion-panel hideToggle [expanded]="panelOpenState3" (opened)="panelOpenState3 = true;send_data();" (closed)="panelOpenState3 = false;send_data();" class="exp_panel_cover">
                     <mat-expansion-panel-header class="exppanel">
                        <div class="hab_exp_image_cover">
                           <img [src]="!panelOpenState3 ? '../../../assets/ui_icons/gutkha_red.svg' : '../../../assets/ui_icons/gutkha.svg'">
                        </div>
   
                     </mat-expansion-panel-header> <!-- heading  -->
                     <div class="hab_content_cover">
                        <div class="row">
                           <mat-label class="col-12" class="hab_conter_text">Duration
                              <select class="sdropdown" [(ngModel)]="gutka_duration" >
                                 <option value="&lt;1 Year">&lt;1 Year</option>
                                 <option value="1 - 2 Years"> 1 - 2 Years</option>
                                 <option value="3 - 5 Years"> 3 - 5 Years</option>
                                 <!-- <option value="&lt;5 Years ">&lt;5 Years </option> -->
                                 <option value="&gt;5 Years">&gt;5 Years</option>
      
                              </select>
                           </mat-label>
                           <mat-label class="col-12" class="hab_conter_text">Freqency/day (Times)
                              <select class="sdropdown" [(ngModel)]="gutka_frequency" >
                                 <option value=1>1</option>
                                 <option value="2"> 2</option>
                                 <option value="3"> 3</option>
                                 <option value="4"> 4</option>
                                 <option value=">5 to 10">>5 to 10</option>
                                 <option value="10+">10+</option>
                              </select>
                           </mat-label>
                        </div>
                     </div>
                  </mat-expansion-panel>
               </mat-accordion>
            </div>
         </div>
      </div>
      <div class="col-3">
         <div class="hebitual_cover">
            <div class="header_lable">
               Exercise
            </div>
            <div class="content_cover">
               <mat-accordion class="mataccordion">
              
                  <mat-expansion-panel hideToggle [expanded]="panelOpenState4" (opened)="panelOpenState4 = true;send_data();" (closed)="panelOpenState4 = false;send_data();" class="exp_panel_cover">
                     <mat-expansion-panel-header class="exppanel">
                        <div class="hab_exp_image_cover">
                           <img [src]="!panelOpenState4 ? '../../../assets/ui_icons/exercise_red.svg' : '../../../assets/ui_icons/exercise.svg'">
                        </div>
   
                     </mat-expansion-panel-header> <!-- heading  -->
                     <div class="hab_content_cover">
                        <div class="row">
                           <mat-label class="col-12" class="hab_conter_text" *ngIf="!phyex_hidden">Exercise
                              <select class="sdropdown" [(ngModel)]="life_exer" (ngModelChange)="Exercise_change($event)" disableOptionCentering >
                                 <option disabled value="Select">Select</option>
                                 <option *ngFor="let exercise of Physcial_exercise"
                                     value={{exercise.description}}>
                                    {{exercise.description}}</option>
                              </select>
                           </mat-label>
                           <mat-label class="col-12" *ngIf="!yoga_div">
                           <div class="scroll_checkbox test_box" id="Div8" style="margin-top: 12px;" >
                              <table style="margin-top: -5px;">
                                  <tr *ngFor="let yoga of Get_theyoga">
                                      <td class="test">
                                          <mat-checkbox multiple [checked]="yoga_check.indexOf(yoga.yoga_asana_id) > -1"
                                              (change)="yoga_click(yoga.yoga_asana_id,$event.checked); send_data()" >
                                          </mat-checkbox>
                                      </td>
                                      <td style="text-align: left !important;text-align: left !important;                                    
                                      color: black;
                                      width: -webkit-fit-content;
                                      width: -moz-fit-content;
                                      width: fit-content;
                                      font-size: 12px !important;
                                      font-family: Arial;
                                      font-weight: 400;
                                      letter-spacing: 0.5px;">
                                          <span>{{yoga.description}}</span>
                                      </td>
                                  </tr>
                              </table>
                          </div>
                        </mat-label>
                           <!-- <mat-label class="col-12" class="hab_conter_text" *ngIf="!yoga_div"><br>
                              <select class="sdropdown" disableOptionCentering multiple >
                                 <option disabled value="Select">Select</option>
                                 <option #matoption color="primary" (click)="yoga_click(yoga.yoga_asana_id,matoption)"
                                    *ngFor="let yoga of Get_theyoga" value={{yoga.description}}>
                                    {{yoga.description}}
                                 </option>
                              </select>
                           </mat-label> -->
      
                           <mat-label class="col-12" class="hab_conter_text">{{phydure_label}}<br>
                              <select class="sdropdown" [(ngModel)]="life_exerdure"  
                                 disableOptionCentering >
                                 <option disabled value="Select">Select</option>
                                 <option value="10 mins">10 mins</option>
                                 <option value="15 mins">15 mins</option>
                                 <option value="30 mins">30 mins</option>
                                 <option value="45 mins">45 mins</option>
                              </select>
                           </mat-label>
                           <mat-label class="col-12" class="hab_conter_text">{{phyfreq_label}}<br>
                              <select class="sdropdown" [(ngModel)]="life_exe_freq" 
                                 disableOptionCentering >
                                 <option disabled value="Select">Select</option>
                                 <option value="Daily">Daily</option>
                                 <option value="Thrice a week">Thrice a week</option>
                                 <option value="4 days a week">4 days a week</option>
                                 <option value="5 days a week">5 days a week</option>
                              </select>
                           </mat-label>
                           <mat-label class="col-12" class="hab_conter_text">Exercise routine<br>
                              <select class="sdropdown" [(ngModel)]="exer_run" disableOptionCentering >
                                 <option disabled value="Select">Select</option>
                                 <option *ngFor="let routine of exercise_routine_arry" value="{{routine.description}}">
                                    {{routine.description}}</option>
                              </select>
                           </mat-label>
                        </div>
                     </div>
                  </mat-expansion-panel>
               </mat-accordion>
            </div>
         </div>
      </div>
   </div>
   

   <div class="row" style="margin-top: 20px;">
      <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
        
         <div class="row" [hidden]="Diabetes_habitual">
            <div class="col-4">
               <mat-label class="matlabel ">Family support</mat-label>
            </div>
            <div class="col-2 margin-bottomvalue">
               <mat-button-toggle id="toggid2" class="togglebtn" [(ngModel)]="togg_family" ngDefaultControl
                  [checked]="familytab1" (change)="changefamily($event.source.checked); send_data()" >
                  <span class="familytab" *ngIf="!familytab1"><img class="family_toggle_img"
                        src="../../../assets/ui_icons/cross_mark.svg"></span>
                  <span class="familytab" *ngIf="familytab1"><img class="family_toggle_img"
                        src="../../../assets/ui_icons/tick_mark.svg"></span>
               </mat-button-toggle>
            </div>
            <!-- <div  [hidden]="Diabetes_habitual">
               <mat-radio-group class="walkin_radio" color="primary" [(ngModel)]="togg_family"  (change)="changefamily($event.value)" labelPosition='before'><b style="vertical-align: text-bottom;">Family Support </b>&nbsp;
                     <mat-radio-button name="apptype" value="no">No</mat-radio-button>
                     &nbsp;&nbsp;
                     <mat-radio-button name="apptype" value="yes">Yes</mat-radio-button>
               </mat-radio-group>

            </div> -->
            <!-- <div class="col-2 col-sm-1  col-lg-1 col-md-1 col-xl-1  margin-bottomvalue">

            </div> --> 
         </div>
      </div>
   </div>
   <div class="row">
      <div class=" col-12 ">
         <a style="float: right;"><img *ngIf="!client" src="../../../assets/ui_icons/buttons/History_button.svg"
               class="saveimgbtn_inpatinfo" (click)="history()" /></a>
      </div>
   </div>

</div>