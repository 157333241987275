<div class="row" style="margin:0 auto; width:100%">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Lab Reports</h5>
          </div>
          <div class="headerButtons">
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div>
          <div class="row">
            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <mat-label class="matlabel">Search By Relation
                <mat-select disableOptionCentering class="ipcss" [(ngModel)]="relationId" required>
                  <mat-option *ngFor="let relation of relationList" (click)="changeRelation(relation.rel_id)"
                    value="{{relation.rel_id}}">{{relation.rel_desc}}</mat-option>
                </mat-select>
              </mat-label>
            </div>
            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"[hidden]="reportFlag">
              <mat-label class="matlabel" [hidden]="reportFlag">Document type
                <mat-select disableOptionCentering class="ipcss " [(ngModel)]="report" required>
                  <mat-option *ngFor="let report of documentTypeList" (click)="selectReport(report)"
                    value="{{report}}">{{report}}</mat-option>
                </mat-select>
              </mat-label>
            </div>
            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <p [hidden]="!reportFlag">
                <mat-label class="matlabel">No Report found</mat-label>
              </p>
            </div>
            <div *ngIf="medPresList != undefined && medPresList.length != 0">
              <div class="row">
                <div class="col-6" *ngFor="let prescription of medPresList; let i = index">
                    <div class="casesheetlist"   (click)="viewMedPres(prescription.pres_drug_id)">
                      <p>{{"Dr."+ prescription.doctorname + " prescribed for " +
                        prescription.clientname}} {{" on "+ prescription.date}}</p>
                      <p *ngIf="!header_footer_flag">{{"Prescription of " + prescription.clientname}} {{" on "+ prescription.date}}</p>
                    </div>
                </div>
              </div>
            </div>
            <!-- diagnosis prescription -->
            <div *ngIf="diagPresList != undefined && diagPresList.length != 0">
              <div class="row">
                <div class="col-6" *ngFor="let prescription of diagPresList; let i = index">
                    <div class="casesheetlist"   (click)="viewDiagPres(prescription.pres_diag_id)">
                      <p>{{"Dr."+ prescription.doctorname + " prescribed for " + prescription.clientname}}<span
                        *ngIf="header_footer_flag">{{"on " + prescription.date }}</span> </p>
                      <p *ngIf="!header_footer_flag">{{"Date : "+ prescription.date}}</p>
                    </div>
                </div>
              </div>
            </div>
            <!-- Reports -->
            <div *ngIf="reportList != undefined && reportList.length != 0">
              <div class="row">
                <div class="col-6" *ngFor="let report of reportList; let i = index">
                    <div class="casesheetlist"  (click)="viewReportDetails(report.diag_appointment_id,report.diag_test_id,report.pres_diag_id,report.diag_test_id,report.type,'diag')">
                      <p>{{report.date}} : {{report.diag_test_name}} taken in {{report.diag_centre_name}} referred by
                        Dr.{{report.doctor_name}} for {{report.Client_Name}}</p>
                    </div>
                </div>
              </div>
            </div>
            <!-- Case sheet -->
            <div *ngIf="caseList != undefined && caseList.length != 0">
              <div class="row">
                <div class="col-6" *ngFor="let caselist of caseList; let i = index">
                    <div class="casesheetlist"  (click)="casesheetDetails(caselist)" style="height: 64px;overflow-y: scroll;">
                      <p style="margin: 0;font-size: 13px;">{{caselist.case_list_data}} <br> Created on: {{ caselist.date }}</p>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>