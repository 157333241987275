import { Component, OnInit } from '@angular/core';
import {Helper_Class} from '../../helper_class';
import {Router, ActivatedRoute } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import * as $ from 'jquery/dist/jquery.min.js';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { formattedDate, Date_Formate, ConvertTimeformat, Nurse_Day_id} from '../../../assets/js/common.js';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';

@Component({
  selector: 'app-pharma-registration',
  templateUrl: './pharma-registration.component.html',
  styleUrls: ['./pharma-registration.component.css']
})
export class PharmaRegistrationComponent implements OnInit {
  //profile
  public pharma_first_name:any;
  public pharma_last_name:any;
  public pharma_gender:any;
  public pharma_dob:any;
  public pharma_mobile:any;
  public pharma_email:any;
  public pharmacy_reg_code:any;
  public pharma_Create_password:any;
  public pharmacy_reg__year:any;
  public pharma_reg_state:any;
  public pharma_security_txt:any;
  public pharma_confirm_pwd:any;
  public get_date:any;
  public CurrentDatetime:any;
  public pharma_middle_name:any;
  public Update_img:any;
  public Imagepath:any;
  public pro_img:any;
  public mobile_str :any;
  public email_str:any;
  public password_string:any;
  public Create_password:any;
  public send_subspl_array = [];
  public img_str = null;

  //work
  public work_arry = [];
  public starts_from_array = [];
  public ends_at_array = [];
  tab2Params: any;
  public hosp_name_txt: any;
  public pharmacy_dist:any;
  public pharmacy_loc_txt: any;
  public pharmacy_state:any;
  public pharmacy_country:any;
  public pharmacy_zip:any;
  public pharmacy_address1:any;
  public pharmacy_address2:any;
  public pharmacy_contact_num:any;
  public pharmacy_website:any;
  public pharmacy_days:any;

  public start_from1: string;
  public start_from2: string;
  public start_from3: string;
  public end_at1: string;
  public end_at2: string;
  public end_at3: string;
  public hospital_txt = [];
  public location_txt = []
  public filt_city_desc:any;
  public filt_state_desc:any;
  public loc_id: string;
  public country_id: string;
  public country_desc: string;

  public location_filterlist = [];
  public filteredlocList = [];
  public filteredhospList = [];
  public hospital_filterlist = [];
  public location_list_data=[];
  public locationList;
  public location_search_items;
  public city_url: string;
  public state_url: string;
  public country_url: string;
  public city_list_data;
  public state_list_data;
  public country_list_data;
  public clnt_location;
  public pharma_name;
  public hosp_nurseid;

  public Location_array = [];
  public Search_City: string
  public Get_city_arry = [];
  public pharma_id;
  public app_type_lbl: string;
  public LocationExits: boolean;
  public HomeDelivery: any;
  public StorePickup: any;
  public pharmacy_store: any;
  public StorepickupDisabled: boolean;

  //terms&condition
  public Reg_profile_tab;
  public Reg_work_tab;
  public Registration_work_add;
  public registration_qual;
  public get_packdata;
  public agree: boolean;
  public save_flag:boolean = false;
  public docAge:string;
  public ageRead: boolean=false;
  public ageDisabledFlag: boolean=false;
  public ageUrl: string;
  public signatureFlag:boolean;
  public signatureFile = null;
  constructor(public http:Http,public routes:ActivatedRoute,public router:Router,public toastr: ToastrService) {
    this.signatureFlag = false;
    this.start_from1 = "06";
    this.start_from2 = "00";
    this.start_from3 = "AM";
    this.end_at1 = "08";
    this.end_at2 = "00";
    this.end_at3 = "AM";
    this.pharma_id = "0";

    this.city_url = ipaddress.getIp + "gen/city";
    this.state_url = ipaddress.getIp + "gen/state";
    this.country_url = ipaddress.getIp + "gen/count";
    this.ageUrl = ipaddress.getIp + "adm/ddif";

    this.StorepickupDisabled = true;
    // time format
    for (var i = 1; i <= 12; i++) {
      if (i < 10)
        this.starts_from_array.push("0" + i);
      else
        this.starts_from_array.push(i);
    }

    for (var j = 0; j <= 55; j += 5) {
      if (j < 10)
        this.ends_at_array.push("0" + j);
      else 
        this.ends_at_array.push(j);
    }
  }

  ngOnInit(): void {
    this.Imagepath = "default";
    this.Get_current_date();
    this.Imagepath = "default";
    $(document).ready(function () {
      $("input").attr("autocomplete", "off");
    });
  }

  Get_current_date() {//Get current date
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate',
      JSON.stringify({
        country: ipaddress.country_code.toString()
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.current_date != null) {
            this.CurrentDatetime = obj.current_date;
            this.get_date = obj.current_date.split('-');
          }
        },error => {
          this.toastr.error(Message_data.network);
        });
  }
  
  fileChange($event): void {
    this.readThis($event.target);
  }
  
  readThis(inputValue: any): void {
    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      var fileSize = Math.round(inputValue.files[0].size / 1024);
      if (fileSize >= 50) {
        this.toastr.error(Message_data.maxImgSize);
        $("#file").val('');
        this.img_str = null;
      }else {
        this.img_str = myReader.result;
        $('#profile_img').attr('src', myReader.result.toString());
      }
    }
    myReader.readAsDataURL(inputValue.files[0]);
  }

  onFileChange($event): void {
    this.signatureFlag = true;
    this.readThis1($event.target);
  }

  readThis1(inputValue: any): void {
    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      var fileSize = Math.round(inputValue.files[0].size / 1024);
      if (fileSize >= 50) {
        // $scope.Imagepath == "default"
        this.toastr.error(Message_data.maxImgSize);
        $("#file").val('');
        this.signatureFile = null;
      } else {
        var signatureFile = myReader.result;
        this.signatureFile = myReader.result;
        $('#signature_img').attr('src', myReader.result);
        this.signatureFile = (signatureFile as string).split(',')[1];
      }
    }
    myReader.readAsDataURL(inputValue.files[0]);   
  }

  Date_click(value) {
    this.pharma_dob = value;

    this.ageRead = true;
    if(value != null)
    this.ageDisabledFlag = true;

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.ageUrl, JSON.stringify({
      dobdate: Date_Formate(this.pharma_dob),
      curdate: Date_Formate(this.CurrentDatetime),

    }), { headers: headers }).subscribe(
      data => {
        var obj = data.json();
        if (obj != undefined)
          this.docAge = obj.value;
      });

  }
  
  final_update() {
    var get_f_date = Date_Formate(this.pharma_dob);
    this.Update_img = this.Imagepath == "default" ? "default" : this.Imagepath.split(',')[1];
    this.mobile_str = encrypt_decript.Encript(this.pharma_mobile).toString();
    this.email_str = encrypt_decript.Encript(this.pharma_email).toString();
    this.password_string = encrypt_decript.Encript(this.pharma_confirm_pwd).toString();

    var sub_spl = undefined;
    if (this.send_subspl_array.length != 0) {
      sub_spl = this.send_subspl_array;
    }

    var img_path = "default", upd_url = undefined, mName = undefined;
    if (this.img_str != null) {
      img_path = this.img_str.split(',')[1];
      upd_url = "1";
    }

    var sig_path;
    if(this.signatureFile != null){
      sig_path = this.signatureFile;
      console.log(sig_path);
    }

    if (this.pharma_middle_name != undefined) {
      mName = this.pharma_middle_name;
    }

    var send_array = null;
    send_array = {
      serv_provider_id: "5",  
      upd_url: upd_url,
      profile_image: img_path,
      signature_image: sig_path,
      first_name: this.pharma_first_name,
      middle_name: mName,
      last_name: this.pharma_last_name,
      gender: this.pharma_gender,
      dob: Date_Formate(get_f_date),
      age: this.docAge,
      email: this.email_str,
      mobile: this.mobile_str,
      registration_code: this.pharmacy_reg_code,
      registration_state: this.pharma_reg_state,
      registration_year: this.pharmacy_reg__year,
      language: "English",
      created_date: this.CurrentDatetime,
      password: this.password_string,
      verification_call: "0"
    }
    Helper_Class.set_regsitration_profile_array(send_array);
  }
  
  Profile_next_click() {
    var chec_valid = true;

    if (this.pharma_first_name == undefined || this.pharma_first_name == "" || this.pharma_last_name == undefined ||
      this.pharma_last_name == "" || this.pharma_gender == undefined || this.pharma_dob == "" ||
      this.pharma_mobile == undefined || this.pharmacy_reg_code == undefined ||
      this.pharmacy_reg_code == "" || this.pharmacy_reg__year == undefined || this.pharmacy_reg__year == "" ||
      this.pharma_reg_state == undefined || this.pharma_reg_state == "" ||
      this.pharma_Create_password == undefined || this.pharma_Create_password == "" ||
      this.pharma_confirm_pwd == undefined || this.pharma_confirm_pwd == "" || this.pharma_dob == undefined ) {
      this.toastr.error(Message_data.mandatory);
      chec_valid = false;
    } else if (this.pharma_mobile.length != 10) {
      this.toastr.error(Message_data.validMobileNo);
      chec_valid = false;
    } else if (this.pharma_email != undefined) {
      var x = this.pharma_email;
      var re = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/igm;
      if (!re.test(x)) {
        chec_valid = false;
        this.toastr.error(Message_data.validEmail);
      }
    }

    if (this.pharma_Create_password != this.pharma_confirm_pwd) {
      this.toastr.error(Message_data.pwdNotMatched);
      chec_valid = false;
    }

    if (this.pharma_dob != undefined) {
      var get_t_date = new Date(this.pharma_dob.toString());
      var Dobsplit = formattedDate(get_t_date).toString().split('-');
      Helper_Class.set_Doc_reg_dob(Dobsplit[0]);
    }
    if (this.pharma_dob != "" && this.pharmacy_reg__year != undefined && this.pharmacy_reg__year != "") {
      if ((this.pharmacy_reg__year.length != 4) || (parseInt(this.pharmacy_reg__year) > parseInt(this.get_date[0]))) {
        this.toastr.error(Message_data.validRegtrYear);
        chec_valid = false;
      } else if ((parseInt(this.pharmacy_reg__year) - parseInt(Dobsplit[0])) < 20) {
        this.toastr.error(Message_data.invalidRegtrOrDOB);
        chec_valid = false;
      }
    }
    if (chec_valid == true) {
      this.check_mobile_exits();
    }
  }
  
  isReadonly() { return true; }

  emailCheck() {
    if(this.pharma_email != undefined){
      var email_di = encrypt_decript.Encript(this.pharma_email);
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'usercontroller/isemailexist',
        JSON.stringify({
          email: email_di.toString(),
          flag: "5"
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            if (obj.result == true) 
              this.toastr.error(Message_data.existEmailID);
            else 
              this.final_update();
          },error => {});
    }
  }

  check_mobile_exits() {
    var email_di = encrypt_decript.Encript(this.pharma_mobile);
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'usercontroller/ismobileexist',
      JSON.stringify({
        mobile: email_di.toString(),
        flag: "5"
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.result == true)
            this.toastr.error(Message_data.existMobileNo);
          else 
            this.emailCheck();
        },error => {});
  }

  work_btn_click() {//pharma_work_tab // add table
    if (this.LocationExits == true) {
      this.toastr.error(Message_data.locationExist);
    } else if (this.pharmacy_loc_txt == "" ||
      this.pharmacy_dist == undefined || this.pharmacy_state == "" || this.pharmacy_country == "" ||
      this.pharmacy_zip == "" || this.pharmacy_address1 == "" || this.pharmacy_contact_num == "" ||
      this.pharmacy_days == "") {
      this.toastr.error(Message_data.mandatory)
    } else if ((this.HomeDelivery == undefined && this.StorePickup == undefined) || (this.HomeDelivery == false && this.StorePickup == false)) {
      this.toastr.error(Message_data.homeORstorepickup);
    } else if (this.StorePickup != undefined && this.StorePickup == true && (this.pharmacy_store == undefined || this.pharmacy_store == "")) {
      this.toastr.error(Message_data.enterStorepickupdays);
    } else {
      var homedel = this.HomeDelivery != undefined && this.HomeDelivery == true ? "1" : "0";
      var storepick = this.StorePickup != undefined && this.StorePickup == true ? "1" : "0";
      var store_days_data = this.pharmacy_store != undefined && this.pharmacy_store != "" ? this.pharmacy_store : undefined;

      this.work_arry.push({
        hospital_id: this.pharma_id,
        name: this.pharma_name,
        location: this.pharmacy_loc_txt,
        city: this.pharmacy_dist,
        state: this.pharmacy_state,
        country: this.pharmacy_country,
        zipcode: this.pharmacy_zip,
        address1: this.pharmacy_address1,
        address2: this.pharmacy_address2,
        mobilenum: this.pharmacy_contact_num,
        website: this.pharmacy_website,
        day_desc: this.pharmacy_days,
        day: Nurse_Day_id(this.pharmacy_days),
        home_delivery: homedel,
        store_pickup: storepick,
        store_pickup_validity: store_days_data,
        available_from: this.start_from1 + ":" + this.start_from2 + " " + this.start_from3,
        available_to: this.end_at1 + ":" + this.end_at2 + " " + this.end_at3,
      });

      this.hosp_name_txt = "";
      this.pharmacy_loc_txt = "";
      this.pharmacy_dist = "";
      this.pharmacy_state = "";
      this.pharmacy_country = "";
      this.pharmacy_zip = "";
      this.pharmacy_address1 = "",
      this.pharmacy_address2 = "";
      this.pharmacy_contact_num = "";
      this.pharmacy_website = '';
      this.pharmacy_days = "";

      this.start_from1 = "06";
      this.start_from2 = "00";
      this.start_from3 = "AM";
      this.end_at1 = "08";
      this.end_at2 = "00";
      this.end_at3 = "AM";
    }
  }

  work_delete(name_data, end_time) {//table delecte action
    for (var i = 0; i < this.work_arry.length; i++) {
      if (name_data == this.work_arry[i].name) {
        this.work_arry.splice(i, 1);
      }
    }
  }

  pharma_change(e) {
    this.pharma_name = e.target.value.toString();
    this.pharmacy_loc_txt = '';
    this.pharmacy_dist = '';
    this.pharmacy_state = '';
    this.pharmacy_country = '';
    this.pharmacy_zip = '';
    this.pharmacy_address1 = '';
    this.pharmacy_address2 = '';
    this.pharmacy_contact_num = '';
    this.pharmacy_website = '';
    this.hospital_txt = [];
    this.filteredlocList = [];
    this.city_list_data = [];
    this.state_list_data = [];
    this.country_list_data = [];

    this.hospital_filterlist = [];
    if (this.pharma_name != undefined && this.pharma_name !== "" && this.pharma_name.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/pharma/',
        JSON.stringify({
          pharma_name: this.pharma_name
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            if (obj.pharmacies != null) {
              this.hospital_filterlist = obj.pharmacies;
              for (var i = 0; i < this.hospital_filterlist.length; i++) {
                this.hospital_txt.push(this.hospital_filterlist[i].pharma_name);
              }
              this.filteredhospList = this.hospital_txt.filter(function (this:any,el) {
                return el.toLowerCase().indexOf(this.pharma_name.toLowerCase()) > -1;
              }.bind(this));
            }
          }, error => {});
    } else {
      this.filteredhospList = [];
    }
  }

  select_hosp(data) {
    this.pharma_name = data;
    this.filteredhospList = [];
    this.getLocationBasedonPharma();
  }

  getLocationBasedonPharma() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'gen/ploc/',
      JSON.stringify({
        pharma_name: this.pharma_name
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.pharma_locations != null) {
            this.location_list_data = obj.pharma_locations;
          }
        },error => {});
  }
  
  Loc_change() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'gen/paddr/',
      JSON.stringify({
        pharma_name: this.pharma_name,
        location: this.pharmacy_loc_txt
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.pharma_id != null) {
            this.pharma_id = obj.pharma_id;
            this.pharmacy_address1 = obj.address1;
            this.pharmacy_address2 = obj.address2;
            this.pharmacy_dist = obj.city;
            this.pharmacy_state = obj.state;
            this.pharmacy_country = obj.country;
            this.pharmacy_contact_num = obj.telephone;
            this.pharmacy_website = obj.website;
            this.pharmacy_zip = obj.zipcode;
          }
        },error => {});
  }

  locfilter() {
    this.pharmacy_dist = '';
    this.pharmacy_state = '';
    this.pharmacy_country = '';
    this.pharmacy_zip = '';
    this.pharmacy_address1 = '';
    this.pharmacy_address2 = '';
    this.pharmacy_contact_num = '';
    this.pharmacy_website = '';
    this.pharma_id = "0";
    this.location_txt = [];
    this.location_filterlist = [];
    if (this.pharmacy_loc_txt !== "" && this.pharmacy_loc_txt.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'gen/loc',
        JSON.stringify({
          loc_name: this.pharmacy_loc_txt
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            if (obj.locations != null) {
              this.location_filterlist = obj.locations;
              this.filteredlocList = this.location_filterlist.filter(function (this:any,el) {
                return el.toLowerCase().indexOf(this.pharmacy_loc_txt.toLowerCase()) > -1;
              }.bind(this));
            }
          },error => {});
    } else {
      this.filteredlocList = [];
    }
  }

  select_location(data) {
    this.pharmacy_loc_txt = data;
    this.filteredlocList = [];
    this.Get_City(data);
    this.state_list_data = [];
    this.city_list_data = [];
    this.country_list_data = [];
  }

  Get_City(data) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.city_url, JSON.stringify({ loc_name: data }), { headers: headers }).subscribe(
      data => {
        if (data.json().cities != null && data.json().cities.length != 0) {
          this.city_list_data = data.json().cities;
          this.pharmacy_dist = this.city_list_data[0].city_id;
          this.filt_city_desc = this.city_list_data[0].city_desc;
          this.loc_id = this.city_list_data[0].location_id;
          this.pharmacy_zip = this.city_list_data[0].zipcode;
          this.City_change(this.city_list_data[0].city_id);
        }
      },error => {});
  }

  City_change(data) {
    if (data != null) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.state_url, JSON.stringify({ city_id: data }), { headers: headers }).subscribe(
        data => {
          if (data.json().states != null) {
            this.state_list_data = data.json().states;
            this.pharmacy_state = this.state_list_data[0].state_id;
            this.filt_state_desc = this.state_list_data[0].state_desc;
            this.State_change(this.state_list_data[0].state_id);
          }
        },error => {});
    } else {
      this.state_list_data = [];
      this.city_list_data = [];
      this.country_list_data = [];
    }
  }

  State_change(data) {
    if (data != null) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.country_url, JSON.stringify({ state_id: data }), { headers: headers }).subscribe(
        data => {
          if (data.json().countries != null && data.json().countries.length != null) {
            this.country_list_data = data.json().countries;
            this.country_id = this.country_list_data[0].country_id;
            this.pharmacy_country = this.country_list_data[0].country_desc;
            this.SerCountry_Change();
          }
        },error => {});
    } else {
      this.state_list_data = [];
      this.country_list_data = [];
    }
  }

  SerCountry_Change() {
    this.pharma_id = "0";
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/ispharexist/',
      JSON.stringify({
        pharma_name: this.pharma_name,
        location: this.pharmacy_loc_txt,
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj != null && obj.is_exists == true) {
            this.LocationExits = true;
            this.toastr.error(Message_data.locationExist);
          }else {
            this.LocationExits = false;
            var headers = new Headers();
            headers.append('Content-Type', 'application/json');
            this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/allpharmaname/',
              JSON.stringify({
                pharma_name: this.pharma_name,
                location: this.pharmacy_loc_txt,
                city: this.pharmacy_dist,
                flag: "signin"
              }),
              { headers: headers })
              .subscribe(
                response1 => {
                  var obj1 = JSON.parse(response1["_body"]);
                  if (obj1.pharma_id != null) {
                    this.pharma_id = obj1.pharma_id;
                    this.pharmacy_address1 = obj1.address1;
                    this.pharmacy_address2 = obj1.address2;
                    this.pharmacy_zip = obj1.zipcode;
                    if (obj1.telephone != null) {
                      this.pharmacy_contact_num = obj1.telephone;
                    }
                    if (obj1.website != null) {
                      this.pharmacy_website = obj1.website;
                    }
                  }
                },error => {});
          }
        },error => {});
  }

  StorePickupChanger() {
    if (this.StorePickup == true) {
      this.StorepickupDisabled = false;
    }else {
      this.pharmacy_store = "";
      this.StorepickupDisabled = true;
    }
  }

  Worklocation_nextclick() {
    if (this.work_arry.length == 0) {
      this.toastr.error(Message_data.addWrkLocation);
    }else {
      var work_arry1 = [];
      var work_address_array = [];

      for (var i = 0; i < this.work_arry.length; i++) {
        if (this.work_arry[i].hospital_id == "0") {
          var pharmacy_address2 = undefined;
          pharmacy_address2 = this.work_arry[i].address2 != undefined ? this.work_arry[i].address2 : "";

          var pharmacy_website = undefined;
          pharmacy_website = this.work_arry[i].hospital_website != undefined ? this.work_arry[i].hospital_website : "";

          work_address_array.push({
            name: this.work_arry[i].name,
            address1: this.work_arry[i].address1,
            address2: this.pharmacy_address2,
            city: this.work_arry[i].city,
            state: this.work_arry[i].state,
            zipcode: this.work_arry[i].zipcode,
            country: this.work_arry[i].country,
            telephone: this.work_arry[i].mobilenum,
            website: this.work_arry[i].website,
            location: this.work_arry[i].location,
          });
          var hospital_namepharma = this.work_arry[i].name;
          var hospital_locaphar = this.work_arry[i].location;
        }
        work_arry1.push({
          hospital_id: this.work_arry[i].hospital_id,
          available_from: ConvertTimeformat("", this.work_arry[i].available_from),
          available_to: ConvertTimeformat("", this.work_arry[i].available_to),
          day: this.work_arry[i].day,
          day_session: "1",
          home_delivery: this.work_arry[i].home_delivery,
          store_pickup: this.work_arry[i].store_pickup,
          store_pickup_validity: this.work_arry[i].store_pickup_validity,
          hosp_name: hospital_namepharma,
          location: hospital_locaphar,
        });
      }
      
      Helper_Class.set_reg_work_array(work_arry1);
      Helper_Class.set_reg_work_add_array(work_address_array);
    }
  }

  register_next_click() {//terms & condition
    this.Profile_next_click();
    this.Worklocation_nextclick();
    if (this.agree == true) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'gen/otp/',
        JSON.stringify({
          mobile: encrypt_decript.Encript("").toString(),
          country: ipaddress.country_code,
          flag:"5",
          registration:true
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
              this.otp_open(obj.otp);
          },error => {
            this.toastr.error(Message_data.network);
          });
    } else {
      this.toastr.error(Message_data.termsNconditions);
    }
  }

  otp_open(otp_data) {
    this.register();
    // var otpval;
    // if(otp_data !="false"){
    //   otpval =encrypt_decript.Decript(otp_data);
    // }else{
    //   otpval = otp_data;
    // }
    // let poup = this.modalCtrl.create('OtpConfirmPage', {
    //   "otp":otpval,
    //   type:"registration"
    // });
    // poup.present();
    // poup.onDidDismiss(data => {
    //   if (data != undefined) {
    //     if (data.result == true) {
    //       this.register();
    //     }
    //   }
    // });
  }

  register(){
    this.Reg_profile_tab = Helper_Class.get_regsitration_profile_array();
    this.Reg_work_tab = Helper_Class.get_reg_work_array();
    this.Registration_work_add = Helper_Class.get_reg_work_add_array();
    this.registration_qual = Helper_Class.getregistration_qual_array();

    var send_work = null;
    send_work = {
      consultation: this.Reg_work_tab
    }

    var send_url = "usercontroller/regprov/";
    for (var key in this.Reg_profile_tab) {
      if (key == "upd_url" && this.Reg_profile_tab[key] != undefined) {
        send_url = "usercontroller/regprovweb/";
      }
    }

    var subscribe_array = {
      grit_package_subscribe_id: "20",
      country: ipaddress.country_code
    }

    var finalObj = $.extend(true, this.Reg_profile_tab, send_work);
    var finalObj1 = $.extend(true, finalObj, this.registration_qual);
    var finalObj2 = $.extend(true, finalObj1, subscribe_array);
    var output = null;
    output = finalObj2;

    // send foot ass
    var send_foot = null, getfoot_ass = null, qual;
    getfoot_ass = Helper_Class.get_reg_work_add_array();

    if (this.Registration_work_add != null || this.Registration_work_add != undefined) {
      send_foot = {
        address: getfoot_ass,
      }
      output = $.extend(true, finalObj2, send_foot);
    }
    
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + send_url, output,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj["key"] != null && obj["key"] == "1") {
            this.save_flag = true;
            this.toastr.success(Message_data.regtrSuccess);
            this.router.navigateByUrl("/loginpage");
          }else {
            this.toastr.error(Message_data.unableToRegtr);
          }
        },error => {
          this.toastr.error(Message_data.network);
        });
  }

  jsonConcat(o1, o2) {
    for (var key in o2) {
      o1[key] = o2[key];
    }
    return o1;
  }
}
