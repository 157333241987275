import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from '../../helper_class';
import { Http, Headers } from '@angular/http';
import { Message_data } from '../../../assets/js/Message_data';
import { Diagnosis_Helper } from '../Diagnosis_Helper';
import { ipaddress } from '../../ipaddress';
import { MenuViewService } from '../../../app/common-module/menu-view/menu-view.service';

@Component({
  selector: 'app-nephrologyreadings',
  templateUrl: './nephrologyreadings.component.html',
  styleUrls: ['./nephrologyreadings.component.css']
})
export class NephrologyreadingsComponent implements OnInit {
  public subRelationID: any;
  public getAppDate;
  public firstName: any;
  public listArray: any = [];
  public listData: any;
  public UserType;
  public middleName: any;
  public lastName: any;
  public diagAppointmentID: any;
  public relationID: any;
  public ethnicGroup: any;
  public diagLocation: any;
  public locationArray: any;
  public appiontment: any;
  public date: any;
  public NephSubID;
  public currentDate;
  public userInfo;

  constructor(public messageservice:MenuViewService ,public toastr: ToastrService,public http: Http) {
    //this.getCurrentDate();
  }

  ngOnInit(): void {
    this.userInfo = Helper_Class.getInfo();
    this.UserType = this.userInfo.user_type;
    this.getCurrentDate();
  }

  getCurrentDate() {//current date
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + "adm/curdate", JSON.stringify({ country: ipaddress.country_code }), { headers: headers }).subscribe(
      data => {
        if (data.json().current_date != null) {
          this.currentDate = data.json().current_date;
          this.date = data.json().current_date;
          this.get_nephrology();
        }
      },error => {
        this.toastr.error(Message_data.getNetworkMessage());
      });
  }

  // get_location() {
  //   this.UserType = this.userInfo.user_type != null ? "diag_user" : "diagnosis";

  //   var headers = new Headers();
  //   headers.append('Content-Type', 'application/json');
  //   this.http.post(ipaddress.getIp.toString() + "diagnosiscontroller/diagloc/", JSON.stringify({
  //     diag_name: this.userInfo.user_id,
  //     flag: "id",
  //     prov_id: this.userInfo.user_id,
  //     type: this.UserType,
  //     imei: Helper_Class.getIPAddress(),
  //   }), { headers: headers }).subscribe(
  //     data => {
  //       if (data.json().diag_loc != null) {
  //         this.locationArray = data.json().diag_loc;
  //       }
  //     },error => {
  //       this.toastr.error(Message_data.getNetworkMessage());
  //     });
  // }

  get_nephrology() {
    //this.getAppDate = this.date;
    //if (this.diagLocation != undefined) {
      this.subRelationID = 0;
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + "neph/gdapps/", JSON.stringify({
        diag_centre_id: this.userInfo.hospitals[0].hptl_clinic_id,
        date: this.date,
      }), { headers: headers }).subscribe(
        data => {
          this.listArray = [];
          if (data.json() != null) {
            this.listData = data.json();
            this.listData.user_details.forEach(element => {
              this.listArray.push({
                first_name: element.first_name,
                middle_name: element.middle_name,
                last_name: element.last_name,
                diag_appointment_id: element.diag_appointment_id,
                client_reg_id: element.client_reg_id,
                relation_id: element.relation_id,
                sub_rel_id: element.sub_rel_id,
                ethnic_group: element.ethnic_group
              });
              if (this.subRelationID != "0") {
                this.subRelationID = element.sub_rel_id;
              }
            });
          }
        }, error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
    // }
    // else {
    //   this.toastr.error(Message_data.sltLocAndDate);
    // }
  }

  view_neph_details(nephreading) {
    this.NephSubID = nephreading.sub_rel_id != null ? nephreading.sub_rel_id : "0";
    var viewnephro_reading =({
      DiagNepro_App_id: nephreading.diag_appointment_id,
      DiagNepro_Clnt_id: nephreading.client_reg_id,
      DiagNepro_Clntrel_id: nephreading.relation_id,
      DiagNepro_ClntSrel_id: this.NephSubID,
      DiagNepro_Clnt_Fname: nephreading.first_name,
      DiagNepro_Clnt_Mname: nephreading.middle_name,
      DiagNepro_Clnt_Lname: nephreading.last_name,
      DiagNepro_Clnt_Ethic: nephreading.ethnic_group
    });
    Diagnosis_Helper.setNephroreading(viewnephro_reading);
    this.messageservice.sendMessage("nephrologyreadingviewpage");
  }

  OnDateMatePicker(e){
    this.date = e;
    this.get_nephrology();
    //this.get_location();
  }
}
