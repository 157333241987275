import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { MatDialog } from '@angular/material/dialog';
import { Client_Helper } from '../Client_helper';
import { Date_Formate } from '../../../assets/js/common';
import { ipaddress } from '../../ipaddress';

@Component({
  selector: 'app-donor-updatedetails',
  templateUrl: './donor-updatedetails.component.html',
  styleUrls: ['./donor-updatedetails.component.css']
})
export class DonorUpdatedetailsComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  public hptlClinicId;
  public donationDate;
  public donorList;
  public sendData;
  public retrivelDonorsListData;
  public donorsList = [];
  public unit = 1;
  public checkAppDonarList = [];
  public sendUpdateDate;
  public updateUrl;

  constructor( private cdRef: ChangeDetectorRef,public gservice:CommonDataService,public toastr:ToastrService, public  http:Http,public  router:Router,public dialog: MatDialog) {
    this.donorList = ipaddress.getIp + "bd/bddet";
    this.updateUrl = ipaddress.getIp + "bd/bdupd";
    this.hptlClinicId = Client_Helper.getblooddonordetail().hptl_clinic_id;
    this.donationDate = Client_Helper.getblooddonordetail().date;
  }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 5,
      "lengthMenu": [[5, 10, 25, 50, -1], [5, 10, 25, 50, "All"]],
      "columnDefs": [
        { "orderable": false, "targets": [0, 1, 2, 3] },
      ],
      "language": {
        "search": "Filter"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12">><"table-responsive"t><"row mt-1 d-flex"<"col-xs-12 col-sm-6 col-md-4"l><"col-xs-12 col-sm-12 col-md-4 text-center sm-order-last"i><"col-xs-12 col-sm-6 col-md-4"p>>'
    };

    if (this.gservice.Client_login_detail_data.Client_Id != undefined) {
      this.sendData = {
        client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
        date: Date_Formate(this.donationDate),
        hptl_clinic_id: this.hptlClinicId
      };
    }
    this.getDonarList(); 
  }

  myCallbackFunction = function (_params) {// callback...
    return new Promise((resolve, reject) => {
      resolve(_params);
    });
  }

  getDonarList() {
    var headers = new Headers;
    headers.append('Content-Type', 'application/json');
    this.http.post(this.donorList, JSON.stringify(this.sendData), { headers: headers }).subscribe(
      data => {
        this.retrivelDonorsListData = data.json().donors;
        if (this.retrivelDonorsListData != null) {
          var middle;
          for (var i = 0; i < this.retrivelDonorsListData.length; i++) {
            if ((this.retrivelDonorsListData[i].middle_name) != null && (this.retrivelDonorsListData[i].middle_name) != undefined) {
              middle = encrypt_decript.Decript(this.retrivelDonorsListData[i].first_name) + ' ' + encrypt_decript.Decript(this.retrivelDonorsListData[i].middle_name) + ' ' + encrypt_decript.Decript(this.retrivelDonorsListData[i].last_name);
            }else {
              middle = encrypt_decript.Decript(this.retrivelDonorsListData[i].first_name) + ' ' + encrypt_decript.Decript(this.retrivelDonorsListData[i].last_name);
            }
            this.donorsList.push({
              client_reg_id: this.retrivelDonorsListData[i].donor,
              name: middle,
              mobile: encrypt_decript.Decript(this.retrivelDonorsListData[i].mobile),
              profile_image: ipaddress.Ip_with_img_address + this.retrivelDonorsListData[i].profile_image,
              check: false
            });
          }
        }
      },error => {});
  }

  minus() {
    var k = 1;
    k -= 1;
    if (k < 1) {
      k = 1;
    }
    this.unit = k;
    this.cdRef.detectChanges();
  }

  plus() {
    var k = 1;
    k += 1;
    if (k < 1) {
      k = 1;
    } else if (k > 2) {
      k = 2;
    }
    this.unit = k;
    this.cdRef.detectChanges();
  }

  checkDonar(client_reg_id, check_value) {
    check_value = check_value == true ? false : true;
    if (check_value == true) {
      for (var i = 0; i < this.donorsList.length; i++) {
        if( client_reg_id == this.donorsList[i].client_reg_id){
          this.donorsList[i].check = true;
          break;
        }
      }
      this.checkAppDonarList.push({
        donor: client_reg_id,
        no_units: this.unit
      });
    }else {
      for (var i = 0; i < this.donorsList.length; i++) {
        if( client_reg_id == this.donorsList[i].client_reg_id){
          this.donorsList[i].check = false;
          break;
        }
      }
      for (var i = 0; i < this.checkAppDonarList.length; i++) {
        if (this.checkAppDonarList[i].donor == client_reg_id) {
          this.checkAppDonarList.splice(i, 1);
          break;
        }
      }
    }
  }

  update() {
    if (this.checkAppDonarList == null || (this.checkAppDonarList != null && this.checkAppDonarList.length == 0)) {
      this.toastr.error("Select donor");
    } else {
      this.sendUpdateDate = {
        requestor: this.gservice.Client_login_detail_data.Client_Id,
        donation_date: Date_Formate(this.donationDate),
        hptl_clinic_id: this.hptlClinicId,
        donors: this.checkAppDonarList
      }
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.updateUrl, JSON.stringify(this.sendUpdateDate), { headers: headers }).subscribe(
        data => {
          this.toastr.success( data.json().result)
        },error => {});
    }
  }
}
