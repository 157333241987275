<div *ngIf="dentalPlanFlag == 'min'">
    <div class="block1_grid">
        <mat-label class="matlabel" [hidden]="invest_hidden">Investigation details<br>
            <textarea #matInput class="ipcss" maxlength="500" [(ngModel)]="invest_details"></textarea>
        </mat-label>
        <mat-label class="matlabel" [hidden]="med_hidden">Medication/Dept referral<br>
            <select class=" ipcss " (change)="med_dept_ref_chage()" disableOptionCentering
                [(ngModel)]="med_dept_ref">
                <option value="Select">Select</option>
                <option *ngFor="let med of med_dept_ref_array" value={{med.description}}>{{med.description}}
                </option>
            </select>
        </mat-label>

        <mat-label class="matlabel">Treatment type<br>
            <textarea #matInput maxlength="50" class="ipcss" [(ngModel)]="den_treatment_id" maxlength="250"
                required></textarea>

            <!-- <select required   class="sdropdown border " [(ngModel)]="den_treatment_id" (selectionChange)="getTreatmentVideos()" disableOptionCentering>
                <option   *ngFor="let treat of treat_array" value={{treat.den_spl_treat_id}}>{{treat.description}}</option>
            </select> -->
        </mat-label>
    </div>

</div>

<div *ngIf="dentalPlanFlag == 'max'">
    <div class="personaldetails">
        <div class="row">

            <div class="col-12 col-md-9 col-lg-9 col-sm-12 col-xl-9" style="border-right:1px solid #2ca8fe">
                <div class="row">
                    <div class="col-6 col-sm-6 col-lg-6 col-md-6 col-xl-4" [hidden]="invest_hidden">
                        <mat-label class="matlabel">Investigation details<br>
                            <textarea #matInput class="ipcss "required  maxlength="500" [(ngModel)]="invest_details" (change)="send_data()"></textarea>
                        </mat-label>
                    </div>
                    <div class="col-6 col-sm-6 col-lg-6 col-md-6 col-xl-4" [hidden]="med_hidden">
                        <mat-label class="matlabel">Medication/Dept referral<br>
                            <select class="ipcss " (change)="med_dept_ref_chage();send_data()" disableOptionCentering
                                [(ngModel)]="med_dept_ref">
                                <option value="Select">Select</option>
                                <option *ngFor="let med of med_dept_ref_array" value={{med.description}}>
                                    {{med.description}}</option>
                            </select>
                        </mat-label>
                    </div>
                    <div class="col-4 col-lg-12 col-md-12 col-xl-8 changes">
                        <div [hidden]="refer_data_hide">
                            <div class="row">
                                <div class="col-12 col-lg-2 col-md-2 col-xl-1">

                                </div>
                                <div class="col-12 col-lg-4 col-md-4 col-xl-4">
                                    <img id="refer_first" src="../../assets/img/default.jpg" class="refer_profimage"
                                        draggable="false">

                                </div>
                                <div class="col-12 col-lg-4 col-md-4 col-xl-6">
                                    <div class="row">
                                        <div class="col-12 col-lg-12 col-md-12 col-xl-12">
                                            <mat-label class="matlabel">Speciality<br>
                                                <select class="ipcss" [(ngModel)]="spl_text" disableOptionCentering (change)="send_data()">
                                                    <option *ngFor="let treat of treat_spl_array"
                                                        value={{treat.description}}>{{treat.description}}
                                                    </option>
                                                </select>
                                            </mat-label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 col-lg-12 col-md-12 col-xl-12">
                                            <mat-label class="matlabel">Doctor name
                                                <input type="text" class="ipcss " required id="country" [(ngModel)]="doc_name"
                                                    (keyup)="spl_search($event)"  matInput [matAutocomplete]="auto"  (change)="send_data()"  />
                                                <mat-autocomplete #auto="matAutocomplete" >
                                                    <mat-option id="optionfont" (click)="doc_name_select(item.doc_reg_id)" *ngFor="let item of doc_name_array"
                                                    value="{{item}}"><span>{{item.first_name+" "}}</span><span *ngIf="item.middle_name != undefined">{{item.middle_name+" "}}</span><span>{{item.last_name+" "}}</span>
                                                </mat-option>
                                                </mat-autocomplete>
                                            </mat-label>
                                            <!-- <mat-label class="matlabel">Doctor name<br>
                                                
                                                <input matInput class="ipcss " (keyup)="spl_search()"
                                                    [(ngModel)]="doc_name" required />
                                                <div class="auto_complete_text" *ngIf="doc_name_array.length > 0">
                                                    <ul *ngFor="let item of doc_name_array"
                                                        style="list-style-type: none;">
                                                        <li>
                                                            <a (click)="doc_name_select(item.doc_reg_id)">{{item.first_name+"
                                                                "+item.middle_name+" "+item.last_name}}</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </mat-label> -->
                                        </div>
                                    </div>
                                </div>

                            </div>


                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 col-sm-12 col-lg-12 col-12 col-xl-12">
                        <p class="tervys_heading">Treatment plan</p>
                    </div>
                </div>
                <!-- <div  class="row"class="row padding_right_Zero" style="padding-left: 13px;">
                   <div class="col-4 col-lg-6 col-md-6 col-xl-4">
                    <mat-label class="matlabel" >Treatment type<br>
                    
                        <select class="ipcss widthappt"  disableOptionCentering [(ngModel)]="den_treatment_id"  >
                            <option *ngFor="let treat of treat_array" value={{treat.den_spl_treat_id}} (click)="getTreatmentVideos(treat.den_spl_treat_id)">{{treat.description}}</option>
                        </select>
                

                </mat-label>
                   </div>
                </div> -->
                <div class="row">
                    <div class="col-6 col-lg-6 col-sm-6 col-md-6 col-xl-4">
                        <mat-label class="matlabel" >Treatment type<br>
                     
                            <mat-select class="ipcss"  disableOptionCentering [(ngModel)]="den_treatment_id" (change)="send_data()" >
                                <mat-option *ngFor="let treat of treat_array" value={{treat.den_spl_treat_id}} (click)="getTreatmentVideos(treat.den_spl_treat_id)">{{treat.description}}</mat-option>
                            </mat-select>
                    
                        <!-- <textarea #matInput maxlength="50" class="ipcss obsttxtareaheight widthappt" [(ngModel)]="den_treatment_id" maxlength="250" required></textarea> -->
                    </mat-label>
                    </div>
                </div>
                <div class="row " [hidden]="phase_hidden">
                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-2">
                        <mat-label class="matlabel">Phase
                            <select class="ipcss" [(ngModel)]="treat_phase" (change)="send_data()">
                                <option value="Phase I">Phase I</option>
                                <option value="Phase II">Phase II</option>
                                <option value="Phase III">Phase III</option>
                                <option value="Phase IV">Phase IV</option>
                                <option value="Phase V">Phase V</option>
                                <option value="Phase VI">Phase VI</option>
                            </select>
                        </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
                        <mat-label class="matlabel">Treatment plan description<br>
                            <textarea #matInput maxlength="50" class="ipcss age" [(ngModel)]="Treateplan_Desc" (change)="send_data()"
                                maxlength="250" required></textarea>
                        </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2">
                        <mat-label class="matlabel">Estimate<br>
                            <input class="ipcss age" matInput [(ngModel)]="estimate_txt" maxlength="6" (change)="send_data()" />
                        </mat-label>
                    </div>
                    <!-- <div class="col-4 col-md-10 col-lg-10 col-xl-1">
                        <mat-label class="matlabel">Estimate<br>
                            <input class="ipcss " matInput  [(ngModel)]="estimate_txt" maxlength="6"/>
                        </mat-label>
                    </div> -->
                    <div class="col-4 col-sm-10 col-md-10 col-lg-10 col-xl-1" [hidden]="treat_plan_hidden">
                            <img (click)="treat_plan_des()" src="../../../assets/ui_icons/buttons/add_button.svg"
                             class="saveimgbtn_inpatinfo addbtn" style="margin-top: 5px;" />
                    </div>
                </div>
                <div class="row ">
                    <div class="col-12" *ngIf="tretemen_plan_array.length !=0">
                        <div class="dig_table_overflow table-responsive">
                            <table id="tbl" >
                                <tbody>
                                    <tr>
                                        <th>Phase</th>
                                        <th>Treatment plan</th>
                                        <th>Estimate</th>
                                        <!-- <th>Edit</th>-->
                                        <th>Action</th>
                                    </tr>
                                    <tr *ngFor="let treate of tretemen_plan_array">
                                        <td style="text-align: left;">{{treate.phase}}</td>
                                        <td style="text-align: left;">{{treate.treatment_desc}}</td>
                                        <td style="text-align: right;">{{treate.estimate}}</td>
                                        <td>
                                            <img src="../../../assets/ui_icons/buttons/trash_icon.svg"  class="saveimgbtn_inpatinfo"  style="width: 22px; cursor: pointer;"  (click)="treate_delete(treate.treatment_desc,treate.estimate)"/>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                       
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 col-lg-12 col-sm-12 col-xl-12">
                        <p class="tervys_heading">Treatment</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 col-sm-6 col-lg-3 col-md-6 col-xl-3">
                        <mat-label class="matlabel">Treatment description<br>
                            <textarea #matInput class="ipcss" required [(ngModel)]="Treatedon_Desc"
                                maxlength="250" (change)="send_data()"></textarea>
                        </mat-label>
                    </div>
                    <div class="col-6 col-sm-6  col-lg-3 col-md-6  col-xl-3">
                        <mat-label class="matlabel">Procedure<br>
                            <textarea #matInput class="ipcss" required [(ngModel)]="Treatedon_Procedure"
                                maxlength="250" (change)="send_data()"></textarea>
                        </mat-label>
                    </div>
                    <div class="col-6 col-sm-4  col-lg-3 col-md-6 col-xl-3">
                        <mat-label class="matlabel">Charges<br>
                            <input class="ipcss " matInput [(ngModel)]="charge_txt" required maxlength="6" (change)="send_data()" />
                        </mat-label>
                    </div>
                    <div class="col-6 col-lg-2 col-md-6 col-sm-6 col-xl-1">
                        <img src="../../../assets/ui_icons/buttons/add_button.svg" (click)="treat_click()"
                                class="saveimgbtn_inpatinfo addbtn" style="margin-top: 5px;"/>
                    </div>
                </div>
                <div class=" row" >
                    <div class="col-12"  [hidden]="treat_doneplan.length==0">
                        <div class="dig_table_overflow table-responsive">
                            <table id="tbl">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Doctor name</th>
                                        <th>Treatment description</th>
                                        <th>Procedure</th>
                                        <th>Charges</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let done of treat_doneplan">
                                        <td style="text-align: left;">{{done.Date}}</td>
                                        <td style="text-align: left;">{{done.Doct_Name}}</td>
                                        <td style="text-align: left;">{{done.treat_desc}}</td>
                                        <td style="text-align: left;">{{done.procedure}}</td>
                                        <td style="text-align: right;">{{done.charges}}</td>
                                        <td >
                                            <img src="../../../assets/ui_icons/buttons/trash_icon.svg"  class="saveimgbtn_inpatinfo"  style="width: 22px; cursor: pointer;" (click)="treatedone_delete(done.treat_desc,done.procedure,done.charges)"/>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-3 col-lg-3 col-sm-6 col-xl-3">
                <div class="row">
                    <div class="col-12" class="paddingdentalplan">
                        <button id="dentalplanbtn" class="buttonst" mat-raised-button disabled="true">case
                            analysis</button>
                    </div>

                </div>
                <div class="row">
                    <div class="col-12" class="paddingdentalplan">
                        <button id="dentalplanbtn" class="buttonst" mat-raised-button disabled="true">Oral hygiene index</button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12" class="paddingdentalplan">
                        <button id="dentalplanbtn" class="buttonst" mat-raised-button disabled="true">Length analysis</button>
                    </div>
                </div>
                <div class="row ">
                    <div class="col-12" class="paddingdentalplan">
                        <button id="dentalplanbtn"  class="buttonst" mat-raised-button disabled="true">Cephalometric analysis</button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12" class="paddingdentalplan">
                        <button id="dentalplanbtn" class="buttonst" mat-raised-button disabled="true">Study model analysis</button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12" class="paddingdentalplan">
                        <p class="casenalysis_more">
                            <u>More &#8594;</u>
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12" class="paddingdentalplan">
                        <button id="dentalplanbtn" class="buttonst" mat-raised-button disabled="true">View analysis</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>