<div class="row" style="width:100%;margin:0 auto;">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Department transfers</h5>
                    </div>
                    <div class="headerButtons">
                        <img *ngIf="newbutton" class="saveimgbtn_inpatinfo" src="../../../assets/ui_icons/buttons/new_button.svg"
                            (click)="createOrder()" />&nbsp;&nbsp;
                        
                            <img style="width: 20px !important;" src="../../../assets/img/filter_icon.png"
                            (click)="optionsPopover()" />
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                        <mat-label class="matlabel" style="margin-top: 7px;">From
                            <input type="date" class="ipcss_date " (change)="selectDate(fromDate,'1')"
                                [(ngModel)]="fromDate" max="{{currentDate}}" #matInput style="width: 140px;">
                        </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                        <mat-label class="matlabel" style="margin-top: 7px;">To
                            <input type="date" class="ipcss_date " (change)="selectDate(toDate,'2')"
                                [(ngModel)]="toDate" max="{{currentDate}}" #matInput style="width: 140px;">
                        </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                        <mat-label class="matlabel"><br>
                            <img src="../../../assets/ui_icons/buttons/search_button.svg" class="saveimgbtn_inpatinfo"
                                (click)="getDeptTransfers()" />
                        </mat-label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-lg-12 col-md-12 col-xl-12">
                        <p class="nodata" *ngIf="orderListArray.length == 0">No currentOrder(s) found</p>
                        <table *ngIf="orderListArray.length" mdbTable datatable [dtOptions]="dtOptions"
                            class="table table-nowrap table-sm dataTable pharmacycurrentorder">
                            <thead class="tableheading">
                                <tr>
                                    <th>Transfer #</th>
                                    <th>Department</th>
                                    <th>Received by </th>
                                    <th>Transfer date </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let list of orderListArray" class="tdvalues"
                                    (click)='orderView(list.order_id, list.rec_name, list.department,list.fin_year)'>
                                    <td style="font-size: 12px;">{{list.order_id}}</td>
                                    <td style="font-size: 12px;text-align: left !important;">{{list.department}}</td>
                                    <td style="font-size: 12px;text-align: left !important;"> {{list.rec_name}}</td>
                                    <td style="font-size: 12px;">{{list.order_date}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>