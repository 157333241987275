import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-diag-reason-dialog',
  templateUrl: './diag-reason-dialog.component.html',
  styleUrls: ['./diag-reason-dialog.component.scss']
})
export class DiagReasonDialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
