<div class="row">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white" style="padding:7px 20px;">
                <div class="d-flex justify-content-between align-items-center">
                    <h5 class="m-0" class="mainHeadingStyle">Profile information</h5>
                    <div>
                        <img src="../../../assets/ui_icons/buttons/update_button.svg" (click)="Update_click()"
                            class="saveimgbtn_inpatinfo" />
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body style="padding-top: 0;">
                <div class="row" style="margin: 0px;">
                    <div class="Details">
                        <mat-accordion class="mataccordion">
                            <mat-expansion-panel class="expandpanel " [expanded]="true">
                                <mat-expansion-panel-header class="exppanel width">
                                    <mat-panel-title class="title">
                                        Personal information
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <hr class="hr">
                                <div class="row" style="margin: 30px 0;">
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-2"
                                        style="text-align: center;position: relative;">
                                        <div style="position: relative;width: 160px;margin: 0 auto;">
                                            <img id="profile_img1" class="profileimg" src="../../assets/img/default.jpg" />
                                            <input type="file" (change)="fileChange($event)" name="profile_pic" id="profile_pic">
                                            <!--  (mouseover)="showsupload=true" (mouseout)="showsupload=false" *ngIf="showsupload"-->
                                            <label for="profile_pic" class="edit_profile_icon"><i
                                                    class="fas fa-pencil-alt"></i></label>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-10">
                                        <div class="row">
                                            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                                                <mat-label class="matlabel">First name
                                                    <input type="text" class="ipcss " disabled="true"
                                                        [(ngModel)]="first_name" matInput />
                                                </mat-label>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                                                <mat-label class="matlabel">Middle name(optional)
                                                    <input type="text" class="ipcss " disabled="true"
                                                        [(ngModel)]="middle_name" matInput />
                                                </mat-label>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                                                <mat-label class="matlabel">Last name
                                                    <input type="text" class="ipcss " disabled="true"
                                                        [(ngModel)]="last_name" matInput />
                                                </mat-label>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                                                <div class="row">
                                                    <div class="col-6">
                                                        <mat-label class="matlabel">Gender<br>
                                                            <input type="text" class="ipcss " disabled="true"
                                                                [(ngModel)]="gender" matInput />
                                                        </mat-label>
                                                    </div>
                                                    <div class="col-6">
                                                        <mat-label class="matlabel">DOB
                                                            <input class="ipcss clienthome_" disabled="true"
                                                                [(ngModel)]="dob" matInput>
                                                        </mat-label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                                                <mat-label class="matlabel">Phone number
                                                    <input type="text" class="ipcss clienthome_" [(ngModel)]="mobile"
                                                        maxlength="10"
                                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                        required matInput />
                                                </mat-label>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                                                <mat-label class="matlabel">Email
                                                    <input type="text" class="ipcss " [(ngModel)]="email" maxlength="50"
                                                        (blur)="checkemail()" matInput />
                                                </mat-label>
                                            </div>
                                            <div class="col-12 col-sm-9 col-md-12 col-lg-9 col-xl-9">
                                                <div class="row" style="margin-top: 10px;">
                                                    <div class="col-4">
                                                        <mat-label class="matlabel">Client's call
                                                            <mat-button-toggle id="toggid" class="togglebtn"
                                                                [(ngModel)]="client_call" ngDefaultControl [checked]="clientCall"
                                                                (change)="change_clientcall($event.source.checked)">
                                                                <span>{{clientCall_lab}}</span>
                                                                <!-- <span *ngIf="!clientCall">No</span>
                                                                <span *ngIf="clientCall">Yes</span> -->
                                                            </mat-button-toggle>
                                                        </mat-label>
                                                    </div>
                                                    <div class="col-4">
                                                        <mat-label class="matlabel" style="margin-top: 1px;">2nd opinion
                                                            <mat-button-toggle id="toggsecopid" class="togglebtn"
                                                                [(ngModel)]="second_opinion" ngDefaultControl [checked]="secopinion"
                                                                (change)="change_secopinion($event.source.checked)">
                                                                <span>{{secop}}</span>
                                                                <!-- <span *ngIf="!secopinion">No</span>
                                                                <span *ngIf="secopinion">Yes</span> -->
                                                            </mat-button-toggle>
                                                        </mat-label>
                                                    </div>
                                                    <div class="col-4">
                                                        <mat-label class="matlabel" style="margin-top: 2px;">Homecare
                                                            <mat-button-toggle id="togghmid" class="togglebtn"
                                                                [checked]="homecare" ngDefaultControl [(ngModel)]="togg_home"
                                                                (change)="change_homecare($event.source.checked)">
                                                                <span>{{homecare_lab}}</span>

                                                                <!-- <span *ngIf="!homecare">No</span>
                                                                <span *ngIf="homecare">Yes</span> -->
                                                            </mat-button-toggle>
                                                        </mat-label>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div [hidden]="Homecare_show">
                                    <p class="tervys_heading">Homecare consultation &nbsp;&nbsp;</p>
                                    <div class="row" style="padding-bottom: 10px;">
                                        <div class="col-12 col-sm-6 col-md-2 col-lg-2 col-xl-2">
                                            <mat-label class="matlabel">Session
                                                <select class="ipcss wid_session" [(ngModel)]="homecare_session"
                                                    disableOptionCentering>
                                                    <option disabled value="">Select</option>
                                                    <option value="Morning">Morning</option>
                                                    <option value="Afternoon">Afternoon</option>
                                                    <option value="Evening">Evening</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel">Starts from</mat-label>
                                            <div class="row">
                                                <div class="col-4">
                                                    <select disableOptionCentering [(ngModel)]="start_time1"
                                                        class="ipcss wid_session_start_end">
                                                        <option *ngFor="let name of starts_from_array"
                                                            value={{name}}>
                                                            {{name}}</option>
                                                    </select>
                                                </div>
                                                <div class="col-4">
                                                    <select disableOptionCentering [(ngModel)]="start_time2"
                                                        class="ipcss wid_session_start_end">
                                                        <option *ngFor="let name of ends_at_array" value={{name}}>
                                                            {{name}}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="col-4">
                                                    <select disableOptionCentering [(ngModel)]="start_time3"
                                                        class="ipcss wid_session_start_end">
                                                        <option value="AM">AM</option>
                                                        <option value="PM">PM</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel">Ends at</mat-label>
                                            <div class="row">
                                                <div class="col-4">
                                                    <select disableOptionCentering [(ngModel)]="end_time1"
                                                        class="ipcss wid_session_start_end">
                                                        <option *ngFor="let name of starts_from_array"
                                                            value={{name}}>
                                                            {{name}}</option>
                                                    </select>
                                                </div>
                                                <div class="col-4">
                                                    <select disableOptionCentering [(ngModel)]="end_time2"
                                                        class="ipcss wid_session_start_end">
                                                        <option *ngFor="let name of ends_at_array" value={{name}}>
                                                            {{name}}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="col-4">
                                                    <select disableOptionCentering [(ngModel)]="end_time3"
                                                        class="ipcss wid_session_start_end">
                                                        <option value="AM">AM</option>
                                                        <option value="PM">PM</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-2 col-lg-2 col-xl-2">
                                            <mat-label class="client_label fee_form1 ">Fee<br>
                                                <input class="ipcss clienthome_" [(ngModel)]="homecare_fee"
                                                    placeholder="0.00" maxlength="5"
                                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                    matInput>
                                            </mat-label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                            <mat-label class="matlabel">
                                                <img src="../../../assets/ui_icons/buttons/add_button.svg"
                                                    class="saveimgbtn_inpatinfo" (click)="homecare_btn_click()"
                                                    style="float: right;" />
                                            </mat-label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="dig_table_overflow" [hidden]="homecare_consult_array.length == 0">
                                            <div class="table-responsive">
                                                <table id="tbl"  class="table table-hover table-dynamic">
                                                    <thead>
                                                        <tr>
                                                            <th>Session</th>
                                                            <th>Starts from</th>
                                                            <th>Ends at</th>
                                                            <th>Fee</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        <tr *ngFor="let getda of homecare_consult_array">
                                                            <td class="align_left">{{getda.session}}</td>
                                                            <td>{{getda.available_from1}}</td>
                                                            <td>{{getda.available_to1}}</td>
                                                            <td class="align_right">{{getda.home_care_fee}}</td>
                                                            <td>
                                                                <a
                                                                    (click)="home_consult_delete(getda.session,getda.available_from1,getda.available_to1)">
                                                                    <img src="../../../assets/ui_icons/buttons/trash_icon.svg" width="15px"
                                                                        height="15px" />
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        </div>

                                    </div>
                                    <p class="tervys_heading">Present address</p>
                                    <div class="row">
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel">Address 1
                                                <input class="ipcss " [(ngModel)]="pres_address1" maxlength="50"
                                                    required matInput>
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel">Address 2
                                                <input class="ipcss " [(ngModel)]="pres_address2" maxlength="50"
                                                    matInput>
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel">Location<br>
                                                <input type="text" class="ipcss " required maxlength="50"
                                                    [(ngModel)]="pres_location_txt" (keyup)="pres_location_fun($event)"
                                                    matInput [matAutocomplete]="auto" />
                                                <mat-autocomplete #auto="matAutocomplete">
                                                    <mat-option (click)="select_pres_location(item)"
                                                        *ngFor="let item of pres_location_filter_List" [value]="item">
                                                        {{item}}
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel">District / City<br>
                                                <select disableOptionCentering class="ipcss cty_" required
                                                    [(ngModel)]="pres_city_select"
                                                    (ngModelChange)="Pres_City_change($event)">
                                                    <option disabled>Select</option>
                                                    <option *ngFor="let city of pres_city_list_data"
                                                        value="{{city.city_id}}">{{city.city_desc}}</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel">State<br>
                                                <select disableOptionCentering class="ipcss " required
                                                    [(ngModel)]="pres_state"
                                                    (ngModelChange)="Present_state_change($event)">
                                                    <option disabled>Select</option>
                                                    <option *ngFor="let state of pres_state_list_data"
                                                        value="{{state.state_id}}">{{state.state_desc}}</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel">Country<br>
                                                <select disableOptionCentering class="ipcss "
                                                    [(ngModel)]="pres_country">
                                                    <option disabled>Select</option>
                                                    <option *ngFor="let country of pres_country_list_data"
                                                        value={{country.country_id}}>{{country.country_desc}}
                                                    </option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel">Zipcode<br>
                                                <input id="zipcode" class="ipcss clienthome_Bgrp" disabled="true"
                                                    maxlength="6" [(ngModel)]="pres_zipcode" matInput />
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel">Contact number<br>
                                                <input type="text" maxlength="14" class="ipcss " matInput
                                                    [(ngModel)]="pres_telephone" maxlength="10"
                                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                    required />
                                            </mat-label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6">
                                            <mat-label class="matlabel wt">
                                                <mat-checkbox color=primary labelPosition="before"
                                                    [(ngModel)]="sameasabove_check" [checked]="sameasabove_check"
                                                    (change)="Sameas_Change($event.checked)"><span
                                                        style="font-weight: 500 !important;">Permanent address</span>
                                                </mat-checkbox>
                                            </mat-label>
                                            <label class="same matlabel">&nbsp; same as above</label>
                                        </div>
                                    </div>
                                    <div [hidden]="perm_div">
                                        <div class="row">
                                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                                <mat-label class="matlabel">Address 1
                                                    <input class="ipcss " [(ngModel)]="perm_address1" maxlength="50"
                                                        required matInput>
                                                </mat-label>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                                <mat-label class="matlabel">Address 2
                                                    <input class="ipcss " [(ngModel)]="perm_address2" maxlength="50"
                                                        matInput>
                                                </mat-label>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                                <mat-label class="matlabel">Location<br>
                                                    <input type="text" class="ipcss " required maxlength="50"
                                                        [(ngModel)]="perm_location_txt"
                                                        (keyup)="perm_location_fun($event)" matInput
                                                        [matAutocomplete]="auto1" />
                                                    <mat-autocomplete #auto1="matAutocomplete">
                                                        <mat-option (click)="select_perm_location(item)"
                                                            *ngFor="let item of perm_location_filter_List"
                                                            [value]="item">
                                                            {{item}}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                </mat-label>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                                <mat-label class="matlabel">District / City<br>
                                                    <select disableOptionCentering class="ipcss cty_" required
                                                        [(ngModel)]="perm_city"
                                                        (ngModelChange)="Perm_City_change($event)">
                                                        <option disabled>Select</option>
                                                        <option *ngFor="let city of perm_city_list_data"
                                                            value="{{city.city_id}}">{{city.city_desc}}</option>
                                                    </select>
                                                </mat-label>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                                <mat-label class="matlabel">State<br>
                                                    <select disableOptionCentering class="ipcss " required
                                                        [(ngModel)]="perm_state"
                                                        (ngModelChange)="Permanent_state_change($event)">
                                                        <option disabled>Select</option>
                                                        <option *ngFor="let state of perm_state_list_data"
                                                            value="{{state.state_id}}">{{state.state_desc}}</option>
                                                    </select>
                                                </mat-label>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                                <mat-label class="matlabel">Country<br>
                                                    <select disableOptionCentering class="ipcss "
                                                        [(ngModel)]="perm_country">
                                                        <option disabled>Select</option>
                                                        <option *ngFor="let country of perm_country_list_data"
                                                            value={{country.country_id}}>{{country.country_desc}}
                                                        </option>
                                                    </select>
                                                </mat-label>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                                <mat-label class="matlabel">Zipcode<br>
                                                    <input id="zipcode" class="ipcss clienthome_Bgrp" disabled="true" maxlength="6" [(ngModel)]="perm_zipcode" matInput />
                                                </mat-label>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                                <mat-label class="matlabel">Contact number<br>
                                                    <input type="text" maxlength="14" class="ipcss " matInput
                                                        [(ngModel)]="perm_telephone" maxlength="10"
                                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                        required />
                                                </mat-label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <p class="tervys_heading">Registration details &nbsp;&nbsp;
                                    </p>
                                    <div class="row">
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel">Licence code
                                                <input type="text" class="ipcss wid_liccode"
                                                    [(ngModel)]="registeration_code" disabled="true" matInput />
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel">Year
                                                <input type="text" class="ipcss wid_session "
                                                    [(ngModel)]="registeration_year" disabled="true" matInput />
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel">State / Country
                                                <input type="text" class="ipcss widin" [(ngModel)]="registeration_state"
                                                    disabled="true" matInput />
                                            </mat-label>
                                        </div>
                                    </div>
                                </div>

                                <!-- <div>
                                    <p class="tervys_heading">Payment mode &nbsp;&nbsp;</p>
                                    <div class="row">
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel">Online
                                                <mat-checkbox color="primary" [(ngModel)]="pay_online"></mat-checkbox>
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel">Cash on visit
                                                <mat-checkbox color="primary" [(ngModel)]="pay_cash"></mat-checkbox>
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel">Insurance
                                                <mat-checkbox color="primary" [(ngModel)]="pay_ins"></mat-checkbox>
                                            </mat-label>
                                        </div>
                                    </div>
                                </div> -->

                                <!-- <div>
                                    <p class="tervys_heading">Medicare type &nbsp;&nbsp;</p>
                                    <div class="row">
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                            <mat-label class="matlabel">Treatment type
                                                <select class="ipcss"
                                                    disableOptionCentering="true" [(ngModel)]="treat_type">
                                                    <option *ngFor="let t_array of treatement_array"
                                                        value={{t_array.value}} (click)="medicare_click(t_array.label,t_array.value)">
                                                        {{t_array.label}}
                                                    </option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                            <mat-label class="matlabel" [hidden]="spl_col">Speciality
                                                <textarea class="ipcss  " [(ngModel)]="spl_type"
                                                    (click)="spl_popup()" matInput></textarea>
                                                <!-- <mat-select *ngIf="!spl_flag" class="ipcss "
                                                    disableOptionCentering="true" multiple>
                                                    <span class="closeoption" (click)="closespl()"><img src="../../../assets/img/close_icon.png" style="    margin: 6px 5px;" width="20px" height="20px" id="close_icd" /></span>
                                                    <mat-option *ngFor="let spl_array of specilization_array"
                                                        value={{spl_array.label}}>
                                                        <mat-checkbox color="primary" [checked]="spl_array.checked"
                                                            (change)="spl_click(spl_array.label,spl_array.value,$event.checked)">
                                                            {{spl_array.label}}</mat-checkbox>
                                                    </mat-option>
                                                </mat-select> --
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel" [hidden]="subspl_col">Sub speciality
                                                <textarea type="text" class="ipcss "
                                                    [(ngModel)]="sub_spl_type" (click)="sub_spl_popup()" matInput></textarea>
                                                <!-- <mat-select *ngIf="!sub_spl_flag" class="ipcss "
                                                    disableOptionCentering="true" multiple>
                                                    <button mat-raised-button id="closetreatment"
                                                        (click)="closesubspl()">close</button>
                                                    <mat-option *ngFor="let subspl_array of subspecilities_array"
                                                        value={{subspl_array.label}}>
                                                        <mat-checkbox color="primary" [checked]="subspl_array.checked"
                                                            (change)="subspl_click(subspl_array.label,subspl_array.value,$event.checked)">
                                                            {{subspl_array.label}}</mat-checkbox>
                                                    </mat-option>
                                                </mat-select> --
                                            </mat-label>
                                        </div>
                                    </div>
                                </div> -->
                            </mat-expansion-panel>
                        </mat-accordion><br>
                        <mat-accordion class="mataccordion">
                            <mat-expansion-panel class="expandpanel" [expanded]="true">
                                <mat-expansion-panel-header class="exppanel width">
                                    <mat-panel-title class="title">
                                        Work location
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <hr class="hr">
                                <div class="row" style="padding-top: 15px;">
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Hospital / Clinic name<br>
                                            <input type="text" class="ipcss " required maxlength="50"
                                                [(ngModel)]="hosp_name_txt" (keyup)="hospital_filter($event)" matInput
                                                [matAutocomplete]="auto10" />
                                            <mat-autocomplete #auto10="matAutocomplete">
                                                <mat-option (click)="select_hospital(item)"
                                                    *ngFor="let item of filteredhospList" value={{item}}>
                                                    {{item}}
                                                </mat-option>
                                            </mat-autocomplete>
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Location<br>
                                            <select disableOptionCentering class="ipcss " required
                                                [(ngModel)]="hosp_loc_txt" (ngModelChange)="Loc_change($event)">
                                                <option disabled>Select</option>
                                                <option *ngFor="let location of location_list_data"
                                                    value={{location}}>
                                                    {{location}}</option>
                                            </select>
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">District / City<br>
                                            <input class="ipcss " [(ngModel)]="hosp_dist" disabled="true" matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">State<br>
                                            <input class="ipcss " [(ngModel)]="hosp_state" disabled="true" matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Country<br>
                                            <input class="ipcss " [(ngModel)]="hosp_country" disabled="true" matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Zip code<br>
                                            <input class="ipcss clienthome_Bgrp" [(ngModel)]="hosp_zip" disabled="true"
                                                matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Address 1<br>
                                            <input type="text" class="ipcss " matInput [(ngModel)]="hosp_address1"
                                                required maxlength="50">
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Address 2<br>
                                            <input type="text" class="ipcss " matInput [(ngModel)]="hosp_address2"
                                                maxlength="50" />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Phone number<br>
                                            <input type="text" class="ipcss clienthome_" matInput
                                                [(ngModel)]="hosp_contact_num" required maxlength="14"
                                                onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Website<br>
                                            <input type="text" class="ipcss " matInput [(ngModel)]="hop_website"
                                                maxlength="100" />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Consultation fee<br>
                                            <input type="text" class="ipcss " matInput [(ngModel)]="hosp_fee"
                                                maxlength="5"
                                                onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <div class="row">
                                            <div class="col-6">
                                                <mat-label class="matlabel">Day<br>
                                                    <select disableOptionCentering class="ipcss "
                                                        [(ngModel)]="hosp_days" required>
                                                        <option disabled>Select</option>
                                                        <option value="Sunday">Sunday</option>
                                                        <option value="Monday">Monday</option>
                                                        <option value="Tuesday">Tuesday</option>
                                                        <option value="Wednesday">Wednesday</option>
                                                        <option value="Thursday">Thursday</option>
                                                        <option value="Friday">Friday</option>
                                                        <option value="Saturday">Saturday</option>
                                                        <option value="Mon - Sat">Mon - Sat</option>
                                                        <option value="All Days">All Days</option>
                                                    </select>
                                                </mat-label>
                                            </div>
                                            <div class="col-6">
                                                <mat-label class="matlabel">Session<br>
                                                    <select disableOptionCentering class="ipcss "
                                                        [(ngModel)]="hosp_session" required>
                                                        <option disabled>Select</option>
                                                        <option value="Morning">Morning</option>
                                                        <option value="Afternoon">Afternoon</option>
                                                        <option value="Evening">Evening</option>
                                                    </select>
                                                </mat-label>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Starts from</mat-label>
                                        <div class="row">
                                            <div class="col-4">
                                                <select disableOptionCentering [(ngModel)]="start_from1"
                                                    class="ipcss wid_session_start_end">
                                                    <option *ngFor="let name of starts_from_array" value={{name}}>
                                                        {{name}}
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col-4">
                                                <select disableOptionCentering [(ngModel)]="start_from2"
                                                    class="ipcss wid_session_start_end">
                                                    <option *ngFor="let name of ends_at_array" value={{name}}>
                                                        {{name}}
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col-4">
                                                <select disableOptionCentering [(ngModel)]="start_from3"
                                                    class="ipcss wid_session_start_end">
                                                    <option value="AM">AM</option>
                                                    <option value="PM">PM</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Ends at</mat-label>
                                        <div class="row">
                                            <div class="col-4">
                                                <select disableOptionCentering [(ngModel)]="end_at1"
                                                    class="ipcss wid_session_start_end">
                                                    <option *ngFor="let name of starts_from_array" value={{name}}>
                                                        {{name}}
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col-4">
                                                <select disableOptionCentering [(ngModel)]="end_at2"
                                                    class="ipcss wid_session_start_end">
                                                    <option *ngFor="let name of ends_at_array" value={{name}}>
                                                        {{name}}
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col-4">
                                                <select disableOptionCentering [(ngModel)]="end_at3"
                                                    class="ipcss wid_session_start_end">
                                                    <option value="AM">AM</option>
                                                    <option value="PM">PM</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel"><br>Consultant
                                            <mat-checkbox color="primary" [(ngModel)]="hops_consult"></mat-checkbox>
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Appointment type</mat-label>
                                        <div class="row">
                                            <div class="col-6">
                                                <mat-checkbox color="primary" [(ngModel)]="app_token"
                                                    labelPosition="before" (change)="app_token_fun()">Token
                                                </mat-checkbox>
                                            </div>
                                            <div class="col-6">
                                                <mat-checkbox color="primary" [(ngModel)]="app_time"
                                                    labelPosition="before" (change)="app_time_fun()">Time</mat-checkbox>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">{{app_type_lbl}} <br>
                                            <input type="text" class="ipcss " matInput [(ngModel)]="count_txt"
                                                maxlength="3" required
                                                onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
                                        </mat-label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div>
                                        <img style="float: right;" src="../../../assets/ui_icons/buttons/add_button.svg"
                                            (click)="work_btn_click()" class="saveimgbtn_inpatinfo" />
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="dig_table_overflow" [hidden]="work_arry.length == 0">
                                        <div class="table-responsive">
                                    <table id="tbl"  class="table table-hover table-dynamic">
                                        <thead>
                                            <tr>
                                                <th hidden></th>
                                                <th>Hospital / Clinic name</th>
                                                <th>Location</th>
                                                <th>Day</th>
                                                <th>Session</th>
                                                <th>Starts from</th>
                                                <th>Ends at</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let work of  work_arry">
                                                <td hidden>{{work.consult_time_id}}</td>
                                                <td class="align_left">{{work.name}}</td>
                                                <td class="align_left">{{work.location}}</td>
                                                <td class="align_left">{{work.day_desc}}</td>
                                                <td class="align_left">{{work.session_desc}}</td>
                                                <td>{{work.available_from}}</td>
                                                <td>{{work.available_to}}</td>
                                                <td>
                                                    <a
                                                        (click)="work_delete(work.consult_time_id,work.name,work.day_desc,work.session_desc,work.available_from)">
                                                        <img src="../../../assets/ui_icons/buttons/trash_icon.svg" width="15px"
                                                            height="15px" />
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion><br>
                        <mat-accordion class="mataccordion">
                            <mat-expansion-panel class="expandpanel" [expanded]="true">
                                <mat-expansion-panel-header class="exppanel width">
                                    <mat-panel-title class="title">
                                        Qualification
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <hr class="hr">
                                <div class="row" style="padding-top: 15px;">
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Degree<br>
                                            <input class="ipcss " [(ngModel)]="qualification_degree" required
                                                maxlength="25" matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">School / College<br>
                                            <input class="ipcss " [(ngModel)]="qualification_clg" maxlength="50"
                                                matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">University<br>
                                            <input class="ipcss " [(ngModel)]="qualification_uni" maxlength="50"
                                                required matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Year of completion<br>
                                            <input class="ipcss " [(ngModel)]="qualification_year" required
                                                maxlength="4"
                                                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                matInput />
                                        </mat-label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div>
                                        <img style="float: right;" src="../../../assets/ui_icons/buttons/add_button.svg"
                                            (click)="qualification_btn_click()" class="saveimgbtn_inpatinfo" />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="dig_table_overflow" [hidden]="qualification_array.length == 0">
                                        <div class="table-responsive">
                                    <table id="tbl" class="table table-hover table-dynamic">
                                        <thead>
                                            <tr>
                                                <th>Degree name</th>
                                                <th>School/College name</th>
                                                <th>University name</th>
                                                <th>Year</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let qualification of  qualification_array">

                                                <td class="align_left">{{qualification.degree}}</td>
                                                <td class="align_left">{{qualification.institute}}</td>
                                                <td class="align_left">{{qualification.university}}</td>
                                                <td>{{qualification.year}}</td>
                                                <td>
                                                    <a (click)="qua_delete(qualification.degree,qualification.year)">
                                                        <img src="../../../assets/ui_icons/buttons/trash_icon.svg" width="15px"
                                                            height="15px" />
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion><br>
                    </div>
                </div>

            </mdb-card-body>
        </mdb-card>
    </div>
</div>