import * as CanvasJS from './canvasjs';

export class Diab_graph {

    static Blood_Graph(Fasting_array_last, Fast_one_Array_last, Fast_onehalf_Array_last, Fast_two_Array_last) {

        CanvasJS.addColorSet("SetColourforChart",
            ['rgb(8,96,0)', 'rgb(255,50,50)', 'rgb(0,47,135)', 'rgb(135, 0, 135)']
        );

        var Blood_Chart = new CanvasJS.Chart("Blood_Glucose_Chart", {
            title: {
                text: ""
            },
            toolTip: {
                fontWeight: "normal",
                fontSize: "15",
                fontFamily: "Consolas,Tohama,verdana",
                fontStyle: "normal",
                borderThickness: 2,

                contentFormatter: function (e) {
                    var content = "";
                    for (var i = 0; i < e.entries.length; i++) {
                        content = e.entries[i].dataPoint.y + " mg/dl" + " on " + CanvasJS.formatDate(e.entries[i].dataPoint.x, "DD-MMM-YYYY");
                    }
                    return content;
                }
            },
            colorSet: "SetColourforChart",
            axisX: {
                labelAngle: 135,
                labelFontSize: "12",
                valueFormatString: "DD-MMM-YYYY",
            },
            axisY: {
                includeZero: true,
                titleFontSize: 15,
                gridThickness: 1,
                //lineThickness: 0,
                tickThickness: 0,
                labelFontSize: 16,
            },

            data: [
                {
                    name: 'Fasting',
                    type: "line",
                    showInLegend: true,
                    dataPoints: Fasting_array_last
                },
                {
                    name: 'Half-hour',
                    type: "line",
                    showInLegend: true,
                    dataPoints: Fast_one_Array_last
                },
                {
                    name: 'One hour',
                    type: "line",
                    showInLegend: true,
                    dataPoints: Fast_onehalf_Array_last
                },
                {
                    name: 'Two hours',
                    type: "line",
                    showInLegend: true,
                    dataPoints: Fast_two_Array_last
                },
            ],
            legend: {
                maxWidth: 500,
                cursor: "pointer",
                itemclick: function (e2) {
                    if (typeof (e2.dataSeries.visible) === "undefined" || e2.dataSeries.visible) {
                        e2.dataSeries.visible = false;
                    }
                    else {
                        e2.dataSeries.visible = true;
                    }

                    Blood_Chart.render();
                }
            }
        });

        Blood_Chart.render();
    }

    // 3 month blood data
    static three_Blood_Graph(Fasting_array_last, Fast_one_Array_last, Fast_onehalf_Array_last, Fast_two_Array_last) {

        CanvasJS.addColorSet("SetColourforChart",
            ['rgb(8,96,0)', 'rgb(255,50,50)', 'rgb(0,47,135)', 'rgb(135, 0, 135)']
        );

        var three_Blood_Chart = new CanvasJS.Chart("Blood_three_Glucose_Chart", {
            title: {
                text: ""
            },
            toolTip: {
                fontWeight: "normal",
                fontSize: "15",
                fontFamily: "Consolas,Tohama,verdana",
                fontStyle: "normal",
                borderThickness: 2,

                contentFormatter: function (e) {
                    var content = "";
                    for (var i = 0; i < e.entries.length; i++) {
                        content = e.entries[i].dataPoint.y + " mg/dl" + " on " + CanvasJS.formatDate(e.entries[i].dataPoint.x, "DD-MMM-YYYY");
                    }
                    return content;
                }
            },
            colorSet: "SetColourforChart",
            axisX: {
                labelAngle: 135,
                labelFontSize: "12",
                valueFormatString: "DD-MMM-YYYY",
            },
            axisY: {
                includeZero: true,
                titleFontSize: 15,
                gridThickness: 1,
                //lineThickness: 0,
                tickThickness: 0,
                labelFontSize: 16,
            },

            data: [
                {
                    name: 'Fasting',
                    type: "line",
                    showInLegend: true,
                    dataPoints: Fasting_array_last
                },
                {
                    name: 'Half-hour',
                    type: "line",
                    showInLegend: true,
                    dataPoints: Fast_one_Array_last
                },
                {
                    name: 'One hour',
                    type: "line",
                    showInLegend: true,
                    dataPoints: Fast_onehalf_Array_last
                },

                {
                    name: 'Two hours',
                    type: "line",
                    showInLegend: true,
                    dataPoints: Fast_two_Array_last
                },
            ],
            legend: {
                maxWidth: 500,
                cursor: "pointer",
                itemclick: function (e2) {
                    if (typeof (e2.dataSeries.visible) === "undefined" || e2.dataSeries.visible) {
                        e2.dataSeries.visible = false;
                    }
                    else {
                        e2.dataSeries.visible = true;
                    }
                    three_Blood_Chart.render();
                }
            }
        });
        three_Blood_Chart.render();
    }

    // 6 month blood data
    static six_Blood_Graph(Fasting_array_last, Fast_one_Array_last, Fast_onehalf_Array_last, Fast_two_Array_last) {

        CanvasJS.addColorSet("SetColourforChart",
            ['rgb(8,96,0)', 'rgb(255,50,50)', 'rgb(0,47,135)', 'rgb(135, 0, 135)']
        );

        var Blood_Chart = new CanvasJS.Chart("Blood_six_Glucose_Chart", {
            title: {
                text: ""
            },
            toolTip: {
                fontWeight: "normal",
                fontSize: "15",
                fontFamily: "Consolas,Tohama,verdana",
                fontStyle: "normal",
                borderThickness: 2,

                contentFormatter: function (e) {
                    var content = "";
                    for (var i = 0; i < e.entries.length; i++) {
                        content = e.entries[i].dataPoint.y + " mg/dl" + " on " + CanvasJS.formatDate(e.entries[i].dataPoint.x, "DD-MMM-YYYY");
                    }
                    return content;
                }
            },
            colorSet: "SetColourforChart",
            axisX: {
                labelAngle: 135,
                labelFontSize: "12",
                valueFormatString: "DD-MMM-YYYY",
            },
            axisY: {
                includeZero: true,
                titleFontSize: 15,
                gridThickness: 1,
                //lineThickness: 0,
                tickThickness: 0,
                labelFontSize: 16,
            },

            data: [
                {
                    name: 'Fasting',
                    type: "line",
                    showInLegend: true,
                    dataPoints: Fasting_array_last
                },
                {
                    name: 'Half-hour',
                    type: "line",
                    showInLegend: true,
                    dataPoints: Fast_one_Array_last
                },
                {
                    name: 'One hour',
                    type: "line",
                    showInLegend: true,
                    dataPoints: Fast_onehalf_Array_last
                },

                {
                    name: 'Two hours',
                    type: "line",
                    showInLegend: true,
                    dataPoints: Fast_two_Array_last
                },
            ],
            legend: {
                maxWidth: 500,
                cursor: "pointer",
                itemclick: function (e2) {
                    if (typeof (e2.dataSeries.visible) === "undefined" || e2.dataSeries.visible) {
                        e2.dataSeries.visible = false;
                    }
                    else {
                        e2.dataSeries.visible = true;
                    }
                    Blood_Chart.render();
                }
            }
        });

        Blood_Chart.render();

    }

    // one month urine graph
    static Urine_Graph(Urine_Glucose_Array_last, Ketones_Array_last, Proteins_Array_last) {

        var Urine_Chart = new CanvasJS.Chart("Urine_Charts",
            {
                title: {
                    text: ""
                },
                toolTip: {
                    fontWeight: "normal",
                    fontSize: "15",
                    fontFamily: "Consolas,Tohama,verdana",
                    fontStyle: "normal",
                    borderThickness: 2,

                    contentFormatter: function (e) {
                        var content = "";

                        for (var i = 0; i < e.entries.length; i++) {
                            if (e.entries[i].dataSeries.name == 'Urine Glucose') {
                                content = e.entries[i].dataPoint.y + " mg/dl" + " on " + CanvasJS.formatDate(e.entries[i].dataPoint.x, "DD-MMM-YYYY");

                            }
                            if (e.entries[i].dataSeries.name == 'Ketone') {
                                content = e.entries[i].dataPoint.y + " mmol/L" + " on " + CanvasJS.formatDate(e.entries[i].dataPoint.x, "DD-MMM-YYYY");
                            }
                            if (e.entries[i].dataSeries.name == 'Protein') {
                                content = e.entries[i].dataPoint.y + " g/dl" + " on " + CanvasJS.formatDate(e.entries[i].dataPoint.x, "DD-MMM-YYYY");
                            }
                        }
                        return content;
                    }
                },
                colorSet: "SetColourforChart",
                axisX: {
                    labelAngle: 135,
                    labelFontSize: "12",
                    valueFormatString: "DD-MMM-YYYY",
                },
                axisY: {
                    includeZero: true,
                    titleFontSize: 15,
                    gridThickness: 1,
                    //lineThickness: 0,
                    tickThickness: 0,
                    labelFontSize: 16,
                },
                data: [
                    {
                        name: 'Urine Glucose',
                        type: "line",
                        showInLegend: true,
                        dataPoints: Urine_Glucose_Array_last
                    },
                    {
                        name: 'Ketone',
                        type: "line",
                        showInLegend: true,
                        dataPoints: Ketones_Array_last
                    },
                    {
                        name: 'Protein',
                        type: "line",
                        showInLegend: true,
                        dataPoints: Proteins_Array_last
                    }
                ],
                legend: {
                    cursor: "pointer",
                    itemclick: function (e1) {
                        if (typeof (e1.dataSeries.visible) === "undefined" || e1.dataSeries.visible) {
                            e1.dataSeries.visible = false;
                        }
                        else {
                            e1.dataSeries.visible = true;
                        }
                        Urine_Chart.render();
                    }
                }
            });
        Urine_Chart.render();
    }

    // one month Cholesterol
    static Cholesterol_Graph(get_hdl, get_ldl, get_trigs, get_vldl) {

        var chart4 = new CanvasJS.Chart("chartContainer6",
            {
                title: {
                    text: ""
                },
                toolTip: {
                    fontWeight: "normal",
                    fontSize: "15",
                    fontFamily: "Consolas,Tohama,verdana",
                    fontStyle: "normal",
                    borderThickness: 2,

                    contentFormatter: function (e) {
                        var content = "";
                        for (var i = 0; i < e.entries.length; i++) {
                            content = e.entries[i].dataPoint.y + " mg/dl" + " on " + CanvasJS.formatDate(e.entries[i].dataPoint.x, "DD-MMM-YYYY");
                        }
                        return content;
                    }
                },
                colorSet: "SetColourforChart",
                axisX: {
                    labelAngle: 135,
                    labelFontSize: "12",
                    valueFormatString: "DD-MMM-YYYY",
                },
                data: [
                    {
                        name: 'HDL',
                        type: "line",
                        showInLegend: true,
                        xValueType: "dateTime",
                        dataPoints: get_hdl
                    },
                    {
                        name: 'LDL',
                        type: "line",
                        showInLegend: true,
                        dataPoints: get_ldl
                    },
                    {
                        name: 'Trigs',
                        type: "line",
                        showInLegend: true,
                        dataPoints: get_trigs
                    },
                    {
                        name: 'VLDL',
                        type: "line",
                        showInLegend: true,
                        dataPoints: get_vldl
                    },

                ],
                legend: {
                    cursor: "pointer",
                    itemclick: function (e) {
                        if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                            e.dataSeries.visible = false;
                        }
                        else {
                            e.dataSeries.visible = true;
                        }
                        chart4.render();
                    }
                }
            });

        chart4.render();
    }

    // three urine graph
    static threeUrine_Graph(Urine_Glucose_Array_last, Ketones_Array_last, Proteins_Array_last) {

        var Urine_Chart = new CanvasJS.Chart("Urine_three_Charts",
            {
                title: {
                    text: ""
                },
                toolTip: {
                    fontWeight: "normal",
                    fontSize: "15",
                    fontFamily: "Consolas,Tohama,verdana",
                    fontStyle: "normal",
                    borderThickness: 2,

                    contentFormatter: function (e) {
                        var content = "";

                        for (var i = 0; i < e.entries.length; i++) {
                            if (e.entries[i].dataSeries.name == 'Urine Glucose') {
                                content = e.entries[i].dataPoint.y + " mg/dl" + " on " + CanvasJS.formatDate(e.entries[i].dataPoint.x, "DD-MMM-YYYY");

                            }
                            if (e.entries[i].dataSeries.name == 'Ketone') {
                                content = e.entries[i].dataPoint.y + " mmol/L" + " on " + CanvasJS.formatDate(e.entries[i].dataPoint.x, "DD-MMM-YYYY");
                            }
                            if (e.entries[i].dataSeries.name == 'Protein') {
                                content = e.entries[i].dataPoint.y + " g/dl" + " on " + CanvasJS.formatDate(e.entries[i].dataPoint.x, "DD-MMM-YYYY");
                            }
                        }
                        return content;
                    }
                },
                colorSet: "SetColourforChart",
                axisX: {
                    labelAngle: 135,
                    labelFontSize: "12",
                    valueFormatString: "DD-MMM-YYYY",
                },
                axisY: {
                    includeZero: true,
                    titleFontSize: 15,
                    gridThickness: 1,
                    //lineThickness: 0,
                    tickThickness: 0,
                    labelFontSize: 16,
                },
                data: [
                    {
                        name: 'Urine Glucose',
                        type: "line",
                        showInLegend: true,
                        dataPoints: Urine_Glucose_Array_last
                    },
                    {
                        name: 'Ketone',
                        type: "line",
                        showInLegend: true,
                        dataPoints: Ketones_Array_last
                    },
                    {
                        name: 'Protein',
                        type: "line",
                        showInLegend: true,
                        dataPoints: Proteins_Array_last
                    }
                ],
                legend: {
                    cursor: "pointer",
                    itemclick: function (e1) {
                        if (typeof (e1.dataSeries.visible) === "undefined" || e1.dataSeries.visible) {
                            e1.dataSeries.visible = false;
                        }
                        else {
                            e1.dataSeries.visible = true;
                        }
                        Urine_Chart.render();
                    }
                }
            });
        Urine_Chart.render();
    }

    // three month Cholesterol
    static threreCholesterol_Graph(get_hdl, get_ldl, get_trigs, get_vldl) {
        var chart4 = new CanvasJS.Chart("coles_three_graph",
            {
                title: {
                    text: ""
                },
                toolTip: {
                    fontWeight: "normal",
                    fontSize: "15",
                    fontFamily: "Consolas,Tohama,verdana",
                    fontStyle: "normal",
                    borderThickness: 2,

                    contentFormatter: function (e) {
                        var content = "";
                        for (var i = 0; i < e.entries.length; i++) {
                            content = e.entries[i].dataPoint.y + " mg/dl" + " on " + CanvasJS.formatDate(e.entries[i].dataPoint.x, "DD-MMM-YYYY");
                        }
                        return content;
                    }
                },
                colorSet: "SetColourforChart",
                axisX: {
                    labelAngle: 135,
                    labelFontSize: "12",
                    valueFormatString: "DD-MMM-YYYY",
                },
                data: [
                    {
                        name: 'HDL',
                        type: "line",
                        showInLegend: true,
                        xValueType: "dateTime",
                        dataPoints: get_hdl
                    },
                    {
                        name: 'LDL',
                        type: "line",
                        showInLegend: true,
                        dataPoints: get_ldl
                    },
                    {
                        name: 'Trigs',
                        type: "line",
                        showInLegend: true,
                        dataPoints: get_trigs
                    },
                    {
                        name: 'VLDL',
                        type: "line",
                        showInLegend: true,
                        dataPoints: get_vldl
                    },

                ],
                legend: {
                    cursor: "pointer",
                    itemclick: function (e) {
                        if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                            e.dataSeries.visible = false;
                        }
                        else {
                            e.dataSeries.visible = true;
                        }
                        chart4.render();
                    }
                }
            });

        chart4.render();
    }

    // six urine graph
    static sixUrine_Graph(Urine_Glucose_Array_last, Ketones_Array_last, Proteins_Array_last) {

        var Urine_Chart = new CanvasJS.Chart("Urine_six_Charts",
            {
                title: {
                    text: ""
                },
                toolTip: {
                    fontWeight: "normal",
                    fontSize: "15",
                    fontFamily: "Consolas,Tohama,verdana",
                    fontStyle: "normal",
                    borderThickness: 2,

                    contentFormatter: function (e) {
                        var content = "";

                        for (var i = 0; i < e.entries.length; i++) {
                            if (e.entries[i].dataSeries.name == 'Urine Glucose') {
                                content = e.entries[i].dataPoint.y + " mg/dl" + " on " + CanvasJS.formatDate(e.entries[i].dataPoint.x, "DD-MMM-YYYY");

                            }
                            if (e.entries[i].dataSeries.name == 'Ketone') {
                                content = e.entries[i].dataPoint.y + " mmol/L" + " on " + CanvasJS.formatDate(e.entries[i].dataPoint.x, "DD-MMM-YYYY");
                            }
                            if (e.entries[i].dataSeries.name == 'Protein') {
                                content = e.entries[i].dataPoint.y + " g/dl" + " on " + CanvasJS.formatDate(e.entries[i].dataPoint.x, "DD-MMM-YYYY");
                            }
                        }
                        return content;
                    }
                },
                colorSet: "SetColourforChart",
                axisX: {
                    labelAngle: 135,
                    labelFontSize: "12",
                    valueFormatString: "DD-MMM-YYYY",
                },
                axisY: {
                    includeZero: true,
                    titleFontSize: 15,
                    gridThickness: 1,
                    //lineThickness: 0,
                    tickThickness: 0,
                    labelFontSize: 16,
                },
                data: [
                    {
                        name: 'Urine Glucose',
                        type: "line",
                        showInLegend: true,
                        dataPoints: Urine_Glucose_Array_last
                    },
                    {
                        name: 'Ketone',
                        type: "line",
                        showInLegend: true,
                        dataPoints: Ketones_Array_last
                    },
                    {
                        name: 'Protein',
                        type: "line",
                        showInLegend: true,
                        dataPoints: Proteins_Array_last
                    }
                ],
                legend: {
                    cursor: "pointer",
                    itemclick: function (e1) {
                        if (typeof (e1.dataSeries.visible) === "undefined" || e1.dataSeries.visible) {
                            e1.dataSeries.visible = false;
                        }
                        else {
                            e1.dataSeries.visible = true;
                        }
                        Urine_Chart.render();
                    }
                }
            });
        Urine_Chart.render();
    }

    // six month Cholesterol
    static sixCholesterol_Graph(get_hdl, get_ldl, get_trigs, get_vldl) {
        var chart4 = new CanvasJS.Chart("coles_six_month",
            {
                title: {
                    text: ""
                },
                toolTip: {
                    fontWeight: "normal",
                    fontSize: "15",
                    fontFamily: "Consolas,Tohama,verdana",
                    fontStyle: "normal",
                    borderThickness: 2,

                    contentFormatter: function (e) {
                        var content = "";
                        for (var i = 0; i < e.entries.length; i++) {
                            content = e.entries[i].dataPoint.y + " mg/dl" + " on " + CanvasJS.formatDate(e.entries[i].dataPoint.x, "DD-MMM-YYYY");
                        }
                        return content;
                    }
                },
                colorSet: "SetColourforChart",
                axisX: {
                    labelAngle: 135,
                    labelFontSize: "12",
                    valueFormatString: "DD-MMM-YYYY",
                },
                data: [
                    {
                        name: 'HDL',
                        type: "line",
                        showInLegend: true,
                        xValueType: "dateTime",
                        dataPoints: get_hdl
                    },
                    {
                        name: 'LDL',
                        type: "line",
                        showInLegend: true,
                        dataPoints: get_ldl
                    },
                    {
                        name: 'Trigs',
                        type: "line",
                        showInLegend: true,
                        dataPoints: get_trigs
                    },
                    {
                        name: 'VLDL',
                        type: "line",
                        showInLegend: true,
                        dataPoints: get_vldl
                    },

                ],
                legend: {
                    cursor: "pointer",
                    itemclick: function (e) {
                        if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                            e.dataSeries.visible = false;
                        }
                        else {
                            e.dataSeries.visible = true;
                        }
                        chart4.render();
                    }
                }
            });

        chart4.render();
    }

    // 1 year blood data
    static oneyear_Blood_Graph(Fasting_array_last, Fast_one_Array_last, Fast_onehalf_Array_last, Fast_two_Array_last) {

        CanvasJS.addColorSet("SetColourforChart",
            ['rgb(8,96,0)', 'rgb(255,50,50)', 'rgb(0,47,135)', 'rgb(135, 0, 135)']
        );


        var Blood_Chart = new CanvasJS.Chart("Blood_oneyear_Glucose_Chart", {
            title: {
                text: ""
            },
            toolTip: {
                fontWeight: "normal",
                fontSize: "15",
                fontFamily: "Consolas,Tohama,verdana",
                fontStyle: "normal",
                borderThickness: 2,

                contentFormatter: function (e) {
                    var content = "";
                    for (var i = 0; i < e.entries.length; i++) {
                        content = e.entries[i].dataPoint.y + " mg/dl" + " on " + CanvasJS.formatDate(e.entries[i].dataPoint.x, "DD-MMM-YYYY");
                    }
                    return content;
                }
            },
            colorSet: "SetColourforChart",
            axisX: {
                labelAngle: 135,
                labelFontSize: "12",
                valueFormatString: "DD-MMM-YYYY",
            },
            axisY: {
                includeZero: true,
                titleFontSize: 15,
                gridThickness: 1,
                //lineThickness: 0,
                tickThickness: 0,
                labelFontSize: 16,
            },

            data: [
                {
                    name: 'Fasting',
                    type: "line",
                    showInLegend: true,
                    dataPoints: Fasting_array_last
                },
                {
                    name: 'Half-hour',
                    type: "line",
                    showInLegend: true,
                    dataPoints: Fast_one_Array_last
                },
                {
                    name: 'One hour',
                    type: "line",
                    showInLegend: true,
                    dataPoints: Fast_onehalf_Array_last
                },

                {
                    name: 'Two hours',
                    type: "line",
                    showInLegend: true,
                    dataPoints: Fast_two_Array_last
                },
            ],
            legend: {
                maxWidth: 500,
                cursor: "pointer",
                itemclick: function (e2) {
                    if (typeof (e2.dataSeries.visible) === "undefined" || e2.dataSeries.visible) {
                        e2.dataSeries.visible = false;
                    }
                    else {
                        e2.dataSeries.visible = true;
                    }
                    Blood_Chart.render();
                }
            }
        });

        Blood_Chart.render();

    }

    // one year urine graph
    static oneyearUrine_Graph(Urine_Glucose_Array_last, Ketones_Array_last, Proteins_Array_last) {

        var Urine_Chart = new CanvasJS.Chart("Urine_1year_Charts",
            {
                title: {
                    text: ""
                },
                toolTip: {
                    fontWeight: "normal",
                    fontSize: "15",
                    fontFamily: "Consolas,Tohama,verdana",
                    fontStyle: "normal",
                    borderThickness: 2,

                    contentFormatter: function (e) {
                        var content = "";

                        for (var i = 0; i < e.entries.length; i++) {
                            if (e.entries[i].dataSeries.name == 'Urine Glucose') {
                                content = e.entries[i].dataPoint.y + " mg/dl" + " on " + CanvasJS.formatDate(e.entries[i].dataPoint.x, "DD-MMM-YYYY");

                            }
                            if (e.entries[i].dataSeries.name == 'Ketone') {
                                content = e.entries[i].dataPoint.y + " mmol/L" + " on " + CanvasJS.formatDate(e.entries[i].dataPoint.x, "DD-MMM-YYYY");
                            }
                            if (e.entries[i].dataSeries.name == 'Protein') {
                                content = e.entries[i].dataPoint.y + " g/dl" + " on " + CanvasJS.formatDate(e.entries[i].dataPoint.x, "DD-MMM-YYYY");
                            }
                        }
                        return content;
                    }
                },
                colorSet: "SetColourforChart",
                axisX: {
                    labelAngle: 135,
                    labelFontSize: "12",
                    valueFormatString: "DD-MMM-YYYY",
                },
                axisY: {
                    includeZero: true,
                    titleFontSize: 15,
                    gridThickness: 1,
                    //lineThickness: 0,
                    tickThickness: 0,
                    labelFontSize: 16,
                },
                data: [
                    {
                        name: 'Urine Glucose',
                        type: "line",
                        showInLegend: true,
                        dataPoints: Urine_Glucose_Array_last
                    },
                    {
                        name: 'Ketone',
                        type: "line",
                        showInLegend: true,
                        dataPoints: Ketones_Array_last
                    },
                    {
                        name: 'Protein',
                        type: "line",
                        showInLegend: true,
                        dataPoints: Proteins_Array_last
                    }
                ],
                legend: {
                    cursor: "pointer",
                    itemclick: function (e1) {
                        if (typeof (e1.dataSeries.visible) === "undefined" || e1.dataSeries.visible) {
                            e1.dataSeries.visible = false;
                        }
                        else {
                            e1.dataSeries.visible = true;
                        }
                        Urine_Chart.render();
                    }
                }
            });
        Urine_Chart.render();
    }

    // oneyearCholesterol_Graph Cholesterol
    static oneyearCholesterol_Graph(get_hdl, get_ldl, get_trigs, get_vldl) {

        var chart4 = new CanvasJS.Chart("coles_1year_month",
            {
                title: {
                    text: ""
                },
                toolTip: {
                    fontWeight: "normal",
                    fontSize: "15",
                    fontFamily: "Consolas,Tohama,verdana",
                    fontStyle: "normal",
                    borderThickness: 2,

                    contentFormatter: function (e) {
                        var content = "";
                        for (var i = 0; i < e.entries.length; i++) {
                            content = e.entries[i].dataPoint.y + " mg/dl" + " on " + CanvasJS.formatDate(e.entries[i].dataPoint.x, "DD-MMM-YYYY");
                        }
                        return content;
                    }
                },
                colorSet: "SetColourforChart",
                axisX: {
                    labelAngle: 135,
                    labelFontSize: "12",
                    valueFormatString: "DD-MMM-YYYY",
                },
                data: [
                    {
                        name: 'HDL',
                        type: "line",
                        showInLegend: true,
                        xValueType: "dateTime",
                        dataPoints: get_hdl
                    },
                    {
                        name: 'LDL',
                        type: "line",
                        showInLegend: true,
                        dataPoints: get_ldl
                    },
                    {
                        name: 'Trigs',
                        type: "line",
                        showInLegend: true,
                        dataPoints: get_trigs
                    },
                    {
                        name: 'VLDL',
                        type: "line",
                        showInLegend: true,
                        dataPoints: get_vldl
                    },

                ],
                legend: {
                    cursor: "pointer",
                    itemclick: function (e) {
                        if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                            e.dataSeries.visible = false;
                        }
                        else {
                            e.dataSeries.visible = true;
                        }
                        chart4.render();
                    }
                }
            });

        chart4.render();
    }

    // 2 year blood data
    static twoyear_Blood_Graph(Fasting_array_last, Fast_one_Array_last, Fast_onehalf_Array_last, Fast_two_Array_last) {

        CanvasJS.addColorSet("SetColourforChart",
            ['rgb(8,96,0)', 'rgb(255,50,50)', 'rgb(0,47,135)', 'rgb(135, 0, 135)']
        );


        var Blood_Chart = new CanvasJS.Chart("Blood_twoyear_Glucose_Chart", {
            title: {
                text: ""
            },
            toolTip: {
                fontWeight: "normal",
                fontSize: "15",
                fontFamily: "Consolas,Tohama,verdana",
                fontStyle: "normal",
                borderThickness: 2,

                contentFormatter: function (e) {
                    var content = "";
                    for (var i = 0; i < e.entries.length; i++) {
                        content = e.entries[i].dataPoint.y + " mg/dl" + " on " + CanvasJS.formatDate(e.entries[i].dataPoint.x, "DD-MMM-YYYY");
                    }
                    return content;
                }
            },
            colorSet: "SetColourforChart",
            axisX: {
                labelAngle: 135,
                labelFontSize: "12",
                valueFormatString: "DD-MMM-YYYY",
            },
            axisY: {
                includeZero: true,
                titleFontSize: 15,
                gridThickness: 1,
                //lineThickness: 0,
                tickThickness: 0,
                labelFontSize: 16,
            },

            data: [
                {
                    name: 'Fasting',
                    type: "line",
                    showInLegend: true,
                    dataPoints: Fasting_array_last
                },
                {
                    name: 'Half-hour',
                    type: "line",
                    showInLegend: true,
                    dataPoints: Fast_one_Array_last
                },
                {
                    name: 'One hour',
                    type: "line",
                    showInLegend: true,
                    dataPoints: Fast_onehalf_Array_last
                },

                {
                    name: 'Two hours',
                    type: "line",
                    showInLegend: true,
                    dataPoints: Fast_two_Array_last
                },
            ],
            legend: {
                maxWidth: 500,
                cursor: "pointer",
                itemclick: function (e2) {
                    if (typeof (e2.dataSeries.visible) === "undefined" || e2.dataSeries.visible) {
                        e2.dataSeries.visible = false;
                    }
                    else {
                        e2.dataSeries.visible = true;
                    }
                    Blood_Chart.render();
                }
            }
        });

        Blood_Chart.render();

    }

    // two year urine graph
    static twoyearUrine_Graph(Urine_Glucose_Array_last, Ketones_Array_last, Proteins_Array_last) {

        var Urine_Chart = new CanvasJS.Chart("Urine_2year_Charts",
            {
                title: {
                    text: ""
                },
                toolTip: {
                    fontWeight: "normal",
                    fontSize: "15",
                    fontFamily: "Consolas,Tohama,verdana",
                    fontStyle: "normal",
                    borderThickness: 2,

                    contentFormatter: function (e) {
                        var content = "";

                        for (var i = 0; i < e.entries.length; i++) {
                            if (e.entries[i].dataSeries.name == 'Urine Glucose') {
                                content = e.entries[i].dataPoint.y + " mg/dl" + " on " + CanvasJS.formatDate(e.entries[i].dataPoint.x, "DD-MMM-YYYY");

                            }
                            if (e.entries[i].dataSeries.name == 'Ketone') {
                                content = e.entries[i].dataPoint.y + " mmol/L" + " on " + CanvasJS.formatDate(e.entries[i].dataPoint.x, "DD-MMM-YYYY");
                            }
                            if (e.entries[i].dataSeries.name == 'Protein') {
                                content = e.entries[i].dataPoint.y + " g/dl" + " on " + CanvasJS.formatDate(e.entries[i].dataPoint.x, "DD-MMM-YYYY");
                            }
                        }
                        return content;
                    }
                },
                colorSet: "SetColourforChart",
                axisX: {
                    labelAngle: 135,
                    labelFontSize: "12",
                    valueFormatString: "DD-MMM-YYYY",
                },
                axisY: {
                    includeZero: true,
                    titleFontSize: 15,
                    gridThickness: 1,
                    //lineThickness: 0,
                    tickThickness: 0,
                    labelFontSize: 16,
                },
                data: [
                    {
                        name: 'Urine Glucose',
                        type: "line",
                        showInLegend: true,
                        dataPoints: Urine_Glucose_Array_last
                    },
                    {
                        name: 'Ketone',
                        type: "line",
                        showInLegend: true,
                        dataPoints: Ketones_Array_last
                    },
                    {
                        name: 'Protein',
                        type: "line",
                        showInLegend: true,
                        dataPoints: Proteins_Array_last
                    }
                ],
                legend: {
                    cursor: "pointer",
                    itemclick: function (e1) {
                        if (typeof (e1.dataSeries.visible) === "undefined" || e1.dataSeries.visible) {
                            e1.dataSeries.visible = false;
                        }
                        else {
                            e1.dataSeries.visible = true;
                        }
                        Urine_Chart.render();
                    }
                }
            });
        Urine_Chart.render();
    }

    // twoyearCholesterol_Graph Cholesterol
    static twoyearCholesterol_Graph(get_hdl, get_ldl, get_trigs, get_vldl) {

        var chart4 = new CanvasJS.Chart("coles_2year_month",
            {
                title: {
                    text: ""
                },
                toolTip: {
                    fontWeight: "normal",
                    fontSize: "15",
                    fontFamily: "Consolas,Tohama,verdana",
                    fontStyle: "normal",
                    borderThickness: 2,

                    contentFormatter: function (e) {
                        var content = "";
                        for (var i = 0; i < e.entries.length; i++) {
                            content = e.entries[i].dataPoint.y + " mg/dl" + " on " + CanvasJS.formatDate(e.entries[i].dataPoint.x, "DD-MMM-YYYY");
                        }
                        return content;
                    }
                },
                colorSet: "SetColourforChart",
                axisX: {
                    labelAngle: 135,
                    labelFontSize: "12",
                    valueFormatString: "DD-MMM-YYYY",
                },
                data: [
                    {
                        name: 'HDL',
                        type: "line",
                        showInLegend: true,
                        xValueType: "dateTime",
                        dataPoints: get_hdl
                    },
                    {
                        name: 'LDL',
                        type: "line",
                        showInLegend: true,
                        dataPoints: get_ldl
                    },
                    {
                        name: 'Trigs',
                        type: "line",
                        showInLegend: true,
                        dataPoints: get_trigs
                    },
                    {
                        name: 'VLDL',
                        type: "line",
                        showInLegend: true,
                        dataPoints: get_vldl
                    },

                ],
                legend: {
                    cursor: "pointer",
                    itemclick: function (e) {
                        if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                            e.dataSeries.visible = false;
                        }
                        else {
                            e.dataSeries.visible = true;
                        }
                        chart4.render();
                    }
                }
            });

        chart4.render();
    }

    static HbA1c_Graph(get_hba1_dat) {


        var chart2 = new CanvasJS.Chart("chartContainer5",
            {
                title: {
                    text: ""
                },
                toolTip: {
                    fontWeight: "normal",
                    fontSize: "15",
                    fontFamily: "Consolas,Tohama,verdana",
                    fontStyle: "normal",
                    borderThickness: 2,

                    contentFormatter: function (e) {
                        var content = "";
                        for (var i = 0; i < e.entries.length; i++) {
                            content = e.entries[i].dataPoint.y + " mg/dl" + " on " + CanvasJS.formatDate(e.entries[i].dataPoint.x, "DD-MMM-YYYY");
                        }
                        return content;
                    }
                },
                colorSet: "SetColourforChart",
                axisX: {
                    labelAngle: 135,
                    labelFontSize: "12",
                    valueFormatString: "DD-MMM-YYYY",
                },
                data: [

                    {
                        name: 'HbA1c',
                        type: "line",
                        showInLegend: true,
                        dataPoints: get_hba1_dat

                    }
                ],
                legend: {
                    cursor: "pointer",
                    itemclick: function (e1) {
                        if (typeof (e1.dataSeries.visible) === "undefined" || e1.dataSeries.visible) {
                            e1.dataSeries.visible = false;
                        }
                        else {
                            e1.dataSeries.visible = true;
                        }
                        chart2.render();
                    }
                }
            });
        chart2.render();

    }

    static threeHbA1c_Graph(get_hba1_dat) {
        var chart2 = new CanvasJS.Chart("hbA1c_three",
            {
                title: {
                    text: ""
                },
                toolTip: {
                    fontWeight: "normal",
                    fontSize: "15",
                    fontFamily: "Consolas,Tohama,verdana",
                    fontStyle: "normal",
                    borderThickness: 2,

                    contentFormatter: function (e) {
                        var content = "";
                        for (var i = 0; i < e.entries.length; i++) {
                            content = e.entries[i].dataPoint.y + " mg/dl" + " on " + CanvasJS.formatDate(e.entries[i].dataPoint.x, "DD-MMM-YYYY");
                        }
                        return content;
                    }
                },
                colorSet: "SetColourforChart",
                axisX: {
                    labelAngle: 135,
                    labelFontSize: "12",
                    valueFormatString: "DD-MMM-YYYY",
                },
                data: [

                    {
                        name: 'HbA1c',
                        type: "line",
                        showInLegend: true,
                        dataPoints: get_hba1_dat

                    }
                ],
                legend: {
                    cursor: "pointer",
                    itemclick: function (e1) {
                        if (typeof (e1.dataSeries.visible) === "undefined" || e1.dataSeries.visible) {
                            e1.dataSeries.visible = false;
                        }
                        else {
                            e1.dataSeries.visible = true;
                        }
                        chart2.render();
                    }
                }
            });
        chart2.render();

    }

    // 6 month HbA1c
    static sixHbA1c_Graph(get_hba1_dat) {
        var chart2 = new CanvasJS.Chart("HbA1c_six",
            {
                title: {
                    text: ""
                },
                toolTip: {
                    fontWeight: "normal",
                    fontSize: "15",
                    fontFamily: "Consolas,Tohama,verdana",
                    fontStyle: "normal",
                    borderThickness: 2,

                    contentFormatter: function (e) {
                        var content = "";
                        for (var i = 0; i < e.entries.length; i++) {
                            content = e.entries[i].dataPoint.y + " mg/dl" + " on " + CanvasJS.formatDate(e.entries[i].dataPoint.x, "DD-MMM-YYYY");
                        }
                        return content;
                    }
                },
                colorSet: "SetColourforChart",
                axisX: {
                    labelAngle: 135,
                    labelFontSize: "12",
                    valueFormatString: "DD-MMM-YYYY",
                },
                data: [

                    {
                        name: 'HbA1c',
                        type: "line",
                        showInLegend: true,
                        dataPoints: get_hba1_dat

                    }
                ],
                legend: {
                    cursor: "pointer",
                    itemclick: function (e1) {
                        if (typeof (e1.dataSeries.visible) === "undefined" || e1.dataSeries.visible) {
                            e1.dataSeries.visible = false;
                        }
                        else {
                            e1.dataSeries.visible = true;
                        }
                        chart2.render();
                    }
                }
            });
        chart2.render();

    }

    // 1 year month HbA1c
    static oneyearHbA1c_Graph(get_hba1_dat) {
        var chart2 = new CanvasJS.Chart("HbA1c_1year",
            {
                title: {
                    text: ""
                },
                toolTip: {
                    fontWeight: "normal",
                    fontSize: "15",
                    fontFamily: "Consolas,Tohama,verdana",
                    fontStyle: "normal",
                    borderThickness: 2,

                    contentFormatter: function (e) {
                        var content = "";
                        for (var i = 0; i < e.entries.length; i++) {
                            content = e.entries[i].dataPoint.y + " mg/dl" + " on " + CanvasJS.formatDate(e.entries[i].dataPoint.x, "DD-MMM-YYYY");
                        }
                        return content;
                    }
                },
                colorSet: "SetColourforChart",
                axisX: {
                    labelAngle: 135,
                    labelFontSize: "12",
                    valueFormatString: "DD-MMM-YYYY",
                },
                data: [

                    {
                        name: 'HbA1c',
                        type: "line",
                        showInLegend: true,
                        dataPoints: get_hba1_dat

                    }
                ],
                legend: {
                    cursor: "pointer",
                    itemclick: function (e1) {
                        if (typeof (e1.dataSeries.visible) === "undefined" || e1.dataSeries.visible) {
                            e1.dataSeries.visible = false;
                        }
                        else {
                            e1.dataSeries.visible = true;
                        }
                        chart2.render();
                    }
                }
            });
        chart2.render();

    }

    // 2 year month HbA1c
    static twoyearHbA1c_Graph(get_hba1_dat) {
        var chart2 = new CanvasJS.Chart("HbA1c_2year",
            {
                title: {
                    text: ""
                },
                toolTip: {
                    fontWeight: "normal",
                    fontSize: "15",
                    fontFamily: "Consolas,Tohama,verdana",
                    fontStyle: "normal",
                    borderThickness: 2,

                    contentFormatter: function (e) {
                        var content = "";
                        for (var i = 0; i < e.entries.length; i++) {
                            content = e.entries[i].dataPoint.y + " mg/dl" + " on " + CanvasJS.formatDate(e.entries[i].dataPoint.x, "DD-MMM-YYYY");
                        }
                        return content;
                    }
                },
                colorSet: "SetColourforChart",
                axisX: {
                    labelAngle: 135,
                    labelFontSize: "12",
                    valueFormatString: "DD-MMM-YYYY",
                },
                data: [

                    {
                        name: 'HbA1c',
                        type: "line",
                        showInLegend: true,
                        dataPoints: get_hba1_dat

                    }
                ],
                legend: {
                    cursor: "pointer",
                    itemclick: function (e1) {
                        if (typeof (e1.dataSeries.visible) === "undefined" || e1.dataSeries.visible) {
                            e1.dataSeries.visible = false;
                        }
                        else {
                            e1.dataSeries.visible = true;
                        }
                        chart2.render();
                    }
                }
            });
        chart2.render();

    }
}