<div class="row" style="margin:0 auto; width:100%">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Diet Tracking </h5>
                    </div>
                    <div class="headerButtons">
                        <div style="width: 385px;">
                            <img class="saveimgbtn_inpatinfo" (click)="save()"
                                src="../../../assets/ui_icons/buttons/save_button.svg" />
                        </div>
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>
                <div class="billAreaCover">
                    <div class="accordion accordion-flush" id="accordionFlushExample">
                        <div class="accordion-item" style="background: none; height: 36px;">
                            <h2 class="accordion-header" id="flush-headingOne">
                                <div class="container-fluid coverRow">
                                    <div class="row" style="text-align: center;">
                                        <div class="col-2 my-auto " data-bs-toggle="collapse"
                                            data-bs-target="#flush-collapseOne" aria-expanded="true"
                                            aria-controls="flush-collapseOne">Date</div>
                                    </div>

                                </div>
                            </h2>
                        </div>
                    </div>
                    <div class="contanier-flud p-0">
                       
                        <div class="row cardCover">

                            <div class="col-2" style="text-align:center; padding-right: 0; margin: 13px 0;border-right: 1px solid #81caf4;">
                               <div *ngFor="let days of displayArray;let i = index" class="dateCover">
                                    <div [ngStyle]="days.selected == true ? {'width': '100%' } : {'width' : '90%'}" >
                                        <div class="dateLable" [ngStyle]="days.selected == true ? {'border': '1px solid #81caf4', 'border-right' : 'none'} : {}" (click)="slectedDay(days.day)">{{days.date}} <br> ({{days.day}})</div>
                                    </div>
                                </div> 
                            </div>
                            <div class="col-10" style="margin: 12px 0 14px 0px; border-top: 1px solid #81caf4; border-bottom: 1px solid #81caf4;">
                                <div *ngFor="let days of displayArray;let i = index">
                                    <div class="row" *ngIf="days.selected == true">
                                        <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3" *ngFor="let session of days.session;let i = index">
                                            <div class="card readingCard">
                                                <div class="card-header readignCardHeader" [ngStyle]="session.checked == true ? {'background': 'linear-gradient(90.05deg, #7DC771 1.86%, #DEFAD4 98.46%)', 'border-right' : 'none'} : {'background' : ' linear-gradient(to right, #51b0e7, #6fbdec, #8acaf1, #a3d7f6, #bbe4fc)'}">
                                                    {{session.lableName}}   
                                                    <div style="float: right;">
                                                        <img *ngIf="session.checked == false" [attr.disabled]="session.disabled ? true : null"  (click)="isSelected(session,'add',days.date)" src="../../../assets/ui_icons/diet_plan/check_box.svg"  style="cursor: pointer;" width="15px" />
                                                        <img *ngIf="session.checked == true && session.disabled == false" [attr.disabled]="session.disabled ? true : null" (click)="isSelected(session,'remove',days.date)" src="../../../assets/ui_icons/diet_plan/check_box_tick_mark.svg" style="cursor: pointer;" width="15px" />
                                                        <img *ngIf="session.checked == true && session.disabled == true" src="../../../assets/ui_icons/diet_plan/check_box_tick_mark.svg" style="cursor: pointer;" width="15px" />
                                                    </div>
                                                </div>
                                                <div class="card-body " style="padding: 0px;margin: 4px 8px 0px;">
                                                    <div *ngIf="session.checked == true && session.disabled == false ">
                                                        <div *ngIf="session.type == 'Morning'">
                                                            <mat-label class="matlabel">Add food item
                                                                <br>
                                                                <div>
                                                                    <input type="text" class="ipcss mainDishInput"
                                                                        [(ngModel)]="morningFoods" name="morningFoods"
                                                                        (keyup)="foodType(morningFoods, session.type, session)"
                                                                        type="text" aria-label="Number" matInput
                                                                        [matAutocomplete]="autoMorning" />
                                                                    <mat-autocomplete #autoMorning="matAutocomplete">
                                                                        <mat-option id="optionfont"
                                                                            (click)="selctedFood(days.date,days.day,food,'1')"
                                                                            *ngFor="let food of morningFoodItem"
                                                                            [value]="food.description">
                                                                            {{food.displayContent}}
                                                                        </mat-option>
                                                                    </mat-autocomplete>
                                                                </div>
                                                            </mat-label>
                                                        </div>
                                                        <div *ngIf="session.type == 'Breakfast'">
                                                            <mat-label class="matlabel">Add food item</mat-label>
                                                            
                                                            <br>
                                                            <input type="text" class="ipcss mainDishInput"
                                                                [(ngModel)]="BreakfastFoods" name="BreakfastFoods"
                                                                (keyup)="foodType(BreakfastFoods, session.type,session)" type="text"
                                                                aria-label="Number" matInput [matAutocomplete]="autoBreakfast" />
                                                            <mat-autocomplete #autoBreakfast="matAutocomplete">
                                                                <mat-option id="optionfont"
                                                                    (click)="selctedFood(days.date,days.day,food,'1')"
                                                                    *ngFor="let food of breadfastFoodItem"
                                                                    [value]="food.description">
                                                                    {{food.displayContent}}
                                                                </mat-option>
                                                            </mat-autocomplete>
                                                        </div>
                                                        <div *ngIf="session.type == 'Mid-day'">
                                                            <mat-label class="matlabel">Add food item</mat-label>
                                                            <br>
                                                            <input type="text" class="ipcss mainDishInput" [(ngModel)]="MidDayFoods"
                                                                name="MidDayFoods"
                                                                (keyup)="foodType(MidDayFoods, session.type, session)" type="text"
                                                                aria-label="Number" matInput [matAutocomplete]="autoMidDay" />
                                                            <mat-autocomplete #autoMidDay="matAutocomplete">
                                                                <mat-option id="optionfont"
                                                                    (click)="selctedFood(days.date,days.day,food,'1')"
                                                                    *ngFor="let food of midDayFoodItem" [value]="food.description">
                                                                    {{food.displayContent}}
                                                                </mat-option>
                                                            </mat-autocomplete>
                                                        </div>
                                                        <div *ngIf="session.type == 'Lunch'">
                                                            <mat-label class="matlabel">Add food item</mat-label>
                                                            <br>
                                                            <input type="text" class="ipcss mainDishInput" [(ngModel)]="LunchFoods"
                                                                name="LunchFoods" (keyup)="foodType(LunchFoods, session.type, session)" type="text" aria-label="Number" matInput [matAutocomplete]="autoLunchFoods" />
                                                            <mat-autocomplete #autoLunchFoods="matAutocomplete">
                                                                <mat-option id="optionfont"
                                                                    (click)="selctedFood(days.date,days.day,food,'1')"
                                                                    *ngFor="let food of lunchFoodItem" [value]="food.description">
                                                                    {{food.displayContent}}
                                                                </mat-option>
                                                            </mat-autocomplete>
                                                        </div>
                                                        <div *ngIf="session.type == 'Evening'">
                                                            <mat-label class="matlabel">Add food item</mat-label>
                                                            
                                                            <br>
                                                            <input type="text" class="ipcss mainDishInput"
                                                                [(ngModel)]="EveningFoods" name="EveningFoods"
                                                                (keyup)="foodType(EveningFoods, session.type, session)" type="text"
                                                                aria-label="Number" matInput [matAutocomplete]="autoEveningFoods" />
                                                            <mat-autocomplete #autoEveningFoods="matAutocomplete">
                                                                <mat-option id="optionfont"
                                                                    (click)="selctedFood(days.date,days.day,food,'1')"
                                                                    *ngFor="let food of eveningFoodItem" [value]="food.description">
                                                                    {{food.displayContent}}
                                                                </mat-option>
                                                            </mat-autocomplete>
                                                        </div>
                                                        <div *ngIf="session.type == 'Dinner'">
                                                            <mat-label class="matlabel">Add food item</mat-label>
                                                            <br>
                                                            <input type="text" class="ipcss mainDishInput" [(ngModel)]="DinnerFoods"
                                                                name="DinnerFoods"
                                                                (keyup)="foodType(DinnerFoods, session.type, session)" type="text"
                                                                aria-label="Number" matInput [matAutocomplete]="autoDinnerFoods" />
                                                            
                                                            <mat-autocomplete #autoDinnerFoods="matAutocomplete">
                                                                <mat-option id="optionfont"
                                                                    (click)="selctedFood(days.date,days.day,food,'1')"
                                                                    *ngFor="let food of dinnerFoodItem" [value]="food.description">
                                                                    {{food.displayContent}}
                                                                </mat-option>
                                                            </mat-autocomplete>
                                                        </div>
                                                        <div *ngIf="session.type == 'Night'">
                                                            <mat-label class="matlabel">Add food item</mat-label>
                                                            
                                                            <br>
                                                            <input type="text" class="ipcss mainDishInput" [(ngModel)]="NightFoods"
                                                                name="NightFoods"
                                                                (keyup)="foodType(NightFoods, session.type, session)" type="text"
                                                                aria-label="Number" matInput [matAutocomplete]="autoNightFoods" />
                                                            
                                                            <mat-autocomplete #autoNightFoods="matAutocomplete">
                                                                <mat-option id="optionfont"
                                                                    (click)="selctedFood(days.date,days.day,food,'1')"
                                                                    *ngFor="let food of nightFoodItem" [value]="food.description">
                                                                    {{food.displayContent}}
                                                                </mat-option>
                                                            </mat-autocomplete>
                                                        </div>
                                                    </div>
                                                    
                                                    <div class="foodItemCover">
                                                        <div *ngFor="let foodList of session.foodItems" style="margin-top: 7px;    display: flex;">

                                                            <span style="width: 23px;" *ngIf="session.checked == true">
                                                                <img *ngIf="foodList.checked == true && session.disabled == true" src="../../../assets/ui_icons/popup_tick_mark.svg" style="cursor: pointer;margin: 0px 17%;" width="15px"  />

                                                                <img *ngIf="foodList.checked == true && session.disabled == false" src="../../../assets/ui_icons/popup_tick_mark.svg" style="cursor: pointer;margin: 0px 17%;" width="15px" (click)="selectFood(foodList,'remove',days.date,session.type)" />

                                                                <img *ngIf="foodList.checked == false" src="../../../assets/ui_icons/yellow_box.svg" style="cursor: pointer;margin: 0px 17%;" width="15px" (click)="selectFood(foodList,'add',days.date,session.type)" />

                                                            </span>
                                                            <div class="foodLable"> {{ foodList.description }} - {{foodList.cals_weight}} <span *ngIf="foodList.wightMesure"> ({{foodList.wightMesure}}) </span> </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>