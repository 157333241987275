<div class="row" style="width:100%;margin:0 auto;">
    <div class="col-12">
      <mdb-card>
        <mdb-card-header class="bg-white">
          <div class="headerCover">
            <div class="headerTilte app_list_header">
              <h5 class="m-0" class="mainHeadingStyle">{{pagename}}</h5>
            </div>
          
            <div class="headerButtons">
              <!-- <a style="float: right;margin-left: 5px;" (click)="closeInpatient()" *ngIf="dischargebutton">
                <img src="../../../assets/ui_icons/buttons/discharged.svg" width="85px" /></a>
              <a style="float: right;margin-left: 5px;" (click)="openDiscahrgeTimeLinePage()">
                <img src="../../../assets/ui_icons/buttons/discharge_time_line.svg" width="85px"  /></a>
              <a style="float: right;margin-left: 5px;" (click)="create()" [hidden]="create_flag">
                <img src="../../../assets/ui_icons/buttons/new_button.svg" class="saveimgbtn_inpatinfo"></a> -->
            </div>
          </div>
        </mdb-card-header>
        <mdb-card-body>
          <div class="row" style="margin-top: -10px">
            <div class="col-10">
              <div class="row">
                <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-2 select_bottom" *ngIf="!wardTransfer_flag">
                  <mat-label class="matlabel">Doctor<br>
                    <select class="ipcss widthappt" (ngModelChange)="changeDoctor($event)" [(ngModel)]="doctor">
                      <option *ngFor="let doctors of doctorList" value={{doctors.Doc_ID}}>{{doctors.Doc_Name}}
                      </option>
                    </select>
                  </mat-label>
                </div>
  
                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2" *ngIf="!wardTransfer_flag">
                  <mat-label class="matlabel">View<br>
                    <select disableOptionCentering class="ipcss widthappt" (change)="viewChange(view_type)"
                      [(ngModel)]="view_type">
                      <option value="graph">Graphical</option>                
                      <option value="tabular">Tabular</option>
                    </select>
                  </mat-label>
                </div>
  
                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2" *ngIf="!wardTransfer_flag">
                  <mat-label class="matlabel">
                    <div class="flex-container">
                      <div class="label">Floor</div>
                      <select class="ipcss widthappt" (ngModelChange)="changefloorvalue($event)" [(ngModel)]="floorvalue">
                        <option *ngFor="let floorcheck of floorarray" [value]="floorcheck">{{ floorcheck }}</option>
                      </select>
                    </div>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2">
                  <mat-label class="matlabel">Filter by<br>
                    <select disableOptionCentering class="ipcss widthappt" (change)="Filterbydata(selectvalue)"
                      [(ngModel)]="selectvalue" >
                      <option value="mrno">MR no</option>
                      <option value="date">Date</option>
                      <option value="name">Name</option>
                      <option value="location">Location</option>                   
                      <option value="mobile">Mobile</option>
                    </select>
                  </mat-label>
                </div>
  
                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2" *ngIf="mrnoflag">
                  <mat-label class="matlabel">MR no<br>
                    <input type="text" style="height: 25px;" class="ipcss inpat_width" [(ngModel)]="mrno" matInput (keyup)="getdatavaluemrno($event)" />
                  </mat-label>
                </div>
  
                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2" *ngIf="mobileflag">
                  <mat-label class="matlabel">Mobile<br>
                    <input type="text" style="height: 25px;" class="ipcss inpat_width" [(ngModel)]="mobile" matInput (keyup)="getdatamobilenumber($event)" />
                  </mat-label>
                </div>
  
                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2" *ngIf="nameflag">
                  <mat-label class="matlabel">Name<br>
                    <input type="text" style="height: 25px;" class="ipcss inpat_width" [(ngModel)]="name" matInput (blur)="mrnobased()"  (keyup)="getdatavalue($event)" />
                  </mat-label>
                </div>
  
                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2" *ngIf="locationflag">
                  <mat-label class="matlabel">Location<br>
                    <input type="text" style="height: 25px;" class="ipcss inpat_width" [(ngModel)]="location"  matInput  (keyup)="getdatavaluelocation($event)" />
                  </mat-label>
                </div>
  
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2" style="width: fit-content;margin-top: -23px !important;" *ngIf="fdateflag">
                  <mat-label class="matlabel"><br></mat-label>
                  <div class="full-input">
                    <mat-label class="matlabel">From&nbsp;</mat-label>
                    <input type="date"  (change)="selectFromDate(fromDate)" class="ipcss" id="appt_date" [(ngModel)]="fromDate" #matInput
                      max="{{currentMaxDate}}"  >
                  </div>
                </div>
  
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2" style="width: fit-content;margin-top: -23px !important;" *ngIf="todateflag">
                  <mat-label class="matlabel"><br></mat-label>
                  <div class="full-input">
                    <mat-label class="matlabel">To&nbsp;</mat-label>
                    <input type="date" (change)="selectToDate(toDate)" class="ipcss"  id="appt_date"  [(ngModel)]="toDate" #matInput
                      max="{{currentMaxDate}}" >
                  </div>
                </div>
  
                <div class="col-1 d-flex justify-content-start" style="margin-top: 23px !important;" *ngIf="nameflag || locationflag || todateflag">
                  <mat-label class="matlabel"><br></mat-label>
                  <a (click)="filtercheck()">
                    <img src="../../../assets/ui_icons/search_icon_new.svg" width='18px' height="auto" style="cursor: pointer;" class="topvalue" />
                  </a>
                </div>
              </div>
            </div>
  
            <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2" style="text-align: right;margin-top: 23px !important;" *ngIf="!wardTransfer_flag">
              <p class="textformat"> Count: <strong>{{count}}</strong></p>
            </div>             
          </div>
          
          <div *ngIf="!wardTransfer_flag">
            <div *ngIf="tabular_format">
              <p class="nodata" *ngIf="patientList.length == 0 && !wardTransfer_flag">No Patient(s) found</p>
              <table *ngIf="patientList.length" mdbTable datatable [dtOptions]="dtOptions"
                class="table table-nowrap table-sm dataTable">
                <thead>
                  <tr>
                    <th>Image</th>
                    <th>MR no</th>
                    <th>Inpatient id</th>
                   
                    <th style="text-align: center !important;">Patient name</th>
                    <th>Speciality</th>
                    <th>Admitted on</th>
                    <th>Surgery date</th>
                    <th>Ward</th>
                    <th>MLC case</th>
                    <!-- <th style="width: 5%;">                  
                    </th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let person of patientList; let i = index">
                    <td (click)='viewPatient(person.patient_id,person.inpatient_id,person.mobile,person.client_reg_id,person.first_name,person.middle_name,person.last_name, person.speciality,person)'>
                      <img src="{{person.profile_image}}" class="img-fluid z-depth-1 rounded-circle mr-1"></td>               
                    <td (click)='viewPatient(person.patient_id,person.inpatient_id,person.mobile,person.client_reg_id,person.first_name,person.middle_name,person.last_name, person.speciality,person)'>
                      <p class="ptagvalue">{{person.patient_id}}</p></td>           
                    <td (click)='viewPatient(person.patient_id,person.inpatient_id,person.mobile,person.client_reg_id,person.first_name,person.middle_name,person.last_name, person.speciality,person)' 
                      style="font-size: 12px;">{{ person.hosp_inp_id }}</td>                 
                    <td (click)='viewPatient(person.patient_id,person.inpatient_id,person.mobile,person.client_reg_id,person.first_name,person.middle_name,person.last_name, person.speciality,person)'
                      style="font-size: 12px;text-align: left !important;">{{ person.patient_name }}</td>                  
                    <td (click)='viewPatient(person.patient_id,person.inpatient_id,person.mobile,person.client_reg_id,person.first_name,person.middle_name,person.last_name, person.speciality,person)'
                      style="font-size: 12px;text-align: left !important;">{{ person.speciality }}</td>                 
                    <td (click)='viewPatient(person.patient_id,person.inpatient_id,person.mobile,person.client_reg_id,person.first_name,person.middle_name,person.last_name, person.speciality,person)'
                      style="font-size: 12px;">{{ person.admit_date }}</td>               
                    <td (click)='viewPatient(person.patient_id,person.inpatient_id,person.mobile,person.client_reg_id,person.first_name,person.middle_name,person.last_name, person.speciality,person)'
                      style="font-size: 12px;">{{ person.surgery_date }}</td>
                    <td (click)='viewPatient(person.patient_id,person.inpatient_id,person.mobile,person.client_reg_id,person.first_name,person.middle_name,person.last_name, person.speciality,person)'
                      style="font-size: 12px;text-align: left;padding-left: 50px;">{{ person.ward_text }}</td>
                      <td (click)='viewPatient(person.patient_id,person.inpatient_id,person.mobile,person.client_reg_id,person.first_name,person.middle_name,person.last_name, person.speciality,person)'
                      style="font-size: 12px;text-align: left;padding-left: 50px;">{{ person.mlc }}</td>
                      
                    <!-- <td style="font-size: 12px;width: 5%;">
                      <mdb-checkbox [default]="true" [(ngModel)]="person.checked" name="list_name"
                        value="{{person.inpatient_id}}" (change)="isInpatSelected(person)" [disabled]="person.disflag">
                      </mdb-checkbox>
                    </td> -->
                  </tr>
                </tbody>
              </table>
            </div>
            <div *ngIf="graphicalformat">
              <div class="row">
                <div class="col-2" *ngFor="let person of patientList" style="text-align: center;">
                  <div
                    (click)='viewPatient(person.inpatient_id,person.mobile,person.client_reg_id,person,person.first_name,person.middle_name,person.last_name, person.speciality,person)'>
                    <div class="bed_bed_no">{{person.patient_name}}</div>
                    <div class="bed_bed_no1">{{person.bed_no}}</div>
                    <div><img src="{{person.bed_img}}" class="bed_img">
                    </div>
                    <div class="bed_bed_name d-flex justify-content-center">
                      {{person.ward_text}}</div>
                    <div class="bed_bed_name1 d-flex justify-content-center">
                      {{person.hosp_inp_id}}</div>
                    <div class="bed_bed_name2 d-flex justify-content-center">
                      {{person.admit_date}}</div>
                    <div class="bed_bed_name2 d-flex justify-content-center">
                      {{person.doctor_name}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="graph_defaultimg">
              <div class="row">
                <div class="col-2 " *ngFor="let person of patientList" style="text-align: center;padding: 10px ">
                  <div class="defaultimg">
                  <div class="defaultimg1" 
                    (click)='viewPatient(person.patient_id,person.inpatient_id,person.mobile,person.client_reg_id,person.first_name,person.middle_name,person.last_name, person.speciality,person)'>
                    <div>
                      <img src="{{person.profile_image}}" class="cycling" />
                    </div>
                    <div>
                      <p class="ptagvalue">{{person.patient_name}}</p>
                      <p class="ptagvalue">{{person.patient_id}}</p>
                      <p class="ptagvalue">{{person.hosp_inp_id}}</p>
                      <p class="ptagvalue">{{person.gender_age}}</p>
                      <p class="ptagvalue">{{person.locationcheck}}/{{person.districtcheck}}</p>
                      <div style="border-top: 1px solid #91badd;">
                      <p class="ptagvalue">{{person.ward_text}}</p>
                      </div>
                      <p class="ptagvalue">ADT: {{person.admitdatetime}}</p>
                      <p class="ptagvalue">{{ person.doctor_name }}</p>
                    </div>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
         
          <p class="nodata" *ngIf='patientList == undefined || patientList.length == 0 && wardTransfer_flag'>No Patient(s) found</p>                  
          <div class="col-12 container-fluid" *ngIf="patientList.length != 0 && wardTransfer_flag" 
            style="max-height: 525px;overflow-y: auto;overflow-x: hidden;border-top: 1px solid #51b0e7;
            border-radius: 5px;border-bottom: 1px solid #51b0e7;border-left: 1px solid #51b0e7;border-right: 1px solid #51b0e7;margin: 0px;padding: 0px;margin-top: 10px;">       
              <mat-accordion displayMode="flat" multi="false" class="mat-table" >
                <section matSort class="mat-elevation-z2 mat-header-row" style="background-color: #51b0e7;position: sticky;top: 0;z-index: 10;">
                  <span class="mat-header-cell" style="text-align: center;width: 5% !important;">Image</span>
                  <span class="mat-header-cell" style="width: 10% !important;">MR no</span>
                  <span class="mat-header-cell" style="width: 10% !important;">Patient Id</span>
                  <span class="mat-header-cell" style="width: 30% !important;">Patient name</span>
                  <span class="mat-header-cell" style="width: 10% !important;">Speciality</span>
                  <span class="mat-header-cell" style="width: 5% !important">Admitted on</span>
                  <span class="mat-header-cell" style="width: 5% !important">Surgery date</span>
                  <span class="mat-header-cell" style="width: 30% !important;">Ward</span>
                </section>
  
                <mat-expansion-panel *ngFor="let person of patientList">
                  <mat-expansion-panel-header class="mat-row align-items-center" (click)="getWardDetails(person)">
                    <span class="mat-cell" style="text-align: center;width: 30px;
                    height: 30px;
                    border-radius: 50%;">
                      <img src="{{person.profile_image}}" onerror="this.src='../../../assets/img/default.jpg';">
                    </span>
                    <span class="mat-cell">{{ person.patient_id }}</span>
                    <span class="mat-cell">{{ person.hosp_inp_id }}</span>
                    <span class="mat-cell" style="text-align: left !important;">{{ person.patient_name }}</span>
                    <span class="mat-cell">{{ person.speciality }}</span>
                    <span class="mat-cell">{{ person.admit_date }}</span>
                    <span class="mat-cell">{{ person.surgery_date }}</span>
                    <span class="mat-cell">{{ person.ward_text }}</span>
                  </mat-expansion-panel-header>
                  <div>
                    <table *ngIf="patientList.length" style="font-family: Arial, Helvetica, sans-serif;">
                      <thead>
                        <tr>
                          <th>Admission id</th>
                          <th>Department name</th>
                          <th>Ward</th>
                          <th>Bed number</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <mat-label class="matlabel"> </mat-label>
                            {{admission_id}}
                          </td>
                          <td>
                            <mat-label class="matlabel"> </mat-label>
                            {{department}}
                          </td>
                          <td>
                            <mat-label class="matlabel"></mat-label>
                            <select disableOptionCentering class="ipcss inpat_width" [(ngModel)]="ward_id"
                             (ngModelChange)="getBedList($event)">
                              <option *ngFor="let ward of ward_list" value="{{ward.ward_id}}">
                                {{ward.ward_name}}</option>
                            </select>
                          </td>
                          <td>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" style="width: fit-content;">
                              <!--PopUp 1-->
                              <div class="btn-group">
                                <mat-label class="matlabel"><br></mat-label>                                 
                                <input [disabled]="!show_bed_details" type="button"
                                  style="width: 210px !important;border-top-left-radius: 4px !important;border-bottom-left-radius: 4px !important;text-align: left;"
                                  class="btn-secondary" [(ngModel)]="bed_no" />
                                <button [disabled]="!show_bed_details" type="button"
                                  style="width: 20px !important;border-top-right-radius: 4px !important;border-bottom-right-radius: 4px !important;"
                                  class="btn-secondary dropdown-toggle dropdown-toggle-split" data-bs-toggle="modal"
                                  [attr.data-bs-target]="'#profile' + person.hosp_inp_id">
                                  <span class="visually-hidden">Toggle Dropdown</span>
                                </button>
                                <div class="modal fade" [attr.id]="'profile' + person.hosp_inp_id" tabindex="-1"
                                  role="dialog" aria-hidden="true" aria-labelledby="dropdownMenuReference">
                                  <div class="modal-dialog">
                                    <div class="modal-content">
                                      <div class="modal-body">
                                        <div class="container-fluid">
                                          <div class="snapshot">
                                            <div class="col-12 text-center">
                                              <ul class="modal-body custom-list" style="padding: 0;">
                                                <li>
                                                  <div class="container">
                                                    <div class="row" *ngIf="bedList.length >= 3">
                                                      <div class="col-3 p-0" *ngFor="let bed of bedList">
                                                        <div (click)="changeBed(bed)" data-bs-dismiss="modal"
                                                          aria-label="Close" style="text-align: center;">
                                                          <div class="bed_bed_no">{{bed.bed_no}}</div>
                                                          <div><img src="{{bed.bed_img}}" class="bed_img">
                                                          </div>
                                                          <div class="bed_bed_name d-flex justify-content-center"
                                                            [ngStyle]="{'color':bed.color,'font-weight':bed.fontWeight}">
                                                            {{bed.pat_name}}</div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div class="row" *ngIf="bedList.length <= 2">
                                                      <div class="col-6 p-0" *ngFor="let bed of bedList">
                                                        <div (click)="changeBed(bed)" data-bs-dismiss="modal"
                                                          aria-label="Close" style="text-align: center;">
                                                          <div class="bed_bed_no">{{bed.bed_no}}</div>
                                                          <div><img src="{{bed.bed_img}}" class="bed_img">
                                                          </div>
                                                          <div class="bed_bed_name d-flex justify-content-center"
                                                            [ngStyle]="{'color':bed.color,'font-weight':bed.fontWeight}">
                                                            {{bed.pat_name}}</div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <!--PopUp 2-->
                                <div class="btn-group">
                                  <button [hidden]="!show_bednos" type="button"
                                    style="width: 30px !important;border:none !important;margin-left: 5px;" data-bs-toggle='modal'
                                    [attr.data-bs-target]="'#Two' + person.hosp_inp_id">
                                    <img src="../../../assets/ui_icons/bed_icon.svg" class="bedbutton" />
                                  </button>
                                  <div class="modal fade" [attr.id]="'Two' + person.hosp_inp_id" tabindex="-1"
                                    role="dialog" aria-hidden="true" aria-labelledby="dropdownMenuReference">
                                    <div class="modal-dialog">
                                      <div class="modal-content">
                                        <div class="modal-body">
                                          <div class="container-fluid">
                                            <div class="snapshot">
                                              <div class="col-12 text-center">
                                                <ul class="modal-body custom-list" style="padding: 0;">
                                                  <li>
                                                    <div class="container">
                                                      <div class="row">
                                                        <table class="table table-hover table-dynamic" id="tbl">
                                                          <thead>
                                                            <tr>
                                                              <th>Ward</th>
                                                              <th>Bed no</th>
                                                              <th>Allotted on</th>
                                                            </tr>
                                                          </thead>
                                                          <tbody>
                                                            <tr *ngFor="let bed of bedOccList">
                                                              <td class="test">
                                                                <span
                                                                  [ngStyle]="bed.status == 1 ? {'color': '#f01818' } : {'color': '#000000'}">{{bed.ward}}</span>
                                                              </td>
                                                              <td style="font-size: 12px;">
                                                                <span
                                                                  [ngStyle]="bed.status == 1 ? {'color': '#f01818' } : {'color': '#000000'}">{{bed.bedno}}</span>
                                                              </td>
                                                              <td style="font-size: 12px;">
                                                                <span
                                                                  [ngStyle]="bed.status == 1 ? {'color': '#f01818' } : {'color': '#000000'}">{{bed.date}}</span>
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </div>
                                                    </div>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>               
          </div>                 
        </mdb-card-body>
      </mdb-card>
    </div>
  </div>
  