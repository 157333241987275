import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Physio_Helper } from '../Physio_Helper';

@Injectable({ providedIn: 'root' })
export class PhysioCasesheetService {
     private subject = new Subject<any>();

    sendCasesheetMessage(message: string) {
        this.subject.next(message);
    }

    clearCasesheetMessages() {
        this.subject.next();
    }

    getCasesheetMessage(): Observable<any> {
        return this.subject.asObservable();
    }

    // get diag page in medical pre compont
    // for medical and diagnosis prescription scroll fuction
    private diag_subject = new Subject<any>();

    getDiagComponent(): Observable<any> {
       return this.diag_subject.asObservable();
    }

    sendDiagComponent(filterBy: string) {
       this.diag_subject.next(filterBy);
    }
    // for meadical heading medical change fun
    private medicalNameFunction = new Subject<any>();

    getMedicalName(): Observable<any> {
       return this.medicalNameFunction.asObservable();
    }

    sendMedicalName(filterBy: string) {
        if(filterBy == "false"){
            Physio_Helper.setMedicalHeading("true");
        }else{
            Physio_Helper.setMedicalHeading("false");
        }
       this.medicalNameFunction.next(filterBy);
    }
}