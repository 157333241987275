import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import { Http, Headers } from '@angular/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { CommonDataService } from '../../providers/common-data.service';
import { ClientViewService } from '../clientview/client-view.service';
import { Client_Helper } from '../Client_helper';
import { ipaddress } from '../../ipaddress';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Helper_Class } from 'src/app/helper_class';

@Component({
  selector: 'app-lifestyle-tracker',
  templateUrl: './lifestyle-tracker.component.html',
  styleUrls: ['./lifestyle-tracker.component.css']
})
export class LifestyleTrackerComponent implements OnInit {

  tomorrow = new Date();
  public header_footer_flag: boolean;
  public lifestyleHomepage: string;
  public relation_url:string;
  public lifestyleData;
  public retrivel_tracker_Data: string;
  public lifestyleUsernames: string;
  public medicine_track;
  public lifestyleTracker = [];
  Nodata: boolean;
  private loader: boolean;
  public submenu_flag:boolean=false;

  public userinfo;
  public send_client_reg_id;
  public relationName;
  public relation_id;
  public sub_rel_id;
  public life_style_id;
  public relationlistData = [];
  public relation_f_name;
  public relation_l_name;
  public relation_m_name;

  constructor(public messageservice:ClientViewService,public gservice:CommonDataService,public datepipe:DatePipe,public http:HttpClient,
    public routes:ActivatedRoute,public router:Router,public toastr:ToastrService,
    ) { 
    this.relation_url = ipaddress.getIp + "usercontroller/grel/";
    if (this.gservice.Client_login_detail_data.Client_Id != undefined) {
      this.lifestyleData = {
        client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
        flag: Client_Helper.getTrackerFlag()
      }
      
      if (Client_Helper.getTrackerFlag() != undefined) {
        this.medicine_track = Client_Helper.getTrackerFlag();
      }
    }
    this.loader = false;
    //this.lifestyleFulldata();

    this.Nodata = false;
    }

  ngOnInit(): void {
    this.userinfo = Helper_Class.getInfo();
    this.getRelationlist();
  }

  getRelationlist() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.relation_url, (this.lifestyleData), { headers: headers }).subscribe(
      data => {
        var obj1 = JSON.parse(JSON.stringify(data));
        if (obj1.relations != null && obj1.relations.length != 0) {
          if (obj1.relations != null) {
            if(this.userinfo.middle_name != undefined && encrypt_decript.Decript(this.userinfo.middle_name)){
              this.relationName = encrypt_decript.Decript(this.userinfo.first_name) + " " + encrypt_decript.Decript(this.userinfo.middle_name) + " " + encrypt_decript.Decript(this.userinfo.last_name);
            }else{
              this.relationName = encrypt_decript.Decript(this.userinfo.first_name) + " " + encrypt_decript.Decript(this.userinfo.last_name);
            }            
            if (this.userinfo.profile_image != null && this.userinfo.profile_image != undefined && this.userinfo.profile_image != '') {
              imgpath = this.userinfo.profile_image;
              img = ipaddress.Ip_with_img_address + this.userinfo.profile_image;
              this.relation_id = "1";
              this.sub_rel_id = "";
              this.life_style_id = this.userinfo.life_style_id;
            }
            this.relationlistData.push({
              rel_name: this.relationName,
              life_style_id: this.userinfo.lif_stl_id,
              rel_id: this.relation_id,
              sub_rel_id: this.sub_rel_id,
              prof_img: img,
              imgpath: imgpath,
            });

          }
          for (var f = 0; f < obj1.relations.length; f++) {
            var obj = obj1.relations[f];
            this.sub_rel_id = null;
            if (obj.sub_rel_id != null) {
              this.sub_rel_id = obj.sub_rel_id
            }
            this.relation_f_name = "";
            this.relation_m_name = "";
            this.relation_l_name = "";
            // this.zip_code = "";
            // this.dateofbirth_val = "";


            if (obj.middle_name != null) {
              this.relation_f_name = encrypt_decript.Decript(obj.first_name.toString());
              this.relation_m_name = encrypt_decript.Decript(obj.middle_name.toString());
              this.relation_l_name = encrypt_decript.Decript(obj.last_name.toString());
              this.relationName = encrypt_decript.Decript(obj.first_name.toString()) + " " + encrypt_decript.Decript(obj.middle_name.toString()) + " " + encrypt_decript.Decript(obj.last_name.toString());
            } else {
              this.relation_f_name = encrypt_decript.Decript(obj.first_name.toString());
              this.relation_l_name = encrypt_decript.Decript(obj.last_name.toString());
              this.relationName = encrypt_decript.Decript(obj.first_name.toString()) + " " + encrypt_decript.Decript(obj.last_name.toString());
            }

            var img = "../../../assets/imgs/default.jpg", imgpath = "../../../assets/imgs/default.jpg";
            if (obj.profile_image !== "default") {
              imgpath = obj.profile_image;
              img = ipaddress.Ip_with_img_address + obj.profile_image;
            }
            this.relationlistData.push({
              rel_name: this.relationName,
              rel_f_name: this.relation_f_name,
              rel_m_name: this.relation_m_name,
              rel_l_name: this.relation_l_name,
              rel_id: obj.relation_id,
              sub_rel_id: this.sub_rel_id,
              gender_val: encrypt_decript.Decript(obj.gender),
              prof_img: img,
              imgpath: imgpath,
              loc_id: obj.location,
              loc_name: obj.location_desc,
              city_id: obj.city,
              city_desc: obj.city_desc,
              state_id: obj.state,
              state_desc: obj.state_desc,
              country_id: obj.country,
              country_desc: obj.country_desc,
              update_flag: true,
              life_style_id: obj.life_style_id,

            });
          }
        }
      });
  }

  // lifestyleFulldata() {
  //   var headers = new Headers();
  //   headers.append('Content-Type', 'application/json');
  //   this.http.post(this.relation_url, JSON.stringify(this.lifestyleData), { headers: headers }).subscribe(
  //     data => {
  //       this.loader = true;
  //       this.retrivel_tracker_Data = data.json();
  //       var trackerDataInformation = JSON.parse(data["_body"]);

  //       if (trackerDataInformation.relations.length == 0)
  //         this.Nodata = true

  //       for (var i = 0; i < trackerDataInformation.relations.length; i++) {
  //         var trackerFulldata_profileImage = trackerDataInformation.relations[i].profile_image;
  //         var trackerFulldata_relationID = trackerDataInformation.relations[i].relation_id;
  //         var trackerFulldata_subRelationid = trackerDataInformation.relations[i].sub_rel_id;
  //         var firstName = trackerDataInformation.relations[i].client_first_name;
  //         var lastName = trackerDataInformation.relations[i].client_last_name;
  //         var middleName = trackerDataInformation.relations[i].client_middle_name;

          
  //         if (middleName != undefined && middleName != "" && encrypt_decript.Decript(middleName) != undefined) {
  //           this.lifestyleUsernames = encrypt_decript.Decript(firstName) + ' ' + encrypt_decript.Decript(middleName) + ' ' + encrypt_decript.Decript(lastName);

  //         } else {
  //           this.lifestyleUsernames = encrypt_decript.Decript(firstName) + ' ' + encrypt_decript.Decript(lastName);
  //         }
          

  //         this.lifestyleTracker.push({
  //           lifestyleTracker_ProfileName: this.lifestyleUsernames,
  //           lifestyleTracker_ProfileImage: ipaddress.Ip_with_img_address + trackerFulldata_profileImage,
  //           lifestyleTracker_RelationID: trackerFulldata_relationID,
  //           lifestyleTracker_Sub_RelID: trackerFulldata_subRelationid
  //         });
  //       }
  //     },
  //     error => {

  //     }
  //   )
  // }

  medi_track(relation_id, sub_rel_id) {
    if (this.medicine_track == "medicine") {
      var data ={
        relation_id: relation_id,
        sub_rel_id: sub_rel_id,
        trackerFlag: Client_Helper.getTrackerFlag()
      }
      Client_Helper.setLifestyleTracker(data);
      // localStorage.setItem("med-tracker",JSON.stringify(data));
      this.messageservice.sendMessage("medicine_tracker");
      // this.router.navigateByUrl("/medicine_tracker");

    } else if (this.medicine_track == "diet") {
       data ={
        relation_id: relation_id,
        sub_rel_id: sub_rel_id,
        trackerFlag: Client_Helper.getTrackerFlag()
      }
      Client_Helper.setLifestyleTracker(data);
      // localStorage.setItem("diet-tracker",JSON.stringify(data));
      this.messageservice.sendMessage("diet_tracker");
      // this.router.navigateByUrl("/diet_tracker");
    }
  }

}
