<div class="Details container" id="Begin_content_others" style="padding: 0 15px 0 15px; max-width: 100%;">
    <div class="row">
        <div class="col-12 p-0">
            <mdb-card>
                <mdb-card-header class="bg-white ">
                    <div class="headerCover">
                        <div class="headerTilte app_list_header">
                            <h5 class="m-0" class="mainHeadingStyle">Reports</h5>
                        </div>
                        <div class="headerButtons">
                               <img src="../../../assets/ui_icons/buttons/Back_button.svg" *ngIf="!report_detail_flag"  (click)="backClicked()"
                                width="85px" style="margin-right: 9px;" />
                                <img src="../../../assets/ui_icons/buttons/Back_button.svg" *ngIf="report_detail_flag"(click)="backClicked1()"
                                width="85px" style="margin-right: 9px;" />
                            <!-- <img src="../../../assets/ui_icons/buttons/Back_button.svg" (click)="backClicked()"
                                width="85px" style="margin-right: 9px;" />
                           <img src="../../../assets/ui_icons/buttons/cancel_button.svg" (click)="cancel_click()"
                                width="85px" style="margin-right: 9px;" />
                           <img src="../../../assets/ui_icons/buttons/update_button.svg" (click)="appointment_update()"
                                width="85px" style="margin-right: 9px;" /> 
                          <img (click)="Write_prescription()"
                                    src="../../../assets/ui_icons/buttons/write_RX_Button.svg" class="viewrximgbtn_inpatinfo" />
                            <img (click)="reportview()"
                                    src="../../../assets/ui_icons/buttons/write_RX_Button.svg" class="viewrximgbtn_inpatinfo" /> -->
                        </div>
                    </div>
                </mdb-card-header>
                <mdb-card-body>
                    <div>
                        <app-loader></app-loader>
                    </div>
                    <div class="container">
                        <div class="row">
                            <div *ngIf="!report_detail_flag" class="Details">
                              <!-- <div class="row">
                                <div class="col-12" style="margin-top: 13px;text-align: right;margin-bottom: 10px !important;">
                                  <a (click)="uploadDocument()"> <span class="excr_confirm matlabel"><i class="fa fa-upload"
                                        aria-hidden="true"></i>
                                      <strong style="padding-left: 5px;">Document Upload </strong>
                                    </span></a>
                                </div>
                              </div> -->
                        
                        
                              <div
                                *ngIf="blood_report_list.length != 0 || urine_report_list.length != 0 || feaces_report_list.length != 0
                              || xray_report_list.length != 0 || scan_report_list.length != 0 || ultrasound_report_list.length != 0 || documentList.length != 0">
                        
                                <div [hidden]="blood_report_list.length == 0 && !documentBlood" style="margin-bottom: 5px;">
                                  <div class="">
                                    <mat-accordion class="mataccordion">
                                      <mat-expansion-panel class="expandpanel">
                                        <mat-expansion-panel-header class="exppanel width">
                                          <mat-panel-title class="title ">
                                            Laboratory
                                          </mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <hr class="hr">
                                        <div class="row" style="margin-top: 11px;">
                                          <div *ngFor="let blood_report of blood_report_list"
                                            class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                                            <mat-card
                                              (click)="report_view(blood_report.Diag_Test_Id,'pathology',blood_report.diag_appointment_id,blood_report.test_type,blood_report.diag_test_name)"
                                              class="CardList_border">
                                              <mat-card-header></mat-card-header>
                                              <mat-card-content>
                                                <mat-label class="matlabel">{{blood_report.diag_test_name}}</mat-label><br>
                                              </mat-card-content>
                                            </mat-card><br>
                                          </div>
                                          <div *ngFor="let document of documentList" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                                            [hidden]="document.type != 'Blood report'">
                                            <mat-card (click)="document_view(document)" class="CardList_border">
                                              <mat-card-header></mat-card-header>
                                              <mat-card-content>
                                                <mat-label class="matlabel">{{"Document : "+document.document_name+" "+document.document_date}}
                                                </mat-label>
                                                <br>
                                              </mat-card-content>
                                            </mat-card><br>
                                          </div>
                                        </div>
                                      </mat-expansion-panel>
                                    </mat-accordion>
                                  </div>
                                </div>
                        
                                <div [hidden]="scan_report_list.length == 0 && !documentScan" style="margin-bottom: 5px;">
                                  <div class="">
                                    <mat-accordion class="mataccordion">
                                      <mat-expansion-panel class="expandpanel">
                                        <mat-expansion-panel-header class="exppanel width">
                                          <mat-panel-title class="title ">
                                            Radiology
                                          </mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <hr class="hr">
                                        <div class="row" style="margin-top: 11px;">
                                          <div *ngFor="let scan_report of scan_report_list"
                                            class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                                            <mat-card
                                              (click)="report_view(scan_report.Diag_Test_Id,'radiology',scan_report.diag_appointment_id,scan_report.test_type,scan_report.diag_test_name)"
                                              class="CardList_border">
                                              <mat-card-header></mat-card-header>
                                              <mat-card-content>
                                                <mat-label>{{scan_report.diag_test_name}}</mat-label><br>
                                              </mat-card-content>
                                            </mat-card><br>
                                          </div>
                                          <div *ngFor="let document of documentList" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                                            [hidden]="document.type != 'Scan report'">
                                            <mat-card (click)="document_view(document)" class="CardList_border">
                                              <mat-card-header></mat-card-header>
                                              <mat-card-content>
                                                <mat-label class="matlabel">{{"Document : "+document.document_name+"
                                                  "+document.document_date}}</mat-label>
                                                <br>
                                              </mat-card-content>
                                            </mat-card><br>
                                          </div>
                                        </div>
                                      </mat-expansion-panel>
                                    </mat-accordion>
                                  </div>
                                </div>
                                <div [hidden]="microlist.length == 0 && !documentmicro" style="margin-bottom: 5px;">
                                  <div class="">
                                    <mat-accordion class="mataccordion">
                                      <mat-expansion-panel class="expandpanel">
                                        <mat-expansion-panel-header class="exppanel width">
                                          <mat-panel-title class="title ">
                                            Microbiology
                                          </mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <hr class="hr">
                                        <div class="row" style="margin-top: 11px;">
                                          <div *ngFor="let scan_report of microlist"
                                            class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                                            <mat-card
                                              (click)="report_view(scan_report.Diag_Test_Id,'microbiology',scan_report.diag_appointment_id,scan_report.test_type,scan_report.diag_test_name)"
                                              class="CardList_border">
                                              <mat-card-header></mat-card-header>
                                              <mat-card-content>
                                                <mat-label>{{scan_report.diag_test_name}}</mat-label><br>
                                              </mat-card-content>
                                            </mat-card><br>
                                          </div>
                                          <div *ngFor="let document of documentList" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                                            [hidden]="document.type != 'Micro report'">
                                            <mat-card (click)="document_view(document)" class="CardList_border">
                                              <mat-card-header></mat-card-header>
                                              <mat-card-content>
                                                <mat-label class="matlabel">{{"Document : "+document.document_name+"
                                                  "+document.document_date}}</mat-label>
                                                <br>
                                              </mat-card-content>
                                            </mat-card><br>
                                          </div>
                                        </div>
                                      </mat-expansion-panel>
                                    </mat-accordion>
                                  </div>
                                </div>
                              </div>
                              <div
                                *ngIf="blood_report_list.length == 0 && urine_report_list.length == 0 && feaces_report_list.length == 0 && 
                                xray_report_list.length == 0 && scan_report_list.length == 0 && ultrasound_report_list.length == 0 && documentList.length == 0">
                                <div class="nodata">No Test Reports Found</div>
                              </div>
                        
                            </div>
                            <div *ngIf="report_detail_flag" class="Details">
                              <div class="row">
                                
                                <div class="col-12" style="margin-bottom: 5px">
                                  <div class="cover_div">
                                    <div class="header_lable">Details</div>
                                    <div class="content_cover">
                        
                                      <div class="row">
                                        <div class="col-3" *ngIf="sampleflag">
                                          Sample date & time:{{sampledatecheck}} {{sampletimecheck}}
                                        </div>
                                        <div class="col-3" *ngIf="!sampleflag">
                                          visit date & time:{{visited_date}} {{visited_time}}
                                        </div>
                                        <div class="col-3" *ngIf="sampleflag">
                                          Sample Id: {{sample_id}}
                                        </div>
                                        <div class="col-3" *ngIf="sampleflag">
                                          Sample Type: {{sample_type}}
                                        </div>
                                        <div class="col-3">
                                          Report date & time : {{reporteddate}} {{reportedtime}}
                                        </div>
                                        <!-- <div class="col-4"  *ngIf="inpatflag">
                                              Admitted date & time: {{admission_date}} {{admission_time}}
                                          </div> -->
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div *ngIf="imageflag_outsource_id">
                                <div [hidden]='tableReportTypeFlag'>
                                  <div *ngFor="let test of diagTestReadingArray">
                                    <div *ngIf="test.sub_test_list.length != 0">
                                      <p class="tervys_heading clinical col-12"><b style="text-transform: capitalize;">{{test.category}}</b></p>
                                      <div class='row'>
                                        <div *ngIf="!template_flag" class='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                                          <div class="dig_table_overflow">
                                            <div class="table-responsive">
                                              <table id='card_tbl' class='table table-hover table-dynamic'>
                                                <thead>
                                                  <tr>
                                                    <th style="width:400px;text-align: left;">Description</th>
                                                    <th style="width:200px;text-align: left;">Reading</th>
                                                    <th style="width:150px;text-align: left;">Unit</th>
                                                    <th style="text-align: left;">Referred value</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr>
                                                    <td colspan="4" style="text-align: left;font-weight: 400;">
                                                      <b>{{test.sub_test_name}}</b>
                                                    </td>
                                                  </tr>
                        
                                                  <tr *ngFor='let subtest of test.sub_test_list'>
                                                    <td style='text-align: left;margin-bottom: 15px !important'
                                                      *ngIf="subtest.tests == undefined">
                                                      <div>
                                                        <strong>
                                                          <p>{{subtest.sub_test_name}}</p>
                                                        </strong>
                                                        <span *ngIf="subtest.testMethod != undefined" class="testMethod">(
                                                          <div [innerHtml]="subtest.testMethod"></div> )
                                                        </span>
                                                      </div>
                        
                                                    </td>
                                                    <td colspan="4" *ngIf="subtest.tests != undefined">
                                                      <div>
                                                        <div class="d-flex flex-row" style="padding: 5px;">
                                                          <strong>
                                                            <p>{{subtest.sub_test_name}}</p>
                                                          </strong>
                                                          <!-- <p style="margin-top: 5px; margin-bottom: 0px;">{{subtest.id}}</p> -->
                                                          <!-- <h2 style=" font-size: 17px;
                                                                    margin-left: 18px;">
                                                                       {{subtest.sub_test_name}}</h2> -->
                                                        </div>
                                                        <table style="width: 100%;">
                                                          <tbody>
                                                            <tr *ngFor="let sub of subtest.tests let i = index"
                                                              style="border-top: 1px solid #6e7070;">
                                                              <!-- <td>{{i + 1}}</td>  style=" style="width: 190px; padding-left: 14px;"width: 373px; padding: 9px;padding-left: 35px;"-->
                                                              <td class="subtestname">
                                                                {{sub.sub_test_name}}</td>
                                                              <td class="subtestnamereading">
                                                                {{sub.reading}}</td>
                                                              <td class="unitvalue">{{sub.unit}}</td>
                                                              <td>{{sub.range}}</td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </div>
                                                    </td>
                                                    <td *ngIf="subtest.test_range == '-' && subtest.tests == undefined"
                                                      style='text-align: left; color: rgb(0, 0, 0); font-weight: 500;'>
                                                      {{subtest.reading}}</td>
                                                    <td *ngIf="subtest.is_Reading_normal && subtest.tests == undefined"
                                                      style='text-align: left; color: green; font-weight: 500;'>
                                                      {{subtest.reading}}</td>
                                                    <td *ngIf="subtest.is_Reading_Abnormal && subtest.tests == undefined"
                                                      style='text-align: left; color: orange; font-weight: 500;'>
                                                      {{subtest.reading}}*</td>
                                                    <td *ngIf="subtest.is_Reading_Critical && subtest.tests == undefined"
                                                      style='text-align: left; color: red; font-weight: 500;'>
                                                      {{subtest.reading}}**</td>
                                                    <td style='text-align: left;' *ngIf="subtest.tests == undefined">{{subtest.unit}}</td>
                                                    <td style=" text-align: left;" *ngIf="subtest.tests == undefined">
                                                      <div [innerHtml]='subtest.test_range'></div>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                        </div>
                                        <div *ngIf="template_flag" [innerHtml]="template">
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                        
                                <div [hidden]='paraReportTypeFlag'>
                                  <div class='row' style="margin-left: 20px;margin-right: 20px;">
                                    <!-- <div class='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12' style='margin-top:13px;'>
                                        <mat-label class='matlabel'><strong>Report type :</strong> {{reportType}}
                                        </mat-label>
                                     </div> -->
                                    <div class='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12' style='margin-top:5px;'>
                                      <mat-label class='matlabel'><strong>Test name :</strong> {{testName}}</mat-label>
                                    </div>
                                    <div *ngIf="value_flag" class='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12' style='margin-top:5px;'>
                                      <p class='tervys_heading clinical'>Sequences</p>
                                    </div>
                                    <div *ngIf="value_flag" class='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                                      <quill-view-html id='viewhtml' [content]='sequence'></quill-view-html>
                                    </div>
                                    <div *ngIf="value_flag" class='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                                      <p class='tervys_heading clinical'>Impression</p>
                                    </div>
                                    <div *ngIf="value_flag" class='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                                      <quill-view-html id='viewhtml' [content]='Impression'></quill-view-html>
                                    </div>
                                    <div *ngIf="template != undefined" [innerHtml]="template">
                                    </div>
                                    <div [hidden]='dicomDotor' class='col-12'>
                                      <mat-card *ngIf="!cultureFlag">
                                        <mat-card-content>
                                          <img alt='report image' src='{{reportIMG}}' (click)='clickDocument()' class='report_img' />
                                        </mat-card-content>
                                      </mat-card>
                                    </div>
                                    <!-- micro sudhan -->
                                    <div [hidden]="microbiology_flag" style="margin-top: 15px; margin-left: -24px;">
                                      <div class="container-fluid" style="margin-bottom: 8px;">
                                        <div class="row">
                                          <div class="row d-flex flex-row justify-content-start mb-3">
                                            <div class="col-2">
                                              <p *ngIf="org_iso1_cc_flag" class="mb-1"><strong>Colony count : </strong></p>
                                              <p *ngIf="org_iso1_cr_flag" class="mb-1"><strong>Culture report : </strong></p>
                                              <p *ngIf="org_iso1_growth_type_flag" class="mb-1"><strong>Gram stain : </strong></p>
                                            </div>
                                            <div class="col-9" style="">
                                              <p *ngIf="org_iso1_cc_flag" class="mb-1"> {{org_iso_1_cc_desc}}</p>
                                              <p *ngIf="org_iso1_cr_flag" class="mb-1"> {{org_iso1_cr_desc}}</p>
                                              <p *ngIf="org_iso1_growth_type_flag" class="mb-1"> {{org_iso1_growth_type_desc}}</p>
                                            </div>
                                          </div>
                                          <div class="col-4 mb-3" style="margin-bottom: 8px;" *ngIf="org_iso1_flag">
                                            <div>
                                              <div class="row d-flex flex-row justify-content-start align-items-center">
                                                <div class="col-5">
                                                  <h2 style="font-weight: 500; margin-bottom: 2px; font-size: 14px;">Organism
                                                    Isolated 1</h2>
                                                </div>
                                                <div class="col-7">
                                                  <h4 style="margin-bottom: 6px;"> &nbsp; {{org_iso_1_desc}}</h4>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="col-12 mb-3" style="margin-bottom: 8px;  margin-top: 5px;">
                                              <table cellpadding='0' cellspacing='0' style="width:100%; border:1px solid #747575;">
                                                <thead>
                                                  <tr>
                                                    <th style="text-align: left; ">
                                                      Antibiotics</th>
                                                    <th style="text-align: left; ">
                                                      Sensitivity</th>
                                                    <th *ngIf="mic_iso1_flag" style="text-align: left;">
                                                      MIC</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr *ngFor="let medicine of org_iso1_antibiotics">
                                                    <td style='padding-left: 20px;text-align: left; '>
                                                      {{medicine.drug_name}}</td>
                                                    <td style='padding-left: 20px;text-align: left; '>
                                                      {{medicine.sensitivity_desc}}</td>
                                                    <td *ngIf="mic_iso1_flag" style='padding-left: 20px;text-align: left; '>
                                                      {{medicine.mic}}</td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                          <div class="col-4 mb-3" style="margin-bottom: 8px;" *ngIf="org_iso2_flag">
                                            <div>
                                              <div class="row d-flex flex-row justify-content-start align-items-center">
                                                <div class="col-5">
                                                  <h2 style="font-weight: 500; margin-bottom: 2px; font-size: 14px;">Organism
                                                    Isolated 2</h2>
                                                </div>
                                                <div class="col-7">
                                                  <h4 style="margin-bottom: 6px;"> &nbsp; {{org_iso_2_desc}}</h4>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="col-12 mb-3" style="margin-bottom: 8px;  margin-top: 5px;">
                                              <table cellpadding='0' cellspacing='0' style="width:100%; border:1px solid #747575;">
                                                <thead>
                                                  <tr>
                                                    <th style="text-align: left; ">
                                                      Antibiotics</th>
                                                    <th style="text-align: left; ">
                                                      Sensitivity</th>
                                                    <th *ngIf="mic_iso2_flag" style="text-align: left;">
                                                      MIC</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr *ngFor="let medicine of org_iso2_antibiotics">
                                                    <td style='padding-left: 20px;text-align: left; '>
                                                      {{medicine.drug_name}}</td>
                                                    <td style='padding-left: 20px;text-align: left; '>
                                                      {{medicine.sensitivity_desc}}</td>
                                                    <td *ngIf="mic_iso2_flag" style='padding-left: 20px;text-align: left; '>
                                                      {{medicine.mic}}</td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                          <div class="col-4 mb-3" style="margin-bottom: 8px;" *ngIf="org_iso3_flag">
                                            <div>
                                              <div class="row d-flex flex-row justify-content-start align-items-center">
                                                <div class="col-5">
                                                  <h2 style="font-weight: 500; margin-bottom: 2px; font-size: 14px;">Organism
                                                    Isolated 3</h2>
                                                </div>
                                                <div class="col-7">
                                                  <h4 style="margin-bottom: 6px;"> &nbsp; {{org_iso_3_desc}}</h4>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="col-12 mb-3" style="margin-bottom: 8px;  margin-top: 5px;">
                                              <table cellpadding='0' cellspacing='0' style="width:100%; border:1px solid #747575;">
                                                <thead>
                                                  <tr>
                                                    <th style="text-align: left; ">
                                                      Antibiotics</th>
                                                    <th style="text-align: left; ">
                                                      Sensitivity</th>
                                                    <th *ngIf="mic_iso3_flag" style="text-align: left;">
                                                      MIC</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr *ngFor="let medicine of org_iso3_antibiotics">
                                                    <td style='padding-left: 20px;text-align: left; '>
                                                      {{medicine.drug_name}}</td>
                                                    <td style='padding-left: 20px;text-align: left; '>
                                                      {{medicine.sensitivity_desc}}</td>
                                                    <td *ngIf="mic_iso3_flag" style='padding-left: 20px;text-align: left; '>
                                                      {{medicine.mic}}</td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                        
                                          <div class="col-12 mb-2" *ngIf="technical_noteFlag">
                                            <h2 style="font-size: 16px; font-weight: 500; margin-bottom: 5px;">Technical Notes</h2>
                                            <p>{{technical_note}}</p>
                                          </div>
                                          <div class="col-12 mb-2" *ngIf="commentsFlag">
                                            <h2 style="font-size: 16px; font-weight: 500; margin-bottom: 5px;">Comments</h2>
                                            <p>{{comment}}</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <hr style="border: 1px solid #06438a;">
                                <div class="row" style='padding: 0px 22px; margin-top: 20px;'>
                                  <table>
                                     <tr>
                                        <td style="width: 90%;text-align: center;">
                                           <!-- <div class="row" [hidden]="createdBy == undefined">
                                              <div [hidden]="createdBy == undefined" class="col-12">
                                                 <mat-label class="matlabel"><strong>Lab technician
                                                       :</strong> {{createdBy}}
                                                 </mat-label>
                                              </div>
                                              <div class="col-12" style="margin-top: 10px;">
                                                 <mat-label class="matlabel"><strong>Signature
                                                       :</strong>
                                                 </mat-label>
                                              </div>
                                           </div> -->
                                           <div class="row" [hidden]="createdBy == undefined">
                                              <div [hidden]="createdBy == undefined" class="col-4">
                                                 <mat-label class="matlabel"><strong>Lab
                                                       technician
                                                       :</strong> {{createdBy}}
                                                 </mat-label>
                                              </div>
                                              <div class="col-4">
                                                 <mat-label class="matlabel"><strong>{{consultant}}:</strong> {{approver_name}}
                                                 </mat-label>
                                              </div>
                                              <div class="col-4">
                                                 <mat-label class="matlabel"><strong>Quality Head
                                                       :</strong> {{qualityHead_name}}
                                                 </mat-label>
                                              </div>
                                           </div>
                                        </td>
                                     </tr>
                                  </table>
                               </div>
                              </div>
                              <div class='row' *ngIf="!imageflag_outsource_id">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-10" style="margin-top: 10px;">
                                  <iframe id="myIframe" class="controls" [src]="uploadidproof" width="100%" height="700px" frameborder="0"
                                    webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                                </div>
                              </div>
                              <div *ngIf="documentView_flag">
                                <div class="row">
                                  <div class="col-12">
                                    <mat-card id="cardcontent">
                                      <mat-card-header id="cardheader">Client Details</mat-card-header>
                                      <mat-card-content style="padding: 10px 0;">
                                        <div class="container">
                                          <div class="row">
                                            <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 p-0">
                                              <div class="row">
                                                <div class="col-5">
                                                  <mat-label class="matlabel">Client id :</mat-label>
                                                </div>
                                                <div class="col-7">
                                                  <mat-label class="matlabel">{{clent_id}}</mat-label>
                                                </div>
                                              </div>
                                              <div class="row">
                                                <div class="col-5">
                                                  <mat-label class="matlabel">Name :</mat-label>
                                                </div>
                                                <div class="col-7">
                                                  <mat-label class="matlabel">{{client_another_name}}</mat-label>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 p-0">
                                              <div class="row">
                                                <div class="col-5">
                                                  <mat-label class="matlabel">Age/Gender :</mat-label>
                                                </div>
                                                <div class="col-7">
                                                  <mat-label class="matlabel">{{client_age}}/{{client_gender}}</mat-label>
                                                </div>
                                              </div>
                                              <!-- <div class="row">
                                                <div class="col-5">
                                                  <mat-label class="matlabel"> :</mat-label>
                                                </div>
                                                <div class="col-7">
                                                  <mat-label class="matlabel"></mat-label>
                                                </div>
                                              </div> -->
                                            </div>
                                            <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 p-0">
                                              <div class="row">
                                                <div class="col-5">
                                                  <mat-label class="matlabel">Date :</mat-label>
                                                </div>
                                                <div class="col-7">
                                                  <mat-label class="matlabel">{{date_txt}}</mat-label>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                        
                                        </div>
                                      </mat-card-content>
                                    </mat-card>
                                  </div>
                                  <div class="col-12 embed-responsive embed-responsive-16by9" style="margin-top: 10px;">
                                    <iframe class="controls embed-responsive-item" [src]="document" width="100%" height="500px" frameborder="0"
                                      webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                                  </div>
                        
                                </div>
                              </div>
                              <!-- <div *ngIf="report_detail_flag" class="row">
                                <a (click)="back_Nav()"><img src="../../../assets/ui_icons/buttons/Back_button.svg"
                                    class="backimgbtn_inpatinfo" /></a>
                              </div><br> -->
                        
                            </div>
                          </div>
                          <script src="../../../assets/dist/all.js"></script>
                    </div>
                </mdb-card-body>
            </mdb-card>
        </div>
    </div>
</div>