import { Component, OnInit } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { ipaddress } from '../../ipaddress';
import { ToastrService } from 'ngx-toastr';
import { Message_data } from 'src/assets/js/Message_data';
import { Helper_Class } from '../../helper_class';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import moment from 'moment';
import { DatePipe } from '@angular/common';
import { stringify } from '@angular/compiler/src/util';
// import * as moment from 'moment';



@Component({
  selector: 'app-timeline-report',
  templateUrl: './timeline-report.component.html',
  styleUrls: ['./timeline-report.component.scss']
})
export class TimelineReportComponent implements OnInit {
  public backbutton = false;
  public fromDate;
  public currentDate;
  public toDate;
  public currenttime;
  public presentdate;
  public hptl_clinic_id;
  public userInfo;
  public hptlClinicId;
  public timelinedatalist = [];
  public applystyle = true;
  public applyheight = true;
  public forcoverdiv: boolean = true;
  public reportType;
  public reportheading;
  public url;
  public pharmaid;
  public estimateflag;
  public diagnosis_center_id;
  public pharmacy_id;
  public docpharmaid;
  public docdiagnosisid;
  public undefinedtable: boolean = false;
  public estimate_tat;
  public estimate_dispature;
  public estimate_style;
  undefined: any;



  constructor(public http: Http, private datePipe: DatePipe, public toastr: ToastrService,) { }

  ngOnInit(): void {
    this.userInfo = Helper_Class.getInfo();
    // this.pharmaid = this.userInfo.pharma_id;

    console.log("phama id", this.pharmaid)
    console.log("user information ", this.userInfo)
    this.hptlClinicId = this.userInfo.hospitals[0].hptl_clinic_id;

    this.docpharmaid = this.userInfo.hospitals[0].pharma_id;
    this.docdiagnosisid = this.userInfo.hospitals[0].diag_centre_id;



    console.log(this.userInfo.user_type)
    console.log("Hospital id", this.hptlClinicId)
    this.diagnosis_center_id = this.userInfo.diag_center_id;
    this.pharmacy_id = this.userInfo.pharma_id
    console.log("pharmacy_id" + this.pharmacy_id)
    this.getCurrentDate();
    this.reportType = Helper_Class.getReportType();
    console.log("report type", this.reportType)
    if (this.reportType == 'htat') {
      this.reportheading = "Time line";

      this.url = "gen/htat/";
      console.log(this.url)
    }
    else if (this.reportType == 'pharmatat') {
      this.reportheading = "Pharma Time line";
      this.url = "gen/ptat";
      console.log(this.url)
    } else if (this.reportType == 'diagtat') {

      this.reportheading = "Diagnosis Time line";
      this.url = "gen/dtat";
      console.log(this.url)

    } else {
      this.reportheading = "erro";
    }



  }

  public getCurrentDate() {
    var headers = new Headers();
    var date;
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate', { country: ipaddress.country_code },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          console.log("current time", JSON.stringify(obj))
          this.currentDate = obj.current_date;//display in html for maximum date
          this.fromDate = obj.current_date;
          this.toDate = obj.current_date;
          var currenttime1 = obj.current_time.split(":");
          this.currenttime = currenttime1[0]
          this.presentdate = obj.current_date;

          // this.updateCurrentSession(this.presentdate,this.currenttime)
          //this.getSalesData();
          console.log('presentdate1', this.presentdate)
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });

  }

  public app_time;
  public order_time;
  public pres_time;
  public consult_time;
  public path_time;
  public reporttime;
  public sampledate;
  public estimatetime;
  public order_date;
  public estimatedate;
  public estimate_initiative_time;
  public pharmaestimatetime;
  public pharmaorder_time;
  public pharmaestimatedate;
  public sampletime;


  public app_timeboolean: boolean;
  public enddate;
  public totaltime;
  public rept_entry_time;
  public type;
  public redFlag: boolean = false;
  public redFlg_esti_order: boolean;
  public estimateandorder;
  public estimat_dis_style;

  convertDecimalToHoursMinutes(decimalHours) {
    const hours = Math.floor(decimalHours);
    const minutes = Math.round((decimalHours - hours) * 60);

    if (minutes >= 60) {
      const extraHours = Math.floor(minutes / 60);
      return {
        hours: hours + extraHours,
        minutes: (minutes + 1) % 60
        // minutes: submin + 1
      };
    }

    return { hours, minutes };
  }




  getSerialNumber(index: number): number {
    return index + 1;
  }


  getSalesData() {
    var timelinefetch;

    if (this.reportType == 'htat') {
      timelinefetch = {
        date: this.fromDate,
        hptl_clinic_id: this.hptlClinicId
      }
    }
    else if (this.reportType == 'pharmatat') {

      if (this.userInfo.user_type == "Admin") {
        console.log("pharma id", this.pharmacy_id)
        timelinefetch = {
          date: this.fromDate,
          pharma_id: this.pharmacy_id
        }

      }
      else if (this.userInfo.user_type == "doctor") {
        console.log("pharma id", this.docpharmaid)
        timelinefetch = {
          date: this.fromDate,
          pharma_id: this.docpharmaid
        }
      }
      else {

        timelinefetch = {
          date: this.fromDate,
          pharma_id: this.hptlClinicId
        }

      }

    }
    else if (this.reportType == 'diagtat') {

      if (this.userInfo.user_type == "Admin") {
        console.log("diagcenter id", this.diagnosis_center_id)
        timelinefetch = {
          date: this.fromDate,
          diag_centre_id: this.diagnosis_center_id
        }
      }
      else if (this.userInfo.user_type == "doctor") {

        console.log("diagcenter id", this.docdiagnosisid)
        timelinefetch = {
          date: this.fromDate,
          diag_centre_id: this.docdiagnosisid
        }
      }
      else {
        timelinefetch = {
          date: this.fromDate,
          diag_centre_id: this.hptlClinicId
        }
      }
    }

    else {
      timelinefetch = '';
    }
    // else if(this.reportType == 'diagtat'){
    //    var timelinefetch = {
    //    date:this.fromDate
    //     hptl_clinic_id:this.hptlClinicId
    //  }

    // }else{

    // }


    var patientname;
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + this.url, timelinefetch,
      { headers: headers }).subscribe(
        data => {
          var timelinedataval = data.json();
          console.log("Time line dataval" + JSON.stringify(timelinedataval))


          if (this.reportType == "htat") {
            if (timelinedataval.length != 0) {
              for (let i = 0; i < timelinedataval.tats.length; i++) {
                this.app_time = '';
                console.log("app time 1", this.app_time)
                if (timelinedataval.tats[i].middle_name != undefined) {
                  patientname = encrypt_decript.Decript(timelinedataval.tats[i].first_name) + " " + encrypt_decript.Decript(timelinedataval.tats[i].middle_name) + " " + encrypt_decript.Decript(timelinedataval.tats[i].last_name);
                } else {
                  patientname = encrypt_decript.Decript(timelinedataval.tats[i].first_name) + " " + encrypt_decript.Decript(timelinedataval.tats[i].last_name);
                }
                this.app_time = timelinedataval.tats[i].doc_app_time;
                this.consult_time = timelinedataval.tats[i].consult_time
                this.pres_time = timelinedataval.tats[i].pres_time;
                this.estimatetime = timelinedataval.tats[i].estimate_initiate_time;
                this.estimatedate = timelinedataval.tats[i].estimate_date;
                this.reporttime = timelinedataval.tats[i].report_entry_time;
                this.sampledate = timelinedataval.tats[i].sample_date;
                this.order_time = timelinedataval.tats[i].order_time;
                this.order_date = timelinedataval.tats[i].order_date

                console.log(this.app_time);




                if (this.app_time != undefined) {
                  // this.undefinedtable = false;
                  // if(this.app_time == undefined){
                  //   // this.app_timeboolean=false;
                  //   this.undefinedtable = true;
                  // } 
                  // else 
                  // {
                  //   this.app_timeboolean=true;
                  // }

                  var serialnumbr = 1;
                  serialnumbr++;
                  // serialnumbr =+ 1 
                  console.log(this.app_timeboolean)
                  var starttimeanddate = this.fromDate + " " + this.app_time;
                  console.log("starttime" + starttimeanddate)
                  // if (starttimeanddate == "undefined"){
                  //   this.undefinedtable = false;
                  // }
                  console.log(" starttimeanddate" + starttimeanddate)

                  if (this.order_time != undefined) {
                    var endtimeanddate = this.estimatedate + " " + this.order_time
                  }

                  else if (this.estimatetime != undefined) {
                    var endtimeanddate = this.estimatedate + " " + this.estimatetime
                  }

                  else if (this.reporttime != undefined) {
                    var endtimeanddate = this.estimatedate + " " + this.reporttime
                  }

                  else if (this.pres_time != undefined) {
                    var endtimeanddate = this.fromDate + " " + this.pres_time
                  }
                  else if (this.consult_time != undefined) {
                    var endtimeanddate = this.fromDate + " " + this.consult_time
                  } else {
                    var endtimeanddate = this.fromDate + " " + this.app_time
                  }



                  // var endtimeanddate=this.fromDate+" "+this.order_time;

                  console.log("start date", starttimeanddate)
                  console.log("end date", endtimeanddate)

                  // const consultTime = moment(timelinedataval.tats[i].consult_time, 'HH:mm:ss');
                  // const presTime = moment(timelinedataval.tats[i].pres_time, 'HH:mm:ss');
                  // const orderTime = moment(timelinedataval.tats[i].order_time, 'HH:mm:ss');

                  // const totalHours = consultTime.hours() + presTime.hours() + orderTime.hours();

                  // timelinedataval.tats[i].total_hours = totalHours;
                  // this.enddate=timelinedataval.tats[i].total_hours
                  // console.log("end date",this.enddate)
                  // console.log("for end time",JSON.stringify( timelinedataval))

                  const startTime = moment(starttimeanddate); // Replace this with your start time
                  const endTime = moment(endtimeanddate);   // Replace this with your end time

                  const duration = moment.duration(endTime.diff(startTime));

                  const hours = duration.hours();
                  const minutes = duration.minutes();

                  this.totaltime = hours + "." + minutes



                  if (starttimeanddate != undefined) {
                    const totalTimeInHours = hours + (minutes / 60);
                    const date = new Date(0, 0, 0, Math.floor(totalTimeInHours), (totalTimeInHours % 1) * 60);
                    this.totaltime = this.datePipe.transform(date, 'HH:mm')

                  }

                  // console.log( this.app_time)
                  // console.log(this.order_time)

                  // Calculate the difference in milliseconds
                  // var total =(parseFloat(timeDifferenceInHours)).toFixed(2)
                  // const timeDifferenceInMilliseconds = startTime.diff(endTime);
                  // Convert the difference to hours
                  //+4 value
                  // const timeDifferenceInMilliseconds = endTime.diff(startTime);

                  // console.log("end time",timeDifferenceInMilliseconds) 

                  // const timeDifferenceInHours = moment.duration(timeDifferenceInMilliseconds).asHours();
                  // console.log("difference in hrs",timeDifferenceInHours)


                  // timelinedataval.tats[i].time_hours = timeDifferenceInHours;


                  // this.totaltime= (parseFloat(timelinedataval.tats[i].time_hours)).toFixed(2)

                  // console.log("hrs",this.totaltime)
                  // const decimalHours = this.totaltime;
                  // const convertedTime = this.convertDecimalToHoursMinutes(decimalHours);
                  // var totalhrsmins= convertedTime.hours+":"+convertedTime.minutes


                  if (timelinedataval.tats[i].doc_app_time != undefined) {

                    const timeParts = timelinedataval.tats[i].doc_app_time.split(':');

                    timelinedataval.tats[i].doc_app_time = `${timeParts[0]}:${timeParts[1]}`
                  }

                  if (timelinedataval.tats[i].order_time != undefined) {

                    const timeParts = timelinedataval.tats[i].order_time.split(':');

                    timelinedataval.tats[i].order_time = `${timeParts[0]}:${timeParts[1]}`
                  }

                  if (timelinedataval.tats[i].pres_time != undefined) {

                    const timeParts = timelinedataval.tats[i].pres_time.split(':');

                    timelinedataval.tats[i].pres_time = `${timeParts[0]}:${timeParts[1]}`
                  }
                  if (timelinedataval.tats[i].report_entry_time != undefined) {

                    const timeParts = timelinedataval.tats[i].report_entry_time.split(':');

                    timelinedataval.tats[i].report_entry_time = `${timeParts[0]}:${timeParts[1]}`
                  }

                  // if (timelinedataval.tats[i].estimate_time != undefined){

                  //   const timeParts = timelinedataval.tats[i].estimate_time.split(':');

                  //   timelinedataval.tats[i].estimate_time = `${timeParts[0]}:${timeParts[1]}`
                  // }

                  if (timelinedataval.tats[i].estimate_initiate_time != undefined) {

                    const timeParts = timelinedataval.tats[i].estimate_initiate_time.split(':');

                    timelinedataval.tats[i].estimate_initiate_time = `${timeParts[0]}:${timeParts[1]}`
                  }

                  if (timelinedataval.tats[i].consult_time != undefined) {

                    const timeParts = timelinedataval.tats[i].consult_time.split(':');

                    timelinedataval.tats[i].consult_time = `${timeParts[0]}:${timeParts[1]}`
                  }

                  this.timelinedatalist.push({
                    //  sno:i+1,
                    sno: serialnumbr,
                    name: patientname,
                    app_time: timelinedataval.tats[i].doc_app_time,
                    order_time: timelinedataval.tats[i].order_time,
                    pres_time: timelinedataval.tats[i].pres_time,
                    diag_time: timelinedataval.tats[i].report_entry_time,
                    estimatetime: timelinedataval.tats[i].estimate_initiate_time,
                    consult_time: timelinedataval.tats[i].consult_time,
                    total: this.totaltime
                    // total:totalhrsmins
                    //total:totaltime
                  })

                }


                // this.timelinedatalist.push({
                //   sno:i+1,
                //   name:patientname,
                //   app_time:timelinedataval.tats[i].doc_app_time,
                //   order_time:timelinedataval.tats[i].order_time,
                //   pres_time:timelinedataval.tats[i].pres_time,
                //   diag_time:timelinedataval.tats[i].report_entry_time,
                //   estimatetime:timelinedataval.tats[i].estimate_initiate_time,
                //   consult_time:	timelinedataval.tats[i].consult_time,
                //    total:this.totaltime
                //   // total:totalhrsmins
                //   //total:totaltime
                // })
                this.forcoverdiv = false;
                this.backbutton = true
                console.log(JSON.stringify(this.timelinedatalist))
              }
            }
          }



          else if (this.reportType == 'pharmatat') {
            this.estimate_tat = this.userInfo.hospitals[0].estimate_tat;
            this.estimate_dispature = this.userInfo.hospitals[0].estimate_disp_tat;

            if (timelinedataval.length != 0) {
              for (let i = 0; i < timelinedataval.tats.length; i++) {

                this.app_time = '';
                console.log("app time 1", this.app_time)

                if (timelinedataval.tats[i].first_name != undefined && timelinedataval.tats[i].first_name != undefined) {
                  if (timelinedataval.tats[i].middle_name != undefined) {
                    patientname = encrypt_decript.Decript(timelinedataval.tats[i].first_name) + " " + encrypt_decript.Decript(timelinedataval.tats[i].middle_name) + " " + encrypt_decript.Decript(timelinedataval.tats[i].last_name);
                  } else {
                    patientname = encrypt_decript.Decript(timelinedataval.tats[i].first_name) + " " + encrypt_decript.Decript(timelinedataval.tats[i].last_name);
                  }

                } else {
                  patientname = timelinedataval.tats[i].delivered_person
                }
                this.estimate_initiative_time = timelinedataval.tats[i].estimate_initiate_time;
                this.pharmaestimatetime = timelinedataval.tats[i].estimate_time;

                this.pharmaorder_time = timelinedataval.tats[i].order_time;
                this.pharmaestimatedate = timelinedataval.tats[i].estimate_date;
                this.order_time = timelinedataval.tats[i].order_time
                // console.log(this.app_time);
                this.estimateflag = false;
                var starttimeanddate = this.fromDate + " " + this.estimate_initiative_time;

                if (this.order_time != undefined) {
                  var endtimeanddate = this.pharmaestimatedate + " " + this.order_time;
                  var type = "ordertime"

                } else if (this.pharmaestimatetime != undefined) {
                  var endtimeanddate = this.pharmaestimatedate + " " + this.pharmaestimatetime;
                  var type = "estimate time"
                  this.type = "estimate time"

                } else {
                  var endtimeanddate = this.pharmaestimatedate + " " + this.estimate_initiative_time
                  var type = "estimate"
                }

                // var endtimeanddate=this.fromDate+" "+this.order_time;
                console.log("start date", starttimeanddate)
                console.log(type, "end date", endtimeanddate)

                // Replace this with your start time
                const startTime = moment(starttimeanddate);
                // Replace this with your end time

                const endTime = moment(endtimeanddate);
                const duration = moment.duration(endTime.diff(startTime));

                const hours = duration.hours();
                const minutes = duration.minutes();

                this.totaltime = hours + "." + minutes

                //to display in 
                if (starttimeanddate != undefined) {
                  const totalTimeInHours = hours + (minutes / 60);
                  const date = new Date(0, 0, 0, Math.floor(totalTimeInHours), (totalTimeInHours % 1) * 60);
                  this.totaltime = this.datePipe.transform(date, 'HH:mm')
                }

                if (timelinedataval.tats[i].estimate_initiate_time != undefined) {
                  const timeParts = timelinedataval.tats[i].estimate_initiate_time.split(':');
                  timelinedataval.tats[i].estimate_initiate_time = `${timeParts[0]}:${timeParts[1]}`
                }

                if (timelinedataval.tats[i].estimate_time != undefined) {
                  const timeParts = timelinedataval.tats[i].estimate_time.split(':');
                  timelinedataval.tats[i].estimate_time = `${timeParts[0]}:${timeParts[1]}`
                }

                if (timelinedataval.tats[i].order_time != undefined) {
                  const timeParts = timelinedataval.tats[i].order_time.split(':');
                  timelinedataval.tats[i].order_time = `${timeParts[0]}:${timeParts[1]}`
                }

                if (timelinedataval.tats[i].estimate_time != undefined || timelinedataval.tats[i].order_time != undefined) {
                  if (this.estimate_tat != this.undefined) {

                    const timeParts = this.estimate_tat.split(':');
                    const hours = parseInt(timeParts[0], 10);
                    let minutes = parseInt(timeParts[1], 10);
                    const seconds = parseInt(timeParts[2], 10);

                    // Calculate the total duration in minutes and seconds
                    const totalMinutes = hours * 60 + minutes;
                    const remainingSeconds = seconds;

                    // Format minutes and seconds as "mm:ss"
                    var formattedestimateTime = String(totalMinutes).padStart(2, '0') + ':' + String(remainingSeconds).padStart(2, '0');

                    console.log('Formatted time:', formattedestimateTime);


                  }

                  if (this.estimate_dispature != this.undefined) {

                    const timeParts = this.estimate_dispature.split(':');
                    const hours = parseInt(timeParts[0], 10);
                    const minutes = parseInt(timeParts[1], 10);
                    const seconds = parseInt(timeParts[2], 10);

                    // Calculate the total duration in minutes and seconds
                    const totalMinutes = hours * 60 + minutes;
                    const remainingSeconds = seconds;
                    var estimatehrs = hours + ":" + minutes;
                    console.log()
                    // Format minutes and seconds as "mm:ss"
                    var formattedestimate_dispature = String(totalMinutes).padStart(2, '0') + ':' + String(remainingSeconds).padStart(2, '0');

                    console.log('Formatted time:', formattedestimate_dispature);


                  }

                  // ///////////////////////////
                  const estimate_intiate = moment(starttimeanddate);
                  const claculate_estimatetime = this.pharmaestimatedate + " " + this.pharmaestimatetime;
                  const estimatetimeend = moment(claculate_estimatetime);
                  const duration = moment.duration(estimatetimeend.diff(estimate_intiate));

                  // Get the difference in minutes and seconds
                  const init_and_estimate_minutes = duration.minutes();
                  const seconds = duration.seconds();
                  var estimate_init_and_estimate_dif = init_and_estimate_minutes + "." + seconds
                  console.log("difference inital and estimate" + estimate_init_and_estimate_dif)

                  // Split formattedestimateTime into minutes and seconds
                  const formattedTimeParts = formattedestimateTime.split(':');
                  const formattedMinutes = parseInt(formattedTimeParts[0], 10);
                  const formattedSeconds = parseInt(formattedTimeParts[1], 10);
                  // Convert estimate_init_and_estimate_dif to a number
                  const estimateInitAndEstimateDifNumber = parseFloat(estimate_init_and_estimate_dif);
                  console.log("estimate init " + estimateInitAndEstimateDifNumber)
                  // Compare the time differences in minutes

                  if (estimateInitAndEstimateDifNumber > formattedMinutes) {
                    this.redFlag = true;
                    this.estimate_style=true;
                  }else{

                    this.estimate_style=false;

                  }
                  console.log('redflag: ' + this.redFlag);
                  
                  
                  
                  
                  const estimatetimeanddate = this.fromDate + ' ' + this.pharmaestimatetime;
                  const claculate_ordertime = this.fromDate + ' ' + this.order_time;
                  const formattedTimeParts_dispature = formattedestimate_dispature.split(':');
                  const formattedMinutes_dispature = parseInt(formattedTimeParts_dispature[0], 10);

                  // Calculate duration
                  const estimate = moment(estimatetimeanddate);
                  const ordertimeend = moment(claculate_ordertime);
                  const estduration = moment.duration(ordertimeend.diff(estimate));
                  const hours = Math.floor(estduration.asHours());
                  const minutes = Math.floor(estduration.asMinutes()) - hours * 60;

                  // Log variables and calculated values for debugging
                  console.log('estimatetimeanddate: ' + estimatetimeanddate);
                  console.log('claculate_ordertime: ' + claculate_ordertime);
                  console.log('formattedMinutes_dispature: ' + formattedMinutes_dispature);
                  console.log('Duration: ' + hours + ' hours, ' + minutes + ' minutes');

                  if (minutes > formattedMinutes_dispature) {
                    
                    this.estimat_dis_style=true;
                    console.log("ngStyle RED" + JSON.stringify(this.estimat_dis_style))

                  } else {
                    this.estimat_dis_style=false;
                   console.log("ngStyle GREEN" + JSON.stringify(this.estimat_dis_style))
                  }

                  console.log('redflag estimate: ' + this.redFlg_esti_order);

                }

                this.timelinedatalist.push({
                  sno: i + 1,
                  name: patientname,
                  // deliveredby:delivered_person,
                  delivered_person: timelinedataval.tats[i].delivered_person,
                  estimate_initiate_time: timelinedataval.tats[i].estimate_initiate_time,
                  estimate_time: timelinedataval.tats[i].estimate_time,
                  order_time: timelinedataval.tats[i].order_time,

                  total: this.totaltime,
                  estimat_disp: formattedestimate_dispature,
                  // estimate: this.estimate_tat
                  estimate: formattedestimateTime,
                  estimatestyle:this.estimate_style,
                  estimatedispatchstyle:this.estimat_dis_style

                })
                // if(){
                //   this.estimateflag=true;
                //   console.log(this.estimateflag)
                // } 

                this.forcoverdiv = false;
                this.backbutton = true
                console.log(JSON.stringify(this.timelinedatalist))
              }
            }

          }

          else if (this.reportType == 'diagtat') {
            if (timelinedataval.length != 0) {
              for (let i = 0; i < timelinedataval.tats.length; i++) {
                this.app_time = '';
                console.log("app time 1", this.app_time)
                if (timelinedataval.tats[i].middle_name != undefined) {
                  patientname = encrypt_decript.Decript(timelinedataval.tats[i].first_name) + " " + encrypt_decript.Decript(timelinedataval.tats[i].middle_name) + " " + encrypt_decript.Decript(timelinedataval.tats[i].last_name);
                } else {
                  patientname = encrypt_decript.Decript(timelinedataval.tats[i].first_name) + " " + encrypt_decript.Decript(timelinedataval.tats[i].last_name);
                }
                this.app_time = timelinedataval.tats[i].diag_app_time;
                this.sampletime = timelinedataval.tats[i].sample_time;
                this.rept_entry_time = timelinedataval.tats[i].report_entry_time;
                this.sampledate = timelinedataval.tats[i].sample_date;
                // this.consult_time=timelinedataval.tats[i].consult_time
                // this.pres_time=timelinedataval.tats[i].pres_time;
                // this.estimatetime=timelinedataval.tats[i].estimate_time;
                // this.estimatedate=timelinedataval.tats[i].estimate_date;
                // this.reporttime=timelinedataval.tats[i].report_entry_time;
                // this.sampledate=timelinedataval.tats[i].sample_date;
                this.order_time = timelinedataval.tats[i].report_approved_time;
                // this.order_date=timelinedataval.tats[i].order_date;

                console.log(this.app_time);

                if (this.app_time == undefined) {
                  this.app_timeboolean = false;
                }
                else {
                  this.app_timeboolean = true;
                }
                console.log(this.app_timeboolean)
                var starttimeanddate = this.fromDate + " " + this.app_time;


                if (this.order_time != undefined) {
                  var endtimeanddate = this.sampledate + " " + this.order_time
                }

                else if (this.sampletime != undefined) {
                  var endtimeanddate = this.sampledate + " " + this.sampletime
                }

                else if (this.rept_entry_time != undefined) {
                  var endtimeanddate = this.sampledate + " " + this.rept_entry_time
                }
                else {
                  var endtimeanddate = this.fromDate + " " + this.app_time;
                }
                console.log("start date", starttimeanddate)
                console.log("end date", endtimeanddate)


                const startTime = moment(starttimeanddate);
                const endTime = moment(endtimeanddate);

                const duration = moment.duration(endTime.diff(startTime));

                const hours = duration.hours();
                const minutes = duration.minutes();

                this.totaltime = hours + "." + minutes

                // total_time = hours + (minutes / 60)  #
                // //  Calculate the total time in hours
                // var formatted_time = "{:02}:{:02}".format(int(total_time), int((total_time % 1) * 60))
                // print(formatted_time)  
                // //  Output: "05:30"

                if (starttimeanddate != undefined) {
                  const totalTimeInHours = hours + (minutes / 60);
                  const date = new Date(0, 0, 0, Math.floor(totalTimeInHours), (totalTimeInHours % 1) * 60);
                  this.totaltime = this.datePipe.transform(date, 'HH:mm')
                }



                if (timelinedataval.tats[i].diag_app_time != undefined) {

                  const timeParts = timelinedataval.tats[i].diag_app_time.split(':');

                  timelinedataval.tats[i].diag_app_time = `${timeParts[0]}:${timeParts[1]}`
                }

                if (timelinedataval.tats[i].sample_time != undefined) {

                  const timeParts = timelinedataval.tats[i].sample_time.split(':');

                  timelinedataval.tats[i].sample_time = `${timeParts[0]}:${timeParts[1]}`
                }

                if (timelinedataval.tats[i].report_entry_time != undefined) {

                  const timeParts = timelinedataval.tats[i].report_entry_time.split(':');

                  timelinedataval.tats[i].report_entry_time = `${timeParts[0]}:${timeParts[1]}`
                }

                if (timelinedataval.tats[i].report_approved_time != undefined) {

                  const timeParts = timelinedataval.tats[i].report_entry_time.split(':');

                  timelinedataval.tats[i].report_entry_time = `${timeParts[0]}:${timeParts[1]}`
                }


                this.timelinedatalist.push({
                  sno: i + 1,
                  name: patientname,
                  app_time: timelinedataval.tats[i].diag_app_time,
                  sample_time: timelinedataval.tats[i].sample_time,
                  report_entry_time: timelinedataval.tats[i].report_entry_time,
                  approval_time: timelinedataval.tats[i].report_approved_time,
                  // order_time:timelinedataval.tats[i].order_time,
                  // pres_time:timelinedataval.tats[i].pres_time,
                  // diag_time:timelinedataval.tats[i].report_entry_time,
                  // estimatetime:timelinedataval.tats[i].estimate_time,
                  // consult_time:	timelinedataval.tats[i].consult_time,
                  total: this.totaltime

                })
                this.forcoverdiv = false;
                this.backbutton = true
                console.log(JSON.stringify(this.timelinedatalist))
              }
            }
          }

        },
        error => { });
  }



  todisplaycoverdiv() {
    this.forcoverdiv = true;
    this.backbutton = false;
    // this.amountcard = false;
    // this.ishide = false;
    this.timelinedatalist = [];
  }
}
