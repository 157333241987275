<div class="row">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Readings</h5>
                    </div>
                 
                    <div class="headerButtons">
                        <img *ngIf="save_flag" src="../../../assets/ui_icons/buttons/save_button.svg" class="saveimgbtn_inpatinfo"  (click)="save_reading()"/>
                        <img *ngIf="save_flag" src="../../../assets/ui_icons/buttons/Back_button.svg" class="saveimgbtn_inpatinfo" (click)=" save_flag = false; default_graph('');" />
                        <img *ngIf="!save_flag" class="saveimgbtn_inpatinfo" src="../../../assets/ui_icons/buttons/new_button.svg" (click)="save_flag = true" />
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>
                <div class="col-12" id="CLvital">
                    <owl-carousel-o [options]="customOptions">
                        <ng-template carouselSlide *ngFor="let relation of relationlistData;">
                            <div class="slide">
                                <div class="cu_slider_cover">
                                    <div class="card_border1"
                                        (click)="selectedRelation(relation.rel_name,relation.rel_id,relation.sub_rel_id,relation.life_style_id)">
                                        <div class="d-flex justify-content-center">
                                            <img class="img-fluid" class="image" src="{{relation.prof_img}}"
                                                alt="Card image cap">
                                        </div>
                                        <p class="carousel_label">{{relation.rel_name}}</p>
                                    </div>
                                    <a tabindex="0">
                                        <div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </ng-template>
                    </owl-carousel-o>
                </div>
                <div *ngIf="save_flag" class="row">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div class="cover_div">
                            <div class="header_lable">
                                Blood glucose
                             </div>
                             <div class="content_cover">
                                <div class="row">
                                    <div class="col-3 col-md-3 col-lg-3 col-xl-3">
                                        <mat-checkbox color="primary"  matInput  (change)="fast_chn()" [(ngModel)]="fasting" labelPosition="before">Fasting</mat-checkbox>
                                    </div>
                                    <div class="col-3 col-md-3 col-lg-3 col-xl-3">
                                        <mat-checkbox  color="primary" matInput  [(ngModel)]="ogit_txt" (change)="ogtt_chn()"   labelPosition="before">OGTT</mat-checkbox>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class=" col-12 col-md-3 col-lg-4 col-xl-4">
                                        <mat-label class="matlabel">Fasting / 1/2 hour<br>
                                            <input type="text" class="ipcss " matInput placeholder="mg/dl" maxlength="3" [(ngModel)]="Fasting_Halfhour" required onkeypress="return event.charCode >= 48 && event.charCode <= 57"/>
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-md-3 col-lg-4 col-xl-4">
                                        <mat-label class="matlabel">1 hour<br>
                                            <input type="text" class="ipcss " matInput placeholder="mg/dl" maxlength="3" disabled="{{fast_one_disable}}" [(ngModel)]="Fasting_Onehour"
                                            onkeypress="return event.charCode >= 48 && event.charCode <= 57"/>
                                        </mat-label>
                                    </div>
                                    <div class=" col-12 col-md-3 col-lg-4 col-xl-4">
                                        
                                    <mat-label class="matlabel">1 1/2 hours<br>
                                        <input type="text" class="ipcss " matInput  placeholder="mg/dl" maxlength="3" disabled="{{fast_onehalf_disable}}" [(ngModel)]="Fasting_Onehalfhour"
                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"/>
                                    </mat-label>
                                    </div>
                                    <div class="  col-12 col-md-3 col-lg-4 col-xl-4">
                                        <mat-label class="matlabel">Postprandial<br>
                                            <input type="text"class="ipcss " matInput placeholder="mg/dl" maxlength="3" [(ngModel)]="Postprandial" required onkeypress="return event.charCode >= 48 && event.charCode <= 57"/>
                                        </mat-label>
                                    </div>
                                    <div class="  col-12 col-md-3 col-lg-4 col-xl-4">
                                        
                                    <mat-label class="matlabel">HbA1c<br>
                                        <input type="text" class="ipcss " placeholder="mg/dl" maxlength="3" [(ngModel)]="HbA1c" onkeypress="return event.charCode >= 48 && event.charCode <= 57 ||  event.charCode==46"/>
                                    </mat-label>
                                    </div>
                                </div>
                             </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" >
                        <div class="cover_div">
                            <div class="header_lable">
                                Vital
                             </div>
                             <div class="content_cover" style="height: 136px;">
                                <div class="row">
                                    <div class="col-12 col-md-3 col-lg-4 col-xl-4">
                                        <mat-label class="matlabel">Temperature<br>
                                            <!-- <input class="ipcss " type="text" placeholder="0.00 F" maxlength="6" [(ngModel)]="Temperature" 
                                            onkeypress="return event.charCode >= 48 && event.charCode <= 57" (ionChange)="formatNumber($event)"/> -->
                                            <input class="ipcss " type="number" placeholder="0.00 F" maxlength="6" [(ngModel)]="Temperature" pattern="^\d\d+\.[1-9]\d*$" onKeyPress="if(Temperature.length==5) return false;" (ionChange)="formatNumber($event)"/>
                                        </mat-label>
                                    </div>
                                    <div class=" col-12 col-md-3 col-lg-4 col-xl-4">
                                        <mat-label class="matlabel">Blood pressure  
                                            <input type="text" class="ipcss " matInput  placeholder="mm Hg" maxlength="3" [(ngModel)]="BP_01" onkeypress="return event.charCode >= 48 && event.charCode <= 57"/>
                                        </mat-label>
                                        </div>
                                        <div class="col-12 col-md-3 col-lg-4 col-xl-4" style="position: relative;">
                                        <mat-label class="matlabel"><br>
                                            <span class="slash_icon">/</span>
                                            <input type="text" class="ipcss " matInput placeholder="mm Hg" maxlength="3" [(ngModel)]="BP_02" onkeypress="return event.charCode >= 48 && event.charCode <= 57"/>
                                        </mat-label>
                                  
                            
                                    </div>
                                    <div class="col-12 col-md-3 col-lg-4 col-xl-4">
                                        <mat-label class="matlabel" >Heart beat<br>
                                            <input type="text" class="ipcss " matInput  placeholder="Pulse" maxlength="3" [(ngModel)]="Heart_Beat" onkeypress="return event.charCode >= 48 && event.charCode <= 57"/>
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-md-3 col-lg-4 col-xl-4">
                                        <mat-label class="matlabel">Respiratory rate<br>
                                            <input type="text" class="ipcss " matInput  placeholder="Rate / minute" maxlength="2" [(ngModel)]="Respiratory_rate" onkeypress="return event.charCode >= 48 && event.charCode <= 57"/>
                                        </mat-label>
                                    </div>
                                </div>
                             </div>
                        </div>
                        
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div class="cover_div">
                            <div class="header_lable">
                                Urine glucose
                             </div>
                             <div class="content_cover">
                                <div class="row">
                                    <div class="col-12 col-md-3 col-lg-4 col-xl-4">
                                        <mat-label class="matlabel">Glucose<br>
                                            <input type="text" class="ipcss " matInput  placeholder="mg/dl" maxlength="3" [(ngModel)]="Glucose" [required]="Ketones!=undefined && Ketones.length!=0 || Proteins!=undefined && Proteins.length!=0"
                                            onkeypress="return event.charCode >= 48 && event.charCode <= 57 ||  event.charCode==46"/>
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-md-3 col-lg-4 col-xl-4">
                                        <mat-label class="matlabel" [hidden]="ketone_hidden">Ketones<br>
                                            <input type="text"class="ipcss " matInput  placeholder="mgol/L" maxlength="3" [(ngModel)]="Ketones" onkeypress="return event.charCode >= 48 && event.charCode <= 57 ||  event.charCode==46"/>
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-md-3 col-lg-4 col-xl-4">
                                        
                                        <mat-label class="matlabel" [hidden]="protin_hidden">Protein<br>
                                            <input type="text" class="ipcss " matInputplaceholder="mg/dl" maxlength="3" [(ngModel)]="Proteins" onkeypress="return event.charCode >= 48 && event.charCode <= 57 ||  event.charCode==46"/>
                                        </mat-label>
                                    </div>
                                </div>
                             </div>
                        </div>
                        
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" >
                        <div class="cover_div">
                            <div class="header_lable">
                                Cholesterol
                             </div>
                             <div class="content_cover">
                                <div class="row">
                                    <div class="col-6 col-md-3 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">HDL<br>
                                            <input type="text" class="ipcss " matInput   placeholder="mg/dl" maxlength="3" [(ngModel)]="Cholesterol_HDL" onkeypress="return event.charCode >= 48 && event.charCode <= 57"/>
                                        </mat-label>
                                    </div>
                                    <div class="col-6 col-md-3 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">LDL<br>
                                            <input type="text" class="ipcss " matInput  placeholder="mg/dl" maxlength="3" [(ngModel)]="Cholesterol_LDL" onkeypress="return event.charCode >= 48 && event.charCode <= 57"/>
                                        </mat-label>
                                    </div>
                                    <div class="col-6 col-md-3 col-lg-3 col-xl-3">
                            
                                        <mat-label class="matlabel">Trigs<br>
                                            <input type="text" class="ipcss " matInput   placeholder="mg/dl" maxlength="3" [(ngModel)]="Cholesterol_Trigs" onkeypress="return event.charCode >= 48 && event.charCode <= 57 || event.charCode==46"/>
                                        </mat-label>
                                    </div>
                                    <div class="col-6 col-md-2 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">VLDL<br>
                                            <input type="text" class="ipcss " matInput   placeholder="mg/dl" maxlength="3" [(ngModel)]="Cholesterol_VLDL" onkeypress="return event.charCode >= 48 && event.charCode <= 57"/>
                                        </mat-label>
                                    </div>
                                </div>
                             </div>
                        </div>
                        
                    </div>
                </div>
                <div *ngIf="!save_flag" class="cover_div">
                    <div class="content_cover">
                        <div class="row">
                            <!-- <div [hidden]="nodapp" class="nodata">No reading(s) found</div> -->
                            <div class="padd_top_btm">
                                <mat-accordion class="mataccordion">
                                    <mat-expansion-panel class="expandpanel" [expanded]="true" (click)="tab_click('1')">
                                        <mat-expansion-panel-header class="exppanel width ">
                                            <mat-panel-title class="title ">Current</mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <hr class="hr">
                                        <div style="margin-top: 15px;">
                                            <div class="row">
                                                <div class="col-12 col-md-6 col-lg-6 col-xl-6">
                                                    <mat-card class="">
                                                        <div class="appint_graph">
                                                            <div [hidden]="glucose_no_data" class="graph_dataunavail">
                                                                <p class="Paraclass">
                                                                    <img src="../../../assets/img/Warning.png"
                                                                        class="Imageclass" />No data found
                                                                </p>
                                                            </div>
                                                            <div [hidden]="!glucose_no_data" id="Third_lvl2" class="chart_div"></div>
                                                            <p class="text-center cahrat_para">Blood glucose</p>
                                                        </div>
                                                    </mat-card>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-6 col-xl-6">
                                                    <mat-card class="">
                                                        <div class="appint_graph">
                                                            <div [hidden]="HbA1c_no_data" class="graph_dataunavail">
                                                                <p class="Paraclass">
                                                                    <img src="../../../assets/img/Warning.png"
                                                                        class="Imageclass" />No data found
                                                                </p>
                                                            </div>
                                                            <div id="Third_lvl1" class="chart_div"
                                                                [hidden]="!HbA1c_no_data"></div>
                                                            <p class="text-center cahrat_para">HbA1c</p>
                                                        </div>
                                                    </mat-card>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12 col-md-6 col-lg-6 col-xl-6">
                                                    <mat-card class="">
                                                        <div class="appint_graph">
                                                            <!-- <h1>Cur_Urine_avail {{Cur_Urine_avail}} </h1> -->
                                                            <!-- <h1>Cur_Urine_avail {{Cur_Urine_avail}} </h1> -->
                                                            <div [hidden]="!Cur_Urine_avail" class="graph_dataunavail">
                                                                <p class="Paraclass">
                                                                    <img src="../../../assets/img/Warning.png"
                                                                        class="Imageclass" />No data found
                                                                </p>
                                                            </div>
                                                            <div [hidden]="Cur_Urine_avail" id="Urine_Glucose"
                                                                class="chart_div"></div>
                                                            <p class="text-center text-center cahrat_para">Urine glucose
                                                            </p>
                                                        </div>

                                                    </mat-card>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-6 col-xl-6">
                                                    <mat-card class="">
                                                        <div class="appint_graph">
                                                            <div [hidden]="Cur_Ketone_unavail"
                                                                class="graph_dataunavail">
                                                                <p class="Paraclass">
                                                                    <img src="../../../assets/img/Warning.png"
                                                                        class="Imageclass" />No data found
                                                                </p>
                                                            </div>
                                                            <div [hidden]="!Cur_Ketone_unavail" id="cur_ketone"
                                                                class="chart_div"></div>
                                                            <p class="text-center cahrat_para">Ketone</p>
                                                        </div>
                                                    </mat-card>
                                                </div>
                                            </div>
                                            <div class="row ">
                                                <div class="col-12 col-md-6 col-lg-6 col-xl-6">
                                                    <mat-card class="">
                                                        <div class="appint_graph">
                                                            <div [hidden]="Cur_Protein_unavail"
                                                                class="graph_dataunavail">
                                                                <p class="Paraclass">
                                                                    <img src="../../../assets/img/Warning.png"
                                                                        class="Imageclass" />No data found
                                                                </p>
                                                            </div>
                                                            <div [hidden]="!Cur_Protein_unavail" id="cur_protein"
                                                                class="chart_div"></div>
                                                            <p class="text-center cahrat_para">Protein</p>
                                                        </div>

                                                    </mat-card>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-6 col-xl-6">
                                                    <mat-card class="">
                                                        <div class="appint_graph">
                                                            <div [hidden]="Cur_Cholesterol_unavail"
                                                                class="graph_dataunavail">
                                                                <p class="Paraclass">
                                                                    <img src="../../../assets/img/Warning.png"
                                                                        class="Imageclass" />No data found
                                                                </p>
                                                            </div>
                                                            <div [hidden]="!Cur_Cholesterol_unavail" id="cur_colestrol"
                                                                class="chart_div"></div>
                                                            <p class="text-center cahrat_para">Cholesterol</p>
                                                        </div>
                                                    </mat-card>
                                                </div>

                                            </div>
                                        </div>
                                    </mat-expansion-panel>
                                </mat-accordion>
                            </div>
                            <!-- expansion2ng s -->
                            <div class="padd_top_btm">
                                <mat-accordion class="mataccordion">
                                    <mat-expansion-panel class="expandpanel" (click)="tab_click('2')">
                                        <mat-expansion-panel-header class="exppanel width">
                                            <mat-panel-title class="title ">
                                                Past 1 month
                                            </mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <hr class="hr">
                                        <div style="margin-top: 15px;">
                                            <div class="row">
                                                <div  class="col-12 col-md-6 col-lg-6 col-xl-6">
                                                    <mat-card class="">
                                                        <div class="appint_graph">
                                                            <div [hidden]="glucose_no_data">
                                                                <div class="graph_dataunavail">
                                                                    <p class="Paraclass">
                                                                        <img src="../../../assets/img/Warning.png"
                                                                            class="Imageclass" />No data found
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div [hidden]="!glucose_no_data" id="Blood_Glucose_Chart" class="chart_div"></div>
                                                            <div>
                                                                <p class="text-center cahrat_para">Blood glucose</p>
                                                            </div>
                                                        </div>
                                                    </mat-card>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-6 col-xl-6">
                                                    <mat-card class="">
                                                        <div class="appint_graph">
                                                            <div [hidden]="HbA1c_one_no_data">
                                                                <div class="graph_dataunavail">
                                                                    <p class="Paraclass">
                                                                        <img src="../../../assets/img/Warning.png"
                                                                            class="Imageclass" />No data found
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div [hidden]="!HbA1c_one_no_data">
                                                                <div id="chartContainer5" class="chart_div"></div>
                                                            </div>
                                                            <div>
                                                                <p class="text-center cahrat_para">HbA1c</p>
                                                            </div>
                                                        </div>
                                                    </mat-card>
                                                </div>
                                            </div>
                                            <div class=" row">
                                                <div class="col-12 col-md-6 col-lg-6 col-xl-6">
                                                    <mat-card class="">
                                                        <div class="appint_graph">
                                                            <div [hidden]="blood_glu_one_notavail"
                                                                class="graph_dataunavail">
                                                                <p class="Paraclass">
                                                                    <img src="../../../assets/img/Warning.png"
                                                                        class="Imageclass" />No data found
                                                                </p>
                                                            </div>
                                                            <div id="Urine_Charts" class="chart_div"
                                                                [hidden]="!blood_glu_one_notavail"></div>
                                                            <div>
                                                                <p class="text-center cahrat_para">Urine glucose</p>
                                                            </div>
                                                        </div>
                                                    </mat-card>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-6 col-xl-6">
                                                    <mat-card class="">
                                                        <div class="appint_graph">
                                                            <div [hidden]="coles_one_notavail"
                                                                class="graph_dataunavail">
                                                                <p class="Paraclass">
                                                                    <img src="../../../assets/img/Warning.png"
                                                                        class="Imageclass" />No data found
                                                                </p>
                                                            </div>
                                                            <div id="chartContainer6" class="chart_div"
                                                                [hidden]="!coles_one_notavail"></div>
                                                            <div>
                                                                <p class="text-center cahrat_para">Cholesterol</p>
                                                            </div>
                                                        </div>
                                                    </mat-card>
                                                </div>
                                            </div>
                                        </div>
                                    </mat-expansion-panel>
                                </mat-accordion>
                            </div>
                            <!-- expansionpast3  s -->
                            <div class="padd_top_btm">
                                <mat-accordion class="mataccordion">
                                    <mat-expansion-panel class="expandpanel" (click)="tab_click('3')">
                                        <mat-expansion-panel-header class="exppanel width">
                                            <mat-panel-title class="title">
                                                Past 3 months
                                            </mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <hr class="hr">
                                        <div style="margin-top: 15px;">
                                            <div class="row">
                                                <div class="col-12 col-md-6 col-lg-6 col-xl-6">
                                                    <mat-card class="">
                                                        <div class="appint_graph">
                                                            <div [hidden]="glucose_no_data" class="graph_dataunavail">
                                                                <p class="Paraclass">
                                                                    <img src="../../../assets/img/Warning.png"
                                                                        class="Imageclass" />No data found
                                                                </p>
                                                            </div>
                                                            <div [hidden]="!glucose_no_data" id="Blood_three_Glucose_Chart" class="chart_div"></div>
                                                            <div>
                                                                <p class="text-center cahrat_para">Blood glucose</p>
                                                            </div>
                                                        </div>
                                                    </mat-card>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-6 col-xl-6">
                                                    <mat-card class="">
                                                        <div class="appint_graph">
                                                            <div [hidden]="HbA1c_three_no_data"
                                                                class="graph_dataunavail">
                                                                <p class="Paraclass">
                                                                    <img src="../../../assets/img/Warning.png"
                                                                        class="Imageclass" />No data found
                                                                </p>
                                                            </div>
                                                            <div id="hbA1c_three" class="chart_div"
                                                                [hidden]="!HbA1c_three_no_data"></div>
                                                            <div>
                                                                <p class="text-center cahrat_para">HbA1c</p>
                                                            </div>
                                                        </div>
                                                    </mat-card>
                                                </div>

                                            </div>
                                            <div class="row">
                                                <div class="col-12 col-md-6 col-lg-6 col-xl-6">
                                                    <mat-card class="">
                                                        <div class="appint_graph">
                                                            <div [hidden]="urine_glu_notavail"
                                                                class="graph_dataunavail">
                                                                <p class="Paraclass">
                                                                    <img src="../../../assets/img/Warning.png"
                                                                        class="Imageclass" />No data found
                                                                </p>
                                                            </div>
                                                            <div id="Urine_three_Charts" class="chart_div"
                                                                [hidden]="!urine_glu_notavail"></div>
                                                            <div>
                                                                <p class="text-center cahrat_para">Urine glucose</p>
                                                            </div>
                                                        </div>
                                                    </mat-card>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-6 col-xl-6">
                                                    <mat-card class="">
                                                        <div class="appint_graph">
                                                            <div [hidden]="coles_three_notavail"
                                                                class="graph_dataunavail">
                                                                <p class="Paraclass">
                                                                    <img src="../../../assets/img/Warning.png"
                                                                        class="Imageclass" />No data found
                                                                </p>
                                                            </div>
                                                            <div id="coles_three_graph" class="chart_div"
                                                                [hidden]="!coles_three_notavail"></div>
                                                            <div>
                                                                <p class="text-center cahrat_para">Cholesterol</p>
                                                            </div>
                                                        </div>
                                                    </mat-card>
                                                </div>
                                            </div>
                                        </div>
                                    </mat-expansion-panel>
                                </mat-accordion>
                            </div>
                            <!-- expansionpast6  s -->
                            <div class="padd_top_btm">
                                <mat-accordion class="mataccordion">
                                    <mat-expansion-panel class="expandpanel" (click)="tab_click('6')">
                                        <mat-expansion-panel-header class="exppanel width">
                                            <mat-panel-title class="title">
                                                Past 6 months
                                            </mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <hr class="hr">
                                        <div style="margin-top: 15px;">
                                            <div class="row">
                                                <div class="col-12 col-md-6 col-lg-6 col-xl-6">
                                                    <mat-card class="">
                                                        <div class="appint_graph">
                                                            <div [hidden]="glucose_no_data" class="graph_dataunavail">
                                                                <p class="Paraclass">
                                                                    <img src="../../../assets/img/Warning.png"
                                                                        class="Imageclass" />No data found
                                                                </p>
                                                            </div>
                                                            <div [hidden]="!glucose_no_data" id="Blood_six_Glucose_Chart" class="chart_div"></div>
                                                            <div>
                                                                <p class="text-center cahrat_para">Blood glucose</p>
                                                            </div>
                                                        </div>
                                                    </mat-card>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-6 col-xl-6">
                                                    <mat-card class="">
                                                        <div class="appint_graph">
                                                            <div [hidden]="HbA1c_six_no_data" class="graph_dataunavail">
                                                                <p class="Paraclass">
                                                                    <img src="../../../assets/img/Warning.png"
                                                                        class="Imageclass" />No data found
                                                                </p>
                                                            </div>
                                                            <div id="HbA1c_six" class="chart_div"
                                                                [hidden]="!HbA1c_six_no_data"></div>
                                                            <div>
                                                                <p class="text-center cahrat_para">HbA1c</p>
                                                            </div>
                                                        </div>
                                                    </mat-card>
                                                </div>

                                            </div>
                                            <div class=" row">
                                                <div class="col-12 col-md-6 col-lg-6 col-xl-6">
                                                    <mat-card class="">
                                                        <div class="appint_graph">
                                                            <div [hidden]="blood_glu_six_notavail"
                                                                class="graph_dataunavail">
                                                                <p class="Paraclass">
                                                                    <img src="../../../assets/img/Warning.png"
                                                                        class="Imageclass" />No data found
                                                                </p>
                                                            </div>
                                                            <div id="Urine_six_Charts" class="chart_div"
                                                                [hidden]="!blood_glu_six_notavail"></div>
                                                            <div>
                                                                <p class="text-center cahrat_para">Urine glucose</p>
                                                            </div>
                                                        </div>
                                                    </mat-card>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-6 col-xl-6">
                                                    <mat-card class="">
                                                        <div class="appint_graph">
                                                            <div [hidden]="coles_six_notavail"
                                                                class="graph_dataunavail">
                                                                <p class="Paraclass">
                                                                    <img src="../../../assets/img/Warning.png"
                                                                        class="Imageclass" />No data found
                                                                </p>
                                                            </div>
                                                            <div id="coles_six_month" class="chart_div"
                                                                [hidden]="!coles_six_notavail"></div>
                                                            <div>
                                                                <p class="text-center cahrat_para">Cholesterol</p>
                                                            </div>
                                                        </div>
                                                    </mat-card>
                                                </div>
                                            </div>
                                        </div>
                                    </mat-expansion-panel>
                                </mat-accordion>

                            </div>
                            <!-- expansionpast1 yr  s -->
                            <div class="padd_top_btm">

                                <mat-accordion class="mataccordion">
                                    <mat-expansion-panel class="expandpanel" (click)="tab_click('1year')">
                                        <mat-expansion-panel-header class="exppanel width">
                                            <mat-panel-title class="title">
                                                Past 1 year
                                            </mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <hr class="hr">

                                        <div style="margin-top: 15px;">
                                            <div class=" row">
                                                <div class="col-12 col-md-6 col-lg-6 col-xl-6">
                                                    <mat-card class="">
                                                        <div class="appint_graph">
                                                            <div [hidden]="glucose_1year_no_data" class="graph_dataunavail">
                                                                <p class="Paraclass">
                                                                    <img src="../../../assets/img/Warning.png"
                                                                        class="Imageclass" />No data found
                                                                </p>
                                                            </div>
                                                            <div [hidden]="!glucose_1year_no_data" id="Blood_oneyear_Glucose_Chart" class="chart_div">
                                                            </div>
                                                            <mat-card-content>
                                                                <div>
                                                                    <p class="text-center cahrat_para">Blood glucose</p>
                                                                </div>
                                                            </mat-card-content>
                                                        </div>
                                                    </mat-card>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-6 col-xl-6">
                                                    <mat-card class="">
                                                        <div class="appint_graph">
                                                            <div [hidden]="HbA1c_1year_no_data"
                                                                class="graph_dataunavail">
                                                                <p class="Paraclass">
                                                                    <img src="../../../assets/img/Warning.png"
                                                                        class="Imageclass" />No data found
                                                                </p>
                                                            </div>
                                                            <div id="HbA1c_1year" class="chart_div"
                                                                [hidden]="!HbA1c_1year_no_data"></div>
                                                            <mat-card-content>
                                                                <div>
                                                                    <p class="text-center cahrat_para">HbA1c</p>
                                                                </div>
                                                            </mat-card-content>
                                                        </div>
                                                    </mat-card>
                                                </div>

                                            </div>
                                            <div class="row">
                                                <div class="col-12 col-md-6 col-lg-6 col-xl-6">
                                                    <mat-card class="">
                                                        <div class="appint_graph">
                                                            <div [hidden]="blood_glu_1year_notavail"
                                                                class="graph_dataunavail">
                                                                <p class="Paraclass">
                                                                    <img src="../../../assets/img/Warning.png"
                                                                        class="Imageclass" />No data found
                                                                </p>
                                                            </div>
                                                            <div id="Urine_1year_Charts" class="chart_div"
                                                                [hidden]="!blood_glu_1year_notavail"></div>
                                                            <mat-card-content>
                                                                <div>
                                                                    <p class="text-center cahrat_para">Urine glucose</p>
                                                                </div>
                                                            </mat-card-content>
                                                        </div>
                                                    </mat-card>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-6 col-xl-6">
                                                    <mat-card class="">
                                                        <div class="appint_graph">
                                                            <div [hidden]="coles_1year_notavail"
                                                                class="graph_dataunavail">
                                                                <p class="Paraclass">
                                                                    <img src="../../../assets/img/Warning.png"
                                                                        class="Imageclass" />No data found
                                                                </p>
                                                            </div>
                                                            <div id="coles_1year_month" class="chart_div"
                                                                [hidden]="!coles_1year_notavail"></div>
                                                            <mat-card-content></mat-card-content>
                                                            <div>
                                                                <p class="text-center cahrat_para">Cholesterol</p>
                                                            </div>
                                                        </div>
                                                    </mat-card>
                                                </div>
                                            </div>
                                        </div>
                                    </mat-expansion-panel>
                                </mat-accordion>

                            </div>
                            <!-- expansionpast2 yr  s -->
                            <div class="padd_top_btm">

                                <mat-accordion class="mataccordion">
                                    <mat-expansion-panel class="expandpanel" (click)="tab_click('2year')">
                                        <mat-expansion-panel-header class="exppanel width">
                                            <mat-panel-title class="title">
                                                Past 2 years
                                            </mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <hr class="hr">

                                        <div style="margin-top: 15px;">
                                            <div class="row">
                                                <div class="col-12 col-md-6 col-lg-6 col-xl-6">
                                                    <mat-card class="">
                                                        <div class="appint_graph">
                                                            <div [hidden]="glucose_2year_no_data" class="graph_dataunavail">
                                                                <p class="Paraclass">
                                                                    <img src="../../../assets/img/Warning.png" class="Imageclass" />No data found
                                                                </p>
                                                            </div>
                                                            <div [hidden]="!glucose_2year_no_data" id="Blood_twoyear_Glucose_Chart" class="chart_div">
                                                            </div>
                                                            <div>
                                                                <p class="text-center cahrat_para">Blood glucose</p>
                                                            </div>
                                                        </div>
                                                    </mat-card>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-6 col-xl-6">
                                                    <mat-card class="">
                                                        <div class="appint_graph">
                                                            <div [hidden]="HbA1c_2year_no_data"
                                                                class="graph_dataunavail">
                                                                <p class="Paraclass">
                                                                    <img src="../../../assets/img/Warning.png" class="Imageclass" />No data found
                                                                </p>
                                                            </div>
                                                            <div id="HbA1c_2year" class="chart_div"
                                                                [hidden]="!HbA1c_2year_no_data"></div>
                                                            <div>
                                                                <p class="text-center cahrat_para">HbA1c</p>
                                                            </div>
                                                        </div>
                                                    </mat-card>
                                                </div>

                                            </div>
                                            <div class="row">
                                                <div class="col-12 col-md-6 col-lg-6 col-xl-6">
                                                    <mat-card class="">
                                                        <div class="appint_graph">
                                                            <div [hidden]="blood_glu_2year_notavail"
                                                                class="graph_dataunavail">
                                                                <p class="Paraclass">
                                                                    <img src="../../../assets/img/Warning.png"
                                                                        class="Imageclass" />No data found
                                                                </p>
                                                            </div>
                                                            <div id="Urine_2year_Charts" class="chart_div"
                                                                [hidden]="!blood_glu_2year_notavail"></div>
                                                            <div>
                                                                <p class="text-center cahrat_para">Urine glucose</p>
                                                            </div>
                                                        </div>
                                                    </mat-card>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-6 col-xl-6">
                                                    <mat-card class="">
                                                        <div class="appint_graph">
                                                            <div [hidden]="coles_2year_notavail"
                                                                class="graph_dataunavail">
                                                                <p class="Paraclass">
                                                                    <img src="../../../assets/img/Warning.png"
                                                                        class="Imageclass" />No data found
                                                                </p>
                                                            </div>
                                                            <div id="coles_2year_month" class="chart_div"
                                                                [hidden]="!coles_2year_notavail"></div>
                                                            <div>
                                                                <p class="text-center cahrat_para">Cholesterol</p>
                                                            </div>
                                                        </div>
                                                    </mat-card>
                                                </div>
                                            </div>
                                        </div>
                                    </mat-expansion-panel>
                                </mat-accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>