import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Http, Headers } from '@angular/http';
import { ipaddress } from '../../ipaddress';

@Component({
  selector: 'app-client-postcovid-track',
  templateUrl: './client-postcovid-track.component.html',
  styleUrls: ['./client-postcovid-track.component.css']
})
export class ClientPostcovidTrackComponent implements OnInit {

  public clientRegId: string;
  public relationId: string;
  public subRelId: string;
  public dailySympList = [];
  public observList = [];
  public sleepTimeList = [];
  public dietHistList = [];
  public medicineList = [];
  public physicalList = [];

  public symptomFlag: boolean;
  public sleepFlag: boolean;
  public dietFlag: boolean;
  public medicationFlag: boolean;
  public physicalFlag: boolean;
  public noData: boolean;

  constructor(public http:Http,public dialogRef: MatDialogRef<ClientPostcovidTrackComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
    dialogRef.disableClose = true;
    this.noData = true;
    this.clientRegId = data.client_reg_id;
    this.relationId = data.relation_id;
    if(data.sub_rel_id != undefined){
      this.subRelId = data.sub_rel_id;
    }
  }

  ngOnInit(): void {
    var send_data={
      client_reg_id: this.clientRegId,
      relation_id: this.relationId,
      sub_rel_id: this.subRelId,
    }
    
    var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp+"lifestyle/gctrack", send_data, { headers: headers }).subscribe(
        data => {
          var dataval = data.json();
          
          if(dataval != null && dataval.length !=0){
            this.noData = false;
            if(dataval.symptoms != undefined){
              for (var i = 0; i < dataval.symptoms.length; i++) {
                this.dailySympList.push({
                  date:dataval.symptoms[i].created_date,
                  symptom:dataval.symptoms[i].symptom,
                  severity:dataval.symptoms[i].severity,
                });
              }
            }

            if(dataval.covid != undefined){
              for (var i = 0; i < dataval.covid.length; i++) {
                this.observList.push({
                  date:dataval.covid[i].created_date,
                  temp:dataval.covid[i].temparature,
                  bp:dataval.covid[i].bp,
                  saturation:dataval.covid[i].spo2,
                  pulse:dataval.covid[i].pulse,
                  resp_rate:dataval.covid[i].resp_rate,
                });

                this.sleepTimeList.push({
                  date:dataval.covid[i].created_date,
                  bedtime:dataval.covid[i].sleep_time,
                  wakeup:dataval.covid[i].wake_time,
                  duration:dataval.covid[i].sleep_dur,
                  pattern:dataval.covid[i].sleep_type,
                });

                var exercise;
                if(dataval.covid[i].yoga =="yes"){
                  exercise =dataval.covid[i].exercise+" - "+dataval.covid[i].yogasana;
                } else {
                  exercise =dataval.covid[i].exercise;
                }

                this.physicalList.push({
                  date:dataval.covid[i].created_date,
                  exercise:exercise,
                  duration:dataval.covid[i].exer_dur,
                  frequency:dataval.covid[i].exer_freq,
                });
              }
            }

            if(dataval.diet != undefined){
              for (var i = 0; i < dataval.diet.length; i++) {
                this.dietHistList.push({
                    break_fast: dataval.diet[i].break_fast,
                    morning: dataval.diet[i].morning,
                    midday: dataval.diet[i].midday,
                    lunch: dataval.diet[i].lunch,
                    evening: dataval.diet[i].evening,
                    dinner: dataval.diet[i].dinner,
                    night: dataval.diet[i].night,
                    created_date: dataval.diet[i].created_date,
                });
              }
            }

            if(dataval.medicines != undefined){
              for (var i = 0; i < dataval.medicines.length; i++) {
                this.medicineList.push({
                  Date:dataval.medicines[i].created_date,
                  drug_name:dataval.medicines[i].product_name,
                  intake:dataval.medicines[i].intake,
                  quantity:dataval.medicines[i].quantity,
                });
              }
            }
          } else {
            this.noData = true;
          }
        },error => {});
  }

  close(){
    this.dialogRef.close();
  }
}
