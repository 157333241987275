import { ReturnStatement } from "@angular/compiler";

export class masterCSData_Helper{

    static mainConcern;
    static setMasterMainConcern(value: any) {
        this.mainConcern = null;
        this.mainConcern = value;
    }
    static getMasterMainConcern() {
        return this.mainConcern;
    }

    //setMasterMedicalHistory
    static MedicalHistory;
    static setMasterMedicalHistory(value: any) {
        this.MedicalHistory = null;
        this.MedicalHistory = value;
    }
    static getMasterMedicalHistory() {
        return this.MedicalHistory;
    }

    //setMasterReligiousOtherBeliefs
    static ReligiousOtherBeliefs;
    static setMasterReligiousOtherBeliefs(value: any) {
        this.ReligiousOtherBeliefs = null;
        this.ReligiousOtherBeliefs = value;
    }
    static getMasterReligiousOtherBeliefs() {
        return this.ReligiousOtherBeliefs;
    }

    //setMasterChiefComplients
    static ChiefComplients;
    static setMasterChiefComplients(value: any) {
        this.ChiefComplients = null;
        this.ChiefComplients = value;
    }
    static getMasterChiefComplients() {
        return this.ChiefComplients;
    }

    //setMasterBrushType
    static BrushType;
    static setMasterBrushType(value: any) {
        this.BrushType = null;
        this.BrushType = value;
    }
    static getMasterBrushType() {
        return this.BrushType;
    }

    //setMasterBrushMate
    static BrushMate;
    static setMasterBrushMate(value: any) {
        this.BrushMate = null;
        this.BrushMate = value;
    }
    static getMasterBrushMate() {
        return this.BrushMate;
    }

    //setMasterIntakeSession
    static IntakeSession;
    static setMasterIntakeSession(value: any) {
        this.IntakeSession = null;
        this.IntakeSession = value;
    }
    static getMasterIntakeSession() {
        return this.IntakeSession;
    }

    //setMasterExercise
    static Exercise;
    static setMasterExercise(value: any) {
        this.Exercise = null;
        this.Exercise = value;
    }
    static getMasterExercise() {
        return this.Exercise;
    }

    //setMasterExerDuration
    static ExerDuration;
    static setMasterExerDuration(value: any) {
        this.ExerDuration = null;
        this.ExerDuration = value;
    }
    static getMasterExerDuration() {
        return this.ExerDuration;
    }

    //setMasterExerRoutine
    static ExerRoutine;
    static setMasterExerRoutine(value: any) {
        this.ExerRoutine = null;
        this.ExerRoutine = value;
    }
    static getMasterExerRoutine() {
        return this.ExerRoutine;
    }

    //setMasterQunatity
    static Qunatity;
    static setMasterQunatity(value: any) {
        this.Qunatity = null;
        this.Qunatity = value;
    }
    static getMasterQunatity() {
        return this.Qunatity;
    }

    //setMasterMixingtype
    static Mixingtype;
    static setMasterMixingtype(value: any) {
        this.Mixingtype = null;
        this.Mixingtype = value;
    }
    static getMasterMixingtype() {
        return this.Mixingtype;
    }

    //setMasterSpecilizationType
    static SpecilizationType;
    static setMasterSpecilizationType(value: any) {
        this.SpecilizationType = null;
        this.SpecilizationType = value;
    }
    static getMasterSpecilizationType() {
        return this.SpecilizationType;
    }

    //setMasterYogaType
    static YogaType;
    static setMasterYogaType(value: any) {
        this.YogaType = null;
        this.YogaType = value;
    }
    static getMasterYogaType() {
        return this.YogaType;
    }

    //setMasterMobility
    static Mobility;
    static setMasterMobility(value: any) {
        this.Mobility = null;
        this.Mobility = value;
    }
    static getMasterMobility() {
        return this.Mobility;
    }

    //setMasterHearing
    static Hearing;
    static setMasterHearing(value: any) {
        this.Hearing = null;
        this.Hearing = value;
    }
    static getMasterHearing() {
        return this.Hearing;
    }

    //setMasterSkin
    static Skin;
    static setMasterSkin(value: any) {
        this.Skin = null;
        this.Skin = value;
    }
    static getMasterSkin() {
        return this.Skin;
    }

    //setMasterFoot
    static Foot;
    static setMasterFoot(value: any) {
        this.Foot = null;
        this.Foot = value;
    }
    static getMasterFoot() {
        return this.Foot;
    }

    //setMasterRetino
    static Retino;
    static setMasterRetino(value: any) {
        this.Retino = null;
        this.Retino = value;
    }
    static getMasterRetino() {
        return this.Retino;
    }

    //setMasterNephro
    static Nephro;
    static setMasterNephro(value: any) {
        this.Nephro = null;
        this.Nephro = value;
    }
    static getMasterNephro() {
        return this.Nephro;
    }

    //setMasterNeuro
    static Neuro;
    static setMasterNeuro(value: any) {
        this.Neuro = null;
        this.Neuro = value;
    }
    static getMasterNeuro() {
        return this.Neuro;
    }

    //setMasterCardiovasular
    static Cardiovasular;
    static setMasterCardiovasular(value: any) {
        this.Cardiovasular = null;
        this.Cardiovasular = value;
    }
    static getMasterCardiovasular() {
        return this.Cardiovasular;
    }

    //setMasterDiabTreatmentType
    static DiabTreatmentType;
    static setMasterDiabTreatmentType(value: any) {
        this.DiabTreatmentType = null;
        this.DiabTreatmentType = value;
    }
    static getMasterDiabTreatmentType() {
        return this.DiabTreatmentType;
    }

    //setMasterGynoDisease
    static GynoDisease;
    static setMasterGynoDisease(value: any) {
        this.GynoDisease = null;
        this.GynoDisease = value;
    }
    static getMasterGynoDisease() {
        return this.GynoDisease;
    }

    //setMasterFoodType
    static FoodType;
    static setMasterFoodType(value: any) {
        this.FoodType = null;
        this.FoodType = value;
    }
    static getMasterFoodType() {
        return this.FoodType;
    }

    //setMasterICDChapter
    static ICDChapter;
    static setMasterICDChapter(value: any) {
        this.ICDChapter = null;
        this.ICDChapter = value;
    }
    static getMasterICDChapter() {
        return this.ICDChapter;
    }

    //setMasterGestationstage
    static Gestationstage;
    static setMasterGestationstage(value: any) {
        this.Gestationstage = null;
        this.Gestationstage = value;
    }
    static getMasterGestationstage() {
        return this.Gestationstage;
    }

    //setMasterModeofdelivery
    static Modeofdelivery;
    static setMasterModeofdelivery(value: any) {
        this.Modeofdelivery = null;
        this.Modeofdelivery = value;
    }
    static getMasterModeofdelivery() {
        return this.Modeofdelivery;
    }

    //setMasterReasonCsection
    static ReasonCsection;
    static setMasterReasonCsection(value: any) {
        this.ReasonCsection = null;
        this.ReasonCsection = value;
    }
    static getMasterReasonCsection() {
        return this.ReasonCsection;
    }

    //setMasterAgeGroup
    static AgeGroup;
    static setMasterAgeGroup(value: any) {
        this.AgeGroup = null;
        this.AgeGroup = value;
    }
    static getMasterAgeGroup() {
        return this.AgeGroup;
    }

    //setMasterCognitiveDevelopment
    static CognitiveDevelopment;
    static setMasterCognitiveDevelopment(value: any) {
        this.CognitiveDevelopment = null;
        this.CognitiveDevelopment = value;
    }
    static getMasterCognitiveDevelopment() {
        return this.CognitiveDevelopment;
    }

    //setMasterLanguageDevelopment
    static LanguageDevelopment;
    static setMasterLanguageDevelopment(value: any) {
        this.LanguageDevelopment = null;
        this.LanguageDevelopment = value;
    }
    static getMasterLanguageDevelopment() {
        return this.LanguageDevelopment;
    }

    //setMasterImmunizationData
    static ImmunizationData;
    static setMasterImmunizationData(value: any) {
        this.ImmunizationData = null;
        this.ImmunizationData = value;
    }
    static getMasterImmunizationData() {
        return this.ImmunizationData;
    }

    //setMasterPregnancyRelatedCondition
    static PregnancyRelatedCondition;
    static setMasterPregnancyRelatedCondition(value: any) {
        this.PregnancyRelatedCondition = null;
        this.PregnancyRelatedCondition = value;
    }
    static getMasterPregnancyRelatedCondition() {
        return this.PregnancyRelatedCondition;
    }

    //setMasterPregnancyMedicalCondition
    static PregnancyMedicalCondition;
    static setMasterPregnancyMedicalCondition(value: any) {
        this.PregnancyMedicalCondition = null;
        this.PregnancyMedicalCondition = value;
    }
    static getMasterPregnancyMedicalCondition() {
        return this.PregnancyMedicalCondition;
    }

    //setMasterTypeContraceptive
    static TypeContraceptive;
    static setMasterTypeContraceptive(value: any) {
        this.TypeContraceptive = null;
        this.TypeContraceptive = value;
    }
    static getMasterTypeContraceptive() {
        return this.TypeContraceptive;
    }

    //setMasterChestpainRadiations
    static ChestpainRadiations;
    static setMasterChestpainRadiations(value: any) {
        this.ChestpainRadiations = null;
        this.ChestpainRadiations = value;
    }
    static getMasterChestpainRadiations() {
        return this.ChestpainRadiations;
    }

    //setMasterChestpainAgrelFactors
    static ChestpainAgrelFactors;
    static setMasterChestpainAgrelFactors(value: any) {
        this.ChestpainAgrelFactors = null;
        this.ChestpainAgrelFactors = value;
    }
    static getMasterChestpainAgrelFactors() {
        return this.ChestpainAgrelFactors;
    }

    //setMasterChestpainAssociatedSymptoms
    static ChestpainAssociatedSymptoms;
    static setMasterChestpainAssociatedSymptoms(value: any) {
        this.ChestpainAssociatedSymptoms = null;
        this.ChestpainAssociatedSymptoms = value;
    }
    static getMasterChestpainAssociatedSymptoms() {
        return this.ChestpainAssociatedSymptoms;
    }

    //setMasterChestpainCauses
    static ChestpainCauses;
    static setMasterChestpainCauses(value: any) {
        this.ChestpainCauses = null;
        this.ChestpainCauses = value;
    }
    static getMasterChestpainCauses() {
        return this.ChestpainCauses;
    }

    //setMasterBreathingTriggers
    static BreathingTriggers;
    static setMasterBreathingTriggers(value: any) {
        this.BreathingTriggers = null;
        this.BreathingTriggers = value;
    }
    static getMasterBreathingTriggers() {
        return this.BreathingTriggers;
    }

    //setMasterBreathingOnsets
    static BreathingOnsets;
    static setMasterBreathingOnsets(value: any) {
        this.BreathingOnsets = null;
        this.BreathingOnsets = value;
    }
    static getMasterBreathingOnsets() {
        return this.BreathingOnsets;
    }

    //setMasterPalpitationTypes
    static PalpitationTypes;
    static setMasterPalpitationTypes(value: any) {
        this.PalpitationTypes = null;
        this.PalpitationTypes = value;
    }
    static getMasterPalpitationTypes() {
        return this.PalpitationTypes;
    }

    //setMasterMucusTypes
    static MucusTypes;
    static setMasterMucusTypes(value: any) {
        this.MucusTypes = null;
        this.MucusTypes = value;
    }
    static getMasterMucusTypes() {
        return this.MucusTypes;
    }

    //setMasterChestpainLocationDetails
    static ChestpainLocationDetails;
    static setMasterChestpainLocationDetails(value: any) {
        this.ChestpainLocationDetails = null;
        this.ChestpainLocationDetails = value;
    }
    static getMasterChestpainLocationDetails() {
        return this.ChestpainLocationDetails;
    }

    //setMasterChestpainpainCharacters
    static ChestpainpainCharacters;
    static setMasterChestpainCharacters(value: any) {
        this.ChestpainpainCharacters = null;
        this.ChestpainpainCharacters = value;
    }
    static getMasterChestpainCharacters() {
        return this.ChestpainpainCharacters;
    }

    //setMasterSkinAbnormalities
    static SkinAbnormalities;
    static setMasterSkinAbnormalities(value: any) {
        this.SkinAbnormalities = null;
        this.SkinAbnormalities = value;
    }
    static getMasterSkinAbnormalities() {
        return this.SkinAbnormalities;
    }

    //setMasterGynoFoot
    static GynoFoot;
    static setMasterGynoFoot(value: any) {
        this.GynoFoot = null;
        this.GynoFoot = value;
    }
    static getMasterGynoFoot() {
        return this.GynoFoot;
    }

    //setMasterAnemia
    static Anemia;
    static setMasterAnemia(value: any) {
        this.Anemia = null;
        this.Anemia = value;
    }
    static getMasterAnemia() {
        return this.Anemia;
    }

    //setMasterFetuspresentation
    static Fetuspresentation;
    static setMasterFetuspresentation(value: any) {
        this.Fetuspresentation = null;
        this.Fetuspresentation = value;
    }
    static getMasterFetuspresentation() {
        return this.Fetuspresentation;
    }

    //setMasterTypeEmergency
    static TypeEmergency;
    static setMasterTypeEmergency(value: any) {
        this.TypeEmergency = null;
        this.TypeEmergency = value;
    }
    static getMasterTypeEmergency() {
        return this.TypeEmergency;
    }

    //setMasterComplicationDelivery
    static ComplicationDelivery;
    static setMasterComplicationDelivery(value: any) {
        this.ComplicationDelivery = null;
        this.ComplicationDelivery = value;
    }
    static getMasterComplicationDelivery() {
        return this.ComplicationDelivery;
    }

    //setMasterComplicationPostpartum
    static ComplicationPostpartum;
    static setMasterComplicationPostpartum(value: any) {
        this.ComplicationPostpartum = null;
        this.ComplicationPostpartum = value;
    }
    static getMasterComplicationPostpartum() {
        return this.ComplicationPostpartum;
    }

    //setMasterPediaConcerns
    static PediaConcerns;
    static setMasterPediaConcerns(value: any) {
        this.PediaConcerns = null;
        this.PediaConcerns = value;
    }
    static getMasterPediaConcerns() {
        return this.PediaConcerns;
    }

    //setMasterChildhabits
    static Childhabits;
    static setMasterChildhabits(value: any) {
        this.Childhabits = null;
        this.Childhabits = value;
    }
    static getMasterChildhabits() {
        return this.Childhabits;
    }

    //setMasterMaternalIllness
    static MaternalIllness;
    static setMasterMaternalIllness(value: any) {
        this.MaternalIllness = null;
        this.MaternalIllness = value;
    }
    static getMasterMaternalIllness() {
        return this.MaternalIllness;
    }

    //setMasterFoodMenu
    static FoodMenu;
    static setMasterFoodMenu(value: any) {
        this.FoodMenu = null;
        this.FoodMenu = value;
    }
    static getMasterFoodMenu() {
        return this.FoodMenu;
    }

    //setMasterNephroDisease
    static NephroDisease;
    static setMasterNephroDisease(value: any) {
        this.NephroDisease = null;
        this.NephroDisease = value;
    }
    static getMasterNephroDisease() {
        return this.NephroDisease;
    }

    //setMasterNephroTreatment
    static NephroTreatment;
    static setMasterNephroTreatment(value: any) {
        this.NephroTreatment = null;
        this.NephroTreatment = value;
    }
    static getMasterNephroTreatment() {
        return this.NephroTreatment;
    }

    //setMasterAbdomenType
    static AbdomenType;
    static setMasterAbdomenType(value: any) {
        this.AbdomenType = null;
        this.AbdomenType = value;
    }
    static getMasterAbdomenType() {
        return this.AbdomenType;
    }

    //setMasterRespiratoryType
    static RespiratoryType;
    static setMasterRespiratoryType(value: any) {
        this.RespiratoryType = null;
        this.RespiratoryType = value;
    }
    static getMasterRespiratoryType() {
        return this.RespiratoryType;
    }

    //setMasterCausesType
    static CausesType;
    static setMasterCausesType(value: any) {
        this.CausesType = null;
        this.CausesType = value;
    }
    static getMasterCausesType() {
        return this.CausesType;
    }

    //setMasterComplicationsType
    static ComplicationsType;
    static setMasterComplicationsType(value: any) {
        this.ComplicationsType = null;
        this.ComplicationsType = value;
    }
    static getMasterComplicationsType() {
        return this.ComplicationsType;
    }

    //setMasterDurationsymptom
    static Durationsymptom;
    static setMasterDurationSymptom(value: any) {
        this.Durationsymptom = null;
        this.Durationsymptom = value;
    }
    static getMasterDurationSymptom() {
        return this.Durationsymptom;
    }

    //setMasterGynoPresentIllness
    static GynoPresentIllness;
    static setMasterGynoPresentIllness(value: any) {
        this.GynoPresentIllness = null;
        this.GynoPresentIllness = value;
    }
    static getMasterGynoPresentIllness() {
        return this.GynoPresentIllness;
    }

    //setMasterGynoStage
    static GynoStage;
    static setMasterGynoStage(value: any) {
        this.GynoStage = null;
        this.GynoStage = value;
    }
    static getMasterGynoStage() {
        return this.GynoStage;
    }

    //setMasterCharacterofpain
    static Characterofpain;
    static setMasterCharacterofpain(value: any) {
        this.Characterofpain = null;
        this.Characterofpain = value;
    }
    static getMasterCharacterofpain() {
        return this.Characterofpain;
    }

    //setMasterFrequency
    static Frequency;
    static setMasterFrequency(value: any) {
        this.Frequency = null;
        this.Frequency = value;
    }
    static getMasterFrequency() {
        return this.Frequency;
    }

    //setMasterInfection
    static Infection;
    static setMasterInfection(value: any) {
        this.Infection = null;
        this.Infection = value;
    }
    static getMasterInfection() {
        return this.Infection;
    }

    //setMastergetReportTypes
    static ReportTypes;
    static setMastergetReportTypes(value: any) {
        this.ReportTypes = null;
        this.ReportTypes = value;
    }
    static getMastergetReportTypes() {
        return this.ReportTypes;
    }

    //setMastergetReliFactor
    static ReliFactor;
    static setMasterReliFactor(value: any) {
        this.ReliFactor = null;
        this.ReliFactor = value;
    }
    static getMasterReliFactor() {
        return this.ReliFactor;
    }

    //setMasterSplSpeciDisease
    static SplSpeciDisease;
    static setMasterSplSpeciDisease(value: any) {
        this.SplSpeciDisease = null;
        this.SplSpeciDisease = value;
    }
    static getMasterSplSpeciDisease() {
        return this.SplSpeciDisease;
    }

    //setMasterAreaSpecificDisease
    static AreaSpecificDisease;
    static setMasterAreaSpecificDisease(value: any) {
        this.AreaSpecificDisease = null;
        this.AreaSpecificDisease = value;
    }
    static getMasterAreaSpecificDisease() {
        return this.AreaSpecificDisease;
    }

    //setMasterAgeSpecificDisease
    static AgeSpecificDisease;
    static setMasterAgeSpecificDisease(value: any) {
        this.AgeSpecificDisease = null;
        this.AgeSpecificDisease = value;
    }
    static getMasterAgeSpecificDisease() {
        return this.AgeSpecificDisease;
    }

    //setMasterGenderSpecificDisease
    static GenderSpecificDisease;
    static setMasterGenderSpecificDisease(value: any) {
        this.GenderSpecificDisease = null;
        this.GenderSpecificDisease = value;
    }
    static getMasterGenderSpecificDisease() {
        return this.GenderSpecificDisease;
    }

    //setMastergetOccupationalhazards
    static Occupationalhazards;
    static setMastergetOccupationalhazards(value: any) {
        this.Occupationalhazards = null;
        this.Occupationalhazards = value;
    }
    static getMastergetOccupationalhazards() {
        return this.Occupationalhazards;
    }

    //setMastergetRatingType
    static RatingType;
    static setMastergetRatingType(value: any) {
        this.RatingType = null;
        this.RatingType = value;
    }
    static getMastergetRatingType() {
        return this.RatingType;
    }

    //setMasterUneruptedTeeth
    static UneruptedTeeth;
    static setMasterUneruptedTeeth(value: any) {
        this.UneruptedTeeth = null;
        this.UneruptedTeeth = value;
    }
    static getMasterUneruptedTeeth() {
        return this.UneruptedTeeth;
    }

    //setMasterGingivalColor
    static GingivalColor;
    static setMasterGingivalColor(value: any) {
        this.GingivalColor = null;
        this.GingivalColor = value;
    }
    static getMasterGingivalColor() {
        return this.GingivalColor;
    }

    //setMasterCircumOralMusTone
    static CircumOralMusTone;
    static setMasterCircumOralMusTone(value: any) {
        this.CircumOralMusTone = null;
        this.CircumOralMusTone = value;
    }
    static getMasterCircumOralMusTone() {
        return this.CircumOralMusTone;
    }

    //setMasterLipPosture
    static LipPosture;
    static setMasterLipPosture(value: any) {
        this.LipPosture = null;
        this.LipPosture = value;
    }
    static getMasterLipPosture() {
        return this.LipPosture;
    }

    //setMasterChinPosition
    static ChinPosition;
    static setMasterChinPosition(value: any) {
        this.ChinPosition = null;
        this.ChinPosition = value;
    }
    static getMasterChinPosition() {
        return this.ChinPosition;
    }

    //setMasterNasoLabelAngle
    static NasoLabelAngle;
    static setMasterNasoLabelAngle(value: any) {
        this.NasoLabelAngle = null;
        this.NasoLabelAngle = value;
    }
    static getMasterNasoLabelAngle() {
        return this.NasoLabelAngle;
    }

    //setMasterMentolabialSclcus
    static MentolabialSclcus;
    static setMasterMentolabialSclcus(value: any) {
        this.MentolabialSclcus = null;
        this.MentolabialSclcus = value;
    }
    static getMasterMentolabialSclcus() {
        return this.MentolabialSclcus;
    }

    //setMasterFacialProfile
    static FacialProfile;
    static setMasterFacialProfile(value: any) {
        this.FacialProfile = null;
        this.FacialProfile = value;
    }
    static getMasterFacialProfile() {
        return this.FacialProfile;
    }

    //setMasterShapeofHead
    static ShapeofHead;
    static setMasterShapeofHead(value: any) {
        this.ShapeofHead = null;
        this.ShapeofHead = value;
    }
    static getMasterShapeofHead() {
        return this.ShapeofHead;
    }

    //setMasterFacialForm
    static FacialForm;
    static setMasterFacialForm(value: any) {
        this.FacialForm = null;
        this.FacialForm = value;
    }
    static getMasterFacialForm() {
        return this.FacialForm;
    }

    //setMasterFacialDivergence
    static FacialDivergence;
    static setMasterFacialDivergence(value: any) {
        this.FacialDivergence = null;
        this.FacialDivergence = value;
    }
    static getMasterFacialDivergence() {
        return this.FacialDivergence;
    }

    //setMasterFacialSymmetry
    static FacialSymmetry;
    static setMasterFacialSymmetry(value: any) {
        this.FacialSymmetry = null;
        this.FacialSymmetry = value;
    }
    static getMasterFacialSymmetry() {
        return this.FacialSymmetry;
    }

    //setMasterLip
    static Lip;
    static setMasterLip(value: any) {
        this.Lip = null;
        this.Lip = value;
    }
    static getMasterLip() {
        return this.Lip;
    }

    //setMasterMolarRight
    static MolarRight;
    static setMasterMolarRight(value: any) {
        this.MolarRight = null;
        this.MolarRight = value;
    }
    static getMasterMolarRight() {
        return this.MolarRight;
    }

    //setMasterTypeofHabit
    static TypeofHabit;
    static setMasterTypeofHabit(value: any) {
        this.TypeofHabit = null;
        this.TypeofHabit = value;
    }
    static getMasterTypeofHabit() {
        return this.TypeofHabit;
    }

    //setMasterChildhoodDisease
    static ChildhoodDisease;
    static setMasterChildhoodDisease(value: any) {
        this.ChildhoodDisease = null;
        this.ChildhoodDisease = value;
    }
    static getMasterChildhoodDisease() {
        return this.ChildhoodDisease;
    }

    //setMasterUpperType
    static UpperType;
    static setMasterUpperType(value: any) {
        this.UpperType = null;
        this.UpperType = value;
    }
    static getMasterUpperType() {
        return this.UpperType;
    }

    //setMasterExtraOralTmj
    static ExtraOralTmj;
    static setMasterExtraOralTmj(value: any) {
        this.ExtraOralTmj = null;
        this.ExtraOralTmj = value;
    }
    static getMasterExtraOralTmj() {
        return this.ExtraOralTmj;
    }

    //setMasterExtraOralCondition
    static ExtraOralCondition;
    static setMasterExtraOralCondition(value: any) {
        this.ExtraOralCondition = null;
        this.ExtraOralCondition = value;
    }
    static getMasterExtraOralCondition() {
        return this.ExtraOralCondition;
    }

    //setMasterShapeofTeeth
    static ShapeofTeeth;
    static setMasterShapeofTeeth(value: any) {
        this.ShapeofTeeth = null;
        this.ShapeofTeeth = value;
    }
    static getMasterShapeofTeeth() {
        return this.ShapeofTeeth;
    }

    //setMasterColorofTeeth
    static ColorofTeeth;
    static setMasterColorofTeeth(value: any) {
        this.ColorofTeeth = null;
        this.ColorofTeeth = value;
    }
    static getMasterColorofTeeth() {
        return this.ColorofTeeth;
    }

    //setMasterConsistencyofTeeth
    static ConsistencyofTeeth;
    static setMasterConsistencyofTeeth(value: any) {
        this.ConsistencyofTeeth = null;
        this.ConsistencyofTeeth = value;
    }
    static getMasterConsistencyofTeeth() {
        return this.ConsistencyofTeeth;
    }

    //setMasterConditionofTeeth
    static ConditionofTeeth;
    static setMasterConditionofTeeth(value: any) {
        this.ConditionofTeeth = null;
        this.ConditionofTeeth = value;
    }
    static getMasterConditionofTeeth() {
        return this.ConditionofTeeth;
    }

    //setMasterMedicationDeptReferral
    static MedicationDeptReferral;
    static setMasterMedicationDeptReferral(value: any) {
        this.MedicationDeptReferral = null;
        this.MedicationDeptReferral = value;
    }
    static getMasterMedicationDeptReferral() {
        return this.MedicationDeptReferral;
    }

    //setMasterMaterialData
    static MaterialData;
    static setMasterMaterialData(value: any) {
        this.MaterialData = null;
        this.MaterialData = value;
    }
    static getMasterMaterialData() {
        return this.MaterialData;
    }
}

