<div class="row" style="width:100%;margin:0 auto;">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white ">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Search by doctor</h5>
                    </div>
                    <div class="headerButtons">
                        <img *ngIf="saveFlag" (click)="save()"
                            src="../../../assets/ui_icons/buttons/save_button.svg"
                            class="saveimgbtn_inpatinfo">
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>
                <div class="container">
                    <div class="row">
                        <div class="col-6">
                            <div class="cover_div" style="padding-bottom: 13px;">
                                <div class="header_lable">
                                    Search by doctor
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                            <mat-label class="matlabel">Doctor
                                                <select class="ipcss widthappt" [(ngModel)]="doctorName" (change)="changeDoctor()" >
                                                    <option *ngFor="let doctors of doctorList" value={{doctors.Doc_Name}}>{{doctors.Doc_Name}}
                                                    </option>
                                                </select>
                                            </mat-label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="cover_div">
                                <div class="header_lable">
                                    Nurse / Physio List
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-12" *ngIf="physioNurseList.length != 0" style="max-height: 405px;">
                                                        <div>
                                                            <div class="table-responsive" style="max-height: 400px;">
                                                                <table class="table table-hover table-dynamic">
                                                                    <tr *ngFor="let nurseList of physioNurseList">
                                                                        <td><img src="{{nurseList.profile_image}}"
                                                                                onerror="this.src='../../../assets/img/default.jpg';"
                                                                                height="40px" width="40px"
                                                                                class="img-fluid z-depth-1 rounded-circle mr-1">
                                                                        </td>
                                                                        <td class="align_left">{{nurseList.nurse_name}}&nbsp;</td>
                                                                        <td>
                                                                            <mat-checkbox color="primary"
                                                                                [checked]="nurseList.checked"
                                                                                (change)="selectNursePhysio($event.checked,nurseList.nurse_reg_id)"
                                                                                style="padding: 3px 0px "></mat-checkbox>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>