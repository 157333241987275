<div class="coverDiv">
    <div class="row minView">
        <div class="col-12 container-fluid">
            <div class="row">
                <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                    <!-- Card -->
                    <mdb-card class="mb-4 view card_border" mdbwaveseffect style="height: 290px;"
                        (click)="openCard('personal')">
                        <mdb-card-header class="text-center primary-color white-text">
                            <div>
                                <img class="img-fluid" src="../../../assets/images/img_new/whiteicon/personal.svg" class="details_card" alt="">
                            </div>
                            <h5 class="m-0" style="font-size: 14px; text-align: left;padding: 2px 0px 0px 32px;">
                                <span>Personal Details</span>
                            </h5>
                        </mdb-card-header>
                        <mdb-card-body>
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                                    <mat-label class="matlabel">First name<br>
                                        <input type="text" class="ipcss inpat_width" />
                                    </mat-label>
                                </div>
                                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 ">
                                    <mat-label class="matlabel">Age<br>
                                        <input type="text" class="ipcss inpat_width" />
                                    </mat-label>
                                </div>
                                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 ">
                                    <mat-label class="matlabel">Gender<br>
                                        <select class="ipcss inpat_width">
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                            <option value="Transgender">Transgender</option>
                                        </select>
                                    </mat-label>
                                </div>
                                <div class="col-12 col-sm-6 col-md-12 col-lg-12 col-xl-12 ">
                                    <mat-label class="matlabel">Mobile No<br>
                                        <input type="text" class="ipcss walkin_mobilewidth"
                                            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                            maxlength="10" matInput style="width:100%;" />
                                    </mat-label>
                                </div>
                            </div>
                        </mdb-card-body>
                    </mdb-card>
                </div>

                <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                    <!-- Card -->
                    <mdb-card class="mb-4 view card_border" mdbwaveseffect style="height: 290px;">
                        <mdb-card-header class="text-center primary-color white-text">
                            <div>
                                <img class="img-fluid" src="../../../assets/images/img_new/whiteicon/bmiandvitals.svg"
                                    class="details_card" alt="">
                            </div>
                            <h5 class="m-0" style="font-size: 14px; text-align: left;padding: 2px 0px 0px 32px;">
                                <span>Vitals & COVID</span>
                            </h5>
                        </mdb-card-header>

                        <mdb-card-body>
                        </mdb-card-body>
                    </mdb-card>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                    <!-- Card -->
                    <mdb-card class="mb-4 view card_border" mdbwaveseffect style="height: 290px;">
                        <mdb-card-header class="text-center primary-color white-text">
                            <div>
                                <img class="img-fluid" src="../../../assets/images/img_new/whiteicon/personal.svg"
                                    class="details_card" alt="">
                            </div>
                            <h5 class="m-0" style="font-size: 14px; text-align: left;padding: 2px 0px 0px 32px;">
                                <span>Other observation</span>
                            </h5>
                        </mdb-card-header>
                        <mdb-card-body>

                        </mdb-card-body>
                    </mdb-card>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                    <!-- Card -->
                    <mdb-card class="mb-4 view card_border" mdbwaveseffect style="height: 290px;">
                        <mdb-card-header class="text-center primary-color white-text">
                            <div>
                                <img class="img-fluid" src="../../../assets/images/img_new/whiteicon/personal.svg"
                                    class="details_card" alt="">
                            </div>
                            <h5 class="m-0" style="font-size: 14px; text-align: left;padding: 2px 0px 0px 32px;">
                                <span>Doctor visiting</span>
                            </h5>
                        </mdb-card-header>
                        <mdb-card-body>

                        </mdb-card-body>
                    </mdb-card>
                </div>
            </div>
        </div>
    </div>
</div>