<div class="row" style="margin:0 auto; width:100%">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Donation history</h5>
          </div>
          <div class="headerButtons">
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row">
          <div class="col-12 col-lg-12 col-md-12 col-xl-12">
           <p class="nodata" *ngIf="donateHistoryData.length == 0">No history(s) Found</p> 
           <div class="table-responsive dig_table_overflow" *ngIf="donateHistoryData.length != 0">
            <table  id="card_tbl"
            class="table table-hover table-dynamic" cellpadding="5" cellspacing="0">
               <thead class="tableheading"> 
                 <tr>
                   <th>Hospital</th>
                   <th>Date</th>
                   <th>Units</th>
                   <th>Accept</th>
                 </tr>
               </thead>
               <tbody>
                 <tr *ngFor="let list of donateHistoryData" class="tdvalues" >
                   <td  style="font-size: 12px;text-align: left;">{{list.hospital}}</td>
                   <td  style="font-size: 12px;"> {{list.date | date:'dd/MM/yyyy'}}</td>
                   <td  style="font-size: 12px;">{{list.units}}</td>
                   <td  style="font-size: 12px;"> {{list.accept}}</td>
                 </tr>
               </tbody>
             </table>
           </div>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>