<div mat-dialog-title class="popup_border_icd">
  <h5 class="header_txt">Filter</h5>
  <div mat-dialog-actions>
    <img src="../../../assets/img/close_icon.png" (click)="ClosePopup()" width="20px" height="20px" id="close_icd" />
  </div>
</div>
<div mat-dialog-content class="filter_content">
  <div class="container">
    <div class="row">
      <div class="col-12 col-sm-6 col-md-4 col-lg-12 col-xl-12">
        <mat-accordion class="mataccordion">
          <mat-expansion-panel class="expandpanel" [expanded]="true">
            <mat-expansion-panel-header class="exppanel width">
              <mat-panel-title class="title">
                Search by
              </mat-panel-title>
            </mat-expansion-panel-header>
            <hr class="hr">
            <div class="row">
              <div class="col-12">
                <mat-label class="matlabel">Country<br>
                  <mat-select disableOptionCentering class="ipcss widthappt" [(ngModel)]="searchClntCountry"
                    (ngModelChange)="countryChange($event,'1')">
                    <mat-option disabled>Select</mat-option>
                    <mat-option *ngFor="let country of clntCountryList" value="{{country.description}}">
                      {{country.description}}</mat-option>
                  </mat-select>
                </mat-label>
              </div>
              <div class="col-12">
                <mat-label class="matlabel">State<br>
                  <mat-select disableOptionCentering class="ipcss widthappt" required [(ngModel)]="searchClntState"
                    (ngModelChange)="stateChange($event,'1')">
                    <mat-option disabled>Select</mat-option>
                    <mat-option *ngFor="let state of clntStateList" value="{{state.description}}">
                      {{state.description}}
                    </mat-option>
                  </mat-select>
                </mat-label>
              </div>
              <div class="col-12">
                <mat-label class="matlabel">City<br>
                  <mat-select disableOptionCentering class="ipcss widthappt" required [(ngModel)]="searchClntCity"
                    (ngModelChange)="cityChange($event, '1')">
                    <mat-option disabled>Select</mat-option>
                    <mat-option *ngFor="let city of clntCityList" value="{{city.description}}">{{city.description}}
                    </mat-option>
                  </mat-select>
                </mat-label>
              </div>
              <div class="col-12">
                <mat-label class="matlabel">Location<br>
                  <input type="text" class="ipcss widthappt" required maxlength="50" (keyup)="locationChange($event)"
                    [(ngModel)]="searchClntLocation" matInput [matAutocomplete]="auto" />
                  <mat-autocomplete #auto="matAutocomplete">
                    <mat-option (click)="selectedLocation(location)" *ngFor="let location of clntLocationList"
                      value="{{location.description}}">
                      {{location.description}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-label>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div class="col-12 col-sm-6 col-md-4 col-lg-12 col-xl-12">
        <mat-accordion class="mataccordion">
          <mat-expansion-panel class="expandpanel ">
            <mat-expansion-panel-header class="exppanel width">
              <mat-panel-title class="title">
                Filter by
              </mat-panel-title>
            </mat-expansion-panel-header>
            <hr class="hr">
            <div class="row">
              <div class="col-12">
                <!-- gened -->
                <mat-label class="matlabel">Gender<br>
                  <mat-select required class="ipcss widthappt" [(ngModel)]="filtGender" disableOptionCentering>
                    <mat-option value="Male">Male</mat-option>
                    <mat-option value="Female">Female</mat-option>
                    <mat-option value="Transgender">Transgender</mat-option>
                  </mat-select>
                </mat-label>
              </div>
              <div class="col-12">
                <!-- avialability -->
                <mat-label class="matlabel">Availability<br>
                  <mat-select required class="ipcss widthappt" [(ngModel)]="filtAvailableDay" disableOptionCentering>
                    <mat-option value="1">Sun</mat-option>
                    <mat-option value="2">Mon</mat-option>
                    <mat-option value="3">Tue</mat-option>
                    <mat-option value="4">Wed</mat-option>
                    <mat-option value="5">Thu</mat-option>
                    <mat-option value="6">Fri</mat-option>
                    <mat-option value="7">Sat</mat-option>
                  </mat-select>
                </mat-label>
              </div>
              <div class="col-12">
                <!-- session -->
                <mat-label class="matlabel">Session<br>
                  <mat-select required class="ipcss widthappt" [(ngModel)]="filtSession" disableOptionCentering>
                    <mat-option value="1">Morning</mat-option>
                    <mat-option value="2">Afternoon</mat-option>
                    <mat-option value="3">Evening</mat-option>
                  </mat-select>
                </mat-label>
              </div>
              <div class="col-12">
                <mat-label class="matlabel">Price
                  <mat-select class="ipcss widthappt" [(ngModel)]="filtFee">
                    <mat-option value="Low to high">Low to high</mat-option>
                    <mat-option value="High to low">High to low</mat-option>
                  </mat-select>
                </mat-label>
                <ngx-slider [(value)]="minValue" [(highValue)]="maxValue" [options]="options"></ngx-slider>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions align="end" style="width: auto;right: 19px;">
  <button (click)="doctorFilter()" id="add_btn" [mat-dialog-close]="" cdkFocusInitial mat-button>
    <img src="../../../assets/ui_icons/buttons/Filter_button.svg" class="addimgbtn_icd" />
  </button>
</div>