import { ReturnStatement } from "@angular/compiler";

export class Physio_Helper {
    //setModuleList
    static physioModuleList;
    static setModuleList(value:any){
        this.physioModuleList=null;
        this.physioModuleList=value;
    }
    static getModuleList(){
        return this.physioModuleList;
    }

    //setHomecare
    static Homecare;
    static setHomecare(value:any){
        this.Homecare=null;
        this.Homecare=value;
    }
    static getHomecare(){
        return this.Homecare;
    }

    // diagsave
    static diagsave;
    static setDiagSave(value:any){
        this.diagsave=null;
        this.diagsave=value;
    }
    static getDiagSave(){
        return this.diagsave;
    }

    // medsave
    static medsave;
    static setMedDiag(value:any){
        this.medsave=null;
        this.medsave=value;
    }
    static getMedDiag(){
        return this.medsave;
    }
    // /setAppDate
    static AppDate;
    static setAppDate(value:any){
        this.AppDate=null;
        this.AppDate=value;
    }
    static getAppDate(){
        return this.AppDate;
    }

    //setHopitalfieldsRet
    static HopitalfieldsRet;
    static setHopitalfieldsRet(value:any){
        this.HopitalfieldsRet=null;
        this.HopitalfieldsRet=value;
    }
    static getHopitalfieldsRet(){
        return this.HopitalfieldsRet;
    }

    //setClient_Info
    static Client_Info;
    static setClient_Info(value:any){
        this.Client_Info=null;
        this.Client_Info=value;
    }
    static getClient_Info(){
        return this.Client_Info;
    }

    //setRet
    static Ret;
    static setRet(value:any){
        this.Ret=null;
        this.Ret=value;
    }
    static getRet(){
        return this.Ret;
    }

    //setDiabHist
    static DiabHist;
    static setDiabHist(value:any){
        this.DiabHist=null;
        this.DiabHist=value;
    }
    static getDiabHist(){
        return this.DiabHist;
    }

    //setTherapies
    static Therapies;
    static setTherapies(value:any){
        this.Therapies=null;
        this.Therapies=value;
    }
    static getTherapies(){
        return this.Therapies;
    }

    //setAsg
    static Asg;
    static setAsg(value:any){
        this.Asg=null;
        this.Asg=value;
    }
    static getAsg(){
        return this.Asg;
    }

    //setPhysioClinical
    static Clinical;
    static setPhysioClinical(value:any){
        this.Clinical = null;
        this.Clinical = value;
    }
    static getPhysioClinical(){
        return this.Clinical;
    }

    //setPhysioNeuro
    static PhysioNeuro;
    static setPhysioNeuro(value:any){
        this.PhysioNeuro = null;
        this.PhysioNeuro = value;
    }
    static getPhysioNeuro(){
        return this.PhysioNeuro;
    }

    //setDiabTreat
    static DiabTreat;
    static setDiabTreat(value:any){
        this.DiabTreat = null;
        this.DiabTreat = value;
    }
    static getDiabTreat(){
        return this.DiabTreat;
    }

    //setDiet
    static Diet;
    static setDiet(value:any){
        this.Diet = null;
        this.Diet = value;
    }
    static getDiet(){
        return this.Diet;
    }
    //setpatient_information
    static patient_information;
    static setpatient_information(value:any){
        this.patient_information = null;
        this.patient_information = value;
    }
    static getpatient_information(){
        return this.patient_information;
    }

    //setSga
    static Sga;
    static setSga(value:any){
        this.Sga = null;
        this.Sga = value;
    }
    static getSga(){
        return this.Sga;
    }
    //setHabitual
    static Habitual;
    static setHabitual(value:any){
        this.Habitual = null;
        this.Habitual = value;
    }
    static getHabitual(){
        return this.Habitual;
    }

    // assign user type physio
    static userType;
    static setUserTypeId(value:any){
        this.userType=null;
        this.userType=value;
    }
    static getUserTypeId(){
        return this.userType;
    }
     
 
     // assign list to detail view data passing
     static assignmentView;
     static setAssignDetailsView(value:any){
         this.assignmentView=null;
         this.assignmentView=value;
     }
     static getAssignDetailsView(){
         return this.assignmentView;
     }

    // user details for user edit and view page
    static userDetails;
    static setUserDetails(value:any){
        this.userDetails=null;
        this.userDetails=value;
    }
    static getUserDetails(){
        return this.userDetails;
    }

    //Client_id
    static client_id;
    static setClient_id(value: any) {
        this.client_id = null;
        this.client_id = value;
    }
    static getClient_id() {
        return this.client_id;
    }
    //Rel_id
    static rel_id;
    static setRel_id(value: any) {
        this.rel_id = null;
        this.rel_id = value;
    }
    static getRel_id() {
        return this.rel_id;
    }

    //Sub_id
    static sub_id;
    static setSub_id(value: any) {
        this.sub_id = null;
        this.sub_id = value;
    }
    static getSub_id() {
        return this.sub_id;
    }
    //Client_Age
    static client_gender;
    static setClient_gender(value: any) {
        this.client_gender = null;
        this.client_gender = value;
    }
    static getClient_gender() {
        return this.client_gender;
    }
    //refer_by
    static refer_by;
    static setRefer_by(value: any) {
        this.refer_by = null;
        this.refer_by = value;
    }
    static getRefer_by() {
        return this.refer_by;
    }
    //Client_Age
    static client_age;
    static setClient_age(value: any) {
        this.client_age = null;
        this.client_age = value;
    }
    static getClient_age() {
        return this.client_age;
    }
      //Client_Name
      static client_info;
      static setClient_name(value: any) {
          this.client_info = null;
          this.client_info = value;
      }
      static getClient_name() {
          return this.client_info;
      }
      //App_id
    static app_id;
    static setApp_id(value: any) {
        this.app_id = null;
        this.app_id = value;
    }
    static getApp_id() {
        return this.app_id;
    }
    //Walk-in-pass
    static walkin;
    static setWalkin(value: any) {
        this.walkin = null;
        this.walkin = value;
    }
    static getWalkin() {
        return this.walkin;
    }
    //Hospital_Logo
    static hospital_logo;
    static setHospital_logo(value: any) {
        this.hospital_logo = null;
        this.hospital_logo = value;
    }
    static getHospital_logo() {
        return this.hospital_logo;
    }

    //Hospital_Name
    static hospital_name;
    static setHospital_name(value: any) {
        this.hospital_name = null;
        this.hospital_name = value;
    }
    static getHospital_name() {
        return this.hospital_name;
    }

    //Hospital_Pres_Logo
    static hospital_pres_logo;
    static setHospital_pres_logo(value: any) {
        this.hospital_pres_logo = null;
        this.hospital_pres_logo = value;
    }
    static getHospital_pres_logo() {
        return this.hospital_pres_logo;
    }

    //Hospital_bg_image
    static hospital_bg_image;
    static setHospital_bg_image(value: any) {
        this.hospital_bg_image = null;
        this.hospital_bg_image = value;
    }
    static getHospital_bg_image() {
        return this.hospital_bg_image;
    }

    //Hospital_print_template
    static hospital_print_template;
    static setHospital_print_template(value: any) {
        this.hospital_print_template = null;
        this.hospital_print_template = value;
    }
    static getHospital_print_template() {
        return this.hospital_print_template;
    }
  //Hospital clinical id 
  static clinicalId;
  static setHospitalClinicalId(value: any) {
      this.clinicalId = null;
      this.clinicalId = value;
  }
  static getHospitalClinicalId() {
      return this.clinicalId;
  }
    //Hospital_location
    static hospital_location;
    static setHospital_location(value: any) {
        this.hospital_location = null;
        this.hospital_location = value;
    }
    static getHospital_location() {
        return this.hospital_location;
    }

    //Hospital_address
    static hospital_address;
    static setHospital_address(value: any) {
        this.hospital_address = null;
        this.hospital_address = value;
    }
    static getHospital_address() {
        return this.hospital_address;
    }

    //Hospital_city_zip
    static hospital_city_zip;
    static setHospital_city_zip(value: any) {
        this.hospital_city_zip = null;
        this.hospital_city_zip = value;
    }
    static getHospital_city_zip() {
        return this.hospital_city_zip;
    }

    //Hospital_state_country
    static hospital_state_country;
    static setHospital_state_country(value: any) {
        this.hospital_state_country = null;
        this.hospital_state_country = value;
    }
    static getHospital_state_country() {
        return this.hospital_state_country;
    }
    static viewbills;
    static setviewbills(value) {
        this.viewbills = null;
        this.viewbills = value;
    }
    static getviewbills() {
        return this.viewbills
    }

    static billId;
    static setBillId(value) {
        this.billId = null;
        this.billId = value;
    }
    static getBillId() {
        return this.billId
    }
    
    //setMedicalHeading
    static MedicalHeading;
    static getMedicalHeading() {
        return this.MedicalHeading;
    }
    static setMedicalHeading(value) {
        this.MedicalHeading = null;
        this.MedicalHeading = value;
    }

    //setDiagAppflow
    static DiagAppflow;
    static getDiagAppflow() {
        return this.DiagAppflow;
    }
    static setDiagAppflow(value) {
        this.DiagAppflow = null;
        this.DiagAppflow = value;
    }
}