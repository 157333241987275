import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { Message_data } from '../../../assets/js/Message_data';
import { ipaddress } from '../../ipaddress';
import { Helper_Class } from '../../helper_class';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Date_Formate } from 'src/assets/js/common';
import { Client_Helper } from '../Client_helper';
import { ClientViewService } from '../clientview/client-view.service';

@Component({
  selector: 'app-client-dashboard',
  templateUrl: './client-dashboard.component.html',
  styleUrls: ['./client-dashboard.component.scss']
})
export class ClientDashboardComponent implements OnInit {
  @ViewChild('barCanvas') barCanvas;

  public userInfo;
  public fasting;
  public calIntake;
  public postprandial;
  public hba1c;
  public medUrl;
  public medicineHistList=[];
  public clntName
  public lifeStyleId;
  public hbalc;
  public heartRate;
  public BP01;
  public BP02;

  public dinnerPercentage:any = 0;
  public morningPercentage:any = 0;
  public lunchPercentage:any = 0;

  public planMor:any = 0;
  public planLunch:any = 0;
  public planNight:any = 0;
  public planMidday:any = 0;
  public planEvening:any = 0;
  public planDinner:any = 0;
  public trackMor:any;
  public trackMidday:any;
  public trackLunch:any;
  public trackEvening:any;
  public trackDinner:any;
  public trackNight:any;

  public currentDate;
  public mindfulList = [];
  public actualDuration;
  public planDuration;
  public kcalburn;
  public kcalPlan;
  public finalKcalBurned = 0;
  public finalActualDuration;
  public finalPlanDuration;
  public timeDuration:any = 0;
  public session;
  public styMorningColor;
  public value;


  public heartFlag: boolean = true;
  public bpFlag: boolean = true;
  public bloodFlag: boolean = true;
  public stepFlag: boolean = true;
  public medicineFlag: boolean = true;
  public activityFlag: boolean = true;
  public sleepFlag: boolean = true;
  public dietFlag: boolean = true;
  public modules;
  public mindfulFlag: boolean = true;
  constructor(public gservice:CommonDataService,public http:HttpClient,public routes:ActivatedRoute,public router:Router,public toastr:ToastrService
    ,public clientservice:ClientViewService) { 
    this.medUrl = ipaddress.getIp + "lifestyle/pmeds";
    Client_Helper.setViewActivity(undefined);
  }

  ngOnInit(): void {
    this.lifeStyleId = this.gservice.Client_login_detail_data.life_style_id;
    if (this.gservice.Client_login_detail_data != undefined) {
      var senddata = {
        life_style_id: this.gservice.Client_login_detail_data.life_style_id,
        interval: "750",
        country: ipaddress.country_code,
      }
      this.loadGraph(senddata);
    }
    this.userInfo = Helper_Class.getInfo();
    this.getTracker();
    this.clntName = this.gservice.Client_login_detail_data.Full_Name;
    this.BP01 = this.userInfo.bp_dia;
    this.BP02 = this.userInfo.bp_sys;
    this.heartRate = this.userInfo.heart_rate;
    this.fasting = this.userInfo.fasting_halfhour;
    this.postprandial = this.userInfo.two_hours;
    this.hba1c = this.userInfo.HbA1c;
    var data = this.userInfo.cal_intake /this.userInfo.cal_required;
    this.calIntake = parseInt(((this.userInfo.cal_intake /this.userInfo.cal_required) *(100)).toString());
    this.visitChange("Morning");
    this.getCurrentTime();
    this.getMindFullData('calm');    
    this.modules = Helper_Class.getModules();
    for (var i = 0; i < this.modules.length; i++) {
      if (this.modules[i] == "90") { //Heart rate
        this.heartFlag = false;
      }else if( this.modules[i] == "91" ){
        this.bpFlag = false;
      }else if( this.modules[i] == "94" ){
        this.bloodFlag = false;
      }else if( this.modules[i] == "95" ){
        this.stepFlag = false;
      }else if( this.modules[i] == "80" ){
        this.medicineFlag = false;
      }else if( this.modules[i] == "78" ){
        this.activityFlag = false;
      }else if( this.modules[i] == "96" ){
        this.sleepFlag = false;
      }else if( this.modules[i] == "79" ){
        this.dietFlag = false;
      }else if( this.modules[i] == "81" ){
        this.mindfulFlag = false;
      }
    }
  }

  getTracker(){
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "lifestyle/gadpa", { life_style_id: this.lifeStyleId }, { headers: headers }).subscribe(
      data => {
        var obj = JSON.parse(JSON.stringify(data));
        this.kcalburn = obj.kcal_burned;
        this.kcalPlan = obj.kcal_plan;
        this.actualDuration = obj.act_duration;
        this.planDuration = obj.plan_duration;
        this.dietPlanTracker(obj);

        if(this.actualDuration !="" && this.actualDuration != null){
          var actualDurationTime = this.actualDuration.split(":");
          var getdata = parseFloat(actualDurationTime[0]);
          var getdata1 = (actualDurationTime[1]);
          var getdata2 = (actualDurationTime[2]);
          var time_hr = (getdata)*(60);
          var time_min = (getdata2/60);
          this.finalActualDuration = parseFloat(time_hr.toString())+parseFloat(time_min.toString())+parseFloat(getdata1.toString());    
        }

        if( this.planDuration !="" && this.planDuration !=undefined && this.finalActualDuration != null && this.finalActualDuration != undefined ){
          var planDurationTime = this.planDuration.split(":");
          var getdataplan = (planDurationTime[0]);
          var getdata_panvalue1 = (planDurationTime[1]);
          var getdata_panvalue2 = (planDurationTime[2]);
          var time_hr = (getdataplan)*(60);
          var time_min = (getdata_panvalue2/60);
          this.finalPlanDuration= (parseFloat(time_hr.toString())+parseFloat(getdata_panvalue1.toString())+parseFloat(time_min.toString()));    
          var final = (this.finalActualDuration/(this.finalPlanDuration)) * 100;
          this.timeDuration = final.toFixed(2);  
        }else{
          this.timeDuration = 0; 
        }

        var timeprogressBar = $(".js-progress-bartimedur");
        timeprogressBar.css("stroke-dashoffset",((100 - (100 * this.timeDuration) /100)));

        if(this.timeDuration <= 100)
          timeprogressBar.css('stroke-dasharray',"100 100");
        
        if(this.kcalburn != ""&& this.kcalburn != undefined &&  this.kcalPlan !=undefined && this.kcalPlan != "")   
          this.finalKcalBurned = Math.round(((this.kcalburn)/(this.kcalPlan))*100);
        else
          this.finalKcalBurned = 0; 
         
        var cal_burnprogressBar = $(".js-progress-barcalburn");
        cal_burnprogressBar.css("stroke-dashoffset",((100 - (100 * this.finalKcalBurned) /100)));
        if(this.finalKcalBurned <= 100)
          cal_burnprogressBar.css('stroke-dasharray',"100 100");
        console.log("kcla value"+this.timeDuration+this.finalKcalBurned)
      });

  }

  format(num: number) {
    return (num + '').length === 1 ? '0' + num : num + '';
  }

  loadGraph(senddata){
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "diab/pgread/", (senddata), { headers: headers }).subscribe(
      data => {
        var obj = JSON.parse(JSON.stringify(data));
        var fasting = Array();
        var onehour = Array();
        var onehalf = Array();
        var twohours = Array();
        var bgdate_array = Array();

        if (obj.bgdate != null && obj.bgdate.length != 0) {
          for (var i = 0; i < obj.bgdate.length; i++) {
            bgdate_array.push(obj.bgdate[i]);
          }
        }

        if (obj.fasting != null) {
          for (var i = 0; i < obj.fasting.length; i++) {
            fasting.push(obj.fasting[i]);
          }
        }

        if (obj.onehour != null) {
          for (var i = 0; i < obj.onehour.length; i++) {
            onehour.push(obj.onehour[i]);
          }
        }

        if (obj.onehalf != null) {
          for (var i = 0; i < obj.onehalf.length; i++) {
            onehalf.push(obj.onehalf[i]);
          }
        }
        if (obj.twohours != null) {
          for (var i = 0; i < obj.twohours.length; i++) {
            twohours.push(obj.twohours[i]);
          }
        }

        var get_fast_dat = Array();
        for (var i = 0; i < bgdate_array.length; i++) {
          var res = bgdate_array[i].split("-");
          if (fasting[i] != "") {
            get_fast_dat.push(fasting[i]);
          }
        }

        var get_one_dat = Array();
        for (var i = 0; i < bgdate_array.length; i++) {
          var res = bgdate_array[i].split("-");
          if (obj.onehour != null) {
            for (var j = 0; j < obj.onehour.length; j++) {
              if (j == i) {
                get_one_dat.push(onehour[j]);
              }
            }
          }
        }
        var get_onehalf_dat = Array();
        for (var i = 0; i < bgdate_array.length; i++) {
          var res = bgdate_array[i].split("-");
          if (obj.onehalf != null) {
            for (var j = 0; j < obj.onehalf.length; j++) {
              if (j == i) {
                get_onehalf_dat.push(onehalf[j]);
              }
            }
          }
        }
        var get_two_dat = Array();
        var graphDate = new Array();
        for (var i = 0; i < bgdate_array.length; i++) {
          var res = bgdate_array[i].split("-");
          if (twohours[i] != "") {
            graphDate.push(res[2] + '-' + res[1] + '-' + res[0]);
            get_two_dat.push(twohours[i]);
          }
        }

        var dataFirst = {
          label: "Monday",
          data: get_fast_dat,
          lineTension: 0.3,
          fill: false,
          borderColor: 'rgb(8,96,0)',
          backgroundColor: 'transparent',
          pointBorderColor: 'rgb(8,96,0)',
          pointBackgroundColor: 'lightgreen',
          pointRadius: 5,
          pointHoverRadius: 15,
          pointHitRadius: 30,
          pointBorderWidth: 2,
          pointStyle: 'rect'
        };

        var dataSecond = {
          label: "Tuesday",
          data: get_one_dat,
          lineTension: 0.3,
          fill: false,
          borderColor: 'rgb(255,50,50)',
          backgroundColor: 'transparent',
          pointBorderColor: 'rgb(255,50,50)',
          pointBackgroundColor: 'lightgreen',
          pointRadius: 5,
          pointHoverRadius: 15,
          pointHitRadius: 30,
          pointBorderWidth: 2
        };

        var dataThree = {
          label: "Wednesday",
          data: get_onehalf_dat,
          lineTension: 0.3,
          fill: false,
          borderColor: 'rgb(0,47,135)',
          backgroundColor: 'transparent',
          pointBorderColor: 'rgb(0,47,135)',
          pointBackgroundColor: 'lightgreen',
          pointRadius: 5,
          pointHoverRadius: 15,
          pointHitRadius: 30,
          pointBorderWidth: 2
        };

        var dataFour = {
          label: "Thursday",
          data: get_two_dat,
          lineTension: 0.3,
          fill: false,
          borderColor: 'rgb(135, 0, 135)',
          backgroundColor: 'transparent',
          pointBorderColor: 'rgb(135, 0, 135)',
          pointBackgroundColor: 'lightgreen',
          pointRadius: 5,
          pointHoverRadius: 15,
          pointHitRadius: 30,
          pointBorderWidth: 2
        };

        var dataFive = {
          label: "Friday",
          data: get_two_dat,
          lineTension: 0.3,
          fill: false,
          borderColor: 'rgb(135, 0, 135)',
          backgroundColor: 'transparent',
          pointBorderColor: 'rgb(135, 0, 135)',
          pointBackgroundColor: 'lightgreen',
          pointRadius: 5,
          pointHoverRadius: 15,
          pointHitRadius: 30,
          pointBorderWidth: 2
        };

        var datasix = {
          label: "Satuarday",
          data: get_two_dat,
          lineTension: 0.3,
          fill: false,
          borderColor: 'rgb(135, 0, 135)',
          backgroundColor: 'transparent',
          pointBorderColor: 'rgb(135, 0, 135)',
          pointBackgroundColor: 'lightgreen',
          pointRadius: 5,
          pointHoverRadius: 15,
          pointHitRadius: 30,
          pointBorderWidth: 2
        };

        var dataseven = {
          label: "Sunday",
          data: get_two_dat,
          lineTension: 0.3,
          fill: false,
          borderColor: 'rgb(135, 0, 135)',
          backgroundColor: 'transparent',
          pointBorderColor: 'rgb(135, 0, 135)',
          pointBackgroundColor: 'lightgreen',
          pointRadius: 5,
          pointHoverRadius: 15,
          pointHitRadius: 30,
          pointBorderWidth: 2
        };

        var speedData = {
          labels: graphDate,
          datasets: [dataFirst, dataSecond, dataThree, dataFour,dataFive,datasix,dataseven]
        };

        var chartOptions: any = {
          legend: {
            display: true,
            position: 'top',
            labels: {
              boxWidth: 80,
              fontColor: 'black'
            }
          }
        };
      });
  }

  visitChange(data){
    var sendDataForMed = {
        client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
        relation_id:"1",
        time_string: "Morning",
    }
  
    if (data != null && data.length != 0 && data != undefined) {
      var image;
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.medUrl, (sendDataForMed), { headers: headers }).subscribe(
        data => {
          var shortform;
          this.medicineHistList = [];
          var obj=JSON.parse(JSON.stringify(data));
            var obj1=obj.medicines;
          if (obj.medicines != null && obj.medicines.length != 0) {
            for (var i = 0; i < obj.medicines.length; i++) {
              if( obj.medicines[i].short_form != null && obj.medicines[i].short_form != undefined && obj.medicines[i].short_form != ""){
                shortform = obj.medicines[i].short_form;
              }else{
                 shortform = "no(s)";
              }
              if(obj.medicines[i].short_name != null){
                if(obj.medicines[i].short_name == "TAB"){
                 image ="../../../assets/ui_icons/client_dashboard/Aspirin_tab_icon.png"
                }else if(obj.medicines[i].short_name == "CAP"){
                  image="../../../assets/ui_icons/client_dashboard/Synthroid_icon.png"
                }else if(obj.medicines[i].short_name == "Drops"){
                  image="../../../assets/ui_icons/client_dashboard/eyedrops_icon.png"
                }
              }
              
              this.medicineHistList.push({
                drug_id: obj.medicines[i].drug_id,
                drug_name: obj.medicines[i].drug_name,
                quantity: obj.medicines[i].quantity,
                short_form:shortform,
                time_duration:obj.medicines[i].time_duration,
                drug_inake:obj.medicines[i].drug_intake,
                tabimg:image,
              });
            }
          } else {
            this.medicineHistList = [];
          }
        },error => {});
    } 
  }

  getCurrentTime() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "adm/curdate", { country: "IN" }, { headers: headers }).subscribe(
      data => {
        var obj=JSON.parse(JSON.stringify(data));
        this.currentDate = Date_Formate(obj.current_date);
          var current_time = obj.current_time.split(":");
          if(current_time[0] >= 3 && current_time[0] < 12){
            this.session = "Morning";
          }else if(current_time[0] >= 12 && current_time[0] < 16){
            this.session = "Afternoon";
          }else if(current_time[0] >= 16 && current_time[0] < 24){
            this.session = "Evening";    
          } 
        },error => {})
   }

  getMindFullData(type) {
    var senddata={
      type:type
    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'lifestyle/gms',senddata,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          obj = obj.mindfull_list;;
          if (obj != null) {
            for(var i=0; i<obj.length; i++){
                this.mindfulList.push({
                  id: obj[i].mindfullness_id,
                  header : obj[i].header,
                  mindful_desc : obj[i].mindful_desc,
                  mind_image : ipaddress.Ip_with_img_address + obj[i].mind_image,
              })
            }
          }
        },error => {
           this.toastr.error(Message_data.network);
        });
  }

  dietPlanTracker(obj){
    var aft_color,nyt_color;
  
    if(obj.doc_diet != null){
      if(obj.doc_diet != null || obj.self_diet != null ){
        for(var k=0;k<obj.doc_diet.length;k++){        
          this.planMor     = obj.doc_diet[0].morning;
          this.planMidday  = obj.doc_diet[0].midday;
          this.planLunch   = obj.doc_diet[0].lunch;
          this.planEvening = obj.doc_diet[0].evening;
          this.planDinner  = obj.doc_diet[0].dinner;
          this.planNight   = obj.doc_diet[0].night;  
        }
      }   
    }
    
    if(obj.self_diet != null){
      for(var k=0;k<obj.self_diet.length;k++){        
        this.planMor     = this.planMor == 0 ? obj.self_diet[0].morning : this.planMor;
        this.planMidday  = this.planMidday == 0 ? obj.self_diet[0].midday : this.planMidday;
        this.planLunch   = this.planLunch == 0 ? obj.self_diet[0].lunch : this.planLunch;
        this.planEvening = obj.self_diet[0].evening;
        this.planDinner  = this.planDinner == 0 ? obj.self_diet[0].dinner : this.planDinner;
        this.planNight   = obj.self_diet[0].night;
      }
    }

    if(obj.diet_track != null){
      for(var k=0;k<obj.diet_track.length;k++){
        this.trackMor     = obj.diet_track[0].morning;
        this.trackMidday  = obj.diet_track[0].midday;
        this.trackLunch   = obj.diet_track[0].lunch;
        this.trackEvening = obj.diet_track[0].evening;
        this.trackDinner  = obj.diet_track[0].dinner;
        this.trackNight   = obj.diet_track[0].night;
      }
    }

    if( this.trackMor != undefined ||  this.planMor != undefined){
      var morn_cal:any;
      if(parseInt(this.trackMor) != 0 && this.planMor != 0)
        morn_cal = parseInt(this.trackMor) / parseInt(this.planMor);
      else
        morn_cal = 0;
       this.morningPercentage = (morn_cal * (100)).toFixed();

       var progressBar = $(".js-progress-bar");
       progressBar.css("stroke-dashoffset",((100 - (100 * this.morningPercentage) /100)));
       var colorprogressBar = $(".progress-bar__progress");
       var CcolorprogressBar = $(".progress-bar__background");

        if(this.morningPercentage <= 100){
          progressBar.css('stroke-dasharray',"100 100");
        }

        if(this.morningPercentage > 0 && this.morningPercentage <= 40){
          this.styMorningColor="#fa1c14";
          colorprogressBar.css('stroke',this.styMorningColor);
          CcolorprogressBar.css('stroke',this.styMorningColor);
        }else if(this.morningPercentage > 40 && this.morningPercentage <= 70){
          this.styMorningColor="#1be088";
          colorprogressBar.css('stroke',this.styMorningColor);
          CcolorprogressBar.css('stroke',this.styMorningColor);
        }else if(this.morningPercentage>70 && this.morningPercentage <= 100){
          this.styMorningColor="#f5a142";
          colorprogressBar.css('stroke',this.styMorningColor);
          CcolorprogressBar.css('stroke',this.styMorningColor);
        }else{
          this.styMorningColor="#fa1c14";
          colorprogressBar.css('stroke',this.styMorningColor);
          CcolorprogressBar.css('stroke',this.styMorningColor);
        }     
    }

    if( this.trackLunch != undefined || this.planLunch != undefined){
      var lunch_cal:any;
      if(parseInt(this.trackLunch) != 0  && this.planLunch != 0)
        lunch_cal = parseInt(this.trackLunch) / parseInt(this.planLunch);
      else
        lunch_cal = 0;

      this.lunchPercentage = parseInt((lunch_cal *(100)).toString());
      var lunchprogressBar = $(".js-progress-barlunch");
      lunchprogressBar.css("stroke-dashoffset",((100 - (100 * this.lunchPercentage) /100)));
      var lunchcolorprogressBar = $(".progress-bar__progresslunch");
      var lunchCcolorprogressBar = $(".progress-bar__backgroundlunch");

      if(this.lunchPercentage != ""){
        if(this.lunchPercentage <= 100){
          lunchcolorprogressBar.css('stroke-dasharray',"100 100");
        }
        if(this.lunchPercentage > 0 && this.lunchPercentage <= 40){
          aft_color="#fa1c14";
          lunchcolorprogressBar.css('stroke',aft_color);
          lunchCcolorprogressBar.css('stroke',aft_color);
        }else if(this.lunchPercentage > 40 && this.lunchPercentage <= 70){
          aft_color="#1be088";
          lunchcolorprogressBar.css('stroke',aft_color);
          lunchCcolorprogressBar.css('stroke',aft_color);
        }else if(this.lunchPercentage > 70 && this.lunchPercentage <= 100){
          aft_color="#f5a142";
          lunchcolorprogressBar.css('stroke',aft_color);
          lunchCcolorprogressBar.css('stroke',aft_color);
        }else{
          aft_color="#fa1c14";
          lunchcolorprogressBar.css('stroke',aft_color);
          lunchCcolorprogressBar.css('stroke',aft_color);
        }
      }
    }

    if( this.trackDinner != undefined ||  this.planDinner != undefined){
      var dinner_cal:any;
      if(parseInt(this.trackDinner) != 0  && this.planDinner != 0)
        dinner_cal = parseInt(this.trackDinner) / parseInt(this.planDinner);
      else
        dinner_cal = 0;
      this.dinnerPercentage = parseInt((dinner_cal *(100)).toString());
      var dinnerprogressBar = $(".js-progress-bardinner");
      dinnerprogressBar.css("stroke-dashoffset",((100 - (100 * this.dinnerPercentage) /100)));

      var dinnercolorprogressBar = $(".progress-bar__progressdinner");
      var dinnerCcolorprogressBar = $(".progress-bar__backgrounddinner");

      if(this.dinnerPercentage != ""){
        if(this.morningPercentage <= 100){
          dinnerprogressBar.css('stroke-dasharray',"100 100");
        }
        if(this.dinnerPercentage > 0 && this.dinnerPercentage <= 40){
          nyt_color="#9c919c";
          dinnercolorprogressBar.css('stroke',nyt_color);
          dinnerCcolorprogressBar.css('stroke',nyt_color);
        }else if(this.dinnerPercentage >40 && this.dinnerPercentage <= 70){
          nyt_color="#651061";
          dinnercolorprogressBar.css('stroke',nyt_color);
          dinnerCcolorprogressBar.css('stroke',nyt_color);
        }else if(this.dinnerPercentage > 70 && this.dinnerPercentage <= 100){
          nyt_color="#36a9eb";
          dinnercolorprogressBar.css('stroke',nyt_color);
          dinnerCcolorprogressBar.css('stroke',nyt_color);
        }else{
          nyt_color="#f5425a";
          dinnercolorprogressBar.css('stroke',nyt_color);
          dinnerCcolorprogressBar.css('stroke',nyt_color);
        }
      }
    }
    Client_Helper.setDietProgress(undefined);
   }

  viewActivity(e){
    Client_Helper.setViewActivity(e);
    this.clientservice.sendMessage("activity");
  }
  mindFullness(){
    this.clientservice.sendMessage("mindfulness");
  }
  viewDietTracker(){
    var flow;
    flow = {
      dietFlow : "dietTracker"
    }
    Client_Helper.setdietFlow(flow);
    this.clientservice.sendMessage("diet_tracker");
  }
}
