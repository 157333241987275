import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Physio_Helper } from '../../Physio_module/Physio_Helper';
import{Helper_Class } from '../../helper_class';
import { Doc_Helper } from '../Doc_Helper';

@Component({
  selector: 'app-dietpopupwindow',
  templateUrl: './dietpopupwindow.component.html',
  styleUrls: ['./dietpopupwindow.component.css']
})
export class DietpopupwindowComponent implements OnInit {
    
  public sga_usual_weight;
  public sga_current_weight;
  public sga_usual_measure;
  public sga_current_measure;
  public amount_losskg;
  public amount_loss;
  public weight_loss;

  private diet_weight1 = "0";
  private diet_weight2 = "0";
  private diet_weight3 = "0";
  private diet_weight4 = "0";
  private diet_weight5 = "0";
  private diet_weight6 = "0";
  private diet_weight7 = "0";
  private diet_weight8 = "0";
  private diet_weight9 = "0";
  private diet_weight10 = "0";
  private diet_weight11 = "0";
  private diet_weight12 = "0";
  private diet_weight13 = "0";
  private diet_weight14 = "0";
  private diet_weight15 = "0";
  private diet_weight16 = "0";
  private diet_weight17 = "0";
  private diet_weight18 = "0";
  private diet_weight19 = "0";
  private diet_weight20 = "0";

  public change_six_months;
  public change_two_weeks;
  public change_diet_intake;
  public chn_diet_int_dur;
  public gi_symptoms;
  public frequency;
  public fun_capacity;
  public change_fun_capacity;
  public under_eye;
  public triceps;
  public biceps;
  public temple;
  public collarbone;
  public shoulder;
  public quadriceps;
  public calf;
  public knee;
  public thumb_finger;
  public oedema;
  public ascites;
  public ribs;
  public giin_text: boolean;
  public overall_txt: string;

 // @Input() id: string;parm: string;  
  public getVitalsData;
  constructor(public dialogRef: MatDialogRef<DietpopupwindowComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
      dialogRef.disableClose = true;
      this.sga_usual_measure = "kgs";
    this.sga_current_measure = "kgs";
    this.giin_text = true;
    this.overall_txt = "Nutritioned";

    this.change_six_months = "0-5%";
    this.change_two_weeks = "No change - normal weight";
    this.change_diet_intake = "No change-adequate";
    this.chn_diet_int_dur = "less than 2 weeks";
    this.gi_symptoms = "None";
    this.frequency = "None-intermittent";
    this.fun_capacity = "Optimal";
    this.change_fun_capacity = "Improved";

    this.under_eye = "Slightly bulging area";
    this.triceps = "Large space between fingers";
    this.biceps = "Large space between fingers";
    this.temple = "Well-defined muscle/flat";
    this.collarbone = "Not visible in Males";
    this.shoulder = "Rounded";
    this.ribs = "Bones not prominent";
    this.quadriceps = "Well rounded or no depression";
    this.calf = "Well developed";
    this.knee = "Bones not prominent";
    this.thumb_finger = "Muscle protrudes";

    this.oedema = "None";
    this.ascites = "None";
    }

  ngOnInit(): void {
    this.getVitalsData =  Doc_Helper.getVitals();
    this.sga_usual_weight = this.getVitalsData.weight;
    this.sga_usual_measure = this.getVitalsData.weight_measure;
    this.sga_current_weight = this.getVitalsData.weight;
    this.sga_current_measure = this.getVitalsData.weight_measure;
  }

  onNoClick(){
    this.dialogRef.close();
  }

  done_click(){
    var sga_send = null;
    var freq_data = null;
    if (this.frequency != undefined) {
      freq_data = this.frequency;
  
    } else {
      freq_data = 0;
    }
    sga_send = {
      usual_weight: this.sga_usual_weight,
      usual_measure_type: this.sga_usual_measure,
      current_weight: this.sga_current_weight,
      cur_measure_type: this.sga_current_measure,
      change_six_months: this.change_six_months,
      change_two_weeks: this.change_two_weeks,
      change_diet_intake: this.change_diet_intake,
      chn_diet_int_dur: this.chn_diet_int_dur,
      gi_symptoms: this.gi_symptoms,
      frequency: freq_data,
      functional_capacity: this.fun_capacity,
      change_fun_capacity: this.change_fun_capacity,
      under_eye: this.under_eye,
      triceps: this.triceps,
      biceps: this.biceps,
      temple: this.temple,
      collarbone: this.collarbone,
      shoulder: this.shoulder,
      ribs: this.ribs,
      quadriceps: this.quadriceps,
      calf: this.calf,
      knee: this.knee,
      thumb_finger: this.thumb_finger,
      oedema: this.oedema,
      ascites: this.ascites,
      overall_rating: this.overall_txt,
    }
    
    Helper_Class.setSga(sga_send);
    Physio_Helper.setSga(sga_send);
    // this.dietplan.Sga_overalltxt=this.overall_txt;
    // this.modalService.close("modal-1");
  
  }
  
  getchange1() {
   var use = 0, current = 0;
    if (this.sga_usual_weight != undefined && this.sga_usual_weight != "") {
      use = parseFloat(this.sga_usual_weight);
  
    } else {
      this.amount_loss = "0";
      this.weight_loss = "0";
      use = 0;
    }
  
    if (this.sga_current_weight != undefined && this.sga_current_weight != "") {
      current = parseFloat(this.sga_current_weight);
    
    } else {
      this.amount_loss = "0";
      this.weight_loss = "0";
      current = 0;
    }
  
    if (this.sga_usual_measure == "kgs" || this.sga_usual_measure == undefined) {
      this.sga_current_measure = "kgs";
      this.amount_losskg = "kgs";
  
      if (use <= current) {
        this.amount_loss = "0";
        this.weight_loss = "0";
  
      } else {
        if (use != 0 && current != 0) {
          this.amount_loss = Math.round(use - current);
          this.weight_loss = Math.round((this.amount_loss / use) * 100);
        }
      }
  
    } else if (this.sga_usual_measure == "lbs" || this.sga_usual_measure == undefined) {
      this.sga_current_measure = "lbs";
      this.amount_losskg = "lbs";
  
      if (current >= use) {
        this.amount_loss = "0";
        this.weight_loss = "0";
  
      } else {
        if (use != 0 && current != 0) {
          this.amount_loss = Math.round(use - current);
          this.weight_loss = Math.round((this.amount_loss / use) * 100);
        }
      }
    }
   
  }
  
  weight_changesix() {
    var weight = this.diet_weight1;
    var selectedText = this.change_six_months;
  
    if (selectedText == "0-5%" || selectedText == undefined) {
      this.diet_weight1 = "1";
  
    } else if (selectedText == "5-10%") {
      this.diet_weight1 = "2";
  
    } else if (selectedText == ">10%") {
      this.diet_weight1 = "3";
  
    } else {
      this.diet_weight1 = "0";
    }
  
    var weight = this.diet_weight1;
    var weight2 = this.diet_weight2;
    var weight3 = this.diet_weight3;
    var weight4 = this.diet_weight4;
    var weight5 = this.diet_weight5;
    var weight6 = this.diet_weight6;
    var weight7 = this.diet_weight7;
    var weight8 = this.diet_weight8;
    var weight9 = this.diet_weight9;
    var weight10 = this.diet_weight10;
    var weight11 = this.diet_weight11;
    var weight12 = this.diet_weight12;
    var weight13 = this.diet_weight13;
    var weight14 = this.diet_weight14;
    var weight15 = this.diet_weight15;
    var weight16 = this.diet_weight16;
    var weight17 = this.diet_weight17;
    var weight18 = this.diet_weight18;
    var weight19 = this.diet_weight19;
    var weight20 = this.diet_weight20;
  
    var tot = parseFloat(weight) + parseFloat(weight2) + parseFloat(weight3) + parseFloat(weight4) + parseFloat(weight5) + parseFloat(weight6) + parseFloat(weight7) + parseFloat(weight8) + parseFloat(weight9) + parseFloat(weight10) + parseFloat(weight11) + parseFloat(weight12) + parseFloat(weight13) + parseFloat(weight14) + parseFloat(weight15) + parseFloat(weight16) + parseFloat(weight17) + parseFloat(weight18) + parseFloat(weight19) + parseFloat(weight20);
  
    if (tot >= 20 && tot <= 25) {
      this.overall_txt = "Nutritioned";
  
    } else if (tot >= 26 && tot <= 45) {
      this.overall_txt = "Moderately nutritioned";
  
    } else if (tot >= 46) {
      this.overall_txt = "Malnutritioned";
    
    } else {
      this.overall_txt = "Nutritioned";;
    }
  }
  
  weight_changepast() {
    var weight2 = this.diet_weight2;
    var selectedText = this.change_two_weeks;
  
    if (selectedText == "No change - normal weight" || selectedText == undefined) {
      this.diet_weight2 = "1";
  
    } else if (selectedText == "Increase (within 5%)") {
      this.diet_weight2 = "1";
  
    } else if (selectedText == "Increase (5-10%)") {
      this.diet_weight2 = "2";
  
    } else if (selectedText == "Increase (>10%)") {
      this.diet_weight2 = "2";
  
    } else if (selectedText == "No change - below usual weight") {
      this.diet_weight2 = "2";
  
    } else if (selectedText == "Decrease") {
      this.diet_weight2 = "3";
  
    } else {
      this.diet_weight2 = "0";
    }
  
    var weight = this.diet_weight1;
    var weight2 = this.diet_weight2;
    var weight3 = this.diet_weight3;
    var weight4 = this.diet_weight4;
    var weight5 = this.diet_weight5;
    var weight6 = this.diet_weight6;
    var weight7 = this.diet_weight7;
    var weight8 = this.diet_weight8;
    var weight9 = this.diet_weight9;
    var weight10 = this.diet_weight10;
    var weight11 = this.diet_weight11;
    var weight12 = this.diet_weight12;
    var weight13 = this.diet_weight13;
    var weight14 = this.diet_weight14;
    var weight15 = this.diet_weight15;
    var weight16 = this.diet_weight16;
    var weight17 = this.diet_weight17;
    var weight18 = this.diet_weight18;
    var weight19 = this.diet_weight19;
    var weight20 = this.diet_weight20;
  
    var tot = parseFloat(weight) + parseFloat(weight2) + parseFloat(weight3) + parseFloat(weight4) + parseFloat(weight5) + parseFloat(weight6) + parseFloat(weight7) + parseFloat(weight8) + parseFloat(weight9) + parseFloat(weight10) + parseFloat(weight11) + parseFloat(weight12) + parseFloat(weight13) + parseFloat(weight14) + parseFloat(weight15) + parseFloat(weight16) + parseFloat(weight17) + parseFloat(weight18) + parseFloat(weight19) + parseFloat(weight20);
  
    if (tot >= 20 && tot <= 25) {
      this.overall_txt = "Nutritioned";
  
    } else if (tot >= 26 && tot <= 45) {
      this.overall_txt = "Moderately nutritioned";
    
    } else if (tot >= 46) {
      this.overall_txt = "Malnutritioned";
      
    } else {
      this.overall_txt = "Nutritioned";;
    }
  }
  
  dietary_change() {
    var weight3 = this.diet_weight3;
    var selectedText = this.change_diet_intake;
  
    if (selectedText == "No change-adequate" || selectedText == undefined) {
      this.diet_weight3 = "1";
  
    } else if (selectedText == "No change-inadequate") {
      this.diet_weight3 = "2";
  
    } else if (selectedText == "Change-Suboptimal diet") {
      this.diet_weight3 = "2";
  
    } else if (selectedText == "Change-Full liquid") {
      this.diet_weight3 = "2";
  
    } else if (selectedText == "Change-Hypocaloric liquid") {
      this.diet_weight3 = "3";
  
    } else if (selectedText == "Change-Starvation") {
      this.diet_weight3 = "3";
  
    } else if (selectedText == "Intake borderline-increasing") {
      this.diet_weight3 = "1";
  
    } else if (selectedText == "Intake borderline-decreasing") {
      this.diet_weight3 = "2";
  
    } else if (selectedText == "Intake poor-no change") {
      this.diet_weight3 = "3";
  
    } else if (selectedText == "Intake poor-increasing") {
      this.diet_weight3 = "2";
  
    } else if (selectedText == "Intake poor-decreasing") {
      this.diet_weight3 = "3";
  
    } else {
      this.diet_weight3 = "0";
    }
  
    var weight = this.diet_weight1;
    var weight2 = this.diet_weight2;
    var weight3 = this.diet_weight3;
    var weight4 = this.diet_weight4;
    var weight5 = this.diet_weight5;
    var weight6 = this.diet_weight6;
    var weight7 = this.diet_weight7;
    var weight8 = this.diet_weight8;
    var weight9 = this.diet_weight9;
    var weight10 = this.diet_weight10;
    var weight11 = this.diet_weight11;
    var weight12 = this.diet_weight12;
    var weight13 = this.diet_weight13;
    var weight14 = this.diet_weight14;
    var weight15 = this.diet_weight15;
    var weight16 = this.diet_weight16;
    var weight17 = this.diet_weight17;
    var weight18 = this.diet_weight18;
    var weight19 = this.diet_weight19;
    var weight20 = this.diet_weight20;
  
    var tot = parseFloat(weight) + parseFloat(weight2) + parseFloat(weight3) + parseFloat(weight4) + parseFloat(weight5) + parseFloat(weight6) + parseFloat(weight7) + parseFloat(weight8) + parseFloat(weight9) + parseFloat(weight10) + parseFloat(weight11) + parseFloat(weight12) + parseFloat(weight13) + parseFloat(weight14) + parseFloat(weight15) + parseFloat(weight16) + parseFloat(weight17) + parseFloat(weight18) + parseFloat(weight19) + parseFloat(weight20);
  
    if (tot >= 20 && tot <= 25) {
      this.overall_txt = "Nutritioned";
  
    } else if (tot >= 26 && tot <= 45) {
      this.overall_txt = "Moderately nutritioned";
  
    } else if (tot >= 46) {
      this.overall_txt = "Malnutritioned";
      
    } else {
      this.overall_txt = "Nutritioned";;
    }
  }
  
  duration_change() {
    var weight20 = this.diet_weight20;
    var selectedText = this.chn_diet_int_dur;
  
    if (selectedText == "less than 2 weeks" || selectedText == undefined) {
      this.diet_weight20 = "1";
  
    } else if (selectedText == "Less than a month") {
      this.diet_weight20 = "2";
  
    } else if (selectedText == "2-6 months") {
      this.diet_weight20 = "3";
  
    } else if (selectedText == "6-12 months") {
      this.diet_weight20 = "4";
  
    } else if (selectedText == "more than a year") {
      this.diet_weight20 = "5";
    }
  
    var weight = this.diet_weight1;
    var weight2 = this.diet_weight2;
    var weight3 = this.diet_weight3;
    var weight4 = this.diet_weight4;
    var weight5 = this.diet_weight5;
    var weight6 = this.diet_weight6;
    var weight7 = this.diet_weight7;
    var weight8 = this.diet_weight8;
    var weight9 = this.diet_weight9;
    var weight10 = this.diet_weight10;
    var weight11 = this.diet_weight11;
    var weight12 = this.diet_weight12;
    var weight13 = this.diet_weight13;
    var weight14 = this.diet_weight14;
    var weight15 = this.diet_weight15;
    var weight16 = this.diet_weight16;
    var weight17 = this.diet_weight17;
    var weight18 = this.diet_weight18;
    var weight19 = this.diet_weight19;
    var weight20 = this.diet_weight20;
  
    var tot = parseFloat(weight) + parseFloat(weight2) + parseFloat(weight3) + parseFloat(weight4) + parseFloat(weight5) + parseFloat(weight6) + parseFloat(weight7) + parseFloat(weight8) + parseFloat(weight9) + parseFloat(weight10) + parseFloat(weight11) + parseFloat(weight12) + parseFloat(weight13) + parseFloat(weight14) + parseFloat(weight15) + parseFloat(weight16) + parseFloat(weight17) + parseFloat(weight18) + parseFloat(weight19) + parseFloat(weight20);
  
    if (tot >= 20 && tot <= 25) {
      this.overall_txt = "Nutritioned";
  
    } else if (tot >= 26 && tot <= 45) {
      this.overall_txt = "Moderately nutritioned";
  
    } else if (tot >= 46) {
      this.overall_txt = "Malnutritioned";
  
    } else {
      this.overall_txt = "Nutritioned";;
    }
  }
  
  gisym_onchage() {
    var selectedText = this.gi_symptoms;
    if (selectedText == "Nausea" || selectedText == undefined) {
      this.giin_text = false;
  
    } else if (selectedText == "Vomiting") {
      this.giin_text = false;
  
    } else if (selectedText == "Diarrhoea") {
      this.giin_text = false;
  
    } else if (selectedText == "Anorexia") {
      this.giin_text = false;
  
    } else if (selectedText == "None") {
      this.giin_text = true;
  
    } else {
      this.giin_text = false;
    }
  }
  
  gi_frequencychange() {
    var weight4 = this.diet_weight4;
    var selectedText = this.frequency;
  
    if (selectedText == "None-intermittent" || selectedText == undefined) {
      this.diet_weight4 = "1";
  
    } else if (selectedText == "Some-daily >2 weeks") {
      this.diet_weight4 = "2";
  
    } else if (selectedText == "All-daily >2 weeks") {
      this.diet_weight4 = "3";
  
    } else {
      this.diet_weight4 = "0";
    }
  
    var weight = this.diet_weight1;
    var weight2 = this.diet_weight2;
    var weight3 = this.diet_weight3;
    var weight4 = this.diet_weight4;
    var weight5 = this.diet_weight5;
    var weight6 = this.diet_weight6;
    var weight7 = this.diet_weight7;
    var weight8 = this.diet_weight8;
    var weight9 = this.diet_weight9;
    var weight10 = this.diet_weight10;
    var weight11 = this.diet_weight11;
    var weight12 = this.diet_weight12;
    var weight13 = this.diet_weight13;
    var weight14 = this.diet_weight14;
    var weight15 = this.diet_weight15;
    var weight16 = this.diet_weight16;
    var weight17 = this.diet_weight17;
    var weight18 = this.diet_weight18;
    var weight19 = this.diet_weight19;
    var weight20 = this.diet_weight20;
  
    var tot = parseFloat(weight) + parseFloat(weight2) + parseFloat(weight3) + parseFloat(weight4) + parseFloat(weight5) + parseFloat(weight6) + parseFloat(weight7) + parseFloat(weight8) + parseFloat(weight9) + parseFloat(weight10) + parseFloat(weight11) + parseFloat(weight12) + parseFloat(weight13) + parseFloat(weight14) + parseFloat(weight15) + parseFloat(weight16) + parseFloat(weight17) + parseFloat(weight18) + parseFloat(weight19) + parseFloat(weight20);
  
    if (tot >= 20 && tot <= 25) {
      this.overall_txt = "Nutritioned";
  
    } else if (tot >= 26 && tot <= 45) {
      this.overall_txt = "Moderately nutritioned";
  
    } else if (tot >= 46) {
      this.overall_txt = "Malnutritioned";
  
    } else {
      this.overall_txt = "Nutritioned";;
    }
  }
  
  capacity_change() {
    var weight6 = this.diet_weight6;
    var selectedText = this.fun_capacity;
  
    if (selectedText == "Optimal") {
      this.diet_weight6 = "1";
  
    } else if (selectedText == "Bed/Chair-ridden") {
      this.diet_weight6 = "3";
  
    } else if (selectedText == "Ambulatory/Suboptimal") {
      this.diet_weight6 = "2";
  
    } else {
      this.diet_weight6 = "0";
    }
  
    var weight = this.diet_weight1;
    var weight2 = this.diet_weight2;
    var weight3 = this.diet_weight3;
    var weight4 = this.diet_weight4;
    var weight5 = this.diet_weight5;
    var weight6 = this.diet_weight6;
    var weight7 = this.diet_weight7;
    var weight8 = this.diet_weight8;
    var weight9 = this.diet_weight9;
    var weight10 = this.diet_weight10;
    var weight11 = this.diet_weight11;
    var weight12 = this.diet_weight12;
    var weight13 = this.diet_weight13;
    var weight14 = this.diet_weight14;
    var weight15 = this.diet_weight15;
    var weight16 = this.diet_weight16;
    var weight17 = this.diet_weight17;
    var weight18 = this.diet_weight18;
    var weight19 = this.diet_weight19;
    var weight20 = this.diet_weight20;
  
    var tot = parseFloat(weight) + parseFloat(weight2) + parseFloat(weight3) + parseFloat(weight4) + parseFloat(weight5) + parseFloat(weight6) + parseFloat(weight7) + parseFloat(weight8) + parseFloat(weight9) + parseFloat(weight10) + parseFloat(weight11) + parseFloat(weight12) + parseFloat(weight13) + parseFloat(weight14) + parseFloat(weight15) + parseFloat(weight16) + parseFloat(weight17) + parseFloat(weight18) + parseFloat(weight19) + parseFloat(weight20);
  
    if (tot >= 20 && tot <= 25) {
      this.overall_txt = "Nutritioned";
  
    } else if (tot >= 26 && tot <= 45) {
      this.overall_txt = "Moderately nutritioned";
  
    } else if (tot >= 46) {
      this.overall_txt = "Malnutritioned";
      
    } else {
      this.overall_txt = "Nutritioned";;
    }
  }
  
  functional_capacity() {
    var weight5 = this.diet_weight5;
    var selectedText = this.change_fun_capacity;
  
    if (selectedText == "Improved") {
      this.diet_weight5 = "1";
  
    } else if (selectedText == "No change") {
      this.diet_weight5 = "2";
  
    } else if (selectedText == "Regressed") {
      this.diet_weight5 = "3";
  
    } else {
      this.diet_weight5 = "0";
    }
  
    var weight = this.diet_weight1;
    var weight2 = this.diet_weight2;
    var weight3 = this.diet_weight3;
    var weight4 = this.diet_weight4;
    var weight5 = this.diet_weight5;
    var weight6 = this.diet_weight6;
    var weight7 = this.diet_weight7;
    var weight8 = this.diet_weight8;
    var weight9 = this.diet_weight9;
    var weight10 = this.diet_weight10;
    var weight11 = this.diet_weight11;
    var weight12 = this.diet_weight12;
    var weight13 = this.diet_weight13;
    var weight14 = this.diet_weight14;
    var weight15 = this.diet_weight15;
    var weight16 = this.diet_weight16;
    var weight17 = this.diet_weight17;
    var weight18 = this.diet_weight18;
    var weight19 = this.diet_weight19;
    var weight20 = this.diet_weight20;
  
    var tot = parseFloat(weight) + parseFloat(weight2) + parseFloat(weight3) + parseFloat(weight4) + parseFloat(weight5) + parseFloat(weight6) + parseFloat(weight7) + parseFloat(weight8) + parseFloat(weight9) + parseFloat(weight10) + parseFloat(weight11) + parseFloat(weight12) + parseFloat(weight13) + parseFloat(weight14) + parseFloat(weight15) + parseFloat(weight16) + parseFloat(weight17) + parseFloat(weight18) + parseFloat(weight19) + parseFloat(weight20);
  
    if (tot >= 20 && tot <= 25) {
      this.overall_txt = "Nutritioned";
  
    } else if (tot >= 26 && tot <= 45) {
      this.overall_txt = "Moderately nutritioned";
  
    } else if (tot >= 46) {
      this.overall_txt = "Malnutritioned";
      
    } else {
      this.overall_txt = "Nutritioned";;
    }
  }
  
  under_eye_fun() {
    var weight7 = this.diet_weight7;
    var selectedText = this.under_eye;
  
    if (selectedText == "Slightly bulging area" || selectedText == undefined) {
      this.diet_weight7 = "1";
  
    } else if (selectedText == "Hollowed look") {
      this.diet_weight7 = "3";
  
    } else if (selectedText == "depression") {
      this.diet_weight7 = "3";
  
    } else if (selectedText == "dark circles") {
      this.diet_weight7 = "3";
  
    } else {
      this.diet_weight7 = "0";
    }
  
    var weight = this.diet_weight1;
    var weight2 = this.diet_weight2;
    var weight3 = this.diet_weight3;
    var weight4 = this.diet_weight4;
    var weight5 = this.diet_weight5;
    var weight6 = this.diet_weight6;
    var weight7 = this.diet_weight7;
    var weight8 = this.diet_weight8;
    var weight9 = this.diet_weight9;
    var weight10 = this.diet_weight10;
    var weight11 = this.diet_weight11;
    var weight12 = this.diet_weight12;
    var weight13 = this.diet_weight13;
    var weight14 = this.diet_weight14;
    var weight15 = this.diet_weight15;
    var weight16 = this.diet_weight16;
    var weight17 = this.diet_weight17;
    var weight18 = this.diet_weight18;
    var weight19 = this.diet_weight19;
    var weight20 = this.diet_weight20;
  
    var tot = parseFloat(weight) + parseFloat(weight2) + parseFloat(weight3) + parseFloat(weight4) + parseFloat(weight5) + parseFloat(weight6) + parseFloat(weight7) + parseFloat(weight8) + parseFloat(weight9) + parseFloat(weight10) + parseFloat(weight11) + parseFloat(weight12) + parseFloat(weight13) + parseFloat(weight14) + parseFloat(weight15) + parseFloat(weight16) + parseFloat(weight17) + parseFloat(weight18) + parseFloat(weight19) + parseFloat(weight20);
  
    if (tot >= 20 && tot <= 25) {
      this.overall_txt = "Nutritioned";
  
    } else if (tot >= 26 && tot <= 45) {
      this.overall_txt = "Moderately nutritioned";
  
    } else if (tot >= 46) {
      this.overall_txt = "Malnutritioned";
      
    } else {
      this.overall_txt = "Nutritioned";;
    }
  }
  
  triceps_change() {
    var weight8 = this.diet_weight8;
    var selectedText = this.triceps;
  
    if (selectedText == "Large space between fingers" || selectedText == undefined) {
      this.diet_weight8 = "1";
  
    } else if (selectedText == "Very little space between fingers") {
      this.diet_weight8 = "3";
  
    } else {
      this.diet_weight8 = "0";
    }
  
    var weight = this.diet_weight1;
    var weight2 = this.diet_weight2;
    var weight3 = this.diet_weight3;
    var weight4 = this.diet_weight4;
    var weight5 = this.diet_weight5;
    var weight6 = this.diet_weight6;
    var weight7 = this.diet_weight7;
    var weight8 = this.diet_weight8;
    var weight9 = this.diet_weight9;
    var weight10 = this.diet_weight10;
    var weight11 = this.diet_weight11;
    var weight12 = this.diet_weight12;
    var weight13 = this.diet_weight13;
    var weight14 = this.diet_weight14;
    var weight15 = this.diet_weight15;
    var weight16 = this.diet_weight16;
    var weight17 = this.diet_weight17;
    var weight18 = this.diet_weight18;
    var weight19 = this.diet_weight19;
    var weight20 = this.diet_weight20;
  
    var tot = parseFloat(weight) + parseFloat(weight2) + parseFloat(weight3) + parseFloat(weight4) + parseFloat(weight5) + parseFloat(weight6) + parseFloat(weight7) + parseFloat(weight8) + parseFloat(weight9) + parseFloat(weight10) + parseFloat(weight11) + parseFloat(weight12) + parseFloat(weight13) + parseFloat(weight14) + parseFloat(weight15) + parseFloat(weight16) + parseFloat(weight17) + parseFloat(weight18) + parseFloat(weight19) + parseFloat(weight20);
  
    if (tot >= 20 && tot <= 25) {
      this.overall_txt = "Nutritioned";
  
    } else if (tot >= 26 && tot <= 45) {
      this.overall_txt = "Moderately nutritioned";
  
    } else if (tot >= 46) {
      this.overall_txt = "Malnutritioned";
      
    } else {
      this.overall_txt = "Nutritioned";;
    }
  }
  
  bicep_change() {
    var weight9 = this.diet_weight9;
    var selectedText = this.biceps;
  
    if (selectedText == "Large space between fingers" || selectedText == undefined) {
      this.diet_weight9 = "1";
  
    } else if (selectedText == "Very little space between fingers") {
      this.diet_weight9 = "3";
  
    } else {
      this.diet_weight9 = "0";
    }
  
    var weight = this.diet_weight1;
    var weight2 = this.diet_weight2;
    var weight3 = this.diet_weight3;
    var weight4 = this.diet_weight4;
    var weight5 = this.diet_weight5;
    var weight6 = this.diet_weight6;
    var weight7 = this.diet_weight7;
    var weight8 = this.diet_weight8;
    var weight9 = this.diet_weight9;
    var weight10 = this.diet_weight10;
    var weight11 = this.diet_weight11;
    var weight12 = this.diet_weight12;
    var weight13 = this.diet_weight13;
    var weight14 = this.diet_weight14;
    var weight15 = this.diet_weight15;
    var weight16 = this.diet_weight16;
    var weight17 = this.diet_weight17;
    var weight18 = this.diet_weight18;
    var weight19 = this.diet_weight19;
    var weight20 = this.diet_weight20;
  
    var tot = parseFloat(weight) + parseFloat(weight2) + parseFloat(weight3) + parseFloat(weight4) + parseFloat(weight5) + parseFloat(weight6) + parseFloat(weight7) + parseFloat(weight8) + parseFloat(weight9) + parseFloat(weight10) + parseFloat(weight11) + parseFloat(weight12) + parseFloat(weight13) + parseFloat(weight14) + parseFloat(weight15) + parseFloat(weight16) + parseFloat(weight17) + parseFloat(weight18) + parseFloat(weight19) + parseFloat(weight20);
  
    if (tot >= 20 && tot <= 25) {
      this.overall_txt = "Nutritioned";
  
    } else if (tot >= 26 && tot <= 45) {
      this.overall_txt = "Moderately nutritioned";
  
    } else if (tot >= 46) {
      this.overall_txt = "Malnutritioned";
      
    } else {
      this.overall_txt = "Nutritioned";;
    }
  }
  
  temple_change() {
    var weight10 = this.diet_weight10;
    var selectedText = this.temple;
  
    if (selectedText == "Well-defined muscle/flat" || selectedText == undefined) {
      this.diet_weight10 = "1";
  
    } else if (selectedText == "Slight depression") {
      this.diet_weight10 = "2";
  
    } else if (selectedText == "Hollowing") {
      this.diet_weight10 = "3";
  
    } else if (selectedText == "Depression") {
      this.diet_weight10 = "3";
  
    } else {
      weight10 = "0";
    }
  
    var weight = this.diet_weight1;
    var weight2 = this.diet_weight2;
    var weight3 = this.diet_weight3;
    var weight4 = this.diet_weight4;
    var weight5 = this.diet_weight5;
    var weight6 = this.diet_weight6;
    var weight7 = this.diet_weight7;
    var weight8 = this.diet_weight8;
    var weight9 = this.diet_weight9;
    var weight10 = this.diet_weight10;
    var weight11 = this.diet_weight11;
    var weight12 = this.diet_weight12;
    var weight13 = this.diet_weight13;
    var weight14 = this.diet_weight14;
    var weight15 = this.diet_weight15;
    var weight16 = this.diet_weight16;
    var weight17 = this.diet_weight17;
    var weight18 = this.diet_weight18;
    var weight19 = this.diet_weight19;
    var weight20 = this.diet_weight20;
  
    var tot = parseFloat(weight) + parseFloat(weight2) + parseFloat(weight3) + parseFloat(weight4) + parseFloat(weight5) + parseFloat(weight6) + parseFloat(weight7) + parseFloat(weight8) + parseFloat(weight9) + parseFloat(weight10) + parseFloat(weight11) + parseFloat(weight12) + parseFloat(weight13) + parseFloat(weight14) + parseFloat(weight15) + parseFloat(weight16) + parseFloat(weight17) + parseFloat(weight18) + parseFloat(weight19) + parseFloat(weight20);
  
    if (tot >= 20 && tot <= 25) {
      this.overall_txt = "Nutritioned";
  
    } else if (tot >= 26 && tot <= 45) {
      this.overall_txt = "Moderately nutritioned";
  
    } else if (tot >= 46) {
      this.overall_txt = "Malnutritioned";
      
    } else {
      this.overall_txt = "Nutritioned";;
    }
  }
  
  collarbone_change() {
    var weight11 = this.diet_weight11;
    var selectedText = this.collarbone;
  
    if (selectedText == "Not visible in Males" || selectedText == undefined) {
      this.diet_weight11 = "1";
  
    } else if (selectedText == "May be visible but not prominent in females") {
      this.diet_weight11 = "1";
  
    } else if (selectedText == "Some protrusion") {
      this.diet_weight11 = "2";
  
    } else if (selectedText == "Prominent bone") {
      this.diet_weight11 = "3";
  
    } else {
      this.diet_weight11 = "0";
    }
  
    var weight = this.diet_weight1;
    var weight2 = this.diet_weight2;
    var weight3 = this.diet_weight3;
    var weight4 = this.diet_weight4;
    var weight5 = this.diet_weight5;
    var weight6 = this.diet_weight6;
    var weight7 = this.diet_weight7;
    var weight8 = this.diet_weight8;
    var weight9 = this.diet_weight9;
    var weight10 = this.diet_weight10;
    var weight11 = this.diet_weight11;
    var weight12 = this.diet_weight12;
    var weight13 = this.diet_weight13;
    var weight14 = this.diet_weight14;
    var weight15 = this.diet_weight15;
    var weight16 = this.diet_weight16;
    var weight17 = this.diet_weight17;
    var weight18 = this.diet_weight18;
    var weight19 = this.diet_weight19;
    var weight20 = this.diet_weight20;
  
    var tot = parseFloat(weight) + parseFloat(weight2) + parseFloat(weight3) + parseFloat(weight4) + parseFloat(weight5) + parseFloat(weight6) + parseFloat(weight7) + parseFloat(weight8) + parseFloat(weight9) + parseFloat(weight10) + parseFloat(weight11) + parseFloat(weight12) + parseFloat(weight13) + parseFloat(weight14) + parseFloat(weight15) + parseFloat(weight16) + parseFloat(weight17) + parseFloat(weight18) + parseFloat(weight19) + parseFloat(weight20);
  
    if (tot >= 20 && tot <= 25) {
      this.overall_txt = "Nutritioned";
  
    } else if (tot >= 26 && tot <= 45) {
      this.overall_txt = "Moderately nutritioned";
  
    } else if (tot >= 46) {
      this.overall_txt = "Malnutritioned";
      
    } else {
      this.overall_txt = "Nutritioned";;
    }
  }
  
  shoulder_change() {
    var weight12 = this.diet_weight12;
    var selectedText = this.shoulder;
  
    if (selectedText == "Rounded" || selectedText == undefined) {
      this.diet_weight12 = "1";
  
    } else if (selectedText == "No square look") {
      this.diet_weight12 = "2";
  
    } else if (selectedText == "Square look") {
      this.diet_weight12 = "3";
  
    } else if (selectedText == "Bones prominent") {
      this.diet_weight12 = "3";
  
    } else {
      this.diet_weight12 = "0";
    }
  
    var weight = this.diet_weight1;
    var weight2 = this.diet_weight2;
    var weight3 = this.diet_weight3;
    var weight4 = this.diet_weight4;
    var weight5 = this.diet_weight5;
    var weight6 = this.diet_weight6;
    var weight7 = this.diet_weight7;
    var weight8 = this.diet_weight8;
    var weight9 = this.diet_weight9;
    var weight10 = this.diet_weight10;
    var weight11 = this.diet_weight11;
    var weight12 = this.diet_weight12;
    var weight13 = this.diet_weight13;
    var weight14 = this.diet_weight14;
    var weight15 = this.diet_weight15;
    var weight16 = this.diet_weight16;
    var weight17 = this.diet_weight17;
    var weight18 = this.diet_weight18;
    var weight19 = this.diet_weight19;
    var weight20 = this.diet_weight20;
  
    var tot = parseFloat(weight) + parseFloat(weight2) + parseFloat(weight3) + parseFloat(weight4) + parseFloat(weight5) + parseFloat(weight6) + parseFloat(weight7) + parseFloat(weight8) + parseFloat(weight9) + parseFloat(weight10) + parseFloat(weight11) + parseFloat(weight12) + parseFloat(weight13) + parseFloat(weight14) + parseFloat(weight15) + parseFloat(weight16) + parseFloat(weight17) + parseFloat(weight18) + parseFloat(weight19) + parseFloat(weight20);
  
    if (tot >= 20 && tot <= 25) {
      this.overall_txt = "Nutritioned";
  
    } else if (tot >= 26 && tot <= 45) {
      this.overall_txt = "Moderately nutritioned";
  
    } else if (tot >= 46) {
      this.overall_txt = "Malnutritioned";
      
    } else {
      this.overall_txt = "Nutritioned";;
    }
  }
  
  scapula_change() {
    var weight13 = this.diet_weight13;
    var selectedText = this.ribs;
  
    if (selectedText == "Bones not prominent" || selectedText == undefined) {
      this.diet_weight13 = "1";
  
    } else if (selectedText == "Mild depressions") {
      this.diet_weight13 = "2";
  
    } else if (selectedText == "Bones prominent") {
      this.diet_weight13 = "3";
  
    } else {
      this.diet_weight13 = "0";
    }
  
    var weight = this.diet_weight1;
    var weight2 = this.diet_weight2;
    var weight3 = this.diet_weight3;
    var weight4 = this.diet_weight4;
    var weight5 = this.diet_weight5;
    var weight6 = this.diet_weight6;
    var weight7 = this.diet_weight7;
    var weight8 = this.diet_weight8;
    var weight9 = this.diet_weight9;
    var weight10 = this.diet_weight10;
    var weight11 = this.diet_weight11;
    var weight12 = this.diet_weight12;
    var weight13 = this.diet_weight13;
    var weight14 = this.diet_weight14;
    var weight15 = this.diet_weight15;
    var weight16 = this.diet_weight16;
    var weight17 = this.diet_weight17;
    var weight18 = this.diet_weight18;
    var weight19 = this.diet_weight19;
    var weight20 = this.diet_weight20;
  
    var tot = parseFloat(weight) + parseFloat(weight2) + parseFloat(weight3) + parseFloat(weight4) + parseFloat(weight5) + parseFloat(weight6) + parseFloat(weight7) + parseFloat(weight8) + parseFloat(weight9) + parseFloat(weight10) + parseFloat(weight11) + parseFloat(weight12) + parseFloat(weight13) + parseFloat(weight14) + parseFloat(weight15) + parseFloat(weight16) + parseFloat(weight17) + parseFloat(weight18) + parseFloat(weight19) + parseFloat(weight20);
  
    if (tot >= 20 && tot <= 25) {
      this.overall_txt = "Nutritioned";
      
    } else if (tot >= 26 && tot <= 45) {
      this.overall_txt = "Moderately nutritioned";
  
    } else if (tot >= 46) {
      this.overall_txt = "Malnutritioned";
      
    } else {
      this.overall_txt = "Nutritioned";;
    }
  }
  
  quadra_change() {
    var weight14 = this.diet_weight14;
    var selectedText = this.quadriceps;
  
    if (selectedText == "Well rounded or no depression" || selectedText == undefined) {
      this.diet_weight14 = "1";
  
    } else if (selectedText == "Mild depressions") {
      this.diet_weight14 = "2";
  
    } else if (selectedText == "Depression or Thin") {
      this.diet_weight14 = "3";
  
    } else {
      this.diet_weight14 = "0";
    }
  
    var weight = this.diet_weight1;
    var weight2 = this.diet_weight2;
    var weight3 = this.diet_weight3;
    var weight4 = this.diet_weight4;
    var weight5 = this.diet_weight5;
    var weight6 = this.diet_weight6;
    var weight7 = this.diet_weight7;
    var weight8 = this.diet_weight8;
    var weight9 = this.diet_weight9;
    var weight10 = this.diet_weight10;
    var weight11 = this.diet_weight11;
    var weight12 = this.diet_weight12;
    var weight13 = this.diet_weight13;
    var weight14 = this.diet_weight14;
    var weight15 = this.diet_weight15;
    var weight16 = this.diet_weight16;
    var weight17 = this.diet_weight17;
    var weight18 = this.diet_weight18;
    var weight19 = this.diet_weight19;
    var weight20 = this.diet_weight20;
  
    var tot = parseFloat(weight) + parseFloat(weight2) + parseFloat(weight3) + parseFloat(weight4) + parseFloat(weight5) + parseFloat(weight6) + parseFloat(weight7) + parseFloat(weight8) + parseFloat(weight9) + parseFloat(weight10) + parseFloat(weight11) + parseFloat(weight12) + parseFloat(weight13) + parseFloat(weight14) + parseFloat(weight15) + parseFloat(weight16) + parseFloat(weight17) + parseFloat(weight18) + parseFloat(weight19) + parseFloat(weight20);
  
    if (tot >= 20 && tot <= 25) {
      this.overall_txt = "Nutritioned";
  
    } else if (tot >= 26 && tot <= 45) {
      this.overall_txt = "Moderately nutritioned";
  
    } else if (tot >= 46) {
      this.overall_txt = "Malnutritioned";
      
    } else {
      this.overall_txt = "Nutritioned";;
    }
  }
  
  calf_change() {
    var weight15 = this.diet_weight15;
    var selectedText = this.calf;
  
    if (selectedText == "Well developed" || selectedText == undefined) {
      this.diet_weight15 = "1";
  
    } else if (selectedText == "Thin or no muscle definition") {
      this.diet_weight15 = "3";
  
    } else {
      this.diet_weight15 = "0";
    }
  
    var weight = this.diet_weight1;
    var weight2 = this.diet_weight2;
    var weight3 = this.diet_weight3;
    var weight4 = this.diet_weight4;
    var weight5 = this.diet_weight5;
    var weight6 = this.diet_weight6;
    var weight7 = this.diet_weight7;
    var weight8 = this.diet_weight8;
    var weight9 = this.diet_weight9;
    var weight10 = this.diet_weight10;
    var weight11 = this.diet_weight11;
    var weight12 = this.diet_weight12;
    var weight13 = this.diet_weight13;
    var weight14 = this.diet_weight14;
    var weight15 = this.diet_weight15;
    var weight16 = this.diet_weight16;
    var weight17 = this.diet_weight17;
    var weight18 = this.diet_weight18;
    var weight19 = this.diet_weight19;
    var weight20 = this.diet_weight20;
  
    var tot = parseFloat(weight) + parseFloat(weight2) + parseFloat(weight3) + parseFloat(weight4) + parseFloat(weight5) + parseFloat(weight6) + parseFloat(weight7) + parseFloat(weight8) + parseFloat(weight9) + parseFloat(weight10) + parseFloat(weight11) + parseFloat(weight12) + parseFloat(weight13) + parseFloat(weight14) + parseFloat(weight15) + parseFloat(weight16) + parseFloat(weight17) + parseFloat(weight18) + parseFloat(weight19) + parseFloat(weight20);
  
    if (tot >= 20 && tot <= 25) {
      this.overall_txt = "Nutritioned";
  
    } else if (tot >= 26 && tot <= 45) {
      this.overall_txt = "Moderately nutritioned";
      
    } else if (tot >= 46) {
      this.overall_txt = "Malnutritioned";
      
    } else {
      this.overall_txt = "Nutritioned";;
    }
  }
  
  knee_change() {
    var weight16 = this.diet_weight16;
    var selectedText = this.knee;
  
    if (selectedText == "Bones not prominent" || selectedText == undefined) {
      this.diet_weight16 = "1";
  
    } else if (selectedText == "Bones prominent") {
      this.diet_weight16 = "3";
  
    } else {
      this.diet_weight16 = "0";
    }
  
    var weight = this.diet_weight1;
    var weight2 = this.diet_weight2;
    var weight3 = this.diet_weight3;
    var weight4 = this.diet_weight4;
    var weight5 = this.diet_weight5;
    var weight6 = this.diet_weight6;
    var weight7 = this.diet_weight7;
    var weight8 = this.diet_weight8;
    var weight9 = this.diet_weight9;
    var weight10 = this.diet_weight10;
    var weight11 = this.diet_weight11;
    var weight12 = this.diet_weight12;
    var weight13 = this.diet_weight13;
    var weight14 = this.diet_weight14;
    var weight15 = this.diet_weight15;
    var weight16 = this.diet_weight16;
    var weight17 = this.diet_weight17;
    var weight18 = this.diet_weight18;
    var weight19 = this.diet_weight19;
    var weight20 = this.diet_weight20;
  
    var tot = parseFloat(weight) + parseFloat(weight2) + parseFloat(weight3) + parseFloat(weight4) + parseFloat(weight5) + parseFloat(weight6) + parseFloat(weight7) + parseFloat(weight8) + parseFloat(weight9) + parseFloat(weight10) + parseFloat(weight11) + parseFloat(weight12) + parseFloat(weight13) + parseFloat(weight14) + parseFloat(weight15) + parseFloat(weight16) + parseFloat(weight17) + parseFloat(weight18) + parseFloat(weight19) + parseFloat(weight20);
  
    if (tot >= 20 && tot <= 25) {
      this.overall_txt = "Nutritioned";
  
    } else if (tot >= 26 && tot <= 45) {
      this.overall_txt = "Moderately nutritioned";
  
    } else if (tot >= 46) {
      this.overall_txt = "Malnutritioned";
      
    } else {
      this.overall_txt = "Nutritioned";;
    }
  }
  
  inter_change() {
    var weight17 = this.diet_weight17;
    var selectedText = this.thumb_finger;
  
    if (selectedText == "Muscle protrudes" || selectedText == undefined) {
      this.diet_weight17 = "1";
  
    } else if (selectedText == "Flat or depressed area") {
      this.diet_weight17 = "3";
  
    } else {
      this.diet_weight17 = "0";
    }
  
    var weight = this.diet_weight1;
    var weight2 = this.diet_weight2;
    var weight3 = this.diet_weight3;
    var weight4 = this.diet_weight4;
    var weight5 = this.diet_weight5;
    var weight6 = this.diet_weight6;
    var weight7 = this.diet_weight7;
    var weight8 = this.diet_weight8;
    var weight9 = this.diet_weight9;
    var weight10 = this.diet_weight10;
    var weight11 = this.diet_weight11;
    var weight12 = this.diet_weight12;
    var weight13 = this.diet_weight13;
    var weight14 = this.diet_weight14;
    var weight15 = this.diet_weight15;
    var weight16 = this.diet_weight16;
    var weight17 = this.diet_weight17;
    var weight18 = this.diet_weight18;
    var weight19 = this.diet_weight19;
    var weight20 = this.diet_weight20;
  
    var tot = parseFloat(weight) + parseFloat(weight2) + parseFloat(weight3) + parseFloat(weight4) + parseFloat(weight5) + parseFloat(weight6) + parseFloat(weight7) + parseFloat(weight8) + parseFloat(weight9) + parseFloat(weight10) + parseFloat(weight11) + parseFloat(weight12) + parseFloat(weight13) + parseFloat(weight14) + parseFloat(weight15) + parseFloat(weight16) + parseFloat(weight17) + parseFloat(weight18) + parseFloat(weight19) + parseFloat(weight20);
  
    if (tot >= 20 && tot <= 25) {
      this.overall_txt = "Nutritioned";
  
    } else if (tot >= 26 && tot <= 45) {
      this.overall_txt = "Moderately nutritioned";
  
    } else if (tot >= 46) {
      this.overall_txt = "Malnutritioned";
      
    } else {
      this.overall_txt = "Nutritioned";;
    }
  }
  
  oedema_change() {
    var weight18 = this.diet_weight18;
    var selectedText = this.oedema;
  
    if (selectedText == "None" || selectedText == undefined) {
      this.diet_weight18 = "1";
  
    } else if (selectedText == "Mild to moderate") {
      this.diet_weight18 = "2";
  
    } else if (selectedText == "Severe") {
      this.diet_weight18 = "3";
  
    } else {
      this.diet_weight18 = "0";
    }
  
    var weight = this.diet_weight1;
    var weight2 = this.diet_weight2;
    var weight3 = this.diet_weight3;
    var weight4 = this.diet_weight4;
    var weight5 = this.diet_weight5;
    var weight6 = this.diet_weight6;
    var weight7 = this.diet_weight7;
    var weight8 = this.diet_weight8;
    var weight9 = this.diet_weight9;
    var weight10 = this.diet_weight10;
    var weight11 = this.diet_weight11;
    var weight12 = this.diet_weight12;
    var weight13 = this.diet_weight13;
    var weight14 = this.diet_weight14;
    var weight15 = this.diet_weight15;
    var weight16 = this.diet_weight16;
    var weight17 = this.diet_weight17;
    var weight18 = this.diet_weight18;
    var weight19 = this.diet_weight19;
    var weight20 = this.diet_weight20;
  
    var tot = parseFloat(weight) + parseFloat(weight2) + parseFloat(weight3) + parseFloat(weight4) + parseFloat(weight5) + parseFloat(weight6) + parseFloat(weight7) + parseFloat(weight8) + parseFloat(weight9) + parseFloat(weight10) + parseFloat(weight11) + parseFloat(weight12) + parseFloat(weight13) + parseFloat(weight14) + parseFloat(weight15) + parseFloat(weight16) + parseFloat(weight17) + parseFloat(weight18) + parseFloat(weight19) + parseFloat(weight20);
  
    if (tot >= 20 && tot <= 25) {
      this.overall_txt = "Nutritioned";
  
    } else if (tot >= 26 && tot <= 45) {
      this.overall_txt = "Moderately nutritioned";
  
    } else if (tot >= 46) {
      this.overall_txt = "Malnutritioned";
      
    } else {
      this.overall_txt = "Nutritioned";;
    }
  }
  
  ascities_change() {
    var weight19 = this.diet_weight19;
    var selectedText = this.ascites;
  
    if (selectedText == "None" || selectedText == undefined) {
      this.diet_weight19 = "1";
  
    } else if (selectedText == "Mild to moderate") {
      this.diet_weight19 = "2";
  
    } else if (selectedText == "Severe") {
      this.diet_weight19 = "3";
  
    } else {
      this.diet_weight19 = "0";
    }
  
    var weight = this.diet_weight1;
    var weight2 = this.diet_weight2;
    var weight3 = this.diet_weight3;
    var weight4 = this.diet_weight4;
    var weight5 = this.diet_weight5;
    var weight6 = this.diet_weight6;
    var weight7 = this.diet_weight7;
    var weight8 = this.diet_weight8;
    var weight9 = this.diet_weight9;
    var weight10 = this.diet_weight10;
    var weight11 = this.diet_weight11;
    var weight12 = this.diet_weight12;
    var weight13 = this.diet_weight13;
    var weight14 = this.diet_weight14;
    var weight15 = this.diet_weight15;
    var weight16 = this.diet_weight16;
    var weight17 = this.diet_weight17;
    var weight18 = this.diet_weight18;
    var weight19 = this.diet_weight19;
    var weight20 = this.diet_weight20;
  
    var tot = parseFloat(weight) + parseFloat(weight2) + parseFloat(weight3) + parseFloat(weight4) + parseFloat(weight5) + parseFloat(weight6) + parseFloat(weight7) + parseFloat(weight8) + parseFloat(weight9) + parseFloat(weight10) + parseFloat(weight11) + parseFloat(weight12) + parseFloat(weight13) + parseFloat(weight14) + parseFloat(weight15) + parseFloat(weight16) + parseFloat(weight17) + parseFloat(weight18) + parseFloat(weight19) + parseFloat(weight20);
  
    if (tot >= 20 && tot <= 25) {
      this.overall_txt = "Nutritioned";
  
    } else if (tot >= 26 && tot <= 45) {
      this.overall_txt = "Moderately nutritioned";
  
    } else if (tot >= 46) {
      this.overall_txt = "Malnutritioned";
  
    } else {
      this.overall_txt = "Nutritioned";;
    }
  }
  



}
