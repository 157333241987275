<div *ngIf="othersPersonalFlag == 'min'">
    <div class="details">
        <mat-label class="matlabel" [hidden]="name_hidden" >{{name_label}}<br>
            <input matInput class="ipcss " maxlength="25" required [(ngModel)]="name">
        </mat-label>
        <mat-label class="matlabel" [hidden]="Gender_hidden" >{{gender_label}}<br>
            <input matInput class="ipcss " [readonly]="isReadonly()" [(ngModel)]="gender">
        </mat-label>
        <mat-label class="matlabel" [hidden]="age_hidden" >{{age_label}}<br>
            <input matInput class="ipcss "  [readonly]="agefield" [(ngModel)]="age">
        </mat-label>
        <mat-label class="matlabel" [hidden]="currnetoccupation_hidden">{{current_label}}<br>
            <input matInput class="ipcss " maxlength="25" required [(ngModel)]="current_occupation">
        </mat-label>
    </div><br>
</div>

<div *ngIf="othersPersonalFlag == 'max'">
    <div class="row">
        <div class="col-6">
            <div class="cover_div">
                <div class="header_lable">
                    Personal
                </div>
                <div class="content_cover">
                    <div class="row">
                        <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6" [hidden]="name_hidden">
                            <mat-label class="matlabel" [hidden]="name_hidden">{{name_label}}<br>
                                <input matInput class="ipcss " maxlength="25" required [(ngModel)]="name" (change)="send_data()"
                                    [readonly]="client">
                            </mat-label>
                        </div>
                        <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6" [hidden]="Gender_hidden">
                            <mat-label class="matlabel" [hidden]="Gender_hidden">{{gender_label}}<br>
                                <input matInput class="ipcss " [readonly]="isReadonly()" [(ngModel)]="gender" (change)="send_data()">
                            </mat-label>
                        </div>
                        <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6" [hidden]="age_hidden">
                            <mat-label class="matlabel" [hidden]="age_hidden">{{age_label}}<br>
                                <input matInput class="ipcss " [(ngModel)]="age" (change)="send_data()">
                            </mat-label>
                        </div>
                        <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6" [hidden]="currnetoccupation_hidden">
                            <mat-label class="matlabel" [hidden]="currnetoccupation_hidden">{{current_label}}<br>
                                <input matInput class="ipcss " maxlength="25" required (change)="send_data()"
                                    [(ngModel)]="current_occupation" [readonly]="client">
                            </mat-label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="cover_div">
                <div class="header_lable">
                    Observation
                </div>
                <div class="content_cover">
                    <div class="row">
                        <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6" [hidden]="bnature_hidden">
                            <mat-label class="matlabel" [hidden]="bnature_hidden">{{bnature_label}}<br>
                                <input matInput class="ipcss " [readonly]="isReadonly()" (change)="send_data()"
                                    [(ngModel)]="body_nature">
                            </mat-label>
                        </div>
                        <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6" [hidden]="bodytype_hidden">
                            <mat-label class="matlabel" [hidden]="bodytype_hidden">{{bodytype_label}}<br>
                                <select class="ipcss " [(ngModel)]="body_type" disableOptionCentering (change)="send_data()"
                                    [disabled]="client" style="height: 26px;">
                                    <option *ngFor="let btype of bodyttype_array" value="{{btype.bodytype_name}}">
                                        {{btype.bodytype_name}}</option>
                                </select>
                            </mat-label>
                        </div>
                        <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6" [hidden]="anymed_hidden">
                            <mat-label class="matlabel" [hidden]="anymed_hidden">{{anymed_label}}<br>
                                <select class="ipcss " required [(ngModel)]="anymed_txt"
                                    (change)="med_change();send_data()" disableOptionCentering [disabled]="client"  style="height: 26px;">
                                    <option value="Yes">Yes</option>
                                    <option selected value="No">No</option>
                                </select>
                            </mat-label>
                        </div>
                        <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6" [hidden]="med_hidden ">
                            <mat-label class="matlabel" [hidden]="med_hidden">{{med_label}}<br>
                                <input matInput class="ipcss " maxlength="100" [disabled]="med_flag" required
                                    [(ngModel)]="med_txt">
                            </mat-label>
                        </div>
                    </div>
            

                </div>
            </div>
        </div>
        <div  class="col-12">
            <div class="cover_div">
                <div class="header_lable">
                    Concerns
                </div>
                <div class="content_cover">
                    <div class="row">
                        <div class="col-md-6 col-lg-6 col-sm-12 col-xl-4">
                           <div class="row">
                             <div class="col-12 margin_10">
                               <mat-label class="matlabel">
                                 <b class="label_bold">Complaints</b>
                               </mat-label>
                             </div>
                             <div class="col-12">
                               <quill-editor id="editor1" [(ngModel)]="complaints" [styles]="{height: 'calc(35vh - 100px)'}"
                                 [modules]="modules" placeholder="Enter Text" (onContentChanged)="changelinemgmt($event,'main');send_data()">
                               </quill-editor>
                               <div>
                                 <quill-view-html hidden id="linemgmt" [content]="complaints"></quill-view-html>
                               </div>
                             </div>
                           </div>
                         </div>
           
                         <div class="col-md-6 col-lg-6 col-sm-12 col-xl-4">
                           <div class="row">
                             <div class="col-12 margin_10">
                               <mat-label class="matlabel">
                                 <b class="label_bold">Past medical history</b>
                               </mat-label>
                             </div>
                             <div class="col-12">
                               <quill-editor id="editor1" [(ngModel)]="past_medical" [styles]="{height: 'calc(35vh - 100px)'}"
                                 [modules]="modules" placeholder="Enter Text" (onContentChanged)="changelinemgmt($event,'past');send_data()">
                               </quill-editor>
                               <div>
                                 <quill-view-html hidden id="linemgmt" [content]="past_medical"></quill-view-html>
                               </div>
                             </div>
                           </div>
                         </div>
                         <div class="col-md-6 col-lg-6 col-sm-12 col-xl-4">
                           <div class="row">
                             <div class="col-12 margin_10">
                               <mat-label class="matlabel">
                                 <b class="label_bold">Present illness</b>
                               </mat-label>
                             </div>
                             <div class="col-12">
                               <quill-editor id="editor1" [(ngModel)]="present_illvalue" [styles]="{height: 'calc(35vh - 100px)'}"
                                 [modules]="modules" placeholder="Enter Text" (onContentChanged)="changelinemgmt($event,'present_illvalue');send_data()">
                               </quill-editor>
                               <div>
                                 <quill-view-html hidden id="linemgmt" [content]="present_illvalue"></quill-view-html>
                               </div>
                             </div>
                           </div>
                         </div>
                  
                     </div>
                </div>
            </div>
        </div>
        <div [hidden]="dietary_hidden" class="col-12">
            <div class="cover_div">
                <div class="header_lable">
                    Diet
                </div>
                <div class="content_cover">
                    <div class="row">
                        <div class="col-12">
                            <mat-label class="matlabel">{{dietary_label}}<br>
                                <textarea class="ipcss " #myInput id="myInput" maxlength="250" rows="1" required
                                    [(ngModel)]="diet_habits" [readonly]="client" style="height: 85px !important;"></textarea>
                            </mat-label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12">
            <img (click)="history()" src="../../../assets/ui_icons/buttons/History_button.svg" class="saveimgbtn_inpatinfo" style="float: right;" />
        </div>
        <!-- <div class="col-6">
            <div class="cover_div">
                <div class="header_lable">
                    Bmi & vitals
                </div>
                <div class="content_cover">
                    <div class="row">
                        <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6" [hidden]="height_hidden">
                            <mat-label class="matlabel">{{height_label}}<br>
                                <input matInput class="ipcss heightmsr" (change)="calories_data()"
                                    maxlength="{{height_measure == 'cms' && (3) || (5)}}"
                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57" required
                                    [(ngModel)]="height_txt" [readonly]="client">
                            </mat-label>
                        </div>
                        <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6" [hidden]="height_hidden">
                            <mat-label class="matlabel">{{height_measurelabel}}<br>
                                <mat-select class="ipcss weightmsr" required (change)="calories_data()"
                                    [(ngModel)]="height_measure" disableOptionCentering [disabled]="client">
                                    <mat-option value="cms">cms</mat-option>
                                    <mat-option value="inch">inch</mat-option>
                                </mat-select>
                            </mat-label>
                        </div>
                        <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6" [hidden]="weight_hidden">
                            <mat-label class="matlabel">{{weight_label}}<br>
                                <input matInput class="ipcss heightmsr" (change)="calories_data()" maxlength="5"
                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57" required
                                    [(ngModel)]="weight_txt" [readonly]="client">
                            </mat-label>
                        </div>
                        <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6" [hidden]="wtmeasure_hidden">
                            <mat-label class="matlabel">{{weight_measurelabel}}<br>
                                <mat-select class="ipcss weightmsr" (change)="calories_data()" required
                                    [(ngModel)]="weight_measure" disableOptionCentering [disabled]="client">
                                    <mat-option value="kgs">kgs</mat-option>
                                    <mat-option value="lbs">lbs</mat-option>
                                </mat-select>
                            </mat-label>
                        </div>
                        <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6" [hidden]="bmi_hidden">
                            <mat-label [hidden]="bmi_hidden" class="matlabel">{{bmi_label}}<br>
                                <input matInput class="ipcss " [readonly]="isReadonly()" [(ngModel)]="txt_bmi">
                            </mat-label>
                        </div>
                        <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6" [hidden]="bmr_hidden">
                            <mat-label [hidden]="bmr_hidden" class="matlabel">{{bmr_label}}<br>
                                <input matInput class="ipcss " [readonly]="isReadonly()" [(ngModel)]="txt_bmr">
                            </mat-label>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->


    </div>
</div>