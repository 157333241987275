import { Component, OnInit ,ElementRef,Input,Inject } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr'
import { CommonDataService } from '../../providers/common-data.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ClientViewService } from '../clientview/client-view.service';
declare var $ : any; 

@Component({
  selector: 'app-pharmacyorderdetailcancel',
  templateUrl: './pharmacyorderdetailcancel.component.html',
  styleUrls: ['./pharmacyorderdetailcancel.component.css']
})
export class PharmacyorderdetailcancelComponent implements OnInit {
  @Input() id: string;parm: string;  
  public element: any;
  public cancel;

  constructor(public clientservice:ClientViewService,public comm_Data:CommonDataService,private el: ElementRef, toastr:ToastrService,public router:Router,public  http:Http,public dialog: MatDialog ,public dialogRef: MatDialogRef<PharmacyorderdetailcancelComponent>,
  @Inject(MAT_DIALOG_DATA) public data: any) {
    dialogRef.disableClose = true;
  }
  
  ngOnInit(): void {
  }

  close(){
    this.dialogRef.close();
  }

  done(){
    this.dialogRef.close(this.cancel);
    this.clientservice.sendMessage("clientpharmacycurrent")
  }
}
