<div *ngIf="apptFlag == 'min'">
    <table id="card_tbl" class="table table-hover table-dynamic">
        <tr>
            <th style="padding-left: 8px !important;">Patient name</th>
            <th>Age</th>
            <th style="text-align: center;">Status </th>
        </tr>
        <tr *ngFor="let list of appointmentList | slice: 0 : 2">
            <td style="font-size: 11px !important;text-align: left;">{{list.patient_name}}</td>
            <td style="font-size: 11px !important;">{{list.age}}</td>
            <td style="font-size: 11px !important;">{{list.status}}</td>
        </tr>
    </table>
</div>
<div *ngIf="apptFlag == 'max'">
    <div style="float: right;margin-bottom: 10px; position: relative;">
        <!-- <img *ngIf="showListFlag" style="width: 18px !important; position: absolute; top: -38px; right: 39px;"
            src="../../../assets/ui_icons/video_icons.svg" (click)="vedio()"/> -->
    </div>
    <div class="splitter">
        <as-split direction="vertical">
            <as-split-area [size]="30">
                <div class="personaldetails">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div class="table-responsive">
                            <table id="card_tbl" class="table table-hover table-dynamic">
                                <tr>
                                    <th class="tervys_heading">Patient name</th>
                                    <th class="tervys_heading">Age</th>
                                    <th class="tervys_heading">Contact </th>
                                    <th class="tervys_heading">Last visit </th>
                                    <th class="tervys_heading">Status </th>
                                    <th class="tervys_heading">Action</th>
                                </tr>
                                <tr *ngFor="let list of appointmentList">
                                    <td class="matlabel" style="text-align: left;">{{list.patient_name}}</td>
                                    <td class="matlabel">{{list.age}}</td>
                                    <td class="matlabel">{{list.contact}}</td>
                                    <td class="matlabel">{{list.lastvisit}}</td>
                                    <td class="matlabel">
                                        <div style="position: relative;top: 9px;">
                                            <img *ngIf="list.status == 'Completed'" width="30px" height="auto"
                                                src="../../../assets/ui_icons/Completed_icon.svg" />
                                            <img *ngIf="list.status == 'Cancel'" width="30px" height="auto"
                                                src="../../../assets/ui_icons/cancel_icon.svg" />
                                            <img *ngIf="list.status == 'Not visited'" width="30px" height="auto"
                                                src="../../../assets/ui_icons/not_visited_icon.svg" />
                                            <img *ngIf="list.status == 'Confirmed'" width="30px" height="auto"
                                                src="../../../assets/ui_icons/confirmed_icon.svg" />
                                            <img *ngIf="list.status == 'Open'" width="30px" height="32px"
                                                src="../../../assets/ui_icons/opened_icon.svg" />
                                            <p>{{list.status}}</p>
                                        </div>
                                    </td>
                                    <td><a (click)='viewAppt(list)'><img
                                                src="../../../assets/ui_icons/search_icon_new.svg"
                                                class="editdelicon" /></a></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </as-split-area>
            <as-split-area [size]="70">
                <div class="container" *ngIf="showListFlag">
                    <div class="row">
                        <div class="col-12">
                            <div class="cover_div">
                                <div class="header_lable">
                                    Personal
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel">Relationship
                                                <input class="ipcss " matInput type="text" [(ngModel)]="clntRelation"
                                                    [readonly]="isReadonly()" />
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel">Name
                                                <input class="ipcss " matInput type="text" [(ngModel)]="clntName"
                                                    [readonly]="isReadonly()" />
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel">Age
                                                <input class="ipcss " matInput type="text" [(ngModel)]="clntAge"
                                                    disabled />
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel">Gender
                                                <input class="ipcss " matInput type="text" [(ngModel)]="clntGender"
                                                    disabled />
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel">Blood group
                                                <input class="ipcss " matInput type="text" [(ngModel)]="clntbloodGroup"
                                                    disabled />
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel">Symptom
                                                <input class="ipcss " matInput type="text" [(ngModel)]="symptoms"
                                                    disabled />
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel">Phone number
                                                <input class="ipcss " matInput type="text" [(ngModel)]="clntMobile"
                                                    disabled />
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel">Physiotherapist
                                                <input class="ipcss " matInput type="text"
                                                    [(ngModel)]="physiotherapistName" disabled />
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel">Reason
                                                <input class="ipcss " matInput type="text" [(ngModel)]="apptReason"
                                                    disabled />
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel">Hospital address
                                                <textarea class="ipcss " matInput type="text" [(ngModel)]="hospDetails"
                                                    [readonly]="isReadonly()"></textarea>
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel">Appointment details
                                                <textarea class="ipcss " matInput type="text" [(ngModel)]="appDetails"
                                                    [readonly]="isReadonly()"></textarea>
                                            </mat-label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </as-split-area>
        </as-split>
    </div>
</div>