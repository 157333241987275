<div class="row">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Appointment Details</h5>
          </div>
          <div class="headerButtons">
            <img class="saveimgbtn_inpatinfo" (click)="back()"
              src="../../../assets/ui_icons/buttons/Back_button.svg" />
            <img class="saveimgbtn_inpatinfo" (click)="cancel()" *ngIf="checkCancel"
              src="../../../assets/ui_icons/buttons/cancel_button.svg" />
            <img class="saveimgbtn_inpatinfo" *ngIf="checkReschedule" (click)="reschedule()"
              src="../../../assets/ui_icons/buttons/reschedule_button.svg" />
            <!-- <img class="saveimgbtn_inpatinfo" (click)="rateus()" *ngIf="checkRate"
              src="../../../assets/ui_icons/buttons/Rate_button.svg" /> -->
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="cover_div" style="padding-bottom: 13px;">
          <div class="header_lable">
            Personal information
          </div>
          <div class="content_cover">
            <div class="row">
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label style="margin-top: 7px;" class="matlabel">Appointment for
                  <input type="text" class="ipcss " [(ngModel)]="relation" [readonly]=true matInput />
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label style="margin-top: 7px;" class="matlabel">First name
                  <input type="text" class="ipcss " [(ngModel)]="firstName" [readonly]=true matInput />
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label style="margin-top: 7px;" class="matlabel">Middle name
                  <input type="text" class="ipcss " [(ngModel)]="middleName" [readonly]=true matInput />
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label style="margin-top: 7px;" class="matlabel">Last name
                  <input type="text" class="ipcss " [(ngModel)]="lastName" [readonly]=true matInput />
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Age
                  <input type="text" class="ipcss " [(ngModel)]="age" [readonly]=true matInput />
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Gender
                  <input type="text" class="ipcss " [(ngModel)]="gender" [readonly]=true matInput />
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Blood group
                  <input type="text" class="ipcss " [(ngModel)]="bgrp" [readonly]=true matInput />
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Phone number
                  <input type="text" class="ipcss " [(ngModel)]="phone" [readonly]=true matInput />
                </mat-label>
              </div>
            </div>
          </div>
        </div>
        <div class="cover_div" style="padding-bottom: 13px;">
          <div class="header_lable">
            Diagnosis center details
          </div>
          <div class="content_cover">
            <div class="row">
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Referred by
                  <input type="text" class="ipcss " [(ngModel)]="refDrName" [readonly]=true matInput />
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Center details
                  <input type="text" class="ipcss " [(ngModel)]="centerName" disabled matInput />
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Appointment details
                  <textarea class="ipcss " [(ngModel)]="centerAddr1" disabled></textarea>
                </mat-label>
              </div>
              <div *ngIf="testFlag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label *ngIf="testFlag" class="matlabel">Test prescribed
                  <div class="testpresc">
                    <div *ngFor="let data of testNameList">
                      <p style="color: black;"><b>{{data.group_name}}</b></p>
                      <p style="color: black;">{{data.sub_test}}</p>
                    </div>
                  </div>
                </mat-label>
              </div>
            </div>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>