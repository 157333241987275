import { Component, OnInit ,ElementRef,Input,Inject,ViewEncapsulation,HostListener} from '@angular/core';
import { Http, Headers } from '@angular/http';
// import { getIp, Ip_with_img_address, country_code } from '../../../assets/js/ipaddress';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-apgarscore',
  templateUrl: './apgarscore.component.html',
  styleUrls: ['./apgarscore.component.scss']
})
export class ApgarscoreComponent implements OnInit {
public apgar_one_heart_rate;
public apgar_one_resp;
public apgar_one_muscle;
public apgar_one_reflex;
public apgar_one_color;
public apgar_score;
public apgarone_hidden:boolean;
public apgarfive_hidden:boolean;
public count_apgarone;
public count_apgarfive;

constructor(public http:Http, public el: ElementRef,public dialogRef: MatDialogRef<ApgarscoreComponent>,
  @Inject(MAT_DIALOG_DATA) public data: any) {    
    dialogRef.disableClose = true;
    this.apgar_score = data.Apgar;
    this.apgarone_hidden=true;
    this.apgarfive_hidden=true;
    this.apgar_one_heart_rate="No heart rate";
    this.apgar_one_resp="Not breathing";
    this.apgar_one_muscle="Limp";
    this.apgar_one_reflex="No response to airways being stimulated";
    this.apgar_one_color="The baby’s entire body is blue or pale";
  
  }

  ngOnInit(): void {
    if(this.apgar_score=="Apgarone"){
      this.apgarone_hidden=false;
      this.apgarfive_hidden=true;
    }
    else{
      this.apgarone_hidden=true;
      this.apgarfive_hidden=false;
    } 
  }

  @HostListener('window:click', ['$event'])
  onclick(event: any): void {
    if(this.apgar_score=="Apgarone"){
      this.Apgar_scoreoneclick();
    }
    else{
      this.Apgar_scorefiveclick();
    } 

  }

  @HostListener('window:blur', ['$event'])
  onBlur(event: any): void {
    if(this.apgar_score=="Apgarone"){
      this.Apgar_scoreoneclick();
    }
    else{
      this.Apgar_scorefiveclick();
    }   
  }


  Apgar_scoreoneclick(){
    var count = 0;
    var daad1 = this.apgar_one_heart_rate;
    if (daad1== "No heart rate") {
        count = count + 0;
    }
    else if (daad1== "Fewer than 100 beats per minute indicates that the baby is not very responsive") {
        count = count + 1;
    }
    else if (daad1 == "More than 100 beats per minute indicates that the baby is vigorous") {
        count = count + 2;
    }

    var daad2 = this.apgar_one_resp;
    if (daad2 =="Not breathing") {
        count = count + 0;
    }
    else if (daad2 == "Weak cry–may sound like whimpering or grunting") {
        count = count + 1;
    }
    else if (daad2=="Good, strong cry") {
        count = count + 2;
    }

    var daad3 = this.apgar_one_muscle;
    if (daad3 == "Limp") {
        count = count + 0;
    }
    else if (daad3 == "Some flexing (bending) of arms and legs") {
        count = count + 1;
    }
    else if (daad3== "Active motion") {
        count = count + 2;
    }

    var daad4 = this.apgar_one_reflex;
    if (daad4 == "No response to airways being stimulated") {
        count = count + 0;
    }
    else if (daad4== "Grimace during stimulation") {
        count = count + 1;
    }
    else if (daad4== "Grimace and cough or sneeze during stimulation") {
        count = count + 2;
    }

    var daad5 = this.apgar_one_color;
    if (daad5 == "The baby’s entire body is blue or pale") {
        count = count + 0;
    }
    else if (daad5 == "Good color in body but with blue hands or feet") {
        count = count + 1;
    }
    else if (daad5 == "Completely pink or good color") {
        count = count + 2;
    }
    this.count_apgarone = count;
    var apger_core_one_txt = document.getElementById("apger_core_one_min");
    // this.viewCtrl.dismiss(count);
  }

  Apgar_scorefiveclick(){
    var count = 0;
    var daad1 = this.apgar_one_heart_rate;
    if (daad1== "No heart rate") {
        count = count + 0;
    }
    else if (daad1== "Fewer than 100 beats per minute indicates that the baby is not very responsive") {
        count = count + 1;
    }
    else if (daad1 == "More than 100 beats per minute indicates that the baby is vigorous") {
        count = count + 2;
    }

    var daad2 = this.apgar_one_resp;
    if (daad2 =="Not breathing") {
        count = count + 0;
    }
    else if (daad2 == "Weak cry–may sound like whimpering or grunting") {
        count = count + 1;
    }
    else if (daad2=="Good, strong cry") {
        count = count + 2;
    }

    var daad3 = this.apgar_one_muscle;
    if (daad3 == "Limp") {
        count = count + 0;
    }
    else if (daad3 == "Some flexing (bending) of arms and legs") {
        count = count + 1;
    }
    else if (daad3== "Active motion") {
        count = count + 2;
    }

    var daad4 = this.apgar_one_reflex;
    if (daad4 == "No response to airways being stimulated") {
        count = count + 0;
    }
    else if (daad4== "Grimace during stimulation") {
        count = count + 1;
    }
    else if (daad4== "Grimace and cough or sneeze during stimulation") {
        count = count + 2;
    }

    var daad5 = this.apgar_one_color;
    if (daad5 == "The baby’s entire body is blue or pale") {
        count = count + 0;
    }
    else if (daad5 == "Good color in body but with blue hands or feet") {
        count = count + 1;
    }
    else if (daad5 == "Completely pink or good color") {
        count = count + 2;
    }
    this.count_apgarfive = count;

    var apger_core_one_txt = document.getElementById("apger_core_one_min");

    // this.viewCtrl.dismiss(count);
   
  }

  ClosePopup(){
    this.dialogRef.close();
  }

}
