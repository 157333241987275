<div class="row" style="width:100%;margin:0 auto;">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Assets</h5>
                    </div>


                    <div class="headerButtons">
                        <img style="float: right;" src="../../../assets/ui_icons/buttons/save_button.svg" 
                        class="saveimgbtn_inpatinfo" (click)="saveData()">
                        <button (click)="gotoassets()">Assets list</button>
                    
                    </div>

                </div>

            </mdb-card-header>
            <mdb-card-body>
                <div class="tablelist-responsive">
                </div>

                <div>
                    <div class="cover_div" style="padding-bottom: 13px;">
                        <div class="header_lable">Assets Details</div>
                        <div class="content_cover">
                            <div class="row">

                                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 input">
                                    <mat-label class="matlabel">Asset category<br>
                                        <mat-select id="paytype" class="ipcss  widthbillcreate" [(ngModel)]="assetscatid">
                                            <mat-option *ngFor="let assets of assetsarray" value={{assets.assetscatid}}>{{assets.assetscatName}}</mat-option>
                                        </mat-select>
                                    </mat-label>
                                </div>
                                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 input">
                                    <mat-label class="matlabel" style="margin-top: 7px;">Name<br>
                                        <input type="text" class="ipcss " [(ngModel)]="name" placeholder="name...." maxlength="25" matInput />
                                    </mat-label>
                                </div>

                                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 input">
                                    <mat-label class="matlabel" style="margin-top: 7px;">Id<br>
                                        <input type="text" class="ipcss " placeholder="id..." [(ngModel)]="id" maxlength="25" matInput />
                                    </mat-label>
                                </div>

                              

                                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 input">
                                    <mat-label class="matlabel">
                                        
                                        
                                        Acquisition Date
                                        
                               
                                        <input type="date" placeholder="" [(ngModel)]="acquisition_Date"  max="{{currentDate}}" class="ipcss_date noappt_width heightdob">
                                    </mat-label>
                                </div>

                                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 input">
                                    <mat-label class="matlabel" style="margin-top: 7px;">Pure price<br>
                                        <input type="text" placeholder="pure price..." [(ngModel)]="pureprice" class="ipcss " maxlength="25" matInput />
                                    </mat-label>
                                </div>

                                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 input">
                                    <mat-label class="matlabel" style="margin-top: 7px;">Depreciation method<br>
                                        <input type="text" class="ipcss" [(ngModel)]="depracation" placeholder="depracation method..." maxlength="25" matInput />
                                    </mat-label>
                                </div>

                                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 input">
                                    <mat-label class="matlabel" style="margin-top: 7px;">Depracation rate<br>
                                        <input type="text" class="ipcss" [(ngModel)]="depracationrate" placeholder="depracation rate..." maxlength="25" matInput />
                                    </mat-label>
                                </div>

                                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 input">
                                    <mat-label class="matlabel" style="margin-top: 7px;">Current book value<br>
                                        <input type="text" class="ipcss" [(ngModel)]="currentbook" placeholder="current book value..." maxlength="25" matInput />
                                    </mat-label>
                                </div>

                                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 input">


                                    <mat-label class="matlabel">Supplier/Vendor<br>
                    
                                        <input type="text" class="ipcss widthappt" required maxlength="50" (keyup)="getVendor()"
                                          maxlength="50" [(ngModel)]="vendor" matInput [matAutocomplete]="auto" />
                                        <mat-autocomplete #auto="matAutocomplete">
                                          <mat-option (click)="vendoridset(vendor)" *ngFor="let vendor of supplierData"
                                            value={{vendor.name}}>
                                            {{vendor.name}}
                                          </mat-option>
                                        </mat-autocomplete>
                                      
                                      </mat-label>

                                </div> 

                                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 input">
                                    <mat-label class="matlabel" style="margin-top: 7px;">Sino / Model<br>
                                        <input type="text" class="ipcss" [(ngModel)]="sino_model" placeholder="si no / Model" maxlength="25" matInput />
                                    </mat-label>
                                </div>

                                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 input">
                                    <mat-label class="matlabel" style="margin-top: 7px;">Status<br>
                                        <input type="text" class="ipcss" [(ngModel)]="status" placeholder="status" maxlength="25" matInput />
                                    </mat-label>
                                </div>

                                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 input">
                                    <mat-label class="matlabel" style="margin-top: 7px;">Notes<br>
                                        <input type="text" class="ipcss" [(ngModel)]="notes" placeholder="" maxlength="25" matInput />
                                    </mat-label>
                                </div>

                                <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 input">
                                    <mat-label class="matlabel" style="margin-top: 7px;">Maitation<br>
                                        <input type="text" class="ipcss" [(ngModel)]="maitation" placeholder="" maxlength="25" matInput />
                                    </mat-label>
                                </div> -->

                                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 input">
                                    <mat-label class="matlabel">maintanance_schedule type<br>
                                        <mat-select id="paytype" class="ipcss  widthbillcreate" [(ngModel)]="maintanance_schud">
                                            <mat-option *ngFor="let  maintance of maintancearray" value={{maintance.maintanceid}}>{{maintance.maintance_name}}</mat-option>
                                        </mat-select>
                                    </mat-label>
                                </div>

                                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 input">

                                    <mat-label class="matlabel">maintenance Due Date
                                        <input type="date" [(ngModel)]="maintduedate" value={{currentDate}} class="ipcss_date noappt_width heightdob">
                                    </mat-label>

                                </div>

                                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 input">

                                    <mat-label class="matlabel">Purchase rate
                                        <input type="text" [(ngModel)]="purchaseprice" class="ipcss">
                                    </mat-label>

                                </div>
                                
                                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 input">
                                    <mat-label class="matlabel">Disposal type<br>
                                        <mat-select id="paytype" class="ipcss  widthbillcreate" [(ngModel)]="disposaltypeid">
                                            <mat-option *ngFor="let disposal of disposalarray" value={{disposal.disposalid}}>{{disposal.disposalName}}</mat-option>
                                        </mat-select>
                                    </mat-label>
                                </div>


                                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 input">
                                    <mat-label class="matlabel" style="margin-top: 7px;">Dispose price<br>
                                        <input type="text" class="ipcss" [(ngModel)]="disposeprice" placeholder="" maxlength="25" matInput />
                                    </mat-label>
                                </div>

                                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 input">

                                    <mat-label class="matlabel">Disposition Date
                                        <input [(ngModel)]="disposition_date" type="date" max="{{currentDate}}" class="ipcss_date noappt_width heightdob">
                                    </mat-label>

                                </div>

                                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 input">
                                    <mat-label class="matlabel" style="margin-top: 7px;">Sale price<br>
                                        <input type="text" class="ipcss" [(ngModel)]="sales_price" placeholder="Sales price....." maxlength="25" matInput />
                                    </mat-label>
                                </div>

                                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 input">
                                    <mat-label class="matlabel" style="margin-top: 7px;">Dispose reason<br>
                                        <input type="text" class="ipcss" [(ngModel)]="disposereason" placeholder="" maxlength="25" matInput />
                                    </mat-label>
                                </div>




                                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 input">

                                    <mat-label class="matlabel">Created by
                                        <input type="date" max="{{currentDate}}" [(ngModel)]="created_by" class="ipcss_date noappt_width heightdob">
                                    </mat-label>

                                </div>

                                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 input" *ngIf="updateflag">Updated_by

                                    <mat-label class="matlabel">
                                        <input type="date" max="{{currentDate}}" [(ngModel)]="updated_by" class="ipcss_date noappt_width heightdob">
                                    </mat-label>

                                </div>

                               <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" style="position: relative;">
                                    <mat-label class="matlabel">
                                      

                                        
                                        <label class="closeoption" (change)="fileChange($event)" for="file"
                                        style="width: 100%;"><span class="excr_confirm matlabel"><i
                                            class="fa fa-upload" aria-hidden="true"></i> Upload image</span> </label>
                                      
                                        <input type="file" class="ipcss" [(ngModel)]="imagefile" (change)="fileChange($event)" name="filUpload" id="file" />

                                     
                                    </mat-label>
                                  </div> 

                                  <!-- <div *ngFor="let photo of photos"> -->
                                  <div *ngIf="assetspagechangeflag">
                                    <img id="selected_img" src={{assimage_name}} style="width: 100px;
                                    height: 100px;
                                    border-radius: 50%;
                                    border: 1px crimson;" />
                                  </div>
                                    <!-- </div> -->

                            </div>
                        </div>


                    </div>

                    <div class="row">
                    </div>
                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>