<div class="row" style="margin:0 auto; width:100%">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Book appointment</h5>
          </div>
          <div class="headerButtons">
            <img class="saveimgbtn_inpatinfo" (click)="createAppt()"
            src="../../../assets/ui_icons/buttons/Next_button.svg" />
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="">
          <div class="row">
            <div class="col-md-12 col-lg-12 col-sm-12 col-xl-12 " style="margin: 15px 0 15px 0;">
              <mat-radio-group class="inpat_radiogrp" [(ngModel)]="appType" color="primary" labelPosition="before"><b
                  style="vertical-align: text-bottom;" class="tervys_heading">Appointment Type &nbsp;&nbsp;&nbsp;&nbsp;:
                </b>&nbsp;&nbsp;
                <mat-radio-button value="New">New</mat-radio-button>&nbsp;&nbsp;
                <mat-radio-button name="apptype" value="Follow-up">&nbsp;&nbsp;Follow-up</mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 " *ngIf="!appointTypeFlag">
              <mat-label class="matlabel">Appointment For &nbsp;&nbsp;
                <mat-select required class="ipcss" name="relationship" [(ngModel)]="relationship" disableOptionCentering
                  (ngModelChange)="getSltRelationType($event)">
                  <mat-option *ngFor="let relation of relationList" value="{{relation.relationship_name}}">
                    {{relation.relationship_name}}</mat-option>
                </mat-select>
              </mat-label>
            </div>
            <div *ngIf="relativeDetailsFlag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
              <mat-label *ngIf="relativeDetailsFlag" class="matlabel">Relation name <br>
                <mat-select disableOptionCentering class="ipcss" required [(ngModel)]="relationName">
                  <mat-option disabled>Select</mat-option>
                  <mat-option *ngFor="let relation of otherRelationList" (click)="getSltRelationDetails(relation.name)"
                    value={{relation.name}}>
                    {{relation.name}}</mat-option>
                </mat-select>
              </mat-label>
            </div>
          </div>
          <div class="cover_div" style="padding-bottom: 13px;">
            <div class="header_lable">
              Personal details
            </div>
            <div class="content_cover">
              <div class="row">
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                  <mat-label class="matlabel">First name
                    <input class="ipcss widthappt" matInput type="text" [(ngModel)]="clntFirstName" />
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                  <mat-label class="matlabel">Middle Name<br>
                    <input type="text" class="ipcss inpat_width" [(ngModel)]="clntMiddleName" matInput />
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                  <mat-label class="matlabel">Last Name<br>
                    <input type="text" class="ipcss inpat_width" [(ngModel)]="clntLastName" required matInput />
                  </mat-label>
                </div>
                <div class="col-6  col-sm-6  col-md-3 col-lg-2 col-xl-2">
                  <mat-label class="matlabel"> Age (yrs)
                    <input class="ipcss widthappt" matInput type="text" [(ngModel)]="clntAge" />
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                  <mat-label class="matlabel">Blood group
                    <input class="ipcss widthappt" matInput type="text" [(ngModel)]="clntBloodGrp" />
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                  <mat-label class="matlabel">Gender
                    <input class="ipcss widthappt" matInput type="text" [(ngModel)]="clntGender" />
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                  <mat-label class="matlabel">Father / Guardian name
                    <input class="ipcss widthappt" matInput type="text" [(ngModel)]="clntFatherOrGuardName" maxlength="50" />
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                  <mat-label class="matlabel">Income
                    <input class="ipcss widthappt" matInput [(ngModel)]="clntIncome" maxlength="7" />
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                  <mat-label class="matlabel">Occupation
                    <input class="ipcss widthappt" matInput type="text" [(ngModel)]="clntOccupation" maxlength="25" />
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                  <mat-label class="matlabel">Contact number
                    <input class="ipcss widthappt" matInput type="text" [(ngModel)]="clntContactNo" maxlength="10" />
                  </mat-label>
                </div>
              </div>
            </div>
          </div>
          <div class="cover_div" style="padding-bottom: 13px;">
            <div class="header_lable">
              Address details
            </div>
            <div class="content_cover">
              <div class="row">
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                  <mat-label class="matlabel">Address1
                    <input class="ipcss widthappt" matInput type="text" [(ngModel)]="clntAddress1" />
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                  <mat-label class="matlabel"> Address2
                    <input class="ipcss widthappt" matInput type="text" [(ngModel)]="clntAddress2" />
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                  <mat-label class="matlabel">Country
                    <input class="ipcss widthappt" matInput [(ngModel)]="clntCountry" />
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                  <mat-label class="matlabel">State
                    <input class="ipcss widthappt" matInput type="text" [(ngModel)]="clntState" maxlength="25" />
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                  <mat-label class="matlabel">District/City
                    <input class="ipcss widthappt" matInput type="text" [(ngModel)]="clntCity" />
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                  <mat-label class="matlabel"> Location
                    <input class="ipcss widthappt" matInput type="text" [(ngModel)]="clntLocation" />
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                  <mat-label class="matlabel">Zip code
                    <input class="ipcss widthappt" matInput type="text" [(ngModel)]="clntZipcode" />
                  </mat-label>
                </div>
              </div>
            </div>
          </div>
          <div class="cover_div" style="padding-bottom: 13px;">
            <div class="header_lable">
              Appointment details
            </div>
            <div class="content_cover">
              <div class="row">
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                  <mat-label class="matlabel">Symptoms
                    <input class="ipcss widthappt" matInput type="text" [(ngModel)]="clntSymptom" />
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" *ngIf="doctorSearchFlag">
                  <mat-label class="matlabel">Treatment Type<br>
                    <mat-select required class="ipcss widthappt" [(ngModel)]="treatementType" disableOptionCentering>
                      <mat-option *ngFor="let treatement of treatmentList" value="{{treatement.medicare_name}}">
                        {{treatement.medicare_name}}</mat-option>
                    </mat-select>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 " *ngIf="doctorSearchFlag">
                  <mat-label class="matlabel">Speciality<br>
                    <mat-select required class="ipcss widthappt" [(ngModel)]="splName" required
                      disableOptionCentering>
                      <mat-option color="primary" *ngFor="let specialization of specializationList"
                        value="{{specialization.splty_name}}" (click)="selectSpeciality(specialization.splty_id,specialization.splty_name,true)">
                        {{specialization.splty_name}}
                      </mat-option>
                    </mat-select>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" *ngIf="doctorSearchFlag">
                  <mat-label class="matlabel"> Country<br>
                    <mat-select disableOptionCentering class="ipcss widthappt" [(ngModel)]="searchClntCountry"
                      (ngModelChange)="countryChange($event,'1')">
                      <mat-option disabled>Select</mat-option>
                      <mat-option *ngFor="let country of clntCountryList" value="{{country.description}}">
                        {{country.description}}</mat-option>
                    </mat-select>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" *ngIf="doctorSearchFlag">
                  <mat-label class="matlabel"> State<br>
                    <mat-select disableOptionCentering class="ipcss widthappt" required [(ngModel)]="searchClntState"
                      (ngModelChange)="stateChange($event,'1')">
                      <mat-option disabled>Select</mat-option>
                      <mat-option *ngFor="let state of clntStateList" value="{{state.description}}">
                        {{state.description}}
                      </mat-option>
                    </mat-select>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3"  *ngIf="doctorSearchFlag">
                  <mat-label class="matlabel"> City<br>
                    <mat-select disableOptionCentering class="ipcss widthappt" required [(ngModel)]="searchClntCity"
                      (ngModelChange)="cityChange($event, '1')">
                      <mat-option disabled>Select</mat-option>
                      <mat-option *ngFor="let city of clntCityList" value="{{city.description}}">{{city.description}}
                      </mat-option>
                    </mat-select>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3"  *ngIf="doctorSearchFlag">
                  <mat-label class="matlabel"> Location<br>
                    <input type="text" class="ipcss widthappt" required maxlength="50" (keyup)="locationChange($event)"
                      [(ngModel)]="clntLocation" matInput [matAutocomplete]="auto" />
                    <mat-autocomplete #auto="matAutocomplete">
                      <mat-option (click)="selectedLocation(location.description)"
                        *ngFor="let location of clntLocationList" value="{{location.description}}">
                        {{location.description}}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>