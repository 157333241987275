import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { Helper_Class } from '../../helper_class';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { customjs } from '../../../assets/js/custom';
import { Subscription } from 'rxjs';
import { ChangePasswordComponent } from '../../../app/common-module/change-password/change-password.component';
import { MatDialog } from '@angular/material/dialog';
import { ipaddress } from '../../ipaddress';
import { DietService } from './diet-view.service';
import { Diet_Helper } from '../Diet_Helper';
declare var $ : any; 
import { MenuViewService } from '../../common-module/menu-view/menu-view.service';

@Component({
  selector: 'app-diet-view',
  templateUrl: './diet-view.component.html',
  styleUrls: ['./diet-view.component.scss']
})
export class DietViewComponent implements OnInit {
  @ViewChild('asied_section_width', { static: false }) asied_width: ElementRef;
  @ViewChild('right_section_width', { static: false }) right_width: ElementRef;
  @ViewChild('logo', { static: false }) logo: ElementRef;
  subscription: Subscription;

  public hospitalLogo;
  public hospitalName;
  public userInfo;
  public profileImage;
  public firstName:string;
  public lastName:string;
  public settings:boolean = false;
  public show_submenu;
  public minimized_asied: boolean = false;
  public selectedMenu;
  public displayPage;
  public currentDate;
  public notificationCount;
  public notificationFlag: boolean = false;
  public reference_video: boolean = false;
  constructor(public gservice:CommonDataService,public http:Http,public routes:ActivatedRoute,public router:Router,public toastr:ToastrService,
    public messageservice:MenuViewService,public dialog:MatDialog) { 
      Diet_Helper.setDietFlow(undefined);
      Diet_Helper.setDietFlow('dietician');
  }

  ngOnInit(): void {
    this.subscription = this.messageservice.getMessage().subscribe(message => {
      if (message) {
          this.menuNav(message);
      } 
    });
    this.getCurrentDate();
    this.userInfo= Helper_Class.getInfo();
    this.firstName=this.userInfo.first_name;
    this.lastName=this.userInfo.last_name;
    this.profileImage= ipaddress.Ip_with_img_address + this.userInfo.profile_image;
    this.hospitalName=this.userInfo.hospitals[0].hptl_name;
    this.hospitalLogo= ipaddress.Ip_with_img_address + this.userInfo.hptl_logo;
    this.minimized_asied = false;
    var Moduleidlist;
    if (Helper_Class.getmodulelist() != null) {
       Moduleidlist = Helper_Class.getmodulelist();
       console.log("Moduleidlist" + JSON.stringify(Moduleidlist));
      for (var i = 0; i < Moduleidlist.length; i++) {
        if (Moduleidlist[i] == "46") { // reference video
          this.reference_video = false;
        }
      }
    }
    this.getNotificationList();   
  }

  getCurrentDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'adm/curdate',
      JSON.stringify({
        country: ipaddress.country_code.toString()
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.current_date != null) {
            this.currentDate = obj.current_date;
          }
        },
        error => {});
  }

  menuNav(page){
    let page_nmae : string = page;
    this.selectedMenu = page;
    switch (page_nmae) {
      
      case 'profile':  this.displayPage = page; break;
      case 'manageappointments':  this.displayPage = page; break;
      case 'dietPlan':  this.displayPage = page; break;
      case 'dietPlancard' : this.displayPage = page; break;
      case 'appointment' : this.displayPage = page; break;
      case 'appt_create': this.displayPage = page; break;
      case 'casesheet': this.displayPage = page; break;
      case 'notification': this.displayPage = page; break;
      case 'diet_dashboard':  this.displayPage = page; break;
      case 'patientList':  
      Helper_Class.setDiagReadingList(null);
      Helper_Class.setDiagReadingList("dietList");
      this.displayPage = page; break;
      case 'patientTracking':  this.displayPage = page; break;
      case 'billList': 
        var setFlow = {
          flow : 'dietcian',
          user_id :  this.userInfo.user_id
        }
        Helper_Class.setBillingFlow(null);
        Helper_Class.setBillingFlow(setFlow);
        this.displayPage = page;
      break;
      case 'billCreate':  this.displayPage = page; break;
      case 'billdetail':  this.displayPage = page; break;
      case 'readings': 
      Helper_Class.setDiagReadingList(null);
      Helper_Class.setDiagReadingList("readings");
      this.displayPage = page;
      break;
      case 'diabReadings':  this.displayPage = page; break;
      case 'reference_video':this.displayPage = page; break;
    }
    customjs.SelectMenuFun(this.selectedMenu,"diag");
  }
  
  getNotificationList() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'gen/nc',
    {
      type: "dietician",
      user_id: this.userInfo.user_id,
    },
      { headers: headers })
      .subscribe(
        response => {
          var obj =response.json()
            if (obj.notifications != null) {
              if (obj.notifications.length != 0) {
                this.notificationCount = obj.notifications;
                this.notificationFlag = true;
              }
              else{
                this.notificationCount = "0";
              }
            }
        },
        error => {});
  }
  
  logout(name) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'usercontroller/logout/',
      JSON.stringify({
        type: "front-desk",
        user: this.userInfo.user_id
      }),
      { headers: headers })
      .subscribe(
        response => {
          this.toastr.success("Logout successfully"+name)
          localStorage.clear();
          this.router.navigateByUrl('/loginpage');
        },
        error => {
        }
      )
  }

  menuModelAction(action){
    if(action == 'profile'){
      this.menuNav('profile');
    }else if(action == 'changePassword'){
      const dialogRef = this.dialog.open(ChangePasswordComponent, {
        width: '33%',
        height: '275px',
        data: {}
      });
    }else{
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'usercontroller/logout/',
        JSON.stringify({
          type: "front-desk",
          user: this.userInfo.user_id
        }),
        { headers: headers })
        .subscribe(
          response => {
            this.toastr.success("Logout successfully"+name)
            localStorage.clear();
            this.router.navigateByUrl('/loginpage');
          },
          error => {
          }
        )
    }
    $('#exampleModal').modal('hide'); 
  }

  minimizing(){
    this.minimized_asied = !this.minimized_asied;
    if(this.minimized_asied ==  true){
      this.asied_width.nativeElement.setAttribute('style','width:3%; transition: all 0.3s ease-in-out;animation-delay: 15s;');
      this.right_width.nativeElement.setAttribute('style','width:97%;margin-left: 3%; transition: all 0.3s ease-in-out;animation-delay: 15s;');
      this.logo.nativeElement.setAttribute('style','padding: 12px 0 0 3px;}');
    }else{
      this.asied_width.nativeElement.setAttribute('style','width:17%; transition: all 0.3s ease-in-out;animation-delay: 15s;');
      this.right_width.nativeElement.setAttribute('style','width:83%;margin-left: 17%; transition: all 0.3s ease-in-out;animation-delay: 15s;');
      this.logo.nativeElement.setAttribute('style','padding: 17px 0 0 14px;}');
    }
  }

  onResize(event){
    this.asied_width.nativeElement.removeAttribute('style');
    this.right_width.nativeElement.removeAttribute('style');
    this.logo.nativeElement.removeAttribute('style');
    this.minimized_asied = false;
  }
  
  openMenumodel(){
    this.minimized_asied = false;
    customjs.openMobileMenuFun();
  }
}
