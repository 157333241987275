<div class="row" style="margin:0 auto; width:100%">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Blood eligibility</h5>
          </div>
          <div class="headerButtons">
            <img *ngIf="saveFlag" src="../../../assets/ui_icons/buttons/save_button.svg"
              class="saveimgbtn_inpatinfo" (click)="save()">
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="">
          <div class="row">
            <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row_padding">
              <mat-card class="cardcontent">
                <mat-card-content>
                  <div class="row">
                    <div class="col-12">
                      <div class="row">
                        <div class="col-6">
                          <mat-label class="matlabel">HIV/AIDS</mat-label>
                        </div>
                        <div class="col-6">
                          <div class="switch-field" *ngIf="hivOpt != undefined">
                            <input type="radio" id="radio-three" (click)="changeHIv(1,'yes')" />
                            <label for="radio-three" [ngClass]="hivOpt ? 'radio_active':'radio_inactive'">Yes</label>
                            <input type="radio" id="radio-four" (click)="changeHIv(1,'no')" />
                            <label for="radio-four" [ngClass]="!hivOpt ? 'radio_active':'radio_inactive'">No</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="hivCuredFlag" radio-group class="radiomargin  col-12 bloodRadio_cover">
                      <div class="row">
                        <div class="col-6">
                          <mat-label class="matlabel widthappt">Cured</mat-label>
                        </div>
                        <div class="col-6">
                          <div class="switch-field">
                            <input type="radio" id="radio-one" value="yes" [(ngModel)]="hivCured" [checked]="hivDate"
                              (click)="getHivCured('yes')" />
                            <label for="radio-one" [ngClass]="hivDate ? 'radio_active':'radio_inactive'">Yes</label>
                            <input type="radio" id="radio-two" value="no" [(ngModel)]="hivCured" [checked]="!hivDate"
                              (click)="getHivCured('no')" />
                            <label for="radio-two" [ngClass]="!hivDate ? 'radio_active':'radio_inactive'">No</label>
                          </div>
                        </div>
                        <div class="col-12">
                          <div *ngIf="hivDate" class="radiomargin" style="margin-top: 10px;">
                            <div class="row">
                              <div class="col-md-6">
                                <mat-label class="matlabel">Date</mat-label>
                              </div>
                              <div class="col-6">
                                <input type="date" class="ipcss_date" (change)="selectDate(hivDateMod)"
                                  [(ngModel)]="hivDateMod" #matInput max="{{currentMaxDate}}">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row_padding">
              <mat-card class="cardcontent">
                <mat-card-content>
                  <div class="col-12">
                    <div class="row">
                      <div class="col-6">
                        <mat-label class="matlabel">Cancer</mat-label>
                      </div>
                      <div class="col-6">
                        <div class="switch-field" *ngIf="cancerOpt != undefined">
                          <input type="radio" id="radio-five" (click)="changeCancer(2,'yes')" />
                          <label for="radio-five" [ngClass]="cancerOpt ? 'radio_active':'radio_inactive'">Yes</label>
                          <input type="radio" id="radio-six" (click)="changeCancer(2,'no')" />
                          <label for="radio-six" [ngClass]="!cancerOpt ? 'radio_active':'radio_inactive'">No</label>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="cancerCuredFlag" radio-group class="radiomargin col-12" style="margin-top:10px;">
                      <div class="row">
                        <div class="col-6">
                          <mat-label class="matlabel widthappt">Cured</mat-label>
                        </div>
                        <div class="col-6">
                          <div class="switch-field">
                            <input type="radio" id="radio-seven" value="yes" [(ngModel)]="cancerCured"
                              (click)="getCancerCured('yes')" [checked]="cancerDate" />
                            <label for="radio-seven"
                              [ngClass]="cancerDate ? 'radio_active':'radio_inactive'">Yes</label>
                            <input type="radio" id="radio-eight" value="no" [(ngModel)]="cancerCured"
                              (click)="getCancerCured('no')" [checked]="cancerDate" />
                            <label for="radio-eight"
                              [ngClass]="!cancerDate ? 'radio_active':'radio_inactive'">No</label>
                          </div>
                        </div>
                        <div class="col-12">
                          <div *ngIf="cancerDate" class="radiomargin" style="margin-top: 10px;">
                            <div class="row">
                              <div class="col-6">
                                <mat-label class="matlabel">Date</mat-label>
                              </div>
                              <div class="col-6">
                                <input type="date" class="ipcss_date" (change)="selectDate(cancerDateMod)"
                                  [(ngModel)]="cancerDateMod" #matInput max="{{currentMaxDate}}">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row_padding">
              <mat-card class="cardcontent">
                <mat-card-content>
                  <div class="col-12">
                    <div class="row">
                      <div class="col-6">
                        <mat-label class="matlabel padding_right ">Asthma</mat-label>
                      </div>
                      <div class="col-6">
                        <div class="switch-field" *ngIf="asthmaOpt != undefined">
                          <input type="radio" id="radio-nine" [checked]="asthmaOpt" (click)="changeAsthma(2,'yes')" />
                          <label for="radio-nine" [ngClass]="asthmaOpt ? 'radio_active':'radio_inactive'">Yes</label>
                          <input type="radio" id="radio-ten" [checked]="!asthmaOpt" (click)="changeAsthma(2,'no')" />
                          <label for="radio-ten" [ngClass]="!asthmaOpt ? 'radio_active':'radio_inactive'">No</label>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="asthmaCuredFlag" radio-group class="col-12" style="margin-top: 10px;">
                      <div class="row">
                        <div class="col-6">
                          <mat-label class="matlabel widthappt">Under control</mat-label>
                        </div>
                        <div class="col-6">
                          <div class="switch-field">
                            <input type="radio" id="radio-eleven" value="yes" [(ngModel)]="asthma"
                              (click)="getAsthmaCured('yes')" [checked]="asthmaDate" />
                            <label for="radio-eleven"
                              [ngClass]="asthmaDate ? 'radio_active':'radio_inactive'">Yes</label>
                            <input type="radio" id="radio-twelve" value="no" [(ngModel)]="asthma"
                              (click)="getAsthmaCured('no')" [checked]="!asthmaDate" />
                            <label for="radio-twelve"
                              [ngClass]="!asthmaDate ? 'radio_active':'radio_inactive'">No</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row_padding">
              <mat-card class="cardcontent">
                <mat-card-content>
                  <div class="col-12">
                    <div class="row">
                      <div class="col-6">
                        <mat-label class="matlabel padding_right ">Blood transfusion</mat-label>
                      </div>
                      <div class="col-6">
                        <div class="switch-field" *ngIf="bloodTransOpt != undefined">
                          <input type="radio" id="radio-thirteen" (click)="changeBlood(4,'yes')" />
                          <label for="radio-thirteen" [ngClass]="bloodTransOpt ? 'radio_active':'radio_inactive'">Yes</label>
                          <input type="radio" id="radio-fourteen" (click)="changeBlood(4,'no')" />
                          <label for="radio-fourteen" [ngClass]="!bloodTransOpt ? 'radio_active':'radio_inactive'">No</label>
                        </div>
                      </div>
                      <div *ngIf="bloodDate" class="col-12" style="margin-top: 10px;">
                        <div class="row">
                          <div class="col-6">
                            <mat-label class="matlabel">Date</mat-label>
                          </div>
                          <div class="col-6"><input type="date" class="ipcss_date" (change)="selectDate(bloodDateMod)"
                              [(ngModel)]="bloodDateMod" #matInput max="{{currentMaxDate}}"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row_padding">
              <!--5-->
              <mat-card class="cardcontent">
                <mat-card-content>
                  <div class="col-12">
                    <div class="row">
                      <div class="col-6">
                        <mat-label class="matlabel padding_right ">Hepatitis B or C</mat-label>
                      </div>
                      <div class="col-6">
                        <div class="switch-field" *ngIf="heptisOpt != undefined">
                          <input type="radio" id="radio-fiveteen" [checked]="heptisOpt"
                            (click)="changeHepatitis(5,'yes')" />
                          <label for="radio-fiveteen" [ngClass]="heptisOpt ? 'radio_active':'radio_inactive'">Yes</label>
                          <input type="radio" id="radio-sixteen" [checked]="!heptisOpt"
                            (click)="changeHepatitis(5,'no')" />
                          <label for="radio-sixteen" [ngClass]="!heptisOpt ? 'radio_active':'radio_inactive'">No</label>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="heptisCuredFlag" radio-group class="radiomargin col-12" style="margin-top:10px;">
                      <div class="row">
                        <div class="col-6">
                          <mat-label class="matlabel widthappt">Cured</mat-label>
                        </div>
                        <div class="col-6">
                          <div class="switch-field">
                            <input type="radio" id="radio-seventeen" (click)="getHepatisCured('yes')" />
                            <!-- <input type="radio" id="radio-seventeen" [(ngModel)]="hepatis_cured"
                               /> -->
                            <label for="radio-seventeen"
                              [ngClass]="heptisDate ? 'radio_active':'radio_inactive'">Yes</label>
                            <input type="radio" id="radio-eightteen" [(ngModel)]="heptisCured"
                              (click)="getHepatisCured('no')" />
                            <label for="radio-eightteen"
                              [ngClass]="!heptisDate ? 'radio_active':'radio_inactive'">No</label>
                          </div>
                        </div>
                        <div class="col-12">
                          <div *ngIf="heptisDate" class="radiomargin" style="margin-top: 10px;">
                            <div class="row">
                              <div class="col-6">
                                <mat-label class="matlabel">Date</mat-label>
                              </div>
                              <div class="col-6">
                                <input type="date" class="ipcss_date" (change)="selectDate(hepatisDateMod)"
                                  [(ngModel)]="hepatisDateMod" #matInput max="{{currentMaxDate}}">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row_padding">
              <!-- 6 -->
              <mat-card class="cardcontent">
                <mat-card-content>
                  <div class="col-12">
                    <div class="row">
                      <div class="col-6">
                        <mat-label class="matlabel padding_right ">Malaria</mat-label>
                      </div>
                      <div class="col-6">                       
                        <div class="switch-field" *ngIf="malariaOpt != undefined">
                          <input type="radio" id="radio-fiveteen1" [checked]="malariaOpt" (click)="changeMalaria(6,'yes')" />
                          <label for="radio-fiveteen1" [ngClass]="malariaOpt ? 'radio_active':'radio_inactive'">Yes</label>
                          <input type="radio" id="radio-sixteen1" [checked]="!malariaOpt" (click)="changeMalaria(6,'no')" />
                          <label for="radio-sixteen1" [ngClass]="!malariaOpt ? 'radio_active':'radio_inactive'">No</label>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="malariaCuredFlag" radio-group class="radiomargin col-12" style="margin-top:10px;">
                      <div class="row">
                        <div class="col-6">
                          <mat-label class="matlabel widthappt">Malaria Cured</mat-label>
                        </div>
                        <div class="col-6">
                          <div class="switch-field">
                            <input type="radio" id="radio-nineteen" (click)="getMalariaCured('yes')"
                              [(ngModel)]="malariaCured" />
                            <label for="radio-nineteen"
                              [ngClass]="malariaDate ? 'radio_active':'radio_inactive'">Yes</label>
                            <input type="radio" id="radio-twenty" [(ngModel)]="malariaCured"
                              (click)="getMalariaCured('no')" />
                            <label for="radio-twenty"
                              [ngClass]="!malariaDate ? 'radio_active':'radio_inactive'">No</label>
                          </div>
                        </div>
                        <div class="col-12">
                          <div *ngIf="malariaDate" class="radiomargin" style="margin-top: 10px;">
                            <div class="row">
                              <div class="col-6">
                                <mat-label class="matlabel">Date</mat-label>
                              </div>
                              <div class="col-6">
                                <input type="date" class="ipcss_date" (change)="selectDate(malariaDateMod)"
                                  [(ngModel)]="malariaDateMod" #matInput max="{{currentMaxDate}}">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row_padding">
              <!-- 7 -->
              <mat-card class="cardcontent">
                <mat-card-content>
                  <div class="col-12">
                    <div class="row">
                      <div class="col-6">
                        <mat-label class="matlabel padding_right ">Kidney</mat-label>
                      </div>
                      <div class="col-6">
                        <div class="switch-field" *ngIf="kidneyOpt != undefined">
                          <input type="radio" id="radio-twentyone" [checked]="kidneyOpt" (click)="changeKidney(7,'yes')" />
                          <label for="radio-twentyone" [ngClass]="kidneyOpt ? 'radio_active':'radio_inactive'">Yes</label>
                          <input type="radio" id="radio-twentytwo" [checked]="!kidneyOpt" (click)="changeKidney(7,'no')" />
                          <label for="radio-twentytwo" [ngClass]="!kidneyOpt ? 'radio_active':'radio_inactive'">No</label>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="kidneyDiseaseFlag" radio-group class="col-12" style="margin-top:10px;">
                      <!-- <mat-label class="matlabel widthappt">Kidney disease</mat-label> -->
                      <div class="row">
                        <div class="col-6">
                          <mat-label class="matlabel">Kidney disease</mat-label>
                        </div>
                        <div class="col-6">
                          <mat-select class="ipcss" [(ngModel)]="KidneyDisease" disableOptionCentering>
                            <mat-option value="Stage 1" [selected]="'Stage 1'== KidneyDisease">Stage 1</mat-option>
                            <mat-option value="Stage 2" [selected]="'Stage 2' == KidneyDisease">Stage 2</mat-option>
                            <mat-option value="Stage 3" [selected]="'Stage 3' == KidneyDisease">Stage 3</mat-option>
                            <mat-option value="Stage 4" [selected]="'Stage 4'== KidneyDisease">Stage 4</mat-option>
                            <mat-option value="Stage 5" [selected]="'Stage 5' == KidneyDisease">Stage 5</mat-option>
                          </mat-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row_padding">
              <!-- 8 -->
              <mat-card class="cardcontent">
                <mat-card-content>
                  <div class="col-12">
                    <div class="row">
                      <div class="col-6">
                        <mat-label class="matlabel padding_right ">Heart disease</mat-label>
                      </div>
                      <div class="col-6">
                        <div class="switch-field" *ngIf="heartDisOpt != undefined">
                          <input type="radio" id="radio-twentythree" [checked]="heartDisOpt"
                            (click)="changeHeart(8,'yes')" />
                          <label for="radio-twentythree"
                            [ngClass]="heartDisOpt ? 'radio_active':'radio_inactive'">Yes</label>
                          <input type="radio" id="radio-twentyfour" [checked]="!heartDisOpt" (click)="changeHeart(8,'no')" />
                          <label for="radio-twentyfour" [ngClass]="!heartDisOpt ? 'radio_active':'radio_inactive'">No</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row_padding">
              <!--9-->
              <mat-card class="cardcontent">
                <mat-card-content>
                  <div class="col-12">
                    <div class="row">
                      <div class="col-6">
                        <mat-label class="matlabel padding_right ">Epilepsy</mat-label>
                      </div>
                      <div class="col-6">
                        <div class="switch-field" *ngIf="epilepsyOpt != undefined">
                          <input type="radio" id="radio-twentyseveen" [checked]="epilepsyOpt"
                            (click)="changeEpilepsy(9,'yes')" />
                          <label for="radio-twentyseveen"
                            [ngClass]="epilepsyOpt ? 'radio_active':'radio_inactive'">Yes</label>
                          <input type="radio" id="radio-twentyeight" [checked]="!epilepsyOpt"
                            (click)="changeEpilepsy(9,'no')" />
                          <label for="radio-twentyeight"
                            [ngClass]="!epilepsyOpt ? 'radio_active':'radio_inactive'">No</label>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="seizurFreeFlag" radio-group class="radiomargin col-12" style="margin-top:10px;">
                      <div class="row">
                        <div class="col-6">
                          <mat-label class="matlabel widthappt">Seizur free</mat-label>
                        </div>
                        <div class="col-6">
                          <div class="switch-field">
                            <input type="radio" id="radio-twentynine" (click)="getEpilepsyCured('yes')"
                              [(ngModel)]="seizurFree" [checked]="seizurFreeDate" />
                            <label for="radio-twentynine"
                              [ngClass]="seizurFreeDate ? 'radio_active':'radio_inactive'">Yes</label>
                            <input type="radio" id="radio-thirdty" [(ngModel)]="seizurFree"
                              (click)="getEpilepsyCured('no')" [checked]="seizurFreeDate" />
                            <label for="radio-thirdty"
                              [ngClass]="!seizurFreeDate ? 'radio_active':'radio_inactive'">No</label>
                          </div>
                        </div>
                        <div class="col-12">
                          <div *ngIf="seizurFreeDate" class="radiomargin" style="margin-top: 10px;">
                            <div class="row">
                              <div class="col-6">
                                <mat-label class="matlabel">Date</mat-label>
                              </div>
                              <div class="col-6">
                                <input type="date" class="ipcss_date" (change)="selectDate(seizurFreeDateMod)"
                                  [(ngModel)]="seizurFreeDateMod" #matInput max="{{currentMaxDate}}">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row_padding">
              <!--10-->
              <mat-card class="cardcontent">
                <mat-card-content>
                  <div class="col-12">
                    <div class="row">
                      <div class="col-6 p-0">
                        <mat-label class="matlabel padding_right ">Cold,Flu,Sore, Throat</mat-label>
                      </div>
                      <div class="col-6">
                        <div class="switch-field" *ngIf="coldFluOpt != undefined">
                          <input type="radio" id="radio-thirtyone" [checked]="coldFluOpt" (click)="changeCold(10,'yes')" />
                          <label for="radio-thirtyone" [ngClass]="coldFluOpt ? 'radio_active':'radio_inactive'">Yes</label>
                          <input type="radio" id="radio-thirtytwo" [checked]="!coldFluOpt" (click)="changeCold(10,'no')" />
                          <label for="radio-thirtytwo" [ngClass]="!coldFluOpt ? 'radio_active':'radio_inactive'">No</label>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="curedFlag" radio-group class="radiomargin col-12" style="margin-top:10px;">
                      <div class="row">
                        <div class="col-6">
                          <mat-label class="matlabel widthappt">Cured</mat-label>
                        </div>
                        <div class="col-6">
                          <div class="switch-field">
                            <input type="radio" id="radio-thirtythree" (click)="getColdCured('yes')"
                              [(ngModel)]="cured" [checked]="curedDate" />
                            <label for="radio-thirtythree"
                              [ngClass]="curedDate ? 'radio_active':'radio_inactive'">Yes</label>
                            <input type="radio" id="radio-thirtyfour" [(ngModel)]="cured"
                              (click)="getColdCured('no')" [checked]="curedDate" />
                            <label for="radio-thirtyfour"
                              [ngClass]="!curedDate ? 'radio_active':'radio_inactive'">No</label>
                          </div>
                        </div>
                        <div class="col-12">
                          <div *ngIf="curedDate" class="radiomargin" style="margin-top: 10px;">
                            <div class="row">
                              <div class="col-6">
                                <mat-label class="matlabel">Date</mat-label>
                              </div>
                              <div class="col-6">
                                <input type="date" class="ipcss_date" (change)="selectDate(curedDateMod)"
                                  [(ngModel)]="curedDateMod" #matInput max="{{currentMaxDate}}">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row_padding">
              <!--11 bp-->
              <mat-card class="cardcontent">
                <mat-card-content>
                  <div class="col-12">
                    <div class="row">
                      <div class="col-6">
                        <mat-label class="matlabel padding_right ">Blood pressure</mat-label>
                      </div>
                      <div class='col-6'>
                        <div class="switch-field" *ngIf="bpOpt != undefined">
                          <input type="radio" id="radio-thirtyfive" [checked]="bpOpt" (click)="changeBp(11,'yes')" />
                          <label for="radio-thirtyfive"
                            [ngClass]="bpOpt ? 'radio_active':'radio_inactive'">Yes</label>
                          <input type="radio" id="radio-thirtysix" [checked]="!bpOpt" (click)="changeBp(11,'no')" />
                          <label for="radio-thirtysix" [ngClass]="!bpOpt ? 'radio_active':'radio_inactive'">No</label>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="bpFlag" radio-group class="col-12" style="margin-top: 10px;">
                      <div class="row">
                        <div class="col-6">
                          <mat-label class="matlabel widthappt">Under control</mat-label>
                        </div>
                        <div class="col-6">
                          <div class="switch-field">
                            <input type="radio" id="radio-thirtyseven" (click)="getBpCured('yes')"
                              [(ngModel)]="bp" [checked]="bpflage" />
                            <label for="radio-thirtyseven"
                              [ngClass]="bpflage ? 'radio_active':'radio_inactive'">Yes</label>
                            <input type="radio" id="radio-thirtyeight" [(ngModel)]="bp"
                              (click)="getBpCured('no')" [checked]="bpflage" />
                            <label for="radio-thirtyeight"
                              [ngClass]="!bpflage ? 'radio_active':'radio_inactive'">No</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row_padding">
              <!--12 Tb-->
              <mat-card class="cardcontent">
                <mat-card-content>
                  <div class="col-12">
                    <div class="row">
                      <div class="col-6">
                        <mat-label class="matlabel padding_right ">Tuberculosis</mat-label>
                      </div>
                      <div class="col-6">
                        <div class="switch-field" *ngIf="tbOpt != undefined">
                          <input type="radio" id="radio-thirtynine" [checked]="tbOpt" (click)="changeTb(12,'yes')" />
                          <label for="radio-thirtynine"
                            [ngClass]="tbOpt ? 'radio_active':'radio_inactive'">Yes</label>
                          <input type="radio" id="radio-fourty" [checked]="!tbOpt" (click)="changeTb(12,'no')" />
                          <label for="radio-fourty" [ngClass]="!tbOpt ? 'radio_active':'radio_inactive'">No</label>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="tbCuredFlag" radio-group class="radiomargin col-12" style="margin-top:10px;">
                      <div class="row">
                        <div class="col-6">
                          <mat-label class="matlabel widthappt">Cured</mat-label>
                        </div>
                        <div class="col-6">
                          <div class="switch-field">
                            <input type="radio" id="radio-fourtyone" (click)="getTbCured('yes')" [(ngModel)]="tbCured"
                              [checked]="tbControl" />
                            <label for="radio-fourtyone"
                              [ngClass]="tbControl ? 'radio_active':'radio_inactive'">Yes</label>
                            <input type="radio" id="radio-fourtytwo" [(ngModel)]="tbCured" (click)="getTbCured('no')"
                              [checked]="tbControl" />
                            <label for="radio-fourtytwo"
                              [ngClass]="!tbControl ? 'radio_active':'radio_inactive'">No</label>
                          </div>
                        </div>
                        <div class="col-12">
                          <div *ngIf="tbControl" class="radiomargin" style="margin-top: 10px;">
                            <div class="row">
                              <div class="col-6">
                                <mat-label class="matlabel">Date</mat-label>
                              </div>
                              <div class="col-6">
                                <input type="date" class="ipcss_date" (change)="selectDate(tbDateMod)"
                                  [(ngModel)]="tbDateMod" #matInput max="{{currentMaxDate}}">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row_padding">
              <!--13-->
              <mat-card class="cardcontent">
                <mat-card-content>
                  <div class="col-12">
                    <div class="row">
                      <div class="col-6">
                        <mat-label class="matlabel padding_right ">Teeth cleaning</mat-label>
                      </div>
                      <div class="col-6">
                        <div class="switch-field" *ngIf="teethOpt != undefined">
                          <input type="radio" id="radio-foutythree" [checked]="teethOpt"
                            (click)="changeTeeth(13,'yes')" />
                          <label for="radio-foutythree"
                            [ngClass]="teethOpt ? 'radio_active':'radio_inactive'">Yes</label>
                          <input type="radio" id="radio-foutyfour" [checked]="!teethOpt"
                            (click)="changeTeeth(13,'no')" />
                          <label for="radio-foutyfour" [ngClass]="!teethOpt ? 'radio_active':'radio_inactive'">No</label>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="teethInfectedFlag" radio-group class="radiomargin col-12" style="margin-top:10px;">
                      <div class="row">
                        <div class="col-6">
                          <mat-label class="matlabel widthappt">Infected</mat-label>
                        </div>
                        <div class="col-6">
                          <div class="switch-field">
                            <input type="radio" id="radio-fourtyfive" (click)="getTeethCured('yes')"
                              [(ngModel)]="teethInfected" [checked]="teethControl" />
                            <label for="radio-fourtyfive"
                              [ngClass]="teethControl ? 'radio_active':'radio_inactive'">Yes</label>
                            <input type="radio" id="radio-fourtysix" [(ngModel)]="teethInfected"
                              (click)="getTeethCured('no')" [checked]="teethControl" />
                            <label for="radio-fourtysix"
                              [ngClass]="!teethControl ? 'radio_active':'radio_inactive'">No</label>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="radiomargin" style="margin-top: 10px;">
                            <div class="row">
                              <div class="col-6">
                                <mat-label class="matlabel">Date</mat-label>
                              </div>
                              <div class="col-6">
                                <input type="date" class="ipcss_date" (change)="selectDate(teethDateMod)"
                                  [(ngModel)]="teethDateMod" #matInput max="{{currentMaxDate}}">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row_padding">
              <!--14-->
              <mat-card class="cardcontent">
                <mat-card-content>
                  <div class="col-12">
                    <div class="row">
                      <div class="col-6">
                        <mat-label class="matlabel padding_right ">Root canal</mat-label>
                      </div>
                      <div class="col-6">
                        <div class="switch-field" *ngIf="rootOpt != undefined">
                          <input type="radio" id="radio-fourtyseven" [checked]="rootOpt"
                            (click)="changeRoot(14,'yes')" />
                          <label for="radio-fourtyseven"
                            [ngClass]="rootOpt ? 'radio_active':'radio_inactive'">Yes</label>
                          <input type="radio" id="radio-foutyeight" [checked]="!rootOpt"
                            (click)="changeRoot(14,'no')" />
                          <label for="radio-foutyeight"
                            [ngClass]="!rootOpt ? 'radio_active':'radio_inactive'">No</label>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="rootInfectedFlag" radio-group class="radiomargin col-12" style="margin-top:10px;">
                      <div class="row">
                        <div class="col-6">
                          <mat-label class="matlabel widthappt">Infected</mat-label>
                        </div>
                        <div class="col-6">
                          <div class="switch-field">
                            <input type="radio" id="radio-fourtynine" (click)="getRootCured('yes')"
                              [(ngModel)]="rootInfected" [checked]="rootDate" />
                            <label for="radio-fourtynine"
                              [ngClass]="rootDate ? 'radio_active':'radio_inactive'">Yes</label>
                            <input type="radio" id="radio-fivety" [(ngModel)]="rootInfected"
                              (click)="getRootCured('no')" [checked]="rootDate" />
                            <label for="radio-fivety"
                              [ngClass]="!rootDate ? 'radio_active':'radio_inactive'">No</label>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="radiomargin" style="margin-top: 10px;">
                            <div class="row">
                              <div class="col-6">
                                <mat-label class="matlabel">Date</mat-label>
                              </div>
                              <div class="col-6">
                                <input type="date" class="ipcss_date" (change)="selectDate(rootDateMod)"
                                  [(ngModel)]="rootDateMod" #matInput max="{{currentMaxDate}}">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row_padding">
              <!--15-->
              <mat-card class="cardcontent">
                <mat-card-content>
                  <div class="col-12">
                    <div class="row">
                      <div class="col-6">
                        <mat-label class="matlabel padding_right ">Scaling</mat-label>
                      </div>
                      <div class="col-6">
                        <div class="switch-field" *ngIf="scaleOpt != undefined">
                          <input type="radio" id="radio-fivetyone" [checked]="scaleOpt"
                            (click)="changeScale(15,'yes')" />
                          <label for="radio-fivetyone" [ngClass]="scaleOpt ? 'radio_active':'radio_inactive'">Yes</label>
                          <input type="radio" id="radio-fivetytwo" [checked]="!scaleOpt"
                            (click)="changeScale(15,'no')" />
                          <label for="radio-fivetytwo" [ngClass]="!scaleOpt ? 'radio_active':'radio_inactive'">No</label>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="scaleInfectedFlag" radio-group class="radiomargin col-12" style="margin-top:10px;">
                      <div class="row">
                        <div class="col-6">
                          <mat-label class="matlabel widthappt">Infected</mat-label>
                        </div>
                        <div class="col-6">
                          <div class="switch-field">
                            <input type="radio" id="radio-fivetythree" (click)="getScaleCured('yes')"
                              [(ngModel)]="scaleInfected" [checked]="scaleDate" />
                            <label for="radio-fivetythree"
                              [ngClass]="scaleDate ? 'radio_active':'radio_inactive'">Yes</label>
                            <input type="radio" id="radio-fivetyfour" [(ngModel)]="scaleInfected"
                              (click)="getScaleCured('no')" [checked]="scaleDate" />
                            <label for="radio-fivetyfour"
                              [ngClass]="!scaleDate ? 'radio_active':'radio_inactive'">No</label>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="radiomargin" style="margin-top: 10px;">
                            <div class="row">
                              <div class="col-6">
                                <mat-label class="matlabel">Date</mat-label>
                              </div>
                              <div class="col-6">
                                <input type="date" class="ipcss_date" (change)="selectDate(scaleDateMod)"
                                  [(ngModel)]="scaleDateMod" #matInput max="{{currentMaxDate}}">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row_padding">
              <!--16-->
              <mat-card class="cardcontent">
                <mat-card-content>
                  <div class="col-12">
                    <div class="row">
                      <div class="col-6">
                        <mat-label class="matlabel padding_right ">Filling</mat-label>
                      </div>
                      <div class="col-6">
                        <div class="switch-field" *ngIf="fillOpt != undefined">
                          <input type="radio" id="radio-fivetyfive" [checked]="fillOpt"
                            (click)="changeFill(16,'yes')" />
                          <label for="radio-fivetyfive"
                            [ngClass]="fillOpt ? 'radio_active':'radio_inactive'">Yes</label>
                          <input type="radio" id="radio-fivetysix" [checked]="!fillOpt" (click)="changeFill(16,'no')" />
                          <label for="radio-fivetysix" [ngClass]="!fillOpt ? 'radio_active':'radio_inactive'">No</label>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="fillInfectedFlag" radio-group class="radiomargin col-12" style="margin-top:10px;">
                      <div class="row">
                        <div class="col-6">
                          <mat-label class="matlabel widthappt">Infected</mat-label>
                        </div>
                        <div class="col-6">
                          <div class="switch-field">
                            <input type="radio" id="radio-fivetyseven" (click)="getFillCured('yes')"
                              [(ngModel)]="fillInfected" [checked]="fillDate" />
                            <label for="radio-fivetyseven"
                              [ngClass]="fillDate ? 'radio_active':'radio_inactive'">Yes</label>
                            <input type="radio" id="radio-fivetyeight" [(ngModel)]="fillInfected"
                              (click)="getFillCured('no')" [checked]="fillDate" />
                            <label for="radio-fivetyeight"
                              [ngClass]="!fillDate ? 'radio_active':'radio_inactive'">No</label>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="radiomargin" style="margin-top: 10px;">
                            <div class="row">
                              <div class="col-6">
                                <mat-label class="matlabel">Date</mat-label>
                              </div>
                              <div class="col-6">
                                <input type="date" class="ipcss_date" (change)="selectDate(fillDateMod)"
                                  [(ngModel)]="fillDateMod" #matInput max="{{currentMaxDate}}">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row_padding">
              <!--17-->
              <mat-card class="cardcontent">
                <mat-card-content>
                  <div class="col-12">
                    <div class="row">
                      <div class="col-6">
                        <mat-label class="matlabel padding_right ">Tooth extraction</mat-label>
                      </div>
                      <div class="col-6">
                        <div class="switch-field" *ngIf="toothOpt != undefined">
                          <input type="radio" id="radio-fivetnine" [checked]="toothOpt"
                            (click)="changeTooth(17,'yes')" />
                          <label for="radio-fivetnine" [ngClass]="toothOpt ? 'radio_active':'radio_inactive'">Yes</label>
                          <input type="radio" id="radio-sixty" [checked]="!toothOpt" (click)="changeTooth(17,'no')" />
                          <label for="radio-sixty" [ngClass]="!toothOpt ? 'radio_active':'radio_inactive'">No</label>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="toothInfectedFlag" radio-group class="radiomargin col-12" style="margin-top:10px;">
                      <div class="row">
                        <div class="col-6">
                          <mat-label class="matlabel widthappt">Infected</mat-label>
                        </div>
                        <div class="col-6">
                          <div class="switch-field">
                            <input type="radio" id="radio-sixtyone" (click)="getToothCured('yes')"
                              [(ngModel)]="toothInfected" [checked]="toothDate" />
                            <label for="radio-sixtyone"
                              [ngClass]="toothDate ? 'radio_active':'radio_inactive'">Yes</label>
                            <input type="radio" id="radio-sixtytwo" [(ngModel)]="toothInfected"
                              (click)="getToothCured('no')" [checked]="toothDate" />
                            <label for="radio-sixtytwo"
                              [ngClass]="!toothDate ? 'radio_active':'radio_inactive'">No</label>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="radiomargin" style="margin-top: 10px;">
                            <div class="row">
                              <div class="col-6">
                                <mat-label class="matlabel">Date</mat-label>
                              </div>
                              <div class="col-6">
                                <input type="date" class="ipcss_date" (change)="selectDate(toothDateMod)"
                                  [(ngModel)]="toothDateMod" #matInput max="{{currentMaxDate}}">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>