<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Stock edit</h5>
          </div>
          <div class="headerButtons">
            <img *ngIf="editbutton" src="../../../assets/ui_icons/buttons/update_button.svg" (click)="save_data()" [disabled]="isDisabled"
              class="saveimgbtn_inpatinfo" />
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row">
          <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel" style="margin-top: 7px;">Location<br>
              <select disableOptionCentering class="ipcss " [(ngModel)]="location">
                <option disabled>Select</option>
                <option (click)="get_stock(pro_type.pharma_facility_id)" *ngFor="let pro_type of loadLocationList"
                  value={{pro_type.location_id}}>{{pro_type.location_id}}</option>
              </select>
            </mat-label>
          </div> -->

          <div class="col-12" style="margin-bottom:15px;">
            <div class="row">
              <div class="col-3">
                  <input type="text" class="ipcss order_width getMedcien" maxlength="50"
                   maxlength="50" [(ngModel)]="patientName" matInput (keyup)="getdatausingname(patientName)"
                  />

              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-6 col-xl-6">
                <mat-label class="matlabel"><br>
                  <!-- <img src="../../../assets/ui_icons/buttons/XL_export.svg" class="saveimgbtn_inpatinfo"
                    (click)="exportToExcel()" style="    position: relative;
                    bottom: 17px !important;"/> -->
                </mat-label>
              </div>
              <div class="col-2" style="margin-left:10px !important">
                <mat-label class="matlabel"style="position: relative;
                bottom: 17px !important;" >Per page<br>
                  <select [(ngModel)]="pagelist" class="ipcss" (change)="changePageList()">
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="full">Full</option>
                  </select>
                </mat-label>
              </div>
            </div>
          </div>

          <div class="col-12 stock_status_tbl" *ngIf="stock.length != 0" style="margin-top:10px">
            
            <!-- <table *ngIf="stock.length" mdbTable datatable [dtOptions]="dtOptions"
              class="table table-nowrap table-sm dataTable">
              <thead>
                <tr>
                  <th style="width: 30%;">Name</th>
                  <th style="width: 20%;">Supplier</th>
                  <th style="width: 15%;">Batch</th>
                  <th style="text-align: left !important;width: 10%;">Receive qty</th>
                  <th style="width: 10%;">Free qty</th>
                  <th >MRP</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let stock of stock">
                  <td class="align_left">{{stock.short_name}} - {{stock.name}}</td>
                  <td class="align_left">{{stock.supplier}}</td>
                  <td class="align_left">
                    <input class="ipcss" matInput text-right maxlength="10" style="margin:0"
                      [(ngModel)]="stock.batch_no" style="text-align:right;" />
                  </td>
                  <td>
                    <input class="ipcss" matInput text-right maxlength="10" style="margin:0"
                      [(ngModel)]="stock.receive_qty" style="text-align:right;" />
                  </td>
                  <td class="align_left">
                  
                    <input class="ipcss" matInput text-right maxlength="10" style="margin:0"
                      [(ngModel)]="stock.free_qty" style="text-align:right;" />
                  </td>
                  <td class="align_left">
                    <input class="ipcss" matInput text-right maxlength="10" style="margin:0" [(ngModel)]="stock.mrp"
                      style="text-align:right;" />
                  </td>
                </tr>
              </tbody>
            </table> -->
            <table  class="table-fixed outline_setup rounded-3" >
              <thead style="background-color: #c1e6fb;
              color: rgba(0, 0, 0, .54);">
                <tr>
                  <th style="width: 30%;">Name</th>
                  <th style="width: 20%;">Supplier</th>
                  <th style="width: 15%;">Batch</th>
                  <th style="text-align: left !important;width: 10%;">Receive qty</th>
                  <th style="width: 10%;">Free qty</th>
                  <th >MRP</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let stock of stock">
                  <td class="align_left">{{stock.short_name}} - {{stock.name}}</td>
                  <td class="align_left">{{stock.supplier}}</td>
                  <td class="align_left">
                    <input class="ipcss" matInput text-right maxlength="10" style="margin:0"
                      [(ngModel)]="stock.batch_no" style="text-align:right;" />
                  </td>
                  <td>
                    <input class="ipcss" matInput text-right maxlength="10" style="margin:0"
                      [(ngModel)]="stock.receive_qty" style="text-align:right;" />
                  </td>
                  <td class="align_left">
                  
                    <input class="ipcss" matInput text-right maxlength="10" style="margin:0"
                      [(ngModel)]="stock.free_qty" style="text-align:right;" />
                  </td>
                  <td class="align_left">
                    <input class="ipcss" matInput text-right maxlength="10" style="margin:0" [(ngModel)]="stock.mrp"
                      style="text-align:right;" />
                  </td>
                </tr>
              </tbody>
            </table>

          


          </div>
          <div *ngIf="stock != undefined && stock.length !=0">
            <div class="row" style="text-align: center;margin-top: 5px;">
                <div class="col-3 col-sm-3 col-md-4 col-lg-4 col-xl-4 select_bottom"> </div>
                <div class="col-3 col-sm-3 col-md-1 col-lg-1 col-xl-1 select_bottom" >
                <input id="Text1" type="image" [disabled]="prevPageCount" class="pag_images" (click)="pageLimitPrevious()"
                    src="../../../assets/ui_icons/arrow_blue.svg" />
                </div>
                <div class="col-3 col-sm-3 col-md-1 col-lg-1 col-xl-1 select_bottom">
                <span class="current_font">{{currentPage}}/{{numberOfPages()||1}}</span>
                </div>
    
                <div class="col-3 col-sm-3 col-md-1 col-lg-1 col-xl-1 select_bottom">
                <input id="Image1" type="image" [disabled]="nextPageCount" class="pag_images" (click)="pageLimitnext()"
                    src="../../../assets/ui_icons/arrow_blue r.svg" />
                </div>                     
            </div>
        </div> 
          <div class="col-12" *ngIf="stock.length == 0">
            <p class="nodata">No data found</p>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>