import { Component, OnInit ,ElementRef,Input,Inject,ViewEncapsulation,HostListener} from '@angular/core';
import { Http, Headers } from '@angular/http';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ipaddress } from '../../ipaddress' ;
@Component({
  selector: 'app-view-medpres',
  templateUrl: './view-medpres.component.html',
  styleUrls: ['./view-medpres.component.scss']
})
export class ViewMedpresComponent implements OnInit {
  public viewpreslist_array:any=[];
  public patid: string;
  private relid:string;
  private subrelid:string;

  constructor(public http:Http, public el: ElementRef,public dialogRef: MatDialogRef<ViewMedpresComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {    
      dialogRef.disableClose = true;
      this.patid = data.client_id;
      this.relid = data.relation_id;
      this.subrelid = data.sub_rel_id;
   }

  ngOnInit(): void {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'prescription/mpbyclntid/',
      JSON.stringify({
        client_id: this.patid,
        relation_id:this.relid,
        sub_rel_id:this.subrelid,
        country:ipaddress.country_code,
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          
          if (obj.prescriptions != null) {
            var spl_view_med_preslist = obj.prescriptions;
            if (spl_view_med_preslist.length != 0) {
              for (var i = 0; i < spl_view_med_preslist.length; i++) {
                var med_format = null;
                var dateview = spl_view_med_preslist[i].date.split('-');
                var medicines:any =[];

                for (var j = 0; j < spl_view_med_preslist[i].drug_list.length; j++) {
                  var drugintakevalue;
                  var shortform = "";
                  shortform = spl_view_med_preslist[i].drug_list[j].short_form;
                  if(spl_view_med_preslist[i].drug_list[j].show_short_form =="1"){
                    
                    if(spl_view_med_preslist[i].drug_list[j].intake =="1"){
                      drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+shortform+"-0-0";

                    } else if(spl_view_med_preslist[i].drug_list[j].intake =="2"){
                      drugintakevalue="0 - "+spl_view_med_preslist[i].drug_list[j].afternoon+shortform+" - 0";

                    } else if(spl_view_med_preslist[i].drug_list[j].intake =="3"){
                      drugintakevalue="0 - 0 - "+spl_view_med_preslist[i].drug_list[j].night+shortform;

                    } else if(spl_view_med_preslist[i].drug_list[j].intake =="4"){
                      drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+shortform+" - "+spl_view_med_preslist[i].drug_list[j].afternoon+shortform+" - 0";

                    } else if(spl_view_med_preslist[i].drug_list[j].intake =="5"){
                      drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+shortform+" - 0 - "+spl_view_med_preslist[i].drug_list[j].night+shortform;

                    } else if(spl_view_med_preslist[i].drug_list[j].intake =="6"){
                      drugintakevalue="0 - "+spl_view_med_preslist[i].drug_list[j].afternoon+shortform+" - "+spl_view_med_preslist[i].drug_list[j].night+shortform;

                    } else if(spl_view_med_preslist[i].drug_list[j].intake =="7"){
                      drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+shortform+' - '+spl_view_med_preslist[i].drug_list[j].afternoon+shortform+' - '+spl_view_med_preslist[i].drug_list[j].night+shortform;
                    }else if(spl_view_med_preslist[i].drug_list[j].intake =="8"){
                        drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+spl_view_med_preslist[i].drug_list[j].short_form+" - "+spl_view_med_preslist[i].drug_list[j].afternoon+spl_view_med_preslist[i].drug_list[j].short_form+" - "+spl_view_med_preslist[i].drug_list[j].evening+spl_view_med_preslist[i].drug_list[j].short_form+" - "+spl_view_med_preslist[i].drug_list[j].night+spl_view_med_preslist[i].drug_list[j].short_form;

                    } else if(spl_view_med_preslist[i].drug_list[j].intake =="9"){
                      drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+spl_view_med_preslist[i].drug_list[j].short_form+" - Every 6 hours";

                    } else if(spl_view_med_preslist[i].drug_list[j].intake =="10"){
                      drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+spl_view_med_preslist[i].drug_list[j].short_form+" - Every 8 hours";

                    } else if(spl_view_med_preslist[i].drug_list[j].intake =="11"){
                      drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+spl_view_med_preslist[i].drug_list[j].short_form+" - Every 12 hours ";

                    } else if(spl_view_med_preslist[i].drug_list[j].intake =="12"){
                      drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+spl_view_med_preslist[i].drug_list[j].short_form+" - Once a day";

                    } else if(spl_view_med_preslist[i].drug_list[j].intake =="13"){
                      drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+spl_view_med_preslist[i].drug_list[j].short_form+" SOS - if required";

                    } else if(spl_view_med_preslist[i].drug_list[j].intake =="14"){
                      drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+spl_view_med_preslist[i].drug_list[j].short_form+" - "+spl_view_med_preslist[i].drug_list[j].afternoon+spl_view_med_preslist[i].drug_list[j].short_form+" - "+spl_view_med_preslist[i].drug_list[j].evening+spl_view_med_preslist[i].drug_list[j].short_form+" - "+spl_view_med_preslist[i].drug_list[j].night+spl_view_med_preslist[i].drug_list[j].short_form +" Alternative days";

                    } else if(spl_view_med_preslist[i].drug_list[j].intake =="15"){
                      drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+spl_view_med_preslist[i].drug_list[j].short_form+" - "+spl_view_med_preslist[i].drug_list[j].afternoon+spl_view_med_preslist[i].drug_list[j].short_form+" - "+spl_view_med_preslist[i].drug_list[j].evening+spl_view_med_preslist[i].drug_list[j].short_form+" - "+spl_view_med_preslist[i].drug_list[j].night+spl_view_med_preslist[i].drug_list[j].short_form +" Once a week";

                    } else if(spl_view_med_preslist[i].drug_list[j].intake =="16"){
                      drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+spl_view_med_preslist[i].drug_list[j].short_form+" - "+spl_view_med_preslist[i].drug_list[j].afternoon+spl_view_med_preslist[i].drug_list[j].short_form+" - "+spl_view_med_preslist[i].drug_list[j].evening+spl_view_med_preslist[i].drug_list[j].short_form+" - "+spl_view_med_preslist[i].drug_list[j].night+spl_view_med_preslist[i].drug_list[j].short_form +" Once in two weeks";

                    } else if(spl_view_med_preslist[i].drug_list[j].intake =="17"){
                      drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+ shortform +" STAT";
                    } 

                    if (spl_view_med_preslist[i].drug_list[j].time_duration != null && spl_view_med_preslist[i].drug_list[j].time_duration != undefined) {
                      med_format = drugintakevalue + "  -  " + spl_view_med_preslist[i].drug_list[j].time_duration + " mins " + spl_view_med_preslist[i].drug_list[j].drug_intake;

                    } else {
                      med_format = drugintakevalue + "  -  " + spl_view_med_preslist[i].drug_list[j].drug_intake;
                    }

                  } else {
                    if(spl_view_med_preslist[i].drug_list[j].intake =="1"){
                      drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+"-0-0";

                    } else if(spl_view_med_preslist[i].drug_list[j].intake =="2"){
                      drugintakevalue="0 - "+spl_view_med_preslist[i].drug_list[j].afternoon+" - 0";

                    } else if(spl_view_med_preslist[i].drug_list[j].intake =="3"){
                      drugintakevalue="0 - 0 - "+spl_view_med_preslist[i].drug_list[j].night;

                    } else if(spl_view_med_preslist[i].drug_list[j].intake =="4"){
                      drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+" - "+spl_view_med_preslist[i].drug_list[j].afternoon+" - 0";

                    } else if(spl_view_med_preslist[i].drug_list[j].intake =="5"){
                      drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+" - 0 - "+spl_view_med_preslist[i].drug_list[j].night;

                    } else if(spl_view_med_preslist[i].drug_list[j].intake =="6"){
                      drugintakevalue="0 - "+spl_view_med_preslist[i].drug_list[j].afternoon+" - "+spl_view_med_preslist[i].drug_list[j].night;

                    } else if(spl_view_med_preslist[i].drug_list[j].intake =="7"){
                      drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+' - '+spl_view_med_preslist[i].drug_list[j].afternoon+' - '+spl_view_med_preslist[i].drug_list[j].night;
                    }else if(spl_view_med_preslist[i].drug_list[j].intake =="8"){
                      drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+" - "+spl_view_med_preslist[i].drug_list[j].afternoon+" - "+spl_view_med_preslist[i].drug_list[j].evening+" - "+spl_view_med_preslist[i].drug_list[j].night;

                    } else if(spl_view_med_preslist[i].drug_list[j].intake =="9"){
                      drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+" - Every 6 hours";

                    } else if(spl_view_med_preslist[i].drug_list[j].intake =="10"){
                      drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+" - Every 8 hours";

                    } else if(spl_view_med_preslist[i].drug_list[j].intake =="11"){
                      drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+" - Every 12 hours";

                    } else if(spl_view_med_preslist[i].drug_list[j].intake =="12"){
                      drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+" - Once a day";

                    } else if(spl_view_med_preslist[i].drug_list[j].intake =="13"){
                      drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+" SOS - if required";

                    } else if(spl_view_med_preslist[i].drug_list[j].intake =="14"){
                      drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+" - "+spl_view_med_preslist[i].drug_list[j].afternoon+" - "+spl_view_med_preslist[i].drug_list[j].evening+" - "+spl_view_med_preslist[i].drug_list[j].night +" Alternative days";

                    } else if(spl_view_med_preslist[i].drug_list[j].intake =="15"){
                      drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+" - "+spl_view_med_preslist[i].drug_list[j].afternoon+" - "+spl_view_med_preslist[i].drug_list[j].evening+" - "+spl_view_med_preslist[i].drug_list[j].night +" Once a week";

                    } else if(spl_view_med_preslist[i].drug_list[j].intake =="16"){
                      drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+" - "+spl_view_med_preslist[i].drug_list[j].afternoon+" - "+spl_view_med_preslist[i].drug_list[j].evening+" - "+spl_view_med_preslist[i].drug_list[j].night +" Once in two weeks";

                    } else if(spl_view_med_preslist[i].drug_list[j].intake =="17"){
                      drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+shortform+" STAT";
                    }  

                    if (spl_view_med_preslist[i].drug_list[j].time_duration != "" || spl_view_med_preslist[i].drug_list[j].time_duration != undefined) {
                      med_format = drugintakevalue + "  -  " + spl_view_med_preslist[i].drug_list[j].time_duration + " mins " + spl_view_med_preslist[i].drug_list[j].drug_intake;

                    } else {
                      med_format = drugintakevalue + "    " + spl_view_med_preslist[i].drug_list[j].drug_intake;
                    }
                  }

                  medicines.push({
                    drug: spl_view_med_preslist[i].drug_list[j].short_name + "  " + spl_view_med_preslist[i].drug_list[j].drug_name + " " + spl_view_med_preslist[i].drug_list[j].days + " "+spl_view_med_preslist[i].drug_list[j].period,
                    catagory: med_format
                  })
                 }

                var docname;
                if (spl_view_med_preslist[i].middle_name != undefined && spl_view_med_preslist[i].middle_name != null) {
                  docname = "Dr. " + spl_view_med_preslist[i].first_name + " " + spl_view_med_preslist[i].middle_name + " " + spl_view_med_preslist[i].last_name

                } else {
                  docname = "Dr. " + spl_view_med_preslist[i].first_name + " " + spl_view_med_preslist[i].last_name
                }

                this.viewpreslist_array.push({
                  tot_doct_name: docname,
                  pres_drug_id: spl_view_med_preslist[i].pres_drug_id,
                  view_date: "Date :" + dateview[2] + '-' + dateview[1] + '-' + dateview[0],
                  medicines: medicines,
                });
              }
            }
          }
        },
        error => {

        }
      )  
  }

  ClosePopup(){
    this.dialogRef.close();
  }

}
