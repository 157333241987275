import { Component, OnInit } from '@angular/core';
import { Helper_Class } from '../../helper_class';
import { ToastrService } from 'ngx-toastr';
import { ipaddress } from 'src/app/ipaddress';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-masters',
  templateUrl: './masters.component.html',
  styleUrls: ['./masters.component.scss']
})
export class MastersComponent implements OnInit, FormsModule {
  usergettype: any[] = [];
  public DiagnosticDarray = [];
  public CultureSensitivearray = [];
  public culturepathologyinputname: any;

  public Accountheadarray: any[] = [];
  public DiagnosticDinputname: string = '';
  public diaag;
  public masterType;
  public masterurl;
  UserInfo: any;
  masterpageheading: string;
  sensitive_drug_type_id: any;
  DiagnosticRarraytype: any;
  public DiagnosticRarray = [];
  public DiagnosticRinputname: string = '';
  public Accountheadinputname: string = '';
  public sensitive_drug_id: string;
  sensitive_drug_types: any;
  sendatab: any;
  culture_sensitive_drug_id: any;
  center_type: any;
  hospitalidfromadmin: any;
  public centerid: any;
  idcenter: any;
  role_id: any;
  user_type_id: any;
  culture_pathogen_id: any;
  culture_pathogen_desc: any;
  culturepathogendesc: any;
  description: any;
  typedescription: any;
  public pharma_id: any;
  reagent_type_desc: any;
  DiagnostictRarray: any;
  testtube_data: any[];
  diagnosisapp_id: any;
  tube_color_data: any = [];
  user_type: string;
  provider_type: any;
  diag_centre_id: any;
  provider_id: any;
  provtype: any;
  stock_adj_reason_id: any;
  store_names_id: any;
  selectedProvider: any;
  account_subhead_id: any;
  SAAinputname: any;
  provider: string;
  diag_dept_id: any;
  department_data: any;
  test_category_data: any[];
  diag_test_categ: any;
  test_category: any[];
  subdepartarray: any[] = [];
  Addtemparray: any[] = [];
  descsubdepartinput: string;
  Odersubdepartinput: string;
  diag_test_cat_id: number;
  Odersubdepart: any;
  DepartmentID;
  department_id: any;
  desctemplateinput: any;
  template_id: any;
  Gram_Stains_array: any[] = [];
  GramStainsinputname: null;
  gram_stain_id: any;
  sensitiveinputname: any;
  all_sensitive_drugs: any[] = [];
  allsensitiveRname: any;
  // usertypelist: any[] = [];
  tempModules: any[] = [];
  packageid: any;
  getUserrolearrays: any[] = [];
  type_desc: any;
  type_id: number;
  userList: any[];
  userid: any;
  user_role_id: any;
  Cultureeditbuttondie: boolean = false;
  Culturenewbuttondie: boolean = false;
  Culturedeletebuttondie: boolean = false;
  Cultureprintbuttondie: boolean = false;
  Cultureviewbuttondie: boolean = false;
  pathologyeditbuttondie: boolean = false;
  pathologynewbuttondie: boolean = false;
  pathologydeletebuttondie: boolean = false;
  pathologyprintbuttondie: boolean = false;
  pathologyviewbuttondie: boolean = false;
  getUsserrolearrayall: any = [];
  // imageBase64: string;
  CultureSensitivename: any;
  billcredittypesarray: any = [];
  billcredittypesname: any;
  bill_credits_id: any;
  specimenaccessionerarray: any[];
  accessioner_id: any;
  diag_assertion_id: any;
  assertion_desc: any;
  public default;
  center_typer: any;
  center_t: any;
  product_type_id: any;
  constructor(public http: HttpClient, public toastr: ToastrService,) { }
  editorStyle = {
    height: '220px',
    width: '350px'
  };
  modules = {};
  range = '';
  UserId: any;
  center_id: any;
  public userId;
  hptl_clinic_id: any;
  public usertype;
  public provtypeArray: any;
  selectedUserType: any;
  public StoreNamearray: any[] = [];
  StoreNameinputname: any;
  CodeNameinputname: any;

  public reagent_type_id: string = '';
  public diag_reagent_id: string;
  public unityinputname;
  public sampletinputname;
  public iteminputname;
  public itemforminputname;
  public pharmainputname;
  public manufactinputname;
  public specimen_type_id;
  public diag_department_id;
  public tube_type_id;
  public samplearray = [];
  public samplet = [];
  public itemarray = [];
  public pharmaarray = [];
  public manufactarray = [];
  public itemformarray = [];
  public DiagnosticDinputnamearry = [];
  public samplelistarray = [];
  public samplelistinputname;
  public Diagnostictarray = [];
  public Diagnostictinputname;
  public DiagnostictypeRarray = [];
  public color_code;
  public Accountsubheadarray: any[] = [];
  public SAAarray: any[] = [];
  public Accountsubheadinputname: string = '';
  public store_desc: string = '';
  public diagId;
  public Subdepartmentarry: any[] = [];
  public subdepartRarray: any[] = [];
  public genericinputname;
  public scheduleinputname;
  public generic_id;
  public schedule_type_id;
  public item_form_id;
  public rate_card_desc: any;
  public rate_card_id: any;
  public discount: any;
  public RateCardarray = [];
  public genericarray = [];
  public schedulearray = [];
  public Visit_Purpose_array = [];
  public VisitPurposeinputname: string = '';
  public diagCentreId: any;
  public userinfo: any;
  public Visit_purpose: any;
  public visit_purpose_id: any;
  public purposeType: any;
  public visit_purpose_name: string = 'diatician';
  public unselected: boolean = false;
  public newbutton: boolean = false
  public editbutton: boolean = false;
  public deletebutton: boolean = false;
  public printbutton: boolean = false;
  public viewbutton: boolean = false;
  public moduleList: any = [];
  public tube_size: string = '15mm';
  selectedUserRoleForEdit: any;
  selectedUserTypeIsSelected: boolean = false;
  public CultureSensitiveList: any;
  isSuccess: boolean = false;
  public pharmaid;

  // selectedUserTypeId: '1';
  public selectedUserTypeid: any;
  public selectedUserTypeId: any;
  selectedRoleId: number;
  // public Erase:boolean = false;
  //added
  public surgery_id: any;
  public surgery_data: any = [];
  public descProductsInput: any;
  public productList: any[];
  public productsQuantityInput: any;
  public productsarray: any = [];
  public product_id: any;
  public surgery_equipment_id: any;
  public AccessionerNameinputname: any;
  public editFLAG: boolean = false;
  private selected_id;
  public discounttypeflag:boolean;
  public discount_typePR:string;
  public center_typePR:string;

  ngOnInit(): void {
    this.changeProviderType();

    this.UserInfo = Helper_Class.getInfo();
    this.moduleList = Helper_Class.getmodulelist();
    this.centerid = Helper_Class.getInfo().hptl_clinic_id;

    var sendatatab;
    this.masterType = Helper_Class.getReportType();
    this.center_typePR ="hospital";
    this.discounttypeflag = true;
    if (this.masterType == 'CultureSensitivityDrugGroup') {
      this.masterpageheading = " Culture Sensitive Drug Group"
      this.getCultureSensitivedata();

    } else if (this.masterType == 'CultureSensitivityDrug') {
      this.masterpageheading = "Sensitive Drug";
      var sendatab = {
        diag_centre_id: this.diaag
      }
      this.Culturemapdata(sendatab),
        this.CultureNametypedata(),
        this.updateSendatab()

    } else if (this.masterType == 'ADmaster') {
      this.masterpageheading = "Add Role";
      var sendataaaa = {
        diag_centre_id: this.diaag
      }
      this.getuserdpt();

    } else if (this.masterType == 'culturepathology') {
      this.masterpageheading = "Culture Pathology";
      var sendatab = {
        diag_centre_id: this.diaag
      }
      this.pathologydata()
    }

    if (this.masterType == 'sampletype') {
      this.masterpageheading = "Specimen Type";
      var sendata = {
        diag_centre_id: this.diaag
      }
      this.samplelistdata(sendata);
      this.getDiagTesttubes();

    } else if (this.masterType == 'DiagnosticD') {
      this.masterpageheading = "Diagnostic Department";
      var sendataaa = {
        diag_centre_id: this.diaag
      }
      this.DiagnosticDdata(sendataaa);

    } else if (this.masterType == 'Diagnostict') {
      this.masterpageheading = "Diagnostic Tubes";
      var sendataaaa = {
        diag_centre_id: this.diaag
      }
      this.Diagnostictdata(sendataaaa);

    } else if (this.masterType == 'DiagnosticR') {
      this.masterpageheading = "Diagnostic Reagents";
      var sendatab = {
        diag_centre_id: this.diaag
      }
      this.DiagnosticRdata(sendatab),
        this.DiagnosticRtypedata()

    } else if (this.masterType == 'stockar') {
      this.masterpageheading = "Stock Adjustment Reasons";
      this.stockAdjustmentAccountdata();

    } else if (this.masterType == 'StoreName') {
      this.masterpageheading = "Store Name";
      this.StoreNamedata();

    } else if (this.masterType == 'GM') {
      this.masterpageheading = "Generic Master"
      sendatatab = {
        pharma_id: this.pharma_id
      }
      this.genericdata(sendatatab)

    } else if (this.masterType == 'SM') {
      this.masterpageheading = "Schedule"
      sendatatab = {
        pharma_id: this.pharma_id
      }
      this.scheduledata(sendatatab)

    } else if (this.masterType == 'IFM') {
      this.masterpageheading = "Item Form "
      sendatatab = {
        pharma_id: this.pharma_id
      }
      this.itemformdata(sendatatab)

    } else if (this.masterType == 'Sub-Deparment') {
      this.masterpageheading = "Sub Deparment";
      var sendatab = {
        diag_centre_id: this.diaag
      }
      this.getDiagDepartment(sendatab);

    } else if (this.masterType == 'Addtemplate') {
      this.masterpageheading = "Add Template";
      var sendatab = {
        diag_centre_id: this.diaag
      }
      this.gettemplate(sendatab);

    } else if (this.masterType == 'Gramstains') {
      this.masterpageheading = "Grams tains";
      this.GramStainsdata();

    } else if (this.masterType == 'RateCard') {
      this.masterpageheading = "Rate Card";
      this.getRateCard();

    } else if (this.masterType == 'Visitpurpose') {
      this.masterpageheading = "Visit purpose";
      this.updateSendatabVP();

    } else if (this.masterType == 'CultureSensitivityDrugName') {
      this.masterpageheading = "Culture Sensitivity Drug Name";
      this.CultureRtypedata();

    } else if (this.masterType == 'UserRole') {
      this.masterpageheading = "User Role";
      this.getUserTypes();
      this.getUserRolesMapping();

    } else if (this.masterType == 'Surgery_Equipments') {
      this.masterpageheading = "Surgery Equipments";
      this.getSurgeryDepartment();

    } else if (this.masterType == 'billcredittypes') {
      this.masterpageheading = "bill Credit Types";
      this.getbillcredittypes();

    } else if (this.masterType == 'specimenaccessioner') {
      this.masterpageheading = "Specimen assertion";
      this.specimenaccessionerdata();

    } else {
      this.masterpageheading = "errror"
    }

    if (this.moduleList != undefined) {
      for (var i = 0; i < this.moduleList.length; i++) {
        if (this.moduleList[i].module_id == "26" && this.masterType == 'CultureSensitivityDrugGroup' ||
          this.moduleList[i].module_id == "133" && this.masterType == "ADmaster" ||
          this.moduleList[i].module_id == "28" && this.masterType == "culturepathology" ||
          this.moduleList[i].module_id == "29" && this.masterType == "sampletype" ||
          // this.moduleList[i].module_id == "29" && this.masterType == "DiagnosticD" ||
          this.moduleList[i].module_id == "30" && this.masterType == "Diagnostict" ||
          this.moduleList[i].module_id == "31" && this.masterType == "DiagnosticR" ||
          this.moduleList[i].module_id == "32" && this.masterType == "stockar" ||
          this.moduleList[i].module_id == "33" && this.masterType == "StoreName" ||
          this.moduleList[i].module_id == "34" && this.masterType == "GM" ||
          this.moduleList[i].module_id == "35" && this.masterType == "SM" ||
          this.moduleList[i].module_id == "36" && this.masterType == "IFM" ||
          this.moduleList[i].module_id == "140" && this.masterType == "Addtemplate" ||
          this.moduleList[i].module_id == "141" && this.masterType == "Gramstains" ||
          this.moduleList[i].module_id == "142" && this.masterType == "RateCard" ||
          this.moduleList[i].module_id == "146" && this.masterType == "Visitpurpose" ||
          this.moduleList[i].module_id == "134" && this.masterType == "CultureSensitivityDrugName" ||
          this.moduleList[i].module_id == "29" && this.masterType == "UserRole" ||
          this.moduleList[i].module_id == "149" && this.masterType == "Surgery_Equipments" ||
          this.moduleList[i].module_id == "150" && this.masterType == "billcredittypes" ||
          this.moduleList[i].module_id == "148" && this.masterType == "specimenaccessioner") {
          console.log("this is full module", JSON.stringify(this.moduleList[i]));
          if (this.moduleList[i].edit == "1") {
            this.editbutton = true;//update button
          }
          if (this.moduleList[i].create == "1") {
            this.newbutton = true;
          }
          if (this.moduleList[i].delete == "1") {
            this.deletebutton = true;
          }
          if (this.moduleList[i].print == "1") {
            this.printbutton = true;
          }
          if (this.moduleList[i].view == "1") {
            this.viewbutton = true;
          }
        }
      }
    }
  }

  //note: please don't remove commend

  // changeProviderType() {
  //   `  ` info = Helper_Class.getInfo();
  //   const userType = info.user_type;
  //   const hospitals = info.hospitals;
  //   switch (userType) {
  //     case 'Admin':
  //       this.setProviderValues(info, 'Admin', true, true, true, true, true);
  //       break;
  //     case 'front-desk':
  //       this.setProviderValues(info, 'front-desk');
  //       break;
  //     case 'doctor':
  //     case 'pharmacy':
  //     case 'diagnosis':
  //     case 'physio':
  //     case 'nurse':
  //     case 'dietician':
  //       this.setProviderValues(hospitals[0], userType);
  //       break;

  //     default:
  //       console.log("Access restricted, contact administrator");
  //   }
  // }

  // setProviderValues(source, type, newButton = false, editButton = false, deleteButton = false, printButton = false, viewButton = false) {
  //   this.centerid = source.hptl_clinic_id;
  //   this.diaag = source.diag_centre_id;
  //   this.pharma_id = source.pharma_id;
  //   this.packageid = Helper_Class.getInfo().hptl_package;
  //   this.provtypeArray = type;
  //   this.newbutton = newButton;
  //   this.editbutton = editButton;
  //   this.deletebutton = deleteButton;
  //   this.printbutton = printButton;
  //   this.viewbutton = viewButton;
  // }
  changeProviderType() {
    if (Helper_Class.getInfo().user_type == 'Admin') {
      this.centerid = Helper_Class.getInfo().hptl_clinic_id;
      this.diaag = Helper_Class.getInfo().diag_centre_id;
      this.pharma_id = Helper_Class.getInfo().pharma_id;
      this.packageid = Helper_Class.getInfo().hptl_package;
      this.provtypeArray = "hospital";
      this.newbutton = true;
      this.editbutton = true;
      this.deletebutton = true;
      this.printbutton = true;
      this.viewbutton = true;

    } else if (Helper_Class.getInfo().user_type == "front-desk") {
      this.centerid = Helper_Class.getInfo().hptl_clinic_id;
      this.diaag = Helper_Class.getInfo().diag_centre_id;
      this.pharma_id = Helper_Class.getInfo().pharma_id;
      this.packageid = Helper_Class.getInfo().hptl_package;
      this.provtypeArray = "hospital"

    } else if (Helper_Class.getInfo().user_type == "doctor") {
      this.centerid = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.diaag = Helper_Class.getInfo().hospitals[0].diag_centre_id;
      this.pharma_id = Helper_Class.getInfo().hospitals[0].pharma_id;
      this.packageid = Helper_Class.getInfo().hptl_package;
      this.provtypeArray = "hospital";

    } else if (Helper_Class.getInfo().user_type == "pharmacy") {
      this.centerid = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.diaag = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.pharma_id = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.packageid = Helper_Class.getInfo().hptl_package;
      this.provtypeArray = "pharmacy";

    } else if (Helper_Class.getInfo().user_type == "diagnosis") {
      this.centerid = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.diaag = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.pharma_id = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.packageid = Helper_Class.getInfo().hptl_package;
      this.provtypeArray = "diagnosis";

    } else if (Helper_Class.getInfo().user_type == "physio") {
      this.centerid = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.diaag = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.pharma_id = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.packageid = Helper_Class.getInfo().hptl_package;
      this.provtypeArray = "physio";

    } else if (Helper_Class.getInfo().user_type == "nurse") {
      this.centerid = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.diaag = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.pharma_id = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.packageid = Helper_Class.getInfo().hptl_package;
      this.provtypeArray = "hospital";

    } else if (Helper_Class.getInfo().user_type == "dietician") {
      this.centerid = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.diaag = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.pharma_id = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.packageid = Helper_Class.getInfo().hptl_package;
      this.provtypeArray = "hospital";

    } else {
      this.toastr.error("Access restricted, contact administrator");
    }
  }

  // Culture Sensitive Drug Group
  getCultureSensitivedata() {
    var sendata = {
      diag_centre_id: this.diaag
    };

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'mas/gdcsdt', sendata,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          var CultureSensitive = obj.sensitive_drug_types;
          for (var i = 0; i < CultureSensitive.length; i++) {
            this.CultureSensitivearray.push({
              CultureSensitiveList: CultureSensitive[i].sensitive_drug_type_desc,
              sensitive_drug_type_id: CultureSensitive[i].sensitive_drug_type_id,
              status: CultureSensitive[i].status
            })
          }
        },
        error => {
          this.toastr.error('error')
        }
      )
  }

  addCulture(sensitive_drug_type_id, CultureSensitiveList, data) {
    var flag = false
    var datatobackend
    if (data == 'Erase') {
      datatobackend = {
        sensitive_drug_type_desc: CultureSensitiveList,
        sensitive_drug_type_id: sensitive_drug_type_id,
        diag_centre_id: this.diaag,
        status: "0"
      }
    }

    if (this.CultureSensitivename != undefined) {
      if (this.CultureSensitivearray.length != 0) {
        for (let i = 0; i < this.CultureSensitivearray.length; i++) {
          if (this.CultureSensitivearray[i].CultureSensitiveList == this.CultureSensitivename) {
            this.toastr.error(" already exists");
            flag = true;
            break;
          }
        }
      }
      datatobackend
      if (flag == false && data == 'save') {
        datatobackend = {
          sensitive_drug_type_desc: this.CultureSensitivename,
          sensitive_drug_type_id: this.sensitive_drug_type_id,
          diag_centre_id: this.diaag,
          status: "1"
        }
      }
    }
    console.log("datatobackend", datatobackend);
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'mas/scsdt', datatobackend,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.toastr.success('saved')
          var sendataa = {
            diag_centre_id: this.diaag
          }
          if (this.masterType == 'CultureSensitivityDrugGroup') {
            this.getCultureSensitivedata()
          }
          else {
            this.toastr.error('error')
          }
        }, error => {
          this.toastr.error('error');
        })

    this.CultureSensitivearray = [];
    this.sensitive_drug_type_id=undefined;
    this.CultureSensitivename = null;
  }

  editCultureDetails(CultureSensitiveList, sensitive_drug_type_id) {
    this.sensitive_drug_type_id = sensitive_drug_type_id;
    for (var i = 0; i < this.CultureSensitivearray.length; i++) {
      if (this.CultureSensitivearray[i].CultureSensitiveList == CultureSensitiveList) {
        this.CultureSensitivename = this.CultureSensitivearray[i].CultureSensitiveList;
        this.CultureSensitivearray.splice(i, 1);
      }
    }
  }
  // Culture Sensitive Drug Group with Name
  CultureNametypedata() {
    this.DiagnosticDarray = [];
    const sendata = {
      diag_centre_id: this.diaag
    };
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    this.http.post(ipaddress.getIp.toString() + 'mas/gdcsdt', sendata, { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          var DiagnosticD = obj.sensitive_drug_types;

          for (var i = 0; i < DiagnosticD.length; i++) {
            this.DiagnosticDarray.push({
              DiagnosticDname: DiagnosticD[i].sensitive_drug_type_desc,
              sensitive_drug_type_id: DiagnosticD[i].sensitive_drug_type_id,
            })
          }
          this.sensitive_drug_type_id = this.DiagnosticDarray[0].sensitive_drug_type_id
          this.updateSendatab();
        },
        error => {
          this.toastr.error('error from group fetch')
        }
      );
  }
  updateSendatab() {
    this.all_sensitive_drugs = [];
    this.sendatab = {
      sensitive_drug_type_id: this.sensitive_drug_type_id,
      diag_centre_id: this.diaag
    };
    this.Culturemapdata(this.sendatab);
  }
  Culturemapdata(sendatab) {
    this.DiagnosticRarray = [];
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    this.http.post(ipaddress.getIp.toString() + 'mas/gdcsd', sendatab, { headers: headers })
      .subscribe(
        (response: any) => {
          const obj = response;
          console.log(obj)
          for (var i = 0; i < obj.all_sensitive_drugs.length; i++) {
            this.all_sensitive_drugs.push({
              allsensitiveRname: obj.all_sensitive_drugs[i].sensitive_drug_desc,
              allsensitive_drug_id: obj.all_sensitive_drugs[i].sensitive_drug_id,
            });
          }
          var DiagnosticR = obj.culture_sensitive_drugs;
          for (var i = 0; i < DiagnosticR.length; i++) {
            this.DiagnosticRarray.push({
              sensitiveRname: DiagnosticR[i].sensitive_drug_desc,
              sensitive_drug_id: DiagnosticR[i].sensitive_drug_id,
            });
          }
        },
        (error) => {
          this.toastr.error('An error occurred');
        }
      );
  }
  isDrugSelected(diagnosticR) {
    return this.DiagnosticRarray.some(item =>
      item.sensitive_drug_id == diagnosticR.allsensitive_drug_id);
  }
  addCultureMapName() {
    const newItem = {
      sensitiveRname: this.allsensitiveRname,
      sensitive_drug_type_id: this.sensitive_drug_type_id,
      sensitive_drug_id: this.sensitive_drug_id,
      status: "1"
    };
    this.savedataCultureName(newItem);
  }
  savedataCultureName(newItem) {
    var datatobackend;
    var dataArray = [];
    if (this.masterType == 'CultureSensitivityDrug') {
      this.masterurl = "mas/scsd";
      if (newItem.sensitive_drug_id != undefined) {
        datatobackend = {
          sensitive_drug_id: newItem.sensitive_drug_id,
          sensitive_drug_desc: newItem.sensitiveRname,
          sensitive_drug_type_id: newItem.sensitive_drug_type_id,
          diag_centre_id: this.diaag,
          status: "1"
        };

        dataArray.push({
          sensitive_drug_id: newItem.sensitive_drug_id,
          sensitive_drug_desc: newItem.sensitiveRname,
        });
      } else {
        datatobackend = {
          drugs: dataArray,
          diag_centre_id: this.diaag,
          sensitive_drug_type_id: newItem.sensitive_drug_type_id,
        };
      }
      console.log("datatobackend", datatobackend);
    } else {
      this.toastr.error('error 5454858');
    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + this.masterurl, datatobackend, { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log(datatobackend);
          this.toastr.success('success');
          var sendatab = {
            diag_centre_id: this.diaag
          };
          if (this.masterType == 'CultureSensitivityDrug') {
            this.CultureNametypedata();
            this.updateSendatab();
            this.Culturemapdata(sendatab);

            // Use dataArray as needed
            console.log("dataArray", dataArray);
          } else {
            this.toastr.error('error condition');
          }
        }, error => {
          this.toastr.error('error in CultureName data yes');
        })
  }
  // Add role
  getuserdpt() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    this.http.get(ipaddress.getIp.toString() + 'adm/utypes', { headers: headers })
      .subscribe(
        (response: any) => {
          var obj = JSON.parse(JSON.stringify(response));
          var userget = obj.user_types;

          for (var i = 1; i < userget.length; i++) {
            this.usergettype.push({
              description: userget[i].description,
              user_type_id: userget[i].user_type_id,
            });
          }
          this.selectedUserType = this.usergettype[0].user_type_id;
          this.getuserdptdata(this.selectedUserType);
        },
        error => {
          this.toastr.error('error');
        }
      );
  }

  getuserdptdata(data) {

    if (this.usergettype.length > 0) {
      for (let i = 0; i < this.usergettype.length; i++) {
        if (this.usergettype[i].user_type_id === this.selectedUserType) {
          this.center_typer = this.usergettype[i].description;
          break;
        }
      }
    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    var requestData = {
      center_id: this.centerid,
      center_type: this.center_typer,
      user_type_id: this.selectedUserType
    };
    this.http.post(ipaddress.getIp.toString() + 'mas/gar', requestData,
      { headers: headers })
      .subscribe(
        response => {
          this.Accountheadarray = [];
          var obj = JSON.parse(JSON.stringify(response));
          var Accounthead = obj.roles;
          console.log("check 00" + JSON.stringify(obj))
          for (var i = 0; i < Accounthead.length; i++) {
            this.Accountheadarray.push({
              role_id: Accounthead[i].role_id,
              Accountheadname: Accounthead[i].description,
              status: Accounthead[i].status,
              typeuser_type_id: this.user_type_id,
            })
          }

        }, error => {
          this.toastr.warning("error in fetch role")
        }
      )
  }

  addroles(role_id, Accountheadname, typeuser_type_id, data) {
    // if (this.usergettype.length > 0) {
    //   for (let i = 0; i < this.usergettype.length; i++) {
    //     if (this.usergettype[i].user_type_id === this.selectedUserType) {
    //       this.center_typer = this.usergettype[i].description;

    //       break;
    //     }
    //   }
    // }


    let center_id = this.centerid;
    let center_type = this.center_typer;
    let user_type_id = this.selectedUserType;
    var datatobackend;
    if (data == 'Erase') {
      datatobackend = {
        
        role_id: role_id,
        description: Accountheadname,
        center_id: center_id,
        center_type: center_type,
        user_type_id: this.selectedUserType,
        status: "0",
      };
    }
    if (this.Accountheadinputname !== undefined) {
      var alreadyExists = false;
      if (this.Accountheadarray.length > 0) {
        for (let i = 0; i < this.Accountheadarray.length; i++) {
          if (this.Accountheadarray[i].Accountheadname === this.Accountheadinputname) {
            alreadyExists = true;
            this.toastr.error("Already exists");
            break;
          }
        }
      }

      if (data == 'save' && alreadyExists == false) {
        if (this.role_id != undefined) {
          datatobackend = {
            role_id:this.role_id,
            description: this.Accountheadinputname,
            center_id: center_id,
            center_type: center_type,
            user_type_id: this.selectedUserType,
            status: "1",
          };

        } else {
          datatobackend = {
            description: this.Accountheadinputname,
            center_id: center_id,
            center_type: center_type,
            user_type_id: this.selectedUserType,
            status: "1",
          };
        }
        this.toastr.success("Success");
      }
    }
    console.log("datatobackend from" + JSON.stringify(datatobackend))

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'mas/srole', datatobackend, { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log("datatobackend from", datatobackend)
          var data = {
            center_id: center_id,
            center_type: center_type,
          };
          this.Accountheadinputname=undefined;
          this.role_id= undefined;
          this.getuserdptdata(data);

        },
        error => {
          this.toastr.warning("error in role data");
        }
      );
    this.Accountheadinputname = null;

  }
  editAccountheadDetails(Accountheadname, role_id) {
   this.role_id = role_id
    for (var i = 0; i < this.Accountheadarray.length; i++) {
      if (this.Accountheadarray[i].Accountheadname == Accountheadname) {
        this.Accountheadinputname = this.Accountheadarray[i].Accountheadname;
        this.Accountheadarray.splice(i, 1);
      }
    }
  }
  //pathology 
  pathologydata() {
    this.StoreNamearray = [];
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    var requestData = { diag_centre_id: this.diaag };
    this.http.post(ipaddress.getIp.toString() + 'mas/gdcp', requestData,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          var StoreName = obj.culture_pathogens;
          if (StoreName.length != 0) {
            for (var i = 0; i < StoreName.length; i++) {
              this.StoreNamearray.push({
                culture_pathogen_code: StoreName[i].culture_pathogen_code,
                culture_pathogen_desc: StoreName[i].culture_pathogen_desc,
                culture_pathogen_id: StoreName[i].culture_pathogen_id,
                status: StoreName[i].status
              })
            }
          }
          console.log("check retrival --" + JSON.stringify(this.StoreNamearray))

        }, error => {
          console.log("error in sample data")
        }
      )
  }
  addpathology(culturepathologyinputname, CodeNameinputname) {
    console.log("check --" + JSON.stringify(this.StoreNamearray))
    var flag = false;
    if (this.culturepathologyinputname != undefined) {
      if (this.StoreNamearray.length != 0) {
        for (let i = 0; i < this.StoreNamearray.length; i++) {
          if (this.StoreNamearray[i].StoreNamename == this.culturepathologyinputname) {
            this.toastr.error("store name already exists or Empty");
            flag = true;
            break;
          }
          var datatobackend;
          if (flag == false) {
            if (this.culture_pathogen_id != undefined) {
              datatobackend = {
                culture_pathogen_desc: culturepathologyinputname,
                culture_pathogen_id: this.culture_pathogen_id,
                diag_centre_id: this.diaag,
                culture_pathogen_code: CodeNameinputname,
                status: "1"
              }
              this.toastr.success(" Success");
            }
            else {
              datatobackend = {
                culture_pathogen_desc: culturepathologyinputname,
                diag_centre_id: this.diaag,
                culture_pathogen_code: CodeNameinputname,
                status: "1"
              }
              this.toastr.success(" Success");
            }
          }
          else {
            console.log("error")
          }

          var headers = new HttpHeaders();
          headers.append('Content-Type', 'application/json');
          this.http.post(ipaddress.getIp.toString() + 'mas/sdcp', datatobackend,
            { headers: headers })
            .subscribe(
              response => {
                var obj = JSON.parse(JSON.stringify(response));
                if (this.masterType == 'culturepathology') {
                  this.pathologydata()
                }
                else {
                  console.log("error")
                }
              }, error => {
                console.log("error in sample type data")
              }
            )
          this.StoreNamearray = [];
          this.CodeNameinputname = [];
          this.StoreNameinputname = [];
          this.StoreNameinputname = null;
        }
      }
    }
  }
  editpathologyDetails(culture_pathogen_code, culture_pathogen_id, culture_pathogen_desc) {

    console.log("che;lkjkljj" + JSON.stringify(this.StoreNamearray))
    console.log("che****" + JSON.stringify(culture_pathogen_code) + culture_pathogen_id + culture_pathogen_desc)
    this.culturepathologyinputname = culture_pathogen_desc;
    this.CodeNameinputname = culture_pathogen_code;
    this.culture_pathogen_id = culture_pathogen_id;
    for (var i = 0; i < this.StoreNamearray.length; i++) {
      if (this.StoreNamearray[i].culture_pathogen_code == culture_pathogen_code && this.StoreNamearray[i].culture_pathogen_desc == culture_pathogen_desc) {
        this.StoreNamearray.splice(i, 1);
        console.log("this.StoreNamearray" + JSON.stringify(this.StoreNamearray))
        break;
      }
    }
  }
  deletepathologyDetails(culture_pathogen_id) {
    const indexToDelete = this.StoreNamearray.findIndex(item => item.culture_pathogen_id == culture_pathogen_id);
    if (indexToDelete >= 0) {
      this.StoreNamearray[indexToDelete].status = "0";
      let deletepathologyD = {
        culture_pathogen_desc: this.StoreNamearray[indexToDelete].culture_pathogen_desc,
        culture_pathogen_id: this.StoreNamearray[indexToDelete].culture_pathogen_id,
        culture_pathogen_code: this.StoreNamearray[indexToDelete].culture_pathogen_code,
        diag_centre_id: this.diaag,
        status: "0",
      };
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + "mas/sdcp", deletepathologyD, { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));
            console.log("deletepathologyD", deletepathologyD)
            this.toastr.success("Success");

            this.pathologydata();
            this.StoreNamearray = [];

          },

          error => {
            console.log("error in deleting item from data");
          });
    }

  }
  //sampletype datat 
  getDiagTesttubes() {
    this.testtube_data = [];
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'mas/gdtt', { diag_centre_id: this.diaag },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log("obj of getDiag tube" + JSON.stringify(obj));
          // var dept_data = obj.diag_dept_list;
          var diagtube_data = obj.tube_type_list;
          if (diagtube_data.length != 0) {
            for (var i = 0; i < diagtube_data.length; i++) {
              this.testtube_data.push(
                {
                  tube_type_id: diagtube_data[i].tube_type_id,
                  tube_desc: diagtube_data[i].tube_desc,
                  status: diagtube_data[i].status,
                }
              )
            }
          }
        }, error => { });
  }
  samplelistdata(sendata) {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'mas/gdst', sendata, { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          var samplelist = obj.specimen_type_list;
          for (var i = 0; i < samplelist.length; i++) {
            const tube_type_id = samplelist[i].tube_type_id;
            this.samplelistarray.push({
              samplelistname: samplelist[i].specimen_desc,
              specimen_type_id: samplelist[i].specimen_type_id,
              status: samplelist[i].status,
              tube_type_id: tube_type_id,
            });
          }
        },
        error => {
          this.toastr.error('error in sample data');
        }
      )
  }
  addsamplelist() {
    if (this.samplelistinputname != undefined) {
      if (this.samplelistarray.length != 0) {

        for (let i = 0; i < this.samplelistarray.length; i++) {
          if (this.samplelistarray[i].samplelistname == this.samplelistinputname) {
            this.toastr.error("sample already exists");
            break;
          }
          else {
            this.samplelistarray.push({
              samplelistname: this.samplelistinputname,
              specimen_type_id: this.samplelistarray[i].specimen_type_id_,
              tube_type_id: this.samplelistarray[i].tube_type_id,
              status: "1",
            });
            break;
          }
        }
      }
      else {
        for (let i = 0; i < this.samplelistarray.length; i++) {
          this.samplelistarray.push({
            samplelistname: this.samplelistinputname,
            specimen_type_id: this.samplelistarray[i].specimen_type_id,
            tube_type_id: this.samplelistarray[i].tube_type_id,
            status: "1",
          });
        }
      }
      this.savedatasample(this.samplelistinputname)
      this.samplelistarray = [];
      this.samplelistinputname = null;
    }
  }
  editsamplelistDetails(samplelistname, specimen_type_id) {
    this.specimen_type_id = specimen_type_id
    for (var i = 0; i < this.samplelistarray.length; i++) {
      if (this.samplelistarray[i].samplelistname == samplelistname) {
        this.samplelistinputname = this.samplelistarray[i].samplelistname;
        this.samplelistarray.splice(i, 1);
      }
    }
  }
  deletesamplelistDetails(specimen_type_id) {
    var deletesamplelistDetails;
    var deletesampleliststatus;
    var deletesamplelistid;
    var deletesamplelistcolor;
    var deletesamplelistname;


    const sampleListItem = this.samplelistarray.find(item => item.specimen_type_id === specimen_type_id);

    if (sampleListItem) {
      sampleListItem.status = "0";
      deletesampleliststatus = sampleListItem.status;
      deletesamplelistid = sampleListItem.specimen_type_id;
      deletesamplelistname = sampleListItem.samplelistname;
      deletesamplelistcolor = sampleListItem.tube_type_id; // Assign tube_type_id

      deletesamplelistDetails = {
        specimen_desc: deletesamplelistname,
        specimen_type_id: deletesamplelistid,
        diag_centre_id: this.diaag,
        tube_type_id: deletesamplelistcolor,
        status: deletesampleliststatus,
      };
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + "mas/sdst", deletesamplelistDetails, { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));
          },
          error => {
            this.toastr.error('error in deleting item form data');
          }
        );
      this.samplelistarray = this.samplelistarray.filter(item => item.specimen_type_id !== specimen_type_id);
    }
  }
  savedatasample(name) {

    if (this.tube_type_id.length > 1) {
      var reagent_values = ""
      for (var i = 0; i < this.tube_type_id.length; i++) {
        reagent_values += this.tube_type_id[i] + ",";
      }
      var tube_type_values = reagent_values.substring(0, reagent_values.length - 1);
    }

    var datatobackend;
    if (this.masterType == 'sampletype') {
      this.masterurl = "mas/sdst";
      if (this.specimen_type_id != undefined) {
        datatobackend = {
          specimen_desc: name,
          specimen_type_id: this.specimen_type_id,
          diag_centre_id: this.diaag,
          tube_type_id: tube_type_values,
          status: "1"
        }
      }
      else {
        datatobackend = {
          specimen_desc: name,
          tube_type_id: tube_type_values,
          diag_centre_id: this.diaag,
        }
      }

    }
    else {
      this.toastr.error('This is a success message', 'Tada');
    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + this.masterurl, datatobackend,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));

          console.log("when save", JSON.parse(JSON.stringify(response)))

          var sendata = {
            diag_centre_id: this.diaag
          }

          if (this.masterType == 'sampletype') {
            this.samplelistdata(sendata)
          }
          else {
            this.toastr.error('error')
          }
        }, error => {
          this.toastr.error('error')
        }
      )
  }
  //DiagnosticD
  DiagnosticDdata(sendataa) {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'mas/gdd', sendataa,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          var DiagnosticD = obj.diag_dept_list;
          for (var i = 0; i < DiagnosticD.length; i++) {
            this.DiagnosticDarray.push({
              DiagnosticDname: DiagnosticD[i].diag_dept_desc,
              diag_department_id: DiagnosticD[i].diag_department_id,
              status: DiagnosticD[i].status
            })
          }
        }, error => {
          this.toastr.error('error')
        }
      )
  }
  addDiagnosticD() {
    if (this.DiagnosticDinputname != undefined) {
      if (this.DiagnosticDarray.length != 0) {
        for (let i = 0; i < this.DiagnosticDarray.length; i++) {
          if (this.DiagnosticDarray[i].DiagnosticDname == this.DiagnosticDinputname) {
            this.toastr.error("Diagnostic already exists");
            break;
          }
          else {
            this.DiagnosticDarray.push({
              DiagnosticDname: this.DiagnosticDinputname,
              diag_department_id: this.DiagnosticDarray[i].diag_department_id,
              status: "1",
            });
            break;
          }
        }
      }
      else {
        for (let i = 0; i < this.DiagnosticDarray.length; i++) {
          this.DiagnosticDarray.push({
            DiagnosticDname: this.DiagnosticDinputname,
            diag_department_id: this.DiagnosticDarray[i].diag_department_id,
            status: "1",
          });
        }
      }
      this.savedataa(this.DiagnosticDinputname)
      this.DiagnosticDarray = [];
      this.DiagnosticDinputname = null;
    }
  }
  editDiagnosticDDetails(DiagnosticDname, diag_department_id) {
    this.diag_department_id = diag_department_id
    for (var i = 0; i < this.DiagnosticDarray.length; i++) {
      if (this.DiagnosticDarray[i].DiagnosticDname == DiagnosticDname) {
        this.DiagnosticDinputname = this.DiagnosticDarray[i].DiagnosticDname;

        this.DiagnosticDarray.splice(i, 1);
      }
    }
  }
  deleteDiagnosticDDetails(diag_department_id) {
    var deleteDiagnosticDDetails
    var deleteDiagnosticDstatus;
    var deleteDiagnosticDid;
    var deleteDiagnosticDname;
    for (var i = 0; i < this.DiagnosticDarray.length; i++) {
      if (this.DiagnosticDarray[i].diag_department_id == diag_department_id) {
        this.DiagnosticDarray[i].status = "0";
        deleteDiagnosticDstatus = this.DiagnosticDarray[i].status;
        deleteDiagnosticDid = this.DiagnosticDarray[i].diag_department_id;
        deleteDiagnosticDname = this.DiagnosticDarray[i].DiagnosticDname
      }
      deleteDiagnosticDDetails = {
        diag_dept_desc: deleteDiagnosticDname,
        diag_department_id: deleteDiagnosticDid,
        diag_centre_id: this.diaag,
        status: deleteDiagnosticDstatus
      }
    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + "mas/sdd", deleteDiagnosticDDetails,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
        }, error => {
          this.toastr.error('error')
        })
    this.DiagnosticDarray = this.DiagnosticDarray.filter(sample => sample.diag_department_id !== diag_department_id);
  }
  savedataa(name) {
    var datatobackend;
    if (this.masterType == 'DiagnosticD') {
      this.masterurl = "mas/sdd";
      if (this.diag_department_id != undefined) {
        datatobackend = {
          diag_dept_desc: name,
          diag_department_id: this.diag_department_id,
          diag_centre_id: this.diaag,
          status: "1"
        }
      }
      else {
        datatobackend = {
          diag_dept_desc: name,
          diag_centre_id: this.diaag,
        }
      }

    }
    else {
      this.toastr.error('error')
    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + this.masterurl, datatobackend,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.toastr.success('Edited')
          var sendataa = {
            diag_centre_id: this.diaag
          }
          if (this.masterType == 'DiagnosticD') {
            this.DiagnosticDdata(sendataa)
          }
          else {
            this.toastr.error('error')
          }
        }, error => {
          this.toastr.error('error');
        })
  }
  //Diagnostict
  Diagnostictdata(sendataaa) {

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'mas/gdtt', sendataaa,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          var Diagnostict = obj.tube_type_list;
          for (var i = 0; i < Diagnostict.length; i++) {
            this.Diagnostictarray.push({
              Diagnostictname: Diagnostict[i].tube_desc,
              tube_type_id: Diagnostict[i].tube_type_id,
              color_code: Diagnostict[i].color_code,
              status: Diagnostict[i].status
            })
          }
        }, error => {
          this.toastr.error('error');
        })
  }
  addDiagnostict() {
    if (this.Diagnostictinputname != undefined) {
      if (this.Diagnostictarray.length != 0) {
        for (let i = 0; i < this.Diagnostictarray.length; i++) {
          if (this.Diagnostictarray[i].Diagnostictname == this.Diagnostictinputname) {
            this.toastr.error("Diagnostic tube data already exists");
            break;
          }
          else {
            this.Diagnostictarray.push({
              // image: this.imageBase64,
              Diagnostictname: this.Diagnostictinputname,
              tube_type_id: this.Diagnostictarray[i].tube_type_id,
              color_code: this.Diagnostictarray[i].color_code,
              tube_size: this.Diagnostictarray[i].tube_size,
              status: "1",
            });
            break;
          }
        }
      }
      else {
        for (let i = 0; i < this.Diagnostictarray.length; i++) {
          this.Diagnostictarray.push({
            // image: this.imageBase64,
            Diagnostictname: this.Diagnostictinputname,
            tube_type_id: this.Diagnostictarray[i].tube_type_id,
            color_code: this.Diagnostictarray[i].color_code,
            tube_size: this.Diagnostictarray[i].tube_size,
            status: "1",
          });
        }
      }

      this.savedataaa(this.Diagnostictinputname)
      this.Diagnostictarray = [];

      this.Diagnostictinputname = null;
    }
  }
  editDiagnostictDetails(Diagnostictname, tube_type_id, color_code, tube_size) {
    this.tube_type_id = tube_type_id;
    this.color_code = color_code;
    this.tube_size = tube_size;
    for (var i = 0; i < this.Diagnostictarray.length; i++) {
      if (this.Diagnostictarray[i].Diagnostictname == Diagnostictname) {
        this.Diagnostictinputname = this.Diagnostictarray[i].Diagnostictname;

        this.Diagnostictarray.splice(i, 1);
      }
    }
  }
  deleteDiagnostictDetails(tube_type_id) {
    var deleteDiagnostictDetails;
    var deleteDiagnostictstatus;
    var deleteDiagnostictid;
    var deleteDiagnostictname;
    var deleteDiagnostictcolor;
    var deleteDiagnostictsize;
    for (var i = 0; i < this.Diagnostictarray.length; i++) {
      if (this.Diagnostictarray[i].tube_type_id == tube_type_id) {
        this.Diagnostictarray[i].status = "0";
        deleteDiagnostictstatus = this.Diagnostictarray[i].status;
        deleteDiagnostictid = this.Diagnostictarray[i].tube_type_id;
        deleteDiagnostictname = this.Diagnostictarray[i].Diagnostictname;
        deleteDiagnostictcolor = this.Diagnostictarray[i].color_code;
        deleteDiagnostictsize = this.Diagnostictarray[i].tube_size
      }
      deleteDiagnostictDetails = {
        tube_desc: deleteDiagnostictname,
        tube_type_id: deleteDiagnostictid,
        diag_centre_id: this.diaag,
        color_code: this.color_code,
        tube_size: this.tube_size,
        status: deleteDiagnostictstatus
      }

    }
    this.toastr.success('deleted');
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + "mas/sdtt", deleteDiagnostictDetails,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
        }, error => {
          this.toastr.error('error');
        })
    this.Diagnostictarray = this.Diagnostictarray.filter(sample => sample.tube_type_id !== tube_type_id);
  }
  
  // onFileChange(event: any) {
  //   const file = event.target.files[0];

  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       this.imageBase64 = reader.result as string;
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // }
  savedataaa(name) {

    var datatobackend;
    if (this.masterType == 'Diagnostict') {

      this.masterurl = "mas/sdtt";
      if (this.tube_type_id != undefined) {
        datatobackend = {
          tube_desc: name,
          tube_type_id: this.tube_type_id,
          diag_centre_id: this.diaag,
          color_code: this.color_code,
          tube_size: this.tube_size,
          // image: this.imageBase64,

          status: "1"
        }
      }
      else {
        datatobackend = {
          tube_desc: name,
          diag_centre_id: this.diaag,
          color_code: this.color_code,
          tube_size: this.tube_size,
          // image: this.imageBase64,
          status: "1"
        }
      }
    }
    else {
      this.toastr.error('error');
    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + this.masterurl, datatobackend,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log("datatobackenddatatobackend", datatobackend)
          var sendataaa = { diag_centre_id: this.diaag }
          if (this.masterType == 'Diagnostict') {
            this.Diagnostictdata(sendataaa)
          }

          else {
            this.toastr.error('error');
          }
        }, error => { this.toastr.error('error'); })

    //  this.imageBase64 ="";
  }
  //diagnosticR
  DiagnosticRtypedata() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'mas/gdat', { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          var DiagnosticR = obj.reagent_types;
          this.DiagnosticRarraytype = DiagnosticR;
          if (this.DiagnosticRarraytype && this.DiagnosticRarraytype.length > 0) {
            this.reagent_type_id = this.DiagnosticRarraytype[0].reagent_type_id;
          }
        },

        error => {
          this.toastr.error('error');
        })
  }
  DiagnosticRdata(sendatab) {
    this.DiagnosticRarray = [];
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'mas/grad', sendatab,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          var DiagnosticR = obj.diag_reagents;
          for (var i = 0; i < DiagnosticR.length; i++) {
            this.DiagnosticRarray.push({
              reagent_type_desc: DiagnosticR[i].reagent_type_desc,
              DiagnosticRname: DiagnosticR[i].diag_reagent_desc,
              diag_reagent_id: DiagnosticR[i].diag_reagent_id,
              reagent_type_id: DiagnosticR[i].reagent_type_id,
              diag_centre_id: DiagnosticR[i].diag_centre_id,
              status: DiagnosticR[i].status
            })
          }
        }, error => {
          this.toastr.error('error');
        })
  }
  addDiagnosticR() {
    console.log("this.DiagnosticRarray ***"+JSON.stringify(this.DiagnosticRarray))
    console.log("this.DiagnosticRarray "+JSON.stringify(this.DiagnosticRinputname))
    if (this.DiagnosticRinputname != undefined) {
      if (this.DiagnosticRarray.length != 0) {
        for (let i = 0; i < this.DiagnosticRarray.length; i++) {
          if (this.DiagnosticRarray[i].DiagnosticRname == this.DiagnosticRinputname) {
            this.toastr.error("already exists");
           
          }
          else {
            this.DiagnosticRarray.push({
              DiagnosticRname: this.DiagnosticRinputname,
              reagent_type_id: this.reagent_type_id,
              status: "1",
            });
           // break;
          }
        }
      }
      // else {
      //   this.toastr.error("SELECT");
      // }
      this.savedataDiagnosticR(this.DiagnosticRinputname)
      this.DiagnosticRarray = [];
      this.DiagnosticRinputname = null;
    }
  }
  editDiagnosticRDetails(DiagnosticRname, reagent_type_id) {
    this.reagent_type_id = reagent_type_id
    for (var i = 0; i < this.DiagnosticRarray.length; i++) {
      if (this.DiagnosticRarray[i].DiagnosticRname == DiagnosticRname) {
        this.DiagnosticRinputname = this.DiagnosticRarray[i].DiagnosticRname;
        this.DiagnosticRarray.splice(i, 1);
      }
    }
  }
  deleteDiagnosticRDetails(data) {
    const deleteData = {
      diag_reagent_desc: data.DiagnosticRname,
      diag_reagent_id: data.diag_reagent_id,
      reagent_type_id: data.reagent_type_id,
      diag_centre_id: this.diaag,
      status: "0",
    };
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    this.http.post(ipaddress.getIp.toString() + 'mas/sdra', deleteData, { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          var sendata = {
            diag_centre_id: this.diaag
          }
          this.DiagnosticRdata(sendata);
        },
        error => {
          this.toastr.error('error');
        });
  }
  savedataDiagnosticR(name) {
    var datatobackend;
    if (this.masterType == 'DiagnosticR') {
      this.masterurl = "mas/sdra";
      if (this.reagent_type_id != undefined) {
        datatobackend = {
          diag_reagent_desc: name,
          reagent_type_id: this.reagent_type_id,
          diag_centre_id: this.diaag,
          reagent_type_desc: this.reagent_type_desc,
          status: "1"
        }
      }
      else {
        datatobackend = {
          diag_reagent_desc: name,
          diag_centre_id: this.diaag,
        }
      }
    }
    else {
      this.toastr.error('error');
    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + this.masterurl, datatobackend,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.toastr.success('success');
          var sendatab = {
            diag_centre_id: this.diaag
          }
          if (this.masterType == 'DiagnosticR') {
            this.DiagnosticRdata(sendatab)
          }
          else {
            this.toastr.error('error');
          }
        }, error => {
          this.toastr.error('error in DiagnosticR data');
        })
  }
  // stoct adujustment recent
  stockAdjustmentAccountdata() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    var requestData = { pharma_id: this.pharma_id };
    this.http.post(ipaddress.getIp.toString() + 'mas/gsar', requestData,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          var samplelist = obj.stock_adj_reasons;
          for (var i = 0; i < samplelist.length; i++) {
            this.SAAarray.push({
              SAAname: samplelist[i].description,
              stock_adj_reason_id: samplelist[i].stock_adj_reason_id,
              status: samplelist[i].status
            })
          }
        }, error => {
          console.log("error in sample data")
        }
      )
  }
  addstoctadujustmentrecent() {
    if (this.SAAinputname != undefined) {
      if (this.SAAarray.length != 0) {
        for (let i = 0; i < this.SAAarray.length; i++) {
          if (this.SAAarray[i].SAAname == this.SAAinputname) {
            this.toastr.error("stock adjustemrnt resons already exists");
            break;
          }
          else {
            this.SAAarray.push({
              SAAname: this.SAAinputname,
              stock_adj_reason_id: this.SAAarray[i].stock_adj_reason_id,
              status: "1",
            });
            break;
          }
        }
      }
      else {
        for (let i = 0; i < this.SAAarray.length; i++) {
          this.SAAarray.push({
            SAAname: this.SAAinputname,
            stock_adj_reason_id: this.SAAarray[i].stock_adj_reason_id,
            status: "1",
          });
        }
      }
      var datatobackend;
      if (this.masterType == 'stockar') {
        this.masterurl = "mas/ssar";
        if (this.stock_adj_reason_id != undefined) {
          datatobackend = {
            description: this.SAAinputname,
            pharma_id: this.pharma_id,
            stock_adj_reason_id: this.stock_adj_reason_id,
            status: "1"
          }
          this.toastr.success(" Success");
        }
        else {
          datatobackend = {
            description: this.SAAinputname,
            pharma_id: this.pharma_id,
            stock_adj_reason_id: this.stock_adj_reason_id,
          }
          this.toastr.success(" Success");
        }
      }
      else {
        console.log("error")
      }
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + this.masterurl, datatobackend,
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));
            var sendata = {
              pharma_id: this.pharma_id,
            }
            if (this.masterType == 'stockar') {
              this.stockAdjustmentAccountdata();
            }
            else {
              console.log("error")
            }
          }, error => {
            console.log("error in sample type data")
          }
        )
      this.SAAarray = [];
      this.SAAinputname = null;
    }
  }
  editstoctDetails(SAAname, stock_adj_reason_id) {
    this.stock_adj_reason_id = stock_adj_reason_id
    for (var i = 0; i < this.SAAarray.length; i++) {
      if (this.SAAarray[i].SAAname == SAAname) {
        this.SAAinputname = this.SAAarray[i].SAAname;
        this.SAAarray.splice(i, 1);
      }
    }
  }
  deletestoctDetails(stock_adj_reason_id) {

    var deletesamplelistDetails
    var deletesampleliststatus;
    var deletesamplelistid;
    var deleteSAAname;
    for (var i = 0; i < this.SAAarray.length; i++) {
      if (this.SAAarray[i].stock_adj_reason_id == stock_adj_reason_id) {
        this.SAAarray[i].status = "0";
        deletesampleliststatus = this.SAAarray[i].status;
        deletesamplelistid = this.SAAarray[i].stock_adj_reason_id;
        deleteSAAname = this.SAAarray[i].SAAname
      }
      deletesamplelistDetails = {
        description: deleteSAAname,
        stock_adj_reason_id: deletesamplelistid,
        pharma_id: this.pharma_id,
        status: deletesampleliststatus
      }

    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + "mas/ssar", deletesamplelistDetails,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
        }, error => {
          console.log("error in deleting item form data")
        })
    this.SAAarray = this.SAAarray.filter(sample => sample.stock_adj_reason_id !== stock_adj_reason_id);
    this.toastr.success(" Success");
  }
  // Store Name
  StoreNamedata() {

    this.StoreNamearray = [];
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    var requestData = { hptl_clinic_id: this.centerid };

    this.http.post(ipaddress.getIp.toString() + 'mas/gsnh', requestData,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log("retravel" + JSON.stringify(obj))
          this.StoreNamearray = [];
          var StoreName = obj.store_names;
          for (var i = 0; i < StoreName.length; i++) {
            this.StoreNamearray.push({
              StoreNamename: StoreName[i].store_desc,
              store_names_id: StoreName[i].store_names_id,
              status: StoreName[i].status
            })
          }
        }, error => {
          console.log("error in sample data")
        }
      )
  }
  addStoreName() {

    if (this.StoreNameinputname != undefined) {
      if (this.StoreNamearray.length != 0) {
        for (let i = 0; i < this.StoreNamearray.length; i++) {
          if (this.StoreNamearray[i].StoreNamename == this.StoreNameinputname) {
            this.toastr.error("store name already exists");
            break;
          }
          else {
            this.StoreNamearray.push({
              StoreNamename: this.StoreNameinputname,
              store_names_id: this.StoreNamearray[i].store_names_id,
              status: "1",
            });
            break;
          }
        }
      }
      else {
        for (let i = 0; i < this.StoreNamearray.length; i++) {
          this.StoreNamearray.push({
            StoreNamename: this.StoreNameinputname,
            store_names_id: this.StoreNamearray[i].store_names_id,
            status: "1",
          });
        }
      }
      this.saveStoreName(this.StoreNameinputname)
      this.StoreNamearray = [];
      this.StoreNameinputname = null;
    }
  }
  editStoreNameDetails(StoreNamename, store_names_id) {
    this.store_names_id = store_names_id
    for (var i = 0; i < this.StoreNamearray.length; i++) {
      if (this.StoreNamearray[i].StoreNamename == StoreNamename) {
        this.StoreNameinputname = this.StoreNamearray[i].StoreNamename;
        this.StoreNamearray.splice(i, 1);
      }
    }
  }
  deleteStoreNameDetails(store_names_id) {
    var deleteStoreNameDetails
    var deleteStoreNamestatus;
    var deleteStoreNameid;
    var deleteStoreNamename;
    for (var i = 0; i < this.StoreNamearray.length; i++) {
      if (this.StoreNamearray[i].store_names_id == store_names_id) {
        this.StoreNamearray[i].status = "0";
        deleteStoreNamestatus = this.StoreNamearray[i].status;
        deleteStoreNameid = this.StoreNamearray[i].store_names_id;
        deleteStoreNamename = this.StoreNamearray[i].StoreNamename
      }
      deleteStoreNameDetails = {
        store_desc: deleteStoreNamename,
        store_names_id: deleteStoreNameid,
        hptl_clinic_id: this.centerid,
        status: deleteStoreNamestatus
      }
    }
    console.log("deleteStoreNameDetails" + JSON.stringify(deleteStoreNameDetails))
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + "mas/shsn", deleteStoreNameDetails,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.toastr.success("Success");
          this.StoreNamedata();
        }, error => {
          console.log("error in deleting item form data");

        })



  }
  saveStoreName(store_desc) {
    var datatobackend;
    if (this.masterType == 'StoreName') {
      this.masterurl = "mas/shsn";
      if (this.store_names_id != undefined) {
        datatobackend = {
          store_desc: store_desc,
          hptl_clinic_id: this.centerid,
          store_names_id: this.store_names_id,
          status: "1"
        }
        this.toastr.success(" Success");
      }
      else {
        datatobackend = {
          store_desc: store_desc,
          hptl_clinic_id: this.centerid,
          store_names_id: this.store_names_id,
          status: "1"
        }
        this.toastr.success(" Success");
      }
    }
    else {
      console.log("error")
    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + this.masterurl, datatobackend,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          if (this.masterType == 'StoreName') {
            this.StoreNamedata()
          }
          else {
            console.log("error")
          }
        }, error => {
          console.log("error in sample type data")
        }
      )
  }
  // generic Name
  genericdata(sendatatab) {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'mas/ggm', sendatatab,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          var genericlist = obj.generic_list
          for (var i = 0; i < genericlist.length; i++) {
            console.log(obj.generic_list[i].generic_name)
            this.genericarray.push({
              genericname: genericlist[i].generic_name,
              generic_id: genericlist[i].generic_id
            })
          }
        }, error => {
          console.log("error in generic data")
        }
      )
  }
  addgeneric() {
    if (this.genericinputname != undefined) {
      if (this.genericarray.length != 0) {
        for (let i = 0; i < this.genericarray.length; i++) {

          if (this.genericarray[i].genericname == this.genericinputname) {
            this.toastr.error("Rack already exists");
            break;
          }
          else {

            this.genericarray.push({

              genericname: this.genericinputname,
              generic_id: this.genericarray[i].generic_id,
              status: "1",
            });
            break;
          }
        }
      }
      else {
        for (let i = 0; i < this.genericarray.length; i++) {
          this.genericarray.push({

            genericname: this.genericinputname,
            generic_id: this.genericarray[i].generic_id,
            status: "1",
          });
        }
      }

      this.savedata(this.genericinputname)
      this.genericarray = [];
      this.genericinputname = null;
    }

  }
  editgenericDetails(genericname, generic_id) {
    this.generic_id = generic_id
    for (var i = 0; i < this.genericarray.length; i++) {
      if (this.genericarray[i].genericname == genericname) {
        this.genericinputname = this.genericarray[i].genericname;

        this.genericarray.splice(i, 1);
      }
    }
  }
  deletegenericDetails(generic_id) {
    var deletegenericDetails
    var deletegenericstatus;
    var deletegenericid;
    var genericname;
    for (var i = 0; i < this.genericarray.length; i++) {
      console.log(JSON.stringify(this.genericarray[i]))
      if (this.genericarray[i].generic_id == generic_id) {
        this.genericarray[i].status = "0";
        deletegenericstatus = this.genericarray[i].status;
        deletegenericid = this.genericarray[i].generic_id;
        genericname = this.genericarray[i].genericname
      }
      deletegenericDetails = {
        generic_name: genericname,
        generic_id: deletegenericid,
        pharma_id: this.pharma_id,
        status: deletegenericstatus
      }
    }


    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + "mas/sgm", deletegenericDetails,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log(JSON.stringify(obj))


        }, error => {
          console.log("error in deleting item form data")
        }

      )
    this.genericarray = this.genericarray.filter(sample => sample.genericname !== genericname);
  }
  // scheduledata
  scheduledata(sendata) {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'mas/gst', sendata,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          var schedulelist = obj.schedule_list
          console.log(JSON.stringify(obj))
          for (var i = 0; i < schedulelist.length; i++) {
            console.log(obj.schedule_list[i].category)
            this.schedulearray.push({
              schedulename: schedulelist[i].category,
              schedule_type_id: schedulelist[i].schedule_type_id,
              status: "1"
            })
          }
        }, error => {
          console.log("error in schedule data")
        }
      )
  }

  addschedule() {
    if (this.scheduleinputname != undefined) {
      if (this.schedulearray.length != 0) {
        for (let i = 0; i < this.schedulearray.length; i++) {

          if (this.schedulearray[i].schedulename == this.scheduleinputname) {
            this.toastr.error("Rack already exists");
            break;
          }
          else {

            this.schedulearray.push({

              schedulename: this.scheduleinputname,
              schedule_type_id: this.schedulearray[i].schedule_type_id,
              status: "1",
            });
            break;
          }
        }
      }
      else {
        for (let i = 0; i < this.schedulearray.length; i++) {
          this.genericarray.push({
            // rackid:this.rackid,
            schedulename: this.scheduleinputname,
            schedule_type_id: this.schedulearray[i].schedule_type_id,
            status: "1",
          });
        }
      }
      this.savedata(this.scheduleinputname)
      this.schedulearray = [];
      // 
      this.scheduleinputname = null;
    }
  }

  editscheduleDetails(schedule_type_id, schedulename) {
    this.schedule_type_id = schedule_type_id;
    for (var i = 0; i < this.schedulearray.length; i++) {
      if (this.schedulearray[i].schedulename == schedulename) {
        this.scheduleinputname = this.schedulearray[i].schedulename;

        this.schedulearray.splice(i, 1);
      }
    }
  }

  deletescheduleDetails(schedule_type_id) {
    var deletescheduleDetails
    var deleteschedulestatus;
    var deletescheduleid;
    var deleteschedulename;
    for (var i = 0; i < this.schedulearray.length; i++) {
      if (this.schedulearray[i].schedule_type_id == schedule_type_id) {
        this.schedulearray[i].status = "0";
        deleteschedulestatus = this.schedulearray[i].status;
        deletescheduleid = this.schedulearray[i].schedule_type_id;
        deleteschedulename = this.schedulearray[i].schedulename;

      }
      deletescheduleDetails = {
        category: deleteschedulename,
        schedule_type_id: deletescheduleid,
        pharma_id: this.pharma_id,
        status: deleteschedulestatus
      }
      console.log("after saveing delete schedule data", JSON.stringify(deletescheduleDetails))
    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + "mas/sst", deletescheduleDetails,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log(JSON.stringify(obj))

        }, error => {
          console.log("error in deleting item form data")
        }

      )
    this.schedulearray = this.schedulearray.filter(sample => sample.schedule_type_id !== schedule_type_id);
  }
  //itemformmaster
  itemformdata(sendata) {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'mas/gif', sendata,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          var pharmaitemformlist = obj.pharma_item_forms;
          console.log("product name", JSON.stringify(obj))
          console.log("product name", JSON.stringify(pharmaitemformlist))
          for (var i = 0; i < pharmaitemformlist.length; i++) {
            console.log(pharmaitemformlist[i].item_form_desc)
            this.itemformarray.push({
              itemformname: pharmaitemformlist[i].item_form_desc,
              item_form_id: pharmaitemformlist[i].item_form_id,
              status: "1"
            })
          }
        }, error => {
          console.log("error in schedule data")
        }
      )
  }

  additemform() {
    if (this.itemforminputname != undefined) {
      if (this.itemformarray.length != 0) {
        for (let i = 0; i < this.itemformarray.length; i++) {
          if (this.itemformarray[i].itemformname == this.itemforminputname) {
            this.toastr.error("Rack already exists");
            break;
          }
          else {
            this.itemformarray.push({
              // genericid:this.rackid,
              itemformname: this.itemforminputname,
              item_form_id: this.itemformarray[i].item_form_id,
              status: "1",
            });
            break;
          }
        }
      }
      else {
        for (let i = 0; i < this.itemformarray.length; i++) {
          this.itemformarray.push({
            itemformname: this.itemforminputname,
            item_form_id: this.itemformarray[i].item_form_id,
            status: "1",
          });
        }
      }
      this.savedata(this.itemforminputname)
      this.itemformarray = [];
      this.itemforminputname = null;
    }
  }

  edititemformDetails(itemformname, item_form_id) {
    this.item_form_id = item_form_id;
    for (var i = 0; i < this.itemformarray.length; i++) {
      if (this.itemformarray[i].itemformname == itemformname) {
        this.itemforminputname = this.itemformarray[i].itemformname;
        this.itemformarray.splice(i, 1);
      }
    }
  }

  deleteitemformDetails(item_form_id) {
    var deleteitemformDetails
    var deleteformstatus;
    var deleteitemformid;
    var deleteitemformname;
    for (var i = 0; i < this.itemformarray.length; i++) {
      console.log(JSON.stringify(this.itemformarray[i]))
      if (this.itemformarray[i].item_form_id == item_form_id) {
        this.itemformarray[i].status = "0";
        deleteformstatus = this.itemformarray[i].status;
        deleteitemformid = this.itemformarray[i].item_form_id;
        deleteitemformname = this.itemformarray[i].itemformname;
      }
      deleteitemformDetails = {
        item_form_desc: deleteitemformname,
        item_form_id: deleteitemformid,
        pharma_id: this.pharma_id,
        status: deleteformstatus
      }
      console.log("after saveing delete data", JSON.stringify(deleteitemformDetails))
    }
    // console.log(datatobackend)
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + "mas/sif", deleteitemformDetails,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log(JSON.stringify(obj))

        }, error => {
          console.log("error in deleting item form data")
        })
    this.itemformarray = this.itemformarray.filter(sample => sample.item_form_id !== item_form_id);
  }
  // save pharma
  savedata(name) {
    var sendata = {
      pharma_id: this.pharma_id
    }
    var datatobackend;
    if (this.masterType == 'GM') {
      this.masterurl = "mas/sgm";
      if (this.generic_id != undefined) {
        console.log(this.generic_id)
        datatobackend = {
          generic_name: name,
          pharma_id: this.pharma_id,
          generic_id: this.generic_id,
          status: "1"
        }
      }
      else {
        datatobackend = {
          generic_name: name,
          pharma_id: this.pharma_id,
        }
      }
    }
    else if (this.masterType == 'SM') {
      this.masterurl = "mas/sst";
      if (this.schedule_type_id != undefined) {
        console.log(this.generic_id)
        datatobackend = {
          category: name,
          pharma_id: this.pharma_id,
          schedule_type_id: this.schedule_type_id,
          status: "1"
        }
      }
      else {
        datatobackend = {
          category: name,
          pharma_id: this.pharma_id,
        }
      }
    }
    else if (this.masterType == 'IFM') {
      this.masterurl = "mas/sif";
      if (this.item_form_id != undefined) {
        console.log(this.item_form_id)
        datatobackend = {
          item_form_desc: name,
          item_form_id: this.item_form_id,
          pharma_id: this.pharma_id,
          status: "1"
        }
      }
      else {
        datatobackend = {
          item_form_desc: name,
          pharma_id: this.pharma_id
        }
      }
    }
    else {
      console.log("error")
    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + this.masterurl, datatobackend,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log(JSON.stringify(obj))
          if (this.masterType == 'GM') {
            this.genericdata(sendata)
          } else if (this.masterType == 'SM') {
            this.scheduledata(sendata)
          } else if (this.masterType == 'IFM') {
            this.itemformdata(sendata)
          }
          else {
            console.log("error")
          }
        }, error => {
          console.log("error in generic data")
        }
      )
  }
  // SubDeparment
  getDiagDepartment(sendatab) {
    this.department_data = [];
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'mas/gdd', sendatab, { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          var dept_data = obj.diag_dept_list;
          if (dept_data && dept_data.length !== 0) {
            for (var i = 0; i < dept_data.length; i++) {
              this.department_data.push({
                diag_department_id: dept_data[i].diag_department_id,
                diag_dept_desc: dept_data[i].diag_dept_desc,
                status: dept_data[i].status,
              });
            }
            this.diag_department_id = this.department_data[0].diag_department_id;
            this.updateSendatabSubDeparment()
          }
        },
        error => { }
      );
  }
  updateSendatabSubDeparment() {

    this.sendatab = {
      diag_dept_id: this.diag_department_id,
      diag_centre_id: this.diaag
    };

    this.SubDeparment(this.sendatab);
  }
  SubDeparment(sendatab) {
    this.subdepartarray = [];
    sendatab = {
      diag_dept_id: this.diag_department_id,
      diag_centre_id: this.diaag
    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/dtcatn', sendatab,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log("SubdepartmentArray", obj);
          var test_category_data = obj.test_categories;
          for (var i = 0; i < test_category_data.length; i++) {
            this.subdepartarray.push({
              diag_test_cat_id: test_category_data[i].diag_test_cat_id,
              subdepartname: test_category_data[i].description,
              Odersubdepart: test_category_data[i].orderby,
              status: test_category_data[i].status
            })
          }
        },
        error => {
          console.log("error in fetch data of Accountsubhead")
        })


  }

  addSubDeparment() {
    if (this.descsubdepartinput !== undefined && this.Odersubdepartinput !== undefined) {
      const newItem = {
        subdepartname: this.descsubdepartinput,
        Odersubdepart: this.Odersubdepartinput,
        status: "1"
      };
      if (this.subdepartarray.some(item => item.subdepartname === this.descsubdepartinput || item.Odersubdepart === this.Odersubdepartinput)) {
        this.toastr.error("Already exists Subdepartment or Order by");
      } else {
        this.subdepartarray.push(newItem);
        this.savedataSubDeparment(this.descsubdepartinput);
        this.descsubdepartinput = null;
        this.Odersubdepartinput = null;
      }
    }
  }
  editSubDeparmentDetails(Odersubdepart, subdepartname, diag_test_cat_id) {
    this.diag_test_cat_id = diag_test_cat_id
    for (var i = 0; i < this.subdepartarray.length; i++) {
      if (this.subdepartarray[i].subdepartname == subdepartname || this.subdepartarray[i].Odersubdepartinput == Odersubdepart) {
        this.descsubdepartinput = this.subdepartarray[i].subdepartname;
        this.Odersubdepartinput = this.subdepartarray[i].Odersubdepart;
      }

    }
    this.deleteSubDeparmentDetails(Odersubdepart, diag_test_cat_id, subdepartname);
  }
  deleteSubDeparmentDetails(Odersubdepart, diag_test_cat_id, subdepartname) {
    const deleteData = {
      diag_test_cat_id: diag_test_cat_id,
      description: subdepartname,
      diag_dept_id: this.diag_department_id,
      diag_centre_id: this.diaag,
      orderby: Odersubdepart,
      status: "0",
    };

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/stcat', deleteData, { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          var sendatab = {
          }
          this.updateSendatabSubDeparment();
        },
        error => {
          this.toastr.error('error');
        });
  }
  savedataSubDeparment(descsubdepartinput) {
    var datatobackend;
    if (this.masterType == 'Sub-Deparment') {
      this.masterurl = "diagnosiscontroller/stcat";
      if (this.diag_test_cat_id != undefined) {
        datatobackend = {
          description: descsubdepartinput,
          diag_dept_id: this.diag_department_id,
          diag_centre_id: this.diaag,
          orderby: this.Odersubdepartinput,
          status: "1"
        }
        this.toastr.success(" Success");
      }
      else {
        datatobackend = {
          description: descsubdepartinput,
          diag_centre_id: this.diaag,
          diag_dept_id: this.diag_department_id,
          orderby: this.Odersubdepartinput,
          status: "1"

        }
        this.toastr.success(" Success");
      }
    }
    else {
      console.log("error")
    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + this.masterurl, datatobackend,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          var sendatab = {
            diag_centre_id: this.diaag,
            diag_dept_id: this.diag_department_id
          }
          this.SubDeparment(sendatab);

        }, error => {
          console.log("error in backend data")
        }
      )
  }

  //Add Products
  getSurgeryDepartment() {
    this.surgery_data = [];
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'mas/gsh', { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          var surg_data = obj.med_procedures;
          if (surg_data && surg_data.length !== 0) {
            for (var i = 0; i < surg_data.length; i++) {
              this.surgery_data.push({
                procedure_id: surg_data[i].procedure_id,
                description: surg_data[i].description,
              });
            }
            this.surgery_id = this.surgery_data[0].procedure_id;
            this.updateSendatabProducts();
          }
        },
        error => { "Error in Surgery Procedures" }
      )
  }

  updateSendatabProducts() {
    this.sendatab = {
      surgery_id: this.surgery_id,
      hptl_clinic_id: this.centerid
    };
    this.products(this.sendatab);
  }

  products(sendatab) {
    this.productsarray = [];
    sendatab = {
      surgery_id: this.surgery_id,
      hptl_clinic_id: this.centerid
    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'mas/gse', sendatab,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log("total ",obj);
          var equipments_data = obj.surgery_equipments;
          for (var i = 0; i < equipments_data.length; i++) {
            this.productsarray.push({
              surgery_equipment_id: equipments_data[i].surgery_equipment_id,
              product_id: equipments_data[i].product_id,
              productname: equipments_data[i].product_name,
              productquantity: equipments_data[i].quantity,
              status: equipments_data[i].status,
              product_type_id: equipments_data[i].product_type_id,
            });
          }
        },
        error => { "error in fetch data of surgery equipments" }
      )
  }

  getSurgeryProductsByName() {
    if (this.descProductsInput.length > 2 && this.descProductsInput != undefined) {
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/gibhs',
        {
          hptl_clinic_id: this.centerid,
          product_name: this.descProductsInput
        },
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));
            console.log("checjdfhgkadfghksehti",obj)
            this.productList = [];
            for (var i = 0; i < obj.products.length; i++) {
              this.productList.push({
                product_name: obj.products[i].product_name,
                product_id: obj.products[i].product_id,
                product_type_id:obj.products[i].product_type_id,

              });
            }
          },
          error => { "error in fetch data of surgery products" }
        )
    }
  }

  selectProduct(item) {
    this.descProductsInput = item.product_name;
    this.product_id = item.product_id;
    this.product_type_id = item.product_type_id;
    this.productList = [];
  }

  addProducts() {
    if (this.descProductsInput !== undefined && this.productsQuantityInput !== undefined) {
      const newItem = {
        product_id: this.product_id,
        productname: this.descProductsInput,
        productquantity: this.productsQuantityInput,
        product_type_id:this.product_type_id,
        product_name:this.descProductsInput,
        status: "1"
      };
      if (this.productsarray.some(item => item.productname === this.descProductsInput )) {
        this.toastr.error("Already exists Equipment or Quantity");
      } else {
        this.productsarray.push(newItem);
        this.savedataProducts(this.product_id);
        this.descProductsInput = null;
        this.productsQuantityInput = null;
      }
    }
  }

  editProductsDetails(productquantity, product_id, surgery_equipment_id,product_type_id,product_name) {
    this.surgery_equipment_id = surgery_equipment_id;
    for (var i = 0; i < this.productsarray.length; i++) {
      if (this.productsarray[i].product_id == product_id || this.productsarray[i].productquantity == productquantity) {
        this.product_id = this.productsarray[i].product_id;
        this.descProductsInput = this.productsarray[i].productname;
        this.productsQuantityInput = this.productsarray[i].productquantity;
      }
    }
    this.deleteProductsDetails(productquantity, surgery_equipment_id, product_id,product_type_id,product_name);
  }

  deleteProductsDetails(productquantity, surgery_equipment_id, product_id,product_type_id,product_name) {
    const deleteData = {
      surgery_equipment_id: surgery_equipment_id,
      product_id: product_id,
      surgery_id: this.surgery_id,
      hptl_clinic_id: this.centerid,
      quantity: productquantity,
      product_type_id:product_type_id,
      equipment:product_name,
      status: "0",
    };
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    this.http.post(ipaddress.getIp.toString() + 'mas/sse', deleteData, { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log("delete",deleteData,obj)
          this.updateSendatabProducts();
        },
        error => {
          this.toastr.error('error in delete deta');
        }
      )
  }
  savedataProducts(product_id) {
    var datatobackend;
    if (this.masterType == 'Surgery_Equipments') {
      this.masterurl = 'mas/sse';
      if (this.surgery_equipment_id != undefined) {
        datatobackend = {
          product_id: product_id,
          surgery_id: this.surgery_id,
          hptl_clinic_id: this.centerid,
          quantity: this.productsQuantityInput,
          product_type_id:this.product_type_id,
          equipment:this.descProductsInput,
          status: "1"
        }
        this.toastr.success("Success");
      } else {
        datatobackend = {
          product_id: product_id,
          hptl_clinic_id: this.centerid,
          surgery_id: this.surgery_id,
          quantity: this.productsQuantityInput,
          product_type_id:this.product_type_id,
          equipment:this.descProductsInput,
          status: "1"
        }
        this.toastr.success("Success");
      }
    }
    else {
      this.toastr.error("error in send data");
    }
    
console.log(datatobackend)
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + this.masterurl, datatobackend,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log(obj)
          var sendatab = {
            hptl_clinic_id: this.centerid,
            surgery_id: this.surgery_id
          }
          this.products(sendatab);
        }, error => {
          this.toastr.error("error in backend data");
        }
      )
  }

  // Add template
  gettemplate(sendatab) {
    this.department_data = [];
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'mas/gdd', sendatab, { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          var dept_data = obj.diag_dept_list;
          if (dept_data && dept_data.length !== 0) {
            for (var i = 0; i < dept_data.length; i++) {
              this.department_data.push({
                diag_department_id: dept_data[i].diag_department_id,
                diag_dept_desc: dept_data[i].diag_dept_desc,
                status: dept_data[i].status,
              });
            }
            console.log("getDiagDepartment", this.department_data);
          }
          this.DepartmentID = this.department_data[0].diag_department_id
          this.updateSendatemplate();
        },
        error => { }
      );
  }
  updateSendatemplate() {

    const selectedDepartment = this.DepartmentID;
    if (selectedDepartment !== 'None') {
      this.department_id = selectedDepartment;
    }
    this.sendatab = {
      diag_department_id: this.department_id,
      diag_centre_id: this.diaag
    };
    this.getmaintemplae(this.sendatab);

  }
  getmaintemplae(sendatab) {
    this.Addtemparray = [];
    sendatab = {
      diag_department_id: this.department_id,
      diag_centre_id: this.diaag
    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'mas/gdttd ', sendatab,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          var addtemplate_data = obj.diag_templates;
          for (var i = 0; i < addtemplate_data.length; i++) {
            this.Addtemparray.push({
              template_id: addtemplate_data[i].template_id,
              template_desc: addtemplate_data[i].template_desc,
              template_name: addtemplate_data[i].template_name,
              // status: test_category_data[i].status,
              // department_id:test_category_data[i].status

            })
          }
        },
        error => {
          console.log("error in fetch data of Accountsubhead")
        })
  }
  addtemplate() {
    if (this.desctemplateinput !== undefined && this.Odersubdepartinput !== undefined) {
      const newItem = {
        template_desc: this.desctemplateinput,
        template_name: this.Odersubdepartinput,
        status: "1"
      };
      if (this.Addtemparray.some(item => item.template_name === this.desctemplateinput || item.template_desc === this.Odersubdepartinput)) {
        this.toastr.error("Already exists Subdepartment or Order by");
      } else {
        this.Addtemparray.push(newItem);
        this.savedatatemplate(this.desctemplateinput)
        this.desctemplateinput = "";
        this.Odersubdepartinput = "";
      }
    }
  }
  edittemplateDetails(template_desc, template_name, template_id) {
    this.template_id = template_id
    for (var i = 0; i < this.Addtemparray.length; i++) {
      if (this.Addtemparray[i].template_name == template_name || this.Addtemparray[i].Odersubdepartinput == template_desc) {
        this.desctemplateinput = this.Addtemparray[i].template_desc;
        this.Odersubdepartinput = this.Addtemparray[i].template_name;
      }

    }
    this.deletetemplateDetails(template_desc, template_id, template_name);
  }

  deletetemplateDetails(template_desc, template_id, template_name) {
    const deleteData = {
      template_id: template_id,
      template_desc: template_desc,
      department_id: this.department_id,
      diag_centre_id: this.diaag,
      template_name: template_name,
      status: "0",
    };
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    this.http.post(ipaddress.getIp.toString() + 'mas/sdttd', deleteData, { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          var sendatab = {
          }
          this.updateSendatemplate();
        },
        error => {
          this.toastr.error('error');
        });
  }
  savedatatemplate(desctemplateinput) {
    var datatobackend;
    if (this.masterType == 'Addtemplate' && desctemplateinput != " " ) {
      this.masterurl = "mas/sdttd";
      if (this.diag_test_cat_id != undefined) {
        datatobackend = {
          template_desc: desctemplateinput,
          department_id: this.department_id,
          diag_centre_id: this.diaag,
          template_name: this.Odersubdepartinput,
          status: "1"
        }
        this.toastr.success("Successfully Updated");
      }
      else {
        datatobackend = {
          template_desc: desctemplateinput,
          diag_centre_id: this.diaag,
          department_id: this.department_id,
          template_name: this.Odersubdepartinput,
          status: "1"

        }
        this.toastr.success(" Success");
      }
    }
  
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + this.masterurl, datatobackend,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          var sendatab = {
            diag_centre_id: this.diaag,
            department_id: this.diag_department_id
          }
          this.getmaintemplae(sendatab);

        }, error => {
          console.log("error in backend data")
        }
      )
  }
  // GramStains
  GramStainsdata() {
    var sendata = {
      diag_centre_id: this.diaag
    };
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'mas/ggst', sendata,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          var Gram_Stains = obj.gram_stains;
          for (var i = 0; i < Gram_Stains.length; i++) {
            this.Gram_Stains_array.push({
              gram_stain_desc: Gram_Stains[i].gram_stain_desc,
              gram_stain_id: Gram_Stains[i].gram_stain_id,
              status: Gram_Stains[i].status
            })
          }
        },
        error => {
          this.toastr.error('error')
        }
      )
  }
  addGramStains() {
    if (this.GramStainsinputname != undefined) {
      if (this.Gram_Stains_array.length != 0) {
        for (let i = 0; i < this.Gram_Stains_array.length; i++) {
          if (this.Gram_Stains_array[i].gram_stain_desc == this.GramStainsinputname) {
            this.toastr.error(" already exists");
            break;
          }
          else {
            this.Gram_Stains_array.push({
              gram_stain_desc: this.GramStainsinputname,
              gram_stain_id: this.Gram_Stains_array[i].gram_stain_id,
              status: "1",
            });
            break;
          }
        }
      }
      else {
        for (let i = 0; i < this.Gram_Stains_array.length; i++) {
          this.Gram_Stains_array.push({
            gram_stain_desc: this.GramStainsinputname,
            gram_stain_id: this.Gram_Stains_array[i].gram_stain_id,
            status: "1",
          });
        }
      }

      var datatobackend
      if (this.gram_stain_id != undefined) {
        datatobackend = {
          gram_stain_desc: this.GramStainsinputname,
          diag_centre_id: this.diaag,
          status: "1"
        }
      }
      else {
        datatobackend = {
          gram_stain_desc: this.GramStainsinputname,
          diag_centre_id: this.diaag,
        }
      }
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'mas/sgst', datatobackend,
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));
            console.log("datatobackend", datatobackend)
            this.toastr.success('Edited')
            if (this.diaag) {
              this.GramStainsdata();
            }
          }, error => {
            this.toastr.error('error');
          })

      this.GramStainsinputname = null;
      this.Gram_Stains_array = [];
    }
  }
  editGramStainsDetails(gram_stain_desc, gram_stain_id) {
    this.gram_stain_id = gram_stain_id
    for (var i = 0; i < this.Gram_Stains_array.length; i++) {
      if (this.Gram_Stains_array[i].gram_stain_desc == gram_stain_desc) {
        this.GramStainsinputname = this.Gram_Stains_array[i].gram_stain_desc;
        this.deleteGramStainsDetails(gram_stain_id);
      }
    }
  }
  deleteGramStainsDetails(gram_stain_id) {
    var deleteGramStainDetails;
    var deleteGramStainstatus;
    var deleteGramStainid;
    var deleteGramStainname;
    for (var i = 0; i < this.Gram_Stains_array.length; i++) {
      if (this.Gram_Stains_array[i].gram_stain_id == gram_stain_id) {
        this.Gram_Stains_array[i].status = "0";
        deleteGramStainstatus = this.Gram_Stains_array[i].status;
        deleteGramStainid = this.Gram_Stains_array[i].gram_stain_id;
        deleteGramStainname = this.Gram_Stains_array[i].gram_stain_desc;
      }
      deleteGramStainDetails = {
        gram_stain_desc: deleteGramStainname,
        gram_stain_id: deleteGramStainid,
        diag_centre_id: this.diaag,
        status: deleteGramStainstatus
      }
    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + "mas/sgst", deleteGramStainDetails,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
        }, error => {
          this.toastr.error('error');
        })
    this.Gram_Stains_array = this.Gram_Stains_array.filter(sample => sample.gram_stain_id !== gram_stain_id);
  }

  // RateCard
  getRateCard() {
    this.RateCardarray = []; 
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    var requestData = { centre_id: this.centerid, center_type:this.center_typePR };
    this.http.post(ipaddress.getIp.toString() + 'mas/grcd', requestData,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          var RateCard = obj.rate_cards;
          
          if (RateCard != undefined && RateCard.length != 0) {
            for (var i = 0; i < RateCard.length; i++) {
              if (RateCard[i].default == "1") {
                RateCard[i].default = true;
              } else {
                RateCard[i].default = false;
              }
              this.RateCardarray.push({
                discount: RateCard[i].discount,
                rate_card_id: RateCard[i].rate_card_id,
                rate_card_desc: RateCard[i].rate_card_desc,
                default: RateCard[i].default,
                status: RateCard[i].status,
                discount_type:RateCard[i].discount_type,
              })
            }
          }
        }, (error) => {
          this.toastr.error('An error occurred');
        }
      )
  }

  checkcenter(value){
    if(value == "pharma"){
      this.centerid= this.UserInfo.pharma_id;
      this.discounttypeflag=false

    } else if (value == "diagnosis") {
      this.centerid= this.UserInfo.diag_center_id;
      this.discounttypeflag = true;

    } else {
      this.centerid= this.UserInfo.hptl_clinic_id;
      this.discounttypeflag = true;
    }
    this.getRateCard();
  }

  isDefaultSelected() {
    if(this.default == true){
      if (this.RateCardarray.length != 0) {
        for (var i = 0; i < this.RateCardarray.length; i++) {
          if (this.RateCardarray[i].default == true) {
            this.toastr.error("RateCard Default Already Selected..");
            this.default = false;
          }
        }
      }
    }
  }

  saveRateCard(rate_card_id, discount, rate_card_desc, default_sel, data) {
    var datatobackend;
    if (data == 'Erase') {
      if (default_sel == "Default") {
        default_sel = "1";
      } else {
        default_sel = "0";
      }
      datatobackend = {
        rate_card_id: rate_card_id,
        rate_card_desc: rate_card_desc,
        default: default_sel,
        center_id: this.centerid,
        discount: discount,
        center_type: this.center_typePR,
        status: "0"
      };
    }
    console.log("this.RateCardarray --"+JSON.stringify(this.RateCardarray))
    if (this.rate_card_desc != undefined) {
      var flag = false;
      if (this.RateCardarray.length != 0) {
        for (let i = 0; i < this.RateCardarray.length; i++) {
          if (this.RateCardarray[i].rate_card_desc == this.rate_card_desc) {
            this.toastr.error("Ratecard already exists");
            flag = true;
            break;
          }
        }
      }
      if (flag == false && data == 'save') {
        if (this.default == true) {
          this.default = "1";
        } else {
          this.default = "0";
        }
        datatobackend = {
          rate_card_id: this.selected_id,
          rate_card_desc: this.rate_card_desc,
          default: this.default,
          center_id: this.centerid,
          discount: this.discount,
          discount_type:this.discount_typePR,
          center_type: this.center_typePR,
          status: "1"
        };
      }
    }
    console.log("datatobackend erase"+JSON.stringify(datatobackend));
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'mas/srcd', datatobackend,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log("obj ****"+JSON.stringify(obj));
          if (obj.key == "1") {
            this.toastr.success(obj.result);
            this.getRateCard();
            this.rate_card_desc = null;
            this.discount = null;
            this.rate_card_desc = null;
            this.default = null;
          } else {
            this.default = null;
            this.toastr.error("error");
          }
        }, error => {
          this.toastr.error("error");
        }
      );
  }

  editRateCard(ratecard) {
    this.selected_id = ratecard.rate_card_id;
    this.rate_card_desc = ratecard.rate_card_desc;
    this.discount = ratecard.discount;
    this.default = ratecard.default;
   
    if(ratecard.discount_type != undefined){
      this.discount_typePR = ratecard.discount_type;
    }

    for (var i = 0; i < this.RateCardarray.length; i++) {
      if (this.RateCardarray[i].rate_card_id == ratecard.rate_card_id) {
        this.RateCardarray.splice(i, 1);
        break;
      }
    }
  }
  // visitpurpose
  updateSendatabVP() {
    this.sendatab = {
      prov_type: this.visit_purpose_name,
      hptl_clinic_id: this.centerid

    };
    this.Visit_Purpose_array = [];
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    this.http.post(ipaddress.getIp.toString() + 'mas/gvpp', this.sendatab, { headers: headers })
      .subscribe(
        (response: any) => {
          const obj = response;
          console.log(obj)
          var VisitPurposeA = obj.visit_purposes;
          for (var i = 0; i < VisitPurposeA.length; i++) {
            this.Visit_Purpose_array.push({
              visit_purpose_desc: VisitPurposeA[i].visit_purpose_desc,
              visit_purpose_id: VisitPurposeA[i].visit_purpose_id,
            })
          }
        },
        (error) => {
          this.toastr.error('An error occurred');
        }
      );
  }

  addVisitPurpose() {
    if (this.VisitPurposeinputname != undefined) {
      if (this.Visit_Purpose_array.length != 0) {
        for (let i = 0; i < this.Visit_Purpose_array.length; i++) {
          if (this.Visit_Purpose_array[i].visit_purpose_desc == this.VisitPurposeinputname) {
            this.toastr.error(" already exists");
            break;
          }
          else {
            this.Visit_Purpose_array.push({
              visit_purpose_desc: this.VisitPurposeinputname,
              visit_purpose_id: this.Visit_Purpose_array[i].visit_purpose_id,
              status: "1",
            });
            break;
          }
        }

      } else {
        for (let i = 0; i < this.Visit_Purpose_array.length; i++) {
          this.Visit_Purpose_array.push({
            visit_purpose_desc: this.VisitPurposeinputname,
            visit_purpose_id: this.Visit_Purpose_array[i].visit_purpose_id,
            status: "1",
          });
        }
      }
      var datatobackend
      if (this.masterType == 'Visitpurpose') {
        this.masterurl = "mas/svpp";
        if (this.visit_purpose_id != undefined) {
          datatobackend = {
            visit_purpose_desc: this.VisitPurposeinputname,
            prov_type: this.visit_purpose_name,
            hptl_clinic_id: this.centerid,
            status: "1"
          }
        }
        else {
          datatobackend = {
            visit_purpose_id: this.visit_purpose_id,
            visit_purpose_desc: this.VisitPurposeinputname,
            hptl_clinic_id: this.centerid,
            prov_type: this.visit_purpose_name,
            status: "1"
          }
        }
      }
      console.log("vist perpose", datatobackend)
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + this.masterurl, datatobackend,
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));
            if(this.visit_purpose_id  != undefined){
              this.toastr.success("Successfully Updated");
            }else{
              this.toastr.success(obj.result);
            }
        
            this.updateSendatabVP();

          }, error => {
            this.toastr.error('error');
          })
      this.Visit_Purpose_array = [];
      this.VisitPurposeinputname = null;
    }
  }
  editVisitPurposeDetails(visit_purpose_desc, visit_purpose_id) {
    this.visit_purpose_id = visit_purpose_id
    for (var i = 0; i < this.Visit_Purpose_array.length; i++) {
      if (this.Visit_Purpose_array[i].visit_purpose_desc == visit_purpose_desc) {
        this.VisitPurposeinputname = this.Visit_Purpose_array[i].visit_purpose_desc;
        this.deleteVisitPurposeDetails(visit_purpose_id);
      }
    }
  }
  deleteVisitPurposeDetails(visit_purpose_id) {
    var deleteVisitPurposeDetails;
    var deleteVisitPurposeStatus;
    var deleteVisitPurposeid;
    var deleteVisitPurposename;
    for (var i = 0; i < this.Visit_Purpose_array.length; i++) {
      if (this.Visit_Purpose_array[i].visit_purpose_id == visit_purpose_id) {
        this.Visit_Purpose_array[i].status = "0";
        deleteVisitPurposeStatus = this.Visit_Purpose_array[i].status;
        deleteVisitPurposeid = this.Visit_Purpose_array[i].visit_purpose_id;
        deleteVisitPurposename = this.Visit_Purpose_array[i].visit_purpose_desc;
      }
      deleteVisitPurposeDetails = {
        visit_purpose_desc: deleteVisitPurposename,
        visit_purpose_id: deleteVisitPurposeid,
        prov_type: this.visit_purpose_name,
        status: deleteVisitPurposeStatus
      }
    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + "mas/svpp", deleteVisitPurposeDetails,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log(obj);
        }, error => {
          this.toastr.error('error');
        })
    this.Visit_Purpose_array = this.Visit_Purpose_array.filter(sample => sample.visit_purpose_id !== visit_purpose_id);
  }
  // Culture Sensitive Drug Group with Name
  CultureRtypedata() {
    const sendata = {
      loctype: 'masters',
      diag_centre_id: this.diaag
    };
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    this.http.post(ipaddress.getIp.toString() + 'mas/gdcsdt', sendata, { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          var DiagnosticD = obj.sensitive_drug_types;
          for (var i = 0; i < DiagnosticD.length; i++) {
            this.DiagnosticDarray.push({
              DiagnosticDname: DiagnosticD[i].sensitive_drug_type_desc,
              sensitive_drug_type_id: DiagnosticD[i].sensitive_drug_type_id,
            })
          }
          this.sensitive_drug_type_id = this.DiagnosticDarray[0].sensitive_drug_type_id;
          this.updateCultureSendatab();
        },
        error => {
          this.toastr.error('error')
        }
      );
  }
  updateCultureSendatab() {
    this.sendatab = {
      sensitive_drug_type_id: this.sensitive_drug_type_id,
      diag_centre_id: this.diaag
    };
    this.CultureRdata(this.sendatab);
  }
  CultureRdata(sendatab) {
    this.DiagnosticRarray = [];
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    this.http.post(ipaddress.getIp.toString() + 'mas/gdcsdm', sendatab, { headers: headers })
      .subscribe(
        (response: any) => {
          const obj = response;
          console.log("obj", obj);
          var DiagnosticR = obj.culture_sensitive_drugs;
          for (var i = 0; i < DiagnosticR.length; i++) {
            this.DiagnosticRarray.push({
              DiagnosticRname: DiagnosticR[i].sensitive_drug_desc,
              sensitive_drug_id: DiagnosticR[i].sensitive_drug_id,
            })
          }
        },
        (error) => {
          this.toastr.error('An error occurred');
        }
      );
  }
  addCulturedata() {
    if (this.DiagnosticRinputname !== undefined) {
      const newItem = {
        DiagnosticRname: this.DiagnosticRinputname,
        sensitive_drug_type_id: this.sensitive_drug_type_id,
        sensitive_drug_id: this.sensitive_drug_id,
        status: "1"
      };

      if (this.DiagnosticRarray.some(item => item.DiagnosticRname === this.DiagnosticRinputname)) {
        this.toastr.error("Already exists");
      } else {
        this.DiagnosticRarray.push(newItem);
      }
      this.saveCulturedata(this.DiagnosticRinputname);
      this.DiagnosticRinputname = null;
    }
  }
  editCultureRDetails(DiagnosticRname, sensitive_drug_id) {
    this.sensitive_drug_id = sensitive_drug_id
    for (var i = 0; i < this.DiagnosticRarray.length; i++) {
      if (this.DiagnosticRarray[i].DiagnosticRname == DiagnosticRname) {
        this.DiagnosticRinputname = this.DiagnosticRarray[i].DiagnosticRname;
        this.DiagnosticRarray.splice(i, 1);
      }
    }
    var sendatab = {
      diag_centre_id: this.diaag
    }
    this.updateCultureSendatab();
    this.CultureRdata(sendatab);
  }
  deleteCultureRDetails(data) {
    const deleteData = {
      sensitive_drug_id: data.sensitive_drug_id,
      sensitive_drug_desc: data.DiagnosticRname,
      diag_centre_id: this.diaag,
      sensitive_drug_type_id: this.sensitive_drug_type_id,
      status: "0",
    };
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    this.http.post(ipaddress.getIp.toString() + 'mas/scsd', deleteData, { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          var sendatab = {
          }
          this.updateCultureSendatab();
          this.CultureRdata(sendatab);
        },
        error => {
          this.toastr.error('error');
        });
  }
  saveCulturedata(name) {
    var datatobackend;
    if (this.masterType == 'CultureSensitivityDrugName') {
      this.masterurl = "mas/scsd";
      if (this.sensitive_drug_id != undefined) {
        datatobackend = {
          sensitive_drug_id: this.sensitive_drug_id,
          sensitive_drug_desc: name,
          sensitive_drug_type_id: this.sensitive_drug_type_id,
          diag_centre_id: this.diaag,
          status: "1"
        }
      } else {
        datatobackend = {
          sensitive_drug_desc: name,
          diag_centre_id: this.diaag,
          sensitive_drug_type_id: this.sensitive_drug_type_id,
        }
      }
    } else {
      this.toastr.error('error');
    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + this.masterurl, datatobackend,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.toastr.success('success');
          var sendatab = {
            diag_centre_id: this.diaag
          }
          if (this.masterType == 'CultureSensitivityDrugName') {
            this.updateCultureSendatab();
            this.CultureRdata(sendatab);
          } else {
            this.toastr.error('error');
          }
          this.sensitive_drug_id=undefined;
        }, error => {
          this.toastr.error('error in Sensitive Drug data');
        })
  }
  //  User Roles Mapping
  getUserRolesMapping() {
    this.getUsserrolearrayall = [];
    var send_data = {
      hptl_clinic_id: Helper_Class.getInfo().hptl_clinic_id,
      user_type_id: this.selectedUserTypeId
    };

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    this.http.post(ipaddress.getIp.toString() + 'adm/grbu', send_data, { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log("Role data " + JSON.stringify(obj));
          var getUserroles = obj.user_roles;
          for (var i = 0; i < getUserroles.length; i++) {
            var docname;
            if (getUserroles[i].middle_name != undefined) {
              docname = getUserroles[i].first_name + " " + getUserroles[i].middle_name + " " + getUserroles[i].last_name;
            } else {
              docname = getUserroles[i].first_name + " " + getUserroles[i].last_name;
            }
            this.getUsserrolearrayall.push({
              user_role_id: getUserroles[i].user_role_id,
              role_id: getUserroles[i].role_id,
              role_desc: getUserroles[i].role_desc,
              user_id: getUserroles[i].user_id,
              doc_name: docname,
              user_type_desc: getUserroles[i].user_type_desc,
              user_type_id: getUserroles[i].user_type_id,
              status: getUserroles[i].status,
            });
          }
        },
        error => {
          this.toastr.error('error');
        }
      );
  }
  getUserTypes() {
    var send_data = {
      hptl_clinic_id: this.centerid,
      package_id: this.packageid,
    };
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/gubh', send_data, { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          if (obj.user_types != undefined) {
            var usertypelist = obj.user_types;
            console.log("User_Type ", usertypelist);
            for (var i = 0; i < usertypelist.length; i++) {
              this.tempModules.push({
                type_id: usertypelist[i].type_id,
                type_desc: usertypelist[i].type_desc,
                status: usertypelist[i].status,
              });
            }
            //this.selectedUserTypeId = this.tempModules[0].type_id;
          }

        },
        error => {
          this.toastr.warning("error in fetch role");
        }
      );
  }
  getUserList() {
    this.userList = [];
    var senddata = {
      center_id: this.centerid,
      type: this.selectedUserTypeId
    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/guth', senddata,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log("requestData ", senddata)
          if (obj.users != null) {
            for (var i = 0; i < obj.users.length; i++) {
              var user_name;
              if (obj.users[i].middle_name != undefined || obj.users[i].middle_name != null) {
                user_name = obj.users[i].first_name + " " + obj.users[i].middle_name + " " + obj.users[i].last_name;

              } else {
                user_name = obj.users[i].first_name + " " + obj.users[i].last_name;
              }
              this.userList.push({
                user_id: obj.users[i].user_id,
                name: user_name,
              })
            }
            //this.userid = this.userList[0].user_id;
          }

        }, error => {
          this.toastr.error();
        });
  }
  getUserRole() {
    this.getUserrolearrays = [];
    if (this.tempModules.length > 0) {
      for (let i = 0; i < this.tempModules.length; i++) {
        if (this.tempModules[i].type_id === this.selectedUserTypeId) {
          this.center_t = this.tempModules[i].type_desc;
          break;
        }
      }
    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    var requestData = {
      center_type: this.center_t,
      center_id: this.centerid,
      user_type_id: this.selectedUserTypeId
    };
    console.log('requestData', requestData)
    this.http.post(ipaddress.getIp.toString() + 'adm/gru', requestData,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          var getUserrole = obj.user_roles;
          for (var i = 0; i < getUserrole.length; i++) {
            this.getUserrolearrays.push({
              role_id: getUserrole[i].role_id,
              Userroledname: getUserrole[i].description,
              status: getUserrole[i].status
            })
          }
          //this.selectedRoleId = this.getUserrolearrays[0].role_id;
          this.getUserList();
          this.getUserRolesMapping();
          
        },
        error => {
          this.toastr.warning("error in fetch role")
        }
      )

  }



  deleteUserRole(user_role_id, role_id, user_id, user_type_id) {
    var deletebackend;
    if (this.masterType == 'UserRole') {
      this.masterurl = "adm/srbu";
      deletebackend = {
        user_role_id: user_id,
        user_id: role_id,
        role_id: user_role_id,
        hosp_clinic_id: this.centerid,
        user_type_id: user_type_id,
        status: "0"
      };
    } else {
      this.toastr.error('Invalid master type');
      return;
    }

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    this.http.post(ipaddress.getIp.toString() + this.masterurl, deletebackend, { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log("From delete", deletebackend);
          this.toastr.success('Success');
          this.getUserRolesMapping();
        }, error => {
          this.toastr.error('Error in delete data');
        });
  }
  saveUserRole() {
    if (this.getUsserrolearrayall.some(userRole => userRole.user_id === this.userid && userRole.role_id === this.selectedRoleId)) {
      this.toastr.error('User already has the selected role assigned');
      return;
    }
    var datatobackend;
    var user_id = this.userid;
    if (this.masterType == 'UserRole') {
      this.masterurl = "adm/srbu";
      datatobackend = {
        user_type_id: this.selectedUserTypeId,
        user_id: user_id,
        role_id: this.selectedRoleId,
        hosp_clinic_id: this.centerid,

        status: "1"
      };
    } else {
      this.toastr.error('Invalid master type');
      return;
    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + this.masterurl, datatobackend, { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log("when save", response)
          console.log("datatobackend 007", datatobackend);
          this.toastr.success('Success');
          this.getUserRolesMapping();
        }, error => {
          this.toastr.error('Error in saving data');
        });
    
  }
  // getbillcredittypes
  getbillcredittypes() {

    var sendata = {
      hptl_clinic_id: this.centerid,
      pharma_id: this.pharma_id
    };

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'mas/gbcd', sendata,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log("sdfdzgdfgdf", obj)
          var billcredittypes = obj.bill_credits;
          for (var i = 0; i < billcredittypes.length; i++) {
            this.billcredittypesarray.push({
              credit_type: billcredittypes[i].credit_type,
              bill_credits_id: billcredittypes[i].bill_credits_id,
              status: billcredittypes[i].status
            })
          }
        },
        error => {
          this.toastr.error('error')
        }
      )
  }
  addbillcredittypes(bill_credits_id, credit_type, data) {

    var flag = false
    var datatobackend
    if (data == 'Erase') {

      datatobackend = {
        credit_type: credit_type,
        bill_credits_id: bill_credits_id,
        hptl_clinic_id: this.centerid,
        status: "0"
      }
    }

    if (this.billcredittypesname != undefined) {
      if (this.billcredittypesarray.length != 0) {
        for (let i = 0; i < this.billcredittypesarray.length; i++) {
          if (this.billcredittypesarray[i].credit_type == this.billcredittypesname) {
            this.toastr.error(" already exists");
            flag = true;
            break;
          }
        }
      }
      if (flag == false && data == 'save') {
        if (bill_credits_id != null) {
          datatobackend = {
            bill_credits_id: bill_credits_id,
            credit_type: this.billcredittypesname,
            hptl_clinic_id: this.centerid,
            status: "1"
          }
        }
        else {
          datatobackend = {
            credit_type: this.billcredittypesname,
            hptl_clinic_id: this.centerid,
            status: "1"
          }
        }
      }
    }

    console.log("datatobackend", datatobackend);
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'mas/sbcd', datatobackend,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.toastr.success('saved')
          this.getbillcredittypes()
        }, error => {
          this.toastr.error('error');
        })
    this.billcredittypesarray = [];
    this.billcredittypesname = null;
  }
  editbillcredittypesDetails(credit_type, bill_credits_id) {
    this.billcredittypesname = bill_credits_id;
    for (var i = 0; i < this.billcredittypesarray.length; i++) {
      if (this.billcredittypesarray[i].credit_type == credit_type) {
        this.billcredittypesname = this.billcredittypesarray[i].credit_type;
        this.billcredittypesarray.splice(i, 1);
        break;
      }
    }
  }
  // specimen accessioner
  specimenaccessionerdata() {

    this.specimenaccessionerarray = [];
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    var requestData = { diag_centre_id: this.diaag };
    this.http.post(ipaddress.getIp.toString() + 'mas/gada', requestData,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log("retravel" + JSON.stringify(obj))
          this.specimenaccessionerarray = [];
          var accessioner = obj.diag_assertions;
          for (var i = 0; i < accessioner.length; i++) {
            this.specimenaccessionerarray.push({
              assertion_desc: accessioner[i].assertion_desc,
              diag_assertion_id: accessioner[i].diag_assertion_id,
              status: accessioner[i].status
            })
          }
        }, error => {
          console.log("error in sample data")
        }
      )
  }
  addAccessioner(diag_assertion_id, assertion_desc, data) {
    if (this.diag_assertion_id != undefined) {
      if (this.specimenaccessionerarray.length != 0) {
        for (let i = 0; i < this.specimenaccessionerarray.length; i++) {
          if (this.specimenaccessionerarray[i].assertion_desc == this.assertion_desc) {
            this.toastr.error("Accessioner Name Already Exists");
            break;
          }

        }
      }
    }
    var datatobackend;
    this.masterurl = "mas/sdat";

    if (data == 'delete') {
      datatobackend = {
        assertion_desc: assertion_desc,
        diag_centre_id: this.diaag,
        diag_assertion_id: diag_assertion_id,
        status: "0"
      }
      this.toastr.success("delete Success");
    } else if (data == 'save') {
      if (this.editFLAG == true) {
        datatobackend = {
          assertion_desc: this.AccessionerNameinputname,
          diag_assertion_id: this.diag_assertion_id,
          diag_centre_id: this.diaag,
          status: "1"
        }
        this.toastr.success("updated Success ");
      }
      else {
        datatobackend = {
          assertion_desc: this.AccessionerNameinputname,
          diag_centre_id: this.diaag,
          status: "1"
        }
        this.toastr.success("saved  Success ");
      }
    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + this.masterurl, datatobackend,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log(response)
          if (this.masterType == 'specimenaccessioner') {
            this.specimenaccessionerdata()
          }
          else {
            console.log("error")
          }
        }, error => {
          console.log("error in sample type data")
        }
      )
    this.AccessionerNameinputname = '';
  }
  editAccessionerDetails(diag_assertion_id, assertion_desc) {
    this.editFLAG = true;
    this.diag_assertion_id = diag_assertion_id;
    for (var i = 0; i < this.specimenaccessionerarray.length; i++) {
      if (this.specimenaccessionerarray[i].assertion_desc == assertion_desc) {
        this.AccessionerNameinputname = this.specimenaccessionerarray[i].assertion_desc;
        this.specimenaccessionerarray.splice(i, 1);
      }
    }
  }
}


