<div class="row" style="width:100%;margin:0 auto;">
    <div class="col-12">
      <mdb-card>
        <mdb-card-header class="bg-white ">
          <div class="headerCover">
            <div class="headerTilte app_list_header">
              <h5 class="m-0" class="mainHeadingStyle">Create appointment</h5>
            </div>
            <!-- *ngIf="bookFlag" -->
            <div class="headerButtons">
              <a *ngIf="!updateFlag"><img src="../../../assets/ui_icons/buttons/Book_now.svg" 
                  class="saveimgbtn_inpatinfo" (click)="book('booking')" /></a>
              <img src="../../../assets/ui_icons/buttons/update_button.svg" class="saveimgbtn_inpatinfo"
                (click)="book('appointment')" *ngIf="updateFlag" />
              <a style="margin:0 5px;" (click)="back()"><img src="../../../assets/ui_icons/buttons/Back_button.svg"
                  class="backimgbtn_inpatinfo" /></a>
            </div>
          </div>
        </mdb-card-header>
        <mdb-card-body>
          <div class="row">
            <div class="" style="margin-top: -10px;">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-5" style="margin: 15px 0 15px 0;">
                  <div class="row">
                    <div class="col-6">
                      <mat-label class="matlabel" style="font-weight: 600;">Appointment Type : </mat-label>
                    </div>
                    <div class="col-6">
                      <div class="switch-field">
                        <input type="radio" id="radio-one" (click)="changeType('app',1,'yes')" />
                        <label for="radio-one" [ngClass]="apptTypeFlag ? 'radio_active':'radio_inactive'">New</label>
                        <input type="radio" id="radio-two" (click)="changeType('app',1,'no')" />
                        <label for="radio-two" [ngClass]="!apptTypeFlag ? 'radio_active':'radio_inactive'">Follow-up</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-7 col-xl-7" style="margin: 15px 0 15px 0;" *ngIf="showClient">
                  <div class="row">
                    <div class="col-5">
                      <mat-label class="matlabel" style="font-weight: 600;">Client Type : </mat-label>
                    </div>
                    <div class="col-7">
                      <div class="switch-field">
                        <input type="radio" id="radio-three" (click)="changeType('client',1,'yes')" />
                        <label for="radio-three" [ngClass]="clientTypeFlag ? 'radio_active':'radio_inactive'">New</label>
                        <input type="radio" id="radio-four" (click)="changeType('client',1,'no')" />
                        <label for="radio-four" [ngClass]="!clientTypeFlag ? 'radio_active':'radio_inactive'">Existing</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="cover_div" style="padding-bottom: 13px;">
                <div class="header_lable">
                  Appointment details
                </div>
                <div class="content_cover">
                  <div class="row">
                    <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3" [hidden]="splshow">
                        <mat-label class="matlabel">Specialization<br>
                          <select id="mandatory_specfication" class="ipcss widthappt" [(ngModel)]="splId"
                            (change)="selecteSpl(splId)">
                            <option *ngFor="let spl of splList" value="{{spl.spl_id}}">{{spl.spl_name}}</option>
                          </select>
                        </mat-label>
                      </div>
                    <div class="col-12 col-sm-6 col-md-5 col-lg-3 col-xl-2">
                      <mat-label class="matlabel">Date
                        <input type="date" class="ipcss"(change)="selectAppointmentDate(appointmentDate)" 
                        required [(ngModel)]="appointmentDate" min="{{currDate}}"  #matInput >
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3" *ngIf="availableFlag">
                      <mat-label class="matlabel">Visiting session<br>
                        <select class="ipcss widthappt" [(ngModel)]="visitSession"
                          (change)="changeVisitSession(visitSession, appointmentDate)" required>
                          <option *ngFor="let session of visitingSessionData" value="{{session.description}}">
                            {{session.description}}</option>
                        </select>
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3" *ngIf="!appTypeTokenFlag">
                      <mat-label class="matlabel">Appointment Time
                        <select required class="ipcss widthappt" [(ngModel)]="visitTime" required
                          *ngIf="!appTypeTokenFlag">
                          <option *ngFor="let time of timeList" value="{{time}}">{{time}}</option>
                        </select>
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3" *ngIf="appTypeTokenFlag">
                        <mat-label>&nbsp;</mat-label>
                      <mat-label class="matlabel">
                        <p *ngIf="appTypeTokenFlag">Appointment type : Token</p>
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3" *ngIf="nonAvailableFlag">
                        <mat-label>&nbsp;</mat-label>
                      <mat-label *ngIf="nonAvailableFlag" class="matlabel">Yourself not available at this time, choose another date to proceed</mat-label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="cover_div" style="padding-bottom: 13px;">
                <div class="header_lable">
                  Profile details
                </div>
                <div class="content_cover">
                  <div class="row">
                    <p class="tervys_heading" [hidden]="searchFlag"> Profile</p>
                    <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3" [hidden]="searchFlag">
                      <mat-label class="matlabel">Filter
                        <select disableOptionCentering class="ipcss " (change)="filterByPatients()" [(ngModel)]="filter">
                          <option value="First name">First name</option>
                          <option value="Mobile">Mobile</option>
                          <option value="Patient Id">Patient</option>
                        </select>
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 " [hidden]="searchFlag">
                      <div class="row">
                        <div class="col-10">
                          <mat-label class="matlabel "><br>
                            <input class="ipcss " [(ngModel)]="patientFName" (selecionChange)="changeName()"
                              (blur)="patientNameToUpper()" placeholder={{somePlaceholder}} [maxLength]="maxLength">
                          </mat-label>
                        </div>
                        <div class="col-2 p-0" [hidden]="searchFlag">
                          <mat-label class="matlabel ">
                            <a (click)="changePatientName(patientFName)"><img src="../../../assets/ui_icons/search.svg"
                                class="seacrhicon" width="25" height="auto" /></a>
                          </mat-label>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3" [hidden]="searchFlag">
                      <mat-label class="matlabel">Patient id
                        <!-- class="form-control" -->
                        <mat-select [(ngModel)]="clientId" class="ipcss widthappt" required>
                          <mat-option *ngFor="let client of clientList" value={{client.patient}}
                            (click)="changeClient(client.patient)">
                            {{client.patient}}</mat-option>
                        </mat-select>
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                      <mat-label class="matlabel">Salutation
                        <br>
                        <select class="ipcss widthappt" [(ngModel)]="clntSal"
                          (ngModelChange)="changeSalutation($event)">
                          <option value="select" Selected>Select</option>
                          <option *ngFor="let sal of clntSalList" value="{{sal.sal_id}}">{{sal.sal_desc}}</option>
                        </select>
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                      <mat-label class="matlabel">First name
                        <input class="ipcss widthappt" [(ngModel)]="clntFirstName" (blur)="fnameToUpper()" required matInput
                          [disabled]="retrvDisabledFlag" />
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                      <mat-label class="matlabel">Middle name
                        <input class="ipcss widthappt" [(ngModel)]="clntMiddleName" (blur)="mnameToUpper()"
                          [disabled]="retrvDisabledFlag" matInput />
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                      <mat-label class="matlabel">Last name
                        <input class="ipcss widthappt" [(ngModel)]="clntLastName" (blur)="lnameToUpper()"
                          [disabled]="retrvDisabledFlag" required matInput />
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                      <mat-label class="matlabel">DOB
                        <input type="date" class="ipcss_date noappt_width heightdob" (change)="selectDob(clntDOB)"
                          [(ngModel)]="clntDOB" max="{{currentMaxDate}}" required #matInput [disabled]="dobDisabledFlag">
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                      <mat-label class="matlabel">Age
                        <input [(ngModel)]="clntAge" [readonly]="ageRead" [required]="!ageRead" class="ipcss widthappt"
                          required matInput [disabled]="ageDisabledFlag" (keyup)="changeDob()">
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                      <mat-label class="matlabel">Mobile
                        <input [(ngModel)]="clntContNumber" maxlength="10" required class="ipcss widthappt" matInput
                          [disabled]="retrvDisabledFlag" onkeypress="return event.charCode >= 48 && event.charCode <= 57" (blur)="validateMobile()">
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                      <mat-label class="matlabel">Emergency contact1
                        <input [(ngModel)]="clntEmergNumber" maxlength="15" class="ipcss widthappt" matInput
                          [disabled]="retrvDisabledFlag">
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                      <mat-label class="matlabel">Emergency contact2
                        <input [(ngModel)]="clntEmergNumber1" maxlength="15" class="ipcss widthappt" matInput
                          [disabled]="retrvDisabledFlag">
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3" [hidden]="userTypeFlag">
                      <mat-label class="matlabel">Gender
                        <input class="ipcss widthappt" [(ngModel)]="clntGender" matInput [disabled]="retrvDisabledFlag" />
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3" [hidden]="!userTypeFlag">
                      <mat-label class="matlabel">Gender<br>
                        <select [(ngModel)]="clntGender" class="ipcss widthappt" [disabled]="retrvDisabledFlag"
                          (ngModelChange)="changeGender($event)">
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Transgender">Transgender</option>
                        </select>
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3" [hidden]="covidFlag">
                      <mat-label class="matlabel">Symptoms
                        <input type="text" class="ipcss noappt_width" [(ngModel)]="clntSymptom" (keyup)="getSymptoms()"
                          matInput />
                        <div class="auto_complete_text" *ngIf="symptomList.length != 0">
                          <ul *ngFor="let symptom of symptomList">
                            <li>
                              <a (click)="selectSymptom(symptom)">{{symptom}}</a>
                            </li>
                          </ul>
                        </div>
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                      <mat-label class="matlabel">Father / Guardian name
                        <input class="ipcss widthappt" [(ngModel)]="clntFatherOrGuardName" matInput
                          (keyup)="updateUserprofile()" />
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                      <mat-label class="matlabel">Address1
                        <!--  [disabled]="userTypeFlag"-->
                        <input type="text" class="ipcss widthappt" [(ngModel)]="clntAddress1" required
                          (keyup)="updateUserprofile()" matInput />
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                      <mat-label class="matlabel">Address2
                        <!--  [disabled]="userTypeFlag"-->
                        <input type="text" class="ipcss widthappt" [(ngModel)]="clntAddress2" matInput
                          (keyup)="updateUserprofile()" />
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                      <mat-label class="matlabel">Zipcode
                        <input type="text" class="ipcss widthappt" [(ngModel)]="clntZipcode" (keyup)=zipcode_change() matInput />
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                      <mat-label class="matlabel">Location<br>
                        <input type="text" class="ipcss widthappt" required maxlength="50" [(ngModel)]="clntLocation"
                          (keyup)="changeLocation(clntLocation)" matInput />
                          <div class="auto_complete_text" *ngIf="clntLocationList.length != 0">
                            <ul *ngFor="let location of clntLocationList">
                              <li>
                                <a (click)="selectLocation(location)">{{location.description}}</a>
                              </li>
                            </ul>
                          </div>
                      </mat-label>
                    </div>
  
                    <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                      <mat-label class="matlabel">District / City<br>
                        <input matInput class="ipcss widthappt" [(ngModel)]="clntCity" [disabled]="userTypeFlag"
                          [hidden]="!existUserFlag">
                        <mat-select required class="ipcss widthappt" [(ngModel)]="clntCity" [hidden]="existUserFlag"
                          disableOptionCentering (ngModelChange)="changeCity(clntCity, '0')">
                          <mat-option *ngFor="let city of clntCityList" value="{{city.city_desc}}">{{city.city_desc}}
                          </mat-option>
                        </mat-select>
                      </mat-label>
                    </div>
  
                    <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                      <mat-label class="matlabel">State<br>
                        <input type="text" matInput class="ipcss widthappt" [(ngModel)]="clntState"
                          [disabled]="userTypeFlag" [hidden]="!existUserFlag">
                        <mat-select required class="ipcss widthappt" [(ngModel)]="clntState" disableOptionCentering
                          [hidden]="existUserFlag" (ngModelChange)="changeState(clntState, '0')">
                          <mat-option *ngFor="let state of clntStateList" value={{state.state_desc}}>{{state.state_desc}}
                          </mat-option>
                        </mat-select>
                      </mat-label>
                    </div>
  
                    <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                      <mat-label class="matlabel">Country<br>
                        <input type="text" class="ipcss widthappt" [(ngModel)]="clntCountry" [disabled]="userTypeFlag"
                          [hidden]="!existUserFlag" >
                        <mat-select required [(ngModel)]="clntCountry" class="ipcss widthappt" [hidden]="existUserFlag"
                          disableOptionCentering >
                          <mat-option *ngFor="let country of clntCountryList" value={{country.country_desc}}>
                            {{country.country_desc}}</mat-option>
                        </mat-select>
                      </mat-label>
                    </div>
                    
                    <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                      <mat-label class="matlabel"> Referred by<br>
                        <input type="text" class="ipcss widthappt" maxlength="50" (keyup)="getReferral($event)"
                          [(ngModel)]="referredDoctor" matInput [matAutocomplete]="auto" />
                        <mat-autocomplete #auto="matAutocomplete">
                          <mat-option (click)="selectReferredDoctor(item)" *ngFor="let item of referredDoctorList"
                            value={{item}}>
                            {{item}}
                          </mat-option>
                        </mat-autocomplete>
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3"></div>
                    <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3"></div>
                  </div>
                </div>
              </div>
              <div class="cover_div" style="padding-bottom: 13px;">
                <div class="header_lable">
                  Observations details
                </div>
                <div class="content_cover">
                  <div class="row">
                    <div class="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-2">
                      <mat-label class="matlabel">Height
                        <input class="ipcss " (change)="calculateCalories()" (keyup)="updateUserprofile()"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57" [(ngModel)]="height"
                          matInput />
                      </mat-label>
                    </div>
                    <div class="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-2">
                      <mat-label class="matlabel">Measure<br>
                        <select class="ipcss widthch1" [(ngModel)]="hmeasure" (change)="calculateCalories()">
                          <option value="cms">cms</option>
                          <option value="inch">inch</option>
                        </select>
                      </mat-label>
                    </div>
                    <div class="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-2">
                      <mat-label class="matlabel">Weight<br>
                        <input class="ipcss widthch1" (change)="calculateCalories()" (keyup)="updateUserprofile()"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57" [(ngModel)]="weight"
                          matInput />
                      </mat-label>
                    </div>
                    <div class="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-2">
                      <mat-label class="matlabel">Measure<br>
                        <select class="ipcss widthch1" [(ngModel)]="wmeasure" (change)="calculateCalories()">
                          <option value="kgs">kgs</option>
                          <option value="lbs">lbs</option>
                        </select>
                      </mat-label>
                    </div>
                    <div class="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-2">
                      <mat-label class="matlabel">BMI
                        <input class="ipcss " [(ngModel)]="bmi" disabled matInput />
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-4 col-md-3 col-lg-4 col-xl-4" [hidden]="bloodPressureFlag">
                      <mat-label class="matlabel">Blood pressure</mat-label>
                      <div class="row" [hidden]="bloodPressureFlag">
                        <div class=" col-6" [hidden]="bloodPressureFlag">
                          <input type="text" [hidden]="bloodPressureFlag" class="ipcss widthch1" placeholder="mm Hg"
                            maxlength="3" [(ngModel)]="BP01" (keyup)="updateUserprofile()"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                        </div>
                        <div class="col-6" [hidden]="bloodPressureFlag">
                          <input type="text" [hidden]="bloodPressureFlag" class="ipcss widthch1" placeholder="mm Hg"
                            maxlength="3" [(ngModel)]="BP02" (keyup)="updateUserprofile()"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                        </div>
                      </div>
                    </div>
                    <div class="col-2">
                      <mat-label class="matlabel">Temperature
                        <input matInput class="ipcss noappt_width" [(ngModel)]="temperature" (keyup)="updateUserprofile()">
                      </mat-label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mdb-card-body>
      </mdb-card> <br><br><br>
    </div>
  </div>