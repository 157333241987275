<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white ">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Discount master</h5>
          </div>
          <div class="headerButtons">
            <img *ngIf="saveFlag" src="../../../assets/ui_icons/buttons/save_button.svg" class="saveimgbtn_inpatinfo"
              (click)="sendDiscount()" />
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row">
          <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
            <div class="row">
              <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div class="cover_div" style="padding-bottom: 13px;">
                  <div class="header_lable">
                    Sales volume
                  </div>
                  <div class="content_cover">
                    <div class="row">
                      <div class="col-4">
                        <input class="ipcss" [(ngModel)]="salVol" maxlength="7"
                           matInput>
                      </div>
                      <div class="col-4">
                        <input class="ipcss" [(ngModel)]="salVolDisc" maxlength="2"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57" matInput>
                      </div>
                      <div class="col-4">
                        <img src="../../../assets/ui_icons/buttons/add_button.svg" class="saveimgbtn_inpatinfo"
                          (click)="addSalesVolume()" />
                      </div>
                      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div class="table-responsive dig_table_overflow">
                          <table id="salesTable" class="table table-striped table-sm table-hover discount_table">
                            <thead>
                              <tr>
                                <th style="width: 35%;">Volume</th>
                                <th style="width: 35%;">Discount (%)</th>
                                <th>&nbsp;</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let salesvol of discSalesVolArray">
                                <td>{{salesvol.volume}}</td>
                                <td>{{salesvol.discount}}</td>
                                <td style="text-align: center;">
                                  <a (click)="editSalesVolume(salesvol.volume,salesvol.discount)">
                                    <img src="../../../assets/ui_icons/buttons/pencil_icon.svg" width="20" height="20"
                                      style="margin: 0 5px;" /></a>
                                  <a (click)="deleteSalesVolume(salesvol.volume,salesvol.discount)">
                                    <img src="../../../assets/ui_icons/buttons/trash_icon.svg" width="20" height="20"
                                      style="margin: 0 5px;" /></a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
            <div class="cover_div" style="padding-bottom: 13px;">
              <div class="header_lable">
                Transactions per month
              </div>
              <div class="content_cover">
                <div class="row">
                  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div class="row">
                      <div class="col-4">
                        <input class="ipcss" [(ngModel)]="transVol" maxlength="7"
                           matInput>
                      </div>
                      <div class="col-4">
                        <input class="ipcss" [(ngModel)]="transVolDisc" maxlength="2"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57" matInput>
                      </div>
                      <div class="col-4">
                        <img src="../../../assets/ui_icons/buttons/add_button.svg" class="saveimgbtn_inpatinfo"
                          (click)="addTransVolume()" />
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div class="table-responsive dig_table_overflow">
                      <table id="TransactionTable" class=" discount_table table table-striped table-sm table-hover ">
                        <thead>
                          <tr>
                            <th style="width: 35%;">Transaction</th>
                            <th style="width: 35%;">Discount (%)</th>
                            <th>&nbsp;</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let transvol of discTransVolArray">
                            <td>{{transvol.volume}}</td>
                            <td>{{transvol.discount}}</td>
                            <td style="text-align: center;">
                              <a (click)="editTransVolume(transvol.volume,transvol.discount)">
                                <img src="../../../assets/ui_icons/buttons/pencil_icon.svg" width="20" height="20"
                                  style="margin: 0 5px;" /></a>
                              <a (click)="deleteTransVolume(transvol.volume,transvol.discount)">
                                <img src="../../../assets/ui_icons/buttons/trash_icon.svg" width="20" height="20"
                                  style="margin: 0 5px;" /></a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
            <div class="cover_div" style="padding-bottom: 13px;">
              <div class="header_lable">
                Regular customer
              </div>
              <div class="content_cover">
                <div class="row">
                  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div class="row">
                      <div class="col-4">
                        <input class="ipcss" [(ngModel)]="regsalVol" maxlength="7"
                         matInput>
                      </div>
                      <div class="col-4">
                        <input class="ipcss" [(ngModel)]="regsalVolDisc" maxlength="2"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57" matInput>
                      </div>
                      <div class="col-4">
                        <img src="../../../assets/ui_icons/buttons/add_button.svg" class="saveimgbtn_inpatinfo"
                          (click)="addRegSalesVolume()" />
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div class="table-responsive dig_table_overflow">
                      <table id="RegularCustomer" class="discount_table table table-striped table-sm table-hover">
                        <thead>
                          <tr>
                            <th style="width: 35%;">Sales volume</th>
                            <th style="width: 35%;">Discount (%)</th>
                            <th>&nbsp;</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let rsalesvol of discRegsalesVol">
                            <td>{{rsalesvol.volume}}</td>
                            <td>{{rsalesvol.discount}}</td>
                            <td style="text-align: center;">
                              <a (click)="editRegSalesVolume(rsalesvol.volume,rsalesvol.discount)">
                                <img src="../../../assets/ui_icons/buttons/pencil_icon.svg" width="20" height="20"
                                  style="margin: 0 5px;" /></a>
                              <a (click)="deleteRegSalesVolume(rsalesvol.volume,rsalesvol.discount)">
                                <img src="../../../assets/ui_icons/buttons/trash_icon.svg" width="20" height="20"
                                  style="margin: 0 5px;" /></a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>