import { Component, OnInit } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { Helper_Class } from '../../helper_class';
import { DiagnosisService } from '../diagnosis/diagnosis.service';
import { Diagnosis_Helper } from '../Diagnosis_Helper';
import { Message_data } from '../../../assets/js/Message_data';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Time_Formate, Date_Formate } from '../../../assets/js/common.js';
import { ipaddress } from '../../ipaddress';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';

@Component({
  selector: 'app-diag-patient-history',
  templateUrl: './diag-patient-history.component.html',
  styleUrls: ['./diag-patient-history.component.scss']
})
export class DiagPatientHistoryComponent implements OnInit {

  public diagCenterId;
  public relationId;
  public subRelationId;
  public clientRegId;
  public testCategory;
  public patientHistoryList: any = [];
  dtOptions: DataTables.Settings = {};

  constructor(public messageservice: MenuViewService, public gservice: CommonDataService, public toastr: ToastrService, public http: Http, public router: Router) { }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      "lengthMenu": [[25, 50, -1], [25, 50, "All"]],
      "order": [[1, 'asc']],
      "columnDefs": [
        { "orderable": false, "targets": [0, 2] },
      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search by name or test type or test name"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };

    this.diagCenterId = Diagnosis_Helper.getPatientDetails().diag_centre_id;
    this.relationId = Diagnosis_Helper.getPatientDetails().relation_id;
    this.subRelationId = Diagnosis_Helper.getPatientDetails().sub_rel_id;
    this.clientRegId = Diagnosis_Helper.getPatientDetails().client_reg_id;
    this.testCategory = Diagnosis_Helper.getPatientDetails().test_cat_id;

    this.getPatientHistory();
  }

  getPatientHistory() {
    var send_data = {
      client_reg_id: this.clientRegId,
      relation_id: this.relationId,
      sub_rel_id: this.subRelationId,
      diag_centre_id: this.diagCenterId,
      test_cat_id: this.testCategory,
    }

    if(this.relationId == undefined) {
      this.relationId = '1';
    } 
  
    console.log("send_data", send_data);

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/grbc',
      JSON.stringify({
        client_reg_id: this.clientRegId,
        relation_id: this.relationId,
        sub_rel_id: this.subRelationId,
        diag_centre_id: this.diagCenterId,
        test_cat_id: this.testCategory,
      }),
      { headers: headers })
      .subscribe(
        response => {
          this.patientHistoryList = [];
          var obj = JSON.parse(response["_body"]);
          console.log("Patient History Details", obj)

          if (obj.test_history != null) {
            for (var i = 0; i < obj.test_history.length; i++ ) {
              this.patientHistoryList.push({
                appointment_date: obj.test_history[i].appointment_date,
                diag_appointment_id: obj.test_history[i].diag_appointment_id,
                reading: obj.test_history[i].reading,
                sub_test_id: obj.test_history[i].sub_test_id,
                sub_test_name: obj.test_history[i].sub_test_name,
                test_id: obj.test_history[i].test_id,
                test_name: obj.test_history[i].test_name,
              })
            }

            console.log("this.patientHistoryList", this.patientHistoryList);

          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());

        });

  }

  backNAV(){
    this.messageservice.sendMessage("report_approval_detailed");
  }

}
