import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Helper_Class } from '../../helper_class';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { Client_Helper } from '../Client_helper';
import { ipaddress } from '../../ipaddress';
import { ClientViewService } from '../clientview/client-view.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-package-upgrade',
  templateUrl: './package-upgrade.component.html',
  styleUrls: ['./package-upgrade.component.scss']
})
export class PackageUpgradeComponent implements OnInit {
  public userInfo;
  public modules = [];
  public packIdList = [];
  public packageModels;
  public packageList = [];

  constructor(public gservice: CommonDataService, public http: HttpClient, public routes: ActivatedRoute, public router: Router, public toastr: ToastrService,
    public messageservice: ClientViewService) {     
  }

  ngOnInit(): void {
    this.userInfo = Helper_Class.getInfo();
    this.modules = [];
    if (this.userInfo.both_modules != null && this.userInfo.both_modules != undefined) {
      for (var i = 0; i < this.userInfo.both_modules.length; i++) {
        this.modules.push(this.userInfo.both_modules[i].module_id);
      }
    }
    if (this.userInfo.home_modules != null) {
      for (var j = 0; j < this.userInfo.home_modules.length; j++) {
        this.modules.push(this.userInfo.home_modules[j].module_id);
      }
    }
    this.getClientPackage();
  }

  getClientPackage() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'adm/gpkgm', { "user": "client" },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.setPackage(obj);
        },
        error => {
          this.toastr.error("Internet is too slow or no connection, try again later");
        }
      )
  }
  
  setPackage(data) {
    for (var i = 0; i < data.package_details.length; i++) {
      var temp_array = [];
      for (var j = 0; j < data.package_details[i].package_modules.length; j++) {
        temp_array.push(data.package_details[i].package_modules[j]);
      }
      var Imagearray = [];
      for (var k = 0; k < data.modules.length; k++) {
        if (temp_array.indexOf(data.modules[k].module_id) > -1) {
        }
        else {
        }
      }
      this.packIdList.push(Imagearray);
    }
    this.packageModels = data.modules;
    for(var i=0; i <this.modules.length;i++){
      var res = this.packageModels.filter(o=> o.module_id === this.modules[i]);
      if(res.length != 0){
          this.packageList.push({
            module_id:res[0].module_id,
            package_name:res[0].package_name,
            checked: true,
            status:"1",
          });
      }
      }    
      this.retrival();  
  }

  retrival(){
    var senddata={
      client_reg_id:this.gservice.Client_login_detail_data.Client_Id
    }
    var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'usercontroller/gmc', (senddata),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));
            for (var i = 0; i < obj.saved_modules.length; i++) {
              for (var j = 0; j < this.packageList.length; j++) {
                if(obj.saved_modules[i].module_id == this.packageList[j].module_id) {
                  this.packageList[j].status = obj.saved_modules[i].status ;
                  if(this.packageList[j].status == "0"){
                    this.packageList[j].checked=false;                    
                  }
                  this.packageList[j].module_id = obj.saved_modules[i].module_id;
                  this.packageList[j].package_name = this.packageList[j].package_name;
                }
                Client_Helper.setpackage_change( this.packageList[j].module_id);
             }
            }
          });
  }

  selectPackage(flag,packageList){ 
    packageList.status = (flag == false) ? "0" : "1";
  }

  save(){
    var flag: boolean = true;
    if (flag == true) {
      var sendData={
        client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
        package_id:this.userInfo.package_id,
        modules:this.packageList,        
      }
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'usercontroller/smc', (sendData),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));            
            if (obj.key == "1") {  
              Helper_Class.setModules(undefined);
              var moduleData = [],bothModulList = [];
              if (obj.both_modules != null && obj.both_modules != undefined) {
                moduleData = [];
                for (var i = 0; i < obj.both_modules.length; i++) {
                  obj.both_modules[i].module_id;
                  moduleData.push(obj.both_modules[i].module_id);
                  bothModulList.push(moduleData);
                }
              }
              if (obj.home_modules != null) {
                moduleData = [];
                for (var j = 0; j < obj.home_modules.length; j++) {
                  moduleData.push(obj.home_modules[j].module_id);
                  bothModulList.push(moduleData);
                }
              }
              if(bothModulList.length != 0)
                Helper_Class.setModules(bothModulList);
                this.toastr.success(obj.result);  
            } else {
              this.toastr.error(obj.result);
            }
          });
    } 
  } 
}
