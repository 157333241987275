import { Component, OnInit } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { ipaddress } from '../../ipaddress';

@Component({
  selector: 'app-diagnosis-help',
  templateUrl: './diagnosis-help.component.html',
  styleUrls: ['./diagnosis-help.component.scss']
})
export class DiagnosisHelpComponent implements OnInit {
  public userType: string;
  public moduleArray = [];
  public helpArray = [];
  public module : any;

  constructor(private http:Http,public toastr:ToastrService) { 
  }

  ngOnInit(): void {
    this.userType="1";
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "usercontroller/gmodules",
      JSON.stringify({
        user_type: this.userType,
        package_id:localStorage.getItem("package_id"),
      }),
      { headers: headers })
      .subscribe(
        response => {
          this.moduleArray=[];
          var obj =response.json();
          if(obj.length !=0 && obj.modules != undefined){
            for(var i=0;i<obj.modules.length;i++){
              this.moduleArray.push({
                module_id: obj.modules[i].module_id,
                description: obj.modules[i].description
              });
            }
          }
        });
  }

  changeTopic(data){
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "usercontroller/ghelp",
      JSON.stringify({
        module_id: data,        
      }),
      { headers: headers })
      .subscribe(
        response => {
          this.helpArray=[];         
          var obj =response.json();
          if(obj.length !=0 && obj.help_topics != undefined){
            for(var i=0;i<obj.help_topics.length;i++){
              this.helpArray.push({
                helpdesc: obj.help_topics[i].help_desc,                
              });
            }
          }
        });
  }
}
