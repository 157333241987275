import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { ipaddress } from '../../ipaddress';


@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss']
})
export class ContactusComponent implements OnInit {
  public name;
  public email;
  public comment;

  constructor(private http: HttpClient, private toastr: ToastrService) { }

  ngOnInit(): void {
  }

  submitInfo() {
    var send_data = {
      user_type: this.name,
      email: this.email,
      comments: this.comment
    }
    console.log("CONTACT SEND " + JSON.stringify(send_data));

    var headers = new HttpHeaders()
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "adm/cus", send_data, { headers: headers }).subscribe(response => {
      var obj = JSON.parse(JSON.stringify(response))
      if (Object.keys(obj).length != 0) {
        this.toastr.success("Thank you for contacting us, we will get back to you")

      } else {
        this.toastr.error("Something went wrong")
      }
    })
  }

}
