<div class="row">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Exercise pattern</h5>
                    </div>
                    <div class="headerButtons">
                        <img *ngIf="saveFlag" class="saveimgbtn_inpatinfo" (click)="save()"
                            src="../../../assets/ui_icons/buttons/save_button.svg" />
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>
                <div class="row">
                    <div class="col-12" id="exercise">
                        <owl-carousel-o [options]="customOptions">
                            <ng-template carouselSlide *ngFor="let relation of relationlistData;">
                                <div class="slide">
                                    <div class="cu_slider_cover">
                                        <div class="card_border1"
                                            (click)="selectedRelation(relation.rel_name,relation.rel_id,relation.sub_rel_id,relation.life_style_id,relation.weight,relation.weight_measure,relation.gender,relation.age)">
                                            <div class="d-flex justify-content-center">
                                                <img class="img-fluid" class="image" src="{{relation.prof_img}}"
                                                    alt="Card image cap">
                                            </div>
                                            <p class="carousel_label">{{relation.rel_name}}</p>
                                        </div>
                                        <a tabindex="0">
                                            <div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </ng-template>
                        </owl-carousel-o>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4" *ngFor="let exercise of physcialExercise" style="padding-bottom: 25px;">
                        <mat-card id="cardcontent">
                            <mat-card-title style="font-size: 14px;margin-left: 10px;padding-top: 5px;">
                                {{exercise.description}}
                                <span style="float: right;position: relative;right: 22px;">
                                    <mat-checkbox color="primary" [checked]="exercise.show"
                                        (change)="selectExercisePattern($event.checked,exercise)"></mat-checkbox>
                                </span>
                            </mat-card-title>
                            <mat-card-content>
                                <div style="text-align: center;">
                                    <img src="{{exercise.exerc_image}}"
                                        onerror="this.src='../../../assets/ui_icons/cycle_icon.svg'" width="140px"
                                        height="auto" />
                                </div>
                                <div class="row" style="justify-content: center;">
                                    <div class="col-10">
                                        <mat-label class="matlabel">Variation<br>
                                            <select disableOptionCentering class="ipcss"
                                                (ngModelChange)="selectVariations($event,exercise.act_desc,exercise)"
                                                [(ngModel)]="exercise.variations" [compareWith]="compareById">
                                                <option *ngFor="let variations of exercise.act_desc"
                                                    [ngValue]="variations"
                                                    [selected]="variations === exercise.variations">
                                                    {{variations.activity_name}}
                                                </option>
                                            </select>
                                        </mat-label>
                                    </div>
                                    <div class="col-10">
                                        <mat-label class="matlabel">Session<br>
                                            <select disableOptionCentering class="ipcss" [(ngModel)]="exercise.session" (ngModelChange)="selectSession($event,exercise)"
                                                required>
                                                <option selected value="">Select</option>
                                                <option value="Morning" selected>Morning</option>
                                                <option value="Afternoon">Afternoon</option>
                                                <option value="Evening">Evening</option>
                                            </select>
                                        </mat-label>
                                    </div>
                                    <div class="col-10">
                                        <mat-label class="matlabel" style="text-transform: capitalize;">
                                            Duration(mins)<br>
                                            <input class="ipcss" [(ngModel)]="exercise.activity_dur" (change)="changeDuration(exercise.activity_dur,exercise)">
                                        </mat-label>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>