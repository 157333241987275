import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { Date_Formate } from '../../../assets/js/common.js';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { CommonDataService } from '../../providers/common-data.service';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Client_Helper } from '../Client_helper';
import { ClientViewService } from '../clientview/client-view.service';
import { MedicineTrackHistoryComponent } from '../medicine-track-history/medicine-track-history.component';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';
import { encrypt_decript } from 'src/assets/js/Encription_Decription';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Helper_Class } from 'src/app/helper_class';

@Component({
  selector: 'app-medicine-track',
  templateUrl: './medicine-track.component.html',
  styleUrls: ['./medicine-track.component.css']
})
export class MedicineTrackComponent implements OnInit {
  public createdTime;
  public createdDate;
  public visitSession;
  public timeStartsFrom = [];
  public timeEndAt = [];
  public visitingSessionData = [];
  public sendData;
  public currentDateUrl;
  public presMedUrl;
  public medTrackerUrl;
  public currentDate;
  public currentTime;
  public checkCurrentTime;
  public sendMedTrackerData;
  public medicineHistList = [];
  public timeStart3;
  public timeStart2;
  public timeStart1;
  public drugId;
  public quantity;
  public subRelId;
  public relationId;
  public medicineHistListFlag: boolean;
  public saveFlag:boolean = true;
  public relationName;
  public relationlistData = [];
  public relationFName;
  public relationLName;
  public relationMName;
  public userInfo;
  public lifeStyleId;
  customOptions: OwlOptions = {
   autoplay: false,
   autoplaySpeed: 1500,
   loop: true,
   pullDrag: false,
   dots: false,
   navSpeed: 750,
   navText: ['<i class="fa fa-chevron-circle-left left" aria-hidden="true" style="font-size: 50px;color: #bfbbbb;position: absolute;left: 16px;top: 46%;z-index: 1;"></i>', '<i class="fa fa-chevron-circle-right right" aria-hidden="true"></i>'],
   responsive: {
     0: {
       items: 4
     },
     400: {
       items: 6
     },
     740: {
       items: 8
     },
     940: {
       items: 9
     }
   },
   nav: true,
  }

  constructor(public messageservice:ClientViewService,public dialog: MatDialog,public gservice:CommonDataService,public datepipe:DatePipe,public http:Http,public routes:ActivatedRoute,public router:Router,public toastr:ToastrService) { 
      this.medicineHistListFlag = false;
      this.currentDateUrl = ipaddress.getIp + "adm/curdate";
      this.presMedUrl = ipaddress.getIp + "lifestyle/pmeds";
      this.medTrackerUrl = ipaddress.getIp + "lifestyle/medtrack";
      for (var i = 1; i <= 12; i++) {
        if (i < 10) {
          this.timeStartsFrom.push("0" + i);
        } else {
          this.timeStartsFrom.push(i);
        }
      }
      for (var i = 0; i <= 55; i += 5) {
        if (i < 10) {
          this.timeEndAt.push("0" + i);
        } else {
          this.timeEndAt.push(i);
        }
      }
      this.timeStart1 = "06";
      this.timeStart2 = "00";
      this.timeStart3 = "AM";
      this.getSession();
      this.sendData = {
        country: ipaddress.country_code
      }
  }

  ngOnInit(): void {
    this.userInfo = Helper_Class.getInfo();
    this.saveFlag = true;
    this.getRelationlist();
  }

  async getRelationlist() {
    var obj1 = Client_Helper.getrelationlistDiet();
    this.relationlistData = [];
    if (obj1.relations != null && obj1.relations.length != 0) {
      if (obj1.relations != null) {
        if(this.userInfo.middle_name != undefined && encrypt_decript.Decript(this.userInfo.middle_name)){
          this.relationName = encrypt_decript.Decript(this.userInfo.first_name) + " " + encrypt_decript.Decript(this.userInfo.middle_name) + " " + encrypt_decript.Decript(this.userInfo.last_name);
        }else{
          this.relationName = encrypt_decript.Decript(this.userInfo.first_name) + " " + encrypt_decript.Decript(this.userInfo.last_name);
        }            
        if (this.userInfo.profile_image != null && this.userInfo.profile_image != undefined && this.userInfo.profile_image != '') {
          imgpath = this.userInfo.profile_image;
          img = ipaddress.Ip_with_img_address + this.userInfo.profile_image;
          this.relationId = "1";
          this.subRelId = "";
          this.lifeStyleId = this.userInfo.life_style_id;
        }
        this.relationlistData.push({
          rel_name: this.relationName,
          life_style_id: this.userInfo.life_style_id,
          rel_id: this.relationId,
          sub_rel_id: this.subRelId,
          prof_img: img,
          imgpath: imgpath,
        });
      }
      for (var f = 0; f < obj1.relations.length; f++) {
        var obj = obj1.relations[f];
        this.subRelId = null;
        if (obj.sub_rel_id != null) {
          this.subRelId = obj.sub_rel_id
        }
        this.relationFName = "";
        this.relationMName = "";
        this.relationLName = "";
        if (obj.middle_name != null) {
          this.relationFName = encrypt_decript.Decript(obj.first_name.toString());
          this.relationMName = encrypt_decript.Decript(obj.middle_name.toString());
          this.relationLName = encrypt_decript.Decript(obj.last_name.toString());
          this.relationName = encrypt_decript.Decript(obj.first_name.toString()) + " " + encrypt_decript.Decript(obj.middle_name.toString()) + " " + encrypt_decript.Decript(obj.last_name.toString());
        } else {
          this.relationFName = encrypt_decript.Decript(obj.first_name.toString());
          this.relationLName = encrypt_decript.Decript(obj.last_name.toString());
          this.relationName = encrypt_decript.Decript(obj.first_name.toString()) + " " + encrypt_decript.Decript(obj.last_name.toString());
        }
        var img = "../../../assets/imgs/default.jpg", imgpath = "../../../assets/imgs/default.jpg";
        if (obj.profile_image !== "default") {
          imgpath = obj.profile_image;
          img = ipaddress.Ip_with_img_address + obj.profile_image;
        }
        this.relationlistData.push({
          rel_name: this.relationName,
          rel_f_name: this.relationFName,
          rel_m_name: this.relationMName,
          rel_l_name: this.relationLName,
          rel_id: obj.relation_id,
          sub_rel_id: this.subRelId,
          gender_val: encrypt_decript.Decript(obj.gender),
          prof_img: img,
          imgpath: imgpath,
          loc_id: obj.location,
          loc_name: obj.location_desc,
          city_id: obj.city,
          city_desc: obj.city_desc,
          state_id: obj.state,
          state_desc: obj.state_desc,
          country_id: obj.country,
          country_desc: obj.country_desc,
          update_flag: true,
          life_style_id: obj.life_style_id,
        });
      }
    }else{
      if (this.userInfo.middle_name != undefined && encrypt_decript.Decript(this.userInfo.middle_name)) {
        this.relationName = encrypt_decript.Decript(this.userInfo.first_name) + " " + encrypt_decript.Decript(this.userInfo.middle_name) + " " + encrypt_decript.Decript(this.userInfo.last_name);
      } else {
        this.relationName = encrypt_decript.Decript(this.userInfo.first_name) + " " + encrypt_decript.Decript(this.userInfo.last_name);
      }
      if (this.userInfo.profile_image != null && this.userInfo.profile_image != undefined && this.userInfo.profile_image != '') {
        imgpath = this.userInfo.profile_image;
        img = ipaddress.Ip_with_img_address + this.userInfo.profile_image;
        this.relationId = "1";
        this.subRelId = "";
        this.lifeStyleId = this.userInfo.life_style_id;
      }
      this.relationlistData.push({
        rel_name: this.relationName,
        life_style_id: this.userInfo.life_style_id,
        rel_id: this.relationId,
        sub_rel_id: this.subRelId,
        prof_img: img,
        imgpath: imgpath,
      });
    }
    await this.getCurrentDate();
    if (this.relationlistData.length == 0) {
      this.selectRelation("", "1", undefined, this.userInfo.life_style_id);
    } else {
      this.selectRelation(this.relationlistData[0].rel_name, this.relationlistData[0].rel_id, this.relationlistData[0].sub_rel_id, this.relationlistData[0].life_style_id);
    }
  }

  selectRelation(name, rel_id, sub_rel_id, life_style_id){
    var data ={
        relation_id: rel_id,
        sub_rel_id: sub_rel_id,
        trackerFlag: Client_Helper.getTrackerFlag()
    }
    Client_Helper.setrelationsubrelationslide(null);
    var getdata = {
      relation_id: rel_id,
      sub_rel_id: sub_rel_id,
      life_style_id: life_style_id
    }
    Client_Helper.setrelationsubrelationslide(getdata);
    this.lifeStyleId = life_style_id;
    this.subRelId = sub_rel_id 
    this.relationId = rel_id;
    this.changeVisitSession(this.visitSession);
  }
  
  myCallbackFunction = function (_params) {
    return new Promise((resolve, reject) => {
      resolve(_params);
    });
  }

  getCurrentDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.currentDateUrl, JSON.stringify(this.sendData), { headers: headers }).subscribe(
      data => {
        this.currentDate = data.json().current_date;
        this.currentTime = data.json().current_time;
        this.createdDate = (data.json().current_date);
      }, error => {});
  }

  getSession() {
    this.visitingSessionData = [{ "id": 1, "description": "Morning" }, { "id": 2, "description": "Afternoon" }, { "id": 3, "description": "Evening" }];
    this.visitSession = this.visitingSessionData[0].description;
  }

  changeVisitSession(data) {
    this.medicineHistListFlag = false;
    this.sendMedTrackerData = {
      client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
      relation_id: this.relationId,
      time_string: data
    }
    if(this.subRelId != "" && this.subRelId != undefined && this.subRelId != null)
      this.sendMedTrackerData.sub_rel_id = this.subRelId;
    if (data != null && data.length != 0 && data != undefined) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.presMedUrl, JSON.stringify(this.sendMedTrackerData), { headers: headers }).subscribe(
        data => {
          this.medicineHistList = [];
          if (data.json().medicines != null && data.json().medicines.length != 0) {
            for (var i = 0; i < data.json().medicines.length; i++) {
              this.medicineHistList.push({
                drug_id: data.json().medicines[i].drug_id,
                drug_name: data.json().medicines[i].drug_name,
                quantity: data.json().medicines[i].quantity,
                short_form:data.json().medicines[i].short_form,
              });
            }
          } else {
            this.medicineHistListFlag = true;
            this.medicineHistList = [];
          }
        },
        error => {
        })
    } else {
    }
  }

  deleteDrug(Drug_id, ind) {
    for (var i = 0; i < this.medicineHistList.length; i++) {
      if (this.medicineHistList[i].drug_id == Drug_id) {
        this.medicineHistList.splice(i, 1);
        break;
      }
    }
  }

  selectApptDate(date,value) {
    if(value =="Appointment_Date"){
        this.createdDate = date;
    }
  }

  save() {
    var saveFlag = true;
    var Drug_Hist_List = [];
    if (this.createdDate == undefined) {
      this.toastr.error(Message_data.sltDate);
      saveFlag = false;
    }
    else if (this.visitSession == undefined) {
      this.toastr.error(Message_data.sltSession);
      saveFlag = false;
    }
    else {
        if (this.timeStart1 == undefined && this.timeStart2 == undefined) {
          this.toastr.error(Message_data.sltTime);
          saveFlag = false;
        }
        var Allottime = this.convertTime12to24(this.timeStart1 + ":" + this.timeStart2 + " " + this.timeStart3);
      var Timesplit = Allottime.split(':');
      this.checkCurrentTime = this.currentTime.split(':');
      var get_date = (this.createdDate);
      var curr_datetime = new Date(Date_Formate(get_date) + " " + this.currentTime);
      var cuur_mor_sess = "", cuur_afetr_sess = "", cuur_eve_sess = "";
      if (curr_datetime.getHours() >= 0 && curr_datetime.getHours() < 12) {
        cuur_mor_sess = "Morning";
        cuur_afetr_sess = "Afternoon";
        cuur_eve_sess = "Evening";
      }
      if (curr_datetime.getHours() >= 12 && curr_datetime.getHours() < 16) {
        cuur_afetr_sess = "Afternoon";
        cuur_eve_sess = "Evening";
      }
      if (curr_datetime.getHours() >= 16 && curr_datetime.getHours() < 24) {
        cuur_eve_sess = "Evening";
      }
      if (new Date(this.currentDate) < new Date((this.createdDate))) 
      {
        if (this.visitSession == "Morning") {
          if (parseInt(Timesplit[0]) >= 12) {
            this.toastr.error(Message_data.enterValidTime);
            saveFlag = false;
          }
        }
        else if (this.visitSession == "Afternoon") {
          if (parseInt(Timesplit[0]) < 12 || parseInt(Timesplit[0]) > 16) {
            this.toastr.error(Message_data.enterValidTime);
            saveFlag = false;
          }
        }
        else if (this.visitSession == "Evening") {
          if (parseInt(Timesplit[0]) < 16) {
            this.toastr.error(Message_data.enterValidTime);
            saveFlag = false;
          }
        }
      }
      else {
        if (this.visitSession == "Morning") {
          if (this.timeStart3 == "PM") {
            this.toastr.error(Message_data.enterValidTime);
            saveFlag = false;
          }
          else if (parseInt(Timesplit[0]) >= 12) {
            this.toastr.error(Message_data.enterValidTime);
            saveFlag = false;
          }
          else {
            if (parseInt(this.checkCurrentTime[0]) < parseInt(Timesplit[0])) {
              this.toastr.error(Message_data.enterValidTime);
              saveFlag = false;
            }
            else if (parseInt(this.checkCurrentTime[0]) == parseInt(Timesplit[0])) {
              if (parseInt(this.checkCurrentTime[1]) <= parseInt(Timesplit[1])) {
                this.toastr.error(Message_data.enterValidTime);
                saveFlag = false;
              }
            }
          }
        }
        else if (this.visitSession == "Afternoon") {
          if (this.timeStart3 == "AM") {
            this.toastr.error(Message_data.enterValidTime);
            saveFlag = false;
          }
          else if (parseInt(Timesplit[0]) < 12 || parseInt(Timesplit[0]) > 16) {
            this.toastr.error(Message_data.enterValidTime);
            saveFlag = false;
          }
          else {
            if (cuur_mor_sess != "" && (parseInt(this.checkCurrentTime[0]) > parseInt(Timesplit[0]))) {
              this.toastr.error(Message_data.enterValidTime);
              saveFlag = false;
            }
            else if (cuur_afetr_sess != "" && (parseInt(this.checkCurrentTime[0]) > parseInt(Timesplit[0]))) {
              this.toastr.error(Message_data.enterValidTime);
              saveFlag = false;
            }
            else if (parseInt(this.checkCurrentTime[0]) == parseInt(Timesplit[0])) {
              if (parseInt(this.checkCurrentTime[1]) <= parseInt(Timesplit[1])) {
                this.toastr.error(Message_data.enterValidTime);
                saveFlag = false;
              }
            }
          }
        }
        else if (this.visitSession == "Evening") {
          if (this.timeStart3 == "AM") {
            this.toastr.error(Message_data.enterValidTime);
            saveFlag = false;
          }
          else if (parseInt(Timesplit[0]) < 16) {
            this.toastr.error(Message_data.enterValidTime);
            saveFlag = false;
          }
          else {
            if (parseInt(this.checkCurrentTime[0]) > parseInt(Timesplit[0])) {
              this.toastr.error(Message_data.enterValidTime);
              saveFlag = false;
            }
            else if (parseInt(this.checkCurrentTime[0]) == parseInt(Timesplit[0])) {
              if (parseInt(this.checkCurrentTime[1]) <= parseInt(Timesplit[1])) {
                this.toastr.error(Message_data.enterValidTime);
                saveFlag = false;
              }
            }
          }
        }
      }
    }
      this.createdTime = this.timeStart1;
    for (var i = 0; i < this.medicineHistList.length; i++) {
      if (saveFlag == true && (this.medicineHistList[i].quantity == "" || this.medicineHistList[i].quantity == "0")) {
        this.toastr.error(Message_data.validQty);
        saveFlag = false;
      }
      Drug_Hist_List.push({
        drug_id: this.medicineHistList[i].drug_id,
        quantity: this.medicineHistList[i].quantity,
        intake: this.visitSession,
        created_date: (this.createdDate),
        created_time: (this.createdTime),
      });
    }
    if (saveFlag == true) {
      this.sendMedTrackerData = {
        client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
        relation_id: this.relationId,
        sub_rel_id: this.subRelId,
        medicines: Drug_Hist_List,
        country: ipaddress.country_code
      }
      if( Drug_Hist_List.length != 0 ){
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(this.medTrackerUrl, JSON.stringify(this.sendMedTrackerData), {
          headers: headers
        }).subscribe(
          data => {
            var obj = JSON.parse(data["_body"]);
            if (obj.key != null) {
              if (obj.key == "1") {
                this.toastr.success(Message_data.saveSuccess);
                this.saveFlag = false;
                Client_Helper.setTrackerFlag("medicine");
               // this.messageservice.sendMessage("medicine");
              }
              else {
                this.toastr.error(Message_data.notSavedSuccess);
              }
            }
          }), error => {
          }
      }else{
        this.toastr.error("No medicine list to save");
      }
     
    }
  }
    
  medHistory() {
    const dialogRef = this.dialog.open(MedicineTrackHistoryComponent, {
      width: '85%',
      height: '500px',
      data: {
        relation_id: this.relationId,
        sub_rel_id: this.subRelId,
        flow:"client"
      }
    });
  }

  convertTime12to24(time12h) {
    const [time, modifier] = time12h.split(' ');
    let [hours, minutes] = time.split(':');
    if (hours === '12') {
      hours = '00';
    }
    if (modifier === 'PM') {
      hours = parseInt(hours, 10) + 12;
    }
    return hours + ':' + minutes;
  }

  back(){
    Client_Helper.setTrackerFlag("medicine");
    this.messageservice.sendMessage("medicine");
  }
}
