import { Component, OnInit ,Input } from '@angular/core';
import { Router } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { Date_Formate } from '../../../assets/js/common';
import { MatDialog } from '@angular/material/dialog';
import { ClientViewService } from '../clientview/client-view.service';
import { Client_Helper } from '../Client_helper';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';

@Component({
  selector: 'app-bloodrequest',
  templateUrl: './bloodrequest.component.html',
  styleUrls: ['./bloodrequest.component.css']
})
export class BloodrequestComponent implements OnInit {
  @Input() id: string;parm: string;  
  public currentMaxDate;
  public currentYear: string;
  public currentDate: string;
  public currentTime:string;
  public appointmentDate;
  public hospitalName: string;
  public hospitalId: string = "0";
  public locationId: string;
  public locationName: string;
  public countryId: string;
  public zipcode: string;
  public bloodId: string;
  public unit: string;
  public hospitalUrl;
  public hospitalList = [];
  public hospitalListData;
  public hospitalListMainArray;
  public bgrpList = [];
  public locationList = [];
  public locationListData;
  public locationListMainArray;
  public cityUrl: string;
  public stateUrl: string;
  public countryUrl: string;
  public getHospitalLocationUrl: string;
  public cityList;
  public stateList;
  public countryList;
  public filtCity;
  public filtState;
  public filtCountry;
  public filtCityDesc;
  public filtStateDesc;
  public toastMessage: string;

  constructor(public clientservice:ClientViewService,public gservice:CommonDataService,public toastr:ToastrService, public  http:Http,public  router:Router,public dialog: MatDialog) {
      this.locationName = this.gservice.Client_login_detail_data.Client_Location;
      this.hospitalUrl = ipaddress.getIp + "usercontroller/hospital/";
      this.cityUrl = ipaddress.getIp + "gen/city";
      this.stateUrl = ipaddress.getIp + "gen/state";
      this.countryUrl = ipaddress.getIp + "gen/count";
      this.getHospitalLocationUrl = ipaddress.getIp + "usercontroller/hospitalloc/";
  }

  ngOnInit() {
    this.locationFilter(this.locationName);
    this.getBloodgrp();
    this.getCurrentDate();
  }

  OnDateChangedto(date) {
    if (date != null) {
      this.appDateChange(date);
    }
  }

  appDateChange(Appointment_Date) {
    if (this.currentDate != Appointment_Date) {
      var gsDayNames = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
      ];
    }
  }

  locationFilter(loc_name) {
    this.locationName =loc_name;
    this.locationList = [];
      this.locationListData = [];
      if (this.locationName !== "" && this.locationName.length > 2) {
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp + "gen/loc",
          JSON.stringify({
            loc_name: this.locationName
          }),
          { headers: headers })
          .subscribe(
            response => {
              var obj = JSON.parse(response["_body"]);
              this.locationListMainArray = obj.locations;

              for (var i = 0; i < this.locationListMainArray.length; i++) {
                this.locationListData.push(this.locationListMainArray[i]);
              }
              this.locationList = this.locationListData.filter(function (this:any,el) {
                return el.toLowerCase().indexOf(this.loc_name.toLowerCase()) > -1;
              }.bind(this));
            },error => {});
      } else {
        this.locationList = [];
        this.cityList = [];

      } 
  }

  selectLocation(loc_name) {
    this.locationName = loc_name;
    this.locationList = [];
    this.stateList = [];
    this.cityList = [];
    this.countryList = [];
    this.getCity(this.locationName);
  }

  getCity(data) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');

    this.http.post(this.cityUrl, JSON.stringify({ loc_name: data }), { headers: headers }).subscribe(
      data => {
        if (data.json().cities != null) {
          this.cityList = data.json().cities;
          this.filtCity = this.cityList[0].city_id;
          this.filtCityDesc = this.cityList[0].city_desc;
          this.filtState = this.cityList[0].state_id;
          this.filtStateDesc = this.cityList[0].state_desc;
          this.locationId = this.cityList[0].location_id;
          this.zipcode = this.cityList[0].zipcode;
          this.cityChange(this.cityList[0].city_id);
        }
      },
      error => {
        
      })
  }

  countryChange(data) {
    // this.pass_param_data = 
    // {
    //   identify:"search",
    //   location:this.clnt_location,
    //   city:this.filt_state_desc,
    //   state:this.filt_state_desc,
    //   country:data,
    // }

    // if(data != null)
    // {
    //   this.viewcontrl.dismiss(this.pass_param_data);
    // }
  }

  cityChange(data) {
    if (data != null) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.stateUrl, JSON.stringify({ city_id: data }), { headers: headers }).subscribe(
        data => {
          if (data.json().states != null) {
            this.stateList = data.json().states;
            this.filtState = this.stateList[0].state_id;
            this.filtStateDesc = this.stateList[0].state_desc;
            this.stateChange(this.stateList[0].state_id);
          }
        },
        error => {
          
        })
    } else {
      this.stateList = [];
      this.cityList = [];
      this.countryList = [];
    }

  
  }

  stateChange(data) {
    if (data != null) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');

      this.http.post(this.countryUrl, JSON.stringify({ state_id: data }), { headers: headers }).subscribe(
        data => {
          if (data.json().countries != null) {
            this.countryList = data.json().countries;
            this.countryId = this.countryList[0].country_id;
            this.filtCountry = this.countryList[0].country_desc;
          }
        },
        error => {
          
        })
    } else {
      this.stateList = [];
      this.countryList = [];
    }

  
  }

  selectHospital(hos_name) {

    this.hospitalName = hos_name.hos_name;
    for (var i = 0; i < this.hospitalListMainArray.length; i++) {
      if (this.hospitalListMainArray[i].hosp_name == this.hospitalName) {
        this.hospitalId = this.hospitalListMainArray[i].hosp_id;
      }
    }
    

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.getHospitalLocationUrl,
      JSON.stringify({

        hosp_name: this.hospitalName
      }),
      { headers: headers })
      .subscribe(
        response => {
          this.locationName = "";
          var obj = JSON.parse(response["_body"]);
          this.locationListMainArray = obj.hospital_location;

          if (obj.hospital_location != null) {
            this.locationName = obj.hospital_location[0].hosp_location;
            this.getCity(this.locationName);
          }
        },
        error => {

        }
      )

    this.hospitalList = [];
    this.locationName = "";
  }

  getBloodgrp() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');

    this.http.get(ipaddress.getIp + "bd/bgroup/", { headers: headers }).subscribe(
      data => {
      
        // this.bgrpList = data.json().blood_groups;
        this.bgrpList = [];
        for (var b = 0; b < data.json().blood_groups.length; b++) {
          var obj = data.json().blood_groups[b];
          this.bgrpList.push({
            bgrp_name: obj.description,
            bgrp_id: obj.blood_group_id
          });
        }
      }, error => {

      }
    );
  }

  getCurrentDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "adm/curdate",JSON.stringify({
      country: ipaddress.country_code
    }), { headers: headers }).subscribe(
      data => {
        this.currentDate = Date_Formate(data.json().current_date);
        this.currentTime = data.json().current_time;
        this.currentYear = data.json().current_date.split('-')[0];
        this.appointmentDate =  data.json().current_date;
        this.currentMaxDate = data.json().current_date;
      },error => {});
  }

  check_next() {
    if (this.hospitalName == "" || this.hospitalName == undefined) {
      this.toastMessage = Message_data.enterHosp;
    } else if (this.locationName == "" || this.locationName == undefined) {
      this.toastMessage = "Enter location";
    } else if (this.unit == "" || this.unit == undefined) {
      this.toastMessage = "Enter unit";
    } else if (this.bloodId == "" || this.bloodId == undefined) {
      this.toastMessage = "Select blood group";
    } 
    else {
      Client_Helper.setfinddonors(null);
      var findonordetails=null
      findonordetails={
        hptl_clinic_id: this.hospitalId,
        blood_group: this.bloodId,
        hptl_clinic_name: this.hospitalName,
        city: this.filtCity,
        location: this.locationId,
        state: this.filtState,
        country: this.countryId,
        zipcode: this.zipcode,
        donation_date:(this.appointmentDate)
    }
    Client_Helper.setfinddonors(findonordetails);
    this.clientservice.sendMessage('finddonors');
    }
  }

  hosfollowfilter(e) {
    this.hospitalName = e.target.value.toString();
    this.hospitalList = [];
    this.hospitalListData = [];

    if (this.hospitalName !== "" && this.hospitalName.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.hospitalUrl,
        JSON.stringify({
          hosp_name: this.hospitalName
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj =JSON.parse(response["_body"])
            
            if (obj.hospitals != null) {
              this.hospitalListMainArray = obj.hospitals;

            for (var i = 0; i < this.hospitalListMainArray.length; i++) {
              this.hospitalListData.push({
                hos_name: this.hospitalListMainArray[i].hosp_name,
                hosp_id: this.hospitalListMainArray[i].hosp_id
              });
            }

            this.hospitalList = this.hospitalListData.filter(function (this:any,el) {
              return el.hos_name.toLowerCase().indexOf(this.hos_name.toLowerCase()) > -1;
            }.bind(this));
          }
          },error => {});
    } else {
      this.hospitalList = [];
    }
  }
}
