<div mat-dialog-title class="popup_border_icd">
    <h5 class="header_txt">View prescription</h5>
    <div mat-dialog-actions>
        <img src="../../../assets/img/close_icon.png" (click)="ClosePopup()" width="20px" height="20px"
            id="close_icd" />
    </div>
</div>
<div mat-dialog-content class="inpat_content">
    <div class="container">
        <div class="row" style="max-height: 250px;">
            <div *ngIf="viewpreslist_array.length ==0">
                <p class="nodata">No prescription data found</p>
            </div>
            <div *ngIf="viewpreslist_array.length !=0">
                <div *ngFor="let viewlist of viewpreslist_array">
                    <div class="cover_div">
                        <div class="row">
                            <div class="col-6">
                                <mat-label class="matlabel">{{viewlist.tot_doct_name}}</mat-label>
                            </div>
                            <div class="col-6">
                                <mat-label class="matlabel" style="float:right;">{{viewlist.view_date}}</mat-label>
                            </div>
                            <div class="col-12">
                                <table style="border: 1px solid #06438a;width: 100%;height: 41px;border: 1px solid #06438a;margin: 5px auto;">
                                    <tr *ngFor="let viewmed of viewlist.medicines">
                                        <td style="text-align: left;padding-left: 5px;">{{viewmed.drug}}</td>
                                        <td style="text-align: left;padding-left: 5px;">{{viewmed.catagory}}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions align="end" style="width: auto;right: 19px;">
</div>