import { Component, OnInit } from '@angular/core';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
import { Helper_Class } from '../../../helper_class';
import { Http, Headers } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ipaddress } from '../../../ipaddress';
import { ToastrService } from 'ngx-toastr';
import { Message_data } from 'src/assets/js/Message_data';
import pdfMake from 'pdfmake/build/pdfmake';

@Component({
  selector: 'app-assets-data-inventory',
  templateUrl: './assets-data-inventory.component.html',
  styleUrls: ['./assets-data-inventory.component.scss']
})
export class AssetsDataInventoryComponent implements OnInit {

  public name;
  public id;
  public pureprice;
  public depracation;
  public depracationrate;
  public currentbook;
  public supplier_vendor;
  public sino_model;
  public status;
  public notes;
  public maitation;
  public disposemethod;
  public sales_price;
  public masterurl;
  public userType;
  public masterType;
  public hsp_id;
  public assetsarray = [];
  public maintancearray = [];
  public disposalarray = [];
  public ipaddress;
  vendor: any;
  public getInfo;
  public userID;
  public pharmacyID;
  public supplierData: any = [];
  public supplerMasterArray: any = [];
  public vendorid;
  public currentDate;
  public fromDate;
  public toDate;
  public currenttime;
  public presentdate;
  public disposition_date;
  public assetscatid;
  public acquisition_Date;
  public maintanance_schud;
  public disposaltypeid;
  public updated_by;
  public created_by;
  public disposeprice;
  public disposereason;
  public imageFileStr: any;
  public pdf;
  public pdfObj;
  public photos = [];
  public base64data;
  public hsp_id1;
  public maintduedate;
  public purchaseprice;
  public assetspagechangeflag;
  public editassetsdata=[];
  public assimage_name;
  public hosp_assets_id;
  public updateflag:boolean;
  public imagefile;

  constructor(public frontdeskservice: MenuViewService, public http: HttpClient, public httpp: Http, public toastr: ToastrService) { }

  ngOnInit(): void {
    this.userType = Helper_Class.getInfo().user_type;
    this.masterType = Helper_Class.getReportType();
    this.hsp_id = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
    this.ipaddress = Helper_Class.getIPAddress();
    // this.paid = pharmacy_helper.getpoid();
    this.getInfo = Helper_Class.getInfo();
    // this.user_type = Helper_Class.getInfo().user_type;
    this.userID = this.getInfo.user_id;
    this.hsp_id1 = this.getInfo.hosp_id;
    console.log("hsp id" + this.hsp_id)
    this.pharmacyID = this.getInfo.hospitals[0].hptl_clinic_id;
    // this.ipaddress = Helper_Class.getIPAddress();
    // this.poapproved = Helper_Class.getInfo().hospitals[0].po_approval_req;



    var retirvesend_data;
    if (this.userType == "pharmacy") {

      retirvesend_data = {
        center_id: this.hsp_id,
        center_type: this.userType
      }
      this.retrive_assetsdata(retirvesend_data);
      this.retrive_maintancedata(retirvesend_data);
      this.retrive_disposal(retirvesend_data);

    }
    else if (this.userType == "diagnosis") {
      retirvesend_data = {
        center_id: this.hsp_id,
        center_type: this.userType
      }
      this.retrive_assetsdata(retirvesend_data);
      this.retrive_maintancedata(retirvesend_data);
      this.retrive_disposal(retirvesend_data);

    }
    else {
      retirvesend_data = {
        center_id: this.hsp_id,
        center_type: "doctor"
      }
      this.userType = "doctor";
      this.retrive_assetsdata(retirvesend_data);
      this.retrive_maintancedata(retirvesend_data);
      this.retrive_disposal(retirvesend_data);
    }
    this.getCurrentDate();
    
    this.assetspagechangeflag = Helper_Class.getassetslistdatachangepage().value;
   
    if(this.assetspagechangeflag === 'true'){
    
      var data=Helper_Class.getassetslistdata();
     
      Helper_Class.getassetslistdata();

      this.assetscatid=Helper_Class.getassetslistdata().asset_category;
      this.name=Helper_Class.getassetslistdata().asset_name;
      this.id=Helper_Class.getassetslistdata().asset_code;
      this.acquisition_Date=Helper_Class.getassetslistdata().acquisition_date;
      this.depracation=Helper_Class.getassetslistdata().depreciation_method;
      
      this.depracationrate=Helper_Class.getassetslistdata().depreciation_rate;
      this.currentbook=Helper_Class.getassetslistdata().current_book_value;
      this.vendor=Helper_Class.getassetslistdata().supplier_name;
      this.vendorid=Helper_Class.getassetslistdata().supplier_id;
      this.sino_model=Helper_Class.getassetslistdata().serial_model_no;
      this.status=Helper_Class.getassetslistdata().status;
     
      this.hosp_assets_id=Helper_Class.getassetslistdata().hosp_assets_id;
      this.maintanance_schud=Helper_Class.getassetslistdata().maintanance_schedule;
      this.assimage_name=ipaddress.Ip_with_img_address+Helper_Class.getassetslistdata().asset_image;
      this.imagefile=Helper_Class.getassetslistdata().asset_image;
      this.photos=ipaddress.Ip_with_img_address+Helper_Class.getassetslistdata().asset_image;
     
      this.disposaltypeid = Helper_Class.getassetslistdata().disposaltypeid;
      this.updateflag = true;
      
      
      
      // disposaltypeid,disposeprice,disposition_date,sales_price,disposereason,created_by,updated_by
      // this.maintduedate=;
      // this.pureprice=;
      // this.depracation=;
      // this.purchaseprice=;
        // this.notes=;
    
    
    
    
    }
    
  
  
  }
  public getCurrentDate() {
    var headers = new Headers();
    var date;
    headers.append('Content-Type', 'application/json');
    this.httpp.post(ipaddress.getIp.toString() + 'adm/curdate', { country: ipaddress.country_code },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          console.log("current time", JSON.stringify(obj))
          this.currentDate = obj.current_date;//display in html for maximum date
          this.fromDate = obj.current_date;
          this.toDate = obj.current_date;
          var currenttime1 = obj.current_time.split(":");
          this.currenttime = currenttime1[0]
          this.presentdate = obj.current_date;

          // this.updateCurrentSession(this.presentdate,this.currenttime)
          //this.getSalesData();
          console.log('presentdate1', this.presentdate)
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });

  }

  gotoassets() {
    this.frontdeskservice.sendMessage("pharma_assets_lists");
  }


  retrive_assetsdata(retirvesend_data) {
    console.log("SEND DATA ========" + JSON.stringify(retirvesend_data))
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'mas/gach', retirvesend_data,
      { headers: headers })
      .subscribe(
        response => {

          var obj = JSON.parse(JSON.stringify(response));
          // console.log("master type == " + this.assetmasterpageheading)
          console.log("assets retrive data  =" + JSON.stringify(obj))
          var assetslist = obj.asset_categories
          console.log(JSON.stringify(obj))
          for (var i = 0; i < assetslist.length; i++) {
            console.log(assetslist[i])
            this.assetsarray.push({
              assetscatid: assetslist[i].asset_category_id,
              assetscatName: assetslist[i].asset_category_desc
            })
          }
        }, error => {
          console.log("error in generic data")
        }
      )
  }


  retrive_maintancedata(retirvesend_data) {
    console.log("SEND DATA ========" + JSON.stringify(retirvesend_data))
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'mas/gmsh', retirvesend_data,
      { headers: headers })
      .subscribe(
        response => {

          var obj = JSON.parse(JSON.stringify(response));
          // console.log("master type == " + this.assetmasterpageheading)
          console.log("maintance retrive data  =" + JSON.stringify(obj))
          var maintancelist = obj.maintenance_schedules
          // console.log(JSON.stringify(obj))
          for (var i = 0; i < maintancelist.length; i++) {
            console.log(maintancelist[i])
            this.maintancearray.push({
              maintanceid: maintancelist[i].maintanance_schedule_id,
              maintance_name: maintancelist[i].maintanance_schedule_desc
            })
          }
        }, error => {
          console.log("error in generic data")
        }
      )
  }

  retrive_disposal(retirvesend_data) {
    console.log("SEND DATA ========" + JSON.stringify(retirvesend_data))
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'mas/gdth', retirvesend_data,
      { headers: headers })
      .subscribe(
        response => {

          var obj = JSON.parse(JSON.stringify(response));
          // console.log("master type == " + this.assetmasterpageheading)
          console.log("assets retrive disposal data  =" + JSON.stringify(obj))
          var disposallist = obj.asset_dispositions
          console.log(JSON.stringify(obj))
          for (var i = 0; i < disposallist.length; i++) {
            console.log(disposallist[i])
            this.disposalarray.push({
              disposalid: disposallist[i].asset_disposition_type_id,
              disposalName: disposallist[i].asset_disposition_type_desc
            })
          }
        }, error => {
          console.log("error in disposal data")
        }
      )
  }


  getVendor() {
    if (this.vendor != undefined && this.vendor.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.httpp.post(ipaddress.getIp + "pharmacontrol/gsupp/", JSON.stringify({
        pharmacist_id: this.userID,
        pharmacy_id: this.pharmacyID,
        imei: this.ipaddress,
        name: this.vendor,
      }), { headers: headers }).subscribe(
        data => {
          var suppler_index = 0;
          var obj = data.json();
          this.supplierData = data.json().suppliers;
          if (this.supplierData.length != 0) {
            for (var i = 0; i < this.supplierData.length; i++) {
              if (this.supplierData[i].address2 != null && this.supplierData[i].address2 != undefined) {
                var add_txt = this.supplierData[i].address1 + "," + this.supplierData[i].address2;

              } else {
                add_txt = this.supplierData[i].address1;
              }
              this.supplerMasterArray.push({
                Index: suppler_index,
                name: this.supplierData[i].name,
                mfg_code: this.supplierData[i].supp_code,
                address: add_txt,
                city_desc: this.supplierData[i].city_desc,
                state_desc: this.supplierData[i].state_desc,
                country_desc: this.supplierData[i].country_desc,
                telephone: this.supplierData[i].telephone,
                mobile: this.supplierData[i].mobile,
                supp_id: this.supplierData[i].supp_id,
                city: this.supplierData[i].city,
                state: this.supplierData[i].state,
                country: this.supplierData[i].country,
              });
              suppler_index++;
            }
            //this.vendor = this.supplierData[0].name;
            //this.vendoraddress_change(this.vendor);
          }
        },
        error => { });
    }
  }
  vendoridset(vendor) {
    this.vendorid = vendor.supp_id;
   
  }

  fileChange(data) {
 
    this.readThis(data.target);
  }

  readThis(inputValue: any): void {
  
    var ValidFileExtension = ['jpg', 'JPG', 'jpeg', 'JPEG', 'png', 'PNG', 'gif', 'GIF', 'bmp', 'BMP', 'pdf'];
    var file: File = inputValue.files[0];
    var fileExtension = file.type.split("/").pop();
    var myReader: FileReader = new FileReader();
    myReader.onloadend = (e) => {
      var fileSize = Math.round(inputValue.files[0].size / 1024);
      this.imageFileStr = myReader.result.toString();

      if (fileExtension == 'pdf') {
       
        this.pdf = file;
        this.createPdf();
      } else {
       
        this.photos.push(this.imageFileStr);
        this.createPdf();
      }

    }
    myReader.readAsDataURL(inputValue.files[0]);

  }

  createPdf() {

  
    if (this.photos.length != 0) {
    
      let imgFinding = [];
      var fileExtension;
      for (var i = 0; i < this.photos.length; i++) {
        imgFinding.push({ image: this.photos[i], fit: [540, 700] })
      }
      let docDefinition = {
        pageSize: 'A4',
        content: imgFinding
      };
      this.pdfObj = pdfMake.createPdf(docDefinition);
      this.pdfObj.getBuffer((buffer) => {
        var blob = new Blob([buffer], {
          type: 'application/pdf'
        });
        var reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function (this: any) {
          var base64data = reader.result;

          this.base64data = reader.result;

          this.base64data = (base64data as string).split(',')[1];
          // this.save_data(base64data) 
        }.bind(this);
      });
    }
    else if (this.pdf != undefined) {
      var reader = new FileReader();
      reader.readAsDataURL(this.pdf);
      reader.onloadend = function (this: any) {
        var base64data = reader.result;
        this.base64data = (base64data as string).split(',')[1];
      }.bind(this);
    }
    else {
      this.toastr.error(Message_data.addAtlstOneImg);
    }
  }

  saveData() {
    this.masterurl = "inv/sha";
    
    var senddata;
    if(this.assetspagechangeflag === 'true'){
      if( Helper_Class.getassetslistdata().asset_image == this.imagefile){
      senddata = {
        hosp_assets_id:this.hosp_assets_id,
        asset_name:this.name,
        acquisition_date:this.acquisition_Date,
        asset_category:this.assetscatid,
        supplier_id:this.vendorid,
        serial_model_no:this.sino_model,
        maintanance_schedule:this.maintanance_schud,
        disposition_type_id:this.disposaltypeid,
        disposition_date:this.disposition_date,
        disposition_price:this.disposeprice,
        disposition_reason:this.disposereason,
        // asset_image:"",
        updated_by:this.updated_by,
        country: ipaddress.country_code
      }
    }else{
      senddata = {
        hosp_assets_id:this.hosp_assets_id,
        asset_name:this.name,
        acquisition_date:this.acquisition_Date,
        asset_category:this.assetscatid,
        supplier_id:this.vendorid,
        serial_model_no:this.sino_model,
        maintanance_schedule:this.maintanance_schud,
        disposition_type_id:this.disposaltypeid,
        disposition_date:this.disposition_date,
        disposition_price:this.disposeprice,
        disposition_reason:this.disposereason,
        asset_image:this.base64data,
        updated_by:this.updated_by,
        country: ipaddress.country_code
      }
    }
    }
    else{
    senddata = {
      center_id: this.hsp_id,
      center_type: this.userType,
      asset_code: this.id,
      asset_name: this.name,
      acquisition_date: this.acquisition_Date,
      asset_category: this.assetscatid,
      purchase_price:this.purchaseprice,
      depreciation_method:this.depracation,
      depreciation_rate:this.depracationrate,
      current_book_value:this.currentbook,
      supplier_id: this.vendorid,
      serial_model_no: this.sino_model,
       maintanance_schedule: this.maintanance_schud,
        next_maintance_date:this.maintduedate,
       asset_image: this.base64data,
       created_by: this.created_by,
       country: ipaddress.country_code,
    }
  }
    console.log("send data =" + JSON.stringify(senddata))
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + this.masterurl, senddata,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log("response assets send data" + JSON.stringify(obj))
        }, error => {
          console.log("error in generic data")
        }
      )
  }
}






