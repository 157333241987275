<div *ngIf="historyFlag == 'min'" class="block1_grid">
    <mat-label class="matlabel" [hidden]="nameFlag" col-12 col-sm-12 col-md-12 col-lg-8 col-xl-6>
        {{nameLabel}}<br>
        <input type="text" class="ipcss widthappt" matInput [readonly]="isReadonly()" [(ngModel)]="name" />
    </mat-label>
    <mat-label class="matlabel">{{genderLabel}}
        <input style="width: 100% !important;" class="age ipcss" matInput type="text" [readonly]="isReadonly()"
            [(ngModel)]="gender" />
    </mat-label>
    <mat-label class="matlabel" [hidden]="ageFlag" col-12 col-sm-12 col-md-12 col-lg-8 col-xl-6>{{ageLabel}}<br>
        <input class="ipcss widthappt" matInput type="text" required
            onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="3" [(ngModel)]="age" />
    </mat-label>
    <mat-label class="matlabel" [hidden]="heightFlag">{{heightLabel}}<br>
        <input style="width: 100%;" required class="ipcss" matInput type="text" (change)="calculateCalories()"
            maxlength="4" onkeypress="return event.charCode >= 48 && event.charCode <= 57" [(ngModel)]="height" />
    </mat-label>
</div>
<div *ngIf="historyFlag == 'max'">
    <!-- <div>
       <div id="maxview"> -->
    <div class="row">
        <div class="col-6">
            <div class="cover_div">
                <div class="header_lable">
                    Personal
                </div>
                <div class="content_cover">
                    <div class="row">
                        <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6 " [hidden]="nameFlag">
                            <mat-label class="matlabel" [hidden]="nameFlag">{{nameLabel}}
                                <input type="text" class="ipcss ip" matInput [readonly]="isReadonly()"
                                    [(ngModel)]="name" />
                            </mat-label>
                        </div>
                        <div class="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3" [hidden]="genderFlag">
                            <mat-label class="matlabel">{{genderLabel}}
                                <input style="width: 100% !important;" class="age ipcss" matInput type="text"
                                    [readonly]="isReadonly()" [(ngModel)]="gender" />
                            </mat-label>
                        </div>
                        <div class="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 " [hidden]="ageFlag">
                            <mat-label class="matlabel" [hidden]="ageFlag">{{ageLabel}}
                                <input class="age ipcss" style="width: 100% !important;" matInput type="text" required
                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="3"
                                    [(ngModel)]="age" [readonly]="ageReadOnlyFlag" />
                            </mat-label>
                        </div>
                        <div class="col-12 col-md-6 col-lg-6 col-xl-6 ">
                            <mat-label class="matlabel" [hidden]="stressFlag">{{stressLabel}}<br>
                                <textarea class="ipcss widthappt" maxlength="250" [(ngModel)]="stressFactor"
                                    style="height: 85px !important;"></textarea>
                            </mat-label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="cover_div">
                <div class="header_lable">
                    BMI & Vitals
                </div>
                <div class="content_cover">
                    <div class="row">
                        <div class="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3" [hidden]="heightFlag">
                            <mat-label class="matlabel">{{heightLabel}}<br>
                                <input style="width: 100%;" class="ipcss" matInput type="text"
                                    ((change)="calculateCalories()" maxlength="{{heightMeasure == 'cms' && (3) || (5)}}"
                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57" required
                                    [(ngModel)]="height" />
                            </mat-label>
                        </div>
                        <div class="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 " [hidden]="heightFlag">
                            <mat-label class="matlabel">{{heightMeasureLabel}}<br>
                                <select class="ipcss" required (change)="calculateCalories()"
                                    [(ngModel)]="heightMeasure">
                                    <option value="cms">cms</option>
                                    <option value="inch">inch</option>
                                </select>
                            </mat-label>
                        </div>
                        <div class="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3" [hidden]="weightFlag">
                            <mat-label class="matlabel">{{weightLabel}}<br>
                                <input class="ipcss" style="width: 100%;" matInput type="text"
                                    (change)="calculateCalories()" maxlength="5"
                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57" required
                                    [(ngModel)]="weight" />
                            </mat-label>
                        </div>
                        <div class="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3" [hidden]="weightFlag">
                            <mat-label class="matlabel">{{weightMeasureLabel}}<br>
                                <select class="ipcss " (change)="calculateCalories()" required
                                    [(ngModel)]="weightMeasure">
                                    <option value="kgs">kgs</option>
                                    <option value="lbs">lbs</option>
                                </select>
                            </mat-label>
                        </div>
                        <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6 " [hidden]="bmiFlag">
                            <mat-label class="matlabel">{{bmiLabel}}
                                <input class="ipcss bmi_lenght" matInput type="text" [readonly]="isReadonly()"
                                    [(ngModel)]="bmi" />
                            </mat-label>
                        </div>
                        <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6 " [hidden]="bmrFlag">
                            <mat-label class="matlabel">{{bmrLabel}}
                                <input class="ipcss" style="width: 100%;" matInput [readonly]="isReadonly()"
                                    [(ngModel)]="bmr" />
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 " [hidden]="bnatureFlag">
                            <mat-label class="matlabel">{{bnatureLabel}}
                                <input class="ipcss ip" matInput type="text" [readonly]="isReadonly()"
                                    [(ngModel)]="bodyNature" />
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 " [hidden]="heartRateFlag">
                            <mat-label class="matlabel">{{heartRateLabel}}
                                <input class="ipcss" matInput [disabled]="heartDisabledFlag" required
                                    [(ngModel)]="heartRate"
                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 " [hidden]="bloodPressureFlag">
                            <mat-label class="matlabel">{{bloodPressureLabel}}</mat-label>
                            <div class="row">
                                <div [hidden]="bloodPressureFlag" class="blood_presclinical" class="col-5"
                                    style="padding-right: 0;">
                                    <input class="ipcss" matInput [disabled]="bpDisabledFlag" required
                                        placeholder="mm Hg" maxlength="3" [(ngModel)]="bpDia" required
                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                                </div>
                                <div [hidden]="bloodPressureFlag" class="col-1 d-flex justify-content-center">
                                    <span class="blood_para">.</span>
                                </div>
                                <div [hidden]="bloodPressureFlag" class="col-5" style="padding-right: 0;">
                                    <input class="ipcss" matInput [disabled]="bpDisabledFlag" required
                                        placeholder="mm Hg" maxlength="3" [(ngModel)]="bpSys" required
                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12" style="margin-top: 10px;">
            <div class="cover_div">
                <div class="header_lable">
                    Main concerns
                </div>
                <div class="content_cover">
                    <div class="row">
                        <div class="col-12 col-md-6 col-lg-3 col-xl-3" [hidden]="mainconFlag">
                            <mat-label class="matlabel "> {{mainconLabel}}<br>
                                <textarea #matInput class="ipcss" [(ngModel)]="mainConcern" required
                                    maxlength="250"></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-md-6 col-lg-3 col-xl-3" [hidden]="pastMedicalFlag">
                            <mat-label class="matlabel">{{pastMedicalLabel}}<br>
                                <textarea #matInput class="ipcss " [(ngModel)]="pastMedical"
                                    (click)="selectPastMedical()" readonly="true"></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-md-6 col-lg-3 col-xl-3" [hidden]="surgicalFlag">
                            <mat-label class="matlabel">{{surgicalLabel}}<br>
                                <textarea #matInput class="ipcss" [(ngModel)]="surgical" required></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-11 col-md-11 col-xl-3 col-lg-3">
                            <mat-label class="matlabel" [hidden]="dietaryFlag">{{dietaryLabel}}<br>
                                <textarea class=" ipcss" maxlength="250" required [(ngModel)]="dietHabits"></textarea>
                            </mat-label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="historytab">
        <div class="col-12 my-auto">
            <a style="float: right;margin-top: 21px;"><img src="../../../assets/ui_icons/buttons/history.svg"
                    class="saveimgbtn_inpatinfo" (click)="physicalHistory()" /></a>
        </div>
    </div>
</div>