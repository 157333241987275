<app-header></app-header>
<section class="page_style">
    <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
            <div>
                <img class="div_image" src="../../../assets/img/tervys/Doctor.png" alt="..">
            </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-8">
            <h2 class="big-text">Doctor</h2>
            <p class="text-center  text righ_align">While the ability to engage with the world of healthcare is determined by 
                the availability, accessibility, and efficiency of healthcare systems and infrastructure. As a physician or 
                healthcare provider it is important to get the trust and belief of the client(client) by providing 
                personalized care as well as their willingness to engage. Tervys provides the platform where physician / doctors 
                can be listed and get visibility to the client (patients) through Tervys doctor / physician can monitor 
                patients for a seamless doctor-patient experience. <br/> 
                <img src="../../../assets/img/tervys/click.svg" class="click_image" alt="register" routerLink="/doctorRegistration">to explore.</p>
        </div>
    </div>

    <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-8">
            <h2 class="big-text">Client (patient)</h2>
            <p class="text-center text righ_align">“If the symptoms persist, consult your doctor 
                immediately,” you may have seen these lines plastered across medicines, health advice 
                columns, and even commercials. You may find it difficult to consult best doctors 
                around your area, Tervys is here to help you out to find doctor around the globe and maintain your 
                health records in one place and never be misplaced. You are required to provide 
                your personal details in the form of registration to help the service providers to 
                identify your name, gender, age etc and to provide personalized care.<br/> 
                <img src="../../../assets/img/tervys/click.svg" class="click_image" alt="register" routerLink="/clientreg">to register.</p>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
            <div>
                <img class="div_image" src="../../../assets/img/tervys/Client.png" alt="..">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
            <div>
                <img class="div_image" src="../../../assets/img/tervys/Diagnosis.png" alt="..">
            </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-8">
            <h2 class="big-text">Diagnosis</h2>
            <p class="text-center  text righ_align">A diagnostic center performs various tests to determine the cause of the condition. 
                There are different types of labs within this category, including radiology & MRI centers, imaging centers, pathology 
                labs, etc. A diagnostic centre may use advanced technology for their services but that may not be visible to the 
                public or targetted people unless untill the name or its values are reached to them. Tervys is there to overcome this. 
                Diagnostic centre can create their own test and offers in Tervys and the targetted people can view these details and 
                book appointment. This also help to reach patients and digitally provide diagnostic outcomes.<br/> 
                <img src="../../../assets/img/tervys/click.svg" class="click_image" alt="register" routerLink="/diagnosisreg">to register and get listed.</p>
        </div>
    </div>

    
    <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-8">
            <h2 class="big-text">Pharmacy</h2>
            <p class="text-center text righ_align">Pharmacy is a healthcare practice specializing in the usage and 
                administration of medication, which is science and practice of discovering, preparing, dispensing, 
                aiming to ensure the safe, effective, and affordable use of medicines and improve patient quality of life. 
                Pharmacist as medicine experts in the pharmacy, pharmacists are essential members of the healthcare team. 
                They’re trusted by other healthcare professionals to assess prescriptions and recommend the best combination 
                of medicines. Tervys extends their products to pharmacy where pharmacist can list their organization and get 
                visibility to the clients (patients) and increase the revenue of pharmacy. <br/> 
                <img src="../../../assets/img/tervys/click.svg" class="click_image" alt="register" routerLink="/pharmareg">to register and gain visibility.</p>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
            <div>
                <img class="div_image" src="../../../assets/img/tervys/Pharmacy.png" alt="..">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
            <div>
                <img class="div_image" src="../../../assets/img/tervys/Nutrition.png" alt="..">
            </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-8">
            <h2 class="big-text">Nutritionist</h2>
            <p class="text-center  text righ_align">Nutritional needs change with different life stages. To be fit and healthy, 
                it is important to take into account the extra demands placed on every body by these changes. To plan for individuals 
                nutritional needs they approach Dietitians and nutritionists as they are experts in the use of food and nutrition to 
                promote health. Nutritionist plan and conduct food service or nutritional programs to help people lead healthy 
                individuals. Understading the greater role of nutritionist Tervys has designed the products to ease the complexity 
                of designing nutrition plan for an individual. Using Tervys nutritionist can design and carve best nutrition plan for 
                an individual based on their health condition and need and gain visibility and credibility of the client (patient). <br/> 
                <img src="../../../assets/img/tervys/click.svg" class="click_image" alt="register" routerLink="/dieticianreg">and explore more.</p>
        </div>
    </div>

    
    <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-8">
            <h2 class="big-text">Physio</h2>
            <p class="text-center text righ_align">Physiotherapy is treatment to restore, maintain, and make the most of a patient’s 
                mobility, function, and well-being. Patients from all age groups like infants, children, adults, and geriatric 
                population can suffer from the above said issues and physiotherapists plays greater role in treating the above said 
                issues as they are trained in these areas. Tervys had understood the need to provide and maintain the client (patient) 
                issues and the treatment provided to specific individual. Tervys has specific section for physiotherapy where the 
                history of the treatment provided to the individual and the resulf of the same are recorded and maintained. This will 
                help the physiotherapist to gain trust, belief and confidence of client(patient) and this in turn results in more 
                visibility for that physiotherapist.  <br/> 
                <img src="../../../assets/img/tervys/click.svg" class="click_image" alt="register" routerLink="/physioreg">to register.</p>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
            <div>
                <img class="div_image" src="../../../assets/img/tervys/Physio.png" alt="..">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
            <div>
                <img class="div_image" src="../../../assets/img/tervys/Nurse.png" alt="..">
            </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-8">
            <h2 class="big-text">Nurse</h2>
            <p class="text-center  text righ_align">Nurses have long delivered a high standard of care 
                to the general public. Professional respect within the medical community. The role of 
                nurse plays key role in healthcare system and monitoring patient's health. Tervys provides 
                single solution to manage their day to day activities as well as their routines in the 
                hospital. Tervys' registration process helps in identifying and managing hospital 
                routines as well as homecare services. <br/> 
                <img src="../../../assets/img/tervys/click.svg" class="click_image" 
                alt="register" routerLink="/nursereg">to register.</p>
        </div>
    </div>

    
    <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-8">
            <h2 class="big-text">Front-desk</h2>
            <p class="text-center text righ_align">A hospital receptionist is the first point of 
                contact between a hospital or medical facility and patients. These receptionists 
                play an integral role in a medical facility because they perform customer service 
                and administrative-related duties like scheduling appointments, answering patients' 
                questions, print bills or prescriptions. Tervys has complete suite for front desk. <br/> 
                <img src="../../../assets/img/tervys/click.svg" class="click_image" alt="register" routerLink="/frontdesk">to register.</p>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
            <div>
                <img class="div_image" src="../../../assets/img/tervys/Frontdesk.png" alt="..">
            </div>
        </div>
    </div>

    <!-- <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 ">
            <div class="imga">
                <img src="../../../assets/img/Subtraction27.png" alt=".." routerLink="/dieticianreg">
            </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-5">
            <h2 class="text-center  big-text">Nutritionist</h2>
            <p class="text-center  text">This is one time capture of information regarding doctor's demographic
                information as well as their work location. This helps in get listed and gain visibility to patients, as
                well as patient monitoring for a seamless nutritionist-patient experience. <br/> <img src="../../../assets/img/click.svg" class="click_image" alt="register" routerLink="/dieticianreg">to register.</p>
        </div>

        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-3">
            <div class="imgb float-end">
                <img src="../../../assets/img/Round.png" alt="..">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-3">
            <div class="float-start">
                <img src="../../../assets/img/Round2.png" alt="..">
            </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-5">
            <h2 class="text-center  big-text">Diagnosis</h2>
            <p class="text-center  text righ_align">To get listed and gain visibility to patients on the tests and
                options provided by your center. It also help to reach patients and digitally provide diagnostic
                outcomes.<br/> <img src="../../../assets/img/click.svg" class="click_image" alt="register" routerLink="/diagnosisreg">to register.</p>
        </div>

        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 ">
            <div class="imga">
                <img src="../../../assets/img/Subtraction23.png" alt=".." routerLink="/diagnosisreg">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
            <div class="imgd">
                <img src="../../../assets/img/Subtraction49.png" alt=".." routerLink="/pharmareg">
            </div>
        </div>
        
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-5">
            <h2 class="text-center  big-text">Pharmacy</h2>
            <p class="text-center  text">This is one time capture of information about pharmacy's demographic
                information and gain visibility to patients. <br/> <img src="../../../assets/img/click.svg" class="click_image" alt="register" routerLink="/pharmareg">to register.
            </p>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-3">
            <div class="imgb float-end">
                <img src="../../../assets/img/Round.png" alt="..">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-3">
            <div class="float-start">
                <img src="../../../assets/img/Round2.png" alt="..">
            </div>
        </div>

        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-5">
            <h2 class="text-center  big-text">Physio</h2>
            <p class="text-center  text righ_align">This is one time capture of information regarding physiotherapist's demographic
                information as well as their work location. This helps in get listed and gain visibility to patients, as
                well as patient monitoring for a seamless physiotherapist-patient experience. <br/> <img src="../../../assets/img/click.svg" class="click_image" alt="register" routerLink="/physioreg">to register.</p>
        </div>
        
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 ">
            <div class="imga">
                <img src="../../../assets/img/Subtraction25.png" alt=".." routerLink="/physioreg">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
            <div class="imgd">
                <img src="../../../assets/img/Subtraction51.png" alt=".." routerLink="/nursereg">
            </div>
        </div>

        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-5">
            <h2 class="text-center  big-text">Nurse</h2>
            <p class="text-center  text">Nurse registration process helps in identifying and managing hospital routines as well
                as homecare services. <br/> <img src="../../../assets/img/click.svg" class="click_image" alt="register" routerLink="/nursereg">to register.</p>
        </div>
        
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-3">
            <div class="imgb float-end">
                <img src="../../../assets/img/Round.png" alt="..">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-3">
            <div class="float-start">
                <img src="../../../assets/img/Round2.png" alt="..">
            </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-5">
            <h2 class="text-center  big-text"> Front-desk</h2>
            <p class="text-center  text righ_align">Front desk or receptionists usually act as gatekeepers for hospitals or
                clinics. To manage appointments, print bills or prescriptions and attached to a particular hospital or
                clinic <br/> <img src="../../../assets/img/click.svg" class="click_image" alt="register" routerLink="/frontdesk">to register.</p>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 ">
            <div class="imga">
                <img src="../../../assets/img/Subtraction53.png" alt=".." routerLink="/frontdesk">
            </div>
        </div>
    </div> -->
</section>
<app-footer></app-footer>