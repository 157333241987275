<div class="row" style="margin:0 auto; width:100%">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white" style="padding:7px 20px;">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Medicine</h5>
          </div>
          <div class="headerButtons">
            <img class="saveimgbtn_inpatinfo" (click)="back()" src="../../../assets/ui_icons/buttons/Back_button.svg" />
            <img class="saveimgbtn_inpatinfo" (click)="medHistory()" src="../../../assets/ui_icons/buttons/History_button.svg" />
            <img *ngIf="saveFlag" class="saveimgbtn_inpatinfo" (click)="save()" src="../../../assets/ui_icons/buttons/save_button.svg" />
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row">
          <div class="col-12" id="medTrack">
            <owl-carousel-o [options]="customOptions">
              <ng-template carouselSlide *ngFor="let relation of relationlistData;">
                  <div class="slide">
                      <div class="cu_slider_cover">
                          <div class="card_border1" (click)="selectRelation(relation.rel_name,relation.rel_id,relation.sub_rel_id,relation.life_style_id)">
                            <div class="d-flex justify-content-center">
                              <img class="img-fluid" class="image"  src="{{relation.prof_img}}" alt="Card image cap">
                            </div>
                            <p class="carousel_label">{{relation.rel_name}}</p>
                          </div>
                          <a tabindex="0">
                            <div>
                            </div>
                          </a>
                      </div>
                  </div>
              </ng-template>
            </owl-carousel-o>
          </div>
        </div>
        <div class="row">
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
              <mat-label class="matlabel" style="margin-top: 7px;">Date
                <input type="date" class="ipcss_date" [(ngModel)]="createdDate" (change)="selectApptDate(createdDate,'Appointment_Date')" matInput/>
              </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
              <mat-label class="matlabel" style="margin-top: 7px;">Time</mat-label>
              <div class="row">
                <div class="col-3 col-sm-4 col-lg-4 col-md-3 col-xl-3 col-xxl-3">
                  <mat-select disableOptionCentering class="ipcss " [(ngModel)]="timeStart1">
                    <mat-option *ngFor="let name of timeStartsFrom" value={{name}}>{{name}}</mat-option>
                  </mat-select>
                </div>
                <div class="col-1 my-auto" style="margin-left: -13px;margin-right: -14px;">
                  <span>:</span>
                </div>
                <div class="col-3 col-sm-4 col-lg-4 col-md-3 col-xl-3 col-xxl-3">
                  <mat-select disableOptionCentering class="ipcss " [(ngModel)]="timeStart2">
                    <mat-option *ngFor="let name of timeEndAt" value={{name}}>{{name}}</mat-option>
                  </mat-select>
                </div>
                <div class="col-3 col-sm-4 col-lg-4 col-md-3 col-xl-3 col-xxl-3">
                  <mat-select disableOptionCentering class="ipcss " [(ngModel)]="timeStart3">
                    <mat-option value="AM">AM</mat-option>
                    <mat-option value="PM">PM</mat-option>
                  </mat-select>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
              <mat-label class="matlabel" style="margin-top: 7px;">Intake<br>
                <mat-select disableOptionCentering class="ipcss " [(ngModel)]="visitSession"
                  (ngModelChange)="changeVisitSession($event)">
                  <mat-option *ngFor="let session of visitingSessionData" value={{session.description}}>
                    {{session.description}}</mat-option>
                </mat-select>
              </mat-label>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="margin-top: 20px;">
              <div class="nodata" *ngIf="medicineHistListFlag">
                <span>No medicine found</span>
              </div>
              <div *ngIf="medicineHistList.length !=0">
                <div class="table-responsive">
                  <table class="table table-hover table-dynamic medicin_track_table" cellpadding="5" cellspacing="0" border="0">
                    <thead>
                      <tr>
                        <th class="pad_mar" style="width: 70%;">Medicine name</th>
                        <th class="pad_mar" style="width: 20%;">Quantity</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let medicine of medicineHistList; let i=index;">
                        <td data-th="Medicine name" style="text-align: left;padding-left: 30px;">{{medicine.drug_name}}</td>
                        <td class="pad_mar1">
                          <input class="ipcss " style="width: 70px;" [(ngModel)]="medicine.quantity" maxlength="5" matInput />
                        </td>
                        <td>{{medicine.short_form}}</td>
                        <td data-th="Delete">
                          <!-- <img src="../../../assets/ui_icons/buttons/trash_icon.svg" (click)="deleteDrug(medicine.drug_id,i)" class="editDelicon"> -->
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>