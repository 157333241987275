<div mat-dialog-title class="popup_border_icd">
  <h5 class="header_txt">{{reactAllgHeading}}</h5>
  <div mat-dialog-actions>
      <img src="../../../assets/img/close_icon.png" (click)="close()" width="20px" height="20px"
      id="close_icd" />  </div>
</div>
<div mat-dialog-content class="react_content">
  <div class="container" style="padding: 8px;">
    <div *ngIf="retriveDataFlag">
      <div class="row">
        <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <mat-label class="matlabel">{{reactAllgSubHeading}}<br>
            <input #matInput value="" class="ipcss  " [(ngModel)]="medSupplyName">
          </mat-label>
        </div>
        <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <mat-label class="matlabel">{{reactAllg}}<br>
            <input #matInput class="ipcss " value=""  [(ngModel)]="reactAllergy">
        </mat-label>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <img style="float: right;" src="../../../assets/ui_icons/buttons/add_button.svg" class="saveimgbtn_inpatinfo" (click)="addAllergy()" />
        </div>
      </div>
      <div class="table-responsive" *ngIf="prescriptionList.length" style="padding: 0;">
        <table id="tbl" class="table table-nowrap table-sm" *ngIf="prescriptionList.length">
          <thead class="thvalues">   
              <tr>
                <th>{{medSuppHead}}</th>
                <th>{{reactAllg}}</th>
                <th>Action</th>
              </tr>
            </thead>
              <tr *ngFor="let sess of prescriptionList; let i=index">
                <td data-th="Medicine name">{{sess.med_supply_name}}</td>
                <td data-th="Dosage">{{sess.react_allrgy}}</td>
                <td data-th="Action">
                  <a (click)="deleteMedication(i)">delete</a>
                </td>
              </tr>
            </table>
      </div>
    </div>
</div>
</div>
<div mat-dialog-actions align="end" style="width: auto;right: 19px;">
</div>