import { Component, OnInit,ElementRef,ViewChild,ChangeDetectorRef } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Helper_Class } from '../../helper_class';
import { ToastrService } from 'ngx-toastr';
import { Date_Formate } from '../../../assets/js/common';
import { Chart } from 'chart.js';
import { ipaddress } from '../../ipaddress';
import { Message_data } from '../../../assets/js/Message_data';

@Component({
  selector: 'app-physio-prof-dash',
  templateUrl: './physio-prof-dash.component.html',
  styleUrls: ['./physio-prof-dash.component.scss']
})
export class PhysioProfDashComponent implements OnInit {
  // @ViewChild('a') aDiv: ElementRef;
  
  public menu:boolean = false;
  public temp1;
  public userInfo:any;
  public userId:any;
  public hospitalDetails;
  public hospitallogo;
  public hospitalName;
  public currentDate = null;
  public chartType: string;
  public fromDate;
  public toDate;
  public totalapp2: string;
  public totalapp3: string;
  public totalapp4: string;
  public totalapp1: string;
  public LocationCanvas: any;
  public noGrpah: boolean;
  public genmyChart: any;
  public ageCanvas: any;
  public ageGraphFlag: boolean;
  public totalapp;
  public pendingapp;
  public newpatientapp;
  public appointmentType;
  public hptlClinicId;
  public hsptl_clinic_id;
  public weightlosschart:any;
  public packagechart:any;
  // public packagegenchart:any;
  public satisfication_level_chart:any;
  public hospitalbill:string;
  public revenuchart:any;
  public packagearray:any=[];
  clickedData: string | undefined;
  // agegenpackage_piechart;
  // public cdRef;

  constructor(private http:Http,public toastr:ToastrService,private cdRef: ChangeDetectorRef) { 
    this.appointmentType = "Today";
    let agebasearray1 = [];
  }

  ngOnInit(): void {
    this.userInfo= Helper_Class.getInfo();
    this.hospitalDetails=Helper_Class.getHospitalInfo();
    this.hospitalName=this.hospitalDetails.hosp_name;
    this.hospitallogo= ipaddress.Ip_with_img_address + this.hospitalDetails.hptl_logo;
    this.hptlClinicId = this.hospitalDetails.hosp_id;
    // console.log(" hsptl_clinic_id"+this.hptlClinicId)
    this.hsptl_clinic_id=Helper_Class.getInfo().hospitals[0].hptl_clinic_id
    // console.log("hspt id =2"+this.hsptl_clinic_id)
    this.userId=this.userInfo.user_id;
    this.getCurrentDate();
    window.addEventListener("keyup", disableF5);
    window.addEventListener("keydown", disableF5);
    function disableF5(e) {
        if ((e.which || e.keyCode) == 116) e.preventDefault(); 
    };

    this.chartType == "Pie"


  }

  getDateFormate(d){
    var month = ((d.getMonth() + 1) < 10 ? '0' : '') + (d.getMonth() + 1);
    var day = (d.getDate() < 10 ? '0' : '') + d.getDate();
    var temp = d.getFullYear() + "-" + month + "-" + day;
    return temp
  }

  changeAppointmentType(){
    var e;
     if(this.appointmentType =="Today"){
       e = this.currentDate;
     } 
     else if(this.appointmentType == "Weekly"){
       var weekly,d =new Date();
       d.setDate(d.getDate() - 7);
       e = this.getDateFormate(d);
     }
     else if(this.appointmentType == "Fort Night"){
       var weekly,d =new Date();
       d.setDate(d.getDate() - 14);
       e = this.getDateFormate(d);
     }
     else if(this.appointmentType == "Monthly"){
       var weekly,d =new Date();
       d.setDate(d.getDate() - 30)
       e = this.getDateFormate(d);
     }
     else if(this.appointmentType == "3 Months"){
       var d =new Date();
       d.setDate(d.getDate() - 92)
       e = this.getDateFormate(d);
     }
     else if(this.appointmentType == "6 Months"){
       var weekly,d =new Date();
       d.setDate(d.getDate() - 183)
       e = this.getDateFormate(d);
     }
     else if(this.appointmentType == "Yearly"){
       var weekly,d =new Date();
       d.setDate(d.getDate() - 365)
       e = this.getDateFormate(d);
     }
     else{}
     this.fromDate = Date_Formate(e);
     this.genderBase();
     this.ageBase();
     this.locationBase();
    //  this.weightBase()
    this.packageBase();
    this.satisficationchart();
    this.reveniechart()
  }

  changeChart() {
    this.genderBase();
    this.ageBase();
    this.locationBase();
    // this.weightBase();
    this.packageBase();
    this.satisficationchart();
    this.reveniechart();
  }

  getCurrentDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate',
      JSON.stringify({
        country: ipaddress.country_code
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          this.currentDate = obj.current_date;
          this.fromDate = Date_Formate(this.currentDate),
          this.toDate = Date_Formate(this.currentDate),
          this.chartType = "Pie";
          this.genderBase();
          this.ageBase();
          this.locationBase();
          // this.weightBase();
          this.packageBase();
          this.satisficationchart();
          this.reveniechart();
        },error => {
          this.toastr.error(Message_data.NoAppointments());
        });
  }

  genderBase() {
    var name = Array();
    var count = Array();
    var dataArrayFinal = Array();
    var dataArrayFinal1 = new Array();
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'dash/pappconbygen/',
      JSON.stringify({
        // reg_id: this.hptlClinicId,
        reg_id:this.hsptl_clinic_id,
        fdate: Date_Formate(this.fromDate),
        tdate: Date_Formate(this.toDate),
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          // console.log(" gender Base ="+JSON.stringify(obj))
          if (obj.appointments != null) {
            for (var i = 0; i < obj.appointments.length; i++) {
              name[i] = obj.appointments[i].name;
              count[i] = parseFloat(obj.appointments[i].data);
            }
            if (obj.appointments.length == 0) {
              this.noGrpah = true;
            } else {
              this.noGrpah = false;
            }
            var d = [{ "name": "Cardiology", "data": 1.0 }]
            var dashsearch_array = [];
            if (obj.appointments != null) {
              for (var i = 0; i < obj.appointments.length; i++) {
                dashsearch_array.push(obj.appointments[i].data);
              }
            }
            var Appointment_total = 0;
            for (var i = 0; i < dashsearch_array.length; i++) {
              if (Appointment_total != 0) {
                Appointment_total = Appointment_total + parseInt(dashsearch_array[i]);
              } else {
                Appointment_total = parseInt(dashsearch_array[i]);
              }
            }
            this.totalapp2 = "No of appointment :" + Appointment_total;
            this.totalapp3 = "No of appointment :" + Appointment_total;
            this.totalapp4 = "No of appointment :" + Appointment_total;
            for (var j = 0; j < name.length; j++) {
              var temp = new Array(name[j], count[j]);
              dataArrayFinal[j] = count[j];
              dataArrayFinal1[j] = temp;
            }
            var jsonString = JSON.stringify(dataArrayFinal);
            if (this.chartType == "Pie") {
              var ctx = document.getElementById("piechart_gen");
              // var bgColor = ["#878BB6", "#FFEA88", "#FF8153", "#4ACAB4", "#c0504d", "#8064a2", "#772c2a", "#f2ab71", "#2ab881", "#4f81bd", "#2c4d75"];
              var bgColor=['#94A684','#EBEF95','#AE445A','#35A29F','#E9B824','#186F65','#FFCF96','#FF8080','#FFF2D8','#A7D397','#00A9FF','#0C356A']
              
              var ctx = document.getElementById("piechart_gen");
              if (this.genmyChart) this.genmyChart.destroy();
              this.genmyChart = new Chart(ctx, {
                type: 'pie',
                data: {
                  labels: name,
                  datasets: [
                    {
                      data: dataArrayFinal,
                      backgroundColor: bgColor
                    }]
                }
              });
            } else if (this.chartType == "Line") {
              var ctx = document.getElementById("piechart_gen");
              var bgColor = ["#878BB6", "#FFEA88", "#FF8153", "#4ACAB4", "#c0504d", "#8064a2", "#772c2a", "#f2ab71", "#2ab881", "#4f81bd", "#2c4d75"];
              
              if (this.genmyChart) this.genmyChart.destroy();
              var ctx = document.getElementById("piechart_gen");
              this.genmyChart = new Chart(ctx, {
                type: 'line',
                data: {
                  labels: name,
                  datasets: [
                    {
                      label: name,
                      data: dataArrayFinal,
                      borderWidth: 1
                    },
                  ]
                },
                options: {
                  scales: {
                    yAxes: [{
                      ticks: {
                        reverse: false
                      }
                    }]
                  }
                }
              });
              this.genmyChart.update();
            } else if (this.chartType == "Bar") {
              var ctx = document.getElementById("piechart_gen");
              var bgColor = ["#878BB6", "#FFEA88", "#FF8153", "#4ACAB4", "#c0504d", "#8064a2", "#772c2a", "#f2ab71", "#2ab881", "#4f81bd", "#2c4d75"];
              var data = {
                labels: name,
                datasets: [
                  {
                    label: name,
                    borderWidth: 2,
                    data: dataArrayFinal,
                    backgroundColor: bgColor
                  }
                ]
              };
              var option = {
                scales: {
                  yAxes: [{
                    stacked: true,
                    gridLines: {
                      display: true,
                      color: "rgba(255,99,132,0.2)"
                    }
                  }],
                  xAxes: [{
                    gridLines: {
                      display: false
                    }
                  }]
                }
              };
              if (this.genmyChart) this.genmyChart.destroy();
              this.genmyChart = Chart.Bar("piechart_gen", {
                data: data,
                options: option
              });
            }
          } else {
           this.toastr.error(Message_data.getNetworkMessage());
          }
        },error => {
          this.toastr.error(Message_data.getNetworkMessage());
        })
  }

  ageBase() {
    var name = Array();
    var count = Array();
    var dataArrayFinal = Array();
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'dash/pappconbyage/',
      JSON.stringify({
        // reg_id: this.hptlClinicId,
        reg_id: this.hsptl_clinic_id,
        fdate: Date_Formate(this.fromDate),
        tdate: Date_Formate(this.toDate),

      }),
      { headers: headers })
      .subscribe(
        response => {
          
          var obj = JSON.parse(response["_body"]);
          // console.log("age base chart"+JSON.stringify(obj))
          if (obj.appointments != null) {
            for (var i = 0; i < obj.appointments.length; i++) {
              name[i] = obj.appointments[i].name;
              count[i] = parseFloat(obj.appointments[i].data);
              var get_age_value = obj.appointments[i].data;
            }
            
            var dashsearch_array = [];
            for (var i = 0; i < obj.appointments.length; i++) {
              dashsearch_array.push(obj.appointments[i].data);
            }
            var Appointment_total = 0;
            for (var i = 0; i < dashsearch_array.length; i++) {
              if (Appointment_total != 0) {
                Appointment_total = Appointment_total + parseInt(dashsearch_array[i]);
              }else {
                Appointment_total = parseInt(dashsearch_array[i]);
              }
            }
            if (get_age_value != null) {
              this.totalapp1 = "No of appointments :" + get_age_value;
              this.ageGraphFlag = false;
            } else {
              this.totalapp1 = "No of appointments :" + get_age_value;
              this.ageGraphFlag = true;
            }
            this.totalapp2 = "No of appointments :" + Appointment_total;
            this.totalapp3 = "No of appointments :" + Appointment_total;
            this.totalapp4 = "No of appointments :" + Appointment_total;
            var d = [{ "name": "Cardiology", "data": 1.0 }]
            for (var j = 0; j < name.length; j++) {
              var temp = new Array(name[j], count[j]);
              dataArrayFinal[j] = count[j];
            }
            var jsonString = JSON.stringify(dataArrayFinal);
            // console.log("json string ------->"+JSON.stringify(jsonString))
            if (this.chartType == "Pie") {
              // var ctx = document.getElementById("AgeCanvas");
              var ctx = document.getElementById("ageCanvas");
              // var bgColor = ["#878BB6", "#FFEA88", "#FF8153", "#4ACAB4", "#c0504d", "#8064a2", "#772c2a", "#f2ab71", "#2ab881", "#4f81bd", "#2c4d75"];
              var bgColor=['#ADE792','#FBC252','#EAC7C7','#8BACAA','#F6635C','#836096','#C38154','#FFE7CE','#CD1818','#47A992','#B7B7B7','#A84448'];
              
              var ctx = document.getElementById("ageCanvas");
              if (this.ageCanvas) this.ageCanvas.destroy();
              this.ageCanvas = new Chart(ctx, {
                type: 'pie',
                data: {
                  labels: name,
                  datasets: [
                    {
                      data: dataArrayFinal,
                      backgroundColor: bgColor
                    }]
                }
              });
            } else if (this.chartType == "Line") {
              // var ctx = document.getElementById("AgeCanvas");
              var bgColor = ["#878BB6", "#FFEA88", "#FF8153", "#4ACAB4", "#c0504d", "#8064a2", "#772c2a", "#f2ab71", "#2ab881", "#4f81bd", "#2c4d75"];
              var ctx = document.getElementById("ageCanvas");
              if (this.ageCanvas) this.ageCanvas.destroy();
              this.ageCanvas = new Chart(ctx, {
                type: 'line',
                data: {
                  labels: name,
                  datasets: [
                    {
                      label: name,
                      data: dataArrayFinal,
                      borderWidth: 1
                    },
                  ]
                },
                options: {
                  scales: {
                    yAxes: [{
                      ticks: {
                        reverse: false
                      }
                    }]
                  }
                }
              });
            } else if (this.chartType == "Bar") {
              // var ctx = document.getElementById("AgeCanvas");
              var ctx = document.getElementById("ageCanvas");
              var bgColor = ["#878BB6", "#FFEA88", "#FF8153", "#4ACAB4", "#c0504d", "#8064a2", "#772c2a", "#f2ab71", "#2ab881", "#4f81bd", "#2c4d75"];
              var data = {
                labels: name,
                datasets: [
                  {
                    label: name,
                    borderWidth: 2,
                    data: dataArrayFinal,
                    backgroundColor: bgColor
                  }
                ]
              };
              var option = {
                scales: {
                  yAxes: [{
                    stacked: true,
                    gridLines: {
                      display: true,
                      color: "rgba(255,99,132,0.2)"
                    }
                  }],
                  xAxes: [{
                    gridLines: {
                      display: false
                    }
                  }]
                }
              };
              if (this.ageCanvas) this.ageCanvas.destroy();
              this.ageCanvas = Chart.Bar("AgeCanvas", {
                data: data,
                options: option
              });
            }
          }
        },
        error => {
          //this.toastr.error(Message_data.getNetworkMessage());
        }
      )
  }

  locationBase() {
    var name = Array();
    var count = Array();
    var dataArrayFinal = Array();
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'dash/pappconloc/',
      JSON.stringify({
        reg_id: this.hsptl_clinic_id,
        fdate: Date_Formate(this.fromDate),
        tdate: Date_Formate(this.toDate),
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.appointments != null) {
            for (var i = 0; i < obj.appointments.length; i++) {
              name[i] = obj.appointments[i].name;
              count[i] = parseFloat(obj.appointments[i].data);
            }
            var dashsearch_array = [];
            if (obj.appointments != null) {
              for (var i = 0; i < obj.appointments.length; i++) {
                dashsearch_array.push(obj.appointments[i].data);
              }
            }
            var Appointment_total = 0;
            for (var i = 0; i < dashsearch_array.length; i++) {
              if (Appointment_total != 0) {
                Appointment_total = Appointment_total + parseInt(dashsearch_array[i]);
              }
              else {
                Appointment_total = parseInt(dashsearch_array[i]);
              }
            }
            this.totalapp2 = "No of appointments :" + Appointment_total;
            this.totalapp3 = "No of appointments :" + Appointment_total;
            this.totalapp4 = "No of appointments :" + Appointment_total;
            var d = [{ "name": "Cardiology", "data": 1.0 }]
            for (var j = 0; j < name.length; j++) {
              var temp = new Array(name[j], count[j]);
              dataArrayFinal[j] = count[j];
            }
            var jsonString = JSON.stringify(dataArrayFinal);
            if (this.chartType == "Pie") {
              var ctx = document.getElementById("LocationCanvas");
              var bgColor = ["#878BB6", "#FFEA88", "#FF8153", "#4ACAB4", "#c0504d", "#8064a2", "#772c2a", "#f2ab71", "#2ab881", "#4f81bd", "#2c4d75"];
              var ctx = document.getElementById("LocationCanvas");
              if (this.LocationCanvas) this.LocationCanvas.destroy();
              this.LocationCanvas = new Chart(ctx, {
                type: 'pie',
                data: {
                  labels: name,
                  datasets: [
                    {
                      data: dataArrayFinal,
                      backgroundColor: bgColor
                    }]
                }
              });
            } else if (this.chartType == "Line") {
              var ctx = document.getElementById("LocationCanvas");
              var bgColor = ["#878BB6", "#FFEA88", "#FF8153", "#4ACAB4", "#c0504d", "#8064a2", "#772c2a", "#f2ab71", "#2ab881", "#4f81bd", "#2c4d75"];
              var ctx = document.getElementById("LocationCanvas");
              if (this.LocationCanvas) this.LocationCanvas.destroy();
              this.LocationCanvas = new Chart(ctx, {
                type: 'line',
                data: {
                  labels: name,
                  datasets: [
                    {
                      label: name,
                      data: dataArrayFinal,
                      borderWidth: 1
                    },
                  ]
                },
                options: {
                  scales: {
                    yAxes: [{
                      ticks: {
                        reverse: false
                      }
                    }]
                  }
                }
              });
            } else if (this.chartType == "Bar") {
              var ctx = document.getElementById("LocationCanvas");
              var bgColor = ["#878BB6", "#FFEA88", "#FF8153", "#4ACAB4", "#c0504d", "#8064a2", "#772c2a", "#f2ab71", "#2ab881", "#4f81bd", "#2c4d75"];
              var data = {
                labels: name,
                datasets: [
                  {
                    label: name,
                    borderWidth: 2,
                    data: dataArrayFinal,
                    backgroundColor: bgColor
                  }
                ]
              };
              var option = {
                scales: {
                  yAxes: [{
                    stacked: true,
                    gridLines: {
                      display: true,
                      color: "rgba(255,99,132,0.2)"
                    }
                  }],
                  xAxes: [{
                    gridLines: {
                      display: false
                    }
                  }]
                }
              };
              if (this.LocationCanvas) this.LocationCanvas.destroy();
              this.LocationCanvas = Chart.Bar("LocationCanvas", {
                data: data,
                options: option
              });
            }
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      )
  }
public agearray=[];

  packageBase() {
    var name = Array();
    var count = Array();
    var pack_id = Array();
    var dataArrayFinal = Array();
    var packagedata=[];
    var agebasearray=[];
    var dataArrayFinal1 = new Array();
    var pkgarray = this.packagearray;
    var pkgid;
    var agearray1:any=[];

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'dash/pappconbygen/',
      JSON.stringify({
        reg_id: this.hsptl_clinic_id,
        fdate: Date_Formate(this.fromDate),
        tdate: Date_Formate(this.toDate),
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          // console.log(" package Base ="+JSON.stringify(obj))
          if (obj.packages != null) {
            for (var i = 0; i < obj.packages.length; i++) {
              pkgarray = obj.packages;
              
              pack_id[i] =parseFloat(obj.packages[i].package_id);
              name[i] = obj.packages[i].package_desc;
              count[i] = parseFloat(obj.packages[i].package_count);
            }

            for(var j=0;j< obj.pack_age_gender.length;j++){
              packagedata.push({
                id:obj.pack_age_gender[j].package_id,
                age:obj.pack_age_gender[j].age,
                gender:obj.pack_age_gender[j].gender,
                genderCount:obj.pack_age_gender[j].gender_count,
                ageCount:obj.pack_age_gender[j].age_count,
              })
            }



            if (obj.packages.length == 0) {
              this.noGrpah = true;
            } else {
              this.noGrpah = false;
            }
            var d = [{ "name": "Cardiology", "data": 1.0 }]
            var dashsearch_array = [];
            if (obj.packages != null) {
              for (var i = 0; i < obj.packages.length; i++) {
                dashsearch_array.push(obj.packages[i].package_count);
              }
            }
            var Appointment_total = 0;
            for (var i = 0; i < dashsearch_array.length; i++) {
              if (Appointment_total != 0) {
                Appointment_total = Appointment_total + parseInt(dashsearch_array[i]);
              } else {
                Appointment_total = parseInt(dashsearch_array[i]);
              }
            }
            this.totalapp2 = "No of appointment :" + Appointment_total;
            this.totalapp3 = "No of appointment :" + Appointment_total;
            this.totalapp4 = "No of appointment :" + Appointment_total;
            for (var j = 0; j < name.length; j++) {
              var temp = new Array(name[j], count[j]);
              dataArrayFinal[j] = count[j];
              dataArrayFinal1[j] = temp;
            }
            var jsonString = JSON.stringify(dataArrayFinal);
            // console.log("JSON STRING"+JSON.stringify(dataArrayFinal))
            if (this.chartType == "Pie") {
              
              var ctx = document.getElementById("piechart_gen");
              // var bgColor = ["#878BB6", "#FFEA88", "#FF8153", "#4ACAB4", "#c0504d", "#8064a2", "#772c2a", "#f2ab71", "#2ab881", "#4f81bd", "#2c4d75"];
              var bgColor=['#94A684','#EBEF95','#AE445A','#35A29F','#E9B824','#186F65','#FFCF96','#FF8080','#FFF2D8','#A7D397','#00A9FF','#0C356A']
              
              var ctx = document.getElementById("package_piechart");
              if (this.packagechart) this.packagechart.destroy();
              this.packagechart = new Chart(ctx, {
                type: 'pie',
                data: {
                  labels: name,
                  datasets: [
                    {
                      data: dataArrayFinal,
                      backgroundColor: bgColor
                    }]
                },

                options: {

                },
               }
              );

            } else if (this.chartType == "Line") {
              var ctx = document.getElementById("package_piechart");
              var bgColor = ["#878BB6", "#FFEA88", "#FF8153", "#4ACAB4", "#c0504d", "#8064a2", "#772c2a", "#f2ab71", "#2ab881", "#4f81bd", "#2c4d75"];
              
              if (this.packagechart) this.packagechart.destroy();
              var ctx = document.getElementById("package_piechart");
              this.packagechart = new Chart(ctx, {
                type: 'line',
                data: {
                  labels: name,
                  datasets: [
                    {
                      label: name,
                      data: dataArrayFinal,
                      borderWidth: 1
                    },
                  ]
                },
                options: {
                  scales: {
                    yAxes: [{
                      ticks: {
                        reverse: false
                      }
                    }]
                  }
                }
              });
              this.packagechart.update();
            } else if (this.chartType == "Bar") {
              var ctx = document.getElementById("package_piechart");
              var bgColor = ["#878BB6", "#FFEA88", "#FF8153", "#4ACAB4", "#c0504d", "#8064a2", "#772c2a", "#f2ab71", "#2ab881", "#4f81bd", "#2c4d75"];
              var data = {
                labels: name,
                datasets: [
                  {
                    label: name,
                    borderWidth: 2,
                    data: dataArrayFinal,
                    backgroundColor: bgColor
                  }
                ]
              };
              var option = {
                scales: {
                  yAxes: [{
                    stacked: true,
                    gridLines: {
                      display: true,
                      color: "rgba(255,99,132,0.2)"
                    }
                  }],
                  xAxes: [{
                    gridLines: {
                      display: false
                    }
                  }]
                }
              };
              if (this.packagechart) this.packagechart.destroy();
              this.packagechart = Chart.Bar("package_piechart", {
                data: data,
                options: option
              });
            }
          } 
          this.agearray=agearray1
        },
        error => {
        }
      )
  }

  satisficationchart(){
    var name = Array();
    var count = Array();
    var packcount=Array();
    var dataArrayFinal = Array();
    var dataArrayFinalcount=Array();
    var dataArrayFinal1 = new Array();
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'dash/pappconbygen/',
      JSON.stringify({
        reg_id:this.hsptl_clinic_id,
        fdate: Date_Formate(this.fromDate),
        tdate: Date_Formate(this.toDate),
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.packages != null) {
            for (var i = 0; i < obj.packages.length; i++) {
              name[i] = obj.packages[i].package_desc;
              count[i] = parseFloat(obj.packages[i].sat_level);
              packcount[i] = parseFloat(obj.packages[i].package_count);
            }
            if (obj.packages.length == 0) {
              this.noGrpah = true;
            } else {
              this.noGrpah = false;
            }
            var d = [{ "name": "Cardiology", "data": 1.0 }]
            var dashsearch_array = [];
            if (obj.packages != null) {
              for (var i = 0; i < obj.packages.length; i++) {
                dashsearch_array.push(obj.packages[i].sat_level);
              }
            }
            var Appointment_total = 0;
            for (var i = 0; i < dashsearch_array.length; i++) {
              if (Appointment_total != 0) {
                Appointment_total = Appointment_total + parseInt(dashsearch_array[i]);
              } else {
                Appointment_total = parseInt(dashsearch_array[i]);
              }
            }
            this.totalapp2 = "No of appointment :" + Appointment_total;
            this.totalapp3 = "No of appointment :" + Appointment_total;
            this.totalapp4 = "No of appointment :" + Appointment_total;
            for (var j = 0; j < name.length; j++) {
              var temp = new Array(name[j], count[j]);
              dataArrayFinal[j] = count[j];
              dataArrayFinalcount[j]=packcount[j]
              dataArrayFinal1[j] = temp;
            }
            var jsonString = JSON.stringify(dataArrayFinal);
            if (this.chartType == "bar") {
              var bgColor=['#94A684','#EBEF95','#AE445A','#35A29F','#E9B824','#186F65','#FFCF96','#FF8080','#FFF2D8','#A7D397','#00A9FF','#0C356A']
              
              var ctx = document.getElementById("saticification_piechart");
              if (this.satisfication_level_chart) this.satisfication_level_chart.destroy();
              this.satisfication_level_chart = new Chart(ctx, {
                type: 'pie',
                data: {
                  labels: name,
                  datasets: [
                    {
                      data: dataArrayFinal,
                      backgroundColor: bgColor
                    }]
                }
              });
            } else if (this.chartType == "Line") {
              var ctx = document.getElementById("saticification_piechart");
              var bgColor = ["#878BB6", "#FFEA88", "#FF8153", "#4ACAB4", "#c0504d", "#8064a2", "#772c2a", "#f2ab71", "#2ab881", "#4f81bd", "#2c4d75"];
              
              if (this.satisfication_level_chart) this.satisfication_level_chart.destroy();
              var ctx = document.getElementById("saticification_piechart");
              this.satisfication_level_chart = new Chart(ctx, {
                type: 'line',
                data: {
                  labels: name,
                  datasets: [
                    {
                      label: name,
                      data: dataArrayFinal,
                      borderWidth: 1
                    },
                  ]
                },
                options: {
                  scales: {
                    yAxes: [{
                      ticks: {
                        reverse: false
                      }
                    }]
                  }
                }
              });
              this.satisfication_level_chart.update();
            } else if (this.chartType == "Pie") {
              
              var ctx = document.getElementById("saticification_piechart");
              var bgColor = ["#878BB6", "#FFEA88", "#FF8153", "#4ACAB4", "#c0504d", "#8064a2", "#772c2a", "#f2ab71", "#2ab881", "#4f81bd", "#2c4d75"];
              var bgColorpk            =["#878BB6", "#FFEA88", "#FF8153", "#4ACAB4","#C1D8C3","#DE8F5F","#FBECB2","#C2D9FF"]
              
              var data = {
                 labels: name,
                datasets: [
                  {
                    label: ["Staifsication level"],
                    borderWidth: 2,
                    data: dataArrayFinal,
                    backgroundColor: bgColor
                  },
                  {
                    label:["Package count"],
                    borderWidth: 2,
                    data: dataArrayFinalcount,
                    backgroundColor: bgColorpk
                  }

                ]
              };
              var option = {
                scales: {
                  yAxes: [{
                    display: true,
                    
                    ticks: {
                      beginAtZero: true,
                    },

                    gridLines: {
                      display: true,
                      color: "rgba(255,99,132,0.2)"
                    }

                  }],
                  xAxes: [{
                    barPercentage: 0.5, 
                    gridLines: {
                      display: false
                    }
                  }]
                },
               
              };
              if (this.satisfication_level_chart) this.satisfication_level_chart.destroy();
              this.satisfication_level_chart = Chart.Bar("saticification_piechart", {
                type:'Bar',
                data: data,
                options: option
              });
            }
          } else {
          }
        },error => {
        })
  }

  reveniechart(){
    var name = Array();
    var count = Array();
    var dataArrayFinal = Array();
    var dataArrayFinal1 = new Array();
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'dash/pappconbygen/',
      JSON.stringify({
        reg_id:this.hsptl_clinic_id,
        fdate: Date_Formate(this.fromDate),
        tdate: Date_Formate(this.toDate),
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          // console.log(" revenue chart ="+JSON.stringify(obj))
          if (obj.packages != null) {
            for (var i = 0; i < obj.packages.length; i++) {
              name[i] = obj.packages[i].package_desc;
              count[i] = parseFloat(obj.packages[i].revenue);
            }
            if (obj.appointments.length == 0) {
              this.noGrpah = true;
            } else {
              this.noGrpah = false;
            }
            var d = [{ "name": "Cardiology", "data": 1.0 }]
            var dashsearch_array = [];
            if (obj.pack_age_gender != null) {
              for (var i = 0; i < obj.packages.length; i++) {
                dashsearch_array.push(obj.packages[i].revenue);
              }
            }
            var Appointment_total = 0;
            for (var i = 0; i < dashsearch_array.length; i++) {
              if (Appointment_total != 0) {
                Appointment_total = Appointment_total + parseInt(dashsearch_array[i]);
              } else {
                Appointment_total = parseInt(dashsearch_array[i]);
              }
            }
            this.totalapp2 = "No of appointment :" + Appointment_total;
            this.totalapp3 = "No of appointment :" + Appointment_total;
            this.totalapp4 = "No of appointment :" + Appointment_total;
            for (var j = 0; j < name.length; j++) {
              var temp = new Array(name[j], count[j]);
              dataArrayFinal[j] = count[j];
              dataArrayFinal1[j] = temp;
            }
            var jsonString = JSON.stringify(dataArrayFinal);
            if (this.chartType == "Pie") {
              var bgColor = ["#878BB6", "#FFEA88", "#FF8153", "#4ACAB4", "#c0504d", "#8064a2", "#772c2a", "#f2ab71", "#2ab881", "#4f81bd", "#2c4d75"];
              var ctx = document.getElementById("revenie_piechart");
              if (this.revenuchart) this.revenuchart.destroy();
              this.revenuchart = new Chart(ctx, {
                type: 'pie',
                data: {
                  labels: name,
                  datasets: [
                    {
                      data: dataArrayFinal,
                      backgroundColor: bgColor
                    }]
                }
              });
            } else if (this.chartType == "Line") {
              var ctx = document.getElementById("revenie_piechart");
              var bgColor = ["#878BB6", "#FFEA88", "#FF8153", "#4ACAB4", "#c0504d", "#8064a2", "#772c2a", "#f2ab71", "#2ab881", "#4f81bd", "#2c4d75"];
              
              if (this.revenuchart) this.revenuchart.destroy();
              var ctx = document.getElementById("revenie_piechart");
              this.revenuchart = new Chart(ctx, {
                type: 'line',
                data: {
                  labels: name,
                  datasets: [
                    {
                      label: name,
                      data: dataArrayFinal,
                      borderWidth: 1
                    },
                  ]
                },
                options: {
                  scales: {
                    yAxes: [{
                      ticks: {
                        reverse: false
                      }
                    }]
                  }
                }
              });
              this.revenuchart.update();
            } else if (this.chartType == "Bar") {
              var ctx = document.getElementById("revenie_piechart");
              var bgColor = ["#878BB6", "#FFEA88", "#FF8153", "#4ACAB4", "#c0504d", "#8064a2", "#772c2a", "#f2ab71", "#2ab881", "#4f81bd", "#2c4d75"];
              var data = {
                labels: name,
                datasets: [
                  {
                    label: name,
                    borderWidth: 2,
                    data: dataArrayFinal,
                    backgroundColor: bgColor
                  }
                ]
              };
              var option = {
                scales: {
                  yAxes: [{
                    stacked: true,
                    gridLines: {
                      display: true,
                      color: "rgba(255,99,132,0.2)"
                    }
                  }],
                  xAxes: [{
                    gridLines: {
                      display: false
                    }
                  }]
                }
              };
              if (this.revenuchart) this.revenuchart.destroy();
              this.revenuchart = Chart.Bar("revenie_piechart", {
                data: data,
                options: option
              });
            }
          } else {
          }
        },error => {
        })



  }



  //   function graphClickEvent(event, array){
//     if(array[0]){
//         foo.bar; 
//     }
// }




}


