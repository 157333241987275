<div class="row" style="margin:0 auto; width:100%">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white" style="padding:7px 20px;">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Order details</h5>
          </div>
          <div class="headerButtons">
            <div class="" *ngIf="header_footer_flag">
              <img src="../../../assets/ui_icons/buttons/update_button.svg" class="saveimgbtn_inpatinfo"
                (click)="update()" *ngIf="updateFlag">
              <img src="../../../assets/ui_icons/buttons/cancel_button.svg" class="saveimgbtn_inpatinfo"
                (click)="cancel()" *ngIf="cancelFlag">
              <img src="../../../assets/ui_icons/buttons/Rate_button.svg" class="saveimgbtn_inpatinfo"
                (click)="rateUs()" *ngIf="rateFlag">
              <img src="../../../assets/ui_icons/buttons/Back_button.svg" class="saveimgbtn_inpatinfo"
                (click)="back()">
            </div>
            <div *ngIf="!header_footer_flag">
              <img src="../../../assets/ui_icons/buttons/update_button.svg" *ngIf="updateFlag" (click)="update()"
                class="saveimgbtn_inpatinfo">
              <img src="../../../assets/ui_icons/buttons/cancel_button.svg" class="saveimgbtn_inpatinfo"
                (click)="cancel()" *ngIf="cancelFlag">
              <img src="../../../assets/ui_icons/buttons/Rate_button.svg" class="saveimgbtn_inpatinfo"
                (click)="rateUs()" *ngIf="rateFlag">
            </div>
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div>
          <div class="row">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <div class="cover_div">
                <div class="header_lable">
                  Order details
                </div>
                <div class="content_cover">
                  <div class="row">
                    <div class="col-7">
                      <table>
                        <tr>
                          <td [hidden]="clientNameFlag" class="changefont">Name :<b> {{clientName}}</b></td>
                        </tr>
                        <tr>
                          <td class="changefont">Order number : {{orderId}}</td>
                        </tr>
                        <tr>
                          <td class="changefont">Order date & time: {{cientOrderdate}}</td>
                        </tr>
                        <tr>
                          <td class="changefont" *ngIf="deliveryStatusFlag">Delivered date & time : {{cientDelivereddate}}
                          </td>
                        </tr>
                        <tr>
                          <td class="changefont" *ngIf="exeptationStatusFlag">Expectation date & time :
                            {{cientDelivereddate}}
                          </td>
                        </tr>
                        <tr>
                          <td class="changefont" *ngIf="preferredStatusFlag">Preferred time : {{preferredTime}}</td>
                        </tr>
                        <tr>
                          <td class="changefont" *ngIf="cancelStatusFlag">Reason for cancellation : {{clientCancellation}}
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div class="col-5">
                      <table>
                        <tr>
                          <td class="changefont">Delivery mode : {{cientDescription}}</td>
                        </tr>
                        <tr>
                          <td class="changefont" *ngIf="storeDaysFlag">Order valid days : {{validDays}}</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
              <div class="cover_div">
                <div class="header_lable">
                  Address details
                </div>
                <div class="content_cover">
                  <table>
                    <tr>
                      <td class="changefont">{{pharmacyName}}</td>
                    </tr>
                    <tr>
                      <td class="changefont">{{pharmacyAddress1}}</td>
                    </tr>
                    <tr>
                      <td class="changefont">{{pharmacyLocation}} {{pharmacyCity}} {{pharmacyZipcode}}</td>
                    </tr>
                    <tr>
                      <td class="changefont">{{pharmacyState}} {{pharmacyCountry}}</td>
                    </tr>
                    <tr>
                      <td class="changefont">{{pharmacyTelephone}} </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-12 col-md-12 col-xl-12" style="padding-top: 10px;">
          <div class="table-responsive dig_table_overflow" *ngIf="drugOrdersList.length">
            <table id="bill_create" class="table table-nowrap table-sm billcreae_table">
              <thead class="tableheading">
                <tr style="height: 32px;">
                  <th>Medicine type</th>
                  <th>Medicine name</th>
                  <th>Quantity</th>
                  <th style="text-align: center;">Price </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let drugorders of drugOrdersList">
                  <td class="align_left">{{drugorders.pharamcytypename}}</td>
                  <td class="align_left">{{drugorders.pharamcydrug_name}}</td>
                  <td>{{drugorders.pharamcyquantity}}</td>
                  <td class="rightalign">{{drugorders.pharamcyamount}}</td>
                </tr>
                <tr class="remove_borderLine">
                  <td></td>
                  <td></td>
                  <td><b class="total">Total</b></td>
                  <td class="rightalign">{{totalAmountValue1}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row ">
            <div class="col-0 col-sm-4  col-md-7 col-lg-8 col-xl-8"></div>
            <div class="col-0 col-sm-8  col-md-5 col-lg-4  col-xl-4" style="margin-left: -23px;">
              <div class="row">
                <div *ngFor="let tax of pharmaOrderTax">
                  <div class="row">
                    <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                      <mat-label class="matlabel  gstfield">{{tax.pharmataxdesc +' '+ tax.pharamtax + ' %'}}</mat-label>
                    </div>
                    <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                      <mat-label class=" ipcss billcreatech heightmsr bill_txt">{{tax.pharmatotalAmount}}</mat-label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row ">
            <div class="col-0 col-sm-4  col-md-7 col-lg-8 col-xl-8"></div>
            <div class="col-0 col-sm-8  col-md-5 col-lg-4  col-xl-4" style="margin-left: -23px;">
              <div class="row">
                <div>
                  <div class="row">
                    <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                      <mat-label class="matlabel  gstfield">Total</mat-label>
                    </div>
                    <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                      <mat-label class=" ipcss billcreatech heightmsr bill_txt">{{totalAmountValue1}}</mat-label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>