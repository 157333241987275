<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card style="border:none;box-shadow:none !important;">
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Help</h5>
          </div>
          <div class="headerButtons">
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row">
          <div class="col-4">
            <mat-label class="matlabel">App help required for<br>
              <select id="selection" class="ipcss" [(ngModel)]="module" (ngModelChange)="changeTopic($event)"
                disableOptionCentering>
                <option disabled selected value="--Select Here--">--Select here--</option>
                <option *ngFor="let module of moduleArray" value={{module.module_id}}>{{module.description}}
                </option>
              </select>
            </mat-label>
          </div>
          <div class="col-12">
            <p class="nodata">No help text found</p>
            <div class="row" *ngIf="helpTopic.length != 0">
              <mat-label class="matlabel"><b>Steps to follow </b></mat-label>
              <mat-card id="cardcontent" *ngFor="let help of helpTopic">
                <mat-card-content>
                  <mat-label class="matlabel">{{help.helpdesc}}</mat-label>
                </mat-card-content>
              </mat-card>
            </div>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>