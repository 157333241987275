<div *ngIf="pediapregdelFlag == 'min'">
    <div class="row">
        <div [hidden]="motherage_hidden" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
            <mat-label class="matlabel" [hidden]="motherage_hidden">Mother’s age at pregnancy<br>
                <input matInput class="ipcss " [disabled]="mother_disabled" maxlength="3" required
                    [(ngModel)]="Mothers_age" onkeypress="return event.charCode >= 48 && event.charCode <= 57">
            </mat-label>
        </div>
        <div [hidden]="gestation_agehidden" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
            <mat-label class="matlabel" [hidden]="gestation_agehidden">Gestational age (weeks)<br>
                <select class="ipcss " [disabled]="gestage_disabled" required [(ngModel)]="Gest_age"
                    disableOptionCentering>
                    <option disabled>Select</option>
                    <option value="24">24</option>
                    <option value="25">25</option>
                    <option value="26">26</option>
                    <option value="27">27</option>
                    <option value="28">28</option>
                    <option value="29">29</option>
                    <option value="30">30</option>
                    <option value="31">31</option>
                    <option value="32">32</option>
                    <option value="33">33</option>
                    <option value="34">34</option>
                    <option value="35">35</option>
                    <option value="36">36</option>
                    <option value="37">37</option>
                    <option value="38">38</option>
                    <option value="39">39</option>
                    <option value="40">40</option>
                    <option value="40">41</option>
                </select>
            </mat-label>
        </div>
        <div [hidden]="delivery_hidden" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
            <mat-label class="matlabel" [hidden]="delivery_hidden">Delivery type<br>
                <select class="ipcss " [disabled]="delivery_disabled" required [(ngModel)]="Delivery_type"
                    disableOptionCentering>
                    <option disabled>Select</option>
                    <option value="Normal">Normal</option>
                    <option value="C Section">C Section</option>
                </select>
            </mat-label>
        </div>
    </div>
</div>

<div *ngIf="pediapregdelFlag == 'max'">
    <div class="container">
        <div class="row">
            <div class="cover_div">
                <div class="header_lable">
                    Pregnancy & Delivery
                </div>
                <div class="content_cover">
                    <div class="row">
                        <div [hidden]="motherage_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                            <mat-label class="matlabel" [hidden]="motherage_hidden">Mother’s age at pregnancy<br>
                                <input [readonly]="client" matInput class="ipcss " [disabled]="mother_disabled"
                                    maxlength="3" required [(ngModel)]="Mothers_age" (blur)="send_data()"
                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                            </mat-label>
                        </div>
                        <div [hidden]="gestation_agehidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                            <mat-label class="matlabel" [hidden]="gestation_agehidden">Gestational age (weeks)<br>
                                <select class="ipcss " [disabled]="gestage_disabled && client" required (change)="send_data()"
                                    [(ngModel)]="Gest_age" disableOptionCentering> 
                                    <option disabled>Select</option>
                                    <option value="24">24</option>
                                    <option value="25">25</option>
                                    <option value="26">26</option>
                                    <option value="27">27</option>
                                    <option value="28">28</option>
                                    <option value="29">29</option>
                                    <option value="30">30</option>
                                    <option value="31">31</option>
                                    <option value="32">32</option>
                                    <option value="33">33</option>
                                    <option value="34">34</option>
                                    <option value="35">35</option>
                                    <option value="36">36</option>
                                    <option value="37">37</option>
                                    <option value="38">38</option>
                                    <option value="39">39</option>
                                    <option value="40">40</option>
                                    <option value="40">41</option>
                                </select>
                            </mat-label>
                        </div>
                        <div [hidden]="delivery_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                            <mat-label class="matlabel" [hidden]="delivery_hidden">Delivery type<br>
                                <select class="ipcss " [disabled]="delivery_disabled && client" required (change)="send_data()"
                                    [(ngModel)]="Delivery_type" disableOptionCentering>
                                    <option disabled>Select</option>
                                    <option value="Normal">Normal</option>
                                    <option value="C Section">C Section</option>
                                </select>
                            </mat-label>
                        </div>
                        <div [hidden]="noof_preghidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                            <mat-label class="matlabel" [hidden]="noof_preghidden">No of pregnancy<br>
                                <input [readonly]="client" matInput class="ipcss " [disabled]="noof_pregdisabled" (blur)="send_data()"
                                    maxlength="2" required [(ngModel)]="noof_preg"
                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                            </mat-label>
                        </div>
                        <div [hidden]="allergy_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                            <mat-label class="matlabel" [hidden]="allergy_hidden">Allergies<br>
                                <textarea [readonly]="client" class="ipcss obsttxtareaheight" #myInput id="myInput"  (blur)="send_data()"
                                    rows="1" maxlength="150" [(ngModel)]="Allergy_txt"></textarea>
                            </mat-label>
                        </div>
                        <div [hidden]="surgical_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                            <mat-label class="matlabel" [hidden]="surgical_hidden">Surgical history<br>
                                <textarea [readonly]="client" class="ipcss obsttxtareaheight" #myInput id="myInput" (blur)="send_data()"
                                    rows="1" maxlength="150" [(ngModel)]="surgical_hist"></textarea>
                            </mat-label>
                        </div>
                        <div [hidden]="feeding_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                            <mat-label class="matlabel" [hidden]="feeding_hidden">Feeding history<br>
                                <select [disabled]="client" class="ipcss " required [(ngModel)]="Feed_history"  (change)="send_data()"
                                    disableOptionCentering>
                                    <option disabled>Select</option>
                                    <option value="Breast feeding">Breast feeding</option>
                                    <option value="Bottle feeding">Bottle feeding</option>
                                    <option value="Breast and bottle feeding">Breast and bottle feeding</option>
                                </select>
                            </mat-label>
                        </div>
                        <div [hidden]="past_medicalhidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                            <mat-label class="matlabel" [hidden]="past_medicalhidden">Medical history<br>
                                <textarea [readonly]="client" matInput class="ipcss obsttxtareaheight " (blur)="send_data()"
                                    [(ngModel)]="Medical_hist" (click)="medical_popup()"></textarea>
                            </mat-label>
                        </div>
                        <div [hidden]="maternal_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                            <mat-label class="matlabel" [hidden]="maternal_hidden">Maternal illnesses<br>
                                <textarea [readonly]="client" matInput class="ipcss obsttxtareaheight " (blur)="send_data()"
                                    [(ngModel)]="Matenal_illness" (click)="maternal_pop()"></textarea>
                            </mat-label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

