<div class="row" style="margin:0 auto; width:100%">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                      <h5 class="m-0" class="mainHeadingStyle">Notifications</h5>
                    </div>
                    <div class="headerButtons">
                        <img src="../../../assets/ui_icons/buttons/Back_button.svg" (click)="back()"
                        class="saveimgbtn_inpatinfo" />
                    <img *ngIf="saveFlag" src="../../../assets/ui_icons/buttons/save_button.svg" class="saveimgbtn_inpatinfo"
                        (click)="savePrescriptionNotification()" />
                    </div>
                  </div>
            </mdb-card-header>
            <mdb-card-body>
                <div class="">
                    <div class="row">
                        <mat-card id="cardcontent">
                            <mat-card-content style="margin-top: 7px;">
                                <div class="row">
                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <mat-label class="matlabel"><b class="notification_view">Doctor
                                            </b>:{{doctorName}}</mat-label><br>
                                        <mat-label class="matlabel"><b class="notification_view">Date
                                            </b>:{{prescriptionDate}}</mat-label>
                                    </div>
                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <mat-label class="matlabel"><b class="notification_view">Patient
                                            </b>:{{clientName}}</mat-label><br>
                                        <mat-label class="matlabel"><b class="notification_view">Disease
                                            </b>:{{diseaseName}} {{diseaseDesc}}</mat-label>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="table-responsive dig_table_overflow">
                                <table *ngIf="tabletTracker.length" mdbTable datatable [dtOptions]="dtOptions" id="tbl"
                                class="table table-nowrap table-sm dataTable billcreae_table">
                                    <thead class="tableheading">
                                        <tr style="height: 32px;">
                                            <th>Medicine</th>
                                            <th>Dosage</th>
                                            <th>Days</th>
                                            <th>Intake</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let medicene of tabletTracker">
                                            <td>{{medicene.medicene_name}}</td>
                                            <td>{{medicene.medicene_dosage}}</td>
                                            <td>{{medicene.medicene_intakedays}}</td>
                                            <td>{{medicene.medicene_food}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <mat-label class="matlabel">Types <br>
                                <mat-select class="ipcss notification_view" [(ngModel)]="consultTxt"
                                    (ngModelChange)="consultChange()" disableOptionCentering>
                                    <mat-option disabled>Select</mat-option>
                                    <mat-option value="Consult me">Consult me</mat-option>
                                    <mat-option value="Consult me">Extend</mat-option>
                                </mat-select>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <mat-label [hidden]="daysFlag" class="matlabel">Days <br>
                                <input type="text" class="ipcss notification_view" [(ngModel)]="daysTxt"
                                    (onkeypress)="keyPress($event)" matInput />
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <mat-label class="matlabel">Remarks <br>
                                <textarea class="ipcss widthappt" [(ngModel)]="remark"
                                    maxlength="150"></textarea>
                            </mat-label>
                        </div>
                    </div>
                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>