<div mat-dialog-title class="popup_border_icd">
  <h5 class="header_txt">Precription details</h5>
  <div mat-dialog-actions>
    <img src="../../../assets/img/close_icon.png" (click)="close()" width="20px" height="20px" id="close_icd" />
  </div>
</div>
<div mat-dialog-content class="pharpres_content">
  <div class="container" style="min-height: 40px;margin-top: 10px;">
    <div class="row">
      <div class="col-md-12 col-lg-12 col-sm-12 col-xl-12 ">
        <mat-radio-group  color="primary" labelPosition="before" (change)="selectClientType($event)"><b
             class="tervys_heading">Client type :&nbsp;&nbsp;
          </b>
          <mat-radio-button  value="Book appointment">Book appointment </mat-radio-button>&nbsp;&nbsp;
          <mat-radio-button 
            value="Notify doctor for precription extension">Notify doctor for precription extension</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions align="end" style="width: auto;right: 19px;">
  <button id="add_btn" [mat-dialog-close]="dis_txt" cdkFocusInitial mat-button>
    <img src="../../../assets/ui_icons/buttons/add_button.svg" class="addimgbtn_icd" />
  </button>
</div>