<div *ngIf="dischargesummary == 'min'">
  <div class="dig_table_overflow" style="height: 200px;">
    <table id="case_summary_tbl_min" *ngIf="Others_flag" style="border: none;">
    <tr *ngIf="name_flag">
      <th>Name</th>
      <td style="text-align: left;">{{f_name}}</td>
    </tr>
    <tr *ngIf="gen_flag">
      <th>Gender</th>
      <td style="text-align: left;">{{gen_txt}}</td>
    </tr>
    <tr *ngIf="age_flag">
      <th>Age</th>
      <td style="text-align: left;">{{age_txt}}</td>
    </tr>
    <tr *ngIf="occ_flag">
      <th>Occupation</th>
      <td style="text-align: left;">{{occ_txt}}</td>
    </tr>

    <tr *ngIf="stress_factor_flag">
      <th>Stress factors</th>
      <td style="text-align: left;">{{stress_factor}}</td>
    </tr>
  </table>
  </div>
</div>

<div *ngIf="dischargesummary == 'max'">
  <mat-tab-group [disableRipple]=true animationDuration="0ms">
    <mat-tab label="Case Summary" isActive="false">
      <mdb-card style="width: 99% !important;margin-left: 3px;">
        <mdb-card-body>
          <div class="Details">
            <div class="row">
              <div class="col-12 col-lg-12 col-md-12 col-sm-12 col-xl-12">
                <a class="print"><button printSectionId="print_case_summary" [useExistingCss]="true" ngxPrint class="borderdiv"
                    class="print_btn" mat-icon-button>
                    <img src="../../../assets/img/printer.svg" width="30px" height="30px">
                  </button></a>
              </div>
            </div>
            <div class="row">
              <p *ngIf="Others_flag" class="tervys_heading">General</p>
              <p *ngIf="Others_flag">Personal history</p>
              <div class="col-12 col-lg-12 col-md-12 col-sm-12 col-xl-12" *ngIf="Others_flag">
                <table id="case_summary_tbl">
                  <tr *ngIf="name_flag">
                    <th>Name</th>
                    <td style="text-align: left;">{{f_name}}</td>
                  </tr>
                  <tr *ngIf="gen_flag">
                    <th>Gender</th>
                    <td style="text-align: left;">{{gen_txt}}</td>
                  </tr>
                  <tr *ngIf="age_flag">
                    <th>Age</th>
                    <td style="text-align: left;">{{age_txt}}</td>
                  </tr>
                  <tr *ngIf="occ_flag">
                    <th>Occupation</th>
                    <td style="text-align: left;">{{occ_txt}}</td>
                  </tr>

                  <tr *ngIf="stress_factor_flag">
                    <th>Stress factors</th>
                    <td style="text-align: left;">{{stress_factor}}</td>
                  </tr>
                  <tr *ngIf="phys_act_flag">
                    <th>Physical activities</th>
                    <td style="text-align: left;">{{phys_act}}</td>
                  </tr>
                  <tr *ngIf="sga_score_flag">
                    <th>SGA score</th>
                    <td style="text-align: left;">{{sga_score}}</td>
                  </tr>
                  <tr *ngIf="height_flag">
                    <th>Height</th>
                    <td style="text-align: left;">{{height_txt}} &nbsp; {{height_meas}}</td>
                  </tr>
                  <tr *ngIf="weight_flag">
                    <th>Weight</th>
                    <td style="text-align: left;">{{weight_txt}} &nbsp; {{weight_meas}}</td>
                  </tr>
                  <tr *ngIf="bmi_flag">
                    <th>BMI</th>
                    <td style="text-align: left;">{{bmi_txt}}</td>
                  </tr>
                  <tr *ngIf="med_hist_flag">
                    <th>Medical history</th>
                    <td style="text-align: left;">{{med_hist}}</td>
                  </tr>
                </table>
              </div>

              <p *ngIf="habtiual_info_flag" class="tervys_heading">Habitual history</p>
              <div class="col-12 col-lg-12 col-md-12 col-sm-12 col-xl-12" *ngIf="habtiual_info_flag">
                <table id="case_summary_tbl" *ngIf="habtiual_info_flag">
                  <tr *ngIf="alc_freq_flag">
                    <th>Alcohol Frequency/day</th>
                    <td style="text-align: left;">{{alc_freq}}</td>
                  </tr>
                  <tr *ngIf="alc_qty_flag">
                    <th>Alcohol qty</th>
                    <td style="text-align: left;">{{alc_qty}}</td>
                  </tr>
                  <tr *ngIf="smk_freq_flag">
                    <th>Smoke frequency/day</th>
                    <td style="text-align: left;">{{smk_freq}}</td>
                  </tr>
                  <tr *ngIf="gutk_freq_flag">
                    <th>Gutka frequency/day</th>
                    <td style="text-align: left;">{{gutk_freq}}</td>
                  </tr>
                  <tr *ngIf="exe_dur_flag">
                    <th>Exercises duration</th>
                    <td style="text-align: left;">{{exe_dur}}</td>
                  </tr>
                </table>
              </div>

              <p *ngIf="Gynecology_flag" class="tervys_heading">Gynecology</p>
              <div class="col-12 col-lg-12 col-md-12 col-sm-12 col-xl-12" *ngIf="Gynecology_flag">
                <table id="case_summary_tbl" *ngIf="Gynecology_flag">
                  <tr>
                    <th>Visit purpose</th>
                    <td style="text-align: left;">{{Visit_purpose}}</td>
                  </tr>
                  <tr>
                    <th>Main concern</th>
                    <td style="text-align: left;">{{Main_concern}}</td>
                  </tr>
                  <tr>
                    <th>Disease</th>
                    <td style="text-align: left;">{{Disease_txt}}</td>
                  </tr>
                  <tr>
                    <th>Present illness</th>
                    <td style="text-align: left;">{{Present_illness}}</td>
                  </tr>
                  <tr>
                    <th>Menstrual periods</th>
                    <td style="text-align: left;">{{Menstrual_periods}}</td>
                  </tr>
                  <tr>
                    <th>Icd10</th>
                    <td style="text-align: left;">{{Icd_txt}}</td>
                  </tr>
                </table>
              </div>

              <p *ngIf="Diabetes_flag" class="tervys_heading">Diabetes</p>
              <div class="col-12 col-lg-12 col-md-12 col-sm-12 col-xl-12" *ngIf="Diabetes_flag">
                <table id="case_summary_tbl" *ngIf="Diabetes_flag">
                  <tr>
                    <th>Concerns</th>
                    <td style="text-align: left;">{{Case_concern}}</td>
                  </tr>
                  <tr>
                    <th>Dietary habits</th>
                    <td style="text-align: left;">{{Case_dietary}}</td>
                  </tr>
                  <tr>
                    <th>Disease type</th>
                    <td style="text-align: left;">{{Disease_type}}</td>
                  </tr>
                  <tr>
                    <th>Icd10</th>
                    <td style="text-align: left;">{{ICD_Code}}</td>
                  </tr>
                </table>
              </div>

              <p *ngIf="Nephrology_flag" class="tervys_heading">Nephrology</p>
              <div class="col-12 col-lg-12 col-md-12 col-sm-12 col-xl-12" *ngIf="Nephrology_flag">
                <table id="case_summary_tbl" *ngIf="Nephrology_flag">
                  <tr>
                    <th>Dietary habits</th>
                    <td style="text-align: left;">{{diet_habits_txt}}</td>
                  </tr>
                  <tr>
                    <th>Disease</th>
                    <td style="text-align: left;">{{neph_dis}}</td>
                  </tr>
                  <tr>
                    <th>ICD code</th>
                    <td style="text-align: left;">{{neph_icd}}</td>
                  </tr>
                  <tr>
                    <th>ICD description</th>
                    <td style="text-align: left;">{{icd_desr}}</td>
                  </tr>
                  <tr>
                    <th>Treatement</th>
                    <td style="text-align: left;">{{treat_txt}}</td>
                  </tr>
                  <tr>
                    <th>Dialysis type</th>
                    <td style="text-align: left;">{{dys_type}}</td>
                  </tr>

                  <tr>
                    <th>Dialysis frequency / type</th>
                    <td style="text-align: left;">{{dia_freq}}</td>
                  </tr>
                  <tr>
                    <th>Skin</th>
                    <td style="text-align: left;">{{skin_txt}}</td>
                  </tr>
                  <tr>
                    <th>Hearing</th>
                    <td style="text-align: left;">{{abdom_txt}}</td>
                  </tr>
                  <tr>
                    <th>Eye</th>
                    <td style="text-align: left;">{{ret_txt}}</td>
                  </tr>
                  <tr>
                    <th>Cardiovascular</th>
                    <td style="text-align: left;">{{card_txt}}</td>
                  </tr>
                  <tr>
                    <th>Respiratory / pulmonary</th>
                    <td style="text-align: left;">{{pum_txt}}</td>
                  </tr>
                  <tr>
                    <th>Causes of disease</th>
                    <td style="text-align: left;">{{dis_cause}}</td>
                  </tr>
                </table>
              </div>

              <p *ngIf="Pediatrics_flag" class="tervys_heading">Pediatrics</p>
              <div class="col-12 col-lg-12 col-md-12 col-sm-12 col-xl-12" *ngIf="Pediatrics_flag">
                <table id="case_summary_tbl" *ngIf="Pediatrics_flag">
                  <tr>
                    <th>Child name</th>
                    <td style="text-align: left;">{{child_name}}</td>
                  </tr>
                  <tr>
                    <th>Mother age</th>
                    <td style="text-align: left;">{{mother_age}}</td>
                  </tr>
                  <tr>
                    <th>No of pregnancy</th>
                    <td style="text-align: left;">{{noof_preg}}</td>
                  </tr>
                  <tr>
                    <th>Delivery type</th>
                    <td style="text-align: left;">{{delivery_type}}</td>
                  </tr>
                  <tr>
                    <th>Gestational age(weeks)</th>
                    <td style="text-align: left;">{{gestation_age}}</td>
                  </tr>
                  <tr>
                    <th>Complications</th>
                    <td style="text-align: left;">{{complication_txt}}</td>
                  </tr>
                  <tr>
                    <th>Living condition</th>
                    <td style="text-align: left;">{{living_condition}}</td>
                  </tr>
                  <tr>
                    <th>Feeding habits</th>
                    <td style="text-align: left;">{{feeding_habits}}</td>
                  </tr>
                  <tr>
                    <th>Allergies</th>
                    <td style="text-align: left;">{{allergies_txt}}</td>
                  </tr>
                  <tr>
                    <th>Diseases</th>
                    <td style="text-align: left;">{{diseases_txt}}</td>
                  </tr>
                  <tr>
                    <th>ICD</th>
                    <td style="text-align: left;">{{icd_txt}}</td>
                  </tr>
                </table>
              </div>

              <div [hidden]="gyna_list.length==0" class="col-12 col-lg-12 col-md-12 col-sm-12 col-xl-12">
                <table cellpadding="5" cellspacing="0" id="case_sum_tbl">
                  <tr>
                    <th>Surgeries</th>
                    <th style="text-align: center;">Date</th>
                  </tr>

                  <tr *ngFor="let surger of gyna_list">
                    <td>{{surger.Surgeries}}</td>
                    <td>{{surger.Surgeries_date}}</td>
                  </tr>
                </table>
              </div>

              <p *ngIf="foot_ass_flag" class="tervys_heading">Foot assessment</p>
              <div *ngIf="foot_ass_flag" class="col-12 col-lg-12 col-md-12 col-sm-12 col-xl-12">
                <table id="case_summary_tbl" *ngIf="Diabetes_flag">
                  <tr *ngIf="case_right_flag">
                    <th>Right</th>
                    <td style="text-align: left;">{{case_right}}</td>
                  </tr>
                  <tr *ngIf="Case_left_flag">
                    <th>Left</th>
                    <td style="text-align: left;">{{Case_left}}</td>
                  </tr>
                </table>

              </div>

              <p *ngIf="Complications_flag" class="tervys_heading">Complications</p>
              <div *ngIf="Complications_flag" class="col-12 col-lg-12 col-md-12 col-sm-12 col-xl-12">
                <table id="case_summary_tbl" *ngIf="Complications_flag">
                  <tr *ngIf="Case_cardio_flag">
                    <th>Cardio</th>
                    <td  style="text-align: left;">{{Case_cardio}}</td>
                  </tr>
                  <tr *ngIf="Case_nephro_flag">
                    <th>Nephro</th>
                    <td style="text-align: left;">{{Case_nephro}}</td>
                  </tr>
                  <tr *ngIf="Case_retino_flag">
                    <th>Retino</th>
                    <td style="text-align: left;">{{Case_retino}}</td>
                  </tr>
                  <tr *ngIf="Case_mobility_flag">
                    <th>Mobility</th>
                    <td style="text-align: left;">{{Case_mobility}}</td>
                  </tr>
                </table>
              </div>

              <p *ngIf="diab_medshow" class="tervys_heading">Medical Prescription</p>
              <div *ngIf="diab_medshow" class="col-12 col-lg-12 col-md-12 col-sm-12 col-xl-12">
                <table id="tbl">
                  <thead>
                    <tr>
                      <th>Medicine type</th>
                      <th>Medicine name</th>
                      <th>Days</th>
                      <th>Intake</th>
                      <th>Duration</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let diabmed of medicdiabetictable">
                      <td style="text-align:left;">{{diabmed.drug_type_name}}</td>
                      <td style="text-align:left;">{{diabmed.drug_name}}</td>
                      <td>{{diabmed.days}}</td>
                      <td>{{diabmed.quan_tx}}</td>
                      <td>{{diabmed.with_time+" "+diabmed.drug_intake}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <p [hidden]="diag_test_flag" class="tervys_heading">Diagnosis prescription</p>
              <div [hidden]="diab_diagshow" class="col-12 col-lg-12 col-md-12 col-sm-12 col-xl-12">
                <table id="case_summary_tbl" [hidden]="diag_test_flag">
                  <tr *ngFor="let diagpres of diagdiabtable1">
                    <td style="text-align:left;">{{diagpres.testself}}</td>
                    <td style="text-align:left;">{{diagpres.test_name}}</td>
                  </tr>
                </table>
              </div>

              <!-- <div [ngStyle]="Diab_Graph_div"  *ngIf="Diabetes_flag">
          <mat-grid-list  cols="2" rowHeight="350px">
            <mat-grid-tile  class="appint_graph1">
              <div id="Blood_Unavailable">
                  <p>No Data Found</p>
              </div>
              <div id="Blood_Glucose_Chart">
                <div id="blood_highchart"></div>
              </div>
             <p class="text-center">Blood Glucose</p>
          </mat-grid-tile>
          <mat-grid-tile  class="appint_graph1">
            <div id="Urine_Unavailable">
                <p>No Data Found</p>
            </div>
            <div id="Urine_Charts">
              <canvas #urineCanvas width="600" height="400" class="chart_div"></canvas>
            </div>
            <p class="text-center cahrat_para">Urine Glucose</p>
    </mat-grid-tile>
          </mat-grid-list>
        </div> -->
              <p *ngIf="medical_nephtable" class="tervys_heading">Medical prescriptions</p>
              <div *ngIf="medical_nephtable" class="col-12 col-lg-12 col-md-12 col-sm-12 col-xl-12">
                <table cellpadding="5" cellspacing="0" id="#tbl">
                  <tr>
                    <th>Medicine type</th>
                    <th>Medicine name</th>
                    <th>Days</th>
                    <th>Intake</th>
                    <th>Duration</th>
                  </tr>
                  <tr *ngFor="let nepgmed of medicalnephtable">
                    <td style="text-align:left;">{{nepgmed.drug_type_name}}</td>
                    <td style="text-align:left;">{{nepgmed.drug_name}}</td>
                    <td>{{nepgmed.days}}</td>
                    <td>{{nepgmed.quan_tx}}</td>
                    <td style="text-align:left;">{{nepgmed.with_time+" "+nepgmed.drug_intake}}</td>
                  </tr>
                </table>
              </div>
              <!-- <div [hidden]="Diab_Graph_div_flag">
          <mat-grid-list cols="2" rowHeight="350px">
            <mat-grid-tile  class="appint_graph">
                <div id="eGFR_Chart_Unavail" class="graph_dataunavail">
                    <p class="Paraclass">
                        <img src="assets/imgs/Warning.png" class="Imageclass" />No data found
                    </p>
                </div>
                <div id="eGFR_Chart" class="chart_div"></div>
                <p class="text-center">eGFR</p>
            </mat-grid-tile>
            <mat-grid-tile  class="appint_graph">
              <div id="Serum_Chart_Unavail" class="graph_dataunavail">
                  <p class="Paraclass">
                      <img src="assets/imgs/Warning.png" class="Imageclass" />No data found
                  </p>
              </div>
              <div id="Serum_Chart" class="chart_div"></div>
              <p class="text-center cahrat_para">Serum - Creatinine</p>
            </mat-grid-tile>
            <mat-grid-tile  class="appint_graph">
              <div id="Albuminuria_Unavail" class="graph_dataunavail">
                <p class="Paraclass">
                    <img src="assets/imgs/Warning.png" class="Imageclass" />No data found
                </p>
                </div>
                <div id="Albuminuria_Chart" class="chart_div"></div>
                <p class="text-center">Albuminuria (24 hrs)</p>
            </mat-grid-tile>
            <mat-grid-tile  class="appint_graph">
              <div id="Proteinuria_Unavail" class="graph_dataunavail">
                <p class="Paraclass"><img src="assets/imgs/Warning.png" class="Imageclass" />No data found</p>
              </div>
              <div id="Proteinuria_Chart" class="chart_div"></div>
              <p class="text-center cahrat_para">Proteinuria (24 hrs)</p>
            </mat-grid-tile>
          </mat-grid-list>
        </div> -->

              <div *ngIf="pedmed_flag" class="col-12 col-lg-12 col-md-12 col-sm-12 col-xl-12">
                <table id="tbl">
                  <thead>
                    <tr>
                      <th>Medicine type</th>
                      <th>Medicine name</th>
                      <th>Days</th>
                      <th>Intake</th>
                      <th>Duration</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let diabmed of pedmedicdiabetictable">
                      <td style="text-align:left;">{{diabmed.drug_type_name}}</td>
                      <td style="text-align:left;">{{diabmed.drug_name}}</td>
                      <td>{{diabmed.days}}</td>
                      <td>{{diabmed.quan_tx}}</td>
                      <td>{{diabmed.with_time+" "+diabmed.drug_intake}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </mdb-card-body>
      </mdb-card>
    </mat-tab>
    <mat-tab label="Discharge Summary">
      <mdb-card style="width: 99% !important;margin-left: 3px;">
        <mdb-card-header class="bg-white" style="padding:7px 20px;">
        </mdb-card-header>
        <mdb-card-body>
          <div *ngIf="!ds_flag">
              <p class="nodata">No data found</p>
          </div>
          <div class="Details" *ngIf="ds_flag">
            <div class="row">
              <div class="Details">
                <div class="row">
                  <div class="col-6" style="float: right;">
                    <div class="col-6">
                      <mat-label class="matlabel"><b class="inpat_ds_b">{{hosp_name}}</b></mat-label>
                    </div>
                    <div class="col-6">
                      <mat-label class="matlabel">{{hosp_addr}}</mat-label>
                    </div>
                    <div class="col-6">
                      <mat-label class="matlabel">{{hosp_csz}}</mat-label>
                    </div>
                    <div class="col-6">
                      <mat-label class="matlabel">{{hosp_tel}}</mat-label>
                    </div>
                  </div>
                </div>
                <div class="row" style="border: 1px solid #c5d7e6">
                  <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12">
                    <div class="row">
                      <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6">
                        <mat-label class="matlabel"><b class="label_bold">Patient name :</b></mat-label>
                      </div>
                      <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6">
                        <mat-label class="matlabel">{{patient_name}}</mat-label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6">
                        <mat-label class="matlabel"><b class="label_bold">Patient identifie :</b></mat-label>
                      </div>
                      <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6">
                        <mat-label class="matlabel">{{patient_identifier}}</mat-label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6">
                        <mat-label class="matlabel"><b class="label_bold">Patient gender :</b></mat-label>
                      </div>
                      <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6">
                        <mat-label class="matlabel">{{gender}}</mat-label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6">
                        <mat-label class="matlabel"><b class="label_bold">Patient age :</b></mat-label>
                      </div>
                      <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6">
                        <mat-label class="matlabel">{{age}}</mat-label>
                      </div>
                    </div>
                  </div>
    
                  <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12">
                    <div class="row">
                      <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6">
                        <mat-label class="matlabel"><b class="label_bold">Admission date :</b></mat-label>
                      </div>
                      <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6">
                        <mat-label class="matlabel">{{admis_date}}</mat-label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6">
                        <mat-label class="matlabel"><b class="label_bold">Discharge date :</b></mat-label>
                      </div>
                      <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6">
                        <mat-label class="matlabel">{{dis_date}}</mat-label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6">
                        <mat-label class="matlabel"><b class="label_bold">Doctor name :</b></mat-label>
                      </div>
                      <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6">
                        <mat-label class="matlabel">{{doctor_name}}</mat-label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                    <div class="row">
                      <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6">
                        <mat-label class="matlabel"><b class="label_bold">Symptoms :</b> </mat-label>
                      </div>
                      <div class="col-sm-6 col-lg-9 col-xl-9 col-md-6">
                        {{symptoms}}
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6">
                        <mat-label class="matlabel"><b class="label_bold">Complaints :</b> </mat-label>
                      </div>
                      <div class="col-sm-6 col-lg-9 col-xl-9 col-md-6">
                        {{complaints}}
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6">
                        <mat-label class="matlabel"><b class="label_bold">Previous medical history :</b> </mat-label>
                      </div>
                      <div class="col-sm-6 col-lg-9 col-xl-9 col-md-6">
                        {{medical_hist}}
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6">
                        <mat-label class="matlabel"><b class="label_bold">Physical examination :</b> </mat-label>
                      </div>
                      <div class="col-sm-6 col-lg-9 col-xl-9 col-md-6">
                        {{physical_exam}}
                      </div>
                    </div>
                  </div>
                </div>
    
                <div class="row">
                  <div class="col-12">
                    <div class="row">
                      <div class="col-12">
                        <mat-label class="matlabel"><b class="label_bold">Discharge diagnosis</b></mat-label>
                      </div>
                      <div class="col-12">
                        <textarea class="ipcss" maxlength="500" [(ngModel)]="dis_diag"></textarea>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <mat-label class="matlabel"><b class="label_bold">Summary course in hospital</b></mat-label>
                      </div>
                      <div class="col-12">
                        <textarea class="ipcss" maxlength="500" [(ngModel)]="hosp_course"></textarea>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <mat-label class="matlabel"><b class="label_bold">Follow up summary</b></mat-label>
                      </div>
                      <div class="col-12">
                        <textarea class="ipcss" maxlength="500" [(ngModel)]="hospital_followup"></textarea>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <mat-label class="matlabel">Next visit</mat-label>
                      </div>
                      <div class="col-3 col-md-3 col-xl-3 col-lg-3">
                        <input type="date" class="ipcss_date" id="appt_date" (change)="NextDate(next_data)"
                          [(ngModel)]="next_data" max="{{currentDate}}" #matInput>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                    <div class="col-12">
                      <div style="float: right;">
                        <a [hidden]="confirm_btn" (click)="saveDischargeSummary()"><img
                          src="../../../assets/ui_icons/buttons/save_button.svg" class="saveimgbtn_inpatinfo" /></a>
                      <a [hidden]="!confirm_btn"><button printSectionId="print_summary" [useExistingCss]="true" ngxPrint mat-button>
                          <img src="../../../assets/img/printer.svg" width="17px" height="17px" />
                        </button></a>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </mdb-card-body>
      </mdb-card>
      <!--  -->

    </mat-tab>
  </mat-tab-group>
</div>

<!-- print section -->
<div id="print_summary" hidden>
  <table>
    <tr>
      <td style="width: 250px;"></td>
      <td>
        <p style="font-size: 24px;">{{hosp_name}}</p>
        <p>{{hosp_addr}} {{hosp_csz}}</p>
        <p>Tel: {{hosp_tel}}</p>
      </td>
    </tr>
  </table>
  <table style="margin-top: 50px;margin-left: 50px;">
    <tr>
      <td>Patient name : {{patient_name}}</td>
      <td style="width: 200px;"></td>
      <td>Admission date: {{admis_date}}</td>
    </tr>
    <tr>
      <td>Patient Idetifier: {{patient_identifier}}</td>
      <td style="width: 200px;"></td>
      <td>Discharge date: {{dis_date}}</td>
    </tr>
    <tr>
      <td>Gender: {{gender}}</td>
      <td style="width: 200px;"></td>
      <td>Doctor name: {{doctor_name}}</td>
    </tr>
    <tr>
      <td>Age: {{age}}</td>
    </tr>
  </table>
  <div class="details1" style="margin-left: 50px;">
    <p>Discharge diagnosis </p>
    <mat-label class="matlabel">{{dis_diag}}</mat-label>

    <p>Summary course in hospital </p>
    <mat-label class="matlabel">{{hosp_course}}</mat-label>

    <p>Follow up summary: </p>
    <mat-label class="matlabel">{{hospital_followup}}</mat-label>

    <p>Next visit: {{next_data}}</p>
    <mat-label class="matlabel" *ngIf="druglist.length !=0">Medicines</mat-label>

    <table *ngIf="druglist.length !=0">
      <tr style="background: #277196;color:#FFFFFF">
        <th style="width: 200px;">Medicine</th>
        <th style="width: 100px;">Dosage</th>
        <th style="width: 50px;">Days</th>
      </tr>
      <tr *ngFor="let product of druglist">
        <td>{{product.medicine_name}}</td>
        <td style="text-align: center">{{product.medicine_dosage}}</td>
        <td style="text-align: center">{{product.medicine_intakedays}} - {{product.medicine_food}}</td>
      </tr>
    </table>
  </div>
</div>

<!-- print sec case summ -->
<div id="print_case_summary" hidden>
  <div class="Details">
    <p *ngIf="Others_flag">General</p>
    <p *ngIf="Others_flag">Personal history</p>
    <table id="case_summary_tbl" *ngIf="Others_flag">
      <tr *ngIf="name_flag">
        <th>Name</th>
        <td>{{f_name}}</td>
      </tr>
      <tr *ngIf="gen_flag">
        <th>Gender</th>
        <td>{{gen_txt}}</td>
      </tr>
      <tr *ngIf="age_flag">
        <th>Age</th>
        <td>{{age_txt}}</td>
      </tr>
      <tr *ngIf="occ_flag">
        <th>Occupation</th>
        <td>{{occ_txt}}</td>
      </tr>

      <tr *ngIf="stress_factor_flag">
        <th>Stress factors</th>
        <td>{{stress_factor}}</td>
      </tr>
      <tr *ngIf="phys_act_flag">
        <th>Physical activities</th>
        <td>{{phys_act}}</td>
      </tr>
      <tr *ngIf="sga_score_flag">
        <th>SGA score</th>
        <td>{{sga_score}}</td>
      </tr>
      <tr *ngIf="height_flag">
        <th>Height</th>
        <td>{{height_txt}} &nbsp; {{height_meas}}</td>
      </tr>
      <tr *ngIf="weight_flag">
        <th>Weight</th>
        <td>{{weight_txt}} &nbsp; {{weight_meas}}</td>
      </tr>
      <tr *ngIf="bmi_flag">
        <th>BMI</th>
        <td>{{bmi_txt}}</td>
      </tr>
      <tr *ngIf="med_hist_flag">
        <th>Medical history</th>
        <td>{{med_hist}}</td>
      </tr>
    </table>
    <p *ngIf="habtiual_info_flag">Habitual history</p>
    <table id="case_summary_tbl" *ngIf="habtiual_info_flag">
      <tr *ngIf="alc_freq_flag">
        <th>Alcohol frequency/day</th>
        <td>{{alc_freq}}</td>
      </tr>
      <tr *ngIf="alc_qty_flag">
        <th>Alcohol qty</th>
        <td>{{alc_qty}}</td>
      </tr>
      <tr *ngIf="smk_freq_flag">
        <th>Smoke frequency/day</th>
        <td>{{smk_freq}}</td>
      </tr>
      <tr *ngIf="gutk_freq_flag">
        <th>Gutka frequency/day</th>
        <td>{{gutk_freq}}</td>
      </tr>
      <tr *ngIf="exe_dur_flag">
        <th>Exercises duration</th>
        <td>{{exe_dur}}</td>
      </tr>
    </table>

    <p *ngIf="Gynecology_flag">Gynecology</p>
    <table id="case_summary_tbl" *ngIf="Gynecology_flag">
      <tr>
        <th>Visit purpose</th>
        <td>{{Visit_purpose}}</td>
      </tr>
      <tr>
        <th>Main concern</th>
        <td>{{Main_concern}}</td>
      </tr>
      <tr>
        <th>Disease</th>
        <td>{{Disease_txt}}</td>
      </tr>
      <tr>
        <th>Present illness</th>
        <td>{{Present_illness}}</td>
      </tr>
      <tr>
        <th>Menstrual periods</th>
        <td>{{Menstrual_periods}}</td>
      </tr>
      <tr>
        <th>Icd10</th>
        <td>{{Icd_txt}}</td>
      </tr>
    </table>
    <p *ngIf="Diabetes_flag">Diabetes</p>
    <table id="case_summary_tbl" *ngIf="Diabetes_flag">
      <tr>
        <th>Concerns</th>
        <td>{{Case_concern}}</td>
      </tr>
      <tr>
        <th>Dietary habits</th>
        <td>{{Case_dietary}}</td>
      </tr>
      <tr>
        <th>Disease type</th>
        <td>{{Disease_type}}</td>
      </tr>
      <tr>
        <th>Icd10</th>
        <td>{{ICD_Code}}</td>
      </tr>
    </table>
    <p *ngIf="Nephrology_flag">Nephrology</p>
    <table id="case_summary_tbl" *ngIf="Nephrology_flag">
      <tr>
        <th>Dietary habits</th>
        <td>{{diet_habits_txt}}</td>
      </tr>
      <tr>
        <th>Disease</th>
        <td>{{neph_dis}}</td>
      </tr>
      <tr>
        <th>ICD code</th>
        <td>{{neph_icd}}</td>
      </tr>
      <tr>
        <th>ICD description</th>
        <td>{{icd_desr}}</td>
      </tr>
      <tr>
        <th>Treatement</th>
        <td>{{treat_txt}}</td>
      </tr>
      <tr>
        <th>Dialysis type</th>
        <td>{{dys_type}}</td>
      </tr>

      <tr>
        <th>Dialysis frequency / type</th>
        <td>{{dia_freq}}</td>
      </tr>
      <tr>
        <th>Skin</th>
        <td>{{skin_txt}}</td>
      </tr>
      <tr>
        <th>Hearing</th>
        <td>{{abdom_txt}}</td>
      </tr>
      <tr>
        <th>Eye</th>
        <td>{{ret_txt}}</td>
      </tr>
      <tr>
        <th>Cardiovascular</th>
        <td>{{card_txt}}</td>
      </tr>
      <tr>
        <th>Respiratory / Pulmonary</th>
        <td>{{pum_txt}}</td>
      </tr>
      <tr>
        <th>Causes of disease</th>
        <td>{{dis_cause}}</td>
      </tr>
    </table>
    <p *ngIf="Pediatrics_flag">Pediatrics</p>
    <table id="case_summary_tbl" *ngIf="Pediatrics_flag">
      <tr>
        <th>Child name</th>
        <td>{{child_name}}</td>
      </tr>
      <tr>
        <th>Mother age</th>
        <td>{{mother_age}}</td>
      </tr>
      <tr>
        <th>No of pregnancy</th>
        <td>{{noof_preg}}</td>
      </tr>
      <tr>
        <th>Delivery type</th>
        <td>{{delivery_type}}</td>
      </tr>
      <tr>
        <th>Gestational age(weeks)</th>
        <td>{{gestation_age}}</td>
      </tr>
      <tr>
        <th>Complications</th>
        <td>{{complication_txt}}</td>
      </tr>
      <tr>
        <th>Living condition</th>
        <td>{{living_condition}}</td>
      </tr>
      <tr>
        <th>Feeding habits</th>
        <td>{{feeding_habits}}</td>
      </tr>
      <tr>
        <th>Allergies</th>
        <td>{{allergies_txt}}</td>
      </tr>
      <tr>
        <th>Diseases</th>
        <td>{{diseases_txt}}</td>
      </tr>
      <tr>
        <th>ICD</th>
        <td>{{icd_txt}}</td>
      </tr>
    </table>

  </div><br><br>
  <div [hidden]="gyna_list.length==0">
    <table cellpadding="5" cellspacing="0" id="case_sum_tbl">
      <tr>
        <th>Surgeries</th>
        <th style="text-align: center;">Date</th>
      </tr>

      <tr *ngFor="let surger of gyna_list">
        <td>{{surger.Surgeries}}</td>
        <td>{{surger.Surgeries_date}}</td>
      </tr>
    </table>
  </div>
  <div *ngIf="foot_ass_flag">
    <p>Foot assessment</p>
    <table id="case_summary_tbl" *ngIf="Diabetes_flag">
      <tr *ngIf="case_right_flag">
        <th>Right</th>
        <td>{{case_right}}</td>
      </tr>
      <tr *ngIf="Case_left_flag">
        <th>Left</th>
        <td>{{Case_left}}</td>
      </tr>
    </table>
  </div>
  <div *ngIf="Complications_flag">
    <p>Complications</p>
    <table id="case_summary_tbl" *ngIf="Complications_flag">
      <tr *ngIf="Case_cardio_flag">
        <th>Cardio</th>
        <td>{{Case_cardio}}</td>
      </tr>
      <tr *ngIf="Case_nephro_flag">
        <th>Nephro</th>
        <td>{{Case_nephro}}</td>
      </tr>
      <tr *ngIf="Case_retino_flag">
        <th>Retino</th>
        <td>{{Case_retino}}</td>
      </tr>
      <tr *ngIf="Case_mobility_flag">
        <th>Mobility</th>
        <td>{{Case_mobility}}</td>
      </tr>
    </table>
  </div>
  <div *ngIf="diab_medshow">
    <p>Medical prescription</p>
    <table id="tbl">
      <thead>
        <tr>
          <th>Medicine type</th>
          <th>Medicine name</th>
          <th>Days</th>
          <th>Intake</th>
          <th>Duration</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let diabmed of medicdiabetictable">
          <td>{{diabmed.drug_type_name}}</td>
          <td>{{diabmed.drug_name}}</td>
          <td>{{diabmed.days}}</td>
          <td>{{diabmed.quan_tx}}</td>
          <td>{{diabmed.with_time+" "+diabmed.drug_intake}}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div [hidden]="diab_diagshow">
    <p [hidden]="diag_test_flag">Diagnosis prescription</p>
    <table id="case_summary_tbl" [hidden]="diag_test_flag">
      <tr *ngFor="let diagpres of diagdiabtable1">
        <td>{{diagpres.testself}}</td>
        <td>{{diagpres.test_name}}</td>
      </tr>
    </table>
  </div>
  <!-- <div [ngStyle]="Diab_Graph_div"  *ngIf="Diabetes_flag">
    <mat-grid-list  cols="2" rowHeight="350px">
      <mat-grid-tile  class="appint_graph1">
        <div id="Blood_Unavailable">
            <p>No Data Found</p>
        </div>
        <div id="Blood_Glucose_Chart">
          <div id="blood_highchart"></div>
        </div>
       <p class="text-center">Blood Glucose</p>
    </mat-grid-tile>
    <mat-grid-tile  class="appint_graph1">
      <div id="Urine_Unavailable">
          <p>No Data Found</p>
      </div>
      <div id="Urine_Charts">
        <canvas #urineCanvas width="600" height="400" class="chart_div"></canvas>
      </div>
      <p class="text-center cahrat_para">Urine Glucose</p>
  </mat-grid-tile>
    </mat-grid-list>
  </div> -->
  <div *ngIf="medical_nephtable">
    <p>Medical prescriptions</p>
    <table cellpadding="5" cellspacing="0" id="#tbl">
      <tr>
        <th>Medicine type</th>
        <th>Medicine name</th>
        <th>Days</th>
        <th>Intake</th>
        <th>Duration</th>
      </tr>
      <tr *ngFor="let nepgmed of medicalnephtable">
        <td>{{nepgmed.drug_type_name}}</td>
        <td>{{nepgmed.drug_name}}</td>
        <td>{{nepgmed.days}}</td>
        <td>{{nepgmed.quan_tx}}</td>
        <td>{{nepgmed.with_time+" "+nepgmed.drug_intake}}</td>
      </tr>
    </table>
  </div>
  <!-- <div [hidden]="Diab_Graph_div_flag">
    <mat-grid-list cols="2" rowHeight="350px">
      <mat-grid-tile  class="appint_graph">
          <div id="eGFR_Chart_Unavail" class="graph_dataunavail">
              <p class="Paraclass">
                  <img src="assets/imgs/Warning.png" class="Imageclass" />No data found
              </p>
          </div>
          <div id="eGFR_Chart" class="chart_div"></div>
          <p class="text-center">eGFR</p>
      </mat-grid-tile>
      <mat-grid-tile  class="appint_graph">
        <div id="Serum_Chart_Unavail" class="graph_dataunavail">
            <p class="Paraclass">
                <img src="assets/imgs/Warning.png" class="Imageclass" />No data found
            </p>
        </div>
        <div id="Serum_Chart" class="chart_div"></div>
        <p class="text-center cahrat_para">Serum - Creatinine</p>
      </mat-grid-tile>
      <mat-grid-tile  class="appint_graph">
        <div id="Albuminuria_Unavail" class="graph_dataunavail">
          <p class="Paraclass">
              <img src="assets/imgs/Warning.png" class="Imageclass" />No data found
          </p>
          </div>
          <div id="Albuminuria_Chart" class="chart_div"></div>
          <p class="text-center">Albuminuria (24 hrs)</p>
      </mat-grid-tile>
      <mat-grid-tile  class="appint_graph">
        <div id="Proteinuria_Unavail" class="graph_dataunavail">
          <p class="Paraclass"><img src="assets/imgs/Warning.png" class="Imageclass" />No data found</p>
        </div>
        <div id="Proteinuria_Chart" class="chart_div"></div>
        <p class="text-center cahrat_para">Proteinuria (24 hrs)</p>
      </mat-grid-tile>
    </mat-grid-list>
  </div> -->
  <div *ngIf="pedmed_flag">
    <table id="tbl">
      <thead>
        <tr>
          <th>Medicine type</th>
          <th>Medicine name</th>
          <th>Days</th>
          <th>Intake</th>
          <th>Duration</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let diabmed of pedmedicdiabetictable">
          <td>{{diabmed.drug_type_name}}</td>
          <td>{{diabmed.drug_name}}</td>
          <td>{{diabmed.days}}</td>
          <td>{{diabmed.quan_tx}}</td>
          <td>{{diabmed.with_time+" "+diabmed.drug_intake}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>