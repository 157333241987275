import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { Helper_Class } from '../../helper_class';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from 'src/app/providers/common-data.service';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Date_Formate, Time_Formate, ConvertTimeformat, Nurse_Day_Return } from '../../../assets/js/common';
import { Doc_Helper } from '../Doc_Helper';
import { ipaddress } from '../../ipaddress' ;import { Master_Helper } from '../Master_Helper';
@Component({
  selector: 'app-doc-sales-report',
  templateUrl: './doc-sales-report.component.html',
  styleUrls: ['./doc-sales-report.component.scss']
})
export class DocSalesReportComponent implements OnInit {
  public hospitaID: string;
  public reportType: string;
  public fromDate;
  public toDate;
  public salesData = [];
  public nodata: boolean;
  public hasdata: boolean;
  tmr = new Date();
  public userinfo;
  @Input() max: any;
  public currentDate;
  public currentYear;
  public currentTime;
  public currDate = null;
  constructor(public gservice: CommonDataService, public http: Http, public routes: ActivatedRoute, public router: Router, public toastr: ToastrService) {
    this.nodata = false;
    this.hasdata = true;
  }
  ngOnInit(): void {
    this.userinfo = Helper_Class.getInfo();
    this.hospitaID = Doc_Helper.getHospitalClinicalId();
    this.getCurrentDate();
    this.reportType = "OTC";
  }
  getCurrentDate() {
    var obj = Master_Helper.getMasterCurrentDate();
    this.currDate = obj.current_date;
    this.currentDate = Date_Formate(this.currDate);
    this.currentTime = obj.current_time;
    this.currentDate = obj.current_date;
    this.fromDate = this.currDate;
    this.toDate = this.currDate;
    this.getSalesData();
  }
  dateSelect(data, value) {
    if (data != null) {
      if (value == "1")
        this.fromDate = data;
      else
        this.toDate = data;
    }
  }
  getSalesData() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    var fdate = Date_Formate(this.fromDate).split('-');
    var tdate = Date_Formate(this.toDate).split('-');
    this.http.post(ipaddress.getIp + "pharmacontrol/gsrep/", {
      hptl_clinic_id: this.hospitaID,
      type: this.reportType,
      from_date: fdate[2] + "-" + fdate[1] + "-" + fdate[0],
      to_date: tdate[2] + "-" + tdate[1] + "-" + tdate[0],
    },
      { headers: headers }).subscribe(
        data => {
          var dataval = data.json();
          if (dataval != null) {
            this.nodata = true;
            this.hasdata = false;
            this.salesData = [];
            if (dataval.orders != undefined) {
              for (var i = 0; i < dataval.orders.length; i++) {
                var name;
                if (this.reportType == "OTC") {
                  name = dataval.orders[i].deliver_to;
                } else {
                  if (dataval.orders[i].middle_name != undefined) {
                    name = encrypt_decript.Decript(dataval.orders[i].first_name) + " " + encrypt_decript.Decript(dataval.orders[i].middle_name) + " " + encrypt_decript.Decript(dataval.orders[i].last_name);
                  } else {
                    name = encrypt_decript.Decript(dataval.orders[i].first_name) + " " + encrypt_decript.Decript(dataval.orders[i].last_name);
                  }
                }
                this.salesData.push({
                  order_id: dataval.orders[i].order_id,
                  name: name,
                  order_type: dataval.orders[i].purchase_type,
                  order_date: Date_Formate(dataval.orders[i].order_date),
                  amount: dataval.orders[i].amount,
                });
              }
            } else {
              this.salesData = [];
              this.nodata = false;
              this.hasdata = true;
            }
          }
        },
        error => { });
  }
}
