<div class="row">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white ">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">{{title}}'s profile</h5>
          </div>
          <div class="headerButtons">
            <img class="saveimgbtn_inpatinfo" (click)="back()"
              src="../../../assets/ui_icons/buttons/Back_button.svg" />
            <img *ngIf="sessionList.length !=0" class="saveimgbtn_inpatinfo" (click)="next()"
              src="../../../assets/ui_icons/buttons/Next_button.svg" />
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="container">
          <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div class="row">
                <div class="col-3 my-auto">
                  <div style="width: fit-content; margin: auto;">
                    <img src="{{profileImg}}" class="physio_profile_img" width="110px" height="110px" />
                  </div>
                </div>
                <div class="col-9 col-sm-9 col-md-9 col-xl-9 col-lg-9 my-auto">
                  <div class="row">
                    <div class="col-6">
                      <mat-card id="cardcontent_name" class="clscardcontnet_name">
                        <mat-card-title id="cardheading">
                          <img src="../../../assets/img/doctor.png" style="margin: 11% 5px;" height="30px"
                            width="30px" />
                        </mat-card-title>
                        <mat-card-content>
                          <div class="row" style="padding: 0 0 0 9px;">
                            <div class="col-12">
                              <mat-label class="matlabel"><b>Name</b></mat-label>
                            </div>
                            <div class="col-12">zz
                              <mat-label class="matlabel">{{name}}</mat-label>
                            </div>
                          </div>
                        </mat-card-content>
                      </mat-card>
                    </div>
                    <div class="col-6">
                      <mat-card id="cardcontent_name" [hidden]="!addressFlag" class="clscardcontnet_name">
                        <mat-card-title id="cardheading">
                          <img src="../../../assets/img/arrow.png" style="margin: 41% 5px;" height="30px"
                            width="30px" />
                        </mat-card-title>
                        <mat-card-content>
                          <div class="row" style="padding: 0 0 0 9px;">
                            <div class="col-12">
                              <mat-label class="matlabel"><b>Address</b></mat-label>
                            </div>
                            <div class="col-12">
                              <mat-label class="matlabel">{{hospitalName}}</mat-label>
                            </div>
                            <div class="col-12">
                              <mat-label class="matlabel">{{hospitalLocation}}</mat-label>
                            </div>
                          </div>
                        </mat-card-content>
                      </mat-card>
                    </div>
                    <div class="col-6">
                      <mat-card id="cardcontent_name">
                        <mat-card-title id="cardheading">
                          <img src="../../../assets/img/briefcase.png" style="margin: 11% 5px;" height="30px"
                            width="30px" />
                        </mat-card-title>
                        <mat-card-content>
                          <div class="row" style="padding: 0 0 0 9px;">
                            <div class="col-12">
                              <mat-label class="matlabel"><b>Work experience</b></mat-label>
                            </div>
                            <div class="col-12">
                              <mat-label class="matlabel">{{workExperience}}</mat-label>
                            </div>
                          </div>
                        </mat-card-content>
                      </mat-card>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <div class="row" style="margin: 10px;">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div class="table-responsive dig_table_overflow">
                        <table id="card_tbl " class="table table-hover table-dynamic"
                          *ngIf="doctorDetailData.length != 0">
                          <thead>
                            <tr>
                              <th><b>Hospital name</b></th>
                              <th><b>Location</b></th>
                              <th><b>Day</b></th>
                              <th><b>Session</b></th>
                              <th><b>Starts from</b></th>
                              <th><b>Ends at</b></th>
                              <th><b>Fee</b></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let doctor of doctorDetailData">
                              <td data-th="Hospital Name">{{doctor.hospital}}</td>
                              <td data-th="Location">{{doctor.location}}</td>
                              <td data-th="Day">{{doctor.day}}</td>
                              <td data-th="Session">{{doctor.session}}</td>
                              <td data-th="Starts from">{{doctor.available_from}}</td>
                              <td data-th="Ends at">{{doctor.available_to}}</td>
                              <td data-th="Fee">{{doctor.fees}}</td>
                            </tr>
                          </tbody>
                        </table>
                        <table id="card_tbl dig_table_overflow" class="table table-hover table-dynamic"
                          *ngIf="homecareData.length != 0">
                          <thead>
                            <tr>
                              <th><b>Session</b></th>
                              <th><b>Time interval</b></th>
                              <th><b>Starts from</b></th>
                              <th><b>Ends at</b></th>
                              <th><b>Fee</b></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let doctor of homecareData">
                              <td data-th="Session">{{doctor.Session}}</td>
                              <td data-th="Day">{{doctor.Time_Inter}}</td>
                              <td data-th="Starts from">{{doctor.Avail_from}}</td>
                              <td data-th="Ends at">{{doctor.Avail_to}}</td>
                              <td data-th="Fee">{{doctor.Fees}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <div class="row" style="margin: 10px;">
                    <div class="col-6">
                      <div class="row">
                        <div class="col-10">
                          <div class="row">
                            <div *ngIf="homecare == '0'" class="col-6">
                              <mat-label *ngIf="homecare == '0'" class="matlabel1">Hospital name<br>

                                <mat-select class="ipcss widthappt" [(ngModel)]="hospitalId" disableOptionCentering (ngModelChange)="changeHospital(hospitalList)">
                                  <mat-option *ngFor="let hospit of hospitalList" value="{{hospit.hospital_id}}">{{hospit.hospname}}</mat-option>
                                </mat-select>
                              </mat-label>
                            </div>
                            <div *ngIf="homecare == '0'" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                              <mat-label *ngIf="homecare == '0'" class="matlabel">Date<br>
                                <input type="date" class="ipcss_date" min="{{presentdate}}"
                                  [(ngModel)]="appointmentDate"
                                  (change)="selectAppointmentDate(appointmentDate,'Appointment_Date')" matInput>
                              </mat-label>
                            </div>
                            <div *ngIf="homecare == '1'" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6"
                              style="margin-top: 10px;     margin-left: 23px;">
                              <mat-label *ngIf="homecare == '1'" class="matlabel">Date<br>
                                <input type="date" class="ipcss_date" min="{{presentdate}}" [(ngModel)]="homecareAppDate"
                                  (change)="selectAppointmentDate(homecareAppDate,'Home_App_Date')" matInput>
                              </mat-label>
                            </div>
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                              *ngIf="timeCardFlag && timeList.length !=0">
                              <mat-card id="cardcontent">
                                <mat-card-content style="background-color: #fff;">
                                  <div *ngFor="let session of timeList;let j=index">
                                    <mat-label class="appcreatenw_appttime">{{session.session}}</mat-label>
                                    <div class="row">
                                      <div class="timediv col-3" *ngFor="let time of session.time;let i=index "
                                        >
                                        <a (click)="selectAppointmentTime(time,session.session,i)">
                                          <p [id]="'time_'+j+'_'+i" class="apptime">{{time}}</p>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </mat-card-content>
                              </mat-card>
                            </div>
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                              *ngIf="homecare == '0' && timeList.length == 0" style="padding-left: 28px;">
                              <div class="nodata">No data found</div>
                            </div>
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                              *ngIf="timeCardFlag && homecareTimeList.length != 0" style="padding-left: 28px;">
                              <mat-card id="cardcontent">
                                <mat-card-content style="background-color: #fff;">
                                  <div *ngFor="let session of homecareTimeList;let j=index">
                                    <mat-label class="appcreatenw_appttime">{{session.session}}</mat-label>
                                    <div class="row">
                                      <div class="timediv col-3" *ngFor="let time of session.time;let i=index "
                                        style=" margin-left: 5px;">
                                        <a (click)="selectAppointmentTime(time,session.session,i)">
                                          <p [id]="'hometime_'+j+'_'+i" class="apptime">{{time}}</p>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </mat-card-content>
                              </mat-card>
                            </div>
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                              *ngIf="homecare == '1' && homecareTimeList.length == 0" style="padding-left: 28px;">
                              <div class="nodata">No data found</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-2 my-auto" style="text-align: center;">
                          <a (click)="addApptSession()" *ngIf="addSessionFlag"><img
                              src="../../../assets/img/pagination_next.png" style="width: 25px; height:auto;" /></a>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="table_cover">
                        <div class="col-12">
                          <div class="dig_table_overflow">
                            <table class="table table-hover table-dynamic" id="card_tbl">
                              <thead>
                                <tr>
                                  <th>Date</th>
                                  <th>Session</th>
                                  <th>Time</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let addsession of sessionList;let i=index">
                                  <td>{{addsession.date}}</td>
                                  <td>{{addsession.session}}</td>
                                  <td>{{addsession.time}}</td>
                                  <td><a (click)="deleteSession(i)"><img
                                        src="../../../assets/ui_icons/buttons/trash_icon.svg" class="editDelicon" /></a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>