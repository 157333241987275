import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { CommonDataService } from '../../providers/common-data.service';
import { Http, Headers } from '@angular/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from '../../helper_class';
import { Diagnosis_Helper } from '../Diagnosis_Helper';
import { ipaddress } from '../../ipaddress';

@Component({
  selector: 'app-diag-patient-list',
  templateUrl: './diag-patient-list.component.html',
  styleUrls: ['./diag-patient-list.component.scss']
})
export class DiagPatientListComponent implements OnInit {
  public pageList;
  public recptPatientArray = [];
  public middleName;
  public pageSize;
  
  public clientCount;
  public loader: boolean;
  public filterBy;
  public noofpages:any;
  dtOptions: DataTables.Settings = {};
  persons: any = [];
  masterSelected: boolean = false;
  public ipaddress;
  hptl_clinic_id: any;

  constructor(public datepipe:DatePipe,public gservice:CommonDataService,public http:Http,public toastr:ToastrService) { 
    this.ipaddress=Helper_Class.getIPAddress();
   
    this.pageList = "50";
   
    this.pageSize = 50;
    this.filterBy ="Patient Id";
    Diagnosis_Helper.setClientCount(null);
  }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 50,
      "lengthMenu": [ [25, 50, -1], [25, 50, "All"] ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search by patient id, name or mobile"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    
    if (Helper_Class.getInfo().user_type == 'Admin') { 
      this.hptl_clinic_id = Helper_Class.getInfo().diag_centre_id;
      this.getPatientList();
    }
    else {
      
      this.hptl_clinic_id = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.getPatientList();
    }

  }


  getPatientList() { 
    var headers = new Headers();
    var send_data={
      hptl_clinic_id:this.hptl_clinic_id,
      country: ipaddress.country_code,
      imei: this.ipaddress,
      limit: "full",
      filterby: this.filterBy,
      start_no: "1",
      type:"diagnosis"
    }
   
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'gen/gac',JSON.stringify(send_data),
      { headers: headers })
      .subscribe(
        response => {         
          var obj = JSON.parse(response["_body"]);
          this.recptPatientArray = [];
          if (obj.login != null) {
          } else {
            if (obj.clients != null) {
              for (var i = 0; i < obj.clients.length; i++) {
                if (obj.clients[i].middle_name != undefined && obj.clients[i].middle_name != "") {
                  this.middleName = encrypt_decript.Decript(obj.clients[i].middle_name);
                } else {
                  this.middleName ="";
                }
                var mobileval;
                if(obj.clients[i].mobile != undefined){
                  mobileval = encrypt_decript.Decript(obj.clients[i].mobile)
                } else {
                  mobileval="";
                }
                this.recptPatientArray.push({
                  client_reg_id: obj.clients[i].client_reg_id,
                  patient_id: obj.clients[i].patient_id,
                  relation_id: obj.clients[i].relation_id,
                  mobile: mobileval,
                  full_name: encrypt_decript.Decript(obj.clients[i].first_name) + " " + this.middleName + " " + encrypt_decript.Decript(obj.clients[i].last_name),
                });
              }
            }
          }
        },error => {});
  }

  numberOfPages() {
    this.noofpages = Math.ceil(this.clientCount / this.pageList);
    return Math.ceil(this.clientCount / this.pageList);
  }
}
