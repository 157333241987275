<div class="row">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Profile Information</h5>
                    </div>
                    <div class="headerButtons">
                        <img src="../../../assets/ui_icons/buttons/update_button.svg" (click)="clickUpdate()"
                            class="saveimgbtn_inpatinfo" />
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>
                <div class="row" style="margin: 0px;">
                    <div class="Details">
                        <mat-accordion class="mataccordion">
                            <mat-expansion-panel class="expandpanel " [expanded]="true">
                                <mat-expansion-panel-header class="exppanel width">
                                    <mat-panel-title class="title">
                                        Personal information
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <hr class="hr">
                                <div class="row" style="padding-top: 20px;">
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-2"
                                        style="text-align: center;position: relative;">
                                        <div style="position: relative;width: 160px;margin: 0 auto;">
                                            <img id="profile_img" class="profileimg"
                                                src="../../../assets/img/default.jpg"
                                                onerror="this.src='./././assets/img/default.jpg'" />
                                            <input type="file" (change)="changeFile($event)" name="profile_pic"
                                                id="profile_pic">
                                            <!--  (mouseover)="showsupload=true" (mouseout)="showsupload=false" *ngIf="showsupload"-->
                                            <label for="profile_pic" class="edit_profile_icon"><i
                                                    class="fas fa-pencil-alt"></i></label>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-10">
                                        <div class="row">
                                            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                                                <mat-label class="matlabel">First Name<br>
                                                    <input type="text" class="ipcss" [(ngModel)]="firstName"
                                                        disabled="true" matInput />
                                                </mat-label>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                                                <mat-label class="matlabel">Middle Name(optional)<br>
                                                    <input type="text" class="ipcss" [(ngModel)]="middleName"
                                                        disabled="true" matInput />
                                                </mat-label>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                                                <mat-label class="matlabel">Last Name<br>
                                                    <input type="text" class="ipcss" [(ngModel)]="lastName"
                                                        disabled="true" matInput />
                                                </mat-label>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                                                <div class="row">
                                                    <div class="col-6">
                                                        <mat-label class="matlabel">Gender<br>
                                                            <input type="text" class="ipcss" [(ngModel)]="gender"
                                                                disabled="true" matInput />
                                                        </mat-label>
                                                    </div>
                                                    <div class="col-6">
                                                        <mat-label class="matlabel">Date<br>
                                                            <input type="text" class="ipcss" [(ngModel)]="dob"
                                                                disabled="true" matInput />
                                                        </mat-label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                                                <mat-label class="matlabel">Phone Number<br>
                                                    <input type="text" class="ipcss" [(ngModel)]="mobile" maxlength="10"
                                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                        required matInput />
                                                </mat-label>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                                                <mat-label class="matlabel">Email<br>
                                                    <input type="text" class="ipcss " [(ngModel)]="email" maxlength="50"
                                                        required matInput />
                                                </mat-label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p class="tervys_heading">Registration details</p>
                                <div class="row">
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Licence code
                                            <input type="text" class="ipcss " [(ngModel)]="regCode" disabled="true"
                                                matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Year
                                            <input type="text" class="ipcss  " [(ngModel)]="regYear" disabled="true"
                                                matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">State / County
                                            <input type="text" class="ipcss " [(ngModel)]="regState" disabled="true"
                                                matInput />
                                        </mat-label>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion><br>
                        <mat-accordion class="mataccordion">
                            <mat-expansion-panel class="expandpanel" [expanded]="true">
                                <mat-expansion-panel-header class="exppanel width">
                                    <mat-panel-title class="title">
                                        Work location
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <hr class="hr">
                                <div class="row" style="padding-top: 20px;">
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Pharmacy Name<br>
                                            <input type="text" class="ipcss " required maxlength="50"
                                                [(ngModel)]="pharmaName" (keyup)="changePharma($event)" matInput
                                                [matAutocomplete]="auto10" />
                                            <mat-autocomplete #auto10="matAutocomplete">
                                                <mat-option (click)="selectHospital(item)"
                                                    *ngFor="let item of filteredHospArray" value={{item}}>
                                                    {{item}}
                                                </mat-option>
                                            </mat-autocomplete>
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Location<br>
                                            <select disableOptionCentering class="ipcss" required
                                                [(ngModel)]="pharmacyLocation" (ngModelChange)="changeLocation()">
                                                <option disabled>Select</option>
                                                <option *ngFor="let location of locationListArray" value={{location}}>
                                                    {{location}}</option>
                                            </select>
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">District / City<br>
                                            <input class="ipcss" [(ngModel)]="pharmacyDist" disabled disabled="true"
                                                matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">State<br>
                                            <input class="ipcss" [(ngModel)]="pharmacyState" disabled disabled="true"
                                                matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label cl ass="matlabel">Country<br>
                                            <input class="ipcss" [(ngModel)]="pharmacyCountry" disabled disabled="true"
                                                matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Address 1<br>
                                            <input type="text" class="ipcss" matInput [(ngModel)]="pharmacyAddress1"
                                                disabled maxlength="50">
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Address 2<br>
                                            <input type="text" class="ipcss" matInput [(ngModel)]="pharmacyAddress2"
                                                disabled maxlength="50" />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Zip code<br>
                                            <input class="ipcss" [(ngModel)]="pharmacyZip" disabled disabled="true"
                                                matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Website<br>
                                            <input type="text" class="ipcss" matInput [(ngModel)]="pharmacyWebsite"
                                                disabled maxlength="100" />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Phone number<br>
                                            <input type="text" class="ipcss" matInput
                                                [(ngModel)]="pharmacyContactNumber" disabled maxlength="14" />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Day<br>
                                            <select disableOptionCentering class="ipcss" [(ngModel)]="pharmacyDays"
                                                required>
                                                <option disabled>Select</option>
                                                <option value="Mon - Sat">Mon - Sat</option>
                                                <option value="All Days">All Days</option>
                                            </select>
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Starts from</mat-label>
                                        <div class="row">
                                            <div class="col-4">
                                                <select disableOptionCentering [(ngModel)]="startFrom1" class="ipcss ">
                                                    <option *ngFor="let name of startsFromArray" value={{name}}>
                                                        {{name}}</option>
                                                </select>
                                            </div>
                                            <div class="col-4">
                                                <select disableOptionCentering [(ngModel)]="startFrom2" class="ipcss ">
                                                    <option *ngFor="let name of endsAtArray" value={{name}}>
                                                        {{name}}</option>
                                                </select>
                                            </div>
                                            <div class="col-4">
                                                <select disableOptionCentering [(ngModel)]="startFrom3" class="ipcss ">
                                                    <option value="AM">AM</option>
                                                    <option value="PM">PM</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Ends at</mat-label>
                                        <div class="row">
                                            <div class="col-4">
                                                <select disableOptionCentering [(ngModel)]="endAt1" class="ipcss ">
                                                    <option *ngFor="let name of startsFromArray" value={{name}}>
                                                        {{name}}</option>
                                                </select>
                                            </div>
                                            <div class="col-4">
                                                <select disableOptionCentering [(ngModel)]="endAt2" class="ipcss ">
                                                    <option *ngFor="let name of endsAtArray" value={{name}}>
                                                        {{name}}</option>
                                                </select>
                                            </div>
                                            <div class="col-4">
                                                <select disableOptionCentering [(ngModel)]="endAt3" class="ipcss ">
                                                    <option value="AM">AM</option>
                                                    <option value="PM">PM</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel"><br>Home Delivery
                                            <mat-checkbox color="primary" [(ngModel)]="HomeDelivery"></mat-checkbox>
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel"><br>Store Pickup
                                            <mat-checkbox color="primary" [(ngModel)]="StorePickup"
                                                (change)="StorePickupChanger()"></mat-checkbox>
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Store pickup days<br>
                                            <input type="text" class="ipcss " [(ngModel)]="pharmacyStore" maxlength="2"
                                                disabled="{{storePickupDisabled}}" maxlength="1" matInput />
                                        </mat-label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div style="margin: 15px 0;">
                                        <img style="float: right;" src="../../../assets/ui_icons/buttons/add_button.svg"
                                            (click)="clickWork()" class="saveimgbtn_inpatinfo" />
                                    </div>
                                </div>
                                <div class="row">
                                    <table *ngIf="workArray.length" mdbTable datatable [dtOptions]="dtOptions"
                                        class="table table-nowrap table-sm dataTable" [hidden]="workArray.length == 0">
                                        <thead>
                                            <tr>
                                                <th>Pharmacy Name</th>
                                                <th>Location</th>
                                                <th>Day</th>
                                                <th>Starts from</th>
                                                <th>Ends at</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let work of  workArray">
                                                <td>{{work.name}}</td>
                                                <td>{{work.location}}</td>
                                                <td>{{work.day_desc}}</td>
                                                <td>{{work.available_from}}</td>
                                                <td>{{work.available_to}}</td>
                                                <td>
                                                    <a style="cursor: pointer;"
                                                        (click)="deleteWork(work.consul_id)"><img
                                                            src="../../../assets/ui_icons/buttons/trash_icon.svg"
                                                            height="20px" width="20px" alt=""></a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion><br>
                    </div>
                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>