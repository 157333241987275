<div class="row">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white" style="padding:7px 20px;">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Features</h5>
          </div>
          <div class="headerButtons">
            <img src="../../../assets/ui_icons/buttons/save_button.svg" (click)="save()"
              class="saveimgbtn_inpatinfo" />
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row" style="margin: 0px;">
          <div class="col-12 d-flex justify-content-center">
            <div class="dig_table_overflow table-responsive" style="width: 85%;height: 410px;">
              <table class="table table-hover table-dynamic">
                <thead>
                  <tr>
                    <th style="text-align: left;left: 185px;position: relative;"> Featuers </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let subpack of packageList;let i = index">
                    <td style="min-width:251px;padding: 3px 20px;text-align: left;"><span> {{subpack.package_name}}</span>
                    </td>
                    <td>
                      <mat-slide-toggle 
                        color="primary"
                        [checked]="subpack.checked"
                        [(ngModel)]="subpack.checked"
                        (ngModelChange)="selectPackage(subpack.checked,subpack)">
                      </mat-slide-toggle>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>