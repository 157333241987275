import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Date_Formate } from '../../../assets/js/common';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { ChangeDetectorRef } from '@angular/core';
import { Helper_Class } from '../../helper_class';
import { Router, ActivatedRoute } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { ipaddress } from '../../ipaddress';
import { Subscription } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { PhysioCasesheetService } from '../physio-casesheet/physio-casesheet.service';
import { PhysioService } from '../physio/physio.service';
import { Physio_Helper } from '../Physio_Helper';
import { MedPresPopupPageComponent } from  '../../Doctor_module/med-pres-popup-page/med-pres-popup-page.component';
import { PreviousPrescriptionPopupComponent } from '../../Doctor_module/previous-prescription-popup/previous-prescription-popup.component';
import { TranslateService } from '@ngx-translate/core';
import { Message_data } from 'src/assets/js/Message_data';
declare var $: any;
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-physio-med-prescription',
  templateUrl: './physio-med-prescription.component.html',
  styleUrls: ['./physio-med-prescription.component.scss']
})
export class PhysioMedPrescriptionComponent implements OnInit {
    @ViewChild('recognitionResult') recognitionResult: ElementRef;
    @ViewChild('printlogowithname') printlogowithname: ElementRef;
    @ViewChild('printbanner') printbanner: ElementRef;
    @ViewChild('printnoheader') printnoheader: ElementRef;
    @ViewChild('printnoheadsidebar') printnoheadsidebar: ElementRef;
    public saveflag: boolean = true;
    public medPresFlag = "min";
    public hospImg;
    public hptlPresLogo: string;
    public treatFlag: boolean;
    public referredDoctor;
    public referredDoctorFlag: boolean;
    public treatment;
    public newMed: boolean;
    public newMedFlag: boolean;
    public rowid: string;
    public orderNow;
    public remark: string;
    public clntBp: string;
    public clntPulse: string;
    public addmed: boolean = false;
    public currentDate;
    public currentMinDate;
    public appointDate: string;
    public dayDuration2;
    public hospitalName: string;
    public doctorName: string;
    public hospLocation: string;
    public hospAddress: string;
    public hospCity: string;
    public hospState: string;
    public clientName: string;
    public medicareName: string;
    public medicalPrescriptionList = [];
    public orthoFlag: boolean;
    public hospitalDetails;
    public clntCvs: string;
    public clntRs: string;
    public clntKidney: string;
    public instructions: string;
    public bpFlag: boolean;
    public medicineType: string;
    public drugType: string;
    public medicineName: string;
    public intakeSessionList = [];
    public drugIdList = [];
    public intakeSession: string;
    public mor: string;
    public aft: string;
    public eve: string;
    public ngt: string;
    public everySix: string;
    public minuteDuration: string;
    public totalDuration: string;
    public listProducts = [];
    public dayDuration1: string;
    public intake: string;
    public afterfood: string;
    public medicineFlag: boolean = true;
    public prferPharma: string;
    public prferPharmaAddress: string;
    public clientId;
    public relId;
    public subId;
    public appId;
    public printTemplate: string;
    public doctorQualif: string;
    public instructionsFlag: boolean;
    public diagnosis;
    public investigation;
    public homecare: string;
    public diabMedPres: string;
    public checkPres;
    public pharmaId;
    public pharmacyMainList;
    public pharmacyListData = [];
    public pharmacyList;
    public minuteDurFlag: boolean = false;
    public morFlag: boolean = true;
    public afterEveFlag: boolean = true;
    public morId: boolean;
    public afterId: boolean;
    public quantityFlag: boolean = true;
    public addMedicineFlag: boolean = false;
    public intakeId;
    public popFlag = null;
    public hsptlClinicId;
    public clntGender;
    public clntAge;
    public doctorname;
    public morRequiredFlag: boolean;
    public morDisabledFlag: boolean;
    public afternoonRequiredFlag: boolean;
    public eveningDisabledFlag: boolean;
    public afternoonDisabledFlag: boolean;
    public eveningRequiredFlag: boolean;
    public nightRequiredFlag: boolean;
    public nightDisabledFlag: boolean;
    public backFlag: boolean = false;
    public bgImage: string;
    public micOff: any = {
      icon: 'mic',
      color: 'balance'
    };
    public micOn: any = {
      icon: 'mic',
      color: 'danger'
    };
    public mic: any = this.micOff;
    public recognition: any;
    public recognizing: Boolean;
    public dureFlag: boolean;
    public medType;
    public shortForm;
    public show;
    public showIntake;
    public eveFlag: boolean = false;
    public nightFlag: boolean = true;
    public fillType: string;
    public mornFlag: boolean;
    public intakeFlag: boolean;
    public ngtDisabledFlag: boolean;
    public licenceCode: string;
    public qualification = [];
    public minuteDurRequiredFlag: boolean;
    public pdfObj;
    public imageString;
    public printPres: string;
    public hospFooter: string;
    public printFooterFlag: boolean;
    public medPresList = [];
    public sendClientDetails: any = {};
    public retrvMedPresListData;
    public docnameFlag: boolean;
    flow: string;
    public docRegId;
    public pharmacyDetails = [];
    public pharmacyFlag: boolean = false;
    public retrvDoctorQualify;
    public retrvHsptlName;
    public nextVisit;
    public retrvHsptlAddress;
    public retrvHsptlLocation;
    public retrvHsptlCity;
    public retrvHsptlState;
    public retrvHsptlCountry;
    public retrvHsptlTelephone;
    public prescriptionUrl: string;
    public prescriptionPresId;
    public retrvPrescriptionData: any;
    public tabletTracker = [];
    public retrvPatientName: string;
    public retrvDoctorName: string;
    public retrvPrescriptionDate: string;
    public retrvDiseaseName: string;
    public retrvDiseaseDesc: string;
    public subRelId: string;
    public relationName: string;
    public hasPharmaFlag: boolean = false;
    public pharmacyName: string;
    public pharAddr1: string;
    public pharAddr2: string;
    public pharLocation: string;
    public pharCity: string;
    public pharZipcode: string;
    public pharState: string;
    public pharCountry: string;
    public telephone: string;
    public storePickUpFlag: boolean = false;
    public delopt: boolean = false;
    public homeDeliveryFlag: boolean = false;
    public validityDays;
    public workTime;
    public hasSearchFlag: boolean = false;
    public hasMultiTab: boolean = false;
    public symptoms: string;
    public symptomListData = [];
    public symptomList = [];
    public symptomsMainListData = [];
    public selectedSymptoms = [];
    public language: string;
    public balanceQuantity: number = 0;
    public retrvDoctorNameFlag: boolean;
    public retrvHospFlag: boolean;
    public sendPresId;
    public medViewListFlag: boolean = false;
    public splname;
    subscription: Subscription;
    public flagviewrx: boolean;
    public currentYear: string;
    public currentTime: string;
    public personalList;
    public userInfo;
    public hospitalInfo;
    public country;
    public zipcode;
    public navigtionflag: string;
    public address1;
    public address2;
    public addMedPresFlag: boolean = false;
    public printInstruction: any;
    public getSymptomData;
    public medicineList;
    public medicneListData = [];
    public medicineMainListData = [];
    public medId;
    public printNextVisit;
    public durationFlag: boolean;

    Reset() {
      $("#obs_txt1").val("");
    }
    content;
    htmlString: string = '';
    modules = {
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'], 
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }], 
        [{ 'direction': 'rtl' }], 
        [{ 'size': ['small', false, 'large', 'huge'] }], 
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'color': [] }, { 'background': [] }], 
        [{ 'font': [] }],
        [{ 'align': [] }],
        ['clean'], 
      ],
    };
    editorStyle = {
      height: '300px;',
    };

  constructor(public translate:TranslateService, public sanitizer: DomSanitizer,
  public dialog: MatDialog, public http: Http, public cd: ChangeDetectorRef,
  public routes: ActivatedRoute, public router: Router, public toastr: ToastrService,
  public messageservice: PhysioCasesheetService, public naviagationService: PhysioService) {    
    this.mor = "1";
    this.aft = "1";
    this.ngt = "1";
    this.instructionsFlag = true;
    this.dureFlag = false;
    this.orthoFlag = false;
    this.bpFlag = false;
    this.treatFlag = false;
    this.referredDoctorFlag = false;
    this.printFooterFlag = true;
    this.newMedFlag = true;
    this.minuteDurFlag = false;
    this.flagviewrx = true;
    this.recognition = null;
    this.recognizing = false;
    this.translate.setDefaultLang('english');
    this.translate.use('english');
  }

  ngOnInit(): void {
  this.dayDuration1 = "";
  this.diagnosis = "";
  this.listProducts = [];
  $(document).ready(function () {
    $("input").attr("autocomplete", "off");
  });
  this.subscription = this.messageservice.getCasesheetMessage().subscribe(message => {
    this.medPresFlag = message;
    this.addmed = false;
  });
  this.userInfo = Helper_Class.getInfo();
  this.personalList = Physio_Helper.getClient_Info();
  this.addMedPresFlag = false;
  this.hospitalName = Physio_Helper.getClient_Info().hptl_name;
  this.address1 = Physio_Helper.getClient_Info().address1;
  this.address2 = Physio_Helper.getClient_Info().address2;
  this.hospLocation = Physio_Helper.getClient_Info().location;
  this.hospCity = Physio_Helper.getClient_Info().city;
  this.hospState = Physio_Helper.getClient_Info().state;
  this.zipcode = Physio_Helper.getClient_Info().zipcode;
  this.country = Physio_Helper.getClient_Info().country;
  this.doctorname = this.userInfo.first_name + " " + this.userInfo.last_name;
  this.clntGender = this.personalList.Gender_data;
  this.appointDate = this.personalList.Appoint_Date;
  this.clntAge = this.personalList.Age_data;
  this.clientName = this.personalList.client_name;
  this.medicareName ="Allopathy";
  this.hospitalInfo = Helper_Class.getHospitalInfo();
  if (this.personalList.AppFlow == "InpatMedPres") {
    this.medPresFlag = 'max';
    this.navigtionflag = "info";
    this.backFlag = true;
  } else if (this.personalList.AppFlow == "InpatMedPres_dis") {
    this.medPresFlag = 'max';
    this.navigtionflag = "dis";
    this.backFlag = true;
  } else {
    this.backFlag = false;
  }
  this.hospitalDetails = Helper_Class.getHospital();
  this.doctorname = this.userInfo.first_name + " " + this.userInfo.last_name;
  this.doctorName = this.doctorname;
  this.pharmaId = this.hospitalDetails[0].pharma_id;
  this.prferPharma = this.hospitalDetails[0].pharma_name;
  if (this.prferPharma != undefined) {
    this.hsptlClinicId = this.hospitalDetails[0].hptl_clinic_id;
    this.getPharmacyAddress();
  }
  this.qualification = Helper_Class.getQualification();
  this.recognition = null;
  this.recognizing = false;
  this.licenceCode = this.userInfo.licence_code;
  if (this.userInfo.hospitals[0].logo != undefined) {
    this.hospImg = this.userInfo.hospitals[0].logo;
    this.getBase64Image(this.hospImg);
  }
  if (this.hospitalDetails.hptl_name != undefined && this.hospitalDetails.hptl_name != "undefined") {
    this.hospitalName = this.hospitalDetails.hptl_name;
  }
  if (this.personalList.treatment != undefined && this.personalList.treatment != "undefined") {
    this.treatment = this.personalList.treatment;
    this.treatFlag = true;
  }
  var refer = Physio_Helper.getClient_Info().refer_by;
  if ((refer != null && refer != undefined && refer != "") && refer != 'null') {
    this.referredDoctor = Physio_Helper.getClient_Info().refer_by;
    this.referredDoctorFlag = true;
  }
  else {
    this.referredDoctorFlag = false;
  }
  if (this.personalList.symptoms != undefined) {
    this.investigation = this.personalList.symptoms;
  }
  this.clntBp = this.personalList.bp == undefined ? "" : this.personalList.bp;
  if (this.personalList.bp != null && this.personalList.bp != undefined) {
    this.clntBp = this.personalList.bp;
    this.bpFlag = true;
  } else {
    this.bpFlag = false;
  }
  if (this.personalList.pulse != undefined && this.personalList.bp != null) {
    this.clntPulse = this.personalList.pulse;
    this.bpFlag = true;
  } else {
    this.bpFlag = false;
  }
  if (this.personalList.cvs != null) {
    this.clntCvs = this.personalList.cvs;
    this.orthoFlag = true;
  } else {
    this.orthoFlag = false;
  }
  if (this.personalList.rs != null) {
    this.clntRs = this.personalList.rs
    this.orthoFlag = true;
  } else {
    this.clntRs = "";
  }
  if (this.personalList.kidney != null) {
    this.clntKidney = this.personalList.kidney;
    this.orthoFlag = true;
  } else {
    this.clntKidney = "";
  }
  this.getCurrentDate();
  if (this.medPresFlag == "min") {
    this.flagviewrx = true;
  }
  else {
    this.flagviewrx = false;
  }
  this.medicineFlag = true;
  this.minuteDurFlag = false;
  this.quantityFlag = true;
  this.morFlag = false;
  this.afterEveFlag = false;
  this.eveFlag = true;
  this.durationFlag = false;
  this.nightFlag = false;
  this.morId = false;
  this.afterId = false;
  this.addMedicineFlag = true;
  this.language = "English";
  this.dayDuration2 = "day(s)"
  this.intake = "After food";
  this.minuteDuration = "30";
  this.splname = this.personalList.spl;
  this.sendClientDetails.client_id = this.personalList.Client_id;
  this.sendClientDetails.relation = this.personalList.relationship_name;
  this.sendClientDetails.home_care = Helper_Class.getapp_flow();
  this.homecare = Helper_Class.getapp_flow();
  this.flow = "order";
  this.getIntakeSession();
  this.changeLanguage();
  this.listProducts = [];
  }

  getMedicineType(e) {
    this.medType = e.target.value.toString();
    this.medicalPrescriptionList = [];
    var headers = new Headers();
    if (this.medType != null && this.medType != undefined && this.medType.length > 2) {
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/medtypes/',
        {
          medicare_id: this.medicareName,
          medicine_name: this.medType
        },
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            var medtypetxt1 = [];
            this.medicalPrescriptionList = obj.med_details;
          },
          error => {
          }
        )
    }
  }

  selectMedicine(item) {
    for (var i = 0; i < this.medicineMainListData.length; i++) {
      if (this.medicineMainListData[i].medname == item) {
        this.medicineName = this.medicineMainListData[i].med_name;
        this.drugType = this.medicineMainListData[i].med_type_id;
        this.medType = this.medicineMainListData[i].type_name;
        this.shortForm = this.medicineMainListData[i].short_form;
        this.show = this.medicineMainListData[i].show_short_form;
        this.showIntake = this.medicineMainListData[i].show_intake;
        this.medId = this.medicineMainListData[i].med_id;
      }
    }
    this.changeIntakeSession();
    this.medicineList = [];
  }

  getIntakeSession() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'prescription/dintypes/',
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          this.intakeSessionList = obj.drug_intake;
          this.intakeSession = "Thrice a day";
        },
        error => {
        }
      )
  }
  
  addMedicalPrescription() {
  var flag = false;
  if(this.medType == undefined || this.medType ==""){
    this.toastr.error(Message_data.enterMedType);
    flag = true;
    this.newMed=true;
    this.newMedFlag=false;
  }
  if (this.medicineName == undefined || this.medicineName == "") {
    this.toastr.error(Message_data.enterMedName);
    flag = true;
  } else if (this.intakeSession != "Stat" &&(this.dayDuration1 == undefined || this.dayDuration1 == "")) {
    this.toastr.error(Message_data.enterNoOfDays);
    flag = true;
  } else if (this.intakeSession == undefined) {
    this.toastr.error(Message_data.enterIntkSess);
    flag = true;
  }
  if (this.showIntake == "1") {
    if (this.intakeSession != undefined && this.intakeSession == "Morning only" && (this.mor == undefined || this.mor == "")) {
      this.toastr.error(Message_data.enterDailyIntk);
      flag = true;
    } else if (this.intakeSession != undefined && this.intakeSession == "Afternoon only" && (this.aft == undefined || this.aft == "")) {
      this.toastr.error(Message_data.enterDailyIntk);
      flag = true;
    } else if (this.intakeSession != undefined && this.intakeSession == "Evening only" && (this.eve == undefined || this.eve == "")) {
      this.toastr.error(Message_data.enterDailyIntk);
      flag = true;
    }  else if (this.intakeSession != undefined && this.intakeSession == "Night only" && (this.ngt == undefined || this.ngt == "")) {
      this.toastr.error(Message_data.enterDailyIntk);
      flag = true;
    } else if (this.intakeSession != undefined && this.intakeSession == "Morning & Afternoon" &&
      ((this.mor == undefined || this.mor == "") || (this.aft == undefined || this.aft == ""))) {
      this.toastr.error(Message_data.enterDailyIntk);
      flag = true;
    } else if (this.intakeSession != undefined && this.intakeSession == "Morning & Evening" &&
      ((this.mor == undefined || this.mor == "") || (this.eve == undefined || this.eve == ""))) {
      this.toastr.error(Message_data.enterDailyIntk);
      flag = true;
    } else if (this.intakeSession != undefined && this.intakeSession == "Morning & Night" &&
      ((this.mor == undefined || this.mor == "") || (this.ngt == undefined || this.ngt == ""))) {
      this.toastr.error(Message_data.enterDailyIntk);
      flag = true;
    } else if (this.intakeSession != undefined && this.intakeSession == "Afternoon & Evening" &&
      ((this.aft == undefined || this.aft == "") || (this.eve == undefined || this.eve == ""))) {
      this.toastr.error(Message_data.enterDailyIntk);
      flag = true;
    } else if (this.intakeSession != undefined && this.intakeSession == "Afternoon & Night" &&
      ((this.aft == undefined || this.aft == "") || (this.ngt == undefined || this.ngt == ""))) {
      this.toastr.error(Message_data.enterDailyIntk);
      flag = true;
    } else if (this.intakeSession != undefined && this.intakeSession == "Morning,Afternoon & Evening" &&
      ((this.mor == undefined || this.mor == "") || (this.aft == undefined || this.aft == "")
        || (this.eve == undefined || this.eve == ""))) {
      this.toastr.error(Message_data.enterDailyIntk);
      flag = true;
    } else if (this.intakeSession != undefined && this.intakeSession == "Morning,Afternoon & Night" &&
      ((this.mor == undefined || this.mor == "") || (this.aft == undefined || this.aft == "")
        || (this.ngt == undefined || this.ngt == ""))) {
      this.toastr.error(Message_data.enterDailyIntk);
      flag = true;
    } else if (this.intakeSession != undefined && this.intakeSession == "Morning,Afternoon,Evening & Night" &&
      ((this.mor == undefined || this.mor == "") || (this.aft == undefined || this.aft == "")
        || (this.eve == undefined || this.eve == "")|| (this.ngt == undefined || this.ngt == ""))) {
      this.toastr.error(Message_data.enterDailyIntk);
      flag = true;
    } else if (this.intakeSession != undefined && this.intakeSession == "Afternoon,Evening & Night" &&
      ((this.aft == undefined || this.aft == "") || (this.eve == undefined || this.eve == "")
        || (this.ngt == undefined || this.ngt == ""))) {
      this.toastr.error(Message_data.enterDailyIntk);
      flag = true;
    } else if (this.intakeSession != undefined && this.intakeSession == "Evening & Night" &&
      ((this.eve == undefined || this.eve == "") || (this.ngt == undefined || this.ngt == ""))) {
      this.toastr.error(Message_data.enterDailyIntk);
      flag = true;
    } else if (this.intakeSession != undefined && this.intakeSession == "Thrice a day" &&
      ((this.mor == undefined || this.mor == "") || (this.aft == undefined || this.aft == "")
        || (this.ngt == undefined || this.ngt == ""))) {
      this.toastr.error(Message_data.enterDailyIntk);
      flag = true;
    } else if (this.intakeSession != undefined && this.intakeSession == "Four times a day" &&
      ((this.mor == undefined || this.mor == "") || (this.aft == undefined || this.aft == "")
        || (this.eve == undefined || this.eve == "")|| (this.ngt == undefined || this.ngt == ""))) {
      this.toastr.error(Message_data.enterDailyIntk);
      flag = true;
    } else if (this.intakeSession != undefined && (this.intakeSession == "Every 6 hours" || this.intakeSession == "Every 8 hours"
      || this.intakeSession == "Every 12 hours" || this.intakeSession == "Once a day" || this.intakeSession == "Stat") 
      && (this.mor == undefined || this.mor == "")) {
      this.toastr.error(Message_data.enterIntake);
      flag = true;
    } else if (this.intakeSession == "Stat" && (this.mor == undefined || this.mor == "")) {
      this.toastr.error(Message_data.enterIntake);
      flag = true;
    } else if (this.intakeSession != undefined && (this.intakeSession == "Alternative day" || this.intakeSession == "Once a week"
      || this.intakeSession == "Once in two weeks") && (this.mor == "0" && this.aft == "0" && this.eve == "0")) {
        this.toastr.error(Message_data.enterIntake);
      flag = true;
    }
  }
  if (flag == false) {
    for (var i = 0; i < this.medicalPrescriptionList.length; i++) {
      if (this.medicalPrescriptionList[i].med_name == this.medType) {
        this.drugType = this.medicalPrescriptionList[i].med_code;
      }
    }
    for (var i = 0; i < this.intakeSessionList.length; i++) {
      if (this.intakeSession == this.intakeSessionList[i].description) {
        this.intakeId = this.intakeSessionList[i].drug_int_id;
      }
    }
    this.medicineFlag = false;
    if (this.medId == null) {
      this.medId="0";
    }
      var aftfood;
      if (this.show == "1") {
        var morntxt;
        if (parseFloat(this.mor) != 0) {
          morntxt = this.mor + this.shortForm;
        } else {
          morntxt = "0"
        }
        var afttxt;
        if (parseFloat(this.aft) != 0) {
          afttxt = this.aft + this.shortForm;
        } else {
          afttxt = "0"
        }
        var evetxt;
        if (parseFloat(this.eve) != 0) {
          evetxt = this.eve + this.shortForm;
        } else {
          evetxt = "0"
        }
        var ngttxt;
        if (parseFloat(this.ngt) != 0) {
          ngttxt = this.ngt + this.shortForm;
        } else {
          ngttxt = "0"
        }
        if (this.showIntake == "0") {
          this.everySix = this.intakeSession;
        } else {
          if (this.intakeSession == "Every 6 hours") {
            this.everySix = morntxt + " - Every 6 hours";
          } else if (this.intakeSession == "Every 8 hours") {
            this.everySix = morntxt + " - Every 8 hours";
          } else if (this.intakeSession == "Every 12 hours") {
            this.everySix = morntxt + " - Every 12 hours";
          } else if (this.intakeSession == "SOS") {
            if(this.drugType =="1"|| this.drugType =="7" || this.drugType =="10"
            || this.drugType =="23"|| this.drugType =="30"){
              this.everySix = morntxt +" SOS - if required";
            } else {
              this.everySix = morntxt + " SOS - if required";
            }
          } else if (this.intakeSession == "Thrice a day") {
            this.everySix = morntxt + " - " + afttxt + " - " + ngttxt;
          } else if (this.intakeSession == "Four times a day") {
            this.everySix = morntxt + " - " + afttxt + " - " + evetxt+ " - " + ngttxt;
          } else if (this.intakeSession == "Once a day") {
            this.everySix = morntxt+" - per day";
          } else if (this.intakeSession == "Stat") {
            this.everySix = morntxt +" - Stat";
          } else {
            this.everySix = morntxt + " - " + afttxt + " - " + ngttxt;
          }
        }
        if (this.intakeSession != "Stat" && (this.minuteDuration != undefined && this.minuteDuration != "")) {
          this.totalDuration = this.minuteDuration + " " + "mins";
        } else {
          this.totalDuration = "";
          this.dureFlag = true;
        }
        if (this.intake == undefined) {
          this.intake = "";
        }
        var frequency;
        if (this.intakeSession == "Alternative day" || this.intakeSession == "Once a week" || this.intakeSession == "Once in two weeks") {
          frequency = this.intakeSession;
        } else {
          if(this.intakeSession == "Stat" || this.intakeSession == "SOS"){
            frequency ="";
          } else {
            if (this.dayDuration1 == "1" && this.dayDuration2 == "day(s)") {
              frequency ="";
            } else {
              frequency = "Daily";
            }
          }
        }
        if(this.intakeSession == "Stat"){
          period="";
          this.dayDuration1="";
        } else {
          var period
          if (this.dayDuration1 == "1" && this.dayDuration2 == "day(s)") {
            period = "day";
          } else if (this.dayDuration1 != "1" && this.dayDuration2 == "day(s)") {
            period = "days";
          } else if (this.dayDuration1 == "1" && this.dayDuration2 == "week(s)") {
            period = "week";
          } else if (this.dayDuration1 != "1" && this.dayDuration2 == "week(s)") {
            period = "weeks";
          } else if (this.dayDuration1 == "1" && this.dayDuration2 == "month(s)") {
            period = "month";
          } else if (this.dayDuration1 != "1" && this.dayDuration2 == "month(s)") {
            period = "months";
          } else {
            period = "";
          }
        }
        if(this.intakeSession != "Stat" && (this.intake != undefined && this.intake !='Select')){
          aftfood=this.intake;
        } else {
          aftfood="";
        }
        this.listProducts.push({
          med_typetxt: this.medType,
          drug_name: this.medicineName,
          days: this.dayDuration1,
          period: period,
          drug_intake: aftfood,
          intake: this.intakeId,
          every_six: this.everySix,
          dure_txt_table: this.totalDuration,
          time_duration: this.minuteDuration,
          morning: this.mor,
          afternoon: this.aft,
          evening: this.eve,
          night: this.ngt,
          drug_id: this.medId,
          drug_type_id: this.drugType,
          remarks: this.remark,
          frequency: frequency,
          show_intake: this.showIntake,
          rowid: this.rowid,
        });
      } else {
        var morntxt;
        if (parseFloat(this.mor) != 0) {
          morntxt = this.mor + this.shortForm;
        } else {
          morntxt = "0"
        }
        var afttxt;
        if (parseFloat(this.aft) != 0) {
          afttxt = this.aft + this.shortForm;
        } else {
          afttxt = "0"
        }
        var evetxt;
        if (parseFloat(this.eve) != 0) {
          evetxt = this.eve + this.shortForm;
        } else {
          evetxt = "0"
        }
        var ngttxt;
        if (parseFloat(this.ngt) != 0) {
          ngttxt = this.ngt + this.shortForm;
        } else {
          ngttxt = "0"
        }
        if (this.showIntake == "0") {
          this.everySix = this.intakeSession;
        } else {
          if (this.intakeSession == "Every 6 hours") {
            this.everySix = morntxt+" "+this.shortForm + " - Every 6 hours";
          } else if (this.intakeSession == "Every 8 hours") {
            this.everySix = morntxt+" "+this.shortForm + " - Every 8 hours";
          } else if (this.intakeSession == "Every 12 hours") {
            this.everySix = morntxt+" "+this.shortForm + " - Every 12 hours";
          } else if (this.intakeSession == "SOS") {
            if(this.drugType =="1"|| this.drugType =="7" || this.drugType =="10"
            || this.drugType =="23"|| this.drugType =="30"){
              this.everySix = morntxt+" "+this.shortForm +" SOS - if required";
            } else {
              this.everySix = morntxt+" "+this.shortForm +" SOS - if required";
            }
          } else if (this.intakeSession == "Thrice a day") {
            this.everySix = morntxt + " - " + afttxt + " - " + ngttxt;
          } else if (this.intakeSession == "Four times a day") {
            this.everySix = morntxt + " - " + afttxt + " - " + evetxt+ " - " + ngttxt;
          } else if (this.intakeSession == "Once a day") {
            this.everySix = morntxt+" - per day";
          } else if (this.intakeSession == "Stat") {
            this.everySix = morntxt+" "+this.shortForm +" - Stat";
          } else {
            this.everySix = morntxt + " - " + afttxt + " - " + ngttxt;
          }
        }
        if (this.minuteDuration != undefined && this.minuteDuration != "") {
          this.totalDuration = this.minuteDuration + " " + "mins";
        } else {
          this.totalDuration = "";
          this.dureFlag = true;
        }
        var frequency;
        if (this.intakeSession == "Alternative day" || this.intakeSession == "Once a week" || this.intakeSession == "Once in two weeks") {
          frequency = this.intakeSession;
        } else {
          if(this.intakeSession == "Stat" || this.intakeSession == "SOS"){
            frequency ="";
          } else {
            if (this.dayDuration1 == "1" && this.dayDuration2 == "day(s)") {
              frequency ="";
            } else {
              frequency = "Daily";
            }
          }
        }
        var period
        if (this.dayDuration1 == "1" && this.dayDuration2 == "day(s)") {
          period = "day";
        } else if (this.dayDuration1 != "1" && this.dayDuration2 == "day(s)") {
          period = "days";
        } else if (this.dayDuration1 == "1" && this.dayDuration2 == "week(s)") {
          period = "week";
        } else if (this.dayDuration1 != "1" && this.dayDuration2 == "week(s)") {
          period = "weeks";
        } else if (this.dayDuration1 == "1" && this.dayDuration2 == "month(s)") {
          period = "month";
        } else if (this.dayDuration1 != "1" && this.dayDuration2 == "month(s)") {
          period = "months";
        } else {
          period = "";
        }
        if(this.intake != undefined && this.intake !='Select'){
          aftfood=this.intake;
        } else {
          aftfood="";
        }
        this.listProducts.push({
          med_typetxt: this.medType,
          drug_name: this.medicineName,
          days: this.dayDuration1,
          period: period,
          drug_intake: aftfood,
          intake: this.intakeId,
          every_six: this.everySix,
          dure_txt_table: this.totalDuration,
          time_duration: this.minuteDuration,
          morning: this.mor,
          afternoon: this.aft,
          evening: this.eve,
          night:this.ngt,
          drug_id: this.medId,
          drug_type_id: this.drugType,
          frequency: frequency,
          remarks: this.remark,
          show_intake: this.showIntake,
          rowid: this.rowid,
        });
      }
      this.medicineType = "";
      this.medicineName = "";
      this.newMedFlag=true;
      this.newMed=false;
  }
  }

  deleteMedicalPrescription(medtype, medname) {
    for (var i = 0; i < this.listProducts.length; i++) {
      if (this.listProducts[i].med_typetxt == medtype && this.listProducts[i].drug_name == medname) {
        this.listProducts.splice(i, 1);
        break;
      }
    }
    if (this.listProducts.length != 0) {
      this.medicineFlag = false;
    }
    else {
      this.medicineFlag = true;
    }
  }

  changePreferPharmacy(e) {
    this.pharmacyList = [];
    this.pharmacyListData = [];
    this.prferPharma = e.target.value.toString();
    if (this.prferPharma != undefined && this.prferPharma.length >= 3) {
      this.prferPharmaAddress = "";
      for (var i = 0; i < this.medicalPrescriptionList.length; i++) {
        if (this.medicalPrescriptionList[i].med_name == this.medicineType) {
          this.drugType = this.medicalPrescriptionList[i].med_code;
        }
      }
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/pharmabyname/',
        {
          location: this.hospLocation,
          pharma_name: this.prferPharma,
          drug_ids: this.drugIdList,
          flag: "prescription",
        },
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            if (obj.pharma_list != null) {
              this.pharmacyMainList = obj.pharma_list;
              for (var i = 0; i < obj.pharma_list.length; i++) {
                this.pharmacyListData.push(obj.pharma_list[i].pharmacy_name);
                this.pharmaId = obj.pharma_list[i].pharma_id;
              }
              this.pharmacyList = this.pharmacyListData.filter(function (this: any, el) {
                return el.toLowerCase().indexOf(this.prfer_pharmatxt.toLowerCase()) > -1;
              }.bind(this));
            } else {
              this.toastr.error(Message_data.noPharmaFound);
            }
          },
          error => {
          }
        )
    }
  }

  selectPreferPharmacy(item) {
    this.prferPharma = item;
    if (item == this.pharmacyList) {
      for (var i = 0; i < this.pharmacyMainList.length; i++) {
        this.pharmaId = this.pharmacyMainList[i].pharma_id;
        if (this.pharmacyMainList[i].telephone != undefined && this.pharmacyMainList[i].telephone != 'undefined') {
          this.prferPharmaAddress = this.pharmacyMainList[i].address1 + ", " + this.pharmacyMainList[i].location + ", " + this.pharmacyMainList[i].city + " - " + this.pharmacyMainList[i].zipcode + ", " + this.pharmacyMainList[i].state + ", " + this.pharmacyMainList[i].country + " , " + this.pharmacyMainList[i].telephone;
        } else {
          this.prferPharmaAddress = this.pharmacyMainList[i].address1 + ", " + this.pharmacyMainList[i].location + ", " + this.pharmacyMainList[i].city + " - " + this.pharmacyMainList[i].zipcode + ", " + this.pharmacyMainList[i].state + ", " + this.pharmacyMainList[i].country;
        }
      }
      this.pharmacyList = [];
    }
  }

  changeIntakeSession() {
    if (this.showIntake == undefined) {
      this.toastr.error(Message_data.selectMedicine);
    }
    if (this.intakeSession == "Morning only" && this.showIntake == "1") {
      if (this.drugType == "1" || this.drugType == "2" || this.drugType == "3" || this.drugType == "4"
        || this.drugType == "6" || this.drugType == "7" || this.drugType == "8" || this.drugType == "9"
        || this.drugType == "10" || this.drugType == "11" || this.drugType == "12" || this.drugType == "13"
        || this.drugType == "15" || this.drugType == "16" || this.drugType == "17" || this.drugType == "19"
        || this.drugType == "22" || this.drugType == "23" || this.drugType == "24" || this.drugType == "28"
        || this.drugType == "30" || this.drugType == "25" || this.drugType == "29" || this.drugType == "53"
        || this.drugType == "56" || this.drugType == "57" || this.drugType == "59" || this.drugType == "61"
        || this.drugType == "65" || this.drugType == "76" || this.drugType == "222") {
        this.mor = "1";
        this.aft = "0";
        this.eve = "0";
        this.ngt = "0";
      } else if (this.drugType == "5" || this.drugType == "14" || this.drugType == "26" || this.drugType == "44") {
        if (this.clntAge != undefined) {
          var cage = parseInt(this.clntAge);
          if (cage <= 5) {
            this.mor = "2.5";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";
          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";
          } else {
            this.mor = "10";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";
          }
        }
      }
      this.morDisabledFlag = false;
      this.afternoonDisabledFlag = true;
      this.morId = false;
      this.afterId = false;
      this.eveFlag = true;
      this.nightFlag = false;
      this.durationFlag = false;
      this.morDisabledFlag = false;
      this.afternoonDisabledFlag = true;
      this.eveningDisabledFlag = true;
      this.ngtDisabledFlag = true;
      this.morFlag = false;
      this.afterEveFlag = false;
      this.quantityFlag = true;
      this.morRequiredFlag = true;
      this.afternoonRequiredFlag = false;
      this.eveningRequiredFlag = false;
      this.nightRequiredFlag = false;
      this.intakeFlag = false;
      if (this.intake != "With food") {
        this.minuteDurFlag = false;
      }
      this.mornFlag = false;
    } else if (this.intakeSession == "Afternoon only" && this.showIntake == "1") {
      if (this.drugType == "1" || this.drugType == "2" || this.drugType == "3" || this.drugType == "4"
        || this.drugType == "6" || this.drugType == "7" || this.drugType == "8" || this.drugType == "9"
        || this.drugType == "10" || this.drugType == "11" || this.drugType == "12" || this.drugType == "13"
        || this.drugType == "15" || this.drugType == "16" || this.drugType == "17" || this.drugType == "19"
        || this.drugType == "22" || this.drugType == "23" || this.drugType == "24" || this.drugType == "28"
        || this.drugType == "30" || this.drugType == "25" || this.drugType == "29" || this.drugType == "53"
        || this.drugType == "56" || this.drugType == "57" || this.drugType == "59" || this.drugType == "61"
        || this.drugType == "65" || this.drugType == "76" || this.drugType == "222") {
        this.mor = "0";
        this.aft = "1";
        this.eve = "0";
        this.ngt = "0";
      } else if (this.drugType == "5" || this.drugType == "14" || this.drugType == "26" || this.drugType == "44") {
        if (this.clntAge != undefined) {
          var cage = parseInt(this.clntAge);
          if (cage <= 5) {
            this.mor = "0";
            this.aft = "2.5";
            this.eve = "0";
            this.ngt = "0";
          } else if (cage > 5 && cage <= 13) {
            this.mor = "0";
            this.aft = "5";
            this.eve = "0";
            this.ngt = "0";
          } else {
            this.mor = "0";
            this.aft = "10";
            this.eve = "0";
            this.ngt = "0";
          }
        }
      }
      this.afternoonDisabledFlag = false;
      this.morDisabledFlag = true;
      this.eveningDisabledFlag = true;
      this.ngtDisabledFlag = true;
      this.morId = false;
      this.afterId = false;
      this.eveFlag = true;
      this.nightFlag = false;
      this.durationFlag = false;
      this.morFlag = false;
      this.afterEveFlag = false;
      this.quantityFlag = true;
      this.morRequiredFlag = false;
      this.afternoonRequiredFlag = true;
      this.eveningRequiredFlag = false;
      this.nightRequiredFlag = true;
      this.intakeFlag = false;
      if (this.intake != "With food") {
        this.minuteDurFlag = false;
      }
      this.mornFlag = false;
    } else if (this.intakeSession == "Evening only" && this.showIntake == "1") {
      if (this.drugType == "1" || this.drugType == "2" || this.drugType == "3" || this.drugType == "4"
        || this.drugType == "6" || this.drugType == "7" || this.drugType == "8" || this.drugType == "9"
        || this.drugType == "10" || this.drugType == "11" || this.drugType == "12" || this.drugType == "13"
        || this.drugType == "15" || this.drugType == "16" || this.drugType == "17" || this.drugType == "19"
        || this.drugType == "22" || this.drugType == "23" || this.drugType == "24" || this.drugType == "28"
        || this.drugType == "30" || this.drugType == "25" || this.drugType == "29" || this.drugType == "53"
        || this.drugType == "56" || this.drugType == "57" || this.drugType == "59" || this.drugType == "61"
        || this.drugType == "65" || this.drugType == "76" || this.drugType == "222") {
        this.mor = "0";
        this.aft = "0";
        this.eve = "1";
        this.ngt = "0";
      } else if (this.drugType == "5" || this.drugType == "14" || this.drugType == "26" || this.drugType == "44") {
        if (this.clntAge != undefined) {
          var cage = parseInt(this.clntAge);
          if (cage <= 5) {
            this.mor = "0";
            this.aft = "0";
            this.eve = "2.5";
            this.ngt = "0";
          } else if (cage > 5 && cage <= 13) {
            this.mor = "0";
            this.aft = "0";
            this.eve = "5";
            this.ngt = "0";
          } else {
            this.mor = "0";
            this.aft = "0";
            this.eve = "10";
            this.ngt = "0";
          }
        }
      }
      this.morDisabledFlag = true;
      this.afternoonDisabledFlag = true;
      this.eveningDisabledFlag = false;
      this.ngtDisabledFlag = true;
      this.morId = false;
      this.afterId = false;
      this.eveFlag = false;
      this.nightFlag = true;
      this.durationFlag = false;
      this.morFlag = false;
      this.afterEveFlag = false;
      this.quantityFlag = true;
      this.morRequiredFlag = false;
      this.afternoonRequiredFlag = false;
      this.eveningRequiredFlag = true;
      this.nightRequiredFlag = false;
      this.intakeFlag = false;
      if (this.intake != "With food") {
        this.minuteDurFlag = false;
      }
      this.mornFlag = false;
    } else if (this.intakeSession == "Night only" && this.showIntake == "1") {
      if (this.drugType == "1" || this.drugType == "2" || this.drugType == "3" || this.drugType == "4"
        || this.drugType == "6" || this.drugType == "7" || this.drugType == "8" || this.drugType == "9"
        || this.drugType == "10" || this.drugType == "11" || this.drugType == "12" || this.drugType == "13"
        || this.drugType == "15" || this.drugType == "16" || this.drugType == "17" || this.drugType == "19"
        || this.drugType == "22" || this.drugType == "23" || this.drugType == "24" || this.drugType == "28"
        || this.drugType == "30" || this.drugType == "25" || this.drugType == "29" || this.drugType == "53"
        || this.drugType == "56" || this.drugType == "57" || this.drugType == "59" || this.drugType == "61"
        || this.drugType == "65" || this.drugType == "76" || this.drugType == "222") {
        this.mor = "0";
        this.aft = "0";
        this.eve = "0";
        this.ngt = "1";
      } else if (this.drugType == "5" || this.drugType == "14" || this.drugType == "26" || this.drugType == "44") {
        if (this.clntAge != undefined) {
          var cage = parseInt(this.clntAge);
          if (cage <= 5) {
            this.mor = "0";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "2.5";
          } else if (cage > 5 && cage <= 13) {
            this.mor = "0";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "5";
          } else {
            this.mor = "0";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "10";
          }
        }
      }
      this.afternoonDisabledFlag = true;
      this.morDisabledFlag = true;
      this.eveningDisabledFlag = true;
      this.ngtDisabledFlag = false;
      this.morId = false;
      this.afterId = false;
      this.eveFlag = true;
      this.nightFlag = false;
      this.durationFlag = false;
      this.morRequiredFlag = false;
      this.afternoonRequiredFlag = false;
      this.eveningRequiredFlag = false;
      this.nightRequiredFlag = true;
      this.morFlag = false;
      this.afterEveFlag = false;
      this.quantityFlag = true;
      this.intakeFlag = false;
      if (this.intake != "With food") {
        this.minuteDurFlag = false;
      }
      this.mornFlag = false;
    } else if (this.intakeSession == "Morning & Afternoon" && this.showIntake == "1") {
      if (this.drugType == "1" || this.drugType == "2" || this.drugType == "3" || this.drugType == "4"
        || this.drugType == "6" || this.drugType == "7" || this.drugType == "8" || this.drugType == "9"
        || this.drugType == "10" || this.drugType == "11" || this.drugType == "12" || this.drugType == "13"
        || this.drugType == "15" || this.drugType == "16" || this.drugType == "17" || this.drugType == "19"
        || this.drugType == "22" || this.drugType == "23" || this.drugType == "24" || this.drugType == "28"
        || this.drugType == "30" || this.drugType == "25" || this.drugType == "29" || this.drugType == "53"
        || this.drugType == "56" || this.drugType == "57" || this.drugType == "59" || this.drugType == "61"
        || this.drugType == "65" || this.drugType == "76" || this.drugType == "222") {
        this.mor = "1";
        this.aft = "1";
        this.eve = "0";
        this.ngt = "0";
      } else if (this.drugType == "5" || this.drugType == "14" || this.drugType == "26" || this.drugType == "44") {
        if (this.clntAge != undefined) {
          var cage = parseInt(this.clntAge);
          if (cage <= 5) {
            this.mor = "2.5";
            this.aft = "2.5";
            this.eve = "0";
            this.ngt = "0";
          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "5";
            this.eve = "0";
            this.ngt = "0";
          } else {
            this.mor = "10";
            this.aft = "10";
            this.eve = "0";
            this.ngt = "0";
          }
        }
      }
      this.morDisabledFlag = false;
      this.afternoonDisabledFlag = false;
      this.eveningDisabledFlag = true;
      this.ngtDisabledFlag = true;
      this.morId = false;
      this.afterId = false;
      this.eveFlag = true;
      this.nightFlag = false;
      this.durationFlag = false;
      this.morRequiredFlag = true;
      this.afternoonRequiredFlag = true;
      this.eveningRequiredFlag = false;
      this.nightRequiredFlag = false;
      this.morFlag = false;
      this.afterEveFlag = false;
      this.quantityFlag = true;
      this.intakeFlag = false;
      if (this.intake != "With food") {
        this.minuteDurFlag = false;
      }
      this.mornFlag = false;
    } else if (this.intakeSession == "Morning & Evening" && this.showIntake == "1") {
      if (this.drugType == "1" || this.drugType == "2" || this.drugType == "3" || this.drugType == "4"
        || this.drugType == "6" || this.drugType == "7" || this.drugType == "8" || this.drugType == "9"
        || this.drugType == "10" || this.drugType == "11" || this.drugType == "12" || this.drugType == "13"
        || this.drugType == "15" || this.drugType == "16" || this.drugType == "17" || this.drugType == "19"
        || this.drugType == "22" || this.drugType == "23" || this.drugType == "24" || this.drugType == "28"
        || this.drugType == "30" || this.drugType == "25" || this.drugType == "29" || this.drugType == "53"
        || this.drugType == "56" || this.drugType == "57" || this.drugType == "59" || this.drugType == "61"
        || this.drugType == "65" || this.drugType == "76" || this.drugType == "222") {
        this.mor = "1";
        this.aft = "0";
        this.eve = "1";
        this.ngt = "0";
      } else if (this.drugType == "5" || this.drugType == "14" || this.drugType == "26" || this.drugType == "44") {
        if (this.clntAge != undefined) {
          var cage = parseInt(this.clntAge);
          if (cage <= 5) {
            this.mor = "0";
            this.aft = "2.5";
            this.eve = "0";
            this.ngt = "0";
          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "0";
            this.eve = "5";
            this.ngt = "0";
          } else {
            this.mor = "10";
            this.aft = "0";
            this.eve = "10";
            this.ngt = "0";
          }
        }
      }
      this.morDisabledFlag = false;
      this.afternoonDisabledFlag = true;
      this.eveningDisabledFlag = false;
      this.ngtDisabledFlag = true;
      this.morId = false;
      this.afterId = false;
      this.eveFlag = false;
      this.nightFlag = true;
      this.durationFlag = false;
      this.morRequiredFlag = true;
      this.afternoonRequiredFlag = false;
      this.eveningRequiredFlag = true;
      this.nightRequiredFlag = false;
      this.morFlag = false;
      this.afterEveFlag = false;
      this.quantityFlag = true;
      this.intakeFlag = false;
      if (this.intake != "With food") {
        this.minuteDurFlag = false;
      }
      this.mornFlag = false;
    } else if (this.intakeSession == "Morning & Night" && this.showIntake == "1") {
      if (this.drugType == "1" || this.drugType == "2" || this.drugType == "3" || this.drugType == "4"
        || this.drugType == "6" || this.drugType == "7" || this.drugType == "8" || this.drugType == "9"
        || this.drugType == "10" || this.drugType == "11" || this.drugType == "12" || this.drugType == "13"
        || this.drugType == "15" || this.drugType == "16" || this.drugType == "17" || this.drugType == "19"
        || this.drugType == "22" || this.drugType == "23" || this.drugType == "24" || this.drugType == "28"
        || this.drugType == "30" || this.drugType == "25" || this.drugType == "29" || this.drugType == "53"
        || this.drugType == "56" || this.drugType == "57" || this.drugType == "59" || this.drugType == "61"
        || this.drugType == "65" || this.drugType == "76" || this.drugType == "222") {
        this.mor = "1";
        this.aft = "0";
        this.eve = "0";
        this.ngt = "1";
      } else if (this.drugType == "5" || this.drugType == "14" || this.drugType == "26" || this.drugType == "44") {
        if (this.clntAge != undefined) {
          var cage = parseInt(this.clntAge);
          if (cage <= 5) {
            this.mor = "2.5";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "2.5";
          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "5";
          } else {
            this.mor = "10";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "10";
          }
        }
      }
      this.morDisabledFlag = false;
      this.afternoonDisabledFlag = true;
      this.eveningDisabledFlag = true;
      this.ngtDisabledFlag = false;
      this.morId = false;
      this.afterId = false;
      this.eveFlag = true;
      this.nightFlag = false;
      this.durationFlag = false;
      this.morRequiredFlag = true;
      this.afternoonRequiredFlag = false;
      this.eveningRequiredFlag = false;
      this.nightRequiredFlag = true;
      this.morFlag = false;
      this.afterEveFlag = false;
      this.quantityFlag = true;
      this.intakeFlag = false;
      if (this.intake != "With food") {
        this.minuteDurFlag = false;
      }
      this.mornFlag = false;
    } else if (this.intakeSession == "Afternoon & Night" && this.showIntake == "1") {
      if (this.drugType == "1" || this.drugType == "2" || this.drugType == "3" || this.drugType == "4"
        || this.drugType == "6" || this.drugType == "7" || this.drugType == "8" || this.drugType == "9"
        || this.drugType == "10" || this.drugType == "11" || this.drugType == "12" || this.drugType == "13"
        || this.drugType == "15" || this.drugType == "16" || this.drugType == "17" || this.drugType == "19"
        || this.drugType == "22" || this.drugType == "23" || this.drugType == "24" || this.drugType == "28"
        || this.drugType == "30" || this.drugType == "25" || this.drugType == "29" || this.drugType == "53"
        || this.drugType == "56" || this.drugType == "57" || this.drugType == "59" || this.drugType == "61"
        || this.drugType == "65" || this.drugType == "76" || this.drugType == "222") {
        this.mor = "0";
        this.aft = "1";
        this.eve = "0";
        this.ngt = "1";
      } else if (this.drugType == "5" || this.drugType == "14" || this.drugType == "26" || this.drugType == "44") {
        if (this.clntAge != undefined) {
          var cage = parseInt(this.clntAge);
          if (cage <= 5) {
            this.mor = "0";
            this.aft = "2.5";
            this.eve = "0";
            this.ngt = "2.5";
          } else if (cage > 5 && cage <= 13) {
            this.mor = "0";
            this.aft = "5";
            this.eve = "0";
            this.ngt = "5";
          } else {
            this.mor = "0";
            this.aft = "10";
            this.eve = "0";
            this.ngt = "10";
          }
        }
      }
      this.morDisabledFlag = true;
      this.afternoonDisabledFlag = false;
      this.eveningDisabledFlag = true;
      this.ngtDisabledFlag = false;
      this.morId = false;
      this.afterId = false;
      this.eveFlag = true;
      this.nightFlag = false;
      this.durationFlag = false;
      this.morRequiredFlag = false;
      this.afternoonRequiredFlag = true;
      this.eveningRequiredFlag = false;
      this.nightRequiredFlag = true;
      this.morFlag = false;
      this.afterEveFlag = false;
      this.quantityFlag = true;
      this.intakeFlag = false;
      if (this.intake != "With food") {
        this.minuteDurFlag = false;
      }
      this.mornFlag = false;
    } else if (this.intakeSession == "Afternoon & Evening" && this.showIntake == "1") {
      if (this.drugType == "1" || this.drugType == "2" || this.drugType == "3" || this.drugType == "4"
        || this.drugType == "6" || this.drugType == "7" || this.drugType == "8" || this.drugType == "9"
        || this.drugType == "10" || this.drugType == "11" || this.drugType == "12" || this.drugType == "13"
        || this.drugType == "15" || this.drugType == "16" || this.drugType == "17" || this.drugType == "19"
        || this.drugType == "22" || this.drugType == "23" || this.drugType == "24" || this.drugType == "28"
        || this.drugType == "30" || this.drugType == "25" || this.drugType == "29" || this.drugType == "53"
        || this.drugType == "56" || this.drugType == "57" || this.drugType == "59" || this.drugType == "61"
        || this.drugType == "65" || this.drugType == "76" || this.drugType == "222") {
        this.mor = "0";
        this.aft = "1";
        this.eve = "1";
        this.ngt = "0";
      } else if (this.drugType == "5" || this.drugType == "14" || this.drugType == "26" || this.drugType == "44") {
        if (this.clntAge != undefined) {
          var cage = parseInt(this.clntAge);
          if (cage <= 5) {
            this.mor = "0";
            this.aft = "2.5";
            this.eve = "2.5";
            this.ngt = "0";
          } else if (cage > 5 && cage <= 13) {
            this.mor = "0";
            this.aft = "5";
            this.eve = "5";
            this.ngt = "";
          } else {
            this.mor = "0";
            this.aft = "10";
            this.eve = "10";
            this.ngt = "0";
          }
        }
      }
      this.morDisabledFlag = true;
      this.afternoonDisabledFlag = false;
      this.eveningDisabledFlag = false;
      this.ngtDisabledFlag = true;
      this.morId = false;
      this.afterId = false;
      this.eveFlag = false;
      this.nightFlag = true;
      this.durationFlag = false;
      this.morRequiredFlag = false;
      this.afternoonRequiredFlag = true;
      this.eveningRequiredFlag = true;
      this.nightRequiredFlag = false;
      this.morFlag = false;
      this.afterEveFlag = false;
      this.quantityFlag = true;
      this.intakeFlag = false;
      if (this.intake != "With food") {
        this.minuteDurFlag = false;
      }
      this.mornFlag = false;
    } else if (this.intakeSession == "Morning,Afternoon & Evening" && this.showIntake == "1") {
      if (this.drugType == "1" || this.drugType == "2" || this.drugType == "3" || this.drugType == "4"
        || this.drugType == "6" || this.drugType == "7" || this.drugType == "8" || this.drugType == "9"
        || this.drugType == "10" || this.drugType == "11" || this.drugType == "12" || this.drugType == "13"
        || this.drugType == "15" || this.drugType == "16" || this.drugType == "17" || this.drugType == "19"
        || this.drugType == "22" || this.drugType == "23" || this.drugType == "24" || this.drugType == "28"
        || this.drugType == "30" || this.drugType == "25" || this.drugType == "29" || this.drugType == "53"
        || this.drugType == "56" || this.drugType == "57" || this.drugType == "59" || this.drugType == "61"
        || this.drugType == "65" || this.drugType == "76" || this.drugType == "222") {
        this.mor = "1";
        this.aft = "1";
        this.eve = "1";
        this.ngt = "0";
      } else if (this.drugType == "5" || this.drugType == "14" || this.drugType == "26" || this.drugType == "44") {
        if (this.clntAge != undefined) {
          var cage = parseInt(this.clntAge);
          if (cage <= 5) {
            this.mor = "2.5";
            this.aft = "2.5";
            this.eve = "2.5";
            this.ngt = "0";
          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "5";
            this.eve = "5";
            this.ngt = "";
          } else {
            this.mor = "10";
            this.aft = "10";
            this.eve = "10";
            this.ngt = "0";
          }
        }
      }
      this.morDisabledFlag = false;
      this.afternoonDisabledFlag = false;
      this.eveningDisabledFlag = false;
      this.ngtDisabledFlag = true;
      this.morId = false;
      this.afterId = false;
      this.eveFlag = false;
      this.nightFlag = true;
      this.durationFlag = false;
      this.morRequiredFlag = true;
      this.afternoonRequiredFlag = true;
      this.eveningRequiredFlag = true;
      this.nightRequiredFlag = false;
      this.morFlag = false;
      this.afterEveFlag = false;
      this.quantityFlag = true;
      this.intakeFlag = false;
      if (this.intake != "With food") {
        this.minuteDurFlag = false;
      }
      this.mornFlag = false;
    } else if (this.intakeSession == "Thrice a day" && this.showIntake == "1") {
      if (this.drugType == "1" || this.drugType == "2" || this.drugType == "3" || this.drugType == "4"
        || this.drugType == "6" || this.drugType == "7" || this.drugType == "8" || this.drugType == "9"
        || this.drugType == "10" || this.drugType == "11" || this.drugType == "12" || this.drugType == "13"
        || this.drugType == "15" || this.drugType == "16" || this.drugType == "17" || this.drugType == "19"
        || this.drugType == "22" || this.drugType == "23" || this.drugType == "24" || this.drugType == "28"
        || this.drugType == "30" || this.drugType == "25" || this.drugType == "29" || this.drugType == "53"
        || this.drugType == "56" || this.drugType == "57" || this.drugType == "59" || this.drugType == "61"
        || this.drugType == "65" || this.drugType == "76" || this.drugType == "222") {
        this.mor = "1";
        this.aft = "1";
        this.eve = "0";
        this.ngt = "1";
      } else if (this.drugType == "5" || this.drugType == "14" || this.drugType == "26" || this.drugType == "44") {
        if (this.clntAge != undefined) {
          var cage = parseInt(this.clntAge);
          if (cage <= 5) {
            this.mor = "2.5";
            this.aft = "2.5";
            this.eve = "0";
            this.ngt = "2.5";
          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "5";
            this.eve = "0";
            this.ngt = "5";
          } else {
            this.mor = "10";
            this.aft = "10";
            this.eve = "0";
            this.ngt = "10";
          }
        }
      }
      this.morDisabledFlag = false;
      this.afternoonDisabledFlag = false;
      this.eveningDisabledFlag = true;
      this.ngtDisabledFlag = false;
      this.morId = false;
      this.afterId = false;
      this.eveFlag = true;
      this.nightFlag = false;
      this.durationFlag = false;
      this.morRequiredFlag = true;
      this.afternoonRequiredFlag = true;
      this.eveningRequiredFlag = false;
      this.nightRequiredFlag = true;
      this.morFlag = false;
      this.afterEveFlag = false;
      this.quantityFlag = true;
      this.intakeFlag = false;
      if (this.intake != "With food") {
        this.minuteDurFlag = false;
      }
      this.mornFlag = false;
    } else if (this.intakeSession == "Morning,Afternoon & Night" && this.showIntake == "1") {
      if (this.drugType == "1" || this.drugType == "2" || this.drugType == "3" || this.drugType == "4"
        || this.drugType == "6" || this.drugType == "7" || this.drugType == "8" || this.drugType == "9"
        || this.drugType == "10" || this.drugType == "11" || this.drugType == "12" || this.drugType == "13"
        || this.drugType == "15" || this.drugType == "16" || this.drugType == "17" || this.drugType == "19"
        || this.drugType == "22" || this.drugType == "23" || this.drugType == "24" || this.drugType == "28"
        || this.drugType == "30" || this.drugType == "25" || this.drugType == "29" || this.drugType == "53"
        || this.drugType == "56" || this.drugType == "57" || this.drugType == "59" || this.drugType == "61"
        || this.drugType == "65" || this.drugType == "76" || this.drugType == "222") {
        this.mor = "1";
        this.aft = "1";
        this.eve = "0";
        this.ngt = "1";
      } else if (this.drugType == "5" || this.drugType == "14" || this.drugType == "26" || this.drugType == "44") {
        if (this.clntAge != undefined) {
          var cage = parseInt(this.clntAge);
          if (cage <= 5) {
            this.mor = "2.5";
            this.aft = "2.5";
            this.eve = "0";
            this.ngt = "2.5";
          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "5";
            this.eve = "0";
            this.ngt = "5";
          } else {
            this.mor = "10";
            this.aft = "10";
            this.eve = "0";
            this.ngt = "10";
          }
        }
      }
      this.morDisabledFlag = false;
      this.afternoonDisabledFlag = false;
      this.eveningDisabledFlag = true;
      this.ngtDisabledFlag = false;
      this.morId = false;
      this.afterId = false;
      this.eveFlag = true;
      this.nightFlag = false;
      this.durationFlag = false;
      this.morRequiredFlag = true;
      this.afternoonRequiredFlag = true;
      this.eveningRequiredFlag = false;
      this.nightRequiredFlag = true;
      this.morFlag = false;
      this.afterEveFlag = false;
      this.quantityFlag = true;
      this.intakeFlag = false;
      if (this.intake != "With food") {
        this.minuteDurFlag = false;
      }
      this.mornFlag = false;
    } else if (this.intakeSession == "Morning,Afternoon,Evening & Night" && this.showIntake == "1") {
      if (this.drugType == "1" || this.drugType == "2" || this.drugType == "3" || this.drugType == "4"
        || this.drugType == "6" || this.drugType == "7" || this.drugType == "8" || this.drugType == "9"
        || this.drugType == "10" || this.drugType == "11" || this.drugType == "12" || this.drugType == "13"
        || this.drugType == "15" || this.drugType == "16" || this.drugType == "17" || this.drugType == "19"
        || this.drugType == "22" || this.drugType == "23" || this.drugType == "24" || this.drugType == "28"
        || this.drugType == "30" || this.drugType == "25" || this.drugType == "29" || this.drugType == "53"
        || this.drugType == "56" || this.drugType == "57" || this.drugType == "59" || this.drugType == "61"
        || this.drugType == "65" || this.drugType == "76" || this.drugType == "222") {
        this.mor = "1";
        this.aft = "1";
        this.eve = "1";
        this.ngt = "1";
      } else if (this.drugType == "5" || this.drugType == "14" || this.drugType == "26" || this.drugType == "44") {
        if (this.clntAge != undefined) {
          var cage = parseInt(this.clntAge);
          if (cage <= 5) {
            this.mor = "2.5";
            this.aft = "2.5";
            this.eve = "2.5";
            this.ngt = "2.5";
          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "5";
            this.eve = "5";
            this.ngt = "5";
          } else {
            this.mor = "10";
            this.aft = "10";
            this.eve = "10";
            this.ngt = "10";
          }
        }
      }
      this.morDisabledFlag = false;
      this.afternoonDisabledFlag = false;
      this.eveningDisabledFlag = false;
      this.ngtDisabledFlag = false;
      this.morId = false;
      this.afterId = false;
      this.eveFlag = false;
      this.nightFlag = false;
      this.durationFlag = false;
      this.morRequiredFlag = true;
      this.afternoonRequiredFlag = true;
      this.eveningRequiredFlag = true;
      this.nightRequiredFlag = true;
      this.morFlag = false;
      this.afterEveFlag = false;
      this.quantityFlag = true;
      this.intakeFlag = false;
      if (this.intake != "With food") {
        this.minuteDurFlag = false;
      }
      this.mornFlag = false;
    } else if (this.intakeSession == "Four times a day" && this.showIntake == "1") {
      if (this.drugType == "1" || this.drugType == "2" || this.drugType == "3" || this.drugType == "4"
        || this.drugType == "6" || this.drugType == "7" || this.drugType == "8" || this.drugType == "9"
        || this.drugType == "10" || this.drugType == "11" || this.drugType == "12" || this.drugType == "13"
        || this.drugType == "15" || this.drugType == "16" || this.drugType == "17" || this.drugType == "19"
        || this.drugType == "22" || this.drugType == "23" || this.drugType == "24" || this.drugType == "28"
        || this.drugType == "30" || this.drugType == "25" || this.drugType == "29" || this.drugType == "53"
        || this.drugType == "56" || this.drugType == "57" || this.drugType == "59" || this.drugType == "61"
        || this.drugType == "65" || this.drugType == "76" || this.drugType == "222") {
        this.mor = "1";
        this.aft = "1";
        this.eve = "1";
        this.ngt = "1";
      } else if (this.drugType == "5" || this.drugType == "14" || this.drugType == "26" || this.drugType == "44") {
        if (this.clntAge != undefined) {
          var cage = parseInt(this.clntAge);
          if (cage <= 5) {
            this.mor = "2.5";
            this.aft = "2.5";
            this.eve = "2.5";
            this.ngt = "2.5";
          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "5";
            this.eve = "5";
            this.ngt = "5";
          } else {
            this.mor = "10";
            this.aft = "10";
            this.eve = "10";
            this.ngt = "10";
          }
        }
      }
      this.morDisabledFlag = false;
      this.afternoonDisabledFlag = false;
      this.eveningDisabledFlag = false;
      this.ngtDisabledFlag = false;
      this.morId = false;
      this.afterId = false;
      this.eveFlag = false;
      this.nightFlag = false;
      this.durationFlag = false;
      this.morRequiredFlag = true;
      this.afternoonRequiredFlag = true;
      this.eveningRequiredFlag = true;
      this.nightRequiredFlag = true;
      this.morFlag = false;
      this.afterEveFlag = false;
      this.quantityFlag = true;
      this.intakeFlag = false;
      if (this.intake != "With food") {
        this.minuteDurFlag = false;
      }
      this.mornFlag = false;
    } else if (this.intakeSession == "Afternoon,Evening & Night" && this.showIntake == "1") {
      if (this.drugType == "1" || this.drugType == "2" || this.drugType == "3" || this.drugType == "4"
        || this.drugType == "6" || this.drugType == "7" || this.drugType == "8" || this.drugType == "9"
        || this.drugType == "10" || this.drugType == "11" || this.drugType == "12" || this.drugType == "13"
        || this.drugType == "15" || this.drugType == "16" || this.drugType == "17" || this.drugType == "19"
        || this.drugType == "22" || this.drugType == "23" || this.drugType == "24" || this.drugType == "28"
        || this.drugType == "30" || this.drugType == "25" || this.drugType == "29" || this.drugType == "53"
        || this.drugType == "56" || this.drugType == "57" || this.drugType == "59" || this.drugType == "61"
        || this.drugType == "65" || this.drugType == "76" || this.drugType == "222") {
        this.mor = "0";
        this.aft = "1";
        this.eve = "1";
        this.ngt = "1";
      } else if (this.drugType == "5" || this.drugType == "14" || this.drugType == "26" || this.drugType == "44") {
        if (this.clntAge != undefined) {
          var cage = parseInt(this.clntAge);
          if (cage <= 5) {
            this.mor = "0";
            this.aft = "2.5";
            this.eve = "2.5";
            this.ngt = "2.5";
          } else if (cage > 5 && cage <= 13) {
            this.mor = "0";
            this.aft = "5";
            this.eve = "5";
            this.ngt = "5";
          } else {
            this.mor = "0";
            this.aft = "10";
            this.eve = "10";
            this.ngt = "10";
          }
        }
      }
      this.morDisabledFlag = true;
      this.afternoonDisabledFlag = false;
      this.eveningDisabledFlag = false;
      this.ngtDisabledFlag = false;
      this.morId = false;
      this.afterId = false;
      this.eveFlag = false;
      this.nightFlag = false;
      this.durationFlag = false;
      this.morRequiredFlag = false;
      this.afternoonRequiredFlag = true;
      this.eveningRequiredFlag = true;
      this.nightRequiredFlag = true;
      this.morFlag = false;
      this.afterEveFlag = false;
      this.quantityFlag = true;
      this.intakeFlag = false;
      if (this.intake != "With food") {
        this.minuteDurFlag = false;
      }
      this.mornFlag = false;
    } else if (this.intakeSession == "Evening & Night" && this.showIntake == "1") {
      if (this.drugType == "1" || this.drugType == "2" || this.drugType == "3" || this.drugType == "4"
        || this.drugType == "6" || this.drugType == "7" || this.drugType == "8" || this.drugType == "9"
        || this.drugType == "10" || this.drugType == "11" || this.drugType == "12" || this.drugType == "13"
        || this.drugType == "15" || this.drugType == "16" || this.drugType == "17" || this.drugType == "19"
        || this.drugType == "22" || this.drugType == "23" || this.drugType == "24" || this.drugType == "28"
        || this.drugType == "30" || this.drugType == "25" || this.drugType == "29" || this.drugType == "53"
        || this.drugType == "56" || this.drugType == "57" || this.drugType == "59" || this.drugType == "61"
        || this.drugType == "65" || this.drugType == "76" || this.drugType == "222") {
        this.mor = "0";
        this.aft = "0";
        this.eve = "1";
        this.ngt = "1";
      } else if (this.drugType == "5" || this.drugType == "14" || this.drugType == "26" || this.drugType == "44") {
        if (this.clntAge != undefined) {
          var cage = parseInt(this.clntAge);
          if (cage <= 5) {
            this.mor = "0";
            this.aft = "0";
            this.eve = "2.5";
            this.ngt = "2.5";
          } else if (cage > 5 && cage <= 13) {
            this.mor = "0";
            this.aft = "0";
            this.eve = "5";
            this.ngt = "5";
          } else {
            this.mor = "0";
            this.aft = "0";
            this.eve = "10";
            this.ngt = "10";
          }
        }
      }
      this.morDisabledFlag = true;
      this.afternoonDisabledFlag = true;
      this.eveningDisabledFlag = false;
      this.ngtDisabledFlag = false;
      this.morId = false;
      this.afterId = false;
      this.eveFlag = false;
      this.nightFlag = false;
      this.durationFlag = false;
      this.morRequiredFlag = false;
      this.afternoonRequiredFlag = false;
      this.eveningRequiredFlag = true;
      this.nightRequiredFlag = true;
      this.morFlag = false;
      this.afterEveFlag = false;
      this.quantityFlag = true;
      this.intakeFlag = false;
      if (this.intake != "With food") {
        this.minuteDurFlag = false;
      }
      this.mornFlag = false;
    } else if ((this.intakeSession == "Every 6 hours" || this.intakeSession == "Every 8 hours"
      || this.intakeSession == "Every 12 hours") && this.showIntake == "1") {
      if (this.drugType == "1" || this.drugType == "2" || this.drugType == "3" || this.drugType == "4"
        || this.drugType == "6" || this.drugType == "7" || this.drugType == "8" || this.drugType == "9"
        || this.drugType == "10" || this.drugType == "11" || this.drugType == "12" || this.drugType == "13"
        || this.drugType == "15" || this.drugType == "16" || this.drugType == "17" || this.drugType == "19"
        || this.drugType == "22" || this.drugType == "23" || this.drugType == "24" || this.drugType == "28"
        || this.drugType == "30" || this.drugType == "25" || this.drugType == "29" || this.drugType == "53"
        || this.drugType == "56" || this.drugType == "57" || this.drugType == "59" || this.drugType == "61"
        || this.drugType == "65" || this.drugType == "76" || this.drugType == "222") {
        this.mor = "1";
        this.aft = "0";
        this.eve = "0";
        this.ngt = "0";
      } else if (this.drugType == "5" || this.drugType == "14" || this.drugType == "26" || this.drugType == "44") {
        if (this.clntAge != undefined) {
          var cage = parseInt(this.clntAge);
          if (cage <= 5) {
            this.mor = "2.5";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";
          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";
          } else {
            this.mor = "10";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";
          }
        }
      }
      this.quantityFlag = false;
      this.morDisabledFlag = false;
      this.morId = true;
      this.afterId = true;
      
      this.eveFlag = true;
      this.nightFlag = true;
      this.durationFlag = false;
      this.morFlag = true;
      this.afterEveFlag = true;
      this.morRequiredFlag = true;
      this.intakeFlag = false;
      if (this.intake != "With food") {
        this.minuteDurFlag = false;
      }
      this.mornFlag = false;
    } else if (this.intakeSession == "Stat") {
      if (this.drugType == "1" || this.drugType == "2" || this.drugType == "3" || this.drugType == "4"
        || this.drugType == "6" || this.drugType == "7" || this.drugType == "8" || this.drugType == "9"
        || this.drugType == "10" || this.drugType == "11" || this.drugType == "12" || this.drugType == "13"
        || this.drugType == "15" || this.drugType == "16" || this.drugType == "17" || this.drugType == "19"
        || this.drugType == "22" || this.drugType == "23" || this.drugType == "24" || this.drugType == "28"
        || this.drugType == "30" || this.drugType == "25" || this.drugType == "29" || this.drugType == "53"
        || this.drugType == "56" || this.drugType == "57" || this.drugType == "59" || this.drugType == "61"
        || this.drugType == "65" || this.drugType == "76" || this.drugType == "222") {
        this.mor = "1";
        this.aft = "0";
        this.eve = "0";
        this.ngt = "0";
      } else if (this.drugType == "5" || this.drugType == "14" || this.drugType == "26" || this.drugType == "44") {
        if (this.clntAge != undefined) {
          var cage = parseInt(this.clntAge);
          if (cage <= 5) {
            this.mor = "2.5";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";
          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";
          } else {
            this.mor = "10";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";
          }
        }
      }
      this.quantityFlag = false;
      this.morDisabledFlag = false;
      this.morId = true;
      this.afterId = true;
      
      this.intakeFlag = true;
      this.minuteDurFlag = true;
      this.eveFlag = true;
      this.nightFlag = true;
      this.durationFlag = true;
      this.morFlag = true;
      this.afterEveFlag = true;
      this.morRequiredFlag = true;
      this.mornFlag = false;
    } else if (this.intakeSession == "Once a day") {
      if (this.drugType == "1" || this.drugType == "2" || this.drugType == "3" || this.drugType == "4"
        || this.drugType == "6" || this.drugType == "7" || this.drugType == "8" || this.drugType == "9"
        || this.drugType == "10" || this.drugType == "11" || this.drugType == "12" || this.drugType == "13"
        || this.drugType == "15" || this.drugType == "16" || this.drugType == "17" || this.drugType == "19"
        || this.drugType == "22" || this.drugType == "23" || this.drugType == "24" || this.drugType == "28"
        || this.drugType == "30" || this.drugType == "25" || this.drugType == "29" || this.drugType == "53"
        || this.drugType == "56" || this.drugType == "57" || this.drugType == "59" || this.drugType == "61"
        || this.drugType == "65" || this.drugType == "76" || this.drugType == "222") {
        this.mor = "1";
        this.aft = "0";
        this.eve = "0";
        this.ngt = "0";
      } else if (this.drugType == "5" || this.drugType == "14" || this.drugType == "26" || this.drugType == "44") {
        if (this.clntAge != undefined) {
          var cage = parseInt(this.clntAge);
          if (cage <= 5) {
            this.mor = "2.5";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";
          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";
          } else {
            this.mor = "10";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";
          }
        }
      }
      this.quantityFlag = false;
      this.morDisabledFlag = false;
      this.morId = true;
      this.afterId = true;
      
      this.eveFlag = true;
      this.nightFlag = true;
      this.durationFlag = false;
      this.morFlag = true;
      this.afterEveFlag = true;
      this.morRequiredFlag = true;
      this.intakeFlag = false;
      if (this.intake != "With food") {
        this.minuteDurFlag = false;
      }
      this.mornFlag = false;
    } else if (this.intakeSession == "SOS") {
      if (this.drugType == "1" || this.drugType == "2" || this.drugType == "3" || this.drugType == "4"
        || this.drugType == "6" || this.drugType == "7" || this.drugType == "8" || this.drugType == "9"
        || this.drugType == "10" || this.drugType == "11" || this.drugType == "12" || this.drugType == "13"
        || this.drugType == "15" || this.drugType == "16" || this.drugType == "17" || this.drugType == "19"
        || this.drugType == "22" || this.drugType == "23" || this.drugType == "24" || this.drugType == "28"
        || this.drugType == "30" || this.drugType == "25" || this.drugType == "29" || this.drugType == "53"
        || this.drugType == "56" || this.drugType == "57" || this.drugType == "59" || this.drugType == "61"
        || this.drugType == "65" || this.drugType == "76" || this.drugType == "222") {
        this.mor = "1";
        this.aft = "0";
        this.eve = "0";
        this.ngt = "0";
      } else if (this.drugType == "5" || this.drugType == "14" || this.drugType == "26" || this.drugType == "44") {
        if (this.clntAge != undefined) {
          var cage = parseInt(this.clntAge);
          if (cage <= 5) {
            this.mor = "2.5";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";
          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";
          } else {
            this.mor = "10";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";
          }
        }
      } else {
        this.mor = "0";
        this.aft = "0";
        this.eve = "0";
        this.ngt = "0";
      }
      this.quantityFlag = false;
      this.morDisabledFlag = false;
      this.morId = true;
      this.afterId = true;
      
      this.eveFlag = true;
      this.nightFlag = true;
      this.durationFlag = false;
      this.morFlag = true;
      this.afterEveFlag = true;
      this.morRequiredFlag = true;
      this.intakeFlag = false;
      if (this.intake != "With food") {
        this.minuteDurFlag = false;
      }
      this.mornFlag = false;
    } else if (this.intakeSession == "Alternative day" || this.intakeSession == "Once a week"
      || this.intakeSession == "Once in two weeks") {
      if (this.drugType == "1" || this.drugType == "2" || this.drugType == "3" || this.drugType == "4"
        || this.drugType == "6" || this.drugType == "7" || this.drugType == "8" || this.drugType == "9"
        || this.drugType == "10" || this.drugType == "11" || this.drugType == "12" || this.drugType == "13"
        || this.drugType == "15" || this.drugType == "16" || this.drugType == "17" || this.drugType == "19"
        || this.drugType == "22" || this.drugType == "23" || this.drugType == "24" || this.drugType == "28"
        || this.drugType == "30" || this.drugType == "25" || this.drugType == "29" || this.drugType == "53"
        || this.drugType == "56" || this.drugType == "57" || this.drugType == "59" || this.drugType == "61"
        || this.drugType == "65" || this.drugType == "76" || this.drugType == "222") {
        this.mor = "1";
        this.aft = "1";
        this.eve = "0";
        this.ngt = "1";
      } else if (this.drugType == "5" || this.drugType == "14" || this.drugType == "26" || this.drugType == "44") {
        if (this.clntAge != undefined) {
          var cage = parseInt(this.clntAge);
          if (cage <= 5) {
            this.mor = "2.5";
            this.aft = "0";
            this.eve = "2.5";
            this.ngt = "2.5";
          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "5";
            this.eve = "0";
            this.ngt = "5";
          } else {
            this.mor = "10";
            this.aft = "10";
            this.eve = "0";
            this.ngt = "10";
          }
        }
      }
      this.afternoonDisabledFlag = false;
      this.morDisabledFlag = false;
      this.morId = false;
      this.morFlag = false;
      this.afterId = false;
      this.eveFlag = false;
      this.nightFlag = false;
      this.durationFlag = false;
      this.eveningDisabledFlag = false;
      this.ngtDisabledFlag = false;
      this.afterEveFlag = false;
      this.quantityFlag = true;
      this.eveningRequiredFlag = true;
      this.afternoonRequiredFlag = true;
      this.morRequiredFlag = true;
      this.intakeFlag = false;
      if (this.intake != "With food") {
        this.minuteDurFlag = false;
      }
      this.mornFlag = false;
    }
  }

  changeIntake = function (this: any) {
    if (this.afterfood_txt == "After food" || this.afterfood_txt == "Before food") {
      this.dure_show = false;
      this.dure_require = true;
      this.dure_txt = "30";
    } else {
      this.dure_show = true;
      this.dure_require = false;
      this.dure_txt = undefined;
    }
  }

  save(pdffilevalue) {
    var flag = true;
    if (this.diagnosis == undefined) {
      this.toastr.error(Message_data.enterDisease);      
      flag = false;
    }
    if (flag == true && this.listProducts.length == 0) {
      this.toastr.error(Message_data.addMedDetails);
      flag = false;
    }
    var ordernow;
    if (this.orderNow == true) {
      ordernow = "1";
    } else {
      ordernow = 0;
    }
    if (flag == true) {
      if (this.personalList.sub_id.length != 0) {
        var sen_pass = JSON.stringify({
          client_reg_id: this.personalList.Client_id,
          relation_id: this.personalList.rel_id,
          sub_rel_id: this.personalList.sub_id,
          appointment_id: this.personalList.app_id,
          date: this.currentDate,
          disease: this.diagnosis,
          investigation: this.investigation,
          instructions: this.instructions,
          next_visit: this.nextVisit,
          medicines: this.listProducts,
          pharmacy_id: this.pharmaId,
          order_now: ordernow,
          language: this.language,
          pdfdoc: pdffilevalue,
          pdfip: ipaddress.Ip_with_img_address,
          print: this.printPres,
        });
      }
      else {
        var sen_pass = JSON.stringify({
          client_reg_id: this.personalList.Client_id,
          relation_id: this.personalList.rel_id,
          appointment_id: this.personalList.app_id,
          date: this.currentDate,
          disease: this.diagnosis,
          comment: this.investigation,
          investigation: this.investigation,
          instructions: this.instructions,
          next_visit: this.nextVisit,
          medicines: this.listProducts,
          pharmacy_id: this.pharmaId,
          order_now: ordernow,
          language: this.language,
          pdfdoc: pdffilevalue,
          pdfip: ipaddress.Ip_with_img_address,
          print: this.printPres,
        });
      }
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'prescription/savepres/', sen_pass,
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            if (this.homecare != "0" && this.homecare != undefined) {
              if (obj["pres_id"] != null) {
                Helper_Class.set_med_pres_id(obj["pres_id"]);
                if (this.personalList.AppFlow == "Doc_app_list") {
                  this.saveflag = false;
                  this.toastr.success(Message_data.prescriptionSavedSuccess);
                }
                else {
                  this.toastr.success(Message_data.prescriptionSavedSuccess);
                  this.getMedPresList();
                  this.addmed = false;
                }
              } else {
                this.saveflag = true;
                this.toastr.error(Message_data.unableToSavePrescription);
              }
            } else {
              Helper_Class.set_med_pres_id(null);
              if (obj["pres_id"] != undefined && obj["pres_id"] != null) {
                Helper_Class.set_med_pres_id(obj["pres_id"]);
                this.diabMedPres = obj["pres_id"];
                if (this.personalList.AppFlow == "Doc_app_list") {
                  this.saveflag = false;
                  this.toastr.success(Message_data.prescriptionSavedSuccess);
                }
                else {
                  this.toastr.success(Message_data.prescriptionSavedSuccess);
                  this.getMedPresList();
                  this.addmed = false;
                }
                var dat = this.checkPres;
                if (dat == "0") {
                  this.checkPres = "1";
                } else {
                  var seconds = 1;
                  setInterval(function () {
                    seconds--;
                  }, 500);
                }
              }
              else {
                this.saveflag = true;
                this.toastr.error(Message_data.unableToSavePrescription);
              }
            }
          },
          error => {
          }
        )
    }
  }

  getPharmacyAddress() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/gfac',
      {
        hptl_clinic_id: this.hsptlClinicId,
        type: "pharma"
      },
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          this.prferPharma = obj.hptl_name;
          if (obj.address1 != undefined) {
            this.prferPharmaAddress = obj.address1 + ", " + obj.location + ", " + obj.city + " - " + obj.zipcode + ", " + obj.state + ", " + obj.country;
          }
          this.getMedPresList();
        },
        error => {
        }
      )
  }

  changeFillType(e) {
    this.fillType = e;
    var send_data;
    var sub_rel_id = null;
    if (this.personalList.sub_id.length != 0) {
      sub_rel_id = this.personalList.sub_id;
    } else {
    }
    if (this.fillType == "refill") {
      send_data = {
        doc_reg_id: this.userInfo.user_id,
        client_age: this.clntAge,
        client_reg_id: this.personalList.Client_id,
        relation_id: this.personalList.rel_id,
        sub_rel_id: sub_rel_id
      }
      const dialogRef1 = this.dialog.open(MedPresPopupPageComponent, {
        width: '900px',
      });
      dialogRef1.afterClosed().subscribe(result => {
        if (result != null) {
          this.listProducts = result;
          this.medicineFlag = false;
          this.drugIdList = [];
          for (var i = 0; i < this.listProducts.length; i++) {
            this.drugIdList.push(this.listProducts[i].drug_id);
          }
        }
      });
    } else if (this.fillType == "autoRx") {
      this.getMedicineDetails();
    } else if (this.fillType == "previous") {
      send_data = {
        client_id: this.personalList.Client_id,
        relation_id: this.personalList.rel_id,
        sub_rel_id: this.personalList.sub_id,
        spl_name: "General",
        doc_reg_id: this.userInfo.user_id
      }
      const dialogRef2 = this.dialog.open(PreviousPrescriptionPopupComponent, {
        width: '900px',
      });
      dialogRef2.afterClosed().subscribe(result => {
        if (result != null) {
          this.listProducts = result;
          this.medicineFlag = false;
          this.drugIdList = [];
          for (var i = 0; i < this.listProducts.length; i++) {
            this.drugIdList.push(this.listProducts[i].drug_id);
          }
        }
        this.medicineFlag = false;
      });
    }
    else {
    }
  }

  getMedicineDetails() {
    var agerange;
    if (this.clntAge == "<1") {
      agerange = "<1";
    } else if (this.clntAge == "1" || this.clntAge == "2" || this.clntAge == "3") {
      agerange = "between 1 and 3 ";
    } else if (this.clntAge == "4" || this.clntAge == "5") {
      agerange = "between 4 and 5 ";
    } else if (this.clntAge == "6" || this.clntAge == "7") {
      agerange = "between 6 and 7 ";
    } else if (this.clntAge == "8" || this.clntAge == "9" || this.clntAge == "10") {
      agerange = "between 8 and 10 ";
    } else if (this.clntAge == "11" || this.clntAge == "12" || this.clntAge == "13") {
      agerange = "between 11 and 13 ";
    } else if (this.clntAge == "14" || this.clntAge == "15") {
      agerange = "between 14 and 15 ";
    } else if (this.clntAge == "16" || this.clntAge == "17" || this.clntAge == "18") {
      agerange = "between 16 and 18 ";
    } else if (this.clntAge == "19" || this.clntAge == "20" || this.clntAge == "21") {
      agerange = "between 19 and 21 ";
    } else {
      agerange = "between 21 and 120 ";
    }
    var temp;
    if (Helper_Class.getTemparature() != undefined || Helper_Class.getTemparature() != null) {
      temp = Helper_Class.getTemparature();
    }
    var bp;
    if (this.clntBp != null && this.clntBp != "" && this.clntBp != undefined) {
      bp = this.clntBp;
    }
    var cgender;
    if (this.clntAge != undefined && parseInt(this.clntAge) > 10) {
      if (this.clntGender != undefined) {
        cgender = this.personalList.Gender_data;
      }
    }
    this.symptoms = Helper_Class.getsymptoms();
    if (this.symptoms != undefined) {
      var sym = this.symptoms;
    }
    if (temp != undefined) {
      var temp_value = temp
    }
    var genflag = true;
    if (cgender != undefined && (encrypt_decript.Decript(cgender) == "Male" && this.personalList.spl == "Gynecology")) {
      genflag = false;
    } else {
      genflag = true
    }
    var gender_val = encrypt_decript.Encript(cgender).toString()
    this.symptoms = this.getSymptomData;
    if (genflag == true) {
      var send_data = {
        relation_id: this.personalList.rel_id,
        spl_name: this.personalList.spl,
        agerange: agerange,
        gender: gender_val,
        symptoms: this.symptoms,
        bp: bp,
        temparature: temp,
        diagnosis: this.diagnosis,
        clntage: this.clntAge,
      }
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'prescription/gmpbycasv/', send_data,
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            this.listProducts = [];
            if (obj != undefined && obj.pres_drug_id != undefined) {
              this.diagnosis = obj.disease;
              if (obj.drug_list.length != 0) {
                for (var i = 0; i < obj.drug_list.length; i++) {
                  if (obj.drug_list[i].show_short_form == "1") {
                    if (obj.drug_list[i].intake_desc == "Every 6 hours") {
                      this.everySix = obj.drug_list[i].morning + obj.drug_list[i].short_form + " - " + obj.drug_list[i].morning + obj.drug_list[i].short_form + " - " + obj.drug_list[i].morning + obj.drug_list[i].short_form + " - " + obj.drug_list[i].morning + obj.drug_list[i].short_form;
                    } else if (obj.drug_list[i].intake_desc == "SOS") {
                      this.everySix = obj.drug_list[i].morning + obj.drug_list[i].short_form + " SOS - if required";
                    } else if (obj.drug_list[i].intake_desc == "Thrice a day") {
                      this.everySix = obj.drug_list[i].morning + obj.drug_list[i].short_form + " - " + obj.drug_list[i].afternoon + obj.drug_list[i].short_form + " - " + obj.drug_list[i].night + obj.drug_list[i].short_form;
                    } else if (obj.drug_list[i].intake_desc == "Four times a day") {
                      this.everySix = obj.drug_list[i].morning + obj.drug_list[i].short_form + " - " + obj.drug_list[i].afternoon + obj.drug_list[i].short_form + " - " + obj.drug_list[i].evening + obj.drug_list[i].short_form + " - " + obj.drug_list[i].night + obj.drug_list[i].short_form;
                    } else {
                      if (obj.drug_list[i].evening == 0 && obj.drug_list[i].night != 0) {
                        this.everySix = obj.drug_list[i].morning + obj.drug_list[i].short_form + " - " + obj.drug_list[i].afternoon + obj.drug_list[i].short_form + " - " + obj.drug_list[i].night + obj.drug_list[i].short_form;
                      } else if (obj.drug_list[i].evening != 0 && obj.drug_list[i].night == 0) {
                        this.everySix = obj.drug_list[i].morning + obj.drug_list[i].short_form + " - " + obj.drug_list[i].afternoon + obj.drug_list[i].short_form + " - " + obj.drug_list[i].evening + obj.drug_list[i].short_form;
                      }
                    }
                    if (obj.drug_list[i].time_duration != undefined && obj.drug_list[i].time_duration != "") {
                      this.totalDuration = obj.drug_list[i].time_duration + " " + "mins";
                    } else {
                      this.totalDuration = "";
                      this.dureFlag = true;
                    }
                    if (this.intake == undefined) {
                      this.intake = "";
                    }
                    var frequency;
                    if (obj.drug_list[i].intake == "Alternative day" || obj.drug_list[i].intake == "Once a week" || obj.drug_list[i].intake == "Once in two weeks") {
                      frequency = this.intakeSession;
                    } else {
                      if (obj.drug_list[i].days == "1" && obj.drug_list[i].day_dur == "day") {
                        frequency = "";
                      } else {
                        frequency = "Daily";
                      }
                    }
                    this.listProducts.push({
                      med_typetxt: obj.drug_list[i].drug_type_name,
                      drug_id: obj.drug_list[i].drug_id,
                      drug_name: obj.drug_list[i].drug_name,
                      days: obj.drug_list[i].days,
                      period: obj.drug_list[i].day_dur,
                      drug_intake: obj.drug_list[i].drug_intake,
                      intake: obj.drug_list[i].intake,
                      every_six: this.everySix,
                      dure_txt_table: this.totalDuration,
                      time_duration: obj.drug_list[i].time_duration,
                      morning: obj.drug_list[i].morning,
                      afternoon: obj.drug_list[i].afternoon,
                      evening: obj.drug_list[i].evening,
                      drug_type_id: obj.drug_list[i].drug_type_id,
                      frequency: frequency,
                      show_intake: obj.drug_list[i].show_intake,
                    });
                  } else {
                    if (obj.drug_list[i].intake_desc == "Every 6 hours") {
                      this.everySix = obj.drug_list[i].morning + " - " + obj.drug_list[i].morning + " - " + obj.drug_list[i].morning + " - " + obj.drug_list[i].morning;
                    } else if (obj.drug_list[i].intake_desc == "SOS") {
                      this.everySix = obj.drug_list[i].morning + " SOS - if required";
                    } else if (obj.drug_list[i].intake_desc == "Thrice a day") {
                      this.everySix = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon + " - " + obj.drug_list[i].night;
                    } else if (obj.drug_list[i].intake_desc == "Four times a day") {
                      this.everySix = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon + " - " + obj.drug_list[i].evening + " - " + obj.drug_list[i].night;
                    } else {
                      if (obj.drug_list[i].evening == 0 && obj.drug_list[i].night != 0) {
                        this.everySix = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon + " - " + obj.drug_list[i].night;
                      } else if (obj.drug_list[i].evening != 0 && obj.drug_list[i].night == 0) {
                        this.everySix = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon + " - " + obj.drug_list[i].evening;
                      }
                    }
                    if (obj.drug_list[i].time_duration != undefined && obj.drug_list[i].time_duration != "") {
                      this.totalDuration = obj.drug_list[i].time_duration + " " + "mins";
                    } else {
                      this.totalDuration = "";
                      this.dureFlag = true;
                    }
                    if (this.intake == undefined) {
                      this.intake = "";
                    }
                    var frequency;
                    if (obj.drug_list[i].intake == "Alternative day" || obj.drug_list[i].intake == "Once a week" || obj.drug_list[i].intake == "Once in two weeks") {
                      frequency = this.intakeSession;
                    } else {
                      frequency = "Daily";
                    }
                    this.listProducts.push({
                      med_typetxt: obj.drug_list[i].drug_type_name,
                      drug_id: obj.drug_list[i].drug_id,
                      drug_name: obj.drug_list[i].drug_name,
                      days: obj.drug_list[i].days,
                      period: obj.drug_list[i].day_dur,
                      drug_intake: obj.drug_list[i].drug_intake,
                      intake: obj.drug_list[i].intake,
                      every_six: this.everySix,
                      dure_txt_table: this.totalDuration,
                      time_duration: obj.drug_list[i].time_duration,
                      morning: obj.drug_list[i].morning,
                      afternoon: obj.drug_list[i].afternoon,
                      evening: obj.drug_list[i].evening,
                      drug_type_id: obj.drug_list[i].drug_type_id,
                      frequency: frequency,
                      show_intake: obj.drug_list[i].show_intake,
                      remarks: obj.drug_list[i].remarks,
                    });
                  }
                }
                this.medicineFlag = false;
              }
            }
          });
    }
  }

  selectMedicineType(item) {
    this.medType = item.med_name;
    this.drugType = item.med_code;
    this.shortForm = item.short_form;
    this.show = item.show_short_form;
    this.showIntake = item.show_intake;
    this.medicalPrescriptionList = [];
    this.changeIntakeSession();
  }

  clearMedicineType() {
    this.medType = null;
    this.drugType = undefined;
    this.medicineName = null;
    this.medicineList = [];
  }

  changeDuration() {
    var duration = this.dayDuration1 + " " + this.dayDuration2;
    return duration
  }

  addMedPrescription() {
    this.saveflag = true;
    this.addmed = true;
    this.quantityFlag = true;
    this.eveFlag = true;
    this.diagnosis = "";
    this.dayDuration1 = "";
    this.listProducts = [];
    this.remark = "";
  }

  getMedPresList() {
    this.medPresList = [];
    var sendata;
    if (this.personalList.sub_id != null && this.personalList.sub_id != undefined &&
      this.personalList.sub_id != "" && this.personalList.sub_id.length != 0) {
      sendata = JSON.stringify
        ({
          client_id: this.personalList.Client_id,
          relation_id: this.personalList.rel_id,
          sub_rel_id: this.personalList.sub_id,
          home_care: this.homecare,
          spl_name: this.splname,
        });
    }
    else {
      sendata = JSON.stringify
        ({
          client_id: this.personalList.Client_id,
          relation_id: this.personalList.rel_id,
          home_care: this.homecare,
          spl_name: this.splname,
        });
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'prescription/mpbyclntid/', sendata,
      { headers: headers }).subscribe(
        response => {
          var data = response.json();
          if (data != null) {
            this.retrvMedPresListData = data.prescriptions;
            if (this.retrvMedPresListData != null) {
              for (var i = 0; i < this.retrvMedPresListData.length; i++) {
                var new_datalist = this.retrvMedPresListData[i];
                this.docnameFlag = false;
                var Docname = "";
                if (new_datalist.middle_name != undefined && new_datalist.middle_name != "") {
                  Docname = new_datalist.first_name + " " + new_datalist.middle_name + " " + new_datalist.last_name;
                }
                else {
                  Docname = new_datalist.first_name + " " + new_datalist.last_name;
                }
                var App_date = "";
                if (new_datalist.date != null) {
                  App_date = new_datalist.date.split('-')[2] + "-" + new_datalist.date.split('-')[1] + "-" + new_datalist.date.split('-')[0];
                }
                this.medPresList.push({
                  doctorname: Docname,
                  date: App_date,
                  disease: new_datalist.disease,
                  pres_drug_id: this.retrvMedPresListData[i].pres_drug_id,
                  docname_flag: this.docnameFlag
                });
              }
            }
          }
        },
        error => {
        }
      )
  }

  prescriptionGetvalue(pres_id) {
    this.prescriptionUrl = "prescription/mpdoc";
    this.listProducts = [];
    this.medViewListFlag = true;
    this.prescriptionPresId = pres_id;
    this.sendPresId = {
      pres_id: this.prescriptionPresId
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + this.prescriptionUrl,
      JSON.stringify(
        this.sendPresId
      ),
      { headers: headers }).subscribe(
        response => {
          var obj = response.json();
          this.retrvDoctorNameFlag = false;
          this.retrvPrescriptionData = obj;
          if (obj.length != 0) {
            if (obj.first_name != null && obj.first_name != undefined) {
              if (obj.middle_name != null && obj.middle_name != "") {
                this.retrvDoctorName = obj.first_name + ' ' + obj.middle_name + ' ' + obj.last_name;
              } else {
                this.retrvDoctorName = obj.first_name + ' ' + obj.last_name;
              }
              this.retrvDoctorNameFlag = true;
            }
            if (obj.qualification != null) {
              this.retrvDoctorQualify = obj.qualification;
            }
            if (obj.cl_middle_name != null && obj.cl_middle_name != "" && encrypt_decript.Decript(obj.cl_middle_name) != undefined) {
              this.retrvPatientName = encrypt_decript.Decript(obj.cl_first_name) + ' ' + encrypt_decript.Decript(obj.cl_middle_name) + ' ' + encrypt_decript.Decript(obj.cl_last_name);
            } else {
              this.retrvPatientName = encrypt_decript.Decript(obj.cl_first_name) + ' ' + encrypt_decript.Decript(obj.cl_last_name);
            }
            if (obj.doc_reg_id != null) {
              this.docRegId = obj.doc_reg_id;
            }
            this.licenceCode = obj.licence_code;
            if (obj.instructions != null && obj.instructions != undefined) {
              this.instructions = obj.instructions;
              this.instructionsFlag = false;
            } else {
              this.instructionsFlag = true;
            }
            if ((obj.hptl_name != null && obj.hptl_name != undefined) && (obj.address1 != null && obj.address1 != undefined)) {
              this.retrvHospFlag = false;
              if (obj.hptl_name != null) {
                this.retrvHsptlName = obj.hptl_name;
              }
              if ((obj.address1 != null && obj.address1 != undefined) && (obj.address2 != null && obj.address2 != undefined && obj.address2 != "")) {
                this.retrvHsptlAddress = (obj.address1.toString()) + ' ' + (obj.address2.toString() + ', ');
              } else {
                if (obj.address1 != null && obj.address1 != undefined) {
                  this.retrvHsptlAddress = (obj.address1.toString()) + ', ';
                }
              }
              if (obj.location != null) {
                this.retrvHsptlLocation = obj.location;
              }
              if (obj.city != null && obj.zipcode != null) {
                this.retrvHsptlCity = obj.city + " - " + obj.zipcode;
              }
              if (obj.state != null) {
                this.retrvHsptlState = obj.state;
              }
              if (obj.country != null) {
                this.retrvHsptlCountry = obj.country;
              }
              if (obj.telephone != null) {
                this.retrvHsptlTelephone = obj.telephone;
              }
            } else {
              this.retrvHospFlag = true;
            }
            if (obj.pharmacy_id != null) 
            {
              this.pharmacyFlag = true;
              if (obj.pharmacy_id != null) {
                this.pharmaId = obj.pharmacy_id;
              }
              if (obj.pharmacy_name != null) {
                this.pharmacyName = obj.pharmacy_name;
                this.hasPharmaFlag = true;
              } else {
                this.hasPharmaFlag = false;
              }
              if (obj.par_address1 != null) {
                this.pharAddr1 = obj.par_address1;
              }
              if (obj.par_address2 != null) {
                this.pharAddr2 = obj.par_address2;
              }
              if (obj.par_location != null) {
                this.pharLocation = obj.par_location;
              }
              if (obj.par_city != null) {
                this.pharCity = obj.par_city;
              }
              if (obj.par_zipcode != null) {
                this.pharZipcode = obj.par_zipcode;
              }
              if (obj.par_state != null) {
                this.pharState = obj.par_state;
              }
              if (obj.par_country != null) {
                this.pharCountry = obj.par_country;
              }
              if (obj.par_telephone != null) {
                this.telephone = obj.par_telephone;
              }
              this.pharmacyDetails.push({
                pharmacy_name: this.pharmacyName,
                phar_addr1: this.pharAddr1,
                phar_addr2: this.pharAddr2,
                phar_loc: this.pharLocation,
                phar_city: this.pharCity,
                phar_zipcode: this.pharZipcode,
                phar_state: this.pharState,
                phar_cnty: this.pharCountry,
                telephone: this.telephone
              })
            }
            if (obj.store_pickup != null || (obj.store_pickup != null && obj.store_pickup == "1")) {
              this.storePickUpFlag = true;
              this.delopt = true;
            }
            if (obj.home_delivery != null || (obj.home_delivery != null && obj.home_delivery == "1")) {
              this.homeDeliveryFlag = true;
              this.delopt = true;
            }
            if (obj.store_pickup_validity_days != null) {
              this.validityDays = obj.store_pickup_validity_days;
            }
            this.retrvPrescriptionDate = obj.pres_date.split('-')[2] + "-" + obj.pres_date.split('-')[1] + "-" + obj.pres_date.split('-')[0];
            this.retrvDiseaseName = obj.disease;
            this.retrvDiseaseDesc = obj.dis_desc;
            if (obj.sub_rel_id != null) {
              this.subRelId = obj.sub_rel_id;
            }
            if (obj.relation_id != null) {
              this.relId = obj.relation_id;
            }
            if (obj.relationship_name != null) {
              this.relationName = obj.relationship_name;
            }
            if (obj.available_from != null) {
              this.workTime = "Timings : " + obj.available_from + " - " + obj.available_to;
            }
            for (var i = 0; i < obj.drug_list.length; i++) {
              if (i != 0) {
                this.hasMultiTab = true;
              } else {
                this.hasMultiTab = false;
              }
              for (var i = 0; i < obj.drug_list.length; i++) {
                if (obj.drug_list[i].show_short_form == "1") {
                  var shortform = obj.drug_list[i].short_form;
                  if (obj.drug_list[i].intake == "1") {
                    this.everySix = obj.drug_list[i].morning + shortform + "-0-0";
                  } else if (obj.drug_list[i].intake == "2") {
                    this.everySix = "0 - " + obj.drug_list[i].afternoon + shortform + " - 0";
                  } else if (obj.drug_list[i].intake == "3") {
                    this.everySix = "0 - 0 - " + obj.drug_list[i].night + shortform;
                  } else if (obj.drug_list[i].intake == "4") {
                    this.everySix = obj.drug_list[i].morning + shortform + " - " + obj.drug_list[i].afternoon + shortform + " - 0";
                  } else if (obj.drug_list[i].intake == "5") {
                    this.everySix = obj.drug_list[i].morning + shortform + " - 0 - " + obj.drug_list[i].night + shortform;
                  } else if (obj.drug_list[i].intake == "6") {
                    this.everySix = "0 - " + obj.drug_list[i].afternoon + shortform + " - " + obj.drug_list[i].night + shortform;
                  } else if (obj.drug_list[i].intake == "7") {
                    this.everySix = obj.drug_list[i].morning + shortform + " - " + obj.drug_list[i].afternoon + shortform + " - " + obj.drug_list[i].night + shortform;
                  } else if (obj.drug_list[i].intake == "8") {
                    this.everySix = obj.drug_list[i].morning + shortform + " - " + obj.drug_list[i].afternoon + shortform + " - " + obj.drug_list[i].evening + shortform + " - " + obj.drug_list[i].night + shortform;
                  } else if (obj.drug_list[i].intake == "9") {
                    this.everySix = obj.drug_list[i].morning + shortform + " - Every 6 hours";
                  } else if (obj.drug_list[i].intake == "10") {
                    this.everySix = obj.drug_list[i].morning + shortform + " - Every 8 hours";
                  } else if (obj.drug_list[i].intake == "11") {
                    this.everySix = obj.drug_list[i].morning + shortform + " - Every 12 hours ";
                  } else if (obj.drug_list[i].intake == "12") {
                    this.everySix = obj.drug_list[i].morning + shortform + " - Once a day";
                  } else if (obj.drug_list[i].intake == "13") {
                    this.everySix = obj.drug_list[i].morning + shortform + " SOS - if required";
                  } else if (obj.drug_list[i].intake == "14") {
                    this.everySix = obj.drug_list[i].morning + shortform + " - " + obj.drug_list[i].afternoon + shortform + " - " + obj.drug_list[i].evening + shortform + " - " + obj.drug_list[i].night + shortform + " Alternative days";
                  } else if (obj.drug_list[i].intake == "15") {
                    this.everySix = obj.drug_list[i].morning + shortform + " - " + obj.drug_list[i].afternoon + shortform + " - " + obj.drug_list[i].evening + shortform + " - " + obj.drug_list[i].night + shortform + " Once a week";
                  } else if (obj.drug_list[i].intake == "16") {
                    this.everySix = obj.drug_list[i].morning + shortform + " - " + obj.drug_list[i].afternoon + shortform + " - " + obj.drug_list[i].evening + shortform + " - " + obj.drug_list[i].night + shortform + " Once in two weeks";
                  } else if (obj.drug_list[i].intake == "17") {
                    this.everySix = obj.drug_list[i].morning + shortform + " STAT";
                  }
                  if (obj.drug_list[i].time_duration != undefined && obj.drug_list[i].time_duration != "") {
                    this.totalDuration = obj.drug_list[i].time_duration + " " + "mins";
                  } else {
                    this.totalDuration = "";
                    this.dureFlag = true;
                  }
                  if (this.intake == undefined) {
                    this.intake = "";
                  }
                  var frequency;
                  if (obj.drug_list[i].intake == "Alternative day" || obj.drug_list[i].intake == "Once a week" || obj.drug_list[i].intake == "Once in two weeks") {
                    frequency = this.intakeSession;
                  } else {
                    frequency = "Daily";
                  }
                  this.listProducts.push({
                    med_typetxt: obj.drug_list[i].drug_type_name,
                    drug_id: obj.drug_list[i].drug_id,
                    drug_name: obj.drug_list[i].drug_name,
                    days: obj.drug_list[i].days,
                    period: obj.drug_list[i].period,
                    drug_intake: obj.drug_list[i].drug_intake,
                    intake: obj.drug_list[i].intake,
                    every_six: this.everySix,
                    dure_txt_table: this.totalDuration,
                    time_duration: obj.drug_list[i].time_duration,
                    morning: obj.drug_list[i].morning,
                    afternoon: obj.drug_list[i].afternoon,
                    evening: obj.drug_list[i].evening,
                    drug_type_id: obj.drug_list[i].drug_type_id,
                    frequency: frequency,
                    show_intake: obj.drug_list[i].show_intake,
                  });
                } else {
                  if (obj.drug_list[i].intake == "1") {
                    this.everySix = obj.drug_list[i].morning + "-0-0";
                  } else if (obj.drug_list[i].intake == "2") {
                    this.everySix = "0 - " + obj.drug_list[i].afternoon + " - 0";
                  } else if (obj.drug_list[i].intake == "3") {
                    this.everySix = "0 - 0 - " + obj.drug_list[i].night;
                  } else if (obj.drug_list[i].intake == "4") {
                    this.everySix = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon + " - 0";
                  } else if (obj.drug_list[i].intake == "5") {
                    this.everySix = obj.drug_list[i].morning + " - 0 - " + obj.drug_list[i].night;
                  } else if (obj.drug_list[i].intake == "6") {
                    this.everySix = "0 - " + obj.drug_list[i].afternoon + " - " + obj.drug_list[i].night;
                  } else if (obj.drug_list[i].intake == "7") {
                    this.everySix = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon + " - " + obj.drug_list[i].night;
                  } else if (obj.drug_list[i].intake == "8") {
                    this.everySix = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon + " - " + obj.drug_list[i].evening + " - " + obj.drug_list[i].night;
                  } else if (obj.drug_list[i].intake == "9") {
                    this.everySix = obj.drug_list[i].morning + " - Every 6 hours";
                  } else if (obj.drug_list[i].intake == "10") {
                    this.everySix = obj.drug_list[i].morning + " - Every 8 hours";
                  } else if (obj.drug_list[i].intake == "11") {
                    this.everySix = obj.drug_list[i].morning + " - Every 12 hours";
                  } else if (obj.drug_list[i].intake == "12") {
                    this.everySix = obj.drug_list[i].morning + " - Once a day";
                  } else if (obj.drug_list[i].intake == "13") {
                    this.everySix = obj.drug_list[i].morning + " SOS - if required";
                  } else if (obj.drug_list[i].intake == "14") {
                    this.everySix = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon + " - " + obj.drug_list[i].evening + " - " + obj.drug_list[i].night + " Alternative days";
                  } else if (obj.drug_list[i].intake == "15") {
                    this.everySix = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon + " - " + obj.drug_list[i].evening + " - " + obj.drug_list[i].night + " Once a week";
                  } else if (obj.drug_list[i].intake == "16") {
                    this.everySix = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon + " - " + obj.drug_list[i].evening + " - " + obj.drug_list[i].night + " Once in two weeks";
                  } else if (obj.drug_list[i].intake == "17") {
                    this.everySix = obj.drug_list[i].morning + " STAT";
                  }
                  if (obj.drug_list[i].time_duration != undefined && obj.drug_list[i].time_duration != "") {
                    this.totalDuration = obj.drug_list[i].time_duration + " " + "mins";
                  } else {
                    this.totalDuration = "";
                    this.dureFlag = true;
                  }
                  if (this.intake == undefined) {
                    this.intake = "";
                  }
                  var frequency;
                  if (obj.drug_list[i].intake == "Alternative day" || obj.drug_list[i].intake == "Once a week" || obj.drug_list[i].intake == "Once in two weeks") {
                    frequency = this.intakeSession;
                  } else {
                    frequency = "Daily";
                  }
                  this.listProducts.push({
                    med_typetxt: obj.drug_list[i].drug_type_name,
                    drug_id: obj.drug_list[i].drug_id,
                    drug_name: obj.drug_list[i].drug_name,
                    days: obj.drug_list[i].days,
                    period: obj.drug_list[i].period,
                    drug_intake: obj.drug_list[i].drug_intake,
                    intake: obj.drug_list[i].intake,
                    every_six: this.everySix,
                    dure_txt_table: this.totalDuration,
                    time_duration: obj.drug_list[i].time_duration,
                    morning: obj.drug_list[i].morning,
                    afternoon: obj.drug_list[i].afternoon,
                    evening: obj.drug_list[i].evening,
                    drug_type_id: obj.drug_list[i].drug_type_id,
                    frequency: frequency,
                    show_intake: obj.drug_list[i].show_intake,
                  });
                }
              }
              if (this.balanceQuantity == 0 && localStorage.getItem('app_nurse_physio') == undefined) {
                this.hasPharmaFlag = false; 
                this.hasSearchFlag = false;
              }
              if (Helper_Class.getapp_flow() != null && this.balanceQuantity == 0) {
                if (Helper_Class.getapp_flow() == "order") {
                  this.hasPharmaFlag = true;
                  if (this.tabletTracker.length != 0) {
                    this.hasSearchFlag = true;
                  } else {
                    this.hasSearchFlag = false;
                  }
                }
              }
            }
            if (this.flow != null && this.balanceQuantity == 0) {
              if (this.flow == "order") {
                this.hasPharmaFlag = true;
                if (this.tabletTracker.length != 0) {
                  this.hasSearchFlag = true;
                } else {
                  this.hasSearchFlag = false;
                }
              }
            }
          }
        },
        error => {
        }
      )
  }

  Back() {
    this.addmed = false;
  }

  editMedicalPres(productid, rowid) {
    for (var i = 0; i < this.listProducts.length; i++) {
      if (this.listProducts[i].drug_id == productid && this.listProducts[i].rowid == rowid) {
        this.rowid = rowid;
        this.medType = this.listProducts[i].med_typetxt;
        this.medicineName = this.listProducts[i].drug_name;
        var dayvale = this.listProducts[i].days.split(' ');
        this.dayDuration1 = dayvale[0];
        if (this.listProducts[i].period == "day" || this.listProducts[i].period == "days") {
          this.dayDuration2 = "day(s)";
        } else if (this.listProducts[i].period == "week" || this.listProducts[i].period == "weeks") {
          this.dayDuration2 = "week(s)";
        } else if (this.listProducts[i].period == "month" || this.listProducts[i].period == "month") {
          this.dayDuration2 = "month(s)";
        } else {
          this.dayDuration2 = "day(s)";
        }
        this.intake = this.listProducts[i].drug_intake;
        var mintxt = this.listProducts[i].dure_txt_table.split(' ');
        this.minuteDuration = mintxt[0];
        this.intakeId = this.listProducts[i].intake;
        this.remark = this.listProducts[i].remarks;
        if (this.intakeId == 8) {
          this.quantityFlag = false;
          this.morFlag = true;
        } else {
          this.quantityFlag = true;
          this.morFlag = false;
        }
        this.mor = this.listProducts[i].morning;
        this.aft = this.listProducts[i].afternoon;
        this.eve = this.listProducts[i].evening;
        this.ngt = this.listProducts[i].night;
        this.addMedicineFlag = true;
        if (this.listProducts[i].show_intake != undefined) {
          this.showIntake = this.listProducts[i].show_intake;
        }
        if (this.listProducts[i].short_form != undefined) {
          this.shortForm = this.listProducts[i].short_form;
        }
        this.listProducts.splice(i, 1);
        this.getMedtypeForEdit();
        break;
      }
    }
    for (var i = 0; i < this.intakeSessionList.length; i++) {
      if (this.intakeId == this.intakeSessionList[i].drug_int_id) {
        this.intakeSession = this.intakeSessionList[i].description;
        this.changeIntakeSession();
      }
    }
    if (this.listProducts.length != 0) {
      this.medicineFlag = false;
    } else {
      this.medicineFlag = true;
    }
  }

  getMedtypeForEdit() {
    var headers = new Headers();
    if (this.medType != null && this.medType != undefined && this.medType.length > 2) {
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/medtypesedit/',
        {
          medicare_id: this.medicareName,
          med_name: this.medType,
        },
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            this.medicalPrescriptionList = [];
            this.drugType = obj.med_code;
            this.shortForm = obj.short_form;
            this.show = obj.show_short_form;
            this.showIntake = obj.show_intake;
            this.changeIntake();
          },
          error => {
          }
        )
    }
  }

  printLogoWithNamePdf() {
    var tempArr = [];
    var pdffile;
    for (var i = 0; i < this.listProducts.length; i++) {
      var remarks
      if (this.listProducts[i].remarks == undefined) {
        remarks = "";
      } else {
        remarks = this.listProducts[i].remarks
      }
      tempArr.push(
        {
          Medicine: this.listProducts[i].med_typetxt + " " + this.listProducts[i].drug_name,
          Dosage: this.listProducts[i].every_six,
          Duration: this.listProducts[i].days + " " + this.listProducts[i].period,
          Frequency: this.listProducts[i].frequency + " " + this.listProducts[i].dure_txt_table + " " + this.listProducts[i].drug_intake,
          Note: remarks
        }
      );
    }
    if (this.investigation == undefined) {
      this.investigation = "";
    }
    if (this.instructions == undefined) {
      this.instructions = "";
    }
    if (this.printNextVisit == undefined) {
      this.printNextVisit = "";
    }
    let docDefinition = {
      content: [
        {
          style: 'personaltable',
          table: {
            widths: [60, 600],
            body: [
              [
                {
                  rowspan: 4,
                  image: this.imageString,
                  width: 50,
                  height: 50,
                },
                [
                  {
                    table: {
                      widths: [310, 250],
                      style: 'personaltable',
                      body: [
                        [this.hospitalName, 'Doctor: Dr.' + this.doctorName],
                        [this.hospAddress + " " + this.hospLocation, 'Date: ' + this.appointDate],
                        [this.hospCity, ' '],
                        [this.hospState, ' '],
                      ]
                    },
                    layout: 'noBorders'
                  }
                ],
              ],
            ]
          },
          layout: 'noBorders'
        },
        {
          style: 'personaltable',
          table: {
            widths: [400, 300],
            body: [
              ['Name: ' + this.clientName, 'BP: ' + this.clntBp],
              ['', ''],
              ['Age: ' + this.clntAge + '     Gender: ' + this.clntGender, ' Pulse: ' + this.clntPulse],
            ]
          },
          layout: 'noBorders'
        },
        {
          text: 'Investigation: ' + this.investigation,
          margin: [0, 20, 0, 0],
          style: 'diagstyle'
        },
        {
          text: 'Diagnosis: ' + this.diagnosis,
          margin: [0, 20, 0, 20],
          style: 'diagstyle'
        },
        this.medicineTable(tempArr, ['Medicine', 'Dosage', 'Duration', 'Frequency', 'Note']),
        {
          text: 'Instructions: ' + this.instructions,
          margin: [0, 20, 0, 20],
          style: 'diagstyle'
        },
        {
          text: 'Next visit: ' + this.printNextVisit,
          margin: [0, 20, 0, 20],
          style: 'diagstyle'
        },
        {
          text: 'Dr. ' + this.doctorName + " " + this.doctorQualif,
          margin: [0, 5, 0, 5],
          style: 'diagstyle'
        }, {
          text: 'Registration no: ' + this.licenceCode,
          margin: [0, 5, 0, 5],
          style: 'diagstyle'
        }, {
          text: 'Find your prescription online at www.tervys.com',
          margin: [0, 5, 0, 5],
          style: 'diagstyle'
        }, {
          text: 'This is digitally generated prescription',
          margin: [0, 5, 0, 5],
          style: 'diagstyle'
        }
      ],
      styles: {
        headertable: {
          fontSize: 12,
          width: 800,
          marginLeft: 120,
          marginTop: -200
        },
        personaltable: {
          fontSize: 12,
          width: 800,
        },
        diagstyle: {
          fontSize: 12,
          bold: true,
        }
      }
    };
    this.pdfObj = pdfMake.createPdf(docDefinition);
    this.pdfObj.getBuffer((buffer) => {
      var blob = new Blob([buffer], {
        type: 'application/pdf'
      });
      var reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function (this: any) {
        pdffile = reader.result;
        //this.Medical_prescription_save(pdffile);
        this.save(null);
      }.bind(this);
    });
  }

  printNoHeaderPdf() {
    var tempArr = [];
    var pdffile;
    for (var i = 0; i < this.listProducts.length; i++) {
      var remarks
      if (this.listProducts[i].remarks == undefined) {
        remarks = "";
      } else {
        remarks = this.listProducts[i].remarks
      }
      tempArr.push(
        {
          Medicine: this.listProducts[i].med_typetxt + " " + this.listProducts[i].drug_name,
          Dosage: this.listProducts[i].every_six,
          Duration: this.listProducts[i].days + " " + this.listProducts[i].period,
          Frequency: this.listProducts[i].frequency + " " + this.listProducts[i].dure_txt_table + " " + this.listProducts[i].drug_intake,
          Note: remarks
        }
      );
    }
    if (this.investigation == undefined) {
      this.investigation = "";
    }
    if (this.instructions == undefined) {
      this.instructions = "";
    }
    if (this.printNextVisit == undefined) {
      this.printNextVisit = "";
    }
    let docDefinition = {
      content: [
        {
          style: 'personaltable',
          margin: [0, 150, 0, 0],
          table: {
            widths: [400, 300],
            body: [
              ['Name: ' + this.clientName, 'BP: ' + this.clntBp],
              ['', ''],
              ['Age: ' + this.clntAge + '     Gender: ' + this.clntGender, ' Pulse: ' + this.clntPulse],
            ]
          },
          layout: 'noBorders'
        },
        {
          text: 'Investigation: ' + this.investigation,
          margin: [0, 20, 0, 0],
          style: 'diagstyle'
        },
        {
          text: 'Diagnosis: ' + this.diagnosis,
          margin: [0, 20, 0, 20],
          style: 'diagstyle'
        },
        this.medicineTable(tempArr, ['Medicine', 'Dosage', 'Duration', 'Frequency', 'Note']),
        {
          text: 'Instructions: ' + this.instructions,
          margin: [0, 20, 0, 20],
          style: 'diagstyle'
        },
        {
          text: 'Next visit: ' + this.printNextVisit,
          margin: [0, 20, 0, 20],
          style: 'diagstyle'
        },
        {
          text: 'Dr. ' + this.doctorName + " " + this.doctorQualif,
          margin: [0, 5, 0, 5],
          style: 'diagstyle'
        }, {
          text: 'Registration no: ' + this.licenceCode,
          margin: [0, 5, 0, 5],
          style: 'diagstyle'
        }, {
          text: 'Find your prescription online at www.tervys.com',
          margin: [0, 5, 0, 5],
          style: 'diagstyle'
        }, {
          text: 'This is digitally generated prescription',
          margin: [0, 5, 0, 5],
          style: 'diagstyle'
        }
      ],
      styles: {
        headertable: {
          fontSize: 12,
          width: 800,
          marginLeft: 120,
          marginTop: -200
        },
        personaltable: {
          fontSize: 12,
          width: 800,
        },
        diagstyle: {
          fontSize: 12,
          bold: true,
        }
      }
    };
    this.pdfObj = pdfMake.createPdf(docDefinition);
    this.pdfObj.getBuffer((buffer) => {
      var blob = new Blob([buffer], {
        type: 'application/pdf'
      });
      var reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function (this: any,) {
        pdffile = reader.result;
        //this.Medical_prescription_save(pdffile);
        this.save(null);
      }.bind(this);
    });
  }

  print() {
    var flag = true;
    if (this.popFlag == undefined && this.popFlag == null) {
      if (this.diagnosis == undefined) {
        this.toastr.error(Message_data.enterDisease);        
        flag = false;
      }
    }
    if (flag == true && this.listProducts.length == 0) {
      this.toastr.error(Message_data.addMedDetails);
      flag = false;
    }
    if (flag == true) {
      if (Helper_Class.get_med_pres_id() == undefined) {
        this.savePrescription("print");
      }
      var footerimg = this.hospFooter;
      var footerflag = this.printFooterFlag;
      var backimg = this.bgImage;
      let printContents, popupWin;
      if (this.printTemplate != undefined && this.printTemplate == "banner") {
        printContents = this.printbanner.nativeElement.innerHTML; 
      } else if (this.printTemplate != undefined && this.printTemplate == "logowithname") {
        printContents = this.printlogowithname.nativeElement.innerHTML; 
      } else if (this.printTemplate != undefined && this.printTemplate == "printnoheadsidebar") {
        printContents = this.printnoheadsidebar.nativeElement.innerHTML; 
      } else {
        printContents = this.printnoheader.nativeElement.innerHTML;
      }
      popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
      popupWin.document.open();
      popupWin.document.write(`
        <html>
        <head>
          <title>Prescription</title>
          <link rel="stylesheet" media="screen" href="">
          <style>
          @page { size: auto;  margin: 0mm; }
          .table_class {
            background-color: rgb(108, 180, 248);
          }
          .casesheet_container {
            width: 211px;
            padding: 1px;
            background: #277196;
            display: inline-flex;
            position: relative;
            padding-left: 5px;
            top: 21px;
            color: #FFFFFF;
          }
          .border_style{
            border:solid 1px;
            border-color: #488aff;
          }
          .width_style{
            margin-top:4px;
            width:600px;
          }
          @media print {
            body{
            }
            footer {
              position: fixed;
              bottom: 0;
            }
          }
          </style>
        </head>
        <body onload="window.print();window.onfocus=function(){ window.close();}" >${printContents}</body>
        <footer [hidden]=${footerflag}>
        <img src=${footerimg}>
      </footer>
      </html>`
      );
      popupWin.document.close();
    }
  }

  savePrescription(data) {
    if (data == "print") {
      this.save(null);
      this.printPres = "1";
    } else {
      this.printPres = "0";
      if (this.printTemplate != undefined && this.printTemplate == "banner") {
        this.bannerTemplateToPdf();
      } else if (this.printTemplate != undefined && this.printTemplate == "logowithname") {
        this.printLogoWithNamePdf();
      } else if (this.printTemplate != undefined && this.printTemplate == "printnoheadsidebar") {
      } else {
        this.printNoHeaderPdf();
      }
    }
  }

  changeLanguage() {
    if (this.language == "English") {
      this.translate.use('english');
    } else if (this.language == "Tamil") {
      this.translate.use('tamil');
    } else if (this.language == "Telugu") {
      this.translate.use('telugu');
    } else if (this.language == "Malayalam") {
      this.translate.use('malayalam');
    } else if (this.language == "Kannada") {
      this.translate.use('kannada');
    } else if (this.language == "Oriya") {
      this.translate.use('oriya');
    } else if (this.language == "Bengali") {
      this.translate.use('bangla');
    }
  }

  buildTableBody(data, columns) {
    var body = [];
    body.push(columns);
    data.forEach(function (row) {
      var dataRow = [];
      columns.forEach(function (column) {
        dataRow.push(row[column]);
      })
      body.push(dataRow);
    });
    return body;
  }

  medicineTable(data, columns) {
    return {
      table: {
        widths: [140, 60, 50, 120, 100],
        headerRows: 1,
        body: this.buildTableBody(data, columns)
      }
    };
  }

  bannerTemplateToPdf() {
    var tempArr = [];
    var pdffile;
    for (var i = 0; i < this.listProducts.length; i++) {
      var remarks
      if (this.listProducts[i].remarks == undefined) {
        remarks = "";
      } else {
        remarks = this.listProducts[i].remarks
      }
      tempArr.push(
        {
          Medicine: this.listProducts[i].med_typetxt + " " + this.listProducts[i].drug_name,
          Dosage: this.listProducts[i].every_six,
          Duration: this.listProducts[i].days + " " + this.listProducts[i].period,
          Frequency: this.listProducts[i].frequency + " " + this.listProducts[i].dure_txt_table + " " + this.listProducts[i].drug_intake,
          Note: remarks
        }
      );
    }
    if (this.investigation == undefined) {
      this.investigation = "";
    }
    if (this.instructions == undefined) {
      this.instructions = "";
    }
    if (this.printNextVisit == undefined) {
      this.printNextVisit = "";
    }
    let docDefinition = {
      content: [
        {
          image: this.imageString,
          width: 50,
          height: 50,
          margin: [-30, -20, 0, 20],
          style: 'personaltable',
          table: {
            widths: [400, 300],
            body: [
              ['Name: ' + this.clientName, 'Date: ' + this.appointDate],
              ['', ''],
              ['Age: ' + this.clntAge + '     Gender: ' + this.clntGender, 'BP: ' + this.clntBp + '     Pulse: ' + this.clntPulse],
            ]
          },
          layout: 'noBorders'
        },
        {
          style: 'personaltable',
          table: {
            widths: [400, 300],
            body: [
              ['Name: ' + this.clientName, 'Date: ' + this.appointDate],
              ['', ''],
              ['Age: ' + this.clntAge + '     Gender: ' + this.clntGender, 'BP: ' + this.clntBp + '     Pulse: ' + this.clntPulse],
            ]
          },
          layout: 'noBorders'
        },
        {
          text: 'Investigation: ' + this.investigation,
          margin: [0, 20, 0, 0],
          style: 'diagstyle'
        },
        {
          text: 'Diagnosis: ' + this.diagnosis,
          margin: [0, 20, 0, 20],
          style: 'diagstyle'
        },
        this.medicineTable(tempArr, ['Medicine', 'Dosage', 'Duration', 'Frequency', 'Note']),
        {
          text: 'Instructions: ' + this.instructions,
          margin: [0, 20, 0, 20],
          style: 'diagstyle'
        },
        {
          text: 'Next visit: ' + this.printNextVisit,
          margin: [0, 20, 0, 20],
          style: 'diagstyle'
        },
        {
          text: 'Dr. ' + this.doctorName + " " + this.doctorQualif,
          margin: [0, 5, 0, 5],
          style: 'diagstyle'
        }, {
          text: 'Registration no: ' + this.licenceCode,
          margin: [0, 5, 0, 5],
          style: 'diagstyle'
        }, {
          text: 'Find your prescription online at www.tervys.com',
          margin: [0, 5, 0, 5],
          style: 'diagstyle'
        }, {
          text: 'This is digitally generated prescription',
          margin: [0, 5, 0, 5],
          style: 'diagstyle'
        }
      ],
      styles: {
        personaltable: {
          fontSize: 12,
          width: 800,
        },
        diagstyle: {
          fontSize: 12,
          bold: true,
        }
      }
    };
    this.pdfObj = pdfMake.createPdf(docDefinition);
    this.pdfObj.getBuffer((buffer) => {
      var blob = new Blob([buffer], {
        type: 'application/pdf'
      });
      var reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function (this: any) {
        pdffile = reader.result;
        //this.Medical_prescription_save(pdffile);
        this.save(null);
      }.bind(this);
    });
  }

  selectNextVisit(e) {
    this.nextVisit = e;
    this.printNextVisit = Date_Formate(e);
  }

  getCurrentDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate',
      JSON.stringify({
        country: ipaddress.country_code.toString()
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.current_date != null) {
            this.currentTime = obj.current_time;
            this.currentYear = obj.current_date.split('-')[0];
            this.currentDate = obj.current_date;
            this.currentDate = (obj.current_date)
          }
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        }
      )
  }

  changeMedicineName(e) {
    if (this.newMed != true) {
      if (this.medicineName != null && this.medicineName.length > 2) {
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/nmeddetails/',
          {
            drug_category: this.userInfo.user_id,
            drug_name: this.medicineName,
            type : "medicine"
          },
          { headers: headers })
          .subscribe(
            response => {
              var obj = response.json();
              if (obj.med_details != null) {
                this.medicineList = [];
                this.medicineMainListData = [];
                this.medicneListData = [];
                for (var i = 0; i < obj.med_details.length; i++) {
                  var medname = obj.med_details[i].short_name + " " + obj.med_details[i].med_name
                  this.medicneListData.push(medname);
                  this.medicineMainListData.push({
                    med_id: obj.med_details[i].med_id,
                    med_name: obj.med_details[i].med_name,
                    med_type_id: obj.med_details[i].med_type_id,
                    type_name: obj.med_details[i].type_name,
                    short_name: obj.med_details[i].short_name,
                    short_form: obj.med_details[i].short_form,
                    show_short_form: obj.med_details[i].show_short_form,
                    show_intake: obj.med_details[i].show_intake,
                    medname: medname
                  })
                }
                this.medicineList = this.medicneListData.filter(function (this: any, el) {
                  return el.toLowerCase().indexOf(this.medicine_nametxt.toLowerCase()) > -1;
                }.bind(this));
                this.addMedicineFlag = true;
              }
            },
            error => {
            }
          )
      } else {
        this.medicineList = [];
      }
    } else {
      this.medId = "0";
      this.addMedicineFlag = true;
    }
  }

  showMedType(new_med) {
    this.medType = "";
    this.newMed = new_med
    if (this.newMed == true) {
      this.newMedFlag = false;
    } else {
      this.newMedFlag = true;
    }
  }

  getBase64Image(data) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'gen/gb64/', {
      imagepath: data
    },
      { headers: headers })
      .subscribe(
        response => {
          var data = JSON.parse(JSON.stringify(response));
          this.imageString = "data:image/jpeg;base64," + data.imagestr;
        });
  }

  onSelectionChanged = (event) => { }
  onContentChanged = (event) => {
    this.instructions = event.html;
    if (this.instructions != undefined) {
      this.printInstruction = this.sanitizer.bypassSecurityTrustHtml(this.instructions);
    }
  }

  getSymptoms() {
    var searchString;
    if (this.diagnosis.indexOf(',') > -1) {
      var dataval = this.diagnosis.split(",");
      searchString = dataval[dataval.length - 1];
    } else {
      searchString = this.diagnosis;
    }
    if (searchString.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'adm/gsymbn/',
        JSON.stringify({
          symp_name: searchString
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            if (obj.symptoms != null) {
              this.symptomsMainListData = [];
              this.symptomList = [];
              this.symptomListData = [];
              this.symptomsMainListData = obj.symptoms;
              for (var i = 0; i < obj.symptoms.length; i++) {
                this.symptomListData.push(obj.symptoms[i].description);
              }
              this.symptomList = this.symptomListData.filter(function (el) {
                return el.toLowerCase().indexOf(searchString.toLowerCase()) > -1;
              }.bind(this));
            }
          },
          error => {
          })
    }
  }

  selectSymptom(data) {
    for (var j = 0; j < this.symptomsMainListData.length; j++) {
      if (this.symptomsMainListData[j].description == data) {
        this.selectedSymptoms.push(data);
        break;
      }
    }
    if (this.diagnosis.indexOf(',') > -1) {
      var dataval = this.diagnosis.split(",");
      dataval.pop();
      this.diagnosis = dataval.join(",");
      this.diagnosis = this.diagnosis + "," + data;
    } else {
      this.diagnosis = data;
    }
    this.symptomList = [];
  }
}
