import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { MatDialog } from '@angular/material/dialog';
import { Client_Helper } from '../Client_helper';
import { ClientViewService } from '../clientview/client-view.service';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';

@Component({
  selector: 'app-finddonors',
  templateUrl: './finddonors.component.html',
  styleUrls: ['./finddonors.component.css']
})
export class FinddonorsComponent implements OnInit {
  public checkDonarAppList = [];
  public locationId: string;
  public locationName: string;
  public countryId: string;
  public zipcode: string;
  public locationList = [];
  public locationListData;
  public masterLocationList;
  public cityUrl: string;
  public stateUrl: string;
  public countryUrl: string;
  public cityList;
  public stateList;
  public countryList;
  public filtCity;
  public filtState;
  public filtCountry;
  public filtCityDesc;
  public filtStateDesc;
  public hptlClinicId;
  public bloodGroup;
  public hptlClinicName;
  public city;
  public state;
  public location;
  public country;
  public donationDate;
  public donorList = [];
  public retrivelDonorsListData = [];
  public saveFlag: boolean;
  public data;
  dtOptions: DataTables.Settings = {};

  constructor(public clientservice:ClientViewService,public gservice:CommonDataService,public toastr:ToastrService, public  http:Http,public  router:Router,public dialog: MatDialog) {
    this.saveFlag = true;
    this.cityUrl = ipaddress.getIp + "gen/city";
    this.stateUrl = ipaddress.getIp + "gen/state";
    this.countryUrl = ipaddress.getIp + "gen/count";
  }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 5,
      "lengthMenu": [[5, 10, 25, 50, -1], [5, 10, 25, 50, "All"]],
      "columnDefs": [
        { "orderable": false, "targets": [0, 1, 2, 3] },
      ],
      "language": {
        "search": "Filter"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12">><"table-responsive"t><"row mt-1 d-flex"<"col-xs-12 col-sm-6 col-md-4"l><"col-xs-12 col-sm-12 col-md-4 text-center sm-order-last"i><"col-xs-12 col-sm-6 col-md-4"p>>'
    };

    this.data = Client_Helper.getfinddonors();
    this.hptlClinicId =this.data.hptl_clinic_id;
    this.bloodGroup =this.data.blood_group;
    this.hptlClinicName =this.data.hptl_clinic_name;
    this.city =this.data.city;
    this.state =this.data.state;
    this.zipcode =this.data.zipcode;
    this.location =this.data.location;
    this.country =this.data.country;
    this.donationDate =this.data.donation_date;

    if (this.gservice.Client_login_detail_data.Client_Location != null) {
      this.locationName = this.gservice.Client_login_detail_data.Client_Location;
      this.getCity(this.locationName);
    }
  }

  locationChange(e) {
    this.locationName=e.target.value.toString();
    this.locationList = [];
    this.locationListData = [];
    if (this.locationName !== "" && this.locationName.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "gen/loc",
        JSON.stringify({
          loc_name: this.locationName
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            this.masterLocationList = obj.locations;
            for (var i = 0; i < this.masterLocationList.length; i++) {
              this.locationListData.push(this.masterLocationList[i]);
            }
            this.locationList = this.locationListData.filter(function (this:any,el) {
              return el.toLowerCase().indexOf(this.locationName.toLowerCase()) > -1;
            }.bind(this));
          },error => {});
    } else {
      this.locationList = [];
      this.cityList = [];
      this.stateList = [];
      this.countryList = [];
    }
  }

  selectedLocation(loc_name) {
    this.locationName = loc_name;
    this.locationList = [];
    this.getCity(this.locationName);
    this.stateList = [];
    this.cityList = [];
    this.countryList = [];
  }

  getCity(data) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.cityUrl, JSON.stringify({ loc_name: data }), { headers: headers }).subscribe(
      data => {
        if (data.json().cities != null && data.json().cities.length != 0) {
          this.cityList = data.json().cities;
          this.filtCity = this.cityList[0].city_id;
          this.filtCityDesc = this.cityList[0].city_desc;
          this.locationId = this.cityList[0].location_id;
          this.zipcode = this.cityList[0].zipcode;
          this.cityChange(this.cityList[0].city_id);
        }
      },error => {});
  }

  cityChange(data) {
    if (data != null) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.stateUrl, JSON.stringify({ city_id: data }), { headers: headers }).subscribe(
        data => {
          if (data.json().states != null && data.json().states.length != 0) {
            this.stateList = data.json().states;
            this.filtState = this.stateList[0].state_id;
            this.filtStateDesc = this.stateList[0].state_desc;
            this.stateChange(this.stateList[0].state_id);
          }
        },error => {});
    } else {
      this.stateList = [];
      this.cityList = [];
      this.countryList = [];
    }
  }

  stateChange(data) {
    if (data != null) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.countryUrl, JSON.stringify({ state_id: data }), { headers: headers }).subscribe(
        data => {
          if (data.json().countries != null && data.json().countries.length != 0) {
            this.countryList = data.json().countries;
            this.countryId = this.countryList[0].country_id;
            this.filtCountry = this.countryList[0].country_desc;
            this.getDonorDetails();
          }
        },error => {});
    } else {
      this.stateList = [];
      this.countryList = [];
    }
  }

  countryChange(data){

  }

  searchDonors() {
    this.getDonorDetails();
  }

  getDonorDetails() {
    this.donorList = [];
    var send_data_getdonor = {
      blood_group: this.bloodGroup,
      city: this.filtCity,
      location: this.locationId,
      state: this.filtState,
      country: this.countryId,
      country_desc: ipaddress.country_code
    }
    
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "bd/donors",  JSON.stringify(send_data_getdonor), { headers: headers }).subscribe(
      data => {
        this.donorList = [];
        this.retrivelDonorsListData = data.json().donors;
        if (this.retrivelDonorsListData != null) {
          var middle;
          for (var i = 0; i < this.retrivelDonorsListData.length; i++) {
            if ((this.retrivelDonorsListData[i].middle_name) != null && (this.retrivelDonorsListData[i].middle_name) != undefined) {
              middle = encrypt_decript.Decript(this.retrivelDonorsListData[i].first_name) + ' ' + encrypt_decript.Decript(this.retrivelDonorsListData[i].middle_name) + ' ' + encrypt_decript.Decript(this.retrivelDonorsListData[i].last_name);
            }else {
              middle = encrypt_decript.Decript(this.retrivelDonorsListData[i].first_name) + ' ' + encrypt_decript.Decript(this.retrivelDonorsListData[i].last_name);
            }
            this.donorList.push({
              client_reg_id: this.retrivelDonorsListData[i].client_reg_id,
              name: middle,
              mobile: encrypt_decript.Decript(this.retrivelDonorsListData[i].mobile),
              profile_image: ipaddress.Ip_with_img_address + this.retrivelDonorsListData[i].profile_image,
              check: false
            });
          }
        }
      },error => {});
  }

  checkDonar(client_reg_id, check_value) {
    if (check_value == true) {
      this.checkDonarAppList.push(client_reg_id);
    }else {
      for (var i = 0; i < this.checkDonarAppList.length; i++) {
        if (this.checkDonarAppList[i] == client_reg_id) {
          this.checkDonarAppList.splice(this.checkDonarAppList.indexOf(i), 1);
          break;
        }
      }
    }
    this.saveFlag = (this.checkDonarAppList.length != 0) ? false : true;
  }

  save() {
    var send_data = null;
    if (this.checkDonarAppList == null || (this.checkDonarAppList != null && this.checkDonarAppList.length == 0)) {
      this.toastr.error(Message_data.sltDonor)
    } else {
      if (this.hptlClinicId == "0") {
        send_data = {
          requestor: this.gservice.Client_login_detail_data.Client_Id,
          donors: this.checkDonarAppList,
          hptl_clinic_id: this.hptlClinicId,
          donation_date: this.donationDate,
          hptl_name: this.hptlClinicName,
          location: this.location,
          country: this.country,
          city: this.city,
          state: this.state,
          zipcode: this.zipcode
        }
      }else {
        send_data = {
          requestor: this.gservice.Client_login_detail_data.Client_Id,
          donors: this.checkDonarAppList,
          hptl_clinic_id: this.hptlClinicId,
          donation_date: this.donationDate        
        }
      }

      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "bd/bdsave", JSON.stringify(send_data), { headers: headers }).subscribe(
        data => {
          this.toastr.success( data.json().result)
          this.clientservice.sendMessage('')
        },error => {});
    }
  }
}
