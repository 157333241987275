<div class="row" style="margin: 0 auto; width: 100%;">
   <div class="col-12">
      <mdb-card>
         <mdb-card-header class="bg-white">
            <div class="headerCover">
               <div class="headerTilte app_list_header">
                  <h5 class="m-0" class="mainHeadingStyle">Wards</h5>
               </div>
               <div class="headerButtons">
                  <!-- <img class="saveimgbtn_inpatinfo" src="../../../assets/ui_icons/buttons/new_button.svg"
                     (click)="testCreate()" /> -->
               </div>
            </div>
         </mdb-card-header>
         <mdb-card-body>
            <div class="row">
               <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                  <mat-label class="matlabel">Ward<br>
                     <select disableOptionCentering class="ipcss inpat_width" [(ngModel)]="ward_id"
                        (ngModelChange)="getBedList($event)">
                        <option value="All">All</option>
                        <option *ngFor="let ward of ward_list" value="{{ward.ward_id}}">
                           {{ward.ward_name}}</option>
                     </select>
                  </mat-label>
               </div>
            </div>
            <div class="row">
            <div class="col-12" *ngIf="nodata_flag">
               <p class="nodata">No ward(s) found</p>
             </div>
             <div class="col-12" *ngIf="!nodata_flag">
               <div *ngFor="let ward of wardBeds" class="ward-card text-center mt-4">
                  <h1 *ngIf="ward.ward_name != undefined" style="font-size: 16px; margin-top: 12px;">{{ward.ward_name}}</h1>
                  <h1 *ngIf="ward.ward_name == undefined" style="font-size: 16px; margin-top: 12px;">{{ward_Name}}</h1>
                  <div class="row" *ngIf="ward.bed_details != undefined" style="margin-bottom: 15px;">
                     <div class="col-2 p-0" *ngFor="let bed of ward.bed_details">
                        <div style="text-align: center;" (click)="viewPatient(bed.inpatient_id, bed.mobile, bed.client_reg_id,bed, bed.first_name, bed.middle_name, bed.last_name, bed.speciality )">
                           <div class="bed_bed_no"><strong>{{bed.bed_no}}</strong></div>
                           <div><img src="../../../assets/ui_icons/bed_icon.svg" class="bed_img" >
                           </div>
                           <!-- <div><img src="../../../assets/ui_icons/bed_icon1.svg" class="bed_img" (click)="data()" >
                           </div> -->
                           <div class="row" *ngIf="ward.isselected == true">
                              <div class="col-8 p-0">
                                 <div style="border: 1px solid red;">
                                 
                                 </div>
                              </div>
                           </div>
                           <div style="font-size: 14px; color: rgb(1, 155, 1);" class="bed_bed_name d-flex justify-content-center">
                              {{bed.name}}</div>
                        </div>
                     </div>
                  
                  </div>
                  <div *ngIf="ward.bed_details == undefined">
                     <p class="nodata">No Bed(s) found</p>
                  </div>
               </div>
         </div>
         </div>
         </mdb-card-body>
      </mdb-card>
   </div>
</div>