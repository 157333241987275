import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { ipaddress } from '../../ipaddress';
import { Client_Helper } from '../Client_helper';
import { Chart } from 'chart.js';
import { Date_Formate, Time_Formate } from 'src/assets/js/common';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Helper_Class } from 'src/app/helper_class';
import { encrypt_decript } from 'src/assets/js/Encription_Decription';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Message_data } from 'src/assets/js/Message_data';
import { ClientViewService } from '../clientview/client-view.service';
@Component({
  selector: 'app-tracking-exercise',
  templateUrl: './tracking-exercise.component.html',
  styleUrls: ['./tracking-exercise.component.scss']
})
export class TrackingExerciseComponent implements OnInit {
  public exerciseTracker = [];
  public exerciseRoutine;
  public session;
  public showStartExerciseFlag:boolean = false;
  public lifeStyleId;
  public yoga;
  mm = 0;
  ss = 0;
  ms = 0;
  hh = 0;
  isRunning = false;
  public timerId;
  public chartDisplay = "none";
  public stopwatch;
  public workoutCanvas: any;
  public workoutNoonCanvas: any;
  public workoutEveCanvas: any;
  public morningChart = "block";
  public noonChart = "block";
  public eveningChart = "block";
  public counter = "";
  public variations = [];
  public chart;
  public chartData = [];
  public relationlistData = [];
  public show_relation:boolean = false;
  public relationFName;
  public relationLName;
  public relationMName;
  public userInfo;
  public relationName;
  public subRelId;
  public relationId;
  public MET;
  public weight;
  public caloriesBurned
  public activityTracks=[];
  public currentDate;
  public kaclvalueinmin
  public activityList = []; 
  public daysValue:boolean = false;
  public nodapp:boolean=false;
  public interval;
  public reset;
  public chartHeadingArray:any;
  customOptions: OwlOptions = {
   autoplay: false,
   autoplaySpeed: 1500,
   loop: true,
   pullDrag: false,
   dots: false,
   navSpeed: 750,
   navText: ['<i class="fa fa-chevron-circle-left left" aria-hidden="true" style="font-size: 50px;color: #bfbbbb;position: absolute;left: 16px;top: 46%;z-index: 1;"></i>', '<i class="fa fa-chevron-circle-right right" aria-hidden="true"></i>'],
   responsive: {
     0: {
       items: 4
     },
     400: {
       items: 6
     },
     740: {
       items: 8
     },
     940: {
       items: 9
     }
   },
   nav: true,
  }

  constructor(public gservice:CommonDataService,public http:HttpClient,public routes:ActivatedRoute,public router:Router,public toastr:ToastrService
    ,public clientservice:ClientViewService) { 
      this.session = "Morning";
      this.chartHeadingArray = ["Day","Week","Month","Quarterly","Half","Yearly"];
  }

  async ngOnInit() {
    this.userInfo = Helper_Class.getInfo();
    this.lifeStyleId = this.userInfo.life_style_id;
    await this.getRelationlist();    
    this.getCurrentTime();
  }

  getRelationlist() {
    var obj1 = Client_Helper.getrelationlistDiet();
    this.relationlistData = [];
    Client_Helper.setrelationsubrelationslide(null);
      var setData = {
        relation_id: "1",
        sub_rel_id: "",
        life_style_id: this.userInfo.life_style_id
      }
    Client_Helper.setrelationsubrelationslide(setData);
    if (obj1.relations != null && obj1.relations.length != 0) {
      this.show_relation = true;
      if (obj1.relations != null) {
        if(this.userInfo.middle_name != undefined && encrypt_decript.Decript(this.userInfo.middle_name)){
          this.relationName = encrypt_decript.Decript(this.userInfo.first_name) + " " + encrypt_decript.Decript(this.userInfo.middle_name) + " " + encrypt_decript.Decript(this.userInfo.last_name);
        }else{
          this.relationName = encrypt_decript.Decript(this.userInfo.first_name) + " " + encrypt_decript.Decript(this.userInfo.last_name);
        }            
        if (this.userInfo.profile_image != null && this.userInfo.profile_image != undefined && this.userInfo.profile_image != '') {
          imgpath = this.userInfo.profile_image;
          img = ipaddress.Ip_with_img_address + this.userInfo.profile_image;
          this.relationId = "1";
          this.subRelId = "";
        }
        this.relationlistData.push({
          rel_name: this.relationName,
          life_style_id: this.userInfo.life_style_id,
          rel_id: this.relationId,
          sub_rel_id: this.subRelId,
          prof_img: img,
          imgpath: imgpath,
        });
      }
      for (var f = 0; f < obj1.relations.length; f++) {
        var obj = obj1.relations[f];
        this.subRelId = null;
        if (obj.sub_rel_id != null) {
          this.subRelId = obj.sub_rel_id
        }
        this.relationFName = "";
        this.relationMName = "";
        this.relationLName = "";
        if (obj.middle_name != null) {
          this.relationFName = encrypt_decript.Decript(obj.first_name.toString());
          this.relationMName = encrypt_decript.Decript(obj.middle_name.toString());
          this.relationLName = encrypt_decript.Decript(obj.last_name.toString());
          this.relationName = encrypt_decript.Decript(obj.first_name.toString()) + " " + encrypt_decript.Decript(obj.middle_name.toString()) + " " + encrypt_decript.Decript(obj.last_name.toString());
        } else {
          this.relationFName = encrypt_decript.Decript(obj.first_name.toString());
          this.relationLName = encrypt_decript.Decript(obj.last_name.toString());
          this.relationName = encrypt_decript.Decript(obj.first_name.toString()) + " " + encrypt_decript.Decript(obj.last_name.toString());
        }
        var img = "../../../assets/imgs/default.jpg", imgpath = "../../../assets/imgs/default.jpg";
        if (obj.profile_image !== "default") {
          imgpath = obj.profile_image;
          img = ipaddress.Ip_with_img_address + obj.profile_image;
        }
        this.relationlistData.push({
          rel_name: this.relationName,
          rel_f_name: this.relationFName,
          rel_m_name: this.relationMName,
          rel_l_name: this.relationLName,
          rel_id: obj.relation_id,
          sub_rel_id: this.subRelId,
          gender_val: encrypt_decript.Decript(obj.gender),
          prof_img: img,
          imgpath: imgpath,
          loc_id: obj.location,
          loc_name: obj.location_desc,
          city_id: obj.city,
          city_desc: obj.city_desc,
          state_id: obj.state,
          state_desc: obj.state_desc,
          country_id: obj.country,
          country_desc: obj.country_desc,
          update_flag: true,
          life_style_id: obj.life_style_id,
        });
      }
    }
  }

  getCurrentTime() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "adm/curdate", { country: "IN" }, { headers: headers }).subscribe(
      data => {
          var obj = JSON.parse(JSON.stringify(data));
          this.currentDate = obj.current_date;
          var current_time = obj.current_time.split(":");
          if(current_time[0] >= 3 && current_time[0] < 12){
            this.session = "Morning";
          }else if(current_time[0] >= 12 && current_time[0] < 16){
            this.session = "Afternoon";
          }else if(current_time[0] >= 16 && current_time[0] < 24){
            this.session = "Evening";    
          }
          this.getActivity();
        },
      error => {})
  }

  getActivity() {
    this.activityList = [];
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'lifestyle/gat',
      { headers: headers })
      .subscribe(
       response => {
          this.activityList = [];
          var obj = JSON.parse(JSON.stringify(response));
          if(obj != null){           
            for (var i = 0; i < obj.activity_list.length; i++) { 
              for (var j = 0; j <obj.activity_list[i].activity_details.length; j++) {             
              this.activityList.push({
                activitytypeid: obj.activity_list[i].activity_type_id,
                activity_id: obj.activity_list[i].activity_details[j].activity_id,
                description: obj.activity_list[i].description,
                checked: false,
                act_type_desc :obj.activity_list[i].activity_details[0].activity_id,
                act_desc: obj.activity_list[i].activity_details,                
                exercise_duration : "10 mins",
                session : this.session,
                activity_name: obj.activity_list[i].activity_details[j].activity_name,
                exerc_image: ipaddress.Ip_with_img_address + obj.activity_list[i].image,
              }); 
             }
         }
          this.getExerciseTracker();
          }
        },
        error => { }
      )
  }

  getExerciseTracker() {  
    this.exerciseTracker = [];
    var send_data = {
      life_style_id:this.lifeStyleId,
      session:this.session,
    }   
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'lifestyle/gac', (send_data),
      { headers: headers })
      .subscribe(
        response => {
          var obj =JSON.parse(JSON.stringify(response));
          if(obj != undefined){
            this.showStartExerciseFlag=false;
              if(obj.activity_details != undefined){
                for(var i = 0; i < obj.activity_details.length; i++){ 
                  var res = this.activityList.filter(o=> o.activity_id == obj.activity_details[i].activity_id);
                  if(res.length != 0){
                    var sec_to_min = obj.activity_details[i].activity_dur.split(":");
                    var durationvalue=parseFloat(sec_to_min[0])+"."+parseFloat(sec_to_min[1])+"."+parseFloat(sec_to_min[2]);
                    var getdatavalue=parseFloat(sec_to_min[1]);
                    var getdatavaluehr=parseFloat(sec_to_min[0]);
                    var getdata1=(sec_to_min[2]);   
                    if(sec_to_min[0] != "00"  ){
                      var floatvaluefixed = (getdatavaluehr + getdatavalue) * 60;                              
                    }else{
                      var floatvaluefixed = parseFloat(sec_to_min[1]);
                    }
                  this.exerciseTracker.push({
                    activity_id: obj.activity_details[i].activity_id,
                    description: obj.activity_details[i].act_type_name,
                    checked: false,
                    activity_dur : floatvaluefixed,
                    session :obj.activity_details[i].session,
                    met_value:obj.activity_details[i].met_value,
                    activity_name :obj.activity_details[i].activity_name,
                    act_type_desc : obj.activity_details[i].activity_id,
                    act_desc: res[0].act_desc,
                    activity_type_id: obj.activity_details[i].activity_type_id,
                    exerc_image: ipaddress.Ip_with_img_address + obj.activity_details[i].image
                   });
                  }                     
                }
              }           
          }
          else{
            this.daysValue=true;
          }
        },
        error => {});
  }

  getChart(value){
    var send_data = {
      life_style_id:this.lifeStyleId,
      interval:value,
      created_date:(this.currentDate),
    }
    this.activityTracks=[];
    this.chartData = [];
    if(this.workoutCanvas){
      this.workoutCanvas.destroy();
    }
    if(this.workoutNoonCanvas){
      this.workoutNoonCanvas.destroy();
    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'lifestyle/gact', (send_data),
      { headers: headers })
      .subscribe(
        response => {   
          var obj = JSON.parse(JSON.stringify(response));
          if(obj != null){    
            if(obj.activity_tracks.length != 0){
              for( var i=0; i<obj.activity_tracks.length;i++){
                this.activityTracks.push({
                  activity_type_id:obj.activity_tracks[i].activity_type_id,
                  act_type_name:obj.activity_tracks[i].act_type_name,
                  activity_id:obj.activity_tracks[i].activity_id,
                  activity_name:obj.activity_tracks[i].activity_name,
                  exercise_dur:obj.activity_tracks[i].activity_dur,
                  session:obj.activity_tracks[i].session,
                  kcal:obj.activity_tracks[i].kcal,
                  created_date:obj.activity_tracks[i].created_date,
                  created_time:obj.activity_tracks[i].created_time,
                  exerc_image:obj.activity_tracks[i].image,
                });
              }           
              if(obj.activity_tracks.length != 0){
                var morningData = [];
                var noonData = [];
                var nightData = [];
                var xAxis = [];
                var noon_xAxis = [];
                var eve_xAxis = [];
                this.chartData = [];
                if(this.activityTracks != undefined){    
                    var  exer = Client_Helper.getExerciseTracker() ; 
                    for(var i = 0; i < this.activityTracks.length; i++){
                      if(this.activityTracks[i].session == "Morning"){
                          if(exer.activity_id == this.activityTracks[i].activity_id){
                            var duration = this.activityTracks[i].exercise_dur.split(":");
                            var duration2 = parseFloat(duration[0])+ "." +duration[1];
                            this.chartData.push({
                              exercise_dur: this.activityTracks[i].exercise_dur,
                              activity_id:this.activityTracks[i].activity_id,
                              date:Date_Formate(this.activityTracks[i].created_date),
                              exerc_image: ipaddress.Ip_with_img_address + this.activityTracks[i].exerc_image,
                              created_time:Time_Formate(this.activityTracks[i].created_time),
                              kcal:this.activityTracks[i].kcal,
                            });
                            morningData[i] = duration[0] + duration[1] + duration[2];
                            var created_date = this.activityTracks[i].created_date.split("-");
                            xAxis[i] = (created_date[2]+ " - " + created_date[1]+ " - " + created_date[0]);
                          }
                      }else if(this.activityTracks[i].session == "Afternoon"){
                          if(exer.activity_id == this.activityTracks[i].activity_id){
                            var duration =this.activityTracks[i].exercise_dur.split(":");
                            var duration2 = parseFloat(duration[0])+ "." + duration[1];
                            this.chartData.push({
                              exercise_dur:this.activityTracks[i].exercise_dur,
                              activity_id:this.activityTracks[i].activity_id,
                              date:Date_Formate(this.activityTracks[i].created_date),
                              exerc_image: ipaddress.Ip_with_img_address + this.activityTracks[i].exerc_image,
                              created_time:Time_Formate(this.activityTracks[i].created_time),
                              kcal:this.activityTracks[i].kcal,
                            });
                            noonData[i] = duration[0] + duration[1] + duration[2];
                            var created_date = this.activityTracks[i].created_date.split("-");
                            xAxis[i] = (created_date[2]+ " - " + created_date[1]+ " - " + created_date[0]); 
                          }
                      }else if(this.activityTracks[i].session == "Evening"){
                          if( exer.activity_id == this.activityTracks[i].activity_id ){
                            this.eveningChart = "block";
                            var duration = (this.activityTracks[i].exercise_dur.toString()).split(":");
                            var duration2 = parseFloat(duration[0]) + "." + duration[1];
                            var time = parseFloat(duration[0]) + ":" + duration[1];
                            this.chartData.push({
                              exercise_dur:this.activityTracks[i].exercise_dur,
                              activity_id:this.activityTracks[i].activity_id,
                              date:Date_Formate(this.activityTracks[i].created_date),
                              exerc_image: ipaddress.Ip_with_img_address + this.activityTracks[i].exerc_image,
                              created_time:Time_Formate(this.activityTracks[i].created_time),
                              kcal:this.activityTracks[i].kcal,
                            });
                            nightData[i] = duration[0] + duration[1] + duration[2];
                            var created_date = this.activityTracks[i].created_date.split("-");
                            xAxis[i] = (created_date[2]+ " - " + created_date[1]+ " - " + created_date[0]); 
                          }
                        }
                   }
                    if(this.workoutCanvas){
                      this.workoutCanvas.destroy();
                    }
                    if(this.workoutNoonCanvas){
                      this.workoutNoonCanvas.destroy();
                    }
                    let options:any;
                    options = {
                      responsive: true,
                      maintainAspectRatio: false,
                      legend: {
                        position: "top" 
                      },
                      layout: {
                        padding: 15,
                      },
                      scales: {
                        xAxes: [
                          {
                            barThickness: 6,  
                            maxBarThickness: 8,
                            stacked: true,
                            gridLines: {
                              display: true,
                            },
                            ticks: {
                              display: true,
                              fontSize: 10,
                            },
                          },
                        ],
                        yAxes: [
                          {
                            ticks: {
                              display: true,
                              fontSize: 12,
                              beginAtZero: true,
                              suggestedMin: 0,
                              userCallback: (v) => this.epochToHhMmSs(v),
                              padding: 10,
                              stepSize:600, 
                            },
                          },
                        ],
                      },
                      tooltips: {
                        callbacks: {
                          label: (tooltipItem, data:any) => {
                            return data.datasets[tooltipItem.datasetIndex].label + ': ' + this.epochToHhMmSs(tooltipItem.yLabel);
                          }
                        }
                      },
                    };
                    var ctx = <HTMLCanvasElement>document.getElementById("workoutCanvas");
                    this.workoutCanvas = new Chart(ctx, {
                      type: 'bar',
                      labels: ["Morning","Afternoon","Evening"],
                      data: {
                        labels: xAxis,
                        datasets: [
                          {
                            data: morningData,
                            backgroundColor: "#01452c",
                            borderWidth: 1,
                            label:"Morning"
                          },{
                            data: noonData,
                            backgroundColor: "#ff8b00",
                            borderWidth: 1,
                            label:"Afternoon"
                          },{
                            data: nightData,
                            backgroundColor: "#b00505",
                            borderWidth: 1,
                            label:"Evening"
                          }]
                      },
                      options:options,
                    });
                    var bgColor = ["#878BB6", "#FFEA88", "#FF8153", "#4ACAB4", "#c0504d", "#8064a2", "#772c2a", "#f2ab71", "#2ab881", "#4f81bd", "#2c4d75"];
                    var ctx = <HTMLCanvasElement>document.getElementById("workoutNoonCanvas");
                    this.workoutNoonCanvas = new Chart(ctx, {
                      type: 'line',
                      labels: ["Morning","Afternoon","Evening"],
                      data: {
                        labels: xAxis,
                        datasets: [
                          {
                            data: morningData,
                            backgroundColor: "#01452c",
                            borderColor: "#01452c",
                            fill: false,
                            borderWidth: 2,
                            spanGaps: true,
                            label:"Morning"
                          },{
                            data: noonData,
                            backgroundColor: "#ff8b00",
                            borderColor: "#ff8b00",
                            fill: false,
                            borderWidth: 2,
                            spanGaps: true,
                            label:"Afternoon"
                          },{
                            data: nightData,
                            backgroundColor: "#b00505",
                            borderColor: "#b00505",
                            fill: false,
                            borderWidth: 2,
                            spanGaps: true,
                            label:"Evening"
                          }]
                      },
                     options:options,
                    });
                    var bgColor = ["#878BB6", "#FFEA88", "#FF8153", "#4ACAB4", "#c0504d", "#8064a2", "#772c2a", "#f2ab71", "#2ab881", "#4f81bd", "#2c4d75"];
                    var ctx = <HTMLCanvasElement>document.getElementById("workoutEveCanvas");
                    this.workoutEveCanvas = new Chart(ctx, {
                      type: 'bar',
                      data: {
                        labels: eve_xAxis,
                        datasets: [
                          {
                            data: nightData,
                            backgroundColor: bgColor,
                            options: options,
                            label:"Evening"
                          }]
                      }
                    });
                }
              }         
            }else{
              this.nodapp=true;
              this.activityTracks=[];
              this.chartData = [];
              this.morningChart = "none";
              this.noonChart = "none";
            }
          }else{
            this.nodapp=true;
            this.activityTracks=[];
            this.chartData = [];
            this.morningChart = "none";
            this.noonChart = "none";
          }
        });
  }

  selectExercisePattern(event,exer){
    Client_Helper.setExerciseTracker(null)  
    Client_Helper.setExerciseTracker(exer)  
    this.counter = "";
    this.chartDisplay = "block";
    if(event == true){
      for (var i = 0; i < this.exerciseTracker.length; i++) {
        if (exer.session == this.exerciseTracker[i].session) {       
          if (exer.met_value == this.exerciseTracker[i].met_value) {
            this.MET  = this.exerciseTracker[i].met_value ;  
             this.exerciseTracker[i].checked = true;
          }
        }       
       }     
      this.counter = exer.exercise_dur;    
      this.startExercise();
      this.clickHandler();      
    }else{
      for(var i = 0; i < this.exerciseTracker.length; i++){
        if(exer.activity_id == this.exerciseTracker[i].activity_id){
          this.exerciseTracker[i].checked = false;
        }
      }
      this.counter = "";
    }
  }

  startExercise(){  
    if(this.counter == ""){
      this.toastr.error(Message_data.sltExercise);
    }else{
    this.chartDisplay = "block";
      this.showStartExerciseFlag = true;
      this.daysValue = true;
    }
    this.mm = 0;
    this.ms = 0;
    this.ss = 0;
    this.hh = 0;
    this.changeChartHeadingData("Day");
  }

  clickHandler() {
    if (!this.isRunning) {
      this.timerId = setInterval(() => {
        this.ms++;
        if (this.ms >= 100) {
          this.ss++;
          this.ms = 0;
        }
        if (this.ss >= 60) {
          this.mm++;
          this.ss = 0
        }
        if (this.mm >= 60) {         
          this.hh++;
          this.mm = 0
        }
      }, 10);
    } else {
      clearInterval(this.timerId);
    }
    this.isRunning = !this.isRunning;
    var ss = (this.ss < 10 ? '0' : '') + this.ss;
    var mm = (this.mm < 10 ? '0' : '') + this.mm;
    var hh = (this.mm < 10 ? '0' : '') + this.hh;
    this.stopwatch =  hh.trim() + ":" + mm.trim()  + ":" + ss.trim(); 
    var sec_to_min= this.stopwatch.split(":");
    var durationvalue=parseFloat(sec_to_min[0])+"."+parseFloat(sec_to_min[1])+"."+parseFloat(sec_to_min[2]);
    var getdatavalue=parseFloat(sec_to_min[1]);
    var getdatavaluehr=parseFloat(sec_to_min[0]);
    var getdata1=(sec_to_min[2]);   
    if(sec_to_min[1] != null  ){
      var data=((getdata1) )/60;     
      var floatvaluefixed=(getdatavalue +data)*60;
      this.kaclvalueinmin=(getdatavaluehr+floatvaluefixed);
    }
    this.reset = this.isRunning == true ? false : true;
  }
  
  resetTimer() {
    clearInterval(this.timerId);
    if(this.reset == false){
        var ss = (this.ss < 10 ? '0' : '') + this.ss;
        var mm = (this.mm < 10 ? '0' : '') + this.mm;
        var hh = (this.mm < 10 ? '0' : '') + this.hh;
        this.stopwatch =  hh.trim() + ":" + mm.trim()  + ":" + ss.trim(); 
        var sec_to_min= this.stopwatch.split(":");
        var durationvalue=parseFloat(sec_to_min[0])+"."+parseFloat(sec_to_min[1])+"."+parseFloat(sec_to_min[2]);
        var getdatavalue=parseFloat(sec_to_min[1]);
        var getdatavaluehr=parseFloat(sec_to_min[0]);
        var getdata1=(sec_to_min[2]);   
        if(sec_to_min[1] != null  ){
          var data=((getdata1) )/60;     
          var floatvaluefixed=(getdatavalue +data)*60;
          this.kaclvalueinmin=(getdatavaluehr+floatvaluefixed);
      }
      this.save();
    }else{
      this.mm = 0;
      this.ms = 0;
      this.ss = 0;
      clearInterval(this.timerId);
      var ss = (this.ss < 10 ? '0' : '') + this.ss;
      var mm = (this.mm < 10 ? '0' : '') + this.mm;
      var hh = (this.hh < 10 ? '0' : '') + this.mm;
      this.stopwatch =hh + " : " +  mm + " : " + ss;
    }
  }

  format(num: number) {
    return (num + '').length === 1 ? '0' + num : num + '';
  }

  changeChartHeadingData(id){  
    $("#"+id).removeClass("active");
    $("#"+id).addClass("active");
    if(id == "Day"){
      this.morningChart = "block";
      this.noonChart = "none";
    }else{
      this.morningChart = "none";
      this.noonChart = "block";
    }
    switch (id) {      
      case 'Day': 
      this.interval = "0";
      this.getChart(this.interval);
      break;
      case 'Week': 
      this.interval="6";
      this.getChart(this.interval);
      break;
      case 'Month': 
      this.interval="30";
      this.getChart(this.interval);
      break;
      case 'Quarterly': 
      this.interval="90";
      this.getChart(this.interval);
        break;
      case 'Half': 
      this.interval="120";
      this.getChart(this.interval);
        break;
      case 'Yearly': 
      this.interval="120";
      this.getChart(this.interval);       
        break;
    }
  }

  changeRelation(name, rel_id, sub_rel_id, life_style_id) {
    this.lifeStyleId = life_style_id;
    Client_Helper.setrelationsubrelationslide(null);
    var setData = {
      relation_id: rel_id,
      sub_rel_id: sub_rel_id,
      life_style_id: life_style_id
    }
    Client_Helper.setrelationsubrelationslide(setData);
    this.getCurrentTime();
  }

  back(){
    this.showStartExerciseFlag = !this.showStartExerciseFlag;
    this.chartDisplay = 'none';
    this.morningChart = 'none';
    this.noonChart = 'none';
    this.eveningChart = 'none';
    this.mm = 0;
    this.ss = 0;
    this.ms = 0;
    this.hh = 0;
    this.reset = false;
    this.isRunning = false;
    clearInterval(this.timerId);
    for(var i = 0; i < this.exerciseTracker.length; i++){
      if(this.exerciseTracker[i].checked == true){
        this.exerciseTracker[i].checked = false;
      }
    }
    for(var j = 0 ; j < this.chartHeadingArray.length; j++){
      $("#"+this.chartHeadingArray[j]).removeClass("active"); 
    }
  }

  save(){
    var send_data;
    if(this.userInfo.weight != null){
      if(this.userInfo.weight_measure == "kgs"){
        this.weight=this.userInfo.weight;
      }
      else if(this.userInfo.weight_measure == "lbs"){
        var weightvalue=((this.userInfo.weight_measure)* 1.6) 
        this.weight=weightvalue;
      }
    }
    this.caloriesBurned = Math.round((((this.MET * 3.5) * (this.weight/200))* (this.kaclvalueinmin)));
    for(var i = 0; i < this.exerciseTracker.length; i++){
      if(this.exerciseTracker[i].checked == true){
        if(this.exerciseTracker[i].activity_id != "2"){
          send_data = {
            life_style_id:this.lifeStyleId,
            activity_type_id: this.exerciseTracker[i].activity_type_id,
            activity_id:this.exerciseTracker[i].activity_id,
            activity_dur:this.stopwatch,
            exercise_routine:this.exerciseTracker[i].exercise_routine,
            session:this.exerciseTracker[i].session,
            kcal_burned:this.caloriesBurned
          }
        }
      }
    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'lifestyle/sact',(send_data),
      { headers: headers })
      .subscribe(
        response => {          
          var obj =JSON.parse(JSON.stringify(response));
          Client_Helper.setActivityActualDuration(undefined);
          if(obj.key == "1"){
            if(Client_Helper.getrelationsubrelationslide().relation_id == "1"){
              Client_Helper.setActivityActualDuration(
                {
                  act_duration:send_data.activity_dur,
                  kcal_burned:send_data.kcal_burned
                });
            }
            this.toastr.success(obj.result);
            this.chartDisplay = 'none';
            this.morningChart = 'none';
            this.noonChart = 'none';
            this.eveningChart = 'none';
            this.showStartExerciseFlag = false;
            this.getChart("0");
            this.mm = 0;
            this.ss = 0;
            this.ms = 0;
            this.hh = 0;
            this.reset = false;
            this.isRunning = false;
            clearInterval(this.timerId);
            for(var i = 0; i < this.exerciseTracker.length; i++){
              if(this.exerciseTracker[i].checked == true){
                this.exerciseTracker[i].checked = false;
              }
            }
            for(var j = 0 ; j < this.chartHeadingArray.length; j++){
              $("#"+this.chartHeadingArray[j]).removeClass("active"); 
            }
            this.counter = "";
          }else{
            this.toastr.error(obj.result);
          }
        });
  }

  epochToHhMmSs(epoch) {//chart yaxis to hh:mm:ss
    return new Date(epoch*1000).toISOString().match("T(.*).000Z")[1];
  }
}
