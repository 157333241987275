import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PharmaSupplierPaymentComponent } from './pharma-supplier-payment/pharma-supplier-payment.component';

const routes: Routes = [
  { path: 'supppay',component: PharmaSupplierPaymentComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InventoryModuleRoutingModule { }
