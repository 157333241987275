<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="container-fluid">
    <a class="navbar-brand" href="#">
      <img [src]="myLogo" class="tervys-logo mx-4" />
    </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" (click)="toggle()" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavAltMarkup" [style.display]="displayStyle">
      <div class="navbar-nav ms-auto" style="margin-left:5%">
        <ul class="navbar-nav me-5 mb-2 mb-lg-0">
          <li class="nav-item" routerLinkActive="active">
            <a class="nav-link px-4" routerLink="/" >Home</a>
          </li>
          <li class="nav-item">
            <a class="nav-link px-4" routerLink="/aboutus">AboutUs</a>
          </li>
          <li class="nav-item" *ngIf="isHomePage()">
            <a class="nav-link px-4" href="#wcuSection">Why Choose Us</a>
          </li>
          
          <li class="nav-item" *ngIf="isHomePage()">
            <a class="nav-link px-4" href="#specialitySection">Specialities</a>
          </li>

          <li class="nav-item">
            <a class="nav-link px-4" routerLink="/solutions">Solutions</a>
          </li>
          
          <li class="nav-item">
                <a class="nav-link px-4" routerLink="/registration">Registration&nbsp;&nbsp;</a>
              </li>
          <li class="nav-item">
            <a class="nav-link px-4" routerLink="/privacy_policy">Privacy Policy</a>
          </li>

          <li class="nav-item">
            <a class="nav-link px-4" routerLink="/loginpage">Login</a>
          </li>
        </ul>
      
      </div>
    </div>
  </div>
</nav>