import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Http, Headers, Jsonp } from '@angular/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { CommonDataService } from '../../providers/common-data.service';
import { ClientViewService } from '../clientview/client-view.service';
import { MatDialog } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import pdfMake from 'pdfmake/build/pdfmake';
import { MeduploadComponent } from '../medupload/medupload.component';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';
declare var $: any;
@Component({
  selector: 'app-upload-documents',
  templateUrl: './upload-documents.component.html',
  styleUrls: ['./upload-documents.component.css']
})
export class UploadDocumentsComponent implements OnInit {
  public relationUrl: string;
  public reportUrl: string;
  public splUrl: string;
  public relationList = [];
  public reportList = [];
  public splList = [];
  public medicineData = [];
  public sendClientRegId;
  public relationId: string;
  public docType: string = "";
  public splId: string = "";
  public document: string;
  public fName: string;
  public mName: string;
  public lName: string;
  public disease: string;
  public documentDate;
  public sendData;
  public imageFileStr: any;
  public relationData;
  public subRelId;
  public currentDateUrl;
  public sendCurrentData;
  public currentTime;
  public currentDate;
  public subId;
  public reportName;
  public pdfObj;
  public photos=[];
  public pdf;
  public Select = "Select";
  public saveFlag:boolean = true;

  constructor(public dialog: MatDialog,public gservice:CommonDataService,public datepipe:DatePipe,public http:Http,public routes:ActivatedRoute,public router:Router,public toastr:ToastrService,
  public clientservice:ClientViewService) { 
    this.relationUrl = ipaddress.getIp + "usercontroller/grels/";
    this.reportUrl = ipaddress.getIp + "gen/dtypes";
    this.splUrl = ipaddress.getIp + "usercontroller/getspecialization/";
    if (this.gservice.Client_login_detail_data.Client_Id != undefined) {
      this.sendClientRegId = {
        client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
      }
    }

    this.document = "Image";
    this.currentDateUrl = ipaddress.getIp + "adm/curdate";
    this.sendCurrentData = {
      country: ipaddress.country_code
    }
    
  }

  ngOnInit(): void {
    this.getRelations();
    this.getReportTypes();
    this.getSplDatas();
    this.getSelfData();
    this.getCurrentDate();
  }

  getCurrentDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.currentDateUrl, JSON.stringify(this.sendCurrentData), { headers: headers }).subscribe(
      data => {
        this.currentDate = data.json().current_date;
        this.currentTime = data.json().current_time;
        this.documentDate = (this.currentDate)
      }, error => {
      }
    );
  }

  getRelations() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.relationUrl, JSON.stringify(this.sendClientRegId), { headers: headers }).subscribe(
      data => {
        this.relationList = [];
        this.relationList = data.json().relationships;
        this.relationId = this.relationList[0].relation_id;
      }, error => {});
  }
  
  getReportTypes() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(this.reportUrl, { headers: headers }).subscribe(
      data => {
        this.reportList = [];
        this.reportList = data.json().doc_types;
      }, error => {});
  }

  getSplDatas() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(this.splUrl, { headers: headers }).subscribe(
      data => {
        this.splList = [];
        for (var t = 0; t < data.json().specialization.length; t++) {
          var spls = data.json().specialization[t];
          if (spls.spl_name != "-") {
            this.splList.push({
              spl_id: spls.spl_id,
              spl_name: spls.spl_name
            });
          }
        }
      }, error => {});
  }

  fileChange(data) {
    this.readThis(data.target);
  }

  readThis(inputValue: any): void {
    var ValidFileExtension = ['jpg', 'JPG', 'jpeg', 'JPEG', 'png', 'PNG', 'gif', 'GIF', 'bmp', 'BMP','pdf'];
    var file: File = inputValue.files[0];
    var fileExtension = file.type.split("/").pop();
    var myReader: FileReader = new FileReader();
    myReader.onloadend = (e) => {
      var fileSize = Math.round(inputValue.files[0].size / 1024);
      this.imageFileStr = myReader.result.toString();
      if(fileExtension == 'pdf'){
        this.pdf = file;
      }else{
        this.photos.push(this.imageFileStr)
      }
    }
    myReader.readAsDataURL(inputValue.files[0]);
  }

  changeRelation(rels) {
    this.relationId = rels;
    if (this.relationId == "1") {
      this.getSelfData();
    } else {
      this.getOtherData();
    }
  }

  getSelfData() {
    var clntDetailedUrl = ipaddress.getIp + "usercontroller/clientdet/";
    var send_datas = {
      client_id: this.gservice.Client_login_detail_data.Client_Id,
      relationship: "Self",
      country: ipaddress.country_code
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(clntDetailedUrl,
      JSON.stringify(send_datas), { headers: headers }).subscribe(
        data => {
          this.relationData = [];
          var fname, mname, lname;
          if (data.json().first_name !== undefined) {
            fname = encrypt_decript.Decript(data.json().first_name);
            this.fName = fname;
          }
          if (data.json().middle_name !== undefined) {
            mname = encrypt_decript.Decript(data.json().middle_name);
            this.mName = mname;
          }
          if (data.json().last_name !== undefined) {
            lname = encrypt_decript.Decript(data.json().last_name);
            this.lName = lname;
          }
          this.relationData.push({
            cln_rel_id: data.json().cln_rel_id,
            name: fname,
            m_name: mname,
            l_name: lname
          });
        }, error => {
        });
  }

  getOtherData() {
    var otherRelDetUrl = ipaddress.getIp + "appointment/clnrelweb/";
    var rel_name;
    for (var g = 0; g < this.relationList.length; g++) {
      if (this.relationList[g].relation_id == this.relationId) {
        rel_name = this.relationList[g].relationship_name;
      }
    }
    var send_datas = {
      client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
      relationship: rel_name,
      relation_id: this.relationId,
      country: ipaddress.country_code
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(otherRelDetUrl,
      JSON.stringify(send_datas), { headers: headers }).subscribe(
        data => {
          this.relationData = [];
          if (data.json().rel_details !== undefined && data.json().rel_details.length != 0) {
            for (var f = 0; f < data.json().rel_details.length; f++) {
              var rel_data = data.json().rel_details[f];
              var m_name;
              if (rel_data.middle_name != null) {
                m_name = rel_data.middle_name;
              } else {
                m_name = "";
              }
              this.relationData.push({
                cln_rel_id: rel_data.cln_rel_id,
                name: rel_data.first_name,
                m_name: m_name,
                l_name: rel_data.last_name
              });
            }
            this.subRelId = this.relationData[0].cln_rel_id;
            this.fName = this.relationData[0].name;
            this.mName = this.relationData[0].m_name;
            this.lName = this.relationData[0].l_name;
          }
        }, error => {
        });
  }

  changeFName(name) {
    for (var f = 0; f < this.relationData.length; f++) {
      if (this.relationData[f].name == name) {
        this.fName = this.relationData[f].name;
        this.mName = this.relationData[f].m_name;
        this.lName = this.relationData[f].l_name;
        this.subRelId = this.relationData[f].cln_rel_id;
      }
    }
  }

  createPdf(){
    if(this.photos.length !=0){
      let imgFinding = [];
      var fileExtension;
      for(var i=0;i<this.photos.length;i++){
          imgFinding.push({image: this.photos[i],fit: [540, 700]})
      }
          let docDefinition = {
           pageSize: 'A4',
           content: imgFinding
          };
          this.pdfObj = pdfMake.createPdf(docDefinition);
          this.pdfObj.getBuffer((buffer) => {
          var blob = new Blob([buffer], { 
            type: 'application/pdf' 
          });
          var reader = new FileReader();
          reader.readAsDataURL(blob); 
          reader.onloadend = function(this:any) {
            var base64data = reader.result;    
            this.save_data(base64data) 
          }.bind(this);        
        });
    }else if(this.pdf != undefined){
      var reader = new FileReader();
      reader.readAsDataURL(this.pdf); 
      reader.onloadend = function(this:any) {
        var base64data = reader.result;    
        this.save_data(base64data) 
      }.bind(this);   
    }
    else {
      this.toastr.error(Message_data.addAtlstOneImg);
    }
  }

  save_data(pdffile) {
    if (this.docType == undefined || this.docType == "") {
      this.toastr.error(Message_data.sltReportType);
    } else if (this.documentDate == undefined) {
      this.toastr.error(Message_data.sltDate);
    } else if (this.reportName == undefined || this.reportName == "") {
      this.toastr.error(Message_data.enterReportName);
    } else if (this.splId == undefined || this.splId == "") {
      this.toastr.error(Message_data.sltSpecializtion);
    } else {
      if (this.relationId == "1") {
        this.subId = undefined;
      } else {
        this.subId = this.subRelId;
      }
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'gen/cudoc',
        JSON.stringify({
          client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
          document_date: (this.documentDate),
          relation_id: this.relationId,
          sub_rel_id: this.subId,
          country: ipaddress.country_code,
          document_type: this.docType,
          spl_id: this.splId,
          disease: this.disease,
          report_name:this.reportName,
          document: pdffile,
          medicines: this.medicineData
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            if (obj.key == "1") {
              this.saveFlag = false;
              this.toastr.success(Message_data.rptUploaddSuccess);
              this.clientservice.sendMessage("documentlist");
            }
          },error => {
            this.toastr.error(Message_data.network);
          });
    }
  }

  changeDocumentDate(value,date) {
    this.documentDate = date;
  }

  changeDocumentType(e) {
    if (e == "7") {
      $("#staticBackdrop").modal('show');
    }
  }

  showWriterxModel(){
    $("#staticBackdrop").modal('hide');
    const dialogRef = this.dialog.open(MeduploadComponent, {
      width: '720px',
      height: '400px'
    });
    dialogRef.afterClosed().subscribe(result => {
      this.medicineData = result;
    });
  }

  back(){
    this.clientservice.sendMessage("documentlist");
  }
}
