import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IndexComponent } from './common-module/index/index.component';

const routes: Routes = [
  // {path:'',component:IndexComponent},
  // { path: 'index',   redirectTo: 'index', pathMatch: 'full' },

  { 
    path: 'common-module', 
    loadChildren: () => import(`./common-module/common-module.module`).then(
      module => module.CustomeCommonModule
    )
  },
  { 
    path: 'Doctor_module',
    loadChildren: () => import(`./Doctor_module/DoctorModule.module`).then(
      module => module.DoctorModule
    )
  },
  { 
    path: 'FrontDesk_module', 
    loadChildren: () => import(`./FrontDesk_module/front-desk.module`).then(
      module => module.FrontDeskModule
    )
  },
  { 
    path: 'pharma', 
    loadChildren: () => import(`./pharma/pharma.module`).then(
      module => module.PharmaModule
    )
  },
  { 
    path: 'Diagnosis_module', 
    loadChildren: () => import(`./Diagnosis_module/DiagnosisModule.module`).then(
      module => module.DiagnosisModule
    )
  },
  { 
    path: 'client', 
    loadChildren: () => import(`./client/ClientModule.module`).then(
      module => module.ClientModule
    )
  },
  { 
    path: 'Nurse', 
    loadChildren: () => import(`./Nurse_module/NurseModule.module`).then(
      module => module.NurseModule
    )
  },
  { 
    path: 'Physio', 
    loadChildren: () => import(`./Physio_module/PhysioModule.module`).then(
      module => module.PhysioModule
    )
  },
  { 
    path: 'Admin_module', 
    loadChildren: () => import(`./Admin_module/Admin-module.module`).then(
      module => module.AdminModule
    )
  },
  { 
    path: 'Diet_module', 
    loadChildren: () => import(`./Diet_module/DietModule.module`).then(
      module => module.DietModule
    )
  },
  { 
    path: 'report_module', 
    loadChildren: () => import(`./reports/reports.module`).then(
      module => module.ReportsModule
    )
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
