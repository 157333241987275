<div class="row" style="width:100%;margin:0 auto;">
    <div class="col-12">
      <mdb-card>
        <mdb-card-header class="bg-white" style="padding:7px 20px;">
          <div class="headerCover">
            <div class="headerTilte app_list_header" >
              <h5 class="m-0" class="mainHeadingStyle">Patient List</h5>
            </div>
          </div>
        </mdb-card-header>
        <mdb-card-body>
          <div class="row">
            <p class="nodata col-4" *ngIf="recptPatientArray.length == 0">No Patient List(s) Found</p>
            <!-- <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"> </div>  -->
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-11">
              <table *ngIf="recptPatientArray.length" mdbTable datatable [dtOptions]="dtOptions"
                class="table table-nowrap table-sm dataTable billlisttable">
                <thead>
                  <tr>
                    <th>Patient id</th>
                    <th>Name</th>
                    <th>Mobile</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let person of recptPatientArray; let i = index">
                    <!-- <td><img src="{{person.Pro_Image}}" class="img-fluid z-depth-1 rounded-circle mr-1"></td> -->
                    <td style="font-size: 12px; text-align: center;">{{ person.patient_id }}</td>
                    <td style="font-size: 12px;text-align: left;padding-left: 20%;">{{ person.full_name }}</td>
                    <td style="font-size: 12px; text-align: center;">{{ person.mobile }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1" ></div>      -->
            <br><br><br>
          </div>
  
        </mdb-card-body>
      </mdb-card>
    </div>
  </div>