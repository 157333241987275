import { Component, OnInit , Input} from '@angular/core';
import {Helper_Class} from '../../helper_class';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import { Http, Headers } from '@angular/http';
import { SecondOpinionService } from '../second-opinion-casesheet/SecondOpinion.Service';
import { Observable, observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-second-opinion-casesheet-familyhistory',
  templateUrl: './second-opinion-casesheet-familyhistory.component.html',
  styleUrls: ['./second-opinion-casesheet-familyhistory.component.css']
})
export class SecondOpinionCasesheetFamilyhistoryComponent implements OnInit {

  public family = 'min';
  public expansion:boolean=false;
  public expansion1:boolean=false;

  public togg_diab:string="no";
  public togg_storke:string="no";
  public togg_endo:string="no";
  public togg_hyper:string="no";
  public togg_tuber:string="no";
  public togg_hepat:string="no";
  public togg_heart_att:string="no";
  public togg_arter:string="no";
  public togg_auto:string="no";
  public togg_hemo:string="no";
  public togg_hiv:string="no";
  public togg_gla:string="no";
  public family_other;
  public diab_case_hist_id;
  public life_style_id;
  public getHopitalfieldsRet;
  subscribe:Subscription

  public Diabetes_txt;
  public Heart_attacktxt;
  public Stroke_txt;
  public Arterial_txt;
  public Endocrine_txt;
  public Autoiimue_txt;
  public Hyper_txt;
  public Hemophila_txt;
  public Tuberculosis_txt;
  public HIV_txt;
  public Hepatatis_txt;
  public Glaucoma_txt;

  constructor(private http:Http,private routes:ActivatedRoute,private router:Router, public messageservice1: SecondOpinionService) { }

  ngOnInit(): void {
    this.Get_family_retrivel()
    this.subscribe = this.messageservice1.getSecondOpinionMessage().subscribe(message => {
      if (message) {
          this.family = message;
      }
    });
  }

  Get_family_retrivel() {
    var getOpnRet = Helper_Class.getOpnRet();
    this.Diabetes_txt = getOpnRet.diabetics == "yes" ? true : false;
    this.Heart_attacktxt = getOpnRet.heart_attack == "yes" ? true : false;
    this.Stroke_txt = getOpnRet.stroke == "yes" ? true : false;
    this.Arterial_txt = getOpnRet.arterial == "yes" ? true : false;
    this.Endocrine_txt = getOpnRet.endocrine == "yes" ? true : false;
    this.Autoiimue_txt = getOpnRet.autoimmune == "yes" ? true : false;
    this.Hyper_txt = getOpnRet.hypertension == "yes" ? true : false;
    this.Hemophila_txt = getOpnRet.hemophilia == "yes" ? true : false;
    this.Tuberculosis_txt = getOpnRet.tuberculosis == "yes" ? true : false;
    this.HIV_txt = getOpnRet.hiv == "yes" ? true : false;
    this.Hepatatis_txt = getOpnRet.hepatitis == "yes" ? true : false;
    this.Glaucoma_txt = getOpnRet.glaucoma == "yes" ? true : false;  
    if (getOpnRet.others != undefined) {
      this.family_other = getOpnRet.others;
    }
  }

  option(val,d){
    if(val==true){
      switch(d){
        case 1: this.togg_diab = "yes";this.Diabetes_txt = val;break;
        case 2: this.togg_heart_att="yes";this.Heart_attacktxt = val;break;
        case 3: this.togg_storke="yes";this.Stroke_txt = val;break;
        case 4: this.togg_arter="yes";this.Arterial_txt = val;break;
        case 5: this.togg_endo="yes";this.Endocrine_txt = val;break;
        case 6:this.togg_auto="yes";this.Autoiimue_txt = val;break;
        case 7:this.togg_hyper="yes";this.Hyper_txt = val;break;
        case 8:this.togg_hemo="yes";this.Hemophila_txt = val;break;
        case 9:this.togg_tuber="yes";this.Tuberculosis_txt = val;break;
        case 10:this.togg_hiv="yes";this.HIV_txt = val;break;
        case 11:this.togg_hepat="yes";this.Hepatatis_txt = val;break;
        case 12:this.togg_gla="yes";this.Glaucoma_txt = val;break;
      }
    }
    if(val == false){
      switch(d){
        case 1: this.togg_diab ="no";this.Diabetes_txt = val;break;
        case 2: this.togg_heart_att="no";this.Heart_attacktxt = val;break;
        case 3: this.togg_storke="no";this.Stroke_txt = val;break;
        case 4: this.togg_arter="no";this.Arterial_txt = val;break;
        case 5: this.togg_endo="no";this.Endocrine_txt = val;break;
        case 6: this.togg_auto="no";this.Autoiimue_txt = val;break;
        case 7: this.togg_hyper="no";this.Hyper_txt = val;break;
        case 8: this.togg_hemo="no";this.Hemophila_txt = val;break;
        case 9: this.togg_tuber="no";this.Tuberculosis_txt = val;break;
        case 10: this.togg_hiv="no";this.HIV_txt = val;break;
        case 11: this.togg_hepat="no";this.Hepatatis_txt = val;break;
        case 12: this.togg_gla="no";this.Glaucoma_txt = val;break;
      }
    }
  }
}
