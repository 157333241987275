<div mat-dialog-title class="popup_border_icd">
    <h5 class="header_txt">Delivery Options</h5>
    <div mat-dialog-actions>
        <!-- <button mat-icon-button  class="btnclose"> -->
        <!-- <span (click)="ClosePopup()" id="close_icd">X</span> -->
        <img src="../../../assets/img/close_icon.png" (click)="ClosePopup()" width="20px" height="20px"
            id="close_icd" />
    </div>
</div>
<div mat-dialog-content class="diag_content">
    <div class="row" *ngIf="isHome" style="margin-top: 10px;margin-left: 5px;margin-right: 5px;">
        <div class="col-12">
            <p class="txt_align1">Please select estimated delivery date and time </p>
        </div>
    </div>
    <div class="row" *ngIf="!isHome" style="margin-top: 10px;margin-left: 5px;margin-right: 5px;">
        <div class="col-12">
            <p class="txt_align1">Please select delivery date and time </p>
        </div>
    </div>
    <div class="row" style="margin-top: 10px;margin-left: 5px;margin-right: 5px;">
        <div class="col-12 col-md-0 col-lg-0 col-xl-12"></div>
        <div class="col-12">
            <mat-label>Delivery date</mat-label>
            <input type="date" class="ipcss" [(ngModel)]="appointmentDate" (click)="getDate('Appointment_Date')"
                matInput>
        </div>
        <br />
        <div class="col-12">
            <mat-label class="client_label start_sty">Delivery Time</mat-label>
            <div class="row">
                <div class="col-4">
                    <div class="dropdown">
                        <select class="ipcss" name="start_time" [(ngModel)]="startFrom1">
                            <option *ngFor="let name of startsFromArray" [ngValue]="">{{name}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-4">
                    <div class="dropdown">
                        <select class="ipcss" name="end_time" [(ngModel)]="startFrom2">
                            <option *ngFor="let name of endsAtArray" [ngValue]="">{{name}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-4">
                    <div class="dropdown">
                        <select class="ipcss" name="time_option" [(ngModel)]="startFrom3">
                            <option [ngValue]="">AM</option>
                            <option>PM</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <br>
    </div>
</div>
<!-- <hr class="hr"> -->
<div mat-dialog-actions align="end" style="width: auto;right: 19px;">
    <button (click)="filter()" id="add_btn" mat-button>
        <img src="../../../assets/ui_icons/buttons/update_button.svg" class="addimgbtn_icd" />
    </button>
</div>