import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatSelectModule} from '@angular/material/select';
import { MatFormFieldModule} from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import {MatDialogModule} from '@angular/material/dialog';
import { DropdownModelpageComponent } from './dropdown-modelpage.component';
import { MatCheckboxModule } from '@angular/material/checkbox';


@NgModule({
    imports:[MatDialogModule,CommonModule,MatCheckboxModule,MatInputModule ,MatSelectModule,MatFormFieldModule,MatIconModule,MatButtonModule,FormsModule],
    declarations:[DropdownModelpageComponent],
    exports:[DropdownModelpageComponent]
})
export class DropDownModelPageModule{   
   
}