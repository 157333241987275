<div class="row" style="margin:0 auto; width:100%">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white" style="padding:7px 20px;">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Donors</h5>
          </div>
          <div class="headerButtons">
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="">
          <div class="row">
            <div *ngIf="donorList.length == 0" class="nodata">No donors found</div>
            <div class="col-12">
              <div class="table-responsive dig_table_overflow" *ngIf="donorList.length != 0">
                <table  id="card_tbl" class="table table-hover table-dynamic"
                  cellpadding="5" cellspacing="0">
                  <thead class="tableheading">
                    <tr style="height: 32px;">
                      <th>Hospital </th>
                      <th>Donation date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr (click)="viewDonarDetails(donor.hptl_clinic_id,donor.date)" class="appointment_list_data" col-12
                      col-sm-6 col-md-12 col-lg-12 col-lx-4 *ngFor="let donor of donorList">
                      <td style="text-align: left;">{{donor.hospital}}</td>
                      <td>{{donor.date}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>