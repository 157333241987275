<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Edit bill</h5>
          </div>
          <div class="headerButtons">
            <a (click)="backClicked()"><img src="../../../assets/ui_icons/buttons/Back_button.svg"
                class="saveimgbtn_inpatinfo" /></a>
            <a *ngIf="show_upd_btn" (click)="Bill_update()"><img src="../../../assets/ui_icons/buttons/update_button.svg"
                class="saveimgbtn_inpatinfo" /></a>
                <!-- <a (click)="consolidatedBilling()" *ngIf="inpatientFlag"><img src="../../../assets/ui_icons/consolidated.svg" class="saveimgbtn_inpatinfo" /></a> -->
          </div>
        </div>
        <!-- <div class="d-flex justify-content-between align-items-center">
          <h5 class="m-0" class="mainHeadingStyle">Edit bill</h5>
          <div>
            <a (click)="backClicked()"><img src="../../../assets/ui_icons/buttons/Back_button.svg"
              class="saveimgbtn_inpatinfo" /></a>
            <a (click)="Bill_update()"><img src="../../../assets/ui_icons/buttons/save_button.svg"
                class="saveimgbtn_inpatinfo" /></a>
          </div>
        </div> -->
      </mdb-card-header>
      <mdb-card-body>
        <div class="row" style="margin-bottom: 15px;">
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel">Bill id<br>
              <input matInput class="ipcss widthbilledit" disabled="true" [(ngModel)]="bill_id" />
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel">Hospital name<br>
              <input matInput class="ipcss widthbilledit" disabled="true" [(ngModel)]="hospital_name" />
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel">Patient name<br>
              <input matInput class="ipcss widthbilledit" disabled="true" [(ngModel)]="patient_name" />
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom">
            <mat-label class="matlabel">Service type<br>
              <input type="text" class="ipcss inpat_width" [(ngModel)]="biill_type"
              (keyup)="getServiceType(biill_type)" required matInput/>
              <div class="auto_complete_text1">
                  <ul *ngFor="let charge of serviceType">
                      <li>
                          <a (click)="charge_change(charge)">{{charge}}</a>
                      </li>
                  </ul>
              </div>
              <!-- <mat-select class="ipcss widthbilledit" required [(ngModel)]="biill_type" disableOptionCentering (selectionChange)="charge_change()">
                <mat-option *ngFor="let charge of charge_name" value={{charge.description}}>
                  {{charge.description}}</mat-option>
              </mat-select> -->
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" [hidden]="paytypreq">
            <mat-label class="matlabel">Payment type<br>
              <mat-select class="ipcss " [(ngModel)]="pay_type" disableOptionCentering>
                <mat-option *ngFor="let pay of paytypelist" value={{pay.pay_id}} (click)="pay_change(pay.pay_id)">
                  {{pay.pay_desc}}</mat-option>
              </mat-select>
            </mat-label>
          </div>
          <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" [hidden]="card_flag">
            <mat-label class="matlabel">Card No <br>
              <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardNumber" />
            </mat-label>
          </div>
         
          <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" [hidden]="card_flag">
            <mat-label class="matlabel">Holder name<br>
              <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardHolderName" />
            </mat-label>
          </div>

          <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" [hidden]="tran_flag">
            <mat-label class="matlabel">Transaction ID<br>
              <input class="ipcss  widthbillcreate" matInput [(ngModel)]="Transaction_id" />
            </mat-label>
          </div>
          <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" [hidden]="cheque_flag">
            <mat-label class="matlabel">Cheque No <br>
              <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardNumber" />
            </mat-label>
          </div>
          <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" [hidden]="cheque_flag">
            <mat-label class="matlabel">Bank Name <br>
              <input class="ipcss  widthbillcreate" matInput [(ngModel)]="bankName" />
            </mat-label>
          </div>
          <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" [hidden]="insur_flag">
            <mat-label class="matlabel">Insurer<br>
              <mat-select required class="ipcss  widthbillcreate" [(ngModel)]="insurer_id" disableOptionCentering>
                <mat-option *ngFor="let ins of insurerlist" value={{ins.insurer_id}}>{{ins.insurer_desc}}</mat-option>
              </mat-select>
            </mat-label>
          </div>
          <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel">Quantity<br>
              <input type=number class="ipcss billing_text_align widthbillcreate" matInput required (keyup)="amount_change()"
                [(ngModel)]="quantity" maxlength="45" />
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel">Amount<br>
              <input type=number matInput class="ipcss bill_txt " required (keyup)="amount_change()"
                [(ngModel)]="amount_txt" maxlength="45" />
            </mat-label>
          </div>
          <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label type=number class="matlabel">Concession(%)<br>
              <input matInput class="ipcss bill_txt" required (keyup)="amount_change()" [(ngModel)]="concession_txt"
                maxlength="45" />
            </mat-label>
          </div>
          <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">


            <mat-label class="matlabel">After concession<br>
              <input type=number matInput class="ipcss bill_txt " disabled="true" [(ngModel)]="amount_aftcon" />
            </mat-label>
          </div>

          <div class="col-12 col-md-12 col-lg-12 col-xl-12 ">
            <mat-label class="addvalue matlabel">
              <a (click)="Bill_add_click()"><img src="../../../assets/ui_icons/buttons/add_button.svg"
                  class="saveimgbtn_inpatinfo save_btn" /></a>
            </mat-label>

          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-12 col-md-12 col-xl-12">
            <!-- <div></div> -->
            <!-- <p class="nodata" *ngIf="billaddlist.length == 0">No bill(s) Found</p> -->
            <table *ngIf="billaddlist.length" class="table table-nowrap table-sm dataTable  bill_edit_table">
              <thead class="tableheading">
                <tr style="height: 23px;">
                  <th>Sno</th>
                  <th>Type</th>
                  <th [hidden]="paytypreq">Payment type</th>
                  <th>Quantity</th>
                  <th class="delete">Fees</th>
                  <th>Concession(%)</th>
                  <th class="delete">Amount</th>
                  <th class="delete">Delete</th>
                </tr>
              </thead>
              <tbody *ngFor="let person of billaddlist">
                <tr *ngIf="person.status != '2'" class="tdvalues">
                  <td style="text-align: center;">{{person.sno}}</td>
                  <td style="text-align: left;">{{person.biill_type}}</td>
                  <td style="text-align: left;" [hidden]="paytypreq">{{person.pay_type}}</td>
                  <td style="text-align: center;">{{person.quantity}}</td>
                  <td style="text-align: right;">{{person.amount_txt}} </td>
                  <td style="text-align: right;">{{person.concession}}</td>
                  <td class="billlistleft" style="text-align: right;">{{person.fee}}</td>
                  <td class="delete">
                    <a (click)="Delete_bill(person.biill_type,person.amount_txt,person)"> <img
                        src="../../../assets/ui_icons/buttons/trash_icon.svg" class="billing_edit" alt=""></a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div [hidden]="billden_plan.length==0">
          <p class="casesheet_para">Treatement plan</p>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <table *ngIf="billden_plan.length" mdbTable datatable [dtOptions]="dtOptions"
            class="table table-nowrap table-sm dataTable bill_edit_table">
            <thead>
              <tr>
                <th>Plan</th>
                <th class="tbl_txt_center">Estimate</th>
                <th class="tbl_txt_center">Select</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let person of billden_plan; let i = index">
                <td>{{person.treatment_desc}}</td>
                <td class="tbl_txt_center">{{person.estimate}}</td>
                <td class="tbl_txt_center">
                  <mat-checkbox color="primary" [(ngModel)]="bill_palnche" id="{{person.den_treatment_plan_id}}"
                    (click)="bill_click(person.den_treatment_plan_id,bill_palnche,i)">
                  </mat-checkbox>
              </tr>
            </tbody>
          </table>
          <br><br><br>
        </div>
        <!-- <div class="row ">
          <div class="col-0 col-sm-4  col-md-7 col-lg-8 col-xl-8"></div>
          <div class="col-0 col-sm-8  col-md-5 col-lg-4  col-xl-4">
            <div class="row">
              <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                <mat-label class="matlabel heightmsr">Include GST &nbsp;&nbsp; </mat-label>
              </div>
              <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                <mat-checkbox color="primary" class="matlabel" [(ngModel)]="inGST" (change)="include_gst_click(inGST)">
                </mat-checkbox>
              </div>
              <div *ngFor="let gsttax of gst_data_list">
                <div class="row">
                  <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                    <mat-label class="matlabel  gstfield">{{gsttax.Gst_Descrip}}</mat-label>
                  </div>
                  <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                    <mat-label class=" ipcss billcreatech heightmsr bill_txt">{{gsttax.Gst_amount}}</mat-label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <div class="row">
          <div class="col-0 col-sm-4 col-md-7 col-lg-8 col-xl-8">
            <div class="cover_div">
              <div class="row">
                <div class="col-3">
                  <mat-label class="matlabel">Credit bill
                      <mat-checkbox (change)="showCreditOption()" color="primary" [(ngModel)]="credit_bill">
                      </mat-checkbox>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-5 col-md-5 col-lg-4 col-xl-6" [hidden]="credit_flag">
                  <div class="row">
                    <div class="col-4">
                      <mat-label class="matlabel">Credit type </mat-label>
                    </div>
                    <div class="col-8">
                      <select class="ipcss " [(ngModel)]="credit_type" 
                        disableOptionCentering>
                        <option *ngFor="let credit of hosp_credits" value={{credit.credit_id}}>{{credit.credit_desc}}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row"  [hidden]="!paytypreq">
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom">
                  <mat-label class="matlabel">Payment type<br>
                    <mat-select class="ipcss" [(ngModel)]="payType1" disableOptionCentering>
                      <mat-option *ngFor="let pay of paytypelist" value={{pay.pay_id}}>{{pay.pay_desc}}</mat-option>
                    </mat-select>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-5 col-md-5 col-lg-4 col-xl-4" >
                  <mat-label class="matlabel">Amount<br>
                    <input type=number class="ipcss billing_text_align" [(ngModel)]="amountval"  matInput maxlength="7" />
                  </mat-label>
                </div>
                <div class="col-12 col-sm-2 col-md-3 col-lg-2 col-xl-2">
                  <mat-label class="matlabel ">
                    <img src="../../../assets/ui_icons/buttons/add_button.svg" class="saveimgbtn_inpatinfo add_align"
                      (click)="addPaymentDetails()" style="float: right;"/>
                  </mat-label>
                </div>
              </div>

              <div class="row">
                <table>
                  <tr *ngFor="let pay of paymentsarray">
                    <td style="width: 150px !important;">{{pay.pay_desc}}</td>
                    <td style="width: 150px !important;">{{pay.amount}}</td>
                    <td>
                      <img  src="../../../assets/img/edit.png" class="billing_edit" (click)="editPayment(pay)"> &nbsp;&nbsp;
                      <img  src="../../../assets/img/Delete.png" class="billing_edit" (click)="deletePayment(pay)">
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div class="col-0 col-sm-8 col-md-5 col-lg-4 col-xl-4">
            <div class="row">
              <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6" [hidden]="previous_balshow">
                <mat-label class="matlabel  heightmsr">Previous balance </mat-label>
              </div>
              <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6" [hidden]="previous_balshow">
                <input class="ipcss  heightmsr bill_txt" matInput text-right [(ngModel)]="balance_data" maxlength="10"
                  disabled />
              </div>

              <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                <mat-label class="matlabel heightmsr">Total </mat-label>
              </div>
              <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                <input class="ipcss billcreatech heightmsr bill_txt" matInput text-right disabled="true"
                  [(ngModel)]="final_amount" />
              </div>
              <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                <mat-label class="matlabel heightmsr">Advance </mat-label>
              </div>
              <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                <input class="ipcss  heightmsr bill_txt" matInput text-right (keyup)="advance_change()"
                  [(ngModel)]="Advance_bill" maxlength="10" />
              </div>
              <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                <mat-label class="matlabel heightmsr">Paid</mat-label>
              </div>
              <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                <input class="ipcss  heightmsr bill_txt billcreatech" matInput text-right (keyup)="paid_change()"
                  [(ngModel)]="paid_bill" maxlength="10" />
              </div>

              <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                <mat-label class="matlabel heightmsr" [hidden]="balance_show">Balance</mat-label>
              </div>
              <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                <input class="ipcss billcreatech heightmsr bill_txt" [hidden]="balance_show" matInput text-right
                  [(ngModel)]="remaing_billbal" maxlength="10" />
              </div>
            </div>
          </div>
        </div>

      </mdb-card-body>
    </mdb-card>
  </div>
</div>