import { Component, OnInit ,HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { ipaddress } from '../../ipaddress';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { PhysioCasesheetService } from '../physio-casesheet/physio-casesheet.service';
import { Physio_Helper } from '../Physio_Helper';

@Component({
  selector: 'app-physio-habitual',
  templateUrl: './physio-habitual.component.html',
  styleUrls: ['./physio-habitual.component.scss']
})
export class PhysioHabitualComponent implements OnInit {
  public habitualFlag = "min";
  public alcholFlag:boolean;
  public smokeFlag:boolean;
  public gutkaFlag:boolean;
  public exerciseFlag:boolean;
  public quantityList = [];
  public mixingList = [];
  public exerciseRoutineList = [];
  public toggFamilyFlag: boolean;
  public toggAlcholFlag: boolean;
  public toggSmokingFlag: boolean;
  public toggGutkaFlag: boolean;
  public gutkaDuration: string;
  public gutkaFrequency: string;
  public alcholDuration: string;
  public alcoholFrequency: string;
  public alcoholQuant: string;
  public alcoholMix: string;
  public smokeDuration: string;
  public smokeFrequency: string;
  public exerDuration: string;
  public exerRoutine: string;
  public toggExerciseFlag: boolean;
  public alcholLabel;
  public showAlcholFlag: boolean;
  public smokeLabel;
  public showSmokeFlag: boolean;
  public showGutkaFlag: boolean;
  public gutkaLabel;
  public exerciseLabel;
  public showExersieFlag: boolean;
  public familySupLabel;
  public showFamilyFlag: boolean;
  subscription:Subscription;

  constructor(public dialog:MatDialog,public messageservice:PhysioCasesheetService,public http:Http,public routes:ActivatedRoute,public router:Router,public toastr:ToastrService
    ,public casesheetservice:PhysioCasesheetService)   {
    this.toggFamilyFlag = false;
    this.toggAlcholFlag = false;
    this.toggSmokingFlag = false;
    this.toggGutkaFlag = false;
    this.toggExerciseFlag = false;
    this.exerciseFlag = true;
    this.alcholFlag = true;
    this.smokeFlag = true;
    this.gutkaFlag = true;
    this.showAlcholFlag = true;
    this.showSmokeFlag = true;
    this.showGutkaFlag = true;
    this.showExersieFlag = true;
    this.showFamilyFlag = true;
    this.alcholDuration = "<1 Year";
    this.alcoholFrequency = "<5";
    this.smokeDuration = "<1 Year";
    this.smokeFrequency = "<5";
    this.gutkaDuration = "<1 Year";
    this.gutkaFrequency = "<5";
    this.exerDuration = "15 Mins";
  }

  ngOnInit(): void {
    this.subscription = this.casesheetservice.getCasesheetMessage().subscribe(message => {
      if (message) {
          this.habitualFlag = message;
      }
    });
    for (var i = 0; i < Physio_Helper.getHopitalfieldsRet().length; i++) {
      if (Physio_Helper.getHopitalfieldsRet()[i].page_name == "History") {
        if (Physio_Helper.getHopitalfieldsRet()[i].fields != undefined) {
          if (Physio_Helper.getHopitalfieldsRet()[i].fields != undefined) {
            for (var j = 0; j < Physio_Helper.getHopitalfieldsRet()[i].fields.length; j++) {
              if (Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name == "Alcohol") {
                this.alcholLabel = Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name;
                this.showAlcholFlag = false;
              }
              if (Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name == "Smoke") {
                this.smokeLabel = Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name;
                this.showSmokeFlag = false;
              }
              if (Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name == "Gutka") {
                this.gutkaLabel = "Gutka/Areca";
                this.showGutkaFlag = false;
              }
              if (Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name == "Exercises") {
                this.exerciseLabel = Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name;
                this.showExersieFlag = false;
              }
              if (Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name == "Family Support") {
                this.familySupLabel = Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name;
                this.showFamilyFlag = false;
              }
            }
          }
        }
      }
    }
        this.getQuantity();
        this.getMixingTypes();
        this.getExerciseRoutines();
        this.getCommonData(); 
  }

  @HostListener('window:click', ['$event'])
  onclick(event: any): void {
    this.sendData();
  }
  @HostListener('window:blur', ['$event'])
  onBlur(event: any): void {
    this.sendData();
  }

  sendData() { 
    var fam_supp = "no", alch = "no", smoke = "no", gutka = "no", gut_dur = undefined, gut_frq = undefined, smk_dur = undefined, smk_frq = undefined, alc_dur = undefined, alc_frq = undefined, qty_id = undefined, mix_id = undefined, exe_rout_id = undefined, exe_dur = undefined;
    if (this.toggFamilyFlag == true) {
      fam_supp = "yes";
    }
    if (this.toggGutkaFlag == true) {
      gut_dur = this.gutkaDuration;
      gut_frq = this.gutkaFrequency;
      gutka = "yes";
    }
    if (this.toggSmokingFlag == true) {
      smk_dur = this.smokeDuration;
      smk_frq = this.smokeFrequency;
      smoke = "yes";
    }
    if (this.toggAlcholFlag == true) {
      alch = "yes";
      alc_dur = this.alcholDuration;
      alc_frq = this.alcoholFrequency;
      for (var i = 0; i < this.quantityList.length; i++) {
        if (this.alcoholQuant == this.quantityList[i].description) {
          qty_id = this.quantityList[i].alcohol_qty_id;
        }
      }
      for (var i = 0; i < this.mixingList.length; i++) {
        if (this.alcoholMix == this.mixingList[i].description) {
          mix_id = this.mixingList[i].mixing_id;
        }
      }
    }
    var exe_dur = undefined;
    if (this.toggExerciseFlag == true) {
      exe_dur = this.exerDuration;
      for (var i = 0; i < this.exerciseRoutineList.length; i++) {
        if (this.exerRoutine == this.exerciseRoutineList[i].description) {
          exe_rout_id = this.exerciseRoutineList[i].exer_rout_id;
        }
      }
    }
    var gen_hist
    gen_hist = {
      gutka: gutka,
      gutka_duration: gut_dur,
      gutka_frequency: gut_frq,
      smoke: smoke,
      smoke_duration: smk_dur,
      smoke_frequency: smk_frq,
      mixing_type: mix_id,
      quantity: qty_id,
      alc_duration: alc_dur,
      alc_frequency: alc_frq,
      alcohol: alch,
      exercise_dur: exe_dur,
      exercise_routine_des: this.exerRoutine,
      exercise_routine: exe_rout_id,
    }
     Physio_Helper.setHabitual(gen_hist);
  }

  changeAlchol(e) {
    this.toggAlcholFlag = e;
    if (e == false) {
      this.alcholFlag = true;
      document.getElementById("togg_alchol_id").style.background = "red";
    } else {
      this.alcholFlag = false;
      document.getElementById("togg_alchol_id").style.background = "green";
    }
  }

  changeSmoke(e) {
    this.toggSmokingFlag = e;
    if (e == false) {
      this.smokeFlag = true;
      document.getElementById("togg_smoke_id").style.background = "red";
    } else {
      this.smokeFlag = false;
      document.getElementById("togg_smoke_id").style.background = "green";
    }
  }

  changeGutka(e) {
    this.toggGutkaFlag = e;
    if (e == false) {
      this.gutkaFlag = true;
      document.getElementById("togg_gutka_id").style.background = "red";
    } else {
      this.gutkaFlag = false;
      document.getElementById("togg_gutka_id").style.background = "green";
    }
  }

  changeExercise(e) {
    this.toggExerciseFlag = e;
    if (e == false) {
      this.exerciseFlag = true;
      document.getElementById("togg_exr_id").style.background = "red";
    } else {
      this.exerciseFlag = false;
      document.getElementById("togg_exr_id").style.background = "green";
    }
  }

  changeFamilySupport(e) {
    this.toggFamilyFlag = e;
    if (e == false) {
      document.getElementById("togg_family_id").style.background = "red";
    } else {
      document.getElementById("togg_family_id").style.background = "green";
    }
  }

  getQuantity() {
    this.quantityList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'dental/aqty',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.quantityList = obj.mixing_quantity;
          this.alcoholQuant = this.quantityList[0].description;
        },
        error => {
        }
      )
  }

  getMixingTypes() {
    this.mixingList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'dental/amix',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.mixingList = obj.mixing_types;
          this.alcoholMix = this.mixingList[0].description;
          this.getRetrievalData();
        },
        error => {
        }
      )
  }

  getExerciseRoutines() {
    this.exerciseRoutineList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'gen/exesrot/',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.exerciseRoutineList = obj.exercise_routine;
          this.exerRoutine = this.exerciseRoutineList[0].description;
        },
        error => {
        }
      )
  }

  getRetrievalData() {
    var obj = Physio_Helper.getRet();
    if(obj != undefined && obj != null){
      if (obj.case_hist_id != null) {
        if (obj.exercise_dur != null) {
          this.exerDuration = obj.exercise_dur;
          this.exerciseFlag = false;
          this.toggExerciseFlag = true;
          if (this.toggExerciseFlag == true) {
            document.getElementById("togg_exr_id").style.background = "green";
          } else {
            document.getElementById("togg_exr_id").style.background = "red";
          }
        }
        if (obj.exercise_routine != null) {
          for (var i = 0; i < this.exerciseRoutineList.length; i++) {
            for (var j = 0; j < obj.exercise_routine.length; j++) {
              if (obj.exercise_routine[j] == this.exerciseRoutineList[i].exer_rout_id) {
                this.exerRoutine = this.exerciseRoutineList[i].description;
              }
            }
          }
          this.exerciseFlag = false;
          this.toggExerciseFlag = true;
        }
      }
    }
  }

  getCommonData() {
    var obj = Physio_Helper.getAsg();
    if (obj.alcohol_mixing_id != null) {
      for (var i = 0; i < this.mixingList.length; i++) {
        for (var j = 0; j < obj.alcohol_mixing_id.length; j++) {
          if (obj.alcohol_mixing_id[j] == this.mixingList[i].mixing_id) {
            this.alcoholMix = this.mixingList[i].description;
          }
        }
      }
      this.alcholFlag = false;
      this.toggAlcholFlag = true;
      if (this.toggAlcholFlag == true) {
        document.getElementById("togg_alchol_id").style.background = "green";
      } else {
        document.getElementById("togg_alchol_id").style.background = "red";
      }
    }
    if (obj.alcohol_qty_id != null) {
      for (var i = 0; i < this.quantityList.length; i++) {
        for (var j = 0; j < obj.alcohol_qty_id.length; j++) {
          if (obj.alcohol_qty_id[j] == this.quantityList[i].alcohol_qty_id) {
            this.alcoholQuant = this.quantityList[i].description;
          }
        }
      }
      this.alcholFlag = false;
      this.toggAlcholFlag = true;
    }
    if (obj.alc_duration != null) {
      this.alcholDuration = obj.alc_duration;
      this.alcholFlag = false;
      this.toggAlcholFlag = true;
    }
    if (obj.alc_frequency != null) {
      this.alcoholFrequency = obj.alc_frequency;
      this.alcholFlag = false;
      this.toggAlcholFlag = true;
    }
    if (obj.smoke_duration != null) {
      this.smokeDuration = obj.smoke_duration;
      this.smokeFlag = false;
      this.toggSmokingFlag = true;
      if (this.toggSmokingFlag == true) {
        document.getElementById("togg_smoke_id").style.background = "green";
      } else {
        document.getElementById("togg_smoke_id").style.background = "red";
      }
    }
    if (obj.smoke_frequency != null) {
      this.smokeFrequency = obj.smoke_frequency;
      this.smokeFlag = false;
      this.toggSmokingFlag = true;
    }
    if (obj.gutka_duration != null) {
      this.gutkaDuration = obj.gutka_duration;
      this.gutkaFlag = false;
      this.toggGutkaFlag = true;
      if (this.toggGutkaFlag == true) {
        document.getElementById("togg_gutka_id").style.background = "green";
      } else {
        document.getElementById("togg_gutka_id").style.background = "red";
      }
    }
    if (obj.gutka_frequency != null) {
      this.gutkaFrequency = obj.gutka_frequency;
      this.gutkaFlag = false;
      this.toggGutkaFlag = true;
    }
  }
}
