<div class="row" style="margin:0 auto; width:100%">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Relations</h5>
          </div>
          <div class="headerButtons">
            <!-- *ngIf="createReationFlag" -->
            <!--  -->
            <img class="saveimgbtn_inpatinfo" src="../../../assets/ui_icons/buttons/new_button.svg" (click)="create()" *ngIf="relationListData.length < 5"  />
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row">
          <div class="col-4 col-sm-3 col-lg-2 col-md-3 col-xl-2" *ngFor="let dataList of relationListData">
            <div (click)="selectRelation(dataList.rel_name,dataList.rel_f_name,dataList.rel_m_name,dataList.rel_l_name,dataList.rel_id,dataList.sub_rel_id,dataList.mobile,dataList.gender_val,dataList.prof_img,dataList.addr1,dataList.addr2,dataList.loc_id,dataList.loc_name,dataList.city_id,dataList.city_desc,dataList.state_id,dataList.state_desc,dataList.country_id,dataList.country_desc,dataList.zipcode,dataList.same_as_self,dataList.bgrp_id,dataList.bgrp_desc,dataList.dob,dataList.imgpath,dataList.salutation)">
              <div class="special_box_class  ">
                <img src="{{dataList.prof_img}}" class="specialization_image ">
                <p class="specialization_heading" style="text-align: center">{{dataList.rel_name}}</p><br>
              </div>
            </div>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>