<div *ngIf="dentalmaterialorderflag == 'min'">
    <!-- <div class="row">
        <div class="col-12">
            <div class="" style="padding: 0 5px;">
                <mat-label class="matlabel margin_space"> <b>Barcode</b> - Sticker to be displayed here<br>
                </mat-label>
            </div>
        </div>
    </div> -->
    <div class="row">
        <div class="borderbdent1">
            <table>
                <tr>
                    <td style="text-align: left;"><b>Name :</b> {{doctor_name}}</td>
                </tr>
                <tr>
                    <td  style="text-align: left;"><b>Clinic :</b> {{clinic_name}}</td>
                </tr>
                <tr>
                    <td  style="text-align: left;"><b>Place :</b> {{doctor_place}}</td>
                </tr>
            </table>

        </div>
    </div>
</div>




<div *ngIf="dentalmaterialorderflag == 'max'">
    <div class="row">
        <div class="col-6">
            <div class="cover_div" style="padding-bottom: 13px;">
                <div class="header_lable">
                    Doctor details
                </div>
                <div class="content_cover">
                    <div class="row">
                        <div class="col-12">
                            <mat-label class="matlabel"> <b>Barcode</b> - Sticker to be displayed here<br>
                            </mat-label>
                        </div>
                        <div class="col-12">
                            <mat-label class="matlabel">Name : {{doctor_name}}</mat-label><br>
                            <mat-label class="matlabel">Clinic : {{clinic_name}}</mat-label><br>
                            <mat-label class="matlabel">Place : {{doctor_place}}</mat-label><br>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="col-6">
            <div class="cover_div" style="padding-bottom: 13px;">
                <div class="header_lable">
                    Patient details
                </div>
                <div class="content_cover">
                    <div class="row">
                        <div class="col-6">
                            <mat-label class="matlabel">Name : {{patent_name}}</mat-label>
                        </div>
                        <div class="col-6">
                            <mat-label class="matlabel">Gender : {{gender_name}}</mat-label>
                        </div>
                        <div class="col-6">
                            <mat-label class="matlabel">Age : {{client_age}}</mat-label>
                        </div>
                        <div class="col-6">
                            <mat-label class="matlabel"> Required Date
                                <input type="date" class="ipcss widthappt" id="appt_date"
                                    (change)="OnDateMatePicker(ReqDate)" [(ngModel)]="ReqDate" max="{{currentDate}}"
                                    #matInput>
                            </mat-label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="cover_div" style="padding-bottom: 13px;">
                <div class="header_lable">
                    Material order
                </div>
                <div class="content_cover">
                    <div class="row">
                        <div class="col-4">
                            <div class="row">
                                <div class="col-10">
                                    <div class="row">
                                        <div class="col-12 col-sm-12  col-md-12 col-lg-12 col-xl-12">
                                            &nbsp;<mat-checkbox color="primary" class="dentalmaterial"
                                                [(ngModel)]="mary_txt" (change)="send_data()"></mat-checkbox>
                                            <mat-label class="matlabel ">Maryland<br> </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-12  col-md-12 col-lg-12 col-xl-12 ">
                                            &nbsp;<mat-checkbox color="primary" class="dentalmaterial"
                                                [(ngModel)]="canti_txt" (change)="send_data()"></mat-checkbox>
                                            <mat-label class="matlabel ">Cantilever<br> </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-12  col-md-12 col-lg-12 col-xl-12 ">
                                            &nbsp;<mat-checkbox class="dentalmaterial" color="primary"
                                                [(ngModel)]="post_txt" (change)="send_data()"></mat-checkbox>
                                            <mat-label class="matlabel ">Post crown<br></mat-label>
                                        </div>
                                        <div class="col-12 col-sm-12  col-md-12 col-lg-12 col-xl-12 ">
                                            &nbsp;<mat-checkbox color="primary" class="dentalmaterial"
                                                [(ngModel)]="gingval_txt" (change)="send_data()"></mat-checkbox>
                                            <mat-label class="matlabel dentalmaterial">Gingiva/ Gum colour <br>
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-12  col-md-12 col-lg-12 col-xl-12">
                                            &nbsp;<mat-checkbox color="primary" class="dentalmaterial"
                                                [(ngModel)]="shoulder_txt" (change)="send_data()"></mat-checkbox>
                                            <mat-label class="matlabel dentalmaterial">Shoulder porcelain<br>
                                            </mat-label>
                                        </div>
                                        <div class="col-12">
                                            <textarea #matInput class="ipcss" required [(ngModel)]="materialType" (change)="send_data()"
                                                (click)="mainconcernpopup()"></textarea>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-2 my-auto" style="text-align: center;">
                                    <a (click)="mat_click()" [disabled]="client"><img src="../../../assets/img/pagination_next.png"
                                            class="saveimgbtn_inpatinfo" /></a>
                                </div>
                            </div>
                        </div>
                        <div class="col-8">
                            <div class="table_cover">
                                <div class="col-12">
                                    <div class="dig_table_overflow">
                                        <table id="tbl" 
                                            class="table table-hover table-dynamic">
                                            <thead>
                                                <tr>
                                                    <th style="width: 30%;">Material</th>
                                                    <th>Maryland</th>
                                                    <th>Gingiva / Gum colour</th>
                                                    <th>Shoulder porcelain</th>
                                                    <th>Cantilever</th>
                                                    <th>Post crown</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody [hidden]="material_array.length==0">
                                                <tr *ngFor="let material of material_array">
                                                    <td style="text-align: left;">{{material.materialdata}}</td>
                                                    <td>{{material.maryland}}</td>
                                                    <td>{{material.gum_colour}}</td>
                                                    <td>{{material.shoulder_procelain}}</td>
                                                    <td>{{material.cantilever}}</td>
                                                    <td>{{material.post_crown}}</td>
                                                    <td>
                                                        <img src="../../../assets/ui_icons/buttons/trash_icon.svg"
                                                            class="saveimgbtn_inpatinfo"
                                                            style="width: 22px; cursor: pointer;"
                                                            (click)="material_delete(material.materialdata)" />
                                                    </td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="cover_div" style="padding-bottom: 13px;">
                <div class="content_cover">
                    <div class="row">
                        <div class="col-12 col-sm-9 col-md-9 col-lg-9 col-xl-9">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style="margin-bottom: 15px;">
                                    <div class="card"
                                        style="padding: 23px 13px;position: relative;box-shadow: 0 0rem 1rem rgb(0 0 0 / 9%) !important;">
                                        <div class="row">
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-6  col-xl-4">
                                                &nbsp;<mat-checkbox color="primary" class="dentalmaterial"
                                                    [(ngModel)]="tube_checkbox" (change)="send_data()"></mat-checkbox>
                                                <mat-label class="matlabel chtxt dentalmaterial">tube<br> </mat-label>
                                            </div>
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-6  col-xl-4 ">
                                                &nbsp;<mat-checkbox color="primary" class="dentalmaterial"
                                                    [(ngModel)]="onlay_check" (change)="send_data()"></mat-checkbox>
                                                <mat-label class="matlabel chtxt">onlay<br></mat-label>
                                            </div>
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-6  col-xl-4">
                                                &nbsp;<mat-checkbox color="primary" class="dentalmaterial" (change)="send_data()"
                                                    [(ngModel)]="inlay_check"></mat-checkbox>
                                                <mat-label class="matlabel chtxt">Inlay<br> </mat-label>
                                            </div>
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12  col-xl-12">
                                                &nbsp;<mat-checkbox color="primary" class="dentalmaterial" (change)="send_data()"
                                                    [(ngModel)]="post_check"></mat-checkbox>
                                                <mat-label class="matlabel chtxt">post & core<br> </mat-label>
                                            </div>
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12  col-xl-12 ">
                                                &nbsp;<mat-checkbox color="primary" class="dentalmaterial" (change)="send_data()"
                                                    [(ngModel)]="metal_check"></mat-checkbox>
                                                <mat-label class="matlabel chtxt">Full metal rest<br> </mat-label>

                                            </div>
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12  col-xl-12">
                                                &nbsp;<mat-checkbox color="primary" class="dentalmaterial matlabel" (change)="send_data()"
                                                    [(ngModel)]="ceram_check"></mat-checkbox>
                                                <mat-label class="matlabel chtxt">Rest with cers coating<br></mat-label>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style="margin-bottom: 15px;">
                                    <div class="card"
                                        style="padding: 15px 13px;position: relative;box-shadow: 0 0rem 1rem rgb(0 0 0 / 9%) !important;">
                                        <div class="row">
                                            <div class="col-8 col-sm-8 col-md-7 col-lg-8 col-xl-8">
                                                <mat-label class="matlabel">Opposing tooth to be restored later
                                                </mat-label>
                                            </div>
                                            <div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                                <mat-checkbox color="primary" [(ngModel)]="opp_yes" 
                                                    class="dentalmaterial matlabel" (change)="Opposing_yes_fun();send_data();"
                                                    value="Yes"> Yes</mat-checkbox>

                                            </div>
                                            <div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                                <mat-checkbox color="primary" [(ngModel)]="opp_no"
                                                    class="dentalmaterial matlabel" (change)="Opposing_no_fun();send_data();"
                                                    value="No"> No</mat-checkbox>

                                            </div>

                                            <div class="col-8 col-sm-8 col-md-7 col-lg-8 col-xl-8">

                                                <mat-label class="matlabel">RCT done</mat-label>
                                            </div>
                                            <div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                                <mat-checkbox color="primary" [(ngModel)]="rct_yes" value="Yes"
                                                    class="matlabel" (change)="rct_yes_fun();send_data();"> Yes</mat-checkbox>

                                            </div>
                                            <div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                                <mat-checkbox color="primary" [(ngModel)]="rct_no" value="No"
                                                    class="matlabel" (change)="rct_no_fun();send_data();"> No</mat-checkbox>

                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <mat-label class="matlabel dentalmaterial">if no occulusal clearance
                                                </mat-label>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-6">
                                                <mat-checkbox color="primary" [(ngModel)]="occu_metal" class="matlabel"
                                                    (change)="occ_clear_metal_fun();send_data();" value="Metal island">Metal island
                                                </mat-checkbox>

                                            </div>
                                            <div class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-6">
                                                <mat-checkbox color="primary" [(ngModel)]="occu_adjust" class="matlabel"
                                                    (change)="occ_clear_adjust_fun();send_data();" value="Adjust opposing"> Adjust
                                                    opposing
                                                </mat-checkbox>
                                            </div>

                                            <div class="col-12">
                                                <mat-checkbox color="primary" [(ngModel)]="occu_reduction"
                                                    class="matlabel" (change)="occ_clear_red_coping_fun();send_data();"
                                                    value="Reduction copi(chargeable)">
                                                    Reduction copi(chargeable)</mat-checkbox>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <div class="cover_div" style="padding-bottom: 13px;">
                                        <div class="header_lable">
                                            Trial
                                        </div>
                                        <div class="content_cover">
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="row">
                                                        <mat-label class="matlabel col-5">
                                                            <mat-checkbox color="primary"
                                                                class="matlabel dentalmaterial" [(ngModel)]="red_wax">
                                                            </mat-checkbox>
                                                            Red wax
                                                        </mat-label>
                                                        <mat-label class="matlabel col-7"> Date
                                                            <input type="date" class="ipcss" id="appt_date"
                                                                (change)="OnDateMatePicker1(red_date);send_data();"
                                                                [(ngModel)]="red_date" max="{{currentDate}}" #matInput
                                                                style="width: 140px;">

                                                            <!-- <input type="date" class="ipcss " [(ngModel)]="red_date" /> -->
                                                        </mat-label>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="row">
                                                        <mat-label class="matlabel col-5">
                                                            <mat-checkbox color="primary"
                                                                class="matlabel dentalmaterial" [(ngModel)]="white_tax">
                                                            </mat-checkbox> white_wax
                                                        </mat-label>
                                                        <mat-label class="matlabel col-7"> Date
                                                            <input type="date" class="ipcss " id="appt_date"
                                                                (change)="OnDateMatePicker2(white_date);send_data();"
                                                                [(ngModel)]="white_date" max="{{currentDate}}" #matInput
                                                                style="width: 140px;">

                                                        </mat-label>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="row">
                                                        <mat-label class="matlabel col-5">
                                                            <mat-checkbox color="primary"
                                                                class="matlabel dentalmaterial" [(ngModel)]="metal_tax">
                                                            </mat-checkbox>Metal
                                                        </mat-label>
                                                        <mat-label class="matlabel col-7"> Date
                                                            <input type="date" class="ipcss " id="appt_date"
                                                                (change)="OnDateMatePicker3(metal_date);send_data();"
                                                                [(ngModel)]="metal_date" max="{{currentDate}}" #matInput
                                                                style="width: 140px;">
                                                        </mat-label>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="row">
                                                        <mat-label class="matlabel col-5">
                                                            <mat-checkbox color="primary"
                                                                class="matlabel dentalmaterial" [(ngModel)]="bisque">
                                                            </mat-checkbox>Bisque(body)
                                                        </mat-label>
                                                        <mat-label class="matlabel col-7"> Date
                                                            <input type="date" class="ipcss " id="appt_date"
                                                                (change)="OnDateMatePicker4(bisque_date);send_data();"
                                                                [(ngModel)]="bisque_date" max="{{currentDate}}"
                                                                #matInput style="width: 140px;">
                                                        </mat-label>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <div class="cover_div" style="padding-bottom: 13px;">
                                        <div class="header_lable">
                                            Pontic design
                                        </div>
                                        <div class="content_cover">
                                            <div class="row">
                                                <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 pad_26">
                                                    <mat-label class="matlabel dentalmaterial">
                                                        <mat-checkbox color="primary" [(ngModel)]="snitary"
                                                            (change)="sant_fun();send_data();" class="dentalmaterial"></mat-checkbox>
                                                    </mat-label>
                                                    <img src="assets/img/material_icons/Sanitary_image.png">
                                                    <div class="matlabel" style="text-align: center;">Sanitary</div>
                                                </div>
                                                <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 pad_26">
                                                    <mat-label class="matlabel dentalmaterial">
                                                        <mat-checkbox color="primary" [(ngModel)]="fullridge"
                                                            (change)="ridge_fun();send_data();" class="dentalmaterial">
                                                        </mat-checkbox>
                                                    </mat-label>
                                                    <img src="assets/img/material_icons/full_ridge.png">
                                                    <div class="matlabel" style="text-align: center;">Fullridge</div>

                                                </div>
                                                <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 pad_26">
                                                    <mat-label class="matlabel dentalmaterial">
                                                        <mat-checkbox color="primary" (change)="modi_fun();send_data();"
                                                            class="dentalmaterial" [(ngModel)]="modified">
                                                        </mat-checkbox>
                                                    </mat-label>
                                                    <img src="assets/img/material_icons/modified.png">
                                                    <div class="matlabel" style="text-align: center;">Modified</div>

                                                </div>
                                                <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 pad_26">
                                                    <mat-label class="matlabel">
                                                        <mat-checkbox color="primary" (change)="bullet_fun();send_data();"
                                                            [(ngModel)]="bullet" class="dentalmaterial "></mat-checkbox>
                                                    </mat-label>
                                                    <img src="assets/img/material_icons/bullet.png">
                                                    <div class="matlabel" style="text-align: center;">Bullet</div>

                                                </div>
                                                <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 pad_26">
                                                    <mat-label class="matlabel">
                                                        <mat-checkbox color="primary" (change)="ovate_fun();send_data();"
                                                            [(ngModel)]="ovate" class="dentalmaterial"></mat-checkbox>
                                                    </mat-label>

                                                    <img src="assets/img/material_icons/bullet.png">
                                                    <div class="matlabel" style="text-align: center;">Ovate</div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3" style="border-left: 2px solid #4299e4;">
                            <div class="row">
                                <div class="col-12">
                                    <mat-label class="matlabel ">Shade</mat-label>
                                    <input class="ipcss " matInput [(ngModel)]="shade_txt" (change)="send_data()" />
                                </div>
                                <div class="col-12">
                                    <mat-label class="matlabel ">Digital photo/mail</mat-label>
                                    <mat-checkbox color="primary" [(ngModel)]="digital_txt" (change)="send_data()"
                                        class="dentalmaterial matlabel"></mat-checkbox>

                                </div>
                                <div class="col-12">
                                    <mat-label class="matlabel ">Cervical</mat-label>
                                    <input class="ipcss " matInput [(ngModel)]="cervical_txt" (change)="send_data()"/>
                                </div>
                                <div class="col-12">
                                    <mat-label class="matlabel ">Middle</mat-label>
                                    <input class="ipcss " matInput [(ngModel)]="middle_txt" (change)="send_data()"/>
                                </div>
                                <div class="col-12">
                                    <mat-label class="matlabel ">Incisal</mat-label>
                                    <input class="ipcss " matInput [(ngModel)]="incisal_txt" (change)="send_data()"/>
                                </div>

                                <div class="col-12">
                                    <img id="Img16" class="up_img" src="{{Material_Upper_Image}}"
                                        usemap="#material_upper_map" />
                                    <map name="material_upper_map">
                                        <area shape="poly" id="Area63" title="U1"
                                            coords="22,26,28,26,31,26,32,21,32,11,32,8"
                                            (click)="material_Upper_Data_click('1')">
                                        <area shape="poly" id="Area64" title="U2"
                                            coords="43,29,55,27,69,27,70,20,69,12,62,8,56,5,46,5,36,9,31,15,31,20,34,7,33,28"
                                            (click)="material_Upper_Data_click('2')">
                                        <area shape="poly" id="Area65" title="U3"
                                            coords="68,14,68,19,70,30,73,30,79,29,79,22,77,15,69,9"
                                            (click)="material_Upper_Data_click('3')">
                                        <area shape="poly" id="Area66" title="U4"
                                            coords="24,61,34,60,34,50,33,36,34,28,29,25,21,25,18,37,18,43,14,54,14,62"
                                            (click)="material_Upper_Data_click('4')">
                                        <area shape="poly" id="Area67" title="U5"
                                            coords="34,33,32,40,32,51,33,62,45,61,70,61,68,52,67,39,68,28,46,28,38,27,32,25"
                                            (click)="material_Upper_Data_click('5')">
                                        <area shape="poly" id="Area68" title="U6"
                                            coords="69,30,69,36,67,50,68,59,73,60,89,60,90,53,90,45,86,38,80,29"
                                            (click)="material_Upper_Data_click('6')">
                                        <area shape="poly" id="Area69" title="U7"
                                            coords="18,60,28,60,34,64,34,73,32,82,26,82,20,78,15,69,15,65,12,62"
                                            (click)="material_Upper_Data_click('7')">
                                        <area shape="poly" id="Area70" title="U8"
                                            coords="39,62,51,62,61,62,69,64,67,73,67,84,53,87,40,86,35,86,33,82,33,70,33,66,33,62"
                                            (click)="material_Upper_Data_click('8')">
                                        <area shape="poly" id="Area71" title="U9"
                                            coords="73,61,80,60,92,61,89,69,86,77,74,85,67,85,69,67"
                                            (click)="material_Upper_Data_click('9')">
                                    </map>
                                    <img src="../../../assets/img/refresh_image.png"
                                        style="height: 25px; width: 25px;margin-left: 32px;"
                                        (click)="refresh_upperclick()" class="refresh_image" />
                                </div>
                                <div class="col-12">
                                    <img class="up_img" id="Img17" src="{{Material_Lower_Image}}"
                                        usemap="#material_lower_map">
                                    <map name="material_lower_map">
                                        <area shape="poly" id="Area72" title="L1"
                                            coords="23,34,33,33,33,29,33,24,33,19,30,19"
                                            (click)="material_Lower_Data_click('1')">
                                        <area shape="poly" id="Area73" title="L2"
                                            coords="34,17,33,21,33,27,34,33,40,32,53,33,58,33,66,32,66,25,66,17,53,14"
                                            (click)="material_Lower_Data_click('2')">
                                        <area shape="poly" id="Area74" title="L3" coords="67,17,66,27,67,33,75,34,77,32"
                                            (click)="material_Lower_Data_click('3')">
                                        <area shape="poly" id="Area75" title="L4"
                                            coords="32,33,34,44,35,54,32,62,28,63,18,63,16,63,13,59,16,45,19,39,21,35"
                                            (click)="material_Lower_Data_click('4')">
                                        <area shape="poly" id="Area76" title="L5"
                                            coords="33,35,33,41,33,47,33,57,33,63,44,63,54,63,63,64,66,57,66,42,65,37,64,35"
                                            (click)="material_Lower_Data_click('5')">
                                        <area shape="poly" id="Area77" title="L6"
                                            coords="66,34,66,38,66,44,66,49,66,55,66,60,67,63,73,63,78,62,84,62,86,62,87,54,86,46,80,36"
                                            (click)="material_Lower_Data_click('6')">
                                        <area shape="poly" id="Area78" title="L7"
                                            coords="17,62,31,64,33,70,32,79,29,85,24,82,19,80,16,73,14,69,17,64,14,64"
                                            (click)="material_Lower_Data_click('7')">
                                        <area shape="poly" id="Area79" title="L8"
                                            coords="35,64,45,63,52,63,64,63,65,69,65,80,50,77,65,83,44,77,42,82,39,84,36,84,31,86"
                                            (click)="material_Lower_Data_click('8')">
                                        <area shape="poly" id="Area80" title="L9"
                                            coords="67,63,67,68,67,77,67,86,74,82,82,77,86,72,86,69,86,65"
                                            (click)="material_Lower_Data_click('9')">
                                    </map>
                                    <img src="../../../assets/img/refresh_image.png" class="refresh_img"
                                        style="height: 25px; width: 25px;margin-left: 32px;"
                                        (click)="refresh_lowerclick()" class="refresh_image" />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
            <div class="cover_div" style="padding-bottom: 13px;">
                <div class="header_lable">
                    Die preparation
                </div>
                <div class="content_cover">
                    <div class="row">
                        <div class="col-6">
                            <mat-checkbox color="primary" (change)="die_yes_fun();send_data();" class="matlabel"
                                [(ngModel)]="die_yes"> Yes</mat-checkbox>
                        </div>
                        <div class="col-6">
                            <mat-checkbox color="primary" (change)="die_no_fun();send_data();" class="matlabel" [(ngModel)]="die_no">
                                NO</mat-checkbox>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
            <div class="cover_div">
                <div class="header_lable">
                    Proximal contacts
                </div>
                <div class="content_cover">
                    <div class="row">
                        <div class="col-6">
                            <mat-checkbox color="primary" class="dentalmaterial matlabel" (change)="Proxi_board_fun();send_data();"
                                [(ngModel)]="proximal_broad">Broad</mat-checkbox>
                        </div>
                        <div class="col-6">
                            <mat-checkbox color="primary" class="dentalmaterial matlabel" (change)="Proxi_point_fun();send_data();"
                                [(ngModel)]="pro_point">Point</mat-checkbox>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
            <div class="cover_div">
                <div class="header_lable">
                    Stains & Effect
                </div>
                <div class="content_cover">
                    <div class="row">
                        <div class="col-6">
                            <mat-checkbox color="primary" class="dentalmaterial matlabel" (change)="Stains_crack_fun();send_data();"
                                [(ngModel)]="stains">Enamel cracks</mat-checkbox>
                        </div>
                        <div class="col-6">
                            <mat-checkbox color="primary" class="dentalmaterial matlabel" (change)="Stains_white_fun();send_data();"
                                [(ngModel)]="whitespot">White spots</mat-checkbox>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
            <div class="cover_div">
                <div class="header_lable">
                    Translucency
                </div>
                <div class="content_cover">
                    <div class="row">
                        <div class="col-4">
                            <mat-checkbox color="primary" (change)="Trans_regular_fun();send_data();" [(ngModel)]="trans_regular"
                                class="dentalmaterial matlabel"> Regular</mat-checkbox>
                        </div>
                        <div class="col-4">
                            <mat-checkbox color="primary" (change)="Trans_low_fun();send_data();" [(ngModel)]="trans_low"
                                class="dentalmaterial matlabel"> Low</mat-checkbox>
                        </div>
                        <div class="col-4">
                            <mat-checkbox color="primary" (change)="Trans_high_fun();send_data();" [(ngModel)]="trann_high"
                                class="dentalmaterial matlabel">High</mat-checkbox>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
            <div class="cover_div">
                <div class="header_lable">
                    Glaze
                </div>
                <div class="content_cover">
                    <div class="row">
                        <div class="col-4">
                            <mat-checkbox color="primary" (change)="Glaze_reg_fun();send_data();" [(ngModel)]="glazeregular"
                                class="dentalmaterial matlabel"> Regular</mat-checkbox>
                        </div>
                        <div class="col-4">
                            <mat-checkbox color="primary" (change)="Glaze_low_fun();send_data();" [(ngModel)]="glazelow"
                                class="dentalmaterial matlabel"> Low</mat-checkbox>
                        </div>
                        <div class="col-4">
                            <mat-checkbox color="primary" (change)="Glaze_high_fun();send_data();" [(ngModel)]="glazehigh"
                                class="dentalmaterial matlabel">High</mat-checkbox>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
            <div class="cover_div">
                <div class="header_lable">
                    Value
                </div>
                <div class="content_cover">
                    <div class="row">
                        <div class="col-4">
                            <mat-checkbox color="primary" (change)="Value_reg_fun();send_data();" [(ngModel)]="valreg"
                                class="dentalmaterial matlabel"> Regular</mat-checkbox>
                        </div>
                        <div class="col-4">
                            <mat-checkbox color="primary" (change)="Value_low_fun();send_data();" [(ngModel)]="vallow"
                                class="dentalmaterial matlabel"> Low</mat-checkbox>
                        </div>
                        <div class="col-4">
                            <mat-checkbox color="primary" (change)="Value_high_fun();send_data();" [(ngModel)]="valhigh"
                                class="dentalmaterial matlabel">High</mat-checkbox>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
            <div class="cover_div">
                <div class="header_lable">
                    Coping design
                </div>
                <div class="content_cover">
                    <div class="row">
                        <div class="col-6">
                            <mat-checkbox color="primary" (change)="copy_indi_fun();send_data();" [(ngModel)]="coping_individual"
                                class="dentalmaterial matlabel"> Individual</mat-checkbox>
                        </div>
                        <div class="col-6">
                            <mat-checkbox color="primary" (change)="copy_spl_fun();send_data();" [(ngModel)]="splinted_cpoing"
                                class="dentalmaterial matlabel"> Splinted (joined)</mat-checkbox>
                        </div>
                        <div class="col-12">
                            <mat-checkbox color="primary" (change)="copy_ling_fun();send_data();" [(ngModel)]="lingual_metal"
                                class="dentalmaterial matlabel"> Lingual metal collar</mat-checkbox>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
            <div class="cover_div">
                <div class="header_lable">
                    Texture
                </div>
                <div class="content_cover">
                    <div class="row">
                        <div class="col-4">
                            <mat-checkbox color="primary" (change)="Texture_no_fun();send_data();" [(ngModel)]="textureno"
                                class="dentalmaterial matlabel"> No</mat-checkbox>
                        </div>
                        <div class="col-4">
                            <mat-checkbox color="primary" (change)="Texture_low_fun();send_data();" [(ngModel)]="textlow"
                                class="dentalmaterial matlabel"> Low</mat-checkbox>
                        </div>
                        <div class="col-4">
                            <mat-checkbox color="primary" (change)="Texture_High_fun();send_data();" [(ngModel)]="texthigh"
                                class="dentalmaterial matlabel">High</mat-checkbox>
                        </div>
                        <div class="col-12">
                            <mat-checkbox color="primary" (change)="Texture_adj_fun();send_data();" [(ngModel)]="followadjust"
                                class="dentalmaterial matlabel">Follow adjacent tooth texture</mat-checkbox>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
            <div class="cover_div">
                <div class="header_lable">
                    Occulusal training
                </div>
                <div class="content_cover">
                    <div class="row">
                        <div class="col-4">
                            <mat-checkbox color="primary" (change)="Occu_no_fun();send_data();" [(ngModel)]="occno"
                                class="dentalmaterial matlabel"> No</mat-checkbox>
                        </div>
                        <div class="col-4">
                            <mat-checkbox color="primary" (change)="Occu_light_fun();send_data();" [(ngModel)]="occulight"
                                class="dentalmaterial matlabel"> Light</mat-checkbox>
                        </div>
                        <div class="col-4">
                            <mat-checkbox color="primary" (change)="Occu_medium_fun();send_data();" [(ngModel)]="occmedium"
                                class="dentalmaterial matlabel">Medium</mat-checkbox>
                        </div>
                        <div class="col-12">
                            <mat-checkbox color="primary" (change)="Occu_dark_fun();send_data();" [(ngModel)]="occdark"
                                class="dentalmaterial matlabel">Dark</mat-checkbox>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="cover_div">
                <div class="content_cover">
                    <div class="row">
                        <div class="col-4">
                            <div style="width: fit-content; margin: 0 auto;">
                                <img src="assets/img/above_e_teen.png" width="250px " draggable="false">
                            </div>

                        </div>
                        <div class="col-8" style="border-left: 2px solid #4299e4;">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <mat-label class="matlabel"> Enclosures </mat-label><br>
                                    <mat-checkbox color="primary" (change)="enclosures_bite_fun($event.checked);send_data();"
                                        [(ngModel)]="enclose_bite">
                                        <mat-label class="matlabel dentalmaterial">Bite</mat-label>
                                    </mat-checkbox><br>
                                    <mat-checkbox color="primary" (change)="enclosures_arti_fun();send_data();"
                                        [(ngModel)]="enclose_articulator">
                                        <mat-label class="matlabel dentalmaterial">Articulator</mat-label>
                                    </mat-checkbox><br>
                                    <mat-checkbox color="primary" (change)="enclosures_shade_fun();send_data();"
                                        [(ngModel)]="enclose_shade">
                                        <mat-label class="matlabel dentalmaterial">Shade tooth/Tab</mat-label>
                                    </mat-checkbox><br>
                                    <mat-checkbox color="primary" (change)="enclosures_pre_op_fun();send_data();"
                                        [(ngModel)]="enclose_preop">
                                        <mat-label class="matlabel dentalmaterial">Pre-op models</mat-label>
                                    </mat-checkbox><br>
                                    <mat-checkbox color="primary" (change)="enclosures_mode_temp_fun();send_data();"
                                        [(ngModel)]="enclose_temporary">
                                        <mat-label class="matlabel dentalmaterial">Model-with-temporary</mat-label>
                                    </mat-checkbox>
                                    <div class="col-12">
                                        <mat-label class="matlabel widthappt">Others</mat-label>
                                        <input class="ipcss widthappt" matInput [(ngModel)]="matimp_others" (change)="send_data()" />
                                    </div>
                                    <div class="col-12">
                                        <mat-label class="matlabel widthappt">Notes</mat-label>
                                        <textarea class="ipcss obsttxtareaheight widthappt" matInput (change)="send_data()"
                                            [(ngModel)]="main_note"></textarea>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <mat-label class="matlabel widthappt col-12">Implant</mat-label><br>
                                    <div class="col-12">

                                        <mat-checkbox color="primary" [(ngModel)]="implant_screw" (change)="send_data()">
                                            <mat-label class="matlabel widthappt">Implant screw driver</mat-label>
                                        </mat-checkbox>
                                    </div>
                                    <div class="col-12">
                                        <mat-label class="matlabel widthappt">No.of impression replica</mat-label>
                                        <input class="ipcss widthappt" matInput [(ngModel)]="immpression_replica" (change)="send_data()"/>
                                    </div>
                                    <div class="col-12">
                                        <mat-label class="matlabel widthappt">No.of impression coping </mat-label>
                                        <input class="ipcss widthappt" matInput [(ngModel)]="impress_coping" (change)="send_data()"/>
                                    </div>
                                    <div class="col-12">
                                        <mat-label class="matlabel widthappt">No.of abutment</mat-label>
                                        <input class="ipcss widthappt" matInput [(ngModel)]="noof_abutment" (change)="send_data()"/>
                                    </div>
                                    <div class="col-12">
                                        <mat-label class="matlabel widthappt">No.of screw</mat-label>
                                        <input class="ipcss widthappt" matInput [(ngModel)]="noof_screw" (change)="send_data()"/>
                                    </div>
                                    <div class="col-12">
                                        <mat-label class="matlabel widthappt">No.of castable abutment</mat-label>
                                        <input class="ipcss widthappt" matInput [(ngModel)]="catable_abdument" (change)="send_data()"/>
                                    </div>
                                    <div class="col-12">
                                        <mat-label class="matlabel widthappt">No.of temporary abutment</mat-label>
                                        <input class="ipcss widthappt" matInput [(ngModel)]="noof_temp" (change)="send_data()"/>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>