import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, HostListener } from '@angular/core';
import { DatePipe } from '@angular/common';
import { CommonDataService } from '../../../app/providers/common-data.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { ToastrService } from 'ngx-toastr';
import { Message_data } from '../../../assets/js/Message_data';
import { Helper_Class } from '../../helper_class';
import { Date_Formate } from '../../../assets/js/common.js';
import { ipaddress } from '../../ipaddress' ;import { MenuViewService } from '../../../app/common-module/menu-view/menu-view.service';
declare var $: any;
import { WebcamImage } from 'ngx-webcam';
import { Subject, Observable } from 'rxjs';
import { ScanDetected } from 'ngx-scanner-detection';
import { PatientRegistrationService } from './patient_registration_service';
import moment from 'moment';

@Component({
  selector: 'app-patient-registration',
  templateUrl: './patient-registration.component.html',
  styleUrls: ['./patient-registration.component.scss']
})
export class PatientRegistrationComponent implements OnInit {
  public ref_source;
  public fulladdress;
  public imgUrl2;
  public patientname;
  fileRemoved: boolean = false;

  public ref_sourcearray=[];
  @ViewChild('idprint') idprint: ElementRef;
  @ViewChild('input', { static: false }) input: ElementRef;
  @ViewChild('imageInput') imageInput: ElementRef;
  public somePlaceholder;
  public covidFlag;
  public updateFlag: boolean;
  public filterType;
  public patientFName;
  public clientList = [];
  public clntFirstName;
  public clntMiddleName;
  public clntLastName;
  public clientId;
  public searchFlag: boolean;
  public filter;
  public referredDoctorListData;
  public readonly: boolean;
  public clntLocationList: any = [];
  public clntCityList: any = [];
  public clntStateList: any = [];
  public clntCountryList: any = [];
  public clntSalList: any = [];
  public hospitalListData: any = [];
  private imageSrc: string = '';
  public ipaddress;
  public sendMiddleName;
  public filteredArr: any = [];
  public patientName;

  public timeOfAppt;
  public send_data;
  public sendUpdateData;
  public clntAddress1: string = null;
  public clntAddress2: string = null;
  public clntLocation: string = null;
  public clntCity: string = null;
  public clntState: string = null;
  public clntCountry: string = null;
  public clntLocationId: string = null;
  public clntCityId: string = null;
  public clntStateId: string = null;
  public clntCountryId: string = null;
  public clntZipcode: string = null;
  public referredDoctor: string = '';
  public clntContNumber: string;
  public clntEmergNumber: number;
  public clntEmergNumber1: number;
  public clntSymptom: string;
  public clntDOB = null;
  public clntSal: string;
  public locationUrl: string;
  public locationNameByUrl: string;
  public cityUrl: string;
  public stateUrl: string;
  public countryUrl: string;
  public salUrl: string;

  public hospId: string;
  private diagId: string;
  public clientRegId: string;
  public relationId: string;
  public subRelId: string;
  public clntGender: string;
  public clntAge;
  public clntFatherOrGuardName: string;
  public clientType: string;
  public splId;
  public currentDatetime;
  public userInfo;
  public ageRead: boolean = false;
  public ageUrl;
  public dobDisabledFlag;
  public maxLength: number;
  public retrvDisabledFlag: boolean;
  public referredDoctorList: any = [];
  public ageDisabledFlag: boolean;
  public clientTypeFlag: boolean;
  public apptTypeFlag: boolean;
  public zipcode_url;
  public appclass: boolean = false;
  public appdisable: boolean = false;
  public searchTypeFlag: boolean;
  private nodob: boolean = false;
  public searchby: string;
  public searchtext: string;
  public mrNumber: string;
  public styleOne: boolean = true;
  public profileImg;
  public patientid: boolean;
  public searchTypeFlagid: boolean;
  public searchTypeFlagmoblile: boolean;
  public name = "1";
  public mobile = "1";
  public patientidflag = "1";
  public typeflag;
  public dateformate
  public aadharNumber;
  public fileType: string;
  public camImage;
  public base64;
  public imgUrl: string;
  public camImage2;
  public base64_2;
  public imgUrl_2: string;
  public bloodGroup: string;
  public primlanguage: string;
  public language: string;
  public blood_donation:boolean;
  public blood: boolean = false;
  public saveFlag: boolean = false;
  public userTypeFlag;
  public existUserFlag: boolean = false;
  public bloodGroupTestArray: any = [];
  public languageDataArray: any = [];
  public webcamImage: WebcamImage = null;
  public webcamImage2: WebcamImage = null;
  public currentDateUrl: string;
  private regUrl: string;
  public fileupload : boolean = true;
  public currentDate;
  public thisnota: boolean = false;
  public thisshowa: boolean = false;
  dtOptions: DataTables.Settings = {};

  format: '' | 'CODE128' | 'CODE128A' | 'CODE128B' | 'CODE128C' | 'EAN' | 'UPC' | 'EAN8' | 'EAN5' | 'EAN2' | 'CODE39' | 'ITF14' | 'MSI' | 'MSI10' | 'MSI11' | 'MSI1010' | 'MSI1110' | 'pharmacode' | 'codabar';

  elementType: 'svg';
  lineColor = '#000000';
  width = 1;
  barcodeheight = 40;
  displayValue = true;
  fontOptions = '';
  font = 'monospace';
  textAlign = 'center';
  textPosition = 'bottom';
  textMargin = 2;
  fontSize = 15;
  background = '#ffffff';
  margin = 5;
  marginTop = 5;
  marginBottom = 5;
  marginLeft = 5;
  marginRight = 5;
  whatsappNumber: any;
  imgStr: any;
  public currentLimit = 1;

  public imgUrll: any;
  public thisnot: boolean = true;
  public thisshow: boolean  = true; 
  public thisnot1: boolean = true;
  public thisshow1: boolean  = false; 
  
  constructor(public frontdeskservice: MenuViewService, public datepipe: DatePipe, public gservice: CommonDataService,
    public http: HttpClient, public toastr: ToastrService, private cdRef: ChangeDetectorRef,
    private regiserService: PatientRegistrationService) {
    this.ageUrl = ipaddress.getIp + "adm/ddif";
    localStorage.setItem("searchbyhospital", "search_hospital");
    this.filterType = "0";
    this.searchFlag = false;
    this.ageRead = false;
    this.clientType = "New";
    this.locationUrl = ipaddress.getIp + "usercontroller/loc";
    this.locationNameByUrl = ipaddress.getIp + "usercontroller/locbyname";
    this.cityUrl = ipaddress.getIp + "gen/city";
    this.stateUrl = ipaddress.getIp + "gen/state";
    this.countryUrl = ipaddress.getIp + "gen/count";
    this.salUrl = ipaddress.getIp + "gen/sal";
    this.zipcode_url = ipaddress.getIp + "usercontroller/locbyzipcode";
    this.currentDateUrl = ipaddress.getIp + "adm/curdate";
    this.regUrl = ipaddress.getIp + "usercontroller/regclntn";
    this.clntAddress1 = "NA";
    this.ipaddress = Helper_Class.getIPAddress();
  }
  public painLevels=[];
  public ageflag:boolean;
  isReadonly() { return true; }

  ngOnInit() {
    this.ageflag=true;
    this.painLevels = [
      { number: 1, active: false, selected: false, activeEmoji: false, blink: false, emoji: '😎' },
      { number: 2, active: false, selected: false, activeEmoji: false, blink: false, emoji: '😉' },
      { number: 3, active: false, selected: false, activeEmoji: false, blink: false, emoji: '😃' },
      { number: 4, active: false, selected: false, activeEmoji: false, blink: false, emoji: '😊' },
      { number: 5, active: false, selected: false, activeEmoji: false, blink: false, emoji: '😱' },
      { number: 6, active: false, selected: false, activeEmoji: false, blink: false, emoji: '😐' },
      { number: 7, active: false, selected: false, activeEmoji: false, blink: false, emoji: '☹️' },
      { number: 8, active: false, selected: false, activeEmoji: false, blink: false, emoji: '😞' },
      { number: 9, active: false, selected: false, activeEmoji: false, blink: false, emoji: '😖' },
      { number: 10, active: false, selected: false, activeEmoji: false, blink: false, emoji: '😭' },
    ];
    this.imgUrll = "../../assets/img/default.jpg";
    this.imgUrl=  "../../assets/img/default.jpg";
    this.imgUrl2 = "../../assets/img/default.jpg";
    $(document).ready(function () {
      $("input").attr("autocomplete", "off");
    });
    this.userInfo = Helper_Class.getInfo()
    this.hospId = this.userInfo.hptl_clinic_id;
    this.diagId = this.userInfo.diag_center_id;
    this.clientType = "New";
    this.clntZipcode = this.userInfo.hospitals[0].zipcode;
    this.clntLocationId = this.userInfo.hospitals[0].location;
    this.clntLocation = this.userInfo.hospitals[0].location_desc;
    this.clntCityId = this.userInfo.hospitals[0].city;
    this.clntCity = this.userInfo.hospitals[0].city_desc;
    this.clntStateId = this.userInfo.hospitals[0].state;
    this.clntState = this.userInfo.hospitals[0].state_desc;
    this.clntCountryId = this.userInfo.hospitals[0].country;
    this.clntCountry = this.userInfo.hospitals[0].country_desc;
    this.selectLocation(this.clntLocationId)
    this.getCurrentDate();
    this.get_Language_Data('0');
    this.get_Bloodgroup_Data('0');
    this.getSalutations();
    this.getreferencesorce();
  }

  getCurrentDate() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.currentDateUrl, { country: "IN" }, { headers: headers }).subscribe(
      data => {
        var dataval = JSON.parse(JSON.stringify(data));
        this.currentDatetime = dataval.current_date;
        this.currentDate = dataval.current_date;
      },
      error => { });
  }

  handle(event: ScanDetected) {
    console.log(event);
    this.input.nativeElement.value = event.barcode;
  }

  focusInput() {
    this.patientName = "";
    document.getElementById("filterInput").focus()
  }
  //public blood_donationvalue;
  donateblood(e){
    this.blood = !this.blood; 
    this.blood_donation = e ;   
  }
  donateblood1() {  
    this.blood = !this.blood;
    if (this.blood_donation == undefined || this.blood_donation == false) {
      this.blood_donation = true;
    } else if (this.blood_donation == true) {
      this.blood_donation = false;
    }
  }

  get_Bloodgroup_Data(retrivel_data) {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp + "bd/bgroup/", { headers: headers }).subscribe(
      data => {
        var dataval = JSON.parse(JSON.stringify(data));
        if (dataval.blood_groups != null) {
          this.bloodGroupTestArray = dataval.blood_groups;
          if (retrivel_data != "null" && retrivel_data != null) {
            for (var t = 0; t < this.bloodGroupTestArray.length; t++) {
              if (this.bloodGroupTestArray[t].blood_group_id == retrivel_data) {
                this.bloodGroup = this.bloodGroupTestArray[t];
                break;
              }
            }
          }
        }
      }, error => { });
  }

  get_Language_Data(retrivel_data) {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp + "usercontroller/lng/", { headers: headers }).subscribe(
      data => {
        var dataval = JSON.parse(JSON.stringify(data));
        if (dataval.languages != null) {
          this.languageDataArray = dataval.languages;
          if (retrivel_data != "0") {
            for (var s = 0; s < this.languageDataArray.length; s++) {
              if (this.languageDataArray[s].language_id == retrivel_data) {
                this.language = this.languageDataArray[s];
                break;
              }
            }
          }
        }
      }, error => { });
  }

  back() {
    this.frontdeskservice.sendMessage("manageappointments");
  }

  fnameToUpper() {
    if (this.clntFirstName != null)
      this.clntFirstName = this.clntFirstName.toLocaleUpperCase();
  }

  mnameToUpper() {
    if (this.clntMiddleName != undefined)
      this.clntMiddleName = this.clntMiddleName.toLocaleUpperCase();
  }

  lnameToUpper() {
    if (this.clntLastName != undefined)
      this.clntLastName = this.clntLastName.toLocaleUpperCase();
  }

  patientNameToUpper() {
    if (this.patientFName != undefined) {
      if (this.filter == "name")
        this.patientFName = this.patientFName.toLocaleUpperCase();
    }
  }

  getCountries(stateid, flag) {
    this.clntCountryList = [];
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.countryUrl, { state_id: stateid }, { headers: headers }).subscribe(
      data => {
        this.clntCountryList = [];
        var dataval = JSON.parse(JSON.stringify(data));
        if (dataval.countries != null) {
          this.clntCountryList = dataval.countries;
          for (var c = 0; c < this.clntCountryList.length; c++) {
            if (this.clntCountryList[c].country_id == this.clntCountryId) {
              this.clntCountry = this.clntCountryList[c].country_desc;
              this.clntCountryId = this.clntCountryList[c].country_id;
              //this.getStates(this.clntCountryId, flag);
              break;
            }
          }
        }
      },
      error => {
        this.toastr.error(Message_data.unableToFetchData);
      });
  }

  getStates(city, flag) {
    this.clntStateList = [];
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.stateUrl, { city_id: city },
      { headers: headers }).subscribe(
        data => {
          var dataval = JSON.parse(JSON.stringify(data));
          if (dataval.states != null) {
            this.clntStateList = dataval.states;
            if (this.clntStateList.length == 1) {
              for (var i = 0; i < this.clntStateList.length; i++) {
                if (this.clntStateList[i].state_id == this.clntStateId) {
                  this.clntStateId = this.clntStateList[i].state_id;
                  this.clntState = this.clntStateList[i].state_desc;
                  this.getCountries(this.clntStateId, '0');
                  break;
                } else {
                  this.clntStateId = this.clntStateList[0].state_id;
                  this.clntState = this.clntStateList[0].state_desc;
                  this.getCountries(this.clntStateId, '0');
                }
              }

            } else {
              this.clntStateId = this.clntStateList[0].state_id;
              this.clntState = this.clntStateList[0].state_desc;
              this.getCountries(this.clntStateId, '0');
            }

          }
        });
  }

  changeState(clnt_state, flag) {
    for (var i = 0; i < this.clntStateList.length; i++) {
      if (this.clntStateList[i].stage_desc == clnt_state) {
        this.clntStateId = this.clntStateList[i].state_id;
        this.clntState = this.clntStateList[i].stage_desc;
        this.getCountries(this.clntStateId, '0');
      }
    }
  }

  getCities(state, flag) {
    this.clntCityList = [];
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.cityUrl, { loc_name: this.clntLocation, zipcode: this.clntZipcode },
      { headers: headers }).subscribe(
        data => {
          var dataval = JSON.parse(JSON.stringify(data));
          if (dataval.cities != null) {
            this.clntCityList = dataval.cities;
            if (this.clntCityList.length == 1) {
              for (var i = 0; i < this.clntCityList.length; i++) {
                if (this.clntCityList[i].city_id == this.clntCityId) {
                  this.clntCityId = this.clntCityList[i].city_id;
                  this.clntCity = this.clntCityList[i].city_desc;
                  this.getStates(this.clntCityId, 0)

                  break;
                } else {
                  this.clntCityId = this.clntCityList[0].city_id;
                  this.clntCity = this.clntCityList[0].city_desc;
                  this.getStates(this.clntCityId, 0)
                }
              }

            } else {
              this.clntCityId = this.clntCityList[0].city_id;
              this.clntCity = this.clntCityList[0].city_desc;
              this.getStates(this.clntCityId, 0)
            }
          }
        });
  }

  changeCity(clnt_city, flag) {
    this.clntLocationList = [];
    for (var i = 0; i < this.clntCityList.length; i++) {
      if (this.clntCityList[i].city_desc == clnt_city) {
        this.clntCityId = this.clntCityList[i].district_id;
        this.clntCity = this.clntCityList[i].city_desc;
        this.getStates(this.clntCityId, 0)
      }
    }
  }

  getLocations(city) {
    this.clntLocationList = [];
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.locationUrl, { city_id: city },
      { headers: headers }).subscribe(
        data => {
          var dataval = JSON.parse(JSON.stringify(data));
          if (dataval.locations != null && dataval.locations.length != 0) {
            this.clntLocationList = dataval.locations;
            for (var i = 0; i < this.clntLocationList.length; i++) {
              if (this.clntLocationList[i].location_id == this.clntLocationId) {
                this.clntLocationId = this.clntLocationList[i].location_id;
                this.clntLocation = this.clntLocationList[i].description;
                this.clntZipcode = this.clntLocationList[i].pincode;
                this.clntLocationList = [];
                break;
              }
            }
          }
        });
  }

  zipcode_change() {
    if (this.clntZipcode.length > 4) {
      this.clntLocationList = [];
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.zipcode_url, {
        zipcode: this.clntZipcode,
      }, { headers: headers }).subscribe(
        data => {
          var obj = JSON.parse(JSON.stringify(data))
          if (obj.locations != null && obj.locations.length != 0) {
            if (obj.locations.length == 1) {
              this.clntLocationId = obj.locations[0].location_id;
              this.clntLocation = obj.locations[0].description;
              this.clntZipcode = obj.locations[0].pincode;
              this.getCities(this.clntLocation, 0);
            } else {
              this.clntLocationList = obj.locations;
              console.log("../location" + JSON.stringify(this.clntLocationList))
            }
          }
        },
        error => {
          this.toastr.error("Unable to fetch location details, please try later");
        }
      )
    } else {
      this.clntLocationList = [];
    }
  }

  changeLocation(locat_desc) {
    this.clntLocation = locat_desc;
    this.clntLocationList = [];

    if (this.clntLocation != null && this.clntLocation.length > 2) {
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.locationNameByUrl, {
        name: this.clntLocation,
        //city_id: this.clntCityId,
      }, { headers: headers }).subscribe(
        data => {
          var dataval = JSON.parse(JSON.stringify(data));
          if (dataval.locations != null && dataval.locations.length != 0)
            this.clntLocationList = dataval.locations;
        },
        error => {
          this.toastr.error(Message_data.unableToFetchData);
        });
    } else {
      this.clntLocationList = [];
    }
  }

  selectLocation(location) {
    for (var i = 0; i < this.clntLocationList.length; i++) {
      if (this.clntLocationList[i].location_id == location) {
        //this.clntLocation = this.clntLocationList[i];
        this.clntLocationId = this.clntLocationList[i].location_id;
        this.clntLocation = this.clntLocationList[i].description;
        this.clntZipcode = this.clntLocationList[i].pincode;
      }
    }
    this.clntLocationList = [];
    this.getCities(this.clntLocation, '0');
    if (this.clientType == "Existing") {
      this.updateFlag = true;
    }
  }

  getReferral(e) {// Refer doctor list
    this.referredDoctor = e.target.value.toString();
    this.referredDoctorList = [];
    this.referredDoctorListData = [];
    var dname = this.checkData(this.referredDoctor);
    if (dname != "" && dname.length > 2) {
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'search/docbyhns/',
        {
          hospital_id: this.hospId,
          doc_name: dname
        },
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));

            this.referredDoctorListData = obj.doctors;

            if (this.referredDoctorListData != undefined) {
              for (var i = 0; i < this.referredDoctorListData.length; i++) {
                var docname = "";
                docname = this.referredDoctorListData[i].middle_name != undefined ? this.referredDoctorListData[i].first_name + " " + this.referredDoctorListData[i].middle_name + " " + this.referredDoctorListData[i].last_name : this.referredDoctorListData[i].first_name + " " + this.referredDoctorListData[i].last_name;
                this.referredDoctorList.push(docname);
              }
            }
            this.referredDoctorList = this.referredDoctorList.filter(function (el) {
              return el.toLowerCase().indexOf(dname.toLowerCase()) > -1;
            }.bind(this));
          },
          error => {
            this.toastr.error(Message_data.getNetworkMessage());
          });
    } else {
      this.referredDoctorList = [];
    }
  }

  selectReferredDoctor(item) {
    this.referredDoctor = item;
    this.referredDoctorList = [];
  }

  getSalutations() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.get(this.salUrl, { headers: headers }).subscribe(
      data => {
        var obj = JSON.parse(JSON.stringify(data));
        if (obj != undefined) {
          for (var i = 0; i < obj.salutations.length; i++) {
            this.clntSalList.push({
              sal_id: obj.salutations[i].salutation_id,
              sal_desc: obj.salutations[i].salutation_desc,
            });
          }
          this.clntSal = "select";
          this.changeSalutation(this.clntSal);
        }
      });
  }

  getDateMatePicker1(e) {
    var month = ((e.getMonth() + 1) < 10 ? '0' : '') + (e.getMonth() + 1);
    var day = (e.getDate() < 10 ? '0' : '') + e.getDate();
    var apt = e.getFullYear() + "-" + month + "-" + day;
    return apt.trim();
  }

  registerPatient() {
    var valid_flag = false;

    if (this.clntAddress1 == undefined) {
      this.toastr.error("Enter Address");
      valid_flag = true;
    }
    if (this.clntFirstName == undefined || this.clntFirstName == "") {
       this.toastr.error("Enter First name");
       valid_flag = true;
     }
    if (this.clntFirstName == undefined || this.clntFirstName == "") {
      this.toastr.error("Enter First name");
      valid_flag = true;
    }

    if (this.clntLastName == undefined || this.clntLastName == "") {
      this.toastr.error("Enter Last name");
      valid_flag = true;
    }

    if (this.clntAge == undefined || this.clntAge == "") {
      this.toastr.error("Enter Age");
      valid_flag = true;
    }
    if (this.clntContNumber == undefined || this.clntContNumber == "") {
      this.toastr.error("Enter Mobile number");
      valid_flag = true;
    }
    if (this.whatsappNumber != undefined && this.whatsappNumber.length != 10) {
      this.toastr.error("Enter Whatsapp number");
      valid_flag = true;
      
    }
    
    if (this.clntSal == "select" || this.clntGender == "") {
      this.toastr.error("Select salutation or gender");
      valid_flag = true;
      
    }
    if (this.clntAddress1 == "") {
      this.toastr.error("Enter Address");
      valid_flag = true;
      
    }
    if (this.clntZipcode == "") {
      this.toastr.error("Enter Zipcode");
      valid_flag = true;
      
    }
    if (this.clntLocation == "") {
      this.toastr.error("Enter Location");
      valid_flag = true;
      
    }
    if (this.clntCity == "") {
      this.toastr.error("Enter City");
      valid_flag = true;
      
    }
    if (this.clntState == "") {
      this.toastr.error("Enter State");
      valid_flag = true;
      
    }
    if (this.clntCountry == "") {
      this.toastr.error("Enter Country");
      valid_flag = true;
      
    }
    if (valid_flag == false) {
      if (this.clntAge != null)
        var client_app_age = this.clntAge;

      if (this.clntMiddleName != null)
        var clnt_middle_name = encrypt_decript.Encript(this.clntMiddleName).toString();
      
      var cdob;
      if (this.clntDOB != null) {
        // cdob = this.clntDOB;
        // var client_send_dob = cdob;
        cdob =(this.clntDOB)
        this.dateformate=Date_Formate(this.clntDOB)
      } else {
        var yearval = this.currentDatetime.split("-")[0];
        var yeardif = parseInt(yearval) - parseInt(this.clntAge);
        cdob=yeardif+"-01-01";
      }

      if (this.subRelId != null)
        var client_sub_rel_id = this.subRelId;

      var addr1;
      addr1 = this.clntAddress1 != null ? encrypt_decript.Encript(this.clntAddress1).toString() : null;

      var addr2;
      addr2 = this.clntAddress2 != undefined ? encrypt_decript.Encript(this.clntAddress2).toString() : null;
      if(this.clntAddress2 !=  undefined){
        this.fulladdress = (this.clntAddress1).toString()+","+(this.clntAddress2).toString()+","
      }else{
        this.fulladdress = (this.clntAddress1).toString()+",";

      }
      
      var address: any = [];
      address.push({
        address1: addr1,
        address2: addr2,
        location: this.clntLocationId,
        city: this.clntCityId,
        state: this.clntStateId,
        country: this.clntCountryId,
        zipcode: encrypt_decript.Encript(this.clntZipcode).toString(),
        type: "pres",
        is_permanent: "1"
      });
      if(this.clntMiddleName != undefined){
        this.patientname = this.clntFirstName+" "+this.clntMiddleName+" "+this.clntLastName;
      }else{
        this.patientname = this.clntFirstName+" "+this.clntLastName;
      }

      var clientFName, lastName, gender, contactNo, emgContNo, emgContNo1, aadharNo, whatsappNo;
      if (this.clntFirstName != null && this.clntFirstName != undefined)
        clientFName = encrypt_decript.Encript(this.clntFirstName).toString();

      if (this.clntLastName != null && this.clntLastName != undefined)
        lastName = encrypt_decript.Encript(this.clntLastName).toString();

      if (this.clntGender != null && this.clntGender != undefined)
        gender = encrypt_decript.Encript(this.clntGender).toString();

      if (this.clntContNumber != null && this.clntContNumber != undefined)
        contactNo = encrypt_decript.Encript(this.clntContNumber).toString();

      if (this.aadharNumber != null && this.aadharNumber != undefined)
        aadharNo = encrypt_decript.Encript(this.aadharNumber).toString();

      if (this.whatsappNumber != null && this.whatsappNumber != undefined)
        whatsappNo = encrypt_decript.Encript(this.whatsappNumber).toString();

      if (this.clntEmergNumber != null && this.clntEmergNumber != undefined)
        emgContNo = encrypt_decript.Encript(this.clntEmergNumber).toString();

      if (this.clntEmergNumber1 != null && this.clntEmergNumber1 != undefined)
        emgContNo1 = encrypt_decript.Encript(this.clntEmergNumber1).toString();
      if (this.referredDoctor.trim() === '') {
          // Handle empty value, maybe show a message to the user
      } else {
        var referredDoctor=this.referredDoctor;
       }
      this.send_data = {
        profile_image: this.base64,
        client_id: this.clientRegId,
        salutation: this.clntSal,
        first_name: clientFName,
        middle_name: clnt_middle_name,
        last_name: lastName,
        gender: gender,
        age: client_app_age,
        dob: cdob,
        mobile: contactNo,
        SSN_PAN_number: aadharNo,
        whatsapp_number: whatsappNo,
        emerg_contact1: emgContNo,
        emerg_contact2: emgContNo1,
        address: address,
        guardian: this.clntFatherOrGuardName,
        hptl_clinic_id: this.hospId,
        referred_by: referredDoctor,
        country: ipaddress.country_code,
        id_proof: this.base64_2,
        rep: true,
        diag_centre_id: this.diagId,
        blood_group:this.bloodGroup,
        language:this.primlanguage,
        ref_source:this.ref_source
      };
      this.confirmRegistration();
    } 
    // else {
    //   this.toastr.error(Message_data.mandatory);
    // }
  }

  confirmRegistration() {
    console.log('POST response:'+JSON.stringify(this.send_data));
    this.regiserService.postData(this.regUrl, this.send_data).subscribe(
      (response) => {
        console.log('POST response:', response);
        var dataval = response;
        if (dataval.key == "1") {
          if (this.clientRegId == "0") {
            this.clientRegId = dataval.client_id;
          }
          this.toastr.success(dataval.result);
          this.mrNumber = dataval.pat_id;
          console.log("this.mrNumber", this.mrNumber)
          this.profileImg = ipaddress.Ip_with_img_address + dataval.profile_image;
          console.log("this.profileImg", this.profileImg)

          this.cdRef.detectChanges();
          setTimeout(() => {
            this.printIdCard();
            this.frontdeskservice.sendMessage('manageappointments');
          }, 1000);

        } else if (dataval.key == "2") {
          this.toastr.error(dataval.result);
        } else {
          this.toastr.error(dataval.result);
        }
      });
  }

  printIdCard() {
    if (this.clientType == "New") {
      let printContents, popupWin;
      printContents = this.idprint.nativeElement.innerHTML;
      popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
      popupWin.document.open();
      popupWin.document.write(`
          <head>
            <title>Bill</title>
            <link rel="stylesheet" media="screen" href="">
            <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">
            <style>
            @page { size: auto;  margin: 0mm; }
            .table_class {
              background-color: rgb(108, 180, 248);
            }        
            </style>
          </head>
              <body onload="window.print();window.onfocus=function(){ window.close();}">
              
              ${printContents}
              

              </body>
            </html>`
      );
      popupWin.document.close();
    }
    else {
      console.log("ID card alredy Printed");
    }
  }

  validateMobile() {
    if (this.clntContNumber != undefined && this.clntContNumber.length != 10) {
      this.toastr.error("Enter valid mobile number")
      this.saveFlag = false;
    } else {
      this.whatsappNumber = this.clntContNumber;
      this.saveFlag = true;
      this.checkClientDetailsExists();
    }  
  }

  checkClientDetailsExists() {   
    var sendData;
    if (this.clntMiddleName != undefined) {
       sendData =  {         
        first_name : this.clntFirstName,
        middle_name: this.clntMiddleName,
        last_name: this.clntLastName,
        mobile: this.clntContNumber
      }
    } else {
       sendData =  {         
        first_name : this.clntFirstName,
        last_name: this.clntLastName,
        mobile: this.clntContNumber
      }
    }
    console.log("mnjhgyuy"+JSON.stringify(sendData))
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'usercontroller/ice', sendData,   
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log("mnjhgyuy obj"+JSON.stringify(obj))
            if (obj.result == true) {
              this.clntFirstName = " ";
              this.clntMiddleName = " ";
              this.clntLastName = " ";
              this.clntContNumber = " ";
              this.whatsappNumber = " ";
              this.toastr.error("Patient details already exists");
            }           
        })  
  }

  validateAadhar() {
    if (this.aadharNumber != undefined && this.aadharNumber.length != 12) {
      this.toastr.error("Enter valid Aadhar number")
    }
  }

  validatewhatsapp() {
    if (this.whatsappNumber != undefined && this.whatsappNumber.length != 10) {
      this.toastr.error("Enter valid whatsapp number")
    }
  }

  selectDob(e) {
    this.clntDOB = e;
    this.ageRead = true;
    if(this.clntDOB != undefined){
      this.ageflag =false;
    }else{
      this.ageflag =true;
    }
    if(e != null)
    this.ageDisabledFlag = true;
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.ageUrl, {
      dobdate: Date_Formate(this.clntDOB),
      curdate: Date_Formate(this.currentDatetime),
      spl: this.splId,
    }, { headers: headers }).subscribe(
    data => {
      var obj = JSON.parse(JSON.stringify(data));
      if (obj != undefined)
      if(this.clntDOB != undefined){
        this.ageflag =false;
      }
      this.clntAge = obj.value;
    });
  }

  ChangeAge() {

    //if(this.clntDOB == undefined) {
      if (this.clntAge != "") {
        this.dobDisabledFlag = true;
       
      } else {
        this.dobDisabledFlag = false;
      }
      var dateval = this.currentDate.split('-');
      if(this.clntAge.length >3){
        var agearray = this.clntAge.split(' ');
        if(agearray.length >5) {
          var dateTo = moment(this.currentDate).subtract(agearray[0], 'year').format("YYYY-MM-DD");
          var dateTo1 = moment(dateTo).subtract(agearray[2], 'months').format("YYYY-MM-DD");
          var dateTo2 = moment(dateTo1).subtract(agearray[4], 'days').format("YYYY-MM-DD");
          this.clntDOB = dateTo2;
         
        } else if(agearray.length > 2 && agearray.length < 5) {
          if(agearray[1] =="y," && agearray[3] =="m") {
            var dateTo = moment(this.currentDate).subtract(agearray[0], 'year').format("YYYY-MM-DD");
            var dateTo1 = moment(dateTo).subtract(agearray[2], 'months').format("YYYY-MM-DD");
            this.clntDOB = dateTo1;
  
          } else if(agearray[1] =="y," && agearray[3] =="d") {
            var dateTo = moment(this.currentDate).subtract(agearray[0], 'year').format("YYYY-MM-DD");
            var dateTo1 = moment(dateTo).subtract(parseInt(agearray[2])-1, 'days').format("YYYY-MM-DD");
            this.clntDOB = dateTo1;
  
          } else if(agearray[1] =="m," && agearray[3] =="d") {
            var dateTo1 = moment(this.currentDate).subtract(agearray[0], 'months').format("YYYY-MM-DD");
            var dateTo2 = moment(dateTo1).subtract(agearray[2], 'days').format("YYYY-MM-DD");
            this.clntDOB = dateTo2;
          } 
  
        } else if(agearray.length < 3 ) {
          if(agearray[1] =="y") {
            var dateTo = moment(this.currentDate).subtract(agearray[0], 'year').format("YYYY-MM-DD");
            this.clntDOB = dateTo;
  
          } else if(agearray[1] =="m") {
            var dateTo = moment(this.currentDate).subtract(agearray[0], 'months').format("YYYY-MM-DD");
            this.clntDOB = dateTo;
            
          } else if(agearray[1] =="d") {
            var dateTo = moment(this.currentDate).subtract(agearray[0], 'days').format("YYYY-MM-DD");
            this.clntDOB = dateTo;
          } 
        }
     
      } else {
        this.clntDOB = (parseInt(dateval[0]) - parseInt(this.clntAge)) + '-01-01';
      }
    //}
  }

  changeSalutation(e) {
    if (e == "1" || e == "6")
      this.clntGender = "Male";
    else if (e == "2" || e == "3" || e == "4" || e == "7")
      this.clntGender = "Female";
    else if (e == "5")
      this.clntGender = "Transgender";
    else if (e == "8")
      this.clntGender = "Female";
    else if (e == "10")
      this.clntGender = "Male";
    else
      this.clntGender = "";
  }

  changeGender(e) {
    if (this.clntAge != undefined && this.clntAge != null && this.clntAge != "") {
      if (e == "Male") {
        if (this.clntAge <= 15)
          this.clntSal = "6";
        else
          this.clntSal = "1";
      } else if (e == "Female") {
        if (this.clntAge <= 15)
          this.clntSal = "7";
        else
          this.clntSal = "2";
      } else if (e == "Transgender") {
        this.clntSal = "5";
      } else {
        this.clntSal = "";
      }

    } else {
      if (e == "Male") {
        this.clntSal = "1";
      } else if (e == "Female") {
        this.clntSal = "2";
      } else if (e == "Transgender") {
        this.clntSal = "5";
      } else {
        this.clntSal = "";
      }
    }
  }

  checkData(value) {
    var data = "";
    if (value != undefined && value != null && value != "" && value != "undefined" && value != "null")
      data = value;
    else
      data = "";
    return data;
  }

  private trigger: Subject<void> = new Subject<void>();
  triggerSnapshot(): void {
    this.trigger.next();
  }
  

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }
  // ----------------------------

  private trigger2: Subject<void> = new Subject<void>();
  triggerSnapshot2(): void {
    this.trigger2.next();
  }


  public get triggerObservable2(): Observable<void> {
    return this.trigger2.asObservable();
  }
  
  handleImage(webcamImage: WebcamImage): void {
    this.thisshow = true;
    this.thisnot = false;

    console.info('Saved webcam image', webcamImage);
    this.webcamImage = webcamImage;
    this.camImage = new Image();
    this.camImage.src = this.webcamImage.imageAsDataUrl;
    const maxWidth = 800; // Adjust as needed
    const maxHeight = 300; // Adjust as needed
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    // Calculate the new width and height while preserving aspect ratio
    let newWidth = this.camImage.width;
    let newHeight = this.camImage.height;
    if (newWidth > maxWidth || newHeight > maxHeight) {
        const aspectRatio = newWidth / newHeight;
        if (newWidth > maxWidth) {
            newWidth = maxWidth;
            newHeight = newWidth / aspectRatio;
        }
        if (newHeight > maxHeight) {
            newHeight = maxHeight;
            newWidth = newHeight * aspectRatio;
        }
    }

    // Set canvas dimensions to the new dimensions
    canvas.width = newWidth;
    canvas.height = newHeight;

    // Draw the image on the canvas with the new dimensions
    context.drawImage(this.camImage, 0, 0, newWidth, newHeight);

    // Convert the canvas content back to a data URL
    const resizedDataUrl = canvas.toDataURL('image/jpeg', 0.7); // Adjust the quality (0.0 - 1.0) as needed

    // Set the resized image as the source for the camImage
    this.camImage = new Image();
    this.camImage.src = resizedDataUrl;
    // Reset imgUrl and base64 when handling webcam image
    // this.imgUrl = '';
    // this.base64 = '';
    
    // Update base64 and imgUrl only if webcamImage is defined
    if (this.webcamImage) {
      this.base64 = this.webcamImage.imageAsBase64;
      this.imgUrl = this.webcamImage.imageAsDataUrl;
    }
  }
  handleImage2(webcamImage2: WebcamImage): void {
    console.info('Saved webcam image', webcamImage2);
    this.webcamImage2 = webcamImage2;
    this.camImage = new Image();
    this.camImage.src = this.webcamImage2.imageAsDataUrl;
    this.imgUrl_2 = '';
    this.base64_2 = '';
    const maxWidth = 800; // Adjust as needed
    const maxHeight = 300; // Adjust as needed
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    // Calculate the new width and height while preserving aspect ratio
    let newWidth = this.camImage.width;
    let newHeight = this.camImage.height;
    if (newWidth > maxWidth || newHeight > maxHeight) {
        const aspectRatio = newWidth / newHeight;
        if (newWidth > maxWidth) {
            newWidth = maxWidth;
            newHeight = newWidth / aspectRatio;
        }
        if (newHeight > maxHeight) {
            newHeight = maxHeight;
            newWidth = newHeight * aspectRatio;
        }
    }

    // Set canvas dimensions to the new dimensions
    canvas.width = newWidth;
    canvas.height = newHeight;

    // Draw the image on the canvas with the new dimensions
    context.drawImage(this.camImage, 0, 0, newWidth, newHeight);

    // Convert the canvas content back to a data URL
    const resizedDataUrl = canvas.toDataURL('image/jpeg', 0.7); // Adjust the quality (0.0 - 1.0) as needed

    // Set the resized image as the source for the camImage
    this.camImage = new Image();
    this.camImage.src = resizedDataUrl;
    // Reset imgUrl and base64 when handling webcam image
    // this.imgUrl = '';
    // this.base64 = '';
    
    // Update base64 and imgUrl only if webcamImage is defined
    if (this.webcamImage) {
      console.log('url', this.webcamImage2.imageAsBase64)
      this.base64_2 = this.webcamImage2.imageAsBase64;
      this.imgUrl_2 = this.webcamImage2.imageAsDataUrl;
    } 
    
  
  }
  
  handleInputChange(e): void {
    this.thisnot = true;
    this.thisshow = false;
    // this.imgUrl = '';
    // this.base64 = '';
    // this.imgUrl2 = '';
    // this.base64 = '';
    // this.imgUrl_2='';
    const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    const pattern = /image-*/;
    const reader = new FileReader();
  
    if (!file.type.match(pattern)) {
      this.toastr.error('Invalid format');
      return;
    }
  
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }
  _handleReaderLoaded(e): void {
    const reader = e.target;
    const base64 = reader.result.split(',')[1];
    this.imgUrl = reader.result;
    this.imgUrll = reader.result;

    this.base64 = base64;

    console.log(this.imgUrl);
    console.log(base64);
  }
  getImageStyle(): { [key: string]: string } {
    // Define your height and width values here
    const height = '200px';
    const width = '200px';
    return {
      'height.px': height,
      'width.px': width
    };
  }

  handleInputChange1(e): void {
    this.thisnota = false;
    this.thisshowa = true;
   
    // this.imgUrl2 = '';
    // this.imgUrl_2='';
    const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    const pattern = /image-*/;
    const reader = new FileReader();
  
    if (!file.type.match(pattern)) {
      this.toastr.error('Invalid format');
      return;
    }
  
    reader.onload = this._handleReaderLoaded1.bind(this);
    reader.readAsDataURL(file);
  }
  
  _handleReaderLoaded1(e): void {
    const reader = e.target;
    const base64_2 = reader.result.split(',')[1];
    this.imgUrl_2 = reader.result;
    this.imgUrl2 = reader.result;
    this.base64_2 = base64_2;

  
    console.log(this.imgUrl);
    console.log(base64_2);
  }
  changeidproofimage(){
    // this.imgUrl_2 = '';
    // this.base64_2 = '';
    this.thisnota = true;
    this.thisshowa = false;
  }
 
  getreferencesorce(){
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp + "gen/glc", { headers: headers }).subscribe(
      data => {
        this.ref_sourcearray=[];
        var obj = JSON.parse(JSON.stringify(data));
        console.log("get ref source --"+JSON.stringify(obj))
      if (obj != undefined) {
          for (var i = 0; i < obj.lead_channels.length; i++) {
            this.ref_sourcearray.push({
              lead_type_id: obj.lead_channels[i].channel_id,
              lead_desc: obj.lead_channels[i].channel_desc,
            });
          }
         // this.ref_source = "select";
          //this.changeref(this.ref_source);
        }
      });
  }
  changeref(e){
    this.ref_source=e
  }
  

  handleHover(level) {
    this.painLevels.forEach(item => item.active = item === level);
  }

  handleClick(selectedLevel) {
    this.painLevels.forEach(item => {
      item.selected = item === selectedLevel;
      item.activeEmoji = item === selectedLevel;
      item.blink = item === selectedLevel;
    });
  }
  handlePainScaleClic1k(level){

  }
  painScaleLevels = Array.from({ length: 10 }, (_, index) => index + 1);
  public selectedPainScaleLevel;
  handlePainScaleClick(level: number): void {
    console.log(`Pain scale level ${level} clicked`);
    // You can add your logic here for handling the click event
    this.selectedPainScaleLevel = level;
  }

  getEmojiForLevel(level: number): string {
    // Check if the level is the selected level, then return the corresponding emoji
    if (level === this.selectedPainScaleLevel) {
      switch (level) {
        case 1:
          return '😉';
        case 2:
          return '😉';
        case 3:
          return '😃';
        case 4:
          return '😊';
        case 5:
          return '😱';
        case 6:
          return '😐';
        case 7:
          return '☹️';
        case 8:
          return '😞';
        case 9:
          return '😖';
        case 10:
          return '😭';
        default:
          return '😃'; // You can set a default emoji if needed
      }
    } else {
      return '😃'; // You can set a different emoji for non-selected levels
    }
  }
  preventKeyboardInput(event: KeyboardEvent) {
    event.preventDefault(); // Prevent keyboard input
  }

  removeFile(value) {
    if(value == "image"){
      this.fileRemoved = true;
      this.thisshow=false;
      this.thisnot=false;
      this.imgUrll = "../../../assets/img/default.jpg";
      this.imgUrl=  "../../../assets/img/default.jpg";
    }else{
      this.fileRemoved = true;
      this.thisshowa=false;
      this.thisnota=false;
      this.imgUrl2 = "../../../assets/img/default.jpg";
    }
  }
}