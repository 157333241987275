<div mat-dialog-title class="popup_border_icd">
  <h5 class="header_txt">Icd</h5>
  <div mat-dialog-actions>
    <img src="../../../assets/img/close_icon.png" (click)="ClosePopup()" width="20px" height="20px" id="close_icd" />
  </div>
</div>
<div mat-dialog-content class="icd_content">
  <div class="container">
    <div class="row">
      <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 ">
        <mat-label>Chapter<br>
          <mat-select class="ipcss" [(ngModel)]="chapter_txt">
            <mat-option disabled value="Select">Select</mat-option>
            <mat-option *ngFor="let chapter of chapterlist" (click)="Chapter_change(chapter_txt)"
              value={{chapter.description}}>{{chapter.description}}</mat-option>
          </mat-select>
        </mat-label>
      </div>
      <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
        <mat-label>Topic<br>
          <mat-select class="ipcss" [(ngModel)]="topic_txt">
            <mat-option disabled value="Select">Select</mat-option>
            <mat-option *ngFor="let topic of topiclist" (click)="topic_Change(topic.description)"
              value={{topic.description}}>{{topic.description}}</mat-option>
          </mat-select>
        </mat-label>
      </div>
      <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
        <mat-label>Section<br>
          <mat-select class="ipcss" [(ngModel)]="sec_txt">
            <mat-option disabled value="Select">Select</mat-option>
            <mat-option *ngFor="let Section of sectionlist" (click)="section_Change(Section.description)"
              value={{Section.description}}>{{Section.description}}</mat-option>
          </mat-select>
        </mat-label>
      </div>
      <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6" [hidden]="subsection_div">
        <mat-label>Sub section<br>
          <mat-select class="ipcss" [(ngModel)]="subsec_txt">
            <mat-option disabled value="Select">Select</mat-option>
            <mat-option *ngFor="let Subsection of subsectionlist" (click)="subsection_Change(Subsection.description)"
              value={{Subsection.description}}>{{Subsection.description}}</mat-option>
          </mat-select>
        </mat-label>
      </div>
      <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6" [hidden]="code_div">
        <mat-label>Code<br>
          <mat-select class="ipcss" [(ngModel)]="subsec_txt">
            <mat-option disabled value="Select">Select</mat-option>
            <mat-option *ngFor="let Subsection of subsectionlist" (click)="subsection_Change(Subsection.description)"
              value={{Subsection.description}}>{{Subsection.description}}</mat-option>
          </mat-select>
        </mat-label>
      </div>
      <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6" [hidden]="subcode_div">
        <mat-label>Sub code<br>
          <mat-select class="ipcss" [(ngModel)]="subcode_txt">
            <mat-option disabled value="Select">Select</mat-option>
            <mat-option *ngFor="let Subcode of subcodelist" value="Subcode.description">{{Subcode.description}}
            </mat-option>
          </mat-select>
        </mat-label>
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions align="end" style="width: auto;right: 19px;">
    <button (click)="icdsubmit_click()" id="add_btn" [mat-dialog-close]="dis_txt" cdkFocusInitial mat-button>
      <img src="../../../assets/ui_icons/buttons/Done_button.svg" class="addimgbtn_icd" />
  </button>
</div>