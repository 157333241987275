import { Component, OnInit,HostListener } from '@angular/core';
import { Helper_Class } from '../../helper_class';
import { Router, ActivatedRoute } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { PhysioCasesheetService } from '../physio-casesheet/physio-casesheet.service';
import { Physio_Helper } from '../Physio_Helper';
import { Message_data } from '../../../assets/js/Message_data';
import { Date_Formate } from 'src/assets/js/common';
import { ipaddress } from '../../ipaddress';

@Component({
  selector: 'app-physio-treatment-plan',
  templateUrl: './physio-treatment-plan.component.html',
  styleUrls: ['./physio-treatment-plan.component.scss']
})
export class PhysioTreatmentPlanComponent implements OnInit {
  public treatmentFlag = "min";
  subscription:Subscription;
  public therapyMasterList = [];
  public prognosisList = [];
  public remarksList=[];
  public therapyList=[];
  public visitFreq: string;
  public referredDoctor: string;
  public therapy: string;
  public caseHistId: string;
  public currentDate: string;
  public investigation;
  public nextVisit;
  public nextVisitFlag: boolean;
  public sittingLabel;
  public sittingFlag: boolean;
  public visitLabel;
  public visitFlag:boolean;
  public prognosisLabel;
  public prognosisFlag: boolean;
  public remarks;
  public prognosis: string;
  public sittings: string;
  public presDiagId:string;
  public sendTherapyFlag: string;
  public therapies;
  public therapyLabel: string;
  public showTherapyFlag:boolean;
  public addPrognosisFlag: boolean = false;
  public Select = "Select";
  public therapyTableFlag:boolean;
  public prognosisTableFlag:boolean;
  public notesTableFlag:boolean;
  public userInfo;

  constructor(public dropdown_dialog:MatDialog,public dialog:MatDialog,public messageservice:PhysioCasesheetService,public http:Http,public routes:ActivatedRoute,public router:Router,public toastr:ToastrService)   {
    this.remarksList=[];
    this.nextVisitFlag = true;
    this.prognosisLabel = true;
    this.showTherapyFlag= true;
  }

  ngOnInit(): void {
    this.subscription = this.messageservice.getCasesheetMessage().subscribe(message => {
      if (message) {
          this.treatmentFlag = message;
      }
    });
    this.remarksList =[];
    this.userInfo = Helper_Class.getInfo();
    if (Physio_Helper.getHopitalfieldsRet() != undefined) {
      for (var i = 0; i < Physio_Helper.getHopitalfieldsRet().length; i++) {
        if (Physio_Helper.getHopitalfieldsRet()[i].page_name == "Treatment plan") {
          var fieldsvalue = Physio_Helper.getHopitalfieldsRet()[i].fields;
          for (var j = 0; j < fieldsvalue.length; j++) {
            if (fieldsvalue[j].field_name == "Next visit") {
              this.nextVisit = fieldsvalue[j].field_name;
              this.nextVisitFlag = false;
            }
            if (fieldsvalue[j].field_name == "Prognosis") {
              this.prognosisLabel = fieldsvalue[j].field_name;
              this.prognosisFlag = false;
            }
            if (fieldsvalue[j].field_name == "No of sittings") {
              this.sittingLabel = fieldsvalue[j].field_name;
              this.sittingFlag = false;
            }
            if (fieldsvalue[j].field_name == "Visit frequency") {
              this.visitLabel = fieldsvalue[j].field_name+" (days)";
              this.visitFlag = false;
            }
            if (fieldsvalue[j].field_name == "Therapies") {
              this.therapyLabel = fieldsvalue[j].field_name;
              this.showTherapyFlag = false;
            }
          }
        }
      }
    }
    this.getCurrentDate();
    this.getTherapyHistory();
    this.changetab('prognosis');
  }

  addTherapy(){
    if(this.therapy == undefined || this.sittings == undefined || this.visitFreq == undefined){
      this.toastr.error(Message_data.mandatory);
    } else {
      var therapyname;
      for(var i=0;i<this.therapyMasterList.length;i++){
        if(this.therapyMasterList[i].value == this.therapy){
          therapyname = this.therapyMasterList[i].label;
        }
      }
      this.therapyList.push({
        therapy_id:this.therapy,
        therapy_name:therapyname,
        sittings: this.sittings,
        frequency: this.visitFreq
      });
      this.therapyTableFlag = true;
    }
    this.sendTherapyFlag = "yes";
  }

  deleteTherapy(data){
    for(var i=0; i<this.therapyList.length; i++){
      if(this.therapyList[i].therapy_id == data){
        this.therapyList.splice(i,1);
      }
    }
    if(this.therapyList.length ==0){
      this.sendTherapyFlag = "no"
    }
  }

  @HostListener('window:click', ['$event'])
  onclick(event: any): void {
    this.sendData();
  }
  @HostListener('window:blur', ['$event'])
  onBlur(event: any): void {
    this.sendData();
  }

  addRemarks() {
    var get_date = this.currentDate.split('-');
    if(this.remarks != undefined && this.remarks != ""){
        this.remarksList.push({
          remarks_data: this.remarks,
          Doct_Name: localStorage.getItem("dr_first_name") + " " + localStorage.getItem("dr_middle_name") + " " + localStorage.getItem("dr_last_name"),
          Date: get_date[2] + '-' + get_date[1] + '-' + get_date[0],
          add_new: "1"
        });
      }
      this.remarks = "";
  }

  getTherapyHistory() {
    this.therapyMasterList = [];
    this.therapyMasterList = Physio_Helper.getTherapies();
    this.getTreatementPlans();
  }

  updateTherapy(){
    var therapylistval=[];
    for (var j = 0; j < this.therapyList.length; j++) {
      this.therapyList[j].checked = true;
      therapylistval.push({
        type: 'checkbox',
        value: this.therapyList[j].therapy_id,
        label: this.therapyList[j].therapy_name,
        checked: true
      })
    }
  }

  therapyHistory(){
  }

  sendData() {
    var diag_pres_id = undefined, med_pres_id = undefined, exercise_id = null, yoga_flag = null, yoga_txt = undefined, nxt_vis = undefined, dit_fol_flg = null, dit_avoid_flg = null, type_id = null;
    if (this.prognosisList != undefined) {
      for (var i = 0; i < this.prognosisList.length; i++) {
        if (this.prognosisList[i].add_new.toString() != "0") {
          var prog_arry = (
            this.prognosisList[i].prognosis
          )
        }
      }
    }
    var remarks_data = undefined;
    if (this.remarksList != undefined) {
      for (var i = 0; i < this.remarksList.length; i++) {
        if (this.remarksList[i].add_new == "1") {
          remarks_data = this.remarksList[i].remarks_data;
          break;
        }
      }
    }
    var diab_treat_plan = null;
    diab_treat_plan = {
      investigation: this.investigation,
      country: ipaddress.country_code,
      refered_to: this.referredDoctor,
      next_visit: nxt_vis,
      pres_diag_id: diag_pres_id,
      prognosis: prog_arry,
      remarks:remarks_data,
      physio_therapy:this.therapyList,
      therapy_flag:this.sendTherapyFlag,
    }
    Physio_Helper.setDiabTreat(diab_treat_plan);
  }

  getCurrentDate(){
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate',
      JSON.stringify({
        country: ipaddress.country_code.toString()
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.current_date != null) {
            this.currentDate = obj.current_date;
          }
        },error => {});
  }

  addPrognosis(){
    var flag = true;
    if (this.prognosis == undefined || this.prognosis == "") {
      this.toastr.error(Message_data.addProg);
      flag = false;
    }
    var docname;
    if(this.userInfo.middle_name != undefined && this.userInfo.middle_name != 'undefined'){
      docname= this.userInfo.first_name + " " + this.userInfo.middle_name + " " + this.userInfo.last_name;
    } else {
      docname= this.userInfo.first_name + " " + this.userInfo.last_name;
    }
    if (flag == true) {
      this.prognosisList.push({
        date: Date_Formate(this.currentDate),
        prognosis: this.prognosis,
        doc_name: docname,
        add_new: "1"
      });
      this.addPrognosisFlag = true;
    }
    this.prognosis = "";
  }

  editPrognosis(data) {
    if (data.add_new == "1") {
      this.prognosis = data.prognosis;
      for (var i = 0; i < this.prognosisList.length; i++) {
        if (this.prognosisList[i].add_new == data.add_new) {
          this.prognosisList.splice(i);
        }
      }
      this.addPrognosisFlag = false;
    }
  }

  isReadonly() { return true; }

  getTreatementPlans() {
    if (Physio_Helper.getRet() != undefined) {
      var obj = Physio_Helper.getRet();
      this.investigation = obj.investigation;
      this.referredDoctor= obj.refer_txt;
      this.visitFreq=obj.visit_freq;
      this.sittings= obj.no_of_visits;
      this.presDiagId= obj.diag_pres_id;
      this.caseHistId= obj.case_hist_id;
      if (obj.physio_therapy != null && obj.physio_therapy !=0) {
        for (var j = 0; j < obj.physio_therapy.length; j++) {
          this.therapyList.push({
            therapy_id: obj.physio_therapy[j].therapy_id,
            therapy_name: obj.physio_therapy[j].therapy_name,
            sittings: obj.physio_therapy[j].sittings,
            frequency: obj.physio_therapy[j].frequency,
          });
        }
        if(this.therapyList.length !=0){
        }
      }
      if (obj.prognosis != undefined && obj.prognosis != null) {
        for (var i = 0; i < obj.prognosis.length; i++) {
          var get_date = obj.prognosis[i].date.split('-');
          var docname;
          if(obj.prognosis[i].middle_name != undefined && obj.prognosis[i].middle_name!= null){
            docname=obj.prognosis[i].first_name + " " + obj.prognosis[i].middle_name + " " + obj.prognosis[i].last_name
          } else{
            docname=obj.prognosis[i].first_name + " " + obj.prognosis[i].last_name
          }
          this.prognosisList.push({
            date: get_date[2] + "-" + get_date[1] + "-" + get_date[0],
            doc_name: docname,
            prognosis: obj.prognosis[i].prognosis,
            add_new: "0",
          });
        }
      }
      if (obj.remarks != undefined && obj.remarks !='undefined') {
        for (var j = 0; j < obj.remarks.length; j++) {
          var get_date = obj.remarks[j].date.split('-');
          this.remarksList.push({
            remarks_data: obj.remarks[j].remarks,
            Doct_Name: docname,
            Date: get_date[2] + "-" + get_date[1] + "-" + get_date[0],
            add_new: "0"
          })
        }
      }
    }
  }  

  editRemarks(data) {
    if (data.add_new == "1") {
      this.remarks = data.remarks_data;
      for (var i = 0; i < this.remarksList.length; i++) {
        if (this.remarksList[i].add_new == data.add_new) {
          this.remarksList.splice(i);
        }
      }
    }
  }
  
  changetab(e){
    if( e == "therapy"){
      this.therapyTableFlag = true;
      this.prognosisTableFlag = false;
      this.notesTableFlag = false;
    }
    if( e == "prognosis"){
      this.therapyTableFlag = false;
      this.prognosisTableFlag = true;
      this.notesTableFlag = false;
    }
    if(e == "notes"){
      this.therapyTableFlag = false;
      this.prognosisTableFlag = false;
      this.notesTableFlag = true;
    }
  }
}
