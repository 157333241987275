import { Component, OnInit } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Helper_Class } from '../../helper_class';
import { Date_Formate, Time_Formate } from '../../../assets/js/common';
import { Location } from '@angular/common';
import { Message_data } from '../../../assets/js/Message_data';
import { ToastrService } from 'ngx-toastr';
import { Physio_Helper } from '../Physio_Helper';
import { PhysioService } from '../physio/physio.service';
import { ipaddress } from '../../ipaddress';

@Component({
  selector: 'app-physio-assign-list',
  templateUrl: './physio-assign-list.component.html',
  styleUrls: ['./physio-assign-list.component.scss']
})
export class PhysioAssignListComponent implements OnInit {
  public userInfo;
  public userId;
  public currentMaxDate = null;
  public retrvAppointmentDate = null;
  public appointmentDate = null;
  public cancelList = [];
  public nodapp: boolean;
  public homecare: string;
  public appointmentList = [];
  is_admin: boolean = false;
  hptl_clinic_id: any;
  doctorid: any;
  physionameList: any[];


  constructor(public http: Http, public toastr: ToastrService, public location: Location, public messageService: PhysioService) { }
  
  ngOnInit(): void {
    this.userInfo = Helper_Class.getInfo();
    this.userId = this.userInfo.user_id;
    this.getCurrentDate();
    this.changeProviderType();
  }
  changeProviderType() {

    if (Helper_Class.getInfo().user_type == 'Admin') {
      this.hptl_clinic_id = Helper_Class.getInfo().hptl_clinic_id;
      this.is_admin = true;
      this.getphysioList();
    }

  }
  changephysio() {
    this.userId = this.doctorid;
    this.getCurrentDate();
  }
  getphysioList() {
    this.physionameList = [];
    var send_data = {
      center_id: this.hptl_clinic_id,
      type: "6"
    };
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(ipaddress.getIp.toString() + 'adm/guth', JSON.stringify(send_data), { headers: headers })
      .subscribe(
        (response) => {
          var obj = JSON.parse(response["_body"]);
          console.log('check---' + JSON.stringify(obj));
          if (obj != null) {
            for (var i = 0; i < obj.users.length; i++) {
              var physioName = '';
              var fname, mname, lname;
              if (
                obj.users[i].middle_name != '' &&
                obj.users[i].middle_name != undefined
              ) {
                physioName =
                  obj.users[i].first_name +
                  ' ' +
                  obj.users[i].middle_name +
                  ' ' +
                  obj.users[i].last_name;
                fname = obj.users[i].first_name;
                mname = obj.users[i].middle_name;
                lname = obj.users[i].last_name;
              } else {
                physioName =
                  obj.users[i].first_name + ' ' + obj.users[i].last_name;
                fname = obj.users[i].first_name;
                lname = obj.users[i].last_name;
              }
              this.physionameList.push({
                PhysioName: physioName,
                physiodata_id: obj.users[i].user_id,

              });
            }
          }
        },
        (error) => {

        }
      );
  }
  getCurrentDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'adm/curdate',
      JSON.stringify({
        country: ipaddress.country_code
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.current_date != null) {
            this.currentMaxDate = obj.current_date;
            this.appointmentDate = obj.current_date;
            this.getDocAppointmentList(this.userId);
          }
        },error => {});
  }

  getDocAppointmentList(data): void {
    this.appointmentList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'hcare/getasg',
      JSON.stringify({
        prov_id: this.userId,
        country: ipaddress.country_code.toString(),
        home_care: "1",
        date: this.appointmentDate,
        imei: this.userInfo.imei_no,
        type: "physio"
      }),
      { headers: headers })
      .subscribe(
        response => {
          var data = JSON.parse(response["_body"]);
          if (data.appointments != null) {
            if (data.appointments.length != "0") {
              for (var i = 0; i < data.appointments.length; i++) {
                var time = Time_Formate(data.appointments[i].schedule_time);
                var appdate = data.appointments[i].start_date.split('-');
                this.retrvAppointmentDate = appdate[2] + "-" + appdate[1] + "-" + appdate[0]
                var clientname;
                if (data.appointments[i].client_middle_name != undefined || data.appointments[i].client_middle_name != null) {
                  clientname = encrypt_decript.Decript(data.appointments[i].client_first_name) + " " + encrypt_decript.Decript(data.appointments[i].client_middle_name) + " " + encrypt_decript.Decript(data.appointments[i].client_last_name);
                } else {
                  clientname = encrypt_decript.Decript(data.appointments[i].client_first_name) + " " + encrypt_decript.Decript(data.appointments[i].client_last_name);
                }
                var docname = data.appointments[i].doc_first_name + " " +  data.appointments[i].doc_last_name; //data.appointments[i].doc_middle_name + " " +;
                var apptext = this.retrvAppointmentDate + "-" + time + " appointment for " + clientname + " by " + docname;
                this.appointmentList.push({
                  appointment: apptext,
                  app_date: this.retrvAppointmentDate,
                  doc_app_id: data.appointments[i].assignment_id,
                  checked: false,
                });
              }
              this.nodapp = true; 
            } else {
              this.nodapp = false; 
            }
          } else {
            this.toastr.error(Message_data.getNetworkMessage());
          }
        }, error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
        // console.log(this.appointmentList);
  }

  multiCancelAppointment(app_id, check) {
    if (check == false) {
      this.cancelList.push(app_id);
      for (var i = 0; i < this.appointmentList.length; i++) {
        if (app_id == this.appointmentList[i].doc_app_id) {
          this.appointmentList[i].checked = true;
        }
      }
    } else {
      for (var i = 0; i < this.cancelList.length; i++) {
        if (app_id == this.cancelList[i]) {
          this.cancelList.splice(this.cancelList.indexOf(app_id), 1);
          break;
        }
      }
      for (var j = 0; j < this.appointmentList.length; j++) {
        if (app_id == this.appointmentList[j].doc_app_id) {
          this.appointmentList[j].checked = false;
        }
      }
    }
  }

  viewAppt(doc_app_id) {
    var assignDetailsView = {
      app_id: doc_app_id,
      home_care : "1"
    }
    Physio_Helper.setAssignDetailsView(assignDetailsView);
    this.messageService.sendMessage("assignmentDetailPage")
  }

  selectAppointmentDate(value) {
    this.appointmentDate = value;
    this.getDocAppointmentList(this.userId);
  }
  
  cancel() {
    if (this.cancelList != undefined && this.cancelList.length != 0) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'appointment/cancelapp',
        JSON.stringify({
          np_cancel: this.cancelList,
          home_care: "1",
          type: "physio",
          reason: "Busy"
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            if (obj.key != null) {
              if (obj.key == "1") {
                this.toastr.success(Message_data.appcancel);
                this.getDocAppointmentList(this.userId);
              }
            }
          },
          error => {
            this.toastr.error(Message_data.defaultErr);
          }
        )
    } else {
      this.toastr.error(Message_data.sltApptToCancel);
    }
  }
}
