<div class="row">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Home</h5>
          </div>
          <div class="headerButtons">
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row">
          <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3" style="margin: 10px 0" [hidden]="heartFlag">
            <mat-card class="cardcontent">
              <mat-card-title style="font-size: 14px;">
                <img src='../../../assets/ui_icons/client_dashboard/Heart_icon.png' width="20px" height="auto" />
                Heart
              </mat-card-title>
              <mat-card-content style="border-top:1px solid gray;">
                <div class="row" style="margin-top: 5px;">
                  <div class="col-12" style="text-align: center;">
                    <img src="../../../assets/ui_icons/client_dashboard/heart_rate_icon.svg" class="heartimg">
                    <p class="sub_contentvalue">{{heartRate}}bpm</p>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
          <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3" style="margin: 10px 0" [hidden]="bpFlag">
            <mat-card class="cardcontent">
              <mat-card-title style="font-size: 14px;">
                <img src='../../../assets/ui_icons/client_dashboard/BP_icon.png' width="20px" height="auto" />
                Bp
              </mat-card-title>
              <mat-card-content style="border-top:1px solid gray;">
                <div class="row" style="margin-top: 5px;">
                  <div class="col-10 col-sm-8 col-md-8 col-lg-8 col-xl-8">
                    <span class="leftch" style="float:left">Sys- {{BP01}}mmHg 
                    </span>
                  </div>
                  <div class="col-2 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                    <p style="color: #000;" class="ptag"> <img
                        src="../../../assets/ui_icons/client_dashboard/heart_icon_bp.svg" class="small_icon">
                    </p>
                  </div>
                  <div class="col-10 col-sm-8 col-md-8 col-lg-8 col-xl-8"> <span class="leftch"
                      style="float:left">Dia- {{BP02}}mmhg  </span> </div>
                  <div class="col-2 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                    <p style="color: #000;" class="ptag"> <img
                        src="../../../assets/ui_icons/client_dashboard/heart_icon_bp.svg" class="small_icon">
                    </p>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
          <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3" style="margin: 10px 0" [hidden]="bpFlag">
            <mat-card class="cardcontent">
              <mat-card-title style="font-size: 14px;">
                <img src='../../../assets/ui_icons/client_dashboard/BP_icon.png' width="20px" height="auto" />
                Know your Health
              </mat-card-title>
              <mat-card-content style="border-top:1px solid gray;">
                <div class="row" style="margin-top: 5px;">
                  <img src='../../../assets/ui_icons/client_dashboard/sample_gif.gif' width="100%"
                  height="auto" />
                </div>
              </mat-card-content>
            </mat-card>
          </div>
          <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3" style="margin: 10px 0" [hidden]="bloodFlag">
            <mat-card class="cardcontent">
              <mat-card-title style="font-size: 14px;">
                <img src='../../../assets/ui_icons/client_dashboard/Blood_glucose_icon.png' width="20px"
                  height="auto" />
                Blood glucose
              </mat-card-title>
              <mat-card-content style="border-top:1px solid gray;">
                <div class="row" style="margin-top: 5px;">
                  <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <span style="float:left">Fasting </span>
                  </div>
                  <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <p style="color: #000;" class="ptag"> {{fasting}}mg/dl </p>
                  </div>
                  <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <span style="float:left">Postprandial </span>
                  </div>
                  <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <p style="color: #000;" class="ptag"> {{postprandial}}mg/dl 
                    </p>
                  </div>
                  <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <span style="float:left">Hba1c </span>
                  </div>
                  <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <p style="color: #000;" class="ptag">  {{hba1c}}mg/dl </p>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
          <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3" style="margin: 10px 0" [hidden]="stepFlag">
            <mat-card class="cardcontent">
              <mat-card-title style="font-size: 14px;">
                <img src='../../../assets/ui_icons/client_dashboard/Steps_icon.svg' width="20px" height="auto" />
                Step count
              </mat-card-title>
              <mat-card-content style="border-top:1px solid gray;">
                <div class="row" style="margin-top: 17px;">
                  <div class="col-12">
                    <div id="" role="progressbar_step" aria-valuenow="--value" aria-valuemin="1000" aria-valuemax="100"
                      style="--value:{{value}}" class="walkingarch">
                      <img src="../../../assets/ui_icons/client_dashboard/walking_man_icon.svg" class="walkimg">
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
          <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3" style="margin: 10px 0" >
            <mat-card class="cardcontent">
              <mat-card-title style="font-size: 14px;">
                <img src='../../../assets/ui_icons/client_dashboard/Diet_icon.png' width="20px" height="auto" />
                Nutri tracker
              </mat-card-title>
              <mat-card-content style="border-top:1px solid gray;">
                <div class="row" style="margin-top: 17px;">
                  <div class="col-12 d-flex justify-content-center" (click)="viewDietTracker()">
                    <div style="display: flex;">
                      <div title="Morning - {{morningPercentage}}" style="position: relative;">
                        <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet" focusable="false"
                          aria-hidden="true" viewBox="-1 -1 34 34" style="width: 170px;height:170px;">
                          <circle cx="16" cy="16" r="15.9155" class="progress-bar__background" stroke-width="1.2px" />
                          <circle id="morningProg" cx="16" cy="16" r="15.9155" stroke="#78bec7" stroke-width="1.2px"
                            class="progress-bar__progress 
                            js-progress-bar" title="Morning" data-tooltip="Morning" />
                        </svg>
                        <div style="position: absolute;top: 13%;left: 12%;" title="Lunch - {{lunchPercentage}}">
                          <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet" focusable="false"
                            aria-hidden="true" viewBox="-1 -1 34 34" style="width: 130px;height:130px;">
                            <circle cx="16" cy="16" r="15.9155" class="progress-bar__backgroundlunch" />
                            <circle id="lunchProg" cx="16" cy="16" r="15.9155" stroke="#78bec7" class="progress-bar__progresslunch 
                            js-progress-barlunch" title="lunch" data-tooltip="lunch" />
                          </svg>
                        </div>
                        <div style="position: absolute;top: 25%; left: 23%;" title="Dinner - {{dinnerPercentage}}">
                          <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet" focusable="false"
                            aria-hidden="true" viewBox="-1 -1 34 34" style="width: 90px;height:90px;">
                            <circle cx="16" cy="16" r="15.9155" class="progress-bar__backgrounddinner"
                              stroke-width="2px" />
                            <circle id="dinnerProg" cx="16" cy="16" r="15.9155" stroke="#78bec7" stroke-width="2px"
                              class="progress-bar__progressdinner 
                            js-progress-bardinner" title="dinner" data-tooltip="dinner" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
          <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3" style="margin: 10px 0" [hidden]="medicineFlag">
            <mat-card class="cardcontent">
              <mat-card-title style="font-size: 14px;">
                <img src='../../../assets/ui_icons/client_dashboard/Medicine_icon.png' width="20px" height="auto" />
                Medicines
              </mat-card-title>
              <mat-card-content style="border-top:1px solid gray;">
                <div class="row" style="margin-top: 5px;" *ngIf="medicineHistList.length !=0">
                  <div class="col-12" *ngFor="let medicine of medicineHistList | slice: 0 : 2">
                    <div class="row">
                      <div class="col-2 col-sm-4 col-md-4 col-lg-4 col-xl-4" style="text-align: center;">
                        <img src="{{medicine.tabimg}}" class="image_view">
                      </div>
                      <div class="col-10 col-sm-8 col-md-8 col-lg-8 col-xl-8 d-flex align-items-center">
                        <span
                          style="float:left">{{medicine.drug_name}}&nbsp;{{medicine.quantity}}-{{medicine.short_form}}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-8" *ngIf="medicineHistList.length ==0">
                    <div class="nodata">No medicines(s) found</div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
          <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3" style="margin: 10px 0" [hidden]="activityFlag">
            <mat-card class="cardcontent">
              <mat-card-title style="font-size: 14px;">
                <img src='../../../assets/ui_icons/client_dashboard/Exercise_icon.png' width="20px" height="auto" />
                Activity
              </mat-card-title>
              <mat-card-content style="border-top:1px solid gray;">
                <div class="row" style="margin-top: 17px;">
                  <div class="col-12 d-flex justify-content-center" (click)="viewActivity('yes')">
                    <div style="display: flex;">
                      <div title="Calories burned - {{finalKcalBurned}}" style="position: relative;">
                        <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet" focusable="false"
                          aria-hidden="true" viewBox="-1 -1 34 34" style="width: 170px;height:170px;">
                          <circle cx="16" cy="16" r="15.9155" class="progress-bar__backgroundcalburn" stroke="red"
                            stroke-width="1.2px" />
                          <circle id="lunchProg" cx="16" cy="16" r="15.9155" stroke="red" stroke-width="1.2px" class="progress-bar__progresscalburn 
                          js-progress-barcalburn" title="lunch" data-tooltip="cal_burn" />
                        </svg>
                        <div style="position: absolute;top: 12%;left: 12%;" title="Time duration - {{timeDuration}}">
                          <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet" focusable="false"
                            aria-hidden="true" viewBox="-1 -1 34 34" style="width: 130px;height:130px;">
                            <circle cx="16" cy="16" r="15.9155" class="progress-bar__backgroundtimedur" stroke="green"
                              stroke-width="1.5px" />
                            <circle id="morningProg" cx="16" cy="16" r="15.9155" stroke="green" stroke-width="1.5px"
                              class="progress-bar__progresstimedur 
                            js-progress-bartimedur" title="Morning" data-tooltip="time_duration" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
          <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3" style="margin: 10px 0" [hidden]="mindfulFlag">
            <mat-card class="cardcontent">
              <mat-card-title style="font-size: 14px;">
                <img src='../../../assets/ui_icons/client_dashboard/BP_icon.png' width="20px" height="auto" />
                Mindfulness
              </mat-card-title>
              <mat-card-content style="border-top:1px solid gray;">
                <div class="row" style="margin-top: 5px;" (click)="mindFullness()">
                  <img src='../../../assets/ui_icons/client_dashboard/sample_gif.gif' width="100%" height="auto" />
                </div>
              </mat-card-content>
            </mat-card>
          </div>
          <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3" style="margin: 10px 0" [hidden]="sleepFlag">
            <mat-card class="cardcontent">
              <mat-card-title style="font-size: 14px;">
                <img src='../../../assets/ui_icons/client_dashboard/Sleep_icon.png' width="20px" height="auto" />
                Sleep
              </mat-card-title>
              <mat-card-content style="border-top:1px solid gray;">
                <div class="row">
                  <div class="col-12">
                    <p class="nodata">No data found</p>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>