<div mat-dialog-title class="popup_border_icd">
  <h5 class="header_txt">Please provide reason for cancellation</h5>
  <div mat-dialog-actions>
    <img src="../../../assets/img/close_icon.png" (click)="close()" width="20px" height="20px" id="close_icd" />
  </div>
</div>
<div mat-dialog-content class="pharcancel_content">
  <div class="container">
    <div class="row">
      <div class="col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2"></div>
      <div class="col-12 col-sm-12 col-md-4 col-lg-8 col-xl-8">
        <mat-label class="matlabel"><br>
          <textarea #matInput class="ipcss " maxlength="50" [(ngModel)]="cancel"></textarea>
        </mat-label>
      </div>
      <div class="col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2"></div>
    </div>
  </div>
</div>
<div mat-dialog-actions align="end" style="width: auto;right: 19px;">
  <button (click)="done()" id="add_btn" [mat-dialog-close]="cancel" cdkFocusInitial mat-button>
    <img src="../../../assets/ui_icons/buttons/Done_button.svg" class="addimgbtn_icd" />
  </button>
  <button (click)="close()" id="add_btn" [mat-dialog-close]="" cdkFocusInitial mat-button>
    <img src="../../../assets/ui_icons/buttons/cancel_button.svg" class="addimgbtn_icd" />
  </button>
</div>