import { Component, OnInit } from '@angular/core';
import { Master_Helper } from '../../Doctor_module/Master_Helper';
import { MasterHelperService } from '../../Doctor_module/MasterHelper.service';
import { Helper_Class } from '../../helper_class';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Http, Headers, JSONPConnection } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { ConvertTimeformat, Date_Formate, tConv24, Time_Formate } from '../../../assets/js/common';
import { MatDialog } from '@angular/material/dialog';
import { ipaddress } from '../../ipaddress' ;import { Doc_Helper } from '../../Doctor_module/Doc_Helper';
import { MenuViewService } from '../../common-module/menu-view/menu-view.service';
import { Message_data } from '../../../assets/js/Message_data';
import { Nurse_Helper } from '../../Nurse_module/Nurse_Helper';


@Component({
  selector: 'app-ward-details',
  templateUrl: './ward-details.component.html',
  styleUrls: ['./ward-details.component.scss']
})
export class WardDetailsComponent implements OnInit {
  public flagcheck:boolean=false;
  public nodata_flag: boolean = false;

  public ward_list: any = [];
  public ward_id: any = 'All';
  bedList: any[];
  hospital_id: any;
  public wardBeds: any = [];
  public ward_Name: any;
  splUrl: string;
  public userInfo: any;
  public splList = [];
  nurseWardList: any = [];
  ward_ids: any[];

  constructor(public masterData: MasterHelperService, public http: Http, public routes: ActivatedRoute, public router: Router, public toastr: ToastrService, public messageservice: MenuViewService) { }

  async ngOnInit() {
    this.wardBeds = []
    this.userInfo = Helper_Class.getInfo();
    this.nurseWardList = Helper_Class.getNurseWardList();
    console.log('this.userInfo ***********'+JSON.stringify(Helper_Class.getInfo()))

    if (this.userInfo.user_type == "nurse") {
      if (Master_Helper.getMasterWardData() != undefined) {
        this.ward_list = Master_Helper.getMasterWardData().wards.filter(item => this.nurseWardList.includes(item.ward_id));
      } else {
        await this.masterData.getWardData();
        this.ward_list = Master_Helper.getMasterWardData().wards.filter(item => this.nurseWardList.includes(item.ward_id));
      }
    } else {
      if (Master_Helper.getMasterWardData() != undefined) {
        this.ward_list = Master_Helper.getMasterWardData().wards;
      } else {
        await this.masterData.getWardData();
        this.ward_list = Master_Helper.getMasterWardData().wards;
      }
    }


    this.splUrl = ipaddress.getIp + "adm/gsplbyhptl";

    var hosp: any = Helper_Class.getHospital();
    this.hospital_id = hosp[0].hptl_clinic_id;

    this.getBedList('All')
    this.getSpecializations();
  }

  getBedList(ward_id) {
    this.bedList = [];
    this.wardBeds = [];
    this.ward_ids = [];

    if (ward_id != 'All') {
      for (var k = 0; k < this.ward_list.length; k++) {
        if (this.ward_list[k].ward_id == ward_id) {
          this.ward_Name = this.ward_list[k].ward_name
        }
      }
    }

    console.log("???????????"+JSON.stringify({
      hptl_clinic_id: this.hospital_id,
      ward_id: ward_id,
      country: ipaddress.country_code.toString(), 
      user_id: this.userInfo.user_id,
      type: this.userInfo.user_type,
    }))

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "inpat/gwbd",
      JSON.stringify({
        hptl_clinic_id: this.hospital_id,
        ward_id: ward_id,
        country: ipaddress.country_code.toString(), 
        user_id: this.userInfo.user_id,
        type: this.userInfo.user_type,
      }),
      { headers: headers }).subscribe(
        data => {
          var obj = data.json();
          if(obj != undefined){
            this.nodata_flag=false;
            this.wardBeds = obj.ward_beds;
            for(var i=0 ; i< this.wardBeds.length;i++){
              this.wardBeds[i].isselected =false;
            }
            console.log("wardBeds ==> " + JSON.stringify(this.wardBeds));
            if (obj != undefined && obj != null && obj != "") {
              console.log("BedList ==> " + JSON.stringify(obj));
            }
          }else{
            this.wardBeds=[];
            this.nodata_flag=true;
          }
        
        });
  }

  getSpecializations() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.splUrl, JSON.stringify({ hptl_clinic_id: this.userInfo.hptl_clinic_id }),
      { headers: headers }).subscribe(
        data => {
          this.splList = [];
          var obj = JSON.parse(JSON.stringify(data));
          if (obj.specializations != null)
            this.splList = obj.specializations;
        },
        error => {
          this.toastr.error(Message_data.unableToFetchData);
        });
  }
  data(){
    this.flagcheck = !this.flagcheck
  }

  viewPatient(inpatient_id, mobile, regid, array, fname, mname, lname, speciality) {
    this.flagcheck=false;
    Doc_Helper.setFirstname(encrypt_decript.Decript(fname));
    if(mname != undefined){
      Doc_Helper.setMiddlename(encrypt_decript.Decript(mname));
    }
   
    Doc_Helper.setLastname(encrypt_decript.Decript(lname));
    Doc_Helper.setClient_id(regid);
    Doc_Helper.setSearchFlag("true");
    Doc_Helper.setClient_type("Existing");
    Doc_Helper.setMobile_no(mobile);
    Doc_Helper.setInpatientId(inpatient_id);
    Doc_Helper.setInpatientFlow("list");
    Doc_Helper.setFlowName("ward");
    Nurse_Helper.setINPatient(array);
    var spa_id
    for (var i = 0; i < this.splList.length; i++) {
      if (this.splList[i].spl_name == speciality) {
        spa_id = this.splList[i].spl_id;
      }
    }
    var specializations = [{
      spl_id: spa_id,
      spl_name: speciality
    }]
    
    if (this.userInfo.user_type == "nurse"){
      Helper_Class.setInpatientFlow("nurse");
    } else if(this.userInfo.user_type == "front-desk"){
      Helper_Class.setInpatientFlow("front-desk");
    }
    Helper_Class.setSpecializations(specializations);
    this.messageservice.sendMessage("viewIpatient");

    // console.log(encrypt_decript.Decript(mobile))
  }

  // if (obj.ward_beds.length != 0) {
  //   for (var j = 0; j < obj.ward_beds.length; j++) {
  //     if (obj.ward_beds[j].bed_details != undefined){
  //       // var bedDetails = obj.ward_beds[j].bed_details;
  //       console.log("obj.ward_beds[j].bed_details---" , obj.ward_beds[j].bed_details)
  //       for (var i = 0; i < obj.ward_beds[j].bed_details.length; i++) {
  //         var pat_name, fontWeight = "", flag: boolean;
  //         pat_name = (obj.ward_beds[j].bed_details[i].name == "Vacant") ? '#000' : 'darkgreen';
  //         fontWeight = (obj.ward_beds[j].bed_details[i].name == "Vacant") ? '400' : '600';
  //         flag = (obj.ward_beds[j].bed_details[i].name == "Vacant") ? true : false;
  //         this.bedList.push({
  //           bed_no: obj.ward_beds[j].bed_details[i].bed_no != undefined ? obj.ward_beds[j].bed_details[i].bed_no : "--",
  //           pat_name: obj.ward_beds[j].bed_details[i].name,
  //           bed: obj.ward_beds[j].bed_details[i].bed_no + " - " + obj.ward_beds[j].bed_details[i].name,
  //           bed_img: "../../../assets/ui_icons/bed_icon.svg",
  //           color: pat_name,
  //           fontWeight: fontWeight,
  //           flag: flag,
  //         });
  //       }
  //     }                
  //   }              
  // }
  flagCheckMap: { [bedId: string]: boolean } = {};

  toggleFlagCheck(bedId: string) {
    var  isBedNoPresent = this.wardBeds.some(ward => {
      if (ward.bed_details && Array.isArray(ward.bed_details)) {
        return ward.bed_details.some(bed => bed.bed_no === bedId);
      }
      return false;
    });
    if (isBedNoPresent) {
      this.flagcheck=true;
      console.log(`Bed ID ${bedId} is present in the array.`);
    } else {
      console.log(`Bed ID ${bedId} is not present in the array.`);
    }

  }
}
