import { ReturnStatement } from "@angular/compiler";

export class Diagnosis_Helper {
    //diag_centre_id
    setReportType

    static clientId;
    static setClientId(value: any) {
        this.clientId = null;
        this.clientId = value;
    }
    static getClientId() {
        return this.clientId;
    }

    static reportType;
    static setReportType(value: any) {
        this.reportType = null;
        this.reportType = value;
    }
    static getReportType() {
        return this.reportType;
    }
    
    static diag_centre_id;
    static setDiag_centre_id(value: any) {
        this.diag_centre_id = null;
        this.diag_centre_id = value;
    }
    static getDiag_centre_id() {
        return this.diag_centre_id;
    }

    //nephroreadingvalues
    static nephroreadingvalues;
    static setNephroreading(value: any) {
        this.nephroreadingvalues = null;
        this.nephroreadingvalues = value;
    }
    static getNephroreading() {
        return this.nephroreadingvalues;
    }

    //setDiagnosis_id
    static diagnosis_id;
    static setDiagnosis_id(value: any) {
        this.diagnosis_id = null;
        this.diagnosis_id = value;
    }
    static getDiagnosis_id() {
        return this.diagnosis_id;
    }

    //Diag_user_reg_id
    static diag_user_reg_id;
    static setDiag_user_reg_id(value: any) {
        this.diag_user_reg_id = null;
        this.diag_user_reg_id = value;
    }
    static getDiag_user_reg_id() {
        return this.diag_user_reg_id;
    }

    //setReportApproval
    static ReportApproval;
    static setReportApproval(value: any) {
        this.ReportApproval = null;
        this.ReportApproval = value;
    }
    static getReportApproval() {
        return this.ReportApproval;
    }


    static diagtest;
    static setdiagnosistest(value: any) {
        this.diagtest = null;
        this.diagtest = value;
    }
    static getdiagnosistest() {
        return this.diagtest
    }


    static bill;
    static setbillcreate(value: any) {
        this.bill = null;
        this.bill = value;
    }
    static getbillcreate() {
        return this.bill
    }

    static billdetail;
    static setbilldetailview(value: any) {
        this.billdetail = null;
        this.billdetail = value;
    }
    static getbilldetailview() {
        return this.billdetail
    }

    //setReportUpload
    static ReportUpload;
    static setReportUpload(value: any) {
        this.ReportUpload = null;
        this.ReportUpload = value;
    }
    static getReportUpload() {
        return this.ReportUpload
    }

    //setReportView
    static ReportView;
    static setReportView(value: any) {
        this.ReportView = null;
        this.ReportView = value;
    }
    static getReportView() {
        return this.ReportView
    }

    //setUserID_Type
    static UserID_Type;
    static setUserID_Type(value: any) {
        this.UserID_Type = null;
        this.UserID_Type = value;
    }
    static getUserID_Type() {
        return this.UserID_Type;
    }

    // set module ids
    static Module_Id;
    static setmodulelistIds(value: any) {
        this.Module_Id = null;
        this.Module_Id = value;
    }
    static getmodulelistIds() {
        return this.Module_Id;
    }

    //setCreateDiagAppt
    static CreateDiagAppt;
    static setCreateDiagAppt(value: any) {
        this.CreateDiagAppt = null;
        this.CreateDiagAppt = value;
    }
    static getCreateDiagAppt() {
        return this.CreateDiagAppt;
    }

    //setFlow
    static Flow;
    static setFlow(value: any) {
        this.Flow = null;
        this.Flow = value;
    }
    static getFlow() {
        return this.Flow;
    }

    //setClientCount
    static client_count;
    static setClientCount(value: any) {
        this.client_count = null;
        this.client_count = value;
    }
    static getClientCount() {
        return this.client_count;
    }

    
    // set Patient Details For Patient History
    static patient_details;
    static setPatientDetails(value: any) {
        this.patient_details = null;
        this.patient_details = value;
    }
    static getPatientDetails() {
        return this.patient_details;
    }

    //IPAddress
    static ipaddress;
    static setIPAddress(value:any){
        this.ipaddress = null;
        this.ipaddress = value;
    }
    static getIPAddress(){
        return this.ipaddress;
    }

    static receptbilleditinfo;
    static setreceptbilledit(value: any) {
        this.receptbilleditinfo = null;
        this.receptbilleditinfo = value;
    }

    static getreceptbilledit() {
        return this.receptbilleditinfo
    }

    // Microbiology Template Save Data
    static microbiologyUploadData;
    static setMicrobiologyUploadData(value: any){
        this.microbiologyUploadData = null;
        this.microbiologyUploadData = value;
    }

    static getMicrobiologyUploadData() {
        return this.microbiologyUploadData
    }
}