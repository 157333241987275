import { Component, OnInit, ElementRef,Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { encrypt_decript } from 'src/assets/js/Encription_Decription';
import { Client_Helper } from '../Client_helper';
import { ClientVedioCallComponent } from '../client-vedio-call/client-vedio-call.component';
import { ipaddress } from '../../ipaddress';


@Component({
  selector: 'app-client-video-link',
  templateUrl: './client-video-link.component.html',
  styleUrls: ['./client-video-link.component.scss']
})
export class ClientVideoLinkComponent implements OnInit {

  public videoLink;
  public videoLinkPaste;
  public videoLink1;
  public textBox;
  loginId: string;
  calleeId: string;
  public generate:boolean = true;
  public readonly:boolean = false;
  constructor(public dialog: MatDialog,private elRef: ElementRef,@Inject(MAT_DIALOG_DATA) public data: any ,public dialogRef: MatDialogRef<ClientVideoLinkComponent>) {
    dialogRef.disableClose = true;
   }

  ngOnInit(): void {
    var userid = encrypt_decript.Encript(this.data.login_id);
    var Clientid = encrypt_decript.Encript(this.data.callee_id);
    this.videoLink = ipaddress.video_ip + "/" + userid + "/" + Clientid;  
  }

  copyToClipboard() {
    var textBox =  this.elRef.nativeElement.querySelector("#myvalue");
    textBox.select();
    this.textBox = textBox.select();
    document.execCommand("copy");
    this.videoLink1 = this.textBox;
    this.readonly = true;
  }

  videolinkpage(){
    this.videoLink1 =this.videoLink;
    if( this.videoLink1 != ' ' && this.videoLink1 != null && this.videoLink1 != undefined) {
      this.videoLinkPaste=this.videoLink;
    }

    var link:boolean;
    link = this.videoLink1 != "" ? true : false;
    Client_Helper.setVideoLink(this.videoLinkPaste);
    
    const dialogRef1 = this.dialog.open(ClientVedioCallComponent, {
      width: '100%',
      height: '100%',
      data: {
        login_id: this.data.login_id,
        callee_id: this.data.callee_id,
        link:link,
      }
    });
    dialogRef1.afterClosed().subscribe(result => {});
    this.close();
  }

  close(){
    this.dialogRef.close();
  }
}
