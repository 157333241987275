import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { Message_data } from '../../../assets/js/Message_data';
import { ipaddress } from '../../ipaddress';
import { Client_Helper } from '../Client_helper';
import { ClientViewService } from '../clientview/client-view.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Helper_Class } from 'src/app/helper_class';

@Component({
  selector: 'app-exercise',
  templateUrl: './exercise.component.html',
  styleUrls: ['./exercise.component.scss']
})
export class ExerciseComponent implements OnInit {

  public exercise;
  public physcialExercise = [];
  public yoga;
  public exerciseDuration;
  public exerciseRoutine = [];
  public lifeStyleId;
  public selectedExercise = [];
  public selectedYoga = [];
  public session = "Morning";
  public variations = [];
  public relationlistData = [];
  public relationFName;
  public relationLName;
  public relationMName;
  public userInfo;
  public relationName;
  public subRelId;
  public relationId;
  public MET;
  public weight;
  public weight_measure;
  public age;
  public gender;
  public caloriesBurned;
  public durationSec = "00";
  public saveFlag:boolean = true;

  customOptions: OwlOptions = {
    //autoplay : true,
   autoplay: false,
   autoplaySpeed: 1500,
   loop: true,
   pullDrag: false,
   dots: false,
   navSpeed: 750,
   navText: ['<i class="fa fa-chevron-circle-left left" aria-hidden="true" style="font-size: 50px;color: #bfbbbb;position: absolute;left: 16px;top: 46%;z-index: 1;"></i>', '<i class="fa fa-chevron-circle-right right" aria-hidden="true"></i>'],
   responsive: {
     0: {
       items: 4
     },
     400: {
       items: 6
     },
     740: {
       items: 8
     },
     940: {
       items: 9
     }
   },
   nav: true,
  
  }
  
  constructor(public gservice:CommonDataService,public http:HttpClient,public routes:ActivatedRoute,public router:Router,public toastr:ToastrService, public clientservice:ClientViewService) { 
    this.session = "Morning";
  }

  async ngOnInit() {
    this.saveFlag = true;
    this.userInfo = Helper_Class.getInfo();
    this.lifeStyleId = this.userInfo.life_style_id;

    if(Client_Helper.getClntHeightMsr() != undefined){
      this.weight = Client_Helper.getClntHeightMsr().weight;
      this.weight_measure = Client_Helper.getClntHeightMsr().weight_measure;   
    }else{
      this.weight_measure = this.userInfo.weight_measure;
      this.weight = this.userInfo.weight;
    }
    await this.getRelationlist();
    this.getCurrentTime();
    this.getVitals();
  }

  getVitals(){
    var send_data = {
      life_style_id: this.lifeStyleId,
    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'lifestyle/gvt', (send_data),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.weight = obj.weight;
          this.weight_measure = obj.weight_measure;
        });
  }

  getRelationlist() {
    var obj1 = Client_Helper.getrelationlistDiet();
    this.relationlistData = [];
    Client_Helper.setrelationsubrelationslide(undefined);
    var setData = {
      relation_id: this.relationId,
      sub_rel_id: this.subRelId,
      life_style_id:  this.userInfo.life_style_id,
      weight:this.userInfo.weight,
      weight_measure:this.userInfo.weight_measure,
      prof_img: this.userInfo.img,
      imgpath: this.userInfo.imgpath,
      gender:this.userInfo.gender != undefined ? encrypt_decript.Decript(this.userInfo.gender) : "",
      age:this.userInfo.age != undefined ? this.userInfo.age : "",
    }

    Client_Helper.setrelationsubrelationslide(setData);
    if (obj1.relations != null && obj1.relations.length != 0) {
      if (obj1.relations != null) {
        if(this.userInfo.middle_name != undefined && encrypt_decript.Decript(this.userInfo.middle_name)){
          this.relationName = encrypt_decript.Decript(this.userInfo.first_name) + " " + encrypt_decript.Decript(this.userInfo.middle_name) + " " + encrypt_decript.Decript(this.userInfo.last_name);
        }else{
          this.relationName = encrypt_decript.Decript(this.userInfo.first_name) + " " + encrypt_decript.Decript(this.userInfo.last_name);
        }  
        
        if(obj1.weight != undefined)
          this.weight = obj1.weight;
        else
          this.weight = "";

        if(obj1.weight_measure != undefined)
          this.weight_measure = obj1.weight_measure;
        else
          this.weight_measure =" ";

        if (this.userInfo.profile_image != null && this.userInfo.profile_image != undefined && this.userInfo.profile_image != '') {
          imgpath = this.userInfo.profile_image;
          img = ipaddress.Ip_with_img_address + this.userInfo.profile_image;
          this.relationId = "1";
          this.subRelId = "";
        }

        this.relationlistData.push({
          rel_name: this.relationName,
          life_style_id: this.userInfo.life_style_id,
          rel_id: this.relationId,
          sub_rel_id: this.subRelId,
          prof_img: img,
          imgpath: imgpath,
          client_reg_id:this.userInfo.client,
          gender_val: obj1.gender != undefined ? encrypt_decript.Decript(obj1.gender) : "",
          age:obj1.age,
          weight:this.weight,
          weight_measure:this.weight_measure,
        });
      }

      for (var f = 0; f < obj1.relations.length; f++) {
        var obj = obj1.relations[f];
        this.subRelId = null;
        if (obj.sub_rel_id != null) {
          this.subRelId = obj.sub_rel_id
        }
        this.relationFName = "";
        this.relationMName = "";
        this.relationLName = "";

        if (obj.middle_name != null) {
          this.relationFName = encrypt_decript.Decript(obj.first_name.toString());
          this.relationMName = encrypt_decript.Decript(obj.middle_name.toString());
          this.relationLName = encrypt_decript.Decript(obj.last_name.toString());
          this.relationName = encrypt_decript.Decript(obj.first_name.toString()) + " " + encrypt_decript.Decript(obj.middle_name.toString()) + " " + encrypt_decript.Decript(obj.last_name.toString());
        } else {
          this.relationFName = encrypt_decript.Decript(obj.first_name.toString());
          this.relationLName = encrypt_decript.Decript(obj.last_name.toString());
          this.relationName = encrypt_decript.Decript(obj.first_name.toString()) + " " + encrypt_decript.Decript(obj.last_name.toString());
        }

        var img = "../../../assets/imgs/default.jpg", imgpath = "../../../assets/imgs/default.jpg";
        if (obj.profile_image !== "default") {
          imgpath = obj.profile_image;
          img = ipaddress.Ip_with_img_address + obj.profile_image;
        }

        this.relationlistData.push({
          rel_name: this.relationName,
          rel_f_name: this.relationFName,
          rel_m_name: this.relationMName,
          rel_l_name: this.relationLName,
          rel_id: obj.relation_id,
          sub_rel_id: this.subRelId,
          gender_val: obj.gender != undefined ? encrypt_decript.Decript(obj.gender) : "",
          prof_img: img,
          imgpath: imgpath,
          loc_id: obj.location,
          loc_name: obj.location_desc,
          city_id: obj.city,
          city_desc: obj.city_desc,
          state_id: obj.state,
          state_desc: obj.state_desc,
          country_id: obj.country,
          country_desc: obj.country_desc,
          update_flag: true,
          life_style_id: obj.life_style_id,
        });
      }
    }
  }

  getCurrentTime() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "adm/curdate", { country: "IN" }, { headers: headers }).subscribe(
      data => {
          var obj = JSON.parse(JSON.stringify(data));
          var current_time = obj.current_time.split(":");
          if(current_time[0] >= 3 && current_time[0] < 12){
            this.session = "Morning";
          }else if(current_time[0] >= 12 && current_time[0] < 16){
            this.session = "Afternoon";
          }else if(current_time[0] >= 16 && current_time[0] < 24){
            this.session = "Evening";    
          }
          this.getActivity();
        },
      error => {});
  }

  getActivity() {
    this.physcialExercise=[];
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'lifestyle/gat',
      { headers: headers })
      .subscribe(
        response => {
          this.physcialExercise = [];
          var obj = JSON.parse(JSON.stringify(response));
          if(obj.activity_list.length != 0){
            for (var i = 0; i < obj.activity_list.length; i++) {
              this.variations = [];                
              this.variations.push({
                activity_type_id	:	obj.activity_list[i].activity_details[0].activity_type_id,
                activity_id	:	obj.activity_list[i].activity_details[0].activity_id,
                activity_name	:	obj.activity_list[i].activity_details[0].activity_name,
                met_value_permin	:	obj.activity_list[i].activity_details[0].met_value_permin,
              });

              this.physcialExercise.push({
                activity_type_id: obj.activity_list[i].activity_type_id,
                description: obj.activity_list[i].description,
                checked: false,
                act_type_desc: obj.activity_list[i].activity_details[0].activity_id,
                act_desc: obj.activity_list[i].activity_details,
                activity_dur: "",
                session: this.session,
                met_value_permin: obj.activity_list[i].activity_details[0].met_value_permin,
                exerc_image: ipaddress.Ip_with_img_address + obj.activity_list[i].image,
                variations:obj.activity_list[i].activity_details[0],
              });

              this.selectedExercise.push({
                activity_dur: "",
                session: "",
                activity_id: "",
                activity_type_id: "",
                met_value: "",
                kcal_plan: "" ,
              });
            }
          }
          this.getActivityTracker();
        },error => {});
  }

  getActivityTracker() {
    var send_data = {
      life_style_id: this.lifeStyleId,
    }
    this.selectedExercise = []; 
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'lifestyle/gac', (send_data),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));

            if (obj.activity_details != undefined) {
              this.selectedExercise = []; 
              for (var i = 0; i < obj.activity_details.length; i++) {
                if(this.physcialExercise.length != 0){
                  for (var j = 0; j < this.physcialExercise.length; j++) {
                    if (obj.activity_details[i].activity_type_id == this.physcialExercise[j].activity_type_id) {
                      this.MET = obj.activity_details[i].met_value;
                      this.physcialExercise[j].checked = true;
                      this.physcialExercise[j].variations = [];
                      var id = this.physcialExercise[j].act_desc.filter(o=> o.activity_id == obj.activity_details[i].activity_id);
                      if(id.length != 0)
                        this.physcialExercise[j].variations = {
                          activity_type_id	:	id[0].activity_type_id,
                          activity_id	:	id[0].activity_id,
                          activity_name	:	id[0].activity_name,
                          met_value_permin	:	id[0].met_value,
                        }
                      this.physcialExercise[j].act_type_desc = obj.activity_details[i].activity_id;
                      this.physcialExercise[j].session = obj.activity_details[i].session;
                      if (obj.activity_details[i].activity_dur != null) {
                        var sec_to_min = obj.activity_details[i].activity_dur.split(":");
                        var getdatavaluehr=(sec_to_min[0]);
                        var getdatavalue=(sec_to_min[1]);
                        var getdatavaluesec =(sec_to_min[2]);
                        var floatvaluefixed  ;
                        if(sec_to_min[0] != "00"  ){
                          floatvaluefixed=((parseInt(getdatavalue) +parseInt(getdatavaluehr))*60).toFixed(2);                              
                        }
                        if(getdatavaluesec != "00" )
                          floatvaluefixed=(parseInt(getdatavalue) +(parseInt(getdatavaluesec))/60).toFixed(2); 
                        else
                          floatvaluefixed=parseInt(getdatavalue);
                      }                  
                      this.physcialExercise[j].activity_dur = floatvaluefixed;
                      this.physcialExercise[j].met_value_permin = obj.activity_details[i].met_value;  
                      this.caloriesBurned = Math.round((((this.MET * 3.5) * (this.weight / 200)) * (floatvaluefixed)));
  
                      this.selectedExercise.push({
                        activity_dur: obj.activity_details[i].activity_dur,
                        session: obj.activity_details[i].session,
                        activity_id: obj.activity_details[i].activity_id,
                        activity_type_id: obj.activity_details[i].activity_type_id,
                        met_value: obj.activity_details[i].met_value,
                        kcal_plan: this.caloriesBurned ,
                        flow:"variance"
                        });
                    }
                  }
                }
              }
            }
        },error => {});
  }

  selectVariations(event,varaiationarray,exercise) {
    var res = varaiationarray.filter(o=> o.activity_id == event.activity_id);
    if(res.length !=0){
      if(this.selectedExercise.length != 0){
        var ok = this.selectedExercise.filter(o=>o.activity_type_id == res[0].activity_type_id);
        if(ok.length != 0){
          for(var i=0;i<this.selectedExercise.length;i++){
            if( this.selectedExercise[i].activity_type_id == ok[0].activity_type_id){
              if(this.selectedExercise[i].session == ok[0].session ){
                this.selectedExercise[i].activity_type_id =res[0].activity_type_id;
                this.selectedExercise[i].activity_id = res[0].activity_id;
                this.selectedExercise[i].met_value =res[0].met_value_permin;  
                this.selectedExercise[i].flow = "variance"
                break;
              }
            }
          }
        }else{
          this.selectedExercise.push({
            activity_dur:exercise.duartion,
            session: exercise.session,
            activity_id: res[0].activity_id,
            activity_type_id: res[0].activity_type_id,
            met_value: res[0].met_value_permin,
            kcal_plan: "" ,
            flow:"variance"
          });
        }
      }else{
        this.selectedExercise.push({
          activity_dur:exercise.duartion,
          session: exercise.session,
          activity_id: res[0].activity_id,
          activity_type_id: res[0].activity_type_id,
          met_value: res[0].met_value_permin,
          kcal_plan: "" ,
          flow:"variance"
        });
      }
    }
  }

  selectSession(session,exer){
    if(exer.checked == true){
      if(this.selectedExercise.length != 0){
        var ok = this.selectedExercise.filter(o=>o.activity_type_id == exer.activity_type_id);
        if(ok.length != 0){
          for(var i=0;i<this.selectedExercise.length;i++){
            if( this.selectedExercise[i].activity_type_id == exer.activity_type_id){
              if(this.selectedExercise[i].activity_id == exer.variations.activity_id ){
                if(this.selectedExercise[i].activity_dur.length != 0){
                  if (exer.activity_dur > 59) {
                    var quotient = (exer.activity_dur) / 60;
                    var duration_hrvalue = parseInt(quotient.toString());
                    var tempvalue = (quotient.toFixed(2));
                    var temp = tempvalue.toString().split(".")
                    var duration_minvalue = parseInt(temp[1]);
                    this.durationSec = "00";
                    this.exerciseDuration = duration_hrvalue + ":" + duration_minvalue + ":" + this.durationSec;
                  } else {
                    var duration_hr1value = "00";
                    this.durationSec = "00";
                    this.exerciseDuration = duration_hr1value + ":" + exer.activity_dur + ":" + this.durationSec;
                  }  
                  this.caloriesBurned = Math.round((((this.MET * 3.5) * (this.weight / 200)) * (exer.activity_dur)));                 
                }
                if(this.selectedExercise[i].activity_dur == this.exerciseDuration){
                  this.selectedExercise[i].activity_type_id = exer.activity_type_id;
                  this.selectedExercise[i].activity_id = exer.variations.activity_id;
                  this.selectedExercise[i].met_value =exer.met_value_permin;  
                  this.selectedExercise[i].activity_dur = this.exerciseDuration;
                  this.selectedExercise[i].kcal_plan = this.caloriesBurned;
                  this.selectedExercise[i].flow = "variance",
                  this.selectedExercise[i].session = session;
                  break;
                }
              }           
            }
          }
        }
      }
    }
  }

  changeDuration(duration,exer){
    if(duration != ""){
      this.MET = exer.met_value_permin;
      if (duration > 59) {
        var quotient = (duration) / 60;
        var duration_hrvalue = parseInt(quotient.toString());
        var tempvalue = (quotient.toFixed(2));
        var temp = tempvalue.toString().split(".")
        var duration_minvalue = parseInt(temp[1]);
        this.durationSec = "00";
        this.exerciseDuration = duration_hrvalue + ":" + duration_minvalue + ":" + this.durationSec;
      } else {
        var duration_hr1value = "00";
        this.durationSec = "00";
        this.exerciseDuration = duration_hr1value + ":" + duration + ":" + this.durationSec;
      }  
      this.caloriesBurned = Math.round((((this.MET * 3.5) * (this.weight / 200)) * (duration)));
    }
    if(this.selectedExercise.length != 0){
      var ok = this.selectedExercise.filter(o=>o.activity_type_id == exer.activity_type_id);
      if(ok.length != 0){
        for(var i=0;i<this.selectedExercise.length;i++){
          if( this.selectedExercise[i].activity_type_id == exer.activity_type_id){
            if(this.selectedExercise[i].session == exer.session ){
              this.selectedExercise[i].activity_type_id = exer.activity_type_id;
              this.selectedExercise[i].activity_id = exer.variations.activity_id;
              this.selectedExercise[i].met_value =exer.met_value_permin;  
              this.selectedExercise[i].activity_dur = this.exerciseDuration;
              this.selectedExercise[i].kcal_plan = this.caloriesBurned;
              this.selectedExercise[i].flow = "variance"
              break;
            }           
          }
        }
      }else{
        this.selectedExercise.push({
          activity_dur:this.exerciseDuration,
          session: exer.session,
          activity_id: exer.variations.activity_id,
          activity_type_id: exer.activity_type_id,
          met_value: exer.met_value_permin,
          kcal_plan: this.caloriesBurned ,
          flow:"variance"
        });
      }
    }else{
      this.selectedExercise.push({
        activity_dur:this.exerciseDuration,
        session: exer.session,
        activity_id: exer.variations.activity_id,
        activity_type_id: exer.activity_type_id,
        met_value: exer.met_value_permin,
        kcal_plan: this.caloriesBurned ,
        flow:"variance"
      });
    }
  }

  selectExercisePattern(event, exer) { 
    if (event == true) {     
      if(this.weight != undefined && this.weight !=""){
        if(this.selectedExercise.length != 0){
          var res = this.selectedExercise.filter(o => o.activity_type_id == exer.activity_type_id);
          if(res.length != 0){
            for(var i = 0; i < this.selectedExercise.length; i++){
              if(this.selectedExercise[i].flow == "variance"){
                if(this.selectedExercise[i].activity_type_id == exer.activity_type_id){
                  if(this.selectedExercise[i].session == exer.session){
                    if(this.selectedExercise[i].activity_dur != null && this.selectedExercise[i].activity_dur !=undefined){

                      exer.duration=res[0].duration
                      this.MET = exer.met_value_permin;
                      if (res[0].duration > 59) {
                        var sec_to_min =res[0].duration.split(":");
                       // var sec_to_min = obj.activity_details[i].activity_dur.split(":");
                        var durationvalue=parseInt(sec_to_min[0])+"."+parseInt(sec_to_min[1])+"."+parseInt(sec_to_min[2]);
                        var userinfovaluehr=(sec_to_min[0]);
                        var userinfovalue=(sec_to_min[1]);
                        var userinfovaluesec =(sec_to_min[2]);
                        var userinfo1=(sec_to_min[2]); 
                        var floatvaluefixed  ;
                        if(sec_to_min[0] != "00"  ){
                          floatvaluefixed=((parseInt(userinfovalue) +parseInt(userinfovaluehr))*60).toFixed(2);                              
                        }
                        if(userinfovaluesec != "00" ){
                        floatvaluefixed=(parseInt(userinfovalue) +(parseInt(userinfovaluesec))/60).toFixed(2); 
                        }
                        else{
                          floatvaluefixed=parseInt(userinfovalue);
                        }
                      }  
                      if (this.userInfo.weight != null) {
                        if (this.userInfo.weight_measure == "kgs") {
                          this.weight = this.userInfo.weight;
                        }
                        else if (this.userInfo.weight_measure == "lbs") {
                          var weightvalue = ((this.userInfo.weight_measure) * 1.6)
                          this.weight = weightvalue;
                        }
                      }
          
                      this.caloriesBurned = Math.round((((this.MET * 3.5) * (this.weight / 200)) * (floatvaluefixed)));
                      this.selectedExercise[i].session = res[0].session;              
                      this.selectedExercise[i].activity_dur = res[0].activity_dur;
                      this.selectedExercise[i].activity_id =   res[0].activity_id;
                      this.selectedExercise[i].activity_type_id =   res[0].activity_type_id;
                      this.selectedExercise[i].kcal_plan = res[0].kcal_plan;
                      this.selectedExercise[i].met_value = res[0].met_value;
                      break; 
                    }
                  }else{
                      this.MET = exer.met_value_permin;
                      if (exer.activity_dur > 59) {
                        var quotient = (exer.activity_dur) / 60;
                        var duration_hrvalue = parseInt(quotient.toString());
                        var tempvalue = (quotient.toFixed(2));
                        var temp = tempvalue.toString().split(".")
                        var duration_minvalue = parseInt(temp[1]);
                        this.durationSec = "00";
                        this.exerciseDuration = duration_hrvalue + ":" + duration_minvalue + ":" + this.durationSec;
                      } else {
                        var duration_hr1value = "00";
                        this.durationSec = "00";
                        this.exerciseDuration = duration_hr1value + ":" + exer.activity_dur + ":" + this.durationSec;
                      }                   
                      if (this.userInfo.weight != null) {
                        if (this.userInfo.weight_measure == "kgs") {
                          this.weight = this.userInfo.weight;
                        }
                        else if (this.userInfo.weight_measure == "lbs") {
                          var weightvalue = ((this.userInfo.weight_measure) * 1.6)
                          this.weight = weightvalue;
                        }
                      }
                      this.caloriesBurned = Math.round((((this.MET * 3.5) * (this.weight / 200)) * ( this.exerciseDuration )));
                 
                    this.selectedExercise.push({
                        activity_dur:exer.activity_dur, 
                        session:exer.session,                         
                        activity_type_id:exer.activity_type_id,
                        activity_id:exer.variations.activity_id,
                        kcal_plan:this.caloriesBurned,
                        met_value: this.MET ,
                      // checked:event
                  });
                  break;
                    }
                  }
              }           
           
              }
            }
            else{
              if(exer.act_duration !=null && exer.act_duration !=undefined){
                  this.MET = exer.met_value_permin;
                  if (exer.act_duration > 59) {
                    var quotient = (exer.act_duration) / 60;
                    var duration_hrvalue = parseInt(quotient.toString());
                    var tempvalue = (quotient.toFixed(2));
                    var temp = tempvalue.toString().split(".")
                    var duration_minvalue = parseInt(temp[1]);
                    this.durationSec = "00";
                    this.exerciseDuration = duration_hrvalue + ":" + duration_minvalue + ":" + this.durationSec;
                  } 
                  else {
                    var duration_hr1value = "00";
                    this.durationSec = "00";
                    if(exer.act_duration !=undefined){
                      exer.act_duration=exer.act_duration;
                      this.exerciseDuration = duration_hr1value + ":" + exer.act_duration + ":" + this.durationSec;
                    }
                  
                  }
                  if (this.weight != null) {
                    if (this.weight_measure == "kgs") {
                      this.weight = this.weight;
                    }
                    else if (this.weight_measure == "lbs") {
                      var weightvalue = ((this.weight_measure) * 1.6)
                      this.weight = weightvalue;
                    }
                  }
      
                  this.caloriesBurned = Math.round((((this.MET * 3.5) * (this.weight / 200)) * (exer.act_duration)));
              
              
                this.selectedExercise.push({
                    activity_dur:this.exerciseDuration, 
                    session:exer.session,                         
                    activity_type_id:exer.activity_type_id,
                    activity_id:exer.variations.activity_id,
                    kcal_plan:this.caloriesBurned,
                    met_value: this.MET ,
              });
            
            }
            }
        
        }else{          
          if(exer.act_duration !=null && exer.act_duration !=undefined){
            this.MET = exer.met_value_permin;
            if (exer.activity_dur > 59) {
              var quotient = (exer.activity_dur) / 60;
              var duration_hrvalue = parseInt(quotient.toString());
              var tempvalue = (quotient.toFixed(2));
              var temp = tempvalue.toString().split(".")
              var duration_minvalue = parseInt(temp[1]);
              this.durationSec = "00";
              this.exerciseDuration =exer.activity_dur;
            } else {
              var duration_hr1value = "00";
              this.durationSec = "00";
              this.exerciseDuration =this.exerciseDuration;
            }
            if (this.weight != null) {
              if (this.weight_measure == "kgs") {
                this.weight = this.weight;
              }
              else if (this.weight_measure == "lbs") {
                var weightvalue = ((this.weight_measure) * 1.6)
                this.weight = weightvalue;
              }
            }
            if(this.weight != undefined){
              this.caloriesBurned = Math.round((((this.MET * 3.5) * (this.weight / 200)) * (this.exerciseDuration)));
            }
        
            this.selectedExercise.push({
                activity_dur:exer.act_duration, 
                session:exer.session,                         
                activity_type_id:exer.activity_type_id,
                activity_id:exer.variations.activity_id,
                kcal_plan:this.caloriesBurned,
                met_value: this.MET ,
               // checked:true
              });
          }    
          else{
           this.toastr.error(" enter Duration");
           exer.checked=false;
           event=false;
          }
         
        }
           
      }else{
        this.toastr.error("Enter your Height and Weight");
        var flowchk={
          flow:"exerciseplan",
          relation_id: Client_Helper.getrelationsubrelationslide().relation_id,
          sub_rel_id: Client_Helper.getrelationsubrelationslide().sub_rel_id,
          life_style_id: Client_Helper.getrelationsubrelationslide().life_style_id,
          age:this.age,
          gender:this.gender
        }
       
        Client_Helper.setexerciseplanvalue(flowchk);
        this.clientservice.sendMessage("vital");
       // this.router.navigateByUrl("/vitals");
      }      
  
    } else {
      for (var j = 0; j < this.selectedExercise.length; j++) {
        if(exer.activity_type_id == this.selectedExercise[j].activity_type_id){
          this.selectedExercise.splice(j,1);
        }
      }
      for(var i = 0; j < this.physcialExercise.length; j++){
        if(exer.activity_type_id == this.physcialExercise[i].activity_type_id){
        // this.physcialExercise[i].checked = false;
        }
      }
    }
  }

  selectedRelation(name, rel_id, sub_rel_id, life_style_id,weight,weight_measure,gender,age) {
    Client_Helper.setrelationsubrelationslide(null);
    Client_Helper.setrelationsubrelationslide(undefined);
    var getdata = {
      relation_id: rel_id,
      sub_rel_id: sub_rel_id,
      life_style_id: life_style_id,
      weight:weight,
      weight_measure:weight_measure,
      gender:gender,
      age:age
    }
    this.weight = weight;
    this.weight_measure = weight_measure;
    this.relationId=rel_id;
    this.subRelId =sub_rel_id;
    this.lifeStyleId=life_style_id;
    this.age=age;
    this.gender=gender;

    Client_Helper.setrelationsubrelationslide(getdata);
    this.getCurrentTime();
  }

  save() {
    var send_data;
    var flag: boolean = true;
    send_data = {
      life_style_id: this.lifeStyleId,
      client_reg_id:this.userInfo.client,
      relation_id:Client_Helper.getrelationsubrelationslide().relation_id,
      client_activity: this.selectedExercise,
    };
    if(Client_Helper.getrelationsubrelationslide().sub_rel_id != undefined && Client_Helper.getrelationsubrelationslide().sub_rel_id != null && Client_Helper.getrelationsubrelationslide().sub_rel_id != "")
      send_data.sub_rel_id = Client_Helper.getrelationsubrelationslide().sub_rel_id;

    if (flag == true) {
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'lifestyle/sac', (send_data),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));
            if (obj.key == "1") {
              this.saveFlag = false;
              this.toastr.success(obj.result);
              this.ngOnInit();
            } else {
              this.saveFlag = true;
              this.toastr.error(obj.result);
            }
          });
    } else {
      this.saveFlag = true;
      this.toastr.error(Message_data.sltYoga);
    }

  }

  compareById(object1: any, object2: any) {
    if(object1.activity_type_id === object2.activity_type_id)
      return object1 && object2 && (parseFloat(object1.activity_id)) === parseFloat(object2.activity_id);
  }
}
