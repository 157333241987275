<div mat-dialog-title class="popup_border_resched">
    <h5 class="header_txt">Reschedule</h5>
    <div mat-dialog-actions>
        <img src="../../../assets/img/close_icon.png" (click)="close()" width="20px" height="20px"
            id="close_resched" />
    </div>
</div>
<div mat-dialog-content class="resch_content">
    <div class="row" style="margin: 5px;">
        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel">Date<br>
                <input type="date" class="ipcss_date " [(ngModel)]="appointmentDate"
                    (change)="selectApptDate(appointmentDate,'date')" [min]="currentDate" />
            </mat-label>
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel">Select Session<br>
                <mat-select required class="ipcss " [(ngModel)]="visitSession">
                    <mat-option *ngFor="let session of visitingSessionData" value="{{session.description}}">
                        {{session.description}}</mat-option>
                </mat-select>
            </mat-label>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <mat-label class="matlabel">Reason for reschedule
                <textarea class="ipcss" [(ngModel)]="rescheduleReason"></textarea>
            </mat-label>
        </div>
    </div>
</div>
<div mat-dialog-actions align="end" style="width: auto;right: 19px;">
    <button (click)="book_appointment()" id="add_btn" [mat-dialog-close]="" cdkFocusInitial mat-button>
        <img src="../../../assets/ui_icons/buttons/Book_now.svg" class="addimgbtn_icd" />
    </button>
</div>