<div class="row" style="width:100%;margin:0 auto;">
    <div class="col-12">
        <mdb-card>
            <mdb-card-body>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-2 col-xl-1 col-lg-1" style="margin-top: 5px;width: auto;">
                        <mat-label class="matlabel" style="font-size: 15px;font-weight: 600;">Appointments</mat-label>

                    </div>

                    <div class="col-12 col-sm-6 col-md-4 col-xl-2 col-lg-2">
                        <select disableOptionCentering class="ipcss widthappt" (change)="changeAppointmentType()"
                            [(ngModel)]="appointmentType" style="border: 1px solid black !important;">
                            <option value="Today">Today</option>
                            <option value="Weekly">Weekly</option>
                            <option value="Fort Night">Fort Night</option>
                            <option value="Monthly">Monthly</option>
                            <option value="3 Months">3 Months</option>
                            <option value="6 Months">6 Months</option>
                            <option value="Yearly">Yearly</option>
                        </select>
                    </div>
                    <!-- <div class="col-1" style="position: relative;top: 6px;width: 8%;" *ngIf="is_admin">
                        <mat-label class="matlabel">Nurse list:</mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3" *ngIf="is_admin">
                        <select required [(ngModel)]="doctorid" (change)="changephysio()" class="ipcss widthappt">
                            <option *ngFor="let doctors of physionameList" value={{doctors.physiodata_id}}>
                                {{doctors.PhysioName}}
                            </option>
                        </select>
                    </div> -->
                    <!-- <div class="col-12 col-sm-6 col-md-2 col-xl-1 col-lg-1" style="margin-top: 5px;width: auto;">
                        <mat-label class="matlabel" style="font-size: 15px; font-weight: 600;">Chart type</mat-label>
                    </div> -->

                    <!-- <div class="col-12 col-sm-6 col-md-4 col-xl-2 col-lg-2">
                        <select class="ipcss" [(ngModel)]="chartType" (change)="changeChart()"
                            style="border: 1px solid black !important;">
                            <option>Pie</option>
                            <option>Line</option>
                            <option>Bar</option>
                        </select>
                    </div> -->

                </div>



                <div class="row">

                    <div class="col-12 col-sm-12 col-md-6 col-lg-10 col-xl-8" style="border-radius:5px;width:66%">

                        <div class="row justify-content-center align-items-center" style="width:100%;">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style="margin: 10px 0">
                                <mat-card id="cardcontent" style="border: 1px solid red;">
                                    <mat-card-title style="font-size: 14px;">
                                        <img src='../../../assets/ui_icons/home_page_icon/location_icon.svg'
                                            width="20px" height="auto" />
                                        Location
                                    </mat-card-title>
                                    <mat-card-content style="background-color: #fff;">
                                        <canvas id="LocationCanvas" width="361" height="175"
                                            style="display: block;"></canvas>
                                    </mat-card-content>
                                </mat-card>
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style="margin: 10px 0">
                                <mat-card id="cardcontent" style="border: 1px solid rgb(127, 155, 127);">
                                    <mat-card-title style="font-size: 14px;">
                                        <img src='../../../assets/ui_icons/home_page_icon/age_icon.svg' width="25px"
                                            height="auto" />
                                        Age
                                    </mat-card-title>
                                    <mat-card-content style="background-color: #fff;">
                                        <canvas id="ageCanvas" width="361" height="175"
                                            style="display: block;"></canvas>
                                    </mat-card-content>
                                </mat-card>
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style="margin: 10px 0">
                                <mat-card id="cardcontent" style="border: 1px solid black;">
                                    <mat-card-title style="font-size: 14px;">
                                        <img src='../../../assets/ui_icons/home_page_icon/gender_icon.svg' width="25px"
                                            height="auto" />
                                        Gender
                                    </mat-card-title>
                                    <mat-card-content style="background-color: #fff;">
                                        <canvas id="piechart_gen" width="361" height="175"
                                            style="display: block;"></canvas>
                                    </mat-card-content>
                                </mat-card>
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style="margin: 10px 0">
                                <mat-card id="cardcontent" style="border: 1px solid black;">
                                    <mat-card-title style="font-size: 14px;">
                                        <img src='../../../assets/ui_icons/home_page_icon/gender_icon.svg' width="25px"
                                            height="auto" />
                                        package
                                    </mat-card-title>
                                    <mat-card-content style="background-color: #fff;">
                                        <canvas id="package_piechart" width="361" height="175"
                                            style="display: block;"></canvas>
                                    </mat-card-content>
                                </mat-card>
                            </div>









                        </div>

                    </div>




                    <!-- class="row justify-content-center align-items-center" style="width:100%" -->
                    <!-- style="display: flex;flex-direction: column-start-nowarp;justify-content: space-evenly;" -->
                    <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">

                        <div class="row justify-content-center align-items-center"
                            style="display: flex;flex-direction: column-start-nowarp;justify-content: space-evenly;width:100%">

                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style="margin: 10px 0;width:100%">
                                <mat-card id="cardcontent" style="border: 1px solid black;">
                                    <mat-card-title style="font-size: 14px;">
                                        <img src='../../../assets/ui_icons/home_page_icon/gender_icon.svg' width="25px"
                                            height="auto" />
                                        Saticification level
                                    </mat-card-title>
                                    <mat-card-content style="background-color: #fff;">
                                        <canvas id="saticification_piechart" width="361" height="175"
                                            style="display: block;"></canvas>
                                    </mat-card-content>
                                </mat-card>
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style="margin: 10px 0;width:100%">
                                <mat-card id="cardcontent" style="border: 1px solid black;">
                                    <mat-card-title style="font-size: 14px;">
                                        <img src='../../../assets/ui_icons/home_page_icon/gender_icon.svg' width="25px"
                                            height="auto" />
                                        Revenue
                                    </mat-card-title>
                                    <mat-card-content style="background-color: #fff;">
                                        <canvas id="revenie_piechart" width="361" height="175"
                                            style="display: block;"></canvas>
                                    </mat-card-content>
                                </mat-card>
                            </div>

                        </div>


                    </div>

                </div>




            </mdb-card-body>
        </mdb-card>
    </div>
</div>

<div class="row">
    <div class="Details" style="margin-top: -12px;">
    </div>
</div>