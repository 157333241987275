<div class="row">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white" style="padding:7px 20px;">
        <div class="d-flex justify-content-between align-items-center">
          <h5 class="m-0" style="font-size: 17px;">Medical Prescription</h5>
          <div>
            <img src="../../../assets/ui_icons/buttons/Back_button.svg" class="saveimgbtn_inpatinfo" (click)="back()" />
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row" style="padding-left: 10px;padding-right: 10px;">
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel" style="margin-top: 7px;">Relation
              <mat-select disableOptionCentering class="ipcss widthappt" [(ngModel)]="relationId" required>
                <mat-option *ngFor="let relation of relationList"
                  (click)="relationChange(relation.rel_id,relation.rel_desc)" value="{{relation.rel_id}}">
                  {{relation.rel_desc}}</mat-option>
              </mat-select>
            </mat-label>
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngIf="medPresList.length == 0">
            <p class="nodata">No Data Found</p>
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngIf="medPresList.length != 0">
            <div class="table-responsive dig_table_overflow">
              <table id="tbl" class="table table-hover table-dynamic ">
                <thead>
                  <tr>
                    <th>Doctor Name</th>
                    <th>Patient Name</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let prescription of medPresList" (click)="openMedpres(prescription.pres_drug_id)">
                    <td style="text-align: left;">{{"Dr."+ prescription.doctorname}}</td>
                    <td style="text-align: left;">{{prescription.clientname}}</td>
                    <td>{{prescription.date}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>