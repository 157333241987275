<div class="row">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Assessment</h5>
          </div>
          <div class="headerButtons">
            <div>
              <img src="../../../assets/ui_icons/buttons/Back_button.svg" (click)="backbutton()" width="85px"
                style="margin-right: 9px;" />
            </div>
          
            <div>
              <img src="../../../assets/ui_icons/buttons/write_RX_Button.svg" (click)="writerx()" width="85px"
                style="margin-right: 9px;" />
            </div>
            <div>
              <img src="../../../assets/ui_icons/buttons/save_button.svg" (click)="save()" width="85px"
                style="margin-right: 9px;" />
            </div>
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>

        <div class="row">
          <div class="Details" style="margin-top: -10px;">
            <div class="row">
              <div class="cover_div" style="padding-bottom: 13px;">
                <div class="header_lable">Personal Details</div>
                <div class="content_cover">

                  <div class="row">
                    <div class="col-2" style="text-align: center;">
                      <img src="{{this.profileImg}}" onerror="this.src='../../../assets/img/default.jpg';"
                        class="img-fluid z-depth-1 rounded-circle mr-1 profileimage">
                    </div>
                    <div class="col-10">
                      <div class="row">
                        <div class="col-3">
                          <p class="textformat"> <span style="font-weight: 550;">MR no:</span> {{mrnno}}</p>

                        </div>
                        <div class="col-3">
                          <p class="textformat"> <span style="font-weight: 550;">Name:</span> {{patient_name}}
                          </p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"> <span style="font-weight: 550;">Age/Gender:</span> {{patient_age}}/{{patient_gender}}</p>

                        </div>
                        
                        <div class="col-3">
                          <p class="textformat"> <span style="font-weight: 550;">Doctor:</span> {{doctor_name}}</p>

                        </div>
                        <div class="col-3">
                          <p class="textformat"> <span style="font-weight: 550;">Speciality:</span> {{speclization_arry}}</p>
                        </div>
                        <div class="col-6">
                          <p class="textformat"> <span style="font-weight: 550;">Address:</span> {{clntAddress1}}</p>
                        </div>
                        <!-- <div class="col-3">
                          <p class="textformat">    Address2: {{this.clntAddress2}}</p>
                      </div> -->
                        <!-- <div class="col-3">
                          <p class="textformat"> Zipcode: {{this.clntZipcode}} </p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"> Location: {{this.clntLocation}} </p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"> District / City: {{this.clntCity}} </p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"> State: {{this.clntState}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"> Country: {{this.clntCountry}}</p>
                        </div> -->
                        <!-- <div class="col-3">
                          <p class="textformat">Mobile: {{this.clntContNumber}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"> Emergency contact1: {{this.clntEmergNumber}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"> Emergency contact2: {{this.clntEmergNumber1}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"> Aadhar Number: {{this.aadharNumber}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"> Whatsapp Number: {{this.whatsappNumber}}</p>
                        </div> -->
                        <!-- <div class="col-3">
                                      <p class="textformat">   Symptoms: {{this.clntSymptom}}</p>
                                    </div> -->


                        <!-- <div class="col-3">
                          <p class="textformat"> Address: {{this.clntAddress1}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"> Address2: {{this.clntAddress2}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"> Zipcode: {{this.clntZipcode}} </p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"> Location: {{this.clntLocation}} </p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"> District / City: {{this.clntCity}} </p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"> State: {{this.clntState}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"> Country: {{this.clntCountry}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"> Channel: {{this.ref_source}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"> Referred by: {{this.referredDoctor}} </p>
                        </div> -->
                        <!-- <div class="col-3">
                                      <p class="textformat">   Visit purpose: {{this.visit_purpose}}</p>
                                    </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-5" style="margin: 15px 0 15px 0;">
                <div class="row">
                  <div class="col-4">
                    <mat-label class="matlabel" style="font-weight: 600;">In-patient / Out-patient : </mat-label>
                  </div>
                  <div class="col-8">
                    <div class="switch-field">
                      <input type="radio" id="radio-one" (click)="changetype('app',2,'yes')" />
                      <label for="radio-one" [ngClass]="apptTypeFlag ? 'radio_active':'radio_inactive'">Out-patient</label>
                      <input type="radio" id="radio-two" (click)="changetype('app',2,'no')" />
                      <label for="radio-two" [ngClass]="!apptTypeFlag ? 'radio_active':'radio_inactive'">In-patient</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-5" style="margin: 15px 0 15px 0;" [hidden]="inpatientflag">
                <div class="row">
                  <div class="col-4">
                    <mat-label class="matlabel" style="font-weight: 600;">Normal/ICU : </mat-label>
                  </div>
                  <div class="col-8">
                    <div class="switch-field">
                      <input type="radio" id="radio-three" (click)="changetype('admission',3,'yes')" />
                      <label for="radio-three" [ngClass]="ynoptapp1 ? 'radio_active':'radio_inactive'">Normal</label>
                      <input type="radio" id="radio-four" (click)="changetype('admission',4,'no')" />
                      <label for="radio-four" [ngClass]="!ynoptapp1 ? 'radio_active':'radio_inactive'">ICU</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6">
                <mat-label class="matlabel"> <b class="label_bold">Complaints</b>
                  <quill-editor #editor_chief id="editor_chief" [(ngModel)]="chief_comp" [style]="editorStyle"
                    [modules]="modules" placeholder="Enter Text"
                    (onContentChanged)="onContentChanged_chief_comp($event)" [styles]="{height: 'calc(35vh - 100px)'}">
                  </quill-editor>
                  <div class="auto_complete_text1" *ngIf="chiefComplaintsList.length != 0">
                    <ul *ngFor="let chiefComplaints of chiefComplaintsList">
                      <li>
                        <a (click)="select_chiefComplaints(chiefComplaints)">{{chiefComplaints}}</a>
                      </li>
                    </ul>
                  </div>
                </mat-label>
              </div>

              <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6 ">
                <div class="row">
                  <div class="col-12 margin_10">
                    <mat-label class="matlabel">
                      <b class="label_bold">Present illness</b>
                    </mat-label>
                  </div>
                  <div class="col-12">
                    <quill-editor id="editor1" [(ngModel)]="pres_ill" [styles]="{height: 'calc(35vh - 100px)'}"
                      [modules]="modules" placeholder="Enter Text" (onContentChanged)="changelinemgmt($event)">
                    </quill-editor>
                    <div>
                      <!-- Preview -->
                      <quill-view-html hidden id="linemgmt" [content]="pres_ill"></quill-view-html>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6">
                <div class="row">
                  <div class="col-12 margin_10">
                    <mat-label class="matlabel">
                      <b class="label_bold">Past illness</b>
                    </mat-label>
                  </div>
                  <div class="col-12">
                    <quill-editor id="pastill" [(ngModel)]="past_illness" [styles]="{height: 'calc(35vh - 100px)'}"
                      [modules]="modules" placeholder="Enter Text" (onContentChanged)="changelinemgmt1($event)">
                    </quill-editor>
                    <div>
                      <!-- Preview -->
                      <quill-view-html hidden id="pastill" [content]="past_illness"></quill-view-html>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6 ">
                <div class="row">
                  <div class="col-12 margin_10">
                    <mat-label class="matlabel">
                      <b class="label_bold">CKD</b>
                    </mat-label>
                  </div>
                  <div class="col-12">
                    <quill-editor id="family" [(ngModel)]="ckdvalue" [styles]="{height: 'calc(35vh - 100px)'}"
                      [modules]="modules" placeholder="Enter Text" (onContentChanged)="changeckd($event)">
                    </quill-editor>
                    <div>
                      <!-- Preview -->
                      <quill-view-html hidden id="family" [content]="family_history"></quill-view-html>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6 ">
                <div class="row">
                  <div class="col-12 margin_10">
                    <mat-label class="matlabel">
                      <b class="label_bold">Heart disease</b>
                    </mat-label>
                  </div>
                  <div class="col-12">
                    <quill-editor id="family" [(ngModel)]="heartdisease" [styles]="{height: 'calc(35vh - 100px)'}"
                      [modules]="modules" placeholder="Enter Text" (onContentChanged)="changeheartdisease($event)">
                    </quill-editor>
                    <div>
                      <!-- Preview -->
                      <quill-view-html hidden id="family" [content]="family_history"></quill-view-html>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6 ">
                <div class="row">
                  <div class="col-12 margin_10">
                    <mat-label class="matlabel">
                      <b class="label_bold">Family history</b>
                    </mat-label>
                  </div>
                  <div class="col-12">
                    <quill-editor id="family" [(ngModel)]="family_history" [styles]="{height: 'calc(35vh - 100px)'}"
                      [modules]="modules" placeholder="Enter Text" (onContentChanged)="changelinemgmt2($event)">
                    </quill-editor>
                    <div>
                      <!-- Preview -->
                      <quill-view-html hidden id="family" [content]="family_history"></quill-view-html>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12" style="margin-top: 10px;">            
                <mat-label class="matlabel ">Pain Scale</mat-label>
                <div class="wrapper" style="text-align: center;">
                  <ul class="pain-scale">
                    <li *ngFor="let level of painLevels" class="pain-scale__level" style="text-align: center;"
                      (click)="handlePainScaleClick(level.number)" >
                      <div>
                        <img class="pain-scale__emoji emojiimage"
                        [ngStyle]="{ 'background-color': level.selected ? '#d3d3d3' : '#fff', 'box-shadow': level.selected ? 'rgb(38, 57, 77) 0px 20px 30px -10px' : 'none' }"
                          [src]="level.emoji">
                      </div>                 
                    </li>
                  </ul>
                </div>
              </div>
              </div>
            <!-- diabe -->
            <div class="row">
              <div class="col-12">
                <div class="row" style="margin: 15px;">
                  <div class="col-4 paddingcheck" >
                    <div class="row">
                      <div class="col-4">
                        <mat-label class="matlabel" style="font-weight: 600;">Diabetes : </mat-label>
                      </div>
                      <div class="col-6">
                        <div class="switch-field" *ngIf="ynopt2 != undefined">
                          <input type="radio" id="radio-five" (click)="changeAllery('Diabetes',1,'yes')" />
                          <label for="radio-five" [ngClass]="ynopt2 ? 'radio_active':'radio_inactive'">Yes</label>
                          <input type="radio" id="radio-six" (click)="changeAllery('Diabetes',1,'no')" />
                          <label for="radio-six" [ngClass]="!ynopt2 ? 'radio_active':'radio_inactive'">No</label>
                        </div>
                      </div>
                      <div class="col-4"></div>
                      <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8" style="margin: 15px 0 15px 0;"
                        *ngIf="diabflag">
                        <mat-label class="matlabel">Details
                          <textarea class="ipcss inpat_width" maxlength="250" [(ngModel)]="diabdetials"
                            matInput></textarea>
                        </mat-label>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 paddingcheck" >
                    <div class="row">
                      <div class="col-4">
                        <mat-label class="matlabel" style="font-weight: 600;">Hypertension : </mat-label>
                      </div>
                      <div class="col-6">
                        <div class="switch-field" *ngIf="ynopt3 != undefined">
                          <input type="radio" id="radio-seven" (click)="changeAllery('hyper',1,'yes')" />
                          <label for="radio-seven" [ngClass]="ynopt3 ? 'radio_active':'radio_inactive'">Yes</label>
                          <input type="radio" id="radio-eight" (click)="changeAllery('hyper',1,'no')" />
                          <label for="radio-eight" [ngClass]="!ynopt3 ? 'radio_active':'radio_inactive'">No</label>
                        </div>
                      </div>
                      <div class="col-4">

                      </div>
                      <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8" style="margin: 15px 0 15px 0;"
                        *ngIf="hyperflag">
                        <mat-label class="matlabel">Details
                          <textarea class="ipcss inpat_width" maxlength="250" [(ngModel)]="hype_details"
                            matInput></textarea>
                        </mat-label>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 paddingcheck" >
                    <div class="row">
                      <div class="col-4">
                        <mat-label class="matlabel" style="font-weight: 600;">CAD : </mat-label>
                      </div>
                      <div class="col-6">
                        <div class="switch-field" *ngIf="ynopt4 != undefined">
                          <input type="radio" id="radio-nine" (click)="changeAllery('cad',1,'yes')" />
                          <label for="radio-nine" [ngClass]="ynopt4 ? 'radio_active':'radio_inactive'">Yes</label>
                          <input type="radio" id="radio-ten" (click)="changeAllery('cad',1,'no')" />
                          <label for="radio-ten" [ngClass]="!ynopt4 ? 'radio_active':'radio_inactive'">No</label>
                        </div>
                      </div>
                      <div class="col-4">

                      </div>
                      <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8" style="margin: 15px 0 15px 0;"
                        *ngIf="cadflag">
                        <mat-label class="matlabel">Details
                          <textarea class="ipcss inpat_width" maxlength="250" [(ngModel)]="caddetails"
                            matInput></textarea>
                        </mat-label>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 paddingcheck" >
                    <div class="row">
                      <div class="col-4">
                        <mat-label class="matlabel" style="font-weight: 600;">CVD : </mat-label>
                      </div>
                      <div class="col-6">
                        <div class="switch-field" *ngIf="ynopt5 != undefined">
                          <input type="radio" id="radio-eleven" (click)="changeAllery('cvd',1,'yes')" />
                          <label for="radio-eleven" [ngClass]="ynopt5 ? 'radio_active':'radio_inactive'">Yes</label>
                          <input type="radio" id="radio-twelve" (click)="changeAllery('cvd',1,'no')" />
                          <label for="radio-twelve" [ngClass]="!ynopt5 ? 'radio_active':'radio_inactive'">No</label>
                        </div>
                      </div>
                      <div class="col-4"></div>
                      <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8" style="margin: 15px 0 15px 0;"
                        *ngIf="cvdflag">
                        <mat-label class="matlabel">Details
                          <textarea class="ipcss inpat_width" maxlength="250" [(ngModel)]="cvd_details"
                            matInput></textarea>
                        </mat-label>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 paddingcheck" >
                    <div class="row">
                      <div class="col-4">
                        <mat-label class="matlabel" style="font-weight: 600;">Tuberculosis : </mat-label>
                      </div>
                      <div class="col-6">
                        <div class="switch-field" *ngIf="ynopt6 != undefined">
                          <input type="radio" id="radio-thirteen" (click)="changeAllery('tuber',1,'yes')" />
                          <label for="radio-thirteen" [ngClass]="ynopt6 ? 'radio_active':'radio_inactive'">Yes</label>
                          <input type="radio" id="radio-fouteen" (click)="changeAllery('tuber',1,'no')" />
                          <label for="radio-fouteen" [ngClass]="!ynopt6 ? 'radio_active':'radio_inactive'">No</label>
                        </div>
                      </div>
                      <div class="col-4"></div>
                      <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8" style="margin: 15px 0 15px 0;"
                        *ngIf="tuberflag">
                        <mat-label class="matlabel">Details
                          <textarea class="ipcss inpat_width" maxlength="250" [(ngModel)]="tuber_details"
                            matInput></textarea>
                        </mat-label>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 paddingcheck" >
                    <div class="row">
                      <div class="col-4">
                        <mat-label class="matlabel" style="font-weight: 600;">Others : </mat-label>
                      </div>
                      <div class="col-6">
                        <div class="switch-field" *ngIf="ynopt7 != undefined">
                          <input type="radio" id="radio-fifteen" (click)="changeAllery('Others',1,'yes')" />
                          <label for="radio-fifteen" [ngClass]="ynopt7 ? 'radio_active':'radio_inactive'">Yes</label>
                          <input type="radio" id="radio-sixteen" (click)="changeAllery('Others',1,'no')" />
                          <label for="radio-sixteen" [ngClass]="!ynopt7 ? 'radio_active':'radio_inactive'">No</label>
                        </div>
                    
                      </div>
                      <div class="col-4">
                     
                      </div>
                      <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8" style="margin: 15px 0 15px 0;"
                      *ngIf="otherflag">
                      <mat-label class="matlabel">Details
                        <textarea class="ipcss inpat_width" maxlength="250" [(ngModel)]="Others_details"
                          matInput></textarea>
                      </mat-label>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- habitual -->
            <div class="row">
              <div class="cover_div" style="margin-top: 10px;margin-left: 10px !important;margin-right: 10px !important;">
                <div class="header_lable">
                  Habitual
                </div>
                <div class="content_cover">
                  <div class="row" style="margin-top: 20px;">
                    <div class="col-3">
                      <div class="hebitual_cover">
                        <div class="header_lable">
                          Alcohol
                        </div>
                        <div class="content_cover">
                          <mat-accordion class="mataccordion">
                            <div class="vl"></div>
                            <mat-expansion-panel [expanded]="panelOpenState1" hideToggle [(ngModel)]="toggAlchol"
                              (opened)="panelOpenState1 = true" (closed)="panelOpenState1 = false"
                              class="exp_panel_cover">
                              <mat-expansion-panel-header class="exppanel">
                                <div class="hab_exp_image_cover">
                                  <img
                                    [src]="!panelOpenState1 ? '../../../assets/ui_icons/alcohol_red.svg' : '../../../assets/ui_icons/alcohol.svg'">
                                </div>
                              </mat-expansion-panel-header> <!-- heading  -->
                              <div class="hab_content_cover">
                                <div class="row">
                                  <mat-label class="col-12" class="hab_conter_text">Quantity 
                                     <select class="sdropdown" ngDefaultControl [(ngModel)]="Alc_Quant">
                                        <option disabled value="Select">Select</option>
                                        <option value={{quan.description}} *ngFor="let quan of Quantity_array">
                                           {{quan.description}}</option>
                                     </select>
                                  </mat-label>
          
                                  <mat-label class="col-12" class="hab_conter_text">Mixing Type
                                     <select class="sdropdown" [(ngModel)]="Alc_Mix">
                                        <option disabled value="Select">Select</option>
                                        <option value={{Mix.description}} *ngFor="let Mix of Mixing_array">{{Mix.description}}
                                        </option>
                                     </select>
                                  </mat-label>
          
                                  <mat-label class="col-12" class="hab_conter_text">Duration
                                     <select class="sdropdown" [(ngModel)]="alchol_duration">
                                        <option value="&lt;1 Year">&lt;1 Year</option>
                                        <option value="1 - 2 Years"> 1 - 2 Years</option>
                                        <option value="3 - 5 Years"> 3 - 5 Years</option>
                                        <!-- <option value="&lt;5 Years">&lt;5 Years</option> -->
                                        <option value=">5 Years"> >5 Years</option>
                                     </select>
                                  </mat-label>
          
                                  <mat-label class="col-12" class="hab_conter_text">Freqency/day
                                     <select class="sdropdown" [(ngModel)]="alcohol_frequency">
                                        <option value=1>1</option>
                                        <option value="2"> 2</option>
                                        <option value="3"> 3</option>
                                        <option value="4"> 4</option>
                                        <option value=">5 to 10">>5 to 10</option>
                                        <option value="10+">10+</option>
                                     </select>
                                  </mat-label>
          
          
                               </div>
                              </div>
                            </mat-expansion-panel>
                          </mat-accordion>
                        </div>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="hebitual_cover">
                        <div class="header_lable">
                          Smoke
                        </div>
                        <div class="content_cover">
                          <mat-accordion class="mataccordion">
                            <div class="vl"></div>
                            <mat-expansion-panel [expanded]="panelOpenState2" hideToggle
                              (opened)="panelOpenState2 = true" (closed)="panelOpenState2 = false"
                              class="exp_panel_cover">
                              <mat-expansion-panel-header class="exppanel">
                                <div class="hab_exp_image_cover">
                                  <img
                                    [src]="!panelOpenState2 ? '../../../assets/ui_icons/smoke_red.svg' : '../../../assets/ui_icons/smoke.svg'">
                                </div>
                              </mat-expansion-panel-header> <!-- heading  -->
                              <div class="hab_content_cover">
                                <div class="row">
                                  <mat-label class="col-12" class="hab_conter_text" >Duration
                                     <select class="sdropdown" [(ngModel)]="smoke_duration">
                                        <option value="&lt;1 Year"> &lt;1 Year</option>
                                        <option value="1 - 2 Years"> 1 - 2 Years</option>
                                        <option value="3 - 5 Years"> 3 - 5 Years</option>
                                        <!-- <option value="&lt;5 Years "> &lt;5 Years </option> -->
                                        <option value="&gt;5 Years">&gt;5 Years </option>
                                     </select>
                                  </mat-label>
                                  <mat-label class="col-12" class="hab_conter_text">Freqency/day(times)
                                     <select class="sdropdown" [(ngModel)]="smoke_frequency">
                                        <option value=1>1</option>
                                        <option value="2"> 2</option>
                                        <option value="3"> 3</option>
                                        <option value="4"> 4</option>
                                        <option value=">5 to 10">>5 to 10</option>
                                        <option value="10+">10+</option>
                                     </select>
                                  </mat-label>
                               </div>
                              </div>
                            </mat-expansion-panel>
                          </mat-accordion>
                        </div>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="hebitual_cover">
                        <div class="header_lable">
                          Gutka
                        </div>
                        <div class="content_cover">
                          <mat-accordion class="mataccordion">
                            <div class="vl"></div>
                            <mat-expansion-panel hideToggle [expanded]="panelOpenState3"
                              (opened)="panelOpenState3 = true" (closed)="panelOpenState3 = false"
                              class="exp_panel_cover">
                              <mat-expansion-panel-header class="exppanel">
                                <div class="hab_exp_image_cover">
                                  <img
                                    [src]="!panelOpenState3 ? '../../../assets/ui_icons/gutkha_red.svg' : '../../../assets/ui_icons/gutkha.svg'">
                                </div>
                              </mat-expansion-panel-header> <!-- heading  -->
                              <div class="hab_content_cover">
                                <div class="row">
                                  <mat-label class="col-12" class="hab_conter_text">Duration
                                     <select class="sdropdown" [(ngModel)]="gutka_duration">
                                        <option value="&lt;1 Year">&lt;1 Year</option>
                                        <option value="1 - 2 Years"> 1 - 2 Years</option>
                                        <option value="3 - 5 Years"> 3 - 5 Years</option>
                                        <!-- <option value="&lt;5 Years ">&lt;5 Years </option> -->
                                        <option value="&gt;5 Years">&gt;5 Years</option>
             
                                     </select>
                                  </mat-label>
                                  <mat-label class="col-12" class="hab_conter_text">Freqency/day (Times)
                                     <select class="sdropdown" [(ngModel)]="gutka_frequency">
                                        <option value=1>1</option>
                                        <option value="2"> 2</option>
                                        <option value="3"> 3</option>
                                        <option value="4"> 4</option>
                                        <option value=">5 to 10">>5 to 10</option>
                                        <option value="10+">10+</option>
                                     </select>
                                  </mat-label>
                               </div>
                              </div>
                            </mat-expansion-panel>
                          </mat-accordion>
                        </div>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="hebitual_cover">
                        <div class="header_lable">
                          Exercise
                        </div>
                        <div class="content_cover">
                          <mat-accordion class="mataccordion">
                            <mat-expansion-panel hideToggle [expanded]="panelOpenState4"
                              (opened)="panelOpenState4 = true" (closed)="panelOpenState4 = false"
                              class="exp_panel_cover">
                              <mat-expansion-panel-header class="exppanel">
                                <div class="hab_exp_image_cover">
                                  <img
                                    [src]="!panelOpenState4 ? '../../../assets/ui_icons/exercise_red.svg' : '../../../assets/ui_icons/exercise.svg'">
                                </div>
                              </mat-expansion-panel-header> <!-- heading  -->
                              <div class="hab_content_cover">
                                <div class="row">
                                  <mat-label class="col-12" class="hab_conter_text" >Exercise
                                     <select class="sdropdown" [(ngModel)]="life_exer" (ngModelChange)="Exercise_change($event)" disableOptionCentering >
                                        <option disabled value="Select">Select</option>
                                        <option *ngFor="let exercise of Physcial_exercise"
                                            value={{exercise.description}}>
                                           {{exercise.description}}</option>
                                     </select>
                                  </mat-label>
                                  <mat-label class="col-12" *ngIf="!yoga_div">
                                  <div class="scroll_checkbox test_box" id="Div8" style="margin-top: 12px;" >
                                     <table style="margin-top: -5px;">
                                         <tr *ngFor="let yoga of Get_theyoga">
                                             <td class="test">
                                                 <mat-checkbox multiple [checked]="yoga_check.indexOf(yoga.yoga_asana_id) > -1"
                                                     (change)="yoga_click(yoga.yoga_asana_id,$event.checked);" >
                                                 </mat-checkbox>
                                             </td>
                                             <td style="text-align: left !important;text-align: left !important;                                    
                                             color: black;
                                             width: -webkit-fit-content;
                                             width: -moz-fit-content;
                                             width: fit-content;
                                             font-size: 12px !important;
                                             font-family: Arial;
                                             font-weight: 400;
                                             letter-spacing: 0.5px;">
                                                 <span>{{yoga.description}}</span>
                                             </td>
                                         </tr>
                                     </table>
                                 </div>
                               </mat-label>
                                  <!-- <mat-label class="col-12" class="hab_conter_text" *ngIf="!yoga_div"><br>
                                     <select class="sdropdown" disableOptionCentering multiple >
                                        <option disabled value="Select">Select</option>
                                        <option #matoption color="primary" (click)="yoga_click(yoga.yoga_asana_id,matoption)"
                                           *ngFor="let yoga of Get_theyoga" value={{yoga.description}}>
                                           {{yoga.description}}
                                        </option>
                                     </select>
                                  </mat-label> -->
             
                                  <mat-label class="col-12" class="hab_conter_text">Duration<br>
                                     <select class="sdropdown" [(ngModel)]="life_exerdure"  
                                        disableOptionCentering >
                                        <option disabled value="Select">Select</option>
                                        <option value="10 mins">10 mins</option>
                                        <option value="15 mins">15 mins</option>
                                        <option value="30 mins">30 mins</option>
                                        <option value="45 mins">45 mins</option>
                                     </select>
                                  </mat-label>
                                  <!-- <mat-label class="col-12" class="hab_conter_text">Frequency<br>
                                     <select class="sdropdown" [(ngModel)]="life_exe_freq" 
                                        disableOptionCentering >
                                        <option disabled value="Select">Select</option>
                                        <option value="Daily">Daily</option>
                                        <option value="Thrice a week">Thrice a week</option>
                                        <option value="4 days a week">4 days a week</option>
                                        <option value="5 days a week">5 days a week</option>
                                     </select>
                                  </mat-label> -->
                                  <mat-label class="col-12" class="hab_conter_text">Exercise routine<br>
                                     <select class="sdropdown" [(ngModel)]="exer_run" disableOptionCentering >
                                        <option disabled value="Select">Select</option>
                                        <option *ngFor="let routine of exercise_routine_arry" value="{{routine.description}}">
                                           {{routine.description}}</option>
                                     </select>
                                  </mat-label>
                               </div>
                              </div>
                            </mat-expansion-panel>
                          </mat-accordion>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Family history -->
        
            <div class="row">
              <div class="cover_div" style="margin-top: 15px;">
                <div class="header_lable">
                  Genral examination & Vitals
                </div>
                <div class="content_cover">
                  <div class="row">
                    <div class="col-6 col-sm-4 col-md-3 col-lg-3 col-xl-2">
                      <div class="row">
                        <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                          <mat-label class="matlabel">Height
                            <input class="ipcss " (change)="calculateCalories()" 
                              [(ngModel)]="height" onkeypress="return event.charCode >= 48 && event.charCode <= 57" matInput  />

                          </mat-label>
                        </div>


                        <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                          <mat-label class="matlabel">Measure<br>
                            <select class="ipcss widthch1" [(ngModel)]="hmeasure" (change)="calculateCalories()">
                              <option value="cms">cms</option>
                              <option value="inch">inch</option>
                            </select>
                          </mat-label>
                        </div>
                      </div>
                    </div>
                    <div class="col-6 col-sm-4 col-md-3 col-lg-3 col-xl-2">
                      <div class="row">
                        <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                          <mat-label class="matlabel">Weight<br>
                            <input class="ipcss widthch1" (change)="calculateCalories()" 
                              onkeypress="return event.charCode >= 48 && event.charCode <= 57" [(ngModel)]="weight" matInput />
                          </mat-label>
                        </div>
                        <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                          <mat-label class="matlabel">Measure<br>
                            <select class="ipcss widthch1" [(ngModel)]="wmeasure" (change)="calculateCalories()">
                              <option value="kgs">kgs</option>
                              <option value="lbs">lbs</option>
                            </select>
                          </mat-label>
                        </div>
                      </div>
                    </div>

                    <!-- onkeypress="return event.charCode >= 48 && event.charCode <= 57" -->

                    <div class="col-6 col-sm-4 col-md-1 col-lg-1 col-xl-1">
                      <mat-label class="matlabel">BMI
                        <input class="ipcss " [(ngModel)]="bmi" disabled matInput />
                      </mat-label>
                    </div>
                    <div class="col-6 col-sm-4 col-md-1 col-lg-1 col-xl-1">
                      <mat-label class="matlabel">Temperature
                        <input type="number" matInput class="ipcss noappt_width"
                          [ngStyle]="{'color': colorstyle ? 'red' : 'black'}" [(ngModel)]="temperature"
                          (keyup)="updateUserprofile()" (input)="tempcolor(temperature)">
                      </mat-label>
                    </div>


                    <div class="col-12 col-sm-4 col-md-2 col-lg-2 col-xl-2" [hidden]="bloodPressureFlag">
                      <mat-label class="matlabel">Blood pressure</mat-label>
                      <div class="row" [hidden]="bloodPressureFlag">
                        <div class=" col-6" [hidden]="bloodPressureFlag">

                          <input type="text" [hidden]="bloodPressureFlag" class="ipcss widthch1" placeholder="mm Hg"
                            maxlength="3" [(ngModel)]="BP01" (keyup)="updateUserprofile()"
                            [ngStyle]="{'color': siscolorstyle ? 'red' : 'black'}"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57" (input)="sistol(BP01)">
                        </div>
                        <div class="col-6" [hidden]="bloodPressureFlag">
                          <input type="text" [hidden]="bloodPressureFlag" class="ipcss widthch1" placeholder="mm Hg"
                            maxlength="3" [(ngModel)]="BP02" (keyup)="updateUserprofile()"
                            [ngStyle]="{'color': diocolorstyle ? 'red' : 'black'}"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57" (input)="diostol(BP02)">
                        </div>
                      </div>
                    </div>


                    <div class="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-1">
                      <mat-label class="matlabel">Saturation<br>
                        <input matInput class="ipcss " placeholder="cvs" [(ngModel)]="spo2" [ngStyle]="{'color': spo2colorstyle ? 'red' : 'black'}"  (input)="spo2value(spo2)">
                      </mat-label>
                    </div>

                    <div class="col-12 col-sm-6 col-md-1 col-lg-1 col-xl-1">
                      <mat-label class="matlabel">Pulse<br>
                        <input matInput class="ipcss " placeholder="pulse" [(ngModel)]="pulse">
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-1 col-lg-1 col-xl-1">
                      <mat-label class="matlabel">Respiratory rate<br>
                        <input matInput class="ipcss " placeholder="rs" [(ngModel)]="rstxt">
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-1 col-lg-1 col-xl-1">
                      <mat-label class="matlabel">CBD<br>
                        <input matInput class="ipcss " placeholder="cbd" [(ngModel)]="cbd">
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-1 col-lg-1 col-xl-1">
                      <mat-label class="matlabel">CVS<br>
                        <input matInput class="ipcss " placeholder="cvs" [(ngModel)]="cvs_txt">
                      </mat-label>
                    </div>
                    <!-- newly added rs key -->

                    <div class="col-12 col-sm-6 col-md-1 col-lg-1 col-xl-1">
                      <mat-label class="matlabel">RS<br>
                        <input matInput class="ipcss " maxlength="25" placeholder="rs" [(ngModel)]="rs_text">
                      </mat-label>
                    </div>
                 
                
                    <div class="col-12 col-sm-6 col-md-1 col-lg-1 col-xl-1">
                      <mat-label class="matlabel">Abdomen<br>
                        <input matInput class="ipcss " placeholder="ABD" maxlength="25" [(ngModel)]="abd_txt">
                      </mat-label>
                    </div>
                    <!-- <div class="col-12 col-sm-6 col-md-1 col-lg-1 col-xl-1">
                      <mat-label class="matlabel">P/R<br>
                        <input matInput class="ipcss " maxlength="25" placeholder="p/r" [(ngModel)]="pr_txt">
                      </mat-label>
                    </div> -->
                    <div class="col-12 col-sm-6 col-md-1 col-lg-1 col-xl-1">
                      <mat-label class="matlabel">CNS<br>
                        <input matInput class="ipcss " maxlength="25" placeholder="cns" [(ngModel)]="cns_txt">
                      </mat-label>
                    </div>
                    <div class="col-2">
                      <mat-label class="matlabel">&nbsp;<br>
                        <img src="../../../assets/ui_icons/buttons/add_button.svg" class="add_button"
                          (click)="addVitals()" />
                      </mat-label>
                    </div>
                  </div>
                  <div class="row">
                    <!-- <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-4 mb-4"
                      *ngIf="vitals_List.length != 0">
                      <table>
                        <thead>
                          <tr>
                            <th style="text-align: left;">S.No</th>
                            <th style="text-align: left;">Height</th>
                            <th style="text-align: left;">Weight</th>
                            <th style="text-align: left;">BMI</th>
                            <th style="text-align: left;">Temp</th>
                            <th style="text-align: left;">BP</th>
                            <th style="text-align: left;">SPO2</th>
                            <th style="text-align: left;">Pulse</th>
                            <th style="text-align: left;">CVS</th>
                            <th style="text-align: left;">RS</th>
                            <th style="text-align: left;">CNS</th>
                            <th style="text-align: left;">ABD</th>
                            <th style="text-align: left;">Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let vital of vitals_List">
                            <td style="text-align: left;">{{vital.sNo}}</td>
                            <td style="text-align: left;">{{vital.height}}</td>
                            <td style="text-align: left;">{{vital.weight}}</td>
                            <td style="text-align: left;">{{vital.bmi}}</td>
                            <td style="text-align: left;">{{vital.temperature}}</td>
                            <td style="text-align: left;">{{vital.bloodPressure}}</td>
                            <td style="text-align: left;">{{vital.SPO2}}</td>
                            <td style="text-align: left;">{{vital.pulse}}</td>
                            <td style="text-align: left;">{{vital.Cvs}}</td>
                            <td style="text-align: left;">{{vital.Rs}}</td>
                            <td style="text-align: left;">{{vital.Cns}}</td>
                            <td style="text-align: left;">{{vital.ABD}}</td>
                            <td style="text-align: left;">{{vital.date}} {{vital.time}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div> -->
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-4 mb-4"
                    *ngIf="vitals_List.length != 0">
                    <table>
                      <thead>
                        <tr>
                          <th style="text-align: left;">S.No</th>
                          <th style="text-align: left;">Height</th>
                          <th style="text-align: left;">Weight</th>
                          <th style="text-align: left;">BMI</th>
                          <th style="text-align: left;">Temp</th>
                          <th style="text-align: left;">BP</th>
                          <th style="text-align: left;">SPO2</th>
                          <th style="text-align: left;">Pulse</th>
                          <th style="text-align: left;">RR</th>
                          <th style="text-align: left;">CBD</th>
                          <th style="text-align: left;">CVS</th>
                          <th style="text-align: left;">RS</th>
                          <th style="text-align: left;">CNS</th>
                          <th style="text-align: left;">ABD</th>
                          <th style="text-align: left;">Date</th>
                          <!-- <th style="text-align: left;">Time</th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let vital of vitals_List">
                          <td style="text-align: left;">{{vital.sNo}}</td>
                          <td style="text-align: left;">{{vital.height}}</td>
                          <td style="text-align: left;">{{vital.weight}}</td>
                          <td style="text-align: left;">{{vital.bmi}}</td>
                          <td style="text-align: left;">
                            <span [style.color]="vital.temperature > 100 ? 'red' : 'black'">{{vital.temperature}}</span>
                           </td>
                           <td style="text-align: left;">
                            <span [style.color]="(vital.bloodPressurekey && vital.bloodPressurekey.split('/')[0] > 160 || vital.bloodPressurekey && vital.bloodPressurekey.split('/')[0] < 100 || vital.bloodPressurekey && vital.bloodPressurekey.split('/')[1] > 100 || vital.bloodPressurekey && vital.bloodPressurekey.split('/')[1] < 60) ? 'red' : 'black'">
                              {{ vital.bloodPressurekey }}
                            </span>
                          </td>
                          <td style="text-align: left;">
                            <span [style.color]="vital.SPO2 < 75 ? 'red' : 'black'">{{vital.SPO2}}</span>
                          </td>
                          <td style="text-align: left;">
                            {{vital.pulse}}</td>
                            <td style="text-align: left;">{{vital.Rs}}</td>
                          <td style="text-align: left;">{{vital.cbd_txt}}</td>
                          <td style="text-align: left;">{{vital.Cvs}}</td>
                          <td style="text-align: left;">{{vital.rs_text}}</td>
                          <td style="text-align: left;">{{vital.Cns}}</td>
                          <td style="text-align: left;">{{vital.ABD}}</td>
                          <td style="text-align: left;">{{vital.date}} {{vital.time}}</td>
                          <!-- <td style="text-align: left;">{{vital.time}}</td> -->
                        </tr>
                      </tbody>
                    </table>
                    
                    
            
                  </div>
                  </div>
                </div>
              </div>
            </div>
       
            <div class="row">
              <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6 ">
                <div class="row">
                  <div class="col-12 margin_10">
                    <mat-label class="matlabel"><b class="label_bold">Local examination</b></mat-label>
                  </div>
                  <div class="col-12">
                    <quill-editor id="editor1" [(ngModel)]="local_examination" [maxLength]="10"
                      [styles]="{height: 'calc(35vh - 100px)'}" [modules]="modules" placeholder="Enter Local examination"
                      (onContentChanged)="changeexamination($event)">
                    </quill-editor>
                    <div>
                      <!-- Preview -->
                      <quill-view-html hidden id="local_examination" [content]="local_examination" [maxLength]="10">
                      </quill-view-html>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6 ">
                <div class="row">
                  <div class="col-12 margin_10">
                    <mat-label class="matlabel">
                      <b class="label_bold">Line of managment</b>
                    </mat-label>
                  </div>
                  <div class="col-12">
                    <quill-editor id="line_mgmt" [(ngModel)]="line_mgmt" [styles]="{height: 'calc(35vh - 100px)'}"
                      [modules]="modules" placeholder="Enter Text" (onContentChanged)="changelinemgmt3($event)">
                    </quill-editor>
                    <div>
                      <!-- Preview -->
                      <quill-view-html hidden id="line_mgmt" [content]="line_mgmt"></quill-view-html>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="row" *ngIf="opiniondoctor">
            <div  class="col-6">
              <div class="cover_div">
                 <div class="header_lable">
                    Assign
                 </div>
                 <div class="content_cover">
                  <div class="row">
                           
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                      <mat-label class="matlabel">Specialization<br>
                        <select id="mandatory_specfication" class="ipcss widthappt" [(ngModel)]="splName"
                          (change)="getDoctorList(splName)">
                          <option *ngFor="let spl of splList" value="{{spl.spl_name}}">{{spl.spl_name}}</option>
                        </select>
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                      <mat-label class="matlabel">Doctor<br>
                        <select class="ipcss widthappt" [(ngModel)]="doctorname" required
                          (change)="getDoctorDetails(doctorname)">
                          <option *ngFor="let doctor of doctorList" value="{{doctor.prov_id}}">{{doctor.docname}}
                          </option>
                        </select>
                      </mat-label>
                    </div>
                  </div>
                 </div>
              </div>
           </div>
           </div>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>
