<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white" >
        <div class="headerCover">
          <div class="headerTilte app_list_header" >
            <h5 class="m-0" class="mainHeadingStyle">Notifications</h5>
          </div>
          <div class="headerButtons">
          </div>
        </div>
        
      </mdb-card-header>
      <mdb-card-body>
        <div class="row">
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div *ngIf="notification_list.length == 0" class="nodata col-12">No notification(s) found</div>
            <div *ngIf="notification_list.length != 0">
              <mat-card  class="matcardnotification" *ngFor="let person of notification_list" (click)="expandNotification(person.notification_id)">
                <mat-card-content>
                  {{person.content}}
                </mat-card-content>
              </mat-card>
            </div>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>