import { Component, OnInit ,ElementRef,Input,Inject,ViewEncapsulation,HostListener} from '@angular/core';
import { Http, Headers } from '@angular/http';
// import { getIp, Ip_with_img_address, country_code } from '../../../assets/js/ipaddress';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Helper_nephro_casesheet } from '../../../assets/js/Helper_nephro_casesheet';

@Component({
  selector: 'app-nephro-history',
  templateUrl: './nephro-history.component.html',
  styleUrls: ['./nephro-history.component.css']
})
export class NephroHistoryComponent implements OnInit {

  public per_hist: boolean;
  public habit_hist: boolean;
  public family_hist: boolean;
  public bmi_hide: boolean;
  public sitting_hide: boolean;
  public diet_hide: boolean;
  public tracker_hist: boolean;
  public standing_hide: boolean;

  public habitual_history_data = [];
  public medical_history_data = [];
  public family_history_data = [];
  public DietHist_Table_Data = [];
  public Vital_history_data = [];
  public head_history_array = [];
  public Trackhist_Table_Data = [];
  public bmi_history_data = [];
  public vital_sitting_data = [];

  constructor(public http:Http, public el: ElementRef,public dialogRef: MatDialogRef<NephroHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {   
      dialogRef.disableClose = true; 
    this.standing_hide = true;
    this.per_hist = true;
    this.habit_hist = true;
    this.family_hist = true;
    this.bmi_hide = true;
    this.sitting_hide = true;
    this.diet_hide = true;
    this.tracker_hist = true;

    if (data.hist_type == "personal") {
      if (Helper_nephro_casesheet.personal_hist_array != undefined) {
        this.medical_history_data = Helper_nephro_casesheet.personal_hist_array;
        this.per_hist = false;
      }else{
        this.per_hist = false;
      }
    }
    else if (data.habit_type == "habits") {    
      if (Helper_nephro_casesheet.Habitual_hist_array != undefined) {
        this.habitual_history_data = Helper_nephro_casesheet.Habitual_hist_array;
        this.habit_hist = false;
      }else{
        this.habit_hist = false;
      }
    }
    else if (data.family_type == "family") {    
      if (Helper_nephro_casesheet.family_hist_array != undefined) {
        this.family_history_data = Helper_nephro_casesheet.family_hist_array;
        this.family_hist = false;
      }else{
        this.family_hist = false;
      }
    }
    else if (data.bmi_type == "bmi") {    
      if (Helper_nephro_casesheet.bmi_hist_array != undefined) {
        this.bmi_history_data = Helper_nephro_casesheet.bmi_hist_array;
        this.bmi_hide = false;
      }else{
        this.bmi_hide = false;
      }
    }
    else if (data.vitals_type == "vitals") {    
      if (Helper_nephro_casesheet.vitals_sit_array != undefined) {
        this.vital_sitting_data = Helper_nephro_casesheet.vitals_sit_array;
        this.sitting_hide = false;
        this.standing_hide = false;
      }else{
        this.sitting_hide = false;
        this.standing_hide = false;
      }
    }
    else if (data.diet_type == "diet") {
      if (Helper_nephro_casesheet.diet_hist_array != undefined) {
        this.DietHist_Table_Data = Helper_nephro_casesheet.diet_hist_array;
        this.diet_hide = false;
      }else{
        this.diet_hide = false;
      }
    }
    else if (data.tracker_type == "tracker") {
      if (Helper_nephro_casesheet.tracker_hist_array != undefined) {
        this.Trackhist_Table_Data = Helper_nephro_casesheet.tracker_hist_array;
        this.tracker_hist = false;
      }else{
        this.tracker_hist = false;
      }
    }
   }

  ngOnInit(): void {
  }

  ClosePopup(){
    this.dialogRef.close();
  }

}
