<div mat-dialog-title class="popup_border_icd">
    <h5 class="header_txt">Medicine details</h5>
    <div mat-dialog-actions>
        <img src="../../../assets/img/close_icon.png" (click)="ClosePopup()" width="20px" height="20px"
            id="close_icd" />
    </div>
</div>

<div mat-dialog-content class="Details">
    <div mat-dialog-content class="diag_content">
        <div class="row" style="max-height: 400px;">
            <p [hidden]="otc_mainlist.length==0" class="tervys_heading">OTC / Non prescribed medicine</p>
            <div class="table-responsive">
                <table id="tbl" class="table" [hidden]="otc_mainlist.length==0" id="tbl">
                    <tr>
                        <th>Supplement</th>
                        <th>Dosage</th>
                        <th>Session</th>
                        <th>Intake</th>
                        <th>Side effect</th>
                    </tr>
                    <tr *ngFor="let medicineotc of otc_mainlist">
                        <td>{{medicineotc.medicine_name}}</td>
                        <td>{{medicineotc.dosage}}</td>
                        <td>{{medicineotc.intake_desc}}</td>
                        <td>{{medicineotc.medicine_total}}</td>
                        <td>{{medicineotc.side_effects}}</td>
                    </tr>
                </table>
            </div>
    
    
            <p [hidden]="vitamin_list.length==0" class="tervys_heading">Vitamins or herbal supplements</p>
            <div class="table-responsive">
                <table id="tbl" class="table" [hidden]="vitamin_list.length==0" id="tbl">
                    <tr>
                        <th>Supplement</th>
                        <th>Dosage</th>
                        <th>Session</th>
                        <th>Intake</th>
                        <th>Side effect</th>
                    </tr>
                    <tr *ngFor="let vitaminmed of vitamin_list">
                        <td>{{vitaminmed.supplement_name}}</td>
                        <td>{{vitaminmed.dosage}}</td>
                        <td>{{vitaminmed.intake_desc}}</td>
                        <td>{{vitaminmed.medicine_total}}</td>
                        <td>{{vitaminmed.side_effects}}</td>
                    </tr>
                </table>
            </div>
    
    
            <!-- Reaction table -->
            <p [hidden]="reaction_list.length==0" class="tervys_heading">Reaction to medication or supplements</p>
            <div class="table-responsive">
                <table id="tbl" class="table" [hidden]="reaction_list.length==0" id="tbl">
                    <tr>
                        <th>Supplement</th>
                        <th>Reaction</th>
                    </tr>
                    <tr *ngFor="let reactmed of reaction_list">
                        <td>{{reactmed.supplement_name}}</td>
                        <td>{{reactmed.reaction}}</td>
                    </tr>
                </table>
            </div>
    
    
            <p [hidden]="allergy_list.length==0" class="tervys_heading">Allergic to any substance</p>
            <div class="table-responsive">
                <table id="tbl" class="table" [hidden]="allergy_list.length==0" id="tbl">
                    <tr>
                        <th>Supplement</th>
                        <th>Allergy</th>
                    </tr>
                    <tr *ngFor="let allmed of allergy_list">
                        <td>{{allmed.medicine_name}}</td>
                        <td>{{allmed.allergies}}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>
<!-- <hr class="hr"> -->
<div mat-dialog-actions align="end" style="width: auto;right: 19px;">

</div>