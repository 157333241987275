<div class="row" style="margin:0 auto; width:100%">
   <div class="col-12">
      <mdb-card>
         <mdb-card-header class="bg-white " style="padding:7px 20px;">
            <div class="headerCover">
               <div class="headerTilte app_list_header">
                  <h5 class="m-0" class="mainHeadingStyle">Profiles</h5>
               </div>
               <div class="headerButtons">
                  <img src="../../../assets/ui_icons/buttons/Back_button.svg" (click)="backClicked()"
                     class="saveimgbtn_inpatinfo " />
                  <img src="../../../assets/ui_icons/buttons/save_button.svg" [hidden]="save_btn"
                     (click)="savePackage('Save')" class="saveimgbtn_inpatinfo" />
                  <img src="../../../assets/ui_icons/buttons/update_button.svg" [hidden]="update_btn"
                     (click)="savePackage('update')" class="saveimgbtn_inpatinfo" />
               </div>
            </div>
         </mdb-card-header>
         <mdb-card-body>
            <div>
               <div class="row">
                  <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2">
                     <mat-label class="matlabel">Department<br>
                        <select disableOptionCentering class="ipcss " (change)="getTestCategory(diag_dept)"
                           [(ngModel)]="diag_dept">
                           <option *ngFor="let dept of department_data" required value={{dept.diag_department_id}}>
                              {{dept.diag_dept_desc}}</option>
                        </select>
                     </mat-label>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2">
                     <mat-label class="matlabel">Sub department<br>
                        <select disableOptionCentering class="ipcss " [(ngModel)]="diag_test_categ"
                           (change)="getTestByName('', diag_test_categ)">
                           <option *ngFor="let testcat of test_category_data" required
                              value={{testcat.diag_test_cat_id}}>
                              {{testcat.description}}</option>
                        </select>
                     </mat-label>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2">
                     <mat-label class="matlabel">Profile name</mat-label>
                     <input type="text" class="ipcss" [(ngModel)]="pkg_name" aria-label="Number" matInput />
                  </div>
                  <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                     <mat-label class="matlabel">Profile description</mat-label>
                     <input type="text" class="ipcss" [(ngModel)]="pkg_desc" aria-label="Number" matInput />
                  </div> -->
                  <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2">
                     <mat-label class="matlabel">Price</mat-label>
                     <input type="text" class="ipcss" [(ngModel)]="pkg_price" aria-label="Number" matInput />
                  </div>
               </div>
               <br />
               <div class="row">
                  <div class="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12">
                     <div class="row">
                        <div class="col-12 col-sm-12 col-xl-5 col-lg-5 col-md-5">
                           <div class="row">
                              <div class="col-12 col-sm-6 col-xl-12 col-lg-12 col-md-12">
                                 <div class="diagnosis_testgrid testtype">
                                    <p class="titletest"><strong>{{diag_test_categ_desc}}</strong></p>
                                    <div class="diag_test_content_cover p-3">
                                       <div class="col-8">
                                          <input type="text" class="ipcss" aria-label="Number" [(ngModel)]="testName"
                                             matInput (keyup)="getTestByName(testName, diag_test_categ)" />
                                       </div>
                                       <div *ngFor="let subtest of fullTest_list" id="testlbel">
                                          <span *ngIf="subtest.checked == true" class="check_img"><img
                                                (click)="ChangeTestType(false,subtest.test_id,subtest.test_name,subtest.test_type,diag_test_categ_desc, subtest.totalTAT)"
                                                src="../../../assets/ui_icons/popup_tick_mark.svg"
                                                style="cursor: pointer;" width="12px" /></span>

                                          <span *ngIf="subtest.checked == false" class="check_img"><img
                                                (click)="ChangeTestType(true,subtest.test_id,subtest.test_name,subtest.test_type,diag_test_categ_desc, subtest.totalTAT)"
                                                src="../../../assets/ui_icons/yellow_box.svg" style="cursor: pointer;"
                                                width="12px" /></span>
                                          {{subtest.test_name}}
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-12 col-sm-12 col-xl-4 col-lg-4 col-md-6">
                           <div class="container-fluid my-3" *ngIf="blood_testObj.length != 0 ">
                              <h2 *ngIf="blood_testObj.length != 0" class=" tervys_heading1">Selected Test</h2>
                              <div class="row">
                                 <div *ngFor="let test of blood_testObj"
                                    class="col-12 card-container-2 d-flex flex-row align-items-center justify-content-between">
                                    <p style="margin: 0px !important;">{{test.test_name}} &nbsp;&nbsp;&nbsp; TAT - {{test.TAT}}</p>
                                    <!-- <img src="../../../assets/ui_icons/buttons/trash_icon.svg" width="15px"
                                       height="15px" /> -->
                                 </div>
                              </div>
                           </div>
                           
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </mdb-card-body>
      </mdb-card>
   </div>
</div>