<div mat-dialog-title class="popup_border_medtrack">
    <h5 class="header_txt">Medicine Tracker</h5>
    <div mat-dialog-actions>
        <img src="../../../assets/img/close_icon.png" (click)="close()" width="20px" height="20px"
            id="close_medtrack" />
    </div>
</div>
<div mat-dialog-content class="med_content">
  <div class="container">
        <div class="row" style="margin-top: 10px;margin-bottom: 10px;">
            <div class="col-12">
                <div class="nodata"  *ngIf="medicineHistoryList.length ==0">
                    No Data Found
                </div>
            </div>
            <div class="col-12">
                <div class="table-responsive" *ngIf="medicineHistoryList.length !=0">
                    <table id="tbl" class="table table-hover table-dynamic" cellpadding="5" cellspacing="0" border="1">
                      <thead>
                        <tr>
                          <th>Medicine Name</th>
                          <th>Quantity</th>
                          <th>Intake</th>
                          <th>Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let med of medicineHistoryList">
                          <td style="text-align: left;">{{med.drug_name}}</td>
                          <td>{{med.quantity}}</td>
                          <td>{{med.intake}}</td>
                          <td>{{med.created_date}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions align="end" style="width: auto;right: 19px;">
</div>