
function session_based_time_fun(t1, t2, ses) {

    //alert("da" + t1 + "asda" + t2 + "asd" + ses);

    var date1 = new Date("03/13/2014" + ' ,' + t1 + ":00");
    var date2 = new Date("03/13/2014" + ' ,' + t2 + ":00");

    var options = {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    };
    var timeString1 = date1.toLocaleString('en-US', options);
    var timeString2 = date2.toLocaleString('en-US', options);

    var ll1 = timeString1.split(' ');
    var ll2 = timeString2.split(' ');

    var t1_spt = timeString1.split(':');
    var t2_spt = timeString2.split(':');


    var fromvalue = t1_spt[0] + "00";
    var tovalue = t2_spt[0] + "00";

    //var f_time = t1.split(':');
    var chk_flag = false;
    if (ses == "Morning") {

        //alert("chk :" + ll1[1] + " :: " + ll2[1]);
        if (ll1[1] == "PM" && ll2[1] == "PM") {
            chk_flag = false;
        }
        else if (ll1[1] == "PM") {
            chk_flag = false;
        }
        else if (ll2[1] == "PM" && t2_spt[0] != "12") {
            chk_flag = false;
        }
        else {


            var morningfrom = 1200;
            var morningTo = 1200;
            var result = compareRangeMorning(fromvalue, morningfrom, morningTo);
            if (result == true) {
                var result1 = compareRangeMorning(tovalue, morningfrom, morningTo);
                if (result1 == true) {
                    var res = compareValues(fromvalue, tovalue);
                    if (res == true) {
                        chk_flag = true;
                    }
                    else {
                        chk_flag = false;
                    }
                }
                else {
                    chk_flag = false;
                }

                if (t1_spt[0] < 12 && t2_spt[0] <= 12) {
                    chk_flag = true;
                }
            }
        }
    }
    else if (ses == "Afternoon") {

        if (ll1[1] == "AM" || ll2[1] == "AM") {
            chk_flag = false;
        }
        else {
            var afternoonfrom = 1200;
            var afternoonTo = 400;
            var result = compareRangeNoon(fromvalue, afternoonfrom, afternoonTo);
            //alert("compareRangeNoon :" + result);

            if (result == true) {

                var res = compareValuesNoon(fromvalue, tovalue);
                //alert("compareValuesNoon :" + res);
                if (res == true) {
                    var toresult = compareRangeNoon(tovalue, afternoonfrom, afternoonTo);
                    if (toresult == true) {
                        chk_flag = true;
                    }
                    else {
                        chk_flag = false;
                    }
                    //	System.out.println("compareRangeNoon");

                }
                else {
                    //	System.out.println("compareRangeNoon 123");
                    chk_flag = false;
                }

            }
            else {
                chk_flag = false;
            }
        }

        //if (t1_spt[0] >= 12 && t2_spt[0] <= 16) {
        //    chk_flag = true;
        //}

    }
    else {
        if (ll1[1] == "AM" || ll2[1] == "AM") {
            chk_flag = false;
        }
        else {
            //alert("eve :" + t1_spt[0] + " :: " + t2_spt[0]);

            //if (t1_spt[0] > 16 && t2_spt[0] <= 24) {
            //    chk_flag = true;
            //}

            var eveningfrom = 400;
            var eveningTo = 1100;

            var result = compareRangeEvening(fromvalue, eveningfrom, eveningTo);
            if (result == true) {
                var result1 = compareRangeEvening(tovalue, eveningfrom, eveningTo);
                if (result1 == true) {
                    var res = compareValues(fromvalue, tovalue);
                    if (res == true) {
                        chk_flag = true;
                    }
                    else {
                        chk_flag = false;
                    }
                }
                else {
                    chk_flag = false;
                }
            }
            else {
                chk_flag = false;
            }
        }
    }

    return chk_flag;

}

exports.session_based_time_fun=session_based_time_fun;

function compareRangeMorning(value, from, to) {
    var result;
    if (parseInt(value) <= parseInt(from) && parseInt(value) <= parseInt(to)) {
        result = true;
    }
    else {
        result = false;
    }
    return result;
}

function compareRangeEvening(value, from, to) {
    var result;
    if (parseInt(value) >= parseInt(from) && parseInt(value) <= parseInt(to)) {
        result = true;
    }
    else {
        result = false;
    }
    return result;
}

function compareRangeNoon(value, from, to) {

    var result = false;
    if (parseInt(value) >= 100 && parseInt(value) <= 400) {
        //MessageBox.Show("inside " + value + "   " + from + "  " + to);
        if (parseInt(value) <= parseInt(from) && parseInt(value) <= parseInt(to)) {
            ///	System.out.println("inside fdfa");
            result = true;
        }
    }
    else if (parseInt(value) >= parseInt(from) && parseInt(value) >= parseInt(to)) {
        // MessageBox.Show("inside123 " + value + "   " + from + "  " + to);
        result = true;
    }
    else {
        result = false;
    }
    //System.out.println("inside " + result);
    return result;
}


function compareValuesNoon(from, to) {
    var result = false;

    if (parseInt(from) >= 100 && parseInt(from) <= 400) {

        if (parseInt(from) <= parseInt(to)) {
            result = true;
        }
    }
    else if (parseInt(from) >= parseInt(to)) {
        result = true;
    }
    else {

        result = false;
    }
    return result;
}

function compareValues(from, to) {
    var result;
    if (parseInt(from) <= parseInt(to)) {
        result = true;
    }
    else {
        result = false;
    }
    return result;
}
