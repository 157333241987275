import { Component, OnInit, Input,HostListener } from '@angular/core';
import {Helper_Class} from '../../helper_class';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { Observable, observable, Subscription } from 'rxjs';
import { CasesheetService } from '../casesheet/casesheet.service';
import { Doc_Helper } from '../Doc_Helper';
import { Master_Helper } from '../Master_Helper';

@Component({
  selector: 'app-gyn-surgical-history',
  templateUrl: './gyn-surgical-history.component.html',
  styleUrls: ['./gyn-surgical-history.component.css'],
})
export class GynSurgicalHistoryComponent implements OnInit {
  public surgHistoryFlag ="min";

  tomorrow = new Date();
  public minflag:boolean=true
  subscription:Subscription;

  public d_c;
  public dcgyna;
  public ovarian_surgery;
  public over_data;
  public hysteroscopy;
  public hytero_data;
  public infertility_surgery;
  public infety_data;
  public left_cyst_removed;
  public leftcry_data;
  public right_cyst_removed;
  public rightcry_data;
  public tuboplasty;
  public tobo_data;
  public left_ovary_removed;
  public lefover_data;
  public tubal_ligation;
  public tubellig_data;
  public right_ovary_removed;
  public rt_removedata;
  public laparoscopy;
  public lap_datatxt;
  public hysterectomy_vaginal;
  public hyte_vagdata;
  public hysterectomy_abdominal;
  public hy_abdomdata;
  public myomectomy;
  public memeo_data;
  public bladder_repair;
  public blader_data;
  public others;
  public surge_hist_id;
  public surge_flag;
  public dc_hidden: boolean;
  public ovarian_hidden: boolean;
  public hysteroscopy_hidden: boolean;
  public infertility_hidden: boolean;
  public lcyst_removed: boolean;
  public rcyst_removed: boolean;
  public tuboplasty_hidden: boolean;
  public tubal_hidden: boolean;
  public lovary_hidden: boolean;
  public rovary_hidden: boolean;
  public laparoscopy_hidden: boolean;
  public hysterec_hidden: boolean;
  public hysterecabdominal_hidden: boolean;
  public myomectomy_hidden: boolean;
  public vaginal_hidden: boolean;
  public other_hidden: boolean;
  public header_footer_flag: boolean;

  public create_dc_flag:boolean;
  public create_ovarian_surgery_flag:boolean;
  public create_hysteroscopy_flag:boolean;
  public create_infertility_surgery_flag:boolean;
  public create_left_cyst_removed_flag:boolean;
  public create_right_cyst_removed_flag:boolean;
  public create_tuboplasty_flag:boolean;
  public create_left_ovary_removed_flag:boolean;
  public create_tubal_ligation_flag:boolean;
  public create_right_ovary_removed_flag:boolean;
  public create_laparoscopy_flag:boolean;
  public create_hysterectomy_vaginal_flag:boolean;
  public create_hysterectomy_abdominal_flag:boolean;
  public create_myomectomy_flag:boolean;
  public create_bladder_repair_flag:boolean;

  public currentDate;

  public client:boolean = false;
  constructor(public messageservice:CasesheetService,private http:Http,private routes:ActivatedRoute,private router:Router,public toastr:ToastrService) {
    this.dc_hidden = true;
    this.ovarian_hidden = true;
    this.hysteroscopy_hidden = true;
    this.infertility_hidden = true;
    this.lcyst_removed = true;
    this.rcyst_removed = true;
    this.tuboplasty_hidden = true;
    this.tubal_hidden = true;
    this.lovary_hidden = true;
    this.rovary_hidden = true;
    this.laparoscopy_hidden = true;
    this.hysterec_hidden = true;
    this.hysterecabdominal_hidden = true;
    this.myomectomy_hidden = true;
    this.vaginal_hidden = true;
    this.other_hidden = true;
  }

  ngOnInit(): void {
    this.getCurrentDate();
    this.subscription = this.messageservice.getCasesheetMessage().subscribe(message => {
      if (message) {
          this.surgHistoryFlag = message;
      }
    });

    if(Doc_Helper.getAppFlow() == "client" ){
      this.client = true;
    }else{
      this.client = false;
    }

    var getHopitalfieldsRet=Helper_Class.getHopitalfieldsRet();
    if (getHopitalfieldsRet != undefined) {
      for (var i = 0; i < getHopitalfieldsRet.length; i++) {
        if (getHopitalfieldsRet[i].page_name == "Surgical history") {
          if (getHopitalfieldsRet[i].fields != undefined) {

            for (var j = 0; j < getHopitalfieldsRet[i].fields.length; j++) {
              if (getHopitalfieldsRet[i].fields[j].field_name == "D&C") {
                this.dc_hidden = false;
              }
              if (getHopitalfieldsRet[i].fields[j].field_name == "Ovarian surgery") {
                this.ovarian_hidden = false;
              }
              if (getHopitalfieldsRet[i].fields[j].field_name == "Hysteroscopy") {
                this.hysteroscopy_hidden = false;
              }
              if (getHopitalfieldsRet[i].fields[j].field_name == "Infertility surgery") {
                this.infertility_hidden = false;
              }
              if (getHopitalfieldsRet[i].fields[j].field_name == "L cyst removed") {
                this.lcyst_removed = false;
              }
              if (getHopitalfieldsRet[i].fields[j].field_name == "R cyst removed") {
                this.rcyst_removed = false;
              }
              if (getHopitalfieldsRet[i].fields[j].field_name == "Tuboplasty") {
                this.tuboplasty_hidden = false;
              }
              if (getHopitalfieldsRet[i].fields[j].field_name == "Tubal ligation") {
                this.tubal_hidden = false;
              }
              if (getHopitalfieldsRet[i].fields[j].field_name == "L ovary removed") {
                this.lovary_hidden = false;
              }
              if (getHopitalfieldsRet[i].fields[j].field_name == "R ovary removed") {
                this.rovary_hidden = false;
              }
              if (getHopitalfieldsRet[i].fields[j].field_name == "Laparoscopy") {
                this.laparoscopy_hidden = false;
              }

              if (getHopitalfieldsRet[i].fields[j].field_name == "Hysterectomy vaginal") {
                this.hysterec_hidden = false;
              }
              if (getHopitalfieldsRet[i].fields[j].field_name == "Hysterectomy abdominal") {
                this.hysterecabdominal_hidden = false;
              }
              if (getHopitalfieldsRet[i].fields[j].field_name == "Myomectomy") {
                this.myomectomy_hidden = false;
              }
              if (getHopitalfieldsRet[i].fields[j].field_name == "Vaginal or bladder repair") {
                this.vaginal_hidden = false;
              }
              if (getHopitalfieldsRet[i].fields[j].field_name == "Surgical others") {
                this.other_hidden = false;
              }
            }
          }
        }
      }
    }
    this.surgical_retrivel();
  }

  surgical_retrivel() {
    var getgynaRet=Helper_Class.getgynaRet();
    if (getgynaRet != undefined) {
      if (getgynaRet.d_c != null && getgynaRet.d_c != undefined) {
        this.d_c = (Helper_Class.getgynaRet().d_c);
      }

      if (getgynaRet.ovarian_surgery != null && getgynaRet.ovarian_surgery != undefined) {
        this.ovarian_surgery = (Helper_Class.getgynaRet().ovarian_surgery);
      }

      if (getgynaRet.hysteroscopy != null && getgynaRet.hysteroscopy != undefined) {
        this.hysteroscopy = (Helper_Class.getgynaRet().hysteroscopy);
      }

      if (getgynaRet.infertility_surgery != null && getgynaRet.infertility_surgery != undefined) {
        this.infertility_surgery = (Helper_Class.getgynaRet().infertility_surgery);
      }

      if (getgynaRet.left_cyst_removed != null && getgynaRet.left_cyst_removed != undefined) {
        this.left_cyst_removed = (Helper_Class.getgynaRet().left_cyst_removed);
      }

      if (getgynaRet.right_cyst_removed != null && getgynaRet.right_cyst_removed != undefined) {
        this.right_cyst_removed = (Helper_Class.getgynaRet().right_cyst_removed);
      }

      if (getgynaRet.tuboplasty != null && getgynaRet.tuboplasty != undefined) {
        this.tuboplasty = (Helper_Class.getgynaRet().tuboplasty);
      }

      if (getgynaRet.tubal_ligation != null && getgynaRet.tubal_ligation != undefined) {
        this.tubal_ligation = (Helper_Class.getgynaRet().tubal_ligation);
      }

      if (getgynaRet.left_ovary_removed != null && getgynaRet.left_ovary_removed != undefined) {
        this.left_ovary_removed = (Helper_Class.getgynaRet().left_ovary_removed);
      }

      if (getgynaRet.right_ovary_removed != null && getgynaRet.right_ovary_removed != undefined) {
        this.right_ovary_removed = (Helper_Class.getgynaRet().right_ovary_removed);
      }

      if (getgynaRet.laparoscopy != null && getgynaRet.laparoscopy != undefined) {
        this.laparoscopy = (Helper_Class.getgynaRet().laparoscopy);
      }

      if (getgynaRet.hysterectomy_vaginal != null && getgynaRet.hysterectomy_vaginal != undefined) {
        this.hysterectomy_vaginal = (Helper_Class.getgynaRet().hysterectomy_vaginal);
      }

      if (getgynaRet.hysterectomy_abdominal != null && getgynaRet.hysterectomy_abdominal != undefined) {
        this.hysterectomy_abdominal = (Helper_Class.getgynaRet().hysterectomy_abdominal);
      }

      if (getgynaRet.myomectomy != null && getgynaRet.myomectomy != undefined) {
        this.myomectomy = (Helper_Class.getgynaRet().myomectomy);
      }

      if (getgynaRet.bladder_repair != null && getgynaRet.bladder_repair != undefined) {
        this.bladder_repair = (Helper_Class.getgynaRet().bladder_repair);
      }

      if (getgynaRet.surgery_others != null && getgynaRet.surgery_others != undefined) {
        this.others = getgynaRet.surgery_others;
      }

      if (getgynaRet.surgical_hist_id != null && getgynaRet.surgical_hist_id != undefined) {
        this.surge_hist_id = getgynaRet.surgical_hist_id;
      }
    }
  }

  send_data() {
    var Pedia_surgical = null;
    var sur_flag = false;
    var Pedia_surgical = null;
    var sur_flag = false;
    if (this.d_c != undefined) {
      this.dcgyna = (this.d_c);
      sur_flag = true;
    }

    if (this.ovarian_surgery != undefined) {
      this.over_data = (this.ovarian_surgery);
      sur_flag = true;
    }

    if (this.hysteroscopy != undefined) {
      this.hytero_data = (this.hysteroscopy);
      sur_flag = true;
    }

    if (this.infertility_surgery != undefined) {
      this.infety_data = (this.infertility_surgery);
      sur_flag = true;
    }

    if (this.left_cyst_removed != undefined) {
      this.leftcry_data = (this.left_cyst_removed);
      sur_flag = true;
    }

    if (this.right_cyst_removed != undefined) {
      this.rightcry_data = (this.right_cyst_removed);
      sur_flag = true;
    }

    if (this.tuboplasty != undefined) {
      this.tobo_data = (this.tuboplasty);
      sur_flag = true;
    }

    if (this.left_ovary_removed != undefined) {
      this.lefover_data = (this.left_ovary_removed);
      sur_flag = true;
    }

    if (this.tubal_ligation != undefined) {
      this.tubellig_data = (this.tubal_ligation);
      sur_flag = true;
    }

    if (this.right_ovary_removed != undefined) {
      this.rt_removedata = (this.right_ovary_removed);
      sur_flag = true;
    }

    if (this.laparoscopy != undefined) {
      this.lap_datatxt = (this.laparoscopy);
      sur_flag = true;
    }

    if (this.hysterectomy_vaginal != undefined) {
      sur_flag = true;
      this.hyte_vagdata = (this.hysterectomy_vaginal);
    }

    if (this.hysterectomy_abdominal != undefined) {
      sur_flag = true;
      this.hy_abdomdata = (this.hysterectomy_abdominal);
    }

    if (this.myomectomy != undefined) {
      sur_flag = true;
      this.memeo_data = (this.myomectomy);
    }

    if (this.bladder_repair != undefined) {
      sur_flag = true;
      this.blader_data = (this.bladder_repair);
    }

    if (this.surge_hist_id != null) {
      this.surge_flag = "yes"

    } else {
      if (sur_flag == true) {
        this.surge_flag = "yes"

      } else {
        this.surge_flag = "no"
      }
    }

    Pedia_surgical = {
      d_c: this.dcgyna,
      ovarian_surgery: this.over_data,
      hysteroscopy: this.hytero_data,
      infertility_surgery: this.infety_data,
      left_cyst_removed: this.leftcry_data,
      right_cyst_removed: this.rightcry_data,
      tuboplasty: this.tobo_data,
      left_ovary_removed: this.lefover_data,
      tubal_ligation: this.tubellig_data,
      right_ovary_removed: this.rt_removedata,
      laparoscopy: this.lap_datatxt,
      hysterectomy_vaginal: this.hyte_vagdata,
      hysterectomy_abdominal: this.hy_abdomdata,
      myomectomy: this.memeo_data,
      bladder_repair: this.blader_data,
      surgery_others: this.others,
      surgery_flag: this.surge_flag
    }

    Helper_Class.setgynasurgical(Pedia_surgical);
    
  }

  OnDateChangeos(e){
    this.ovarian_surgery = e;
  }

  OnDateChanged_c(e){
    this.d_c=e;
  }

  OnDateChangehyst(e){
    this.hysteroscopy=e;
  }

  OnDateChangeis(e){
    this.infertility_surgery=e;
  }

  OnDateChangelc(e){
    this.left_cyst_removed=e;
  }

  OnDateChangerc(e){
    this.right_cyst_removed=e;
  }

  OnDateChangetubo(e){
    this.tuboplasty=e;
  }

  OnDateChangetubo_lig(e){
    this.tubal_ligation = e;
  }

  OnDateChangelor(e){
    this.left_ovary_removed=e;
  }

  OnDateChangeror(e){
    this.right_ovary_removed=e;
  }

  OnDateChangelaparo(e){
    this.laparoscopy=e;
  }

  OnDateChangehysto_vag(e){
    this.hysterectomy_vaginal=e;
  }

  OnDateChangehysto_abd(e){
    this.hysterectomy_abdominal=e;
  }

  OnDateChangemymoect(e){
    this.myomectomy=e;
  }

  OnDateChangebladerorvaginalrepair(e){
    this.bladder_repair=e;
  }

  getCurrentDate(){
    var obj = Master_Helper.getMasterCurrentDate();
    if (obj.current_date != null) {
      this.currentDate = obj.current_date;
    }
   }
}
