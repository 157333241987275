<div class="row" style="width:100%;margin:0 auto;">
    <div class="col-12">
      <mdb-card>
        <mdb-card-header class="bg-white">
          <div class="headerCover">
            <div class="headerTilte app_list_header">
              <h5 class="m-0" class="mainHeadingStyle">Doctor referral list</h5>
            </div>
            <div class="headerButtons">
              <img class="saveimgbtn_inpatinfo" *ngIf="newbuttondie" src="../../../assets/ui_icons/buttons/new_button.svg" (click)="create()"
                style="margin-left: 9px ;" />
            </div>
          </div>
        </mdb-card-header>
        <mdb-card-body>
          <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="margin: 10px 0 0 0px;">
              <p class="nodata" *ngIf="referralist.length == 0">No referral(s) Found</p>
              <table *ngIf="referralist.length != 0" mdbTable datatable [dtOptions]="dtOptions"
                class="table table-nowrap table-sm dataTable billlisttable">
                <thead>
                  <tr>
                    <th>Profile image</th>
                    <th>Name</th>
                    <th>Referred by</th>
                    <th>Referred to</th>
                    <th>Speciality</th>
                    <th>Hospital</th>
                    <th>Referred date </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let person of referralist; let i = index" (click)="viewReferral(person)">
                    <td><img src="{{person.profile_image}}" onerror="this.src='../../../assets/img/default.jpg';"
                        class="img-fluid z-depth-1 rounded-circle mr-1"></td>
                    <td style="font-size: 12px;text-align: left;">{{ person.name }}</td>
                    <td style="font-size: 12px;">{{ person.referred_by }}</td>
                    <td style="font-size: 12px;">{{ person.referred_to }}</td>
                    <td style="font-size: 12px;">{{ person.speciality }}</td>
                    <td style="font-size: 12px;">{{ person.ref_hospital }}</td>
                    <td style="font-size: 12px;">{{ person.referred_date }}</td>
                  </tr>
                </tbody>
              </table>
              <br><br><br>
            </div>
          </div>
        </mdb-card-body>
      </mdb-card>
    </div>
  </div>
