import { Component, OnInit, Input, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from '../../helper_class';
import { Message_data } from '../../../assets/js/Message_data';
import { encrypt_decript } from 'src/assets/js/Encription_Decription';
import { FrontDesk_Helper } from '../FrontDesk_Helper';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ipaddress } from '../../ipaddress'; import { Diagnosis_Helper } from 'src/app/Diagnosis_module/Diagnosis_Helper';
import { Admin_Helper } from 'src/app/Admin_module/Admin_Helper';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';

@Component({
  selector: 'app-recept-bill-list',
  templateUrl: './recept-bill-list.component.html',
  styleUrls: ['./recept-bill-list.component.css']
})
export class ReceptBillListComponent implements OnInit {
  @ViewChild('focusMRNo') focusMRNo: ElementRef;
  @ViewChild('focusFName') focusFName: ElementRef;
  public userInfo;
  public billList = [];
  public hospitalList = [];
  public hptlClinicId: string;
  public fromFlag: boolean;
  public locationFlag: boolean;
  public toFlag: boolean;
  public uhidFlag: boolean;
  public mobileFlag: boolean;
  @Input() max: any;
  public currentDate;
  public currentYear;
  public currentTime
  public getDate;
  public noBillsFlag: boolean;
  public billTableFlag: boolean = true;
  public hospitalFlag: boolean;
  public fromDate;
  public toDate;
  public fdateFlag: boolean;
  public tdateFlag: boolean;
  public location;
  public filter: string;
  public cityFlag: boolean;
  public firstNameFlag: boolean;
  public firstName: string;
  public lastName: string;
  public patientId: string;
  public mobileNo: string;
  public cityList = [];
  public locationList = [];
  public cityDesc: string;
  public locationDesc: string;
  public billingType;
  dtOptions: DataTables.Settings = {};
  public doctorList: any;
  public billType: string;
  public doctorFlag: boolean;
  public doctorId: string;
  public billingList = [];
  public centerId: string;
  public hospId;
  public patientFlag: boolean;
  public diagCenterId;
  public type;
  public diagnosisFlag: boolean = false;
  public ynopt1: boolean = true;
  public Moduleidlist = [];
  public editbutton: boolean = false;
  public deletebutton: boolean = false;
  public newbutton: boolean = false;
  public viewbutton: boolean = false;
  public printbutton: boolean = false;
  public appType = "all";
  public packageFlag: boolean = false;

  constructor(public toastr: ToastrService,
    public http: HttpClient, public frontdeskservice: MenuViewService, public adminservice: MenuViewService, public cdr: ChangeDetectorRef) {
    this.hospitalFlag = true;
    this.cityFlag = true;
    this.firstNameFlag = true;
    this.locationFlag = true;
    this.fromFlag = false;
    this.toFlag = false;
    this.uhidFlag = true;
    this.mobileFlag = true;
  }

  ngOnInit(): void {
    this.userInfo = Helper_Class.getInfo();
    this.Moduleidlist = Helper_Class.getmodulelist();
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 25,
      "lengthMenu": [[25, 50, -1], [25, 50, "All"]],
      "columnDefs": [
        { "orderable": false, "targets": [3] },
      ],
      "language": {
        "search": "<img src='../../../assets/ui_icons/Bill_list/Filter_Icon.svg' width='18px'/>",
        searchPlaceholder: "Filter by bill no or patient name or date"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12">><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"l><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };

    if (this.Moduleidlist != undefined && this.Moduleidlist.length != 0) {
      for (var i = 0; i < this.Moduleidlist.length; i++) {
        if (this.Moduleidlist[i].module_id == "7") {
          if (this.Moduleidlist[i].edit == "1") {
            this.editbutton = true;
          }
          if (this.Moduleidlist[i].create == "1") {
            this.newbutton = true;
          }
          if (this.Moduleidlist[i].delete == "1") {
            this.deletebutton = true;
          }
          if (this.Moduleidlist[i].print == "1") {
            this.printbutton = true;
          }
          if (this.Moduleidlist[i].view == "1") {
            this.viewbutton = true;
          }
        }
      }
    }

    if (Helper_Class.getInfo().user_type == 'Admin') {
      this.editbutton = true;
      this.newbutton = true;
      this.deletebutton = true;
      this.printbutton = true;
      this.viewbutton = true;
    }

    this.billingType = "patient";
    this.patientFlag = true;
    this.hospitalFlag = this.hospitalList.length == 1 ? true : false;
    this.billType = "All";
    if (Helper_Class.getIsAdmin() != undefined) {
      this.type = "Admin";
      this.diagnosisFlag = Admin_Helper.getDiagBill() == false ? false : true;
      this.diagnosisFlag = false;
      // this.type = "reception";
      // this.diagnosisFlag = false;
    } else {
      this.type = "reception";
      this.diagnosisFlag = false;
    }
    this.hospId = Helper_Class.getInfo().hptl_clinic_id;
    this.diagCenterId = Helper_Class.getInfo().diag_center_id
    this.doctorFlag = true;
    this.billTableFlag = false;

    if (FrontDesk_Helper.getBillFromDate() != undefined) {
      this.fromDate = FrontDesk_Helper.getBillFromDate();
      this.toDate = FrontDesk_Helper.getBillToDate();

    } else {
      this.fromDate = FrontDesk_Helper.getAppDate();
      this.toDate = FrontDesk_Helper.getAppDate();
    }

    this.getCurrentDate();  
    this.change_type(1, 'yes');
    this.getChargeType();
  }

  getChargeType() { //Get bill type 
    console.log(this.userInfo.hptl_clinic_id);
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/chgtype',
      {
        hptl_clinic_id: this.userInfo.hptl_clinic_id,
        provider: "doctor",
        type: "in-patient",
      },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log("Response", obj);
          Helper_Class.setBillCharges(obj);
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      )
  }

  getCurrentDate() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate',
      { country: ipaddress.country_code },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.getDate = obj.current_date.split('-');
          this.currentYear = this.getDate[0];
          this.currentTime = obj.current_time;
          this.currentDate = obj.current_date;
          this.fromDate = obj.current_date;
          this.toDate = obj.current_date;
          console.log(this.fromDate);
          FrontDesk_Helper.setBillFromDate(this.fromDate);
          FrontDesk_Helper.setBillToDate(this.toDate);
          this.filter = "Date";
          this.changeFilter();
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }

  focusMRNoInput() {
    this.patientId = "";
    document.getElementById("focusMRNo").focus();
    this.focusMRNo.nativeElement.focus();
  }

  focusFirst_nameInput() {
    this.firstName = "";
    document.getElementById("focusFName").focus();
    this.focusFName.nativeElement.focus();
  }

  changeFilter() {
    if (this.filter == "Location") {
      this.cityFlag = false;
      this.locationFlag = false;
      this.firstNameFlag = true;
      this.fromFlag = true;
      this.toFlag = true;
      this.uhidFlag = true;
      this.mobileFlag = true;
      this.getCity();
    } else if (this.filter == "Date") {
      this.cityFlag = true;
      this.locationFlag = true;
      this.firstNameFlag = true;
      this.fromFlag = false;
      this.toFlag = false;
      this.uhidFlag = true;
      this.mobileFlag = true;
      this.searchBill();
    } else if (this.filter == "Name") {
      this.firstNameFlag = false;
      this.locationFlag = true;
      this.cityFlag = true;
      this.fromFlag = true;
      this.toFlag = true;
      this.uhidFlag = true;
      this.mobileFlag = true;
      this.cdr.detectChanges();
      this.focusFirst_nameInput();
    } else if (this.filter == "Patient_ID") {
      this.cityFlag = true;
      this.locationFlag = true;
      this.firstNameFlag = true;
      this.fromFlag = true;
      this.toFlag = true;
      this.uhidFlag = false;
      this.mobileFlag = true;
      this.cdr.detectChanges();
      this.focusMRNoInput();
    } else if (this.filter == "Mobile") {
      this.cityFlag = true;
      this.locationFlag = true;
      this.firstNameFlag = true;
      this.fromFlag = true;
      this.toFlag = true;
      this.uhidFlag = true;
      this.mobileFlag = false;
    }
  }

  getCity() {// Get city
    this.cityList = [];
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'dash/city/',
      {
        reg_id: this.userInfo.hptl_clinic_id,
        type: this.type
      },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.cityList = obj.cities;
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  changeCity(city) {//Get location based on city
    this.locationList = [];
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'dash/loc/',
      {
        reg_id: this.userInfo.hptl_clinic_id,
        city: city,
        type: this.type
      },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.locationList = obj.locations;
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  editBill(bill_no, docid, app_id, finyear) {
    var inpatient = this.billingType == "patient" ? "yes" : "no";
    var billedit;
    if (this.billingType == 'patient') {
      billedit = ({
        doc_reg_id: docid,
        bill_view_id: bill_no,
        app_id: app_id,
        is_inaptient: inpatient,
        fin_year: finyear,
      });
    } else {
      billedit = ({
        bill_view_id: bill_no,
        is_inaptient: inpatient,
        fin_year: finyear,
      });
    }   

    if (Helper_Class.getIsAdmin() != undefined) {
      FrontDesk_Helper.setreceptbilledit(billedit);
      this.adminservice.sendMessage("billingEdit");
    } else {
      FrontDesk_Helper.setreceptbilledit(billedit);
      this.frontdeskservice.sendMessage("receptbilledit");
    }
  }

  selectFromDate(e) {
    console.log("called");
    FrontDesk_Helper.setBillFromDate(undefined);
    this.fromDate = e;
    console.log(this.fromDate);
    FrontDesk_Helper.setBillFromDate(e);
    this.fdateFlag = true;
  }

  selectToDate(e) {
    FrontDesk_Helper.setBillToDate(undefined);
    this.toDate = e;
    FrontDesk_Helper.setBillToDate(e);
    this.tdateFlag = true;
  }

  getBillList() {
    this.billList = [];
    var inpatient = this.ynopt1 == true ? "no" : "yes";
    var appType = this.appType == "New" ? true : this.appType == "follow" ? false : undefined;
    var fieldvalue = {
      provider_id: this.userInfo.hptl_clinic_id,
      from_date: this.fromDate,
      to_date: this.toDate,
      app_type: this.type,
      inpatient: inpatient,
      is_new: appType,
      rep_admin: "yes",
      center_id: this.userInfo.hptl_clinic_id
    }

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/getbills',
      { fieldvalue },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          if (obj.bills != null) {
            this.setBillList(obj);
            this.noBillsFlag = false;
          } else {
            this.noBillsFlag = true;
            this.billTableFlag = true;
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  viewBill(bill_id, doc_reg_id, finyear, bill) { // view bills1
    console.log("json obj" + JSON.stringify(bill))
    var billdetail = ({
      bill_view_id: bill_id,
      fin_year: finyear,
      flow: "doctor",
      doc_reg_id: doc_reg_id
    })
    FrontDesk_Helper.setreceptbilldetailview(billdetail);
    if (this.type == "reception" || this.type == "dietician") {
      console.log("called");
      this.frontdeskservice.sendMessage("receptbilldetail");
    } else {
      this.adminservice.sendMessage("receptbilldetail");
    }
  }

  fnameToUpper() {
    if (this.firstName != undefined)
      this.firstName = this.firstName.toLocaleUpperCase().trim();
  }

  lnameToUpper() {
    if (this.lastName != undefined)
      this.lastName = this.lastName.toLocaleUpperCase().trim();
  }

  getPatientListByMRNo(patientId) {
    if (patientId != undefined && patientId.length > 8) {
      this.searchBill();
    }
  }

  searchBill() {
    FrontDesk_Helper.setBillToDate(this.toDate)
    this.billList = [];
    this.billingList = [];
    var fieldvalue;
    var inpatient = this.ynopt1 == true ? "no" : "yes";
    var userId = this.userInfo.user_id;
    var type =
      this.billingType == 'Diagnosis'
        ? 'diagnosis'
        : this.billingType == 'dietician'
          ? 'dietician'
          : this.billingType == 'package'
            ? 'package'
            : 'doctor';
    var providerID = this.billingType == "Diagnosis" ? this.userInfo.diag_center_id : this.userInfo.hptl_clinic_id;
    var appType = this.appType == "New" ? true : this.appType == "follow" ? false : undefined;
    console.log(this.fromDate);
    if (this.filter == "Patient_ID") {
      fieldvalue = {
        provider_id: providerID,
        patient_id: this.patientId,
        inpatient: inpatient,
        app_type: type,
        is_new: appType,
        rep_admin: "yes",
        center_id: providerID,
        is_advance: "0"
      }
    } else if (this.filter == "Location") {
      fieldvalue = {
        provider_id: providerID,
        city: this.cityDesc,
        location: this.location,
        inpatient: inpatient,
        app_type: type,
        is_new: appType,
        rep_admin: "yes",
        center_id: providerID,
        is_advance: "0"
      }
    } else if (this.filter == "Name") {
      fieldvalue = {
        provider_id: providerID,
        first_name: this.firstName,
        last_name: this.lastName,
        inpatient: inpatient,
        app_type: type,
        is_new: appType,
        rep_admin: "yes",
        center_id: providerID,
        is_advance: "0"
      }
    } else if (this.filter == "Date") {
      fieldvalue = {
        provider_id: providerID,
        from_date: this.fromDate,
        to_date: this.toDate,
        inpatient: inpatient,
        app_type: type,
        is_new: appType,
        rep_admin: "yes",
        center_id: providerID,
        is_advance: "0"
      }
    } else if (this.filter == "Mobile") {
      fieldvalue = {
        provider_id: providerID,
        mobile: this.mobileNo,
        inpatient: inpatient,
        app_type: type,
        is_new: appType,
        rep_admin: "yes",
        center_id: providerID,
        is_advance: "0"
      }
    }
    console.log("BILL FILEDS " + JSON.stringify(fieldvalue))
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/getbills/', { fieldvalue },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log(obj);
          if (obj.bills != 0) {
            this.noBillsFlag=false;
            if (this.billingType == "Diagnosis") {
              this.inSetBillList(obj);
              this.centerId = this.userInfo.diag_center_id;
            }
            else if (this.billingType == "package") {
              this.packageBillList(obj);
            }
            else {
              this.setBillList(obj);
              Helper_Class.setbilllist(obj);
            }
          } else {
            this.noBillsFlag = true;
            this.billTableFlag = true;
            this.billList = [];
            //this.toastr.error(Message_data.noBillsFound);
          }
          // this.getChargeType();
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
    this.location = "";
    this.cityDesc = "";
    this.firstName = "";
    this.lastName = "";
    this.mobileNo = "";
  }

  setBillList(obj) {
    this.billList = [];
    console.log(obj);
    if (obj.bills != null) {
      for (var i = 0; i < obj.bills.length; i++) {
        var clnt_nmae = null;
        if (obj.bills[i].middle_name != undefined && obj.bills[i].middle_name != null) {
          clnt_nmae = encrypt_decript.Decript(obj.bills[i].first_name) + " " + encrypt_decript.Decript(obj.bills[i].middle_name) + " " + encrypt_decript.Decript(obj.bills[i].last_name);
        } else {
          if (obj.bills[i].last_name != undefined && obj.bills[i].last_name != null) {
            clnt_nmae = encrypt_decript.Decript(obj.bills[i].first_name) + " " + encrypt_decript.Decript(obj.bills[i].last_name);
          } else {
            clnt_nmae = encrypt_decript.Decript(obj.bills[i].first_name);
          }
        }

        var status, edit_delete_flag = false;
        if (obj.bills[i].status != undefined && obj.bills[i].status != "") {
          if (obj.bills[i].status === "0") {
            status = "Closed";
          } else if (obj.bills[i].status === "1") {
            status = "Open";
          } else if (obj.bills[i].status === "2") {
            status = "Finalized";
            edit_delete_flag = true;
          } else if (obj.bills[i].status === "3") {
            status = "Settled";
            edit_delete_flag = true;
          }
        }

        var salutation = '';
        if (obj.bills[i].salutation != undefined && obj.bills[i].salutation != null) {
          salutation = obj.bills[i].salutation + ".";
        }

        var get_date = obj.bills[i].date.split('-');
        this.billList.push({
          bill_id: obj.bills[i].bill_id,
          fin_year: obj.bills[i].fin_year,
          date: get_date[2] + "-" + get_date[1] + "-" + get_date[0],
          salutation: salutation,
          client_name: clnt_nmae,
          pay_type: obj.bills[i].pay_type,
          amount: parseFloat(encrypt_decript.Decript(obj.bills[i].amount)).toFixed(2),
          amount_only: encrypt_decript.Decript(obj.bills[i].amount),
          doc_reg_id: obj.bills[i].doc_reg_id,
          doc_app_id: obj.bills[i].doc_app_id,
          advance: obj.bills[i].advance,
          status: status,
          flag: edit_delete_flag
        });
      }
      console.log(this.billList);
      var tot = 0;
      for (var i = 0; i < this.billList.length; i++) {
        tot = tot + parseFloat(this.billList[i].amount_only);
      }

      if (this.billList.length == 0) {
        this.noBillsFlag = true;
        this.billTableFlag = true;
      } else {
        this.noBillsFlag = false;
        this.billTableFlag = false;
      }
    }
  }

  packageBillList(obj) {
    this.billList = [];
    console.log(obj);
    if (obj.package_bills != null) {
      for (var i = 0; i < obj.package_bills.length; i++) {
        var clnt_nmae = null;
        if (obj.package_bills[i].middle_name != undefined && obj.package_bills[i].middle_name != null) {
          clnt_nmae = encrypt_decript.Decript(obj.package_bills[i].first_name) + " " + encrypt_decript.Decript(obj.package_bills[i].middle_name) + " " + encrypt_decript.Decript(obj.package_bills[i].last_name);
        } else {
          if (obj.package_bills[i].last_name != undefined && obj.package_bills[i].last_name != null) {
            clnt_nmae = encrypt_decript.Decript(obj.package_bills[i].first_name) + " " + encrypt_decript.Decript(obj.package_bills[i].last_name);
          } else {
            clnt_nmae = encrypt_decript.Decript(obj.package_bills[i].first_name);
          }
        }

        var status, edit_delete_flag = false;
        if (obj.package_bills[i].status != undefined && obj.package_bills[i].status != "") {
          if (obj.package_bills[i].status === "0") {
            status = "Closed";
          } else if (obj.package_bills[i].status === "1") {
            status = "Open";
          } else if (obj.package_bills[i].status === "2") {
            status = "Finalized";
            edit_delete_flag = true;
          } else if (obj.package_bills[i].status === "3") {
            status = "Settled";
            edit_delete_flag = true;
          }
        }

        var salutation = '';
        if (obj.package_bills[i].salutation != undefined && obj.package_bills[i].salutation != null) {
          salutation = obj.package_bills[i].salutation + ".";
        }

        var get_date = obj.package_bills[i].date.split('-');

        this.billList.push({
          bill_id: obj.package_bills[i].bill_id,
          fin_year: obj.package_bills[i].fin_year,
          date: get_date[2] + "-" + get_date[1] + "-" + get_date[0],
          salutation: salutation,
          client_name: clnt_nmae,
          pay_type: obj.package_bills[i].package_name,
          amount: parseFloat(encrypt_decript.Decript(obj.package_bills[i].bill_amount)).toFixed(2),
          amount_only: encrypt_decript.Decript(obj.package_bills[i].bill_amount),
          doc_reg_id: obj.package_bills[i].doc_reg_id,
          doc_app_id: obj.package_bills[i].doc_app_id,
          advance: obj.package_bills[i].advance,
          status: status,
          flag: edit_delete_flag
        });
      }
      console.log(this.billList);
      var tot = 0;
      for (var i = 0; i < this.billList.length; i++) {
        tot = tot + parseFloat(this.billList[i].amount_only);
      }

      if (this.billList.length == 0) {
        this.noBillsFlag = true;
        this.billTableFlag = true;
      } else {
        this.noBillsFlag = false;
        this.billTableFlag = false;
      }
    }
  }

  create() {//Redirect to bill create page
    this.hptlClinicId = this.userInfo.hptl_clinic_id;
    if (this.ynopt1 == true) {
      if (this.type != "Admin") {
        this.frontdeskservice.sendMessage("receptbillcreate");
        Helper_Class.setBillType(this.billingType);
      } else {
        Helper_Class.setBillType(this.billingType);
        this.frontdeskservice.sendMessage("adminReceptbillcreate");
      }
    } else {
      var inpatientBill = {
        app_id: "",
        flow: "inpatien-create"
      }
      Helper_Class.setInpatientBillEdit(inpatientBill);
      Helper_Class.setInpatientBillCreate("front-desk");
      this.frontdeskservice.sendMessage("ipatientBillCreate");
    }
  }

  digBillCreate() {
    var pattype;
    if (this.ynopt1 == true) {
      pattype = "outpatient";
    } else {
      pattype ="inpatient"
    }
    
    var type_user = {
      user_id: this.userInfo.user_id,
      user_type: "frontDesk",
      diag_centre_id: Helper_Class.getInfo().diag_centre_name,
      app_type: pattype
    }
    Diagnosis_Helper.setUserID_Type(type_user);
    if (this.userInfo.user_type == 'Admin') {
      this.frontdeskservice.sendMessage("diagbillcreate");
    } else {
      this.frontdeskservice.sendMessage("diaBillCreate");
    }
  }

  changeBillingType() {// inpatient billing changes
    if (this.billingType == "package") {
      this.packageFlag = true;
    } else {
      this.packageFlag = false;
    }
    
    if (this.billingType == "patient") {
      if (this.ynopt1 == true) {
        this.doctorFlag = false;
        this.getAllDoctors();
      }
      this.patientFlag = true;
    } else {
      this.billType = "diagnosis";
      this.doctorFlag = true;
      this.patientFlag = true;
    }

    var setFlow;
    Helper_Class.setBillingFlow(undefined);
    if (this.billingType == "patient") {
      setFlow = {
        flow: 'patient',
        user_id: this.userInfo.user_id
      }
      Helper_Class.setBillingFlow(setFlow);
    } else if(this.billingType == "package") {
      setFlow = {
        flow: 'package',
        user_id: this.userInfo.user_id
      }
      Helper_Class.setBillingFlow(setFlow);
    }
    console.log(Helper_Class.getBillingFlow());
    this.searchBill();
  }

  getAllDoctors() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    var senddata = {
      hptl_clinic_id: this.hospId
    }
    this.http.post(ipaddress.getIp.toString() + 'usercontroller/docbyhosp/', senddata,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));

          if (obj.doctors != null) {
            this.doctorList = [];
            for (var i = 0; i < obj.doctors.length; i++) {
              var doc_name;
              if (obj.doctors[i].middle_name != undefined || obj.doctors[i].middle_name != null) {
                doc_name = obj.doctors[i].first_name + " " + obj.doctors[i].middle_name + " " + obj.doctors[i].last_name;
              } else {
                doc_name = obj.doctors[i].first_name + " " + obj.doctors[i].last_name;
              }
              this.doctorList.push({
                doctor_id: obj.doctors[i].doc_reg_id,
                doctor_name: doc_name,
              })
            }
          }
        },
        error => { });
  }

  searchBills() {
    var headers = new HttpHeaders();
    var senddata = {
      hptl_clinic_id: this.hospId,
      bill_type: this.billType,
      doc_reg_id: this.doctorId,
      from_date: this.fromDate,
      to_date: this.toDate
    }
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/gbillByHosp/',
      senddata,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));

          if (obj.bills != null) {
            this.inSetBillList(obj);
            this.centerId = obj.center;
            this.noBillsFlag = false;
          } else {
            this.noBillsFlag = true;
            this.billTableFlag = true;
          }
        },
        error => { });
  }

  inSetBillList(obj) {
    if (obj.bills != null) {
      console.log("BILL DETAILS " + obj);
      for (var i = 0; i < obj.bills.length; i++) {
        var clnt_nmae = null;

        if (obj.bills[i].middle_name != undefined && obj.bills[i].middle_name != null) {
          clnt_nmae = encrypt_decript.Decript(obj.bills[i].first_name) + " " + encrypt_decript.Decript(obj.bills[i].middle_name) + " " + encrypt_decript.Decript(obj.bills[i].last_name);
        } else {
          if (obj.bills[i].last_name != undefined && obj.bills[i].last_name != null) {
            clnt_nmae = encrypt_decript.Decript(obj.bills[i].first_name) + " " + encrypt_decript.Decript(obj.bills[i].last_name);
          } else {
            clnt_nmae = encrypt_decript.Decript(obj.bills[i].first_name);
          }
        }

        var status, edit_delete_flag = false;
        if (obj.bills[i].status != undefined && obj.bills[i].status != "") {
          if (obj.bills[i].status === "0") {
            status = "Closed";
          } else if (obj.bills[i].status === "1") {
            status = "Open";
          } else if (obj.bills[i].status === "2") {
            status = "Finalized";
            edit_delete_flag = true;
          } else if (obj.bills[i].status === "3") {
            status = "Settled";
            edit_delete_flag = true;
          }
        }

        var salutation = '';
        if (obj.bills[i].salutation != undefined && obj.bills[i].salutation != null) {
          salutation = obj.bills[i].salutation + ".";
        }

        var get_date = obj.bills[i].date.split('-');
        this.billingList.push({
          bill_id: obj.bills[i].bill_id,
          fin_year: obj.bills[i].fin_year,
          date: get_date[2] + "-" + get_date[1] + "-" + get_date[0],
          client_name: clnt_nmae,
          amount: parseFloat(encrypt_decript.Decript(obj.bills[i].amount)).toFixed(2),
          amount_only: encrypt_decript.Decript(obj.bills[i].amount),
          diag_app_id: obj.bills[i].diag_app_id,
          salutation: salutation,
          status: status,
          flag: edit_delete_flag
        });
      }

      var tot = 0;
      for (var i = 0; i < this.billingList.length; i++) {
        tot = tot + parseFloat(this.billingList[i].amount_only);
      }

      if (this.billingList.length == 0) {
        this.noBillsFlag = true;
        this.billTableFlag = true;
      } else {
        this.noBillsFlag = false;
        this.billTableFlag = false;
      }
    }
    console.log("this.billingList" + JSON.stringify(this.billingList));
  }

  billTypeChange() {
    if (this.billType == "Doctor") {
      this.doctorFlag = false;
      this.getAllDoctors();
    } else {
      this.doctorFlag = true;
    }
  }


  inViewBill(billid, billtype, providerid, fin_year, bill) {
    console.log("inpatient --" + JSON.stringify(bill))
    if (this.type == "reception" || this.type == "diagnosis" || this.type == "Admin") {
      console.log("called...");
      FrontDesk_Helper.setBillsDetails(null);
      var billdetail = {
        bill_view_id: billid,
        Diag_center_id: this.diagCenterId,
        fin_year: fin_year,
        flow: "frontdesk_billing",
        advance: bill.advance
      }
      Diagnosis_Helper.setbilldetailview(billdetail);
      var sendData = {
        center_id: this.diagCenterId,
        flow: "frontdeskbilling",
      }
      Diagnosis_Helper.setCreateDiagAppt(sendData);
      providerid = this.type == "diagnosis" ? this.centerId : providerid;
      var getBillDetails = {
        bill_view_id: billid,
        fin_year: fin_year,
        provider_type: billtype,
        provider: providerid,
      }
      FrontDesk_Helper.setBillsDetails(getBillDetails);
      if (this.userInfo.user_type == 'Admin') {
        this.frontdeskservice.sendMessage("diagBillingView");
      } else {
        if (this.billingType == "Diagnosis") {
          this.frontdeskservice.sendMessage("diaBillingDetailsView");
        } else {
          this.frontdeskservice.sendMessage("billingDetailsView");
        }
      }   
    }
  }

  change_type(id, value) {
    if (value == "yes") {
      this.ynopt1 = true;
    } else {
      this.ynopt1 = false;
    }
    this.changeBillingType();
  }

  deleteBill(bill_no, fin_year) {
    var type = this.billingType == "Diagnosis" ? "diagnosis" : this.billingType == "dietician" ? "dietician" : "doctor";
    var providerID = this.billingType == "Diagnosis" ? this.userInfo.diag_center_id : this.userInfo.hptl_clinic_id;
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/cbill',
      {
        bill_no: bill_no,
        fin_year: fin_year,
        hptl_clinic_id: providerID,
        userid: Helper_Class.getInfo().user_id,
        app_type: type,
        country: "IN",
        del_by: "front-desk"
      },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          if (obj.key == "1") {
            this.toastr.success(Message_data.billCancelledSuccess);
            this.searchBill();
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      )
  }
}
