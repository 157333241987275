import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClientRegistComponent } from './client-regist/client-regist.component';
import { DiagnosisRegistrationComponent } from './diagnosis-registration/diagnosis-registration.component';
import { DieticianRegistrationComponent } from './dietician-registration/dietician-registration.component';
import { DocterRegistrationComponent } from './docter-registration/docter-registration.component';

import { ForgotpwdComponent } from './forgotpwd/forgotpwd.component';
import { FrontDeskRegistrationComponent } from './front-desk-registration/front-desk-registration.component';
import { IndexComponent } from './index/index.component';
import { LoginpgComponent } from './loginpg/loginpg.component';
import { NurseRegistrationComponent } from './nurse-registration/nurse-registration.component';
import { PharmaRegistrationComponent } from './pharma-registration/pharma-registration.component';
import { PhysioRegistrationComponent } from './physio-registration/physio-registration.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { RduComponent } from './rdu/rdu.component';
import { MenuViewComponent } from './menu-view/menu-view.component';
import { WithoutLoginSearchComponent } from './without-login-search/without-login-search.component';
import { RegistrationComponent } from './registration/registration.component';
import { ServicesComponent } from './services/services.component';
import { SolutionsComponent } from './solutions/solutions.component';
import { ContactusComponent} from './contactus/contactus.component';
import { DiagManagementComponent} from './diag-management/diag-management.component';
import { NutritionManagementComponent } from './nutrition-management/nutrition-management.component';
import { HospManagementComponent } from './hosp-management/hosp-management.component';
import { SpecialityComponent } from './speciality/speciality.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { PharmaManagementComponent } from './pharma-management/pharma-management.component';
import { ClinicalDecisionComponent } from './clinical-decision/clinical-decision.component';
import { PredictiveHealthComponent } from './predictive-health/predictive-health.component';

const routes: Routes = [
  {path:'',component:IndexComponent},
  {path:'loginpage',component:LoginpgComponent},
  { path: 'forgotpwd',component:ForgotpwdComponent },
  { path: 'frontdesk',component:FrontDeskRegistrationComponent },
  { path: 'doctorRegistration',component:DocterRegistrationComponent },
  { path: 'clientreg',component:ClientRegistComponent },
  { path: 'pharmareg',component:PharmaRegistrationComponent },
  { path: 'diagnosisreg',component:DiagnosisRegistrationComponent },
  { path: 'physioreg',component:PhysioRegistrationComponent },
  { path: 'nursereg',component:NurseRegistrationComponent },
  { path: 'privacy_policy',component:PrivacyPolicyComponent },
  { path: 'doctorsearch',component:WithoutLoginSearchComponent },
  { path: 'dieticianreg',component:DieticianRegistrationComponent },
  { path: 'index',   redirectTo: 'index', pathMatch: 'full' },
  { path: 'rdu',   component:RduComponent },
  { path: 'menuView',   component:MenuViewComponent },
  { path: 'registration',   component:RegistrationComponent },
  { path: 'Services',   component:ServicesComponent },
  { path: 'solutions',   component:SolutionsComponent },
  { path:"contactus",component:ContactusComponent},
  { path:"diagnosis",component:DiagManagementComponent},
  { path:"clinic",component:ClinicalDecisionComponent},
  { path:"nutrition",component:NutritionManagementComponent},
  { path:"health",component:PredictiveHealthComponent},
  { path:"speciality",component:SpecialityComponent},
  { path:"aboutus",component:AboutusComponent},
  { path:"hosmanage",component:HospManagementComponent},
  { path:"pharma_info",component:PharmaManagementComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CommonRoutingModule { }
