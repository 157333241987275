

<div class="pt-3 pb-3 vr-footer-section">
    <div class="container">
       <div class="row">
          <div class="col-12 col-md-3">
             <div class=" text-md-left mt-3">
                <img src="../../../assets/images/tervys-logo.png" class="vr-logo" />
                <div class="d-flex flex-row justify-content-center justify-content-lg-start mt-3 mb-3">
                   <div class="vr-icon-container">
                      <i class="icon fa-brands fa-google"></i>
                   </div>
                   <div class="vr-icon-container">
                      <i class="icon fa-brands fa-twitter"></i>
                   </div>
                   <div class="vr-icon-container">
                      <i class="icon fa-brands fa-instagram"></i>
                   </div>
                   <div class="vr-icon-container">
                      <i class="icon fa-brands fa-linkedin"></i>
                   </div>
                </div>
                <p class="vr-footer-section-text"> No: 31, Vembuliamman koil street, Pazhavanthangal, Chennai 600114,
                   Tamil Nadu, India</p>
             </div>
          </div>
          <div class="col-7 col-md-3 mt-3">
             <h1 class="vr-footer-section-heading pb-2"> Contact Us </h1>
             <ul class="vr-footer-section-list">
                <li class="mb-3">
                   <i class="fa-solid fa-envelope icon2 mr-2"></i>
                   <span class="vr-footer-section-text">info@gritsys.com</span>
                </li>
                <li class="mb-3">
                   <i class="fa-solid fa-phone icon2 mr-2"></i>
                   <span class="vr-footer-section-text">044-49526808</span>
                </li>
                <li class="mb-3">
                   <i class="fa-solid fa-globe icon2 mr-2"></i>
                   <span class="vr-footer-section-text">www.gritsys.com</span>
                </li>
             </ul>
          </div>
          <div class="col-5 col-md-3 mt-3">
             <h1 class="vr-footer-section-heading pb-2">Services </h1>
             <p class="vr-footer-section-text"> Primarycare & internal medicine</p>
             <p class="vr-footer-section-text"> Pain & spain care</p>
             <p class="vr-footer-section-text"> Home visit services </p>
             <p class="vr-footer-section-text"> Consultation </p>
          </div>
          <div class="col-6 col-md-3 mt-3">
             <h1 class="vr-footer-section-heading pb-2">Why Choose Us? </h1>
             <p class="vr-footer-section-text"> Home Care</p>
             <p class="vr-footer-section-text"> Quick Appointments</p>
             <p class="vr-footer-section-text"> Analytics </p>
             <p class="vr-footer-section-text"> Easy Access </p>
          </div>
       </div>
       <hr class="hr-line" />
       <div class="text-center">
          <i class="fa-solid fa-copyright icon2"></i>
          <span class="vr-footer-section-cp"> 2023 All rights reserved - www.tervys.com</span>
       </div>
    </div>
 </div>
