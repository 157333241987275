<div class="container">
  <div class="row">
    <div class="col-12">
      <mdb-card>
        <mdb-card-header class="bg-white">
          <div class="headerCover">
            <div class="headerTilte app_list_header">
              <h5 class="m-0" class="mainHeadingStyle">Casesheet view</h5>
            </div>
            <div class="headerButtons">
              <img src="../../../assets/ui_icons/buttons/Back_button.svg" class="saveimgbtn_inpatinfo"
                (click)="backClicked()" />
              <img class="" src="../../../assets/img/printer.svg" style="width:25px; height:25px; margin: 0 0 0 7px;"
                (click)="print_area()">
            </div>
          </div>

        </mdb-card-header>
        <mdb-card-body>
          <div class="container">
            <div class="row">
              <!-- <div class="cover_div">
                <div class="header_lable"></div>
                <div class="content_cover">
                  <div class="row">                  
                    <table id="table_header" class="d-flex justify-content-center">
                      <tr>
                        <td class="firsttd_style"></td>
                        <td style="text-align: left;">
                          <p class="hosp_details"><strong>{{hosp_name}}</strong></p>
                          <p class="hosp_details_addr">{{hosp_addr}} {{hosp_csz}}</p>
                          <p class="hosp_details_addr">Tel: {{hosp_tel}}</p>
                        </td>
                        <td class="threetd_style">
                          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                        </td>
                      </tr>
                    </table>
                  </div>

                </div>
              </div> -->
            </div>
            <div class="row margin">
              <div class="cover_div">
                <div class="header_lable">Personal details</div>
                <div class="content_cover">

                  <div class="row">
                    <div class="col-3">
                      <p class="textformat"><span style="font-weight: 550;">MR No:</span> {{patient_id}}</p>

                    </div>
                    <div class="col-3">
                      <p class="textformat"><span style="font-weight: 550;">Name:</span> {{patient_name}}</p>

                    </div>
                    <div class="col-3">
                      <p class="textformat"><span style="font-weight: 550;">Age/Gender:</span> {{patient_age}}/
                        {{patient_gender}}</p>
                    </div>
                    <div class="col-3">
                      <p class="textformat"><span style="font-weight: 550;">Current occupation:</span>
                        {{current_occup}}</p>
                    </div>
                    <!-- <div class="col-3" *ngIf="ageflag && genderflag">
                        <p class="textformat"><span style="font-weight: 550;">Mobile:</span> {{patient_age}}/ {{patient_gender}}</p>
                      </div> -->

                    <div class="col-3">
                      <p class="textformat"><span style="font-weight: 550;">Doctor:</span> {{doctor_name}}</p>
                    </div>
                    <div class="col-3">
                      <p class="textformat"><span style="font-weight: 550;">Disease:</span> {{disease}}</p>
                    </div>
                    <div class="col-3">
                      <p class="textformat"><span style="font-weight: 550;">Symptoms:</span> {{symptoms}}</p>
                    </div>
                    <div class="col-3">
                      <p class="textformat"><span style="font-weight: 550;">Stress factors:</span> {{stress_factor}}
                      </p>
                    </div>
                    <!-- <div class="col-3">
                                    Op number: {{outpatientnumber}}
                                </div> -->


                  </div>


                </div>
              </div>
            </div>
            <!-- cardio -->
            <div class="row " *ngIf="spl_name == 'Cardiology'">
              <div class="col-12">
                <div class="row margin">
                  <div class="cover_div">
                    <div class="header_lable">Vitals</div>
                    <div class="content_cover">
                      <div class="row">
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Height:</span> {{height}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Weight:</span> {{weight}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">BMI:</span> {{bmi}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Body type:</span> {{body_type}}</p>
                        </div>
                        <div class="col-6">
                          <p class="textformat"><span style="font-weight: 550;">Body nature:</span> {{body_nature}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Heart sound:</span> {{heart_sound}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Diatory habits:</span> {{diet_habits}}
                          </p>
                        </div>


                      </div>
                      <div class="row" [hidden]="bp_flag">
                        <div class="col-6">
                          <p class="textformat"><span style="font-weight: 550;">Blood pressure:</span> Right arm:
                            {{bp_right}}, Left arm: {{bp_left}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- chest pain -->
                <div class="row margin">
                  <div class="cover_div">
                    <div class="header_lable">Chest pain</div>
                    <div class="content_cover">
                      <div class="row">
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Pain location:</span> {{pain_location}}
                          </p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Pain character:</span> {{pain_location}}
                          </p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Pain character:</span> {{weight}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Radiation:</span> {{bmi}}</p>
                        </div>
                        <div class="col-6">
                          <p class="textformat"><span style="font-weight: 550;">Duration:</span> {{body_type}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Aggravating factor:</span>
                            {{body_nature}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Relieving factor:</span> {{heart_sound}}
                          </p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Pain intensity:</span> {{body_nature}}
                          </p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Pain cause:</span> {{heart_sound}}</p>
                        </div>


                      </div>

                    </div>
                  </div>
                </div>
                <!--Breathing  -->
                <div class="row margin">
                  <div class="cover_div">
                    <div class="header_lable">Breathing dificulties</div>
                    <div class="content_cover">
                      <div class="row">
                        <div class="col-3">
                          <p class="textformat"><span style="">Trigers:</span> {{breath_trigers}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="">Onset:</span> {{breath_onset}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="">Cold sweats:</span> {{breath_cold_sweat}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="">Diziness:</span> {{breath_diziness}}</p>
                        </div>
                        <div class="col-6">
                          <p class="textformat"><span style="">Vomiting:</span> {{breath_vomiting}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="">Palpitation:</span> {{breath_palpitation}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="">Palpitation type:</span> {{breath_palp_type}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="">Cough:</span> {{breath_cough}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="">Mucus:</span> {{breath_mucus}}</p>
                        </div>


                      </div>

                    </div>
                  </div>
                </div>
                <div class="row margin" [hidden]="mainconc_flag">
                  <div class="cover_div">
                    <div class="header_lable">Past medical history</div>
                    <div class="content_cover">
                      <div [innerHTML]="main_concerns"></div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- diab -->
            <div class="row" *ngIf="spl_name == 'Diabetes'">
              <div class="col-12">
                <div class="row margin">
                  <div class="cover_div">
                    <div class="header_lable">Vitals</div>
                    <div class="content_cover">
                      <div class="row">
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Height:</span> {{height}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Weight:</span> {{weight}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">BMI:</span> {{bmi}}</p>
                        </div>


                      </div>

                    </div>
                  </div>
                </div>
                <div class="row margin">
                  <div class="cover_div">
                    <div class="header_lable">concerns</div>
                    <div class="content_cover">
                      <div class="row">
                        <div class="col-3" [hidden]="mainconc_flag">
                          <div style="display: flex;">
                            <p class="textformat"><span style="">Main concerns: </span></p>
                            <div [innerHTML]="main_concerns"></div>
                          </div>
                        </div>
                        <div class="col-3" [hidden]="medhist_flag">
                          <div style="display: flex;">
                            <p class="textformat"><span style="">Medical history: </span></p>
                            <div [innerHTML]="med_hist"></div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                <div class="row margin">
                  <div class="cover_div">
                    <div class="header_lable">Recommended excercises</div>
                    <div class="content_cover">
                      <div class="row">
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Excercise:</span> {{rec_excercise}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Duration:</span> {{rec_dur}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Frequency:</span> {{rec_freq}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!--Comorbidities  -->
                <div class="row margin" [hidden]="comorb_flag">
                  <div class="cover_div">
                    <div class="header_lable">Comorbidities</div>
                    <div class="content_cover">
                      <div class="row">
                        <div class="col-3">
                          <p class="textformat"><span style="">Cardiological:</span> {{cardiodis}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="">Mobility:</span> {{mobility}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="">Nephrological:</span> {{neprhodis}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="">Neurological:</span> {{neurodis}}</p>
                        </div>
                        <div class="col-6">
                          <p class="textformat"><span style="">Retino issues:</span> {{retinodis}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="">Dermatological:</span> {{skindis}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="">Hearing issues:</span> {{hearingdis}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="">Foot issues:</span> {{footdis}}</p>
                        </div>


                      </div>

                    </div>
                  </div>
                </div>

              </div>
            </div>

            <!-- ENT -->

            <div class="row " *ngIf="spl_name == 'ENT'">
              <div class="col-12">
                <div class="row margin">
                  <div class="cover_div">
                    <div class="header_lable">Vitals</div>
                    <div class="content_cover">
                      <div class="row">
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Height:</span> {{height}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Weight:</span> {{weight}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">BMI:</span> {{bmi}}</p>
                        </div>


                      </div>

                    </div>
                  </div>
                </div>
                <div class="row margin">
                  <div class="cover_div">
                    <div class="header_lable">concerns</div>
                    <div class="content_cover">
                      <div class="row">
                        <div class="col-3" [hidden]="mainconc_flag">
                          <div style="display: flex;">
                            <p class="textformat"><span style="">Main concerns: </span></p>
                            <div [innerHTML]="main_concerns"></div>
                          </div>
                        </div>
                        <div class="col-3" [hidden]="medhist_flag">
                          <div style="display: flex;">
                            <p class="textformat"><span style="">Medical history: </span></p>
                            <div [innerHTML]="med_hist"></div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>




              </div>
            </div>
            <!-- *ngIf="spl_name == 'General' || spl_name == 'General surgery'" -->
            <div class="row" *ngIf="spl_name == 'General' || spl_name == 'General surgery'">
              <div class="col-12">
                <div class="row margin">
                  <div class="cover_div">
                    <div class="header_lable">Vitals</div>
                    <div class="content_cover">
                      <div class="row">
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Height:</span> {{height}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Weight:</span> {{weight}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">BMI:</span> {{bmi}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Body type:</span> {{body_type}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Body nature:</span> {{body_nature}}</p>
                        </div>


                      </div>
                      <div class="row" [hidden]="bp_flag">
                        <div class="col-6">
                          <p class="textformat"><span style="font-weight: 550;">Blood pressure:</span> Right arm:
                            {{bp_right}}, Left arm: {{bp_left}}</p>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <div class="row margin" [hidden]="mainconc_flag || medhist_flag" >
                  <div class="cover_div">
                    <div class="header_lable">concerns</div>
                    <div class="content_cover">
                      <div class="row">
                        <div class="col-3" [hidden]="mainconc_flag">
                          <div style="display: flex;">
                            <p class="textformat"><span style="">Main concerns: </span></p>
                            <div [innerHTML]="main_concerns"></div>
                          </div>
                        </div>
                        <div class="col-3" [hidden]="medhist_flag">
                          <div style="display: flex;">
                            <p class="textformat"><span style="">Past medical history: </span></p>
                            <div [innerHTML]="med_hist"></div>
                          </div>
                        </div>
                        <div class="col-3" [hidden]="medhist_flag">
                          <div style="display: flex;">
                            <div class="row">
                              <div class="col-12">
                                <p><b>Surgery:</b> {{surgery}}</p>
                                <p><b>Procedure:</b> {{surgery_proc}}</p>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>




              </div>
            </div>

            <!-- spl_name == 'Gynecology -->
            <div class="row" *ngIf="spl_name == 'Gynecology'">
              <div class="col-12">
                <div class="row margin">
                  <div class="cover_div">
                    <div class="header_lable">Vitals</div>
                    <div class="content_cover">
                      <div class="row">
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Height:</span> {{height}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Weight:</span> {{weight}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">BMI:</span> {{bmi}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Body type:</span> {{body_type}}</p>
                        </div>
                        <div class="col-6">
                          <p class="textformat"><span style="font-weight: 550;">Body nature:</span> {{body_nature}}</p>
                        </div>
                        <!-- <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Heart sound:</span> {{heart_sound}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Diatory habits:</span> {{diet_habits}}
                          </p>
                        </div> -->


                      </div>
                      <div class="row" [hidden]="bp_flag">
                        <div class="col-6">
                          <p class="textformat"><span style="font-weight: 550;">Blood pressure:</span> Right arm:
                            {{bp_right}}, Left arm: {{bp_left}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Menstrual -->
                <div class="row margin">
                  <div class="cover_div">
                    <div class="header_lable">Menstrual</div>
                    <div class="content_cover">
                      <div class="row">
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Stage:</span> {{mens_stage}}
                          </p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Periods:</span> {{mens_periods}}
                          </p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Last menstrual period:</span> {{last_mens_period}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Cycle length:</span> {{mens_cycle_length}}</p>
                        </div>
                        <div class="col-6">
                          <p class="textformat"><span style="font-weight: 550;">Menstrual pain:</span> {{mens_pain}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Pain frequency:</span>
                            {{mens_pain_freq}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Pain timing:</span> {{mens_pain_time}}
                          </p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Pain character:</span> {{mens_pain_char}}
                          </p>
                        </div>
                        <!-- <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Pain cause:</span> {{heart_sound}}</p>
                        </div> -->


                      </div>

                    </div>
                  </div>
                </div>
                <!--concerns  -->
           
                <div class="row margin" [hidden]="mainconc_flag || medhist_flag" >
                  <div class="cover_div">
                    <div class="header_lable">concerns</div>
                    <div class="content_cover">
                      <div class="row">
                        <div class="col-3" [hidden]="mainconc_flag">
                          <div style="display: flex;">
                            <p class="textformat"><span style="">Main concerns: </span></p>
                            <div [innerHTML]="main_concerns"></div>
                          </div>
                        </div>
                        <div class="col-3" [hidden]="medhist_flag">
                          <div style="display: flex;">
                            <p class="textformat"><span style="">Past medical history: </span></p>
                            <div [innerHTML]="med_hist"></div>
                          </div>
                        </div>
                        <div class="col-3" [hidden]="medhist_flag">
                          <div style="display: flex;">
                            <div class="row">
                              <div class="col-12">
                                <p><b>Surgery:</b> {{surgery}}</p>
                                <p><b>Procedure:</b> {{surgery_proc}}</p>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- spl_name == 'others -->
            <div class="row" *ngIf="others">
              <div class="col-12">
                <div class="row margin">
                  <div class="cover_div">
                    <div class="header_lable">Vitals</div>
                    <div class="content_cover">
                      <div class="row">
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Height:</span> {{height}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Weight:</span> {{weight}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">BMI:</span> {{bmi}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Body type:</span> {{body_type}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Body nature:</span> {{body_nature}}</p>
                        </div>
                        <div class="col-3"  [hidden]="bp_flag">
                          <p class="textformat"><span style="font-weight: 550;">Blood pressure:</span> {{bp_right}}</p>
                        </div>


                      </div>
                  

                    </div>
                  </div>
                </div>
                <div class="row margin" [hidden]="mainconc_flag || medhist_flag" >
                  <div class="cover_div">
                    <div class="header_lable">concerns</div>
                    <div class="content_cover">
                      <div class="row">
                        <div class="col-3" [hidden]="mainconc_flag">
                          <div style="display: flex;">
                            <p class="textformat"><span style="">Main concerns: </span></p>
                            <div [innerHTML]="main_concerns"></div>
                          </div>
                        </div>
                        <div class="col-3" [hidden]="medhist_flag">
                          <div style="display: flex;">
                            <p class="textformat"><span style="">Past medical history: </span></p>
                            <div [innerHTML]="med_hist"></div>
                          </div>
                        </div>
                        <div class="col-3" [hidden]="medhist_flag">
                          <div style="display: flex;">
                            <div class="row">
                              <div class="col-12">
                                <p><b>Surgery:</b> {{surgery}}</p>
                                <p><b>Procedure:</b> {{surgery_proc}}</p>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>




              </div>
            </div>
              <!-- spl_name == pediatrics -->
              <div class="row" *ngIf="spl_name == 'Pediatrics'">
                <div class="col-12">
                  <div class="row margin">
                    <div class="cover_div">
                      <div class="header_lable">Clinical</div>
                      <div class="content_cover">
                        <div class="row">
                          <div class="col-3">
                            <p class="textformat"><span style="font-weight: 550;">Complications:</span> {{ped_complic}}</p>
                          </div>
                          <div class="col-3">
                            <p class="textformat"><span style="font-weight: 550;">Mother's age at birth:</span> {{mother_age}}</p>
                          </div>
                          <div class="col-3">
                            <p class="textformat"><span style="font-weight: 550;">Delivery type:</span> {{delivery_type}}</p>
                          </div>
                          <div class="col-3">
                            <p class="textformat"><span style="font-weight: 550;">Gestational age:</span> {{gestational_age}}</p>
                          </div>
                          <div class="col-3">
                            <p class="textformat"><span style="font-weight: 550;">Allergies:</span> {{allergies}}</p>
                          </div>
                          <div class="col-3"  [hidden]="bp_flag">
                            <p class="textformat"><span style="font-weight: 550;">Feeding habit:</span> {{feeding_habit}}</p>
                          </div>
  
  
                        </div>
                    
  
                      </div>
                    </div>
                  </div>
                  <div class="row margin" [hidden]="mainconc_flag || medhist_flag" >
                    <div class="cover_div">
                      <div class="header_lable">concerns</div>
                      <div class="content_cover">
                        <div class="row">
                          <div class="col-3" [hidden]="mainconc_flag">
                            <div style="display: flex;">
                              <p class="textformat"><span style="">Main concerns: </span></p>
                              <div [innerHTML]="main_concerns"></div>
                            </div>
                          </div>
                          <div class="col-3" [hidden]="medhist_flag">
                            <div style="display: flex;">
                              <p class="textformat"><span style="">Past medical history: </span></p>
                              <div [innerHTML]="med_hist"></div>
                            </div>
                          </div>
                       
  
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row margin" [hidden]="materill_flag" >
                    <div class="cover_div">
                      <div class="header_lable">Maternal illness</div>
                      <div class="content_cover">
                        <div class="row">
                      
                          <div class="col-3" [hidden]="medhist_flag">
                            <div style="display: flex;">
                              <div [innerHTML]="med_hist"></div>
                            </div>
                          </div>
                       
  
                        </div>
                      </div>
                    </div>
                  </div>
  
  
  
  
                </div>
              </div>
                <!-- fdrugs -->
                <div class="row margin">
                  <div class="col-12">
                    <div class="cover_div" style="padding-bottom: 13px;" *ngIf="druglist.length !=0">
                      <div class="header_lable">Medicines</div>
                      <div class="content_cover">
                        <div class="table-responsive dig_table_overflow">
                          <table *ngIf="druglist.length" class="table table-nowrap table-sm dataTable billlisttable">
                            <thead>
                              <tr>
                                <th>Medicine</th>
                                <th>Dosage</th>
                                <th>Days</th>                         
                              </tr>
                            </thead>
                            <tbody>
                              <tr style="height: 30px;" *ngFor="let medicine of druglist">
                                <td style="font-size: 12px;" class="txtalign">{{medicine.medicine_name}}</td>
                                <td style="font-size: 12px;" class="txtalignright">{{medicine.medicine_dosage}}</td>
                                <td style="font-size: 12px;" class="txtalignright">{{medicine.medicine_intakedays+" -
                                  "+medicine.medicine_food}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
               
            <!-- olf -->
           
          </div>

        </mdb-card-body>
      </mdb-card>
    </div>
  </div>
</div>

<!-- print -->
<div [hidden]="true">
  <div *ngIf="spl_name == 'Cardiology'" class="col-12 col-md-12 col-lg-12 col-xl-10">
    <div #casesheetsummary id="casesheetsummary">
      <div>
        <table>
          <tr>
            <td style="width: 250px;"></td>
            <td>
              <p style="font-size: 24px;"><strong>{{hosp_name}}</strong></p>
              <p>{{hosp_addr}} {{hosp_csz}}</p>
              <p>Tel: {{hosp_tel}}</p>
            </td>
            <td style="width: 250px;"> </td>
          </tr>
        </table>
      </div>
      <div style="margin-left: 50px;">
        <p style="width: 211px !important;
          padding: 1px !important;
          background: #eaeaec !important;
          display: inline-flex !important;
          position: relative !important;
          padding-left: 5px !important;
          border: 1px solid #b5b5b6;
          top: 16px !important;"><strong>Personal information </strong></p>
      </div>
      <div style="border: 1px solid #b5b5b6; width: 85%;margin-left: 50px;">
        <table style="margin-top: 5px;margin-left: 50px;">
          <tr>
            <td><strong>MR No:</strong> {{patient_id}}</td>
            <td style="width: 200px;"></td>
            <td><strong>Date:</strong> {{curr_date}}</td>
          </tr>
          <tr style="background-color: #fff;">
            <td><strong>Name:</strong> {{patient_name}}</td>
            <td style="width: 200px;"></td>
            <td><strong>Doctor:</strong> {{patient_name}}</td>
          </tr>
          <tr>
            <td><strong>Age/Gender:</strong> {{patient_age}}/ {{patient_gender}}</td>
            <td style="width: 200px;"></td>
            <td>&nbsp;</td>
          </tr>
          <!-- <tr style="background-color: #fff;">
            <td><strong>Gender:</strong> {{gender}}</td>
            <td style="width: 200px;"></td>
            <td>&nbsp;</td>
          </tr> -->
          <tr>
            <td><strong>Occupation: </strong>{{current_occup}}</td>
          </tr>
        </table>
      </div>

      <div style="margin-left: 50px; margin-top: -20px;">
        <p style="width: 211px !important;
          padding: 1px !important;
          background: #eaeaec !important;
          display: inline-flex !important;
          position: relative !important;
          padding-left: 5px !important;
          border: 1px solid #b5b5b6;
          top: 32px !important;"><strong>Vitals </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;">Height: {{height}}, Weight: {{weight}}, BMI: {{bmi}}, Body
          type: {{body_type}}, Body nature: {{body_nature}}</p>

        <p style="width: 211px !important;
          padding: 1px !important;
          background: #eaeaec !important;
          display: inline-flex !important;
          position: relative !important;
          padding-left: 5px !important;
          border: 1px solid #b5b5b6;
          margin-top: -20px;
          top: 32px !important;" [hidden]="mainconc_flag"><strong>Main concerns </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;" [hidden]="mainconc_flag" [innerHTML]="main_concerns"></p>

        <p style="width: 211px !important;
          padding: 1px !important;
          background: #eaeaec !important;
          display: inline-flex !important;
          position: relative !important;
          padding-left: 5px !important;
          border: 1px solid #b5b5b6;
          margin-top: -20px;
          top: 32px !important;" [hidden]="medhist_flag"><strong>Past medical history: </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;" [hidden]="medhist_flag"  [innerHTML]="med_hist">{{med_hist}}</p>
        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        margin-top: -20px;
        top: 32px !important;" [hidden]="medhist_flag"><strong>Complaints: </strong></p>
      <p style="border: 1px solid #b5b5b6; width: 90%;" [hidden]="complaints_flag" [innerHTML]="complaints">{{complaints}}</p>

        <p style="width: 211px !important;
          padding: 1px !important;
          background: #eaeaec !important;
          display: inline-flex !important;
          position: relative !important;
          padding-left: 5px !important;
          border: 1px solid #b5b5b6;
          margin-top: -20px;
          top: 32px !important;" [hidden]="bp_flag"><strong>Blood pressure: </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;" [hidden]="bp_flag">Right arm: {{bp_right}}, Left arm:
          {{bp_left}}</p>

        <p style="width: 211px !important;
          padding: 1px !important;
          background: #eaeaec !important;
          display: inline-flex !important;
          position: relative !important;
          padding-left: 5px !important;
          border: 1px solid #b5b5b6;
          margin-top: -20px;
          top: 32px !important;" [hidden]="bp_flag"><strong>Medicines: </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;" [hidden]="bp_flag">Surgery: {{surgery}} <br /> Procedure:
          {{surgery_proc}}</p>


        <p style="width: 211px !important;
          padding: 1px !important;
          background: #eaeaec !important;
          display: inline-flex !important;
          position: relative !important;
          padding-left: 5px !important;
          border: 1px solid #b5b5b6;
          margin-top: -20px;
          top: 32px !important;" [hidden]="med_flag"><strong>Medicines: </strong></p>
        <table *ngIf="druglist.length !=0" style="border: 1px solid #b5b5b6; width: 90%;margin-top: 16px;">
          <tr style="background: #d6d8da;color:#000000">
            <th style="width: 200px;"><b>Name</b></th>
            <th style="width: 100px;"><b>Dosage</b></th>
            <th style="width: 50px;"><b>Days</b></th>
          </tr>
          <tr *ngFor="let product of druglist">
            <td>{{product.medicine_name}}</td>
            <td style="text-align: center">{{product.medicine_dosage}}</td>
            <td style="text-align: center">{{product.medicine_intakedays}} - {{product.medicine_food}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <div *ngIf="spl_name == 'Diabetes'" class="col-12 col-md-12 col-lg-12 col-xl-10">
    <div #casesheetsummary id="casesheetsummary">
      <div>
        <table>
          <tr>
            <td style="width: 250px;"></td>
            <td>
              <p style="font-size: 24px;"><strong>{{hosp_name}}</strong></p>
              <p>{{hosp_addr}} {{hosp_csz}}</p>
              <p>Tel: {{hosp_tel}}</p>
            </td>
            <td style="width: 250px;"> </td>
          </tr>
        </table>
      </div>
      <div style="margin-left: 50px;">
        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        top: 16px !important;"><strong>Personal information </strong></p>
      </div>
      <div style="border: 1px solid #b5b5b6; width: 85%;margin-left: 50px;">
        <table style="margin-top: 5px;margin-left: 50px;">
          <tr>
            <td><strong>Patient Idetifier: </strong>{{patient_id}}</td>
            <td style="width: 200px;"></td>
            <td><strong>Date:</strong> {{curr_date}}</td>
          </tr>
          <tr style="background-color: #fff;">
            <td><strong>Patient Name : </strong>{{patient_name}}</td>
            <td style="width: 200px;"></td>
            <td><strong>Doctor:</strong> {{patient_name}}</td>
          </tr>
          <tr>
            <td><strong>Age: </strong>{{age}}</td>
            <td style="width: 200px;"></td>
            <td>&nbsp;</td>
          </tr>
          <tr style="background-color: #fff;">
            <td><strong>Gender: </strong>{{gender}}</td>
            <td style="width: 200px;"></td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td><strong>Occupation: </strong>{{current_occup}}</td>
          </tr>
        </table>
      </div>

      <div style="margin-left: 50px; margin-top: -20px;">
        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        top: 32px !important;"><strong>Vitals </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;">Height: {{height}}, Weight: {{weight}}, BMI: {{bmi}}, Body
          type: {{body_type}}, Body nature: {{body_nature}}</p>

        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        margin-top: -20px;
        top: 32px !important;" [hidden]="mainconc_flag"><strong>Complaints </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;" [hidden]="mainconc_flag">{{main_concerns}}</p>

        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        margin-top: -20px;
        top: 32px !important;" [hidden]="medhist_flag"><strong>Present illness: </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;" [hidden]="medhist_flag">{{med_hist}}</p>

        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        margin-top: -20px;
        top: 32px !important;" [hidden]="bp_flag"><strong>Blood pressure: </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;" [hidden]="bp_flag">Right arm: {{bp_right}}, Left arm:
          {{bp_left}}</p>

        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        margin-top: -20px;
        top: 32px !important;" [hidden]="bp_flag"><strong>Medicines: </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;" [hidden]="bp_flag">Surgery: {{surgery}} <br /> Procedure:
          {{surgery_proc}}</p>


        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        margin-top: -20px;
        top: 32px !important;" [hidden]="med_flag"><strong>Medicines: </strong></p>
        <table *ngIf="druglist.length !=0" style="border: 1px solid #b5b5b6; width: 90%;margin-top: 16px;">
          <tr style="background: #d6d8da;color:#000000">
            <th style="width: 200px;"><b>Name</b></th>
            <th style="width: 100px;"><b>Dosage</b></th>
            <th style="width: 50px;"><b>Days</b></th>
          </tr>
          <tr *ngFor="let product of druglist">
            <td>{{product.medicine_name}}</td>
            <td style="text-align: center">{{product.medicine_dosage}}</td>
            <td style="text-align: center">{{product.medicine_intakedays}} - {{product.medicine_food}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <div *ngIf="spl_name == 'ENT'" class="col-12 col-md-12 col-lg-12 col-xl-10">
    <div #casesheetsummary id="casesheetsummary">
      <div>
        <table>
          <tr>
            <td style="width: 250px;"></td>
            <td>
              <p style="font-size: 24px;"><strong>{{hosp_name}}</strong></p>
              <p>{{hosp_addr}} {{hosp_csz}}</p>
              <p>Tel: {{hosp_tel}}</p>
            </td>
            <td style="width: 250px;"> </td>
          </tr>
        </table>
      </div>
      <div style="margin-left: 50px;">
        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        top: 16px !important;"><strong>Personal information </strong></p>
      </div>
      <div style="border: 1px solid #b5b5b6; width: 85%;margin-left: 50px;">
        <table style="margin-top: 5px;margin-left: 50px;">
          <tr>
            <td><strong>Patient Idetifier: </strong>{{patient_id}}</td>
            <td style="width: 200px;"></td>
            <td><strong>Date:</strong> {{curr_date}}</td>
          </tr>
          <tr style="background-color: #fff;">
            <td><strong>Patient Name : </strong>{{patient_name}}</td>
            <td style="width: 200px;"></td>
            <td><strong>Doctor:</strong> {{patient_name}}</td>
          </tr>
          <tr>
            <td><strong>Age: </strong>{{age}}</td>
            <td style="width: 200px;"></td>
            <td>&nbsp;</td>
          </tr>
          <tr style="background-color: #fff;">
            <td><strong>Gender: </strong>{{gender}}</td>
            <td style="width: 200px;"></td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td><strong>Occupation: </strong>{{current_occup}}</td>
          </tr>
        </table>
      </div>

      <div style="margin-left: 50px; margin-top: -20px;">
        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        top: 32px !important;"><strong>Vitals </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;">Height: {{height}}, Weight: {{weight}}, BMI: {{bmi}}, Body
          type: {{body_type}}, Body nature: {{body_nature}}</p>

        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        margin-top: -20px;
        top: 32px !important;" [hidden]="mainconc_flag"><strong>Main concerns </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;" [hidden]="mainconc_flag">{{main_concerns}}</p>

        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        margin-top: -20px;
        top: 32px !important;" [hidden]="medhist_flag"><strong>Past medical history: </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;" [hidden]="medhist_flag">{{med_hist}}</p>
        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        margin-top: -20px;
        top: 32px !important;" [hidden]="medhist_flag"><strong>Past medical history: </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;" [hidden]="medhist_flag">{{med_hist}}</p>

        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        margin-top: -20px;
        top: 32px !important;" [hidden]="bp_flag"><strong>Blood pressure: </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;" [hidden]="bp_flag">Right arm: {{bp_right}}, Left arm:
          {{bp_left}}</p>

        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        margin-top: -20px;
        top: 32px !important;" [hidden]="bp_flag"><strong>Medicines: </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;" [hidden]="bp_flag">Surgery: {{surgery}} <br /> Procedure:
          {{surgery_proc}}</p>


        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        margin-top: -20px;
        top: 32px !important;" [hidden]="med_flag"><strong>Medicines: </strong></p>
        <table *ngIf="druglist.length !=0" style="border: 1px solid #b5b5b6; width: 90%;margin-top: 16px;">
          <tr style="background: #d6d8da;color:#000000">
            <th style="width: 200px;"><b>Name</b></th>
            <th style="width: 100px;"><b>Dosage</b></th>
            <th style="width: 50px;"><b>Days</b></th>
          </tr>
          <tr *ngFor="let product of druglist">
            <td>{{product.medicine_name}}</td>
            <td style="text-align: center">{{product.medicine_dosage}}</td>
            <td style="text-align: center">{{product.medicine_intakedays}} - {{product.medicine_food}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <div *ngIf="spl_name == 'General' || spl_name == 'General surgery'" class="col-12 col-md-12 col-lg-12 col-xl-10">
    <div #casesheetsummary id="casesheetsummary">
      <div>
        <table>
          <tr>
            <td style="width: 250px;"></td>
            <td>
              <p style="font-size: 24px;"><strong>{{hosp_name}}</strong></p>
              <p>{{hosp_addr}} {{hosp_csz}}</p>
              <p>Tel: {{hosp_tel}}</p>
            </td>
            <td style="width: 250px;"> </td>
          </tr>
        </table>
      </div>
      <div style="margin-left: 50px;">
        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        top: 16px !important;"><strong>Personal information </strong></p>
      </div>
      <div style="border: 1px solid #b5b5b6; width: 85%;margin-left: 50px;">
        <table style="margin-top: 5px;margin-left: 50px;">
          <tr>
            <td><strong>Patient Idetifier: </strong>{{patient_id}}</td>
            <td style="width: 200px;"></td>
            <td><strong>Date:</strong> {{curr_date}}</td>
          </tr>
          <tr style="background-color: #fff;">
            <td><strong>Patient Name : </strong>{{patient_name}}</td>
            <td style="width: 200px;"></td>
            <td><strong>Doctor:</strong> {{patient_name}}</td>
          </tr>
          <tr>
            <td><strong>Age: </strong>{{age}}</td>
            <td style="width: 200px;"></td>
            <td>&nbsp;</td>
          </tr>
          <tr style="background-color: #fff;">
            <td><strong>Gender: </strong>{{gender}}</td>
            <td style="width: 200px;"></td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td><strong>Occupation: </strong>{{current_occup}}</td>
          </tr>
        </table>
      </div>

      <div style="margin-left: 50px; margin-top: -20px;">
        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        top: 32px !important;"><strong>Vitals </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;">Height: {{height}}, Weight: {{weight}}, BMI: {{bmi}}, Body
          type: {{body_type}}, Body nature: {{body_nature}}</p>

        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        margin-top: -20px;
        top: 32px !important;" [hidden]="mainconc_flag"><strong>Main concerns </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;" [hidden]="mainconc_flag">{{main_concerns}}</p>

        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        margin-top: -20px;
        top: 32px !important;" [hidden]="medhist_flag"><strong>Past medical history: </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;" [hidden]="medhist_flag">{{med_hist}}</p>

        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        margin-top: -20px;
        top: 32px !important;" [hidden]="bp_flag"><strong>Blood pressure: </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;" [hidden]="bp_flag">Right arm: {{bp_right}}, Left arm:
          {{bp_left}}</p>

        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        margin-top: -20px;
        top: 32px !important;" [hidden]="bp_flag"><strong>Medicines: </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;" [hidden]="bp_flag">Surgery: {{surgery}} <br /> Procedure:
          {{surgery_proc}}</p>


        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        margin-top: -20px;
        top: 32px !important;" [hidden]="med_flag"><strong>Medicines: </strong></p>
        <table *ngIf="druglist.length !=0" style="border: 1px solid #b5b5b6; width: 90%;margin-top: 16px;">
          <tr style="background: #d6d8da;color:#000000">
            <th style="width: 200px;"><b>Name</b></th>
            <th style="width: 100px;"><b>Dosage</b></th>
            <th style="width: 50px;"><b>Days</b></th>
          </tr>
          <tr *ngFor="let product of druglist">
            <td>{{product.medicine_name}}</td>
            <td style="text-align: center">{{product.medicine_dosage}}</td>
            <td style="text-align: center">{{product.medicine_intakedays}} - {{product.medicine_food}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <div *ngIf="spl_name == 'Gynecology'" class="col-12 col-md-12 col-lg-12 col-xl-10">
    <div #casesheetsummary id="casesheetsummary">
      <div>
        <table>
          <tr>
            <td style="width: 250px;"></td>
            <td>
              <p style="font-size: 24px;"><strong>{{hosp_name}}</strong></p>
              <p>{{hosp_addr}} {{hosp_csz}}</p>
              <p>Tel: {{hosp_tel}}</p>
            </td>
            <td style="width: 250px;"> </td>
          </tr>
        </table>
      </div>
      <div style="margin-left: 50px;">
        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        top: 16px !important;"><strong>Personal information </strong></p>
      </div>
      <div style="border: 1px solid #b5b5b6; width: 85%;margin-left: 50px;">
        <table style="margin-top: 5px;margin-left: 50px;">
          <tr>
            <td><strong>Patient Idetifier: </strong>{{patient_id}}</td>
            <td style="width: 200px;"></td>
            <td><strong>Date:</strong> {{curr_date}}</td>
          </tr>
          <tr style="background-color: #fff;">
            <td><strong>Patient Name : </strong>{{patient_name}}</td>
            <td style="width: 200px;"></td>
            <td><strong>Doctor:</strong> {{patient_name}}</td>
          </tr>
          <tr>
            <td><strong>Age: </strong>{{age}}</td>
            <td style="width: 200px;"></td>
            <td>&nbsp;</td>
          </tr>
          <tr style="background-color: #fff;">
            <td><strong>Gender: </strong>{{gender}}</td>
            <td style="width: 200px;"></td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td><strong>Occupation: </strong>{{current_occup}}</td>
          </tr>
        </table>
      </div>

      <div style="margin-left: 50px; margin-top: -20px;">
        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        top: 32px !important;"><strong>Vitals </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;">Height: {{height}}, Weight: {{weight}}, BMI: {{bmi}}, Body
          type: {{body_type}}, Body nature: {{body_nature}}</p>

        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        margin-top: -20px;
        top: 32px !important;" [hidden]="mainconc_flag"><strong>Main concerns </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;" [hidden]="mainconc_flag">{{main_concerns}}</p>

        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        margin-top: -20px;
        top: 32px !important;" [hidden]="medhist_flag"><strong>Past medical history: </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;" [hidden]="medhist_flag">{{med_hist}}</p>

        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        margin-top: -20px;
        top: 32px !important;" [hidden]="bp_flag"><strong>Blood pressure: </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;" [hidden]="bp_flag">Right arm: {{bp_right}}, Left arm:
          {{bp_left}}</p>

        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        margin-top: -20px;
        top: 32px !important;" [hidden]="bp_flag"><strong>Medicines: </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;" [hidden]="bp_flag">Surgery: {{surgery}} <br /> Procedure:
          {{surgery_proc}}</p>


        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        margin-top: -20px;
        top: 32px !important;" [hidden]="med_flag"><strong>Medicines: </strong></p>
        <table *ngIf="druglist.length !=0" style="border: 1px solid #b5b5b6; width: 90%;margin-top: 16px;">
          <tr style="background: #d6d8da;color:#000000">
            <th style="width: 200px;"><b>Name</b></th>
            <th style="width: 100px;"><b>Dosage</b></th>
            <th style="width: 50px;"><b>Days</b></th>
          </tr>
          <tr *ngFor="let product of druglist">
            <td>{{product.medicine_name}}</td>
            <td style="text-align: center">{{product.medicine_dosage}}</td>
            <td style="text-align: center">{{product.medicine_intakedays}} - {{product.medicine_food}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <div *ngIf="others" class="col-12 col-md-12 col-lg-12 col-xl-10">
    <div #casesheetsummary id="casesheetsummary">
      <div>
        <table>
          <tr>
            <td style="width: 250px;"></td>
            <td>
              <p style="font-size: 24px;"><strong>{{hosp_name}}</strong></p>
              <p >{{hosp_addr}}   {{hosp_csz}}</p>
              <p>Tel: {{hosp_tel}}</p>
            </td>
            <td style="width: 250px;"> </td>
          </tr>
        </table>
      </div>
      <div style="margin-left: 50px;">
        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        top: 16px !important;"><strong>Personal information </strong></p>
      </div>
      <div style="border: 1px solid #b5b5b6; width: 85%;margin-left: 50px;">
        <table style="margin-top: 5px;margin-left: 50px;">
          <tr>
            <td><strong>Patient Idetifier: </strong>{{patient_id}}</td>
            <td style="width: 200px;"></td>
            <td><strong>Date:</strong> {{curr_date}}</td>
          </tr>
          <tr style="background-color: #fff;">
            <td><strong>Patient Name : </strong>{{patient_name}}</td>
            <td style="width: 200px;"></td>
            <td><strong>Doctor:</strong> {{patient_name}}</td>
          </tr>
          <tr>
            <td><strong>Age: </strong>{{age}}</td>
            <td style="width: 200px;"></td>
            <td>&nbsp;</td>
          </tr>
          <tr style="background-color: #fff;">
            <td><strong>Gender: </strong>{{gender}}</td>
            <td style="width: 200px;"></td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td><strong>Occupation: </strong>{{current_occup}}</td>
          </tr>
        </table>
      </div>
      
      <div style="margin-left: 50px; margin-top: -20px;">
        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        top: 32px !important;"><strong>Vitals </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;">Height: {{height}}, Weight: {{weight}}, BMI: {{bmi}}, Body type: {{body_type}}, Body nature: {{body_nature}}</p>

        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        margin-top: -20px;
        top: 32px !important;" [hidden]="mainconc_flag"><strong>Main concerns </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;" [hidden]="mainconc_flag">{{main_concerns}}</p>

        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        margin-top: -20px;
        top: 32px !important;" [hidden]="medhist_flag"><strong>Past medical history: </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;" [hidden]="medhist_flag">{{med_hist}}</p>

        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        margin-top: -20px;
        top: 32px !important;" [hidden]="bp_flag"><strong>Blood pressure: </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;" [hidden]="bp_flag">Right arm: {{bp_right}}, Left arm: {{bp_left}}</p>
        
        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        margin-top: -20px;
        top: 32px !important;" [hidden]="bp_flag"><strong>Medicines: </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;" [hidden]="bp_flag">Surgery: {{surgery}} <br/> Procedure: {{surgery_proc}}</p>


        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        margin-top: -20px;
        top: 32px !important;" [hidden]="med_flag"><strong>Medicines: </strong></p>
        <table *ngIf="druglist.length !=0" style="border: 1px solid #b5b5b6; width: 90%;margin-top: 16px;">
          <tr style="background: #d6d8da;color:#000000">
            <th style="width: 200px;"><b>Name</b></th>
            <th style="width: 100px;"><b>Dosage</b></th>
            <th style="width: 50px;"><b>Days</b></th>
          </tr>
          <tr *ngFor="let product of druglist">
            <td>{{product.medicine_name}}</td>
            <td style="text-align: center">{{product.medicine_dosage}}</td>
            <td style="text-align: center">{{product.medicine_intakedays}}  -  {{product.medicine_food}}</td>     
          </tr>
        </table>
      </div>
    </div>
  </div>
  <div *ngIf="spl_name == 'Gynecology'" class="col-12 col-md-12 col-lg-12 col-xl-10">
    <div #casesheetsummary id="casesheetsummary">
      <div>
        <table>
          <tr>
            <td style="width: 250px;"></td>
            <td>
              <p style="font-size: 24px;"><strong>{{hosp_name}}</strong></p>
              <p >{{hosp_addr}}   {{hosp_csz}}</p>
              <p>Tel: {{hosp_tel}}</p>
            </td>
            <td style="width: 250px;"> </td>
          </tr>
        </table>
      </div>
      <div style="margin-left: 50px;">
        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        top: 16px !important;"><strong>Personal information </strong></p>
      </div>
      <div style="border: 1px solid #b5b5b6; width: 85%;margin-left: 50px;">
        <table style="margin-top: 5px;margin-left: 50px;">
          <tr>
            <td><strong>Patient Idetifier: </strong>{{patient_id}}</td>
            <td style="width: 200px;"></td>
            <td><strong>Date:</strong> {{curr_date}}</td>
          </tr>
          <tr style="background-color: #fff;">
            <td><strong>Patient Name : </strong>{{patient_name}}</td>
            <td style="width: 200px;"></td>
            <td><strong>Doctor:</strong> {{patient_name}}</td>
          </tr>
          <tr>
            <td><strong>Age: </strong>{{age}}</td>
            <td style="width: 200px;"></td>
            <td>&nbsp;</td>
          </tr>
          <tr style="background-color: #fff;">
            <td><strong>Gender: </strong>{{gender}}</td>
            <td style="width: 200px;"></td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td><strong>Occupation: </strong>{{current_occup}}</td>
          </tr>
        </table>
      </div>
      
      <div style="margin-left: 50px; margin-top: -20px;">
        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        top: 32px !important;"><strong>Vitals </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;">Height: {{height}}, Weight: {{weight}}, BMI: {{bmi}}, Body type: {{body_type}}, Body nature: {{body_nature}}</p>

        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        margin-top: -20px;
        top: 32px !important;" [hidden]="mainconc_flag"><strong>Main concerns </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;" [hidden]="mainconc_flag">{{main_concerns}}</p>

        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        margin-top: -20px;
        top: 32px !important;" [hidden]="medhist_flag"><strong>Past medical history: </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;" [hidden]="medhist_flag">{{med_hist}}</p>

        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        margin-top: -20px;
        top: 32px !important;" [hidden]="bp_flag"><strong>Blood pressure: </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;" [hidden]="bp_flag">Right arm: {{bp_right}}, Left arm: {{bp_left}}</p>
        
        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        margin-top: -20px;
        top: 32px !important;" [hidden]="bp_flag"><strong>Medicines: </strong></p>
        <p style="border: 1px solid #b5b5b6; width: 90%;" [hidden]="bp_flag">Surgery: {{surgery}} <br/> Procedure: {{surgery_proc}}</p>


        <p style="width: 211px !important;
        padding: 1px !important;
        background: #eaeaec !important;
        display: inline-flex !important;
        position: relative !important;
        padding-left: 5px !important;
        border: 1px solid #b5b5b6;
        margin-top: -20px;
        top: 32px !important;" [hidden]="med_flag"><strong>Medicines: </strong></p>
        <table *ngIf="druglist.length !=0" style="border: 1px solid #b5b5b6; width: 90%;margin-top: 16px;">
          <tr style="background: #d6d8da;color:#000000">
            <th style="width: 200px;"><b>Name</b></th>
            <th style="width: 100px;"><b>Dosage</b></th>
            <th style="width: 50px;"><b>Days</b></th>
          </tr>
          <tr *ngFor="let product of druglist">
            <td>{{product.medicine_name}}</td>
            <td style="text-align: center">{{product.medicine_dosage}}</td>
            <td style="text-align: center">{{product.medicine_intakedays}}  -  {{product.medicine_food}}</td>     
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>