<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Sales Report</h5>
          </div>
          <div class="headerButtons">
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="cover_div" style="padding-bottom: 13px;">
          <div class="header_lable">
            Salse report
          </div>
          <div class="content_cover">
            <div class="row">
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel" style="margin-top: 7px;">Filter by<br>
                  <select disableOptionCentering class="ipcss " [(ngModel)]="reportType">
                    <option value="OTC">OTC</option>
                    <option value="Non-OTC">Non-OTC</option>
                  </select>
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                <mat-label class="matlabel" style="margin-top: 7px;">From
                  <input type="date" class="ipcss_date " (change)="dateSelect(fromDate,'1')" [(ngModel)]="fromDate"
                    max="{{currentDate}}" #matInput style="width: 140px;">
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                <mat-label class="matlabel" style="margin-top: 7px;">To
                  <input type="date" class="ipcss_date " (change)="dateSelect(toDate,'2')" [(ngModel)]="toDate"
                    max="{{currentDate}}" #matInput style="width: 140px;">
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel"><br>
                  <img src="../../../assets/ui_icons/buttons/search_button.svg" class="saveimgbtn_inpatinfo"
                    (click)="getSalesData()" />
                </mat-label>
              </div>
            </div>
          </div>
        </div>
        <p class="nodata" *ngIf="salesData.length == 0">No records found</p>
        <div class="table-responsive dig_table_overflow" style="margin-top: 10px;" [hidden]="hasdata">
          <table id="card_tbl" class="table table-hover table-dynamic"
            style="font-size: 12px; width: 95%;margin-left: 5px;">
            <thead>
              <tr>
                <th>Bill no</th>
                <th>Buyer</th>
                <th>Purchase type</th>
                <th>Bill date</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let sales of salesData">
                <td>{{sales.order_id}}</td>
                <td class="align_left">{{sales.name}}</td>
                <td>{{sales.order_type}}</td>
                <td>{{sales.order_date}}</td>
                <td style="text-align: right;">{{sales.amount}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>