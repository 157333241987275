import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { CommonDataService } from '../../providers/common-data.service';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { ClientViewService } from '../clientview/client-view.service';
import { Client_Helper } from '../Client_helper';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';
@Component({
  selector: 'app-physio-doctor-list',
  templateUrl: './physio-doctor-list.component.html',
  styleUrls: ['./physio-doctor-list.component.css']
})
export class PhysioDoctorListComponent implements OnInit {
  public physioDoctorList = [];
  public clntCountryList = [];
  public locationNameByUrl: string;
  public clntLocationList = [];
  public clntCityList = [];
  public clntStateList = [];
  public locationUrl: string;
  public cityUrl: string;
  public stateUrl: string;
  public countryUrl: string;
  public clntCountryId: string;
  public clntStateId: string;
  public clntCityId: string;
  public clntLocationId: string;
  public filtCity: string;
  public locationName: string;
  public filtCountry;
  public filtState;
  public appNursePhysio;
  public homecare;
  public sendData;
  public locationList = [];
  public locationListData = [];
  dtOptions: DataTables.Settings = {};
  public typeLable;
  constructor(public messageservice:ClientViewService,public datepipe:DatePipe,public gservice:CommonDataService,public http:Http,public routes:ActivatedRoute,public router:Router,public toastr:ToastrService) { 
    this.locationUrl = ipaddress.getIp + "usercontroller/loc";
    this.locationNameByUrl = ipaddress.getIp + "usercontroller/locbyname";
    this.cityUrl = ipaddress.getIp + "usercontroller/city";
    this.stateUrl = ipaddress.getIp + "usercontroller/state";
    this.countryUrl = ipaddress.getIp + "usercontroller/count";
  }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 25,
      "lengthMenu": [[25, 50, -1], [25, 50, "All"]],
      "order": [[2, 'asc']],
      "columnDefs": [
        { "orderable": false, "targets": [0,4,5] },
      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search by hospital name, location, or gender "
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    this.clntCountryId = this.gservice.Client_login_detail_data.Country_ID;
    this.clntStateId = this.gservice.Client_login_detail_data.State_ID;
    this.clntCityId = this.gservice.Client_login_detail_data.City_ID;
    this.clntLocationId = this.gservice.Client_login_detail_data.Location_ID;
    this.filtCity = this.gservice.Client_login_detail_data.Client_City;
    this.locationName = this.gservice.Client_login_detail_data.Client_Location;
    this.appNursePhysio= Client_Helper.getHomecare_nurse_physio();
    this.homecare = Client_Helper.getHomecare();
    this.typeLable =  this.appNursePhysio == "physio" ? "Physiotherapist"  : "Dietician";
    this.getDoctors();
    this.getCountries(0);
  }

  getCountries(flag) {
    this.clntCountryList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(this.countryUrl, { headers: headers }).subscribe(
      data => {
        this.clntCountryList = [];
        if (data.json().countries != null) {
          this.clntCountryList = data.json().countries;
          for (var c = 0; c < this.clntCountryList.length; c++) {
            if (this.clntCountryList[c].country_id == this.clntCountryId) {
              this.filtCountry = this.clntCountryList[c].description;
              this.clntCountryId = this.clntCountryList[c].country_id;
              this.getStates(this.clntCountryId, flag);
              break;
            }
          }
        }
      },error => {
        this.toastr.error(Message_data.unableToFetchData);
      });
  }

  changeCountry(clnt_country, flag) {
    for (var c = 0; c < this.clntCountryList.length; c++) {
      if (this.clntCountryList[c].description == clnt_country) {
        this.filtCountry = this.clntCountryList[c].description;
        this.clntCountryId = this.clntCountryList[c].country_id;
        this.clntStateList = [];
        this.clntCityList = [];
        this.clntLocationId = undefined;
        this.locationName = "";
        this.getStates(this.clntCountryId, flag);
      }
    }
    this.filtCountry=this.clntCountryList[0].description;
  }

  getStates(country, flag) {
    this.clntStateList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.stateUrl, JSON.stringify({ country_id: country }),
      { headers: headers }).subscribe(
        data => {
          if (data.json().states != null) {
            this.clntStateList = data.json().states;
            for (var i = 0; i < this.clntStateList.length; i++) {
              if (this.clntStateList[i].state_id == this.clntStateId) {
                this.clntStateId = this.clntStateList[i].state_id;
                this.filtState = this.clntStateList[i].description;
                this.getCities(this.clntStateId, flag);
                break;
              }
            }
          }
        });
  }

  changeState(clnt_state, flag) {
    for (var i = 0; i < this.clntStateList.length; i++) {
      if (this.clntStateList[i].description == clnt_state) {
        this.clntStateId = this.clntStateList[i].state_id;
        this.filtState = this.clntStateList[i].description;
        this.clntCityList = [];
        this.clntCityId = undefined;
        this.clntLocationId = undefined;
        this.locationName = "";
        this.getCities(this.clntStateId, flag);
      }
    }
  }

  getCities(state, flag) {
    this.clntCityList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.cityUrl, JSON.stringify({ state_id: state }),
      { headers: headers }).subscribe(
        data => {
          if (data.json().cities != null) {
            this.clntCityList = data.json().cities;
            for (var i = 0; i < this.clntCityList.length; i++) {
              if (this.clntCityList[i].district_id == this.clntCityId) {
                this.clntCityId = this.clntCityList[i].district_id;
                this.filtCity = this.clntCityList[i].description;
                break;
              } else {
                this.filtCity = this.clntCityList[0].description;
              }
            }
          }
        });
  }

  changeCity(clnt_city, flag) {
    this.clntLocationList = [];
    for (var i = 0; i < this.clntCityList.length; i++) {
      if (this.clntCityList[i].description == clnt_city) {
        this.clntCityId = this.clntCityList[i].district_id;
        this.filtCity = this.clntCityList[i].description;
        this.clntLocationId = undefined;
        this.locationName = "";
      }
    }
  }

  changeLocation(locat_desc) {
    this.clntLocationList = [];
    this.locationListData=[];
    this.locationList=[];
    if (locat_desc != null && locat_desc.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.locationNameByUrl, JSON.stringify({
        name: locat_desc,
        city_id: this.clntCityId,
      }), { headers: headers }).subscribe(
        data => {
          if (data.json().locations != null && data.json().locations.length != 0) {
            this.clntLocationList = data.json().locations;
          }
          for (var i = 0; i < this.clntLocationList.length; i++) {
            this.locationListData.push(this.clntLocationList[i].description);
          }
          this.locationList = this.locationListData.filter(function (this: any, el) {
            return el.toLowerCase().indexOf(this.locationName.toLowerCase()) > -1;
          }.bind(this));
        },
        error => {
          this.toastr.error(Message_data.unableToFetchDocDet);
        }
      )
    } else {
      this.clntLocationList = [];
    }
  }

  selectLocation(location) {
    this.locationName = location;
    for (var i = 0; i < this.clntLocationList.length; i++) {
      if (this.clntLocationList[i].location_id == location.location_id) {
        this.clntLocationId = this.clntLocationList[i].location_id;
        this.locationName = this.clntLocationList[i].description;
      }
    }
    this.clntLocationList = [];
    this.getDoctors()
  }

  getDoctors(){
    this.sendData = {
      location:this.locationName,
      city:this.filtCity,
      type:this.appNursePhysio,
      homecare:this.homecare
    }
    var name,hname,lname;
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "search/npsear", 
    JSON.stringify(this.sendData), { headers: headers }).subscribe(
      data => {   
        var obj = data.json().info;
        this.physioDoctorList=[];
        for(var i = 0; i < obj.length; i++){
          if(obj[i].middle_name != null){
            name = obj[i].first_name + " " + obj[i].middle_name + " " + obj[i].last_name;
          }
          else{
            name = obj[i].first_name + " " + obj[i].last_name;
          }
          for(var j = 0; j < obj[i].hospital.length;j++){
            hname = obj[i].hospital[j].hospital_name;
            lname = obj[i].hospital[j].location;
          }
          this.physioDoctorList.push({
            prov_id :obj[i].prov_id,
            first_name :obj[i].first_name,
            middle_name :obj[i].middle_name,
            last_name :obj[i].last_name,
            name : name,
            gender : obj[i].gender,
            experience : obj[i].experience,
            profile_image : ipaddress.Ip_with_img_address + obj[i].profile_image,
            hospital_name : hname,
            location : lname,
            hospital : obj[i] .hospital,
          })
        }
      },
      error => {
      })
  }

  selectDoctor(physiolist){
    Client_Helper.setPhysio_Doctor_List(physiolist);
    this.messageservice.sendMessage('physio_doctor_detail');
  }

  back() {
    if (Client_Helper.getHomecare_nurse_physio() == "nurse" ) {
      Client_Helper.setHomecare_nurse_physio(this.appNursePhysio);
      Client_Helper.setHomecare(this.homecare);
      this.messageservice.sendMessage("recordwallet_medpres");
    } 
    if (Client_Helper.getHomecare_nurse_physio() == "physio" || Client_Helper.getHomecare_nurse_physio() == "dietician") {
      Client_Helper.setHomecare_nurse_physio(this.appNursePhysio);
      Client_Helper.setHomecare(this.homecare);
      this.messageservice.sendMessage("physio_appcreate_new");
    }
  }
}
