<div class="Details container" id="Begin_content_others" style="padding: 0 15px 0 15px; max-width: 100%;">
    <div class="row">
        <div class="col-12 p-0">
            <mdb-card>
                <mdb-card-header class="bg-white ">
                    <div class="headerCover">
                        <div class="headerTilte app_list_header">
                            <h5 class="m-0" class="mainHeadingStyle">Appointment details</h5>
                        </div>
                        <div class="headerButtons">
                            <img src="../../../assets/ui_icons/buttons/cancel_button.svg" (click)="cancel_click()"
                                width="85px" style="margin-right: 9px;" />
                        </div>
                    </div>
                </mdb-card-header>
                <mdb-card-body>
                    <!-- spinnner element is 
                        defined with id loading -->
                    <!-- *ngIf="appointment_list.length == 0" *ngIf="loading_flag"-->
                    <div>
                        <app-loader></app-loader>
                    </div>
                    <!-- <p class="nodata" *ngIf="nodata_flag">No appointment(s) found</p> -->
                    <div class="row">
                        <div class="cover_div">
                            <div class="header_lable">
                                patient details
                            </div>
                            <div class="content_cover">
                                <div class="row">
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <mat-label class="matlabel">Name
                                            <!-- [disabled]="disable_age" -->
                                            <input type="text" class="ipcss" [(ngModel)]="clientName" readonly
                                                matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <mat-label class="matlabel">Age
                                            <!-- [disabled]="disable_age" -->
                                            <input type="text" class="ipcss" [(ngModel)]="age" readonly matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <mat-label class="matlabel">Phone number
                                            <!-- [disabled]="disable_age" -->
                                            <input type="text" class="ipcss" [(ngModel)]="mobile" readonly matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <mat-label class="matlabel">Gender
                                            <!-- [disabled]="disable_age" -->
                                            <input type="text" class="ipcss" [(ngModel)]="gender" readonly matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <mat-label class="matlabel">Relationship
                                            <!-- [disabled]="disable_age" -->
                                            <input type="text" class="ipcss" [(ngModel)]="relation" readonly matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <mat-label class="matlabel">Client address
                                            <!-- [disabled]="disable_age" -->
                                            <textarea class="ipcss" [(ngModel)]="sample"></textarea>
                                        </mat-label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="cover_div" style="margin-top: 15px;">
                            <div class="header_lable">
                                Medical & Appointment details
                            </div>
                            <div class="content_cover">
                                <div class="row">
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <mat-label class="matlabel">Blood group
                                            <input type="text" class="ipcss" [(ngModel)]="bloodGroup" readonly
                                                matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <mat-label class="matlabel">Date
                                            <!-- [disabled]="disable_age" -->
                                            <input type="text" class="ipcss" [(ngModel)]="date" readonly matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <mat-label class="matlabel">Time
                                            <!-- [disabled]="disable_age" -->
                                            <input type="text" class="ipcss" [(ngModel)]="time" readonly matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <mat-label class="matlabel">Symptom
                                            <!-- [disabled]="disable_age"  -->
                                            <input type="text" class="ipcss" [(ngModel)]="symtoms" readonly matInput />
                                        </mat-label>
                                    </div>
                                    <div *ngIf="reasonContent" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <mat-label class="matlabel">Reason
                                            <!-- [disabled]="disable_age" -->
                                            <input type="text" class="ipcss" [(ngModel)]="reason" readonly matInput />
                                        </mat-label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Modal -->
                    <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false"
                        tabindex="-1" aria-labelledby="loginmodel_header" aria-hidden="true">
                        <div class="modal-dialog" style="width: 25%;top: 20%;">
                            <div class="modal-content">
                                <div class="modal-header"
                                    style="padding:0px;background: linear-gradient(to right, #51b0e7, #6fbdec, #8acaf1, #a3d7f6, #bbe4fc);">
                                    <div style="width: 100%;color: #fff;">
                                        <h3 class="modal-title" id="loginmodel_header">Resone for cancel</h3>
                                    </div>
                                    <button type="button" class="model_close" data-bs-dismiss="modal"
                                        aria-label="Close"><img src="../../../assets/img/close_icon.png"
                                            alt="Card image cap" style="width:20px"></button>
                                </div>
                                <div class="modal-body">
                                    <div class="row" style="text-align: center;">
                                        <div class="col-12">
                                            <mat-label class="matlabel">Reason for cancelling appointment
                                                <textarea class="ipcss" [(ngModel)]="rea_txt"></textarea>
                                            </mat-label>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <img src="../../../assets/ui_icons/buttons/cancel_button.svg"
                                        (click)="cancelModel()" width="85px" style="margin-right: 9px;" />
                                    <img src="../../../assets/ui_icons/buttons/submit_button.svg"
                                        (click)="submitCancel()" width="85px" style="margin-right: 9px;" />
                                </div>
                            </div>
                        </div>
                    </div>
                </mdb-card-body>
            </mdb-card>
        </div>
    </div>
</div>