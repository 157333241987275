<div class="row">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white" style="padding:7px 20px;">
                <div class="d-flex justify-content-between align-items-center">
                    <h5 class="m-0" style="font-size: 17px;">Discharge summary</h5>
                    <div *ngIf="nodischarge_flag">
                        <img class="saveimgbtn_inpatinfo" (click)="back_Nav()" src="../../../assets/ui_icons/buttons/Back_button.svg" />
                        <img src="../../../assets/ui_icons/buttons/save_button.svg" [hidden]="confirm_btn" class="saveimgbtn_inpatinfo" (click)="saveDischargeSummary()" />
                        <img src="../../../assets/ui_icons/buttons/update_button.svg" [hidden]="!confirm_btn" class="saveimgbtn_inpatinfo" (click)="saveDischargeSummary()" />
                        <img src="../../../assets/ui_icons/buttons/write_RX_Button.svg" (click)="Write_prescription()" class="saveimgbtn_inpatinfo" />
                        <img src="../../../assets/ui_icons/buttons/view_RX_button.svg" class="saveimgbtn_inpatinfo" (click)="view_prescription()"/>
                        <button printSectionId="print" [useExistingCss]="true" ngxPrint
                                mat-icon-button>
                                <img src="../../../assets/img/printer.svg" width="25px" height="25px" />
                            </button>
                        <!-- <img class="print_img" src="../../../assets/img/printer.svg" [hidden]="!confirm_btn" class="print_btn" (click)="print_area()"> -->

                        <!-- <a (click)="goToPatientlist()"><img src="../../../assets/ui_icons/buttons/Back_button.svg"
                                class="saveimgbtn_inpatinfo" /></a>
                        <a><button printSectionId="print" [useExistingCss]="true" ngxPrint
                                mat-button>
                                <img src="../../../assets/img/printer.svg" width="17px" height="17px" />
                            </button></a> -->
                    </div>
                    <div *ngIf="!nodischarge_flag">
                        <img class="saveimgbtn_inpatinfo" (click)="back_Nav()" src="../../../assets/ui_icons/buttons/Back_button.svg" />
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>
                <div class="row">
                    <div *ngIf="!nodischarge_flag" class="nodata">
                        No Data Found
                    </div>
                    <div *ngIf="nodischarge_flag" class="Details">
                        <div class="row">
                            <div class="col-6" style="float: right;">
                                <div class="col-6">
                                    <mat-label class="matlabel"><b class="inpat_ds_b">{{hosp_name}}</b></mat-label>
                                </div>
                                <div class="col-10">
                                    <mat-label class="matlabel">{{hosp_addr}}</mat-label>
                                </div>
                                <div class="col-6">
                                    <mat-label class="matlabel">{{hosp_csz}}</mat-label>
                                </div>
                                <div class="col-6">
                                    <mat-label class="matlabel">{{hosp_tel}}</mat-label>
                                </div>
                            </div>
                        </div>
                        <div class="row" style="border: 1px solid #c5d7e6">
                            <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12">
                                <div class="row">
                                    <div class="col-sm-6 col-lg-4 col-xl-4 col-md-6">
                                        <mat-label class="matlabel">Patient Name :</mat-label>
                                    </div>
                                    <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6">
                                        <mat-label class="matlabel">{{patient_name}}</mat-label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6 col-lg-4 col-xl-4 col-md-6">
                                        <mat-label class="matlabel">Patient Identifier :
                                        </mat-label>
                                    </div>
                                    <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6">
                                        <mat-label class="matlabel">{{patient_identifier}}</mat-label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6 col-lg-4 col-xl-4 col-md-6">
                                        <mat-label class="matlabel">Patient Gender :</mat-label>
                                    </div>
                                    <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6">
                                        <mat-label class="matlabel">{{gender}}</mat-label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6 col-lg-4 col-xl-4 col-md-6">
                                        <mat-label class="matlabel">Patient Age :</mat-label>
                                    </div>
                                    <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6">
                                        <mat-label class="matlabel">{{age}}</mat-label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12">
                                <div class="row">
                                    <div class="col-sm-6 col-lg-4 col-xl-4 col-md-6">
                                        <mat-label class="matlabel">Admission Date :</mat-label>
                                    </div>
                                    <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6">
                                        <mat-label class="matlabel">{{admis_date}}</mat-label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6 col-lg-4 col-xl-4 col-md-6">
                                        <mat-label class="matlabel">Discharge Date :</mat-label>
                                    </div>
                                    <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6">
                                        <mat-label class="matlabel">{{dis_date}}</mat-label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6 col-lg-4 col-xl-4 col-md-6">
                                        <mat-label class="matlabel">Doctor Name :</mat-label>
                                    </div>
                                    <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6">
                                        <mat-label class="matlabel">{{doctor_name}}</mat-label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6 col-lg-4 col-xl-4 col-md-6">
                                        <mat-label class="matlabel">Anaesthesia :</mat-label>
                                    </div>
                                    <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6">
                                        <input type="text" class="ipcss" [(ngModel)]="anaesthetia">
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div class="row" style="padding: 16px 0px;">
                            <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                                <div class="row">
                                    <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6">
                                        <mat-label class="matlabel" style="font-family: Montserrat-SemiBold;">Complaints : </mat-label>
                                    </div>
                                    <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6">
                                        {{complaints}}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6">
                                        <mat-label class="matlabel" style="font-family: Montserrat-SemiBold;">OBH : </mat-label>
                                    </div>
                                    <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6">
                                        {{marital_status}}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6">
                                        <mat-label class="matlabel" style="font-family: Montserrat-SemiBold;">Previous Medical History :
                                        </mat-label>
                                    </div>
                                    <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6">
                                        {{medical_hist}}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6">
                                        <mat-label class="matlabel" style="font-family: Montserrat-SemiBold;">Menstrual history :
                                        </mat-label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6">
                                        <mat-label class="matlabel" style="font-family: Montserrat-SemiBold;"><b>PMC </b>{{menstrual_hist}}</mat-label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6">
                                        <mat-label class="matlabel" style="font-family: Montserrat-SemiBold;"><b>LMP </b>{{last_mens_period}}</mat-label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6">
                                        <mat-label class="matlabel" style="font-family: Montserrat-SemiBold;"><b>EDD </b>{{edd_value}}</mat-label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-12">
                                        <mat-label class="matlabel">Discharge Diagnosis
                                        </mat-label>
                                    </div>
                                    <div class="col-12">
                                        <textarea class="ipcss" maxlength="500" [(ngModel)]="dis_diag"></textarea>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <mat-label class="matlabel">Physical examination
                                        </mat-label>
                                    </div>
                                    <div class="col-12">
                                        <textarea class="ipcss" maxlength="500" [(ngModel)]="physical_exam"></textarea>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <mat-label class="matlabel">Systemic examination
                                        </mat-label>
                                    </div>
                                    <div class="col-12">
                                        <textarea class="ipcss" maxlength="500"
                                            [(ngModel)]="system_exam"></textarea>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <mat-label class="matlabel">PV examination</mat-label>
                                    </div>
                                    <div class="col-12">
                                        <textarea myInput class="ipcss" [(ngModel)]="pv_exam" maxlength="500"></textarea>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <mat-label class="matlabel">Lab investigation</mat-label>
                                    </div>
                                    <div class="col-12">
                                        <textarea myInput class="ipcss" [(ngModel)]="lab_invest" maxlength="500"></textarea>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <mat-label class="matlabel">Treatment Given</mat-label>
                                    </div>
                                    <div class="col-12">
                                        <textarea myInput class="ipcss" [(ngModel)]="treatment" maxlength="500"></textarea>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <mat-label class="matlabel">Advice At Discharge</mat-label>
                                    </div>
                                    <div class="col-12">
                                        <textarea myInput class="ipcss" [(ngModel)]="hospital_followup" maxlength="500"></textarea>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                      <mat-label class="matlabel">Next Visit</mat-label>
                                    </div>
                                    <div class="col-3 col-md-3 col-xl-3 col-lg-3">
                                      <input type="date" class="ipcss_date" id="appt_date" (change)="NextDate(next_data)"
                                        [(ngModel)]="next_data" max="{{currentDate}}" #matInput>
                                    </div>
                                  </div>
                                <div class="row" *ngIf="druglist.length !=0">
                                    <div class="col-12">
                                        <table id="tbl" cellpadding="5" cellspacing="0">
                                            <tr>
                                                <th>Medicine</th>
                                                <th>Dosage</th>
                                                <th>Days</th>
                                            </tr>
                                            <tr *ngFor="let medicine of druglist">
                                                <td>{{medicine.medicine_name}}</td>
                                                <td>{{medicine.medicine_dosage}}</td>
                                                <td>{{medicine.medicine_intakedays+" - "+medicine.medicine_food}}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>


<!-- print section -->
<div id="print" hidden>
    <div class="row">
        <div class="Details">
            <div class="row">
                <div class="col-6" style="float: right;">
                    <div class="col-6">
                        <mat-label class="matlabel"><b class="inpat_ds_b">{{hosp_name}}</b></mat-label>
                    </div>
                    <div class="col-6">
                        <mat-label class="matlabel">{{hosp_addr}}</mat-label>
                    </div>
                    <div class="col-6">
                        <mat-label class="matlabel">{{hosp_csz}}</mat-label>
                    </div>
                    <div class="col-6">
                        <mat-label class="matlabel">{{hosp_tel}}</mat-label>
                    </div>
                </div>
            </div>
            <div class="row" style="border: 1px solid #c5d7e6">
                <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12">
                    <div class="row">
                        <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6">
                            <mat-label class="matlabel">Patient Name :</mat-label>
                        </div>
                        <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6">
                            <mat-label class="matlabel">{{patient_name}}</mat-label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6">
                            <mat-label class="matlabel">Patient Identifier :
                            </mat-label>
                        </div>
                        <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6">
                            <mat-label class="matlabel">{{patient_identifier}}</mat-label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6">
                            <mat-label class="matlabel">Patient Gender :</mat-label>
                        </div>
                        <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6">
                            <mat-label class="matlabel">{{gender}}</mat-label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6">
                            <mat-label class="matlabel">Patient Age :</mat-label>
                        </div>
                        <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6">
                            <mat-label class="matlabel">{{age}}</mat-label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6">
                            <mat-label class="matlabel">Anaesthesia :</mat-label>
                        </div>
                        <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6">
                            <mat-label class="matlabel">{{anaesthetia}}</mat-label>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12">
                    <div class="row">
                        <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6">
                            <mat-label class="matlabel">Admission Date :</mat-label>
                        </div>
                        <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6">
                            <mat-label class="matlabel">{{admis_date}}</mat-label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6">
                            <mat-label class="matlabel">Discharge Date :</mat-label>
                        </div>
                        <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6">
                            <mat-label class="matlabel">{{dis_date}}</mat-label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6">
                            <mat-label class="matlabel">Doctor Name :</mat-label>
                        </div>
                        <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6">
                            <mat-label class="matlabel">{{doctor_name}}</mat-label>
                        </div>
                    </div>

                </div>

            </div>
            <div class="row">
                <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12">
                    <div class="row">
                        <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6">
                            <mat-label class="matlabel">Complaints : </mat-label>
                        </div>
                        <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6">
                            {{complaints}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6">
                            <mat-label class="matlabel">OBH : </mat-label>
                        </div>
                        <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6">
                            {{marital_status}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6">
                            <mat-label class="matlabel">Previous Medical History :
                            </mat-label>
                        </div>
                        <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6">
                            {{medical_hist}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6">
                            <mat-label class="matlabel">Menstrual history :
                            </mat-label>
                        </div>
                        <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6">
                            <mat-label class="matlabel" style="font-family: Montserrat-SemiBold;"><b>PMC </b>{{menstrual_hist}}</mat-label>
                            <mat-label class="matlabel" style="font-family: Montserrat-SemiBold;"><b>LMP </b>{{last_mens_period}}</mat-label>
                            <mat-label class="matlabel" style="font-family: Montserrat-SemiBold;"><b>EDD </b>{{edd_value}}</mat-label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12">
                            <mat-label class="matlabel">Discharge Diagnosis :
                            </mat-label>
                        </div>
                        <div class="col-12">
                            <textarea class="ipcss" maxlength="500" [(ngModel)]="dis_diag"></textarea>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <mat-label class="matlabel">Past medical history
                            </mat-label>
                        </div>
                        <div class="col-12">
                            <mat-label class="matlabel">{{med_hist}}</mat-label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <mat-label class="matlabel">Physical examination :
                            </mat-label>
                        </div>
                        <div class="col-12">
                            <mat-label class="matlabel">{{physical_exam}}</mat-label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <mat-label class="matlabel">Systemic examination :
                            </mat-label>
                        </div>
                        <div class="col-12">
                            <mat-label class="matlabel">{{system_exam}}</mat-label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <mat-label class="matlabel">PV examination :
                            </mat-label>
                        </div>
                        <div class="col-12">
                            <mat-label class="matlabel">{{pv_exam}}</mat-label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <mat-label class="matlabel">Lab investigation :
                            </mat-label>
                        </div>
                        <div class="col-12">
                            <mat-label class="matlabel">{{lab_invest}}</mat-label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <mat-label class="matlabel">Treatment given :
                            </mat-label>
                        </div>
                        <div class="col-12">
                            <mat-label class="matlabel">{{treatment}}</mat-label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <mat-label class="matlabel">Advice at discharge :
                            </mat-label>
                        </div>
                        <div class="col-12">
                            <mat-label class="matlabel">{{hospital_followup}}</mat-label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 col-md-6 col-xl-4 col-lg-4">
                            <mat-label class="matlabel">Next Visit : {{next_data}}</mat-label>
                        </div>
                    </div>
                    <div class="row">
                        <table *ngIf="druglist.length !=0">
                            <tr style="background: #277196;color:#FFFFFF">
                              <th style="width: 200px;"><b>Medicine</b></th>
                              <th style="width: 100px;"><b>Dosage</b></th>
                              <th style="width: 50px;"><b>Days</b></th>
                            </tr>
                            <tr *ngFor="let product of druglist">
                              <td>{{product.medicine_name}}</td>
                              <td style="text-align: center">{{product.medicine_dosage}}</td>
                              <td style="text-align: center">{{product.medicine_intakedays}}  -  {{product.medicine_food}}</td>     
                            </tr>
                          </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- end of print section -->
