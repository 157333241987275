<div class="row" style="margin: 0 auto;">
    <div class="Details">
      <div style="width: 252px; position: absolute;right: 27px;z-index: 9;" *ngIf="showDate">
        <div class="row">
          <div class="col-12 col-sm-6 col-md-6 col-xl-6 col-lg-6" style="margin-top: 5px;width: auto;">
            <mat-label class="matlabel" style="font-size: 15px;
            font-weight: 600;">Appointments</mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-6 col-xl-6 col-lg-6">
            <select disableOptionCentering class="ipcss widthappt" (change)="Appointment_type_change()"
              [(ngModel)]="appointment_type" style="border: 1px solid black !important;">
              <option value="Today">Today</option>
              <option value="Weekly">Weekly</option>
              <option value="Fort Night">Fort Night</option>
              <option value="Monthly">Monthly</option>
              <option value="3 Months">3 Months</option>
              <option value="6 Months">6 Months</option>
              <option value="Yearly">Yearly</option>
            </select>
          </div>
        </div>
      </div>
     
      <mat-tab-group [disableRipple]=true mat-align-tabs="start" [selectedIndex]="0"
        (selectedTabChange)="selectTab($event)">
        <mat-tab label="outpatient">
          <ng-template mat-tab-label style="margin-left:10px !important;">
            <img src="../../../assets/ui_icons/second_opinion/Pending_icon.svg" width="18px" height="auto" />
            &nbsp; {{displayType}}
          </ng-template>
          <mdb-card style="width: 99% !important;margin-left: 3px;">
            <mdb-card-body>
              <div class="row">
                <div class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
                  <mat-card id="card_tappt">
                    <mat-card-content>
                      <div class="row" style="position: relative;top: 1px;">
                        <div class="col-1">
                          <img src='../../../assets/ui_icons/total_appointments_logo.svg' width="20px" height="auto"
                            style="position: relative;left: 6px;" />
                        </div>
                        <div class="col-2">
                        </div>
  
                        <div class="col-8 my-auto">
                          <mat-label class="matlabel">{{appointment_type}} apps:
                          </mat-label>
                          <mat-label class="matlabel">&nbsp;&nbsp;<b>{{totalapp}}</b></mat-label>
                        </div>
                      </div>
  
                    </mat-card-content>
                  </mat-card>
                </div>
                <div class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
                  <mat-card id="card_pendappt">
                    <mat-card-content>
                      <div class="row" style="position: relative;top: 1px;">
                        <div class="col-1">
                          <img src='../../../assets/ui_icons/Pending_appointments_logo.svg' width="20px" height="auto"
                            style="position: relative;left: 6px;" />
                        </div>
                        <div class="col-1">
                        </div>
                        <div class="col-1">
                        </div>
                        <div class="col-8 my-auto">
                          <mat-label class="matlabel">Pending apps:</mat-label>
                          <mat-label class="matlabel">&nbsp;&nbsp;<b>{{pendingapp}}</b>
                          </mat-label>
                        </div>
                      </div>
                    </mat-card-content>
                  </mat-card>
                </div>
                <div class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
                  <mat-card id="card_newpatient">
                    <mat-card-content>
                      <div class="row" style="position: relative;top: 1px;">
                        <div class="col-1">
                          <img src='../../../assets/ui_icons/new_patients_logo.svg' width="20px" height="auto"
                            style="position: relative;left: 6px;" />
                        </div>
                        <div class="col-2">
                        </div>
                        <div class="col-8 my-auto">
                          <mat-label class="matlabel">New patients:</mat-label>
                          <mat-label class="matlabel">&nbsp;&nbsp;<b>{{newpatientapp}}</b>
                          </mat-label>
                        </div>
                      </div>
                    </mat-card-content>
                  </mat-card>
                </div>
                <div class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
                  <mat-card id="card_newpatient">
                    <mat-card-content>
                      <div class="row" style="position: relative;top: 1px;">
                        <div class="col-1">
                          <img src='../../../assets/ui_icons/new_patients_logo.svg' width="20px" height="auto"
                            style="position: relative;left: 6px;" />
                        </div>
                        <div class="col-2">
                        </div>
                        <div class="col-8 my-auto">
                          <mat-label class="matlabel">Follow patients:</mat-label>
                          <mat-label class="matlabel">&nbsp;&nbsp;<b>{{followApp}}</b>
                          </mat-label>
                        </div>
                      </div>
                    </mat-card-content>
                  </mat-card>
                </div>
              </div>
  
              <div class="row">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style="margin: 10px 0">
                  <mat-card id="cardcontent" style="border: 1px solid red;">
                    <mat-card-title style="font-size: 14px;">
                      <img src='../../../assets/ui_icons/home_page_icon/location_icon.svg' width="20px" height="auto" />
                      Location
                    </mat-card-title>
                    <mat-card-content style="background-color: #fff;">
                      <canvas id="LocationCanvas" width="361" height="175" style="display: block;"></canvas>
                    </mat-card-content>
                  </mat-card>
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style="margin: 10px 0">
                  <mat-card id="cardcontent" style="border: 1px solid rgb(127, 155, 127);">
                    <mat-card-title style="font-size: 14px;">
                      <img src='../../../assets/ui_icons/home_page_icon/age_icon.svg' width="25px" height="auto" />
                      Age
                    </mat-card-title>
                    <mat-card-content style="background-color: #fff;">
                      <canvas id="AgeCanvas" width="361" height="175" style="display: block;"></canvas>
                    </mat-card-content>
                  </mat-card>
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style="margin: 10px 0">
                  <mat-card id="cardcontent" style="border: 1px solid black;">
                    <mat-card-title style="font-size: 14px;">
                      <img src='../../../assets/ui_icons/home_page_icon/gender_icon.svg' width="25px" height="auto" />
                      Gender
                    </mat-card-title>
                    <mat-card-content style="background-color: #fff;">
                      <canvas id="piechart_gen" width="361" height="175" style="display: block;"></canvas>
                    </mat-card-content>
                  </mat-card>
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style="margin: 10px 0" [ngStyle]="{'display':spl_display}"> 
                  <mat-card id="cardcontent" style="border: 1px solid red;">
                    <mat-card-title style="font-size: 14px;">
                      <img src='../../../assets/ui_icons/home_page_icon/specialization_icon.svg' width="18px"
                        height="auto" />
                      Specialization
                    </mat-card-title>
                    <mat-card-content style="background-color: #fff;">
                      <canvas id="specialization_canvas" width="361" height="175" style="display: block;"></canvas>
                    </mat-card-content>
                  </mat-card>
                </div>
              </div>
  
            </mdb-card-body>
          </mdb-card>
        </mat-tab>
        <mat-tab label="inpatient" *ngIf="type == 'doctor'">
          <ng-template mat-tab-label (click)="selectTab('inpatient')">
            <img src="../../../assets/ui_icons/second_opinion/Completed_icon.svg" width="20px" height="auto" />
            &nbsp; Inpatient
          </ng-template>
  
          <mdb-card style="width: 99% !important;margin-left: 3px;">
            <mdb-card-body>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style="margin: 10px 0">
                  <mat-card id="cardcontent" style="border: 1px solid red;">
                    <mat-card-title style="font-size: 14px;">
                      <img src='../../../assets/ui_icons/home_page_icon/specialization_icon.svg' width="18px"
                        height="auto" />
                      Specialization
                    </mat-card-title>
                    <mat-card-content style="background-color: #fff;">
                      <div>
                        <div id="inPatientGraphCont">
                          <canvas id="InpatientSpecializationCanvas" width="361" height="175" style="display: block;"
                            (click)="inSpalData($event)"></canvas>
                        </div>
                      </div>
  
                    </mat-card-content>
                  </mat-card>
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style="margin: 10px 0">
                  <mat-card id="cardcontent" style="border: 1px solid red;">
                    <mat-card-title style="font-size: 14px;">
                      <img src='../../../assets/ui_icons/home_page_icon/ward_icon.svg' width="18px" height="auto" />
                      Ward
                    </mat-card-title>
                    <mat-card-content style="background-color: #fff;">
                      <canvas id="WardCanvas" (click)="inWardData($event)" width="361" height="175"
                        style="display: block;"></canvas>
                    </mat-card-content>
                  </mat-card>
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style="margin: 10px 0">
                  <div class="tableCover" *ngIf="!inSplFlag" style="height:100%;">
                    <div class="listHeading">
                      <div class="headingText">
                        Specialization based patient list
                      </div>
                      <div class="headingClose">
                        <img src="../../../assets/img/close_icon.png" (click)="closeList('Specialization')"
                          class="closeButton" alt="">
                      </div>
                    </div>
                    <div style="    padding: 10px 10px 10px 10px;">
                      <table style="width: 100%; " *ngIf="inSpalDataArray.length != 0" mdbTable datatable
                      [dtOptions]="dtOptions" class="table table-nowrap table-sm dataTable billlisttable">
                      <thead>
                        <tr>
                          <th>Image</th>
                          <th>Name</th>
                          <th>Bed no</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let person of inSpalDataArray; let i = index">
                          <td><img src="{{person.profile_image}}" onerror="this.src='../../../assets/img/default.jpg';"
                              height="40px" width="40px" class="img-fluid z-depth-1 rounded-circle mr-1"></td>
                          <td style="font-size: 12px;text-align: left;">{{person.name}} </td>
                          <td style="font-size: 12px;text-align: right;padding-right: 8%;">{{person.bed_no}}</td>
                        </tr>
                      </tbody>
                    </table>
                    </div>
                    
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style="margin: 10px 0">
                  <div class="tableCover" *ngIf="!inWardFlag">
                    <div class="listHeading">
                      <div class="headingText">
                        Ward based patient list
                      </div>
                      <div class="headingClose">
                        <img src="../../../assets/img/close_icon.png" (click)="closeList('ward')" class="closeButton"
                          alt="">
                      </div>
                    </div>
                      <div style="padding: 10px 10px 10px 10px;">
                        <div class="matlabel" style="display: inline-block;">
                          Total beds count : {{totalBeds}}
                        </div>
                        <div class="matlabel" style="display: inline-block; margin-left: 20px;">
                          Vacant beds : {{availableBeds}}
                        </div>
                        <table style="width: 100%; margin: 0 auto;" *ngIf="inWardDataArray.length != 0" mdbTable datatable
                          [dtOptions]="dtOptions" class="table table-nowrap table-sm dataTable billlisttable">
                          <thead>
                            <tr>
                              <th>Image</th>
                              <th>Name</th>
                              <th>Bed no</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let person of inWardDataArray; let i = index">
                              <td><img src="{{person.profile_image}}" onerror="this.src='../../../assets/img/default.jpg';"
                                  height="40px" width="40px" class="img-fluid z-depth-1 rounded-circle mr-1"></td>
                              <td style="font-size: 12px;text-align: left;">{{person.name}} </td>
                              <td style="font-size: 12px;text-align: right;padding-right: 8%;">{{person.bed_no}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                  </div>
                </div>
              </div>
            </mdb-card-body>
          </mdb-card>
        </mat-tab>
      </mat-tab-group>
  
    </div>
  </div>