<div *ngIf="generalPersonalFlag == 'min'" class="block1_grid">
    <mat-label class="matlabel" [hidden]="name_hidden">{{name_label}}<br>
        <input type="text" class="ipcss widthappt" matInput maxlength="25" required [(ngModel)]="name" />
    </mat-label>
    <mat-label class="matlabel" [hidden]="Gender_hidden">{{gender_label}}<br>
        <input class="ipcss" matInput type="text" [readonly]="isReadonly()" [(ngModel)]="gender" />
    </mat-label>
    <mat-label class="matlabel" [hidden]="age_hidden">{{age_label}}<br>
        <input class="ipcss" matInput type="text" [readonly]="agefield" [(ngModel)]="age" />
    </mat-label>
    <mat-label class="matlabel" [hidden]="currnetoccupation_hidden">{{current_label}}<br>
        <input class="ipcss widthappt" matInput type="text" maxlength="25" required [(ngModel)]="current_occupation" />
    </mat-label>
    <!-- <mat-label class="matlabel" [hidden]="height_hidden">{{height_label}}<br>
        <input class="ipcss" matInput type="text" (change)="calories_data()" maxlength="4"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57" required
            [(ngModel)]="height_txt" />
    </mat-label>
    <mat-label class="matlabel" [hidden]="height_hidden">{{height_measurelabel}}<br>
        <select class="ipcss " required (change)="calories_data()"
            [(ngModel)]="height_measure">
            <option value="cms">cms</option>
            <option value="inch">inch</option>
        </select>
    </mat-label> -->
</div>

<div *ngIf="generalPersonalFlag == 'max'">
    <div class="row">
        <div class="col-12">
            <div class="cover_div">
                <div class="header_lable">
                    Personal
                </div>
                <div class="content_cover">
                    <div class="row">
                        <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" [hidden]="name_hidden">
                            <mat-label class="matlabel" [hidden]="name_hidden">{{name_label}}<br>
                                <input type="text" class="ipcss" style="width: 100% !important;" matInput maxlength="25" required
                                    [(ngModel)]="name" [readonly]="client"/>
                            </mat-label>
                        </div> -->
                        <div [hidden]="currnetoccupation_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <mat-label class="matlabel" [hidden]="currnetoccupation_hidden">{{current_label}}<br>
                                <input class="ipcss widthappt" matInput type="text" style="width: 100% !important;" maxlength="25"  required [(ngModel)]="current_occupation" [readonly]="client" />
                            </mat-label>
                        </div>
                        <!-- <div [hidden]="age_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <mat-label class="matlabel" [hidden]="age_hidden">{{age_label}}<br>
                                <input class="ipcss" matInput type="text" style="width: 100% !important;" [readonly]="agefield"
                                    [(ngModel)]="age" />
                            </mat-label>
                        </div>
                        <div [hidden]="Gender_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <mat-label class="matlabel" [hidden]="Gender_hidden">{{gender_label}}<br>
                                <input class="ipcss" style="width: 100% !important;" matInput type="text" [readonly]="isReadonly()"
                                    [(ngModel)]="gender" />
                            </mat-label>
                        </div> -->
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" [hidden]="bnature_hidden">
                            <mat-label class="matlabel">{{bnature_label}}<br>
                                <input class="ipcss" matInput type="text" [(ngModel)]="body_nature" [readonly]="client"/>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" [hidden]="bodytype_hidden">
                            <mat-label class="matlabel">{{bodytype_label}}<br>
                                <select class="ipcss" [(ngModel)]="body_type" [disabled]="client">
                                    <option value="{{btype.bodytype_name}}" *ngFor="let btype of bodyttype_array">
                                        {{btype.bodytype_name}}</option>
                                </select>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" [hidden]="anymed_hidden">
                            <mat-label class="matlabel">{{anymed_label}}<br>
                                <select class="ipcss" [(ngModel)]="anymed_txt" [disabled]="client" (change)="med_change()">
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" [hidden]="med_hidden ">
                            <mat-label class="matlabel">{{med_label}}<br>
                                <input class="ipcss" matInput type="text" maxlength="100" [disabled]="med_flag" required
                                    [(ngModel)]="med_txt" [readonly]="client"/>
                            </mat-label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="cover_div">
                <div class="header_lable">
                    Stress
                </div>
                <div class="content_cover">
                    <div class="row">
                        <div [hidden]="feelstress_hidden" class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <mat-label class="matlabel" [hidden]="feelstress_hidden">{{feelstress_label}}<br>
                                <select class="ipcss" [(ngModel)]="feelstress" (change)="stress_change()"
                                    [disabled]="client" disableOptionCentering="true">
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </mat-label>
                        </div>
                        <div [hidden]="stress_hidden" class="col-12">
                            <mat-label class="matlabel" [hidden]="stress_hidden">{{stress_label}}<br>
                                <textarea class="ipcss text_area" style="width: 100% !important;" #stress1 id="stress1" maxlength="500" rows="1" [required]="!stress_flag" [(ngModel)]="stress" [disabled]="stress_flag"></textarea>
                            </mat-label>
                        </div>
                        <div [hidden]="symptom_hidden" class="col-12">
                            <mat-label class="matlabel" [hidden]="symptom_hidden">{{symptom_label}}<br>
                                <textarea class="ipcss text_area" style="width: 100% !important;" #symptom1 id="symptom1" maxlength="500" rows="1"  [(ngModel)]="symptom" [required]="!stress_flag" [disabled]="stress_flag"></textarea>
                            </mat-label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

      
        <div class="col-6">
            <div class="cover_div">
                <div class="header_lable">
                    Previous hospital details
                </div>
                <div class="content_cover">
                    <div class="row">
                        <div [hidden]="hosp_hidden" class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <mat-label class="matlabel" [hidden]="hosp_hidden">{{hosp_label}}<br>
                                <select class="ipcss" [(ngModel)]="hosp" [disabled]="client" (change)="enableHosp()" disableOptionCentering="true">
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </mat-label>
                        </div>
                        <div [hidden]="hospdet_hidden" class="col-12">
                            <mat-label class="matlabel" [hidden]="hospdet_hidden">{{hospdet_label}}<br>
                                <textarea class="ipcss text_area" #prevhosp id="prevhosp1" maxlength="500" rows="1" [(ngModel)]="hospdet" [required]="!prevhosp_flag"
                                    [disabled]="prevhosp_flag"></textarea>
                            </mat-label>
                        </div>
                        <div [hidden]="treat_hidden" class="col-12">
                            <mat-label class="matlabel" [hidden]="treat_hidden">{{treat_label}}<br>
                                <textarea class="ipcss text_area" #treatment id="treatment" maxlength="500" rows="1" [(ngModel)]="treat" [required]="!prevhosp_flag"
                                    [disabled]="prevhosp_flag"></textarea>
                            </mat-label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12">
            <div class="cover_div">
                <div class="header_lable">
                    Concerns
                </div>
                <div class="content_cover">
                    <div class="row">

                       
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xl-4">
                               <div class="row">
                                 <div class="col-12 margin_10">
                                   <mat-label class="matlabel">
                                     <b class="label_bold">Complaints</b>
                                   </mat-label>
                                 </div>
                                 <div class="col-12">
                                   <quill-editor id="editor1" [(ngModel)]="complaints" [styles]="{height: 'calc(35vh - 100px)'}"
                                     [modules]="modules" placeholder="Enter Text" (onContentChanged)="changelinemgmt($event,'main')">
                                   </quill-editor>
                                   <div>
                                     <quill-view-html hidden id="linemgmt" [content]="complaints"></quill-view-html>
                                   </div>
                                 </div>
                               </div>
                             </div>
               
                             <div class="col-md-6 col-lg-6 col-sm-12 col-xl-4">
                               <div class="row">
                                 <div class="col-12 margin_10">
                                   <mat-label class="matlabel">
                                     <b class="label_bold">Past medical history</b>
                                   </mat-label>
                                 </div>
                                 <div class="col-12">
                                   <quill-editor id="editor1" [(ngModel)]="past_medical" [styles]="{height: 'calc(35vh - 100px)'}"
                                     [modules]="modules" placeholder="Enter Text" (onContentChanged)="changelinemgmt($event,'past')">
                                   </quill-editor>
                                   <div>
                                     <quill-view-html hidden id="linemgmt" [content]="past_medical"></quill-view-html>
                                   </div>
                                 </div>
                               </div>
                             </div>
                             <div class="col-md-6 col-lg-6 col-sm-12 col-xl-4">
                               <div class="row">
                                 <div class="col-12 margin_10">
                                   <mat-label class="matlabel">
                                     <b class="label_bold">Present illness</b>
                                   </mat-label>
                                 </div>
                                 <div class="col-12">
                                   <quill-editor id="editor1" [(ngModel)]="present_illvalue" [styles]="{height: 'calc(35vh - 100px)'}"
                                     [modules]="modules" placeholder="Enter Text" (onContentChanged)="changelinemgmt($event,'present_illvalue')">
                                   </quill-editor>
                                   <div>
                                     <quill-view-html hidden id="linemgmt" [content]="present_illvalue"></quill-view-html>
                                   </div>
                                 </div>
                               </div>
                             </div>
                      
                        
                    </div>
                </div>
            </div>
        </div>

        <!-- <div [hidden]="rightarm_hidden" class="col-6">
            <div class="cover_div">
                <div class="header_lable">
                    Blood pressure
                </div>
                <div class="content_cover">
                    <div class="row">
                        <div class=" col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6" [hidden]="rightarm_hidden">
                            <mat-label class="matlabel">{{rightarm_label}}</mat-label>
                
                            <div class="row" [hidden]="rightarm_hidden">
                                <div class=" col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" [hidden]="rightarm_hidden">
                                    <input matInput class="ipcss" maxlength="3" placeholder="mm Hg" [(ngModel)]="rightarm"
                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57" [readonly]="client"/>
                                </div>
                                <div class=" col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" [hidden]="rightarm_hidden">
                                    <input matInput class="ipcss" maxlength="3" placeholder="mm Hg" [(ngModel)]="rightarm1"
                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57" [readonly]="client"/>
                                </div>
                            </div>
                        </div>
                        <div class=" col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6" [hidden]="leftarm_hidden">
                            <mat-label class="matlabel">{{leftarm_label}}<br></mat-label>
                            <div class="row" [hidden]="leftarm_hidden">
                                <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" [hidden]="leftarm_hidden">
                                    <input matInput class="ipcss" maxlength="3" placeholder="mm Hg" [(ngModel)]="leftarm"
                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57" [readonly]="client"/>
                                </div>
                                <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" [hidden]="leftarm_hidden">
                                    <input matInput class="ipcss" maxlength="3" placeholder="mm Hg" [(ngModel)]="leftarm1"
                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57" [readonly]="client"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="col-6">
            <div class="cover_div" style="padding-bottom: 32px;">
                <div class="header_lable">
                    Blood glucose
                </div>
                <div class="content_cover">
                    <div class="row">
                        <div class=" col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <mat-label class="matlabel">{{fasting_label}}<br>
                                <input matInput class="ipcss" style="width: 100%;" maxlength="3" placeholder="mg/dl"
                                    [(ngModel)]="fasting" onkeypress="return event.charCode >= 48 && event.charCode <= 57" [readonly]="client"/>
                            </mat-label>
                        </div>
                        <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <mat-label class="matlabel">{{prandial_label}}<br>
                                <input matInput class="ipcss" style="width: 100%;" maxlength="3" placeholder="mg/dl"
                                    [(ngModel)]="prandial" onkeypress="return event.charCode >= 48 && event.charCode <= 57" [readonly]="client"/>
                            </mat-label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div [hidden]="dietary_hidden" class="col-12">
            <div class="cover_div">
                <div class="header_lable">
                    Diet
                </div>
                <div class="content_cover">
                    <div class="row">
                        <div  class="col-12">
                            <mat-label class="matlabel" [hidden]="dietary_hidden">{{dietary_label}}<br>
                                <textarea class="ipcss text_area" required maxlength="250" [(ngModel)]="diet_habits" [readonly]="client"></textarea>
                            </mat-label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Fututre hist 01-02-2022 <div class=" col-12 " style="margin-top: 5px;">
            <a  style="float: right;"><img src="../../../assets/ui_icons/buttons/history.svg" class="saveimgbtn_inpatinfo" (click)="history()"/></a>
         </div> -->
    </div>
</div>