import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ServerApi } from '../../server-api';
declare var $: any;
import { encrypt_decript } from 'src/assets/js/Encription_Decription';
import { Client_Helper } from '../Client_helper';
import { ClientViewService } from '../clientview/client-view.service';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from '../../helper_class';
import { CommonDataService } from '../../providers/common-data.service';
import { ipaddress } from '../../ipaddress';
declare var Razorpay: any;
@Component({
  selector: 'app-get-package',
  templateUrl: './get-package.component.html',
  styleUrls: ['./get-package.component.scss']
})
export class GetPackageComponent implements OnInit {
  public location;
  public diagCenter;
  public userinfo;
  public imgURL;
  public centerID;
  public packageTypeArray = [];
  public packageArray = [];
  public clntLocationList:any = [];
  public packageGroupID;
  public centerArray = [];
  public getLoactionArray = [];
  public relationlistData = [];
  public relationName;
  public relationId;
  public subRelId;
  public lifeStyleId;
  public payid;
  public orderId;
  public signature;
  public clientID;
  public packageId;
  customOptions: OwlOptions = {
    stagePadding: 60,
    loop: false,
    margin: 10,
    nav: false,
    dots: false,
    navText: ["", ""],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 2
      },
      940: {
        items: 3
      },
      1324: {
        items: 4
      }
    },
    // nav: true,
  }

  customOptionsRel : OwlOptions = {
    autoplay: false,
    autoplaySpeed: 1500,
    loop: true,
    pullDrag: false,
    dots: false,
    navSpeed: 750,
    navText: ['<i class="fa fa-chevron-circle-left left" aria-hidden="true" style="font-size: 50px;color: #bfbbbb;position: absolute;left: 16px;top: 46%;z-index: 1;"></i>', '<i class="fa fa-chevron-circle-right right" aria-hidden="true"></i>'],
    responsive: {
      0: {
        items: 4
      },
      400: {
        items: 6
      },
      740: {
        items: 8
      },
      940: {
        items: 9
      }
    },
    nav: true,
  }
  constructor(public serviceAPI: ServerApi, public toastr: ToastrService, public messageservice: ClientViewService, public gservice: CommonDataService,) { }

  ngOnInit(): void {
    this.diagCenter = "0";
    this.userinfo = Helper_Class.getInfo();
    this.imgURL = ipaddress.Ip_with_img_address;
    this.clientID =  this.userinfo.client;
    this.getPackageType();
    this.getRelationlist();
  }

  async getRelationlist() {
    var obj1 = Client_Helper.getrelationlistDiet();
    this.relationlistData = [];
    if (obj1.relations != null && obj1.relations.length != 0) {
      
      if (obj1.relations != null) {
        if (this.userinfo.middle_name != undefined && encrypt_decript.Decript(this.userinfo.middle_name)) {
          this.relationName = encrypt_decript.Decript(this.userinfo.first_name) + " " + encrypt_decript.Decript(this.userinfo.middle_name) + " " + encrypt_decript.Decript(this.userinfo.last_name);
        } else {
          this.relationName = encrypt_decript.Decript(this.userinfo.first_name) + " " + encrypt_decript.Decript(this.userinfo.last_name);
        }
        if (this.userinfo.profile_image != null && this.userinfo.profile_image != undefined && this.userinfo.profile_image != '') {
          imgpath = this.userinfo.profile_image;
          img = ipaddress.Ip_with_img_address + this.userinfo.profile_image;
          this.relationId = "1";
          this.subRelId = "";
          this.lifeStyleId = this.userinfo.life_style_id;
        }
        this.relationlistData.push({
          rel_name: this.relationName,
          life_style_id: this.userinfo.life_style_id,
          rel_id: this.relationId,
          sub_rel_id: this.subRelId,
          prof_img: img,
          imgpath: imgpath,
        });
      }
      for (var f = 0; f < obj1.relations.length; f++) {
        var obj = obj1.relations[f];
        this.subRelId = null;
        if (obj.sub_rel_id != null) {
          this.subRelId = obj.sub_rel_id
        }
        
        if (obj.middle_name != null) {
          this.relationName = encrypt_decript.Decript(obj.first_name.toString()) + " " + encrypt_decript.Decript(obj.middle_name.toString()) + " " + encrypt_decript.Decript(obj.last_name.toString());
        } else {
          this.relationName = encrypt_decript.Decript(obj.first_name.toString()) + " " + encrypt_decript.Decript(obj.last_name.toString());
        }
        var img = "../../../assets/imgs/default.jpg", imgpath = "../../../assets/imgs/default.jpg";
        if (obj.profile_image !== "default") {
          imgpath = obj.profile_image;
          img = ipaddress.Ip_with_img_address + obj.profile_image;
        }
        this.relationlistData.push({
          rel_name: this.relationName,
          rel_id: obj.relation_id,
          sub_rel_id: this.subRelId,
          gender_val: encrypt_decript.Decript(obj.gender),
          prof_img: img,
          imgpath: imgpath,
          loc_id: obj.location,
          loc_name: obj.location_desc,
          city_id: obj.city,
          city_desc: obj.city_desc,
          state_id: obj.state,
          state_desc: obj.state_desc,
          country_id: obj.country,
          country_desc: obj.country_desc,
          update_flag: true,
          life_style_id: obj.life_style_id,
        });
      }
    }
    if (this.relationlistData.length == 0) {
      this.selectRelation("", "1", undefined, this.userinfo.life_style_id);
    } else {
      this.selectRelation(this.relationlistData[0].rel_name, this.relationlistData[0].rel_id, this.relationlistData[0].sub_rel_id, this.relationlistData[0].life_style_id);
    }
  }

  selectRelation(name, rel_id, sub_rel_id, life_style_id) {
      this.relationId = rel_id;
      this.subRelId = sub_rel_id;
      this.lifeStyleId = life_style_id
  }
  async getPackageType() {
    var response = await this.serviceAPI.getData('adm/ghpg').toPromise();
    if (response) {
      var obj = JSON.parse(JSON.stringify(response));
      this.packageTypeArray = obj.health_packages.map((x: any, index) => ({ ...x, isActive: index == 0 ? true : false }));
      this.getPackage(this.packageTypeArray[0]);
    }
  }
  async getPackage(array) {
    this.packageTypeArray.forEach((element: any, index) => { element.isActive = element.package_type_id == array.package_type_id ? true : false });
    this.getPackageDetails(array.package_type_id);
    this.packageGroupID = array.package_type_id;
  }
  async getPackageDetails(id) {
    var send = {
      package_type_id: id,
      centre_id: this.centerID,
    }
    var response = await this.serviceAPI.PostData('adm/ghpt', send).toPromise();
    if (response) {
      var obj = JSON.parse(JSON.stringify(response));
      this.packageArray = obj.health_packages;
    }
  }
  showSubList(test){
      $(".subMenu").css("display","none");
      $("#test"+test.test_id).css("display","block");
  }
  async keyUpLocation(e) {
    var locat_desc = e.target.value.toString();
    this.location = locat_desc;
    var send = {
      loc_name :  locat_desc,
      package_type_id : this.packageGroupID,
    }
    if (locat_desc != null && locat_desc.length > 2) { 
      var response = await this.serviceAPI.PostData('gen/locn', send).toPromise();
      if (response) {
        var obj = JSON.parse(JSON.stringify(response));
        this.clntLocationList = obj.locations;
      }
    }
  }
  async selectedLocation(location){
    var send = {
      location_id : location.location_id ,
      package_type_id : this.packageGroupID,
    }
    var response = await this.serviceAPI.PostData('adm/ghpl', send).toPromise();
    if (response) {
      var obj = JSON.parse(JSON.stringify(response));
      this.centerArray = obj.hosp_details;
      this.getLoactionArray = obj.health_packages;
      console.log("obj" + JSON.stringify(obj));
    }
  }
  changeGender(){
    this.diagCenter;
    this.centerID = this.diagCenter;
    this.packageArray = [];
    this.packageArray = this.getLoactionArray.filter((r) => { return r.hosp_id == this.diagCenter; }).map((m) => { return m; });
    console.log( "packageArray" + JSON.stringify( this.packageArray));
  }

  async bookNow(array) {
    var billamount= array.price+"00";
    this.packageId = array.health_pkg_id;
    //this.updatePaymentDetails("001","pay002","0000");
    var orderoptions = {
      amount: billamount,  // amount in the smallest currency unit
      currency: "INR",
      receipt: "consult_rcptid"
    };
    // this.rpayorderUrl = ipaddress.getIp + "rpay/oid";
    // this.paySuccessUrl = ipaddress.getIp + "rpay/conforder";
    var response = await this.serviceAPI.PostData('rpay/oid', orderoptions).toPromise();
    if (response) {
      var obj = JSON.parse(JSON.stringify(response));
      if(obj.id != undefined){
        var options = {
          description: 'Consultation fee',
          image: 'https://i.imgur.com/3g7nmJC.png',
          currency: 'INR',
          key: 'rzp_live_2MzOMLIEZm8bbG',
          order_id: obj.id,
          amount: billamount,
          name: 'gritsys',
          theme: {
            color: '#51b0e7'
          },
          "prefill":{
            "contact": encrypt_decript.Decript(this.userinfo.mobile),
            "email":  encrypt_decript.Decript(this.userinfo.email),
          }
        };
        options['handler'] = this.razorPaySuccessHandler.bind(this);
        let razorpay = new Razorpay(options);
        razorpay.open();
        razorpay.on('payment.failed', function (response){
          console.log("ERROR in payments "+response.error.description);
        });
      }
    }
  }
  
  public razorPaySuccessHandler(success) {
    this.payid = success.razorpay_payment_id;
    this.orderId = success.razorpay_order_id;
    this.signature = success.razorpay_signature;
    this.updatePaymentDetails(this.orderId,this.payid,this.signature);
  }

 async updatePaymentDetails(order,payment,signature){
  this.subRelId = this.subRelId != "" ? this.subRelId : undefined;
    var send_data={
      health_pkg_id : this.packageId,
      client_reg_id : this.clientID,
      relation_id : this.relationId,
      sub_rel_id : this.subRelId,
      app_type:"health_package",
      order_id:order,
      payment_id:payment,
      signature:signature,
    }
    console.log(" this.package" + JSON.stringify(send_data));
    var response = await this.serviceAPI.PostData("adm/spdc",send_data).toPromise();
    if (response) {
      var obj = JSON.parse(JSON.stringify(response)); 
      if( obj.key == "1" ){
          this.toastr.success("Transction completed successfully");
      }else{
        this.toastr.success("please try again later");
      }
      console.log(" this.package" + JSON.stringify(obj));
    }
  }
}
