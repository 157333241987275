import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { ClientViewService } from '../clientview/client-view.service';
import { Client_Helper } from '../Client_helper';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';
@Component({
  selector: 'app-quantity-change',
  templateUrl: './quantity-change.component.html',
  styleUrls: ['./quantity-change.component.css']
})
export class QuantityChangeComponent implements OnInit {
  public pharmacyName: string;
  public pharmacyAddr1: string;
  public pharmacyAddr2: string;
  public pharmacyLocation: string;
  public pharmacyCity: string;
  public pharmacyZipcode: string;
  public pharmacyState: string;
  public pharmacyCountry: string;
  public pharmacyTelephone: string;
  public pharmaId: string;
  public sendData;
  public drugIdsList = [];
  public medicineList;
  public taxList;
  public toastMessage: string;
  public orderType: string = "None";
  public validity: string;
  public workTime: string;
  public prevflow: boolean;
  public relId: string;
  public relName: string;
  public subRelId: string;
  public PresDrugId: any;
  public locationList = [];
  public locationListData;
  public locationName: string = "";
  public locationMainList;
  public cityUrl: string;
  public stateUrl: string;
  public countryUrl: string;
  public cityList;
  public stateList;
  public countryList;
  public filtCity;
  public filtState;
  public filtCountry;
  public filtCityDesc;
  public filtStateDesc;
  public zipcode: string;
  public fromTime;
  public toTime;
  public isPrefTime: boolean = false;
  public clntDetailedUrl: string;
  public firstName: string;
  public middleName: string;
  public lastName: string;
  public address1: string;
  public address2: string;
  public mobile: string;
  public saveOrderUrl: string;
  public docUrl;
  public purchaseTypeId;
  public sendAddressData;
  public sendOrderData;
  public orderDisabledFlag: boolean;
  public isHomeDelFlag: boolean = false;
  public get1;
  public drugList;
  public flow;
  public timeGrp;
  dtOptions: DataTables.Settings = {};

  constructor(public clientservice: ClientViewService, public gservice: CommonDataService, public router: Router, public http: Http, public toastr: ToastrService) {
    this.drugList = [];   
    this.cityUrl = ipaddress.getIp + "gen/city";
    this.stateUrl = ipaddress.getIp + "gen/state";
    this.countryUrl = ipaddress.getIp + "gen/count";
    this.saveOrderUrl = ipaddress.getIp + "pharmacontrol/savepharsorder";
    this.clntDetailedUrl = ipaddress.getIp + "usercontroller/clientdet/";
    this.orderType = "None";
  }

  ngOnInit() {
    this.dtOptions = {
      pageLength: 5,
      "lengthMenu": [ [5, 10, 25, 50, -1], [5, 10, 25, 50, "All"] ],
      "columnDefs": [
        { "orderable": false, "targets": [0,1,2,3,4,5] },
      ],
      "language": {
      "search": "Filter"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12">><"table-responsive"t><"row mt-1 d-flex"<"col-xs-12 col-sm-6 col-md-4"l><"col-xs-12 col-sm-12 col-md-4 text-center sm-order-last"i><"col-xs-12 col-sm-6 col-md-4"p>>'
    };
    if(Client_Helper.getstorepickup() != undefined){
      this.orderType = Client_Helper.getstorepickup().del_type;
      this.validity = Client_Helper.getstorepickup().validity;
      this.workTime = Client_Helper.getstorepickup().work_time;
      this.medicineList = Client_Helper.getstorepickup().tablet_datas;
      this.relId = Client_Helper.getstorepickup().rel_id;
      this.relName = Client_Helper.getstorepickup().rel_name;
      this.subRelId = Client_Helper.getstorepickup().sub_rel_id;
      this.docUrl = Client_Helper.getstorepickup().doc_url;
      this.flow = Client_Helper.getstorepickup().flow;
    }
    if (Client_Helper.getstorepickup().pharmacyflow == "pharmacylist") {
      this.orderDisabledFlag = false;
    }
    if (Client_Helper.gethomedelivery() != null) {
      this.get1 = Client_Helper.gethomedelivery();
      this.pharmaId = Client_Helper.gethomedelivery().pharma_id
      this.pharmacyName = this.get1.pharmacy_name;
      this.prevflow = this.orderType == "None" ? false : true;
      this.pharmacyAddr1 = this.get1.phar_addr1;
      this.pharmacyAddr2 = this.get1.phar_addr2;
      this.pharmacyLocation = this.get1.phar_loc;
      this.pharmacyCity = this.get1.phar_city;
      this.pharmacyZipcode = this.get1.phar_zipcode;
      this.pharmacyState = this.get1.phar_state;
      this.pharmacyCountry = this.get1.phar_cnty;
      this.pharmacyTelephone = this.get1.telephone;
      this.orderType = this.get1.del_type;
      this.validity = this.get1.validity;
      this.workTime = this.get1.work_time;
      this.medicineList = this.get1.tablet_datas;
      this.relId = this.get1.rel_id;
      this.relName = this.get1.rel_name;
      this.subRelId = this.get1.sub_rel_id;
      this.docUrl = this.get1.doc_url;
      this.orderType = Client_Helper.getstorepickup().del_type == "Home delivery" ? "Home delivery" : "None";
      this.medicineList = Client_Helper.getstorepickup().tablet_tracker;
      this.relId = Client_Helper.getstorepickup().rel_id;
      this.relName = Client_Helper.getstorepickup().rel_name;
      this.subRelId = Client_Helper.getstorepickup().sub_rel_id;
      this.PresDrugId = Client_Helper.getstorepickup().Pres_drug_id;
      for (var g = 0; g < this.medicineList.length; g++) {
        this.drugIdsList.push(
          this.medicineList[g].drug_id
        );
      }
      this.getItemPrices();
    }else if(Client_Helper.getstorepickup() != null) {
      var get2 = Client_Helper.getstorepickup();
      this.pharmaId = Client_Helper.getstorepickup().pharma_id
      this.pharmacyName = get2.pharmacy_name;
      this.pharmacyAddr1 = get2.phar_addr1;
      this.pharmacyAddr2 = get2.phar_addr2;
      this.pharmacyLocation = get2.phar_loc;
      this.pharmacyCity = get2.phar_city;
      this.pharmacyZipcode = get2.phar_zipcode;
      this.pharmacyState = get2.phar_state;
      this.pharmacyCountry = get2.phar_cnty;
      this.pharmacyTelephone = get2.telephone;
      this.validity = this.orderType == "Store pickup" ? Client_Helper.getstorepickup().validity : "None";
      this.workTime = this.orderType == "Store pickup" ? Client_Helper.getstorepickup().work_time : "";
      this.medicineList = Client_Helper.getstorepickup().tablet_datas;
      this.relId = Client_Helper.getstorepickup().rel_id;
      this.relName = Client_Helper.getstorepickup().rel_name;
      this.subRelId = Client_Helper.getstorepickup().sub_rel_id;
      this.PresDrugId = Client_Helper.getstorepickup().Pres_drug_id;
      for (var g = 0; g < this.medicineList.length; g++) {
        this.drugIdsList.push(
          this.medicineList[g].drug_id
        );
      }
      this.getItemPrices();
    }else {
      var get3 = Client_Helper.getstorepickup();
      this.pharmaId = get3.pharma_id;
      this.pharmacyName = get3.pharmacy_name;
      this.pharmacyAddr1 = get3.phar_addr1;
      this.pharmacyAddr2 = get3.phar_addr2;
      this.pharmacyLocation = get3.phar_loc;
      this.pharmacyCity = get3.phar_city
      this.pharmacyZipcode = get3.phar_zipcode;
      this.pharmacyState = get3.phar_state;
      this.pharmacyCountry = get3.phar_cnty;
      this.pharmacyTelephone = get3.telephone;
      this.orderType = get3.del_type;
      this.validity = get3.validity;
      this.workTime = get3.work_time;
      this.medicineList = get3.tablet_datas;
      this.relId = get3.rel_id;
      this.relName = get3.rel_name;
      this.subRelId = get3.sub_rel_id;
      this.PresDrugId = get3.Pres_drug_id;
    }
  }

  getItemPrices() {
    this.sendData = {
      pharma_id: this.pharmaId,
      drug_ids: this.drugIdsList
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/itemprice/", JSON.stringify(this.sendData), { headers: headers }).subscribe(
      data => {
        this.taxList = [];
        if (data.json() == null || (data.json() == null && (data.json().pharma_price == undefined || data.json().pharma_price.length == 0))) {
          this.toastr.success(Message_data.noMedDet);
        } else {
          this.drugList = [];
          for (var b = 0; b < data.json().pharma_price.length; b++) {
            var price_obj = data.json().pharma_price[b];
            var single_rate = price_obj.price / price_obj.per_unit;
            var single_total = single_rate * this.medicineList[b].drug_qty;
            var qtyreq;
            if (price_obj.drug_type_id == 1 || price_obj.drug_type_id == 2) {
              qtyreq = parseInt(this.medicineList[b].drug_qty);
            } else {
              qtyreq = parseInt("1");
            }
            this.drugList.push({
              Drug_ID: price_obj.drug_id,
              drug_name: price_obj.drug_name,
              drug_qty: Math.ceil(qtyreq),
              drug_type_name: price_obj.drug_type_name,
              per_unit: price_obj.per_unit,
              price: price_obj.price,
              single_price: single_rate,
              act_qty: this.medicineList[b].act_qty,
              price_txt: 0.00
            });
          }
          for (var c = 0; c < data.json().taxes.length; c++) {
            var tax_obj = data.json().taxes[c];
            this.taxList.push({
              tax_desc: tax_obj.tax_desc,
              tax: tax_obj.tax
            });
          }
        }
      }, error => {}
    );
  }

  next() {
    var invalid_qty = false;
    if (this.orderType == "None") {
      invalid_qty = true;
      this.toastMessage = Message_data.sltOrderType;
      this.toastr.error(this.toastMessage);
    }
    if (this.isHomeDelFlag == true) {
      if (this.firstName == undefined || this.firstName == "" || this.lastName == undefined || this.lastName == "" ||
        this.address1 == undefined || this.address1 == "" || this.mobile == undefined || this.mobile == "") {
        this.toastr.error(Message_data.mandatory);
      } else if (this.mobile != undefined && this.mobile.length != 10) {
        this.toastr.error(Message_data.validMobileNo);
      } else {
        this.purchaseTypeId = "2";
        var anytime;
        var name;
        if (this.middleName == undefined) {
          name = this.firstName + " " + this.lastName;
        } else {
          name = this.firstName + " " + this.middleName + " " + this.lastName;
        }
        this.sendAddressData = {
          name: name,
          address1: this.address1,
          address2: this.address2,
          location: this.locationName,
          city: this.filtCity,
          state: this.filtState,
          country: this.filtCountry,
          zipcode: this.zipcode,
          contact_no: this.mobile
        };
        if (this.isPrefTime == false) {
          anytime = "YES";
          this.fromTime = undefined;
          this.toTime = undefined;
        } else {
          anytime = "NO";
        }
      }
    } else if (this.isHomeDelFlag == false) {
      this.purchaseTypeId = "1";
    }
    if (this.docUrl == undefined) {
      if (this.medicineList == undefined || (this.medicineList !== undefined && this.medicineList.length == 0)) {
        invalid_qty = true;
        this.toastMessage = "No medicine(s) found";
      } else {
        for (var b = 0; b < this.medicineList.length; b++) {
          if (parseInt(this.medicineList[b].drug_qty) > parseInt(this.medicineList[b].act_qty)) {
            invalid_qty = true;
            this.toastMessage = "Cannot order more than prescribed quantity";
          }
        }
      }
    }
    if(invalid_qty == false){
      var send_druglist = [];
      for (var i = 0; i < this.medicineList.length; i++) {
        var id = this.medicineList[i].drug_id;
        send_druglist.push({
          drug_id: this.medicineList[i].drug_id,
          quantity: this.medicineList[i].drug_qty,
          amount: "0.00",
          balance_quantity: this.medicineList[i].act_qty
        });
      }
      var homecare = "0";    
      this.sendOrderData = {
        pharma_id: this.pharmaId.toString(),
        pres_drug_id: this.PresDrugId,
        purchase_type_id: this.purchaseTypeId,
        home_care: homecare,
        delivery_preferred_from_time: this.fromTime,
        delivery_preferred_to_time: this.toTime,
        delivery_any_time: anytime,
        address: this.sendAddressData,
        medicines: send_druglist,
        country: ipaddress.country_code
      };
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.saveOrderUrl, JSON.stringify(this.sendOrderData), { headers: headers }).subscribe(
        data => {
          var obj = JSON.stringify(data);
          if (data.json().key !== null && data.json().key == "1") {
            this.toastMessage = Message_data.orderPlaced;
            this.toastr.success(this.toastMessage);
            this.clientservice.sendMessage('clientpharmacycurrent')
          } else {
            this.toastMessage = Message_data.orderNotPlaced;
            this.toastr.error(this.toastMessage);
          }
        }, error => {}
      );
    }
  }

  back() {
    if( this.flow == "order"){
      Client_Helper.setstorepickup(Client_Helper.getstorepickup());
      this.clientservice.sendMessage('recordwallet_medpres');
    }else if(this.flow == "pharmacylist"){
      Client_Helper.setstorepickup(Client_Helper.getstorepickup());
      this.clientservice.sendMessage('pharmacyorderlist');
    }
  }

  getClientDetails() {
    this.subRelId = Client_Helper.getstorepickup().sub_rel_id;
    if (this.subRelId != null && this.subRelId != undefined && this.subRelId != '') {
      var subrelid = this.subRelId;
    }
    var val = {
      client_id: this.gservice.Client_login_detail_data.Client_Id,
      relationship: this.relName,
      sub_rel_id: this.subRelId,
      country: "IN"
    };
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    var val = {
      client_id: this.gservice.Client_login_detail_data.Client_Id,
      relationship: this.relName,
      sub_rel_id: this.subRelId,
      country: ipaddress.country_code
    };
    this.http.post(this.clntDetailedUrl,
      val,
      { headers: headers })
      .subscribe(
        data => {
          if (data.json().cln_rel_id != null) {
            if (data.json().middle_name != null && data.json().middle_name != undefined && data.json().middle_name != "") {
              this.middleName = encrypt_decript.Decript(data.json().middle_name.toString())
            }
            this.firstName = encrypt_decript.Decript(data.json().first_name.toString());
            this.lastName = encrypt_decript.Decript(data.json().last_name.toString());
            this.address1 = encrypt_decript.Decript(data.json().address1.toString());
            this.address2 = encrypt_decript.Decript(data.json().address2.toString());
            this.locationName = data.json().location_desc;
            this.selectLocation(this.locationName);
            this.filtCityDesc = data.json().city_desc;
            this.filtCity = data.json().city;
            this.zipcode = encrypt_decript.Decript(data.json().zipcode);
            this.mobile = encrypt_decript.Decript(data.json().mobile);
          }
        }, error => {
        }
      );
  }

  selectLocation(loc_name) {
    this.locationName = loc_name;
    this.locationList = [];
    this.getCity(this.locationName);
    this.stateList = [];
    this.cityList = [];
    this.countryList = [];
  }

  getCity(data) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.cityUrl, JSON.stringify({ loc_name: data }), { headers: headers }).subscribe(
      data => {
        if (data.json().cities != null) {
          this.cityList = data.json().cities;
          this.filtCity = this.cityList[0].city_id;
          this.filtCityDesc = this.cityList[0].city_desc;
          this.zipcode = this.cityList[0].zipcode;
          this.changeCity(this.cityList[0].city_id);
        }
      },error => {});
  }

  changeCity(data) {
    if (data != null) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.stateUrl, JSON.stringify({ city_id: data }), { headers: headers }).subscribe(
        data => {
          if (data.json().states != null) {
            this.stateList = data.json().states;
            this.filtState = this.stateList[0].state_id;
            this.filtStateDesc = this.stateList[0].state_desc;
            this.changeState(this.stateList[0].state_id);
          }
        },error => {});
    } else {
      this.stateList = [];
      this.cityList = [];
      this.countryList = [];
    }
  }

  changeState(data) {
    if (data != null) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.countryUrl, JSON.stringify({ state_id: data }), { headers: headers }).subscribe(
        data => {
          if (data.json().countries != null) {
            this.countryList = data.json().countries;
            this.filtCountry = this.countryList[0].country_desc;
          }
        },error => {});
    } else {
      this.stateList = [];
      this.countryList = [];
    }
  }

  changeLocation(e) {
    this.locationList = [];
    this.locationListData = [];
    if (this.locationName !== "" && this.locationName.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "gen/loc",
        JSON.stringify({
          loc_name: this.locationName
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            this.locationMainList = obj.locations;
            for (var i = 0; i < this.locationMainList.length; i++) {
              this.locationListData.push(this.locationMainList[i]);
            }
            this.locationList = this.locationListData.filter(function (this: any, el) {
              return el.toLowerCase().indexOf(this.loc_name.toLowerCase()) > -1;
            }.bind(this));
          },
          error => {
          }
        )
    } else {
      this.locationList = [];
      this.cityList = [];
    }
  }

  changeDecision(grp) {
    if (grp == "Anytime between 8:00 AM - 6:00 PM") {
      this.isPrefTime = false;
      this.timeGrp = grp;
    }else {
      this.isPrefTime = true;
      this.timeGrp = grp;
    }
  }

  changeCountry(data) {
    this.filtCountry = data;
  }

  changeOrderType() {
    if (this.orderType == "Home delivery") {
      this.isHomeDelFlag = true;
      this.getClientDetails();
    } else {
      this.isHomeDelFlag = false;
    }
  }

  timeNow = function (this: any, i) {
    i.value = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  }

  timeNow1 = function (this: any, i) {
    i.value = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  }
}
