import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { CommonDataService } from '../../providers/common-data.service';
import { ClientViewService } from '../clientview/client-view.service';
import { Client_Helper } from '../Client_helper';
import { ipaddress } from '../../ipaddress';

@Component({
  selector: 'app-client-nurse-appcreate-new',
  templateUrl: './client-nurse-appcreate-new.component.html',
  styleUrls: ['./client-nurse-appcreate-new.component.css']
})
export class ClientNurseAppcreateNewComponent implements OnInit {

  public medPresList = [];
  public relationListUrl;
  public presUrl;
  public sendData;
  public relationList = [];
  public relationId;
  public relationDesc;
  public reportList = [];
  public medPresListData = [];
  public docnameFlag:boolean;
  public appNursePhysio;
  public homecare;

  constructor(public messageservice:ClientViewService,public gservice:CommonDataService,private http:Http,private routes:ActivatedRoute,private router:Router,private toastr: ToastrService) { 
    this.relationListUrl = ipaddress.getIp + "record/getrel";
    this.presUrl = ipaddress.getIp+"record/medprescriptions/";
    this.homecare = Client_Helper.getHomecare();
    this.appNursePhysio = Client_Helper.getHomecare_nurse_physio();
    this.sendData = {
        client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
    }
  }

  ngOnInit(): void {
    this.getRelationList();
  }

  getRelationList() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.relationListUrl, 
      JSON.stringify(this.sendData), { headers: headers }).subscribe(
      data => {
        var reldata = data.json();
        if (reldata != null && reldata.relationships.length != 0) {
          for(var i=0;i<reldata.relationships.length;i++){
            this.relationList.push({
              rel_id:reldata.relationships[i].relation_id,
              rel_desc:reldata.relationships[i].relation,
            });
          }
          this.relationChange(this.relationList[0].rel_id,this.relationList[0].rel_desc);
        } 
      }
    )
  }

  relationChange(rel_id,rel_desc){
    this.medPresList = [];
    this.relationId = rel_id;
    this.relationDesc = rel_desc;
    this.reportList = [];
    this.getReports();
  }

  getReports(){
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "record/getreccnt/", {
      client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
      home_care: this.homecare,
      relation:this.relationDesc
    },
    { headers: headers }).subscribe(
      data => {
        var dataval = data.json();
        
        if(dataval.med_pres != "0")
          this.reportList.push("Medical prescription");

        if(dataval.diag_pres != "0")
          this.reportList.push("Diagnosis prescription");
        
        if(dataval.blood_report != "0")
          this.reportList.push("Blood report");
        
        if(dataval.urine_report != "0")
          this.reportList.push("Urine report");
        
        if(dataval.faeces_report != "0")
          this.reportList.push("Faeces report");
        
        if(dataval.scan_report != "0")
          this.reportList.push("Scan report");
        
        if(dataval.xray_report != "0")
          this.reportList.push("X-ray report");
        
        if(dataval.ultra_report != "0")
          this.reportList.push("Ultrasound report");
        
        if(dataval.diab_case_his != "0")
          this.reportList.push("Diabetic casesheet");
        
        if(dataval.cardio_case_hist != "0")
          this.reportList.push("Cardio casesheet");
        
        if(dataval.nephro_case_hist != "0")
          this.reportList.push("Nephrology casesheet");
        
        if(dataval.ped_case_hist != "0")
          this.reportList.push("Pediatric casesheet");
        
        if(dataval.gyn_case_hist != "0")
          this.reportList.push("Gynaecology casesheet");

        if(this.reportList.length != 0)
          this.medpresList();

      },error => {});
  }

  medpresList() {
    this.sendData = {};
    this.sendData.client_id = this.gservice.Client_login_detail_data.Client_Id;
    this.sendData.home_care = this.homecare;
    this.sendData.relation = this.relationDesc; 

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.presUrl, this.sendData, { headers: headers }).subscribe(
      data => {
        var dataval = data.json();

        if (dataval != null) {
          this.medPresListData = dataval.prescriptions;

          if (this.medPresListData != null) {
            for (var i = 0; i < this.medPresListData.length; i++) {
              var new_datalist = this.medPresListData[i];
              this.docnameFlag = false;
              var Docname = "";
              var res_clientname = "";
              
              if(new_datalist.first_name != undefined){
                if (new_datalist.middle_name != undefined && new_datalist.middle_name != "") {
                  Docname = new_datalist.first_name + " " + new_datalist.middle_name + " " + new_datalist.last_name;
                } else {
                  Docname = new_datalist.first_name + " " + new_datalist.last_name;
                }
                this.docnameFlag=true;
              }
              
              if (new_datalist.client_middle_name != undefined && new_datalist.client_middle_name != "") {
                res_clientname = encrypt_decript.Decript(new_datalist.client_first_name) + " " + encrypt_decript.Decript(new_datalist.client_middle_name) + " " + encrypt_decript.Decript(new_datalist.client_last_name);
              } else {
                res_clientname = encrypt_decript.Decript(new_datalist.client_first_name) + " " + encrypt_decript.Decript(new_datalist.client_last_name);
              }

              var App_date = "";
              if (new_datalist.date != null) {
                App_date = new_datalist.date.split('-')[2] + "-" + new_datalist.date.split('-')[1] + "-" + new_datalist.date.split('-')[0];
              }

              this.medPresList.push({
                doctorname: Docname,
                clientname: res_clientname,
                date: App_date,
                pres_drug_id: this.medPresListData[i].pres_drug_id,
                docname_flag: this.docnameFlag
              });
            }
          }
        }
      },error => {});
  }

  openMedpres(pres_id){
    var data = {
        pres_id: pres_id,
    }

    Client_Helper.setmedialpres(data);
    this.messageservice.sendMessage("recordwallet_medpres");
  }

  back(){
    Client_Helper.setHomecare_nurse_physio(this.appNursePhysio);
    Client_Helper.setHomecare(this.homecare);
    this.messageservice.sendMessage("physio");
  }
}
