import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute } from '@angular/router';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { ipaddress } from '../../ipaddress';
import { Client_Helper } from '../Client_helper';
import { ClientViewService } from '../clientview/client-view.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Helper_Class } from 'src/app/helper_class';

@Component({
  selector: 'app-client-vitals',
  templateUrl: './client-vitals.component.html',
  styleUrls: ['./client-vitals.component.scss']
})
export class ClientVitalsComponent implements OnInit {

  public heightMeasure;
  public weightMeasure;
  public height;
  public weight;
  public temperature;
  public lifeStyleId;
  public bmi;
  public bmr;
  public exerRun: string;
  public calories: any;
  public bodyNature: string;

  public userInfo;
  public subRelId;
  public sendClientRegId;
  public relFirstName;
  public relLastName;
  public relMiddleName;
  public relationName;
  public relationId;
  public relationlistData = [];
  public clntGender;
  public age;

  customOptions: OwlOptions = {
    //autoplay : true,
   autoplay: false,
   autoplaySpeed: 1500,
   loop: true,
   pullDrag: false,
   dots: false,
   navSpeed: 750,
   navText: ['<i class="fa fa-chevron-circle-left left" aria-hidden="true" style="font-size: 50px;color: #bfbbbb;position: absolute;left: 16px;top: 46%;z-index: 1;"></i>', '<i class="fa fa-chevron-circle-right right" aria-hidden="true"></i>'],
   responsive: {
     0: {
       items: 4
     },
     400: {
       items: 6
     },
     740: {
       items: 8
     },
     940: {
       items: 9
     }
   },
   nav: true,
  }

  constructor(public gservice:CommonDataService,public http:HttpClient,public routes:ActivatedRoute,public router:Router,public toastr:ToastrService, public clientservice:ClientViewService) { 
    this.heightMeasure = "cms";
    this.weightMeasure = "kgs";
  }

  async ngOnInit() {
    this.userInfo = Helper_Class.getInfo();
    this.clntGender = encrypt_decript.Decript(this.userInfo.gender);
    this.age = this.userInfo.age != undefined ? encrypt_decript.Decript(this.userInfo.age) : "";  
    this.sendClientRegId = {
      client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
    } 

    if(Client_Helper.getexerciseplanvalue() ==  undefined){
      if (this.gservice.Client_login_detail_data.Client_Id != undefined) {
        this.sendClientRegId = {
          client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
        }
      }
      if(Client_Helper.getrelationsubrelationslide() != undefined){
        if (Client_Helper.getrelationsubrelationslide().sub_rel_id != null && Client_Helper.getrelationsubrelationslide().sub_rel_id != ""
        && Client_Helper.getrelationsubrelationslide().sub_rel_id != undefined && Client_Helper.getrelationsubrelationslide().sub_rel_id != "0") {
          this.subRelId = Client_Helper.getrelationsubrelationslide().sub_rel_id;
        }
        if (Client_Helper.getrelationsubrelationslide().relation_id != null) {
          this.relationId = Client_Helper.getrelationsubrelationslide().relation_id
        }
        this.lifeStyleId = Client_Helper.getrelationsubrelationslide().life_style_id;
      }
    }else if(Client_Helper.getexerciseplanvalue() != undefined){
      this.bmr="";
      this.bmi = "";
      this.height="";
      this.weight="";
      this.temperature="";
      if(Client_Helper.getexerciseplanvalue().flow == "exerciseplan"){
        if (Client_Helper.getexerciseplanvalue().sub_rel_id != null && Client_Helper.getexerciseplanvalue().sub_rel_id != ""
        && Client_Helper.getexerciseplanvalue().sub_rel_id != undefined && Client_Helper.getexerciseplanvalue().sub_rel_id != "0") {
        this.subRelId = Client_Helper.getexerciseplanvalue().sub_rel_id;
      }
      if (Client_Helper.getexerciseplanvalue().relation_id != null) {
        this.relationId = Client_Helper.getexerciseplanvalue().relation_id
      }
      this.lifeStyleId = Client_Helper.getexerciseplanvalue().life_style_id;
      }
    }
    await this.getRelationlist();
  }

  getRelationlist() {
    var obj1 = Client_Helper.getrelationlistDiet();
    console.log("relation" + JSON.stringify(obj1));
    this.relationlistData = [];
    Client_Helper.setrelationsubrelationslide(undefined);
    var setData = {
      relation_id: this.relationId,
      sub_rel_id: this.subRelId,
      life_style_id:  this.userInfo.life_style_id
    }
    Client_Helper.setrelationsubrelationslide(setData);
    if (obj1.relations != null && obj1.relations.length != 0) {
      if (obj1.relations != null) {
        if(this.userInfo.middle_name != undefined && encrypt_decript.Decript(this.userInfo.middle_name)){
          this.relationName = encrypt_decript.Decript(this.userInfo.first_name) + " " + encrypt_decript.Decript(this.userInfo.middle_name) + " " + encrypt_decript.Decript(this.userInfo.last_name);
        }else{
          this.relationName = encrypt_decript.Decript(this.userInfo.first_name) + " " + encrypt_decript.Decript(this.userInfo.last_name);
        }            
        if (this.userInfo.profile_image != null && this.userInfo.profile_image != undefined && this.userInfo.profile_image != '') {
          imgpath = this.userInfo.profile_image;
          img = ipaddress.Ip_with_img_address + this.userInfo.profile_image;
          this.relationId = "1";
          this.subRelId = "";
        }
        this.relationlistData.push({
          rel_name: this.relationName,
          life_style_id: this.userInfo.life_style_id,
          rel_id: this.relationId,
          sub_rel_id: this.subRelId,
          prof_img: img,
          imgpath: imgpath,
          client_reg_id:this.userInfo.client,
        });
      }
      for (var f = 0; f < obj1.relations.length; f++) {
        var obj = obj1.relations[f];
        this.subRelId = null;
        if (obj.sub_rel_id != null) {
          this.subRelId = obj.sub_rel_id
        }
        this.relFirstName = "";
        this.relMiddleName = "";
        this.relLastName = "";

        if (obj.middle_name != null) {
          this.relFirstName = encrypt_decript.Decript(obj.first_name.toString());
          this.relMiddleName = encrypt_decript.Decript(obj.middle_name.toString());
          this.relLastName = encrypt_decript.Decript(obj.last_name.toString());
          this.relationName = encrypt_decript.Decript(obj.first_name.toString()) + " " + encrypt_decript.Decript(obj.middle_name.toString()) + " " + encrypt_decript.Decript(obj.last_name.toString());
        } else {
          this.relFirstName = encrypt_decript.Decript(obj.first_name.toString());
          this.relLastName = encrypt_decript.Decript(obj.last_name.toString());
          this.relationName = encrypt_decript.Decript(obj.first_name.toString()) + " " + encrypt_decript.Decript(obj.last_name.toString());
        }

        var img = "../../../assets/imgs/default.jpg", imgpath = "../../../assets/imgs/default.jpg";
        if (obj.profile_image !== "default") {
          imgpath = obj.profile_image;
          img = ipaddress.Ip_with_img_address + obj.profile_image;
        }

        this.relationlistData.push({
          rel_name: this.relationName,
          rel_f_name: this.relFirstName,
          rel_m_name: this.relMiddleName,
          rel_l_name: this.relLastName,
          rel_id: obj.relation_id,
          sub_rel_id: this.subRelId,
          gender_val: encrypt_decript.Decript(obj.gender),
          prof_img: img,
          imgpath: imgpath,
          loc_id: obj.location,
          loc_name: obj.location_desc,
          city_id: obj.city,
          city_desc: obj.city_desc,
          state_id: obj.state,
          state_desc: obj.state_desc,
          country_id: obj.country,
          country_desc: obj.country_desc,
          update_flag: true,
          life_style_id: obj.life_style_id,
        });
      }
    }
    this.selectedSpl("",this.relationId,this.subRelId,this.userInfo.life_style_id)
  }

  selectedSpl(name, rel_id, sub, life_style_id) {
    this.bmr = "";
    this.bmi = "";
    this.height = "";
    this.weight = "";
    this.temperature = "";
    Client_Helper.setrelationsubrelationslide(null);
    var getdata = {
      relation_id: rel_id,
      sub_rel_id: sub,
      life_style_id: life_style_id,
    }
    Client_Helper.setrelationsubrelationslide(getdata);
    this.lifeStyleId=life_style_id;
    this.vitalRetrival();
  }

  vitalRetrival(){
    var sendata={
      life_style_id:this.lifeStyleId
    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "lifestyle/gvt", (sendata), { headers: headers }).subscribe(
      data => {
        var obj = JSON.parse(JSON.stringify(data));
        if(obj != null){
          if(obj.height != null)
            this.height =obj.height;
          
          if(obj.height_measure != null)
            this.heightMeasure = obj.height_measure;
          
          if(obj.weight != null)
            this.weight =obj.weight;
          
          if(obj.weight_measure != null)
            this.weightMeasure = obj.weight_measure;
          
          if(obj.bmi != null)
            this.bmi =obj.bmi;
           
          if(obj.bmr !="NaN" || obj.bmr !="" || obj.bmr != null){
            this.bmr =obj.bmr;
          this.temperature=obj.temparature;
          }else{
            this.bmr =""
          }
        }
      },error => {});
  }

  save() {
    this.subRelId = Client_Helper.getrelationsubrelationslide().sub_rel_id;
    this.relationId = Client_Helper.getrelationsubrelationslide().relation_id;
    this.lifeStyleId = Client_Helper.getrelationsubrelationslide().life_style_id;
    var chk_read_flag = true;
    var Send_data = null;

    if (this.height == undefined) {
      chk_read_flag = false;
      this.toastr.error("Please enter  mandatory fields");
    }

    if (this.heightMeasure == undefined) {
      chk_read_flag = false;
      this.toastr.error("Please enter  mandatory fields");
    }

    if (this.weight == undefined) {
      chk_read_flag = false;
      this.toastr.error("Please enter  mandatory fields");
    }

    if (this.weightMeasure == undefined) {
      chk_read_flag = false;
      this.toastr.error("Please enter  mandatory fields");
    }

    Send_data = {
      client_reg_id: this.userInfo.client,
      relation_id: this.relationId,
      life_style_id: this.lifeStyleId,
      country: ipaddress.country_code,
      height: this.height,
      height_measure: this.heightMeasure,
      weight: this.weight,
      weight_measure: this.weightMeasure,
      temparature: this.temperature,
      bmi:this.bmi,
    };
    Send_data.sub_rel_id = (this.subRelId != undefined && this.subRelId.length != 0) ? this.subRelId : undefined;

    if (chk_read_flag == true) {
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'lifestyle/svt', (Send_data),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));
            if (obj.key != null) {
              if (obj.key == "1") {
                var datavalue=null;
                datavalue={
                  txt_bmi:this.bmi,
                  height:this.height,
                  height_measure:this.heightMeasure,
                  weight: this.weight,
                  weight_measure: this.weightMeasure,
                  temparature:this.temperature,
                  flag:"vitals"       
                }
                Client_Helper.setClntHeightMsr(datavalue);         
                this.toastr.success("Vital details saved successfully");
               // this.clientservice.sendMessage("exercise_plan");
              } else {
                this.toastr.error("Vital details not saved, please try again later");
              }
            }
          },error => {
            this.toastr.error("Something Went Wrong, Please Try Again Later");
          });
    } else {
      this.toastr.error("Please enter mandatory fields");
    }
  }

  caloriesData() {//Get bmi and bmr and calories data
    if (this.height == undefined || this.height == "" || this.weight == undefined || this.weight == "") {
      this.bmr = "";
      this.bmi = "";
    } else {
      if (this.height != undefined) {
        if (this.heightMeasure == "cms") {
          this.height = this.height.replace(/[^0-9]/g, '');

        } else {
          this.height = this.height.replace('/[^-0-9\.]/g', '');

          if (this.height.indexOf('.') !== -1) {
            if (this.height.split('.').length > 2) {
              this.height = this.height.height.slice(0, -1);
            }
          }
        }

        if (this.heightMeasure == "cms") {
          if (parseFloat(this.height) > 300) {
            this.height = this.height.slice(0, -1);
          }

        } else {
          if (parseFloat(this.height) > 100) {
            this.height = this.height.slice(0, -1);
          }
        }
      }

      if (this.weightMeasure == "kgs" && this.heightMeasure == "inch") {
        var pound = this.weight * 2.20462;
        var height_txt = parseFloat(this.height)
        var dat = pound / (height_txt * height_txt);
        var to = dat * 703;
        this.bmi = Math.round(to);

        if (this.userInfo.Gender_data == "Male") {
          var get_bmr = (10 * this.weight) + (6.25 * (this.height * 2.54)) - (5 * this.userInfo.Age_data) + 5;
          
          this.bmr = Math.round(get_bmr);

        } else if (this.userInfo.Gender_data == "Female") {
          var get_bmr = (10 * this.weight) + (6.25 * (this.height * 2.54)) - (5 * this.userInfo.Age_data) - 161;
          this.bmr = Math.round(get_bmr);
          
        }

      } else if (this.weightMeasure == "kgs" && this.heightMeasure == "cms") {
        var meter = parseFloat(this.height) / 100.00;
        var to = parseFloat(this.weight) / (meter * meter);
        this.bmi = Math.round(to);

        if (this.userInfo.Gender_data == "Male") {
          var get_bmr = (10 * this.weight) + (6.25 * this.height) - (5 * this.userInfo.Age_data) + 5;
          this.bmr = Math.round(get_bmr);
          
          } else if (this.userInfo.Gender_data == "Female") {
          var get_bmr = (10 * this.weight) + (6.25 * this.height) - (5 * this.userInfo.Age_data) - 161;
          this.bmr = Math.round(get_bmr);
          
        }

      } else if (this.weightMeasure == "lbs" && this.heightMeasure == "inch") {
        var height_txt = parseFloat(this.height);
        var dat = this.weight / (height_txt * height_txt);
        var to = dat * 703;
        this.bmi = Math.round(to);

        if (this.userInfo.Gender_data == "Male") {
          var get_bmr = (10 * (this.weight * 0.453592)) + (6.25 * (this.height * 2.54)) - (5 * this.userInfo.Age_data) + 5;
          this.bmr = Math.round(get_bmr);
          

        } else if (this.userInfo.Gender_data == "Female") {
          var get_bmr = (10 * (this.weight * 0.453592)) + (6.25 * (this.height * 2.54)) - (5 * this.userInfo.Age_data) - 161;
          this.bmr = Math.round(get_bmr);
          

        }

      } else if (this.weightMeasure == "lbs" && this.heightMeasure == "cms") {
        var height_txt = parseFloat(this.height);
        var inch = height_txt * 0.393701;
        var dat = this.weight / (inch * inch);
        var to = dat * 703;
        this.bmi = Math.round(to);
      

        if (this.userInfo.Gender_data == "Male") {
          var get_bmr = (10 * (this.weight * 0.453592)) + (6.25 * this.height) - (5 * this.userInfo.Age_data) + 5;
          this.bmr = Math.round(get_bmr);

        } else if (this.userInfo.Gender_data == "Female") {
          var get_bmr = (10 * (this.weight * 0.453592)) + (6.25 * this.height) - (5 * this.userInfo.Age_data) - 161;
          this.bmr = Math.round(get_bmr);
        }
      }
      
      var SelectedText1 = this.exerRun;
      if (SelectedText1 == "Light") {
        var get_txt = this.bmr * 1.375;
        this.calories = Math.round(get_txt);

      } else if (SelectedText1 == "Moderate") {
        var get_txt = this.bmr * 1.55;
        this.calories = Math.round(get_txt);

      } else if (SelectedText1 == "Heavy") {
        var get_txt = this.bmr * 1.725;
        this.calories = Math.round(get_txt);

      } else if (SelectedText1 == "Very Heavy") {
        var get_txt = this.bmr * 1.9;
        this.calories = Math.round(get_txt);

      } else {
        var get_txt = parseFloat(this.bmr) * 1.375;
        this.calories = Math.round(get_txt);
      }

      if (this.bmi < 18.5) {
        this.bodyNature = "Under weight";

      } else if (this.bmi >= 18.5 && this.bmi <= 24.9) {
        this.bodyNature = "Normal weight";

      } else if (this.bmi >= 25 && this.bmi <= 29.9) {
        this.bodyNature = "Over weight";

      } else {
        this.bodyNature = "Obase";
      }

    }
    //this.dentalservice.sendMessage(THIS.TXT);
  }
}
