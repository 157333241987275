<div *ngIf="habitualFlag == 'min'">
    <div class="row">
        <div class="col-12" style="border: 1px solid lightgray; margin-bottom: 3px;">
            <div class="row" style="padding: 10px;">
                <div class="col-8">
                    <mat-label class="matlabel" class="matlabel">{{alcholLabel}}</mat-label>
                </div>
                <div class="col-4">
                    <div class="onoffswitch">
                        <mat-button-toggle id="togg_alchol_id" class="togglebtn" [(ngModel)]="toggAlcholFlag"
                            ngDefaultControl [checked]="toggAlcholFlag" (change)="changeAlchol($event.source.checked)">
                            <span *ngIf="!toggAlcholFlag">No</span>
                            <span *ngIf="toggAlcholFlag">Yes</span>
                        </mat-button-toggle>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12" style="border: 1px solid lightgray; margin-bottom: 3px;">
            <div class="row" style="padding: 10px;">
                <div class="col-8">
                    <mat-label class="matlabel" class="matlabel">{{smokeLabel}}</mat-label>
                </div>
                <div class="col-4">
                    <div class="onoffswitch">
                        <mat-button-toggle id="togg_smoke_id" class="togglebtn" [(ngModel)]="toggSmokingFlag"
                            ngDefaultControl [checked]="toggSmokingFlag" (change)="changeSmoke($event.source.checked)">
                            <span *ngIf="!toggSmokingFlag">No</span>
                            <span *ngIf="toggSmokingFlag">Yes</span>
                        </mat-button-toggle>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12" style="border: 1px solid lightgray; margin-bottom: 3px;">
            <div class="row" style="padding: 10px;">
                <div class="col-8">
                    <mat-label class="matlabel">{{gutkaLabel}}</mat-label>
                </div>
                <div class="col-4">
                    <div class="onoffswitch">
                        <mat-button-toggle id="togg_gutka_id" class="togglebtn" [(ngModel)]="toggGutkaFlag"
                            ngDefaultControl [checked]="toggGutkaFlag" (change)="changeGutka($event.source.checked)">
                            <span *ngIf="!toggGutkaFlag">No</span>
                            <span *ngIf="toggGutkaFlag">Yes</span>
                        </mat-button-toggle>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12" style="border: 1px solid lightgray; margin-bottom: 3px;">
            <div class="row" style="padding: 10px;">
                <div class="col-8">
                    <mat-label class="matlabel">{{exerciseLabel}}</mat-label>
                </div>
                <div class="col-4">
                    <div class="onoffswitch">
                        <mat-button-toggle id="togg_exr_id" class="togglebtn" [(ngModel)]="toggExerciseFlag"
                            ngDefaultControl [checked]="toggExerciseFlag"
                            (change)="changeExercise($event.source.checked)">
                            <span *ngIf="!toggExerciseFlag">No</span>
                            <span *ngIf="toggExerciseFlag">Yes</span>
                        </mat-button-toggle>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- maximize///// -->
<div *ngIf="habitualFlag == 'max'">
    <div [hidden]="showAlcholFlag">
        <div class="row">
            <div class="col-3">
                <div class="row">
                    <div class="col-6">
                        <mat-label class="matlabel" class="matlabel">{{alcholLabel}}</mat-label>
                    </div>
                    <div class="col-6">
                        <div class="onoffswitch">
                            <mat-button-toggle id="togg_alchol_id" class="togglebtn" [(ngModel)]="toggAlcholFlag"
                                ngDefaultControl [checked]="toggAlcholFlag"
                                (change)="changeAlchol($event.source.checked)">
                                <span *ngIf="!toggAlcholFlag">No</span>
                                <span *ngIf="toggAlcholFlag">Yes</span>
                            </mat-button-toggle>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-9" [hidden]="alcholFlag">
                <div class="row">
                    <div class="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                        <mat-label class="matlabel">Quantity</mat-label>
                        <select class="ipcss" [(ngModel)]="alcoholQuant">
                            <option *ngFor="let quan of quantityList"> {{quan.description}}</option>
                        </select>
                    </div>
                    <div class="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                        <mat-label class="matlabel">Mixing type</mat-label>
                        <select class="ipcss" [(ngModel)]="alcoholMix">
                            <option *ngFor="let Mix of mixingList"> {{Mix.description}}</option>
                        </select>
                    </div>
                    <div class="col-5 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                        <mat-label class="matlabel">Duration</mat-label>
                        <select class="ipcss" [(ngModel)]="alcholDuration">
                            <option> &lt;1 Year </option>
                            <option> &lt;5 Years </option>
                            <option>&gt;5 Years </option>
                        </select>
                    </div>
                    <div class="col-5 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                        <mat-label class="matlabel">Frequency/day (Times)</mat-label>
                        <select class="ipcss" [(ngModel)]="alcoholFrequency">
                            <option> &lt;5 </option>
                            <option> &gt;5 to &lt;0 </option>
                            <option> 10+ </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div [hidden]="showSmokeFlag" style="margin-top: 10px;margin-bottom: 10px;">
        <div class="row">
            <div class="col-3">
                <div class="row">
                    <div class="col-6">
                        <mat-label class="matlabel" class="matlabel">{{smokeLabel}}</mat-label>
                    </div>
                    <div class="col-6">
                        <!-- <ion-toggle (ionChange)="changeAlchol()" [(ngModel)]="togg_alchol"></ion-toggle> -->
                        <div class="onoffswitch">
                            <mat-button-toggle id="togg_smoke_id" class="togglebtn" [(ngModel)]="toggSmokingFlag"
                                ngDefaultControl [checked]="toggSmokingFlag"
                                (change)="changeSmoke($event.source.checked)">
                                <span *ngIf="!toggSmokingFlag">No</span>
                                <span *ngIf="toggSmokingFlag">Yes</span>
                            </mat-button-toggle>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-9" [hidden]="smokeFlag">
                <div class="row">
                    <div class="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                        <mat-label class="matlabel">Duration</mat-label>
                        <select class="ipcss" [(ngModel)]="smokeDuration">
                            <option> &lt;1 Year </option>
                            <option> &lt;5 Years </option>
                            <option> &gt;5 Years </option>
                        </select>
                    </div>
                    <div [hidden]="smokeFlag" class="col-5 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                        <mat-label class="matlabel">Frequency/day (Times)</mat-label>
                        <select class="ipcss" [(ngModel)]="smokeFrequency">
                            <option> &lt;5 </option>
                            <option> &gt;5 to &lt;0 </option>
                            <option> 10+ </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div [hidden]="showGutkaFlag" style="margin-top: 5px;margin-bottom: 10px;">
        <div class="row">
            <div class="col-3">
                <div class="row">
                    <div class="col-6">
                        <mat-label class="matlabel">{{gutkaLabel}}</mat-label>
                    </div>
                    <div class="col-6">
                        <div class="onoffswitch">
                            <mat-button-toggle id="togg_gutka_id" class="togglebtn" [(ngModel)]="toggGutkaFlag"
                                ngDefaultControl [checked]="toggGutkaFlag"
                                (change)="changeGutka($event.source.checked)">
                                <span *ngIf="!toggGutkaFlag">No</span>
                                <span *ngIf="toggGutkaFlag">Yes</span>
                            </mat-button-toggle>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-9" [hidden]="gutkaFlag">
                <div class="row">
                    <div class="col-5 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                        <mat-label class="matlabel">Duration</mat-label>
                        <select class="ipcss" [(ngModel)]="gutkaDuration">
                            <option> &lt;1 Year </option>
                            <option> &lt;5 Years </option>
                            <option> &gt;5 Years </option>
                        </select>
                    </div>
                    <div class="col-5 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                        <mat-label class="matlabel">Frequency/day (Times)</mat-label>
                        <select class="ipcss" [(ngModel)]="gutkaFrequency">
                            <option> &lt;5 </option>
                            <option> &gt;5 to &lt;10 </option>
                            <option> 10+ </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div [hidden]="showExersieFlag" style="margin-top: 5px;margin-bottom: 10px;">
        <div class="row">
            <div class="col-3">
                <div class="row">
                    <div class="col-6">
                        <mat-label class="matlabel">{{exerciseLabel}}</mat-label>
                    </div>
                    <div class="col-6">
                        <div class="onoffswitch">
                            <mat-button-toggle id="togg_exr_id" class="togglebtn" [(ngModel)]="toggExerciseFlag"
                                ngDefaultControl [checked]="toggExerciseFlag"
                                (change)="changeExercise($event.source.checked)">
                                <span *ngIf="!toggExerciseFlag">No</span>
                                <span *ngIf="toggExerciseFlag">Yes</span>
                            </mat-button-toggle>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-9" [hidden]="exerciseFlag">
                <div class="row">
                    <div class="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                        <mat-label class="matlabel">Duration</mat-label>
                        <select class="ipcss" [(ngModel)]="exerDuration">
                            <option>15 Mins</option>
                            <option>30 Mins</option>
                            <option>45 Mins</option>
                            <option> &lt;1 Hour</option>
                        </select>
                    </div>
                    <div class="col-6 col-md-6 col-lg-3 col-xl-3">
                        <mat-label class="matlabel">Exercise routine</mat-label>
                        <select class="ipcss" [(ngModel)]="exerRoutine">
                            <option *ngFor="let routine of exerciseRoutineList">
                                {{routine.description}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div [hidden]="showFamilyFlag" style="margin-top: 5px;margin-bottom: 10px;">
        <div class="row">
            <div class="col-6">
                <mat-label class="matlabel">{{familySupLabel}}</mat-label>
            </div>
            <div class="col-6">
                <div class="onoffswitch">
                    <mat-button-toggle id="togg_family_id" class="togglebtn" [(ngModel)]="toggFamilyFlag"
                        ngDefaultControl [checked]="toggFamilyFlag"
                        (change)="changeFamilySupport($event.source.checked)">
                        <span *ngIf="!toggFamilyFlag">No</span>
                        <span *ngIf="toggFamilyFlag">Yes</span>
                    </mat-button-toggle>
                </div>
            </div>
        </div>
    </div>
</div>