import { Component, OnInit ,ElementRef,Input,Inject,ViewEncapsulation,HostListener} from '@angular/core';
import { Http, Headers } from '@angular/http';
// import { getIp, Ip_with_img_address, country_code } from '../../../assets/js/ipaddress';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Neprology_graph } from '../../../assets/js/Neprology_Graph';
import { Helper_nephro_casesheet } from '../../../assets/js/Helper_nephro_casesheet';


@Component({
  selector: 'app-nephro-graph-popup',
  templateUrl: './nephro-graph-popup.component.html',
  styleUrls: ['./nephro-graph-popup.component.scss']
})
export class NephroGraphPopupComponent implements OnInit {

  graph_id:any;
  header_text:string;

  constructor(public http:Http, public el: ElementRef,public dialogRef: MatDialogRef<NephroGraphPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {   
      dialogRef.disableClose = true;

      this.header_text = data.title;
      this.graph_id = data.graph_id;

  }

  ngOnInit(): void {
    if (Helper_nephro_casesheet.nephro_get_ret != undefined) {
      if (Helper_nephro_casesheet.nephro_get_ret.diag_reading != null) {
        for (let value of Helper_nephro_casesheet.nephro_get_ret.diag_reading) {
          var Datelist = new Array();
          var eGFR_list = new Array();
          var Serum_Creatinine_list = new Array();
          var Albuminuria_list = new Array();
          var Proteinuria_list = new Array();
          var Serum_Measure_list = new Array();
          var Albumin_Measure_List = new Array();

          Datelist.push(value.created_date);
          eGFR_list.push(value.egfr);
          Serum_Creatinine_list.push(value.serum_creatinine);
          Albuminuria_list.push(value.albumin);
          Proteinuria_list.push(value.protein);

          Neprology_graph.Neprozoomgraph(Datelist, eGFR_list, Serum_Creatinine_list, Albuminuria_list, Proteinuria_list,this.graph_id);
        }
      }
    }

  }

  ClosePopup(){
    this.dialogRef.close();
  }
}
