import { Component,OnInit} from '@angular/core';
import {Helper_Class} from '../../helper_class';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Time_Formate_Session } from '../../../assets/js/common';
import { Date_Formate} from '../../../assets/js/common';
import { Observable, observable, Subscription } from 'rxjs';
import { Physio_Helper } from '../Physio_Helper';
import { DocumentDetailsZoomComponent } from '../../Doctor_module/document-details-zoom/document-details-zoom.component';
import { PhysioCasesheetService } from '../physio-casesheet/physio-casesheet.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { UploadReportComponent } from '../../Doctor_module/upload-report/upload-report.component';
import { ipaddress } from '../../ipaddress';
import { Message_data } from '../../../assets/js/Message_data';

declare var $: any;

@Component({
  selector: 'app-physio-reports',
  templateUrl: './physio-reports.component.html',
  styleUrls: ['./physio-reports.component.scss']
})
export class PhysioReportsComponent implements OnInit {
  AgreeBox: boolean;
  lastImage: string = null;

  public pres_url: string;
  public doc_url: string;
  public send_data;
  public send_doc_data;
  public pres_data;
  public pres_ids = [];
  public doc_data;
  public doc_ids;
  public submenu_flag:boolean =false;
  public submenu_flag1:boolean =false;
  public pres_id: string;
  public image_send_data;

  public image_file_str;
  public image_file_name;

  public Image_table_list = [];

  public second_total_data;
  public second_doctor_list;
  public appointment_client_data;
  public appointment_doctor_data;
  public second_general_data;
  public second_healthmedicine_data;
  public second_family_data;
  public second_document_data;

  public image_doc_obj: any;
  public send_report_data: any;
  public appointment_data_list;

  public blood_report_list:any = [];
  public urine_report_list:any = [];
  public feaces_report_list:any = [];
  public xray_report_list:any = [];
  public scan_report_list:any = [];
  public ultrasound_report_list:any = [];

  public retr_blood_report_list:any = [];
  public retr_urine_report_list:any = [];
  public retr_feaces_report_list:any = [];
  public retr_xray_report_list:any = [];
  public retr_scan_report_list:any = [];
  public retr_ultrasound_report_list:any = [];
  public retr_prescription_list:any = [];
  public retrival_document_data;
  public retrival_imagelist:any = [];

  public header_footer_flag: boolean = false;

  public add_document_div: boolean = false;
  public extraadd_document_div: boolean = false;
  public retrival_flag_data: boolean = false;

  public second_update_data: any;
  public userinfo:any;
  public user_id:any;
  public first_name:string;
  public last_name:string;
  public hospital_logo;
  public hospital_name;
  public hospital_details;
  public profile_image:string;
  public messages;
  public msgflag:boolean=false;
 
  public notifications;
  public notifyflag:boolean=false;

  // send diagniss array
  Send_Ultra_Select_List:any = [];
  Send_Scan_Select_List:any = [];
  Send_Xray_Select_List:any = [];
  Send_Feaces_Select_List:any = [];
  Send_Urine_Select_List:any = [];
  Send_Blood_Select_List:any = [];
  ExitsUser: boolean;
  Newupload: boolean;

  btnFlag: boolean = true;
  private saveflag:boolean;
  public panelOpenState1:boolean;
  public panelOpenState2:boolean;
  public panelOpenState3:boolean;
  public panelOpenState4:boolean;
  public panelOpenState5:boolean;
  public panelOpenState6:boolean;

  public report_detail_flag:boolean = true;
  public report_detail_dig_flag:boolean = true;
  public report_detail_pres_flag:boolean = true;

  //details
  public report_pres_id;
  public report_type;
  public report_diag_id;
  public report_test_id;

  public date_txt: string;
  public diag_name: string;
  public diag_add: string;
  public diag_loc: string;
  public diag_cityzip: string;
  public diag_statecon: string;
  public diag_teleph: string;
  public client_age: string;
  public get_age: string;
  public Age_div: boolean;
  public review_sign: string;
  public review_div: boolean;
  public clent_id: string;
  public review_by: string;
  public client_another_name: string;
  public client_name: string;
  public diag_refertxt: string;
  public client_gender: string;
  public time_collect: string;
  public review_txt: string;
  public second_opinion_mintlist:any = [];
  public second_opinion_subtlist:any = [];
  public second_opinion_mintlist1:any = [];
  public temp_value;
  public dicom_doc: boolean = true;
  public report_img:any =[];
  public report_img_flag: boolean = false;

  public report_detail: string;
  public blood_urine_faeces_div: boolean;
  public xray_scan_ultra_div: boolean;

  public opn_id;
  public opn_type;
  public opn_pres_test_id;
  public opn_app_id;
  public loader: boolean;
  public clientflag: boolean;
  public docflag:boolean;
  public diagflag:boolean;
  public relation: any;

  //prescription details
  public url_prescription = ipaddress.getIp.toString()+"prescription/medpresdet";
  public prescription_pres_id;
  public data_prescription: any;

  public tablet_tracker:any = [];

  public total_name: string;
  public dr_total_name: string;
  public middlename: string;
  public date_prescription;
  public disease_name: string;
  public disease_describtion: string;
  public personaldata;
  public subscription:Subscription;

  public reportexp_flag = "min";
  ImagePath:any;

  //upload
  public filetype: string;
  public img_str: any;
  report_arr: any = [];
  public photos:any=[];
  public pdf;
  public pdfObj;
  public base64data;
  public image_file_str1;
  current_date;
  public imgsend_data;
  public documentView_flag:boolean = false;
  public documentList:any = [];
  public document;
  public report_datas:any = [];

  public documentBlood:boolean;
  public documentUrine:boolean;
  public documentFaeces:boolean;
  public documentXray:boolean;
  public documentScan:boolean;
  public documentUltrasound:boolean;

  constructor(public sanitizer:DomSanitizer,public dialog: MatDialog,private http:HttpClient,private routes:ActivatedRoute,private router:Router,public toastr:ToastrService,public messageservice1: PhysioCasesheetService) { 
    this.ExitsUser = false;
    this.Newupload = false;
    this.saveflag=false;

    this.Send_Ultra_Select_List = [];
    this.Send_Scan_Select_List = [];
    this.Send_Xray_Select_List = [];
    this.Send_Feaces_Select_List = [];
    this.Send_Urine_Select_List = [];
    this.Send_Blood_Select_List = [];
    this.doc_ids = [];
    this.Image_table_list = [];
    this.pres_data = [];
    this.doc_data = [];
  }

  ngOnInit(): void {
    this.subscription = this.messageservice1.getCasesheetMessage().subscribe(message => {
      if (message) {
          this.reportexp_flag = message;
          this.report_detail_flag = false;
      }
    });

    this.userinfo = Helper_Class.getInfo();
    this.user_id = this.userinfo.user_id;
    this.personaldata = Physio_Helper.getClient_Info()

    this.image_file_str = "assets/img/default.jpg";
    this.pres_url = ipaddress.getIp + "record/medprescriptions/";
    this.doc_url = ipaddress.getIp + "secopn/cdoc";
    var send_data:any;
    if(this.personaldata.sub_id!= undefined && (this.personaldata.sub_id.trim ==null || this.personaldata.sub_id =="" 
      || this.personaldata.sub_id.trim ==undefined || this.personaldata.sub_id =="0")){
      this.retrival_flag_data = true; 
        this.send_data = {
          client_id: this.personaldata.Client_id,
          relation: this.personaldata.relationship_name,
          home_care: "0"
        };
  
        this.send_doc_data = {
          client_reg_id: this.personaldata.Client_id,
          relation_id: this.personaldata.relationship_name,
        };
  
        this.send_report_data = {
          client_id: this.personaldata.Client_id,
          relation: this.personaldata.relationship_name,
          home_care: "0"
        }

        send_data = {
          client_reg_id:this.personaldata.Client_id,
          relation_id:this.personaldata.rel_id,
          spl_id:this.personaldata.spl_id,
        }
  
        this.retrieveUploadDocumentList(send_data);
        this.get_Blood_reports(this.send_report_data);
        this.get_Urine_reports(this.send_report_data);
        this.get_Feaces_reports(this.send_report_data);
        this.get_Scan_reports(this.send_report_data);
        this.get_Xray_reports(this.send_report_data);
        this.get_Ultrasound_reports(this.send_report_data);
       // this.set_retrival_document_data();
        if(this.appointment_data_list != undefined){
          if (this.appointment_data_list.status != null && this.appointment_data_list.status == "0") {
            this.pres_ids = [];
            this.doc_ids = [];
            this.extraadd_document_div = false;
            this.add_document_div = false;

          } else {
            this.extraadd_document_div = true;
          }
        }

    }else{
      this.retrival_flag_data = false;       
      this.send_data = {
        client_id: this.personaldata.Client_id,
        relation: this.personaldata.relationship_name,
        sub_relation_id: this.personaldata.sub_id,
        home_care: "0"
      };

      this.send_doc_data = {
        client_id: this.personaldata.Client_id,
        relation: this.personaldata.relationship_name,
        sub_relation_id: this.personaldata.sub_id,
      };

      this.send_report_data = {
        client_id: this.personaldata.Client_id,
        relation: this.personaldata.relationship_name,
        sub_relation_id: this.personaldata.sub_id,
        home_care: "0"
      }

      send_data = {
        client_reg_id:this.personaldata.Client_id,
        relation_id:this.personaldata.rel_id,
        sub_relation_id: this.personaldata.sub_id,
        spl_id:this.personaldata.spl_id,
      }

      this.pres_ids = [];
      this.doc_ids = [];
      // this.GetPrescriptions();
      this.retrieveUploadDocumentList(send_data);
      this.get_Blood_reports(this.send_report_data);
      this.get_Urine_reports(this.send_report_data);
      this.get_Feaces_reports(this.send_report_data);
      this.get_Scan_reports(this.send_report_data);
      this.get_Xray_reports(this.send_report_data);
      this.get_Ultrasound_reports(this.send_report_data);

      this.add_document_div = true;
    }
  }

  changeImge(e: any) {
    this.ImagePath = e.target.currentSrc;
  }

  public loadScript() {
    let body = <HTMLDivElement> document.body;
    let script = document.createElement('script');
    script.innerHTML = '';
    script.src = 'assets/dist/all.js';
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }

  public loadCss(){
    $("<link/>", {
      rel: "stylesheet",
      type: "text/css",
      href: "assets/dist/all.css"
    }).appendTo("mdb-card");
  }

  Add_Documents_Data() {
    
  }

  GetPrescriptions() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.pres_url, (this.send_data), { headers: headers }).subscribe(
      data => {
        var obj = JSON.parse(JSON.stringify(data));
        if (obj != null) {
          if (obj.prescriptions != null && obj.prescriptions.length != 0) {
            this.pres_data = [];
            for (var pres_int = 0; pres_int < obj.prescriptions.length; pres_int++) {
              var pres = obj.prescriptions[pres_int];
              var doc_name = "";
              if (pres.middle_name != null) {
                doc_name = pres.first_name + " " + pres.middle_name + " " + pres.last_name;
              } else {
                doc_name = pres.first_name + " " + pres.last_name;
              }
              
              var clnt_name = "";
              if (pres.client_middle_name != null) {
                clnt_name = encrypt_decript.Decript(pres.client_first_name) + " " + encrypt_decript.Decript(pres.client_middle_name) + " " + encrypt_decript.Decript(pres.client_last_name);
              } else {
                clnt_name = encrypt_decript.Decript(pres.client_first_name) + " " + encrypt_decript.Decript(pres.client_last_name);
              }
              
              var pres_date = "";
              if (pres.date != null) {
                pres_date = " on " + pres.date.split('-')[2] + "-" + pres.date.split('-')[1] + "-" + pres.date.split('-')[0];
              }
              this.pres_data.push({
                data: "Dr." + doc_name + " prescribed for " + clnt_name + pres_date,
                id: pres.pres_drug_id
              });
            }
          }
        }
      }, error => {});
  }

  getdoc(doc_id, docname) {
    var check_value = true;
    for (var h = 0; h < this.doc_ids.length; h++) {
      if (this.doc_ids[h] == doc_id) {
        check_value = false;
      } else {
        check_value = true;
      }
    }
    if (check_value == true) {
      this.doc_ids.push(doc_id + "#" + docname);
    }
    else {
      for (var i = 0; i < this.doc_ids.length; i++) {
        if (this.doc_ids[i] == doc_id + "#" + docname) {
          this.doc_ids.splice(this.doc_ids.indexOf(i), 1);
          break;
        }
      }
    }
    
  }

  view_med(test_id) {
    this.report_detail_flag = true; 
    this.report_detail_dig_flag = true; 
    this.report_detail_pres_flag = false;
 
    this.pres_id = test_id;
    this.prescriptionGetvalue();
  }

  prescriptionGetvalue() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    this.http.post(this.url_prescription, (
      { pres_id: this.pres_id }),
      { headers: headers }).subscribe(
        data => {
          var obj = JSON.parse(data["_body"]);

          this.data_prescription = obj;
          if (obj.middle_name != null && obj.middle_name != "") {
            this.total_name = obj.first_name + ' ' + obj.middle_name + ' ' + obj.last_name;
          }
          else {
            this.total_name = obj.first_name + ' ' + obj.last_name;
          }

           if (obj.cl_middle_name != null && encrypt_decript.Decript(obj.cl_middle_name) != undefined) {
            this.dr_total_name = encrypt_decript.Decript(obj.cl_first_name) + ' ' + encrypt_decript.Decript(obj.cl_last_name) + ' ' + encrypt_decript.Decript(obj.cl_middle_name);
          }
          else {
            this.dr_total_name = encrypt_decript.Decript(obj.cl_first_name) + ' ' + encrypt_decript.Decript(obj.cl_last_name);
          }


          this.date_prescription = Date_Formate(obj.pres_date);
          this.disease_name = obj.disease;
          this.disease_describtion = obj.dis_desc;

          for (var i = 0; i < obj.drug_list.length; i++) {
            var master_tablet_data = obj.drug_list[i];
            var medicene_dosage_data = "";
            if (master_tablet_data.intake_desc == "Every 6 hours") {
              medicene_dosage_data = master_tablet_data.morning + '-' + master_tablet_data.morning + '-' + master_tablet_data.morning + '-' + master_tablet_data.morning;
            }else {
              medicene_dosage_data = master_tablet_data.morning + '-' + master_tablet_data.afternoon + '-' + master_tablet_data.evening;;
            }

            var master_timeDuration = "";
            if (master_tablet_data.drug_intake == "With food") {
              master_timeDuration = master_tablet_data.drug_intake;
            }else {
              if (master_tablet_data.time_duration != null && master_tablet_data.time_duration != "0") {
                master_timeDuration = master_tablet_data.time_duration + ' mins ' + master_tablet_data.drug_intake;
              }
              else {
                master_timeDuration = master_tablet_data.drug_intake;
              }
            }
            this.tablet_tracker.push({
              medicene_name: master_tablet_data.drug_type_name + ' - ' + master_tablet_data.drug_name,
              medicene_dosage: medicene_dosage_data,
              medicene_intakedays: master_tablet_data.days + ' days ',
              medicene_food: master_timeDuration
            })
          }
        },
        error => {});
  }

  viewdoc(doc_id, name) {
    // this.report_imagepdf_detail(doc_id + "#" + name);
  }

  get_Blood_reports(get_data) {
    get_data.type = "blood";

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(
      ipaddress.getIp + "record/reportlist/", (get_data), { headers: headers }).subscribe(
        data => {
           
           var obj = JSON.parse(JSON.stringify(data));

          if (obj != null) {
            if (obj.reportlist != null && obj.reportlist.length != 0) {
              for (var i = 0; i < obj.reportlist.length; i++) {
                var client_name = "";
                var doctor_name = "";

                if (obj.reportlist[i].client_middle_name != null) {
                  client_name = encrypt_decript.Decript(obj.reportlist[i].client_first_name) + " " +
                    encrypt_decript.Decript(obj.reportlist[i].client_middle_name) + " " +
                    encrypt_decript.Decript(obj.reportlist[i].client_last_name);
                } else {
                  client_name = encrypt_decript.Decript(obj.reportlist[i].client_first_name) + " " +
                    encrypt_decript.Decript(obj.reportlist[i].client_last_name);
                }

                if (obj.middle_name != null) {
                  doctor_name = obj.reportlist[i].first_name + " " + obj.reportlist[i].middle_name + " " + obj.reportlist[i].last_name;
                } else {
                  doctor_name = obj.reportlist[i].first_name + " " + obj.reportlist[i].last_name;
                }

                this.blood_report_list.push({
                  Date: obj.reportlist[i].appointment_date,
                  Diag_Test_Id: obj.reportlist[i].diag_test_id,
                  diag_test_name:obj.reportlist[i].diag_test_name,
                  Centre_Name: obj.reportlist[i].diag_centre_name,
                  diag_appointment_id:obj.reportlist[i].diag_appointment_id,
                  Doctor_Name: doctor_name,
                  Client_Name: client_name
                });
              }
            }
          }
        },
        error => {});
  }

  get_Urine_reports(get_data) {
    get_data.type = "urine";

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    this.http.post(
      ipaddress.getIp + "record/reportlist/", (get_data), { headers: headers }).subscribe(
        data => {
          var obj = JSON.parse(JSON.stringify(data));
          if (obj != null) {
            if (obj.reportlist != null && obj.reportlist.length != 0) {
              for (var i = 0; i < obj.reportlist.length; i++) {
                var client_name = "";
                var doctor_name = "";

                if (obj.reportlist[i].client_middle_name != null) {
                  client_name = encrypt_decript.Decript(obj.reportlist[i].client_first_name) + " " +
                    encrypt_decript.Decript(obj.reportlist[i].client_middle_name) + " " +
                    encrypt_decript.Decript(obj.reportlist[i].client_last_name);
                } else {
                  client_name = encrypt_decript.Decript(obj.reportlist[i].client_first_name) + " " +
                    encrypt_decript.Decript(obj.reportlist[i].client_last_name);
                }

                if (obj.middle_name != null) {
                  doctor_name = obj.reportlist[i].first_name + " " + obj.reportlist[i].middle_name + " " + obj.reportlist[i].last_name;
                } else {
                  doctor_name = obj.reportlist[i].first_name + " " + obj.reportlist[i].last_name;
                }

                this.urine_report_list.push({
                  Date: obj.reportlist[i].appointment_date,//this.gservice.get_Date_Formate(obj.reportlist[i].appointment_date),
                  Diag_Test_Id: obj.reportlist[i].diag_test_id,
                  diag_test_name:obj.reportlist[i].diag_test_name,
                  Centre_Name: obj.reportlist[i].diag_centre_name,
                  diag_appointment_id:obj.reportlist[i].diag_appointment_id,
                  Doctor_Name: doctor_name,
                  Client_Name: client_name
                });
              }
            }
          }
        },
        error => {});
  }

  get_Feaces_reports(get_data) {
    get_data.type = "faeces";

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(
      ipaddress.getIp + "record/reportlist/", (get_data), { headers: headers }).subscribe(
        data => {
          var obj = JSON.parse(JSON.stringify(data));
          if (obj != null) {
            if (obj.reportlist != null && obj.reportlist.length != 0) {
              for (var i = 0; i < obj.reportlist.length; i++) {
                var client_name = "";
                var doctor_name = "";

                if (obj.reportlist[i].client_middle_name != null) {
                  client_name = encrypt_decript.Decript(obj.reportlist[i].client_first_name) + " " +
                    encrypt_decript.Decript(obj.reportlist[i].client_middle_name) + " " +
                    encrypt_decript.Decript(obj.reportlist[i].client_last_name);
                } else {
                  client_name = encrypt_decript.Decript(obj.reportlist[i].client_first_name) + " " +
                    encrypt_decript.Decript(obj.reportlist[i].client_last_name);
                }

                if (obj.middle_name != null) {
                  doctor_name = obj.reportlist[i].first_name + " " + obj.reportlist[i].middle_name + " " + obj.reportlist[i].last_name;
                } else {
                  doctor_name = obj.reportlist[i].first_name + " " + obj.reportlist[i].last_name;
                }

                this.feaces_report_list.push({
                  Date: obj.reportlist[i].appointment_date,//this.gservice.get_Date_Formate(obj.reportlist[i].appointment_date),
                  Diag_Test_Id: obj.reportlist[i].diag_test_id,
                  diag_test_name:obj.reportlist[i].diag_test_name,
                  Centre_Name: obj.reportlist[i].diag_centre_name,
                  diag_appointment_id:obj.reportlist[i].diag_appointment_id,
                  Doctor_Name: doctor_name,
                  Client_Name: client_name
                });
              }
            }
          }
        },
        error => {});
  }

  get_Scan_reports(get_data) {
    get_data.type = "scans";
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(
      ipaddress.getIp + "record/reportlist/", (get_data), { headers: headers }).subscribe(
        data => {
          var obj = JSON.parse(JSON.stringify(data));
          if (obj != null) {
            if (obj.reportlist != null && obj.reportlist.length != 0) {
              for (var i = 0; i < obj.reportlist.length; i++) {
                var client_name = "";
                var doctor_name = "";

                if (obj.reportlist[i].client_middle_name != null) {
                  client_name = encrypt_decript.Decript(obj.reportlist[i].client_first_name) + " " +
                    encrypt_decript.Decript(obj.reportlist[i].client_middle_name) + " " +
                    encrypt_decript.Decript(obj.reportlist[i].client_last_name);
                } else {
                  client_name = encrypt_decript.Decript(obj.reportlist[i].client_first_name) + " " +
                    encrypt_decript.Decript(obj.reportlist[i].client_last_name);
                }

                if (obj.middle_name != null) {
                  doctor_name = obj.reportlist[i].first_name + " " + obj.reportlist[i].middle_name + " " + obj.reportlist[i].last_name;
                } else {
                  doctor_name = obj.reportlist[i].first_name + " " + obj.reportlist[i].last_name;
                }

                this.scan_report_list.push({
                  Date: obj.reportlist[i].appointment_date,//this.gservice.get_Date_Formate(obj.reportlist[i].appointment_date),
                  Diag_Test_Id: obj.reportlist[i].diag_test_id,
                  diag_test_name:obj.reportlist[i].diag_test_name,
                  Centre_Name: obj.reportlist[i].diag_centre_name,
                  diag_appointment_id:obj.reportlist[i].diag_appointment_id,
                  Doctor_Name: doctor_name,
                  Client_Name: client_name
                });
              }
            }
          }
        },
        error => {});
  }

  get_Xray_reports(get_data) {
    get_data.type = "x-ray";

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(
      ipaddress.getIp + "record/reportlist/",(get_data), { headers: headers }).subscribe(
        data => {
          var obj = JSON.parse(JSON.stringify(data));
          if (obj != null) {
            if (obj.reportlist != null && obj.reportlist.length != 0) {
              for (var i = 0; i < obj.reportlist.length; i++) {
                var client_name = "";
                var doctor_name = "";
                if (obj.reportlist[i].client_middle_name != null) {
                  client_name = encrypt_decript.Decript(obj.reportlist[i].client_first_name) + " " +
                    encrypt_decript.Decript(obj.reportlist[i].client_middle_name) + " " +
                    encrypt_decript.Decript(obj.reportlist[i].client_last_name);
                } else {
                  client_name = encrypt_decript.Decript(obj.reportlist[i].client_first_name) + " " +
                    encrypt_decript.Decript(obj.reportlist[i].client_last_name);
                }

                if (obj.middle_name != null) {
                  doctor_name = obj.reportlist[i].first_name + " " + obj.reportlist[i].middle_name + " " + obj.reportlist[i].last_name;
                } else {
                  doctor_name = obj.reportlist[i].first_name + " " + obj.reportlist[i].last_name;
                }

                this.xray_report_list.push({
                  Date: obj.reportlist[i].appointment_date,//this.gservice.get_Date_Formate(obj.reportlist[i].appointment_date),
                  Diag_Test_Id: obj.reportlist[i].diag_test_id,
                  diag_test_name:obj.reportlist[i].diag_test_name,
                  Centre_Name: obj.reportlist[i].diag_centre_name,
                  diag_appointment_id:obj.reportlist[i].diag_appointment_id,
                  Doctor_Name: doctor_name,
                  Client_Name: client_name
                });
              }
            }
          }
        },
        error => {});
  }

  get_Ultrasound_reports(get_data) {
    get_data.type = "Ultra-Sound";

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(
      ipaddress.getIp + "record/reportlist/", (get_data), { headers: headers }).subscribe(
        data => {
          var obj = JSON.parse(JSON.stringify(data));
          if (obj != null) {
            if (obj.reportlist != null && obj.reportlist.length != 0) {
              for (var i = 0; i < obj.reportlist.length; i++) {
                var client_name = "";
                var doctor_name = "";

                if (obj.reportlist[i].client_middle_name != null) {
                  client_name = encrypt_decript.Decript(obj.reportlist[i].client_first_name) + " " +
                    encrypt_decript.Decript(obj.reportlist[i].client_middle_name) + " " +
                    encrypt_decript.Decript(obj.reportlist[i].client_last_name);
                } else {
                  client_name = encrypt_decript.Decript(obj.reportlist[i].client_first_name) + " " +
                    encrypt_decript.Decript(obj.reportlist[i].client_last_name);
                }

                if (obj.middle_name != null) {
                  doctor_name = obj.reportlist[i].first_name + " " + obj.reportlist[i].middle_name + " " + obj.reportlist[i].last_name;
                } else {
                  doctor_name = obj.reportlist[i].first_name + " " + obj.reportlist[i].last_name;
                }

                this.ultrasound_report_list.push({
                  Date: obj.reportlist[i].appointment_date,//this.gservice.get_Date_Formate(obj.reportlist[i].appointment_date),
                  Diag_Test_Id: obj.reportlist[i].diag_test_id,
                  diag_test_name:obj.reportlist[i].diag_test_name,
                  Centre_Name: obj.reportlist[i].diag_centre_name,
                  diag_appointment_id:obj.reportlist[i].diag_appointment_id,
                  Doctor_Name: doctor_name,
                  Client_Name: client_name,
                  TestId: "Test_" + i
                });
              }
            }
          }
        },
        error => {});
  }

  report_view(test_id, type,diag_appointment_id) {
    this.report_diag_id = diag_appointment_id;
    this.report_test_id = test_id;
    this.relation = this.personaldata.rel_id;
    this.report_type = type;
    this.Get_Report_Detailview("normal"); // view report view
    this.clientflag = true;
    this.docflag=false;
    this.diagflag=false;
    this.report_detail_flag = true; 
    this.report_detail_dig_flag = false; 
    this.report_detail_pres_flag = true;
    this.documentView_flag = false;
  }

  back_Nav(){
    this.report_detail_flag = false; 
    this.report_detail_dig_flag = true; 
    this.report_detail_pres_flag = true;
    this.documentView_flag = false;
  }

  Get_Report_Detailview(type) {
    var send_json, url;
    if (type == "normal") {
        url = 'record/diagreportview/';
        send_json = ({
            type: this.report_type,
            flag: "doctor",
            diag_app_id: this.report_diag_id,
            country: ipaddress.country_code,
            pres_diag_id: this.report_pres_id,
            pres_test_id: this.report_test_id,
        })

    } else if(type == "diag"){
        url = 'record/diagreportview/';
        send_json = ({
            type: this.report_type,
            flag: "diagnosis",
            diag_app_id: this.report_diag_id,
            country: ipaddress.country_code,
            pres_diag_id: this.report_pres_id,
            pres_test_id: this.report_test_id,
        })
        
    } else {
        url = 'secopn/greport/';
        send_json = ({
            type: this.opn_type,
            pres_test_id: this.opn_pres_test_id,
            opinion_id: this.opn_id,
            country: ipaddress.country_code,
            app_id: this.opn_app_id
        })
    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.set('Access-Control-Allow-Origin', '*');
    headers.set('Access-Control-Allow-Headers', 'Content-Type',);
    headers.set('Access-Control-Allow-Methods', 'GET,POST,OPTIONS,DELETE,PUT');
    headers.set('Authorization', 'Bearer key');

    this.http.post(ipaddress.getIp.toString() + url, send_json,
        { headers: headers })
        .subscribe(
            response => {
              var obj = JSON.parse(JSON.stringify(response));
                this.ImagePath = "https://mdbootstrap.com/img/Others/documentation/img%20(150)-mini.jpg";
               
                if (obj.diag_centre_name != null) {
                    this.loader = true;
                    if (obj.date_entered != null) {
                        var datereport = obj.date_entered.split('-');
                        this.date_txt = datereport[2] + "-" + datereport[1] + "-" + datereport[0]
                    }
                    this.diag_name = obj.diag_centre_name;

                    if (obj.address2 != null) {
                        this.diag_add = obj.address1 + " " + obj.address2;
                    } else {
                        this.diag_add = obj.address1;
                    }

                    this.diag_loc = obj.location;
                    this.diag_cityzip = obj.city + " - " + obj.zipcode;
                    this.diag_statecon = obj.state + " - " + obj.country;
                    this.diag_teleph = obj.telephone;

                    if (obj != null) {
                        this.client_age = obj.age;
                        this.get_age = obj.age;
                        this.Age_div = false;
                    } else {
                        this.Age_div = true;
                    }

                    if (obj.sign != null) {
                        this.review_sign = "data:image/png;base64," + obj.sign;
                    } else {
                      this.review_sign = "";
                    }
                    if (obj.app_first_name != null) {
                        this.review_div = true;

                    } else {}

                    if (obj.app_middle_name != null) {
                        if (obj.app_first_name != undefined && obj.app_middle_name != undefined && obj.app_last_name != undefined) {
                            this.review_by = obj.app_first_name + " " + obj.app_middle_name + " " + obj.app_last_name;
                        }
                    } else {
                        if (obj.app_first_name != undefined && obj.app_last_name != undefined) {
                            this.review_by = obj.app_first_name + " " + obj.app_last_name;
                        }
                    }

                    this.clent_id = obj.client_reg_id;
                    localStorage.setItem("client_decrypt_first", obj.first_name);
                    localStorage.setItem("client_decrypt_middle", obj.middle_name);
                    localStorage.setItem("client_decrypt_last", obj.last_name);

                    if (obj.middle_name != null) {
                        this.client_another_name = encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.middle_name) + " " + encrypt_decript.Decript(obj.last_name);
                    } else {
                        this.client_another_name = encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.last_name);
                    }

                    this.client_name = encrypt_decript.Encript(localStorage.getItem("client_decrypt_first")).toString(), + " " + encrypt_decript.Encript(localStorage.getItem("client_decrypt_middle")).toString() + " " + encrypt_decript.Encript(localStorage.getItem("client_decrypt_last")).toString();
                    if( obj.doc_middle_name != undefined && obj.doc_middle_name != null){
                        this.diag_refertxt = "Dr." + obj.doc_first_name + " " + obj.doc_middle_name + " " + obj.doc_last_name;
                    } else{
                        this.diag_refertxt = "Dr." + obj.doc_first_name + " " + obj.doc_last_name;
                    }
                    
                    this.client_age = obj.age;
                    this.client_gender = encrypt_decript.Decript(obj.gender);
                    this.time_collect = Time_Formate_Session(obj.time_collected);
                    this.review_txt = "------";

                    this.second_opinion_mintlist = [];
                    this.second_opinion_subtlist = [];
                    this.second_opinion_mintlist1 = [];

                    if (this.report_type == "Urine" || this.report_type == "Blood" || this.report_type == "Faeces") {
                        this.blood_urine_faeces_div = false;
                        this.xray_scan_ultra_div = true;
                        for (var i = 0; i < obj.main_tests.length; i++) {
                            this.second_opinion_mintlist.push({
                                pres_test_id: obj.main_tests[i].pres_test_id,
                                test_id: obj.main_tests[i].test_id,
                                test_name: obj.main_tests[i].test_name,
                            });

                            for (var i = 0; i < obj.main_tests.length; i++) {
                                for (var j = 0; j < obj.main_tests[i].subtests.length; j++) {
                                    this.temp_value = "";

                                    this.temp_value = this.sanitizer.bypassSecurityTrustHtml(obj.main_tests[i].subtests[j].range);
                                    this.second_opinion_subtlist.push({
                                        sub_test_name: obj.main_tests[i].subtests[j].sub_test_name,
                                        reading: obj.main_tests[i].subtests[j].reading,
                                        test_range: this.temp_value,
                                        unit: obj.main_tests[i].subtests[j].unit,
                                        subtest_name_txt: obj.main_tests[i].subtests[j].test_name
                                    });
                                }
                            }
                        }
                    } else {
                        this.blood_urine_faeces_div = true;
                        this.xray_scan_ultra_div = false;
                        this.loadCss();
                        this.loadScript();
                        this.report_img = [];
                        for (var i = 0; i < obj.main_tests.length; i++) {

                            if (obj.main_tests[i].image != null) {
                                this.dicom_doc = false;
                                this.report_img_flag = true;
                                this.report_detail = ipaddress.Ip_with_img_address.toString() + obj.main_tests[i].image;
                                    this.report_img.push({
                                      image:ipaddress.Ip_with_img_address.toString() + obj.main_tests[i].image});
                            }
                            if (obj.main_tests[i].image1 != null) {
                              this.report_img.push({
                                image:ipaddress.Ip_with_img_address.toString() + obj.main_tests[i].image1})
                            }
                            if (obj.main_tests[i].image2 != null) {
                              this.report_img.push({
                                image:ipaddress.Ip_with_img_address.toString() + obj.main_tests[i].image2})
                            }
                            if (obj.main_tests[i].image3 != null) {
                              this.report_img.push({
                                image:ipaddress.Ip_with_img_address.toString() + obj.main_tests[i].image3})
                            }

                            var sequences:any = "";
                            if(obj.main_tests[i].sequences != undefined){
                              sequences = this.sanitizer.bypassSecurityTrustHtml(obj.main_tests[i].sequences);
                            }
                            var impression:any = "";
                            if(obj.main_tests[i].impression != undefined){
                              impression = this.sanitizer.bypassSecurityTrustHtml(obj.main_tests[i].impression);
                            }

                            this.second_opinion_mintlist1.push({
                                pres_test_id: obj.main_tests[i].pres_test_id,
                                test_name: obj.main_tests[i].test_name,
                                sequences: sequences,
                                impression: impression,
                            });
                        }
                    }
                } else {
                  this.toastr.error(Message_data.network);
                }
            },
            error => {
              this.toastr.error(Message_data.network);
            }
        )
  }

  Document_click() {
    const dialogRef = this.dialog.open(DocumentDetailsZoomComponent, {
      width: '900px',
      data: {report_pdf: this.report_detail}
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
 
  savesend_data(){
    if(this.report_detail_flag == true){
      if((document.getElementById("theimage")) != null){
        var img_str = (document.getElementById("theimage")as HTMLImageElement).src;
      }

      var img_path ="";
      if (img_str != null) {
        img_path = (img_str.toString()).split(',')[1];
      }
      else{
        img_path = "";
      }

      //upload document
      if(img_path != ""){
        var send_data;
        if(this.personaldata.sub_id != "" && this.personaldata.sub_id.length != 0){
          send_data = {
            image: (img_path),
            client_reg_id:this.personaldata.Client_id,
            relation_id:this.personaldata.rel_id,
            sub_rel_id: this.personaldata.sub_id,
            country:ipaddress.country_code,
            image_type:"PNG",
            scan_test:this.report_type,
            pres_test_id: this.report_test_id,
            document: this.base64data,
          }
        }
        else{
            send_data = {
            image: (img_path),
            client_reg_id:this.personaldata.Client_id,
            relation_id:this.personaldata.rel_id,
            country:ipaddress.country_code,
            image_type:"PNG",
            scan_test:this.report_type,
            pres_test_id: this.report_test_id,
            document: this.base64data,
          }
        }

        

        var headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
    
        this.http.post(ipaddress.getIp + 'prescription/udr', (send_data), { headers: headers }).subscribe(
          response => {
            
            if(response["key"] != 0){
              this.toastr.success(Message_data.imgSavedSuccess);
            }else{
              this.toastr.error(Message_data.errOccur);
          }
          })
      }else{
        var send_data;
        if(this.personaldata.sub_id != "" && this.personaldata.sub_id.length != 0){
          send_data = {
            image: (img_path),
            client_reg_id:this.personaldata.Client_id,
            relation_id:this.personaldata.rel_id,
            sub_rel_id: this.personaldata.sub_id,
            country:ipaddress.country_code,
            image_type:"PNG",
            scan_test:this.report_type,
            pres_test_id: this.report_test_id,
            document: this.base64data,
          }
        }
        else{
            send_data = {
            image: (img_path),
            client_reg_id:this.personaldata.Client_id,
            relation_id:this.personaldata.rel_id,
            country:ipaddress.country_code,
            image_type:"PNG",
            scan_test:this.report_type,
            pres_test_id: this.report_test_id,
          }
        }

        

        var headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
    
        this.http.post(ipaddress.getIp + 'prescription/udr', (send_data), { headers: headers }).subscribe(
          response => {
            
            if(response["key"] != 0){
              this.toastr.success(Message_data.imgSavedSuccess);
            }else{
              this.toastr.error(Message_data.errOccur);
            }
          })
      }
    }

  }

  uploadDocument(){
    const dialogRef = this.dialog.open(UploadReportComponent, {
      width: '700px',
      height: '220px',
      data:{
        client_reg_id:this.personaldata.Client_id,
        relation_id: this.personaldata.rel_id,
        sub_rel_id: this.personaldata.sub_id,
        spl_id: this.personaldata.spl_id
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      
      var send_data;
      if(this.personaldata.sub_id.trim ==null || this.personaldata.sub_id =="" || this.personaldata.sub_id.trim ==undefined){
        send_data = {
          client_reg_id:this.personaldata.Client_id,
          relation_id:this.personaldata.rel_id,
          spl_id:this.personaldata.spl_id,
        }
      }else{
        send_data = {
          client_reg_id:this.personaldata.Client_id,
          relation_id:this.personaldata.rel_id,
          sub_relation_id: this.personaldata.sub_id,
          spl_id:this.personaldata.spl_id,
        }
      }
      this.retrieveUploadDocumentList(send_data);
    });
  }

  retrieveUploadDocumentList(getData){
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(
      ipaddress.getIp + "gen/cgdoc", (getData), { headers: headers }).subscribe(
        data => {
          if(data != undefined){
            var response = JSON.parse(JSON.stringify(data));
            if(response.doc_details.length != 0){
              var obj = response.doc_details;
              this.documentBlood = false;
              this.documentUrine = false;
              this.documentFaeces = false;
              this.documentXray = false;
              this.documentScan = false;
              this.documentUltrasound = false;
              for(var i= 0; i < obj.length; i++){
                var doc_type ="";
                if(obj[i].doc_type_name){
                  doc_type = obj[i].doc_type_name;
                  if(doc_type == "Blood report"){
                    this.documentBlood = true;
                  }
                  if(doc_type == "Urine report"){
                    this.documentUrine = true;
                  }
                  if(doc_type == "Stool report"){
                    this.documentFaeces = true;
                  }
                  if(doc_type == "X-ray report"){
                    this.documentXray = true;
                  }
                  if(doc_type == "Scan report"){
                    this.documentScan = true;
                  }
                  if(doc_type == "Ulta-sound report"){
                    this.documentUltrasound = true;
                  }
                }

                var document_name = "";
                if(obj[i].document_name !=undefined){
                  document_name = obj[i].document_name;
                }

                var document_date = "";
                if(obj[i].document_date !=undefined){
                  document_date = Date_Formate(obj[i].document_date);
                }

                var document:any ="";
                if(obj[i].document !=undefined){
                  document = this.sanitizer.bypassSecurityTrustResourceUrl(ipaddress.Ip_with_img_address+ obj[i].document);
                }

                var middle_name = "";
                if(obj[i].middle_name != undefined){
                  middle_name = encrypt_decript.Decript(obj[i].middle_name);
                }

                this.documentList.push({
                  client_doc_id: obj[i].client_doc_id,
                  relation_id: obj[i].relation_id,
                  relationship_name: obj[i].relationship_name,
                  document_type: obj[i].document_type,
                  type: obj[i].doc_type_name,
                  document_name: document_name,
                  document_date: document_date,
                  document: document,
                  spl_id: obj[i].spl_id,
                  spl_name: obj[i].spl_name,
                  first_name: encrypt_decript.Decript(obj[i].first_name),
                  last_name: encrypt_decript.Decript(obj[i].last_name),
                  middle_name:middle_name,
                });
              }
            }
          }
        });
  }

  document_view(document) {
    this.relation = this.personaldata.rel_id;
    this.clientflag = true;
    this.docflag=false;
    this.diagflag=false;
    this.report_detail_flag = true; 
    this.report_detail_dig_flag = true; 
    this.report_detail_pres_flag = true;
    this.documentView_flag = true;
    this.documentDetailedView(document);
  }

  documentDetailedView(document){
    this.document = "";
    if(document.length != 0){
      this.document = document.document;
      if(document.middle_name != ""){
        this.client_another_name = document.first_name + " " + document.middle_name + " " + document.last_name;
      }else{
        this.client_another_name = document.first_name + " " + document.last_name;
      }
      this.clent_id = this.personaldata.Client_id;
      this.client_age = this.personaldata.Age_data;
      this.client_gender = this.personaldata.Gender_data;
      this.date_txt = document.document_date;
    }
  }
}
