import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatSelectModule} from '@angular/material/select';
import { MatFormFieldModule} from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import {MatDialogModule} from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSortModule } from '@angular/material/sort';
import { SecondOpinionReportdetailviewPopupComponent } from './second-opinion-reportdetailview-popup.component';
import { QuillModule } from 'ngx-quill';

@NgModule({
    imports:[MatDialogModule,MatSortModule,CommonModule,MatInputModule,MatCheckboxModule ,MatSelectModule,MatFormFieldModule,MatIconModule,MatButtonModule,FormsModule,QuillModule.forRoot()],
    declarations:[SecondOpinionReportdetailviewPopupComponent],
    exports:[SecondOpinionReportdetailviewPopupComponent]
})
export class SecondOpinionReportdetailviewPopupModule{   
   
}