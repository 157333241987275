import { Component, OnInit, Input } from '@angular/core';
import { Helper_Class } from '../../helper_class';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SecondOpinionReportdetailviewPopupComponent } from '../second-opinion-reportdetailview-popup/second-opinion-reportdetailview-popup.component';
import { SecondOpinionPresviewPopupComponent } from '../second-opinion-presview-popup/second-opinion-presview-popup.component';
import { Doc_Helper } from '../Doc_Helper';
import { Observable, observable, Subscription } from 'rxjs';
import { SecondOpinionService } from '../second-opinion-casesheet/SecondOpinion.Service';
import { DocumentDetailsZoomComponent } from '../document-details-zoom/document-details-zoom.component';
import { ipaddress } from '../../ipaddress' ;
@Component({
  selector: 'app-second-opinion-casesheet-reports',
  templateUrl: './second-opinion-casesheet-reports.component.html',
  styleUrls: ['./second-opinion-casesheet-reports.component.css']
})
export class SecondOpinionCasesheetReportsComponent implements OnInit {

  public reports = "min";
  public document_list = [];

  public second_blood_list: any = [];
  public second_urine_list: any = [];
  public second_faces_list: any = [];
  public second_xray_list: any = [];
  public second_scane_list: any = [];
  public second_ultra_list: any = [];

  public second_medical_preslist: any = [];
  public blood_hidden: boolean;
  public urine_hidden: boolean;
  public faeces_hidden: boolean;
  public xray_hidden: boolean;
  public scan_hidden: boolean;
  public ultra_hidden: boolean;
  public header_footer_flag: boolean;
  public reports_upload: boolean;
  public medical_prescript: boolean;

  public bloodTab: boolean = false;
  public urineTab: boolean = false;
  public facesTab: boolean = false;
  public xrayTab: boolean = false;
  public scanTab: boolean = false;

  public panelOpenState1: boolean;
  public panelOpenState2: boolean;
  public panelOpenState3: boolean;
  public panelOpenState4: boolean;
  public panelOpenState5: boolean;
  public panelOpenState6: boolean;
  public subscription: Subscription;

  constructor(public dialog: MatDialog, private http: Http, private routes: ActivatedRoute, private router: Router, public toastr: ToastrService, public messageservice1: SecondOpinionService) {
    this.medical_prescript = true;
    this.blood_hidden = true;
    this.urine_hidden = true;
    this.faeces_hidden = true;
    this.xray_hidden = true;
    this.scan_hidden = true;
    this.ultra_hidden = true;
  }


  ngOnInit() {
    this.get_retrival_data();
    this.subscription = this.messageservice1.getSecondOpinionMessage().subscribe(message => {
      if (message) {
          this.reports = message;
      }
    });
  }

  get_retrival_data() {
    var getOpnRet = Helper_Class.getOpnRet();
    //Document upload
    if (getOpnRet.blood != null || getOpnRet.urine != null || getOpnRet.faeces != null || getOpnRet["x-ray"] != null || getOpnRet.scans != null || getOpnRet["ultra-sound"] != null) {
      this.reports_upload = false;
    }else {
      this.reports_upload = true;
    }

    if (getOpnRet.blood != null) {
      this.second_blood_list = getOpnRet.blood;
      if (this.second_blood_list != 0) {
        this.blood_hidden = false;
      }
    }
    if (getOpnRet.urine != null) {
      this.second_urine_list = getOpnRet.urine;
      if (this.second_urine_list != 0) {
        this.urine_hidden = false;
      }
    }
    
    if (getOpnRet.faeces != null) {
      this.second_faces_list = getOpnRet.faeces;
      if (this.second_faces_list != 0) {
        this.faeces_hidden = false;
      }
    }
    
    if (getOpnRet["x-ray"] != null) {
      this.second_xray_list = getOpnRet["x-ray"];
      if (this.second_xray_list != 0) {
        this.xray_hidden = false;
      }
    }

    if (getOpnRet.scans != null) {
      this.second_scane_list = getOpnRet.scans;
      if (this.second_scane_list != 0) {
        this.scan_hidden = false;
      }
    }

    if (getOpnRet["ultra-sound"] != null) {
      this.second_ultra_list = getOpnRet["ultra-sound"];
      if (this.second_ultra_list != 0) {
        this.ultra_hidden = false;
      }
    }

    if (getOpnRet.pres_drug != null) {
      this.second_medical_preslist = getOpnRet.pres_drug;
      this.medical_prescript = false;
    }else {
      this.medical_prescript = true;
    }

    if (getOpnRet.doc1 != null) {
      if (getOpnRet.doc1.indexOf("#") >= 0) {
        this.document_list.push({
          document: getOpnRet.doc1,
        });
      }else {
        this.document_list.push({
          document: ipaddress.Ip_with_img_address + getOpnRet.doc1,
        });
      }
    }
    if (getOpnRet.doc2 != null) {
      if (getOpnRet.doc2.indexOf("#") >= 0) {
        this.document_list.push({
          document: getOpnRet.doc2,
        });
      }else {
        this.document_list.push({
          document: ipaddress.Ip_with_img_address + getOpnRet.doc2,
        });
      }
    }
    if (getOpnRet.doc3 != null) {
      if (getOpnRet.doc3.indexOf("#") >= 0) {
        this.document_list.push({
          document: getOpnRet.doc3,
        });
      }else {
        this.document_list.push({
          document: ipaddress.Ip_with_img_address + getOpnRet.doc3,
        });
      }
    }
    if (getOpnRet.doc4 != null) {
      if (getOpnRet.doc4.indexOf("#") >= 0) {
        this.document_list.push({
          document: getOpnRet.doc4,
        });
      }else {
        this.document_list.push({
          document: ipaddress.Ip_with_img_address + getOpnRet.doc4,
        });
      }
    }
    if (getOpnRet.doc5 != null) {
      if (getOpnRet.doc5.indexOf("#") >= 0) {
        this.document_list.push({
          document: getOpnRet.doc5,
        });
      }else {
        this.document_list.push({
          document: ipaddress.Ip_with_img_address + getOpnRet.doc5,
        });
      }
    }
  }

  report_detail(report) {
    var get_file_type = null;

    if (report.indexOf("#") >= 0) {
      var report_data = report.split('#');
      get_file_type = report_data[1].split('.');
      var send_data;
      send_data = {
        client_reg_id: Helper_Class.getOpnRet().client_reg_id,
        document_name: report_data[1],
      }
      if(report_data[0] != undefined && report_data[0] != null && report_data[0] != "")
        send_data.client_doc_id = report_data[0];

      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'gen/durl', JSON.stringify(send_data),
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            const dialogRef = this.dialog.open(DocumentDetailsZoomComponent, {
              width: '900px',
              height:'500px',
              data: {
                report_pdf: ipaddress.Ip_with_img_address + obj.path
              }
            });
        
            dialogRef.afterClosed().subscribe(result => {
            });
          },
          error => {});
    }else {
      const dialogRef = this.dialog.open(DocumentDetailsZoomComponent, {
        width: '900px',
        height:'500px',
        data: {report_pdf: report}
      });
  
      dialogRef.afterClosed().subscribe(result => {
      });
    }
  }

  report_view(repo, repo1, second_app_id) {
    const dialogRef = this.dialog.open(SecondOpinionReportdetailviewPopupComponent, {
      width: '900px',
      data: { type: repo1, pres_test_id: repo, opinion_id: Doc_Helper.getOpnid(), app_id: second_app_id }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  view_med(medview) {
    const dialogRef = this.dialog.open(SecondOpinionPresviewPopupComponent, {
      width: '900px',
      height: '500px',
      data: { med_id: medview, sec_opn: "" }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
