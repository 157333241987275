<div mat-dialog-title class="popup_border_icd">
    <h5 class="header_txt">Purpose Of Visit</h5>
    <div mat-dialog-actions>
      <!-- <button mat-icon-button  class="btnclose"> -->
        <!-- <span (click)="ClosePopup()" id="close_icd">X</span> -->
        <img src="../../../assets/img/close_icon.png" (click)="ClosePopup()" width="20px" height="20px" id="close_gynoobst"/>
    </div>
  </div>
  <div mat-dialog-content>
      <div class="img_cls">
        <a (click)="setgynofields('Gynaecology')" style="    padding-right: 16px;">
          <button id="add_btn" [mat-dialog-close]="" cdkFocusInitial mat-button >
          <img src="../../../assets/img/Gynacology_icons/gynaecology.png" width="100px" height="100px"/>
          <span>Gynacology</span>
          </button> 
        </a>
        <a (click)="setgynofields('Obstetrics')">
            <button id="add_btn" [mat-dialog-close]="" cdkFocusInitial mat-button >
            <img src="../../../assets/img/Gynacology_icons/obstetrics.png"  width="100px" height="100px"/>
            <span>Obstetrics</span>
            </button> 
          </a>
        </div>
</div>