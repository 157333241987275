<div mat-dialog-title class="popup_border_icd">
  <h5 class="header_txt">View document</h5>
  <div mat-dialog-actions>
    <img src="../../../assets/img/close_icon.png" (click)="ClosePopup()" width="20px" height="20px" id="close_icd" />
  </div>
</div>
<div mat-dialog-content class="diab_content">
  <div class="container">
    <div class="row">
      <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-6">
        <div class="pdf_class" [hidden]="pdf_view">
          <iframe class="controls" [src]="controllerSrc" width="887px" height="500px" frameborder="0"
            webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
        </div>
      </div>
    </div>
  </div>
  <br>
</div>
<div mat-dialog-actions align="end" style="width: auto;right: 19px;">
</div>