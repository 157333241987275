<div *ngIf="clinicalFlag == 'min'">
    <div class="container">
        <div class="row">
           <div class="col-7 margin-bottomvalue">
              <mat-label class="matlabel ">Diabetics</mat-label>
           </div>
           <div class="col-2  margin-bottomvalue">
              <mat-button-toggle id="toggid1" class="togglebtn" [(ngModel)]="toggDiabFlag" ngDefaultControl
                 [checked]="toggDiabFlag" (change)="changeDiab($event.source.checked)" [disabled]="client">
                 <span class="familytab" *ngIf="!toggDiabFlag"><img class="family_toggle_img"
                       src="../../../assets/ui_icons/cross_mark.svg"></span>
                 <span class="familytab" *ngIf="toggDiabFlag"><img class="family_toggle_img"
                       src="../../../assets/ui_icons/tick_mark.svg"></span>
              </mat-button-toggle>
           </div>
           <div class="col-7">
              <mat-label class="matlabel ">Heart attack</mat-label>
           </div>
           <div class="col-2 margin-bottomvalue">
              <mat-button-toggle id="toggid2" class="togglebtn" [(ngModel)]="toggHeartAttackFlag" ngDefaultControl
                 [checked]="toggHeartAttackFlag" (change)="changeHeartAttack($event.source.checked)" [disabled]="client">
                 <span class="familytab" *ngIf="!toggHeartAttackFlag"><img class="family_toggle_img"
                       src="../../../assets/ui_icons/cross_mark.svg"></span>
                 <span class="familytab" *ngIf="toggHeartAttackFlag"><img class="family_toggle_img"
                       src="../../../assets/ui_icons/tick_mark.svg"></span>
              </mat-button-toggle>
           </div>
           <div class="col-7 margin-bottomvalue">
              <mat-label class="matlabel ">Stroke</mat-label>
           </div>
           <div class="col-2 margin-bottomvalue">
              <mat-button-toggle id="toggid3" class="togglebtn" [(ngModel)]="toggStrokeFlag" ngDefaultControl
                 [checked]="toggStrokeFlag" (change)="changeStroke($event.source.checked)" [disabled]="client">
                 <span class="familytab" *ngIf="!toggStrokeFlag"><img class="family_toggle_img"
                       src="../../../assets/ui_icons/cross_mark.svg"></span>
                 <span class="familytab" *ngIf="toggStrokeFlag"><img class="family_toggle_img"
                       src="../../../assets/ui_icons/tick_mark.svg"></span>
              </mat-button-toggle>
           </div>
           <div class="col-7 margin-bottomvalue">
              <mat-label class="matlabel ">Arterial</mat-label>
           </div>
           <div class="col-2">
              <mat-button-toggle id="toggid4" class="togglebtn" [(ngModel)]="toggArterFlag" ngDefaultControl
                 [checked]="toggArterFlag" (change)="changeArter($event.source.checked)" [disabled]="client">
                 <span class="familytab" *ngIf="!toggArterFlag"><img class="family_toggle_img"
                       src="../../../assets/ui_icons/cross_mark.svg"></span>
                 <span class="familytab" *ngIf="toggArterFlag"><img class="family_toggle_img"
                       src="../../../assets/ui_icons/tick_mark.svg"></span>
              </mat-button-toggle>
           </div>
        </div>
     </div>
</div>
<div *ngIf="clinicalFlag == 'max'">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <p class="tervys_heading">Life style Diseases</p>
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-4">
                        <div class="cover_div">
                            <div class="content_cover">
                                <div class="row">
                                    <div class="col-7 margin-bottomvalue">
                                        <mat-label class="matlabel ">Diabetics</mat-label>
                                    </div>
                                    <div class="col-2  margin-bottomvalue">
                                        <mat-button-toggle id="toggid1" class="togglebtn" [(ngModel)]="toggDiabFlag"
                                            ngDefaultControl [checked]="toggDiabFlag"
                                            (change)="changeDiab($event.source.checked)" [disabled]="client">
                                            <span class="familytab" *ngIf="!toggDiabFlag"><img class="family_toggle_img"
                                                    src="../../../assets/ui_icons/cross_mark.svg"></span>
                                            <span class="familytab" *ngIf="toggDiabFlag"><img class="family_toggle_img"
                                                    src="../../../assets/ui_icons/tick_mark.svg"></span>
                                        </mat-button-toggle>
                                    </div>
                                    <div class="col-7">
                                        <mat-label class="matlabel ">Heart attack</mat-label>
                                    </div>
                                    <div class="col-2 margin-bottomvalue">
                                        <mat-button-toggle id="toggid2" class="togglebtn" [(ngModel)]="toggHeartAttackFlag"
                                            ngDefaultControl [checked]="toggHeartAttackFlag"
                                            (change)="changeHeartAttack($event.source.checked)" [disabled]="client">
                                            <span class="familytab" *ngIf="!toggHeartAttackFlag"><img
                                                    class="family_toggle_img"
                                                    src="../../../assets/ui_icons/cross_mark.svg"></span>
                                            <span class="familytab" *ngIf="toggHeartAttackFlag"><img
                                                    class="family_toggle_img"
                                                    src="../../../assets/ui_icons/tick_mark.svg"></span>
                                        </mat-button-toggle>
                                    </div>
                                    <div class="col-7 margin-bottomvalue">
                                        <mat-label class="matlabel ">Stroke</mat-label>
                                    </div>
                                    <div class="col-2 margin-bottomvalue">
                                        <mat-button-toggle id="toggid3" class="togglebtn" [(ngModel)]="toggStrokeFlag"
                                            ngDefaultControl [checked]="toggStrokeFlag"
                                            (change)="changeStroke($event.source.checked)" [disabled]="client">
                                            <span class="familytab" *ngIf="!toggStrokeFlag"><img class="family_toggle_img"
                                                    src="../../../assets/ui_icons/cross_mark.svg"></span>
                                            <span class="familytab" *ngIf="toggStrokeFlag"><img class="family_toggle_img"
                                                    src="../../../assets/ui_icons/tick_mark.svg"></span>
                                        </mat-button-toggle>
                                    </div>
                                    <div class="col-7 margin-bottomvalue">
                                        <mat-label class="matlabel ">Arterial</mat-label>
                                    </div>
                                    <div class="col-2">
                                        <mat-button-toggle id="toggid4" class="togglebtn" [(ngModel)]="toggArterFlag"
                                            ngDefaultControl [checked]="toggArterFlag"
                                            (change)="changeArter($event.source.checked)" [disabled]="client">
                                            <span class="familytab" *ngIf="!toggArterFlag"><img class="family_toggle_img"
                                                    src="../../../assets/ui_icons/cross_mark.svg"></span>
                                            <span class="familytab" *ngIf="toggArterFlag"><img class="family_toggle_img"
                                                    src="../../../assets/ui_icons/tick_mark.svg"></span>
                                        </mat-button-toggle>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-4" style="position: relative;left: -18px;">
                        <div class="cover_div">
                            <div class="content_cover">
                                <div class="row">
                                    <div class="col-7 margin-bottomvalue">
                                        <mat-label class="matlabel ">Endocrine</mat-label>
                                    </div>
                                    <div class="col-2  margin-bottomvalue">
                                        <mat-button-toggle id="toggid5" class="togglebtn" [(ngModel)]="toggEndoFlag"
                                            ngDefaultControl [checked]="toggEndoFlag"
                                            (change)="changeEndro($event.source.checked)" [disabled]="client">
                                            <span class="familytab" *ngIf="!toggEndoFlag"><img class="family_toggle_img"
                                                    src="../../../assets/ui_icons/cross_mark.svg"></span>
                                            <span class="familytab" *ngIf="toggEndoFlag"><img class="family_toggle_img"
                                                    src="../../../assets/ui_icons/tick_mark.svg"></span>
                                        </mat-button-toggle>
                                    </div>
                                    <div class="col-7">
                                        <mat-label class="matlabel ">Autoimmune</mat-label>
                                    </div>
                                    <div class="col-2 margin-bottomvalue">
                                        <mat-button-toggle id="toggid6" class="togglebtn" [(ngModel)]="toggAutoFlag"
                                            ngDefaultControl [checked]="toggAutoFlag"
                                            (change)="changeAutoImmune($event.source.checked)" [disabled]="client">
                                            <span class="familytab" *ngIf="!toggAutoFlag"><img class="family_toggle_img"
                                                    src="../../../assets/ui_icons/cross_mark.svg"></span>
                                            <span class="familytab" *ngIf="toggAutoFlag"><img class="family_toggle_img"
                                                    src="../../../assets/ui_icons/tick_mark.svg"></span>
                                        </mat-button-toggle>
                                    </div>
                                    <div class="col-7 margin-bottomvalue">
                                        <mat-label class="matlabel ">Hypertension</mat-label>
                                    </div>
                                    <div class="col-2 margin-bottomvalue">
                                        <mat-button-toggle id="toggid7" class="togglebtn" [(ngModel)]="toggHyperFlag"
                                            ngDefaultControl [checked]="toggHyperFlag"
                                            (change)="changeHypertension($event.source.checked)" [disabled]="client">
                                            <span class="familytab" *ngIf="!toggHyperFlag"><img class="family_toggle_img"
                                                    src="../../../assets/ui_icons/cross_mark.svg"></span>
                                            <span class="familytab" *ngIf="toggHyperFlag"><img class="family_toggle_img"
                                                    src="../../../assets/ui_icons/tick_mark.svg"></span>
                                        </mat-button-toggle>
                                    </div>
                                    <div class="col-7 margin-bottomvalue">
                                        <mat-label class="matlabel ">Hemophilia</mat-label>
                                    </div>
                                    <div class="col-2 margin-bottomvalue">
                                        <mat-button-toggle id="toggid8" class="togglebtn" [(ngModel)]="toggHemoFlag"
                                            ngDefaultControl [checked]="toggHemoFlag"
                                            (change)="changeHemophilia($event.source.checked)" [disabled]="client">
                                            <span class="familytab" *ngIf="!toggHemoFlag"><img class="family_toggle_img"
                                                    src="../../../assets/ui_icons/cross_mark.svg"></span>
                                            <span class="familytab" *ngIf="toggHemoFlag"><img class="family_toggle_img"
                                                    src="../../../assets/ui_icons/tick_mark.svg"></span>
                                        </mat-button-toggle>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-4" style="position: relative;left: -36px;">
                        <div class="cover_div">
                            <div class="content_cover">
                                <div class="row">
                                    <div class="col-7 margin-bottomvalue">
                                        <mat-label class="matlabel ">Tuberculosis</mat-label>
                                    </div>
                                    <div class="col-2 margin-bottomvalue">
                                        <mat-button-toggle id="toggid9" class="togglebtn" [(ngModel)]="toggTuberFlag"
                                            ngDefaultControl [checked]="toggTuberFlag"
                                            (change)="changeTuber($event.source.checked)" [disabled]="client">
                                            <span class="familytab" *ngIf="!toggTuberFlag"><img class="family_toggle_img"
                                                    src="../../../assets/ui_icons/cross_mark.svg"></span>
                                            <span class="familytab" *ngIf="toggTuberFlag"><img class="family_toggle_img"
                                                    src="../../../assets/ui_icons/tick_mark.svg"></span>
                                        </mat-button-toggle>
                                    </div>
                                    <div class="col-7 margin-bottomvalue">
                                        <mat-label class="matlabel ">HIV</mat-label>
                                    </div>
                                    <div class="col-2 margin-bottomvalue">
                                        <mat-button-toggle id="toggid10" class="togglebtn" [(ngModel)]="toggHivFlag"
                                            ngDefaultControl [checked]="toggHivFlag"
                                            (change)="changeHiv($event.source.checked)" [disabled]="client">
                                            <span class="familytab" *ngIf="!toggHivFlag"><img class="family_toggle_img"
                                                    src="../../../assets/ui_icons/cross_mark.svg"></span>
                                            <span class="familytab" *ngIf="toggHivFlag"><img class="family_toggle_img"
                                                    src="../../../assets/ui_icons/tick_mark.svg"></span>
                                        </mat-button-toggle>
                                    </div>
                                    <div class="col-7 margin-bottomvalue">
                                        <mat-label class="matlabel">Hepatitis</mat-label>
                                    </div>
                                    <div class="col-2  margin-bottomvalue">
                                        <mat-button-toggle id="toggid11" class="togglebtn" [(ngModel)]="toggHepatFlag"
                                            ngDefaultControl [checked]="toggHepatFlag"
                                            (change)="changeHepatitis($event.source.checked)" [disabled]="client">
                                            <span class="familytab" *ngIf="!toggHepatFlag"><img class="family_toggle_img"
                                                    src="../../../assets/ui_icons/cross_mark.svg"></span>
                                            <span class="familytab" *ngIf="toggHepatFlag"><img class="family_toggle_img"
                                                    src="../../../assets/ui_icons/tick_mark.svg"></span>
                                        </mat-button-toggle>
                                    </div>
                                    <div class="col-7 margin-bottomvalue">
                                        <mat-label class="matlabel">Glaucoma</mat-label>
                                    </div>
                                    <div class="col-2 margin-bottomvalue">
                                        <mat-button-toggle id="toggid12" class="togglebtn" [(ngModel)]="toggGlaucomaFlag"
                                            ngDefaultControl [checked]="toggGlaucomaFlag"
                                            (change)="changeGlaucoma($event.source.checked)" [disabled]="client">
                                            <span class="familytab" *ngIf="!toggGlaucomaFlag"><img class="family_toggle_img"
                                                    src="../../../assets/ui_icons/cross_mark.svg"></span>
                                            <span class="familytab" *ngIf="toggGlaucomaFlag"><img class="family_toggle_img"
                                                    src="../../../assets/ui_icons/tick_mark.svg"></span>
                                        </mat-button-toggle>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-12 col-lg-12 col-md-12 col-xl-12" style="margin-top: 5px;">
                        <mat-label class="matlabel">Others<br>
                            <textarea class="habitstawidth ipcss" [(ngModel)]="familyOther">
                          </textarea>
                        </mat-label>
                    </div>
                    <div class=" col-12 ">
                        <img src="../../../assets/ui_icons/buttons/History_button.svg" class="saveimgbtn_inpatinfo pointer"
                            (click)="diseaseHistory('Disease')" style="float: right;margin-right: 33px;" />
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <p class="tervys_heading">Character of pain</p>
            </div>
            <div class="col-12">
                <div class="cover_div" style="margin-right: 36px;">
                    <div class="content_cover">
                        <div class="row">
                            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                <mat-label class="matlabel">{{doUFeelPainLabel}}<br>
                                    <select class="ipcss  " [(ngModel)]="pain" [disabled]="client"
                                        (change)="changeDoUFeelPain()" disableOptionCentering>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                <mat-label class="matlabel">{{locationLabel}}<br>
                                    <select class="ipcss  " [required]="locationRequiredFlag" disableOptionCentering
                                        [(ngModel)]="location" [disabled]="charPainDisabledFlag">
                                        <option Selected *ngFor="let painloc of locationList"
                                            value="{{painloc.location_desc}}">
                                            {{painloc.location_desc}}</option>
                                    </select>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                <mat-label class="matlabel">{{charOfPainLabel}}<br>
                                    <select class="ipcss  " [required]="charOfPainRequiredFlag" disableOptionCentering
                                        [(ngModel)]="character" [disabled]="charPainDisabledFlag">
                                        <option *ngFor="let paincar of charPainList"
                                            value="{{paincar.character_desc}}">
                                            {{paincar.character_desc}}
                                        </option>
                                    </select>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                <mat-label class="matlabel">{{radiationLabel}}<br>
                                    <select class="ipcss  " [required]="radiationRequiredFlag" disableOptionCentering
                                        [(ngModel)]="radiation" [disabled]="charPainDisabledFlag">
                                        <option *ngFor="let painrad of radiationList"
                                            value="{{painrad.radiation_desc}}">
                                            {{painrad.radiation_desc}}
                                        </option>
                                    </select>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                <mat-label class="matlabel">{{durationLabel}}<br>
                                    <select class="ipcss  " [required]="durationRequiredFlag" disableOptionCentering
                                        [(ngModel)]="duration" [disabled]="charPainDisabledFlag">
                                        <option value="&#60;30 mins">&#60;30 mins</option>
                                        <option value="&#60;60 mins">&#60;60 mins</option>
                                        <option vvalue="&#62;60 mins">&#62;60 mins</option>
                                    </select>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                <mat-label class="matlabel">{{aggravatLabel}}<br>
                                    <select class="ipcss  " [required]="aggravatRequiredFlag" disableOptionCentering
                                        [(ngModel)]="aggravat" [disabled]="charPainDisabledFlag">
                                        <option *ngFor="let painagrrel of aggravatingFactorsList"
                                            value="{{painagrrel.agrrelfact_desc}}">
                                            {{painagrrel.agrrelfact_desc}}
                                        </option>
                                    </select>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                <mat-label class="matlabel">{{relieveLabel}}<br>
                                    <select class="ipcss  " [required]="associatedSymptomRequiredFlag" disableOptionCentering
                                        [(ngModel)]="relieve" [disabled]="charPainDisabledFlag">
                                        <option *ngFor="let painagrrel of aggravatingFactorsList"
                                            value="{{painagrrel.agrrelfact_desc}}">
                                            {{painagrrel.agrrelfact_desc}}
                                        </option>
                                    </select>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                <mat-label class="matlabel">{{associatedSymptomLabel}}<br>
                                    <select class="ipcss  " [required]="assosymp_required" disableOptionCentering
                                        [(ngModel)]="assosymp" [disabled]="charPainDisabledFlag">
                                        <option *ngFor="let painasympt of painSymptomList"
                                            value="{{painasympt.asssympt_desc}}">
                                            {{painasympt.asssympt_desc}}
                                        </option>
                                    </select>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                <mat-label class="matlabel">{{intensityLabel}}<br>
                                    <select class="ipcss  " [required]="intensityRequiredFlag" disableOptionCentering
                                        [(ngModel)]="intensity" [disabled]="charPainDisabledFlag">
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                    </select>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                <mat-label class="matlabel">{{causeOfPainLabel}}<br>
                                    <select class="ipcss  " [required]="causeOfPainRequiredFlag" disableOptionCentering
                                        [(ngModel)]="paincause" [disabled]="charPainDisabledFlag">
                                        <option *ngFor="let painc of painCauseList" value="{{painc.cause_desc}}">
                                            {{painc.cause_desc}}</option>
                                    </select>
                                </mat-label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class=" col-12 ">
                <img src="../../../assets/ui_icons/buttons/history.svg" class="saveimgbtn_inpatinfo pointer" (click)="diseaseHistory('Pain')" 
                    style="float: right;margin-right: 34px;margin-top: 5px;"/>
            </div>
        </div>
    </div>
</div>