import { Component, OnInit, ElementRef, Input, ViewEncapsulation, OnDestroy, Inject } from '@angular/core';
// import { LoggingService } from '../logging.service';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr'
import { CommonDataService } from 'src/app/providers/common-data.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Data, Route, Router } from '@angular/router';
@Component({
  selector: 'app-ordercancel',
  templateUrl: './ordercancel.component.html',
  styleUrls: ['./ordercancel.component.css']
})
export class OrdercancelComponent implements OnInit {
  @Input() id: string;
  parm: string;
  public element: any;
  public cancelTxt;
  constructor(public comm_Data: CommonDataService, private el: ElementRef, toastr: ToastrService, public router: Router, public http: Http, public dialog: MatDialog, public dialogRef: MatDialogRef<OrdercancelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    dialogRef.disableClose = true;
  }
  ngOnInit(): void {
  }
  onNoClick() {
    this.dialogRef.close(this.cancelTxt);
  }
  submit_click() {
    this.dialogRef.close(this.cancelTxt);
  }
  ClosePopup() {
    this.dialogRef.close();
  }
}
