import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-solutions',
  templateUrl: './solutions.component.html',
  styleUrls: ['./solutions.component.css']
})

export class SolutionsComponent implements OnInit {
  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  hospManage() {
    this.router.navigateByUrl("/hosmanage")
  }

  diagnosis() {
    this.router.navigateByUrl("/diagnosis")
  }

  pharmacyManage() {
    this.router.navigateByUrl("/pharma_info")
  }

  clinicDecision() {
    this.router.navigateByUrl("/clinic")
  }

  nutritionManage() {
    this.router.navigateByUrl("/nutrition")
  }

  predictive() {
    this.router.navigateByUrl("/health")
  }
}
