<div *ngIf="othersClinicalFlag == 'min'">
    <div class="details">
        <mat-label class="matlabel" [hidden]="maincon_hidden">{{Maincon_label}}<br>
            <textarea class="ipcss widthappt" [(ngModel)]="Maincon_txt"
                (click)="Mainconcern_pop()"></textarea>

        </mat-label>
        <mat-label class="matlabel" [hidden]="medhist_hidden">{{medhist_label}}<br>
            <textarea class="ipcss widthappt" [(ngModel)]="past_medical"
                (click)="medical_popup()"></textarea>
            <!-- <mat-select *ngIf="medhist_flag" class="ipcss widthappt" disableOptionCentering="true" multiple>
                <button mat-raised-button id="closemedhist" (click)="closemedhist_popup()">close</button>
                <mat-option color="primary" *ngFor="let medhist of Medical_history_array" value={{medhist.label}}>
                    <mat-checkbox multiple [checked]="medhist.checked"
                        (change)="medhist_change(medhist.label,medhist.value,$event.checked)">{{medhist.label}}
                    </mat-checkbox>&nbsp;
                </mat-option>
            </mat-select> -->
        </mat-label>
        <mat-label class="matlabel" [hidden]="religious_hidden">{{Religious_label}}<br>
            <textarea class="ipcss widthappt" [(ngModel)]="religious_belief"
                (click)="religious_popup()"></textarea>
            <!-- <mat-select *ngIf="religious_flag" class="ipcss widthappt" disableOptionCentering="true" multiple>
                <button mat-raised-button id="closemedhist" (click)="closereligious_popup()">close</button>
                <mat-option color="primary" *ngFor="let religious of Religious_belief_array" value={{religious.label}}>
                    <mat-checkbox multiple [checked]="religious.checked"
                        (change)="religious_change(religious.label,religious.value,$event.checked)">{{religious.label}}
                    </mat-checkbox>&nbsp;
                </mat-option>
            </mat-select> -->
        </mat-label>
    </div><br>
</div>

<div *ngIf="othersClinicalFlag == 'max'">
    <div class="row">
        <!-- <div class="col-12">
            <div class="cover_div">
                <div class="header_lable">
                    Concerns
                </div>
                <div class="content_cover">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-5 col-lg-4 colxl-4">
                            <mat-label class="matlabel" [hidden]="maincon_hidden">{{Maincon_label}}<br>
                                <textarea [readonly]="client" class="ipcss widthappt" [(ngModel)]="Maincon_txt" required (change)="send_data()"
                                    (click)="Mainconcern_pop()"></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-5 col-lg-4 colxl-4">
                            <mat-label class="matlabel" [hidden]="medhist_hidden">{{medhist_label}}<br>
                                <textarea [readonly]="client" class="ipcss widthappt" required (change)="send_data()"
                                    [(ngModel)]="past_medical" (click)="medical_popup()"></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-5 col-lg-4 colxl-4">
                            <mat-label class="matlabel" [hidden]="religious_hidden">{{Religious_label}}<br>
                                <textarea [readonly]="client" class="ipcss widthappt" [(ngModel)]="religious_belief" (change)="send_data()"
                                    (click)="religious_popup()"></textarea>
                            </mat-label>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="col-6">
            <div class="cover_div">
                <div class="header_lable">
                    Stress
                </div>
                <div class="content_cover">
                    <div class="row">
                        <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <mat-label class="matlabel" [hidden]="feelstress_hidden">{{feelstress_label}}<br>
                                <select class="ipcss widthappt" [(ngModel)]="feelstress"
                                    (change)="stress_change();send_data();" [disabled]="client" disableOptionCentering>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </mat-label>
                        </div>
                        <div class="col-12">
                            <mat-label class="matlabel" [hidden]="stress_hidden">{{stress_label}}<br> 
                                <textarea class="ipcss widthappt" #stress1 id="stress1" maxlength="250" required  (change)="send_data()"
                                    [(ngModel)]="stress" [disabled]="stress_flag"></textarea> 
                            </mat-label>
                        </div>
                        <div class="col-12">
                            <mat-label class="matlabel" [hidden]="symptom_hidden">{{symptom_label}}<br>
                                <textarea class="ipcss widthappt" #symptom1 id="symptom1" maxlength="250" required (change)="send_data()"
                                    [(ngModel)]="symptom" [disabled]="stress_flag"></textarea>
                            </mat-label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="cover_div">
                <div class="header_lable">
                    Previous hospital details
                </div>
                <div class="content_cover">
                    <div class="row">
                        <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <mat-label class="matlabel" [hidden]="hosp_hidden">{{hosp_label}}<br>
                                <select class="ipcss widthappt" [(ngModel)]="hosp" (change)="enableHosp();send_data()"
                                    [disabled]="client" disableOptionCentering>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </mat-label>
                        </div>
                        <div class="col-12">
                            <mat-label class="matlabel" [hidden]="hospdet_hidden">{{hospdet_label}}<br>
                                <textarea class="ipcss widthappt" #prevhosp id="prevhosp1" maxlength="250" required
                                    [(ngModel)]="hospdet" [disabled]="prevhosp_flag" (change)="send_data()"></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12">
                            <mat-label class="matlabel" [hidden]="treat_hidden">{{treat_label}}<br>
                                <textarea class="ipcss widthappt" #treatment id="treatment" maxlength="250" required (change)="send_data()"
                                    [(ngModel)]="treat" [disabled]="prevhosp_flag"></textarea>
                            </mat-label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="cover_div">
                <div class="header_lable">
                    Blood Pressure
                </div>
                <div class="content_cover">
                    <div class="row" [hidden]="rightarm_hidden">
                        <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6" [hidden]="rightarm_hidden">
                            <mat-label class="matlabel">{{rightarm_label}}</mat-label>

                            <div class="row" [hidden]="rightarm_hidden">
                                <div class=" col-6 col-md-6 col-lg-3 col-xl-6" [hidden]="rightarm_hidden">
                                    <input matInput class="ipcss age1" maxlength="3" placeholder="mm Hg" (change)="send_data()"
                                        [(ngModel)]="rightarm"
                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                        [readonly]="client" />
                                    <!-- </mat-label> -->
                                </div>
                                <div class=" col-6 col-md-6 col-lg-3 col-xl-6" [hidden]="rightarm_hidden">
                                    <input matInput class="ipcss age1" maxlength="3" placeholder="mm Hg"
                                        [(ngModel)]="rightarm1" (change)="send_data()"
                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                        [readonly]="client" />
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6" [hidden]="leftarm_hidden">
                            <mat-label class="matlabel">{{leftarm_label}}<br></mat-label>

                            <div class="row" [hidden]="leftarm_hidden">
                                <div class="col-6 col-sm-4 col-md-6 col-lg-3 col-xl-6" [hidden]="leftarm_hidden">
                                    <input matInput class="ipcss age1" maxlength="3" placeholder="mm Hg"
                                        [(ngModel)]="leftarm" (change)="send_data()"
                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                        [readonly]="client" />
                                    <!-- </mat-label> -->
                                </div>
                                <div class="col-6 col-sm-4 col-md-6 col-lg-3 col-xl-6" [hidden]="leftarm_hidden">
                                    <input matInput class="ipcss age1" maxlength="3" placeholder="mm Hg"
                                        [(ngModel)]="leftarm1" (change)="send_data()"
                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                        [readonly]="client" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="cover_div">
                <div class="header_lable">
                    Blood glucose
                </div>
                <div class="content_cover">
                    <div class="row">
                        <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <mat-label class="matlabel">{{fasting_label}}<br>
                                <input matInput class="ipcss" style="width: 100%;" maxlength="3" placeholder="mm Hg"
                                    [(ngModel)]="fasting" (change)="send_data()"
                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                    [readonly]="client" />
                            </mat-label>
                        </div>
                        <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <mat-label class="matlabel">{{prandial_label}}<br>
                                <input matInput class="ipcss" style="width: 100%;" maxlength="3" placeholder="mm Hg"
                                    [(ngModel)]="prandial" (change)="send_data()"
                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                    [readonly]="client" />
                            </mat-label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="cover_div">
                <div class="header_lable">
                    Others
                </div>
                <div class="content_cover">
                    <div class="row">
                        <div class="col-12 col-md-6 col-lg-4 col-xl-3" [hidden]="cardio_hidden">
                            <mat-label class="matlabel">{{cardio_label}}<br>
                                <textarea class="ipcss" [(ngModel)]="cardio_txt" (click)="medical_popup1()" (change)="send_data()"
                                    [readonly]="client"></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4 col-xl-3" [hidden]="neuropathy_hidden">
                            <mat-label class="matlabel">{{neuropathy_label}}<br>
                                <textarea [readonly]="client" class="ipcss" [(ngModel)]="Neuropathy" (change)="send_data()"
                                    (click)="neuro()"></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4 col-xl-3" [hidden]="neuropathy_hidden">
                            <mat-label class="matlabel">{{nephropathy_label}}<br>
                                <textarea [readonly]="client" class="ipcss" [(ngModel)]="nephropathy" (change)="send_data()"
                                    (click)="neph()"></textarea>
                            </mat-label>

                        </div>
                        <div class="col-12 col-md-6 col-lg-4 col-xl-3" [hidden]="retinopathy_hidden">
                            <mat-label class="matlabel">{{retinopathy_label}}<br>
                                <textarea [readonly]="client" class="ipcss" [(ngModel)]="retino_pathy" (change)="send_data()"
                                    (click)="retino_pathy1()"></textarea>
                            </mat-label>

                        </div>
                        <div class="col-12 col-md-6 col-lg-4 col-xl-3" [hidden]="footdamage_hidden">
                            <mat-label class="matlabel">{{footdamage_label}}<br>
                                <textarea [readonly]="client" class="ipcss" [(ngModel)]="foot_damage" (change)="send_data()"
                                    (click)="footdamage()"></textarea>
                            </mat-label>


                        </div>
                        <div class="col-12 col-md-6 col-lg-4 col-xl-3" [hidden]="skincon_hidden">
                            <mat-label class="matlabel">{{skincon_label}}<br>
                                <textarea [readonly]="client" class="ipcss" [(ngModel)]="skin_txt" (change)="send_data()"
                                    (click)="skin()"></textarea>
                            </mat-label>

                        </div>
                        <div class="col-12 col-md-6 col-lg-4 col-xl-3" [hidden]="hearing_hidden">
                            <mat-label class="matlabel">{{hearing_label}}<br>
                                <textarea [readonly]="client" class="ipcss" [(ngModel)]="hearing_txt" (change)="send_data()"
                                    (click)="hear()"></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4 col-xl-3" [hidden]="mobility_hidden">
                            <mat-label class="matlabel">{{mobility_label}}<br>
                                <textarea [readonly]="client" class="ipcss" [(ngModel)]="mobility_txt" (change)="send_data()"
                                    (click)="mobi_flag1()"></textarea>
                            </mat-label>
                        </div>
                        <div class=" col-12 ">
                            <a class="right" [hidden]="clinic_histhidden" (click)="history()"><img
                                    src="../../../assets/ui_icons/buttons/History_button.svg" class="saveimgbtn_inpatinfo" style="float: right;"/></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <!-- <div class="row">
        <div class=" col-12 ">
            <a [hidden]="clinic_histhidden" (click)="history1()" class="right"><img
                    src="../../../assets/ui_icons/buttons/History_button.svg" class="saveimgbtn_inpatinfo" /></a>
        </div><br>
    </div> -->
</div>