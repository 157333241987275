import { Component, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Helper_Class } from 'src/app/helper_class';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  
})
export class HeaderComponent implements OnInit {
  public showsubmenu: boolean = false;
  public notificationCount;
  public app_type_txt: string;
  public mob_sub_menu: boolean = false;
  myLogo: string = "../../assets/images/tervys-logo.png";
  displayStyle = 'none';

  //transitionStyle= 'all 2s ease';

  toggle() {
    if (this.displayStyle === 'none') {
      this.displayStyle = 'block';
      // this.transitionStyle= 'all 2s ease';
    } else {
      this.displayStyle = 'none';
      // this.transitionStyle= 'all 2s ease';
    }

  }

  constructor(public dialog: MatDialog, public router: Router) {
  }

  isHomePage(): boolean {
    return this.router.url === '/';
  }

  ngOnInit() {

  }

  showSubmenu() {
    this.showsubmenu = !this.showsubmenu;
  }

  main_Appointments(type) {
    Helper_Class.setSearchType(type);
    Helper_Class.setTreatmentList(undefined);
    this.router.navigateByUrl("/doctorsearch");
  }

  registerBy(e) {
    if (e == 'Client') {
      this.router.navigateByUrl("/clientreg");
    } else if (e == 'Doctor') {
      this.router.navigateByUrl("/doctorRegistration");
    } else if (e == 'Diagnosis') {
      this.router.navigateByUrl("/diagnosisreg");
    } else if (e == 'Pharmacy') {
      this.router.navigateByUrl("/pharmareg");
    } else if (e == 'Physio') {
      this.router.navigateByUrl("/physioreg");
    } else if (e == 'Nurse') {
      this.router.navigateByUrl("/nursereg");
    } else if (e == 'Front Desk') {
      this.router.navigateByUrl("/frontdesk");
    } else { }
  }
}
