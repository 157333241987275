import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClientviewComponent } from './clientview/clientview.component';
import { RelationdetailsComponent } from './relationdetails/relationdetails.component';


const routes: Routes = [
    
     {path: 'clientview', component: ClientviewComponent},
     {path:'relationdetails',component:RelationdetailsComponent},

   
  //    {path:'recordwallet',component:RecordWalletNewComponent},
  //    {path:'recordwallet_medpres',component:MedicalprescriptionDetailsComponent},
  //    {path:'recordwallet_diagpres',component:DiagnosisPreslistDetailComponent},
  //    {path:'recordwallet_reportdetails',component:ReportDetailsComponent},
  //    {path:'clientapplist',component:ClientapplistComponent},
  //    {path:'clienthome',component:ClienthomeComponent},
  //    {path:'doctorappointmentdetails',component:DoctorAppointmentDetailComponent},
  //    {path:'lifestyle_personal',component:LifestylePersonalComponent},
  //    {path:'postcovid_track',component:PostcovidTrackComponent},
  //    {path:'uploaddocs',component:UploadDocumentsComponent},
  //    {path:'documentlist',component:DocumentListComponent},
  //    {path:'lifestyle_tracker',component:LifestyleTrackerComponent},
  //    {path:'diet_tracker',component:DietTrackerComponent},
  //    {path:'medicine_tracker',component:MedicineTrackComponent},
  //    {path:'secondopinionretrival',component:SecondopinionretrivalComponent},
  //    {path:'secondopinionlist',component:SecondOpinionListComponent
  //   },
  //   {path:'otcnonpres',component:OtcNonpresPageComponent},
  //   {path:'doctorlist',component:DoctorlistComponent},
  //   {path:'appnewsecondopinion',component:AppcreateComponent},
  
  //   // {path:'orderconfirm',component:OrderconfirmComponent},
  
  //    {path:'pharmacyprescription',component:PharmacyrelationprescriptiondetailsComponent},
  //    {path:'appcreate',component:AppCreateNewComponent},
  //    {path:'pharmacycurrent',component:PharmacycurrentComponent},
  //    {path:'pharmacycompleted',component:PharmacyCompletedComponent},
  //     {path:'pharmacyupdateorder', component:PharmacyupdateorderpageComponent},
  //     {path:'pharmacieslist', component:PharmacyListComponent},
  //     {path:'quantitychange', component:QuantityChangeComponent},
  //     {path:'orderconfirm', component:OrderconfirmComponent},
  //     {path:'orderconfirmpopup', component:OrderconfirmpopupComponent},
  // {path:'pharmacyorderdetailview', component: PharmacyorderComponent},
  // {path:'pharmacymoduleorderdetailview', component: PharmacymoduleOrderdetiledviewComponent},
  
  // {path:'bloodrequest', component: BloodrequestComponent},
  // {path:'donordetail',component:DonorDetailComponent},
  // {path:'updatedetails', component:DonorUpdatedetailsComponent},
  // {path:'donationhistory', component: DonationhistoryComponent},
  // {path:'donors', component: DonorsComponent},
  // {path:'eligibility_blood', component:BloodeligibilityComponent},
  // {path:'finddonors',component:FinddonorsComponent},
  // {path:'secondopinioncreate', component:SecondopinioncreateComponent},

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ClientRoutingModule { }
