import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { CommonDataService } from '../../providers/common-data.service';
import { Http, Headers } from '@angular/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from '../../helper_class';
import { Date_Formate } from '../../../assets/js/common.js';
import { Router, ActivatedRoute } from '@angular/router';
import { ClientViewService } from '../clientview/client-view.service';
import { Client_Helper } from '../Client_helper';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';

@Component({
  selector: 'app-client-bill-list',
  templateUrl: './client-bill-list.component.html',
  styleUrls: ['./client-bill-list.component.css']
})
export class ClientBillListComponent implements OnInit {

  //mdbTable
  dtOptions: DataTables.Settings = {};
  persons: any = [];

  public currentDate;
  public currentMaxDate;
  public currentYear;
  public date;
  public currentTime;
  public personsBill = [];
  public billUrl;
  public sendData;
  public filtrBillUrl;
  public searchbyDateFlag: boolean = true;
  public searchbyRelationFlag: boolean = false;
  public relationTypeList = [];
  public relationship;
  public clientRegId;
  public otherRelDetUrl;
  public relationFlag: Boolean = false;
  public relationList;
  public relationDetails = [];
  public relationName;
  public billingType;
  public subId;
  public relID;
  constructor(public clientservice: ClientViewService, public datepipe: DatePipe, public gservice: CommonDataService, public http: Http, public routes: ActivatedRoute, public router: Router, public toastr: ToastrService) {
    this.billUrl = ipaddress.getIp + "bill/gbills";
    this.filtrBillUrl = ipaddress.getIp + "bill/filtbl";
    this.otherRelDetUrl = ipaddress.getIp + "appointment/clnrelweb";
    this.searchbyRelationFlag = true;
    this.searchbyDateFlag = false;
  }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 25,
      "lengthMenu": [[25, 50, -1], [25, 50, "All"]],
      "columnDefs": [
        { "orderable": false, "targets": [0, 1, 2, 3] },
      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search bill no, date or name "
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    this.billingType = "doctor";
    if (this.gservice.Client_login_detail_data.Client_Id != undefined) {
      this.sendData = {
        user_id: this.gservice.Client_login_detail_data.Client_Id,
        type: "client",
        relation_id: "all",
        flag : this.billingType
      }
    }
    this.getRelationList();
    this.getCurrentDate();
  }

  getCurrentDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate',
      JSON.stringify({
        country: ipaddress.country_code
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          this.currentDate = obj.current_date;
          var get_date = obj.current_date.split('-');
          this.currentYear = get_date[0];
          this.currentTime = obj.current_time;
          this.currentMaxDate = obj.current_date;
          this.date = this.currentDate;
        },error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }

  getRelationList() { // Get Relation
    this.clientRegId = {
      client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "usercontroller/grels/", this.clientRegId, { headers: headers }).subscribe(
      data => {
        var obj = data.json();
        if (obj.relationships != null && obj.relationships != undefined) {
          this.relationTypeList = obj.relationships;
          Helper_Class.setrelation(this.relationTypeList);
          // Default self 
          this.relationship = obj.relationships[0].relation_id;
          this.relationDetail(obj.relationships[0].relation_id, obj.relationships[0].relationship_name);
        }
        this.sendData = {
          user_id: this.gservice.Client_login_detail_data.Client_Id,
          type: "client",
          relation_id: obj.relationships[0].relation_id,
          flag : this.billingType
        }
        this.getBillList(this.billUrl);
      },error => {
        this.toastr.error(Message_data.unableToFetchData);
      });
  }

  getBillList(url) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/Json');
    this.http.post(url, JSON.stringify(this.sendData), { headers: headers }).subscribe(
      data => {
        this.personsBill = [];
        var obj = data.json();
        var bill_listdata = obj.bills;
        this.personsBill = [];
        if (bill_listdata != null && bill_listdata.length != 0) {
          for (var i = 0; i < bill_listdata.length; i++) {
            var bill_no = "";
            var date = "";
            var speciali_name = "";
            var doctor_id = "";
            var total_amt = "";
            var client_name = "";
            if (bill_listdata[i].bill_id != null) 
              bill_no = bill_listdata[i].bill_id;
            if (bill_listdata[i].date != null) 
              date = bill_listdata[i].date.split('-')[2] + "-" + bill_listdata[i].date.split('-')[1] + "-" + bill_listdata[i].date.split('-')[0];

            if (bill_listdata[i].specialization_name != null) 
              speciali_name = bill_listdata[i].specialization_name;

            if (bill_listdata[i].doc_reg_id != null) 
              doctor_id = bill_listdata[i].doc_reg_id;

            if (bill_listdata[i].amount != null) 
              total_amt = encrypt_decript.Decript(bill_listdata[i].amount);

            total_amt = total_amt != "NaN" ? total_amt : "";

            if (bill_listdata[i].middle_name != null) 
              client_name = encrypt_decript.Decript(bill_listdata[i].first_name.toString()) + ' ' + encrypt_decript.Decript(bill_listdata[i].middle_name.toString()) + ' ' + encrypt_decript.Decript(bill_listdata[i].last_name.toString());
            else 
              client_name = encrypt_decript.Decript(bill_listdata[i].first_name.toString()) + ' ' + encrypt_decript.Decript(bill_listdata[i].last_name.toString());

            this.personsBill.push({
              billno: bill_no,
              amount: total_amt,
              clientname: client_name,
              date: date,
              specialization_name: speciali_name,
              doc_reg_id: doctor_id,
              fin_year:bill_listdata[i].fin_year,
            });
          }
        }
        console.log("CLIENT BILLS "+JSON.stringify(this.personsBill))
      },error => {
        this.toastr.error(Message_data.unableToFetchBillDet);
      });
  }

  viewBill(bill_id, docid, splname, fin_year) {
    Client_Helper.setclientviewbilldetail(null);
    var list = [];
    list.push({
      bill_view_id: bill_id,
      doc_reg_id: docid,
      spl_name: splname,
      fin_year:fin_year,
    })
    Client_Helper.setclientviewbilldetail(list);
    this.clientservice.sendMessage("clientbilldetailview");
  }

  selectDate(selected_date) {
    var data = (selected_date);
    this.sendData = {
      type: "client",
      provider_id: this.gservice.Client_login_detail_data.Client_Id,
      filter: "date",
      filter_value: data,
      flag : this.billingType
    }
    this.getBillList(this.filtrBillUrl);
  }

  getSltRelationType(relation_id) {
    for (var i = 0; i < this.relationTypeList.length; i++) {
      if (relation_id == this.relationTypeList[i].relation_id) {
        if (this.relationTypeList[i].relationship_name == "Self") {
          this.relationFlag = false;
          this.sendData = {
            user_id: this.gservice.Client_login_detail_data.Client_Id,
            type: "client",
            relation_id: relation_id,
            flag : this.billingType
          }
        //  this.getBillList(this.billUrl);
        }
        else {
          this.relationFlag = true;
          this.relationDetail(relation_id, this.relationTypeList[i].relationship_name);
        }
      }
    }
  }

  relationDetail(relation_id_1, relation_name_1) {
    this.relationDetails = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.otherRelDetUrl,
      JSON.stringify({
        client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
        country: ipaddress.country_code,
        relation_id: relation_id_1,
        relationship: relation_name_1
      }), { headers: headers }).subscribe(
        data => {
          var obj = data.json();

          this.relationList = obj.rel_details;
          var subRelationName = "", subRelId;
          if (this.relationList.length != 0 && this.relationList != undefined) {
            for (var i = 0; i < this.relationList.length; i++) {
              if (this.relationList[i].middle_name != undefined && this.relationList[i].middle_name != "") {
                subRelationName = this.relationList[i].first_name + " " + this.relationList[i].middle_name + " " + this.relationList[i].last_name;
              }
              else {
                subRelationName = this.relationList[i].first_name + " " + this.relationList[i].last_name;
              }
              subRelId = this.relationList[i].cln_rel_id

              this.relationDetails.push({
                relation_id: relation_id_1,
                relation_name: subRelationName,
                sub_relation_id: this.relationList[i].cln_rel_id
              });

            }
          }
        },
        error => {

        }
      );
  }

  relativeChange(rel_name, sub_rel_id) {
    this.subId = sub_rel_id;
    this.relID = rel_name;
    
    this.sendData = {
      user_id: this.gservice.Client_login_detail_data.Client_Id,
      type: "client",
      relation_id: rel_name,
      sub_rel_id: sub_rel_id,
      flag : this.billingType
    }
    this.getBillList(this.billUrl);
  }

  // callback...
  myCallbackFunction = function (_params) {
    return new Promise((resolve, reject) => {
      resolve(_params);
    });
  }

  selectRelOrDate(e) {
    if (e == "Date") {
      this.searchbyDateFlag = true;
      this.searchbyRelationFlag = false;
      this.relationFlag=false;
      this.selectDate(this.currentDate);
      this.date = this.currentDate;
    } else {
      this.searchbyRelationFlag = true;
      this.searchbyDateFlag = false;
    }
  }
  billType(action){
     
    console.log("billingType" + this.billingType)
    if (action == "Date") {
      this.searchbyDateFlag = true;
      this.searchbyRelationFlag = false;
      this.relationFlag=false;
      var data = this.date;
      this.sendData = {
        type: "client",
        user_id: this.gservice.Client_login_detail_data.Client_Id,
        filter: "date",
        relation_id: "all",
        filter_value: data,
        flag : this.billingType
      }
      this.date = this.currentDate;
    } else if (action == "Relation") {
      this.searchbyRelationFlag = true;
      this.searchbyDateFlag = false;
      this.relationship = "all";
      this.sendData = {
        user_id: this.gservice.Client_login_detail_data.Client_Id,
        type: "client",
        relation_id: "all",
        flag : this.billingType
      }
    } else if(action ==  "date"){
      var data = this.date;
      this.sendData = {
        type: "client",
        user_id: this.gservice.Client_login_detail_data.Client_Id,
        filter: "date",
        relation_id: "all",
        filter_value: data,
        flag : this.billingType
      }
    } else if ( action == "relative" ){
      this.relID = this.relationDetails.filter((r) => {  return this.relationName == r.relation_name }).map((m) => { return m.relation_id});
      this.subId = this.relationDetails.filter((r) => {  return this.relationName == r.relation_name }).map((m) => { return m.sub_relation_id});
      this.sendData = {
        user_id: this.gservice.Client_login_detail_data.Client_Id,
        type: "client",
        relation_id: this.relID[0],
        sub_rel_id: this.subId[0],
        flag : this.billingType
      }
    }else if ( action == "billType" ){
      this.sendData.flag = this.billingType;
    } else {
      this.getBillList(this.billUrl);
    }
    console.log("sendData" + JSON.stringify(this.sendData))
  }
}
