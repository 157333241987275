import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Helper_Class } from '../../helper_class';
import { ToastrService } from 'ngx-toastr';
import { Http, Headers } from '@angular/http';
import { ActivatedRoute, Router } from '@angular/router';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { CommonDataService } from '../../providers/common-data.service';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  public Old_Password;
  public New_Password;
  public Conform_Password;
  public clientinfo;
  public clientname;
  public profile_image;
  public userinfo;
  public provider;
  public type;

  constructor(public dialogRef: MatDialogRef<ChangePasswordComponent>,public toastr:ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any,public gservice:CommonDataService,public http:Http,public routes:ActivatedRoute,public router:Router) {
      dialogRef.disableClose = true;
      this.type = data.type;
      this.clientinfo = Helper_Class.getInfo();
      this.clientname = encrypt_decript.Decript(this.clientinfo.first_name)
      this.profile_image = ipaddress.Ip_with_img_address + this.clientinfo.profile_image;
      
  }

  ngOnInit(): void {
    this.userinfo = Helper_Class.getInfo();
    // if(this.type == "client")
    //   this.provider = this.type;
    // else  
    //   this.provider = this.userinfo.provider;
  
  }

  Change_password_confirm() { //Change password
    var flag = true;

    if (this.Old_Password == undefined || this.New_Password == undefined || this.Conform_Password == undefined) {
      this.toastr.error(Message_data.mandatory)
      flag = false;
    }

    if(this.New_Password != this.Conform_Password){
      this.toastr.error(Message_data.confirmPWD);
      flag = false;
    }

    if (flag == true) {
      var curpass;
      var new_pass;

      if(this.type == "client"){
        curpass = this.Old_Password;
        new_pass = this.New_Password;
      } else {
        curpass = encrypt_decript.Encript(this.Old_Password).toString();
        new_pass = encrypt_decript.Encript(this.New_Password).toString();
      }

      var reg_id;
      if(this.type == "client")
        reg_id = this.userinfo.client;
      else  
        reg_id = this.userinfo.user_id;

      var send_data = {
        reg_id: reg_id,
        curr_pass: curpass,
        new_pass: new_pass,
        flag: this.type
      }

      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'usercontroller/cngpass/',
        JSON.stringify(send_data),
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            if (obj.result == "Password changed successfully") {
              this.toastr.success(Message_data.passchange);
              var headers = new Headers();
              headers.append('Content-Type', 'application/json');
              this.http.post(ipaddress.getIp.toString() + 'usercontroller/logout/',
                JSON.stringify({
                  type: "doctor",
                  user: this.userinfo.user_id
                }),
                { headers: headers })
                .subscribe(
                  response => {
                    this.toastr.success(Message_data.logOut)
                    localStorage.clear();
                    this.router.navigateByUrl('/loginpage');
                  },error => {
                    this.toastr.error(Message_data.network);
                  });
              this.dialogRef.close();
;            } else if (obj.result == "Please check current password") {
              this.toastr.error(obj.result); 
              this.dialogRef.close(); 
            } else {
              this.toastr.error(Message_data.passunchange);
            }
          },error => {
            this.toastr.error(Message_data.defaultErr);
          });
    }
  }
  
  Change_password_cancel() {//Cancel password
    this.dialogRef.close();
  }

  onNoClick(){
    this.dialogRef.close();
  }
}
