import { Component, OnInit, ElementRef, ViewChild, } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { Helper_Class } from '../../helper_class';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { customjs } from '../../../assets/js/custom';
import { Subscription } from 'rxjs';
import { ipaddress } from '../../ipaddress';
import { ChangePasswordComponent } from '../../common-module/change-password/change-password.component';
import { MatDialog } from '@angular/material/dialog';
import { PhysioService } from './physio.service';
import { Physio_Helper } from '../Physio_Helper';
import { Message_data } from 'src/assets/js/Message_data';
declare var $: any;

@Component({
  selector: 'app-physio',
  templateUrl: './physio.component.html',
  styleUrls: ['./physio.component.scss']
})
export class PhysioComponent implements OnInit {
  @ViewChild('asied_section_width', { static: false }) asied_width: ElementRef;
  @ViewChild('right_section_width', { static: false }) right_width: ElementRef;
  @ViewChild('logo', { static: false }) logo: ElementRef;

  public hospitalLogo;
  public hospitalName;
  public userInfo;
  public hospitalDetails;
  public profileImage;
  public firstName;
  public lastName;
  public settings: boolean = false;
  public minimizedAsied: boolean = false;
  public selectedMenu;
  public displayPage;
  public eventIconFlag: boolean;
  public homecareFlag: boolean;
  public currentDate = null;
  public isAdmin: boolean;
  public walkinFlag: boolean;
  subscription: Subscription;
  public reference_video: boolean = true;
  constructor(public gservice: CommonDataService, public http: Http, public routes: ActivatedRoute, public router: Router, public toastr: ToastrService, public dialog: MatDialog, public messageservice: PhysioService) {
    this.appointments("app");
    this.menuNav("appointment");
  }

  ngOnInit(): void {
    this.subscription = this.messageservice.getMessage().subscribe(message => {
      if (message) {
        this.menuNav(message);
      }
    });
    this.userInfo = Helper_Class.getInfo();
    this.firstName = this.userInfo.first_name;
    this.lastName = this.userInfo.last_name;
    this.profileImage = ipaddress.Ip_with_img_address + this.userInfo.profile_image;
    this.hospitalDetails = this.userInfo.hospitals;
    this.hospitalName = this.hospitalDetails[0].hptl_name;
    this.hospitalLogo = ipaddress.Ip_with_img_address + this.hospitalDetails[0].logo;
    this.minimizedAsied = false;
    var module_list = [];
    var Moduleidlist = [];
    if (Physio_Helper.getModuleList() != null) {
      Moduleidlist = Physio_Helper.getModuleList();
      this.homecareFlag = true;
      this.eventIconFlag = true;
      this.isAdmin = true;
      this.walkinFlag = false;
      if (this.userInfo.admin == "1") {
        this.isAdmin = false;
      }
      for (var i = 0; i < Moduleidlist.length; i++) {
        if (Moduleidlist[i] == "35") {
          this.homecareFlag = false;
        } else if (Moduleidlist[i] == "21") {
          this.eventIconFlag = false;
        } else if (Moduleidlist[i] == "46") {
          this.reference_video = false;
        }
      }
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate',
      JSON.stringify({
        country: ipaddress.country_code.toString()
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.current_date != null) {
            this.currentDate = obj.current_date;
          }
        }, error => { });
  }

  appointments(data) {
    if (data == "home")
      Physio_Helper.setHomecare("1");
    else
      Physio_Helper.setHomecare("0");
  }

  logout(name) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'usercontroller/logout/',
      JSON.stringify({
        type: "diagnosis",
        user: this.userInfo.user_id
      }),
      { headers: headers })
      .subscribe(
        response => {
          this.toastr.success(Message_data.logOut + name)
          localStorage.clear();
          this.router.navigateByUrl('/loginpage');
        }, error => {
          this.toastr.error(Message_data.network);
        });
  }

  menuNav(page) {
    let page_nmae: string = page;
    this.selectedMenu = page;
    switch (page_nmae) {
      case 'appointment': this.displayPage = page; break;
      case 'casesheet': this.displayPage = page; break;
      case 'assignmentList': this.displayPage = page; break;
      case 'assignmentDetailPage': this.displayPage = page; break;
      case 'therapies': this.displayPage = page; break;
      case 'userList': this.displayPage = page; break;
      case 'userViewPage': this.displayPage = page; break;
      case 'Home': this.displayPage = page; break;
      case 'billing': this.displayPage = page; break;
      case 'walkin': this.displayPage = page; break;
      case 'billdetails': this.displayPage = page; break;
      case 'billCreate': this.displayPage = page; break;
    }
    customjs.SelectMenuFun(this.selectedMenu, "diag");
  }

  minimizing() {
    this.minimizedAsied = !this.minimizedAsied;
    if (this.minimizedAsied == true) {
      this.asied_width.nativeElement.setAttribute('style', 'width:3%; transition: all 0.3s ease-in-out;animation-delay: 15s;');
      this.right_width.nativeElement.setAttribute('style', 'width:97%;margin-left: 3%; transition: all 0.3s ease-in-out;animation-delay: 15s;');
      this.logo.nativeElement.setAttribute('style', 'padding: 12px 0 0 3px;}');
    } else {
      this.asied_width.nativeElement.setAttribute('style', 'width:17%; transition: all 0.3s ease-in-out;animation-delay: 15s;');
      this.right_width.nativeElement.setAttribute('style', 'width:83%;margin-left: 17%; transition: all 0.3s ease-in-out;animation-delay: 15s;');
      this.logo.nativeElement.setAttribute('style', 'padding: 17px 0 0 14px;}');
    }
  }

  onResize(event) {
    this.asied_width.nativeElement.removeAttribute('style');
    this.right_width.nativeElement.removeAttribute('style');
    this.logo.nativeElement.removeAttribute('style');
    this.minimizedAsied = false;
  }

  openMenumodel() {
    this.minimizedAsied = false;
    customjs.openMobileMenuFun();
  }

  menuModelAction(action) {
    if (action == 'profile') {
      this.menuNav(action);
    } else if (action == 'changePassword') {
      const dialogRef = this.dialog.open(ChangePasswordComponent, {
        width: '33%',
        height: '275px',
        data: {}
      });
    } else {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'usercontroller/logout/',
        JSON.stringify({
          type: "diagnosis",
          user: this.userInfo.user_id
        }),
        { headers: headers })
        .subscribe(
          response => {
            this.toastr.success(Message_data.logOut)
            localStorage.clear();
            this.router.navigateByUrl('/loginpage');
          }, error => {
            this.toastr.error(Message_data.network);
          });
    }
    $('#exampleModal').modal('hide');
  }
}
