<div *ngIf="vitalFlag == 'min'">
    <div class="row">
        <div  class="col-12">
            <mat-label class="matlabel">Height<br>
                <input [readonly]="client" matInput class="ipcss " (change)="calculateCalories()" required
                    [(ngModel)]="height">
            </mat-label>
        </div>
        <div class="col-12">
            <mat-label class="matlabel" >Measure<br>
                <select [disabled]="client" class="ipcss " (change)="calculateCalories()" [(ngModel)]="heightMeasure"
                    disableOptionCentering>
                    <option value="cms">cms</option>
                    <option value="inch">inch</option>
                </select>
            </mat-label>
        </div>
        <div  class="col-12">
            <mat-label class="matlabel">Weight<br>
                <input [readonly]="client" matInput class="ipcss " (change)="calculateCalories()" required
                    [(ngModel)]="weight">
            </mat-label>
        </div>
        <div  class="col-12">
            <mat-label class="matlabel" >Measure<br>
                <select [disabled]="client" class="ipcss " [(ngModel)]="weightMeasure" disableOptionCentering>
                    <option value="kgs">kgs</option>
                    <option value="lbs">lbs</option>
                </select>
            </mat-label>
        </div>
    </div>
</div>
<div *ngIf="vitalFlag == 'max'">
    <a (click)="save()"><span class="cardsave"><img src="../../../assets/img/save.svg" style="width:20px"
        style="width:25px" /></span> </a>
    <div class="cover_div">
        <div class="content_cover">
            <div class="row">
                <div  class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                    <div class="row">
                        <div  class="col-6">
                            <mat-label class="matlabel">Height<br>
                                <input [readonly]="client" matInput class="ipcss " (change)="calculateCalories()" required
                                    [(ngModel)]="height">
                            </mat-label>
                        </div>
                        <div  class="col-6">
                            <mat-label class="matlabel" >Measure<br>
                                <select [disabled]="client" class="ipcss " (change)="calculateCalories()"
                                    [(ngModel)]="heightMeasure" disableOptionCentering>
                                    <option value="cms"> cms</option>
                                    <option value="inch">inch</option>
                                </select>
                            </mat-label>
                        </div>
                    </div>
                </div>
                <div  class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                    <div class="row">
                        <div class="col-6">
                            <mat-label class="matlabel" >Weight<br>
                                <input [readonly]="client" matInput class="ipcss " (change)="calculateCalories()" required
                                    [(ngModel)]="weight">
                            </mat-label>
                        </div>
                        <div  class="col-6">
                            <mat-label class="matlabel" >Measure<br>
                                <select [disabled]="client" class="ipcss " [(ngModel)]="weightMeasure"
                                    disableOptionCentering (change)="calculateCalories()">
                                    <option value="kgs">kgs</option>
                                    <option value="lbs">lbs</option>
                                </select>
                            </mat-label>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                    <mat-label class="matlabel">BMI
                        <input class="ipcss bmi_lenght" matInput type="text" [readonly]="client" [(ngModel)]="bmi"
                            [readonly]="client" />
                    </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                    <mat-label class="matlabel">BMR
                        <input class="ipcss" style="width: 100%;" matInput [readonly]="client" [(ngModel)]="bmr"
                            [readonly]="client" />
                    </mat-label>
                </div>
                <div  class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                    <mat-label class="matlabel" >Temperature<br>
                        <input [readonly]="client" matInput class="ipcss " [(ngModel)]="temperature">
                    </mat-label>
                </div>
                <div  class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                    <mat-label class="matlabel" >Blood Pressure<br>
                        <div class="row">
                            <div class="col-6">
                                <input [readonly]="client" matInput class="ipcss " placeholder="mm Hg" maxlength="3"
                                    [(ngModel)]="BP01"
                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                            </div>
                            <div class="col-6">
                                <input [readonly]="client" matInput class="ipcss " placeholder="mm Hg" maxlength="3"
                                    [(ngModel)]="BP02"
                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                            </div>
                        </div>
                    </mat-label>
                </div>
                <div  class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                    <mat-label class="matlabel" >Heart rate<br>
                        <input [readonly]="client" matInput class="ipcss " placeholder="Pulse" [(ngModel)]="heartRate">
                    </mat-label>
                </div>
            </div>
        </div>
    </div>
</div>