<div class="row">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white" style="padding:7px 20px;">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Profile Information</h5>
          </div>
          <div class="headerButtons">
            <img src="../../../assets/ui_icons/buttons/update_button.svg" (click)="update()"
              class="saveimgbtn_inpatinfo" />
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row" style="margin: 0px;">
          <div class="Details">
            <mat-accordion class="mataccordion">
              <mat-expansion-panel class="expandpanel " [expanded]="true">
                <mat-expansion-panel-header class="exppanel width">
                  <mat-panel-title class="title">
                    Personal
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <hr class="hr">
                <div class="row" style="margin: 30px 0;">
                  <div class="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-2"
                    style="text-align: center;position: relative;">
                    <div style="position: relative;width: 160px;margin: 0 auto;">
                      <img id="profile_img1" class="profileimg" src="../../assets/img/default.jpg" />
                      <input type="file" (change)="fileChange($event)" name="profile_pic" id="profile_pic">
                      <label for="profile_pic" class="edit_profile_icon"><i class="fas fa-pencil-alt"></i></label>
                    </div>
                  </div>
                  <div class="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-10">
                    <div class="row">
                      <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                        <mat-label class="matlabel" style="margin-top:7px">User id
                          <input type="text" class="ipcss " disabled [(ngModel)]="clientPersonalList.client_id"
                            matInput />
                        </mat-label>
                      </div>
                      <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                        <mat-label class="matlabel" style="margin-top:7px">First Name
                          <input type="text" class="ipcss " [readonly]="retrivalFlag" (onfocus)="playText('First name')"
                            (blur)="fnameToUpper()" maxlength="50" [(ngModel)]="clientPersonalList.first_name"
                            matInput />
                        </mat-label>
                      </div>
                      <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                        <mat-label class="matlabel" style="margin-top:7px">Middle Name(optional)
                          <input type="text" class="ipcss " [readonly]="retrivalFlag" maxlength="50"
                            (blur)="mnameToUpper()" [(ngModel)]="clientPersonalList.middle_name" matInput />
                        </mat-label>
                      </div>
                      <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                        <mat-label class="matlabel" style="margin-top:7px">Last Name
                          <input type="text" class="ipcss " [readonly]="retrivalFlag" maxlength="50"
                            (blur)="lnameToUpper()" (onFocus)="playText('Last name')"
                            [(ngModel)]="clientPersonalList.last_name" matInput />
                        </mat-label>
                      </div>
                      <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                        <mat-label class="matlabel">Gender<br>
                          <mat-select disableOptionCentering class="ipcss " [(ngModel)]="clientPersonalList.gender">
                            <mat-option value="Male">Male</mat-option>
                            <mat-option value="Female">Female</mat-option>
                            <mat-option value="Transgender">Transgender</mat-option>
                          </mat-select>
                        </mat-label>
                      </div>
                      <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                        <mat-label class="matlabel">DOB
                          <input type="date" style="font-size: 11px;" class="ipcss "
                            (change)="selectDOB('clientPersonalList.doB')" [(ngModel)]="clientPersonalList.doB"
                            matInput>
                        </mat-label>
                      </div>
                      <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                        <mat-label class="matlabel">Occupation
                          <input type="text" class="ipcss " [(ngModel)]="clientPersonalList.Occupation" maxlength="50"
                            matInput />
                        </mat-label>
                      </div>
                      <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                        <mat-label class="matlabel">Email
                          <input type="text" class="ipcss " [(ngModel)]="clientPersonalList.email" maxlength="50"
                            (onFocus)="playText('Email address')" matInput />
                        </mat-label>
                      </div>
                      <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                        <mat-label class="matlabel">Mobile Number
                          <input type="text" class="ipcss " [(ngModel)]="clientPersonalList.mobile" maxlength="10"
                            type="text" (onFocus)="playText('Mobile number')" pattern="[0-9]*" required matInput />
                        </mat-label>
                      </div>
                      <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                        <mat-label class="matlabel">Password
                          <input class="ipcss " [(ngModel)]="clientPersonalList.password" type="password" maxlength="15"
                            (onFocus)="playText('Password')" matInput />
                        </mat-label>
                      </div>
                      <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                        <mat-label class="matlabel">Confirm Password
                          <input class="ipcss " [(ngModel)]="clientPersonalList.con_password" type="password"
                            maxlength="15" (onFocus)="playText('Confirm password')" matInput />
                        </mat-label>
                      </div>
                    </div>
                  </div>
                </div>
                <mat-icon class="updown" *ngIf="!downArrowFlag" (click)="openCloseArrow()">arrow_circle_down</mat-icon>
                <label *ngIf="!downArrowFlag" class="optional">Optional</label>
                <mat-icon class="updown" *ngIf="downArrowFlag" (click)="openCloseArrow()">arrow_circle_up</mat-icon>
                <div class="row" *ngIf="downArrowFlag">
                  <div class="col-12 col-sm-6 col-md-2 col-lg-2 col-xl-2">
                    <mat-label class="matlabel">Blood Group<br>
                      <mat-select disableOptionCentering class="ipcss " [(ngModel)]="clientPersonalList.bloodGroup">
                        <mat-option disabled>Select</mat-option>
                        <mat-option *ngFor="let blood of bloodGrpList" [value]="blood">{{blood.description}}
                        </mat-option>
                      </mat-select>
                    </mat-label>
                  </div>
                  <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                    <mat-label class="matlabel">Education<br>
                      <input type="text" class="ipcss " maxlength="12" [(ngModel)]="clientPersonalList.education"
                        matInput />
                    </mat-label>
                  </div>
                  <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                    <mat-label class="matlabel">Primary Language<br>
                      <mat-select class="ipcss " [(ngModel)]="clientPersonalList.language">
                        <mat-option disabled>Select</mat-option>
                        <mat-option *ngFor="let language of languageList" [value]="language">
                          {{language.description}}
                        </mat-option>
                      </mat-select>
                    </mat-label>
                  </div>
                  <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                    <mat-label class="matlabel">Mothers Name<br>
                      <input type="text" class="ipcss " [(ngModel)]="clientPersonalList.motherName" maxlength="15"
                        myNumberOnly matInput />
                    </mat-label>
                  </div>
                  <div class="col-12 col-sm-6 col-md-2 col-lg-2 col-xl-2">
                    <mat-label class="matlabel">Martial Status<br>
                      <mat-select disableOptionCentering class="ipcss " [(ngModel)]="clientPersonalList.marital_status">
                        <mat-option disabled>Select</mat-option>
                        <mat-option *ngFor="let marstatus of maritalStatusList" [value]="marstatus">{{marstatus}}
                        </mat-option>
                      </mat-select>
                    </mat-label>
                  </div>
                  <div
                    *ngIf="clientPersonalList.marital_status == 'Married' || clientPersonalList.marital_status == 'Widowed'"
                    class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                    <mat-label class="matlabel">
                      Spouse name<br>
                      <input type="text" class="ipcss " [(ngModel)]="clientPersonalList.spouseName" maxlength="50"
                        required matInput />
                    </mat-label>
                  </div>
                  <div class="col-12 col-sm-6 col-md-2 col-lg-2 col-xl-2">
                    <mat-label class="matlabel">Citizenship<br>
                      <input type="text" class="ipcss " [(ngModel)]="clientPersonalList.citizenShip" maxlength="50"
                        required matInput />
                    </mat-label>
                  </div>
                  <div class="col-12 col-sm-6 col-md-2 col-lg-2 col-xl-2">
                    <mat-label class="matlabel">Nationality<br>
                      <input type="text" class="ipcss " [(ngModel)]="clientPersonalList.nationality" maxlength="50"
                        required matInput />
                    </mat-label>
                  </div>
                  <div class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-4">
                    <mat-label class="matlabel paddingtop">Willing To Donate Blood
                      <button mat-button class="matbutton" (click)="selectBloodDonate()">
                        <mat-label *ngIf="bloodFlag" class="donateblood_yes"
                          [(ngModel)]="clientPersonalList.blood_donation">Yes</mat-label>
                        <mat-label *ngIf="!bloodFlag" class="donateblood_no"
                          [(ngModel)]="clientPersonalList.blood_donation">No</mat-label>
                      </button>
                    </mat-label>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion><br>
            <mat-accordion class="mataccordion">
              <mat-expansion-panel class="expandpanel">
                <mat-expansion-panel-header class="exppanel width">
                  <mat-panel-title class="title">
                    Address
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <hr class="hr">
                <div class="row" style="padding-top: 20px;">
                  <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                    <mat-label class="matlabel" style="margin-top:7px">Address 1<br>
                      <input type="text" class="ipcss " matInput [(ngModel)]="clntPresentAddress.address1"
                        maxlength="50" required>
                    </mat-label>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                    <mat-label class="matlabel" style="margin-top:7px">Address 2<br>
                      <input type="text" class="ipcss " matInput [(ngModel)]="clntPresentAddress.address2"
                        maxlength="50" />
                    </mat-label>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                    <mat-label class="matlabel" style="margin-top:7px">Location<br>
                      <input type="text" class="ipcss " required [(ngModel)]="clntPresentAddress.location"
                        maxlength="50" (keyup)="locationChange($event, '0')"
                        (ngModelChange)="locationChange($event, '0')" matInput [matAutocomplete]="auto" />
                      <mat-autocomplete #auto="matAutocomplete">
                        <mat-option (click)="selectedLocation(location, '0')"
                          *ngFor="let location of presentLocationList" [value]="location">
                          {{location}}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-label>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                    <mat-label class="matlabel" style="margin-top:7px">City<br>
                      <mat-select disableOptionCentering class="ipcss " required [(ngModel)]="clntPresentAddress.city"
                        (ngModelChange)="cityChange(clntPresentAddress.city.city_id, '0','0')">
                        <mat-option disabled>Select</mat-option>
                        <mat-option *ngFor="let city of presentCityList" [value]="city">{{city.city_desc}}
                        </mat-option>
                      </mat-select>
                    </mat-label>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                    <mat-label class="matlabel">State<br>
                      <mat-select disableOptionCentering class="ipcss " required [(ngModel)]="clntPresentAddress.state"
                        (ngModelChange)="stateChange($event, '0','0')">
                        <mat-option disabled>Select</mat-option>
                        <mat-option *ngFor="let state of presentStateList" [value]="state">{{state.state_desc}}
                        </mat-option>
                      </mat-select>
                    </mat-label>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                    <mat-label class="matlabel">Country<br>
                      <mat-select disableOptionCentering class="ipcss " [(ngModel)]="clntPresentAddress.country">
                        <mat-option disabled>Select</mat-option>
                        <mat-option *ngFor="let country of presentCountryList" [value]="country">
                          {{country.country_desc}}
                        </mat-option>
                      </mat-select>
                    </mat-label>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                    <mat-label class="matlabel">Zipcode<br>
                      <input id="zipcode" class="ipcss " disabled="true" maxlength="6"
                        [(ngModel)]="clntPresentAddress.zipcode" matInput />
                    </mat-label>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                    <mat-label class="matlabel">Telephone Business<br>
                      <input type="text" maxlength="14" class="ipcss " matInput
                        [(ngModel)]="clntPresentAddress.tel_business" type="number" pattern="[0-9]*"
                        onKeyPress="if(this.value.length==14) return false;" />
                    </mat-label>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                    <mat-label class="matlabel">Telephone home<br>
                      <input type="text" maxlength="14" class="ipcss " matInput
                        [(ngModel)]="clntPresentAddress.tel_home" type="number" pattern="[0-9]*"
                        onKeyPress="if(this.value.length==14) return false;" />
                    </mat-label>
                  </div>
                  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <mat-label class="matlabel wt">
                      <mat-checkbox color=primary labelPosition="before" [(ngModel)]="sameasFlag" [checked]="sameasFlag"
                        (change)="sameAddress($event.checked)"><span style="font-weight: 500 !important;">Permanent
                          Address</span></mat-checkbox>
                    </mat-label>
                    <label class="same matlabel">same as above</label>
                  </div>
                  <div *ngIf="!sameasFlag" class="row" style="padding-top: 5px;">
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                      <mat-label class="matlabel">Address 1<br>
                        <input type="text" class="ipcss " [(ngModel)]="clntPermenantAddress.address1" maxlength="50"
                          required matInput />
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                      <mat-label class="matlabel">Address 2<br>
                        <input type="text" class="ipcss " [(ngModel)]="clntPermenantAddress.address2" maxlength="50"
                          matInput />
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                      <mat-label class="matlabel">Location<br>
                        <input type="text" class="ipcss " required maxlength="50"
                          [(ngModel)]="clntPermenantAddress.location" (ngModelChange)="locationChange($event, '1')"
                          matInput [matAutocomplete]="auto" />
                        <mat-autocomplete #auto="matAutocomplete">
                          <mat-option (click)="selectedLocation(location, '1')"
                            *ngFor="let location of permenantLocationList" [value]="location">
                            {{location}}
                          </mat-option>
                        </mat-autocomplete>
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                      <mat-label class="matlabel">City<br>
                        <mat-select disableOptionCentering class="ipcss " required
                          [(ngModel)]="clntPermenantAddress.city"
                          (ngModelChange)="cityChange(clntPermenantAddress.city.city_id, '1','1')">
                          <mat-option disabled>Select</mat-option>
                          <mat-option *ngFor="let city of permenantCityList" [value]="city">{{city.city_desc}}
                          </mat-option>
                        </mat-select>
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                      <mat-label class="matlabel">State<br>
                        <mat-select disableOptionCentering class="ipcss " required
                          [(ngModel)]="clntPermenantAddress.state" (ngModelChange)="stateChange($event, '1','1')">
                          <mat-option disabled>Select</mat-option>
                          <mat-option *ngFor="let state of permenantStateList" [value]="state">
                            {{state.state_desc}}
                          </mat-option>
                        </mat-select>
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                      <mat-label class="matlabel">Country<br>
                        <mat-select disableOptionCentering class="ipcss " required
                          [(ngModel)]="clntPermenantAddress.country" (ngModelChange)="countryChange($event, '1')">
                          <mat-option disabled>Select</mat-option>
                          <mat-option *ngFor="let country of permenantCountryList" [value]="country">
                            {{country.country_desc}}</mat-option>
                        </mat-select>
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                      <mat-label class="matlabel">Zipcode<br>
                        <input disabled="true" maxlength="6" class="ipcss " matInput
                          [(ngModel)]="clntPermenantAddress.zipcode" />
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                      <mat-label class="matlabel">Telephone Business<br>
                        <input class="ipcss " matInput [(ngModel)]="clntPermenantAddress.tel_business" type="number"
                          pattern="[0-9]*" onKeyPress="if(this.value.length==14) return false;" maxlength="14" />
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                      <mat-label class="matlabel">Telephone Home<br>
                        <input [(ngModel)]="clntPermenantAddress.tel_home" type="number" pattern="[0-9]*" maxlength="14"
                          class="ipcss " onKeyPress="if(this.value.length==14) return false;" matInput />
                      </mat-label>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion><br>
            <mat-accordion class="mataccordion">
              <mat-expansion-panel class="expandpanel">
                <mat-expansion-panel-header class="exppanel width">
                  <mat-panel-title class="title">
                    Package Subscription
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <hr class="hr">
                <div class="row">
                  <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"></div>
                  <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div style="margin-top:7px; ">
                      <img width="110px" src="../../../assets/img/Subsribe_msg_icon.png" />
                      <p>{{packageName +" - "+ packageValidity}}</p>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="table-responsive">
                      <table class="table table-hover table-dynamic">
                        <thead>
                          <tr>
                            <th>Featuers</th>
                            <th *ngFor="let Packages of mainPackage" style="text-align: center" class="packge_cell">
                              {{Packages.package_name}}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let subpack of packageModels;let i = index">
                            <td>{{subpack.package_name}}</td>
                            <td *ngFor="let Packages of packIdList" style="text-align: center" class="packge_cell">
                              <img src="{{Packages[i]}}" width="22" height="22" />
                            </td>
                          </tr>
                          <tr>
                            <td></td>
                            <td *ngFor="let Packages of mainPackage" style="text-align: center" class="packge_cell">
                              <input type="button" ng-model="Packages.package_id" value="Buy now" class="Buynow_Class"
                                (click)="buyNow(Packages.package_id)" />
                              <br /><br />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion><br>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>