<div class="row" style="width:100%;margin:0 auto;">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white ">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Discharge list</h5>
                    </div>
                    <div class="headerButtons">
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>
                <div class="Details" >
                    <div class="row">
                        <div class="col-6 col-sm-4 col-md-3 col-lg-3 col-xl-3" [hidden]="dateFlag">
                            <mat-label class="matlabel">From date<br>
                                <input type="date" class="ipcss_date widthbilllist datecss" required
                                    [(ngModel)]="fromDate"  matInput>
                            </mat-label>
                        </div>
                        <div class="col-6 col-sm-4 col-md-3 col-lg-3 col-xl-3" [hidden]="dateFlag">
                            <mat-label class="matlabel">To date<br>
                                <input type="date" class="ipcss_date widthbilllist datecss" required
                                    [(ngModel)]="toDate" matInput>
                            </mat-label>
                        </div>
                        <div class="col-1  p-0" style="display: flex;align-items: end;">
                            <mat-label class="matlabel">
                                <a (click)="getDischargeList()"><img src="../../../assets/ui_icons/buttons/search_button.svg"
                                        class="searchButton" /></a>
                            </mat-label>
                        </div>
                    </div>
                    <div class="row" style="margin-top: 15px;">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                          <p class="nodata" *ngIf="dischargeList.length == 0">No Discharged Patients </p>
                          <table *ngIf="dischargeList.length" mdbTable datatable [dtOptions]="dtOptions"
                            class="table table-nowrap table-sm dataTable billlisttable">
                            <thead>
                              <tr>
                                <th class="delete">Profile image</th>
                                <th>Inpatient id</th>
                                <th>Name</th>
                                <th>Ward type</th>
                                <th>Bed no</th>
                                <th class="">Discharge date</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let person of dischargeList; let i = index">
                                <td><img src="{{person.profileImage}}" onerror="this.src='../../../assets/img/default.jpg';" class="img-fluid z-depth-1 rounded-circle mr-1"></td>
                                <td style="font-size: 12px;">{{ person.inpatient_id }}</td>
                                <td style="font-size: 12px;text-align: left;" >{{person.name}}
                                </td>
                                <td style="font-size: 12px;text-align: left !important;" >
                                  {{person.ward_name}}</td>
                                <td style="font-size: 12px;text-align: right;" >{{person.bed_no}}
                                </td>
                                <td style="font-size: 12px;text-align: center;" >{{person.discharge_date}}&nbsp;&nbsp;
                                  <a *ngIf="person.discharge_date != ''"
                                    (click)="viewDischargeSummary(person.inpatient_id)"><img
                                       src="../../../assets/ui_icons/search_icon.svg" class="editDelicon" /></a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>