import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Helper_Class } from '../../helper_class';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Date_Formate, first_uppercase } from '../../../assets/js/common';
import { FrontDesk_Helper } from '../FrontDesk_Helper';
import { ipaddress } from '../../ipaddress' ;import { Message_data } from '../../../assets/js/Message_data';
import { FrontDeskService } from '../front-desk/front-desk.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';

@Component({
  selector: 'app-inp-discharge-view',
  templateUrl: './inp-discharge-view.component.html',
  styleUrls: ['./inp-discharge-view.component.scss']
})
export class InpDischargeViewComponent implements OnInit {
  @ViewChild('printlogowithname') printlogowithname: ElementRef;
  @ViewChild('printbanner') printbanner: ElementRef;
  @ViewChild('printnoheader') printnoheader: ElementRef;
  @ViewChild('dischargesummary') dischargesummary: ElementRef;
  @ViewChild('printheader') printheader: ElementRef;
  
  @ViewChild('editor', { static: false }) editor: ElementRef;
  editorStyle = {
    height: '300px;',
  };
  content;
  htmlString: string = '';
  modules = {
    toolbar: [
      ['bold', 'italic', 'underline',], // toggled buttons
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'align': [] }],
    ],

  };
  format: '' | 'CODE128' | 'CODE128A' | 'CODE128B' | 'CODE128C' | 'EAN' | 'UPC' | 'EAN8' | 'EAN5' | 'EAN2' | 'CODE39' | 'ITF14' | 'MSI' | 'MSI10' | 'MSI11' | 'MSI1010' | 'MSI1110' | 'pharmacode' | 'codabar';
  public viewpres:boolean=false;
  elementType: 'svg';
  lineColor = '#000000';
  width =1.0;
  barcodeheight = 20;
  displayValue = true;
  fontOptions = '';
  font = 'monospace';
  textAlign = 'center';
  textPosition = 'bottom';
  textMargin = 2;
  fontSize = 15;
  background = '#ffffff';
  margin = 10;
  marginTop = 10;
  marginBottom = 10;
  marginLeft = 0;
  marginRight = 10;
  public userinfo: any;
  public anaest_doctors=[];
  public user_id: any;
  public first_name: string;
  public last_name: string;
  public hospital_logo;
  public hospital_name;
  public datacheck=[];
  public hospital_details;
  public messages;
  public msgflag: boolean = false;
  public notifications;
  public notifyflag: boolean = false;
  public profile_image: string;
  public submenu_flag: boolean = false;
  public submenu_flag1: boolean = false;
  public print_template;
  public hptl_dis_logo;
  public header_footer_flag: boolean;
  public patient_name: string;
  public patient_identifier: string;
  public gender: string;
  public age: string;
  public admis_date: string;
  public dis_date: string;
  public doctor_name: string;
  public mobile_no: string;
  public symptoms: string;
  public complaints: string;
  public medical_hist: string;
  public physical_exam: string;

  public loader: boolean;
  public pres_pop_flag: string;
  public mobile: string;
  public next_data: string;
  public inpatient_id: string;

  public dis_diag: string;
  public hosp_course: string;
  public hospital_followup: string;
  public hosp_name: string;
  public hosp_addr: string;
  public hosp_csz: string;
  public hosp_tel: string;
  public hosp_web: string;
  public hosp_web_flag: boolean;
  public hosp_count: string;
  public druglist: any = [];
  public currentYear;
  public currentTime;
  public currentDate;
  public Treatmetnt_done;
  public investigation;
  public ot_notes;
  public emergency_notes;
  public headerstyle;
  public footerstyle;
  public comp_flag:boolean=false;
  public dis_diag_flag:boolean=false;
  public treat_flag:boolean=false;
  public prev_med_flag:boolean=false;
  public phy_exam_flag:boolean=false;
  public otnote_flag:boolean=false;
  public sum_course_flag:boolean=false;
  public inv_flag:boolean=false;
  public dis_adv_flag:boolean=false;
  public surgery_date:string;
  public surgery_proc:string;
  public surgeon:string;
  public surgeon_list:any=[];
  public anaestetist:any=[];
  public consultarray:any=[];
  public anaestetype:string;
  public anaest_proc:string;
  public anaest_findings: string;
  public inpno: string;
  public admis_time: string;
  public dis_time: string;
  public client_address: string;
  public dis_style: string;
  public surgery: string;
  public inpId: string;
  public height:string;
  public weight:string;
  public temparature:string;
  public pulse:string;
  public resp_rate:string;
  public cvs:string;
  public rstxt:string;
  public cns:string;
  public blood_pressure:string;
  public hospital_id;
  public printlogostyle;
  constructor(public sanitize:DomSanitizer,public messageservice: MenuViewService,private http: Http, private routes: ActivatedRoute, private router: Router, public toastr: ToastrService) { }

  ngOnInit(): void {
    this.getCurrentDate();
    this.userinfo = Helper_Class.getInfo();
    this.user_id = this.userinfo.user_id;
    this.hospital_logo = ipaddress.Ip_with_img_address + Helper_Class.getInfo().hospitals[0].logo;

    this.inpatient_id = FrontDesk_Helper.getInpatientId();
    this.hospital_details = this.userinfo.hospitals[0];
    this.hospital_name = this.hospital_details.hptl_name;
    // this.hospId = this.hospital_details.hptl_clinic_id;
    this.hospital_id = this.hospital_details.hptl_clinic_id;
    this.hptl_dis_logo=ipaddress.Ip_with_img_address+Helper_Class.getInfo().dis_sum_logo;
    console.log(("checj "+(this.inpatient_id)+this.hospital_id))
    this.getAnaestisiaDoctors();
    if (this.inpatient_id != undefined) {
      this.getDischargeSummaryDetails();
    }
    this.print_template=FrontDesk_Helper.getDisPrintTemp();
    this.hptl_dis_logo=ipaddress.Ip_with_img_address+Helper_Class.getInfo().dis_sum_logo;
    this.dis_style=Helper_Class.getInfo().dis_sum_logo_style;
    this.printlogostyle=Helper_Class.getInfo().bill_print_logo_style;

    this.headerstyle=this.sanitize.bypassSecurityTrustHtml(FrontDesk_Helper.getDisPrintHeaderText());
    this.footerstyle=this.sanitize.bypassSecurityTrustHtml(FrontDesk_Helper.getDisPrintFooterText());
  }

  getDischargeSummaryDetails() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + "inpat/gdsv", { inpatient_id: this.inpatient_id,hptl_clinic_id:this.hospital_id },
      { headers: headers })
      .subscribe(
        response => {
          var values = JSON.parse(response["_body"]);
          console.log(("checj "+JSON.stringify(values)))

          if (values != undefined) {
            this.inpId=values.inp_hosp_id;
            if (values.middle_name != undefined) {
              this.patient_name = encrypt_decript.Decript(values.first_name) + " " + encrypt_decript.Decript(values.middle_name) + " " + encrypt_decript.Decript(values.last_name);

            } else {
              this.patient_name = encrypt_decript.Decript(values.first_name) + " " + encrypt_decript.Decript(values.last_name);
            }
            this.inpId= values.inp_hosp_id;
            this.patient_identifier = values.patient_id;
            this.gender = encrypt_decript.Decript(values.gender);
            if(this.gender =="Male"){
              this.gender="M";
            } else if(this.gender =="Female"){
              this.gender="F";
            }
            this.age = values.age;
            if(values.caddress2 != undefined){
              this.client_address = encrypt_decript.Decript(values.caddress1)+" "+encrypt_decript.Decript(values.caddress2)+" "+values.clocation+" "+values.ccity+" "+values.cstate+" "+values.ccountry+" "+encrypt_decript.Decript(values.czipcode)
            
            } else {
              this.client_address = encrypt_decript.Decript(values.caddress1)+" "+values.clocation+" "+values.ccity+" "+values.cstate+" "+values.ccountry+" "+encrypt_decript.Decript(values.czipcode)
            }
            
            if(values.admission_date != undefined){
              this.admis_date = Date_Formate(values.admission_date);
              var dtval = values.admission_time.split(':')
              if(parseInt(dtval[0])>12){
                this.admis_time=(parseInt(dtval[0])-12)+":"+dtval[1]+" PM";

              } else if(parseInt(dtval[0]) == 12){
                this.admis_time=dtval[0]+":"+dtval[1]+" PM";
              } else {
                this.admis_time=dtval[0]+":"+dtval[1]+" AM";
              }
            }

            if(values.discharge_date != undefined){
              this.dis_date = Date_Formate(values.discharge_date);
            }

            if(values.discharge_time !=""){
              var dtval = values.discharge_time.split(':')
              if(parseInt(dtval[0])>12){
                this.dis_time=(parseInt(dtval[0])-12)+":"+dtval[1]+" PM";

              } else if(parseInt(dtval[0]) == 12){
                this.dis_time=dtval[0]+":"+dtval[1]+" PM";
                
              } else {
                this.dis_time=dtval[0]+":"+dtval[1]+" AM";
              } 
            }
            var docqualif;
            if(values.dr_qualif != undefined){
              docqualif = values.dr_qualif
            } else {
              docqualif ="";
            }
            if (values.dr_mname != undefined) {
              this.doctor_name = "Dr."+values.dr_fname + " " + values.dr_mname + " " + values.dr_lname+" "+docqualif;

            } else {
              this.doctor_name = "Dr."+values.dr_fname + " " + values.dr_lname+" "+docqualif;
            }
            this.consultarray.push({name:this.doctor_name});
            console.log("check array--"+JSON.stringify(this.consultarray))
            if(values.consultants != undefined){
              var cname;
              for(var i=0;i<values.consultants.length;i++){
                if(values.consultants[i].middle_name != undefined){
                  cname = "Dr."+values.consultants[i].first_name+" "+values.consultants[i].middle_name+" "+values.consultants[i].last_name;
                } else {
                  cname = "Dr."+values.consultants[i].first_name+" "+values.consultants[i].last_name;
                }
                if(values.consultants[i].qualification != undefined){
                  cname = cname+" "+values.consultants[i].qualification;
                }
                this.consultarray.push({name:cname});
                console.log("check cname--"+JSON.stringify(this.consultarray))
              }
            }
            
            this.symptoms = values.symptoms;
            if(values.height !=undefined){
              this.height = values.height+" "+values.height_measure;
            }
            if(values.weight !=undefined){
              this.weight = values.weight+" "+values.weight_measure;
            }
            if(values.temparature !=undefined){
              this.temparature = values.temparature+" F";
            }
            if(values.blood_pressure !=undefined){
              this.blood_pressure = values.blood_pressure+" mm Hg";
            }
            if(values.pulse !=undefined){
              this.pulse = values.pulse+" /min";
            }
            if(values.resp_rate !=undefined){
              this.resp_rate = values.resp_rate+" /min";
            }
            if(values.cvs !=undefined){
              this.cvs = values.cvs;
            }
            if(values.rstxt !=undefined){
              this.rstxt = values.rstxt;
            }
            if(values.cns !=undefined){
              this.cns = values.cns;
            }

            if(values.complaints != undefined ){
              this.complaints = values.complaints;
              this.comp_flag=true;
            }
            
            if(values.medical_history != undefined){
              this.medical_hist = values.medical_history;
              this.prev_med_flag=true;
            }
            
            if(values.physical_exam != undefined){
              this.physical_exam = values.physical_exam;
              this.phy_exam_flag=true;
            }
            
            if(values.discharge_diagnosis != undefined){
              this.dis_diag = values.discharge_diagnosis;
              this.dis_diag_flag=true;
            }
            
            if(values.hospital_course != undefined){
              this.hosp_course = values.hospital_course;
              this.sum_course_flag=true;
            }
           
            if(values.treat_proc != undefined){
              this.Treatmetnt_done = values.treat_proc;
              this.treat_flag=true;
            }
           
            if(values.follow_summary != undefined){
              this.hospital_followup = values.follow_summary;
              this.dis_adv_flag=true;
            }
            if(values.investigation != undefined){
              this.investigation = values.investigation;
              this.inv_flag=true;
            }
            if(values.ot_notes != ""){
              this.ot_notes = values.ot_notes;
              this.otnote_flag=true;
            }

            this.emergency_notes = values.emergencies;
            var nextdate;
            if (values.next_visit != undefined) {
              nextdate = values.next_visit.split('-');
              this.next_data = nextdate[2] + "-" + nextdate[1] + "-" + nextdate[0];
            }

            if (values.drug_list != undefined) {
              for (var i = 0; i < values.drug_list.length; i++) {
                var master_tablet_data = values.drug_list[i];
                var medicine_dosage_data = "";

                if (master_tablet_data.intake_desc == "Every 6 hours") {
                  medicine_dosage_data = master_tablet_data.morning + master_tablet_data.short_form + '-' + master_tablet_data.morning + master_tablet_data.short_form + '-' + master_tablet_data.morning + master_tablet_data.short_form + master_tablet_data.short_form + '-' + master_tablet_data.morning + master_tablet_data.short_form;

                } else {
                  medicine_dosage_data = master_tablet_data.morning + master_tablet_data.short_form + '-' + master_tablet_data.afternoon + master_tablet_data.short_form + '-' + master_tablet_data.night + master_tablet_data.short_form;
                }

                var master_timeDuration = "";
                if (master_tablet_data.drug_intake == "With food") {
                  master_timeDuration = master_tablet_data.drug_intake;

                } else {
                  if (master_tablet_data.time_duration != null && master_tablet_data.time_duration != "0") {
                    master_timeDuration = master_tablet_data.time_duration + ' mins ' + master_tablet_data.drug_intake;

                  } else {
                    master_timeDuration = master_tablet_data.drug_intake;
                  }
                }

                this.druglist.push({
                  medicine_name: master_tablet_data.short_name + " " + master_tablet_data.drug_name,
                  medicine_dosage: medicine_dosage_data,
                  medicine_intakedays: master_tablet_data.days + ' days ',
                  medicine_food: master_timeDuration
                })
              }
            }

            this.hosp_name = values.hptl_name;
            var addval;
            if (values.address2 != undefined) {
              addval = values.address1 + "," + values.address2;

            } else {
              addval = values.address1;
            }
            this.hosp_addr = addval;
            this.hosp_csz = values.city + ", " + values.state + " - " + values.zipcode + "  " + values.country;
            this.hosp_count = values.country;
            if (values.telephone != undefined) {
              this.hosp_tel = values.telephone;

            } else {
              this.hosp_tel = "-";
            }

            if (values.website != undefined) {
              this.hosp_web = values.website;
              this.hosp_web_flag = false;

            } else {
              this.hosp_web_flag = true;
            }
            
            if(values.surgery_date != undefined){
              var surdate = values.surgery_date.split("-");
              this.surgery_date = surdate[2]+"-"+surdate[1]+"-"+surdate[0] +" "+values.surgery_time;
              this.surgery_proc = values.surgery_proc;
              this.surgery= values.surgery;
              //this.surgeon = values.surgeon;
              //this.anaestetist=values.anaest_doc;
              this.anaestetype=values.anaesttype;
              this.anaest_proc = values.anaest_proc;
              this.anaest_findings= values.anaest_findings;
              if(values.surgeons != undefined){
                for(var i=0;i<values.surgeons.length;i++){
                  var name;
                  if(values.surgeons[i].middle_name != undefined){
                    name= "Dr. "+values.surgeons[i].first_name+" "+values.surgeons[i].middle_name+" "+values.surgeons[i].last_name+" "+values.surgeons[i].qualification;
                  } else {
                    name= "Dr. "+values.surgeons[i].first_name+" "+values.surgeons[i].last_name+" "+values.surgeons[i].qualification;
                  }
                  this.surgeon_list.push({name:name});
                }
              }
              
              if(values.anaest_doc != undefined){
                this.datacheck=[];
                var check =values.anaest_doc.split(",")
                console.log("check --"+JSON.stringify(check))
                for(var j=0;j<this.anaest_doctors.length;j++){
                   for(var i=0;i<check.length;i++){              
                    if(this.anaest_doctors[j].doc_reg_id == check[i]){
                      this.anaestetist.push({ name: this.anaest_doctors[j].name +" "+this.anaest_doctors[j].qualification });
                    }
                    console.log("check datat --"+JSON.stringify(this.datacheck))
                  }
              console.log("check datat --"+JSON.stringify(this.datacheck))
               
              }
            }
              // if (values.anaest_doc != undefined) {
              //   for (var i = 0; i < values.anaest_doc.length; i++) {
              //     var name;
              //     if (values.anaest_doc[i].middle_name != undefined) {
              //       console.log("a0000 ---"+JSON.stringify(values.anaest_doc[i].qualification)+values.anaest_doc[i].middle_name)
              //       if(values.anaest_doc[i].qualification != undefined || values.anaest_doc[i].qualification != ""){
              //         name = "Dr. " + values.anaest_doc[i].first_name + " " + values.anaest_doc[i].middle_name + " " + values.anaest_doc[i].last_name + " " + values.anaest_doc[i].qualification;

              //       }else{
              //         name = "Dr. " + values.anaest_doc[i].first_name + " " + values.anaest_doc[i].middle_name + " " + values.anaest_doc[i].last_name;

              //       }
              //     } else {
              //       console.log("else 000 ---"+JSON.stringify(values.anaest_doc[i].qualification)+values.anaest_doc[i].middle_name)

              //       if(values.anaest_doc[i].qualification != undefined || values.anaest_doc[i].qualification != ""){
              //       name = "Dr. " + values.anaest_doc[i].first_name + " " + values.anaest_doc[i].last_name + " " + values.anaest_doc[i].qualification;
              //       }else{
              //         name = "Dr. " + values.anaest_doc[i].first_name + " " + values.anaest_doc[i].last_name;

              //       }
              //     }
              //     this.anaestetist.push({ name: name });
              //     console.log("a0000 ---"+JSON.stringify(this.anaestetist))
              //   }
              // }
            }
          }
        },
        error => {
          this.loader = true;
          this.toastr.error(Message_data.defaultErr);
        }
      )
  }

  goToPatientlist() {
    this.messageservice.sendMessage("dischargeList");
    
  }

  getCurrentDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate',
      JSON.stringify({
        country: ipaddress.country_code.toString()
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();

          if (obj.current_date != null) {
            this.currentDate = obj.current_date;
            var get_date = obj.current_date.split('-');
            this.currentDate = get_date[2] + "-" + get_date[1] + "-" + get_date[0];
          }
          
        },error => {});
  }
  
  print_area() {
    let printContents, popupWin;
    if (this.print_template != undefined && this.print_template == "banner") {
      printContents = this.printbanner.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;

    } else if (this.print_template != undefined && this.print_template == "logowithname") {
      printContents = this.printlogowithname.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;

    } else {
      printContents = this.printnoheader.nativeElement.innerHTML;
    }
    popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
    <head>
      <title>Discharge summary</title>
      <link rel="stylesheet" media="screen" href="">
      <style>
      @page { size: auto;  margin: 0mm 10mm 10mm 10mm; }
      .table_class {
        background-color: rgb(108, 180, 248);
      }
      * {
        font-size: 11px !important; 
      }
      .block1_grid{
        margin: 7px auto;
        width: 90%;
      }
      .block1{
        margin:3px 0;
      }
      </style>
    </head>
        <body onload="window.print();window.onfocus=function(){ window.close();}">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
  }

  getAnaestisiaDoctors() {
    console.log('ANAESTESIA EFFECTS' + JSON.stringify(this.hospital_details.hptl_clinic_id));
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(
        ipaddress.getIp + 'inpat/gad',
        JSON.stringify({ hptl_clinic_id: this.hospital_details.hptl_clinic_id }),
        { headers: headers }
      )
      .subscribe(
        (data) => {
          this.anaest_doctors = [];
          this.anaest_doctors = JSON.parse(data['_body']).anaesthesia_doctors;
          console.log('ANAESTESIA EFFECTS' + JSON.stringify(JSON.parse(data['_body'])));
        },
        (error) => {
          this.toastr.error(Message_data.network);
        }
      );
  }
  oncontentdiag = (event) => {
    this.dis_diag = event.html;
  }
  oncontentcomp = (event) => {
    this.complaints = event.html;
  }
  oncontentpreviousmedicalhist = (event) => {
    this.medical_hist = event.html;
  }
  oncontentphysical = (event) => {
    this.physical_exam = event.html;
  }
  oncontenttreatment = (event) => {
    this.Treatmetnt_done = event.html;
  }
  oncontenthosp = (event) => {
    this.hosp_course = event.html;
  }
  oncontentot = (event) => {
    this.ot_notes = event.html;
  }
  oncontentemer = (event) => {
    this.emergency_notes = event.html;
  }
  oncontentdis = (event) => {
    this.hospital_followup = event.html;
  }
  
}
