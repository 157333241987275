import { Component, OnInit,Inject } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { CommonDataService } from '../../providers/common-data.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Date_Formate } from '../../../assets/js/common.js';
import { ipaddress } from '../../ipaddress';
import { Client_Helper } from '../Client_helper';

@Component({
  selector: 'app-diet-track-history',
  templateUrl: './diet-track-history.component.html',
  styleUrls: ['./diet-track-history.component.scss']
})
export class DietTrackHistoryComponent implements OnInit {

  public dietHistTableData = [];
  public trackHistTableData = [];
  public getIp: string;
  public subRelId;
  public relationId;
  public sendData;
  Title:any = "Diet";

  constructor(public gservice: CommonDataService,private http:Http,public dialogRef: MatDialogRef<DietTrackHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
  }

  ngOnInit(): void {
    var getDetails = Client_Helper.getClientDietHistory();
    this.relationId = getDetails.rel_id;
    this.subRelId =  (getDetails.sub_id != "" && getDetails.sub_id != undefined) ? getDetails.sub_id : undefined;
    this.dietPlan();
  }

  dietPlan() {
    this.sendData = {
      client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
      relation_id: this.relationId,
      sub_rel_id: this.subRelId,
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "lifestyle/dphist",
      JSON.stringify(this.sendData),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.diet_plan_hist != null && obj.diet_plan_hist.length != 0) {
            for (var i = 0; i < obj.diet_plan_hist.length; i++) {
              this.dietHistTableData.push({
                break_fast: obj.diet_plan_hist[i].break_fast,
                morning: obj.diet_plan_hist[i].morning,
                midday: obj.diet_plan_hist[i].midday,
                lunch: obj.diet_plan_hist[i].lunch,
                evening: obj.diet_plan_hist[i].evening,
                dinner: obj.diet_plan_hist[i].dinner,
                night: obj.diet_plan_hist[i].night,
                created_date: Date_Formate(obj.diet_plan_hist[i].created_date),
              });
            }
          }
        },error => {});
  }

  trackerPlan() {//TRacker history
    this.sendData = {
      client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
      relation_id: this.relationId,
      sub_rel_id: this.subRelId,
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "lifestyle/dpthist",
      JSON.stringify(this.sendData),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          for (var i = 0; i < obj.diet_plan_track_hist.length; i++) {
            this.trackHistTableData.push({
              break_fast: obj.diet_plan_track_hist[i].break_fast,
              morning: obj.diet_plan_track_hist[i].morning,
              midday: obj.diet_plan_track_hist[i].midday,
              lunch: obj.diet_plan_track_hist[i].lunch,
              evening: obj.diet_plan_track_hist[i].evening,
              dinner: obj.diet_plan_track_hist[i].dinner,
              night: obj.diet_plan_track_hist[i].night,
              created_date: Date_Formate(obj.diet_plan_track_hist[i].created_date),
            });
          }
        },error => {});
  }

  close(){
    this.dialogRef.close();
  }

}
