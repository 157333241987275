<div class="row" style="width:100%;margin:0 auto;">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Insurance Provider</h5>
                    </div>
                    <div class="headerButtons">
                        <img src="../../../assets/ui_icons/buttons/update_button.svg" class="saveimgbtn_inpatinfo"
                            (click)="update()" />
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>
                <div class="row">                   
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                            <mat-label class="matlabel">Company<br>
                                <input type="text" class="ipcss inpat_width" [(ngModel)]="company_name"
                                   matInput />
                            </mat-label>
                        </div>
                        <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                            <mat-label class="matlabel">Name<br>
                                <input type="text" class="ipcss inpat_width" [(ngModel)]="insurer_name"
                                   matInput />
                            </mat-label>
                        </div> -->
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                            <mat-label class="matlabel">Address<br>
                                <input type="text" class="ipcss inpat_width" [(ngModel)]="address1"
                                   matInput />
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                            <mat-label class="matlabel">Address1<br>
                                <input type="text" class="ipcss inpat_width" [(ngModel)]="address2"
                                   matInput />
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom">
                            <mat-label class="matlabel">Country
                               <!-- <input type="text" class="ipcss noappt_width" [(ngModel)]="clnt_country"
                                  [disabled]="user_type" [hidden]="exist_user" matInput /> -->
                               <select disableOptionCentering class="ipcss noappt_width" [(ngModel)]="clnt_country"
                                 
                               (ngModelChange)="countryChange(clnt_country, '1')">
                                  <option Selected>Select</option>
                                  <option *ngFor="let country of clnt_country_list" value="{{country.description}}">
                                     {{country.description}}</option>
                               </select>
                            </mat-label>
                         </div>
                         <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom">
                            <mat-label class="matlabel">State
                               <!-- <input type="text" class="ipcss noappt_width" [(ngModel)]="clnt_state"
                                  [disabled]="user_type" [hidden]="exist_user" matInput /> -->
                               <select disableOptionCentering class="ipcss noappt_width" [(ngModel)]="clnt_state"
                                 
                               (ngModelChange)="stateChange(clnt_state, '1')">
                                  <option Selected>Select</option>
                                  <option *ngFor="let state of clnt_state_list" value="{{state.description}}">
                                     {{state.description}}</option>
                               </select>
                            </mat-label>
                         </div>
                         <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom">
                            <mat-label class="matlabel">District / City
                               <!-- <input type="text" class="ipcss noappt_width" [(ngModel)]="clnt_city"
                                  [disabled]="user_type" [hidden]="exist_user" matInput /> -->
                               <select disableOptionCentering class="ipcss noappt_width" [(ngModel)]="clnt_city"
                                 
                               (ngModelChange)="cityChange(clnt_city, '1')">
                                  <option Selected>Select</option>
                                  <option *ngFor="let city of clnt_city_list" value="{{city.description}}">
                                     {{city.description}}</option>
                               </select>
                            </mat-label>
                         </div>
                         <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <mat-label class="matlabel">Location
                               <input type="text" class="ipcss noappt_width" 
                                  [(ngModel)]="clnt_location" (keyup)="location_change(clnt_location)" matInput
                                  [matAutocomplete]="auto" required />
                               <mat-autocomplete #auto="matAutocomplete" >
                                  <mat-option (click)="location_selected(location)"
                                     *ngFor="let location of clnt_location_list" value="{{location.description}}">
                                     {{location.description}}
                                  </mat-option>
                               </mat-autocomplete>
                            </mat-label>
                         </div>
                         <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                            <mat-label class="matlabel">Zipcode
                               <input type="text" class="ipcss noappt_width" [(ngModel)]="clnt_zipcode"
                                   matInput />
                            </mat-label>
                         </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                            <mat-label class="matlabel">Email id<br>
                                <input type="text" class="ipcss inpat_width" [(ngModel)]="email_id" required
                                    matInput />
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                            <mat-label class="matlabel">Rohini id<br>
                                <input type="text" class="ipcss inpat_width" [(ngModel)]="rohini_id" required
                                    matInput />
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                            <mat-label class="matlabel">Mobile no<br>
                                <input type="text" class="ipcss inpat_width" [(ngModel)]="mobileno" required
                                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                maxlength="10" matInput style="width:100%;" matInput />
                            </mat-label>
                        </div>
            
                </div> 
             
            </mdb-card-body>
        </mdb-card>
    </div>
</div>