import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CommonDataService } from '../../providers/common-data.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ipaddress } from '../../ipaddress';
import { Message_data } from '../../../assets/js/Message_data';
import { MenuViewService } from '../../../app/common-module/menu-view/menu-view.service';
import { Helper_Class } from '../../helper_class';
import { encrypt_decript } from 'src/assets/js/Encription_Decription';
import { Diagnosis_Helper } from '../Diagnosis_Helper';
import { MicroModalComponent } from '../micro-modal/micro-modal.component';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';

@Component({
  selector: 'app-diag-specimen-report-template',
  templateUrl: './diag-specimen-report-template.component.html',
  styleUrls: ['./diag-specimen-report-template.component.scss']
})
export class DiagSpecimenReportTemplateComponent implements OnInit {
  modalRef: MdbModalRef<MicroModalComponent> | null = null;
  public save_btn: boolean;
  public sampledate: string;
  public filtduration: string;
  public currentDate: string;
  public sampleList: any = [];
  public selectedList: any = [];
  public sampletype_data: any = [];
  public sampleDetails_data: any = [];
  public testtube_data: any = [];
  dtOptions: DataTables.Settings = {};
  selected_tube_colors: any[];
  impression: any;
  public technical_notes: any;
  public comment_data: any;
  public colony_input: any;
  public sterile_area: any;
  public sterile_result: any;
  public sterileChecklist: any = [];
  public fileType: string;
  public imgStr: any;
  public growth_noGrowth: any = 'growth';
  public diag_centre_id: any;

  specimen_data = [
    {
      code: "AF",
      description: "Ascilic FLuid",
    },
    {
      code: "AS",
      description: "Abscess",
    },
    {
      code: "ASP",
      description: "Aspirates",
    },
    {
      code: "AV",
      description: "Aortic Valve",
    },
    {
      code: "B",
      description: "Blood",
    },
    {
      code: "BA",
      description: "Broncho Alveolar Lavage ",
    },
  ]

  //Editor
  @ViewChild('editor', { static: false }) editor: ElementRef;
  content;
  htmlString: string = '';
  modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      // [{ 'header': 1 }, { 'header': 2 }], // custom button values
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      // superscript/subscript
      [{ 'indent': '-1' }, { 'indent': '+1' }], // outdent/indent
      [{ 'direction': 'rtl' }], // text direction
      [{ 'size': ['small', false, 'large', 'huge'] }], // custom dropdown
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      [{ 'color': [] }, { 'background': [] }], // dropdown with defaults from theme
      [{ 'font': [] }],
      [{ 'align': [] }],
      ['clean'], // remove formatting button
      // ['link', 'image'], // link and image, video
      // ['table']
    ],
  };
  editorStyle = {
    height: '300px;',
  };
  // specimen_data: {}[];
  specimen_code: any;

  public cultureReports: any[];
  public gramStainList: any[];

  public gram_stain_1: any;
  public gram_stain_2: any;
  public gram_stain_3: any;

  public culture_report_notes: any;
  public culture_report_notes_1: any;
  public culture_report_notes_2: any;
  public culture_report_notes_3: any;

  public colony_count_1: any;
  public colony_count_2: any;
  public colony_count_3: any;
  public colonyCountList: any = [];

  public culture_code_1: any;
  public culture_code_2: any;
  public culture_code_3: any;

  public culture_id_1: any;
  public culture_id_2: any;
  public culture_id_3: any;

  public organismIsoList: any[];
  public organism_code_Iso1: any;
  public organism_code_Iso2: any;
  public organism_code_Iso3: any;
  public organism_desc_Iso1: any;
  public organism_desc_Iso2: any;
  public organism_desc_Iso3: any;
  medicineList: any[];
  sensitivityTypeList: any[];
  showColonyData: boolean = false;
  colonyCount_array: any[];
  sendObj: any;
  sendObject: any;
  reportArray: any[];
  testObj: { lab_test: any; };
  public testArray: any = [];
  public sensitive_med: any;
  public otSterile_checked: boolean;
  public sensitivityCardList: any = [];

  public organism_iso_id1: any;
  public organism_iso_id2: any;
  public organism_iso_id3: any;
  test_Name: any;
  isSensitive: boolean = false;
  reporttype: any;
  public showSterile: boolean = false;
  public isDisabled: boolean = false;
  public mrno: string;
  public patientName: string;
  public patAge: string;
  public patGender: string;
  public mobile: string;
  public patient_type: string;
  public doctorname: string;
  public address1: string;
  public location: string;
  public district: string;
  public state: string;
  public clntcountry: string;
  public zipcode: string;
  public template_flag: boolean = true;
  public micro_template_flag: boolean = false;
  public cbt_Template: string;
  public antibiotics_iso1: any = [];
  public antibiotics_iso2: any = [];
  public antibiotics_iso3: any = [];
  public urineTest_flag: boolean = false;
  public no_growthFlag: boolean;
  sensitive_id: any;

  constructor(public messageservice: MenuViewService, public toastr: ToastrService,
    public gservice: CommonDataService, public http: HttpClient,
    public dialog: MatDialog, private modalService: MdbModalService) {

  }

  ngOnInit(): void {
    if (Helper_Class.getInfo().user_type == "Admin") {
      this.diag_centre_id = Helper_Class.getInfo().diag_center_id;

    } else {
      this.diag_centre_id = Helper_Class.getHospitalInfo().hptl_clinic_id;
    }

    this.filtduration = "select";
    this.getdate();
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 25,
      "lengthMenu": [[25, 50, -1], [25, 50, "All"]],
      "order": [[0, 'asc']],
      "columnDefs": [
        { "orderable": false, "targets": [] },
      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search by test name or count"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };

    this.sendObject = Diagnosis_Helper.getMicrobiologyUploadData();
    this.test_Name = this.sendObject.test_name;
    console.log("this.sendObject" + JSON.stringify(this.sendObject))

    if (this.test_Name.toLowerCase().includes("urine")) {
      this.urineTest_flag = true;
    }

    console.log("Test Name from helper classs", this.test_Name)
    this.toOpenSpecimenModal();
    this.getCultureReportType();
    this.getGramStainList();
    this.getOrganismIsolated();
    this.getSensitivityType();
    this.getColonyCountList();
    this.checkTemplate();

    this.mrno = this.sendObject.mrno;
    this.patientName = this.sendObject.patient_name;
    this.patAge = this.sendObject.age;
    this.patGender = this.sendObject.gender;
    this.mobile = this.sendObject.mobile;
    this.patient_type = this.sendObject.pat_type;
    if (this.sendObject.doctor_name != undefined) {
      this.doctorname = this.sendObject.doctor_name;
    }

    this.address1 = this.sendObject.address;
    this.location = this.sendObject.location;
    this.district = this.sendObject.district;
    this.state = this.sendObject.state;
    this.clntcountry = this.sendObject.clnt_country;
    this.zipcode = this.sendObject.zipcode;
    this.sensitive_id = this.sendObject.sensitive_id;
  }

  checkTemplate() {
    if (this.sendObject.template_id != undefined) {
      this.template_flag = false;
      this.micro_template_flag = true;
      this.cbt_Template = this.sendObject.template_desc;
    }
  }

  onTemplateChanged = (event) => {
    this.cbt_Template = event.html
  }


  getdate() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "adm/curdate", ({ country: ipaddress.country_code }), { headers: headers }).subscribe(
      data => {
        var response = JSON.parse(JSON.stringify(data));
        this.currentDate = response.current_date;
        this.sampledate = this.currentDate;
      }, error => { });
  }

  editorCreated(quill: any) { }
  onSelectionChanged = (event) => { }
  onContentChanged2 = (event) => {
    this.impression = event.html;
  }

  toOpenSpecimenModal() {
    this.specimen_data = [
      {
        code: "AF",
        description: "Ascilic FLuid",
      },
      {
        code: "AS",
        description: "Abscess",
      },
      {
        code: "ASP",
        description: "Aspirates",
      },
      {
        code: "AV",
        description: "Aortic Valve",
      },
      {
        code: "B",
        description: "Blood",
      },
      {
        code: "BA",
        description: "Broncho Alveolar Lavage ",
      },
    ]
  }

  getCultureReportInput(data, id) {
    if (id == '1') {
      this.culture_code_1 = data.culture_report_desc;
      this.culture_id_1 = data.culture_report_id;
    } else if (id == '2') {
      this.culture_code_2 = data.culture_report_desc;
      this.culture_id_2 = data.culture_report_id;
    } else {
      this.culture_code_3 = data.culture_report_desc;
      this.culture_id_3 = data.culture_report_id;
    }
    // this.culture_code = data.culture_report_code + " - " + data.culture_report_desc;
    // this.culture_id = data.culture_report_id;
  }

  getOrgnaismIsoInput(data, id) {
    if (id == 1) {
      this.organism_code_Iso1 = data.culture_pathogen_code;
      this.organism_desc_Iso1 = data.culture_pathogen_desc;
      this.organism_iso_id1 = data.culture_pathogen_id;
    } else if (id == 2) {
      this.organism_code_Iso2 = data.culture_pathogen_code;
      this.organism_desc_Iso2 = data.culture_pathogen_desc;
      this.organism_iso_id2 = data.culture_pathogen_id;
    } else {
      this.organism_code_Iso3 = data.culture_pathogen_code;
      this.organism_desc_Iso3 = data.culture_pathogen_desc;
      this.organism_iso_id3 = data.culture_pathogen_id;
    }
  }

  back() {
    this.messageservice.sendMessage("pathpending");
  }

  getCultureReportType() {
    this.cultureReports = [];

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    
    this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/gdcr', { diag_centre_id: this.diag_centre_id },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log("getCultureReportType", obj)

          this.cultureReports = obj.culture_reports;
        }, error => { });
  }

  getGramStainList() {
    this.gramStainList = [];

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    
    this.http.post(ipaddress.getIp.toString() + 'mas/ggst', { diag_centre_id: this.diag_centre_id },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log("getGramStainList", obj)

          this.gramStainList = obj.gram_stains;
        }, error => { });
  }

  getColonyCountList() {
    this.colonyCountList = [];

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    
    this.http.post(ipaddress.getIp.toString() + 'mas/gccbd', { diag_centre_id: this.diag_centre_id },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log("getColonyCountList", obj)

          this.colonyCountList = obj.colonycounts;
        }, error => { });
  }

  getOrganismIsolated() {
    this.organismIsoList = [];

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
   
    this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/gdcp', { diag_centre_id: this.diag_centre_id },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log("getOrganismIsolated", obj)

          this.organismIsoList = obj.culture_pathogens;
        }, error => { });
  }

  getMedicine(gramStainId, Id) {
    if (gramStainId == '6') {
      this.showSterile = true;
    } else {
      this.showSterile = false;
    }

    this.medicineList = [];

    if (this.gram_stain_1 != undefined || this.gram_stain_2 != undefined || this.gram_stain_3 != undefined) {
      this.showColonyData = true;
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
     
      this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/gcat', { gram_stain_id: gramStainId, sensitive_id: this.sensitive_id },
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));
            console.log("getMedicine", obj)

            this.medicineList = obj.sensitive_drugs;
            if (this.medicineList != undefined && this.medicineList.length != 0) {
              Helper_Class.setmicromodalData(this.medicineList);
              this.modalRef = this.modalService.open(MicroModalComponent, {
                modalClass: 'modal-xl'
              })
              this.modalRef.onClose.subscribe((message: any) => {
                // this.culture_report_notes = message;
                if (Id == '1') {
                  this.culture_report_notes_1 = message.culture_report_notes;
                  this.antibiotics_iso1 = message.medicine_list;
                } else if (Id == '2') {
                  this.culture_report_notes_2 = message.culture_report_notes;
                  this.antibiotics_iso2 = message.medicine_list;
                } else {
                  this.culture_report_notes_3 = message.culture_report_notes;
                  this.antibiotics_iso3 = message.medicine_list;
                }
                console.log("public antibiotics_iso1: any = [];", this.antibiotics_iso1)
              });
              // setTimeout(() => {
              //   this.modalRef = this.modalService.open(MicroModalComponent, {
              //     modalClass: 'modal-xl'
              //   })
              // }, 1000)
            }
          }, error => { });
    } else {
      this.showColonyData = false;
      this.toastr.error("Select Gram Stain");
    }
  }

  getSensitivityType() {
    this.sensitivityTypeList = [];

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    
    this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/gdcs', { diag_centre_id: this.diag_centre_id },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log("getSensitivityType", obj)

          this.sensitivityTypeList = obj.culture_sensitives;
        }, error => { });

  }

  saveColonyCountData() {
    this.colonyCount_array = [];
    console.log(this.medicineList)
    this.colony_input = "..."

    var sensitiveMedicines = "";
    for (var i = 0; i < this.sensitivityCardList.length; i++) {
      sensitiveMedicines += this.sensitivityCardList[i].med_Name + " - " + "Sensitive  ";
    }

    console.log(sensitiveMedicines)
    this.culture_report_notes = sensitiveMedicines;
  }

  saveUploadData() {
    var save_Data;
    var flag = true;
    this.testArray = [];

    console.log(this.otSterile_checked)
    var otCheck;
    if (this.otSterile_checked == true) {
      otCheck = 1;
    } else {
      otCheck = 0;
    }

    this.sendObject.test_template = this.cbt_Template,
      this.reportArray = [];
    console.log("Data from helper classs", this.sendObject)

    if (this.growth_noGrowth == 'growth') {
      if (this.organism_iso_id1 != undefined && this.organism_iso_id1 != '' && this.gram_stain_1 != undefined) {
        flag = true;
      } else {
        flag = false;
      }

      if (this.urineTest_flag == true) {
        if (this.colony_count_1 != undefined) {
          flag = true;
        } else {
          flag = false;
        }
      }
    } else {
      if (this.culture_code_1 != undefined) {
        flag = true;
      } else {
        flag = false;
      }
    }

    this.testArray.push({
      test_id: this.sendObject.test_id,
      growth_nogrowth: this.growth_noGrowth,

      org_iso_1: this.organism_iso_id1,
      org_iso1_cc: this.colony_count_1,
      org_iso1_cr: this.culture_code_1,
      org_iso1_gt: this.gram_stain_1,
      org_iso1_selanti: this.culture_report_notes_1,
      org_iso1_antibiotics: this.antibiotics_iso1,

      org_iso_2: this.organism_iso_id2,
      org_iso2_cc: this.colony_count_2,
      org_iso2_cr: this.culture_code_2,
      org_iso2_gt: this.gram_stain_2,
      org_iso2_selanti: this.culture_report_notes_2,
      org_iso2_antibiotics: this.antibiotics_iso2,

      org_iso_3: this.organism_iso_id3,
      org_iso3_cc: this.colony_count_3,
      org_iso3_cr: this.culture_code_3,
      org_iso3_gt: this.gram_stain_3,
      org_iso3_selanti: this.culture_report_notes_3,
      org_iso3_antibiotics: this.antibiotics_iso3,

      comment: this.comment_data,
      technical_note: this.technical_notes,
    });

    this.testObj = { "lab_test": this.testArray }


    this.sendObj = Object.assign({}, this.sendObject, this.reportArray, this.testObj);
    console.log("sendobj" + JSON.stringify(this.sendObj))
    if (flag == true) {
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + "diagnosiscontroller/savediagreport/", this.sendObj, { headers: headers }).subscribe(
        data => {
          var obj = JSON.parse(JSON.stringify(data));

          if (obj.key != null && obj.key == "1") {
            this.toastr.success(Message_data.rptUploaddSuccess);
            this.messageservice.sendMessage("micropending");

          } else {
            this.toastr.error(Message_data.unableToUploadRpt);
          }
        });
      // save_Data = "";
    } else {
      this.toastr.error(Message_data.enterTestReading);
    }

  }


  onSensitivyChange(sensitivityId, med, medId) {

    for (var i = 0; i < this.medicineList.length; i++) {
      if (this.medicineList[i].culture_sensitive_id == '1') {
        this.medicineList[i].isSensitive = true;
      } else {
        this.medicineList[i].isSensitive = false;
      }
    }

    if (sensitivityId == '1') {
      this.sensitivityCardList.push({
        med_Name: med,
        med_id: medId
      })
      this.isSensitive = true;

    } else {
      for (var i = 0; i < this.sensitivityCardList.length; i++) {
        if (this.sensitivityCardList[i].med_id == medId) {
          this.sensitivityCardList.splice(i, 1);
          break;
        }
      }
      this.isSensitive = false;
    }
  }

  addSterileCheckList() {
    if (this.sterile_area != undefined && this.sterile_result != undefined) {
      this.sterileChecklist.push({
        area: this.sterile_area,
        result: this.sterile_result,
      })

      this.sterile_area = "";
      this.sterile_result = "";
    } else {
      this.toastr.error("Please Fill The Required Fields")
    }
  }

  editSterileCheckList(sterile) {
    for (var i = 0; i < this.sterileChecklist.length; i++) {
      if (this.sterileChecklist[i].area == sterile.area) {

        this.sterile_area = sterile.area;
        this.sterile_result = sterile.result
        this.sterileChecklist.splice(i, 1);
        break;
      }
    }

  }

  deleteSterileCheckList(sterile) {
    for (var i = 0; i < this.sterileChecklist.length; i++) {
      if (this.sterileChecklist[i].area == sterile.area) {
        this.sterileChecklist.splice(i, 1);
        break;
      }
    }
  }

  fileChange($event): void {
    this.readThis($event.target);
  }

  readThis(inputValue: any): void {
    var file = inputValue.files[0];
    var myReader: FileReader = new FileReader();

    var fileval = file.type.split('/');
    this.fileType = fileval[1] == "jpeg" ? "jpg" : fileval[1];

    if (file.type == "image/jpeg" || file.type == "image/png" || file.type == "video/mp4") {
      myReader.onloadend = (e) => {
        var fileSize = Math.round(inputValue.files[0].size / 1024);
        if (fileSize >= 1024) {
          this.toastr.error(Message_data.maxOnembImg);
          $("#file").val('');
          this.imgStr = null;
        } else {
          this.imgStr = myReader.result;
          $('#profile_img').attr('src', myReader.result as string);
        }
      }
      myReader.readAsDataURL(inputValue.files[0]);
    } else {
      this.toastr.error(Message_data.appSupportsJPGorPng);
    }
  }

  growthNogrowth(value) {
    if (value == 'nogrowth') {
      this.isDisabled = true;
      this.no_growthFlag = true;

      this.organism_code_Iso1 = "";
      this.organism_desc_Iso1 = "";
      this.colony_count_1 = "";
      this.culture_code_1 = "Sterile after 2 overnight incubation.";
      this.gram_stain_1 = "";
      this.culture_report_notes_1 = "";

      this.organism_code_Iso2 = "";
      this.organism_desc_Iso2 = "";
      this.colony_count_2 = "";
      this.culture_code_2 = "Sterile after 2 overnight incubation.";
      this.gram_stain_2 = "";
      this.culture_report_notes_2 = "";

      this.organism_code_Iso3 = "";
      this.organism_desc_Iso3 = "";
      this.colony_count_3 = "";
      this.culture_code_3 = "Sterile after 2 overnight incubation.";
      this.gram_stain_3 = "";
      this.culture_report_notes_3 = "";

    } else {
      this.isDisabled = false;
      this.no_growthFlag = false;
      this.culture_code_1 = "";
    }
  }

}
