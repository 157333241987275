import { Component, OnInit, ViewChild } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Helper_Class } from '../../helper_class';
import { Date_Formate, Time_Formate, Time_Formate_Session } from '../../../assets/js/common';
import { ipaddress } from '../../ipaddress';
import { Location } from '@angular/common';
import { Message_data } from '../../../assets/js/Message_data';
import { ToastrService } from 'ngx-toastr';
import { Nurse_Helper } from '../Nurse_Helper';
import { NurseService } from '../nurse-view/nurse.service';
import { ClientCancelAppointmentComponent } from 'src/app/client/client-cancel-appointment/client-cancel-appointment.component';
import { MatDialog } from '@angular/material/dialog';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
import { Doc_Helper } from 'src/app/Doctor_module/Doc_Helper';
import { Diet_Helper } from 'src/app/Diet_module/Diet_Helper';

@Component({
  selector: 'app-nurse-doc-app-view',
  templateUrl: './nurse-doc-app-view.component.html',
  styleUrls: ['./nurse-doc-app-view.component.scss']
})
export class NurseDocAppViewComponent implements OnInit {
  editorStyle = {
    height: '210px;',
  };
  content;
  htmlString: string = '';
  modules = {
    toolbar: [
      ['bold', 'italic', 'underline'], // toggled buttons
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ align: [] }],
    ],
  };
  spo2colorstyle: boolean;
  public barcode_flag:boolean=false;
  public barcode_url;
  public barcode;
  public bmr;
  public relationship_name;
  public clientName: string;
  public profile_image;
  public previousflag:boolean=true;
  public vitals_List:any=[];
  public vitalsFlag:boolean;
  public previousvisits:any=[]
  public hosp_address1;
  public address2;
  public hcm_city_id;
  public hcm_country_id;
  public hcm_state_id;
  public state;
  public country;
  public city;
  public zipcode;
  public rel_city;
  public rel_location;
  public rel_state;
  public rel_country;
  public rel_address1;
  public rel_address2;
  public doc_id;
  public client_reg_id;
  public clnt_gender;
  public doc_appointment_id;
  public hptl_id;
  public mobile;

  public hosp_address2;
  public hosp_location;
  public hosp_city;
  public hosp_state;
  public hosp_country;
  public hosp_zipcode;
  public salutation;
  public spl_id;
  public doctorname;
  public location_desc;
  public dob;
  public city_desc;
  public state_desc;
  public hcmzipcode;
  public country_desc;
  public address1;
  public age;
  public gender: string;
  public phoneNo: string;
  public bloodGroup: string;
  public relationType: string;
  public symtomes: string;
  public treatementType: string;
  public spl: string;
  public doctorName: string;
  public hospitalName: string;
  public appDate: string;
  public session: string;
  public docAppID: string;
  public tokenNo: string;
  public appID: any;
  public chiefCompalaint: string;
  public presentIllness: string;
  public medicalHistory: string;
  public familyHistory: string;
  public physicalExamination: string;
  public height;
  public heightMesure: string;
  public weight;
  public weightMesure: string;
  public temprature: string;
  public bmi;
  public encHeight;
  public encWeight;
  public tokenFlag: boolean
  public currentDatetime: string;
  public confirmflag: boolean;
  public loader: boolean;
  public spo2;
  public pulse;
  public cvs_txt;
  public rstxt;
  public cns_txt;
  public abd_txt;
  public pr_txt;
  colorstyle: boolean;
  BP01: any;
  BP02: any;
  siscolorstyle: boolean;
  diocolorstyle: boolean;
  public temperature : string;
  tempcolorstyle: boolean;
  public mrno;
  public userinfo;
  public applist;
  public medicalprescriptionflag:boolean=false;
  public reportflag:boolean=false;
  constructor(public dialog: MatDialog, public http: Http, public toastr: ToastrService, public messageService: MenuViewService,public nurseservice: MenuViewService) {
    this.tokenFlag = true;
    this.loader = false;
  }


  ngOnInit() {
    this.heightMesure = 'cms';
    this.weightMesure = 'kgs';
    this.userinfo=Helper_Class.getInfo();
    // console.log("user info-->"+JSON.stringify(this.userinfo))
    if (Helper_Class.getInfo().both_modules.length != 0) {
      var Moduleidlist;
      Moduleidlist = Helper_Class.getInfo().both_modules;
      for (var i = 0; i < Moduleidlist.length; i++) {    
        // console.log("user Moduleidlist-->"+JSON.stringify(Moduleidlist[i].module_id))   
        if (Moduleidlist[i].module_id == "6" || Moduleidlist[i].module_id == "119") {
          this.medicalprescriptionflag = true;
        }  
        if (Moduleidlist[i].module_id == "158") {
          this.reportflag = true;
        }       
      }
    }
    this.applist=Nurse_Helper.getDoctorAppoinmentList().list;
    console.log("check status --"+JSON.stringify(this.applist))
    this.appID = Nurse_Helper.getDoctorAppoinmentList().app_id;
 //   if(this.applist.status !=)
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate',
      JSON.stringify({
        country: ipaddress.country_code.toString()
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.current_date != null) {
            this.currentDatetime = obj.current_date;
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      )
    this.get_app();
  }

  backClicked() {
    this.messageService.sendMessage("doctorAppList");
  }

  isReadonly() {
    return true;
  }
  public specialization;
  get_app() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'appointment/viewapp/',
      JSON.stringify({
        doc_app_id: this.appID,
        type: "doctor",
        home_care: "0",
        country: ipaddress.country_code,
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          console.log("patient details == " + JSON.stringify(obj))
          if (obj != null) {
            this.loader = true;
            if (obj.middle_name != undefined || obj.middle_name != null) {
              this.clientName = encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.middle_name) + " " + encrypt_decript.Decript(obj.last_name);
             // this.patient_name=this.clientName;
            } else {
              this.clientName = encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.last_name);
             // this.patient_name=this.clientName;
            }
            this.spl_id=obj.spl_id;
            this.salutation=obj.salutation;
            if(obj.doc_appointment_id != undefined){
              this.doc_appointment_id=obj.doc_appointment_id
            }
            if (obj.dr_middle_name != undefined || obj.dr_middle_name != null) {
              this.doctorname = obj.dr_first_name + " " + obj.dr_middle_name + " " + obj.dr_last_name;
            } else {
              this.doctorname = obj.dr_first_name + " " + obj.dr_last_name;
            }
            if(obj.profile_image != undefined){
              this.profile_image = ipaddress.Ip_with_img_address + obj.profile_image;
            }
            this.client_reg_id =obj.client_reg_id;
            this.clnt_gender= encrypt_decript.Decript(obj.gender)
            if(obj.dob != undefined){
              this.dob=obj.dob 
            }
            if(obj.dob != undefined){
              this.dob=Date_Formate(obj.dob)
            }
            if(obj.specialization != undefined){
              this.specialization=obj.specialization 
            }
       
            if(obj.hosp_address1 != undefined){
              this.hosp_address1=obj.hosp_address1
            }
            if(obj.hosp_address2 != undefined){
              this.hosp_address1=obj.hosp_address2
            }
             if(obj.hosp_location != undefined){
              this.hosp_location=obj.hosp_location
            }
            if(obj.relation != undefined){
              this.relationship_name=obj.relation
            }
            if(obj.hosp_city != undefined){
              this.hosp_city=obj.hosp_city
            }
            if(obj.hosp_state != undefined){
              this.hosp_state=obj.hosp_state
            }
             if(obj.hosp_country != undefined){
              this.hosp_country=obj.hosp_country
            }
            if(obj.doc_id != undefined){
              this.doc_id=obj.doc_id
            }
            if(obj.mobile != undefined){
              this.mobile=encrypt_decript.Decript(obj.mobile)
            }
           // public doc_id;
         
           if(obj.barcode != undefined){
            this.barcode_flag = true;
            this.barcode_url = ipaddress.Ip_with_img_address + obj.barcode;
            this.barcode=obj.barcode;
           }
            //hosp_zipcode
            if(obj.hosp_zipcode != undefined){
              this.hosp_zipcode=obj.hosp_zipcode
            }
            this.mrno   =obj.patient_id;
            //if(this)
            this.age = obj.age;
            this.gender = encrypt_decript.Decript(obj.gender);
            this.phoneNo = encrypt_decript.Decript(obj.mobile);
            this.bloodGroup = obj.blood_desc;
            this.relationType = obj.relation;
            this.symtomes = obj.symptoms;
            this.height=obj.height;
        
            this.treatementType = obj.medicare_name;
            this.spl = obj.specialization;
            if (obj.dr_middle_name != undefined || obj.dr_middle_name != null) {
              this.doctorName = obj.dr_first_name + " " + obj.dr_middle_name + " " + obj.dr_last_name;
            } else {
              this.doctorName = obj.dr_first_name + " " + obj.dr_last_name;
            }
            this.hospitalName = obj.hptl_name;
            this.appDate = Date_Formate(obj.date);
            this.session = encrypt_decript.Decript(obj.session);
            if (obj.token_count != undefined && obj.token_count == "true") {
              this.tokenFlag = false;
              this.tokenNo = obj.token;
            }
            if(obj.hptl_id != undefined){
              this.hptl_id=obj.hptl_id;
            }
          
            if (obj.height != undefined) {
              this.height = obj.height;
              this.heightMesure = obj.height_measure;
            }
            if (obj.height != undefined) {
              this.height = obj.height;
              this.heightMesure = obj.height_measure;
            }


            if (obj.weight != undefined) {
              this.weight = obj.weight;
              this.weightMesure = obj.weight_measure;
            }
            if (obj.bmi != undefined) {
              this.bmi = obj.bmi;
              
            }
            if (obj.bmr != undefined) {
              this.bmr = obj.bmr;
              
            }

            if (obj.temparature != undefined) {
              this.temprature = obj.temparature;
              var value = parseInt(this.temprature)
              if (value > 100) {
                this.colorstyle = true;

              } else {
                this.colorstyle = false;
              }
            }

            if (obj.bp != undefined) {
              const [systolic, diastolic] = obj.bp.split('/').map(value => parseInt(value));
              this.BP01 = systolic;
              this.BP02 = diastolic;
              var bpvalue1 = this.BP01;
              var bpvalue2 = this.BP02;

              if (bpvalue1 > 160 || bpvalue1 < 100) {
                this.siscolorstyle = true;
              }
              else {
                this.siscolorstyle = false;
              }

              if (bpvalue2 > 100 || bpvalue2 < 60) {
                this.diocolorstyle = true;
              }
              else {
                this.diocolorstyle = false;
              }
            }
            if (obj.spo2 != undefined) {
              this.spo2 = obj.spo2
            }
            if (obj.cvs != undefined) {
              this.cvs_txt = obj.cvs
            }
            if (obj.rs != undefined) {
              this.rstxt = obj.rs
            }

            if (obj.pulse != undefined) {
              this.pulse = obj.pulse
            }
            if (obj.cns != undefined) {
              this.cns_txt = obj.cns
            }
            if (obj.abd != undefined) {
              this.abd_txt = obj.abd
            }
            if (obj.pr != undefined) {
              this.pr_txt = obj.pr
            }

            if (obj.bmi != undefined) {
              this.bmi = obj.bmi;
            }
            if (obj.complaints != undefined) {
              this.chiefCompalaint = obj.complaints;
            }
            if (obj.present_illness != undefined) {
              this.presentIllness = obj.present_illness;
            }
            if (obj.medical_history != undefined) {
              this.medicalHistory = obj.medical_history;
            }
            if (obj.family_history != undefined) {
              this.familyHistory = obj.family_history;
            }
            if (obj.physical_exam != undefined) {
              this.physicalExamination = obj.physical_exam;
            }
          
            if(obj.location_desc != undefined){
              this.location_desc = obj.location_desc
            }
            if(obj.city_desc != undefined){
              this.city_desc = obj.city_desc
            }
            if(obj.state_desc != undefined){
              this.state_desc = obj.state_desc
            }
            if(obj.country_desc != undefined){
              this.country_desc = obj.country_desc
            }
            if(obj.rel_zipcode != undefined){
              this.zipcode = encrypt_decript.Decript(obj.rel_zipcode)
            }
            if(obj.zipcode != undefined){
              this.hcmzipcode = encrypt_decript.Decript(obj.zipcode)
            }
             if (obj.city != undefined) {
              this.city = obj.city;
            }
            if (obj.state != undefined) {
              this.state = obj.state;
            }
            if (obj.country != undefined) {
              this.country = obj.country;
            }
            if (obj.hcm_city_id != undefined) {
              this.hcm_city_id = obj.hcm_city_id;
            }
            if (obj.hcm_state_id != undefined) {
              this.hcm_state_id = obj.hcm_state_id;
            }
            
            if (obj.hcm_country_id != undefined) {
              this.hcm_country_id = obj.hcm_country_id;
            }
            if (obj.rel_country != undefined) {
              this.rel_country = obj.rel_country;
            }
            if (obj.rel_city != undefined) {
              this.rel_city = obj.rel_city;
            }
            if (obj.city_desc != undefined) {
              this.city_desc = obj.city_desc;
            }
            if (obj.rel_state != undefined) {
              this.rel_state = obj.rel_state;
            }
            if (obj.state_desc != undefined) {
              this.state_desc = obj.state_desc;
            }
            if (obj.country_desc != undefined) {
              this.country_desc = obj.country_desc;
            }
            if (obj.prev_visits != undefined) {
              this.previousflag=true;
              for (var k = 0; k < obj.prev_visits.length; k++){
                if(obj.prev_visits[k].visit_date != undefined){
                  this.previousvisits.push({
                    visit_date:Date_Formate(obj.prev_visits[k].visit_date),
                    doc_name:obj.prev_visits[k].doc_name,
                    visit_purpose:obj.prev_visits[k].visit_purpose != undefined ?obj.prev_visits[k].visit_purpose:"",
                    specialization_name:obj.prev_visits[k].specialization_name,
                    app_type:obj.prev_visits[k].app_type,
                    spl_id:obj.prev_visits[k].spl_id,
                    doc_reg_id:obj.prev_visits[k].doc_reg_id,
                  })
                }
              }            

              
            }else{
              this.previousflag=false;
          
            }
            if(obj.vital_log != undefined){
              this.vitalsFlag=true;
              for (var i = 0; i < obj.vital_log.length; i++){
                var dateval = obj.vital_log[i].date.split("-");
                if(obj.vital_log[i].weight != "" || obj.vital_log[i].weight != undefined){
                  // if(this.BP_01 != undefined && this.BP_02 != undefined){
                  //   this.bpvalue =  this.BP_01 + " / " + this.BP_02
                  // }
                  // else if(this.BP_01 != undefined){
                  //   this.bpvalue =  this.BP_01
                  // } else if(this.BP_02 != undefined){
                  //   this.bpvalue =  this.BP_02
                  // }
                  this.vitals_List.push({
                    sNo: i + 1,
                    height: obj.vital_log[i].height + " " + obj.vital_log[i].hmeasure,
                    weight: obj.vital_log[i].weight + " " + obj.vital_log[i].wmeasure,
                    bmi : obj.vital_log[i].bmi,
                    temperature: obj.vital_log[i].temparature,
                    bloodPressure: obj.vital_log[i].bp,
                    SPO2: obj.vital_log[i].spo2,
                    pulse: obj.vital_log[i].pulse,
                    Cvs: obj.vital_log[i].cvs,
                    Rs: obj.vital_log[i].rs,
                    Cns: obj.vital_log[i].cns,
                    ABD: obj.vital_log[i].abd,
                    date:dateval[2]+"-"+dateval[1]+"-"+dateval[0],
                    time:Time_Formate(obj.vital_log[i].time)
                  })
                }
              }
            }
            if(encrypt_decript.Decript(obj.rel_address2) !=""){
              var addresscheck2 = encrypt_decript.Decript(obj.rel_address2);

            }
            var addresscheck1 = encrypt_decript.Decript(obj.rel_address1);
            this.rel_address2=(obj.rel_address2)
            this.rel_address1=(obj.rel_address1)
            if(addresscheck2 != undefined){
              this.address1 = addresscheck1+""+ addresscheck2;
            }else{
              this.address1 = addresscheck1;
            }
          }
        });
  }

  tempcolor(value){
    if(value >100){
      this.colorstyle=true;

    } else {
      this.colorstyle=false;
    }
  }

  cancel_click() {
    const dialogRef = this.dialog.open(ClientCancelAppointmentComponent, {
      width: '21%',
      height: '180px',
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data.toString().trim() == "" && data.length == 0) {
        this.toastr.error(Message_data.appcancelreason);
      } else if (data.length > 150) {
        this.toastr.error(Message_data.maxlen150);
      } else {
        var cancel_list = [];
        cancel_list.push(
          this.appID
        );
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp.toString() + 'appointment/cancelapp',
          JSON.stringify({
            doc_cancel: cancel_list,
            home_care: "0",
            reason: data
          }),
          { headers: headers })
          .subscribe(
            response => {
              var obj = JSON.parse(response["_body"]);
              if (obj.key != null) {
                if (obj.key == "1") {
                  this.toastr.success(Message_data.appcancel);
                  this.messageService.sendMessage("doctorAppList");
                }
              }
            },
            error => {
              this.toastr.error(Message_data.getNetworkMessage());
            }
          )
      }
    });
  }

  sistol(value){
    if( value > 160 || value < 100 ){
      this.siscolorstyle=true;

    } else {
      this.siscolorstyle=false;
    }
  }

  diostol(value){
    if( value > 100 || value < 60 ){
      this.diocolorstyle=true;
    } else {
      this.diocolorstyle=false;
    }
  }

  appointment_update() {
    var appflag = true;
    var error = "Please fill ";
    if (this.height == undefined || this.height == "") {
      error = error + "Height, ";
      appflag = false;
    }
    if (this.heightMesure == undefined || this.heightMesure == "") {
      error = error + "Height measure,";
      appflag = false;
    }
    if (this.weight == undefined || this.weight == "") {
      error = error + "Weight,";
      appflag = false;
    }
    if (this.weightMesure == undefined || this.weightMesure == "") {
      error = error + "Weight measure,";
      appflag = false;
    }

    // if (this.temprature == undefined || this.temprature == "") {
    //   error = error + "Temparature,";
    //   appflag = false;
    // }

    if (appflag == false) {
      this.toastr.error(error);
    } else {
      var bpval;
      var sendata;
      if (this.height != "") {
        console.log("check data ==="+JSON.stringify({
          height: this.height,
          height_measure: this.heightMesure,
          weight: this.weight,
          weight_measure: this.weightMesure,
          bmi: this.bmi,
          temparature: this.temprature,
          spo2: this.spo2,
          pulse: this.pulse,
          rstxt: this.rstxt,
          cvs: this.cvs_txt,
          cns: this.cns_txt,
          abd: this.abd_txt,
          prtxt: this.pr_txt,
          complaints: this.chiefCompalaint,
          present_illness: this.presentIllness,
          medical_history: this.medicalHistory,
          family_history: this.familyHistory,
          physical_exam: this.physicalExamination,
         // blood_pressure: bpval
        }))
        sendata={
          height: this.height,
          height_measure: this.heightMesure,
          weight: this.weight,
          weight_measure: this.weightMesure,
          bmi: this.bmi,
          temparature: this.temprature,
          spo2: this.spo2,
          pulse: this.pulse,
          rstxt: this.rstxt,
          cvs: this.cvs_txt,
          cns: this.cns_txt,
          abd: this.abd_txt,
          prtxt: this.pr_txt,
          complaints: this.chiefCompalaint,
          present_illness: this.presentIllness,
          medical_history: this.medicalHistory,
          family_history: this.familyHistory,
          physical_exam: this.physicalExamination,
          //blood_pressure: bpval,
          doc_appointment_id: this.appID,
        }
        if(this.BP01 != undefined && this.BP02 != undefined ){
          if(this.BP01 != "" && this.BP02 != " " ){
          sendata.blood_pressure = this.BP01 != "" ? this.BP01 + "/" + this.BP02 : "";
        }
      }
        console.log("check sendata3333333333333"+JSON.stringify(sendata))

        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp.toString() + 'appointment/updapp',
          JSON.stringify(sendata),
          { headers: headers })
          .subscribe(
            response => {
              var obj = JSON.parse(response["_body"]);
              console.log("check update"+JSON.stringify(obj))
              if (obj.key != null) {
                if (obj.key == "1") {
                  this.toastr.success(Message_data.apptUpdSuccess);
                  this.messageService.sendMessage("doctorAppList");
                }else{
                  this.toastr.error(Message_data.getNetworkMessage());
                }
              }else{
                this.toastr.error(Message_data.getNetworkMessage());
              }
            },
            error => {
              this.toastr.error(Message_data.getNetworkMessage());
            }
          )
      }else{
        this.toastr.error("Enter mandatory field")
      }


    }
  }
  calories_data() {
    if ((this.height != undefined && this.height.length != 0)
      || (this.weight != undefined && this.weight.length != 0)
      || (this.chiefCompalaint != undefined && this.chiefCompalaint.length != 0)
      || (this.familyHistory != undefined && this.familyHistory.length != 0)
      || (this.presentIllness != undefined && this.presentIllness.length != 0)
      || (this.medicalHistory != undefined && this.medicalHistory.length != 0)
      || (this.physicalExamination != undefined && this.physicalExamination.length != 0)) {
      this.confirmflag = false;
      if (this.height != undefined && this.height.length != 0) {
        this.encHeight = encrypt_decript.Encript(this.height);
      }
      if (this.weight != undefined && this.weight.length != 0) {
        this.encWeight = encrypt_decript.Encript(this.weight);
      }
    } else {
      this.confirmflag = true;
    }
    if (this.height == undefined || this.height == "" || this.weight == undefined || this.weight == "") {
      this.bmi = "";
    } else {
      if (this.height != undefined) {
        if (this.heightMesure == "cms") {
          this.height = this.height.replace(/[^0-9]/g, '');
        } else {
          this.height = this.height.replace('/[^-0-9\.]/g', '');
          if (this.height.indexOf('.') !== -1) {
            if (this.height.split('.').length > 2) {
              this.height = this.height.height.slice(0, -1);
            }
          }
        }
        if (this.heightMesure == "cms") {
          if (parseFloat(this.height) > 300) {
            this.height = this.height.slice(0, -1);
          }
        } else {
          if (parseFloat(this.height) > 100) {
            this.height = this.height.slice(0, -1);
          }
        }
      }
      if (this.weightMesure == "kgs" && this.heightMesure == "inch") {
        var pound = this.weight * 2.20462;
        var Height_txt = parseFloat(this.height)
        var dat = pound / (Height_txt * Height_txt);
        var to = dat * 703;
        this.bmi = Math.round(to);
      } else if (this.weightMesure == "kgs" && this.heightMesure == "cms") {
        var meter = parseFloat(this.height) / 100.00;
        var to = parseFloat(this.weight) / (meter * meter);
        this.bmi = Math.round(to);
      } else if (this.weightMesure == "lbs" && this.heightMesure == "inch") {
        var Height_txt = parseFloat(this.height);
        var dat = this.weight / (Height_txt * Height_txt);
        var to = dat * 703;
        this.bmi = Math.round(to);
      } else if (this.weightMesure == "lbs" && this.heightMesure == "cms") {
        var Height_txt = parseFloat(this.height);
        var inch = Height_txt * 0.393701;
        var dat = this.weight / (inch * inch);
        var to = dat * 703;
        this.bmi = Math.round(to);
      }
    }
  }
  settempcolor(temprature){
    if(temprature >100){
      this.tempcolorstyle=true;
    }else{
      this.tempcolorstyle=false;
    }
  }

  Write_prescription() {     

    var inpat_pres: any = [];
    inpat_pres = {
      prov_id: this.userinfo.user_id,
      client_id: this.client_reg_id,
      relation_id: '1',
      // inpatient_id:this.inpatId,
     // salutation: this.clnt_sal,
      Gender_data: this.clnt_gender,
      client_name: this.clientName,
      Age_data: this.age,
      app_id: this.doc_appointment_id,
      Client_id: this.client_reg_id,
      rel_id: '1',
      sub_id: '',
      Case_Clnt_dob: this.dob,
      spl_id: this.spl_id,
      hptl_clinic_id: this.hptl_id,
      mobile: this.mobile.toString(),
      spl: this.spl,
      // temparature: this.temp_txt,
      // height: this.height_txt,
      // height_measure: this.hmeasure,
      // weight: this.weight_txt,
      // weight_measure: this.wmeasure,
      // bmi: this.bmi_txt,
      // bp: bpval,
      // symptoms: this.clnt_symptom,
      // medicare_name: this.treatement_type,
      // Appoint_Date: Date_Formate(this.CurrentDatetime),
      addmed: 'write',
      // AppFlow: 'InpatMedPres',
      // ref_by:this.refer_txt,
      // covid_symp: this.finalcovidsymp,
      // covid_comorb: this.finalcomorbList,
      // covid_observ: this.observList,
      // is_covid: this.covid_type,
      // covid_dur: this.covid_duration,
      location: this.hosp_location,
      address1: this.address1,
      address2: this.address2,
      city: this.city,
      state: this.state,
      country: this.country,
      zipcode: this.hcmzipcode,
      // refer_by: this.refer_txt,
      country_id:this.hcm_country_id,
      state_id:this.hcm_state_id,
      city_id:this.hcm_city_id,
      
      user_type:this.userinfo.user_type,
      salutation:this.salutation,
      doctor_name:this.doctorname,
      clientaddress1: this.rel_address1,
      clientaddress2: this.rel_address2,
      clientlocation: this.location_desc,
      clientcity: this.city_desc,
      clientstate: this.state_desc,
      clientcountry: this.country_desc,
      clientcountry_id: this.rel_country,
      clientstate_id: this.rel_state,
      clientcity_id: this.rel_city,
      clientzipcode: this.zipcode,
      AppFlow:"Nurseoutpatient",
      relationship_name:this.relationship_name,      
      pat_id:this.mrno,
      barcode:this.barcode
      //doc_id:this.doc_id,
    
    };
    Doc_Helper.setClient_Info(inpat_pres);
    console.log("************"+JSON.stringify(Doc_Helper.getClient_Info()))
    Doc_Helper.setDiagAppflow('medical');
    this.messageService.sendMessage('docpresnocase');
  }
  reportview(){
    var inpat_pres: any = [];
    inpat_pres = {
      prov_id: this.userinfo.user_id,
      client_id: this.client_reg_id,
      relation_id: '1',
      // inpatient_id:this.inpatId,
     // salutation: this.clnt_sal,
      Gender_data: this.clnt_gender,
      client_name: this.clientName,
      Age_data: this.age,
      app_id: this.doc_appointment_id,
      Client_id: this.client_reg_id,
      rel_id: '1',
      sub_id: '',
      Case_Clnt_dob: this.dob,
      spl_id: this.spl_id,
      hptl_clinic_id: this.hptl_id,
      mobile: this.mobile.toString(),
      spl: this.spl,
      // temparature: this.temp_txt,
      // height: this.height_txt,
      // height_measure: this.hmeasure,
      // weight: this.weight_txt,
      // weight_measure: this.wmeasure,
      // bmi: this.bmi_txt,
      // bp: bpval,
      // symptoms: this.clnt_symptom,
      // medicare_name: this.treatement_type,
      // Appoint_Date: Date_Formate(this.CurrentDatetime),
      addmed: 'write',
      // AppFlow: 'InpatMedPres',
      // ref_by:this.refer_txt,
      // covid_symp: this.finalcovidsymp,
      // covid_comorb: this.finalcomorbList,
      // covid_observ: this.observList,
      // is_covid: this.covid_type,
      // covid_dur: this.covid_duration,
      location: this.hosp_location,
      address1: this.address1,
      address2: this.address2,
      city: this.city,
      state: this.state,
      country: this.country,
      zipcode: this.hcmzipcode,
      // refer_by: this.refer_txt,
      country_id:this.hcm_country_id,
      state_id:this.hcm_state_id,
      city_id:this.hcm_city_id,
      
      user_type:this.userinfo.user_type,
      salutation:this.salutation,
      doctor_name:this.doctorname,
      clientaddress1: this.rel_address1,
      clientaddress2: this.rel_address2,
      clientlocation: this.location_desc,
      clientcity: this.city_desc,
      clientstate: this.state_desc,
      clientcountry: this.country_desc,
      clientcountry_id: this.rel_country,
      clientstate_id: this.rel_state,
      clientcity_id: this.rel_city,
      clientzipcode: this.zipcode,
      AppFlow:"Nurseoutpatient",
      relationship_name:this.relationship_name,
      pat_id:this.mrno,
      barcode:this.barcode
      //doc_id:this.doc_id,
    
    };
    Doc_Helper.setClient_Info(inpat_pres);
    console.log("************"+JSON.stringify(Doc_Helper.getClient_Info()))
    Doc_Helper.setDiagAppflow('medical');
    this.nurseservice.sendMessage('nursereports');
  }
  changelinemgmt = (event) => {
    this.chiefCompalaint = event.html;
    if (this.chiefCompalaint.length > 250) {
      this.chiefCompalaint = this.chiefCompalaint.substring(0, 250);
      console.log(`Content truncated to  characters.`);
    }
  };
  changelinemgmt1 = (event) => {
    this.presentIllness = event.html;
    if (this.presentIllness.length > 250) {
      this.presentIllness = this.presentIllness.substring(0, 250);
      console.log(`Content truncated to  characters.`);
    }
  };
  changelinemgmt2 = (event) => {
    this.medicalHistory = event.html;
    if (this.medicalHistory.length > 250) {
      this.medicalHistory = this.medicalHistory.substring(0, 250);
      console.log(`Content truncated to  characters.`);
    }
  };
  changelinemgmt3 = (event) => {
    this.familyHistory = event.html;
    if (this.familyHistory.length > 250) {
      this.familyHistory = this.familyHistory.substring(0, 250);
      console.log(`Content truncated to  characters.`);
    }
  };
  changelinemgmt4 = (event) => {
    this.physicalExamination = event.html;
    if (this.physicalExamination.length > 250) {
      this.physicalExamination = this.physicalExamination.substring(0, 250);
      console.log(`Content truncated to  characters.`);
    }
  };
  diettrack(){
    var inpat_pres: any = [];
    inpat_pres = {
      prov_id: this.userinfo.user_id,
      client_id: this.client_reg_id,
      relation_id: '1',
      height:this.height,
      weight:this.weight,
      height_measure:this.heightMesure,
      weight_measure: this.weightMesure,
      bmi: this.bmi,
      bmr:this.bmr,
      
      // inpatient_id:this.inpatId,
     // salutation: this.clnt_sal,
      Gender_data: this.clnt_gender,
      client_name: this.clientName,
      Age_data: this.age,
      app_id: this.doc_appointment_id,
      Client_id: this.client_reg_id,
      rel_id: '1',
      sub_id: '',
      Case_Clnt_dob: this.dob,
      spl_id: this.spl_id,
      hptl_clinic_id: this.hptl_id,
      mobile: this.mobile.toString(),
      spl: this.spl,     
      addmed: 'write',  
      location: this.hosp_location,
      address1: this.address1,
      address2: this.address2,
      city: this.city,
      state: this.state,
      country: this.country,
      zipcode: this.hcmzipcode,
      country_id:this.hcm_country_id,
      state_id:this.hcm_state_id,
      city_id:this.hcm_city_id,      
      user_type:this.userinfo.user_type,
      salutation:this.salutation,
      doctor_name:this.doctorname,
      clientaddress1: this.rel_address1,
      clientaddress2: this.rel_address2,
      clientlocation: this.location_desc,
      clientcity: this.city_desc,
      clientstate: this.state_desc,
      clientcountry: this.country_desc,
      clientcountry_id: this.rel_country,
      clientstate_id: this.rel_state,
      clientcity_id: this.rel_city,
      clientzipcode: this.zipcode,
      AppFlow:"Nurseoutpatient",
      relationship_name:this.relationship_name,      
      pat_id:this.mrno,
      barcode:this.barcode,
    
    };
    Diet_Helper.setClient_Info(inpat_pres);
    console.log("************"+JSON.stringify(Diet_Helper.getClient_Info()))
    Diet_Helper.setDiagAppflow('medical');
    this.messageService.sendMessage('dietaccess');
  }
  spo2value(value) {
    if (value < 75) {
      this.spo2colorstyle = true;
    } else {
      this.spo2colorstyle = false;
    }
  }
}
