import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule} from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import {MatDialogModule} from '@angular/material/dialog';
import { ViewmedprespageComponent } from './viewmedprespage.component';
import { DataTablesModule } from 'angular-datatables';

@NgModule({
    imports:[MatDialogModule,CommonModule,MatInputModule ,MatSelectModule,MatFormFieldModule,MatIconModule,MatButtonModule,FormsModule,DataTablesModule],
    declarations:[ViewmedprespageComponent],
    exports:[ViewmedprespageComponent]
})
export class ViewmedprespageModule{   
   
}