<div class="row" style="margin:0 auto; width:100%">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white ">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Tests</h5>
          </div>
          <div class="headerButtons">
            <img src="../../../assets/ui_icons/buttons/Back_button.svg" class="saveimgbtn_inpatinfo" (click)="back()" />
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div>
          <div class="">
            <div class="row">
              <div class="row" style="padding: 10px;">
                <div class="col-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                  <div class="cover_div" style="padding-bottom: 13px;">
                    <div class="header_lable">
                      Address details
                    </div>
                    <div class="content_cover">
                      <mat-label class="matlabel" id="reportpara"><strong>{{diagName}}</strong></mat-label><br>
                      <mat-label class="matlabel" id="reportpara">{{diagAddr}},{{diagLocation}},{{diagCityZipcode}}
                      </mat-label> <br>
                      <mat-label class="matlabel" id="reportpara">{{diagStateCountry}}</mat-label><br>
                      <mat-label class="matlabel" id="reportpara">{{diagTelephoneNo}}</mat-label><br>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-sm-6 col-md-7 col-lg-7 col-xl-7">
                  <div class="cover_div" style="padding-bottom: 13px;">
                    <div class="header_lable">
                      Client details
                    </div>
                    <div class="content_cover">
                      <div class="row">
                        <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <mat-label class="matlabel" id="reportpara"><strong>Name :</strong> {{clientName}}</mat-label>
                          <br>
                          <mat-label class="matlabel" [hidden]="ageFlag" id="reportpara"><strong>Age :</strong>
                            {{clientAge}}</mat-label><br>
                          <mat-label class="matlabel" id="reportpara"><strong>Gender :</strong> {{clientGender}}
                          </mat-label><br>
                          <mat-label class="matlabel" id="reportpara"><strong>Collected at :</strong> {{timeCollect}}
                          </mat-label><br>
                        </div>
                        <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <mat-label class="matlabel" id="reportpara"><strong>Date :</strong> {{date}}</mat-label><br>
                          <mat-label class="matlabel" id="reportpara"><strong>Referred by :</strong> {{referredBy}}
                          </mat-label><br>
                          <mat-label class="matlabel" id="reportpara"><strong>Client id :</strong> {{clientId}}
                          </mat-label><br>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div [hidden]="pathologyFlag">
              <div class="row">
                <p class="col-12"><b>Clinical test results</b></p>
              </div>
              <div class="row" *ngFor="let test of pathologyTestList">
                <div class="table-responsive " *ngFor="let test of pathologyTestList">
                  <div class="table-responsive dig_table_overflow">
                    <table id="bill_create" class="table table-nowrap table-sm billcreae_table">
                      <thead>
                        <tr>
                          <th>Test name</th>
                          <th>Reading</th>
                          <th>Unit</th>
                          <th>Referred value</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td colspan="4" style="text-align: left;"><b style="font-weight: bold;">{{test.test_name+"
                              test"}}</b></td>
                        </tr>
                        <tr *ngFor="let subtest of pathologySubTestList">
                          <td style="text-align: left;"><strong>{{subtest.sub_test_name}}</strong></td>
                          <td>{{subtest.reading}}</td>
                          <td>{{subtest.unit}}</td>
                          <td>
                            <div class="matlabel" id="viewhtml" [innerHtml]="subtest.test_range"></div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" [hidden]="radiologyFlag">
              <div *ngFor="let report of radiologyTestList">
                <mat-label class="matlabel"><strong>Report type :</strong> {{reportType}}</mat-label>
                <mat-label class="matlabel"><strong>Test name :</strong> {{report.test_name}}</mat-label>
                <div class="col-12">
                  <div *ngIf="reportImgFlag" class="col-6">
                    <p class="tervys_heading"><b>Attachment</b>
                    <p>
                      <img alt="" src="{{reportImg}}" class="img_radiology" />
                  </div>
                  <div class="row">
                    <mat-card id="cardcontent1" class="grid_border">
                      <mat-card-title id="cardheading1">
                        <div class="heading_cover">
                          <b class="tervys_heading tervys_heading1 ">Sequences</b>
                        </div>
                      </mat-card-title>
                      <mat-card-content>
                        <div class="row" style="padding: 10px;">
                          <div class="col-12 ">
                            <div class="matlabel" id="viewhtml" [innerHtml]="report.sequences"></div>
                          </div>
                        </div>
                      </mat-card-content>
                    </mat-card>
                    <mat-card id="cardcontent1" class="grid_border">
                      <mat-card-title id="cardheading1">
                        <div class="heading_cover">
                          <b class="tervys_heading tervys_heading1">Impression</b>
                        </div>
                      </mat-card-title>
                      <mat-card-content>
                        <div class="row" style="padding: 10px;">
                          <div class="col-12 ">
                            <div class="matlabel" id="viewhtml" [innerHtml]="report.impression"></div>
                          </div>
                        </div>
                      </mat-card-content>
                    </mat-card>
                  </div>
                </div>
              </div>
            </div>
            <hr style="border: 1px solid #06438a;">
            <div class="row">
              <div class="col-12">
                <mat-label class="matlabel"><strong>Reviewed by :</strong> {{reviewBy}}</mat-label><br>
                <mat-label class="matlabel"><strong>Signature :</strong><img alt="" src="{{reviewSign}}"
                    class="sign_img" /></mat-label>
              </div>
            </div>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>