import { Component, OnInit } from '@angular/core';
import { Helper_Class } from '../../helper_class';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ipaddress } from '../../ipaddress';
import { Message_data } from '../../../assets/js/Message_data';
import { FrontDesk_Helper } from 'src/app/FrontDesk_module/FrontDesk_Helper';

@Component({
  selector: 'app-operation-theatre',
  templateUrl: './operation-theatre.component.html',
  styleUrls: ['./operation-theatre.component.scss']
})
export class OperationTheatreComponent implements OnInit {
  public floor;
  public userInfo;
  public hospitalDetails;
  public rowCount;
  public otList:any=[];
  
  public otTypes:any=[];
  private otId:string="0";
  public otName: string;
  public otType: string;
  public otfloor: string;
  public otFacilities: string;
  editbuttondie: boolean = false;
  newbuttondie: boolean = false;
  deletebuttondie: boolean = false;
  printbuttondie: boolean = false;
  viewbuttondie: boolean = false;

  constructor(public http:HttpClient,public toastr:ToastrService) { }

  ngOnInit(): void {
    this.userInfo= Helper_Class.getInfo();
    this.hospitalDetails = Helper_Class.getHospitalId();
    this.getOperationTheaterType();
    this.getOtList();
// we want change the module Id
    if (FrontDesk_Helper.getmodules() != null) {
      for (var i = 0; i < FrontDesk_Helper.getmodules().length; i++) {
        if (FrontDesk_Helper.getmodules()[i].module_id == "92") {
          if (FrontDesk_Helper.getmodules()[i].edit == "1") {
            this.editbuttondie = true;
          }
          if (FrontDesk_Helper.getmodules()[i].create == "1") {
            this.newbuttondie = true;
          }
          if (FrontDesk_Helper.getmodules()[i].delete == "1") {
            this.deletebuttondie = true;
          }
          if (FrontDesk_Helper.getmodules()[i].print == "1") {
            this.printbuttondie = true;
          }
          if (FrontDesk_Helper.getmodules()[i].view == "1") {
            this.viewbuttondie = true;
          }
        }
      }
    }
  }

  getOtList(){
    this.otList = [];
    var send_data = {
      hptl_clinic_id:Helper_Class.getHospitalId()
    };
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'inpat/got',send_data,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          if(obj != undefined && obj.ot_details != undefined){
            this.otList = obj.ot_details;
          }
        });
  }

  editOtType(data){
    for(var i=0;i<this.otList.length;i++){
      if(data.ot_id == this.otList[i].ot_id){
        this.otId= data.ot_id;
        this.otName = data.ot_name;
        this.otType= data.ot_type_id;
        this.otfloor = data.floor;
        this.otFacilities = data.facilities;
        this.otList.splice(i,1);
      }
    }
  }

  deleteOtType(data){
    var send_data;
    send_data = {
      country:ipaddress.country_code.toString(),
      ot_id:data.ot_id,
      hptl_clinic_id: this.hospitalDetails,
      user_id:this.userInfo.user_id,
    }
   
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'inpat/dot',send_data,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log('delete',JSON.stringify(obj));
          if(obj.key == "1"){
            this.toastr.success(obj.result);
            this.getOtList();
          } else {
            this.toastr.error(obj.result);
          }
        });
  }

  addMaster(){
    var send_data;
    send_data = {
      ot_id:this.otId,
      ot_name: this.otName,
      ot_type_id: this.otType,
      floor: this.otfloor,
      facilities: this.otFacilities,
      hptl_clinic_id: this.hospitalDetails,
      country:ipaddress.country_code.toString(),
      user_id:this.userInfo.user_id,
    }
   
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'inpat/sot',send_data,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log('save',JSON.stringify(obj));
          if(obj.key == "1"){
            this.toastr.success(obj.result);
            this.getOtList();
          } else {
            this.toastr.error(obj.result);
          }
        });
  }

  // empty(data){
  //   var check:boolean = true;
  //   if(data.length != 0){
  //     for(var i = 0; i < data.length;i++){
  //       if(data[i].bed_no == ""){
  //         check = false;
  //       }
  //       if(data[i].rate == "0"){
  //         check = false;
  //       }
  //       if(check == false){
  //         data.splice(i,1);
  //       }
  //     }
  //   }
  //   return this.bedTableCount;
  // }

  // getSpecializations() {
  //   var headers = new HttpHeaders();
  //   headers.append('Content-Type', 'application/json');
  //   this.http.post(ipaddress.getIp + "adm/gsplbyhptl", { hptl_clinic_id: this.hospitalDetails},
  //     { headers: headers }).subscribe(
  //       data => {
  //         this. spl_array = [];
  //         var obj = JSON.parse(JSON.stringify(data));
          
  //         if (obj.specializations != null) {
  //           this.spl_array = obj.specializations;
  //           this.spl_id = this.spl_array[0].spl_id;
  //           this.spl = this.spl_array[0].spl_name;
  //           // this.getDoctorList(this.spl_id)
  //           this.getSurgeries();
  //           this.getSurgeons();
  //         }
  //       },
  //       error => {
  //         this.toastr.error("Unable to fetch country details, try again later");
  //       })
  // }

  // getSurgeons() {
  //   var send_data = {
  //     hospital_id: this.hospitalDetails
  //   }
  //   var headers = new HttpHeaders();
  //   headers.append('Content-Type', 'application/json');
  //   this.http.post(ipaddress.getIp + 'search/docbyhns', send_data,
  //     { headers: headers })
  //     .subscribe(
  //       response => {
  //         var obj = JSON.parse(JSON.stringify(response));
  //         // console.log("Surgeons "+JSON.stringify(obj))

  //         if (obj != null) {
  //           this.surgeon_list=[];
  //           for(var i=0;i<obj.doctors.length;i++){
  //             var docname;
  //             if(obj.doctors[i].middle_name != undefined){
  //               docname = obj.doctors[i].first_name+" "+obj.doctors[i].middle_name+" "+obj.doctors[i].last_name;

  //             } else {
  //               docname = obj.doctors[i].first_name+" "+obj.doctors[i].last_name;
  //             }
  //             this.surgeon_list.push({
  //               docname:docname,
  //               prov_id: obj.doctors[i].prov_id,
                
  //             })
  //           }
  //         }
  //       },
  //       error => {
  //         this.toastr.error(Message_data.getNetworkMessage());
  //       });
  // }

  // Change_Specialization(e) {
  //   this.spl_id = e;
  //   for (var i = 0; i < this.spl_array.length; i++) {
  //     if (this.spl_array[i].spl_id == this.spl_id) {
  //       this.spl = this.spl_array[i].spl_name;
  //       this.spl_id = this.spl_array[i].spl_id;
  //       this.getSurgeries();
  //     }
  //   }
  // }

  // getSurgeries() {
  //   var send_data = {
  //     spl_id: this.spl_id,
  //   }
  //   var headers = new HttpHeaders();
  //   headers.append('Content-Type', 'application/json');
  //   this.http.post(ipaddress.getIp + 'cardio/medproc', send_data,
  //     { headers: headers })
  //     .subscribe(
  //       response => {
  //         var obj = JSON.parse(JSON.stringify(response));
  //         // console.log("getSurgeries "+JSON.stringify(obj))

  //         if (obj != null) {
  //           this.surgery_list=[];
  //           for(var i=0;i<obj.med_procedures.length;i++){
  //             this.surgery_list.push({
  //               surgery_id:obj.med_procedures[i].procedure_id,
  //               surgery_name:obj.med_procedures[i].description,
  //             })
  //           }
  //         }
  //       },
  //       error => {
  //         this.toastr.error(Message_data.getNetworkMessage());
  //       });
  // }

  // getAnaestesiaTypes(){
  //   var headers = new HttpHeaders();
  //   headers.append('Content-Type', 'application/json');
  //   this.http.get(ipaddress.getIp + "inpat/gat",
  //     { headers: headers }).subscribe(
  //       data => {
  //         this.anaest_types = [];
  //         var obj =  JSON.parse(JSON.stringify(data));
  //         this.anaest_types = obj.anaesthesia_types;
  //         console.log("ANAESTHESIA TYPES "+JSON.stringify(this.anaest_types));
          
  //       },
  //       error => {
  //         this.toastr.error("Unable to fetch country details, try again later");
  //       })
  // }

  // getAnaestisiaDoctors(){
  //   var headers = new HttpHeaders();
  //   headers.append('Content-Type', 'application/json');
  //   this.http.post(ipaddress.getIp + "inpat/gad",{ hptl_clinic_id: this.hospitalDetails},
  //     { headers: headers }).subscribe(
  //       data => {
  //         this.anaest_doctors = [];
  //         var obj =  JSON.parse(JSON.stringify(data));
  //         this.anaest_doctors = obj.anaesthesia_doctors;
  //         console.log("ANAESTESIA EFFECTS "+JSON.stringify(this.anaest_doctors))
          
  //       },
  //       error => {
  //         this.toastr.error("Unable to fetch country details, try again later");
  //       })
  // }

  getOperationTheaterType() {
    var send_data = {
      hptl_clinic_id: this.hospitalDetails,
    }

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "inpat/gott", send_data,
      { headers: headers }).subscribe(
        data => {
          this.otTypes = [];
          var obj =  JSON.parse(JSON.stringify(data));  
          this.otTypes = obj.ot_types;        
          console.log("OperationTheater  "+JSON.stringify(this.otTypes))          
        },
        error => {
          this.toastr.error("Unable to fetch country details, try again later");
        })
  }
}
