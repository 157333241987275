<div class="row" style="width:100%;margin:0 auto;">
   <div class="col-12">
      <mdb-card>
         <mdb-card-header class="bg-white">
            <div class="headerCover">
               <div class="headerTilte app_list_header">
                  <h5 class="m-0" class="mainHeadingStyle">Outsource Payment mapping</h5>
               </div>
               <div class="headerButtons">
                  <img src="../../../assets/ui_icons/buttons/save_button.svg" class="saveimgbtn_inpatinfo"
                     (click)="saveOutsourcePayments()" />
               </div>
            </div>
         </mdb-card-header>
         <mdb-card-body>
            <div class="row">
               <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                  <mat-label class="matlabel">Outsourcing Type<br>
                     <select disableOptionCentering class="ipcss " required [(ngModel)]="outSrcType"
                        style="margin-left: 0px !important; margin-top: 3px !important;" (change)="showTests()">
                        <!-- <option value="Fixed Amount">Fixed Amount</option>
                        <option value="Percentage">Percentage</option>
                        <option value="Test wise Amount">Testwise Amount</option> -->
                        <option *ngFor="let type of outSrcTypeArray" value="{{type.outsource_type_id}}">
                           {{type.out_type_desc}}</option>
                     </select>
                  </mat-label>
               </div>
               <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                  <mat-label class="matlabel">Outsourcing Center<br>
                     <select disableOptionCentering class="ipcss " required [(ngModel)]="outSrcId"
                        style="margin-left: 0px !important; margin-top: 3px !important;"
                        (change)="serch_centerchange()">
                        <option disabled>Select</option>
                        <option *ngFor="let centerName of outsourceList" value="{{centerName.outsource_id}}">
                           {{centerName.centre_name}}</option>
                     </select>
                  </mat-label>
               </div>
               <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" *ngIf="!showTable">
                  <mat-label class="matlabel"> {{outSrcAmtLabel}}<br>
                     <input [(ngModel)]="outSrcAmt" type="text" class="ipcss " maxlength="50"
                        style="margin-left: 0px !important; margin-top: 3px !important;" />
                  </mat-label>
               </div>
            </div>
            <div *ngIf="noshow">
               <div class="container-fluid" >
                 <h2 class="tervys_heading2">Tests</h2>
                 <div class="row">
                    <div *ngFor="let value of dataArrayBloodTest"
                       class="col-2 card-container d-flex flex-row align-items-center justify-content-between">
                       <p style="margin: 0px !important;">{{value}}</p>

                    </div>
                 </div>
              </div>
             </div>
            <div *ngIf="showTable">
               <div class="container-fluid">
                  <div class="row d-flex justify-content-center masterdatabox">
                     <div *ngFor="let testtype of big_four_data" class="masterboxcol">
                        <div>
                           <table>
                              <thead style="
                                 position:sticky;
                                 width: 100%;
                                 z-index: 1000;">
                                 <tr>
                                    <th style="padding: 6px;" colspan="3">
                                       <p class="titletest text-center"><strong>{{testtype.tittle}}</strong></p>
                                 </tr>
                                 <tr>
                                    <th>Test Name</th>
                                    <th>Outsource Cost</th>
                                    <th>Insource Cost</th>
                                 </tr>
                              </thead>
                              <tbody id="salesTable">
                                 <ng-container *ngIf="testtype.Blood_test && testtype.Blood_test.length > 0">
                                    <tr *ngFor="let testtypee of testtype.Blood_test">
                                       <td>{{testtypee.test_name}}</td>
                                       <td>
                                          <mat-label class="matlabel">
                                             <input (keyup)="setInsourceValue()" [(ngModel)]="testtypee.outsource_cost"
                                                type="text" class="ipcss " maxlength="50" style="width: 125px;" />
                                          </mat-label>
                                       </td>
                                       <td>
                                          <mat-label class="matlabel">
                                             <input [(ngModel)]="testtypee.insource_cost" type="text" class="ipcss "
                                                maxlength="50" style="width: 125px;" />
                                          </mat-label>
                                       </td>
                                    </tr>
                                 </ng-container>
                                 <ng-container *ngIf="testtype.microtests && testtype.microtests.length > 0">
                                    <tr *ngFor="let testtypee of testtype.microtests">
                                       <td>{{testtypee.test_name}}</td>
                                       <td>
                                          <mat-label class="matlabel">
                                             <input (keyup)="setInsourceValue()" [(ngModel)]="testtypee.outsource_cost"
                                                type="text" class="ipcss " maxlength="50" style="width: 125px;" />
                                          </mat-label>
                                       </td>
                                       <td>
                                          <mat-label class="matlabel">
                                             <input [(ngModel)]="testtypee.insource_cost" type="text" class="ipcss "
                                                maxlength="50" style="width: 125px;" />
                                          </mat-label>
                                       </td>
                                    </tr>
                                 </ng-container>
                                 <ng-container *ngIf="testtype.radiotests && testtype.radiotests.length > 0">
                                    <tr *ngFor="let testtypee of testtype.radiotests">
                                       <td>{{testtypee.test_name}}</td>
                                       <td>
                                          <mat-label class="matlabel">
                                             <input (keyup)="setInsourceValue()" [(ngModel)]="testtypee.outsource_cost"
                                                type="text" class="ipcss " maxlength="50" style="width: 125px;" />
                                          </mat-label>
                                       </td>
                                       <td>
                                          <mat-label class="matlabel">
                                             <input [(ngModel)]="testtypee.insource_cost" type="text" class="ipcss "
                                                maxlength="50" style="width: 125px;" />
                                          </mat-label>
                                       </td>
                                    </tr>
                                 </ng-container>
                                 <ng-container *ngIf="testtype.profiles && testtype.profiles.length > 0">
                                    <tr *ngFor="let testtypee of testtype.profiles">
                                       <td>{{testtypee.profile_name}}</td>
                                       <td>
                                          <mat-label class="matlabel">
                                             <input (keyup)="setInsourceValue()" [(ngModel)]="testtypee.price"
                                                type="text" class="ipcss " maxlength="50" style="width: 125px;" />
                                          </mat-label>
                                       </td>
                                       <td>
                                          <mat-label class="matlabel">
                                             <input [(ngModel)]="testtypee.insource_cost" type="text" class="ipcss "
                                                maxlength="50" style="width: 125px;" />
                                          </mat-label>
                                       </td>
                                    </tr>
                                 </ng-container>
                              </tbody>
                           </table>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </mdb-card-body>
      </mdb-card>
   </div>
</div>