<div class="row" style="margin:0 auto; width:100%">
  <mdb-card>
    <mdb-card-header class="bg-white">
      <div class="headerCover">
        <div class="headerTilte app_list_header">
          <h5 class="m-0" class="mainHeadingStyle">Bill list</h5>
        </div>
        <div class="headerButtons">
        </div>
      </div>
    </mdb-card-header>
    <mdb-card-body>
      <div class="">
        <div class="row" >
          <div class="col-md-12 col-lg-12 col-sm-12 col-xl-12 " >
            <mat-radio-group class="inpat_radiogrp" color="primary" labelPosition="before">
              <b style="vertical-align: text-bottom;" class="tervys_heading">Filter Type &nbsp;&nbsp;&nbsp;&nbsp;:
              </b>&nbsp;&nbsp;
              <mat-radio-button (change)="billType('Date')" value="Date">Date</mat-radio-button>&nbsp;&nbsp;
              <mat-radio-button (change)="billType('Relation')" checked value="Relation">Relation</mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-2" >
            <mat-label class="matlabel">Billing type &nbsp;&nbsp;
              <mat-select required class="ipcss " name="relationship" [(ngModel)]="billingType" disableOptionCentering >
                <mat-option value="doctor" (click)="billType('billType')">Doctor</mat-option>
                <mat-option value="dietician" (click)="billType('billType')">Dietician</mat-option>
              </mat-select>
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-2" *ngIf="searchbyDateFlag">
            <mat-label *ngIf="searchbyDateFlag" class="matlabel"> Select By Date
              <input type="date" class="ipcss  datecss" (change)="billType('date')" [(ngModel)]="date" #matInput
                max="{{currentDate}}">
            </mat-label>
          </div>
        
          <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-2" *ngIf="searchbyRelationFlag">
            <mat-label *ngIf="searchbyRelationFlag" class="matlabel">Select By Relation &nbsp;&nbsp;
              <mat-select required class="ipcss " name="relationship" [(ngModel)]="relationship"
                (ngModelChange)="getSltRelationType($event)" disableOptionCentering>
                <mat-option value="all">All</mat-option>
                <mat-option *ngFor="let relation of relationTypeList" value="{{relation.relation_id}}">
                  {{relation.relationship_name}}</mat-option>
              </mat-select>
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-2" *ngIf="relationFlag">
            <mat-label *ngIf="relationFlag" class="matlabel">Relation Name
              <mat-select disableOptionCentering class="ipcss " required [(ngModel)]="relationName">
                <mat-option disabled>Select</mat-option>
                <mat-option *ngFor="let relation of relationDetails"
                  (click)="billType('relative')" value={{relation.relation_name}}>{{relation.relation_name}}</mat-option>
              </mat-select>
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-2">
              <img src="../../../assets/ui_icons/buttons/Filter_button.svg" (click)="billType(null)" style="width:85px; margin: 20px 0 0 0;" alt="">
          </div>
        </div>
        <div class="row">
          <p class="nodata" *ngIf="personsBill.length == 0" style="width: 33% !important;">No bill(s) Found</p>
          <div class="col-12">
            <table *ngIf="personsBill.length" mdbTable datatable [dtOptions]="dtOptions"
              class="table table-nowrap table-sm dataTable billlisttable">
              <thead class="tableheading">
                <tr>
                  <th>Bill No</th>
                  <th>Date</th>
                  <th>Client Name</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let bill of personsBill" class="tdvalues"
                  (click)="viewBill(bill.billno,bill.doc_reg_id,bill.specialization_name,bill.fin_year)">
                  <td>{{bill.billno}}</td>
                  <td>{{bill.date}}</td>
                  <td style="text-align: left;padding-left: 30px;">{{bill.clientname}} </td>
                  <td class="textalignright">{{bill.amount}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </mdb-card-body>
  </mdb-card>
</div>