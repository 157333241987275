<div class="row">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">About us</h5>
          </div>
          <div class="headerButtons">
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row">
          <div class="col-12">
            <b>Tervys</b>
            <p class="paragraph_text">
              Tervys is created by well enthusiastic and social and health conscious team. We are passionate about
              healthcare system as such as well as the health of the user. Striving to bridge the gap between the
              healthcare
              provider and receiver by simplifying the processes and workflows so that the user will have hassle-free
              experience.
            </p>
          </div>
          <b>AIM</b>
          <p class="paragraph_text">Our aim is to provide healthcare service to all the sections of the society be it
            urban or rural users. We are
            offering technology enablement to empowerment patients and as well as healthcare provider to digitalize
            their
            personal healthcare information (PHI) so that they can be used any time anywhere with ease.</p>
          <b>VISION</b>
          <p class="paragraph_text">Our vision is to provide the best possible solutions to the problems or issues faced
            by patient by integrating
            the advanced and high end technologies and the healthcare, this will help continuous monitor and track
            patient’s
            health.</p>
          <b>TEAM</b>
          <p class="paragraph_text">Founders of the company has years of experience in the healthcare field and has deep
            understanding on the
            technological point of view where technology meets healthcare. Our team is motivated and enthusiastic to
            provide
            the best possible solutions in the healthcare.</p>
          <b>COMMITMENT</b>
          <p class="paragraph_text">Tervys is committed to offer top-notch healthcare not just to you, but also to your
            family as well as to your
            well-wishers. We believe in the concept of living healthy and happy, this motivation drives our commitment
            to
            provide best healthcare to your family. Gone are the days where we need to maintain and safe-guard our
            health
            records, you can access your health records at anytime and anywhere in no time and your family health is in
            your
            fingertips</p>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>