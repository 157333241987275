<div class="row" style="margin: 0 auto; width: 100%;">
   <div class="col-12">
      <mdb-card>
         <mdb-card-header class="bg-white">
            <div class="headerCover">
               <div class="headerTilte app_list_header">
                  <h5 class="m-0" class="mainHeadingStyle">Tracker</h5>
               </div>
               <div class="headerButtons">
                  <a style="margin:0 5px;" (click)="gotoback()" *ngIf="backflag"><img
                      src="../../../assets/ui_icons/buttons/Back_button.svg"
                      class="backimgbtn_inpatinfo" />
                  </a>

              </div>
            </div>
         </mdb-card-header>
         <mdb-card-body>
            <div class="row">
               <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                  <mat-label class="matlabel">Ward<br>
                     <select disableOptionCentering class="ipcss inpat_width" [(ngModel)]="ward_id"
                        (ngModelChange)="getBedList($event)">
                        <option value="All">All</option>
                        <option *ngFor="let ward of ward_list" value="{{ward.ward_id}}">
                           {{ward.ward_name}}</option>
                     </select>
                  </mat-label>
               </div>
            </div>
            <div class="col-12" *ngIf="wardBeds.length == 0">
               <p class="nodata">No ward(s) found</p>
             </div>
             <div class="col-12" *ngIf="wardBeds.length != 0">
            <div *ngFor="let ward of wardBeds" class="ward-card text-center mt-4">
               <div [ngClass]="{
                  'margin-bottom-15': Medicine_Hist_List.length === 0,
                  'margin-bottom-97': Medicine_Hist_List.length > 0 && Medicine_Hist_List.length < 4,
                  'margin-bottom-150': Medicine_Hist_List.length >= 4
                }">
                  <h1 *ngIf="ward.ward_name != undefined" style="font-size: 16px; margin-top: 12px;">{{ward.ward_name}}
                  </h1>
                  <h1 *ngIf="ward.ward_name == undefined" style="font-size: 16px; margin-top: 12px;">{{ward_Name}}</h1>
                  <div class="row" *ngIf="ward.bed_details != undefined">
                     <div class="col-2 p-0" *ngFor="let bed of ward.bed_details">
                        <div style="text-align: center;">
                           <div class="bed_bed_no"><strong>{{bed.bed_no}}</strong></div>
                           <div><img src="../../../assets/ui_icons/bed_icon.svg" class="bed_img">
                           </div>
                           <div style="font-size: 14px; color: rgb(1, 155, 1);"
                              class="bed_bed_name d-flex justify-content-center">
                              {{bed.name}}</div>
                           <div style="display: inline-flex;"  >
                              <div *ngIf="bed.name !== 'Vacant'" class="chkmargin"><img src="../../../assets/ui_icons/plan_icon.svg" class="bed_img1"
                                    (click)="toggleFlagCheck1(bed.bed_no,ward.ward_id,bed)">
                              </div>
                              <div *ngIf="bed.name !== 'Vacant'" class="chkmargin"><img src="../../../assets/ui_icons/medicine_tracker_icon.svg"
                                    class="bed_img1" (click)="toggleFlagCheck(bed.bed_no,ward.ward_id,bed)">
                              </div>
                           </div>

                           <!-- <div style="width: 100%;position: absolute;left: 10%;top: 86%;"> -->
               
                        </div>
                     </div>

                  </div>
                  <div *ngIf="ward.bed_details == undefined">
                     <p class="nodata">No Bed(s) found</p>
                  </div>
               </div>
            </div>
            </div>

         </mdb-card-body>
      </mdb-card>
   </div>
</div>