import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-clinical-decision',
  templateUrl: './clinical-decision.component.html',
  styleUrls: ['./clinical-decision.component.scss']
})
export class ClinicalDecisionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
