import { ReturnStatement } from "@angular/compiler";

export class Nurse_Helper {
    static AppFlow;
    static setClient_Info(value: any) {
        this.AppFlow = null;
        this.AppFlow = value;
    }
    static getClient_Info() {
        return this.AppFlow;
    }
    static data;
    static setdoctor(value: any) {
        this.data = null;
        this.data = value;
    }
    static getdoctor() {
        return this.data
    }
    static assign;
    static setAssignInfo(value: any) {
        this.assign = null;
        this.assign = value;
    }
    static getAssignInfo() {
        return this.assign;
    }
    static doctorList;
    static setDoctorAppoinmentList(value: any) {
        this.doctorList = null;
        this.doctorList = value;
    }
    static getDoctorAppoinmentList() {
        return this.doctorList;
    }

    //setINPatient
    static INPatient;
    static setINPatient(value:any){
        this.INPatient=null;
        this.INPatient=value;
    } 
    static getINPatient(){
        return this.INPatient;
    }
    //setwardinfomedicinetracker
    static medtrack;
    static setwardinfomedicinetracker(value:any){
        this.medtrack=null;
        this.medtrack=value;
    } 
    static getwardinfomedicinetracker(){
        return this.medtrack;
    }
    static moduleList;
    static setmodules(value: any) {
        this.moduleList = null;
        this.moduleList = value;
    }

    static getmodules() {
        return this.moduleList;
    }
}