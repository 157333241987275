<div class="row" style="margin:0 auto; width:100%">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Mindfulness</h5>
          </div>
          <div class="headerButtons">
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row">
          <div class="col-12" id="mind" *ngIf="!isVideoOnFlag">
            <div style="margin-bottom: 10px;">
              <button class="buttonStyle calm" (click)="getVediosType('calm')">Calm</button>
              <button class="buttonStyle relax" (click)="getVediosType('relax')">Relax</button>
              <button class="buttonStyle focus" (click)="getVediosType('focus')">Focus</button>
              <button class="buttonStyle sleep" (click)="getVediosType('sleep')">Sleep</button>
            </div>
            <owl-carousel-o [options]="customOptions">
              <ng-template carouselSlide *ngFor="let mind of videoList;let i = index">
                <div class="slide">
                  <div class="cu_slider_cover">
                    <div class="card_border1">
                    </div>
                    <a tabindex="0">
                      <div class="mask">
                        <div>
                          <div class="d-flex justify-content-center">
                            <img class="img-fluid" class="image" src="{{mind.video_image}}" (click)="startVedio(mind.video_path)" alt="Card image cap">
                          </div>
                          <p class="carousel_label">{{mind.video_name}}</p>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </ng-template>
            </owl-carousel-o>
          </div>
          <div class="col-12" *ngIf="mindfulnessOptionFlag">
            <mat-accordion class="mataccordion" *ngFor="let mind of mindfulList;let i = index">
              <mat-expansion-panel hideToggle class="expandpanel" [expanded]="step === i" (opened)="setStep(i,mind.id);">
                <mat-expansion-panel-header class="exppanel">
                  <mat-panel-title class="title">
                    <div>
                      <img class="accordion_image" src="{{mind.mind_image}}" />
                      {{mind.mindful_desc}}
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <hr class="hr">
                <div class="row" style="margin-top: 12px;">
                  <div class="col-12" *ngFor="let mindfull of contentList;let i = index">
                    <mat-accordion class="mataccordion" *ngIf="mind.id == mindfull.mind_id">
                      <mat-expansion-panel hideToggle class="expandpanel" [expanded]="step === i"
                        (opened)="setSubStep(i)">
                        <mat-expansion-panel-header class="exppanel">
                          <mat-panel-title class="title">
                            <div>
                              <img class="accordion_image" src="../../../assets/ui_icons/play_button.svg" />
                              {{mindfull.header}}
                            </div>
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                        <hr class="hr">
                        <div class="row" style="margin-top: 12px;">
                          <div class="col-6 embed-responsive embed-responsive-16by9 d-flex justify-content-center" *ngIf="mindfull.mediaType == 'video'">
                            <video id="myVideo" class="wh_video embed-responsive-item" autoplay controls controlsList="nodownload">
                                <source src="{{mindfull.video_audio_link}}" type="video/mp4">
                              </video>
                            </div>
                            <div class="col-6 embed-responsive embed-responsive-16by9 d-flex justify-content-center" *ngIf="mindfull.mediaType == 'audio'">
                              <audio autoplay controls controlsList="nodownload">
                                <source src="{{mindfull.video_audio_link}}" type="audio/ogg">
                              </audio>
                              </div>
                          <div class="col-6">
                            <div class="cover_div">
                              <div class="content_cover">
                                <div [innerHTML]="mindfull.long_description"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
        <div class="row" *ngIf="!mindfulnessOptionFlag" style="justify-content: center;">
          <div class="col-10">
            <video id="myVideo12" class="wh_video embed-responsive-item" autoplay controls controlsList="nodownload">
              <source src="{{videoPlaylist}}" type="video/mp4">
            </video>
            <img class="closeicon" (click)="stop()" src="../../../assets/ui_icons//video_cancel_icon.svg" width="16px"/>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>