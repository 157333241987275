<div class="row" style="margin:0 auto; width:100%">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Find donor</h5>
          </div>
          <div class="headerButtons">
            <img class="saveimgbtn_inpatinfo" [hidden]="saveFlag" (click)="save()"
              src="../../../assets/ui_icons/buttons/save_button.svg" />
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="cover_div" style="padding-bottom: 13px;">
          <div class="header_lable">
            Fine doners
          </div>
          <div class="content_cover">
            <div class="row">
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel"> location<br>
                  <input type="text" class="ipcss widthappt" required maxlength="50" (keyup)="locationChange($event)"
                    [(ngModel)]="locationName" matInput [matAutocomplete]="auto" />
                  <mat-autocomplete #auto="matAutocomplete">
                    <mat-option (click)="selectedLocation(location)" *ngFor="let location of locationList"
                      value="{{location}}">
                      {{location}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2">
                <mat-label class="matlabel"> City<br>
                  <mat-select disableOptionCentering class="ipcss widthappt" required [(ngModel)]="filtCity"
                    (ngModelChange)="cityChange($event)">
                    <mat-option disabled>Select</mat-option>
                    <mat-option *ngFor="let city of cityList" [value]="city.city_id">{{city.city_desc}}</mat-option>
                  </mat-select>
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 select_bottomy">
                <mat-label class="matlabel"> State<br>
                  <mat-select disableOptionCentering class="ipcss widthappt" required [(ngModel)]="filtState"
                    (ngModelChange)="stateChange($event)">
                    <mat-option disabled>Select</mat-option>
                    <mat-option *ngFor="let state of stateList" [value]="state.state_id">{{state.state_desc}}
                    </mat-option>
                  </mat-select>
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2">
                <mat-label class="matlabel"> Country<br>
                  <mat-select disableOptionCentering class="ipcss widthappt" [(ngModel)]="filtCountry"
                    (ngModelChange)="countryChange($event)">
                    <mat-option disabled>Select</mat-option>
                    <mat-option *ngFor="let country of countryList" [value]="country.country_desc">
                      {{country.country_desc}}</mat-option>
                  </mat-select>
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" style="padding: 20px 4px;">
                <img class="saveimgbtn_inpatinfo" (click)="searchDonors()"
                  src="../../../assets/ui_icons/buttons/search_button.svg" />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12" style="margin-top: 15px;">
            <p class="nodata" *ngIf="donorList.length == 0 ">No donors Found</p>
            <div class="table-responsive dig_table_overflow">
              <table *ngIf="donorList.length != 0" id="card_tbl" class="table table-hover table-dynamic" cellpadding="5"
                cellspacing="0">
                <thead>
                  <tr>
                    <th></th>
                    <th>Name </th>
                    <th>Ph</th>
                    <th>checkbox</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let person of donorList; let i = index">
                    <td><img src="{{person.profile_image}}" class="img-fluid z-depth-1 rounded-circle mr-1"></td>
                    <td style="font-size: 12px;">{{ person.name }}</td>
                    <td style="font-size: 12px;text-align: left;">{{ person.mobile }}</td>
                    <td>
                      <mat-checkbox (change)="checkDonar(person.client_reg_id,person.check)" [(ngModel)]="person.check">
                      </mat-checkbox>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>