  import { Component, OnInit } from '@angular/core';
  import { DatePipe } from '@angular/common';
  import { Http, Headers } from '@angular/http';
  import { CommonDataService } from 'src/app/providers/common-data.service';
  import { Data, Route, Router } from '@angular/router';
  import { ToastrService } from 'ngx-toastr';
  import { Helper_Class } from '../../helper_class';
  import { ipaddress } from '../../ipaddress' ;import { Message_data } from 'src/assets/js/Message_data';
@Component({
  selector: 'app-receptionistdoctorlistpage',
  templateUrl: './receptionistdoctorlistpage.component.html',
  styleUrls: ['./receptionistdoctorlistpage.component.css']
})
export class ReceptionistdoctorlistpageComponent implements OnInit {
  public filterFlag: boolean;
  public getSplData = [];
  public screensizeBasedFlag: boolean;
  public doctorList = [];
  public secondOpinionFlag: string;
  public secondOpinionDocListFlag: Boolean = false;
  public currentDateUrl: string;
  public sendData;
  public currentDate: string;
  public currentYear: string;
  public doctorListUrl: string;
  public sendDoctorDetailData;
  public homecare: string;
  public sendDoctorData;
  public sendDoctorLocation: string;
  public autoLocationFlag: Boolean = false;
  public secondOpnDoctorList = [];
  public secondOpnDoctorIdList = [];
  public hospitalFlag: Boolean;
  public specialFlag: Boolean;
  public knobValues: any = {
      upper: 1500,
      lower: 0
  }
  public locationList = [];
  public cityList;
  public stateList;
  public countryList;
  public location;
  public city;
  public state;
  public country;
  public cityDesc;
  public stateDesc;
  public flow
  public filtFee;
  public gender;
  public filter_session;
  public filt_available_day;
  public Doctor_filter_div: boolean;
  public filter_search_location: string;
  public filter_search_city: string;
  public filter_search_state: string;
  public filter_search_country: string;
  public doctornodata: boolean;
  loader: boolean;
  public nursenodata: boolean;
  fabButtonOpened: Boolean;
  public withoutlogin: boolean;
  Title: string;
  public loginheader;
  public withlogin;
  public genderflag;
  public availabilityflag;
  public sessionflag;
  value3:any;
  value1:any =100;
  value2:any =1500;
  changevalue1:any;
  changevalue2:any;
  constructor(public datepipe:DatePipe,public gservice:CommonDataService,public http:Http,public router:Router,public toastr:ToastrService) { 
    this.value1=this.changevalue1;
    this.value2=this.changevalue2;
    this.withoutlogin = true;
    this.screensizeBasedFlag = false;
    this.filterFlag = true;
    this.doctornodata = false;
    this.nursenodata = false;
    this.loader = false;
    this.loginheader = false;
    this.withlogin = false
   }
   ngOnInit (){   
        this.sendData = {
        country: ipaddress.country_code
        }
        this.homecare = "0";
        this.currentDateUrl = ipaddress.getIp + "adm/curdate";
        this.doctorListUrl = ipaddress.getIp + "search/doctor/";
        if (localStorage.getItem('flowsec') == "second_opinion" || localStorage.getItem('flowsec') == "without_second_opinion") {
            this.secondOpinionFlag = "1";
            localStorage.setItem(" this.second_opinion_flag", this.secondOpinionFlag)
        } 
        this.appGetdate();
    }
    genderclick(){
        this.genderflag = !this.genderflag
    }
    Availabilityclick(){
        this.availabilityflag=!this.availabilityflag
    }
    session(){
        this.sessionflag= !this.sessionflag
    }
    Gotohome() {
    }
    openFabButton() {
        if (this.fabButtonOpened == false) {
            this.fabButtonOpened = true;
        } else {
            this.fabButtonOpened = false;
        }
    }
    appGetdate() {
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(this.currentDateUrl, JSON.stringify(this.sendData), { headers: headers }).subscribe(
            data => {
                this.currentDate = data.json().current_date;
                this.currentYear = data.json().current_date.split('-')[0];
            {
                    this.Title = "Doctor(s)";
                    this.Get_Doctor_Data_List();
                }
            },
            error => {
                this.loader = true;
            })
    }
    Get_Doctor_Details(responselist) {
        this.second_next_btn_Click()
        this.doctorList = [];
        if (responselist.length == 0) {
            this.doctornodata = true;
            this.loader = true;
        } else {
            this.loader = true;
            this.doctornodata = false;
        }
        for (var i = 0; i < responselist.length; i++) {
            var Docname = "";
            if (responselist[i].middle_name != undefined && responselist[i].middle_name != "") {
                Docname = responselist[i].first_name + " " + responselist[i].middle_name + " " + responselist[i].last_name;
            } else {
                Docname = responselist[i].first_name + " " + responselist[i].last_name;
            }
            var MedicareName = "";
            var gender_data = "";
            if (responselist[i].gender != null) {
                gender_data = responselist[i].gender;
            }
            if (responselist[i].medicare != null) {
                if (responselist[i].medicare.length != 0) {
                    for (var j = 0; j < responselist[i].medicare.length; j++) {
                        if (j == 0) {
                            MedicareName = responselist[i].medicare[j];
                        } else {
                            MedicareName = MedicareName + ", " + responselist[i].medicare[j];
                        }
                    }
                }
            }
            var Splityname = "", splid = "0";
            if (responselist[i].specialization != null && responselist[i].specialization.length != 0) {
                Splityname = "";
                for (var j = 0; j < responselist[i].specialization.length; j++) {
                    if (j == 0) {
                        Splityname = responselist[i].specialization[j];
                    } else {
                        Splityname = Splityname + ", " + responselist[i].specialization[j];
                    }
                }
                var Doct_Special_Row = true;
                var Medispecial = false;
            } else {
                var Doct_Special_Row = false;
                var Medispecial = true;
            }
            var Qualify = "";
            if (responselist[i].qualification.length != 0) {
                for (var j = 0; j < responselist[i].qualification.length; j++) {
                    if (j == 0) {
                        Qualify = responselist[i].qualification[j].degree_name;
                    } else {
                        Qualify = Qualify + ", " + responselist[i].qualification[j].degree_name;
                    }
                }
            }
            var Hosp_detail = [];
            if (responselist[i].hospital.length != 0) {
                var Hosp_id_list = [];
                for (var j = 0; j < responselist[i].hospital.length; j++) {
                    if (j == 0) {
                        Hosp_id_list.push(responselist[i].hospital[j].hospital_id);
                        Hosp_detail.push({
                            Hosp_name: responselist[i].hospital[j].hospital_name,
                            Hosp_Locat: responselist[i].hospital[j].location
                        });
                    } else {
                        if (this.Array_contains(Hosp_id_list, responselist[i].hospital[j].hospital_id) == false) {
                            Hosp_id_list.push(responselist[i].hospital[j].hospital_id);
                            Hosp_detail.push({
                                Hosp_name: responselist[i].hospital[j].hospital_name,
                                Hosp_Locat: responselist[i].hospital[j].location
                            });
                        }
                    }
                }
            }
            var experiece;
            if((parseInt(this.currentYear) - parseInt(responselist[i].registeration_year))==0){
                experiece="<1";
            } else {
                experiece = parseInt(this.currentYear) - parseInt(responselist[i].registeration_year);
            }
            this.doctorList.push({
                Prov_ID: responselist[i].prov_id,
                Doctor_Name: Docname,
                Prof_image: ipaddress.Ip_with_img_address + responselist[i].profile_image,
                Work_Year: experiece,
                Medicare: MedicareName,
                Speciality: Splityname,
                Qualification: Qualify,
                Hospital: Hosp_detail,
                gender1: gender_data,
                check: false
            });
        }
        if (Medispecial == true) {
            var image_profig_cls = "nurse_image_hotspot";
        } else {
            var image_profig_cls = "image_hotspot";
        }
    }
    Array_contains(array, value) {
        var data = false;
        for (var i = 0; i < array.length; i++) {
            if (array[i] == value) {
                data = true;
                break;
            }
        }
        return data;
    }
    Doctor_View(Doctor_ID, value) {
    this.flow ="second_opinion1";
    localStorage.setItem('flow',this.flow)
        if (localStorage.getItem('flowsec') == "second_opinion" ||localStorage.getItem('flowsec') == "without_second_opinion") {
            for (var i = 0; i < this.doctorList.length; i++) {
                if (this.doctorList[i].Prov_ID == Doctor_ID) {
                    if (this.secondOpnDoctorIdList.length == 0) {
                        var spl = [];                 
                        spl = this.doctorList[i].Speciality.split(",");
                        this.getSplData = Helper_Class.getsplnextdoclistpage().secOpn;  
                        for (var j = 0; j <  this.getSplData.length; j++) {      
                            for (var t = 0; t < spl.length; t++) {
                                if (spl[t] == this.getSplData[j].specialization_name) {
                                    this.secondOpnDoctorIdList.push({
                                        doc_reg_id: this.doctorList[i].Prov_ID.toString(),
                                        specialization:  this.getSplData[j].specialization_name,
                                    })
                                }
                                else{
                                }
                            }
                        }
                        var Location = "";
                        for (var j = 0; j < this.doctorList[i].Hospital.length; j++) {                      
                            if (this.sendDoctorLocation == this.doctorList[i].Hospital[j].Hosp_Locat) {
                                Location = this.doctorList[i].Hospital[j].Hosp_Locat;
                                break;
                            }
                        }
                        if(this.secondOpnDoctorList.length !=0){  
                            if(this.secondOpnDoctorList.find(x => x.Doc_ID === Doctor_ID) == undefined){
                                this.secondOpnDoctorList.push({
                                    Doc_ID: this.doctorList[i].Prov_ID,
                                    Doc_Name: this.doctorList[i].Doctor_Name,
                                    Doc_Location: Location,
                                });
                        }
                        } else {
                            this.secondOpnDoctorList.push({
                                Doc_ID: this.doctorList[i].Prov_ID,
                                Doc_Name: this.doctorList[i].Doctor_Name,
                                Doc_Location: Location,
                            });
                        }
                        this.doctorList[value].check = true;
                        this.doctorList[i].chang_col = true;
                        break;
                    } else {
                        if (this.gservice.get_Array_Contains_string(this.secondOpnDoctorIdList, this.doctorList[i].Prov_ID) == false) {
                            var spl = [];                      
                            spl = this.doctorList[i].Speciality.split(",");
                            for (var j = 0; j < this.getSplData.length; j++) {
                                for (var t = 0; t < spl.length; t++) {
                                    if (spl[t].trim() == this.getSplData[j].specialization_name.trim()) {
                                        if(this.secondOpnDoctorIdList.find(x => x.doc_reg_id === Doctor_ID) == undefined){
                                            this.secondOpnDoctorIdList.push({
                                                doc_reg_id: this.doctorList[i].Prov_ID.toString(),
                                                specialization:  this.getSplData[j].specialization_name,
                                            })
                                        }
                                    }
                                }
                            }
                            this.doctorList[value].check = true;
                            var Location = "";
                            for (var j = 0; j < this.doctorList[i].Hospital.length; j++) {
                                if (this.sendDoctorLocation == this.doctorList[i].Hospital[j].Hosp_Locat) {
                                    Location = this.doctorList[i].Hospital[j].Hosp_Locat;
                                    break;
                                }
                            }
                            this.doctorList[i].chang_col = true;
                            if(this.secondOpnDoctorList.length !=0){
                                if(this.secondOpnDoctorList.find(x => x.Doc_ID === Doctor_ID) == undefined){
                                    this.secondOpnDoctorList.push({
                                        Doc_ID: this.doctorList[i].Prov_ID,
                                        Doc_Name: this.doctorList[i].Doctor_Name,
                                        Doc_Location: Location,
                                    });
                                }
                            } else {
                                this.secondOpnDoctorList.push({
                                    Doc_ID: this.doctorList[i].Prov_ID,
                                    Doc_Name: this.doctorList[i].Doctor_Name,
                                    Doc_Location: Location,
                                });
                            }
                            break;
                        } else {
                            for (var i = 0; i < this.secondOpnDoctorList.length; i++) {
                                if (this.secondOpnDoctorList[i].Doc_ID == Doctor_ID) {
                                    this.doctorList[value].check = false;
                                    this.secondOpnDoctorList.splice(i, 1);
                                    this.secondOpnDoctorIdList.splice(i, 1);
                                    break;
                                }
                            }
                        }
                    }
                    break;
                }
                this.gservice.set_second_doctor_data(this.secondOpnDoctorIdList);
            }
            if (this.secondOpnDoctorList.length != 0) {
                this.secondOpinionDocListFlag = true;
            } else {
                this.secondOpinionDocListFlag = false;
            }
        } else {
            localStorage.setItem("Normal_Doctor_ID", Doctor_ID);
            this.sendDoctorData = {
                doct_id: Doctor_ID,
                location: this.sendDoctorLocation
            }
        }
    }
    second_doctor_delete(index) {
        for (var i = 0; i < this.secondOpnDoctorList.length; i++) {
            if (this.secondOpnDoctorList[i].Doc_ID == index) {
                for(var j=0;j<this.doctorList.length;j++){
                    if (this.secondOpnDoctorList[i].Doc_ID == this.doctorList[j].Prov_ID) {
                        this.doctorList[j].check = false;
                    }
                }
                this.secondOpnDoctorList.splice(i, 1);
                this.secondOpnDoctorIdList.splice(i, 1);
                break;
            }
        }
    }
    second_next_btn_Click() {
        this.gservice.set_second_health_data(null);
        this.gservice.set_second_family_data(null);
        this.gservice.set_second_general_data(null);
        this.gservice.set_second_appointment_data(null);
        this.gservice.set_second_document_data(null);
        if (this.secondOpnDoctorList.length != 0) {
            this.gservice.set_second_doctor_data(this.secondOpnDoctorIdList);
                var getdata =Helper_Class.getDocSearch()
                this.router.navigateByUrl('/secondopinioncreate')
                    var send_data1={
                app_flow:localStorage.getItem("flowsec"),
                    doctor: this.sendDoctorData,
                doc_search:getdata,
                login_search:'login_search',
                    }
        } else {
            this.toastr.error("select doctor")
        }
    }
    Get_Doctor_Data_List() {
        function StringBuilder(this:any,value) {
            this.strings = new Array("");
            this.append(value);
        }
        StringBuilder.prototype.append = function (value) {
            if (value) {
                this.strings.push(value);
            }
        }
        StringBuilder.prototype.clear = function () {
            this.strings.length = 1;
        }
        StringBuilder.prototype.toString = function () {
            return this.strings.join("");
        }
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post( ipaddress.getIp + "search/doctor/", JSON.stringify(this.sendDoctorDetailData), { headers: headers }).subscribe(
            data => {
                this.loader = true;
                if (data.json().info != null && data.json().info.length != 0) {
                    this.Get_Doctor_Details(data.json().info);
                    if (this.autoLocationFlag == true) {
                        this.Client_location_auto();
                    }
                } else {
                    this.loader = true;
                    this.doctornodata = true;
                    this.doctorList = [];
                }
            },
            error => {
            })
    }
    Location_change(data) {
        this.location = data.target.value.toString();
        this.locationList = [];
        if (this.location != null && this.location.length > 2) {
            var headers = new Headers();
            headers.append('Content-Type', 'application/json');
            this.http.post(ipaddress.getIp + "gen/loc", JSON.stringify({ loc_name: this.location }), { headers: headers }).subscribe(
                data => {
                    if (data.json().locations != null && data.json().locations.length != 0) {
                        this.locationList = data.json().locations;
                    }
                },
                error => {
                })
        }
    }
    location_selected(loc_name) {
        this.location = loc_name;
        this.locationList = [];
        this.doctorList = [];
        this.Get_City(loc_name);
    }
    Get_City(data) {
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post( ipaddress.getIp + "gen/city", JSON.stringify({ loc_name: data }), { headers: headers }).subscribe(
            data => {
                if (data.json().cities != null) {
                    this.cityList = data.json().cities;
                        this.city = this.cityList[0].city_id;
                        this.cityDesc = this.cityList[0].city_desc;
                        this.City_change(this.cityList[0].city_id, this.cityDesc);
                }
            },
            error => {
            })
    }
    City_change(clnt_city,city_desc){
        this.getStates(clnt_city);
    }
    getStates(cities){
        this.stateList = [];
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post( ipaddress.getIp + "gen/state",JSON.stringify({city_id: cities }), 
        { headers: headers }).subscribe(
        data => {
            if (data.json().states != null) {
            this.stateList = data.json().states;
            this.state = this.stateList[0].state_id;
            this.stateDesc = this.stateList[0].state_desc;
            this.getCountries(this.state)
            }
        })
    }
    getCountries(state) {
        this.countryList = [];
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post( ipaddress.getIp + "gen/count",JSON.stringify({state_id: state }), 
        { headers: headers }).subscribe(
        data => {
            if (data.json().countries != null) {
            this.countryList = data.json().countries;
            this.country = this.countryList[0].country_desc;
            }
        },
        error => {
            this.toastr.error(Message_data.unableToFetchData);
        })
    }
stateChange(clnt_state){
    this.getCountries(this.state)
  }
Country_Change(data) {
    function StringBuilder(this:any,value) {
        this.strings = new Array("");
        this.append(value);
    }
    StringBuilder.prototype.append = function (value) {
        if (value) {
            this.strings.push(value);
        }
    }
    StringBuilder.prototype.clear = function () {
        this.strings.length = 1;
    }
    StringBuilder.prototype.toString = function () {
        return this.strings.join("");
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.sendDoctorLocation = this.location;
}

doctor_filter_click() {
  var doctotfilt = Helper_Class.getsplnextdoclistpage();
    function StringBuilder(this:any,value) {
        this.strings = new Array("");
        this.append(value);
    }
    StringBuilder.prototype.append = function (value) {
        if (value) {
            this.strings.push(value);
        }
    }
    StringBuilder.prototype.clear = function () {
        this.strings.length = 1;
    }
    StringBuilder.prototype.toString = function () {
        return this.strings.join("");
    }
    if (doctotfilt.secOpn != undefined) {
        var selected_data = new StringBuilder("");
        var getSpl = doctotfilt.secOpn;
        for(var i=0; i<getSpl.length;i++){
            var speciality_name= getSpl.specialization_name;
        }
        var n = 0;
    }
    if (this.filtFee != undefined && this.filtFee != "") {
        if (this.filtFee == "Low To High") {
            var price_asc = "asc";
        } else {
            var price_asc = "desc";
        }
    }
    var sec_opn = undefined;
    if (this.secondOpinionFlag == "1") {
        sec_opn = "1"
    }
if( price_asc != undefined){
    price_asc =price_asc;
}
if(this.gender != undefined){
    this.gender = this.gender
}
if(this.filter_session != undefined){
    this.filter_session =this.filter_session;
}
if(this.filt_available_day != undefined){
    this.filt_available_day =this.filt_available_day
}
if(this.knobValues.lower ! = undefined){
    this.knobValues.lower= this.knobValues.lower}
    if(this.knobValues.upper ! = undefined){
        this.knobValues.upper= this.knobValues.upper}
    this.sendDoctorDetailData = {
        second_opinion:localStorage.getItem(" this.second_opinion_flag"),
       fees_from: this.knobValues.lower,
        fees_to: this.knobValues.upper,
        price: price_asc,
        gender: this.gender,
        session: this.filter_session,
        available_day: this.filt_available_day,
        medicare_name:"Allopathy",
       spl_name: speciality_name,
       home_care: "0",
        country: this.country,
        state: this.stateDesc,
        city: this.cityDesc,
        location: this.location,
    }
    if (this.fabButtonOpened == false) {
        this.fabButtonOpened = true;
    } else {
        this.fabButtonOpened = false;
    }
    this.doctor_filter(this.sendDoctorDetailData);
}
doctor_filter(send_data) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.doctorListUrl, JSON.stringify(send_data), { headers: headers }).subscribe(
        data => {
            if (data.json().info != null && data.json().info.length != 0) {
                this.Get_Doctor_Details(data.json().info);
            } else {
                this.doctorList = [];
                this.loader = true;
                this.doctornodata = true;
            }
        },
        error => {
        })
}
myCallbackFunction = function (_params) {
    return new Promise<void>((resolve, reject) => {
        resolve();
    });
}
    Client_location_auto() {
            this.location = Helper_Class.getDocSearch().Search_Location;
            this.filter_search_location = Helper_Class.getDocSearch().Search_Location;
            this.filter_search_city =  Helper_Class.getDocSearch().Search_City;
            this.filter_search_state = Helper_Class.getDocSearch().Search_State;
            this.filter_search_country = Helper_Class.getDocSearch().Search_Country;
        this.Get_City(this.filter_search_location);
    }
}
