import { Component, OnInit ,ElementRef,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Helper_Class } from 'src/app/helper_class';
import { FrontDesk_Helper } from '../FrontDesk_Helper';
import { ipaddress } from '../../ipaddress' ;import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-bill-hist-view',
  templateUrl: './bill-hist-view.component.html',
  styleUrls: ['./bill-hist-view.component.scss']
})
export class BillHistViewComponent implements OnInit {
  public billNo: string;
  public hospId:string;
  public billList = [];
  public userInfo;
  
  constructor(public http:HttpClient, public el: ElementRef,public dialogRef: MatDialogRef<BillHistViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      dialogRef.disableClose = true;
    }

  ngOnInit() {
    console.log(Helper_Class.getInfo());
    if( Helper_Class.getLoginType() == "doctor" ){   
      this.userInfo = Helper_Class.getInfo().hospitals[0];
      this.billNo = FrontDesk_Helper.getreceptbilldetailview().bill_view_id;
      this.hospId = this.userInfo.hptl_clinic_id;     
    } else{
      this.userInfo = Helper_Class.getInfo();
      this.billNo = FrontDesk_Helper.getreceptbilldetailview().bill_view_id;
      this.hospId = this.userInfo.hptl_clinic_id;
    } 
    console.log("UserInfo",this.userInfo);
    console.log("BillNo",this.billNo);
    console.log("HospId",this.hospId);
    console.log(Helper_Class.getBillingFlow()); 
    var type;
    if (Helper_Class.getBillingFlow() != undefined)
      type = Helper_Class.getBillingFlow().flow == 'patient' ? "doctor" : "dietician";  
    else 
      type = "doctor";

    var send_data = {
      bill_no: this.billNo,
      hptl_clinic_id: this.hospId,
      type: type,
      fin_year:FrontDesk_Helper.getreceptbilldetailview().fin_year,
    }
    console.log("send_data",send_data);
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/ghbill/',send_data,
      { headers: headers })
      .subscribe(
        response => {
          this.billList = [];
          var obj = JSON.parse(JSON.stringify(response));
          console.log(obj);
          if(obj.bills != undefined && obj.bills != null && obj.bills.length != 0 ) {
            this.billList = obj.bills;
          }
        });
  }

  close(){
    this.dialogRef.close();
  }
  
}
