import { Component, OnInit } from '@angular/core';
import { Helper_Class } from '../../helper_class';
import { Router, ActivatedRoute } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Date_Formate } from '../../../assets/js/common.js';
import { CommonDataService } from '../../providers/common-data.service';
import { Client_Helper } from '../Client_helper';
import { ClientViewService } from '../clientview/client-view.service';
import { Doc_Helper } from '../../Doctor_module/Doc_Helper';
import { MatDialog } from '@angular/material/dialog';
import { Helper_nephro_casesheet } from '../../../assets/js/Helper_nephro_casesheet';
import { ipaddress } from '../../ipaddress';
declare var $ : any; 

@Component({
  selector: 'app-record-wallet-new',
  templateUrl: './record-wallet-new.component.html',
  styleUrls: ['./record-wallet-new.component.css']
})
export class RecordWalletNewComponent implements OnInit {
  public relationId: string;
  public relationListUrl: string;
  public medPresUrl: string;
  public diagUrl: string;
  public caseListUrl: string;
  public caseRetUrl: string;
  public sendData;
  public relationDesc: string;
  public report: string;
  public subRelId: string;
  public splUrl;
  public relationList=[];
  public documentTypeList=[];
  public medPresListData=[];
  public medPresList=[];
  public diagPresList=[];
  public reportList=[];
  public caseList=[];
  public reportFlag: boolean;
  public medPresFlag: boolean;
  public diagPresFlag: boolean;
  public reportListFlag: boolean;
  public caseListFlag: boolean;
  public docnameFlag: boolean;
  public userInfo:any;
  public image:any;
  public ClntDob:any;
  public spl:any;
  public mobile:any;
  public symptoms:any;
  public medicareName:any;
  public address1:any;
  public address2:any;
  public location:any;
  public addr:any;
  public hobj:any;
  public hospUrl:any;
  public header_footer_flag: boolean;

  constructor( public dialog: MatDialog,public clientservice:ClientViewService,public gservice:CommonDataService,private http:Http,private routes:ActivatedRoute,private router:Router,private toastr: ToastrService) { 
    Doc_Helper.setAppFlow("");
    Doc_Helper.setCasesheet_flag("1");
    Doc_Helper.setAppFlow("client");
    this.relationListUrl = ipaddress.getIp + "record/getrel";
    this.medPresUrl = ipaddress.getIp+"record/medprescriptions/";
    this.diagUrl = ipaddress.getIp + "record/diagprescriptions/";
    this.caseRetUrl = ipaddress.getIp + "goi/chist";
    this.reportFlag = true;
    this.diagPresFlag= true;
    this.medPresFlag= true;
    this.reportListFlag=true;
    this.caseListFlag=true;
    if (this.gservice.Client_login_detail_data != undefined) {
      this.sendData = {
        client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
        home_care: "0",
      }
    }
  }

  ngOnInit(): void {
    this.getRelationList();
    this.userInfo = Helper_Class.getInfo();
    this.image = this.userInfo.profile_image;
  }

  getRelationList() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.relationListUrl, this.sendData, { headers: headers }).subscribe(
      data => {
        var reldata = data.json();
        if (reldata != null && reldata.relationships.length != 0) {
          for(var i=0;i<reldata.relationships.length;i++){
            this.relationList.push({
              rel_id:reldata.relationships[i].relation_id,
              rel_desc:reldata.relationships[i].relation,
            });
          }
          this.changeRelation(this.relationList[0].rel_id);
        } 
      }
    )
  }

  changeRelation(data){
    for(var i=0;i<this.relationList.length;i++){
      if(data == this.relationList[i].rel_id){
        this.relationId = this.relationList[i].rel_id;
        this.relationDesc= this.relationList[i].rel_desc;
        this.documentTypeList=[];
        this.caseList=[];
        this.reportListFlag=true;
        this.diagPresFlag=true;
        this.medPresFlag=true;
        this.caseListFlag = true;
        this.getReports();
      }
    }
  }

  getReports(){
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "record/getreccnt/", {
      client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
      home_care: "0",
      relation:this.relationDesc
    },
    { headers: headers }).subscribe(
      data => {
        var dataval = data.json();
        if(dataval.med_pres != "0"){
          this.documentTypeList.push("Medical prescription");
        }
        if(dataval.diag_pres != "0"){
          this.documentTypeList.push("Diagnosis prescription");
        }
        if(dataval.blood_report != "0"){
          this.documentTypeList.push("Blood report");
        }
        if(dataval.urine_report != "0"){
          this.documentTypeList.push("Urine report");
        }
        if(dataval.faeces_report != "0"){
          this.documentTypeList.push("Faeces report");
        }
        if(dataval.scan_report != "0"){
          this.documentTypeList.push("Scan report");
        }
        if(dataval.xray_report != "0"){
          this.documentTypeList.push("X-ray report");
        }
        if(dataval.ultra_report != "0"){
          this.documentTypeList.push("Ultrasound report");
        }
        if(dataval.diab_case_his != "0"){
          this.documentTypeList.push("Diabetic casesheet");
        }
        if(dataval.cardio_case_hist != "0"){
          this.documentTypeList.push("Cardio casesheet");
        }
        if(dataval.nephro_case_hist != "0"){
          this.documentTypeList.push("Nephrology casesheet");
        }
        if(dataval.ped_case_hist != "0"){
          this.documentTypeList.push("Pediatric casesheet");
        }
        if(dataval.gyn_case_hist != "0"){
          this.documentTypeList.push("Gynaecology casesheet");
        }
        if(dataval.gen_case_hist != "0"){
          this.documentTypeList.push("General casesheet");
        }
        if(dataval.other_case_hist != "0"){
          this.documentTypeList.push("Other casesheet");
        }
        if(dataval.ent_case_hist != "0"){
          this.documentTypeList.push("Ent casesheet");
        }
        if(dataval.den_case_hist != "0"){
          this.documentTypeList.push("Dental");
        }
        if(this.documentTypeList.length !=0){
          this.reportFlag = false;
          this.report = this.documentTypeList[0];
          this.changeReport();
        }
      },error => {})
  }
  
  changeReport(){
    if(this.report =="Medical prescription") {
      this.medPresList=[];
      this.diagPresList=[];
      this.reportList=[];
      this.caseList=[];
      this.medPresFlag =false;
      this.diagPresFlag=true;
      this.reportListFlag=true;
      this.caseListFlag=true;
      this.getMedPresList();
    } else if(this.report =="Diagnosis prescription") {
      this.medPresList=[];
      this.diagPresList=[];
      this.reportList=[];
      this.caseList=[];
      this.medPresFlag=true;
      this.reportListFlag=true;
      this.caseListFlag=true;
      this.getDiagPresList();
    } else if(this.report =="Blood report") {
      this.medPresList=[];
      this.diagPresList=[];
      this.reportList=[];
      this.caseList=[];
      this.medPresFlag=true;
      this.diagPresFlag=true;
      this.caseListFlag=true;
      this.reportListFlag = false;
      this.getReportList("Blood");
    }  else if(this.report =="Urine report") {
      this.medPresList=[];
      this.diagPresList=[];
      this.reportList=[];
      this.caseList=[];
      this.medPresFlag=true;
      this.diagPresFlag=true;
      this.caseListFlag=true;
      this.reportListFlag = false;
      this.getReportList("Urine");
    } else if(this.report =="Faeces report") {
      this.medPresList=[];
      this.diagPresList=[];
      this.reportList=[];
      this.caseList=[];
      this.medPresFlag=true;
      this.diagPresFlag=true;
      this.reportListFlag = false;
      this.caseListFlag=true;
      this.getReportList("Faeces");
    } else if(this.report =="Scan report") {
      this.medPresList=[];
      this.diagPresList=[];
      this.reportList=[];
      this.caseList=[];
      this.medPresFlag=true;
      this.diagPresFlag=true;
      this.caseListFlag=true;
      this.reportListFlag = false;
      this.getReportList("Scans");
    } else if(this.report =="X-ray report") {
      this.medPresList=[];
      this.diagPresList=[];
      this.reportList=[];
      this.caseList=[];
      this.medPresFlag=true;
      this.diagPresFlag=true;
      this.reportListFlag = false;
      this.caseListFlag=true;
      this.getReportList("X-ray");
    } else if(this.report =="Ultrasound report") {
      this.medPresList=[];
      this.diagPresList=[];
      this.reportList=[];
      this.caseList=[];
      this.medPresFlag=true;
      this.diagPresFlag=true;
      this.caseListFlag=true;
      this.reportListFlag = false;
      this.getReportList("Ultra-Sound");
    } else if(this.report =="Diabetic casesheet") {
      this.medPresList=[];
      this.diagPresList=[];
      this.reportList=[];
      this.caseList=[];
      this.medPresFlag=true;
      this.diagPresFlag=true;
      this.caseListFlag=false;
      this.reportListFlag = true;
      this.getCasesheetList("Diabetic");
    } else if(this.report =="Cardio casesheet") {
      this.medPresList=[];
      this.diagPresList=[];
      this.reportList=[];
      this.caseList=[];
      this.medPresFlag=true;
      this.diagPresFlag=true;
      this.caseListFlag=false;
      this.reportListFlag = true;
      this.getCasesheetList("Cardiology");
    } else if(this.report =="Nephrology casesheet") {
      this.medPresList=[];
      this.diagPresList=[];
      this.reportList=[];
      this.caseList=[];
      this.medPresFlag=true;
      this.diagPresFlag=true;
      this.caseListFlag=false;
      this.reportListFlag = true;
      this.getCasesheetList("Nephrology");
    } else if(this.report =="Pediatric casesheet") {
      this.medPresList=[];
      this.diagPresList=[];
      this.reportList=[];
      this.caseList=[];
      this.medPresFlag=true;
      this.diagPresFlag=true;
      this.caseListFlag=false;
      this.reportListFlag = true;
      this.getCasesheetList("Pediatrics");
    } else if(this.report =="Gynaecology casesheet") {
      this.medPresList=[];
      this.diagPresList=[];
      this.reportList=[];
      this.caseList=[];
      this.medPresFlag=true;
      this.diagPresFlag=true;
      this.caseListFlag=false;
      this.reportListFlag = true;
      this.getCasesheetList("Gynaecology");
    } else if(this.report =="Cardio casesheet") {
      this.medPresList=[];
      this.diagPresList=[];
      this.reportList=[];
      this.caseList=[];
      this.medPresFlag=true;
      this.diagPresFlag=true;
      this.caseListFlag=false;
      this.reportListFlag = true;
      this.getCasesheetList("Cardiology");
    }else if(this.report =="Other casesheet") {
      this.medPresList=[];
      this.diagPresList=[];
      this.reportList=[];
      this.caseList=[];
      this.medPresFlag=true;
      this.diagPresFlag=true;
      this.caseListFlag=false;
      this.reportListFlag = true;
      this.getCasesheetList("Others");
    } else if(this.report =="Ent casesheet") {
      this.medPresList=[];
      this.diagPresList=[];
      this.reportList=[];
      this.caseList=[];
      this.medPresFlag=true;
      this.diagPresFlag=true;
      this.caseListFlag=false;
      this.reportListFlag = true;
      this.getCasesheetList("ENT");
    } else if(this.report =="General casesheet") {
      this.medPresList=[];
      this.diagPresList=[];
      this.reportList=[];
      this.caseList=[];
      this.medPresFlag=true;
      this.diagPresFlag=true;
      this.caseListFlag=false;
      this.reportListFlag = true;
      this.getCasesheetList("General");
    }
    else if(this.report =="Dental") {
      this.medPresList=[];
      this.diagPresList=[];
      this.reportList=[];
      this.caseList=[];
      this.medPresFlag=true;
      this.diagPresFlag=true;
      this.caseListFlag=false;
      this.reportListFlag = true;
      this.getCasesheetList("Dental");
    }
  }

  selectReport(e){
    this.report = e;
    if(this.report =="Medical prescription") {
      this.medPresList=[];
      this.diagPresList=[];
      this.reportList=[];
      this.caseList=[];
      this.medPresFlag =false;
      this.diagPresFlag=true;
      this.reportListFlag=true;
      this.caseListFlag=true;
      this.getMedPresList();
    } else if(this.report =="Diagnosis prescription") {
      this.medPresList=[];
      this.diagPresList=[];
      this.reportList=[];
      this.caseList=[];
      this.medPresFlag=true;
      this.reportListFlag=true;
      this.caseListFlag=true;
      this.diagPresFlag=false;
      this.getDiagPresList();
    } else if(this.report =="Blood report") {
      this.medPresList=[];
      this.diagPresList=[];
      this.reportList=[];
      this.caseList=[];
      this.reportListFlag = false;
      this.medPresFlag=true;
      this.diagPresFlag=true;
      this.caseListFlag=true;
      this.getReportList("Blood");
    }  else if(this.report =="Urine report") {
      this.medPresList=[];
      this.diagPresList=[];
      this.reportList=[];
      this.caseList=[];
      this.medPresFlag=true;
      this.diagPresFlag=true;
      this.caseListFlag=true;
      this.reportListFlag = false;
      this.getReportList("Urine");
    } else if(this.report =="Faeces report") {
      this.medPresList=[];
      this.diagPresList=[];
      this.reportList=[];
      this.caseList=[];
      this.medPresFlag=true;
      this.diagPresFlag=true;
      this.caseListFlag=true;
      this.reportListFlag = false;
      this.getReportList("Faeces");
    } else if(this.report =="Scan report") {
      this.medPresList=[];
      this.diagPresList=[];
      this.reportList=[];
      this.caseList=[];
      this.medPresFlag=true;
      this.diagPresFlag=true;
      this.caseListFlag=true;
      this.reportListFlag = false;
      this.getReportList("Scans");
    } else if(this.report =="X-ray report") {
      this.medPresList=[];
      this.diagPresList=[];
      this.reportList=[];
      this.caseList=[];
      this.medPresFlag=true;
      this.diagPresFlag=true;
      this.caseListFlag=true;
      this.reportListFlag = false;
      this.getReportList("X-ray");
    } else if(this.report =="Ultrasound report") {
      this.medPresList=[];
      this.diagPresList=[];
      this.reportList=[];
      this.caseList=[];
      this.medPresFlag=true;
      this.diagPresFlag=true;
      this.caseListFlag=true;
      this.reportListFlag = false;
      this.getReportList("Ultra-Sound");
    } else if(this.report =="Diabetic casesheet") {
      this.medPresList=[];
      this.diagPresList=[];
      this.reportList=[];
      this.caseList=[];
      this.medPresFlag=true;
      this.diagPresFlag=true;
      this.reportListFlag = true;
      this.caseListFlag=false;
      this.getCasesheetList("Diabetic");
    } else if(this.report =="Cardio casesheet") {
      this.medPresList=[];
      this.diagPresList=[];
      this.reportList=[];
      this.caseList=[];
      this.medPresFlag=true;
      this.diagPresFlag=true;
      this.caseListFlag=false;
      this.reportListFlag = true;
      this.getCasesheetList("Cardiology");
    } else if(this.report =="Nephrology casesheet") {
      this.medPresList=[];
      this.diagPresList=[];
      this.reportList=[];
      this.caseList=[];
      this.medPresFlag=true;
      this.diagPresFlag=true;
      this.caseListFlag=false;
      this.reportListFlag = true;
      this.getCasesheetList("Nephrology");
    } else if(this.report =="Pediatric casesheet") {
      this.medPresList=[];
      this.diagPresList=[];
      this.reportList=[];
      this.caseList=[];
      this.medPresFlag=true;
      this.diagPresFlag=true;
      this.caseListFlag=false;
      this.reportListFlag = true;
      this.getCasesheetList("Pediatrics");
    } else if(this.report =="Gynaecology casesheet") {
      this.medPresList=[];
      this.diagPresList=[];
      this.reportList=[];
      this.caseList=[];
      this.medPresFlag=true;
      this.diagPresFlag=true;
      this.caseListFlag=false;
      this.reportListFlag = true;
      this.getCasesheetList("Gynaecology");
    } else if(this.report =="Cardio casesheet") {
      this.medPresList=[];
      this.diagPresList=[];
      this.reportList=[];
      this.caseList=[];
      this.medPresFlag=true;
      this.diagPresFlag=true;
      this.caseListFlag=false;
      this.reportListFlag = true;
      this.getCasesheetList("Cardiology");
    } else if(this.report =="Other casesheet") {
      this.medPresList=[];
      this.diagPresList=[];
      this.reportList=[];
      this.caseList=[];
      this.medPresFlag=true;
      this.diagPresFlag=true;
      this.caseListFlag=false;
      this.reportListFlag = true;
      this.getCasesheetList("Others");
    } else if(this.report =="Ent casesheet") {
      this.medPresList=[];
      this.diagPresList=[];
      this.reportList=[];
      this.caseList=[];
      this.medPresFlag=true;
      this.diagPresFlag=true;
      this.caseListFlag=false;
      this.reportListFlag = true;
      this.getCasesheetList("ENT");
    } else if(this.report =="General casesheet") {
      this.medPresList=[];
      this.diagPresList=[];
      this.reportList=[];
      this.caseList=[];
      this.medPresFlag=true;
      this.diagPresFlag=true;
      this.caseListFlag=false;
      this.reportListFlag = true;
      this.getCasesheetList("General");
    } else if(this.report =="Dental") {
      this.medPresList=[];
      this.diagPresList=[];
      this.reportList=[];
      this.caseList=[];
      this.medPresFlag=true;
      this.diagPresFlag=true;
      this.caseListFlag=false;
      this.reportListFlag = true;
      this.getCasesheetList("Dental");
    }
  }

  getMedPresList() {
    this.sendData = {};
    this.sendData.client_id = this.gservice.Client_login_detail_data.Client_Id;
    this.sendData.home_care = "0";
    this.sendData.relation = this.relationDesc; 
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.medPresUrl, this.sendData, { headers: headers }).subscribe(
      data => {
        var dataval = data.json();
        if (dataval != null) {
          this.medPresListData = dataval.prescriptions;
          if (this.medPresListData != null) {
            for (var i = 0; i < this.medPresListData.length; i++) {
              var new_datalist = this.medPresListData[i];
              this.docnameFlag = false;
              var Docname = "";
              var res_clientname = "";
              if(new_datalist.first_name != undefined){
                if (new_datalist.middle_name != undefined && new_datalist.middle_name != "") {
                  Docname = new_datalist.first_name + " " + new_datalist.middle_name + " " + new_datalist.last_name;
                } else {
                  Docname = new_datalist.first_name + " " + new_datalist.last_name;
                }
                this.docnameFlag=true;
              }
              if (new_datalist.client_middle_name != undefined && new_datalist.client_middle_name != "") {
                res_clientname = encrypt_decript.Decript(new_datalist.client_first_name) + " " + encrypt_decript.Decript(new_datalist.client_middle_name) + " " + encrypt_decript.Decript(new_datalist.client_last_name);
              } else {
                res_clientname = encrypt_decript.Decript(new_datalist.client_first_name) + " " + encrypt_decript.Decript(new_datalist.client_last_name);
              }
              var App_date = "";
              if (new_datalist.date != null) {
                App_date = new_datalist.date.split('-')[2] + "-" + new_datalist.date.split('-')[1] + "-" + new_datalist.date.split('-')[0];
              }
              var pres_drug_id = "";
              this.medPresList.push({
                doctorname: Docname,
                clientname: res_clientname,
                date: App_date,
                pres_drug_id: this.medPresListData[i].pres_drug_id,
                docname_flag: this.docnameFlag
              });
            }
          }
          this.medPresFlag = (this.medPresList.length == 0) ? true : false;
        }
      },error => {});
  }

  getDiagPresList(){
    this.sendData = {};
    this.sendData.client_id = this.gservice.Client_login_detail_data.Client_Id;
    this.sendData.home_care = "0";
    this.sendData.relation = this.relationDesc; 
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.diagUrl, this.sendData, { headers: headers }).subscribe(
      data => {
        var dataval = data.json();
        if(dataval!=null){
        var diag_pres_list_data = dataval.prescriptions;
        if (diag_pres_list_data != null) {
          for (var i = 0; i < diag_pres_list_data.length; i++) {
            var new_datalist = diag_pres_list_data[i];
            var Docname = "";
            var res_clientname = "";
            if (new_datalist.middle_name != undefined && new_datalist.middle_name != "") {
              Docname = new_datalist.first_name + " " + new_datalist.middle_name + " " + new_datalist.last_name;
            }else {
              Docname = new_datalist.first_name + " " + new_datalist.last_name;
            }
            if (new_datalist.client_middle_name != undefined && new_datalist.client_middle_name != "") {
              res_clientname = encrypt_decript.Decript(new_datalist.client_first_name) + " " + encrypt_decript.Decript(new_datalist.client_middle_name) + " " + encrypt_decript.Decript(new_datalist.client_last_name);
            }else {
              res_clientname = encrypt_decript.Decript(new_datalist.client_first_name) + " " + encrypt_decript.Decript(new_datalist.client_last_name);
            }
            var App_date = "";
            if (new_datalist.date != null) {
              App_date = new_datalist.date.split('-')[2] + "-" + new_datalist.date.split('-')[1] + "-" + new_datalist.date.split('-')[0];
            }
            var diag_centre_name = "";
            this.diagPresList.push({
              pres_diag_id: diag_pres_list_data[i].pres_diag_id,
              doctorname: Docname,
              clientname: res_clientname,
              date: App_date,
              diag_centre_name: diag_pres_list_data[i].diag_centre_name,
            })
          }
        }
        this.diagPresFlag = (this.diagPresList.length == 0) ? true : false;
      }
    },error => {});
  }

  viewMedPres(pres_id){
    Client_Helper.setmedialpres(null);
    var new_data={
      pres_id :pres_id
    }
    Client_Helper.setmedialpres(new_data);
    this.clientservice.sendMessage('recordwallet_medpres');
  }

  viewDiagPres(pres_id){
    Client_Helper.setopenmedpres(null);
    Client_Helper.setDiagPresList(null);
    var new_data={
      pres_id :pres_id,
      flag:"record"
    }
    Client_Helper.setDiagPresList(new_data);
    this.clientservice.sendMessage('diagnosis_pres_list');
  }

  getReportList(type){
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "record/reportlist", {
       client_id: this.gservice.Client_login_detail_data.Client_Id,
        home_care: "0",
        relation: this.relationDesc,
        type: type,
      },
      { headers: headers }).subscribe(
        data => {
          var dataval = data.json();
          if (dataval.reportlist != null) {
            if (dataval.reportlist.length != 0) {
              for (var z = 0; z < dataval.reportlist.length; z++) {
                var report_list = dataval.reportlist[z];
                var client_name = "";
                var doct_name = "";
                if (dataval.reportlist[z].client_middle_name != null) {
                  if (encrypt_decript.Decript(dataval.reportlist[z].client_middle_name) != "") {
                    client_name = encrypt_decript.Decript(dataval.reportlist[z].client_first_name) + " " +
                      encrypt_decript.Decript(dataval.reportlist[z].client_middle_name) + " " +
                      encrypt_decript.Decript(dataval.reportlist[z].client_last_name) + " ";
                  }else {
                    client_name = encrypt_decript.Decript(dataval.reportlist[z].client_first_name) + " " +
                      encrypt_decript.Decript(dataval.reportlist[z].client_last_name) + " ";
                  }
                }else {
                  client_name = encrypt_decript.Decript(dataval.reportlist[z].client_first_name) + " " +
                    encrypt_decript.Decript(dataval.reportlist[z].client_last_name) + " ";
                }
                if (dataval.reportlist[z].middle_name != null && dataval.reportlist[z].middle_name != "") {
                  doct_name = dataval.reportlist[z].first_name + " " + dataval.reportlist[z].middle_name + " " + dataval.reportlist[z].last_name;
                }else {
                  doct_name = dataval.reportlist[z].first_name + " " + dataval.reportlist[z].last_name;
                }
                if (dataval.reportlist[z].diag_centre_name != null && dataval.reportlist[z].diag_centre_name != "") {
                  var diag_centre_name = dataval.reportlist[z].diag_centre_name
                }
                if (dataval.reportlist[z].diag_test_name != null && dataval.reportlist[z].diag_test_name != "") {
                  var diag_test_name = dataval.reportlist[z].diag_test_name
                }
                this.reportList.push({
                  Client_Name: client_name,
                  doctor_name: doct_name,
                  diag_centre_name: diag_centre_name,
                  diag_test_name: diag_test_name,
                  date: dataval.reportlist[z].appointment_date.split('-')[2].split("T")[0] + "-" + dataval.reportlist[z].appointment_date.split('-')[1] + "-" + dataval.reportlist[z].appointment_date.split('-')[0],
                  diag_test_id: dataval.reportlist[z].diag_test_id,
                  diag_appointment_id: dataval.reportlist[z].diag_appointment_id,
                  pres_diag_id: dataval.reportlist[z].pres_diag_id,
                  pres_test_id: dataval.reportlist[z].pres_test_id,
                  relation_id: dataval.reportlist[z].relation_id,
                  sub_rel_id: dataval.reportlist[z].sub_rel_id,
                  sample_collected_time: dataval.reportlist[z].sample_collected_time,
                  type: type
                })
              }
              this.reportListFlag=false;
            }
          }
        },error => {});
  }

  viewReportDetails(app_id, pres_test_id, pres_diag_id, diag_test_id, type, cat_type) {
    var send_data={
      pres_test_id: pres_test_id,
      pres_diag_id: pres_diag_id,
      diag_app_id: app_id,
      report_type: type,
      cat_type : cat_type,
      relation: this.relationDesc,
    }
    Client_Helper.setReportDetails(send_data)
    this.clientservice.sendMessage('report_details');
  }

  getCasesheetList(datatype){
    this.sendData = {};
    this.sendData.client_reg_id = this.gservice.Client_login_detail_data.Client_Id;
    this.sendData.home_care = "0";
    this.sendData.relation_name = this.relationDesc; 
    this.sendData.country="IN";
    this.caseList=[];
    if (datatype == "Diabetic") {
      this.caseListUrl = ipaddress.getIp + "record/dcaselist"
    } else if (datatype == "Nephrology") {
      this.caseListUrl = ipaddress.getIp + "record/ncaselist"
    } else if (datatype == "Pediatrics") {
      this.caseListUrl = ipaddress.getIp + "record/pcaselist"
    } else if (datatype == "Gynaecology") {
      this.caseListUrl = ipaddress.getIp + "record/gcaselist"
    } else if (datatype == "Dental") {
      this.caseListUrl = ipaddress.getIp + "record/clist"
    } else if (datatype == "Cardiology") {
      this.caseListUrl = ipaddress.getIp + "record/cordlist"
    } else if (datatype == "Others") {
      this.caseListUrl = ipaddress.getIp + "record/otherlist"
    } else if (datatype == "ENT") {
      this.caseListUrl = ipaddress.getIp + "record/entlist"
    } else if (datatype == "General") {
      this.caseListUrl = ipaddress.getIp + "record/genlist"
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.caseListUrl, this.sendData, { headers: headers }).subscribe(
      data => {
        var dataval = JSON.parse(data["_body"]);
        this.caseList = [];
        if (dataval != undefined) {
          for (var i = 0; i < dataval.case_history.length; i++) {
            var new_datalist = dataval.case_history[i];
            var Docname = "", case_list_text;
            var res_clientname = "";
            if (new_datalist.dr_middle_name != undefined && new_datalist.dr_middle_name != "") {
              Docname = new_datalist.dr_first_name + " " + new_datalist.dr_middle_name + " " + new_datalist.dr_last_name;
            } else {
              Docname = new_datalist.dr_first_name + " " + new_datalist.dr_last_name;
            }
            if (new_datalist.middle_name != undefined) {
              res_clientname = encrypt_decript.Decript(new_datalist.first_name) + " " + encrypt_decript.Decript(new_datalist.middle_name) + " " + encrypt_decript.Decript(new_datalist.last_name);
            } else {
              res_clientname = encrypt_decript.Decript(new_datalist.first_name) + " " + encrypt_decript.Decript(new_datalist.last_name);
            }
            var App_date = "", gender, age, case_hist_id, relationship_name, Sub_Special, modified = "0",hptl_clinic_id,spl_id, dr_first_name,dr_last_name,licence_code,relationship_name,doc_reg_id;
            if (new_datalist.created_date != null) {
              App_date = new_datalist.created_date.split('-')[2] + "-" + new_datalist.created_date.split('-')[1] + "-" + new_datalist.created_date.split('-')[0];
            }
            if (new_datalist.modified_date != null) {
              modified = new_datalist.modified_date.split('-')[2] + "-" + new_datalist.modified_date.split('-')[1] + "-" + new_datalist.modified_date.split('-')[0];
            } else {
              modified = "0";
            }
            if (encrypt_decript.Decript(new_datalist.gender) != undefined && encrypt_decript.Decript(new_datalist.gender) != "") {
              gender = encrypt_decript.Decript(new_datalist.gender);
            }
            if ((new_datalist.age) != undefined && (new_datalist.age) != "") {
              age = (new_datalist.age);
            }
            if ((new_datalist.case_hist_id) != undefined && (new_datalist.case_hist_id) != "") {
              case_hist_id = (new_datalist.case_hist_id);
            }
            if ((new_datalist.spl_desc) != undefined && (new_datalist.spl_desc) != "") {
              Sub_Special = (new_datalist.spl_desc);
            }
            if ((new_datalist.relationship_name) != undefined && (new_datalist.relationship_name) != "") {
              relationship_name = (new_datalist.relationship_name);
            }
            if ((new_datalist.relation_id) != undefined && (new_datalist.relation_id) != "") {
              this.relationId = (new_datalist.relation_id);
            }
            var created_time;
            if ((new_datalist.sub_rel_id) != undefined && (new_datalist.sub_rel_id) != "") {
              this.subRelId = (new_datalist.sub_rel_id);
            }
            if ((new_datalist.created_time) != undefined && (new_datalist.created_time) != "") {
              created_time = new_datalist.created_time;
            }
            if (new_datalist.modified_date != null) {
              case_list_text = "Case sheet for " + res_clientname + " assessed by " + "Dr. " + Docname+" updated on " + Date_Formate(new_datalist.modified_date)  ;
            } else {
              case_list_text = "Case sheet for " + res_clientname + " assessed by " + "Dr. " + Docname;
            }
            if(new_datalist.spl_id == '29'){
              datatype = "General surgery";
            }
            if ((new_datalist.spl_id) != undefined && (new_datalist.spl_id) != "") {
              spl_id = (new_datalist.spl_id);
            }
            if ((new_datalist.dr_first_name) != undefined && (new_datalist.dr_first_name) != "") {
              dr_first_name = (new_datalist.dr_first_name);
            }
            if ((new_datalist.dr_last_name) != undefined && (new_datalist.dr_last_name) != "") {
              dr_last_name = (new_datalist.dr_last_name);
            }
            if ((new_datalist.licence_code) != undefined && (new_datalist.licence_code) != "") {
              licence_code = (new_datalist.licence_code);
            }
            if ((new_datalist.hptl_clinic_id) != undefined && (new_datalist.hptl_clinic_id) != "") {
              hptl_clinic_id = (new_datalist.hptl_clinic_id);
            }
            if ((new_datalist.relationship_name) != undefined && (new_datalist.relationship_name) != "") {
              relationship_name = (new_datalist.relationship_name);
            }
            if ((new_datalist.doc_reg_id) != undefined && (new_datalist.doc_reg_id) != "") {
              doc_reg_id = (new_datalist.doc_reg_id);
            }
            this.caseList.push({
              case_list_data: case_list_text,
              case_hist_id: case_hist_id,
              modified: modified,
              relation_id: this.relationId,
              sub_rel_id: this.subRelId,
              created_time: created_time,
              gender: gender,
              age: age,
              date: App_date,
              name: res_clientname,
              type: datatype,
              Sub_Special: Sub_Special,
              DOB: new_datalist.dob,
              hptl_clinic_id:hptl_clinic_id,
              spl_id:spl_id,
              dr_first_name: dr_first_name,
              dr_last_name: dr_last_name,
              licence_code: licence_code,
              relationship_name: relationship_name,
              doc_reg_id: doc_reg_id,
            });
          }
        }
      },
      error => {
      }
    )
  }

  casesheetDetails(caselist){
    if (caselist.type == "Diabetic") {
      this.spl = "Diabetes";
     this.getHospitalLocation(caselist,caselist.hptl_clinic_id);
    } else if (caselist.type == "Pediatrics") {
      this.spl = "Pediatrics";
      this.getHospitalLocation(caselist,caselist.hptl_clinic_id);
    } else if (caselist.type == "Gynaecology") {
      this.spl = "Gynecology";
     this.getHospitalLocation(caselist,caselist.hptl_clinic_id)
    } else if (caselist.type == "Nephrology") {
      this.spl = "Nephrology";
      this.getHospitalLocation(caselist,caselist.hptl_clinic_id);
    } else if (caselist.type == "Dental") {
      this.spl = "Dental";
      this.getHospitalLocation(caselist,caselist.hptl_clinic_id);
    } else if (caselist.type == "Cardiology") {
      this.spl = "Cardiology";
      this.getHospitalLocation(caselist,caselist.hptl_clinic_id);
    }else if (caselist.type == "General") {
      this.spl = "General";
      this.getHospitalLocation(caselist,caselist.hptl_clinic_id);
    }else if (caselist.type == "General surgery") {
      this.spl = "General surgery";
      this.getHospitalLocation(caselist,caselist.hptl_clinic_id);
    }else if (caselist.type == "Others") {
      this.spl = "Others";
      this.getHospitalLocation(caselist,caselist.hptl_clinic_id);
    }else if (caselist.type == "ENT") {
      this.spl = "ENT";
      this.getHospitalLocation(caselist,caselist.hptl_clinic_id);
    }
  }

  getHospitalLocation(caselist,hospitalId){
    this.hospUrl=ipaddress.getIp+"usercontroller/hospdetails";
    var headers=new Headers();
    headers.append('Content-Type','application/json');
    this.http.post(this.hospUrl,JSON.stringify({hosp_clinic_id:hospitalId}), {headers:headers}).subscribe(
      data=>{
        this.hobj=data.json();
        if(this.hobj!=null){
          Helper_Class.setHospitalInfo(this.hobj);
          this.getHelperdetails(caselist,this.hobj);
        }
      })
  }

  getHelperdetails(caselist,location){
    Helper_Class.setpediaRet(null);
    var patient_list:any = [];
    patient_list = {
      Age_data: caselist.age,
      Gender_data: caselist.gender,
      client_name: caselist.name,
      image: this.image,
      sub_id: caselist.sub_rel_id,
      Client_id: this.gservice.Client_login_detail_data.Client_Id,
      rel_id: caselist.relation_id,
      Case_Clnt_dob: this.ClntDob,
      relationship_name: caselist.relationship_name,
      hptl_clinic_id: caselist.hptl_clinic_id,
      user_id: caselist.doc_reg_id,
      spl_id: caselist.spl_id,
      spl: this.spl,
      Appoint_Date: caselist.date,
      medicare_name: this.medicareName,
      AppFlow: "client",
    };
    var doctor_list:any = [];
    doctor_list = {
      hptl_logo: location.hptl_logo,
      hptl_name: location.hosp_name,
      address1: location.address1,
      address2: location.address2,
      location: location.location,
      city: location.city,
      state: location.state,
      country: location.country,
      zipcode: location.zipcode,
      mobile: location.telephone,
      website: location.website,
      first_name:caselist.dr_first_name,
      last_name: caselist.dr_last_name
    }
    this.addr=location.address1+location.address2+", "+location.city+", "+location.state+", "+location.country+", "+location.zipcode;
    var addr = {
      address_data: this.addr,
      city_zip:location.city,
      state_country:location.country
    }
    Doc_Helper.setDoctorAddr(addr);
    Helper_Class.setclient_hospitalDetails(doctor_list);
    Helper_Class.setInfo("");
    Helper_Class.setInfo(patient_list);
    Doc_Helper.setClient_Info("");
    Doc_Helper.setClient_Info(patient_list);
    var send_data;
    if (caselist.sub_rel_id != null && caselist.sub_rel_id != undefined &&
      caselist.sub_rel_id != "" && caselist.sub_rel_id.length != 0) {
      send_data = JSON.stringify({
        case_hist_id: caselist.case_hist_id,
        client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
        relation_id: caselist.relation_id,
        sub_rel_id: caselist.sub_rel_id,
        created_date: Date_Formate(caselist.date),
        country: "IN",
      })
    }
    else {
      send_data = JSON.stringify({
        case_hist_id: caselist.case_hist_id,
        client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
        relation_id: caselist.relation_id,
        created_date: Date_Formate(caselist.date),
        country: "IN",
      })
    }
    if(caselist.type == "Diabetic"){
      this.splUrl = 'diab/chist';
    }
    if(caselist.type == "Nephrology"){
      this.splUrl = 'neph/chist';
    }
    if(caselist.type == "Cardiology"){
      this.splUrl = 'cardio/chist';
    }
    if(caselist.type == "Pediatrics"){
      this.splUrl = 'pedia/chist';
    }
    if(caselist.type == "Dental"){
      this.splUrl = 'dental/perdet/';
    }
    if(caselist.type == "General" || caselist.type == "General surgery"){
      this.splUrl = 'gen/chist';
    }
    if (caselist.type == "ENT") {
      this.splUrl = 'ent/chist';
    }
    if (caselist.type == "Others") {
      this.splUrl = 'other/chist';
    }
    if(caselist.type == "Gynaecology"){
     this.getGynaretrievalData(caselist);
    }else{
      var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + this.splUrl, send_data,
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          if (obj != null || obj != "" || obj != undefined) {
            Helper_Class.setRet(obj);
            if (caselist.type == "Pediatrics") {
              Helper_Class.setpediaRet(obj);
              this.getCaseSheetFields(caselist);
            }else if(caselist.type == "Nephrology"){
              Helper_nephro_casesheet.nephro_get_ret = obj;
              this.getCaseSheetFields(caselist);
            }else if(caselist.type == "Dental"){
              Helper_Class.Set_den_ret1(obj);
              this.dentalRetrieval2(caselist);
            }else{
              this.getCaseSheetFields(caselist);
            }
          }
          else {
          }
        },
        error => {
        })
    }   
  }

  getCaseSheetFields(caselist){
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'adm/gpfhsp/',
      JSON.stringify({
        hosp_id: caselist.hptl_clinic_id,
        spl_id: caselist.spl_id
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          Helper_Class.setHopitalfieldsRet(null);
          Helper_Class.setHopitalfieldsRet(obj.pages);
          this.getCaseSheetData(caselist);
        },
      )
  }

  getCaseSheetData(caselist) {
    Doc_Helper.setDischarge_Summary(undefined);
    var sen_pass
    if (caselist.sub_rel_id != null && caselist.sub_rel_id != "" && caselist.sub_rel_id !=  undefined) {
      sen_pass = JSON.stringify({
        client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
        relation_id: caselist.relation_id,
        sub_rel_id: caselist.sub_rel_id,
        country: ipaddress.country_code
      });
    } else {
      sen_pass = JSON.stringify({
        client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
        relation_id: caselist.relation_id,
        country: ipaddress.country_code
      });
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'gen/cbook', sen_pass,
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          if (obj["key"] != 0) {
            if (obj != null && obj.length != 0 && obj != undefined && obj != "") {
              Doc_Helper.setDischarge_Summary("S");
              Helper_Class.setcasesumret(obj);
            }else {
              Helper_Class.setcasesumret(undefined);
            }
          } else {
            Helper_Class.setcasesumret(undefined);
          }
            this.clientservice.sendMessage("casesheet");
        },
        error => {
        }
      )
  }

  getGynaretrievalData(caselist) {
    Helper_Class.setgynaRet(undefined)
    Helper_Class.setRet(undefined)
    if (Doc_Helper.getClient_Info().sub_id.length != 0 && Doc_Helper.getClient_Info().sub_id !=null && Doc_Helper.getClient_Info().sub_id != undefined) {
      var sub_id_data = Doc_Helper.getClient_Info().sub_id;
      var sendata = JSON.stringify({
        client_reg_id: Doc_Helper.getClient_Info().Client_id,
        relation_id: Doc_Helper.getClient_Info().rel_id,
        sub_rel_id: sub_id_data,
        country: ipaddress.country_code
      });
    } else {
      sendata = JSON.stringify({
        client_reg_id: Doc_Helper.getClient_Info().Client_id,
        relation_id: Doc_Helper.getClient_Info().rel_id,
        country: ipaddress.country_code
      })
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'goi/chist', sendata,
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          Helper_Class.setgynaRet(obj);
          Helper_Class.setRet(obj);
          var gyna_field = obj.visit_purpose;
           if( gyna_field == "Gynaecology"){
            Doc_Helper.setGyna_fields(null);
            Doc_Helper.setGyna_fields(gyna_field);
            this.getCaseSheetFields(caselist)
           }else{
            Doc_Helper.setGyna_fields(null);
            Doc_Helper.setGyna_fields("Obstetrics");
            this.getCaseSheetFields(caselist)
           }
        },
        error => {})
  }

  dentalRetrieval2(caselist) {
      if (caselist.sub_rel_id != null && caselist.sub_rel_id != undefined &&
        caselist.sub_rel_id != "" && caselist.sub_rel_id.length != 0){
      var sen_pass = JSON.stringify({
        client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
        relation_id: caselist.relation_id,
        sub_rel_id: caselist.sub_rel_id,
        country: "IN",
        den_case_hist_id: Helper_Class.Get_den_ret1().den_case_hist_id,
      });
    } else {
      var sen_pass = JSON.stringify({
        client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
      relation_id: caselist.relation_id,
        country: ipaddress.country_code,
        den_case_hist_id: Helper_Class.Get_den_ret1().den_case_hist_id,
      });
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'dental/patdet/', sen_pass,
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          Helper_Class.Set_den_ret2(obj);
          this.getCaseSheetFields(caselist);
        }
      )
  }
}
