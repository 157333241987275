import { Component, OnInit ,ElementRef,Input,Inject,ViewEncapsulation,HostListener} from '@angular/core';
import { Http, Headers } from '@angular/http';
// import { ipaddress.getIp, Ip_with_img_address, country_code } from '../../../assets/js/ipaddress';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Doc_Helper } from '../Doc_Helper';
import { ipaddress } from '../../ipaddress' ;import { masterCSData_Helper } from '../casesheet/masterCSData_Helper';
import { AnyMxRecord } from 'dns';
import { MasterCSHelperService } from '../casesheet/masterCSData.service';

@Component({
  selector: 'app-icd-code-popup',
  templateUrl: './icd-code-popup.component.html',
  styleUrls: ['./icd-code-popup.component.css']
})
export class IcdCodePopupComponent implements OnInit {
  public chapterlist;
  public sectionlist = [];
  public subsectionlist = [];
  public subsectionlist1 = [];
  public codelist = [];
  public codelist1 = [];
  public subcodelist = [];
  public subcodelist1 = [];
  public topiclist;
  public icd_btn: boolean;
  public chapter_code;
  public topic_code;
  public section_code;
  public subsection_code;
  public main_code;
  public chapter_txt;
  public topic_txt;
  public subsection_div: boolean;
  public code_div: boolean;
  public dis_txt;
  public subcode_div: boolean;
  public subcode_txt;
  public code_txt;
  public subsec_txt;
  public sec_txt;
  @Input() id: string;parm: string;  
  public element: any;
  public parent;

  constructor(public http:Http, public el: ElementRef,public dialogRef: MatDialogRef<IcdCodePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AnyMxRecord,public masterData:MasterCSHelperService) {
      dialogRef.disableClose = true;
    this.element = el.nativeElement;
    this.chapter_txt = undefined;
    this.topic_txt = undefined;
    this.sec_txt = undefined;
    this.subsec_txt = undefined;
    this.code_txt = undefined;
    this.subcode_txt = undefined; 
    // this.medicalpres.dis_txt= this.dis_txt;
   }

  async ngOnInit() {
    this.parent = Doc_Helper.getClient_Info().spl;
    this.icd_btn = true;
    if(masterCSData_Helper.getMasterICDChapter() == undefined){
      await this.masterData.getChapter();
      this.Get_chapter();
    }else{
      this.Get_chapter();
    }
    this.subsection_div = true;
    this.subcode_div = true;
    this.code_div = true;
  }

  Get_chapter() {
    var obj = masterCSData_Helper.getMasterICDChapter();
    if(obj != undefined){

      this.chapterlist = obj.chapters;
      for (var i = 0; i < this.chapterlist.length; i++) {
        if(this.parent =="Dental" && this.chapterlist[i].code =="K00-K95"){
          this.chapter_txt = this.chapterlist[i].description;
          this.default_select_topic(this.chapterlist[i]);
          break;
        } else if (this.parent =="Diabetes" && this.chapterlist[i].code == "E00-E89") {
          this.chapter_txt = this.chapterlist[i].description;
          this.default_select_topic(this.chapterlist[i]);
          break;
        } else if (this.parent =="Gynecology" && this.chapterlist[i].code == "N00-N99") {
          this.chapter_txt = this.chapterlist[i].description;
          this.default_select_topic(this.chapterlist[i]);
          break;
        } else if (this.parent =="Pediatrics" && this.chapterlist[i].code == "K00-K95") {
          this.chapter_txt = this.chapterlist[i].description;
          this.default_select_topic(this.chapterlist[i]);
          break;
        } else if (this.parent =="Cardiology" && this.chapterlist[i].code == "I00-I99") {
          this.chapter_txt = this.chapterlist[i].description;
          this.default_select_topic(this.chapterlist[i]);
          break;
        }  else if (this.parent =="Nephrology" && this.chapterlist[i].code == "N00-N99") {
          this.chapter_txt = this.chapterlist[i].description;
          this.default_select_topic(this.chapterlist[i]);
          break;
        }
      }
    }
  }

  //Get default set topic
  default_select_topic(chapter) {
    for (var i = 0; i < this.chapterlist.length; i++) {
      if (chapter.description == this.chapterlist[i].description) {
        this.chapter_code = this.chapterlist[i].code
      }
    }

    this.sectionlist = [];
    this.subsectionlist = [];
    this.subsectionlist1 = [];

    this.codelist = [];
    this.codelist1 = [];

    this.subcodelist = [];
    this.subcodelist1 = [];

    this.subsection_div = true;
    this.code_div = true;
    this.subcode_div = true;

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'icd/topic/',
      JSON.stringify({
        chapter: this.chapter_code
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.topiclist = obj.topics;
          for (var i = 0; i < this.topiclist.length; i++) {
            if(this.parent =="Dental" && this.topiclist[i].code =="K00-K14"){
              this.topic_txt = this.topiclist[i].description;
              this.defult_select_section(this.topiclist[i]);
              break;
            } else if (this.parent =="Diabetes" && this.topiclist[i].code == "E08-E13") {
              this.topic_txt = this.topiclist[i].description;
              this.defult_select_section(this.topiclist[i]);
              break;
            } else if (this.parent =="Gynecology" && this.topiclist[i].code == "N70-N77") {
              this.topic_txt = this.topiclist[i].description;
              this.defult_select_section(this.topiclist[i]);
              break;
            } else if (this.parent =="Pediatrics" && this.topiclist[i].code == "K55-K64") {
              this.topic_txt = this.topiclist[i].description;
              this.defult_select_section(this.topiclist[i]);
              break;
            } else if (this.parent =="Cardiology" && this.topiclist[i].code == "I70-I79") {
              this.topic_txt = this.topiclist[i].description;
              this.defult_select_section(this.topiclist[i]);
              break;
            } else if (this.parent =="Nephrology" && this.topiclist[i].code == "N25-N29") {
              this.topic_txt = this.topiclist[i].description;
              this.defult_select_section(this.topiclist[i]);
              break;
            }
          }
          this.icd_btn =  this.topiclist.length == 0 ? false : true;
        },
        error => {});
  }

  defult_select_section(topic1) {//Get default section
    this.sectionlist = [];
    this.subsectionlist = [];
    this.subsectionlist1 = [];
    this.codelist = [];
    this.codelist1 = [];
    this.subcodelist
    this.subcodelist1 = [];

    for (var i = 0; i < this.topiclist.length; i++) {
      if (topic1.description == this.topiclist[i].description) {
        this.topic_code = this.topiclist[i].code
      }
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'icd/section/',
      JSON.stringify({
        topic: this.topic_code
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.sectionlist = obj.topics;
          this.icd_btn = this.sectionlist.length == 0 ? false : true;
        },
        error => {});
  }

  Chapter_change(chapter) {//Get topic based on chapter
    for (var i = 0; i < this.chapterlist.length; i++) {
      if (chapter == this.chapterlist[i].description) {
        this.chapter_code = this.chapterlist[i].code
      }
    }

    this.subsection_div = true;
    this.code_div = true;
    this.subcode_div = true;
    this.sectionlist = [];
    this.subsectionlist = [];
    this.subsectionlist1 = [];
    this.codelist = [];
    this.codelist1 = [];
    this.subcodelist = [];
    this.subcodelist1 = [];

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'icd/topic/',
      JSON.stringify({
        chapter: this.chapter_code
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.topiclist = obj.topics;
          this.icd_btn = this.topiclist.length == 0 ? false : true;
        },
        error => {})
  }

  topic_Change(topic1) {
    this.sectionlist = [];
    this.subsectionlist = [];
    this.subsectionlist1 = [];
    this.codelist = [];
    this.codelist1 = [];
    this.subcodelist
    this.subcodelist1 = [];

    for (var i = 0; i < this.topiclist.length; i++) {
      if (topic1 == this.topiclist[i].description) {
        this.topic_code = this.topiclist[i].code
      }
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'icd/section/',
      JSON.stringify({
        topic: this.topic_code
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.sectionlist = obj.topics;
          this.icd_btn = this.sectionlist.length == 0 ? false : true;
        },
        error => {})
  }

  section_Change(section) {//Get sub section
    this.subsectionlist = [];
    this.subsectionlist1 = [];
    this.codelist = [];
    this.codelist1 = [];
    this.subcodelist = [];
    this.subcodelist1 = [];
    this.subsection_div = true;
    this.code_div = true;
    this.subcode_div = true;

    for (var i = 0; i < this.sectionlist.length; i++) {
      if (section == this.sectionlist[i].description) {
        this.section_code = this.sectionlist[i].code
      }
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'icd/subsec/',
      JSON.stringify({
        section: this.section_code
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.subsectionlist = obj.topics;
          if (this.subsectionlist != undefined) {
            for (var i = 0; i < this.subsectionlist.length; i++) {
              this.subsectionlist1.push(this.subsectionlist[i].code)
            }
          }

          if (this.subsectionlist1.length == 0) {
            this.icd_btn = false;
            this.subsection_div = true;
            this.code_div = true;
            this.subcode_div = true;
          }else {
            this.icd_btn = true;
            this.subsection_div = false;

          }
        },
        error => {});
  }

  subsection_Change(subsection) {
    this.codelist = [];
    this.codelist1 = [];
    this.subcodelist = [];
    this.subcodelist1 = [];

    for (var i = 0; i < this.subsectionlist.length; i++) {
      if (subsection == this.subsectionlist[i].description) {
        this.subsection_code = this.subsectionlist[i].code
      }
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'icd/code/',
      JSON.stringify({
        subsec: this.subsection_code
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.codelist = obj.topics;

          if (this.codelist != undefined) {
            for (var i = 0; i < this.codelist.length; i++) {
              this.codelist1.push(this.codelist[i].code)
            }
          }

          if (this.codelist1.length == 0) {
            this.icd_btn = false;
            this.code_div = true;
          }else {
            this.icd_btn = true;
            this.code_div = false;
          }
        },
        error => {});
  }
  
  code_Change(sedcode) {
    this.subcodelist = [];
    this.subcodelist1 = [];
    for (var i = 0; i < this.codelist.length; i++) {
      if (sedcode == this.codelist[i].description) {
        this.main_code = this.codelist[i].code
      }
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'icd/subcode/',
      JSON.stringify({
        code: this.main_code
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.subcodelist = obj.topics;
          if (this.subcodelist != undefined) {
            for (var i = 0; i < this.subcodelist.length; i++) {
              this.subcodelist1.push(this.subcodelist[i].code)
            }
          }

          if (this.subcodelist1.length == 0) {
            this.icd_btn = false;
            this.subcode_div = true;
          }else {
            this.icd_btn = false;
            this.subcode_div = false;
          }
        },
        error => {});
  }
  
    @HostListener('window:click', ['$event'])
    onclick(event: any): void {
      this.icdsubmit_click();
  
    }
  
    @HostListener('window:blur', ['$event'])
    onBlur(event: any): void {
      this.icdsubmit_click();
    }
 
    // Icd submit
    icdsubmit_click() {
      if (this.sec_txt != undefined && this.subsec_txt == undefined) {
        for (var i = 0; i < this.sectionlist.length; i++) {
          if (this.sectionlist[i].description.trim() == this.sec_txt.trim()) {
            this.dis_txt = this.sectionlist[i].code + "-" + this.sectionlist[i].description;
            
          }
        }
      }
      if (this.subsec_txt != undefined && this.code_txt == undefined) {
        for (var i = 0; i < this.subsectionlist.length; i++) {
          var text = this.subsectionlist[i].description.trim();
          var text1 = this.subsec_txt.trim();
          if (text == text1) {
            this.dis_txt = this.subsectionlist[i].code + "-" + this.subsectionlist[i].description;
            
          }
        }
      }
      if (this.subsec_txt != undefined && this.code_txt != undefined) {
        for (var i = 0; i < this.codelist.length; i++) {
          if (this.codelist[i].description.trim() == this.code_txt.trim()) {
            this.dis_txt = this.codelist[i].code + "-" + this.codelist[i].description;
            
          }
        }
      }
      if (this.code_txt != undefined && this.subcode_txt != undefined) {
        for (var i = 0; i < this.subcodelist.length; i++) {
          if (this.subcodelist[i].description.trim() == this.subcode_txt.trim()) {
            this.dis_txt = this.subcodelist[i].code + "-" + this.subcodelist[i].description;
            
          }
        }
      }
    }

    ClosePopup(){
      this.dialogRef.close();
    }
}
