import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { ClientViewService } from '../clientview/client-view.service';
import { Client_Helper } from '../Client_helper';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';
import { ServerApi } from 'src/app/server-api';
import { Helper_Class } from 'src/app/helper_class';
@Component({
  selector: 'app-create-recepies',
  templateUrl: './create-recepies.component.html',
  styleUrls: ['./create-recepies.component.scss']
})
export class CreateRecepiesComponent implements OnInit {
  public recepiesName;
  public ingredientsName;
  public weight;
  public quantity;
  public quantityMeasure;
  public getFoodList= [];
  public wightMesure;
  public foodItems;
  public foodId;
  public sendRecepi = [];
  public tempObj;
  public updateFlag:boolean = false;
  public totalKcal;
  public totalWeight;
  constructor(public messageservice:ClientViewService,private gservice:CommonDataService,private http:Http,private routes:ActivatedRoute,private router:Router,public toastr:ToastrService,public serviceAPI: ServerApi) { }
  ngOnInit(): void {
    if( Helper_Class.getRecepiesEdit() != undefined ){
      this.updateFlag = true;
      console.log("Helper_Class.getRecepiesEdit()" + JSON.stringify(Helper_Class.getRecepiesEdit()));
      this.getRetrevel();
      this.recepiesName = Helper_Class.getRecepiesEdit().recepies.recipe_name;
      this.selctedFood(Helper_Class.getRecepiesEdit().recepies);
    }
    
  }
  async getRetrevel(){
    var send = {
      recipe_id: Helper_Class.getRecepiesEdit().recepies.recipe_id,
    }
    var response = await this.serviceAPI.PostData('lifestyle/gdrd',send).toPromise();
    if (response) {
      var obj = JSON.parse(JSON.stringify(response)).food_items;
      console.log("this.obj" + JSON.stringify(obj));
      for( var i = 0; i < obj.length; i++ ){
          if(obj[i].weight != undefined){
            var tempWeight = obj[i].weight.split(" ");
            var calsWight = tempWeight[0];
            var wightMesure = tempWeight[1] !=  undefined ?  tempWeight[1] : "--" ;
          }
          this.sendRecepi.push({
            food_item_id: obj[i].food_item_id,
            description: obj[i].description,
            food_type_id: obj[i].food_type_id,
            food_sub_type: obj[i].food_sub_type,
            orginal_weight : calsWight,
            quantity: obj[i].quantity,
            temp_quantity : obj[i].quantity,
            measure: obj[i].measure,
            weight: calsWight,
            temp_cals_weight : calsWight,
            cals_weight: calsWight,
            wightMesure: wightMesure,
            energy: obj[i].energy,
            proteins: obj[i].proteins,
            carbohydrates: obj[i].carbohydrates,
            fat: obj[i].fat,
            calcium: obj[i].calcium,
            iron: obj[i].iron,
            vitaminC: obj[i].vitaminC,
            vitaminA: obj[i].vitaminA,
            vitaminB12: obj[i].vitaminB12,
            fiber: obj[i].fiber,
            folic: obj[i].folic,
            h2o: obj[i].h2o,
            displayContent: obj[i].description + " (" + obj[i].quantity + " " + obj[i].measure + " - " + obj[i].weight + " )",
            image: obj[i].image,
          })
        }
      this.totalKcal = this.sendRecepi.reduce((temp,energy) => temp = temp + parseFloat(energy.energy),0);
      this.totalWeight = this.sendRecepi.reduce((temp,weight) => temp = temp + parseFloat(weight.weight),0);
    }
  }
   async foodType(e){
    if( this.ingredientsName.length > 3 ){
     var send = {
        item_name: this.ingredientsName
      }
      var response = await this.serviceAPI.PostData('lifestyle/gdbn',send).toPromise();
      if (response) {
        var obj = JSON.parse(JSON.stringify(response));
        obj = obj.food_items;
        console.log("this.obj" + JSON.stringify(obj));
        for( var i = 0; i < obj.length; i++ ){
          if(obj[i].weight != undefined){
            var tempWeight = obj[i].weight.split(" ");
            var calsWight = tempWeight[0];
            var wightMesure = tempWeight[1] !=  undefined ?  tempWeight[1] : "--" ;
          }
          this.getFoodList.push({
            food_item_id: obj[i].food_item_id,
            description: obj[i].description,
            food_type_id: obj[i].food_type_id,
            food_sub_type: obj[i].food_sub_type,
            orginal_weight : obj[i].weight,
            quantity: obj[i].quantity,
            temp_quantity : obj[i].quantity,
            measure: obj[i].measure,
            weight: obj[i].weight,
            temp_cals_weight : calsWight,
            cals_weight: calsWight,
            wightMesure: wightMesure,
            energy: obj[i].energy,
            proteins: obj[i].proteins,
            carbohydrates: obj[i].carbohydrates,
            fat: obj[i].fat,
            calcium: obj[i].calcium,
            iron: obj[i].iron,
            vitaminC: obj[i].vitaminC,
            vitaminA: obj[i].vitaminA,
            vitaminB12: obj[i].vitaminB12,
            fiber: obj[i].fiber,
            folic: obj[i].folic,
            h2o: obj[i].h2o,
            displayContent: obj[i].description + " (" + obj[i].quantity + " " + obj[i].measure + " - " + obj[i].weight + " )",
            image: obj[i].image,
            
          })
        }
        console.log("this.getFoodList" + JSON.stringify(this.getFoodList));
      }
    }
  }
  changeQuantity(value,action) {
    var checked;
    var dicrption = "";
    var energy, proteins_change, carbohydrates_change, fat_change, calcium_change, vitaminC_change, vitaminA_change,vitaminB12_change, fiber_change, folic_change, h2o_change,weight,quantity; 
    this.tempObj
    this.totalKcal = (parseFloat(this.totalKcal) - parseFloat(this.tempObj.energy)).toFixed(2);
         if( action == "quantity" ) {
          energy = ((this.tempObj.energy / this.tempObj.temp_quantity) * (value));
          proteins_change = ((this.tempObj.proteins / this.tempObj.temp_quantity) * (value));
          fat_change = ((this.tempObj.fat / this.tempObj.temp_quantity) * (value));
          calcium_change = ((this.tempObj.calcium / this.tempObj.temp_quantity) * (value));
          vitaminC_change = ((this.tempObj.vitaminC / this.tempObj.temp_quantity) * (value));
          vitaminA_change = ((this.tempObj.vitaminA / this.tempObj.temp_quantity) * (value));
          vitaminB12_change = ((this.tempObj.vitaminB12 / this.tempObj.temp_quantity) * (value));
          fiber_change = ((this.tempObj.fiber / this.tempObj.temp_quantity) * (value));
          folic_change = ((this.tempObj.folic / this.tempObj.temp_quantity) * (value));
          h2o_change = ((this.tempObj.h2o / this.tempObj.temp_quantity) * (value));
          weight = ((this.tempObj.cals_weight / this.tempObj.temp_quantity) * (value));
          quantity = value;
        } else {
          energy = ((this.tempObj.energy / this.tempObj.temp_cals_weight) * (value));
          proteins_change = (this.tempObj.proteins / this.tempObj.temp_cals_weight) * (value);
          carbohydrates_change = (this.tempObj.carbohydrates / this.tempObj.temp_cals_weight) * (value);
          fat_change = (this.tempObj.fat / this.tempObj.temp_cals_weight) * (value);
          calcium_change = ((this.tempObj.calcium / this.tempObj.temp_cals_weight) * (value));
          vitaminC_change = ((this.tempObj.vitaminC / this.tempObj.temp_cals_weight) * (value));
          vitaminA_change = ((this.tempObj.vitaminA / this.tempObj.temp_cals_weight) * (value));
          vitaminB12_change = ((this.tempObj.vitaminB12 / this.tempObj.temp_cals_weight) * (value));
          fiber_change = ((this.tempObj.fiber / this.tempObj.temp_cals_weight) * (value));
          folic_change = ((this.tempObj.folic / this.tempObj.temp_cals_weight) * (value));
          h2o_change = ((this.tempObj.h2o / this.tempObj.temp_cals_weight) * (value));
          weight = value;
          var test =  value / this.tempObj.orginal_weight;
          console.log("test" + JSON.stringify(test))
          if(!this.isDecimal(test)){
            quantity = test ;
          } else {
            quantity = this.tempObj.temp_quantity;
          }
        }
        this.tempObj.weight = parseInt(weight).toString();
        this.tempObj.energy = parseInt(energy).toString();
        this.tempObj.temp_quantity = parseInt(quantity).toString();
        this.tempObj.quantity = parseInt(quantity).toString();
        this.tempObj.temp_cals_weight =  parseInt(weight).toString(); ;
        this.tempObj.cals_weight =  parseInt(weight).toString(); ;
        this.tempObj.proteins =  parseInt(proteins_change).toString(); ;
        this.tempObj.carbohydrates =  parseInt(carbohydrates_change).toString(); ;
        this.tempObj.fat =  parseInt(fat_change).toString(); ;
        this.tempObj.calcium =  parseInt(calcium_change).toString(); ;
        this.tempObj.vitaminC =  parseInt(vitaminC_change).toString(); ;
        this.tempObj.vitaminA =  parseInt(vitaminA_change).toString(); ;
        this.tempObj.vitaminB12 =  parseInt(vitaminB12_change).toString(); ;
        this.tempObj.fiber =  parseInt(fiber_change).toString(); ;
        this.tempObj.folic =  parseInt(folic_change).toString(); ;
        this.tempObj.h2o =  parseInt(h2o_change).toString(); ;
        this.totalKcal = (parseFloat(this.totalKcal) + parseFloat(weight)).toFixed(2);
        console.log("chhhhqqqqqqqqqq--------" + JSON.stringify(this.tempObj))
  }
  // changeQuantity(value, action){
  //   if(action == "weight"){
  //     var test =  value / this.tempObj.orginal_weight;
  //     console.log("test" + JSON.stringify(test))
  //     if(!this.isDecimal(test)){
  //       this.quantity = test;
  //     } 
  //   }
  // }
  isDecimal(num) {
    return (num % 1);
  }
  fileChange1($event): void {
    this.readThis1($event.target);
  }
  public recepiesImage;
  public sendRecepiesImage;
  readThis1(inputValue: any): void {
    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      var fileSize = Math.round(inputValue.files[0].size / 1024);
      if (fileSize >= 50) {
        $("#file1").val('');
        this.recepiesImage = null;
        this.toastr.error("Image size must be below 50Kb");
      } else {
        this.recepiesImage = myReader.result;
        this.sendRecepiesImage = this.recepiesImage.split(',')[1];
        $("#recepiesImage").attr("src", this.recepiesImage);
      }
    }
    myReader.readAsDataURL(inputValue.files[0]);
  }
  selctedFood(foodArray){
    this.tempObj = {};
    this.tempObj = foodArray;
    this.foodId = foodArray.food_item_id;
    this.ingredientsName = foodArray.description
    this.weight = foodArray.cals_weight;
    this.wightMesure = foodArray.wightMesure;
    this.quantity = foodArray.quantity;
    this.quantityMeasure = foodArray.measure;
    this.getFoodList = [];
  }
  add(){
    var flag = true;
      if( this.ingredientsName == "" || this.ingredientsName == undefined ){
        flag = false;
      }
      if( this.weight == "" || this.weight == undefined ){
        flag = false;
      }
      if( this.quantity == "" || this.quantity == undefined ){
        flag = false;
      }
      if( flag == true ){
        this.tempObj.weight = this.weight;
        this.tempObj.quantity = this.quantity;
        this.sendRecepi.push(this.tempObj);
        this.ingredientsName = undefined;
        this.wightMesure = undefined;
        this.weight = undefined;
        this.quantity = undefined;
        this.quantityMeasure = undefined;
        this.totalKcal = this.sendRecepi.reduce((temp,energy) => temp = temp + parseFloat(energy.energy),0);
        this.totalWeight = this.sendRecepi.reduce((temp,weight) => temp = temp + parseFloat(weight.weight),0);
      }else{
        this.toastr.error("Enter all mandatory fields");
      }
      console.log("this.sendRecepi" + JSON.stringify(this.sendRecepi));
  }
  deleteOffer(id){
    var findIndex = this.sendRecepi.findIndex((x) => x.food_item_id == id );
    this.sendRecepi.splice(findIndex,1);
    this.totalKcal = this.sendRecepi.reduce((temp,energy) => temp = temp + parseFloat(energy.energy),0);
    this.totalWeight = this.sendRecepi.reduce((temp,weight) => temp = temp + parseFloat(weight.weight),0);
  }
  editOffer(array){
    this.selctedFood(array);
    setTimeout(() => {
      var findIndex = this.sendRecepi.findIndex((x) => x.food_item_id == array.food_item_id );
      this.sendRecepi.splice(findIndex,1);
      this.totalKcal = this.sendRecepi.reduce((temp,energy) => temp = temp + parseFloat(energy.energy),0);
      this.totalWeight = this.sendRecepi.reduce((temp,weight) => temp = temp + parseFloat(weight.weight),0);
    }, 500);
  }
  back(){
    this.messageservice.sendMessage("listRecepies") 
  }
  async save(){
      if( this.sendRecepi.length != 0){
        var send = {
          recipe_name : this.recepiesName ,
          total_kcal : this.totalKcal ,
          total_weight : this.totalWeight,
          recipe_details: this.sendRecepi,
        //  image : this.sendRecepiesImage,
          country : ipaddress.country_code,
        }
        console.log(" send " + JSON.stringify(send));
        var response = await this.serviceAPI.PostData('lifestyle/sdr',send).toPromise();
        if (response) {
          var obj = JSON.parse(JSON.stringify(response));
          if(obj.key != 0){
            this.toastr.success("Recepies saved successflly");
            this.messageservice.sendMessage("listRecepies") 
          }
        }
      }else{
        this.toastr.error("No recepies to save");
      }
  }
  async update(){
    if( this.sendRecepi.length != 0){
      var send = {
        recipe_name : this.recepiesName ,
        total_kcal : this.totalKcal ,
        total_weight : this.totalWeight,
        recipe_details: this.sendRecepi,
        recipe_id: Helper_Class.getRecepiesEdit().recepies.recipe_id,
      //  image : this.sendRecepiesImage,
        country : ipaddress.country_code,
      }
      console.log(" send " + JSON.stringify(send));
      var response = await this.serviceAPI.PostData('lifestyle/udr',send).toPromise();
      if (response) {
        var obj = JSON.parse(JSON.stringify(response));
        if(obj.key != 0){
          this.toastr.success("Recepies saved successflly");
          this.messageservice.sendMessage("listRecepies") 
        }
      }
    }else{
      this.toastr.error("No recepies to save");
    }
  }
}
