import { ReturnStatement } from "@angular/compiler";

export class pharmacy_helper{
    static pharmacyidlist;
    static setpoidlist(value:any){
        this.pharmacyidlist=null;
        this.pharmacyidlist=value;
    }
    static getpoidlist(){
        return this.pharmacyidlist;
    }

    static pharmacyinfo;
    static setpharmaInfo(value:any){
        this.pharmacyinfo=null;
        this.pharmacyinfo=value;
    }
    static getpharmaInfo(){
        return this.pharmacyinfo;
    }
    static pharmacyflow;
    static setpharmacyflow(value:any){
        this.pharmacyinfo=null;
        this.pharmacyinfo=value;
    }
    static getpharmacyflow(){
        return this.pharmacyinfo;
    }
    static cureentorder;
    static setcurrentinformation_pharrmacy(value:any){
        this.cureentorder=null;
        this.cureentorder=value;
    }
    static getcurrentinformation_pharrmacy(){
        return this.cureentorder;
    }
    static pastorder;
    static setpastinformation_pharrmacy(value:any){
        this.cureentorder=null;
        this.cureentorder=value;
    }
    static getpastinformation_pharrmacy(){
        return this.cureentorder;
    }
    static orderview;
    static setorderview(value:any){
        this.orderview=null;
        this.orderview=value;
    }
    static getorderview(){
        return this.orderview;
    }
    static purchaserequestlistpage;
    static setfromreqlist(value:any){
        this.purchaserequestlistpage = null;
        this.purchaserequestlistpage = value;
    }
    static getfromreqlist(){
        return this.purchaserequestlistpage;
    }

    static pharmapoid;
    static setpoid(value:any){
        this.pharmapoid=null;
        this.pharmapoid=value;
    }
    static getpoid(){
        return this.pharmapoid

    }

    static pharmapono;
    static setpono(value:any){
        this.pharmapono=null;
        this.pharmapono=value;
    }
    static getpono(){
        return this.pharmapono

    }

    static pharmapoid1;
    static setviewpres(value:any){
        this.pharmapoid1=null;
        this.pharmapoid1=value;
    }
    static getviewpres(){
        return this.pharmapoid1
    }

    //setModuleList
    static pharmaModuleList;
    static setModuleList(value:any){
        this.pharmaModuleList=null;
        this.pharmaModuleList=value;
    }
    static getModuleList(){
        return this.pharmaModuleList;
    }

    //setManufacturer
    static Manufacturer;
    static setManufacturer(value:any){
        this.Manufacturer = null;
        this.Manufacturer = value;
    }
    static getManufacturer(){
        return this.Manufacturer;
    }

    //setSupplier
    static Supplier;
    static setSupplier(value:any){
        this.Supplier = null;
        this.Supplier = value;
    }
    static getSupplier(){
        return this.Supplier;
    }
    //setProductMst
    static ProductMst;
    static setProductMst(value:any){
        this.ProductMst = null;
        this.ProductMst = value;
    }
    static getProductMst(){
        return this.ProductMst;
    }
}