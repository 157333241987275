<div *ngIf="reportexp_flag == 'min'">
    
   <div *ngIf="blood_report_list.length != 0 || urine_report_list.length != 0 || feaces_report_list.length != 0
               || xray_report_list.length != 0 || scan_report_list.length != 0 || ultrasound_report_list.length != 0
               || documentList.length != 0">
     <!-- <p class="tervys_heading"
       *ngIf="blood_report_list.length != 0 || urine_report_list.length != 0 || feaces_report_list.length != 0 || documentList.length != 0">
       <b>Pathology</b>
     </p> -->
     <div [hidden]="blood_report_list.length == 0 && !documentBlood" style="margin-bottom: 2px;">
       <div class="">
         <mat-accordion class="mataccordion">
           <mat-expansion-panel class="expandpanel">
             <mat-expansion-panel-header class="exppanel width">
               <mat-panel-title class="title ">
                 Laboratory
               </mat-panel-title>
             </mat-expansion-panel-header>
             <hr class="hr">
             <div class="row" style="margin-top: 11px;">
               <div *ngFor="let blood_report of blood_report_list" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                 <mat-card
                   (click)="report_view(blood_report.Diag_Test_Id,'pathology',blood_report.diag_appointment_id,blood_report.test_type,blood_report.diag_test_name)"
                   class="CardList_border">
                   <mat-card-header></mat-card-header>
                   <mat-card-content>
                     <mat-label class="matlabel">{{blood_report.diag_test_name+" "+"test"}}</mat-label><br>
                   </mat-card-content>
                 </mat-card><br>
               </div>
               <div *ngFor="let document of documentList" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                 [hidden]="document.type != 'Blood report'">
                 <mat-card (click)="document_view(document)" class="CardList_border">
                   <mat-card-header></mat-card-header>
                   <mat-card-content>
                     <mat-label class="matlabel">{{"Document : "+document.document_name+" "+document.document_date}}
                     </mat-label>
                     <br>
                   </mat-card-content>
                 </mat-card><br>
               </div>
             </div>
           </mat-expansion-panel>
         </mat-accordion>
       </div>
     </div>
 
     <div [hidden]="scan_report_list.length == 0 && !documentScan" style="margin-bottom: 2px;">
       <div class="">
         <mat-accordion class="mataccordion">
           <mat-expansion-panel class="expandpanel">
             <mat-expansion-panel-header class="exppanel width">
               <mat-panel-title class="title ">
                 Radiology
               </mat-panel-title>
             </mat-expansion-panel-header>
             <hr class="hr">
             <div class="row" style="margin-top: 11px;">
               <div *ngFor="let scan_report of scan_report_list" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                 <mat-card
                   (click)="report_view(scan_report.Diag_Test_Id,'radiology',scan_report.diag_appointment_id,scan_report.test_type,scan_report.diag_test_name)"
                   class="CardList_border">
                   <mat-card-header></mat-card-header>
                   <mat-card-content>
                     <mat-label>{{scan_report.diag_test_name+" "+"test"}}</mat-label><br>
                   </mat-card-content>
                 </mat-card><br>
               </div>
             </div>
           </mat-expansion-panel>
         </mat-accordion>
       </div>
     </div>
 
   </div>
   <div
     *ngIf="blood_report_list.length == 0 && urine_report_list.length == 0 && feaces_report_list.length == 0 && 
   xray_report_list.length == 0 && scan_report_list.length == 0 && ultrasound_report_list.length == 0 && documentList.length == 0">
     <div class="nodata">No Test Reports Found</div>
   </div>
 </div>
 
 <div *ngIf="reportexp_flag == 'max'">
   <a *ngIf="report_detail_flag" (click)="back_Nav()"><img src="../../../assets/ui_icons/pres_list_view.svg"
       style="position: absolute; top: 7px; right: 61px; width: 20px;" /></a>
       <a  *ngIf="report_detail_flag && !documentView_flag" (click)="print()"><img src="../../../assets/img/printer.svg"
         style="position: absolute; top: 7px; right: 81px; width: 20px;" /></a>
 
   <div class="row">
     <div *ngIf="!report_detail_flag" class="Details">
       <div class="row">
         <div class="col-12" style="margin-top: 13px;text-align: right;margin-bottom: 10px !important;">
           <a (click)="uploadDocument()"> <span class="excr_confirm matlabel"><i class="fa fa-upload"
                 aria-hidden="true"></i>
               <strong style="padding-left: 5px;">Document Upload </strong>
             </span></a>
         </div>
       </div>
 
 
       <div
         *ngIf="blood_report_list.length != 0 || urine_report_list.length != 0 || feaces_report_list.length != 0
       || xray_report_list.length != 0 || scan_report_list.length != 0 || ultrasound_report_list.length != 0 || documentList.length != 0">
 
         <div [hidden]="blood_report_list.length == 0 && !documentBlood" style="margin-bottom: 5px;">
           <div class="">
             <mat-accordion class="mataccordion">
               <mat-expansion-panel class="expandpanel">
                 <mat-expansion-panel-header class="exppanel width">
                   <mat-panel-title class="title ">
                     Laboratory
                   </mat-panel-title>
                 </mat-expansion-panel-header>
                 <hr class="hr">
                 <div class="row" style="margin-top: 11px;">
                   <div *ngFor="let blood_report of blood_report_list"
                     class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                     <mat-card
                       (click)="report_view(blood_report.Diag_Test_Id,'pathology',blood_report.diag_appointment_id,blood_report.test_type,blood_report.diag_test_name)"
                       class="CardList_border">
                       <mat-card-header></mat-card-header>
                       <mat-card-content>
                         <mat-label class="matlabel">{{blood_report.diag_test_name}}</mat-label><br>
                       </mat-card-content>
                     </mat-card><br>
                   </div>
                   <div *ngFor="let document of documentList" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                     [hidden]="document.type != 'Blood report'">
                     <mat-card (click)="document_view(document)" class="CardList_border">
                       <mat-card-header></mat-card-header>
                       <mat-card-content>
                         <mat-label class="matlabel">{{"Document : "+document.document_name+" "+document.document_date}}
                         </mat-label>
                         <br>
                       </mat-card-content>
                     </mat-card><br>
                   </div>
                 </div>
               </mat-expansion-panel>
             </mat-accordion>
           </div>
         </div>
 
         <div [hidden]="scan_report_list.length == 0 && !documentScan " style="margin-bottom: 5px;">
           <div class="">
             <mat-accordion class="mataccordion">
               <mat-expansion-panel class="expandpanel">
                 <mat-expansion-panel-header class="exppanel width">
                   <mat-panel-title class="title ">
                     Radiology
                   </mat-panel-title>
                 </mat-expansion-panel-header>
                 <hr class="hr">
                 <div class="row" style="margin-top: 11px;">
                  
                   <div *ngFor="let scan_report of scan_report_list"
                     class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                     <mat-card
                       (click)="report_view(scan_report.Diag_Test_Id,'radiology',scan_report.diag_appointment_id,scan_report.test_type,scan_report.diag_test_name)"
                       class="CardList_border">
                       <mat-card-header></mat-card-header>
                       <mat-card-content>
                         <mat-label>{{scan_report.diag_test_name}}</mat-label><br>
                       </mat-card-content>
                     </mat-card><br>
                   </div>
                   <div *ngFor="let document of documentList" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                     [hidden]="document.type != 'Scan report'">
                     <mat-card (click)="document_view(document)" class="CardList_border">
                       <mat-card-header></mat-card-header>
                       <mat-card-content>
                         <mat-label class="matlabel">{{"Document : "+document.document_name+"
                           "+document.document_date}}</mat-label>
                         <br>
                       </mat-card-content>
                     </mat-card><br>
                   </div>
                   <div *ngFor="let document of documentList" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                   [hidden]="document.type != 'X-ray report'">
                   <mat-card (click)="document_view(document)" class="CardList_border">
                     <mat-card-header></mat-card-header>
                     <mat-card-content>
                       <mat-label class="matlabel">{{"Document : "+document.document_name+"
                         "+document.document_date}}</mat-label>
                       <br>
                     </mat-card-content>
                   </mat-card><br>
                 </div>
                 </div>
               </mat-expansion-panel>
             </mat-accordion>
           </div>
         </div>
         <div [hidden]="microlist.length == 0 && !documentmicro" style="margin-bottom: 5px;">
           <div class="">
             <mat-accordion class="mataccordion">
               <mat-expansion-panel class="expandpanel">
                 <mat-expansion-panel-header class="exppanel width">
                   <mat-panel-title class="title ">
                     Microbiology
                   </mat-panel-title>
                 </mat-expansion-panel-header>
                 <hr class="hr">
                 <div class="row" style="margin-top: 11px;">
                   <div *ngFor="let scan_report of microlist"
                     class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                     <mat-card
                       (click)="report_view(scan_report.Diag_Test_Id,'microbiology',scan_report.diag_appointment_id,scan_report.test_type,scan_report.diag_test_name)"
                       class="CardList_border">
                       <mat-card-header></mat-card-header>
                       <mat-card-content>
                         <mat-label>{{scan_report.diag_test_name}}</mat-label><br>
                       </mat-card-content>
                     </mat-card><br>
                   </div>
                   <div *ngFor="let document of documentList" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                     [hidden]="document.type != 'Micro report'">
                     <mat-card (click)="document_view(document)" class="CardList_border">
                       <mat-card-header></mat-card-header>
                       <mat-card-content>
                         <mat-label class="matlabel">{{"Document : "+document.document_name+"
                           "+document.document_date}}</mat-label>
                         <br>
                       </mat-card-content>
                     </mat-card><br>
                   </div>
                 </div>
               </mat-expansion-panel>
             </mat-accordion>
           </div>
         </div>
       </div>
       <div
         *ngIf="blood_report_list.length == 0 && urine_report_list.length == 0 && feaces_report_list.length == 0 && 
         xray_report_list.length == 0 && scan_report_list.length == 0 && ultrasound_report_list.length == 0 && documentList.length == 0">
         <div class="nodata">No Test Reports Found</div>
       </div>
 
     </div>
     <div *ngIf="report_detail_flag" class="Details">
       <div style="margin: auto;" *ngIf="imageflag_outsource_id">
         <mat-label class="matlabel" style="margin-top: 10px !important;">
            <mat-radio-group class="radiobtngrp" color="primary" labelPosition="before">
               <mat-radio-button value="header" (change)="printMod($event.value)" [checked]="true">
                  Print with banner
               </mat-radio-button>&nbsp;
               <mat-radio-button value="noHeader" (change)="printMod($event.value)">
                  Print without header
               </mat-radio-button>&nbsp;
            </mat-radio-group>
         </mat-label>
      </div>
       <div class="row" *ngIf="documentList.length == 0">
         
         <div class="col-12" style="margin-bottom: 5px">
           <div class="cover_div">
             <div class="header_lable">Details</div>
             <div class="content_cover">
 
               <div class="row">
                 <div class="col-3" *ngIf="sampleflag">
                   Sample date & time: {{sampledatecheck}} {{sampletimecheck}}
                 </div>
                 <div class="col-3" *ngIf="!sampleflag">
                   Visit date & time: {{visited_date}} {{visited_time}}
                 </div>
                 <div class="col-3" *ngIf="sampleflag">
                   Sample Id: {{sample_id}}
                 </div>
                 <div class="col-3" *ngIf="sampleflag">
                   Specimen: {{sample_type}}
                 </div>
                 <div class="col-3">
                   Report date & time: {{reporteddate}} {{reportedtime}}
                 </div>
                 <!-- <div class="col-4"  *ngIf="inpatflag">
                       Admitted date & time: {{admission_date}} {{admission_time}}
                   </div> -->
               </div>
             </div>
           </div>
         </div>
       </div>
       <div *ngIf="imageflag_outsource_id">
        
         <div [hidden]='tableReportTypeFlag && !documentView_flag'>
           <div *ngFor="let test of diagTestReadingArray">
             <div *ngIf="test.sub_test_list.length != 0">
               <p class="tervys_heading clinical col-12"><b style="text-transform: capitalize;">{{test.category}}</b></p>
               <div class='row'>
                 <div *ngIf="!template_flag" class='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                   <div class="dig_table_overflow">
                     <div class="table-responsive">
                       <table id='card_tbl' class='table table-hover table-dynamic'>
                         <thead>
                           <tr>
                             <th style="width:400px;text-align: left;">Description</th>
                             <th style="width:200px;text-align: left;">Reading</th>
                             <th style="width:150px;text-align: left;">Unit</th>
                             <th style="text-align: left;">Referred value</th>
                           </tr>
                         </thead>
                         <tbody>
                           <tr>
                             <td colspan="4" style="text-align: left;font-weight: 400;">
                               <b>{{test.sub_test_name}}</b>
                             </td>
                           </tr>
 
                           <tr *ngFor='let subtest of test.sub_test_list'>
                             <td style='text-align: left;margin-bottom: 15px !important'
                               *ngIf="subtest.tests == undefined">
                               <div>
                                 <strong>
                                   <p>{{subtest.sub_test_name}}</p>
                                 </strong>
                                 <span *ngIf="subtest.test_method != undefined" class="testMethod">
                                   <div *ngIf="subtest.test_method != undefined">Test method:
                                      {{subtest.test_method}}</div>
                                </span>
                               </div>
 
                             </td>
                             <td colspan="4" *ngIf="subtest.tests != undefined">
                               <div>
                                 <div class="d-flex flex-row" style="padding: 5px;">
                                   <strong>
                                     <p>{{subtest.sub_test_name}}</p>
                                   </strong>
                                   <!-- <p style="margin-top: 5px; margin-bottom: 0px;">{{subtest.id}}</p> -->
                                   <!-- <h2 style=" font-size: 17px;
                                             margin-left: 18px;">
                                                {{subtest.sub_test_name}}</h2> -->
                                 </div>
                                 <table style="width: 100%;">
                                   <tbody>
                                     <tr *ngFor="let sub of subtest.tests let i = index"
                                       style="border-top: 1px solid #6e7070;">
                                       <!-- <td>{{i + 1}}</td>  style=" style="width: 190px; padding-left: 14px;"width: 373px; padding: 9px;padding-left: 35px;"-->
                                       <td class="subtestname">
                                         {{sub.sub_test_name}}</td>
                                       <td class="subtestnamereading">
                                         {{sub.reading}}</td>
                                       <td class="unitvalue">{{sub.unit}}</td>
                                       <td>{{sub.range}}</td>
                                     </tr>
                                   </tbody>
                                 </table>
                               </div>
                             </td>
                             <td *ngIf="subtest.test_range == '-' && subtest.tests == undefined"
                               style='text-align: left; color: rgb(0, 0, 0); font-weight: 500;'>
                               {{subtest.reading}}</td>
                             <td *ngIf="subtest.is_Reading_normal && subtest.tests == undefined"
                               style='text-align: left; color: green; font-weight: 500;'>
                               {{subtest.reading}}</td>
                             <td *ngIf="subtest.is_Reading_Abnormal && subtest.tests == undefined"
                               style='text-align: left; color: orange; font-weight: 500;'>
                               {{subtest.reading}}*</td>
                             <td *ngIf="subtest.is_Reading_Critical && subtest.tests == undefined"
                               style='text-align: left; color: red; font-weight: 500;'>
                               {{subtest.reading}}**</td>
                             <td style='text-align: left;' *ngIf="subtest.tests == undefined">{{subtest.unit}}</td>
                             <td style=" text-align: left;" *ngIf="subtest.tests == undefined">
                               <div [innerHtml]='subtest.test_range'></div>
                             </td>
                           </tr>
                         </tbody>
                       </table>
                     </div>
                   </div>
                 </div>
                 <div *ngIf="template_flag" [innerHtml]="template">
                 </div>
               </div>
             </div>
           </div>
         </div>
 
         <div [hidden]='paraReportTypeFlag && !documentView_flag'>
           <div class='row' style="margin-left: 20px;margin-right: 20px;">
             <!-- <div class='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12' style='margin-top:13px;'>
                 <mat-label class='matlabel'><strong>Report type :</strong> {{reportType}}
                 </mat-label>
              </div> -->
             <div class='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12' style='margin-top:5px;'>
               <mat-label class='matlabel'><strong>Test name :</strong> {{testName}}</mat-label>
             </div>
             <div *ngIf="value_flag" class='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12' style='margin-top:5px;'>
               <p class='tervys_heading clinical'>Sequences</p>
             </div>
             <div *ngIf="value_flag" class='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
               <quill-view-html id='viewhtml' [content]='sequence'></quill-view-html>
             </div>
             <div *ngIf="value_flag" class='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
               <p class='tervys_heading clinical'>Impression</p>
             </div>
             <div *ngIf="value_flag" class='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
               <quill-view-html id='viewhtml' [content]='Impression'></quill-view-html>
             </div>
             <div *ngIf="template != undefined" [innerHtml]="template">
             </div>
             <div [hidden]='dicomDotor' class='col-12'>
               <mat-card *ngIf="!cultureFlag">
                 <mat-card-content>
                   <img alt='report image' src='{{reportIMG}}' (click)='clickDocument()' class='report_img' />
                 </mat-card-content>
               </mat-card>
             </div>
             <!-- micro sudhan -->
             <div [hidden]="microbiology_flag" style="margin-top: 15px; margin-left: -24px;">
               <div class="container-fluid" style="margin-bottom: 8px;">
                 <div class="row">
                   <div class="row d-flex flex-row justify-content-start mb-3">
                     <div class="col-2">
                       <p *ngIf="org_iso1_cc_flag" class="mb-1"><strong>Colony count : </strong></p>
                       <p *ngIf="org_iso1_cr_flag" class="mb-1"><strong>Culture report : </strong></p>
                       <!-- <p *ngIf="org_iso1_growth_type_flag" class="mb-1"><strong>Gram stain : </strong></p> -->
                     </div>
                     <div class="col-9" style="">
                       <p *ngIf="org_iso1_cc_flag" class="mb-1"> {{org_iso_1_cc_desc}}</p>
                       <p *ngIf="org_iso1_cr_flag" class="mb-1"> {{org_iso1_cr_desc}}</p>
                       <!-- <p *ngIf="org_iso1_growth_type_flag" class="mb-1"> {{org_iso1_growth_type_desc}}</p> -->
                     </div>
                   </div>
                   <div class="col-4 mb-3" style="margin-bottom: 8px;" *ngIf="org_iso1_flag">
                     <div>
                       <div class="row d-flex flex-row justify-content-start align-items-center">
                         <div class="col-5">
                           <h2 style="font-weight: 500; margin-bottom: 2px; font-size: 14px;">Organism
                             Isolated 1</h2>
                         </div>
                         <div class="col-7">
                           <h4 style="margin-bottom: 6px;"> &nbsp; {{org_iso_1_desc}}</h4>
                         </div>
                       </div>
                     </div>
                     <div class="col-12 mb-3" style="margin-bottom: 8px;  margin-top: 5px;">
                       <table cellpadding='0' cellspacing='0' style="width:100%; border:1px solid #747575;">
                         <thead>
                           <tr>
                             <th style="text-align: left; ">
                               Antibiotics</th>
                             <th style="text-align: left; ">
                               Sensitivity</th>
                             <th *ngIf="mic_iso1_flag" style="text-align: left;">
                               MIC</th>
                           </tr>
                         </thead>
                         <tbody>
                           <tr *ngFor="let medicine of org_iso1_antibiotics">
                             <td style='padding-left: 20px;text-align: left; '>
                               {{medicine.drug_name}}</td>
                             <td style='padding-left: 20px;text-align: left; '>
                               {{medicine.sensitivity_desc}}</td>
                             <td *ngIf="mic_iso1_flag" style='padding-left: 20px;text-align: left; '>
                               {{medicine.mic}}</td>
                           </tr>
                         </tbody>
                       </table>
                     </div>
                   </div>
                   <div class="col-4 mb-3" style="margin-bottom: 8px;" *ngIf="org_iso2_flag">
                     <div>
                       <div class="row d-flex flex-row justify-content-start align-items-center">
                         <div class="col-5">
                           <h2 style="font-weight: 500; margin-bottom: 2px; font-size: 14px;">Organism
                             Isolated 2</h2>
                         </div>
                         <div class="col-7">
                           <h4 style="margin-bottom: 6px;"> &nbsp; {{org_iso_2_desc}}</h4>
                         </div>
                       </div>
                     </div>
                     <div class="col-12 mb-3" style="margin-bottom: 8px;  margin-top: 5px;">
                       <table cellpadding='0' cellspacing='0' style="width:100%; border:1px solid #747575;">
                         <thead>
                           <tr>
                             <th style="text-align: left; ">
                               Antibiotics</th>
                             <th style="text-align: left; ">
                               Sensitivity</th>
                             <th *ngIf="mic_iso2_flag" style="text-align: left;">
                               MIC</th>
                           </tr>
                         </thead>
                         <tbody>
                           <tr *ngFor="let medicine of org_iso2_antibiotics">
                             <td style='padding-left: 20px;text-align: left; '>
                               {{medicine.drug_name}}</td>
                             <td style='padding-left: 20px;text-align: left; '>
                               {{medicine.sensitivity_desc}}</td>
                             <td *ngIf="mic_iso2_flag" style='padding-left: 20px;text-align: left; '>
                               {{medicine.mic}}</td>
                           </tr>
                         </tbody>
                       </table>
                     </div>
                   </div>
                   <div class="col-4 mb-3" style="margin-bottom: 8px;" *ngIf="org_iso3_flag">
                     <div>
                       <div class="row d-flex flex-row justify-content-start align-items-center">
                         <div class="col-5">
                           <h2 style="font-weight: 500; margin-bottom: 2px; font-size: 14px;">Organism
                             Isolated 3</h2>
                         </div>
                         <div class="col-7">
                           <h4 style="margin-bottom: 6px;"> &nbsp; {{org_iso_3_desc}}</h4>
                         </div>
                       </div>
                     </div>
                     <div class="col-12 mb-3" style="margin-bottom: 8px;  margin-top: 5px;">
                       <table cellpadding='0' cellspacing='0' style="width:100%; border:1px solid #747575;">
                         <thead>
                           <tr>
                             <th style="text-align: left; ">
                               Antibiotics</th>
                             <th style="text-align: left; ">
                               Sensitivity</th>
                             <th *ngIf="mic_iso3_flag" style="text-align: left;">
                               MIC</th>
                           </tr>
                         </thead>
                         <tbody>
                           <tr *ngFor="let medicine of org_iso3_antibiotics">
                             <td style='padding-left: 20px;text-align: left; '>
                               {{medicine.drug_name}}</td>
                             <td style='padding-left: 20px;text-align: left; '>
                               {{medicine.sensitivity_desc}}</td>
                             <td *ngIf="mic_iso3_flag" style='padding-left: 20px;text-align: left; '>
                               {{medicine.mic}}</td>
                           </tr>
                         </tbody>
                       </table>
                     </div>
                   </div>
 
                   <div class="col-12 mb-2" *ngIf="technical_noteFlag">
                     <h2 style="font-size: 16px; font-weight: 500; margin-bottom: 5px;">Technical Notes</h2>
                     <p>{{technical_note}}</p>
                   </div>
                   <div class="col-12 mb-2" *ngIf="commentsFlag">
                     <h2 style="font-size: 16px; font-weight: 500; margin-bottom: 5px;">Comments</h2>
                     <p>{{comment}}</p>
                   </div>
                 </div>
               </div>
             </div>
           </div>
         </div>
         <hr style="border: 1px solid #06438a;">
         <div class="row" style='padding: 0px 22px; margin-top: 20px;'>
           <table>
              <tr>
                 <td style="width: 90%;text-align: center;">
                   
                    <div class="row" [hidden]="createdBy == undefined">
                       <div [hidden]="createdBy == undefined" class="col-4">
                          <mat-label class="matlabel"><strong>Lab
                                technician
                                :</strong> {{createdBy}}
                          </mat-label>
                       </div>
                       <div class="col-4">
                          <mat-label class="matlabel"><strong>{{consultant}}:</strong> {{approver_name}}
                          </mat-label>
                       </div>
                       <div class="col-4">
                          <mat-label class="matlabel"><strong>Quality Head
                                :</strong> {{qualityHead_name}}
                          </mat-label>
                       </div>
                    </div>
                 </td>
              </tr>
           </table>
        </div>
       </div>
       <div class='row' *ngIf="!imageflag_outsource_id">
         <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-10" style="margin-top: 10px;">
           <iframe id="myIframe" class="controls" [src]="uploadidproof" width="100%" height="700px" frameborder="0"
             webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
         </div>
       </div>
       <div *ngIf="documentView_flag">
         <div class="row">
           <div class="col-12">
             <mat-card id="cardcontent">
               <mat-card-header id="cardheader">Patient Details</mat-card-header>
               <mat-card-content style="padding: 10px 0;">
                 <div class="container">
                   <div class="row">
                     <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 p-0">
                       <div class="row">
                         <div class="col-5">
                           <mat-label class="matlabel">MR.No :</mat-label>
                         </div>
                         <div class="col-7">
                           <mat-label class="matlabel">{{clent_id}}</mat-label>
                         </div>
                       </div>
                       <div class="row">
                         <div class="col-5">
                           <mat-label class="matlabel">Name :</mat-label>
                         </div>
                         <div class="col-7">
                           <mat-label class="matlabel">{{client_another_name}}</mat-label>
                         </div>
                       </div>
                     </div>
                     <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 p-0">
                       <div class="row">
                         <div class="col-5">
                           <mat-label class="matlabel">Age/Gender :</mat-label>
                         </div>
                         <div class="col-7">
                           <mat-label class="matlabel">{{client_age}}/{{client_gender}}</mat-label>
                         </div>
                       </div>
                       <!-- <div class="row">
                         <div class="col-5">
                           <mat-label class="matlabel"> :</mat-label>
                         </div>
                         <div class="col-7">
                           <mat-label class="matlabel"></mat-label>
                         </div>
                       </div> -->
                     </div>
                     <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 p-0">
                       <div class="row">
                         <div class="col-5">
                           <mat-label class="matlabel">Date :</mat-label>
                         </div>
                         <div class="col-7">
                           <mat-label class="matlabel">{{date_txt}}</mat-label>
                         </div>
                       </div>
                     </div>
                   </div>
 
                 </div>
               </mat-card-content>
             </mat-card>
           </div>
           <div class="col-12 embed-responsive embed-responsive-16by9" style="margin-top: 10px;">
             <iframe class="controls embed-responsive-item" [src]="document" width="100%" height="500px" frameborder="0"
               webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
           </div>
 
         </div>
       </div>
      
 
     </div>
 
   </div>
   
   <div [hidden]='true' id="printID">
     <div *ngIf="imageflag_outsource_id">
        <div #printlogowithname id="printlogowithname"
           style="background-image: url({{bgImage}}); background-position: center;">
           <table style="border:'0';width:100%">
              <thead>
                 <tr>
                    <th style="width:100%;height: 100px;"></th>
                 </tr>
              </thead>
              <tfoot>
                 <tr>
                    <td width="100%">
                       <table style="border:'0';width:100%">
                          <tr>
                             <td style="width:100%;height: 100px;">&nbsp;</td>
                          </tr>
                       </table>
                    </td>
                 </tr>
              </tfoot>
              <tbody>
                 <tr>
                    <td width="100%">
                       <table class="report-container" style="width: 100%;margin-left:50px;margin-top:70px;">
                          <thead class="report-header">
                             <tr>
                                <td style="display: flex;justify-content: center;">
                                   <div>
                                      <img alt="image" src={{hptlLogo}} width="100px" height="100px"
                                         style="vertical-align: middle;">
                                   </div>
                                   <div style="position: relative;top: 20px;left: 10px;">
                                      <p><b>{{hospitalName}}</b>,</p>
                                      <span>{{location}},</span> <span>{{city}},</span>
                                      <span>{{state}},</span><span>{{country}}.</span><br>
                                   </div>
                                </td>
                             </tr>
                          </thead>
                          <tfoot class="report-footer">
                             <tr>
                                <td style="height:100px;"></td>
                             </tr>
                          </tfoot>
                          <tbody class="report-content">
                           <tr>
                              <td class="report-content-cell">
                                 <div class="main">
                                    <div [hidden]='tableReportTypeFlag'>
                                       <div class='row'>
                                          <div class='col-print-12'
                                             style="text-align: center;background: #d0eaf7;width:100%;">
                                             <mat-label class='matlabel'
                                                style="font-size: 17px;"><b>Clinical
                                                   Laboratory Reports</b></mat-label>
                                          </div><br><br>
                                          <div class='col-print-12'>
                                             <table cellpadding='0' cellspacing='0'
                                                style=" width:95%; border-collapse: collapse;    margin-bottom: 20px; margin-right: 5%;">
                                                <tr>
                                                   <th style="width:200px;text-align: left;">
                                                      Test Name</th>
                                                   <th style="width:150px;text-align: center;">
                                                      Reading</th>
                                                   <th style="width:100px;text-align: center;">
                                                      Unit</th>
                                                   <th>Reference value</th>
                                                </tr>
                                                <tbody *ngFor="let test of printArray">
                                                   <tr>
                                                      <td style='padding: 5px 0 5px 20px;text-align: left;'
                                                         colspan="4">
                                                         <!-- <b>{{test.test_name}}</b> -->
                                                         <b>{{test.category}} -
                                                            {{test.test_name}}</b>
                                                      </td>
                                                   </tr>
                                                   <tr *ngFor='let subtest of test.sub_test_list'>
                                                      <td
                                                         style='padding-left: 20px;text-align: left;margin-bottom: 15px !important'>
                                                         <div style="margin-bottom: 15px !important;">
                                                            <p>{{subtest.sub_test_name}}
                                                            </p>
                                                            <span *ngIf="subtest.testMethod != undefined"
                                                               style="display: flex;">(
                                                               <div [innerHtml]="subtest.testMethod">
                                                               </div>
                                                               )
                                                            </span>
                                                         </div>
                                                      </td>
                                                      <td style='text-align: center;'>
                                                         {{subtest.reading}}
                                                      </td>
                                                      <td style='text-align: center;'>
                                                         {{subtest.unit}}
                                                      </td>
                                                      <td
                                                         style=" text-align: left;padding-left: 20px !important;">
                                                         <div class="test_range"
                                                            style="margin-bottom: 15px !important;"
                                                            [innerHtml]='subtest.test_range'>
                                                         </div>
                                                      </td>
                                                   </tr>
                                                </tbody>
                                             </table>
                                          </div>
                                       </div>
                                    </div>

                                    <div [hidden]='paraReportTypeFlag'>
                                       <div class='row' style="margin-left: 20px;margin-right: 20px;">
                                          <div class='col-print-12' style='margin-top:13px;'>
                                             <mat-label class='matlabel'><strong>Report type
                                                   :</strong>
                                                {{reportType}}
                                             </mat-label>
                                          </div>
                                          <div class='col-print-12' style='margin-top:5px;'>
                                             <mat-label class='matlabel'><strong>Test name
                                                   :</strong>
                                                {{testName}}
                                             </mat-label>
                                          </div>
                                          <div *ngIf="!cultureFlag" class='col-print-12'
                                             style='margin-top:5px;'>
                                             <p class='tervys_heading clinical'>
                                                <b>Sequences</b>
                                             </p>
                                          </div>
                                          <div *ngIf="!cultureFlag" class='col-print-12'>
                                             <quill-view-html id='viewhtml' [content]='sequence'>
                                             </quill-view-html>
                                          </div>
                                          <div *ngIf="!cultureFlag" class='col-print-12'>
                                             <p class='tervys_heading clinical'>
                                                <b>Impression</b>
                                             </p>
                                          </div>
                                          <div *ngIf="!cultureFlag" class='col-print-12'
                                             style='margin-bottom: 12px;'>
                                             <quill-view-html id='viewhtml' [content]='Impression'>
                                             </quill-view-html>
                                          </div>
                                          <div *ngIf="cultureFlag" class='col-print-12'>
                                             <p class='tervys_heading clinical'>Report
                                                details</p>
                                          </div>
                                          <div *ngIf="cultureFlag" class='col-print-12'
                                             style='margin-bottom: 12px;'>
                                             <quill-view-html id='viewhtml' [content]='culture'>
                                             </quill-view-html>
                                          </div>
                                          <div [hidden]='dicomDotor' class='col-print-6'>
                                             <div *ngIf="!cultureFlag">
                                                <div class='tervys_heading'>Attachment</div>
                                                <div>
                                                   <img alt='report image' src='{{reportIMG}}'
                                                      (click)='clickDocument()' class='report_img' />
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <hr [hidden]='sigSectionFlag' style='border: 1px solid #000;'>
                                    <div class="row" style='padding: 0px 22px;'>
                                       <table>
                                          <tr>
                                             <td style="width: 90%;text-align: right;">
                                                <div class="row" [hidden]="createdBy == undefined">
                                                   <div [hidden]="createdBy == undefined" class="col-12">
                                                      <mat-label class="matlabel"><strong>Lab
                                                            technician
                                                            :</strong> {{createdBy}}
                                                      </mat-label>
                                                   </div>
                                                   <div class="col-12" style="margin-top: 10px;">
                                                      <mat-label class="matlabel"><strong>Signature
                                                            :</strong>
                                                      </mat-label>
                                                   </div>
                                                </div>
                                             </td>
                                          </tr>
                                       </table>
                                    </div>
                                 </div>
                              </td>
                           </tr>
                        </tbody>
                       </table>
                    </td>
                 </tr>
              </tbody>
           </table>
        </div>
        <div #printbanner id="printbanner"
           style="background-image: url({{bgImage}}); background-position: center;">
           <table style="border:'0';width:100%;">
              <thead>
                 <tr>
                    <th style="width:100%;">
                       <div class="main"
                          style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
                          <div style="width: 90%;">
                             <img alt="image" src={{hospitalLogo}} width="100%" height="170px">
 
                             <table
                                style="width: 100%; font-size: 12px; border: 1px solid black; margin-top: 10px; margin-bottom: 10px; border-radius: 10px;">
                                <tbody>
                                   <tr>
                                      <td style="padding-left: 5px;"><strong>Patient Name</strong></td>
                                      <td>: {{salutation}} {{clientAnotherName}}</td>
                                      <td style="padding-left: 5px;"><strong>Mr.No</strong></td>
                                      <td>: {{patientID}}</td>
                                   </tr>
                                   <tr>
                                      <td style="padding-left: 5px;"><strong>Visit ID</strong></td>
                                      <td> : {{visit_id}}</td>
                                      <td style="padding-left: 5px;"><strong>Age / Gender</strong></td>
                                      <td>: {{clientAge}} {{clientGender}}</td>
                                   </tr>
                                   <tr>
                                      <td style="padding-left: 5px;" *ngIf="diag_report_type != 'radiology'">
                                         <strong>Sample Date</strong>
                                      </td>
                                      <td *ngIf="diag_report_type != 'radiology'"> :  {{sampledatecheck}} {{sampletimecheck}}
                                      </td>
                                      <td style="padding-left: 5px;"><strong>Doctor</strong></td>
                                      <td>: {{doctorname}}</td>
                                      <td style="padding-left: 5px;" *ngIf="diag_report_type == 'radiology'"><strong>Visited Date & Time</strong></td>
                                      <td *ngIf="diag_report_type == 'radiology'"> : {{visited_date}} {{visited_time}}</td> 
                                   </tr>
                                   <tr *ngIf="diag_report_type != 'radiology'">
                                      <td style="padding-left: 5px;"><strong>Specimen</strong></td>
                                      <td>: {{sample_type}}</td>
                                      <td style="padding-left: 5px;"><strong>Sample ID</strong></td>
                                      <td>: {{sample_id}}</td>
                                   </tr>
                                   <tr>
                                      <td style="padding-left: 5px;"><strong>Report Date & Time</strong></td>
                                      <td> : {{reporteddate}} {{reportedtime}}</td>
                                      <td>
                                        <div>
                                           <ngx-barcode class="barcode" [bc-value]="patientID"
                                              [bc-display-value]="displayValue" [bc-line-color]="lineColor"
                                              [element-type]="elementType" [format]="format"
                                              [bc-width]="width" [bc-height]="barcodeheight"
                                              [bc-font-options]="fontOptions" [bc-font]="font"
                                              [bc-text-align]="textAlign" [bc-text-position]="textPosition"
                                              [bc-text-margin]="textMargin" [bc-font-size]="fontSize"
                                              [bc-background]="background" [bc-margin]="margin"
                                              [bc-margin-top]="marginTop" [bc-margin-bottom]="marginBottom"
                                              [bc-margin-left]="marginLeft"
                                              [bc-margin-right]="marginRight"></ngx-barcode>
                                        </div>
                                     </td>
                                      <!-- <td style="padding-left: 5px;" *ngIf="diag_report_type == 'radiology'">
                                         <strong>Visited Date & Time</strong></td>
                                      <td *ngIf="diag_report_type == 'radiology'"> : {{visited_date}}
                                         {{visited_time}}</td> -->
                                   </tr>
                                   <!-- <tr>
                                          <td>
                                            <div id="barcode" style="margin-left: -10px;">
                                               <ngx-barcode class="barcode" [bc-value]="patientID"
                                                  [bc-display-value]="displayValue"
                                                  [bc-line-color]="lineColor"
                                                  [element-type]="elementType" [format]="format"
                                                  [bc-width]="width" [bc-height]="barcodeheight"
                                                  [bc-font-options]="fontOptions" [bc-font]="font"
                                                  [bc-text-align]="textAlign"
                                                  [bc-text-position]="textPosition"
                                                  [bc-text-margin]="textMargin"
                                                  [bc-font-size]="fontSize"
                                                  [bc-background]="background"
                                                  [bc-margin]="margin" [bc-margin-top]="marginTop"
                                                  [bc-margin-bottom]="marginBottom"
                                                  [bc-margin-left]="marginLeft"
                                                  [bc-margin-right]="marginRight"></ngx-barcode>
                                            </div>
                                         </td>                                               
                                      </tr> -->
                                </tbody>
                             </table>
 
                          </div>
 
                       </div>
                    </th>
                 </tr>
              </thead>
 
              <tbody>
                 <tr>
                    <td width="100%">
                       <div
                          style="width: 100%;height: auto;display: contents; display: flex; justify-content: center;">
                          <table class=paging style="width:90%;">
                             <!-- <thead>
                                <tr>
                                   <td style="width: 100%;">
                                      <img alt="image" src={{hospitalLogo}} width="100%" height="170px">
                                   </td>
                                </tr>
                             </thead> -->
                             <tbody>
                                <tr>
                                   <td>
                                      <div class="main"
                                         style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
                                         <div [hidden]='pathology_flag' style="width: 100%;">
                                            <div class='row' style="justify-content: center;">
                                               <div class='col-print-12' style="background: #d0eaf7;
                                               width: 98%;
                                               height: 18px;
                                               display: flex;
                                               flex-direction: row;
                                               justify-content: center;">
                                                  <mat-label class='matlabel' style="font-size: 14px;">
                                                     <b>Laboratory</b>
                                                  </mat-label>
                                               </div><br><br>
                                               <div class='col-print-12' *ngIf="template_flag == false">
                                                  <table cellpadding='0' cellspacing='0'
                                                     style="width:100%;border: 1p solid #747575; font-size: 12px;">
                                                     <tr>
                                                        <th style="width:250px;text-align: left;">
                                                           Test Name
                                                        </th>
                                                        <th style="width:150px;text-align: center;">
                                                           Reading
                                                        </th>
                                                        <th style="width:100px;text-align: center;">
                                                           Unit
                                                        </th>
                                                        <th style="width:200px;text-align: left;">Reference
                                                           value</th>
                                                     </tr>
                                                     <tbody *ngFor="let test of printArray">
                                                        <tr *ngIf="test.sub_test_list != undefined">
                                                           <td style='padding: 5px 0 5px 20px;text-align: left;'
                                                              colspan="4">
                                                              <!-- <b>{{test.test_name}}</b> -->
                                                              <b>{{test.category}}</b> {{test.test_name}}
                                                           </td>
                                                        </tr>
                                                        <tr *ngFor='let subtest of test.sub_test_list'
                                                           style="border: 1px solid #6e7070; height: 18px;">
                                                           <td colspan="8"
                                                              *ngIf="subtest.tests != undefined && !subtest.egfr_flag">
                                                              <div>
                                                                 <div
                                                                    class="d-flex flex-row align-items-center"
                                                                    style="padding: 5px;">
                                                                    <!-- <p style="margin-top: 5px; margin-bottom: 0px;">{{subtest.id}}</p> -->
                                                                    <h2
                                                                       style=" font-size: 14px;
                                                                 margin-left: 18px; margin-bottom: 0px !important;">
                                                                       {{subtest.sub_test_name}}</h2>
                                                                 </div>
                                                                 <table style="width: 100%; font-size: 12px;">
                                                                    <tbody>
                                                                       <tr *ngFor="let sub of subtest.tests let i = index"
                                                                          style="border-top: 1px solid #6e7070;">
                                                                          <!-- <td>{{i + 1}}</td> -->
                                                                          <td
                                                                             style="width: 310px; height: 18px;           padding-left: 22px;">
                                                                             {{sub.sub_test_name}}</td>
                                                                          <td
                                                                             style="width: 135px; padding-left: 14px;">
                                                                             {{sub.reading}}</td>
                                                                          <td style="width: 80px;">
                                                                             {{sub.unit}}</td>
                                                                          <td>{{sub.range}}</td>
                                                                       </tr>
                                                                    </tbody>
                                                                 </table>
                                                              </div>
                                                           </td>
                                                           <td colspan="8"
                                                              *ngIf="subtest.tests == undefined && subtest.egfr_flag">
                                                              <div>
                                                                 <div
                                                                    class="d-flex flex-row align-items-center"
                                                                    style="padding: 5px;">
                                                                    <!-- <p style="margin-top: 5px; margin-bottom: 0px;">{{subtest.id}}</p> -->
                                                                    <h2
                                                                       style=" font-size: 14px; margin-left: 18px;margin-bottom: 0px !important;">
                                                                       {{subtest.sub_test_name}}</h2>
                                                                 </div>
                                                                 <table style="width: 100%; font-size: 12px;">
                                                                    <tbody>
                                                                       <tr
                                                                          style="border-top: 1px solid #6e7070;">
                                                                          <td
                                                                             style="width: 310px; height: 18px;           padding-left: 22px;">
                                                                             Creatinine</td>
                                                                          <td
                                                                             style="width: 130px; padding-left: 14px;">
                                                                             {{subtest.reading}}</td>
                                                                          <td style="width: 60px;">
                                                                             {{subtest.unit}}</td>
                                                                          <td>{{subtest.test_range}}</td>
                                                                       </tr>
                                                                       <tr
                                                                          style="border-top: 1px solid #6e7070;">
                                                                          <td
                                                                             style="width: 295px; height: 18px;           padding-left: 35px;">
                                                                             eGFR</td>
                                                                          <td
                                                                             style="width: 120px; padding-left: 14px;">
                                                                             {{subtest.egfr_value}}</td>
                                                                          <td style="width: 100px;">
                                                                             mL/min/<br>1.73m2</td>
                                                                          <td> - </td>
                                                                       </tr>
                                                                    </tbody>
                                                                 </table>
                                                              </div>
                                                           </td>
                                                           <td style='padding-left: 20px;text-align: left;'
                                                              *ngIf="subtest.tests == undefined && !subtest.egfr_flag">
                                                              <div>
                                                                 <p style="margin-bottom: 2px !important;">
                                                                    {{subtest.sub_test_name}}
                                                                 </p>
                                                                 <span
                                                                    *ngIf="subtest.test_method != undefined"
                                                                    style="display: flex;">
                                                                    <div
                                                                       *ngIf="subtest.test_method != undefined">
                                                                       Test method: {{subtest.test_method}}
                                                                    </div>
                                                                 </span>
                                                              </div>
                                                           </td>
                                                           <td style='text-align: center;'
                                                              *ngIf="subtest.tests == undefined && !subtest.egfr_flag">
                                                              {{subtest.print_reading}}
                                                           </td>
                                                           <td style='text-align: center;'
                                                              *ngIf="subtest.tests == undefined && !subtest.egfr_flag">
                                                              {{subtest.unit}}
                                                           </td>
                                                           <td style=" text-align: left;padding-left: 20px !important;"
                                                              *ngIf="subtest.tests == undefined && !subtest.egfr_flag">
                                                              <div class="test_range"
                                                                 style="margin-bottom: 0px !important;"
                                                                 [innerHtml]='subtest.test_range'>
                                                              </div>
                                                           </td>
                                                        </tr>
                                                     </tbody>
                                                  </table>
                                               </div>
                                               <div class='col-print-12' *ngIf="template != undefined"
                                                  [innerHtml]="template">
                                               </div>
                                            </div>
                                         </div>
 
                                         <div [hidden]='radiology_flag' style="width: 100%;">
                                            <div class='row' style="margin-left: 20px;margin-right: 20px;">
                                               <div class='col-print-12' style="background: #d0eaf7;
                                            width: 98%;
                                            height: 35px;
                                            display: flex;
                                            flex-direction: row;
                                            justify-content: center;
                                            align-items: center;">
                                                  <mat-label class='matlabel' style="font-size: 17px;">
                                                     <b>Radiology</b>
                                                  </mat-label>
                                               </div><br><br>
                                               <!-- <div class='col-print-12' style='margin-top:13px;'>
                                                  <mat-label class='matlabel'><strong>Report
                                                        type :</strong>
                                                     {{reportType}}
                                                  </mat-label>
                                               </div> -->
                                               <div class='col-print-12' style='margin-top:5px;'>
                                                  <mat-label class='matlabel'><strong>Test
                                                        name :</strong>
                                                     {{testName}}
                                                  </mat-label>
                                               </div>
                                               <div *ngIf="!cultureFlag && !template_flag"
                                                  class='col-print-12' style='margin-top:5px;'>
                                                  <p class='tervys_heading clinical'>
                                                     <b>Sequences</b>
                                                  </p>
                                               </div>
                                               <div *ngIf="!cultureFlag && !template_flag"
                                                  class='col-print-12'>
                                                  <quill-view-html id='viewhtml' [content]='sequence'>
                                                  </quill-view-html>
                                               </div>
                                               <div *ngIf="!cultureFlag && !template_flag"
                                                  class='col-print-12'>
                                                  <p class='tervys_heading clinical'>
                                                     <b>Impression</b>
                                                  </p>
                                               </div>
                                               <div *ngIf="!cultureFlag && !template_flag"
                                                  class='col-print-12' style='margin-bottom: 12px;'>
                                                  <quill-view-html id='viewhtml' [content]='Impression'>
                                                  </quill-view-html>
                                               </div>
                                               <div *ngIf="cultureFlag && !template_flag"
                                                  class='col-print-12'>
                                                  <p class='tervys_heading clinical'>Report
                                                     details</p>
                                               </div>
                                               <div *ngIf="cultureFlag && !template_flag"
                                                  class='col-print-12' style='margin-bottom: 12px;'>
                                                  <quill-view-html id='viewhtml' [content]='culture'>
                                                  </quill-view-html>
                                               </div>
                                               <div [hidden]='dicomDotor' class='col-print-6'>
                                                  <div *ngIf="!cultureFlag">
                                                     <div class='tervys_heading'>Attachment
                                                     </div>
                                                     <div>
                                                        <img alt='report image' src='{{reportIMG}}'
                                                           (click)='clickDocument()' class='report_img' />
                                                     </div>
                                                  </div>
                                               </div>
                                               <div *ngIf="template_flag"
                                                  [innerHtml]="template">
                                               </div>
 
                                            </div>
                                         </div>
 
                                         <div [hidden]="microbiology_flag" style="width: 100%;">
                                            <div [hidden]="!ot_Sterile_flag">
                                               <div class="container-fluid" style="margin-bottom: 8px;">
                                                  <div class="row">
                                                     <div class="col-12 mb-2" style="text-align: center;">
                                                        <h2 style="font-size: 18px;">Microbiology</h2>
                                                     </div>
                                                     <div class="col-12 mb-2" style="text-align: left;">
                                                        <h2 style="font-size: 18px;">{{testName}}</h2>
                                                     </div>
                                                     <div *ngIf="org_iso1_cr_flag" class="col-12 mb-2"
                                                        style="text-align: center;">
                                                        <h2 style="font-size: 22px; font-weight: 500;">
                                                           {{org_iso1_cr_desc}}</h2>
                                                     </div>
                                                     <div class="col-12 mb-3" style="margin-bottom: 8px;">
                                                        <div>
                                                           <p *ngIf="org_iso1_cc_flag"><strong>Colony count :
                                                              </strong>
                                                              {{org_iso_1_cc_desc}}
                                                           </p>
                                                           <!-- <p *ngIf="org_iso1_cr_flag"><strong>Culture Report:
                                                           </strong>
                                                           {{org_iso1_cr_desc}}</p> -->
                                                           <p *ngIf="org_iso1_growth_type_flag"><strong>Gram
                                                                 Stain: </strong>
                                                              {{org_iso1_growth_type_desc}}
                                                           </p>
                                                        </div>
                                                     </div>
                                                     <div class="col-6 mb-3" style="margin-bottom: 8px;"
                                                        *ngIf="org_iso1_flag">
                                                        <div class="mb-2">
                                                           <p style="margin-bottom: 2px !important;">
                                                              <strong>{{org_iso_heading}}</strong>
                                                           </p>
                                                           <p style="margin-bottom: 2px !important;">
                                                              {{org_iso_1_desc}}</p>
                                                        </div>
                                                        <table cellpadding='0' cellspacing='0'
                                                           style="width:100%; border:1px solid #747575;">
                                                           <thead>
                                                              <tr>
                                                                 <th
                                                                    style="padding: 3px;text-align: left; border: 1px solid #000">
                                                                    Antibiotics</th>
                                                                 <th
                                                                    style="padding: 3px;text-align: left; border: 1px solid #000">
                                                                    Sensitivity</th>
                                                                 <th *ngIf="mic_iso1_flag"
                                                                    style="padding: 3px;text-align: left; border: 1px solid #000">
                                                                    MIC</th>
                                                              </tr>
                                                           </thead>
                                                           <tbody>
                                                              <tr *ngFor="let medicine of org_iso1_antibiotics"
                                                                 style="border: 1px solid #000; height: 18px;">
                                                                 <td
                                                                    style='padding: 3px;text-align: left; border: 1px solid'>
                                                                    {{medicine.drug_name}}</td>
                                                                 <td
                                                                    style='padding: 3px;text-align: left; border: 1px solid'>
                                                                    {{medicine.sensitivity_desc}}</td>
                                                                 <td *ngIf="mic_iso1_flag"
                                                                    style='padding: 3px;text-align: left; border: 1px solid'>
                                                                    {{medicine.mic}}</td>
                                                              </tr>
                                                           </tbody>
                                                        </table>
                                                     </div>
                                                     <div class="col-6 mb-3" style="margin-bottom: 8px; "
                                                        *ngIf="org_iso2_flag">
                                                        <div class="mb-2">
                                                           <!-- <p><strong>Organism
                                                                 Isolated 2 </strong> &nbsp;
                                                              {{org_iso_2_desc}}
                                                           </p> -->
                                                           <p style="margin-bottom: 2px !important;">
                                                              <strong>Organism
                                                                 Isolated 2 </strong>
                                                           </p>
                                                           <p style="margin-bottom: 2px !important;">
                                                              {{org_iso_2_desc}}</p>
                                                        </div>
                                                        <table cellpadding='0' cellspacing='0'
                                                           style="width:100%; border:1px solid #747575;">
                                                           <thead>
                                                              <tr>
                                                                 <th
                                                                    style="padding: 3px;text-align: left; border: 1px solid #000">
                                                                    Antibiotics</th>
                                                                 <th
                                                                    style="padding: 3px;text-align: left; border: 1px solid #000">
                                                                    Sensitivity</th>
                                                                 <th *ngIf="mic_iso2_flag"
                                                                    style="padding: 3px;text-align: left; border: 1px solid #000">
                                                                    MIC</th>
                                                              </tr>
                                                           </thead>
                                                           <tbody>
                                                              <tr *ngFor="let medicine of org_iso2_antibiotics"
                                                                 style="border: 1px solid #000; height: 18px;">
                                                                 <td
                                                                    style='padding: 3px;text-align: left; border: 1px solid'>
                                                                    {{medicine.drug_name}}</td>
                                                                 <td
                                                                    style='padding: 3px;text-align: left; border: 1px solid'>
                                                                    {{medicine.sensitivity_desc}}</td>
                                                                 <td *ngIf="mic_iso2_flag"
                                                                    style='padding: 3px;text-align: left; border: 1px solid'>
                                                                    {{medicine.mic}}</td>
                                                              </tr>
                                                           </tbody>
                                                        </table>
                                                     </div>
                                                     <div class="col-6 mb-3" style="margin-bottom: 8px; "
                                                        *ngIf="org_iso3_flag">
                                                        <div class="mb-2">
                                                           <!-- <p><strong>Organism
                                                                 Isolated 3 </strong> &nbsp;
                                                              {{org_iso_1_code}} &nbsp;
                                                              {{org_iso_3_desc}}
                                                           </p> -->
 
                                                           <p style="margin-bottom: 2px !important;">
                                                              <strong>Organism
                                                                 Isolated 3 </strong>
                                                           </p>
                                                           <p style="margin-bottom: 2px !important;">
                                                              {{org_iso_3_desc}}</p>
 
                                                        </div>
                                                        <table cellpadding='0' cellspacing='0'
                                                           style="width:100%; border:1px solid #747575;">
                                                           <thead>
                                                              <tr>
                                                                 <th
                                                                    style="padding: 3px;text-align: left; border: 1px solid #000">
                                                                    Antibiotics</th>
                                                                 <th
                                                                    style="padding: 3px;text-align: left; border: 1px solid #000">
                                                                    Sensitivity</th>
                                                                 <th *ngIf="mic_iso3_flag"
                                                                    style="padding: 3px;text-align: left; border: 1px solid #000">
                                                                    MIC</th>
                                                              </tr>
                                                           </thead>
                                                           <tbody>
                                                              <tr *ngFor="let medicine of org_iso3_antibiotics"
                                                                 style="border: 1px solid #000; height: 18px;">
                                                                 <td
                                                                    style='padding: 3px;text-align: left; border: 1px solid'>
                                                                    {{medicine.drug_name}}</td>
                                                                 <td
                                                                    style='padding: 3px;text-align: left; border: 1px solid'>
                                                                    {{medicine.sensitivity_desc}}</td>
                                                                 <td *ngIf="mic_iso3_flag"
                                                                    style='padding: 3px;text-align: left; border: 1px solid'>
                                                                    {{medicine.mic}}</td>
                                                              </tr>
                                                           </tbody>
                                                        </table>
                                                     </div>
                                                     <div class="col-12 mb-2" *ngIf="technical_noteFlag">
                                                        <h2 style="font-size: 18px;">Technical Notes</h2>
                                                        <p>{{technical_note}}</p>
                                                     </div>
                                                     <div class="col-12 mb-2" *ngIf="commentsFlag">
                                                        <h2 style="font-size: 18px;">Comments</h2>
                                                        <p>{{comment}}</p>
                                                     </div>
                                                  </div>
                                               </div>
                                            </div>
                                         </div>
 
                                         <div class="empty-footer"
                                            style="text-align: center; margin-top: 10px;">
                                            <h4>------ End of Report ------</h4>
                                         </div>
                                         <div class="container-fluid" style="margin-top: 30px;">
                                            <div class="row d-flex flex-row justify-content-center">
                                               <table style="width: 100%;">
                                                  <tr>
                                                     <td style="width: 90%;text-align: center;">
                                                        <div class="row" [hidden]="createdBy == undefined">
                                                           <div
                                                              class="d-flex flex-row justify-content-center align-items-end">
                                                              <div [hidden]="createdBy == undefined"
                                                                 class="col-4">
                                                                 <div style="text-align: center;">
                                                                  <img *ngIf="lab_tech_sign" style="width: 55px;" src={{lab_tech_sign}}/>
                                                                    <p style="margin-bottom: 3px !important;">
                                                                       {{createdBy}}</p>
                                                                    <p><strong>Lab technician</strong></p>
                                                                 </div>
                                                                 <!-- <mat-label class="matlabel"><strong>Lab
                                                                 technician
                                                                 :</strong> {{createdBy}}
                                                                   </mat-label> -->
                                                              </div>
                                                              <div class="col-4">
                                                                 <div style="text-align: center;">
                                                                  <img *ngIf="approver_sign" style="width: 55px;" src={{approver_sign}}/>
                                                                    <p *ngIf="approver_name == undefined"
                                                                       style="margin-bottom: 3px !important;">
                                                                       &nbsp;</p>
                                                                    <p *ngIf="approver_name != undefined"
                                                                       style="margin-bottom: 3px !important;">
                                                                       {{approver_name}}</p>
                                                                    <p><strong>{{consultant}}</strong></p>
                                                                 </div>
                                                                 <!-- <mat-label class="matlabel"><strong>Approver
                                                                 name:
                                                                 </strong> {{approver_name}}
                                                                 </mat-label> -->
                                                              </div>
                                                              <div class="col-4">
                                                                 <div style="text-align: center;">
                                                                  <img *ngIf="quality_head_sign" style="width: 55px;" src={{quality_head_sign}}/>
                                                                    <p style="margin-bottom: 3px !important;">
                                                                       {{qualityHead_name}}</p>
                                                                    <p><strong>Quality Head</strong></p>
                                                                 </div>
                                                                 <!-- <mat-label class="matlabel"><strong>Quality Head
                                                                 :</strong> {{qualityHead_name}}
                                                                 </mat-label> -->
                                                              </div>
                                                           </div>
                                                        </div>
                                                     </td>
                                                  </tr>
                                               </table>
                                            </div>
                                         </div>
                                      </div>
                                   </td>
                                </tr>
                             </tbody>
                             <tfoot>
                                <tr>
                                   <td width="100%">
                                      <table style="border:'0';width:100%">
                                         <tr>
                                            <td style="width:100%;height: 100px;">&nbsp;</td>
                                         </tr>
                                      </table>
                             </tfoot>
                          </table>
                       </div>
                    </td>
                 </tr>
              </tbody>
 
           </table>
 
           <footer style="width:100%;">
              <table style="border:'0';width:100%; margin-bottom: 0;">
                 <tr>
                    <td style="width:100%;height: 100px;">&nbsp;
                       <!-- <img src={{hospitalFooter}} width="100%" height="200px"> -->
                    </td>
                 </tr>
              </table>
           </footer>
        </div>
        <div #printnoheader id="printnoheader"
           style="background-image: url({{bgImage}}); background-position: center;">
           <table style="border:'0';width:100%">
              <thead>
                 <tr>
                    <th style="width:100%;height: 100px;"></th>
                 </tr>
              </thead>
              <tfoot>
                 <tr>
                    <td width="100%">
                       <table style="border:'0';width:100%">
                          <tr>
                             <td style="width:100%;height: 100px;">&nbsp;</td>
                          </tr>
                       </table>
                    </td>
                 </tr>
              </tfoot>
              <tbody>
               <tr>
                  <td width="100%">
                     <div
                        style="width: 100%;height: auto;display: contents; display: flex; justify-content: center;">
                        <table class=paging style="width:90%;">
                           <!-- <thead>
                              <tr>
                                 <td style="width: 100%;">
                                    <img alt="image" src={{hospitalLogo}} width="100%" height="170px">
                                 </td>
                              </tr>
                           </thead> -->
                           <tbody>
                              <tr>
                                 <td>
                                    <div class="main"
                                       style="display: flex; flex-direction: column; justify-content: center; align-items: center; margin-top: 10px; ">
                                       <div [hidden]='pathology_flag' style="width: 100%;">
                                          <div class='row' style="justify-content: center;">

                                             <div class='col-print-12' *ngIf="template_flag == false">
                                                <table cellpadding='0' cellspacing='0'
                                                   style="width:100%;border: 1p solid #747575; font-size: 12px;">
                                                   <tr>
                                                      <th style="width:300px;text-align: left;">
                                                         Test Name
                                                      </th>
                                                      <th style="width:150px;text-align: left;">
                                                         Reading
                                                      </th>
                                                      <th style="width:100px;text-align: left;">
                                                         Unit
                                                      </th>
                                                      <th style="width:200px;text-align: left;">Reference
                                                         value</th>
                                                   </tr>
                                                   <tbody *ngFor="let test of printArray">
                                                      <tr *ngIf="test.sub_test_list != undefined">
                                                         <td colspan="4">
                                                            <!-- <b>{{test.test_name}}</b> -->
                                                            <!-- <b>{{test.category}}</b> -->
                                                            <div class='col-print-12' style="background: #d0eaf7;
                                                                                             height: 18px;
                                                                                             display: flex;
                                                                                             flex-direction: row;
                                                                                             justify-content: center;
                                                                                             margin-bottom: -34px;    margin-top: 5px;">
                                                               <mat-label class='matlabel'
                                                                  style="font-size: 14px;">
                                                                  <b>{{test.category}}</b>
                                                               </mat-label>
                                                            </div><br><br>
                                                         </td>
                                                      </tr>
                                                      <tr *ngIf="test.sub_test_list != undefined">
                                                         <td style='padding: 5px 0 5px 20px;text-align: left;'
                                                            colspan="4">
                                                            <b>{{test.test_name}}</b>
                                                            <!-- {{test.test_name}} -->
                                                         </td>
                                                      </tr>
                                                      <tr *ngFor='let subtest of test.sub_test_list'
                                                         style="border: 1px solid #6e7070; height: 18px;">
                                                         <td colspan="8"
                                                            *ngIf="subtest.tests != undefined && !subtest.egfr_flag">
                                                            <div>
                                                               <div
                                                                  class="d-flex flex-row align-items-center"
                                                                  style="padding: 5px;">
                                                                  <!-- <p style="margin-top: 5px; margin-bottom: 0px;">{{subtest.id}}</p> -->
                                                                  <h2
                                                                     style=" font-size: 14px;
                                                               margin-left: 18px; margin-bottom: 0px !important;">
                                                                     {{subtest.sub_test_name}}</h2>
                                                               </div>
                                                               <table style="width: 100%; font-size: 12px;">
                                                                  <tbody>
                                                                     <tr *ngFor="let sub of subtest.tests let i = index"
                                                                        style="border-top: 1px solid #6e7070;">
                                                                        <!-- <td>{{i + 1}}</td> -->
                                                                        <td
                                                                           style="width: 270px; height: 18px;           padding-left: 22px;">
                                                                           {{sub.sub_test_name}}</td>
                                                                        <td
                                                                           style="width: 153px; padding-left: 14px;">
                                                                           {{sub.reading}}</td>
                                                                        <td style="width: 118px;">
                                                                           {{sub.unit}}</td>
                                                                        <td>{{sub.range}}</td>
                                                                     </tr>
                                                                  </tbody>
                                                               </table>
                                                            </div>
                                                         </td>
                                                         <td colspan="8"
                                                            *ngIf="subtest.tests == undefined && subtest.egfr_flag">
                                                            <div>
                                                               <div
                                                                  class="d-flex flex-row align-items-center"
                                                                  style="padding: 5px;">
                                                                  <!-- <p style="margin-top: 5px; margin-bottom: 0px;">{{subtest.id}}</p> -->
                                                                  <h2
                                                                     style=" font-size: 14px; margin-left: 18px;margin-bottom: 0px !important;">
                                                                     {{subtest.sub_test_name}}</h2>
                                                               </div>
                                                               <table style="width: 100%; font-size: 12px;">
                                                                  <tbody>
                                                                     <tr
                                                                        style="border-top: 1px solid #6e7070;">
                                                                        <td
                                                                           style="width: 270px; height: 18px;           padding-left: 22px; text-align: left;" >
                                                                           Creatinine</td>
                                                                        <td
                                                                           style="width: 153px; padding-left: 14px;">
                                                                           {{subtest.reading}}</td>
                                                                        <td style="width: 118px;">
                                                                           {{subtest.unit}}</td>
                                                                        <td>{{subtest.test_range}}</td>
                                                                     </tr>
                                                                     <tr
                                                                        style="border-top: 1px solid #6e7070;">
                                                                        <td
                                                                           style="width: 270px; height: 18px;           padding-left: 22px; text-align: left;">
                                                                           eGFR</td>
                                                                        <td
                                                                           style="width: 153px; padding-left: 14px;">
                                                                           {{subtest.egfr_value}}</td>
                                                                        <td style="width: 118px;">
                                                                           mL/min/1.73m2</td>
                                                                        <td> - </td>
                                                                     </tr>
                                                                  </tbody>
                                                               </table>
                                                            </div>
                                                         </td>
                                                         <td style='padding-left: 20px;text-align: left;'
                                                            *ngIf="subtest.tests == undefined && !subtest.egfr_flag">
                                                            <div>
                                                               <p style="margin-bottom: 2px !important;">
                                                                  {{subtest.sub_test_name}}
                                                               </p>
                                                               <span
                                                                  *ngIf="subtest.test_method != undefined"
                                                                  style="display: flex;">
                                                                  <div
                                                                     *ngIf="subtest.test_method != undefined">
                                                                     Test method: {{subtest.test_method}}
                                                                  </div>
                                                               </span>
                                                            </div>
                                                         </td>
                                                         <td style='text-align: left;'
                                                            *ngIf="subtest.tests == undefined && !subtest.egfr_flag">
                                                            {{subtest.print_reading}}
                                                         </td>
                                                         <td style='text-align: left;'
                                                            *ngIf="subtest.tests == undefined && !subtest.egfr_flag">
                                                            {{subtest.unit}}
                                                         </td>
                                                         <td style=" text-align: left;padding-left: 20px !important;"
                                                            *ngIf="subtest.tests == undefined && !subtest.egfr_flag">
                                                            <div class="test_range"
                                                               style="margin-bottom: 0px !important;"
                                                               [innerHtml]='subtest.test_range'>
                                                            </div>
                                                         </td>
                                                      </tr>
                                                   </tbody>
                                                </table>
                                             </div>
                                             <div class='col-print-12' *ngIf="template != undefined"
                                                [innerHtml]="template">
                                             </div>
                                          </div>
                                       </div>

                                       <div [hidden]='radiology_flag' style="width: 100%;">
                                          <div class='row' style="margin-left: 20px;margin-right: 20px;">
                                             <div class='col-print-12' style="background: #d0eaf7;
                                          width: 98%;
                                          height: 35px;
                                          display: flex;
                                          flex-direction: row;
                                          justify-content: center;
                                          align-items: center;">
                                                <mat-label class='matlabel' style="font-size: 17px;">
                                                   <b>Radiology</b>
                                                </mat-label>
                                             </div><br><br>
                                             <!-- <div class='col-print-12' style='margin-top:13px;'>
                                                <mat-label class='matlabel'><strong>Report
                                                      type :</strong>
                                                   {{reportType}}
                                                </mat-label>
                                             </div> -->
                                             <div class='col-print-12' style='margin-top:5px;'>
                                                <mat-label class='matlabel'><strong>Test
                                                      name :</strong>
                                                   {{testName}}
                                                </mat-label>
                                             </div>
                                             <div *ngIf="!cultureFlag && !template_flag"
                                                class='col-print-12' style='margin-top:5px;'>
                                                <p class='tervys_heading clinical'>
                                                   <b>Sequences</b>
                                                </p>
                                             </div>
                                             <div *ngIf="!cultureFlag && !template_flag"
                                                class='col-print-12'>
                                                <quill-view-html id='viewhtml' [content]='sequence'>
                                                </quill-view-html>
                                             </div>
                                             <div *ngIf="!cultureFlag && !template_flag"
                                                class='col-print-12'>
                                                <p class='tervys_heading clinical'>
                                                   <b>Impression</b>
                                                </p>
                                             </div>
                                             <div *ngIf="!cultureFlag && !template_flag"
                                                class='col-print-12' style='margin-bottom: 12px;'>
                                                <quill-view-html id='viewhtml' [content]='Impression'>
                                                </quill-view-html>
                                             </div>
                                             <div *ngIf="cultureFlag && !template_flag"
                                                class='col-print-12'>
                                                <p class='tervys_heading clinical'>Report
                                                   details</p>
                                             </div>
                                             <div *ngIf="cultureFlag && !template_flag" class='col-print-12'
                                                style='margin-bottom: 12px;'>
                                                <quill-view-html id='viewhtml' [content]='culture'>
                                                </quill-view-html>
                                             </div>
                                             <div [hidden]='dicomDotor' class='col-print-6'>
                                                <div *ngIf="!cultureFlag">
                                                   <div class='tervys_heading'>Attachment
                                                   </div>
                                                   <div>
                                                      <img alt='report image' src='{{reportIMG}}'
                                                         (click)='clickDocument()' class='report_img' />
                                                   </div>
                                                </div>
                                             </div>
                                             <div *ngIf="template_flag" [innerHtml]="template">
                                             </div>

                                          </div>
                                       </div>

                                       <div [hidden]="microbiology_flag" style="width: 100%;">
                                          <div [hidden]="!ot_Sterile_flag">
                                             <div class="container-fluid" style="margin-bottom: 8px;">
                                                <div class="row">
                                                   <div class="col-12 mb-2" style="text-align: center;">
                                                      <h2 style="font-size: 18px;">Microbiology</h2>
                                                   </div>
                                                   <div class="col-12 mb-2" style="text-align: left;">
                                                      <h2 style="font-size: 18px;">{{testName}}</h2>
                                                   </div>
                                                   <div *ngIf="org_iso1_cr_flag" class="col-12 mb-2"
                                                      style="text-align: center;">
                                                      <h2 style="font-size: 22px; font-weight: 500;">
                                                         {{org_iso1_cr_desc}}</h2>
                                                   </div>
                                                   <div class="col-12 mb-3" style="margin-bottom: 8px;">
                                                      <div>
                                                         <p *ngIf="org_iso1_cc_flag"><strong>Colony count :
                                                            </strong>
                                                            {{org_iso_1_cc_desc}}
                                                         </p>
                                                         <!-- <p *ngIf="org_iso1_cr_flag"><strong>Culture Report:
                                                         </strong>
                                                         {{org_iso1_cr_desc}}</p> -->
                                                         <!-- <p *ngIf="org_iso1_growth_type_flag"><strong>Gram
                                                               Stain: </strong>
                                                            {{org_iso1_growth_type_desc}}
                                                         </p> -->
                                                      </div>
                                                   </div>
                                                   <div class="col-6 mb-3" style="margin-bottom: 8px;"
                                                      *ngIf="org_iso1_flag">
                                                      <div class="mb-2">
                                                         <p style="margin-bottom: 2px !important;">
                                                            <strong>{{org_iso_heading}}</strong>
                                                         </p>
                                                         <p style="margin-bottom: 2px !important;">
                                                            {{org_iso_1_desc}}</p>
                                                      </div>
                                                      <table cellpadding='0' cellspacing='0'
                                                         style="width:100%; border:1px solid #747575;">
                                                         <thead>
                                                            <tr>
                                                               <th
                                                                  style="padding: 3px;text-align: left; border: 1px solid #000">
                                                                  Antibiotics</th>
                                                               <th
                                                                  style="padding: 3px;text-align: left; border: 1px solid #000">
                                                                  Sensitivity</th>
                                                               <th *ngIf="mic_iso1_flag"
                                                                  style="padding: 3px;text-align: left; border: 1px solid #000">
                                                                  MIC</th>
                                                            </tr>
                                                         </thead>
                                                         <tbody>
                                                            <tr *ngFor="let medicine of org_iso1_antibiotics"
                                                               style="border: 1px solid #000; height: 18px;">
                                                               <td
                                                                  style='padding: 3px;text-align: left; border: 1px solid'>
                                                                  {{medicine.drug_name}}</td>
                                                               <td
                                                                  style='padding: 3px;text-align: left; border: 1px solid'>
                                                                  {{medicine.sensitivity_desc}}</td>
                                                               <td *ngIf="mic_iso1_flag"
                                                                  style='padding: 3px;text-align: left; border: 1px solid'>
                                                                  {{medicine.mic}}</td>
                                                            </tr>
                                                         </tbody>
                                                      </table>
                                                   </div>
                                                   <div class="col-6 mb-3" style="margin-bottom: 8px; "
                                                      *ngIf="org_iso2_flag">
                                                      <div class="mb-2">
                                                         <!-- <p><strong>Organism
                                                               Isolated 2 </strong> &nbsp;
                                                            {{org_iso_2_desc}}
                                                         </p> -->
                                                         <p style="margin-bottom: 2px !important;">
                                                            <strong>Organism
                                                               Isolated 2 </strong>
                                                         </p>
                                                         <p style="margin-bottom: 2px !important;">
                                                            {{org_iso_2_desc}}</p>
                                                      </div>
                                                      <table cellpadding='0' cellspacing='0'
                                                         style="width:100%; border:1px solid #747575;">
                                                         <thead>
                                                            <tr>
                                                               <th
                                                                  style="padding: 3px;text-align: left; border: 1px solid #000">
                                                                  Antibiotics</th>
                                                               <th
                                                                  style="padding: 3px;text-align: left; border: 1px solid #000">
                                                                  Sensitivity</th>
                                                               <th *ngIf="mic_iso2_flag"
                                                                  style="padding: 3px;text-align: left; border: 1px solid #000">
                                                                  MIC</th>
                                                            </tr>
                                                         </thead>
                                                         <tbody>
                                                            <tr *ngFor="let medicine of org_iso2_antibiotics"
                                                               style="border: 1px solid #000; height: 18px;">
                                                               <td
                                                                  style='padding: 3px;text-align: left; border: 1px solid'>
                                                                  {{medicine.drug_name}}</td>
                                                               <td
                                                                  style='padding: 3px;text-align: left; border: 1px solid'>
                                                                  {{medicine.sensitivity_desc}}</td>
                                                               <td *ngIf="mic_iso2_flag"
                                                                  style='padding: 3px;text-align: left; border: 1px solid'>
                                                                  {{medicine.mic}}</td>
                                                            </tr>
                                                         </tbody>
                                                      </table>
                                                   </div>
                                                   <div class="col-6 mb-3" style="margin-bottom: 8px; "
                                                      *ngIf="org_iso3_flag">
                                                      <div class="mb-2">
                                                         <!-- <p><strong>Organism
                                                               Isolated 3 </strong> &nbsp;
                                                            {{org_iso_1_code}} &nbsp;
                                                            {{org_iso_3_desc}}
                                                         </p> -->

                                                         <p style="margin-bottom: 2px !important;">
                                                            <strong>Organism
                                                               Isolated 3 </strong>
                                                         </p>
                                                         <p style="margin-bottom: 2px !important;">
                                                            {{org_iso_3_desc}}</p>

                                                      </div>
                                                      <table cellpadding='0' cellspacing='0'
                                                         style="width:100%; border:1px solid #747575;">
                                                         <thead>
                                                            <tr>
                                                               <th
                                                                  style="padding: 3px;text-align: left; border: 1px solid #000">
                                                                  Antibiotics</th>
                                                               <th
                                                                  style="padding: 3px;text-align: left; border: 1px solid #000">
                                                                  Sensitivity</th>
                                                               <th *ngIf="mic_iso3_flag"
                                                                  style="padding: 3px;text-align: left; border: 1px solid #000">
                                                                  MIC</th>
                                                            </tr>
                                                         </thead>
                                                         <tbody>
                                                            <tr *ngFor="let medicine of org_iso3_antibiotics"
                                                               style="border: 1px solid #000; height: 18px;">
                                                               <td
                                                                  style='padding: 3px;text-align: left; border: 1px solid'>
                                                                  {{medicine.drug_name}}</td>
                                                               <td
                                                                  style='padding: 3px;text-align: left; border: 1px solid'>
                                                                  {{medicine.sensitivity_desc}}</td>
                                                               <td *ngIf="mic_iso3_flag"
                                                                  style='padding: 3px;text-align: left; border: 1px solid'>
                                                                  {{medicine.mic}}</td>
                                                            </tr>
                                                         </tbody>
                                                      </table>
                                                   </div>
                                                   <div class="col-12 mb-2" *ngIf="technical_noteFlag">
                                                      <h2 style="font-size: 18px;">Technical Notes</h2>
                                                      <p>{{technical_note}}</p>
                                                   </div>
                                                   <div class="col-12 mb-2" *ngIf="commentsFlag">
                                                      <h2 style="font-size: 18px;">Comments</h2>
                                                      <p>{{comment}}</p>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>

                                       <div class="empty-footer"
                                          style="text-align: center; margin-top: 10px;">
                                          <h4>------ End of Report ------</h4>
                                       </div>
                                       <div class="container-fluid" style="margin-top: 30px;">
                                          <div class="row d-flex flex-row justify-content-center">
                                             <table style="width: 100%;">
                                                <tr>
                                                   <td style="width: 90%;text-align: center;">
                                                      <div class="row" [hidden]="createdBy == undefined">
                                                         <div
                                                            class="d-flex flex-row justify-content-center align-items-end">
                                                            <div [hidden]="createdBy == undefined"
                                                               class="col-4">
                                                               <div style="text-align: center;">
                                                                  <img *ngIf="lab_tech_sign" style="width: 55px;" src={{lab_tech_sign}}/>
                                                                  <p style="margin-bottom: 3px !important;">
                                                                     {{createdBy}}</p>
                                                                  <p><strong>Lab technician</strong></p>
                                                               </div>
                                                               <!-- <mat-label class="matlabel"><strong>Lab
                                                               technician
                                                               :</strong> {{createdBy}}
                                                                 </mat-label> -->
                                                            </div>
                                                            <div class="col-4">
                                                               <div style="text-align: center;">
                                                                  <img *ngIf="approver_sign" style="width: 55px;" src={{approver_sign}}/>
                                                                  <p *ngIf="approver_name == undefined"
                                                                     style="margin-bottom: 3px !important;">
                                                                     &nbsp;</p>
                                                                  <p *ngIf="approver_name != undefined"
                                                                     style="margin-bottom: 3px !important;">
                                                                     {{approver_name}}</p>
                                                                  <p><strong>{{consultant}}</strong></p>
                                                               </div>
                                                               <!-- <mat-label class="matlabel"><strong>Approver
                                                               name:
                                                               </strong> {{approver_name}}
                                                               </mat-label> -->
                                                            </div>
                                                            <div class="col-4">
                                                               <div style="text-align: center;">
                                                                  <img *ngIf="quality_head_sign" style="width: 55px;" src={{quality_head_sign}}/>
                                                                  <p style="margin-bottom: 3px !important;">
                                                                     {{qualityHead_name}}</p>
                                                                  <p><strong>Quality Head</strong></p>
                                                               </div>
                                                               <!-- <mat-label class="matlabel"><strong>Quality Head
                                                               :</strong> {{qualityHead_name}}
                                                               </mat-label> -->
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </td>
                                                </tr>
                                             </table>
                                          </div>
                                       </div>
                                    </div>
                                 </td>
                              </tr>
                           </tbody>
                           <tfoot>
                              <tr>
                                 <td width="100%">
                                    <table style="border:'0';width:100%">
                                       <tr>
                                          <td style="width:100%;height: 70px;">&nbsp;</td>
                                       </tr>
                                    </table>
                           </tfoot>
                        </table>
                     </div>
                  </td>
               </tr>
            </tbody>
           </table>
        </div>
        <div #printnoheadsidebar id="printnoheadsidebar"
           style="background-image: url({{bgImage}}); background-position: center;">
 
           <table style="border:'0';width:100%">
              <thead>
                 <tr>
                    <th style="width:100%;">
                       <div class="main"
                          style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
                          <div style="width: 90%;">
                             <!-- <img alt="image" src={{hospitalLogo}} width="100%" height="170px"> -->
                             <div style="height: 170px;"></div>
 
                             <table
                                style="width: 100%; font-size: 12px; border: 1px solid black; margin-top: 10px; margin-bottom: 10px; border-radius: 10px;">
                                <tbody>
                                   <tr>
                                      <td style="padding-left: 5px;"><strong>Patient Name</strong></td>
                                      <td>: {{salutation}} {{clientAnotherName}}</td>
                                      <td style="padding-left: 5px;"><strong>Mr.No</strong></td>
                                      <td>: {{patientID}}</td>
                                   </tr>
                                   <tr>
                                      <td style="padding-left: 5px;"><strong>Visit ID</strong></td>
                                      <td> : {{visit_id}}</td>
                                      <td style="padding-left: 5px;"><strong>Age / Gender</strong></td>
                                      <td>: {{clientAge}} {{clientGender}}</td>
                                   </tr>
                                   <tr>
                                      <td style="padding-left: 5px;" *ngIf="diag_report_type != 'radiology'">
                                         <strong>Sample Date</strong>
                                      </td>
                                      <td *ngIf="diag_report_type != 'radiology'"> : {{sampledatecheck}} {{sampletimecheck}}
                                      </td>
                                      <td style="padding-left: 5px;"><strong>Doctor</strong></td>
                                      <td>: {{doctorname}}</td>
                                      <td style="padding-left: 5px;" *ngIf="diag_report_type == 'radiology'">
                                         <strong>Visited Date & Time</strong></td>
                                      <td *ngIf="diag_report_type == 'radiology'"> :{{visited_date}} {{visited_time}}</td>
                                   </tr>
                                   <tr *ngIf="diag_report_type != 'radiology'">
                                      <td style="padding-left: 5px;"><strong>Specimen</strong></td>
                                      <td>: {{sample_type}}</td>
                                      <td style="padding-left: 5px;"><strong>Sample ID</strong></td>
                                      <td>: {{sample_id}}</td>
                                   </tr>
                                   <tr>
                                      <td style="padding-left: 5px;"><strong>Report Date & Time</strong></td>
                                      <td> : {{reporteddate}} {{reportedtime}}</td>
                                     <td>
                                        <div>
                                           <ngx-barcode class="barcode" [bc-value]="patientID"
                                              [bc-display-value]="displayValue" [bc-line-color]="lineColor"
                                              [element-type]="elementType" [format]="format"
                                              [bc-width]="width" [bc-height]="barcodeheight"
                                              [bc-font-options]="fontOptions" [bc-font]="font"
                                              [bc-text-align]="textAlign" [bc-text-position]="textPosition"
                                              [bc-text-margin]="textMargin" [bc-font-size]="fontSize"
                                              [bc-background]="background" [bc-margin]="margin"
                                              [bc-margin-top]="marginTop" [bc-margin-bottom]="marginBottom"
                                              [bc-margin-left]="marginLeft"
                                              [bc-margin-right]="marginRight"></ngx-barcode>
                                        </div>
                                     </td>
                                   </tr>
                                   <!-- <tr>
                                          <td>
                                            <div id="barcode" style="margin-left: -10px;">
                                               <ngx-barcode class="barcode" [bc-value]="patientID"
                                                  [bc-display-value]="displayValue"
                                                  [bc-line-color]="lineColor"
                                                  [element-type]="elementType" [format]="format"
                                                  [bc-width]="width" [bc-height]="barcodeheight"
                                                  [bc-font-options]="fontOptions" [bc-font]="font"
                                                  [bc-text-align]="textAlign"
                                                  [bc-text-position]="textPosition"
                                                  [bc-text-margin]="textMargin"
                                                  [bc-font-size]="fontSize"
                                                  [bc-background]="background"
                                                  [bc-margin]="margin" [bc-margin-top]="marginTop"
                                                  [bc-margin-bottom]="marginBottom"
                                                  [bc-margin-left]="marginLeft"
                                                  [bc-margin-right]="marginRight"></ngx-barcode>
                                            </div>
                                         </td>                                               
                                      </tr> -->
                                </tbody>
                             </table>
 
                          </div>
 
                       </div>
                    </th>
                 </tr>
              </thead>
 
              <tbody>
                 <tr>
                    <td width="100%">
                       <div
                          style="width: 100%;height: auto;display: contents; display: flex; justify-content: center;">
                          <table class=paging style="width:90%;">
                             <!-- <thead style="display:table-header-group;">
                                <tr>
                                   <td style="width: 100%;">
                                      <img alt="image" src={{hospitalLogo}} width="100%" height="170px">
                                      <div style="width: 100%; height: 170px;"></div>
                                   </td>
                                </tr>
                             </thead> -->
                             <tbody>
                                <tr>
                                   <td>
                                      <div class="main"
                                         style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
                                         <div [hidden]='pathology_flag' style="width: 100%;">
                                            <div class='row' style="justify-content: center;">
                                               <div class='col-print-12' style="background: #d0eaf7;
                                            width: 98%;
                                            height: 18px;
                                            display: flex;
                                            flex-direction: row;
                                            justify-content: center;">
                                                  <mat-label class='matlabel' style="font-size: 14px;">
                                                     <b>Laboratory</b>
                                                  </mat-label>
                                               </div><br><br>
                                               <div class='col-print-12' *ngIf="template_flag == false">
                                                  <table cellpadding='0' cellspacing='0'
                                                     style="width:100%;border: 1p solid #747575; font-size: 12px;">
                                                     <tr>
                                                        <th style="width:250px;text-align: left;">
                                                           Test Name
                                                        </th>
                                                        <th style="width:150px;text-align: center;">
                                                           Reading
                                                        </th>
                                                        <th style="width:100px;text-align: center;">
                                                           Unit
                                                        </th>
                                                        <th style="width:200px;text-align: left;">Reference
                                                           value</th>
                                                     </tr>
                                                     <tbody *ngFor="let test of printArray">
                                                        <tr
                                                           *ngIf="test.sub_test_list != undefined && test.sub_test_list.length > 1">
                                                           <td style='padding: 5px 0 5px 20px;text-align: left;'
                                                              colspan="4">
                                                              <!-- <b>{{test.test_name}}</b> -->
                                                              {{test.category}} -
                                                              <b>{{test.test_name}}</b>
                                                           </td>
                                                        </tr>
                                                        <tr *ngFor='let subtest of test.sub_test_list'
                                                           style="border: 1px solid #6e7070; height: 18px;">
                                                           <td colspan="8"
                                                              *ngIf="subtest.tests != undefined && !subtest.egfr_flag">
                                                              <div>
                                                                 <div
                                                                    class="d-flex flex-row align-items-center"
                                                                    style="padding: 5px;">
                                                                    <!-- <p style="margin-top: 5px; margin-bottom: 0px;">{{subtest.id}}</p> -->
                                                                    <h2
                                                                       style=" font-size: 14px;
                                                              margin-left: 18px; margin-bottom: 0px !important;">
                                                                       {{subtest.sub_test_name}}</h2>
                                                                 </div>
                                                                 <table style="width: 100%; font-size: 12px;">
                                                                    <tbody>
                                                                       <tr *ngFor="let sub of subtest.tests let i = index"
                                                                          style="border-top: 1px solid #6e7070;">
                                                                          <!-- <td>{{i + 1}}</td> -->
                                                                          <td
                                                                             style="width: 310px; height: 18px;           padding-left: 22px;">
                                                                             {{sub.sub_test_name}}</td>
                                                                          <td
                                                                             style="width: 135px; padding-left: 14px;">
                                                                             {{sub.reading}}</td>
                                                                          <td style="width: 80px;">
                                                                             {{sub.unit}}</td>
                                                                          <td>{{sub.range}}</td>
                                                                       </tr>
                                                                    </tbody>
                                                                 </table>
                                                              </div>
                                                           </td>
                                                           <td colspan="8"
                                                              *ngIf="subtest.tests == undefined && subtest.egfr_flag">
                                                              <div>
                                                                 <div
                                                                    class="d-flex flex-row align-items-center"
                                                                    style="padding: 5px;">
                                                                    <!-- <p style="margin-top: 5px; margin-bottom: 0px;">{{subtest.id}}</p> -->
                                                                    <h2
                                                                       style=" font-size: 14px; margin-left: 18px;margin-bottom: 0px !important;">
                                                                       {{subtest.sub_test_name}}</h2>
                                                                 </div>
                                                                 <table style="width: 100%; font-size: 12px;">
                                                                    <tbody>
                                                                       <tr
                                                                          style="border-top: 1px solid #6e7070;">
                                                                          <td
                                                                             style="width: 310px; height: 18px;           padding-left: 22px;">
                                                                             Creatinine</td>
                                                                          <td
                                                                             style="width: 130px; padding-left: 14px;">
                                                                             {{subtest.reading}}</td>
                                                                          <td style="width: 60px;">
                                                                             {{subtest.unit}}</td>
                                                                          <td>{{subtest.test_range}}</td>
                                                                       </tr>
                                                                       <tr
                                                                          style="border-top: 1px solid #6e7070;">
                                                                          <td
                                                                             style="width: 295px; height: 18px;           padding-left: 35px;">
                                                                             eGFR</td>
                                                                          <td
                                                                             style="width: 120px; padding-left: 14px;">
                                                                             {{subtest.egfr_value}}</td>
                                                                          <td style="width: 100px;">
                                                                             mL/min/<br>1.73m2</td>
                                                                          <td> - </td>
                                                                       </tr>
                                                                    </tbody>
                                                                 </table>
                                                              </div>
                                                           </td>
                                                           <td style='padding-left: 20px;text-align: left;'
                                                              *ngIf="subtest.tests == undefined && !subtest.egfr_flag">
                                                              <div>
                                                                 <p style="margin-bottom: 2px !important;">
                                                                    {{subtest.sub_test_name}}
                                                                 </p>
                                                                 <span
                                                                    *ngIf="subtest.test_method != undefined"
                                                                    style="display: flex;">
                                                                    <div
                                                                       *ngIf="subtest.test_method != undefined">
                                                                       Test method: {{subtest.test_method}}
                                                                    </div>
                                                                 </span>
                                                              </div>
                                                           </td>
                                                           <td style='text-align: center;'
                                                              *ngIf="subtest.tests == undefined && !subtest.egfr_flag">
                                                              {{subtest.print_reading}}
                                                           </td>
                                                           <td style='text-align: center;'
                                                              *ngIf="subtest.tests == undefined && !subtest.egfr_flag">
                                                              {{subtest.unit}}
                                                           </td>
                                                           <td style=" text-align: left;padding-left: 20px !important;"
                                                              *ngIf="subtest.tests == undefined && !subtest.egfr_flag">
                                                              <div class="test_range"
                                                                 style="margin-bottom: 0px !important;"
                                                                 [innerHtml]='subtest.test_range'>
                                                              </div>
                                                           </td>
                                                        </tr>
                                                     </tbody>
                                                  </table>
                                               </div>
                                               <div class='col-print-12' *ngIf="template != undefined"
                                                  [innerHtml]="template">
                                               </div>
                                            </div>
                                         </div>
 
                                         <div [hidden]='radiology_flag' style="width: 100%;">
                                            <div class='row' style="margin-left: 20px;margin-right: 20px;">
                                               <div class='col-print-12' style="background: #d0eaf7;
                                         width: 98%;
                                         height: 35px;
                                         display: flex;
                                         flex-direction: row;
                                         justify-content: center;
                                         align-items: center;">
                                                  <mat-label class='matlabel' style="font-size: 17px;">
                                                     <b>Radiology</b>
                                                  </mat-label>
                                               </div><br><br>
                                               <!-- <div class='col-print-12' style='margin-top:13px;'>
                                               <mat-label class='matlabel'><strong>Report
                                                     type :</strong>
                                                  {{reportType}}
                                               </mat-label>
                                            </div> -->
                                               <div class='col-print-12' style='margin-top:5px;'>
                                                  <mat-label class='matlabel'><strong>Test
                                                        name :</strong>
                                                     {{testName}}
                                                  </mat-label>
                                               </div>
                                               <div *ngIf="!cultureFlag && !template_flag"
                                                  class='col-print-12' style='margin-top:5px;'>
                                                  <p class='tervys_heading clinical'>
                                                     <b>Sequences</b>
                                                  </p>
                                               </div>
                                               <div *ngIf="!cultureFlag && !template_flag"
                                                  class='col-print-12'>
                                                  <quill-view-html id='viewhtml' [content]='sequence'>
                                                  </quill-view-html>
                                               </div>
                                               <div *ngIf="!cultureFlag && !template_flag"
                                                  class='col-print-12'>
                                                  <p class='tervys_heading clinical'>
                                                     <b>Impression</b>
                                                  </p>
                                               </div>
                                               <div *ngIf="!cultureFlag && !template_flag"
                                                  class='col-print-12' style='margin-bottom: 12px;'>
                                                  <quill-view-html id='viewhtml' [content]='Impression'>
                                                  </quill-view-html>
                                               </div>
                                               <div *ngIf="cultureFlag && !template_flag"
                                                  class='col-print-12'>
                                                  <p class='tervys_heading clinical'>Report
                                                     details</p>
                                               </div>
                                               <div *ngIf="cultureFlag && !template_flag"
                                                  class='col-print-12' style='margin-bottom: 12px;'>
                                                  <quill-view-html id='viewhtml' [content]='culture'>
                                                  </quill-view-html>
                                               </div>
                                               <div [hidden]='dicomDotor' class='col-print-6'>
                                                  <div *ngIf="!cultureFlag">
                                                     <div class='tervys_heading'>Attachment
                                                     </div>
                                                     <div>
                                                        <img alt='report image' src='{{reportIMG}}'
                                                           (click)='clickDocument()' class='report_img' />
                                                     </div>
                                                  </div>
                                               </div>
                                               <div *ngIf="template != undefined"
                                                  [innerHtml]="template">
                                               </div>
 
                                            </div>
                                         </div>
 
                                         <div [hidden]="microbiology_flag" style="width: 100%;">
                                            <div [hidden]="!ot_Sterile_flag">
                                               <div class="container-fluid" style="margin-bottom: 8px;">
                                                  <div class="row">
                                                     <div class="col-12 mb-2" style="text-align: center;">
                                                        <h2 style="font-size: 18px;">Microbiology</h2>
                                                     </div>
                                                     <div class="col-12 mb-2" style="text-align: left;">
                                                        <h2 style="font-size: 18px;">{{testName}}</h2>
                                                     </div>
                                                     <div *ngIf="org_iso1_cr_flag" class="col-12 mb-2"
                                                        style="text-align: center;">
                                                        <h2 style="font-size: 22px; font-weight: 500;">
                                                           {{org_iso1_cr_desc}}</h2>
                                                     </div>
                                                     <div class="col-12 mb-3" style="margin-bottom: 8px;">
                                                        <div>
                                                           <p *ngIf="org_iso1_cc_flag"><strong>Colony count :
                                                              </strong>
                                                              {{org_iso_1_cc_desc}}
                                                           </p>
                                                           <!-- <p *ngIf="org_iso1_cr_flag"><strong>Culture Report:
                                                        </strong>
                                                        {{org_iso1_cr_desc}}</p> -->
                                                           <p *ngIf="org_iso1_growth_type_flag"><strong>Gram
                                                                 Stain: </strong>
                                                              {{org_iso1_growth_type_desc}}
                                                           </p>
                                                        </div>
                                                     </div>
                                                     <div class="col-6 mb-3" style="margin-bottom: 8px;"
                                                        *ngIf="org_iso1_flag">
                                                        <div class="mb-2">
                                                           <p style="margin-bottom: 2px !important;">
                                                              <strong>{{org_iso_heading}}</strong>
                                                           </p>
                                                           <p style="margin-bottom: 2px !important;">
                                                              {{org_iso_1_desc}}</p>
                                                        </div>
                                                        <table cellpadding='0' cellspacing='0'
                                                           style="width:100%; border:1px solid #747575;">
                                                           <thead>
                                                              <tr>
                                                                 <th
                                                                    style="padding: 3px;text-align: left; border: 1px solid #000">
                                                                    Antibiotics</th>
                                                                 <th
                                                                    style="padding: 3px;text-align: left; border: 1px solid #000">
                                                                    Sensitivity</th>
                                                                 <th *ngIf="mic_iso1_flag"
                                                                    style="padding: 3px;text-align: left; border: 1px solid #000">
                                                                    MIC</th>
                                                              </tr>
                                                           </thead>
                                                           <tbody>
                                                              <tr *ngFor="let medicine of org_iso1_antibiotics"
                                                                 style="border: 1px solid #000; height: 18px;">
                                                                 <td
                                                                    style='padding: 3px;text-align: left; border: 1px solid'>
                                                                    {{medicine.drug_name}}</td>
                                                                 <td
                                                                    style='padding: 3px;text-align: left; border: 1px solid'>
                                                                    {{medicine.sensitivity_desc}}</td>
                                                                 <td *ngIf="mic_iso1_flag"
                                                                    style='padding: 3px;text-align: left; border: 1px solid'>
                                                                    {{medicine.mic}}</td>
                                                              </tr>
                                                           </tbody>
                                                        </table>
                                                     </div>
                                                     <div class="col-6 mb-3" style="margin-bottom: 8px; "
                                                        *ngIf="org_iso2_flag">
                                                        <div class="mb-2">
                                                           <!-- <p><strong>Organism
                                                              Isolated 2 </strong> &nbsp;
                                                           {{org_iso_2_desc}}
                                                        </p> -->
                                                           <p style="margin-bottom: 2px !important;">
                                                              <strong>Organism
                                                                 Isolated 2 </strong>
                                                           </p>
                                                           <p style="margin-bottom: 2px !important;">
                                                              {{org_iso_2_desc}}</p>
                                                        </div>
                                                        <table cellpadding='0' cellspacing='0'
                                                           style="width:100%; border:1px solid #747575;">
                                                           <thead>
                                                              <tr>
                                                                 <th
                                                                    style="padding: 3px;text-align: left; border: 1px solid #000">
                                                                    Antibiotics</th>
                                                                 <th
                                                                    style="padding: 3px;text-align: left; border: 1px solid #000">
                                                                    Sensitivity</th>
                                                                 <th *ngIf="mic_iso2_flag"
                                                                    style="padding: 3px;text-align: left; border: 1px solid #000">
                                                                    MIC</th>
                                                              </tr>
                                                           </thead>
                                                           <tbody>
                                                              <tr *ngFor="let medicine of org_iso2_antibiotics"
                                                                 style="border: 1px solid #000; height: 18px;">
                                                                 <td
                                                                    style='padding: 3px;text-align: left; border: 1px solid'>
                                                                    {{medicine.drug_name}}</td>
                                                                 <td
                                                                    style='padding: 3px;text-align: left; border: 1px solid'>
                                                                    {{medicine.sensitivity_desc}}</td>
                                                                 <td *ngIf="mic_iso2_flag"
                                                                    style='padding: 3px;text-align: left; border: 1px solid'>
                                                                    {{medicine.mic}}</td>
                                                              </tr>
                                                           </tbody>
                                                        </table>
                                                     </div>
                                                     <div class="col-6 mb-3" style="margin-bottom: 8px; "
                                                        *ngIf="org_iso3_flag">
                                                        <div class="mb-2">
                                                           <!-- <p><strong>Organism
                                                              Isolated 3 </strong> &nbsp;
                                                           {{org_iso_1_code}} &nbsp;
                                                           {{org_iso_3_desc}}
                                                        </p> -->
 
                                                           <p style="margin-bottom: 2px !important;">
                                                              <strong>Organism
                                                                 Isolated 3 </strong>
                                                           </p>
                                                           <p style="margin-bottom: 2px !important;">
                                                              {{org_iso_3_desc}}</p>
 
                                                        </div>
                                                        <table cellpadding='0' cellspacing='0'
                                                           style="width:100%; border:1px solid #747575;">
                                                           <thead>
                                                              <tr>
                                                                 <th
                                                                    style="padding: 3px;text-align: left; border: 1px solid #000">
                                                                    Antibiotics</th>
                                                                 <th
                                                                    style="padding: 3px;text-align: left; border: 1px solid #000">
                                                                    Sensitivity</th>
                                                                 <th *ngIf="mic_iso3_flag"
                                                                    style="padding: 3px;text-align: left; border: 1px solid #000">
                                                                    MIC</th>
                                                              </tr>
                                                           </thead>
                                                           <tbody>
                                                              <tr *ngFor="let medicine of org_iso3_antibiotics"
                                                                 style="border: 1px solid #000; height: 18px;">
                                                                 <td
                                                                    style='padding: 3px;text-align: left; border: 1px solid'>
                                                                    {{medicine.drug_name}}</td>
                                                                 <td
                                                                    style='padding: 3px;text-align: left; border: 1px solid'>
                                                                    {{medicine.sensitivity_desc}}</td>
                                                                 <td *ngIf="mic_iso3_flag"
                                                                    style='padding: 3px;text-align: left; border: 1px solid'>
                                                                    {{medicine.mic}}</td>
                                                              </tr>
                                                           </tbody>
                                                        </table>
                                                     </div>
                                                     <div class="col-12 mb-2" *ngIf="technical_noteFlag">
                                                        <h2 style="font-size: 18px;">Technical Notes</h2>
                                                        <p>{{technical_note}}</p>
                                                     </div>
                                                     <div class="col-12 mb-2" *ngIf="commentsFlag">
                                                        <h2 style="font-size: 18px;">Comments</h2>
                                                        <p>{{comment}}</p>
                                                     </div>
                                                  </div>
                                               </div>
                                            </div>
                                         </div>
 
                                         <div class="empty-footer"
                                            style="text-align: center; margin-top: 10px;">
                                            <h4>------ End of Report ------</h4>
                                         </div>
                                         <div class="container-fluid" style="margin-top: 30px;">
                                            <div class="row d-flex flex-row justify-content-center">
                                               <table style="width: 100%;">
                                                  <tr>
                                                     <td style="width: 90%;text-align: center;">
                                                        <div class="row" [hidden]="createdBy == undefined">
                                                           <div
                                                              class="d-flex flex-row justify-content-center align-items-end">
                                                              <div [hidden]="createdBy == undefined"
                                                                 class="col-4">
                                                                 <div style="text-align: center;">
                                                                    <p style="margin-bottom: 3px !important;">
                                                                       {{createdBy}}</p>
                                                                    <p><strong>Lab technician</strong></p>
                                                                 </div>
                                                                 <!-- <mat-label class="matlabel"><strong>Lab
                                                              technician
                                                              :</strong> {{createdBy}}
                                                                </mat-label> -->
                                                              </div>
                                                              <div class="col-4">
                                                                 <div style="text-align: center;">
                                                                    <p *ngIf="approver_name == undefined"
                                                                       style="margin-bottom: 3px !important;">
                                                                       &nbsp;</p>
                                                                    <p *ngIf="approver_name != undefined"
                                                                       style="margin-bottom: 3px !important;">
                                                                       {{approver_name}}</p>
                                                                    <p><strong>{{consultant}}</strong></p>
                                                                 </div>
                                                                 <!-- <mat-label class="matlabel"><strong>Approver
                                                              name:
                                                              </strong> {{approver_name}}
                                                              </mat-label> -->
                                                              </div>
                                                              <div class="col-4">
                                                                 <div style="text-align: center;">
                                                                    <p style="margin-bottom: 3px !important;">
                                                                       {{qualityHead_name}}</p>
                                                                    <p><strong>Quality Head</strong></p>
                                                                 </div>
                                                                 <!-- <mat-label class="matlabel"><strong>Quality Head
                                                              :</strong> {{qualityHead_name}}
                                                              </mat-label> -->
                                                              </div>
                                                           </div>
                                                        </div>
                                                     </td>
                                                  </tr>
                                               </table>
                                            </div>
                                         </div>
                                      </div>
                                   </td>
                                </tr>
                             </tbody>
                             <tfoot>
                                <tr>
                                   <td width="100%">
                                      <table style="border:'0';width:100%">
                                         <tr>
                                            <td style="width:100%;height: 100px;">&nbsp;</td>
                                         </tr>
                                      </table>
                             </tfoot>
                          </table>
                       </div>
                    </td>
                 </tr>
              </tbody>
 
           </table>
 
           <footer style="width:100%;">
              <table style="border:'0';width:100%; margin-bottom: 0;">
                 <tr>
                    <td style="width:100%;height: 100px;">&nbsp;
                       <!-- <img src={{hospitalFooter}} width="100%" height="200px"> -->
                    </td>
                 </tr>
              </table>
           </footer>
        </div>
     </div>
 
  </div>
   <script src="../../../assets/dist/all.js"></script>
 </div>