import { Component,ViewChild, OnInit,HostListener,ViewEncapsulation,ElementRef,OnDestroy, Injector, Input } from '@angular/core';
import {Helper_Class} from '../../helper_class';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { Doc_Helper } from '../Doc_Helper';
import { CasesheetService } from '../casesheet/casesheet.service';
import { Observable, Observer, Subscription } from 'rxjs';

@Component({
  selector: 'app-entdiagnosis',
  templateUrl: './entdiagnosis.component.html',
  styleUrls: ['./entdiagnosis.component.scss']
})
export class EntdiagnosisComponent implements OnInit {

  public diagnosis = "min";
  public header_footer_flag: Boolean;
  public pas_left: boolean;
  public pas_right: boolean;
  public aoe_left: boolean;
  public aoe_right: boolean;
  public cdoe_left: boolean;
  public cdoe_right: boolean;
  public aom_left: boolean;
  public aom_right: boolean;
  public com_left: boolean;
  public com_right: boolean;
  public muc_type: boolean;
  public squa_type: boolean;
  public tm_cen: boolean;
  public tm_mar: boolean;
  public ps_type: boolean;
  public sub_type: boolean;
  public ad_left: boolean;
  public ad_right: boolean;
  public cho_left: boolean;
  public cho_right: boolean;
  public cho_at: boolean;
  public cho_pos: boolean;
  public ome_left: boolean;
  public ome_right: boolean;
  public ec_left: boolean;
  public ec_right: boolean;
  public bp_left: boolean;
  public bp_right: boolean;
  public men_left: boolean;
  public men_right: boolean;
  public poly_left: boolean;
  public poly_right: boolean;
  public ac_left: boolean;
  public ac_right: boolean;
  public ethi_left: boolean;
  public ethi_right: boolean;
  public rhino_left: boolean;
  public rhino_right: boolean;
  public front_sin_left: boolean;
  public front_sin_right: boolean;
  public maxi_sin_left: boolean;
  public maxi_sin_right: boolean;
  public maxi_sin_acu: boolean;
  public maxi_sin_chro: boolean;
  public ethi_sin_left: boolean;
  public ethi_sin_right: boolean;
  public sphe_sin_left: boolean;
  public sphe_sin_right: boolean;
  public septal_sin_left: boolean;
  public septal_sin_right: boolean;
  public spur_sin_left: boolean;
  public spur_sin_right: boolean;
  public nb_left: boolean;
  public nb_right: boolean;
  public tend_left: boolean;
  public tend_right: boolean;
  public antral_right: boolean;
  
  public geit_left: boolean;
  public geit_right: boolean;
  public geit_diff: boolean;
  public geit_mng: boolean;
  public neck_single: boolean;
  public neck_mul: boolean;
  public neck_ten: boolean;
  public neck_nonten: boolean;
  public neck_sin: boolean;
  public neck_multi: boolean;
  public neck_tender: boolean;
  public neck_non: boolean;

  public ear_diag_notes: string;
  subscription: Subscription;

  public Ota_hidden:boolean;
  public ota_dur_hidden:boolean;
  public block_hidden:boolean;
  public ota_disc_hidden:boolean;
  public ota_severe_hidden:boolean;
  public maxi_sel_8:boolean;
  public maxi_txt_8;
  public mig_bi_dur_hidden:boolean;
  public mig_bilate_hidden:boolean;
  public mig_uni_dur_hidden:boolean;
  public mig_unilate_hidden:boolean;
  public nose_hidden:boolean;
  public client:boolean;

  constructor(public messageservice:CasesheetService,private http:Http,private routes:ActivatedRoute,private router:Router,public toastr:ToastrService) { 
    
   }

  ngOnInit(): void {
    this.subscription = this.messageservice.getCasesheetMessage().subscribe(message => {
      if (message) {
          this.diagnosis = message;
      }
    });
    if(Doc_Helper.getAppFlow() == "client" ){
      this.client = true;
    }else{
      this.client = false;
    }
    this.getEntDiagnosisDetails();
  }

  // @HostListener('window:click', ['$event'])
  // onclick(event: any): void {
  //   if(this.client == false){
  //     this.send_data();
  //   }
  // }

  // @HostListener('window:blur', ['$event'])
  // onBlur(event: any): void {
  //   if(this.client == false){
  //     this.send_data();
  //   }
  // }

  getEntDiagnosisDetails(){
    if (Helper_Class.getRet() != undefined) {
      this.pas_left = Helper_Class.getRet().pas_left;
      this.pas_right = Helper_Class.getRet().pas_right;
      this.aoe_left=Helper_Class.getRet().aoe_left;
      this.aoe_right=Helper_Class.getRet().aoe_right;
      this.cdoe_left=Helper_Class.getRet().cdoe_left;
      this.cdoe_right=Helper_Class.getRet().cdoe_right;
      this.aom_left=Helper_Class.getRet().aom_left;
      this.aom_right=Helper_Class.getRet().aom_right;
      this.com_left=Helper_Class.getRet().com_left;
      this.com_right=Helper_Class.getRet().com_right;
      this.muc_type=Helper_Class.getRet().muc_type;
      this.squa_type=Helper_Class.getRet().squa_type;
      this.tm_cen=Helper_Class.getRet().tm_cen;
      this.tm_mar=Helper_Class.getRet().tm_mar;
      this.ps_type=Helper_Class.getRet().ps_type;
      this.sub_type=Helper_Class.getRet().sub_type;
      this.ad_left=Helper_Class.getRet().ad_left;
      this.ad_right=Helper_Class.getRet().ad_right;
      this.cho_left=Helper_Class.getRet().cho_left;
      this.cho_right=Helper_Class.getRet().cho_right;
      this.cho_at=Helper_Class.getRet().cho_at;
      this.cho_pos=Helper_Class.getRet().cho_pos;
      this.ome_left=Helper_Class.getRet().ome_left;
      this.ome_right=Helper_Class.getRet().ome_right;
      this.ec_left=Helper_Class.getRet().ec_left;
      this.ec_right=Helper_Class.getRet().ec_right;
      this.bp_left=Helper_Class.getRet().bp_left;
      this.bp_right=Helper_Class.getRet().bp_right;
      this.men_left=Helper_Class.getRet().men_left;
      this.men_right=Helper_Class.getRet().men_right;
      this.poly_left=Helper_Class.getRet().poly_left;
      this.poly_right=Helper_Class.getRet().poly_right;
      this.ac_left=Helper_Class.getRet().ac_left;
      this.ac_right=Helper_Class.getRet().ac_right;
      this.ethi_left=Helper_Class.getRet().ethi_left;
      this.ethi_right=Helper_Class.getRet().ethi_right;
      this.rhino_left=Helper_Class.getRet().rhino_left;
      this.rhino_right=Helper_Class.getRet().rhino_right;
      this.front_sin_left=Helper_Class.getRet().front_sin_left;
      this.front_sin_right=Helper_Class.getRet().front_sin_right;
      this.maxi_sin_left=Helper_Class.getRet().maxi_sin_left;
      this.maxi_sin_right=Helper_Class.getRet().maxi_sin_right;
      this.maxi_sin_acu=Helper_Class.getRet().maxi_sin_acu;
      this.maxi_sin_chro=Helper_Class.getRet().maxi_sin_chro;
      this.ethi_sin_left=Helper_Class.getRet().ethi_sin_left;
      this.ethi_sin_right=Helper_Class.getRet().ethi_sin_right;
      this.sphe_sin_left=Helper_Class.getRet().sphe_sin_left;
      this.sphe_sin_right=Helper_Class.getRet().sphe_sin_right;
      this.septal_sin_left=Helper_Class.getRet().septal_sin_left;
      this.septal_sin_right=Helper_Class.getRet().septal_sin_right;
      this.spur_sin_left=Helper_Class.getRet().spur_sin_left;
      this.spur_sin_right=Helper_Class.getRet().spur_sin_right;
      this.nb_left=Helper_Class.getRet().nb_left;
      this.nb_right=Helper_Class.getRet().nb_right;
      this.tend_left=Helper_Class.getRet().tend_left;
      this.tend_right=Helper_Class.getRet().tend_right;
      this.antral_right = Helper_Class.getRet().antral_right;
      this.geit_left=Helper_Class.getRet().geit_left;
      this.geit_right=Helper_Class.getRet().geit_right;
      this.geit_diff=Helper_Class.getRet().geit_diff;
      this.geit_mng=Helper_Class.getRet().geit_mng;
      this.neck_single=Helper_Class.getRet().neck_single;
      this.neck_mul=Helper_Class.getRet().neck_mul;
      this.neck_ten=Helper_Class.getRet().neck_ten;
      this.neck_nonten=Helper_Class.getRet().neck_nonten;
      this.neck_sin=Helper_Class.getRet().neck_sin;
      this.neck_multi=Helper_Class.getRet().neck_multi;
      this.neck_tender=Helper_Class.getRet().neck_tender;
      this.neck_non=Helper_Class.getRet().neck_non;
      this.ear_diag_notes=Helper_Class.getRet().ear_diag_notes;
    }
    this.send_data();
  }

  send_data(){
    var ent_diagnosis={
      pas_left:this.pas_left,
      pas_right:this.pas_right,
      aoe_left:this.aoe_left,
      aoe_right: this.aoe_right,
      cdoe_left: this.cdoe_left,
      cdoe_right:this.cdoe_right,
      aom_left:this.aom_left,
      aom_right:this.aom_right,
      com_left:this.com_left,
      com_right:this.com_right,
      muc_type:this.muc_type,
      squa_type:this.squa_type,
      tm_cen:this.tm_cen,
      tm_mar:this.tm_mar,
      ps_type:this.ps_type,
      sub_type:this.sub_type,
      ad_left:this.ad_left,
      ad_right:this.ad_right,
      cho_left:this.cho_left,
      cho_right:this.cho_right,
      cho_at:this.cho_at,
      cho_pos:this.cho_pos,
      ome_left:this.ome_left,
      ome_right:this.ome_right,
      ec_left:this.ec_left,
      ec_right:this.ec_right,
      bp_left:this.bp_left,
      bp_right:this.bp_right,
      men_left:this.men_left,
      men_right:this.men_right,
      poly_left:this.poly_left,
      poly_right:this.poly_right,
      ac_left:this.ac_left,
      ac_right:this.ac_right,
      ethi_left:this.ethi_left,
      ethi_right:this.ethi_right,
      rhino_left:this.rhino_left,
      rhino_right:this.rhino_right,
      front_sin_left:this.front_sin_left,
      front_sin_right:this.front_sin_right,
      maxi_sin_left:this.maxi_sin_left,
      maxi_sin_right:this.maxi_sin_right,
      maxi_sin_acu:this.maxi_sin_acu,
      maxi_sin_chro:this.maxi_sin_chro,
      ethi_sin_left:this.ethi_sin_left,
      ethi_sin_right:this.ethi_sin_right,
      sphe_sin_left:this.sphe_sin_left,
      sphe_sin_right:this.sphe_sin_right,
      septal_sin_left:this.septal_sin_left,
      septal_sin_right:this.septal_sin_right,
      spur_sin_left:this.spur_sin_left,
      spur_sin_right:this.spur_sin_right,
      nb_left:this.nb_left,
      nb_right:this.nb_right,
      tend_left:this.tend_left,
      tend_right:this.tend_right,
      antral_right: this.antral_right,
      geit_left:this.geit_left,
      geit_right:this.geit_right,
      geit_diff:this.geit_diff,
      geit_mng:this.geit_mng,
      neck_single:this.neck_single,
      neck_mul:this.neck_mul,
      neck_ten:this.neck_ten,
      neck_nonten:this.neck_nonten,
      neck_sin:this.neck_sin,
      neck_multi:this.neck_multi,
      neck_tender:this.neck_tender,
      neck_non:this.neck_non,
      ear_diag_notes: this.ear_diag_notes,
    }
    Helper_Class.setEntDiagnosis(ent_diagnosis);
  }
}
