import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from '../../helper_class';
import { Http, Headers } from '@angular/http';
import { Message_data } from '../../../assets/js/Message_data';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Date_Formate, Ignorecase_data } from '../../../assets/js/common';
import { Diagnosis_Helper } from '../Diagnosis_Helper';
import { DiagnosisService } from '../diagnosis/diagnosis.service';
import { ipaddress } from '../../ipaddress';
import { FrontDeskService } from 'src/app/FrontDesk_module/front-desk/front-desk.service';
import { AdminViewService } from 'src/app/Admin_module/admin-view/admin-view.service';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
@Component({
  selector: 'app-diagbilledit',
  templateUrl: './diagbilledit.component.html',
  styleUrls: ['./diagbilledit.component.scss']
})
export class DiagbilleditComponent implements OnInit {
  public appoint;
  public amount;
  public finalAmount;
  public inGST: boolean;
  public concession;
  public concessionData;
  public afterConserssion;
  public tableFlag: boolean;
  public finalFlag: boolean;
  public amountGST;
  public advance;
  public currentDateTime;
  public currentDate;
  public appointDate;
  public createBillArray = [];
  public appId;
  public billdenPlanArray = [];
  public retrivelGSTArray = [];
  public addBillArray = [];
  public listGSTArray = [];
  public charges;
  public estimateID = [];
  public remaingBalance;
  public addvance;
  public totalRecievedAmount;
  public centralGST;
  public stateGST;
  public clientID;
  public relationID;
  public subRelationID;
  public chargeID;
  public diagLocationArray = [];
  public userType;
  public location;
  public date;
  public diagLocation;
  public diagID;
  public testArray = [];
  public diagName;
  public diagnosisCenter;
  public testType;
  public testNames;
  public ipaddress;
  public userInfo;
  //mdbTable
  dtOptions: DataTables.Settings = {};
  public dateTxt;
  public get_app_date
  //Get hospital detials
  public clientName: string;
  public prevID;
  public balanceFlag: boolean;
  public amountRecevied: boolean;
  public previousBalFlag: boolean;
  public balance;
  public totalCollected;
  public payTypeListArray: any = [];
  public insuranceFlag;
  public payType: string;
  public insurancyID: string;
  public insurerList: any = [];
  public inpatientFlag: boolean;
  public tranFlag: boolean;
  public TransactionID;
  public cardFlag: boolean;
  public cardNumber;
  public cardHolderName;
  public chequeFlag: boolean;
  public bankName;
  public quantity = 1;
  public appFlow = "create";
  public billID;
  public diagTestName;
  public priceTable = [];
  public TestArray: any;
  public updateFlag: boolean = true;
  public finyear:string;

  constructor(public toastr: ToastrService, public http: Http, public diagnosisservice: MenuViewService, public frontdeskservice: MenuViewService, public adminservice: MenuViewService) {
    this.dateTxt = this.appointDate;
    this.insuranceFlag = true;
    this.tranFlag = true;
    this.cardFlag = true;
    this.chequeFlag = true;
  }
  ngOnInit(): void {
    this.updateFlag = true;
    this.dtOptions = {
      pageLength: 5,
      "lengthMenu": [[5, 10, 25, 50, -1], [5, 10, 25, 50, "All"]],
      "columnDefs": [
        { "orderable": false, "targets": [0, 1, 2, 3, 4, 5] },
      ],
      "language": {
        "search": "Filter"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12">><"table-responsive"t><"row mt-1 d-flex"<"col-xs-12 col-sm-6 col-md-4"l><"col-xs-12 col-sm-12 col-md-4 text-center sm-order-last"i><"col-xs-12 col-sm-6 col-md-4"p>>'
    };
    this.finalAmount = "0";
    this.amountGST = "0";
    this.advance = "0.00";
    this.concession = "0";
    this.inGST = false;
    this.retrivelGSTArray = [];
    this.estimateID = [];
    this.previousBalFlag = true;
    this.balanceFlag = true;
    this.getDate()
    this.ipaddress = Helper_Class.getIPAddress();
    this.userInfo = Helper_Class.getInfo();
    this.getPaymentType();
    this.getInsurers();
    this.getGST();
    this.getBillType();
    if (Diagnosis_Helper.getreceptbilledit() != undefined) {
      this.appFlow = Diagnosis_Helper.getreceptbilledit().flow;
      this.billID = Diagnosis_Helper.getreceptbilledit().bill_view_id;
      this.finyear = Diagnosis_Helper.getreceptbilledit().fin_year;
      this.clientName = Diagnosis_Helper.getreceptbilledit().patient_name;
      this.diagID = Diagnosis_Helper.getreceptbilledit().Diag_center_id;
      this.diagName = Diagnosis_Helper.getreceptbilledit().diag_centre_name;
    }
    this.getDiagTestTypes();
    if (Diagnosis_Helper.getreceptbilledit != undefined && this.appFlow == "edit")
      this.getBillDetailView();
  }

  getDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate',
      JSON.stringify({
        country: ipaddress.country_code.toString()
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.current_date != null) {
            this.currentDateTime = obj.current_date;
            var get_date = obj.current_date.split('-');
            this.appointDate = get_date[2] + "-" + get_date[1] + "-" + get_date[0];
            this.currentDate = Date_Formate(obj.current_date)
            this.date = obj.current_date;
            this.appointDate = this.currentDateTime;
            this.getLocation();
          }
        }, error => { });
  }

  getLocation() {//Get location
    this.diagLocationArray = [];
    var flag;
    if (Helper_Class.getIsAdmin() != undefined) {
      this.userType = "admin";
    } else {
      if (Diagnosis_Helper.getUserID_Type().user_type != null) {
        if (Diagnosis_Helper.getUserID_Type().user_type == "frontDesk") {
          this.userType = "frontDesk";
          flag = "name";
        } else {
          this.userType = "diag_user";
          flag = "name";
        }
      } else {
        flag = "name";
        this.userType = "diagnosis";
      }
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/diagloc/',
      JSON.stringify({
        diag_name: Diagnosis_Helper.getUserID_Type().diag_centre_id,
        flag: flag,
        imei: this.ipaddress,
        prov_id: Diagnosis_Helper.getUserID_Type().user_id,
        type: this.userType
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          for (var i = 0; i < obj.diag_loc.length; i++) {
            this.diagLocationArray.push({
              centre_id: obj.diag_loc[i].diag_centre_id,
              centre_name: obj.diag_loc[i].diag_centre_name,
              centre_location: obj.diag_loc[i].diag_loc,
              view_data: obj.diag_loc[i].diag_centre_name + " - " + obj.diag_loc[i].diag_loc
            });
          }
          this.location = this.diagLocationArray[0].view_data;
        }, error => { });
  }
  getDiagTestTypes() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/dtests/',
      JSON.stringify({
        diag_centre_id: this.diagID
      }),
      { headers: headers })
      .subscribe(
        response => {
          this.testArray = [];
          var obj = JSON.parse(response["_body"]);
          if (obj.test_types != null) {
            this.testArray = obj.test_types;
          }
        },
        error => { });
  }
  diagTestTypeChange(e) {
    if (e != undefined) {
      this.testType = e;
      this.diagTestName = [];
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/dtestdetails/',
        JSON.stringify({
          diag_centre_id: this.diagID,
          test_type: this.testType
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            var blood_Test_list = [], urine_Test_list = [], face_Test_list = [], scan_Test_list = [], xray_Test_list = [],
              ultra_Test_list = [], electrocardio_test_list = [], culture_Test_list = [];
            if (obj.test_details != null) {
              if (Ignorecase_data("Blood Test", this.testType) == true) {
                if (blood_Test_list.length == 0) {
                  for (var i = 0; i < obj.test_details.length; i++) {
                    blood_Test_list.push({
                      test_id: obj.test_details[i].diag_test_id,
                      test_name: obj.test_details[i].test_name,
                      checked: false,
                      show: false,
                    });
                  }
                  this.diagTestName = blood_Test_list;
                } else {
                  this.diagTestName = blood_Test_list;
                }
              } else if (Ignorecase_data("Urine Test", this.testType) == true) {
                if (urine_Test_list.length == 0) {
                  for (var i = 0; i < obj.test_details.length; i++) {
                    urine_Test_list.push({
                      test_id: obj.test_details[i].diag_test_id,
                      test_name: obj.test_details[i].test_name,
                      checked: false,
                      show: false,
                    });
                  }
                  this.diagTestName = urine_Test_list;
                } else {
                  this.diagTestName = urine_Test_list;
                }
              } else if (Ignorecase_data("Faeces Test", this.testType) == true) {
                if (face_Test_list.length == 0) {
                  for (var i = 0; i < obj.test_details.length; i++) {
                    face_Test_list.push({
                      test_id: obj.test_details[i].diag_test_id,
                      test_name: obj.test_details[i].test_name,
                      checked: false,
                      show: false,
                    });
                  }
                  this.diagTestName = face_Test_list;
                } else {
                  this.diagTestName = face_Test_list;
                }
              } else if (Ignorecase_data("Scan", this.testType) == true) {
                if (scan_Test_list.length == 0) {
                  for (var i = 0; i < obj.test_details.length; i++) {
                    scan_Test_list.push({
                      test_id: obj.test_details[i].diag_test_id,
                      test_name: obj.test_details[i].test_name,
                      checked: false,
                      show: false,
                    });
                  }
                  this.diagTestName = scan_Test_list;
                } else {
                  this.diagTestName = scan_Test_list;
                }
              } else if (Ignorecase_data("X-ray", this.testType) == true) {
                if (xray_Test_list.length == 0) {
                  for (var i = 0; i < obj.test_details.length; i++) {
                    xray_Test_list.push({
                      test_id: obj.test_details[i].diag_test_id,
                      test_name: obj.test_details[i].test_name,
                      checked: false,
                      show: false,
                    });
                  }
                  this.diagTestName = xray_Test_list;
                } else {
                  this.diagTestName = xray_Test_list;
                }
              } else if (Ignorecase_data("Ultra Sound", this.testType) == true) {
                if (ultra_Test_list.length == 0) {
                  for (var i = 0; i < obj.test_details.length; i++) {
                    ultra_Test_list.push({
                      test_id: obj.test_details[i].diag_test_id,
                      test_name: obj.test_details[i].test_name,
                      checked: false,
                      show: false,
                    });
                  }
                  this.diagTestName = ultra_Test_list;
                } else {
                  this.diagTestName = ultra_Test_list;
                }
              } else if (Ignorecase_data("Electrocardio", this.testType) == true) {
                if (electrocardio_test_list.length == 0) {
                  for (var i = 0; i < obj.test_details.length; i++) {
                    electrocardio_test_list.push({
                      test_id: obj.test_details[i].diag_test_id,
                      test_name: obj.test_details[i].test_name,
                      checked: false,
                      show: false,
                    });
                  }
                  this.diagTestName = electrocardio_test_list;
                } else {
                  this.diagTestName = electrocardio_test_list;
                }
              } else if (Ignorecase_data("Culture", this.testType) == true) {
                if (culture_Test_list.length == 0) {
                  for (var i = 0; i < obj.test_details.length; i++) {
                    culture_Test_list.push({
                      test_id: obj.test_details[i].diag_test_id,
                      test_name: obj.test_details[i].test_name,
                      checked: false,
                      show: false,
                    });
                  }
                  this.diagTestName = culture_Test_list;
                } else {
                  this.diagTestName = culture_Test_list;
                }
              }
            }
          },
          error => { });
    } else {
      this.diagTestName = [];
    }
  }
  diagTestChange(e, type) {
    this.testNames = e.test_name;
    if (this.diagTestName.length != 0) {
      if (Ignorecase_data("Blood Test", this.testType) == true) {
        this.addAmount(e.test_id, "Blood");
      } else if (Ignorecase_data("Urine Test", this.testType) == true) {
        this.addAmount(e.test_id, "Urine");
      } else if (Ignorecase_data("Faeces Test", this.testType) == true) {
        this.addAmount(e.test_id, "Feace");
      } else if (Ignorecase_data("X-ray", this.testType) == true) {
        this.addAmount(e.test_id, "Xray");
      } else if (Ignorecase_data("Scan", this.testType) == true) {
        this.addAmount(e.test_id, "Scan");
      } else if (Ignorecase_data("Ultra Sound", this.testType) == true) {
        this.addAmount(e.test_id, "Ultra");
      } else if (Ignorecase_data("Electrocardio", this.testType) == true) {
        this.addAmount(e.test_id, "Electrocardio");
      } else if (Ignorecase_data("Culture", this.testType) == true) {
        this.addAmount(e.test_id, "Culture");
      }
    }
  }
  array_exits(array, id) {
    var flag = false;
    for (var i in array) {
      if (array[i].test_id === id) {
        flag = true;
        break;
      }
    }
    return flag;
  }
  addAmount(test_id, type_of_test) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/tbyid',
      JSON.stringify({
        test_id: test_id,
        type: "diagnosis",
        country: "IN"
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          this.amount = undefined;
          this.amount = obj.price;
          this.concession = "0";
          var perce = this.concession / 100;
          var amt = this.amount;
          var amt_cons = this.afterConserssion;
          var net_pay = parseFloat(amt) * parseFloat(perce.toString());
          var tot = amt - net_pay;
          if (isNaN(tot)) {
            tot = 0;
          }
          this.afterConserssion = Math.round(tot);
        });
  }
  addBill() { //Bill add table
    if (this.amount == undefined) {
      this.toastr.error(Message_data.enterAmt);
    } else if (this.quantity == undefined) {
      this.toastr.error(Message_data.enterQty);
    } else {
      if (this.finalAmount == "0") {
        this.inGST == false;
      }
      this.tableFlag = true;
      this.finalFlag = true;
      var flag = true;
      for (var i = 0; i < this.addBillArray.length; i++) {
        if (this.addBillArray[i].status != '2') {
          if (this.addBillArray[i].test_name == this.testNames) {
            flag = false;
            break;
          }
        }
      }
      if (flag == true) {
        if (this.testArray.length != 0) {
          for (var i = 0; i < this.testArray.length; i++) {
            if (this.testNames == this.testArray[i].Testname) {
              this.chargeID = this.testArray[i].diag_test_id;
            }
          }
        }
        var paydesc;
        if (this.payTypeListArray.length != 0) {
          for (var i = 0; i < this.payTypeListArray.length; i++) {
            if (this.payType == this.payTypeListArray[i].pay_id) {
              paydesc = this.payTypeListArray[i].pay_desc;
            }
          }
        }
        this.concessionData = this.concession == "" ? "0" : this.concession;
        this.addBillArray.push({
          test_name: this.testNames,
          amount_txt: parseFloat(this.amount).toFixed(2),
          concession: parseFloat(this.concessionData).toFixed(2),
          fee: parseFloat(this.afterConserssion).toFixed(2),
          charge_type: this.testNames,
          pay_type: paydesc,
          paytype: this.payType,
          insurer: this.insurancyID,
          quantity: this.quantity,
          status: "1",
        });
        this.amount = '';
        this.concession = '0';
        this.afterConserssion = '';
        this.quantity = 1;
        this.TestArray = [];
      }
      if (this.finalAmount == "0") {
        this.inGST == false;
      }
      this.calculateGST("0", "0");
    }
  }
  deleteBill(type, amount, bill) {//Delete bill
    if (this.addBillArray.length != 0) {
      for (var i = 0; i < this.addBillArray.length; i++) {
        if (this.addBillArray[i].bill_details_id == bill.bill_details_id) {
          if (this.addBillArray[i].biill_type == type && this.addBillArray[i].amount_txt == amount) {
            bill.status = "2";
            // this.addBillArray.splice(i, 1);
            this.calculateGST("0", "0");
            break;
          }
        }
      }
    }
    if (this.addBillArray.length == 0) {
      this.tableFlag = false;
      this.finalFlag = false;
    } else {
      this.tableFlag = true;
    }
    if (this.finalAmount == "0") {
      this.inGST == false;
    }
    this.calculateGST("0", "0");
  }
  getBillType() {//Get bill type
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/chgtype',
      JSON.stringify({
        provider: "diagnosis",
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.charges = obj.charges;
        }, error => { });
  }
  getAppointmentIdDateBase(date) {
    this.createBillArray = [];
    for (var i = 0; i < this.diagLocationArray.length; i++) {
      if (this.diagLocationArray[i].view_data == this.location) {
        this.diagID = this.diagLocationArray[i].centre_id.toString();
        this.diagLocation = this.diagLocationArray[i].centre_location.toString();
      }
    }
    this.get_app_date = (this.date);
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'appointment/appbydate',
      JSON.stringify({
        provider: "diagnosis",
        diag_center_id: this.diagID,
        location: this.diagLocation,
        session: "all",
        date: this.get_app_date,
        bill: "yes"
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.appointments != null) {
            for (var i = 0; i < obj.appointments.length; i++) {
              if (obj.appointments[i].middle_name != null && encrypt_decript.Decript(obj.appointments[i].middle_name) != undefined) {
                this.diagName = obj.appointments[i].diag_appointment_id + " - " + encrypt_decript.Decript(obj.appointments[i].first_name) + " " + encrypt_decript.Decript(obj.appointments[i].middle_name) + " " + encrypt_decript.Decript(obj.appointments[i].last_name);
              } else {
                this.diagName = obj.appointments[i].diag_appointment_id + " - " + encrypt_decript.Decript(obj.appointments[i].first_name) + " " + encrypt_decript.Decript(obj.appointments[i].last_name);
              }
              this.inpatientFlag = obj.appointments[i].inpatient_id != undefined ? true : false;
              this.createBillArray.push({
                doc_app_id: obj.appointments[i].diag_appointment_id,
                client_reg_id: obj.appointments[i].client_reg_id,
                relation_id: obj.appointments[i].relation_id,
                sub_rel_id: obj.appointments[i].sub_rel_id,
                first_name: obj.appointments[i].first_name,
                middle_name: obj.appointments[i].middle_name,
                last_name: obj.appointments[i].last_name,
                diagid_name: this.diagName,
              });
            }
          }
        }, error => { });
  }
  changeAmount() {// concession calculation
    var perce = null;
    perce = this.concession / 100;
    var amt = this.amount;
    if (this.quantity > 1) {
      amt = amt * this.quantity;
    }
    var amt_cons = this.afterConserssion;
    var net_pay = parseFloat(amt) * parseFloat(perce.toString());
    var tot = amt - net_pay;
    if (isNaN(tot)) {
      tot = 0;
    }
    this.afterConserssion = Math.round(tot);
  }
  getGST() {//Get gst
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'gen/tax/',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.retrivelGSTArray = obj.taxes;
        }, error => { });
  }
  includeGST(check) {// Include GST
    this.calculateGST("0", "0");
  }
  calculateGST(palnid, planmodal) {
    this.finalAmount = "0";
    this.amountGST = "0";
    this.totalCollected = "0";
    this.listGSTArray = [];
    if (this.billdenPlanArray.length != 0) {
      for (var i = 0; i < this.billdenPlanArray.length; i++) {
        for (var j = 0; j < this.estimateID.length; j++) {
          if (this.estimateID[j] == this.billdenPlanArray[i].den_treatment_plan_id) {
            this.finalAmount = (parseFloat(this.finalAmount) + parseFloat(this.billdenPlanArray[i].estimate)).toFixed(2);
            this.amountGST = (parseFloat(this.amountGST) + parseFloat(this.billdenPlanArray[i].estimate)).toFixed(2);
            if (parseFloat(this.advance) < parseFloat(this.finalAmount)) {
              this.balanceFlag = false;
              this.totalCollected = (parseFloat(this.finalAmount) - parseFloat(this.advance)).toFixed(2);
              this.remaingBalance = (parseFloat(this.finalAmount) - parseFloat(this.advance)).toFixed(2);
            }
            if (parseFloat(this.advance) > parseFloat(this.finalAmount)) {
              this.balanceFlag = false;
              this.remaingBalance = (parseFloat(this.advance) - parseFloat(this.finalAmount)).toFixed(2);
            }
            this.amountRecevied = true;
            if (this.totalCollected == "0") {
              this.amountRecevied = false;
            }
            if (this.remaingBalance == "0") {
              this.balanceFlag = true;
            }
            break;
          }
        }
      }
    }
    for (var i = 0; i < this.addBillArray.length; i++) {
      if (this.addBillArray[i].status != '2') {
        this.finalAmount = (parseFloat(this.finalAmount) + parseFloat(this.addBillArray[i].fee)).toFixed(2);
        this.amountGST = (parseFloat(this.amountGST) + parseFloat(this.addBillArray[i].fee)).toFixed(2);
        if (parseFloat(this.advance) < parseFloat(this.finalAmount)) {
          this.balanceFlag = false;
          this.remaingBalance = (parseFloat(this.finalAmount) - parseFloat(this.advance)).toFixed(2);
        }
        if (parseFloat(this.advance) > parseFloat(this.finalAmount)) {
          this.balanceFlag = false;
          this.remaingBalance = (parseFloat(this.advance) - parseFloat(this.finalAmount)).toFixed(2);
          this.totalCollected = "0";
        }
        if (this.totalCollected == "0") {
          this.amountRecevied = false;
        }
        if (this.remaingBalance == "0") {
          this.balanceFlag = true;
        }
      }
    }
    if (this.inGST == true) {
      for (var i = 0; i < this.retrivelGSTArray.length; i++) {
        this.listGSTArray.push({
          Gst_Descrip: this.retrivelGSTArray[i].tax_desc + " " + this.retrivelGSTArray[i].tax,
          Gst_amount: (parseFloat(this.amountGST) * (parseFloat(this.retrivelGSTArray[i].tax) / 100)).toFixed(2)
        });
        this.finalAmount = (parseFloat(this.finalAmount) + (parseFloat(this.amountGST) * (parseFloat(this.retrivelGSTArray[i].tax) / 100)));
      }
      this.finalAmount = parseFloat(this.finalAmount).toFixed(2);
    }
    if (this.balance != undefined) {
      this.finalAmount = (parseFloat(this.finalAmount) + parseFloat(this.balance)).toFixed(2);
    }
    //amount to be collected
    this.remaingBalance = (parseFloat(this.finalAmount) - parseFloat(this.advance)).toFixed(2);
    if (this.estimateID.length == 0 && this.addBillArray.length == 0) {
      this.inGST = false;
      this.listGSTArray = [];
      this.advance = "0"
      this.finalAmount = "0";
      this.amountGST = 0;
      this.totalCollected = "0";
      this.remaingBalance = "0";
    }
  }
  changeAdvance() {//add the bill details
    if (this.advance != undefined && this.advance != "") {
      this.remaingBalance = (parseFloat(this.finalAmount) - parseFloat(this.advance)).toFixed(2);
    } else {
      this.remaingBalance = this.finalAmount;
    }
    if (parseFloat(this.advance) > parseFloat(this.finalAmount)) {
      this.remaingBalance = "0";
    }
  }
  saveBill() { //Bill save
    var flag = true;
    if (this.addBillArray.length == 0) {
      this.toastr.error(Message_data.addbill);
      flag = false;
    }
    if (flag == true) {
      this.updateFlag = false;
      if (this.createBillArray.length != 0) {
        for (var i = 0; i < this.createBillArray.length; i++) {
          if (this.appoint == this.createBillArray[i].diagid_name) {
            this.clientID = this.createBillArray[i].client_reg_id;
            this.relationID = this.createBillArray[i].relation_id;
            if (this.createBillArray[i].sub_rel_id != undefined) {
              this.subRelationID = this.createBillArray[i].sub_rel_id;
            }
            this.appId = this.createBillArray[i].doc_app_id;
          }
        }
      }
      this.addvance = this.advance != undefined ? this.advance : "0";
      this.totalRecievedAmount = this.totalCollected != undefined ? this.totalCollected : "0";
      if (this.inGST == true && this.retrivelGSTArray.length != 0) {
        this.centralGST = this.retrivelGSTArray[0].tax;
        this.stateGST = this.retrivelGSTArray[1].tax;
      }
      var app_type;
      app_type = this.userType == "frontDesk" ? "frontdesk" : "diagnosis";
      var paid_flag;
      if (this.finalAmount == this.remaingBalance) {
        paid_flag = "Un paid";
      } else if (this.finalAmount != "0.00" && (this.addvance != "0.00") && this.finalAmount != this.addvance) {
        paid_flag = "Partially paid";
      } else if (this.remaingBalance == "0.00" || this.remaingBalance == "0") {
        paid_flag = "Fully paid";
      }
      var billcreate_value = JSON.stringify({
        bill_id: this.billID,
        fin_year: this.finyear,
        diag_centre_id: this.diagID,
        app_type: app_type,
        client_reg_id: this.clientID,
        relation_id: this.relationID,
        sub_rel_id: this.subRelationID,
        bill_amount: encrypt_decript.Encript(this.finalAmount).toString(),
        advance: this.addvance,
        balance: this.remaingBalance,
        prev_bal: this.balance,
        created_by: this.userInfo.user_id,
        cgst: this.centralGST,
        sgst: this.stateGST,
        bills: this.addBillArray,
        country: ipaddress.country_code,
        prev_bill_id: this.prevID,
        bill_amt: this.finalAmount,
        paid_amt: this.addvance,
        paid_flag: paid_flag,
        inpatient_flag: this.inpatientFlag,
        card_no: this.cardNumber,
        card_holder_name: this.cardHolderName,
        transaction_no: this.TransactionID,
        bank_name: this.bankName
      });
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'bill/ubill/', billcreate_value,
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            if (obj["result"] != null) {
              if (obj["result"] == "Bill updated successful") {
                this.updateFlag = false;
                this.toastr.success(Message_data.billgen);
                if (Helper_Class.getIsAdmin() != undefined) {
                  this.adminservice.sendMessage("diagBillingList");
                } else {
                  if (this.userType == "frontDesk")
                    this.frontdeskservice.sendMessage("frontdeskbilling");
                  else
                    this.diagnosisservice.sendMessage("billing");
                }

              } else {
                this.updateFlag = true;
              }
            }
          }, error => {
            this.updateFlag = true;
            this.toastr.error(Message_data.getNetworkMessage());
          });
    } else {
      this.updateFlag = true;
    }
  }
  getPaymentType() {//Get payment type
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'bill/paytype',
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          this.payTypeListArray = obj.payments;
          this.payType = this.payTypeListArray[0].pay_id;
        }, error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  changePayType() {
    document.getElementById("paytype")!.style.borderColor = '#2ca8fe';
    this.insuranceFlag = true;
    this.tranFlag = true;
    this.cardFlag = true;
    this.chequeFlag = true;
    if (this.payType == "3") {
      this.insuranceFlag = false;
    } else if (this.payType == "4") {
      this.tranFlag = false;
    } else if (this.payType == "2") {
      this.tranFlag = false;
      this.cardFlag = false;
    } else if (this.payType == "5") {
      this.chequeFlag = false;
    } else {
      this.insuranceFlag = true;
    }
  }

  getInsurers() {//Get payment type
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'bill/gins',
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          this.insurerList = obj.insurers;
        }, error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  backClicked() {
    if (Helper_Class.getIsAdmin() != undefined) {
      this.adminservice.sendMessage('diagBillingList');
    } else {
      Diagnosis_Helper.setreceptbilledit(undefined);
      this.diagnosisservice.sendMessage('billing')
    }
  }

  OnDateChangedto(e) {
    this.date = e;
    this.getLocation();
    this.getAppointmentIdDateBase(this.date);
    this.clearData();
  }

  clearData() {
    this.finalAmount = "0";
    this.amountGST = "0";
    this.advance = "0.00";
    this.concession = "0";
    this.testArray = [];
    this.retrivelGSTArray = [];
    this.estimateID = [];
    this.previousBalFlag = true;
    this.balanceFlag = true;
    this.clientName = "";
    this.amount = "";
    this.afterConserssion = "";
  }

  getBillDetailView() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/getbill/',
      JSON.stringify({
        doc_reg_id: this.diagID,
        billing_id: this.billID,
        app_type: "diagnosis",
        fin_year:this.finyear,
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          if (obj.bills != null) {
            var saldata = obj.salutation != undefined ? obj.salutation : "";
            if (obj.middle_name != null && obj.middle_name != undefined) {
              this.clientName = saldata + " " + encrypt_decript.Decript(obj.first_name).toString() + " " + encrypt_decript.Decript(obj.middle_name).toString() + " " + encrypt_decript.Decript(obj.last_name).toString();
            } else {
              this.clientName = saldata + " " + encrypt_decript.Decript(obj.first_name).toString() + " " + encrypt_decript.Decript(obj.last_name).toString();
            }
            this.advance = obj.advance;
            this.finalAmount = encrypt_decript.Decript(obj.bill_amount);
            if (obj.prev_balance != null) {
              this.previousBalFlag = false;
              this.balance = parseFloat(obj.prev_balance).toFixed(2);
            } else {
              this.previousBalFlag = true;
            }
            if (obj.bills != null) {
              for (var i = 0; i < obj.bills.length; i++) {
                var quantity = 1;
                if (obj.bills[i].quantity != undefined) {
                  quantity = obj.bills[i].quantity;
                }
                this.addBillArray.push({
                  test_name: obj.bills[i].test_name,
                  biill_type: obj.bills[i].charge_desc,
                  amount_txt: parseFloat(obj.bills[i].fee).toFixed(2),
                  concession: parseFloat(obj.bills[i].concession).toFixed(2),
                  fee: parseFloat(obj.bills[i].fee).toFixed(2),
                  quantity: obj.bills[i].quantity,
                  charge_type: obj.bills[i].charge_type,
                  pay_type: obj.bills[i].pay_type,
                  paytype: obj.bills[i].pay_id,
                  status: "0",
                  bill_details_id: obj.bills[i].bill_details_id
                });
              }
              if (obj.cgst != undefined) {
                this.inGST = true;
                this.includeGST(this.inGST);
              }
              if (obj.created_date != undefined) {
                var get_date = obj.created_date.toString().split('-');
                var datetxt = get_date[2] + "-" + get_date[1] + "-" + get_date[0];
              }
              var tot_amt = "0";
              if (obj.bills != null && obj.bills.length != 0) {
                for (var i = 0; i < obj.bills.length; i++) {
                  if (obj.bills[i].fee != null) {
                    tot_amt = (parseInt(tot_amt) + parseFloat(obj.bills[i].fee)).toFixed(2);
                  }
                }
              }
              if (obj.estimates != null) {
                if (obj.estimates.length != 0) {
                  for (var i = 0; i < obj.estimates.length; i++) {
                    if (obj.estimates[i].estimate != null) {
                      tot_amt = (parseFloat(tot_amt) + parseFloat(obj.estimates[i].estimate)).toFixed(2);
                    }
                  }
                }
              }
            }
          }
        }, error => { });
  }
}
