import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ipaddress } from './ipaddress';
import 'rxjs/add/operator/map';

@Injectable()
export class ServerApi{
   constructor( public http:HttpClient){}
   public getData(url){
      var headers = new HttpHeaders();
         headers.append('Content-Type', 'application/json;');
         headers.append('responseType', 'text');
         return this.http.get( ipaddress.getIp.toString() + url, { headers: headers });
      }
   public PostData(url, data) {
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      return this.http.post(ipaddress.getIp.toString() + url, data, { headers: headers });
   }
}