<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Sales Report</h5>
          </div>
          <div class="headerButtons">
            <mat-label class="matlabel"><br>
              <img src="../../../assets/ui_icons/buttons//back_icon.svg" style="width: 35px;
                padding-left: 10px;margin-right: 10px;" (click)="todisplaycoverdiv()" *ngIf="backbutton" />

              <a style="margin:0 5px;" (click)="print_area()"><img src="../../../assets/img/printer.svg"
                  class="backimgbtn_inpatinfo" style="width: 25px; height: 25px;" *ngIf="backbutton" /></a>
              <img src="../../../assets/ui_icons/buttons/XL_export.svg" class="saveimgbtn_inpatinfo"
                (click)="getExcelData('excel')" *ngIf="backbutton" />
            </mat-label>
          </div>
        </div>


      </mdb-card-header>
      <mdb-card-body>
        <div class="cover_div" style="padding-bottom: 13px;" *ngIf="forcoverdiv">
          <div class="header_lable">
            Sales report
          </div>
          <div class="content_cover">
            <div class="row">
              <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 select_bottom" *ngIf="userFlag">
                <mat-label class="matlabel"> User <br>
                  <select [(ngModel)]="userName" class="ipcss" (change)="selectedText()">
                    <!-- <option value="All">All</option> -->
                    <option *ngFor="let data of userList" value={{data.name}}>{{data.name}}</option>
                  </select>
                </mat-label>
              </div>

              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                <mat-label class="matlabel" style="margin-top: 7px;">Order type<br>
                  <select disableOptionCentering class="ipcss " [(ngModel)]="reportType">
                    <option value="All">Both</option>
                    <option value="OTC">OTC</option>
                    <option value="Non-OTC">Non-OTC</option>
                  </select>
                </mat-label>
              </div>

              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                <mat-label class="matlabel" style="margin-top: 7px;">Patient Type<br>
                  <select disableOptionCentering class="ipcss " [(ngModel)]="PatientType">
                    <option value="All">All</option>
                    <option value="IP">IP</option>
                    <option value="OP">OP</option>
                  </select>
                </mat-label>
              </div>


              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                <mat-label class="matlabel">Payment type<br>
                  <mat-select id="paytype" class="ipcss  widthbillcreate" [(ngModel)]="payType">
                    <mat-option *ngFor="let pay of payTypeListArrray" value={{pay.pay_id}}>{{pay.pay_desc}}</mat-option>
                  </mat-select>
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                <mat-label class="matlabel" style="margin-top: 7px;">From
                  <input type="date" class="ipcss_date " (change)="dateSelect(fromDate,'1')" [(ngModel)]="fromDate"
                    max="{{currentDate}}" #matInput style="width: 140px;">
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                <mat-label class="matlabel" style="margin-top: 7px;">To
                  <input type="date" class="ipcss_date " (change)="dateSelect(toDate,'2')" [(ngModel)]="toDate"
                    max="{{currentDate}}" #matInput style="width: 140px;">
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                <mat-label class="matlabel"><br>
                  <img src="../../../assets/ui_icons/buttons/search_button.svg" class="saveimgbtn_inpatinfo"
                    (click)="getSalesData('otc')" />
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                <mat-label class="matlabel"><br>

                </mat-label>
              </div>
            </div>
          </div>
        </div>

        <div
          style="box-shadow: 0 0 2px 0 ;
        background: inherit;
        backdrop-filter: blur(10px);border: 1px solid #66b9eb;border-radius: 5px;padding: 1px 20px 5px 20px;margin-top:5px"
          *ngIf="amountcard">
          <!-- border: .01rem solid #e0e1ed -->
          <div style="margin-top: 5px;" class="row">

            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 my-auto">
              <mat-label class="matlabel" style="margin-top: 7px;" #totalamount id="totalamount">
                <span style="font-weight: 600;">Amount : </span> {{totalAmount}}

              </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 my-auto">
              <mat-label class="matlabel" style="margin-top: 7px;" *ngIf="cashAmount == 'NaN' ? '0.00' : cashAmount"
                #cashamount id="cashamount"><span style="font-weight: 600;">Cash : </span>
                {{cashAmount}}
              </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 my-auto">
              <mat-label class="matlabel" style="margin-top: 7px;" *ngIf="cardAmount == 'NaN' ? '0.00' : cardAmount"
                #cardamount id="cardamount"><span style="font-weight: 600;">Card : </span>
                {{cardAmount}}
              </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 my-auto">
              <mat-label class="matlabel" style="margin-top: 7px;" *ngIf="insureAmount == 'NaN' ? '0.00' : insureAmount"
                #insureamount id="insureamount"><span style="font-weight: 600;">Insurance :
                </span> {{insureAmount}}
              </mat-label>
            </div>

            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 my-auto">
              <mat-label class="matlabel" style="margin-top: 7px;" *ngIf="upiAmount == 'NaN' ? '0.00' : upiAmount"
                #upiamount id="upiamount"><span style="font-weight: 600;">UPI:</span>
                {{upiAmount}}
              </mat-label>
            </div>
          </div>
        </div>

        <p class="nodata" *ngIf="salesArray.length ==0">No records found</p>
        <div *ngIf="viewbutton" class="table-responsive cover_div" [ngStyle]="{'height': ishide ? '75vh' : '60vh'}"
          style="margin-top: 10px;width: 100% !important;" [hidden]="hasData">
          <table #printpharsalesRepo id="printpharsalesRepo" style="font-size: 12px; width: 100%;margin-left: 5px;">
            <thead>
              <tr>
                <th style="width: 7%;height: auto;">Bill no</th>
                <th style="width: 17%;height: auto;">Buyer</th>
                <th style="width: 17%;height: auto;">Created by</th>
                <th style="width: 17%;height: auto;">Purchase type</th>
                <th style="width: 13%;height: auto;">Bill date</th>
                <th style="width: 10%;height: auto;">Amount</th>
                <th style="width: 10%;height: auto;">Balance</th>
                <th style="width: 10%;height: auto;">Payment Status</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let sales of salesArray">
                <td style="width: 7%;height: auto;">{{sales.order_id}}</td>
                <td style="width: 17%;height: auto;" class="align_left">{{sales.name}}</td>
                <td style="width: 17%;height: auto;" class="align_left">{{sales.created_by}}</td>
                <td style="width: 17%;height: auto;" >{{sales.order_type}}</td>
                <td  style="width: 13%;height: auto;font-size: 11px;" >{{sales.order_date}}</td>
                <td  style="width: 10%;height: auto;text-align: right;">{{sales.amount}}</td>
                <td  style="width: 10%;height: auto;text-align: right;">{{sales.balance}}</td>
                <td  style="width: 10%;height: auto;" >{{sales.paid}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>