<div class="row">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 style="color:black" class="m-0" class="mainHeadingStyle">Appointment Details</h5>
                    </div>
                    <div class="headerButtons">
                        <a *ngIf="saveFlag" (click)="createAppointment()"><img
                                src="../../../assets/ui_icons/buttons/save_button.svg"
                                class="saveimgbtn_inpatinfo" /></a>
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>
                <div class="row">
                    <div class="Details" style="margin-top: -12px;">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style="margin: 15px 0 15px 0;">
                                <mat-radio-group class="walkin_radio" [(ngModel)]="clientType" color="primary"
                                    labelPosition="before"><b style="vertical-align: text-bottom;">Client Type
                                        : </b>&nbsp;
                                    <mat-radio-button (change)="userTypeSelect('New')" value="New">New
                                    </mat-radio-button>&nbsp;&nbsp;
                                    <mat-radio-button (change)="userTypeSelect('Existing')" checked value="Existing">
                                        Existing</mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div class="col-1"style="position: relative;top: 6px;width: 8%;" *ngIf="is_admin">
                                <mat-label class="matlabel" 
                                >Nurse list:</mat-label>
                              </div>
                              <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3"  *ngIf="is_admin">
                                <select required [(ngModel)]="doctorid" (change)="changephysio()" class="ipcss widthappt">
                                  <option value="All">All</option>
                                  <option *ngFor="let doctors of physionameList" value={{doctors.physiodata_id}}>{{doctors.PhysioName}}
                                  </option>
                                </select>
                              </div>
                        </div>
                        <mat-accordion class="mataccordion">
                            <mat-expansion-panel class="expandpanel" [expanded]="true">
                                <mat-expansion-panel-header class="exppanel width">
                                    <mat-panel-title class="title">
                                        Personal
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <hr class="hr">
                                <div class="row pad_top_15">
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom"
                                        *ngIf="userTypeFlag">
                                        <mat-label class="matlabel">Relationship
                                            <select disableOptionCentering class="ipcss noappt_width"
                                                name="Relationship" [(ngModel)]="relationName"
                                                (ngModelChange)="changeRelation($event)">
                                                <option disabled>Select</option>
                                                <option *ngFor="let relation of relationList"
                                                    value="{{relation.relationship_name}}">
                                                    {{relation.relationship_name}}</option>
                                            </select>
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom">
                                        <mat-label class="matlabel">Salutation
                                            <select disableOptionCentering class="ipcss noappt_width"
                                                [(ngModel)]="clntSal" (ngModelChange)="changeSalutation($event)">
                                                <option value="select" selected>Select</option>
                                                <option *ngFor="let sal of clntSalList" value="{{sal.sal_id}}">
                                                    {{sal.sal_desc}}</option>
                                            </select>
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <mat-label class="matlabel">First Name
                                            <input type="text" class="ipcss noappt_width" (change)="acitvateSaveBtn()"
                                                [(ngModel)]="clntFirstName"
                                                (blur)="fnameToUpper()" required matInput [matAutocomplete]="auto5" />
                                            <mat-autocomplete #auto5="matAutocomplete" [hidden]="nameFlag">
                                                <mat-option (click)="selectClient(client.name)"
                                                    *ngFor="let client of clientList" value="{{client.name}}">
                                                    {{client.name}}
                                                </mat-option>
                                            </mat-autocomplete>
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <mat-label class="matlabel">Middle Name
                                            <input type="text" class="ipcss noappt_width" [(ngModel)]="clntMiddleName"
                                                (blur)="mnameToUpper()" matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <mat-label class="matlabel">Last Name
                                            <input type="text" required class="ipcss noappt_width"
                                                [(ngModel)]="clntLastName" [required]="lNameRequiredFlag"
                                                (change)="acitvateSaveBtn()" (blur)="lnameToUpper()" matInput />
                                        </mat-label>
                                    </div>
                                    <div *ngIf="userTypeFlag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <div class="row">
                                            <div class="col-10">
                                                <mat-label class="matlabel" *ngIf="userTypeFlag">Mobile No
                                                    <input type="text" class="ipcss noappt_width"
                                                        [(ngModel)]="clntContactNo" maxlength="10"
                                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                        required (click)="fucusMobile()" (blur)="checkClient()"
                                                        matInput />
                                                </mat-label>
                                            </div>
                                            <div class="col-2  p-0">
                                                <mat-label class="matlabel" *ngIf="userTypeFlag">
                                                    <a (click)="getUserDetails()"><img
                                                            src="../../../assets/ui_icons/search_icon_new.svg"
                                                            class="seacrhicon" /></a>
                                                </mat-label>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="!userTypeFlag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel" *ngIf="!userTypeFlag">Mobile No
                                            <input type="text" class="ipcss noappt_width" (change)="acitvateSaveBtn()"
                                                [(ngModel)]="clntContactNo" maxlength="10" required
                                                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                (focus)="clearMobile()" matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <mat-label class="matlabel">DOB
                                            <input type="date" class="ipcss_date noappt_width" [disabled]="dobDisabledFlag"
                                                (change)="selectDob(clntDOB)" [(ngModel)]="clntDOB"
                                                max="{{currentMaxDate}}" required #matInput>
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <mat-label class="matlabel">Age
                                            <!-- [disabled]="ageDisabledFlag" -->
                                            <input type="text" class="ipcss noappt_width" [disabled]="ageDisabledFlag"
                                                (keyup)="changeAge()" [(ngModel)]="clntAge" [required]="requireFlag"
                                                matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <mat-label class="matlabel" *ngIf="userTypeFlag">Gender
                                            <input type="text" class="ipcss noappt_width" [(ngModel)]="clntGender"
                                                matInput />
                                        </mat-label>
                                        <div class="select_bottom">
                                            <mat-label class="matlabel" *ngIf="!userTypeFlag">Gender
                                                <select disableOptionCentering (change)="acitvateSaveBtn()"
                                                    (ngModelChange)=" ($event)" class="ipcss noappt_width"
                                                    [(ngModel)]="clntGender">
                                                    <option value="Male">Male</option>
                                                    <option value="Female">Female</option>
                                                    <option value="Transgender">Transgender</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                    </div>
                                    <div [hidden]="covidFlag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Symptoms
                                            <input type="text" class="ipcss noappt_width" [(ngModel)]="clntSymptom"
                                                (keyup)="getSymptoms()" matInput />
                                            <div class="auto_complete_text" *ngIf="symptomList.length != 0">
                                                <ul *ngFor="let symptom of symptomList">
                                                    <li>
                                                        <a (click)="selectSymptom(symptom)">{{symptom}}</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </mat-label>
                                    </div>
                                    <div class="col-12">
                                        <div class="cover_div">
                                            <div class="header_lable">
                                                Appointment details
                                            </div>
                                            <div class="content_cover">
                                                <div class="row">
                                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                                        <mat-label class="matlabel">Therapy
                                                            <select required class="ipcss" [(ngModel)]="therapy">
                                                                <option [ngValue]="Select">Select</option>
                                                                <option *ngFor="let therapy of therapyMasterList"
                                                                    value={{therapy.value}}>
                                                                    {{therapy.label}}</option>
                                                            </select>
                                                        </mat-label>
                                                    </div>
                                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                                        <div class="row">
                                                            <div class="col-4" style="padding: 0;">
                                                                <mat-label class="matlabel">sittings
                                                                    <input class="ipcss" required [(ngModel)]="sittings"
                                                                        maxlength="3"
                                                                        onkeypress="return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)">
                                                                </mat-label>
                                                            </div>
                                                            <div class="col-8">
                                                                <mat-label class="matlabel">Start date
                                                                    <input type="date" class="ipcss_date "
                                                                        id="appt_date"
                                                                        (change)="selectStartDate(physioStartDate)"
                                                                        [(ngModel)]="physioStartDate"
                                                                        min={{currentMaxDate}} #matInput>
                                                                </mat-label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                                        <mat-label class="matlabel">Frequency
                                                            <select class="ipcss" required [(ngModel)]="visitFreq"
                                                                required>
                                                                <option>Every day</option>
                                                                <option>Alternative days</option>
                                                                <option>3 days once</option>
                                                                <option>Once in a week</option>
                                                                <option>Once in fortnight</option>
                                                                <option>Once in a month</option>
                                                            </select>
                                                        </mat-label>
                                                    </div>
                                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                                        <mat-label class="matlabel">Session<br>
                                                            <select class="ipcss"
                                                                (ngModelChange)="selectSession($event)" required
                                                                [(ngModel)]="session">
                                                                <option *ngFor="let name of visitingSessionList"
                                                                        value="{{name}}">{{name}}</option>
                                                            </select>
                                                        </mat-label>
                                                    </div>
                                                    <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                                        <mat-label class="matlabel">From</mat-label>
                                                        <div class="row">
                                                            <div class="col-4" style="padding-right: 0px;">
                                                                <select class="ipcss  " [(ngModel)]="startTime">
                                                                    <option *ngFor="let name of timeStartsFrom"
                                                                        value="{{name}}">{{name}}</option>
                                                                </select>
                                                            </div>
                                                            <div class="col-4" [hidden]="sessionFlag"
                                                                style="padding-right: 0px;">
                                                                <select class="ipcss " [(ngModel)]="endTime">
                                                                    <option *ngFor="let name of timeEndsAt"
                                                                        value="{{name}}">{{name}}</option>
                                                                </select>
                                                            </div>
                                                            <div class="col-4" [hidden]="sessionFlag"
                                                                style="padding-right: 0px;">
                                                                <select class="ipcss" [(ngModel)]="timeSession">
                                                                    <option value="AM" Selected>AM</option>
                                                                    <option value="PM">PM</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                                        <mat-label class="matlabel">  Remarks  <br>
                                                            <textarea class="ipcss noappt_width" [(ngModel)]="remarks" maxlength="500"></textarea>
                                                        </mat-label>
                                                    </div>
                                                    <div class="col-12 col-sm-6 col-md-4 col-lg-5 col-xl-5 my-auto">
                                                        <img (click)="addTherapy()"
                                                            src="../../../assets/ui_icons/buttons/add_button.svg"
                                                            class="saveimgbtn_inpatinfo btn-outline-success rounded pointer" style="float: right;" />
                                                    </div>
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                        <div class="dig_table_overflow" *ngIf="therapyList.length != 0">
                                                            <div class="table-responsive" >
                                                                <table id="card_tbl" class="table table-hover table-dynamic">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Theraphy</th>
                                                                            <th>Starts From</th>
                                                                            <th>Time</th>
                                                                            <th>No of sittings</th>
                                                                            <th>Frequency</th>
                                                                            <th>Remarks</th>
                                                                            <th>&nbsp;</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr *ngFor="let therapy of therapyList">
                                                                            <td>{{therapy.therapy_name}}</td>
                                                                            <td>{{therapy.start_from}}</td>
                                                                            <td>{{therapy.app_time}}</td>
                                                                            <td>{{therapy.sittings}}</td>
                                                                            <td>{{therapy.frequency}}</td>
                                                                            <td>{{therapy.remarks}}</td>
                                                                            <td>
                                                                                <a (click)="deleteTherapy(therapy.therapy_id)"><img
                                                                                        src="../../../assets/ui_icons/buttons/trash_icon.svg"
                                                                                        class="editDelicon" /></a>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                        <mat-accordion class="mataccordion" [hidden]="otherFlag">
                            <mat-expansion-panel class="expandpanel">
                                <mat-expansion-panel-header class="exppanel width">
                                    <mat-panel-title class="title">
                                        Other profile details
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <hr class="hr">
                                <div class="row pad_top_15">
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Referred doctor
                                            <input type="text" class="ipcss noappt_width" [(ngModel)]="referredDoctor"
                                                maxlength="25" matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Occupation
                                            <input type="text" class="ipcss noappt_width" [(ngModel)]="clntOccupation"
                                                maxlength="25" matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <mat-label class="matlabel">Address1
                                            <input type="text" class="ipcss noappt_width" [(ngModel)]="clntAddress1"
                                                [disabled]="userTypeFlag" matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <mat-label class="matlabel">Address2
                                            <input type="text" class="ipcss noappt_width" [(ngModel)]="clntAddress2"
                                                [disabled]="userTypeFlag" matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom">
                                        <mat-label class="matlabel">Country
                                            <input type="text" class="ipcss noappt_width" [(ngModel)]="clntCountry"
                                                [disabled]="!userTypeFlag" [hidden]="existUserFlag" matInput />
                                            <select disableOptionCentering class="ipcss noappt_width"
                                                [(ngModel)]="clntCountry" [hidden]="newUserFlag"
                                                (ngModelChange)="changeCountry(clntCountry, '0')">
                                                <option Selected>Select</option>
                                                <option *ngFor="let country of clntCountryList"
                                                    value="{{country.description}}">{{country.description}}</option>
                                            </select>
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom">
                                        <mat-label class="matlabel">State
                                            <input type="text" class="ipcss noappt_width" [(ngModel)]="clntState"
                                                [disabled]="!userTypeFlag" [hidden]="existUserFlag" matInput />
                                            <select disableOptionCentering class="ipcss noappt_width"
                                                [(ngModel)]="clntState" [hidden]="newUserFlag"
                                                (ngModelChange)="changeState(clntState, '0')">
                                                <option Selected>Select</option>
                                                <option *ngFor="let state of clntStateList"
                                                    value="{{state.description}}">
                                                    {{state.description}}</option>
                                            </select>
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom">
                                        <mat-label class="matlabel">District / City
                                            <input type="text" class="ipcss noappt_width" [(ngModel)]="clntCity"
                                                [disabled]="!userTypeFlag" [hidden]="existUserFlag" matInput />
                                            <select disableOptionCentering class="ipcss noappt_width"
                                                [(ngModel)]="clntCity" [hidden]="newUserFlag"
                                                (ngModelChange)="changeCity(clntCity, '0')">
                                                <option Selected>Select</option>
                                                <option *ngFor="let city of clntCityList"
                                                    value="{{city.description}}">
                                                    {{city.description}}</option>
                                            </select>
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Location
                                            <input type="text" class="ipcss noappt_width" [disabled]="userTypeFlag"
                                                [(ngModel)]="clntLocation" (keyup)="changeLocation(clntLocation)"
                                                matInput [matAutocomplete]="auto" required />
                                            <mat-autocomplete #auto="matAutocomplete" [hidden]="newUserFlag">
                                                <mat-option (click)="selectLocation(location)"
                                                    *ngFor="let location of clntLocationList"
                                                    value="{{location.description}}">
                                                    {{location.description}}
                                                </mat-option>
                                            </mat-autocomplete>
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <mat-label class="matlabel">Zipcode
                                            <input type="text" class="ipcss noappt_width" [(ngModel)]="clntZipcode"
                                                [disabled]="!userTypeFlag" matInput />
                                        </mat-label>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>