import { Component, OnInit, HostListener } from '@angular/core';
import { Helper_Class } from '../../helper_class';
// import { getIp, Ip_with_img_address } from '../../../assets/js/ipaddress.js';
import { Http, Headers } from '@angular/http';
import { DentalService, TreatmentService } from '../service';
import { ToastrService } from 'ngx-toastr';
import { Observable, Observer, Subscription } from 'rxjs';
import * as $ from 'jquery/dist/jquery.min.js';
import { Doc_Helper } from '../Doc_Helper';
import { ipaddress } from '../../ipaddress' ;
@Component({
  selector: 'app-dental-spl-general-hist-pane',
  templateUrl: './dental-spl-general-hist-pane.component.html',
  styleUrls: ['./dental-spl-general-hist-pane.component.css']
})
export class DentalSplGeneralHistPaneComponent implements OnInit {
  public MaleDisable: boolean;
  public FeMaleDisable: boolean;
  public gen_fat_name;
  public gen_mot_name;
  public ynoption: boolean = false;
  ynopt13: boolean;
  public ortho_fields: boolean;

  public gen_marriage;
  public gen_pare;
  public gen_sib;
  public gen_male;
  public gen_fem;
  public gen_sib_gen;
  public gen_history_fami;
  public gen_home;
  public gen_past_dent;
  public gen_pare_con;
  public gen_rea_dis;
  public gen_dur_treat;
  public gen_type_app;
  public type_dev;
  public his_ortho;
  public General_Hist_Pane: boolean;
  public get_spl_name;
  public disabled: boolean;

  public genTab;
  public panelOpenState11;
  public panelOpenState12;
  public subscription: Subscription;

  public gen_fat_name_req: boolean;
  public gen_mot_name_req: boolean;
  public gen_marriage_req: boolean;
  public gen_part_gen_dent_req: boolean;
  public gen_type_app_req: boolean;
  public gen_du_treat_req: boolean;
  public gen_home_care_req: boolean;
  public gen_reason_req: boolean;
  public gen_part_conc_req: boolean;
  public gen_male_req: boolean;
  public gen_female_req: boolean;
  public gen_gen_den_req: boolean;

  public Intra_Oral_Pane: boolean;
  public Extraoral_Pane: boolean;
  public Gnathic_Pane: boolean;
  public Functional_Pane: boolean;
  public Orthodontic_Pane: boolean;
  public Gums_Teeth_Pane: boolean;
  public Dental_Hist_Pane: boolean;
  public Birth_Hist_Pane: boolean;
  public Natal_Hist_Pane: boolean;
  public Natal_Hist: boolean;
  public Pediatric_Spec_Pane: boolean;
  public Endodontic_Hist_Pane: boolean;
  public perio_specific: boolean;
  public ortho_physical: boolean;
  public vital_signs_public: boolean;

  public client:boolean = false;

  constructor(public http: Http, public toastr: ToastrService, public messageservice: TreatmentService, public dentalservice: DentalService) {
    this.gen_gen_den_req = false;
    this.gen_female_req = false;
    this.gen_fat_name_req = false;
    this.gen_mot_name_req = false;
    this.gen_marriage_req = false;
    this.gen_part_gen_dent_req = false;
    this.gen_type_app_req = false;
    this.gen_du_treat_req = false;
    this.gen_home_care_req = false;
    this.gen_reason_req = false;
    this.gen_part_conc_req = false;
    this.gen_male_req = false;
    this.ortho_fields = true;
    this.disabled = true;
    this.Orthodontic_Pane = true;
    this.Birth_Hist_Pane = true;
    this.Intra_Oral_Pane = false;
    this.Extraoral_Pane = false;
    this.Gnathic_Pane = true;
    this.Functional_Pane = true;
    this.Gums_Teeth_Pane = true;
    this.Dental_Hist_Pane = true;
    this.Birth_Hist_Pane = true;
    this.General_Hist_Pane = true;
    this.Natal_Hist_Pane = true;
    this.Pediatric_Spec_Pane = true;
    this.Endodontic_Hist_Pane = true;
    this.perio_specific = true;
    this.ortho_physical = true;
    this.vital_signs_public = true;
  }


  ngOnInit(): void {
    if(Doc_Helper.getAppFlow() == "client" ){
      this.client = true;
    }else{
      this.client = false;
    }

    this.subscription = this.dentalservice.getMessage().subscribe(message => {
      if (message) {
        
        // var data=message.spl
        //this.get_spl_name=message.spl;
        Doc_Helper.Set_dent_spl(message.spl);
        this.speciality_change(message.spl);
        //this.send_data(data);
      } else {
        // clear messages when empty message received
        var la = [];
        la = [];
      }
    });
    this.general_change();
    this.SiblingsChange();

    this.get_spl_name = Doc_Helper.get_dent_spl();
    if (this.get_spl_name == "Orthodontics") {
      this.Intra_Oral_Pane = false;
      this.Extraoral_Pane = false;
      this.Gnathic_Pane = false;
      this.Functional_Pane = false;
      this.Dental_Hist_Pane = true;
      this.Gums_Teeth_Pane = true;
      this.Birth_Hist_Pane = false;
      this.General_Hist_Pane = false;
      // this.ease_access_Txt_Div = true;
      this.Natal_Hist_Pane = false;
      this.Pediatric_Spec_Pane = true;
      this.Endodontic_Hist_Pane = true;
      this.perio_specific = true;
      this.ortho_physical = false;
      this.Natal_Hist = false;

    }
    this.RetrivalFunction()

  }

  @HostListener('window:click', ['$event'])
  onclick(event: any): void {
    this.send_data();
  }

  @HostListener('window:keyup', ['$event'])
  onkeyup(event: any): void {
    this.send_data();
  }
  SiblingsChange() {
    if (this.gen_sib == "Male") {
      this.MaleDisable = false;
      this.FeMaleDisable = true;
      this.gen_fem = "";

    }
    else if (this.gen_sib == "Female") {
      this.MaleDisable = true;
      this.FeMaleDisable = false;
      this.gen_male = "";
    }
    else if (this.gen_sib == "Male/Female") {
      this.MaleDisable = false;
      this.FeMaleDisable = false;
    }
    else {
      this.gen_male = "";
      this.gen_fem = "";
      this.MaleDisable = true;
      this.FeMaleDisable = true;
    }
  }


  RetrivalFunction() {    // retival
    // retival
    this.subscription = this.dentalservice.getMessage().subscribe(message => {
      if (message) {
        
        // var data = message.spl
        this.speciality_change(message.spl);
        Doc_Helper.Set_dent_spl(message.spl)
        

      } else {
        // clear messages when empty message received
        var la = [];
        la = [];
      }
    });
    var getret1 = Helper_Class.Get_den_ret1()
    var getret2 = Helper_Class.Get_den_ret2()
    
    if (getret2 != undefined) {

      //functional
      //  if (getret2.father_name != null) {
      //   this.gen_fat_name = getret2.father_name;
      // }
      // if (getret2.mother_name != null) {
      //   this.gen_mot_name = getret2.mother_name;
      // }
      // if (getret2.marriage != null) {
      //   this.gen_marriage = getret2.marriage;
      // }
      // if (getret2.par_gen_dental_cond != null) {
      //   this.gen_pare = getret2.par_gen_dental_cond;
      // }
      // if (getret2.siblings != null) {
      //   this.gen_sib = getret2.siblings;
      // }
      // if (getret2.male != null) {
      //   this.gen_male = getret2.male;
      //   this.MaleDisable = false;
      // }
      // if (getret2.female != null) {
      //   this.gen_fem = getret2.female;
      //   this.FeMaleDisable = false;
      // }
      // if (getret2.sib_gen_dental_cond != null) {
      //   this.gen_sib_gen = getret2.sib_gen_dental_cond;
      // }
      // if (getret2.familial_disease != null) {
      //   this.gen_history_fami = getret2.familial_disease;
      // }
      // if (getret2.ortho_treat != null) {
      //   this.his_ortho = true;

      // }

      //functional
      if (getret2.father_name != null) {
        this.gen_fat_name = getret2.father_name;
      }
      if (getret2.mother_name != null) {
        this.gen_mot_name = getret2.mother_name;
      }
      if (getret2.marriage != null) {
        this.gen_marriage = getret2.marriage;
      }
      if (getret2.par_gen_dental_cond != null) {
        this.gen_pare = getret2.par_gen_dental_cond;
      }
      if (getret2.siblings != null) {
        this.gen_sib = getret2.siblings;
      }
      if (getret2.male != null) {
        this.gen_male = getret2.male;
        this.MaleDisable = false;
      }
      if (getret2.female != null) {
        this.gen_fem = getret2.female;
        this.FeMaleDisable = false;
      }
      if (getret2.sib_gen_dental_cond != null) {
        this.gen_sib_gen = getret2.sib_gen_dental_cond;
      }
      if (getret2.familial_disease != null) {
        this.gen_history_fami = getret2.familial_disease;
      }
      if (getret2.ortho_treat != null) {

       
          if(getret2.ortho_treat == "yes")
          { 
            this.his_ortho1 = true;
            this.his_ortho = "yes";

          }
         
        
        else {
          this.his_ortho1 = false;
          this.his_ortho = "no";
        }
      }

      if (getret2.type_appliance != null) {
        this.gen_type_app = getret2.type_appliance;
      }

      if (getret2.duration_treatment != null) {
        this.gen_dur_treat = getret2.duration_treatment;
      }
      if (getret2.reason_disc != null) {
        this.gen_rea_dis = getret2.reason_disc;
      }
      if (getret2.concern_ortho != null) {
        this.gen_pare_con = getret2.concern_ortho;
      }
      if (getret2.concern_ortho != null) {
        this.gen_pare_con = getret2.concern_ortho;
      }
      if (getret2.past_den_hist != null) {
        this.gen_past_dent = getret2.past_den_hist;
      }
      if (getret2.care_type != null) {
        this.gen_home = getret2.care_type;
      }
      if (getret2.his_orthodontic_treatment != null && getret2.his_orthodontic_treatment == "yes") {
        this.his_ortho = true;
        this.ortho_fields = false;
      }
      if (getret2.den_lip_post_id != null) {
        // this.lip_posture = getret2.den_lip_post_id;
      }


    }
  }
  general_change() {
    if (this.get_spl_name == "Orthodontics") {
      if ((this.gen_fat_name != undefined && this.gen_fat_name.length != 0) || (this.gen_mot_name != undefined && this.gen_mot_name.length != 0) ||
        this.gen_marriage != undefined || this.gen_sib != undefined || (this.gen_sib_gen != undefined && this.gen_sib_gen.length != 0) || (this.gen_pare != undefined && this.gen_pare.length != 0) || (this.gen_type_app != undefined && this.gen_type_app.length != 0) || (this.gen_history_fami != undefined && this.gen_history_fami.length != 0) ||
        (this.gen_past_dent != undefined && this.gen_past_dent.length != 0) || (this.gen_dur_treat != undefined && this.gen_dur_treat.length != 0) || (this.gen_home != undefined && this.gen_home.length.length != 0) || (this.gen_rea_dis != undefined && this.gen_rea_dis.length != 0) || (this.gen_pare_con != undefined && this.gen_pare_con.length != 0)) {

        if (this.gen_sib != undefined && this.gen_sib == "Male") {
          this.gen_male_req = true;

        } else if (this.gen_sib != undefined && this.gen_sib == "Female") {
          this.gen_female_req = true;

        } else if (this.gen_sib != undefined && this.gen_sib == "Male/Female") {
          this.gen_female_req = true;
          this.gen_male_req = true;

        } else {
          this.gen_male_req = false;
          this.gen_female_req = false;
          this.gen_gen_den_req = false;
        }

        this.gen_gen_den_req = true;
        this.gen_fat_name_req = true;
        this.gen_mot_name_req = true;
        this.gen_marriage_req = true;
        this.gen_part_gen_dent_req = true;
        this.gen_type_app_req = true;
        this.gen_du_treat_req = true;
        this.gen_home_care_req = true;
        this.gen_reason_req = true;
        this.gen_part_conc_req = true;

      } else {
        this.gen_gen_den_req = false;
        this.gen_fat_name_req = false;
        this.gen_mot_name_req = false;
        this.gen_marriage_req = false;
        this.gen_part_gen_dent_req = false;
        this.gen_type_app_req = false;
        this.gen_du_treat_req = false;
        this.gen_home_care_req = false;
        this.gen_reason_req = false;
        this.gen_part_conc_req = false;
      }
    }
  }
  hist_prev_treat() {
    if (this.his_ortho == "yes" || this.his_ortho == "Yes") {
      this.ortho_fields = false;
    }
    else {
      this.ortho_fields = true;

    }
  }
  getColor(v) {
    if (v == "Yes") {
      return "green";
    }
    if (v = "No") {
      return "red";
    }
  }


  option(d) {
    this.ynoption = !this.ynoption;
    if (this.ynoption == true) {
      switch (d) {
        case 13: {
          this.ynopt13 = true;
          this.his_ortho = "yes";
          this.hist_prev_treat()
          //this.specific_flag=true;
          break;
        }
      }
    }
    if (this.ynoption == false) {
      switch (d) {
        case 13: {
          this.ynopt13 = false;
          this.his_ortho = "no";
          this.hist_prev_treat()
          //this.specific_flag=true;
          break;
        }

      }

    }
  }

  public gen_hist;
  send_data() {
    Doc_Helper.Set_den_spl_general(null);

    this.get_spl_name = Doc_Helper.get_dent_spl();
    //var gen_hist = null;
    //   this.general_change()
    var endo_treat = "no", child_visit = "no", diet_flag_arry = "no", ortho_treat = "no", any_req = "no", area_spec_dis = undefined, bleed_injury = "no", serious_operation = "no";
    if (this.his_ortho != undefined && this.his_ortho == "yes") {
      ortho_treat = "yes"
    }


    // var gen_hist = null;
    if (this.get_spl_name == "Orthodontics") {
      var post_ex = "no", pre_txt = "no", fam_flg = "no", birth_flag = "no", intra_orl = "no", intr_flg = "no", tmj_fun = "no", ho_pain = "no", ho_sound = "no", tmj_trend = "no", mus_trend = "no", physical_status_flag = "no";
      var hab_txt = "no", post_ex = "no", pre_txt = "no", fam_flg = "no", birth_flag = "no", intra_orl = "no", intr_flg = "no", tmj_fun = "no", ho_pain = "no", ho_sound = "no", tmj_trend = "no", mus_trend = "no", physical_status_flag = "no";
      if (this.gen_fat_name != undefined || this.gen_mot_name != undefined || this.gen_marriage != undefined || this.gen_sib != undefined || this.gen_sib_gen != undefined ||
        this.gen_history_fami != undefined || this.gen_pare != undefined || this.gen_male != undefined || this.gen_fem != undefined || this.gen_past_dent != undefined ||
        (this.his_ortho == true) || this.gen_type_app != undefined || this.gen_dur_treat != undefined || this.gen_home != undefined || this.gen_rea_dis != undefined ||
        this.gen_pare_con != undefined) {
        fam_flg = "yes";
      }
      this.gen_hist = {
        fam_exists: fam_flg,
        father_name: this.gen_fat_name,
        mother_name: this.gen_mot_name,
        marriage: this.gen_marriage,
        siblings: this.gen_sib,
        sib_dental_cond: this.gen_sib_gen,
        familial_disease: this.gen_history_fami,
        par_dental_cond: this.gen_pare,
        male: this.gen_male,
        female: this.gen_fem,
        past_den_hist: this.gen_past_dent,
        hsitory_previous: this.his_ortho,
        type_appliance: this.gen_type_app,
        duration_treat: this.gen_dur_treat,
        care_type: this.gen_home,
        reason_discont: this.gen_rea_dis,
        concern_ortho: this.gen_pare_con,
        ortho_treat: ortho_treat,

      }
      // Doc_Helper.Set_den_spl_general(this.gen_hist);

    }


    else if (this.get_spl_name == "Pediatrics") {
      var ped_flag = "no", diet_nalsis_flg = "no", ped_histflg = "no", gen_flag = "no", case_pedflg = "no", condition_txt = "", descrption_data = "", freq_data = "", inten_data = "", dure_dataoral = "", age_what = "", cond_suck = "";
      this.gen_hist = {
        int_oral_flag: intr_flg,
        ped_oth_flag: ped_flag,
        habit_exists: hab_txt,
        //   ped_per_hist_flag: ped_histflg,
        //ort_eval_exists: over_flg,
        //  gin_ped_flag: gen_flag,
        // softisoth_flag: soft_flag,
        case_ped_flag: case_pedflg,        //ped speci
        analysis_required: any_req,
        condition: condition_txt,
        problem: cond_suck,
        description: descrption_data,
        frequency: freq_data,
        duration: dure_dataoral,
        intensity: inten_data,
        age_habit_stop: age_what,
        diet_flag: diet_flag_arry,
        diet_analysis_flag: diet_nalsis_flg,
      }
      // Doc_Helper.Set_den_spl_general(this.gen_hist);

    }
    Doc_Helper.Set_den_spl_general(this.gen_hist);
    


  }


  speciality_change(e) {
    this.get_spl_name = "";
    this.get_spl_name = e;
    if (this.get_spl_name == "General") {
      
      this.Orthodontic_Pane = true;
      this.Birth_Hist_Pane = true;
      this.Intra_Oral_Pane = false;
      this.Extraoral_Pane = false;
      this.Gnathic_Pane = true;
      this.Functional_Pane = true;
      this.Gums_Teeth_Pane = true;
      this.Dental_Hist_Pane = true;
      this.General_Hist_Pane = true;
      this.Natal_Hist_Pane = true;
      this.Pediatric_Spec_Pane = true;
      this.Endodontic_Hist_Pane = true;
      this.perio_specific = true;
      this.ortho_physical = true;
      this.vital_signs_public = true;



    }
    else if (this.get_spl_name == "Periodontics") {


      this.Extraoral_Pane = false;
      this.Intra_Oral_Pane = false;


      this.Gnathic_Pane = true;
      this.Functional_Pane = true;
      this.Orthodontic_Pane = true;
      this.Gums_Teeth_Pane = false;
      this.Dental_Hist_Pane = true;
      this.Birth_Hist_Pane = true;
      this.General_Hist_Pane = true;
      this.Natal_Hist_Pane = true;
      this.Pediatric_Spec_Pane = true;
      this.Endodontic_Hist_Pane = true;
      this.perio_specific = false;


      this.vital_signs_public = true;


    }
    else if (this.get_spl_name == "Orthodontics") {
      this.Birth_Hist_Pane = false;
      this.ortho_physical = false;
      this.genTab = false;
      this.Intra_Oral_Pane = false;
      this.Extraoral_Pane = false;
      this.Gnathic_Pane = false;
      this.Functional_Pane = false;
      this.Dental_Hist_Pane = true;
      this.Gums_Teeth_Pane = true;
      //  this.Birth_Hist_Pane = false;
      this.General_Hist_Pane = false;
      this.Natal_Hist_Pane = false;
      this.Pediatric_Spec_Pane = true;
      this.Endodontic_Hist_Pane = true;
      this.perio_specific = true;
      this.ortho_physical = false;
      this.Natal_Hist = false;
      this.vital_signs_public = true;
      if (this.get_spl_name == "Pediatrics") {
        

        this.Birth_Hist_Pane = false;
        //this.post_txt=true;
        this.Natal_Hist = true;

        this.Pediatric_Spec_Pane = false;
        this.Orthodontic_Pane = false;
        this.Dental_Hist_Pane = false;
        this.perio_specific = true;

        this.Natal_Hist_Pane = false;
        this.ortho_physical = true;
        this.Intra_Oral_Pane = false;
        this.Extraoral_Pane = false;
        this.Gnathic_Pane = true;
        this.Functional_Pane = true;
        this.Gums_Teeth_Pane = true;
        this.vital_signs_public = true;
        this.Dental_Hist_Pane = false;
        //this.Birth_Hist_Pane = false;
        this.General_Hist_Pane = true;
        this.Natal_Hist_Pane = false;
        this.Pediatric_Spec_Pane = false;
        this.Endodontic_Hist_Pane = true;
        this.perio_specific = true;
        this.Natal_Hist = true;

      }


    }

  }
  public his_ortho1;
  changeprviousortho(e) {
    this.his_ortho1 = e;
    if (e == false) {
      //this.ill_operation_txt=true;
      this.his_ortho = "no";
      this.ortho_fields = true;
      //  this.hist_prev_treat();
      document.getElementById("his_ortho1").style.background = "red";

      // this.ho_sound_text = false;
    }
    else {
      // this.ill_operation_txt=false;
      this.his_ortho = "yes";
      this.hist_prev_treat();
      document.getElementById("his_ortho1").style.background = "green";

      // this.ho_sound_text = true;
    }

  }
}
