<div class="row">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Appointments details</h5>
          </div>
          <div class="headerButtons">
            <img class="video" src="../../../assets/ui_icons/video_icons.svg" style="width: 25px;" (click)="video()" />
            <img class="saveimgbtn_inpatinfo" (click)="back()" src="../../../assets/ui_icons/buttons/Back_button.svg" />
            <!-- <img class="saveimgbtn_inpatinfo" *ngIf="rateFlag" (click)="rating()"
              src="../../../assets/ui_icons/buttons/Rate_button.svg" /> -->
            <img class="saveimgbtn_inpatinfo" *ngIf="rescheduleFlag" (click)="reschedule_App()"
              src="../../../assets/ui_icons/buttons/reschedule_button.svg" />
            <img class="saveimgbtn_inpatinfo" *ngIf="cancelFlag" (click)="cancel_App()"
              src="../../../assets/ui_icons/buttons/cancel_button.svg" />
            <img class="saveimgbtn_inpatinfo" *ngIf="homecareRescheduleFlag" (click)="HomeResched_Book_Click()"
              src="../../../assets/ui_icons/buttons/Book_now.svg" />
            <img class="saveimgbtn_inpatinfo" *ngIf="availableFlag" (click)="Reschedule_Book()"
              src="../../../assets/ui_icons/buttons/Book_now.svg" />
            <img class="saveimgbtn_inpatinfo" *ngIf="payFlag" (click)="payWithRazor()"
              src="../../../assets/ui_icons/buttons/pay_button.svg" />
            <!-- <img class="video_btn" src="../../../assets/img/video.png" (click)="videocall()" /> -->
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="cover_div" style="padding-bottom: 13px;">
          <div class="header_lable">
            Personal details
          </div>
          <div class="content_cover">
            <div class="row">
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label style="margin-top: 7px;" class="matlabel">Appointment for
                  <input type="text" class="ipcss " [(ngModel)]="clntRelation" disabled matInput />
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label style="margin-top: 7px;" class="matlabel">First name
                  <input type="text" class="ipcss " [(ngModel)]="clntFName" disabled matInput />
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label style="margin-top: 7px;" class="matlabel">Middle name
                  <input type="text" class="ipcss " [(ngModel)]="clntMName" disabled matInput />
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label style="margin-top: 7px;" class="matlabel">Last name
                  <input type="text" class="ipcss " [(ngModel)]="clntLName" disabled matInput />
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Age
                  <input type="text" class="ipcss " [(ngModel)]="clntAge" disabled matInput />
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Gender
                  <input type="text" class="ipcss " [(ngModel)]="clntGender" disabled matInput />
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Blood Group
                  <input type="text" class="ipcss " [(ngModel)]="clntBloodGrp" disabled matInput />
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Father/Guardian
                  <input type="text" class="ipcss " [(ngModel)]="clntGuardian" disabled matInput />
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Income
                  <input type="text" class="ipcss " [(ngModel)]="clntIncome" disabled matInput />
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Occupation
                  <input type="text" class="ipcss " [(ngModel)]="clntOccupation" disabled matInput />
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Phone
                  <input type="text" class="ipcss " [(ngModel)]="clntMobile" disabled matInput />
                </mat-label>
              </div>
            </div>
          </div>
        </div>
        <div class="cover_div" style="padding-bottom: 13px;">
          <div class="header_lable">
            Appointment details
          </div>
          <div class="content_cover">
            <div class="row">
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Treatment type
                  <input type="text" class="ipcss " [(ngModel)]="clntTreatType" disabled matInput />
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Speciality
                  <input type="text" class="ipcss " [(ngModel)]="speciality" disabled matInput />
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Doctor
                  <input type="text" class="ipcss " [(ngModel)]="doctorName" disabled matInput />
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Referred by
                  <input type="text" class="ipcss " [(ngModel)]="referredBy" disabled matInput />
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Payment mode
                  <input type="text" class="ipcss " [(ngModel)]="payType" disabled matInput />
                </mat-label>
              </div>
              <div *ngIf="!hospitalFlag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label *ngIf="!hospitalFlag" class="matlabel">Personal details
                  <textarea class="ipcss " [(ngModel)]="personalDetails" disabled></textarea>
                </mat-label>
              </div>
              <div *ngIf="hospitalFlag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label *ngIf="hospitalFlag" class="matlabel">Hospital details
                  <textarea class="ipcss " [(ngModel)]="hospitalDetails" disabled></textarea>
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Appointment details
                  <textarea class="ipcss " [(ngModel)]="apptDetail" disabled></textarea>
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Symptoms
                  <textarea class="ipcss " [(ngModel)]="clntSymptom" disabled></textarea>
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Reason
                  <textarea class="ipcss " [(ngModel)]="clntReason" disabled></textarea>
                </mat-label>
              </div>
            </div>
          </div>
        </div>
        <div class="cover_div" style="padding-bottom: 13px;" *ngIf="heightFlag">
          <div class="header_lable">
            Observation
          </div>
          <div class="content_cover">
            <div class="row">
              <div *ngIf="heightFlag" class="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-2">
                <mat-label *ngIf="heightFlag" class="matlabel">Height
                  <input type="text" class="ipcss " [(ngModel)]="height" disabled matInput />
                </mat-label>
              </div>
              <div *ngIf="weightFlag" class="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-2">
                <mat-label *ngIf="weightFlag" class="matlabel">Weight
                  <input type="text" class="ipcss " [(ngModel)]="weight" disabled matInput />
                </mat-label>
              </div>
              <div *ngIf="temperatureFlag" class="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-2">
                <mat-label *ngIf="temperatureFlag" class="matlabel">Temperature
                  <input type="text" class="ipcss " [(ngModel)]="temp" disabled matInput />
                </mat-label>
              </div>
              <div *ngIf="bmiFlag" class="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-2">
                <mat-label *ngIf="bmiFlag" class="matlabel">BMI
                  <input type="text" class="ipcss " [(ngModel)]="bmi" disabled matInput />
                </mat-label>
              </div>
              <div *ngIf="complaintsFlag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label *ngIf="complaintsFlag" class="matlabel">Complaints
                  <input type="text" class="ipcss " [(ngModel)]="complaints" disabled matInput />
                </mat-label>
              </div>
              <div *ngIf="illnessFlag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label *ngIf="illnessFlag" class="matlabel">Present illness
                  <input type="text" class="ipcss " [(ngModel)]="illnes" disabled matInput />
                </mat-label>
              </div>
              <div *ngIf="medicalFlag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label *ngIf="medicalFlag" class="matlabel">Medical history
                  <input type="text" class="ipcss " [(ngModel)]="medical" disabled matInput />
                </mat-label>
              </div>
              <div *ngIf="familyFlag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label *ngIf="familyFlag" class="matlabel">Family history
                  <input type="text" class="ipcss " [(ngModel)]="family" disabled matInput />
                </mat-label>
              </div>
              <div *ngIf="physicalFlag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label *ngIf="physicalFlag" class="matlabel">Physical examination
                  <input type="text" class="ipcss " [(ngModel)]="phyExam" disabled matInput />
                </mat-label>
              </div>
            </div>
          </div>
        </div>
        <div class="cover_div" style="padding-bottom: 13px;" *ngIf="normalRescheduleFlag">
          <div class="header_lable">
            Reschedule
          </div>
          <div class="content_cover">
            <div class="row">
              <div class="col-12">
                <div class="table-responsive dig_table_overflow">
                  <table id="tbl" class="table table-hover table-dynamic">
                    <thead>
                      <tr>
                        <th>Hospital name</th>
                        <th>Day</th>
                        <th>Session</th>
                        <th>Starts from</th>
                        <th>Ends at</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let doctor of doctorDetails">
                        <td data-th="Hospital Name">{{doctor.hospital}}</td>
                        <td data-th="Day">{{doctor.workdays}}</td>
                        <td data-th="Session">{{doctor.day_session}}</td>
                        <td data-th="Starts from">{{doctor.available_from}}</td>
                        <td data-th="Ends at">{{doctor.available_to}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Select date
                  <input type="date" class="ipcss " [(ngModel)]="appointmentDate"
                    (change)="selectApptDate(appointmentDate)" min={{currentMaxDate}} matInput />
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" *ngIf="availableFlag">
                <mat-label class="matlabel">Select session<br>
                  <mat-select [(ngModel)]="visitSession" disableOptionCentering class="ipcss "
                    (ngModelChange)="visit_Session_Change(visitSession, appointmentDate)">
                    <mat-option disabled>Select</mat-option>
                    <mat-option *ngFor="let session of visitingSessionData" value={{session.description}}>
                      {{session.description}}</mat-option>
                  </mat-select>
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Appointment {{appType}} :</mat-label>
                <div class="row">
                  <div class="col-12">
                    <mat-label *ngIf="appTypeTokenFlag" class="matlabel">Token<br></mat-label>
                    <mat-select [(ngModel)]="appointmentTime" *ngIf="!appTypeTokenFlag" disableOptionCentering
                      class="ipcss ">
                      <mat-option disabled>Select</mat-option>
                      <mat-option *ngFor="let time of timeList" value={{time}}>{{time}}</mat-option>
                    </mat-select>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Reason for reschedule
                  <textarea class="ipcss " [(ngModel)]="rescheduleReason"></textarea>
                </mat-label>
              </div>
              <div class="col-12" *ngIf="availableFlag">
                <mat-label class="matlabel" *ngIf="Unavailable">Sorry already booked</mat-label>
              </div>
              <div class="col-12" *ngIf="nonAvailableFlag">
                <mat-label class="matlabel" *ngIf="Unavailable">Please check doctor's availability</mat-label>
              </div>
            </div>
          </div>
        </div>
        <div class="cover_div" style="padding-bottom: 13px;" *ngIf="homecareRescheduleFlag">
          <div class="header_lable">
            Reschedule
          </div>
          <div class="content_cover">
            <div class="row">
              <div class="col-12">
                <div class="table-responsive dig_table_overflow">
                  <table id="tbl" class="table table-hover table-dynamic">
                    <thead>
                      <tr>
                        <th>Session</th>
                        <th>Starts from</th>
                        <th>Ends at</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let doctor of homecareDoctorDetails">
                        <td data-th="Session">{{doctor.day_session}}</td>
                        <td data-th="Starts from">{{doctor.available_from}}</td>
                        <td data-th="Ends at">{{doctor.available_to}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Select date
                  <input type="date" class="ipcss_date widthappt" id="appt_date"
                    (change)="selectApptDate(appointmentDate)" [(ngModel)]="appointmentDate" max="{{currentMaxDate}}"
                    #matInput style="width: 140px;">
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Select session<br>
                  <mat-select [(ngModel)]="visitHomeSession" disableOptionCentering class="ipcss "
                    (ngModelChange)="HomeSession_Change(visitHomeSession, appointmentDate)">
                    <mat-option disabled>Select</mat-option>
                    <mat-option *ngFor="let session of homecareSessionData" value={{session.description}}>
                      {{session.description}}</mat-option>
                  </mat-select>
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Appointment system</mat-label>
                <div class="row">
                  <div *ngIf="appTypeTokenFlag" class="col-12">
                    <mat-label class="matlabel" *ngIf="appTypeTokenFlag">Token<br>
                      <mat-select [(ngModel)]="visitHomeTime" disableOptionCentering class="ipcss ">
                        <mat-option disabled>Select</mat-option>
                        <mat-option *ngFor="let time of homecareTimeList" value={{time}}>{{time}}</mat-option>
                      </mat-select>
                    </mat-label>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Reason for reschedule
                  <input type="text" class="ipcss " [(ngModel)]="rescheduleReason" matInput />
                </mat-label>
              </div>
            </div>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>