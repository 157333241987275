import { Component, OnInit, HostListener, Input } from '@angular/core';
import { Helper_Class } from '../../helper_class';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { Observable, observable, Subscription } from 'rxjs';
import { pediatric_graph } from '../../../assets/js/Pediatric_Graph';
import { Doc_Helper } from '../Doc_Helper';
import { CasesheetService } from '../casesheet/casesheet.service';
import { DiabeticpopuphistoryComponent } from '../diabeticpopuphistory/diabeticpopuphistory.component';
import { MatDialog } from '@angular/material/dialog';
import { PediatricGraphPopupComponent } from '../pediatric-graph-popup/pediatric-graph-popup.component';
import { ipaddress } from '../../ipaddress' ;import { Message_data } from 'src/assets/js/Message_data';
declare var $:any;

@Component({
  selector: 'app-pediatrics-vitals',
  templateUrl: './pediatrics-vitals.component.html',
  styleUrls: ['./pediatrics-vitals.component.css']
})
export class PediatricsVitalsComponent implements OnInit {

  public pediavitalFlag = "min";
  subscription: Subscription;
  public minflag: boolean = true;
  public Height_measure;
  public weight_measure;
  public header_footer_flag: boolean;

  public Height_txt;
  public weight_txt;
  public BP_01;
  public BP_02;
  public Heart_Beat;
  public Respiratory_rate;
  public Temperature;
  public At_birth: boolean;
  public birthvital;
  public life_style_id;
  public vital_history_data = [];
  public Vitalhist;
  public diab_case_hist_id;
  public vital_graph: boolean;
  public height_hidden: boolean;
  public height_measurehidden: boolean;
  public weight_hidden: boolean;
  public weight_measurehidden: boolean;
  public heart_hidden: boolean;
  public heart_rate: boolean;
  public respi_rate: boolean;
  public respi_hidden: boolean;
  public temp_hidden: boolean;
  public blood_hidden: boolean;
  public atbirth_hidden: boolean;

  public Height_Chart_Unavail: boolean;
  public Weight_Chart_Unavail: boolean;
  public Unavail_Temp_Chart: boolean;
  public Heart_Chart_Unavail: boolean;
  public Height_zoomhidden: boolean;
  public temp_zoomhidden: boolean;
  public weight_zoomhidden: boolean;
  public Heart_zoomhidden: boolean;

  public patient_list;
  public userinfo;
  public user_id;
  public client: boolean;
  public userlist;
  constructor(public dialog: MatDialog, public messageservice: CasesheetService, private http: Http, private routes: ActivatedRoute, private router: Router, public toastr: ToastrService) {
    this.vital_graph = false;
    this.height_hidden = true;
    this.height_measurehidden = true;
    this.weight_hidden = true;
    this.weight_measurehidden = true;
    this.heart_hidden = true;
    this.respi_hidden = true;
    this.temp_hidden = true;
    this.blood_hidden = true;
    this.atbirth_hidden = true;
    this.Height_Chart_Unavail = false;
    this.Unavail_Temp_Chart = false;
    this.Weight_Chart_Unavail = false;
    this.Heart_Chart_Unavail = false;
    this.temp_zoomhidden = true;
    this.Height_zoomhidden = true;
    this.weight_zoomhidden = true;
    this.Heart_zoomhidden = true;
    this.heart_rate = false;
    this.respi_rate = false;
  }

  ngOnInit(): void {
    this.subscription = this.messageservice.getCasesheetMessage().subscribe(message => {
      if (message) {
        this.pediavitalFlag = message;
        if (this.pediavitalFlag == "max") {
          if (Doc_Helper.getAppFlow() == "client") {
            this.patient_list = Helper_Class.getInfo();
            this.client = true;
          } else {
            this.patient_list = Doc_Helper.getClient_Info();
            this.client = false;
          }

          var getHopitalfieldsRet = Helper_Class.getHopitalfieldsRet();
          if (getHopitalfieldsRet != undefined) {
            for (var i = 0; i < getHopitalfieldsRet.length; i++) {
              if (getHopitalfieldsRet[i].page_name == "Vitals") {
                if (getHopitalfieldsRet[i].fields != undefined) {
                  if (getHopitalfieldsRet[i].fields != undefined) {
                    for (var j = 0; j < getHopitalfieldsRet[i].fields.length; j++) {
                      if (getHopitalfieldsRet[i].fields[j].field_name == "Height") {
                        this.height_hidden = false;
                        this.height_measurehidden = false;
                      }
                      if (getHopitalfieldsRet[i].fields[j].field_name == "Weight") {
                        this.weight_hidden = false;
                        this.weight_measurehidden = false;
                      }
                      if (getHopitalfieldsRet[i].fields[j].field_name == "Heart beat") {
                        this.heart_hidden = false;
                      }
                      if (getHopitalfieldsRet[i].fields[j].field_name == "Respiratory rate") {
                        this.respi_hidden = false;
                      }
                      if (getHopitalfieldsRet[i].fields[j].field_name == "Temperature") {
                        this.temp_hidden = false;
                      }
                      if (getHopitalfieldsRet[i].fields[j].field_name == "atbirth") {
                        this.atbirth_hidden = false;
                      }
                      if (getHopitalfieldsRet[i].fields[j].field_name == "Blood pressure") {
                        this.blood_hidden = false;
                      }
                    }
                  }
                }
              }
            }
          }
          this.Height_measure = "cms";
          this.weight_measure = "kgs";
          this.Get_commom_retrival();
        }
      }
    });
    this.userlist = Doc_Helper.getClient_Info();
    console.log("check data 000"+JSON.stringify(this.userlist))
    this.life_style_id=this.userlist.life_style_id
  }

  Get_commom_retrival() {
    var getpediaasg = Helper_Class.getAsg();
    
    if (getpediaasg != undefined) {
      if (getpediaasg.life_style_id != null) {
        this.diab_case_hist_id = getpediaasg.life_style_id;
      }
      if (getpediaasg.life_style_id != null) {
        this.life_style_id = getpediaasg.life_style_id;
        this.Get_Pediatric_Graph_Data(this.life_style_id, this.patient_list.Case_Clnt_dob);
      }
    }
  }

  // reading poup window
  reading_poup(month_data, graph_id) {
    const dialogRef5 = this.dialog.open(PediatricGraphPopupComponent, {
      width: '700px',
      height: 'auto',
      data: {
        graph_id: graph_id,
        month: month_data,
        life_style: this.life_style_id,
        dob:this.patient_list.Case_Clnt_dob,
        gender:this.patient_list.Gender_data
      }
    });

    dialogRef5.afterClosed().subscribe(result => {});
  }

  //Graphs
  Get_Pediatric_Graph_Data(lifes_ID, Clnt_DOB) {
    this.vital_history_data = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'pedia/vhist',
      JSON.stringify({
        life_style_id: lifes_ID
      }),
      { headers: headers })
      .subscribe(
        response => {
          
          var obj = response.json();
          var Datelist = new Array();
          var Height_Datelist = new Array();
          var BP_list = new Array();
          var Heart_Rate_list = new Array();
          var Respiratory_list = new Array();
          var Temparature_list = new Array();
          var Height_list = new Array();
          var HeightMeasure_List = new Array();
          var Weight_list = new Array();
          var WeightMeasure_List = new Array();
          var At_Birth_List = new Array();
          var Age_List = new Array();

          if (obj.vital_history != null && obj.vital_history.length != 0) {
            this.vital_graph = true;
            for (var i = 0; i < obj.vital_history.length; i++) {
              if (obj.vital_history[i].vital_created_date != null) {
                this.vital_history_data.push({
                  blood_pres: obj.vital_history[i].blood_pressure,
                  respi_rate: obj.vital_history[i].respiratory_rate,
                  created_date: obj.vital_history[i].vital_created_date,
                });

                obj.vital_histbtn = this.vital_history_data.length != 0 ? true : false;
                Datelist.push(obj.vital_history[i].vital_created_date.toString());

                if (obj.vital_history[i].heart_rate != null && obj.vital_history[i].heart_rate != "") {
                  Heart_Rate_list.push(obj.vital_history[i].heart_rate.toString());
                }
                if (obj.vital_history[i].heart_rate == null && obj.vital_history[i].heart_rate == "") {
                  Heart_Rate_list.push("");
                  this.heart_rate = true;
                }

                if (obj.vital_history[i].temparature != null && obj.vital_history[i].temparature != "") {
                  Temparature_list.push(obj.vital_history[i].temparature.toString());
                }
                if (obj.vital_history[i].temparature == null && obj.vital_history[i].temparature == "") {
                  Temparature_list.push("");
                  this.respi_rate = true;
                }

                if (obj.vital_history[i].blood_pressure != null) {
                  BP_list.push(obj.vital_history[i].blood_pressure.toString());
                } else {
                  BP_list.push("-");
                }

                if (obj.vital_history[i].respiratory_rate != null) {
                  Respiratory_list.push(obj.vital_history[i].respiratory_rate.toString());
                } else {
                  Respiratory_list.push("-");
                }
              }

              if (obj.vital_history[i].hw_created_date != null) {
                Height_Datelist.push(obj.vital_history[i].hw_created_date.toString());

                if (obj.vital_history[i].height_measure.toString() == "inch") {
                  Height_list.push((parseInt(obj.vital_history[i].height.toString()) * 100));
                  HeightMeasure_List.push(obj.vital_history[i].height_measure.toString());
                } else {
                  Height_list.push(obj.vital_history[i].height.toString());
                  HeightMeasure_List.push(obj.vital_history[i].height_measure.toString());
                }

                if (obj.vital_history[i].weight_measure.toString() == "lbs") {
                  Weight_list.push(Math.round(parseFloat((obj.vital_history[i].weight.toString() / 2.20462, 2).toString())));
                  WeightMeasure_List.push(obj.vital_history[i].weight_measure.toString());
                } else {
                  Weight_list.push(obj.vital_history[i].weight.toString());
                  WeightMeasure_List.push(obj.vital_history[i].weight_measure.toString());
                }
                At_Birth_List.push(obj.vital_history[i].at_birth.toString());
              }

              if (obj.vital_history[i].age != null) {
                Age_List.push(obj.vital_history[i].age.toString());
              } else {
                Age_List.push("");
              }
            }

            this.Height_Chart_Unavail = true;
            this.Weight_Chart_Unavail = true;
            this.Unavail_Temp_Chart = this.respi_rate != true ? true : false;
            this.Heart_Chart_Unavail = this.heart_rate != true ? true : false;

            if (this.heart_rate != true) {
              this.Heart_zoomhidden = true;
            } else {
              this.temp_zoomhidden = true;
            }

            this.Height_zoomhidden = false;
            this.temp_zoomhidden = false;
            this.weight_zoomhidden = false;
            this.Heart_zoomhidden = false;

            pediatric_graph.Pediatric_Graph(Datelist, Height_list, Weight_list, Heart_Rate_list, Temparature_list, At_Birth_List, Age_List, Height_Datelist, Clnt_DOB, this.patient_list.Gender_data, "New");

          } else {
            this.Height_Chart_Unavail = false;
            this.Unavail_Temp_Chart = false;
            this.Weight_Chart_Unavail = false;
            this.Heart_Chart_Unavail = false;
            this.temp_zoomhidden = true;
            this.Height_zoomhidden = true;
            this.weight_zoomhidden = true;
            this.Heart_zoomhidden = true;
          }
        },
        error => {});
  }

  vital_historytemp() {//pediatrics vital history click
    this.Vitalhist = "Vitalhist";
    const dialogRef5 = this.dialog.open(DiabeticpopuphistoryComponent, {
      width: '700px',
      height: '300px',
      data: {
        Diab_case_hist_id: this.diab_case_hist_id,
        Life_style_id: this.life_style_id,
        History: this.Vitalhist
      }
    });

    dialogRef5.afterClosed().subscribe(result => {});
  }

  save_click() {
    var chk_read_flag = true;
    var Send_data = null;
    var pedia_case_hist_id = undefined;
    var bpvar: any;
    var lif_stl_id = undefined;

    if (this.Height_txt == undefined) {
      chk_read_flag = false;
      this.toastr.error(Message_data.mandatory);
    }

    if (this.Height_measure == undefined) {
      chk_read_flag = false;
      this.toastr.error(Message_data.mandatory);
    }

    if (this.weight_txt == undefined) {
      chk_read_flag = false;
      this.toastr.error(Message_data.mandatory);
    }

    if (this.weight_measure == undefined) {
      chk_read_flag = false;
      this.toastr.error(Message_data.mandatory);
    }

    if (Helper_Class.getRet() != undefined) {
      if (Helper_Class.getRet().life_style_id != null) {
        lif_stl_id = Helper_Class.getRet().life_style_id;
      }
      if (Helper_Class.getRet().case_hist_id != null) {
        pedia_case_hist_id = Helper_Class.getRet().case_hist_id;
      }
    }else{
      this.userlist = Doc_Helper.getClient_Info();
      console.log("check data 000"+JSON.stringify(this.userlist))
      lif_stl_id=this.userlist.life_style_id
    }

    this.birthvital = this.At_birth == true ? "1" : "0";
    bpvar = this.BP_01 != undefined && this.BP_02 != undefined ? this.BP_01 + "/" + this.BP_02 : null;

    if (this.patient_list.sub_id != "" && this.patient_list.sub_id.length != 0) {
      Send_data = {
        client_reg_id: this.patient_list.Client_id,
        relation_id: this.patient_list.rel_id,
        sub_rel_id: this.patient_list.sub_id,
        life_style_id: lif_stl_id,
        country: ipaddress.country_code,
        height: this.Height_txt,
        height_measure: this.Height_measure,
        weight: this.weight_txt,
        weight_measure: this.weight_measure,
        blood_pressure: bpvar,
        heart_rate: this.Heart_Beat,
        respiratory_rate: this.Respiratory_rate,
        temparature: this.Temperature,
        at_birth: this.birthvital,
        case_hist_id: pedia_case_hist_id,
      };
    }
    else {
      Send_data = {
        client_reg_id: this.patient_list.Client_id,
        relation_id: this.patient_list.rel_id,
        life_style_id: lif_stl_id,
        country: ipaddress.country_code,
        height: this.Height_txt,
        height_measure: this.Height_measure,
        weight: this.weight_txt,
        weight_measure: this.weight_measure,
        blood_pressure: bpvar,
        heart_rate: this.Heart_Beat,
        respiratory_rate: this.Respiratory_rate,
        temparature: this.Temperature,
        at_birth: this.birthvital,
        case_hist_id: pedia_case_hist_id,
      };
    }
    console.log(" "+JSON.stringify(Send_data))
    if (chk_read_flag == true) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'pedia/svital', JSON.stringify(Send_data),
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            if (obj.key != null) {
              if (obj.key == "1") {
                this.toastr.success(Message_data.vitalSavedSuccess);
                this.Get_Pediatric_Graph_Data(obj.life_style_id, this.patient_list.Case_Clnt_dob);
              } else {
                this.toastr.error(Message_data.vitalNotSavedSuccess);
              }
            }
          },
          error => {
            this.toastr.error(Message_data.defaultErr);
          }
        )
    } else {
      this.toastr.error(Message_data.mandatory);
    }
  }
}
