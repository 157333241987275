
<div class="row" style="width:100%;margin:0 auto;">
    <div class="col-12">
      <mdb-card>
        <mdb-card-header class="bg-white">
          <div class="headerCover">
            <div class="headerTilte app_list_header">
              <h5 class="m-0" class="mainHeadingStyle">Sales Report</h5>
            </div>
            <div class="headerButtons">
             
            </div>
          </div>
          
        </mdb-card-header>
        <div class="d-flex justify-content-end" style="width:95%;" *ngIf="backbutton">
            
          <img src="../../../assets/ui_icons/buttons/Back_button.svg" width="85px" (click)="backpage()">
          </div>
        <mdb-card-body>
          <div class="cover_div" style="padding-bottom: 13px;">
            <div class="header_lable">
              Salse report
            </div>
            <div class="content_cover">
              <div class="row">
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                  <mat-label class="matlabel" style="margin-top: 7px;">Order type<br>
                    <select disableOptionCentering class="ipcss " [(ngModel)]="reportType">
                      <option value="OTC">OTC</option>
                      <option value="Non-OTC">Non-OTC</option>
                    </select>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                  <mat-label class="matlabel">Payment type<br>
                    <mat-select id="paytype" class="ipcss  widthbillcreate" [(ngModel)]="payType">
                      <mat-option *ngFor="let pay of payTypeListArrray" value={{pay.pay_id}}>{{pay.pay_desc}}</mat-option>
                    </mat-select>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                  <mat-label class="matlabel" style="margin-top: 7px;">From
                    <input type="date" class="ipcss_date " (change)="dateSelect(fromDate,'1')" [(ngModel)]="fromDate"
                      max="{{currentDate}}" #matInput style="width: 140px;">
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                  <mat-label class="matlabel" style="margin-top: 7px;">To
                    <input type="date" class="ipcss_date " (change)="dateSelect(toDate,'2')" [(ngModel)]="toDate"
                      max="{{currentDate}}" #matInput style="width: 140px;">
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                  <mat-label class="matlabel"><br>
                    <img src="../../../assets/ui_icons/buttons/search_button.svg" class="saveimgbtn_inpatinfo"
                      (click)="getSalesData('otc')" />
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                  <mat-label class="matlabel"><br>
                    <img src="../../../assets/ui_icons/buttons/XL_export.svg" class="saveimgbtn_inpatinfo"
                      (click)="getExcelData('excel')" />
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 my-auto">
                  <mat-label class="matlabel" style="margin-top: 7px;"><span style="font-weight: 600;">Sales Amount : </span> {{totalAmount}}
                    
                  </mat-label>
                </div>
              </div>
            </div>
          </div>
          <p class="nodata" *ngIf="salesArray.length ==0">No records found</p>
          <div class="table-responsive dig_table_overflow" style="margin-top: 10px;" [hidden]="hasData">
            <table  id="tbl" class="table table-hover table-dynamic"
              style="font-size: 12px; width: 100%;margin-left: 5px;">
              <thead>
                <tr>
                  <th>Bill no</th>
                  <th>Buyer</th>
                  <th>Created by</th>
                  <th>Purchase type</th>
                  <th>Bill date</th>
                  <th>Amount</th>
                  <th>Balance</th>
                  <th>Payment Status</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let sales of salesArray">
                  <td>{{sales.order_id}}</td>
                  <td class="align_left">{{sales.name}}</td>
                  <td class="align_left">{{sales.created_by}}</td>
                  <td>{{sales.order_type}}</td>
                  <td>{{sales.order_date}}</td>
                  <td style="text-align: right;">{{sales.amount}}</td>
                  <td style="text-align: right;">{{sales.balance}}</td>
                  <td>{{sales.paid}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </mdb-card-body>
      </mdb-card>
    </div>
  </div>
