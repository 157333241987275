<div class="row" style="margin:0 auto; width:100%">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Book appointment</h5>
                    </div>
                    <div class="headerButtons">
                        <img class="save_btn" *ngIf="bookFlag" (click)="app_Book_now()"
                            src="../../../assets/ui_icons/buttons/Book_now.svg" width="85px"
                            style="margin-top: 10px;position: absolute; top: 6px; right: 4px;" />
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>
                <div class="row">
                    <div class="cover_div" style="padding-bottom: 13px;margin-bottom: 25px;">
                        <div class="header_lable">
                            Personal information
                        </div>
                        <div class="content_cover">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <mat-label class="matlabel">Appointment Type</mat-label>&nbsp;&nbsp;&nbsp;&nbsp;
                                    <mat-radio-group class="dietradio" labelPosition="before"
                                        aria-label="Select an option" color="primary" [(ngModel)]="appType">
                                        <mat-radio-button style="width: 70px;" name="apptype" value="New">New
                                        </mat-radio-button>
                                        <mat-radio-button style="width: 70px;" name="apptype" value="Follow-up">
                                            &nbsp;&nbsp;Follow-up
                                        </mat-radio-button>
                                    </mat-radio-group><br><br>
                                </div>
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <div class="row">
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <mat-label class="matlabel">Appointment For &nbsp;&nbsp;
                                                <mat-select required class="ipcss " name="relationship"
                                                    [(ngModel)]="relationship" disableOptionCentering
                                                    (ngModelChange)="getSltRelationType($event)">
                                                    <mat-option *ngFor="let relation of relationList"
                                                        value="{{relation.relationship_name}}">
                                                        {{relation.relationship_name}}</mat-option>
                                                </mat-select>
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" [hidden]="relationFlag">
                                            <mat-label class="matlabel">Relation name
                                                <select class="ipcss " required [(ngModel)]="relationName"
                                                    (click)="getSltRelationDetails(relationName)">
                                                    <option disabled>Select</option>
                                                    <option *ngFor="let relation of relationsList"
                                                        value={{relation.cln_rel_id}}>
                                                        {{relation.name}}</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                    <mat-label class="matlabel">First name
                                        <input class="ipcss " matInput type="text" [(ngModel)]="clntFirstName" />
                                    </mat-label>
                                </div>
                                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                    <mat-label class="matlabel">Middle name
                                        <input class="ipcss " matInput type="text" [(ngModel)]="clntMiddleName" />
                                    </mat-label>
                                </div>
                                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                    <mat-label class="matlabel">Last name
                                        <input class="ipcss " matInput type="text" [(ngModel)]="clntLastName" />
                                    </mat-label>
                                </div>
                                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                    <mat-label class="matlabel"> Age (yrs)
                                        <input class="ipcss " matInput type="text" [(ngModel)]="clntAge" />
                                    </mat-label>
                                </div>
                                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                    <mat-label class="matlabel">Occupation
                                        <input class="ipcss " matInput type="text" [(ngModel)]="clntOccupation"
                                            maxlength="25" />
                                    </mat-label>
                                </div>
                                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                    <mat-label class="matlabel">Symptoms
                                        <input type="text" class="ipcss " [(ngModel)]="clntSymptom"
                                            (keyup)="getSymptoms()" matInput [matAutocomplete]="auto2" />
                                        <mat-autocomplete #auto2="matAutocomplete">
                                            <mat-option (click)="selectSymptom(symptom)"
                                                *ngFor="let symptom of dispSymptomList" value="{{symptom}}">
                                                {{symptom}}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-label>
                                </div>
                                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                    <mat-label class="matlabel">Referred By
                                        <input type="text" class="ipcss " [(ngModel)]="referredDoctorName"
                                            (keyup)="getReferralDoctorList($event)" matInput [matAutocomplete]="auto" />
                                        <mat-autocomplete #auto="matAutocomplete">
                                            <mat-option (click)="sltReferDoctor(item)"
                                                *ngFor="let item of referredDoctorList" [value]="item">
                                                {{item}}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-label>
                                </div>
                                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                    <mat-label class="matlabel">Treatment Type<br>
                                        <mat-select required class="ipcss " [(ngModel)]="treatementType"
                                            disableOptionCentering>
                                            <mat-option *ngFor="let treatement of treatmentList"
                                                value="{{treatement.medicare_name}}">{{treatement.medicare_name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-label>
                                </div>
                                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                    <mat-label class="matlabel">Speciality<br>
                                        <mat-select class="ipcss " [(ngModel)]="splName"
                                            disableOptionCentering required>
                                            <mat-option *ngFor="let specialization of specializationList"
                                                value="{{specialization.splty_name}}"
                                                (click)="sltSpecialization(specialization.splty_id,specialization.splty_name)">
                                                {{specialization.splty_name}}</mat-option>
                                        </mat-select>
                                    </mat-label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="cover_div" style="padding-bottom: 13px;margin-bottom: 25px;">
                        <div class="header_lable">
                            Find doctor by
                        </div>
                        <div class="content_cover">
                            <div class="row">
                                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                    <mat-label class="matlabel">Country<br>
                                        <mat-select required class="ipcss " [(ngModel)]="clntCountry"
                                            disableOptionCentering>
                                            <mat-option *ngFor="let country of clntCountryList"
                                                value={{country.description}} (click)="countryChange(clntCountry, '0')">
                                                {{country.description}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-label>
                                </div>
                                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                    <mat-label class="matlabel">State<br>
                                        <mat-select required class="ipcss " [(ngModel)]="clntState"
                                            disableOptionCentering>
                                            <mat-option *ngFor="let state of clntStateList" value={{state.state_id}}
                                                (click)="stateChange(clntState, '0')">{{state.description}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-label>
                                </div>
                                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                    <mat-label class="matlabel">District / City<br>
                                        <mat-select required class="ipcss " [(ngModel)]="clntCity"
                                            disableOptionCentering>
                                            <mat-option (click)="cityChange(clntCity, '0')"
                                                *ngFor="let city of clntCityList" value="{{city.district_id}}">
                                                {{city.description}}</mat-option>
                                        </mat-select>
                                    </mat-label>
                                </div>
                                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                    <mat-label class="matlabel">Location<br>
                                        <input type="text" class="ipcss " required maxlength="50"
                                            [(ngModel)]="clntLocation" (keyup)="locationChange($event)" matInput
                                            [matAutocomplete]="auto1" />
                                        <mat-autocomplete #auto1="matAutocomplete">
                                            <mat-option (click)="selectedLocation(location)"
                                                *ngFor="let location of clntLocationList"
                                                value="{{location.description}}">
                                                {{location.description}}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-label>
                                </div>
                                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                    <mat-label class="matlabel">Doctor first name <br>
                                        <input class="ipcss " matInput [(ngModel)]="doctorFirstName"
                                            (keyup)=getDocListByFirstName() />
                                    </mat-label>
                                </div>
                            </div>
                            <div class="" [hidden]="doctorListFlag">
                                <div class="row">
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        <a (click)="selectedDoctor(doctor.Prov_id,doctor.Doctor_Name,doctor.Qualification)"
                                            *ngFor="let doctor of doctorList">
                                            <mat-list class="list_style boxlist">
                                                <div class="pro_image"> <img src="{{doctor.Prof_image}}"
                                                        class="profile_image"
                                                        onerror="this.src='../../../assets/img/default.jpg';">
                                                </div>
                                                <div class="appointment_name">
                                                    <div>
                                                        <p class="app_names"><span>
                                                                <strong>{{"Name :"}}</strong></span>{{doctor.Doctor_Name}}
                                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                                            <span *ngIf="!screensizeBased"><strong>{{"Experience:"}}</strong></span>{{doctor.Work_Year}}
                                                        </p>
                                                    </div>
                                                    <div class="hospital chn">
                                                        <p class="app_names"><span
                                                                *ngIf="!screensizeBased"><strong>{{"Gender :"}}</strong></span>{{doctor.gender1}}
                                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                                            <span *ngIf="!screensizeBased"><strong>{{"Speciality:"}}</strong></span>{{doctor.Speciality}}
                                                        </p>
                                                    </div>
                                                    <div class="hospital chn" >
                                                        <p class="app_names"><strong>{{"Qualification : "}}</strong>
                                                            <span class="span_class" >{{doctor.Qualification}}</span>
                                                        </p>
                                                    </div>
                                                    <div class="hospital chn" *ngIf="!hospitalFlag">
                                                        <p class="app_names"><strong>{{"Hospital : "}}</strong>
                                                            <span class="span_class"
                                                                *ngFor="let hosp of doctor.Hospital">{{hosp.Hosp_name
                                                                + " - " + hosp.Hosp_Locat+" "}}</span>
                                                        </p>
                                                    </div>
                                                    <img width="25" float-right height="25"
                                                        src="../../../assets/img/correct.png" item-end
                                                        *ngIf="doctor.check" />
                                                </div>
                                            </mat-list>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div [hidden]="doctorFlag" class="cover_div" style="padding-bottom: 13px;">
                        <div class="header_lable">
                            Doctor
                        </div>
                        <div class="content_cover">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <div class="row">
                                        <div class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10">
                                            <div class="appcreatenewdoctor"><b>{{doctorName}} - {{doctorQualif}}</b>
                                            </div>
                                        </div>
                                        <div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                            <div style="margin-top: -20px;margin-left: 41%;"> <a
                                                    (click)="revertSelctedDoctor()"><img width="27px" height="27px"
                                                        src="../../../assets//img/captcha_refresh.png"
                                                        style="position: absolute;top: 6px;right: 5px;"
                                                        alt="clear specialization"></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <ng-container *ngIf="!homecareFlag">
                                        <p class="tervys_heading"><b>Doctor Availability</b></p>
                                        <div class="row">
                                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                <div class="table-responsive available_div">
                                                    <table id="card_tbl"
                                                        class="table table-hover table-dynamic available_tbl">
                                                        <thead>
                                                            <tr>
                                                                <th style="width: 250px !important;"><b>Hospital
                                                                        Name</b>
                                                                </th>
                                                                <th><b>Location</b></th>
                                                                <th><b>Day</b></th>
                                                                <th><b>Session</b></th>
                                                                <th style="width: 150px;"><b>Starts from</b></th>
                                                                <th style="width: 150px;"><b>Ends at</b></th>
                                                                <th><b>Fee</b></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let doctor of doctorDetailTableData">
                                                                <td data-th="Hospital Name">{{doctor.hospital}}</td>
                                                                <td data-th="Location">{{doctor.location}}</td>
                                                                <td data-th="Day">{{doctor.day}}</td>
                                                                <td data-th="Session">{{doctor.session}}</td>
                                                                <td data-th="Starts from">{{doctor.available_from}}</td>
                                                                <td data-th="Ends at">{{doctor.available_to}}</td>
                                                                <td data-th="Fee">{{doctor.fees}}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                <div class="row ">
                                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                        <mat-label class="matlabel">Date<br>
                                                            <input type="date" class="ipcss_date widthappt"
                                                                id="appt_date" (change)="getApptByDate(appointmentDate)"
                                                                [(ngModel)]="appointmentDate" min={{currentMinDate}}
                                                                #matInput style="width: 160px;">
                                                        </mat-label>
                                                    </div>
                                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                        <div class="row">
                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                                <mat-label class="matlabel">Hospital name<br>
                                                                    <mat-select class="ipcss " [(ngModel)]="hospitalID"
                                                                        disableOptionCentering>
                                                                        <mat-option
                                                                            *ngFor="let hospit of hospitalListData"
                                                                            value="{{hospit.hospital_id}}"
                                                                            (click)="hospitalChange(hospitalID)">
                                                                            {{hospit.hospname}}</mat-option>
                                                                    </mat-select>
                                                                </mat-label>
                                                            </div>
                                                            <div *ngIf="appTypeTokenFlag"
                                                                class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                                <mat-label class="matlabel" *ngIf="appTypeTokenFlag">
                                                                    Visiting session<br>
                                                                    <mat-select required class="ipcss "
                                                                        [(ngModel)]="visitSession">
                                                                        <mat-option
                                                                            *ngFor="let session of visitingSessionData"
                                                                            value="{{session.description}}"
                                                                            (click)="visitSessionChange(visitSession, appointmentDate)">
                                                                            {{session.description}}
                                                                        </mat-option>
                                                                    </mat-select>
                                                                </mat-label>
                                                            </div>
                                                            <div *ngIf="docTreatementFlag"
                                                                class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                                <mat-label class="matlabel" *ngIf="docTreatementFlag">
                                                                    Treatment Type
                                                                    <mat-select required class="ipcss "
                                                                        [disabled]="docTreatementReadonlyFlag"
                                                                        [(ngModel)]="treatementType"
                                                                        disableOptionCentering>
                                                                        <mat-option
                                                                            *ngFor="let medicare of treatmentList"
                                                                            value="{{medicare.medicare_name}}">
                                                                            {{medicare.medicare_name}}
                                                                        </mat-option>
                                                                    </mat-select>
                                                                </mat-label>
                                                            </div>
                                                            <div *ngIf="appTypeTokenFlag"
                                                                class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                                <mat-label class="matlabel" *ngIf="appTypeTokenFlag">
                                                                    <p *ngIf="appTypeTokenFlag">Appointment type : Token
                                                                    </p>
                                                                </mat-label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <!-- Homecare Doctor -->
                                    <ng-container *ngIf="homecareFlag">
                                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                                            style="margin-top: 10px;">
                                            <div class="table-responsive">
                                                <table id="card_tbl" class="table table-hover table-dynamic">
                                                    <thead>
                                                        <tr>
                                                            <th>Session</th>
                                                            <th>Starts from</th>
                                                            <th>Ends at</th>
                                                            <th>Fee</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let doctor of homecareTableData">
                                                            <td>{{doctor.Session}}</td>
                                                            <td>{{doctor.Avail_from}}</td>
                                                            <td>{{doctor.Avail_to}}</td>
                                                            <td>{{doctor.Fees}}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            <mat-label class="matlabel">Date
                                                <input type="date" class="ipcss_date " id="appt_date"
                                                    (change)="getHomecareApptByDate(homecareAppDate)"
                                                    [(ngModel)]="homecareAppDate" min={{currentMinDate}} #matInput
                                                    style="width: 160px;">
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
                                            <div class="row">
                                                <div class="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
                                                    <mat-card id="cardcontent">
                                                        <mat-card-content style="background-color: #fff;">
                                                            <div *ngIf="visitingSessionData.length == 0">
                                                                <p>Time slots are not availabile</p>
                                                            </div>
                                                            <div *ngFor="let session of homecareTimeList;let j=index">
                                                                <mat-label class="appcreatenw_appttime">
                                                                    {{session.session}}
                                                                </mat-label>
                                                                <div
                                                                    style="margin:5px;display: grid; grid-template-columns: auto auto auto auto auto auto auto auto auto auto auto;">
                                                                    <div class="timediv"
                                                                        *ngFor="let time of session.time;let i=index "
                                                                        style=" margin-left: 5px;">
                                                                        <a
                                                                            (click)="Onappttimeclick(time,session.session,i)">
                                                                            <p [id]="'time_'+j+'_'+i" class="apptime">
                                                                                {{time}}</p>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </mat-card-content>
                                                    </mat-card>
                                                </div>
                                                <div class="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2"
                                                    style="padding-top: 8px;">
                                                    <img class="saveimgbtn_inpatinfo" (click)="Home_Table_Add()"
                                                        src="../../../assets/ui_icons/buttons/add_button.svg" />
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="homecareSessionTableData.length != 0"
                                            class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                            style="margin-top: 10px;">
                                            <div class="table-responsive">
                                                <table id="card_tbl" class="table table-hover table-dynamic">
                                                    <thead>
                                                        <tr>
                                                            <th>Date</th>
                                                            <th>Session</th>
                                                            <th>Time</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr
                                                            *ngFor="let data of homecareSessionTableData; let i = index">
                                                            <td data-th="Date">{{data.Date}}</td>
                                                            <td data-th="Session">{{data.Session}}</td>
                                                            <td data-th="Time">{{data.app_time}}</td>
                                                            <td data-th="Action"><a
                                                                    (click)="Delete_homesession_data(i)">Delete</a>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                                <div *ngIf="doctorAvailableFlag" style="margin-top: 10px;"
                                    class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <div *ngIf="!appTypeTokenFlag">
                                        <mat-card id="cardcontent" *ngIf="!appTypeTokenFlag">
                                            <mat-card-content style="background-color: #fff;">
                                                <div *ngFor="let session of timeListData;let j=index">
                                                    <mat-label class="appcreatenw_appttime">
                                                        {{session.session}}
                                                    </mat-label>
                                                    <div
                                                        style="margin:5px;display: grid; grid-template-columns: auto auto auto auto auto auto auto auto auto auto auto;">
                                                        <div class="timediv"
                                                            *ngFor="let time of session.time;let i=index "
                                                            style=" margin-left: 5px;">
                                                            <a (click)="Onappttimeclick(time,session.session,i)">
                                                                <p [id]="'time_'+j+'_'+i" class="apptime">
                                                                    {{time}}</p>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </mat-card-content>
                                        </mat-card>
                                    </div>
                                </div>
                                <div *ngIf="doctorNonAvailableFlag"
                                    class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <mat-label class="matlabel doctoravail">Please check doctor availability
                                    </mat-label>
                                </div>
                            </div>
                            <div class="row" *ngIf="payFlag">
                                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                    <mat-label class="matlabel">Payment mode : </mat-label>
                                    <mat-radio-group class="dietradio" [(ngModel)]="payType" labelPosition="before"
                                        aria-label="Select an option" color="primary" [(ngModel)]="payType">
                                        <mat-radio-button [hidden]="cashFlag" name="paytype" value="Cash">Cash
                                        </mat-radio-button>
                                        <mat-radio-button [hidden]="onlineFlag" name="paytype" value="Online" checked>
                                            Online
                                        </mat-radio-button>
                                        <mat-radio-button [hidden]="insuranceFlag" name="paytype" value="insurance">
                                            Insurance
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>