import { Component, OnInit ,ElementRef,Input,ViewEncapsulation,OnDestroy,Inject} from '@angular/core';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr'
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Data, Route, Router } from '@angular/router';
// import { getIp, Ip_with_img_address, country_code } from '../../../assets/js/ipaddress.js';

@Component({
  selector: 'app-document-details-zoom',
  templateUrl: './document-details-zoom.component.html',
  styleUrls: ['./document-details-zoom.component.scss']
})
export class DocumentDetailsZoomComponent implements OnInit {

  @Input() id: string;parm: string;  
  public element: any;
  public docs: string;
  UrlPDF: string = "http://192.168.1.9:8080/gritrest/doc/17011089400066/3-s2.0-B9781843347095500065-main.pdf"
  public report_image;
  public report_image_zomm = null
  showControls: boolean = true;
  public pdfSrc;
  public img_view: boolean;
  public result;
  public pdf_view: boolean;
  controllerSrc: any;

  scale: number = 1;
  public header_footer_flag: boolean = false;

  constructor(public el: ElementRef,public toastr:ToastrService,public router:Router,public  http:Http,public dialog: MatDialog ,public dialogRef: MatDialogRef<DocumentDetailsZoomComponent>,
    // public navParams: NavParams,
       @Inject(MAT_DIALOG_DATA) public data: any, public sanitizer: DomSanitizer) {    
        dialogRef.disableClose = true;
        this.img_view = true;
        this.pdf_view = true;

        this.pdf_view = false;
        this.pdfSrc = data.report_pdf;
        const url = this.pdfSrc;
        this.controllerSrc = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  ngOnInit(): void {
  }

  // back_Nav() {
  //   if (this.data.report_image != null ||this.data.report_pdf) {
  //     this.router.navigateByUrl("/secondopinionretrival")
  //     var data1={
  //     }
     
  //     this.result="secopn"
  //   }
  //   else {

  //   }
  // }

  ClosePopup(){
    this.dialogRef.close(this.result);}
}
