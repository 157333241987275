<mat-accordion class="mataccordion">
    <mat-expansion-panel   class="expandpanel"(opened)="panelOpenState12 = true"
    (closed)="panelOpenState12 = false">
       <mat-expansion-panel-header class="exppanel ">
          <mat-panel-title class="title width">
            <span class=""> 
           General history </span>
           <!-- <mat-icon  class="iconsleft" *ngIf="!panelOpenState11">add</mat-icon>
            <mat-icon  class="iconsleft" *ngIf="panelOpenState11">remove</mat-icon>   -->
          </mat-panel-title>
       </mat-expansion-panel-header>
    
    <div  [hidden]="genTab">
        <div class="row">
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel ">Father name<br>
                    <input class="ipcss  " matInput maxlength="50" [(ngModel)]="gen_fat_name" [disabled]="client" [required]="gen_fat_name_req"
                    (change)="general_change()"/>
        
                    </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel ">Mother name<br>
                    <input class="ipcss  "  maxlength="50" [(ngModel)]="gen_mot_name" [disabled]="client" [required]="gen_mot_name_req"
                    (change)="general_change()"/>
    
                    </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                
                <mat-label class="matlabel">Marriage<br>
                    <!-- <select class="ipcss  "[(ngModel)]="gen_marriage" disabled [required]="gen_marriage_req"  (change)="SiblingsChange()" disableOptionCentering > -->

                    <select class="ipcss  "[(ngModel)]="gen_marriage"  [disabled]="client" [required]="gen_marriage_req"  (change)="SiblingsChange()" disableOptionCentering >
                        <option disabled>Select</option>
                        <option value="Consanguineous">Consanguineous</option>
                        <option value="Non-Consanguineous">Non-consanguineous</option>
                    </select>
                </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Siblings<br>
                    <select class="ipcss  " [(ngModel)]="gen_sib" (change)="SiblingsChange()"  [disabled]="client" disableOptionCentering >
                        <option disabled>Select</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Male/Female">Male/Female</option>
                    </select>
                </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel ">Male<br>
                    <input class="ipcss  "  maxlength="1" [(ngModel)]="gen_male" disabled="{{MaleDisable}}" [disabled]="client"
                    [required]="gen_male_req" onkeypress="return event.charCode >= 48 && event.charCode <= 57"/>
        
                    </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel ">Female<br>
                    <input class="ipcss  " maxlength="1" [(ngModel)]="gen_fem" disabled="{{FeMaleDisable}}" [disabled]="client"
                    [required]="gen_female_req" onkeypress="return event.charCode >= 48 && event.charCode <= 57"/>
    
                    </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel" >Siblings general conditionsription<br>
                    <textarea #matInput maxlength="50" class="ipcss  "[(ngModel)]="gen_sib_gen" (change)="general_change()" [disabled]="client"
                    maxlength="100"></textarea>
                </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel" >History of familial Diseases<br>
                    <textarea #matInput maxlength="50" [(ngModel)]="gen_history_fami" (change)="general_change()" [disabled]="client" class="ipcss tetxarea  "
                     maxlength="100"></textarea>
                </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Parent’s general / dental conditions<br>
                    <textarea #matInput  [(ngModel)]="gen_pare" class="ipcss tetxarea " [disabled]="client"
                    maxlength="100" [required]="gen_part_gen_dent_req" (change)="general_change()"></textarea>
                </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel" >Past dental history<br>
                    <textarea #matInput [(ngModel)]="gen_past_dent" (change)="general_change()" [disabled]="client"
                    class="ipcss tetxarea " maxlength="50"></textarea>
                </mat-label>
            </div>
            <div class="row">
            <div class="col-9 col-sm-6 col-md-6 col-lg-5 col-xl-5">
                <mat-label class="matlabel ">History of previous orthodontic treatment</mat-label>
            </div>
            <div class="col-3 col-sm-6 col-md-6 col-lg-3 col-xl-3" style="padding-top: 4px;">
                <mat-button-toggle id="toggid13" class="togglebtn"
                [(ngModel)]="his_ortho1" ngDefaultControl [checked]="his_ortho1" [disabled]="client"
                (change)="changeprviousortho($event.source.checked)" [ngStyle]="his_ortho1 && { 'background' : 'green' } || !his_ortho1 && { 'background' : 'red' }">
               <span class="familytab" *ngIf="!his_ortho1">No</span>
               <span class="familytab" *ngIf="his_ortho1">Yes</span>
            </mat-button-toggle>
        <!-- <button  id="matbtn"  *ngIf="ynopt13"    [(ngModel)]="his_ortho" [style.color]="getColor('Yes')" [ngStyle]="{'border':'1px solid green'}" mat-raised-button (click)="option(13)">Yes</button>
        <button id="matbtn"  *ngIf="!ynopt13"   [style.color]="getColor('No')" [ngStyle]="{'border':'1px solid red'}" [(ngModel)]="his_ortho" mat-raised-button (click)="option(13)">No</button>
        -->
            </div>
           
        </div>
        </div>
        <div class="row" [hidden]="ortho_fields">
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel" >Type of appliance<br>
                    <textarea  #myInput id="myInput" [(ngModel)]="gen_type_app" [disabled]="client"
                    class="ipcss textarea" maxlength="50"
                    (change)="general_change()"></textarea>
                </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel" >Duration of treatment<br>
                    <textarea  #myInput id="myInput" [(ngModel)]="gen_dur_treat" [disabled]="client"
                    class="ipcss textarea" maxlength="50"
                    (change)="general_change()"></textarea>
                </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel" >Type of homecare<br>
                    <textarea  #myInput id="myInput" [(ngModel)]="gen_home" [disabled]="client"
                    class="ipcss textarea" maxlength="50"
                    (change)="general_change()"></textarea>
                </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel" >Reason for discontinuing<br>
                    <textarea  #myInput id="myInput" [(ngModel)]="gen_rea_dis" [disabled]="client"
                     class="ipcss textarea" maxlength="50"
                    (change)="general_change()"></textarea>
                </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Parents concern for orthodontics treatment<br>
                    <select class="ipcss  " [(ngModel)]="gen_pare_con" (change)="general_change()" [disabled]="client" disableOptionCentering >
                        <option disabled>Select</option>
                        <option value="Concerned">Concerned</option>
                        <option value="Very Concerned">Very Concerned</option>
                        <option value="Not Concerned">Not Concerned</option>
                    </select>
                </mat-label>
            </div>
            
        </div> 
  
   
    </div>
    </mat-expansion-panel>
</mat-accordion>