<div mat-dialog-title class="popup_border_cancel">
    <h5 class="header_txt">Cancel Appointment</h5>
    <div mat-dialog-actions>
        <img src="../../../assets/img/close_icon.png" (click)="onNoClick()" width="20px" height="20px"
            id="close_cancel" />
    </div>
</div>
<div mat-dialog-content class="close_content">
    <div class="row" style="margin: 5px;">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <textarea class="ipcss" placeholder="Reason for cancellation" name="username" id="canceltxtarea" type="text"
                required [(ngModel)]="cancelTxt"></textarea>
        </div>
    </div>
</div>
<div mat-dialog-actions align="end" style="width: auto;right: 19px;">
    <button (click)="cancelApps()" id="add_btn" [mat-dialog-close]="cancelTxt" cdkFocusInitial mat-button>
        <img src="../../../assets/ui_icons/buttons/submit_button.svg" class="addimgbtn_icd" />
    </button>
</div>