import { Injectable } from '@angular/core';
declare var webkitSpeechRecognition: any
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class VoicerecognitionService {

  public transcript;
  public speechdata;
  private isObservableStarted: boolean = false;
  constructor() {
  }


  private subject = new Subject<any>();

  sendMessage(message: any) {
    this.isObservableStarted = true;
    this.subject.next(message);
  }

  clearMessages() {
    this.subject.next();
  }
  stopMessages(){
     this.subject.unsubscribe();
    //  return  this.subject = null
  }
  component_name(name: any) {
    this.subject.next(name);
  }
  
  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }
  isObservableActive(): boolean { // this will check whether regonoization and observabe is on or not
    return this.isObservableStarted;
  }
}
