import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { Date_Formate } from '../../../assets/js/common';
import { ClientViewService } from '../clientview/client-view.service';
import { Client_Helper } from '../Client_helper';
import { ipaddress } from '../../ipaddress';

@Component({
  selector: 'app-donors',
  templateUrl: './donors.component.html',
  styleUrls: ['./donors.component.css']
})
export class DonorsComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  public donorList = [];
  public bloodDonorListUrl: string;
  
  constructor(public clientservice:ClientViewService,public gservice:CommonDataService,public http:Http,public routes:ActivatedRoute,public router:Router,public toastr:ToastrService) { 
    this.bloodDonorListUrl = ipaddress.getIp + "bd/bdlist";
  }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 5,
      "lengthMenu": [[5, 10, 25, 50, -1], [5, 10, 25, 50, "All"]],
      "columnDefs": [
        { "orderable": false, "targets": [0, 1, 2, 3] },
      ],
      "language": {
        "search": "Filter"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12">><"table-responsive"t><"row mt-1 d-flex"<"col-xs-12 col-sm-6 col-md-4"l><"col-xs-12 col-sm-12 col-md-4 text-center sm-order-last"i><"col-xs-12 col-sm-6 col-md-4"p>>'
    };
    this.getDonorList();
  }

  getDonorList() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.bloodDonorListUrl,
      JSON.stringify({
        client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.donations != null) {
            for (var i = 0; i < obj.donations.length; i++) {
              this.donorList.push({
                hptl_clinic_id: obj.donations[i].hptl_clinic_id,
                hospital: obj.donations[i].hptl_name,
                date:Date_Formate( obj.donations[i].donation_date)
              });
            }
          }
        },error => {});
  }

  viewDonarDetails(hptl_clinic_id, date) {
    var data={
      hptl_clinic_id: hptl_clinic_id,
      date : date,
    }
    Client_Helper.setblooddonordetail(data);
    this.clientservice.sendMessage('DonorUpdateDetailPage');
  }

  myCallbackFunction = function (_params) {// callback...
    return new Promise((resolve, reject) => {
      resolve(_params);
    });
  }
}
