import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Physio_Helper } from '../Physio_Helper';
import { Helper_Class } from '../../helper_class';
import { PhysioCasesheetService } from '../physio-casesheet/physio-casesheet.service';
import { Observable, observable, Subscription } from 'rxjs';
import { PhysioService } from '../physio/physio.service';
import { ipaddress } from '../../ipaddress';
import { Master_Helper } from '../../Doctor_module/Master_Helper';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
import { pharmacy_helper } from 'src/app/pharma/Pharmcy_Helper';
import { Message_data } from 'src/assets/js/Message_data';
import { Date_Formate, ConvertTimeformat } from '../../../assets/js/common';

@Component({
  selector: 'app-physio-diag-prescription',
  templateUrl: './physio-diag-prescription.component.html',
  styleUrls: ['./physio-diag-prescription.component.scss']
})
export class PhysioDiagPrescriptionComponent implements OnInit {
  @ViewChild('recognitionResult') recognitionResult: ElementRef;
  @ViewChild('printlogowithname') printlogowithname: ElementRef;
  @ViewChild('printbanner') printbanner: ElementRef;
  @ViewChild('printnoheader') printnoheader: ElementRef;
  public print_same_age: boolean;
  public doctorname;
  public locationinfo;
  public doct_country;
  public hospital_logo;
  public doct_address1;
  public doct_address2;
  public zipcode;
  @ViewChild('printnoheadsidebar') printnoheadsidebar: ElementRef;
  public printFlag: boolean = false;
  public saveflag: boolean = true;
  public print_template: string;
  public printpres: string;

  public blood_test_head;
  public blood_test_content;
  public scan_test_head;
  public scan_test_content;
  public us_test_head;
  public us_test_content;
  public xray_test_head;
  public xray_test_content;
  public bio_test_content;
  public bio_test_head;
  public ut_test_content;
  public ut_test_head;
  public ft_test_head;
  public ft_test_content;
  public culture_test_head;
  public culture_test_content;
// diagnosis and medical flow
public loc_name;
public subtestname: any = [];
public subtestuname: any = [];
public subtestsname: any = [];
public subtestxname: any = [];
public subtestbname: any = [];
public subtesturname: any = [];
public subtestfname: any = [];
public subtestcname: any = [];

public bloodtest: boolean = true;
public ultratest: boolean = true;
public scantest: boolean = true;
public xraytest: boolean = true;
public biopsytest: boolean = true;
public urinetest: boolean = true;
public faecestest: boolean = true;
public culturetest: boolean = true;

public Blood_test = [];
public filt_city;
public loc_id;
public sercah_center: string;

public filt_state;
public filt_country;
public obs_diag: string;
public city_url = ipaddress.getIp + "gen/city";
public state_url = ipaddress.getIp + "gen/state";
public country_url = ipaddress.getIp + "gen/count";
public urine_test_array = [];
public feace_test_array = [];
public scan_test_array = [];
public xray_test_array = [];
public ultra_test_array = [];
public culture_test_array = [];
public biopsy_test_array = []
public dig: any;
public dig_view_flag: boolean;
public diag_detail_List;
public location_search_items;
public location_txt;
public diag_list_address;
public diag_address1;
public diag_address2;
public diag_cen_name;
public obs_add_diag: any = [];
public test_Show: boolean = false;
public diag_cen_id;
public diag_centre_id;
public addr: any;

public clocation: any[];
public Urine_test = [];
public Feace_test = [];
public Xray_test = [];
public Scan_test = [];
public Ultra_test = [];
public testtypename: any = [];
public filt_state_desc;
public filt_city_desc;
public country_id;
public diag_location;
public diag_city;
public diag_state;
public diag_zipcode;
public diag_cntry;
public diag_telpho;
public test_flag: boolean = false;
public checked: boolean = false;
public frommedical: boolean;
public test_now: boolean;
public book_app: any;
public observation: string;
public blood_test = [];
public flagtxt;

public pritn_scan_flag: boolean = false;
public pritn_us_flag: boolean = false;
public pritn_xray_flag: boolean = false;
public pritn_bio_flag: boolean = false;
public pritn_ft_flag: boolean = false;
public pritn_ut_flag: boolean = false;
public pritn_culture_flag: boolean = false;

public print_diag: boolean;
public print_medidiag: boolean = false;
public print_med: boolean;
public inpatiant_save: boolean = false;

public both_save_flag: boolean = false;
public med_save_flag: boolean = false;
public diag_save_flag: boolean = false;

public get_locname_url: string;

public clnt_location_list = [];

public clnt_country_list = [];
public clnt_state_list = [];
public clnt_city_list = [];

public clnt_country_id;
public clnt_state_id;
public clnt_city_id;
public clnt_location_id;

public search_location;
public search_city;
public search_state;
public search_city_desc;
public search_state_desc;
public search_country;

public height;
public weight;
public temparature;
public height_flag: boolean;
public weight_flag: boolean;
public temparature_flag: boolean;

//Get medicine name
public Medicinenamelist;
public Medicne_data = [];
public Medicine_list = [];

//Medical prescription save
public next_txt;
public next_datetxt;
public daydur_show: boolean;
public investigation;
public currentDate;

public disease_flag: boolean = false;
public medicineFlag: boolean = false;

// inpatient view
public medInpatientViewPrec: boolean;
public viewpreslist_array = [];
public user_id;
public culture_test;
dtOptions: DataTables.Settings = {};
private med_id;
public short_name;
public genericDisplayFlag: boolean = false;
public book_app_now:boolean=false;
public dur_flag: boolean= false;
public notes_flag: boolean= false;
public headerstyle;
public footerstyle;
public preslogo_style;
public med_style;
public dosage_style;
public dur_style;
public freq_style;
public indur_style;
public note_style;
public salutation;
private req_estimate:string;
public showInst: boolean=true;
public mixtype:any=[];
public intakeOption:any=[];
public mixing_txt:string;
public mix_show: boolean=true;

  public diagPresFlag = "min";
  public adddiag: boolean = false;
  public city_list_data;
  public state_list_data;
  public country_list_data;
  public Search_Location: string;
  public Location_array = [];
  public Search_City: string
  public State_data = [];
  public Search_State: string;
  public Countrydata = [];
  public CurrentDatetime = null;
 

  public pres_state_list_data;

  public pres_country_list_data;

  public perm_city_list_data;
  public city: any;
  public cityval = [];
  public perm_state_list_data;
  public perm_country_list_data;

  public address: boolean;

  public consultationlocation = [];
  public consultationcity;
  public consultationstate;
  public consultationcountry;
 
  public check_pres;
 

  public diag_type;
  public diag_test_names = [];
  public newarray;
  public mainarray;

  public profile_image: any;
  public subtests: any[];
  public Thyroid: boolean = false;

  public doc_reg_id: string;

  public msgflag: boolean = false;
  public notifyflag: boolean = false;

  public hospital_name;
  public hospital_details;

  public dct_hospital: string;
  public doct_name: string;
  public doc_qualif: string ="";
  public doct_location: string;
  public doct_address: string;
  public doct_city: string;
  public doct_state: string;
  public doct_clientname: string;
  public Medicare_name: string;
  public client_gender;
  public client_age;
  public Appoint_Date: string;
  public hospitall_clinic_id;
  public subtest_types = [];

  //table view presc
  public diag_view_list: boolean = false;
  public diag_pres_list = [];
  public url_txt: string;
  public send_client_reg_id: any = {};
  public diag_pres_list_data;
  public diag_pres_listdata: boolean;
  public header_footer_flag: boolean;

  Hospdetails: boolean;
  public test_check: Boolean = false;
  public send_diag_data;
  public diag_url_det: string;
  public pres_test_id;
  public testname_sub: string;
  public testname: string
  public urine_test_list = [];
  public scan_test_list = [];
  scan_test = [];
  public urine_test = [];
  public test = [];
  public app_date: string;
  public pres_id: string;
  public flag: string;
  public send_data;
  public diag_url_txt;
  public test_type = [];
  public diag_id;
  public temp_test_data = {};
  public diag_details;
  public diag_list = [];
  public toastOption;
  public toastMessage;
  public seacrhbtn: boolean;
  public bookbtn: boolean;
  public hospital_city;
  public hospital_state;
  public hospital_location;

  public patient_list;
  public userinfo: any;
  subscription: Subscription;
  subscription1:Subscription;
  public client_id;
  public relation;
  public home_care;
  public add_MP;
  public relation_id;

  public getRetdetails:any = [];
  public hosp_footer;
  public printf_flag: boolean;

  public areas = [
    { size: 30 },
    { size: 70 },
  ];
  public list_button_flag:boolean = false;
  public pritn_blood_flag: boolean = false;
  public bg_image: string;
  public print_view_flag:boolean = true;
  public licence_code;
  public personalinfo;
  constructor(public http: Http, public toastr: ToastrService, public router: Router, 
    public messageservice: PhysioCasesheetService, public naviagationService:MenuViewService) {
    this.getTime();
  }

  getTime() {
    var obj = Master_Helper.getMasterCurrentDate();
    if (obj.current_date != null) {
      this.CurrentDatetime = obj.current_date;
      var get_date = obj.current_date.split('-');
      this.Appoint_Date = get_date[2] + "-" + get_date[1] + "-" + get_date[0]
    }
  }

  ngOnInit() {
    this.get_locname_url = ipaddress.getIp + "usercontroller/locbyname";

    this.personalinfo = Physio_Helper.getClient_Info();
    this.dct_hospital = Physio_Helper.getClient_Info().hptl_name;
    this.doct_address1 = Physio_Helper.getClient_Info().address1;
    this.doct_address2 = this.doct_address2 != undefined ? Physio_Helper.getClient_Info().address2 : "";
    this.doct_address = this.doct_address1 + this.doct_address2;
    this.doct_location = Physio_Helper.getClient_Info().location;
    this.doct_city = Physio_Helper.getClient_Info().city;
    this.doct_state = Physio_Helper.getClient_Info().state;
    this.zipcode = Physio_Helper.getClient_Info().zipcode;
    this.doct_country = Physio_Helper.getClient_Info().country_id;
    this.subscription = this.messageservice.getCasesheetMessage().subscribe(message => {
      if (message == "max") {
        this.adddiag = false;
      }
     
      this.diagPresFlag = message;
      this.diag_view_list = false;
      this.list_button_flag = false;
    });

    this.subscription = this.messageservice.getDiagComponent().subscribe(message => {
        this.appdiagpresList();
    });

    this.Blood_test = [];
    this.testtypename = [];
    this.sercah_center = "";
    this.subtestname = [];
    var addr2;
    
    this.add_MP = false;
    this.patient_list = Physio_Helper.getClient_Info();
    
    this.userinfo = Helper_Class.getInfo();
    var hosp: any = Helper_Class.getHospital();
    this.hospitall_clinic_id = hosp[0].hptl_clinic_id;
    this.dct_hospital = this.patient_list.hptl_name;
    this.doct_name = this.userinfo.first_name + " " + this.userinfo.last_name;
    this.doct_location = this.patient_list.location;
    this.licence_code = this.userinfo.licence_code;

    // if(this.userinfo != undefined && this.userinfo != null){
    //   if(this.userinfo.qualifications.length != 0){
    //     for(var i = 0 ; i < this.userinfo.qualifications.length; i++){
    //       if(this.doc_qualif != ""){
    //         this.doc_qualif += ","+ this.userinfo.qualifications[i];
    //       }else{
    //         this.doc_qualif = this.userinfo.qualifications[i];
    //       }
    //     }
    //   }  
    // }

    addr2 = this.patient_list.address2 == undefined ? "" : this.patient_list.address2;
    this.doct_address = this.patient_list.address1 + addr2;
    this.doct_city = this.patient_list.city + ", " + this.patient_list.state;
    this.doct_state = this.patient_list.country + ", " + this.patient_list.zipcode;
    this.doct_clientname = this.patient_list.client_name;
    this.Medicare_name = this.patient_list.Medicare_name;
    this.client_gender = this.patient_list.Gender_data;
    this.Appoint_Date = this.patient_list.Appoint_Date;
    this.client_age = this.patient_list.Age_data;
    this.check_pres = "0";
    this.personalinfo = Physio_Helper.getClient_Info();
    this.add_MP = false;
    this.dct_hospital = Physio_Helper.getClient_Info().hptl_name;
    this.doct_address1 = Physio_Helper.getClient_Info().address1;
    this.doct_address2 = this.doct_address2 != undefined ? Physio_Helper.getClient_Info().address2 : "";
    this.doct_address = this.doct_address1 + this.doct_address2;
    this.doct_location = Physio_Helper.getClient_Info().location;
    this.doct_city = Physio_Helper.getClient_Info().city;
    this.doct_state = Physio_Helper.getClient_Info().state;
    this.zipcode = Physio_Helper.getClient_Info().zipcode;
    this.doct_country = Physio_Helper.getClient_Info().country_id;
    console.log("this.doct_country"+JSON.stringify(this.doct_country))
    console.log("this.doct_country"+JSON.stringify(Physio_Helper.getClient_Info()))

    if (this.doct_country != null && this.doct_country != undefined) {
      console.log("this.doct_country")
      this.clnt_country_id = this.doct_country;
      this.clnt_state_id = Physio_Helper.getClient_Info().state_id;
      this.clnt_city_id = Physio_Helper.getClient_Info().city_id;
      this.getCountries("0");
    } else {
      this.getCountries("0");
    }

    this.doctorname = this.userinfo.first_name + " " + this.userinfo.last_name;
    this.client_gender = this.personalinfo.Gender_data;
    this.Appoint_Date = this.personalinfo.Appoint_Date;
    this.client_age = this.personalinfo.Age_data;
    if(this.personalinfo.salutation != undefined){
      this.doct_clientname = this.personalinfo.salutation+". "+this.personalinfo.client_name;
    } else {
      this.doct_clientname = this.personalinfo.client_name;
    }
    
    if (this.doct_location != null && this.doct_location != undefined) {
      var location_name = this.doct_location;
      this.loc_name = location_name;
      // this.Get_City(this.loc_name);
    }
    //list view
    
    
    this.header_footer_flag = false;
    this.url_txt = ipaddress.getIp + "record/dpbyid";

    this.client_id = this.patient_list.Client_id;
    this.relation = this.patient_list.relationship_name;
    this.relation_id = this.patient_list.rel_id;
    this.home_care = "0";
    this.getRetdetails = Helper_Class.getRet();

    // if (Physio_Helper.getHospFooter() != undefined) {
    //   this.hosp_footer = Physio_Helper.getHospFooter();
    //   this.printf_flag = false;
    // }

    if (Physio_Helper.getHospital_bg_image() != undefined) {
      this.bg_image = Physio_Helper.getHospital_bg_image();
    }
    this.appdiagpresList();
  }

  spiltarea(e){
    if(e == 'default'){
      this.areas[0].size = 40;
      this.areas[1].size = 60;
      this.list_button_flag = false;
    }else if(e == 'details_view'){
      this.areas[0].size = 10;
      this.areas[1].size = 90;
      this.list_button_flag = true;
    }else{
      this.areas[0].size = 90;
      this.areas[1].size = 10;
      this.list_button_flag = false;
      this.print_view_flag = true;
    }
  }

  // Locationfilter(location) {
  //   this.loc_name = location.target.value.toString();
  //   this.consultationlocation = [];
  //   this.consultationstate = [];
  //   this.consultationcity = [];
  //   this.consultationcountry = [];
  //   this.bloodtest = true;
  //   this.ultratest = true;
  //   this.scantest = true;
  //   this.xraytest = true;
  //   this.biopsytest = true;
  //   this.urinetest = true;
  //   this.faecestest = true;
  //   this.filt_country = [];
  //   this.filt_state_desc = [];
  //   this.subtestbname = [];
  //   this.subtestfname = [];
  //   this.subtestname = [];
  //   this.subtestname = [];
  //   this.subtestsname = [];
  //   this.subtestuname = [];
  //   this.subtesturname = [];
  //   this.subtestxname = [];
  //   this.diag_detail_List = [];
  //   this.location_txt = [];
  //   if (this.loc_name !== "" && this.loc_name.length > 2) {
  //     var headers = new Headers();
  //     headers.append('Content-Type', 'application/json');
  //     this.http.post(ipaddress.getIp + "gen/loc",
  //       JSON.stringify({
  //         loc_name: this.loc_name
  //       }),
  //       { headers: headers })
  //       .subscribe(
  //         response => {
  //           var obj = response.json();
  //           if (obj.locations != null && obj.locations != undefined) {
  //             this.location_search_items = obj.locations;
  //             for (var i = 0; i < this.location_search_items.length; i++) {
  //               this.location_txt.push(this.location_search_items[i]);
  //             }
  //             this.consultationlocation = this.location_txt.filter(function (this: any, el) {
  //               return el.toLowerCase().indexOf(this.loc_name.toLowerCase()) > -1;
  //             }.bind(this));
  //           }
  //         },
  //         error => {}
  //       )
  //   } else {
  //     this.consultationlocation = [];
  //     this.consultationcity = [];
  //     this.consultationstate = [];
  //     this.consultationcountry = [];
  //   }
  // }

  // select_location_item(loc_name) {
  //   this.loc_name = loc_name;
  //   this.consultationlocation = [];
  //   this.Get_City(this.loc_name);
  //   this.consultationstate = [];
  //   this.consultationcity = [];
  //   this.consultationcountry = [];
  //   this.bloodtest = true;
  //   this.ultratest = true;
  //   this.scantest = true;
  //   this.xraytest = true;
  //   this.biopsytest = true;
  //   this.urinetest = true;
  //   this.faecestest = true;
  //   this.subtestbname = [];
  //   this.subtestfname = [];
  //   this.subtestname = [];
  //   this.subtestname = [];
  //   this.subtestsname = [];
  //   this.subtestuname = [];
  //   this.subtesturname = [];
  //   this.subtestxname = [];
  //   this.diag_detail_List = [];
  //   this.Blood_test = [];
  // }

  // Get_City(data) {
  //   var headers = new Headers();
  //   headers.append('Content-Type', 'application/json');
  //   this.http.post(this.city_url, JSON.stringify({ loc_name: data }), { headers: headers }).subscribe(
  //     data => {
  //       var obj = data.json();
  //       if (obj.cities != null && obj.cities != undefined && obj.cities != "") {

  //         this.consultationcity = obj.cities;
  //         this.filt_city = this.consultationcity[0].city_id;
  //         this.filt_city_desc = this.consultationcity[0].city_desc;
  //         this.loc_id = this.consultationcity[0].location_id;
  //         this.zipcode = this.consultationcity[0].zipcode;
  //         this.City_change(this.consultationcity[0].city_id);

  //       }
  //     },
  //     error => {
        
  //     })
  // }

  // City_change(data) {
  //   if (data != null) {
  //     var headers = new Headers();
  //     headers.append('Content-Type', 'application/json');

  //     this.http.post(this.state_url, JSON.stringify({ city_id: data }), { headers: headers }).subscribe(
  //       data => {

  //         if (data.json().states != null && data.json().states != undefined && data.json().states != "") {
  //           this.consultationstate = data.json().states;

  //           this.filt_state = this.consultationstate[0].state_id;
  //           this.filt_state_desc = this.consultationstate[0].state_desc;

  //           this.State_change(this.consultationstate[0].state_id);
  //         }
  //       },
  //       error => {
  //       })
  //   } else {
  //     this.consultationstate = [];
  //     this.consultationcity = [];
  //     this.consultationcountry = [];
  //   }
  // }

  // State_change(data) {
  //   if (data != null) {
  //     var headers = new Headers();
  //     headers.append('Content-Type', 'application/json');

  //     this.http.post(this.country_url, JSON.stringify({ state_id: data }), { headers: headers }).subscribe(
  //       data => {

  //         if (data.json().countries != null && data.json().countries != undefined && data.json().countries != "") {
  //           this.consultationcountry = data.json().countries;
  //           this.country_id = this.consultationcountry[0].country_id;
  //           this.filt_country = this.consultationcountry[0].country_desc;
  //           this.SerCountry_Change(this.consultationcountry[0].country_desc, this.filt_state_desc, this.filt_city_desc, this.loc_name);
  //         }
  //       },
  //       error => {
          
  //       })
  //   } else {
  //     this.consultationstate = [];
  //     this.consultationcountry = [];
  //   }
  // }
  // //center
  // SerCountry_Change(countrytxt, statetxt, citytxt, loctxt) {
  //   this.diag_detail_List = [];
  //   var headers = new Headers();
  //   headers.append('Content-Type', 'application/json');
  //   this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/diagdetbyname/',
  //     JSON.stringify({
  //       country: countrytxt,
  //       state: statetxt,
  //       city: citytxt,
  //       location: loctxt,
  //     }),
  //     { headers: headers })
  //     .subscribe(
  //       response => {
          
  //         var obj = response.json();;
  //         if (obj.diagnosis != null && obj.diagnosis != undefined && obj.diagnosis != "") {
  //           this.diag_detail_List = obj.diagnosis;
  //           for (var i = 0; i < obj.diagnosis.length; i++) {

  //             if (obj.diagnosis[i].diag_centre_name != null) {
  //               this.diag_cen_name = obj.diagnosis[i].diag_centre_name;
  //             }
  //             if (obj.diagnosis[i].diag_centre_id != null) {
  //               this.diag_centre_id = obj.diagnosis[i].diag_centre_id;
  //             }

  //             if (obj.diagnosis[i].address1 != null || obj.diagnosis[i].address2 != null) {
  //               this.diag_address1 = obj.diagnosis[i].address1;
  //               this.diag_address2 = obj.diagnosis[i].address2;
  //             }


  //             if (obj.diagnosis[i].location != null) {
  //               this.diag_location = obj.diagnosis[i].location;
  //             }

  //             if (obj.diagnosis[i].city != null) {
  //               this.diag_city = obj.diagnosis[i].city;
  //             }

  //             if (obj.diagnosis[i].state != null) {
  //               this.diag_state = obj.diagnosis[i].state;
  //             }

  //             if (obj.diagnosis[i].zipcode != null) {
  //               this.diag_zipcode = obj.diagnosis[i].zipcode;
  //             }

  //             if (obj.diagnosis[i].country != null) {
  //               this.diag_cntry = obj.diagnosis[i].country;

  //             }
  //             if (obj.diagnosis[i].telephone != null) {
  //               this.diag_telpho = obj.diagnosis[i].telephone;
  //             }
  //           }
  //         }
  //       },
  //       error => {

  //       }
  //     )
  // }

  // //Get diagnosis center
  // serch_centerchange(data) {
  //   this.obs_add_diag = undefined;
  //   var datae: string = "";
  //   datae = data.diag_centre_name;
  //   for (var i = 0; i < this.diag_detail_List.length; i++) {
  //     if (this.diag_detail_List[i].diag_centre_name == datae) {
  //       this.diag_cen_id = this.diag_detail_List[i].diag_centre_id;
  //       if (this.diag_detail_List[i].address1 != null && this.diag_detail_List[i].address1 != "") {
  //         this.diag_address1 = this.diag_detail_List[i].address1;
  //       }
  //       else {
  //         this.diag_address1 = this.diag_detail_List[i].address1;
  //       }
  //       if (this.diag_detail_List[i].address1 != null || this.diag_detail_List[i].address2 != null) {
  //         this.diag_address2 = this.diag_detail_List[i].address2;
  //       }
  //       else {
  //         this.diag_address2 = this.diag_detail_List[i].address2;
  //       }
  //       this.obs_add_diag = this.diag_address1 + ", " + this.diag_address2;
  //     }
  //     else {
  //     }
  //   }

  //   this.Blood_test = [];
  //   var headers = new Headers();
  //   headers.append('Content-Type', 'application/json');
  //   this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/dtsub/',
  //     JSON.stringify({
  //       diag_centre_id: this.diag_cen_id
  //     }),
  //     { headers: headers })
  //     .subscribe(
  //       response => {
          
  //         var obj = response.json();
  //         if (obj.diag_tests.length == 0) {
  //           this.test_flag = true;
  //         }
  //         else {
  //           this.test_flag = false;
  //         }
  //         this.diag_centre_id = this.diag_cen_id;

  //         this.Blood_test = obj.diag_tests;
  //         for (var k = 0; k < this.Blood_test.length; k++) {
  //           this.testtypename[k] = this.Blood_test[k].test__type_name;
  //           for (var j = 0; j < this.Blood_test[k].sub_tests.length; j++) {
  //               this.Blood_test[k].sub_tests[j] = {
  //                 test_id: this.Blood_test[k].sub_tests[j].test_id,
  //                 test_name: this.Blood_test[k].sub_tests[j].test_name,
  //                 checked: false                }
  //           }
  //         }
          
          
  //       },
  //       error => { }
  //     )
  // }

  // ChangeTestType(event, id, data, type) {
  //   if (event == true) {
  //     for (var k = 0; k < this.Blood_test.length; k++) {
  //       this.testtypename[k] = this.Blood_test[k].test__type_name;
  //       if(this.testtypename[k] == type ){
  //         for (var j = 0; j < this.Blood_test[k].sub_tests.length; j++) {
  //           if( this.Blood_test[k].sub_tests[j].test_id == id){
  //             this.Blood_test[k].sub_tests[j].checked = true;
  //           }
  //         }
  //       }
  //     }
  //     if (type == "Blood Test") {
  //       this.bloodtest = false;
  //       this.subtestname.push({
  //         test_id: id,
  //         test_name: data
  //       });
        
  //     } else if (type == "Scan") {
  //       this.scantest = false;
  //       this.subtestsname.push({
  //         test_id: id,
  //         test_name: data
  //       });

  //     } else if (type == "Ultra Sound") {
  //       this.ultratest = false;
  //       this.subtestuname.push({
  //         test_id: id,
  //         test_name: data
  //       });

  //     } else if (type == "X-ray") {
  //       this.xraytest = false;
  //       this.subtestxname.push({
  //         test_id: id,
  //         test_name: data
  //       });

  //     } else if (type == "Biopsy") {
  //       this.biopsytest = false;
  //       this.subtestbname.push({
  //         test_id: id,
  //         test_name: data
  //       });

  //     } else if (type == "Urine Test") {
  //       this.urinetest = false;
  //       this.subtesturname.push({
  //         test_id: id,
  //         test_name: data
  //       });
        
  //     } else if (type == "Faeces Test") {
  //       this.faecestest = false;
  //       this.subtestfname.push({
  //         test_id: id,
  //         test_name: data
  //       });
  //     }

  //   } else if (event == false) {
  //     for (var k = 0; k < this.Blood_test.length; k++) {
  //       this.testtypename[k] = this.Blood_test[k].test__type_name;
  //       if(this.testtypename[k] == type ){
  //         for (var j = 0; j < this.Blood_test[k].sub_tests.length; j++) {
  //           if( this.Blood_test[k].sub_tests[j].test_id == id){
  //             this.Blood_test[k].sub_tests[j].checked = false;
  //           }
            
  //         }
  //       }
        
  //     }
  //     for (var h = 0; h < this.subtestname.length; h++) {
  //       if (this.subtestname[h].test_id == id) {
  //         this.subtestname.splice(h, 1);
  //         this.checked = false;
  //         if (this.subtestname.length == 0) {
  //           this.bloodtest = true;
  //         }
  //       }
  //     }
  //     for (var h = 0; h < this.subtestsname.length; h++) {
  //       if (this.subtestsname[h].test_id == id) {
  //         this.subtestsname.splice(h, 1);
  //         if (this.subtestsname.length == 0) {
  //           this.scantest = true;
  //         }
  //       }
  //     }
  //     for (var h = 0; h < this.subtestuname.length; h++) {
  //       if (this.subtestuname[h].test_id == id) {
  //         this.subtestuname.splice(h, 1);
  //         if (this.subtestuname.length == 0) {
  //           this.ultratest = true;
  //         }
  //       }
  //     }
  //     for (var h = 0; h < this.subtestxname.length; h++) {
  //       if (this.subtestxname[h].test_id == id) {
  //         this.subtestxname.splice(h, 1);
  //         if (this.subtestxname.length == 0) {
  //           this.xraytest = true;
  //         }
  //       }
  //     }
  //     for (var h = 0; h < this.subtestbname.length; h++) {
  //       if (this.subtestbname[h].test_id == id) {
  //         this.subtestbname.splice(h, 1);
  //         if (this.subtestbname.length == 0) {
  //           this.biopsytest = true;
  //         }
  //       }
  //     }
  //     for (var h = 0; h < this.subtesturname.length; h++) {
  //       if (this.subtesturname[h].test_id == id) {
  //         this.subtesturname.splice(h, 1);
  //         if (this.subtesturname.length == 0) {
  //           this.urinetest = true;
  //         }
  //       }
  //     }
  //     for (var h = 0; h < this.subtestfname.length; h++) {
  //       if (this.subtestfname[h].test_id == id) {
  //         this.subtestfname.splice(h, 1);
  //         if (this.subtestfname.length == 0) {
  //           this.faecestest = true;
  //         }
  //       }
  //     }
  //   }
    
  // }

  downloadPDF() {
    window.print();
  }

  // closeSelectedTest(type, id, subtype) {
  //     for (var k = 0; k < this.Blood_test.length; k++) {
  //       if (type == this.Blood_test[k].test__type_name) {
  //       for (var j = 0; j < this.Blood_test[k].sub_tests.length; j++) {
  //         if (this.Blood_test[k].sub_tests[j].test_id == id) {
  //           this.Blood_test[k].sub_tests[j].checked = false;
  //         }
  //       }
  //     }
  //   }
  //   if (type == "Blood Test") {
      

  //     for (var h = 0; h < this.subtestname.length; h++) {
  //       if (this.subtestname[h].test_id == id) {
  //         this.subtestname.splice(h, 1);
  //         if (this.subtestname.length == 0) {
  //           this.bloodtest = true;
  //         }
  //       }

  //     }
  //   }
  //   if (type == "Scan") {
  //     for (var h = 0; h < this.subtestsname.length; h++) {
  //       if (this.subtestsname[h].test_id == id) {
  //         this.subtestsname.splice(h, 1);
  //         if (this.subtestsname.length == 0) {
  //           this.scantest = true;
  //         }
  //       }
  //     }
  //   }
  //   if (type == "Ultra Sound") {
  //     for (var h = 0; h < this.subtestuname.length; h++) {
  //       if (this.subtestuname[h].test_id == id) {
  //         this.subtestuname.splice(h, 1);
  //         if (this.subtestuname.length == 0) {
  //           this.ultratest = true;
  //         }
  //       }
  //     }
  //   }
  //   if (type == "X-ray") {
  //     for (var h = 0; h < this.subtestxname.length; h++) {
  //       if (this.subtestxname[h].test_id == id) {
  //         this.subtestxname.splice(h, 1);
  //         if (this.subtestxname.length == 0) {
  //           this.xraytest = true;
  //         }
  //       }
  //     }
  //   }
  //   if (type == "Biopsy") {
  //     for (var h = 0; h < this.subtestbname.length; h++) {
  //       if (this.subtestbname[h].test_id == id) {
  //         this.subtestbname.splice(h, 1);
  //         if (this.subtestbname.length == 0) {
  //           this.biopsytest = true;
  //         }

  //       }
  //     }
  //   }
  //   if (type == "Urine Test") {
  //     for (var h = 0; h < this.subtesturname.length; h++) {
  //       if (this.subtesturname[h].test_id == id) {
  //         this.subtesturname.splice(h, 1);
  //         if (this.subtesturname.length == 0) {
  //           this.urinetest = true;
  //         }

  //       }
  //     }
  //   }
  //   if (type == "Faeces Test") {
  //     for (var h = 0; h < this.subtestfname.length; h++) {
  //       if (this.subtestfname[h].test_id == id) {
  //         this.subtestfname.splice(h, 1);
  //         if (this.subtestfname.length == 0) {
  //           this.faecestest = true;
  //         }
  //       }
  //     }
  //   }

  // }

  // Diagnosis_prescription_save() {
  //   var flag = true;
  //   if (this.loc_name == undefined || this.filt_city == undefined || this.filt_state == undefined || this.filt_country == undefined || this.sercah_center == undefined) {
  //     flag = false;
  //     this.toastr.error("Please enter mandatory fields");
  //   }
  //   if (flag == true && (this.subtestsname == undefined || this.subtestfname == undefined || this.subtestuname == undefined || this.subtesturname == undefined || this.subtestxname == undefined || this.subtestname == undefined || this.subtestbname == undefined)) {
  //     flag = false;
  //     this.toastr.error("Please select test type");
  //   }

  //   if(this.subtestuname.length != 0){
  //     flag=true;
  //   } else if(this.subtestfname.length != 0){
  //     flag=true;
  //   }else if(this.subtestname.length != 0){
  //     flag = true;
  //   }else if(this.subtestsname.length != 0){
  //     flag = true;
  //   }else if(this.subtestxname.length != 0){
  //     flag = true;
  //   }else if(this.subtesturname.length != 0){
  //     flag = true;
  //   }else if(this.subtestbname.length != 0){
  //     flag = true;
  //   }else{
  //     flag = false;
  //     this.toastr.error("Please select atleast one test to proceed");
  //   }

  //   // if (this.subtestuname.length == 0 || this.subtestfname.length == 0
  //   //   || this.subtestname.length == 0 || this.subtestsname.length == 0
  //   //   || this.subtestxname.length == 0 || this.subtesturname.length == 0) {

  //   //   flag = false;
  //   //   this.toastr.error("Please select atleast one test to proceed");
  //   // }

  //   if (flag == true) {
  //     if (this.subtestname.length != 0) {
  //       for (var i = 0; i < this.subtestname.length; i++) {
  //         this.blood_test.push(this.subtestname[i].test_id);
  //       }
  //     }
  //     if (this.subtesturname.length != 0) {
  //       for (var i = 0; i < this.subtesturname.length; i++) {
  //         this.urine_test_array.push(this.subtesturname[i].test_id);
  //       }
  //     }
  //     if (this.subtestfname.length != 0) {
  //       for (var i = 0; i < this.subtestfname.length; i++) {
  //         this.feace_test_array.push(this.subtestfname[i].test_id);
  //       }
  //     }
  //     if (this.subtestsname.length != 0) {
  //       for (var i = 0; i < this.subtestsname.length; i++) {
  //         this.scan_test_array.push(this.subtestsname[i].test_id);
  //       }
  //     }
  //     if (this.subtestxname.length != 0) {
  //       for (var i = 0; i < this.subtestxname.length; i++) {
  //         this.xray_test_array.push(this.subtestxname[i].test_id);
  //       }
  //     }
  //     if (this.subtestuname.length != 0) {
  //       for (var i = 0; i < this.subtesturname.length; i++) {
  //         this.ultra_test_array.push(this.subtesturname[i].test_id);
  //       }
  //     }
  //     if (this.obs_diag != undefined) {
  //       this.observation = this.obs_diag;
  //     }
  //     else {
  //       this.observation = "";
  //     }
  //     if (this.test_now == true) {
  //       this.book_app = "1";
  //     }
  //     else {
  //       this.book_app = "0";
  //     }

  //     var sen_pass;
  //     if(this.patient_list.sub_id != "" && this.patient_list.sub_id != undefined){
  //         sen_pass = JSON.stringify({
  //           appointment_id: this.patient_list.app_id,
  //           client_reg_id:this.client_id,
  //           relation_id: this.relation_id,
  //           sub_rel_id: this.patient_list.sub_id,
  //           date: this.CurrentDatetime,
  //           diagnosis: "diag",
  //           disease: "",
  //           comment: this.observation,
  //           diagnosis_id: this.diag_centre_id,
  //           spl_id:this.patient_list.spl_id,
  //           "Blood Test": this.blood_test,
  //           "Urine Test": this.urine_test_array,
  //           "Faeces Test": this.feace_test_array,
  //           "Scan": this.scan_test_array,
  //           "X-ray": this.xray_test_array,
  //           "Ultra Sound": this.ultra_test_array,
  //           "book_now": this.book_app
  //         });
  //     }else{
  //       sen_pass = JSON.stringify({
  //         appointment_id: this.patient_list.app_id,
  //         client_reg_id:this.client_id,
  //         relation_id: this.relation_id,
  //         date: this.CurrentDatetime,
  //         diagnosis: "diag",
  //         disease: "",
  //         comment: this.observation,
  //         diagnosis_id: this.diag_centre_id,
  //         spl_id:this.patient_list.spl_id,
  //         "Blood Test": this.blood_test,
  //         "Urine Test": this.urine_test_array,
  //         "Faeces Test": this.feace_test_array,
  //         "Scan": this.scan_test_array,
  //         "X-ray": this.xray_test_array,
  //         "Ultra Sound": this.ultra_test_array,
  //         "book_now": this.book_app
  //       });
  //     }

      

  //     var headers = new Headers();
  //     headers.append('Content-Type', 'application/json');
  //     this.http.post(ipaddress.getIp.toString() + 'prescription/savepres/', sen_pass,
  //       { headers: headers })
  //       .subscribe(
  //         response => {
  //           var obj = response.json();
  //           if (localStorage.getItem("HomeCare") != "0") {
  //             if (obj["pres_id"] != null) {
  //               Helper_Class.set_diag_pres_id(obj["pres_id"])
  //               this.toastr.success("Prescription saved successfully");
  //               this.flagtxt = "booking";

  //               this.send_data = {
  //                 pres_id: obj.pres_id,
  //                 flag: this.flagtxt
  //               }
  //               this.appdiagpresList();
  //               this.adddiag = false;

  //               // var dat = this.check_pres;
  //               // if (dat == "0") {
  //               //   this.router.navigateByUrl('/dashboard');
  //               //   this.check_pres = "1";
  //               // }
  //               // else {
  //               //   var seconds = 1;
  //               //   setInterval(function () {
  //               //     seconds--;

  //               //     // if (seconds == 0) {
  //               //     //   this.navCtrl.push(clientapplistPage);
  //               //     // }
  //               //   }, 500);
  //               // }

  //             }
  //             else {
  //               this.toastr.error("Unable to save prescription, please try again later");

  //             }
  //           }
  //           else {
  //             //$scope.diag_test_names = response.data.test_details;
  //             //for (var i = 0; i < $scope.diag_test_names.length; i++) {
  //             if (obj["pres_id"] != null) {
  //               this.toastr.error("Prescription saved successfully");
  //               var dat = this.check_pres;
  //               if (dat == "0") {
  //                 // this.navCtrl.push(MedicalPrescriptionTabPage);
  //                 this.check_pres = "1";
  //               }
  //               else {
  //                 var seconds = 1;
  //                 setInterval(function () {
  //                   seconds--;
  //                   // if (seconds == 0) {
  //                   //   this.navCtrl.push(clientapplistPage);
  //                   // }
  //                 }, 500);
  //               }
  //             }
  //             else {
  //               this.toastr.error('Unable to save prescription, please try again later');
  //             }

  //           }
  //         },
  //         error => {

  //         }
  //       )
  //   }
  // }

  // Get_diagnosis_address() {
  //   function StringBuilder(this: any, value) {
  //     this.strings = new Array();
  //     this.append(value);
  //   }

  //   StringBuilder.prototype.append = function (value) {
  //     if (value) {
  //       this.strings.push(value);
  //     }
  //   }

  //   StringBuilder.prototype.clear = function () {
  //     this.strings.length = 0;
  //   }

  //   StringBuilder.prototype.toString = function () {
  //     return this.strings.join("");
  //   }
  //   var headers = new Headers();
  //   headers.append('Content-Type', 'application/json');
  //   this.http.post(ipaddress.getIp.toString() + 'adm/gfac',
  //     JSON.stringify({
  //       hptl_clinic_id: this.hospitall_clinic_id,
  //       type: "diagnosis"
  //     }),
  //     { headers: headers })
  //     .subscribe(
  //       response => {

          
  //         var obj = response.json();
  //         if (obj != null) {
  //           if (obj.location != undefined) {
  //             var Search_Location = obj.location;
  //           }
  //           if (obj.city != null) {
  //             if (this.city_list_data != undefined) {
  //               for (var i = 0; i < this.city_list_data.length; i++) {
  //                 if (this.city_list_data[i].city_desc == obj.city) {
  //                   this.Search_City = this.city_list_data[i];
  //                 }
  //               }
  //             }
  //           }
  //           if (obj.state != null) {
  //             if (this.state_list_data != undefined) {
  //               for (var i = 0; i < this.state_list_data.length; i++) {

  //                 if (this.state_list_data[i].state_desc == obj.state) {


  //                   this.Search_State = this.state_list_data[i];
  //                 }
  //               }
  //             }
  //           }
  //           if (obj.country != null) {
  //             if (this.country_list_data != undefined) {
  //               for (var i = 0; i < this.country_list_data.length; i++) {

  //                 if (this.country_list_data[i].country_desc == obj.country) {


  //                   this.filt_country = this.country_list_data[i];
  //                 }
  //               }
  //             }
  //           }
  //           if (obj.hptl_name != null) {
  //             if (this.diag_detail_List != undefined) {
  //               for (var i = 0; i < this.diag_detail_List.length; i++) {

  //                 if (this.diag_detail_List[i].diag_centre_name == obj.hptl_name) {


  //                   this.sercah_center = this.diag_detail_List[i];
  //                 }
  //               }
  //             }
  //           }


  //           if (obj.address1 != undefined) {
  //             var sb = new StringBuilder("");


  //             if (obj.address2 != null && obj.address2 != "") {
  //               sb.append(obj.address1 + "," + obj.address2);
  //             }
  //             else {
  //               sb.append(obj.address1);
  //             }

  //             sb.append("\n");
  //             sb.append(obj.location);
  //             sb.append("\n");
  //             sb.append(obj.city + "," + obj.state);
  //             sb.append("\n");
  //             sb.append(obj.country + "-" + obj.zipcode);
  //             sb.append("\n");
  //             sb.append(obj.telephone);

  //             this.obs_add_diag = sb.toString();
  //           }
  //         }
  //       },
  //       error => {

  //       }
  //     )
  // }

  addDiagPrescription() {
    this.adddiag=true;
    this.print_view_flag = true;
    Physio_Helper.setDiagSave(false);
    Physio_Helper.setMedDiag(false);
    // this.messageservice.sendDiagComponent("diag"); 
  }

  //list view codng
  appdiagpresList() {
    this.diag_pres_list = [];
    var headers = new Headers();
    var send_list;
    headers.append('Content-Type', 'application/json');
    if( this.patient_list.spl == "Dental"){ 
      this.home_care = "Dental";
      this.client_id = this.getRetdetails.case_hist_id;
    }
    
    if(this.patient_list.AppFlow == "Inpatient"){
      if(this.patient_list.sub_id != "" && this.patient_list.sub_id != undefined){
        send_list = JSON.stringify(
          {
            client_id: this.patient_list.Client_id,
            relation_id: this.patient_list.rel_id,
            sub_rel_id: this.patient_list.sub_id,
            spl_id: this.patient_list.spl_id,
            home_care:this.home_care,
            is_inpat: true
  
          });
      }else{
        send_list = JSON.stringify(
          {
            client_id: this.patient_list.Client_id,
            relation_id: this.patient_list.rel_id,
            spl_id: this.patient_list.spl_id,
            home_care:this.home_care,
            is_inpat: true
          });
      }
    }else{
      if(this.patient_list.sub_id != "" && this.patient_list.sub_id != undefined && this.patient_list.sub_id != "0"){
        send_list = JSON.stringify(
          {
            client_id: this.patient_list.Client_id,
            relation_id: this.patient_list.rel_id,
            sub_rel_id: this.patient_list.sub_id,
            spl_id: this.patient_list.spl_id,
            home_care:this.home_care,
          });
      }else{
        send_list = JSON.stringify(
          {
            client_id: this.patient_list.Client_id,
            relation_id: this.patient_list.rel_id,
            spl_id: this.patient_list.spl_id,
            home_care:this.home_care
          });
      }
    }
    
    this.http.post(this.url_txt, send_list, { headers: headers }).subscribe(
      response => {
        var data = response.json();
        this.diag_pres_list_data = [];
        console.log("appdiagpresList --"+JSON.stringify(send_list))
        console.log("appdiagpresList --"+JSON.stringify(data))
        if (data != null) {
          this.diag_pres_list_data = data.prescriptions;
          if (this.diag_pres_list_data != null) {
            for (var i = 0; i < this.diag_pres_list_data.length; i++) {
              var new_datalist = this.diag_pres_list_data[i];
              var Docname = "";
              var res_clientname = "";
              if (new_datalist.middle_name != undefined && new_datalist.middle_name != "") {
                Docname = new_datalist.first_name + " " + new_datalist.middle_name + " " + new_datalist.last_name;
              }else {
                Docname = new_datalist.first_name + " " + new_datalist.last_name;
              }

              var App_date = "";
              if (new_datalist.date != null) {
                App_date = new_datalist.date.split('-')[2] + "-" + new_datalist.date.split('-')[1] + "-" + new_datalist.date.split('-')[0];
              }

              var diag_centre_name = "";
              this.diag_pres_list.push({
                pres_diag_id: this.diag_pres_list_data[i].pres_diag_id,
                doctorname: Docname,
                // clientname: res_clientname,
                date: App_date,
                diag_centre_name: this.diag_pres_list_data[i].diag_centre_name,
              });
              console.log("appdiagpresList --"+JSON.stringify(this.diag_pres_list))

            }
            this.spiltarea("default");
          }
          if (this.diag_pres_list.length == 0) {
            this.diag_pres_listdata = true;
          }
          else {
            this.diag_pres_listdata = false;
          }
        }
      },
      error => {
      })
  }

  Select_list(pres_diag_id) {
    this.print_view_flag = false;
    this.doct_address1 = [];
    this.test_check = false;
    this.diag_view_list = true;
    this.Hospdetails = false;
    this.pres_id = pres_diag_id;
    this.diag_url_txt = ipaddress.getIp + "prescription/dpresdet";
    this.diag_url_det = ipaddress.getIp + "diagnosiscontroller/diagdetails";
    this.flagtxt = "booking";

    this.send_data = {
      pres_id: this.pres_id,
      flag: this.flagtxt
    }

    this.seacrhbtn = true;
    this.bookbtn = true;
    this.getdiagdetail();
    this.naviagationService.filter('');
    this.spiltarea('details_view')
    this.naviagationService.filter('detail_view');
   
  }

  getdiagdetail() {
    this.test_type = [];
    var headers = new Headers;
    // console.log("check send_data --"+JSON.stringify(this.send_data))
    headers.append('Content-Type', 'application/json');
    this.http.post(this.diag_url_txt, JSON.stringify(
      this.send_data
    ), { headers: headers }).subscribe(
      response => {
        var data = response.json();
        // console.log("check data --"+JSON.stringify(data))
        var doct_mname, address, country, telephone, hosp_address, h_telephone;
        this.print_view_flag = false;
        if (data.middle_name != null) {
          doct_mname = data.first_name + ' ' + data.middle_name + ' ' + data.last_name;
        }
        else {
          doct_mname = data.first_name + ' ' + data.last_name;

        }
        if (data.address2 != null && data.address2 != undefined) {
          address = data.address1 + ' ' + data.address2 + ', ';
        }
        else {
          if (data.address1 != null && data.address1 != undefined) {
            address = data.address1 + ', ';
          }
        }
        if (data.hosp_address2 != null && data.hosp_address2 != undefined) {
          hosp_address = data.hosp_address1 + ', ' + data.hosp_address2 + ', ';
        }
        else {
          if (data.hosp_address1 != null && data.hosp_address1 != undefined) {
            hosp_address = data.hosp_address1 + ', ';
          }
        }
        if (data.telephone != null && data.telephone != undefined) {
          telephone = data.telephone;
        }
        if (data.hosp_telephone != null && data.hosp_telephone != undefined) {
          h_telephone = data.hosp_telephone;
        }
        if ((data.pres_date) != undefined) {
          var dateSplit = (data.pres_date).split("-");
          this.app_date = dateSplit[2] + "-" + dateSplit[1] + "-" + dateSplit[0];
        }

        if (data.hosp_location != null && data.hosp_location != undefined) {
          data.hosp_location;
        }
        if (data.diag_location != null && data.diag_location != undefined) {
          this.diag_location = data.diag_location;
        }
        if (data.diag_id != null && data.diag_id != undefined) {
          this.diag_id = data.diag_id;
          this.bookbtn = false;
        }
        if (data.hosp_city != null && data.hosp_city != undefined) {
          data.hosp_city;
        }
        if (data.hosp_zipcode != null && data.hosp_zipcode != undefined) {
          data.hosp_zipcode;
        }
        if (data.hosp_state != null && data.hosp_state != undefined) {
          data.hosp_state;
        }
        if (data.hosp_country != null && data.hosp_country != undefined) {
          data.hosp_country;
        }
        if (data.diag_name != null && data.diag_name != undefined) {
          data.diag_name;
        }
        if (data.tests != null && data.tests.length != 0) {
          this.temp_test_data = {};

          for (var i = 0; i < data.tests.length; i++) {
            var t_name = data.tests[i].replace("_", " ");
            t_name = t_name.charAt(0).toUpperCase() + t_name.substr(1).toLowerCase();
            this.temp_test_data = {
              test: t_name
            };

            if (data[data.tests[i]] != null) {
              this.test_check = true;
              this.testname_sub = "";
              this.testname = "";
              this.pres_test_id = "";
              for (var j = 0; j < data[data.tests[i]].length; j++) {
                if (j == data[data.tests[i]].length - 1 && i == data.tests[i].length - 1) {
                  this.testname = this.testname + data[data.tests[i]][j].test_name;
                } else {
                  this.testname = this.testname + data[data.tests[i]][j].test_name + ", ";
                }
                if (j == data[data.tests[i]].length - 1) {
                  this.testname_sub = this.testname_sub + data[data.tests[i]][j].test_name;
                  this.pres_test_id = this.pres_test_id + data[data.tests[i]][j].pres_test_id;
                } else {
                  this.testname_sub = this.testname_sub + data[data.tests[i]][j].test_name + ", ";
                  this.pres_test_id = this.pres_test_id + data[data.tests[i]][j].pres_test_id + ", ";
                }
              }
              this.test_type.push({
                test: t_name,
                subtest: this.testname_sub,
                pres_test_id: this.pres_test_id
              })
            } else {
              this.test_check = false;
            }
          }
        }
        else {

        }
        if (this.test_type != null && this.test_type.length != 0 && this.flag == "diagnosis") {
          this.seacrhbtn = false;
        }

        if (data.hosp_name != undefined) {
          this.hospital_name = data.hosp_name;
          this.Hospdetails = true;
        }
        if (data.hosp_city != undefined) {
          this.hospital_city = data.hosp_city + ' - ' + data.hosp_zipcode;
        }
        if (data.hosp_location != undefined) {
          this.hospital_location = data.hosp_location;
        }
        if (data.hosp_state != undefined) {
          this.hospital_state = data.hosp_state + ' - ' + data.hosp_country;
        }
        this.doct_address1.push({
          name: doct_mname,
          address1: address,
          location: this.diag_location,
          city: data.city + ' - ' + data.zipcode,
          state: data.state + ", " + data.country,
          telephone: telephone,
          hosp_name: this.hospital_name,
          hos_address: hosp_address,
          hos_location: this.hospital_location,
          hos_city: this.hospital_city,
          hos_state: this.hospital_state,
          hos_telephone: h_telephone,
          relation: data.relation_name,
          date: this.app_date,
          hos_center: data.diag_name,
          diag_id: this.diag_id,
        });
        this.getdiagdirectdetails(this.diag_location, this.diag_id);
      }, error => {

      }
    );
    
  }

  getdiagdirectdetails(diag_location, diag_id) {
    this.send_diag_data = {
      location: diag_location,
      diag_id: diag_id,
    }

    var headers = new Headers;
    headers.append('Content-Type', 'application/json');
    this.http.post(this.diag_url_det, JSON.stringify(this.send_diag_data), { headers: headers }).subscribe(
      response => {
        var data = response.json();
        
        this.diag_list = [];
        var address, telephone, website, workday, available, time_available;

        if (data.address2 != null && data.address2 != undefined) {
          address = (data.address1) + ', ' + (data.address2 + ', ');
        } else {
          if (data.address1 != null && data.address1 != undefined) {
            address = (data.address1) + ', ';
          }
        }

        if (data.telephone != null && data.telephone != undefined) {
          telephone = data.telephone;
        }

        if (data.website != null && data.website != undefined) {
          website = data.website;
        } else {
          website = " - ";
        }

        if (data.work_days != null && data.work_days != undefined) {
          // workday = this.comm_Data.get_diabday(data.work_days);
        }

        if (workday != null && workday != undefined || data.available_from != null && data.available_from != undefined &&
          data.available_to != null && data.available_to != undefined) {

          // available = workday + ", " + this.comm_Data.get_Timeformate(data.available_from) + "- " +
          //   this.comm_Data.get_Timeformate(data.available_to)
        }

        var diag_tests_list;
        diag_tests_list = this.test_type;

        this.diag_list.push({
          center_id: data.diag_id,
          center_name: data.diag_name,
          city: data.city + "- " + data.zipcode,
          location: data.location,
          state: data.state + ", " + data.country,
          telephone: telephone,
          profile_image: ipaddress.Ip_with_img_address + data.profile_image,
          website: website,
          available: available,
          diag_tests_list: diag_tests_list,
          address: address
        })

      }, error => {

      }
    );
  }

  Back() {
    this.adddiag = false;
  }

  test1(){
    var test = "4";
    return test;
  }

  print_Viewprescription(){
    var footerimg = this.hosp_footer;
    var footerflag = this.printf_flag;
    // var backimg = this.bg_image;
    let printContents, popupWin;
    printContents = this.printnoheadsidebar.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
      <head>
        <title>Prescription</title>
        <link rel="stylesheet" media="screen" href="">
        <style>
        @page { size: auto;  margin: 0mm; }
        .table_class {
          background-color: rgb(108, 180, 248);
        }
        .casesheet_container {
          width: 211px;
          padding: 1px;
          background: #277196;
          display: inline-flex;
          position: relative;
          padding-left: 5px;
          top: 21px;
          color: #FFFFFF;
        }
        .border_style{
          border:solid 1px;
          border-color: #488aff;
        }
        .width_style{
          margin-top:4px;
          width:600px;
        }
        @media all {
          .page-break { display: none; }
          }
          
          @media print {
          .page-break { display: block; page-break-before: always; }
          }
        @media print {
          body{
            -webkit-print-color-adjust: exact;
            -moz-print-color-adjust: exact;
            -ms-print-color-adjust: exact;
            print-color-adjust: exact;
          }
          footer {
            position: fixed;
            bottom: 0;
          }
        }
        </style>
      </head>
      <body onload="window.print();window.onfocus=function(){ window.close();}" >${printContents}</body>
      <footer [hidden]=${footerflag}>
      <img src=${footerimg}>
    </footer>
    </html>`
    );
    popupWin.document.close();
    this.print_view_flag = true;
    this.diag_view_list = false;
    this.spiltarea('default');
  }

  
  print_area() {
    var flag = true;

    
    if (Physio_Helper.getDiagAppflow() == "diag") {
      if (this.subtestuname.length != 0) {
        // for (var i = 0; i < this.subtesturname.length; i++) {
        //   this.ultra_test_array.push(this.subtesturname[i].test_id);
        // }
        flag = true;
      } else if (this.subtestfname.length != 0) {
        // for (var i = 0; i < this.subtestfname.length; i++) {
        //   this.feace_test_array.push(this.subtestfname[i].test_id);
        // }
        flag = true;
      } else if (this.subtestname.length != 0) {
        // for (var i = 0; i < this.subtestname.length; i++) {
        //   this.blood_test.push(this.subtestname[i].test_id);
        // }
        flag = true;
      } else if (this.subtestsname.length != 0) {
        // for (var i = 0; i < this.subtestsname.length; i++) {
        //   this.scan_test_array.push(this.subtestsname[i].test_id);
        // }
        flag = true;
      } else if (this.subtestxname.length != 0) {
        // for (var i = 0; i < this.subtestxname.length; i++) {
        //   this.xray_test_array.push(this.subtestxname[i].test_id);
        // }
        flag = true;
      } else if (this.subtesturname.length != 0) {
        // for (var i = 0; i < this.subtesturname.length; i++) {
        //   this.urine_test_array.push(this.subtesturname[i].test_id);
        // }
        flag = true;
      } else if (this.subtestbname.length != 0) {
        flag = true;
      } else if (this.subtestcname.length != 0) {
        flag = true;
      } else {
        flag = false;
      }
      if (flag == false) {
        this.toastr.error(Message_data.enterDiagpres);
      }
    }

    // if (this.listProducts.length != 0) {
    //   this.print_med = true;
    // }

    if (this.print_view_flag == false) {
      flag = true;
    }

    if (flag == true) {
      if (Helper_Class.get_med_pres_id() == undefined) {
        if (this.print_view_flag == true) {
          this.savePrescription(this.print_template);
        }
      }
      var footerimg = this.hosp_footer;
      var footerflag = this.printf_flag;
      var backimg = this.bg_image;
      let printContents, popupWin;

      if (this.print_template != undefined && this.print_template == "banner") {
        printContents = this.printbanner.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;

      } else if (this.print_template != undefined && this.print_template == "logowithname") {
        printContents = this.printlogowithname.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;

      } else if (this.print_template != undefined && this.print_template == "printnoheadsidebar") {
        printContents = this.printnoheadsidebar.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;

      } else {
        printContents = this.printnoheader.nativeElement.innerHTML;
      }
      popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
      popupWin.document.open();
      popupWin.document.write(`
        <html>
        <head>
          <title>Prescription</title>
          <link rel="stylesheet" media="screen" href="">
          <style>
          @page { size: auto;  margin: 0mm; }
          .table_class {
            background-color: rgb(108, 180, 248);
          }
          .casesheet_container {
            width: 211px;
            padding: 1px;
            background: #277196;
            display: inline-flex;
            position: relative;
            padding-left: 5px;
            top: 21px;
            color: #FFFFFF;
          }
          .border_style{
            border:solid 1px;
            border-color: #488aff;
          }
          .width_style{
            margin-top:4px;
            width:600px;
          }
          @media all {
            .page-break { display: none; }
            }
            
            @media print {
            .page-break { display: block; page-break-before: always; }
            }
          @media print {
            body{
              -webkit-print-color-adjust: exact;
              -moz-print-color-adjust: exact;
              -ms-print-color-adjust: exact;
              print-color-adjust: exact;
            }
            footer {
              position: fixed;
              bottom: 0;
            }
          }
          </style>
        </head>
        <body onload="window.print();window.onfocus=function(){ window.close();}" >${printContents}</body>
        <footer [hidden]=${footerflag}>
        <img src=${footerimg}>
      </footer>
      </html>`
      );
      popupWin.document.close();
      printContents = "";
      if (this.print_view_flag == false) {
        this.next_txt = "";
       
        this.disease_flag = false;
        this.spiltarea('default');
        this.print_diag = false;
        this.Blood_test = [];
        this.sercah_center = "";
        this.obs_add_diag = "";
        this.subtestbname = [];
        this.subtestfname = [];
        this.subtestname = [];
        this.subtestname = [];
        this.subtestsname = [];
        this.subtestuname = [];
        this.subtesturname = [];
        this.subtestxname = [];
        this.subtestcname = [];
      } else {
        this.next_txt = "";
       
        this.print_diag = false;
      }
      this.print_view_flag = true;
      //this.listProducts = [];
    }
  }
  savePrescription(data) {
    if (data == "print") {
      this.Medical_prescription_save(null);
      this.printpres = "1";
    } else {
      
      this.printpres = "0";
      if (this.print_template != undefined && this.print_template == "banner") {
        this.bannerTemplateToPdf();

      } else if (this.print_template != undefined && this.print_template == "logowithname") {
        this.printlogowithnamepdf();

      } else if (this.print_template != undefined && this.print_template == "printnoheadsidebar") {
        //printContents = this.printnoheadsidebar.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;

      } else {
        this.printnoheaderdf();
      }
    }
  }
  Medical_prescription_save(pdffilevalue) {
    var flag = true;
   
    if (Physio_Helper.getDiagAppflow() == "diag") {
      
        flag = false;
        this.Diagnosis_prescription_save();
      
    }


   

    // if (flag == true) {
    //   var sen_pass;
    //   //var pres = this.presID != undefined ? this.presID : undefined;

    //   var splid;
    //   if(this.personalinfo != undefined){
    //     splid = this.personalinfo.spl_id;
    //   } else {
    //     splid = Helper_Class.getspl_id();
    //   }
    //   var medid;
    //   if(this.userinfo.medicares != undefined){
    //     medid = this.userinfo.medicares[0].medicare_id;

    //   } else {
    //     medid="1";
    //   }

    //   if (this.personalinfo.sub_id.length != 0) {
    //     if (pdffilevalue != null) {
    //       sen_pass = JSON.stringify({
    //         client_reg_id: this.personalinfo.Client_id,
    //         relation_id: this.personalinfo.rel_id,
    //         sub_rel_id: this.personalinfo.sub_id,
    //         appointment_id: this.personalinfo.app_id,
    //         date: this.CurrentDatetime,
    //         disease: this.dis_txt,
    //         investigation: this.oberse_med,
    //         instructions: this.instructions,
    //         next_visit: this.next_datetxt,
    //         pharmacy_id: this.pharma_id,
    //         order_now: ordernow,
    //         language: this.language,
    //         pdfdoc: pdffilevalue,
    //         pdfip: ipaddress.Ip_with_img_address,
    //         print: this.printpres,
    //         newsymptoms: new_symptoms,
    //         mobile: this.personalinfo.mobile,
    //         doc_reg_id: this.user_id,
    //         pres_drug_id: pres,
    //         book_now:this.book_app_now,
    //         hptl_clinic_id:this.hospital_clinic_id,
    //         time:"1",
    //         medicare_id:medid,
    //         specialization_id:splid,
    //         gender:this.client_gender,
    //         mem_mobile: this.personalinfo.mobile,
    //         country:ipaddress.country_code,
    //         req_estimate:this.req_estimate,
    //         medicines: this.listProducts,
    //       });

    //     } else {
    //       sen_pass = JSON.stringify({
    //         client_reg_id: this.personalinfo.Client_id,
    //         relation_id: this.personalinfo.rel_id,
    //         sub_rel_id: this.personalinfo.sub_id,
    //         appointment_id: this.personalinfo.app_id,
    //         date: this.CurrentDatetime,
    //         disease: this.dis_txt,
    //         investigation: this.oberse_med,
    //         instructions: this.instructions,
    //         next_visit: this.next_datetxt,
    //         pharmacy_id: this.pharma_id,
    //         order_now: ordernow,
    //         language: this.language,
    //         pdfip: ipaddress.Ip_with_img_address,
    //         print: this.printpres,
    //         newsymptoms: new_symptoms,
    //         mobile: this.personalinfo.mobile,
    //         doc_reg_id: this.user_id,
    //         pres_drug_id: pres,
    //         book_now:this.book_app_now,
    //         hptl_clinic_id:this.hospital_clinic_id,
    //         time:"1",
    //         medicare_id:medid,
    //         specialization_id:splid,
    //         gender:this.client_gender,
    //         mem_mobile: this.personalinfo.mobile,
    //         country:ipaddress.country_code,
    //         req_estimate:this.req_estimate,
    //         medicines: this.listProducts,
    //       });
    //     }

    //   } else {
    //     if (pdffilevalue != null) {
    //       sen_pass = JSON.stringify({
    //         client_reg_id: this.personalinfo.Client_id,
    //         relation_id: this.personalinfo.rel_id,
    //         appointment_id: this.personalinfo.app_id,
    //         date: this.CurrentDatetime,
    //         disease: this.dis_txt,
    //         comment: this.oberse_med,
    //         investigation: this.oberse_med,
    //         instructions: this.instructions,
    //         next_visit: this.next_datetxt,
    //         pharmacy_id: this.pharma_id,
    //         order_now: ordernow,
    //         language: this.language,
    //         pdfdoc: pdffilevalue,
    //         pdfip: ipaddress.Ip_with_img_address,
    //         print: this.printpres,
    //         newsymptoms: new_symptoms,
    //         mobile: this.personalinfo.mobile,
    //         doc_reg_id: this.user_id,
    //         pres_drug_id: pres,
    //         book_now:this.book_app_now,
    //         hptl_clinic_id:this.hospital_clinic_id,
    //         time:"1",
    //         medicare_id:medid,
    //         specialization_id:splid,
    //         gender:this.client_gender,
    //         mem_mobile: this.personalinfo.mobile,
    //         country:ipaddress.country_code,
    //         req_estimate:this.req_estimate,
    //         medicines: this.listProducts,
    //       });

    //     } else {
    //       sen_pass = JSON.stringify({
    //         client_reg_id: this.personalinfo.Client_id,
    //         relation_id: this.personalinfo.rel_id,
    //         appointment_id: this.personalinfo.app_id,
    //         date: this.CurrentDatetime,
    //         disease: this.dis_txt,
    //         comment: this.oberse_med,
    //         investigation: this.oberse_med,
    //         instructions: this.instructions,
    //         next_visit: this.next_datetxt,
    //         pharmacy_id: this.pharma_id,
    //         order_now: ordernow,
    //         language: this.language,
    //         pdfip: ipaddress.Ip_with_img_address,
    //         print: this.printpres,
    //         newsymptoms: new_symptoms,
    //         mobile: this.personalinfo.mobile,
    //         doc_reg_id: this.user_id,
    //         pres_drug_id: pres,
    //         book_now:this.book_app_now,
    //         hptl_clinic_id:this.hospital_clinic_id,
    //         time:"1",
    //         medicare_id:medid,
    //         specialization_id:splid,
    //         gender:this.client_gender,
    //         mem_mobile: this.personalinfo.mobile,
    //         country:ipaddress.country_code,
    //         req_estimate:this.req_estimate,
    //         medicines: this.listProducts,
    //       });
    //     }
    //   }

    //   if (Physio_Helper.getAdmission_id() != undefined && Physio_Helper.getAdmission_id().flag == "edit" && pres != undefined) {
    //     var headers = new Headers();
    //     headers.append('Content-Type', 'application/json');
    //     this.http.post(ipaddress.getIp.toString() + 'prescription/upres', sen_pass,
    //       { headers: headers })
    //       .subscribe(
    //         response => {
    //           var obj = response.json();
    //           if (obj.key != 0) {
    //             Physio_Helper.setMedForDischarge(this.listProducts);
    //             this.toastr.success("Prescription updated successfully");
    //             this.naviagationService.sendMessage("inPatientDS");
    //           }
    //         }
    //       )
          
    //   } else {
    //     var headers = new Headers();
    //     headers.append('Content-Type', 'application/json');
    //     this.http.post(ipaddress.getIp.toString() + 'prescription/savepres/', sen_pass,
    //       { headers: headers })
    //       .subscribe(
    //         response => {
    //           var obj = response.json();
    //           Physio_Helper.setMedForDischarge(this.listProducts);
    //           this.print_med = true;
    //           var flag_diag = true;
    //           if (this.personalinfo.discharge_id != "" && this.personalinfo.discharge_id != undefined)  {
    //             Helper_Class.set_med_pres_id(obj["pres_id"]);
    //             this.getPres();
    //           } else {
    //             if (this.subtestuname.length != 0) {
    //               flag_diag = true;
    //             } else if (this.subtestfname.length != 0) {
    //               flag_diag = true;
    //             } else if (this.subtestname.length != 0) {
    //               flag_diag = true;
    //             } else if (this.subtestsname.length != 0) {
    //               flag_diag = true;
    //             } else if (this.subtestxname.length != 0) {
    //               flag_diag = true;
    //             } else if (this.subtesturname.length != 0) {
    //               flag_diag = true;
    //             } else if (this.subtestbname.length != 0) {
    //               flag_diag = true;
    //             } else if (this.subtestcname.length != 0) {
    //               flag = true;
    //             } else {
    //               flag_diag = false;
    //             }
  
    //             if (flag_diag == true) {
    //               this.Diagnosis_prescription_save();
    //             } else {
    //               if (this.home_care != "0" && this.home_care != undefined) {
    //                 if (obj["pres_id"] != null) {
    //                   Helper_Class.set_med_pres_id(obj["pres_id"]);
    //                   if (this.personalinfo.AppFlow == "Doc_app_list") {
    //                     if (Physio_Helper.getDiagAppflow() == 'medical') {
    //                       Physio_Helper.setMedSave(true);
    //                       this.listProducts = [];
    //                       this.appmedpresList();
    //                       this.addmed = false;
    //                       this.messageservice.sendDiagComponent("save_med");

    //                     } else {
    //                       Physio_Helper.setMedDiag(true);
    //                       this.subtestbname = [];
    //                       this.subtestfname = [];
    //                       this.subtestname = [];
    //                       this.subtestsname = [];
    //                       this.subtestuname = [];
    //                       this.subtesturname = [];
    //                       this.subtestxname = [];
    //                       this.subtestcname = [];
    //                       this.messageservice.sendDiagComponent("save_diag");
    //                     }
    //                     this.saveflag = true;
    //                     this.toastr.success(Message_data.prescriptionSavedSuccess);
  
    //                   } else if (this.personalinfo.AppFlow == "Walkin") {
    //                     if (Physio_Helper.getDiagAppflow() == 'medical') {
    //                       Physio_Helper.setMedSave(true);
    //                       this.listProducts = [];
    //                       this.appmedpresList();
    //                       this.addmed = false;
    //                       this.messageservice.sendDiagComponent("save_med");
    //                     } else {
    //                       Physio_Helper.setMedDiag(true);
    //                       this.subtestbname = [];
    //                       this.subtestfname = [];
    //                       this.subtestname = [];
    //                       this.subtestsname = [];
    //                       this.subtestuname = [];
    //                       this.subtesturname = [];
    //                       this.subtestxname = [];
    //                       this.subtestcname = [];
    //                       this.messageservice.sendDiagComponent("save_diag");
    //                     }
    //                     this.saveflag = true;
    //                     this.toastr.success(Message_data.prescriptionSavedSuccess);
    //                   } else if (this.personalinfo.AppFlow == "Inpatient") {
    //                     Physio_Helper.setMedSave(true);
    //                     this.listProducts = [];
    //                     this.appmedpresList();
    //                     this.addmed = false;
    //                     this.messageservice.sendDiagComponent("save_med");
    //                   }
    //                   else {
    //                     this.toastr.success(Message_data.prescriptionSavedSuccess);
    //                     this.backInpatiantInfo();
    //                     // this.appmedpresList();
    //                     this.addmed = false;
    //                   }
    //                   this.book_app_now=false;
    //                   this.next_datetxt=undefined;

    //                 } else {
    //                   this.saveflag = true;
    //                   this.toastr.error(Message_data.unableToSavePrescription);
    //                 }
  
    //               } else {
    //                 Helper_Class.set_med_pres_id(null);
    //                 if (obj["pres_id"] != undefined && obj["pres_id"] != null) {
    //                   Helper_Class.set_med_pres_id(obj["pres_id"]);
    //                   this.diab_medpres = obj["pres_id"];
  
    //                   if (this.personalinfo.AppFlow == "Doc_app_list") {
    //                     if (Physio_Helper.getDiagAppflow() == 'medical') {
    //                       Physio_Helper.setMedSave(true);
    //                       this.listProducts = [];
    //                       this.appmedpresList();
    //                       this.addmed = false;
    //                       this.messageservice.sendDiagComponent("save_med");
    //                     } else {
    //                       Physio_Helper.setMedDiag(true);
    //                       this.subtestbname = [];
    //                       this.subtestfname = [];
    //                       this.subtestname = [];
    //                       this.subtestsname = [];
    //                       this.subtestuname = [];
    //                       this.subtesturname = [];
    //                       this.subtestxname = [];
    //                       this.subtestcname = [];
    //                       this.messageservice.sendDiagComponent("save_diag");
    //                     }
    //                     this.saveflag = true;
    //                     this.toastr.success(Message_data.prescriptionSavedSuccess);
  
    //                   } else if (this.personalinfo.AppFlow == "Walkin") {
    //                     if (Physio_Helper.getDiagAppflow() == 'medical') {
    //                       Physio_Helper.setMedSave(true);
    //                       this.listProducts = [];
    //                       this.appmedpresList();
    //                       this.addmed = false;
    //                       this.messageservice.sendDiagComponent("save_med");
    //                     } else {
    //                       Physio_Helper.setMedDiag(true);
    //                       this.subtestbname = [];
    //                       this.subtestfname = [];
    //                       this.subtestname = [];
    //                       this.subtestsname = [];
    //                       this.subtestuname = [];
    //                       this.subtesturname = [];
    //                       this.subtestxname = [];
    //                       this.subtestcname = [];
    //                       this.messageservice.sendDiagComponent("save_diag");
    //                     }
    //                     this.saveflag = true;
    //                     this.toastr.success(Message_data.prescriptionSavedSuccess);
  
    //                   } else if (this.personalinfo.AppFlow == "Inpatient") {
    //                     Physio_Helper.setMedSave(true);
    //                     this.listProducts = [];
    //                     this.appmedpresList();
    //                     this.addmed = false;
    //                     this.messageservice.sendDiagComponent("save_med");
    //                   } else {
    //                     this.toastr.success(Message_data.prescriptionSavedSuccess);
    //                     this.backInpatiantInfo();
    //                     //this.appmedpresList();
    //                     this.addmed = false;
    //                   }
  
    //                   //this.addmed = false;
    //                   var dat = this.check_pres;
    //                   if (dat == "0") {
    //                     this.check_pres = "1";
  
    //                   } else {
    //                     var seconds = 1;
    //                     setInterval(function () {
    //                       seconds--;
  
    //                     }, 500);
    //                   }

    //                   this.book_app_now=false;
    //                   this.next_datetxt=undefined;
    //                 }
    //                 else {
    //                   this.saveflag = true;
    //                   this.toastr.error(Message_data.unableToSavePrescription);
    //                 }
    //               }
    //             }
    //           }

             
    //         },
    //         error => {
    //         }
    //       )
    //   }

    // }
  }

  printlogowithnamepdf() {
    // var tempArr = [];
    // var pdffile;
  
    // if (this.next_txt == undefined) {
    //   this.next_txt = "";
    // }
    // if (this.dct_hospital == undefined) {
    //   this.dct_hospital = "";
    // }
    // if (this.doct_address == undefined) {
    //   this.doct_address = "";
    // }

    // let docDefinition = {
    //   content: [
    //     {
    //       style: 'personaltable',
    //       table: {
    //         widths: [60, 600],
    //         body: [
    //           [
    //             {
    //               rowspan: 4,
    //            //   image: this.imagestring,
    //               width: 50,
    //               height: 50,
    //             },
    //             [
    //               {
    //                 table: {
    //                   widths: [310, 250],
    //                   style: 'personaltable',
    //                   body: [
    //                     [this.dct_hospital, 'Doctor: Dr.' + this.doct_name],
    //                     [this.doct_address + " " + this.doct_location, 'Date: ' + this.Appoint_Date],
    //                     [this.doct_city, ' '],
    //                     [this.doct_state, ' '],
    //                   ]
    //                 },
    //                 layout: 'noBorders'
    //               }
    //             ],
    //           ],
    //         ]
    //       },
    //       layout: 'noBorders'
    //     },
    //     {
    //       style: 'personaltable',
    //       table: {
    //         widths: [400, 300],
    //         body: [
    //           ['Name: ' + this.doct_clientname, 'BP: ' + this.clnt_bp],
    //           ['', ''],
    //           ['Age: ' + this.client_age + '     Gender: ' + this.client_gender, ' Pulse: ' + this.clnt_pulse],
    //         ]
    //       },
    //       layout: 'noBorders'
    //     },
    //     {
    //       text: 'Investigation: ' + this.oberse_med,
    //       margin: [0, 20, 0, 0],
    //       style: 'diagstyle'
    //     },
    //     {
    //       text: 'Diagnosis: ' + this.dis_txt,
    //       margin: [0, 20, 0, 20],
    //       style: 'diagstyle'
    //     },
    //     this.medicinetable(tempArr, ['Medicine', 'Dosage', 'Duration', 'Frequency', 'Note']),
    //     {
    //       text: 'Instructions: ' + this.instructions,
    //       margin: [0, 20, 0, 20],
    //       style: 'diagstyle'
    //     },
    //     {
    //       text: 'Next visit: ' + this.next_txt,
    //       margin: [0, 20, 0, 20],
    //       style: 'diagstyle'
    //     },
    //     {
    //       text: 'Dr. ' + this.doct_name + " " + this.doc_qualif,
    //       margin: [0, 5, 0, 5],
    //       style: 'diagstyle'
    //     }, {
    //       text: 'Registration no: ' + this.licence_code,
    //       margin: [0, 5, 0, 5],
    //       style: 'diagstyle'
    //     }, {
    //       text: 'Find your prescription online at www.tervys.com',
    //       margin: [0, 5, 0, 5],
    //       style: 'diagstyle'
    //     }, {
    //       text: 'This is digitally generated prescription',
    //       margin: [0, 5, 0, 5],
    //       style: 'diagstyle'
    //     }
    //   ],
    //   styles: {
    //     headertable: {
    //       fontSize: 12,
    //       width: 800,
    //       marginLeft: 120,
    //       marginTop: -200
    //     },
    //     personaltable: {
    //       fontSize: 12,
    //       width: 800,
    //     },
    //     diagstyle: {
    //       fontSize: 12,
    //       bold: true,
    //     }
    //   }
    // };
    // this.pdfObj = pdfMake.createPdf(docDefinition);

    // this.pdfObj.getBuffer((buffer) => {
    //   var blob = new Blob([buffer], {
    //     type: 'application/pdf'
    //   });

    //   var reader = new FileReader();
    //   reader.readAsDataURL(blob);
    //   reader.onloadend = function (this: any) {
    //     pdffile = reader.result;
    //     this.Medical_prescription_save(pdffile);
    //   }.bind(this);
    // });
  }

  printnoheaderdf() {
    // var tempArr = [];
    // var pdffile;
   
    // if (this.next_txt == undefined) {
    //   this.next_txt = "";
    // }

    // let docDefinition = {
    //   content: [
    //     {
    //       style: 'personaltable',
    //       margin: [0, 150, 0, 0],
    //       table: {
    //         widths: [400, 300],
    //         body: [
    //           ['Name: ' + this.doct_clientname, 'BP: ' + this.clnt_bp],
    //           ['', ''],
    //           ['Age: ' + this.client_age + '     Gender: ' + this.client_gender, ' Pulse: ' + this.clnt_pulse],
    //         ]
    //       },
    //       layout: 'noBorders'
    //     },
        
    //     {
    //       text: 'Next visit: ' + this.next_txt,
    //       margin: [0, 20, 0, 20],
    //       style: 'diagstyle'
    //     },
    //     {
    //       text: 'Dr. ' + this.doct_name + " " + this.doc_qualif,
    //       margin: [0, 5, 0, 5],
    //       style: 'diagstyle'
    //     }, {
    //       text: 'Registration no: ' + this.licence_code,
    //       margin: [0, 5, 0, 5],
    //       style: 'diagstyle'
    //     }, {
    //       text: 'Find your prescription online at www.tervys.com',
    //       margin: [0, 5, 0, 5],
    //       style: 'diagstyle'
    //     }, {
    //       text: 'This is digitally generated prescription',
    //       margin: [0, 5, 0, 5],
    //       style: 'diagstyle'
    //     }
    //   ],
    //   styles: {
    //     headertable: {
    //       fontSize: 12,
    //       width: 800,
    //       marginLeft: 120,
    //       marginTop: -200
    //     },
    //     personaltable: {
    //       fontSize: 12,
    //       width: 800,
    //     },
    //     diagstyle: {
    //       fontSize: 12,
    //       bold: true,
    //     }
    //   }
    // };
    // this.pdfObj = pdfMake.createPdf(docDefinition);
    // //this.save_data(this.pdfObj);
    // this.pdfObj.getBuffer((buffer) => {
    //   var blob = new Blob([buffer], {
    //     type: 'application/pdf'
    //   });

    //   var reader = new FileReader();
    //   reader.readAsDataURL(blob);
    //   reader.onloadend = function (this: any,) {
    //     pdffile = reader.result;
    //     this.Medical_prescription_save(pdffile);
    //   }.bind(this);
    // });
  }

  
  bannerTemplateToPdf() {
    var tempArr = [];
    var pdffile;
  
    // let docDefinition = {
    //   content: [
    //     {
    //       image: this.imageString,
    //       width: 50,
    //       height: 50,
    //       margin: [-30, -20, 0, 20],
    //       style: 'personaltable',
    //       table: {
    //         widths: [400, 300],
    //         body: [
    //           ['Name: ' + this.clientName, 'Date: ' + this.appointDate],
    //           ['', ''],
    //           ['Age: ' + this.clntAge + '     Gender: ' + this.clntGender, 'BP: ' + this.clntBp + '     Pulse: ' + this.clntPulse],
    //         ]
    //       },
    //       layout: 'noBorders'
    //     },
    //     {
    //       style: 'personaltable',
    //       table: {
    //         widths: [400, 300],
    //         body: [
    //           ['Name: ' + this.clientName, 'Date: ' + this.appointDate],
    //           ['', ''],
    //           ['Age: ' + this.clntAge + '     Gender: ' + this.clntGender, 'BP: ' + this.clntBp + '     Pulse: ' + this.clntPulse],
    //         ]
    //       },
    //       layout: 'noBorders'
    //     },
    //     {
    //       text: 'Investigation: ' + this.investigation,
    //       margin: [0, 20, 0, 0],
    //       style: 'diagstyle'
    //     },
    //     {
    //       text: 'Diagnosis: ' + this.diagnosis,
    //       margin: [0, 20, 0, 20],
    //       style: 'diagstyle'
    //     },
    //     this.medicineTable(tempArr, ['Medicine', 'Dosage', 'Duration', 'Frequency', 'Note']),
    //     {
    //       text: 'Instructions: ' + this.instructions,
    //       margin: [0, 20, 0, 20],
    //       style: 'diagstyle'
    //     },
    //     {
    //       text: 'Next visit: ' + this.printNextVisit,
    //       margin: [0, 20, 0, 20],
    //       style: 'diagstyle'
    //     },
    //     {
    //       text: 'Dr. ' + this.doctorName + " " + this.doctorQualif,
    //       margin: [0, 5, 0, 5],
    //       style: 'diagstyle'
    //     }, {
    //       text: 'Registration no: ' + this.licenceCode,
    //       margin: [0, 5, 0, 5],
    //       style: 'diagstyle'
    //     }, {
    //       text: 'Find your prescription online at www.tervys.com',
    //       margin: [0, 5, 0, 5],
    //       style: 'diagstyle'
    //     }, {
    //       text: 'This is digitally generated prescription',
    //       margin: [0, 5, 0, 5],
    //       style: 'diagstyle'
    //     }
    //   ],
    //   styles: {
    //     personaltable: {
    //       fontSize: 12,
    //       width: 800,
    //     },
    //     diagstyle: {
    //       fontSize: 12,
    //       bold: true,
    //     }
    //   }
    // };
    // this.pdfObj = pdfMake.createPdf(docDefinition);
    // this.pdfObj.getBuffer((buffer) => {
    //   var blob = new Blob([buffer], {
    //     type: 'application/pdf'
    //   });
    //   var reader = new FileReader();
    //   reader.readAsDataURL(blob);
    //   reader.onloadend = function (this: any) {
    //     pdffile = reader.result;
    //     this.Medical_prescription_save(pdffile);
    //   }.bind(this);
    // });
  }

  
  Diagnosis_prescription_save() {
    var flag = true;
    if (this.search_location == undefined || this.search_city == undefined || this.search_state == undefined || this.search_country == undefined || this.sercah_center == undefined) {
      flag = false;
      this.toastr.error(Message_data.mandatory);
    }
    if (flag == true && (this.subtestsname == undefined || this.subtestfname == undefined || this.subtestuname == undefined || this.subtesturname == undefined || this.subtestxname == undefined || this.subtestname == undefined || this.subtestbname == undefined || this.subtestcname == undefined)) {
      flag = false;
      this.toastr.error(Message_data.sltTestType);
    }

    if (this.subtestuname.length != 0) {
      flag = true;
    } else if (this.subtestfname.length != 0) {
      flag = true;
    } else if (this.subtestname.length != 0) {
      flag = true;
    } else if (this.subtestsname.length != 0) {
      flag = true;
    } else if (this.subtestxname.length != 0) {
      flag = true;
    } else if (this.subtesturname.length != 0) {
      flag = true;
    } else if (this.subtestbname.length != 0) {
      flag = true;
    } else if (this.subtestcname.length != 0) {
      flag = true;
    } else {
      flag = false;
      this.toastr.error(Message_data.sltAtleastOneTest);
    }

    if (flag == true) {
      if (this.subtestname.length != 0) {
        for (var i = 0; i < this.subtestname.length; i++) {
          this.blood_test.push(this.subtestname[i].test_id);
        }
      }
      if (this.subtesturname.length != 0) {
        for (var i = 0; i < this.subtesturname.length; i++) {
          this.urine_test_array.push(this.subtesturname[i].test_id);
        }
      }
      if (this.subtestfname.length != 0) {
        for (var i = 0; i < this.subtestfname.length; i++) {
          this.feace_test_array.push(this.subtestfname[i].test_id);
        }
      }
      if (this.subtestsname.length != 0) {
        for (var i = 0; i < this.subtestsname.length; i++) {
          this.scan_test_array.push(this.subtestsname[i].test_id);
        }
      }
      if (this.subtestxname.length != 0) {
        for (var i = 0; i < this.subtestxname.length; i++) {
          this.xray_test_array.push(this.subtestxname[i].test_id);
        }
      }
      if (this.subtestuname.length != 0) {
        for (var i = 0; i < this.subtesturname.length; i++) {
          this.ultra_test_array.push(this.subtesturname[i].test_id);
        }
      }
      if (this.subtestcname.length != 0) {
        for (var i = 0; i < this.subtestcname.length; i++) {
          this.culture_test_array.push(this.subtestcname[i].test_id);
        }
      }
      if (this.subtestbname.length != 0) {
        for (var i = 0; i < this.subtestbname.length; i++) {
          this.biopsy_test_array.push(this.subtestbname[i].test_id);
        }
      }
      if (this.obs_diag != undefined) {
        this.observation = this.obs_diag;
      } else {
        this.observation = "";
      }
      if (this.test_now == true) {
        this.book_app = "1";
      } else {
        this.book_app = "0";
      }

      var sen_pass;
      if (this.personalinfo.sub_id != "" && this.personalinfo.sub_id != undefined && this.personalinfo.sub_id != "0") {
        sen_pass = JSON.stringify({
          appointment_id: this.personalinfo.app_id,
          client_reg_id: this.personalinfo.Client_id,
          relation_id: this.personalinfo.rel_id,
          sub_rel_id: this.personalinfo.sub_id,
          date: this.CurrentDatetime,
          diagnosis: "diag",
          disease: "",
          comment: this.observation,
          diagnosis_id: this.diag_centre_id,
          spl_id: this.personalinfo.spl_id,
          "Blood Test": this.blood_test,
          "Urine Test": this.urine_test_array,
          "Faeces Test": this.feace_test_array,
          "Scan": this.scan_test_array,
          "X-ray": this.xray_test_array,
          "Ultra Sound": this.ultra_test_array,
          "Culture": this.culture_test_array,
          "Biopsy": this.biopsy_test_array,
          "book_now": this.book_app,
          book_app_now:this.book_app_now,
          next_visit:this.next_datetxt,
        });

      } else {
        sen_pass = JSON.stringify({
          appointment_id: this.personalinfo.app_id,
          client_reg_id: this.personalinfo.Client_id,
          relation_id: this.personalinfo.rel_id,
          date: this.CurrentDatetime,
          diagnosis: "diag",
          disease: "",
          comment: this.observation,
          diagnosis_id: this.diag_centre_id,
          spl_id: this.personalinfo.spl_id,
          "Blood Test": this.blood_test,
          "Urine Test": this.urine_test_array,
          "Faeces Test": this.feace_test_array,
          "Scan": this.scan_test_array,
          "X-ray": this.xray_test_array,
          "Ultra Sound": this.ultra_test_array,
          "Culture": this.culture_test_array,
          "Biopsy": this.biopsy_test_array,
          "book_now": this.book_app,
          book_app_now:this.book_app_now,
          next_visit:this.next_datetxt,
        });
      }

      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'prescription/savepres/', sen_pass,
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            if (this.home_care != "0" && this.home_care != undefined) {
              if (obj != null) {
                Helper_Class.set_med_pres_id(obj["pres_id"]);
                if (this.personalinfo.AppFlow == "Doc_app_list") {
                  if (Physio_Helper.getDiagAppflow() == 'medical') {
                   // Physio_Helper.setMedSave(true);
                  } else {
                    Physio_Helper.setMedDiag(true);
                  }
                  this.saveflag = false;
                  this.toastr.success("Diagnosis " + Message_data.prescriptionSavedSuccess);
                } 
              } else {
                this.saveflag = true;
                this.toastr.error(Message_data.unableToSavePrescription);
              }
            } else {
              if (obj != undefined && obj != null) {
                if (this.personalinfo.AppFlow == "Doc_app_list") 
                {
                    Physio_Helper.setMedDiag(true);
                    this.subtestbname = [];
                    this.subtestfname = [];
                    this.subtestname = [];
                    this.subtestsname = [];
                    this.subtestuname = [];
                    this.subtesturname = [];
                    this.subtestxname = [];
                    this.subtestcname = [];
                    this.Blood_test = [];
                    this.sercah_center = "";
                    this.obs_add_diag = "";
                    this.messageservice.sendDiagComponent("save_diag");
                  }
                  this.saveflag = true;
                  this.toastr.success(Message_data.prescriptionSavedSuccess);
                
                var dat = this.check_pres;
                if (dat == "0") {
                  this.check_pres = "1";
                } else {
                  var seconds = 1;
                  setInterval(function () {
                    seconds--;

                  }, 500);
                }
              }
              else {
                this.saveflag = true;
                this.toastr.error(Message_data.unableToSavePrescription);
              }
            }
          },
          error => { }
        )
    }
  }
  
  getCountries(flag) {
    this.clnt_country_list = [];
    var data = Master_Helper.getMasterCountries();
    if (data.countries != null) {
      this.clnt_country_list = data.countries;
      for (var c = 0; c < this.clnt_country_list.length; c++) {
        // console.log("check country --"+this.clnt_country_list[c].country_id+this.clnt_country_id)
        if (this.clnt_country_list[c].country_id == this.clnt_country_id) {
          this.search_country = this.clnt_country_list[c].description;
          this.clnt_country_id = this.clnt_country_list[c].country_id;
          this.getStates(this.clnt_country_id, flag);
          break;
        }
      }
    }
  }

  countryChange(clnt_country, flag) {
    for (var c = 0; c < this.clnt_country_list.length; c++) {
      if (this.clnt_country_list[c].description == clnt_country) {
        this.search_country = this.clnt_country_list[c].description;
        this.clnt_country_id = this.clnt_country_list[c].country_id;

        this.clnt_state_list = [];
        this.clnt_city_list = [];
        this.clnt_location_id = undefined;
        this.search_location = "";
        this.getStates(this.clnt_country_id, flag);
      }
    }
  }

  getStates(country, flag) {
    this.clnt_state_list = [];
    var data;
    if (flag == "1") {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "usercontroller/state", JSON.stringify({ country_id: country }),
        { headers: headers }).subscribe(
          response => {
            data = response.json();
            this.clnt_state_list = data.states;
          });
    } else {
      data = Master_Helper.getMasterStates();
      this.clnt_state_list = data.states;
    }
    if (this.clnt_state_list != null) {
      for (var i = 0; i < this.clnt_state_list.length; i++) {
        if (this.clnt_state_list[i].state_id == this.clnt_state_id) {
          this.clnt_state_id = this.clnt_state_list[i].state_id;
          this.search_state = this.clnt_state_list[i].description;
          this.getCities(this.clnt_state_id, flag);
          break;
        }
      }
    }
  }

  stateChange(clnt_state, flag) {
    for (var i = 0; i < this.clnt_state_list.length; i++) {
      if (this.clnt_state_list[i].description == clnt_state) {
        this.clnt_state_id = this.clnt_state_list[i].state_id;
        this.search_city = this.clnt_state_list[i].description;
        this.clnt_city_list = [];
        // this.clnt_city_id=undefined;
        this.clnt_location_id = undefined;
        this.search_location = "";
        this.getCities(this.clnt_state_id, flag);
      }
    }
  }

  getCities(state, flag) {
    this.clnt_city_list = [];
    if (flag == "1") {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "usercontroller/city", JSON.stringify({ state_id: state }),
        { headers: headers }).subscribe(
          response => {
            var data = response.json();
            if (data.cities != null)
              this.clnt_city_list = data.cities;

          });
    } else {
      var data = Master_Helper.getMasterCities();
      if (data.cities != null)
        this.clnt_city_list = data.cities;
    }

    if (this.clnt_city_list != null) {
      for (var i = 0; i < this.clnt_city_list.length; i++) {
        if (this.clnt_city_list[i].district_id == this.clnt_city_id) {
          this.clnt_city_id = this.clnt_city_list[i].district_id;
          this.search_city = this.clnt_city_list[i].description;
          this.getLocations(this.clnt_city_id, flag);
          break;
        } else {
          this.search_city = this.clnt_city_list[0].description;
        }
      }
    }
  }

  cityChange(clnt_city, flag) {
    this.clnt_location_list = [];
    for (var i = 0; i < this.clnt_city_list.length; i++) {
      if (this.clnt_city_list[i].description == clnt_city) {
        this.clnt_city_id = this.clnt_city_list[i].district_id;
        this.search_city = this.clnt_city_list[i].description;
        this.clnt_location_id = undefined;
        this.search_location = "";
      }
    }
  }

  getLocations(city, flag) {
    this.clnt_location_list = [];

    if (flag == "1") {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "usercontroller/loc", JSON.stringify({ city_id: city }),
        { headers: headers }).subscribe(
          response => {
            var data = response.json();
            if (data.locations != null && data.locations.length != 0)
              this.clnt_location_list = data.locations;
          });
    } else {
      var data = Master_Helper.getMasterLocation();
      if (data.locations != null && data.locations.length != 0)
        this.clnt_location_list = data.locations;
    }
    if (this.clnt_location_list != null && this.clnt_location_list.length != 0) {
      for (var i = 0; i < this.clnt_location_list.length; i++) {
        if (this.clnt_location_list[i].location_id == this.clnt_location_id) {
          this.clnt_location_id = this.clnt_location_list[i].location_id;
          this.search_location = this.clnt_location_list[i].description;
          this.clnt_location_list = [];
          break;
        }
      }
      this.SerCountry_Change(this.search_country, this.search_state, this.search_city, this.search_location);
    }
  }

  location_change(locat_desc) {
    this.clnt_location_list = [];
    if (locat_desc.target.value.toString() != null && locat_desc.target.value.toString().length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.get_locname_url, JSON.stringify({
        name: locat_desc.target.value.toString(),
        city_id: this.clnt_city_id,
      }), { headers: headers }).subscribe(
        data => {
          if (data.json().locations != null && data.json().locations.length != 0) {
            this.clnt_location_list = data.json().locations;
          }
        },
        error => {
          this.toastr.error(Message_data.unableToFetchDocDet);
        }
      )
    } else {
      this.clnt_location_list = [];
    }
  }

  location_selected(location) {
    this.search_location = location;
    for (var i = 0; i < this.clnt_location_list.length; i++) {
      if (this.clnt_location_list[i].location_id == location.location_id) {
        this.clnt_location_id = this.clnt_location_list[i].location_id;
        this.search_location = this.clnt_location_list[i].description;
      }
    }
    this.clnt_location_list = [];
    this.SerCountry_Change(this.search_country, this.search_state, this.search_city, this.search_location);
  }

  // //center
  SerCountry_Change(countrytxt, statetxt, citytxt, loctxt) {
    this.diag_detail_List = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/diagdetbyname/',
      JSON.stringify({
        country: countrytxt,
        state: statetxt,
        city: citytxt,
        location: loctxt,
      }),
      { headers: headers })
      .subscribe(
        response => {

          var obj = response.json();;
          if (obj.diagnosis != null && obj.diagnosis != undefined && obj.diagnosis != "") {
            this.diag_detail_List = obj.diagnosis;
            for (var i = 0; i < obj.diagnosis.length; i++) {

              if (obj.diagnosis[i].diag_centre_name != null) {
                this.diag_cen_name = obj.diagnosis[i].diag_centre_name;
              }
              if (obj.diagnosis[i].diag_centre_id != null) {
                this.diag_centre_id = obj.diagnosis[i].diag_centre_id;
              }

              if (obj.diagnosis[i].address1 != null || obj.diagnosis[i].address2 != null) {
                this.diag_address1 = obj.diagnosis[i].address1;
                this.diag_address2 = obj.diagnosis[i].address2;
              }


              if (obj.diagnosis[i].location != null) {
                this.diag_location = obj.diagnosis[i].location;
              }

              if (obj.diagnosis[i].city != null) {
                this.diag_city = obj.diagnosis[i].city;
              }

              if (obj.diagnosis[i].state != null) {
                this.diag_state = obj.diagnosis[i].state;
              }

              if (obj.diagnosis[i].zipcode != null) {
                this.diag_zipcode = obj.diagnosis[i].zipcode;
              }

              if (obj.diagnosis[i].country != null) {
                this.diag_cntry = obj.diagnosis[i].country;

              }
              if (obj.diagnosis[i].telephone != null) {
                this.diag_telpho = obj.diagnosis[i].telephone;
              }
            }
          }
        },
        error => {

        }
      )
  }

  //Get diagnosis center
  serch_centerchange(data) {
    this.obs_add_diag = undefined;
    var datae: string = "";
    datae = data.diag_centre_name;
    for (var i = 0; i < this.diag_detail_List.length; i++) {
      if (this.diag_detail_List[i].diag_centre_name == datae) {
        this.diag_cen_id = this.diag_detail_List[i].diag_centre_id;
        if (this.diag_detail_List[i].address1 != null && this.diag_detail_List[i].address1 != "") {
          this.diag_address1 = this.diag_detail_List[i].address1;
        }
        else {
          this.diag_address1 = this.diag_detail_List[i].address1;
        }
        if (this.diag_detail_List[i].address1 != null || this.diag_detail_List[i].address2 != null) {
          this.diag_address2 = this.diag_detail_List[i].address2;
        }
        else {
          this.diag_address2 = this.diag_detail_List[i].address2;
        }
        this.obs_add_diag = this.diag_address1 + ", " + this.diag_address2;
      }
    }

    this.Blood_test = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/dtsub/',
      JSON.stringify({
        diag_centre_id: this.diag_cen_id
      }),
      { headers: headers })
      .subscribe(
        response => {

          var obj = response.json();
          if (obj.diag_tests.length == 0) {
            this.test_flag = true;
          } else {
            this.test_flag = false;
          }
          this.diag_centre_id = this.diag_cen_id;

          this.Blood_test = obj.diag_tests;
          for (var k = 0; k < this.Blood_test.length; k++) {
            this.testtypename[k] = this.Blood_test[k].test_type_name;
            for (var j = 0; j < this.Blood_test[k].sub_tests.length; j++) {
              this.Blood_test[k].sub_tests[j] = {
                test_id: this.Blood_test[k].sub_tests[j].test_id,
                test_name: this.Blood_test[k].sub_tests[j].test_name,
                checked: false
              }
            }
          }


        },
        error => { }
      )
  }

  ChangeTestType(event, id, data, type) {
    if (event == true) {
      for (var k = 0; k < this.Blood_test.length; k++) {
        this.testtypename[k] = this.Blood_test[k].test_type_name;
        if (this.testtypename[k] == type) {
          for (var j = 0; j < this.Blood_test[k].sub_tests.length; j++) {
            if (this.Blood_test[k].sub_tests[j].test_id == id) {
              this.Blood_test[k].sub_tests[j].checked = true;
            }

          }
        }

      }
      if (type == "Blood Test") {
        this.bloodtest = false;
        this.subtestname.push({
          test_id: id,
          test_name: data
        });
        this.blood_test_head = type;
        if (this.blood_test_content != '' && this.blood_test_content != undefined) {
          this.blood_test_content = this.blood_test_content + ", " + data;
        } else {
          this.blood_test_content = data;
        }
        this.pritn_blood_flag = true;
      } else if (type == "Scan") {
        this.scantest = false;
        this.subtestsname.push({
          test_id: id,
          test_name: data
        });
        this.scan_test_head = type;
        if (this.scan_test_content != '' && this.scan_test_content != undefined) {
          this.scan_test_content = this.scan_test_content + ", " + data;
        } else {
          this.scan_test_content = data;
        }
        this.pritn_scan_flag = true;
      } else if (type == "Ultra Sound") {
        this.ultratest = false;
        this.subtestuname.push({
          test_id: id,
          test_name: data
        });
        this.us_test_head = type;
        if (this.us_test_content != '' && this.us_test_content != undefined) {
          this.us_test_content = this.us_test_content + ", " + data;
        } else {
          this.us_test_content = data;
        }
        this.pritn_us_flag = true;

      } else if (type == "X-ray") {
        this.xraytest = false;
        this.subtestxname.push({
          test_id: id,
          test_name: data
        });
        this.xray_test_head = type;
        if (this.xray_test_content != '' && this.xray_test_content != undefined) {
          this.xray_test_content = this.xray_test_content + ", " + data;
        } else {
          this.xray_test_content = data;
        }
        this.pritn_xray_flag = true;
      } else if (type == "Biopsy") {
        this.biopsytest = false;
        this.subtestbname.push({
          test_id: id,
          test_name: data
        });
        this.bio_test_head = type;
        if (this.bio_test_content != '' && this.bio_test_content != undefined) {
          this.bio_test_content = this.bio_test_content + ", " + data;
        } else {
          this.bio_test_content = data;
        }
        this.pritn_bio_flag = true;
      } else if (type == "Urine Test") {
        this.urinetest = false;
        this.subtesturname.push({
          test_id: id,
          test_name: data
        });
        this.ut_test_head = type;
        if (this.ut_test_content != '' && this.ut_test_content != undefined) {
          this.ut_test_content = this.ut_test_content + ", " + data;
        } else {
          this.ut_test_content = data;
        }
        this.pritn_ut_flag = true;
      } else if (type == "Faeces Test") {
        this.faecestest = false;
        this.subtestfname.push({
          test_id: id,
          test_name: data
        });
        this.ft_test_head = type;
        if (this.ft_test_content != '' && this.ft_test_content != undefined) {
          this.ft_test_content = this.ft_test_content + ", " + data;
        } else {
          this.ft_test_content = data;
        }
        this.pritn_ft_flag = true;
      } else if (type == "Culture") {
        this.culturetest = false;
        this.subtestcname.push({
          test_id: id,
          test_name: data
        });
        this.culture_test_head = type;
        if (this.culture_test_content != '' && this.culture_test_content != undefined) {
          this.culture_test_content = this.culture_test_content + ", " + data;
        } else {
          this.culture_test_content = data;
        }
        this.pritn_culture_flag = true;
      }

    } else if (event == false) {
      if (type == "Blood Test") {
        if (this.blood_test_content != "") {
          this.blood_test_content = this.blood_test_content.replace(data + ', ', "");
          this.blood_test_content = this.blood_test_content.replace(data, "");

        }
        if (this.blood_test_content == "") {
          this.blood_test_head = "";
          this.pritn_blood_flag = false;
        } else {
          this.pritn_blood_flag = true;
        }
      }
      if (type == "Scan") {
        if (this.scan_test_content != "") {
          this.scan_test_content = this.scan_test_content.replace(data + ', ', "");
          this.scan_test_content = this.scan_test_content.replace(data, "");

        }
        if (this.scan_test_content == "") {
          this.scan_test_head = "";
          this.pritn_scan_flag = false;
        } else {
          this.pritn_scan_flag = true;
        }
      }
      if (type == "Ultra Sound") {
        if (this.us_test_content != "") {
          this.us_test_content = this.us_test_content.replace(data + ', ', "");
          this.us_test_content = this.us_test_content.replace(data, "");

        }
        if (this.us_test_content == "") {
          this.us_test_head = "";
          this.pritn_us_flag = false;
        } else {
          this.pritn_us_flag = true;
        }
      }
      if (type == "X-ray") {
        if (this.xray_test_content != "") {
          this.xray_test_content = this.xray_test_content.replace(data + ', ', "");
          this.xray_test_content = this.xray_test_content.replace(data, "");

        }
        if (this.xray_test_content == "") {
          this.xray_test_head = "";
          this.pritn_xray_flag = false;
        } else {
          this.pritn_xray_flag = true;
        }
      }
      if (type == "Biopsy") {
        if (this.bio_test_content != "") {
          this.bio_test_content = this.bio_test_content.replace(data + ', ', "");
          this.bio_test_content = this.bio_test_content.replace(data, "");

        }
        if (this.bio_test_content == "") {
          this.bio_test_head = "";
          this.pritn_bio_flag = false;
        } else {
          this.pritn_bio_flag = true;
        }
      }
      if (type == "Urine Test") {
        if (this.ut_test_content != "") {
          this.ut_test_content = this.ut_test_content.replace(data + ', ', "");
          this.ut_test_content = this.ut_test_content.replace(data, "");

        }
        if (this.ut_test_content == "") {
          this.ut_test_head = "";
          this.pritn_ut_flag = false;
        } else {
          this.pritn_ut_flag = true;
        }
      }
      if (type == "Faeces Test") {
        if (this.ft_test_content != "") {
          this.ft_test_content = this.ft_test_content.replace(data + ', ', "");
          this.ft_test_content = this.ft_test_content.replace(data, "");

        }
        if (this.ft_test_content == "") {
          this.ft_test_head = "";
          this.pritn_ft_flag = false;
        } else {
          this.pritn_ft_flag = true;
        }
      }
      for (var k = 0; k < this.Blood_test.length; k++) {
        this.testtypename[k] = this.Blood_test[k].test_type_name;
        if (this.testtypename[k] == type) {
          for (var j = 0; j < this.Blood_test[k].sub_tests.length; j++) {
            if (this.Blood_test[k].sub_tests[j].test_id == id) {
              this.Blood_test[k].sub_tests[j].checked = false;
            }

          }
        }

      }
      for (var h = 0; h < this.subtestname.length; h++) {
        if (this.subtestname[h].test_id == id) {
          this.subtestname.splice(h, 1);
          this.checked = false;
          if (this.subtestname.length == 0) {
            this.bloodtest = true;
          }
        }
      }
      for (var h = 0; h < this.subtestsname.length; h++) {
        if (this.subtestsname[h].test_id == id) {
          this.subtestsname.splice(h, 1);
          if (this.subtestsname.length == 0) {
            this.scantest = true;
          }
        }
      }
      for (var h = 0; h < this.subtestuname.length; h++) {
        if (this.subtestuname[h].test_id == id) {
          this.subtestuname.splice(h, 1);
          if (this.subtestuname.length == 0) {
            this.ultratest = true;
          }
        }
      }
      for (var h = 0; h < this.subtestxname.length; h++) {
        if (this.subtestxname[h].test_id == id) {
          this.subtestxname.splice(h, 1);
          if (this.subtestxname.length == 0) {
            this.xraytest = true;
          }
        }
      }
      for (var h = 0; h < this.subtestbname.length; h++) {
        if (this.subtestbname[h].test_id == id) {
          this.subtestbname.splice(h, 1);
          if (this.subtestbname.length == 0) {
            this.biopsytest = true;
          }
        }
      }
      for (var h = 0; h < this.subtesturname.length; h++) {
        if (this.subtesturname[h].test_id == id) {
          this.subtesturname.splice(h, 1);
          if (this.subtesturname.length == 0) {
            this.urinetest = true;
          }
        }
      }
      for (var h = 0; h < this.subtestfname.length; h++) {
        if (this.subtestfname[h].test_id == id) {
          this.subtestfname.splice(h, 1);
          if (this.subtestfname.length == 0) {
            this.faecestest = true;
          }
        }
      }
      for (var h = 0; h < this.subtestcname.length; h++) {
        if (this.subtestcname[h].test_id == id) {
          this.subtestcname.splice(h, 1);
          if (this.subtestcname.length == 0) {
            this.culturetest = true;
          }
        }
      }
    }

    this.print_diag = false;
    if (this.subtestname.length != 0) {
      this.print_diag = true;
    }

    if (this.subtesturname.length != 0) {
      this.print_diag = true;

    }
    if (this.subtestfname.length != 0) {
      this.print_diag = true;

    }
    if (this.subtestsname.length != 0) {
      this.print_diag = true;

    }
    if (this.subtestxname.length != 0) {
      this.print_diag = true;

    }
    if (this.subtestuname.length != 0) {
      this.print_diag = true;
    }
    if (this.subtestcname.length != 0) {
      this.print_diag = true;

    }

   
      this.print_medidiag = false;
    
  }
  
  closeSelectedTest(type, id, subtype) {
    for (var k = 0; k < this.Blood_test.length; k++) {
      if (type == this.Blood_test[k].test_type_name) {
        for (var j = 0; j < this.Blood_test[k].sub_tests.length; j++) {
          if (this.Blood_test[k].sub_tests[j].test_id == id) {
            this.Blood_test[k].sub_tests[j].checked = false;
          }
        }
      }
    }
    if (type == "Blood Test") {
      for (var h = 0; h < this.subtestname.length; h++) {
        if (this.subtestname[h].test_id == id) {
          this.subtestname.splice(h, 1);
          if (this.subtestname.length == 0) {
            this.bloodtest = true;
          }
        }

      }
    }
    if (type == "Scan") {
      for (var h = 0; h < this.subtestsname.length; h++) {
        if (this.subtestsname[h].test_id == id) {
          this.subtestsname.splice(h, 1);
          if (this.subtestsname.length == 0) {
            this.scantest = true;
          }
        }
      }
    }
    if (type == "Ultra Sound") {
      for (var h = 0; h < this.subtestuname.length; h++) {
        if (this.subtestuname[h].test_id == id) {
          this.subtestuname.splice(h, 1);
          if (this.subtestuname.length == 0) {
            this.ultratest = true;
          }
        }
      }
    }
    if (type == "X-ray") {
      for (var h = 0; h < this.subtestxname.length; h++) {
        if (this.subtestxname[h].test_id == id) {
          this.subtestxname.splice(h, 1);
          if (this.subtestxname.length == 0) {
            this.xraytest = true;
          }
        }
      }
    }
    if (type == "Biopsy") {
      for (var h = 0; h < this.subtestbname.length; h++) {
        if (this.subtestbname[h].test_id == id) {
          this.subtestbname.splice(h, 1);
          if (this.subtestbname.length == 0) {
            this.biopsytest = true;
          }

        }
      }
    }
    if (type == "Urine Test") {
      for (var h = 0; h < this.subtesturname.length; h++) {
        if (this.subtesturname[h].test_id == id) {
          this.subtesturname.splice(h, 1);
          if (this.subtesturname.length == 0) {
            this.urinetest = true;
          }

        }
      }
    }
    if (type == "Faeces Test") {
      for (var h = 0; h < this.subtestfname.length; h++) {
        if (this.subtestfname[h].test_id == id) {
          this.subtestfname.splice(h, 1);
          if (this.subtestfname.length == 0) {
            this.faecestest = true;
          }
        }
      }
    }
    if (type == "Culture") {
      for (var h = 0; h < this.subtestcname.length; h++) {
        if (this.subtestcname[h].test_id == id) {
          this.subtestcname.splice(h, 1);
          if (this.subtestcname.length == 0) {
            this.culturetest = true;
          }
        }
      }
    }
    var flag;
    if (this.subtestuname.length != 0) {
      flag = true;
    } else if (this.subtestfname.length != 0) {
      flag = true;
    } else if (this.subtestname.length != 0) {
      flag = true;
    } else if (this.subtestsname.length != 0) {
      flag = true;
    } else if (this.subtestxname.length != 0) {
      flag = true;
    } else if (this.subtesturname.length != 0) {
      flag = true;
    } else if (this.subtestbname.length != 0) {
      flag = true;
    } else if (this.subtestcname.length != 0) {
      flag = true;
    } else {
      flag = false;
    }
    if (flag == true ) {
      this.print_medidiag = true;
    } else {
      this.print_medidiag = false;
    }
  }
  OnDateMatePicker(e) {
    this.next_datetxt = e;
    this.next_txt = Date_Formate(e);
    //this.get_doc_apps(this.user_id);
  }

  checkNextVisit(){
    if(this.next_txt ==undefined && this.book_app_now==true){
      this.toastr.error("Select next visit date");
      //this.book_app_now=false;
    }
  }

}
