<div mat-dialog-title class="popup_border_icd">
  <h5 class="header_txt">Remarks</h5>
  <div mat-dialog-actions>
    <!-- <button mat-icon-button  class="btnclose"> -->
    <!-- <span (click)="ClosePopup()" id="close_icd">X</span> -->
    <img src="../../../assets/img/close_icon.png" (click)="ClosePopup()" width="20px" height="20px" id="close_icd" />
  </div>
</div>
<div mat-dialog-content class="ped_content">
  <div class="container">
    <div class="row">
      <div class="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10 ">
        <input class="ipcss" placeholder="Remarks" [(ngModel)]="remarks" matInput />
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions align="end" style="width: auto;right: 19px;">
  <button id="add_btn" [mat-dialog-close]="remarks" cdkFocusInitial mat-raised-button>
      <img src="../../../assets/ui_icons/buttons/Done_button.svg" class="addimgbtn_icd" />
    </button>
    <button (click)="ClosePopup()" id="add_btn" [mat-dialog-close]="" cdkFocusInitial mat-raised-button>
      <img src="../../../assets/ui_icons/buttons/cancel_button.svg" class="addimgbtn_icd" />
    </button>
</div>