import { Component, OnInit, HostListener, Input } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Helper_Class } from '../../helper_class';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Observable, observable, Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ipaddress } from '../../ipaddress';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ServerApi } from '../../server-api';
import { Message_data } from '../../../assets/js/Message_data';
import { encrypt_decript } from 'src/assets/js/Encription_Decription';
import { Date_Formate } from '../../../assets/js/common';
import { Master_Helper } from 'src/app/Doctor_module/Master_Helper';
import { MasterHelperService } from 'src/app/Doctor_module/MasterHelper.service';
import * as HighCharts from 'highcharts';
import { Chart } from 'chart.js';
import { OwlOptions } from 'ngx-owl-carousel-o';
import moment from 'moment';
import { Doc_Helper } from 'src/app/Doctor_module/Doc_Helper';
import { Client_Helper } from '../Client_helper';
import { ClientViewService } from '../clientview/client-view.service';

declare var $: any;
@Component({
  selector: 'app-diet-tracking-history',
  templateUrl: './diet-tracking-history.component.html',
  styleUrls: ['./diet-tracking-history.component.scss']
})
export class DietTrackingHistoryComponent implements OnInit {
  public patient_list;
  public dietPlanFlag;
  public appointment_type;
  public curr_date;
  public f_date;
  public t_date;
  public tdate;
  public obj;
  public dateSet = [];
  public DTEnergy = [];
  public DTproteins = [];
  public DTfats = [];
  public DTcarbs = [];
  public DPEnergy = [];
  public DPproteins = [];
  public DPfats = [];
  public DPcarbs = [];
  public EnergyGraph: any;
  public proteinsGraph: any;
  public fatsGraph: any;
  public crabsGraph: any;
  public vis_type;
  public visualFlag: boolean = true;
  public dataSetEnergy = [];
  public dataSetProtine = [];
  public dataSetFats = [];
  public dataSetCarbs = [];
  public mainGraph: boolean = true;
  public showGraph: boolean = true;
  public lableFlag;
  public consumeEnergy;
  public planEnergy;
  public foodLogFlag: boolean = false;
  public showCard: boolean = false;
  public foodLogArray = [];
  public expandArray:any = [];
  public foodLogGraph: any;
  public dateFlag: boolean = false;
  public fdate;
  public energyPercentage;
  public obj1:any = [];
  customOptions1: OwlOptions = {
    autoplay: false,
    autoplaySpeed: 1500,
    loop: false,
    pullDrag: false,
    dots: false,
    navSpeed: 750,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 3
      }
    },
    nav: false,

  }
  constructor(public dialog: MatDialog, public http: Http, public serviceAPI: ServerApi,public router: Router, public route: ActivatedRoute, public toastr: ToastrService, public sanitizer: DomSanitizer, public masterData: MasterHelperService,public messageservice: ClientViewService) { }

  ngOnInit(): void {
    this.patient_list = Client_Helper.getClientDietHistory();
    this.appointment_type = "Today";
    this.vis_type = "graph";
    this.get_curr_date();
  }
  async get_curr_date() {
    if (Master_Helper.getMasterCurrentDate() == undefined) {
      await this.masterData.getCurrentDate();
    }
    var obj = Master_Helper.getMasterCurrentDate();
    if (obj != undefined) {
      this.curr_date = obj.current_date;
      this.f_date = Date_Formate(this.curr_date);
      this.t_date = Date_Formate(this.curr_date);
      this.Appointment_type_change();
    }
  }
  Appointment_type_change() {
    var e;
    console.log("this.appointment_type" + this.appointment_type)
    this.tdate = Date_Formate(this.curr_date);
    this.dateFlag = false;
    if (this.appointment_type == "Today") {
      e = this.curr_date;
    } else if (this.appointment_type == "Weekly") {
      var weekly, d = new Date();
      d.setDate(d.getDate() - 7);
      e = this.getDateFormate(d);
    } else if (this.appointment_type == "Fort Night") {
      var weekly, d = new Date();
      d.setDate(d.getDate() - 14);
      e = this.getDateFormate(d);
    } else if (this.appointment_type == "Monthly") {
      var weekly, d = new Date();
      d.setDate(d.getDate() - 30)
      e = this.getDateFormate(d);
    } else if (this.appointment_type == "3 Months") {
      var d = new Date();
      d.setDate(d.getDate() - 92)
      e = this.getDateFormate(d);
    } else if (this.appointment_type == "6 Months") {
      var weekly, d = new Date();
      d.setDate(d.getDate() - 183)
      e = this.getDateFormate(d);
    } else if (this.appointment_type == "Yearly") {
      var weekly, d = new Date();
      d.setDate(d.getDate() - 365)
      e = this.getDateFormate(d);
    } else if (this.appointment_type == "Date"){
      this.dateFlag = true;
    }else { }
    this.f_date = Date_Formate(e);
    if(this.appointment_type != "Date" && this.foodLogFlag == false){
      this.getGraphData();
    }else if(this.foodLogFlag != false){
      this.getFoodhistory();
    }
  }
  getDateFormate(d) {
    var month = ((d.getMonth() + 1) < 10 ? '0' : '') + (d.getMonth() + 1);
    var day = (d.getDate() < 10 ? '0' : '') + d.getDate();
    var temp = d.getFullYear() + "-" + month + "-" + day;
    return temp
  }

  async getGraphData() {
    var sen_pass;
    this.obj = [];
    this.dateSet = [];
    this.DTEnergy = [];
    this.DTproteins = [];
    this.DTfats = [];
    this.DTcarbs = [];
    this.DPEnergy = [];
    this.DPproteins = [];
    this.DPfats = [];
    this.DPcarbs = [];
    var cal_txt = cal_txt;
    if (this.patient_list.sub_id != null && this.patient_list.sub_id != undefined &&
      this.patient_list.sub_id != "" && this.patient_list.sub_id.length != 0) {
      sen_pass = {
        client_reg_id: this.patient_list.Client_id,
        relation_id: this.patient_list.rel_id,
        sub_rel_id: this.patient_list.sub_id,
        from_date: Date_Formate(this.f_date),
        to_date: Date_Formate(this.tdate),
        country: "IN",
      }
    } else {
      sen_pass = {
        client_reg_id: this.patient_list.Client_id,
        relation_id: this.patient_list.rel_id,
        from_date: Date_Formate(this.f_date),
        to_date: Date_Formate(this.tdate),
        country: "IN",
      }
    }

    var response = await this.serviceAPI.PostData('dash/diettrack', sen_pass).toPromise();
    if (response) {
      this.obj = JSON.parse(JSON.stringify(response));
      console.log("diet ==>>>>>>>>>>>" + JSON.stringify(this.obj));
      this.consumeEnergy = this.obj.total_consume_energy != undefined ? this.obj.total_consume_energy : "0.00";
      this.planEnergy = this.obj.total_plan_energy != undefined ? this.obj.total_plan_energy : "0.00";
      if (this.obj.energy.length != 0) {
        this.DTEnergy = this.obj.energy;
      }
      if (this.obj.proteins.length != 0) {
        this.DTproteins = this.obj.proteins;
      }
      if (this.obj.fats.length != 0) {
        this.DTfats = this.obj.fats;
      }
      if (this.obj.carbs.length != 0) {
        this.DTcarbs = this.obj.carbs;
      }
      if (this.obj.energy_plan.length != 0) {
        this.DPEnergy = this.obj.energy_plan;
      }

      if (this.obj.fats_plan.length != 0) {
        this.DPfats = this.obj.fats_plan;
      }
      if (this.obj.carbs_plan.length != 0) {
        this.DPcarbs = this.obj.carbs_plan;
      }
      if (this.obj.date.length != 0) {
        this.dateSet = this.obj.date;
      }
      if (this.obj.proteins_plan.length != 0) {
        this.DPproteins = this.obj.proteins_plan;
      }
      if (this.vis_type == "graph") {
        this.drawGraph();
      } else {
        this.visGetData();
      }
    }
  }
  drawGraph() {
    //var xAxis = [];
    var xAxis = this.dateSet;
    if (this.EnergyGraph) {
      this.EnergyGraph.destroy();
    }
    if (this.proteinsGraph) {
      this.proteinsGraph.destroy();
    }
    if (this.fatsGraph) {
      this.fatsGraph.destroy();
    }
    if (this.crabsGraph) {
      this.crabsGraph.destroy();
    }
    // Energy 
    var bgColor = ["#878BB6", "#FFEA88", "#FF8153", "#4ACAB4", "#c0504d", "#8064a2", "#772c2a", "#f2ab71", "#2ab881", "#4f81bd", "#2c4d75"];
    var ctx = <HTMLCanvasElement>document.getElementById("EnergyGraph");
    //var lable = this.showGraph == true ?  : [" ", " "];
    this.EnergyGraph = new Chart(ctx, {
      type: 'line',
      labels: ["Consumed", "Suggested"],
      data: {
        labels: xAxis,
        datasets: [
          {
            data: this.DTEnergy,
            backgroundColor: "#01452c",
            borderColor: "#01452c",
            fill: false,
            borderWidth: 2,
            spanGaps: true,
            label: "Consumed"
          }, {
            data: this.DPEnergy,
            backgroundColor: "#ff8b00",
            borderColor: "#ff8b00",
            fill: false,
            borderWidth: 2,
            spanGaps: true,
            label: "Suggested"
          }]
      },
      options: {
        legend: {
          display: this.showGraph,
        },
        responsive: true,
        scales: {
          yAxes: [{
            display: true,
            ticks: {
              beginAtZero: true,
              steps: 100,
              stepValue: 5,

            }
          }],
          xAxes: [{
            ticks: {
              display: this.showGraph,
              maxRotation: 50,
              minRotation: 50
            }
          }]
        },

      }
      // options:chartOptions,

    });
    // proteins
    var bgColor = ["#878BB6", "#FFEA88", "#FF8153", "#4ACAB4", "#c0504d", "#8064a2", "#772c2a", "#f2ab71", "#2ab881", "#4f81bd", "#2c4d75"];
    var ctx = <HTMLCanvasElement>document.getElementById("proteinsGraph");
    this.proteinsGraph = new Chart(ctx, {
      type: 'line',
      labels: ["Consumed", "Suggested"],
      data: {
        labels: xAxis,
        datasets: [
          {
            data: this.DTproteins,
            backgroundColor: "#01452c",
            borderColor: "#01452c",
            fill: false,
            borderWidth: 2,
            spanGaps: true,
            label: "Consumed"
          }, {
            data: this.DPproteins,
            backgroundColor: "#ff8b00",
            borderColor: "#ff8b00",
            fill: false,
            borderWidth: 2,
            spanGaps: true,
            label: "Suggested"
          }]
      },
      options: {
        responsive: true,
        scales: {
          yAxes: [{
            display: true,
            ticks: {
              beginAtZero: true,
              steps: 100,
              stepValue: 5,

            }
          }],
          xAxes: [{
            ticks: {
              maxRotation: 50,
              minRotation: 50
            }
          }]
        },

      }
    });
    // Fats
    var bgColor = ["#878BB6", "#FFEA88", "#FF8153", "#4ACAB4", "#c0504d", "#8064a2", "#772c2a", "#f2ab71", "#2ab881", "#4f81bd", "#2c4d75"];
    var ctx = <HTMLCanvasElement>document.getElementById("fatsGraph");
    this.fatsGraph = new Chart(ctx, {
      type: 'line',
      labels: ["Consumed", "Suggested"],
      data: {
        labels: xAxis,
        datasets: [
          {
            data: this.DTfats,
            backgroundColor: "#01452c",
            borderColor: "#01452c",
            fill: false,
            borderWidth: 2,
            spanGaps: true,
            label: "Consumed"
          }, {
            data: this.DPfats,
            backgroundColor: "#ff8b00",
            borderColor: "#ff8b00",
            fill: false,
            borderWidth: 2,
            spanGaps: true,
            label: "Suggested"
          }]
      },
      options: {
        responsive: true,
        scales: {
          yAxes: [{
            display: true,
            ticks: {
              beginAtZero: true,
              steps: 100,
              stepValue: 5,
              //max: 5000
            }
          }],
          xAxes: [{
            ticks: {
              maxRotation: 50,
              minRotation: 50
            }
          }]
        },

      }
      // options:chartOptions,

    });
    // carb
    var bgColor = ["#878BB6", "#FFEA88", "#FF8153", "#4ACAB4", "#c0504d", "#8064a2", "#772c2a", "#f2ab71", "#2ab881", "#4f81bd", "#2c4d75"];
    var ctx = <HTMLCanvasElement>document.getElementById("crabsGraph");
    this.crabsGraph = new Chart(ctx, {
      type: 'line',
      labels: ["Consumed", "Suggested"],
      data: {
        labels: xAxis,
        datasets: [
          {
            data: this.DTcarbs,
            backgroundColor: "#01452c",
            borderColor: "#01452c",
            fill: false,
            borderWidth: 2,
            spanGaps: true,
            label: "Consumed"
          }, {
            data: this.DPcarbs,
            backgroundColor: "#ff8b00",
            borderColor: "#ff8b00",
            fill: false,
            borderWidth: 2,
            spanGaps: true,
            label: "Suggested"
          }]
      },
      options: {

        responsive: true,
        scales: {
          yAxes: [{
            display: true,
            ticks: {
              beginAtZero: true,
              steps: 100,
              stepValue: 5,

            }
          }],
          xAxes: [{
            ticks: {
              maxRotation: 50,
              minRotation: 50
            }
          }]
        },

      }
      // options:chartOptions,

    });
    this.getGreetingLable();
    // this.visType();
  }
  visType() {
    if (this.vis_type == "graph") {
      this.visualFlag = true;
      this.Appointment_type_change();
      //this.drawGraph();
    } else {
      this.visualFlag = false;
      this.visGetData();
    }

  }
  visGetData() {
    this.dataSetEnergy = [];
    this.dataSetProtine = [];
    this.dataSetFats = [];
    this.dataSetCarbs = [];
    for (var i = 0; i <= this.dateSet.length; i++) {
      var eneryColor = this.getColor(this.DTEnergy[i], this.DPEnergy[i]);
      this.dataSetEnergy.push({
        date: Date_Formate(this.dateSet[i]),
        DTEnergy: this.DTEnergy[i],
        DPEnergy: this.DPEnergy[i],
        EnergyColor: eneryColor
      });
      var proteinsColor = this.getColor(this.DTproteins[i], this.DPproteins[i])
      this.dataSetProtine.push({
        date: Date_Formate(this.dateSet[i]),
        DTEnergy: this.DTproteins[i],
        DPEnergy: this.DPproteins[i],
        EnergyColor: proteinsColor
      });
      var fatColor = this.getColor(this.DTfats[i], this.DPfats[i])
      this.dataSetFats.push({
        date: Date_Formate(this.dateSet[i]),
        DTEnergy: this.DTfats[i],
        DPEnergy: this.DPfats[i],
        EnergyColor: fatColor
      });
      var fatColor = this.getColor(this.DTcarbs[i], this.DPcarbs[i])
      this.dataSetCarbs.push({
        date: Date_Formate(this.dateSet[i]),
        DTEnergy: this.DTcarbs[i],
        DPEnergy: this.DPcarbs[i],
        EnergyColor: fatColor
      });

    }
  }
  getColor(cal_txt, tot_cal) {
    var day_totcal, color;
    if (cal_txt != "0") {
      day_totcal = (parseFloat(tot_cal) / parseFloat(cal_txt)) * 100;
    } else {
      day_totcal = 0;
    }
    if (day_totcal > 0 && day_totcal <= 30) {
      // morning_color=document.getElementsByClassName("circlecal").style.color

      color = "#fa1c14";
    }
    else if (day_totcal > 30 && day_totcal <= 60) {

      color = "#f5a142";
    }
    else if (day_totcal > 60 && day_totcal <= 80) {

      color = "#1be088";
    }
    else {

      color = "#fa1c14";
    }
    return color;
  }

  getColorValue(cal_txt, tot_cal) {
    var day_totcal, color;
    console.log("cal_txt" + cal_txt + "tot_cal" + tot_cal);
    if (cal_txt != "0" && cal_txt != "0.00") {
      day_totcal = (parseFloat(tot_cal) / parseFloat(cal_txt)) * 100;
    } else {
      day_totcal = 0;
    }
    if(day_totcal != 0){
      if (day_totcal >= 160 || day_totcal <= 40) {
        color = "#ff0000";
      } else if ((day_totcal < 160 && day_totcal >= 130) || (day_totcal > 40 && day_totcal < 60)) {
        color = "#f98137";
      } else if ((day_totcal < 130 && day_totcal >= 105) || (day_totcal > 60 && day_totcal < 95)) {
        color = "#ffc000";
      } else if ((day_totcal < 105 && day_totcal >= 95)) {
        color = "#5be32e";
      } else {
        color = "#ff0000";
      }
    }else{
      color = "#707070";
    }
 
    var val = {
      value: day_totcal,
      color: color
    }
    return val;
  }
  energyToggle(selection) {
    this.showGraph = !this.showGraph;
    setTimeout(() => {
      this.drawGraph();
    }, 200);
  }
  getGreetingLable() {
    var day_totcal, color;
    if (this.planEnergy != "0.00") {
      day_totcal = (parseFloat(this.consumeEnergy) / parseFloat(this.planEnergy)) * 100;
    } else {
      day_totcal = 0;
    }
    if (day_totcal > 160 || day_totcal < 40) {
      this.lableFlag = "4";
    } else if ((day_totcal < 160 && day_totcal >= 130) || (day_totcal > 40 && day_totcal < 60)) {
      this.lableFlag = "3";
    } else if ((day_totcal < 130 && day_totcal >= 105) || (day_totcal > 60 && day_totcal < 90)) {
      this.lableFlag = "2";
    } else {
      this.lableFlag = "1";
    }
    this.energyPercentage = parseInt(day_totcal).toString();
  }
  changeheart(e) {
    this.foodLogFlag = e;

   
    if (this.foodLogFlag == true) {
      this.getFoodhistory();
    }else{
      setTimeout(() => {
        this.drawGraph();
      }, 200);
    }
    //this.togg_heart_att = e == false ? "no" : "yes";
  }
  async getFoodhistory() {
    var sen_pass;
    console.log("get the food history" + JSON.stringify(this.patient_list))
    if (this.patient_list.sub_id != null && this.patient_list.sub_id != undefined && this.patient_list.sub_id != "" && this.patient_list.sub_id.length != 0) {
      sen_pass = {
        client_reg_id: this.patient_list.Client_id,
        relation_id: this.patient_list.rel_id,
        sub_rel_id: this.patient_list.sub_id,
        from_date: Date_Formate(this.f_date),
        to_date: Date_Formate(this.tdate),
      }
    } else {
      sen_pass = {
        client_reg_id: this.patient_list.Client_id,
        relation_id: this.patient_list.rel_id,
        from_date: Date_Formate(this.f_date),
        to_date: Date_Formate(this.tdate),

      }
    }
    var response = await this.serviceAPI.PostData('lifestyle/dpthistn', sen_pass).toPromise();
    if (response) {
      console.log("get the food history" + JSON.stringify(response));
      this.obj1 = JSON.parse(JSON.stringify(response));
      this.obj1 = this.obj1.diet_plan_track_hist;
      this.foodLogArray = [];
      for (var i = 0; i < this.obj1.length; i++) {
        var morning = this.getColorValue(this.obj1[i].morning_energy != undefined ? this.obj1[i].morning_energy : '0', this.obj1[i].morning_plan_energy != undefined ? this.obj1[i].morning_plan_energy : '0');

        var breakFast = this.getColorValue(this.obj1[i].break_energy != undefined ? this.obj1[i].break_energy : '0', this.obj1[i].break_plan_energy != undefined ? this.obj1[i].break_plan_energy : '0');

        var midday = this.getColorValue(this.obj1[i].midday_energy != undefined ? this.obj1[i].midday_energy : '0', this.obj1[i].midday_plan_energy != undefined ? this.obj1[i].midday_plan_energy : '0');

        var lunch = this.getColorValue(this.obj1[i].lunch_energy != undefined ? this.obj1[i].lunch_energy : '0', this.obj1[i].lunch_plan_energy != undefined ? this.obj1[i].lunch_plan_energy : '0');

        var evening = this.getColorValue(this.obj1[i].evening_energy != undefined ? this.obj1[i].evening_energy : '0', this.obj1[i].evening_plan_energy != undefined ? this.obj1[i].evening_plan_energy : '0');

        var dinner = this.getColorValue(this.obj1[i].dinner_energy != undefined ? this.obj1[i].dinner_energy : '0', this.obj1[i].dinner_plan_energy != undefined ? this.obj1[i].dinner_plan_energy : '0');

        var night = this.getColorValue(this.obj1[i].night_energy != undefined ? this.obj1[i].night_energy : '0', this.obj1[i].night_plan_energy != undefined ? this.obj1[i].night_plan_energy : '0');


        var session = [
          {
            name: "Morning",
            tracking: this.obj1[i].morning != undefined ? this.obj1[i].morning : [],
            plan: this.obj1[i].plan_morning != undefined ? this.obj1[i].plan_morning : [],
            energy: this.obj1[i].morning_energy != undefined ? this.obj1[i].morning_energy : '0',
            plan_energy: this.obj1[i].morning_plan_energy != undefined ? this.obj1[i].morning_plan_energy : '0',
            proteins: this.obj1[i].morning_proteins != undefined ? this.obj1[i].morning_proteins : '0',
            plan_proteins: this.obj1[i].morning_plan_proteins != undefined ? this.obj1[i].morning_plan_proteins : '0',
            carbs: this.obj1[i].morning_carbs != undefined ? this.obj1[i].morning_carbs : '0',
            plan_carbs: this.obj1[i].morning_plan_carbs != undefined ? this.obj1[i].morning_plan_carbs : '0',
            fat: this.obj1[i].morning_fat != undefined ? this.obj1[i].morning_fat : '0',
            plan_fat: this.obj1[i].morning_plan_fat != undefined ? this.obj1[i].morning_plan_fat : '0',
            percentage: parseFloat(morning.value).toFixed(0),
            color: morning.color,
          },
          {
            name: "Break-Fast",
            tracking: this.obj1[i].break_fast != undefined ? this.obj1[i].break_fast : [],
            plan: this.obj1[i].plan_break_fast != undefined ? this.obj1[i].plan_break_fast : [],
            energy: this.obj1[i].break_energy != undefined ? this.obj1[i].break_energy : '0',
            plan_energy: this.obj1[i].break_plan_energy != undefined ? this.obj1[i].break_plan_energy : '0',
            proteins: this.obj1[i].break_proteins != undefined ? this.obj1[i].break_proteins : '0',
            plan_proteins: this.obj1[i].break_plan_proteins != undefined ? this.obj1[i].break_plan_proteins : '0',
            carbs: this.obj1[i].break_carbs != undefined ? this.obj1[i].break_carbs : '0',
            plan_carbs: this.obj1[i].break_plan_carbs != undefined ? this.obj1[i].break_plan_carbs : '0',
            fat: this.obj1[i].break_fat != undefined ? this.obj1[i].break_fat : '0',
            plan_fat: this.obj1[i].break_plan_fat != undefined ? this.obj1[i].break_plan_fat : '0',
            percentage: parseFloat(breakFast.value).toFixed(0),
            color: breakFast.color,
          },
          {
            name: "Mid-Day",
            tracking: this.obj1[i].midday != undefined ? this.obj1[i].midday : [],
            plan: this.obj1[i].plan_midday != undefined ? this.obj1[i].plan_midday : [],

            energy: this.obj1[i].midday_energy != undefined ? this.obj1[i].midday_energy : '0',
            plan_energy: this.obj1[i].midday_plan_energy != undefined ? this.obj1[i].midday_plan_energy : '0',
            proteins: this.obj1[i].midday_proteins != undefined ? this.obj1[i].midday_proteins : '0',
            plan_proteins: this.obj1[i].midday_plan_proteins != undefined ? this.obj1[i].midday_plan_proteins : '0',
            carbs: this.obj1[i].midday_carbs != undefined ? this.obj1[i].midday_carbs : '0',
            plan_carbs: this.obj1[i].midday_plan_carbs != undefined ? this.obj1[i].midday_plan_carbs : '0',
            fat: this.obj1[i].midday_fat != undefined ? this.obj1[i].midday_fat : '0',
            plan_fat: this.obj1[i].midday_plan_fat != undefined ? this.obj1[i].midday_plan_fat : '0',
            percentage: parseFloat(midday.value).toFixed(0),
            color: midday.color,
          },
          {
            name: "Lunch",
            tracking: this.obj1[i].lunch != undefined ? this.obj1[i].lunch : [],
            plan: this.obj1[i].plan_lunch != undefined ? this.obj1[i].plan_lunch : [],

            energy: this.obj1[i].lunch_energy != undefined ? this.obj1[i].lunch_energy : '0',
            plan_energy: this.obj1[i].lunch_plan_energy != undefined ? this.obj1[i].lunch_plan_energy : '0',
            proteins: this.obj1[i].lunch_proteins != undefined ? this.obj1[i].lunch_proteins : '0',
            plan_proteins: this.obj1[i].lunch_plan_proteins != undefined ? this.obj1[i].lunch_plan_proteins : '0',
            carbs: this.obj1[i].lunch_carbs != undefined ? this.obj1[i].lunch_carbs : '0',
            plan_carbs: this.obj1[i].lunch_plan_carbs != undefined ? this.obj1[i].lunch_plan_carbs : '0',
            fat: this.obj1[i].lunch_fat != undefined ? this.obj1[i].lunch_fat : '0',
            plan_fat: this.obj1[i].lunch_plan_fat != undefined ? this.obj1[i].lunch_plan_fat : '0',
            percentage: parseFloat(lunch.value).toFixed(0),
            color: lunch.color,
          },
          {
            name: "Evening",
            tracking: this.obj1[i].evening != undefined ? this.obj1[i].evening : [],
            plan: this.obj1[i].plan_evening != undefined ? this.obj1[i].plan_evening : [],

            energy: this.obj1[i].evening_energy != undefined ? this.obj1[i].evening_energy : '0',
            plan_energy: this.obj1[i].evening_plan_energy != undefined ? this.obj1[i].evening_plan_energy : '0',
            proteins: this.obj1[i].evening_proteins != undefined ? this.obj1[i].evening_proteins : '0',
            plan_proteins: this.obj1[i].evening_plan_proteins != undefined ? this.obj1[i].evening_plan_proteins : '0',
            carbs: this.obj1[i].evening_carbs != undefined ? this.obj1[i].evening_carbs : '0',
            plan_carbs: this.obj1[i].evening_plan_carbs != undefined ? this.obj1[i].evening_plan_carbs : '0',
            fat: this.obj1[i].evening_fat != undefined ? this.obj1[i].evening_fat : '0',
            plan_fat: this.obj1[i].evening_plan_fat != undefined ? this.obj1[i].evening_plan_fat : '0',
            percentage: parseFloat(evening.value).toFixed(0),
            color: evening.color,
          },
          {
            name: "Dinner",
            tracking: this.obj1[i].dinner != undefined ? this.obj1[i].dinner : [],
            plan: this.obj1[i].plan_dinner != undefined ? this.obj1[i].plan_dinner : [],

            energy: this.obj1[i].dinner_energy != undefined ? this.obj1[i].dinner_energy : '0',
            plan_energy: this.obj1[i].dinner_plan_energy != undefined ? this.obj1[i].dinner_plan_energy : '0',
            proteins: this.obj1[i].dinner_proteins != undefined ? this.obj1[i].dinner_proteins : '0',
            plan_proteins: this.obj1[i].dinner_plan_proteins != undefined ? this.obj1[i].dinner_plan_proteins : '0',
            carbs: this.obj1[i].dinner_carbs != undefined ? this.obj1[i].dinner_carbs : '0',
            plan_carbs: this.obj1[i].dinner_plan_carbs != undefined ? this.obj1[i].dinner_plan_carbs : '0',
            fat: this.obj1[i].dinner_fat != undefined ? this.obj1[i].dinner_fat : '0',
            plan_fat: this.obj1[i].dinner_plan_fat != undefined ? this.obj1[i].dinner_plan_fat : '0',
            percentage: parseFloat(dinner.value).toFixed(0),
            color: dinner.color,
          },
          {
            name: "Night",
            tracking: this.obj1[i].night != undefined ? this.obj1[i].night : [],
            plan: this.obj1[i].plan_night != undefined ? this.obj1[i].plan_night : [],

            energy: this.obj1[i].night_energy != undefined ? this.obj1[i].night_energy : '0',
            plan_energy: this.obj1[i].night_plan_energy != undefined ? this.obj1[i].night_plan_energy : '0',
            proteins: this.obj1[i].night_proteins != undefined ? this.obj1[i].night_proteins : '0',
            plan_proteins: this.obj1[i].night_plan_proteins != undefined ? this.obj1[i].night_plan_proteins : '0',
            carbs: this.obj1[i].night_carbs != undefined ? this.obj1[i].night_carbs : '0',
            plan_carbs: this.obj1[i].night_plan_carbs != undefined ? this.obj1[i].night_plan_carbs : '0',
            fat: this.obj1[i].night_fat != undefined ? this.obj1[i].night_fat : '0',
            plan_fat: this.obj1[i].night_plan_fat != undefined ? this.obj1[i].night_plan_fat : '0',
            percentage: parseFloat(night.value).toFixed(0),
            color: night.color,
          },
        ];

        this.foodLogArray.push({
          index: i,
          date: Date_Formate(this.obj1[i].created_date),
          day: this.obj1[i].day,
          expand: false,
          session: session,
          sectedSession : "",
        });
      }
      console.log("get Display Array" + JSON.stringify(this.foodLogArray));
    }
  }
  showDetails(index) {
    for (var i = 0; i < this.foodLogArray.length; i++) {
      if (this.foodLogArray[i].index == index) {
        this.foodLogArray[i].index = this.foodLogArray[i].index == false ? true : false;
      }
    }
  }
  accordianOpen(index, session) {
    $(".removeShow").removeClass("show");
    this.expandArray = [];
    console.log("session"+ session)
    for (var i = 0; i < this.foodLogArray.length; i++) {
      if (this.foodLogArray[i].index == index) {
        if(this.foodLogArray[i].expand != true){
          for(var j = 0; j < this.foodLogArray[i].session.length; j++ ){
            if( this.foodLogArray[i].session[j].name == session &&  this.foodLogArray[i].session[j].percentage != "0" ){
              $("#flush-collapse"+index).addClass("show");
              this.foodLogArray[i].sectedSession = this.foodLogArray[i].session[j].name;
              this.expandArray = this.foodLogArray[i].session[j];
            this.foodLogArray[i].expand = true;
            
            setTimeout(() => {
              this.getBarGraph(session,index);
            }, 200);
            break;
            }
          }
        }else{
          this.foodLogArray[i].expand = false;
        }
        
        break;  
      }
    }
    
  }
  getBarGraph(day,index) {
    var xAxis = [ "Energy", "Proteins", "Carbs", "Fat"];
    var Consumed = [];
    var Suggested = [];
    if (this.foodLogGraph) {
      this.foodLogGraph.destroy();
    }
      Consumed = [this.expandArray.energy,this.expandArray.proteins, this.expandArray.carbs, this.expandArray.fat];
      Suggested = [this.expandArray.plan_energy,this.expandArray.plan_proteins, this.expandArray.plan_carbs, this.expandArray.plan_fat];
   var ctx = <HTMLCanvasElement>document.getElementById(day+index);
    this.foodLogGraph = new Chart(ctx, {
      type: 'bar',
      labels: ["Consumed", "Suggested"],
      data: {
        labels: xAxis,
        datasets: [
          {
            data: Consumed,
            backgroundColor: "#01452c",
            borderColor: "#01452c",
            fill: false,
            borderWidth: 2,
            spanGaps: true,
            label: "Consumed"
          }, {
            data: Suggested,
            backgroundColor: "#ff8b00",
            borderColor: "#ff8b00",
            fill: false,
            borderWidth: 2,
            spanGaps: true,
            label: "Suggested"
          }]
      },
      options: {
        responsive: true,
        scales: {
          yAxes: [{
            display: true,
            ticks: {
              beginAtZero: true,
              steps: 100,
              stepValue: 5,

            }
          }],
          xAxes: [{
            ticks: {
              maxRotation: 0,
              minRotation: 0
            }
          }]
        },

      }
    });
  }

  OnDateChangedto(e) {
    this.t_date = e;
    this.tdate = Date_Formate(e);
  }
  OnDateChangedfrom(e) {
    this.fdate = e;
    this.f_date = Date_Formate(e);
  }
  backFunction(){
    var backPage = this.patient_list.flow == "dietPlan" ? "diet_paln" : "diet_tracker";
    this.messageservice.sendMessage(backPage);
  }
}
