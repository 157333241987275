<div class="row" style="width:100%;margin:0 auto;">
    <div class="col-12">
       <mdb-card>
          <mdb-card-header class="bg-white ">
             <div class="headerCover">
                <div class="headerTilte app_list_header">
                   <h5 class="m-0" class="mainHeadingStyle">OT Master</h5>
                </div>
             </div>
          </mdb-card-header>
          <mdb-card-body>
             <div class="conatiner-fluid mt-4" style="margin-left: 50px;">
                <div class="row">
                   <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-3">
                    <mat-label class="matlabel">OT name<br>
                        <input type="text" class="ipcss" required [(ngModel)]="otName" maxlength="100" matInput />
                    </mat-label>
                   </div>
                   <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxl-3">
                        <mat-label class="matlabel">OT Type<br>
                            <mat-select [(ngModel)]="otType" class="ipcss" required>
                                <mat-option value="select">Select</mat-option>
                                <mat-option *ngFor="let type of otTypes" value="{{type.type_id}}">{{type.description}}
                                </mat-option>
                            </mat-select>
                        </mat-label>
                   </div>
                   <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-2 col-xxl-2">
                        <mat-label class="matlabel">Floor
                            <input type="text" class="ipcss " [(ngModel)]="otfloor" required maxlength="2"
                                onkeypress="return event.charCode >= 48 && event.charCode <= 57" matInput />
                        </mat-label>
                   </div>
                   <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-3">
                        <mat-label class="matlabel">Facilities
                            <input type="text" class="ipcss " [(ngModel)]="otFacilities" matInput />
                        </mat-label>
                   </div>   
                   <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-3" style="margin-top: 20px;">
                    <img src="../../../assets/ui_icons/buttons/save_button.svg" class="saveimgbtn_inpatinfo"
                      (click)="addMaster()" />
                   </div>               
                </div>
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngIf="otList.length==0">
                        <p class="nodata">No Data found</p>
                     </div>
                    <table class="mt-4 mb-4 table table-nowrap table-sm billlisttable" *ngIf="otList.length!=0">
                      <thead>
                         <tr>
                            <th>OT name</th>
                            <th>OT Type</th>
                            <th>Facilities</th>
                            <th>Floor</th>
                            <th>&nbsp;</th>
                         </tr>
                      </thead>
                      <tbody>
                         <tr *ngFor="let operthe of otList; let i = index">
                            <td style="font-size: 14px; text-align: left;">{{ operthe.ot_name }}</td>
                            <td style="font-size: 14px; text-align: center;">{{ operthe.ot_type }}</td>
                            <td style="font-size: 14px; text-align: center;">{{ operthe.facilities }}</td>
                            <td style="font-size: 14px; text-align: center;">{{ operthe.floor }}</td>
                            <td style="font-size: 14px; text-align: center;">
                                <img  src="../../../assets/img/edit.png" class="billing_edit" (click)="editOtType(operthe)"> &nbsp;&nbsp;
                                <img  src="../../../assets/img/Delete.png" class="billing_edit" (click)="deleteOtType(operthe)">
                            </td>
                         </tr>
                      </tbody>
                   </table>
                </div>
             </div>
          </mdb-card-body>
       </mdb-card> <br><br><br>
    </div>
 </div>