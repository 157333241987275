<div *ngIf="family == 'min'">
   <div class="block1">
      <mat-label class="matlabel padding_right">Diabetics</mat-label>
      <mat-button-toggle id="toggid1" class="togglebtn" [(ngModel)]="Diabetes_txt" ngDefaultControl
         [checked]="Diabetes_txt" (change)="option($event.source.checked,1)">
         <span class="familytab" *ngIf="!Diabetes_txt"><img class="family_toggle_img"
               src="../../../assets/ui_icons/cross_mark.svg"></span>
         <span class="familytab" *ngIf="Diabetes_txt"><img class="family_toggle_img"
               src="../../../assets/ui_icons/tick_mark.svg"></span>
      </mat-button-toggle>
   </div>
   <div class="block1">
      <mat-label class="matlabel padding_right">Heart Attack</mat-label>
      <mat-button-toggle id="toggid2" class="togglebtn" [(ngModel)]="Heart_attacktxt" ngDefaultControl
         [checked]="Heart_attacktxt" (change)="option($event.source.checked,2)">
         <span class="familytab" *ngIf="!Heart_attacktxt"><img class="family_toggle_img"
               src="../../../assets/ui_icons/cross_mark.svg"></span>
         <span class="familytab" *ngIf="Heart_attacktxt"><img class="family_toggle_img"
               src="../../../assets/ui_icons/tick_mark.svg"></span>
      </mat-button-toggle>
   </div>
   <div class="block1">
      <mat-label class="matlabel padding_right">Stroke</mat-label>
      <mat-button-toggle id="toggid3" class="togglebtn" [(ngModel)]="Stroke_txt" ngDefaultControl [checked]="Stroke_txt"
         (change)="option($event.source.checked,3)">
         <span class="familytab" *ngIf="!Stroke_txt"><img class="family_toggle_img"
               src="../../../assets/ui_icons/cross_mark.svg"></span>
         <span class="familytab" *ngIf="Stroke_txt"><img class="family_toggle_img"
               src="../../../assets/ui_icons/tick_mark.svg"></span>
      </mat-button-toggle>
   </div>
</div>

<div *ngIf="family == 'max'">
   <div class="personaldetails">
      <div class="row">
         <div class="col-4">
            <div class="cover_div">
               <div class="content_cover">
                  <div class="row">
                     <div class="col-7 margin-bottomvalue">
                        <mat-label class="matlabel">Diabetics</mat-label>
                     </div>
                     <div class="col-2  margin-bottomvalue">
                        <mat-button-toggle id="toggid1" class="togglebtn" [(ngModel)]="Diabetes_txt" ngDefaultControl
                           [checked]="Diabetes_txt" (change)="option($event.source.checked,1)">
                           <span class="familytab" *ngIf="!Diabetes_txt"><img class="family_toggle_img"
                                 src="../../../assets/ui_icons/cross_mark.svg"></span>
                           <span class="familytab" *ngIf="Diabetes_txt"><img class="family_toggle_img"
                                 src="../../../assets/ui_icons/tick_mark.svg"></span>
                        </mat-button-toggle>
                     </div>
                     <div class="col-7 margin-bottomvalue">
                        <mat-label class="matlabel">Heart Attack</mat-label>
                     </div>
                     <div class="col-2  margin-bottomvalue">
                        <mat-button-toggle id="toggid2" class="togglebtn" [(ngModel)]="Heart_attacktxt" ngDefaultControl
                           [checked]="Heart_attacktxt" (change)="option($event.source.checked,2)">
                           <span class="familytab" *ngIf="!Heart_attacktxt"><img class="family_toggle_img"
                                 src="../../../assets/ui_icons/cross_mark.svg"></span>
                           <span class="familytab" *ngIf="Heart_attacktxt"><img class="family_toggle_img"
                                 src="../../../assets/ui_icons/tick_mark.svg"></span>
                        </mat-button-toggle>
                     </div>
                     <div class="col-7 margin-bottomvalue">
                        <mat-label class="matlabel">Stroke</mat-label>
                     </div>
                     <div class="col-2  margin-bottomvalue">
                        <mat-button-toggle id="toggid3" class="togglebtn" [(ngModel)]="Stroke_txt" ngDefaultControl
                           [checked]="Stroke_txt" (change)="option($event.source.checked,3)">
                           <span class="familytab" *ngIf="!Stroke_txt"><img class="family_toggle_img"
                                 src="../../../assets/ui_icons/cross_mark.svg"></span>
                           <span class="familytab" *ngIf="Stroke_txt"><img class="family_toggle_img"
                                 src="../../../assets/ui_icons/tick_mark.svg"></span>
                        </mat-button-toggle>
                     </div>
                     <div class="col-7 margin-bottomvalue">
                        <mat-label class="matlabel">Arterial</mat-label>
                     </div>
                     <div class="col-2  margin-bottomvalue">
                        <mat-button-toggle id="toggid4" class="togglebtn" [(ngModel)]="Arterial_txt" ngDefaultControl
                           [checked]="Arterial_txt" (change)="option($event.source.checked,4)">
                           <span class="familytab" *ngIf="!Arterial_txt"><img class="family_toggle_img"
                                 src="../../../assets/ui_icons/cross_mark.svg"></span>
                           <span class="familytab" *ngIf="Arterial_txt"><img class="family_toggle_img"
                                 src="../../../assets/ui_icons/tick_mark.svg"></span>
                        </mat-button-toggle>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div class="col-4" style="position: relative;left: -18px;">
            <div class="cover_div">
               <div class="content_cover">
                  <div class="row">
                     <div class="col-7 margin-bottomvalue">
                        <mat-label class="matlabel">Endocrine</mat-label>
                     </div>
                     <div class="col-2  margin-bottomvalue">
                        <mat-button-toggle id="toggid5" class="togglebtn" [(ngModel)]="Endocrine_txt" ngDefaultControl
                           [checked]="Endocrine_txt" (change)="option($event.source.checked,5)">
                           <span class="familytab" *ngIf="!Endocrine_txt"><img class="family_toggle_img"
                                 src="../../../assets/ui_icons/cross_mark.svg"></span>
                           <span class="familytab" *ngIf="Endocrine_txt"><img class="family_toggle_img"
                                 src="../../../assets/ui_icons/tick_mark.svg"></span>
                        </mat-button-toggle>
                     </div>
                     <div class="col-7 margin-bottomvalue">
                        <mat-label class="matlabel">Autoimmune</mat-label>
                     </div>
                     <div class="col-2  margin-bottomvalue">
                        <mat-button-toggle id="toggid6" class="togglebtn" [(ngModel)]="Autoiimue_txt" ngDefaultControl
                           [checked]="Autoiimue_txt" (change)="option($event.source.checked,6)">
                           <span class="familytab" *ngIf="!Autoiimue_txt"><img class="family_toggle_img"
                                 src="../../../assets/ui_icons/cross_mark.svg"></span>
                           <span class="familytab" *ngIf="Autoiimue_txt"><img class="family_toggle_img"
                                 src="../../../assets/ui_icons/tick_mark.svg"></span>
                        </mat-button-toggle>
                     </div>
                     <div class="col-7 margin-bottomvalue">
                        <mat-label class="matlabel">Hypertension</mat-label>
                     </div>
                     <div class="col-2  margin-bottomvalue">
                        <mat-button-toggle id="toggid7" class="togglebtn" [(ngModel)]="Hyper_txt" ngDefaultControl
                           [checked]="Hyper_txt" (change)="option($event.source.checked,7)">
                           <span class="familytab" *ngIf="!Hyper_txt"><img class="family_toggle_img"
                                 src="../../../assets/ui_icons/cross_mark.svg"></span>
                           <span class="familytab" *ngIf="Hyper_txt"><img class="family_toggle_img"
                                 src="../../../assets/ui_icons/tick_mark.svg"></span>
                        </mat-button-toggle>
                     </div>
                     <div class="col-7 margin-bottomvalue">
                        <mat-label class="matlabel">Hemophilia</mat-label>
                     </div>
                     <div class="col-2  margin-bottomvalue">
                        <mat-button-toggle id="toggid8" class="togglebtn" [(ngModel)]="Hemophila_txt" ngDefaultControl
                           [checked]="Hemophila_txt" (change)="option($event.source.checked,8)">
                           <span class="familytab" *ngIf="!Hemophila_txt"><img class="family_toggle_img"
                                 src="../../../assets/ui_icons/cross_mark.svg"></span>
                           <span class="familytab" *ngIf="Hemophila_txt"><img class="family_toggle_img"
                                 src="../../../assets/ui_icons/tick_mark.svg"></span>
                        </mat-button-toggle>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div class="col-4" style="position: relative;left: -36px;">
            <div class="cover_div">
               <div class="content_cover">
                  <div class="row">
                     <div class="col-7 margin-bottomvalue">
                        <mat-label class="matlabel">Tuberculosis</mat-label>
                     </div>
                     <div class="col-2  margin-bottomvalue">
                        <mat-button-toggle id="toggid9" class="togglebtn" [(ngModel)]="Tuberculosis_txt"
                           ngDefaultControl [checked]="Tuberculosis_txt" (change)="option($event.source.checked,9)">
                           <span class="familytab" *ngIf="!Tuberculosis_txt"><img class="family_toggle_img"
                                 src="../../../assets/ui_icons/cross_mark.svg"></span>
                           <span class="familytab" *ngIf="Tuberculosis_txt"><img class="family_toggle_img"
                                 src="../../../assets/ui_icons/tick_mark.svg"></span>
                        </mat-button-toggle>
                     </div>
                     <div class="col-7 margin-bottomvalue">
                        <mat-label class="matlabel">HIV</mat-label>
                     </div>
                     <div class="col-2  margin-bottomvalue">
                        <mat-button-toggle id="toggid10" class="togglebtn" [(ngModel)]="HIV_txt" ngDefaultControl
                           [checked]="HIV_txt" (change)="option($event.source.checked,10)">
                           <span class="familytab" *ngIf="!HIV_txt"><img class="family_toggle_img"
                                 src="../../../assets/ui_icons/cross_mark.svg"></span>
                           <span class="familytab" *ngIf="HIV_txt"><img class="family_toggle_img"
                                 src="../../../assets/ui_icons/tick_mark.svg"></span>
                        </mat-button-toggle>
                     </div>
                     <div class="col-7 margin-bottomvalue">
                        <mat-label class="matlabel">Hepatitis</mat-label>
                     </div>
                     <div class="col-2  margin-bottomvalue">
                        <mat-button-toggle id="toggid11" class="togglebtn" [(ngModel)]="Hepatatis_txt" ngDefaultControl
                           [checked]="Hepatatis_txt" (change)="option($event.source.checked,11)">
                           <span class="familytab" *ngIf="!Hepatatis_txt"><img class="family_toggle_img"
                                 src="../../../assets/ui_icons/cross_mark.svg"></span>
                           <span class="familytab" *ngIf="Hepatatis_txt"><img class="family_toggle_img"
                                 src="../../../assets/ui_icons/tick_mark.svg"></span>
                        </mat-button-toggle>
                     </div>
                     <div class="col-7 margin-bottomvalue">
                        <mat-label class="matlabel">Glaucoma</mat-label>
                     </div>
                     <div class="col-2  margin-bottomvalue">
                        <mat-button-toggle id="toggid12" class="togglebtn" [(ngModel)]="Glaucoma_txt" ngDefaultControl
                           [checked]="Glaucoma_txt" (change)="option($event.source.checked,12)">
                           <span class="familytab" *ngIf="!Glaucoma_txt"><img class="family_toggle_img"
                                 src="../../../assets/ui_icons/cross_mark.svg"></span>
                           <span class="familytab" *ngIf="Glaucoma_txt"><img class="family_toggle_img"
                                 src="../../../assets/ui_icons/tick_mark.svg"></span>
                        </mat-button-toggle>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="row">
         <div class="col-12" style="margin-top: 5px;">
            <mat-label>Others<br>
               <textarea class="ipcss habista" [(ngModel)]="family_other"></textarea>
            </mat-label>
         </div>
      </div>
   </div>
</div>