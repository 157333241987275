<div *ngIf="personal == 'min'">
    <mat-label class="matlabel">Main concerns<br>
        <textarea type="text" class="ipcss scpersonal_width" [readonly]="isReadonly()" [(ngModel)]="main_concerns"
            matInput></textarea>
    </mat-label>
    <mat-label class="matlabel">Past Medical History<br>
        <textarea type="text" class="ipcss scpersonal_width" [readonly]="isReadonly()" [(ngModel)]="past_medical_hist"
            matInput></textarea>
    </mat-label>
</div>

<div *ngIf="personal == 'max'">
    <div class="container">
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div class="cover_div">
                            <div class="header_lable">
                                <b>Personal</b>
                            </div>
                            <div class="content_cover">
                                <div class="row">
                                    <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <mat-label class="matlabel">Name<br>
                                            <input type="text" class="ipcss scpersonal_width" [readonly]="isReadonly()"
                                                [(ngModel)]="name" matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <mat-label class="matlabel">Age<br>
                                            <input type="text" class="ipcss scpersonal_width" [readonly]="isReadonly()"
                                                [(ngModel)]="age" matInput />
                                        </mat-label>
                                    </div>

                                    <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <mat-label class="matlabel">Gender<br>
                                            <input type="text" class="ipcss scpersonal_width" [readonly]="isReadonly()"
                                                [(ngModel)]="gender" matInput />
                                        </mat-label>
                                    </div>

                                    <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <mat-label class="matlabel">Marital status<br>
                                            <input type="text" class="ipcss scpersonal_width" [readonly]="isReadonly()"
                                                [(ngModel)]="marital_stat" matInput />
                                        </mat-label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div class="cover_div">
                            <div class="header_lable">
                                <b>Observations</b>
                            </div>
                            <div class="content_cover">
                                <div class="row">
                                    <div class="col-3 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Height<br>
                                            <input type="text" class="ipcss" [readonly]="isReadonly()"
                                                [(ngModel)]="Height_txt" matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-3 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Measure<br>
                                            <input type="text" class="ipcss" [readonly]="isReadonly()"
                                                [(ngModel)]="heightkg_txt" matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-3 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Weight<br>
                                            <input type="text" class="ipcss" [readonly]="isReadonly()"
                                                [(ngModel)]="weight_txt" matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-3 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Measure<br>
                                            <input type="text" class="ipcss" [readonly]="isReadonly()"
                                                [(ngModel)]="weightkg_txt" matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-3 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">BMI<br>
                                            <input type="text" class="ipcss scpersonal_width" [readonly]="isReadonly()"
                                                [(ngModel)]="BMI_txt" matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-3 col-sm-6 col-md-3 col-lg-3 col-xl-3" style="width: fit-content;">
                                        <mat-label class="matlabel">Blood Pressure<br>
                                            <input type="text" class="ipcss scpersonal_width" [readonly]="isReadonly()"
                                                [(ngModel)]="blood_pres" matInput />
                                        </mat-label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div class="cover_div">
                            <div class="header_lable">
                                <b>Concerns</b>
                            </div>
                            <div class="content_cover">
                                <div class="row">
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Main concerns<br>
                                            <textarea type="text" class="ipcss scpersonal_width"
                                                [readonly]="isReadonly()" [(ngModel)]="main_concerns"
                                                matInput></textarea>
                                        </mat-label>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Past Medical History<br>
                                            <textarea type="text" class="ipcss scpersonal_width"
                                                [readonly]="isReadonly()" [(ngModel)]="past_medical_hist"
                                                matInput></textarea>
                                        </mat-label>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Religious or Other Beliefs<br>
                                            <textarea type="text" class="ipcss scpersonal_width"
                                                [readonly]="isReadonly()" [(ngModel)]="religious_txt"
                                                matInput></textarea>
                                        </mat-label>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Any Other Issue<br>
                                            <textarea type="text" class="ipcss scpersonal_width"
                                                [readonly]="isReadonly()" [(ngModel)]="Any_issue" matInput></textarea>
                                        </mat-label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div class="cover_div">
                            <div class="header_lable">
                                <b>Stress Factors</b>
                            </div>
                            <div class="content_cover">
                                <div class="row">
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        <textarea type="text" class="ipcss" [readonly]="isReadonly()"
                                            [(ngModel)]="stress_factortxt" matInput></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div class="cover_div">
                            <div class="header_lable">
                                <b>Dietary Habits</b>
                            </div>
                            <div class="content_cover">
                                <div class="row">
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        <textarea type="text" class="ipcss" [readonly]="isReadonly()"
                                            [(ngModel)]="Dietary_txt" matInput></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div [hidden]="HabitualDIV" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <p class="tervys_heading col-12"><b>Habitual</b></p>
                        <div class="content_cover">
                            <div class="row">
                                <div [hidden]="gutka_div" class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <div class="cover_div">
                                        <div class="header_lable">
                                            <b>Gutka/Areca</b>
                                        </div>
                                        <div class="content_cover">
                                            <div class="row">
                                                <div [hidden]="gutka_div"
                                                    class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                    <mat-label [hidden]="gutka_div" class="matlabel">Duration<br>
                                                        <input type="text" class="ipcss scpersonal_width"
                                                            [readonly]="isReadonly()" [(ngModel)]="gutka_dure"
                                                            matInput />
                                                    </mat-label>
                                                </div>
                                                <div [hidden]="gutka_div"
                                                    class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                    <mat-label [hidden]="gutka_div" class="matlabel">
                                                        Frequency/day(Times)<br>
                                                        <input type="text" class="ipcss scpersonal_width"
                                                            [readonly]="isReadonly()" [(ngModel)]="gutka_freqency"
                                                            matInput />
                                                    </mat-label>
                                                </div>
                                                <div [hidden]="gutka_div"
                                                    class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div [hidden]="Alcohol_div" class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <div class="cover_div">
                                        <div class="header_lable">
                                            <b>Alcohol</b>
                                        </div>
                                        <div class="content_cover">
                                            <div class="row">
                                                <div [hidden]="Alcohol_div"
                                                    class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                    <mat-label [hidden]="Alcohol_div" class="matlabel">Quantity<br>
                                                        <input type="text" class="ipcss scpersonal_width"
                                                            [readonly]="isReadonly()" [(ngModel)]="quan_txt" matInput />
                                                    </mat-label>
                                                </div>
                                                <div [hidden]="Alcohol_div"
                                                    class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                    <mat-label [hidden]="Alcohol_div" class="matlabel">Duration<br>
                                                        <input type="text" class="ipcss scpersonal_width"
                                                            [readonly]="isReadonly()" [(ngModel)]="alcohol_dure"
                                                            matInput />
                                                    </mat-label>
                                                </div>
                                                <div [hidden]="Alcohol_div"
                                                    class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                    <mat-label [hidden]="Alcohol_div" class="matlabel">Mixing Type<br>
                                                        <input type="text" class="ipcss scpersonal_width"
                                                            [readonly]="isReadonly()" [(ngModel)]="alcohol_mix"
                                                            matInput />
                                                    </mat-label>
                                                </div>
                                                <div [hidden]="Alcohol_div"
                                                    class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                    <mat-label [hidden]="Alcohol_div" class="matlabel">
                                                        Frequency/Day(Times)<br>
                                                        <input type="text" class="ipcss scpersonal_width"
                                                            [readonly]="isReadonly()" [(ngModel)]="alcohol_freq"
                                                            matInput />
                                                    </mat-label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div [hidden]="Exercises_Div" class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <div class="cover_div">
                                        <div class="header_lable">
                                            <b>Exercises</b>
                                        </div>
                                        <div class="content_cover">
                                            <div class="row">
                                                <div [hidden]="Exercises_Div"
                                                    class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                                    <mat-label [hidden]="Exercises_Div" class="matlabel">Duration<br>
                                                        <input type="text" class="ipcss scpersonal_width"
                                                            [readonly]="isReadonly()" [(ngModel)]="exer_dure"
                                                            matInput />
                                                    </mat-label>
                                                </div>
                                                <div [hidden]="Alcohol_div"
                                                    class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                                    <mat-label [hidden]="Exercises_Div" class="matlabel">Exercise
                                                        routine<br>
                                                        <input type="text" class="ipcss scpersonal_width"
                                                            [readonly]="isReadonly()" [(ngModel)]="exer_routine"
                                                            matInput />
                                                    </mat-label>
                                                </div>
                                                <div [hidden]="Exercises_Div"
                                                    class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                                    <mat-label [hidden]="Exercises_Div" class="matlabel">Exercises<br>
                                                        <input type="text" class="ipcss scpersonal_width"
                                                            [readonly]="isReadonly()" [(ngModel)]="excer_txt"
                                                            matInput />
                                                    </mat-label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>