import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DietCasesheetService {
     private subject = new Subject<any>();

    sendCasesheetMessage(message: string) {
        this.subject.next(message);
    }

    clearCasesheetMessages() {
        this.subject.next();
    }

    getCasesheetMessage(): Observable<any> {
        return this.subject.asObservable();
    }
}