<mdb-card>
    <mdb-card-header class="bg-white" style="border-bottom: 0 !important;">
        <div class="headerCover">
            <div class="headerTilte app_list_header">
                <h5 class="m-0" class="mainHeadingStyle">Campaign management</h5>
            </div>
            <div class="headerButtons" style="text-align: right;"  *ngIf="history_flag">
                <img class="history_btn" src="../../../assets/ui_icons/buttons/Back_button.svg" (click)="backClicked()" />
            </div>
            <div class="headerButtons" style="text-align: right;"  *ngIf="!history_flag">
                <img class="history_btn" src="../../../assets/ui_icons/buttons/History_button.svg" (click)="leadHistory()" />
            </div>
        </div>
        
    </mdb-card-header>
    <mdb-card-body style="margin-top: -10px;">
        <div *ngIf="!history_flag">
            <div class="cover_div" style="padding-bottom: 13px;">
                <div class="header_lable">
                    Campaign
                </div>
                <div class="content_cover">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                            <mat-label class="matlabel">Patient name
                                <input type="text" class="ipcss" [(ngModel)]="patientName">
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                            <mat-label class="matlabel">City
                                <input type="text" class="ipcss" [(ngModel)]="patCity">
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                            <mat-label class="matlabel">Contact no
                                <input type="text" class="ipcss" [(ngModel)]="patContact">
                            </mat-label>
                        </div>
                        <!-- <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 ">
                            <div class="row">
                                <mat-label class="matlabel">Doctor
                                    <input type="text" class="ipcss " [(ngModel)]="DoctorName"
                                        (keyup)="getReferralDoctorList($event)" matInput [matAutocomplete]="auto" />
                                    <mat-autocomplete #auto="matAutocomplete">
                                        <mat-option (click)="sltReferDoctor(item)" *ngFor="let item of referredDoctorList"
                                            [value]="item">
                                            {{item}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-label>
                            </div>
                        </div> -->
                        <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                            <mat-label class="matlabel">Channel
                                <mat-select class="ipcss widthappt" [(ngModel)]="channel">
                                    <mat-option *ngFor="let channel of channel_arr" value="{{channel.channel_id}}">
                                        {{channel.channel_desc}}
                                    </mat-option>
                                </mat-select>
                            </mat-label>
                        </div>
                        
                        <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                            <mat-label class="matlabel">Campaign Date
                                <input type="date" class="ipcss" [(ngModel)]="campDate" max="{{campDate}}">
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                            <mat-label class="matlabel">Campaign type
                                <mat-select class="ipcss widthappt" [(ngModel)]="leadType">
                                    <mat-option *ngFor="let type of lead_type" value="{{type.lead_type_id}}">
                                        {{type.lead_desc}}
                                    </mat-option>
                                </mat-select>
                            </mat-label>
                        </div>
                        <!-- <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                            <mat-label class="matlabel">Symptoms
                                <input type="text" class="ipcss " [(ngModel)]="symptom" (keyup)="getSymptoms()" matInput
                                    [matAutocomplete]="auto1" />
                                <mat-autocomplete #auto1="matAutocomplete">
                                    <mat-option (click)="select_symptom(symptom)" *ngFor="let symptom of symptomlist"
                                        [value]="symptom">
                                        {{symptom}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                            <mat-label class="matlabel">Diagnosis
                                <input type="text" class="ipcss" [(ngModel)]="diagnosis">
                            </mat-label>
                        </div> -->
                        <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                            <mat-label class="matlabel">Status
                                <!-- <input type="text" class="ipcss" [(ngModel)]="status" maxlength="50"> -->
    
                                <mat-select class="ipcss widthappt" [(ngModel)]="status">
                                    <mat-option *ngFor="let status of lead_status_arr" value="{{status.lead_stat_id}}">
                                        {{status.lead_stat_desc}}
                                    </mat-option>
                                </mat-select>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                            <mat-label class="matlabel">Representative name
                                <!-- <input type="text" class="ipcss" [(ngModel)]="status" maxlength="50"> -->
    
                                <mat-select class="ipcss widthappt" [(ngModel)]="repId">
                                    <mat-option *ngFor="let rep of repNameArray" value="{{rep.repId}}">
                                        {{rep.repName}}
                                    </mat-option>
                                </mat-select>
                            </mat-label>
                        </div>
                        
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                            <mat-label class="matlabel">Remarks
                                <textarea type="text" cols="35" rows="15" class="ipcss" [(ngModel)]="remarks"></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                            <mat-label class="matlabel">Notes
                                <textarea type="text" cols="35" rows="15" class="ipcss" [(ngModel)]="notes"></textarea>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3 col-lg-2">
                            <mat-label class="matlabel" style="cursor:pointer"><br>
                                <img src="../../../assets/ui_icons/buttons/save_button.svg" class="saveimgbtn_inpatinfo"
                                    (click)="saveLeadGeneration()" />
                            </mat-label>
                        </div>
                    </div>
                </div>
            </div>
            <br/>
            <div class="nodata" *ngIf="leadArray.length ==0" >No data found</div>
            <div style="overflow: scroll;">
                <table mdbTable datatable [dtOptions]="dtOptions" class="table table-nowrap table-sm dataTable mb-5" style="width:100%" *ngIf="leadArray.length !=0">
                    <thead>
                        <tr>
                            <th>Patient Name</th>
                            <th>City</th>
                            <th>Contact no</th>
                            <th>Channel</th>
                            <th>Campaign Type</th>
                            <th>Camp Date</th>
                            <th>Status</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let lead of leadArray">
                            <td style="width: 150px;">{{lead.patientname}}</td>
                            <td style="width: 100px;">{{lead.patCity}}</td>
                            <td style="width: 50px;">{{lead.patContact}}</td>
                            <td style="width: 50px;">{{lead.lead_channel}}</td>
                            <td style="width: 150px;">{{lead.lead_type}}</td>
                            <td style="width: 100px;">{{lead.camp_date}}</td>
                            <td style="width: 50px;">{{lead.status}}</td>
                            <td style="width: 20px;"><img src="../../../assets/img/edit.png" class="lead_edit" style="z-index: 9;" alt="" (click)="editLead(lead)"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div *ngIf="history_flag">
            <div class="row">
                <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                    <mat-label class="matlabel">Filter type
                        <mat-select class="ipcss widthappt" [(ngModel)]="search_filter" (ngModelChange)="fiterCampaign()">
                            <mat-option  value="channel" selected>Channel</mat-option>
                            <mat-option  value="campaign">Campaign</mat-option>
                            <mat-option  value="date">Date</mat-option>
                        </mat-select>
                    </mat-label>
                </div>
    
                <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3" *ngIf="channel_flag">
                    <mat-label class="matlabel">Channel
                        <mat-select class="ipcss widthappt" [(ngModel)]="channel">
                            <mat-option *ngFor="let channel of channel_arr" value="{{channel.channel_id}}">
                                {{channel.channel_desc}}
                            </mat-option>
                        </mat-select>
                    </mat-label>
                </div>
    
                <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3" *ngIf="camp_flag">
                    <mat-label class="matlabel">Campaign type
                        <mat-select class="ipcss widthappt" [(ngModel)]="leadType">
                            <mat-option *ngFor="let type of lead_type" value="{{type.lead_type_id}}">
                                {{type.lead_desc}}
                            </mat-option>
                        </mat-select>
                    </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-3 col-lg-2" *ngIf="date_flag">
                    <mat-label class="matlabel">From date
                        <input type="date" class="ipcss" [(ngModel)]="from_date" >
                    </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-3 col-lg-2" *ngIf="date_flag">
                    <mat-label class="matlabel">To date
                        <input type="date" class="ipcss" [(ngModel)]="to_date" >
                    </mat-label>
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 d-flex align-items-center">
                    <mat-label class="matlabel">
                      <a (click)="searchCampaign()"><img src="../../../assets/ui_icons/buttons/search_button.svg"
                          class="saveimgbtn_inpatinfo topvalue" /></a>
                    </mat-label>
                </div>
            </div>
            <br/>
            <div class="nodata" *ngIf="filtleadArray.length ==0" >No data found</div>
            <div style="overflow: scroll;" *ngIf="filtleadArray.length !=0">
                <table mdbTable datatable [dtOptions]="dtOptions" class="table table-nowrap table-sm dataTable mb-5" style="width:100%">
                    <thead>
                        <tr>
                            <th>Patient Name</th>
                            <th>City</th>
                            <th>Contact no</th>
                            <th>Channel</th>
                            <th>Campaign Type</th>
                            <th>Status</th>
                            <th>Camp Date</th>
                            <th>Created date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let lead of filtleadArray">
                            <td style="width: 150px;">{{lead.patientname}}</td>
                            <td style="width: 100px;">{{lead.patCity}}</td>
                            <td style="width: 50px;">{{lead.patContact}}</td>
                            <td style="width: 50px;">{{lead.lead_channel}}</td>
                            <td style="width: 150px;">{{lead.lead_type}}</td>
                            <td style="width: 50px;">{{lead.status}}</td>
                            <td style="width: 100px;">{{lead.camp_date}}</td>
                            <td style="width: 100px;">{{lead.created_date}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </mdb-card-body>
</mdb-card>