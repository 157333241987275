import { Component, OnInit } from '@angular/core';
import { Helper_Class } from '../../helper_class';
import { ToastrService } from 'ngx-toastr';
import { ipaddress } from 'src/app/ipaddress';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Time_Formate } from '../../../assets/js/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FrontDesk_Helper } from '../FrontDesk_Helper';

@Component({
  selector: 'app-ot-procedure',
  templateUrl: './ot-procedure.component.html',
  styleUrls: ['./ot-procedure.component.scss']
})
export class OTProcedureComponent implements OnInit {

  [x: string]: any;
  public hospitalDetails;
  UserInfo: any;
  otDataList: any;
  eventList: any[];
  otDate: any;
  selectedBookingId: number;
  selectedFile: File; // Holds the selected file
  booking_id: any;
  form: FormGroup;
  pat_med_required: File;
  pat_position_table: File;
  inpatient_id: any;
  headname: any;
  patName: any;
  editbuttondie: boolean = false;
  newbuttondie : boolean = false;
  deletebuttondie: boolean = false;
  viewbuttondie: boolean = false;
  printbuttondie: boolean = false;

  constructor(public http: HttpClient, public toastr: ToastrService, private formBuilder: FormBuilder) {

    this.form = this.formBuilder.group({
      medicalHistoryVerified: '0',
      underlyingIssuesVerified: '0',
      physicalExaminationVerified: '0',
      diagnosisTestsVerified: '0',
      surgeonExplanationVerified: '0',
      otDisinfectedVerified: '0',
      otasepticVerified: '0',
      otSteriledrapesVerified: '0',
      otCleanedVerified: '0',
      otDfectedVerified: '0',
      patmedrequire:'0'   
    });
   }

  ngOnInit(): void {
    this.hospitalDetails = Helper_Class.getHospitalId();
    this.selectOTDate();
    if (FrontDesk_Helper.getmodules() != null) {
      for (var i = 0; i < FrontDesk_Helper.getmodules().length; i++) {
        if (FrontDesk_Helper.getmodules()[i].module_id == "25") {
          if (FrontDesk_Helper.getmodules()[i].edit == "1" ) {
            this.editbuttondie = true;
          }
          if (FrontDesk_Helper.getmodules()[i].create == "1"  ) {
            this.newbuttondie = true;
          }
          if (FrontDesk_Helper.getmodules()[i].delete == "1") {
            this.deletebuttondie = true;
          }
          if (FrontDesk_Helper.getmodules()[i].print == "1") {
            this.printbuttondie = true; 
          }
          if (FrontDesk_Helper.getmodules()[i].view == "1" ) {
            this.viewbuttondie = true;
          }
        }
      }
    }
  }

  selectOTDate() {
    var send_data = {
      hptl_clinic_id: this.hospitalDetails,
      date: this.otDate,
    };
    this.getOtData(send_data);
  }

  getOtData(send_data) {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'inpat/gotd', send_data, { headers: headers }).subscribe(
      (response: any) => {
        this.otDataList = [];
        this.eventList = [];
        var obj = response.otbooking_details;
        console.log("obj",obj)
        if (obj != undefined) {
          for (var i = 0; i < obj.length; i++) {
            var patName = encrypt_decript.Decript(obj[i].first_name);
            var fromTimeList, fromTime, toTimeList, toTime;
            if (obj[i].from_time != undefined) {
              fromTimeList = obj[i].from_time.split(":");
              fromTime = fromTimeList[2];
            }
            if (obj[i].to_time != undefined) {
              toTimeList = obj[i].to_time.split(":");
              toTime = fromTimeList[2];
            }

            this.eventList.push({
              title: patName,
              start: obj[i].booking_date + 'T' + obj[i].from_time,
              end: obj[i].booking_date + 'T' + obj[i].to_time,
            });

            var bdate;
            if (obj[i].booking_date != undefined) {
              var bookdate = obj[i].booking_date.split("-");
              bdate = bookdate[2] + "-" + bookdate[1] + "-" + bookdate[0];
            }
            this.otDataList.push({
              inpatient_id:obj[i].inpatient_id,
              patFirstName: patName,
              ot_name: obj[i].ot_name,
              booking_date: bdate,
              booking_id: obj[i].booking_id,
              from_time: fromTime,
              to_time: toTime,
              from_time1: Time_Formate(obj[i].from_time),
              to_time1: Time_Formate(obj[i].to_time),
              profile_image: obj[i].profile_image,
            });
          }
        }
      }
    );
  }

  handleRowClick(booking_id: number, inpatient_id: number,patFirstName) {
    this.booking_id = booking_id;
    this.inpatient_id = inpatient_id;
    this. patFirstName = patFirstName;
    this.toastr.info('Clicked row with booking ID: ' + booking_id + ' and inpatient ID: ' + inpatient_id);
   
   
  }
  

  // handleMedRequiredFileInput(event: any): void {
  //   this.concent_form = event.target.files[0];
  //   this.convertFileToBase64(this.concent_form , 'concent_form');
  //   console.log("concent_form",this.concent_form)
  //   // .split(',')[1]
  // }

  // handlePositionTableFileInput(event: any): void {
  //   this.pat_position_table = event.target.files[0];
  //   this.convertFileToBase64(this.pat_position_table, 'pat_position_table');

  //   console.log("pat_position_table",this.pat_position_table)
  // }
  handleMedRequiredFileInput(event: any): void {
    this.concent_form = event.target.files[0];
    this.extractImageBase64(this.concent_form).then((base64Data: string) => {
   
     
      this.base64DataConcentForm = base64Data;
    });
  }
  
  handlePositionTableFileInput(event: any): void {
    this.pat_position_table = event.target.files[0];
    this.extractImageBase64(this.pat_position_table).then((base64Data: string) => {
      this.base64DataPatPositionTable = base64Data;
    });
  }
  
  extractImageBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
  
      reader.onload = (event: any) => {
        const base64String = event.target.result;
        if (typeof base64String === 'string') {
          const base64Data = base64String.split(',')[1];
          resolve(base64Data);
        } else {
          reject(new Error('Failed to read image data'));
        }
      };
  
      reader.onerror = (error) => {
        reject(error);
      };
  
      reader.readAsDataURL(file);
    });
  }
  


  convertFileToBase64(file: File, propertyName: string): void {
    const reader = new FileReader();

    reader.onload = (event: any) => {
      const base64String = event.target.result;
      // Do something with the base64String or store it in your component
      this[propertyName] = base64String;
    };

    reader.readAsDataURL(file);
  }

  onSaveClick() {
    const formData = this.form.value;
    const data = {
      inpatient_id:this.inpatient_id,
      ot_id: this.booking_id,
      
      patient_med_hist: formData.medicalHistoryVerified,
      pat_underlying_issues: formData.underlyingIssuesVerified,
      pat_physical_exam: formData.physicalExaminationVerified,
      pat_diag_test: formData.diagnosisTestsVerified,
      pat_sur_explanation: formData.surgeonExplanationVerified,
      pat_med_required:formData.patmedrequire,
      concent_form:this.base64DataConcentForm,
      follow_aseptic_proc: formData.otasepticVerified,
      sterile_drape_placed: formData.otSteriledrapesVerified,
      pat_position_table: this.base64DataPatPositionTable,
      ot_cleaned: formData.otCleanedVerified,
      ot_disinfect: formData.otDfectedVerified,
    };

    console.log("data", data);

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString()+'inpat/sotp', data, 
    {headers: headers })
    .subscribe(
      response => {
        var obj = JSON.parse(JSON.stringify(response));
        this.toastr.success('Data saved successfully.');
        console.log("final",obj)
        console.log("final",response)
      },
      (error) => {
        this.toastr.error('Failed to save data.');
        console.log("final data",data)
        
      }
    );
  }

}
