<div mat-dialog-title class="popup_border_icd">
    <h5 class="header_txt">Video</h5>
    <div mat-dialog-actions>
      <img src="../../../assets/img/close_icon.png" (click)="close()" width="20px" height="20px" id="close_icd" />
    </div>
  </div>
  <div mat-dialog-content class="icd_content">
    <div class="container">
      <div class="row" style="max-height: 80vh;">
        <div class="col-12">
          <div id="errorMsg"></div>
          <video class="video_style" id="partner-video" (click)="swapVideo('my-video')" autoplay playsinline
            [ngClass]="{'top-video': topVideoFrame === 'partner-video', 'main-video': topVideoFrame != 'partner-video'}">
          </video>
          <video class="video_style" id="my-video" muted="true" (click)="swapVideo('partner-video')" autoplay playsinline
            [ngClass]="{'top-video': topVideoFrame === 'my-video', 'main-video': topVideoFrame != 'my-video'}">
          </video>
          <div>
            <div class="row" style="position: relative;">
              <div class="col-12">
                <img src="../../../assets/img/call.png" [hidden]="callFlag" class="call_button"
                  (click)="inital(true,'my-video');" />
                <img src="../../../assets/img/reject.png" [hidden]="!callFlag" class="call_button"
                  (click)="disconnectCall()" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div mat-dialog-actions align="end" style="width: auto;right: 19px;">
  </div>