import { Component, OnInit, ElementRef, Input, Inject, ViewEncapsulation, HostListener, ViewChild } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from 'src/app/helper_class';
import { Message_data } from 'src/assets/js/Message_data';
import { ipaddress } from '../../ipaddress';
import { Doc_Helper } from '../Doc_Helper';
@Component({
  selector: 'app-previous-prescription-popup',
  templateUrl: './previous-prescription-popup.component.html',
  styleUrls: ['./previous-prescription-popup.component.css']
})
export class PreviousPrescriptionPopupComponent implements OnInit {
  public element: any;
  public docid: string;
  public every_six: string;
  public dure_txt: string;
  public dure_write: string;
  public afterfood_txt: string;
  public generic;
  public intake_txt: string;
  dtOptions: DataTables.Settings = {};
  persons: any = [];
  masterSelected: boolean = false;
  checklist: any;
  checkedList: any;
  public dure_flag: boolean;
  public nodataapp: boolean;
  public saveflag: boolean = true;

  public sortdata = [];
  public listProducts = [];
  public selectedList = [];
  public userinfo;
  public param_four: boolean;



  constructor(public http: Http, public dialogRef: MatDialogRef<PreviousPrescriptionPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public el: ElementRef, public toastr: ToastrService) {
    dialogRef.disableClose = true;
    this.element = el.nativeElement;
    this.dure_flag = false;
    this.nodataapp = true;
  }

  ngOnInit(): void {
    console.log("Helper_Class.getInfo() 00" + JSON.stringify(this.docid))
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 5,
      order: [[1, 'desc']],
      columnDefs: [
        { orderable: false, targets: [-1] }, // Exclude the last column from sorting
      ],
      language: {
        search: "<img src='../../../assets/img/search.png' width='18px' style='display:inline-block;'/>",
        searchPlaceholder: "Search by medicine name",
      },
      dom: '<"row"<"col-sm-12 col-xs-12"f>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center"p>>',
    };
    
    // this.dtOptions = {
    //   pagingType: 'simple_numbers_no_ellipses',
    //   pageLength: 5,
    //   order: [[1, 'desc']],
    //   "columnDefs": [
    //     { "orderable": false, "targets": [0] },
    //   ],
    //   "language": {
    //     "search": "<img src='../../../assets/img/search.png' width='18px' style='display:inline-block;'/>",
    //     searchPlaceholder: "Search by medicine name"
    //   },
    //   dom: '<"row"<"col-sm-12 col-xs-12"f>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center"p>>'
    // };

    console.log("check getAppFlow --" + JSON.stringify(Doc_Helper.getAppFlow()))
    console.log("check setClient_Info --" + JSON.stringify(Doc_Helper.getClient_Info()))
    if (Doc_Helper.getAppFlow() != undefined) {
      if (Doc_Helper.getAppFlow() == "Inpatient") {
        this.docid = Doc_Helper.getClient_Info().doc_reg_id
      }
      if (Doc_Helper.getClient_Info().AppFlow == "Inpatient" || Doc_Helper.getClient_Info().AppFlow == "InpatMedPres_dis" || Doc_Helper.getClient_Info().AppFlow == "InpatMedPres") {
        this.docid = Doc_Helper.getClient_Info().doc_reg_id
      }
      else if (Doc_Helper.getClient_Info().AppFlow == "InpatMedPres_dis" || Doc_Helper.getClient_Info().AppFlow == "InpatMedPres") {
        this.docid = Doc_Helper.getClient_Info().doc_reg_id
      }
      else if (Doc_Helper.getClient_Info().AppFlow == "InpatMedPres") {
        this.docid = Doc_Helper.getClient_Info().doc_reg_id
      }

      else {
        this.userinfo = Helper_Class.getInfo();
        this.docid = this.userinfo.user_id;
        // this.getPreviousPrescriptions();
      }
      this.getPreviousPrescriptions();
    } else {
      this.userinfo = Helper_Class.getInfo();
      this.docid = this.userinfo.user_id;
      this.getPreviousPrescriptions();
    }

  }

  getPreviousPrescriptions() {
    this.listProducts = [];
    console.log("data this.docid--" + JSON.stringify(this.docid))
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'prescription/prevmp/', { doc_reg_id: this.docid },
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          console.log("data ret--" + JSON.stringify(obj))

          if (obj.drug_list.length != 0) {
            var rowcount = 1;

            for (var i = 0; i < obj.drug_list.length; i++) {
              if (obj.drug_list[i].show_short_form == "1") {
                var morning = " ", afternoon = " ", evening = " ", night = " ";
                var shortform = obj.drug_list[i].short_form;
                var short_form = obj.drug_list[i].short_form;
                if (obj.drug_list[i].intake == "1") {
                  this.param_four = false;
                  if (obj.drug_list[i].morning !== "0") {
                    morning = obj.drug_list[i].morning + " " + shortform + " - ";
                  } else {
                    morning = obj.drug_list[i].morning + " - ";
                  }
                  if (obj.drug_list[i].afternoon !== "0") {
                    afternoon = obj.drug_list[i].afternoon + " " + short_form + " - ";
                  } else {
                    afternoon = obj.drug_list[i].afternoon + " - ";
                  }
                  if (obj.drug_list[i].evening !== "0") {
                    evening = obj.drug_list[i].evening + " " + short_form + " - ";
                  } else {
                    evening = obj.drug_list[i].evening + " - ";
                  }
                  if (obj.drug_list[i].night !== "0") {
                    night = obj.drug_list[i].night + " " + short_form;
                  } else {
                    night = obj.drug_list[i].night;
                  }

                  this.every_six = morning + afternoon + evening + night;
                } else if (obj.drug_list[i].intake == "2") {
                  this.param_four = false;
                  if (obj.drug_list[i].morning !== "0") {
                    morning = obj.drug_list[i].morning + " " + shortform + " - ";
                  } else {
                    morning = obj.drug_list[i].morning + " - ";
                  }
                  if (obj.drug_list[i].afternoon !== "0") {
                    afternoon = obj.drug_list[i].afternoon + " " + short_form + " - ";
                  } else {
                    afternoon = obj.drug_list[i].afternoon + " - ";
                  }
                  if (obj.drug_list[i].evening !== "0") {
                    evening = obj.drug_list[i].evening + " " + short_form + " - ";
                  } else {
                    evening = obj.drug_list[i].evening + " - ";
                  }
                  if (obj.drug_list[i].night !== "0") {
                    night = obj.drug_list[i].night + " " + short_form;
                  } else {
                    night = obj.drug_list[i].night;
                  }

                  this.every_six = morning + afternoon + evening + night;
                } else if (obj.drug_list[i].intake == "3") {
                  this.param_four = false;
                  if (obj.drug_list[i].morning !== "0") {
                    morning = obj.drug_list[i].morning + " " + shortform + " - ";
                  } else {
                    morning = obj.drug_list[i].morning + " - ";
                  }
                  if (obj.drug_list[i].afternoon !== "0") {
                    afternoon = obj.drug_list[i].afternoon + " " + short_form + " - ";
                  } else {
                    afternoon = obj.drug_list[i].afternoon + " - ";
                  }
                  if (obj.drug_list[i].evening !== "0") {
                    evening = obj.drug_list[i].evening + " " + short_form + " - ";
                  } else {
                    evening = obj.drug_list[i].evening + " - ";
                  }
                  if (obj.drug_list[i].night !== "0") {
                    night = obj.drug_list[i].night + " " + short_form;
                  } else {
                    night = obj.drug_list[i].night;
                  }

                  this.every_six = morning + afternoon + evening + night;
                  // this.every_six = "0 - 0 - "+ obj.drug_list[i].evening + shortform  + obj.drug_list[i].night + shortform;

                } else if (obj.drug_list[i].intake == "4") {
                  if (obj.drug_list[i].morning !== "0") {
                    morning = obj.drug_list[i].morning + " " + shortform + " - ";
                  } else {
                    morning = obj.drug_list[i].morning + " - ";
                  }
                  if (obj.drug_list[i].afternoon !== "0") {
                    afternoon = obj.drug_list[i].afternoon + " " + short_form + " - ";
                  } else {
                    afternoon = obj.drug_list[i].afternoon + " - ";
                  }
                  if (obj.drug_list[i].evening !== "0") {
                    evening = obj.drug_list[i].evening + " " + short_form + " - ";
                  } else {
                    evening = obj.drug_list[i].evening + " - ";
                  }
                  if (obj.drug_list[i].night !== "0") {
                    night = obj.drug_list[i].night + " " + short_form;
                  } else {
                    night = obj.drug_list[i].night;
                  }

                  this.every_six = morning + afternoon + evening + night; this.param_four = false;

                } else if (obj.drug_list[i].intake == "5") {
                  if (obj.drug_list[i].morning !== "0") {
                    morning = obj.drug_list[i].morning + " " + shortform + " - ";
                  } else {
                    morning = obj.drug_list[i].morning + " - ";
                  }
                  if (obj.drug_list[i].afternoon !== "0") {
                    afternoon = obj.drug_list[i].afternoon + " " + short_form + " - ";
                  } else {
                    afternoon = obj.drug_list[i].afternoon + " - ";
                  }
                  if (obj.drug_list[i].evening !== "0") {
                    evening = obj.drug_list[i].evening + " " + short_form + " - ";
                  } else {
                    evening = obj.drug_list[i].evening + " - ";
                  }
                  if (obj.drug_list[i].night !== "0") {
                    night = obj.drug_list[i].night + " " + short_form;
                  } else {
                    night = obj.drug_list[i].night;
                  }

                  this.every_six = morning + afternoon + evening + night; this.param_four = false;

                } else if (obj.drug_list[i].intake == "6") {
                  if (obj.drug_list[i].morning !== "0") {
                    morning = obj.drug_list[i].morning + " " + shortform + " - ";
                  } else {
                    morning = obj.drug_list[i].morning + " - ";
                  }
                  if (obj.drug_list[i].afternoon !== "0") {
                    afternoon = obj.drug_list[i].afternoon + " " + short_form + " - ";
                  } else {
                    afternoon = obj.drug_list[i].afternoon + " - ";
                  }
                  if (obj.drug_list[i].evening !== "0") {
                    evening = obj.drug_list[i].evening + " " + short_form + " - ";
                  } else {
                    evening = obj.drug_list[i].evening + " - ";
                  }
                  if (obj.drug_list[i].night !== "0") {
                    night = obj.drug_list[i].night + " " + short_form;
                  } else {
                    night = obj.drug_list[i].night;
                  }

                  this.every_six = morning + afternoon + evening + night; this.param_four = false;

                } else if (obj.drug_list[i].intake == "7") {
                  this.param_four = false;
                  if (obj.drug_list[i].morning !== "0") {
                    morning = obj.drug_list[i].morning + " " + shortform + " - ";
                  } else {
                    morning = obj.drug_list[i].morning + " - ";
                  }
                  if (obj.drug_list[i].afternoon !== "0") {
                    afternoon = obj.drug_list[i].afternoon + " " + short_form + " - ";
                  } else {
                    afternoon = obj.drug_list[i].afternoon + " - ";
                  }
                  if (obj.drug_list[i].evening !== "0") {
                    evening = obj.drug_list[i].evening + " " + short_form + " - ";
                  } else {
                    evening = obj.drug_list[i].evening + " - ";
                  }
                  if (obj.drug_list[i].night !== "0") {
                    night = obj.drug_list[i].night + " " + short_form;
                  } else {
                    night = obj.drug_list[i].night;
                  }

                  this.every_six = morning + afternoon + evening + night;
                } else if (obj.drug_list[i].intake == "8") {
                  this.param_four = false;
                  if (obj.drug_list[i].morning !== "0") {
                    morning = obj.drug_list[i].morning + " " + shortform + " - ";
                  } else {
                    morning = obj.drug_list[i].morning + " - ";
                  }
                  if (obj.drug_list[i].afternoon !== "0") {
                    afternoon = obj.drug_list[i].afternoon + " " + short_form + " - ";
                  } else {
                    afternoon = obj.drug_list[i].afternoon + " - ";
                  }
                  if (obj.drug_list[i].evening !== "0") {
                    evening = obj.drug_list[i].evening + " " + short_form + " - ";
                  } else {
                    evening = obj.drug_list[i].evening + " - ";
                  }
                  if (obj.drug_list[i].night !== "0") {
                    night = obj.drug_list[i].night + " " + short_form;
                  } else {
                    night = obj.drug_list[i].night;
                  }

                  this.every_six = morning + afternoon + evening + night;
                } else if (obj.drug_list[i].intake == "9") {
                  this.param_four = true;
                  this.every_six = obj.drug_list[i].morning + shortform + " - Every 6 hours";

                } else if (obj.drug_list[i].intake == "10") {
                  this.param_four = true;
                  this.every_six = obj.drug_list[i].morning + shortform + " - Every 8 hours";

                } else if (obj.drug_list[i].intake == "11") {
                  this.param_four = true;
                  this.every_six = obj.drug_list[i].morning + shortform + " - Every 12 hours ";

                } else if (obj.drug_list[i].intake == "12") {
                  this.every_six = obj.drug_list[i].morning + shortform + " - Once a day";
                  this.param_four = true;

                } else if (obj.drug_list[i].intake == "13") {
                  this.every_six = obj.drug_list[i].morning + shortform + " SOS - if required";
                  this.param_four = true;

                } else if (obj.drug_list[i].intake == "14") {
                  this.param_four = false;
                  if (obj.drug_list[i].morning !== "0") {
                    morning = obj.drug_list[i].morning + " " + shortform + " - ";
                  } else {
                    morning = obj.drug_list[i].morning + " - ";
                  }
                  if (obj.drug_list[i].afternoon !== "0") {
                    afternoon = obj.drug_list[i].afternoon + " " + short_form + " - ";
                  } else {
                    afternoon = obj.drug_list[i].afternoon + " - ";
                  }
                  if (obj.drug_list[i].evening !== "0") {
                    evening = obj.drug_list[i].evening + " " + short_form + " - ";
                  } else {
                    evening = obj.drug_list[i].evening + " - ";
                  }
                  if (obj.drug_list[i].night !== "0") {
                    night = obj.drug_list[i].night + " " + short_form;
                  } else {
                    night = obj.drug_list[i].night;
                  }

                  this.every_six = morning + afternoon + evening + night;
                } else if (obj.drug_list[i].intake == "15") {
                  this.param_four = false;
                  if (obj.drug_list[i].morning !== "0") {
                    morning = obj.drug_list[i].morning + " " + shortform + " - ";
                  } else {
                    morning = obj.drug_list[i].morning + " - ";
                  }
                  if (obj.drug_list[i].afternoon !== "0") {
                    afternoon = obj.drug_list[i].afternoon + " " + short_form + " - ";
                  } else {
                    afternoon = obj.drug_list[i].afternoon + " - ";
                  }
                  if (obj.drug_list[i].evening !== "0") {
                    evening = obj.drug_list[i].evening + " " + short_form + " - ";
                  } else {
                    evening = obj.drug_list[i].evening + " - ";
                  }
                  if (obj.drug_list[i].night !== "0") {
                    night = obj.drug_list[i].night + " " + short_form;
                  } else {
                    night = obj.drug_list[i].night;
                  }

                  this.every_six = morning + afternoon + evening + night;
                } else if (obj.drug_list[i].intake == "16") {
                  this.param_four = false;
                  if (obj.drug_list[i].morning !== "0") {
                    morning = obj.drug_list[i].morning + " " + shortform + " - ";
                  } else {
                    morning = obj.drug_list[i].morning + " - ";
                  }
                  if (obj.drug_list[i].afternoon !== "0") {
                    afternoon = obj.drug_list[i].afternoon + " " + short_form + " - ";
                  } else {
                    afternoon = obj.drug_list[i].afternoon + " - ";
                  }
                  if (obj.drug_list[i].evening !== "0") {
                    evening = obj.drug_list[i].evening + " " + short_form + " - ";
                  } else {
                    evening = obj.drug_list[i].evening + " - ";
                  }
                  if (obj.drug_list[i].night !== "0") {
                    night = obj.drug_list[i].night + " " + short_form;
                  } else {
                    night = obj.drug_list[i].night;
                  }

                  this.every_six = morning + afternoon + evening + night;
                } else if (obj.drug_list[i].intake == "17") {
                  this.param_four = true;
                  this.every_six = obj.drug_list[i].morning + shortform + " STAT";
                }
                else if (obj.drug_list[i].intake == "18") {
                  this.param_four = true;
                  this.every_six = obj.drug_list[i].morning + shortform + "Once a month";
                }
                else if (obj.drug_list[i].intake == "19") {
                  this.param_four = false;
                  if (obj.drug_list[i].morning !== "0") {
                    morning = obj.drug_list[i].morning + " " + shortform + " - ";
                  } else {
                    morning = obj.drug_list[i].morning + " - ";
                  }
                  if (obj.drug_list[i].afternoon !== "0") {
                    afternoon = obj.drug_list[i].afternoon + " " + short_form + " - ";
                  } else {
                    afternoon = obj.drug_list[i].afternoon + " - ";
                  }
                  if (obj.drug_list[i].evening !== "0") {
                    evening = obj.drug_list[i].evening + " " + short_form + " - ";
                  } else {
                    evening = obj.drug_list[i].evening + " - ";
                  }
                  if (obj.drug_list[i].night !== "0") {
                    night = obj.drug_list[i].night + " " + short_form;
                  } else {
                    night = obj.drug_list[i].night;
                  }

                  this.every_six = morning + afternoon + evening + night;
                }
                else if (obj.drug_list[i].intake == "20") {
                  this.param_four = false;
                  if (obj.drug_list[i].morning !== "0") {
                    morning = obj.drug_list[i].morning + " " + shortform + " - ";
                  } else {
                    morning = obj.drug_list[i].morning + " - ";
                  }
                  if (obj.drug_list[i].afternoon !== "0") {
                    afternoon = obj.drug_list[i].afternoon + " " + short_form + " - ";
                  } else {
                    afternoon = obj.drug_list[i].afternoon + " - ";
                  }
                  if (obj.drug_list[i].evening !== "0") {
                    evening = obj.drug_list[i].evening + " " + short_form + " - ";
                  } else {
                    evening = obj.drug_list[i].evening + " - ";
                  }
                  if (obj.drug_list[i].night !== "0") {
                    night = obj.drug_list[i].night + " " + short_form;
                  } else {
                    night = obj.drug_list[i].night;
                  }

                  this.every_six = morning + afternoon + evening + night;
                }

                if (obj.drug_list[i].time_duration != undefined && obj.drug_list[i].time_duration != "") {
                  this.dure_write = obj.drug_list[i].time_duration + " " + "mins";
                } else {
                  this.dure_write = "";
                  this.dure_flag = true;
                }

                if (this.afterfood_txt == undefined) {
                  this.afterfood_txt = "";
                }
                var frequency;
                if (obj.drug_list[i].intake == "Alternative day" || obj.drug_list[i].intake == "Once a week" || obj.drug_list[i].intake == "Once in two weeks") {
                  frequency = this.intake_txt;
                } else {
                  if (obj.drug_list[i].days == "1" && obj.drug_list[i].day_dur == "day") {
                    frequency = "";
                  } else {
                    frequency = "Daily";
                  }
                }
                if (obj.drug_list[i].generic_name !== undefined) {
                  var genericname = obj.drug_list[i].generic_name;
                }
                var product: any = {
                  med_typetxt: obj.drug_list[i].drug_type_name,
                  drug_id: obj.drug_list[i].drug_id,
                  drug_name: obj.drug_list[i].drug_name,
                  // //genericname: this.ganericName,
                  genericname: genericname,
                  days: obj.drug_list[i].days,
                  period: obj.drug_list[i].day_dur,
                  drug_intake: obj.drug_list[i].drug_intake,
                  intake: obj.drug_list[i].intake,
                  every_six: this.every_six,
                  dure_txt_table: this.dure_write,
                  time_duration: obj.drug_list[i].time_duration,
                  // morning: obj.drug_list[i].morning,
                  // afternoon: obj.drug_list[i].afternoon,
                  // evening: obj.drug_list[i].evening,
                  morning: obj.drug_list[i].morning,
                  afternoon: obj.drug_list[i].afternoon,
                  evening: obj.drug_list[i].evening,
                  night: obj.drug_list[i].night,
                  morning1: obj.drug_list[i].morning !== "0" && shortform ? `${obj.drug_list[i].morning} ${shortform}` : obj.drug_list[i].morning,
                  afternoon1: obj.drug_list[i].afternoon !== "0" && shortform ? `${obj.drug_list[i].afternoon} ${shortform}` : obj.drug_list[i].afternoon,
                  evening1: obj.drug_list[i].evening !== "0" && shortform ? `${obj.drug_list[i].evening} ${shortform}` : obj.drug_list[i].evening,
                  night1: obj.drug_list[i].night !== "0" && shortform ? `${obj.drug_list[i].night} ${shortform}` : obj.drug_list[i].night,

                  // night: obj.drug_list[i].night,
                  drug_type_id: obj.drug_list[i].drug_type_id,
                  frequency: frequency,
                  show_intake: obj.drug_list[i].show_intake,
                  short_name: obj.drug_list[i].short_name,
                  mixval: obj.drug_list[i].mixtype,
                  param_four: this.param_four
                }


                if (obj.drug_list[i].generic_name !== undefined) {
                  product.genericname = obj.drug_list[i].generic_name;
                }
                this.listProducts.push(product);

              } else {
                if (obj.drug_list[i].intake_desc == "Every 6 hours") {
                  this.every_six = obj.drug_list[i].morning + " - " + obj.drug_list[i].morning + " - " + obj.drug_list[i].morning + " - " + obj.drug_list[i].morning;
                  this.param_four = true;
                } else if (obj.drug_list[i].intake_desc == "SOS") {
                  this.every_six = obj.drug_list[i].morning + " SOS - if required";
                  this.param_four = true;
                } else if (obj.drug_list[i].intake_desc == "Thrice a day") {
                  this.every_six = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon + " - " + obj.drug_list[i].evening + " - " + obj.drug_list[i].night;
                  this.param_four = false;
                } else if (obj.drug_list[i].intake_desc == "Four times a day") {
                  this.every_six = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon + " - " + obj.drug_list[i].evening + " - " + obj.drug_list[i].night;
                  this.param_four = false;
                } else if (obj.drug_list[i].intake_desc == "Once a week") {
                  this.every_six = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon + " - " + obj.drug_list[i].evening + " - " + obj.drug_list[i].night;
                  this.param_four = true;
                } else {
                  if (obj.drug_list[i].evening == 0 && obj.drug_list[i].night != 0) {
                    this.every_six = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon + " - " + obj.drug_list[i].evening + " - " + obj.drug_list[i].night;
                  } else if (obj.drug_list[i].evening != 0 && obj.drug_list[i].night == 0) {
                    this.every_six = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon + " - " + obj.drug_list[i].evening + " - " + obj.drug_list[i].night;
                  }
                  this.param_four = false;
                }

                if (obj.drug_list[i].time_duration != undefined && obj.drug_list[i].time_duration != "") {
                  this.dure_write = obj.drug_list[i].time_duration + " " + "mins";
                } else {
                  this.dure_write = "";
                  this.dure_flag = true;
                }

                if (this.afterfood_txt == undefined) {
                  this.afterfood_txt = "";
                }
                if (obj.drug_list[i].generic_name !== undefined) {
                  var genericname = obj.drug_list[i].generic_name;
                }
                var frequency;
                frequency = obj.drug_list[i].intake == "Alternative day" || obj.drug_list[i].intake == "Once a week" || obj.drug_list[i].intake == "Once in two weeks" ? this.intake_txt : "Daily";
                var product: any = {
                  med_typetxt: obj.drug_list[i].drug_type_name,
                  drug_id: obj.drug_list[i].drug_id,
                  genericname: genericname,
                  drug_name: obj.drug_list[i].drug_name,
                  days: obj.drug_list[i].days,
                  period: obj.drug_list[i].day_dur,
                  drug_intake: obj.drug_list[i].drug_intake,
                  intake: obj.drug_list[i].intake,
                  every_six: this.every_six,
                  dure_txt_table: this.dure_write,
                  time_duration: obj.drug_list[i].time_duration,
                  // evening: obj.drug_list[i].evening,
                  morning: obj.drug_list[i].morning,
                  afternoon: obj.drug_list[i].afternoon,
                  evening: obj.drug_list[i].evening,
                  night: obj.drug_list[i].night,
                  morning1: obj.drug_list[i].morning !== "0" && shortform ? `${obj.drug_list[i].morning} ${shortform}` : obj.drug_list[i].morning,
                  afternoon1: obj.drug_list[i].afternoon !== "0" && shortform ? `${obj.drug_list[i].afternoon} ${shortform}` : obj.drug_list[i].afternoon,
                  evening1: obj.drug_list[i].evening !== "0" && shortform ? `${obj.drug_list[i].evening} ${shortform}` : obj.drug_list[i].evening,
                  night1: obj.drug_list[i].night !== "0" && shortform ? `${obj.drug_list[i].night} ${shortform}` : obj.drug_list[i].night,
                  drug_type_id: obj.drug_list[i].drug_type_id,
                  frequency: frequency,
                  show_intake: obj.drug_list[i].show_intake,
                  remarks: obj.drug_list[i].remarks,
                  short_name: obj.drug_list[i].short_name,
                  param_four: this.param_four
                }
                if (obj.drug_list[i].generic_name !== undefined) {
                  product.genericname = obj.drug_list[i].generic_name;
                }
                this.listProducts.push(product);

              }
              rowcount++;
            }

          } else {
            this.nodataapp = false;
          }

        },
        error => {

        }

      )
  }

  selectMed(product, productid, rowid, ischecked) {
    if (product.checked == true) {
      if (this.selectedList.length != 0) {
        var flag = false;
        for (var j = 0; j < this.selectedList.length; j++) {
          if (this.selectedList[j].drug_id == product.drug_id) {
            flag = true;
          }
        }
        if (flag == true) {
          this.toastr.warning(Message_data.medAlreadySlt);
          this.selectedList.push(product);
        } else {
          this.selectedList.push(product);
        }

      } else {
        this.selectedList.push(product);
      }
    } else {
      for (var j = 0; j < this.selectedList.length; j++) {
        if (this.selectedList[j].drug_id == product.drug_id
          && this.selectedList[j].rowid == product.rowid) {
          this.selectedList.splice(j, 1);
        }
      }
    }
    if (this.selectedList.length != 0) {
      this.saveflag = false;
    }
    else {
      this.saveflag = true;
    }

  }

  addtoprescription() {

  }

  ClosePopup() {
    this.dialogRef.close();
  }
  //sort table
  sortTable(n) {
    var table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
    table = document.getElementById("tbl");
    switching = true;
    //Set the sorting direction to ascending:
    dir = "asc";
    /*Make a loop that will continue until
    no switching has been done:*/
    while (switching) {
      //start by saying: no switching is done:
      switching = false;
      rows = table.rows;
      /*Loop through all table rows (except the
      first, which contains table headers):*/
      for (i = 1; i < (rows.length - 1); i++) {
        //start by saying there should be no switching:
        shouldSwitch = false;
        /*Get the two elements you want to compare,
        one from current row and one from the next:*/
        x = rows[i].getElementsByTagName("TD")[n];
        y = rows[i + 1].getElementsByTagName("TD")[n];
        /*check if the two rows should switch place,
        based on the direction, asc or desc:*/
        if (dir == "asc") {
          if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
            //if so, mark as a switch and break the loop:
            shouldSwitch = true;
            break;
          }
        } else if (dir == "desc") {
          if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
            //if so, mark as a switch and break the loop:
            shouldSwitch = true;
            break;
          }
        }
      }
      if (shouldSwitch) {
        /*If a switch has been marked, make the switch
        and mark that a switch has been done:*/
        rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
        switching = true;
        //Each time a switch is done, increase this count by 1:
        switchcount++;
      } else {
        /*If no switching has been done AND the direction is "asc",
        set the direction to "desc" and run the while loop again.*/
        if (switchcount == 0 && dir == "asc") {
          dir = "desc";
          switching = true;
        }
      }
    }
  }

  //filter med name
  searchMedName() {

    var input, filter, table, tr, td, i, txtValue;
    input = document.getElementById("searchmed_name");
    filter = input.value.toUpperCase();
    table = document.getElementById("tbl");
    tr = table.getElementsByTagName("tr");
    for (i = 0; i < tr.length; i++) {
      td = tr[i].getElementsByTagName("td")[1];
      if (td) {
        txtValue = td.textContent || td.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          tr[i].style.display = "";
        } else {
          tr[i].style.display = "none";
        }
      }
    }
  }
}
