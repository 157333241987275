<div mat-dialog-title class="popup_border_icd">
    <h5 class="header_txt">History</h5>
    <div mat-dialog-actions>
        <img src="../../../assets/img/close_icon.png" (click)="dismiss()" width="20px" height="20px" id="close_icd" />
    </div>
</div>
<div mat-dialog-content class="diab_content">
    <div class="container">
        <div [hidden]="nodataapp" class="nodata col-12">No history found</div>
        <div class="row" style="max-height: 250px;">
            <div class="col-12 table-responsive" style="padding-top: 10px;padding-bottom: 10px;">
                <table id="tbl" [hidden]="medical_history_data.length==0">
                    <thead>
                        <tr>
                            <th>Occupation</th>
                            <th>Concerns</th>
                            <th>Medical history</th>
                            <th>Stress fact</th>
                            <th>BMI</th>
                            <th>Dietary habits</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let analysis of medical_history_data">
                            <td>{{analysis.current_occupation}}</td>
                            <td>{{analysis.main_concerns}}</td>
                            <td>{{analysis.medical_history}}</td>
                            <td>{{analysis.stress_factor}}</td>
                            <td>{{analysis.bmi}}</td>
                            <td>{{analysis.diet_habits}}</td>
                            <td class="diet_histtable_date_td">{{analysis.created_date | date:'dd/MM/yyyy'}}</td>
                        </tr>
                    </tbody>
                </table>
                <!-- Habitual  history -->
                <table id="tbl" [hidden]="habitual_history_data.length==0">
                    <thead>
                        <tr>
                            <th>Alchol frequency (per day)</th>
                            <th>Alchol quantity</th>
                            <th>Smoke frequency (per day)</th>
                            <th>Gutka frequency (per day)</th>
                            <th>Exercise duration</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let habitual of habitual_history_data">
                            <td>{{habitual.alc_frequency}}</td>
                            <td>{{habitual.alc_qty}}</td>
                            <td>{{habitual.smoke_frequency}}</td>
                            <td>{{habitual.gutka_frequency}}</td>
                            <td>{{habitual.curr_exer_dur}}</td>
                            <td class="diet_histtable_date_td">{{habitual.created_date | date:'dd/MM/yyyy'}}</td>
                        </tr>
                    </tbody>
                </table>

                <!-- //Family hsitory_pop -->
                <table id="tbl" [hidden]="family_history_data.length==0">
                    <thead>
                        <tr>
                            <th>Diabetes</th>
                            <th>Cardio</th>
                            <th>Endocrine</th>
                            <th>Hypertension</th>
                            <th>Hepatitis</th>
                            <th>HIV</th>
                            <th>Other disease</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let family of family_history_data">
                            <td>{{family.Diabetics}}</td>
                            <td>{{family.heart_attack}}</td>
                            <td>{{family.endocrine}}</td>
                            <td>{{family.hypertension}}</td>
                            <td>{{family.hepatitis}}</td>
                            <td>{{family.hiv}}</td>
                            <td>{{family.others}}</td>
                            <td class="diet_histtable_date_td">{{family.created_date | date:'dd/MM/yyyy'}}</td>
                        </tr>
                    </tbody>
                </table>

                <!-- //Fotasses ment -->
                <table id="tbl" [hidden]="foot_asses_arraydata.length==0">
                    <thead>
                        <tr>
                            <th>Foot type</th>
                            <th>Tibial artery</th>
                            <th>Pedis artery</th>
                            <th>Numbness</th>
                            <th>Deformity</th>
                            <th>Skin dry</th>
                            <th>Risk level</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let foot of foot_asses_arraydata">
                            <td>{{foot.foot_type}}</td>
                            <td>{{foot.limb_Tibia}}</td>
                            <td>{{foot.limb_pedis}}</td>
                            <td>{{foot.limb_numbness}}</td>
                            <td>{{foot.deformity}}</td>
                            <td>{{foot.skin_dry}}</td>
                            <td>{{foot.risk_level}}</td>
                            <td class="diet_histtable_date_td">{{foot.created_date | date:'dd/MM/yyyy'}}</td>
                        </tr>
                    </tbody>
                </table>

                <!-- //Complication -->
                <table id="tbl" [hidden]="complication_array_data.length==0">
                    <thead>
                        <tr>
                            <th>Cardiovascular</th>
                            <th>Neuropathy</th>
                            <th>Nephropathy</th>
                            <th>Retinopathy</th>
                            <th>Foot damage</th>
                            <th>Skin conditions</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let comp of complication_array_data">
                            <td>{{comp.cardio_dis}}</td>
                            <td>{{comp.neuro_dis}}</td>
                            <td>{{comp.nephro_dis}}</td>
                            <td>{{comp.retino_dis}}</td>
                            <td>{{comp.foot_dis}}</td>
                            <td>{{comp.skin_dis}}</td>
                            <td class="diet_histtable_date_td">{{comp.created_date | date:'dd/MM/yyyy'}}</td>
                        </tr>
                    </tbody>
                </table>

                <!-- //Diet plan -->
                <table id="tbl" ng-table="DietHist_Table" [hidden]="DietHist_Table_Data.length==0">
                    <thead>
                        <tr>
                            <th>Break fast</th>
                            <th>Morning</th>
                            <th>Mid-day</th>
                            <th>Lunch</th>
                            <th>Evening</th>
                            <th>Dinner1</th>
                            <th>Night</th>
                            <th>Date</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr *ngFor="let Diet of DietHist_Table_Data">
                            <td>{{Diet.break_fast}}</td>
                            <td>{{Diet.morning}}</td>
                            <td>{{Diet.midday}}</td>
                            <td>{{Diet.lunch}}</td>
                            <td>{{Diet.evening}}</td>
                            <td>{{Diet.dinner}}</td>
                            <td>{{Diet.night}}</td>
                            <td class="diet_histtable_date_td">{{Diet.created_date | date:'dd-MM-yyyy'}}</td>
                        </tr>
                    </tbody>
                </table>

                <!-- //Tracker history -->
                <table id="tbl" [hidden]="Trackhist_Table_Data.length==0">
                    <thead>
                        <tr>
                            <th>Break fast</th>
                            <th>Morning</th>
                            <th>Mid-day</th>
                            <th>Lunch</th>
                            <th>Evening</th>
                            <th>Dinner</th>
                            <th>Night</th>
                            <th>Date</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr *ngFor="let Tracker of Trackhist_Table_Data">
                            <td>{{Tracker.break_fast}}</td>
                            <td>{{Tracker.morning}}</td>
                            <td>{{Tracker.midday}}</td>
                            <td>{{Tracker.lunch}}</td>
                            <td>{{Tracker.evening}}</td>
                            <td>{{Tracker.dinner}}</td>
                            <td>{{Tracker.night}}</td>
                            <td class="diet_histtable_date_td">{{Tracker.created_date | date:'dd-MM-yyyy'}}</td>
                        </tr>
                    </tbody>
                </table>

                <!-- //Vital history -->
                <table id="tbl" [hidden]="Vital_history_data.length==0">
                    <thead>
                        <tr>
                            <th>Blood pressure</th>
                            <th>Respiratory rate</th>
                            <th>Date</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr *ngFor="let vital of Vital_history_data">
                            <td>{{vital.blood_pressure}}</td>
                            <td>{{vital.respiratory_rate}}</td>
                            <td class="diet_histtable_date_td">{{vital.created_date | date:'dd-MM-yyyy'}}</td>
                        </tr>
                    </tbody>
                </table>

                <!-- //head history -->
                <table id="tbl" [hidden]="head_history_array.length==0">
                    <thead>
                        <tr>
                            <th>Head</th>
                            <th>Vision</th>
                            <th>Hearing</th>
                            <th>Nose</th>
                            <th>Mouth throat</th>
                            <th>Head circumference</th>
                            <th>Date</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr *ngFor="let head of head_history_array">
                            <td>{{head.head}}</td>
                            <td>{{head.vision}}</td>
                            <td>{{head.hearing}}</td>
                            <td>{{head.nose}}</td>
                            <td>{{head.mouth_throat}}</td>
                            <td>{{head.head_circum}}</td>
                            <td>{{head.created_date | date:'dd/MM/yyyy'}}</td>
                        </tr>
                    </tbody>
                </table>

                <!-- Cardio history -->
                <table id="tbl" [hidden]="cardio_history_array.length==0">
                    <thead>
                        <tr>
                            <th>Chest</th>
                            <th>Abdominal examination</th>
                            <th>Genitalia</th>
                            <th>Extremities</th>
                            <th>Neurologic</th>
                            <th>General appearance</th>
                            <th>Skin</th>
                            <th>Date</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr *ngFor="let carddata of cardio_history_array">
                            <td>{{carddata.chest}}</td>
                            <td>{{carddata.admonial}}</td>
                            <td>{{carddata.genitalia}}</td>
                            <td>{{carddata.extermities}}</td>
                            <td>{{carddata.neurological}}</td>
                            <td>{{carddata.geneal_appearance}}</td>
                            <td>{{carddata.skin}}</td>
                            <td>{{carddata.created_date | date:'dd/MM/yyyy'}}</td>
                        </tr>
                    </tbody>
                </table>

                <!-- Menstrual history for gynacology-->
                <table id="tbl" [hidden]="mens_history_data.length==0" class="Grid">
                    <thead>
                        <tr>
                            <th>Stage</th>
                            <th>Stage date</th>
                            <th>Menstrual periods</th>
                            <th>Last menstrual period</th>
                            <th>Cycle length</th>
                            <th>Frequency</th>
                            <th>Menstrual pain</th>
                            <th>Timing of pain</th>
                            <th>Menorrhagia</th>
                            <th>Date</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr *ngFor="let gynamen of mens_history_data">
                            <td>{{gynamen.mens_stage}}</td>
                            <td>{{gynamen.stage_date | date:'dd-MM-yyyy'}}</td>
                            <td>{{gynamen.periods}}</td>
                            <td>{{gynamen.last_mens_period | date:'dd-MM-yyyy'}}</td>
                            <td>{{gynamen.cycle_length}}</td>
                            <td>{{gynamen.frequency}}</td>
                            <td>{{gynamen.mens_pain}}</td>
                            <td>{{gynamen.pain_timing}}</td>
                            <td>{{gynamen.menorragia}}</td>
                            <td class="diet_histtable_date_td">{{gynamen.created_date | date:'dd-MM-yyyy'}}</td>
                        </tr>
                    </tbody>
                </table>

                <!-- menstrual history for obestric -->
                <table id="tbl" [hidden]="mens_history_obstricdata.length==0" class="Grid">
                    <thead>
                        <tr>
                            <th>Menstrual periods</th>
                            <th>Last menstrual period</th>
                            <th>Cycle length</th>
                            <th>Frequency</th>
                            <th>EDD / REDD</th>
                            <th>Trimester</th>
                            <th>pop date</th>
                            <th>Result</th>
                            <th>Date</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr *ngFor="let obomen of mens_history_obstricdata">
                            <td>{{obomen.periods}}</td>
                            <td>{{obomen.last_mens_period}}</td>
                            <td>{{obomen.cycle_length}}</td>
                            <td>{{obomen.frequency}}</td>
                            <td>{{obomen.edd | date:'dd-MM-yyyy'}}</td>
                            <td>{{obomen.trimester}}</td>
                            <td>{{obomen.pap_smear_date}}</td>
                            <td>{{obomen.pap_smear_result}}</td>
                            <td class="diet_histtable_date_td">{{obomen.created_date | date:'dd-MM-yyyy'}}</td>
                        </tr>
                    </tbody>
                </table>

                <!-- sexual history for gynacology -->
                <table id="tbl" [hidden]="sexual_history_gynadata.length==0" class="Grid">
                    <thead>
                        <tr>
                            <th>Sexual status</th>
                            <th>Age</th>
                            <th>Type of sex</th>
                            <th>Difficulties during intercourse</th>
                            <th>Type of pain</th>
                            <th>Infections</th>
                            <th>Date</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr *ngFor="let sexgyna of sexual_history_gynadata">
                            <td>{{sexgyna.status}}</td>
                            <td>{{sexgyna.age_sex_active}}</td>
                            <td>{{sexgyna.sex_type}}</td>
                            <td>{{sexgyna.difficulties}}</td>
                            <td>{{sexgyna.pain_type}}</td>
                            <td>{{sexgyna.infections}}</td>
                            <td class="diet_histtable_date_td">{{sexgyna.created_date | date:'dd-MM-yyyy'}}</td>
                        </tr>
                    </tbody>
                </table>

                <!-- sexual history for obestric -->
                <table id="tbl" [hidden]="sexual_history_obostricdata.length==0" class="Grid">
                    <thead>
                        <tr>
                            <th>Sexual status</th>
                            <th>Difficulties during intercourse</th>
                            <th>Type of pain</th>
                            <th>Date</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr *ngFor="let sexob of sexual_history_obostricdata">
                            <td>{{sexob.status}}</td>
                            <td>{{sexob.difficulties}}</td>
                            <td>{{sexob.pain_type}}</td>
                            <td class="diet_histtable_date_td">{{sexob.created_date | date:'dd-MM-yyyy'}}</td>
                        </tr>
                    </tbody>
                </table>

                <!-- //clincal systemic history -->
                <table id="tbl" [hidden]="systemic_hist_data.length==0" class="Grid">
                    <thead>
                        <tr>
                            <th>Skin</th>
                            <th>Mouth</th>
                            <th>Nails</th>
                            <th>Heart rhythm</th>
                            <th>Anaemia</th>
                            <th>Mood swing</th>
                            <th>Date</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr *ngFor="let system of systemic_hist_data">
                            <td>{{system.skin}}</td>
                            <td>{{system.mouth}}</td>
                            <td>{{system.nails}}</td>
                            <td>{{system.heart_rhythm}}</td>
                            <td>{{system.anaemia}}</td>
                            <td>{{system.mood_swing}}</td>
                            <td class="diet_histtable_date_td">{{system.created_date | date:'dd-MM-yyyy'}}</td>
                        </tr>
                    </tbody>
                </table>

                <table id="tbl" [hidden]="ante_hist_data.length==0" class="Grid">
                    <thead>
                        <tr>
                            <th>Fetal movements</th>
                            <th>Fetus growth</th>
                            <th>Fetus lie</th>
                            <th>Delivery term</th>
                            <th>Date</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr *ngFor="let ante of ante_hist_data">
                            <td>{{ante.fetal_movement}}</td>
                            <td>{{ante.fetus_growth}}</td>
                            <td>{{ante.fetus_lie}}</td>
                            <td>{{ante.delivery_term}}</td>
                            <td class="diet_histtable_date_td">{{ante.created_date | date:'dd-MM-yyyy'}}</td>
                        </tr>
                    </tbody>
                </table>
                <table id="tbl" [hidden]="post_hist_data.length==0" class="Grid">
                    <thead>
                        <tr>
                            <th>Anaemic</th>
                            <th>Mood</th>
                            <th>Puerperium plan</th>
                            <th>Uterine involution</th>
                            <th>Date</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr *ngFor="let post of post_hist_data">
                            <td>{{post.anaemic}}</td>
                            <td>{{post.mood}}</td>
                            <td>{{post.puerperium}}</td>
                            <td>{{post.uterine_evolution}}</td>
                            <td class="diet_histtable_date_td">{{post.created_date | date:'dd-MM-yyyy'}}</td>
                        </tr>
                    </tbody>
                </table>

                <!--clinical pain history-->
                <table id="tbl" [hidden]="pain_table">
                    <thead>
                        <tr>
                            <th>Pain</th>
                            <th>Location</th>
                            <th>Character</th>
                            <th>Radiation</th>
                            <th>Aggrieved factor</th>
                            <th>Date</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr *ngFor="let painloc of pain_data">
                            <td>{{painloc.pain}}</td>
                            <td>{{painloc.location}}</td>
                            <td>{{painloc.character}}</td>
                            <td>{{painloc.radiation}}</td>
                            <td>{{painloc.aggr_factor}}</td>
                            <td class="diet_histtable_date_td">{{painloc.created_date | date:'dd/MM/yyyy'}}</td>
                        </tr>
                    </tbody>
                </table>

                <!-- walkin -->
                <table id="tbl" [hidden]="walkinHistoryList.length==0">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Complaints</th>
                            <th>Present illness</th>
                            <th>Medical History</th>
                            <th>Physical Exam</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr *ngFor="let analysis of walkinHistoryList">
                            <td>
                                <div class="matlabel" [innerHtml]="analysis.app_date"></div>
                            </td>
                            <td>
                                <div class="matlabel" [innerHtml]="analysis.complaints"></div>
                            </td>
                            <td>
                                <div class="matlabel" [innerHtml]="analysis.pres_illness"></div>
                            </td>
                            <td>
                                <div class="matlabel" [innerHtml]="analysis.medical_history"></div>
                            </td>
                            <td>
                                <div class="matlabel" [innerHtml]="analysis.physical_exam"></div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions align="end" style="width: auto;right: 19px;">
</div>