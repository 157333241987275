import { Component, OnInit, SimpleChanges, Input } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Helper_Class } from '../../helper_class';
import { ToastrService } from 'ngx-toastr';
import * as HighCharts from 'highcharts';
import { Date_Formate } from '../../../assets/js/common';
import { Chart } from 'chart.js';
import { Message_data } from '../../../assets/js/Message_data';
import { Doc_Helper } from '../../Doctor_module/Doc_Helper';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ipaddress } from '../../ipaddress';
import { Master_Helper } from '../../Doctor_module/Master_Helper';
import { MasterHelperService } from '../../Doctor_module/MasterHelper.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Diet_Helper } from '../../Diet_module/Diet_Helper';
import { MenuViewService } from '../../../app/common-module/menu-view/menu-view.service';
import { relative } from 'path';

@Component({
  selector: 'app-billing-home',
  templateUrl: './billing-home.component.html',
  styleUrls: ['./billing-home.component.scss']
})
export class BillingHomeComponent implements OnInit {
  public adminbased: boolean = false;
  public inpathospital_bills_count_graph:any=[];
  public inpathospital_bills_graph:any=[]
  public menu: boolean = false;
  public userinfo: any;
  public user_id: any;
  public first_name: string;
  public last_name: string;
  public opd = 77;
  public opdwidth;
  public opdbk;
  public operations = 50;
  public operationwidth;
  public operationbk;
  public patient_visit = 23;
  public patientwidth;
  public patientbk;
  public diagnosisdetails: any = [];
  public docdetails: any = [];
  public submenu_flag: boolean = false;
  public submenu_flag1: boolean = false;
  public profile_image: string;
  public datainfo: any = [];
  public dob: string;
  public obj: any;
  public mobile: any;
  public email: any;
  public gender: string;
  public urlt = ipaddress.getIp + 'usercontroller/providerdetails';
  public memberdetails = null;
  public pres_address1;
  public pres_address2;
  public pres_city;
  public pres_loc_id;
  public pres_country;
  public pres_location_txt;
  public pres_state;
  public pres_telephone;
  public pres_zipcode;
  public perm_address1;
  public perm_address2;
  public perm_city;
  public perm_loc_id;
  public perm_country;
  public perm_location_txt;
  public perm_state;
  public perm_telephone;
  public perm_zipcode;
  public pres_city_list_data;
  public pres_city_select;
  public filt_city_desc;
  public degree_name: any = [];
  public d_name: any = [];
  public qualification: any = [];
  public pres_state_list_data;
  public filt_state_desc;
  public pres_country_list_data;
  public country_id;
  public perm_city_list_data;
  public perm_state_list_data;
  public perm_country_list_data;
  public address: boolean;
  public addr: any;
  public addr1: any;
  public specializations: any = [];
  public spl_name: any = [];
  public social_media: boolean = false;
  public twitter;
  public fbook;
  public instagram;
  public hospital_logo;
  public hospital_name;
  public curr_date = null;
  public chart_type: string;
  public f_date;
  public t_date;
  public totalapp2: string;
  public totalapp3: string;
  public totalapp4: string;
  public totalapp1: string;
  public LocationCanvas: any;
  public specialization_canvas: any;
  public no_data: boolean;
  public no_grpah: boolean;
  public genmyChart: any;
  public AgeCanvas: any;
  public docCanvas: any;
  public SplCanvas: any;
  public age_graph: boolean;
  public Appointment_typebase: boolean;
  public totalapp;
  public pendingapp;
  public newpatientapp;
  public total_earnings;
  public appointment_type;
  public tdate;
  public fdate;
  public totalapp_00: string;
  public totalapp_02: string;
  public totalapp_01: string;
  public loc_flag: boolean;
  public gender_flag: boolean;
  public age_flag: boolean;
  public spec_flag: boolean;
  dtOptions: DataTables.Settings = {};
  public inpatientGrpah: any = [];
  public inpatientSpacility: any;
  public inSpacilityGraphData: any = [];
  public inWardGraphData: any = [];
  public wardSpacility: any;
  public inSplFlag: boolean = true;
  public inWardFlag: boolean = true;
  public inSpalDataArray: any = [];
  public inWardDataArray: any = [];
  public totalBeds;
  public availableBeds;
  public showDate: boolean = true;
  public type;
  public spl_display;
  public displayType;
  public followApp;
  public doctorList: any = [];
  public appointmentList: any = [];
  public is_admin: boolean = false;
  public doctorid: string;
  public hosp_id: string;
  public view_type: string;
  public viewflag: boolean = false;
  public currentDate: string;
  public doc_flag: boolean = false;
  public doc_graph: boolean = false;
  private admin: string;
  public hospital_bills: number;
  public diagnosis_bills: number;
  public pharma_bills: number;
  public hospitalidfromadmin;
  public tabtype;
  public inpathospital_bills: number;
  public inpatdiagnosis_bills: number;
  public inpatpharma_bills: number;
  public inpathospital_bills_count: number;
  public inpatdiagnosis_bills_count: number;
  public inpatpharma_bills_count: number;
  public hospital_bill_count: number;
  public diagnosis_bill_count: number;
  public pharma_bill_count: number;
  public wardSpacilityratio;
  public graphchart: boolean = false;
  public inpattabular: boolean = true;
  public wardarray: any = [];
  public specialityarray: any = [];
  percentage: number = 0;
  progress: number = 0;
  public total_bills: number = 7;
  public interval;
  public hsp_revenue;
  public diag_revenue;
  public pharma_revenue;
  public from_date;
  public to_date;
  current = 27;
  max = 50;
  stroke = 15;
  radius = 125;
  semicircle = false;
  rounded = false;
  responsive = false;
  clockwise = true;
  color = '#45ccce';
  background = '#eaeaea';
  duration = 800;
  animation = 'easeOutCubic';
  animationDelay = 0;
  public hospitalbillCanvas: any;
  public inpathospitalbillCanvas: any;
  public diagnosisCanvas: any;
  public pharmacyCanvas: any;
  public hospbillchart;
  public hospitalusername = [];
  public hospital_bill_amount = [];
  public diagnosisusername = [];
  public diag_bill_amount = [];
  public pharmausername = [];
  public pharma_bill_amount = [];
  public diag_bil_date=[];
  @Input() value: number = 0;
  public circumference: number = 2 * Math.PI * 47;
  public strokeDashoffset: number = 20;
  range: string;
  public inpatdiagnosisCanvas: any;
  public hospitalUserList = [];
  public diagnosisUserList = [];
  public hospitalUser_id = [];
  public diagnosis_bills_array =[];
  public diagnosisuser_id = [];
  public hospital_backgroundColor = [];
  public diag_backgroundColor = [];
  public noofinpat = 0;

  constructor(
    private http: HttpClient,
    public doctormessageservice: MenuViewService,
    private routes: ActivatedRoute,
    private router: Router,
    public toastr: ToastrService,
    public masterData: MasterHelperService
  ) {
    this.appointment_type = 'Weekly';
    this.tabtype = 'outpatient';
  }

  async ngOnInit() {
    this.from_date=this.curr_date;
    this.range = 'Weekly';
    this.userinfo = Helper_Class.getInfo();
    console.log(this.userinfo);
    console.log('GET_USER_INFO ' + JSON.stringify(this.userinfo));
    this.hospitalidfromadmin = this.userinfo.hospitals[0];
    var id = this.hospitalidfromadmin.hptl_clinic_id;
    console.log('id =' + id);
    console.log('_____________;;;' + this.hospitalidfromadmin);
    this.view_type = 'graph';
    this.hospital_name = this.userinfo.hptl_name;
    this.hospital_logo = ipaddress.Ip_with_img_address + this.userinfo.hptl_logo;
    this.hosp_id = this.userinfo.hptl_clinic_id;
    console.log('------------------->', this.hosp_id);
    this.interval = '0';
    this.admin = this.userinfo.admin; 
    this.doctorid = 'All';
    
    if (this.userinfo.admin == '1' || this.userinfo.user_type == 'Admin') {
      this.is_admin = true;
      this.user_id = 'All';
      this.getDoctors();
    } else {
      this.user_id = this.userinfo.user_id;  
      this.is_admin = true;
      this.getDoctors();
    }
    if (this.userinfo.user_type == 'Admin') {
      this.adminbased = true;
    } else {
      this.adminbased = false;
      this.adminbased = true;
    }
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 10,
      processing: true,
      order: [[1, 'desc']],
      columnDefs: [{ orderable: false, targets: [0] }],
      language: {
        search:
          "<img src='../../../assets/img/search.png' width='18px' style='display:inline-block;'/>",
        searchPlaceholder: 'Search by name',
      },
      dom: '<"row"<"col-sm-12 col-xs-12"f>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center"p>>',
    };
    this.getHospitalUsername();
    this.getDiagnosisUsername();
    if (this.userinfo.user_type == 'dietician') {
      this.type = 'dietician';
      this.spl_display = 'none';
      this.get_curr_date();
      this.displayType = 'Dashboard';
    } else {
      this.spl_display = 'block';
      this.type = 'doctor';
      this.displayType = 'Out patient';
      if (this.userinfo.specializations != undefined) {
        for (var j = 0; j < this.userinfo.specializations.length; j++) {
          this.specializations[j] = this.userinfo.specializations[j].spl_name;
        }
      }
      this.get_curr_date();
    }

    var seconds = 5;
    window.addEventListener('keyup', disableF5);
    window.addEventListener('keydown', disableF5);
    function disableF5(e) {
      if ((e.which || e.keyCode) == 116) e.preventDefault();
    }

    this.tdate = Date_Formate(this.curr_date);
    this.Appointment_type_change();
    this.animateProgress(75);
  }

  animateProgress(targetPercentage: number) {
    const animationDuration = 2000; // 2 seconds
    const step =
      (targetPercentage - this.percentage) / (animationDuration / 16);

    const updateProgress = () => {
      this.percentage += step;
      this.progress = (this.percentage / 100) * 360;

      if (this.percentage < targetPercentage) {
        requestAnimationFrame(updateProgress);
      }
    };

    updateProgress();
  }

  public reportType;
  public displayPage;
  hospitalbill(page) {
    let page_name: string = page;
    let pagetrue = 'doctorbill';
    Helper_Class.setbillpagetrue(pagetrue);
    this.reportType = Helper_Class.setReportType(page_name);
    Helper_Class.sethspidfrmadm(this.hospitalidfromadmin);
    this.doctormessageservice.sendMessage('hosp_bill_reports');
  }

  diagnosisbill(page) {
    let page_name: string = page;
    let pagetrue = 'diagnosisbill';
    Helper_Class.setbillpagetrue(pagetrue);
    this.reportType = Helper_Class.setReportType(page_name);
    Helper_Class.sethspidfrmadm(this.hospitalidfromadmin);
    this.doctormessageservice.sendMessage('diag_bill_reports');
  }

  pharmacybill(page) {
    let page_name: string = page;
    let pagetrue = 'pharmacybill';
    Helper_Class.setbillpagetrue(pagetrue);
    this.reportType = Helper_Class.setReportType(page_name);
    Helper_Class.sethspidfrmadm(this.hospitalidfromadmin);
    this.doctormessageservice.sendMessage('Sales_report_pharma');
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['value']) {
      this.onPercentageChanged(changes['value'].currentValue);
    }
  }

  onPercentageChanged(val: number) {
    const offset = this.circumference - (val / 100) * this.circumference;
    this.strokeDashoffset = offset;
  }

  getStyle(): object {
    if (this.is_admin) {
      return { width: '75%', position: 'relative', left: '20px' };
    } else {
      return { width: '100%' };
    }
  }

  getinpatStyle(): object {
    if (this.is_admin) {
      return { width: '100%' };
    } else {
      return { width: '100%' };
    }
  }

  getlocation(): object {
    if (!this.is_admin) {
      return { width: '270px !important' };
    } else {
      return { width: '100%' };
    }
  }
  getspecilization(): object {
    if (!this.is_admin) {
      return { width: '270px !important' };
    } else {
      return { width: '100%' };
    }
  }
  profileInfo() {
    this.obj = Master_Helper.getMasterDoctorProfileInfo();
    if (this.obj != null) {
      this.first_name = this.obj.first_name;
      this.last_name = this.obj.last_name;
      Helper_Class.setProfilename(this.first_name + ' ' + this.last_name);
      this.dob = this.obj.dob;
      this.mobile = encrypt_decript.Decript(this.obj.mobile);
      this.email = encrypt_decript.Decript(this.obj.email);
      this.gender = this.obj.gender;
      this.profile_image =
        ipaddress.Ip_with_img_address + this.obj.profile_image;
      if (this.obj.home_address != null) {
        if (this.obj.home_address.length != 0) {
          for (var l = 0; l < this.obj.home_address.length; l++) {
            if (this.obj.home_address[l].type == 'pres') {
              this.pres_address1 = this.obj.home_address[l].address1;
              this.pres_address2 = this.obj.home_address[l].address2;
              this.pres_city = this.obj.home_address[l].city_desc;
              this.pres_loc_id = this.obj.home_address[l].location;
              this.pres_country = this.obj.home_address[l].country_desc;
              this.pres_location_txt = this.obj.home_address[l].location_desc;
              this.pres_state = this.obj.home_address[l].state_desc;
              this.pres_telephone = this.obj.home_address[l].telephone;
              this.pres_zipcode = this.obj.home_address[l].zipcode;
              this.addr =
                this.pres_address1 +
                this.pres_address2 +
                ', ' +
                this.pres_city +
                ', ' +
                this.pres_state +
                ', ' +
                this.pres_country +
                ', ' +
                this.pres_zipcode;
              this.addr1 = this.pres_city + ',' + this.pres_state;
              var city = this.pres_city;
              var ctry = this.pres_country;
              this.address = true;
            } else {
              this.perm_address1 = this.obj.home_address[l].address1;
              this.perm_address2 = this.obj.home_address[l].address2;
              this.perm_city = this.obj.home_address[l].city_desc;
              this.perm_loc_id = this.obj.home_address[l].location;
              this.perm_country = this.obj.home_address[l].country_desc;
              this.perm_location_txt = this.obj.home_address[l].location_desc;
              this.perm_state = this.obj.home_address[l].state_desc;
              this.perm_telephone = this.obj.home_address[l].telephone;
              this.perm_zipcode = this.obj.home_address[l].zipcode;
              this.addr =
                this.perm_address1 +
                this.perm_address2 +
                ', ' +
                this.perm_city +
                ', ' +
                this.perm_state +
                ', ' +
                this.perm_country +
                ', ' +
                this.perm_zipcode;
              this.addr1 = this.perm_city + ',' + this.perm_state;
              this.address = false;
              city = this.perm_city;
              ctry = this.perm_country;
            }
          }

          var addr = {
            address_data: this.addr,
            city_zip: city,
            state_country: ctry,
          };
          Doc_Helper.setDoctorAddr(addr);
        }
      }
      this.spl_name = this.specializations;
      if (this.obj.qualification != null) {
        for (var i = 0; i < this.obj.qualification.length; i++) {
          this.qualification.push({
            id: this.obj.qualification[i].qualification_id,
            institute: this.obj.qualification[i].institute,
            university: this.obj.qualification[i].university,
            degree_name: this.obj.qualification[i].degree_name,
            graduation_year: this.obj.qualification[i].graduation_year,
          });
          this.degree_name[i] = this.obj.qualification[i].degree_name;
        }
      }
      this.d_name = this.degree_name;
      Helper_Class.setQualification(this.d_name);
      if (this.opd! == null) {
        this.opdwidth = this.opd + '%';
      }
      if (this.operations != null) {
        this.operationwidth = this.operations + '%';
      }
      if (this.patient_visit != null) {
        this.patientwidth = this.patient_visit + '%';
      }

      this.diagnosisdetails.push({
        name: this.first_name + ' ' + this.last_name,
        dob: this.dob,
        mobile: this.mobile,
        email: this.email,
        gender: this.gender,
        profile_image: this.profile_image,
        address: this.addr,
        degree_name: this.d_name,
      });
      Helper_Class.setDiagProfilename(this.diagnosisdetails);
    }
  }

  selectTab(event: MatTabChangeEvent) {
    const tab = event.tab.textLabel;
    if (tab == 'inpatient') {
      this.tabtype = 'inpatient';
      this.getInpatient();
      this.viewChange();
      this.showDate = false;
    } else {
      this.tabtype = 'outpatient';
      this.showDate = true;
      this.viewChange();
      this.Appointment_type_change();
      this.apptypegraphView();
      this.speciality_chart();
      this.location_chart();
      this.gender_base();
      this.user_base()
      this.age_base();
      this.doctorWise();
      this.patient_base();
      if (this.view_type == 'tabular') {
        this.getAppointments();
      }
    }
  }

  async get_curr_date() {
    if (this.tabtype == 'outpatient') {
      if (Master_Helper.getMasterCurrentDate() == undefined) {
        await this.masterData.getCurrentDate();
      }
      var obj = Master_Helper.getMasterCurrentDate();
      if (obj != undefined) {
        this.currentDate = obj.current_date;
        this.curr_date = obj.current_date;
        this.from_date = obj.current_date;
        this.to_date = obj.current_date;
        this.f_date = Date_Formate(this.curr_date);
        this.t_date = Date_Formate(this.curr_date);
        this.chart_type = 'Pie';

        this.gender_base();
        this.user_base();
        this.age_base();
        this.speciality_chart();
        this.location_chart();
        this.apptypegraphView();
        this.get_earnings();
        this.doctorWise();
        this.patient_base();
      }
    } else {
      if (Master_Helper.getMasterCurrentDate() == undefined) {
        await this.masterData.getCurrentDate();
      }
      var obj = Master_Helper.getMasterCurrentDate();
      if (obj != undefined) {
        this.curr_date = obj.current_date;
        this.currentDate = obj.current_date;
        this.f_date = Date_Formate(this.curr_date);
        this.t_date = Date_Formate(this.curr_date);
        this.getInpatient();
      }
    }
  }


  getHospitalUsername() {  
    var senddata = {
      hptl_clinic_id: this.hosp_id,
      type: 'hospital'
    }   
    console.log(senddata);
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'misr/grbh', senddata,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log("hospital user name--", obj);
          if (obj.reps != null) {
            this.hospitalUserList = [];
            for (var i = 0; i < obj.reps.length; i++) {
              var user_name;
              if (obj.reps[i].middle_name != undefined || obj.reps[i].middle_name != null) {
                user_name = obj.reps[i].first_name + " " + obj.reps[i].middle_name + " " + obj.reps[i].last_name;
              } else {
                user_name = obj.reps[i].first_name + " " + obj.reps[i].last_name;
              }

              this.hospitalUserList.push({
                id: obj.reps[i].user_id,
                name: user_name,
              })

              console.log('...........', this.hospitalUserList);
            }
          }
        }, error => {
          this.toastr.error(Message_data.unableToFetchDocDet);
        });
  }

  getDiagnosisUsername() {  
    var senddata = {
      hptl_clinic_id: this.hosp_id,
      type: 'diagnosis'
    }   
    console.log(senddata);
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'misr/grbh', senddata,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log("dianosis user name--", obj);
          if (obj.reps != null) {
            this.diagnosisUserList = [];
            for (var i = 0; i < obj.reps.length; i++) {
              var user_name;
              if (obj.reps[i].middle_name != undefined || obj.reps[i].middle_name != null) {
                user_name = obj.reps[i].first_name + " " + obj.reps[i].middle_name + " " + obj.reps[i].last_name;
              } else {
                user_name = obj.reps[i].first_name + " " + obj.reps[i].last_name;
              }

              this.diagnosisUserList.push({
                id: obj.reps[i].user_id,
                name: user_name,
              })

              console.log('...........', this.diagnosisUserList);
            }
          }
        }, error => {
          this.toastr.error(Message_data.unableToFetchDocDet);
        });
  }

  getInpatient1() {
    var data;
    var regid;
    if (this.user_id != 'All') {
      regid = this.user_id
    }
    if (this.userinfo.user_type == 'Admin') {
      data = {
        hptl_clinic_id: this.hosp_id,
        from_date: Date_Formate(this.f_date),
        to_date: Date_Formate(this.t_date),
        admin: "1",
        pat_type: 'inpat',
      };
    } else {
      data = {
        hptl_clinic_id: this.hosp_id,
        reg_id: regid,
        from_date: Date_Formate(this.f_date),
        to_date: Date_Formate(this.t_date),
        admin: this.admin,
        pat_type: 'inpat',
      };
    }
    console.log(JSON.stringify(data));

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(
        ipaddress.getIp.toString() + 'inpat/inprep/',
        data,
        { headers: headers }
      )
      .subscribe(
        (response) => {
          var obj = JSON.parse(JSON.stringify(response));

          console.log('getinpatient' + JSON.stringify(obj));

          this.inpatientGrpah = obj;
    //       let inpathostpitalbill = parseFloat(obj.hospital_bills); 
    //       let inpathospitalcount = parseInt(obj.hosp_bill_count);
    //       if (!isNaN(inpathostpitalbill)) {
    //         this.inpathospital_bills_graph=[];
    //         let fixedAmount = inpathostpitalbill.toFixed(2); 
    //         obj.hospital_bills = fixedAmount.toString();
    //         this.inpathospital_bills = obj.hospital_bills;
    //         this.inpathospital_bills_graph.push(obj.hospital_bills)
    //       }

    //       if (!isNaN(inpathospitalcount)) {
    //         this.inpathospital_bills_count = parseInt(obj.hosp_bill_count);
    //         this.inpathospital_bills_count_graph=parseInt(obj.hosp_bill_count);
    //       }
    //       console.log('inpathospital_bills_count',this.inpathospital_bills_count,'inpathospital_bills',this.inpathospital_bills);
    //       setTimeout(() => {
    //         var inpathospital_bills_count_graph = ["Nirmal S","Prema S","Malathi R"];
    //         var inpathospital_bills_graph = ["20000","40000","60000"];
    //         this.inpatHospital_bills(inpathospital_bills_count_graph,inpathospital_bills_graph);
    //       }, 500);
        

    //       let inpatdiagnosisbill = parseFloat(obj.diagnosis_bills);
    //       let inpatdiagnosisbillcount = parseInt(obj.diag_bill_count);
    //       if (!isNaN(inpatdiagnosisbill)) {
    //         let fixedAmount = inpatdiagnosisbill.toFixed(2);
    //         obj.diagnosis_bills = fixedAmount.toString();
    //         this.inpatdiagnosis_bills = obj.diagnosis_bills;
    //       }

    //       if (!isNaN(inpatdiagnosisbillcount)) {
    //         this.inpatdiagnosis_bills_count = parseInt(obj.diag_bill_count);
    //       }

    //       console.log('inpatdiagnosisbillcount',this.inpatdiagnosis_bills_count,'inpatdiagnosis_bills',this.inpatdiagnosis_bills);
    //       setTimeout(() => {
    //         var inpatdiagnosis_bills_count = ["20-04-2024","21-04-2024","22-04-2024","23-04-2024","24-04-2024"];
    //         var inpatdiagnosis_bills = ["10000","20000","30000","40000"];
    //         this.inpatDiagnosis_bills(inpatdiagnosis_bills_count,inpatdiagnosis_bills);
    //       }, 500);


    //       let inpatpharmabill = parseFloat(obj.pharma_bills);
    //       let inpatpharmabillcount = parseInt(obj.pharma_bill_count);
    //       if (!isNaN(inpatpharmabill)) {
    //         let fixedAmount = inpatpharmabill.toFixed(2);
    //         obj.pharma_bills = fixedAmount.toString();
    //         this.inpatpharma_bills = obj.pharma_bills;
    //       }
    //       if (!isNaN(inpatpharmabillcount)) {
    //         this.inpatpharma_bills_count = parseInt(obj.pharma_bill_count);
    //       }

    //       console.log('JJ' + JSON.stringify(this.inpatientGrpah));
    this.inSpacilityGraphData = this.inpatientGrpah.speciality_wise;
          this.inWardGraphData = this.inpatientGrpah.ward_wise;
    //       console.log(
    //         'inSpacility GraphData' + JSON.stringify(this.inSpacilityGraphData)
    //       );
    //       console.log(
    //         'inWardGraphData GraphData' + JSON.stringify(this.inWardGraphData)
    //       );

          this.inpatientSpecialityChart();
          this.inpatientWard();
          this.inpatientocuupationratio();
        },
        (error) => { }
      );
  }
  getInpatient() {
    this.getInpatient1();
    if (this.userinfo.admin == '1' || this.userinfo.user_type == 'Admin') {  
      this.user_id = 'All';     
    } else {
      this.user_id = this.userinfo.user_id;
    }
    this.hospital_bills = 0;
    this.diagnosis_bills = 0;
    this.pharma_bills = 0;
    var name = Array();
    var count = Array();
    var dataArrayFinal = Array();
    var dataArrayFinal1 = new Array();
    var send_data: any;
    send_data = {
      reg_id: this.user_id,
      fdate: Date_Formate(this.f_date),
      tdate: Date_Formate(this.t_date),
      hosp_id: this.hosp_id,
      range: this.range,
      createdby: this.user_id
    };

    console.log('user based  send_data ' + JSON.stringify(send_data));
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(ipaddress.getIp.toString() + 'dash/grbhd', send_data, {
        headers: headers,
      })
      .subscribe(
        (response) => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log('user based  GRAPH ' + JSON.stringify(obj));
          console.log('user based  GRAPH ' + obj);
          if(this.is_admin == true){
            this.user_based_inpatient_bills_chart(obj);
          }
        },
        (error) => { }
      );
  }

  user_based_inpatient_bills_chart(data) {
    if (this.interval == 0) {
      this.hospitalusername = [];
      this.hospitalUser_id = [];
      this.hospital_bill_amount = [];
      this.diagnosisusername = [];
      this.diag_bill_amount = [];
      this.pharmausername = [];
      this.pharma_bill_amount = [];    
      this.pharma_bill_count = 0  
      this.inpathospital_bills = 0;
      this.inpathospital_bills_count = 0;
      this.inpatdiagnosis_bills = 0;
      this.inpatdiagnosis_bills_count = 0;
      this.pharma_bills = 0;
      this.hospital_backgroundColor = [];
      this.diag_backgroundColor = [];

      if(data.hosp_data != undefined){
        for (var h = 0; h < data.hospital_bills.length; h++) {                
          this.inpathospital_bills += parseFloat(data.hospital_bills[h].ip_bill_amount);
          this.inpathospital_bills_count += parseInt(data.hospital_bills[h].ip_bill_count);           
        }
        console.log("inpathospital_bills_count",this.inpathospital_bills_count);                       
        console.log("inpathospital_bills_amount",this.inpathospital_bills);

        var backgroundColors = ['rgb(255, 99, 132)',
                                'rgb(75, 192, 192)',
                                'rgb(255, 205, 86)',
                                'rgb(201, 203, 207)',
                                'rgb(54, 162, 235)'];

      
        for(var i = 0; i < data.hosp_data.length; i++){
          this.hospitalusername.push(
            data.hosp_data[i].created_by
          )
          this.hospital_bill_amount.push(
            this.sumArray(data.hosp_data[i].ip_bill_amount)
          )
          this.hospital_backgroundColor.push(
            backgroundColors[i]
          )         
        }
      
        if (this.inpathospitalbillCanvas) {
          this.inpathospitalbillCanvas.destroy();
        }
        
        var hspbill = <HTMLCanvasElement>(
          document.getElementById('inpathospitalbillCanvas')
        );
        hspbill.height = 175;
        hspbill.width = 361;
        this.inpathospitalbillCanvas = new Chart(hspbill, {
          type: 'bar',
          data: {
            labels: this.hospitalusername,
            datasets: [{
              data: this.hospital_bill_amount,
              backgroundColor: this.hospital_backgroundColor,
              fill: false,
              borderWidth: 2,
              spanGaps: true,
            }]
          },
          options: {
            responsive: false,
            scales: {
              yAxes: [{
                display: true,
                ticks: {
                  beginAtZero: true,
                  steps: 100,
                  stepValue: 5,
                },
                scaleLabel: {
                  display: true,
                  labelString: 'Bill Amount'
                }
              }],
              xAxes: [{
                barPercentage: 1,
                barThickness: 35,
                ticks: {
                  maxRotation: 0,
                  minRotation: 0,
                },
                scaleLabel: {
                  display: true,
                  labelString: 'Users'
                }
              }]
            },
            legend: {
              display: false
            },
          }
        })            
      }   
     
      if (data.diagnosis_bills != undefined) {
        for (var d = 0; d < data.diagnosis_bills.length; d++) {
          if (data.diagnosis_bills[d].created_by != "null null") {
            if(data.diagnosis_bills[d].ip_bill_amount != undefined) {
              this.inpatdiagnosis_bills  += parseInt(data.diagnosis_bills[d].ip_bill_amount);
            }        
            this.inpatdiagnosis_bills_count += parseInt(data.diagnosis_bills[d].ip_bill_count);             
          }
        } 
        console.log("inpatdiagnosis_bills_count",this.inpatdiagnosis_bills_count);                       
        console.log("inpatdiagnosis_bills_amount",this.inpatdiagnosis_bills);

        var backgroundColors = ['rgb(255, 99, 132)',
                                'rgb(75, 192, 192)',
                                'rgb(255, 205, 86)',
                                'rgb(201, 203, 207)',
                                'rgb(54, 162, 235)'];
        
        for(var i = 0; i < data.diag_data.length; i++){
          this.diagnosisusername.push(
            data.diag_data[i].created_by
          )
          this.diag_bill_amount.push(
            this.sumArray(data.diag_data[i].ip_bill_amount)
          )
          this.diag_backgroundColor.push(
            backgroundColors[i]
          )         
        }
      
        if (this.inpatdiagnosisCanvas) {
          this.inpatdiagnosisCanvas.destroy();
        }
        
        var hspbill = <HTMLCanvasElement>(
          document.getElementById('inpatdiagnosisCanvas')
        );
        hspbill.height = 175;
        hspbill.width = 361;
        this.inpatdiagnosisCanvas = new Chart(hspbill, {
          type: 'bar',
          data: {
            labels: this.diagnosisusername,
            datasets: [{
              data: this.diag_bill_amount,
              backgroundColor: this.diag_backgroundColor,
              fill: false,
              borderWidth: 2,
              spanGaps: true,
            }]
          },
          options: {
            responsive: false,
            scales: {
              yAxes: [{
                display: true,
                ticks: {
                  beginAtZero: true,
                  steps: 100,
                  stepValue: 5,
                },
                scaleLabel: {
                  display: true,
                  labelString: 'Bill Amount'
                }
              }],
              xAxes: [{
                barPercentage: 1,
                barThickness: 35,
                ticks: {
                  maxRotation: 0,
                  minRotation: 0,
                },
                scaleLabel: {
                  display: true,
                  labelString: 'Users'
                }
              }]
            },
            legend: {
              display: false
            },
          }
        })         
      } 
    } else if (this.interval != 0) {
      //hospital
      this.hospitalusername = [];
      this.hospitalUser_id = [];
      this.hospital_bill_amount = [];
      this.diagnosisusername = [];
      this.diag_bill_amount = [];
      this.diagnosis_bills_array = [];
      this.diagnosisuser_id = [];
      this.pharmausername = [];
      this.pharma_bill_amount = [];
      this.diag_bil_date=[];
      this.inpathospital_bills = 0;
      this.inpatdiagnosis_bills = 0;
      this.pharma_bills = 0;
      this.inpatdiagnosis_bills_count = 0;
      this.pharma_bill_count = 0;
      this.inpathospital_bills_count = 0;

      if (data.hospital_bills != undefined) {
        setTimeout(()=>{
          this.getInpatHospitalBillDetails(data.hospital_bills,data.hosp_data,data.hosp_dates);
        },500);       
        for (var h = 0; h < data.hospital_bills.length; h++) {                 
          this.inpathospital_bills += parseFloat(data.hospital_bills[h].ip_bill_amount);
          this.inpathospital_bills_count += parseInt(data.hospital_bills[h].ip_bill_count);  
        }
        console.log("inpathospital_bills_amount",this.inpathospital_bills);                       
        console.log("inpathospital_bills_count",this.inpathospital_bills_count);
      }
      
      if (data.diagnosis_bills != undefined) {
        setTimeout(()=>{
          this.getInpatDiagnosisBillDetails(data.diagnosis_bills,data.diag_data,data.diag_dates);
        },500);       
        for (var d = 0; d < data.diagnosis_bills.length; d++) {
          if (data.diagnosis_bills[d].created_by != "null null") {
            if (data.diagnosis_bills[d].ip_bill_amount != undefined) {
              this.inpatdiagnosis_bills  += parseInt(data.diagnosis_bills[d].ip_bill_amount);
            }        
            this.inpatdiagnosis_bills_count += parseInt(data.diagnosis_bills[d].ip_bill_count);             
          }
        } 
        console.log("inpatdiagnosis_bills_count",this.inpatdiagnosis_bills_count);                       
        console.log("inpatdiagnosis_bills_amount",this.inpatdiagnosis_bills);
      }
    }
  }

  getInpatHospitalBillDetails(hospital_bills,hosp_data,hosp_dates) {
    var backgroundColors = ['rgb(255, 99, 132)',
    'rgb(75, 192, 192)',
    'rgb(255, 205, 86)',
    'rgb(201, 203, 207)',
    'rgb(54, 162, 235)']
    console.log("getHospitalBillDetails------", hospital_bills, hosp_data, hosp_dates);
    var hospital_dates = [];
    for (var i = 0;i < hosp_dates.length; i++) {
      hospital_dates.push(
        Date_Formate(hosp_dates[i])
      )
    }
    console.log(hospital_dates);

    var dataset_array = [];
    for(var i = 0; i < hosp_data.length; i++){
      dataset_array.push({
        data: hosp_data[i].ip_bill_amount,
        fill: false,
        backgroundColor: backgroundColors[i],
        borderColor: backgroundColors[i],
        spanGaps: true,
        label: hosp_data[i].created_by
      })      
    }
    console.log("Datasetarray------",dataset_array);
    
    if (this.inpathospitalbillCanvas) {
      this.inpathospitalbillCanvas.destroy();
    }
    
    var ctx = <HTMLCanvasElement>document.getElementById("inpathospitalbillCanvas"); 
    ctx.height = 175;
    ctx.width = 361;
    this.inpathospitalbillCanvas = new Chart(ctx, {
      type: "line",
      labels: ["Stepcount"],
      data: {
        labels: hospital_dates,
        datasets: dataset_array,
      },
      options: {
        responsive: false,
        scales: {
          yAxes: [{
            display: true,
            ticks: {
              beginAtZero: true,
              steps: 100,
              stepValue: 5,
            },
            scaleLabel: {
              display: true,
              labelString: 'Bill Amount'
            }
          }],
          xAxes: [{
            barPercentage: 1,
            barThickness: 35,
            ticks: {
              maxRotation: 0,
              minRotation: 0,
            },
            scaleLabel: {
              display: false,
              labelString: 'Bill Date'
            }
          }]
        },
        legend: {
          display: true
        },
      }
    });
  }
 
  getInpatDiagnosisBillDetails(diagnosis_bills,diag_data,diag_dates) {
    var backgroundColors = ['rgb(255, 99, 132)',
    'rgb(75, 192, 192)',
    'rgb(255, 205, 86)',
    'rgb(201, 203, 207)',
    'rgb(54, 162, 235)'];
    console.log("getDiagnosisBillDetails------", diagnosis_bills, diag_data, diag_dates);
    var diagnosis_dates = [];
    for (var i = 0;i < diag_dates.length; i++) {
      diagnosis_dates.push(
        Date_Formate(diag_dates[i])
      )
    }
    console.log(diagnosis_dates);

    var dataset_array = [];
    for(var i = 0; i < diag_data.length; i++){
      if (diag_data[i].created_by == "null null") {
        diag_data[i].created_by = "Sudharsan";
      }
      dataset_array.push({
        data: diag_data[i].ip_bill_amount,
        fill: false,
        backgroundColor: backgroundColors[i],
        borderColor: backgroundColors[i],
        spanGaps: true,
        label: diag_data[i].created_by
      })      
    }
    console.log("Datasetarray------",dataset_array);
    
    if (this.inpatdiagnosisCanvas) {
      this.inpatdiagnosisCanvas.destroy();
    }
    
    var ctx = <HTMLCanvasElement>document.getElementById("inpatdiagnosisCanvas"); 
    ctx.height = 175;
    ctx.width = 361;
    this.diagnosisCanvas = new Chart(ctx, {
      type: "line",
      labels: ["Stepcount"],
      data: {
        labels: diagnosis_dates,
        datasets: dataset_array,
      },
      options: {
        responsive: false,
        scales: {
          yAxes: [{
            display: true,
            ticks: {
              beginAtZero: true,
              steps: 100,
              stepValue: 5,
            },
            scaleLabel: {
              display: true,
              labelString: 'Bill Amount'
            }
          }],
          xAxes: [{
            barPercentage: 1,
            barThickness: 35,
            ticks: {
              maxRotation: 0,
              minRotation: 0,
            },
            scaleLabel: {
              display: false,
              labelString: 'Bill Date'
            }
          }]
        },
        legend: {
          display: true
        },
      }
    });  
  }

  closeList(value) {
    if (value == 'Specialization') {
      this.inSplFlag = true;
    } else if (value == 'ward') {
      this.inWardFlag = true;
    }
  }

  inpatientSpecialityChart() {
    var name = Array();
    var count = Array();
    var dataArrayFinal = Array();
    if (this.inSpacilityGraphData != null) {
      this.noofinpat = 0;
      for (var i = 0; i < this.inSpacilityGraphData.length; i++) {
        this.specialityarray.push({
          specialid: this.inSpacilityGraphData[i].spl_id,
          specialname: this.inSpacilityGraphData[i].spl_name,
          specialpat: this.inSpacilityGraphData[i].inpat_count,
        });
        name[i] = this.inSpacilityGraphData[i].spl_name;
        count[i] = parseFloat(this.inSpacilityGraphData[i].inpat_count);

        this.noofinpat += parseInt(this.inSpacilityGraphData[i].inpat_count);

        var spec_value = this.inSpacilityGraphData[i].inpat_count;
      }
      console.log(this.noofinpat);

      // for (var j = 0; j < name.length; j++) {
      //   var temp = new Array(name[j], count[j]);
      //   dataArrayFinal[j] = count[j];
      // }

      // var jsonString = JSON.stringify(dataArrayFinal);
      // console.log('dataArrayFinal -- ' + JSON.stringify(dataArrayFinal));
      // var bgColor = [
      //   '#878BB6',
      //   '#FFEA88',
      //   '#FF8153',
      //   '#4ACAB4',
      //   '#c0504d',
      //   '#8064a2',
      //   '#772c2a',
      //   '#f2ab71',
      //   '#2ab881',
      //   '#4f81bd',
      //   '#2c4d75',
      // ];
      // var ctx = <HTMLCanvasElement>(
      //   document.getElementById('InpatientSpecializationCanvas')
      // );
      // this.inpatientSpacility = new Chart(ctx, {
      //   type: 'pie',
      //   data: {
      //     datasets: [
      //       {
      //         data: dataArrayFinal,
      //         backgroundColor: bgColor,
      //         name: name,
      //       },
      //     ],
      //   },
      //   options: {
      //     tooltips: {
      //       callbacks: {
      //         label: function (tooltipItem, data) {
      //           var dataset = data.datasets[tooltipItem.datasetIndex];
      //           var label =
      //             dataset.data[tooltipItem.index] +
      //             ' ' +
      //             dataset.name[tooltipItem.index]; 
      //           return label;
      //         },
      //       },
      //     },
      //   },
      //   maintainAspectRatio: true,
      // });
    }
  }

  inpatientWard() {
    this.wardarray = [];
    var name = Array();
    var count = Array();
    var dataArrayFinal = Array();
    if (this.inWardGraphData != null) {
      for (var i = 0; i < this.inWardGraphData.length; i++) {
        this.wardarray.push({
          wardid: this.inWardGraphData[i].ward_id,
          wardname: this.inWardGraphData[i].ward_name,
          patcount: this.inWardGraphData[i].inpat_count,
          bed_count: this.inWardGraphData[i].bed_count,
          bed_occupt: this.inWardGraphData[i].bed_occ_ratio,
        });
        name[i] = this.inWardGraphData[i].ward_name;
        count[i] = parseFloat(this.inWardGraphData[i].inpat_count);
        var spec_value = this.inWardGraphData[i].inpat_count;
      }
      for (var j = 0; j < name.length; j++) {
        var temp = new Array(name[j], count[j]);
        dataArrayFinal[j] = count[j];
      }

      var jsonString = JSON.stringify(dataArrayFinal);
      if (this.wardSpacility) {
        this.wardSpacility.destroy();
      }

      var bgColor = [
        '#98EECC',
        '#F5F0BB',
        '#E5F9DB',
        '#A6D0DD',
        '#BFCCB5',
        '#E8A0BF',
        '#FFDEB4',
        '#FFBFA9',
        '#E1EEDD',
        '#F1DBBF',
        '#85586F',
      ];
      var ctx = <HTMLCanvasElement>document.getElementById('WardCanvas');
      ctx.height = 175;
      ctx.width = 361;
      this.wardSpacility = new Chart(ctx, {
        type: 'pie',
        data: {
          datasets: [
            {
              data: dataArrayFinal,
              backgroundColor: bgColor,
              name: name,
            },
          ],
        },
        options: {       
          tooltips: {
            callbacks: {             
              label: function (tooltipItem, data) {
                var dataset = data.datasets[tooltipItem.datasetIndex];
                var label =
                  dataset.name[tooltipItem.index] +
                  '=  no.of.bed(s)-' +
                  dataset.data[tooltipItem.index]; 
                return label;
              },
            },
          },
        },
      });
    }
  }

  inpatientocuupationratio() {
    var name = Array();
    var count = Array();
    var dataArrayFinal = Array();
    if (this.inWardGraphData != null) {
      for (var i = 0; i < this.inWardGraphData.length; i++) {
        name[i] = this.inWardGraphData[i].ward_name;
        count[i] = parseFloat(this.inWardGraphData[i].bed_occ_ratio);
      }
      for (var j = 0; j < name.length; j++) {
        var temp = new Array(name[j], count[j]);
        dataArrayFinal[j] = count[j];
      }

      var jsonString = JSON.stringify(dataArrayFinal);
      console.log("dataArrayFinal",dataArrayFinal);
      console.log("name",name);
    
      var bgColor = [
        '#772c2a',
        '#f2ab71',
        '#2ab881',
        '#4f81bd',
        '#2c4d75',
        '#016A70',
        '#F6635C',
        '#ED7B7B',
        '#5B9A8B',
        '#FCBAAD',
        '#E8FFCE',
        '#CCEEBC',
      ];
      var ctx = <HTMLCanvasElement>document.getElementById('bedratioCanvas');
      this.wardSpacilityratio = new Chart(ctx, {
        type: 'pie',
        data: {
          datasets: [
            {
              data: dataArrayFinal,
              backgroundColor: bgColor,
              name: name,
            },
          ],
        },
        options: {
          tooltips: {
            callbacks: {
              label: function (tooltipItem, data) {
                var dataset = data.datasets[tooltipItem.datasetIndex];
                var label =
                  dataset.name[tooltipItem.index] +
                  '= Occupation ratio -' +
                  dataset.data[tooltipItem.index] +
                  '%';
                return label;
              },
            },
          },
        },
      });
    }
  }

  noofpatient() {
    let duration = 0.8;
    let delay = 0.3;
    let revealText = document.querySelector('.reveal');
    let letters = revealText.textContent.split('');
    revealText.textContent = '';

    let middle = letters.filter((e) => e !== ' ').length / 2;

    letters.forEach((letter, i) => {
      let span = document.createElement('span');
      span.textContent = letter;
      span.style.animationDelay = `${delay + Math.abs(i - middle) * 0.1}s`;
      revealText.append(span);
    });
  }

  inSpalData(evt: any) {
    var data = this.inpatientSpacility.getElementsAtEvent(evt);

    var activePoints = this.inpatientSpacility.getElementsAtEventForMode(
      evt,
      'point',
      this.inpatientSpacility.options
    );
    var firstPoint = activePoints[0];
    var label = this.inpatientSpacility.data.labels[firstPoint._index];
    var value =
      this.inpatientSpacility.data.datasets[firstPoint._datasetIndex].data[
      firstPoint._index
      ];

    this.inSpalDataArray = [];
    var inSplSenddata;
    for (var i = 0; i < this.inSpacilityGraphData.length; i++) {
      if (this.inSpacilityGraphData[i].spl_name == label) {
        inSplSenddata = {
          hptl_clinic_id: Doc_Helper.getHospitalClinicalId(),
          spl_id: this.inSpacilityGraphData[i].spl_id,
        };
        break;
      }
    }

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(ipaddress.getIp.toString() + 'inpat/inpsd/', inSplSenddata, {
        headers: headers,
      })
      .subscribe(
        (response) => {
          var obj = JSON.parse(JSON.stringify(response));
          var splList = obj.inpat_details;
          var name;
          for (var i = 0; i < splList.length; i++) {
            if (splList[i].middle_name != undefined) {
              name =
                encrypt_decript.Decript(splList[i].first_name) +
                ' ' +
                encrypt_decript.Decript(splList[i].middle_name) +
                ' ' +
                encrypt_decript.Decript(splList[i].last_name);
            } else {
              if (splList[i].last_name != undefined) {
                name =
                  encrypt_decript.Decript(splList[i].first_name) +
                  ' ' +
                  encrypt_decript.Decript(splList[i].last_name);
              } else {
                name = encrypt_decript.Decript(splList[i].first_name);
              }
            }
            this.inSpalDataArray.push({
              name: name,
              profile_image:
                ipaddress.getIp.toString() + splList[i].profile_image,
              bed_no: splList[i].bed_no,
            });
          }
          this.inSplFlag = false;
        },
        (error) => { }
      );
  }

  inWardData(evt: any) {
    var data = this.wardSpacility.getElementsAtEvent(evt);

    var activePoints = this.wardSpacility.getElementsAtEventForMode(
      evt,
      'point',
      this.wardSpacility.options
    );
    var firstPoint = activePoints[0];
    var label = this.wardSpacility.data.labels[firstPoint._index];
    var value =
      this.wardSpacility.data.datasets[firstPoint._datasetIndex].data[
      firstPoint._index
      ];

    this.inWardDataArray = [];
    var inSplSenddata;
    for (var i = 0; i < this.inWardGraphData.length; i++) {
      if (this.inWardGraphData[i].ward_name == label) {
        inSplSenddata = {
          hptl_clinic_id: Doc_Helper.getHospitalClinicalId(),
          ward_id: this.inWardGraphData[i].ward_id,
        };
        break;
      }
    }

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(ipaddress.getIp.toString() + 'inpat/inpwd/', inSplSenddata, {
        headers: headers,
      })
      .subscribe(
        (response) => {
          var obj = JSON.parse(JSON.stringify(response));

          var splList = obj.inpat_details;
          var name;
          this.totalBeds = obj.no_of_beds;
          this.availableBeds = obj.vacant_beds;
          for (var i = 0; i < splList.length; i++) {
            if (splList[i].middle_name != undefined) {
              name =
                encrypt_decript.Decript(splList[i].first_name) +
                ' ' +
                encrypt_decript.Decript(splList[i].middle_name) +
                ' ' +
                encrypt_decript.Decript(splList[i].last_name);
            } else {
              if (splList[i].last_name != undefined) {
                name =
                  encrypt_decript.Decript(splList[i].first_name) +
                  ' ' +
                  encrypt_decript.Decript(splList[i].last_name);
              } else {
                name = encrypt_decript.Decript(splList[i].first_name);
              }
            }
            this.inWardDataArray.push({
              name: name,
              profile_image:
                ipaddress.getIp.toString() + splList[i].profile_image,
              bed_no: splList[i].bed_no,
            });
          }
          this.inWardFlag = false;
        },
        (error) => { }
      );
  }

  location_chart() {
    var name = Array();
    var count = Array();
    var dataArrayFinal = Array();
    var send_data: any;

    if (this.userinfo.user_type == 'Admin') {
      send_data = {
        reg_id: 'All',
        fdate: Date_Formate(this.f_date),
        tdate: Date_Formate(this.t_date),
        hosp_id: this.hosp_id,
      };
    } else {
      send_data = {
        reg_id: this.user_id,
        fdate: Date_Formate(this.f_date),
        tdate: Date_Formate(this.t_date),
        hosp_id: this.hosp_id,
      };
    }
    console.log('location send data' + JSON.stringify(send_data));

    if (this.type == 'dietician') send_data.type = this.type;

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(ipaddress.getIp.toString() + 'dash/appconloc/', send_data, {
        headers: headers,
      })
      .subscribe(
        (response) => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log('location chart data' + JSON.stringify(obj));

          if (obj.appointments != null) {
            this.loc_flag = true;
            for (var i = 0; i < obj.appointments.length; i++) {
              name[i] = obj.appointments[i].name;
              count[i] = parseFloat(obj.appointments[i].data);
            }

            var dashsearch_array: any = [];
            if (obj.appointments != null) {
              for (var i = 0; i < obj.appointments.length; i++) {
                dashsearch_array.push(obj.appointments[i].data);
              }
            }

            var Appointment_total = 0;
            for (var i = 0; i < dashsearch_array.length; i++) {
              if (Appointment_total != 0) {
                Appointment_total =
                  Appointment_total + parseInt(dashsearch_array[i]);
              } else {
                Appointment_total = parseInt(dashsearch_array[i]);
              }
            }

            this.totalapp2 = 'No of appointments :' + Appointment_total;
            this.totalapp3 = 'No of appointments :' + Appointment_total;
            this.totalapp4 = 'No of appointments :' + Appointment_total;

            var d = [{ name: 'Cardiology', data: 1.0 }];
            for (var j = 0; j < name.length; j++) {
              var temp = new Array(name[j], count[j]);
              dataArrayFinal[j] = count[j];
            }

            HighCharts.setOptions({
              colors: [
                '#878BB6',
                '#FFEA88',
                '#FF8153',
                '#4ACAB4',
                '#c0504d',
                '#8064a2',
                '#772c2a',
                '#f2ab71',
                '#2ab881',
                '#4f81bd',
                '#2c4d75',
              ],
            });
           
            if (dataArrayFinal.length < 0) {
              dataArrayFinal = [0];
            }

            if (this.LocationCanvas) {
              this.LocationCanvas.destroy();
            }

            var bgColor = [
              '#9278ba',
              '#eb9100',
              '#4ba3a9',
              '#9278ba',
              '#4ACAB4',
              '#c0504d',
              '#8064a2',
              '#772c2a',
              '#f2ab71',
              '#2ab881',
              '#4f81bd',
              '#2c4d75',
            ];
            var ctx = <HTMLCanvasElement>(
              document.getElementById('LocationCanvas')
            );
            this.LocationCanvas = new Chart(ctx, {
              type: 'pie',
              data: {
                datasets: [
                  {
                    data: dataArrayFinal,
                    backgroundColor: bgColor,
                    name: name,
                  },
                ],
              },
              options: {
                tooltips: {
                  callbacks: {                
                    label: function (tooltipItem, data) {
                      var dataset = data.datasets[tooltipItem.datasetIndex];
                      var label =
                        dataset.data[tooltipItem.index] +
                        ' ' +
                        dataset.name[tooltipItem.index]; 
                      return label;
                    },
                  },
                },
              },
            });
          } else {
            this.loc_flag = false;
          }
        },
        (error) => { }
      );
  }

  age_chart() {
    HighCharts.setOptions({
      colors: [
        'rgb(8,96,0)',
        'rgb(255,50,50)',
        'rgb(0,47,135)',
        'rgb(135, 0, 135)',
      ],
    });
  }

  gender_chart() {
    HighCharts.setOptions({
      colors: [
        'rgb(8,96,0)',
        'rgb(255,50,50)',
        'rgb(0,47,135)',
        'rgb(135, 0, 135)',
      ],
    });
  }
  public Malecount;
  public Femalecount;
  public male_gender = [];
  public gendercount;



  gender_base() {
    this.hospital_bills = 0;
    this.diagnosis_bills = 0;
    this.pharma_bills = 0;
    var name = Array();
    var count = Array();
    var dataArrayFinal = Array();
    var dataArrayFinal1 = new Array();
    var send_data: any;
    send_data = {
      reg_id: this.user_id,
      fdate: Date_Formate(this.f_date),
      tdate: Date_Formate(this.t_date),
      hosp_id: this.hosp_id,
      admin: this.admin,
      pat_type: 'outpat',
      interval: this.interval,
      range: this.range,
    };

    if (this.type == 'dietician') {
      send_data.type = this.type;
    } else if (this.userinfo.user_type == 'Admin') {
      send_data.reg_id = 'All';
      send_data.admin = '1';
    } else if (this.userinfo.user_type == 'doctor') {
      send_data.reg_id = this.user_id;
    }

    console.log('gender based  send_data ' + JSON.stringify(send_data));
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(ipaddress.getIp.toString() + 'dash/appconbygen/', send_data, {
        headers: headers,
      })
      .subscribe(
        (response) => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log('gender based  GRAPH ' + JSON.stringify(obj));
          if(this.is_admin == true){
            // this.bills_chart(obj);
          }
          
          this.Malecount = 0;
          this.Femalecount = 0;
          if (obj.appointments != null) {
            this.gender_flag = true;

            for (var i = 0; i < obj.appointments.length; i++) {
              name[i] = obj.appointments[i].name;
              count[i] = parseFloat(obj.appointments[i].data);

              if (obj.appointments[i].name == 'Male') {
                this.Malecount = obj.appointments[i].data;
              }

              console.log(
                '---------------------------->>>>>>M',
                this.Malecount
              );

              if (obj.appointments[i].name == 'Female') {
                this.Femalecount = obj.appointments[i].data;
              }

            }
            let hostpitalbill = parseFloat(obj.hosp_bill_amount); 
            let hostpitalbillcount = parseInt(obj.hosp_bill_count);
            let hsp_revenue = parseFloat(obj.hosp_revenue);

            if (!isNaN(hostpitalbill)) {
              let fixedAmount = hostpitalbill.toFixed(2); 
              obj.hospital_bills = fixedAmount.toString();
              this.hospital_bills = obj.hosp_bill_amount;
            }
            if (!isNaN(hostpitalbillcount)) {
              this.hospital_bill_count = parseInt(obj.hosp_bill_count);
            }
            if (!isNaN(hsp_revenue)) {
              let fixedAmount = hsp_revenue.toFixed(2);
              obj.hosp_revenue = fixedAmount.toString();
              this.hsp_revenue = obj.hosp_revenue;
            }
            let diagnosisbill = parseFloat(obj.diag_bill_amount);
            let diagnosisbillcount = parseInt(obj.diag_bill_count);
            let diag_revenue = parseFloat(obj.diag_revenue);
            if (!isNaN(diagnosisbill)) {
              let fixedAmount = diagnosisbill.toFixed(2);
              obj.diagnosis_bills = fixedAmount.toString();
              this.diagnosis_bills = obj.diag_bill_amount;
            }
            if (!isNaN(diagnosisbillcount)) {
              this.diagnosis_bill_count = parseInt(obj.diag_bill_count);
            }
            if (!isNaN(diag_revenue)) {
              let fixedAmount = diag_revenue.toFixed(2);
              obj.diag_revenue = fixedAmount.toString();
              this.diag_revenue = obj.diag_revenue;
            }

            // let pharmabill = parseFloat(obj.pharma_bill_amount);
            // let pharmabillcount = parseInt(obj.pharma_bill_count);
            // if (!isNaN(pharmabill)) {
            //   let fixedAmount = pharmabill.toFixed(2);
            //   obj.diagnosis_bills = fixedAmount.toString();
            //   this.pharma_bills = obj.pharma_bill_amount;
            // }
            // if (!isNaN(pharmabillcount)) {
            //   this.pharma_bill_count = obj.pharma_bill_count;
            // }


            console.log('hospbill', this.pharma_bills);


            if (obj.appointments.length == 0) {
              this.no_data = false;
              this.no_grpah = true;
            } else {
              this.no_data = true;
              this.no_grpah = false;
            }

            var d = [{ name: 'Cardiology', data: 1.0 }];
            var dashsearch_array: any = [];
            if (obj.appointments != null) {
              for (var i = 0; i < obj.appointments.length; i++) {
                dashsearch_array.push(obj.appointments[i].data);

              }
            }

            var Appointment_total = 0;

            for (var i = 0; i < dashsearch_array.length; i++) {
              if (Appointment_total != 0) {
                Appointment_total =
                  Appointment_total + parseInt(dashsearch_array[i]);
              } else {
                Appointment_total = parseInt(dashsearch_array[i]);
              }
            }

            this.totalapp2 = 'No of appointment :' + Appointment_total;
            this.totalapp3 = 'No of appointment :' + Appointment_total;
            this.totalapp4 = 'No of appointment :' + Appointment_total;
            console.log('name 1', name);
            for (var j = 0; j < name.length; j++) {
              console.log('name 2', name);
              var temp = new Array(name[j], count[j]);
              console.log('temp-------->', temp);
              dataArrayFinal[j] = count[j];
              dataArrayFinal1[j] = temp;
            }
            if (true) { //this.is_admin

              var jsonString = JSON.stringify(dataArrayFinal);
              console.log(dataArrayFinal);
              if (this.genmyChart) {
                this.genmyChart.destroy();
              }

              var bgColor = ['#CFD7C7', '#70A9A1', '#B7B6C1'];
              var ctx = <HTMLCanvasElement>(
                document.getElementById('piechart_gen')
              );
              this.genmyChart = new Chart(ctx, {
                type: 'pie',
                data: {
                  datasets: [
                    {
                      data: dataArrayFinal,
                      backgroundColor: bgColor,
                      name: name,
                    },
                  ],
                },
                options: {
                  radius: '60%',
                  tooltips: {
                    callbacks: {
                     
                      label: function (tooltipItem, data) {
                       
                        var dataset = data.datasets[tooltipItem.datasetIndex];
                        var label =
                          dataset.data[tooltipItem.index] +
                          ' ' +
                          dataset.name[tooltipItem.index]; 
                        return label;
                      },
                    },
                  },
                },
              });
            }

          } else {
            this.gender_flag = false;
          }
        },
        (error) => { }
      );
  }


  user_base() {
    if (this.userinfo.admin == '1' || this.userinfo.user_type == 'Admin') {  
      this.user_id = 'All';     
    } else {
      this.user_id = this.userinfo.user_id;
    }
    this.hospital_bills = 0;
    this.diagnosis_bills = 0;
    this.pharma_bills = 0;
    var name = Array();
    var count = Array();
    var dataArrayFinal = Array();
    var dataArrayFinal1 = new Array();
    var send_data: any;
    send_data = {
      reg_id: this.user_id,
      fdate: Date_Formate(this.f_date),
      tdate: Date_Formate(this.t_date),
      hosp_id: this.hosp_id,
      range: this.range,
      createdby: this.user_id
    };

    console.log('user based  send_data ' + JSON.stringify(send_data));
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(ipaddress.getIp.toString() + 'dash/grbhd', send_data, {
        headers: headers,
      })
      .subscribe(
        (response) => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log('user based  GRAPH ' + JSON.stringify(obj));
          console.log('user based  GRAPH ' + obj);
          if(true){ //this.is_admin
            this.user_based_bills_chart(obj);
          }
          
          // this.Malecount = 0;
          // this.Femalecount = 0;
          // if (obj.appointments != null) {
          //   this.gender_flag = true;

          //   for (var i = 0; i < obj.appointments.length; i++) {
          //     name[i] = obj.appointments[i].name;
          //     count[i] = parseFloat(obj.appointments[i].data);

          //     if (obj.appointments[i].name == 'Male') {
          //       this.Malecount = obj.appointments[i].data;
          //     }

          //     console.log(
          //       '---------------------------->>>>>>M',
          //       this.Malecount
          //     );

          //     if (obj.appointments[i].name == 'Female') {
          //       this.Femalecount = obj.appointments[i].data;
          //     }

          //   }
          //   let hostpitalbill = parseFloat(obj.hosp_bill_amount); 
          //   let hostpitalbillcount = parseInt(obj.hosp_bill_count);
          //   let hsp_revenue = parseFloat(obj.hosp_revenue);

          //   if (!isNaN(hostpitalbill)) {
          //     let fixedAmount = hostpitalbill.toFixed(2); 
          //     obj.hospital_bills = fixedAmount.toString();
          //     this.hospital_bills = obj.hosp_bill_amount;
          //   }
          //   if (!isNaN(hostpitalbillcount)) {
          //     this.hospital_bill_count = parseInt(obj.hosp_bill_count);
          //   }
          //   if (!isNaN(hsp_revenue)) {
          //     let fixedAmount = hsp_revenue.toFixed(2);
          //     obj.hosp_revenue = fixedAmount.toString();
          //     this.hsp_revenue = obj.hosp_revenue;
          //   }
          //   let diagnosisbill = parseFloat(obj.diag_bill_amount);
          //   let diagnosisbillcount = parseInt(obj.diag_bill_count);
          //   let diag_revenue = parseFloat(obj.diag_revenue);
          //   if (!isNaN(diagnosisbill)) {
          //     let fixedAmount = diagnosisbill.toFixed(2);
          //     obj.diagnosis_bills = fixedAmount.toString();
          //     this.diagnosis_bills = obj.diag_bill_amount;
          //   }
          //   if (!isNaN(diagnosisbillcount)) {
          //     this.diagnosis_bill_count = parseInt(obj.diag_bill_count);
          //   }
          //   if (!isNaN(diag_revenue)) {
          //     let fixedAmount = diag_revenue.toFixed(2);
          //     obj.diag_revenue = fixedAmount.toString();
          //     this.diag_revenue = obj.diag_revenue;
          //   }

          //   let pharmabill = parseFloat(obj.pharma_bill_amount);
          //   let pharmabillcount = parseInt(obj.pharma_bill_count);
          //   if (!isNaN(pharmabill)) {
          //     let fixedAmount = pharmabill.toFixed(2);
          //     obj.diagnosis_bills = fixedAmount.toString();
          //     this.pharma_bills = obj.pharma_bill_amount;
          //   }
          //   if (!isNaN(pharmabillcount)) {
          //     this.pharma_bill_count = obj.pharma_bill_count;
          //   }


          //   console.log('hospbill', this.pharma_bills);


          //   if (obj.appointments.length == 0) {
          //     this.no_data = false;
          //     this.no_grpah = true;
          //   } else {
          //     this.no_data = true;
          //     this.no_grpah = false;
          //   }

          //   var d = [{ name: 'Cardiology', data: 1.0 }];
          //   var dashsearch_array: any = [];
          //   if (obj.appointments != null) {
          //     for (var i = 0; i < obj.appointments.length; i++) {
          //       dashsearch_array.push(obj.appointments[i].data);

          //     }
          //   }

          //   var Appointment_total = 0;

          //   for (var i = 0; i < dashsearch_array.length; i++) {
          //     if (Appointment_total != 0) {
          //       Appointment_total =
          //         Appointment_total + parseInt(dashsearch_array[i]);
          //     } else {
          //       Appointment_total = parseInt(dashsearch_array[i]);
          //     }
          //   }

          //   this.totalapp2 = 'No of appointment :' + Appointment_total;
          //   this.totalapp3 = 'No of appointment :' + Appointment_total;
          //   this.totalapp4 = 'No of appointment :' + Appointment_total;
          //   console.log('name 1', name);
          //   for (var j = 0; j < name.length; j++) {
          //     console.log('name 2', name);
          //     var temp = new Array(name[j], count[j]);
          //     console.log('temp-------->', temp);
          //     dataArrayFinal[j] = count[j];
          //     dataArrayFinal1[j] = temp;
          //   }
          //   if (this.is_admin == false) {

          //     var jsonString = JSON.stringify(dataArrayFinal);
          //     if (this.genmyChart) {
          //       this.genmyChart.destroy();
          //     }

          //     var bgColor = ['#CFD7C7', '#70A9A1', '#B7B6C1'];
          //     var ctx = <HTMLCanvasElement>(
          //       document.getElementById('piechart_gen')
          //     );
          //     this.genmyChart = new Chart(ctx, {
          //       type: 'pie',
          //       data: {
          //         datasets: [
          //           {
          //             data: dataArrayFinal,
          //             backgroundColor: bgColor,
          //             name: name,
          //           },
          //         ],
          //       },
          //       options: {
          //         radius: '60%',
          //         tooltips: {
          //           callbacks: {
                     
          //             label: function (tooltipItem, data) {
                       
          //               var dataset = data.datasets[tooltipItem.datasetIndex];
          //               var label =
          //                 dataset.data[tooltipItem.index] +
          //                 ' ' +
          //                 dataset.name[tooltipItem.index]; 
          //               return label;
          //             },
          //           },
          //         },
          //       },
          //     });

          //   }

          // } else {
          //   this.gender_flag = false;
          // }
        },
        (error) => { }
      );
  }

  bills_chart(data) {
    if (this.interval == 0) {
      this.hospitalusername = [];
      this.hospital_bill_amount = [];
      this.diagnosisusername = [];
      this.diag_bill_amount = [];
      this.pharmausername = [];
      this.pharma_bill_amount = [];
      this.diagnosis_bill_count = 0
      this.pharma_bill_count = 0
      this.hospital_bill_count = 0
      this.hospital_bills = 0;
      this.diagnosis_bills = 0;
      this.pharma_bills = 0;

      if(data.hosp_bills != undefined){
        for (var h = 0; h < data.hosp_bills.length; h++) {
          console.log(JSON.stringify(data.hosp_bills[h]))
          this.hospitalusername.push(
            data.hosp_bills[h].created_by
          )
          this.hospital_bills += parseInt(data.hosp_bills[h].bill_amount)
          this.hospital_bills += parseInt(data.hosp_bills[h].bill_count)
          this.hospital_bill_amount.push(
            data.hosp_bills[h].bill_amount
          )
        }
      }   
      
      if(data.diag_bills != undefined){
        for (var d = 0; d < data.diag_bills.length; d++) {
          console.log("diag_bill = " + JSON.stringify(data.diag_bills[d]))
          if (data.diag_bills[d].created_by != "null null") {
            this.diagnosisusername.push(
              data.diag_bills[d].created_by
            )
            this.diagnosis_bills += parseInt(data.diag_bills[d].bill_amount)
            this.diagnosis_bill_count += parseInt(data.diag_bills[d].bill_count)
            this.diag_bill_amount.push(
              data.diag_bills[d].bill_amount
            )
          }
        }
      }

      if(data.pharma_bills != undefined){
        for (var p = 0; p < data.pharma_bills.length; p++) {
          console.log("diag_bill = " + JSON.stringify(data.pharma_bills[p]))
          if (data.pharma_bills[p].created_by != undefined) {
            this.pharmausername.push(
              data.pharma_bills[p].created_by
            )
            this.pharma_bills += parseInt(data.pharma_bills[p].bill_amount)
            this.pharma_bill_count += parseInt(data.pharma_bills[p].pharma_bill_count)
            this.pharma_bill_amount.push(
              data.pharma_bills[p].bill_amount
            )
          }
        }
      }

      console.log("hospitalusername" + JSON.stringify(this.hospitalusername))
      console.log("hospital_bill_amount" + JSON.stringify(this.hospital_bill_amount))

      console.log("diag billl = " + JSON.stringify(this.diagnosisusername))
      console.log("diag billl amt = " + JSON.stringify(this.diag_bill_amount))
      var xAxis = [this.curr_date];
      if (this.hospitalbillCanvas) {
        this.hospitalbillCanvas.destroy();
      }
      var hspbill = <HTMLCanvasElement>(
        document.getElementById('hospitalbillCanvas')
      );
      this.hospitalbillCanvas = new Chart(hspbill, {
        type: 'bar',
        data: {
          labels: this.hospitalusername,
          datasets: [{
            label: 'Bill Amount',
            data: this.hospital_bill_amount,
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(255, 159, 64, 0.2)',
              'rgba(255, 205, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(201, 203, 207, 0.2)'
            ]
          }]

        },
      })

      //diagnosis
      if (this.diagnosisCanvas) {
        this.diagnosisCanvas.destroy();
      }
      var diagbill = <HTMLCanvasElement>(
        document.getElementById('diagnosisCanvas')
      );
      this.diagnosisCanvas = new Chart(diagbill, {
        type: 'bar',

        data: {
          labels: this.diagnosisusername,
          datasets: [{
            data: this.diag_bill_amount,
            label: 'Bill Amount',
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(255, 159, 64, 0.2)',
              'rgba(255, 205, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(201, 203, 207, 0.2)'
            ]
          }]
        },
      })

      //pharma
      if (this.pharmacyCanvas) {
        this.pharmacyCanvas.destroy();
      }
      var pharma_bill = <HTMLCanvasElement>(
        document.getElementById('pharmacyCanvas')
      );
      this.pharmacyCanvas = new Chart(pharma_bill, {
        type: 'bar',
        data: {
          labels: this.pharmausername,
          datasets: [{
            data: this.pharma_bill_amount,
            label: 'Bill Amount',
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(255, 159, 64, 0.2)',
              'rgba(255, 205, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(201, 203, 207, 0.2)'
            ]
          }]
        },
      })


    } else if (this.interval != 0) {

      //hospital
      this.hospitalusername = [];
      this.hospital_bill_amount = [];
      this.diagnosisusername = [];
      this.diag_bill_amount = [];
      this.pharmausername = [];
      this.pharma_bill_amount = [];
      this.diag_bil_date=[];
      this.hospital_bills = 0;
      this.diagnosis_bills = 0;
      this.pharma_bills = 0;
      this.diagnosis_bill_count = 0
      this.pharma_bill_count = 0
      this.hospital_bill_count = 0

      if(data.hosp_bills != undefined){
        for (var h = 0; h < data.hosp_bills.length; h++) {
          console.log(JSON.stringify(data.hosp_bills[h]));
          this.hospitalusername.push(
            data.hosp_bills[h].created_by
          )
          this.hospital_bills += parseFloat(data.hosp_bills[h].bill_amount);
          this.hospital_bill_count += parseInt(data.hosp_bills[h].bill_count)
          this.hospital_bill_amount.push(
            data.hosp_bills[h].bill_amount
          )
  
        }
      }
    
      var dataset_array = []
      if(data.diag_bills != undefined){
        for (var d = 0; d < data.diag_bills.length; d++) {
          console.log("diag_bill = " + JSON.stringify(data.diag_bills[d]))
          if (data.diag_bills[d].created_by != "null null") {
            this.diagnosisusername.push(
              data.diag_bills[d].created_by
            )
            if(data.diag_bills[d].bill_amount != undefined){
              this.diagnosis_bills  += parseInt(data.diag_bills[d].bill_amount)
            }
            
            this.diagnosis_bill_count += parseInt(data.diag_bills[d].bill_count)
            
            this.diag_bill_amount.push(
              data.diag_bills[d].bill_amount
            )
            this.diag_bil_date.push(
              Date_Formate (data.diag_bills[d].bill_date)
            )     
            
            dataset_array.push({
              data: [data.diag_bills[d].bill_amount],
              fill: false,
              borderWidth: 2,
              spanGaps: true,
              label: data.diag_bills[d].created_by
            })          
          }
        }
  
      }

      if(data.pharma_bills != undefined){
        for (var p = 0; p < data.pharma_bills.length; p++) {
          console.log("diag_bill = " + JSON.stringify(data.pharma_bills[p]))
          if (data.pharma_bills[p].created_by != undefined) {
            this.pharmausername.push(
              data.pharma_bills[p].created_by
            )
            this.pharma_bills = parseInt(data.pharma_bills[p].bill_amount)
            this.pharma_bill_count = parseInt(data.pharma_bills[p].pharma_bill_count)
            this.pharma_bill_amount.push(
              data.pharma_bills[p].bill_amount
            )
          }
        }      
      }
      
      console.log("hospitalusername" + JSON.stringify(this.hospitalusername))
      console.log("hospital_bill_amount" + JSON.stringify(this.hospital_bill_amount))

      console.log("diag billl = " + JSON.stringify(this.diagnosisusername))
      console.log("diag billl amt = " + JSON.stringify(this.diag_bill_amount))
      var xAxis = [this.curr_date];
      if (this.hospitalbillCanvas) {
        this.hospitalbillCanvas.destroy();
      }
      var hspbill = <HTMLCanvasElement>(
        document.getElementById('hospitalbillCanvas')
      );
      this.hospitalbillCanvas = new Chart(hspbill, {
        type: 'bar',
        data: {
          labels: this.hospitalusername,
          datasets: [
            {
              data: this.hospital_bill_amount,
              label: 'Bill Amount',
              backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(255, 159, 64, 0.2)',
                'rgba(255, 205, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(201, 203, 207, 0.2)'
              ]
            }
          ]

        },
      })

      //diagnosis
      if (this.diagnosisCanvas) {
        this.diagnosisCanvas.destroy();
      }
      var diagbill = <HTMLCanvasElement>(
        document.getElementById('diagnosisCanvas')
      );
      this.diagnosisCanvas = new Chart(diagbill, {
        type: 'line',
        data: {
          labels: this.diag_bil_date,
          datasets: [{
            data: [2000, 3000, 2500, 4000],
            backgroundColor: "#01452c",
            borderColor: "#01452c",
            fill: false,
            borderWidth: 2,
            spanGaps: true,
            label: "Abishek"
          }, {
            data: [2500, 3200, 2000, 4500],
            backgroundColor: "#ff8b00",
            borderColor: "#ff8b00",
            fill: false,
            borderWidth: 2,
            spanGaps: true,
            label: "Nithyakala"
          }],
        },
      })
      //pharma

      if (this.pharmacyCanvas) {
        this.pharmacyCanvas.destroy();
      }
      var pharma_bill = <HTMLCanvasElement>(
        document.getElementById('pharmacyCanvas')
      );
      this.pharmacyCanvas = new Chart(pharma_bill, {
        type: 'line',
        data: {
          labels: this.pharmausername,
          datasets: [{
            data: this.pharma_bill_amount,
            label: 'Bill Amount',
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(255, 159, 64, 0.2)',
              'rgba(255, 205, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(201, 203, 207, 0.2)'
            ]
          }]
        },
      })
    }
  }

  user_based_bills_chart(data) {
    if (this.interval == 0) {
      this.hospitalusername = [];
      this.hospitalUser_id = [];
      this.hospital_bill_amount = [];
      this.diagnosisusername = [];
      this.diag_bill_amount = [];
      this.pharmausername = [];
      this.pharma_bill_amount = [];
      this.diagnosis_bill_count = 0
      this.pharma_bill_count = 0
      this.hospital_bill_count = 0
      this.hospital_bills = 0;
      this.diagnosis_bills = 0;
      this.pharma_bills = 0;
      this.hospital_backgroundColor = [];
      this.diag_backgroundColor = [];

      if(data.hosp_data != undefined){
        for (var h = 0; h < data.hospital_bills.length; h++) {                
          this.hospital_bills += parseFloat(data.hospital_bills[h].op_bill_amount);
          this.hospital_bill_count += parseInt(data.hospital_bills[h].op_bill_count);           
        }
        console.log("hospital_bill_count",this.hospital_bill_count);                       
        console.log("hospital_bill_amount",this.hospital_bills);

        var backgroundColors = ['rgb(255, 99, 132)',
                                'rgb(75, 192, 192)',
                                'rgb(255, 205, 86)',
                                'rgb(201, 203, 207)',
                                'rgb(54, 162, 235)']

      
        for (var i = 0; i < data.hosp_data.length; i++) {
          this.hospitalusername.push(
            data.hosp_data[i].created_by
          )
          this.hospital_bill_amount.push(
            this.sumArray(data.hosp_data[i].op_bill_amount)
          )
          this.hospital_backgroundColor.push(
            backgroundColors[i]
          )         
        }

        console.log("hospitalusername",this.hospitalusername);
        console.log("hospital_bill_amount",this.hospital_bill_amount);
      
        if (this.hospitalbillCanvas) {
          this.hospitalbillCanvas.destroy();
        }
      
        var hspbill = <HTMLCanvasElement>(
          document.getElementById('hospitalbillCanvas')
        );
        hspbill.height = 175;
        hspbill.width = 361;
        this.hospitalbillCanvas = new Chart(hspbill, {
          type: 'bar',
          data: {
            labels: this.hospitalusername,
            datasets: [{
              data: this.hospital_bill_amount,
              backgroundColor: this.hospital_backgroundColor,
              fill: false,
              borderWidth: 2,
              spanGaps: true,  
            }]
          },
          options: {
            responsive: false,
            scales: {
              yAxes: [{
                display: true,
                ticks: {
                  beginAtZero: true,
                  steps: 100,
                  stepValue: 5,
                },
                scaleLabel: {
                  display: true,
                  labelString: 'Bill Amount'
                }
              }],
              xAxes: [{
                barPercentage: 1,
                barThickness: 35,
                ticks: {
                  maxRotation: 0,
                  minRotation: 0,
                },
                scaleLabel: {
                  display: true,
                  labelString: 'Users'
                }
              }]
            },
            legend: {
              display: false
            },
          }  
        })           
      }   
      
      if (data.diagnosis_bills != undefined) {
        for (var d = 0; d < data.diagnosis_bills.length; d++) {
          if (data.diagnosis_bills[d].created_by != "null null") {
            if(data.diagnosis_bills[d].op_bill_amount != undefined) {
              this.diagnosis_bills  += parseInt(data.diagnosis_bills[d].op_bill_amount);
            }        
            this.diagnosis_bill_count += parseInt(data.diagnosis_bills[d].op_bill_count);             
          }
        } 
        console.log("diagnosis_bill_count",this.diagnosis_bill_count);                       
        console.log("diagnosis_bill_amount",this.diagnosis_bills);

        var backgroundColors = ['rgb(255, 99, 132)',
                                'rgb(75, 192, 192)',
                                'rgb(255, 205, 86)',
                                'rgb(201, 203, 207)',
                                'rgb(54, 162, 235)'];
        
        for(var i = 0; i < data.diag_data.length; i++){
          this.diagnosisusername.push(
            data.diag_data[i].created_by
          )
          this.diag_bill_amount.push(
            this.sumArray(data.diag_data[i].op_bill_amount)
          )
          this.diag_backgroundColor.push(
            backgroundColors[i]
          )         
        }
      
        if (this.diagnosisCanvas) {
          this.diagnosisCanvas.destroy();
        }
      
        var hspbill = <HTMLCanvasElement>(
          document.getElementById('diagnosisCanvas')
        );
        hspbill.height = 175;
        hspbill.width = 361;
        this.diagnosisCanvas = new Chart(hspbill, {
          type: 'bar',
          data: {
            labels: this.diagnosisusername,
            datasets: [{
              data: this.diag_bill_amount,
              backgroundColor: this.diag_backgroundColor,
              fill: false,
              borderWidth: 2,
              spanGaps: true,  
            }]
          },
          options: {
            responsive: false,
            scales: {
              yAxes: [{
                display: true,
                ticks: {
                  beginAtZero: true,
                  steps: 100,
                  stepValue: 5,
                },
                scaleLabel: {
                  display: true,
                  labelString: 'Bill Amount'
                }
              }],
              xAxes: [{
                barPercentage: 1,
                barThickness: 35,
                ticks: {
                  maxRotation: 0,
                  minRotation: 0,
                },
                scaleLabel: {
                  display: true,
                  labelString: 'Users'
                }
              }]
            },
            legend: {
              display: false
            },
          }
        })     
      } 
    } else if (this.interval != 0) {
      //hospital
      this.hospitalusername = [];
      this.hospitalUser_id = [];
      this.hospital_bill_amount = [];
      this.diagnosisusername = [];
      this.diag_bill_amount = [];
      this.diagnosis_bills_array = [];
      this.diagnosisuser_id = [];
      this.pharmausername = [];
      this.pharma_bill_amount = [];
      this.diag_bil_date=[];
      this.hospital_bills = 0;
      this.diagnosis_bills = 0;
      this.pharma_bills = 0;
      this.diagnosis_bill_count = 0
      this.pharma_bill_count = 0
      this.hospital_bill_count = 0

      if (data.hospital_bills != undefined) {
        setTimeout(()=>{
          this.getHospitalBillDetails(data.hospital_bills,data.hosp_data,data.hosp_dates);
        },500);       
        for (var h = 0; h < data.hospital_bills.length; h++) {                 
          this.hospital_bills += parseFloat(data.hospital_bills[h].op_bill_amount);
          this.hospital_bill_count += parseInt(data.hospital_bills[h].op_bill_count);  
        }
        console.log("hospital_bill_count",this.hospital_bill_count);                       
        console.log("hospital_bill_amount",this.hospital_bills);
      }
      
      if (data.diagnosis_bills != undefined) {
        setTimeout(()=>{
          this.getDiagnosisBillDetails(data.diagnosis_bills,data.diag_data,data.diag_dates);
        },500);      
        for (var d = 0; d < data.diagnosis_bills.length; d++) {
          if (data.diagnosis_bills[d].created_by != "null null") {
            if (data.diagnosis_bills[d].op_bill_amount != undefined) {
              this.diagnosis_bills  += parseInt(data.diagnosis_bills[d].op_bill_amount);
            }        
            this.diagnosis_bill_count += parseInt(data.diagnosis_bills[d].op_bill_count);             
          }
        } 
        console.log("diagnosis_bill_count",this.diagnosis_bill_count);                       
        console.log("diagnosis_bill_amount",this.diagnosis_bills);
      }
    }
  }

  sumArray(arr) {
    return arr.reduce((acc, currentValue) => acc + parseFloat(currentValue), 0);
  }

  getHospitalBillDetails(hospital_bills,hosp_data,hosp_dates) {
    var backgroundColors = ['rgb(255, 99, 132)',
    'rgb(75, 192, 192)',
    'rgb(255, 205, 86)',
    'rgb(201, 203, 207)',
    'rgb(54, 162, 235)']
    console.log("getHospitalBillDetails------", hospital_bills, hosp_data, hosp_dates);
    var hospital_dates = [];
    for (var i = 0;i < hosp_dates.length; i++) {
      hospital_dates.push(
        Date_Formate(hosp_dates[i])
      )
    }
    console.log(hospital_dates);

    var dataset_array = [];
    for(var i = 0; i < hosp_data.length; i++){
      dataset_array.push({
        data: hosp_data[i].op_bill_amount,
        fill: false,
        backgroundColor: backgroundColors[i],
        borderColor: backgroundColors[i],
        spanGaps: true,
        label: hosp_data[i].created_by
      })      
    }
    console.log("Datasetarray------",dataset_array);
    
    if (this.hospitalbillCanvas) {
      this.hospitalbillCanvas.destroy();
    }
    
    var ctx = <HTMLCanvasElement>document.getElementById("hospitalbillCanvas"); 
    ctx.height = 175;
    ctx.width = 361;
    this.hospitalbillCanvas = new Chart(ctx, {
      type: "line",
      labels: ["Stepcount"],
      data: {
        labels: hospital_dates,
        datasets: dataset_array,
      },
      options: {
        responsive: false,
        scales: {
          yAxes: [{
            display: true,
            ticks: {
              beginAtZero: true,
              steps: 100,
              stepValue: 5,
            },
            scaleLabel: {
              display: true,
              labelString: 'Bill Amount'
            }
          }],
          xAxes: [{
            barPercentage: 1,
            barThickness: 35,
            ticks: {
              maxRotation: 0,
              minRotation: 0,
            },
            scaleLabel: {
              display: false,
              labelString: 'Bill Date'
            }
          }]
        },
        legend: {
          display: true
        },
      }
    });   
  }

  getDiagnosisBillDetails(diagnosis_bills,diag_data,diag_dates) {
    var backgroundColors = ['rgb(255, 99, 132)',
    'rgb(75, 192, 192)',
    'rgb(255, 205, 86)',
    'rgb(201, 203, 207)',
    'rgb(54, 162, 235)'];
    console.log("getDiagnosisBillDetails------", diagnosis_bills, diag_data, diag_dates);
    var diagnosis_dates = [];
    for (var i = 0;i < diag_dates.length; i++) {
      diagnosis_dates.push(
        Date_Formate(diag_dates[i])
      )
    }
    console.log(diagnosis_dates);

    var dataset_array = [];
    for(var i = 0; i < diag_data.length; i++){
      if (diag_data[i].created_by == 'null null') {
        diag_data[i].created_by = 'Sudharsan';
      }
      dataset_array.push({
        data: diag_data[i].op_bill_amount,
        fill: false,
        backgroundColor: backgroundColors[i],
        borderColor: backgroundColors[i],
        spanGaps: true,
        label: diag_data[i].created_by
      })      
    }
    console.log("Datasetarray------",dataset_array);
    
    if (this.diagnosisCanvas) {
      this.diagnosisCanvas.destroy();
    }
    var ctx = <HTMLCanvasElement>document.getElementById("diagnosisCanvas"); 
    ctx.height = 175;
    ctx.width = 361;
    this.diagnosisCanvas = new Chart(ctx, {
      type: "line",
      labels: ["Stepcount"],
      data: {
        labels: diagnosis_dates,
        datasets: dataset_array,
      },
      options: {
        responsive: false,
        scales: {
          yAxes: [{
            display: true,
            ticks: {
              beginAtZero: true,
              steps: 100,
              stepValue: 5,
            },
            scaleLabel: {
              display: true,
              labelString: 'Bill Amount'
            }
          }],
          xAxes: [{
            barPercentage: 1,
            barThickness: 35,
            ticks: {
              maxRotation: 0,
              minRotation: 0,
            },
            scaleLabel: {
              display: false,
              labelString: 'Bill Date'
            }
          }]
        },
        legend: {
          display: true
        },
      }
    });   
  }

  getRotation(): string {
    if (!isNaN(this.diag_revenue)) {
      const rotation = this.diag_revenue * 1.4;
      console.log('Rotation:', rotation);
      return rotation.toFixed(2) + 'deg';
    } else {
      console.log('Rotation: 0');
      return 'rotate(0deg)';
    }
  }

  public patientmyChart;
  patient_base() {
    console.log("Function Called-----");
    var name = Array();
    var count = Array();
    var dataArrayFinal = Array();
    var dataArrayFinal1 = new Array();
    var send_data: any;
    var count1;
    var count2;
    var patientdata;

    send_data = {
      reg_id: this.user_id,
      fdate: Date_Formate(this.f_date),
      tdate: Date_Formate(this.t_date),

      hosp_id: this.hosp_id,
      admin: this.admin,
      pat_type: 'outpat',
      interval: this.interval,
      range: this.range
    };

    if (this.type == 'dietician') {
      send_data.type = this.type;

    } else if (this.userinfo.user_type == 'Admin') {
      send_data.reg_id = 'All';
      send_data.admin = '1';

    }
    console.log('patient base send data' + JSON.stringify(send_data));
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(ipaddress.getIp.toString() + 'dash/appconbygen/', send_data, {
        headers: headers,
      })
      .subscribe(
        (response) => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log('patient chart' + JSON.stringify(obj));
          dataArrayFinal.push({
            Out_patients: obj.op_app,
            In_patients: parseFloat(obj.ip_app),
          });
          console.log('data count', dataArrayFinal);
          if (this.patientmyChart) {
            this.patientmyChart.destroy();
          }
          var name = ['Out-Patients', 'In-Patients'];
          var ctx = <HTMLCanvasElement>(
            document.getElementById('piechart_patients')
          );
          this.patientmyChart = new Chart(ctx, {
            type: 'pie',
            data: {
              datasets: [
                {
                  label: '# of Votes',
                  data: Object.values(dataArrayFinal[0]),
                  backgroundColor: ['rgb(255, 99, 132)', 'rgb(54, 162, 235)'],
                  name: name,
                },
              ],
            },
            options: {
              tooltips: {
                callbacks: {                  
                  label: function (tooltipItem, data) {                   
                    var dataset = data.datasets[tooltipItem.datasetIndex];
                    var label =
                      dataset.data[tooltipItem.index] +
                      ' ' +
                      dataset.name[tooltipItem.index]; 
                    return label;
                  },
                },
              },
              scales: {
                y: {
                  beginAtZero: true,
                },
              },
              plugins: {
                legend: {
                  position: 'right',
                },
              },
            },
          });
        },
        (error) => { }
      );
  }

  getMalecountValue(): number | string {
    if (
      this.Malecount === undefined ||
      this.Malecount === null ||
      this.Malecount === ''
    ) {
      return '0';
    } else {
      return this.Malecount;
    }
  }

  age_base() {
    var name = Array();
    var count = Array();
    var dataArrayFinal = Array();
    var get_age_value = null;
    var send_data: any;
    send_data = {
      reg_id: this.user_id,
      fdate: Date_Formate(this.f_date),
      tdate: Date_Formate(this.t_date),
      hosp_id: this.hosp_id,
    };

    if (this.type == 'dietician') {
      send_data.type = this.type;
    } else if (this.userinfo.user_type == 'Admin') {
      send_data.reg_id = 'All';
      send_data.admin = '1';
    }

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(ipaddress.getIp.toString() + 'dash/appconbyage/', send_data, {
        headers: headers,
      })
      .subscribe(
        (response) => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log('age base DATA ----->', JSON.stringify(obj));

          if (obj.appointments != null) {
            this.age_flag = true;
            for (var i = 0; i < obj.appointments.length; i++) {
              name[i] = obj.appointments[i].name;
              count[i] = parseFloat(obj.appointments[i].data);
              var get_age_value = obj.appointments[i].data;
            }

            var dashsearch_array: any = [];
            for (var i = 0; i < obj.appointments.length; i++) {
              dashsearch_array.push(obj.appointments[i].data);
            }

            var Appointment_total = 0;
            for (var i = 0; i < dashsearch_array.length; i++) {
              if (Appointment_total != 0) {
                Appointment_total =
                  Appointment_total + parseInt(dashsearch_array[i]);
              } else {
                Appointment_total = parseInt(dashsearch_array[i]);
              }
            }

            if (get_age_value != null) {
              this.totalapp1 = 'No of appointments :' + get_age_value;
              this.age_graph = false;
            } else {
              this.totalapp1 = 'No of appointments :' + get_age_value;
              this.age_graph = true;
            }

            this.totalapp2 = 'No of appointments :' + Appointment_total;
            this.totalapp3 = 'No of appointments :' + Appointment_total;
            this.totalapp4 = 'No of appointments :' + Appointment_total;

            var d = [{ name: 'Cardiology', data: 1.0 }];

            for (var j = 0; j < name.length; j++) {
              var temp = new Array(name[j], count[j]);
              dataArrayFinal[j] = count[j];
            }

            var jsonString = JSON.stringify(dataArrayFinal);

            if (this.AgeCanvas) {
              this.AgeCanvas.destroy();
            }
        
            var bgColor = [
              '#9fc2cc',
              '#b7cdcc',
              '#87bfff',
              '#59656f',
              '#726953',
              '#4bc0c0',
              '#36a2eb',
              '#878BB6',
              '#FFEA88',
              '#FF8153',
              '#4ACAB4',
              '#c0504d',
              '#8064a2',
              '#772c2a',
              '#f2ab71',
              '#2ab881',
              '#4f81bd',
              '#2c4d75',
            ];

            var ctx = <HTMLCanvasElement>document.getElementById('AgeCanvas');
            this.AgeCanvas = new Chart(ctx, {
              type: 'pie',
              data: {
                datasets: [
                  {
                    data: dataArrayFinal,
                    backgroundColor: bgColor,
                    name: name,
                  },
                ],
              },
              options: {
                radius: '60%',
                tooltips: {
                  callbacks: {                   
                    label: function (tooltipItem, data) {                     
                      var dataset = data.datasets[tooltipItem.datasetIndex];
                      var label =
                        dataset.data[tooltipItem.index] +
                        ' ' +
                        dataset.name[tooltipItem.index]; 
                      return label;
                    },
                  },
                },
              },
            });
          } else {
            this.age_flag = false;
          }
        },
        (error) => { }
      );
  }

  doctorWise() {
    var name = Array();
    var count = Array();
    var dataArrayFinal = Array();
    var get_doc_value = null;
    var send_data: any;
    send_data = {
      reg_id: this.user_id,
      f_date: Date_Formate(this.f_date),
      t_date: Date_Formate(this.t_date),
      hosp_id: this.hosp_id,
    };

    if (this.type == 'dietician') {
      send_data.type = this.type;
    } else if (this.userinfo.user_type == 'Admin') {
      send_data.reg_id = 'All';
    }
    console.log('doctor send data' + JSON.stringify(send_data));
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(ipaddress.getIp.toString() + 'dash/appcondoc/', send_data, {
        headers: headers,
      })
      .subscribe(
        (response) => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log('doctor wise' + JSON.stringify(obj));
          if (obj.appointments != null) {
            this.doc_flag = true;
            for (var i = 0; i < obj.appointments.length; i++) {
              name[i] = obj.appointments[i].name;
              count[i] = parseFloat(obj.appointments[i].data);
              get_doc_value = obj.appointments[i].data;
            }

            var dashsearch_array: any = [];
            for (var i = 0; i < obj.appointments.length; i++) {
              dashsearch_array.push(obj.appointments[i].data);
            }

            var Appointment_total = 0;
            for (var i = 0; i < dashsearch_array.length; i++) {
              if (Appointment_total != 0) {
                Appointment_total =
                  Appointment_total + parseInt(dashsearch_array[i]);
              } else {
                Appointment_total = parseInt(dashsearch_array[i]);
              }
            }

            if (get_doc_value != null) {
              this.totalapp1 = 'No of appointments :' + get_doc_value;
              this.doc_graph = false;
            } else {
              this.totalapp1 = 'No of appointments :' + get_doc_value;
              this.doc_graph = true;
            }

            this.totalapp2 = 'No of appointments :' + Appointment_total;
            this.totalapp3 = 'No of appointments :' + Appointment_total;
            this.totalapp4 = 'No of appointments :' + Appointment_total;    

            for (var j = 0; j < name.length; j++) {
              var temp = new Array(name[j], count[j]);
              dataArrayFinal[j] = count[j];
            }  

            if (this.docCanvas) {
              this.docCanvas.destroy();
            }
           
            var bgColor = [
              '#36a2eb',
              '#4bc0c0',
              '#ff9f40c7',
              '#ff6384',
              '#878BB6',
              '#FFEA88',
              '#FF8153',
              '#4ACAB4',
              '#c0504d',
              '#8064a2',
              '#772c2a',
              '#f2ab71',
              '#2ab881',
              '#4f81bd',
              '#2c4d75',
            ];
            var ctx = <HTMLCanvasElement>document.getElementById('docCanvas');
            this.docCanvas = new Chart(ctx, {
              type: 'pie',
              data: {
                datasets: [
                  {
                    data: dataArrayFinal,
                    backgroundColor: bgColor,
                    name: name,
                  },
                ],
              },
              options: {
                tooltips: {
                  callbacks: {                   
                    label: function (tooltipItem, data) {                     
                      var dataset = data.datasets[tooltipItem.datasetIndex];
                      var label =
                        dataset.data[tooltipItem.index] +
                        ' ' +
                        dataset.name[tooltipItem.index]; 
                      return label;
                    },
                  },
                },
              },
            });
          } else {
            this.age_flag = false;
          }
        },
        (error) => { }
      );
  }

  renderChart(dataArrayFinal) {
    var myChart = new Chart('piechart_patients', {
      type: 'pie',
      data: {
        datasets: [
          {
            label: '# of Votes',
            data: Object.values(dataArrayFinal[0]),
            backgroundColor: [
              'rgb(255, 99, 132)',
              'rgb(54, 162, 235)',
            ],
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
        plugins: {
          legend: {
            position: 'right',
          },
        },
      },
    });
  }
  get_earnings() {
    //future(not used yet)
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(
        ipaddress.getIp.toString() + 'bill/gbam',
        {
          doc_Reg_id: this.userinfo.user_id,
          from_date: Date_Formate(this.f_date),
          to_date: Date_Formate(this.t_date),
        },
        { headers: headers }
      )
      .subscribe((response) => {
        var obj = JSON.parse(JSON.stringify(response));
      });
  }

  apptypegraphView() {
    var name = Array();
    var pending_name = Array();
    var newpatient_name = Array();
    var count = Array();
    var pending_count = Array();
    var newpatient_count = Array();
    var dataArrayFinal = Array();
    this.totalapp = 0;
    this.pendingapp = 0;
    this.newpatientapp = 0;

    var send_data: any;
    if (this.userinfo.user_type == 'Admin') {
      send_data = {
        reg_id: 'All',
        fdate: Date_Formate(this.f_date),
        country: ipaddress.country_code,
        hosp_id: this.hosp_id,
      };
    } else {
      send_data = {
        reg_id: this.user_id,
        fdate: Date_Formate(this.f_date),
        country: ipaddress.country_code,
        hosp_id: this.hosp_id,
      };
    }

    if (this.type == 'dietician') send_data.type = this.type;

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(ipaddress.getIp.toString() + 'dash/appconbytypen', send_data, {
        headers: headers,
      })
      .subscribe((response) => {
        var obj = JSON.parse(JSON.stringify(response));
        if (obj.appointments != null) {
          if (obj.appointments.length == 0) {
            this.Appointment_typebase = true;
          }

          var totalpatient;
          for (var i = 0; i < obj.appointments.length; i++) {
            totalpatient = obj.appointments[i];
            if (totalpatient['total_apps'] != undefined) {
              if (totalpatient.type == '1') {
                name[i] = 'New';
              } else if (totalpatient.type == '2') {
                name[i] = 'Follow-up';
              }
              count[i] = parseFloat(totalpatient.total_apps);
            }

            //pending
            if (totalpatient['pending_apps'] != undefined) {
              if (totalpatient.type == '1') {
                pending_name.push('New');
              }
              if (totalpatient.type == '2') {
                pending_name.push('Follow-up');
              }
              pending_count.push(parseFloat(totalpatient.pending_apps));
            }

            //new
            if (totalpatient['new_clients'] != undefined) {
              if (totalpatient.type == '1') {
                newpatient_name.push('New');
              }
              if (totalpatient.type == '2') {
                newpatient_name.push('Follow-up');
              }
              newpatient_count.push(parseFloat(totalpatient.new_clients));
            }
          }

          var total = 0;
          var pending_total = 0;
          var new_total = 0;

          for (var j = 0; j < name.length; j++) {
            var temp = new Array(name[j], count[j]);
            dataArrayFinal[j] = temp;
            total = total + count[j];
          }
          this.totalapp = total;

          //pending
          dataArrayFinal = [];
          for (var j = 0; j < pending_name.length; j++) {
            var temp = new Array(pending_name[j], pending_count[j]);
            dataArrayFinal[j] = temp;
            pending_total = pending_total + pending_count[j];
          }
          this.pendingapp = pending_total;

          //new
          dataArrayFinal = [];
          for (var j = 0; j < newpatient_name.length; j++) {
            var temp = new Array(newpatient_name[j], newpatient_count[j]);
            dataArrayFinal[j] = temp;
            new_total = new_total + newpatient_count[j];
          }
          this.newpatientapp = new_total;
          this.followApp = this.totalapp - this.newpatientapp;
        }
      });
  }

  getDateFormate(d) {
    var month = (d.getMonth() + 1 < 10 ? '0' : '') + (d.getMonth() + 1);
    var day = (d.getDate() < 10 ? '0' : '') + d.getDate();
    var temp = d.getFullYear() + '-' + month + '-' + day;
    return temp;
  }

  Appointment_type_change() {
    var e;
    this.tdate = Date_Formate(this.curr_date);
    if (this.appointment_type == 'Today') {
      e = this.curr_date;
      this.from_date = e;
      this.interval = '0';
      this.range = 'today';
    } else if (this.appointment_type == 'Weekly') {
      var weekly,
        d = new Date();
      d.setDate(d.getDate() - 7);
      e = this.getDateFormate(d);
      this.from_date = e;
      this.interval = '7';
      this.range = 'weekly';
    } else if (this.appointment_type == 'Fort Night') {
      var weekly,
        d = new Date();
      d.setDate(d.getDate() - 14);
      e = this.getDateFormate(d);
      this.from_date = e;
      this.interval = '14';
      this.range = 'fort night';
    } else if (this.appointment_type == 'Monthly') {
      var weekly,
        d = new Date();
      d.setDate(d.getDate() - 30);
      e = this.getDateFormate(d);
      this.from_date = e;
      this.interval = '30';
      this.range = 'monthly';
    } else if (this.appointment_type == '3 Months') {
      var d = new Date();
      d.setDate(d.getDate() - 92);
      e = this.getDateFormate(d);
      this.from_date = e;
      this.interval = '92';
      this.range = '3 months';
    } else if (this.appointment_type == '6 Months') {
      var weekly,
        d = new Date();
      d.setDate(d.getDate() - 183);
      e = this.getDateFormate(d);
      this.from_date = e;
      this.interval = '183';
      this.range = '6 months';
    } else if (this.appointment_type == 'Yearly') {
      var weekly,
        d = new Date();
      d.setDate(d.getDate() - 365);
      e = this.getDateFormate(d);
      this.from_date = e;
      this.interval = '365';
      this.range = '12 months';
    } else {
    }

    if (this.tabtype == 'outpatient') {
      this.f_date = Date_Formate(e);
      this.apptypegraphView();
      this.speciality_chart();
      this.location_chart();
      this.gender_base();
      this.user_base();
      this.age_base();
      this.doctorWise();
      this.patient_base();

      if (this.view_type == 'tabular') {
        this.getAppointments();
      }
    } else if (this.tabtype == 'inpatient') {
      this.f_date = Date_Formate(e);
      this.getInpatient();
    } else {
      console.log('allrt');
    }
  }

  speciality_chart() {
    var name = Array();
    var count = Array();
    var dataArrayFinal = Array();
    var headers = new HttpHeaders();
    var sendata: any;
    if (this.userinfo.user_type == 'Admin') {
      sendata = {
        reg_id: 'All',
        fdate: Date_Formate(this.f_date),
        tdate: Date_Formate(this.t_date),
        spl_name: this.spl_name,
        hosp_id: this.hosp_id,
      };
    } else {
      sendata = {
        reg_id: this.user_id,
        fdate: Date_Formate(this.f_date),
        tdate: Date_Formate(this.t_date),
        spl_name: this.spl_name,
        hosp_id: this.hosp_id,
      };
    }
    headers.append('Content-Type', 'application/json');
    this.http
      .post(ipaddress.getIp.toString() + 'dash/appconspl/', sendata, {
        headers: headers,
      })
      .subscribe(
        (response) => {
          var obj = JSON.parse(JSON.stringify(response));

          if (obj.appointments != null) {
            this.spec_flag = true;
            for (var i = 0; i < obj.appointments.length; i++) {
              name[i] = obj.appointments[i].name;
              count[i] = parseFloat(obj.appointments[i].data);
              var spec_value = obj.appointments[i].data;
            }
            var d = [{ name: 'Cardiology', data: 1.0 }];
            var Appoint_total = 0;
            for (var j = 0; j < count.length; j++) {
              Appoint_total =
                Appoint_total != 0 ? Appoint_total + count[j] : count[j];
            }

            this.totalapp_00 = 'No of appointment is : ' + Appoint_total;
            this.totalapp_01 = 'No of appointment is : ' + Appoint_total;
            this.totalapp_02 = 'No of appointment is : ' + Appoint_total;

            for (j = 0; j < name.length; j++) {
              var temp = new Array(name[j], count[j]);
              dataArrayFinal[j] = count[j];
            }

            var jsonString = JSON.stringify(dataArrayFinal);
            console.log('SPECILIZATION ' + JSON.stringify(jsonString));

            if (this.specialization_canvas) {
              this.specialization_canvas.destroy();
            }

            var bgColor = [
              '#4bc0c0',
              '#ff9f40c7',
              '#ff6384',
              '#36a2eb',
              '#878BB6',
              '#FFEA88',
              '#FF8153',
              '#4ACAB4',
              '#c0504d',
              '#8064a2',
              '#772c2a',
              '#f2ab71',
              '#2ab881',
              '#4f81bd',
              '#2c4d75',
            ];
            var ctx = <HTMLCanvasElement>(
              document.getElementById('specialization_canvas')
            );
            this.specialization_canvas = new Chart(ctx, {
              type: 'pie',
              data: {
                datasets: [
                  {
                    data: dataArrayFinal,
                    backgroundColor: bgColor,
                    name: name,
                  },
                ],
              },

              options: {
                tooltips: {
                  callbacks: {
                    label: function (tooltipItem, data) {
                      var dataset = data.datasets[tooltipItem.datasetIndex];
                      var label =
                        dataset.data[tooltipItem.index] +
                        ' ' +
                        dataset.name[tooltipItem.index]; 
                      return label;
                    },
                  },
                },
              },
            });
          } else {
            this.spec_flag = false;
          }
        },
        (error) => { }
      );
  }

  getDoctors() {
    this.doctorList = [];
    var send_data = {
      country: 'IN',
      doc_reg_id: this.userinfo.user_id,
      type: this.userinfo.user_type,
      hptl_clinic_id: this.hosp_id,
    };
    console.log(
      'check---' +
      JSON.stringify({
        country: 'IN',
        doc_reg_id: this.userinfo.user_id,
        type: this.userinfo.user_type,
        hptl_clinic_id: this.hosp_id,
      })
    );
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(ipaddress.getIp + 'adm/docbyhosp', send_data, { headers: headers })
      .subscribe(
        (response) => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log('check---' + JSON.stringify(obj));
          if (obj != null) {
            for (var i = 0; i < obj.doctors.length; i++) {
              var doctorName = '';
              var fname, mname, lname;
              if (
                obj.doctors[i].middle_name != '' &&
                obj.doctors[i].middle_name != undefined
              ) {
                doctorName =
                  obj.doctors[i].first_name +
                  ' ' +
                  obj.doctors[i].middle_name +
                  ' ' +
                  obj.doctors[i].last_name;
                fname = obj.doctors[i].first_name;
                mname = obj.doctors[i].middle_name;
                lname = obj.doctors[i].last_name;
              } else {
                doctorName =
                  obj.doctors[i].first_name + ' ' + obj.doctors[i].last_name;
                fname = obj.doctors[i].first_name;
                lname = obj.doctors[i].last_name;
              }

              this.doctorList.push({
                Doc_Name: doctorName,
                Doc_ID: obj.doctors[i].prov_id,
                type: this.userinfo.user_type,
              });
            }

            this.doctorid = this.userinfo.user_id;
            this.user_id = this.userinfo.user_id;
            for (var i = 0; i < this.doctorList.length; i++) {
              if (this.doctorList[i].Doc_ID == this.userinfo.user_id) {
                this.changeDoctor();
              }
            }
          }
        },
        (error) => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      );
  }

  changeDoctor() {
    this.user_id = this.doctorid;
    if (this.tabtype == 'inpatient') {
      this.getInpatient();
      if (this.view_type == 'tabular') {
        this.getAppointments();
      }
    } else {
      this.apptypegraphView();
      this.speciality_chart();
      this.location_chart();
      this.gender_base();
      this.age_base();
      this.get_earnings();
      this.doctorWise();
      this.patient_base();
      if (this.view_type == 'tabular') {
        this.getAppointments();
      }
    }
  }

  viewChange() {
    if (this.tabtype == 'inpatient') {
      if (this.view_type == 'graph') {
        this.viewflag = false;
        this.graphchart = false;
        this.inpattabular = true;
        this.getInpatient();
      } else {
        this.viewflag = true;
        this.getAppointments();
        this.inpattabular = false;
        this.graphchart = true;
      }
    } else {
      if (this.view_type == 'graph') {
        this.viewflag = false;
        this.apptypegraphView();
        this.speciality_chart();
        this.location_chart();
        this.gender_base();
        this.user_base();
        this.age_base();
        this.doctorWise();
        this.patient_base();
      } else {
        this.viewflag = true;
        this.getAppointments();
      }
    }
  }

  getAppointments() {
    var c_date;
    this.tdate = Date_Formate(this.curr_date);
    if (this.appointment_type == 'Today') {
      c_date = this.curr_date;
    } else if (this.appointment_type == 'Weekly') {
      var d = new Date();
      d.setDate(d.getDate() - 7);
      c_date = this.getDateFormate(d);
    } else if (this.appointment_type == 'Fort Night') {
      var d = new Date();
      d.setDate(d.getDate() - 14);
      c_date = this.getDateFormate(d);
    } else if (this.appointment_type == 'Monthly') {
      var d = new Date();
      d.setDate(d.getDate() - 30);
      c_date = this.getDateFormate(d);
    } else if (this.appointment_type == '3 Months') {
      var d = new Date();
      d.setDate(d.getDate() - 92);
      c_date = this.getDateFormate(d);
    } else if (this.appointment_type == '6 Months') {
      var d = new Date();
      d.setDate(d.getDate() - 183);
      c_date = this.getDateFormate(d);
    } else if (this.appointment_type == 'Yearly') {
      var d = new Date();
      d.setDate(d.getDate() - 365);
      c_date = this.getDateFormate(d);
    }
    this.f_date = Date_Formate(c_date);

    var tdate = this.f_date.split('-');
    var fdate = this.tdate.split('-');
    this.appointmentList = [];
    var send_data = {
      f_date: fdate[2] + '-' + fdate[1] + '-' + fdate[0],
      date: tdate[2] + '-' + tdate[1] + '-' + tdate[0],
      doc_prov_id: this.user_id,
      home_care: '0',
      country: ipaddress.country_code,
      hptl_clinic_id: this.hosp_id,
      type: 'doctor',
      imei: this.userinfo.user_id,
    };
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(ipaddress.getIp + 'appointment/getdocapp', send_data, {
        headers: headers,
      })
      .subscribe(
        (response) => {
          var obj = JSON.parse(JSON.stringify(response));
          if (
            obj.appointments != null &&
            obj.appointments.length != 0 &&
            obj.appointments[0] != null
          ) {
            for (var i = 0; i < obj.appointments.length; i++) {
              var patientName = '';

              if (
                obj.appointments[i].middle_name != null &&
                encrypt_decript.Decript(obj.appointments[i].middle_name) != ''
              ) {
                patientName =
                  encrypt_decript.Decript(obj.appointments[i].first_name) +
                  ' ' +
                  encrypt_decript.Decript(obj.appointments[i].middle_name) +
                  ' ' +
                  encrypt_decript.Decript(obj.appointments[i].last_name);
              } else {
                if (obj.appointments[i].last_name != undefined) {
                  patientName =
                    encrypt_decript.Decript(obj.appointments[i].first_name) +
                    ' ' +
                    encrypt_decript.Decript(obj.appointments[i].last_name);
                } else {
                  patientName = encrypt_decript.Decript(
                    obj.appointments[i].first_name
                  );
                }
              }

              var displayPatientName = '';
              var tokenNo = '';
              var appTime = '';
              if (
                obj.appointments[i].f_time == '1' &&
                obj.appointments[i].token != null &&
                obj.appointments[i].token != undefined &&
                obj.appointments[i].token != ''
              ) {
                tokenNo = obj.appointments[i].token;
              }
              if (
                obj.appointments[i].f_time == '1' &&
                obj.appointments[i].token != null &&
                obj.appointments[i].token != undefined &&
                obj.appointments[i].token != ''
              ) {
                displayPatientName = patientName;
              } else {
                var splitAppTime = obj.appointments[i].f_time.split(':');

                if (
                  encrypt_decript
                    .Decript(obj.appointments[i].session)
                    .toString() == 'Morning'
                ) {
                  if (splitAppTime[0] == '12') {
                    appTime = splitAppTime[0] + ':' + splitAppTime[1] + ' PM';
                  } else {
                    appTime = splitAppTime[0] + ':' + splitAppTime[1] + ' AM';
                  }
                } else {
                  appTime = splitAppTime[0] + ':' + splitAppTime[1] + ' PM';
                }

                var displayPatientName = patientName;
                tokenNo = appTime;
              }

              if (obj.appointments[i].session != undefined) {
                var session = encrypt_decript.Decript(
                  obj.appointments[i].session
                );
              }
              if (
                obj.appointments[i].status == '1' ||
                obj.appointments[i].status == '2'
              ) {
                var apptOpenCount = apptOpenCount + 1;
              }
              var status_txt;
              if (obj.appointments[i].status == '0') {
                status_txt = 'Completed';
              } else if (obj.appointments[i].status == '1') {
                if (
                  obj.appointments[i].date == this.currentDate ||
                  this.currentDate < obj.appointments[i].date
                ) {
                  if (obj.appointments[i].f_time != '1') {
                    var get_time = new Date(
                      obj.appointments[i].date + ' ' + this.currentDate
                    );
                    var cur_time = new Date(
                      this.currentDate + ' ' + this.currentDate
                    );
                    if (get_time > cur_time) {
                      status_txt = 'Not visited';
                    } else {
                      status_txt = 'Open';
                    }
                  } else {
                    var get_time = new Date(obj.appointments[i].date);
                    var cur_time = new Date(this.currentDate);
                    if (get_time >= cur_time) {
                      status_txt = 'Open';
                    } else {
                      status_txt = 'Not visited';
                    }
                  }
                } else {
                  if (
                    obj.appointments[i].date > this.currentDate ||
                    obj.appointments[i].date == this.currentDate
                  ) {
                    status_txt = 'Open';
                  } else {
                    status_txt = 'Not visited';
                  }
                }
              } else if (obj.appointments[i].status == '2') {
                if (
                  obj.appointments[i].date == this.currentDate ||
                  this.currentDate < obj.appointments[i].date
                ) {
                  var get_time = new Date(
                    obj.appointments[i].date + ' ' + this.currentDate
                  );
                  var cur_time = new Date(
                    this.currentDate + ' ' + this.currentDate
                  );
                  status_txt = 'Confirmed';
                } else {
                  if (
                    obj.appointments[i].date > this.currentDate ||
                    obj.appointments[i].date == this.currentDate
                  ) {
                    status_txt = 'Open';
                  } else {
                    status_txt = 'Not visited';
                  }
                }
              }
              var package_based = obj.appointments[i].package_based == 'false' ? 'No' : 'Yes';
              this.appointmentList.push({
                profile_image: ipaddress.Ip_with_img_address + obj.appointments[i].profile_image,
                token: tokenNo,
                patient_name: displayPatientName,
                doc_app_id: obj.appointments[i].doc_app_id,
                session: session,
                patient_id: obj.appointments[i].pat_id,
                contact: encrypt_decript.Decript(obj.appointments[i].mobile),
                dr_first_name: obj.appointments[i].doc_first_name,
                dr_middle_name: obj.appointments[i].doc_middle_name,
                dr_last_name: obj.appointments[i].doc_last_name,
                app_time: appTime,
                app_date: obj.appointments[i].date,
                status: obj.appointments[i].status,
                status_txt: status_txt,
                package: package_based,
              });
            }
          }
        },
        (error) => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      );
  }
}
