<div mat-dialog-title class="popup_border_icd">
    <h5 class="header_txt">History</h5>
    <div mat-dialog-actions>
        <img src="../../../assets/img/close_icon.png" (click)="onNoClick()" width="20px" height="20px" id="close_icd" />
    </div>
</div>
<div mat-dialog-content class="diet_content">
    <div class="container">
        <div class="row"  style="max-height: 250px;">
            <div [hidden]="nodataapp" class="col-12 nodata">No history found</div>
            <!-- //Diet plan -->
            <div *ngIf="pflag" class="col-12 table-responsive" style="padding-top: 10px;padding-bottom: 10px;">
                <table id="tbl" class="table" [hidden]="DietHist_Table_Data.length==0">
                    <thead>
                        <tr>
                            <th>Break fast</th>
                            <th>Morning</th>
                            <th>Mid-day</th>
                            <th>Lunch</th>
                            <th>Evening</th>
                            <th>Dinner</th>
                            <th>Night</th>
                            <th style="width: 16%;">Date</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr *ngFor="let Diet of DietHist_Table_Data">
                            <td>{{Diet.break_fast}}</td>
                            <td>{{Diet.morning}}</td>
                            <td>{{Diet.midday}}</td>
                            <td>{{Diet.lunch}}</td>
                            <td>{{Diet.evening}}</td>
                            <td>{{Diet.dinner}}</td>
                            <td>{{Diet.night}}</td>
                            <td class="diet_histtable_date_td">{{Diet.created_date | date:'dd-MM-yyyy'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- //Tracker history -->
            <div *ngIf="diettrackerflag" class="col-12 table-responsive" style="padding-top: 10px;padding-bottom: 10px;">
                <table id="tbl" class="table" [hidden]="Trackhist_Table_Data.length==0">
                    <thead>
                        <tr>
                            <th>Break </th>
                            <th>Morning</th>
                            <th>Mid-day</th>
                            <th>Lunch</th>
                            <th>Evening</th>
                            <th>Dinner</th>
                            <th>Night</th>
                            <th>Date</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr *ngFor="let Tracker of Trackhist_Table_Data">
                            <td>{{Tracker.break_fast}}</td>
                            <td>{{Tracker.morning}}</td>
                            <td>{{Tracker.midday}}</td>
                            <td>{{Tracker.lunch}}</td>
                            <td>{{Tracker.evening}}</td>
                            <td>{{Tracker.dinner}}</td>
                            <td>{{Tracker.night}}</td>
                            <td class="diet_histtable_date_td">{{Tracker.created_date | date:'dd-MM-yyyy'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions align="end" style="width: auto;right: 19px;">
</div>