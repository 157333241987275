<div class="row">
    <!-- <div *ngIf="diagLocation" class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-4"
        style="margin-left: 9px;margin-top: 13px;">
        <mat-label class="matlabel">Diagnosis Centre
            <mat-select disableOptionCentering class="ipcss " [(ngModel)]="location">
                <mat-option [ngValue]="undefined" selected>Select</mat-option>
                <mat-option (click)="changeLocation(loc.view_data)" *ngFor="let loc of locationArray"
                    value="{{loc.view_data}}">{{loc.view_data}}</mat-option>
            </mat-select>
        </mat-label>
    </div> -->
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Patient History</h5>
                    </div>
                    <div class="headerButtons">
                        <img src="../../../assets/ui_icons/buttons/Back_button.svg" (click)="backNAV()"
                            class="saveimgbtn_inpatinfo" />
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>
                <div class="row">
                    <div class="col-12">
                        <div [hidden]='patientHistoryList.length!=0' class="nodata">No data found</div>
                        <div [hidden]='patientHistoryList.length==0'>
                            <table *ngIf="patientHistoryList.length" mdbTable datatable [dtOptions]="dtOptions"
                                class="table table-nowrap table-sm dataTable">
                                <thead>
                                    <tr>
                                        <th style="width: 20%;">Date</th>
                                        <th style="width: 20%;">Test Name</th>
                                        <th style="width: 30%;">Subtest Name</th>
                                        <th style="width: 20%;">Reading</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let pat of patientHistoryList">
                                        <td style="text-align: left;">
                                            {{pat.appointment_date}}
                                        </td>
                                        <td style="text-align: left;">{{ pat.test_name }}</td>
                                        <td style="text-align: left;">{{ pat.sub_test_name }}</td>
                                        <td style="text-align: left;">{{ pat.reading }}</td>
                                        <!-- <td style="text-align: center;">{{ app.createdDate }}</td>
                                        <td>
                                            <div style="position: relative;top: 9px;">
                                                <img width="30px" height="auto"
                                                    src="../../../assets/ui_icons/awaiting_approval.svg" />
                                                <p>Awaiting For Approval</p>
                                            </div>
                                        </td> -->
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>
