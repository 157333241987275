<div class="container-fluid">
    <app-header></app-header>
    <section>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 padding_col">
                    <mat-card id="cardcontent">
                        <mat-card-title id="card_header">
                            <span style="position: relative;top: 4px;">Search doctor
                                <img (click)="doctor_Detail_Next()" *ngIf="Nextbtn_Div" width="22px" height="auto"
                                src="../../../assets/ui_icons/buttons/Next_icon.svg" style="float: right;" />
                                <!-- <img (click)="back_Nav()" src="../../../assets/ui_icons/buttons/Back_icon.svg" width="22px"
                    height="auto" style="float: right;" /> -->
                            </span>
                        </mat-card-title>
                        <mat-card-content>
                            <div class="container-fluid">
                                <div class="row" style="margin:10px;">
                                    <div class="col-12">
                                        <div class="cover_div">
                                            <div class="header_lable">
                                                Search by location
                                            </div>
                                            <div class="content_cover">
                                                <div class="row">
                                                    <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                                                        <mat-label class="matlabel">Country</mat-label>
                                                        <select class="ipcss" [(ngModel)]="search_country"
                                                            (ngModelChange)="countryChange(search_country, '0')">
                                                            <option [ngValue]=null>select</option>
                                                            <option *ngFor="let country of clnt_country_list"
                                                                value="{{country.description}}">
                                                                {{country.description}}
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                                                        <mat-label class="matlabel">State</mat-label>
                                                        <select class="ipcss" [(ngModel)]="search_state"
                                                            (ngModelChange)="stateChange(search_state, '0')">
                                                            <option [ngValue]=null>select</option>
                                                            <option *ngFor="let state of clnt_state_list"
                                                                value="{{state.description}}">
                                                                {{state.description}}
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                                                        <mat-label class="matlabel">District / City</mat-label>
                                                        <select class="ipcss" [(ngModel)]="search_city"
                                                            (ngModelChange)="cityChange(search_city, '0')">
                                                            <option [ngValue]=null>select</option>
                                                            <option *ngFor="let city of clnt_city_list"
                                                                value="{{city.description}}">
                                                                {{city.description}}</option>
                                                        </select>
                                                    </div>
                                                    <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                                                        <mat-label class="matlabel">Location</mat-label>
                                                        <input type="text" class="ipcss length"
                                                            [(ngModel)]="search_location" name="loc_name"
                                                            (keyup)="location_change($event)" type="text"
                                                            aria-label="Number" matInput [matAutocomplete]="auto" />
                                                        <mat-autocomplete #auto="matAutocomplete">
                                                            <mat-option id="optionfont"
                                                                (click)="location_selected(location)"
                                                                *ngFor="let location of clnt_location_list"
                                                                [value]="location.description">
                                                                {{location.description}}
                                                            </mat-option>
                                                        </mat-autocomplete>
                                                    </div>
                                                    <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3"
                                                        *ngIf="type_div">
                                                        <mat-label class="matlabel">Select type</mat-label>
                                                        <select class="ipcss" name="type_hosp_doct"
                                                            [(ngModel)]="type_hosp_doct" required>
                                                            <option [ngValue]="Select">Select</option>
                                                            <option value="Doctor">Doctor</option>
                                                            <option value="Hospital">Hospital</option>
                                                        </select>
                                                    </div>
                                                    <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3"
                                                        *ngIf="type_div">
                                                        <mat-label class="matlabel">Enter minumum 3 characters
                                                        </mat-label>
                                                        <input class="ipcss" matInput required
                                                            [(ngModel)]="search_hosp_type">
                                                    </div>
                                                    <div *ngIf="homecare_show"
                                                        class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                                                        <mat-label>Homecare</mat-label>
                                                        <mat-slide-toggle [(ngModel)]="homecare_toggle"
                                                            (ngModelChange)="homecare_check(homecare_toggle)">
                                                        </mat-slide-toggle>
                                                    </div>
                                                    <div class="col-12">
                                                        <img style="float: right;" (click)="search_Doctor()"
                                                            id="add_btn"
                                                            src="../../../assets/ui_icons/buttons/search_button.svg"
                                                            class="saveimgbtn_inpatinfo" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row" style="margin:10px;" *ngIf="showTreatment_flag">
                                    <div class="col-12">
                                        <div class="cover_div">
                                            <div class="header_lable">
                                                Select treament
                                            </div>
                                            <div class="content_cover">
                                                <div class="row">
                                                    <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                                                        <mat-label class="matlabel">Treament list</mat-label>
                                                        <select class="ipcss" [(ngModel)]="treatement_obj"
                                                            (ngModelChange)="treatement_type_check(treatement_obj)">
                                                            <option disabled value="select">select</option>
                                                            <option *ngFor="let dataList of treatmentlistData" [ngValue]="dataList">
                                                                {{dataList.medicare_name}}
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3" *ngIf="showSpeciality_flag">
                                                        <mat-label class="matlabel">Speciality</mat-label>
                                                        <select class="ipcss" [(ngModel)]="SecOpnListSpl"
                                                            (ngModelChange)="speciality_click(SecOpnListSpl)">
                                                            <option disabled value="select">select</option>
                                                            <option *ngFor="let specialization of specialization_list_id"
                                                                [ngValue]="specialization">
                                                                {{specialization.specialization_name}}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row cover_div" id="specialitySection" style="margin: 10px 19px 10px 22px;" *ngIf="showDoctorList_flag">
                                    <owl-carousel-o [options]="speciality_option">
                                        <ng-template carouselSlide class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3"
                                            *ngFor="let doctor of Doctor_list; let i = index">
                                            <mat-list class="list_style boxlist" (click)="Doctor_View(doctor,i)"
                                                style="position: relative;">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="pro_image"> <img src="{{doctor.Prof_image}}"
                                                                onerror="this.src='./././assets/img/default.jpg'"
                                                                class="profile_image"></div>
                                                    </div>
                                                    <div class="col-12">
                                                        <p class="app_names">
                                                            <span>{{"Name : "}}</span>{{doctor.Doctor_Name}}
                                                        </p>
                                                    </div>
                                                    <div class="col-12" *ngIf="!hospital_div">
                                                        <p class="app_names1">{{"Hospital : "}}
                                                            <span class="span_class"
                                                                *ngFor="let hosp of doctor.Hospital">{{hosp.Hosp_name
                                                                + " - " + hosp.Hosp_Locat+" "}}</span>
                                                        </p>
                                                        <!-- <p class="app_names"><span >{{"Hospital : "}}</span><span *ngFor="let hosp of doctor.Hospital"> {{{hosp.Hosp_name + " - " + hosp.Hosp_Locat+" "}}</span> </p> -->
                                                    </div>
                                                    <div class="col-12" *ngIf="!special_div">
                                                        <p class="app_names"><span *ngIf="!screensize_based">{{"Exp : "}}</span>{{doctor.Work_Year + " years"}}</p>
                                                    </div>
                                                    <div class="col-12" *ngIf="!special_div">
                                                        <p class="app_names"><span
                                                                *ngIf="!screensize_based">{{"Speciality: "}}</span>{{doctor.Speciality}}</p>
                                                    </div>
                                                    <div class="col-12" *ngIf="!special_div">
                                                        <p class="app_names"><span *ngIf="!screensize_based">{{"Gender : "}}</span>{{doctor.gender1}}</p>
                                                    </div>
                                                </div>
                                                <img width="25" float-right height="25"
                                                    src="../../../assets/img/correct.png" item-end *ngIf="doctor.check"
                                                    style="position: absolute;top: 5px; right: 4px;" />
                                            </mat-list>
                                        </ng-template>

                                    </owl-carousel-o>
                                </div>
                                <div class="row cover_div" style="margin: 10px 19px 10px 22px;" *ngIf="showDoctorDetail_flag">
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        <div class="row">
                                            <div class="col-3 my-auto">
                                                <div style="width: fit-content; margin: auto;">
                                                    <img src="{{doc_Profile_Image}}" class="physio_profile_img"
                                                        width="110px" height="110px" />
                                                </div>
                                            </div>
                                            <div class="col-9 col-sm-9 col-md-9 col-xl-9 col-lg-9 my-auto">

                                                <div class="row">
                                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                        <mat-card id="cardcontent_name" class="clscardcontnet_name">
                                                            <mat-card-title id="cardheading">
                                                                <img src="../../../assets/img/doctor.png"
                                                                    style="margin: 11% 5px;" height="30px"
                                                                    width="30px" />
                                                            </mat-card-title>
                                                            <mat-card-content>
                                                                <div class="row" style="padding: 0 0 0 9px;">
                                                                    <div class="col-12">
                                                                        <mat-label class="matlabel"><b>Name</b>
                                                                        </mat-label>
                                                                    </div>
                                                                    <div class="col-12">
                                                                        <mat-label class="matlabel">{{doctorname}}<span
                                                                                *ngIf="!nurse_physio_div">{{" "
                                                                                +doctor_Qualify_txt}}</span></mat-label>
                                                                    </div>
                                                                    <div class="col-12">
                                                                        <mat-label class="matlabel">{{treatementName}}
                                                                            {{splityname}}</mat-label>
                                                                    </div>
                                                                    <div class="col-12">
                                                                        <mat-label class="matlabel">
                                                                            {{densub_Splityname}}
                                                                        </mat-label>
                                                                    </div>
                                                                </div>
                                                            </mat-card-content>
                                                        </mat-card>
                                                    </div>
                                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                                                        [hidden]="Homecare_div">
                                                        <mat-card id="cardcontent_name" class="clscardcontnet_name">
                                                            <mat-card-title id="cardheading">
                                                                <img src="../../../assets/img/arrow.png"
                                                                    style="margin: 41% 5px;" height="30px"
                                                                    width="30px" />
                                                            </mat-card-title>
                                                            <mat-card-content>
                                                                <div class="row" style="padding: 0 0 0 9px;">
                                                                    <div class="col-12">
                                                                        <mat-label class="matlabel"><b>Address</b>
                                                                        </mat-label>
                                                                    </div>
                                                                    <div class="col-12">
                                                                        <span *ngFor="let hosp of hospital_list_data">
                                                                            <mat-label class="matlabel">
                                                                                {{hosp.Hospname}}
                                                                            </mat-label>
                                                                            <mat-label class="matlabel">{{hosp.Address_1
                                                                                + "
                                                                                - "
                                                                                + hosp.Location}}</mat-label>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </mat-card-content>
                                                        </mat-card>
                                                    </div>
                                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                                                        *ngIf="!nurse_physio_div">
                                                        <mat-card id="cardcontent_name" class="clscardcontnet_name">
                                                            <mat-card-title id="cardheading">
                                                                <img src="../../../assets/img/graduation.png"
                                                                    style="margin: 41% 5px;" height="30px"
                                                                    width="30px" />
                                                            </mat-card-title>
                                                            <mat-card-content>
                                                                <div class="row" style="padding: 0 0 0 9px;">
                                                                    <div class="col-12">
                                                                        <mat-label class="matlabel"><b>Qualification</b>
                                                                        </mat-label>
                                                                    </div>
                                                                    <div class="col-12">
                                                                        <span
                                                                            *ngFor="let qualify of doctor_qualify_data">
                                                                            <mat-label class="matlabel">
                                                                                {{qualify.degree_name + " - "}} <span
                                                                                    *ngIf="qualify.institute != undefined">{{qualify.institute
                                                                                    + " "}}{{qualify.university + ", "
                                                                                    +qualify.graduation_year}}</span>
                                                                            </mat-label>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </mat-card-content>
                                                        </mat-card>
                                                    </div>
                                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                        <mat-card id="cardcontent_name">
                                                            <mat-card-title id="cardheading">
                                                                <img src="../../../assets/img/briefcase.png"
                                                                    style="margin: 11% 5px;" height="30px"
                                                                    width="30px" />
                                                            </mat-card-title>
                                                            <mat-card-content>
                                                                <div class="row" style="padding: 0 0 0 9px;">
                                                                    <div class="col-12">
                                                                        <mat-label class="matlabel"><b>Work
                                                                                experience</b>
                                                                        </mat-label>
                                                                    </div>
                                                                    <div class="col-12">
                                                                        <mat-label class="matlabel">{{doc_Experiance}}
                                                                        </mat-label>
                                                                    </div>
                                                                </div>
                                                            </mat-card-content>
                                                        </mat-card>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                <div class="row" style="margin: 10px;margin-right: -5px;">
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                        <div class="dig_table_overflow">
                                                            <div class="table-responsive">
                                                                <table id="card_tbl "
                                                                    *ngIf="doctor_detail_table_data.length != 0"
                                                                    class="table table-hover table-dynamic">
                                                                    <thead>
                                                                        <tr>
                                                                            <th><b>Hospital name</b></th>
                                                                            <th><b>Location</b></th>
                                                                            <th><b>Day</b></th>
                                                                            <th><b>Session</b></th>
                                                                            <th><b>Starts from</b></th>
                                                                            <th><b>Ends at</b></th>
                                                                            <th><b>Fee</b></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr
                                                                            *ngFor="let doctor of doctor_detail_table_data">
                                                                            <td data-th="Hospital Name">
                                                                                {{doctor.hospital}}
                                                                            </td>
                                                                            <td data-th="Location">{{doctor.location}}
                                                                            </td>
                                                                            <td data-th="Day">{{doctor.day}}</td>
                                                                            <td data-th="Session">{{doctor.session}}
                                                                            </td>
                                                                            <td data-th="Starts from">
                                                                                {{doctor.available_from}}
                                                                            </td>
                                                                            <td data-th="Ends at">
                                                                                {{doctor.available_to}}
                                                                            </td>
                                                                            <td data-th="Fee">{{doctor.fees}}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                                <table id="card_tbl"
                                                                    class="table table-hover table-dynamic"
                                                                    *ngIf="Homecare_Table_Data.length != 0">
                                                                    <thead>
                                                                        <tr>
                                                                            <th><b>Session</b></th>
                                                                            <th><b>Time interval</b></th>
                                                                            <th><b>Starts from</b></th>
                                                                            <th><b>Ends at</b></th>
                                                                            <th><b>Fee</b></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr *ngFor="let doctor of Homecare_Table_Data">
                                                                            <td data-th="Session">{{doctor.Session}}
                                                                            </td>
                                                                            <td data-th="Day">{{doctor.Time_Inter}}</td>
                                                                            <td data-th="Starts from">
                                                                                {{doctor.avail_from}}
                                                                            </td>
                                                                            <td data-th="Ends at">{{doctor.avail_to}}
                                                                            </td>
                                                                            <td data-th="Fee">{{doctor.Fees}}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <ng-container *ngIf="!Homecare_div">
                                                            <div class="row">
                                                                <div
                                                                    class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                                    <div class="row " style="margin-left: 11px;">
                                                                        <div
                                                                            class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
                                                                            <mat-label class="matlabel">Date<br>
                                                                                <input type="date" class="ipcss_date"
                                                                                    id="appt_date"
                                                                                    (change)="Date_click(Appointment_Date,'Appointment_Date')"
                                                                                    [(ngModel)]="Appointment_Date"
                                                                                    min={{presentdate}} #matInput>

                                                                                <!-- <mat-calendar class="ipcss" [minDate]="presentdate"
                                                                                [selected]="Appointment_Date"
                                                                                (selectedChange)="Date_click($event,'Appointment_Date')">
                                                                            </mat-calendar> -->
                                                                            </mat-label>
                                                                        </div>
                                                                        <div
                                                                            class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
                                                                            <mat-label class="matlabel">Hospital
                                                                                name<br>
                                                                                <mat-select class="ipcss "
                                                                                    [(ngModel)]="hospital_id"
                                                                                    disableOptionCentering>
                                                                                    <mat-option
                                                                                        *ngFor="let hospit of hospital_list_data"
                                                                                        value="{{hospit.hospital_id}}"
                                                                                        (click)="hospital_change(hospital_id)">
                                                                                        {{hospit.Hospname}}
                                                                                    </mat-option>
                                                                                </mat-select>
                                                                            </mat-label>
                                                                        </div>
                                                                        <div *ngIf="app_Type_Token"
                                                                            class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
                                                                            <mat-label class="matlabel"
                                                                                *ngIf="app_Type_Token">
                                                                                Visiting
                                                                                session<br>
                                                                                <mat-select required class="ipcss "
                                                                                    [(ngModel)]="visit_Session">
                                                                                    <mat-option
                                                                                        *ngFor="let session of visiting_Session_Data"
                                                                                        value="{{session.description}}"
                                                                                        (click)="visit_Session_Change(visit_Session, Appointment_Date)">
                                                                                        {{session.description}}
                                                                                    </mat-option>
                                                                                </mat-select>
                                                                            </mat-label>
                                                                        </div>
                                                                        <div *ngIf="doc_treatement_div"
                                                                            class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
                                                                            <mat-label class="matlabel"
                                                                                *ngIf="doc_treatement_div">
                                                                                Treatment Type
                                                                                <mat-select class="ipcss "
                                                                                    [(ngModel)]="treatement_type"
                                                                                    disableOptionCentering>
                                                                                    <mat-option
                                                                                        *ngFor="let medicare of doctor_Medicare_list"
                                                                                        value="{{medicare.medicare_name}}">
                                                                                        {{medicare.medicare_name}}
                                                                                    </mat-option>
                                                                                </mat-select>
                                                                            </mat-label>
                                                                        </div>
                                                                        <div *ngIf="app_Type_Token"
                                                                            class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
                                                                            <mat-label class="matlabel"
                                                                                *ngIf="app_Type_Token">
                                                                                <p *ngIf="app_Type_Token">
                                                                                    Appointment type : Token</p>
                                                                            </mat-label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                        <!-- Homecare Doctor -->
                                                        <ng-container *ngIf="Homecare_div">
                                                            <div class="row">
                                                                <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3"
                                                                    style="margin-left: 22px;">
                                                                    <mat-label class="matlabel">Date
                                                                        <input type="date" class="ipcss_date "
                                                                            id="appt_date"
                                                                            (change)="Date_click(Home_App_Date,'Home_App_Date')"
                                                                            [(ngModel)]="Home_App_Date"
                                                                            min={{presentdate}} #matInput>

                                                                        <!-- <mat-calendar class="ipcss" [minDate]="presentdate"
                                                                    [selected]="Home_App_Date"
                                                                    (selectedChange)="Date_click1($event,'Home_App_Date')"></mat-calendar> -->
                                                                    </mat-label>
                                                                </div>
                                                                <div class="col-12"
                                                                    *ngIf="time_card_flag && Home_Time_List.length != 0">
                                                                    <mat-card id="cardcontent_time">
                                                                        <mat-card-content
                                                                            style="background-color: #fff;">
                                                                            <div
                                                                                *ngFor="let session of Home_Time_List;let j=index">
                                                                                <mat-label class="appcreatenw_appttime">
                                                                                    {{session.session}}</mat-label>
                                                                                <div class="row">
                                                                                    <div class="timediv col-2"
                                                                                        *ngFor="let time of session.time;let i=index "
                                                                                        style=" margin-left: 5px;">
                                                                                        <a
                                                                                            (click)="Onappttimeclick(time,session.session,i)">
                                                                                            <p [id]="'hometime_'+j+'_'+i"
                                                                                                class="apptime">{{time}}
                                                                                            </p>
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </mat-card-content>
                                                                    </mat-card>
                                                                </div>
                                                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                                                    *ngIf="homecare == '1' && Home_Time_List.length == 0"
                                                                    style="padding-left: 28px;">
                                                                    <div class="nodata">No data found</div>
                                                                </div>
                                                                <div
                                                                    class="col-12">
                                                                    <img (click)="Add_Session()"
                                                                        *ngIf="add_session_btn_flag"
                                                                        src="../../../assets/ui_icons/buttons/add_button.svg"
                                                                        class="saveimgbtn_inpatinfo"
                                                                        style="float: right;" />
                                                                </div>
                                                            </div>
                                                            <div *ngIf="Homesessiontable_Data.length != 0"
                                                                class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                                                style="margin-top: 10px;">
                                                                <div class="table-responsive">
                                                                    <table id="card_tbl"
                                                                        class="table table-hover table-dynamic">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Date</th>
                                                                                <th>Session</th>
                                                                                <th>Time</th>
                                                                                <th>Action</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr
                                                                                *ngFor="let data of Homesessiontable_Data; let i = index">
                                                                                <td data-th="Date">{{data.Date}}</td>
                                                                                <td data-th="Session">{{data.Session}}
                                                                                </td>
                                                                                <td data-th="Time">{{data.app_time}}
                                                                                </td>
                                                                                <td data-th="Action"><a
                                                                                        (click)="Delete_Session(i)">Delete</a>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                    <div *ngIf="Available_Div" style="margin-top: 10px;"
                                                        class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-10">
                                                        <div class="row">
                                                            <div class="col-12"
                                                                *ngIf="time_card_flag && time_List_time.length !=0">
                                                                <mat-card id="cardcontent_time">
                                                                    <mat-card-content style="background-color: #fff;">
                                                                        <div
                                                                            *ngFor="let session of time_List_time;let j=index">
                                                                            <mat-label class="appcreatenw_appttime">
                                                                                {{session.session}}</mat-label>
                                                                            <div class="row">
                                                                                <div class="timediv col-2"
                                                                                    *ngFor="let time of session.time;let i=index ">
                                                                                    <a
                                                                                        (click)="Onappttimeclick(time,session.session,i)">
                                                                                        <p [id]="'time_'+j+'_'+i"
                                                                                            class="apptime">
                                                                                            {{time}}</p>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </mat-card-content>
                                                                </mat-card>
                                                            </div>
                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                                                *ngIf="homecare == '0' && time_List_time.length == 0 && typeorTime == 'time'"
                                                                style="padding-left: 28px;">
                                                                <div class="nodata">No data found</div>
                                                            </div>
                                                            <div class="col-12">
                                                                <img (click)="Add_Session()"
                                                                    *ngIf="add_session_btn_flag"
                                                                    src="../../../assets/ui_icons/buttons/add_button.svg"
                                                                    class="saveimgbtn_inpatinfo"
                                                                    style="float: right;" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="NonAvailable_Div"
                                                        class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                        <mat-label class="matlabel doctoravail">Please check doctor
                                                            availability
                                                        </mat-label>
                                                    </div>
                                                </div>

                                                <div class="col-12" *ngIf="add_session_table_list.length != 0"
                                                    style="margin: 5px 0px 5px 21px;">
                                                    <div class="table_cover">
                                                        <div class="col-12">
                                                            <div class="dig_table_overflow" style="width: 97%;">
                                                                <table class="table table-hover table-dynamic"
                                                                    id="card_tbl">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Date</th>
                                                                            <th>Session</th>
                                                                            <th>Time</th>
                                                                            <th>Action</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr
                                                                            *ngFor="let addsession of add_session_table_list;let i=index">
                                                                            <td>{{addsession.date}}</td>
                                                                            <td>{{addsession.session}}</td>
                                                                            <td>{{addsession.time}}</td>
                                                                            <td><a (click)="Delete_Session(i)"><img
                                                                                        src="../../../assets/ui_icons/buttons/trash_icon.svg"
                                                                                        class="editDelicon" /></a>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 my-auto">

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
        </div>
    </section>
    <footer>
        <p>© 2020 All rights reserved - www.tervys.com</p>
    </footer>
</div>