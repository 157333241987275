
<div *ngIf="dentalreporFlag == 'min'" class="block1_grid">
    <div class="row" style="text-decoration: none;">
        <a (click)="report_view(report.image_path)" *ngFor="let report of report_list">
        <p class=" col-12">
            <mat-label class="matlabel"><strong>{{"Test name : "}}</strong></mat-label><mat-label class="matlabel">{{report.test_name}}</mat-label>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <mat-label class="matlabel"><strong>{{"Date : "}}</strong></mat-label> <mat-label class="matlabel"> {{report.pres_date}}</mat-label>
        </p>
        </a>
    </div>
</div>
<div *ngIf="dentalreporFlag == 'max'" >

<div class="Details noavailablity">
    <div class="nodata" *ngIf="reportnodata">
        <span>No report(s) found</span>
    </div>
    <a (click)="report_view(report.image_path)" *ngFor="let report of report_list" style="text-decoration: none;">
    
    <div class="row">
        <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">    
                    <img src="{{report.image_path}}" class="thumbnailimage">
        </div>
        <div class="col-9 col-sm-9 col-md-9 col-lg-9 col-xl-9">
            <div class="row">
                <p class=" col-12">
                    <mat-label class="matlabel"><strong>{{"Test name : "}}</strong></mat-label><mat-label class="matlabel">{{report.test_name}}</mat-label>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <mat-label class="matlabel"><strong>{{"Date : "}}</strong></mat-label> <mat-label class="matlabel"> {{report.pres_date}}</mat-label>
                </p>
            </div>
            <div class="row">
                <p class="col-12">
                    <mat-label class="matlabel"><strong>{{"Observation : "}}</strong></mat-label> <mat-label class="matlabel">
                        <!-- {{report.observation}} -->
                        <quill-view-html id="viewhtml" [content]="report.observation"></quill-view-html>

                    </mat-label>
                </p>
            </div>
            <div class="row">
                <p class="col-12">
                    <mat-label class="matlabel"><strong>{{"Impression : "}}</strong></mat-label> <mat-label class="matlabel">{{report.impression}}</mat-label></p>
            </div>
          
        </div>
    
    </div>
    </a>
    
    </div>
    </div>