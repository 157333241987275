import { Component, OnInit ,ElementRef,Input,Inject,ViewEncapsulation,HostListener, ViewChild} from '@angular/core';
import { Http, Headers } from '@angular/http';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from '../../helper_class';

@Component({
  selector: 'app-second-opinion-medication-popup',
  templateUrl: './second-opinion-medication-popup.component.html',
  styleUrls: ['./second-opinion-medication-popup.component.css']
})
export class SecondOpinionMedicationPopupComponent implements OnInit {
  public med_format;
  public otc_mainlist=[];
  public vitamin_list=[];
  public reaction_list=[];
  public allergy_list=[];
  public Otc_model;

  public otc_table:boolean;
  public vitamin_table:boolean;

  public reaction_table:boolean;
  public allergic_table:boolean;

  constructor(public http:Http,public dialogRef: MatDialogRef<SecondOpinionMedicationPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public el: ElementRef, public toastr:ToastrService) { 
      dialogRef.disableClose = true;
      this.Otc_model = data;
    }

  ngOnInit(): void {
    this.otc_table=true;
    this.vitamin_table=true;
    this.reaction_table=true;
    this.allergic_table=true;
    this.Get_Medication_retrivel();
  }

  Get_Medication_retrivel() {
    this.otc_mainlist=[];
    this.vitamin_list=[];
    this.reaction_list=[];
    this.allergy_list=[];
    //Otc medicine
    var getOpnRet = Helper_Class.getOpnRet();
    if(this.Otc_model == "1"){
      this.otc_table=false;       
      this.vitamin_table=true;
      this.reaction_table=true;
      this.allergic_table=true;
      
      if (getOpnRet.otc_medicines != null) {
        for (var i = 0; i < getOpnRet.otc_medicines.length; i++) {
            if (getOpnRet.otc_medicines[i].intake_desc == "Every 6 hours") {
                this.med_format = getOpnRet.otc_medicines[i].morning;
            }else {
                this.med_format = getOpnRet.otc_medicines[i].morning + "-" + getOpnRet.otc_medicines[i].afternoon + "-" + getOpnRet.otc_medicines[i].evening;
            }
            this.otc_mainlist.push({
                medicine_name: getOpnRet.otc_medicines[i].medicine_name,
                dosage: getOpnRet.otc_medicines[i].dosage,
                side_effects: getOpnRet.otc_medicines[i].side_effects,
                intake_desc: getOpnRet.otc_medicines[i].intake_desc,
                medicine_total: this.med_format
            });
          }
      }
    }else if(this.Otc_model == "2"){
        this.vitamin_table=false;
        this.otc_table=true;
        this.reaction_table=true;
        this.allergic_table=true;
        //Vitamins or herbal 
        if (getOpnRet.vitamins != null) {
          for (var i = 0; i < getOpnRet.vitamins.length; i++) {
              if (getOpnRet.vitamins[i].intake_desc == "Every 6 hours") {
                  this.med_format = getOpnRet.vitamins[i].morning;
              }else {
                  this.med_format = getOpnRet.vitamins[i].morning + "-" + getOpnRet.vitamins[i].afternoon + "-" + getOpnRet.vitamins[i].evening;
              }
              this.vitamin_list.push({
                  supplement_name: getOpnRet.vitamins[i].supplement_name,
                  dosage: getOpnRet.vitamins[i].dosage,
                  side_effects: getOpnRet.vitamins[i].side_effects,
                  intake_desc: getOpnRet.vitamins[i].intake_desc,
                  medicine_total: this.med_format
              });
            }
        }
    }else if(this.Otc_model=="3"){
        this.reaction_table=false;
        this.vitamin_table=true;
        this.otc_table=true;
        this.allergic_table=true;
        //Reaction to medication
        if (getOpnRet.med_reaction != null) {
          for (var i = 0; i < getOpnRet.med_reaction.length; i++) {
              this.reaction_list.push({
                  supplement_name: getOpnRet.med_reaction[i].supplement_name,
                  reaction: getOpnRet.med_reaction[i].reaction,
              });
          }
        }
    }else if(this.Otc_model=="4"){//Allergic to Any Substance
      this.allergic_table=false;
      this.reaction_table=true;
      this.vitamin_table=true;
      this.otc_table=true;
        
      if (getOpnRet.allergies != null) {
        for (var i = 0; i < getOpnRet.allergies.length; i++) {
            this.allergy_list.push({
                medicine_name: getOpnRet.allergies[i].medicine_name,
                allergies: getOpnRet.allergies[i].allergies,
            });
        }
      }
    }
  }

  ClosePopup(){
    this.dialogRef.close();
  }
}
