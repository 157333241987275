<mdb-card>
    <mdb-card-header *ngIf="!cardViewFlag" class="bg-white" style="background: #fff !important;">
        <div class="headerCover">
            <div class="headerTilte app_list_header">
                <h5 class="m-0" class="mainHeadingStyle">Casesheet</h5>
            </div>
            <div class="headerButtons">
                <img src="../../../assets/ui_icons/buttons/Back_button.svg" (click)="back()" width="85px" style="    margin-right: 25px;
                " />
            </div>
        </div>
    </mdb-card-header>
    <mdb-card-body>
        <div class="Details">
            <div class="header">
            </div>
            <div class="row" style="margin: 0px;">
                <div *ngIf="cardViewFlag" id="casesheet_slider_cover">
                    <owl-carousel-o [options]="customOptions" class="sliderTwo">
                        <ng-template carouselSlide *ngFor="let user of cards;let i = index">
                            <div class="slide">
                                <div class="cu_slider_cover">
                                    <div class="card_border1" (click)="viewRecord(user, i,'card_'+i)">
                                        <div [ngSwitch]="user">
                                            <div *ngSwitchCase="'Diet Plan'">
                                                <img class="img-fluid"
                                                    src="../../../assets/images/img_new/slider/diet_plan.svg"
                                                    alt="Card image cap">
                                            </div>
                                            <div *ngSwitchCase="'Case Summary'">
                                                <img class="img-fluid"
                                                    src="../../../assets/images/img_new/slider/case_summary.svg"
                                                    alt="Card image cap">
                                            </div>
                                            <div *ngSwitchCase="'Vitals'">
                                                <img class="img-fluid"
                                                    src="../../../assets/images/img_new/slider/vitals.svg"
                                                    alt="Card image cap">
                                            </div>
                                            <div *ngSwitchCase="'Diet Tracking'">
                                                <img class="img-fluid"
                                                    src="../../../assets/images/img_new/slider/Diet_tracker.svg"
                                                    alt="Card image cap">
                                            </div>
                                        </div>
                                    </div>
                                    <a tabindex="0">
                                        <div class="mask">
                                        </div>
                                    </a>
                                    <!-- </mdb-card> -->
                                </div>
                            </div>
                        </ng-template>
                    </owl-carousel-o>
                </div>
                <div class="">
                    <div class="col-12 container-fluid p-0">
                        <div class="row">
                            <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4" [id]="'cardindex_'+i"
                                *ngFor="let user of cards;let i = index">
                                <!-- Card -->
                                <mdb-card class="mb-4 view card_border" [id]="'card_'+i" mdbwaveseffect
                                    style="height: 290px;">
                                    <mdb-card-header class="text-center primary-color white-text">
                                        <div [ngSwitch]="user">
                                            <div *ngSwitchCase="'Diet Plan'">
                                                <img class="img-fluid"
                                                    src="../../../assets/images/img_new/whiteicon/diet_plan.svg"
                                                    class="details_card" alt="">
                                            </div>
                                            <div *ngSwitchCase="'Case Summary'">
                                                <img class="img-fluid"
                                                    src="../../../assets/images/img_new/whiteicon/case_summary.svg"
                                                    class="details_card" alt="">
                                            </div>
                                            <div *ngSwitchCase="'Vitals'">
                                                <img class="img-fluid"
                                                    src="../../../assets/images/img_new/whiteicon/bmiandvitals.svg"
                                                    class="details_card" alt="">
                                            </div>
                                            <div *ngSwitchCase="'Diet Tracking'">
                                                <img class="img-fluid"
                                                    src="../../../assets/images/img_new/whiteicon/Diet_tracker_white.svg"
                                                    class="details_card" alt="">
                                            </div>
                                        </div>
                                        <h5 class="m-0"
                                            style="font-size: 14px; text-align: left;padding: 2px 0px 0px 32px;">{{
                                            user }}</h5>
                                        <div *ngIf="cardViewFlag">
                                            <a (click)="opencard(userList.patient_id,user,i,'card_'+i,false)"
                                                class="white-text">
                                                <span class="closeicon"><img src="../../../assets/img/close_icon.png"
                                                        alt="Card image cap" style="width:25px"> </span>
                                            </a>
                                        </div>
                                    </mdb-card-header>
                                    <!--Card content-->
                                    <mdb-card-body>
                                        <div *ngIf="user == 'Diet Plan'">
                                            <app-dietplan></app-dietplan>
                                        </div>
                                        <div *ngIf="user == 'Case Summary'">
                                            <app-diet-case-summary></app-diet-case-summary>
                                        </div>
                                        <div *ngIf="user == 'Vitals'">
                                            <app-diet-vitals></app-diet-vitals>
                                        </div>
                                        <div *ngIf="user == 'Diet Tracking'">
                                            <app-diet-readings></app-diet-readings>
                                        </div>
                                    </mdb-card-body>
                                    <a *ngIf="!cardViewFlag"
                                        (click)="opencard(userList.patient_id,user,i,'card_'+i,true)">
                                        <!-- <a> -->
                                        <div class="mask waves-effect waves-light" mdbWavesEffect></div>
                                    </a>
                                </mdb-card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mdb-card-body>
</mdb-card>