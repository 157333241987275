<div class="row">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Activity</h5>
                    </div>
                    <div class="headerButtons">
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>
                <div class="row" style="margin-top: 15px;">
                    <div class="col-12">
                        <div class="dite_content_cover">
                            <ul class="nav nav-tabs" id="myTab" role="tablist">
                                <li class="nav-item" role="presentation" *ngFor="let heading of chartHeadingArray; let i = index">
                                    <button class="nav-link" id="{{heading}}" data-bs-toggle="tab"
                                        attr.data-bs-target="#{{heading}}" type="button" role="tab"
                                        attr.aria-controls="{{heading}}" aria-selected="true"
                                        (click)="changeforHomeType(heading)">{{heading}}</button>
                                </li>
                            </ul>
                            <div class="tab-content" id="myTabContent">
                                <div class="row" style="margin-top: 15px;">
                                    <div class="col-6" style="max-height: 266px;overflow-y:auto;overflow-x:hidden">
                                        <mat-card class="card_chart" *ngFor="let chart of chartData" style="margin-top: 5px;">
                                            <mat-card-content>
                                                <div class="row">
                                                    <div class="col-4">
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <div style="display: grid;margin-top: 5px;">
                                                                    <p style="margin: 0;" class="d-flex justify-content-center">{{chart.date}}&nbsp;{{chart.created_time}}</p>
                                                                    <div class="d-flex justify-content-center">
                                                                        <img src="{{chart.exerc_image}}"
                                                                        onerror="this.src='../../../assets/ui_icons/cycle_icon.svg'" width="50px"
                                                                        height="auto" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-4 d-flex align-items-center">
                                                        {{chart.exercise_dur}}
                                                    </div>
                                                    <div class="col-4 d-flex align-items-center">
                                                        {{chart.kcal}} - Calories burned
                                                    </div>
                                                </div>
                                            </mat-card-content>
                                        </mat-card>
                                    </div>
                                    <div class="col-6">
                                        <div class="row">
                                            <div class="col-12">
                                                <mat-card id="" [ngStyle]="{'display':showGraph}">
                                                    <mat-card-content style="background-color: #fff;">
                                                        <canvas id="dayGraph" width="361" height="175"
                                                            style="display: block;"></canvas>
                                                    </mat-card-content>
                                                </mat-card>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>