import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from '../../helper_class';
import { MenuViewService } from '../../../app/common-module/menu-view/menu-view.service';

@Component({
  selector: 'app-outsource-payments',
  templateUrl: './outsource-payments.component.html',
  styleUrls: ['./outsource-payments.component.scss']
})
export class OutsourcePaymentsComponent implements OnInit {
  dtOptions: DataTables.Settings = {};

  constructor(public http: HttpClient, public toastr: ToastrService ,public messageservice:MenuViewService ,) { }
  public fromDate;
  public toDate;
  private tmr = new Date();
  public currentDate;
  public currDate;
  public outsourceList: any = [];
  public outsourceCenterNameList: any = [];
  public outSrcBills: any = [];
  public filtduration: string;
  public outSrcId;
  public paid_amount: number = 0;
  public invoice_number;
  public invoiceNo;
  public selectedList:any=[];
  public currenttime;
  public presentdate;

  ngOnInit(): void {
    this.filtduration = "select";
    this.getOutsourceList();
    this.getCurrentDate();



    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 25,
      "lengthMenu": [[25, 50, -1], [25, 50, "All"]],
      order: [[0, 'desc']],
      "columnDefs": [
        // { "orderable": false, "targets": [0] },
      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search invoice, date, or due date"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
  }

  // getDate() {
  //   var headers = new HttpHeaders();
  //   headers.append('Content-Type', 'application/json');
  //   this.http.post(ipaddress.getIp.toString() + 'adm/curdate', { country: ipaddress.country_code },
  //     { headers: headers })
  //     .subscribe(
  //       response => {
  //         var obj = JSON.parse(JSON.stringify(response));
  //         this.tmr = new Date(obj.current_date);
  //         this.tmr.setDate(this.tmr.getDate());
  //         this.currentDate = this.getDateMatePicker1(this.tmr);
  //         this.currDate= obj.current_date;
  //         this.fromDate = obj.current_date;
  //         this.toDate = obj.current_date;
  //         this.getInvoiceDetails();
  //       },
  //       error => {
  //         this.toastr.error(Message_data.defaultErr);
  //       });
  // }

  getDateMatePicker1(e) {
    var month = ((e.getMonth() + 1) < 10 ? '0' : '') + (e.getMonth() + 1);
    var day = (e.getDate() < 10 ? '0' : '') + e.getDate();
    var apt = e.getFullYear() + "-" + month + "-" + day;
    return apt.trim();
  }



  dateSelect(data, value) {
    if (data != null) {
      if (value == "1")
        this.fromDate = data;
      else
        this.toDate = data;
    }
  }

  getOutsourceList() {
    var diagid = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "diagnosiscontroller/gosl", { diag_center_id: diagid },
      { headers: headers }).subscribe(
        data => {
          var dataval = JSON.parse(JSON.stringify(data))
          if (dataval != undefined && dataval.outsource_list != undefined) {
            this.outsourceList = dataval.outsource_list;
            console.log("this.outsourceList", this.outsourceList)
            for (let center in this.outsourceList) {
              this.outsourceCenterNameList.push(this.outsourceList[center].centre_name)
              // console.log("this.outsourceCenterNameList", this.outsourceCenterNameList);
            }

          }
        })
  }

  getSampleList(date) {
    var durval;
    if (this.filtduration != 'select') {
      durval = this.filtduration
    }

    var send_data = {
      country: ipaddress.country_code,
      diag_center_id: Helper_Class.getInfo().hospitals[0].hptl_clinic_id,
      duration: durval,
      date: date,
    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "diagnosiscontroller/gscl", send_data, { headers: headers }).subscribe(
      data => {
        var dataval = JSON.parse(JSON.stringify(data))
        if (dataval != undefined && dataval.outsource_list != undefined) {
          this.outsourceList = dataval.outsource_list;
        }

      }, error => { });
  }

  getOutsourceBills() {
    var durval;
    if(this.filtduration !='select'){
      durval = this.filtduration
    }


    var send_data = {
      diag_center_id: Helper_Class.getInfo().hospitals[0].hptl_clinic_id,
      outsource_id: this.outSrcId,
      duration: durval,
      from_date: this.fromDate,
      to_date: this.toDate,
      country: ipaddress.country_code,
    }

    console.log("getOutsrcBills-Send data", send_data)

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "diagnosiscontroller/gobl", send_data, { headers: headers }).subscribe(
      data => {
        var dataval = JSON.parse(JSON.stringify(data))
        this.outSrcBills = dataval.out_bill_list;
        for (let bills in this.outSrcBills) {
          this.outSrcBills[bills].paid_amount = "0";
          this.outSrcBills[bills].inv_num = "";
        }
        console.log("getOutsourceBills--dataval", this.outSrcBills)

      }, error => { });
  }

  getInvoiceNo() {
    console.log(this.outSrcBills[0].isSelected);
    for (let bills in this.outSrcBills) {
      if (this.outSrcBills[bills].isSelected == true) {
        this.outSrcBills[bills].inv_num = this.invoiceNo;
      }
    }
  }

  savePayments() {
    var send_data;
    this.selectedList=[];


    for (let bills in this.outSrcBills) {
      if (this.outSrcBills[bills].isSelected == true) {
        this.selectedList.push({
          invoice_no: this.outSrcBills[bills].inv_num,
          paid_amount: this.outSrcBills[bills].paid_amount,
          paid_flag: this.outSrcBills[bills].paid_flag,
          country: ipaddress.country_code,
          billing_id: this.outSrcBills[bills].billing_id,
          fin_year: this.outSrcBills[bills].fin_year,
          outsource_id: this.outSrcBills[bills].outsource_id,
          diag_centre_id: Helper_Class.getInfo().hospitals[0].hptl_clinic_id,
        })
      }
    }

    send_data = {
      selected_bills: this.selectedList,
    }

    console.log("save_data", send_data)

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "diagnosiscontroller/sob", send_data, { headers: headers }).subscribe(
      data => {
        var response = JSON.parse(JSON.stringify(data));
        console.log(response);
        if (response.key == "1") {
          this.toastr.success("Successfully saved Outsource Payments")
        }

      }, error => { })

      this.messageservice.sendMessage("appointment");    
  }

  isAnySelected(): boolean {
    return this.outSrcBills.some(item => item.isSelected);
  }

  getCurrentDate() {
   
    var headers = new HttpHeaders();
    var date
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'adm/curdate', { country: ipaddress.country_code },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log("current time", JSON.stringify(obj))
          this.currentDate = obj.current_date;//display in html for maximum date
          this.fromDate = obj.current_date;
          this.toDate = obj.current_date;
          var currenttime1 = obj.current_time.split(":");
          this.currenttime = currenttime1[0]
          this.presentdate = obj.current_date;

          // this.updateCurrentSession(this.presentdate,this.currenttime)
          //this.getSalesData();
          console.log('presentdate1', this.presentdate)
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });

  }
}
