import { Component, OnInit, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { Time_Formate } from '../../../assets/js/common';
import { ClientViewService } from '../clientview/client-view.service';
import { Client_Helper } from '../Client_helper';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';

@Component({
  selector: 'app-pharmacy-list',
  templateUrl: './pharmacy-list.component.html',
  styleUrls: ['./pharmacy-list.component.css']
})
export class PharmacyListComponent implements OnInit {
  public sendDiagListDetails = [];
  public diagDetails;
  public diagList = [];
  public sendDiagData;
  public pharmaUrl: string;
  public sendPharmaData;
  public drugIds = [];
  public pharmacyList = [];
  public locationName: string;
  public filtState;
  public filtStateDesc;
  public filtCountry;
  public filtCity: string;
  public filtCityDesc: string;
  public zipcode: string;
  public prescriptionPresId: string;
  public relId: string;
  public relName: string;
  public subRelId: string;
  public tabletTracker = [];
  public diagTestsList;
  public diagUrl;
  public diagFlag: Boolean = false;
  public pharmaFlag: Boolean = false;
  public presId;
  public getPharmacyData;
  public clntCountryId: string;
  public clntLocationId: string;
  public clntStateId: string;
  public clntCityId: string;
  public clntLocationList = [];
  public clntCityList = [];
  public clntStateList = [];
  public clntCountryList = [];
  public cityUrl: string;
  public stateUrl: string;
  public countryUrl: string;
  public locationNameByUrl: string;
  public title: string;
  public flow;

  constructor(public clientservice: ClientViewService, public gservice: CommonDataService, public http: Http, public el: ElementRef, public toastr: ToastrService, public router: Router) {
    this.pharmaUrl = ipaddress.getIp + "pharmacontrol/allpharma";
    this.diagUrl = ipaddress.getIp + "search/diagnosis";
  }

  ngOnInit() {
    this.getPharmacyData = Client_Helper.getPharmacyList();
    this.pharmaUrl = ipaddress.getIp + "pharmacontrol/allpharma";
    this.diagUrl = ipaddress.getIp + "search/diagnosis";
    this.locationNameByUrl = ipaddress.getIp + "usercontroller/locbyname";
    this.cityUrl = ipaddress.getIp + "usercontroller/city";
    this.stateUrl = ipaddress.getIp + "usercontroller/state";
    this.countryUrl = ipaddress.getIp + "usercontroller/count";
    this.getCountries("0");
    this.clntCountryId = this.gservice.Client_login_detail_data.Country_ID;
    this.clntStateId = this.gservice.Client_login_detail_data.State_ID;
    this.clntCityId = this.gservice.Client_login_detail_data.City_ID;
    this.clntLocationId = this.gservice.Client_login_detail_data.Location_ID;
    this.filtCity = this.gservice.Client_login_detail_data.Client_City;
    this.locationName = this.gservice.Client_login_detail_data.Client_Location;
    this.prescriptionPresId = this.getPharmacyData.prescription_pres_id;
    this.relId = this.getPharmacyData.rel_id;
    this.relName = this.getPharmacyData.rel_name;
    this.subRelId = this.getPharmacyData.sub_rel_id;
    this.flow = this.getPharmacyData.flow;
    if (this.getPharmacyData.tablet_tracker != null) {
      this.tabletTracker = this.getPharmacyData.tablet_tracker
    }
    this.diagTestsList = this.getPharmacyData.diag_tests_list;
    if (this.diagTestsList != null && this.diagTestsList != undefined) {
      this.title="Diagnosis center(s)";
      this.getDiagnosisDetail();
      this.diagFlag = true;
    } else {
      this.title="Pharmacie(s)";
      this.getPharmacies();
      this.pharmaFlag = true;
    }
  }

  getPharmacies() {
    this.diagList = [];
    this.pharmacyList = [];
    this.drugIds = [];
    if (this.tabletTracker.length != 0) {
      for (var g = 0; g < this.tabletTracker.length; g++) {
        this.drugIds.push(this.tabletTracker[g].drug_id);
      }
    }
    this.sendPharmaData = {
      city: this.filtCity,
      location: this.locationName,
      drug_ids: this.drugIds
    };
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.pharmaUrl, JSON.stringify(this.sendPharmaData), { headers: headers }).subscribe(
      data => {
        this.pharmacyList = [];
        if (data.json().pharma_list[0] != undefined) {
          for (var f = 0; f < data.json().pharma_list.length; f++) {
            var phar_data = data.json().pharma_list[f];
            var address1 = "", address2 = "", location = "", city = "", zipcode = "", state = "", country = "", telephone, work_desc, work_id, avail_from, avail_to, valid, work_time;
            var home_del: boolean = false;
            var store_pick: boolean = false;
            var delopt: boolean = false;
            address1 = phar_data.address1;
            if (phar_data.address2 != null) {
              address2 = "\n" + phar_data.address2;
            }
            location = phar_data.location;
            city = phar_data.city;
            zipcode = phar_data.zipcode;
            state = phar_data.state;
            country = phar_data.country;
            if (phar_data.telephone != null) {
              telephone = "Telephone : " + phar_data.telephone;
            }
            work_id = phar_data.work_days;
            if (phar_data.home_delivery != null && phar_data.home_delivery == "1") {
              home_del = true;
              delopt = true;
            }
            if (phar_data.store_pickup != null && phar_data.store_pickup == "1") {
              store_pick = true;
              delopt = true;
            }
            work_desc = (work_id == "1") ? "Mon - Sat" : "All days";

            if (phar_data.available_from != null) {
              avail_from = phar_data.available_from;
              if (phar_data.available_to != null) {
                avail_to = phar_data.available_to;
              }
              work_time = Time_Formate(avail_from) + " - " + Time_Formate(avail_to);
            }
            if (phar_data.validity != null) {
              valid = phar_data.validity + " day(s)"
            }
            this.pharmacyList.push({
              pharma_id: phar_data.pharma_id,
              pharmacy_name: phar_data.pharmacy_name,
              phar_addr1: address1,
              phar_addr2: address2,
              phar_loc: location,
              phar_city: city,
              phar_zipcode: zipcode,
              phar_state: state,
              phar_cnty: country,
              phar_tele: telephone,
              phar_work: work_id,
              phar_word_desc: work_desc,
              phar_work_time: work_time,
              phar_avail_from: avail_from,
              phar_avail_to: avail_to,
              phar_valid: valid,
              is_home_delivery: home_del,
              is_store_pick: store_pick,
              is_del_option: delopt
            });
          }
        }
      });
  }

  getDiagnosisDetail() {
    this.diagList = [];
    this.pharmacyList = [];
    this.sendDiagData = {};
    this.sendDiagData.city = this.filtCityDesc;
    this.sendDiagData.location = this.locationName;
    this.sendDiagData.state = this.filtStateDesc;
    this.sendDiagData.country = this.filtCountry;
    if (this.diagTestsList != null && this.diagTestsList.length != 0) {
      for (var i = 0; i < this.diagTestsList.length; i++) {
        var test = this.diagTestsList[i].test;
        var temp_test = [];
        var sub_test = this.diagTestsList[i].subtest.split(",");
        for (var j = 0; j < sub_test.length; j++) {
          temp_test.push(sub_test[j]);
        }
        if (test == "Blood tests") {
          this.sendDiagData.blood_tests = temp_test;
        } else if (test == "Urine tests") {
          this.sendDiagData.urine_tests = temp_test;
        } else if (test == "Scan tests") {
          this.sendDiagData.scan_tests = temp_test;
        } else if (test == "Faeces tests") {
          this.sendDiagData.faeces_tests = temp_test;
        } else if (test == "Xray tests") {
          this.sendDiagData.xray_tests = temp_test;
        } else if (test == "Ultra-sound tests") {
          this.sendDiagData.ultrasound_tests = temp_test;
        }
      }
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.diagUrl, JSON.stringify(this.sendDiagData), { headers: headers }).subscribe(
      data => {
        this.diagDetails = data.json().centres;
        if (this.diagDetails[0].centre_id != undefined) {
          var address, telephone, website, workday, available, time_available;
          for (var i = 0; i < this.diagDetails.length; i++) {
            if (this.diagDetails[i].address2 != null && this.diagDetails[i].address2 != undefined) {
              address = (this.diagDetails[i].address1) + ', ' + (this.diagDetails[i].address2 + ', ');
            } else {
              if (this.diagDetails[i].address1 != null && this.diagDetails[i].address1 != undefined) {
                address = (this.diagDetails[i].address1) + ', ';
              }
            }
            if (this.diagDetails[i].telephone != null && this.diagDetails[i].telephone != undefined) {
              telephone = this.diagDetails[i].telephone;
            }
            if (this.diagDetails[i].website != null && this.diagDetails[i].website != undefined) {
              website = this.diagDetails[i].website;
            } else {
              website = " - ";
            }
            if (this.diagDetails[i].workday != null && this.diagDetails[i].workday != undefined) {
              workday = this.gservice.get_diabday(this.diagDetails[i].workday);
            }
            if (workday != null && workday != undefined || this.diagDetails[i].available_from != null && this.diagDetails[i].available_from != undefined &&
              this.diagDetails[i].available_to != null && this.diagDetails[i].available_to != undefined) {
              available = workday + ", " + this.gservice.get_Timeformate(this.diagDetails[i].available_from) + "- " +
                this.gservice.get_Timeformate(this.diagDetails[i].available_to)
            }
            this.diagList.push({
              center_id: this.diagDetails[i].centre_id,
              center_name: this.diagDetails[i].centre_name,
              city: this.diagDetails[i].city + "- " + this.diagDetails[i].zipcode,
              location: this.diagDetails[i].location,
              state: this.diagDetails[i].state + ", " + this.diagDetails[i].country,
              telephone: telephone,
              profile_image: ipaddress.Ip_with_img_address + this.diagDetails[i].profile_image,
              website: website,
              available: available,
              diag_tests_list: this.diagTestsList,
            })
          }
        }
      },error => {});
  }

  changeLocation(e) {
    var locat_desc = e.target.value.toString();
        this.clntLocationList = [];
        if (locat_desc != null && locat_desc.length > 2) {
            var headers = new Headers();
            headers.append('Content-Type', 'application/json');
            this.http.post(this.locationNameByUrl, JSON.stringify({
                name: locat_desc,
                city_id: this.clntCityId,
            }), { headers: headers }).subscribe(
                data => {
                    if (data.json().locations != null && data.json().locations.length != 0) {
                        this.clntLocationList = data.json().locations;
                    }
                },
                error => {
                }
            )
        } else {
            this.clntLocationList = [];
        }
  }

  viewHomeDeliveryDetails(pharma_id, pharmacy_name, phar_addr1, phar_addr2, phar_loc, phar_city, phar_zipcode, phar_state, phar_cnty, telephone) {
    Client_Helper.setstorepickup(null);
    var homedelivery = {
      pharma_id: pharma_id,
      del_type: "Home delivery",
      pharmacy_name: pharmacy_name,
      phar_addr1: phar_addr1,
      phar_addr2: phar_addr2,
      phar_loc: phar_loc,
      phar_city: phar_city,
      phar_zipcode: phar_zipcode,
      phar_state: phar_state,
      phar_cnty: phar_cnty,
      telephone: telephone,
      tablet_datas: this.tabletTracker,
      rel_id: this.relId,
      rel_name: this.relName,
      sub_rel_id: this.subRelId,
      Pres_drug_id: this.prescriptionPresId,
      pres_id: this.prescriptionPresId,
      pharmacyflow:"pharmacylist",
      flow:"pharmacylist"
    }
    var homedelivery1 = "Home delivery";
    localStorage.setItem("home", homedelivery1)
    Client_Helper.setstorepickup(homedelivery);
    this.clientservice.sendMessage('quantitychange');
  }

  viewStorePickupDetails(pharma_id, pharmacy_name, phar_addr1, phar_addr2, phar_loc, phar_city, phar_zipcode, phar_state, phar_cnty, telephone, validity, work_time) {
    Client_Helper.setstorepickup(null);
    var store = null;
    store = {
      pharma_id: pharma_id,
      del_type: "Store pickup",
      pharmacy_name: pharmacy_name,
      phar_addr1: phar_addr1,
      phar_addr2: phar_addr2,
      phar_loc: phar_loc,
      phar_city: phar_city,
      phar_zipcode: phar_zipcode,
      phar_state: phar_state,
      phar_cnty: phar_cnty,
      telephone: telephone,
      validity: validity,
      work_time: work_time,
      tablet_datas: this.tabletTracker,
      rel_id: this.relId,
      rel_name: this.relName,
      sub_rel_id: this.subRelId,
      Pres_drug_id: this.prescriptionPresId,
      pres_id: this.prescriptionPresId,
      flow:"pharmacylist"
    }
    Client_Helper.setstorepickup(store);
    this.clientservice.sendMessage('quantitychange');
  }

  viewDiagDetails(centre_id, profile_image, center_name, address, location, city,
    state, telephone, website, available, diag_tests_list) {
    this.sendDiagListDetails.push({
      center_id: centre_id,
      center_name: center_name,
      city: city,
      location: location,
      state: state,
      telephone: telephone,
      profile_image: profile_image,
      website: website,
      available: available,
      diag_tests_list: diag_tests_list,
    })
    var diagbooknow = {
      diag_list_details: this.sendDiagListDetails,
      pres_id: this.getPharmacyData.pres_id,
      flow:Client_Helper.getDiagPresList().flow,
      flag:"diagnosis",
      client_reg_id: Client_Helper.getDiagPresList().client_reg_id,
      relation_id:Client_Helper.getDiagPresList().relation_id,
      sub_rel_id:Client_Helper.getDiagPresList().sub_rel_id,
      centre_id:centre_id,     
    }
    Client_Helper.setDiagBookNow(diagbooknow);
    this.clientservice.sendMessage("diagnosis_booking");
  }

  back() {
    if( Client_Helper.getDiagPresList() != undefined){
      if(Client_Helper.getDiagPresList().flag !=  undefined && Client_Helper.getDiagPresList().flag == "diagnosis"){
        var send = {
          AppFlow:"diagdetail",
          pres_id: Client_Helper.getPharmacyList().pres_id,
          flag: Client_Helper.getPharmacyList().flag,
          client_reg_id: Client_Helper.getDiagPresList().client_reg_id,
          relation_id:Client_Helper.getDiagPresList().relation_id,
          sub_rel_id:Client_Helper.getDiagPresList().sub_rel_id      
        }
        Client_Helper.setPharmacyList(null);
        Client_Helper.setPharmacyList(send);
        this.clientservice.sendMessage("diagnosis_pres_list");
      } 
    }else if( this.flow == "order"){
      this.clientservice.sendMessage("recordwallet_medpres");
    } else{
      this.clientservice.sendMessage("pharmacyprescriptionrelation");
    }
  }

  getCountries(flag) {
    this.clntCountryList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(this.countryUrl, { headers: headers }).subscribe(
      data => {
        this.clntCountryList = [];
        if (data.json().countries != null) {
          this.clntCountryList = data.json().countries;
          for (var c = 0; c < this.clntCountryList.length; c++) {
            if (this.clntCountryList[c].country_id == this.clntCountryId) {
              this.filtCountry = this.clntCountryList[c].description;
              this.clntCountryId = this.clntCountryList[c].country_id;
              this.getStates(this.clntCountryId, flag);
              break;
            }
          }
        }
      },error => {
        this.toastr.error(Message_data.unableToFetchData);
      });
  }

  changeCountry(clnt_country, flag) {
    for (var c = 0; c < this.clntCountryList.length; c++) {
      if (this.clntCountryList[c].description == clnt_country) {
        this.filtCountry = this.clntCountryList[c].description;
        this.clntCountryId = this.clntCountryList[c].country_id;
        this.clntStateList = [];
        this.clntCityList = [];
        this.clntLocationId = undefined;
        this.locationName = "";
        this.getStates(this.clntCountryId, flag);
      }
    }
    this.filtCountry=this.clntCountryList[0].description;
    this.diagList = [];
    this.pharmacyList = [];
  }

  getStates(country, flag) {
    this.clntStateList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.stateUrl, JSON.stringify({ country_id: country }),
      { headers: headers }).subscribe(
        data => {
          if (data.json().states != null) {
            this.clntStateList = data.json().states;
            for (var i = 0; i < this.clntStateList.length; i++) {
              if (this.clntStateList[i].state_id == this.clntStateId) {
                this.clntStateId = this.clntStateList[i].state_id;
                this.filtState = this.clntStateList[i].description;
                this.getCities(this.clntStateId, flag);
                break;
              }
            }
          }
        });
  }

  changeState(clnt_state, flag) {
    for (var i = 0; i < this.clntStateList.length; i++) {
      if (this.clntStateList[i].description == clnt_state) {
        this.clntStateId = this.clntStateList[i].state_id;
        this.filtState = this.clntStateList[i].description;
        this.clntCityList = [];
        this.clntCityId = undefined;
        this.clntLocationId = undefined;
        this.locationName = "";
        this.getCities(this.clntStateId, flag);
      }
    }
    this.diagList = [];
    this.pharmacyList = [];
  }

  getCities(state, flag) {
    this.clntCityList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.cityUrl, JSON.stringify({ state_id: state }),
      { headers: headers }).subscribe(
        data => {
          if (data.json().cities != null) {
            this.clntCityList = data.json().cities;
            for (var i = 0; i < this.clntCityList.length; i++) {
              if (this.clntCityList[i].district_id == this.clntCityId) {
                this.clntCityId = this.clntCityList[i].district_id;
                this.filtCity = this.clntCityList[i].description;
                break;
              } else {
                this.filtCity = this.clntCityList[0].description;
              }
            }
          }
        });
  }

  changeCity(clnt_city, flag) {
    this.clntLocationList = [];
    for (var i = 0; i < this.clntCityList.length; i++) {
      if (this.clntCityList[i].description == clnt_city) {
        this.clntCityId = this.clntCityList[i].district_id;
        this.filtCity = this.clntCityList[i].description;
        this.clntLocationId = undefined;
        this.locationName = "";
      }
    }
    this.diagList = [];
    this.pharmacyList = [];
  }

  selectLocation(location) {
    this.locationName =location;
    for (var i = 0; i < this.clntLocationList.length; i++) {
      if (this.clntLocationList[i].location_id == location.location_id) {
          this.clntLocationId = this.clntLocationList[i].location_id;
          this.locationName = this.clntLocationList[i].description;
      }
  }
    this.clntLocationList = [];
    this.diagList = [];
    this.pharmacyList = [];
    if (this.diagTestsList != null && this.diagTestsList != undefined) {
      this.getDiagnosisDetail();
      this.diagFlag = true;
    } else {
      this.getPharmacies();
    }
  }
}
