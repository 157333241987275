import { Component, OnInit } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { MatDialog } from '@angular/material/dialog';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { FormControl } from '@angular/forms';
import { Time_Formate } from '../../../assets/js/common.js';
import { Helper_Class } from '../../helper_class';
import { DiagnosisService } from '../diagnosis/diagnosis.service';
import { Diagnosis_Helper } from '../Diagnosis_Helper';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
@Component({
  selector: 'app-diagnmosis-appointments',
  templateUrl: './diagnmosis-appointments.component.html',
  styleUrls: ['./diagnmosis-appointments.component.css']
})
export class DiagnmosisAppointmentsComponent implements OnInit {
  public userType;
  public appDate;
  public diagLocationArray = [];
  public CurrentDatetime: string;
  public clickDate;
  public diagAppListDate = [];
  public status;
  public clientName;
  public time;
  public location;
  public diagCenterID;
  public locationFlag: boolean;
  public billdate;
  public currentDate;
  //mdbTable
  dtOptions: DataTables.Settings = {};
  masterSelected: boolean = false;
  checklist: any;
  checkedList: any
  public appListArray: any = [];
  public appOpenCount: number = 0;
  diagnameList: any = [];
  hptl_clinic_id: any;
  doctorid: any;
  user_id: any;
  is_admin: boolean = false;
  diag_centre_id: any;
  daigID: any;
  daigIDAPP: any;
  constructor(public messageservice: MenuViewService, public gservice: CommonDataService, public toastr: ToastrService, public http: Http, public dialog: MatDialog) {
    this.getDate();
  }
  
  ngOnInit(): void {
    this.getDate();
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 25,
      "lengthMenu": [[25, 50, -1], [25, 50, "All"]],
      "order": [[2, 'asc']],
      "columnDefs": [
        { "orderable": false, "targets": [2, 3, 4] },
      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search patients with name, date or session "
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    if(Helper_Class.getInfo().user_type == 'Admin'){
      this.hptl_clinic_id = Helper_Class.getInfo().hptl_clinic_id;
      this.userType = Helper_Class.getInfo().user_type;
      this.diag_centre_id = Helper_Class.getInfo().diag_centre_id;
      this.daigID = Helper_Class.getInfo().diag_center_id;
      this.is_admin = true;
      this.getphysioList();
      
    }
    else{
      this.userType = Helper_Class.getInfo().user_type;
      this.diag_centre_id = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.user_id = Helper_Class.getInfo().user_id;
      this.daigID = Helper_Class.getInfo().diag_center_id;
    }
   
  }
  changephysio() {
    this.user_id = this.doctorid;
    this.getAppointment(this.currentDate);
  }
  getphysioList() {
    this.diagnameList = [];
    var send_data = {
      center_id: this.hptl_clinic_id,
      type: "4"
    };
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(ipaddress.getIp.toString() + 'adm/guth', JSON.stringify(send_data), { headers: headers })
      .subscribe(
        (response) => {
          var obj = JSON.parse(response["_body"]);
          console.log('check---' + JSON.stringify(obj));
          if (obj != null) {
            for (var i = 0; i < obj.users.length; i++) {
              var physioName = '';
              var fname, mname, lname;
              if (
                obj.users[i].middle_name != '' &&
                obj.users[i].middle_name != undefined
              ) {
                physioName =
                  obj.users[i].first_name +
                  ' ' +
                  obj.users[i].middle_name +
                  ' ' +
                  obj.users[i].last_name;
                fname = obj.users[i].first_name;
                mname = obj.users[i].middle_name;
                lname = obj.users[i].last_name;
              } else {
                physioName =
                  obj.users[i].first_name + ' ' + obj.users[i].last_name;
                fname = obj.users[i].first_name;
                lname = obj.users[i].last_name;
              }
              this.diagnameList.push({
                PhysioName: physioName,
                physiodata_id: obj.users[i].user_id,

              });
            }
          }
        },
        (error) => {

        }
      );
  }
  getDate() {
    var get_date;
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'adm/curdate',
      JSON.stringify({
        country: ipaddress.country_code
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          console.log("data",obj)
          if (obj.current_date != null) {
            this.currentDate = obj.current_date;
            this.billdate = obj.current_date;
            this.clickDate = this.currentDate;
          }
          this.getAppointment(this.currentDate);
        }, error => { });
        
  }
 

  OnDateMatePicker(e) {
    this.clickDate = e;
    this.getAppointment(this.clickDate)
  }

  getDateMatePicker(e) {
    var month = ((e.value.getMonth() + 1) < 10 ? '0' : '') + (e.value.getMonth() + 1);
    var day = (e.value.getDate() < 10 ? '0' : '') + e.value.getDate();
    var apt = e.value.getFullYear() + "-" + month + "-" + day;
    return apt.trim();
  }

  getAppointment(clickDate) {
    this.appDate = clickDate;
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'appointment/diagapps/',
      JSON.stringify({
        diag_center_id:this.diag_centre_id,
        date: this.appDate,
        imei: Helper_Class.getIPAddress(),
        prov_id:this.user_id,
        type: "diagnosis"
      }),
      { headers: headers })
      .subscribe(
        response => {
          this.diagAppListDate = [];
          var obj = JSON.parse(response["_body"]);
          console.log("final",JSON.stringify({
            diag_center_id:this.diag_centre_id,
            date: this.appDate,
            imei: Helper_Class.getIPAddress(),
            prov_id: this.user_id,
            type: "diagnosis"
          }))
          if (obj.appointments != null) {
            for (var i = 0; i < obj.appointments.length; i++) {
              this.status = "";
              this.clientName = "";
              this.time = "";
              var sessiontext = "";
              if (obj.appointments[i].middle_name != null && encrypt_decript.Decript(obj.appointments[i].middle_name) != "") {
                this.clientName = encrypt_decript.Decript(obj.appointments[i].first_name) + " " + encrypt_decript.Decript(obj.appointments[i].middle_name) + " " + encrypt_decript.Decript(obj.appointments[i].last_name);
              }
              else {
                if (obj.appointments[i].first_name != "" && obj.appointments[i].first_name != undefined) {
                  if (obj.appointments[i].last_name != "" && obj.appointments[i].last_name != undefined) {
                    this.clientName = encrypt_decript.Decript(obj.appointments[i].first_name) + " " + encrypt_decript.Decript(obj.appointments[i].last_name);
                  } else {
                    this.clientName = encrypt_decript.Decript(obj.appointments[i].first_name);
                  }
                }
              }
              if (obj.appointments[i].appointment_date != null) {
                var last_visit = obj.appointments[i].appointment_date;
                var gettmp = last_visit.split("-");
                var Date_value = gettmp[2] + "-" + gettmp[1] + "-" + gettmp[0];
              }
              if (obj.appointments[i].status == "1") {
                var get_time = new Date(obj.appointments[i].appointment_date + " " + this.CurrentDatetime);
                var cur_time = new Date((this.clickDate).toString());
                if (get_time > cur_time)
                  this.status = "Open";
                else if (get_time < cur_time)
                  this.status = " Open";
                else
                  this.status = "Open";
              }
              if (obj.appointments[i].from_time != null) {
                this.time = Time_Formate(obj.appointments[i].from_time);
                this.status = "Time alloted";
              }
              if (obj.appointments[i].status == "2") {
                this.status = "Sample collected";
              }
              if (obj.appointments[i].status == "3") {
                this.status = "Report yet to be finalized";
              }
              if (obj.appointments[i].status == "0") {
                this.status = "Closed";
              }
              if (obj.appointments[i].is_canclled == "1") {
                this.status = "Cancelled";
              }
              if (obj.appointments[i].session != undefined) {
                sessiontext = obj.appointments[i].session;
              }
              var package_based = obj.appointments[i].package_based == "false" ? "No" : "Yes";
              this.diagAppListDate.push({
                App_id: obj.appointments[i].diag_appointment_id,
                App_date: Date_value,
                Relation: obj.appointments[i].relation,
                Clnt_Name: this.clientName,
                Time: this.time,
                Session: sessiontext,
                status: this.status,
                package : package_based
              });
            }
          }
        },
        error => { });
  }
  
  viewApp(diagID, status) {
    var diagnosis = {
      diag_id: diagID,
      status_txt: status,
    }
    Helper_Class.setdiagnosisappview(diagnosis);
    this.messageservice.sendMessage("diagnosisview");
  }

  ChangeMatePicker(date) {
    var apt = new FormControl(new Date(date));
    return apt;
  }

  spiltDate(date) {
    var get_tmp = date.split("-");
    var date_appt = get_tmp[2] + "-" + get_tmp[1] + "-" + get_tmp[0];
    return date_appt;
  }

  checkUncheckAll() {
    this.checklist = this.diagAppListDate;
    if (this.appListArray != "" && this.appListArray.length != 0) {
      if (this.appListArray.length == this.appOpenCount) {
        // uncheck all
        this.appListArray = [];
        for (var i = 0; i < this.checklist.length; i++) {
          this.checklist[i].isSelected = this.masterSelected;
        }
      } else {
        //  check all with array not empty        
        this.appListArray = [];
        for (var i = 0; i < this.checklist.length; i++) {
          if (this.checklist[i].status == 'Open' || this.checklist[i].status == 'Time alloted') {
            var doc_id = this.checklist[i].doc_app_id;
            this.appListArray.push(doc_id);
            this.checklist[i].isSelected = this.masterSelected;
          }
        }
      }
    } else {
      // array empty
      for (var i = 0; i < this.checklist.length; i++) {
        if (this.checklist[i].status == 'Open' || this.checklist[i].status == 'Time alloted') {
          var doc_id = this.checklist[i].doc_app_id;
          this.appListArray.push(doc_id);
          this.checklist[i].isSelected = this.masterSelected;
        }
      }
    }
  }

  isAllSelected(appid) {
    if (this.appListArray != "" && this.appListArray.length != 0) {
      if (this.appListArray.indexOf(appid) !== -1) {
        var index = this.appListArray.indexOf(appid);
        this.appListArray.splice(index, 1);
      } else {
        this.appListArray.push(appid);
      }
    } else {
      this.appListArray.push(appid);
    }
  }

  getCheckedItemList() {
    if (this.checklist.length == this.appListArray.length) {
      this.toastr.success(Message_data.allRowSlt);
    } else if (this.appListArray.length != 0) {
      this.toastr.success('Thank you...', ``);
    } else {
      this.appListArray = `No row seleted`;
      this.toastr.success(Message_data.noRowSlt);
    }
  }

  clearAll() {
    this.checklist = this.diagAppListDate;
    for (var i = 0; i < this.checklist.length; i++) {
      this.checklist[i].isSelected = false;
    }
    this.masterSelected = false;
    this.checkedList = [];
  }

  cancel() {
    if (this.appListArray != "" && this.appListArray.length != 0) {
      var res = this.appListArray.filter(o => o != null);
      var send_data = {
        diag_cancel: res,
        type: "diagnosis",
        home_care: "0",
      }
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'appointment/cancelapp',
        JSON.stringify(send_data),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            if (obj.key != null) {
              if (obj.key == "1") {
                this.toastr.success(Message_data.appcancel);
                this.ngOnInit();
              }
            }
          }, error => {
            this.toastr.error(Message_data.defaultErr);
          });
    } else {
      this.toastr.error(Message_data.sltApptToCancel);
    }
  }
  createAppointment() {
    var send_create = {
      center_id: this.diagCenterID,
      flow: "dignosis",
    }
    Diagnosis_Helper.setCreateDiagAppt(send_create);
    this.messageservice.sendMessage("diag_app_create");
  }
  editAppointment(diagid, status){
    var diagnosis = {
      diag_id: diagid,
      status_txt: status,
      centre_id:this.diagCenterID,
      flow: "dignosis",
    }
    Helper_Class.setdiagnosisappview(diagnosis);
    Diagnosis_Helper.setCreateDiagAppt(diagnosis);
    this.messageservice.sendMessage("appedit");
  }
}
