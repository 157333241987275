import { NgModule,CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { DatePipe } from '@angular/common';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatExpansionModule} from '@angular/material/expansion';
import { RouterModule, Routes } from '@angular/router';
import {ScrollingModule} from '@angular/cdk/scrolling';

import {MatSelectModule} from '@angular/material/select';
import {MatButtonModule} from '@angular/material/button';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatDividerModule} from '@angular/material/divider';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatRadioModule} from '@angular/material/radio';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { FormsModule } from '@angular/forms'; 
import { ReactiveFormsModule } from '@angular/forms';
import {MatListModule} from '@angular/material/list';
import { ToastrModule ,ToastNoAnimation, ToastNoAnimationModule } from 'ngx-toastr';
import {MatTabsModule} from '@angular/material/tabs';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import {MatTableModule} from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import {NgxPrintModule} from 'ngx-print';
import { AngularSplitModule } from 'angular-split';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import {MatSliderModule} from '@angular/material/slider';
import {MatDialogModule} from '@angular/material/dialog';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { DataTablesModule } from 'angular-datatables';
import { CommonModule } from '@angular/common';
import {TabsModule } from 'ngx-bootstrap/tabs';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { BrowserModule } from '@angular/platform-browser';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { QuillModule } from 'ngx-quill'
import { NurseViewComponent } from './nurse-view/nurse-view.component';
import { NurseAppointmentsComponent } from './nurse-appointments/nurse-appointments.component';
import { NurseAppointmentDetailspageComponent } from './nurse-appointment-detailspage/nurse-appointment-detailspage.component';
import { NurseAssignListComponent } from './nurse-assign-list/nurse-assign-list.component';
import { NurseAssignViewComponent } from './nurse-assign-view/nurse-assign-view.component';
import { NurseDocAppListComponent } from './nurse-doc-app-list/nurse-doc-app-list.component';
import { NurseDocListComponent } from './nurse-doc-list/nurse-doc-list.component';
import { NurseProfileComponent } from './nurse-profile/nurse-profile.component';
import { MedicineTrackerComponent } from './medicine-tracker/medicine-tracker.component';
import { InpatMedicineTrackerModule } from './inpat-medicine-tracker/inpat-medicine-tracker.module';
import { NurseDocAppViewComponent } from './nurse-doc-app-view/nurse-doc-app-view.component';
import { DoctorModule } from '../Doctor_module/DoctorModule.module';
import { DiettrackernurseComponent } from './diettrackernurse/diettrackernurse.component';
// import { WebcomComponent } from './webcom/webcom.component';
import { MedicinetrackerNurseComponent } from './medicinetracker-nurse/medicinetracker-nurse.component';
import { WarstrackerInfoComponent } from './warstracker-info/warstracker-info.component';
import { ReportNurseComponent } from './report-nurse/report-nurse.component';
import { NursedietaccessComponent } from './nursedietaccess/nursedietaccess.component';
import { NurseInpatientlistDietComponent } from './nurse-inpatientlist-diet/nurse-inpatientlist-diet.component';

@NgModule({
  declarations: [
    NurseViewComponent,
    NurseAppointmentsComponent,
    NurseAppointmentDetailspageComponent,
    NurseAssignListComponent,
    NurseAssignViewComponent,
    NurseDocAppListComponent,
    NurseDocListComponent,
    NurseProfileComponent,
    MedicineTrackerComponent,
    NurseDocAppViewComponent,
    DiettrackernurseComponent,
    // WebcomComponent,
    MedicinetrackerNurseComponent,
    WarstrackerInfoComponent,
    ReportNurseComponent,
    NursedietaccessComponent,
    NurseInpatientlistDietComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatSliderModule,
    RouterModule,
    MatSelectModule,
    ScrollingModule,
    MatButtonModule,
    MatIconModule,
    MatGridListModule,
    MatDividerModule,
    MatInputModule,
    MatFormFieldModule,
    HttpClientModule,
    HttpModule,
    FormsModule,
    MatButtonToggleModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatListModule,  
    MatExpansionModule,
    MatRadioModule,
    MatTabsModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTableModule,
    NgxPrintModule,
    AngularSplitModule,
    MatCardModule,
    MatDialogModule,
    MatNativeDateModule,
    CommonModule,
    QuillModule.forRoot(),
    // EditorModule,
    DataTablesModule.forRoot(),
    MDBBootstrapModule.forRoot(),
    InpatMedicineTrackerModule,
    DoctorModule,
    ToastrModule.forRoot({
      timeOut: 1000,
      positionClass: 'toast-top-center'     
    }),
    ToastNoAnimationModule.forRoot(),
  ],
  providers: [DatePipe],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  exports:[
    NurseAppointmentsComponent,
    NurseAppointmentDetailspageComponent,
    NurseAssignListComponent,
    NurseAssignViewComponent,
    NurseDocAppListComponent,
    NurseDocListComponent,
    NurseProfileComponent,
    MedicineTrackerComponent,
    NurseDocAppViewComponent,
    DiettrackernurseComponent,
    MedicinetrackerNurseComponent,
    InpatMedicineTrackerModule,   
    MatDialogModule,MatIconModule,FormsModule,ReportNurseComponent, NursedietaccessComponent,
    NurseInpatientlistDietComponent]
})
export class NurseModule { }
