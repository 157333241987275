<div class="container-fluid g-0" style="height: 100%;">
    <div class="warpper_div">
        <div class="asied_section" #asied_section_width>
            <section id="aside_section">
                <div class="logopanel" id="logo" #logo>
                    <h1>
                        <a (click)="menuNav('dashboard');"></a>
                    </h1>
                </div>
                <div class="menu_list">
                    <!-- <div class="menu menu_active" id="aboutus"
                        (click)="menuNav('aboutus');setHomecareNursePhysio('0','');">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../assets/global/images/dashboard.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied != undefined">
                            <span>About us</span>
                        </div>
                    </div> -->
                    <div class="menu" id="appt" (click)="menuNav('appt');setHomecareNursePhysio('0','');" *ngIf="!diagApptFlag || !physioApptFlag || !docApptFlag || !dietApptFlag || !homecareFlag">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../../assets/images/clientModel/appointment_icon.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                            <span>Appointments</span><span style="float: right;padding-right: 7px;"> <i
                                    class="fa fa-angle-right"></i></span>
                        </div>
                        <ul *ngIf="apptSubmenuFlag" class="nav-children">
                            <li [hidden]="docApptFlag" (click)="menuNav('doctor');setHomecareNursePhysio('0','');"><a
                                    id="nav-a">
                                    <i class="icon-menu"><img
                                            src="../../../assets/images/clientModel/Doctor_icon.svg" /></i>&nbsp;
                                    Doctor
                                </a></li>
                            <li [hidden]="diagApptFlag" (click)="menuNav('diagnosis');setHomecareNursePhysio('0','');">
                                <a id="nav-a">
                                    <!-- <i class="fa fa-angle-right"></i> -->
                                    <i class="icon-menu"><img   src="../../../assets/images/clientModel/diagnosis_icon.svg" /></i>&nbsp;
                                    Diagnosis
                                </a>
                            </li>
                            <li [hidden]="physioApptFlag"
                                (click)="menuNav('physio');setHomecareNursePhysio('0','physio');"><a id="nav-a">
                                    <!-- <i class="fa fa-angle-right"></i> -->
                                    <i class="icon-menu"><img src="../../../assets/images/clientModel/physio_icon.svg" /></i>&nbsp;
                                    Physio
                                </a></li>
                                <li [hidden]="dietApptFlag"
                                (click)="menuNav('dietician');setHomecareNursePhysio('0','dietician');"><a id="nav-a">
                                    <!-- <i class="fa fa-angle-right"></i> -->
                                    <i class="icon-menu"><img src="../../../assets/images/clientModel/Dietitian_icon.svg" /></i>&nbsp;Dietician
                                </a>
                            </li>
                            <li [hidden]="homecareFlag" (click)="menuNav('homecare');"
                                *ngIf="!minimizedAsied  && minimizedAsied !== undefined"><a>
                                    <!-- <i class="fa fa-angle-right"></i>  -->
                                    <i class="icon-menu"><img src="../../../assets/images/clientModel/home_care_icon.svg" /></i>&nbsp;Homecare
                                </a></li>
                            <ul *ngIf="hmSubmenuFlag" class="nav-children" style="left: 25px !important;top: 0;">
                                <li (click)="menuNav('doctor');setHomecareNursePhysio('1','');"><a id="nav-a">
                                        <i class="icon-menu">
                                            <img src="../../../assets/images/clientModel/Doctor_icon.svg" /></i>&nbsp; Doctor</a></li>
                                <li (click)="menuNav('physio');setHomecareNursePhysio('1','physio');"><a id="nav-a">
                                        <!-- <i class="fa fa-angle-right"></i>  -->
                                        <i class="icon-menu"><img src="../../../assets/images/clientModel/physio_icon.svg" /></i>&nbsp; Physiotherapist</a>
                                </li>
                                <li (click)="menuNav('nurse');setHomecareNursePhysio('1','nurse');">
                                    <a id="nav-a"><i class="icon-menu"><img src="../../../assets/images/clientModel/physio_icon.svg" /></i>&nbsp; Nurse</a></li>
                            </ul>
                        </ul>
                    </div>
                    <div [hidden]="secopnFlag" class="menu" id="secondopinionlist" (click)="menuNav('secondopinionlist');setHomecareNursePhysio('0','');setSecondOpinion('second_opinion')">
                        <div class="menu_icon"> <i class="icon-menu"><img src="../../../assets/images/clientModel/second_opinon_icon.svg" /></i></div><div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined"> <span>Second Opinion</span><span style="float: right;padding-right: 7px;"> <i class="fa fa-angle-right"></i></span></div>
                    </div>
                    <div class="menu" id="vital" (click)="menuNav('vital');" [hidden]="vitalsFlag">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../../assets/images/clientModel/vitals_icon.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                            <span>Vital measurements</span><span style="float: right;padding-right: 7px;"> <i
                                    class="fa fa-angle-right"></i></span>
                        </div>
                    </div>
                    <div [hidden]="recordWalletFlag" class="menu" id="recordwallet" (click)="menuNav('recordwallet');setHomecareNursePhysio('0','');setSecondOpinion('recordwallet')">
                        <div class="menu_icon"> <i class="icon-menu"><img src="../../../assets/images/clientModel/record_wallet.svg" /></i></div><div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined"> <span>Lab Reports</span><span style="float: right;padding-right: 7px;"> <i class="fa fa-angle-right"></i></span></div>
                    </div>
                    <div class="menu" id="wellness" (click)="menuNav('wellness');setHomecareNursePhysio('0','');" *ngIf="!activitiesFlag && !dietFlag && !mindfulFlag">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../../assets/images/clientModel/wellness_icon.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                            <span>Know your Health</span><span style="float: right;padding-right: 7px;"> <i
                                    class="fa fa-angle-right"></i></span>
                        </div>
                        <ul *ngIf="wellnessSubmenuFlag" class="nav-children">
                            <li [hidden]="activitiesFlag" (click)="menuNav('exercise');setHomecareNursePhysio('0','');"
                                *ngIf="!minimizedAsied  && minimizedAsied !== undefined"><a id="nav-a">
                                    <i class="icon-menu"><img
                                            src="../../../assets/images/clientModel/activities_icon.svg" /></i>&nbsp;
                                    Activities</a></li>
                            <ul *ngIf="activitySubmenuFlag" class="nav-children" style="left: 25px !important;top: 0;">
                                <li (click)="menuNav('exercise_plan');"><a id="nav-a">
                                    <i class="icon-menu"><img
                                        src="../../../assets/images/clientModel/activities_plan.svg" /></i>&nbsp;
                                        Plan</a></li>
                                <li (click)="menuNav('exercise_tracker');"><a id="nav-a">
                                    <i class="icon-menu"><img
                                        src="../../../assets/images/clientModel/activities_tracker.svg" /></i>&nbsp;
                                            Tracker</a></li>
                            </ul>
                            <li [hidden]="dietFlag" (click)="menuNav('lifestyle');setHomecareNursePhysio('0','');"
                                *ngIf="!minimizedAsied  && minimizedAsied !== undefined"><a id="nav-a">
                                    <i class="icon-menu">
                                        <img src="../../../assets/images/clientModel/Diet_icon.svg" /></i>&nbsp;Diet</a></li>
                            <ul *ngIf="lifestyleSubmenuFlag" class="nav-children" style="left: 25px !important;top: 0;">
                                <li (click)="menuNav('diet_paln');">
                                    <a id="nav-a"><i class="icon-menu"><img src="../../../assets/images/clientModel/Diet_plan.svg" /></i>&nbsp;
                                        Plan</a></li>
                                <li (click)="menuNav('diet_tracker');">
                                    <a id="nav-a"> <i class="icon-menu"><img src="../../../assets/images/clientModel/diet_tracker_icon.svg" /></i>&nbsp;Tracker</a>
                                </li>
                            </ul>
                            <li [hidden]="mindfulFlag" (click)="menuNav('mindfulness');setHomecareNursePhysio('0','');">
                                <a id="nav-a"><i class="icon-menu"><img src="../../../assets/images/clientModel/mindfulness_icon.svg" /></i>&nbsp;
                                    <span>Mindfulness</span></a>
                            </li>
                        </ul>
                    </div>
                    <div class="menu" id="ehr" (click)="menuNav('ehr');setHomecareNursePhysio('0','');" *ngIf="!healthFlag && !postCovidFlag && !mydocFlag">
                        <div class="menu_icon"> <i class="icon-menu"><img src="../../../assets/images/clientModel/EHR_icon.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                            <span>EHR</span><span style="float: right;padding-right: 7px;"> <i
                                    class="fa fa-angle-right"></i></span>
                        </div>
                        <ul *ngIf="ehrSubmenuFlag" class="nav-children">
                            <!-- <li [hidden]="recordWalletFlag"
                                (click)="menuNav('recordwallet');setHomecareNursePhysio('0','');"><a id="nav-a">
                                    <i class="icon-menu"><img  src="../../../assets/images/clientModel/record_wallet.svg" /></i>&nbsp;
                                    Record Wallet</a></li> -->
                            <li [hidden]="healthFlag" (click)="menuNav('healthdata');setHomecareNursePhysio('0','');">
                                <a id="nav-a"><i class="icon-menu"><img src="../../../assets/images/clientModel/health_data.svg" /></i>&nbsp;
                                    Health data</a></li>
                            <li [hidden]="postCovidFlag" (click)="menuNav('postcovid');setHomecareNursePhysio('0','');">
                                <a id="nav-a"><i class="icon-menu"><img src="../../../assets/images/clientModel/Covid_icon.svg" /></i>&nbsp;
                                    Post COVID</a>
                            </li>
                            <li [hidden]="mydocFlag" (click)="menuNav('documentlist');setHomecareNursePhysio('0','');">
                                <a id="nav-a"><i class="icon-menu"><img src="../../../assets/images/clientModel/documents_icon.svg" /></i>&nbsp; Documents</a>
                            </li>
                        </ul>
                    </div>
                    <div [hidden]="pharmaFlag" class="menu" id="pharmacy"
                        (click)="menuNav('pharmacy');setHomecareNursePhysio('0','');">
                        <div class="menu_icon"><i class="icon-menu"><img
                                    src="../../../assets/images/clientModel/pharmacy_icon.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                            <span>Pharmacy</span><span style="float: right;padding-right: 7px;"> <i
                                    class="fa fa-angle-right"></i></span>
                        </div>
                        <ul *ngIf="pharmaSubmenuFlag" class="nav-children">
                            <li (click)="menuNav('clientpharmacycurrent');"><a id="nav-a">
                                <i class="icon-menu"><img src="../../../assets/images/clientModel/Pharmacy_current.svg" /></i>&nbsp;Current</a></li>
                            <li (click)="menuNav('clientpharmacycompleted');"><a>
                                <i class="icon-menu"><img src="../../../assets/images/clientModel/Pharmacy_completed.svg" /></i>&nbsp;Completed</a></li>
                        </ul>
                    </div>
                    
                    <div [hidden]="medFlag" class="menu" id="medicine"
                        (click)="menuNav('medicine_tracker');setHomecareNursePhysio('0','');">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../../assets/images/clientModel/medicine_tracker_icon.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                            <span>Medicine</span><span style="float: right;padding-right: 7px;"> <i
                                    class="fa fa-angle-right"></i></span>
                        </div>
                    </div>
                    <div [hidden]="relationFlag" class="menu" id="relations"
                        (click)="menuNav('relations');setHomecareNursePhysio('1','');">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../../assets/images/clientModel/relation_icon.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                            <span>Relations</span><span style="float: right;padding-right: 7px;"> <i
                                    class="fa fa-angle-right"></i></span>
                        </div>
                    </div>
                    <div [hidden]="billingFlag" class="menu" id="billlist"
                        (click)="menuNav('billlist');setHomecareNursePhysio('0','');">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../../assets/images/clientModel/billing_icon.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                            <span>Bill List</span><span style="float: right;padding-right: 7px;"> <i
                                    class="fa fa-angle-right"></i></span>
                        </div>
                    </div>
                    <div [hidden]="blooddonFlag" class="menu" id="blood" (click)="menuNav('blood');">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../../assets/images/clientModel/blood_donation_icon.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined"> <span>Blood
                                donation</span><span style="float: right;padding-right: 7px;"> <i
                                    class="fa fa-angle-right"></i></span></div>
                        <ul *ngIf="schduleSubmenuFlag" class="nav-children">
                            <li (click)="menuNav('bloodrequest');"><a id="nav-a">
                                    <i class="icon-menu"><img src="../../../assets/images/clientModel/Blood_donation_request.svg" /></i>&nbsp; Request</a></li>
                            <li (click)="menuNav('donors');"><a>
                                    <i class="icon-menu"><img src="../../../assets/images/clientModel/Blood_donation_update.svg" /></i>&nbsp;
                                    Update</a></li>
                            <li (click)="menuNav('bloodeligibility');"><a>
                                    <i class="icon-menu"><img src="../../../assets/images/clientModel/Blood_donation_eligibility.svg" /></i>&nbsp;
                                    Eligibility criteria</a></li>
                            <li (click)="menuNav('donationhistory');"><a>
                                    <i class="icon-menu"><img src="../../../assets/images/clientModel/Blood_donation_donation_history.svg" /></i>&nbsp; Donation History</a></li>
                        </ul>
                    </div>
                    
                    <div class="menu" id="readings" (click)="menuNav('readings');" [hidden]="recordWalletFlag">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../../assets/images/clientModel/vitals_icon.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                            <span>Readings</span><span style="float: right;padding-right: 7px;"> <i
                                    class="fa fa-angle-right"></i></span>
                        </div>
                    </div>
                    <div class="menu" id="settings" (click)="menuNav('settings');">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../../assets/images/clientModel/settings_icon.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                            <span>Settings</span><span style="float: right;padding-right: 7px;"> <i
                                    class="fa fa-angle-right"></i></span>
                        </div>
                        <ul *ngIf="settingSubmenuFlag" class="nav-children">
                            <li (click)="menuNav('features');"><a id="nav-a">
                                    <i class="icon-menu"><img  src="../../../assets/images/clientModel/features_icon.svg" /></i>&nbsp; Features</a></li>
                        </ul>
                    </div>
                    <div class="menu" id="packages" (click)="menuNav('packages');">
                        <div class="menu_icon"> <i class="icon-menu">
                            <img src="../../../assets/images/clientModel/Notification_icon.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                            <span>Health packages</span><span style="float: right;padding-right: 7px;"> <i
                                    class="fa fa-angle-right"></i></span>
                        </div>
                    </div>
                    <div class="menu" id="notification" (click)="menuNav('notification');">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../../assets/images/clientModel/Notification_icon.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                            <span>Notification</span><span style="float: right;padding-right: 7px;"> <i
                                    class="fa fa-angle-right"></i></span>
                        </div>
                    </div>
                    <div class="menu" id="clienthelp" (click)="menuNav('clienthelp');">
                        <div class="menu_icon"> <i class="icon-menu"><img
                                    src="../../../assets/images/clientModel/help_icon.svg" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                            <span>Help</span><span style="float: right;padding-right: 7px;"> <i
                                    class="fa fa-angle-right"></i></span>
                        </div>
                    </div>
                    <div [hidden]="reference_video" class="menu" id="reference_video" (click)="menuNav('reference_video');">
                        <div class="menu_icon"> <i class="icon-menu"><img src="../../assets/global/images/reference_video.png" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined"> <span> Reference video</span><span style="float: right;padding-right: 7px;"> <i class="fa fa-angle-right"></i></span></div>
                    </div>
                    <div class="menu" id="reference_video" (click)="menuNav('listRecepies');">
                        <div class="menu_icon"> <i class="icon-menu"><img src="../../assets/global/images/reference_video.png" /></i></div>
                        <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined"> <span> Recepies </span><span style="float: right;padding-right: 7px;"> <i class="fa fa-angle-right"></i></span></div>
                    </div>
                </div>
            </section>
        </div>
        <div class="right_content_section" #right_section_width>
            <section id="headar_section">
                <div class="header-left" style="position: relative;">
                    <div class="topnav">
                        <div class="openbtn_desktop" (click)="minimizing()"><i class="fas fa-bars"></i></div>
                        <div class="openbtn" (click)="openMenumodel()"><i class="fas fa-bars"></i></div>
                        <div class="hospital_logo">
                        </div>
                        <div class="hospital_name flexClass">
                        </div>
                        <div class="doctor_name flexClass" data-bs-toggle="modal" data-bs-target="#exampleModal"> {{firstName}}&nbsp;{{lastName}}</div>
                        <div class="doctor_profile_pc flexClass"><img src="{{profileImg}}"
                                onerror="this.src='./././assets/img/default.jpg'"> </div>
                        <!-- <div class="share flexClass"><i class="far fa-paper-plane"></i></div> -->
                        <div class="bell flexClass"><i class="far fa-bell"></i></div>
                        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                            aria-hidden="true">
                            <div class="modal-dialog profile_dialog">
                                <div class="modal-content">
                                    <div class="modal-header" style="display: inline-block;text-align: center">
                                        <div class="modal-title" id="exampleModalLabel"><img class="model_profile"
                                                src="{{profileImg}}" onerror="this.src='./././assets/img/default.jpg'">
                                        </div>
                                    </div>
                                    <div class="modal-body">
                                        <div class="menu_div" (click)="MenuModelAction('profile');"> <i
                                                class="far fa-user"></i> <span class="header_model_menu">My
                                                Profile</span> </div>
                                        <div class="menu_div" (click)="MenuModelAction('changePassword');"><i
                                                class="fas fa-cog"></i><span class="header_model_menu">Change
                                                Password</span></div>
                                        <div class="menu_div" (click)="MenuModelAction('logout');"
                                            style="border-bottom: none;"><i class="fas fa-sign-out-alt"></i><span
                                                class="header_model_menu">Log Out</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="body_section">
                <div class="body_warpper">
                    <div class="card shadow-lg">
                        <div [ngSwitch]="displayPage">
                            <div *ngSwitchCase="'dashboard'" >
                                <app-client-dashboard></app-client-dashboard>
                            </div>
                            <div *ngSwitchCase="'home'">
                                <app-clienthome></app-clienthome>
                            </div>
                            <div *ngSwitchCase="'relations'">
                                <app-relationlist></app-relationlist>
                            </div>
                            <div *ngSwitchCase="'relationdetails'">
                                <app-relationdetails></app-relationdetails>
                            </div>
                            <div *ngSwitchCase="'clientpharmacycurrent'">
                                <app-pharmacycurrent></app-pharmacycurrent>
                            </div>
                            <div *ngSwitchCase="'clientpharmacycompleted'">
                                <app-pharmacy-completed></app-pharmacy-completed>
                            </div>
                            <div *ngSwitchCase="'pharmacyorderdetailview'">
                                <app-pharmacyorderdetailview></app-pharmacyorderdetailview>
                            </div>
                            <div *ngSwitchCase="'pharmacyprescriptionrelation'">
                                <app-pharmacyrelationprescriptiondetails></app-pharmacyrelationprescriptiondetails>
                            </div>
                            <div *ngSwitchCase="'PharmacyorderComponent'">
                                <app-pharmacyorder></app-pharmacyorder>
                            </div>
                            <div *ngSwitchCase="'Pharmacyordercancel'">
                                <app-pharmacyorderdetailcancel></app-pharmacyorderdetailcancel>
                            </div>
                            <div *ngSwitchCase="'pharmacyorderlist'">
                                <app-pharmacy-list></app-pharmacy-list>
                            </div>
                            <div *ngSwitchCase="'quantitychange'">
                                <app-quantity-change></app-quantity-change>
                            </div>
                            <div *ngSwitchCase="'documentlist'">
                                <app-document-list></app-document-list>
                            </div>
                            <div *ngSwitchCase="'uploaddocs'">
                                <app-upload-documents></app-upload-documents>
                            </div>
                            <div *ngSwitchCase="'billlist'">
                                <app-client-bill-list></app-client-bill-list>
                            </div>
                            <div *ngSwitchCase="'clientbilldetailview'">
                                <app-client-bill-detail-view></app-client-bill-detail-view>
                            </div>
                            <div *ngSwitchCase="'recordwallet_medpres'">
                                <app-medicalprescription-details></app-medicalprescription-details>
                            </div>
                            <!-- recored wallet page -->
                            <div *ngSwitchCase="'recordwallet'">
                                <app-record-wallet-new></app-record-wallet-new>
                            </div>
                            <div *ngSwitchCase="'casesheet'">
                                <app-casesheet></app-casesheet>
                            </div>
                            <div *ngSwitchCase="'secondopinionlist'">
                                <app-second-opinion-list></app-second-opinion-list>
                            </div>
                            <div *ngSwitchCase="'doctorlist'">
                                <app-doctorlist></app-doctorlist>
                            </div>
                            <div *ngSwitchCase="'appnewsecondopinion'">
                                <app-appcreate></app-appcreate>
                            </div>
                            <div *ngSwitchCase="'secondopinioncreate'">
                                <app-secondopinioncreate></app-secondopinioncreate>
                            </div>
                            <div *ngSwitchCase="'secondopinionretrival'">
                                <app-secondopinionretrival></app-secondopinionretrival>
                            </div>
                            <div *ngSwitchCase="'bloodrequest'">
                                <app-bloodrequest></app-bloodrequest>
                            </div>
                            <div *ngSwitchCase="'finddonors'">
                                <app-finddonors></app-finddonors>
                            </div>
                            <div *ngSwitchCase="'donors'">
                                <app-donors></app-donors>
                            </div>
                            <div *ngSwitchCase="'bloodeligibility'">
                                <app-bloodeligibility></app-bloodeligibility>
                            </div>
                            <div *ngSwitchCase="'notification'">
                                <app-client-notification></app-client-notification>
                            </div>
                            <div *ngSwitchCase="'notificationview'">
                                <app-client-notification-view></app-client-notification-view>
                            </div>
                            <div *ngSwitchCase="'clienthelp'">
                                <app-client-help></app-client-help>
                            </div>
                            <div *ngSwitchCase="'donordeatil'">
                                <app-donor-detail></app-donor-detail>
                            </div>
                            <div *ngSwitchCase="'DonorUpdateDetailPage'">
                                <app-donor-updatedetails></app-donor-updatedetails>
                            </div>
                            <div *ngSwitchCase="'donationhistory'">
                                <app-donationhistory></app-donationhistory>
                            </div>
                            <div *ngSwitchCase="'postcovid'">
                                <app-postcovid-track></app-postcovid-track>
                            </div>
                            <div *ngSwitchCase="'healthdata'">
                                <app-lifestyle-personal></app-lifestyle-personal>
                            </div>
                            <div *ngSwitchCase="'medicine'">
                                <app-lifestyle-tracker></app-lifestyle-tracker>
                            </div>
                            <div *ngSwitchCase="'diet'">
                                <app-lifestyle-tracker></app-lifestyle-tracker>
                            </div>
                            <div *ngSwitchCase="'medicine_tracker'">
                                <app-medicine-track></app-medicine-track>
                            </div>
                            <div *ngSwitchCase="'diet_tracker'">
                                <!-- <app-diet-tracker></app-diet-tracker> -->
                                <app-new-diet-tracker></app-new-diet-tracker>
                            </div>
                            <div *ngSwitchCase="'doctor'">
                                <app-clientapplist></app-clientapplist>
                            </div>
                            <div *ngSwitchCase="'doctor_apptdetails'">
                                <app-doctor-appointment-detail></app-doctor-appointment-detail>
                            </div>
                            <div *ngSwitchCase="'doctor_appcreate'">
                                <app-app-create-new></app-app-create-new>
                            </div>
                            <div *ngSwitchCase="'diagnosis'">
                                <app-diagnosis-appointment-list></app-diagnosis-appointment-list>
                            </div>
                            <div *ngSwitchCase="'diagnosis_detalied_view'">
                                <app-diagnosis-detailed-view></app-diagnosis-detailed-view>
                            </div>
                            <div *ngSwitchCase="'diagnosis_appcreate'">
                                <app-diagnosis-create-new></app-diagnosis-create-new>
                            </div>
                            <div *ngSwitchCase="'diagnosis_pres_list'">
                                <app-diagnosis-preslist-detail></app-diagnosis-preslist-detail>
                            </div>
                            <div *ngSwitchCase="'diagnosis_pharmacy_list'">
                                <app-diagnosis-pharmcay-list></app-diagnosis-pharmcay-list>
                            </div>
                            <div *ngSwitchCase="'diagnosis_booking'">
                                <app-diagnosis-booking></app-diagnosis-booking>
                            </div>
                            <div *ngSwitchCase="'physio'">
                                <app-physio-appointment-list></app-physio-appointment-list>
                            </div>
                            <div *ngSwitchCase="'nurse'">
                                <app-physio-appointment-list></app-physio-appointment-list>
                            </div>
                            <div *ngSwitchCase="'physio_appointment_detailed_view'">
                                <app-physio-appointment-details></app-physio-appointment-details>
                            </div>
                            <div *ngSwitchCase="'physio_appcreate_new'">
                                <app-physio-appcreate-new></app-physio-appcreate-new>
                            </div>
                            <div *ngSwitchCase="'physio_doctor_list'">
                                <app-physio-doctor-list></app-physio-doctor-list>
                            </div>
                            <div *ngSwitchCase="'physio_doctor_detail'">
                                <app-physio-doctor-detail></app-physio-doctor-detail>
                            </div>
                            <!-- <div *ngSwitchCase="'recordwallet_medpres'">
                                <app-medicalprescription-details></app-medicalprescription-details>
                            </div> -->
                            <div *ngSwitchCase="'report_details'">
                                <app-report-details></app-report-details>
                            </div>
                            <div *ngSwitchCase="'physio_appointment_conform'">
                                <app-physio-doctor-appointment-conform></app-physio-doctor-appointment-conform>
                            </div>
                            <div *ngSwitchCase="'client_nurse_appcreate_new'">
                                <app-client-nurse-appcreate-new></app-client-nurse-appcreate-new>
                            </div>
                            <div *ngSwitchCase="'doctor_appointment_conform'">
                                <app-doctor-appointment-conform></app-doctor-appointment-conform>
                            </div>
                            <div *ngSwitchCase="'exercise_plan'">
                                <app-exercise></app-exercise>
                            </div>
                            <div *ngSwitchCase="'exercise_tracker'">
                                <app-tracking-exercise></app-tracking-exercise>
                            </div>
                            <div *ngSwitchCase="'mindfulness'">
                                <app-mindfulness></app-mindfulness>
                            </div>
                            <div *ngSwitchCase="'diet_paln'">
                                <app-new-diet-tracker></app-new-diet-tracker>
                            </div>
                            <div *ngSwitchCase="'activity'">
                                <app-view-activity></app-view-activity>
                            </div>
                            <div *ngSwitchCase="'aboutus'">
                                <app-aboutus></app-aboutus>
                            </div>
                            <div *ngSwitchCase="'vital'">
                                <app-client-vitals></app-client-vitals>
                            </div>
                            <div *ngSwitchCase="'features'">
                                <app-package-upgrade></app-package-upgrade>
                            </div>
                            <div *ngSwitchCase="'newDietTrackingHistory'">
                                <app-diet-tracking-history></app-diet-tracking-history>
                            </div>
                            <div *ngSwitchCase="'doctDietTracking'">
                                <app-doc-diet-tracking></app-doc-diet-tracking>
                            </div>
                            <div *ngSwitchCase="'packages'">
                                <app-get-package></app-get-package>
                            </div>
                            <div *ngSwitchCase="'readings'">
                                <app-reading></app-reading>
                            </div>
                            <div *ngSwitchCase="'dietician'">
                                <app-physio-appointment-list></app-physio-appointment-list>
                            </div>
                            <div *ngSwitchCase="'reference_video'">
                                <app-reference-video></app-reference-video>
                            </div>
                            <div *ngSwitchCase="'dietPlanMenu'">
                                <app-menu-diet-plan></app-menu-diet-plan>
                            </div>
                            <div *ngSwitchCase="'getDateDiet'">
                                <app-create-datevice-dietplan></app-create-datevice-dietplan>
                            </div>
                            <div *ngSwitchCase="'listRecepies'">
                                <app-list-recepies></app-list-recepies>
                            </div>
                            <div *ngSwitchCase="'createRecepies'">
                                <app-create-recepies></app-create-recepies>
                            </div>
                            <div *ngSwitchDefault="'default'">
                                <app-client-dashboard></app-client-dashboard>
                                <!-- <app-clientapplist></app-clientapplist> -->
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <footer id="footer_section">
                <div class="footer_left">Copyright © 2021 All rights reserved </div>
                <div class="footer_right">
                    <p class="pull-right sm-pull-reset">
                        <span>
                            <a href="#" class="m-r-10">Support</a> | <a href="#" class="m-l-10 m-r-10">Terms of use</a>
                            | <a href="#" class="m-l-10">Privacy Policy</a>
                        </span>
                    </p>
                </div>
            </footer>
        </div>
    </div>
</div>