var Male_Standard_Height = new Array();
Male_Standard_Height.push({ x: 0, y: 47.1 });
Male_Standard_Height.push({ x: 0.25, y: 59.1 });
Male_Standard_Height.push({ x: 0.5, y: 64.7 });
Male_Standard_Height.push({ x: 0.75, y: 68.2 });
Male_Standard_Height.push({ x: 1, y: 73.9 });
Male_Standard_Height.push({ x: 2, y: 81.6 });
Male_Standard_Height.push({ x: 3, y: 88.9 });
Male_Standard_Height.push({ x: 4, y: 96.0 });
Male_Standard_Height.push({ x: 5, y: 102.1 });
Male_Standard_Height.push({ x: 6, y: 108.5 });
Male_Standard_Height.push({ x: 7, y: 113.9 });
Male_Standard_Height.push({ x: 8, y: 119.3 });
Male_Standard_Height.push({ x: 9, y: 123.7 });
Male_Standard_Height.push({ x: 10, y: 124.4 });


var Male_Standard_Weight = new Array();
Male_Standard_Weight.push({ x: 0, y: 2.6 });
Male_Standard_Weight.push({ x: 0.25, y: 5.3 });
Male_Standard_Weight.push({ x: 0.5, y: 6.7 });
Male_Standard_Weight.push({ x: 0.75, y: 7.4 });
Male_Standard_Weight.push({ x: 1, y: 8.4 });
Male_Standard_Weight.push({ x: 2, y: 10.1 });
Male_Standard_Weight.push({ x: 3, y: 11.8 });
Male_Standard_Weight.push({ x: 4, y: 13.5 });
Male_Standard_Weight.push({ x: 5, y: 14.8 });
Male_Standard_Weight.push({ x: 6, y: 16.3 });
Male_Standard_Weight.push({ x: 7, y: 18.0 });
Male_Standard_Weight.push({ x: 8, y: 19.7 });
Male_Standard_Weight.push({ x: 9, y: 21.5 });
Male_Standard_Weight.push({ x: 10, y: 23.5 });

var Famale_Standard_Height = new Array();
Famale_Standard_Height.push({ x: 0, y: 46.7 });
Famale_Standard_Height.push({ x: 0.25, y: 58.4 });
Famale_Standard_Height.push({ x: 0.5, y: 63.7 });
Famale_Standard_Height.push({ x: 0.75, y: 67.0 });
Famale_Standard_Height.push({ x: 1, y: 72.5 });
Famale_Standard_Height.push({ x: 2, y: 80.1 });
Famale_Standard_Height.push({ x: 3, y: 87.2 });
Famale_Standard_Height.push({ x: 4, y: 94.5 });
Famale_Standard_Height.push({ x: 5, y: 101.4 });
Famale_Standard_Height.push({ x: 6, y: 107.4 });
Famale_Standard_Height.push({ x: 7, y: 112.8 });
Famale_Standard_Height.push({ x: 8, y: 118.2 });
Famale_Standard_Height.push({ x: 9, y: 122.9 });
Famale_Standard_Height.push({ x: 10, y: 123.4 });

var Famale_Standard_Weight = new Array();
Famale_Standard_Weight.push({ x: 0, y: 2.6 });
Famale_Standard_Weight.push({ x: 0.25, y: 5.0 });
Famale_Standard_Weight.push({ x: 0.5, y: 6.2 });
Famale_Standard_Weight.push({ x: 0.75, y: 6.9 });
Famale_Standard_Weight.push({ x: 1, y: 7.8 });
Famale_Standard_Weight.push({ x: 2, y: 9.6 });
Famale_Standard_Weight.push({ x: 3, y: 11.2 });
Famale_Standard_Weight.push({ x: 4, y: 12.9 });
Famale_Standard_Weight.push({ x: 5, y: 14.5 });
Famale_Standard_Weight.push({ x: 6, y: 16.0 });
Famale_Standard_Weight.push({ x: 7, y: 17.6 });
Famale_Standard_Weight.push({ x: 8, y: 19.4 });
Famale_Standard_Weight.push({ x: 9, y: 21.3 });
Famale_Standard_Weight.push({ x: 10, y: 23.6 });


function Pediatric_Graph(Date_Array, Height, Weight, Heart_Rate, Temparature, Birth, Age, Height_date,Clnt_DOB,Clnt_Gender,Vital_check,graph) {
    
 
    if (Vital_check == "Saved") {

        toastr.options = {
            "positionClass": "toast-center",
        }

        toastr.success('Vital details saved successfully !!!');

        $("#vital_savebtn").hide();
    }

    if (Clnt_DOB != null && Clnt_DOB != "") {

        var Client_Age = Clnt_DOB;

        var agesplit = Client_Age.split('-');

        var age_newformate = agesplit[2].toString() + "/" + agesplit[1].toString() + "/" + agesplit[0].toString();
    }

    var Height_Array_last = new Array();
    var Weight_Array_last = new Array();
    var Temp_Array_last = new Array();
    var Heart_Array_last = new Array();

    //var Respitory_Array_last = new Array();
    //var BP_array_last = new Array();
    //var Heightmeasure_Array_last = new Array();
    //var Weightmeasure_Array_last = new Array();

    

    if (Date_Array.length != 0) {

        for (var i = 0; i < Date_Array.length ; i++) {

            var res = Date_Array[i].split("-");

            if (Heart_Rate[i] != "") {

                Heart_Array_last.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(Heart_Rate[i]) });

            }
            if (Temparature[i] != "") {

                Temp_Array_last.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(Temparature[i]) });
            }

        }
    }

    Temp_Array_last.sort(function (a, b) {
        var c = new Date(a.x);
        var d = new Date(b.x);
        return c - d;
    });

    Heart_Array_last.sort(function (a, b) {
        var c = new Date(a.x);
        var d = new Date(b.x);
        return c - d;
    });

    if (Height_date.length != 0) {
        if (Birth.length != 0) {
            for (var i = 0; i < Birth.length ; i++) {
                if (Birth[i] != "0") {
                    Height_Array_last.push({ x: 0, y: parseFloat(Height[i]) });
                    Weight_Array_last.push({ x: 0, y: parseFloat(Weight[i]) });

                    if (Vital_check != "Client") {
                        $("#at_birth_div").hide();
                        $("#weight_textbox_div").removeClass("col-md-3");
                        $("#weight_textbox_div").addClass("col-md-4");
                        $("#weight_drop_div").removeClass("col-md-3");
                        $("#weight_drop_div").addClass("col-md-4");
                    }
                }
            }
        }

        if (Height_Array_last.length == 0 && Weight_Array_last.length == 0) {

            if (Clnt_Gender == "Male") {

                Height_Array_last.push({ x: 0, y: parseFloat(47.1) });
                Weight_Array_last.push({ x: 0, y: parseFloat(2.6) });
            }

            else if (Clnt_Gender == "Female") {
                Height_Array_last.push({ x: 0, y: parseFloat(46.7) });
                Weight_Array_last.push({ x: 0, y: parseFloat(2.6) });
            }
        }



        for (var i = 0; i < Height_date.length ; i++) {

            var res = Height_date[i].split("-");

            if (Height[i] != "")
            {
                if (Birth[i] == "0")
                {
                    if (Clnt_DOB != null && Clnt_DOB != "") // has DOB
                    {
                        Height_Array_last.push({ x: parseFloat(getAge(age_newformate.toString(), res[0].toString() + '/' + res[1].toString() + '/' + res[2].toString())), y: parseFloat(Height[i]) });
                    }
                }
            }
            if (Weight[i] != "")
            {
                if (Birth[i] == "0")
                {
                    if (Clnt_DOB != null && Clnt_DOB != "") // has DOB
                    {
                        Weight_Array_last.push({ x: parseFloat(getAge(age_newformate.toString(), res[0].toString() + '/' + res[1].toString() + '/' + res[2].toString())), y: parseFloat(Weight[i]) });
                    }
                }
            }
        }

    }

    if (Height_Array_last.length == 0 && Weight_Array_last.length == 0 && Temp_Array_last.length == 0 && Heart_Array_last.length == 0) {


        if (Vital_check != "Client") {
            
            $("#Graph_div").hide();
            $("#vital_savebtn").hide();
            $("#vital_anosave").show();
            //$("#Graph_Save_Div").removeClass("col-md-8");
            //$("#Graph_Save_Div").addClass("col-md-9");

            //$("#Graph_Save_Div").attr('style', 'margin-right:20px');
        }
    }
    else {

        if (Vital_check != "Client") {

          
            $("#Graph_div").show();
            $("#vital_savebtn").show();
            $("#vital_anosave").hide();
          
        }

if(graph=="1"){
        if (Height_Array_last.length != 0) {

            if (Clnt_Gender == "Male") {

                //Height_Graph(Height_Array_last, Heightmeasure_Array_last, Height, Male_Standard_Height);
                Height_Graph(Height_Array_last, Male_Standard_Height);
            }
            else if (Clnt_Gender == "Female") {

                //Height_Graph(Height_Array_last, Heightmeasure_Array_last, Height, Famale_Standard_Height);
                Height_Graph(Height_Array_last, Famale_Standard_Height);
            }
            else {

                //Height_Graph(Height_Array_last, Heightmeasure_Array_last, Height, Male_Standard_Height);
                Height_Graph(Height_Array_last, Male_Standard_Height);
            }

            $("#Height_Chart").show();
            $("#Height_Chart_Unavail").hide();
        }
        else {

            $("#Height_Chart_Unavail").show();
            $("#Height_Chart").hide();
        }
    }
    if(graph=="2"){
        if (Weight_Array_last.length != 0) {

            if (Clnt_Gender == "Male") {

                // Weight_Graph(Weight_Array_last, Weightmeasure_Array_last, Weight, Male_Standard_Weight);
                Weight_Graph(Weight_Array_last, Male_Standard_Weight);

            }
            else if (Clnt_Gender == "Female") {

                //Weight_Graph(Weight_Array_last, Weightmeasure_Array_last, Weight, Famale_Standard_Weight);
                Weight_Graph(Weight_Array_last, Famale_Standard_Weight);

            }
            else {
                // Weight_Graph(Weight_Array_last, Weightmeasure_Array_last, Weight, Male_Standard_Weight);
                Weight_Graph(Weight_Array_last, Male_Standard_Weight);

            }

            $("#Weight_Chart").show();
            $("#Weight_Chart_Unavail").hide();
        }
        else {

            $("#Weight_Chart_Unavail").show();
            $("#Weight_Chart").hide();
        }
    }
    if(graph=="3"){
        if (Temp_Array_last.length != 0) {

            Temp_Graph(Temp_Array_last);
            $("#Temp_Chart").show();
            $("#Unavail_Temp_Chart").hide();
        }
        else {

            $("#Unavail_Temp_Chart").show();
            $("#Temp_Chart").hide();
        }
    }
    if(graph=="4"){
        if (Heart_Array_last.length != 0) {

            Heart_Graph(Heart_Array_last);

            $("#Heart_Chart").show();
            $("#Heart_Chart_Unavail").hide();
        }
        else {

            $("#Heart_Chart_Unavail").show();
            $("#Heart_Chart").hide();
        }
    }
    }

    //if (BP_array_last.length != 0) {

    //    BP_Graph(BP_array_last);
    //    $("#BP_Chart").show();
    //    $("#Unavail_BP_Chart").hide();
    //}
    //else {

    //    $("#Unavail_BP_Chart").show();
    //    $("#BP_Chart").hide();
    //}

    //if (Respitory_Array_last.length != 0) {

    //    Respitory_Graph(Respitory_Array_last);
    //    $("#Respiratory_Chart").show();
    //    $("#Respiratory_Chart_Unavail").hide();
    //}
    //else {

    //    $("#Respiratory_Chart_Unavail").show();
    //    $("#Respiratory_Chart").hide();
    //}

    //}
}

// Age Calculation

function getAge(Client_DOB, Created_Date) {

// alert("Created Date : " + Created_Date);
// alert("Client_DOB Date : " + Client_DOB);

var array = Created_Date.split('/');

var create_Year = parseInt(array[0]);
var create_Month = parseInt(array[1]);
var create_Day = parseInt(array[2]);

var Dob_Array = Client_DOB.split('/');

var Dob_Year = parseInt(Dob_Array[0]);
var Dob_Month = parseInt(Dob_Array[1]);
var Dob_Day = parseInt(Dob_Array[2]);

var final_year = create_Year - Dob_Year;

if (create_Month >= Dob_Month) {
    var final_month = create_Month - Dob_Month;
}
else {
    final_year = final_year - 1;
    var final_month = (create_Month + 12) - Dob_Month;
}

if (create_Day >= Dob_Day) {
    var final_Day = create_Day - Dob_Day;
}
else {
    final_month = final_month - 1;

    var final_Day = (create_Day + 31) - Dob_Day;

    if (final_month == 0)
    {
        final_year = final_year - 1;
        final_month = 11;
    }
}

// alert("final_year" + final_year);
// alert("final_month" + final_month);
// alert("final_Day" + final_Day);

if (final_month == "1") {
    final_month = 0.083;
}
else if (final_month == "2") {
    final_month = 0.166;
}
else if (final_month == "3") {
    final_month = 0.25;
}
else if (final_month == "4") {
    final_month = 0.33;
}
else if (final_month == "5") {
    final_month = 0.416;
}
else if (final_month == "6") {
    final_month = 0.5;
}
else if (final_month == "7") {
    final_month = 0.583;
}
else if (final_month == "8") {
    final_month = 0.66;
}
else if (final_month == "9") {
    final_month = 0.75;
}
else if (final_month == "10") {
    final_month = 0.833;
}
else if (final_month == "11") {
    final_month = 0.916;
}

var finalyaermonth = final_year + final_month;

return finalyaermonth;
}

// Height Graph

function Height_Graph(finalarray , Standard_Height) {

// alert("Length : " + measureval.length);

var Height_Graph = new CanvasJS.Chart("Height_Chartzooom",
{
    title: {
        text: ""
    },
    toolTip: {
        fontWeight: "normal",
        fontSize: "15",
        fontFamily: "Consolas,Tohama,verdana",
        fontStyle: "normal",
        borderThickness: 2,

        contentFormatter: function (e) {
            var content = "";
            for (var i = 0; i < e.entries.length; i++) {

                var integer = Math.floor(e.entries[i].dataPoint.x);

                // alert("Integer : " + integer);

                if (integer != 0)
                {
                    var Decimalvalue = (e.entries[i].dataPoint.x - integer).toFixed([3]);

                    if (Decimalvalue == 0.083) {
                        content = e.entries[i].dataPoint.y + " cm at " + integer + " years 1 month";
                        break;
                    }
                    else if (Decimalvalue == 0.166) {
                        content = e.entries[i].dataPoint.y + " cm at " + integer + " years 2 months";
                        break;
                    }
                    else if (Decimalvalue == 0.25) {
                        content = e.entries[i].dataPoint.y + " cm at " + integer + " years 3 months";
                        break;
                    }
                    else if (Decimalvalue == 0.33) {
                        content = e.entries[i].dataPoint.y + " cm at " + integer + " years 4 months";
                        break;
                    }
                    else if (Decimalvalue == 0.416) {
                        content = e.entries[i].dataPoint.y + " cm at " + integer + " years 5 months";
                        break;
                    }
                    else if (Decimalvalue == 0.5) {
                        content = e.entries[i].dataPoint.y + " cm at " + integer + " years 6 months";
                        break;
                    }
                    else if (Decimalvalue == 0.583) {
                        content = e.entries[i].dataPoint.y + " cm at " + integer + " years 7 months";
                        break;
                    }
                    else if (Decimalvalue == 0.66) {
                        content = e.entries[i].dataPoint.y + " cm at " + integer + " years 8 months";
                        break;
                    }
                    else if (Decimalvalue == 0.75) {
                        content = e.entries[i].dataPoint.y + " cm at " + integer + " years 9 months";
                        break;
                    }
                    else if (Decimalvalue == 0.833) {
                        content = e.entries[i].dataPoint.y + " cm at " + integer + " years 10 months";
                        break;
                    }
                    else if (Decimalvalue == 0.916) {
                        content = e.entries[i].dataPoint.y + " cm at " + integer + " years 11 months";
                        break;
                    }
                    else if (integer == 0 && Decimalvalue == 0.000) {
                        content = e.entries[i].dataPoint.y + " cm at Birth";
                        break;
                    }
                    else {
                        content = e.entries[i].dataPoint.y + " cm at " + e.entries[i].dataPoint.x + " years";
                        break;
                    }
                }
                else {
                    if (e.entries[i].dataPoint.x == 0.083) {
                        content = e.entries[i].dataPoint.y + " cm at 1 month";
                        break;
                    }
                    else if (e.entries[i].dataPoint.x == 0.166) {
                        content = e.entries[i].dataPoint.y + " cm at 2 months";
                        break;
                    }
                    else if (e.entries[i].dataPoint.x == 0.25) {
                        content = e.entries[i].dataPoint.y + " cm at 3 months";
                        break;
                    }
                    else if (e.entries[i].dataPoint.x == 0.33) {
                        content = e.entries[i].dataPoint.y + " cm at 4 months";
                        break;
                    }
                    else if (e.entries[i].dataPoint.x == 0.416) {
                        content = e.entries[i].dataPoint.y + " cm at 5 months";
                        break;
                    }
                    else if (e.entries[i].dataPoint.x == 0.5) {
                        content = e.entries[i].dataPoint.y + " cm at 6 months";
                        break;
                    }
                    else if (e.entries[i].dataPoint.x == 0.583) {
                        content = e.entries[i].dataPoint.y + " cm at 7 months";
                        break;
                    }
                    else if (e.entries[i].dataPoint.x == 0.66) {
                        content = e.entries[i].dataPoint.y + " cm at 8 months";
                        break;
                    }
                    else if (e.entries[i].dataPoint.x == 0.75) {
                        content = e.entries[i].dataPoint.y + " cm at 9 months";
                        break;
                    }
                    else if (e.entries[i].dataPoint.x == 0.833) {
                        content = e.entries[i].dataPoint.y + " cm at 10 months";
                        break;
                    }
                    else if (e.entries[i].dataPoint.x == 0.916) {
                        content = e.entries[i].dataPoint.y + " cm at 11 months";
                        break;
                    }
                    else if (e.entries[i].dataPoint.x == 0) {
                        content = e.entries[i].dataPoint.y + " cm at Birth";
                        break;
                    }
                    else {
                        content = e.entries[i].dataPoint.y + "cm at the Age of " + e.entries[i].dataPoint.x;
                    }
                }
            }
            return content;
        }
    },
    colorSet: "SetColourforChart",
    axisX: {
        //labelAngle: 135,
        labelFontSize: "12",
        //valueFormatString: "DD-MMM-YYYY",
        title: " Age in years ",
        titleFontSize: 15,
        // interval: 1,
        minimum: 0,
    },
    axisY: {
        includeZero: true,
        title: " Height in cms ",
        titleFontSize: 15,
        gridThickness: 1,
        //lineThickness: 0,
        tickThickness: 0,
        labelFontSize: 16,
    },
    data: [

      {
          name: 'Standard Height',
          type: "spline",
          showInLegend: true,
          dataPoints: Standard_Height,
          color: "green",
          markerSize: 1,
      },
       {
           name: 'Actual Height',
           type: "spline",
           showInLegend: true,
           dataPoints: finalarray,
           //markerSize: 1,
       }
    ],
    legend: {
        cursor: "pointer",
        itemclick: function (e1) {
            if (typeof (e1.dataSeries.visible) === "undefined" || e1.dataSeries.visible) {
                e1.dataSeries.visible = false;
            }
            else {
                e1.dataSeries.visible = true;
            }
            Height_Graph.render();
        }
    }
});

Height_Graph.render();
}


//Weight Graph

function Weight_Graph(finalarray, Standard_weight) {

// alert("Length : " + measureval.length);

var Weight_Chart = new CanvasJS.Chart("Weight_Chartzoom",
{
    title: {
        text: ""
    },
    toolTip: {
        fontWeight: "normal",
        fontSize: "15",
        fontFamily: "Consolas,Tohama,verdana",
        fontStyle: "normal",
        borderThickness: 2,
        //lineThickness: 0,
        contentFormatter: function (e) {

            var content = "";

            for (var i = 0; i < e.entries.length; i++) {

                var integer = Math.floor(e.entries[i].dataPoint.x);

                if (integer != 0) {

                    var Decimalvalue = (e.entries[i].dataPoint.x - integer).toFixed([3]);

                    //alert("integer value " + integr);

                    if (Decimalvalue == 0.083) {
                        content = e.entries[i].dataPoint.y + " kg at " + integer + " years 1 month";
                        break;
                    }
                    else if (Decimalvalue == 0.166) {
                        content = e.entries[i].dataPoint.y + " kg at " + integer + " years 2 months";
                        break;
                    }
                    else if (Decimalvalue == 0.25) {
                        content = e.entries[i].dataPoint.y + " kg at " + integer + " years 3 months";
                        break;
                    }
                    else if (Decimalvalue == 0.33) {
                        content = e.entries[i].dataPoint.y + " kg at " + integer + " years 4 months";
                        break;
                    }
                    else if (Decimalvalue == 0.416) {
                        content = e.entries[i].dataPoint.y + " kg at " + integer + " years 5 months";
                        break;
                    }
                    else if (Decimalvalue == 0.5) {
                        content = e.entries[i].dataPoint.y + " kg at " + integer + " years 6 months";
                        break;
                    }
                    else if (Decimalvalue == 0.583) {
                        content = e.entries[i].dataPoint.y + " kg at " + integer + " years 7 months";
                        break;
                    }
                    else if (Decimalvalue == 0.66) {
                        content = e.entries[i].dataPoint.y + " kg at " + integer + " years 8 months";
                        break;
                    }
                    else if (Decimalvalue == 0.75) {
                        content = e.entries[i].dataPoint.y + " kg at " + integer + " years 9 months";
                        break;
                    }
                    else if (Decimalvalue == 0.833) {
                        content = e.entries[i].dataPoint.y + " kg at " + integer + " years 10 months";
                        break;
                    }
                    else if (Decimalvalue == 0.916) {
                        content = e.entries[i].dataPoint.y + " kg at " + integer + " years 11 months";
                        break;
                    }
                    else if (integer == 0 && Decimalvalue == 0.000) {
                        content = e.entries[i].dataPoint.y + " kg at Birth";
                        break;
                    }
                    else {
                        content = e.entries[i].dataPoint.y + " kg at " + e.entries[i].dataPoint.x + " years";
                        break;
                    }
                }
                else {

                    if (e.entries[i].dataPoint.x == 0.083) {
                        content = e.entries[i].dataPoint.y + " kg at 1 month";
                        break;
                    }
                    else if (e.entries[i].dataPoint.x == 0.166) {
                        content = e.entries[i].dataPoint.y + " kg at 2 months";
                        break;
                    }
                    else if (e.entries[i].dataPoint.x == 0.25) {
                        content = e.entries[i].dataPoint.y + " kg at 3 months";
                        break;
                    }
                    else if (e.entries[i].dataPoint.x == 0.33) {
                        content = e.entries[i].dataPoint.y + " kg at 4 months";
                        break;
                    }
                    else if (e.entries[i].dataPoint.x == 0.416) {
                        content = e.entries[i].dataPoint.y + " kg at 5 months";
                        break;
                    }
                    else if (e.entries[i].dataPoint.x == 0.5) {
                        content = e.entries[i].dataPoint.y + " kg at 6 months";
                        break;
                    }
                    else if (e.entries[i].dataPoint.x == 0.583) {
                        content = e.entries[i].dataPoint.y + " kg at 7 months";
                        break;
                    }
                    else if (e.entries[i].dataPoint.x == 0.66) {
                        content = e.entries[i].dataPoint.y + " kg at 8 months";
                        break;
                    }
                    else if (e.entries[i].dataPoint.x == 0.75) {
                        content = e.entries[i].dataPoint.y + " kg at 9 months";
                        break;
                    }
                    else if (e.entries[i].dataPoint.x == 0.833) {
                        content = e.entries[i].dataPoint.y + " kg at 10 months";
                        break;
                    }
                    else if (e.entries[i].dataPoint.x == 0.916) {
                        content = e.entries[i].dataPoint.y + " kg at 11 months";
                        break;
                    }
                    else if (integer == 0 && e.entries[i].dataPoint.x == 0.000) {
                        content = e.entries[i].dataPoint.y + " kg at Birth";
                        break;
                    }
                    else {
                        content = e.entries[i].dataPoint.y + " kg at " + e.entries[i].dataPoint.x + " years";
                        break;
                    }
                }
            }
            return content;
        }
    },
    colorSet: "SetColourforChart",
    axisX: {
        //labelAngle: 135,
        labelFontSize: "12",
        //valueFormatString: "DD-MMM-YYYY",
        title: " Age in years ",
        titleFontSize: 15,
        //interval: 1,
        minimum: 0,
    },
    axisY: {
        includeZero: true,
        title: " Weight in Kgs ",
        titleFontSize: 15,
        gridThickness: 1,
        tickThickness: 0,
        labelFontSize: 16,
    },
    data: [
      {
          name: 'Standard Weight',
          type: "spline",
          showInLegend: true,
          dataPoints: Standard_weight,
          color: "green",
          markerSize: 1,
      },
      {
          name: 'Actual Weight',
          type: "spline",
          showInLegend: true,
          dataPoints: finalarray,
          //markerSize: 1,
      }
    ],
    legend: {
        cursor: "pointer",
        itemclick: function (e1) {
            if (typeof (e1.dataSeries.visible) === "undefined" || e1.dataSeries.visible) {
                e1.dataSeries.visible = false;
            }
            else {
                e1.dataSeries.visible = true;
            }
            Weight_Chart.render();
        }
    }
});

Weight_Chart.render();
}

//Temperature Graph

function Temp_Graph(finalarray) {

var Temp_Chart = new CanvasJS.Chart("Temp_Chartzoom",
{
    title: {
        text: ""
    },
    toolTip: {
        fontWeight: "normal",
        fontSize: "15",
        fontFamily: "Consolas,Tohama,verdana",
        fontStyle: "normal",
        borderThickness: 2,

        contentFormatter: function (e) {
            var content = "";
            for (var i = 0; i < e.entries.length; i++) {
                content = e.entries[i].dataPoint.y + " <sup>o</sup>F on " + CanvasJS.formatDate(e.entries[i].dataPoint.x, "DD-MMM-YYYY");
            }
            return content;
        }
    },
    colorSet: "SetColourforChart",
    axisX: {
        labelAngle: 135,
        labelFontSize: "12",
        valueFormatString: "DD-MMM-YYYY",
    },
    axisY: {
        //includeZero: true,
        //title: " Weight in Kgs ",
        //titleFontSize: 15,
        gridThickness: 1,
        tickThickness: 0,
        labelFontSize: 16,
    },
    data: [
      {
          name: 'Temperature',
          type: "line",
          showInLegend: true,
          dataPoints: finalarray
      }
    ],
    legend: {
        cursor: "pointer",
        itemclick: function (e1) {
            if (typeof (e1.dataSeries.visible) === "undefined" || e1.dataSeries.visible) {
                e1.dataSeries.visible = false;
            }
            else {
                e1.dataSeries.visible = true;
            }
            Temp_Chart.render();
        }
    }
});

Temp_Chart.render();

}
//Blood Pressure Graph

function BP_Graph(finalarray) {

var BP_Chart = new CanvasJS.Chart("BP_Chartzoom",
{
    title: {
        text: ""
    },
    toolTip: {
        fontWeight: "normal",
        fontSize: "15",
        fontFamily: "Consolas,Tohama,verdana",
        fontStyle: "normal",
        borderThickness: 2,

        contentFormatter: function (e) {
            var content = "";
            for (var i = 0; i < e.entries.length; i++) {
                content = e.entries[i].dataPoint.y + " mm Hg on " + CanvasJS.formatDate(e.entries[i].dataPoint.x, "DD-MMM-YYYY");
            }
            return content;
        }
    },
    colorSet: "SetColourforChart",
    axisX: {
        labelAngle: 135,
        labelFontSize: "12",
        valueFormatString: "DD-MMM-YYYY",
    },
    axisY: {
        //includeZero: true,
        //title: " Weight in Kgs ",
        //titleFontSize: 15,
        gridThickness: 1,
        tickThickness: 0,
        labelFontSize: 16,
    },
    data: [
      {
          name: 'Blood Pressure',
          type: "line",
          showInLegend: true,
          dataPoints: finalarray
      }
    ],
    legend: {
        cursor: "pointer",
        itemclick: function (e1) {
            if (typeof (e1.dataSeries.visible) === "undefined" || e1.dataSeries.visible) {
                e1.dataSeries.visible = false;
            }
            else {
                e1.dataSeries.visible = true;
            }
            BP_Chart.render();
        }
    }
});

BP_Chart.render();
}

//Heart Rate Graph

function Heart_Graph(finalarray) {

var Heart_Chart = new CanvasJS.Chart("Heart_Chartzoom",
{
    title: {
        text: ""
    },
    toolTip: {
        fontWeight: "normal",
        fontSize: "15",
        fontFamily: "Consolas,Tohama,verdana",
        fontStyle: "normal",
        borderThickness: 2,

        contentFormatter: function (e) {
            var content = "";
            for (var i = 0; i < e.entries.length; i++) {
                content = e.entries[i].dataPoint.y + " (beats/minute) on " + CanvasJS.formatDate(e.entries[i].dataPoint.x, "DD-MMM-YYYY");
            }
            return content;
        }
    },
    colorSet: "SetColourforChart",
    axisX: {
        labelAngle: 135,
        labelFontSize: "12",
        valueFormatString: "DD-MMM-YYYY",
    },
    axisY: {
        //includeZero: true,
        //title: " Weight in Kgs ",
        //titleFontSize: 15,
        gridThickness: 1,
        tickThickness: 0,
        labelFontSize: 16,
    },
    data: [
      {
          name: 'Heart Rate',
          type: "line",
          showInLegend: true,
          dataPoints: finalarray
      }
    ],
    legend: {
        cursor: "pointer",
        itemclick: function (e1) {
            if (typeof (e1.dataSeries.visible) === "undefined" || e1.dataSeries.visible) {
                e1.dataSeries.visible = false;
            }
            else {
                e1.dataSeries.visible = true;
            }
            Heart_Chart.render();
        }
    }
});

Heart_Chart.render();
}

//Respiratory rate Graph

function Respitory_Graph(finalarray) {

var Respiratory_Chart = new CanvasJS.Chart("Respiratory_Chart",
{
    title: {
        text: ""
    },
    toolTip: {
        fontWeight: "normal",
        fontSize: "15",
        fontFamily: "Consolas,Tohama,verdana",
        fontStyle: "normal",
        borderThickness: 2,

        contentFormatter: function (e) {
            var content = "";
            for (var i = 0; i < e.entries.length; i++) {
                content = e.entries[i].dataPoint.y + " (breaths per minute) on " + CanvasJS.formatDate(e.entries[i].dataPoint.x, "DD-MMM-YYYY");
            }
            return content;
        }
    },
    colorSet: "SetColourforChart",
    axisX: {
        labelAngle: 135,
        labelFontSize: "12",
        valueFormatString: "DD-MMM-YYYY",
    },
    axisY: {
        //includeZero: true,
        //title: " Weight in Kgs ",
        //titleFontSize: 15,
        gridThickness: 1,
        tickThickness: 0,
        labelFontSize: 16,
    },
    data: [
      {
          name: 'Respiratory Rate',
          type: "line",
          showInLegend: true,
          dataPoints: finalarray
      }
    ],
    legend: {
        cursor: "pointer",
        itemclick: function (e1) {
            if (typeof (e1.dataSeries.visible) === "undefined" || e1.dataSeries.visible) {
                e1.dataSeries.visible = false;
            }
            else {
                e1.dataSeries.visible = true;
            }
            Respiratory_Chart.render();
        }
    }
});

Respiratory_Chart.render();
}

exports.Pediatric_Graph = Pediatric_Graph;