import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatSelectModule} from '@angular/material/select';
import { MatFormFieldModule} from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { BillHistViewComponent } from './bill-hist-view.component';
import {MatDialogModule} from '@angular/material/dialog';


@NgModule({
    imports:[MatDialogModule,CommonModule,MatInputModule ,MatSelectModule,MatFormFieldModule,MatIconModule,MatButtonModule,FormsModule],
    declarations:[BillHistViewComponent],
    exports:[BillHistViewComponent]
})
export class BillHistViewModule{   
  
}