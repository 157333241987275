import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { ClientViewService } from '../clientview/client-view.service';
import { Client_Helper } from '../Client_helper';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';
import { Helper_Class } from 'src/app/helper_class';

@Component({
  selector: 'app-diagnosis-appointment-list',
  templateUrl: './diagnosis-appointment-list.component.html',
  styleUrls: ['./diagnosis-appointment-list.component.css']
})
export class DiagnosisAppointmentListComponent implements OnInit {

  public currentDateUrl: string;
  public relationUrl: string;
  public apptDetailsUrl: string;
  public send_current_data;
  public current_time: string;
  public Delete_App_List = [];
  public currentDate: string;
  public send_client_reg_id;
  public retrivel_client_id;
  public app_datas = [];
  public retrivel_client_listData;
  public response_data;
  public Delete_view: Boolean = false;
  public deleteApptUrl;
  public toastMessage: string;
  public diagnodata: boolean;
  public option_list: boolean;
  public homecare_value: string;
  public loader: boolean;
  public relationship_list_data = [];

  //mdbTable
  dtOptions: DataTables.Settings = {};
  persons: any = [];
  masterSelected: boolean = false;
  checklist:any;
  checkedList:any
  public newbutton:boolean = false
  public editbutton: boolean = false;
  public deletebutton: boolean = false;
  public printbutton: boolean = false;
  public viewbutton: boolean = false;
  constructor(public messageservice:ClientViewService,private gservice:CommonDataService,private http:Http,private routes:ActivatedRoute,private router:Router,public toastr:ToastrService) { 
    this.diagnodata = true;
    this.currentDateUrl = ipaddress.getIp + "adm/curdate";
    this.relationUrl = ipaddress.getIp + "usercontroller/grels";
    this.apptDetailsUrl = ipaddress.getIp + "appointment/getallappdetails";
    this.deleteApptUrl = ipaddress.getIp + "appointment/delapps/";
    Client_Helper.setDoctorApptdetail(null);

    if (Client_Helper.getHomecare() != null) 
      this.homecare_value = "1";
    else 
      this.homecare_value = "0";

    this.send_current_data = {
      country: ipaddress.country_code
    }
    if (this.gservice.Client_login_detail_data.Client_Id != undefined) {
      this.send_client_reg_id = {
        client_reg_id: this.gservice.Client_login_detail_data.Client_Id
      }
      this.retrivel_client_id = {
        client_id: this.gservice.Client_login_detail_data.Client_Id,
        home_care: "0",
        provider: "diagnosis",
        relation: "all",
        country:"IN"
      }
    }
    this.getCurrentDate();
    this.getRelationList();
    this.appRetrieval();
  }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      "lengthMenu": [ [10, 25, 50,100, -1], [10, 25, 50, 100, "All"] ],
      "order": [[3, 'desc']],
      "columnDefs": [
        { "orderable": false, "targets": [] },
        {"targets":3, "type":"date-eu"}
      ],
      "language": {
            "search": "<img src='../../../assets/img/search.png' width='18px'/>",
            searchPlaceholder: "Search by name, center, or date "
          },
          dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    if(Helper_Class.getmodulelist() != undefined){
      for (var i = 0; i < Helper_Class.getmodulelist().length; i++) {
        if (Helper_Class.getmodulelist()[i].module_id == "16"){
            if(Helper_Class.getmodulelist()[i].edit == "1"){
              this.editbutton=true;//update button
            }
            if(Helper_Class.getmodulelist()[i].create == "1"){
              this.newbutton=true;
            }
            if(Helper_Class.getmodulelist()[i].delete == "1"){
              this.deletebutton=true;
            }
            if(Helper_Class.getmodulelist()[i].print == "1"){
              this.printbutton=true;
            }
            if(Helper_Class.getmodulelist()[i].view == "1"){
              this.viewbutton=true;
            }
        }
      }
    }
  }

  getCurrentDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.currentDateUrl, JSON.stringify(this.send_current_data), { headers: headers }).subscribe(
      data => {
        this.currentDate = data.json().current_date;
        this.current_time = data.json().current_time;
      }, error => {});
  }

  getRelationList() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.relationUrl, JSON.stringify(this.send_client_reg_id), { headers: headers }).subscribe(
      data => {
        if (data.json().relationships != null) {
          for (var l = 0; l < data.json().relationships.length; l++) {
            this.relationship_list_data.push({
              type: 'radio',
              value: data.json().relationships[l].relation_id,
              label: data.json().relationships[l].relationship_name,
            });
          }
        }
      }, error => {});
  }

  appRetrieval() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.apptDetailsUrl, JSON.stringify(this.retrivel_client_id), { headers: headers }).subscribe(
      data => {
        this.loader = true;
        this.app_datas = [];
        this.retrivel_client_listData = data.json();
        
        if (this.retrivel_client_listData.appointments == null || (this.retrivel_client_listData.appointments != null && this.retrivel_client_listData.appointments.length == 0)) {
          this.diagnodata = false;
        } else {
          for (var i = 0; i < this.retrivel_client_listData.appointments.length; i++) {
            var new_datalist = this.retrivel_client_listData.appointments[i];
            var middle = "";
            if (new_datalist.middle_name != null) {
              middle = encrypt_decript.Decript(new_datalist.first_name.toString()) + ' ' + encrypt_decript.Decript(new_datalist.middle_name.toString) + ' ' + encrypt_decript.Decript(new_datalist.last_name);
            } else {
              middle = encrypt_decript.Decript(new_datalist.first_name.toString()) + ' ' + encrypt_decript.Decript(new_datalist.last_name);
            }
            var diag_name = new_datalist.diag_name.toString();
            var app_date = "";
            if (new_datalist.date != null) {
              app_date = new_datalist.date.split('-')[2] + "-" + new_datalist.date.split('-')[1] + "-" + new_datalist.date.split('-')[0];
            }
            var session = "";
            if (new_datalist.session != null) {
              session = new_datalist.session;
            }
            var Status_txt = "";
            if (new_datalist.status != null) {
              Status_txt = new_datalist.status;
            }
            if (new Date(this.currentDate) > new Date(new_datalist.date) && new_datalist.status == "1" && new_datalist.is_cancelled == "0") {
              Status_txt = "Time not alloted"; //Not Visited
            }
            if (new_datalist.status == "1") {
              Status_txt = "Open";
            }

            if (new Date(this.currentDate) == new Date(new_datalist.date) && new_datalist.status == "1" && new_datalist.is_cancelled == "0") {
              Status_txt = "Open";
            }

            if (new Date(this.currentDate) < new Date(new_datalist.date) && new_datalist.status == "1" && new_datalist.is_cancelled == "0") {
              Status_txt = "Open";
            }
            if (new_datalist.f_time != null) {
              Status_txt = "Time alloted";
            }
            if (new Date(this.currentDate) > new Date(new_datalist.date) && new_datalist.status == "1" && new_datalist.is_cancelled == "0") {
              Status_txt = "Time not alloted"; //Not Visited
            }
            if (new_datalist.status == "2") {
              Status_txt = "Sample collected";
            }

            if (new_datalist.status == "3") {
              Status_txt = "Report yet to be finalized";
            }
            if (new_datalist.status == "1" && new_datalist.is_cancelled == "1") {
              Status_txt = "Rejected";
            }
            if (new_datalist.status == "0" && new_datalist.is_cancelled == "0") {
              Status_txt = "Completed";
            }
            if (new_datalist.status == "0" && new_datalist.is_cancelled == "1") {
              Status_txt = "Cancelled";
            }
            
            if (this.Delete_view == true) {
              if (Status_txt != "Open" && Status_txt != "Confirmed" && Status_txt != "Awaiting for rating" && Status_txt != "Report yet to be finalized" && Status_txt != "Sample collected" && Status_txt != "Time alloted") {
                this.app_datas.push({
                  app_id: new_datalist.app_id,
                  name: middle,
                  date: app_date,
                  session: session,
                  status: Status_txt,
                  center_name: diag_name,
                  delete_flg: false
                })
              }
            } else {
              this.app_datas.push({
                app_id: new_datalist.app_id,
                name: middle,
                date: app_date,
                session: session,
                status: Status_txt,
                center_name: diag_name,
                delete_flg: false
              })
            }

            if (this.app_datas.length == 0) 
              this.diagnodata = false;
            else 
              this.diagnodata = true;
          }
        }
      }
    )
  }

  appDelete() {
    this.option_list = false;
    this.Delete_view = true;
    this.appRetrieval();
    this.option_list = false;
  }

  newAppointment() {
    this.option_list = false;
  }

  delAppointment() {
    this.option_list = false;
    this.deleteApp();
  }

  deleteCancel() {
    this.option_list = false;
    this.Delete_view = false;

    this.retrivel_client_id = {
      client_id: this.gservice.Client_login_detail_data.Client_Id,
      home_care: "0",
      provider: "diagnosis",
      relation: "all"
    }
    this.appRetrieval();
  }

  getdelete(app_id, check_value) {
    if (check_value == true) {
      this.Delete_App_List.push(app_id);
    }else {
      for (var i = 0; i < this.Delete_App_List.length; i++) {
        if (this.Delete_App_List[i] == app_id) {
          this.Delete_App_List.splice(this.Delete_App_List.indexOf(i), 1);
          break;
        }
      }
    }
  }

  viewAppt(app_id, status) {
    var selectlist;
    if (this.Delete_view != true){ // Delete Appointment
      selectlist = {
        app_status: status,
        app_id:app_id
      }
    }
    Client_Helper.setHomecare(this.homecare_value);
    Client_Helper.setDoctorApptdetail(selectlist);
    this.messageservice.sendMessage("diagnosis_detalied_view");
  }

  deleteApp(){
    if (this.Delete_App_List == null || (this.Delete_App_List != null && this.Delete_App_List.length == 0)) {
      this.toastr.error(Message_data.sltApptToDel);
    } else {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.deleteApptUrl, JSON.stringify({ apps_id: this.Delete_App_List, type: "diagnosis" }), { headers: headers }).subscribe(
        data => {
          this.response_data = data.json();
          this.toastr.error = this.response_data.result;
          this.appRetrieval();
          
        },
        error => {
        })
    }
  }

  checkUncheckAll() {
    this.checklist = this.app_datas;
    for (var i = 0; i < this.checklist.length; i++) {
      this.checklist[i].isSelected = this.masterSelected;
    }
  }
  
  isAllSelected() {
    this.masterSelected = this.checklist.every(function(item:any) {
        return item.isSelected == true;
      })
  }
  
  getCheckedItemList(){
    this.checkedList = [];
    for (var i = 0; i < this.checklist.length; i++) {
      if(this.checklist[i].isSelected)
      this.checkedList.push(this.checklist[i].patient_id);
    }
    if(this.checklist.length == this.checkedList.length) {
      this.toastr.success(Message_data.allRowSlt);  
    } else if(this.checkedList.length != 0 ) {
      this.toastr.success('Thank you...', `selected Id: ${this.checkedList}`);  
    } else {
      this.checkedList = `No row seleted`;
      this.toastr.success(Message_data.noRowSlt);  
    }
  }
  
  clearAll() {
    this.checklist = this.app_datas;
    for (var i = 0; i < this.checklist.length; i++) {
      this.checklist[i].isSelected = false;
    }
    this.masterSelected = false;
    this.checkedList = [];
  }
  
  create(){
    this.messageservice.sendMessage("diagnosis_appcreate");
  }
}
