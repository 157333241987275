import { Component, OnInit ,ElementRef,Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Date_Formate } from '../../../assets/js/common.js';
import { MatDialog } from '@angular/material/dialog';
import { ClientViewService } from '../clientview/client-view.service';
import { Client_Helper } from '../Client_helper';
import { CommonDataService } from '../../providers/common-data.service';
import { ipaddress } from '../../ipaddress';
declare var $ : any; 
@Component({
  selector: 'app-pharmacyrelationprescriptiondetails',
  templateUrl: './pharmacyrelationprescriptiondetails.component.html',
  styleUrls: ['./pharmacyrelationprescriptiondetails.component.css']
})
export class PharmacyrelationprescriptiondetailsComponent implements OnInit {
  @Input() id: string;parm: string;  
  public element: any;
  public relationId: string;
  public relationUrl: string;
  public presUrl: string;
  public sendData;
  public relationName: string;
  public report: string;
  public subRelId: string;
  public relationList = [];
  public medPresListData = [];
  public medPresList = [];
  public medPresFlag: boolean;
  public docnameFlag: boolean;
  dtOptions: any = {};

  constructor(  public gservice: CommonDataService,public clientservice:ClientViewService,public http:Http,public routes:ActivatedRoute,public el: ElementRef,public toastr:ToastrService,public dialog: MatDialog ,public router:Router) { 
    this.element = el.nativeElement;
    this.relationUrl = ipaddress.getIp + "record/getrel";
    this.presUrl = ipaddress.getIp+"record/medprescriptions/";
    this.medPresFlag= true;
      this.sendData = {
        client_reg_id:  this.gservice.Client_login_detail_data.Client_Id,
        home_care: "0",
      }
  }

  ngOnInit(): void {
    this.report = "Medical prescription";
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      iDisplayLength: 2,
      bFilter: false,
      pageLength: 10,
      "lengthMenu": [[5, 10, 25, 50, -1], [5, 10, 25, 50, "All"]],
      "columnDefs": [
        { "orderable": false, "targets": [0, 1, 2] },
      ],
      "language": {
        "search": "Filter"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12">><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"l><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    this.getrelationList();
  }

  getrelationList() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.relationUrl, this.sendData, { headers: headers }).subscribe(
      data => {
        var reldata = data.json();
        if (reldata != null && reldata.relationships.length != 0) {
          for(var i=0;i<reldata.relationships.length;i++){
            this.relationList.push({
              rel_id:reldata.relationships[i].relation_id,
              rel_desc:reldata.relationships[i].relation,
            });
          }
          this.changeRelation(this.relationList[0].rel_id);
        } 
      }
    )
  }

  changeRelation(data){
    for(var i=0;i<this.relationList.length;i++){
      if(data == this.relationList[i].rel_id){
        this.relationId = this.relationList[i].rel_id;
        this.relationName= this.relationList[i].rel_desc;
        this.medPresFlag=true;
        this.getMedPresList();
      }
    }
  }
  
  getMedPresList() {
    this.sendData = {};
     this.sendData.client_id =  this.gservice.Client_login_detail_data.Client_Id;;
    this.sendData.home_care = "0";
    this.sendData.relation = this.relationName; 
    this.medPresList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.presUrl, this.sendData, { headers: headers }).subscribe(
      data => {
        var dataval = data.json();
        if (dataval != null) {
          this.medPresListData = dataval.prescriptions;
          if (this.medPresListData != null) {
            for (var i = 0; i < this.medPresListData.length; i++) {
              var new_datalist = this.medPresListData[i];
              this.docnameFlag = false;
              var Docname = "";
              var res_clientname = "";
              if(new_datalist.first_name != undefined){
                if (new_datalist.middle_name != undefined && new_datalist.middle_name != "") {
                  Docname = new_datalist.first_name + " " + new_datalist.middle_name + " " + new_datalist.last_name;
                } else {
                  Docname = new_datalist.first_name + " " + new_datalist.last_name;
                }
                this.docnameFlag=true;
              }
              if (new_datalist.client_middle_name != undefined && new_datalist.client_middle_name != "") {
                res_clientname = encrypt_decript.Decript(new_datalist.client_first_name) + " " + encrypt_decript.Decript(new_datalist.client_middle_name) + " " + encrypt_decript.Decript(new_datalist.client_last_name);
              } else {
                res_clientname = encrypt_decript.Decript(new_datalist.client_first_name) + " " + encrypt_decript.Decript(new_datalist.client_last_name);
              }
              var App_date = "";
              if (new_datalist.date != null) {
                App_date = new_datalist.date.split('-')[2] + "-" + new_datalist.date.split('-')[1] + "-" + new_datalist.date.split('-')[0];
              }
              var pres_drug_id = "";
              this.medPresList.push({
                doctorname: Docname,
                clientname: res_clientname,
                date: App_date,
                pres_drug_id: this.medPresListData[i].pres_drug_id,
                docname_flag: this.docnameFlag
              });
            }
          }
          if (this.medPresList.length == 0) {
            this.medPresFlag = true;
          } else {
            this.medPresFlag = false;
          }
        }
      },
      error => {
      }
    )
  }

  viewMedPres(pres_id){
   var data1=null;
    data1={
      pres_id:pres_id,
      AppFlow: "ordpresdet",
      flow: "order"
    }
    Client_Helper.setmedialpres(data1)
    this.clientservice.sendMessage("recordwallet_medpres")
  }

}
