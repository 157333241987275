import { Component, OnInit,HostListener } from '@angular/core';
import { Helper_Class } from '../../helper_class';
import { Router, ActivatedRoute } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { PhysioCasesheetService } from '../physio-casesheet/physio-casesheet.service';
import { Physio_Helper } from '../Physio_Helper';
import { Message_data } from '../../../assets/js/Message_data';
import { DietpopupwindowComponent } from '../../Doctor_module/dietpopupwindow/dietpopupwindow.component';
import { DietplanEditComponent } from '../../Doctor_module/dietplan-edit/dietplan-edit.component';
import { DietplanhistorypopupComponent } from '../../Doctor_module/dietplanhistorypopup/dietplanhistorypopup.component';
import { ipaddress } from '../../ipaddress';

@Component({
  selector: 'app-physio-diet-plan',
  templateUrl: './physio-diet-plan.component.html',
  styleUrls: ['./physio-diet-plan.component.scss']
})
export class PhysioDietPlanComponent implements OnInit {
  public dietPlanFlag = "min";
  public foodCategoryList = [];
  public morFoodTypeList = [];
  public morSubfoodTypeList = [];
  public mornFoodCategory: string;
  public morFoodType: string;
  public morSubFoodType: string;
  public morMeasure: string;
  public morWeight: any;
  public morEnegry: any;
  public morQuantity: string;
  public dietMorFoodData = [];
  public morningIndex: number;
  public calories;
  public caloriesRequired: string;
  public totalCalories: any;
  public morningFlag: boolean;
  public breakFlag: boolean;
  public dplanBreakFlag;
  public dplanMidFlag;
  public dplanLunchFlag;
  public dplanEveningFlag;
  public eveningFlag;
  public dplanDinnerFlag;
  public dplanNightFlag;
  public lifeStyleId;
  public bfFoodCategory: string;
  public bfFoodType: string;
  public bfSubFoodType: string;
  public bfQuantity: string;
  public bfMeasure: string;
  public bfWeight: any;
  public bfEnergy: any;
  public breakfastTableFlag: boolean;
  public sgaOverallScore: string;
  public bfFoodTypeList = [];
  public bfSubFoodTypeList = [];
  public dietBreakfastFoodData = [];
  public middayFoodCategory: string;
  public middayFoodType: string;
  public middaySubFoodType: string;
  public middayQuantity: string;
  public middayMeasure: string;
  public middayWeight: any;
  public middayEnergy: any;
  public middayTableFlag: boolean;
  public middayFoodTypeList = [];
  public middaySubfoodTypeList = [];
  public dietMiddayFoodData = [];
  public lunchFoodCategory: string;
  public lunchFoodType: string;
  public lunchSubFoodType: string;
  public lunchQuantity: string;
  public lunchMeasure: string;
  public lunchWeight: any;
  public lunchEnergy: any;
  public lunchTableFlag: boolean;
  public lunchFoodTypeList = [];
  public lunchSubFoodTypeList = [];
  public dietLunchFoodData = [];
  public eveFoodCategory: string;
  public eveFoodType: string;
  public eveSubFoodType: string;
  public eveQuantity: string;
  public eveMeasure: string;
  public eveWeight: any;
  public eveEnergy: any;
  public eveTableFlag: boolean;
  public eveFoodTypeList = [];
  public eveSubFoodTypeList = [];
  public dietEveFoodData = [];
  public dinnerFoodCategory: string;
  public dinnerFoodType: string;
  public dinnerSubFoodType: string;
  public dinnerQuantity: string;
  public dinnerMeasure: string;
  public dinnerWeight: any;
  public dinnerEnergy: any;
  public dinnerTableFlag: boolean;
  public dinnerFoodTypeList = [];
  public dinnerSubfoodTypeList = [];
  public dietDinnerFoodData = [];
  public nightFoodCategory: string;
  public nightFoodType: string;
  public nightSubFoodType: string;
  public nightQuantity: string;
  public nightMeasure: string;
  public nightWeight: any;
  public nightEnergy: any;
  public nightTableFlag: boolean;
  public nightFoodTypeList = [];
  public nightSubfoodTypeList = [];
  public dietNightFoodData = [];
  public calcNumber: number;
  public calcQuantity: number;
  public calcWeight: number;
  public calcEnergy: number;
  public calcMeasure: string;
  public caloriesLabel;
  public caloriesFlag: boolean;
  public totalCaloriesLabel;
  public totalCaloriesFlag: boolean;
  public sgaLabel;
  public sgaFlag: boolean;
  public showMorningFlag: boolean;
  public showBreakfastFlag: boolean;
  public showMiddayFlag: boolean;
  public showEveningFlag: boolean;
  public showDinnerFlag: boolean;
  public showNightFlag: boolean;
  public showLunchFlag: boolean;
  public dietMorning: string;
  public dietBreakfast: string;
  public dietMidday: string;
  public dietLunch: string;
  public dietEvening: string;
  public dietDinner: string;
  public dietNight: string;
  public morFoodCond: string;
  public breakfastCond: string;
  public middayCond: string;
  public lunchCond: string;
  public eveCond: string;
  public dinnerCond: string;
  public nightCond: string;
  public patientList;
  public dplanMorFlag;
  public dietPlanAll = "0";
  subscription:Subscription;

  constructor(public dropdown_dialog:MatDialog,public dialog:MatDialog,public messageservice:PhysioCasesheetService,public http:Http,public routes:ActivatedRoute,public router:Router,public toastr:ToastrService)   {
    this.caloriesFlag = true;
    this.totalCaloriesFlag = true;
    this.sgaFlag = true;
    this.showMorningFlag = true;
    this.showBreakfastFlag = true;
    this.showMiddayFlag = true;
    this.showEveningFlag = true;
    this.showDinnerFlag = true;
    this.showNightFlag = true;
    this.showLunchFlag = true;
    this.morningFlag = true;
    this.breakfastTableFlag = true;
    this.middayTableFlag = true;
    this.lunchTableFlag = true;
    this.eveTableFlag = true;
    this.dinnerTableFlag = true;
    this.nightTableFlag = true;
    this.calories = "0";
    this.caloriesRequired = "0";
    this.totalCalories = "0";
    this.calcQuantity = 0;
    this.calcWeight = 0;
    this.calcEnergy = 0;
    this.calcMeasure = "";
  }

  ngOnInit(): void {
    this.subscription = this.messageservice.getCasesheetMessage().subscribe(message => {
      if (message) {
          this.dietPlanFlag = message;
      }
    });
    this.patientList = Physio_Helper.getClient_Info();
    if(Physio_Helper.getHopitalfieldsRet() != undefined){
      var objFields = Physio_Helper.getHopitalfieldsRet();
      for (var i = 0; i < objFields.length; i++) {
        if (objFields[i].page_name == "Diet plan") {
          if (objFields[i].fields != undefined) {
            if (objFields[i].fields != undefined) {
              for (var j = 0; j < objFields[i].fields.length; j++) {
                if (objFields[i].fields[j].field_name == "Calories required") {
                  this.caloriesLabel = objFields[i].fields[j].field_name;
                  this.caloriesFlag = false;
                }
                if (objFields[i].fields[j].field_name == "Total calories (Kcal)") {
                  this.totalCaloriesLabel = objFields[i].fields[j].field_name;
                  this.totalCaloriesFlag = false;
                }
                if (objFields[i].fields[j].field_name == "SGA score") {
                  this.sgaLabel = objFields[i].fields[j].field_name;
                  this.sgaFlag = false;
                }
                if (objFields[i].fields[j].field_name == "Diet morning") {
                  this.showMorningFlag = false;
                }
                if (objFields[i].fields[j].field_name == "Diet breakfast") {
                  this.showBreakfastFlag = false;
                }
                if (objFields[i].fields[j].field_name == "Diet Mid-day snacks") {
                  this.showMiddayFlag = false;
                }
                if (objFields[i].fields[j].field_name == "Diet Evening snacks") {
                  this.showEveningFlag = false;
                }
                if (objFields[i].fields[j].field_name == "Diet Dinner") {
                  this.showDinnerFlag = false;
                }
                if (objFields[i].fields[j].field_name == "Diet Night snacks") {
                  this.showNightFlag = false;
                }
                if (objFields[i].fields[j].field_name == "Diet lunch") {
                  this.showLunchFlag = false;
                }
              }
            }
          }
        }
      }
    }
    this.coloriesRequired();
    this.getDietPlanDetails();
  }

  @HostListener('window:click', ['$event'])
  onclick(event: any): void {
    this.sendData();
  }
  @HostListener('window:blur', ['$event'])
  onBlur(event: any): void {
    this.sendData();
  }

  sendData() {
    var sga_send = null;
    var sub_rel = null;
    if (localStorage.getItem("sub_id") != null) {
      sub_rel = localStorage.getItem("sub_id");
    } else {
      sub_rel = undefined;
    }
    var tcal_to_txt = null;
    if (this.totalCalories != "") {
      tcal_to_txt = this.totalCalories;
    } else {
      tcal_to_txt = "0"
    }
    var get_ret = Physio_Helper.getRet();
    var get_life_sty_id = null;
    if (get_ret != undefined && get_ret.life_style_id != null) {
      get_life_sty_id = get_ret.life_style_id;
    } else {
      get_life_sty_id = undefined;
    }
    var diet_mrng_arry = [];
    var flag_data = false;
    if (this.dietMorFoodData.length != 0) {
      for (var i = 0; i < this.dietMorFoodData.length; i++) {
        diet_mrng_arry.push({
          food_item_id: this.dietMorFoodData[i].food_item_id,
          quantity: this.dietMorFoodData[i].quantity,
          energy: this.dietMorFoodData[i].energy,
          cond: this.dietMorFoodData[i].cond,
        });
      }
      flag_data = true;
    } else {
      if (this.dplanMorFlag == "1") {
        diet_mrng_arry.push({
          flag: "No"
        });
      }
    }
    var break_fast_arrydata = [];
    if (this.dietBreakfastFoodData.length != 0) {
      for (var i = 0; i < this.dietBreakfastFoodData.length; i++) {
        break_fast_arrydata.push({
          food_item_id: this.dietBreakfastFoodData[i].food_item_id,
          quantity: this.dietBreakfastFoodData[i].quantity,
          energy: this.dietBreakfastFoodData[i].energy,
          cond: this.dietBreakfastFoodData[i].cond,
        });
        flag_data = true;
      }
    } else {
      if (this.dplanBreakFlag == "1") {
        break_fast_arrydata.push({
          flag: "No"
        });
      }
    }
    var mid_day_arrydata = [];
    if (this.dietMiddayFoodData.length != 0) {
      for (var i = 0; i < this.dietMiddayFoodData.length; i++) {
        mid_day_arrydata.push({
          food_item_id: this.dietMiddayFoodData[i].food_item_id,
          quantity: this.dietMiddayFoodData[i].quantity,
          energy: this.dietMiddayFoodData[i].energy,
          cond: this.dietMiddayFoodData[i].cond,
        });
        flag_data = true;
      }
    } else {
      if (this.dplanMidFlag == "1") {
        mid_day_arrydata.push({
          flag: "No"
        });
        flag_data = true;
      }
    }
    var lunch_arrydata = [];
    if (this.dietLunchFoodData.length != 0) {
      for (var i = 0; i < this.dietLunchFoodData.length; i++) {
        lunch_arrydata.push({
          food_item_id: this.dietLunchFoodData[i].food_item_id,
          quantity: this.dietLunchFoodData[i].quantity,
          energy: this.dietLunchFoodData[i].energy,
          cond: this.dietLunchFoodData[i].cond
        });
        flag_data = true;
      }
    } else {
      if (this.dplanLunchFlag == "1") {
        lunch_arrydata.push({
          flag: "No"
        });
      }
    }
    var evening_arrydata = [];
    if (this.dietEveFoodData.length != 0) {
      for (var i = 0; i < this.dietEveFoodData.length; i++) {
        evening_arrydata.push({
          food_item_id: this.dietEveFoodData[i].food_item_id,
          quantity: this.dietEveFoodData[i].quantity,
          energy: this.dietEveFoodData[i].energy,
          cond: this.dietEveFoodData[i].cond
        });
        flag_data = true;
      }
    } else {
      if (this.dplanEveningFlag == "1") {
        evening_arrydata.push({
          flag: "No"
        });
      }
    }
    var dinner_arrydata = [];
    if (this.dietDinnerFoodData.length != 0) {
      for (var i = 0; i < this.dietDinnerFoodData.length; i++) {
        dinner_arrydata.push({
          food_item_id: this.dietDinnerFoodData[i].food_item_id,
          quantity: this.dietDinnerFoodData[i].quantity,
          energy: this.dietDinnerFoodData[i].energy,
          cond: this.dietDinnerFoodData[i].cond
        });
        flag_data = true;
      }
    } else {
      if (this.dplanDinnerFlag == "1") {
        dinner_arrydata.push({
          flag: "No"
        });
      }
    }
    var night_arrydata = [];
    if (this.dietNightFoodData.length != 0) {
      for (var i = 0; i < this.dietNightFoodData.length; i++) {
        night_arrydata.push({
          food_item_id: this.dietNightFoodData[i].food_item_id,
          quantity: this.dietNightFoodData[i].quantity,
          energy: this.dietNightFoodData[i].energy,
          cond: this.dietNightFoodData[i].cond
        });
      }
    } else {
      if (this.dplanNightFlag == "1") {
        night_arrydata.push({
          flag: "No"
        });
      }
    }
    sga_send = {
      overall_rating: this.sgaOverallScore,
      client_reg_id: localStorage.getItem("Client_id"),
      relation_id: localStorage.getItem("rel_id"),
      sub_rel_id: sub_rel,
      country: ipaddress.country_code,
      cal_required: this.caloriesRequired,
      cal_intake: tcal_to_txt,
      life_style_id: get_life_sty_id,
      dplan_morning: diet_mrng_arry,
      dplan_breakfast: break_fast_arrydata,
      dplan_midday: mid_day_arrydata,
      dplan_lunch: lunch_arrydata,
      dplan_evening: evening_arrydata,
      dplan_dinner: dinner_arrydata,
      dplan_night: night_arrydata,
      diet_plan: this.dietPlanAll
    }
    Physio_Helper.setDiet(sga_send);
  }

  isReadonly() { return true; }

  addDietData(value, array){
    var dietarray = array;
    var dispstring: string;
    for(var i=0;i<dietarray.length;i++){
      if(dietarray[i].measure !="item(s)"){
        if(dietarray[i].cond !=undefined && dietarray[i].cond =="or" ){
          if(dispstring != undefined){
            dispstring+=dietarray[i].quantity+" "+dietarray[i].measure+" "+dietarray[i].food_sub_type+" - "+dietarray[i].description+"<br/>"+dietarray[i].cond +" "
          }else{
            dispstring=dietarray[i].quantity+" "+dietarray[i].measure+" "+dietarray[i].food_sub_type+" - "+dietarray[i].description+"<br/>"+dietarray[i].cond +" "
          }
        } else if(dietarray[i].cond !="or" && dietarray[i].cond !="Select" && dietarray[i].cond !=undefined) {
          if(dispstring != undefined){
            dispstring+=dietarray[i].quantity+" "+dietarray[i].measure+" "+dietarray[i].food_sub_type+" - "+dietarray[i].description+" "+dietarray[i].cond+" "
          }else{
            dispstring=dietarray[i].quantity+" "+dietarray[i].measure+" "+dietarray[i].food_sub_type+" - "+dietarray[i].description+" "+dietarray[i].cond+" "
          }
        } else{
          if(dispstring != undefined){
            dispstring+=dietarray[i].quantity+" "+dietarray[i].measure+" "+dietarray[i].food_sub_type+" - "+dietarray[i].description
          }else{
            dispstring=dietarray[i].quantity+" "+dietarray[i].measure+" "+dietarray[i].food_sub_type+" - "+dietarray[i].description
          }
        }
      } else{
        if(dietarray[i].cond !=undefined && dietarray[i].cond =="or" ){
          if(dispstring != undefined){
            dispstring+=dietarray[i].quantity+" "+dietarray[i].food_sub_type+" - "+dietarray[i].description+"<br/>"+dietarray[i].cond +" "
          }else{
            dispstring=dietarray[i].quantity+" "+dietarray[i].food_sub_type+" - "+dietarray[i].description+"<br/>"+dietarray[i].cond +" "
          }
        } else if(dietarray[i].cond !="or" && dietarray[i].cond !="Select" && dietarray[i].cond !=undefined) {
          if(dispstring != undefined){
            dispstring+=dietarray[i].quantity+" "+dietarray[i].food_sub_type+" - "+dietarray[i].description+" "+dietarray[i].cond+" "
          }else{
            dispstring=dietarray[i].quantity+" "+dietarray[i].food_sub_type+" - "+dietarray[i].description+" "+dietarray[i].cond+" "
          }
        } else{
          if(dispstring != undefined){
            dispstring+=dietarray[i].quantity+" "+dietarray[i].food_sub_type+" - "+dietarray[i].description
          }else{
            dispstring=dietarray[i].quantity+" "+dietarray[i].food_sub_type+" - "+dietarray[i].description
          }
        }
      }
    }
    if(value =="mor"){
      this.dietMorning = dispstring;
      localStorage.setItem("morning_diet",this.dietMorning);
    } else if(value =="break"){
      this.dietBreakfast = dispstring;
      localStorage.setItem("break_diet",this.dietBreakfast);
    }else if(value =="midday"){
      this.dietMidday = dispstring;
      localStorage.setItem("midday_diet",this.dietMidday);
    } else if(value =="lunch"){
      this.dietLunch = dispstring;
      localStorage.setItem("lunch_diet",this.dietLunch);
    } else if(value =="eve"){
      this.dietEvening = dispstring;
      localStorage.setItem("eve_diet",this.dietEvening);
    } else if(value =="dinner"){
      this.dietDinner = dispstring;
      localStorage.setItem("dinner_diet",this.dietDinner);
    } else if(value =="night"){
      this.dietNight = dispstring;
      localStorage.setItem("night_diet",this.dietNight);
    }
  }

  coloriesRequired() {
    if (Helper_Class.getCasesheetPersonal() != undefined && Helper_Class.getCasesheetPersonal()["bmr"]) {
      if (Helper_Class.getCasesheetPersonal()["exercise_routine_des"] == "Light") {
        var get_txt = Helper_Class.getCasesheetPersonal()["bmr"] * 1.375;
        this.caloriesRequired = Math.round(get_txt).toString();
      } else if (Helper_Class.gethabitual()["exercise_routine_des"] == "Moderate") {
        var get_txt = Helper_Class.gethabitual()["bmr"] * 1.55;
        this.caloriesRequired = Math.round(get_txt).toString();
      } else if (Helper_Class.gethabitual()["exercise_routine_des"] == "Heavy") {
        var get_txt = Helper_Class.gethabitual()["bmr"] * 1.725;
        this.caloriesRequired = Math.round(get_txt).toString();
      } else if (Helper_Class.gethabitual()["exercise_routine_des"] == "Very Heavy") {
        var get_txt = Helper_Class.getCasesheetPersonal()["bmr"] * 1.9;
        this.caloriesRequired = Math.round(get_txt).toString();
      } else {
        var get_txt = parseFloat(Helper_Class.getCasesheetPersonal()["bmr"]) * 1.375;
        this.caloriesRequired = Math.round(get_txt).toString();
      }
    }
    else {
      this.caloriesRequired ="0";
    }
  }

  openModal() {
    const dialogRef = this.dialog.open(DietpopupwindowComponent, {
      width: '70%',
      height: '500px',
    });
    dialogRef.afterClosed().subscribe(result => {
      this.sgaOverallScore = result;
    });
  }

  getDietPlanDetails() {
    var sen_pass;
    if (this.patientList.sub_id != null && this.patientList.sub_id != undefined &&
      this.patientList.sub_id != "" && this.patientList.sub_id.length != 0) {
      sen_pass = JSON.stringify({
        client_reg_id: this.patientList.Client_id,
        relation_id: this.patientList.rel_id,
        sub_rel_id: this.patientList.sub_id,
        country: "IN",
      })
    }
    else {
      sen_pass = JSON.stringify({
        client_reg_id: this.patientList.Client_id,
        relation_id: this.patientList.rel_id,
        country: "IN",
      })
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'lifestyle/dplan',sen_pass,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (Physio_Helper.getRet() != undefined && Physio_Helper.getRet()["bmr"]) {
            if (Physio_Helper.getRet()["EXERCISE_ROUTINE_DESC"] == "Light") {
              var get_txt = Physio_Helper.getRet()["bmr"] * 1.375;
              this.caloriesRequired = Math.round(get_txt).toString();
            } else if (Physio_Helper.getRet()["EXERCISE_ROUTINE_DESC"] == "Moderate") {
              var get_txt = Physio_Helper.getRet()["bmr"] * 1.55;
              this.caloriesRequired = Math.round(get_txt).toString();
            } else if (Physio_Helper.getRet()["EXERCISE_ROUTINE_DESC"] == "Heavy") {
              var get_txt = Physio_Helper.getRet()["bmr"] * 1.725;
              this.caloriesRequired = Math.round(get_txt).toString();
            } else if (Physio_Helper.getRet()["EXERCISE_ROUTINE_DESC"] == "Very Heavy") {
              var get_txt = Physio_Helper.getRet()["bmr"] * 1.9;
              this.caloriesRequired = Math.round(get_txt).toString();
            } else {
              var get_txt = parseFloat(Physio_Helper.getRet()["bmr"]) * 1.375;
              this.caloriesRequired = Math.round(get_txt).toString();
            }
          }
          if (obj.cal_intake != null) {
            this.totalCalories = Math.round(obj.cal_intake).toString();
          }
          if (obj.sga_overall_rating != null) {
            this.sgaOverallScore = obj.sga_overall_rating;
          }
          if (obj.dplan_morning != null && obj.dplan_morning.length != 0) {
            if (obj.dplan_morning != null) {
              for (var i = 0; i < obj.dplan_morning.length; i++) {
                this.dplanMorFlag = "1";
                this.dietPlanAll = "1";
                this.morningFlag = false;
                this.dietMorFoodData.push({
                  category: obj.dplan_morning[i].category,
                  food_sub_type: obj.dplan_morning[i].food_sub_type,
                  description: obj.dplan_morning[i].description,
                  quantity: obj.dplan_morning[i].quantity,
                  measure: obj.dplan_morning[i].measure,
                  weight: obj.dplan_morning[i].weight,
                  energy: obj.dplan_morning[i].energy,
                  food_item_id: obj.dplan_morning[i].food_item_id,
                  cond: obj.dplan_morning[i].cond
                });
                this.addDietData("mor", this.dietMorFoodData);
              }
              for (var i = 0; i < this.dietMorFoodData.length; i++) {
                if (this.dietMorFoodData.length == 0) {
                  if (this.calories == "0") {
                    this.calories = this.dietMorFoodData[i].energy;
                    this.setCalories();
                  } else {
                    var tot = parseFloat(this.calories) + parseFloat(this.dietMorFoodData[i].energy);
                    this.calories = tot;
                    this.setCalories();
                  }
                } else {
                  if (this.calories == "0") {
                    this.calories = this.dietMorFoodData[i].energy;
                    this.setCalories();
                  } else {
                    var tot = parseFloat(this.calories) + parseFloat(this.dietMorFoodData[i].energy);
                    this.calories = tot;
                    this.setCalories();
                  }
                }
              }
            }
          }
          if (obj.dplan_breakfast != null && obj.dplan_breakfast.length != 0) {
            this.breakFlag = false;
            this.dplanBreakFlag = "1";
            this.dietPlanAll = "1";
            for (var i = 0; i < obj.dplan_breakfast.length; i++) {
              this.dietBreakfastFoodData.push({
                category: obj.dplan_breakfast[i].category,
                food_sub_type: obj.dplan_breakfast[i].food_sub_type,
                description: obj.dplan_breakfast[i].description,
                quantity: obj.dplan_breakfast[i].quantity,
                measure: obj.dplan_breakfast[i].measure,
                weight: obj.dplan_breakfast[i].weight,
                energy: obj.dplan_breakfast[i].energy,
                food_item_id: obj.dplan_breakfast[i].food_item_id,
                cond: obj.dplan_breakfast[i].cond
              });
              this.addDietData("break", this.dietBreakfastFoodData);
            }
            for (var i = 0; i < this.dietBreakfastFoodData.length; i++) {
              if (this.dietBreakfastFoodData.length == 0) {
                if (this.calories == "0") {
                  this.calories = this.dietBreakfastFoodData[i].energy;
                  this.setCalories();
                } else {
                  var tot = parseFloat(this.calories) + parseFloat(this.dietBreakfastFoodData[i].energy);
                  this.calories = tot;
                  this.setCalories();
                }
              } else {
                if (this.calories == "0") {
                  this.calories = this.dietBreakfastFoodData[i].energy;
                  this.setCalories();
                } else {
                  var tot = parseFloat(this.calories) + parseFloat(this.dietBreakfastFoodData[i].energy);
                  this.calories = tot;
                  this.setCalories();
                }
              }
            }
          }
          if (obj.dplan_midday != null && obj.dplan_midday.length != 0) {
            this.middayTableFlag = false;
            for (var i = 0; i < obj.dplan_midday.length; i++) {
              this.dplanMidFlag = "1";
              this.dietPlanAll = "1";
              this.dietMiddayFoodData.push({
                category: obj.dplan_midday[i].category,
                food_sub_type: obj.dplan_midday[i].food_sub_type,
                description: obj.dplan_midday[i].description,
                quantity: obj.dplan_midday[i].quantity,
                measure: obj.dplan_midday[i].measure,
                weight: obj.dplan_midday[i].weight,
                energy: obj.dplan_midday[i].energy,
                food_item_id: obj.dplan_midday[i].food_item_id,
                cond: obj.dplan_midday[i].cond
              });
              this.addDietData("midday", this.dietMiddayFoodData);
            }
            for (var i = 0; i < this.dietMiddayFoodData.length; i++) {
              if (this.dietMiddayFoodData.length == 0) {
                if (this.calories == "0") {
                  this.calories = this.dietMiddayFoodData[i].energy;
                  this.setCalories();
                } else {
                  var tot = parseFloat(this.calories) + parseFloat(this.dietMiddayFoodData[i].energy);
                  this.calories = tot;
                  this.setCalories();
                }
              } else {
                if (this.calories == "0") {
                  this.calories = this.dietMiddayFoodData[i].energy;
                  this.setCalories();
                } else {
                  var tot = parseFloat(this.calories) + parseFloat(this.dietMiddayFoodData[i].energy);
                  this.calories = tot;
                  this.setCalories();
                }
              }
            }
          }
          if (obj.dplan_lunch != null && obj.dplan_lunch.length != 0) {
            for (var i = 0; i < obj.dplan_lunch.length; i++) {
              this.dplanLunchFlag = "1";
              this.dietPlanAll = "1";
              this.lunchTableFlag = false;
              this.dietLunchFoodData.push({
                category: obj.dplan_lunch[i].category,
                food_sub_type: obj.dplan_lunch[i].food_sub_type,
                description: obj.dplan_lunch[i].description,
                quantity: obj.dplan_lunch[i].quantity,
                measure: obj.dplan_lunch[i].measure,
                weight: obj.dplan_lunch[i].weight,
                energy: obj.dplan_lunch[i].energy,
                food_item_id: obj.dplan_lunch[i].food_item_id,
                cond: obj.dplan_lunch[i].cond
              });
              this.addDietData("lunch", this.dietLunchFoodData);
            }
            for (var i = 0; i < this.dietLunchFoodData.length; i++) {
              if (this.dietLunchFoodData.length == 0) {
                if (this.calories == "0") {
                  this.calories = this.dietLunchFoodData[i].energy;
                  this.setCalories();
                } else {
                  var tot = parseFloat(this.calories) + parseFloat(this.dietLunchFoodData[i].energy);
                  this.calories = tot;
                  this.setCalories();
                }
              } else {
                if (this.calories == "0") {
                  this.calories = this.dietLunchFoodData[i].energy;
                  this.setCalories();
                } else {
                  var tot = parseFloat(this.calories) + parseFloat(this.dietLunchFoodData[i].energy);
                  this.calories = tot;
                  this.setCalories();
                }
              }
            }
          }
          if (obj.dplan_evening != null && obj.dplan_evening.length != 0) {
            this.dplanEveningFlag = "1";
            this.dietPlanAll = "1";
            for (var i = 0; i < obj.dplan_evening.length; i++) {
              this.eveningFlag = false;
              this.dietEveFoodData.push({
                category: obj.dplan_evening[i].category,
                food_sub_type: obj.dplan_evening[i].food_sub_type,
                description: obj.dplan_evening[i].description,
                quantity: obj.dplan_evening[i].quantity,
                measure: obj.dplan_evening[i].measure,
                weight: obj.dplan_evening[i].weight,
                energy: obj.dplan_evening[i].energy,
                food_item_id: obj.dplan_evening[i].food_item_id,
                cond: obj.dplan_evening[i].cond
              });
              this.addDietData("eve", this.dietEveFoodData);
            }
            for (var i = 0; i < this.dietEveFoodData.length; i++) {
              if (this.dietEveFoodData.length == 0) {
                if (this.calories == "0") {
                  this.calories = this.dietEveFoodData[i].energy;
                  this.setCalories();
                } else {
                  var tot = parseFloat(this.calories) + parseFloat(this.dietEveFoodData[i].energy);
                  this.calories = tot;
                  this.setCalories();
                }
              } else {
                if (this.calories == "0") {
                  this.calories = this.dietEveFoodData[i].energy;
                  this.setCalories();
                } else {
                  var tot = parseFloat(this.calories) + parseFloat(this.dietEveFoodData[i].energy);
                  this.calories = tot;
                  this.setCalories();
                }
              }
            }
          }
          if (obj.dplan_dinner != null && obj.dplan_dinner.length != 0) {
            this.dplanDinnerFlag = "1";
            this.dietPlanAll = "1";
            for (var i = 0; i < obj.dplan_dinner.length; i++) {
              this.dinnerTableFlag = false;
              this.dietDinnerFoodData.push({
                category: obj.dplan_dinner[i].category,
                food_sub_type: obj.dplan_dinner[i].food_sub_type,
                description: obj.dplan_dinner[i].description,
                quantity: obj.dplan_dinner[i].quantity,
                measure: obj.dplan_dinner[i].measure,
                weight: obj.dplan_dinner[i].weight,
                energy: obj.dplan_dinner[i].energy,
                food_item_id: obj.dplan_dinner[i].food_item_id,
                cond: obj.dplan_dinner[i].cond
              });
              this.addDietData("dinner", this.dietDinnerFoodData);
            }
            for (var i = 0; i < this.dietDinnerFoodData.length; i++) {
              if (this.dietDinnerFoodData.length == 0) {
                if (this.calories == "0") {
                  this.calories = this.dietDinnerFoodData[i].energy;
                  this.setCalories();
                } else {
                  var tot = parseFloat(this.calories) + parseFloat(this.dietDinnerFoodData[i].energy);
                  this.calories = tot;
                  this.setCalories();
                }
              } else {
                if (this.calories == "0") {
                  this.calories = this.dietDinnerFoodData[i].energy;
                  this.setCalories();
                } else {
                  var tot = parseFloat(this.calories) + parseFloat(this.dietDinnerFoodData[i].energy);
                  this.calories = tot;
                  this.setCalories();
                }
              }
            }
          }
          if (obj.dplan_night != null && obj.dplan_night.length != 0) {
            this.dplanNightFlag = "1";
            this.dietPlanAll = "1";
            for (var i = 0; i < obj.dplan_night.length; i++) {
              this.nightTableFlag = false;
              this.dietNightFoodData.push({
                category: obj.dplan_night[i].category,
                food_sub_type: obj.dplan_night[i].food_sub_type,
                description: obj.dplan_night[i].description,
                quantity: obj.dplan_night[i].quantity,
                measure: obj.dplan_night[i].measure,
                weight: obj.dplan_night[i].weight,
                energy: obj.dplan_night[i].energy,
                food_item_id: obj.dplan_night[i].food_item_id,
                cond: obj.dplan_night[i].cond
              });
              this.addDietData("night", this.dietNightFoodData);
            }
            for (var i = 0; i < this.dietNightFoodData.length; i++) {
              if (this.dietNightFoodData.length == 0) {
                if (this.calories == "0") {
                  this.calories = this.dietNightFoodData[i].energy;
                  this.setCalories();
                } else {
                  var tot = parseFloat(this.calories) + parseFloat(this.dietNightFoodData[i].energy);
                  this.calories = tot;
                  this.setCalories();
                }
              } else {
                if (this.calories == "0") {
                  this.calories = this.dietNightFoodData[i].energy;
                  this.setCalories();
                } else {
                  var tot = parseFloat(this.calories) + parseFloat(this.dietNightFoodData[i].energy);
                  this.calories = tot;
                  this.setCalories();
                }
              }
            }
          }
          if (obj.life_style_id != null) {
            this.lifeStyleId = obj.life_style_id;
          }
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        }
      )
  }

  changeFoodType(session) {
    if (session == "Morning") {
      this.morMeasure = "";
      this.morWeight = "";
      this.morEnegry = "";
      this.morQuantity = undefined;
      this.morSubFoodType = undefined;
    } else if (session == "breakfast") {
      this.bfMeasure = "";
      this.bfWeight = "";
      this.bfEnergy = "";
      this.bfQuantity = "Select";
      this.bfSubFoodType = undefined;
    } else if (session == "lunch") {
      this.lunchMeasure = "";
      this.lunchWeight = "";
      this.lunchEnergy = "";
      this.lunchQuantity = "---Select---";
      this.lunchSubFoodType = undefined;
    } else if (session == "eve") {
      this.eveMeasure = "";
      this.eveWeight = "";
      this.eveEnergy = "";
      this.eveQuantity = "---Select---";
      this.eveSubFoodType = undefined;
    } else if (session == "dinner") {
      this.dinnerMeasure = "";
      this.dinnerWeight = "";
      this.dinnerEnergy = "";
      this.dinnerQuantity = "---Select---";
      this.dinnerSubFoodType = undefined;
    } else if (session == "night") {
      this.nightMeasure = "";
      this.nightWeight = "";
      this.nightEnergy = "";
      this.nightQuantity = "---Select---";
      this.nightSubFoodType = undefined;
    }
    var type_id = null, food_ty = null;
    for (var i = 0; i < this.foodCategoryList.length; i++) {
      if (session == "Morning") {
        if (this.mornFoodCategory == this.foodCategoryList[i].description) {
          type_id = this.foodCategoryList[i].type_id;
        }
        food_ty = this.morFoodType;
      } else if (session == "breakfast") {
        if (this.bfFoodCategory == this.foodCategoryList[i].description) {
          type_id = this.foodCategoryList[i].type_id;
        }
        food_ty = this.bfFoodType;
      } else if (session == "midday") {
        if (this.middayFoodCategory == this.foodCategoryList[i].description) {
          type_id = this.foodCategoryList[i].type_id;
        }
        food_ty = this.middayFoodType;
      } else if (session == "lunch") {
        if (this.lunchFoodCategory == this.foodCategoryList[i].description) {
          type_id = this.foodCategoryList[i].type_id;
        }
        food_ty = this.lunchFoodType;
      } else if (session == "eve") {
        if (this.eveFoodCategory == this.foodCategoryList[i].description) {
          type_id = this.foodCategoryList[i].type_id;
        }
        food_ty = this.eveFoodType;
      } else if (session == "dinner") {
        if (this.dinnerFoodCategory == this.foodCategoryList[i].description) {
          type_id = this.foodCategoryList[i].type_id;
        }
        food_ty = this.dinnerFoodType;
      } else if (session == "night") {
        if (this.nightFoodCategory == this.foodCategoryList[i].description) {
          type_id = this.foodCategoryList[i].type_id;
        }
        food_ty = this.nightFoodType;
      }
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'lifestyle/fstypes',
      JSON.stringify({
        food_category: type_id,
        food_type: food_ty
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (session == "Morning") {
            this.morSubfoodTypeList = obj.food_subtypes;
          } else if (session == "breakfast") {
            this.bfSubFoodTypeList = obj.food_subtypes;
          } else if (session == "midday") {
            this.middaySubfoodTypeList = obj.food_subtypes;
          } else if (session == "lunch") {
            this.lunchSubFoodTypeList = obj.food_subtypes;
          } else if (session == "eve") {
            this.eveSubFoodTypeList = obj.food_subtypes;
          } else if (session == "dinner") {
            this.dinnerSubfoodTypeList = obj.food_subtypes;
          } else if (session == "night") {
            this.nightSubfoodTypeList = obj.food_subtypes;
          }
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        }
      )
  }

  changeSubFoodType(foodsub, session) {
    this.calculationQuantity(foodsub, session);
    if (session == "Morning") {
      for (var i = 0; i < this.morSubfoodTypeList.length; i++) {
        if (this.morSubFoodType == this.morSubfoodTypeList[i].description) {
          this.morQuantity = this.morSubfoodTypeList[i].quantity;
          this.morMeasure = this.morSubfoodTypeList[i].measure;
          this.morWeight = this.morSubfoodTypeList[i].weight;
          this.morEnegry = this.morSubfoodTypeList[i].energy;
        }
      }
    } else if (session == "breakfast") {
      for (var i = 0; i < this.bfSubFoodTypeList.length; i++) {
        if (this.bfSubFoodType == this.bfSubFoodTypeList[i].description) {
          this.bfQuantity = this.bfSubFoodTypeList[i].quantity;
          this.bfMeasure = this.bfSubFoodTypeList[i].measure;
          this.bfWeight = this.bfSubFoodTypeList[i].weight;
          this.bfEnergy = this.bfSubFoodTypeList[i].energy;
        }
      }
    } else if (session == "midday") {
      for (var i = 0; i < this.middaySubfoodTypeList.length; i++) {
        if (this.middaySubFoodType == this.middaySubfoodTypeList[i].description) {
          this.middayQuantity = this.middaySubfoodTypeList[i].quantity;
          this.middayMeasure = this.middaySubfoodTypeList[i].measure;
          this.middayWeight = this.middaySubfoodTypeList[i].weight;
          this.middayEnergy = this.middaySubfoodTypeList[i].energy;
        }
      }
    } else if (session == "lunch") {
      for (var i = 0; i < this.lunchSubFoodTypeList.length; i++) {
        if (this.lunchSubFoodType == this.lunchSubFoodTypeList[i].description) {
          this.lunchQuantity = this.lunchSubFoodTypeList[i].quantity;
          this.lunchMeasure = this.lunchSubFoodTypeList[i].measure;
          this.lunchWeight = this.lunchSubFoodTypeList[i].weight;
          this.lunchEnergy = this.lunchSubFoodTypeList[i].energy;
        }
      }
    } else if (session == "eve") {
      for (var i = 0; i < this.eveSubFoodTypeList.length; i++) {
        if (this.eveSubFoodType == this.eveSubFoodTypeList[i].description) {
          this.eveQuantity = this.eveSubFoodTypeList[i].quantity;
          this.eveMeasure = this.eveSubFoodTypeList[i].measure;
          this.eveWeight = this.eveSubFoodTypeList[i].weight;
          this.eveEnergy = this.eveSubFoodTypeList[i].energy;
        }
      }
    } else if (session == "dinner") {
      for (var i = 0; i < this.dinnerSubfoodTypeList.length; i++) {
        if (this.dinnerSubFoodType == this.dinnerSubfoodTypeList[i].description) {
          this.dinnerQuantity = this.dinnerSubfoodTypeList[i].quantity;
          this.dinnerMeasure = this.dinnerSubfoodTypeList[i].measure;
          this.dinnerWeight = this.dinnerSubfoodTypeList[i].weight;
          this.dinnerEnergy = this.dinnerSubfoodTypeList[i].energy;
        }
      }
    } else if (session == "night") {
      for (var i = 0; i < this.nightSubfoodTypeList.length; i++) {
        if (this.nightSubFoodType == this.nightSubfoodTypeList[i].description) {
          this.nightQuantity = this.nightSubfoodTypeList[i].quantity;
          this.nightMeasure = this.nightSubfoodTypeList[i].measure;
          this.nightWeight = this.nightSubfoodTypeList[i].weight;
          this.nightEnergy = this.nightSubfoodTypeList[i].energy;
        }
      }
    }
  }

  changeFoodCategory(session) {
    if (session == "Morning") {
      this.morMeasure = "";
      this.morWeight = "";
      this.morEnegry = "";
      this.morQuantity = "---Select---";
      this.morSubfoodTypeList = [];
    } else if (session == "breakfast") {
      this.bfQuantity = "";
      this.bfMeasure = "";
      this.bfWeight = "";
      this.bfEnergy = "";
      this.bfSubFoodTypeList = [];
    } else if (session == "midday") {
      this.middayQuantity = "";
      this.middayMeasure = "";
      this.middayWeight = "";
      this.middayEnergy = "";
      this.middaySubfoodTypeList = [];
    } else if (session == "lunch") {
      this.lunchQuantity = "";
      this.lunchMeasure = "";
      this.lunchWeight = "";
      this.lunchEnergy = "";
      this.lunchSubFoodTypeList = [];
    } else if (session == "eve") {
      this.eveQuantity = "";
      this.eveMeasure = "";
      this.eveWeight = "";
      this.eveEnergy = "";
      this.eveSubFoodTypeList = [];
    } else if (session == "dinner") {
      this.dinnerQuantity = "";
      this.dinnerMeasure = "";
      this.dinnerWeight = "";
      this.dinnerEnergy = "";
      this.dinnerSubfoodTypeList = [];
    } else if (session == "night") {
      this.nightQuantity = "";
      this.nightMeasure = "";
      this.nightWeight = "";
      this.nightEnergy = "";
      this.nightSubfoodTypeList = [];
    }
    var type_id = null;
    for (var i = 0; i < this.foodCategoryList.length; i++) {
      if (session == "Morning") {
        if (this.mornFoodCategory == this.foodCategoryList[i].description) {
          type_id = this.foodCategoryList[i].type_id;
        }
      } else if (session == "breakfast") {
        if (this.bfFoodCategory == this.foodCategoryList[i].description) {
          type_id = this.foodCategoryList[i].type_id;
        }
      } else if (session == "midday") {
        if (this.middayFoodCategory == this.foodCategoryList[i].description) {
          type_id = this.foodCategoryList[i].type_id;
        }
      } else if (session == "lunch") {
        if (this.lunchFoodCategory == this.foodCategoryList[i].description) {
          type_id = this.foodCategoryList[i].type_id;
        }
      } else if (session == "eve") {
        if (this.eveFoodCategory == this.foodCategoryList[i].description) {
          type_id = this.foodCategoryList[i].type_id;
        }
      } else if (session == "dinner") {
        if (this.dinnerFoodCategory == this.foodCategoryList[i].description) {
          type_id = this.foodCategoryList[i].type_id;
        }
      } else if (session == "night") {
        if (this.nightFoodCategory == this.foodCategoryList[i].description) {
          type_id = this.foodCategoryList[i].type_id;
        }
      }
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'lifestyle/ftypes',
      JSON.stringify({
        food_category: type_id,
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (session == "Morning") {
            this.morFoodTypeList = obj.food_types;
          } else if (session == "breakfast") {
            this.bfFoodTypeList = obj.food_types;
          } else if (session == "midday") {
            this.middayFoodTypeList = obj.food_types;
          } else if (session == "lunch") {
            this.lunchFoodTypeList = obj.food_types;
          } else if (session == "eve") {
            this.eveFoodTypeList = obj.food_types;
          } else if (session == "dinner") {
            this.dinnerFoodTypeList = obj.food_types;
          } else if (session == "night") {
            this.nightFoodTypeList = obj.food_types;
          }
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        }
      )
  }

  getFoodCategory() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'lifestyle/fcat',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.foodCategoryList = obj.food_categories;
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        }
      )
  }

  addDietMorningFood() {
    if (this.mornFoodCategory == undefined || this.morFoodType == undefined || this.morSubFoodType == undefined || this.morQuantity == undefined
      || (this.morMeasure == undefined || this.morMeasure == "") || (this.morWeight == undefined || this.morWeight == "")
      || (this.morEnegry == undefined || this.morEnegry == "")) {
        this.toastr.error(Message_data.mandatory);
      } else {
      this.morningIndex = this.dietMorFoodData.length;
      this.morningFlag = false;
      var flag = true;
      if(this.morMeasure !="item(s)"){
        if(this.morFoodCond !=undefined && this.morFoodCond =="or" ){
          if(this.dietMorning != undefined){
            this.dietMorning+=this.morQuantity+" "+this.morMeasure+" "+this.morFoodType+" - "+this.morSubFoodType+"<br/>"+this.morFoodCond +" "
          }else{
            this.dietMorning=this.morQuantity+" "+this.morMeasure+" "+this.morFoodType+" - "+this.morSubFoodType+"<br/>"+this.morFoodCond +" "
          }
        } else if(this.morFoodCond !="or" && this.morFoodCond !="Select" && this.morFoodCond !=undefined) {
          if(this.dietMorning != undefined){
            this.dietMorning+=this.morQuantity+" "+this.morMeasure+" "+this.morFoodType+" - "+this.morSubFoodType+" "+this.morFoodCond+" "
          }else{
            this.dietMorning=this.morQuantity+" "+this.morMeasure+" "+this.morFoodType+" - "+this.morSubFoodType+" "+this.morFoodCond+" "
          }
        } else{
          if(this.dietMorning != undefined){
            this.dietMorning+=this.morQuantity+" "+this.morMeasure+" "+this.morFoodType+" - "+this.morSubFoodType
          }else{
            this.dietMorning=this.morQuantity+" "+this.morMeasure+" "+this.morFoodType+" - "+this.morSubFoodType
          }
        }
      } else{
        if(this.morFoodCond !=undefined && this.morFoodCond =="or" ){
          if(this.dietMorning != undefined){
            this.dietMorning+=this.morQuantity+" "+this.morFoodType+" - "+this.morSubFoodType+"<br/>"+this.morFoodCond +" "
          }else{
            this.dietMorning=this.morQuantity+" "+this.morFoodType+" - "+this.morSubFoodType+"<br/>"+this.morFoodCond +" "
          }
        } else if(this.morFoodCond !="or" && this.morFoodCond !="Select" && this.morFoodCond !=undefined) {
          if(this.dietMorning != undefined){
            this.dietMorning+=this.morQuantity+" "+this.morFoodType+" - "+this.morSubFoodType+" "+this.morFoodCond+" "
          }else{
            this.dietMorning=this.morQuantity+" "+this.morFoodType+" - "+this.morSubFoodType+" "+this.morFoodCond+" "
          }
        } else{
          if(this.dietMorning != undefined){
            this.dietMorning+=this.morQuantity+" "+this.morFoodType+" - "+this.morSubFoodType
          }else{
            this.dietMorning=this.morQuantity+" "+this.morFoodType+" - "+this.morSubFoodType
          }
        }
      }
      if (this.dietMorFoodData.length != 0) {
        for (var i = 0; i < this.dietMorFoodData.length; i++) {
          if (this.dietMorFoodData[i].description == this.morSubFoodType) {
            flag = false;
            this.toastr.error(Message_data.mandatory);
            break;
          }
        }
      }
      if (flag == true) {
        for (var i = 0; i < this.morSubfoodTypeList.length; i++) {
          if (this.morSubFoodType == this.morSubfoodTypeList[i].description) {
            var food_item_id = this.morSubfoodTypeList[i].food_item_id;
          }
        }
        this.dietMorFoodData.push({
          category: this.mornFoodCategory,
          food_sub_type: this.morFoodType,
          description: this.morSubFoodType,
          quantity: this.morQuantity,
          measure: this.morMeasure,
          weight: this.morWeight,
          energy: this.morEnegry,
          food_item_id: food_item_id,
          cond:this.morFoodCond
        });
        if (this.dietMorFoodData.length == 0) {
          if (this.calories == "0") {
            this.calories = parseInt(this.morEnegry);
            this.setCalories();
          } else {
            var tot = parseFloat(this.calories) + parseFloat(this.morEnegry);
            this.calories = tot.toString();
            this.setCalories();
          }
        } else {
          if (this.calories == "0") {
            this.calories = parseInt(this.morEnegry);
            this.setCalories();
          } else {
            var tot = parseFloat(this.calories) + parseFloat(this.morEnegry);
            this.calories = tot.toString();
            this.setCalories();
          }
        }
        this.mornFoodCategory = undefined;
        this.morFoodType = undefined;
        this.morSubFoodType = undefined;
        this.morQuantity = undefined;
        this.morMeasure = '';
        this.morWeight = '';
        this.morEnegry = '';
      }
    }
  }

  addDietBreakfastFood() {
    if (this.bfFoodCategory == undefined || this.bfFoodType == undefined || this.bfSubFoodType == undefined || this.bfQuantity == undefined
      || (this.bfMeasure == undefined || this.bfMeasure == "") || (this.bfWeight == undefined || this.bfWeight == "")
      || (this.bfEnergy == undefined || this.bfEnergy == "")) {
        this.toastr.error(Message_data.mandatory);
      } else {
      this.breakfastTableFlag = false;
      var flag = true;
      if (this.dietBreakfastFoodData.length != 0) {
        for (var i = 0; i < this.dietBreakfastFoodData.length; i++) {
          if (this.dietBreakfastFoodData[i].description == this.bfSubFoodType) {
            flag = false;
            this.toastr.error(Message_data.alrdyExist);
            break;
          }
        }
      }
      if(this.bfMeasure !="item(s)"){
        if(this.breakfastCond !=undefined && this.breakfastCond =="or" ){
          if(this.dietBreakfast != undefined){
            this.dietBreakfast+=this.bfQuantity+" "+this.bfMeasure+" "+this.bfSubFoodType+"<br/>"+this.breakfastCond +" "
          }else{
            this.dietBreakfast=this.bfQuantity+" "+this.bfMeasure+" "+this.bfSubFoodType+"<br/>"+this.breakfastCond +" "
          }
        } else if(this.breakfastCond !="or" && this.breakfastCond !="Select" && this.breakfastCond !=undefined) {
          if(this.dietBreakfast != undefined){
            this.dietBreakfast+=this.bfQuantity+" "+this.bfMeasure+" "+this.bfSubFoodType+" "+this.breakfastCond+" "
          }else{
            this.dietBreakfast=this.bfQuantity+" "+this.bfMeasure+" "+this.bfSubFoodType+" "+this.breakfastCond+" "
          }
        } else{
          if(this.dietBreakfast != undefined){
            this.dietBreakfast+=this.bfQuantity+" "+this.bfMeasure+" "+this.bfSubFoodType
          }else{
            this.dietBreakfast=this.bfQuantity+" "+this.bfMeasure+" "+this.bfSubFoodType
          }
        }
      } else{
        if(this.breakfastCond !=undefined && this.breakfastCond =="or" ){
          if(this.dietBreakfast != undefined){
            this.dietBreakfast+=this.bfQuantity+" "+this.bfSubFoodType+"<br/>"+this.breakfastCond +" "
          }else{
            this.dietBreakfast=this.bfQuantity+" "+this.bfSubFoodType+"<br/>"+this.breakfastCond +" "
          }
        } else if(this.breakfastCond !="or" && this.breakfastCond !="Select" && this.breakfastCond !=undefined) {
          if(this.dietBreakfast != undefined){
            this.dietBreakfast+=this.bfQuantity+" "+this.bfSubFoodType+" "+this.breakfastCond+" "
          }else{
            this.dietBreakfast=this.bfQuantity+" "+this.bfSubFoodType+" "+this.breakfastCond+" "
          }
        } else{
          if(this.dietBreakfast != undefined){
            this.dietBreakfast+=this.bfQuantity+" "+this.bfSubFoodType
          }else{
            this.dietBreakfast=this.bfQuantity+" "+this.bfSubFoodType
          }
        }
      }
      if (flag == true) {
        for (var i = 0; i < this.bfSubFoodTypeList.length; i++) {
          if (this.bfSubFoodType == this.bfSubFoodTypeList[i].description) {
            var food_item_id = this.bfSubFoodTypeList[i].food_item_id;
          }
        }
        this.dietBreakfastFoodData.push({
          category: this.bfFoodCategory,
          food_sub_type: this.bfFoodType,
          description: this.bfSubFoodType,
          quantity: this.bfQuantity,
          measure: this.bfMeasure,
          weight: this.bfWeight,
          energy: this.bfEnergy,
          food_item_id: food_item_id,
          cond:this.breakfastCond
        });
        if (this.dietBreakfastFoodData.length == 0) {
          if (this.calories == "0") {
            this.calories = parseInt(this.bfEnergy);
            this.setCalories();
          } else {
            var tot = parseFloat(this.calories) + parseFloat(this.bfEnergy);
            this.calories = tot.toString();
            this.setCalories();
          }
        } else {
          if (this.calories == "0") {
            this.calories = parseInt(this.bfEnergy);
            this.setCalories();
          } else {
            var tot = parseFloat(this.calories) + parseFloat(this.bfEnergy);
            this.calories = tot.toString();
            this.setCalories();
          }
        }
        this.bfFoodCategory = undefined;
        this.bfFoodType = undefined;
        this.bfSubFoodType = undefined;
        this.bfQuantity = undefined;
        this.bfMeasure = '';
        this.bfWeight = '';
        this.bfEnergy = '';
      }
    }
  }

  addDietMiddayFood() {
    if (this.middayFoodCategory == undefined || this.middayFoodType == undefined || this.middaySubFoodType == undefined || this.middayQuantity == undefined
      || (this.middayMeasure == undefined || this.middayMeasure == "") || (this.middayWeight == undefined || this.middayWeight == "")
      || (this.middayEnergy == undefined || this.middayEnergy == "")) {
        this.toastr.error(Message_data.mandatory);
      } else {
      this.middayTableFlag = false;
      var flag = true;
      if (this.dietMiddayFoodData.length != 0) {
        for (var i = 0; i < this.dietMiddayFoodData.length; i++) {
          if (this.dietMiddayFoodData[i].description == this.middaySubFoodType) {
            flag = false;
            this.toastr.error(Message_data.alrdyExist);
            break;
          }
        }
      }
      if(this.middayMeasure !="item(s)"){
        if(this.middayCond !=undefined && this.middayCond =="or" ){
          if(this.dietMidday != undefined){
            this.dietMidday+=this.middayQuantity+" "+this.middayMeasure+" "+this.middaySubFoodType+"<br/>"+this.middayCond +" "
          }else{
            this.dietMidday=this.middayQuantity+" "+this.middayMeasure+" "+this.middaySubFoodType+"<br/>"+this.middayCond +" "
          }
        } else if(this.middayCond !="or" && this.middayCond !="Select" && this.middayCond !=undefined) {
          if(this.dietMidday != undefined){
            this.dietMidday+=this.middayQuantity+" "+this.middayMeasure+" "+this.middaySubFoodType+" "+this.middayCond+" "
          }else{
            this.dietMidday=this.middayQuantity+" "+this.middayMeasure+" "+this.middaySubFoodType+" "+this.middayCond+" "
          }
        } else{
          if(this.dietMidday != undefined){
            this.dietMidday+=this.middayQuantity+" "+this.middayMeasure+" "+this.middaySubFoodType
          }else{
            this.dietMidday=this.middayQuantity+" "+this.middayMeasure+" "+this.middaySubFoodType
          }
        }
      } else{
        if(this.middayCond !=undefined && this.middayCond =="or" ){
          if(this.dietMidday != undefined){
            this.dietMidday+=this.middayQuantity+" "+this.middaySubFoodType+"<br/>"+this.middayCond +" "
          }else{
            this.dietMidday=this.middayQuantity+" "+this.middaySubFoodType+"<br/>"+this.middayCond +" "
          }
        } else if(this.middayCond !="or" && this.middayCond !="Select" && this.middayCond !=undefined) {
          if(this.dietMidday != undefined){
            this.dietMidday+=this.middayQuantity+" "+this.middaySubFoodType+" "+this.middayCond+" "
          }else{
            this.dietMidday=this.middayQuantity+" "+this.middaySubFoodType+" "+this.middayCond+" "
          }
        } else{
          if(this.dietMidday != undefined){
            this.dietMidday+=this.middayQuantity+" "+this.middaySubFoodType
          }else{
            this.dietMidday=this.middayQuantity+" "+this.middaySubFoodType
          }
        }
      }
      if (flag == true) {
        for (var i = 0; i < this.middaySubfoodTypeList.length; i++) {
          if (this.middaySubFoodType == this.middaySubfoodTypeList[i].description) {
            var food_item_id = this.middaySubfoodTypeList[i].food_item_id;
          }
        }
        this.dietMiddayFoodData.push({
          category: this.middayFoodCategory,
          food_sub_type: this.middayFoodType,
          description: this.middaySubFoodType,
          quantity: this.middayQuantity,
          measure: this.middayMeasure,
          weight: this.middayWeight,
          energy: this.middayEnergy,
          food_item_id: food_item_id,
          cond:this.middayCond
        });
        if (this.dietMiddayFoodData.length == 0) {
          if (this.calories == "0") {
            this.calories = parseInt(this.middayEnergy);
            this.setCalories();
          } else {
            var tot = parseFloat(this.calories) + parseFloat(this.middayEnergy);
            this.calories = tot.toString();
            this.setCalories();
          }
        } else {
          if (this.calories == "0") {
            this.calories = parseInt(this.middayEnergy);
            this.setCalories();
          } else {
            var tot = parseFloat(this.calories) + parseFloat(this.middayEnergy);
            this.calories = tot.toString();
            this.setCalories();
          }
        }
        this.middayFoodCategory = undefined;
        this.middayFoodType = undefined;
        this.middaySubFoodType = undefined;
        this.middayQuantity = undefined;
        this.middayMeasure = '';
        this.middayWeight = '';
        this.middayEnergy = '';
      }
    }
  }

  addDietLunchFood() {
    if (this.lunchFoodCategory == undefined || this.lunchFoodType == undefined || this.lunchSubFoodType == undefined || this.lunchQuantity == undefined
      || (this.lunchMeasure == undefined || this.lunchMeasure == "") || (this.lunchWeight == undefined || this.lunchWeight == "")
      || (this.lunchEnergy == undefined || this.lunchEnergy == "")) {
        this.toastr.error(Message_data.mandatory);
      } else {
      this.lunchTableFlag = false;
      var flag = true;
      if (this.dietLunchFoodData.length != 0) {
        for (var i = 0; i < this.dietLunchFoodData.length; i++) {
          if (this.dietLunchFoodData[i].description == this.lunchSubFoodType) {
            flag = false;
            this.toastr.error(Message_data.alrdyExist);
            break;
          }
        }
      }
      if(this.lunchMeasure !="item(s)"){
        if(this.lunchCond !=undefined && this.lunchCond =="or" ){
          if(this.dietLunch != undefined){
            this.dietLunch+=this.lunchQuantity+" "+this.lunchMeasure+" "+this.lunchSubFoodType+"<br/>"+this.lunchCond +" "
          }else{
            this.dietLunch=this.lunchQuantity+" "+this.lunchMeasure+" "+this.lunchSubFoodType+"<br/>"+this.lunchCond +" "
          }
        } else if(this.lunchCond !="or" && this.lunchCond !="Select" && this.lunchCond !=undefined) {
          if(this.dietLunch != undefined){
            this.dietLunch+=this.lunchQuantity+" "+this.lunchMeasure+" "+this.lunchSubFoodType+" "+this.lunchCond+" "
          }else{
            this.dietLunch=this.lunchQuantity+" "+this.lunchMeasure+" "+this.lunchSubFoodType+" "+this.lunchCond+" "
          }
        } else{
          if(this.dietLunch != undefined){
            this.dietLunch+=this.lunchQuantity+" "+this.lunchMeasure+" "+this.lunchSubFoodType
          }else{
            this.dietLunch=this.lunchQuantity+" "+this.lunchMeasure+" "+this.lunchSubFoodType
          }
        }
      } else{
        if(this.lunchCond !=undefined && this.lunchCond =="or" ){
          if(this.dietLunch != undefined){
            this.dietLunch+=this.lunchQuantity+" "+this.lunchSubFoodType+"<br/>"+this.lunchCond +" "
          }else{
            this.dietLunch=this.lunchQuantity+" "+this.lunchSubFoodType+"<br/>"+this.lunchCond +" "
          }
        } else if(this.lunchCond !="or" && this.lunchCond !="Select" && this.lunchCond !=undefined) {
          if(this.dietLunch != undefined){
            this.dietLunch+=this.lunchQuantity+" "+this.lunchSubFoodType+" "+this.lunchCond+" "
          }else{
            this.dietLunch=this.lunchQuantity+" "+this.lunchSubFoodType+" "+this.lunchCond+" "
          }
        } else{
          if(this.dietLunch != undefined){
            this.dietLunch+=this.lunchQuantity+" "+this.lunchSubFoodType
          }else{
            this.dietLunch=this.lunchQuantity+" "+this.lunchSubFoodType
          }
        }
      }
      if (flag == true) {
        for (var i = 0; i < this.lunchSubFoodTypeList.length; i++) {
          if (this.lunchSubFoodType == this.lunchSubFoodTypeList[i].description) {
            var food_item_id = this.lunchSubFoodTypeList[i].food_item_id;
          }
        }
        this.dietLunchFoodData.push({
          category: this.lunchFoodCategory,
          food_sub_type: this.lunchFoodType,
          description: this.lunchSubFoodType,
          quantity: this.lunchQuantity,
          measure: this.lunchMeasure,
          weight: this.lunchWeight,
          energy: this.lunchEnergy,
          food_item_id: food_item_id,
          cond:this.lunchCond
        });
        if (this.dietLunchFoodData.length == 0) {
          if (this.calories == "0") {
            this.calories = parseInt(this.lunchEnergy);
            this.setCalories();
          } else {
            var tot = parseFloat(this.calories) + parseFloat(this.lunchEnergy);
            this.calories = tot.toString();
            this.setCalories();
          }
        } else {
          if (this.calories == "0") {
            this.calories = parseInt(this.lunchEnergy);
            this.setCalories();
          } else {
            var tot = parseFloat(this.calories) + parseFloat(this.lunchEnergy);
            this.calories = tot.toString();
            this.setCalories();
          }
        }
        this.lunchFoodCategory = undefined;
        this.lunchFoodType = undefined;
        this.lunchSubFoodType = undefined;
        this.lunchQuantity = undefined;
        this.lunchMeasure = '';
        this.lunchWeight = '';
        this.lunchEnergy = '';
      }
    }
  }

  addDietEveFood() {
    if (this.eveFoodCategory == undefined || this.eveFoodType == undefined || this.eveSubFoodType == undefined || this.eveQuantity == undefined
      || (this.eveMeasure == undefined || this.eveMeasure == "") || (this.eveWeight == undefined || this.eveWeight == "")
      || (this.eveEnergy == undefined || this.eveEnergy == "")) {
        this.toastr.error(Message_data.mandatory);
      } else {
      this.eveTableFlag = false;
      var flag = true;
      if (this.dietEveFoodData.length != 0) {
        for (var i = 0; i < this.dietEveFoodData.length; i++) {
          if (this.dietEveFoodData[i].description == this.eveSubFoodType) {
            flag = false;
            this.toastr.error(Message_data.alrdyExist);
            break;
          }
        }
      }
      if(this.eveMeasure !="item(s)"){
        if(this.eveCond !=undefined && this.eveCond =="or" ){
          if(this.dietEvening != undefined){
            this.dietEvening+=this.eveQuantity+" "+this.eveMeasure+" "+this.eveSubFoodType+"<br/>"+this.eveCond +" "
          }else{
            this.dietEvening=this.eveQuantity+" "+this.eveMeasure+" "+this.eveSubFoodType+"<br/>"+this.eveCond +" "
          }
        } else if(this.eveCond !="or" && this.eveCond !="Select" && this.eveCond !=undefined) {
          if(this.dietEvening != undefined){
            this.dietEvening+=this.eveQuantity+" "+this.eveMeasure+" "+this.eveSubFoodType+" "+this.eveCond+" "
          }else{
            this.dietEvening=this.eveQuantity+" "+this.eveMeasure+" "+this.eveSubFoodType+" "+this.eveCond+" "
          }
        } else{
          if(this.dietEvening != undefined){
            this.dietEvening+=this.eveQuantity+" "+this.eveMeasure+" "+this.eveSubFoodType
          }else{
            this.dietEvening=this.eveQuantity+" "+this.eveMeasure+" "+this.eveSubFoodType
          }
        }
      } else{
        if(this.eveCond !=undefined && this.eveCond =="or" ){
          if(this.dietEvening != undefined){
            this.dietEvening+=this.eveQuantity+" "+this.eveSubFoodType+"<br/>"+this.eveCond +" "
          }else{
            this.dietEvening=this.eveQuantity+" "+this.eveSubFoodType+"<br/>"+this.eveCond +" "
          }
        } else if(this.eveCond !="or" && this.eveCond !="Select" && this.eveCond !=undefined) {
          if(this.dietEvening != undefined){
            this.dietEvening+=this.eveQuantity+" "+this.eveSubFoodType+" "+this.eveCond+" "
          }else{
            this.dietEvening=this.eveQuantity+" "+this.eveSubFoodType+" "+this.eveCond+" "
          }
        } else{
          if(this.dietEvening != undefined){
            this.dietEvening+=this.eveQuantity+" "+this.eveSubFoodType
          }else{
            this.dietEvening=this.eveQuantity+" "+this.eveSubFoodType
          }
        }
      }
      if (flag == true) {
        for (var i = 0; i < this.eveSubFoodTypeList.length; i++) {
          if (this.eveSubFoodType == this.eveSubFoodTypeList[i].description) {
            var food_item_id = this.eveSubFoodTypeList[i].food_item_id;
          }
        }
        this.dietEveFoodData.push({
          category: this.eveFoodCategory,
          food_sub_type: this.eveFoodType,
          description: this.eveSubFoodType,
          quantity: this.eveQuantity,
          measure: this.eveMeasure,
          weight: this.eveWeight,
          energy: this.eveEnergy,
          food_item_id: food_item_id,
          cond:this.eveCond
        });
        if (this.dietEveFoodData.length == 0) {
          if (this.calories == "0") {
            this.calories = parseInt(this.eveEnergy);
            this.setCalories();
          } else {
            var tot = parseFloat(this.calories) + parseFloat(this.eveEnergy);
            this.calories = tot.toString();
            this.setCalories();
          }
        } else {
          if (this.calories == "0") {
            this.calories = parseInt(this.eveEnergy);
            this.setCalories();
          } else {
            var tot = parseFloat(this.calories) + parseFloat(this.eveEnergy);
            this.calories = tot.toString();
            this.setCalories();
          }
        }
        this.eveFoodCategory = undefined;
        this.eveFoodType = undefined;
        this.eveSubFoodType = undefined;
        this.eveQuantity = undefined;
        this.eveMeasure = '';
        this.eveWeight = '';
        this.eveEnergy = '';
      }
    }
  }

  addDietDinnerFood() {
    if (this.dinnerFoodCategory == undefined || this.dinnerFoodType == undefined || this.dinnerSubFoodType == undefined || this.dinnerQuantity == undefined
      || (this.dinnerMeasure == undefined || this.dinnerMeasure == "") || (this.dinnerWeight == undefined || this.dinnerWeight == "")
      || (this.dinnerEnergy == undefined || this.dinnerEnergy == "")) {
        this.toastr.error(Message_data.mandatory);
      } else {
      this.dinnerTableFlag = false;
      var flag = true;
      if (this.dietDinnerFoodData.length != 0) {
        for (var i = 0; i < this.dietDinnerFoodData.length; i++) {
          if (this.dietDinnerFoodData[i].description == this.dinnerSubFoodType) {
            flag = false;
            this.toastr.error(Message_data.alrdyExist);
            break;
          }
        }
      }
      if(this.dinnerMeasure !="item(s)"){
        if(this.dinnerCond !=undefined && this.dinnerCond =="or" ){
          if(this.dietDinner != undefined){
            this.dietDinner+=this.dinnerQuantity+" "+this.dinnerMeasure+" "+this.dinnerSubFoodType+"<br/>"+this.dinnerCond +" "
          }else{
            this.dietDinner=this.dinnerQuantity+" "+this.dinnerMeasure+" "+this.dinnerSubFoodType+"<br/>"+this.dinnerCond +" "
          }
        } else if(this.dinnerCond !="or" && this.dinnerCond !="Select" && this.dinnerCond !=undefined) {
          if(this.dietDinner != undefined){
            this.dietDinner+=this.dinnerQuantity+" "+this.dinnerMeasure+" "+this.dinnerSubFoodType+" "+this.dinnerCond+" "
          }else{
            this.dietDinner=this.dinnerQuantity+" "+this.dinnerMeasure+" "+this.dinnerSubFoodType+" "+this.dinnerCond+" "
          }
        } else{
          if(this.dietDinner != undefined){
            this.dietDinner+=this.dinnerQuantity+" "+this.dinnerMeasure+" "+this.dinnerSubFoodType
          }else{
            this.dietDinner=this.dinnerQuantity+" "+this.dinnerMeasure+" "+this.dinnerSubFoodType
          }
        }
      } else{
        if(this.dinnerCond !=undefined && this.dinnerCond =="or" ){
          if(this.dietDinner != undefined){
            this.dietDinner+=this.dinnerQuantity+" "+this.dinnerSubFoodType+"<br/>"+this.dinnerCond +" "
          }else{
            this.dietDinner=this.dinnerQuantity+" "+this.dinnerSubFoodType+"<br/>"+this.dinnerCond +" "
          }
        } else if(this.dinnerCond !="or" && this.dinnerCond !="Select" && this.dinnerCond !=undefined) {
          if(this.dietDinner != undefined){
            this.dietDinner+=this.dinnerQuantity+" "+this.dinnerSubFoodType+" "+this.dinnerCond+" "
          }else{
            this.dietDinner=this.dinnerQuantity+" "+this.dinnerSubFoodType+" "+this.dinnerCond+" "
          }
        } else{
          if(this.dietDinner != undefined){
            this.dietDinner+=this.dinnerQuantity+" "+this.dinnerSubFoodType
          }else{
            this.dietDinner=this.dinnerQuantity+" "+this.dinnerSubFoodType
          }
        }
      }
      if (flag == true) {
        for (var i = 0; i < this.dinnerSubfoodTypeList.length; i++) {
          if (this.dinnerSubFoodType == this.dinnerSubfoodTypeList[i].description) {
            var food_item_id = this.dinnerSubfoodTypeList[i].food_item_id;
          }
        }
        this.dietDinnerFoodData.push({
          category: this.dinnerFoodCategory,
          food_sub_type: this.dinnerFoodType,
          description: this.dinnerSubFoodType,
          quantity: this.dinnerQuantity,
          measure: this.dinnerMeasure,
          weight: this.dinnerWeight,
          energy: this.dinnerEnergy,
          food_item_id: food_item_id,
          cond:this.dinnerCond
        });
        if (this.dietDinnerFoodData.length == 0) {
          if (this.calories == "0") {
            this.calories = parseInt(this.dinnerEnergy);
            this.setCalories();
          } else {
            var tot = parseFloat(this.calories) + parseFloat(this.dinnerEnergy);
            this.calories = tot.toString();
            this.setCalories();
          }
        } else {
          if (this.calories == "0") {
            this.calories = parseInt(this.dinnerEnergy);
            this.setCalories();
          } else {
            var tot = parseFloat(this.calories) + parseFloat(this.dinnerEnergy);
            this.calories = tot.toString();
            this.setCalories();
          }
        }
        this.dinnerFoodCategory = undefined;
        this.dinnerFoodType = undefined;
        this.dinnerSubFoodType = undefined;
        this.dinnerQuantity = undefined;
        this.dinnerMeasure = '';
        this.dinnerWeight = '';
        this.dinnerEnergy = '';
      }
    }
  }

  addDietNightFood() {
    if (this.nightFoodCategory == undefined || this.nightFoodType == undefined || this.nightSubFoodType == undefined || this.nightQuantity == undefined
      || (this.nightMeasure == undefined || this.nightMeasure == "") || (this.nightWeight == undefined || this.nightWeight == "")
      || (this.nightEnergy == undefined || this.nightEnergy == "")) {
        this.toastr.error(Message_data.mandatory);
      } else {
      this.nightTableFlag = false;
      var flag = true;
      if (this.dietNightFoodData.length != 0) {
        for (var i = 0; i < this.dietNightFoodData.length; i++) {
          if (this.dietNightFoodData[i].description == this.nightSubFoodType) {
            flag = false;
            break;
          }
        }
      }
      if(this.nightMeasure !="item(s)"){
        if(this.nightCond !=undefined && this.nightCond =="or" ){
          if(this.dietNight != undefined){
            this.dietNight+=this.nightQuantity+" "+this.nightMeasure+" "+this.nightSubFoodType+"<br/>"+this.nightCond +" "
          }else{
            this.dietNight=this.nightQuantity+" "+this.nightMeasure+" "+this.nightSubFoodType+"<br/>"+this.nightCond +" "
          }
        } else if(this.nightCond !="or" && this.nightCond !="Select" && this.nightCond !=undefined) {
          if(this.dietNight != undefined){
            this.dietNight+=this.nightQuantity+" "+this.nightMeasure+" "+this.nightSubFoodType+" "+this.nightCond+" "
          }else{
            this.dietNight=this.nightQuantity+" "+this.nightMeasure+" "+this.nightSubFoodType+" "+this.nightCond+" "
          }
        } else{
          if(this.dietNight != undefined){
            this.dietNight+=this.nightQuantity+" "+this.nightMeasure+" "+this.nightSubFoodType
          }else{
            this.dietNight=this.nightQuantity+" "+this.nightMeasure+" "+this.nightSubFoodType
          }
        }
      } else{
        if(this.nightCond !=undefined && this.nightCond =="or" ){
          if(this.dietNight != undefined){
            this.dietNight+=this.nightQuantity+" "+this.nightSubFoodType+"<br/>"+this.nightCond +" "
          }else{
            this.dietNight=this.nightQuantity+" "+this.nightSubFoodType+"<br/>"+this.nightCond +" "
          }
        } else if(this.nightCond !="or" && this.nightCond !="Select" && this.nightCond !=undefined) {
          if(this.dietNight != undefined){
            this.dietNight+=this.nightQuantity+" "+this.nightSubFoodType+" "+this.nightCond+" "
          }else{
            this.dietNight=this.nightQuantity+" "+this.nightSubFoodType+" "+this.nightCond+" "
          }
        } else{
          if(this.dietNight != undefined){
            this.dietNight+=this.nightQuantity+" "+this.nightSubFoodType
          }else{
            this.dietNight=this.nightQuantity+" "+this.nightSubFoodType
          }
        }
      }
      if (flag == true) {
        for (var i = 0; i < this.nightSubfoodTypeList.length; i++) {
          if (this.nightSubFoodType == this.nightSubfoodTypeList[i].description) {
            var food_item_id = this.nightSubfoodTypeList[i].food_item_id;
          }
        }
        this.dietNightFoodData.push({
          category: this.nightFoodCategory,
          food_sub_type: this.nightFoodType,
          description: this.nightSubFoodType,
          quantity: this.nightQuantity,
          measure: this.nightMeasure,
          weight: this.nightWeight,
          energy: this.nightEnergy,
          food_item_id: food_item_id,
          cond:this.nightCond
        });
        if (this.dietNightFoodData.length == 0) {
          if (this.calories == "0") {
            this.calories = parseInt(this.nightEnergy);
            this.setCalories();
          } else {
            var tot = parseFloat(this.calories) + parseFloat(this.nightEnergy);
            this.calories = tot.toString();
            this.setCalories();
          }
        } else {
          if (this.calories == "0") {
            this.calories = parseInt(this.nightEnergy);
            this.setCalories();
          } else {
            var tot = parseFloat(this.calories) + parseFloat(this.nightEnergy);
            this.calories = tot.toString();
            this.setCalories();
          }
        }
        this.nightFoodCategory = undefined;
        this.nightFoodType = undefined;
        this.nightSubFoodType = undefined;
        this.nightQuantity = undefined;
        this.nightMeasure = '';
        this.nightWeight = '';
        this.nightEnergy = '';
      }
    }
  }

  refreshFoodContent(data){
    if(data =="mor"){
      this.dietMorning="";
      var morncal =0;
      for(var i=0;i<this.dietMorFoodData.length;i++){
        morncal= morncal+parseInt(this.dietMorFoodData[i].energy);
      }
      var totcalval =0;
      totcalval = parseInt(this.totalCalories)-morncal;
      this.totalCalories = totcalval.toString();
      this.dietMorFoodData=[];
    } else if(data =="break"){
      this.dietBreakfast="";
      var cal =0;
      for(var i=0;i<this.dietBreakfastFoodData.length;i++){
        cal= cal+parseInt(this.dietBreakfastFoodData[i].energy);
      }
      var totcalval =0;
      totcalval = parseInt(this.totalCalories)-cal;
      this.totalCalories = totcalval.toString();
      this.dietBreakfastFoodData=[];
    } else if(data =="midday"){
      this.dietMidday="";
      var cal =0;
      for(var i=0;i<this.dietMiddayFoodData.length;i++){
        cal= cal+parseInt(this.dietMiddayFoodData[i].energy);
      }
      var totcalval =0;
      totcalval = parseInt(this.totalCalories)-cal;
      this.totalCalories = totcalval.toString();
      this.dietMiddayFoodData=[];
    } else if(data =="lunch"){
      this.dietLunch="";
      var cal =0;
      for(var i=0;i<this.dietLunchFoodData.length;i++){
        cal= cal+parseInt(this.dietLunchFoodData[i].energy);
      }
      var totcalval =0;
      totcalval = parseInt(this.totalCalories)-cal;
      this.totalCalories = totcalval.toString();
      this.dietLunchFoodData=[];
    } else if(data =="eve"){
      this.dietEvening="";
      var cal =0;
      for(var i=0;i<this.dietEveFoodData.length;i++){
        cal= cal+parseInt(this.dietEveFoodData[i].energy);
      }
      var totcalval =0;
      totcalval = parseInt(this.totalCalories)-cal;
      this.totalCalories = totcalval.toString();
      this.dietEveFoodData=[];
    } else if(data =="dinner"){
      this.dietDinner="";
      var cal =0;
      for(var i=0;i<this.dietDinnerFoodData.length;i++){
        cal= cal+parseInt(this.dietDinnerFoodData[i].energy);
      }
      var totcalval =0;
      totcalval = parseInt(this.totalCalories)-cal;
      this.totalCalories = totcalval.toString();
      this.dietDinnerFoodData=[];
    } else if(data =="night"){
      this.dietNight="";
      var cal =0;
      for(var i=0;i<this.dietNightFoodData.length;i++){
        cal= cal+parseInt(this.dietNightFoodData[i].energy);
      }
      var totcalval =0;
      totcalval = parseInt(this.totalCalories)-cal;
      this.totalCalories = totcalval.toString();
      this.dietNightFoodData=[];
    }   
  }

  editFoodContent(value){
    var dietarray=[];
    if(value =="mor"){
      dietarray = this.dietMorFoodData;
    } else if(value =="midday"){
      dietarray = this.dietMiddayFoodData;
    } else if(value =="lunch"){
      dietarray = this.dietLunchFoodData;
    } else if(value =="break"){
      dietarray = this.dietBreakfastFoodData;
    } else if(value =="eve"){
      dietarray = this.dietEveFoodData;
    } else if(value =="dinner"){
      dietarray = this.dietDinnerFoodData;
    } else if(value =="night"){
      dietarray = this.dietNightFoodData;
    }
    const dialogRef = this.dialog.open(DietplanEditComponent, {
      width: '70%',
      height: '500px',
      data: { dietlist: dietarray, cal_data: this.calories, }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        if (value == "mor") {
          this.dietMorFoodData = [];
          this.dietMorFoodData = result.dietlist;
          this.addDietData('mor', result.dietlist);
        } else if (value == "midday") {
          this.dietMiddayFoodData = [];
          this.dietMiddayFoodData = result.dietlist;
          this.addDietData('midday', result.dietlist);
        } else if (value == "lunch") {
          this.dietLunchFoodData = [];
          this.dietLunchFoodData = result.dietlist;
          this.addDietData('lunch', result.dietlist);
        } else if (value == "break") {
          this.dietBreakfastFoodData = [];
          this.dietBreakfastFoodData = result.dietlist;
          this.addDietData('break', result.dietlist);
        } else if (value == "eve") {
          this.dietEveFoodData = [];
          this.dietEveFoodData = result.dietlist;
          this.addDietData('eve', result.dietlist);
        } else if (value == "dinner") {
          this.dietDinnerFoodData = [];
          this.dietDinnerFoodData = result.dietlist;
          this.addDietData('dinner', result.dietlist);
        } else if (value == "night") {
          this.dietNightFoodData = [];
          this.dietNightFoodData = result.dietlist;
          this.addDietData('night', result.dietlist);
        }
        this.calories = result.cal_data;
        this.setCalories();
      }
    });
  }

  calculationQuantity(data, session) {
    var quantity = null, measure = null, weight = null, energy = null;
    if (session == "Morning") {
      for (var i = 0; i < this.morSubfoodTypeList.length; i++) {
        if (data == this.morSubfoodTypeList[i].description) {
          quantity = this.morSubfoodTypeList[i].quantity;
          measure = this.morSubfoodTypeList[i].measure;
          weight = this.morSubfoodTypeList[i].weight;
          energy = this.morSubfoodTypeList[i].energy;
        }
      }
    } else if (session == "breakfast") {
      for (var i = 0; i < this.bfSubFoodTypeList.length; i++) {
        if (data == this.bfSubFoodTypeList[i].description) {
          quantity = this.bfSubFoodTypeList[i].quantity;
          measure = this.bfSubFoodTypeList[i].measure;
          weight = this.bfSubFoodTypeList[i].weight;
          energy = this.bfSubFoodTypeList[i].energy;
        }
      }
    } else if (session == "midday") {
      for (var i = 0; i < this.middaySubfoodTypeList.length; i++) {
        if (data == this.middaySubfoodTypeList[i].description) {
          quantity = this.middaySubfoodTypeList[i].quantity;
          measure = this.middaySubfoodTypeList[i].measure;
          weight = this.middaySubfoodTypeList[i].weight;
          energy = this.middaySubfoodTypeList[i].energy;
        }
      }
    } else if (session == "lunch") {
      for (var i = 0; i < this.lunchSubFoodTypeList.length; i++) {
        if (data == this.lunchSubFoodTypeList[i].description) {
          quantity = this.lunchSubFoodTypeList[i].quantity;
          measure = this.lunchSubFoodTypeList[i].measure;
          weight = this.lunchSubFoodTypeList[i].weight;
          energy = this.lunchSubFoodTypeList[i].energy;
        }
      }
    } else if (session == "eve") {
      for (var i = 0; i < this.eveSubFoodTypeList.length; i++) {
        if (data == this.eveSubFoodTypeList[i].description) {
          quantity = this.eveSubFoodTypeList[i].quantity;
          measure = this.eveSubFoodTypeList[i].measure;
          weight = this.eveSubFoodTypeList[i].weight;
          energy = this.eveSubFoodTypeList[i].energy;
        }
      }
    } else if (session == "dinner") {
      for (var i = 0; i < this.dinnerSubfoodTypeList.length; i++) {
        if (data == this.dinnerSubfoodTypeList[i].description) {
          quantity = this.dinnerSubfoodTypeList[i].quantity;
          measure = this.dinnerSubfoodTypeList[i].measure;
          weight = this.dinnerSubfoodTypeList[i].weight;
          energy = this.dinnerSubfoodTypeList[i].energy;
        }
      }
    } else if (session == "night") {
      for (var i = 0; i < this.nightSubfoodTypeList.length; i++) {
        if (data == this.nightSubfoodTypeList[i].description) {
          quantity = this.nightSubfoodTypeList[i].quantity;
          measure = this.nightSubfoodTypeList[i].measure;
          weight = this.nightSubfoodTypeList[i].weight;
          energy = this.nightSubfoodTypeList[i].energy;
        }
      }
    }
    if (quantity == "1" || quantity == "2" || quantity == "3" || quantity == "4" || quantity == "5" ||
      quantity == "6" || quantity == "7" || quantity == "8" || quantity == "9" || quantity == "10" ||
      quantity == "11" || quantity == "12" || quantity == "13" || quantity == "14" || quantity == "15" ||
      quantity == "16" || quantity == "17" || quantity == "20" || quantity == "21" || quantity == "22" ||
      quantity == "24" || quantity == "26" || quantity == "28" || quantity == "30" || quantity == "32" ||
      quantity == "40" || quantity == "42" || quantity == "48" || quantity == "60") {
      this.calcNumber = 1 / parseFloat(quantity);
    } else if (quantity == "1/8") {
      this.calcNumber = 8;
    } else if (quantity == "1/4") {
      this.calcNumber = 4;
    } else if (quantity == "1/3") {
      this.calcNumber = 3;
    } else if (quantity == "1/2") {
      this.calcNumber = 2;
    } else if (quantity == "3/4") {
      this.calcNumber = 1.34;
    } else if (quantity == "11/2") {
      this.calcNumber = 0.67;
    } else if (quantity == "21/2") {
      this.calcNumber = 0.4;
    } else if (quantity == "21/8") {
      this.calcNumber = 0.47;
    } else if (quantity == "91/2") {
      this.calcNumber = 0.105;
    } else if (quantity == "2/3") {
      this.calcNumber = 1.5;
    } else if (quantity == "41/4") {
      this.calcNumber = 0.235;
    }
    this.calcQuantity = 1;
    this.calcMeasure = measure;
    this.calcWeight = parseInt((weight * this.calcNumber).toFixed(2).toString());
    this.calcEnergy = parseInt((energy * this.calcNumber).toFixed(2).toString());
  }

  changeQuantity(quantity, session) {
    var Actual_Quantity = quantity;
    if (quantity == "1/8") {
      Actual_Quantity = 0.125;
    } else if (quantity == "1/4") {
      Actual_Quantity = 0.25;
    } else if (quantity == "1/3") {
      Actual_Quantity = 0.33;
    } else if (quantity == "1/2") {
      Actual_Quantity = 0.5;
    } else if (quantity == "3/4") {
      Actual_Quantity = 0.75;
    } else if (quantity == "11/2") {
      Actual_Quantity = 1.5;
    } else if (quantity == "21/2") {
      Actual_Quantity = 2.5;
    } else if (quantity == "21/8") {
      Actual_Quantity = 2.125;
    } else if (quantity == "91/2") {
      Actual_Quantity = 9.5;
    } else if (quantity == "2/3") {
      Actual_Quantity = 0.67;
    } else if (quantity == "41/4") {
      Actual_Quantity = 4.25;
    }
    if (session == "Morning") {
      this.morMeasure = this.calcMeasure;
      this.morWeight = Math.round(this.calcWeight * parseFloat(Actual_Quantity));
      this.morEnegry = Math.round(this.calcEnergy * parseFloat(Actual_Quantity));
    } else if (session == "breakfast") {
      this.bfMeasure = this.calcMeasure;
      this.bfWeight = Math.round(this.calcWeight * parseFloat(Actual_Quantity));
      this.bfEnergy = Math.round(this.calcEnergy * parseFloat(Actual_Quantity));
    } else if (session == "midday") {
      this.middayMeasure = this.calcMeasure;
      this.middayWeight = Math.round(this.calcWeight * parseFloat(Actual_Quantity));
      this.middayEnergy = Math.round(this.calcEnergy * parseFloat(Actual_Quantity));
    } else if (session == "lunch") {
      this.lunchMeasure = this.calcMeasure;
      this.lunchWeight = Math.round(this.calcWeight * parseFloat(Actual_Quantity));
      this.lunchEnergy = Math.round(this.calcEnergy * parseFloat(Actual_Quantity));
    } else if (session == "eve") {
      this.eveMeasure = this.calcMeasure;
      this.eveWeight = Math.round(this.calcWeight * parseFloat(Actual_Quantity));
      this.eveEnergy = Math.round(this.calcEnergy * parseFloat(Actual_Quantity));
    } else if (session == "dinner") {
      this.dinnerMeasure = this.calcMeasure;
      this.dinnerWeight = Math.round(this.calcWeight * parseFloat(Actual_Quantity));
      this.dinnerEnergy = Math.round(this.calcEnergy * parseFloat(Actual_Quantity));
    } else if (session == "night") {
      this.nightMeasure = this.calcMeasure;
      this.nightWeight = Math.round(this.calcWeight * parseFloat(Actual_Quantity));
      this.nightEnergy = Math.round(this.calcEnergy * parseFloat(Actual_Quantity));
    }
  }

  setCalories() {
    var col_dtaa = this.calories;
    this.totalCalories = Math.round(col_dtaa);
    this.KcalCalories();
  }

  KcalCalories() {
    var Cal_req = this.caloriesRequired;
    var Cal_req_00 = parseInt(Cal_req);
    var Cal_max = Cal_req_00 + 500;
    var Tot_Cal = Math.ceil(parseInt(this.totalCalories));
      var Tot_Cal_00 = 0;
      if (Tot_Cal != 0) {
        Tot_Cal_00 = Tot_Cal;
      }
  }

  dietPlanHistory(e) {
    const dialogRef1 = this.dialog.open(DietplanhistorypopupComponent, {
      width: '75%',
      height: '500px',
      data: e
    });
    dialogRef1.afterClosed().subscribe(result => {
    });
  }
}
