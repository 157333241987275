<div class="row" style="width:100%;margin:0 auto;">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white ">
                <div class="headerCover">

                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">{{this.masterpageheading}}</h5>
                    </div>

                    <div class="headerButtons">

                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngIf="this.masterType == 'GM'">
                        <div class="row">

                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

                                <div class="cover_div" style="padding-bottom: 13px;padding: 15px 45px 9px 45px">
                                    <div class="header_lable">
                                        Generic
                                    </div>
                                    <div class="content_cover">
                                        <div class="row">

                                            <div class="row">
                                                <div class="col-12">
                                                    <input placeholder="Generic name" [(ngModel)]="genericinputname"
                                                        matInput>
                                                    <img src="../../../assets/img/add.png" style="width: 90px;
                                                height: 30px;
                                                border: 1px solid gainsboro;border-radius: 5px;margin-left:10px"
                                                        class="saveimgbtn_inpatinfo" (click)="addgeneric()" />
                                                </div>


                                            </div>

                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 masterdatabox">

                                                <div class="row">
                                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 masterboxcol"
                                                        *ngFor="let generic of genericarray">


                                                        <table id="salesTable" class="table table-sm discount_table">

                                                            <tbody style="border-radius: 10px;">
                                                                <tr style="margin-bottom: 5px;">
                                                                    <td style="  text-align: left;
                                                                    padding-left:5px;
                                                                    padding-bottom: 15px;
                                                                     width: 45%;">{{generic.genericname}}</td>
                                                                    <td style="  text-align: right;
                                                                    padding-bottom: 15px">
                                                                        <a
                                                                            (click)="editgenericDetails(generic.genericname,generic.generic_id)">

                                                                            <img src="../../assets/img/edit.png"
                                                                                width="20" height="20"
                                                                                style="margin: 0 5px;" />
                                                                        </a>
                                                                        <a
                                                                            (click)="deletegenericDetails(generic.generic_id)">


                                                                            <img src="../../assets/dist/images/delete.png"
                                                                                width="20" height="20"
                                                                                style="margin: 0 5px;" />
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                            </tbody>

                                                        </table>

                                                    </div>

                                                </div>






                                            </div>





                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngIf="this.masterType == 'SM'">
                        <div class="row">

                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

                                <div class="cover_div" style="padding-bottom: 13px;padding: 15px 45px 9px 45px">
                                    <div class="header_lable">
                                        Schedule
                                    </div>
                                    <div class="content_cover">
                                        <div class="row">

                                            <div class="row">
                                                <div class="col-12">
                                                    <input placeholder="Schedule name" [(ngModel)]="scheduleinputname"
                                                        style="margin-left:6%" matInput>
                                                    <img src="../../../assets/img/add.png" style="width: 90px;
                                            height: 30px;
                                            border: 1px solid gainsboro;border-radius: 5px;margin-left:10px"
                                                        class="saveimgbtn_inpatinfo" (click)="addschedule()" />
                                                </div>


                                            </div>

                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 masterdatabox">

                                                <div class="row">
                                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 masterboxcol"
                                                        *ngFor="let schedule of schedulearray">


                                                        <table id="salesTable" class="table table-sm  discount_table">

                                                            <tbody style="border-radius: 10px;">
                                                                <tr>
                                                                    <td style="text-align: left;
                                                                    padding-left:5px;
                                                                    padding-bottom: 15px;
                                                                     width: 45%;">{{schedule.schedulename}}</td>
                                                                    <td style="  text-align: right;
                                                                    padding-bottom: 15px">
                                                                        <a
                                                                            (click)="editscheduleDetails(schedule.schedulename,schedule.schedule_type_id)">


                                                                            <img src="../../assets/img/edit.png"
                                                                                width="20" height="20"
                                                                                style="margin: 0 5px;" />
                                                                        </a>
                                                                        <a
                                                                            (click)="deletescheduleDetails(schedule.schedule_type_id)">


                                                                            <img src="../../assets/dist/images/delete.png"
                                                                                width="20" height="20"
                                                                                style="margin: 0 5px;" />
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                            </tbody>

                                                        </table>

                                                    </div>

                                                </div>






                                            </div>





                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngIf="this.masterType == 'IFM'">
                        <div class="row">

                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

                                <div class="cover_div" style="padding-bottom: 13px;padding: 15px 45px 9px 45px">
                                    <div class="header_lable">
                                        Product Form
                                    </div>
                                    <div class="content_cover">
                                        <div class="row">

                                            <div class="row">
                                                <div class="col-12">
                                                    <input placeholder="Product name" [(ngModel)]="itemforminputname"
                                                        style="margin-left:6%" matInput>
                                                    <img src="../../../assets/img/add.png" style="width: 90px;
                                        height: 30px;
                                        border: 1px solid gainsboro;border-radius: 5px;margin-left:10px"
                                                        class="saveimgbtn_inpatinfo" (click)="additemform()" />
                                                </div>


                                            </div>

                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 masterdatabox">

                                                <div class="row">
                                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 masterboxcol"
                                                        *ngFor="let itemform of itemformarray">


                                                        <table id="salesTable" class="table table-sm discount_table">

                                                            <tbody style="border-radius: 10px;">
                                                                <tr>
                                                                    <td style="text-align: left;
                                                                    padding-left:5px;
                                                                    padding-bottom: 15px;
                                                                     width: 45%;"> {{itemform.itemformname}}</td>
                                                                    <td style="  text-align: right;
                                                                    padding-bottom: 15px">
                                                                        <a
                                                                            (click)="edititemformDetails(itemform.itemformname,itemform.item_form_id)">


                                                                            <img src="../../assets/img/edit.png"
                                                                                width="20" height="20"
                                                                                style="margin: 0 5px;" />
                                                                        </a>
                                                                        <a
                                                                            (click)="deleteitemformDetails(itemform.item_form_id)">


                                                                            <img src="../../assets/dist/images/delete.png"
                                                                                width="20" height="20"
                                                                                style="margin: 0 5px;" />
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                            </tbody>

                                                        </table>

                                                    </div>

                                                </div>






                                            </div>





                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>


                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>