import { Component, OnInit , ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Data, Route, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { PharmacyService } from '../pharmacy/pharmacy.service';
import { FrontDesk_Helper } from '../../FrontDesk_module/FrontDesk_Helper';
import { Helper_Class } from '../../helper_class';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Date_Formate, Time_Formate,  convertNumberToWords  } from 'src/assets/js/common';
import { encrypt_decript } from 'src/assets/js/Encription_Decription';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
import { JsonPipe } from '@angular/common';
import { PharmaPrintService } from '../pharmacy-print.service';
import { Http, Headers } from '@angular/http';

@Component({
  selector: 'app-inward',
  templateUrl: './inward.component.html',
  styleUrls: ['./inward.component.scss']
})
export class InwardComponent implements OnInit {
  @ViewChild('printnoheader') printnoheader: ElementRef;
  @ViewChild('printbanner') printbanner: ElementRef;
  @ViewChild('logowithname') logowithname: ElementRef;

  public orderNo;
  public pharmacyID;
  public userInfo;
  public drugDetailsArray = [];
  public confirmFlag: boolean;
  preferredTimeFlag: boolean;
  dure_show: boolean;
  dtOptions: DataTables.Settings = {};
  OrderByName: String;
  OrderDate: String;
  PrepareTime: String;
  orderNumber: string;
  totalTime: string;
  orderDate: string;
  orderTime: string;
  todayDate: string;
  PurchaseType: string;
  addDeliveryFlag: boolean;
  presFlag: boolean;
  public orderType: boolean
  public ipaddress;
  public totalvalue;
  public viewOrderFlag: boolean = false;
  public tranFlag: boolean;
  public transactionID;
  public cardFlag: boolean;
  public cardNumber;
  public cardHolderName;
  public chequeFlag: boolean;
  public insurFlag: boolean;
  public bankName;
  public payTypeArray: any = [];
  public insurerArray: any = [];
  public payType: string;
  public insurerID: string;
  public saveFlag: boolean = true;
  public filter: string;
  public cityFlag:boolean;
  public cityList=[];
  public fromDate;
  public toDate;
  public firstNameFlag: boolean;
  public locationFlag: boolean;
  public fromFlag: boolean;
  public toFlag: boolean;
  public uhidFlag: boolean;
  public mobileFlag: boolean;
  public fdateFlag: boolean;
  public tdateFlag: boolean;
  public currentDate;
  public getDate;
  public currentYear;
  public currentTime;
  public locationList = [];
  public cityDesc: string;
  public firstName: string;
  public lastName: string;
  public patientId: string;
  public appType="all";
  public location;
  public mobileNo;
  public docRegId;
  public doctorFlag;
  public billList = [];
  public billingList = [];
  public ynopt1: boolean = true;
  public billnoFlag:boolean;
  public salutation:string;
  public clntGender;
  public clntContNumber;
  public clntEmergNumber;
  public clntEmergNumber1;
  public clientname;
  public clntDOB;
  public dobDisabledFlag:boolean;
  public clntAge;
  public clntFatherOrGuardName;
  public clntAddress1;
  public clntAddress2;
  public aadharNumber;
  public address;
  public clntLocation;
  public clntCountry;
  public clntState;
  public clntCity;
  public clntZipcode;
  public ref_source;
  public mr_no;
  public doctorName;
  public paytype;
  public billTemplate;
  public pharmaLogo;
  public drsal="Dr. ";
  public inwardsarray=[];
  public discountURl
  public discountDatasArray: any = [];
  public discountType: string;
  public discountdesc;
  public discount_id;
  public discountvalue_amount;
  public disperce;
  public rateplanetype;
  public aftdiscamt: number = 0;
  public discamount: number = 0;
  public GSTamount:any=0.00;
  public prodDiscount:any=0.00;
  public actualamount:any=0.00;
  public pendingamt:any=0.00;
  public paidamt:any=0.00
  public printarray:any=[];
  public dateformate;
  public numToWords;
  public printerflag;
  public patienttype;
  public bgImage: string;
  public city;
  public state;
  public country;
  public pharmaName;
  public dlNO;
  public GSTNo;
  public pharmaAddress1;
  public pharmaAddress2;
  public show_hsn;

  constructor(public printService: PharmaPrintService,public pharmacyservice: MenuViewService, public toastr: ToastrService, public gservice: CommonDataService,public httpp: Http, public http: HttpClient, public router: Router, public dialog: MatDialog) {
    this.confirmFlag = false;
    this.presFlag = false;
    this.insurFlag = true;
    this.tranFlag = true;
    this.cardFlag = true;
    this.chequeFlag = true;
    this.cityFlag = true;
    this.firstNameFlag = true;
    this.locationFlag = true;
    this.fromFlag = false;
    this.toFlag = true;
    this.uhidFlag = true;
    this.mobileFlag = true;
    this.discountURl = ipaddress.getIp.toString() + "pharmacontrol/gdis";
  }
  ngOnInit(): void {
    this.printerflag=true;
    this.userInfo = Helper_Class.getInfo();
    this.pharmaLogo = ipaddress.Ip_with_img_address + this.userInfo.hospitals[0].bill_logo;
    this.bgImage = this.userInfo.hospitals[0].bg_image != undefined ? this.userInfo.hospitals[0].bg_image : undefined;
    this.billTemplate = this.userInfo.hospitals[0].bill_template;
    this.pharmacyID = this.userInfo.hospitals[0].hptl_clinic_id;
    this.ipaddress = Helper_Class.getIPAddress();
    this.addDeliveryFlag = true;
    this.CurrentDate();
    this.getPaymentType();
    this.getDiscTypes()
    this.filter = "bill_no";
    this.pharmaName = this.userInfo.hospitals[0].hptl_name;
    this.location = this.userInfo.hospitals[0].location_desc;
    this.city = this.userInfo.hospitals[0].city_desc;
    this.state = this.userInfo.hospitals[0].state_desc;
    this.country = this.userInfo.hospitals[0].country_desc;
    this.pharmaAddress1 = this.userInfo.hospitals[0].address1 != undefined && this.userInfo.hospitals[0].address1 != null && this.userInfo.hospitals[0].address1 != "" && this.userInfo.hospitals[0].address1 != "undefined" ? this.userInfo.hospitals[0].address1 : undefined;
    this.pharmaAddress2 = this.userInfo.hospitals[0].address2 != undefined && this.userInfo.hospitals[0].address2 != null && this.userInfo.hospitals[0].address2 != "" && this.userInfo.hospitals[0].address2 != "undefined" ? this.userInfo.hospitals[0].address2 : undefined;
    this.show_hsn = this.userInfo.hospitals[0].show_hsn;
    this.dlNO = this.userInfo.hospitals[0].dl_no;
    this.GSTNo = this.userInfo.hospitals[0].gst_no;
    if(this.fromDate == undefined){
      this.getCurrentDate();
    }
  }




  getDiscTypes() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');


    this.httpp.post(this.discountURl, { center_id: this.pharmacyID }, { headers: headers }).subscribe(
      data => {
        this.discountDatasArray = [];
        var distypes = data.json();
        console.log("discount type = " + JSON.stringify(distypes))
        this.discountDatasArray = distypes.disc_types;
     
        for(var i=0;i<this.discountDatasArray.length;i++){
          if(this.discountDatasArray[i].default_val == 1){
            this.discountType = this.discountDatasArray[i].disc_type_id;
            break
          }else{
            this.discountType = this.discountDatasArray[0].disc_type_id;
          }
        }
        this.discountChange(this.discountType)


      }, error => { });
  }

  discountChange(distype) {
    // for (let k = 0; k < this.drugDetailsArray.length; k++) {
    //   if (this.drugDetailsArray[k].drug_id != "") {
    //     this.rateplaneflag = true;
    //   }
    // }
    for (var i = 0; i < this.discountDatasArray.length; i++) {

      if (distype == this.discountDatasArray[i].disc_type_id) {
        
        this.rateplanetype=this.discountDatasArray[i].description;
        this.discountdesc = this.discountDatasArray[i].discount_type;
     
        this.discount_id = this.discountDatasArray[i].disc_type_id;
        this.disperce = this.discountDatasArray[i].discount;
        this.discountvalue_amount = this.discountDatasArray[i].discount;
        // this.discountcalculatestaff();
       
      }
    }

  }

 
  discountcalculatestaff(data,action) {
    console.log("list in discount data = "+JSON.stringify(data))
    // if (this.discountdesc == "cost plus gst") {
      // this.remainBalance = "0.00";
      // this.GSTamountflag = false;
        this.discamount = 0;
      // this.totalAmount = 0;
      // this.prodDiscount = 0;
      // this.GSTamount = 0;
      // this.amountDicount = "0.00";
      // for (var i = 0; i < this.drugDetailsArray.length; i++) {
         this.aftdiscamt = 0;
        var price = 0;
        var pricelineamount =0;
        // var costprice = 0;
        var discgst = 0;
        var dissgst = 0;
        var cgstcal = 0;
        var sgstcal = 0;
        var gstamount=0;
        var calTotalamount = 0;
        // var costpricesubstute;
        // var salsepricesubstute;
        // var costlineamount = 0;

      //   if (this.drugDetailsArray[i].drug_id != "") {

      //     if (this.drugDetailsArray[i].cost_price != undefined && this.drugDetailsArray[i].cost_price != null && this.drugDetailsArray[i].cost_price != "") {
      //       //costprice=
      //       costprice = parseFloat(this.drugDetailsArray[i].cost_price) / parseInt(this.drugDetailsArray[i].per_unit);

      //       costlineamount = costprice * this.drugDetailsArray[i].quantity;
      //       costpricesubstute = costlineamount - (parseFloat(this.discountvalue_amount) / 100);

      //       //salesprice
            price = parseFloat(data.price)
             pricelineamount = price * data.quantity;
            //  alert(price +" "+data.quantity)
      //       // discount cal
             var disamt = pricelineamount * (parseFloat(data.discount) / 100)
      
              // this.discamount = (pricelineamount * (parseFloat(data.discount) / 100))
             
             this.aftdiscamt = (pricelineamount - disamt);
      //     }
      //     else {
      //       costpricesubstute = 0;
      //       salsepricesubstute = 0;
      //     }
          if (data.cgst != undefined && data.cgst != null && data.cgst != "") {
            discgst = parseFloat(data.cgst)
          } else {
            discgst = 0
          }
          if (data.sgst != undefined && data.sgst != null && data.sgst != "") {
            dissgst = parseFloat(data.sgst)
          } else {
            dissgst = 0
          }


      //     this.drugDetailsArray[i].price = costprice.toFixed(2);
      //     this.drugDetailsArray[i].amount = costlineamount.toFixed(2);
      //     this.drugDetailsArray[i].discountstaff = this.discamount;
          if(this.discountdesc != "MRP"){
            cgstcal = parseFloat((this.aftdiscamt * discgst / 100).toFixed(2))
           sgstcal = parseFloat((this.aftdiscamt * dissgst / 100).toFixed(2))
           calTotalamount = this.aftdiscamt + cgstcal + sgstcal;
           gstamount = parseFloat((cgstcal + sgstcal).toFixed(2));
          }else{
            calTotalamount = this.aftdiscamt
          } 
          

          

      //     if (this.GSTamount == 0) {
      //       this.GSTamountflag = true

      //     } else {
      //       this.GSTamountflag = false
      //     }
          
        
      
           //     this.drugDetailsArray[i].calamt = calTotalamount;
           if (action == "select") {
            this.actualamount=(parseFloat(this.actualamount) + parseFloat(pricelineamount.toFixed(2))).toFixed(2);
            
            this.totalvalue = Math.round(parseFloat(this.totalvalue) + parseFloat(calTotalamount.toFixed(2))).toFixed(2);
            if(this.discountdesc != "MRP"){
            this.GSTamount= (parseFloat(this.GSTamount) + parseFloat(gstamount.toFixed(2))).toFixed(2);
          }
            this.prodDiscount = (parseFloat(this.prodDiscount) + parseFloat(disamt.toFixed(2))).toFixed(2);
          } else {
            this.actualamount=(parseFloat(this.actualamount) - parseFloat(pricelineamount.toFixed(2))).toFixed(2);
            this.totalvalue =  Math.round(parseFloat(this.totalvalue) - parseFloat(calTotalamount.toFixed(2))).toFixed(2);
            if(this.discountdesc != "MRP"){
            this.GSTamount= (parseFloat(this.GSTamount) - parseFloat(gstamount.toFixed(2))).toFixed(2);
            }
            this.prodDiscount = (parseFloat(this.prodDiscount) - parseFloat(disamt.toFixed(2))).toFixed(2);
          }
      
      //     this.totalAmount = this.totalAmount.toFixed(2); // Convert totalAmount to a string with 2 decimal places
      //     this.prodDiscount = this.discamount.toFixed(2);

      //     if (this.prodDiscount == 0.00) {
      //       this.discoutFlag = true

      //     } else {
      //       this.discoutFlag = false
      //     }
      //     if (this.amountDicount != "") {
      //       this.amountDicount = Math.round(parseFloat(this.amountDicount) + calTotalamount).toFixed(2)

      //     } else {
      //       this.amountDicount = calTotalamount.toFixed(2)
      //       // Math.round(amtval).toFixed(2);
      //     }



      //     if (this.paidAmount != "") {
            
      //       this.remainBalance = Math.round(parseFloat(this.amountDicount) - parseFloat(this.paidAmount)).toFixed(2);
          
      //     } else {
            
      //       this.remainBalance = Math.round(parseFloat(this.amountDicount) - 0).toFixed(2);
          
      //     }

      //     if(this.returnbilltotalAmount != 0){
      //       this.remainBalance= (parseFloat(this.remainBalance) - this.returnbilltotalAmount).toFixed(2)
      //     }

      //   }
      // }
    // } 
    // else if (this.discountdesc == "sales plus gst") {
    //   //salesplucgst----	Own staff
    //   this.remainBalance = "0.00";
    //   this.GSTamountflag = false;
    //   this.discamount = 0;
    //   this.totalAmount = 0;
    //   this.amountDicount = "0.00";
    //   this.prodDiscount = 0;
    //   this.GSTamount = 0;
    //   for (var i = 0; i < this.drugDetailsArray.length; i++) {
    //     this.aftdiscamt = 0;
    //     var salesprice = 0;
    //     var costprice = 0;
    //     var discgst = 0;
    //     var dissgst = 0;
    //     var cgstcal = 0;
    //     var sgstcal = 0;
    //     var calTotalamount = 0;
    //     var saleslineamount = 0;
    //     var costpricesubstute;
    //     var salsepricesubstute;
    //     console.log("DISCOUNT VALUE sa = " + JSON.stringify(this.drugDetailsArray[i]))



    //     if (this.drugDetailsArray[i].drug_id != "") {
    //       if (this.drugDetailsArray[i].sales_price != undefined && this.drugDetailsArray[i].sales_price != null && this.drugDetailsArray[i].sales_price != "") {
    //         costprice = parseInt(this.drugDetailsArray[i].cost_price) / parseInt(this.drugDetailsArray[i].per_unit);
    //         var costlineamount = costprice * this.drugDetailsArray[i].quantity;
    //         costpricesubstute = costlineamount - (parseFloat(this.discountvalue_amount) / 100);

    //         salesprice = parseFloat(this.drugDetailsArray[i].sales_price) / parseInt(this.drugDetailsArray[i].per_unit);

    //         saleslineamount = salesprice * this.drugDetailsArray[i].quantity;
    //         var disamt = saleslineamount * (parseFloat(this.discountvalue_amount) / 100);
    //         this.aftdiscamt += (saleslineamount - disamt);
    //         this.discamount += disamt

    //         console.log("saleslineamount" + saleslineamount + " sales_price = " + salesprice + " quantity " + this.drugDetailsArray[i].quantity)
    //         console.log("disamt = " + disamt + " sales_price = " + saleslineamount + " discountvalue _amount " + parseFloat(this.discountvalue_amount) / 100)
    //         console.log("after discamt = " + this.aftdiscamt + " salesline = " + saleslineamount + " disamt " + disamt)
    //         console.log("sales price = " + salesprice + " sales_price = " + this.drugDetailsArray[i].sales_price + "" + this.drugDetailsArray[i].per_unit)
    //         console.log("discamount = " + this.discamount + " salesline amount  = " + saleslineamount + " discount amount =  " + parseFloat(this.discountvalue_amount) / 100)
    //       } else {
    //         costprice = 0;
    //         salesprice = 0;
    //       }
    //       if (this.drugDetailsArray[i].cgst != undefined && this.drugDetailsArray[i].cgst != null && this.drugDetailsArray[i].cgst != "") {
    //         discgst = parseFloat(this.drugDetailsArray[i].cgst)
    //       } else {
    //         discgst = 0
    //       }
    //       if (this.drugDetailsArray[i].sgst != undefined && this.drugDetailsArray[i].sgst != null && this.drugDetailsArray[i].sgst != "") {
    //         dissgst = parseFloat(this.drugDetailsArray[i].sgst)
    //       } else {
    //         dissgst = 0
    //       }
    //       this.drugDetailsArray[i].price = salesprice.toFixed(2)
    //       console.log("sales price = " + salesprice)
    //       this.drugDetailsArray[i].amount = saleslineamount.toFixed(2);
    //       console.log("saleslineamount = " + saleslineamount)

    //       this.drugDetailsArray[i].discountstaff = this.discamount;


    //       cgstcal = (this.aftdiscamt * (discgst / 100))
    //       sgstcal = (this.aftdiscamt * (dissgst / 100))

    //       // this.GSTamount += cgstcal+sgstcal
    //       this.GSTamount += parseFloat((cgstcal + sgstcal).toFixed(2));

    //       if (this.GSTamount == 0) {
    //         this.GSTamountflag = true

    //       } else {
    //         this.GSTamountflag = false
    //       }
    //       calTotalamount += this.aftdiscamt + cgstcal + sgstcal
    //       console.log("afterdiscamt = " + this.aftdiscamt + " cgst " + cgstcal + " sgst " + sgstcal)
    //       console.log("calTotalamount = " + calTotalamount)
    //       this.drugDetailsArray[i].calamt = calTotalamount
    //       console.log("afterdiscamt = " + this.totalAmount + " cgst " + saleslineamount)
    //       this.totalAmount = parseFloat(this.totalAmount) + parseFloat(saleslineamount.toFixed(2));
    //       this.totalAmount = this.totalAmount.toFixed(2); // Convert totalAmount to a string with 2 decimal places
    //       // this.totalAmount += saleslineamount.toFixed(2);
    //       this.prodDiscount = this.discamount.toFixed(2);
    //       if (this.prodDiscount == 0.00) {
    //         this.discoutFlag = true
    //       } else {
    //         this.discoutFlag = false
    //       }

    //       if (this.amountDicount != "") {
    //         this.amountDicount = Math.round(parseFloat(this.amountDicount) + calTotalamount).toFixed(2)
    //       } else {
    //         this.amountDicount = Math.round(calTotalamount).toFixed(2)
    //       }

    //       console.log("amount dicont = " + this.amountDicount + " calTotalamount = " + calTotalamount)
    //       if (this.paidAmount != "") {
    //         this.remainBalance = Math.round(parseFloat(this.amountDicount) - parseFloat(this.paidAmount)).toFixed(2) ;
    //       } else {
    //         this.remainBalance = Math.round(parseFloat(this.amountDicount) - 0).toFixed(2);
    //       }

    //       if(this.returnbilltotalAmount != 0){
    //         this.remainBalance= (parseFloat(this.remainBalance) - this.returnbilltotalAmount).toFixed(2)
    //       }
    //     }
    //   }
    // } else if (this.discountdesc == "MRP") {
    //   this.discamount = 0;
    //   this.remainBalance = "0.00";
    //   this.GSTamountflag = true;
    //   this.totalAmount = 0;
    //   this.prodDiscount = 0;
    //   this.GSTamount = 0;
    //   this.amountDicount = "0.00";
    //   if(this.rateplanetype == "Product wise"){
    //     this.lineitmdisflag=false
    //   }
    //   else
    //   {
    //       this.lineitmdisflag=true
    //     }

    //   for (var i = 0; i < this.drugDetailsArray.length; i++) {
    //     this.aftdiscamt = 0;
    //     var salesprice = 0;
    //     var costprice = 0;
    //     var discgst = 0;
    //     var dissgst = 0;
    //     var cgstcal = 0;
    //     var sgstcal = 0;
    //     var calTotalamount = 0;
    //     var saleslineamount = 0;
    //     var costpricesubstute;
    //     var salsepricesubstute;
    //     var mrpprice = 0;
    //     var mrpcallineamount = 0;
    //     var mrpdisamt;

    //     console.log("DISCOUNT VALUE mr = " + JSON.stringify(this.drugDetailsArray[i]))



    //     if (this.drugDetailsArray[i].drug_id != "") {
    //       if (this.drugDetailsArray[i].cost_price != undefined && this.drugDetailsArray[i].cost_price != null && this.drugDetailsArray[i].cost_price != "") {
    //         costprice = parseInt(this.drugDetailsArray[i].cost_price) / parseInt(this.drugDetailsArray[i].per_unit);
    //         costpricesubstute = costprice - this.discountvalue_amount
    //         salesprice = parseInt(this.drugDetailsArray[i].sales_price) / parseInt(this.drugDetailsArray[i].per_unit);
    //         salsepricesubstute = salsepricesubstute - this.discountvalue_amount
    //       } else {
    //         costprice = 0;
    //         salesprice = 0;
    //       }
    //       if (this.drugDetailsArray[i].cgst != undefined && this.drugDetailsArray[i].cgst != null && this.drugDetailsArray[i].cgst != "") {
    //         discgst = parseFloat(this.drugDetailsArray[i].cgst)
    //       } else {
    //         discgst = 0
    //       }
    //       if (this.drugDetailsArray[i].sgst != undefined && this.drugDetailsArray[i].sgst != null && this.drugDetailsArray[i].sgst != "") {
    //         dissgst = parseFloat(this.drugDetailsArray[i].sgst)
    //       } else {
    //         dissgst = 0
    //       }
    //       this.drugDetailsArray[i].price = this.drugDetailsArray[i].mrp
    //       console.log("mRP PRICE IN DISCONT =  = " + JSON.stringify(this.drugDetailsArray[i].price))
    //       mrpprice = this.drugDetailsArray[i].mrp
    //       this.drugDetailsArray[i].amount = (parseFloat(this.drugDetailsArray[i].mrp) * this.drugDetailsArray[i].quantity).toFixed(2);
    //       console.log("aMOUNT =  = " + JSON.stringify(this.drugDetailsArray[i].amount))

    //       mrpcallineamount = mrpprice * ((this.drugDetailsArray[i].quantity));
    //       console.log("mrpcallineamount =  = " + mrpcallineamount)
         
    //       if(this.rateplanetype == "Product wise"){
          
    //         if(this.drugDetailsArray[i].discount != undefined && this.drugDetailsArray[i].discount != null && this.drugDetailsArray[i].discount != ""){
    //           console.log("lineitemdiscount"+this.drugDetailsArray[i].discount)
    //         }
    //         else{
    //           this.drugDetailsArray[i].discount=0
    //         }
    //         mrpdisamt = mrpcallineamount * (parseFloat(this.drugDetailsArray[i].discount) / 100)
    //         this.discamount += (mrpcallineamount * (parseFloat(this.drugDetailsArray[i].discount) / 100))
    //       }else{
    //         mrpdisamt = mrpcallineamount * (parseFloat(this.discountvalue_amount) / 100)
    //         this.discamount += (mrpcallineamount * (parseFloat(this.discountvalue_amount) / 100))
    //       }

    //       this.aftdiscamt += (mrpcallineamount - mrpdisamt);
    //       console.log("After discount   = " + this.aftdiscamt + " mrpcallineamount = " + mrpcallineamount + " mrpdisamt =  " + mrpdisamt)
    //       this.drugDetailsArray[i].discountstaff = this.discamount;
    //       calTotalamount += this.aftdiscamt
    //       console.log("After discount   = " + this.aftdiscamt + " calTotalamount = " + calTotalamount)
    //       this.drugDetailsArray[i].calamt = calTotalamount
    //       this.totalAmount = parseFloat(this.totalAmount) + parseFloat(mrpcallineamount.toFixed(2));
    //       this.totalAmount = this.totalAmount.toFixed(2); // Convert totalAmount to a string with 2 decimal places
    //       this.prodDiscount = this.discamount.toFixed(2);

    //       console.log("mrpdisamt = " + mrpdisamt + " mrpcallineamount = " + mrpcallineamount + " discountvalue =  " + (parseFloat(this.drugDetailsArray[i].discoun) / 100))
    //       console.log("discamount  = " + this.discamount + " mrpcallineamount = " + mrpcallineamount + " discountvalue =  " + (parseFloat(this.drugDetailsArray[i].discoun) / 100))
    //       console.log("mrpdisamt = " + mrpdisamt + " mrpcallineamount = " + mrpcallineamount + " discountvalue =  " + (parseFloat(this.discountvalue_amount) / 100))
    //       console.log("discamount  = " + this.discamount + " mrpcallineamount = " + mrpcallineamount + " discountvalue =  " + (parseFloat(this.discountvalue_amount) / 100))
    //       console.log("this.totalAmount    = " + this.totalAmount + " mrpcallineamount = " + parseFloat(mrpcallineamount.toFixed(2)))


    //       if (this.prodDiscount == 0.00) {
    //         this.discoutFlag = true

    //       } else {
    //         this.discoutFlag = false
    //       }
    //       if (this.amountDicount != "") {
    //         this.amountDicount = Math.round(parseFloat(this.amountDicount) + calTotalamount).toFixed(2)
    //       } else {
    //         this.amountDicount = Math.round(calTotalamount).toFixed(2)
    //       }


    //       if (this.paidAmount != "") {
    //         this.remainBalance = Math.round(parseFloat(this.amountDicount) - parseFloat(this.paidAmount)).toFixed(2);
    //       } else {
    //         this.remainBalance = Math.round(parseFloat(this.amountDicount) - 0).toFixed(2);
    //       }


    //       if(this.returnbilltotalAmount != 0){
    //         this.remainBalance= (parseFloat(this.remainBalance) - this.returnbilltotalAmount).toFixed(2)
    //       }

    //     }
    //   }
    // }
  
  // }

  }






  change_type(id, value) {
    if (value == "yes") {
      this.ynopt1 = true;
    } else {
      this.ynopt1 = false;
    }
    // this.changeBillingType();
  }

 

  changeFilter() {
    if (this.filter == "bill_no") {
      // this.cityFlag = false;
      // this.locationFlag = true;
      this.billnoFlag=false;
      this.firstNameFlag = true;
      this.fromFlag = true;
      this.toFlag = true;
      this.uhidFlag = true;
      this.mobileFlag = true;
      // this.getCity();

    } else if (this.filter == "Date") {
      this.billnoFlag=true;
      this.cityFlag = true;
      this.locationFlag = true;
      this.firstNameFlag = true;
      this.fromFlag = false;
      this.toFlag = false;
      this.uhidFlag = true;
      this.mobileFlag = true;

    } else if (this.filter == "Name") {
      this.billnoFlag=true;
      this.firstNameFlag = false;
      this.locationFlag = true;
      this.cityFlag = true;
      this.fromFlag = true;
      this.toFlag = true;
      this.uhidFlag = true;
      this.mobileFlag = true;

    } else if (this.filter == "Patient_ID") {
      this.billnoFlag=true;
      this.cityFlag = true;
      this.locationFlag = true;
      this.firstNameFlag = true;
      this.fromFlag = true;
      this.toFlag = true;
      this.uhidFlag = false;
      this.mobileFlag = true;

    } else if (this.filter == "Mobile_No") {
      this.billnoFlag=true;
      this.cityFlag = true;
      this.locationFlag = true;
      this.firstNameFlag = true;
      this.fromFlag = true;
      this.toFlag = true;
      this.uhidFlag = true;
      this.mobileFlag = false;
    }
  }
  selectFromDate(e) {
    FrontDesk_Helper.setBillFromDate(undefined);
    this.fromDate = e;
    FrontDesk_Helper.setBillFromDate(e);
    this.fdateFlag = true;
  }
  getCurrentDate() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate',
      { country: ipaddress.country_code },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          //changeCity
          this.getDate = obj.current_date.split('-');
          this.currentYear = this.getDate[0];
          this.currentTime = obj.current_time;
          this.currentDate = obj.current_date;
          this.fromDate = obj.current_date;
          this.toDate = obj.current_date;
          FrontDesk_Helper.setBillFromDate(this.fromDate);
          FrontDesk_Helper.setBillToDate(this.toDate);
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }

  changeCity(city) {//Get location based on city
    this.locationList = [];
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'dash/loc/',
      {
        reg_id: this.userInfo.hptl_clinic_id,
        city: city,
        type: "pharmacy"
      },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.locationList = obj.locations;
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  fnameToUpper() {
    if (this.firstName != undefined)
      this.firstName = this.firstName.toLocaleUpperCase().trim();
  }
  lnameToUpper() {
    if (this.lastName != undefined)
      this.lastName = this.lastName.toLocaleUpperCase().trim();
  }

  getCity() {// Get city
    this.cityList = [];
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'dash/city/',
      {
        reg_id: this.userInfo.hptl_clinic_id,
        type: "pharmacy"
      },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.cityList = obj.cities;
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  selectToDate(e) {
    FrontDesk_Helper.setBillToDate(undefined);
    this.toDate = e;
    FrontDesk_Helper.setBillToDate(e);
    this.tdateFlag = true;
  }

  searchByOrderNo(e) {
      this.drugDetailsArray = [];
      this.totalvalue = 0;
      var send_data = {
        order_id: e,
        pharmacy_id: this.pharmacyID,
      }
      console.log("send data = "+JSON.stringify(send_data))
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "pharmacontrol/vieworderdetails/", send_data,
        { headers: headers }).subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));
            
            console.log("inward = "+JSON.stringify(obj))
            if (obj != null && obj != undefined) {
              this.viewOrderFlag = true;
              this.discountType=obj.disc_type
              this.discountChange(this.discountType)
              if(obj.order_date != undefined){
              var orderdate = obj.order_date != undefined ? obj.order_date.split('-') : "";
              this.OrderDate = orderdate[2] + "-" + orderdate[1] + "-" + orderdate[0];
              this.PrepareTime = Time_Formate(obj.order_time);
              }
              this.OrderByName = obj.middle_name != undefined ? encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.middle_name) + " " + encrypt_decript.Decript(obj.last_name) : encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.last_name);
              if(obj.balance != undefined){
              this.pendingamt = obj.balance
              }
              if(obj.paid_amount != undefined){
                this.paidamt = obj.paid_amount
                }
              
              this.presFlag = obj.pres_drug_id != undefined ? false : true;
              this.orderNumber = obj.drug_purchase_id != undefined ? obj.drug_purchase_id : "";
              if (obj.salutation != undefined) {
                this.salutation = obj.salutation;
              }
              if(obj.age != undefined){
                this.clntAge=obj.age
              }
              if(obj.gender != undefined){
                this.clntGender=obj.gender

                if(obj.gender == "male" ||"Male"){
                  this.clntGender="M"
              }
              else if(obj.gender == "female" || "Female"){
                this.clntGender="F"
              }
              else if(obj.gender == "tarnsgender" || "others" ||"Trans gender"){
                this.clntGender="TG"
              }
              
              else{
                this.clntGender=""
              }
            }
              if(obj.mr_no != undefined){
                this.mr_no=obj.mr_no
              }

              if(obj.pay_type){
                this.paytype=obj.pay_type
              }

              if(obj.pat_type != undefined){
                this.patienttype=obj.pat_type
              }
              

              // if(obj.dr_name != undefined){
              //   this.doctorName=obj.dr_first_name
              // }

                 if (obj.dr_last_name != undefined && obj.dr_middle_name != null) {
                  this.doctorName= obj.dr_first_name+" "+obj.dr_middle_name+" "+obj.dr_last_name; 
          // clnt_nmae = encrypt_decript.Decript(obj.bill_details[i].first_name) + " " + encrypt_decript.Decript(obj.bill_details[i].middle_name) + " " + encrypt_decript.Decript(obj.bill_details[i].last_name);
        } else {
          this.doctorName= obj.dr_first_name+" "+obj.dr_middle_name+" "+obj.dr_last_name;
          if (  obj.dr_last_name != undefined && obj.dr_last_name != null) {
            // clnt_nmae = encrypt_decript.Decript(obj.bill_details[i].first_name) + " " + encrypt_decript.Decript(obj.bill_details[i].last_name);
          this.doctorName=obj.dr_first_name+" "+obj.dr_last_name;
          } else if(obj.dr_first_name != undefined)
           {
            this.doctorName=obj.dr_first_name;
          }else if(obj.dr_name != undefined){
            this.doctorName= obj.dr_name
          }
          
          else{
            this.doctorName="";
          }
        }
              if (obj.description != undefined) {
                this.PurchaseType = obj.description;
                
              }
              if(obj.pay_desc != undefined){
                this.paytype=obj.pay_desc
              }
              if (obj.preferred_from_time != undefined) {
                this.totalTime = (obj.preferred_from_time) + " " + (obj.preferred_to_time);
                this.preferredTimeFlag = false;
              } else {
                this.preferredTimeFlag = true;
              }
              if (obj.order_date != undefined) {
                this.orderDate = Date_Formate(obj.order_date);
              }
              if (obj.order_time != undefined) {
                this.orderTime = Time_Formate(obj.order_time);
              }

              if (obj.drug_details != null) {


                for (var i = 0; i < obj.drug_details.length; i++) {
                 var reverse_exp
                  if(obj.drug_details[i].expiry_date != undefined){
                    var expiry_date = obj.drug_details[i].expiry_date !=  undefined ? obj.drug_details[i].expiry_date.split('-'):"";
                    reverse_exp = expiry_date[2] + "-" + expiry_date[1] + "-" + expiry_date[0];
                  }
                  var priceval = (parseFloat(obj.drug_details[i].price) / parseFloat(obj.drug_details[i].per_unit)).toFixed(2);
                  var amountval = (parseFloat(obj.drug_details[i].quantity) * parseFloat(priceval)).toFixed(2);
                  var discount;
                  if(this.rateplanetype == "Product wise"){
                    discount=obj.drug_details[i].discount
                  }else if(this.discountvalue_amount != undefined){
                    discount=this.discountvalue_amount
                  }else{
                    discount="0.00"
                  }
                  
                  this.drugDetailsArray.push({
                   sno:i+1,
                    drug_id: obj.drug_details[i].drug_id,
                    type_name: obj.drug_details[i].type_name,
                    drug_name: obj.drug_details[i].drug_name,
                    drug_disp_name:obj.drug_details[i].drug_name,
                    quantity: obj.drug_details[i].quantity,
                    amount: obj.drug_details[i].amount,
                    price: obj.drug_details[i].price,
                    discount: discount,
                    batch_no: obj.drug_details[i].batch_no,
                    batch_hide: this.orderType,
                    cgst: obj.drug_details[i].cgst,
                    sgst: obj.drug_details[i].sgst,
                    expiry_date:reverse_exp,
                    checked : false,
                    paid_amount:	obj.paid_amount
                  })
                  // this.totalvalue = (parseFloat(this.totalvalue) + parseFloat(amountval)).toFixed(2);
                }
              }
              var addval = obj.phar_address2 != undefined ? obj.phar_address1 + "," + obj.phar_address2 : obj.phar_address1;
            } else {
            
              this.viewOrderFlag = false;
            }
          });
    }
  

  searchBill() {

    FrontDesk_Helper.setBillToDate(this.toDate)
    this.billList = [];
    this.billingList = []; 
    var fieldvalue;
    if (this.filter == "Patient_ID") {
      fieldvalue = {
        mr_no: this.patientId,
        type: "mr_no",
        pharma_id: this.pharmacyID,
      }

    }  else if (this.filter == "Name") {
      fieldvalue = {
        type: "first_name",
        first_name: this.firstName,
        last_name:this.lastName,
        pharma_id: this.pharmacyID,
      }

    } else if (this.filter == "Date") {
      fieldvalue = {
        type: "date",
        fdate: this.fromDate,
        tdate: this.toDate,
        pharma_id: this.pharmacyID,
      }

    } else if (this.filter == "Mobile_No") {
   
      fieldvalue={
        type: "mobile",
        mobile:this.mobileNo,
        pharma_id:this.pharmacyID,
      }
    }
     console.log("BILL FILEDS "+JSON.stringify(fieldvalue))
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() +'pharmacontrol/gbbf/',fieldvalue,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log("bill list = "+JSON.stringify(obj));
           if (obj.bill_details.length != 0) {
             console.log(obj);
          //   if (obj.bills != 0) {
          //   if (this.billingType == "Diagnosis") {
          //     this.inSetBillList(obj);
          //     this.centerId = this.userInfo.diag_center_id;
            // }
            //  else {
               this.setBillList(obj);
            //   Helper_Class.setbilllist(obj);
            // }
            } 
          else {
            // this.noBillsFlag = false;
            // this.billTableFlag = true;
            this.billList = [];
            //this.toastr.error(Message_data.noBillsFound);
           }
          // if (obj.bills != null) {

          //  } else {
          //   this.noBillsFlag = false;
          //   this.billTableFlag = true;
          //  }
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });   
        this.patientId = "";
        this.location = "";
        this.cityDesc = "";
        this.firstName = "";
        this.lastName = "";
        this.mobileNo = "";

  }
  

  setBillList(obj) {
    this.billList = [];
    if (obj.bill_details != null) {
      for (var i = 0; i < obj.bill_details.length; i++) {
        var clnt_nmae = null;
        if (obj.bill_details[i].middle_name != undefined && obj.bill_details[i].middle_name != null) {
          clnt_nmae = encrypt_decript.Decript(obj.bill_details[i].first_name) + " " + encrypt_decript.Decript(obj.bill_details[i].middle_name) + " " + encrypt_decript.Decript(obj.bill_details[i].last_name);
        } else {
          if (obj.bill_details[i].last_name != undefined && obj.bill_details[i].last_name != null) {
            clnt_nmae = encrypt_decript.Decript(obj.bill_details[i].first_name) + " " + encrypt_decript.Decript(obj.bill_details[i].last_name);
          } else if(obj.bill_details[i].first_name != undefined)
           {
            clnt_nmae = encrypt_decript.Decript(obj.bill_details[i].first_name);
          }else{
            clnt_nmae=""
          }
        }

          
        // if (obj.bill_details[i].middle_name != undefined && obj.bill_details[i].middle_name != null) {
        //   clnt_nmae = encrypt_decript.Decript(obj.bill_details[i].first_name) + " " + encrypt_decript.Decript(obj.bill_details[i].middle_name) + " " + encrypt_decript.Decript(obj.bill_details[i].last_name);
        // } else {
        //   if (obj.bill_details[i].last_name != undefined && obj.bill_details[i].last_name != null) {
        //     clnt_nmae = encrypt_decript.Decript(obj.bill_details[i].first_name) + " " + encrypt_decript.Decript(obj.bill_details[i].last_name);
        //   } else if(obj.bill_details[i].first_name != undefined)
        //    {
        //     clnt_nmae = encrypt_decript.Decript(obj.bill_details[i].first_name);
        //   }else{
        //     clnt_nmae=""
        //   }
        // }





        var get_date = obj.bill_details[i].order_date.split('-');
        this.billList.push({
          bill_id:obj.bill_details[i].order_id,
          date:get_date[2] + "-" + get_date[1] + "-" + get_date[0],
          client_name:clnt_nmae,
          relation_id:obj.bill_details[i].relation_id,
          salutation:obj.bill_details[i].salutation,
          bill_amount:obj.bill_details[i].bill_amount,
          paid_amount:obj.bill_details[i].paid_amount,
          balance:obj.bill_details[i].balance,
          paid_flag:obj.bill_details[i].paid_flag,
          pay_type:obj.bill_details[i].payment_desc,
          fin_year:obj.bill_details[i].fin_year,
          client_reg_id:obj.bill_details[i].client_reg_id
        });
      }
      
      var tot = 0;
      for (var i = 0; i < this.billList.length; i++) {
        tot = tot + parseFloat(this.billList[i].amount_only);
      }

      // if (this.billList.length == 0) {
      //   this.noBillsFlag = false;
      //   this.billTableFlag = true;
      // } else {
      //   this.noBillsFlag = true;
      //   this.billTableFlag = false;
      // }
    }
  }

  isReadonly() { return true; }
  CurrentDate() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "adm/curdate", {
      country: ipaddress.country_code,
    },
      { headers: headers }).subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.todayDate = obj.current_date;
        });
  }
  CalculatePrice(drugid, typename, drugname, quantity, price, per_unit, discount, gst) {
    this.totalvalue = 0;
    for (var i = 0; i < this.drugDetailsArray.length; i++) {
      if (drugid == this.drugDetailsArray[i].drug_id) {
        var amountval = (parseFloat(this.drugDetailsArray[i].quantity) * parseFloat(this.drugDetailsArray[i].price)).toFixed(2);
        if(amountval == "NaN"){
          amountval="0"
        }
        this.drugDetailsArray[i].quantity = quantity;
        this.drugDetailsArray[i].amount = amountval ;
        this.drugDetailsArray[i].price = price;
        this.drugDetailsArray[i].discount = discount;
      }
      //this.totalvalue = (parseFloat(this.totalvalue) + parseFloat(this.drugDetailsArray[i].amount)).toFixed(2);
    }
  }
  clearField(data) {
    for (var i = 0; i < this.drugDetailsArray.length; i++) {
      if (this.drugDetailsArray[i].drug_id == data.drug_id) {
        this.drugDetailsArray[i].quantity = "";
      }
    }
  }
  // deleteMedicine(drug_id) {
  //   for (var i = 0; i < this.drugDetailsArray.length; i++) {
  //     if (this.drugDetailsArray[i].drug_id == drug_id) {
  //       this.drugDetailsArray.splice(i, 1);
  //     }
  //   }
  //   var prec_tx = 0;
  //   for (var i = 0; i < this.drugDetailsArray.length; i++) {
  //     prec_tx = prec_tx + parseFloat(this.drugDetailsArray[i].amount)
  //   }
  //   this.totalvalue = prec_tx.toFixed(2);
  // }
  save() {
    var checkFlag: boolean = true;
    
    var drugDetails = this.drugDetailsArray.filter((x:any ) => { return x.checked == true });
    if (drugDetails.length == 0) {
      checkFlag = false;
    }
    console.log("drug" + JSON.stringify(drugDetails));
    console.log("totalvalue" + JSON.stringify(this.totalvalue));
    if (checkFlag == true) {
      var send_data = {
        pharma_id: this.pharmacyID,
        order_id: this.orderNo,
        amount: this.totalvalue,
        initiated_by: this.userInfo.user_id,
        drug_details: drugDetails,
        imei: this.ipaddress,
        country: ipaddress.country_code,
        pay_type: this.payType,
        insurer: this.insurerID,
        card_holder_name: this.cardHolderName,
        transaction_no: this.transactionID,
        bank_name: this.bankName,
        checked: false
      }
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "pharmacontrol/sminv", send_data,
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));
            if (obj.key == "1") {
              this.saveFlag = false;
              this.toastr.success(obj.result);
              this.pharmacyservice.sendMessage("pharmacycurentinformation");
            } else {
              this.saveFlag = true;
              this.toastr.error(obj.result);
            }
          }, error => { this.saveFlag = true; })
    } else {
      this.toastr.error(Message_data.noDrugDetFoundToSave);
    }
  }
  getPaymentType() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'bill/paytype',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.payTypeArray = obj.payments;
          this.payType = this.payTypeArray[0].pay_id;
          this.getInsurers();
        },
        error => {
          this.toastr.error(Message_data.network);
        });
  }
  getInsurers() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'bill/gins',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.insurerArray = obj.insurers;
        },
        error => {
          this.toastr.error(Message_data.network);
        });
  }
  isAllSelected(list, action) {
    console.log("selected_medcine"+JSON.stringify(list))
    list.checked = action == "select" ? true : false;
    this.discountcalculatestaff(list,action)
    

    // this.totalvalue = this.drugDetailsArray.filter(function(options){ return options.checked == true }).reduce(function(a,b){
    //   return a +    Math.round(b.paid_amount);
    // },0);
    // this.totalvalue = list.paid_amount
    console.log("this.drugDetailsArray" + JSON.stringify(this.totalvalue))
    this.insurerArray=[];
   this.insurerArray = this.drugDetailsArray
   this.insurerArray = this.insurerArray.filter(item => item.checked === true);
   this.numToWords = this.totalvalue == "0.00" ? " " : convertNumberToWords(this.totalvalue, ipaddress.country_code);
  if(this.insurerArray.length != 0){
    this.printerflag=false
  }else{
    this.printerflag=true
  }
  }


  pay_change() {
    document.getElementById("paytype")!.style.borderColor = '#2ca8fe';
    this.insurFlag = true;
    this.tranFlag = true;
    this.cardFlag = true;
    this.chequeFlag = true;
    if (this.payType == "3") {
      this.insurFlag = false;
    } else if (this.payType == "4") {
      this.tranFlag = false;
    } else if (this.payType == "2") {
      this.tranFlag = false;
      this.cardFlag = false;
    } else if (this.payType == "5") {
      this.chequeFlag = false;
    } else {
      this.insurFlag = true;
    }
  }


  printArea(){
    var template;
    var printData;
  
   console.log("value = "+JSON.stringify(this.insurerArray))
   
   
   if (this.totalvalue != "") {
      this.totalvalue;
    } else {
      this.totalvalue = "0.00"
    }

    if (this.prodDiscount != "") {
      this.prodDiscount;
    } else {
      this.prodDiscount = "0.00"
    }

    if (this.paidamt != "") {
      this.paidamt;
    } else {
      this.paidamt = "0.00"
    }

    if (this.pendingamt != "") {
      this.pendingamt;
    } else {
      this.pendingamt = "0.00"
    }
    if (this.actualamount != "") {
      this.actualamount;
    } else {
      this.actualamount = "0.00"
    }
    if(this.orderDate != undefined){
    this.dateformate=Date_Formate(this.orderDate)
  }
    // printData = {
    //   pagetype:"Inwards",
    //   doctor_desc:this.drsal,
    //   clntsal:this.salutation,
    //   template: this.billTemplate,
    //   content: template,
    //   returnsinward:"refundamount",
    //   retunrsheader:"false",
    //   mrno:this.mr_no,
    //   pharma_logo: this.pharmaLogo,
    //   pharma_name: "",
    //   pharma_addr: "",
    //   location: "",
    //   city: "",
    //   state: "",
    //   country: "",
    //   patient_name: this.OrderByName,
    //   bill_no: this.orderNumber,
    //   PurchaseType: this.PurchaseType,
    //   doc_name: this.doctorName,
    //   order_date: Date_Formate(this.orderDate),
    //   order_time: this.orderTime,
    //   drugList:this.insurerArray,
    //   returns: parseFloat(this.totalvalue).toFixed(2),
    //   discount: parseFloat(this.prodDiscount).toFixed(2),
    //   // total: parseFloat(this.totalvalue).toFixed(2),
    //   paid:parseFloat(this.paidamt).toFixed(2),
    //   billamount: parseFloat(this.actualamount).toFixed(2) ,
    //   balance:  parseFloat(this.pendingamt).toFixed(2),
    //   order_type: true,
    //   show_discount: "",
    //   pay_type: this.paytype,
    //   pay_flag:"",
    //   age:this.clntAge,
    //   gender:this.clntGender,
    // }
    console.log("insurerArray = "+JSON.stringify(this.insurerArray))
    let printContents, popupWin;
    this.billTemplate = "logowithname"
    if (this.billTemplate != undefined && this.billTemplate == "noheader") {
      printContents =  this.printnoheader.nativeElement.innerHTML; 
    }else if(this.billTemplate == "logowithname"){
    printContents = this.logowithname.nativeElement.innerHTML;
    }
    else {
      printContents = this.printbanner.nativeElement.innerHTML;
    }
    popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
    <html>
      <head>
        <title>Bill</title>
        <link rel="stylesheet" media="screen" href="">
        <style>                        
        * {
        font-size: 15px !important; 
      }
        .table_class {
          background-color: rgb(108, 180, 248);
        }
        table.report-container {
          page-break-after:always;
      }
      thead.report-header {
          display:table-header-group;
      }
      tfoot.report-footer {
          display:table-footer-group;
      } 
      tfoot > tr > td.multiColumn {
        position: relative;
        z-index: 1;
        border:none !important;
        }
      p{
        margin:0;
      }
      table { page-break-inside:auto }
      tr{ page-break-inside:avoid; page-break-after:auto }
        *{
          font-size:15px
        }
        </style>
      </head>
      <body onload="window.print(); window.onfocus=function(){ window.close();}">
      
      ${printContents}
      
      </body>
    </html>`);
    popupWin.document.close();
  } 
  
}



// print() {






 

// }