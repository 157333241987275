import { Component, OnInit } from '@angular/core';
import { Helper_Class } from '../../helper_class';
import { Router, ActivatedRoute } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { ClientViewService } from '../clientview/client-view.service';
import { Client_Helper } from '../Client_helper';
import { DomSanitizer } from '@angular/platform-browser';
import { PharmacyprescriptiondetailsComponent } from '../pharmacyprescriptiondetails/pharmacyprescriptiondetails.component';
import { MatDialog } from '@angular/material/dialog';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';
declare var $: any;

@Component({
  selector: 'app-medicalprescription-details',
  templateUrl: './medicalprescription-details.component.html',
  styleUrls: ['./medicalprescription-details.component.css']
})
export class MedicalprescriptionDetailsComponent implements OnInit {
  public PdfFlag: boolean;
  public pdfFile: any;
  public documentUrl: any;
  public docRegId;
  public pharmacyDetails = [];
  public pharmacyDetailsFlag: boolean = false;
  public doctorQualifyData;
  public hospitalName;
  public hospitalAddress;
  public doctorLocation;
  public doctorCity;
  public doctorState;
  public doctorCountry;
  public doctorTelephone;
  public prescriptionUrl: string;
  public prescriptionPresId;
  public tabletTracker = [];
  public patientName: string;
  public doctorName: string;
  public prescriptionDate: string;
  public diseaseName: string;
  public diseaseDescribtion: string;
  public subRelId: string;
  public relId: string;
  public relName: string;
  public pharmaFlag: boolean;
  public pharmacyName: string;
  public pharmacyAddr1: string;
  public pharmacyAddr2: string;
  public pharmacyLocation: string;
  public pharmacyCity: string;
  public pharmacyZipcode: string;
  public pharmacyState: string;
  public pharmacyCountry: string;
  public pharmacyTelephone: string;
  public pharmaId: string;
  public currentDate: string;
  public apptDate: string;
  public storePickFlag: boolean = false;
  public homeDeliveryFlag: boolean = false;
  public validityDays;
  public workTime;
  public notificationId;
  public sendData;
  public viewPresUrl;
  public searchFlag: boolean = false;
  public multiTabFlag: boolean = false;
  public appointmentFlag: boolean = false;
  public balanceQuantity: number = 0;
  public nameFlag: boolean;
  public hospFlag: boolean;
  public appNursePhysio;
  public homecare;
  public orderBackFlag;

  constructor(public dialog: MatDialog, private sanitizer: DomSanitizer, public clientservice: ClientViewService, public http: Http, public routes: ActivatedRoute, public router: Router, public toastr: ToastrService) {
    this.PdfFlag = true;
    this.orderBackFlag = true;
  }

  ngOnInit() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate',
      JSON.stringify({
        country: ipaddress.country_code.toString()
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          if (obj.current_date != null) {
            this.currentDate = obj.current_date;
            var get_date = obj.current_date.split('-');
            this.apptDate = get_date[2] + "-" + get_date[1] + "-" + get_date[0]
          }
        },error => {
          this.toastr.error(Message_data.network);
        });

    this.prescriptionUrl = "prescription/medpresdet";
    this.viewPresUrl = "gen/gp";
    this.prescriptionPresId = Client_Helper.getmedialpres().pres_id;
    this.notificationId = localStorage.getItem('notification_id');
    this.appNursePhysio =  Client_Helper.getHomecare_nurse_physio();
    this.homecare = localStorage.getItem('home_care');
    this.notificationId = localStorage.getItem('notification_id');
    if (Client_Helper.getHomecare_nurse_physio() == "nurse") {
      this.appointmentFlag = true;
      this.searchFlag = false;
      this.sendData = {
        pres_id: this.prescriptionPresId,
      }
      this.retrievePresDetails(this.prescriptionUrl);
    } else if (Client_Helper.getmedialpres().flow == "order") {
      this.pharmaFlag = true;
      this.searchFlag = true;
      this.sendData = {
        pres_id: this.prescriptionPresId,
      }
      this.retrievePresDetails(this.prescriptionUrl);
    } else if (localStorage.getItem("secopn") != undefined) {
      this.sendData = {
        pres_id: localStorage.getItem("pres_test_id"),
      }
      this.retrievePresDetails(this.prescriptionUrl);
    } else if (this.notificationId != null) {
      this.sendData = {
        pres_drug_id: this.prescriptionPresId,
        notification_id: this.notificationId,
        type: "client"
      }
      this.retrievePresDetails(this.viewPresUrl);
      this.searchFlag = false;
    } else {
      this.sendData = {
        pres_id: this.prescriptionPresId,
      }
      this.retrievePresDetails(this.prescriptionUrl);
    }
  }

  back() {
    if ( Client_Helper.getHomecare_nurse_physio() == "nurse") {
      Client_Helper.setHomecare_nurse_physio(this.appNursePhysio);
      Client_Helper.setHomecare(this.homecare);
      this.clientservice.sendMessage("client_nurse_appcreate_new");
    } else if (Client_Helper.getmedialpres().flow == "order") {
      this.clientservice.sendMessage("pharmacyprescriptionrelation");
    }else {
      this.clientservice.sendMessage('recordwallet');
    }
  }

  retrievePresDetails(url) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + url,
      JSON.stringify(
        this.sendData
      ),
      { headers: headers }).subscribe(
        data => {
          this.nameFlag = false;
          var med_format =  null;
          var obj = data.json();
          if (obj.length != 0) {
            if (obj.first_name != null && obj.first_name != undefined) {
              if (obj.middle_name != null && obj.middle_name != "") {
                this.doctorName = obj.first_name + ' ' + obj.middle_name + ' ' + obj.last_name;
              } else {
                this.doctorName = obj.first_name + ' ' + obj.last_name;
              }
              this.nameFlag = true;
            }
            if (obj.qualification != null) {
              this.doctorQualifyData = obj.qualification;
            }
            if (obj.cl_middle_name != null && obj.cl_middle_name != "" && encrypt_decript.Decript(obj.cl_middle_name).toString() != undefined) {
              this.patientName = encrypt_decript.Decript(obj.cl_first_name).toString() + ' ' + encrypt_decript.Decript(obj.cl_middle_name).toString() + ' ' + encrypt_decript.Decript(obj.cl_last_name).toString();
            } else {
              this.patientName = encrypt_decript.Decript(obj.cl_first_name).toString() + ' ' + encrypt_decript.Decript(obj.cl_last_name).toString();
            }
            if (obj.doc_reg_id != null) {
              this.docRegId = obj.doc_reg_id;
            }
            if ((obj.hptl_name != null && obj.hptl_name != undefined) && (obj.address1 != null && obj.address1 != undefined)) {
              this.hospFlag = false;
              if (obj.hptl_name != null) {
                this.hospitalName = obj.hptl_name;
              }
              if ((obj.address1 != null && obj.address1 != undefined) && (obj.address2 != null && obj.address2 != undefined)) {
                this.hospitalAddress = (obj.address1.toString()) + ' ' + (obj.address2.toString() + ', ');
              } else {
                if (obj.address1 != null && obj.address1 != undefined) {
                  this.hospitalAddress = (obj.address1.toString()) + ', ';
                }
              }
              if (obj.location != null) {
                this.doctorLocation = obj.location;
              }
              if (obj.city != null && obj.zipcode != null) {
                this.doctorCity = obj.city + " - " + obj.zipcode;
              }
              if (obj.state != null) {
                this.doctorState = obj.state;
              }
              if (obj.country != null) {
                this.doctorCountry = obj.country;
              }
              if (obj.telephone != null) {
                this.doctorTelephone = obj.telephone;
              }
            } else {
              this.hospFlag = true;
            }
            if (obj.pharmacy_id != null) {
              this.pharmacyDetailsFlag = true;
              if (obj.pharmacy_id != null) {
                this.pharmaId = obj.pharmacy_id;
              }
              if (obj.pharmacy_name != null) {
                this.pharmacyName = obj.pharmacy_name;
                if (Client_Helper.getmedialpres().flow == "order") {
                  this.pharmaFlag = true;
                }
              } else {
                this.pharmaFlag = false;
              }
              if (obj.par_address1 != null) {
                this.pharmacyAddr1 = obj.par_address1;
              }
              if (obj.par_address2 != null) {
                this.pharmacyAddr2 = obj.par_address2;
              }
              if (obj.par_location != null) {
                this.pharmacyLocation = obj.par_location;
              }
              if (obj.par_city != null) {
                this.pharmacyCity = obj.par_city;
              }
              if (obj.par_zipcode != null) {
                this.pharmacyZipcode = obj.par_zipcode;
              }
              if (obj.par_state != null) {
                this.pharmacyState = obj.par_state;
              }
              if (obj.par_country != null) {
                this.pharmacyCountry = obj.par_country;
              }
              if (obj.par_telephone != null) {
                this.pharmacyTelephone = obj.par_telephone;
              }
              this.pharmacyDetails.push({
                pharmacy_name: this.pharmacyName,
                phar_addr1: this.pharmacyAddr1,
                phar_addr2: this.pharmacyAddr2,
                phar_loc: this.pharmacyLocation,
                phar_city: this.pharmacyCity,
                phar_zipcode: this.pharmacyZipcode,
                phar_state: this.pharmacyState,
                phar_cnty: this.pharmacyCountry,
                telephone: this.pharmacyTelephone
              });
            }
            else {
              this.pharmacyDetailsFlag = false;
            }
            if (obj.store_pickup != null || (obj.store_pickup != null && obj.store_pickup == "1")) {
              this.storePickFlag = true;
            }
            if (obj.home_delivery != null || (obj.home_delivery != null && obj.home_delivery == "1")) {
              this.homeDeliveryFlag = true;
            }
            if (obj.store_pickup_validity_days != null) {
              this.validityDays = obj.store_pickup_validity_days;
            }
            if (obj != undefined && obj.pres_date != undefined) {
              this.prescriptionDate = obj.pres_date.split('-')[2] + "-" + obj.pres_date.split('-')[1] + "-" + obj.pres_date.split('-')[0];
            }
            this.diseaseName = obj.disease;
            this.diseaseDescribtion = obj.dis_desc;
            if (obj.sub_rel_id != null) {
              this.subRelId = obj.sub_rel_id;
            }
            if (obj.relation_id != null) {
              this.relId = obj.relation_id;
            }
            if (obj.relationship_name != null) {
              this.relName = obj.relationship_name;
            }
            if (obj.available_from != null) {
              this.workTime = "Timings : " + obj.available_from + " - " + obj.available_to;
            }
            if(obj.doc_url != undefined && obj.doc_url != null && obj.doc_url != ""){
              this.pdfFile = this.sanitizer.bypassSecurityTrustResourceUrl(ipaddress.Ip_with_img_address + obj.doc_url);
              this.documentUrl = ipaddress.Ip_with_img_address + obj.doc_url;
            }
            if (obj.drug_list.length == 0 && obj.doc_url != undefined && obj.doc_url != null && obj.doc_url != "") {
              this.PdfFlag = false;
            }else{
              this.PdfFlag = true;
            }
            for (var i = 0; i < obj.drug_list.length; i++) {
              if (i != 0) {
                if (Client_Helper.getmedialpres().flow == "order") {
                  this.multiTabFlag = true;
                } else {
                  this.multiTabFlag = false;
                }
              }
              var master_tablet_data = obj.drug_list[i];
              var medicene_dosage_data = "";
              if (master_tablet_data.show_short_form == "1") {
                var morntxt;
                if (master_tablet_data.intake == "1") {
                  medicene_dosage_data = master_tablet_data.morning+" - 0 - 0";
                } else if (master_tablet_data.intake == "2"){
                  medicene_dosage_data = "0 - "+master_tablet_data.afternoon+" - 0";
                } else if (master_tablet_data.intake == "3"){
                  medicene_dosage_data = "0 - 0 - "+master_tablet_data.night;
                } else if (master_tablet_data.intake == "4"){
                  medicene_dosage_data = master_tablet_data.morning+" - "+master_tablet_data.afternoon+" - 0";
                }else if (master_tablet_data.intake == "5"){
                  medicene_dosage_data=master_tablet_data.morning+" - 0 - "+master_tablet_data.night;
                }else if(master_tablet_data.intake =="6"){
                  medicene_dosage_data = "0 - "+master_tablet_data.afternoon+" - "+master_tablet_data.night;
                } else if(master_tablet_data.intake.intake =="7"){
                  medicene_dosage_data=master_tablet_data.intake.morning+" - "+master_tablet_data.intake.afternoon+" - "+master_tablet_data.intake.night;
                }else if(master_tablet_data.intake =="8"){
                  medicene_dosage_data=master_tablet_data.morning+" - "+master_tablet_data.afternoon+" - "+master_tablet_data.evening+" - "+master_tablet_data.night;
                } else if(master_tablet_data.intake =="9"){
                  medicene_dosage_data=master_tablet_data.morning+" - Every 6 hours";
                } else if(master_tablet_data.intake =="10"){
                  medicene_dosage_data=master_tablet_data.morning+" - Every 8 hours";
                } else if(master_tablet_data.intake =="11"){
                  medicene_dosage_data=master_tablet_data.morning+" - Every 12 hours";
                } else if(master_tablet_data.intake =="12"){
                  medicene_dosage_data=master_tablet_data.morning+" - Once a day";
                } else if(master_tablet_data.intake =="13"){
                  medicene_dosage_data=master_tablet_data.morning+" SOS - if required";
                } else if(master_tablet_data.intake =="14"){
                  medicene_dosage_data=master_tablet_data.morning+" - "+master_tablet_data.afternoon+" - "+master_tablet_data.evening+" - "+master_tablet_data.night +" Alternative days";
                } else if(master_tablet_data.intake =="15"){
                  medicene_dosage_data=master_tablet_data.morning+" - "+master_tablet_data.afternoon+" - "+master_tablet_data.evening+" - "+master_tablet_data.night +" Once a week";
                } else if(master_tablet_data.intake =="16"){
                  medicene_dosage_data=master_tablet_data.morning+" - "+master_tablet_data.afternoon+" - "+master_tablet_data.evening+" - "+master_tablet_data.night +" Once in two weeks";
                } else if(master_tablet_data.intake =="17"){
                  medicene_dosage_data=master_tablet_data.morning+" STAT";
                } 
                if(master_tablet_data.intake !="17"){
                  if(master_tablet_data.drug_intake != undefined && master_tablet_data.drug_intake != "" ) {
                    if (master_tablet_data.time_duration != null && master_tablet_data.time_duration != undefined && master_tablet_data.time_duration !="()") {
                      med_format = medicene_dosage_data + "  -  " + master_tablet_data.time_duration + " mins " + master_tablet_data.drug_intake;
                    } else {
                      med_format = medicene_dosage_data + "  -  " + master_tablet_data.drug_intake;
                    }
                  } else {
                    med_format = medicene_dosage_data;
                  }
                } else {
                  med_format = medicene_dosage_data;
                }
              } else {
                if(master_tablet_data.intake =="1"){
                  medicene_dosage_data=master_tablet_data.morning+"-0-0";
                } else if(master_tablet_data.intake =="2"){
                  medicene_dosage_data="0 - "+master_tablet_data.afternoon+" - 0";
                } else if(master_tablet_data.intake =="3"){
                  medicene_dosage_data="0 - 0 - "+master_tablet_data.night;
                } else if(master_tablet_data.intake =="4"){
                  medicene_dosage_data=master_tablet_data.morning+" - "+master_tablet_data.afternoon+" - 0";
                } else if(master_tablet_data.intake =="5"){
                  medicene_dosage_data=master_tablet_data.morning+" - 0 - "+master_tablet_data.night;
                } else if(master_tablet_data.intake =="6"){
                  medicene_dosage_data="0 - "+master_tablet_data.afternoon+" - "+master_tablet_data.night;
                } else if(master_tablet_data.intake =="7"){
                  medicene_dosage_data=master_tablet_data.morning+" - "+master_tablet_data.afternoon+" - "+master_tablet_data.night;
                } else if(master_tablet_data.intake =="8"){
                  medicene_dosage_data=master_tablet_data.morning+" - "+master_tablet_data.afternoon+" - "+master_tablet_data.evening+" - "+master_tablet_data.night;
                } else if(master_tablet_data.intake =="9"){
                  medicene_dosage_data=master_tablet_data.morning+" - Every 6 hours";
                } else if(master_tablet_data.intake =="10"){
                  medicene_dosage_data=master_tablet_data.morning+" - Every 8 hours";
                } else if(master_tablet_data.intake =="11"){
                  medicene_dosage_data=master_tablet_data.morning+" - Every 12 hours";
                } else if(master_tablet_data.intake =="12"){
                  medicene_dosage_data=master_tablet_data.morning+" - Once a day";
                } else if(master_tablet_data.intake =="13"){
                  medicene_dosage_data=master_tablet_data.morning+" SOS - if required";
                } else if(master_tablet_data.intake =="14"){
                  medicene_dosage_data=master_tablet_data.morning+" - "+master_tablet_data.afternoon+" - "+master_tablet_data.evening+" - "+master_tablet_data.night +" Alternative days";
                } else if(master_tablet_data.intake =="15"){
                  medicene_dosage_data=master_tablet_data.morning+" - "+master_tablet_data.afternoon+" - "+master_tablet_data.evening+" - "+master_tablet_data.night +" Once a week";
                } else if(master_tablet_data.intake =="16"){
                  medicene_dosage_data=master_tablet_data.morning+" - "+master_tablet_data.afternoon+" - "+master_tablet_data.evening+" - "+master_tablet_data.night +" Once in two weeks";
                } else if(master_tablet_data.intake =="17"){
                  medicene_dosage_data=master_tablet_data.morning+" STAT";
                }
                if(master_tablet_data.intake !="17"){
                  if(master_tablet_data.drug_intake != undefined && master_tablet_data.drug_intake != "" ) {
                    if (master_tablet_data.time_duration != null && master_tablet_data.time_duration != undefined && master_tablet_data.time_duration !="()") {
                      med_format = medicene_dosage_data + "  -  " + master_tablet_data.time_duration + " mins " + master_tablet_data.drug_intake;
                    } else {
                      med_format = medicene_dosage_data + "  -  " + master_tablet_data.drug_intake;
                    }
                  } else {
                    med_format = medicene_dosage_data;
                  }
                } else {
                  med_format = medicene_dosage_data;
                }
              }
              var master_timeDuration = "";
              if (master_tablet_data.drug_intake == "With food") {
                master_timeDuration = master_tablet_data.drug_intake;
              } else {
                if (master_tablet_data.time_duration != null && master_tablet_data.time_duration != "0") {
                  master_timeDuration = master_tablet_data.time_duration + ' mins ' + master_tablet_data.drug_intake;
                } else {
                  master_timeDuration = master_tablet_data.drug_intake;
                }
              }
              this.balanceQuantity = master_tablet_data.balance_quantity;
              if (Client_Helper.getmedialpres().flow == "order") {
                if (master_tablet_data.balance_quantity == 0){
                  this.toastr.error(Message_data.cannotOrderMoreThanPrescQty);
                }
                else if (master_tablet_data.balance_quantity != 0) {
                  this.tabletTracker.push({
                    drug_id: master_tablet_data.drug_id,
                    drug_type_name: master_tablet_data.short_name,
                    drug_name: master_tablet_data.drug_name,
                    medicene_name: master_tablet_data.short_name + ' ' + master_tablet_data.drug_name,
                    medicene_dosage: med_format,
                    medicene_intakedays: master_tablet_data.days + ' ' + master_tablet_data.day_dur,
                    medicene_food: master_timeDuration,
                    drug_qty: master_tablet_data.balance_quantity,
                    act_qty: master_tablet_data.balance_quantity
                  })
                  Helper_Class.settablettracker1(this.tabletTracker)
                }
              } else {
                var freq;
                if (master_tablet_data.intake == "9" || master_tablet_data.intake == "10"
                  || master_tablet_data.intake == "11" || master_tablet_data.intake == "12") {
                  if (master_tablet_data.time_duration != undefined) {
                    freq = master_tablet_data.time_duration + " mins " + master_tablet_data.drug_intake + " - " + master_tablet_data.intake_desc;
                  } else {
                    freq = master_tablet_data.drug_intake + " - " + master_tablet_data.intake_desc;
                  }
                } else {
                  if (master_tablet_data.time_duration != undefined) {
                    freq = master_tablet_data.time_duration + " mins " + master_tablet_data.drug_intake;
                  } else {
                    freq = master_tablet_data.drug_intake;
                  }
                }
                var noteval;
                if (master_tablet_data.notes != undefined && master_tablet_data.notes != null && master_tablet_data.notes != "") {
                  noteval = master_tablet_data.notes;
                } else {
                  noteval = "empty";
                }
                var drug_qun;
                if(master_tablet_data.balance_quantity != undefined && master_tablet_data.balance_quantity != null && master_tablet_data.balance_quantity != "" ){
                  drug_qun = master_tablet_data.balance_quantity;
                }else{
                  drug_qun = "1";
                }
                this.tabletTracker.push({
                  drug_id: master_tablet_data.drug_id,
                  drug_type_name: master_tablet_data.short_name,
                  drug_name: master_tablet_data.drug_name,
                  medicene_name: master_tablet_data.short_name + ' ' + master_tablet_data.drug_name,
                  medicene_dosage: med_format,
                  medicene_intakedays: master_tablet_data.days + ' ' + master_tablet_data.day_dur,
                  medicene_food: master_timeDuration,
                  drug_qty: master_tablet_data.balance_quantity,
                  act_qty: master_tablet_data.balance_quantity,
                  frequency: freq,    
                  note: noteval,
                })
                Helper_Class.settablettracker(this.tabletTracker);
              }
            }
            if (this.balanceQuantity == 0 && this.appNursePhysio == undefined) {
              this.pharmaFlag = false; 
              this.searchFlag = false;
            }
            if (this.PdfFlag == true && (Client_Helper.getmedialpres().flow != null && this.balanceQuantity == 0)) {
              if (Client_Helper.getmedialpres().flow == "order") {
                this.pharmaFlag = true;
                if (this.tabletTracker.length != 0) {
                  this.searchFlag = true;
                } else {
                  this.searchFlag = false;
                }
              }
            } else if (this.PdfFlag == false && Client_Helper.getmedialpres().flow != null) {
              this.searchFlag = true;
              this.pharmaFlag = false;
            }
          }
        },error => {});
  }

  order() {
    Client_Helper.setstorepickup(null);
    if (this.tabletTracker.length == 0 && this.PdfFlag == true) {
      const dialogRef = this.dialog.open(PharmacyprescriptiondetailsComponent, {
        width: '700px',
        height: '300px'
      });
      dialogRef.afterClosed().subscribe(data => {
        if (data != undefined) {
          if (data == "0") {
            this.clientservice.sendMessage('DoctorDetailPage');
            var data = null;
            data = {
              App_Flow: "Prescription_App",
              flow: "order",
              Prescrip_Doc_Location: this.doctorLocation,
              doc_reg_id: this.docRegId,
              pres_drug_id: this.prescriptionPresId,
              Prescrip_Subrel_Id: this.subRelId,
            };
          } else {
            this.clientservice.sendMessage('clientpharmacycurrent')
          }
        }
      });
    } else {
      var pharmacy = null;
      pharmacy = {
        Pres_drug_id: this.prescriptionPresId,
        rel_id: this.relId,
        rel_name: this.relName,
        sub_rel_id: this.subRelId,
        tablet_datas: this.tabletTracker,
        pharmacy_name: this.pharmacyName,
        phar_addr1: this.pharmacyAddr1,
        phar_addr2: this.pharmacyAddr2,
        phar_loc: this.pharmacyLocation,
        phar_city: this.pharmacyCity,
        phar_zipcode: this.pharmacyZipcode,
        phar_state: this.pharmacyState,
        phar_cnty: this.pharmacyCountry,
        telephone: this.pharmacyTelephone,
        pharma_id: this.pharmaId,
        del_type: "None",
        validity: this.validityDays,
        work_time: this.workTime,
        flow: "order", 
        pharmacyflow:" ",
      }
      Client_Helper.setstorepickup(pharmacy);
      this.clientservice.sendMessage('quantitychange');
    }
  }

  search() {
    var inv_qty: boolean = false;
    for (var y = 0; y < this.tabletTracker.length; y++) {
      if (this.tabletTracker[y].drug_qty == "0" || this.tabletTracker[y].drug_qty == "") {
        inv_qty = true;
      }
    }
    if (inv_qty == true) {
      this.toastr.error(Message_data.noMedToOrder);
    } else {
      Client_Helper.setPharmacyList(null);
      var pharmacy1 = {
        prescription_pres_id: this.prescriptionPresId,
        rel_id: this.relId,
        rel_name: this.relName,
        sub_rel_id: this.subRelId,
        tablet_tracker: this.tabletTracker,
        flow: Client_Helper.getmedialpres().flow
      }
      Client_Helper.setPharmacyList(pharmacy1);
    }
    this.clientservice.sendMessage('pharmacyorderlist');
  }

  bookAppointment() {
    if (Client_Helper.getHomecare_nurse_physio() == "nurse" || Client_Helper.getHomecare_nurse_physio() == "physio") {
      Client_Helper.setNurse_Prescription_list(this.prescriptionPresId);
      this.clientservice.sendMessage("physio_doctor_list");
    }
  }

  deleteTabletTracker(index) {
    this.tabletTracker.splice(index, 1);
  }
  
  myCallbackFunction = function (_params) {
    return new Promise((resolve, reject) => {
      resolve(_params);
    });
  }
}
