<div mat-dialog-title class="popup_border_icd">
  <h5 class="header_txt">Additional Vaccine</h5>
  <div mat-dialog-actions>
    <img src="../../../assets/img/close_icon.png" (click)="ClosePopup()" width="20px" height="20px" id="close_icd" />
  </div>
</div>
<div mat-dialog-content class="ped_content">
  <div class="container">
    <div class="row">
      <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 ">
        <mat-label class="matlabel">Age group<br>
          <select disableOptionCentering class="ipcss" [(ngModel)]="Age_group">
            <option disabled>Select</option>
            <option *ngFor="let vaccine of Age_grouparray" value="{{vaccine.description}}">{{vaccine.description}}
            </option>
          </select>
        </mat-label>
      </div>
      <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 ">
        <mat-label class="matlabel">Vaccine name
          <input type="text" class="ipcss" matInput required [(ngModel)]="Vaccine_name" />
        </mat-label>
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions align="end" style="width: auto;right: 19px;">
    <button (click)="ClosePopup()" id="add_btn" [mat-dialog-close]="" cdkFocusInitial mat-raised-button>
      <img src="../../../assets/ui_icons/buttons/cancel_button.svg" class="addimgbtn_icd" />
    </button>
    <button  (click)="Vaccine_save()" id="add_btn" [mat-dialog-close]="" cdkFocusInitial mat-raised-button>
      <img src="../../../assets/ui_icons/buttons/save_button.svg" class="addimgbtn_icd" />
    </button>
</div>