<div class="row" style="width:100%;margin:0 auto;">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white ">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Reports</h5>
                    </div>
                    <div class="headerButtons">

                    </div>
                </div>

            </mdb-card-header>
            <mdb-card-body>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                        <mat-label class="matlabel">Filter-by
                            <mat-select class="ipcss" [(ngModel)]="filterByText" required
                                disableOptionCentering>
                                <mat-option value="Patient">Patient</mat-option>
                                <mat-option value="Speciality">Speciality</mat-option>
                                <mat-option value="TestWise">Test Wise</mat-option>
                            </mat-select>
                        </mat-label>
                    </div>
                    <div [hidden]="fromDateFlag" class="col-12 col-sm-6 col-md-3 col-lg-2 col-xl-2">
                        <mat-label class="matlabel">From
                            <input type="date" [(ngModel)]="fromDate" class="ipcss"
                                (change)="clickDate('fromDate',fromDate)" matInput />
                        </mat-label>
                    </div>
                    <div [hidden]="toDateFlag" class="col-12 col-sm-6 col-md-3 col-lg-2 col-xl-2">
                        <mat-label class="matlabel">To
                            <input type="date" [(ngModel)]="toDate" class="ipcss" (change)="clickDate('toDate',toDate)"
                                matInput />
                        </mat-label>
                    </div>
                    <div [hidden]="patientFlag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                        <mat-label class="matlabel">First Name
                            <input type="text" class="ipcss" [(ngModel)]="clientFirstName" (blur)="toUpperCaseFirstName()"
                                matInput />
                        </mat-label>
                    </div>
                    <div [hidden]="patientFlag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                        <mat-label class="matlabel">Last Name
                            <input type="text" class="ipcss" [(ngModel)]="clientLastName" (blur)="toUpperCaseLastName()"
                                matInput />
                        </mat-label>
                    </div>
                    <div [hidden]="specialityFlag" class="col-12 col-sm-6 col-md-3 col-lg-2 col-xl-2">
                        <mat-label class="matlabel">Speciality
                            <select class="ipcss " [(ngModel)]="speclization">
                                <option Selected>Select</option>
                                <option *ngFor="let spl of splArray" value="{{spl.spl_id}}">{{spl.spl_name}}
                                </option>
                            </select>
                        </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                        <mat-label class="matlabel">
                            <img src="../../../assets/ui_icons/buttons/search_button.svg" class="saveimgbtn_inpatinfo"
                                style="margin-top: 20px;" (click)="searchBy()" />
                        </mat-label>
                    </div>
                    <div class="col-12" style="margin-top: 10px;" *ngIf="specialityListArray.length != 0">
                        <div class="nodata" *ngIf="specialityListArray.length == 0">No Data Found</div>
                        <div class="dig_table_overflow">
                            <div class="table-responsive" *ngIf="specialityListArray.length != 0">
                                <table class="table table-hover table-dynamic" id="card_tbl">
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th [hidden]="!patientFlag">Patient Name</th>
                                            <th [hidden]="patientFlag">Referred Doctor</th>
                                            <th>Radiology</th>
                                            <th>Pathology</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let reportList of specialityListArray">
                                            <td style="width: 100px;">{{reportList.date}}</td>
                                            <td [hidden]="!patientFlag" style="text-align: left;width: fit-content;">
                                                {{reportList.patient_name}}</td>
                                            <td [hidden]="patientFlag" style="text-align: left;">
                                                {{reportList.referred_by}}</td>
                                            <td style="text-align: left;width: fit-content;">{{reportList.radialogy}}
                                            </td>
                                            <td style="text-align: left;width: fit-content;">{{reportList.pathology}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="col-12" style="margin-top: 10px;" [hidden]="srchSpecialityFlag">
                        <p class="nodata" [hidden]='specialityListArray.length!=0'>No Data Found</p>
                    </div>
                    <div class="col-12" style="margin-top: 10px;" *ngIf="testwiseDataList.length">
                        <p class="tervys_heading col-12">Tests</p>
                        <p class="nodata" [hidden]='testwiseDataList.length!=0'>No Test(s) Found</p>
                        <table *ngIf="testwiseDataList.length" mdbTable datatable [dtOptions]="dtOptions"
                            class="table table-nowrap table-sm dataTable">
                            <thead>
                                <tr>
                                    <th style="width: 500px;">Test Name</th>
                                    <th>Count</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let timewise of testwiseDataList">
                                    <td style="text-align: left;width: 500px;">{{timewise.test_name}}</td>
                                    <td>{{timewise.test_count}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-12" style="margin-top: 10px;" [hidden]='srcTestFlag'>
                        <p class="tervys_heading col-12">Tests</p>
                        <p class="nodata" [hidden]='testwiseDataList.length!=0'>No Test(s) Found</p>
                    </div>
                </div>

            </mdb-card-body>
        </mdb-card>
    </div>
</div>