import { Component, OnInit } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';

@Component({
  selector: 'app-forgotpwd',
  templateUrl: './forgotpwd.component.html',
  styleUrls: ['./forgotpwd.component.css']
})
export class ForgotpwdComponent implements OnInit {
  public ipaddress: string;
  userinfo:any;
  username:string;
  datainfo=[];
  public usertype;
  public optiontype;
  public sec_code;

  constructor(private router:Router, private route:ActivatedRoute,private http:Http,public toastr:ToastrService) {
    this.getIP();
  }

  forgotpwd(){
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'usercontroller/forgotpass',
      JSON.stringify({
        email: this.username,
        security_code: this.sec_code,
        type: this.optiontype,
        flag: this.usertype
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.key != null && obj.key == "1") {
            if(this.usertype =="email"){
              this.toastr.error(Message_data.chkUrMailBox);
              this.router.navigateByUrl('/loginpage');
            } else{
              this.toastr.error(Message_data.sentSMStoRegtrMobile);
               this.router.navigateByUrl('/loginpage');
            }
          } else {
            this.toastr.error(Message_data.chkUserId);
          }
        },error => {
          this.toastr.error(Message_data.network);       
        });
  }

  getIP() {
    var headers = new Headers();
    this.http.get(ipaddress.getIp.toString() + 'gen/ip',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.ipaddress = obj.ipaddress;
        });
  }

  ngOnInit(): void {
  }
}
