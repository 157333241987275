import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule} from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import {MatDialogModule} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CancelAppointmentComponent } from './cancel-appointment.component';

@NgModule({
    imports:[FormsModule,MatDatepickerModule,MatDialogModule,CommonModule,MatInputModule ,MatSelectModule,MatFormFieldModule,MatIconModule,MatButtonModule,FormsModule],
    declarations:[CancelAppointmentComponent],
    exports:[CancelAppointmentComponent]
})
export class CancelAppointmentModule{   
   
}