<div *ngIf="clinicalFlag == 'min'">
    <div class="block1_grid">
        <mat-label class="matlabel column">{{stress_label}}<br>
            <select required [(ngModel)]="stress" [disabled]="stress_flag" class="ipcss  thappt"
                disableOptionCentering>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
            </select>
        </mat-label>
        <mat-label class="matlabel column">{{stress_factor_label}}<br>
            <textarea class="ipcss  thappt " #stress1 id="stress1" maxlength="250" rows="1" required
                [(ngModel)]="stress_factor" [disabled]="stress_flag"></textarea>
        </mat-label>
    </div>
</div>

<div *ngIf="clinicalFlag == 'max'">
    <div class="container ">
        <div class="row">
            <div class="col-12" [hidden]="stress_flag">
                <div class="cover_div">
                    <div class="header_lable">
                        Stress
                    </div>
                    <div class="content_cover">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel">{{stress_label}}<br>
                                    <select required [(ngModel)]="stress" [disabled]="client"
                                        (ngModelChange)="stress_change($event)" 
                                        class="ipcss  " disableOptionCentering>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-12 col-md-4 col-lg-5 col-xl-5">
                                <mat-label class="matlabel">{{stress_factor_label}}<br>
                                    <textarea class="ipcss" #stress1 id="stress1" maxlength="250" rows="1"
                                        required [(ngModel)]="stress_factor"
                                        [disabled]="stress_fac_flag || client"></textarea>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <mat-label class="matlabel">{{symptom_label}}<br>
                                    <textarea class="ipcss" #symptom1 id="symptom1" maxlength="250" rows="1"
                                        required [(ngModel)]="symptom"
                                        [disabled]="stress_fac_flag || client"></textarea>
                                </mat-label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="col-6">
                <div class="cover_div">
                    <div class="header_lable">
                        Concerns
                    </div>
                    <div class="content_cover">
                        <div class="row">
                            <div class=" col-12" [hidden]="main_flag">
                                <mat-label class="matlabel">{{mainconcen_label}}<br>
                                    <textarea class="ipcss" maxlength="250" rows="1"
                                        [(ngModel)]="main_concern" [disabled]="client"></textarea>
                                </mat-label>
                            </div>

                            <div class=" col-12" [hidden]="prev_hist_flag">
                                <mat-label class="matlabel">{{prev_hist_label}}<br>
                                    <textarea class="ipcss" maxlength="250" rows="1"
                                        [(ngModel)]="prev_hist" [disabled]="client"></textarea>
                                </mat-label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-6" [hidden]="take_med_flag">
                <div class="cover_div">
                    <div class="header_lable">
                        Medication
                    </div>
                    <div class="content_cover" >
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <mat-label class="matlabel">{{take_med_label}}<br>
                                    <select class="ipcss  " required [(ngModel)]="take_med"
                                        [disabled]="take_med_flag && client" (change)="medication_change()">
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <mat-label class="matlabel">{{take_med_det_label}}<br>
                                    <textarea class="ipcss" #meddet id="takemed1" maxlength="250" rows="1"
                                        required [(ngModel)]="take_med_det"
                                        [disabled]="take_med_det_flag"></textarea>
                                </mat-label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12">
                <div class="cover_div">
                    <div class="header_lable">
                        Nervous system
                    </div>
                    <div class="content_cover">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3" [hidden]="headache_flag">
                                <mat-label class="matlabel">{{headache_label}}<br>
                                    <select class="ipcss" [(ngModel)]="headache" [disabled]="client"
                                        disableOptionCentering>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </mat-label>
                            </div>
                            
                            <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3" [hidden]="giddiness_flag">
                                <mat-label class="matlabel">{{giddiness_label}}<br>
                                    <select class="ipcss" [(ngModel)]="giddiness" [disabled]="client"
                                        disableOptionCentering>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </mat-label>
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3" [hidden]="convulsions_flag">
                                <mat-label class="matlabel">{{convulsions_label}}<br>
                                    <select class="ipcss" [(ngModel)]="convulsions" [disabled]="client"
                                        disableOptionCentering>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </mat-label>
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3" [hidden]="tremor_flag">
                                <mat-label class="matlabel">{{tremor_label}}<br>
                                    <select class="ipcss" [(ngModel)]="tremor" [disabled]="client"
                                        disableOptionCentering>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <mat-label class="matlabel">Other nervous system issues<br>
                                    <textarea class="ipcss"  maxlength="250" rows="1"
                                        [(ngModel)]="nerv_other"></textarea>
                                </mat-label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12">
                <div class="cover_div">
                    <div class="header_lable">
                        ENT & Eye
                    </div>
                    <div class="content_cover">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3" [hidden]="ear_flag">
                                <mat-label class="matlabel">{{ear_label}}<br>
                                    <select class="ipcss" [(ngModel)]="ear" [disabled]="client"
                                        disableOptionCentering>
                                        <option value="normal">Normal</option>
                                        <option value="abnormal">Abnormal</option>
                                    </select>
                                </mat-label>
                            </div>
                            
                            <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3" [hidden]="hoarseness_flag">
                                <mat-label class="matlabel">{{hoarseness_label}}<br>
                                    <select class="ipcss" [(ngModel)]="hoarseness" [disabled]="client"
                                        disableOptionCentering>
                                        <option *ngFor="let hoarse of hoarsearray" value="{{hoarse.hoarse_id}}">
                                            {{hoarse.hoarse_desc}}</option>
                                    </select>
                                </mat-label>
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3" [hidden]="epistaxis_flag">
                                <mat-label class="matlabel">{{epistaxis_label}}<br>
                                    <select class="ipcss" [(ngModel)]="epistaxis" [disabled]="client"
                                        disableOptionCentering>
                                        <option *ngFor="let  epistax of epistaxisarray" value="{{ epistax.epistax_id}}">
                                            {{ epistax.epistax_desc}}</option>
                                    </select>
                                </mat-label>
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3" [hidden]="dysphagia_flag">
                                <mat-label class="matlabel">{{dysphagia_label}}<br>
                                    <select class="ipcss" [(ngModel)]="dysphagia" [disabled]="client"
                                        disableOptionCentering>
                                        <option *ngFor="let  dysphag of dysphagiaarray" value="{{ dysphag.dysphag_id}}">
                                            {{ dysphag.dysphag_desc}}</option>
                                    </select>
                                </mat-label>
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3" [hidden]="cold_flag">
                                <mat-label class="matlabel">{{cold_label}}<br>
                                    <select class="ipcss" [(ngModel)]="cold" [disabled]="client"
                                        disableOptionCentering>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </mat-label>
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3" [hidden]="eye_flag">
                                <mat-label class="matlabel">{{eye_label}}<br>
                                    <select class="ipcss" [(ngModel)]="eye" [disabled]="client"
                                        disableOptionCentering>
                                        <option *ngFor="let  eye of eyearray" value="{{ eye.eye_id}}">
                                            {{ eye.eye_desc}}</option>
                                    </select>
                                </mat-label>
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3" [hidden]="nose_flag">
                                <mat-label class="matlabel">{{nose_label}}<br>
                                    <select class="ipcss" [(ngModel)]="nose" [disabled]="client"
                                        disableOptionCentering>
                                        <option value="normal">Normal</option>
                                        <option value="abnormal">Abnormal</option>
                                    </select>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <mat-label class="matlabel">Other ENT issues<br>
                                    <textarea class="ipcss"  maxlength="250" rows="1"
                                        [(ngModel)]="ent_other"></textarea>
                                </mat-label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12">
                <div class="cover_div">
                    <div class="header_lable">
                        Cardio
                    </div>
                    <div class="content_cover">
                        <div class="row">
                            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3" [hidden]="chest_pain_flag">
                                <mat-label class="matlabel">{{chest_pain_label}}<br>
                                    <select class="ipcss" [(ngModel)]="chest_pain"
                                        [disabled]="client" (change)="pain_change()"
                                        disableOptionCentering>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3" [hidden]="chest_pain_loc_flag">
                                <mat-label class="matlabel">{{chest_pain_loc_label}}<br>
                                    <select class="ipcss  " [required]="loc_required"
                                        disableOptionCentering [(ngModel)]="chest_pain_loc"
                                        [disabled]="pain_flag || client">
                                        <option Selected
                                            *ngFor="let painloc of cpainloc_array"
                                            value="{{painloc.location_desc}}">
                                            {{painloc.location_desc}}</option>
                                    </select>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3" [hidden]="chest_pain_char_flag">
                                <mat-label class="matlabel">{{chest_pain_char_label}}<br>
                                    <select class="ipcss  " [required]="char_required"
                                        disableOptionCentering [(ngModel)]="chest_pain_char"
                                        [disabled]="pain_flag || client">
                                        <option *ngFor="let paincar of cpaincar_array"
                                            value="{{paincar.character_desc}}">
                                            {{paincar.character_desc}}
                                        </option>
                                    </select>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3" [hidden]="chest_pain_rad_flag">
                                <mat-label class="matlabel">{{chest_pain_rad_label}}<br>
                                    <select class="ipcss  " [required]="rad_required"
                                        disableOptionCentering [(ngModel)]="chest_pain_rad"
                                        [disabled]="pain_flag || client">
                                        <option *ngFor="let painrad of cpainrad_array"
                                            value="{{painrad.radiation_desc}}">
                                            {{painrad.radiation_desc}}
                                        </option>
                                    </select>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3" [hidden]="chest_pain_dur_flag">
                                <mat-label class="matlabel">{{chest_pain_dur_label}}<br>
                                    <select class="ipcss  " [required]="dur_required"
                                        disableOptionCentering [(ngModel)]="chest_pain_dur"
                                        [disabled]="pain_flag || client">
                                        <option value="&#60;30 mins">&#60;30 mins</option>
                                        <option value="&#60;60 mins">&#60;60 mins</option>
                                        <option vvalue="&#62;60 mins">&#62;60 mins</option>
                                    </select>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3" [hidden]="chest_pain_aggr_flag">
                                <mat-label class="matlabel">{{chest_pain_aggr_label}}<br>
                                    <select class="ipcss" [required]="aggravat_required"
                                        disableOptionCentering [(ngModel)]="chest_pain_aggr"
                                        [disabled]="pain_flag || client">
                                        <option *ngFor="let painagrrel of cpainagrel_array"
                                            value="{{painagrrel.agrrelfact_desc}}">
                                            {{painagrrel.agrrelfact_desc}}
                                        </option>
                                    </select>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3" [hidden]="chest_pain_reliev_flag">
                                <mat-label class="matlabel">{{chest_pain_reliev_label}}<br>
                                    <select class="ipcss" [required]="relieve_required"
                                        disableOptionCentering [(ngModel)]="chest_pain_reliev"
                                        [disabled]="pain_flag || client">
                                        <option *ngFor="let painagrrel of cpainagrel_array"
                                            value="{{painagrrel.agrrelfact_desc}}">
                                            {{painagrrel.agrrelfact_desc}}
                                        </option>
                                    </select>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3" [hidden]="chest_pain_asymp_flag">
                                <mat-label class="matlabel">{{chest_pain_asymp_label}}<br>
                                    <select class="ipcss  " [required]="assosymp_required"
                                        disableOptionCentering [(ngModel)]="chest_pain_asymp"
                                        [disabled]="pain_flag || client">
                                        <option *ngFor="let painasympt of cpainasympt_array"
                                            value="{{painasympt.asssympt_desc}}">
                                            {{painasympt.asssympt_desc}}
                                        </option>
                                    </select>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3" [hidden]="chest_pain_intense_flag">
                                <mat-label class="matlabel">{{chest_pain_intense_label}}<br>
                                    <select class="ipcss  " [required]="intensity_required"
                                        disableOptionCentering [(ngModel)]="chest_pain_intense"
                                        [disabled]="pain_flag || client">
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                    </select>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3" [hidden]="chest_pain_cause_flag">
                                <mat-label class="matlabel">{{chest_pain_cause_label}}<br>
                                    <select class="ipcss  " [required]="paincause_required"
                                        disableOptionCentering [(ngModel)]="chest_pain_cause"
                                        [disabled]="pain_flag || client">
                                        <option *ngFor="let painc of cpaincause_array"
                                            value="{{painc.cause_desc}}">
                                            {{painc.cause_desc}}</option>
                                    </select>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <mat-label class="matlabel">Other cardio issues<br>
                                    <textarea class="ipcss"  maxlength="250" rows="1"
                                        [(ngModel)]="cardio_other"></textarea>
                                </mat-label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="col-12">
                <div class="cover_div">
                    <div class="header_lable">
                        Respiratory
                    </div>
                    <div class="content_cover">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3" [hidden]="cough_flag">
                                <mat-label class="matlabel">{{cough_label}}<br>
                                    <select class="ipcss" [(ngModel)]="cough" [disabled]="client"
                                        disableOptionCentering>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </mat-label>
                            </div>
                            
                            <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3" [hidden]="expectoration_flag">
                                <mat-label class="matlabel">{{expectoration_label}}<br>
                                    <select class="ipcss" [(ngModel)]="expectoration" [disabled]="client"
                                        disableOptionCentering>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </mat-label>
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3" [hidden]="haemoptysis_flag">
                                <mat-label class="matlabel">{{haemoptysis_label}}<br>
                                    <select class="ipcss" [(ngModel)]="haemoptysis" [disabled]="client"
                                        disableOptionCentering>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </mat-label>
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3" [hidden]="weezing_flag">
                                <mat-label class="matlabel">{{weezing_label}}<br>
                                    <select class="ipcss" [(ngModel)]="weezing" [disabled]="client"
                                        disableOptionCentering>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </mat-label>
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3" [hidden]="palpitation_flag">
                                <mat-label class="matlabel">{{palpitation_label}}<br>
                                    <select class="ipcss" [(ngModel)]="palpitation" [disabled]="client"
                                        disableOptionCentering>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </mat-label>
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3" [hidden]="dyspnoea_flag">
                                <mat-label class="matlabel">{{dyspnoea_label}}<br>
                                    <select class="ipcss" [(ngModel)]="dyspnoea" [disabled]="client"
                                        disableOptionCentering>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </mat-label>
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3" [hidden]="orthohnoea_flag">
                                <mat-label class="matlabel">{{orthohnoea_label}}<br>
                                    <select class="ipcss" [(ngModel)]="orthohnoea" [disabled]="client"
                                        disableOptionCentering>
                                        <option value="normal">Normal</option>
                                        <option value="abnormal">Abnormal</option>
                                    </select>
                                </mat-label>
                            </div>

                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <mat-label class="matlabel">Other respiratory issues<br>
                                    <textarea class="ipcss"  maxlength="250" rows="1"
                                        [(ngModel)]="resp_other"></textarea>
                                </mat-label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12">
                <div class="cover_div">
                    <div class="header_lable">
                        Gastro intestinal apetite pain
                    </div>
                    <div class="content_cover">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3" [hidden]="pain_flag">
                                <mat-label class="matlabel">{{pain_label}}<br>
                                    <select class="ipcss" [(ngModel)]="pain" [disabled]="client"
                                        disableOptionCentering>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </mat-label>
                            </div>
                            
                            <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3" [hidden]="nature_flag">
                                <mat-label class="matlabel">{{nature_label}}<br>
                                    <select class="ipcss" [(ngModel)]="nature" [disabled]="client"
                                        disableOptionCentering>
                                        <option *ngFor="let painnature of gaspainnaturearray"
                                            value="{{painnature.pnature_id}}">
                                            {{painnature.pnature_desc}}</option>
                                    </select>
                                </mat-label>
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3" [hidden]="situation_flag">
                                <mat-label class="matlabel">{{situation_label}}<br>
                                    <select class="ipcss" [(ngModel)]="situation" [disabled]="client"
                                        disableOptionCentering>
                                        <option *ngFor="let painloc of gaspainlocarray"
                                            value="{{painloc.loc_id}}">
                                            {{painloc.loc_desc}}</option>
                                    </select>
                                </mat-label>
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3" [hidden]="radiation_flag">
                                <mat-label class="matlabel">{{radiation_label}}<br>
                                    <select class="ipcss" [(ngModel)]="radiation" [disabled]="client"
                                        disableOptionCentering>
                                        <option *ngFor="let painrad of gaspainradarray"
                                            value="{{painrad.prad_id}}">
                                            {{painrad.prad_desc}}</option>
                                    </select>
                                </mat-label>
                            </div>

                            <!-- <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3" [hidden]="relation_flag">
                                <mat-label class="matlabel">{{relation_label}}<br>
                                    <select class="ipcss" [(ngModel)]="relation" [disabled]="client"
                                        disableOptionCentering>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </mat-label>
                            </div> -->

                            <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3" [hidden]="nausea_flag">
                                <mat-label class="matlabel">{{nausea_label}}<br>
                                    <select class="ipcss" [(ngModel)]="nausea" [disabled]="client"
                                        disableOptionCentering>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </mat-label>
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3" [hidden]="vomiting_flag">
                                <mat-label class="matlabel">{{vomiting_label}}<br>
                                    <select class="ipcss" [(ngModel)]="vomiting" [disabled]="client"
                                        disableOptionCentering>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </mat-label>
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3" [hidden]="haematemesis_flag">
                                <mat-label class="matlabel">{{haematemesis_label}}<br>
                                    <select class="ipcss" [(ngModel)]="haematemesis" [disabled]="client"
                                        disableOptionCentering>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </mat-label>
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3" [hidden]="belching_flag">
                                <mat-label class="matlabel">{{belching_label}}<br>
                                    <select class="ipcss" [(ngModel)]="belching" [disabled]="client"
                                        disableOptionCentering>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </mat-label>
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3" [hidden]="bowel_abrnorm_flag">
                                <mat-label class="matlabel">{{bowel_abrnorm_label}}<br>
                                    <select class="ipcss" [(ngModel)]="bowel_abrnorm" [disabled]="client"
                                        disableOptionCentering>
                                        <option value="normal">Normal</option>
                                        <option value="Inflammatory bowel disease">Inflammatory bowel disease</option>
                                        <option value="Irritable bowel syndrome">Irritable bowel syndrome</option>
                                        <option value="Diverticulitis">Diverticulitis</option>
                                        <option value="Celiac disease">Celiac disease</option>
                                        <option value="Others">Others</option>
                                    </select>
                                </mat-label>
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3" [hidden]="blood_stool_flag">
                                <mat-label class="matlabel">{{blood_stool_label}}<br>
                                    <select class="ipcss" [(ngModel)]="blood_stool" [disabled]="client"
                                        disableOptionCentering>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </mat-label>
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3" [hidden]="haemorrhoids_flag">
                                <mat-label class="matlabel">{{haemorrhoids_label}}<br>
                                    <select class="ipcss" [(ngModel)]="haemorrhoids" [disabled]="client"
                                        disableOptionCentering>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <mat-label class="matlabel">Other GA pain issues<br>
                                    <textarea class="ipcss"  maxlength="250" rows="1"
                                        [(ngModel)]="giap_other"></textarea>
                                </mat-label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="col-12">
                <div class="cover_div">
                    <div class="header_lable">
                        Genito urinary
                    </div>
                    <div class="content_cover">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3" [hidden]="frequency_flag">
                                <mat-label class="matlabel">{{frequency_label}}<br>
                                    <select class="ipcss" [(ngModel)]="frequency" [disabled]="client"
                                        disableOptionCentering>
                                        <option value="normal">Normal</option>
                                        <option value="abnormal">Frequent</option>
                                        <option value="abnormal">Very frequent</option>
                                    </select>
                                </mat-label>
                            </div>
                            
                            <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3" [hidden]="nocturia_flag">
                                <mat-label class="matlabel">{{nocturia_label}}<br>
                                    <select class="ipcss" [(ngModel)]="nocturia" [disabled]="client"
                                        disableOptionCentering>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </mat-label>
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3" [hidden]="dysuria_flag">
                                <mat-label class="matlabel">{{dysuria_label}}<br>
                                    <select class="ipcss" [(ngModel)]="dysuria" [disabled]="client"
                                        disableOptionCentering>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </mat-label>
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3" [hidden]="haematuria_flag">
                                <mat-label class="matlabel">{{haematuria_label}}<br>
                                    <select class="ipcss" [(ngModel)]="haematuria" [disabled]="client"
                                        disableOptionCentering>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </mat-label>
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3" [hidden]="discharge_flag">
                                <mat-label class="matlabel">{{discharge_label}}<br>
                                    <select class="ipcss" [(ngModel)]="discharge" [disabled]="client"
                                        disableOptionCentering>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <mat-label class="matlabel">Other genito urinary issues<br>
                                    <textarea class="ipcss"  maxlength="250" rows="1"
                                        [(ngModel)]="genito_other"></textarea>
                                </mat-label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12">
                <div class="cover_div">
                    <div class="header_lable">
                        General
                    </div>
                    <div class="content_cover">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3" [hidden]="gen_fatigue_flag">
                                <mat-label class="matlabel">{{gen_fatigue_label}}<br>
                                    <select class="ipcss" [(ngModel)]="gen_fatigue" [disabled]="client"
                                        disableOptionCentering>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </mat-label>
                            </div>
                            
                            <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3" [hidden]="weigh_change_flag">
                                <mat-label class="matlabel">{{weigh_change_label}}<br>
                                    <select class="ipcss" [(ngModel)]="weigh_change" [disabled]="client"
                                        disableOptionCentering>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </mat-label>
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3" [hidden]="fever_flag">
                                <mat-label class="matlabel">{{fever_label}}<br>
                                    <select class="ipcss" [(ngModel)]="fever" [disabled]="client"
                                        disableOptionCentering>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </mat-label>
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3" [hidden]="night_sweats_flag">
                                <mat-label class="matlabel">{{night_sweats_label}}<br>
                                    <select class="ipcss" [(ngModel)]="night_sweats" [disabled]="client"
                                        disableOptionCentering>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3" [hidden]="jaundice_flag">
                                <mat-label class="matlabel">{{jaundice_label}}<br>
                                    <select class="ipcss" [(ngModel)]="jaundice" [disabled]="client"
                                        disableOptionCentering>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3" [hidden]="hypertension_flag">
                                <mat-label class="matlabel">{{hypertension_label}}<br>
                                    <select class="ipcss" [(ngModel)]="hypertension" [disabled]="client"
                                        disableOptionCentering>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3" [hidden]="diabetics_flag">
                                <mat-label class="matlabel">{{diabetics_label}}<br>
                                    <select class="ipcss" [(ngModel)]="diabetics" [disabled]="client"
                                        disableOptionCentering>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3" [hidden]="surgery_flag">
                                <mat-label class="matlabel">{{surgery_label}}<br>
                                    <select class="ipcss" [(ngModel)]="surgery" [disabled]="client"
                                        disableOptionCentering (change)="surgery_change()">
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-12 col-md-4 col-lg-5 col-xl-5" [hidden]="surgery_flag">
                                <mat-label class="matlabel">Surger details<br>
                                    <textarea class="ipcss " maxlength="250" rows="1"
                                        [required]="surgeryreq_flag" [(ngModel)]="surger_details"
                                        [disabled]="surgerydet_flag || client"></textarea>
                                </mat-label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12" [hidden]="show_mens_flag">
                <div class="cover_div">
                    <div class="header_lable">
                        Menstrual
                    </div>
                    <div class="content_cover">
                        <div class="row">
                            <div [hidden]="mens_period_flag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel">Menstrual periods<br>
                                    <select class="ipcss" [(ngModel)]="mens_period"
                                        [disabled]="client" disableOptionCentering ngDefaultControl>
                                        <option value="Regular">Regular</option>
                                        <option value="Irregular">Irregular</option>
                                    </select>
                                </mat-label>
                            </div>
                            <div [hidden]="last_mens_period_flag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel">Last menstrual period<br>
                                    <input type="date" class="ipcss_date" id="appt_date" [disabled]="client"
                                        (change)="Menstrual_OnChange(last_mens_period)" [(ngModel)]="last_mens_period"
                                        max="{{currentDate}}" ngDefaultControl matInput />
                                </mat-label>
                            </div>
                            <div [hidden]="cycle_flag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel">Cycle length<br>
                                    <select class="ipcss" [(ngModel)]="cycle" [disabled]="cycle_disabled || client" (change)="send_data()"
                                        disableOptionCentering ngDefaultControl>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                        <option value="13">13</option>
                                        <option value="14">14</option>
                                        <option value="15">15</option>
                                    </select>
                                </mat-label>
                            </div>
                            <div [hidden]="frequency_flag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                <mat-label class="matlabel">Frequency<br>
                                    <select class="ipcss" #gynafreq [(ngModel)]="mens_frequency"
                                        [disabled]="cycle_freqdisabled || client" disableOptionCentering>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                        <option value="13">13</option>
                                        <option value="14">14</option>
                                        <option value="15">15</option>
                                        <option value="16">16</option>
                                        <option value="17">17</option>
                                        <option value="18">18</option>
                                        <option value="19">19</option>
                                        <option value="20">20</option>
                                        <option value="21">21</option>
                                        <option value="21">21</option>
                                        <option value="22">22</option>
                                        <option value="23">23</option>
                                        <option value="24">24</option>
                                        <option value="25">25</option>
                                        <option value="26">26</option>
                                        <option value="27">27</option>
                                        <option value="28">28</option>
                                        <option value="29">29</option>
                                        <option value="30">30</option>
                                        <option value="31">31</option>
                                        <option value="32">32</option>
                                        <option value="33">33</option>
                                        <option value="34">34</option>
                                        <option value="35">35</option>
                                        <option value="36">36</option>
                                        <option value="37">37</option>
                                        <option value="38">38</option>
                                        <option value="39">39</option>
                                        <option value="40">40</option>
                                    </select>
                                </mat-label>
                            </div>
                            <div [hidden]="mens_pain_flag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel">Menstrual pain<br>
                                    <select class="ipcss" [(ngModel)]="mens_pain" 
                                        [disabled]="menstrual_paindisbaled || client"
                                        (change)="menstrualpain_change(mens_pain)" disableOptionCentering ngDefaultControl>
                                        <option value="No">No</option>
                                        <option value="Yes">Yes</option>
                                    </select>
                                </mat-label>
                            </div>
                            <div [hidden]="mens_pain_time_flag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel">Timing of pain<br>
                                    <select class="ipcss" [disabled]="time_paindisabled || client"
                                        [required]="time_painrequire" [(ngModel)]="mens_pain_time" disableOptionCentering ngDefaultControl>
                                        <option disabled>Select</option>
                                        <option value="Before menses">Before menses</option>
                                        <option value="During menses">During menses</option>
                                        <option value="Both">Both</option>
                                    </select>
                                </mat-label>
                            </div>
                            <div [hidden]="mens_pain_char_flag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel">Character of pain<br>
                                    <select class="ipcss" [disabled]="client"
                                        [required]="character_painrequire" [(ngModel)]="mens_pain_char"
                                        disableOptionCentering ngDefaultControl>
                                        <option disabled>Select</option>
                                        <option *ngFor="let character of Characer_pain" value="{{character.description}}">
                                            {{character.description}}</option>
                                    </select>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <mat-label class="matlabel">Other menstrual issues<br>
                                    <textarea class="ipcss"  maxlength="250" rows="1"
                                        [(ngModel)]="mens_other"></textarea>
                                </mat-label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
