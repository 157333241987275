import { Component, OnInit, ViewChild } from '@angular/core';
import { Helper_Class } from '../../helper_class';
import { ToastrService } from 'ngx-toastr';
import { DietService } from '../diet-view/diet-view.service';
import { Diet_Helper } from '../Diet_Helper';
import { Subscription } from 'rxjs';
import { DietCasesheetService } from './diet-casesheet.service';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { HttpClient } from '@angular/common/http';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
@Component({
  selector: 'app-diet-casesheet',
  templateUrl: './diet-casesheet.component.html',
  styleUrls: ['./diet-casesheet.component.scss']
})
export class DietCasesheetComponent implements OnInit {

  public cards= [];
  public cardViewFlag:boolean = false;
  public userInfo;
  public userList;
  public selectedUser: any;
  public min = "min";
  public max = "max";
  public minMaxFlag:boolean = false;
  subscription: Subscription;

  @ViewChild("slickModal")
  slickModal!: SlickCarouselComponent;
  activeIndex = -1;
  fadeInOut = false;
  isShow = false;

  customOptions: OwlOptions = {
  autoplay: false,
  autoplaySpeed: 1500,
  loop: false,
  pullDrag: false,
  dots: false,
  navSpeed: 750,
  navText: ['', ''],
  responsive: {
    0: {
      items: 3
    },
    400: {
      items: 3
    },
    740: {
      items: 3
    },
    940: {
      items: 3
    }
  },
  nav: false,
  }

  slideConfig = {
  infinite: false,
  speed: 300,
  slidesToShow: 6,
  slidesToScroll: 6,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: false,
        dots: false
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
  };

  constructor(public http:HttpClient,public dietservice:MenuViewService,public messageservice:DietCasesheetService,public toastr:ToastrService) {
    this.messageservice.sendCasesheetMessage("min");
   
  }

  ngOnInit(): void {
    this.cards = ["Vitals","Case Summary","Diet Tracking","Diet Plan"];
    this.userInfo = Helper_Class.getInfo();
    this.userList = Diet_Helper.getClient_Info();
  }

  opencard(id,card,index,card_id,flag){
    this.minMaxFlag = flag;
    if(this.minMaxFlag == true){
      this.messageservice.sendCasesheetMessage("max");
      this.cardViewFlag = true;

      for(var i=0; i< this.cards.length; i++){
        document.getElementById("cardindex_"+i).className = "col-md-12 m-auto user-view";
        if(card == this.cards[i]){
          document.getElementById("card_"+i).style.width = "100%";
          document.getElementById("card_"+i).style.height = "auto";
        }else{
          document.getElementById("card_"+i).style.display = "none";
        }
      }
    }

    if(this.minMaxFlag == false){
      this.messageservice.sendCasesheetMessage("min");
      this.cardViewFlag = false;

      for(var i=0; i< this.cards.length; i++){
        document.getElementById("cardindex_"+i).className = "col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4";
        if(card == this.cards[i]){
          document.getElementById("card_"+i).style.width = "100%";
          document.getElementById("card_"+i).style.height = "290px";
        }else{
          document.getElementById("card_"+i).style.display = "flex";
          document.getElementById("card_"+i).style.width = "100%";
          document.getElementById("card_"+i).style.height = "290px";
        }
      }
    }
  }

  viewRecord(card, index: any,card_id) {
    this.messageservice.sendCasesheetMessage("max");
    this.cardViewFlag = true;
    
    for(var i=0; i< this.cards.length; i++){
      document.getElementById("cardindex_"+i).className = "col-md-12 m-auto user-view";

      if(card == this.cards[i]){
        document.getElementById("card_"+i).style.width = "100%";
        document.getElementById("card_"+i).style.height = "auto";
        document.getElementById("card_"+i).style.display = "flex";
      }else{
        document.getElementById("card_"+i).style.display = "none";
      }
    }
  }

  viewRecord_user(id: any, card, index: any,card_id) {
    this.fadeInOut = true;
    this.isShow = true;
    this.activeIndex = index;
    this.selectedUser = [];
    this.selectedUser = card;
    setTimeout(() => {
      this.isShow = false;
      this.fadeInOut = false;
    },500)
  }

  jsonConcat(o1, o2) {
    for (var key in o2) {
      o1[key] = o2[key];
    }
    return o1;
  }

  back(){
    if(this.userList.AppFlow == "DietApp")
      this.dietservice.sendMessage("appointment");
    else
      this.dietservice.sendMessage("manageappointments");
  }
}
