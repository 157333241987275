import { Component, OnInit, HostListener, Input } from '@angular/core';
import { Helper_Class } from '../../helper_class';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { Observable, observable, Subscription } from 'rxjs';
import { CasesheetService } from '../casesheet/casesheet.service';
import { DiabeticpopuphistoryComponent } from '../diabeticpopuphistory/diabeticpopuphistory.component';
import { MatDialog } from '@angular/material/dialog';
import { Doc_Helper } from '../Doc_Helper';
import { Helper_nephro_casesheet } from '../../../assets/js/Helper_nephro_casesheet';
import { ToastrService } from 'ngx-toastr';
import { ipaddress } from 'src/app/ipaddress';
import { Message_data } from 'src/assets/js/Message_data';

@Component({
  selector: 'app-family-history',
  templateUrl: './family-history.component.html',
  styleUrls: ['./family-history.component.css']
})
export class FamilyHistoryComponent implements OnInit {
  public familyhist;
  public familyHistFlag = "min";
  public togg_heart_att1;
  public stroke1;
  public togg_endo1;
  public togg_hyper1;
  public togg_tuber1;
  public togg_hepat1;
  public togg_arter1;
  public togg_auto1;
  public togg_hemo1;
  public togg_hiv1;
  public togg_gla1;
  subscription: Subscription;

  public togg_diab1;
  public togg_diab: string;
  public togg_storke: string;
  public togg_endo: string;
  public togg_hyper: string;
  public togg_tuber: string;
  public togg_hepat: string;
  public togg_heart_att: string;
  public togg_arter: string;
  public togg_auto: string;
  public togg_hemo: string;
  public togg_hiv: string;
  public togg_gla: string;
  public family_other:string = "";
  private diab_case_hist_id;
  private life_style_id;
  public family;
  public client: boolean = false;
  public getHopitalfieldsRet;
  public patient_list;
  public spc;
  public personaldata:any;
  public relation_datas = [];
  public relation:any;
  public family_hist_data:any = [];

  constructor(public toastr:ToastrService,public dialog: MatDialog, private http: Http, private routes: ActivatedRoute, private router: Router, public messageservice: CasesheetService) {
    this.togg_diab = "no";
    this.togg_storke = "no";
    this.togg_endo = "no";
    this.togg_hyper = "no";
    this.togg_tuber = "no";
    this.togg_hepat = "no";
    this.togg_heart_att = "no";
    this.togg_arter = "no";
    this.togg_auto = "no";
    this.togg_hemo = "no";
    this.togg_hiv = "no";
    this.togg_gla = "no";
  }

  ngOnInit() {
    this.subscription = this.messageservice.getCasesheetMessage().subscribe(message => {
      if (message) {
        this.familyHistFlag = message;
      }
    });

    if (Doc_Helper.getAppFlow() == "client") {
      this.client = true;
      this.patient_list = Helper_Class.getInfo();
    } else {
      this.client = false;
      this.patient_list = Doc_Helper.getClient_Info();
    }

    this.spc = this.patient_list.spl;
    this.getRelations();

    var provider = Helper_Class.getInfo();
    if (provider.provider == "doctor") {
      this.getHopitalfieldsRet = Helper_Class.getHopitalfieldsRet()
      if(this.getHopitalfieldsRet != undefined){
        for (var i = 0; i < this.getHopitalfieldsRet.length; i++) {
          if (this.getHopitalfieldsRet[i].page_name == "Family details") {
            if (this.getHopitalfieldsRet[i].fields != undefined) {
              if (this.getHopitalfieldsRet[i].fields != undefined) {
                for (var j = 0; j < this.getHopitalfieldsRet[i].fields.length; j++) {
                  if (this.getHopitalfieldsRet[i].fields[j].field_name == "Diabetics") {
                  }
                  if (this.getHopitalfieldsRet[i].fields[j].field_name == "Stroke") {
                    //  this.stroke_label=this.getHopitalfieldsRet[i].fields[j].field_name;
  
                    // this.stroke_hidden=false;
                  }
                  if (this.getHopitalfieldsRet[i].fields[j].field_name == "Endocrine") {
                    // this.endrocine_label=this.getHopitalfieldsRet[i].fields[j].field_name;
  
                    // this.endrocine_hidden=false;
                  }
                  if (this.getHopitalfieldsRet[i].fields[j].field_name == "Hypertension") {
                    //  this.hypertension_label=this.getHopitalfieldsRet[i].fields[j].field_name;
  
                    //  this.hyper_hidden=false;
                  }
                  if (this.getHopitalfieldsRet[i].fields[j].field_name == "Tuberculosis") {
                    //  this.tuberculosis_label=this.getHopitalfieldsRet[i].fields[j].field_name;
  
                    //  this.tuberculosis_hidden=false;
                  }
                  if (this.getHopitalfieldsRet[i].fields[j].field_name == "Hepatitis") {
                    // this.hepatitis_label=this.getHopitalfieldsRet[i].fields[j].field_name;
  
                    //this.hepatis_hidden=false;
                  }
                  if (this.getHopitalfieldsRet[i].fields[j].field_name == "Heart attack") {
                    //   this.heart_attacklabel=this.getHopitalfieldsRet[i].fields[j].field_name;
  
                    //  this.heartattack_hidden=false;
                  }
                  if (this.getHopitalfieldsRet[i].fields[j].field_name == "Arterial") {
                    // this.arterial_label=this.getHopitalfieldsRet[i].fields[j].field_name;
  
                    //  this.arterial_hidden=false;
                  }
                  if (this.getHopitalfieldsRet[i].fields[j].field_name == "Autoimmune") {
                    // this.auto_immuelabel=this.getHopitalfieldsRet[i].fields[j].field_name;
  
                    // this.auto_immuehidden=false;
                  }
                  if (this.getHopitalfieldsRet[i].fields[j].field_name == "Hemophilia") {
                    //  this.hempo_philalabel=this.getHopitalfieldsRet[i].fields[j].field_name;
  
                    //  this.hemophila_hidden=false;
                  }
                  if (this.getHopitalfieldsRet[i].fields[j].field_name == "HIV") {
                    //   this.hiv_label=this.getHopitalfieldsRet[i].fields[j].field_name;
  
                    // this.hiv_hidden=false;
                  }
                  if (this.getHopitalfieldsRet[i].fields[j].field_name == "Glaucoma") {
                    //this.glaucoma_label=this.getHopitalfieldsRet[i].fields[j].field_name;
  
                    // this.glaucoma_hidden=false;
                  }
                  if (this.getHopitalfieldsRet[i].fields[j].field_name == "Others") {
                    //  this.other_label=this.getHopitalfieldsRet[i].fields[j].field_name;
  
                    //this.other_hidden=false;
                  }
                }
              }
            }
          }
        }
      }
      
    }
  }

  getRelations() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');

    this.http.get(ipaddress.getIp + "usercontroller/getrelation/", { headers: headers }).subscribe(
      data => {
        this.relation_datas = [];
        for (var b = 0; b < data.json().relation.length; b++) {
          var obj = data.json().relation[b];
            this.relation_datas.push({
              relation_name: obj.relationship_name,
              relation_id: obj.relationship_id
            });
          this.relation = this.relation_datas[0];
        }
        this.Get_family_retrivel();
      }, error => {});
  }

  get_client_data() {// get client common data asg
    var obj = Helper_Class.getAsg();
    if(obj != undefined){
      if (obj.life_style_id != null) {
        this.family = obj;
        this.life_style_id = obj.life_style_id;
        if (this.family.case_hist_id != undefined) {
          this.diab_case_hist_id = this.family.case_hist_id;
        }
        if (this.family.life_style_id != null) {
          this.life_style_id = this.family.life_style_id;
        }
        if(this.family.family_history != undefined && this.family.family_history.length != 0){
          for(var i = 0; i < this.family.family_history.length ; i++){
            var res = this.relation_datas.filter(o => o.relation_id === this.family.family_history[i].relation_id);
            var checkRet = [];
            if(this.family_hist_data.length !=0)
              checkRet = this.family_hist_data.filter(o => o.relation_id === this.family.family_history[i].relation_id);
  
            if(checkRet.length == 0){
              this.family_hist_data.push({
                relation_id:res[0].relation_id,
                relation_name:res[0].relation_name,
                diabetics: this.family.family_history[i].diabetics,
                heart_attack: this.family.family_history[i].heart_attack,
                stroke: this.family.family_history[i].stroke,
                arterial: this.family.family_history[i].arterial,
                endocrine: this.family.family_history[i].endocrine,
                autoimmune: this.family.family_history[i].autoimmune,
                hypertension: this.family.family_history[i].hypertension,
                tuberculosis: this.family.family_history[i].tuberculosis,
                hemophilia: this.family.family_history[i].hemophilia,
                hiv: this.family.family_history[i].hiv,
                hepatitis: this.family.family_history[i].hepatitis,
                glaucoma: this.family.family_history[i].glaucoma,
                others: this.family.family_history[i].others,
              });
            }
          }
          this.send_data();
        }
      }
    }

  }

  Get_family_retrivel() {
    if(this.patient_list.spl == 'Gynecology'){
      this.family = Helper_Class.getgynaRet();
    }else{
      this.family = Helper_Class.getRet();
    }
    if (this.family != undefined && this.family['key'] != 0) {
      if (this.family.case_hist_id != undefined) {
        this.diab_case_hist_id = this.family.case_hist_id;
      }
      if (this.family.life_style_id != null) {
        this.life_style_id = this.family.life_style_id;
      }

      if(this.family.family_history != undefined){
        if(this.family.family_history.length != 0){
          for(var i = 0; i < this.family.family_history.length ; i++){
            var res = this.relation_datas.filter(o => o.relation_id === this.family.family_history[i].relation_id);

            this.family_hist_data.push({
              relation_id:res[0].relation_id,
              relation_name:res[0].relation_name,
              diabetics: this.family.family_history[i].diabetics,
              heart_attack: this.family.family_history[i].heart_attack,
              stroke: this.family.family_history[i].stroke,
              arterial: this.family.family_history[i].arterial,
              endocrine: this.family.family_history[i].endocrine,
              autoimmune: this.family.family_history[i].autoimmune,
              hypertension: this.family.family_history[i].hypertension,
              tuberculosis: this.family.family_history[i].tuberculosis,
              hemophilia: this.family.family_history[i].hemophilia,
              hiv: this.family.family_history[i].hiv,
              hepatitis: this.family.family_history[i].hepatitis,
              glaucoma: this.family.family_history[i].glaucoma,
              others: this.family.family_history[i].others,
            });
          }
          this.send_data();
        }
      }

    }
    this.get_client_data(); // get common data
  }
deletList(action){
  var index = this.family_hist_data.findIndex( x => x.relation_id == action.relation_id);
  this.family_hist_data.splice(index,1);
}
  send_data() {
    if (this.family_other != "") {
      var others = this.family_other;
    }
    var family_hist_data = this.family_hist_data;
      //{
      // diabetics: this.togg_diab,
      // heart_attack: this.togg_heart_att,
      // stroke: this.togg_storke,
      // arterial: this.togg_arter,
      // endocrine: this.togg_endo,
      // autoimmune: this.togg_auto,
      // hypertension: this.togg_hyper,
      // tuberculosis: this.togg_tuber,
      // hemophilia: this.togg_hemo,
      // hiv: this.togg_hiv,
      // hepatitis: this.togg_hepat,
      // glaucoma: this.togg_gla,
      // others: others,
    // }
    // if (this.spc == "Nephrology") {
    //   Helper_nephro_casesheet.neph_diab = this.togg_diab;
    //   Helper_nephro_casesheet.neph_heart_attack = this.togg_heart_att;
    //   Helper_nephro_casesheet.neph_stroke = this.togg_storke;
    //   Helper_nephro_casesheet.neph_arterial = this.togg_arter;
    //   Helper_nephro_casesheet.neph_endo = this.togg_endo;
    //   Helper_nephro_casesheet.neph_auto = this.togg_auto;
    //   Helper_nephro_casesheet.neph_hyper = this.togg_hyper;
    //   Helper_nephro_casesheet.neph_tuber = this.togg_tuber;
    //   Helper_nephro_casesheet.neph_hemo = this.togg_hemo;
    //   Helper_nephro_casesheet.neph_hiv = this.togg_hiv;
    //   Helper_nephro_casesheet.neph_hep = this.togg_hepat;
    //   Helper_nephro_casesheet.neph_glacoma = this.togg_gla;
    //   Helper_nephro_casesheet.neph_family_other = others;
    // }
    Helper_Class.setDiabFam(family_hist_data);
    console.log("famil ----"+JSON.stringify(Helper_Class.getDiabFam()))
  }

  addFamilyDetails(){
    if (this.family_other != "") {
      var others = this.family_other;
    }
    var checkRet = [];
    if(this.family_hist_data.length !=0)
      checkRet = this.family_hist_data.filter(o => o.relation_id === this.relation.relation_id);

    if(checkRet.length == 0){
      if(this.togg_diab != "no" || this.togg_heart_att != "no" || this.togg_storke != "no" || this.togg_arter != "no" ||
      this.togg_endo != "no" || this.togg_auto != "no" || this.togg_hyper != "no" || this.togg_tuber != "no" || this.togg_hemo != "no" ||
      this.togg_hiv != "no" || this.togg_hepat != "no" || this.togg_gla != "no" || this.family_other != ""){
        this.family_hist_data.push({
          relation_id:this.relation.relation_id,
          relation_name:this.relation.relation_name,
          diabetics: this.togg_diab,
          heart_attack: this.togg_heart_att,
          stroke: this.togg_storke,
          arterial: this.togg_arter,
          endocrine: this.togg_endo,
          autoimmune: this.togg_auto,
          hypertension: this.togg_hyper,
          tuberculosis: this.togg_tuber,
          hemophilia: this.togg_hemo,
          hiv: this.togg_hiv,
          hepatitis: this.togg_hepat,
          glaucoma: this.togg_gla,
          others: this.family_other,
        });
      }
      this.send_data();
      this.clearData();
    }else{
      this.toastr.error(Message_data.alrdyExist);
    }
  }

  RelationChange(){
    this.clearData();
  }

  clearData(){
      this.togg_diab1 = false;
      this.changediab(false);
      this.togg_heart_att1 = false;
      this.changeheart(false);
      this.stroke1 = false;
      this.changestroke(false);
      this.togg_arter1 = false;
      this.changearter(false);
      this.togg_auto1 = false;
      this.changeautoimmune(false);
      this.togg_endo1 = false;
      this.changeendro(false);
      this.togg_hyper1 = false;
      this.changehypertension(false);
      this.togg_hemo1 = false;
      this.changehemophilia(false);
      this.togg_tuber1 = false;
      this.changetuber(false);
      this.togg_hiv1 = false;
      this.changehiv(false);
      this.togg_hepat1 = false;
      this.changehepatitis(false);
      this.togg_gla1 = false;
      this.changegla(false);
      this.family_other = "";
  }

  //toggle
  changediab(e) {
    this.togg_diab1 = e;
    this.togg_diab = e == false ? "no" : "yes";
  }

  changeheart(e) {
    this.togg_heart_att1 = e;
    this.togg_heart_att = e == false ? "no" : "yes";
  }

  changestroke(e) {
    this.stroke1 = e;
    this.togg_storke = e == false ? "no" : "yes";
  }

  changearter(e) {
    this.togg_arter1 = e;
    this.togg_arter = e == false ? "no" : "yes";
  }

  changeendro(e) {
    this.togg_endo1 = e;
    this.togg_endo = e == false ? "no" : "yes";
  }

  changeautoimmune(e) {
    this.togg_auto1 = e;
    this.togg_auto = e == false ? "no" : "yes";
  }

  changehypertension(e) {
    this.togg_hyper1 = e;
    this.togg_hyper = e == false ? "no" : "yes";
  }

  changehemophilia(e) {
    this.togg_hemo1 = e;
    this.togg_hemo = e == false ? "no" : "yes";
  }

  changetuber(e) {
    this.togg_tuber1 = e;
    this.togg_tuber = e == false ? "no" : "yes";
  }

  changehiv(e) {
    this.togg_hiv1 = e;
    this.togg_hiv = e == false ? "no" : "yes";
  }

  changehepatitis(e) {
    this.togg_hepat1 = e;
    this.togg_hepat = e == false ? "no" : "yes";
  }

  changegla(e) {
    this.togg_gla1 = e;
    this.togg_gla = e == false ? "no" : "yes";
  }

  history() {
    this.familyhist = "Familyhist"
    const dialogRef = this.dialog.open(DiabeticpopuphistoryComponent, {
      width: '700px',
      height: '300px',
      data: {
        Diab_case_hist_id: this.diab_case_hist_id,
        Life_style_id: this.life_style_id,
        History: this.familyhist,
      }
    });

    dialogRef.afterClosed().subscribe(result => {});
  }

  compareById(object1: any, object2: any) {
    return object1 && object2 && object1.relation_id === object2.relation_id;
  }
}
