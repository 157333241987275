<div class="row" style="margin:0 auto; width:100%">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white" style="padding:7px 20px;">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Pharmacy details</h5>
          </div>
          <div class="headerButtons">
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row">
          <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <mat-label class="matlabel">Search By Relation
              <mat-select disableOptionCentering class="ipcss" [(ngModel)]="relationId" required>
                <mat-option *ngFor="let relation of relationList" (click)="changeRelation(relation.rel_id)"
                  value="{{relation.rel_id}}">{{relation.rel_desc}}</mat-option>
              </mat-select>
            </mat-label>
          </div>
        </div>
        <div class="row" >
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngIf="medPresList.length == 0">
            <p>
              <mat-label class="matlabel">No Report found</mat-label>
            </p>
          </div>
          <div *ngIf="medPresList != undefined && medPresList.length != 0">
            <div class="row">
              <div class="col-6" *ngFor="let prescription of medPresList; let i = index">
                  <div class="casesheetlist"   (click)="viewMedPres(prescription.pres_drug_id)">
                    <p *ngIf="prescription.docname_flag">{{"Dr."+ prescription.doctorname + " prescribed for " +
                      prescription.clientname}} {{" on "+ prescription.date}}</p>
                    <p *ngIf="!prescription.docname_flag">{{"Prescription of " + prescription.clientname}} {{" on "+ prescription.date}}</p>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>