<div *ngIf="diabUploadFlag == 'min'">
    <div class="block1_grid" >
        <div style="display: flex;">
            <mat-label class="matlabel heightmsr">Blood glucose<br>
                <mat-checkbox type="checkbox" color="primary"  matInput  (change)="fast_chn()" [(ngModel)]="fasting" labelPosition="before">Fasting</mat-checkbox>
                &nbsp;&nbsp;&nbsp;<mat-checkbox type="checkbox" color="primary" matInput  [(ngModel)]="ogit_txt" (change)="ogtt_chn()"   labelPosition="before">OGTT</mat-checkbox>
            </mat-label>
        </div>
 
        <div class="block1_grid">
            <mat-label class="matlabel">Fasting / 1/2 hour<br>
                <input type="text" class="ipcss widthappt" matInput placeholder="mg/dl" maxlength="3" [(ngModel)]="Fasting_Halfhour" required onkeypress="return event.charCode >= 48 && event.charCode <= 57"/>
            </mat-label>
            <mat-label class="matlabel">1 hour<br>
                <input type="text" class="ipcss widthappt" matInput placeholder="mg/dl" maxlength="3" disabled="{{fast_one_disable}}" [(ngModel)]="Fasting_Onehour"
                onkeypress="return event.charCode >= 48 && event.charCode <= 57"/>
            </mat-label>
        </div>
    </div>
</div>
 
<div *ngIf="diabUploadFlag == 'max'">
    <!-- <div class="row"></div> -->
    <img  style="width: 25px !important; position: absolute;top: 5px; right: 33px;" src="../../../assets/img/save.svg" (click)="save_reading()"/>
    <div class="row">
        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div class="cover_div">
                <div class="header_lable">
                    Blood glucose
                 </div>
                 <div class="content_cover">
                    <div class="row">
                        <div class="col-3 col-md-3 col-lg-3 col-xl-3">
                            <mat-checkbox color="primary"  matInput  (change)="fast_chn()" [(ngModel)]="fasting" labelPosition="before">Fasting</mat-checkbox>
                        </div>
                        <div class="col-3 col-md-3 col-lg-3 col-xl-3">
                            <mat-checkbox  color="primary" matInput  [(ngModel)]="ogit_txt" (change)="ogtt_chn()"   labelPosition="before">OGTT</mat-checkbox>
                        </div>
                    </div>
                    <div class="row">
                        <div class=" col-12 col-md-3 col-lg-4 col-xl-4">
                            <mat-label class="matlabel">Fasting / 1/2 hour<br>
                                <input type="text" class="ipcss " matInput placeholder="mg/dl" maxlength="3" [(ngModel)]="Fasting_Halfhour" required onkeypress="return event.charCode >= 48 && event.charCode <= 57"/>
                            </mat-label>
                        </div>
                        <div class="col-12 col-md-3 col-lg-4 col-xl-4">
                            <mat-label class="matlabel">1 hour<br>
                                <input type="text" class="ipcss " matInput placeholder="mg/dl" maxlength="3" disabled="{{fast_one_disable}}" [(ngModel)]="Fasting_Onehour"
                                onkeypress="return event.charCode >= 48 && event.charCode <= 57"/>
                            </mat-label>
                        </div>
                        <div class=" col-12 col-md-3 col-lg-4 col-xl-4">
                            
                        <mat-label class="matlabel">1 1/2 hours<br>
                            <input type="text" class="ipcss " matInput  placeholder="mg/dl" maxlength="3" disabled="{{fast_onehalf_disable}}" [(ngModel)]="Fasting_Onehalfhour"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57"/>
                        </mat-label>
                        </div>
                        <div class="  col-12 col-md-3 col-lg-4 col-xl-4">
                            <mat-label class="matlabel">Postprandial<br>
                                <input type="text"class="ipcss " matInput placeholder="mg/dl" maxlength="3" [(ngModel)]="Postprandial" required onkeypress="return event.charCode >= 48 && event.charCode <= 57"/>
                            </mat-label>
                        </div>
                        <div class="  col-12 col-md-3 col-lg-4 col-xl-4">
                            
                        <mat-label class="matlabel">HbA1c<br>
                            <input type="text" class="ipcss " placeholder="mg/dl" maxlength="3" [(ngModel)]="HbA1c" onkeypress="return event.charCode >= 48 && event.charCode <= 57 ||  event.charCode==46"/>
                        </mat-label>
                        </div>
                    </div>
                 </div>
            </div>
        </div>
        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" >
            <div class="cover_div">
                <div class="header_lable">
                    Vital
                 </div>
                 <div class="content_cover" style="height: 136px;">
                    <div class="row">
                        <div class="col-12 col-md-3 col-lg-4 col-xl-4">
                            <mat-label class="matlabel">Temperature<br>
                                <!-- <input class="ipcss " type="text" placeholder="0.00 F" maxlength="6" [(ngModel)]="Temperature" 
                                onkeypress="return event.charCode >= 48 && event.charCode <= 57" (ionChange)="formatNumber($event)"/> -->
                                <input class="ipcss " type="number" placeholder="0.00 F" maxlength="6" [(ngModel)]="Temperature" pattern="^\d\d+\.[1-9]\d*$" onKeyPress="if(Temperature.length==5) return false;" (ionChange)="formatNumber($event)"/>
                            </mat-label>
                        </div>
                        <div class=" col-12 col-md-3 col-lg-4 col-xl-4">
                            <mat-label class="matlabel">Blood pressure  
                                <input type="text" class="ipcss " matInput  placeholder="mm Hg" maxlength="3" [(ngModel)]="BP_01" onkeypress="return event.charCode >= 48 && event.charCode <= 57"/>
                            </mat-label>
                            </div>
                            <div class="col-12 col-md-3 col-lg-4 col-xl-4" style="position: relative;">
                            <mat-label class="matlabel"><br>
                                <span class="slash_icon">/</span>
                                <input type="text" class="ipcss " matInput placeholder="mm Hg" maxlength="3" [(ngModel)]="BP_02" onkeypress="return event.charCode >= 48 && event.charCode <= 57"/>
                            </mat-label>
                      
                
                        </div>
                        <div class="col-12 col-md-3 col-lg-4 col-xl-4">
                            <mat-label class="matlabel"  [hidden]="heart_hidden">{{heart_label}}<br>
                                <input type="text" class="ipcss " matInput  placeholder="Pulse" maxlength="3" [(ngModel)]="Heart_Beat" onkeypress="return event.charCode >= 48 && event.charCode <= 57"/>
                            </mat-label>
                        </div>
                        <div class="col-12 col-md-3 col-lg-4 col-xl-4">
                            <mat-label class="matlabel"  [hidden]="respi_hidden">{{respi_label}}<br>
                                <input type="text" class="ipcss " matInput  placeholder="Rate / minute" maxlength="2" [(ngModel)]="Respiratory_rate" onkeypress="return event.charCode >= 48 && event.charCode <= 57"/>
                            </mat-label>
                        </div>
                    </div>
                 </div>
            </div>
            
        </div>
        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div class="cover_div">
                <div class="header_lable">
                    Urine glucose
                 </div>
                 <div class="content_cover">
                    <div class="row">
                        <div class="col-12 col-md-3 col-lg-4 col-xl-4">
                            <mat-label class="matlabel">{{glocose_label}}<br>
                                <input type="text" class="ipcss " matInput  placeholder="mg/dl" maxlength="3" [(ngModel)]="Glucose" [required]="Ketones!=undefined && Ketones.length!=0 || Proteins!=undefined && Proteins.length!=0"
                                onkeypress="return event.charCode >= 48 && event.charCode <= 57 ||  event.charCode==46"/>
                            </mat-label>
                        </div>
                        <div class="col-12 col-md-3 col-lg-4 col-xl-4">
                            <mat-label class="matlabel" [hidden]="ketone_hidden">{{ketone_label}}<br>
                                <input type="text"class="ipcss " matInput  placeholder="mgol/L" maxlength="3" [(ngModel)]="Ketones" onkeypress="return event.charCode >= 48 && event.charCode <= 57 ||  event.charCode==46"/>
                            </mat-label>
                        </div>
                        <div class="col-12 col-md-3 col-lg-4 col-xl-4">
                            
                            <mat-label class="matlabel" [hidden]="protin_hidden">{{protin_label}}<br>
                                <input type="text" class="ipcss " matInputplaceholder="mg/dl" maxlength="3" [(ngModel)]="Proteins" onkeypress="return event.charCode >= 48 && event.charCode <= 57 ||  event.charCode==46"/>
                            </mat-label>
                        </div>
                    </div>
                 </div>
            </div>
            
        </div>
        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" >
            <div class="cover_div">
                <div class="header_lable">
                    Cholesterol
                 </div>
                 <div class="content_cover">
                    <div class="row">
                        <div class="col-6 col-md-3 col-lg-3 col-xl-3">
                            <mat-label class="matlabel">HDL<br>
                                <input type="text" class="ipcss " matInput   placeholder="mg/dl" maxlength="3" [(ngModel)]="Cholesterol_HDL" onkeypress="return event.charCode >= 48 && event.charCode <= 57"/>
                            </mat-label>
                        </div>
                        <div class="col-6 col-md-3 col-lg-3 col-xl-3">
                            <mat-label class="matlabel">LDL<br>
                                <input type="text" class="ipcss " matInput  placeholder="mg/dl" maxlength="3" [(ngModel)]="Cholesterol_LDL" onkeypress="return event.charCode >= 48 && event.charCode <= 57"/>
                            </mat-label>
                        </div>
                        <div class="col-6 col-md-3 col-lg-3 col-xl-3">
                
                            <mat-label class="matlabel" [hidden]="trig_hidden">{{trig_label}}  <br>
                                <input type="text" class="ipcss " matInput   placeholder="mg/dl" maxlength="3" [(ngModel)]="Cholesterol_Trigs" onkeypress="return event.charCode >= 48 && event.charCode <= 57 || event.charCode==46"/>
                            </mat-label>
                        </div>
                        <div class="col-6 col-md-2 col-lg-3 col-xl-3">
                            <mat-label class="matlabel" [hidden]="vldl_hidden">{{vldl_label}}<br>
                                <input type="text" class="ipcss " matInput   placeholder="mg/dl" maxlength="3" [(ngModel)]="Cholesterol_VLDL" onkeypress="return event.charCode >= 48 && event.charCode <= 57"/>
                            </mat-label>
                        </div>
                    </div>
                 </div>
            </div>
            
        </div>
    </div>
</div>
