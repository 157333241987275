<div class="Details container" id="Begin_content_others" style="padding: 0 15px 0 15px; max-width: 100%;">
  <div class="row">

    <div class="col-12">
      <mdb-card>
        <mdb-card-header class="bg-white">
          <div class="headerCover">
            <div class="headerTilte app_list_header">
              <h5 class="m-0" class="mainHeadingStyle">Patient List</h5>
            </div>
            <div class="headerButtons">
            </div>
          </div>

        </mdb-card-header>
        <mdb-card-body>
          <div class="row">
            <div class="col-3 col-sm-3 col-md-3 col-xl-3 col-lg-3">
              <mat-label class="mat-label">Filter by
                <select class="ipcss widthappt" id="Search" required [(ngModel)]="Filter_txt" (change)="Filter_change()"
                  disableOptionCentering>
                  <option disabled value="Select">Select</option>
                  <option value="In-patient">In-patient</option>
                  <option value="Discharged">Discharged</option>
                </select>
              </mat-label>
            </div>
            <div class="col-3 col-sm-3 col-md-3 col-xl-3 col-lg-3" *ngIf="is_admin">
              <mat-label class="mat-label" id="Search">Doctor</mat-label>
              <div>
                <select required [(ngModel)]="doctorid" (change)="changedoc()" class="ipcss widthappt">
                  <option value="All">All</option>
                  <option *ngFor="let doctors of doctorList" value={{doctors.Doc_ID}}>{{doctors.Doc_Name}}
                  </option>
                </select>
              </div>
            </div>
            <!-- <div class="col-3 col-sm-3 col-md-3 col-xl-3 col-lg-3">
                <mat-label class="mat-label"><br>
                  <a (click)="Searc_click()"><img src="../../../assets/ui_icons/buttons/search_button.svg"
                      class="saveimgbtn_inpatinfo" /></a>
                </mat-label>
              </div> -->
          </div>
          <div class="row mt-2" *ngIf="Filter_txt == 'Discharged'">
            <div class="col-12" style="margin-bottom: 9px ;">
              <div class="switch-field" *ngIf="ynopt1 != undefined">
                <input type="radio" id="radio-three" (click)="change_type(1,'yes')" />
                <label for="radio-three" [ngClass]="ynopt1 ? 'radio_active':'radio_inactive'">
                  <div class="tab_cover">
                    <div class="tab_icon_cover"><img src="../../../assets/ui_icons/Self.svg" class="iocinStyle" alt="">
                    </div>
                    <div class="lableStyle">Self</div>
                  </div>
                </label>
                <input type="radio" id="radio-four" (click)="change_type(1,'no')" />
                <label for="radio-four" [ngClass]="!ynopt1 ? 'radio_active':'radio_inactive'">
                  <div class="tab_cover">
                    <div class="tab_icon_cover"><img src="../../../assets/ui_icons/others.svg" class="iocinStyle"
                        alt=""></div>
                    <div class="lableStyle">Others</div>
                  </div>
                </label>
              </div>
            </div>
          </div>
          <p class="nodata" *ngIf="appointmentpatinet_list.length == 0">No Appointment(s) Found</p>
          <table *ngIf="appointmentpatinet_list.length" mdbTable datatable [dtOptions]="dtOptions"
            class="table table-nowrap table-sm dataTable">
            <thead>
              <tr>
                <th>Image</th>
                <th>Patient Name</th>
                <th>Location</th>
                <th>Contact No</th>
                <!-- <th *ngIf="this.Filter_txt == 'Discharged'"></th> -->
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let person of appointmentpatinet_list; let i = index" >
                <td (click)='Patient_view(person.admission_id)'><img src="{{person.pro_image}}" class="img-fluid z-depth-1 rounded-circle mr-1"></td>
                <td (click)='Patient_view(person.admission_id)' style="font-size: 12px;text-align: left;padding-left: 50px;">{{ person.full_name }}</td>
                <td (click)='Patient_view(person.admission_id)' style="font-size: 12px;text-align: left;padding-left: 50px;">{{ person.loca+", "+ person.city }}
                </td>
                <td (click)='Patient_view(person.admission_id)' style="font-size: 12px; text-align: left;padding-left: 50px;">{{ person.mobile_no }}</td>
                <!-- <td *ngIf="this.Filter_txt == 'Discharged'"><img src="../../../assets/ui_icons/pencil_icon_New.svg"
                    style="width: 20px; height: 20px;" (click)="editDischarge(person)"></td> -->
              </tr>
            </tbody>
          </table>


        </mdb-card-body>
      </mdb-card>
    </div>
  </div>
</div>