<div *ngIf="diabFootFlag == 'min'">
    <div class="block1_grid">
        <mat-label class="matlabel foot">Left leg</mat-label>
        <mat-label class="matlabel">
            <select class="ipcss widthappt" required (change)="foot_ass_valid()" [(ngModel)]="limb_tibia_L">
                <option value="None">None</option>
                <option value="Absent">Absent</option>
                <option value="Present-diminished">Present-diminished</option>
                <option value="Normal">Normal</option>
                <option value="Bounding">Bounding</option>
            </select>
        </mat-label>
        <mat-label class="matlabel">
            <select class="ipcss widthappt" required (change)="foot_ass_valid()" [(ngModel)]="limb_pedis_L"
                [ngStyle]="limb_ped_L">
                <option value="None">None</option>
                <option value="Absent">Absent</option>
                <option value="Present-diminished">Present-diminished</option>
                <option value="Normal">Normal</option>
                <option value="Bounding">Bounding</option>
            </select>
        </mat-label>
        <mat-label class="matlabel">
            <select class="ipcss widthappt" [(ngModel)]="limb_skin_discolor_L">
                <option value="No">None</option>
                <option value="Absent">Absent</option>
            </select>
        </mat-label>
        <mat-label class="matlabel">
            <select class="ipcss widthappt" required (change)="foot_ass_valid()" [(ngModel)]="limb_pedis_L"
                [ngStyle]="limb_ped_L">
                <option value="None">None</option>
                <option value="Absent">Absent</option>
                <option value="Present-diminished">Present-diminished</option>
                <option value="Normal">Normal</option>
                <option value="Bounding">Bounding</option>
            </select>
        </mat-label>
        <!-- <mat-label class="matlabel">
           <select   class="ipcss widthappt"  [(ngModel)]="limb_numbness_L" >
               <option value="No">No</option>
               <option value="Yes">Yes</option>
           </select>
           </mat-label>
           <mat-label class="matlabel">
               <select   class="ipcss widthappt"   [(ngModel)]="limb_burning_L" >
                   <option value="No">No</option>
                   <option value="Yes">Yes</option>
           </select>
           </mat-label> -->
    </div>
</div>

<div *ngIf="diabFootFlag == 'max'">

    <div class="row">
        <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3" >
            <div class="cover_div">
                <div class="header_lable">
                    Lower limb vascular
                </div>
                <div class="content_cover">
                    <div class="row">
                        <div class="col-4 p-0"></div>
                        <div class="col-4 p-0">
                            <div style="width: fit-content; margin: 0 auto;"><img class="foot_img"
                                    src="../../../assets/ui_icons/left_foot.svg"></div>
                        </div>
                        <div class="col-4 p-0">
                            <div style="width: fit-content; margin: 0 auto;"><img class="foot_img"
                                    src="../../../assets/ui_icons/right_foot.svg"></div>
                        </div>
                        <!-- content -->
                        <!-- 1 -->
                        <div class="col-4 p-0 foot_col_height foot_col_height">
                            <mat-label class="matlabel " [hidden]="posterior_hidden">{{posterior_label}}</mat-label>
                        </div>
                        <div class="col-4 p-0 foot_col_height">
                            <!--  left -->
                            <mat-label class="matlabel">
                                <select class="ipcss foot_select_width" (change)="foot_ass_valid1()"
                                    [(ngModel)]="limb_tibia_L" [ngStyle]="limbL" [disabled]="client">
                                    <option value="None">None</option>
                                    <option value="Absent">Absent</option>
                                    <option value="Present-diminished">Present-diminished</option>
                                    <option value="Normal">Normal</option>
                                    <option value="Bounding">Bounding</option>
                                </select>
                            </mat-label>
                        </div>
                        <div class="col-4 p-0 foot_col_height">
                            <!-- right -->
                            <mat-label class="matlabel">
                                <select class="ipcss foot_select_width" (change)="foot_ass_valid()"
                                    [(ngModel)]="limb_tibia_R" [ngStyle]="limbR" [disabled]="client">
                                    <option value="None">None</option>
                                    <option value="Absent">Absent</option>
                                    <option value="Present-diminished">Present-diminished</option>
                                    <option value="Normal">Normal</option>
                                    <option value="Bounding">Bounding</option>
                                </select>
                            </mat-label>
                        </div>
                        <!-- 2 -->
                        <div class="col-4 p-0 foot_col_height">
                            <mat-label class="matlabel " [hidden]="dorsalis_hidden"> {{dorsalis_label}} </mat-label>
                        </div>
                        <div class="col-4 p-0 foot_col_height">
                            <!--  left -->
                            <mat-label class="matlabel">
                                <select class="ipcss foot_select_width" (change)="foot_ass_valid3()"
                                    [(ngModel)]="limb_pedis_L" [ngStyle]="limb_ped_L" [disabled]="client">
                                    <option value="None">None</option>
                                    <option value="Absent">Absent</option>
                                    <option value="Present-diminished">Present-diminished</option>
                                    <option value="Normal">Normal</option>
                                    <option value="Bounding">Bounding</option>
                                </select>
                            </mat-label>
                        </div>
                        <div class="col-4 p-0 foot_col_height">
                            <!-- right -->
                            <mat-label class="matlabel">
                                <select class="ipcss foot_select_width" (change)="foot_ass_valid2()"
                                    [(ngModel)]="limb_pedis_R" [ngStyle]="limb_ped_R" [disabled]="client">
                                    <option value="None">None</option>
                                    <option value="Absent">Absent</option>
                                    <option value="Present-diminished">Present-diminished</option>
                                    <option value="Normal">Normal</option>
                                    <option value="Bounding">Bounding</option>
                                </select>
                            </mat-label>
                        </div>
                    </div>
                    <div class="row foot_col_height" [hidden]="skindis_hidden">
                        <!-- 3 -->
                        <div class="col-4 p-0 my-auto">
                            <div class="matlabel" [hidden]="skindis_hidden"> {{skindis_label}}<br></div>
                        </div>
                        <div class="col-4 p-0 my-auto">

                            <div style="text-align: center;">
                                <mat-button-toggle id="toggid2" class="togglebtn" [(ngModel)]="limb_skin_discolor_Li"
                                    ngDefaultControl [checked]="limb_skin_discolor_Li"
                                    (change)="changeskindiscolorleft($event.source.checked)" [disabled]="client">
                                    <span class="familytab" *ngIf="!limb_skin_discolor_Li"><img class="foot_toggle_img"
                                            src="../../../assets/ui_icons/cross_mark.svg"></span>
                                    <span class="familytab" *ngIf="limb_skin_discolor_Li"><img class="foot_toggle_img"
                                            src="../../../assets/ui_icons/tick_mark.svg"></span>
                                </mat-button-toggle>
                            </div>

                        </div>
                        <div class="col-4 p-0 my-auto">
                            <!-- right -->
                            <div style="text-align: center;">
                                <mat-button-toggle id="toggid1" class="togglebtn" [(ngModel)]="limb_skin_discolor_Ri"
                                    ngDefaultControl [checked]="limb_skin_discolor_Ri"
                                    (change)="changeskindiscolor($event.source.checked)" [disabled]="client">
                                    <span class="familytab" *ngIf="!limb_skin_discolor_Ri"><img class="foot_toggle_img"
                                            src="../../../assets/ui_icons/cross_mark.svg"></span>
                                    <span class="familytab" *ngIf="limb_skin_discolor_Ri"><img class="foot_toggle_img"
                                            src="../../../assets/ui_icons/tick_mark.svg"></span>
                                </mat-button-toggle>
                            </div>


                        </div>
                    </div>
                    <div class="row foot_col_height" [hidden]="aching_hidden">
                        <!-- 4 -->
                        <div class="col-4 p-0 my-auto">
                            <div class="matlabel" [hidden]="aching_hidden"> {{aching_label}}<br></div>
                        </div>
                        <div class="col-4 p-0 my-auto">
                            <div style="text-align: center;">
                                <mat-button-toggle id="toggid4" class="togglebtn" [(ngModel)]="limb_aching_Li"
                                    ngDefaultControl [checked]="limb_aching_Li"
                                    (change)="changeachingleft($event.source.checked)" [disabled]="client">
                                    <span class="familytab" *ngIf="!limb_aching_Li"><img class="foot_toggle_img"
                                            src="../../../assets/ui_icons/cross_mark.svg"></span>
                                    <span class="familytab" *ngIf="limb_aching_Li"><img class="foot_toggle_img"
                                            src="../../../assets/ui_icons/tick_mark.svg"></span>
                                </mat-button-toggle>

                            </div>

                        </div>
                        <div class="col-4 p-0 my-auto">
                            <!-- right -->
                            <div style="text-align: center;">
                                <mat-button-toggle id="toggid3" class="togglebtn" [(ngModel)]="limb_aching_Ri"
                                    ngDefaultControl [checked]="limb_aching_Ri"
                                    (change)="changeachingright($event.source.checked)" [disabled]="client">
                                    <span class="familytab" *ngIf="!limb_aching_Ri"><img class="foot_toggle_img"
                                            src="../../../assets/ui_icons/cross_mark.svg"></span>
                                    <span class="familytab" *ngIf="limb_aching_Ri"><img class="foot_toggle_img"
                                            src="../../../assets/ui_icons/tick_mark.svg"></span>
                                </mat-button-toggle>
                            </div>
                        </div>
                    </div>
                    <div class="row foot_col_height" [hidden]="numbeness_hidden">
                        <!-- 5 -->
                        <div class="col-4 p-0 my-auto">
                            <div class="matlabel" [hidden]="numbeness_hidden"> {{numbeness_label}}<br></div>
                        </div>
                        <div class="col-4 p-0 my-auto">
                            <div style="text-align: center;">

                                <mat-button-toggle id="toggid6" class="togglebtn" [(ngModel)]="limb_numbness_Li"
                                    ngDefaultControl [checked]="limb_numbness_Li"
                                    (change)="changenumbnessleft($event.source.checked)" [disabled]="client">
                                    <span class="familytab" *ngIf="!limb_numbness_Li"><img class="foot_toggle_img"
                                            src="../../../assets/ui_icons/cross_mark.svg"></span>
                                    <span class="familytab" *ngIf="limb_numbness_Li"><img class="foot_toggle_img"
                                            src="../../../assets/ui_icons/tick_mark.svg"></span>
                                </mat-button-toggle>


                            </div>

                        </div>
                        <div class="col-4 p-0 my-auto">
                            <!-- right -->
                            <div style="text-align: center;">
                                <mat-button-toggle id="toggid5" class="togglebtn" [(ngModel)]="limb_numbness_Ri"
                                    ngDefaultControl [checked]="limb_numbness_Ri"
                                    (change)="changenumbnessright($event.source.checked)" [disabled]="client">
                                    <span class="familytab" *ngIf="!limb_numbness_Ri"><img class="foot_toggle_img"
                                            src="../../../assets/ui_icons/cross_mark.svg"></span>
                                    <span class="familytab" *ngIf="limb_numbness_Ri"><img class="foot_toggle_img"
                                            src="../../../assets/ui_icons/tick_mark.svg"></span>
                                </mat-button-toggle>


                            </div>
                        </div>
                    </div>
                    <div class="row foot_col_height" [hidden]="buring_hidden">
                        <!-- 6 -->
                        <div class="col-4 p-0 my-auto">
                            <div class="matlabel" [hidden]="buring_hidden"> {{buring_label}}<br></div>
                        </div>
                        <div class="col-4 p-0 my-auto">
                            <div style="text-align: center;">
                                <mat-button-toggle id="toggid8" class="togglebtn" [(ngModel)]="limb_burning_Li"
                                    ngDefaultControl [checked]="limb_burning_Li"
                                    (change)="changeburningleft($event.source.checked)" [disabled]="client">
                                    <span class="familytab" *ngIf="!limb_burning_Li"><img class="foot_toggle_img"
                                            src="../../../assets/ui_icons/cross_mark.svg"></span>
                                    <span class="familytab" *ngIf="limb_burning_Li"><img class="foot_toggle_img"
                                            src="../../../assets/ui_icons/tick_mark.svg"></span>
                                </mat-button-toggle>

                            </div>

                        </div>
                        <div class="col-4 p-0 my-auto">
                            <!-- right -->
                            <div style="text-align: center;">
                                <mat-button-toggle id="toggid7" class="togglebtn" [(ngModel)]="limb_burning_Ri"
                                    ngDefaultControl [checked]="limb_burning_Ri"
                                    (change)="changeburningright($event.source.checked)" [disabled]="client">
                                    <span class="familytab" *ngIf="!limb_burning_Ri"><img class="foot_toggle_img"
                                            src="../../../assets/ui_icons/cross_mark.svg"></span>
                                    <span class="familytab" *ngIf="limb_burning_Ri"><img class="foot_toggle_img"
                                            src="../../../assets/ui_icons/tick_mark.svg"></span>
                                </mat-button-toggle>
                            </div>
                        </div>
                    </div>
                    <div class="row foot_col_height" [hidden]="tingling_hidden">
                        <!-- 7 -->
                        <div class="col-4 p-0 my-auto">
                            <div class="matlabel" [hidden]="tingling_hidden"> {{tingling_label}}<br></div>
                        </div>
                        <div class="col-4 p-0 my-auto">
                            <div style="text-align: center;">
                                <mat-button-toggle id="toggid10" class="togglebtn" [(ngModel)]="limb_tingling_Li"
                                    ngDefaultControl [checked]="limb_tingling_Li"
                                    (change)="changetinglingleft($event.source.checked)" [disabled]="client">
                                    <span class="familytab" *ngIf="!limb_tingling_Li"><img class="foot_toggle_img"
                                            src="../../../assets/ui_icons/cross_mark.svg"></span>
                                    <span class="familytab" *ngIf="limb_tingling_Li"><img class="foot_toggle_img"
                                            src="../../../assets/ui_icons/tick_mark.svg"></span>
                                </mat-button-toggle>
                            </div>

                        </div>
                        <div class="col-4 p-0 my-auto">
                            <!-- right -->
                            <div style="text-align: center;">
                                <mat-button-toggle id="toggid9" class="togglebtn" [(ngModel)]="limb_tingling_Ri"
                                    ngDefaultControl [checked]="limb_tingling_Ri"
                                    (change)="changetinglingright($event.source.checked)" [disabled]="client">
                                    <span class="familytab" *ngIf="!limb_tingling_Ri"><img class="foot_toggle_img"
                                            src="../../../assets/ui_icons/cross_mark.svg"></span>
                                    <span class="familytab" *ngIf="limb_tingling_Ri"><img class="foot_toggle_img"
                                            src="../../../assets/ui_icons/tick_mark.svg"></span>
                                </mat-button-toggle>

                            </div>
                        </div>
                    </div>
                    <div class="row" [hidden]="footdefor_hidden">
                        <!-- 8 -->
                        <div class="col-4 p-0 foot_col_height">
                            <mat-label class="matlabel " [hidden]="footdefor_hidden"> {{footdefor_label}} </mat-label>
                        </div>
                        <div class="col-4 p-0 foot_col_height">
                            <!--  left -->
                            <mat-label class="matlabel">
                                <select class="ipcss foot_select_width" [(ngModel)]="deformity_L" [disabled]="client">
                                    <option value="None">None</option>
                                    <option value="Bunion">Bunion</option>
                                    <option value="Claw Toes">Claw Toes</option>
                                    <option value="Charcot Joint">Charcot Joint</option>
                                </select>
                            </mat-label>
                        </div>
                        <div class="col-4 p-0 foot_col_height">
                            <!-- right -->
                            <mat-label class="matlabel">
                                <select class="ipcss foot_select_width" required [(ngModel)]="deformity_R"
                                    [disabled]="client">
                                    <option value="None">None</option>
                                    <option value="Bunion">Bunion</option>
                                    <option value="Claw Toes">Claw toes</option>
                                    <option value="Charcot Joint">Charcot joint</option>
                                </select>

                            </mat-label>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="col-12 col-sm-6 col-md-8 col-lg-8 col-xl-9 p-0" >
            <div class="row">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4" >
                    <div class="cover_div">
                        <div class="header_lable" [hidden]="periperalhidden">
                            Peripheral sensory assessment
                        </div>
                        <div class="content_cover">
                            <div class="row">
                                <div class="col-4 p-0"></div>
                                <div class="col-4 p-0">
                                    <div style="width: fit-content; margin: 0 auto;"><img class="foot_img"
                                            src="../../../assets/ui_icons/left_foot.svg"></div>
                                </div>
                                <div class="col-4 p-0">
                                    <div style="width: fit-content; margin: 0 auto;"><img class="foot_img"
                                            src="../../../assets/ui_icons/right_foot.svg"></div>
                                </div>
                            </div>
                            <div class="row" [hidden]="vibration_hidden">
                                <!-- 1 -->
                                <div class="col-4 p-0 foot_col_height foot_col_height">
                                    <div class="matlabel">{{vibration_lable_1 + " "}}<span
                                            class="vibrain_substring">{{vibration_lable_2}}</span></div>
                                </div>
                                <div class="col-4 p-0 foot_col_height">
                                    <!--  left -->
                                    <mat-label class="matlabel">
                                        <select class="ipcss foot_select_width" [(ngModel)]="sensory_vibration_L"
                                            [disabled]="client">
                                            <option value="None">None</option>
                                            <option value="Present">Present</option>
                                            <option value="Absent">Absent</option>
                                        </select>
                                    </mat-label>
                                </div>
                                <div class="col-4 p-0 foot_col_height">
                                    <!-- right -->
                                    <mat-label class="matlabel">
                                        <select class="ipcss foot_select_width" required
                                            [(ngModel)]="sensory_vibration_R" [disabled]="client">
                                            <option value="None">None</option>
                                            <option value="Present">Present</option>
                                            <option value="Absent">Absent</option>
                                        </select>
                                    </mat-label>
                                </div>
                            </div>
                            <div class="row" [hidden]="monofilament_hidden">
                                <!-- 2 -->
                                <div class="col-4 p-0 foot_col_height foot_col_height">
                                    <mat-label class="matlabel " style="font-size:11.4px !important;"
                                        [hidden]="monofilament_hidden">{{monofilament_label}}</mat-label>
                                </div>
                                <div class="col-4 p-0 foot_col_height">
                                    <!--  left -->
                                    <mat-label class="matlabel">
                                        <select class="ipcss foot_select_width" [(ngModel)]="sensory_10g_L"
                                            [disabled]="client">
                                            <option value="None">None</option>
                                            <option value="Present">Present</option>
                                            <option value="Absent">Absent</option>
                                        </select>

                                    </mat-label>
                                </div>
                                <div class="col-4 p-0 foot_col_height">
                                    <!-- right -->
                                    <mat-label class="matlabel">
                                        <select class="ipcss foot_select_width" required [(ngModel)]="sensory_10g_R"
                                            [disabled]="client">
                                            <option value="None">None</option>
                                            <option value="Present">Present</option>
                                            <option value="Absent">Absent</option>
                                        </select>
                                    </mat-label>
                                </div>
                            </div>
                            <div class="row" [hidden]="pinprick_hidden">
                                <!-- 3 -->
                                <div class="col-4 p-0 foot_col_height foot_col_height">
                                    <mat-label class="matlabel " [hidden]="pinprick_hidden">{{pinprick_label}}
                                    </mat-label>
                                </div>
                                <div class="col-4 p-0 foot_col_height">
                                    <!--  left -->
                                    <mat-label class="matlabel">
                                        <select class="ipcss foot_select_width" [(ngModel)]="pinprick_L"
                                            [disabled]="client">
                                            <option value="None">None</option>
                                            <option value="Present">Present</option>
                                            <option value="Absent">Absent</option>
                                        </select>
                                    </mat-label>
                                </div>
                                <div class="col-4 p-0 foot_col_height">
                                    <!-- right -->
                                    <mat-label class="matlabel">
                                        <select class="ipcss foot_select_width" required [(ngModel)]="pinprick_R"
                                            [disabled]="client">
                                            <option value="None">None</option>
                                            <option value="Present">Present</option>
                                            <option value="Absent">Absent</option>
                                        </select>
                                    </mat-label>
                                </div>
                            </div>
                            <div class="row" [hidden]="ankle_reflexeshidden">
                                <!-- 4 -->
                                <div class="col-4 p-0 foot_col_height foot_col_height">
                                    <mat-label class="matlabel " [hidden]="ankle_reflexeshidden">{{ankle_reflexeslabel}}
                                    </mat-label>
                                </div>
                                <div class="col-4 p-0 foot_col_height">
                                    <!--  left -->
                                    <mat-label class="matlabel">
                                        <select class="ipcss foot_select_width" [(ngModel)]="ankle_reflex_L"
                                            [disabled]="client">
                                            <option value="None">None</option>
                                            <option value="Present">Present</option>
                                            <option value="Absent">Absent</option>
                                        </select>
                                    </mat-label>
                                </div>
                                <div class="col-4 p-0 foot_col_height">
                                    <!-- right -->
                                    <mat-label class="matlabel">
                                        <select class="ipcss foot_select_width" required [(ngModel)]="ankle_reflex_R"
                                            [disabled]="client">
                                            <option value="None">None</option>
                                            <option value="Present">Present</option>
                                            <option value="Absent">Absent</option>
                                        </select>
                                    </mat-label>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
                    <div class="cover_div">
                        <div class="header_lable" [hidden]="footwoundhidden">
                            Foot wounds
                        </div>
                        <div class="content_cover">
                            <div class="row">
                                <div class="col-4 p-0"></div>
                                <div class="col-4 p-0">
                                    <div style="width: fit-content; margin: 0 auto;"><img class="foot_img"
                                            src="../../../assets/ui_icons/left_foot.svg"></div>
                                </div>
                                <div class="col-4 p-0">
                                    <div style="width: fit-content; margin: 0 auto;"><img class="foot_img"
                                            src="../../../assets/ui_icons/right_foot.svg"></div>
                                </div>
                            </div>
                            <div class="row foot_col_height" [hidden]="foot_ulcerhidden">
                                <!-- 1 -->
                                <div class="col-4 p-0 my-auto">
                                    <div class="matlabel" [hidden]="foot_ulcerhidden"> {{foot_ulcerlabel}}<br></div>
                                </div>
                                <div class="col-4 p-0 my-auto">
                                    <div style="text-align: center;">
                                        <mat-button-toggle id="toggid12" class="togglebtn"
                                            [(ngModel)]="wound_previous_Li" ngDefaultControl
                                            [checked]="wound_previous_Li"
                                            (change)="changewoundleft($event.source.checked)" [disabled]="client">
                                            <span class="familytab" *ngIf="!wound_previous_Li"><img
                                                    class="foot_toggle_img"
                                                    src="../../../assets/ui_icons/cross_mark.svg"></span>
                                            <span class="familytab" *ngIf="wound_previous_Li"><img
                                                    class="foot_toggle_img"
                                                    src="../../../assets/ui_icons/tick_mark.svg"></span>
                                        </mat-button-toggle>
                                    </div>
                                </div>
                                <div class="col-4 p-0 my-auto">
                                    <!-- right -->
                                    <div style="text-align: center;">
                                        <mat-button-toggle id="toggid11" class="togglebtn"
                                            [(ngModel)]="wound_previous_Ri" ngDefaultControl
                                            [checked]="wound_previous_Ri"
                                            (change)="changewoundright($event.source.checked)" [disabled]="client">
                                            <span class="familytab" *ngIf="!wound_previous_Ri"><img
                                                    class="foot_toggle_img"
                                                    src="../../../assets/ui_icons/cross_mark.svg"></span>
                                            <span class="familytab" *ngIf="wound_previous_Ri"><img
                                                    class="foot_toggle_img"
                                                    src="../../../assets/ui_icons/tick_mark.svg"></span>
                                        </mat-button-toggle>
                                    </div>
                                </div>
                            </div>
                            <div class="row foot_col_height" [hidden]="footdefor_hidden">
                                <!-- 12 -->
                                <div class="col-4 p-0 my-auto">
                                    <div class="matlabel" [hidden]="footdefor_hidden"> {{currentfore_ulcerlabel}}<br>
                                    </div>
                                </div>
                                <div class="col-4 p-0 my-auto">
                                    <div style="text-align: center;">

                                        <mat-button-toggle id="toggid14" class="togglebtn"
                                            [(ngModel)]="wound_current_fore_Li" ngDefaultControl
                                            [checked]="wound_current_fore_Li"
                                            (change)="changecurrentforeleft($event.source.checked)" [disabled]="client">
                                            <span class="familytab" *ngIf="!wound_current_fore_Li">
                                                <img class="foot_toggle_img"
                                                    src="../../../assets/ui_icons/cross_mark.svg">
                                            </span>
                                            <span class="familytab" *ngIf="wound_current_fore_Li">
                                                <img class="foot_toggle_img"
                                                    src="../../../assets/ui_icons/tick_mark.svg">
                                            </span>
                                        </mat-button-toggle>

                                    </div>
                                </div>
                                <div class="col-4 p-0 my-auto">
                                    <!-- right -->
                                    <div style="text-align: center;">
                                        <mat-button-toggle id="toggid13" class="togglebtn"
                                            [(ngModel)]="wound_current_fore_Ri" ngDefaultControl
                                            [checked]="wound_current_fore_Ri"
                                            (change)="changecurrentforeright($event.source.checked)"
                                            [disabled]="client">
                                            <span class="familytab" *ngIf="!wound_current_fore_Ri"><img
                                                    class="foot_toggle_img"
                                                    src="../../../assets/ui_icons/cross_mark.svg"></span>
                                            <span class="familytab" *ngIf="wound_current_fore_Ri"><img
                                                    class="foot_toggle_img"
                                                    src="../../../assets/ui_icons/tick_mark.svg"></span>
                                        </mat-button-toggle>

                                    </div>
                                </div>
                            </div>
                            <div class="row foot_col_height" [hidden]="currentmid_hidden">
                                <!-- 3 -->
                                <div class="col-4 p-0 my-auto">
                                    <div class="matlabel" [hidden]="currentmid_hidden"> {{currentmid_ulcerlabel}}<br>
                                    </div>
                                </div>
                                <div class="col-4 p-0 my-auto">
                                    <div style="text-align: center;">
                                        <mat-button-toggle id="toggid16" class="togglebtn"
                                            [(ngModel)]="wound_current_mid_Li" ngDefaultControl
                                            [checked]="wound_current_mid_Li"
                                            (change)="changecurrentmidleft($event.source.checked)" [disabled]="client">
                                            <span class="familytab" *ngIf="!wound_current_mid_Li"><img
                                                    class="foot_toggle_img"
                                                    src="../../../assets/ui_icons/cross_mark.svg"></span>
                                            <span class="familytab" *ngIf="wound_current_mid_Li"><img
                                                    class="foot_toggle_img"
                                                    src="../../../assets/ui_icons/tick_mark.svg"></span>
                                            <span class="familytab" *ngIf="wound_previous_Li"></span>
                                        </mat-button-toggle>


                                    </div>
                                </div>
                                <div class="col-4 p-0 my-auto">
                                    <!-- right -->
                                    <div style="text-align: center;">

                                        <mat-button-toggle id="toggid15" class="togglebtn"
                                            [(ngModel)]="wound_current_mid_Ri" ngDefaultControl
                                            [checked]="wound_current_mid_Ri"
                                            (change)="changecurrentmidright($event.source.checked)" [disabled]="client">
                                            <span class="familytab" *ngIf="!wound_current_mid_Ri"><img
                                                    class="foot_toggle_img"
                                                    src="../../../assets/ui_icons/cross_mark.svg"></span>
                                            <span class="familytab" *ngIf="wound_current_mid_Ri"><img
                                                    class="foot_toggle_img"
                                                    src="../../../assets/ui_icons/tick_mark.svg"></span>
                                        </mat-button-toggle>


                                    </div>
                                </div>
                            </div>
                            <div class="row foot_col_height" [hidden]="currenthind_hidden">
                                <!-- 4 -->
                                <div class="col-4 p-0 my-auto">
                                    <div class="matlabel" [hidden]="currenthind_hidden"> {{currenthind_footlabel}}<br>
                                    </div>
                                </div>
                                <div class="col-4 p-0 my-auto">
                                    <div style="text-align: center;">
                                        <mat-button-toggle id="toggid18" class="togglebtn"
                                            [(ngModel)]="wound_current_hind_Li" ngDefaultControl
                                            [checked]="wound_current_hind_Li"
                                            (change)="changecurrenthintleft($event.source.checked)" [disabled]="client">
                                            <span class="familytab" *ngIf="!wound_current_hind_Li"><img
                                                    class="foot_toggle_img"
                                                    src="../../../assets/ui_icons/cross_mark.svg"></span>
                                            <span class="familytab" *ngIf="wound_current_hind_Li"><img
                                                    class="foot_toggle_img"
                                                    src="../../../assets/ui_icons/tick_mark.svg"></span>
                                        </mat-button-toggle>

                                    </div>
                                </div>
                                <div class="col-4 p-0 my-auto">
                                    <!-- right -->
                                    <div style="text-align: center;">
                                        <mat-button-toggle id="toggid17" class="togglebtn"
                                            [(ngModel)]="wound_current_hind_Ri" ngDefaultControl
                                            [checked]="wound_current_hind_Ri"
                                            (change)="changecurrenthintright($event.source.checked)"
                                            [disabled]="client">
                                            <span class="familytab" *ngIf="!wound_current_hind_Ri"><img
                                                    class="foot_toggle_img"
                                                    src="../../../assets/ui_icons/cross_mark.svg"></span>
                                            <span class="familytab" *ngIf="wound_current_hind_Ri"><img
                                                    class="foot_toggle_img"
                                                    src="../../../assets/ui_icons/tick_mark.svg"></span>
                                        </mat-button-toggle>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4">
                    <div class="cover_div">
                        <div class="header_lable" [hidden]="skinnailhidden">
                            Skin & nail condition
                        </div>
                        <div class="content_cover">
                            <div class="row">
                                <div class="col-4 p-0"></div>
                                <div class="col-4 p-0">
                                    <div style="width: fit-content; margin: 0 auto;"><img class="foot_img"
                                            src="../../../assets/ui_icons/left_foot.svg"></div>
                                </div>
                                <div class="col-4 p-0">
                                    <div style="width: fit-content; margin: 0 auto;"><img class="foot_img"
                                            src="../../../assets/ui_icons/right_foot.svg"></div>
                                </div>
                            </div>
                            <div class="row foot_col_height" [hidden]="skindry_hidden">
                                <!-- 1 -->
                                <div class="col-4 p-0 my-auto">
                                    <div class="matlabel" [hidden]="skindry_hidden"> {{skindry_label}}<br></div>
                                </div>
                                <div class="col-4 p-0 my-auto">
                                    <div style="text-align: center;">
                                        <mat-button-toggle id="toggid20" class="togglebtn" [(ngModel)]="skin_dry_Li"
                                            ngDefaultControl [checked]="skin_dry_Li"
                                            (change)="changeskindryleft($event.source.checked)" [disabled]="client">
                                            <span class="familytab" *ngIf="!skin_dry_Li">
                                                <img class="foot_toggle_img"
                                                    src="../../../assets/ui_icons/cross_mark.svg">
                                            </span>
                                            <span class="familytab" *ngIf="skin_dry_Li">
                                                <img class="foot_toggle_img"
                                                    src="../../../assets/ui_icons/tick_mark.svg">
                                            </span>
                                        </mat-button-toggle>

                                    </div>
                                </div>
                                <div class="col-4 p-0 my-auto">
                                    <!-- right -->
                                    <div style="text-align: center;">
                                        <mat-button-toggle id="toggid19" class="togglebtn" [(ngModel)]="skin_dry_Ri"
                                            ngDefaultControl [checked]="skin_dry_Ri"
                                            (change)="changeskindryright($event.source.checked)" [disabled]="client">
                                            <span class="familytab" *ngIf="!skin_dry_Ri">
                                                <img class="foot_toggle_img"
                                                    src="../../../assets/ui_icons/cross_mark.svg">
                                            </span>
                                            <span class="familytab" *ngIf="skin_dry_Ri">
                                                <img class="foot_toggle_img"
                                                    src="../../../assets/ui_icons/tick_mark.svg">
                                            </span>
                                        </mat-button-toggle>

                                    </div>
                                </div>
                            </div>
                            <div class="row foot_col_height" [hidden]="footdefor_hidden">
                                <!-- 2 -->
                                <div class="col-4 p-0 my-auto">
                                    <div class="matlabel" [hidden]="footdefor_hidden"> {{plantar_label}}<br></div>
                                </div>
                                <div class="col-4 p-0 my-auto">
                                    <div style="text-align: center;">
                                        <mat-button-toggle id="toggid22" class="togglebtn" [(ngModel)]="skin_plantar_Li"
                                            ngDefaultControl [checked]="skin_plantar_Li"
                                            (change)="changeskinplantarleft($event.source.checked)" [disabled]="client">
                                            <span class="familytab" *ngIf="!skin_plantar_Li">
                                                <img class="foot_toggle_img"
                                                    src="../../../assets/ui_icons/cross_mark.svg">
                                            </span>
                                            <span class="familytab" *ngIf="skin_plantar_Li">
                                                <img class="foot_toggle_img"
                                                    src="../../../assets/ui_icons/tick_mark.svg">
                                            </span>
                                        </mat-button-toggle>


                                    </div>
                                </div>
                                <div class="col-4 p-0 my-auto">
                                    <!-- right -->
                                    <div style="text-align: center;">
                                        <mat-button-toggle id="toggid21" class="togglebtn" [(ngModel)]="skin_plantar_Ri"
                                            ngDefaultControl [checked]="skin_plantar_Ri"
                                            (change)="changeskinplantarright($event.source.checked)"
                                            [disabled]="client">
                                            <span class="familytab" *ngIf="!skin_plantar_Ri">
                                                <img class="foot_toggle_img"
                                                    src="../../../assets/ui_icons/cross_mark.svg">
                                            </span>
                                            <span class="familytab" *ngIf="skin_plantar_Ri">
                                                <img class="foot_toggle_img"
                                                    src="../../../assets/ui_icons/tick_mark.svg">
                                            </span>
                                        </mat-button-toggle>

                                    </div>
                                </div>
                            </div>
                            <div class="row foot_col_height" [hidden]="ignoring_hidden">
                                <!--3 -->
                                <div class="col-4 p-0 my-auto">
                                    <div class="matlabel" [hidden]="ignoring_hidden"> {{ignoring_label}}<br></div>
                                </div>
                                <div class="col-4 p-0 my-auto">
                                    <div style="text-align: center;">
                                        <mat-button-toggle id="toggid24" class="togglebtn"
                                            [(ngModel)]="nail_ingrowing_Li" ngDefaultControl
                                            [checked]="nail_ingrowing_Li"
                                            (change)="changenailingrowingleft($event.source.checked)"
                                            [disabled]="client">
                                            <span class="familytab" *ngIf="!nail_ingrowing_Li">
                                                <img class="foot_toggle_img"
                                                    src="../../../assets/ui_icons/cross_mark.svg">
                                            </span>
                                            <span class="familytab" *ngIf="nail_ingrowing_Li">
                                                <img class="foot_toggle_img"
                                                    src="../../../assets/ui_icons/tick_mark.svg">
                                            </span>
                                        </mat-button-toggle>

                                    </div>
                                </div>
                                <div class="col-4 p-0 my-auto">
                                    <!-- right -->
                                    <div style="text-align: center;">
                                        <mat-button-toggle id="toggid23" class="togglebtn"
                                            [(ngModel)]="nail_ingrowing_Ri" ngDefaultControl
                                            [checked]="nail_ingrowing_Ri"
                                            (change)="changenailingrowingright($event.source.checked)"
                                            [disabled]="client">
                                            <span class="familytab" *ngIf="!nail_ingrowing_Ri">
                                                <img class="foot_toggle_img"
                                                    src="../../../assets/ui_icons/cross_mark.svg">
                                            </span>
                                            <span class="familytab" *ngIf="nail_ingrowing_Ri">
                                                <img class="foot_toggle_img"
                                                    src="../../../assets/ui_icons/tick_mark.svg">
                                            </span>
                                        </mat-button-toggle>
                                    </div>
                                </div>
                            </div>
                            <div class="row foot_col_height" [hidden]="thickednail_hidden">
                                <!-- 4 -->
                                <div class="col-4 p-0 my-auto">
                                    <div class="matlabel" [hidden]="thickednail_hidden"> {{thickednail_label}}<br></div>
                                </div>
                                <div class="col-4 p-0 my-auto">
                                    <div style="text-align: center;">
                                        <mat-button-toggle id="toggid26" class="togglebtn"
                                            [(ngModel)]="nail_thickened_Li" ngDefaultControl
                                            [checked]="nail_thickened_Li"
                                            (change)="changenailthickendleft($event.source.checked)"
                                            [disabled]="client">
                                            <span class="familytab" *ngIf="!nail_thickened_Li">
                                                <img class="foot_toggle_img"
                                                    src="../../../assets/ui_icons/cross_mark.svg">
                                            </span>
                                            <span class="familytab" *ngIf="nail_thickened_Li">
                                                <img class="foot_toggle_img"
                                                    src="../../../assets/ui_icons/tick_mark.svg">
                                            </span>
                                        </mat-button-toggle>
                                    </div>
                                </div>
                                <div class="col-4 p-0 my-auto">
                                    <!-- right -->
                                    <div style="text-align: center;">
                                        <mat-button-toggle id="toggid25" class="togglebtn"
                                            [(ngModel)]="nail_thickened_Ri" ngDefaultControl
                                            [checked]="nail_thickened_Ri"
                                            (change)="changenailthickendright($event.source.checked)"
                                            [disabled]="client">
                                            <span class="familytab" *ngIf="!nail_thickened_Ri">
                                                <img class="foot_toggle_img"
                                                    src="../../../assets/ui_icons/cross_mark.svg">
                                            </span>
                                            <span class="familytab" *ngIf="nail_thickened_Ri">
                                                <img class="foot_toggle_img"
                                                    src="../../../assets/ui_icons/tick_mark.svg">
                                            </span>
                                        </mat-button-toggle>

                                    </div>
                                </div>
                            </div>
                            <div></div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4" >
                    <div class="cover_div" style="height: 162px;">
                        <div class="header_lable" [hidden]="footwearhidden">
                            Foot Wear
                        </div>
                        <div class="content_cover">
                            <div class="row foot_col_height" [hidden]="skindry_hidden">
                                <!-- 1 -->
                                <div class="col-4 p-0 my-auto">
                                    <div class="matlabel" [hidden]="skindry_hidden"> {{fitting_label}}<br></div>
                                </div>
                                <div class="col-4 p-0 my-auto">
                                    <div style="text-align: center;">
                                        <mat-button-toggle id="toggid28" class="togglebtn"
                                            [(ngModel)]="footwear_gfit_Li" ngDefaultControl [checked]="footwear_gfit_Li"
                                            (change)="changefootweargfitleft($event.source.checked)"
                                            [disabled]="client">
                                            <span class="familytab" *ngIf="!footwear_gfit_Li">
                                                <img class="foot_toggle_img"
                                                    src="../../../assets/ui_icons/thumbs_down.svg">
                                            </span>
                                            <span class="familytab" *ngIf="footwear_gfit_Li">
                                                <img class="foot_toggle_img"
                                                    src="../../../assets/ui_icons/thumbs_up.svg">
                                            </span>
                                        </mat-button-toggle>
                                    </div>
                                </div>
                                <div class="col-4 p-0 my-auto">
                                    <!-- right -->
                                    <div style="text-align: center;">
                                        <mat-button-toggle id="toggid27" class="togglebtn"
                                            [(ngModel)]="footwear_gfit_Ri" ngDefaultControl [checked]="footwear_gfit_Ri"
                                            (change)="changefootweargfitright($event.source.checked)"
                                            [disabled]="client">
                                            <span class="familytab" *ngIf="!footwear_gfit_Ri">
                                                <img class="foot_toggle_img"
                                                    src="../../../assets/ui_icons/thumbs_down.svg">
                                            </span>
                                            <span class="familytab" *ngIf="footwear_gfit_Ri">
                                                <img class="foot_toggle_img"
                                                    src="../../../assets/ui_icons/thumbs_up.svg">
                                            </span>
                                        </mat-button-toggle>
                                    </div>
                                </div>
                            </div>
                            <div class="row foot_col_height" [hidden]="footdefor_hidden">
                                <!-- 1 -->
                                <div class="col-4 p-0 my-auto">
                                    <div class="matlabel" [hidden]="footdefor_hidden"> {{shape_label}}<br></div>
                                </div>
                                <div class="col-4 p-0 my-auto">
                                    <div style="text-align: center;">
                                        <mat-button-toggle id="toggid30" class="togglebtn"
                                            [(ngModel)]="footwear_gshape_Li" ngDefaultControl
                                            [checked]="footwear_gshape_Li"
                                            (change)="changefootweargshapeleft($event.source.checked)"
                                            [disabled]="client">
                                            <span class="familytab" *ngIf="!footwear_gshape_Li">
                                                <img class="foot_toggle_img"
                                                    src="../../../assets/ui_icons/thumbs_down.svg">
                                            </span>
                                            <span class="familytab" *ngIf="footwear_gshape_Li">
                                                <img class="foot_toggle_img"
                                                    src="../../../assets/ui_icons/thumbs_up.svg">
                                            </span>
                                        </mat-button-toggle>

                                    </div>
                                </div>
                                <div class="col-4 p-0 my-auto">
                                    <!-- right -->
                                    <div style="text-align: center;">
                                        <mat-button-toggle id="toggid29" class="togglebtn"
                                            [(ngModel)]="footwear_gshape_Ri" ngDefaultControl
                                            [checked]="footwear_gshape_Ri"
                                            (change)="changefootweargshaperight($event.source.checked)"
                                            [disabled]="client">
                                            <span class="familytab" *ngIf="!footwear_gshape_Ri">
                                                <img class="foot_toggle_img"
                                                    src="../../../assets/ui_icons/thumbs_down.svg">
                                            </span>
                                            <span class="familytab" *ngIf="footwear_gshape_Ri">
                                                <img class="foot_toggle_img"
                                                    src="../../../assets/ui_icons/thumbs_up.svg">
                                            </span>
                                        </mat-button-toggle>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8 my-auto">
                    <div class="cover_div">
                        <div class="row">
                            <div class="col-5 "></div>
                                <div class="col-3 ">
                                    <div style="width: fit-content; margin: 0 auto;"><img class="foot_img"
                                            src="../../../assets/ui_icons/left_foot.svg"></div>
                                </div>
                                <div class="col-3 ">
                                    <div style="width: fit-content; margin: 0 auto;"><img class="foot_img"
                                            src="../../../assets/ui_icons/right_foot.svg"></div>
                                </div>
                        </div>
                        <div class="row" [hidden]="footdefor_hidden">
                            
                            <div class="col-5 my-auto">
                                <div class="foot_lable" style="font-weight: 575;" [hidden]="diabetes_relatedhidden">Diabetes related amputation</div>
                            </div>
                            <div class="col-3 my-auto">
                                <select class="ipcss" [(ngModel)]="limb_amputation_R" [disabled]="client">
                                    <option value="None">None</option>
                                    <option value="Below Knee Amputation">Below knee amputation</option>
                                    <option value="Above Knee Amputation">Above knee amputation</option>
                                    <option value="Transmetatarsal Amputation">Transmetatarsal amputation</option>
                                    <option value="Digital">Digital</option>
                                </select>
                            </div>
                            <div class="col-3 my-auto">
                                <select class="ipcss" [(ngModel)]="limb_amputation_L" [disabled]="client">
                                    <option value="None">None</option>
                                    <option value="Below Knee Amputation">Below knee amputation</option>
                                    <option value="Above Knee Amputation">Above knee amputation</option>
                                    <option value="Transmetatarsal Amputation">Transmetatarsal amputation</option>
                                    <option value="Digital">Digital</option>
                                </select>
                            </div>
                        </div>
                        <div class="row" [hidden]="risk_hidden">
                            <div class="col-5 my-auto">
                                <div class="foot_lable" style="font-weight: 575;" [hidden]="risk_hidden">{{risk_label}}</div>
                            </div>
                            <div class="col-3 my-auto">
                                <select class="ipcss widthappt" (change)="foot_ass_valid4()" [(ngModel)]="risk_level_R"
                                    [ngStyle]="riskR" [disabled]="client">
                                    <option value="None">None</option>
                                    <option value="Low">Low</option>
                                    <option value="Moderate">Moderate</option>
                                    <option value="High">High</option>
                                    <option value="Very High">Very high</option>
                                </select>
                            </div>
                            <div class="col-3 my-auto">
                                <select class="ipcss widthappt" (change)="foot_ass_valid5()" [(ngModel)]="risk_level_L"
                                    [ngStyle]="riskL" [disabled]="client">
                                    <option value="None">None</option>
                                    <option value="Low">Low</option>
                                    <option value="Moderate">Moderate</option>
                                    <option value="High">High</option>
                                    <option value="Very High">Very high</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>


    <div class=" col-12 " style="float: right;">
        <a style="float: right;"><img src="../../../assets/ui_icons/buttons/History_button.svg"
                class="saveimgbtn_inpatinfo" (click)="history()" /></a>
    </div>

</div>
<br>