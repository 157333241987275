<div *ngIf="nephphysicalFlag == 'min'">
    <div class="row">
        <div  class="col-md-12 col-lg-12 col-sm-12 col-xl-12">
            <mat-label class="matlabel">Name<br>
                <input type="text" class="ipcss widthappt" matInput [readonly]="client" [(ngModel)]="patient_name" />
            </mat-label>
        </div>
        <div [hidden]="age_hidden" class="col-md-12 col-lg-12 col-sm-12 col-xl-12">
            <mat-label class="matlabel" [hidden]="age_hidden" >Age<br>
                <input  matInput class="ipcss " readonly="agefield" [(ngModel)]="age_txt">
            </mat-label>
        </div>
        <div [hidden]="Gender_hidden" class="col-md-12 col-lg-12 col-sm-12 col-xl-12">
            <mat-label class="matlabel" [hidden]="Gender_hidden" >Gender<br>
                <input  matInput class="ipcss " readonly="true" [(ngModel)]="gender">
            </mat-label>
        </div>
        <div [hidden]="currnetoccupation_hidden" class="col-md-12 col-lg-12 col-sm-12 col-xl-12">
            <mat-label class="matlabel" [hidden]="currnetoccupation_hidden">Current Occupation<br>
                <input matInput class="ipcss " maxlength="50" [(ngModel)]="current_occ_txt" required>
            </mat-label>
        </div>
    </div>

</div>

<div *ngIf="nephphysicalFlag == 'max'">
    <div class="row">
        <div class="col-12 col-sm-12 col-md-6 col-lg-12 col-xl-12">
            <div class="cover_div" style="padding-bottom: 33px;">
                <div class="header_lable">
                    Personal
                </div>
                <div class="content_cover">
                    <div class="row">
                        <!-- <div  class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <mat-label class="matlabel">Name<br>
                                <input type="text" class="ipcss widthappt" matInput [readonly]="client" [(ngModel)]="patient_name" (blur)="send_data()"/>
                            </mat-label>
                        </div>
                        <div [hidden]="age_hidden" class="col-6 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                            <mat-label class="matlabel" [hidden]="age_hidden">Age<br>
                                <input matInput class="ipcss " readonly="agefield" [(ngModel)]="age_txt" (blur)="send_data()">
                            </mat-label>
                        </div>
                        <div [hidden]="Gender_hidden" class="col-6 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                            <mat-label class="matlabel" [hidden]="Gender_hidden">Gender<br>
                                <input matInput class="ipcss " readonly="true" [(ngModel)]="gender">
                            </mat-label>
                        </div> -->
                        <div [hidden]="currnetoccupation_hidden" class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <mat-label class="matlabel" [hidden]="currnetoccupation_hidden">Current Occupation<br>
                                <input matInput class="ipcss " maxlength="50" [(ngModel)]="current_occ_txt" (blur)="send_data()"
                                    [disabled]="client" required>
                            </mat-label>
                        </div>
                        <div [hidden]="physical_hidden" class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <mat-label class="matlabel" [hidden]="physical_hidden">Physical Activities<br>
                                <textarea matInput class="ipcss txtareaheight" maxlength="250" [disabled]="client" (blur)="send_data()"
                                    [(ngModel)]="phy_act_txt"></textarea>
                            </mat-label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div class="cover_div">
                <div class="header_lable">
                    Causes / Complications
                </div>
                <div class="content_cover">
                    <div class="row">
                        <div [hidden]="Causes_dis" class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <mat-label class="matlabel" [hidden]="Causes_dis">Causes Of Disease<br>
                                <textarea class="ipcss txtareaheight" [(ngModel)]="Causes_type" (click)="Causes_popup()" (blur)="send_data()"
                                    matInput [disabled]="client"></textarea>
                            </mat-label>
                        </div>
                        <div [hidden]="pastmeical_hidden" class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <mat-label class="matlabel" [hidden]="pastmeical_hidden">Medical History<br>
                                <textarea class="ipcss txtareaheight" [disabled]="client" [(ngModel)]="Medical_type" (blur)="send_data()"
                                    (click)="Medical_popup()"></textarea>
                            </mat-label>
                        </div>
                        <div [hidden]="Comple_hidden" class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <mat-label class="matlabel" [hidden]="Comple_hidden">Complications<br>
                                <textarea matInput class="ipcss txtareaheight" [(ngModel)]="Complications_type" (blur)="send_data()"
                                    (click)="Complications_popup()" [disabled]="client"></textarea>
                            </mat-label>
                        </div>
                        <div [hidden]="stress_hidden" class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <mat-label class="matlabel" [hidden]="stress_hidden">Stress Factors<br>
                                <textarea class="ipcss txtareaheight" matInput maxlength="150" (blur)="send_data()"
                                    [(ngModel)]="neph_stress_fact" [disabled]="client"></textarea>
                            </mat-label>
                        </div>
                      
                    </div>
                    <div class="row">
                        <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6 ">
                           <div class="row">
                             <div class="col-12 margin_10">
                               <mat-label class="matlabel">
                                 <b class="label_bold">Complaints</b>
                               </mat-label>
                             </div>
                             <div class="col-12">
                               <quill-editor id="editor1" [(ngModel)]="complaints" [styles]="{height: 'calc(35vh - 100px)'}"
                                 [modules]="modules" placeholder="Enter Text" (onContentChanged)="changelinemgmt($event,'main');">
                               </quill-editor>
                               <div>
                                 <quill-view-html hidden id="linemgmt" [content]="complaints"></quill-view-html>
                               </div>
                             </div>
                           </div>
                         </div>
           
                         <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6 ">
                           <div class="row">
                             <div class="col-12 margin_10">
                               <mat-label class="matlabel">
                                 <b class="label_bold">Past medical history</b>
                               </mat-label>
                             </div>
                             <div class="col-12">
                               <quill-editor id="editor1" [(ngModel)]="past_medical" [styles]="{height: 'calc(35vh - 100px)'}"
                                 [modules]="modules" placeholder="Enter Text" (onContentChanged)="changelinemgmt($event,'past');send_data()">
                               </quill-editor>
                               <div>
                                 <quill-view-html hidden id="linemgmt" [content]="past_medical"></quill-view-html>
                               </div>
                             </div>
                           </div>
                         </div>
                         <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6">
                           <div class="row">
                             <div class="col-12 margin_10">
                               <mat-label class="matlabel">
                                 <b class="label_bold">Present illness</b>
                               </mat-label>
                             </div>
                             <div class="col-12">
                               <quill-editor id="editor1" [(ngModel)]="present_illvalue" [styles]="{height: 'calc(35vh - 100px)'}"
                                 [modules]="modules" placeholder="Enter Text" (onContentChanged)="changelinemgmt($event,'present_illvalue');send_data()">
                               </quill-editor>
                               <div>
                                 <quill-view-html hidden id="linemgmt" [content]="present_illvalue"></quill-view-html>
                               </div>
                             </div>
                           </div>
                         </div>
                  
                     </div>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="cover_div">
                <div class="header_lable">
                    Symptoms / Concerns
                </div>
                <div class="content_cover">
                    <div class="row">
                        <div [hidden]="skin_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <mat-label class="matlabel" [hidden]="skin_hidden">Skin<br>
                                <textarea class="ipcss txtareaheight" [(ngModel)]="skin_type" (click)="skin_popup()" (blur)="send_data()"
                                    matInput [disabled]="client"></textarea>
                            </mat-label>
                        </div>
                        <div [hidden]="skin_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <mat-label class="matlabel" [hidden]="skin_hidden">Duration Of Symptoms<br>
                                <select class="ipcss " [(ngModel)]="skin_dur_desc" [disabled]="skin_disable || client" (change)="send_data()"
                                    required disableOptionCentering>
                                    <option disabled>Select</option>
                                    <option *ngFor="let skin_du of dutarion_symplist" value="{{skin_du.nephro_dur_id}}">
                                        {{skin_du.description}}</option>
                                </select>
                            </mat-label>
                        </div>
                        <div [hidden]="hearing_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <mat-label class="matlabel" [hidden]="hearing_hidden">Hearing<br>
                                <textarea class="ipcss txtareaheight" skin_disable [(ngModel)]="hearing_type" (blur)="send_data()"
                                    (click)="hearing_popup()" matInput [disabled]="client"></textarea>
                            </mat-label>
                        </div>
                        <div [hidden]="hearing_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <mat-label class="matlabel" [hidden]="hearing_hidden">Duration Of Symptoms<br>
                                <select class="ipcss " [(ngModel)]="hearing_duration" (change)="send_data()"
                                    [disabled]="hear_disable || client" required disableOptionCentering>
                                    <option disabled>Select</option>
                                    <option *ngFor="let hearing of dutarion_symplist" value="{{hearing.nephro_dur_id}}">
                                        {{hearing.description}}</option>
                                </select>
                            </mat-label>
                        </div>
                        <div [hidden]="Abdomen_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <mat-label class="matlabel" [hidden]="Abdomen_hidden">Abdomen<br>
                                <textarea class="ipcss txtareaheight" [disabled]="client" [(ngModel)]="Abdomen_type" (blur)="send_data()"
                                    (click)="Abdomen_popup()" matInput></textarea>
                            </mat-label>
                        </div>
                        <div [hidden]="Abdomen_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <mat-label class="matlabel" [hidden]="Abdomen_hidden">Duration Of Symptoms<br>
                                <select class="ipcss " [(ngModel)]="abdomen_duration" (change)="send_data()"
                                    [disabled]=" abd_disable || client" required disableOptionCentering>
                                    <option disabled>Select</option>
                                    <option *ngFor="let abdomen of dutarion_symplist" value="{{abdomen.nephro_dur_id}}">
                                        {{abdomen.description}}</option>
                                </select>
                            </mat-label>
                        </div>
                        <div [hidden]="Eye_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <mat-label class="matlabel" [hidden]="Eye_hidden">Eye<br>
                                <textarea class="ipcss txtareaheight" [(ngModel)]="Eye_type" (click)="Eye_popup()" (blur)="send_data()"
                                    matInput [disabled]="client"></textarea>
                            </mat-label>
                        </div>
                        <div [hidden]="Eye_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <mat-label class="matlabel" [hidden]="Eye_hidden">Duration Of Symptoms<br>
                                <select class="ipcss " [(ngModel)]="eye_duration" [disabled]="eye_disable || client" (change)="send_data()"
                                    required disableOptionCentering>
                                    <option disabled>Select</option>
                                    <option *ngFor="let eye of dutarion_symplist" value="{{eye.nephro_dur_id}}">
                                        {{eye.description}}
                                    </option>
                                </select>
                            </mat-label>
                        </div>
                        <div [hidden]="Cardio_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <mat-label class="matlabel" [hidden]="Cardio_hidden">Cardiovascular<br>
                                <textarea class="ipcss txtareaheight" [(ngModel)]="Cardio_type" (click)="Cardio_popup()" (blur)="send_data()"
                                    matInput [disabled]="client"></textarea>
                            </mat-label>
                        </div>
                        <div [hidden]="Cardio_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <mat-label class="matlabel" [hidden]="Cardio_hidden">Duration Of Symptoms<br>
                                <select class="ipcss " [(ngModel)]="cardio_duration" (change)="send_data()"
                                    [disabled]="cardio_disable || client" required disableOptionCentering>
                                    <option disabled>Select</option>
                                    <option *ngFor="let cardio of dutarion_symplist" value="{{cardio.nephro_dur_id}}">
                                        {{cardio.description}}</option>
                                </select>
                            </mat-label>
                        </div>
                        <div [hidden]="Resp_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <mat-label class="matlabel" [hidden]="Resp_hidden">Respiratory / Pulmonary<br>
                                <textarea class="ipcss txtareaheight" [(ngModel)]="Respiratory_type" (blur)="send_data()"
                                    (click)="Respiratory_popup()" matInput [disabled]="client"></textarea>
                            </mat-label>
                        </div>
                        <div [hidden]="Resp_hidden" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <mat-label class="matlabel" [hidden]="Resp_hidden">Duration Of Symptoms<br>
                                <select class="ipcss " [(ngModel)]="resp_duration" [disabled]="res_disable || client" (change)="send_data()"
                                    required disableOptionCentering>
                                    <option disabled>Select</option>
                                    <option *ngFor="let resp of dutarion_symplist" value="{{resp.nephro_dur_id}}">
                                        {{resp.description}}
                                    </option>
                                </select>
                            </mat-label>
                        </div>
                        <div [hidden]="symp_others" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <mat-label class="matlabel" [hidden]="symp_others">Other<br>
                                <textarea class="ipcss txtareaheight" maxlength="300" [(ngModel)]="neph_other_txt" (blur)="send_data()"
                                    [disabled]="client" matInput></textarea>
                            </mat-label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12">
            <a [hidden]="client" (click)="personal_hist_click()">
                <img src="../../../assets/ui_icons/buttons/History_button.svg" style="float: right;"
                    width="82px" height="29px" />
            </a>
        </div>
    </div>
</div>