<app-header></app-header>
<div class="banner-section-bg d-flex flex-column justify-content-center mt-5">
    <div class="px-5 ">
       <h1 class="banner-head text-center pt-5"> Nutrition Management System</h1>
    </div>
 </div>
 <div class="pt-5 pb-5">
    <div class="container">
       <div class="row">
          <div class="col-12 col-md-4">
             <img src="../../../assets/images/solutions/schedule.png" class="hosp-img">
          </div>
          <div class="col-12 col-md-7">
             <p class="para">Gritsys AI  based nutrition management system is a computer-based system that utilizes artificial intelligence (AI) algorithms to support individuals in managing their diet and nutrition. This system is designed to help people make better-informed decisions about what to eat, when to eat, and how much to eat, based on their specific health goals and nutritional needs.
                <br>
                <br>
                The system works by processing large amounts of data related to nutrition, including food composition data, individual dietary preferences, and health metrics such as weight, body mass index (BMI), and blood glucose levels. It then applies advanced algorithms and machine learning techniques to analyze this data and generate recommendations tailored to the individual's specific needs.
             </p>
          </div>
       </div>
    </div>
 </div>
 <div class="wcu-section pt-5 pb-5" id="wcuSection">
    <div class="container">
       <div class="row justify-content-center">
          <div class="text-center pt-3 pb-3">
             <h1 class="wcu-heading" style="font-size: 30px;">Our key components of an AI nutrition management system</h1>
          </div>
          <div class="col-12 col-md-4">
             <div class="wcu-card text-center">
                <img src="../../../assets/images/solutions/data-input.png" class="wcu-card-icon" />
                <h2 class="wcu-heading pt-3">Data Input</h2>
                <p class="wcu-para">The system needs access to a variety of data related to the individual's diet and nutrition, including food logs, dietary preferences, and health metrics.</p>
             </div>
          </div>
          <div class="col-12 col-md-4">
             <div class="wcu-card text-center">
                <img src="../../../assets/images/solutions/data-process.png" class="wcu-card-icon" />
                <h2 class="wcu-heading pt-3">Data Processing</h2>
                <p class="wcu-para">The system uses algorithms to process and analyze the data, identifying patterns and relationships that may be relevant to the individual's nutritional needs.</p>
             </div>
          </div>
          <div class="col-12 col-md-4">
             <div class="wcu-card text-center">
                <img src="../../../assets/images/solutions/dietplan.png" class="wcu-card-icon" />
                <h2 class="wcu-heading pt-3"> Dietary planning</h2>
                <p class="wcu-para">The system generates personalized meal plans based on the analyzed data, taking into account the individual's dietary preferences, health goals, and nutritional needs.</p>
             </div>
          </div>
          <div class="col-12 col-md-4">
             <div class="wcu-card text-center">
                <img src="../../../assets/images/solutions/nutrition-track.png" class="wcu-card-icon" />
                <h2 class="wcu-heading pt-3">Nutritional tracking</h2>
                <p class="wcu-para">The system helps individuals track their food intake and nutrient intake, providing feedback on their progress towards their health goals.</p>
             </div>
          </div>
          <div class="col-12 col-md-4">
             <div class="wcu-card text-center">
                <img src="../../../assets/images/solutions/wearables.png" class="wcu-card-icon" />
                <h2 class="wcu-heading pt-3">Integration with wearable devices</h2>
                <p class="wcu-para">The system integrate with wearable devices such as fitness trackers, smart watches, and glucose monitors, to provide real-time feedback.</p>
             </div>
          </div>
          <div class="text-center pt-3 pb-3">
             <p class="para">Our AI nutrition management system include improved adherence to dietary recommendations, better management of chronic health conditions such as diabetes and heart disease, and improved overall health outcomes. However, there are also challenges associated with implementing these systems, such as ensuring data privacy and security, addressing issues of bias and fairness, and ensuring that recommendations are evidence-based and aligned with current nutritional guidelines.</p>
          </div>
       </div>
    </div>
 </div>

 <app-footer></app-footer>
